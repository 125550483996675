import React from "react";

export default class NotebookSVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {

		//let color3b = this.props.selectedDresses.pants ? '#293' : '#1B1464'
		return (  
			<div style={{ 
				padding: 0, marginTop: 0, marginLeft: 0, 
				height: "100%", width: "100%", 
				position: "absolute", zIndex: 22}}>	
<svg version="1.1" 
	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1895.5 1353.1"
	 enable-background="new 0 0 1895.5 1353.1" >
<switch>
	<g >
		<g id="Слой_1">
			<path fill="#333333" d="M1862.5,0H33C14.8,0,0,14.8,0,33v1287.1c0,18.2,14.8,33,33,33h1829.5c18.2,0,33-14.8,33-33V33
				C1895.5,14.8,1880.7,0,1862.5,0z M1790.8,1197.1c0,8.3-6.7,15-15,15H119.7c-8.3,0-15-6.7-15-15V156c0-8.3,6.7-15,15-15h1656.2
				c8.3,0,15,6.7,15,15V1197.1z"/>
		</g>
		<g id="Слой_2">
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="168.0168" y1="1353.1195" x2="168.0168" y2="1248.1248">
				<stop  offset="0" style={{stopColor:"#666666"}}/>
				<stop  offset="0.1906" style={{stopColor:"#535353"}}/>
				<stop  offset="0.4132" style={{stopColor:"#454545"}}/>
				<stop  offset="0.7199" style={{stopColor:"#373737"}}/>
				<stop  offset="1" style={{stopColor:"#333333"}}/>
			</linearGradient>
			<rect x="129" y="1248.1" fill="url(#SVGID_1_)" width="78" height="105"/>
			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="1728.0837" y1="1353.1195" x2="1728.0837" y2="1248.1248">
				<stop  offset="0" style={{stopColor:"#666666"}}/>
				<stop  offset="0.1906" style={{stopColor:"#535353"}}/>
				<stop  offset="0.4132" style={{stopColor:"#454545"}}/>
				<stop  offset="0.7199" style={{stopColor:"#373737"}}/>
				<stop  offset="1" style={{stopColor:"#333333"}}/>
			</linearGradient>
			<rect x="1689.1" y="1248.1" fill="url(#SVGID_2_)" width="78" height="105"/>
			<path fill="#101010" d="M1233.1,73.5c-184,0-368,0-552.1,0C668.8,49,656.5,24.5,644.1,0c209.3,0,418.7,0,628,0
				C1259.1,24.5,1246.1,49,1233.1,73.5z"/>
			<rect x="129" y="0" fill="#040404" width="39" height="39"/>
			<rect x="1728.1" fill="#040404" width="39" height="39"/>
			<g>
				<g>
					<path fill="#040404" d="M17,74.3c9.5-11.6,19.1-23.1,28.6-34.7c0.3-0.4-0.2-0.9-0.5-0.5C35.6,50.6,26,62.2,16.5,73.7
						C16.2,74.1,16.7,74.6,17,74.3L17,74.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M39.4,93.2C46.8,80.1,55.7,68,65.7,56.8c0.3-0.4-0.2-0.9-0.5-0.5c-10.1,11.3-19,23.5-26.5,36.6
						C38.5,93.3,39.1,93.6,39.4,93.2L39.4,93.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M60.9,126.2c3.4-23.5,13.5-45.3,29.2-63.1c0.3-0.4-0.2-0.9-0.5-0.5C73.8,80.4,63.6,102.4,60.2,126
						C60.1,126.5,60.8,126.7,60.9,126.2L60.9,126.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M72.3,129c1.7-28.6,14.5-55.7,35.5-75.2c0.4-0.3-0.2-0.9-0.5-0.5c-21.2,19.7-34,46.9-35.7,75.8
						C71.5,129.5,72.3,129.5,72.3,129L72.3,129z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1361.9,1236.1c2.2,23.3,6.4,46.4,12.5,69c0.1,0.5,0.8,0.3,0.7-0.2c-6.1-22.5-10.3-45.5-12.5-68.8
						C1362.6,1235.6,1361.8,1235.6,1361.9,1236.1L1361.9,1236.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1386.8,1232.8c7.9,20.1,10.5,41.8,7.6,63.2c-0.1,0.5,0.7,0.7,0.7,0.2c2.9-21.6,0.4-43.3-7.6-63.6
						C1387.4,1232.2,1386.6,1232.4,1386.8,1232.8L1386.8,1232.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1416.9,1235.8c8.5,21,11.1,43.8,7.4,66.2c-0.1,0.5,0.6,0.7,0.7,0.2c3.7-22.5,1.1-45.4-7.4-66.6
						C1417.4,1235.1,1416.7,1235.3,1416.9,1235.8L1416.9,1235.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1452.6,1230.2c-1.1,26-2.2,52-3.3,78c0,0.5,0.7,0.5,0.8,0c1.1-26,2.2-52,3.3-78
						C1453.3,1229.7,1452.6,1229.7,1452.6,1230.2L1452.6,1230.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1478.4,1230.2c3.6,26.8,4.6,53.9,3.2,80.9c0,0.5,0.7,0.5,0.8,0c1.5-27,0.4-54.1-3.2-80.9
						C1479.1,1229.7,1478.3,1229.7,1478.4,1230.2L1478.4,1230.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1511.8,1239.1c0,14,0,28,0,42c0,0.5,0.8,0.5,0.8,0c0-14,0-28,0-42
						C1512.5,1238.6,1511.8,1238.6,1511.8,1239.1L1511.8,1239.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1530.5,1247.7c7.2,5.3,12,12.9,13.9,21.6c0.1,0.5,0.8,0.3,0.7-0.2c-1.9-9-6.8-16.6-14.2-22.1
						C1530.5,1246.8,1530.1,1247.4,1530.5,1247.7L1530.5,1247.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#040404" d="M1561.1,1250.6c4.4,7.4,8.8,14.7,13.3,22.1c0.2,0.4,0.9,0,0.6-0.4c-4.4-7.4-8.8-14.7-13.3-22.1
						C1561.5,1249.8,1560.8,1250.1,1561.1,1250.6L1561.1,1250.6z"/>
				</g>
			</g>
		</g>
	</g>
</switch>

</svg>
</div>
)}}
