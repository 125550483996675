import React from "react";

export default class CableSVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		//let color4 = this.props.selectedDresses.hat ? '#0d0' :'#2A2A2A'
		//let color4a = this.props.selectedDresses.hat ? '#3d5' : '#36353C'

		//let noteBookXY = (this.props.selectedDresses.hat && this.props.selectedDresses.shoes) ? 'M764.7,2694.8' : 'M864.7,2994.8'
		return (   
			<div
				style={{
					position: 'absolute',
					height: '20%',
					width: '45%',
					right: '5%',
					bottom: '10%',
					cursor: "pointer"
				}}
				onClick={this.props.onClick}
				draggableId={"1"} draggable="true" 
			>
				<svg version="1.1" id="Слой_1"
					x="0px" y="0px" viewBox="0 0 723.8 563.4"
	 enable-background="new 0 0 723.8 563.4">
				<switch>
					<g>
						<g>
							<g>
								<path fill="#808080" d="M152.6,153.7c47.3-11.5,94.6-22.8,141.9-34.1c22.2-5.3,44.7-9.9,67.6-6.2c20.7,3.4,40.1,12.7,57.4,24.3
					c35.1,23.6,64.3,56.9,88.2,91.4c22.6,32.7,41.8,70.4,45.6,110.5c3.2,34.2-7.1,70.1-33.3,93.4c-25.8,23-61,29.5-94.6,26.9
					c-36.9-2.9-73.6-16.1-103.1-38.7c-29.6-22.6-50.7-53.9-60.6-89.8c-8.6-31.4-9-64.4-5.7-96.6c1.9-18.7,3.3-40.7,16.7-55.2
					c12.4-13.5,32.2-17.6,49.7-18.5c40.3-2.2,81.2,11.1,116.5,29.5c35.7,18.6,68.2,44,94.5,74.4c12.3,14.2,23.4,29.7,32.2,46.3
					c9.2,17.4,18.1,40.7,6.2,59c-10.3,15.9-30.4,24-47.5,29.8c-18.3,6.2-37.4,9.7-56.7,10.9c-43.4,2.7-87.2-6.7-125.5-27.2
					c-35.3-18.9-65.3-46.1-91.8-75.8c-13-14.5-25.2-29.7-37.1-45c-13.6-17.5-27.7-35.6-36.4-56.1c-7.4-17.5-9.4-36.4,0.6-53.3
					c9.5-16.2,26.3-27.3,42-36.8c38.2-23.2,78.2-44.8,119.2-62.7c19.8-8.7,40-10.9,60.8-4.1c18.2,6,34.5,16.8,49.2,28.9
					c15.6,12.9,29.6,27.5,42.9,42.8c15.9,18.3,31.7,36.7,47.6,55.1c31.7,36.7,63.4,73.4,95.1,110c15.6,18.1,31.4,36,46.8,54.2
					c12.5,14.8,26.2,31.6,31.1,50.8c2.1,8.1,2,16.9-3,24c-5.7,8-15.8,11.6-24.9,14.2c-21.5,6-44,9.5-66,12.8
					c-45.1,6.7-90.9,10.5-136.5,10.1c-45.3-0.4-90.8-6-134.1-20c-39.9-12.9-77.6-33.3-109-61.3c-27.2-24.3-52.4-56.9-54.6-94.7
					c-2.3-40.3,22.5-74.9,45.8-105.3c19.8-25.9,44-50.8,75.2-62.4c38-14.1,78.5-3.2,111.6,17.9c35.1,22.4,64.5,53.7,93.7,83.1
					c30,30.3,59.8,62.4,80.2,100.2c16.9,31.4,28.9,74.5,9.7,107.6c-18,31-57.5,37.5-90.4,37.1c-41-0.5-81.7-10.5-120.7-22.3
					c-41.9-12.7-83.2-28.9-118.9-54.6c-32.3-23.3-58.9-53.3-81.5-85.9c-10.7-15.4-20.6-31.4-30.1-47.6c-10.4-17.7-22-35.6-29.3-54.8
					c-3.6-9.5-6.2-19.9-3.9-30c2.1-9.4,8.5-17.1,15.7-23.2c14.1-11.9,31.5-20.5,47.9-28.8c35.6-18,74-30.9,114-33
					c41.4-2.1,82.3,8,119.1,26.5c68.2,34.2,120.9,93.6,164.8,154.6c12.5,17.3,25,34.8,34.8,53.8c10.7,20.8,15.9,43.5,13.1,66.9
					c-3.5,30.5-19,57.8-42.2,77.7c-52.2,44.8-129.2,52-194.4,39.5c-72.7-13.9-139.7-54.6-188.7-109.6
					c-39.2-44-73.9-104.6-65.6-165.7c9.8-72.8,78.6-117.9,142.5-140.5c40.9-14.4,84.7-20.9,127.7-14.3
					c43.6,6.6,83.5,26.8,118.8,52.5c34.8,25.2,66.9,55.4,93.7,89c24.3,30.4,47.2,67.4,47.7,107.5c0.5,36.9-17.9,72.4-42.8,98.7
					c-23.9,25.2-54.9,43.1-87.5,54.4c-75.7,26.2-159.9,18.9-235.7-3.2c-81.4-23.8-160.8-65.8-220.6-126.7
					c-25.9-26.4-49.1-57.4-60.4-93c-12.8-40.3-5.9-82.6,14.4-119.2c34.3-61.8,101.3-96.8,170.7-99.8c84.3-3.7,166.9,33.3,237.1,76.7
					c36.6,22.6,71.7,48.1,104.2,76.2c31.6,27.3,61.7,58.1,81.4,95.3c20.7,38.9,27.1,83.3,17.7,126.4c-4.3,19.7-11.7,38.7-21.1,56.6
					c-8.9,16.8-19.5,34.8-33.1,48.3c-17.1,17-40.5,12.4-61,4.7c-18.9-7.1-36.7-17.2-52.6-29.6c-15.3-11.9-29.1-26-39.8-42.2
					c-4.8-7.3-9.1-15-12.3-23.2c-2.9-7.5-5.5-16.8-2.6-24.6c4-10.8,17.3-9.2,26.2-6.5c9.1,2.7,17.7,7.5,25.2,13.1
					c13.5,10.1,23.9,23.9,30.3,39.4c5.7,13.8,8.6,28.8,8.1,43.7c-0.5,15.9-4,34.2-15.7,45.8c-12,11.9-29.8,12.6-45.4,9.3
					c-16.9-3.5-33-11.6-47-21.6c-14.7-10.6-26.9-23.8-35.4-39.9c-10-18.9-14.1-40.3-14.1-61.6c0-8.9,0.6-17.8,1.5-26.6
					c0.8-7.9,1.9-15.8,6.9-22.3c5.7-7.5,14.3-8,22.1-3c7.3,4.6,11.9,12.6,16,20c11.1,19.8,29.2,65.3-4.4,74c1.1,0.3,2.1,0.5,3.2,0.8
					c-32-35.2-57-88.4-37.9-135.8c-1.9,0.1-3.8,0.3-5.7,0.4c5.7,28.8,10.8,58.2,10.7,87.7c0,14.4-0.8,31.2-8.6,43.8
					c-4.9,7.8-12.5,11.5-21.6,10.9c-12.4-0.9-24.6-6.8-35.1-13.2c-11.5-6.9-22.4-15.4-31.1-25.7c-6-7.2-11.8-16.1-11.2-25.8
					c0.5-7.9,5.3-14.6,11-19.8c15.3-13.8,36.6-21,55.9-27.1c46-14.5,94.5-26,143-24.1c56.7,2.3,119.7,26.4,143.6,82
					c9.8,22.8,11.5,49.8-1.3,71.8c-14.1,24.4-41,38.3-67.2,45.8c-56.9,16.2-127.8,9.5-171.2-34.1c-22.8-22.9-37.4-54.3-42.6-86.1
					c-2.6-16-2.6-32.5,0.6-48.4c3-14.8,8.8-30.7,18.5-42.4c10.7-13,27.1-16.7,41.7-7.5c7,4.4,15.8,11.8,17.8,20.3
					c3.3,14-10.6,28-19.6,36.7c-15.7,15.2-34,27.7-52.4,39.4c-19.3,12.3-39.3,23.6-59.5,34.4c-7.7,4.1-15.5,8.2-23.3,12.1
					c-3.5,1.8-0.4,5.1,2.6,3.6c41.7-21.2,84-43.9,120.7-73.1c13.7-10.8,30.4-24.4,36.2-41.5c2-6,2.2-12.3-0.8-18
					c-5.4-10.1-16.7-18.9-27.5-22.2c-32.1-9.9-51.4,24.9-58.4,50.5c-9.3,34-3.2,70.7,11.5,102.3c14,30,36.5,54.5,66.4,69
					c28.3,13.8,60.7,18.6,92,17.1c52.2-2.6,125.1-26.3,129.2-88.3c4.2-62.6-54.3-107.8-109.4-121.5c-55.1-13.7-111.3-3.8-165.2,10.3
					c-22.3,5.9-44.9,12.3-65.5,22.9c-14.7,7.6-33.6,20-32.9,38.9c0.4,10.5,6.7,19.7,13.4,27.3c9.5,10.6,21,19.4,33.3,26.4
					c14.3,8.1,34.1,17.2,50.6,9.9c10.8-4.8,16.1-15.9,18.9-26.8c5.4-21.5,3.3-45,0.8-66.8c-2-17.5-5-34.9-8.5-52.2
					c-0.5-2.5-5-1.4-5.7,0.4c-19.6,48.6,5.3,103,38.2,139.2c0.8,0.9,2.1,1.1,3.2,0.8c34.6-8.9,24.2-49.3,12.7-72.9
					c-7-14.4-17.8-34.5-36.7-33.1c-16.2,1.2-22,18.4-23.5,32c-4.5,40.9,0.3,83.9,29.5,115.3c12.1,13,26.9,23.4,42.9,31
					c14.9,7,31.9,11.9,48.5,10.5c16.2-1.4,30.3-9.9,38.1-24.4c7.7-14.3,9.7-32,8.5-48.1c-2.4-33.2-19.9-64.7-49.2-81.4
					c-13-7.4-37.3-17.3-49.1-2.6c-10.3,12.7-0.8,32.8,5.7,44.9c18.9,35.4,52.8,62.9,88.9,79.4c18.6,8.5,42.1,17.2,62.6,10.3
					c17.9-6,29.1-23.6,39-38.6c22.9-34.9,38.5-75.3,39.3-117.4c1.8-95.2-72.5-165.1-142.6-218.4c-70.5-53.5-151.2-101-239.1-118
					c-40.1-7.8-81.9-8.2-121.4,2.8c-34.2,9.5-66,27.3-91.2,52.3c-26.1,25.9-45.5,59.8-51.9,96.2C9.8,217,22.2,256.8,44.2,291
					c23,35.7,54.7,65.7,88.7,90.9c36.2,26.9,76.2,48.6,117.9,65.6c78.5,32,167.5,49.1,251.8,33.5c69.8-12.9,140.4-52,168.1-120.6
					c8.2-20.2,11.6-42.1,8.4-63.7c-2.9-19.7-10.8-38.5-20.6-55.7c-22.2-39.2-54.6-72.8-88.4-102.3c-33-28.8-70.2-54.3-111.9-68.9
					c-43.8-15.4-91-16.6-136.1-6.6c-39,8.6-77.5,24.5-110.5,47.2c-29.2,20.1-54.6,47.4-66.9,81.2c-22.3,61.6,6.5,127.9,44.3,176.8
					c46.6,60.3,114.1,106.4,188.1,125.3c68.4,17.5,149.4,14,208.6-28.3c24.4-17.4,43.5-41.9,51.5-71c6-21.9,5.9-45.3-1.3-66.8
					c-7.4-22.2-20.9-42-34.2-61c-45.3-65-99.3-128.7-170.3-166.4C394.1,80.5,352.6,69.2,310.2,70c-41.6,0.9-81.9,13.4-119.1,31.6
					c-17.5,8.6-35.6,17.8-51.1,29.7c-14.9,11.5-24.1,26.8-20.6,46.1c3.9,21.5,17.3,41.1,28.1,59.7c10,17.3,20.3,34.5,31.4,51
					c23.1,34.2,50.1,66,83.4,90.7c37,27.5,80.4,44.7,124.2,57.9c40.7,12.3,83,22.5,125.8,22.4c33.4-0.1,72.3-7.8,91.6-38
					c20.5-32,11.1-74.4-4.4-106.4c-20.1-41.4-52.6-76-84.7-108.3c-54-54.3-125.9-133.3-211.4-101.8c-32.4,11.9-57.6,37.3-78.2,64.1
					c-24.3,31.6-50,67.8-47.1,109.7c2.7,38.2,27.8,70.8,55.3,95.5c32.7,29.4,72.5,50.5,114.3,63.6c45.1,14.1,92.4,19.2,139.5,19.3
					c48.9,0,97.9-4.5,146.1-12.6c11.6-1.9,23.2-4.1,34.7-6.5c10-2.1,20.2-4.2,29.6-8.4c15.9-7.1,24.3-20.9,20.3-38.2
					c-4.5-19.8-18.7-37.3-31.4-52.5c-16.6-19.8-33.7-39.1-50.6-58.7C602,240.9,567.9,201.4,533.8,162
					c-29.1-33.7-57.1-70.5-93.8-96.5c-15.7-11.1-33.3-20.2-52.4-23.3c-21-3.4-40.2,2.8-59.1,11.6c-22.6,10.5-44.8,21.7-66.7,33.6
					c-19.8,10.8-40,21.6-58.6,34.4c-14.6,10-29.3,22.6-35.6,39.7c-6.8,18.3-1.6,37.6,6.7,54.5c10.3,20.7,25,39,39.3,57.1
					c12.8,16.2,26,32.1,40,47.2c28.8,30.8,61.8,58.2,100.6,75.4c40.4,17.9,85.9,24.2,129.7,18.3c19.2-2.6,38.1-7.4,55.9-15
					c16.2-6.9,34.2-17.1,40.9-34.5c7.4-19.4-1.9-41-11.2-58c-9.6-17.5-21.7-33.7-35-48.6c-28.1-31.2-62.5-56.9-100.3-75.2
					c-36.6-17.7-79.8-30.3-120.8-25c-16.9,2.2-34.9,7.9-46.4,21.2c-12.9,15-14.7,36.4-16.7,55.2c-3.6,34.1-3.2,69.2,6.5,102.3
					c10.9,37,33.9,69.1,65.2,91.5c30.9,22.2,69.1,35,107,36.8c34.2,1.6,69.9-6,95.8-29.8c26.3-24.1,37.2-59.7,34.3-94.7
					c-3.4-41.8-23.6-81.3-47.3-115.2c-25.2-36-56.2-71-93.7-94.5c-17.1-10.7-36.1-19-56.2-21.9c-21.7-3.1-43.2,0.7-64.4,5.7
					c-48.8,11.5-97.5,23.3-146.2,35.1C147.9,150.4,149,154.6,152.6,153.7L152.6,153.7z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M103,355.5c-3.5-4.8-7-9.6-10.6-14.5c-0.4-0.5-2.5,0.2-2,0.8c3.5,4.8,7,9.6,10.6,14.5
					C101.3,356.8,103.4,356.1,103,355.5L103,355.5z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M170.9,402.7c-12.2-4.7-26.1-11.4-32.1-23.7c-0.4-0.8-2.5-0.1-2.2,0.6c6.2,12.6,20.2,19.4,32.6,24.2
					C169.9,404,171.9,403.1,170.9,402.7L170.9,402.7z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M197.9,369.8c-6.1-2.7-25.6-11.7-17.5-21.1c0.6-0.7-1.6-0.6-2-0.1c-8.5,9.9,11.4,19.4,17.8,22.2
					C196.8,371.2,198.8,370.2,197.9,369.8L197.9,369.8z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M177.1,146.7c17-9.6,37.3-11.1,56.4-9.9c0.4,0,2.5-0.8,1.4-0.8c-20.1-1.3-41.6,0.7-59.5,10.7
					C174.6,147.2,176.8,146.9,177.1,146.7L177.1,146.7z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M176.8,113.1c30.3-13.9,61.8-25,93.9-33.7c1.3-0.4,0.9-1.8-0.4-1.5c-32.4,8.8-64,19.9-94.5,34
					C174.5,112.5,175.7,113.6,176.8,113.1L176.8,113.1z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M362.8,445.5c-9.7-9.8-17.2-21.3-21.4-34.5c-3.5-10.9-4.6-22.3-5.5-33.7c-1.6-21.8-4.9-49.6,18.1-61.5
					c1.3-0.7,0.1-1.5-0.9-1c-22.1,11.5-21.3,36-19.8,57.7c0.9,12.4,1.7,24.9,5.2,37c4.1,14.1,11.8,26.4,22.1,36.8
					C361.3,446.9,363.4,446.1,362.8,445.5L362.8,445.5z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M375.6,438.9c-1.4-34.8,1.3-69.7,8.8-103.7c0.2-1-2-0.7-2.2,0.2c-7.5,34.1-10.2,68.9-8.8,103.7
					C373.5,440.1,375.7,439.7,375.6,438.9L375.6,438.9z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M491.2,482.7c-22.3-12.4-45.6-31.6-50.2-58.2c-0.1-0.8-2.3-0.3-2.2,0.6c4.6,26.8,28.2,46.2,50.6,58.7
					C490.2,484.2,492.1,483.2,491.2,482.7L491.2,482.7z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M530.1,399.3c14.9,0,29.9-2,43.7-7.9c1.3-0.6-0.7-0.5-1.3-0.3c-13,5.5-27.1,7.3-41.1,7.3
					C531,398.4,529,399.3,530.1,399.3L530.1,399.3z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M609.1,446.4c16.6-11.9,31-26.3,42-43.6c0.2-0.3-1.8-0.2-2.1,0.4c-10.9,17.1-25.2,31.3-41.6,43.1
					C606.6,447,608.7,446.7,609.1,446.4L609.1,446.4z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M592.7,254c-17.1-18.6-33.1-38.3-47.2-59.2c-0.4-0.6-2.5,0.1-2,0.8c14.1,21,30,40.7,47.2,59.2
					C591.1,255.3,593.2,254.6,592.7,254L592.7,254z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M621,260.1c-2.8-6.3-7.3-11.6-12.4-16.2c-0.7-0.6-2.6,0.4-1.7,1.2c4.9,4.4,9.2,9.5,11.9,15.5
					C619.3,261.7,621.4,261,621,260.1L621,260.1z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M238.5,19c26.3-3.4,54.4,4.8,77.1,17.7c0.6,0.3,2.4-0.6,2-0.8c-23.4-13.2-51.9-21.4-78.8-17.9
					C237.9,18.3,236.9,19.2,238.5,19L238.5,19z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M497.9,192c-10.5-14.6-23.9-26.7-38.5-37.1c-0.8-0.6-2.7,0.6-1.7,1.3c14.5,10.3,27.8,22.3,38.2,36.8
					C496.5,193.9,498.6,193,497.9,192L497.9,192z"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333333" d="M559.8,356.5c-5.7-27.7-11.4-55.3-17-83c-0.1-0.5-2.3,0-2.2,0.6c5.7,27.7,11.4,55.3,17,83
					C557.7,357.6,559.9,357.1,559.8,356.5L559.8,356.5z"/>
							</g>
						</g>
						<g>
							<g>
								<path d="M397.6,214c0.8,0,2.3-1.2,0.9-1.2C397.7,212.8,396.1,214,397.6,214L397.6,214z" />
							</g>
						</g>
					</g>
				</switch>
</svg>

            </div>
        )}}