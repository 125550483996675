import React from "react";

export default class BRMZSVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		//let color4 = this.props.selectedDresses.hat ? '#0d0' :'#2A2A2A'
		return ( 
			<div style={{
				position: 'absolute',
				height: '10%',
				width: '10%',
				left: this.props.leftPosition,
				top: this.props.topPosition,
				///cursor: 'pointer',
				zIndex: 222,
				transition: 'all 2s cubic-bezier(0.99, 0.01, 1, 0.54) 0s',
				bottom: this.props.onIPsCorrect ? '2%' : '-10%',

			}}>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 1312 1102" enable-background="new 0 0 1312 1102" onClick={this.props.onClick}>
<g id="Слой_1">
	<g>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M955.2,68.4c3,2.5,6.6,2,10,2.1
			c26.5,0.9,52.9,0.5,79.4,1.9c48.2,2.5,96.7,0.8,144.9,3.6c32.3,1.9,64.6-0.7,96.8,3.8c5.9,0.8,12.2,1.8,12.2,8.3
			c-0.2,23.2,3.8,46.7-3.1,69.6c-7.8-4.4-15.2-8.3-25.1-5.4c-12.8,3.8-8.8,13.6-9.1,21.7c-0.3,7.7,3.7,12.1,12.1,12.2
			c8.7,0.1,15.6-3.9,22.5-8c2.2,27.6-0.9,54.9-3.3,82.3c-0.5,6.3-3.1,13.3,3.5,18.3c-1.6,59.9-3.1,119.7-4.7,179.6
			c-2.7,22.3-2.1,44.7-1.6,67.1c-0.7,17.2-1.5,34.5-2.2,51.7c-1.2,3.4-1,6.7-0.2,10.1c-0.2,18.7-0.4,37.3-0.6,56
			c-0.6,3-1.2,5.9-1.9,8.9c0,1.9,0,3.9,0,5.8c-0.9,38.9-1.8,77.8-2.7,116.7c-0.1,0.9-0.1,1.8-0.2,2.7c-5.3,2.8-4.2,7.9-4.2,12.4
			c-0.1,30,0,60-0.1,90c0,4.2,1.3,8.6-1.8,12.4c-3.4-3.1-6.4-7.1-10.3-9.2c-6.7-3.6-13.9-7.4-20.9,0.7c-7.1,8.2-9,20.9-3.2,29.2
			c5.7,8.2,12.4,4.4,19.7,2c6.5-2.1,10.3-6.5,14.4-11.1c4.4,9.3,2.7,19.3,1.7,28.6c-1.5,12.9-1.9,25.6-1.7,38.5
			c0.1,7.5-3,9.9-10.4,10.6c-43.2,4.2-86.4,9.2-129.7,13.5c-29,2.9-58.1,4.7-87.1,7.5c-37.1,3.5-74.1,7.6-111.2,11.2
			c-28.2,2.7-56.5,5.1-84.7,7.5c-19,1.6-37.9,3.3-56.9,4.7c-4.6,0.3-6,2.6-6,6.8c0.1,7.8-0.7,15.7,0,23.4c1,11.2-3.3,19.5-11.9,26
			c-1.2-2.8-4-5.9-3.5-8.3c1.7-8.5-1.8-10.7-9.6-10.7c-7.5,0-5.1-5.8-4.7-10.2c1-1.2,1.9-2.5,3-3.5c5.5-5.2,4.3-12,1.5-17
			c-2.3-4.1-8.8-2.7-12.9-1c-7.8,3.3-1.9,9.5-1.6,14.2c0.2,2.8,2.8,5.3,4.3,8c0.1,10.1-4.6,11.2-12.5,6.9
			c-7.1-3.9-14.2-7.6-21.3-11.3c1.6-12.7,0.2-25.9,6.9-37.7c6-10.5,13.9-18.2,26.8-17.5c17.9,0.9,22.8-3.2,23.4-21.6
			c1.5-46.9,3.1-93.7,4.4-140.6c2.5-91,4.9-182,7.3-273c1.7-66,3.3-132.1,5-198.1c0.4-16.8,0.9-33.6,1.3-50.4
			c0.2-1.1,0.4-2.2,0.6-3.2c0-2.7-0.1-5.3-0.1-8c0.1-1.2,0.1-2.5,0.2-3.7c1.3-48.1,2.5-96.3,3.8-144.4c6.2-0.3,5.3-4.8,6.3-9.1
			c4.3-18,2.4-36.4,3.7-54.6c1.5-21.1,6.4-43.6-6.2-63.7c12.8,5.7,16.3,17.1,15.9,29.4c-0.3,10.1,2.4,13.7,13,13.1
			c31.4-2,62.7,2.7,94.1,2.2c4.1,0.9,7.7-3.1,12.2-0.6c2.2,1.2,4.2-0.7,5.1-3.2c0.7-2.1-2.8-6,1.9-6.2c4.2-0.3,3.7,3.4,4.1,6.5
			C950.3,67.2,952.6,68.4,955.2,68.4z M925.9,397.3c3.4-13.3,3.9-26.7,0-40c-0.1-4.7-0.1-9.4-0.2-14.1c-13.9-4.4-11.6-15.4-10.8-26
			c0.2-3.1-2-5.4-5.2-4.8c-12.4,2.4-23.4-4.9-35.4-4.9c-3.3-6.4-6.5-6.6-9.5,0.2c-5.5-0.9-8.1,1.3-7.4,7c-3.4,0.6-5.8,2.6-6.8,5.8
			c-3.7,11.8-9.4,23-11.4,35.3c-4.2,26-6.9,52.3-8.7,78.6c-1.3,18.6,8.1,34.9,13.6,52c1.1,3.3,4.1,4.5,7.6,4.4
			c0,2.2-0.1,4.3-0.1,6.5c-0.2,0.3-0.6,0.7-0.6,1c0.1,0.7,0.5,1.4,0.8,2.1c10.2,4,20.5,2.5,30.3-1c5.8-2.1,11.6-2.8,17-1
			c9.9,3.3,11.3-2.5,11.9-9.6c0.6-6.1,0.8-12.3,1.2-18.5c10.5,2,13.3-3.1,13.1-12.8C925,437.4,925.7,417.3,925.9,397.3z M939,480.6
			c-0.1,1.2-0.3,2.3-0.4,3.5c-0.2,3.1-0.5,6.2-0.6,9.4c-3.8,139.1-7.5,278.1-11.2,417.2c-0.8,30.5,0.7,31.5,31.9,23.9
			c10.6-1.5,21.4,5.1,31.9-1l0.9,0.3l0.9,0c8,2.1,8.6-3,9.2-9c0.6-5.6-2.5-15.1,5.3-15.3c9.1-0.2,2.7,10.1,5.5,14.9
			c-1,5.1,2,5.5,6,5.5c8.5-0.1,16.8-0.8,25.1-2.5c10.6-0.5,21.2,1.6,31.7-0.9l0.8,0l0.7-0.3c13.9,1.9,7.8-9.1,8-14.2
			c0.3-8.9,2.6-11.8,11.7-10c9.5,1.9,19.3,2.6,28.9-0.3c2.4-0.2,4.8-0.5,7.2-0.7c6.3-2.5,8-7,8.5-13.8c1.6-21.1-0.5-42.3,1.8-63.5
			c3-26.7,1.3-53.8,2.4-80.7c2-48.7,2.4-97.4,5-146.1c0.7-13.3-0.3-26.6,1-39.8c0.5-5.6-7.1-9.8-3.1-13.4c5.9-5.4,4-11.5,4.2-17.3
			c1.4-47.6,2-95.3,3.7-142.9c2.2-60.5,4.1-121.1,4.8-181.6c0.1-8.4-3.6-11-11.3-10.8c-9,0.2-18-0.2-27-0.4
			c-15.9-0.4-16.3-0.5-15.9-16.3c0.3-8.7-2.4-12-11.4-12.8c-8.8-0.8-18.6,1.1-26.2-5.6c-1.5-0.1-3.1-0.3-4.6-0.4
			c-32.4-0.9-32.4-0.9-30.8,32.1c-7.4,2.9-10.1-0.1-9.7-7.4c0.2-3.5-0.3-7,0.2-10.5c0.9-7.1-1.9-9.5-9-9.5c-8.8,0-17.9,2-26.3-2.5
			c-9.4-7.7-20.6-2.3-30.8-4.2c-6.6-1.2-8.6,1.5-8.8,8.3C945.8,268,942.4,374.3,939,480.6z M1172,956.8c0,11.7,4.8,19.8,11.9,19.8
			c10.3,0,23.3-12.1,23.4-21.8c0.1-9.6-10.7-20.1-20.8-20.1C1178.6,934.7,1172.1,944.6,1172,956.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M796,299.2c0,2.7,0.1,5.3,0.1,8
			c-1.2,0.9-1.5,1.9-0.6,3.2c-0.4,16.8-0.9,33.6-1.3,50.4c-1.7,66-3.3,132.1-5,198.1c-2.4,91-4.8,182-7.3,273
			c-1.3,46.9-2.9,93.7-4.4,140.6c-0.6,18.4-5.5,22.6-23.4,21.6c-12.9-0.7-20.8,7.1-26.8,17.5c-6.7,11.8-5.4,25-6.9,37.7
			c-6,13.9-17.5,13-29.5,11.1c-33.9-5.3-67.8-10.4-101.8-15.5c-81.8-12.3-163.6-24.5-245.4-36.8C262.7,996,181.7,983.7,100.5,972
			c-34.3-4.9-34.4-4.2-35.5-39.5c-0.6-18.4-11.6-36.9-26.1-39.2c-14.1-2.3-17-10.5-16.7-22.1c0.5-21.5,1.3-42.9,1.9-64.4
			c2.6-98.4,5.2-196.8,7.8-295.2c1.6-60.9,3.3-121.8,4.7-182.8c0.1-3.9,2.3-8.7-2.3-11.8c0.1-3,0.1-6,0.2-9c5-5.9,3.5-13.2,3.7-19.9
			c1.3-56.3,2.3-112.5,3.3-168.8c0.1-5.1,0.4-10.2,2.2-15c1.8-4.7,5-7.5,10.3-8.3c21.5-3,31.8-14,34-35.8c0.7-7,0.8-14.1,2.1-20.9
			c1.6-8.4,6.8-12.5,15.9-12c47.2,2.7,94.5,1.2,141.7,2.9c54.7,2,109.4,1.1,164.1,2.4c98.4,2.3,196.9,3.5,295.4,5
			c37.3,0.6,37.1,0.4,40.1,37.7c1.5,18.6,11.3,40.6,37.2,40.1c8.9-0.2,13.3,7.1,14.3,15.8c0.8,6.6-1.2,13.3,1.3,19.8
			c-1.3,48.1-2.5,96.3-3.8,144.4C794.6,296.6,794.4,297.8,796,299.2z M590.7,327.2c1-1.3,2-2.7,3.1-4c6.4-8.3,12.8-16.5,19.1-24.8
			c4.7-6.2,5-13,0.8-19.5c-7.5-11.7-15.3-23.3-22.9-34.9c-0.8-2.9-2.4-5.2-4.8-7c-4.6-5.1-8.1-3.5-11.1,1.8
			c-3.9,3.4-6.8,7.5-9.1,12.1c-4.9,4.7-8.9,10-11.9,16c-0.7,0.9-1.4,1.7-2.2,2.6c-1.7,2.2-3.3,4.5-5,6.7c-5.9,6.2-5,12.4,0.1,18.5
			c1,1.4,1.9,2.9,2.9,4.3c0.6,0.9,1.3,1.9,1.9,2.8c3,5,6,10,8.9,15.1c2,3.5,4.1,2.6,6.3,0.2c7.3,5.7,15.1,6.9,23.4,2
			c0.6,0.8,1.2,1.5,1.9,2.3c-1,1.4-2,2.8-2.9,4.3c-1,1-2.1,2-0.4,3.1C588.8,328.9,590,327.7,590.7,327.2z M525.5,262
			c0.9,2.9,2.2,5.4,4.9,7c3.8,5,7.5,3,10.4-0.7c6.7-8.5,13-17.2,19.6-25.8c2.9-3.8,2.5-6.3-2.7-7c3.9-10.5,4.3-21,0.2-31.6
			c8.7,5.4,12.4,13.3,10.9,24.7c6.5-6.1,4.7-11.5,1.4-16.5c-7.6-11.7-15.6-23.1-23.4-34.7c-7.7-13.7-10.5-13.9-18.7-1.8
			c-9.9,10.7-18.5,22.4-25.9,35c-7.1,7.9-2.4,14.1,2.8,20.3c0.6,0.9,1.2,1.9,1.9,2.8c3.5,7.8,8.3,14.8,13.9,21.3
			C522.4,257.3,524,259.6,525.5,262z M497.7,353.1c0.4,3.1,2,5.5,4.4,7.4c0.9,1.4,1.9,2.9,2.8,4.3c3.5,7.8,8.3,14.7,13.8,21.2
			c0.9,1.5,1.9,2.9,2.8,4.4c3.7,5.3,4.9,16,15.3,7.2c7.4-7.7,13.5-16.3,19.3-25.3c2.7-3.2,5.5-6.3,8.2-9.6
			c5.9-7.3,8.2-14.8,0.7-22.6c-5.8-11.1-12.6-21.5-20.4-31.2c-1.6-2.3-3.1-4.6-4.7-6.9c-6.6-10.3-10.6-4.6-14.3,2.4
			c-0.7,0.8-1.4,1.7-2,2.5c-9.7,11-19.1,22.2-25.9,35.3C495.1,345.9,495.2,349.5,497.7,353.1z M455.2,287.7
			c1.7,4.4,4.1,8.3,7.5,11.6c3.5,7.7,8.1,14.8,13.7,21.3c1,2.7,2.2,5.3,4.8,7.1c3.9,8.7,9,7.6,13.9,1.3
			c8.1-10.4,15.7-21.1,23.6-31.7c5.5-6,11.1-12,4.8-20.8c-7-13-15-25.4-24.1-37.1c-7.5-12.6-10.2-12.6-18.2-0.5
			c-7.9,8.7-15.2,17.9-20.9,28.2C454.1,272.8,449,279,455.2,287.7z M752.2,161.8c9.3,0.2,18.7,0.4,28,0.6c6,2,4.9,6.8,5,11.5
			c0.7,38.4-3.8,76.7-2.1,115.1c-0.3,14.8-0.5,29.5-0.8,44.3c-1.9,2.8-1.6,6-1.7,9.1c-0.7,25.8-1.5,51.6-2.1,77.4
			c-0.9,34.8-1.8,69.6-2.2,104.4c-0.1,7.2-2.1,9.9-9.4,8.8c-6.9-1.1-14-1.5-21-2.1c-2.7-0.2-5.7-0.5-5.9,3.8
			c9.7,0.9,19.5,1.1,29.1,2.8c9.2,1.6,11.5-1.7,11.4-10.4c-0.2-17.2,0.7-34.4,1-51.6c1-47.3,2-94.5,3-141.8
			c0.2-14.9,0.3-29.8,0.5-44.6c0.7-7.7,1.6-15.5,2.1-23.2c1.4-26.1-0.5-52.2,1.9-78.3c0.8-8.8,3.2-18.3-1.7-27
			c-11.6-0.4-23.1-0.8-34.7-1.2c-6.3-2.3-12.8-1.7-19.2-1c-18.7-0.7-37.4-1.3-56-2c-4.8-1.3-9.6-1.1-14.4-0.4
			c-18.7-0.6-37.3-1.2-56-1.8c-5.5-1.5-11.1-1.3-16.7-0.6c-14.7-0.3-29.5-0.6-44.2-1c-33.7-3-67.5-3.4-101.3-3.8
			c-15.5-0.4-31-0.8-46.5-1.2c-6.4-2.3-12.9-1.6-19.4-1c-14-0.3-28-0.7-41.9-1c-30.5-3-61.1-3.3-91.8-3.4
			c-19.4-0.4-38.8-0.7-58.3-1.1c-1.7-0.7-3.5-1.8-5.2-1.9c-22.5-0.4-44.9-0.7-67.4-1c-18.2-3.1-36.7-0.7-54.9-2.3
			c-9.9-0.9-12.8,2.7-12.9,12.3c-0.3,33.2-1.4,66.4-2.3,99.6c-1.5,61-3.2,121.9-4.6,182.9c-0.3,14.8-0.1,29.6-0.1,45.1
			c4-2.6,3-5.6,3.1-8.1c0.9-28.1,1.7-56.3,2.4-84.4c1.8-78.2,3.7-156.3,5.1-234.5c0.1-7.8,2.5-10.1,10.1-9.6
			c17.9,1.2,35.8,2.8,53.8,1.5c24.3,0.2,48.7,2.9,73,0.2c9.8,9.1,21.5,4,32.4,4.2c0.8-0.4,1.5-0.8,2.3-1.2c7.7,0.3,15.5,0.7,23.2,1
			c30.5,4.2,61.2,3.7,91.8,3c8.4,3.2,17.1,3.4,25.8,1.7c2.4,0.1,4.9,0.8,6.9-1.1c1.6,0,3.1,0.1,4.7,0.1c7.7,3.1,15.7,1.8,23.6,1.4
			c15.5,1,30.9,5.6,46.5,1.2c33.7,2.6,67.5,3.7,101.3,3.7c7.9,0.1,15.7,0.3,23.6,0.4c3,1.9,6.1,1.9,9.2,0.2
			c3.8,0.2,7.5,0.3,11.3,0.5c5.5,2.4,11.3,1.5,17,1.1c18.6,0.6,37.2,1.2,55.8,1.8c4.8,1.6,9.6,1.3,14.5,0.7
			c15.6,0.3,31.1,0.6,46.7,0.9c0.7,1,1.6,1.3,2.5,0.2c2.2,0.2,4.5,0.4,6.7,0.6C739.3,162.9,745.8,162.4,752.2,161.8z M411.9,298.4
			c2.6-13.4,2-26.8,1.4-40.3c0.2-7.4,0-14.8,0.8-22.2c0.9-8-1.7-10.6-10-10.9c-67.8-2.7-135.5-6-203.3-8.8c-9.9-0.4-23.4-5.9-29,1
			c-5.1,6.3-1.8,19.4-2.1,29.5c-0.5,1.1-1.3,2.1-1.3,3.2c-1,49.1-1.9,98.1-2.8,147.2c-2.9,12.3,2.7,15.5,14.4,16.2
			c64.9,4,129.8,9,194.7,13.5c10.8,0.7,25.2,5.2,31.6,0.1c7.6-6.1,2.3-21.1,3-32.2c1.9-9.3,2.5-18.6,0.6-28
			c0.2-3.8,0.4-7.7,0.6-11.5c1.7-7.9,1.4-15.9,0.7-23.9C411.5,320.3,411.7,309.3,411.9,298.4z M554,424.1
			c-7.3-4.6-15.7-2.7-23.6-3.6c-4.5-0.5-6.2,2.3-6.8,6.1c-8.5,2.7-13.3,8.9-15.9,17.1c-5.3-3.9,0.4-11-6.1-17.4
			c0.6,24.2-3.2,45.9,0.9,67.7c0.7,0.9,1.3,1.8,2,2.7c12.5,6.5,26.3,3.7,39.5,5.7c9.7,1.5,13.4-3.5,13.6-12.7
			c0.3-14.8,1.2-29.6,1.4-44.4c0.1-6.4,2.1-13.3-2.8-19.1C555.4,425.5,554.7,424.8,554,424.1z M617.1,428.8
			c-14.7-6.4-30.6-3.3-45.9-5.1c-2.8-0.3-4.7,2.4-5.6,5.2c-5.3,1.9-3.8,6.5-3.4,10c1.8,16.6-0.1,33.2,0.1,49.9
			c0.2,15,6.7,19.1,21,13.3c2.7,2.4,5.7,3.8,9.3,2.5c1.7,2.9,4.7,2.3,7.3,2.6c18.2,1.7,20.5-0.4,20.6-18.4
			c0.1-12.9,0.5-25.8,0.2-38.7c-0.1-6,2.3-12.5-2.1-18C618.1,430.9,617.6,429.8,617.1,428.8z M494.7,422c-0.8-1.5-2.2-2.7-3.5-1.2
			c-1.6,1.8,0.7,2.3,1.8,3.1c0.9,8,1.9,16-0.9,23.8c-2.8-11-6.7-21.1-18.6-25.4c2.4-3.3,2.6-5.9-2.4-6.2c-4.7-0.3-9.4-0.4-14.1-0.6
			c-14-0.4-16.3,1.6-16.7,15.7c-0.4,16.4-0.5,32.8-0.6,49.2c0,4.2-0.7,8.8,3.8,11.5c15.1,5.7,31,4.8,46.7,4.7c0.8,0,1.6-1.6,2.4-2.4
			C498.6,470.4,497.7,446.2,494.7,422z M778.2,579.5c0-5.5-0.6-11,0.1-16.4c1.3-10-1.5-14.1-12.7-14.9c-55.7-4-111.3-8.9-167-13.6
			c-71.2-6-142.5-11.2-213.6-18.1c-53.8-5.2-107.8-6.4-161.6-13c-57.6-7-115.8-8.1-173.5-14.9c-8.6-1-11.5,1.3-11.6,10.4
			c-1.3,69.9-3.2,139.9-5,209.8C32.1,761.6,30.6,814.3,29,867c-0.3,8.5,1.2,14.3,11.1,15.7c11.5,1.6,18.1,10.1,23.4,19.5
			c7.5,13.1,9,27.3,8.1,42c-0.5,8.1,2.7,12.3,10.9,13.5c58.7,8.6,117.4,17.3,176,26c71.8,10.7,143.5,21.5,215.3,32.2
			c70.2,10.5,140.5,20.9,210.7,31.4c24,3.6,24.7,8.9,26.1-22.2c1-23.2,18-46.4,44.4-43.2c9.1,1.1,11.6-2.9,11.8-11.2
			c1.1-41.8,3.4-83.6,3.7-125.4c0.3-46.9,2-93.7,3.5-140.5C775.3,663,774.2,621.2,778.2,579.5z M140.7,37.9
			c-10.6,0-21.2,0.7-31.7-0.2c-10.5-0.8-14.4,3.6-13.8,13.4c0.2,2.7,0,5.5-0.2,8.2c-1.6,27-13.3,40.9-39.6,46.5
			c-7.5,1.6-6.8,7.4-7.1,12.8c-0.4,6.7,4.5,5.9,8.8,6.1c16.4,0.6,32.8,1.4,49.2,1.9c96.1,3.1,192.2,6.2,288.3,9.2
			c51.2,1.6,102.4,3.1,153.5,4.7c76.6,2.4,153.1,4.8,229.7,7.2c5.9,0.2,12.2,1.4,11.8-8.1c-0.3-7.4-1.1-13-10.7-13.1
			c-15.7-0.1-26.1-9-33.5-22.1c-7-12.6-9.8-26.4-9-40.7c0.6-10.3-2.4-14.5-13.7-14.7c-59.4-0.8-118.8-3-178.2-3.8
			c-83.3-1.1-166.5-2.7-249.8-4.5c-29.6-0.6-59.2-0.5-88.8,0.2C184.5,41.5,162.5,38.5,140.7,37.9z M71.7,476.1
			c-0.1,1.4-0.2,2.7-0.3,4.1c74.4,5.9,148.8,11.9,223.2,17.8c0.1-1.3,0.2-2.7,0.3-4C220.5,488.1,146.1,482.1,71.7,476.1z
			 M642.2,526.3c0.1-1.3,0.1-2.6,0.2-4c-45.2-3.5-90.4-7-135.6-10.6c-0.1,1.2-0.2,2.4-0.3,3.6C551.7,519.1,597,522.7,642.2,526.3z
			 M408.4,507.4c-9.4-6.5-97.1-12.7-105.9-8.4C338.6,501.9,373.5,504.6,408.4,507.4z M731,531.1c-28.7-6.1-70.3-7.4-75.4-3
			C681.2,527.9,705.9,534.3,731,531.1z M495.1,514.5c0.1-1.3,0.3-2.5,0.4-3.8c-24.4-1.9-48.9-3.9-73.3-5.8c-0.1,1.2-0.2,2.4-0.3,3.6
			C446.3,510.5,470.7,512.5,495.1,514.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M800,151.1c-2.4-6.5-0.5-13.2-1.3-19.8
			c-1-8.7-5.4-16-14.3-15.8c-25.9,0.5-35.7-21.5-37.2-40.1c-2.9-37.4-2.7-37.2-40.1-37.7c-98.5-1.5-196.9-2.7-295.4-5
			c-54.7-1.3-109.5-0.4-164.1-2.4c-47.3-1.7-94.5-0.2-141.7-2.9c-9.1-0.5-14.3,3.6-15.9,12c-1.3,6.9-1.4,14-2.1,20.9
			C85.9,82.1,75.6,93,54.1,96c-5.3,0.7-8.5,3.5-10.3,8.3c-1.8,4.8-2.1,9.9-2.2,15c-1,56.3-2.1,112.5-3.3,168.8
			c-0.1,6.7,1.3,14-3.7,19.9c-4.4-5.6-2.3-12.1-2-18c2.3-51.5,3.5-103.1,4.3-154.6c0.5-32.8,1.5-65.6,2.4-98.4
			c0.4-15.8,8.1-25,23.9-25.2c38.3-0.5,76.5,0,114.8,0.4c73.1,0.9,146.2,1.1,219.3,3.4c45,1.4,89.9,0.2,134.8,2.3
			c49.3,2.2,98.5,0.1,147.7,2.3c36.7,1.7,73.5,0.9,110.2,2c4.7,0.1,9.3,1.1,13.9,1.7c12.6,20.1,7.6,42.6,6.2,63.7
			c-1.2,18.1,0.6,36.6-3.7,54.6C805.3,146.3,806.2,150.8,800,151.1z M775.3,63.7c-11.2,4,2.9,10.1-1,15.5c-0.9,1.3,5.3,5.6,9.2,4.9
			c5.1-0.9,9.6-3.7,10.6-9.5c0.6-3.4,0.4-7,0.5-10.5c0.2-6.6-2.8-11.8-9.3-12C778.5,51.8,776.2,57.6,775.3,63.7z M76.5,50.6
			c-0.5-5.9-1.3-13.3-8.5-12.8c-7.4,0.6-8.2,8.5-8,14.8c0.1,5.7,0.5,13.5,8.2,12.9C75.8,64.9,75.7,57,76.5,50.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M34.3,317c4.6,3.2,2.4,7.9,2.3,11.8
			c-1.4,60.9-3.1,121.8-4.7,182.8c-2.6,98.4-5.2,196.8-7.8,295.2c-0.6,21.5-1.4,42.9-1.9,64.4c-0.3,11.6,2.6,19.8,16.7,22.1
			c14.5,2.3,25.5,20.8,26.1,39.2c1.1,35.3,1.2,34.6,35.5,39.5c81.1,11.6,162.1,24,243.2,36.1c81.8,12.2,163.6,24.5,245.4,36.8
			c33.9,5.1,67.9,10.3,101.8,15.5c12,1.9,23.5,2.8,29.5-11.1c7.1,3.8,14.3,7.5,21.3,11.3c7.9,4.4,12.6,3.2,12.5-6.9
			c1.9-0.2,3.8-0.5,5.6-0.7c-0.4,4.3-2.8,10.1,4.7,10.2c7.8,0,11.3,2.2,9.6,10.7c-0.5,2.4,2.2,5.5,3.5,8.3c-2.9,5.6-6.9,7-13.4,6
			c-90.8-14.5-181.7-28.9-272.6-43c-58.5-9.1-117.1-17.3-175.5-26.4c-93.4-14.5-186.8-29.3-280.2-44.1
			c-12.2-1.9-22.1-14.2-21.6-26.2c1.9-45.6,3.2-91.3,3.9-137c0.9-56.6,2.9-113.2,4.5-169.8c1.4-50.3,3.2-100.7,4.6-151
			c1.6-55,3-110.1,4.5-165.1C32.1,322.6,31.3,319.3,34.3,317z M52.8,934c-0.9-6.4-1.9-14.3-8.7-15c-6.6-0.7-7.1,7-7.4,12.3
			c-0.4,6.3,0.4,13.9,7.9,14.5C51.6,946.5,51.3,938.8,52.8,934z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1275.7,903.9c-4.1,4.5-7.9,8.9-14.4,11.1
			c-7.3,2.4-14,6.2-19.7-2c-5.8-8.3-3.9-21,3.2-29.2c7-8,14.1-4.2,20.9-0.7c4,2.1,6.9,6.1,10.3,9.2
			C1275.9,896.1,1275.8,900,1275.7,903.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1295.8,178.2c-7,4.1-13.9,8.1-22.5,8
			c-8.4-0.1-12.4-4.5-12.1-12.2c0.3-8.1-3.7-17.9,9.1-21.7c9.9-2.9,17.3,0.9,25.1,5.4C1296.4,164.5,1296.9,171.3,1295.8,178.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M955.2,68.4c-2.6,0-4.8-1.2-5.1-3.6
			c-0.4-3,0.2-6.7-4.1-6.5c-4.7,0.3-1.2,4.1-1.9,6.2c-0.8,2.5-2.9,4.4-5.1,3.2c-4.4-2.5-8.1,1.5-12.2,0.6
			c1.8-13.5,10.7-18.9,23.3-14.1C957.3,57,952.7,64,955.2,68.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1282.3,774.7c1,0.7,2.1,1.4,3.1,2.1
			c-1.1,0.2-2.2,0.4-3.3,0.6C1282.1,776.5,1282.2,775.6,1282.3,774.7z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M988.6,157.6c8.4,4.5,17.5,2.5,26.3,2.5
			c7.2,0,9.9,2.4,9,9.5c-0.4,3.5,0,7-0.2,10.5c-0.4,7.4,2.4,10.3,9.7,7.4c9.3-4.7,3.9-13,4-19.4c0.2-8.4,2.8-10.9,10.8-10.6
			c6.8,0.2,14,1.9,20.6-1.8c7.6,6.8,17.4,4.9,26.2,5.6c9,0.8,11.7,4.1,11.4,12.8c-0.5,15.8,0,15.9,15.9,16.3c9,0.2,18,0.6,27,0.4
			c7.7-0.2,11.4,2.4,11.3,10.8c-0.7,60.6-2.6,121.1-4.8,181.6c-1.7,47.6-2.3,95.3-3.7,142.9c-0.2,5.8,1.7,11.9-4.2,17.3
			c-4,3.6,3.7,7.8,3.1,13.4c-1.3,13.2-0.3,26.6-1,39.8c-2.6,48.7-2.9,97.4-5,146.1c-1.1,26.9,0.6,54-2.4,80.7
			c-2.4,21.2-0.2,42.4-1.8,63.5c-0.5,6.8-2.2,11.3-8.5,13.8c1.2-22.2,1.2-22.3-13.4-22.3c-5.2,0-12.4-3.4-12.2,7
			c0.1,7.6-1.4,15.4,10.4,14.4c2.6-0.2,5.3,1.1,8,1.7c-9.6,3-19.4,2.3-28.9,0.3c-9.1-1.8-11.5,1.1-11.7,10c-0.1,5.1,5.9,16-8,14.2
			c0.8-1.3,1.9-2.6,2.4-4c1.9-5.6,3.2-11.4-1.1-16.4c-1.9-2.2-4.6-0.7-5.9,1.3c-4,6.1-4.5,12.2,1.4,17.7c0.6,0.5,1.1,1.1,1.7,1.6
			c-10.5,2.5-21.2,0.4-31.7,0.9c-9.9-5.8-20.8-1.4-31.1-3c-2.8-4.8,3.6-15.1-5.5-14.9c-7.8,0.1-4.7,9.7-5.3,15.3
			c-0.6,6-1.1,11-9.1,9c5.2-4,6.4-9.3,5.7-15.5c-0.4-3.4-1.6-6.4-5.3-6.4c-3.7,0-5.3,2.8-5.3,6.3c-0.1,5.3-1.3,10.9,3.2,15.3
			c-10.5,6.1-21.3-0.5-31.9,1c-5.3-3.3-11.7-3.8-17-3.1c-10.4,1.4-11.8-2.9-11.5-11.6c0.7-19.1,3.9-38.1,2.8-57.3
			c-1.5-26,0.3-52.3,1.8-78c1.7-29.8,2.9-59.9,4.2-89.7c3-68.1,4.2-136.3,7.5-204.4c2.1-44.4,4-88.8,4.5-133.2
			c0.6-53.1,4.1-106.2,2.7-159.3c-0.3-10.8-2.9-21.5-2.6-32.5c0.2-6,2.1-8.5,8.2-8.3C969.1,157.5,978.8,157.4,988.6,157.6z
			 M1085.3,219.3c3.1-0.3,6.2-0.3,9.2-0.9c10-2.3,7.7-10.5,6.9-16.9c-1.2-9.4-9.4-5.9-14.9-6c-8.5-0.3-5.6,6.2-5.5,10.8
			c-3.4,8-2.4,15.8,0,24.1C1086.6,227.2,1084.9,222.9,1085.3,219.3z M1019.9,217.4C1019.9,217.4,1020,217.4,1019.9,217.4
			c0.1-3.1,0-6.2,0.1-9.3c0.1-15.1,0.1-15.1-15-15.3c-1.2,0-2.3,0-3.5,0c-24.9-0.3-24.9-0.3-28,24.2c-2.4,18.8-1.5,20,16.9,23
			c3,0.5,6,1.7,9,2c19.8,1.5,20.3,1,20.5-18.8C1019.9,221.2,1019.9,219.3,1019.9,217.4z M1067.1,462.3c3.3-5.2-3.3-8.1-2.3-12
			c4-15.6-5.6-14.9-16.3-15.3c-7-0.2-10.5,0.6-9.7,8.3c0.7,6.2-3.5,14.7,9,14.1C1054.3,457.1,1062.4,453.8,1067.1,462.3z
			 M1039.8,825.1c-11.3-0.1-15.5,3-15.2,11.3c0.3,10.1,6.9,10.5,15.1,11.4c11.7,1.2,13.6-5.3,14-14.2
			C1054.1,822.4,1046.1,825.1,1039.8,825.1z M1063.3,610.7c-2.2-6.3-3.8-11.9-2.4-18.1c0.8-3.5-0.5-7.9-5.1-7.4
			c-7,0.8-15.8-3.9-20.6,2.6c-2.9,3.9-2.7,11.8,0.2,16.8c2.2,3.9,7.8,2.2,12,2.1C1052.8,606.6,1059.1,602.4,1063.3,610.7z
			 M1138,255.1c-8.5-2.6-12.6-0.4-12.6,9.8c0,8.4,1.7,12.1,10.8,11.8c8.5-0.3,16.3,0.7,16.8-11.6
			C1153.4,253,1145.9,254.3,1138,255.1z M1070.8,309.6c2.3-4.1-2.7-5.8-2.1-8.9c2.9-14.9-5.7-16.1-17.3-16c-6.4,0.1-9.1,1.9-7.9,7.7
			c1.1,5.3-5.4,14.3,6.8,14.8C1057.2,307.5,1064.5,305.3,1070.8,309.6z M1123.6,353.7c-2,8.6,0.5,12.5,9.9,12.2
			c9.1-0.2,17.3,0.2,17-12.5c-0.3-17.6-14.1-6.9-21.1-9.5C1121.1,340.9,1124.5,349.8,1123.6,353.7z M1058,761
			c2.4-5.7-3.9-7.5-2.5-11.9c4-13.1-2.6-15.7-13.8-13.7c-3.9,0.7-8.8-2.9-11.7,2.2c-5.3,9.4,0.8,19.5,11.6,19.2
			C1047.4,756.7,1053.8,753.9,1058,761z M1063.6,579.7c-0.9-3.4-2-6.3-2.3-9.2c-1.7-16.1-1.6-15.9-18.5-15.7
			c-11.7,0.1-5.5,8.7-6.9,13.6c-1.5,5-1,8.9,6.4,8.9C1049.1,577.3,1056.5,574.7,1063.6,579.7z M1055.9,819.1c1.2-3.1-1.8-5-1.4-6.9
			c3.1-16.3-4.9-18.3-18.9-17.5c-12.2,0.7-5.2,8.8-7.1,13.2c-2.1,4.7-2.2,8.9,5.4,9.2C1041,817.4,1048.5,814.9,1055.9,819.1z
			 M1058.3,276.7c9.8,3,14-4.3,14.3-12.6c0.4-9.2-7.5-8.8-14.3-8.1c-1.5,0.2-3.2,0-4.6-0.5c-7.7-3.2-9.4,0.9-10.1,7.5
			C1042.4,276.2,1042.6,276.6,1058.3,276.7z M1054.2,877.6c-0.7-2.6-2.4-5.4-1.9-7.6c3.2-15.1-5.2-15.6-16.7-15.2
			c-7.5,0.3-8.5,2.6-9.3,9.2c-1.3,10.7,3.3,14,12.8,13.7C1043.9,877.4,1048.9,877.6,1054.2,877.6z M1132.6,454.9
			c9.1,1.8,13.6-2.4,16.3-10.8c1.6-5-1.2-9.2-3.5-9.7c-7.4-1.4-15.9-3-22.4-0.4c-4.6,1.8-2.3,10.8-1.7,16.4
			C1122,457.4,1128.6,454,1132.6,454.9z M1043.3,675.8c-11.5-0.2-11-0.1-11.8,9.8c-0.6,8.2,0.9,12,10.4,11.7
			c14.9-0.5,16.2-1,16.3-13.9C1058.3,670.9,1049.6,675.8,1043.3,675.8z M1037.8,906.2c8.4,1.2,14.2,0.4,14.3-11.4
			c0.1-16.4-11.3-7.6-17.7-9.4c-9.8-2.7-11.2,5-11.4,11.3C1022.7,906.6,1030.1,907.1,1037.8,906.2z M1066.3,491.4
			c-1.1-4.7-2.8-7.9-2.3-10.6c2.6-13.9-4.3-16.6-16.1-15.3c-4.6,0.5-10.2-1.6-9.3,6.1c0.7,5.9-4.5,14.6,7.7,15.8
			C1052.3,487.9,1059.4,484.6,1066.3,491.4z M1140.3,195.9c-8.1-2-16.1,0-16.3,8.9c-0.1,5.8,4.6,12.4,14,11.4
			c8-0.9,16.9,1.8,17-11.1C1155.1,193.9,1147.5,195.6,1140.3,195.9z M1126.5,700.9c-10-3-13.5,0.7-13.6,11.8
			c-0.1,10.5,5.6,9.5,12.3,10c9.4,0.6,14.1-1.9,14.2-12.6C1139.6,699.8,1134.4,699.6,1126.5,700.9z M1044.7,666.8
			c14.3-0.1,18.3-2.9,16.1-14.4c-2.3-12.1-13.4-5.5-20.4-6.5c-11.3-1.6-7.5,8.5-8,13.9C1031.1,671.3,1040.8,664.8,1044.7,666.8z
			 M1044.6,705.4c-10.4-3-14.3,0.8-13.8,12c0.3,7.9,2.5,10.2,10,9.7c8.7-0.6,16.7,0.1,16.5-13.1
			C1057.1,703.5,1052.2,703.6,1044.6,705.4z M1122.2,730.5c-6.1-1.7-10.4-0.5-10.8,10c-0.4,10.3,4.3,11,12,11.7
			c10.6,1,15.4-3.3,15.5-13.7C1139.1,726.1,1130,731.7,1122.2,730.5z M1123.4,820.2c-9.7-2.7-15.2-1.4-14.6,11.1
			c0.9,18.2,14.8,7.7,21.8,9.7c6.3,1.8,4.3-8.8,5.2-13.5C1137.6,817.3,1130.2,818.3,1123.4,820.2z M1123,781.4
			c11.7,3.4,14.5-3.3,15.1-12.7c0.9-12.4-8.1-6.7-13.5-7.5c-2.7-0.4-5.3-1.1-7.9-1.7c-2.1-0.5-4.6-0.2-4.8,1.9
			c-0.5,5.8-1.4,11.9,0.1,17.3C1113.4,783.9,1119.4,780.6,1123,781.4z M1130.5,522.6c-10.4-3.8-12.6,2-12.1,11.2
			c0.3,5.8-1.4,12.4,8.3,10.8c7.4-1.2,16.7,2.2,17.2-11.1C1144.3,523,1140.2,520.3,1130.5,522.6z M1049.6,426.4
			c9.3,0.4,17.2,0.6,16.7-12.6c-0.5-14.2-10.7-7.6-16.9-8.9c-11.3-2.4-8.6,6.2-9.1,12.3C1039.6,424.5,1041.6,428.8,1049.6,426.4z
			 M1053.8,366.2c10.2,2.8,14.8-2.3,14.2-12.4c-1-15-13.2-6.3-19.9-8.4c-9.8-3-5.8,6-6.7,10.3C1039,366.3,1045.2,367.4,1053.8,366.2
			z M1128.5,603.8c3.7-3.1,11.6,3.6,13.5-6c1.1-5.3,4.1-12.7-1.2-14.8c-6.3-2.5-15.1-2.8-21.3-0.3c-4.1,1.7-3.7,10.4-3.1,16.2
			C1117.4,606.5,1123.9,602,1128.5,603.8z M1129.1,611.3c-13.1,0-12.7,0-13.3,11.2c-0.4,7.9,2.4,9.9,10.1,9.6
			c9.1-0.3,17.2,0.2,17.2-12.5C1143.2,608.2,1135.1,611.9,1129.1,611.3z M1047.6,546.7c14.4-0.1,14.4-0.1,14.3-5.2
			c-0.1-15.9-0.3-15.9-19.2-16.7c-7-0.3-7.2,3.9-7.3,8.8C1035.3,542.9,1036.9,550.3,1047.6,546.7z M1059,195.5
			c-6.8-0.5-14.1-3.1-13.6,9c0.4,8,1.2,11.8,10.5,11.6c9.2-0.2,16.6,0.2,16.7-12.2C1072.7,192.6,1065,195.9,1059,195.5z
			 M1133.5,463.7c-9.1-3.3-13.4-0.6-13.3,10.6c0.1,9,3.7,9.8,11.1,9.8c8.9,0,16.2-0.4,16.2-12.1
			C1147.5,461.7,1141.4,462,1133.5,463.7z M1138.1,305.4c8.6,1.9,14.6-1.1,14.2-11.5c-0.5-15-12.6-7.6-19.5-9.2
			c-10.4-2.4-6.6,6.4-7.5,11.3C1123.4,306.8,1130.6,305.9,1138.1,305.4z M1127.2,692.4c10.5,3.4,13.9-4.2,13.3-13
			c-1.1-15.3-14-6.3-21-7.9c-8.9-2.1-4.5,7.2-5.4,11.6C1111.7,693.8,1119.4,692.6,1127.2,692.4z M1136.2,374.4
			c-7.2-1.3-14.4-3-13.5,9.4c0.5,7.1,0.5,11.9,9.7,11.1c8.3-0.7,17.4,1.6,17.5-11.7C1149.9,372.8,1143.4,373.6,1136.2,374.4z
			 M1049.1,496c-8.1-2.1-13.5,0.4-14.3,10.2c-0.9,11.9,8.9,11.4,15.5,11.8c7.5,0.5,12-4.1,12.4-13
			C1063.2,493.7,1055.9,496.1,1049.1,496z M1057.2,789.6c-1.2-4.4-2.9-7.2-2.5-9.5c2.3-12.3-2.9-15-14.6-14.9
			c-8.9,0.1-11.1,2-11.5,10.7c-0.4,9.4,3.2,11.4,11.4,11C1045.2,786.8,1050.8,784.8,1057.2,789.6z M1041.2,384.2
			c-3.8,12,3.1,12.7,11.7,12.7c8,0,14,0.3,13.8-11.1c-0.2-17.7-14-8-20.8-10.6C1038.3,372.3,1042.1,381.1,1041.2,384.2z
			 M1049.2,636.4c9,3.6,9.9-2.5,10.3-11.1c0.7-15.5-10.8-8-17.2-9.6c-9.4-2.4-7.9,4.6-8.8,10.4
			C1031.7,638.6,1040.3,636.4,1049.2,636.4z M1139.4,226.5c-8-3.1-12.4-1.6-12.4,9c0,8.5,1.9,12.2,10.8,11.2
			c7.3-0.8,15.6,2.4,15.7-10.6C1153.7,224.7,1148.2,224.4,1139.4,226.5z M1137.4,315.3c-7-1.7-13.7-2.1-12.6,9.2
			c0.6,5.7-1.6,12.5,8.3,11.2c7.7-1.1,18,3.5,17.9-11.3C1151,313.6,1145,314.4,1137.4,315.3z M1057.5,246.5c8,1,14,0.9,13.9-10.6
			c-0.2-15.9-12-8.2-18.6-10.1c-9.2-2.7-6.7,5.3-7.6,10.2C1043.3,246.5,1049.5,247.6,1057.5,246.5z M1129.4,572.5
			c9.1,1.3,15.2-0.5,14.7-11.9c-0.7-15.3-12.1-6.8-18.4-8.3c-10.2-2.4-6.8,6.4-7.6,11.4C1116.6,573.4,1122.2,574,1129.4,572.5z
			 M1130.6,513.5c8.9,0.9,15.7,0.4,15.6-11.4c0-14.6-10.6-7.4-16.7-8.9c-9.8-2.4-8.9,4.4-9.5,11
			C1119.3,513.2,1123.1,515.5,1130.6,513.5z M1126.9,641.4c-7-1.4-12.3-0.6-11.5,9.4c0.5,6.5-0.6,12.7,9.3,11.4
			c7.7-1,16.8,2.1,16.9-11.3C1141.7,639.3,1134.2,641.5,1126.9,641.4z M1134,404.4c-7.6-2.1-12.4-0.4-11.6,9.9
			c0.5,6.3-0.1,11.2,8.8,10.8c8.8-0.4,17.2,0.7,17.3-12C1148.6,402.3,1141.3,404.4,1134,404.4z M1121.8,810.7
			c5.3-0.7,12.8,2.3,13.8-9.2c1-12-5.5-10.4-12.9-10c-2.5,0.1-5.1-1.5-7.7-2c-2.3-0.4-4.5-0.5-5.9,2.2
			C1104.3,800.9,1110.2,810.6,1121.8,810.7z M1054.9,336.3c11.6,0,12.7-1.3,13.6-10.4c1.1-10.6-6.1-8.1-11.9-8.6
			c-2.6-0.2-5.1-1.7-7.7-2.2c-2.3-0.5-4.4-0.4-5.7,2.3C1038.5,327.3,1044,336.3,1054.9,336.3z M1108.7,858.6
			c-0.4,6.8-1.6,12.8,8,12.3c9-0.5,14.1-5.3,12.7-16.3c-1.1-9.5-10.2-4.5-15.2-5.6C1105.1,847,1110.4,855.8,1108.7,858.6z
			 M941.2,925.7c0.6-5.6,1.1-11.2,1.9-16.8c0.4-2.5,1.2-4.8-2.6-4.5c-2.6,0.2-5.1,2.2-4.1,4.5C938.8,914.3,936.8,920.3,941.2,925.7z
			 M1100.8,619.7c-0.7-1.1,0.2-4.4-3.5-3.4c-2.2,0.6-4.2,2-4.1,4.8c0.1,3.2,2.3,5.1,5.1,4.9C1101.3,625.8,1100.5,622.7,1100.8,619.7
			z M1029.4,211.8c1.1-5.8,2.3-9.6-1.1-13.5C1023.6,202.7,1023.4,206.5,1029.4,211.8z M1017.4,558.7c-2.3,5.1-2.7,8,0.3,12.2
			C1019.5,566.2,1019.6,563.3,1017.4,558.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M912.1,470.2c-0.4,6.2-0.6,12.3-1.2,18.5
			c-0.7,7.1-2,13-11.9,9.6c-5.3-1.8-11.2-1.1-17,1c-9.8,3.5-20,5.1-30.3,1c0.1-0.3,0.4-0.7,0.4-1c-0.1-0.7-0.4-1.4-0.6-2
			c0-2.2,0.1-4.3,0.1-6.5c0.3-2.3,0.2-4.7,0.9-6.9c3.2-10.5-6.9-17.7-6.9-27.2c0.1-32.8,0.5-65.6,1.4-98.4
			c0.1-5.2-0.9-11.6,4.9-16.5c0,17.4,0.1,33.7-0.1,50c0,4.1-1,8.5,5.1,7.9c9.7-0.8,19.8,6.5,29-1.2c1.2-1,0.3-4.5,0.4-6.8
			c0.2-9.8,0.2-19.5,0.7-29.3c1-22.5,6.2-21.4-19.6-21.7c-7.9-0.1-13.4-0.4-10.5-10.9c1.3-4.7,0.4-10.1,0.4-15.1
			c-0.7-5.7,1.9-7.9,7.4-7c3.2-0.1,6.3-0.1,9.5-0.2c12,0,23,7.3,35.4,4.9c3.2-0.6,5.4,1.7,5.2,4.8c-0.7,10.6-3.1,21.6,10.8,26
			c-3.1,0-6.2,0.1-9.2,0.1c-3.9,1.4-2.1,4.2-1.5,6.7c-0.1,3.9-0.2,7.8-0.3,11.7c0,0.8-0.1,1.6-0.1,2.5c0,1.5,0,3,0,4.5
			c-1,30.3-2,60.6-2.9,90.8c-0.1,3.2,0.7,6.2,2.8,8.7C913.7,468.9,912.9,469.5,912.1,470.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M988.6,157.6c-9.8-0.1-19.5-0.1-29.3-0.5
			c-6.1-0.2-8,2.3-8.2,8.3c-0.3,11,2.3,21.7,2.6,32.5c1.4,53.1-2.1,106.2-2.7,159.3c-0.5,44.4-2.4,88.9-4.5,133.2
			c-3.3,68.1-4.5,136.3-7.5,204.4c-1.3,29.8-2.5,59.9-4.2,89.7c-1.5,25.7-3.3,52-1.8,78c1.1,19.2-2.1,38.2-2.8,57.3
			c-0.3,8.7,1.1,13,11.5,11.6c5.3-0.7,11.7-0.2,17,3.1c-31.2,7.6-32.7,6.6-31.9-23.9c3.7-139.1,7.5-278.1,11.2-417.2
			c0.1-3.1,0.4-6.2,0.6-9.4c0.1-1.2,0.3-2.3,0.4-3.5c3.4-106.3,6.9-212.6,10.1-319c0.2-6.7,2.2-9.4,8.8-8.3
			C968,155.2,979.2,149.9,988.6,157.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M857.3,314.7c-0.1,5.1,0.9,10.4-0.4,15.1
			c-3,10.5,2.5,10.8,10.5,10.9c25.9,0.3,20.6-0.8,19.6,21.7c-0.4,9.8-0.5,19.5-0.7,29.3c-0.1,2.3,0.8,5.8-0.4,6.8
			c-9.2,7.7-19.3,0.3-29,1.2c-6.1,0.5-5.2-3.8-5.1-7.9c0.2-16.3,0.1-32.6,0.1-50c-5.8,4.9-4.8,11.3-4.9,16.5
			c-0.9,32.8-1.3,65.6-1.4,98.4c0,9.4,10.1,16.6,6.9,27.2c-0.7,2.2-0.6,4.6-0.9,6.9c-3.4,0.1-6.5-1.1-7.6-4.4
			c-5.5-17.1-14.9-33.4-13.6-52c1.8-26.3,4.5-52.5,8.7-78.6c2-12.3,7.7-23.5,11.4-35.3C851.5,317.3,853.9,315.3,857.3,314.7z
			 M868.8,375.6c6.8,1.5,9.7,0,10-5.1c0.2-5.5-2.6-7.7-7.9-6.7c-4,0.7-10.1-2.3-10.4,5.3C860.1,376.9,866.4,375.3,868.8,375.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1172,956.8c0-12.2,6.5-22,14.5-22.1
			c10,0,20.9,10.5,20.8,20.1c-0.1,9.8-13.1,21.8-23.4,21.8C1176.8,976.6,1172,968.5,1172,956.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1125.2,901.7c-2.7-0.6-5.4-1.9-8-1.7
			c-11.8,1-10.3-6.7-10.4-14.4c-0.2-10.4,7-7,12.2-7c14.6,0,14.6,0.1,13.4,22.3C1130,901.2,1127.6,901.5,1125.2,901.7z"/>
		<path fill="#FEFEFE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M760,1053.1c-1.9,0.2-3.8,0.5-5.6,0.7
			c-1.5-2.7-4.1-5.2-4.3-8c-0.4-4.7-6.2-10.9,1.6-14.2c4.1-1.7,10.6-3.1,12.9,1c2.8,5,4,11.8-1.5,17
			C761.9,1050.6,761,1051.9,760,1053.1z"/>
		<path fill="#FEFEFE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M914.7,361.7c0.1-3.9,0.2-7.8,0.3-11.7
			c2.2-1.8,1.5-4.3,1.5-6.7c3.1,0,6.2-0.1,9.2-0.1c0.1,4.7,0.1,9.4,0.2,14.1c-0.1,3.8-0.2,7.6-0.4,11.4c-3.6,0-7.3,0-10.9,0
			c0-1.5,0-3,0-4.5c0.3-0.2,0.9-0.5,0.8-0.7C915.3,362.9,914.9,362.3,914.7,361.7z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M912.1,470.2c0.8-0.7,1.5-1.4,2.3-2.1
			c5.5,0.2,6.8-2.6,7-7.9c0.6-20.9,1.7-41.7,2.7-62.5c0.6,0,1.2-0.2,1.8-0.4c-0.3,20.1-0.9,40.1-0.6,60.2
			C925.4,467.1,922.7,472.3,912.1,470.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M990.5,933.5c-4.5-4.4-3.3-10-3.2-15.3
			c0.1-3.5,1.7-6.3,5.3-6.3c3.7,0,4.9,3,5.3,6.4c0.7,6.1-0.5,11.5-5.7,15.5c0,0-0.9,0-0.9,0L990.5,933.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1075,926.5c-0.6-0.5-1.1-1.1-1.7-1.6
			c-5.9-5.5-5.4-11.6-1.4-17.7c1.3-2,4-3.5,5.9-1.3c4.3,5,3.1,10.8,1.1,16.4c-0.5,1.4-1.6,2.7-2.4,4c0,0-0.8,0.3-0.8,0.3L1075,926.5
			z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1068.9,155.8c-6.6,3.7-13.7,2-20.6,1.8
			c-8.1-0.3-10.7,2.2-10.8,10.6c-0.1,6.4,5.3,14.7-4,19.4c-1.6-33-1.6-33,30.8-32.1C1065.8,155.5,1067.3,155.6,1068.9,155.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1012.2,924.5c10.3,1.6,21.2-2.8,31.1,3
			c-8.3,1.8-16.6,2.5-25.1,2.5C1014.2,930,1011.3,929.5,1012.2,924.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M925.9,397.3c-0.6,0.3-1.2,0.4-1.8,0.4
			c-1.7-9.8,3.5-19.2,1.4-29.1c0.1-3.8,0.2-7.6,0.4-11.4C929.8,370.6,929.3,383.9,925.9,397.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M874.2,307.6c-3.2,0.1-6.3,0.1-9.5,0.2
			C867.8,301,870.9,301.1,874.2,307.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1285,652.2c1.9,1.9,2,3.9,0,5.8
			C1285,656.1,1285,654.2,1285,652.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M795.5,310.4c-0.9-1.3-0.6-2.3,0.6-3.2
			C795.9,308.2,795.7,309.3,795.5,310.4z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M939,480.6c-0.1,1.2-0.3,2.3-0.4,3.5
			C938.7,483,938.8,481.8,939,480.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M851.5,497.3c0.2,0.7,0.5,1.3,0.6,2
			c0,0.3-0.2,0.7-0.4,1c-0.3-0.7-0.6-1.4-0.8-2.1C850.9,498,851.3,497.6,851.5,497.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M796,299.2c-1.6-1.3-1.4-2.5,0.2-3.7
			C796.2,296.7,796.1,297.9,796,299.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M778.2,579.5c-4,41.7-2.9,83.5-4.3,125.3
			c-1.6,46.8-3.2,93.6-3.5,140.5c-0.3,41.8-2.6,83.6-3.7,125.4c-0.2,8.3-2.7,12.4-11.8,11.2c-26.3-3.3-43.3,20-44.4,43.2
			c-1.4,31.2-2,25.8-26.1,22.2c-70.2-10.5-140.5-20.9-210.7-31.4c-71.8-10.7-143.5-21.5-215.3-32.2c-58.7-8.7-117.3-17.5-176-26
			c-8.2-1.2-11.4-5.4-10.9-13.5c0.9-14.6-0.6-28.9-8.1-42c-5.4-9.4-12-17.9-23.4-19.5c-9.9-1.4-11.4-7.2-11.1-15.7
			c1.6-52.7,3.1-105.4,4.5-158.2c1.8-69.9,3.6-139.8,5-209.8c0.2-9.1,3.1-11.4,11.6-10.4c57.7,6.7,115.9,7.9,173.5,14.9
			c53.7,6.5,107.8,7.7,161.6,13c71.1,6.9,142.4,12.2,213.6,18.1c55.6,4.7,111.3,9.6,167,13.6c11.2,0.8,13.9,4.9,12.7,14.9
			C777.7,568.5,778.2,574,778.2,579.5z M193.6,685.7c2.4-6.9,2.4-6.9-3.7-10.6c0.4,4.1,4.7,6.4,3.5,10.3c-3.1,0.5-5.1,2.2-4.1,5.5
			c0.3,0.8,2.9,1.9,3.9,1.4C197.1,690.7,192.6,688,193.6,685.7z M433,620.1c-4.2,5.9-1.6,12.3-0.1,18.6c0-6.2,0-12.4,0-18.7
			c0.7-0.8,1.3-1.6,2-2.4c-0.4-0.2-0.9-0.4-1.3-0.6C433.4,617.9,433.2,619,433,620.1z M529.2,624.6c0.1,1.4,0.8,2.4,2.4,2.3
			c1,22.8,0.4,45.5-2.4,68.2c-1.1,1-2.1,2-3.2,2.9c0.5,0.3,1.2,0.9,1.5,0.7c1.6-0.7,2-2.1,1.6-3.8c6-3,4.3-8.8,4.1-13.5
			c-0.6-18.1,2.9-36.4-1.7-54.4C531.3,625.6,530.6,624.7,529.2,624.6c-11.9-6.9-24.6-6.9-37.5-4.6c-14.3,7-20.2,18.9-21.4,34.3
			c-1.1,15.1,6.8,26.4,14.5,37.9c-17.5,0.9-34.6-4.3-52-4.1c-0.2-1.1-0.3-2.2-0.5-3.3c-0.3,0.1-0.7,0.3-1,0.4
			c0.6,0.9,1.1,1.8,1.7,2.8c2.2,2.6,4.3,6.4,8,5.8c14.5-2.4,28.4,2.6,42.6,2.8c10.5,0.1,20.8,4.6,34.9,0.7
			c-7.4-1.4-11.4-2.2-15.4-2.9c12.3-11.3,18.7-24.6,17-42c-1.2-12.1-8.9-20.1-14.7-29.5C513.3,623.5,521.2,624,529.2,624.6z
			 M714.4,887.6c0.2-2.1-0.1-3.9-2.3-4.8c-1.3-0.8-2.5-2.1-3.8-2.3c-29-3.9-57.9-7.8-86.9-11.6c-3.9-0.5-7.9-0.4-9.2,4.1
			c18.7-0.9,36.9,3.1,55.3,5.8c-11.4,11.9-18.7,25.1-16.2,42.5c1.8,12.6,10,21.3,16.6,31.1c-17.8-1.9-35.6-3.9-53.3-5.8
			c0.6,2.9,1.4,3.7,2.4,3.8c27,2.9,53.9,6.7,80.7,11.5c4.7,0.9,11.1,2.2,14.6-4.1c4-2.3,4.3-6.1,4.3-10.1
			c0.2-16.3-0.1-32.6,1.7-48.9C718.7,894.8,719.3,890.1,714.4,887.6z M568.5,911.5c-4,1.5-4.2,4.8-3.8,8.4c-2.9,1-8-0.6-7,5.1
			c1.1,6.4-4.7,15.4,2.2,18.6c6.2,2.9,3.8,6.2,4.5,9.8c-3.5,1.2-5.5-6-9.6-1.7c-5-18.1-2.1-35.2,7-51.2c-9.5-4.7-12.1-1.2-11.9,8.6
			c0.4,14-0.3,28-0.8,42c-0.2,4.8,1.1,7.6,6.3,7.3c11,2,22,4.1,34.2,6.3c-2.8-6.3-6.9-3.5-9.8-4.5c-5.4-1.7-12.6,0.4-15.5-7
			c5.1-0.2,10.1-0.1,15.2-0.5c11.9-0.9,12.5-2.1,8.3-14.5c0.8-5.7,1.7-11.5,2.5-17.2c1.8-11.6-3.9-15.4-14.6-14.1
			c-2.5-0.5-3.9,0.3-4,3C570.6,910.5,569.6,911,568.5,911.5z M716.8,833.4c2.5-1.1,3.1-3.7,3.1-5.8c0.5-18.6,0.9-37.2,1.1-55.8
			c0-2.8-1.2-5.3-4.2-6.3c-0.4-6.8-5.8-6.2-10.3-6.7c-26.1-3-52.3-6-78.5-8.9c-4.5-0.5-9.5-1.8-12.1,3.9c-2.4,1.2-3.5,3.5-3.6,5.7
			c-0.5,14.8-0.8,29.6-0.9,44.4c-0.2,24.6-0.1,24.7,23.3,28.5c19.9,3.2,39.8,6.9,59.9,8.4c6,0.4,13.9,3.2,19.8-2.6
			C717.1,837.5,717,835.5,716.8,833.4z M565.9,865.6c-12.2-6.5-5.7,7-9.7,9.2c-4.1-2.9,1.1-9.1-4.2-11.9c-0.5,1.4-1.2,2.4-1.3,3.4
			c-0.9,11.3,8,19,18.6,15.9c3.5-1,6-3.8,3.4-8.1c-1.6-2.6-5.6-3.2-5.5-7c1.4-0.2,3.9-0.5,3.3-1.8
			C569.5,863.1,567.4,864.1,565.9,865.6z M526.9,741.9c-0.1,2.1-0.9,4.5,1.5,6c0.2,0.1,0.8-0.2,0.9-0.4c1.1-2.7-0.7-4.1-2.6-5.4
			c-1.4-2.7-3.9-3.7-6.6-4.1c-27.2-3.1-54.5-6.2-81.8-9.1c-4.2-0.4-7.8,0.8-7.7,6.2c-4.7,2-3.9,6.3-4,10c-0.3,16-0.2,32-0.6,48
			c-0.3,9.5,3.7,14.2,13.4,15.4c14.3,1.7,28.4,5.8,42.7,6c9.5,0.1,19.1,5.5,30.9,0c-6.2-1.2-10.3-2-14.3-2.8
			c0.2-2.2,1.9-3.1,3.4-4.4c14.7-12.2,18.9-30.1,12.2-48.1c-2.6-7.1-8.6-11.9-11.3-18.8C511,740.8,518.9,741.4,526.9,741.9z
			 M207.7,767.9c-48.1-5.5-96.2-10.9-145.5-16.6c1.5,2.2,1.8,3.3,2.2,3.4c46.4,5.8,92.8,11.6,139.3,17.3
			C206.9,772.4,207.7,770.5,207.7,767.9c7.9-16.2,4.7-32.8,2.3-49.4c0-3.2,0-6.3-0.1-9.5c0.4-0.3,0.8-0.7,1.2-1
			c-0.2,0-0.6,0-0.7,0.2c-0.2,0.3-0.3,0.7-0.4,1c-3.1,1.9-6.4,2.4-10.1,2c-43.3-5.1-86.7-10-130.1-14.9c-2.1-0.2-5.5-2.5-6.6,2.9
			c28.8,3.2,57.5,5.8,86,9.8c20.3,2.8,41.3,1.6,60.8,9.4C210.1,734.9,211.9,751.6,207.7,767.9z M719.2,643.5
			c-9.8-8.5-21.2-7-32.7-5.1c-2.6,3.3-5,3.5-7.3-0.3c-1.9-4-5.7-3.4-9.1-3.7c-13.9-1.2-27.8-2.5-41.7-3.7c-2.6-0.2-5.5-1.1-6.4,2.7
			c16.9,1.9,33.7,3.9,50.5,5.8c0.1,2.5-1.3,4.4-3,6c-8.9,8.1-11,18.5-11.7,30.2c-0.9,14.9,7.5,25.1,14.5,36.3
			c-18.2,0.6-36-3.1-54-4.8c-0.4-1,1.1-3.2-1.7-2.8c0.6,0.9,1.2,1.8,1.8,2.7c1.3,3.5,4.1,4.3,7.4,4.6c27.5,3,55,6,82.6,9.1
			c11,1.3,14.8-4.5,15.1-14.3c0.4-15.6,0.1-31.2,1.6-46.8C725.5,653.8,726.9,646.7,719.2,643.5z M573.7,842.9
			c0.1-1.7-0.7-2.5-2.4-2.3c0-21.7,0-43.5,0-65.2c-2,17.9-2.9,35.8-3.6,53.7c-0.3,9-3.5,11.8-13.2,10.5c-40.9-5.6-82-10.5-123-15.2
			c-24.4-2.8-24.5-2.5-24.6-26.5c0-2.2-0.2-4.4-0.4-6.6c-1.3,5.8-1.4,11.7-1.3,17.5c0.1,13.1,2.9,16,16.2,17.7
			c35.6,4.5,71.1,9.5,106.8,13.4c14.3,1.6,28.8,7.2,43.3,0.6C571.5,841.9,572.3,842.8,573.7,842.9c3.3,4.3,8.3,3.7,12.8,4.3
			c9.7,1.3,19.4,2.2,29.1,3.4c37.1,4.7,74.3,9.4,111.4,14c6,0.8,11.3,0.4,10.9-7.8c4.6-3.8,3.6-9.2,3.7-14
			c1.2-49.1,1.1-98.3,3.6-147.3c1.6-31.5,0.1-63.1,3.2-94.6c0.6-5.8,1.6-12.7-5.7-16.1c-2.6-6.9-7.3-4-11.9-1.5
			c4,0.5,8,0.9,11.9,1.4c2.6,5.4,3,11.2,2.8,17.1c-1.4,43.7-2.9,87.4-4.2,131c-1.2,41.4-2.3,82.7-3.4,124.1
			c-3.1,3.2-6.5,5.2-11.4,4.5c-20-2.8-40.1-5.6-60.2-7.9C635.6,850,604.8,844.5,573.7,842.9z M428.3,850c-2.9,0.3-4.1,2.2-4.2,4.7
			c-0.8,18.6-1.8,37.1-1.9,55.7c0,8.2,4.5,13.4,13.6,14.5c25.1,3.1,50.1,6.7,75.1,10.2c4.2,0.6,8.6,2.2,11.1-3
			c1.2-1.4,3.5-2.8,3.5-4.3c0.9-20.1,1.9-40.2,1.9-60.3c0-6.7-4.6-11.3-11.9-12.2c-25.8-3.2-51.5-6.6-77.3-9.6
			C434.6,845.2,429.2,843.1,428.3,850z M580.7,570.6c-53.4-13.2-108.3-11.2-162.5-16.3c46.2,5.8,92.6,9.9,139,13.7
			c8.3,0.7,16.3,0.8,19.6,11.5c1.8-3.9,3-6.3,4.1-8.8c18.8,1.3,37.7,2.7,56.5,4c-17.7-5.6-35.8-6-53.9-7.3
			C581.8,567.3,580.8,568.8,580.7,570.6z M383.7,737.2c0,16.5,0,33,0,49.5c-3.8,0.4-7.2,2.4-11.4,1.8c-26.7-3.5-53.4-6.8-80.1-10
			c-21.2-2.5-42.5-4.8-63.7-7.2c24.9,6.1,49.9,12.3,75.1,13.6c22.8,1.2,45.2,5.5,68,6.7c8.4,0.4,12.2,5.7,11.5,14.3
			c-0.7,8.9-1.4,18-1,26.9c0.4,10.4-3.6,14-14,12.6c-27.8-3.8-55.7-7.2-83.6-10.6c-18.9-2.4-37.8-4.6-57.5-7c1.2,3.7,3,3.1,4.4,3.3
			c45.7,6.1,91.3,12.5,137,18c10.2,1.2,13.7,6.1,13.2,15.3c-0.7,13.2-1.7,26.5-2.6,39.7c-17.5-2-35-4-52.4-6c-0.1,1-0.2,2-0.3,3.1
			c15,2.1,29.9,4.2,44.9,6.3c3.4,0.5,6.2,0,7.7-3.5c4.1-2.2,4-6.1,4.2-10.1c1-22.7-0.6-45.4,2.1-68.1c1.5-13-0.1-26.1-1.6-39.1
			c4.4-9.9,3.7-20.5,3.7-30.8c0-6.2,3-13.4-3.7-18.4c5.8-18.5,6.3-37.3,2.3-56.2c4.8-16.3,4.2-33,4.4-49.7c0-6.2-0.8-13.3-7.3-14.5
			c-16.1-2.9-32.5-4.1-48.8-5.9c-0.1,1.1-0.2,2.2-0.3,3.3c14.4,1.6,28.8,3.5,43.3,4.8c7.3,0.6,12.1,4.7,10.9,10.9
			c-2.7,14.4,2.9,29.7-5.2,43.5c-2,3.4,0.3,5.9,3.2,7.6c0,12.1,0.3,24.2,0.1,36.4c-0.2,10-4.7,14.4-15.7,13.1
			c-43.8-5.4-87.7-10.1-131.5-15.4c-6.9-0.8-10-0.4-9.2,7.9c0.9,9.9,0.2,19.9,0.2,29.9c-0.1-8.2,0.4-16.5-0.2-24.6
			c-0.6-9,3.6-10.5,11.4-9.5c26.8,3.3,53.7,5.4,80.3,9.4C342.1,731.1,363.5,730.5,383.7,737.2z M273.8,549.8
			c-5,14.4,12.7,4.9,14.6,12.6c12.6-4.6,24.9,0,37.4,1.1c5.2,0.4,8.3-0.6,7.2-7.1c-1.1-6.5-1.1-10.6-10.9-9.8
			c-7.4,0.6-17.6-4-24-1.6c-5.9,2.2-7.4,3.6-12-1.2C284.6,542.3,273,535.9,273.8,549.8z M65.9,640.7c0,1.1,0,2.3-0.1,3.4
			c47.8,5,95.6,10,143.4,15c0.1-1.2,0.2-2.4,0.4-3.5C161.7,650.6,113.8,645.6,65.9,640.7z M137.3,534.9c0.4,11,8,12.2,16.6,13.2
			c4.2,0.5,8.3,0,7.8-3.8c-0.5-4.3,3-11.7-4.1-12.9c-11.2-1.8-22.6-2.1-34-2.9c-4.2-0.3-5,2.8-5,6.2c0,3.9-0.4,8.2,4.3,9.8
			c3.8,1.3,5.4-1.6,6.7-4.7c0.8-1.8-0.9-4.7,2.5-5.5c2.2,3.2-1.1,8.3,4.1,11.7C136.7,541.7,137,538.3,137.3,534.9z M71.8,808
			c3.1,4.7,6.1,3.6,8.6,3.9c38.7,5.6,77.4,11.3,116.2,16.2c2.7,0.3,8.7,7,9.9-0.4c1.5-9.4-6.8-1-9.5-3.9c-0.7-0.7-2.2-0.7-3.4-0.9
			C153.7,818,113.6,813.1,71.8,808z M232.5,658.1c-0.1,1.1-0.2,2.1-0.3,3.2c45.2,4.8,90.4,9.5,135.6,14.3c0.1-1.1,0.2-2.2,0.3-3.3
			C322.9,667.5,277.7,662.8,232.5,658.1z M672.1,598.3c-3.5,6.3-3.7,4.3-6.7,4.2c-5.5-0.3-9.7-11.1-16.1-2.3
			c-2.4,3.3-2.6,0.6-3.3-1.1c-1.1-2.5-2.8-2.8-3.9-0.2c-3.2,7.9,5.6,9,8.2,13.7c2,3.7,6.2,4.1,8.6,0.2c4.7-7.6,8.3-3.4,10.8,1.1
			c4,7.2,9.7-0.1,14.7,2.1c2.2,1,0.6-5.5-0.1-8.5c-0.6-2.7,0.5-6.6-4.3-7.9c-1.4,2.5-2.8,5.1-4.7,8.6
			C674.2,605,673.4,602.5,672.1,598.3z M613.5,595.5c-4.8-0.9-4.1,8.3-8.3,3.5c-3.1-3.5-7.2-2.8-9.9-4.8c-3.3-2.4-6.5-4.6-8.1-0.3
			c-1.8,4.7-3.9,12.6,1.6,14.6c6.6,2.5,15.2,2.7,22.1,0.8C616.2,607.9,612.7,600.6,613.5,595.5z M693.1,602.5
			c10.8,2.8,0.7,14.5,9.6,17.3c9.8,3.1,11.9-4.4,13.7-11.5c4.8,3.3-1.6,9.8,5.4,12.5c0-5.1,0-9.3,0-13.6c0-3.7-2.6-4.5-5.5-4.8
			c-3.1-0.3-3.8,1.9-4.1,4.5c-0.3,2.3-0.4,4.7-1.1,6.9c-0.8,2.5-2.7,3.7-5.5,2.4c-4.2-2.1,0.1-3,0.4-4.6
			C708.1,602.6,704.2,599.5,693.1,602.5z M641.4,671.5c-0.8-5.3-1.7-11.6-8.7-11.3c-6.4,0.2-7.2,6.1-7.2,11.3c0,5.5,2,10.8,7.9,10.9
			C639.4,682.5,641,677.1,641.4,671.5z M634.5,909.4c-1-4.9-2.3-10.8-8.3-11c-6.3-0.2-7.9,5.4-7.7,10.9c0.1,5.4,1.5,10.8,7.8,11.1
			C633,920.7,633.9,915,634.5,909.4z M636.5,612.8c3.8-2.3,3.2-4.6,2.9-6.2c-0.6-4.1,3-10.5-4.1-11.6c-7.3-1.1-4.5,5.5-5.5,9
			c-0.5,1.8-0.6,4.2-3.3,3.6c-2.1-0.5-2.9-2.5-3-4.5c-0.1-2.7,3.7-4.2,2.1-8.9c-1.9,5.9-8.6-4-9.7,4.8c-0.7,5.4,0.5,9.7,2.7,11
			c11,6.1,14.5-0.9,17.8-9.5C636.5,604.2,636.5,607.9,636.5,612.8z M357,557.2c-1-4.7-0.9-11.1-7.3-11.5c-5.6-0.3-7.5,5-7.6,9.9
			c-0.1,5.2,1.3,11.1,7.3,11.6C355.2,567.7,356.6,562.4,357,557.2z M350.3,815.5c-1-5-1.4-11.3-7.6-11.7c-5.7-0.4-7.4,5-7.6,10
			c-0.2,5.5,1.8,11,7.6,11.6C348.6,825.9,349.8,820.4,350.3,815.5z M717.9,582.2c0-1.2,0-2.4,0-3.5c-22.2-1.8-44.4-3.7-66.6-5.5
			c-0.1,1-0.2,2.1-0.3,3.1C673.3,578.3,695.6,580.3,717.9,582.2z M207,874.8c-7,1.4-10.3,7.5-16.8,4.6c-12.1-5.5-24.9-5.3-39.6-3.8
			C172.1,884.6,202.1,884.7,207,874.8z M180.8,682.4c-0.8-5.2-1.6-11.4-7.5-11.8c-5.7-0.4-7.1,5.5-7.2,10.2
			c-0.1,5.2,1.5,10.9,7.9,11C179.2,691.9,179.9,686.5,180.8,682.4z M439.5,652.7c0.3,4.9,1.4,10.5,7.7,10.5c5.5,0,7.1-5,7-10.1
			c-0.1-5.3-1.2-11-7.2-11.4C440.3,641.2,440,647.3,439.5,652.7z M355,642.6c-1.7-3.8-1-10.7-7.9-10.6c-5.7,0-7.6,5.3-7.2,10.2
			c0.4,5,1.6,10.9,8.3,10.6C353.5,652.6,354.4,647.7,355,642.6z M181.8,626c-0.1-5.2-0.6-11.1-7-11.3c-5.8-0.2-6.9,5.2-6.9,10.1
			c-0.1,5.5,1.6,10.8,7.8,10.8C180.7,635.6,181.8,630.6,181.8,626z M348.3,872.6c-0.8-4.1-0.4-10.3-7-10.9c-6.3-0.6-7.1,4.8-7.2,9.7
			c-0.1,5.3,0.6,10.9,7.3,11.1C347,882.6,348,878,348.3,872.6z M161.3,848.4c0,5-1.2,10.8,6.7,10.8c5.9,0,6.7-4,6.7-8.7
			c0.1-6-1.3-11.5-8.2-11.6C160.2,838.7,161.3,844.2,161.3,848.4z M351.1,757.6c-0.3-5-0.7-10.7-6.7-10.8c-6.7-0.1-7.7,5.7-7.5,11
			c0.2,4.9,1.7,10,7.6,10C350.9,767.7,350.9,762.1,351.1,757.6z M179.1,738.1c-0.7-4.7-1-10.9-7.2-11.3c-5.5-0.3-7.3,5.1-7.2,10
			c0.1,5.1,1.3,10.9,7.7,10.9C177.5,747.8,178.7,742.8,179.1,738.1z M353,700c-0.6-4.9-1.3-10.7-7-10.8c-6.6-0.1-7.4,5.9-7.3,11.2
			c0.1,5,1.8,10.2,7.5,10C352.1,710.2,352.9,704.8,353,700z M184.1,540.6c-0.9-3.6-0.8-9.9-7.7-9.7c-5.2,0.1-6.3,5.1-6.3,9.7
			c0,5.3,0.9,11,7.6,10.9C182.9,551.4,184,546.5,184.1,540.6z M177.2,793.2c-0.6-4.1-0.7-10.3-7.3-10.3c-5.6,0-6.8,5.6-6.7,10.5
			c0.1,4.9,1.3,9.9,7.4,9.9C176.1,803.3,177,798.7,177.2,793.2z M212.6,603.2c-10.3-5.7-40.6-9.2-53.4-5.7
			C178.4,599.6,194.5,601.3,212.6,603.2z M479.7,616.4c-13.4-1.1-26.9-2.3-40.3-3.4c-0.1,1.2-0.2,2.3-0.3,3.5
			C452.7,616.4,466,620.4,479.7,616.4z M545.8,898.7c-1.2,0-2.4,0-3.7-0.1c0,20.5,0,40.9,0,61.4
			C542.1,939.5,543.3,919.1,545.8,898.7z M376.2,876.8c-0.1-4.2-0.2-9-5.1-9.4c-4.5-0.3-5,4.4-5.2,7.8c-0.3,3.8-0.1,8.9,4.6,9.6
			C375.9,885.6,375.6,880.3,376.2,876.8z M360.5,759.8c-0.4,3.9-1.3,8.8,4.1,9.2c4.7,0.4,5-3.9,4.5-7.6c-0.5-3.8,2.2-9.8-4.7-9.8
			C359.3,751.6,360.6,756.4,360.5,759.8z M584.9,875.8c-0.7-3.6,1.7-9.5-5.1-9.6c-6.4,0-4.1,5.7-4.1,8.9c0,3.3-2.6,9,3.4,9.2
			C583,884.4,586.2,881,584.9,875.8z M724.2,602.3c0,6.6-0.1,10.6,0,14.7c0,2.5,0.8,4.6,3.7,5.1c2.5,0.4,4.6,0,6.1-2.2
			c1.8-2.6,1.9-5.8-0.8-7.2C729.8,610.9,729,607,724.2,602.3z M191,851.1c-1-2.5,1.8-7.8-4.7-7.9c-3.7,0-5.7,3.7-4.1,5.1
			c4.4,3.7-4,11.8,4.3,12.4C191.7,861.1,191,855.6,191,851.1z M362.8,646c0.2,3.6,0.4,7.6,5,7.7c3.2,0.1,5.1-3.5,3.9-5.8
			c-1.9-3.7,0.9-10.3-3.9-11C363.4,636.5,362.8,642,362.8,646z M362.3,826.1c2.2-0.1,3.7-1.5,4.2-3.4c1.1-4.5,2.2-9.2-1.7-13.2
			c-1.3-1.3-3-1-4.2,0.1c-4.2,3.8-2,7.7-0.1,11.7C361.2,822.8,359.4,825.2,362.3,826.1z M195.1,739.8c0.3-4.2,0.3-9.3-4.9-8.6
			c-6.9,1,3.1,4.9-0.4,7.7c-1.4,1.1,2.1,4.3-1.1,5.9c-1.8,0.9-1.8,2.6-0.1,3.4c1.1,0.5,3.1,0.4,4.2-0.3
			C195.9,745.9,195.3,742.6,195.1,739.8z M192.4,787.7c-9.7,10.3-9.6,10.7,0.6,15.8C194,798.3,193.7,793.3,192.4,787.7z
			 M363.4,709.9c9.6-13.8,9.6-14.5-2.5-16.1C367.7,699.1,363.5,704.5,363.4,709.9z M191.5,628.3c1.2,2.4-4.4,6.5,1,7.4
			c5.3,0.8,3-4.3,2.9-6.7c-0.2-2.9,2.1-8.5-2.8-8.2C187.8,621.1,192.5,625.7,191.5,628.3z M359.9,867.7c-1.2,5.6-2.8,11.2-0.2,19.5
			C363.2,878.9,362.9,873.3,359.9,867.7z M688.2,601.6c0.3-0.2,0.5-0.4,0.8-0.6c-0.3-0.2-0.6-0.5-0.9-0.7c-0.2,0.3-0.4,0.5-0.4,0.8
			C687.6,601.2,688,601.4,688.2,601.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M140.7,37.9c21.8,0.7,43.8,3.7,65.2,3.2
			c29.6-0.7,59.3-0.8,88.8-0.2c83.2,1.8,166.5,3.4,249.8,4.5c59.4,0.8,118.8,2.9,178.2,3.8c11.3,0.2,14.2,4.3,13.7,14.7
			c-0.8,14.3,2,28,9,40.7c7.3,13.1,17.8,21.9,33.5,22.1c9.6,0.1,10.4,5.7,10.7,13.1c0.4,9.5-5.9,8.3-11.8,8.1
			c-76.6-2.4-153.1-4.8-229.7-7.2c-51.2-1.6-102.4-3.1-153.5-4.7c-96.1-3-192.2-6.1-288.3-9.2c-16.4-0.5-32.8-1.3-49.2-1.9
			c-4.3-0.2-9.2,0.7-8.8-6.1c0.3-5.4-0.4-11.2,7.1-12.8c26.3-5.6,38-19.5,39.6-46.5c0.2-2.7,0.4-5.5,0.2-8.2
			c-0.5-9.9,3.3-14.3,13.8-13.4C119.6,38.5,130.2,37.9,140.7,37.9z M332,103.6c9.3,8.1,21.4,2.2,31.8,6.3c4.2,1.6,4.6-5.8,5.4-9.8
			c0.5-3,1.1-7.2-3.1-7.6c-6.3-0.6-12.8-0.3-19.2,0.3c-5.6,0.6-2,8-6.3,9.8c-4.1-2.3,1.8-7.1-2-10c-2.3,4-4.4,7.5-6.5,11.1
			c-1.6,1.1-3.2,1.1-4.9,0c-0.4-10.4-5.1-14.7-15.4-10.9c-1.6,0.6-2.4,0.4-4,0.1c-15.2-3.3-30.6-2.8-46-1.3c-4.2,0.4-8.4,4-13.1,0.8
			c-0.8-0.6-4.9-3-5.3,1.2c-0.3,3.3-4.3,6-1.4,9.8c1.7,2.2,4.1,2.9,6.1,1.6c3.4-2.2,9-3.7,10.7-3.4c5.8,1.1,10.4,4.1,17.7,2.6
			c4-0.8,12.7-3.5,18,2l0,0c1.8,1.4,0.1,7.8,3.5,4.9c9-7.4,20.8-0.8,29.4-7.3C328.9,106.6,330.4,106.6,332,103.6z M237.9,91.7
			c-6.8-2.7-16.9,3.1-20.4-8.2c-0.3-1-2.5,0.5-3.7,1.6c-1,1-0.6,4-3.7,2.5c-5.8-2.7-5.9-2.6-6.8,3.3c-2.1,12.6,0.3,14.9,12.5,11.2
			c1.6-0.5,1.8-1.6,4.1-0.3c10.6,5.8,17,2.4,18.7-9.2c1.1-0.6,2.1-1.2,0.6-2.4C238.8,90.6,238.4,91.1,237.9,91.7z M157.3,103.1
			c17.8,0.1,17.7,0.1,19.9-16.7c0.5-3.8,2.2-7.6,2.1-11.4c-0.1-4.4,4.8-9.7,0-12.9c-4.9-3.3-5.1,4.5-8.5,5.6
			c-1.1,0.3-2.3,0.4-3.4,0.5c-12.8,1.7-19.5,8.1-20.5,21.9C146,100.3,147.5,105.2,157.3,103.1z M669.1,92.2c2.9,6.5-5.7,7-1.7,12.3
			c4.4,5.8,2,7.8-4.4,5.4c-1.7-0.6-3.2-1-4,0.6c-1,2,0.2,3.8,1.8,5.1c3.6,2.9,8,2.9,10.4-0.6c4.8-7.1,6.5-16.7,3.6-23.5
			c-4.6-10.8-16.9,3.3-24.5-4.3c-1.4-1.4-5.5-0.3-6.3,3.2c-1.8,8-4.2,16-3.2,24.9C649.6,105.9,657.8,97.3,669.1,92.2z M634.1,114.5
			c3.9-4.5,2.7-10.4,4.1-15.6c1-3.6,3.9-9.8-1.2-11.2c-5.6-1.6-4.9,5.6-7.1,8.9c-0.3,0.5-1.1,0.6-2.1,1.2c-0.7-3.2-0.8-6.4-2-9.1
			c-1.6-3.6-5.3-3.3-5.9-0.1c-1.8,8.5-5,17-1.6,26.1c1.2-3.1,2.4-6.2,3.6-9.6c2.3,3.2-0.6,7.5,3.1,9.7c4.5-0.6,3.4-5.8,6.6-7.3
			C633.5,109.5,630.4,113.1,634.1,114.5z M613.4,88.2c-11.6-5.4-14.2-4.1-16,7.4c-0.7,4.6-1.8,9.1-2.9,13.6
			c-0.4,1.7-2.7,3.8-0.3,4.9c4.3,2,9.2,1.4,13.7,0.6c4.2-0.8,4.7-4.9,5.2-8.2c0.7-4.1-0.4-7.7-4.9-9.4c-1.9-0.7-5,0.4-5.1-2.9
			C605,89.2,612.2,93.9,613.4,88.2z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M592.6,504.4c-3.7,1.3-6.6-0.1-9.3-2.5
			c0.1-0.7,0-1.3-0.4-1.9c-5.6-6.4-12.7-11.7-14-20.9c-0.9-8.9-2.3-17.7-0.5-26.7c2.5-11.8,8.9-20.2,21.2-23
			c2.5,0.1,4.9,0.2,7.4,0.3c11.1,5.9,16.8,15.6,19.4,27.4c-0.8,8.3-1.6,16.5-2.4,24.8c-2.2,11-11,15.7-19.6,20.6
			C593.8,503.2,593.2,503.8,592.6,504.4z M587.7,474.1c3.8-0.9,5.8-4.6,6.1-7.3c0.5-3.9-1.8-8.8-6.2-8.6c-2.9,0.1-8.5-0.4-8.6,6.5
			C578.8,471.6,585.2,470.4,587.7,474.1z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M558,203.9c4.1,10.6,3.7,21.1-0.2,31.6
			c-3.9,6.1-7.8,12.1-11.7,18.2c-7.2,1.3-14.4,0.4-21.6,0l0.2,0.2c0.2-0.3,0.6-0.6,0.6-0.9c-0.1-0.6-0.4-1.2-0.6-1.8
			c-4-6.1-7.9-12.2-11.9-18.4c-3.9-8.3-0.7-17.3-2.8-25.8c8.1-5.6,9.8-16.8,19-21.4c6.7-3.9,12.7-0.9,18.7,2.1
			C554.6,190.8,553.1,199.4,558,203.9z M534.2,241.4c-3-13.9,8.1-27.9,0.7-42.8C522.6,213.1,535,227.5,534.2,241.4z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M590.1,319.1c-8.3,4.9-16,3.7-23.4-2
			c-3.2-4.7-6.3-9.3-9.5-14c-0.4-1.2-0.4-2.8-2.4-2.5c0,0,0.2,0.2,0.2,0.2c0.3-10.2-2.3-20.7,2.4-30.6c3.9-5.3,7.8-10.7,11.7-16
			c6.4-5.2,13.9-2.3,21-2.6l-0.1-0.1c-0.5,2.2,1,2.5,2.6,2.7c3.2,6.2,6.4,12.4,9.6,18.6c4.6,11,2.9,21.9-0.9,32.7
			C597.5,310.1,593.8,314.6,590.1,319.1z M593.1,288.6c-8.7-15.9-19.9-1.5-29.4-4.8c-1.3-0.4-2,3,0,4.3c4.7,3,10.8-1.3,15.8,4.3
			C585.3,298.9,587.9,288.1,593.1,288.6z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M507.7,443.6c2.6-8.2,7.4-14.4,15.9-17.1
			c4.3,0.2,8.6,0.5,12.9,0.7c10.1,4.5,14.3,13.1,16.4,23.2c0,7.5,0.1,14.9,0.1,22.4c-3,11.3-8.1,20.7-20.9,23.1
			c-2.9-0.1-5.8-0.2-8.8-0.4c-9.9-5.6-16.1-13.9-18.1-25.2C505.6,461.4,505.2,452.4,507.7,443.6z M515.6,479.8
			c1.6-2.4,3.2-4.7,4.8-7.1c1.5-1,3-1.9,4.4-2.9c0.9,1.3-0.8,6.4,2.9,3.4c4.1-3.2,6.7-8.3,10.5-13.2c-5-1.1-5.3,2.3-7.4,2.9
			c-0.2-4,1.3-9.1-5.2-9.5c-7.4-0.4-3.1,5.1-3.9,8c-0.9,3.3-1.9,6.6-2.8,9.9c-2.6,1.7-4.3,4-4.6,7.1c-0.2,0.6-0.4,1.2-0.5,1.9
			C514.4,480.1,515,480,515.6,479.8z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M473.5,422.3c11.9,4.3,15.8,14.5,18.6,25.4
			c-0.8,8.3,0.4,16.8-2.4,24.9c-2.9,9.5-8.5,16.3-18.6,18.5c-3-0.1-6-0.2-9-0.3c-9.4-5.2-14.7-13.3-17.4-23.5
			c-0.2-9.6-0.6-19.1,2.2-28.5c2.7-8.1,7.4-14.2,15.9-16.7C466.3,422.3,469.9,422.3,473.5,422.3z M473.5,443.3
			c-6.1-0.1-12.2-5.8-13.8,3.2c-1.4,7.2-0.3,14.8-0.3,22.3c1.1,0.4,2.2,0.8,3.3,1.1c-0.6-6.7-0.3-12.9,7.5-12.9
			c-1.1-4.6-8-2-6.8-7.4C464.3,444.5,469.7,448.2,473.5,443.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M113.9,140.5c-18,1.3-35.9-0.3-53.8-1.5
			c-7.6-0.5-9.9,1.8-10.1,9.6c-1.5,78.2-3.3,156.3-5.1,234.5c-0.7,28.1-1.5,56.3-2.4,84.4c-0.1,2.5,0.9,5.5-3.1,8.1
			c0-15.5-0.3-30.3,0.1-45.1c1.4-61,3.1-121.9,4.6-182.9c0.8-33.2,2-66.4,2.3-99.6c0.1-9.7,3-13.2,12.9-12.3
			c18.2,1.7,36.7-0.8,54.9,2.3C114,138.9,114,139.7,113.9,140.5z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M410.6,355.1c-0.2,3.8-0.4,7.7-0.6,11.5
			c-1.9,9.3-1.1,18.6-0.6,28c-0.7,11.1,4.6,26.1-3,32.2c-6.4,5.1-20.8,0.6-31.6-0.1c-64.9-4.5-129.8-9.4-194.7-13.5
			c-11.7-0.7-17.4-3.9-14.4-16.2c0.9,2.5,1.8,5,2.7,7.5c2.6,4.6,7.3,4,11.4,4c26.8,0.1,53.2,4.5,79.9,5.7c45.6,2,91.2,5.9,136.8,9.6
			c7.9,0.6,10.1-1.5,10.1-9.2c0-19.9,0.7-39.8,1.1-59.8C408.6,355,409.6,355.1,410.6,355.1z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M169.8,246.8c0.4-10.1-2.9-23.1,2.1-29.5
			c5.6-7,19-1.4,29-1c67.8,2.7,135.5,6.1,203.3,8.8c8.3,0.3,10.9,3,10,10.9c-0.8,7.3-0.6,14.8-0.8,22.2c-1-0.7-2-1.5-3-2.2
			c0.2-7,0.5-14,0.6-21c0-2.5,1.2-6.4-3.6-5c-9.1,2.6-17.4-2.1-26.3-2.3c-23.8-0.6-47.5-2.2-71.3-3.3c-42.1-1.9-84.1-3.7-126.2-5.6
			c-6.9-0.3-11.8,0-11,9.6c1,11.6,0.1,23.4,0.1,35.1C168,258.5,171.9,252.1,169.8,246.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M71.7,476.1c74.4,6,148.8,12,223.3,17.9
			c-0.1,1.3-0.2,2.7-0.3,4c-74.4-5.9-148.8-11.9-223.2-17.8C71.5,478.9,71.6,477.5,71.7,476.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M784.4,333.5c-1,47.3-2,94.5-3,141.8
			c-0.4,17.2-1.2,34.4-1,51.6c0.1,8.8-2.2,12-11.4,10.4c-9.6-1.7-19.4-1.9-29.1-2.8c0.2-4.3,3.2-4.1,5.9-3.8c7,0.6,14,1,21,2.1
			c7.3,1.1,9.3-1.6,9.4-8.8c0.4-34.8,1.4-69.6,2.2-104.4c0.6-25.8,1.4-51.6,2.1-77.4c0.1-3.1-0.2-6.3,1.7-9.1
			C783,333.2,783.7,333.3,784.4,333.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M169.8,246.8c2.1,5.3-1.8,11.7,2.8,16.6
			c2.2,46.1-3.1,92.1-2.8,138.2c0,1-1,2.1-1.5,3.1c-0.9-2.5-1.8-5-2.7-7.5c0.9-49.1,1.8-98.1,2.8-147.2
			C168.5,248.9,169.3,247.8,169.8,246.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M642.2,526.3c-45.2-3.6-90.5-7.3-135.7-10.9
			c0.1-1.2,0.2-2.4,0.3-3.6c45.2,3.5,90.4,7,135.6,10.6C642.3,523.7,642.3,525,642.2,526.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M553,472.8c0-7.5-0.1-14.9-0.1-22.4
			c3.9-7.3,1.9-14.9,1.4-22.4c0.6-0.6,1.1-1.3,1.7-1.9c5,5.8,2.9,12.7,2.8,19.1c-0.2,14.8-1.1,29.6-1.4,44.4
			c-0.2,9.2-3.9,14.2-13.6,12.7c-13.1-2.1-27,0.7-39.5-5.7c0.6-0.5,1.2-1.1,1.7-1.6c5.7,0.2,11.5,0.4,17.2,0.6
			c2.9,0.1,5.8,0.2,8.8,0.4c20.3,5.5,22.9,3.6,22.5-16.5C554.6,477.1,554.9,474.7,553,472.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M783,289c-1.6-38.4,2.8-76.7,2.1-115.1
			c-0.1-4.6,1.1-9.5-5-11.5c2.2-1.1,5.3,0.5,7-2.2c4.9,8.7,2.5,18.2,1.7,27c-2.4,26.1-0.5,52.2-1.9,78.3c-0.4,7.8-1.4,15.5-2.1,23.2
			C784.3,289,783.7,289.1,783,289z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M336.7,147.8c-30.7,0.6-61.3,1.2-91.8-3
			c0-0.9,0.1-1.8,0.1-2.8c30.6,0.1,61.2,0.4,91.8,3.4C336.7,146.2,336.7,147,336.7,147.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M446.8,439c-2.8,9.3-2.4,18.9-2.2,28.5
			c-3.6,7.7-2.8,15.3,0.7,22.8c-0.6,0.6-1.1,1.2-1.7,1.8c-4.5-2.8-3.9-7.3-3.8-11.5c0.2-16.4,0.2-32.8,0.6-49.2
			c0.4-14.1,2.7-16.1,16.7-15.7c4.7,0.1,9.4,0.2,14.1,0.6c5,0.4,4.7,2.9,2.4,6.2c-3.6,0-7.2-0.1-10.8-0.1c-5.1-3.3-11.6-6-15.8-1.3
			C442.7,425.7,442.6,432.9,446.8,439z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M408.4,507.4c-34.9-2.8-69.8-5.5-105.9-8.4
			C311.3,494.6,398.9,500.9,408.4,507.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M568.4,452.5c-1.8,8.9-0.4,17.8,0.5,26.7
			c-7.2,16.3-3.5,21.8,14,20.9c0.3,0.6,0.5,1.2,0.4,1.9c-14.3,5.8-20.8,1.7-21-13.3c-0.2-16.6,1.7-33.2-0.1-49.9
			c-0.4-3.5-1.8-8.1,3.4-10c0.7,0.5,1.3,1.1,2,1.6C567.2,437.9,565.2,445.3,568.4,452.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M444.6,148.6c33.8,0.4,67.6,0.8,101.3,3.8
			c-0.1,0.8-0.2,1.6-0.3,2.4c-33.8,0-67.6-1.1-101.3-3.7C444.4,150.3,444.5,149.5,444.6,148.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M602.1,272.8c-3.2-6.2-6.4-12.4-9.6-18.6
			c-0.4-1.4-0.7-2.7-2.6-2.7l0.1,0.1c-0.5-1.9-1.1-3.9-1.6-5.8c0.8-0.6,1.5-1.3,2.3-1.9c7.7,11.6,15.4,23.2,22.9,34.9
			c4.2,6.5,3.9,13.3-0.8,19.5c-6.3,8.4-12.7,16.6-19.1,24.8c-0.6-0.6-1.2-1.2-1.9-1.8c-0.6-0.8-1.2-1.5-1.8-2.3
			c3.7-4.5,7.4-9,11.2-13.6C617,291.2,617.3,281.1,602.1,272.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M614,482c0.8-8.3,1.6-16.5,2.4-24.7
			c2.5-7.9,1.6-15.9,0.6-23.9c0.6-0.5,1.1-1,1.7-1.5c4.4,5.6,2,12,2.1,18c0.3,12.9-0.1,25.8-0.2,38.7c-0.1,18.1-2.4,20.1-20.6,18.4
			c-2.6-0.2-5.6,0.3-7.3-2.6c0.6-0.6,1.2-1.2,1.8-1.8c2.3,0,4.6,0,6.9,0C617.5,503.1,620.4,498.3,614,482z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M731,531.1c-25.1,3.2-49.8-3.3-75.4-3
			C660.7,523.7,702.3,525,731,531.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M495.1,514.5c-24.4-2-48.8-4-73.2-6
			c0.1-1.2,0.2-2.4,0.3-3.6c24.4,1.9,48.9,3.9,73.3,5.8C495.4,512,495.2,513.2,495.1,514.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M489.6,472.7c2.9-8.1,1.6-16.6,2.4-24.9
			c2.8-7.8,1.7-15.8,0.9-23.8c0.6-0.6,1.2-1.3,1.7-1.9c3,24.2,3.9,48.3-2,72.3c-0.6-0.6-1.2-1.2-1.8-1.8
			C491.4,485.9,494.6,479,489.6,472.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M507.7,443.6c-2.5,8.8-2.1,17.8-2.4,26.8
			c-3,7.2-1.4,14.7-1.2,22.1c-0.5,0.5-1.1,0.9-1.6,1.4c-4.1-21.8-0.3-43.5-0.9-67.7C508.1,432.6,502.4,439.8,507.7,443.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M558,203.9c-4.9-4.5-3.3-13.1-10.4-16.2
			c0.7-3.3-2-5.6-2.7-8.5c0.7-0.6,1.3-1.2,2-1.8c7.8,11.5,15.8,23,23.4,34.7c3.2,5,5.1,10.4-1.4,16.5
			C570.3,217.2,566.6,209.3,558,203.9z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M245,142c0,0.9-0.1,1.8-0.1,2.8
			c-7.7-0.3-15.5-0.7-23.2-1c-0.8,0.4-1.5,0.8-2.3,1.2c-11-0.2-22.6,4.9-32.4-4.2c0,0-0.2,0.2-0.2,0.2
			C206.1,141.3,225.6,141.7,245,142z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M528.9,185.6c-9.2,4.6-10.9,15.8-19,21.4
			c-2,1.7-3.9,3.4-5.9,5.2c-0.6-0.5-1.1-1.1-1.7-1.6c7.4-12.6,16-24.3,25.9-35c0.6,0.5,1.2,1,1.9,1.5
			C526,184.4,526,184.4,528.9,185.6z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M113.9,140.5c0.1-0.8,0.2-1.7,0.2-2.5
			c22.5,0.3,44.9,0.6,67.4,1c1.7,0,3.5,1.2,5.2,1.9c0,0,0.2-0.2,0.2-0.2C162.5,143.4,138.2,140.7,113.9,140.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M546,253.7c3.9-6.1,7.8-12.1,11.7-18.2
			c5.2,0.7,5.6,3.1,2.7,7c-6.5,8.6-12.9,17.4-19.6,25.8c-2.9,3.7-6.6,5.7-10.4,0.7c0.6-0.6,1.2-1.3,1.8-1.9
			C541.2,267.1,542.5,259.2,546,253.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M518.5,297.2c-7.8,10.6-15.5,21.3-23.6,31.7
			c-4.9,6.3-10,7.4-13.9-1.3c0.6-0.5,1.2-1.1,1.8-1.6c3,3,6.2,5,9.4,0.5c3.1-4.5,7.8-8,8.8-13.8c3-4,6-8,9-11.9
			c2.1-1.8,4.2-3.7,6.4-5.5C517.1,295.9,517.8,296.5,518.5,297.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M462,490.9c3,0.1,6,0.2,9,0.3
			c6.5,3.1,13.2,2.1,19.9,1.3c0.6,0.6,1.2,1.2,1.8,1.8c-0.8,0.8-1.6,2.4-2.4,2.4c-15.7,0.1-31.6,1-46.7-4.7c0.6-0.6,1.1-1.2,1.7-1.8
			C450.8,490.5,456.4,490.7,462,490.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M497.6,342.2c6.8-13.1,16.2-24.3,25.9-35.3
			c0.6,0.6,1.2,1.1,1.9,1.7c-1.9,3-3.8,5.9-5.7,8.9c-3.9,7.7-7.7,15.4-11.6,23.1c-0.3-1.8-0.5-3.6-0.8-5.5c-1.3,1.1-2.7,2-3.9,3.2
			c-1.5,1.6-2.8,3.5-4.2,5.2C498.7,343.1,498.2,342.6,497.6,342.2z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M444.6,148.6c-0.1,0.9-0.2,1.7-0.4,2.6
			c-15.6,4.4-31-0.2-46.5-1.2c0.1-0.8,0.2-1.7,0.3-2.5C413.6,147.8,429.1,148.2,444.6,148.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M499.2,239.2c9.1,11.7,17.1,24.1,24.1,37.1
			c-0.7,0.5-1.3,1-2,1.5c-4-2.6-4-9.1-9.7-10.1c-3.5-6.1-7-12.1-10.5-18.2c-0.3-1.5-0.6-2.9-2.6-2.9l0.1,0.1c-0.5-2-1-4-1.5-5.9
			C497.8,240.4,498.5,239.8,499.2,239.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M597,429.8c-2.5-0.1-5-0.2-7.4-0.3
			c-7.4-1.8-14.9-4.2-22.1,1c-0.7-0.5-1.3-1.1-2-1.6c0.9-2.8,2.9-5.5,5.6-5.2c15.3,1.8,31.1-1.3,45.9,5.1c-0.6,0.7-1.3,1.4-1.9,2
			C609.1,430.5,603,430.2,597,429.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M460.1,266.9c5.8-10.3,13-19.5,21-28.2
			c0.6,0.5,1.3,1.1,1.9,1.6c0.1,3.4-6.3,4.5-3.1,9c-6.1,6-10.4,13.2-14.1,20.8c-0.1-3.5-1.5-3.8-3.8-1.5
			C461.3,268.1,460.7,267.5,460.1,266.9z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M733,161.1c-2.2-0.2-4.5-0.4-6.7-0.5
			c-0.8-0.1-1.7-0.1-2.5-0.2c-15.6-0.3-31.1-0.6-46.7-0.9c0-1.1,0.1-2.2,0.1-3.3c18.7,0.7,37.4,1.3,56,2
			C733.1,159.1,733.1,160.1,733,161.1z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M606.8,154c18.7,0.6,37.3,1.2,56,1.8
			c-0.1,1-0.2,2-0.3,3c-18.6-0.6-37.2-1.2-55.8-1.8C606.8,156,606.8,155,606.8,154z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M544.5,308.9c7.7,9.8,14.6,20.2,20.4,31.2
			c-0.7,0.6-1.4,1.2-2.2,1.8c-1.9-2-3.7-4-5.6-6.1l0.2,0.2c-0.7-1.6-1.3-3.1-2-4.7c-5.3-2.8-7.1-8.2-9.7-12.9
			c-0.5-1.5-0.6-3.3-2.6-3.7c0,0,0.1,0.2,0.1,0.2c-0.2-1.5-0.3-3-0.5-4.5C543.3,310,543.9,309.5,544.5,308.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M536.5,427.3c-4.3-0.2-8.6-0.5-12.9-0.7
			c0.6-3.9,2.3-6.6,6.8-6.1c7.9,0.9,16.3-1,23.6,3.6c-0.7,0.6-1.4,1.2-2,1.9C546.8,426.5,541.3,423.2,536.5,427.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M556.1,372.4c-5.8,8.9-11.9,17.6-19.3,25.3
			c-0.6-0.6-1.3-1.3-1.9-1.9c1.3-4.7,8.6-6.7,5.8-13.2c4.1-3.8,7-9.1,12.5-11.4c0.2-0.4,0.5-0.6,1-0.7
			C554.8,371,555.4,371.7,556.1,372.4z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M336.7,147.8c0-0.8,0-1.6,0-2.4
			c14,0.3,28,0.7,41.9,1c-1.5,0.7-3,1.4-4.5,2.1c-1.6,0-3.1-0.1-4.7-0.1c-2.5-0.7-4.9-1.3-6.9,1.1
			C353.8,151.3,345.1,151,336.7,147.8z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M411.9,298.4c-0.2,10.9-0.4,21.8-0.6,32.7
			c-1,0-2,0-3,0c0.5-18.7,1-37.3,1.5-56C413.3,282.7,410.1,290.8,411.9,298.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M476.3,320.6c-5.5-6.5-10.1-13.5-13.7-21.3
			c0.5-0.7,1-1.3,1.6-2c0.5-1.2,1.1-2.3,1.6-3.5c3.9,6.9,7.8,13.8,11.7,20.8c0.3,1.5,0.5,2.9,0.8,4.4
			C477.6,319.5,476.9,320,476.3,320.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M411.9,298.4c-1.8-7.7,1.4-15.8-2.1-23.3
			c-0.8-6.4-1-12.8,0.5-19.2c1,0.7,2,1.5,3,2.2C413.9,271.6,414.6,285.1,411.9,298.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M512.7,232.8c4,6.1,7.9,12.2,11.9,18.4
			c-0.6,0.7-1.3,1.5-1.9,2.2c-0.6,0.5-1.2,1.1-1.8,1.6c-5.6-6.5-10.4-13.5-13.9-21.3c0.6-0.5,1.1-1,1.7-1.6
			C510.1,231.9,510.8,236,512.7,232.8z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M545.6,154.8c0.1-0.8,0.2-1.6,0.3-2.4
			c14.7,0.3,29.5,0.6,44.2,1c-0.1,0.9-0.3,1.7-0.4,2.6c-3.8-0.2-7.5-0.3-11.3-0.5c-3.1-0.1-6.1-0.1-9.2-0.2
			C561.3,155.1,553.5,155,545.6,154.8z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M783,289c0.6,0.1,1.3,0.1,1.9-0.1
			c-0.2,14.9-0.3,29.8-0.5,44.6c-0.7-0.2-1.4-0.3-2.1-0.3C782.5,318.5,782.8,303.7,783,289z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M518.7,386.1c-5.5-6.5-10.2-13.5-13.8-21.2
			c0.6-0.6,1.2-1.2,1.7-1.7c0.7,0.1,1.2,0.5,1.4,1.2l-0.2-0.2c0.7,0.8,1.4,1.6,2,2.3c4,4.2,8.3,8.3,9.8,14.3c0.3,1.3,0.5,2.7,0.8,4
			C519.9,385.1,519.3,385.6,518.7,386.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M408.3,331.2c1,0,2,0,3,0
			c0.7,8,1.1,16-0.7,23.9c-1,0-2-0.1-3-0.1C406.8,347,406.8,339.1,408.3,331.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M557.2,303.1c3.2,4.7,6.3,9.3,9.5,14
			c-2.2,2.4-4.3,3.3-6.3-0.2c-2.9-5-5.9-10-8.9-15.1c0.5-0.5,1.1-1.1,1.6-1.6c0.7,0.1,1.3,0.4,1.9,0.7c0,0-0.2-0.2-0.2-0.2
			C555.7,301.5,556.4,302.3,557.2,303.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M564.9,340.2c7.5,7.8,5.2,15.2-0.7,22.6
			c-2.6,3.3-5.4,6.4-8.2,9.6c-0.6-0.7-1.3-1.4-1.9-2c2.4-3.4,4.6-6.9,7.3-10c5.3-5.9,6.2-11.8,1.3-18.3
			C563.5,341.4,564.2,340.8,564.9,340.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M569.1,254.3c-3.9,5.3-7.8,10.7-11.7,16
			c-0.7-0.6-1.3-1.1-2-1.7c-0.6-0.6-1.1-1.2-1.7-1.8c3-6,7.1-11.3,11.9-16c0.6,0.5,1.3,0.9,1.9,1.4
			C568.1,252.9,568.6,253.6,569.1,254.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M374.2,148.6c1.5-0.7,3-1.4,4.5-2.1
			c6.5-0.6,13.1-1.3,19.4,1c-0.1,0.8-0.2,1.7-0.3,2.5C389.9,150.3,381.9,151.6,374.2,148.6z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M787.2,160.3c-1.7,2.7-4.8,1.1-7,2.2
			c-9.3-0.2-18.7-0.4-28-0.6c0.1-0.9,0.2-1.8,0.3-2.7C764,159.5,775.6,159.9,787.2,160.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M752.5,159.1c-0.1,0.9-0.2,1.8-0.3,2.7
			c-6.4,0.5-12.9,1.1-19.3-0.7c0.1-1,0.2-2,0.3-3C739.7,157.5,746.2,156.8,752.5,159.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M528.2,175.6c8.2-12.1,11-11.8,18.7,1.8
			c-0.7,0.6-1.3,1.2-2,1.8c-4.2-5.9-8.5-11.8-14.9-2.1C529.4,176.6,528.8,176.1,528.2,175.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M481.1,238.7c8-12.1,10.6-12.1,18.2,0.5
			c-0.7,0.6-1.4,1.2-2.2,1.8c-4.3-8.3-9.1-7.2-14.1-0.6C482.3,239.8,481.7,239.3,481.1,238.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M505.1,230.9c-5.2-6.2-10-12.4-2.8-20.3
			c0.6,0.5,1.2,1,1.7,1.6c-2.3,6.3-0.2,11.7,3.1,16.9C506.5,229.7,505.8,230.3,505.1,230.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M455.1,287.8c-6.1-8.8-1-14.9,5-20.8
			c0.6,0.6,1.2,1.2,1.8,1.7c-3.3,5.3-7.6,10.4-4.8,17.4C456.5,286.6,455.8,287.2,455.1,287.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M523.3,276.4c6.3,8.7,0.7,14.7-4.8,20.8
			c-0.7-0.6-1.4-1.2-2.1-1.9c3.3-5.3,7.6-10.3,4.8-17.4C521.9,277.4,522.6,276.9,523.3,276.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M589.7,156c0.1-0.9,0.3-1.7,0.4-2.6
			c5.6-0.8,11.2-0.9,16.7,0.6c0,1-0.1,2-0.1,3.1C601,157.5,595.2,158.4,589.7,156z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M409.4,394.6c-0.4-9.3-1.3-18.7,0.6-28
			C411.9,376,411.3,385.3,409.4,394.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M662.6,158.8c0.1-1,0.2-2,0.3-3
			c4.8-0.7,9.6-0.9,14.4,0.4c0,1.1-0.1,2.2-0.1,3.3C672.2,160.1,667.3,160.4,662.6,158.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M525.5,304.4c3.8-7,7.7-12.7,14.4-2.4
			c-0.7,0.4-1.3,0.9-1.8,1.5c-4.9-5.1-7.6-0.4-10.7,2.5C526.7,305.5,526.1,304.9,525.5,304.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M536.8,397.6c-10.3,8.8-11.5-1.9-15.3-7.2
			c0.7-0.6,1.4-1.2,2-1.7c3.4,2.9,3.9,10.5,11.3,6.9C535.5,396.3,536.2,397,536.8,397.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M546.8,294.6c-5.2-6.1-6-12.3-0.1-18.5
			c0.6,0.6,1.2,1.1,1.9,1.7c-3.2,5.1-2.8,10.2,0.2,15.2C548.1,293.6,547.4,294.1,546.8,294.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M565.8,250.8c2.3-4.6,5.3-8.7,9.1-12.1
			c0.7,0.6,1.3,1.2,2,1.8c-3.1,3.9-6.1,7.8-9.2,11.7C567,251.7,566.4,251.2,565.8,250.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M462.6,299.3c-3.4-3.3-5.8-7.2-7.5-11.6
			c0.7-0.6,1.3-1.1,2-1.7c2.4,3.7,4.7,7.5,7.1,11.2C463.7,298,463.2,298.6,462.6,299.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M574.9,238.7c2.9-5.2,6.5-6.9,11.1-1.8
			c-0.7,0.7-1.5,1.5-2.2,2.2c-2.8-2.3-4.8,0-6.9,1.3C576.2,239.9,575.6,239.3,574.9,238.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M481,327.6c-2.5-1.7-3.7-4.3-4.8-7
			c0.7-0.5,1.3-1.1,2-1.6c1.5,2.4,3.1,4.7,4.6,7.1C482.3,326.6,481.7,327.1,481,327.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M502.1,360.6c-2.4-1.9-3.9-4.3-4.4-7.4
			c0.6-0.5,1.3-1,1.9-1.5c1.4,2.4,2.9,4.9,4.3,7.3C503.3,359.5,502.7,360,502.1,360.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M530.4,269c-2.6-1.6-4-4.2-4.9-7
			c0.6-0.5,1.2-1,1.8-1.5c1.6,2.2,3.3,4.4,4.9,6.7C531.7,267.7,531,268.4,530.4,269z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M525.5,262c-1.6-2.3-3.1-4.6-4.7-7
			c0.6-0.5,1.2-1.1,1.8-1.6c0.6,0.2,1.3,0.3,2,0.5l-0.2-0.2c1,2.2,1.9,4.5,2.9,6.7C526.7,260.9,526.1,261.4,525.5,262z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M539.8,302c1.6,2.3,3.1,4.6,4.7,6.9
			c-0.6,0.5-1.2,1.1-1.9,1.6c-1.5-2.3-3.1-4.7-4.6-7C538.6,302.9,539.2,302.4,539.8,302z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M569.2,155.3c3.1,0.1,6.1,0.1,9.2,0.2
			C575.3,157.2,572.2,157.1,569.2,155.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M546.7,276.1c1.7-2.2,3.3-4.5,5-6.7
			c0.6,0.6,1.3,1.1,1.9,1.7c-1.7,2.2-3.4,4.5-5,6.7C547.9,277.3,547.3,276.7,546.7,276.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M497.7,353.1c-2.5-3.6-2.6-7.2-0.1-10.9
			c0.6,0.4,1.1,0.8,1.7,1.3c0.1,2.7,0.2,5.4,0.3,8.1C499,352.1,498.4,352.6,497.7,353.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M586,236.9c2.5,1.7,4,4.1,4.8,7
			c-0.8,0.6-1.5,1.3-2.3,1.9c-1.6-2.2-3.1-4.4-4.7-6.7C584.5,238.4,585.2,237.6,586,236.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M362.5,149.5c2-2.4,4.4-1.8,6.9-1.1
			C367.4,150.3,364.9,149.6,362.5,149.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M549.7,298.9c-1-1.4-1.9-2.9-2.9-4.3
			c0.6-0.5,1.3-1,1.9-1.6c0.9,1.4,1.9,2.8,2.8,4.2C550.9,297.8,550.3,298.4,549.7,298.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M521.5,390.5c-0.9-1.5-1.9-2.9-2.8-4.4
			c0.6-0.5,1.2-1,1.8-1.5c1,1.4,2,2.7,3.1,4.1C522.9,389.3,522.2,389.9,521.5,390.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M492.9,423.9c-1.1-0.8-3.3-1.4-1.8-3.1
			c1.3-1.5,2.7-0.3,3.5,1.2C494.1,422.7,493.5,423.3,492.9,423.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M504.9,364.9c-0.9-1.4-1.9-2.9-2.8-4.3
			c0.6-0.5,1.2-1.1,1.9-1.6c0.9,1.4,1.8,2.8,2.7,4.2C506.1,363.7,505.5,364.3,504.9,364.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M593.8,323.2c-1,1.3-2,2.7-3.1,4
			c-0.5-0.6-1.1-1.1-1.8-1.5c1-1.4,2-2.8,3-4.3C592.5,322,593.2,322.6,593.8,323.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M219.3,144.9c0.8-0.4,1.5-0.8,2.3-1.2
			C220.9,144.1,220.1,144.5,219.3,144.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M551.7,269.4c0.7-0.9,1.4-1.7,2.1-2.6
			c0.5,0.6,1.1,1.2,1.6,1.8c-0.6,0.8-1.3,1.7-1.9,2.5C552.9,270.5,552.3,270,551.7,269.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M590.7,327.2c-0.7,0.6-1.9,1.8-2.1,1.6
			c-1.7-1.1-0.6-2.2,0.4-3.1C589.6,326.1,590.2,326.6,590.7,327.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M523.5,306.9c0.7-0.8,1.4-1.7,2-2.5
			c0.6,0.5,1.2,1,1.8,1.6c-0.7,0.9-1.3,1.7-2,2.6C524.7,308,524.1,307.5,523.5,306.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M504.5,496.6c-0.7-0.9-1.3-1.8-2-2.7
			c0.5-0.5,1.1-0.9,1.6-1.4c0.7,0.8,1.4,1.6,2.1,2.4C505.6,495.5,505,496.1,504.5,496.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M617.1,428.8c0.5,1,1.1,2,1.6,3.1
			c-0.5,0.5-1.1,1-1.6,1.5c-0.6-0.8-1.3-1.7-1.9-2.5C615.8,430.2,616.4,429.5,617.1,428.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M507,233.7c-0.6-0.9-1.2-1.9-1.9-2.8
			c0.7-0.6,1.4-1.2,2-1.8c0.5,1,1,2,1.5,3C508.1,232.6,507.5,233.2,507,233.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M554,424.1c0.7,0.7,1.4,1.4,2.1,2.1
			c-0.6,0.6-1.1,1.2-1.7,1.9c-0.8-0.7-1.6-1.4-2.4-2.1C552.6,425.3,553.3,424.7,554,424.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M723.7,160.4c0.8,0.1,1.7,0.1,2.5,0.2
			C725.3,161.6,724.5,161.4,723.7,160.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M551.6,301.8c-0.6-0.9-1.3-1.9-1.9-2.8
			c0.6-0.5,1.2-1.1,1.9-1.6c0.5,0.9,1.1,1.9,1.6,2.8C552.7,300.7,552.1,301.3,551.6,301.8z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M794.6,64.1c-0.1,3.5,0.1,7-0.5,10.5
			c-0.9,5.8-5.5,8.6-10.6,9.5c-3.9,0.7-10.1-3.6-9.2-4.9c3.9-5.4-10.2-11.4,1-15.5C781.8,63.8,788.2,63.9,794.6,64.1z"/>
		<path fill="#FEFEFE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M76.5,50.6c-0.8,6.3-0.7,14.3-8.4,14.9
			c-7.8,0.6-8.1-7.1-8.2-12.9c-0.2-6.3,0.6-14.2,8-14.8C75.1,37.3,76,44.8,76.5,50.6z"/>
		<path fill="#FEFEFE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M794.6,64.1c-6.4-0.1-12.9-0.2-19.3-0.4
			c0.8-6.1,3.2-11.9,10-11.6C791.8,52.3,794.8,57.5,794.6,64.1z"/>
		<path fill="#FEFEFE" d="M52.8,934c-1.6,4.8-1.2,12.5-8.2,11.8c-7.5-0.7-8.3-8.3-7.9-14.5c0.3-5.3,0.8-12.9,7.4-12.3
			C50.9,919.7,51.9,927.5,52.8,934z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1019.9,217.4c0,1.9,0,3.9,0,5.8
			c-0.2,19.8-0.7,20.3-20.5,18.8c-3-0.2-6-1.5-9-2c-18.4-3-19.3-4.3-16.9-23c3.1-24.5,3.1-24.5,28-24.2c1.2,0,2.3,0,3.5,0
			c15.1,0.2,15.1,0.2,15,15.3C1020,211.1,1020,214.2,1019.9,217.4C1020,217.4,1019.9,217.4,1019.9,217.4z M994.6,205.3
			c-5.8,7.8-12.2,15.8,0.4,23.7C998.7,220.9,995.2,213.1,994.6,205.3z M1004.5,229c3.4-8.8,3.6-13.7,0.9-23.2
			C1000.7,210,1000.7,210,1004.5,229z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1067.1,462.3c-4.6-8.4-12.8-5.1-19.3-4.9
			c-12.5,0.5-8.3-7.9-9-14.1c-0.8-7.7,2.7-8.5,9.7-8.3c10.7,0.4,20.3-0.3,16.3,15.3C1063.8,454.2,1070.4,457.1,1067.1,462.3z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1039.8,825.1c6.3,0.1,14.3-2.7,13.8,8.4
			c-0.4,8.9-2.3,15.4-14,14.2c-8.1-0.8-14.7-1.3-15.1-11.4C1024.3,828.1,1028.5,825,1039.8,825.1z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1063.3,610.7c-4.2-8.3-10.4-4.1-16-3.9
			c-4.2,0.1-9.8,1.8-12-2.1c-2.9-5.1-3.1-12.9-0.2-16.8c4.8-6.5,13.6-1.8,20.6-2.6c4.7-0.5,5.9,3.8,5.1,7.4
			C1059.5,598.8,1061.1,604.4,1063.3,610.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1138,255.1c7.9-0.8,15.4-2.1,15,10
			c-0.4,12.3-8.2,11.3-16.8,11.6c-9.1,0.3-10.8-3.5-10.8-11.8C1125.4,254.7,1129.5,252.5,1138,255.1z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1070.8,309.6c-6.3-4.2-13.6-2.1-20.5-2.4
			c-12.3-0.5-5.7-9.5-6.8-14.8c-1.2-5.8,1.4-7.6,7.9-7.7c11.6-0.1,20.3,1.1,17.3,16C1068.1,303.8,1073.1,305.5,1070.8,309.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1123.6,353.7c0.9-3.8-2.4-12.8,5.8-9.7
			c7,2.6,20.8-8.1,21.1,9.5c0.2,12.7-7.9,12.3-17,12.5C1124.1,366.2,1121.6,362.3,1123.6,353.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1058,761c-4.2-7.1-10.6-4.3-16.4-4.2
			c-10.8,0.2-16.8-9.8-11.6-19.2c2.9-5.1,7.8-1.5,11.7-2.2c11.2-2,17.8,0.6,13.8,13.7C1054.1,753.5,1060.4,755.3,1058,761z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1063.6,579.7c-7.2-5-14.5-2.4-21.4-2.4
			c-7.3,0-7.8-3.9-6.4-8.9c1.4-4.8-4.7-13.5,6.9-13.6c16.9-0.1,16.8-0.4,18.5,15.7C1061.6,573.4,1062.7,576.3,1063.6,579.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1055.9,819.1c-7.4-4.2-14.9-1.7-22-2.1
			c-7.6-0.3-7.5-4.5-5.4-9.2c1.9-4.4-5.2-12.5,7.1-13.2c14-0.8,22,1.2,18.9,17.5C1054.1,814.1,1057,816,1055.9,819.1z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1058.3,276.7c-15.7-0.1-15.9-0.4-14.6-13.8
			c0.6-6.5,2.4-10.6,10.1-7.5c1.4,0.6,3.1,0.7,4.6,0.5c6.8-0.7,14.7-1.1,14.3,8.1C1072.3,272.4,1068.1,279.7,1058.3,276.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1054.2,877.6c-5.3,0-10.3-0.1-15.2,0
			c-9.5,0.3-14.1-3-12.8-13.7c0.8-6.6,1.8-8.9,9.3-9.2c11.6-0.4,20,0,16.7,15.2C1051.8,872.2,1053.5,874.9,1054.2,877.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1132.6,454.9c-3.9-0.9-10.6,2.5-11.3-4.5
			c-0.6-5.6-2.9-14.6,1.7-16.4c6.5-2.6,15-0.9,22.4,0.4c2.3,0.4,5.1,4.7,3.5,9.7C1146.2,452.5,1141.7,456.7,1132.6,454.9z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1043.3,675.8c6.3,0,15-4.9,14.9,7.6
			c-0.1,12.9-1.4,13.4-16.3,13.9c-9.5,0.3-11-3.5-10.4-11.7C1032.3,675.6,1031.8,675.6,1043.3,675.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1037.8,906.2c-7.7,0.9-15.1,0.4-14.8-9.4
			c0.2-6.3,1.6-14,11.4-11.3c6.4,1.8,17.8-7.1,17.7,9.4C1052.1,906.5,1046.2,907.4,1037.8,906.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1066.3,491.4c-6.9-6.8-13.9-3.5-20.1-4.1
			c-12.2-1.2-7-9.9-7.7-15.8c-0.9-7.7,4.7-5.6,9.3-6.1c11.9-1.3,18.8,1.5,16.1,15.3C1063.5,483.5,1065.2,486.6,1066.3,491.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1140.3,195.9c7.2-0.4,14.8-2,14.7,9.3
			c-0.1,12.9-8.9,10.2-17,11.1c-9.4,1-14.1-5.7-14-11.4C1124.2,195.9,1132.2,193.9,1140.3,195.9z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1126.5,700.9c7.8-1.3,13.1-1.1,13,9.2
			c-0.1,10.7-4.8,13.2-14.2,12.6c-6.8-0.5-12.4,0.6-12.3-10C1113.1,701.6,1116.5,697.9,1126.5,700.9z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1044.7,666.8c-3.9-2-13.6,4.5-12.4-7
			c0.6-5.3-3.3-15.4,8-13.9c7,1,18.1-5.6,20.4,6.5C1063,664,1059,666.7,1044.7,666.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1044.6,705.4c7.6-1.8,12.5-1.9,12.7,8.7
			c0.2,13.2-7.8,12.4-16.5,13.1c-7.6,0.6-9.7-1.8-10-9.7C1030.3,706.1,1034.2,702.4,1044.6,705.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1122.2,730.5c7.8,1.3,16.9-4.4,16.8,8
			c-0.1,10.4-4.9,14.7-15.5,13.7c-7.7-0.7-12.4-1.4-12-11.7C1111.8,730,1116,728.8,1122.2,730.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1123.4,820.2c6.7-2,14.1-3,12.3,7.3
			c-0.8,4.7,1.1,15.2-5.2,13.5c-7-2-20.9,8.5-21.8-9.7C1108.2,818.8,1113.8,817.6,1123.4,820.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1123,781.4c-3.5-0.8-9.6,2.5-11-2.7
			c-1.5-5.4-0.5-11.6-0.1-17.3c0.2-2.1,2.7-2.4,4.8-1.9c2.6,0.6,5.2,1.3,7.9,1.7c5.5,0.8,14.4-4.9,13.5,7.5
			C1137.4,778.1,1134.7,784.8,1123,781.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1130.5,522.6c9.7-2.3,13.8,0.4,13.4,10.9
			c-0.5,13.3-9.8,9.9-17.2,11.1c-9.7,1.6-8-5-8.3-10.8C1117.9,524.5,1120.1,518.8,1130.5,522.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1049.6,426.4c-8,2.4-10-1.9-9.3-9.2
			c0.5-6.1-2.1-14.7,9.1-12.3c6.2,1.3,16.4-5.3,16.9,8.9C1066.8,427,1058.9,426.8,1049.6,426.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1053.8,366.2c-8.6,1.2-14.8,0.1-12.5-10.4
			c1-4.3-3.1-13.3,6.7-10.3c6.8,2,19-6.6,19.9,8.4C1068.6,363.9,1064,369,1053.8,366.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1128.5,603.8c-4.7-1.8-11.1,2.7-12-4.9
			c-0.7-5.7-1-14.5,3.1-16.2c6.2-2.5,15-2.3,21.3,0.3c5.4,2.2,2.3,9.5,1.2,14.8C1140.1,607.4,1132.2,600.7,1128.5,603.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1129.1,611.3c5.9,0.6,14.1-3.2,14,8.3
			c0,12.7-8.2,12.1-17.2,12.5c-7.6,0.3-10.5-1.6-10.1-9.6C1116.4,611.3,1116,611.3,1129.1,611.3z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1047.6,546.7c-10.6,3.6-12.2-3.9-12.1-13.1
			c0.1-4.9,0.3-9.1,7.3-8.8c18.9,0.8,19.1,0.7,19.2,16.7C1062,546.7,1062,546.7,1047.6,546.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1059,195.5c6,0.4,13.7-2.9,13.7,8.4
			c-0.1,12.4-7.5,12-16.7,12.2c-9.4,0.2-10.2-3.6-10.5-11.6C1044.9,192.4,1052.2,195.1,1059,195.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1133.5,463.7c7.8-1.7,14-1.9,14,8.3
			c0,11.7-7.3,12.1-16.2,12.1c-7.3,0-11-0.8-11.1-9.8C1120.1,463.1,1124.4,460.4,1133.5,463.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1138.1,305.4c-7.5,0.5-14.7,1.3-12.7-9.3
			c0.9-4.9-2.9-13.7,7.5-11.3c6.9,1.6,18.9-5.8,19.5,9.2C1152.7,304.3,1146.7,307.3,1138.1,305.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1127.2,692.4c-7.7,0.2-15.5,1.5-13.2-9.3
			c0.9-4.3-3.5-13.7,5.4-11.6c7,1.6,20-7.4,21,7.9C1141,688.2,1137.7,695.8,1127.2,692.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1136.2,374.4c7.2-0.8,13.7-1.6,13.7,8.8
			c0,13.3-9.2,11-17.5,11.7c-9.2,0.8-9.2-4-9.7-11.1C1121.8,371.4,1129,373.2,1136.2,374.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1049.1,496c6.9,0.1,14.2-2.3,13.7,8.9
			c-0.4,8.9-4.9,13.5-12.4,13c-6.6-0.4-16.4,0-15.5-11.8C1035.5,496.4,1041,493.9,1049.1,496z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1057.2,789.6c-6.4-4.8-12-2.8-17.1-2.6
			c-8.2,0.3-11.8-1.7-11.4-11c0.4-8.7,2.5-10.6,11.5-10.7c11.7-0.2,16.9,2.5,14.6,14.9C1054.3,782.4,1056,785.2,1057.2,789.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1041.2,384.2c0.9-3.1-2.9-12,4.7-9
			c6.9,2.7,20.6-7.1,20.8,10.6c0.1,11.5-5.8,11.2-13.8,11.1C1044.3,396.9,1037.4,396.2,1041.2,384.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1049.2,636.4c-9,0-17.5,2.3-15.7-10.3
			c0.8-5.8-0.7-12.8,8.8-10.4c6.4,1.6,17.9-5.8,17.2,9.6C1059.1,633.9,1058.2,639.9,1049.2,636.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1139.4,226.5c8.8-2.1,14.3-1.9,14.2,9.7
			c-0.2,12.9-8.4,9.8-15.7,10.6c-9,0.9-10.8-2.8-10.8-11.2C1127,225,1131.4,223.5,1139.4,226.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1137.4,315.3c7.6-0.8,13.6-1.6,13.6,9.1
			c0.1,14.8-10.2,10.2-17.9,11.3c-9.9,1.4-7.7-5.4-8.3-11.2C1123.8,313.1,1130.4,313.6,1137.4,315.3z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1057.5,246.5c-8,1.1-14.2,0-12.2-10.6
			c0.9-4.8-1.7-12.9,7.6-10.2c6.6,1.9,18.4-5.8,18.6,10.1C1071.5,247.4,1065.5,247.5,1057.5,246.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1129.4,572.5c-7.2,1.5-12.8,0.9-11.3-8.7
			c0.8-5.1-2.6-13.8,7.6-11.4c6.3,1.5,17.7-7,18.4,8.3C1144.6,572,1138.5,573.8,1129.4,572.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1130.6,513.5c-7.5,2-11.3-0.3-10.6-9.3
			c0.5-6.6-0.3-13.4,9.5-11c6.1,1.5,16.7-5.7,16.7,8.9C1146.3,513.9,1139.5,514.5,1130.6,513.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1126.9,641.4c7.3,0.1,14.8-2.1,14.7,9.5
			c-0.1,13.3-9.2,10.3-16.9,11.3c-9.9,1.3-8.8-4.8-9.3-11.4C1114.7,640.9,1119.9,640,1126.9,641.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1134,404.4c7.2,0,14.6-2.1,14.5,8.7
			c-0.1,12.7-8.5,11.7-17.3,12c-8.9,0.4-8.3-4.5-8.8-10.8C1121.7,404.1,1126.5,402.3,1134,404.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1121.8,810.7c-11.6-0.1-17.5-9.8-12.7-19.1
			c1.4-2.7,3.6-2.6,5.9-2.2c2.6,0.5,5.2,2.2,7.7,2c7.4-0.3,13.9-2,12.9,10C1134.6,813,1127.1,810,1121.8,810.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1054.9,336.3c-11,0-16.4-8.9-11.7-18.8
			c1.3-2.8,3.5-2.8,5.7-2.3c2.6,0.5,5.1,2,7.7,2.2c5.8,0.5,13-2,11.9,8.6C1067.6,335,1066.5,336.3,1054.9,336.3z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1108.7,858.6c1.7-2.8-3.6-11.6,5.5-9.6
			c5,1.1,14.1-3.9,15.2,5.6c1.3,11-3.7,15.8-12.7,16.3C1107.1,871.4,1108.3,865.4,1108.7,858.6z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1081,206.2c-0.1-4.6-3-11.1,5.5-10.8
			c5.5,0.2,13.6-3.4,14.9,6c0.8,6.4,3,14.5-6.9,16.9c-3,0.7-6.1,0.7-9.2,0.9C1083.9,214.9,1088.4,208.6,1081,206.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1081,206.2c7.3,2.4,2.9,8.7,4.3,13.1
			c-0.4,3.7,1.3,7.9-4.3,11.1C1078.6,221.9,1077.7,214.2,1081,206.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M941.2,925.7c-4.3-5.4-2.4-11.4-4.8-16.9
			c-1-2.2,1.6-4.3,4.1-4.5c3.8-0.3,3,2,2.6,4.5C942.2,914.4,941.8,920.1,941.2,925.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1100.8,619.7c-0.2,2.9,0.5,6-2.5,6.3
			c-2.8,0.2-5-1.7-5.1-4.9c-0.1-2.7,1.9-4.2,4.1-4.8C1101,615.3,1100.1,618.6,1100.8,619.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1029.4,211.8c-6-5.3-5.8-9.1-1.1-13.5
			C1031.7,202.3,1030.5,206,1029.4,211.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1017.4,558.7c2.2,4.6,2,7.5,0.3,12.2
			C1014.7,566.7,1015.1,563.8,1017.4,558.7z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M925.5,368.7c2.1,9.8-3.1,19.3-1.4,29.1
			c-0.9,20.9-2.1,41.7-2.7,62.6c-0.2,5.3-1.5,8.1-7,7.9c-2.1-2.5-2.9-5.4-2.8-8.6c1-30.3,2-60.6,2.9-90.8
			C918.2,368.7,921.9,368.7,925.5,368.7z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M916.5,343.4c-0.1,2.3,0.7,4.8-1.5,6.7
			C914.4,347.6,912.6,344.8,916.5,343.4z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M914.7,361.7c0.3,0.6,0.6,1.2,0.8,1.8
			c0,0.1-0.5,0.4-0.8,0.7C914.6,363.4,914.6,362.5,914.7,361.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M868.8,375.6c-2.4-0.3-8.7,1.3-8.3-6.5
			c0.3-7.6,6.4-4.6,10.4-5.3c5.3-0.9,8.2,1.2,7.9,6.7C878.6,375.6,875.6,377.1,868.8,375.6z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M944.8,966.5c-2.5,0.9-2.2-0.9-2.4-1.6
			c-0.2-1.5-0.1-3.2,1.8-3.5c1.5-0.3,3.3,0.2,2.8,2.2C946.7,964.8,945.3,965.8,944.8,966.5z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M484.8,692.2c-7.7-11.6-15.6-22.8-14.5-37.9
			c1.1-15.4,7.1-27.3,21.4-34.3c4.1,3.1,9.3,1.3,13.7,3c5.8,9.4,13.5,17.4,14.7,29.5c1.7,17.3-4.7,30.7-17,42
			C496.9,693.7,490.9,693,484.8,692.2z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M503,740.3c2.7,6.9,8.7,11.7,11.3,18.8
			c6.7,18,2.5,35.9-12.2,48.1c-1.5,1.3-3.2,2.2-3.4,4.4c-5.4-0.7-10.8-1.4-16.2-2.1c-1.8-7-9.2-10.2-11.5-17.2
			c-6.8-21.1-4.5-39.6,13.9-54.2C490.9,738.9,497,739.6,503,740.3z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M667.9,952.5c-6.6-9.8-14.9-18.5-16.6-31.1
			c-2.4-17.4,4.8-30.6,16.2-42.5c7.9,1.2,15.9,2.4,23.8,3.5c3.2,7.3,7.1,14.5,9.6,22.1c6.8,20.7-1.3,36.8-16.8,50.3
			C678.7,954,673.3,953.2,667.9,952.5z M679.3,915.4c-2.1-3.2-6.7-2.7-11.3-3.8c-5.7-1.4-2.9,2.5-3.1,3.7c-1.2,8.1,6.2,5,9.6,6.6
			C677,923,680.4,921.5,679.3,915.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M383.6,786.6c1.5,13,3.1,26.1,1.6,39.1
			c-2.7,22.7-1,45.4-2.1,68.1c-0.2,4-0.1,7.8-4.2,10.1l0.1,0.2c0.9-13.2,1.9-26.4,2.6-39.7c0.5-9.1-2.9-14-13.2-15.3
			c-45.7-5.6-91.3-11.9-137-18c-1.4-0.2-3.2,0.4-4.4-3.3c19.7,2.4,38.6,4.7,57.5,7c27.9,3.5,55.8,6.8,83.6,10.6
			c10.3,1.4,14.3-2.2,14-12.6c-0.3-8.9,0.3-18,1-26.9c0.7-8.7-3.2-13.9-11.5-14.3c-22.8-1.2-45.1-5.5-68-6.7
			c-25.3-1.3-50.3-7.5-75.1-13.6c21.2,2.4,42.5,4.7,63.7,7.2c26.7,3.2,53.4,6.5,80.1,10c4.2,0.6,7.6-1.4,11.4-1.8L383.6,786.6z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M679.2,638.2c2.3,3.8,4.8,3.6,7.3,0.3
			c3,1,6,2,9.1,3c2,7.4,9.5,11.1,11.9,18.5c6.7,21.2,1.6,39-14,54.2c-7.1,0-14.2-0.5-21.1-2.4c-7-11.2-15.3-21.4-14.5-36.3
			c0.7-11.8,2.8-22.1,11.7-30.2c1.8-1.6,3.1-3.4,3-6C674.7,638.8,676.9,638.5,679.2,638.2z M662.4,678.7c0.4,1.8,0.8,6.5,2.4,10.8
			c3.4,9.2,8.9,17.2,19.8,17c11.7-0.3,15.5-9.9,18.6-19.3c1.6-4.9,1.6-9.9,0.8-15c-2.5-15.7-12-26-23-24.9
			C670.3,648.4,662.3,660.5,662.4,678.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M738,857c1.1-41.4,2.2-82.8,3.4-124.1
			c1.3-43.7,2.7-87.4,4.2-131c0.2-5.9-0.2-11.7-2.8-17.1l-0.1,0.1c7.3,3.4,6.3,10.3,5.7,16.1c-3.1,31.5-1.6,63.1-3.2,94.6
			c-2.6,49-2.5,98.2-3.6,147.3c-0.1,4.9,0.9,10.3-3.7,14L738,857z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M273.8,549.8c-0.8-13.8,10.9-7.5,12.2-6.1
			c4.6,4.8,6.2,3.3,12,1.2c6.4-2.4,16.6,2.2,24,1.6c9.9-0.8,9.8,3.3,10.9,9.8c1.1,6.4-2.1,7.5-7.2,7.1c-12.4-1.1-24.8-5.6-37.4-1.1
			C286.4,554.7,268.8,564.2,273.8,549.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M571.4,840.5c-14.5,6.6-29,1-43.3-0.6
			c-35.6-3.9-71.2-8.9-106.8-13.4c-13.4-1.7-16.1-4.6-16.2-17.7c0-5.8,0-11.6,1.3-17.5c0.1,2.2,0.4,4.4,0.4,6.6
			c0.1,24,0.2,23.7,24.6,26.5c41.1,4.7,82.1,9.6,123,15.2c9.7,1.3,12.9-1.4,13.2-10.5c0.6-17.9,1.6-35.8,3.6-53.7
			c0,21.7,0,43.5,0,65.2L571.4,840.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M385.9,681.1c3.9,18.9,3.5,37.7-2.3,56.2
			c0,0,0.1-0.1,0.1-0.1c-20.2-6.7-41.6-6.1-62.3-9.2c-26.6-3.9-53.5-6.1-80.3-9.4c-7.7-0.9-12,0.5-11.4,9.5
			c0.6,8.2,0.1,16.4,0.2,24.6c0-10,0.7-20-0.2-29.9c-0.8-8.3,2.3-8.7,9.2-7.9c43.8,5.3,87.7,9.9,131.5,15.4
			c11,1.4,15.5-3.1,15.7-13.1c0.3-12.1,0-24.2-0.1-36.4L385.9,681.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M580.8,570.7c-1.1,2.4-2.3,4.9-4.1,8.8
			c-3.2-10.7-11.3-10.8-19.6-11.5c-46.4-3.8-92.8-8-139-13.7c54.2,5.1,109.1,3.1,162.5,16.3L580.8,570.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M714.5,838.2c-5.9,5.7-13.8,3-19.8,2.6
			c-20-1.5-39.9-5.2-59.9-8.4c-23.4-3.8-23.5-3.9-23.3-28.5c0.1-14.8,0.4-29.6,0.9-44.4c0.1-2.2,1.2-4.5,3.6-5.7l-0.1-0.1
			c-1.3,19.7-3,39.4-2.9,59.1c0.1,8.8,2.8,13.7,12.4,14.5c15.8,1.4,31.4,3.6,47.2,5.5c4.6,0.4,9.1,0.9,13.7,1.3
			C695.5,837.3,705.2,836.6,714.5,838.2L714.5,838.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M522,932.1c-2.6,5.2-6.9,3.6-11.1,3
			c-25-3.5-50-7.1-75.1-10.2c-9.1-1.1-13.6-6.4-13.6-14.5c0.1-18.6,1.2-37.1,1.9-55.7c0.1-2.5,1.3-4.4,4.2-4.7l-0.1-0.2
			c-4.6,20-2.1,40.3-2.8,60.4c-0.2,5.7,2.7,9.6,8.8,10.3c15.4,1.9,30.7,3.9,46,5.9c4.5,0.6,9.1,1.2,13.6,1.9
			c9.3,2.2,18.7,3.8,28.3,3.8L522,932.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M737.9,856.9c0.3,8.2-4.9,8.6-10.9,7.8
			c-37.1-4.7-74.3-9.4-111.4-14c-9.7-1.2-19.4-2.2-29.1-3.4c-4.5-0.6-9.5,0-12.7-4.4c0,0-0.1,0.1-0.1,0.1
			c31.2,1.6,61.9,7.1,92.8,10.7c20.1,2.3,40.2,5.1,60.2,7.9c4.9,0.7,8.3-1.3,11.4-4.5C738,857,737.9,856.9,737.9,856.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M672.2,711.7c6.9,2,14,2.5,21.1,2.4
			c6,3.7,12.8,1.9,19.3,2.5c4,0.4,6.7-2.3,7.2-6.2c2.3-22.4,4.8-44.8-0.5-67.1l-0.1,0.1c7.7,3.2,6.3,10.3,5.8,15.9
			c-1.5,15.6-1.2,31.2-1.6,46.8c-0.3,9.8-4.1,15.6-15.1,14.3c-27.5-3.1-55-6.1-82.6-9.1c-3.3-0.4-6.1-1.2-7.4-4.6l-0.1,0.1
			C636.2,708.6,654,712.3,672.2,711.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M65.9,640.7c47.9,5,95.8,9.9,143.7,14.9
			c-0.1,1.2-0.2,2.4-0.4,3.5c-47.8-5-95.6-10-143.4-15C65.9,643,65.9,641.8,65.9,640.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M209.9,709.1c0,3.2,0,6.3,0.1,9.5l0.1-0.1
			c-19.5-7.8-40.5-6.6-60.8-9.4c-28.5-3.9-57.2-6.6-86-9.8c1.1-5.4,4.4-3.2,6.6-2.9c43.4,4.9,86.7,9.8,130.1,14.9
			c3.7,0.4,6.9,0,10.1-2L209.9,709.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M428.3,850c0.9-6.9,6.3-4.8,9.9-4.4
			c25.8,3,51.6,6.4,77.3,9.6c7.3,0.9,11.9,5.5,11.9,12.2c-0.1,20.1-1,40.2-1.9,60.3c-0.1,1.5-2.3,2.9-3.5,4.3c0,0,0.1,0.1,0.1,0.1
			c4-16.5,2.4-33.4,2.8-50.1c0.4-21.7,0.2-21.7-20.9-24.6c-1.1-0.2-2.3,0.2-3.5,0.3c-6.8-1.1-13.4-3.3-20.5-2.5
			c-17.2-2.7-34.3-6.7-52-5.3L428.3,850z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M137.3,534.9c-0.3,3.4-0.6,6.7-1,11.3
			c-5.2-3.4-1.9-8.5-4.1-11.7c-3.4,0.8-1.8,3.7-2.5,5.5c-1.3,3.1-2.9,6-6.7,4.7c-4.6-1.6-4.2-5.9-4.3-9.8c0-3.4,0.7-6.5,5-6.2
			c11.4,0.7,22.8,1,34,2.9c7.1,1.2,3.6,8.6,4.1,12.9c0.5,3.8-3.6,4.3-7.8,3.8C145.3,547.1,137.7,545.9,137.3,534.9z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M564.4,953.3c-0.7-3.5,1.7-6.9-4.5-9.8
			c-6.9-3.1-1.1-12.2-2.2-18.6c-1-5.6,4.1-4.1,7-5.1c1.4,0.1,2.8,0.3,4.2,0.4l-0.2,0c-1.9,17.5-1.9,17.5,15.5,18.4
			c1.1,0.1,2.3-0.3,3.4-0.5c4.2,12.3,3.6,13.5-8.3,14.5c-5,0.4-10.1,0.3-15.2,0.5C564.2,953.1,564.4,953.3,564.4,953.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M207.6,767.8c0.2,2.7-0.6,4.6-3.9,4.2
			c-46.4-5.8-92.8-11.5-139.3-17.3c-0.5-0.1-0.8-1.1-2.2-3.4C111.5,756.9,159.6,762.4,207.6,767.8
			C207.7,767.9,207.6,767.8,207.6,767.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M71.8,808c41.7,5.1,81.8,10,121.9,14.9
			c1.2,0.1,2.7,0.1,3.4,0.9c2.7,2.9,11-5.5,9.5,3.9c-1.2,7.5-7.2,0.8-9.9,0.4c-38.8-4.8-77.5-10.6-116.2-16.2
			C78,811.6,74.9,812.7,71.8,808z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M482.5,809.5c5.4,0.7,10.8,1.4,16.2,2.1
			c4,0.8,8.1,1.6,14.3,2.8c-11.8,5.5-21.4,0.1-30.9,0c-14.2-0.1-28.4-4.3-42.7-6c-9.7-1.2-13.6-5.8-13.4-15.4c0.4-16,0.3-32,0.6-48
			c0.1-3.8-0.7-8,4-10c0,0-0.1-0.1-0.1-0.1c-0.9,18.7-1.8,37.4-2.6,56.1c-0.3,6.9,1.6,12.9,9.4,13.9
			C452.3,806.7,467.4,808,482.5,809.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M232.5,658.1c45.2,4.7,90.4,9.4,135.6,14.1
			c-0.1,1.1-0.2,2.2-0.3,3.3c-45.2-4.8-90.4-9.5-135.6-14.3C232.3,660.2,232.4,659.2,232.5,658.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M672.1,598.3c1.3,4.1,2,6.6,3.1,9.9
			c1.9-3.4,3.4-6.1,4.7-8.6c4.8,1.3,3.7,5.2,4.3,7.9c0.7,2.9,2.3,9.4,0.1,8.5c-5-2.2-10.7,5.1-14.7-2.1c-2.5-4.5-6.1-8.7-10.8-1.1
			c-2.4,3.9-6.6,3.5-8.6-0.2c-2.6-4.7-11.4-5.8-8.2-13.7c1-2.6,2.7-2.3,3.9,0.2c0.7,1.7,1,4.3,3.3,1.1c6.4-8.8,10.6,2,16.1,2.3
			C668.4,602.7,668.6,604.6,672.1,598.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M386,681c-3-1.7-5.2-4.2-3.2-7.6
			c8.1-13.7,2.5-29.1,5.2-43.5c1.2-6.2-3.7-10.3-10.9-10.9c-14.5-1.2-28.8-3.1-43.3-4.8c0.1-1.1,0.2-2.2,0.3-3.3
			c16.3,1.9,32.7,3.1,48.8,5.9c6.5,1.2,7.3,8.3,7.3,14.5c-0.1,16.7,0.4,33.4-4.4,49.7C385.9,681.1,386,681,386,681z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M667.9,952.5c5.4,0.8,10.8,1.5,16.2,2.3
			c9.3,1.8,18.6,3.7,28.2,3.3l-0.1-0.1c-3.5,6.2-9.8,4.9-14.6,4.1c-26.8-4.8-53.6-8.6-80.7-11.5c-0.9-0.1-1.8-0.9-2.4-3.8
			C632.3,948.6,650.1,950.6,667.9,952.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M691.3,882.4c-7.9-1.2-15.9-2.4-23.8-3.5
			c-18.4-2.7-36.6-6.7-55.3-5.8c1.3-4.5,5.3-4.6,9.2-4.1c29,3.8,58,7.6,86.9,11.6c1.4,0.2,2.6,1.5,3.8,2.3l0.1-0.1
			C705.3,882.6,698.3,882.5,691.3,882.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M613.5,595.5c-0.8,5.1,2.6,12.4-2.7,13.9
			c-6.9,1.9-15.4,1.7-22.1-0.8c-5.5-2.1-3.4-10-1.6-14.6c1.6-4.3,4.8-2.1,8.1,0.3c2.8,2,6.8,1.2,9.9,4.8
			C609.4,603.7,608.7,594.6,613.5,595.5z M596.3,600.5c0.2-0.2,0.6-0.5,0.6-0.7c0-0.2-0.4-0.5-0.6-0.7c-0.2,0.2-0.6,0.5-0.6,0.7
			C595.7,600,596.1,600.3,596.3,600.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M616,753.8c2.6-5.7,7.5-4.4,12.1-3.9
			c26.2,2.9,52.3,5.9,78.5,8.9c4.4,0.5,9.8-0.1,10.3,6.7c0,0,0.1-0.1,0.1-0.1c-8.4-3.6-17.1-4.5-26.1-4.2
			c-6.1-0.7-12.2-1.4-18.2-2.2c-18.8-2.5-37.5-6.1-56.7-5.4L616,753.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M503,740.3c-6-0.7-12.1-1.5-18.1-2.2
			c-11.1-1.3-22.1-2.5-33.2-3.9c-7.1-0.9-14.3-3.8-21.2,0.7c0,0,0.1,0.1,0.1,0.1c-0.1-5.4,3.5-6.6,7.7-6.2c27.3,2.9,54.5,6,81.8,9.1
			c2.7,0.3,5.2,1.4,6.6,4.1l0.2-0.1C518.9,741.4,511,740.8,503,740.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M484.8,692.2c6.1,0.7,12.2,1.4,18.2,2.2
			c4,0.8,8,1.5,15.4,2.9c-14.1,3.9-24.5-0.6-34.9-0.7c-14.2-0.2-28.1-5.2-42.6-2.8c-3.6,0.6-5.8-3.2-8-5.8l-0.1,0.1
			C450.2,687.9,467.3,693.1,484.8,692.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M693.1,602.5c11.2-2.9,15,0.2,13,9
			c-0.4,1.6-4.6,2.5-0.4,4.6c2.7,1.4,4.7,0.2,5.5-2.4c0.7-2.2,0.8-4.6,1.1-6.9c0.3-2.5,0.9-4.8,4.1-4.5c2.8,0.3,5.5,1.1,5.5,4.8
			c0,4.3,0,8.6,0,13.6c-6.9-2.7-0.5-9.2-5.4-12.5c-1.8,7.1-4,14.6-13.7,11.5C693.8,617,703.9,605.2,693.1,602.5z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M587.7,938.2c-1.1,0.2-2.3,0.5-3.4,0.5
			c-17.4-1-17.4-1-15.5-18.4c7.2,0.2,14.3,0.5,21.5,0.7C589.4,926.7,588.5,932.5,587.7,938.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M641.4,671.5c-0.3,5.6-1.9,11-8,10.9
			c-5.9-0.1-7.9-5.5-7.9-10.9c0-5.2,0.8-11,7.2-11.3C639.7,660,640.5,666.2,641.4,671.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M567.2,867c-0.1,3.9,3.9,4.5,5.5,7
			c2.7,4.3,0.2,7-3.4,8.1c-10.7,3.1-19.5-4.6-18.6-15.9c0.1-1,0.7-2,1.3-3.4c5.4,2.9,0.2,9,4.2,11.9c4.1-2.2-2.5-15.7,9.7-9.2
			C566.4,866.1,566.8,866.5,567.2,867z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M634.5,909.4c-0.6,5.5-1.4,11.2-8.1,10.9
			c-6.4-0.3-7.7-5.7-7.8-11.1c-0.1-5.5,1.4-11.1,7.7-10.9C632.1,898.6,633.4,904.6,634.5,909.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M636.5,612.8c0-4.9,0-8.6,0-12.3
			c-3.4,8.6-6.9,15.6-17.8,9.5c-2.2-1.2-3.4-5.6-2.7-11c1.1-8.8,7.8,1.1,9.7-4.8c1.6,4.8-2.2,6.2-2.1,8.9c0.1,2,0.9,4,3,4.5
			c2.7,0.6,2.8-1.7,3.3-3.6c1-3.6-1.8-10.2,5.5-9c7.1,1.1,3.4,7.5,4.1,11.6C639.7,608.3,640.3,610.6,636.5,612.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M357,557.2c-0.4,5.2-1.8,10.5-7.6,10
			c-6-0.5-7.5-6.3-7.3-11.6c0.1-4.9,2-10.2,7.6-9.9C356.2,546.1,356.1,552.5,357,557.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M350.3,815.5c-0.5,4.9-1.7,10.4-7.6,9.8
			c-5.8-0.6-7.8-6.1-7.6-11.6c0.2-5,1.9-10.3,7.6-10C348.9,804.2,349.3,810.5,350.3,815.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M717.9,582.2c-22.3-2-44.6-3.9-66.9-5.9
			c0.1-1,0.2-2.1,0.3-3.1c22.2,1.8,44.4,3.7,66.6,5.5C717.9,579.9,717.9,581.1,717.9,582.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M555.4,958.3c-5.2,0.3-6.5-2.5-6.3-7.3
			c0.5-14,1.2-28,0.8-42c-0.3-9.7,2.4-13.3,11.9-8.6c-9.1,16-12,33-7,51.2C555,953.8,555.2,956.1,555.4,958.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M207,874.8c-5,9.9-35,9.7-56.4,0.8
			c14.7-1.5,27.5-1.7,39.6,3.8C196.7,882.4,200.1,876.3,207,874.8z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M180.8,682.4c-1,4.1-1.6,9.5-6.9,9.4
			c-6.3-0.1-7.9-5.8-7.9-11c0-4.7,1.5-10.6,7.2-10.2C179.3,671,180.1,677.2,180.8,682.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M716.8,765.5c3,1,4.3,3.5,4.2,6.3
			c-0.2,18.6-0.6,37.2-1.1,55.8c-0.1,2.1-0.6,4.7-3.1,5.8c0,0,0.1,0.1,0.1,0.1c-0.8-22.7,3.8-45.4,0-68.1L716.8,765.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M439.5,652.7c0.5-5.4,0.8-11.5,7.5-11
			c6,0.5,7.1,6.1,7.2,11.4c0.1,5.1-1.5,10.1-7,10.1C440.9,663.3,439.8,657.7,439.5,652.7z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M355,642.6c-0.6,5.2-1.4,10.1-6.8,10.3
			c-6.7,0.3-8-5.6-8.3-10.6c-0.4-4.9,1.6-10.2,7.2-10.2C354,631.9,353.3,638.7,355,642.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M181.8,626c0,4.6-1.1,9.5-6.2,9.5
			c-6.2,0-7.9-5.3-7.8-10.8c0.1-4.8,1.1-10.3,6.9-10.1C181.2,615,181.7,620.9,181.8,626z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M348.3,872.6c-0.3,5.4-1.3,10.1-6.8,9.9
			c-6.7-0.2-7.4-5.8-7.3-11.1c0.1-4.9,0.8-10.3,7.2-9.7C348,862.3,347.5,868.5,348.3,872.6z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M161.3,848.4c0-4.1-1-9.7,5.2-9.6
			c7,0.1,8.3,5.7,8.2,11.6c0,4.7-0.9,8.7-6.7,8.7C160,859.2,161.3,853.3,161.3,848.4z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M351.1,757.6c-0.2,4.5-0.2,10.1-6.6,10.1
			c-5.9,0-7.4-5.1-7.6-10c-0.2-5.3,0.8-11.1,7.5-11C350.4,746.9,350.8,752.5,351.1,757.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M679.2,638.2c-2.2,0.3-4.4,0.7-6.7,1
			c-16.8-1.9-33.6-3.8-50.5-5.8c0.8-3.8,3.7-2.9,6.4-2.7c13.9,1.2,27.8,2.5,41.7,3.7C673.5,634.7,677.3,634.2,679.2,638.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M179.1,738.1c-0.5,4.7-1.7,9.6-6.7,9.6
			c-6.4-0.1-7.6-5.8-7.7-10.9c-0.1-4.9,1.7-10.3,7.2-10C178.1,727.2,178.5,733.4,179.1,738.1z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M353,700c-0.1,4.8-0.9,10.3-6.8,10.4
			c-5.7,0.2-7.4-5-7.5-10c-0.1-5.3,0.7-11.3,7.3-11.2C351.7,689.3,352.4,695.1,353,700z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M184.1,540.6c-0.1,5.9-1.2,10.8-6.4,10.9
			c-6.7,0.1-7.5-5.7-7.6-10.9c0-4.5,1.1-9.5,6.3-9.7C183.3,530.7,183.2,537,184.1,540.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M378.9,903.9c-1.5,3.5-4.3,4-7.7,3.5
			c-15-2.1-29.9-4.2-44.9-6.3c0.1-1,0.2-2,0.3-3.1c17.5,2,35,4,52.4,6C379,904,378.9,903.9,378.9,903.9z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M177.2,793.2c-0.2,5.5-1.1,10.1-6.6,10.1
			c-6,0-7.3-5-7.4-9.9c-0.1-4.9,1.1-10.4,6.7-10.5C176.4,782.9,176.6,789.1,177.2,793.2z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M590.2,921c-7.2-0.2-14.3-0.5-21.5-0.7
			c0,0,0.2,0,0.2,0c-0.1-2.9-0.3-5.8-0.4-8.7c1-0.5,2.1-1.1,3.1-1.6c2.8,0.9,4-0.3,4-3C586.3,905.5,592,909.3,590.2,921z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M580.7,570.6c0.1-1.8,1.2-3.3,2.8-3.2
			c18.1,1.3,36.2,1.7,53.9,7.3c-18.8-1.3-37.7-2.7-56.5-4C580.8,570.7,580.7,570.6,580.7,570.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M714.4,887.6c4.9,2.5,4.2,7.2,3.8,11.3
			c-1.9,16.3-1.5,32.6-1.7,48.9c-0.1,4-0.3,7.8-4.3,10.1c0,0,0.1,0.1,0.1,0.1c3.5-23.4,3-47,2.3-70.5L714.4,887.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M212.6,603.2c-18.1-1.9-34.2-3.7-53.4-5.7
			C172,594,202.3,597.5,212.6,603.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M531.4,627.1c4.7,18,1.2,36.3,1.7,54.4
			c0.1,4.7,1.9,10.4-4.1,13.5l0.2,0.1c2.7-22.7,3.3-45.4,2.4-68.2L531.4,627.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M479.7,616.4c-13.7,4.1-27,0-40.6,0.1
			c0.1-1.2,0.2-2.3,0.3-3.5C452.8,614.1,466.3,615.2,479.7,616.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M383.7,786.7c0-16.5,0-33,0-49.5
			c0,0-0.1,0.1-0.1,0.1c6.7,5,3.7,12.2,3.7,18.4c0.1,10.4,0.7,20.9-3.7,30.8C383.6,786.6,383.7,786.7,383.7,786.7z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M545.8,898.7c-2.4,20.4-3.6,40.8-3.7,61.3
			c0-20.5,0-40.9,0-61.4C543.3,898.6,544.6,898.7,545.8,898.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M376.2,876.8c-0.7,3.5-0.3,8.8-5.8,8
			c-4.6-0.7-4.8-5.7-4.6-9.6c0.2-3.3,0.7-8.1,5.2-7.8C376,867.8,376.1,872.6,376.2,876.8z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M555.4,958.3c-0.2-2.3-0.4-4.5-0.6-6.7
			c4.1-4.3,6.1,2.9,9.6,1.7c0,0-0.2-0.1-0.2-0.1c2.9,7.4,10.1,5.3,15.5,7c3,0.9,7-1.8,9.8,4.5C577.4,962.4,566.4,960.3,555.4,958.3z
			"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M360.5,759.8c0.1-3.3-1.2-8.2,3.9-8.2
			c6.9,0,4.2,6,4.7,9.8c0.5,3.7,0.3,8-4.5,7.6C359.2,768.6,360.1,763.7,360.5,759.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M584.9,875.8c1.3,5.2-2,8.6-5.8,8.5
			c-6.1-0.1-3.4-5.9-3.4-9.2c0-3.3-2.3-8.9,4.1-8.9C586.6,866.2,584.2,872.2,584.9,875.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M505.4,622.9c-4.4-1.6-9.6,0.1-13.7-3
			c13-2.2,25.6-2.3,37.4,4.7c0,0,0.1-0.1,0.1-0.1C521.2,624,513.3,623.5,505.4,622.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M724.2,602.3c4.8,4.6,5.5,8.5,9,10.4
			c2.6,1.4,2.5,4.6,0.8,7.2c-1.5,2.2-3.7,2.6-6.1,2.2c-2.9-0.5-3.6-2.6-3.7-5.1C724.2,613,724.2,608.9,724.2,602.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M191,851.1c0,4.5,0.7,10-4.5,9.6
			c-8.4-0.6,0-8.8-4.3-12.4c-1.6-1.3,0.4-5.1,4.1-5.1C192.9,843.3,190,848.7,191,851.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M362.8,646c0.1-4,0.7-9.6,4.9-9
			c4.8,0.6,2,7.3,3.9,11c1.2,2.3-0.6,5.9-3.9,5.8C363.1,653.6,363,649.6,362.8,646z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M362.3,826.1c-2.9-0.8-1.1-3.3-1.9-4.8
			c-1.9-3.9-4.1-7.9,0.1-11.7c1.2-1.1,2.9-1.4,4.2-0.1c3.9,3.9,2.8,8.6,1.7,13.2C366,824.5,364.5,826,362.3,826.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M695.5,641.4c-3-1-6-2-9.1-3
			c11.5-1.9,22.8-3.4,32.7,5.1c0,0,0.1-0.1,0.1-0.1C711.4,642.4,703.6,639.8,695.5,641.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M195.1,739.8c0.2,2.8,0.8,6.1-2.3,8.1
			c-1.1,0.7-3,0.8-4.2,0.3c-1.7-0.8-1.7-2.5,0.1-3.4c3.2-1.6-0.3-4.8,1.1-5.9c3.5-2.8-6.5-6.6,0.4-7.7
			C195.4,730.4,195.4,735.6,195.1,739.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M209.9,718.5c2.4,16.6,5.6,33.2-2.4,49.3
			c0,0,0.1,0.1,0.1,0.1c4.3-16.3,2.4-32.9,2.4-49.4C210,718.4,209.9,718.5,209.9,718.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M192.4,787.7c1.4,5.6,1.7,10.6,0.6,15.8
			C182.7,798.3,182.7,797.9,192.4,787.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M363.4,709.9c0.1-5.3,4.3-10.8-2.5-16.1
			C373,695.4,373.1,696,363.4,709.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M191.5,628.3c1-2.6-3.8-7.3,1.1-7.5
			c4.9-0.3,2.6,5.3,2.8,8.2c0.1,2.4,2.5,7.5-2.9,6.7C187.1,634.9,192.7,630.7,191.5,628.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M359.9,867.7c3,5.6,3.4,11.2-0.2,19.5
			C357.1,878.9,358.7,873.3,359.9,867.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M742.7,584.7c-4-0.5-8-0.9-11.9-1.4
			C735.4,580.7,740,577.9,742.7,584.7C742.7,584.8,742.7,584.7,742.7,584.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M432.9,619.9c0,6.2,0,12.4,0,18.7
			c-1.5-6.2-4-12.6,0.1-18.6L432.9,619.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M193.4,685.5c1.2-3.9-3.1-6.3-3.5-10.3
			c6.1,3.7,6.1,3.7,3.7,10.6L193.4,685.5z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M568.5,911.5c0.1,2.9,0.3,5.8,0.4,8.7
			c-1.4-0.1-2.8-0.3-4.2-0.4C564.3,916.3,564.5,913.1,568.5,911.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M193.6,685.7c-1,2.2,3.5,4.9-0.4,6.7
			c-0.9,0.4-3.6-0.6-3.9-1.4c-1-3.2,0.9-4.9,4.1-5.5C193.4,685.5,193.6,685.7,193.6,685.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M526.7,742c1.9,1.3,3.7,2.7,2.6,5.4
			c-0.1,0.2-0.7,0.5-0.9,0.4c-2.4-1.5-1.6-3.8-1.5-6C526.9,741.9,526.7,742,526.7,742z"/>
		<path fill="#B0733E" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M575.6,906.9c0,2.7-1.1,4-4,3
			C571.6,907.2,573.1,906.4,575.6,906.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M688.2,601.6c-0.2-0.2-0.5-0.4-0.5-0.5
			c0-0.3,0.3-0.6,0.4-0.8c0.3,0.2,0.6,0.5,0.9,0.7C688.7,601.2,688.4,601.4,688.2,601.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M529,695c0.4,1.7,0,3-1.6,3.8
			c-0.3,0.2-1-0.5-1.5-0.7c1.1-1,2.1-2,3.2-2.9C529.2,695.1,529,695,529,695z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M565.9,865.6c1.5-1.5,3.5-2.4,4.5-0.3
			c0.6,1.3-1.9,1.6-3.3,1.8C566.8,866.5,566.4,866.1,565.9,865.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M712.1,882.8c2.2,0.9,2.5,2.7,2.3,4.8
			c0,0,0.2-0.1,0.2-0.1c-1.8-1.1-2.9-2.5-2.3-4.7L712.1,882.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M433,620.1c0.2-1.1,0.4-2.1,0.6-3.2
			c0.4,0.2,0.9,0.4,1.3,0.6c-0.7,0.8-1.3,1.6-2,2.4C432.9,619.9,433,620.1,433,620.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M432.9,688c-0.6-0.9-1.1-1.8-1.7-2.8
			c0.3-0.1,0.7-0.3,1-0.4c0.2,1.1,0.3,2.2,0.5,3.3C432.8,688.1,432.9,688,432.9,688z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M716.8,833.4c0.2,2.1,0.3,4-2.3,4.8
			c0,0,0.1,0.1,0.1,0.1c-0.5-2.2,0.7-3.6,2.3-4.7L716.8,833.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M618.4,706.8c-0.6-0.9-1.2-1.8-1.8-2.7
			c2.8-0.4,1.3,1.7,1.7,2.8C618.2,706.9,618.4,706.8,618.4,706.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M571.3,840.6c1.7-0.2,2.5,0.6,2.4,2.3
			c0,0,0.1-0.1,0.1-0.1c-1.5-0.1-2.2-1-2.3-2.4C571.4,840.5,571.3,840.6,571.3,840.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M531.6,626.9c-1.6,0-2.2-0.9-2.4-2.3
			c0,0-0.1,0.1-0.1,0.1c1.5,0.1,2.2,0.9,2.3,2.4C531.4,627.1,531.6,626.9,531.6,626.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M210,709.2c0.1-0.3,0.2-0.7,0.4-1
			c0.1-0.1,0.4-0.1,0.7-0.2c-0.4,0.3-0.8,0.7-1.2,1C209.9,709.1,210,709.2,210,709.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M327.3,103.6c-8.7,6.5-20.5-0.1-29.4,7.3
			c-3.4,2.8-1.7-3.6-3.5-4.9c3.1-2.2,2.9-4.8,0-10.5c0,5.4,0,8,0,10.5c-5.3-5.5-14-2.8-18-2c-7.4,1.5-11.9-1.5-17.7-2.6
			c-1.7-0.3-7.3,1.2-10.7,3.4c-2.1,1.4-4.4,0.7-6.1-1.6c-2.9-3.8,1.1-6.5,1.4-9.8c0.4-4.2,4.5-1.8,5.3-1.2c4.7,3.3,8.9-0.4,13.1-0.8
			c15.5-1.4,30.8-2,46,1.3c1.6,0.3,2.5,0.5,4-0.1c10.2-3.7,15,0.6,15.4,10.9C327.2,103.7,327.3,103.6,327.3,103.6z M283.7,95
			c-1.2,3.1-2.5,5.3,0.2,7.3C285.4,100.2,285.5,98.1,283.7,95z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M157.3,103.1c-9.7,2.1-11.3-2.8-10.5-13
			c1-13.8,7.7-20.2,20.5-21.9c1.1-0.1,2.3-0.2,3.4-0.5c3.5-1.1,3.6-8.9,8.5-5.6c4.8,3.2-0.1,8.5,0,12.9c0.1,3.8-1.6,7.5-2.1,11.4
			C175,103.2,175,103.2,157.3,103.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M669.1,92.2c-11.3,5-19.4,13.7-28.4,23.1
			c-1-8.9,1.4-16.9,3.2-24.9c0.8-3.5,5-4.6,6.3-3.2c7.6,7.6,19.8-6.5,24.5,4.3c2.9,6.8,1.2,16.4-3.6,23.5c-2.4,3.5-6.8,3.5-10.4,0.6
			c-1.6-1.3-2.8-3.1-1.8-5.1c0.8-1.5,2.3-1.2,4-0.6c6.4,2.4,8.8,0.4,4.4-5.4C663.4,99.3,672,98.8,669.1,92.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M238.7,92.4c-1.7,11.6-8.1,15.1-18.7,9.2
			c-2.3-1.3-2.5-0.2-4.1,0.3c-12.1,3.7-14.6,1.4-12.5-11.2c1-5.8,1.1-6,6.8-3.3c3.1,1.5,2.7-1.5,3.7-2.5c1.2-1.1,3.4-2.6,3.7-1.6
			c3.5,11.3,13.6,5.5,20.4,8.2L238.7,92.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M332.1,103.7c2.1-3.5,4.2-7.1,6.5-11.1
			c3.9,2.9-2.1,7.7,2,10c4.3-1.8,0.8-9.2,6.3-9.8c6.3-0.7,12.8-0.9,19.2-0.3c4.1,0.4,3.6,4.6,3.1,7.6c-0.7,3.9-1.1,11.4-5.4,9.8
			c-10.4-4-22.5,1.8-31.8-6.3L332.1,103.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M634.1,114.5c-3.6-1.4-0.6-5.1-2.6-7
			c-3.1,1.5-2.1,6.7-6.6,7.3c-3.7-2.2-0.8-6.5-3.1-9.7c-1.3,3.4-2.5,6.5-3.6,9.6c-3.5-9.1-0.2-17.6,1.6-26.1
			c0.7-3.2,4.4-3.4,5.9,0.1c1.2,2.7,1.3,5.9,2,9.1c1.1-0.6,1.8-0.7,2.1-1.2c2.2-3.2,1.5-10.5,7.1-8.9c5.1,1.5,2.2,7.6,1.2,11.2
			C636.8,104.1,638,110,634.1,114.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M613.4,88.2c-1.2,5.7-8.4,1-10.3,6
			c0.1,3.3,3.3,2.2,5.1,2.9c4.5,1.7,5.6,5.3,4.9,9.4c-0.5,3.3-1,7.4-5.2,8.2c-4.5,0.8-9.4,1.4-13.7-0.6c-2.4-1.1-0.1-3.2,0.3-4.9
			c1.1-4.5,2.2-9,2.9-13.6C599.2,84.1,601.8,82.9,613.4,88.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M332,103.6c-1.6,3.1-3.1,3-4.7,0
			c0,0-0.1,0.1-0.1,0.1c1.6,1.1,3.2,1.1,4.9,0C332.1,103.7,332,103.6,332,103.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M237.9,91.7c0.5-0.5,0.9-1.1,1.4-1.6
			c1.4,1.2,0.5,1.8-0.6,2.4C238.7,92.4,237.9,91.7,237.9,91.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M615.1,430.8c0.6,0.8,1.3,1.7,1.9,2.5
			c1,8,1.9,16-0.6,23.9c-2.6-11.9-8.3-21.6-19.4-27.5C603,430.2,609.1,430.5,615.1,430.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M567.5,430.5c7.1-5.2,14.6-2.8,22.1-1
			c-12.3,2.9-18.6,11.2-21.2,23C565.2,445.3,567.2,437.9,567.5,430.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M587.7,474.1c-2.5-3.7-8.9-2.4-8.8-9.4
			c0.2-7,5.8-6.4,8.6-6.5c4.4-0.2,6.7,4.7,6.2,8.6C593.4,469.5,591.5,473.2,587.7,474.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M614,482c6.4,16.3,3.5,21-12.7,20.7
			c-2.3-0.1-4.6,0-6.9,0C603,497.7,611.8,493,614,482z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M582.9,500.1c-17.5,0.9-21.1-4.6-14-20.9
			C570.1,488.4,577.3,493.7,582.9,500.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M534.2,241.4c0.8-14-11.6-28.3,0.7-42.8
			C542.2,213.6,531.1,227.5,534.2,241.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M530.1,177.2c6.4-9.7,10.6-3.8,14.9,2.1
			c0.7,2.9,3.4,5.1,2.7,8.5c-6-3-11.9-5.9-18.7-2.1C526,184.4,526,184.4,530.1,177.2z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M527.3,260.4c-1-2.2-1.9-4.5-2.9-6.7
			c7.2,0.4,14.4,1.3,21.6,0c-3.5,5.5-4.8,13.4-13.7,13.4C530.6,264.9,529,262.6,527.3,260.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M508.6,232.1c-0.5-1-1-2-1.5-3
			c-3.3-5.2-5.5-10.7-3.1-16.9c2-1.7,3.9-3.4,5.9-5.2c2.1,8.5-1.1,17.5,2.8,25.8C510.8,236,510.1,231.9,508.6,232.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M524.6,253.8c-0.7-0.1-1.3-0.3-2-0.5
			c0.6-0.7,1.3-1.5,1.9-2.2c0.2,0.6,0.5,1.2,0.6,1.8C525.2,253.2,524.8,253.5,524.6,253.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M593.1,288.6c-5.2-0.5-7.7,10.3-13.6,3.8
			c-5-5.5-11.1-1.3-15.8-4.3c-2-1.3-1.3-4.7,0-4.3C573.1,287,584.4,272.7,593.1,288.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M602.1,272.8c15.1,8.3,14.9,18.4-0.9,32.7
			C605.1,294.7,606.7,283.9,602.1,272.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M583.8,239.1c1.6,2.2,3.1,4.4,4.7,6.7
			c0.5,2,1.1,3.9,1.6,5.8c-7.1,0.3-14.5-2.6-21,2.6c-0.5-0.7-1-1.4-1.5-2.1c3.1-3.9,6.2-7.8,9.2-11.7
			C579,239.1,580.9,236.8,583.8,239.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M553.2,300.1c-0.5-0.9-1.1-1.9-1.6-2.8
			c-0.9-1.4-1.9-2.8-2.8-4.3c-3-5-3.4-10.1-0.2-15.2c1.7-2.2,3.4-4.5,5-6.7c0.6-0.8,1.3-1.7,1.9-2.5c0.7,0.6,1.3,1.1,2,1.7
			c-4.6,9.9-2.1,20.3-2.4,30.6C554.5,300.5,553.8,300.3,553.2,300.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M590,251.5c1.8,0,2.2,1.3,2.6,2.7
			C590.9,254,589.5,253.7,590,251.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M557.2,303.1c-0.8-0.8-1.6-1.6-2.4-2.5
			C556.8,300.4,556.9,301.9,557.2,303.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M553,472.8c1.8,1.9,1.6,4.3,1.6,6.6
			c0.4,20.1-2.2,22-22.5,16.5C544.9,493.5,550,484.1,553,472.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M552,425.9c0.8,0.7,1.6,1.4,2.4,2.1
			c0.5,7.5,2.5,15.2-1.4,22.4c-2.1-10.1-6.3-18.7-16.4-23.2C541.3,423.2,546.8,426.5,552,425.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M506.2,495c-0.7-0.8-1.4-1.6-2.1-2.4
			c-0.2-7.4-1.8-14.9,1.2-22.2c2,11.3,8.2,19.6,18.1,25.2C517.6,495.4,511.9,495.2,506.2,495z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M524.8,469.8c-1.5,1-3,1.9-4.4,2.9
			c-0.5-0.4-1-0.9-1.5-1.4c0.9-3.3,1.9-6.6,2.8-9.9c0.8-2.9-3.5-8.4,3.9-8c6.5,0.4,5,5.5,5.2,9.5c0,0-0.4-0.1-0.4-0.1
			c-1.2,0.8-2.4,1.6-3.7,2.4l0.2-0.2c-1.8,1.1-2.5,2.8-2.5,4.9L524.8,469.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M530.5,462.8c0,0,0.4,0.1,0.4,0.1
			c2-0.7,2.4-4.1,7.4-2.9c-3.8,4.9-6.4,10-10.5,13.2c-3.7,2.9-2-2.1-2.9-3.4c0,0-0.3,0.1-0.3,0.1c2.3-0.8,4.8-1.6,2.5-4.9
			c0,0-0.2,0.2-0.2,0.2C529.4,466.5,529.9,464.5,530.5,462.8z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M514.3,478.5c0.3-3.2,2.1-5.4,4.6-7.1
			c0.5,0.5,1,0.9,1.5,1.4c-1.6,2.4-3.2,4.7-4.8,7.1C515.2,479.3,514.8,478.9,514.3,478.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M515.6,479.8c-0.6,0.2-1.2,0.4-1.8,0.6
			c0.2-0.6,0.4-1.2,0.5-1.9C514.8,478.9,515.2,479.3,515.6,479.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M473.5,443.3c-3.8,4.8-9.1,1.2-10.2,6.3
			c-1.1,5.5,5.7,2.8,6.8,7.4c-7.8,0-8,6.2-7.5,12.9c-1.1-0.4-2.2-0.8-3.3-1.1c0-7.4-1.1-15.1,0.3-22.3
			C461.3,437.5,467.4,443.2,473.5,443.3z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M462,490.9c-5.6-0.2-11.2-0.5-16.8-0.7
			c-3.5-7.5-4.3-15.1-0.7-22.8C447.3,477.6,452.6,485.7,462,490.9z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M446.8,439c-4.2-6.1-4.1-13.3,0.1-18
			c4.2-4.7,10.7-2,15.8,1.3C454.1,424.7,449.4,430.9,446.8,439z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M490.9,492.5c-6.7,0.8-13.4,1.8-19.9-1.3
			c10.1-2.2,15.7-9,18.6-18.5C494.6,479,491.4,485.9,490.9,492.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M408.3,331.2c-1.5,7.9-1.5,15.8-0.7,23.8
			c-0.4,19.9-1.1,39.8-1.1,59.8c0,7.7-2.2,9.8-10.1,9.2c-45.6-3.6-91.1-7.6-136.8-9.6c-26.7-1.2-53.2-5.5-79.9-5.7
			c-4.1,0-8.8,0.6-11.4-4c0.5-1,1.5-2.1,1.5-3.1c-0.3-46.1,5-92,2.8-138.2c0.1-11.7,0.9-23.5-0.1-35.1c-0.8-9.6,4-9.9,11-9.6
			c42.1,1.9,84.1,3.7,126.2,5.6c23.8,1.1,47.5,2.7,71.3,3.3c8.9,0.2,17.2,4.8,26.3,2.3c4.8-1.4,3.7,2.5,3.6,5c-0.1,7-0.4,14-0.6,21
			c-1.4,6.4-1.2,12.8-0.5,19.2C409.3,293.8,408.8,312.5,408.3,331.2z"/>
		<path fill="#00C331" d="M406.7,330.4c-1.5,7.9-1.5,15.8-0.7,23.8c-0.4,19.9-1.1,39.8-1.1,59.8c0,7.7-2.2,9.8-10.1,9.2
			c-45.6-3.6-91.1-7.6-136.8-9.6c-26.7-1.2-53.2-5.5-79.9-5.7c-4.1,0-8.8,0.6-11.4-4c0.5-1,1.5-2.1,1.5-3.1
			c-0.3-46.1,5-92,2.8-138.2c0.1-11.7,0.9-23.5-0.1-35.1c-0.8-9.6,4-9.9,11-9.6c42.1,1.9,84.1,3.7,126.2,5.6
			c23.8,1.1,47.5,2.7,71.3,3.3c8.9,0.2,17.2,4.8,26.3,2.3c4.8-1.4,3.7,2.5,3.6,5c-0.1,7-0.4,14-0.6,21c-1.4,6.4-1.2,12.8-0.5,19.2
			C407.7,293.1,407.2,311.7,406.7,330.4z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M477.5,314.5c-3.9-6.9-7.8-13.8-11.7-20.8
			c-2.5-7.9-2.5-15.8,0-23.7c3.7-7.6,8-14.8,14.1-20.8c5.7-4.7,12.5-1.2,18.7-2.4c0,0-0.1-0.1-0.1-0.1c-0.7,2.3,1,2.6,2.6,2.9
			c3.5,6.1,7,12.1,10.5,18.1c3.2,11.1,2.5,22.1-1.5,32.9c-3,4-6,8-9,11.9C493.6,318.9,485.6,317.5,477.5,314.5z M504.1,282.6
			c-6.7-4.8-15.1,0-18.5-4.9c-3.1-4.4-4.4-4-7.3-1.4c-1.8,1.7-6.1,2.7-4.5,5.6c1.8,3.1,5.5,5.1,8.4,7.6
			C486.8,280.8,496.6,287.4,504.1,282.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M510.1,300.8c3.9-10.8,4.7-21.8,1.5-32.9
			c5.7,0.9,5.7,7.4,9.7,10.1c2.8,7-1.5,12-4.8,17.4C514.3,297.1,512.2,299,510.1,300.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M477.5,314.5c8.1,2.9,16.2,4.4,23.6-1.8
			c-1,5.8-5.7,9.4-8.8,13.8c-3.1,4.5-6.4,2.4-9.4-0.5c-1.5-2.4-3.1-4.7-4.6-7.1C478,317.5,477.7,316,477.5,314.5z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M519.7,380.6c-1.6-5.9-5.8-10-9.8-14.3
			c-0.2-1.2-0.1-2.7-2-2.3c0,0,0.2,0.2,0.2,0.2c0-7.9,0-15.8,0-23.7c3.9-7.7,7.7-15.4,11.6-23.1c7.4-4.8,15.4-3.8,23.5-2.5
			c0,0-0.1-0.2-0.1-0.2c-0.3,2,1.2,2.8,2.6,3.7c2.7,4.7,4.5,10.1,9.7,12.9c0,1.8-0.9,4.1,2,4.7c0,0-0.2-0.2-0.2-0.2
			c0.7,11.9,0,23.7-4,35.1c-5.5,2.4-8.3,7.6-12.5,11.4C533.3,386.8,526.5,383.3,519.7,380.6z M530.5,369.5c9.6-14.3-3.9-28.8,0-36.6
			C532.5,340.8,518.7,354.8,530.5,369.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M543.2,315c-8.1-1.3-16.1-2.3-23.5,2.5
			c1.9-3,3.8-5.9,5.7-8.9c0.7-0.9,1.3-1.7,2-2.6c3.1-2.8,5.8-7.5,10.7-2.5c1.5,2.3,3.1,4.7,4.6,7C542.8,312,543,313.5,543.2,315z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M508.1,340.6c0,7.9,0,15.8,0,23.7
			c-0.3-0.6-0.7-1-1.4-1.2c-0.9-1.4-1.8-2.8-2.7-4.2c-1.4-2.4-2.9-4.9-4.3-7.3c-0.1-2.7-0.2-5.4-0.3-8.1c1.4-1.7,2.7-3.6,4.2-5.2
			c1.1-1.2,2.6-2.1,3.9-3.2C507.6,336.9,507.8,338.7,508.1,340.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M498.5,247c-6.2,1.1-13-2.3-18.7,2.4
			c-3.2-4.4,3.2-5.6,3.1-9c5-6.6,9.8-7.7,14.1,0.6C497.6,243,498,245,498.5,247z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M501,249.7c-1.6-0.3-3.3-0.6-2.6-2.9
			C500.4,246.8,500.7,248.3,501,249.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M465.8,270.1c-2.4,7.9-2.5,15.8,0,23.7
			c-0.5,1.2-1.1,2.3-1.6,3.5c-2.4-3.7-4.7-7.5-7.1-11.2c-2.7-7,1.5-12.1,4.8-17.4C464.3,266.4,465.7,266.6,465.8,270.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M553.2,371c4-11.4,4.7-23.2,4-35.1
			c1.9,2,3.7,4,5.6,6.1c5,6.5,4,12.4-1.3,18.3c-2.7,3.1-4.9,6.7-7.3,10C553.7,370.4,553.4,370.6,553.2,371z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M557.3,336.1c-2.9-0.6-1.9-2.9-2-4.7
			C556,333,556.7,334.6,557.3,336.1z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M545.6,318.5c-1.3-0.9-2.9-1.7-2.6-3.7
			C545.1,315.3,545.1,317,545.6,318.5z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M519.7,380.6c6.8,2.7,13.6,6.1,21,1.8
			c2.8,6.5-4.6,8.5-5.8,13.2c-7.4,3.6-7.9-4.1-11.3-6.9c-1-1.4-2-2.7-3.1-4.1C520.2,383.3,520,382,519.7,380.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M507.9,364c1.9-0.3,1.8,1.1,2,2.3
			C509.2,365.6,508.6,364.8,507.9,364z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M994.6,205.3c0.6,7.8,4,15.6,0.4,23.7
			C982.4,221.1,988.9,213.2,994.6,205.3z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M1004.5,229c-3.8-19-3.8-19,0.9-23.2
			C1008.1,215.3,1007.9,220.2,1004.5,229z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M430.5,734.9c6.9-4.5,14.1-1.6,21.2-0.7
			c11,1.4,22.1,2.6,33.2,3.9c-18.4,14.5-20.7,33.1-13.9,54.2c2.3,7,9.7,10.1,11.5,17.2c-15.1-1.5-30.2-2.8-45.2-4.6
			c-7.8-1-9.7-7-9.4-13.9C428.7,772.3,429.6,753.6,430.5,734.9z M451.4,769.9c-0.6-5.2-1.1-11-7.5-11.7c-6.1-0.6-7.8,4.7-7.9,9.5
			c-0.1,5.2,1.2,10.9,7.5,11.6C449.7,780.1,450.6,774.7,451.4,769.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M712.3,882.7c-0.6,2.2,0.5,3.7,2.3,4.7
			c0.7,23.6,1.2,47.1-2.3,70.5c-9.6,0.5-18.8-1.5-28.2-3.3c15.5-13.5,23.6-29.6,16.8-50.3c-2.5-7.6-6.3-14.7-9.6-22.1
			C698.3,882.5,705.3,882.6,712.3,882.7z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M679.3,915.4c1,6.1-2.4,7.6-4.8,6.5
			c-3.4-1.6-10.8,1.5-9.6-6.6c0.2-1.2-2.6-5,3.1-3.7C672.7,912.7,677.3,912.1,679.3,915.4z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M662.4,678.7c0-18.2,7.9-30.4,18.6-31.5
			c10.9-1.1,20.4,9.2,23,24.9c0.8,5.1,0.8,10.1-0.8,15c-3.1,9.4-6.8,19-18.6,19.3c-10.9,0.3-16.4-7.7-19.8-17
			C663.2,685.2,662.8,680.5,662.4,678.7z M667.6,676.1c-0.4,13.5,11.7,4.2,17.1,8.1c4,2.9,7.2-3.2,6.5-6.6
			c-2.1-10.1-12.1-5.7-18.3-8.4C667.5,667.1,668,672.2,667.6,676.1z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M695.5,641.4c8.1-1.6,15.8,1,23.7,2
			c5.4,22.3,2.8,44.7,0.5,67.1c-0.4,4-3.1,6.6-7.2,6.2c-6.5-0.6-13.2,1.1-19.3-2.5c15.7-15.2,20.7-33,14-54.2
			C705,652.5,697.5,648.8,695.5,641.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M615.9,753.7c19.1-0.7,37.8,2.9,56.7,5.4
			c-14,12.7-21.5,27.3-17,47.1c2.6,11.3,9.8,18.8,17,26.7c-15.7-1.9-31.4-4.2-47.2-5.5c-9.6-0.8-12.3-5.8-12.4-14.5
			C612.9,793,614.6,773.4,615.9,753.7z M637.3,790.9c-0.7-4.9-0.4-11.6-8.1-11.4c-5.7,0.1-7.7,5.2-7.5,10.1
			c0.2,5.6,1.7,11.8,8.6,11.9C636,801.6,637.3,796.4,637.3,790.9z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M672.6,832.9c-7.2-7.9-14.4-15.4-17-26.7
			c-4.6-19.8,2.9-34.4,17-47.1c6.1,0.7,12.2,1.4,18.2,2.2c1.3,6.1,7.2,8.8,10.1,14c11.3,20.3,6.6,40.3-14.6,59
			C681.7,833.7,677.2,833.3,672.6,832.9z M687.6,801c-3.3-2.3-6.1-4.3-6.2-8.4c0-1.7-1.2-2.9-3-2.5c-1,0.2-2.5,1.7-2.4,2
			c1.4,3.7-0.7,9.2,4.2,10.9c3.9,1.4,9.2,5.6,12-0.9c4.9-10.9,2.4,6.9,6.1,0.3c-1.7-3.2,3.6-9.7-4.8-11.1
			C684.8,789.9,690.3,797.7,687.6,801z M670,796.7c0.3-4.9-0.7-7.8-4.7-8c-4.2-0.3-5,2.9-5,6.2c0,3.6,0.5,7.7,5.2,7.4
			C669.2,802.2,669.9,798.6,670,796.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M686.3,834.2c21.2-18.7,25.9-38.7,14.6-59
			c-2.9-5.1-8.8-7.8-10.1-14c9-0.3,17.8,0.6,26.1,4.2c3.8,22.7-0.8,45.4,0,68.1c-1.6,1.2-2.8,2.5-2.3,4.7
			C705.2,836.6,695.5,837.3,686.3,834.2z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M428.1,849.9c17.7-1.3,34.8,2.6,52,5.3
			c-18.6,22.4-21.1,37.6-10.2,57.5c2.8,5.2,8.9,7.7,10.2,13.8c-15.3-2-30.7-4-46-5.9c-6.1-0.8-9-4.6-8.8-10.3
			C426,890.1,423.5,869.8,428.1,849.9z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M480.2,926.5c-1.4-6.1-7.4-8.6-10.2-13.8
			c-10.9-19.9-8.5-35.1,10.2-57.5c7-0.8,13.7,1.4,20.5,2.5c1.1,2,2,4.1,3.3,6c16.5,24,12.8,47.6-10,64.7
			C489.3,927.8,484.8,927.2,480.2,926.5z M499.5,900.3c1.4-4.5,10-11.7,0.2-12.7c-7.5-0.8-14.8-3.5-22.4-3.2
			c-2.4,0.1-5.7-2.4-6.7,1.7c-0.6,2.5-1.2,6.2,0.1,7.9c4.9,6.5,12.2,4,18.9,4.6c5.9,0.5,7.1-2.3,8.2-6.8
			C498.4,894.7,499,897.5,499.5,900.3z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M493.9,928.4c22.9-17.1,26.5-40.6,10-64.7
			c-1.3-1.9-2.2-4-3.3-6c1.2-0.1,2.3-0.5,3.5-0.3c21.1,2.9,21.3,3,20.9,24.6c-0.3,16.7,1.3,33.6-2.8,50.1
			C512.6,932.2,503.2,930.6,493.9,928.4z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M596.3,600.5c-0.2-0.2-0.6-0.5-0.6-0.7
			c0-0.2,0.4-0.5,0.6-0.7c0.2,0.2,0.6,0.5,0.6,0.7C597,600,596.6,600.3,596.3,600.5z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M294.4,106c0-2.6,0-5.1,0-10.5
			C297.3,101.1,297.6,103.7,294.4,106C294.4,106,294.4,106,294.4,106z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M283.7,95c1.8,3.1,1.6,5.1,0.2,7.3
			C281.2,100.3,282.6,98.1,283.7,95z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M527,465c2.3,3.2-0.1,4-2.5,4.9
			C524.5,467.9,525.1,466.1,527,465z"/>
		<path fill="#D5DADE" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M530.5,462.8c-0.6,1.7-1.1,3.7-3.7,2.4
			C528,464.4,529.2,463.6,530.5,462.8z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M504.1,282.6c-7.5,4.8-17.2-1.8-21.9,7
			c-2.9-2.5-6.6-4.5-8.4-7.6c-1.6-2.9,2.6-4,4.5-5.6c2.9-2.7,4.3-3,7.3,1.4C489,282.6,497.4,277.7,504.1,282.6z"/>
		<path fill="#1B3E87" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M530.5,369.5c-11.8-14.7,2-28.7,0-36.6
			C526.6,340.7,540.1,355.2,530.5,369.5z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M451.4,769.9c-0.7,4.8-1.7,10.1-7.9,9.5
			c-6.3-0.7-7.5-6.4-7.5-11.6c0.1-4.8,1.7-10.1,7.9-9.5C450.3,758.9,450.7,764.7,451.4,769.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M667.6,676.1c0.4-3.9,0-9.1,5.2-6.8
			c6.2,2.7,16.2-1.8,18.3,8.4c0.7,3.4-2.5,9.5-6.5,6.6C679.3,680.3,667.2,689.7,667.6,676.1z"/>
		<path fill="#34353C" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M637.3,790.9c0,5.5-1.3,10.7-7.1,10.6
			c-6.9-0.1-8.4-6.3-8.6-11.9c-0.2-4.9,1.9-10,7.5-10.1C636.9,779.3,636.6,786,637.3,790.9z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M687.6,801c2.7-3.3-2.8-11.2,6-9.7
			c8.3,1.4,3.1,7.9,4.8,11.1c-3.7,6.6-1.3-11.2-6.1-0.3c-2.9,6.5-8.2,2.3-12,0.9c-4.9-1.7-2.9-7.2-4.2-10.9c-0.1-0.4,1.5-1.8,2.4-2
			c1.8-0.4,2.9,0.8,3,2.5C681.5,796.7,684.3,798.7,687.6,801z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M670,796.7c-0.1,1.8-0.9,5.4-4.5,5.6
			c-4.7,0.2-5.1-3.8-5.2-7.4c0-3.3,0.8-6.5,5-6.2C669.3,789,670.3,791.8,670,796.7z"/>
		<path fill="#9EA7B1" stroke="#333333" stroke-width="4" stroke-miterlimit="10" d="M499.5,900.3c-0.5-2.8-1.1-5.6-1.6-8.4
			c-1.1,4.5-2.3,7.3-8.2,6.8c-6.7-0.6-14.1,1.9-18.9-4.6c-1.3-1.7-0.8-5.4-0.1-7.9c1-4.1,4.3-1.7,6.7-1.7c7.6-0.3,14.9,2.5,22.4,3.2
			C509.5,888.6,500.9,895.8,499.5,900.3z"/>
	</g>
</g>
<g id="Слой_2">
	<g>
		<g>
			<path fill="#333333" d="M60.8,186.5c15.3-13.4,30.6-26.8,45.9-40.1c1.5-1.3-0.8-2.3-1.9-1.3c-15.3,13.4-30.6,26.8-45.9,40.1
				C57.4,186.5,59.8,187.5,60.8,186.5L60.8,186.5z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M51.2,221.8c15.8-17.1,33.9-31.8,54-43.6c1.2-0.7-1.7-0.4-2.2-0.1C82.8,190,64.5,204.8,48.6,222
				C48,222.6,50.7,222.3,51.2,221.8L51.2,221.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M65.3,227.9c28.4-24.2,59.1-45.7,91.6-64.2c1.2-0.7-1.7-0.3-2.2,0c-32.6,18.5-63.5,40.1-92,64.4
				C62.2,228.6,64.8,228.4,65.3,227.9L65.3,227.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M46.7,275.9c34.1-16.3,65.3-37.9,92.6-64c0.6-0.5-2.1-0.2-2.6,0.3c-27,25.8-58,47.3-91.7,63.4
				C43.3,276.4,46,276.2,46.7,275.9L46.7,275.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M56.5,299.7c31-18.8,61.9-37.7,92.9-56.5c1.7-1-0.8-1.6-1.8-1c-31,18.8-61.9,37.7-92.9,56.5
				C53,299.8,55.5,300.3,56.5,299.7L56.5,299.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M68.9,328c18.7-15.3,37.4-30.6,56-46c1.3-1.1-1.5-1.3-2.4-0.6c-18.7,15.3-37.4,30.6-56,46
				C65.2,328.5,68.1,328.6,68.9,328L68.9,328z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M63.2,365.8c20.8-17.4,42.9-33.3,66-47.5c1.2-0.7-1.7-0.4-2.2-0.1c-23.3,14.3-45.5,30.3-66.4,47.8
				C60,366.5,62.7,366.2,63.2,365.8L63.2,365.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M63.3,409.9c20.8-16.9,42.7-32.2,65.7-46c1.2-0.7-1.7-0.5-2.2-0.1c-23.1,13.9-45.1,29.4-66.1,46.4
				C60.1,410.6,62.8,410.4,63.3,409.9L63.3,409.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M67.3,454.5c9.5-9.2,18.9-18.4,28.4-27.6c1.3-1.3-1.5-1.8-2.4-0.9c-9.5,9.2-18.9,18.4-28.4,27.6
				C63.6,454.9,66.3,455.5,67.3,454.5L67.3,454.5z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M635.2,240.9c19.5-14.4,39.9-27.6,61.1-39.4c1.7-0.9-0.8-1.5-1.8-1c-21.2,11.8-41.6,25-61.1,39.4
				C631.8,241.1,634.3,241.7,635.2,240.9L635.2,240.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M656.8,276.4c19.3-16,38.6-32,57.9-48.1c1.5-1.3-0.8-2.3-1.9-1.4c-19.3,16-38.6,32-57.9,48.1
				C653.3,276.3,655.6,277.4,656.8,276.4L656.8,276.4z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M679,300.8c14.6-9.4,29.3-18.8,43.9-28.1c1.6-1.1-0.7-2.2-2-1.4c-14.6,9.4-29.3,18.8-43.9,28.1
				C675.4,300.4,677.8,301.6,679,300.8L679,300.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M673.4,334.4c25.8-16.3,51.6-32.6,77.4-48.9c1.2-0.7-1.7-0.3-2.2,0c-25.8,16.3-51.6,32.6-77.4,48.9
				C670,335.2,672.9,334.7,673.4,334.4L673.4,334.4z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M639.1,446.8c48.4-29.8,93.5-64.7,134.4-104.1c1.3-1.2-1.6-1.5-2.4-0.7c-40.8,39.2-85.7,74-133.9,103.7
				C635.5,446.7,638,447.4,639.1,446.8L639.1,446.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M674.3,495.9c28.7-12.3,53.4-32.1,71.6-57.4c0.8-1.2-2.1-1.1-2.7-0.2c-17.8,24.8-42.1,44.2-70.1,56.2
				C671.2,495.3,672.9,496.5,674.3,495.9L674.3,495.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M83.1,944.2c0.7-0.7,1.3-1.3,2-2c0.1-0.1,0.4-0.3,0.3-0.6c-0.1-0.2-0.4-0.3-0.5-0.3
				c-0.6-0.1-1.6,0.1-2,0.5c-0.7,0.7-1.3,1.3-2,2c-0.1,0.1-0.4,0.3-0.3,0.6c0.1,0.2,0.4,0.3,0.5,0.3
				C81.7,944.8,82.7,944.6,83.1,944.2L83.1,944.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M91.4,946.6c5.9-4.8,11.8-9.6,17.7-14.4c1.3-1-1.6-1.1-2.3-0.5c-5.9,4.8-11.8,9.6-17.7,14.4
				C87.8,947.2,90.7,947.2,91.4,946.6L91.4,946.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M107,948.1c5.3-4.7,10.6-9.4,16-14c1.3-1.1-1.6-1.4-2.4-0.6c-5.3,4.7-10.6,9.4-16,14
				C103.4,948.6,106.2,948.8,107,948.1L107,948.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M122.8,954.1c3.6-3.8,7.1-7.6,10.7-11.3c1.3-1.3-1.5-1.9-2.4-0.9c-3.6,3.8-7.1,7.6-10.7,11.3
				C119.1,954.5,121.9,955.1,122.8,954.1L122.8,954.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M137,957.7c2.7-3.3,5.4-6.6,8.2-9.9c0.7-0.9-2.1-0.7-2.7,0c-2.7,3.3-5.4,6.6-8.2,9.9
				C133.5,958.6,136.4,958.3,137,957.7L137,957.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M153.1,965.7c4.8-5.2,9.5-10.5,14.3-15.7c0.6-0.7-2.1-0.4-2.6,0.2c-4.8,5.2-9.5,10.5-14.3,15.7
				C149.8,966.6,152.6,966.2,153.1,965.7L153.1,965.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M169.4,969.7c5.6-8.2,12.3-15.5,19.9-21.8c0.5-0.4-2.1-0.2-2.6,0.3c-7.8,6.4-14.5,13.8-20.2,22.1
				C166.4,970.5,168.9,970.4,169.4,969.7L169.4,969.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M187.3,971.9c3.3-3.3,6.7-6.7,10-10c0.5-0.5-0.4-0.5-0.7-0.5c-0.6,0-1.5,0.3-1.9,0.7
				c-3.3,3.3-6.7,6.7-10,10c-0.5,0.5,0.4,0.5,0.7,0.5C186,972.7,186.9,972.3,187.3,971.9L187.3,971.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M217.1,971.8c2.6-4,5.3-8,7.9-12.1c0.7-1-2.2-0.7-2.7,0c-2.6,4-5.3,8-7.9,12.1
				C213.7,972.9,216.6,972.6,217.1,971.8L217.1,971.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M243.2,972.3c4-4,8-8,12-12c1.2-1.2-1.6-1.3-2.3-0.5c-4,4-8,8-12,12C239.6,972.9,242.4,973,243.2,972.3
				L243.2,972.3z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M259.4,982.6c3.4-4,6.7-8,10.1-11.9c1.2-1.4-1.5-2-2.5-1c-3.4,4-6.7,8-10.1,11.9
				C255.8,983.1,258.5,983.7,259.4,982.6L259.4,982.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M287.2,976c0.7-1.3,1.4-2.7,2-4c0.3-0.6-0.3-1.1-0.9-1.2c-0.7-0.1-1.6,0.2-1.9,0.9c-0.7,1.3-1.4,2.7-2,4
				c-0.3,0.6,0.3,1.1,0.9,1.2C285.9,977,286.8,976.7,287.2,976L287.2,976z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M297.5,985.8c1.8-4.2,5-7.7,9.3-9.7c1.7-0.8-1-0.6-1.6-0.3c-4.7,2.1-8.4,5.7-10.5,10.5
				C294.5,986.7,297.2,986.5,297.5,985.8L297.5,985.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M312.7,988.4c6-4.6,12.1-9.2,18.1-13.9c1.6-1.2-0.8-2.3-1.9-1.4c-6,4.6-12.1,9.2-18.1,13.9
				C309.2,988.1,311.5,989.3,312.7,988.4L312.7,988.4z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M334.2,990.9c6.3-1.5,12-4.5,16.9-8.8c1.2-1.1-1.7-0.9-2.3-0.3c-4.3,3.8-9.3,6.5-14.9,7.9
				C332.2,990.1,332.5,991.3,334.2,990.9L334.2,990.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M364.8,990.4c3.3-2.6,6.7-5.3,10-7.9c1.5-1.2-0.8-2-1.9-1.2c-3.3,2.6-6.7,5.3-10,7.9
				C361.4,990.4,363.7,991.2,364.8,990.4L364.8,990.4z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M387.8,1001.1c5.7-5,11.4-10,17.1-15c1.3-1.1-1.6-1.3-2.4-0.6c-5.7,5-11.4,10-17.1,15
				C384.2,1001.6,387,1001.8,387.8,1001.1L387.8,1001.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M415.4,1000.4c2.6-2,5.3-4.1,7.9-6.1c1.2-0.9-1.7-0.6-2.2-0.2c-2.6,2-5.3,4.1-7.9,6.1
				C411.9,1001.1,414.8,1000.8,415.4,1000.4L415.4,1000.4z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M435.2,1009.7c2.7-2,5.3-4,8-6c0.5-0.4-0.5-0.4-0.7-0.4c-0.7,0-1.4,0.3-1.9,0.7c-2.7,2-5.3,4-8,6
				c-0.5,0.4,0.5,0.4,0.7,0.4C433.9,1010.4,434.7,1010.1,435.2,1009.7L435.2,1009.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M457.4,1012.6c5.3-5.3,10.7-10.7,16-16c1.3-1.3-1.5-1.9-2.4-0.9c-5.3,5.3-10.7,10.7-16,16
				C453.7,1013,456.5,1013.6,457.4,1012.6L457.4,1012.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M481.2,1010.2c3.3-3.3,6.7-6.7,10-10c1.2-1.2-1.7-1-2.3-0.4c-3.3,3.3-6.7,6.7-10,10
				C477.7,1011,480.5,1010.8,481.2,1010.2L481.2,1010.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M494.9,1012.5c3.3-2.7,6.7-5.3,10-8c1.5-1.2-0.8-2.1-1.9-1.2c-3.3,2.7-6.7,5.3-10,8
				C491.4,1012.5,493.8,1013.4,494.9,1012.5L494.9,1012.5z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M513.1,1014.6c0.6,0,3.3-1.1,1.9-1.1C514.4,1013.4,511.7,1014.6,513.1,1014.6L513.1,1014.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M527.2,1019.8c2.7-3.3,5.3-6.7,8-10c0.6-0.7-2.2-0.3-2.6,0.2c-2.7,3.3-5.3,6.7-8,10
				C524,1020.7,526.7,1020.3,527.2,1019.8L527.2,1019.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M531.4,1020.7c0.9,0,3.1-1.3,1.2-1.3C531.7,1019.3,529.5,1020.7,531.4,1020.7L531.4,1020.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M539,1016c3.3-2.7,6.6-5.5,9.9-8.2c0.5-0.4,0.2-0.9-0.3-1.1c-0.6-0.3-1.6,0.2-2,0.5
				c-3.3,2.7-6.6,5.5-9.9,8.2c-0.5,0.4-0.2,0.9,0.3,1.1C537.5,1016.8,538.5,1016.4,539,1016L539,1016z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M552.9,1014.5c4.6,1.5,10-0.2,12.7-4.3c0.7-1.1-2.2-0.8-2.7,0c-1.7,2.6-4.6,4-7.7,3
				c-0.6-0.2-1.5-0.1-2,0.3C552.9,1013.6,552.2,1014.2,552.9,1014.5L552.9,1014.5z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M572.9,1022.6c2.7-2,5.3-4,8-6c1.6-1.2-0.8-1.9-1.9-1.1c-2.7,2-5.3,4-8,6
				C569.5,1022.6,571.9,1023.3,572.9,1022.6L572.9,1022.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M590.8,1029.1c4-2.1,7.9-4.1,11.9-6.2c1.8-0.9,0.2-2.1-1.2-1.4c-4,2.1-7.9,4.1-11.9,6.2
				C587.8,1028.6,589.4,1029.8,590.8,1029.1L590.8,1029.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M612.6,1032.8c6.1-2.7,11.1-7.1,14.7-12.7c0.8-1.3-2.1-1.3-2.8-0.3c-3.2,5.1-7.7,9-13.2,11.4
				C609.6,1032,611.2,1033.5,612.6,1032.8L612.6,1032.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M645,1036.1c2.7-2.7,5.4-5.3,8-8c0.5-0.5,0.2-1.1-0.3-1.3c-0.7-0.3-1.6,0.1-2,0.5c-2.7,2.7-5.4,5.3-8,8
				c-0.5,0.5-0.2,1.1,0.3,1.3C643.6,1036.9,644.5,1036.6,645,1036.1L645,1036.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M399.2,97.8c9.3-8,18.7-16,28-24c0.6-0.5-2.1-0.3-2.6,0.2c-9.3,8-18.7,16-28,24
				C396,98.5,398.7,98.3,399.2,97.8L399.2,97.8z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M425.4,102.1c2.7-4,5.3-8,8-12c0.8-1.1-2.2-0.9-2.7-0.1c-2.7,4-5.3,8-8,12
				C421.9,103.1,424.8,102.9,425.4,102.1L425.4,102.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M448.5,99.7c8.8-5.1,17.6-10.2,26.4-15.3c1.7-1-1-0.9-1.7-0.5c-8.8,5.1-17.6,10.2-26.4,15.3
				C445.2,100.2,447.8,100.1,448.5,99.7L448.5,99.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M811.3,733.9c18.3-28.6,38.1-56.1,59.3-82.6c0.7-0.8-2.2-0.5-2.7,0.1c-21.2,26.5-41,54-59.3,82.6
				C808,735,810.9,734.6,811.3,733.9L811.3,733.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M849,738.7c15.5-13.3,29.6-28.1,42.1-44.4c1.1-1.4-1.6-1.9-2.4-0.8c-12.3,16-26.2,30.7-41.6,43.9
				C845.6,738.7,848,739.7,849,738.7L849,738.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M848.7,785.7c6.3-10,14.7-18.1,24.8-24.3c1.7-1-0.8-1.8-1.9-1.2c-10.4,6.4-19.1,14.9-25.6,25.2
				C845.1,786.7,848,786.6,848.7,785.7L848.7,785.7z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M843.3,839.9c2-2,4-4,6-6c0.5-0.5-0.4-0.5-0.7-0.5c-0.6,0-1.5,0.2-1.9,0.7c-2,2-4,4-6,6
				c-0.5,0.5,0.4,0.5,0.7,0.5C842,840.6,842.9,840.4,843.3,839.9L843.3,839.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1192.9,298.2c13.9-19.2,28.9-37.7,44.7-55.4c1.2-1.4-1.5-1.9-2.4-0.9c-15.9,17.7-30.8,36.2-44.7,55.4
				C1189.4,298.8,1192.1,299.3,1192.9,298.2L1192.9,298.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1176.5,363.9c21.4-29.1,43.9-57.4,67.5-84.8c1.2-1.4-1.6-1.9-2.4-0.9c-23.6,27.4-46.1,55.7-67.5,84.8
				C1173,364.5,1175.7,365,1176.5,363.9L1176.5,363.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1205.2,373.6c17.3-18.6,35.3-36.5,54.2-53.6c0.5-0.5-2.1-0.2-2.6,0.3c-18.9,17.2-37,35.1-54.4,53.9
				C1202.3,374.2,1204.7,374.2,1205.2,373.6L1205.2,373.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1214.8,419.6c18.8-23.9,37.6-47.8,56.4-71.7c0.8-1-2.1-0.8-2.7,0c-18.8,23.9-37.6,47.8-56.4,71.7
				C1211.3,420.5,1214.2,420.3,1214.8,419.6L1214.8,419.6z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1201.6,493.9c9.3-12.7,18.6-25.4,28-38c0.1-0.2-2.4-0.1-2.9,0.5c-9.3,12.7-18.6,25.4-28,38
				C1198.6,494.6,1201.1,494.6,1201.6,493.9L1201.6,493.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1193.1,535.9c10.8-13.9,21.5-27.9,32.3-41.8c0.8-1-2.1-0.8-2.7-0.1c-10.8,13.9-21.5,27.9-32.3,41.8
				C1189.7,536.8,1192.6,536.6,1193.1,535.9L1193.1,535.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1197.2,678.3c22-26.8,40.2-56.5,54.3-88.2c0.6-1.4-2.3-1.2-2.7-0.2c-14,31.5-32.1,61-54,87.7
				C1193.7,679,1196.5,679.2,1197.2,678.3L1197.2,678.3z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1195,716.2c14.3-17.6,29.5-34.4,45.7-50.3c1.3-1.2-1.6-1.5-2.4-0.7c-16.2,15.9-31.5,32.7-45.7,50.3
				C1191.5,716.9,1194.3,717.2,1195,716.2L1195,716.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#333333" d="M1197,735.6c10.8-11.2,21.7-22.3,32.5-33.5c0.7-0.7-2.1-0.4-2.6,0.2c-10.8,11.2-21.7,22.3-32.5,33.5
				C1193.7,736.5,1196.5,736.2,1197,735.6L1197,735.6z"/>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M778.8,265.6c-1.2-13.2-4.3-25.9-9.3-38.2c-4.1-11-8.1-22-12.2-33.1
					c-0.8-2.7-2.6-4.6-5.3-5.7c-2.6-1.3-5.2-1.3-7.8-0.1c-2.8,2.7-4.9,6-6.3,9.7c-1.8,3.5-2.8,7.3-2.9,11.2c4,11,8.1,21.9,12.2,32.9
					c4.9,11.6,8.1,23.6,9.8,36.1c0.9,2.6,2.7,4.5,5.3,5.5c2.5,1.3,5,1.5,7.7,0.5c2.7-2.4,4.8-5.3,6.1-8.7
					C777.9,272.6,778.8,269.2,778.8,265.6z"/>
				<path opacity="0.1091" fill="#333333" d="M753,246.8c-0.7-13-3.9-25.4-9.6-37c-4.1-11.1-8.2-22.2-12.2-33.3
					c-0.6-2.9-2.4-4.9-5.2-6.1c-2.7-1.3-5.4-1.2-8,0.3c-3.1,3.3-5.4,7-7,11.3c-2,4-3.2,8.3-3.5,12.8c4.1,11.1,8.1,22.2,12.2,33.3
					c5.5,11.1,8.8,22.8,9.9,35.1c1,5.4,8.2,8.7,13,6c3-3,5.3-6.4,6.9-10.4C751.5,255.1,752.7,251.1,753,246.8z"/>
				<path opacity="0.1091" fill="#333333" d="M757.1,199.1c-8.2-21.9-16.1-43.9-24.2-65.9c-1.7-3-4.3-4.2-7.7-3.4
					c-4.8,2.3-8,6.1-9.8,11.4c-4.3,8.2-8,16.7-11.1,25.4c-2.7,4.9-3.3,9.8-1.7,14.9c4.1,11.1,8.1,22.2,12.2,33.3
					c3.5,10.5,7.3,20.9,11.3,31.1c1.7,3,4.2,4.2,7.6,3.5c4.8-2.2,8.1-5.8,9.9-11c4.4-8,8.2-16.2,11.4-24.7
					C757.9,209,758.6,204.1,757.1,199.1z"/>
				<path opacity="0.1091" fill="#333333" d="M777.3,231c-8.3-21.7-16.2-43.7-24.2-65.5c-1.9-2.6-4.5-3.7-7.7-3.4
					c-4.1,1.2-6.9,4-8.4,8.2c-3.4,6.2-6.2,12.6-8.4,19.3c-2.1,4-2.3,7.9-0.4,11.8c7.9,21.5,15.6,43.1,23.6,64.6
					c1.9,2.6,4.4,3.8,7.6,3.5c4.1-1.1,7-3.8,8.5-8c3.5-6,6.4-12.3,8.8-18.9C778.9,238.7,779.1,234.8,777.3,231z"/>
				<path opacity="0.1091" fill="#333333" d="M780.1,193.7c-8.4-21.8-16.2-43.9-24.3-65.9c-1.6-3.4-4.1-4.5-7.7-3.4
					c-5.3,3.3-8.9,8-11,14.1c-5,9.9-9.5,20.1-13.4,30.5c-3.1,5.6-4.1,11.5-2.9,17.6c4.1,11.1,8.1,22.2,12.2,33.3
					c3.3,10.5,7.1,20.7,11.3,30.8c1.5,3.4,4.1,4.6,7.6,3.5c5.3-3.1,9-7.6,11.1-13.6c5.2-9.7,9.8-19.6,13.8-29.7
					C780.2,205.5,781.2,199.8,780.1,193.7z"/>
				<path opacity="0.1091" fill="#333333" d="M737.2,231.2c-1.6-12.4-5.1-24.3-10.3-35.6c-4.1-11.1-8.1-22.2-12.2-33.3
					c-0.5-3-2.2-5.1-5.1-6.2c-2.8-1.3-5.5-1.1-8,0.5c-3.2,3.5-5.6,7.5-7.3,11.9c-2.1,4.3-3.4,8.7-3.8,13.5
					c4.1,11.1,8.1,22.2,12.2,33.3c5,10.9,8.5,22.2,10.4,34c0.6,3,2.2,5,5,6.2c2.7,1.4,5.4,1.3,8-0.2c3.2-3.3,5.6-7,7.3-11.3
					C735.4,240,736.7,235.7,737.2,231.2z"/>
				<path opacity="0.1091" fill="#333333" d="M762,266c-2.1-12.3-5.8-24.1-11.2-35.4c-4-11.2-8-22.3-12.1-33.4
					c-1.2-2.4-3-4.1-5.5-5.1c-2.4-1.3-4.9-1.6-7.4-1c-2.4,1.8-4.2,4.1-5.3,6.9c-1.5,2.7-2.1,5.5-1.8,8.5
					c4.1,11.1,8.1,22.3,12.1,33.4c5.3,10.9,9.2,22.4,11.8,34.2c1.3,2.3,3.1,3.9,5.6,4.9c2.3,1.3,4.8,1.7,7.4,1.1
					c2.3-1.6,4-3.7,5-6.3C761.8,271.4,762.3,268.8,762,266z"/>
				<path opacity="0.1091" fill="#333333" d="M793.2,226.9c-8.1-22-16-44-24.1-65.9c-1.8-2.8-4.4-4-7.7-3.4
					c-4.5,1.9-7.6,5.2-9.2,10.1c-3.9,7.4-7.3,15-10.1,22.8c-2.5,4.5-2.9,9-1.2,13.6c7.9,21.5,15.6,43.1,23.5,64.6
					c1.8,2.9,4.3,4,7.6,3.6c4.5-1.7,7.6-5,9.3-9.7c4-7.2,7.5-14.6,10.4-22.3C794.3,236,794.8,231.5,793.2,226.9z"/>
				<path opacity="0.1091" fill="#333333" d="M746.3,212.8c-8.1-21.9-16.1-43.8-24.1-65.8c-1.9-2.7-4.5-3.8-7.7-3.4
					c-4.2,1.5-7.1,4.4-8.6,8.9c-3.5,6.6-6.5,13.4-8.9,20.4c-2.2,4.1-2.4,8.3-0.6,12.4c7.9,21.5,15.6,43.1,23.6,64.6
					c1.9,2.7,4.4,3.9,7.6,3.5c4.2-1.3,7.2-4.2,8.8-8.5c3.6-6.4,6.7-13,9.2-20C747.8,221,748.1,216.9,746.3,212.8z"/>
				<path opacity="0.1091" fill="#333333" d="M767.3,210.3c-8.2-21.9-16.1-43.9-24.1-65.9c-1.8-3-4.3-4.1-7.7-3.4
					c-4.7,2.1-7.8,5.8-9.5,10.9c-4.1,7.8-7.7,15.9-10.6,24.3c-2.6,4.7-3.1,9.5-1.5,14.4c4.1,11.1,8.1,22.2,12.2,33.3
					c3.5,10.5,7.3,20.9,11.3,31.2c1.7,3,4.3,4.1,7.6,3.5c4.7-2,7.9-5.5,9.7-10.5c4.2-7.6,7.9-15.5,11-23.7
					C768.3,219.8,768.8,215.1,767.3,210.3z"/>
				<path opacity="0.1091" fill="#333333" d="M790,244.5c-7.6-22.2-15.8-44.1-23.9-66.1c-2-2.4-4.6-3.6-7.7-3.4
					c-3.9,0.9-6.5,3.3-7.9,7.2c-3.1,5.5-5.6,11.3-7.6,17.3c-1.9,3.7-1.9,7.3,0.1,10.7c6,16.5,12.1,32.9,18.1,49.4
					c-0.5-1.4-0.9-2.7-1.4-4.1c2.2,6.6,4.5,13.2,6.7,19.8c2,2.5,4.5,3.6,7.6,3.6c3.9-0.8,6.5-3.1,8-6.9c3.2-5.3,5.8-11,7.8-16.8
					C791.9,251.4,792,247.9,790,244.5z"/>
				<path opacity="0.1091" fill="#333333" d="M739,246.9c-1.4-13.5-4.5-26.6-9.5-39.2c-3.9-11-7.9-21.9-12-32.9
					c-0.9-2.7-2.7-4.6-5.3-5.6c-2.6-1.3-5.1-1.4-7.7-0.3c-2.8,2.6-4.9,5.7-6.2,9.3c-1.8,3.4-2.7,7-2.8,10.9c4,10.8,8,21.6,11.9,32.4
					c4.9,12.1,8.3,24.6,10.2,37.5c1,2.6,2.8,4.4,5.4,5.4c2.5,1.3,5,1.5,7.6,0.6c2.7-2.3,4.6-5,5.9-8.3
					C738.1,253.6,739,250.3,739,246.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M795.7,308.2c3-4.7,3.8-9.6,2.4-14.6c-1.7-4-3.5-8.1-5.3-12.1
					c-2.4-5.1-5.3-10-8.7-14.5c-3.8-5-8.1-9.7-12.7-13.9c-2.3-1.9-4.9-3-7.8-3.1c-3.1,0.1-5.3,1.6-6.5,4.6c-2.2,3.8-3.7,7.8-4.7,12
					c-1.1,3-0.6,5.6,1.6,7.8c2.8,3.1,5.2,6.5,7.3,10.1c2.2,3.3,4.1,6.8,5.5,10.6c-0.4-1.2-0.9-2.4-1.3-3.6c1.1,3.4,2.3,6.8,3.4,10.3
					c-0.3-1.2-0.7-2.5-1-3.7c1.2,4.7,2.5,9.3,3.9,13.9c-0.2-1.3-0.4-2.6-0.6-3.9c-0.9,3.2-1.8,6.4-2.7,9.5c-0.2,2.9,0.9,5.3,3.2,7.2
					c2.2,2,4.7,2.8,7.6,2.2c3.8-2.1,7-4.8,9.6-8.2C791.9,315.8,794.1,312.2,795.7,308.2z"/>
				<path opacity="0.1091" fill="#333333" d="M769.6,289.4c3.2-4.8,4.1-9.8,2.6-14.9c-1.5-4.1-3.3-8.1-5.3-12
					c-2.4-4.9-5.3-9.6-8.7-13.9c-3.6-4.6-7.6-8.9-12.1-12.8c-2.3-2-4.9-2.9-8-2.8c-3.4,0.6-5.6,2.6-6.7,6c-2.3,4.6-3.9,9.4-5,14.4
					c-1.3,3.4-0.8,6.3,1.5,8.9c4.6,4.7,8.9,9.4,11.9,15.3c2.5,4.8,4.4,9.8,6.2,14.8c-0.2-1.3-0.4-2.6-0.6-3.8
					c-0.9,3.2-1.8,6.5-2.7,9.7c-0.1,2.9,1,5.3,3.3,7.1c2.1,2,4.6,2.8,7.5,2.3c3.7-2,6.8-4.6,9.4-7.9
					C766.1,296.7,768.2,293.3,769.6,289.4z"/>
				<path opacity="0.1091" fill="#333333" d="M775.8,242.7c-0.4-4.3-1.4-8.6-2.9-12.7c-1.2-3.7-2.9-7.2-4.9-10.6
					c-1.9-3.7-4.2-7.2-6.8-10.5c-3.7-4.7-7.7-9.1-12.1-13.1c-1.9-2.9-4.6-3.8-7.9-2.9c-4.6,2.6-7.6,6.5-8.9,11.8
					c-3.7,8.3-6.8,16.9-9.3,25.7c-2.3,5-2.6,9.9-0.7,14.8c4.1,4.3,7.6,8.6,10.2,14c1.2,2.2,2,4.6,2.6,7c0.6,3,1.1,6.1,1.5,9.1
					c0.9,3.2,3,5.1,6.4,5.5c4.8-0.4,8.7-2.7,11.7-6.9c5.9-5.8,11.3-12.1,16.2-18.7C774.3,251.7,776,247.5,775.8,242.7z"/>
				<path opacity="0.1091" fill="#333333" d="M796,273.8c-0.4-4.2-1.4-8.3-3-12.3c-1.2-3.6-2.9-7-4.9-10.3c-1.9-3.6-4.1-7-6.7-10.2
					c-3.6-4.6-7.5-8.8-11.9-12.7c-2.1-2.4-4.8-3.3-7.9-2.8c-4,1.4-6.5,4.3-7.7,8.5c-2.9,6.2-5.2,12.6-6.8,19.3
					c-1.8,4-1.6,7.9,0.6,11.5c2.4,3.4,4.5,6.9,6.2,10.6c1.3,2.7,2.4,5.6,3.2,8.5c1.8,6,2.5,12.1,3.1,18.3c1,3.1,3.2,4.9,6.4,5.5
					c4.4-0.1,8-2,10.8-5.8c5.3-5.1,10.1-10.7,14.4-16.6C795.1,282.1,796.5,278.2,796,273.8z"/>
				<path opacity="0.1091" fill="#333333" d="M799.8,237.4c-0.9-4.3-2.1-8.5-3.3-12.7c-1.9-5-4.3-9.7-7.3-14.1
					c-2.4-3.7-5.1-7.2-7.9-10.5c-4-4.5-8.4-8.7-13.1-12.4c-4-0.7-7.1,0.7-9.3,4.4c-3.3,5.6-6.1,11.5-8.3,17.7
					c-3.2,7.5-5.9,15.1-8.2,22.9c-2.7,5.7-3.3,11.4-1.8,17.3c2.9,5,5.4,9.9,7.1,15.4c0.8,2.4,1.3,4.8,1.6,7.2c-0.1,3,0.3,6,1.3,8.9
					c0.5,3.7,2.7,5.5,6.4,5.5c5.9-1.4,10.6-4.7,14.2-9.8c7.5-7.7,14.5-15.8,21.1-24.3C796.7,248.6,799.2,243.4,799.8,237.4z"/>
				<path opacity="0.1091" fill="#333333" d="M754.7,273.5c2.6-4.6,3.2-9.2,1.7-13.9c-1.7-4-3.5-8-5.5-11.9c-2.5-4.9-5.5-9.5-9-13.7
					c-3.7-4.6-7.8-8.8-12.3-12.6c-2.3-2.1-4.9-3-8-2.7c-3.5,0.8-5.8,2.9-6.9,6.6c-2.4,4.9-4.1,10-5.3,15.4c-1.4,3.5-0.9,6.6,1.4,9.4
					c4.9,3.8,9.6,7.7,13.1,12.9c2.9,4.3,5.1,8.9,7.5,13.5c-0.2-1.2-0.5-2.5-0.7-3.7c-0.7,3-1.4,6-2.1,9c0,2.9,1.1,5.2,3.4,7
					c2.1,2,4.6,2.8,7.4,2.4c3.5-1.8,6.5-4.3,9-7.5C751.3,280.6,753.4,277.2,754.7,273.5z"/>
				<path opacity="0.1091" fill="#333333" d="M778.9,309.8c2.9-4.6,3.3-9.1,1.2-13.5c-1.4-4.7-3.4-9-6-13.2
					c-2.7-4.9-5.7-9.5-9.2-13.8c-3.8-4.6-7.9-8.8-12.5-12.7c-2.3-1.8-4.9-2.8-7.8-3.2c-2.7-0.4-4.7,0.6-6,3
					c-1.8,2.8-3.1,5.8-3.8,9.1c-0.8,2.6-0.1,4.8,2.1,6.4c2.9,2.5,5.5,5.2,7.8,8.3c2.5,2.8,4.6,5.8,6.3,9.1
					c3.2,5.8,6.1,11.9,8.6,18.1c-0.4-1.1-0.7-2.2-1.1-3.3c-0.6,3.1-1.2,6.3-1.9,9.4c0.4,2.6,1.7,4.8,3.9,6.4c1.9,1.9,4.2,2.9,6.9,3
					c2.9-1.1,5.2-2.8,7.1-5.3C776.9,315.4,778.3,312.8,778.9,309.8z"/>
				<path opacity="0.1091" fill="#333333" d="M812.6,268.9c-0.7-4.2-1.7-8.3-3.2-12.3c-1.3-3.6-3-7-5.1-10.1c-2-3.6-4.3-6.9-7-10
					c-3.7-4.5-7.7-8.6-12.1-12.4c-2-2.7-4.7-3.6-7.9-2.9c-4.3,2-7.1,5.4-8.4,10.3c-3.3,7.3-6.1,14.9-8.2,22.6
					c-2.1,4.5-2.2,8.9-0.1,13.2c1.9,3.6,3.5,7.4,4.7,11.3c1,2.8,1.7,5.7,2.2,8.7c0.6,2.8,1,5.6,1.2,8.5c-0.1,3.3,0.2,6.5,0.9,9.7
					c0.8,3.4,2.9,5.2,6.4,5.5c5.2-0.8,9.4-3.4,12.6-7.9c6.5-6.5,12.4-13.4,18-20.7C810.6,278.7,812.6,274.2,812.6,268.9z"/>
				<path opacity="0.1091" fill="#333333" d="M765.3,255.2c-0.3-4.2-1.3-8.2-3.1-12c-1.2-3.7-2.8-7.1-4.8-10.4
					c-1.9-3.7-4.1-7.1-6.8-10.3c-3.6-4.6-7.6-8.9-12-12.8c-2.1-2.5-4.7-3.4-7.9-2.8c-4.1,1.7-6.8,4.9-8,9.4
					c-3.1,6.8-5.5,13.7-7.4,20.9c-1.9,4.3-1.8,8.4,0.3,12.3c2.8,2.2,5.3,4.8,7.6,7.6c1.8,2.1,3.2,4.4,4.4,6.8
					c1.4,2.3,2.4,4.7,3.1,7.3c1.2,2.9,1.8,6,1.8,9.2c1.2,3,3.3,4.8,6.4,5.5c4.1,0.2,7.5-1.5,10-4.9c4.8-4.6,9.1-9.6,13-15
					C764.9,262.8,766.1,259.3,765.3,255.2z"/>
				<path opacity="0.1091" fill="#333333" d="M786.1,253.2c-0.5-4.3-1.5-8.5-2.9-12.5c-1.2-3.7-2.9-7.1-4.9-10.4
					c-1.9-3.7-4.2-7.1-6.8-10.3c-3.6-4.6-7.7-8.9-12.1-12.8c-2-2.8-4.6-3.7-7.9-2.9c-4.5,2.4-7.4,6.1-8.7,11.2
					c-3.5,7.9-6.5,16.1-8.8,24.4c-2.3,4.8-2.4,9.5-0.5,14.2c3.8,5,6.9,10,9.1,15.8c2,5.5,2.6,11.1,3.4,16.8c0.9,3.3,3,5.1,6.4,5.5
					c4.9-0.5,8.9-2.9,11.9-7.1c6-6,11.5-12.4,16.6-19.2C784.5,262.4,786.3,258.2,786.1,253.2z"/>
				<path opacity="0.1091" fill="#333333" d="M808.9,286.8c-0.4-4.2-1.4-8.3-3-12.2c-1.2-3.6-2.9-7-4.8-10.3
					c-1.9-3.6-4.1-7-6.7-10.2c-3.6-4.6-7.5-8.9-11.7-13c-2.2-2.2-4.8-3.2-7.9-2.8c-3.7,1.1-6.1,3.5-7.3,7.4
					c-2.6,5.5-4.6,11.2-6.1,17.1c-1.6,3.7-1.3,7.2,1,10.4c2.4,3.9,4.3,8.1,6,12.4c1.2,3.1,2.2,6.2,2.8,9.5c1.5,6.4,2,12.8,2.5,19.3
					c1.1,3.1,3.2,4.9,6.4,5.5c4.4,0,7.9-1.9,10.6-5.6c5.2-5,9.9-10.4,14.1-16.3C808.2,294.9,809.5,291.2,808.9,286.8z"/>
				<path opacity="0.1091" fill="#333333" d="M757.5,287.6c2.7-4.6,3.1-9.1,1.2-13.5c-1.3-4.8-3.2-9.3-5.8-13.5
					c-2.5-5-5.5-9.7-8.9-14.1c-3.6-4.3-7.6-8.3-11.9-12c-2.3-1.9-4.9-2.8-7.9-2.9c-3.1,0.2-5.3,1.8-6.4,4.8
					c-2.1,3.8-3.6,7.9-4.5,12.2c-1.1,3.1-0.5,5.7,1.8,7.8c5,4,9.4,7.6,12.9,13.1c3.5,5.4,6.5,11.2,9.1,17.1c-0.3-1.1-0.7-2.3-1-3.4
					c-0.6,3.1-1.2,6.2-1.8,9.4c0.3,2.7,1.6,4.9,3.8,6.6c2,1.9,4.3,2.9,7,2.8c3-1.3,5.6-3.2,7.6-5.9
					C755.1,293.6,756.6,290.8,757.5,287.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M709.2,181.5c-10.2-8.1-21.3-14.8-33.2-20.1c-2.5-1.1-5-1.2-7.6-0.1
					c-2.7,0.7-4.7,2.2-6,4.6c-0.5,3.3-0.1,6.4,1.1,9.5c0.8,3.2,2.4,6,4.7,8.3c11.6,5,22.5,11.2,32.7,18.6c2.5,1,5,1,7.5-0.1
					c2.6-0.7,4.7-2.1,6.1-4.4c0.6-3,0.3-5.9-0.9-8.8C712.9,186.1,711.4,183.6,709.2,181.5z"/>
				<path opacity="0.1091" fill="#333333" d="M683.2,161.1c-11.9-6.4-22.9-12.2-35-18.2c-3.1-0.4-5.8,0.6-7.9,2.8
					c-2.2,3.3-2.5,6.8-0.9,10.6c1.5,6.1,3.6,12,6.2,17.6c1.1,4,3.6,6.6,7.4,7.7c5.1,2.6,10.3,5.1,15.4,7.7c-1.3-0.7-2.5-1.3-3.8-2
					c6.2,3.3,12.4,6.6,18.6,9.9c3.1,0.4,5.8-0.4,8-2.6c1.8-2.2,2.3-4.5,1.5-7.1c-0.2-4.2-1.2-8.2-2.8-12.1
					C688.5,170.2,686.2,165.4,683.2,161.1z"/>
				<path opacity="0.1091" fill="#333333" d="M681.2,115.6c-10.6-5-21-10.2-31.3-15.7c-3.5-1.4-6.1-0.4-7.9,2.8
					c-1.7,5.9-1.2,11.7,1.5,17.4c3,10.5,6.6,20.7,10.8,30.8c1.5,6.1,4.8,11,9.8,14.5c9.6,5.4,19.5,10.4,29.6,14.9
					c3.4,1.4,6.1,0.5,8-2.6c1.8-5.7,1.5-11.5-1.1-17.2c-2.7-10.4-6.1-20.5-10-30.5C689.2,124,686.1,119.1,681.2,115.6z"/>
				<path opacity="0.1091" fill="#333333" d="M703.6,150.5c-10.3-5.6-20.7-11-31.2-16.1c-3.1-0.4-5.8,0.5-7.9,2.8
					c-2.2,3.3-2.5,6.9-0.9,10.7c1.5,6.1,3.6,12.1,6.3,17.8c1.1,4,3.6,6.6,7.4,7.8c5.1,2.6,10.3,5.2,15.4,7.8c-1.3-0.7-2.6-1.4-3.8-2
					c6.2,3.3,12.4,6.6,18.6,9.9c3.1,0.5,5.8-0.4,8-2.6c2.3-3.2,2.7-6.7,1.1-10.5c-1.3-6.1-3.3-12-5.8-17.7
					C709.7,154.3,707.3,151.7,703.6,150.5z"/>
				<path opacity="0.1091" fill="#333333" d="M703.4,114.1c-10.5-5-21-10.3-31.3-15.7c-3.4-1.2-6.1-0.3-7.9,2.8
					c-1.8,5.5-1.4,11,1.1,16.4c2.8,9.8,6.1,19.4,10.1,28.8c1.4,5.8,4.6,10.3,9.4,13.5c9.7,5.4,19.6,10.4,29.7,15
					c3.4,1.3,6,0.4,8-2.6c1.9-5.4,1.7-10.8-0.7-16.2c-2.5-9.7-5.6-19.2-9.3-28.5C711.1,121.8,708.1,117.3,703.4,114.1z"/>
				<path opacity="0.1091" fill="#333333" d="M662.4,143.8c-10.4-5.3-20.9-10.6-31.3-15.9c-3.3-0.8-5.9,0.1-7.9,2.8
					c-2,4.4-2,8.9,0.1,13.5c2.1,7.9,4.9,15.6,8.2,23.1c1.3,4.9,4,8.4,8.4,10.5c9.9,5.2,19.9,10.3,29.9,15.3c3.2,0.8,5.9,0,8-2.6
					c2.1-4.3,2.2-8.7,0.2-13.3c-1.9-7.8-4.5-15.5-7.6-22.9C669.4,149.4,666.7,145.9,662.4,143.8z"/>
				<path opacity="0.1091" fill="#333333" d="M690.8,184c-10.3-7.9-21.3-14.6-33.2-19.8c-2.5-1.1-5-1.2-7.6-0.1
					c-2.7,0.7-4.7,2.2-6,4.6c-0.5,3.3-0.1,6.5,1.2,9.6c0.9,3.2,2.4,6,4.8,8.4c11.5,5,22.4,11.2,32.7,18.4c2.5,1,5,1,7.5,0
					c2.6-0.7,4.7-2.2,6.1-4.4c0.5-3,0.2-6-0.9-8.9C694.5,188.7,693,186.1,690.8,184z"/>
				<path opacity="0.1091" fill="#333333" d="M723.3,148.5c-5.8-3.2-11.6-6.4-17.4-9.4c-5.8-3-11.7-6-17.6-8.8
					c-3.1-0.4-5.8,0.5-7.9,2.8c-2.2,3.2-2.5,6.7-0.9,10.5c1.4,6,3.5,11.9,6.1,17.5c1.1,4,3.6,6.5,7.3,7.6c5.1,2.6,10.3,5.1,15.4,7.7
					c-1.3-0.7-2.5-1.3-3.8-2c4.9,2.6,9.9,5.3,14.8,7.9c2.4,2.1,5.1,2.7,8.1,1.7c3-1,4.9-3,5.5-6.1c-0.1-5.2-1.1-10.3-3-15.2
					C728.4,157.5,726.2,152.8,723.3,148.5z"/>
				<path opacity="0.1091" fill="#333333" d="M672,130.1c-10.5-5-20.9-10.3-31.3-15.7c-3.4-1.2-6-0.2-7.9,2.8
					c-1.8,5.3-1.5,10.6,1,15.9c2.7,9.5,5.9,18.7,9.8,27.7c1.4,5.7,4.5,10,9.2,13c9.7,5.4,19.6,10.4,29.7,15.1c3.3,1.2,6,0.3,8-2.6
					c1.9-5.2,1.8-10.4-0.6-15.7c-2.4-9.4-5.4-18.5-9.1-27.5C679.6,137.4,676.6,133.1,672,130.1z"/>
				<path opacity="0.1091" fill="#333333" d="M693,129c-10.5-5.1-20.9-10.4-31.3-15.8c-3.3-1-6-0.1-7.9,2.8
					c-1.9,4.9-1.7,9.9,0.6,14.9c2.4,8.8,5.5,17.4,9.1,25.8c1.3,5.3,4.3,9.3,8.8,12c9.8,5.3,19.7,10.4,29.8,15.2c3.3,1,6,0.2,8-2.6
					c2-4.8,2-9.7-0.2-14.7c-2.2-8.7-5-17.3-8.4-25.6C700.3,135.6,697.5,131.6,693,129z"/>
				<path opacity="0.1091" fill="#333333" d="M720.9,167.4c-9.6-8.8-20.5-15.6-32.5-20.4c-2.5-1.5-5.1-1.7-7.8-0.6
					c-2.8,0.9-4.7,2.6-5.7,5.4c-0.1,4.1,0.6,8.1,2.2,12c1.2,4,3.1,7.6,5.8,10.7c11.5,4.5,22,10.8,31.4,18.8c4.2,3,11.9,0.4,13.6-4.5
					c0.3-3.9-0.3-7.6-1.7-11.3C725.2,173.7,723.5,170.3,720.9,167.4z"/>
				<path opacity="0.1091" fill="#333333" d="M664.8,158.9c-10.3-5.4-20.7-10.8-31.2-16c-3.1-0.4-5.8,0.5-7.9,2.8
					c-2.2,3.3-2.5,7-0.8,10.8c1.5,6.2,3.7,12.2,6.4,18c1.1,4.1,3.6,6.7,7.4,7.9c10.1,5,20.2,10.2,30.2,15.6c3.1,0.5,5.8-0.4,8-2.6
					c2.3-3.3,2.6-6.8,1.1-10.7c-1.4-6.2-3.4-12.1-5.9-17.9C671,162.8,668.6,160.1,664.8,158.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M97.1,863.9c1.1-3.6,2.2-7.2,3.3-10.8c0.5-3-0.3-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.6-2.4
					c-4.1,2.1-7.8,4.9-10.9,8.4c-3.4,3.2-6.1,6.8-8.2,11c-0.9,3.3-1.8,6.5-2.8,9.8c-1.6,5.6,4.9,11.1,10.1,10.1
					c4.1-1.9,7.6-4.6,10.6-7.9C92.5,871.5,95.2,867.9,97.1,863.9z"/>
				<path opacity="0.1091" fill="#333333" d="M70.6,845.4c1.5-4.1,3-8.1,4.5-12.2c0.4-2.9-0.5-5.4-2.7-7.4c-2.1-2.2-4.5-3.1-7.5-2.6
					c-3.9,1.9-7.4,4.5-10.3,7.7c-3.2,3-5.8,6.4-7.6,10.4c-1.2,3.7-2.5,7.4-3.7,11.1c-0.3,2.9,0.6,5.3,2.8,7.3c2,2.2,4.4,3.1,7.3,2.8
					c3.8-1.7,7.1-4.1,9.9-7.2C66.5,852.5,68.9,849.2,70.6,845.4z"/>
				<path opacity="0.1091" fill="#333333" d="M77.1,799.1c0-2.5,0-5.1,0-7.6c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.4c-3.8,3.3-5.7,7.3-5.7,12.1c0,2.2,0,4.5,0.1,6.7c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C75.2,807.8,77.1,803.8,77.1,799.1z"/>
				<path opacity="0.1091" fill="#333333" d="M97.4,830.1c0-2.5-0.1-5-0.1-7.5c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.7,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,2.2,0.1,4.5,0.1,6.7c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C95.9,838.3,97.6,834.5,97.4,830.1z"/>
				<path opacity="0.1091" fill="#333333" d="M101.3,794c-0.1-2.5-0.2-4.9-0.4-7.4c-0.3-3.7-2.3-5.7-6-5.9c-6,1.1-10.9,4-14.8,8.9
					c-8,7.2-15.5,14.9-22.5,23.1c-4.8,4-7.7,9.1-8.6,15c0.2,2.1,0.4,4.1,0.6,6.2c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6
					c7.9-7.1,15.4-14.6,22.5-22.5C97.5,804.9,100.4,799.9,101.3,794z"/>
				<path opacity="0.1091" fill="#333333" d="M55.5,829.9c1.3-3.9,2.6-7.9,3.9-11.8c0.2-2.9-0.7-5.3-2.9-7.2c-2-2.2-4.4-3.1-7.2-2.9
					c-3.7,1.6-6.8,3.9-9.5,6.9c-3,2.7-5.3,5.9-6.8,9.6c-1.1,3.7-2.2,7.5-3.3,11.2c-0.2,2.8,0.8,5.2,3,7.1c1.9,2.1,4.3,3.1,7.2,3
					c3.6-1.5,6.6-3.7,9.2-6.6C51.9,836.6,54,833.5,55.5,829.9z"/>
				<path opacity="0.1091" fill="#333333" d="M80.3,865.8c1-3.7,2.1-7.4,3.1-11.1c-0.1-2.7-1.2-5-3.3-6.8c-1.8-2.1-4.1-3.2-6.8-3.3
					c-3.1,1.1-5.8,2.8-8,5.3c-2.5,2.2-4.3,4.9-5.4,8c-0.8,3.5-1.7,7-2.5,10.5c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4
					c3-1,5.6-2.6,7.7-5C77.6,871.4,79.3,868.8,80.3,865.8z"/>
				<path opacity="0.1091" fill="#333333" d="M114.3,825c-0.1-2.5-0.2-5-0.2-7.4c-0.5-3.5-2.5-5.5-6-5.9c-5.4,0.6-9.9,3.1-13.4,7.5
					c-7.1,6.3-13.7,13.1-19.9,20.3c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,2.1,0.2,4.3,0.4,6.4c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C111.5,834.8,113.9,830.3,114.3,825z"/>
				<path opacity="0.1091" fill="#333333" d="M66.5,811.8c0-2.6,0.1-5.1,0.1-7.7c-1-3-3-5-6-5.9c-2.9-0.6-5.3,0.2-7.3,2.2
					c-3.7,2.3-7.1,5.1-9.9,8.5c-4.1,3.8-7.4,8.2-10.1,13.1c-0.1,3.8-0.3,7.7-0.4,11.5c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C65.8,819.1,67.1,815.7,66.5,811.8z"/>
				<path opacity="0.1091" fill="#333333" d="M87.5,809.5c0-2.5,0-5-0.1-7.5c-0.7-3.3-2.7-5.3-6-5.9c-5,0.2-9.1,2.3-12.3,6.4
					c-6.4,5.6-12.3,11.7-17.7,18.1c-3.9,3.4-6,7.5-6.1,12.5c0,2.2,0.1,4.4,0.1,6.6c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C85.4,818.5,87.4,814.4,87.5,809.5z"/>
				<path opacity="0.1091" fill="#333333" d="M110.4,843c0-2.5-0.1-5-0.1-7.5c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,2.2,0.1,4.5,0.1,6.7c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C108.9,851.3,110.6,847.5,110.4,843z"/>
				<path opacity="0.1091" fill="#333333" d="M58.5,843.9c1-3.7,2.1-7.4,3.1-11.1c-0.1-2.7-1.2-5-3.3-6.9c-1.8-2.1-4.1-3.2-6.9-3.3
					c-3.2,1.1-5.9,2.9-8.1,5.5c-2.5,2.3-4.4,5-5.5,8.1c-0.9,3.5-1.7,7-2.6,10.6c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3
					c3.1-1,5.7-2.7,7.9-5.2C55.8,849.6,57.5,847,58.5,843.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M105,923.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C94.4,920.2,99.3,923,105,923.8z"/>
				<path opacity="0.1091" fill="#333333" d="M76.9,901.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C69.7,901.3,73,902.5,76.9,901.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M75.5,862.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C70,860,72.5,861.6,75.5,862.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M101.3,893.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C93,892.1,96.8,893.8,101.3,893.6z"/>
				<path opacity="0.1091" fill="#333333" d="M95.6,858.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C88.6,854.2,91.9,856.6,95.6,858.2z"/>
				<path opacity="0.1091" fill="#333333" d="M56.2,887.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C50.3,885,53,886.8,56.2,887.9z"/>
				<path opacity="0.1091" fill="#333333" d="M85.6,925.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C76.5,923.5,80.6,925.6,85.6,925.8z"/>
				<path opacity="0.1091" fill="#333333" d="M117.6,889.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C108.4,887.6,112.6,889.7,117.6,889.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,874.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C59.6,871.7,62.4,873.5,65.6,874.6z"/>
				<path opacity="0.1091" fill="#333333" d="M84.7,871.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C77.3,866.7,80.7,869.2,84.7,871.1z"/>
				<path opacity="0.1091" fill="#333333" d="M116,908.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C105.6,905,110.4,907.6,116,908.4z"/>
				<path opacity="0.1091" fill="#333333" d="M56.6,900.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C49.4,896.6,52.7,899.1,56.6,900.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85.1,870.1c0.4-2,0.8-3.9,1.2-5.9c-0.6,1.6-1.1,3.3-1.7,4.9c1.3-3.4,2.6-6.8,3.9-10.1
					c-2,3.7-4.1,7.4-6.1,11.1c3.6-4.5,6.9-9.3,9.8-14.4c0-1.3-0.1-2.7-0.1-4c-1.1-2.8-3.1-4.8-5.9-6c-2.5-0.9-4.6-0.5-6.5,1.2
					c-3.2,1.7-6.1,3.9-8.5,6.7c-3.4,2.6-5.8,5.8-7.2,9.7c-1.6,2-2.3,4.3-2,6.7c-1,3.7-1.6,7.4-1.8,11.1c0.3,2.9,1.7,5.1,4.1,6.6
					c2.3,1.8,4.9,2.3,7.7,1.5c3.3-2.2,6.1-5.1,8.2-8.6C82.6,877.6,84.2,874.1,85.1,870.1z"/>
				<path opacity="0.1091" fill="#333333" d="M58.4,852.5c0.6-2.2,1.1-4.4,1.7-6.7c-0.6,1.7-1.2,3.4-1.8,5c1.5-3.6,2.9-7.3,4.4-10.9
					c-2.1,3.7-4.1,7.5-6.2,11.2c2.5-3.4,5-6.9,7.5-10.3c0.9-1.6,1.8-3.1,2.7-4.7c-0.1-1.3-0.2-2.6-0.3-3.9c-0.8-1.2-1.6-2.4-2.4-3.6
					c-1.5-1.8-3.3-2.7-5.5-2.6c-1.6-0.5-3.1-0.1-4.3,1.2c-2,0.9-3.7,2.2-5.2,3.8c-2,1.5-3.8,3.3-5.3,5.3c-2.2,2.1-4,4.6-5.2,7.5
					c-1.4,3.5-2.6,7.2-3.6,10.8c-1,3.2-1.7,6.4-2.1,9.7c0.2,2.9,1.6,5.1,4.1,6.7c2.4,1.8,4.9,2.2,7.7,1.4c3.4-2.4,6.2-5.3,8.4-8.9
					C55.7,860.2,57.4,856.5,58.4,852.5z"/>
				<path opacity="0.1091" fill="#333333" d="M63.8,805.3c0-1.3-0.1-2.6-0.1-3.9c0,1.8-0.1,3.5-0.1,5.3c0.3-1,0.6-2.1,0.9-3.1
					c-1.4,2.7-2.8,5.3-4.2,8c2-3.5,4.1-7.1,6.1-10.6c-4.1,5.8-8.1,11.6-12.2,17.3c3.3-4.1,6.6-8.1,9.9-12.2c1.6-2.3,3.3-4.7,4.9-7
					c0.2-1.7,0.5-3.3,0.7-5c-0.7-1.3-1.4-2.6-2.1-3.9c-1.9-1.9-4.1-2.5-6.4-1.6c-2.4,0-4.4,1-5.9,2.9c-2.9,1.9-5.5,4.1-7.8,6.6
					c-3,2.6-5.8,5.5-8.4,8.6c-3.5,3.4-6.4,7.2-8.9,11.4c-1.5,4.5-3,8.9-3.2,13.8c-0.1,1.7-0.2,3.3-0.1,5c-0.2,2.3,0,4.6,0.6,6.9
					c1.2,3.2,3.5,4.8,7,4.7c4.9-1.1,8.6-4,11.1-8.6c5.4-6.7,10.2-13.8,14.5-21.2C63.2,814.7,64.5,810.2,63.8,805.3z"/>
				<path opacity="0.1091" fill="#333333" d="M84.5,841.1c-0.2-2.8-0.5-5.6-0.7-8.4c-1,3.9-1.9,7.8-2.9,11.7c2-4.3,3.9-8.6,5.9-12.8
					c-3.6,5.2-7.2,10.3-10.8,15.5c5-5.5,9.4-11.3,13.4-17.5c0.2-1.6,0.4-3.2,0.6-4.8c-0.8-3.2-2.8-5.1-6-5.9c-3.2-0.4-5.8,0.6-8,2.9
					c-4.3,2.7-8.1,6-11.5,9.8c-3.8,3.4-7.1,7.2-9.8,11.5c-3,3.3-4.2,7.4-3.7,12.1c-0.1,4.3,0.3,8.5,1.2,12.7
					c1.4,2.9,3.7,4.5,6.9,4.8c3.1-0.2,5.5-1.5,7.2-4c3.6-3.3,6.7-7.1,9.2-11.3C79.4,852.3,82.4,846.9,84.5,841.1z"/>
				<path opacity="0.1091" fill="#333333" d="M87.5,799.9c-0.1-1.3-0.1-2.5-0.2-3.8c-0.3,2.1-0.5,4.1-0.8,6.2c0.3-1,0.6-2,1-3
					c0.3-2.1,0.6-4.1,1-6.2c-0.8-1.3-1.5-2.7-2.3-4c-1.5-0.3-3-0.7-4.5-1c-4.1,1.1-7.3,3.4-9.7,6.8c-7,7.3-13.1,15.4-18.9,23.7
					c-2.9,3.7-5.4,7.6-7.6,11.7c-2.7,3.3-3.9,7-3.8,11.2c0.1,2.8,0.5,5.6,1.4,8.3c0.9,3.6,3.2,5.2,6.9,4.8
					c5.7-2.1,10.1-5.9,13.1-11.5c6.6-8.6,12.8-17.5,18.4-26.8C85.6,811.4,87.5,805.9,87.5,799.9z"/>
				<path opacity="0.1091" fill="#333333" d="M42.2,837.8c0.6-2.2,1.1-4.5,1.7-6.7c-0.6,1.7-1.2,3.3-1.7,5c0.8-3.1,2.2-6,4.1-8.5
					c-1.4,1.9-2.9,3.8-4.3,5.7c3.3-2.8,5.9-6.2,7.8-10c1.5-1.7,1.8-3.7,0.8-5.9c-0.8-1.2-1.6-2.4-2.4-3.5c-1.4-1.8-3.2-2.7-5.4-2.7
					c-1.5-0.6-2.9-0.2-4.1,1c-2.9,1.3-5.4,3.2-7.5,5.7c-1.5,1.7-3.1,3.5-4.6,5.2c-5.3,8.3-8.6,17.2-9.9,26.7
					c0.2,2.9,1.6,5.1,4.1,6.7c2.4,1.8,4.9,2.2,7.7,1.4c3.4-2.4,6.2-5.3,8.4-8.9C39.6,845.5,41.3,841.9,42.2,837.8z"/>
				<path opacity="0.1091" fill="#333333" d="M66.5,876.7c0.6-3.2,1.3-6.4,1.9-9.6c-0.3,1.2-0.6,2.4-0.8,3.7
					c0.6-1.8,1.3-3.5,1.9-5.3c-0.9,1.5-1.9,2.9-2.8,4.4c1.6-2.3,3.2-4.7,4.9-7c-2.4,2.7-4.7,5.4-7.1,8.2c2-2,4.1-3.9,6.1-5.9
					c2.6-2.2,4.4-4.9,5.5-8.1c-0.1-2.7-1.1-5-3.2-6.9c-1.8-2.1-4.1-3.2-6.8-3.3c-3.2,1.1-5.9,2.9-8.2,5.4c-1.1,1-2.1,2.1-3.2,3.1
					c-2.4,2.6-4.1,5.6-5,9c-1.1,2.1-1.8,4.2-2.3,6.5c-0.6,2.5-1,5-1.2,7.5c0.8,2.5,2.4,4.5,4.7,5.8c1.6,1.4,3.4,2.1,5.4,2.1
					c1.8,0.5,3.4-0.1,4.7-1.8C63.4,882.3,65.2,879.6,66.5,876.7z"/>
				<path opacity="0.1091" fill="#333333" d="M100.2,832.1c-0.1-1.3-0.1-2.6-0.2-3.9c-1.5,4.6-2.9,9.2-4.4,13.8
					c3.4-5.6,6.8-11.1,10.2-16.7c0.4-1.8,0.8-3.6,1.2-5.4c-0.6-1.4-1.3-2.7-1.9-4.1c-1.4-0.6-2.7-1.2-4.1-1.8
					c-3.7,0.1-6.9,1.6-9.4,4.3c-6.9,5.2-13,11.4-18.8,17.8c-2.9,2.8-5.4,5.8-7.7,9.1c-2.7,2.5-4.1,5.7-4.1,9.4c-0.2,3-0.1,6,0.3,9
					c0.2,2.7,0.7,5.3,1.5,7.8c1.2,3.2,3.5,4.8,6.9,4.8c5-1.2,8.7-4.1,11.3-8.7c5.5-6.8,10.4-14,14.9-21.6
					C99.5,841.7,100.9,837.1,100.2,832.1z"/>
				<path opacity="0.1091" fill="#333333" d="M53.6,822.8c-0.5-2.4-0.3-4.7,0.6-7c-1.1,2.2-2.1,4.4-3.2,6.5c1.7-3.1,3.4-6.1,5.1-9.2
					c0.8-3.2-0.1-5.9-2.5-8c-2.5-2.1-5.3-2.5-8.3-1.3c-4.7,3.2-8.8,7-12.4,11.5c-3.9,4.2-7,8.9-9.5,14c-1,2.5-1.6,5.1-1.6,7.7
					c-0.3,2.2-0.2,4.4,0.2,6.7c-0.5,3.3,0.6,5.9,3.3,7.9c2.8,1.8,5.6,1.9,8.5,0.2c4.5-3.9,8.3-8.4,11.5-13.4
					C48.8,833.6,51.6,828.4,53.6,822.8z"/>
				<path opacity="0.1091" fill="#333333" d="M74,816c-0.1-1.3-0.1-2.6-0.2-3.9c0,1.8-0.1,3.5-0.1,5.3c0.3-1.1,0.6-2.1,1-3.2
					c-1.4,2.7-2.9,5.4-4.3,8.1c2.9-4.2,5.9-8.4,8.8-12.6c1.6-3.3,1.2-6.3-1.1-9c-2.8-2.3-5.8-2.7-9.1-1c-6,3.9-11.4,8.6-16.3,13.8
					c-5.2,5-9.7,10.5-13.6,16.5c-0.5,1.8-0.9,3.5-1.4,5.3c-0.6,2.6-0.9,5.3-0.9,8c-0.2,1.6-0.2,3.2-0.1,4.9c0,2.2,0.2,4.3,0.8,6.4
					c1.2,3.2,3.5,4.8,6.9,4.8c4.9-1.1,8.6-3.9,11.1-8.5c5.3-6.7,10.2-13.7,14.5-21.2C73.5,825.4,74.8,821,74,816z"/>
				<path opacity="0.1091" fill="#333333" d="M97.5,854.1c-0.1-1.3-0.2-2.5-0.3-3.8c-1,2.2-2,4.5-3,6.7c1.9-4.2,3.8-8.4,5.7-12.7
					c-3.5,5-7,10.1-10.5,15.1c2.9-3.5,5.8-7.1,8.7-10.6c1.5-2.2,3-4.4,4.5-6.5c0.2-1.6,0.4-3.2,0.6-4.8c-0.7-1.3-1.4-2.6-2.1-3.9
					c-1.8-1.9-3.9-2.5-6.2-1.8c-2.2-0.1-4.1,0.8-5.6,2.6c-2.7,1.7-5.2,3.7-7.4,6c-2.9,2.4-5.5,5-7.9,7.9c-3.3,3.2-6,6.7-8.3,10.6
					c-0.6,2.8-0.8,5.6-0.7,8.4c0.1,3.9,0.6,7.7,1.6,11.5c1.4,2.8,3.8,4.4,6.9,4.8c3,0,5.3-1.3,7-3.7c3.4-3.1,6.4-6.6,8.8-10.6
					C92.8,864.7,95.5,859.6,97.5,854.1z"/>
				<path opacity="0.1091" fill="#333333" d="M46.1,850.2c0.4-1.9,0.7-3.9,1.1-5.8c-0.4,1.4-0.8,2.8-1.2,4.2
					c0.6-1.7,1.2-3.4,1.8-5.2c-1.1,1.6-2.1,3.3-3.2,4.9c1.7-2.5,3.5-5,5.2-7.4c-1.1,1.6-2.3,3.1-3.4,4.7c0.8-0.8,1.5-1.5,2.3-2.3
					c2.6-2.3,4.5-5,5.6-8.3c0-2.8-1.1-5.1-3.2-6.9c-1.8-2.1-4.1-3.2-6.9-3.3c-3.2,1.1-6,3-8.3,5.6c-1.9,2.3-3.7,4.5-5.6,6.8
					c-1.9,2-3.2,4.3-3.9,7c-0.6,1.7-1.1,3.4-1.5,5.1c-0.9,2.9-1.4,5.9-1.6,8.9c0.6,2.7,2.1,4.7,4.5,6.2c2.2,1.7,4.7,2.3,7.4,1.9
					c2.9-1.7,5.3-4,7.1-6.9C44.2,856.6,45.6,853.6,46.1,850.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M119,985.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C108.4,982.2,113.3,985,119,985.8z"/>
				<path opacity="0.1091" fill="#333333" d="M90.9,963.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C83.7,963.3,87,964.5,90.9,963.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M89.5,924.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C84,922,86.5,923.6,89.5,924.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M115.3,955.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C107,954.1,110.8,955.8,115.3,955.6z"/>
				<path opacity="0.1091" fill="#333333" d="M109.6,920.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C102.6,916.2,105.9,918.6,109.6,920.2z"/>
				<path opacity="0.1091" fill="#333333" d="M70.2,949.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C64.3,947,67,948.8,70.2,949.9z"/>
				<path opacity="0.1091" fill="#333333" d="M99.6,987.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C90.5,985.5,94.6,987.6,99.6,987.8z"/>
				<path opacity="0.1091" fill="#333333" d="M131.6,951.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C122.4,949.6,126.6,951.7,131.6,951.9z"/>
				<path opacity="0.1091" fill="#333333" d="M79.6,936.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C73.6,933.7,76.4,935.5,79.6,936.6z"/>
				<path opacity="0.1091" fill="#333333" d="M98.7,933.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C91.3,928.7,94.7,931.2,98.7,933.1z"/>
				<path opacity="0.1091" fill="#333333" d="M130,970.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C119.6,967,124.4,969.6,130,970.4z"/>
				<path opacity="0.1091" fill="#333333" d="M70.6,962.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C63.4,958.6,66.7,961.1,70.6,962.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M139,981.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C128.4,978.2,133.3,981,139,981.8z"/>
				<path opacity="0.1091" fill="#333333" d="M110.9,959.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C103.7,959.3,107,960.5,110.9,959.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M109.5,920.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C104,918,106.5,919.6,109.5,920.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M135.3,951.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C127,950.1,130.8,951.8,135.3,951.6z"/>
				<path opacity="0.1091" fill="#333333" d="M129.6,916.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C122.6,912.2,125.9,914.6,129.6,916.2z"/>
				<path opacity="0.1091" fill="#333333" d="M90.2,945.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C84.3,943,87,944.8,90.2,945.9z"/>
				<path opacity="0.1091" fill="#333333" d="M119.6,983.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C110.5,981.5,114.6,983.6,119.6,983.8z"/>
				<path opacity="0.1091" fill="#333333" d="M151.6,947.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C142.4,945.6,146.6,947.7,151.6,947.9z"/>
				<path opacity="0.1091" fill="#333333" d="M99.6,932.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C93.6,929.7,96.4,931.5,99.6,932.6z"/>
				<path opacity="0.1091" fill="#333333" d="M118.7,929.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C111.3,924.7,114.7,927.2,118.7,929.1z"/>
				<path opacity="0.1091" fill="#333333" d="M150,966.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C139.6,963,144.4,965.6,150,966.4z"/>
				<path opacity="0.1091" fill="#333333" d="M90.6,958.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C83.4,954.6,86.7,957.1,90.6,958.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M175,1011.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C164.4,1008.2,169.3,1011,175,1011.8z"/>
				<path opacity="0.1091" fill="#333333" d="M146.9,989.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C139.7,989.3,143,990.5,146.9,989.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M145.5,950.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C140,948,142.5,949.6,145.5,950.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M171.3,981.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C163,980.1,166.8,981.8,171.3,981.6z"/>
				<path opacity="0.1091" fill="#333333" d="M165.6,946.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C158.6,942.2,161.9,944.6,165.6,946.2z"/>
				<path opacity="0.1091" fill="#333333" d="M126.2,975.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C120.3,973,123,974.8,126.2,975.9z"/>
				<path opacity="0.1091" fill="#333333" d="M155.6,1013.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C146.5,1011.5,150.6,1013.6,155.6,1013.8z"/>
				<path opacity="0.1091" fill="#333333" d="M187.6,977.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C178.4,975.6,182.6,977.7,187.6,977.9z"/>
				<path opacity="0.1091" fill="#333333" d="M135.6,962.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C129.6,959.7,132.4,961.5,135.6,962.6z"/>
				<path opacity="0.1091" fill="#333333" d="M154.7,959.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C147.3,954.7,150.7,957.2,154.7,959.1z"/>
				<path opacity="0.1091" fill="#333333" d="M186,996.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C175.6,993,180.4,995.6,186,996.4z"/>
				<path opacity="0.1091" fill="#333333" d="M126.6,988.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C119.4,984.6,122.7,987.1,126.6,988.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M169,963.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C158.4,960.2,163.3,963,169,963.8z"/>
				<path opacity="0.1091" fill="#333333" d="M140.9,941.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C133.7,941.3,137,942.5,140.9,941.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M139.5,902.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C134,900,136.5,901.6,139.5,902.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M165.3,933.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C157,932.1,160.8,933.8,165.3,933.6z"/>
				<path opacity="0.1091" fill="#333333" d="M159.6,898.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C152.6,894.2,155.9,896.6,159.6,898.2z"/>
				<path opacity="0.1091" fill="#333333" d="M120.2,927.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C114.3,925,117,926.8,120.2,927.9z"/>
				<path opacity="0.1091" fill="#333333" d="M149.6,965.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C140.5,963.5,144.6,965.6,149.6,965.8z"/>
				<path opacity="0.1091" fill="#333333" d="M181.6,929.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C172.4,927.6,176.6,929.7,181.6,929.9z"/>
				<path opacity="0.1091" fill="#333333" d="M129.6,914.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C123.6,911.7,126.4,913.5,129.6,914.6z"/>
				<path opacity="0.1091" fill="#333333" d="M148.7,911.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C141.3,906.7,144.7,909.2,148.7,911.1z"/>
				<path opacity="0.1091" fill="#333333" d="M180,948.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C169.6,945,174.4,947.6,180,948.4z"/>
				<path opacity="0.1091" fill="#333333" d="M120.6,940.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C113.4,936.6,116.7,939.1,120.6,940.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M219,1017.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C208.4,1014.2,213.3,1017,219,1017.8z"/>
				<path opacity="0.1091" fill="#333333" d="M190.9,995.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C183.7,995.3,187,996.5,190.9,995.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M189.5,956.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C184,954,186.5,955.6,189.5,956.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M215.3,987.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C207,986.1,210.8,987.8,215.3,987.6z"/>
				<path opacity="0.1091" fill="#333333" d="M209.6,952.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C202.6,948.2,205.9,950.6,209.6,952.2z"/>
				<path opacity="0.1091" fill="#333333" d="M170.2,981.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C164.3,979,167,980.8,170.2,981.9z"/>
				<path opacity="0.1091" fill="#333333" d="M199.6,1019.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C190.5,1017.5,194.6,1019.6,199.6,1019.8z"/>
				<path opacity="0.1091" fill="#333333" d="M231.6,983.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C222.4,981.6,226.6,983.7,231.6,983.9z"/>
				<path opacity="0.1091" fill="#333333" d="M179.6,968.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C173.6,965.7,176.4,967.5,179.6,968.6z"/>
				<path opacity="0.1091" fill="#333333" d="M198.7,965.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C191.3,960.7,194.7,963.2,198.7,965.1z"/>
				<path opacity="0.1091" fill="#333333" d="M230,1002.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C219.6,999,224.4,1001.6,230,1002.4z"/>
				<path opacity="0.1091" fill="#333333" d="M170.6,994.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C163.4,990.6,166.7,993.1,170.6,994.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M263,1017.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C252.4,1014.2,257.3,1017,263,1017.8z"/>
				<path opacity="0.1091" fill="#333333" d="M234.9,995.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C227.7,995.3,231,996.5,234.9,995.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M233.5,956.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C228,954,230.5,955.6,233.5,956.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M259.3,987.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C251,986.1,254.8,987.8,259.3,987.6z"/>
				<path opacity="0.1091" fill="#333333" d="M253.6,952.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C246.6,948.2,249.9,950.6,253.6,952.2z"/>
				<path opacity="0.1091" fill="#333333" d="M214.2,981.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C208.3,979,211,980.8,214.2,981.9z"/>
				<path opacity="0.1091" fill="#333333" d="M243.6,1019.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C234.5,1017.5,238.6,1019.6,243.6,1019.8z"/>
				<path opacity="0.1091" fill="#333333" d="M275.6,983.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C266.4,981.6,270.6,983.7,275.6,983.9z"/>
				<path opacity="0.1091" fill="#333333" d="M223.6,968.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C217.6,965.7,220.4,967.5,223.6,968.6z"/>
				<path opacity="0.1091" fill="#333333" d="M242.7,965.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C235.3,960.7,238.7,963.2,242.7,965.1z"/>
				<path opacity="0.1091" fill="#333333" d="M274,1002.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C263.6,999,268.4,1001.6,274,1002.4z"/>
				<path opacity="0.1091" fill="#333333" d="M214.6,994.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C207.4,990.6,210.7,993.1,214.6,994.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M291,1025.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C280.4,1022.2,285.3,1025,291,1025.8z"/>
				<path opacity="0.1091" fill="#333333" d="M262.9,1003.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2
					C255.7,1003.3,259,1004.5,262.9,1003.9z"/>
				<path opacity="0.1091" fill="#333333" d="M261.5,964.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C256,962,258.5,963.6,261.5,964.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M287.3,995.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C279,994.1,282.8,995.8,287.3,995.6z"/>
				<path opacity="0.1091" fill="#333333" d="M281.6,960.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C274.6,956.2,277.9,958.6,281.6,960.2z"/>
				<path opacity="0.1091" fill="#333333" d="M242.2,989.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C236.3,987,239,988.8,242.2,989.9z"/>
				<path opacity="0.1091" fill="#333333" d="M271.6,1027.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C262.5,1025.5,266.6,1027.6,271.6,1027.8z"/>
				<path opacity="0.1091" fill="#333333" d="M303.6,991.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C294.4,989.6,298.6,991.7,303.6,991.9z"/>
				<path opacity="0.1091" fill="#333333" d="M251.6,976.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C245.6,973.7,248.4,975.5,251.6,976.6z"/>
				<path opacity="0.1091" fill="#333333" d="M270.7,973.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C263.3,968.7,266.7,971.2,270.7,973.1z"/>
				<path opacity="0.1091" fill="#333333" d="M302,1010.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C291.6,1007,296.4,1009.6,302,1010.4z"/>
				<path opacity="0.1091" fill="#333333" d="M242.6,1002.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C235.4,998.6,238.7,1001.1,242.6,1002.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M123,985.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C112.4,982.2,117.3,985,123,985.8z"/>
				<path opacity="0.1091" fill="#333333" d="M94.9,963.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C87.7,963.3,91,964.5,94.9,963.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M93.5,924.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C88,922,90.5,923.6,93.5,924.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M119.3,955.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C111,954.1,114.8,955.8,119.3,955.6z"/>
				<path opacity="0.1091" fill="#333333" d="M113.6,920.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C106.6,916.2,109.9,918.6,113.6,920.2z"/>
				<path opacity="0.1091" fill="#333333" d="M74.2,949.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C68.3,947,71,948.8,74.2,949.9z"/>
				<path opacity="0.1091" fill="#333333" d="M103.6,987.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C94.5,985.5,98.6,987.6,103.6,987.8z"/>
				<path opacity="0.1091" fill="#333333" d="M135.6,951.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C126.4,949.6,130.6,951.7,135.6,951.9z"/>
				<path opacity="0.1091" fill="#333333" d="M83.6,936.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C77.6,933.7,80.4,935.5,83.6,936.6z"/>
				<path opacity="0.1091" fill="#333333" d="M102.7,933.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C95.3,928.7,98.7,931.2,102.7,933.1z"/>
				<path opacity="0.1091" fill="#333333" d="M134,970.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C123.6,967,128.4,969.6,134,970.4z"/>
				<path opacity="0.1091" fill="#333333" d="M74.6,962.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C67.4,958.6,70.7,961.1,74.6,962.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M115,957.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C104.4,954.2,109.3,957,115,957.8z"/>
				<path opacity="0.1091" fill="#333333" d="M86.9,935.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C79.7,935.3,83,936.5,86.9,935.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M85.5,896.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C80,894,82.5,895.6,85.5,896.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M111.3,927.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C103,926.1,106.8,927.8,111.3,927.6z"/>
				<path opacity="0.1091" fill="#333333" d="M105.6,892.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C98.6,888.2,101.9,890.6,105.6,892.2z"/>
				<path opacity="0.1091" fill="#333333" d="M66.2,921.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C60.3,919,63,920.8,66.2,921.9z"/>
				<path opacity="0.1091" fill="#333333" d="M95.6,959.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C86.5,957.5,90.6,959.6,95.6,959.8z"/>
				<path opacity="0.1091" fill="#333333" d="M127.6,923.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C118.4,921.6,122.6,923.7,127.6,923.9z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,908.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C69.6,905.7,72.4,907.5,75.6,908.6z"/>
				<path opacity="0.1091" fill="#333333" d="M94.7,905.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C87.3,900.7,90.7,903.2,94.7,905.1z"/>
				<path opacity="0.1091" fill="#333333" d="M126,942.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C115.6,939,120.4,941.6,126,942.4z"/>
				<path opacity="0.1091" fill="#333333" d="M66.6,934.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C59.4,930.6,62.7,933.1,66.6,934.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M141,967.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C130.4,964.2,135.3,967,141,967.8z"/>
				<path opacity="0.1091" fill="#333333" d="M112.9,945.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C105.7,945.3,109,946.5,112.9,945.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M111.5,906.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C106,904,108.5,905.6,111.5,906.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M137.3,937.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C129,936.1,132.8,937.8,137.3,937.6z"/>
				<path opacity="0.1091" fill="#333333" d="M131.6,902.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C124.6,898.2,127.9,900.6,131.6,902.2z"/>
				<path opacity="0.1091" fill="#333333" d="M92.2,931.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C86.3,929,89,930.8,92.2,931.9z"/>
				<path opacity="0.1091" fill="#333333" d="M121.6,969.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C112.5,967.5,116.6,969.6,121.6,969.8z"/>
				<path opacity="0.1091" fill="#333333" d="M153.6,933.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C144.4,931.6,148.6,933.7,153.6,933.9z"/>
				<path opacity="0.1091" fill="#333333" d="M101.6,918.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C95.6,915.7,98.4,917.5,101.6,918.6z"/>
				<path opacity="0.1091" fill="#333333" d="M120.7,915.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C113.3,910.7,116.7,913.2,120.7,915.1z"/>
				<path opacity="0.1091" fill="#333333" d="M152,952.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C141.6,949,146.4,951.6,152,952.4z"/>
				<path opacity="0.1091" fill="#333333" d="M92.6,944.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C85.4,940.6,88.7,943.1,92.6,944.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M101,929.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C90.4,926.2,95.3,929,101,929.8z"/>
				<path opacity="0.1091" fill="#333333" d="M72.9,907.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C65.7,907.3,69,908.5,72.9,907.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M71.5,868.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C66,866,68.5,867.6,71.5,868.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M97.3,899.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C89,898.1,92.8,899.8,97.3,899.6z"/>
				<path opacity="0.1091" fill="#333333" d="M91.6,864.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C84.6,860.2,87.9,862.6,91.6,864.2z"/>
				<path opacity="0.1091" fill="#333333" d="M52.2,893.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C46.3,891,49,892.8,52.2,893.9z"/>
				<path opacity="0.1091" fill="#333333" d="M81.6,931.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C72.5,929.5,76.6,931.6,81.6,931.8z"/>
				<path opacity="0.1091" fill="#333333" d="M113.6,895.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C104.4,893.6,108.6,895.7,113.6,895.9z"/>
				<path opacity="0.1091" fill="#333333" d="M61.6,880.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C55.6,877.7,58.4,879.5,61.6,880.6z"/>
				<path opacity="0.1091" fill="#333333" d="M80.7,877.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C73.3,872.7,76.7,875.2,80.7,877.1z"/>
				<path opacity="0.1091" fill="#333333" d="M112,914.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C101.6,911,106.4,913.6,112,914.4z"/>
				<path opacity="0.1091" fill="#333333" d="M52.6,906.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C45.4,902.6,48.7,905.1,52.6,906.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M93,925.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C82.4,922.2,87.3,925,93,925.8z"/>
				<path opacity="0.1091" fill="#333333" d="M64.9,903.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C57.7,903.3,61,904.5,64.9,903.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M63.5,864.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C58,862,60.5,863.6,63.5,864.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M89.3,895.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C81,894.1,84.8,895.8,89.3,895.6z"/>
				<path opacity="0.1091" fill="#333333" d="M83.6,860.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C76.6,856.2,79.9,858.6,83.6,860.2z"/>
				<path opacity="0.1091" fill="#333333" d="M44.2,889.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C38.3,887,41,888.8,44.2,889.9z"/>
				<path opacity="0.1091" fill="#333333" d="M73.6,927.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C64.5,925.5,68.6,927.6,73.6,927.8z"/>
				<path opacity="0.1091" fill="#333333" d="M105.6,891.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C96.4,889.6,100.6,891.7,105.6,891.9z"/>
				<path opacity="0.1091" fill="#333333" d="M53.6,876.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C47.6,873.7,50.4,875.5,53.6,876.6z"/>
				<path opacity="0.1091" fill="#333333" d="M72.7,873.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C65.3,868.7,68.7,871.2,72.7,873.1z"/>
				<path opacity="0.1091" fill="#333333" d="M104,910.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C93.6,907,98.4,909.6,104,910.4z"/>
				<path opacity="0.1091" fill="#333333" d="M44.6,902.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C37.4,898.6,40.7,901.1,44.6,902.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M79,913.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C68.4,910.2,73.3,913,79,913.8z"/>
				<path opacity="0.1091" fill="#333333" d="M50.9,891.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C43.7,891.3,47,892.5,50.9,891.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M49.5,852.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C44,850,46.5,851.6,49.5,852.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M75.3,883.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C67,882.1,70.8,883.8,75.3,883.6z"/>
				<path opacity="0.1091" fill="#333333" d="M69.6,848.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C62.6,844.2,65.9,846.6,69.6,848.2z"/>
				<path opacity="0.1091" fill="#333333" d="M30.2,877.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C24.3,875,27,876.8,30.2,877.9z"/>
				<path opacity="0.1091" fill="#333333" d="M59.6,915.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C50.5,913.5,54.6,915.6,59.6,915.8z"/>
				<path opacity="0.1091" fill="#333333" d="M91.6,879.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C82.4,877.6,86.6,879.7,91.6,879.9z"/>
				<path opacity="0.1091" fill="#333333" d="M39.6,864.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C33.6,861.7,36.4,863.5,39.6,864.6z"/>
				<path opacity="0.1091" fill="#333333" d="M58.7,861.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C51.3,856.7,54.7,859.2,58.7,861.1z"/>
				<path opacity="0.1091" fill="#333333" d="M90,898.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C79.6,895,84.4,897.6,90,898.4z"/>
				<path opacity="0.1091" fill="#333333" d="M30.6,890.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C23.4,886.6,26.7,889.1,30.6,890.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M81,891.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C70.4,888.2,75.3,891,81,891.8z"/>
				<path opacity="0.1091" fill="#333333" d="M52.9,869.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C45.7,869.3,49,870.5,52.9,869.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M51.5,830.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C46,828,48.5,829.6,51.5,830.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M77.3,861.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3c-4.9-5.7-10.2-11-15.9-15.9
					c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3c4.9,5.7,10.2,11,15.9,15.9
					C69,860.1,72.8,861.8,77.3,861.6z"/>
				<path opacity="0.1091" fill="#333333" d="M71.6,826.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C64.6,822.2,67.9,824.6,71.6,826.2z"/>
				<path opacity="0.1091" fill="#333333" d="M32.2,855.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C26.3,853,29,854.8,32.2,855.9z"/>
				<path opacity="0.1091" fill="#333333" d="M61.6,893.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C52.5,891.5,56.6,893.6,61.6,893.8z"/>
				<path opacity="0.1091" fill="#333333" d="M93.6,857.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C84.4,855.6,88.6,857.7,93.6,857.9z"/>
				<path opacity="0.1091" fill="#333333" d="M41.6,842.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C35.6,839.7,38.4,841.5,41.6,842.6z"/>
				<path opacity="0.1091" fill="#333333" d="M60.7,839.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C53.3,834.7,56.7,837.2,60.7,839.1z"/>
				<path opacity="0.1091" fill="#333333" d="M92,876.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C81.6,873,86.4,875.6,92,876.4z"/>
				<path opacity="0.1091" fill="#333333" d="M32.6,868.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C25.4,864.6,28.7,867.1,32.6,868.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M99,847.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C88.4,844.2,93.3,847,99,847.8z"/>
				<path opacity="0.1091" fill="#333333" d="M70.9,825.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C63.7,825.3,67,826.5,70.9,825.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M69.5,786.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C64,784,66.5,785.6,69.5,786.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M95.3,817.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C87,816.1,90.8,817.8,95.3,817.6z"/>
				<path opacity="0.1091" fill="#333333" d="M89.6,782.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C82.6,778.2,85.9,780.6,89.6,782.2z"/>
				<path opacity="0.1091" fill="#333333" d="M50.2,811.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C44.3,809,47,810.8,50.2,811.9z"/>
				<path opacity="0.1091" fill="#333333" d="M79.6,849.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C70.5,847.5,74.6,849.6,79.6,849.8z"/>
				<path opacity="0.1091" fill="#333333" d="M111.6,813.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C102.4,811.6,106.6,813.7,111.6,813.9z"/>
				<path opacity="0.1091" fill="#333333" d="M59.6,798.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C53.6,795.7,56.4,797.5,59.6,798.6z"/>
				<path opacity="0.1091" fill="#333333" d="M78.7,795.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C71.3,790.7,74.7,793.2,78.7,795.1z"/>
				<path opacity="0.1091" fill="#333333" d="M110,832.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C99.6,829,104.4,831.6,110,832.4z"/>
				<path opacity="0.1091" fill="#333333" d="M50.6,824.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C43.4,820.6,46.7,823.1,50.6,824.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M83,851.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C72.4,848.2,77.3,851,83,851.8z"/>
				<path opacity="0.1091" fill="#333333" d="M54.9,829.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C47.7,829.3,51,830.5,54.9,829.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M53.5,790.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C48,788,50.5,789.6,53.5,790.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M79.3,821.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3c-4.9-5.7-10.2-11-15.9-15.9
					c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3c4.9,5.7,10.2,11,15.9,15.9
					C71,820.1,74.8,821.8,79.3,821.6z"/>
				<path opacity="0.1091" fill="#333333" d="M73.6,786.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C66.6,782.2,69.9,784.6,73.6,786.2z"/>
				<path opacity="0.1091" fill="#333333" d="M34.2,815.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C28.3,813,31,814.8,34.2,815.9z"/>
				<path opacity="0.1091" fill="#333333" d="M63.6,853.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C54.5,851.5,58.6,853.6,63.6,853.8z"/>
				<path opacity="0.1091" fill="#333333" d="M95.6,817.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C86.4,815.6,90.6,817.7,95.6,817.9z"/>
				<path opacity="0.1091" fill="#333333" d="M43.6,802.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C37.6,799.7,40.4,801.5,43.6,802.6z"/>
				<path opacity="0.1091" fill="#333333" d="M62.7,799.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C55.3,794.7,58.7,797.2,62.7,799.1z"/>
				<path opacity="0.1091" fill="#333333" d="M94,836.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C83.6,833,88.4,835.6,94,836.4z"/>
				<path opacity="0.1091" fill="#333333" d="M34.6,828.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C27.4,824.6,30.7,827.1,34.6,828.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85,829.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C74.4,826.2,79.3,829,85,829.8z"/>
				<path opacity="0.1091" fill="#333333" d="M56.9,807.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C49.7,807.3,53,808.5,56.9,807.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M55.5,768.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C50,766,52.5,767.6,55.5,768.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M81.3,799.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C73,798.1,76.8,799.8,81.3,799.6z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,764.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C68.6,760.2,71.9,762.6,75.6,764.2z"/>
				<path opacity="0.1091" fill="#333333" d="M36.2,793.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C30.3,791,33,792.8,36.2,793.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,831.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C56.5,829.5,60.6,831.6,65.6,831.8z"/>
				<path opacity="0.1091" fill="#333333" d="M97.6,795.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C88.4,793.6,92.6,795.7,97.6,795.9z"/>
				<path opacity="0.1091" fill="#333333" d="M45.6,780.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C39.6,777.7,42.4,779.5,45.6,780.6z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,777.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C57.3,772.7,60.7,775.2,64.7,777.1z"/>
				<path opacity="0.1091" fill="#333333" d="M96,814.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2c-6.7-7.6-13.8-14.7-21.3-21.3
					c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2c6.7,7.6,13.8,14.7,21.3,21.3
					C85.6,811,90.4,813.6,96,814.4z"/>
				<path opacity="0.1091" fill="#333333" d="M36.6,806.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C29.4,802.6,32.7,805.1,36.6,806.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85,825.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C74.4,822.2,79.3,825,85,825.8z"/>
				<path opacity="0.1091" fill="#333333" d="M56.9,803.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C49.7,803.3,53,804.5,56.9,803.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M55.5,764.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C50,762,52.5,763.6,55.5,764.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M81.3,795.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C73,794.1,76.8,795.8,81.3,795.6z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,760.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C68.6,756.2,71.9,758.6,75.6,760.2z"/>
				<path opacity="0.1091" fill="#333333" d="M36.2,789.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C30.3,787,33,788.8,36.2,789.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,827.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C56.5,825.5,60.6,827.6,65.6,827.8z"/>
				<path opacity="0.1091" fill="#333333" d="M97.6,791.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C88.4,789.6,92.6,791.7,97.6,791.9z"/>
				<path opacity="0.1091" fill="#333333" d="M45.6,776.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C39.6,773.7,42.4,775.5,45.6,776.6z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,773.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C57.3,768.7,60.7,771.2,64.7,773.1z"/>
				<path opacity="0.1091" fill="#333333" d="M96,810.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C85.6,807,90.4,809.6,96,810.4z"/>
				<path opacity="0.1091" fill="#333333" d="M36.6,802.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C29.4,798.6,32.7,801.1,36.6,802.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85,809.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C74.4,806.2,79.3,809,85,809.8z"/>
				<path opacity="0.1091" fill="#333333" d="M56.9,787.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C49.7,787.3,53,788.5,56.9,787.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M55.5,748.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C50,746,52.5,747.6,55.5,748.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M81.3,779.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C73,778.1,76.8,779.8,81.3,779.6z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,744.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C68.6,740.2,71.9,742.6,75.6,744.2z"/>
				<path opacity="0.1091" fill="#333333" d="M36.2,773.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C30.3,771,33,772.8,36.2,773.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,811.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C56.5,809.5,60.6,811.6,65.6,811.8z"/>
				<path opacity="0.1091" fill="#333333" d="M97.6,775.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C88.4,773.6,92.6,775.7,97.6,775.9z"/>
				<path opacity="0.1091" fill="#333333" d="M45.6,760.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C39.6,757.7,42.4,759.5,45.6,760.6z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,757.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C57.3,752.7,60.7,755.2,64.7,757.1z"/>
				<path opacity="0.1091" fill="#333333" d="M96,794.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C85.6,791,90.4,793.6,96,794.4z"/>
				<path opacity="0.1091" fill="#333333" d="M36.6,786.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C29.4,782.6,32.7,785.1,36.6,786.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85,791.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C74.4,788.2,79.3,791,85,791.8z"/>
				<path opacity="0.1091" fill="#333333" d="M56.9,769.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C49.7,769.3,53,770.5,56.9,769.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M55.5,730.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C50,728,52.5,729.6,55.5,730.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M81.3,761.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C73,760.1,76.8,761.8,81.3,761.6z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,726.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C68.6,722.2,71.9,724.6,75.6,726.2z"/>
				<path opacity="0.1091" fill="#333333" d="M36.2,755.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C30.3,753,33,754.8,36.2,755.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,793.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C56.5,791.5,60.6,793.6,65.6,793.8z"/>
				<path opacity="0.1091" fill="#333333" d="M97.6,757.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C88.4,755.6,92.6,757.7,97.6,757.9z"/>
				<path opacity="0.1091" fill="#333333" d="M45.6,742.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C39.6,739.7,42.4,741.5,45.6,742.6z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,739.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C57.3,734.7,60.7,737.2,64.7,739.1z"/>
				<path opacity="0.1091" fill="#333333" d="M96,776.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C85.6,773,90.4,775.6,96,776.4z"/>
				<path opacity="0.1091" fill="#333333" d="M36.6,768.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C29.4,764.6,32.7,767.1,36.6,768.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M85,767.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C74.4,764.2,79.3,767,85,767.8z"/>
				<path opacity="0.1091" fill="#333333" d="M56.9,745.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C49.7,745.3,53,746.5,56.9,745.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M55.5,706.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C50,704,52.5,705.6,55.5,706.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M81.3,737.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C73,736.1,76.8,737.8,81.3,737.6z"/>
				<path opacity="0.1091" fill="#333333" d="M75.6,702.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C68.6,698.2,71.9,700.6,75.6,702.2z"/>
				<path opacity="0.1091" fill="#333333" d="M36.2,731.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C30.3,729,33,730.8,36.2,731.9z"/>
				<path opacity="0.1091" fill="#333333" d="M65.6,769.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C56.5,767.5,60.6,769.6,65.6,769.8z"/>
				<path opacity="0.1091" fill="#333333" d="M97.6,733.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C88.4,731.6,92.6,733.7,97.6,733.9z"/>
				<path opacity="0.1091" fill="#333333" d="M45.6,718.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C39.6,715.7,42.4,717.5,45.6,718.6z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,715.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C57.3,710.7,60.7,713.2,64.7,715.1z"/>
				<path opacity="0.1091" fill="#333333" d="M96,752.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C85.6,749,90.4,751.6,96,752.4z"/>
				<path opacity="0.1091" fill="#333333" d="M36.6,744.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C29.4,740.6,32.7,743.1,36.6,744.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M83,729.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C72.4,726.2,77.3,729,83,729.8z"/>
				<path opacity="0.1091" fill="#333333" d="M54.9,707.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C47.7,707.3,51,708.5,54.9,707.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M53.5,668.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C48,666,50.5,667.6,53.5,668.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M79.3,699.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3c-4.9-5.7-10.2-11-15.9-15.9
					c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3c4.9,5.7,10.2,11,15.9,15.9
					C71,698.1,74.8,699.8,79.3,699.6z"/>
				<path opacity="0.1091" fill="#333333" d="M73.6,664.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C66.6,660.2,69.9,662.6,73.6,664.2z"/>
				<path opacity="0.1091" fill="#333333" d="M34.2,693.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C28.3,691,31,692.8,34.2,693.9z"/>
				<path opacity="0.1091" fill="#333333" d="M63.6,731.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C54.5,729.5,58.6,731.6,63.6,731.8z"/>
				<path opacity="0.1091" fill="#333333" d="M95.6,695.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C86.4,693.6,90.6,695.7,95.6,695.9z"/>
				<path opacity="0.1091" fill="#333333" d="M43.6,680.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C37.6,677.7,40.4,679.5,43.6,680.6z"/>
				<path opacity="0.1091" fill="#333333" d="M62.7,677.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C55.3,672.7,58.7,675.2,62.7,677.1z"/>
				<path opacity="0.1091" fill="#333333" d="M94,714.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C83.6,711,88.4,713.6,94,714.4z"/>
				<path opacity="0.1091" fill="#333333" d="M34.6,706.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C27.4,702.6,30.7,705.1,34.6,706.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M83,695.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C72.4,692.2,77.3,695,83,695.8z"/>
				<path opacity="0.1091" fill="#333333" d="M54.9,673.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C47.7,673.3,51,674.5,54.9,673.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M53.5,634.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C48,632,50.5,633.6,53.5,634.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M79.3,665.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C71,664.1,74.8,665.8,79.3,665.6z"/>
				<path opacity="0.1091" fill="#333333" d="M73.6,630.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C66.6,626.2,69.9,628.6,73.6,630.2z"/>
				<path opacity="0.1091" fill="#333333" d="M34.2,659.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C28.3,657,31,658.8,34.2,659.9z"/>
				<path opacity="0.1091" fill="#333333" d="M63.6,697.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C54.5,695.5,58.6,697.6,63.6,697.8z"/>
				<path opacity="0.1091" fill="#333333" d="M95.6,661.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C86.4,659.6,90.6,661.7,95.6,661.9z"/>
				<path opacity="0.1091" fill="#333333" d="M43.6,646.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C37.6,643.7,40.4,645.5,43.6,646.6z"/>
				<path opacity="0.1091" fill="#333333" d="M62.7,643.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C55.3,638.7,58.7,641.2,62.7,643.1z"/>
				<path opacity="0.1091" fill="#333333" d="M94,680.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C83.6,677,88.4,679.6,94,680.4z"/>
				<path opacity="0.1091" fill="#333333" d="M34.6,672.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C27.4,668.6,30.7,671.1,34.6,672.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M77,645.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C66.4,642.2,71.3,645,77,645.8z"/>
				<path opacity="0.1091" fill="#333333" d="M48.9,623.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C41.7,623.3,45,624.5,48.9,623.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M47.5,584.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C42,582,44.5,583.6,47.5,584.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M73.3,615.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C65,614.1,68.8,615.8,73.3,615.6z"/>
				<path opacity="0.1091" fill="#333333" d="M67.6,580.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C60.6,576.2,63.9,578.6,67.6,580.2z"/>
				<path opacity="0.1091" fill="#333333" d="M28.2,609.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C22.3,607,25,608.8,28.2,609.9z"/>
				<path opacity="0.1091" fill="#333333" d="M57.6,647.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C48.5,645.5,52.6,647.6,57.6,647.8z"/>
				<path opacity="0.1091" fill="#333333" d="M89.6,611.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C80.4,609.6,84.6,611.7,89.6,611.9z"/>
				<path opacity="0.1091" fill="#333333" d="M37.6,596.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C31.6,593.7,34.4,595.5,37.6,596.6z"/>
				<path opacity="0.1091" fill="#333333" d="M56.7,593.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C49.3,588.7,52.7,591.2,56.7,593.1z"/>
				<path opacity="0.1091" fill="#333333" d="M88,630.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C77.6,627,82.4,629.6,88,630.4z"/>
				<path opacity="0.1091" fill="#333333" d="M28.6,622.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C21.4,618.6,24.7,621.1,28.6,622.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M109.8,939.8c2.6,2.5,5.2,4.9,7.8,7.4c2.8,2.3,5.6,2.3,8.4-0.1
					c3.5-4.8,4.9-10.3,4.2-16.4c0.5-10.5,0.4-21-0.3-31.5c0.5-6.1-1-11.6-4.5-16.3c-2.4-2.1-4.8-4.3-7.2-6.4c-2.8-2.3-5.6-2.3-8.4,0
					c-3.5,4.7-4.9,10.1-4.4,16.1c-0.6,10.3-0.6,20.7,0,31C104.9,929.8,106.3,935.1,109.8,939.8z"/>
				<path opacity="0.1091" fill="#333333" d="M84.2,917c2.6,2.6,5.1,5.2,7.7,7.7c2.8,1.7,5.6,1.7,8.4-0.1c3.3-3.3,4.7-7.3,4.3-12.2
					c0.6-7.8,0.5-15.6-0.2-23.3c0.4-4.9-1.1-8.9-4.5-12.1c-2.4-2.4-4.8-4.7-7.2-7.1c-2.8-1.7-5.6-1.7-8.4,0c-3.3,3.2-4.8,7.2-4.4,12
					c-0.6,7.7-0.6,15.4,0,23.1C79.4,909.8,80.9,913.8,84.2,917z"/>
				<path opacity="0.1091" fill="#333333" d="M84.6,874c2.7,4,5.3,8,8,11.9c2,1.8,4.4,2.6,7.2,2.4c2.7,0.2,5.1-0.6,7.2-2.4
					c1.5-2.8,2.1-5.8,1.9-9.1c0.2-3.2-0.4-6.2-1.9-9.1c-2.7-3.6-5.3-7.2-8-10.8c-2.1-1.7-4.5-2.4-7.2-2.3c-2.7-0.2-5.1,0.6-7.2,2.2
					c-1.5,2.6-2.1,5.5-1.9,8.5C82.5,868.5,83.1,871.3,84.6,874z"/>
				<path opacity="0.1091" fill="#333333" d="M108.4,908.8c2.6,2.5,5.1,4.9,7.7,7.4c2.8,1.5,5.6,1.5,8.4-0.1
					c3.2-2.7,4.6-6.2,4.2-10.5c0.6-6.7,0.5-13.5-0.2-20.2c0.3-4.4-1.2-7.9-4.5-10.4c-2.4-2.3-4.8-4.7-7.2-7c-2.8-1.5-5.6-1.5-8.4,0
					c-3.2,2.6-4.7,6.1-4.4,10.4c-0.6,6.7-0.6,13.4,0,20.1C103.7,902.7,105.1,906.2,108.4,908.8z"/>
				<path opacity="0.1091" fill="#333333" d="M106.3,870.7c2.7,4,5.3,8,8,12.1c2.1,1.7,4.5,2.4,7.2,2.2c2.7,0.2,5.1-0.6,7.2-2.3
					c1.5-2.6,2.1-5.4,1.9-8.5c0.2-3-0.4-5.9-1.9-8.5c-2.7-3.6-5.3-7.2-8-10.8c-2.1-1.6-4.5-2.3-7.2-2.1c-2.7-0.2-5,0.5-7.2,2.1
					c-1.5,2.4-2.1,5-1.9,7.9C104.2,865.7,104.9,868.3,106.3,870.7z"/>
				<path opacity="0.1091" fill="#333333" d="M64.7,899.7c2.5,3.1,5.1,6.2,7.6,9.3c1.6,2.7,4,4,7.2,3.9c3.1,0,5.5-1.3,7.1-4
					c1.5-4.9,2-9.8,1.8-14.9c0.2-5.1-0.5-10.1-2-14.9c-2.4-2.9-4.9-5.7-7.3-8.6c-1.6-2.6-4-3.9-7.1-3.9c-3.1-0.1-5.5,1.2-7.2,3.9
					c-1.5,4.8-2.2,9.6-1.9,14.6C62.6,890,63.2,894.9,64.7,899.7z"/>
				<path opacity="0.1091" fill="#333333" d="M91.7,944.3c2.6,2.5,5.2,4.9,7.8,7.4c2.8,2.4,5.6,2.4,8.4-0.1
					c3.5-4.9,4.9-10.5,4.2-16.8c0.5-10.7,0.4-21.5-0.3-32.2c0.5-6.2-1-11.8-4.5-16.7c-2.4-2.1-4.8-4.3-7.2-6.4
					c-2.8-2.4-5.6-2.4-8.4,0c-3.5,4.8-5,10.3-4.4,16.5c-0.6,10.6-0.6,21.2,0,31.7C86.7,934,88.2,939.5,91.7,944.3z"/>
				<path opacity="0.1091" fill="#333333" d="M124.3,904.2c2.6,2.7,5.1,5.4,7.7,8.1c2.8,1.4,5.6,1.3,8.4-0.1
					c2.4-1.6,3.6-3.7,3.6-6.5c1-4.2,1.4-8.4,1-12.7c0.2-5.5-0.5-10.8-2.1-16c-3.4-3.6-6.8-7.2-10.2-10.8c-2.8-1.4-5.6-1.4-8.4,0
					c-3.2,2.3-4.6,5.6-4.4,9.7c-0.6,6.2-0.6,12.5,0,18.7C119.6,898.7,121.1,901.9,124.3,904.2z"/>
				<path opacity="0.1091" fill="#333333" d="M74.5,885.5c2.6,4,5.2,8.1,7.7,12.1c3,4.7,11.3,4.2,14.3-0.1c1.5-4,2.1-8.1,1.8-12.4
					c0.2-4.3-0.5-8.4-2-12.4c-2.5-3.7-5-7.3-7.5-11c-3-4.5-11.2-4.2-14.3,0c-1.5,3.8-2.1,7.8-1.9,11.9
					C72.4,877.7,73.1,881.7,74.5,885.5z"/>
				<path opacity="0.1091" fill="#333333" d="M95.3,883c2.6,4.3,5.2,8.6,7.8,12.9c1.9,2.2,4.2,3.3,7.2,3.1c2.9,0.1,5.3-1,7.1-3.2
					c1.5-3.8,2.1-7.8,1.8-11.9c0.2-4.1-0.5-8.1-2-11.9c-2.5-3.9-5-7.7-7.6-11.6c-2.9-4.4-11.3-3.9-14.3,0c-1.5,3.6-2.1,7.4-1.9,11.4
					C93.1,875.6,93.8,879.4,95.3,883z"/>
				<path opacity="0.1091" fill="#333333" d="M121.4,921.9c2.6,2.5,5.2,5.1,7.7,7.6c2.8,1.9,5.6,1.8,8.4-0.1c3.3-3.6,4.7-8,4.2-13.1
					c0.5-8.4,0.4-16.7-0.3-25.1c0.4-5.1-1.1-9.5-4.5-13c-2.4-2.3-4.8-4.6-7.2-6.9c-2.8-1.9-5.6-1.9-8.4,0c-3.4,3.5-4.8,7.8-4.4,12.9
					c-0.6,8.3-0.6,16.5,0,24.8C116.5,914.1,118,918.4,121.4,921.9z"/>
				<path opacity="0.1091" fill="#333333" d="M69.8,918.7c2.6,2.5,5.1,5,7.7,7.5c2.8,1.7,5.6,1.7,8.4-0.1c3.3-3.1,4.7-7.1,4.3-11.8
					c0.6-7.6,0.5-15.1-0.2-22.7c0.4-4.8-1.1-8.7-4.5-11.7c-2.4-2.3-4.8-4.6-7.2-7c-2.8-1.7-5.6-1.7-8.4,0c-3.3,3.1-4.8,6.9-4.4,11.7
					c-0.6,7.5-0.7,15,0,22.5C65.1,911.7,66.5,915.6,69.8,918.7z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M133,913.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C122.4,910.2,127.3,913,133,913.8z"/>
				<path opacity="0.1091" fill="#333333" d="M104.9,891.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C97.7,891.3,101,892.5,104.9,891.9z
					"/>
				<path opacity="0.1091" fill="#333333" d="M103.5,852.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C98,850,100.5,851.6,103.5,852.5z
					"/>
				<path opacity="0.1091" fill="#333333" d="M129.3,883.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C121,882.1,124.8,883.8,129.3,883.6z"/>
				<path opacity="0.1091" fill="#333333" d="M123.6,848.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C116.6,844.2,119.9,846.6,123.6,848.2z"/>
				<path opacity="0.1091" fill="#333333" d="M84.2,877.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C78.3,875,81,876.8,84.2,877.9z"/>
				<path opacity="0.1091" fill="#333333" d="M113.6,915.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C104.5,913.5,108.6,915.6,113.6,915.8z"/>
				<path opacity="0.1091" fill="#333333" d="M145.6,879.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C136.4,877.6,140.6,879.7,145.6,879.9z"/>
				<path opacity="0.1091" fill="#333333" d="M93.6,864.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C87.6,861.7,90.4,863.5,93.6,864.6z"/>
				<path opacity="0.1091" fill="#333333" d="M112.7,861.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C105.3,856.7,108.7,859.2,112.7,861.1z"/>
				<path opacity="0.1091" fill="#333333" d="M144,898.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C133.6,895,138.4,897.6,144,898.4z"/>
				<path opacity="0.1091" fill="#333333" d="M84.6,890.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C77.4,886.6,80.7,889.1,84.6,890.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M145.3,904c2.5,0.5,5,1.1,7.4,1.6c3.6,0.6,6.1-0.8,7.2-4.3c0.5-6-1.1-11.6-4.8-16.6
					c-4.9-9.6-10.4-18.8-16.4-27.7c-2.7-5.7-6.8-9.7-12.3-12.1c-2.1-0.4-4.2-0.7-6.4-1.1c-3.6-0.7-6.1,0.7-7.3,4.2
					c-0.6,5.9,0.9,11.4,4.5,16.5c4.8,9.5,10.1,18.6,15.9,27.5C135.8,897.6,139.8,901.6,145.3,904z"/>
				<path opacity="0.1091" fill="#333333" d="M117.8,881.5c2.5,0.6,5,1.3,7.5,1.9c3.2-0.1,5.6-1.6,7.2-4.3c1.5-3.9,0.9-7.6-1.6-11.2
					c-2.9-6.1-6.3-11.8-10.3-17.3c-1.9-3.9-5-6.2-9.1-6.7c-2.3-0.6-4.6-1.2-6.8-1.8c-3.2,0.1-5.6,1.5-7.3,4.2
					c-1.5,3.8-1,7.6,1.4,11.2c2.8,6.1,6.1,11.8,10,17.3C110.7,878.6,113.7,880.9,117.8,881.5z"/>
				<path opacity="0.1091" fill="#333333" d="M116.2,840c3.4,2.1,6.9,4.1,10.3,6.2c2.6,0.4,5.1-0.1,7.3-1.6c2.4-1.2,4.1-3.1,5.1-5.6
					c-0.1-3-1-5.8-2.7-8.4c-1.4-2.7-3.3-4.9-5.9-6.5c-3.3-1.7-6.5-3.5-9.8-5.2c-2.6-0.3-5,0.3-7.3,1.8c-2.4,1.2-4.1,3-5.1,5.4
					c0,2.9,0.8,5.5,2.4,7.9C111.8,836.6,113.7,838.6,116.2,840z"/>
				<path opacity="0.1091" fill="#333333" d="M141.9,872.9c2.5,0.6,5,1.3,7.5,1.9c3.2,0,5.7-1.5,7.2-4.3c1.3-4.2,0.6-8.2-2-12
					c-3.2-6.6-6.9-12.8-11.2-18.7c-2-4.2-5.2-6.7-9.6-7.5c-2.3-0.6-4.5-1.2-6.8-1.7c-3.2,0-5.7,1.4-7.3,4.2
					c-1.4,4.2-0.8,8.1,1.9,11.9c3.1,6.5,6.7,12.8,10.8,18.7C134.5,869.5,137.6,872,141.9,872.9z"/>
				<path opacity="0.1091" fill="#333333" d="M138.9,838.9c3.4,1.9,6.7,3.8,10.1,5.7c2.7,0.8,5.2,0.4,7.6-1.2c2.5-1.3,4.1-3.3,4.8-6
					c-0.4-3.6-1.6-7-3.7-10.1c-1.7-3.3-4-6-6.9-8.2c-3.3-1.7-6.5-3.5-9.8-5.2c-2.7-0.7-5.2-0.3-7.6,1.3c-2.5,1.3-4.1,3.3-4.8,6
					c0.4,3.5,1.5,6.8,3.5,9.8C133.7,834.2,136,836.9,138.9,838.9z"/>
				<path opacity="0.1091" fill="#333333" d="M96,864.3c3.6,2.3,7.2,4.7,10.8,7c2.7,0.9,5.3,0.5,7.7-1c2.6-1.3,4.1-3.3,4.7-6.1
					c-0.5-3.8-1.8-7.3-3.9-10.5c-1.7-3.4-4.1-6.3-7.2-8.6c-3.4-2-6.9-4.1-10.3-6.1c-2.7-0.8-5.2-0.4-7.6,1.2c-2.5,1.3-4.1,3.3-4.8,6
					c0.4,3.6,1.6,7,3.6,10.1C90.8,859.4,93.1,862.2,96,864.3z"/>
				<path opacity="0.1091" fill="#333333" d="M126.4,906.2c2.5,0.6,5,1.2,7.5,1.8c3.5,0.4,5.9-1,7.2-4.3c0.8-5.3-0.4-10.3-3.7-14.8
					c-4.2-8.4-9-16.5-14.4-24.3c-2.4-5.1-6.1-8.6-11.2-10.4c-2.2-0.5-4.4-0.9-6.6-1.4c-3.5-0.4-5.9,1-7.3,4.2
					c-0.9,5.3,0.2,10.2,3.5,14.7c4.1,8.4,8.7,16.4,13.9,24.1C117.7,900.9,121.4,904.4,126.4,906.2z"/>
				<path opacity="0.1091" fill="#333333" d="M157.8,868.8c2.5,0.7,5.1,1.4,7.6,2.1c3.3,0.1,5.7-1.4,7.2-4.3
					c1.2-4.5,0.4-8.7-2.5-12.7c-3.5-7-7.5-13.8-12-20.1c-2.1-4.4-5.5-7.1-10-8.2c-2.3-0.6-4.5-1.2-6.8-1.8c-3.3-0.1-5.7,1.3-7.3,4.2
					c-1.3,4.4-0.5,8.7,2.3,12.6c3.3,7,7.2,13.7,11.7,20.1C150,864.9,153.3,867.7,157.8,868.8z"/>
				<path opacity="0.1091" fill="#333333" d="M106.7,852.9c3.4,2,6.8,4,10.2,6c2.7,0.7,5.2,0.3,7.6-1.2c2.5-1.3,4.1-3.3,4.8-6
					c-0.4-3.6-1.5-6.9-3.5-9.9c-1.6-3.2-3.9-5.9-6.8-8c-3.3-1.8-6.6-3.6-9.8-5.3c-2.7-0.7-5.2-0.2-7.5,1.3c-2.5,1.3-4.1,3.2-4.9,5.9
					c0.3,3.5,1.4,6.7,3.3,9.6C101.6,848.4,103.8,850.9,106.7,852.9z"/>
				<path opacity="0.1091" fill="#333333" d="M125.6,847.9c3.7,2.6,7.4,5.3,11.2,7.9c2.7,1.1,5.3,0.8,7.8-0.8
					c2.6-1.4,4.1-3.5,4.5-6.4c-0.7-4.2-2.3-8-4.6-11.6c-2-3.8-4.6-7-7.9-9.7c-3.5-2.3-6.9-4.6-10.4-6.8c-2.7-1-5.3-0.7-7.7,0.9
					c-2.6,1.3-4.1,3.4-4.6,6.3c0.6,4,2,7.7,4.2,11.1C120,842.4,122.5,845.4,125.6,847.9z"/>
				<path opacity="0.1091" fill="#333333" d="M155.8,887.4c2.5,0.6,5,1.1,7.5,1.7c3.5,0.4,5.9-1,7.2-4.3c0.8-5.5-0.6-10.6-4-15.2
					c-4.4-8.7-9.4-17-14.9-25c-2.5-5.2-6.3-8.8-11.5-10.8c-2.2-0.4-4.3-0.9-6.5-1.3c-3.5-0.5-5.9,1-7.3,4.2
					c-0.8,5.4,0.4,10.5,3.7,15.1c4.3,8.6,9.1,16.9,14.4,24.9C146.9,881.9,150.7,885.5,155.8,887.4z"/>
				<path opacity="0.1091" fill="#333333" d="M99,880.3c2.9,1.4,5.8,2.8,8.7,4.2c5.5,2.7,12.1-1.1,12.3-7.3
					c-1.1-4.8-3.1-9.3-5.8-13.5c-2.3-4.4-5.4-8.3-9.1-11.6c-2.7-1.2-5.3-2.5-8-3.7c-2.7-1.4-5.4-1.3-8.1,0.3
					c-2.7,1.5-4.2,3.8-4.3,6.9c1,4.8,2.8,9.2,5.5,13.3C92.4,873.3,95.4,877.1,99,880.3z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M553,829.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C542.4,826.2,547.3,829,553,829.8z"/>
				<path opacity="0.1091" fill="#333333" d="M524.9,807.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C517.7,807.3,521,808.5,524.9,807.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M523.5,768.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C518,766,520.5,767.6,523.5,768.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M549.3,799.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C541,798.1,544.8,799.8,549.3,799.6z"/>
				<path opacity="0.1091" fill="#333333" d="M543.6,764.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C536.6,760.2,539.9,762.6,543.6,764.2z"/>
				<path opacity="0.1091" fill="#333333" d="M504.2,793.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C498.3,791,501,792.8,504.2,793.9z"/>
				<path opacity="0.1091" fill="#333333" d="M533.6,831.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C524.5,829.5,528.6,831.6,533.6,831.8z"/>
				<path opacity="0.1091" fill="#333333" d="M565.6,795.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C556.4,793.6,560.6,795.7,565.6,795.9z"/>
				<path opacity="0.1091" fill="#333333" d="M513.6,780.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C507.6,777.7,510.4,779.5,513.6,780.6z"/>
				<path opacity="0.1091" fill="#333333" d="M532.7,777.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C525.3,772.7,528.7,775.2,532.7,777.1z"/>
				<path opacity="0.1091" fill="#333333" d="M564,814.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C553.6,811,558.4,813.6,564,814.4z"/>
				<path opacity="0.1091" fill="#333333" d="M504.6,806.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C497.4,802.6,500.7,805.1,504.6,806.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M549,829.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C538.4,826.2,543.3,829,549,829.8z"/>
				<path opacity="0.1091" fill="#333333" d="M520.9,807.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C513.7,807.3,517,808.5,520.9,807.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M519.5,768.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C514,766,516.5,767.6,519.5,768.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M545.3,799.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C537,798.1,540.8,799.8,545.3,799.6z"/>
				<path opacity="0.1091" fill="#333333" d="M539.6,764.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C532.6,760.2,535.9,762.6,539.6,764.2z"/>
				<path opacity="0.1091" fill="#333333" d="M500.2,793.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C494.3,791,497,792.8,500.2,793.9z"/>
				<path opacity="0.1091" fill="#333333" d="M529.6,831.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C520.5,829.5,524.6,831.6,529.6,831.8z"/>
				<path opacity="0.1091" fill="#333333" d="M561.6,795.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C552.4,793.6,556.6,795.7,561.6,795.9z"/>
				<path opacity="0.1091" fill="#333333" d="M509.6,780.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C503.6,777.7,506.4,779.5,509.6,780.6z"/>
				<path opacity="0.1091" fill="#333333" d="M528.7,777.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C521.3,772.7,524.7,775.2,528.7,777.1z"/>
				<path opacity="0.1091" fill="#333333" d="M560,814.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C549.6,811,554.4,813.6,560,814.4z"/>
				<path opacity="0.1091" fill="#333333" d="M500.6,806.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C493.4,802.6,496.7,805.1,500.6,806.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M549,827.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C538.4,824.2,543.3,827,549,827.8z"/>
				<path opacity="0.1091" fill="#333333" d="M520.9,805.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C513.7,805.3,517,806.5,520.9,805.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M519.5,766.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C514,764,516.5,765.6,519.5,766.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M545.3,797.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C537,796.1,540.8,797.8,545.3,797.6z"/>
				<path opacity="0.1091" fill="#333333" d="M539.6,762.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C532.6,758.2,535.9,760.6,539.6,762.2z"/>
				<path opacity="0.1091" fill="#333333" d="M500.2,791.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C494.3,789,497,790.8,500.2,791.9z"/>
				<path opacity="0.1091" fill="#333333" d="M529.6,829.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C520.5,827.5,524.6,829.6,529.6,829.8z"/>
				<path opacity="0.1091" fill="#333333" d="M561.6,793.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C552.4,791.6,556.6,793.7,561.6,793.9z"/>
				<path opacity="0.1091" fill="#333333" d="M509.6,778.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C503.6,775.7,506.4,777.5,509.6,778.6z"/>
				<path opacity="0.1091" fill="#333333" d="M528.7,775.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C521.3,770.7,524.7,773.2,528.7,775.1z"/>
				<path opacity="0.1091" fill="#333333" d="M560,812.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C549.6,809,554.4,811.6,560,812.4z"/>
				<path opacity="0.1091" fill="#333333" d="M500.6,804.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C493.4,800.6,496.7,803.1,500.6,804.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M561,727.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C550.4,724.2,555.3,727,561,727.8z"/>
				<path opacity="0.1091" fill="#333333" d="M532.9,705.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C525.7,705.3,529,706.5,532.9,705.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M531.5,666.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C526,664,528.5,665.6,531.5,666.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M557.3,697.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C549,696.1,552.8,697.8,557.3,697.6z"/>
				<path opacity="0.1091" fill="#333333" d="M551.6,662.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C544.6,658.2,547.9,660.6,551.6,662.2z"/>
				<path opacity="0.1091" fill="#333333" d="M512.2,691.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C506.3,689,509,690.8,512.2,691.9z"/>
				<path opacity="0.1091" fill="#333333" d="M541.6,729.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C532.5,727.5,536.6,729.6,541.6,729.8z"/>
				<path opacity="0.1091" fill="#333333" d="M573.6,693.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C564.4,691.6,568.6,693.7,573.6,693.9z"/>
				<path opacity="0.1091" fill="#333333" d="M521.6,678.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C515.6,675.7,518.4,677.5,521.6,678.6z"/>
				<path opacity="0.1091" fill="#333333" d="M540.7,675.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C533.3,670.7,536.7,673.2,540.7,675.1z"/>
				<path opacity="0.1091" fill="#333333" d="M572,712.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C561.6,709,566.4,711.6,572,712.4z"/>
				<path opacity="0.1091" fill="#333333" d="M512.6,704.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C505.4,700.6,508.7,703.1,512.6,704.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M561,727.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C550.4,724.2,555.3,727,561,727.8z"/>
				<path opacity="0.1091" fill="#333333" d="M532.9,705.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C525.7,705.3,529,706.5,532.9,705.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M531.5,666.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C526,664,528.5,665.6,531.5,666.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M557.3,697.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C549,696.1,552.8,697.8,557.3,697.6z"/>
				<path opacity="0.1091" fill="#333333" d="M551.6,662.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C544.6,658.2,547.9,660.6,551.6,662.2z"/>
				<path opacity="0.1091" fill="#333333" d="M512.2,691.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C506.3,689,509,690.8,512.2,691.9z"/>
				<path opacity="0.1091" fill="#333333" d="M541.6,729.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C532.5,727.5,536.6,729.6,541.6,729.8z"/>
				<path opacity="0.1091" fill="#333333" d="M573.6,693.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C564.4,691.6,568.6,693.7,573.6,693.9z"/>
				<path opacity="0.1091" fill="#333333" d="M521.6,678.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C515.6,675.7,518.4,677.5,521.6,678.6z"/>
				<path opacity="0.1091" fill="#333333" d="M540.7,675.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C533.3,670.7,536.7,673.2,540.7,675.1z"/>
				<path opacity="0.1091" fill="#333333" d="M572,712.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C561.6,709,566.4,711.6,572,712.4z"/>
				<path opacity="0.1091" fill="#333333" d="M512.6,704.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C505.4,700.6,508.7,703.1,512.6,704.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M561,727.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C550.4,724.2,555.3,727,561,727.8z"/>
				<path opacity="0.1091" fill="#333333" d="M532.9,705.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C525.7,705.3,529,706.5,532.9,705.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M531.5,666.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C526,664,528.5,665.6,531.5,666.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M557.3,697.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C549,696.1,552.8,697.8,557.3,697.6z"/>
				<path opacity="0.1091" fill="#333333" d="M551.6,662.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C544.6,658.2,547.9,660.6,551.6,662.2z"/>
				<path opacity="0.1091" fill="#333333" d="M512.2,691.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C506.3,689,509,690.8,512.2,691.9z"/>
				<path opacity="0.1091" fill="#333333" d="M541.6,729.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C532.5,727.5,536.6,729.6,541.6,729.8z"/>
				<path opacity="0.1091" fill="#333333" d="M573.6,693.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C564.4,691.6,568.6,693.7,573.6,693.9z"/>
				<path opacity="0.1091" fill="#333333" d="M521.6,678.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C515.6,675.7,518.4,677.5,521.6,678.6z"/>
				<path opacity="0.1091" fill="#333333" d="M540.7,675.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C533.3,670.7,536.7,673.2,540.7,675.1z"/>
				<path opacity="0.1091" fill="#333333" d="M572,712.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C561.6,709,566.4,711.6,572,712.4z"/>
				<path opacity="0.1091" fill="#333333" d="M512.6,704.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C505.4,700.6,508.7,703.1,512.6,704.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M555,971.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C544.4,968.2,549.3,971,555,971.8z"/>
				<path opacity="0.1091" fill="#333333" d="M526.9,949.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C519.7,949.3,523,950.5,526.9,949.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M525.5,910.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C520,908,522.5,909.6,525.5,910.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M551.3,941.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C543,940.1,546.8,941.8,551.3,941.6z"/>
				<path opacity="0.1091" fill="#333333" d="M545.6,906.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C538.6,902.2,541.9,904.6,545.6,906.2z"/>
				<path opacity="0.1091" fill="#333333" d="M506.2,935.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C500.3,933,503,934.8,506.2,935.9z"/>
				<path opacity="0.1091" fill="#333333" d="M535.6,973.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C526.5,971.5,530.6,973.6,535.6,973.8z"/>
				<path opacity="0.1091" fill="#333333" d="M567.6,937.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C558.4,935.6,562.6,937.7,567.6,937.9z"/>
				<path opacity="0.1091" fill="#333333" d="M515.6,922.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C509.6,919.7,512.4,921.5,515.6,922.6z"/>
				<path opacity="0.1091" fill="#333333" d="M534.7,919.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C527.3,914.7,530.7,917.2,534.7,919.1z"/>
				<path opacity="0.1091" fill="#333333" d="M566,956.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C555.6,953,560.4,955.6,566,956.4z"/>
				<path opacity="0.1091" fill="#333333" d="M506.6,948.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C499.4,944.6,502.7,947.1,506.6,948.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M555,971.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C544.4,968.2,549.3,971,555,971.8z"/>
				<path opacity="0.1091" fill="#333333" d="M526.9,949.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C519.7,949.3,523,950.5,526.9,949.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M525.5,910.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C520,908,522.5,909.6,525.5,910.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M551.3,941.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C543,940.1,546.8,941.8,551.3,941.6z"/>
				<path opacity="0.1091" fill="#333333" d="M545.6,906.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C538.6,902.2,541.9,904.6,545.6,906.2z"/>
				<path opacity="0.1091" fill="#333333" d="M506.2,935.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C500.3,933,503,934.8,506.2,935.9z"/>
				<path opacity="0.1091" fill="#333333" d="M535.6,973.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C526.5,971.5,530.6,973.6,535.6,973.8z"/>
				<path opacity="0.1091" fill="#333333" d="M567.6,937.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C558.4,935.6,562.6,937.7,567.6,937.9z"/>
				<path opacity="0.1091" fill="#333333" d="M515.6,922.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C509.6,919.7,512.4,921.5,515.6,922.6z"/>
				<path opacity="0.1091" fill="#333333" d="M534.7,919.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C527.3,914.7,530.7,917.2,534.7,919.1z"/>
				<path opacity="0.1091" fill="#333333" d="M566,956.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C555.6,953,560.4,955.6,566,956.4z"/>
				<path opacity="0.1091" fill="#333333" d="M506.6,948.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C499.4,944.6,502.7,947.1,506.6,948.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M727,991.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C716.4,988.2,721.3,991,727,991.8z"/>
				<path opacity="0.1091" fill="#333333" d="M698.9,969.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C691.7,969.3,695,970.5,698.9,969.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M697.5,930.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C692,928,694.5,929.6,697.5,930.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M723.3,961.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C715,960.1,718.8,961.8,723.3,961.6z"/>
				<path opacity="0.1091" fill="#333333" d="M717.6,926.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C710.6,922.2,713.9,924.6,717.6,926.2z"/>
				<path opacity="0.1091" fill="#333333" d="M678.2,955.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C672.3,953,675,954.8,678.2,955.9z"/>
				<path opacity="0.1091" fill="#333333" d="M707.6,993.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C698.5,991.5,702.6,993.6,707.6,993.8z"/>
				<path opacity="0.1091" fill="#333333" d="M739.6,957.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C730.4,955.6,734.6,957.7,739.6,957.9z"/>
				<path opacity="0.1091" fill="#333333" d="M687.6,942.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C681.6,939.7,684.4,941.5,687.6,942.6z"/>
				<path opacity="0.1091" fill="#333333" d="M706.7,939.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C699.3,934.7,702.7,937.2,706.7,939.1z"/>
				<path opacity="0.1091" fill="#333333" d="M738,976.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C727.6,973,732.4,975.6,738,976.4z"/>
				<path opacity="0.1091" fill="#333333" d="M678.6,968.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C671.4,964.6,674.7,967.1,678.6,968.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M727,991.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C716.4,988.2,721.3,991,727,991.8z"/>
				<path opacity="0.1091" fill="#333333" d="M698.9,969.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C691.7,969.3,695,970.5,698.9,969.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M697.5,930.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C692,928,694.5,929.6,697.5,930.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M723.3,961.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C715,960.1,718.8,961.8,723.3,961.6z"/>
				<path opacity="0.1091" fill="#333333" d="M717.6,926.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C710.6,922.2,713.9,924.6,717.6,926.2z"/>
				<path opacity="0.1091" fill="#333333" d="M678.2,955.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C672.3,953,675,954.8,678.2,955.9z"/>
				<path opacity="0.1091" fill="#333333" d="M707.6,993.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C698.5,991.5,702.6,993.6,707.6,993.8z"/>
				<path opacity="0.1091" fill="#333333" d="M739.6,957.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C730.4,955.6,734.6,957.7,739.6,957.9z"/>
				<path opacity="0.1091" fill="#333333" d="M687.6,942.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C681.6,939.7,684.4,941.5,687.6,942.6z"/>
				<path opacity="0.1091" fill="#333333" d="M706.7,939.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C699.3,934.7,702.7,937.2,706.7,939.1z"/>
				<path opacity="0.1091" fill="#333333" d="M738,976.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C727.6,973,732.4,975.6,738,976.4z"/>
				<path opacity="0.1091" fill="#333333" d="M678.6,968.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C671.4,964.6,674.7,967.1,678.6,968.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M735,859.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C724.4,856.2,729.3,859,735,859.8z"/>
				<path opacity="0.1091" fill="#333333" d="M706.9,837.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C699.7,837.3,703,838.5,706.9,837.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M705.5,798.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C700,796,702.5,797.6,705.5,798.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M731.3,829.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C723,828.1,726.8,829.8,731.3,829.6z"/>
				<path opacity="0.1091" fill="#333333" d="M725.6,794.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C718.6,790.2,721.9,792.6,725.6,794.2z"/>
				<path opacity="0.1091" fill="#333333" d="M686.2,823.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C680.3,821,683,822.8,686.2,823.9z"/>
				<path opacity="0.1091" fill="#333333" d="M715.6,861.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C706.5,859.5,710.6,861.6,715.6,861.8z"/>
				<path opacity="0.1091" fill="#333333" d="M747.6,825.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C738.4,823.6,742.6,825.7,747.6,825.9z"/>
				<path opacity="0.1091" fill="#333333" d="M695.6,810.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C689.6,807.7,692.4,809.5,695.6,810.6z"/>
				<path opacity="0.1091" fill="#333333" d="M714.7,807.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C707.3,802.7,710.7,805.2,714.7,807.1z"/>
				<path opacity="0.1091" fill="#333333" d="M746,844.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C735.6,841,740.4,843.6,746,844.4z"/>
				<path opacity="0.1091" fill="#333333" d="M686.6,836.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C679.4,832.6,682.7,835.1,686.6,836.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M735,859.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C724.4,856.2,729.3,859,735,859.8z"/>
				<path opacity="0.1091" fill="#333333" d="M706.9,837.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C699.7,837.3,703,838.5,706.9,837.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M705.5,798.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C700,796,702.5,797.6,705.5,798.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M731.3,829.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C723,828.1,726.8,829.8,731.3,829.6z"/>
				<path opacity="0.1091" fill="#333333" d="M725.6,794.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C718.6,790.2,721.9,792.6,725.6,794.2z"/>
				<path opacity="0.1091" fill="#333333" d="M686.2,823.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C680.3,821,683,822.8,686.2,823.9z"/>
				<path opacity="0.1091" fill="#333333" d="M715.6,861.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C706.5,859.5,710.6,861.6,715.6,861.8z"/>
				<path opacity="0.1091" fill="#333333" d="M747.6,825.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C738.4,823.6,742.6,825.7,747.6,825.9z"/>
				<path opacity="0.1091" fill="#333333" d="M695.6,810.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C689.6,807.7,692.4,809.5,695.6,810.6z"/>
				<path opacity="0.1091" fill="#333333" d="M714.7,807.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C707.3,802.7,710.7,805.2,714.7,807.1z"/>
				<path opacity="0.1091" fill="#333333" d="M746,844.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C735.6,841,740.4,843.6,746,844.4z"/>
				<path opacity="0.1091" fill="#333333" d="M686.6,836.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C679.4,832.6,682.7,835.1,686.6,836.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M735,747.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C724.4,744.2,729.3,747,735,747.8z"/>
				<path opacity="0.1091" fill="#333333" d="M706.9,725.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C699.7,725.3,703,726.5,706.9,725.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M705.5,686.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C700,684,702.5,685.6,705.5,686.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M731.3,717.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C723,716.1,726.8,717.8,731.3,717.6z"/>
				<path opacity="0.1091" fill="#333333" d="M725.6,682.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C718.6,678.2,721.9,680.6,725.6,682.2z"/>
				<path opacity="0.1091" fill="#333333" d="M686.2,711.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C680.3,709,683,710.8,686.2,711.9z"/>
				<path opacity="0.1091" fill="#333333" d="M715.6,749.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C706.5,747.5,710.6,749.6,715.6,749.8z"/>
				<path opacity="0.1091" fill="#333333" d="M747.6,713.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C738.4,711.6,742.6,713.7,747.6,713.9z"/>
				<path opacity="0.1091" fill="#333333" d="M695.6,698.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C689.6,695.7,692.4,697.5,695.6,698.6z"/>
				<path opacity="0.1091" fill="#333333" d="M714.7,695.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C707.3,690.7,710.7,693.2,714.7,695.1z"/>
				<path opacity="0.1091" fill="#333333" d="M746,732.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C735.6,729,740.4,731.6,746,732.4z"/>
				<path opacity="0.1091" fill="#333333" d="M686.6,724.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C679.4,720.6,682.7,723.1,686.6,724.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M735,747.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C724.4,744.2,729.3,747,735,747.8z"/>
				<path opacity="0.1091" fill="#333333" d="M706.9,725.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C699.7,725.3,703,726.5,706.9,725.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M705.5,686.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C700,684,702.5,685.6,705.5,686.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M731.3,717.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C723,716.1,726.8,717.8,731.3,717.6z"/>
				<path opacity="0.1091" fill="#333333" d="M725.6,682.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C718.6,678.2,721.9,680.6,725.6,682.2z"/>
				<path opacity="0.1091" fill="#333333" d="M686.2,711.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C680.3,709,683,710.8,686.2,711.9z"/>
				<path opacity="0.1091" fill="#333333" d="M715.6,749.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C706.5,747.5,710.6,749.6,715.6,749.8z"/>
				<path opacity="0.1091" fill="#333333" d="M747.6,713.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C738.4,711.6,742.6,713.7,747.6,713.9z"/>
				<path opacity="0.1091" fill="#333333" d="M695.6,698.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C689.6,695.7,692.4,697.5,695.6,698.6z"/>
				<path opacity="0.1091" fill="#333333" d="M714.7,695.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C707.3,690.7,710.7,693.2,714.7,695.1z"/>
				<path opacity="0.1091" fill="#333333" d="M746,732.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C735.6,729,740.4,731.6,746,732.4z"/>
				<path opacity="0.1091" fill="#333333" d="M686.6,724.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C679.4,720.6,682.7,723.1,686.6,724.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M603.5,379.3c0.8-3.2,1.6-6.4,2.5-9.6c-2.7,5.4-5.5,10.9-8.2,16.3c4-4.7,8-9.4,12-14.1
					c2.5-1.8,3.5-4,3.1-6.7c0.3-1.5,0-2.7-1.1-3.8c-0.9-2.1-2.4-3.5-4.5-4.4c-1.3-0.1-2.6-0.3-3.9-0.4c-1.7,0.9-3.4,1.7-5,2.6
					c-3.3,2.2-6.2,4.7-8.9,7.6c-0.9,0.9-1.8,1.9-2.8,2.8c4.3-4.3,8.6-8.6,12.8-13c-3.8,2.1-7,4.8-9.7,8.2c-3,3.1-5.3,6.7-6.8,10.7
					c-0.8,3.7-1.5,7.4-2,11.2c0.6,2.8,2.2,4.8,4.7,6.1c1.8,1.4,3.8,1.8,5.8,1.2c2.3,0.1,4.1-1.1,5.3-3.4
					C599.8,387.1,601.9,383.4,603.5,379.3z"/>
				<path opacity="0.1091" fill="#333333" d="M577,361.6c1-3.5,1.9-6.9,2.9-10.4c-1.6,3.9-3.2,7.8-4.8,11.7
					c1.9-3.6,3.9-7.2,5.8-10.8c-2.8,4.1-5.7,8.1-8.5,12.2c2.3-2.2,4.5-4.4,6.8-6.6c1.7-1.8,3.4-3.5,5.2-5.3c1-1.5,1.9-3,2.9-4.5
					c0-1.3-0.1-2.6-0.1-3.9c-0.7-1.2-1.5-2.5-2.2-3.7c-1.1-0.9-2.3-1.7-3.4-2.6c-1.3-0.2-2.5-0.4-3.8-0.5c-2.9,1-5.5,2.6-7.7,4.7
					c-4,3.3-3.9,3,0.5-1c4-3.1,4.7-4,2-2.7c-3.3,1.3-5.7,4.2-7.9,6.9c-1.7,1.9-3.1,3.9-4.3,6.1c-1.4,1.8-2.4,3.8-3,6
					c-1.7,4.4-2.6,8.7-3.5,13.3c0.4,2.9,2,5,4.6,6.4c1.9,1.4,3.9,1.7,5.9,1.1c2.5-0.1,4.4-1.4,5.6-4
					C572.8,370.2,575.2,366,577,361.6z"/>
				<path opacity="0.1091" fill="#333333" d="M583.5,314.3c0-0.7,0-1.5,0-2.2c-5.7,10.4-11.4,20.9-17.1,31.3
					c5.4-8.3,10.9-16.5,16.3-24.8c-5.2,6.4-10.4,12.8-15.6,19.2c6-6.4,11.9-12.7,17.9-19.1c3.3-2.4,5.1-5.5,5.3-9.2
					c0.8-1.6,0.5-3.1-0.6-4.4c-0.7-2.3-2.3-3.6-4.9-4c-1.6,0.2-3.3,0.5-4.9,0.7c-2.3,1.6-4.6,3.2-6.9,4.8
					c-17.7,10.5-25.9,23.6-24.6,39.4c-0.1,2,0.2,3.9,0.8,5.8c1.5,3.1,3.9,4.5,7.3,4.2c3.5-0.9,6-3,7.7-6.1c3.9-4.6,7.2-9.5,9.9-14.9
					C577.9,328.3,581.1,321.5,583.5,314.3z"/>
				<path opacity="0.1091" fill="#333333" d="M591.1,370.5c4.7-9.7,9.5-19.4,14.2-29.1c-5.1,8.2-10.2,16.5-15.3,24.7
					c5.3-6,10.5-12,15.8-18c1.5-2.1,3.1-4.2,4.6-6.3c0.2-1.6,0.4-3.1,0.7-4.7c-0.4-2.5-1.7-4.2-3.9-5c-1.2-1.2-2.6-1.5-4.3-0.9
					c-2.2-0.2-4.1,0.6-5.6,2.3c-2.7,1.6-5.2,3.5-7.4,5.7c-9.2,7.1-14.6,17-16.2,29.7c0.3,1.5,0.5,3,0.8,4.6c1.1,2.3,2.9,3.5,5.2,3.7
					c1.5,0.8,2.9,0.7,4.3-0.3C587.3,376.2,589.7,374.1,591.1,370.5z"/>
				<path opacity="0.1091" fill="#333333" d="M606.5,303.6c0-0.7,0-1.4,0.1-2.1c-4.3,10.4-8.7,20.8-13,31.2c3-5.2,6-10.3,9-15.5
					c-6.1,9.1-12.1,18.1-18.2,27.2c6.7-9.3,13.3-18.6,20-27.8c-6.6,8.3-13.3,16.6-19.9,24.9c7.4-8.2,14.8-16.4,22.3-24.5
					c2.2-2.9,4.4-5.8,6.6-8.7c0.5-1.9,1.1-3.9,1.6-5.8c-0.1-2.9-1.4-4.6-3.9-5.1c-1.6-1.2-3.3-1.2-5,0c-2.9,0.4-5.3,1.8-7.2,4.2
					c-9.5,6.6-15.9,16-19.1,28.2c-14.1,8.5-18.7,17.7-13.6,27.4c1.2,3.5,3.6,4.9,7.3,4.2c5.5-2.6,9.6-6.8,12.2-12.6
					c6-9.1,11.4-18.6,16.2-28.4C605.5,315.3,607,309.7,606.5,303.6z"/>
				<path opacity="0.1091" fill="#333333" d="M561,346.6c1-3.4,1.9-6.9,2.9-10.3c-1.6,4-3.3,7.9-4.9,11.9c1.9-3.6,3.9-7.1,5.8-10.7
					c-2.8,4-5.6,8.1-8.4,12.1c2.1-2.3,4.1-4.6,6.2-6.9c-1.6,1.7-3.3,3.4-4.9,5.1c4.3-3,8.1-6.5,11.5-10.5c2.5-2.2,3.2-4.9,2.2-8
					c-1.1-2.8-3-4.8-5.7-6.2c-2.1-1.1-4.1-1-5.9,0.4c-1.5,1.2-3.2,1.4-5,0.7c-3.5,2.5-6.4,5.5-8.7,9.2c-2.7,3.7-4.8,7.7-6.1,12.1
					c-1.1,3.5-2,7-2.6,10.6c0.4,2.9,1.9,5.1,4.6,6.5c1.9,1.4,3.9,1.7,6,1c2.6-0.1,4.5-1.5,5.7-4.2
					C556.7,355.5,559.1,351.2,561,346.6z"/>
				<path opacity="0.1091" fill="#333333" d="M587.4,379.1c0.4-1.6,0.9-3.2,1.3-4.8c-0.7,1.5-1.4,3-2.1,4.4c1.5-2.8,2.9-5.6,4.4-8.4
					c-3.1,3.5-6.1,6.9-9.2,10.4c0.3,0,0.6,0,0.9,0c-1.1-0.2-2.3-0.4-3.4-0.5c1.5,0.4,3,0.7,4.5,1.1c3.2-1,6-2.7,8.3-5.1
					c2.6-2.1,4.5-4.8,5.7-7.9c0-2.7-1-5.1-3-7c-1.8-2.1-4-3.3-6.7-3.5c-2.7-0.3-5.3-0.2-7.8,0.6c-2,0.9-3.7,2.2-4.9,4
					c-1.8,1.8-3.2,4-4.2,6.4c-1.7,3.2-2.8,6.6-3.3,10.1c-1,2.5-0.6,4.6,1.3,6.5c2.1,2,4.5,3.4,7.3,4.2c2.5,0.8,4.6,0.1,6.2-2.1
					C584.8,384.9,586.3,382.2,587.4,379.1z"/>
				<path opacity="0.1091" fill="#333333" d="M619.7,341.5c0.5-1.3,1.1-2.6,1.6-3.9c-6.3,10.1-12.5,20.1-18.8,30.2
					c6.3-7.4,12.5-14.8,18.8-22.2c3.7-2.7,5.9-6.2,6.5-10.4c1-1.7,0.9-3.3-0.3-4.7c-0.6-2.4-2.2-3.7-5-4c-1.8,0.4-3.6,0.8-5.4,1.1
					c-2.6,1.9-5.2,3.8-7.9,5.7c-5.3,4.4-10.2,9.2-14.8,14.3c-1.6,1.7-3.2,3.3-4.7,5c9.4-8.7,18.7-17.4,28.1-26.1
					c-3.6,0.4-6.5,2-8.7,4.8c-4.6,3.9-8.7,8.3-12.4,13.2c-2.7,3.5-5.4,7.1-8.1,10.6c-3.8,3.8-5.6,8.3-5.4,13.5
					c0.7,2.9,1.7,5.7,3,8.4c1.5,3.1,3.9,4.5,7.3,4.2c3.4-0.8,5.9-2.7,7.5-5.8c3.8-4.4,7-9.1,9.6-14.3
					C614.5,355,617.5,348.4,619.7,341.5z"/>
				<path opacity="0.1091" fill="#333333" d="M573.3,326.7c0-0.7-0.1-1.5-0.1-2.2c-2.5,5.5-5.1,10.9-7.6,16.4
					c3.6-4.6,6.7-9.6,9.3-14.8c1.8-2.2,2.4-4.7,1.5-7.6c-0.8-1.2-1.7-2.4-2.5-3.5c-1.9-1.8-3.9-2.3-6.1-1.5c-2.1-0.1-3.7,0.8-5,2.5
					c-3.6,2.7-6.8,5.8-9.4,9.5c-2,2.6-4,5.3-5.9,7.9c-4.4,5.6-6,11.5-4.5,17.6c-0.3,3.4,0.9,6,3.8,7.7c3,1.6,5.8,1.4,8.6-0.6
					c4.3-4.4,7.8-9.3,10.6-14.8C569.2,338.2,571.6,332.6,573.3,326.7z"/>
				<path opacity="0.1091" fill="#333333" d="M593.7,325.2c0-0.8,0-1.5-0.1-2.3c-5.6,10.2-11.2,20.5-16.8,30.7
					c5.3-8.2,10.7-16.3,16-24.5c-5.3,6.5-10.6,13-15.9,19.5c8.9-7.9,16.6-16.7,23.3-26.4c1.6-3.3,1.3-6.3-1-9
					c-2.7-2.4-5.7-2.8-9-1.2c-17.1,10.4-27.2,26.9-30.4,49.5c1.5,3.1,3.9,4.5,7.3,4.2c3.4-0.9,6-2.8,7.6-5.9c3.8-4.5,7-9.3,9.7-14.6
					C588.3,338.9,591.4,332.2,593.7,325.2z"/>
				<path opacity="0.1091" fill="#333333" d="M605.2,381.5c6.2-9,12.3-18,18.5-27c0.2-1.6,0.5-3.1,0.7-4.7c-0.7-1.3-1.3-2.6-2-3.9
					c-1.3-0.7-2.5-1.5-3.8-2.2c-3.1-0.5-5.8,0.4-8,2.5c-5.7,3.7-10.7,8.4-15.4,13.3c-2.4,2.1-4.4,4.4-6.1,7.1
					c-2.3,2.1-3.3,4.7-3,7.8c1,3.2,2,6.3,3,9.5c1.7,2.7,4.1,4.1,7.3,4.2C600.4,387.5,603.4,385.3,605.2,381.5z"/>
				<path opacity="0.1091" fill="#333333" d="M565,358.2c0.4-1.5,0.8-3,1.2-4.6c-0.9,1.8-1.8,3.6-2.6,5.3c1.6-3,3.2-6,4.8-9
					c-3.4,4-6.9,8.1-10.3,12.1c2.5-1.3,4.9-2.7,7.4-4c3.2-2.1,5.9-4.7,8.3-7.7c1.8-1.6,2.3-3.5,1.6-5.5c-0.1-2.1-1-3.8-2.7-5.2
					c-1.8-2.1-4-3.3-6.7-3.5c-2.8-0.1-5.5,0.4-8.1,1.4c-2.2,1.2-4,2.7-5.4,4.8c-2,2.2-3.6,4.6-4.9,7.3c-1.7,3.2-2.7,6.7-3.2,10.3
					c-1.4,2.7-1.2,5.2,0.8,7.5c2,2.2,4.4,3.6,7.3,4.2c2.9,0.5,5.2-0.6,6.8-3.1C561.7,365.4,563.6,362,565,358.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M609,435.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C598.4,432.2,603.3,435,609,435.8z"/>
				<path opacity="0.1091" fill="#333333" d="M580.9,413.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C573.7,413.3,577,414.5,580.9,413.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M579.5,374.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C574,372,576.5,373.6,579.5,374.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M605.3,405.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C597,404.1,600.8,405.8,605.3,405.6z"/>
				<path opacity="0.1091" fill="#333333" d="M599.6,370.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C592.6,366.2,595.9,368.6,599.6,370.2z"/>
				<path opacity="0.1091" fill="#333333" d="M560.2,399.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C554.3,397,557,398.8,560.2,399.9z"/>
				<path opacity="0.1091" fill="#333333" d="M589.6,437.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C580.5,435.5,584.6,437.6,589.6,437.8z"/>
				<path opacity="0.1091" fill="#333333" d="M621.6,401.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C612.4,399.6,616.6,401.7,621.6,401.9z"/>
				<path opacity="0.1091" fill="#333333" d="M569.6,386.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C563.6,383.7,566.4,385.5,569.6,386.6z"/>
				<path opacity="0.1091" fill="#333333" d="M588.7,383.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C581.3,378.7,584.7,381.2,588.7,383.1z"/>
				<path opacity="0.1091" fill="#333333" d="M620,420.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C609.6,417,614.4,419.6,620,420.4z"/>
				<path opacity="0.1091" fill="#333333" d="M560.6,412.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C553.4,408.6,556.7,411.1,560.6,412.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M585,423.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C574.4,420.2,579.3,423,585,423.8z"/>
				<path opacity="0.1091" fill="#333333" d="M556.9,401.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C549.7,401.3,553,402.5,556.9,401.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M555.5,362.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C550,360,552.5,361.6,555.5,362.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M581.3,393.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C573,392.1,576.8,393.8,581.3,393.6z"/>
				<path opacity="0.1091" fill="#333333" d="M575.6,358.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C568.6,354.2,571.9,356.6,575.6,358.2z"/>
				<path opacity="0.1091" fill="#333333" d="M536.2,387.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C530.3,385,533,386.8,536.2,387.9z"/>
				<path opacity="0.1091" fill="#333333" d="M565.6,425.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C556.5,423.5,560.6,425.6,565.6,425.8z"/>
				<path opacity="0.1091" fill="#333333" d="M597.6,389.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C588.4,387.6,592.6,389.7,597.6,389.9z"/>
				<path opacity="0.1091" fill="#333333" d="M545.6,374.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C539.6,371.7,542.4,373.5,545.6,374.6z"/>
				<path opacity="0.1091" fill="#333333" d="M564.7,371.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C557.3,366.7,560.7,369.2,564.7,371.1z"/>
				<path opacity="0.1091" fill="#333333" d="M596,408.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C585.6,405,590.4,407.6,596,408.4z"/>
				<path opacity="0.1091" fill="#333333" d="M536.6,400.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C529.4,396.6,532.7,399.1,536.6,400.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M623,375.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C612.4,372.2,617.3,375,623,375.8z"/>
				<path opacity="0.1091" fill="#333333" d="M594.9,353.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C587.7,353.3,591,354.5,594.9,353.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M593.5,314.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C588,312,590.5,313.6,593.5,314.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M619.3,345.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C611,344.1,614.8,345.8,619.3,345.6z"/>
				<path opacity="0.1091" fill="#333333" d="M613.6,310.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C606.6,306.2,609.9,308.6,613.6,310.2z"/>
				<path opacity="0.1091" fill="#333333" d="M574.2,339.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C568.3,337,571,338.8,574.2,339.9z"/>
				<path opacity="0.1091" fill="#333333" d="M603.6,377.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C594.5,375.5,598.6,377.6,603.6,377.8z"/>
				<path opacity="0.1091" fill="#333333" d="M635.6,341.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C626.4,339.6,630.6,341.7,635.6,341.9z"/>
				<path opacity="0.1091" fill="#333333" d="M583.6,326.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C577.6,323.7,580.4,325.5,583.6,326.6z"/>
				<path opacity="0.1091" fill="#333333" d="M602.7,323.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C595.3,318.7,598.7,321.2,602.7,323.1z"/>
				<path opacity="0.1091" fill="#333333" d="M634,360.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C623.6,357,628.4,359.6,634,360.4z"/>
				<path opacity="0.1091" fill="#333333" d="M574.6,352.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C567.4,348.6,570.7,351.1,574.6,352.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M629,371.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C618.4,368.2,623.3,371,629,371.8z"/>
				<path opacity="0.1091" fill="#333333" d="M600.9,349.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C593.7,349.3,597,350.5,600.9,349.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M599.5,310.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C594,308,596.5,309.6,599.5,310.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M625.3,341.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C617,340.1,620.8,341.8,625.3,341.6z"/>
				<path opacity="0.1091" fill="#333333" d="M619.6,306.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C612.6,302.2,615.9,304.6,619.6,306.2z"/>
				<path opacity="0.1091" fill="#333333" d="M580.2,335.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C574.3,333,577,334.8,580.2,335.9z"/>
				<path opacity="0.1091" fill="#333333" d="M609.6,373.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C600.5,371.5,604.6,373.6,609.6,373.8z"/>
				<path opacity="0.1091" fill="#333333" d="M641.6,337.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C632.4,335.6,636.6,337.7,641.6,337.9z"/>
				<path opacity="0.1091" fill="#333333" d="M589.6,322.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C583.6,319.7,586.4,321.5,589.6,322.6z"/>
				<path opacity="0.1091" fill="#333333" d="M608.7,319.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C601.3,314.7,604.7,317.2,608.7,319.1z"/>
				<path opacity="0.1091" fill="#333333" d="M640,356.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C629.6,353,634.4,355.6,640,356.4z"/>
				<path opacity="0.1091" fill="#333333" d="M580.6,348.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C573.4,344.6,576.7,347.1,580.6,348.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M625,339.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C614.4,336.2,619.3,339,625,339.8z"/>
				<path opacity="0.1091" fill="#333333" d="M596.9,317.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C589.7,317.3,593,318.5,596.9,317.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M595.5,278.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C590,276,592.5,277.6,595.5,278.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M621.3,309.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C613,308.1,616.8,309.8,621.3,309.6z"/>
				<path opacity="0.1091" fill="#333333" d="M615.6,274.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C608.6,270.2,611.9,272.6,615.6,274.2z"/>
				<path opacity="0.1091" fill="#333333" d="M576.2,303.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C570.3,301,573,302.8,576.2,303.9z"/>
				<path opacity="0.1091" fill="#333333" d="M605.6,341.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C596.5,339.5,600.6,341.6,605.6,341.8z"/>
				<path opacity="0.1091" fill="#333333" d="M637.6,305.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C628.4,303.6,632.6,305.7,637.6,305.9z"/>
				<path opacity="0.1091" fill="#333333" d="M585.6,290.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C579.6,287.7,582.4,289.5,585.6,290.6z"/>
				<path opacity="0.1091" fill="#333333" d="M604.7,287.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C597.3,282.7,600.7,285.2,604.7,287.1z"/>
				<path opacity="0.1091" fill="#333333" d="M636,324.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C625.6,321,630.4,323.6,636,324.4z"/>
				<path opacity="0.1091" fill="#333333" d="M576.6,316.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C569.4,312.6,572.7,315.1,576.6,316.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M613,311.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C602.4,308.2,607.3,311,613,311.8z"/>
				<path opacity="0.1091" fill="#333333" d="M584.9,289.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C577.7,289.3,581,290.5,584.9,289.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M583.5,250.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C578,248,580.5,249.6,583.5,250.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M609.3,281.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C601,280.1,604.8,281.8,609.3,281.6z"/>
				<path opacity="0.1091" fill="#333333" d="M603.6,246.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C596.6,242.2,599.9,244.6,603.6,246.2z"/>
				<path opacity="0.1091" fill="#333333" d="M564.2,275.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C558.3,273,561,274.8,564.2,275.9z"/>
				<path opacity="0.1091" fill="#333333" d="M593.6,313.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C584.5,311.5,588.6,313.6,593.6,313.8z"/>
				<path opacity="0.1091" fill="#333333" d="M625.6,277.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C616.4,275.6,620.6,277.7,625.6,277.9z"/>
				<path opacity="0.1091" fill="#333333" d="M573.6,262.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C567.6,259.7,570.4,261.5,573.6,262.6z"/>
				<path opacity="0.1091" fill="#333333" d="M592.7,259.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C585.3,254.7,588.7,257.2,592.7,259.1z"/>
				<path opacity="0.1091" fill="#333333" d="M624,296.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C613.6,293,618.4,295.6,624,296.4z"/>
				<path opacity="0.1091" fill="#333333" d="M564.6,288.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C557.4,284.6,560.7,287.1,564.6,288.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M609,279.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C598.4,276.2,603.3,279,609,279.8z"/>
				<path opacity="0.1091" fill="#333333" d="M580.9,257.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C573.7,257.3,577,258.5,580.9,257.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M579.5,218.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C574,216,576.5,217.6,579.5,218.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M605.3,249.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C597,248.1,600.8,249.8,605.3,249.6z"/>
				<path opacity="0.1091" fill="#333333" d="M599.6,214.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C592.6,210.2,595.9,212.6,599.6,214.2z"/>
				<path opacity="0.1091" fill="#333333" d="M560.2,243.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C554.3,241,557,242.8,560.2,243.9z"/>
				<path opacity="0.1091" fill="#333333" d="M589.6,281.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C580.5,279.5,584.6,281.6,589.6,281.8z"/>
				<path opacity="0.1091" fill="#333333" d="M621.6,245.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C612.4,243.6,616.6,245.7,621.6,245.9z"/>
				<path opacity="0.1091" fill="#333333" d="M569.6,230.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C563.6,227.7,566.4,229.5,569.6,230.6z"/>
				<path opacity="0.1091" fill="#333333" d="M588.7,227.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C581.3,222.7,584.7,225.2,588.7,227.1z"/>
				<path opacity="0.1091" fill="#333333" d="M620,264.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C609.6,261,614.4,263.6,620,264.4z"/>
				<path opacity="0.1091" fill="#333333" d="M560.6,256.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C553.4,252.6,556.7,255.1,560.6,256.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M605,271.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C594.4,268.2,599.3,271,605,271.8z"/>
				<path opacity="0.1091" fill="#333333" d="M576.9,249.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C569.7,249.3,573,250.5,576.9,249.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M575.5,210.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C570,208,572.5,209.6,575.5,210.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M601.3,241.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C593,240.1,596.8,241.8,601.3,241.6z"/>
				<path opacity="0.1091" fill="#333333" d="M595.6,206.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C588.6,202.2,591.9,204.6,595.6,206.2z"/>
				<path opacity="0.1091" fill="#333333" d="M556.2,235.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C550.3,233,553,234.8,556.2,235.9z"/>
				<path opacity="0.1091" fill="#333333" d="M585.6,273.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C576.5,271.5,580.6,273.6,585.6,273.8z"/>
				<path opacity="0.1091" fill="#333333" d="M617.6,237.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C608.4,235.6,612.6,237.7,617.6,237.9z"/>
				<path opacity="0.1091" fill="#333333" d="M565.6,222.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C559.6,219.7,562.4,221.5,565.6,222.6z"/>
				<path opacity="0.1091" fill="#333333" d="M584.7,219.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C577.3,214.7,580.7,217.2,584.7,219.1z"/>
				<path opacity="0.1091" fill="#333333" d="M616,256.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C605.6,253,610.4,255.6,616,256.4z"/>
				<path opacity="0.1091" fill="#333333" d="M556.6,248.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C549.4,244.6,552.7,247.1,556.6,248.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M635,339.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C624.4,336.2,629.3,339,635,339.8z"/>
				<path opacity="0.1091" fill="#333333" d="M606.9,317.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C599.7,317.3,603,318.5,606.9,317.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M605.5,278.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C600,276,602.5,277.6,605.5,278.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M631.3,309.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C623,308.1,626.8,309.8,631.3,309.6z"/>
				<path opacity="0.1091" fill="#333333" d="M625.6,274.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C618.6,270.2,621.9,272.6,625.6,274.2z"/>
				<path opacity="0.1091" fill="#333333" d="M586.2,303.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C580.3,301,583,302.8,586.2,303.9z"/>
				<path opacity="0.1091" fill="#333333" d="M615.6,341.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C606.5,339.5,610.6,341.6,615.6,341.8z"/>
				<path opacity="0.1091" fill="#333333" d="M647.6,305.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C638.4,303.6,642.6,305.7,647.6,305.9z"/>
				<path opacity="0.1091" fill="#333333" d="M595.6,290.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C589.6,287.7,592.4,289.5,595.6,290.6z"/>
				<path opacity="0.1091" fill="#333333" d="M614.7,287.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C607.3,282.7,610.7,285.2,614.7,287.1z"/>
				<path opacity="0.1091" fill="#333333" d="M646,324.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C635.6,321,640.4,323.6,646,324.4z"/>
				<path opacity="0.1091" fill="#333333" d="M586.6,316.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C579.4,312.6,582.7,315.1,586.6,316.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M629,555.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C618.4,552.2,623.3,555,629,555.8z"/>
				<path opacity="0.1091" fill="#333333" d="M600.9,533.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C593.7,533.3,597,534.5,600.9,533.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M599.5,494.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C594,492,596.5,493.6,599.5,494.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M625.3,525.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C617,524.1,620.8,525.8,625.3,525.6z"/>
				<path opacity="0.1091" fill="#333333" d="M619.6,490.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C612.6,486.2,615.9,488.6,619.6,490.2z"/>
				<path opacity="0.1091" fill="#333333" d="M580.2,519.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C574.3,517,577,518.8,580.2,519.9z"/>
				<path opacity="0.1091" fill="#333333" d="M609.6,557.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C600.5,555.5,604.6,557.6,609.6,557.8z"/>
				<path opacity="0.1091" fill="#333333" d="M641.6,521.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C632.4,519.6,636.6,521.7,641.6,521.9z"/>
				<path opacity="0.1091" fill="#333333" d="M589.6,506.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C583.6,503.7,586.4,505.5,589.6,506.6z"/>
				<path opacity="0.1091" fill="#333333" d="M608.7,503.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C601.3,498.7,604.7,501.2,608.7,503.1z"/>
				<path opacity="0.1091" fill="#333333" d="M640,540.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C629.6,537,634.4,539.6,640,540.4z"/>
				<path opacity="0.1091" fill="#333333" d="M580.6,532.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C573.4,528.6,576.7,531.1,580.6,532.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M651,559.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C640.4,556.2,645.3,559,651,559.8z"/>
				<path opacity="0.1091" fill="#333333" d="M622.9,537.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C615.7,537.3,619,538.5,622.9,537.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M621.5,498.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C616,496,618.5,497.6,621.5,498.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M647.3,529.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C639,528.1,642.8,529.8,647.3,529.6z"/>
				<path opacity="0.1091" fill="#333333" d="M641.6,494.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C634.6,490.2,637.9,492.6,641.6,494.2z"/>
				<path opacity="0.1091" fill="#333333" d="M602.2,523.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C596.3,521,599,522.8,602.2,523.9z"/>
				<path opacity="0.1091" fill="#333333" d="M631.6,561.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C622.5,559.5,626.6,561.6,631.6,561.8z"/>
				<path opacity="0.1091" fill="#333333" d="M663.6,525.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C654.4,523.6,658.6,525.7,663.6,525.9z"/>
				<path opacity="0.1091" fill="#333333" d="M611.6,510.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C605.6,507.7,608.4,509.5,611.6,510.6z"/>
				<path opacity="0.1091" fill="#333333" d="M630.7,507.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C623.3,502.7,626.7,505.2,630.7,507.1z"/>
				<path opacity="0.1091" fill="#333333" d="M662,544.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C651.6,541,656.4,543.6,662,544.4z"/>
				<path opacity="0.1091" fill="#333333" d="M602.6,536.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C595.4,532.6,598.7,535.1,602.6,536.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M663,515.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C652.4,512.2,657.3,515,663,515.8z"/>
				<path opacity="0.1091" fill="#333333" d="M634.9,493.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C627.7,493.3,631,494.5,634.9,493.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M633.5,454.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C628,452,630.5,453.6,633.5,454.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M659.3,485.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C651,484.1,654.8,485.8,659.3,485.6z"/>
				<path opacity="0.1091" fill="#333333" d="M653.6,450.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C646.6,446.2,649.9,448.6,653.6,450.2z"/>
				<path opacity="0.1091" fill="#333333" d="M614.2,479.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C608.3,477,611,478.8,614.2,479.9z"/>
				<path opacity="0.1091" fill="#333333" d="M643.6,517.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C634.5,515.5,638.6,517.6,643.6,517.8z"/>
				<path opacity="0.1091" fill="#333333" d="M675.6,481.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C666.4,479.6,670.6,481.7,675.6,481.9z"/>
				<path opacity="0.1091" fill="#333333" d="M623.6,466.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C617.6,463.7,620.4,465.5,623.6,466.6z"/>
				<path opacity="0.1091" fill="#333333" d="M642.7,463.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C635.3,458.7,638.7,461.2,642.7,463.1z"/>
				<path opacity="0.1091" fill="#333333" d="M674,500.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C663.6,497,668.4,499.6,674,500.4z"/>
				<path opacity="0.1091" fill="#333333" d="M614.6,492.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C607.4,488.6,610.7,491.1,614.6,492.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M665,515.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C654.4,512.2,659.3,515,665,515.8z"/>
				<path opacity="0.1091" fill="#333333" d="M636.9,493.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C629.7,493.3,633,494.5,636.9,493.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M635.5,454.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C630,452,632.5,453.6,635.5,454.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M661.3,485.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C653,484.1,656.8,485.8,661.3,485.6z"/>
				<path opacity="0.1091" fill="#333333" d="M655.6,450.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C648.6,446.2,651.9,448.6,655.6,450.2z"/>
				<path opacity="0.1091" fill="#333333" d="M616.2,479.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C610.3,477,613,478.8,616.2,479.9z"/>
				<path opacity="0.1091" fill="#333333" d="M645.6,517.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C636.5,515.5,640.6,517.6,645.6,517.8z"/>
				<path opacity="0.1091" fill="#333333" d="M677.6,481.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C668.4,479.6,672.6,481.7,677.6,481.9z"/>
				<path opacity="0.1091" fill="#333333" d="M625.6,466.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C619.6,463.7,622.4,465.5,625.6,466.6z"/>
				<path opacity="0.1091" fill="#333333" d="M644.7,463.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C637.3,458.7,640.7,461.2,644.7,463.1z"/>
				<path opacity="0.1091" fill="#333333" d="M676,500.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C665.6,497,670.4,499.6,676,500.4z"/>
				<path opacity="0.1091" fill="#333333" d="M616.6,492.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C609.4,488.6,612.7,491.1,616.6,492.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M96,171.9c6.4,2.8,12.6,5.7,19.6,6.9c5.5,0.9,11,1.2,16.5,1.3c3.5,1,6.1-0.1,7.7-3.5
					c1.2-5.9,0.2-11.6-2.9-17c-3.8-10-8.2-19.7-13.2-29.2c-2-5.9-5.6-10.4-10.9-13.4c-4.2-1.4-8.2-2.8-12.1-4.9
					c-1.5-0.7-3-1.6-4.3-2.6c-1.8-1.4-3.8-2.5-6.1-3.1c-3.3-1.7-6-1.2-8.2,1.8c-2.4,5.5-2.6,11.2-0.7,17.2c1.7,10.5,3.9,21,6.8,31.2
					C88.9,162.8,91.5,167.9,96,171.9z"/>
				<path opacity="0.1091" fill="#333333" d="M69.5,148.7c1.8,1,3.6,1.9,5.4,2.9c3.2,1.4,6.4,2.5,9.8,3.2c2.5,0.8,5,1.3,7.5,1.6
					c4,0.8,8.1,1.1,12.2,1.1c3.2,0.2,5.7-0.9,7.6-3.5c1.8-3.9,1.6-7.8-0.6-11.7c-2.4-6.6-5.3-12.9-8.8-18.9c-1.6-4.2-4.4-6.9-8.5-8
					c-5-1.5-9.9-3.2-14.5-5.6c-3.9-2-7.6-4.3-11.4-6.6c-3.1-1-5.8-0.4-8.2,1.8c-2.6,3.6-3.2,7.6-1.9,12.1c0.9,7.3,2.5,14.4,4.6,21.4
					C63.4,143.1,65.7,146.5,69.5,148.7z"/>
				<path opacity="0.1091" fill="#333333" d="M69,106c1.9,5.2,5.3,8.4,10.2,9.3c2.2,0.8,4.4,1.5,6.6,2.1c2.5,0.5,5,0.9,7.4,1.4
					c5.5,1,11,1.6,16.6,1.7c2.9-0.4,5.5-1.4,7.8-3.2c2.2-1.7,2.8-3.9,2-6.6c-0.7-3.4-2-6.6-3.9-9.5c-1.3-2.5-3.3-3.6-6.1-3.3
					c-4.8-0.1-9.6-0.7-14.3-1.7c-4.2-0.6-8.4-1.6-12.5-2.8c1.1,0.3,2.2,0.7,3.3,1c-2.2-2.4-4.3-4.8-6.5-7.2
					c-2.4-1.1-4.9-1.3-7.5-0.6c-2.7,0.4-4.8,1.6-6.5,3.6c-0.9,2.7-0.9,5.5-0.1,8.3C65.8,101.3,67,103.8,69,106z"/>
				<path opacity="0.1091" fill="#333333" d="M93.7,140.4c1.8,0.9,3.6,1.8,5.4,2.7c3.2,1.3,6.4,2.4,9.8,3.2c2.5,0.8,5,1.4,7.6,1.6
					c4,0.8,8.1,1.2,12.3,1.1c3.2,0.3,5.8-0.9,7.6-3.6c1.8-4,1.5-7.9-0.7-11.9c-2.4-6.7-5.4-13.1-9-19.3c-1.6-4.3-4.5-7-8.6-8.2
					c-4.8-0.8-9.4-1.7-13.9-3.6c-4-1.6-7.5-3.5-11.2-5.6c-3.1-0.9-5.8-0.3-8.2,1.8c-2.6,3.4-3.3,7.2-2,11.5
					c0.9,6.9,2.4,13.7,4.5,20.4C87.8,135.2,89.9,138.5,93.7,140.4z"/>
				<path opacity="0.1091" fill="#333333" d="M90.8,103.7c2,5.2,5.4,8.2,10.2,9.3c2.2,0.8,4.4,1.5,6.7,2.2c8,2.1,16.2,3,24.4,3.6
					c2.9-0.3,5.5-1.3,7.8-3.2c2.2-2,2.7-4.5,1.7-7.4c-0.9-3.9-2.4-7.6-4.6-11c-1.3-2.8-3.4-4.1-6.4-4c-4.7,0.1-9.3-0.3-14-1.1
					c-4.2-0.5-8.3-1.3-12.3-2.4c1.1,0.4,2.2,0.7,3.3,1.1c-2.2-2.4-4.3-4.8-6.5-7.1c-2.4-1.2-4.9-1.5-7.5-0.7
					c-2.7,0.4-4.9,1.7-6.5,3.8c-0.9,2.9-0.9,5.9,0,8.9C87.6,98.7,88.8,101.4,90.8,103.7z"/>
				<path opacity="0.1091" fill="#333333" d="M48.9,130.2c1.7,4.4,4.5,7.5,8.4,9.3c2.3,1.4,4.8,2.2,7.5,2.4c2.4,0.6,4.8,1.3,7.2,1.9
					c5.4,1.3,10.9,2,16.5,2.2c3-0.2,5.5-1.4,7.6-3.6c1.7-1.8,2.1-3.8,1.2-6c-0.3-3.2-1.3-6.3-3-9.2c-1.6-3.9-3.9-7.3-6.9-10.2
					c-3.6-0.9-7.2-1.8-10.9-2.7c1.2,0.4,2.4,0.8,3.7,1.2c-3.4-1.2-6.9-2.4-10.3-3.6c1.2,0.5,2.4,1,3.6,1.5c-4.5-2-9.1-3.9-13.7-5.6
					c1.2,0.6,2.3,1.2,3.5,1.9c-2-2.5-3.9-4.9-5.9-7.4c-2.2-1.9-4.8-2.5-7.7-1.8c-3,0.5-5,2.1-6.3,4.8c-0.6,4.3-0.3,8.6,0.8,12.9
					C45.1,122.4,46.6,126.5,48.9,130.2z"/>
				<path opacity="0.1091" fill="#333333" d="M77.8,175.2c6.3,2.8,12.5,5.6,19.4,6.6c5.4,0.8,10.8,0.9,16.3,0.9
					c3.5,0.8,6-0.3,7.7-3.5c1.4-5.4,0.6-10.6-2.3-15.7c-3.5-9.1-7.5-18-12.1-26.7c-1.9-5.5-5.3-9.5-10.3-12
					c-4.5-1.8-8.9-3.7-13.1-6.3c-1.6-0.9-3.1-1.8-4.5-3c-1.8-1.5-3.9-2.7-6.2-3.5c-3.2-1.7-6-1.1-8.2,1.8
					c-2.4,5.3-2.7,10.8-0.9,16.5c1.6,10.1,3.7,20.1,6.5,30C70.8,166.5,73.4,171.4,77.8,175.2z"/>
				<path opacity="0.1091" fill="#333333" d="M109.6,136.3c1.8,0.9,3.6,1.9,5.4,2.8c3.2,1.3,6.4,2.4,9.8,3.3c2.5,0.8,5,1.4,7.6,1.6
					c4.1,0.8,8.2,1.2,12.3,1.2c3.2,0.3,5.8-0.9,7.6-3.6c1.7-4.1,1.4-8.2-0.9-12.2c-2.5-6.9-5.7-13.6-9.4-20
					c-1.6-4.4-4.6-7.2-8.8-8.5c-4.6-0.3-9-0.8-13.4-2.3c-1.6-0.5-3.1-1-4.6-1.7c-2.3-0.9-4.4-2-6.4-3.4c-3.1-0.9-5.8-0.3-8.2,1.8
					c-2.6,3.4-3.3,7.2-2,11.4c0.9,6.9,2.3,13.6,4.4,20.2C103.7,131.2,105.8,134.4,109.6,136.3z"/>
				<path opacity="0.1091" fill="#333333" d="M58.7,116.9c1.9,5.3,5.3,8.7,10.1,10.3c2.1,1,4.3,1.9,6.5,2.7c2.3,0.5,4.7,0.9,7,1.4
					c5.7,1.1,11.6,1.7,17.4,1.8c3-0.2,5.5-1.3,7.7-3.3c2.2-2.2,2.6-4.9,1.4-7.8c-1.1-4.2-2.8-8.1-5-11.8c-1.3-2.9-3.5-4.4-6.6-4.4
					c-3.9-0.3-7.7-1-11.5-2c-3.2-0.7-6.5-1.5-9.6-2.4c1.2,0.5,2.3,0.9,3.5,1.4c-2.7-1.2-5.4-2.3-8-3.5c1.1,0.5,2.2,1,3.4,1.5
					c-2.2-2.7-4.4-5.4-6.6-8c-2.3-1.6-4.8-2-7.6-1.3c-2.8,0.5-5,1.9-6.4,4.3c-0.7,3.7-0.6,7.3,0.4,10.9
					C55.1,110.4,56.5,113.8,58.7,116.9z"/>
				<path opacity="0.1091" fill="#333333" d="M79.5,115.5c1.7,4.6,4.5,7.7,8.5,9.3c2.3,1.2,4.8,2,7.4,2.4c2.3,0.5,4.7,1.1,7,1.6
					c5.4,1.2,10.8,1.7,16.3,1.8c3-0.2,5.5-1.4,7.6-3.6c2-2.6,2.3-5.5,0.7-8.6c-1.5-4.6-3.6-9-6.2-13c-1.4-3.2-3.8-4.8-7.2-4.9
					c-4.4-0.2-8.6-0.9-12.8-2.1c-3.2-0.8-6.4-1.7-9.5-2.5c1.1,0.5,2.3,1.1,3.4,1.6c-2.1-2.5-4.1-5-6.2-7.5c-2.3-1.7-4.8-2.2-7.6-1.5
					c-2.9,0.5-5,2-6.3,4.5c-0.7,3.9-0.5,7.8,0.6,11.6C75.9,108.6,77.3,112.2,79.5,115.5z"/>
				<path opacity="0.1091" fill="#333333" d="M106.9,154.1c5.1,2.6,10.1,4.9,15.6,6.4c2.5,0.8,5.1,1.4,7.7,1.6
					c4.1,0.8,8.3,1.2,12.4,1.2c3.4,0.7,6-0.4,7.7-3.5c1.4-5.2,0.7-10.2-2.1-15c-3.3-8.7-7.2-17.2-11.7-25.5
					c-1.8-5.3-5.2-9.1-10-11.4c-4.3-0.8-8.4-1.7-12.4-3.4c-3.7-1.6-7.1-3.7-10.6-5.5c-3.2-1.4-5.9-0.8-8.2,1.8
					c-2.5,4.5-2.9,9.4-1.3,14.5c1.3,8.8,3.2,17.6,5.7,26.1C100.3,146.8,102.7,151,106.9,154.1z"/>
				<path opacity="0.1091" fill="#333333" d="M55.3,150.3c1.8,0.9,3.6,1.9,5.4,2.8c3.2,1.3,6.4,2.3,9.7,3.1c2.5,0.8,5,1.3,7.5,1.6
					c4.1,0.8,8.2,1.2,12.3,1.3c3.1,0.1,5.7-1.1,7.6-3.6c1.9-3.4,1.9-6.9-0.1-10.5c-2-5.8-4.6-11.4-7.8-16.7c-1.5-3.8-4.2-6.1-8-6.8
					c-5.4-1.8-10.5-3.8-15.5-6.5c-4-2.2-7.9-4.5-11.7-7c-3.1-0.9-5.8-0.3-8.2,1.8c-2.6,3.3-3.3,7-2.1,11.2
					c0.8,6.7,2.2,13.4,4.3,19.8C49.4,145.3,51.6,148.4,55.3,150.3z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M139.5,138.9c3.2,0.5,6.3,1.1,9.5,1.6c3.7,0.4,6-1.1,7-4.7c0.2-6-1.7-11.4-5.6-16.3
					c-5.4-9.2-11.4-18.1-17.8-26.7c-3-5.5-7.3-9.3-12.9-11.4c-2.8-0.4-5.6-0.8-8.4-1.2c-3.7-0.5-6,1.1-7,4.6
					c-0.3,5.9,1.5,11.3,5.3,16.1c5.3,9.2,11,18,17.3,26.5C129.7,133,133.9,136.8,139.5,138.9z"/>
				<path opacity="0.1091" fill="#333333" d="M112,116.7c3.1,0.6,6.2,1.2,9.4,1.7c3.2-0.3,5.5-1.9,7-4.7c1.3-3.9,0.6-7.6-2.1-11
					c-3.2-5.8-6.8-11.3-11-16.5c-2.1-3.8-5.3-5.8-9.4-6.1c-2.9-0.6-5.9-1.2-8.8-1.8c-3.2,0.3-5.5,1.8-7,4.6c-1.3,3.9-0.7,7.5,2,11
					c3.1,5.9,6.7,11.4,10.8,16.5C104.8,114.2,107.9,116.3,112,116.7z"/>
				<path opacity="0.1091" fill="#333333" d="M110.2,75.3c4.1,1.9,8.2,3.7,12.3,5.6c2.7,0.3,5.1-0.4,7.2-2c2.4-1.3,3.9-3.3,4.7-5.9
					c-0.3-3.1-1.3-5.8-3.2-8.3c-1.5-2.7-3.7-4.8-6.4-6.2c-3.9-1.5-7.8-3.1-11.7-4.6c-2.6-0.2-5,0.5-7.2,2.1
					c-2.3,1.3-3.9,3.2-4.8,5.7c0.2,2.9,1.1,5.5,2.9,7.8C105.6,72.1,107.6,74,110.2,75.3z"/>
				<path opacity="0.1091" fill="#333333" d="M136,107.9c3.2,0.6,6.3,1.2,9.5,1.8c3.3-0.2,5.6-1.8,7-4.7c1.1-4.3,0.2-8.3-2.7-11.9
					c-3.6-6.4-7.7-12.5-12.3-18.2c-2.3-4.1-5.6-6.4-10-7c-2.9-0.6-5.8-1.1-8.8-1.7c-3.2,0.2-5.6,1.7-7,4.6
					c-1.1,4.3-0.3,8.2,2.6,11.9c3.5,6.4,7.4,12.5,12,18.2C128.4,104.9,131.7,107.3,136,107.9z"/>
				<path opacity="0.1091" fill="#333333" d="M131.4,71.8c4.4,2.3,8.7,4.5,13.1,6.8c2.7,0.7,5.3,0.2,7.6-1.5
					c2.5-1.4,3.9-3.6,4.4-6.3c-0.7-3.7-2.1-7.1-4.4-10.1c-1.9-3.3-4.4-6-7.6-8c-4.1-1.9-8.3-3.8-12.4-5.7c-2.7-0.6-5.2,0-7.5,1.7
					c-2.4,1.4-3.9,3.5-4.5,6.2c0.6,3.5,1.9,6.7,4.1,9.6C126,67.4,128.4,69.9,131.4,71.8z"/>
				<path opacity="0.1091" fill="#333333" d="M89.8,99.3c4.3,2.3,8.7,4.5,13,6.8c2.7,0.7,5.3,0.2,7.6-1.5c2.5-1.4,4-3.5,4.4-6.3
					c-0.6-3.7-2.1-7-4.3-10.1c-1.9-3.3-4.4-5.9-7.5-8c-4.1-1.9-8.2-3.8-12.4-5.7c-2.7-0.6-5.2,0-7.5,1.7c-2.4,1.4-3.9,3.5-4.5,6.2
					c0.5,3.5,1.9,6.7,4,9.6C84.4,95,86.8,97.4,89.8,99.3z"/>
				<path opacity="0.1091" fill="#333333" d="M120.5,140.9c3.2,0.6,6.3,1.2,9.5,1.8c3.5,0.2,5.8-1.4,7-4.7
					c0.6-5.3-0.9-10.1-4.4-14.4c-4.6-8-9.7-15.7-15.4-23c-2.6-4.9-6.5-8.1-11.6-9.5c-2.9-0.5-5.7-1-8.6-1.5c-3.5-0.2-5.8,1.3-7,4.6
					c-0.7,5.2,0.7,10,4.1,14.3c4.5,8,9.4,15.6,15,22.9C111.7,136.3,115.5,139.4,120.5,140.9z"/>
				<path opacity="0.1091" fill="#333333" d="M152,103.9c3.2,0.7,6.4,1.3,9.6,2c3.3-0.1,5.7-1.6,7-4.7c0.9-4.7-0.2-8.9-3.3-12.8
					c-3.9-7-8.4-13.6-13.4-19.9c-2.4-4.4-5.9-7-10.6-7.9c-2.9-0.6-5.9-1.1-8.8-1.7c-3.3,0.1-5.7,1.6-7,4.6c-1,4.6,0.1,8.8,3.1,12.7
					c3.8,7,8.2,13.6,13.1,19.8C143.9,100.3,147.3,102.9,152,103.9z"/>
				<path opacity="0.1091" fill="#333333" d="M100.6,88.1c4.1,1.8,8.1,3.6,12.2,5.5c2.7,0.6,5.2,0,7.5-1.6c2.4-1.4,3.9-3.5,4.5-6.2
					c-0.5-3.5-1.9-6.7-4-9.6c-1.8-3.1-4.2-5.6-7.2-7.6c-3.9-1.6-7.8-3.2-11.7-4.8c-2.7-0.5-5.2,0.1-7.4,1.7
					c-2.4,1.4-3.9,3.4-4.6,6.1c0.5,3.4,1.7,6.5,3.8,9.3C95.3,83.9,97.7,86.3,100.6,88.1z"/>
				<path opacity="0.1091" fill="#333333" d="M119.6,83.4c4.4,2.5,8.8,4.9,13.2,7.4c2.8,0.9,5.4,0.5,7.8-1.2
					c2.5-1.6,3.9-3.8,4.1-6.7c-1-4.2-2.8-8-5.4-11.5c-2.2-3.7-5.1-6.8-8.5-9.4c-4.1-2.1-8.2-4.2-12.2-6.3c-2.8-0.9-5.3-0.4-7.7,1.3
					c-2.5,1.5-3.9,3.7-4.3,6.6c0.8,4,2.5,7.6,4.9,11C113.5,78.1,116.2,81.1,119.6,83.4z"/>
				<path opacity="0.1091" fill="#333333" d="M150.1,122.5c3.2,0.6,6.3,1.1,9.5,1.7c3.6,0.3,5.9-1.3,7-4.7
					c0.4-5.6-1.2-10.6-4.9-15.1c-5-8.5-10.4-16.7-16.4-24.5c-2.8-5.1-6.8-8.6-12.2-10.3c-2.8-0.4-5.7-0.9-8.5-1.3
					c-3.5-0.3-5.9,1.3-7,4.6c-0.5,5.5,1,10.5,4.6,15c4.8,8.5,10.1,16.6,16,24.3C140.8,117.4,144.8,120.8,150.1,122.5z"/>
				<path opacity="0.1091" fill="#333333" d="M92.7,115.2c3.7,1.5,7.5,3.1,11.2,4.6c5.5,2.3,12-1.9,11.9-8
					c-1.3-4.6-3.4-8.9-6.3-12.8c-2.5-4.2-5.6-7.7-9.4-10.7c-3.5-1.4-7-2.7-10.5-4.1c-2.8-1.2-5.5-0.9-8,0.8c-2.6,1.6-3.9,3.9-4,7
					c1.2,4.6,3.2,8.8,6,12.6C86,108.8,89,112.3,92.7,115.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M129.5,127.6c7.3,2.2,14.4,4.8,21.5,7.6c3.6,1,6.1-0.3,7.6-3.7c1-6-0.1-11.7-3.4-17
					c-4.1-10-8.8-19.7-14.1-29.1c-2.2-5.9-5.9-10.3-11.3-13.2c-6.5-2.9-13.2-5.2-20.1-7c-3.6-1-6.1,0.2-7.6,3.6
					c-1.1,5.9-0.1,11.5,3,16.9c3.9,9.9,8.4,19.5,13.5,28.9C120.7,120.3,124.3,124.7,129.5,127.6z"/>
				<path opacity="0.1091" fill="#333333" d="M102.4,104.9c7.1,2.3,14.2,4.8,21.3,7.5c3.2,0.2,5.7-1,7.6-3.7
					c1.8-3.9,1.5-7.7-0.7-11.6c-2.4-6.5-5.4-12.7-9-18.6c-1.6-4.2-4.5-6.7-8.6-7.8c-6.8-2.6-13.6-5-20.5-7.3c-3.2-0.2-5.7,1-7.6,3.5
					c-1.8,3.8-1.6,7.6,0.5,11.5c2.3,6.5,5.2,12.7,8.6,18.6C95.5,101.3,98.3,103.9,102.4,104.9z"/>
				<path opacity="0.1091" fill="#333333" d="M100.9,62.8c7.2,5.4,15.1,9.3,23.8,11.8c2.6,0.7,5.1,0.4,7.5-0.9
					c2.5-1,4.4-2.7,5.5-5.1c0.2-3.1-0.5-6-2-8.7c-1.1-2.9-2.9-5.3-5.4-7.1c-8.2-2.2-15.9-5.6-23-10.3c-2.6-0.5-5-0.2-7.4,1.1
					c-2.5,1-4.4,2.6-5.6,4.9c-0.3,2.8,0.2,5.5,1.6,8C96.9,59.1,98.6,61.2,100.9,62.8z"/>
				<path opacity="0.1091" fill="#333333" d="M126.6,96.6c7.2,2.1,14.2,4.6,21.2,7.3c3.2,0.2,5.8-1,7.6-3.7c1.7-4,1.4-8-1-12
					c-2.6-6.7-5.7-13.2-9.4-19.4c-1.7-4.3-4.6-7-8.8-8.2c-6.7-2.6-13.5-5-20.4-7.2c-3.2-0.3-5.8,0.9-7.6,3.6c-1.8,4-1.5,8,0.7,11.9
					c2.4,6.7,5.5,13.2,9.1,19.4C119.6,92.7,122.5,95.4,126.6,96.6z"/>
				<path opacity="0.1091" fill="#333333" d="M123.3,61.5c7.2,5.2,15.1,9,23.6,11.4c4.6,1.7,11.7-0.9,13-6.1
					c-0.1-3.6-0.9-7-2.7-10.2c-1.3-3.4-3.4-6.2-6.1-8.6c-8.2-2.2-15.9-5.6-23-10.3c-2.6-0.9-5.1-0.6-7.6,0.7c-2.6,1-4.4,2.8-5.4,5.4
					c0,3.4,0.8,6.6,2.4,9.6C118.8,56.7,120.7,59.4,123.3,61.5z"/>
				<path opacity="0.1091" fill="#333333" d="M81.1,87.7c6.8,6.1,14.6,10.1,23.4,12.2c2.6,1.2,5.2,1.1,7.8-0.3
					c2.7-1.1,4.4-3.1,5.1-5.9c-0.3-4.1-1.5-7.9-3.4-11.5c-1.6-3.8-3.9-7.1-6.9-9.9c-8.3-1.8-15.7-5.4-22.3-10.8
					c-4.4-2.7-11.9,1.2-13,6c0.1,3.8,1.1,7.5,3,10.9C76.1,82,78.3,85.1,81.1,87.7z"/>
				<path opacity="0.1091" fill="#333333" d="M111,130c7.2,2.4,14.4,4.9,21.5,7.7c3.5,0.8,6-0.5,7.6-3.7c1.2-5.4,0.4-10.6-2.6-15.5
					c-3.6-9-7.9-17.8-12.7-26.2c-2-5.4-5.5-9.3-10.5-11.7c-6.6-2.8-13.4-5.2-20.3-7.2c-3.5-0.8-6,0.4-7.6,3.5
					c-1.3,5.3-0.6,10.4,2.3,15.4c3.5,8.9,7.5,17.6,12.1,26C102.6,123.7,106,127.6,111,130z"/>
				<path opacity="0.1091" fill="#333333" d="M142.4,92.2c7.2,2.4,14.3,5,21.4,7.7c3.3,0.3,5.8-0.9,7.6-3.7
					c1.6-4.2,1.2-8.4-1.2-12.5c-2.7-7.1-6.1-13.9-9.9-20.4c-1.7-4.5-4.7-7.3-9.1-8.7c-6.8-2.6-13.6-5.1-20.5-7.4
					c-3.3-0.3-5.8,0.9-7.6,3.6c-1.7,4.2-1.4,8.3,1,12.4c2.6,7,5.8,13.8,9.5,20.3C135.1,88,138.1,90.9,142.4,92.2z"/>
				<path opacity="0.1091" fill="#333333" d="M91.5,76c7.2,5.2,15,9,23.5,11.4c2.6,1.1,5.2,0.9,7.7-0.4c2.7-1.1,4.4-3,5.3-5.6
					c-0.1-3.8-1.1-7.3-2.9-10.6c-1.4-3.5-3.5-6.5-6.3-9c-8.2-2.1-15.8-5.6-22.8-10.3c-2.6-1-5.1-0.8-7.6,0.6c-2.6,1-4.4,2.9-5.3,5.5
					c0,3.5,0.9,6.9,2.6,10.1C86.7,70.9,88.8,73.7,91.5,76z"/>
				<path opacity="0.1091" fill="#333333" d="M111.5,72.8c6.7,5.8,14.4,9.6,23,11.4c2.6,1.3,5.3,1.2,7.9-0.2c2.7-1.2,4.4-3.2,5-6.1
					c-0.4-4.2-1.6-8.2-3.7-12c-1.7-3.9-4-7.4-7.1-10.3c-8.1-1.6-15.5-5-22-10.2c-4.6-2.7-11.9,1-13,6.1c0.2,4,1.3,7.8,3.2,11.4
					C106.4,66.7,108.6,70,111.5,72.8z"/>
				<path opacity="0.1091" fill="#333333" d="M140.1,110.6c7.2,2.4,14.4,4.9,21.4,7.7c3.5,0.7,6-0.5,7.6-3.7
					c1.3-5.3,0.4-10.4-2.6-15.3c-3.6-8.9-7.8-17.4-12.5-25.7c-2-5.3-5.5-9.2-10.4-11.5c-6.6-2.8-13.4-5.2-20.3-7.2
					c-3.4-0.7-6,0.5-7.6,3.6c-1.4,5.2-0.6,10.3,2.2,15.1c3.4,8.8,7.4,17.3,11.9,25.5C131.8,104.5,135.2,108.3,140.1,110.6z"/>
				<path opacity="0.1091" fill="#333333" d="M84,104c4.2,2.1,8.5,4,13,5.6c4.2,1.6,8.4,3.1,12.7,4.5c3.1,0,5.6-1.2,7.6-3.7
					c1.9-3.4,1.8-6.8-0.1-10.3c-2-5.7-4.7-11.1-7.8-16.3c-1.5-3.7-4.2-5.9-8-6.5c-3.5-1.2-7-2.4-10.5-3.7c1.3,0.5,2.6,1,4,1.5
					c-3.5-1.5-6.9-2.9-10.4-4.4c-2.6-1.8-5.3-2-8.2-0.6c-2.9,1.3-4.5,3.5-4.8,6.6c0.7,5.1,2.2,9.9,4.6,14.4
					C77.9,95.9,80.6,100.2,84,104z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M164.7,140.4c2.3,1.4,4.9,2.3,7.5,2.8c2.4,0.5,4.9,0.7,7.4,0.6c3,0.1,6-0.2,9-0.9
					c4.6-1.3,9-3,13.2-5.1c3-2.5,3.9-5.7,2.5-9.6c-2.2-5.8-4.9-11.4-8.1-16.6c-3.6-6.8-7.7-13.3-12.1-19.6c-2.6-5.4-6.6-9.2-12-11.4
					c-1.5-2.3-3.4-4-5.7-5.3c-3-2.1-5.8-1.8-8.4,0.9c-3,5.2-3.9,10.8-2.6,16.9c0.5,10.6,1.6,21.1,3.3,31.6
					C158.7,130.6,160.7,136,164.7,140.4z"/>
				<path opacity="0.1091" fill="#333333" d="M138.7,117.4c2.1,1.6,4.5,2.5,7.2,2.6c2.3,0.5,4.6,0.6,6.9,0.4
					c7.1-0.2,13.6-2.4,20.3-4.8c2.7-1.8,3.9-4.4,3.6-7.6c-1.1-3.8-2.8-7.4-5.1-10.7c-2.4-4.3-5.1-8.4-8.3-12.1
					c-2.1-3.7-5.2-5.8-9.2-6.1c-4.3-2.3-8.1-5.1-11.6-8.5c-3-1.4-5.8-1.1-8.4,0.9c-3,3.4-4,7.5-3.2,12.2c0.1,7.6,0.9,15.1,2.3,22.6
					C133.2,110.9,135.1,114.6,138.7,117.4z"/>
				<path opacity="0.1091" fill="#333333" d="M138.6,74.3c1.2,1.5,2.3,3,3.5,4.5c1.5,1.3,2.9,2.6,4.4,3.9c3.6,1.9,7.8,1.4,11.8,1.2
					c5.9-0.3,11.5-2.1,17.1-3.9c2.8-0.7,5.3-2.1,7.3-4.1c1.9-2,2.3-4.2,1.2-6.8c-1.1-3.3-2.8-6.2-5-8.9c-1.6-2.3-3.7-3.1-6.4-2.5
					c-3,1.1-5.9,2.2-8.9,3.3c1.4-0.4,2.7-0.8,4.1-1.2c-3.1,0.7-6.1,1.5-9.2,2.2c1.4-0.3,2.8-0.5,4.2-0.8c-2.8,0.3-5.6,0.7-8.4,1
					c1.4-0.1,2.8-0.3,4.2-0.4c-1.4,0-2.7,0-4.1,0c1.1,0.4,2.1,0.8,3.2,1.2c-2.4-2-4.6-4.3-6.4-6.9c-2.2-1.4-4.7-1.9-7.3-1.5
					c-2.7,0.1-5,1.1-6.9,2.9c-1.2,2.7-1.5,5.6-1,8.5C136,69.2,136.9,71.9,138.6,74.3z"/>
				<path opacity="0.1091" fill="#333333" d="M162.9,109c2.1,1.5,4.5,2.4,7.1,2.8c2.3,0.3,4.6,0.5,6.8,0.3
					c7.4-0.1,13.9-2.2,20.8-4.6c2.8-1.9,3.9-4.5,3.4-7.9c-1.3-4.1-3.1-7.9-5.5-11.4c-2.6-4.6-5.5-8.9-8.9-13
					c-2.2-3.9-5.4-6.2-9.6-6.7c-2,0.5-4,0.9-6,1.4c1.4-0.3,2.9-0.6,4.3-0.8c-2.6-0.2-5.1-0.3-7.7-0.1c1.5,0,2.9,0,4.4,0
					c-2.1-0.7-3.3-1.4-5.1-2.7c-3-1.3-5.7-1-8.4,0.9c-3,3-4,6.7-3.2,11.1c0.1,6.9,0.8,13.7,2.1,20.5
					C157.5,103.2,159.4,106.6,162.9,109z"/>
				<path opacity="0.1091" fill="#333333" d="M160.4,71.6c2.1,3.3,4.8,6.2,7.9,8.6c2.7,1.4,5.7,2,8.8,1.7c3.5,0.3,7,0,10.4-0.8
					c3.4-0.6,6.7-1.4,9.9-2.5c1.3-0.5,2.6-1,4-1.6c1.1-0.9,2.2-1.8,3.3-2.7c0.5-1.1,1-2.2,1.5-3.3c-0.2-2.6-1-5-2.3-7.2
					c-0.8-1.9-1.8-3.7-3.1-5.3c-0.8-1.6-2-2.9-3.5-4c-0.8-1.3-2-1.9-3.5-1.6c-2.1-0.4-4,0.1-5.8,1.4c-1.9,1.3-3.9,2.3-6.1,3
					c-1.9,0.8-4,1.3-6.1,1.5c1.4-0.1,2.8-0.3,4.2-0.4c-2.7,0.2-5.5,0.4-8.2,0.5c2.5,0.4,5,0.7,7.5,1.1c-2.5-1.8-4.6-4-6.4-6.5
					c-2.2-1.4-4.7-1.9-7.3-1.4c-2.7,0.1-5,1.1-6.9,2.9c-1.2,2.7-1.5,5.5-1,8.4C157.7,66.5,158.6,69.2,160.4,71.6z"/>
				<path opacity="0.1091" fill="#333333" d="M118.8,99.4c1.3,2.3,3,4.1,5.2,5.4c1.7,1.4,3.6,2.6,5.5,3.7c1.7,0,3.3,0.1,5,0.1
					c3.3,0,6.5-0.5,9.7-1.2c-1.4,0.2-2.8,0.5-4.2,0.7c5-0.5,10-1.4,15-2.5c2.9-0.6,5.3-2,7.2-4.3c1.8-2.5,1.9-5.2,0.3-8
					c-1.7-4-3.9-7.8-6.7-11.2c-1.7-2.8-4.1-4-7.2-3.5c-3.9,0-7.7-0.5-11.4-1.3c1.2,0.4,2.4,0.8,3.6,1.3c-2.1-0.8-4.2-1.5-6.3-2.3
					c1.2,0.6,2.4,1.2,3.5,1.7c-2.1-1.1-4.1-2.1-6.2-3.2c1.1,0.7,2.3,1.4,3.4,2.1c-1.8-2-3.4-4.1-4.8-6.4c-1.9-2.3-4.4-3.3-7.5-2.9
					c-3.1,0.2-5.3,1.7-6.8,4.4c-1,4.6-1.2,9.3-0.5,14C115.7,90.5,116.8,95.1,118.8,99.4z"/>
				<path opacity="0.1091" fill="#333333" d="M146.6,144.3c2.4,1.3,4.9,2.2,7.5,2.7c2.4,0.3,4.9,0.3,7.3,0c3-0.1,5.9-0.6,8.7-1.6
					c4.4-1.5,8.6-3.3,12.6-5.6c2.4-1.6,3.3-3.6,2.8-6.2c0.4-4.2-0.8-8.1-3.7-11.6c-2.2-3.9-4.5-7.9-6.7-11.8
					c-3.9-6.8-8.5-13-13.7-18.7c-2.1-1.3-4.2-2.7-6.3-4c1.6,1,3.1,2.1,4.7,3.1c-2.4-2.3-4.8-4.6-7.2-6.9c1.4,1.4,2.7,2.9,4.1,4.3
					c-1.3-3.1-3.3-5.3-6-6.7c-0.9-0.4-1.6-1-2.4-1.7c-3-2.1-5.8-1.8-8.4,0.9c-3,5.1-3.9,10.7-2.7,16.7c0.4,10.5,1.5,21,3.2,31.4
					C140.6,134.6,142.6,139.9,146.6,144.3z"/>
				<path opacity="0.1091" fill="#333333" d="M178.8,105c2.2,1.5,4.5,2.5,7.1,3c2.3,0.3,4.5,0.4,6.8,0.3c2.8,0,5.5-0.2,8.2-0.7
					c4.4-0.9,8.8-2.1,13-3.7c2.8-2,3.9-4.7,3.2-8.1c-2.2-6-5.1-11.7-8.9-16.9c-3.3-5.5-7.3-10.5-12-14.9c-2.9-2-5.8-2.1-8.8-0.3
					c-0.5,0.7-1.2,1.1-2,1.2c-0.7,0.1-1.4,0.4-2,0.7c1.5,0,2.9,0,4.4,0c-1.9,0-3.8,0-5.7-0.1c1.4,0.1,2.9,0.2,4.3,0.3
					c-1.3-0.2-2.5-0.8-3.5-1.8c-2.9-1.2-5.7-0.9-8.4,0.9c-2.9,2.9-4,6.4-3.3,10.7c0.1,6.6,0.7,13.2,2.1,19.7
					C173.5,99.6,175.3,102.8,178.8,105z"/>
				<path opacity="0.1091" fill="#333333" d="M128.5,85.6c1.2,1.6,2.3,3.2,3.5,4.8c1.5,1.4,3,2.7,4.5,4.1c1.1,0.6,2.2,1.2,3.3,1.8
					c2.6,0.3,5.3,0.4,7.9,0.2c5.9-0.3,11.6-2.1,17.3-3.8c2.9-0.6,5.3-2.1,7.3-4.3c1.9-2.4,2.1-4.9,0.6-7.7
					c-1.5-3.8-3.6-7.3-6.2-10.5c-1.7-2.6-4-3.7-7-3.2c-3.4,1-6.8,1.6-10.3,1.7c-2,0-4-0.3-5.9-1c1.1,0.6,2.2,1.2,3.3,1.8
					c-2.3-2.5-4.4-5.1-6.3-8c-2.1-2-4.6-2.7-7.4-2.3c-2.9,0.1-5.2,1.4-6.8,3.7c-1.1,3.8-1.3,7.6-0.7,11.6
					C125.6,78.4,126.7,82.1,128.5,85.6z"/>
				<path opacity="0.1091" fill="#333333" d="M149.2,83.7c1.1,1.6,2.2,3.1,3.4,4.7c1.5,1.4,3,2.7,4.4,4.1c1.1,0.6,2.2,1.2,3.3,1.8
					c2.3,0.2,4.6,0.1,6.8-0.1c5.5-0.5,10.7-2,16-3.5c2.9-0.7,5.2-2.3,6.9-4.8c1.6-2.9,1.4-5.8-0.6-8.6c-2.2-4.3-4.9-8.2-8.1-11.7
					c-1.9-2.9-4.5-4.1-7.8-3.6c-2.3,0.9-4.7,1.8-7,2.7c1.4-0.4,2.7-0.8,4.1-1.3c-2.4,0.5-4.9,1-7.3,1.5c1.4-0.3,2.8-0.5,4.2-0.8
					c-2.1,0.3-4.2,0.6-6.2,1c1.4-0.1,2.8-0.2,4.3-0.4c-2.1,0-4.2,0-6.3,0c2.5,0.6,5.1,1.2,7.6,1.8c-2.2-2.1-4.1-4.5-5.7-7.1
					c-2.1-2-4.6-2.7-7.4-2.3c-2.9,0.2-5.2,1.4-6.8,3.8c-1.1,3.8-1.3,7.7-0.7,11.6C146.3,76.4,147.4,80.2,149.2,83.7z"/>
				<path opacity="0.1091" fill="#333333" d="M175.9,122.4c2.2,1.6,4.7,2.5,7.4,2.9c2.3,0.6,4.7,0.8,7,0.8c2.9,0.2,5.8,0,8.7-0.4
					c4.5-0.9,9-2.3,13.3-4c2.9-2.3,3.9-5.4,2.8-9.1c-1.9-5.3-4.4-10.3-7.4-15c-3.3-6.1-7.1-11.9-11.2-17.5c-2.5-5-6.3-8.3-11.3-9.9
					c-1.7,0.3-3.4,0.7-5.1,1c1.5-0.1,3-0.3,4.5-0.4c-2.3-0.3-4.7-0.6-7-1c1.5,0.2,2.9,0.5,4.4,0.7c-1.2-0.2-2.3-0.7-3.2-1.5
					c-3-1.6-5.8-1.4-8.4,0.9c-3,4.1-4,8.7-2.9,13.8c0.3,8.7,1.2,17.2,2.7,25.8C170.2,114.7,172.1,119,175.9,122.4z"/>
				<path opacity="0.1091" fill="#333333" d="M124.4,118.8c2.1,1.7,4.5,2.5,7.2,2.5c2.3,0.4,4.6,0.5,6.9,0.4
					c5.5-0.1,11-1.5,16.4-2.6c3-0.5,5.4-2.1,7-4.7c1.3-2.4,1.2-4.7-0.2-6.9c-1.2-3.7-3-7.1-5.4-10.1c-2.5-4.2-5.7-7.8-9.6-10.9
					c-2.4-0.8-4.8-1.6-7.2-2.4c1.3,0.5,2.7,0.9,4,1.4c-2.4-1.2-4.9-2.4-7.3-3.7c1.3,0.7,2.6,1.3,3.9,2c-2-1.3-4-2.5-5.9-3.8
					c1.2,0.8,2.5,1.7,3.7,2.5c-1.9-1.6-3.8-3.2-5.8-4.7c1.2,1,2.4,2,3.5,3c-1.8-1.5-3.6-3.1-5.4-4.6c1.1,1.1,2.3,2.1,3.4,3.2
					c-1.7-1.7-3.5-3.4-5.2-5.1c-3-1.3-5.7-1-8.4,0.8c-3,3.2-4.1,7-3.2,11.6c0.1,7.2,0.8,14.3,2.2,21.4
					C119,112.7,120.8,116.2,124.4,118.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M119,135.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C108.4,132.2,113.3,135,119,135.8z"/>
				<path opacity="0.1091" fill="#333333" d="M90.9,113.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C83.7,113.3,87,114.5,90.9,113.9z"
					/>
				<path opacity="0.1091" fill="#333333" d="M89.5,74.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C84,72,86.5,73.6,89.5,74.5z"/>
				<path opacity="0.1091" fill="#333333" d="M115.3,105.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C107,104.1,110.8,105.8,115.3,105.6z"/>
				<path opacity="0.1091" fill="#333333" d="M109.6,70.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C102.6,66.2,105.9,68.6,109.6,70.2z"/>
				<path opacity="0.1091" fill="#333333" d="M70.2,99.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1C64.3,97,67,98.8,70.2,99.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M99.6,137.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C90.5,135.5,94.6,137.6,99.6,137.8z"/>
				<path opacity="0.1091" fill="#333333" d="M131.6,101.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C122.4,99.6,126.6,101.7,131.6,101.9z"/>
				<path opacity="0.1091" fill="#333333" d="M79.6,86.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C73.6,83.7,76.4,85.5,79.6,86.6z"/>
				<path opacity="0.1091" fill="#333333" d="M98.7,83.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C91.3,78.7,94.7,81.2,98.7,83.1z"/>
				<path opacity="0.1091" fill="#333333" d="M130,120.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C119.6,117,124.4,119.6,130,120.4z"/>
				<path opacity="0.1091" fill="#333333" d="M70.6,112.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C63.4,108.6,66.7,111.1,70.6,112.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M155.9,120.5c2.9,2.1,6.1,3.5,9.6,4.3c3.6,1.1,7.2,1.8,10.9,2.1c5,0.4,9.9,0.1,14.8-1
					c5.4-0.9,10.6-2.6,15.6-4.8c2.4-1.8,3.2-4,2.5-6.7c0.1-4.7-1.5-9-4.7-12.8c-2.6-4.4-5.2-8.8-7.9-13.2c-4.5-7.5-9.7-14.5-15.7-21
					c-3.5-1.7-7.1-3.4-10.6-5.1c-1.3-0.6-2.6-1.2-3.9-1.8c-0.8-0.6-1.7-1.2-2.5-1.8c-2-0.7-3.8-1.7-5.5-3c-3-2.1-5.8-1.9-8.4,0.7
					c-3.1,5.1-4,10.7-2.9,16.8c0.3,10.6,1.2,21.1,2.8,31.5C150,110.7,152,116,155.9,120.5z"/>
				<path opacity="0.1091" fill="#333333" d="M129.9,97.5c2,1.7,4.2,2.8,6.6,3.4c2.8,1.1,5.7,1.8,8.7,2.1c2.9,0.3,5.9,0.5,8.9,0.4
					c6.8-0.1,13.5-1.4,20-3c3.1-0.4,5.5-1.9,7-4.7c1.1-2.7,0.9-5.2-0.7-7.6c-1.5-4.2-3.7-8-6.4-11.5c-3-4.8-6.6-9-10.9-12.6
					c-6-1.8-12-3-17.6-5.9c-4-2-7.8-4.7-11.3-7.5c-2.9-1.5-5.7-1.2-8.4,0.7c-3,3.4-4.1,7.4-3.3,12.1c0,7.6,0.7,15.2,2,22.7
					C124.6,90.9,126.4,94.7,129.9,97.5z"/>
				<path opacity="0.1091" fill="#333333" d="M129.9,54.5c2.8,3.6,6.1,6.8,9.9,9.4c3,1.5,6.3,2.3,9.7,2.4c4.9,0.6,9.9,0.6,14.8,0.1
					c5.5-0.4,11-1.4,16.3-3c2.8-0.8,5.2-2.2,7.3-4.3c1.9-2,2.3-4.3,1.1-6.8c-1.2-3.3-2.9-6.2-5.2-8.9c-1.6-2.3-3.8-3.1-6.5-2.4
					c-3.3,1-6.7,2-10,3.1c1.4-0.4,2.7-0.8,4.1-1.2c-4,1-8.1,1.4-12.2,1.3c-3.7,0.1-7.3-0.3-10.9-1.2c1.1,0.4,2.1,0.9,3.2,1.3
					c-3.2-2.2-6.1-4.9-8.7-7.8c-2.2-1.5-4.7-2-7.3-1.6c-2.7,0.1-5,1-6.9,2.8c-1.2,2.7-1.6,5.5-1.2,8.5
					C127.4,49.3,128.3,52,129.9,54.5z"/>
				<path opacity="0.1091" fill="#333333" d="M154.1,89.1c1.3,0.8,2.7,1.6,4,2.4c2.6,1.3,5.3,2.2,8.2,2.6c4.7,0.9,9.4,1.3,14.2,1.1
					c6.2-0.4,12.2-1.3,18.2-3c3.2-0.3,5.5-1.8,7-4.7c1.1-4.1,0.3-8-2.5-11.5c-3.4-6.2-7.4-12-11.8-17.4c-2.2-3.9-5.5-6.1-9.8-6.6
					c-2,0.2-4,0.4-6,0.6c-1.9-0.1-3.7-0.3-5.6-0.7c-1.8-0.2-3.5-0.7-5.1-1.5c-2.2-0.9-4.3-2.1-6.1-3.6c-2.9-1.3-5.7-1-8.4,0.7
					c-3,3-4.1,6.6-3.4,11c0,6.9,0.6,13.7,1.8,20.5C148.9,83.3,150.6,86.7,154.1,89.1z"/>
				<path opacity="0.1091" fill="#333333" d="M151.7,51.7c2.8,3.7,6.1,6.9,9.9,9.6c3.1,1.5,6.3,2.4,9.7,2.6c5,0.8,10,1,15.1,0.7
					c5.5-0.1,10.8-1,16-2.7c1.3-0.5,2.6-1.1,3.9-1.6c1.1-0.9,2.1-1.9,3.2-2.8c0.5-1.1,0.9-2.2,1.4-3.3c-0.3-2.6-1.1-5-2.5-7.2
					c-0.8-1.9-1.9-3.7-3.3-5.3c-0.9-1.6-2.1-2.9-3.6-4c-0.9-1.3-2.1-1.8-3.6-1.6c-2.1-0.4-4,0.1-5.7,1.5c-1.4,0.6-2.7,1.2-4.1,1.8
					c1.4-0.4,2.7-0.8,4.1-1.2c-3.3,0.8-6.6,1.6-9.9,2.3c1.4-0.3,2.8-0.5,4.2-0.8c-3.4,0.4-6.7,0.8-10.1,1.2c1.4-0.2,2.8-0.3,4.2-0.5
					c-3.6,0.4-7.1,0.3-10.7-0.3c1.1,0.4,2.1,0.9,3.2,1.3c-3.2-2.1-6.1-4.6-8.7-7.5c-2.2-1.4-4.7-2-7.3-1.5c-2.7,0.1-5,1-6.9,2.8
					c-1.2,2.7-1.6,5.4-1.2,8.4C149.1,46.6,150,49.3,151.7,51.7z"/>
				<path opacity="0.1091" fill="#333333" d="M110.1,79.6c2.1,2.7,4.5,4.9,7.3,6.8c3.2,3.1,7,4.6,11.5,4.7c4.9,0.5,9.7,0.7,14.6,0.4
					c5.7-0.1,11.2-1,16.7-2.5c2.9-0.7,5.3-2.2,7.1-4.5c1.8-2.6,1.8-5.2,0.2-8c-1.7-4-4-7.6-6.8-11c-1.8-2.7-4.2-3.8-7.3-3.4
					c-2.2,0-4.4,0-6.6,0c-3.2-0.3-6.2-0.9-9.3-1.8c-4.2-1.1-7.8-2.7-11.7-4.7c1.1,0.8,2.2,1.5,3.3,2.3c-2.6-2.2-5-4.7-7.2-7.4
					c-1.9-2.4-4.4-3.4-7.4-3.1c-3.1,0.2-5.3,1.6-6.8,4.3c-1.1,4.6-1.3,9.3-0.7,14.1C107.2,70.6,108.2,75.2,110.1,79.6z"/>
				<path opacity="0.1091" fill="#333333" d="M137.8,124.5c2.6,1.9,5.4,3.1,8.5,3.7c3.9,1.3,7.9,2,12,2c4.9,0.1,9.8-0.5,14.5-1.7
					c5.2-1.2,10.2-2.9,15-5.2c2.4-1.6,3.3-3.7,2.7-6.3c0.4-4.2-1-8-4-11.5c-2.3-3.9-4.6-7.7-7-11.6c-4-6.6-8.7-12.7-14.1-18.3
					c-3.3-2.2-6.8-4.1-10.5-5.7c-1.6-1-3.1-2.1-4.7-3.1c-1.5-1-2.9-2.1-4.2-3.5c-2-1-3.9-2.2-5.6-3.7c-3-2.1-5.8-1.9-8.4,0.7
					c-3.1,5.1-4,10.7-2.9,16.7c0.3,10.5,1.2,21,2.8,31.4C131.9,114.7,133.9,120,137.8,124.5z"/>
				<path opacity="0.1091" fill="#333333" d="M170,85.1c1.3,0.8,2.6,1.6,4,2.4c2.6,1.5,5.3,2.4,8.2,2.7c4.7,0.9,9.5,1.3,14.3,1.1
					c2.5-0.1,4.9-0.4,7.4-0.7c1.9-0.3,3.8-0.6,5.7-1c3.3-0.5,6.5-1.4,9.6-2.7c2.8-2.1,3.8-4.8,3.1-8.2c-2.3-6.1-5.3-11.7-9.2-17
					c-3.4-5.5-7.5-10.5-12.3-14.9c-2.9-1.9-5.8-2-8.8-0.2c-1.7,0.6-3.4,1.1-5.2,1.4c1.5-0.2,2.9-0.3,4.4-0.5
					c-2.6,0.2-5.2,0.4-7.9,0.6c1.5,0,2.9-0.1,4.4-0.1c-3.7-0.3-7.3-0.6-11-0.9c1.4,0.2,2.9,0.3,4.3,0.5c-2.3-0.7-4.3-1.7-6.1-3.2
					c-2.9-1.2-5.7-1-8.4,0.8c-3,2.8-4.1,6.3-3.4,10.5c0,6.6,0.5,13.1,1.8,19.6C164.8,79.6,166.6,82.9,170,85.1z"/>
				<path opacity="0.1091" fill="#333333" d="M119.8,65.8c2.7,3.9,5.9,7.3,9.7,10.2c2.7,2.1,5.9,3.1,9.4,3c5,0.6,9.9,0.6,14.9,0.1
					c5.7-0.3,11.3-1.3,16.8-3c2.9-0.7,5.3-2.1,7.2-4.3c1.9-2.4,2-4.9,0.5-7.7c-1.5-3.8-3.6-7.3-6.2-10.4c-1.7-2.6-4-3.7-7-3.2
					c-2,0.3-4,0.7-6,1c-3.3,0.3-6.6,0.3-9.9-0.1c-1.9,0-3.7-0.3-5.6-0.7c-2.3-0.3-4.5-1-6.7-1.9c1.1,0.6,2.2,1.2,3.3,1.9
					c-3-2.7-5.7-5.7-8.1-8.9c-3.4-4.1-11.6-3.1-14.3,1.2c-1.2,3.8-1.5,7.7-0.9,11.6C117.1,58.5,118,62.2,119.8,65.8z"/>
				<path opacity="0.1091" fill="#333333" d="M140.5,63.8c2.7,3.9,5.9,7.2,9.6,10.1c2.7,2.1,5.8,3,9.3,2.8c4.7,0.4,9.4,0.3,14.1-0.1
					c5.2-0.4,10.3-1.4,15.3-2.8c2.9-0.7,5.2-2.3,6.9-4.8c1.6-2.9,1.3-5.8-0.7-8.6c-2.2-4.3-5-8.2-8.2-11.7c-2-2.9-4.6-4.1-7.9-3.6
					c-3.7,1-7.2,1.7-11,1.8c-1.5,0-3,0-4.5,0c-2,0.1-4-0.2-6-0.7c1.1,0.6,2.2,1.3,3.3,1.9c-2.9-2.4-5.5-5.2-7.8-8.2
					c-3.4-4-11.5-3.1-14.3,1.3c-1.2,3.8-1.4,7.6-0.9,11.6C137.8,56.5,138.7,60.2,140.5,63.8z"/>
				<path opacity="0.1091" fill="#333333" d="M167.1,102.5c2.8,2.1,6,3.7,9.4,4.7c3.5,0.9,7.1,1.6,10.7,1.9
					c4.9,0.6,9.7,0.5,14.6-0.3c5.4-0.6,10.6-1.9,15.6-3.9c2.9-2.4,3.8-5.5,2.6-9.2c-2-5.3-4.6-10.2-7.7-14.9
					c-3.5-6.1-7.3-11.8-11.6-17.4c-2.6-4.9-6.4-8.2-11.6-9.7c-2.8,0.2-5.7,0.5-8.5,0.7c1.5,0,3-0.1,4.5-0.1c-2.4-0.2-4.8-0.3-7-1.2
					c-1-0.4-1.9-0.7-2.8-1.1c-1.6-0.5-3-1.4-4.3-2.5c-3-1.7-5.8-1.4-8.4,0.8c-3.1,4-4.1,8.6-3.2,13.7c0.1,8.6,0.9,17.2,2.3,25.7
					C161.6,94.8,163.4,99.1,167.1,102.5z"/>
				<path opacity="0.1091" fill="#333333" d="M115.6,98.9c2.7,2.2,5.8,3.6,9.2,4.3c3.1,0.9,6.3,1.3,9.5,1.4
					c3.6,0.3,7.2,0.3,10.9,0.1c5.1-0.2,10.1-0.9,15-2.3c3-0.6,5.3-2.2,6.9-4.8c1.2-2.4,1.2-4.6-0.3-6.8c-1.2-3.6-3-6.9-5.4-9.9
					c-2.6-4.1-5.8-7.7-9.6-10.6c-2.6-0.7-5.3-1.4-7.9-2.1c1.3,0.4,2.7,0.9,4,1.3c-3-1-5.9-2-8.9-3c1.3,0.6,2.6,1.2,3.9,1.8
					c-5-2.4-10.1-4.6-14.6-7.9c-2.8-2-5.5-4-8.2-6.2c-2.9-1.4-5.7-1.1-8.4,0.7c-3,3.2-4.2,7-3.4,11.6c0,7.2,0.6,14.4,1.9,21.6
					C110.3,92.7,112.1,96.3,115.6,98.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M274.4,110.5c4.5,1.9,8.9,3.8,13.4,5.7c3.5,1.2,6.1,0.2,7.8-3.1
					c1.5-5.9,0.8-11.7-2.1-17.3c-3.4-10.3-7.3-20.4-11.8-30.2c-1.7-6.1-5.1-10.8-10.2-14.1c-4.1-1.7-8.2-3.4-12.3-5
					c-3.5-1.3-6.1-0.3-7.9,3c-1.6,5.8-1,11.5,1.8,17.1c3.2,10.2,6.9,20.2,11.3,29.9C266.1,102.5,269.4,107.2,274.4,110.5z"/>
				<path opacity="0.1091" fill="#333333" d="M247.5,87.7c4.4,1.9,8.8,3.8,13.2,5.6c3.2,0.5,5.8-0.6,7.8-3.1c2-3.8,2-7.7,0.1-11.8
					c-2-6.8-4.6-13.4-7.7-19.7c-1.3-4.3-4-7.2-8.1-8.6c-4.2-1.8-8.4-3.6-12.6-5.5c-3.2-0.5-5.8,0.5-7.9,3c-2.1,3.8-2.2,7.7-0.2,11.8
					c1.9,6.8,4.3,13.3,7.4,19.7C240.9,83.4,243.5,86.3,247.5,87.7z"/>
				<path opacity="0.1091" fill="#333333" d="M246.3,45.2c5.1,3.3,10.2,6.6,15.3,9.9c2.5,0.8,5,0.7,7.5-0.4c2.6-0.8,4.6-2.3,5.9-4.6
					c0.4-3,0-5.9-1.2-8.7c-0.9-2.9-2.5-5.4-4.8-7.3c-4.9-2.9-9.9-5.9-14.8-8.8c-2.5-0.7-5-0.5-7.4,0.6c-2.6,0.8-4.5,2.3-6,4.5
					c-0.5,2.8-0.2,5.5,1,8.1C242.6,41.2,244.1,43.4,246.3,45.2z"/>
				<path opacity="0.1091" fill="#333333" d="M271.7,79.3c4.4,1.9,8.8,3.7,13.2,5.6c3.2,0.5,5.8-0.6,7.8-3.1c2-3.9,2-7.8,0-11.9
					c-2-6.9-4.6-13.5-7.8-19.9c-1.3-4.4-4-7.3-8.1-8.7c-4.2-1.8-8.4-3.6-12.5-5.4c-3.2-0.5-5.8,0.5-7.9,3c-2.1,3.8-2.1,7.7-0.2,11.8
					c1.9,6.8,4.4,13.4,7.5,19.8C265,74.9,267.7,77.8,271.7,79.3z"/>
				<path opacity="0.1091" fill="#333333" d="M268.5,43.6c5.1,3.2,10.1,6.5,15.2,9.7c2.5,1.1,5.1,1,7.6-0.1c2.7-0.8,4.6-2.5,5.8-5
					c0.3-3.4-0.3-6.7-1.7-9.8c-1-3.3-2.8-6.1-5.2-8.5c-4.9-2.9-9.9-5.9-14.8-8.8c-2.5-1-5.1-0.9-7.6,0.2c-2.6,0.8-4.6,2.4-5.8,4.8
					c-0.3,3.2,0.2,6.4,1.5,9.4C264.4,38.7,266.1,41.4,268.5,43.6z"/>
				<path opacity="0.1091" fill="#333333" d="M226.7,70.6c5,3.4,10,6.8,15.1,10.2c4.5,3.1,12,0,13.3-5.2c0-4.2-0.9-8.2-2.6-12.1
					c-1.3-4-3.4-7.6-6.2-10.7c-4.8-3.1-9.6-6.2-14.4-9.3c-4.5-2.9-11.9-0.1-13.4,5c-0.1,4,0.7,7.9,2.3,11.6
					C222,64.2,224,67.6,226.7,70.6z"/>
				<path opacity="0.1091" fill="#333333" d="M256,113.1c4.5,1.9,9,3.9,13.4,5.8c3.4,1.1,6,0,7.8-3.1c1.6-5.4,1.1-10.8-1.6-16
					c-3-9.5-6.6-18.7-10.8-27.8c-1.6-5.7-4.8-9.9-9.7-12.8c-4.1-1.7-8.3-3.5-12.4-5.2c-3.4-1.1-6-0.1-7.9,3
					c-1.7,5.3-1.3,10.6,1.3,15.8c2.9,9.4,6.3,18.6,10.4,27.5C248.1,106,251.2,110.2,256,113.1z"/>
				<path opacity="0.1091" fill="#333333" d="M287.5,74.9c4.5,2,8.9,3.9,13.4,5.9c3.2,0.5,5.8-0.5,7.8-3.1c2-4,1.9-8.1-0.1-12.2
					c-2.1-7.1-4.8-13.9-8.1-20.5c-1.3-4.5-4.1-7.5-8.3-9c-4.2-1.9-8.4-3.7-12.6-5.6c-3.2-0.5-5.8,0.5-7.9,3c-2,3.9-2.1,7.9-0.1,12.1
					c2,7,4.6,13.8,7.7,20.4C280.7,70.3,283.4,73.3,287.5,74.9z"/>
				<path opacity="0.1091" fill="#333333" d="M235.7,55.6c5.3,3.9,10.6,7.7,15.9,11.6c2.5,1.2,5.1,1.3,7.7,0.1
					c2.7-0.9,4.6-2.6,5.7-5.2c0.2-3.7-0.5-7.3-2-10.7c-1.1-3.6-3-6.7-5.6-9.4c-5.1-3.4-10.3-6.8-15.4-10.3c-2.5-1.1-5.1-1.1-7.6,0
					c-2.7,0.8-4.6,2.5-5.8,5c-0.3,3.5,0.3,6.8,1.8,10.1C231.4,50.3,233.2,53.2,235.7,55.6z"/>
				<path opacity="0.1091" fill="#333333" d="M256.9,55c5.1,3.5,10.1,7,15.2,10.5c4.5,3.1,12-0.1,13.3-5.2c0-4.2-0.9-8.2-2.6-12
					c-1.3-4-3.4-7.5-6.2-10.6c-4.8-3.2-9.7-6.3-14.5-9.5c-4.4-2.9-11.9,0-13.4,5.1c-0.1,4,0.7,7.8,2.3,11.5
					C252.2,48.7,254.2,52,256.9,55z"/>
				<path opacity="0.1091" fill="#333333" d="M285.1,93.2c4.5,2,8.9,3.9,13.4,5.9c3.4,0.9,6-0.1,7.8-3.1c1.7-5.1,1.3-10.2-1.3-15.2
					c-2.8-8.9-6.3-17.7-10.3-26.1c-1.6-5.4-4.7-9.4-9.4-12c-4.1-1.8-8.3-3.5-12.4-5.3c-3.4-1-6,0-7.9,3c-1.8,5-1.4,10,1,15
					c2.7,8.9,6,17.5,9.8,25.9C277.4,86.6,280.4,90.6,285.1,93.2z"/>
				<path opacity="0.1091" fill="#333333" d="M233.2,88.6c4.5,2.1,9,4.3,13.5,6.4c3.1,0.3,5.7-0.7,7.8-3.1c2.1-3.3,2.3-6.9,0.5-10.7
					c-1.7-6.1-4-11.9-6.8-17.5c-1.3-4-3.8-6.5-7.6-7.5c-4.3-2-8.6-4.1-12.8-6.1c-3.1-0.3-5.7,0.7-7.9,3c-2.2,3.3-2.4,6.8-0.7,10.6
					c1.6,6,3.8,11.8,6.5,17.4C227,85,229.5,87.5,233.2,88.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1015.7,892c3.5-9.3,6.6-18.1,9.6-27.6c0-3.1-1.3-5.6-3.9-7.5c-3.5-1.8-7-1.6-10.6,0.5
					c-5.8,2.3-11.3,5.1-16.5,8.5c-3.8,1.7-6,4.4-6.5,8.3c-1.2,3.8-2.5,7.7-3.7,11.5c0.5-1.3,1-2.7,1.4-4c-1.8,5.1-3.6,10.1-5.5,15.2
					c0,3.1,1.3,5.6,3.7,7.5c2.4,1.5,4.8,1.7,7.2,0.5c4.1-0.8,7.9-2.3,11.5-4.4C1007.5,898.4,1011.9,895.6,1015.7,892z"/>
				<path opacity="0.1091" fill="#333333" d="M988.6,873.4c5.7-7.5,9.5-15.8,11.3-25c1.2-2.7,1.1-5.3-0.4-7.9
					c-1.3-2.7-3.3-4.3-6.2-4.8c-4.2,0.5-8.2,1.9-11.9,4.1c-3.9,1.8-7.3,4.3-10.2,7.4c-1.6,8.7-5,16.7-10.1,24
					c-2.5,4.7,1.2,11.9,6.4,12.8c4.1-0.4,7.9-1.6,11.4-3.6C982.7,878.7,985.9,876.4,988.6,873.4z"/>
				<path opacity="0.1091" fill="#333333" d="M995.6,829.3c2.4-7.8,5-15.6,7.6-23.3c0-3.2-1.3-5.7-3.9-7.5c-3.8-1.7-7.6-1.3-11.3,1
					c-6.2,2.5-12.2,5.6-17.8,9.3c-4,1.7-6.4,4.6-7.2,8.7c-2.6,7.5-5,14.9-7.4,22.5c-0.1,3.2,1.2,5.7,3.8,7.5
					c3.7,1.7,7.5,1.5,11.2-0.7c6.2-2.4,12.1-5.4,17.8-8.9C992.4,836.2,994.8,833.3,995.6,829.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1015.8,860c2.4-7.8,5-15.6,7.7-23.4c0.2-3.3-1.1-5.8-3.9-7.5c-4.3-1.5-8.5-1-12.5,1.6
					c-7,2.9-13.8,6.4-20.2,10.5c-4.4,1.8-7.2,4.9-8.5,9.3c-2.6,7.4-5.1,14.9-7.4,22.4c-0.3,3.3,1,5.8,3.8,7.5
					c4.2,1.6,8.3,1.2,12.4-1.3c7-2.8,13.7-6.1,20.1-10C1011.8,867.4,1014.6,864.4,1015.8,860z"/>
				<path opacity="0.1091" fill="#333333" d="M1018.7,826c2.4-7.9,5-15.7,7.7-23.5c0.6-3.5-0.7-6-3.9-7.5
					c-5.3-1.1-10.3-0.1-15.1,2.9c-8.7,3.8-17.1,8.2-25.2,13.1c-5.2,2.1-8.9,5.7-11.1,10.7c-2.8,7.3-5.2,14.7-7.3,22.2
					c-0.6,3.5,0.6,6,3.8,7.5c5.2,1.2,10.2,0.4,15-2.6c8.6-3.6,17-7.8,25-12.5C1012.8,834.4,1016.5,830.9,1018.7,826z"/>
				<path opacity="0.1091" fill="#333333" d="M973.2,858.7c5.2-8,9-16.6,11.5-25.7c1.6-4.5-1.4-11.7-6.4-12.8
					c-3.5,0.1-6.7,1-9.7,2.8c-3.2,1.4-5.9,3.4-8,6.1c-2.2,8.8-5.6,17.2-10.2,25.1c-0.7,2.6-0.4,5.1,1,7.5c1.1,2.5,2.9,4.3,5.4,5.3
					c3.2,0,6.3-0.8,9.1-2.4C968.8,863.3,971.3,861.4,973.2,858.7z"/>
				<path opacity="0.1091" fill="#333333" d="M995.4,896.3c6.2-8.2,10.8-17.1,13.8-26.9c1.9-4.7-1.1-11.7-6.4-12.8
					c-3.8,0.2-7.3,1.3-10.6,3.2c-3.5,1.5-6.5,3.7-8.9,6.6c-2.7,9.3-6.8,18-12.2,26.1c-0.9,2.6-0.6,5.2,0.8,7.6
					c1.1,2.6,2.9,4.3,5.6,5.2c3.5-0.1,6.8-1,9.8-2.8C990.5,901.1,993.2,899,995.4,896.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1033.4,855.1c2.3-7.9,4.9-15.7,7.7-23.4c0.7-3.5-0.6-6-3.9-7.5
					c-5.5-1.1-10.7,0-15.6,3.2c-9,4-17.8,8.5-26.2,13.5c-5.4,2.2-9.3,5.8-11.6,10.9c-2.8,7.3-5.2,14.6-7.2,22.2
					c-0.7,3.5,0.5,6,3.8,7.5c5.4,1.2,10.6,0.2,15.5-2.8c9-3.8,17.6-8.1,26-13C1027.3,863.7,1031.1,860.1,1033.4,855.1z"/>
				<path opacity="0.1091" fill="#333333" d="M981.3,846.9c5.4-7.5,9.1-15.8,11-24.9c1.5-2.7,1.4-5.4-0.1-8
					c-1.3-2.8-3.5-4.3-6.6-4.7c-4.7,0.7-9,2.3-13.1,4.7c-4.3,2-8.1,4.7-11.4,8.1c-1.8,8.6-5.1,16.6-10,23.9
					c-2.7,5.1,0.6,12,6.4,12.8c4.5-0.6,8.7-2,12.7-4.3C974.4,852.7,978.1,850.1,981.3,846.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1005.7,839.7c2.4-7.8,5-15.6,7.7-23.4c0.2-3.3-1.1-5.8-3.9-7.5
					c-4.3-1.5-8.5-1-12.5,1.6c-7,2.9-13.7,6.4-20.2,10.5c-4.4,1.8-7.2,4.9-8.5,9.3c-2.6,7.4-5.1,14.9-7.4,22.4
					c-0.3,3.3,1,5.8,3.8,7.5c4.2,1.6,8.3,1.2,12.4-1.3c7-2.8,13.7-6.1,20.1-10C1001.6,847.1,1004.4,844.1,1005.7,839.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1029,872.9c2.5-7.8,5.1-15.7,7.8-23.4c0.4-3.4-0.9-5.8-3.9-7.5
					c-4.7-1.4-9.2-0.7-13.6,2.1c-7.7,3.3-15.1,7.1-22.2,11.5c-4.8,1.9-7.9,5.2-9.5,9.8c-2.7,7.4-5.2,14.8-7.4,22.4
					c-0.4,3.3,0.8,5.9,3.7,7.5c4.6,1.5,9.1,0.9,13.4-1.8c7.6-3.1,15-6.8,22.1-11C1024.2,880.7,1027.4,877.5,1029,872.9z"/>
				<path opacity="0.1091" fill="#333333" d="M974.5,873.9c5.3-8,9.2-16.6,11.7-25.8c0.7-2.6,0.3-5.1-1.1-7.5
					c-1.1-2.5-2.8-4.3-5.3-5.3c-3.1-0.1-6.1,0.7-8.8,2.3c-2.9,1.2-5.3,3.1-7.1,5.7c-2.2,8.8-5.6,17.2-10.2,25.1
					c-0.5,2.6-0.1,5,1.3,7.4c1,2.5,2.7,4.3,5.1,5.4c2.9,0.2,5.6-0.4,8.1-1.9C970.9,878.1,973,876.4,974.5,873.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1007.8,900.8c1.8-6,3.6-12,5.5-18.1c1.4-2.8,1.2-5.5-0.5-8.2c-1.7-2.7-4.1-4-7.2-3.9
					c-4.9,1.4-9.5,3.5-13.7,6.5c-4.4,2.6-8.3,5.8-11.6,9.7c-1.7,5.7-3.3,11.5-5,17.2c-1.4,2.8-1.3,5.5,0.4,8.1c1.6,2.7,4,4,7.1,4
					c4.9-1.2,9.4-3.3,13.5-6.1C1000.7,907.7,1004.5,904.6,1007.8,900.8z"/>
				<path opacity="0.1091" fill="#333333" d="M981.4,882c2.6-6.6,5.2-13.1,7.8-19.7c2-5.1-2.2-11.8-7.7-12.1
					c-4.2,0.9-8,2.6-11.5,5.1c-3.7,2.1-6.9,4.9-9.4,8.3c-2.3,6.3-4.6,12.5-6.9,18.8c-1.9,5,2.1,11.7,7.5,12.2
					c4-0.7,7.7-2.3,11.1-4.6C975.9,887.9,978.9,885.2,981.4,882z"/>
				<path opacity="0.1091" fill="#333333" d="M988,837.5c1.2-5.7,2.5-11.4,3.7-17.1c-0.2-3.2-1.7-5.6-4.6-7.1
					c-4.1-1.2-8-0.5-11.6,2.3c-6.3,3.3-12.2,7.1-17.8,11.5c-4,2.2-6.3,5.4-6.9,9.6c-1.2,5.4-2.5,10.9-3.7,16.3
					c0.1,3.2,1.6,5.6,4.4,7.1c4.1,1.3,8,0.6,11.6-2.1c6.3-3.2,12.3-6.9,17.9-11.1C985.1,844.9,987.4,841.7,988,837.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1008.1,868.4c1.3-5.8,2.7-11.5,4-17.3c-0.1-3.3-1.6-5.6-4.5-7.1
					c-4.4-1.1-8.5-0.2-12.3,2.7c-6.7,3.5-13.1,7.6-19.1,12.2c-4.2,2.2-6.7,5.6-7.5,10c-1.3,5.4-2.5,10.9-3.8,16.3
					c0,3.3,1.5,5.7,4.4,7.2c4.3,1.2,8.4,0.4,12.2-2.4c6.7-3.4,13-7.3,19-11.8C1004.7,876.1,1007.2,872.8,1008.1,868.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1011.3,833.9c1.3-5.8,2.6-11.6,3.9-17.4c0.4-3.6-1.2-5.9-4.5-7.1
					c-5.6-0.5-10.7,1-15.3,4.6c-8.6,4.8-16.9,10.1-24.8,15.9c-5.2,2.7-8.7,6.7-10.5,12c-1.2,5.4-2.3,10.7-3.5,16.1
					c-0.4,3.5,1.1,5.9,4.4,7.1c5.5,0.7,10.5-0.8,15.1-4.3c8.6-4.6,16.8-9.7,24.7-15.4C1005.9,843.1,1009.4,839.2,1011.3,833.9z"/>
				<path opacity="0.1091" fill="#333333" d="M966.3,867c2.4-6.6,4.8-13.2,7.2-19.8c0.6-2.7,0.1-5.2-1.5-7.5c-1.3-2.5-3.4-4-6.1-4.7
					c-3.5,0.5-6.7,1.7-9.7,3.8c-3.1,1.7-5.7,4-7.7,7c-2.2,6.5-4.3,12.9-6.5,19.4c-0.6,2.7,0,5.2,1.6,7.4c1.3,2.5,3.3,4,6,4.7
					c3.4-0.4,6.5-1.6,9.3-3.5C962,872.2,964.4,869.9,966.3,867z"/>
				<path opacity="0.1091" fill="#333333" d="M990.5,903.5c2.4-6.6,4.8-13.2,7.2-19.8c1.7-4.6-2.7-11.6-7.5-12.2
					c-3.6,0.5-6.8,1.7-9.8,3.8c-3.2,1.7-5.8,4.1-7.8,7c-2.2,6.4-4.3,12.9-6.5,19.3c-0.6,2.7-0.1,5.2,1.5,7.5c1.3,2.5,3.3,4,6,4.7
					c3.4-0.4,6.6-1.6,9.4-3.6C986.1,908.7,988.6,906.4,990.5,903.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1025.5,863.4c1.3-5.8,2.6-11.6,3.9-17.3c0.3-3.5-1.2-5.9-4.5-7.1
					c-5.5-0.6-10.6,0.9-15.1,4.5c-8.5,4.7-16.8,10-24.6,15.7c-5.2,2.6-8.6,6.6-10.4,11.9c-1.2,5.4-2.3,10.7-3.5,16.1
					c-0.4,3.5,1.1,5.9,4.4,7.2c5.5,0.7,10.5-0.7,15-4.1c8.5-4.5,16.7-9.6,24.5-15.1C1020.2,872.5,1023.7,868.6,1025.5,863.4z"/>
				<path opacity="0.1091" fill="#333333" d="M974.7,855.1c2.1-6.2,4.3-12.5,6.4-18.7c1.3-2.8,1.1-5.5-0.7-8.1c-1.6-2.6-4-4-7.1-3.9
					c-4.7,1.3-9.1,3.4-13.2,6.2c-4.3,2.5-8,5.6-11.1,9.4c-1.9,5.9-3.9,11.8-5.8,17.7c-1.3,2.8-1.1,5.5,0.6,8c1.6,2.7,3.9,4,6.9,4.1
					c4.7-1.1,9-3.1,12.9-5.8C968,861.8,971.6,858.8,974.7,855.1z"/>
				<path opacity="0.1091" fill="#333333" d="M997.8,848.2c1.4-5.8,2.8-11.6,4.2-17.4c0-3.3-1.6-5.7-4.6-7.1
					c-4.5-1.1-8.7-0.1-12.6,2.9c-6.9,3.7-13.5,7.9-19.7,12.7c-4.3,2.3-6.9,5.7-7.9,10.3c-1.3,5.5-2.6,10.9-3.9,16.4
					c0,3.3,1.5,5.7,4.4,7.2c4.5,1.2,8.6,0.3,12.5-2.6c6.9-3.5,13.4-7.6,19.6-12.2C994.2,856,996.9,852.7,997.8,848.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1021.1,881.4c1.4-5.8,2.7-11.6,4.1-17.4c0-3.3-1.5-5.7-4.5-7.1c-4.7-1-9,0-13,3.1
					c-7.2,3.8-14,8.2-20.5,13.1c-4.5,2.3-7.2,5.8-8.3,10.5c-1.3,5.4-2.6,10.9-3.8,16.3c-0.1,3.3,1.4,5.7,4.4,7.2
					c4.6,1.1,8.9,0.2,12.9-2.8c7.1-3.7,13.9-7.8,20.4-12.6C1017.2,889.4,1020,886,1021.1,881.4z"/>
				<path opacity="0.1091" fill="#333333" d="M967.4,882.5c2.5-6.7,5-13.3,7.5-20c0.4-2.7-0.2-5.1-1.8-7.3c-1.3-2.4-3.2-4-5.8-4.8
					c-3.1,0.2-6,1.2-8.5,3.1c-2.8,1.5-5,3.6-6.5,6.3c-2.2,6.5-4.3,12.9-6.5,19.4c-0.3,2.6,0.3,5,1.9,7.2c1.3,2.4,3.1,4,5.6,4.9
					c2.9-0.1,5.6-1,8-2.7C964,887,966,885,967.4,882.5z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1019,796.1c0.4-1.8,0.9-3.7,1.3-5.5c-0.6,1.6-1.1,3.2-1.7,4.9c1-2,2.1-4,3.1-6
					c-3.4,3.8-6.8,7.6-10.2,11.4c3.1-3.3,6.5-6.4,10-9.3c2.9-2.1,5.4-4.7,7.4-7.7c1-2.2,0.8-4.2-0.4-6c-0.6-2.1-2-3.7-4.1-4.8
					c-1.3-0.2-2.7-0.5-4-0.7c-4.5,1.5-8.7,3.8-12.4,6.9c-2.1,1.8-4.2,3.5-6.3,5.3c-4.5,7.1-7,14.6-7.7,22.7c0.3,2.9,1.7,5.1,4.2,6.6
					c2.3,1.8,4.9,2.3,7.7,1.5c3.3-2.2,6-5.1,8.1-8.6C1016.4,803.6,1018.1,800,1019,796.1z"/>
				<path opacity="0.1091" fill="#333333" d="M992.2,778.4c0.6-2.1,1.2-4.2,1.8-6.3c-0.6,1.7-1.2,3.4-1.8,5c0.8-1.8,1.7-3.6,2.5-5.4
					c-1.4,2.1-2.8,4.1-4.2,6.2c2.5-3,5.1-6,7.6-8.9c-1.7,1.8-3.4,3.6-5.1,5.4c3.6-2.2,6.5-5,8.9-8.3c1.8-1.5,2.4-3.5,1.9-5.9
					c-0.6-1.3-1.2-2.6-1.8-3.9c-1.2-2-2.9-3.1-5-3.3c-1.5-0.7-3-0.6-4.4,0.5c-3.2,1-6.1,2.7-8.6,4.9c-1.9,1.6-3.7,3.2-5.6,4.8
					c-6.2,8.2-10.1,17.3-11.5,27.2c0.2,2.9,1.6,5.1,4.1,6.7c2.4,1.8,4.9,2.2,7.7,1.4c3.4-2.4,6.2-5.4,8.4-9
					C989.5,786.1,991.3,782.5,992.2,778.4z"/>
				<path opacity="0.1091" fill="#333333" d="M997.6,731.2c0-1.2,0-2.3,0-3.5c0,1.8,0,3.5-0.1,5.3c0.3-0.8,0.7-1.7,1-2.5
					c-3.9,6.1-7.7,12.3-11.6,18.4c3-4.3,6-8.6,9-12.9c-2.6,3.2-5.1,6.4-7.7,9.6c3.3-3.8,6.6-7.5,9.9-11.3c-2.7,2.9-5.4,5.8-8.1,8.6
					c5.7-4.7,10.9-9.9,15.6-15.6c1.7-3,1.5-5.9-0.5-8.7c-1.2-0.8-2.4-1.7-3.6-2.5c-4.4-0.7-8.4,0.5-11.8,3.7
					c-4.7,3-9.1,6.4-13.3,10.2c-3.6,2.8-6.8,5.9-9.5,9.6c-4.1,6.6-6.2,13.7-6.4,21.2c-0.3,3.1-0.1,6.2,0.5,9.2
					c1.2,3.2,3.6,4.8,7,4.7c4.9-1.2,8.6-4,11.1-8.6c5.3-6.8,10.2-13.9,14.5-21.3C997.1,740.6,998.4,736.1,997.6,731.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1018,762.3c1-1.1,2.1-2.2,3.1-3.2c-3.6,5.1-7.3,10.2-10.9,15.3c3-3.4,6-6.7,9-10.1
					c-2.6,2.7-5.1,5.4-7.7,8.1c5.5-4.4,10.4-9.3,14.9-14.7c1.7-3,1.5-5.9-0.5-8.7c-5.6-7.2-14.7,0.6-19.8,4.2
					c-4.6,3.1-8.9,6.5-12.9,10.3c-4.7,3.6-7.2,8.3-7.6,14.3c-0.9,5.4-0.5,10.8,0.7,16.1c1.4,2.9,3.7,4.5,7,4.7
					c4.3-0.5,7.6-2.8,9.9-6.8c4.6-5.6,8.6-11.5,12.1-17.8C1018.2,770.5,1019.1,766.6,1018,762.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1020.6,732c0.2-3.2,0.4-6.3,0.6-9.5c-0.3,2.1-0.5,4.1-0.8,6.2
					c0.4-0.8,0.8-1.6,1.1-2.4c-8.5,12-16.9,24.1-25.4,36.1c10.9-12.7,21.8-25.5,32.7-38.2c0.7-1.8,1.4-3.7,2.1-5.5
					c-0.4-1.4-0.9-2.9-1.3-4.3c-1.3-0.7-2.6-1.5-4-2.2c-3.9-0.1-7.4,1.1-10.3,3.7c-7.9,4.9-15.1,10.7-22.1,16.8
					c-3.4,2.6-6.5,5.6-9.3,8.8c-3.1,2.4-5,5.5-5.7,9.4c-3,8.4-3.4,16.7-1.1,25c0.9,3.6,3.3,5.2,7,4.7c4-1.3,7.1-3.7,9.3-7.2
					c5-5.4,9.4-11.2,13.3-17.5C1012.1,748.4,1016.7,740.4,1020.6,732z"/>
				<path opacity="0.1091" fill="#333333" d="M976.1,763.7c0.6-2.1,1.2-4.2,1.8-6.3c-0.6,1.7-1.2,3.3-1.7,5c0.8-1.8,1.7-3.6,2.5-5.4
					c-1.3,2-2.7,4-4,6c2-2.7,3.9-5.3,5.9-8c-3.1,3.5-6.2,7-9.4,10.5c2.4-2.3,4.7-4.6,7.1-6.9c-1.8,1.4-3.5,2.9-5.3,4.3
					c2.9-2,5.8-4,8.7-6c2.5-1.6,4.6-3.7,6.1-6.2c1.6-1.5,2-3.4,1.3-5.6c-0.7-1.3-1.3-2.5-2-3.8c-1.6-2.4-3.8-3.7-6.6-3.9
					c-5.6,2.3-10.7,5.3-15.3,9c-2.6,2.5-4.9,5.3-6.8,8.4c-1.9,2.3-3.2,4.9-4,7.9c-1.7,4.2-2.9,8.5-3.6,12.9c0.2,2.9,1.6,5.1,4.1,6.7
					c2.4,1.8,4.9,2.2,7.7,1.4c3.4-2.4,6.2-5.3,8.3-8.9C973.4,771.5,975.2,767.8,976.1,763.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1000.3,802.6c0.7-3.1,1.4-6.1,2.1-9.2c-0.3,1.2-0.6,2.4-0.8,3.7
					c0.7-1.6,1.4-3.3,2.1-4.9c-0.9,1.5-1.9,2.9-2.8,4.4c0.6-0.7,1.2-1.3,1.8-2c-1.3,1.2-2.6,2.5-4,3.7c2.1-1.7,4.1-3.4,6.2-5.1
					c-2.8,1.8-5.5,3.5-8.3,5.3c2.1-0.9,4.2-1.8,6.4-2.7c3.4-1.9,6.5-4.3,9.2-7.1c2-1.5,2.7-3.3,2.2-5.4c0.1-2.1-0.6-3.9-2.2-5.5
					c-1.6-2.3-3.7-3.6-6.5-4c-1.6,0.4-3.3,0.7-4.9,1.1c-1.3,0.6-2.6,1.1-3.9,1.7c-3.6,1.8-6.4,4.4-9,7.4c-1.1,1.1-2,2.3-2.7,3.6
					c-1.2,1.5-2,3.1-2.4,5c-1.4,3.9-2,7.7-2.7,11.8c0.8,2.5,2.4,4.4,4.7,5.8c1.6,1.4,3.4,2.1,5.4,2.1c1.8,0.5,3.4-0.1,4.7-1.8
					C997.3,808.2,999,805.6,1000.3,802.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1034,758c0-1.2,0-2.3-0.1-3.5c-1.4,4.6-2.9,9.2-4.3,13.8c2.6-4.5,5.1-9,7.7-13.4
					c-7.7,9.6-15.5,19.3-23.2,28.9c9.8-9.9,19.5-19.9,29-30.2c2.3-3.2,2.5-6.3,0.5-9.5c-1.3-0.8-2.6-1.5-3.9-2.3
					c-5.4-0.1-10.1,1.8-14.1,5.6c-5.8,3.9-11.3,8.2-16.5,12.9c-4.5,3.5-8.5,7.4-12.1,11.8c-2,6-2.5,12-1.5,18.2
					c0.2,2.4,0.7,4.7,1.5,7c1.2,3.2,3.5,4.8,7,4.7c4.9-1.2,8.7-4.1,11.3-8.8c5.4-6.8,10.4-14.1,14.8-21.6
					C1033.4,767.6,1034.7,763.1,1034,758z"/>
				<path opacity="0.1091" fill="#333333" d="M987.4,748.7c-0.5-2.1-0.2-4.1,0.8-6c-3,5-6,9.9-8.9,14.9c2.5-3.6,5-7.2,7.5-10.8
					c-4.3,4.9-8.6,9.9-12.9,14.8c5.9-5.9,11.7-11.9,17.6-17.8c1.4-1.6,2.7-3.3,4.1-4.9c0.2-1.4,0.4-2.8,0.6-4.2
					c-0.6-1.3-1.2-2.6-1.8-4c-1.4-2.1-3.2-3.1-5.5-3c-1.8-0.6-3.5-0.2-5,1.1c-2.4,0.9-4.6,2.2-6.6,3.9c-2.6,1.6-4.9,3.4-7.1,5.4
					c-2.9,2.2-5.4,4.8-7.5,7.8c-5.9,7.5-8.2,15.5-7,23.9c-0.5,3.3,0.6,5.9,3.3,7.9c2.8,1.8,5.6,1.9,8.5,0.2
					c4.5-3.9,8.3-8.4,11.4-13.5C982.7,759.5,985.5,754.3,987.4,748.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1007.9,741.9c0-1.2,0-2.3,0-3.5c0,1.8-0.1,3.5-0.1,5.3c0.4-0.9,0.7-1.8,1.1-2.7
					c-3.9,6.2-7.8,12.4-11.7,18.6c3.7-4.9,7.5-9.7,11.2-14.6c-2.8,3-5.7,6-8.5,9c5.9-5,11.3-10.4,16.3-16.2c0.4-1.6,0.9-3.2,1.3-4.8
					c-0.3-3.3-2.1-5.5-5.2-6.6c-3.3-0.6-6.2,0.1-8.7,2.2c-4.8,2.4-9.3,5.4-13.4,9.1c-4.4,3.1-8.4,6.8-11.9,10.9
					c-6.1,5.7-8.5,13.3-7.3,22.8c-0.4,3.1-0.2,6.1,0.6,9.1c1.2,3.2,3.6,4.7,7,4.7c4.9-1.1,8.6-4,11.1-8.6
					c5.3-6.7,10.1-13.8,14.4-21.2C1007.3,751.4,1008.6,746.9,1007.9,741.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1031.3,780c-0.1-1.1-0.2-2.2-0.2-3.3c-2.8,5.1-5.7,10.2-8.5,15.4
					c4.8-7.1,9.6-14.2,14.4-21.4c-4.1,4.8-8.3,9.7-12.4,14.5c5.5-4.4,10.5-9.3,15-14.7c0.4-1.6,0.8-3.1,1.2-4.7
					c-0.4-3.3-2.1-5.5-5.2-6.7c-3.2-0.7-6-0.1-8.4,1.9c-4.7,2.2-8.9,5.1-12.9,8.5c-4.3,3-8.1,6.4-11.4,10.4c-4,3.3-5.3,7.8-3.8,13.5
					c-0.1,3.9,0.4,7.7,1.4,11.5c1.5,2.8,3.8,4.4,7,4.7c3,0,5.3-1.3,7-3.7c3.4-3.1,6.3-6.6,8.7-10.6
					C1026.6,790.6,1029.4,785.5,1031.3,780z"/>
				<path opacity="0.1091" fill="#333333" d="M979.9,776.2c0.4-1.8,0.8-3.6,1.2-5.5c-0.4,1.4-0.8,2.8-1.2,4.2c0.7-1.6,1.3-3.1,2-4.7
					c-1.1,1.6-2.1,3.3-3.2,4.9c0.6-0.7,1.2-1.4,1.9-2.1c-1.4,1.3-2.8,2.7-4.2,4c2.2-1.8,4.3-3.7,6.5-5.5c-1.5,1.2-3,2.4-4.5,3.6
					c1-0.4,1.9-0.9,2.9-1.3c3.3-1.8,6.1-4,8.6-6.8c1.9-1.5,2.5-3.2,2-5.2c0-2.1-0.7-3.9-2.3-5.4c-1.6-2.3-3.7-3.6-6.4-4.1
					c-1.5,0.3-3,0.5-4.5,0.8c-1.3,0.6-2.6,1.2-3.9,1.7c-3.6,1.9-6.4,4.6-9.2,7.7c-1.1,1.2-2.1,2.5-2.9,3.9c-1.3,1.6-2.2,3.3-2.6,5.3
					c-0.6,1.6-1.2,3.3-1.6,5c-0.8,2.5-1.3,5-1.5,7.6c0.6,2.7,2.1,4.7,4.5,6.2c2.2,1.7,4.7,2.3,7.4,1.9c2.9-1.7,5.3-4,7-7
					C978.1,782.5,979.4,779.5,979.9,776.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1017.7,704.4c-0.5-6.2-1-12.5-1.5-18.7c-0.4-5.3-8-8.9-12.5-6.9
					c-3.1,2.5-5.5,5.5-7.2,9.1c-2.1,3.4-3.4,7-3.9,11c0.7,5.9,1.4,11.8,2,17.7c0.6,5.1,8,8.8,12.5,6.9c3-2.3,5.3-5.2,6.9-8.6
					C1016.1,711.7,1017.3,708.2,1017.7,704.4z"/>
				<path opacity="0.1091" fill="#333333" d="M991.5,686.1c-0.4-6.2-0.8-12.5-1.2-18.7c-0.4-5.7-7.8-9.2-12.6-6.8
					c-3.3,2.9-5.9,6.4-7.7,10.4c-2.3,3.8-3.8,7.9-4.4,12.2c0.5,5.8,1.1,11.6,1.6,17.4c0.5,5.5,7.7,9.2,12.6,6.9
					c3.2-2.7,5.7-6,7.5-9.8C989.5,694.2,990.9,690.3,991.5,686.1z"/>
				<path opacity="0.1091" fill="#333333" d="M996.2,638.6c-1.3-4.5-2.7-9-4-13.4c-1.5-3.1-4-4.4-7.4-4c-4.9,1.9-8.3,5.3-10.4,10.3
					c-4.8,7.6-9,15.6-12.7,23.8c-3,4.5-3.9,9.3-2.7,14.4c1.3,4.1,2.6,8.3,3.9,12.4c1.5,3.1,4,4.4,7.4,4c4.8-1.7,8.3-5.1,10.5-10
					c4.8-7.5,9.1-15.3,12.8-23.4C996.4,648.3,997.3,643.6,996.2,638.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1016.4,670.1c-1.4-4.4-2.8-8.7-4.2-13.1c-1.7-2.7-4.2-4-7.4-4c-4.2,0.9-7.2,3.5-9,7.7
					c-3.9,5.9-7.2,12.2-10,18.7c-2.4,3.8-2.9,7.8-1.3,11.8c1.3,4.2,2.6,8.3,3.9,12.5c1.7,2.7,4.2,4.1,7.4,4.1
					c4.2-0.9,7.2-3.4,9.1-7.5c3.9-5.9,7.3-12,10.2-18.5C1017.5,678.1,1017.9,674.2,1016.4,670.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1019.5,633.2c-1.4-4.4-2.8-8.9-4.2-13.3c-1.3-3.5-3.8-4.8-7.4-4
					c-5.5,2.8-9.5,7.2-12,13.1c-5.8,9.5-11,19.2-15.6,29.3c-3.5,5.3-5,11.1-4.3,17.3c1.3,4,2.7,8,4,12c1.3,3.5,3.7,4.9,7.4,4
					c5.5-2.7,9.5-7,12-12.8c5.8-9.3,11-18.8,15.7-28.7C1018.7,644.9,1020.2,639.2,1019.5,633.2z"/>
				<path opacity="0.1091" fill="#333333" d="M975.6,670.9c-0.5-5.9-1.1-11.7-1.6-17.6c-0.5-5.8-7.6-9.3-12.7-6.7
					c-3.3,3.1-6,6.7-7.9,10.8c-2.3,3.9-3.8,8.1-4.5,12.6c0.6,5.5,1.3,10.9,1.9,16.4c0.6,5.6,7.5,9.3,12.6,6.9
					c3.3-2.9,5.9-6.3,7.8-10.3C973.5,679.3,974.9,675.2,975.6,670.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1000.9,705.2c-0.9-5.6-1.7-11.1-2.6-16.7c-1-2.5-2.7-4.3-5.2-5.4
					c-2.3-1.5-4.7-1.9-7.4-1.5c-2.5,1.6-4.5,3.8-5.8,6.5c-1.6,2.6-2.4,5.4-2.4,8.4c1,5.3,2,10.5,3.1,15.8c1.1,2.4,2.8,4.2,5.2,5.3
					c2.3,1.4,4.7,2,7.3,1.6c2.5-1.5,4.3-3.5,5.5-6.1C1000.3,710.7,1001,708.1,1000.9,705.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1032.4,666c-1.3-4.5-2.7-9-4-13.5c-1.6-3-4-4.3-7.4-4c-4.7,1.6-8.1,4.8-10.1,9.6
					c-4.6,7.2-8.6,14.7-12.1,22.5c-2.9,4.4-3.7,8.9-2.4,13.8c1.3,4.2,2.5,8.3,3.8,12.5c1.5,3,4,4.4,7.4,4.1
					c4.7-1.5,8.1-4.6,10.1-9.4c4.6-7.1,8.7-14.4,12.2-22.1C1032.8,675.3,1033.6,670.8,1032.4,666z"/>
				<path opacity="0.1091" fill="#333333" d="M985.6,651.7c-1.4-4.3-2.8-8.7-4.2-13c-1.7-2.7-4.2-4.1-7.4-3.9
					c-4.3,1-7.3,3.6-9.1,7.9c-3.9,6.1-7.3,12.4-10.1,19.1c-2.5,3.9-2.9,7.9-1.4,12c1.3,4.1,2.6,8.3,3.9,12.4c1.7,2.8,4.2,4.1,7.4,4
					c4.3-0.9,7.3-3.5,9.2-7.7c4-6,7.4-12.3,10.3-18.8C986.6,659.8,987.1,655.8,985.6,651.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1006.4,649.6c-1.3-4.5-2.7-9-4-13.4c-1.5-3.1-4-4.4-7.4-4c-4.8,1.7-8.2,5.1-10.3,10
					c-4.7,7.4-8.8,15.1-12.3,23.2c-2.9,4.4-3.8,9.1-2.5,14.1c1.3,4.1,2.6,8.3,3.8,12.4c1.5,3.1,4,4.4,7.4,4
					c4.8-1.6,8.2-4.9,10.3-9.7c4.7-7.3,8.9-14.8,12.5-22.7C1006.7,659.1,1007.6,654.5,1006.4,649.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1029.3,683.4c-1.3-4.5-2.6-9-3.9-13.4c-1.8-2.6-4.3-3.9-7.4-4c-4,0.7-6.9,2.9-8.6,6.8
					c-3.6,5.4-6.7,11.1-9.2,17.1c-2.3,3.6-2.6,7.3-0.9,11c1.2,4.3,2.4,8.6,3.7,12.9c1.8,2.6,4.2,4,7.4,4.1c4-0.6,6.9-2.8,8.7-6.7
					c3.7-5.3,6.8-11,9.3-16.9C1030.6,690.7,1030.9,687.1,1029.3,683.4z"/>
				<path opacity="0.1091" fill="#333333" d="M979,683.5c-0.8-5.7-1.6-11.4-2.4-17c-0.7-4.9-8.1-8.7-12.6-6.9
					c-2.9,2.2-5.1,5-6.7,8.3c-1.9,3.1-3.1,6.5-3.4,10.1c0.9,5.4,1.9,10.9,2.8,16.3c0.8,2.7,2.5,4.6,5,5.8c2.4,1.5,4.9,1.9,7.6,1.1
					c2.8-2.1,5-4.7,6.5-8C977.7,690.3,978.8,687,979,683.5z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1013.2,833.1c-1.4-2.9-2.1-6-2.1-9.2c-0.7,5-1.5,10-2.2,15c1.2-2.4,2.4-4.7,3.5-7.1
					c-0.9,1.2-1.9,2.3-2.8,3.5c0.2-0.2,0.4-0.3,0.7-0.3c-1.2,0-2.5,0.1-3.7,0.1c1.4,0.1,2.8,0.2,4.3,0.3c3.8-1.8,7.1-4.3,9.9-7.4
					c3.1-2.9,5.5-6.2,7.1-10.1c0.3-2.9-0.7-5.3-2.9-7.3c-2-2.1-4.5-3-7.4-2.7c-5.2,0.3-9.7,0.5-14.3,3.1c-3.5,1.9-6.6,5-7.9,8.9
					c-0.4,1.9-0.8,3.7-1.2,5.6c-0.3,2.8-0.3,5.6-0.2,8.5c0.2,1.8,0.4,3.6,0.5,5.5c0.6,1.6,1.2,3.2,1.8,4.8c0.3,2.9,1.8,4.7,4.5,5.4
					c2.8,0.7,5.6,0.6,8.4-0.3c2.7-0.9,4.1-2.8,4.2-5.7C1014,840.2,1013.9,836.6,1013.2,833.1z"/>
				<path opacity="0.1091" fill="#333333" d="M986.1,810.5c-0.5-1.2-1-2.5-1.5-3.7c0.5,2,1,3.9,1.5,5.9c-0.1-1.2-0.3-2.3-0.4-3.5
					c0,2.8,0.1,5.6,0.1,8.3c0.2-3.2,0.4-6.5,0.6-9.7c-0.7,5.3-1.4,10.7-2,16c0.7-3.2,1.4-6.3,2.1-9.5c-0.8,2.5-1.5,5-2.3,7.6
					c1.2-3.2,2.4-6.3,3.6-9.5c-1,2.3-2,4.6-3,7c1.6-2.9,3.2-5.8,4.8-8.6c-2.5,3.5-5,6.9-7.5,10.4c1.8-1.9,3.6-3.9,5.5-5.8
					c-1.4,1.1-2.7,2.2-4.1,3.3c2-1.5,4.1-3.1,6.1-4.6c3.8-3,7.1-6.5,9.9-10.3c2.1-1.9,2.9-4.1,2.2-6.5c0-2.2-0.9-4-2.7-5.4
					c-2-2.1-4.5-3-7.4-2.6c-3.2-0.2-5.8,0.8-7.8,2.9c-5.3,4.1-9.8,9.1-13.5,14.9c-1.4,4.7-2,9.4-1.7,14.3c-0.2,4.9,0.5,9.7,2.1,14.3
					c0.7,1.3,1.4,2.6,2,3.9c1.8,2.5,4.2,3.7,7.3,3.5c3.1,0,5.4-1.4,7-4c1.3-4.7,1.8-9.4,1.4-14.3C988.5,819.8,987.7,815,986.1,810.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M986.1,763.1c-0.4-0.6-0.9-1.2-1.3-1.9c1.8,10.6,3.5,21.2,5.3,31.8
					c-0.1-6.3-0.1-12.5-0.2-18.8c-1,9.9-2.1,19.8-3.1,29.8c1.5-10.4,3-20.8,4.5-31.2c-1.9,9.5-3.7,18.9-5.6,28.4
					c2.4-9.8,4.9-19.7,7.3-29.5c-2.7,8.7-5.5,17.4-8.2,26.1c3.4-9,6.9-18,10.3-27c-3.6,8-7.2,15.9-10.8,23.9
					c4.5-8.1,9-16.2,13.6-24.3c-4.3,6.9-8.7,13.8-13,20.7c5.3-7,10.6-13.9,15.9-20.9c1.4-2.4,2.8-4.7,4.2-7.1
					c0.1-1.6,0.2-3.3,0.3-4.9c-0.6-2.5-2.1-4.1-4.4-4.6c-1.4-1.1-2.9-1.2-4.4-0.3c-2.3,0.1-4.2,1.2-5.6,3.2
					c-10.5,7.5-13.3,19.4-8.5,35.6c-1.8,11-3.5,22-5.3,33c0.8,0.7,1.6,1.3,2.4,2c2.9,2.3,5.7,2.2,8.4-0.3c3.3-4.8,4.6-10.3,3.9-16.4
					c0.3-10.4,0-20.8-1-31.2C991.4,773.1,989.8,767.7,986.1,763.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1010.4,824.9c-0.6-9.5-1.1-19-1.7-28.5c0.4,5.6,0.8,11.2,1.2,16.8
					c0.2-3.7,0.3-7.5,0.5-11.2c-0.8,6.5-1.6,13-2.4,19.5c0.7-3.7,1.4-7.3,2.1-11c-0.9,3.2-1.8,6.4-2.6,9.5c1.1-3.6,2.2-7.3,3.3-10.9
					c-1.2,3-2.5,6.1-3.7,9.1c1.6-3.4,3.3-6.8,4.9-10.2c-1.6,2.9-3.2,5.8-4.9,8.8c2.1-3.2,4.2-6.4,6.4-9.5c-2,2.7-4,5.3-6,8
					c2.6-2.8,5.3-5.6,7.9-8.3c2.2-2.6,4.3-5.3,6.5-7.9c2.8-2.4,4.1-5.2,3.8-8.5c0.5-1.6,0.1-3-1.1-4.1c-0.9-2.1-2.6-3.4-5.1-3.8
					c-3.6,0-7,0.4-10.5,1.3c-2.8,0.9-5.3,2.2-7.6,4c-1.2,0.9-2.4,1.7-3.6,2.6c-2,3.5-3.4,7.3-4.2,11.2c-1,3.1-1.8,6.4-2.2,9.7
					c-0.9,3.9-1.3,7.9-1,12c1.4,2.9,2.8,5.8,4.2,8.6c1,1.1,2,2.2,3,3.3c2.1,1.3,4.2,1.4,6.3,0.5c1.6,0,2.8-0.8,3.6-2.3
					C1010,831.3,1010.9,828.4,1010.4,824.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1007.9,759.9c0.7,0.6,1.4,1.1,2.1,1.7c0,14.7,0.1,29.5,0.1,44.2
					c1.1-12,2.2-24,3.3-35.9c-2,10.6-4.1,21.2-6.1,31.7c2.6-10.9,5.3-21.8,7.9-32.7c-3.1,10.1-6.2,20.1-9.3,30.2
					c3.9-10.3,7.8-20.5,11.7-30.8c-4.2,9.5-8.4,19.1-12.6,28.6c5.1-9.5,10.2-19.1,15.2-28.6c-5.2,8.6-10.5,17.2-15.7,25.7
					c6.2-8.4,12.4-16.9,18.6-25.3c3.6-3.3,5.6-7.2,5.9-11.8c0.9-1.8,0.7-3.4-0.7-4.8c-0.7-2.4-2.6-3.5-5.4-3.4
					c-1.8,0.7-3.6,1.3-5.4,2c-2.6,2.4-5.1,4.7-7.7,7.1c-29.7,30-28.8,54.7-8.3,68.2c2.9,2.3,5.7,2.3,8.4-0.3
					c3.4-5,4.7-10.7,3.9-16.9c0.3-10.8,0-21.5-1-32.3C1013.2,770.3,1011.6,764.7,1007.9,759.9z"/>
				<path opacity="0.1091" fill="#333333" d="M969.4,794.3c-0.5-0.7-1-1.3-1.4-2c0.5,2.4,1,4.8,1.5,7.3c-0.5-3-1-5.9-1.5-8.9
					c0.4,5.7,0.8,11.5,1.3,17.2c0.2-4,0.4-8.1,0.6-12.1c-0.7,6.3-1.5,12.6-2.2,19c0.7-4,1.4-7.9,2.1-11.9c-0.8,2.9-1.6,5.8-2.4,8.7
					c1.3-3.8,2.5-7.7,3.8-11.5c-1.1,2.6-2.1,5.2-3.2,7.7c1.6-3.4,3.3-6.9,4.9-10.3c-2.6,3.7-5.2,7.4-7.7,11.1
					c2.2-2.4,4.5-4.8,6.7-7.3c-1.3,1.1-2.7,2.3-4,3.4c2.4-2,4.8-3.9,7.2-5.9c3.5-2.9,6.6-6.2,9.2-10c2-1.9,2.6-4,1.9-6.3
					c-0.1-2.1-1-3.9-2.9-5.3c-2-2.1-4.5-2.9-7.3-2.6c-7.8,1.3-14.2,5.4-19.1,12.3c-1,1.1-2,2.2-3.1,3.3c-1.3,3.9-2,7.9-2.1,12
					c-0.4,3.3-0.6,6.7-0.4,10.1c-0.2,4.1,0.2,8.1,1.2,12.1c0.8,2.3,2.1,4.4,3.8,6.1c1.5,3,4,4.5,7.3,4.5c3.4-0.2,5.7-1.9,7-5
					c1.3-5.9,1.7-11.9,1.3-17.9C971.9,806,971.1,800.1,969.4,794.3z"/>
				<path opacity="0.1091" fill="#333333" d="M995,834.3c-0.5-1.6-1.1-3.2-1.6-4.7c0.2,1.6,0.4,3.2,0.5,4.8c-0.3-3.1-0.7-6.2-1-9.3
					c-0.7,4.3-1.4,8.7-2.2,13c1.2-1.7,2.4-3.4,3.5-5.1c-0.7,1-1.4,2-2.1,3c1.4-1,2.9-1.8,4.6-2.2c1.8-0.7,3.6-1,5.6-0.8
					c2.9-1.2,5.3-3,7.2-5.6c2.2-2.3,3.6-5,4.3-8c-0.4-2.7-1.8-4.8-3.9-6.4c-2-1.9-4.3-2.8-7-2.8c-3.2,0.2-6.3,0.8-9.3,1.8
					c-2.7,0.6-5.2,1.5-7.7,2.8c-2.8,1.4-5.4,2.8-7.6,5.1c-2.2,2.4-3.2,5.8-3.2,9.1c0.1,1.6,0.1,3.3,0.2,4.9
					c0.4,3.6,1.4,7.1,2.9,10.5c0.4,2.7,1.9,4.3,4.5,4.8c2.8,0.6,5.6,0.5,8.4-0.3c2.6-0.7,4-2.4,4.2-5.1
					C995.8,840.6,995.7,837.4,995,834.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1021.6,817.2c1.4-8.7,2.8-17.4,4.2-26.2c-0.7,9.7-1.4,19.5-2.1,29.2
					c0.7-3.9,1.4-7.8,2.1-11.8c-2,6-3.9,12.1-5.9,18.1c7.5-12.6,14.9-25.2,22.4-37.7c0.2-1.7,0.4-3.4,0.6-5.1
					c-0.7-1.3-1.5-2.6-2.2-3.8c-2.1-1.8-4.3-2.2-6.6-1.1c-2.4,0.2-4.4,1.4-5.9,3.5c-2.9,2.2-5.5,4.6-7.7,7.4
					c-6.7,7.2-5.7,6.1,2.9-3.3c9.4-7.2,10.2-9.5,2.3-7c-1.5,0.8-2.9,1.5-4.4,2.3c-3.1,3.7-5.6,7.7-7.6,12c-2,3.4-3.7,6.9-5.1,10.6
					c-2.1,4.3-3.7,8.8-4.6,13.5c0.3,1.6,0.6,3.2,0.9,4.9c1.1,1,2.1,2,3.2,3c2.4,1.2,4.6,1,6.6-0.5
					C1018,823.8,1020.3,821.2,1021.6,817.2z"/>
				<path opacity="0.1091" fill="#333333" d="M976.2,778.2c0.6,0.3,1.2,0.6,1.8,0.9c0.4,7.4,0.8,14.8,1.2,22.2
					c0.2-5,0.3-9.9,0.5-14.9c-0.9,8.3-1.8,16.6-2.7,24.9c1.3-8.8,2.6-17.6,4-26.5c-1.6,7.8-3.2,15.7-4.8,23.5
					c2.2-8.3,4.4-16.6,6.6-25c-2.3,7.1-4.7,14.2-7,21.3c3-7.6,6.1-15.1,9.1-22.7c-3.1,6.4-6.1,12.8-9.2,19.3
					c2.4-3.9,4.7-7.7,7.1-11.6c-1.9,2.7-3.9,5.3-5.8,8c3.7-4.5,7.4-9,11.1-13.5c2.6-2.8,4.7-6,6.3-9.5c0-1.4,0-2.9-0.1-4.3
					c-0.7-2.3-2.2-3.9-4.3-4.6c-1.2-1.1-2.6-1.3-4.1-0.8c-3.1-0.1-5.6,1.3-7.6,4.1c-4.7,19-9.4,38-14.1,57c1.4,2.6,3.1,4.9,5.3,6.8
					c2.9,1.9,5.7,1.8,8.4-0.3c3.3-4,4.6-8.6,3.9-14c0.3-8.9,0.1-17.8-0.9-26.7C981.4,786.5,979.7,782,976.2,778.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1000,780.5c-1.5-2.2-3-4.4-4.4-6.5c1.6,13.9,3.3,27.7,4.9,41.6
					c0.6-13.9,1.2-27.8,1.8-41.7c-1.9,11.8-3.8,23.5-5.7,35.3c2.3-9,4.6-18.1,7-27.1c-2.6,8.1-5.2,16.2-7.9,24.4
					c3.4-8.4,6.7-16.9,10.1-25.3c-3.5,7.6-7,15.2-10.6,22.8c4.4-7.7,8.8-15.5,13.2-23.2c-4.3,6.8-8.7,13.6-13,20.3
					c5.3-6.8,10.6-13.6,15.9-20.4c1.4-2.4,2.9-4.8,4.3-7.2c0.1-1.7,0.3-3.3,0.4-5c-0.6-2.6-2-4.1-4.3-4.6c-1.4-1.1-2.9-1.2-4.5-0.3
					c-2.3,0.2-4.2,1.2-5.7,3.2c-9.8,6.9-12.6,17.9-8.4,32.9c-1.7,10.1-3.5,20.2-5.2,30.3c0.8,0.7,1.6,1.3,2.3,2
					c2.9,2,5.7,1.9,8.4-0.3c2.5-2.7,3.6-5.9,3.4-9.5c0.9-6.1,1.1-12.3,0.6-18.5C1002.6,795.9,1001.8,788.1,1000,780.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1024.8,821.7c-1-3.4-2.1-6.7-3.1-10.1c0.1,8.7,0.2,17.3,0.3,26
					c5.6-10.8,11.3-21.7,16.9-32.5c0.9-3.2,0.2-5.9-2.2-8.2c-1.8-1.9-3.8-2.5-6.1-1.8c-3.2,0-5.9,1.4-8,4.4
					c-5,4.5-9.5,9.3-13.5,14.6c5.9-6.3,11.8-12.7,17.7-19c-0.8,0.1-1.6,0.3-2.4,0.4c-2.2,0.3-4.3,0.9-6.4,1.8
					c-3.7,1.7-6.7,3.9-9.6,6.7c-2.1,4.2-3.3,8.6-3.7,13.3c-0.8,4.6-0.8,9.2,0.1,13.8c2.3,3.3,3.9,7,4.8,11.1
					c1.9,2.3,4.3,3.3,7.3,3.1c2.4,0.2,4.2-0.6,5.6-2.3c2.1-1.4,3-3.6,2.7-6.5C1025.7,831.6,1025.6,826.6,1024.8,821.7z"/>
				<path opacity="0.1091" fill="#333333" d="M971.7,811.2c-0.5-1.5-1-3-1.5-4.5c0.5,1.8,1,3.7,1.5,5.5c-0.1-1.4-0.3-2.7-0.4-4.1
					c-0.2,5.2-0.5,10.3-0.7,15.5c0.4-3.3,0.8-6.6,1.1-9.8c-0.5,2.4-0.9,4.8-1.4,7.3c0.7-3.1,1.5-6.2,2.2-9.2
					c-1.5,3.7-3,7.5-4.6,11.2c1.6-2.6,3.1-5.1,4.7-7.7c-0.9,1.3-1.9,2.6-2.8,3.9c1.8-2.3,3.6-4.5,5.4-6.8c-1.1,1.1-2.1,2.2-3.2,3.3
					c2.2-1.7,4.6-3,7.2-3.9c3.1-1.3,5.6-3.3,7.6-6c2.3-2.4,3.9-5.2,4.7-8.5c-0.3-2.7-1.6-4.9-3.8-6.6c-2-1.9-4.3-2.8-7.1-2.7
					c-2.2,0.4-4.4,0.7-6.6,1.1c-1.9,0.5-3.9,0.9-5.8,1.4c-1.1,1.1-2.1,2.2-3.2,3.3c-5.5,4.3-8.9,9.6-10,15.9
					c-1.2,4.8-1.1,9.8-0.7,14.7c-0.1,2.3,0.3,4.6,1,6.7c0.6,2.6,1.5,5.1,2.8,7.4c1.8,2.4,4.2,3.5,7.3,3.3c3,0,5.4-1.3,7-3.8
					c1.3-4.4,1.8-8.9,1.5-13.5C974.1,819.9,973.4,815.5,971.7,811.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1037.4,814.6c1.4-6.2,2.8-12.3,4.1-18.5c0.7-8.4,1.5-16.8,2.5-25.2
					c1.5-14.2,3.1-28.5,4.7-42.7c0.9-2.9,0.3-5.6-1.7-7.9c-1.9-2.4-4.5-3.4-7.5-3c-4.5,1.8-8.6,4.4-12.2,7.8
					c-3.9,3-7.1,6.5-9.7,10.7c-1.6,14.3-3.2,28.5-4.6,42.8c-0.6,5.6-1.2,11.1-1.6,16.7c-0.4,8.7-1.9,17.3-4.3,25.7
					c-0.9,2.9-0.3,5.5,1.7,7.9c1.9,2.4,4.3,3.5,7.4,3.2c4.4-1.7,8.4-4.1,11.9-7.2C1031.7,821.9,1034.9,818.5,1037.4,814.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1010.1,796.6c2.2-6.9,4.4-13.8,6.6-20.7c0.7-8.3,1.4-16.6,2.4-24.9
					c1.5-14.2,3.1-28.5,4.7-42.7c0.7-2.9,0-5.4-2-7.6c-1.8-2.4-4.2-3.5-7.2-3.3c-4.1,1.5-7.7,3.8-10.9,6.8c-3.5,2.7-6.3,5.9-8.5,9.7
					c-1.6,14.3-3.2,28.5-4.7,42.8c-0.6,5.5-1.1,11-1.5,16.5c-0.2,9.5-2.3,18.6-6.2,27.4c-1.5,5.2,3.9,11.3,9.1,11.1
					c3.9-1.3,7.3-3.3,10.4-6C1005.5,803.2,1008.1,800.2,1010.1,796.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1017.5,750.5c2.7-28.3,5.9-56.5,9.1-84.7c-0.5-3.3-2.3-5.4-5.4-6.4
					c-4.6-0.5-8.5,1-11.9,4.4c-6.2,4.5-12.1,9.4-17.5,14.8c-3.9,2.8-6,6.5-6.3,11c-3.1,27.9-6.3,55.8-9,83.7
					c0.5,3.3,2.2,5.4,5.3,6.5c4.5,0.6,8.5-0.8,11.9-4.1c6.2-4.3,12.1-9.1,17.5-14.4C1015.1,758.6,1017.3,755,1017.5,750.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1037.8,781.4c2.7-28.3,5.9-56.5,9.1-84.7c-0.5-3.2-2.3-5.4-5.4-6.4
					c-4.5-0.5-8.4,0.9-11.8,4.2c-6.2,4.4-11.9,9.3-17.3,14.6c-3.9,2.8-5.9,6.4-6.2,10.9c-3.1,27.9-6.2,55.7-8.9,83.6
					c0.5,3.2,2.3,5.4,5.3,6.5c4.4,0.6,8.4-0.7,11.7-4c6.2-4.2,11.9-8.9,17.3-14.1C1035.5,789.4,1037.6,785.8,1037.8,781.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1041.5,746.1c2.4-28.3,5.8-56.5,9-84.8c0-3.7-1.8-5.8-5.4-6.4
					c-5.9,0.4-10.9,2.7-15.2,7.1c-8.3,6.2-16.2,12.9-23.7,20.1c-5,3.5-8.2,8.1-9.5,13.8c-1.6,14.3-3.2,28.6-4.7,42.9
					c-0.5,5.6-1.2,11.3-1.9,16.9c-1.1,7.8-1.8,15.7-2.2,23.5c0,3.6,1.8,5.8,5.3,6.5c5.8-0.2,10.8-2.5,15.1-6.7
					c8.3-6,16.2-12.5,23.7-19.5C1036.9,756.2,1040.1,751.7,1041.5,746.1z"/>
				<path opacity="0.1091" fill="#333333" d="M994.7,781.6c2-6.9,3.9-13.7,5.9-20.6c1.4-8.5,2.5-17,3.3-25.5
					c1.5-14.2,3.1-28.4,4.7-42.6c0.4-2.8-0.4-5.3-2.3-7.4c-1.7-2.3-4-3.5-6.9-3.6c-3.7,1.2-7,3.1-9.8,5.8c-3.1,2.3-5.6,5.2-7.4,8.7
					c-1.6,14.2-3.2,28.4-4.7,42.6c-0.5,5.5-1.2,11-1.9,16.5c-0.8,9.6-2.8,18.9-5.9,28c-0.3,2.8,0.5,5.2,2.4,7.3
					c1.7,2.3,3.9,3.5,6.7,3.8c3.5-0.9,6.5-2.6,9.2-5.1C990.9,787.5,993.2,784.8,994.7,781.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1020.4,816.9c1.5-6.5,3-12.9,4.5-19.4c0.9-8.6,1.9-17.2,3-25.9
					c1.7-14.3,3.3-28.6,4.9-42.9c0.6-4.8-3.8-11.1-9-11.1c-3.4,0.9-6.4,2.5-9,4.9c-2.9,2.1-5.1,4.7-6.7,7.8
					c-1.6,14.3-3.2,28.6-4.8,42.9c-0.7,5.7-1.3,11.4-1.8,17.1c-0.6,9.2-2.1,18.3-4.6,27.3c-0.1,2.7,0.7,5.1,2.6,7.1
					c1.6,2.2,3.8,3.6,6.4,4c3.2-0.7,6-2.2,8.4-4.4C1017.1,822.3,1019.1,819.8,1020.4,816.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1054.9,776.3c2.6-28.3,5.8-56.5,9.1-84.8c-0.2-3.5-2-5.7-5.4-6.4
					c-5.5,0.1-10.3,2.2-14.3,6.3c-7.8,5.7-15.1,11.9-22.1,18.6c-4.7,3.3-7.6,7.6-8.6,13c-1.6,14.3-3.1,28.6-4.6,42.9
					c-0.5,5.6-1.2,11.3-1.9,16.9c-1,7.8-1.8,15.7-2.3,23.6c0.1,3.5,1.9,5.7,5.3,6.5c5.4,0,10.2-2,14.2-5.9c7.7-5.5,15-11.5,22-18
					C1051,785.9,1053.9,781.6,1054.9,776.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1006,767.6c1.1-6.4,2-12.7,2.6-19.1c0.7-6.3,1.3-12.6,1.9-18.9
					c-0.1,1.4-0.3,2.8-0.4,4.3c1.8-17.1,3.7-34.2,5.6-51.3c1.1-3,0.6-5.7-1.5-8.1c-2.1-2.4-4.6-3.4-7.8-2.8c-4.8,2.1-9.2,5-13.1,8.6
					c-4.2,3.3-7.7,7.1-10.6,11.5c-2.7,24.1-5.4,48.1-7.7,72.3c0.1-1.4,0.3-2.8,0.4-4.2c-0.7,5.4-1.4,10.8-2.1,16.2
					c-1.1,3-0.6,5.7,1.4,8.1c2,2.5,4.5,3.5,7.7,3c4.8-2,9.1-4.7,13-8.2C999.6,775.6,1003.2,771.9,1006,767.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1027.7,761c2.9-28.3,6-56.5,9.2-84.8c-0.4-3.3-2.2-5.5-5.4-6.4
					c-4.8-0.3-9,1.3-12.6,4.9c-6.7,4.8-12.9,10.1-18.7,15.9c-4.1,2.9-6.4,6.8-6.9,11.6c-3.1,27.9-6.3,55.8-9,83.7
					c0.4,3.3,2.2,5.5,5.3,6.5c4.8,0.4,8.9-1.1,12.5-4.6c6.6-4.6,12.9-9.7,18.7-15.3C1024.9,769.5,1027.2,765.8,1027.7,761z"/>
				<path opacity="0.1091" fill="#333333" d="M1050.9,794.3c2.7-28.3,5.9-56.5,9.1-84.7c-0.5-3.3-2.3-5.4-5.4-6.4
					c-4.6-0.5-8.7,1-12.1,4.5c-6.4,4.5-12.3,9.6-17.9,15.1c-4,2.8-6.1,6.5-6.5,11.2c-3.1,27.8-6.2,55.7-8.8,83.5
					c0.4,3.3,2.2,5.4,5.3,6.5c4.6,0.6,8.6-0.8,12-4.2c6.3-4.4,12.3-9.2,17.8-14.5C1048.4,802.5,1050.5,798.9,1050.9,794.3z"/>
				<path opacity="0.1091" fill="#333333" d="M998.1,795.4c1.5-6.5,3.1-13,4.6-19.5c0.9-8.6,1.9-17.3,3-25.9
					c1.7-14.3,3.3-28.6,4.9-42.8c0.2-2.7-0.7-5.1-2.5-7.2c-1.6-2.3-3.8-3.6-6.5-4c-3.3,0.7-6.1,2.3-8.6,4.5c-2.7,2-4.8,4.5-6.2,7.5
					c-1.6,14.3-3.2,28.6-4.9,42.9c-0.7,5.7-1.3,11.4-1.8,17.1c-0.6,9.3-2.1,18.4-4.6,27.3c0,2.7,0.9,5,2.7,7
					c1.6,2.2,3.7,3.6,6.3,4.1c3-0.6,5.7-1.9,8-4.1C995.1,800.6,996.9,798.3,998.1,795.4z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1036.9,686.8c2.7-7.8,4.2-15.9,4.6-24.1c0.6-7.6,1.4-15.2,2.2-22.8
					c0.9-2.9,0.3-5.6-1.7-7.9c-1.9-2.4-4.4-3.4-7.5-3c-4.5,1.8-8.6,4.4-12.1,7.7c-3.9,3-7.1,6.5-9.7,10.6
					c-0.9,7.5-1.6,15.1-2.2,22.6c-0.3,7.8-1.7,15.5-4.1,23c-0.9,2.9-0.3,5.5,1.7,7.9c1.9,2.4,4.3,3.5,7.3,3.3
					c4.4-1.7,8.4-4.1,11.9-7.2C1031.2,694.2,1034.4,690.8,1036.9,686.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1009.6,668.9c4.5-8.3,6.9-17,7.1-26.2c0.6-7.5,1.3-15.1,2.2-22.6c0.7-2.9,0-5.4-2-7.6
					c-1.8-2.4-4.2-3.5-7.2-3.3c-4.1,1.5-7.7,3.8-10.9,6.8c-3.5,2.7-6.3,5.9-8.5,9.6c-0.9,7.4-1.5,14.9-2,22.4
					c-0.1,8.6-2.1,16.9-5.9,24.8c-1.6,5.2,3.9,11.3,9,11.1c3.9-1.2,7.3-3.2,10.4-6C1005,675.4,1007.6,672.4,1009.6,668.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1017.1,622.8c1.4-15.1,3-30.1,4.6-45.2c-0.5-3.3-2.3-5.4-5.4-6.4
					c-4.6-0.5-8.5,1-11.9,4.4c-6.2,4.5-12.1,9.4-17.5,14.9c-3.9,2.8-6,6.5-6.3,11c-1.5,14.7-3.1,29.3-4.5,44
					c0.5,3.2,2.2,5.4,5.3,6.5c4.5,0.6,8.5-0.7,11.9-4.1c6.2-4.3,12.1-9.1,17.5-14.3C1014.7,630.9,1016.8,627.3,1017.1,622.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1037.4,653.6c1.4-15.1,3-30.1,4.6-45.1c-0.5-3.2-2.3-5.4-5.4-6.4
					c-4.5-0.5-8.4,0.9-11.8,4.2c-6.2,4.4-11.9,9.3-17.3,14.6c-3.9,2.8-5.9,6.4-6.2,10.9c-1.5,14.7-3.1,29.3-4.5,44
					c0.5,3.2,2.2,5.4,5.3,6.5c4.4,0.7,8.4-0.7,11.8-3.9c6.2-4.2,11.9-8.9,17.3-14.1C1035,661.7,1037.1,658.1,1037.4,653.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1041,618.4c1.3-15.1,3-30.2,4.5-45.3c0-3.7-1.8-5.8-5.4-6.4
					c-5.9,0.4-10.9,2.7-15.2,7.1c-8.3,6.2-16.2,12.9-23.7,20.1c-5,3.5-8.2,8.1-9.5,13.8c-0.7,7.6-1.5,15.2-2.5,22.7
					c-1,6.9-1.6,13.9-1.9,20.9c0,3.6,1.8,5.8,5.3,6.5c5.8-0.2,10.8-2.4,15.1-6.7c8.3-6,16.2-12.4,23.7-19.4
					C1036.4,628.5,1039.6,624,1041,618.4z"/>
				<path opacity="0.1091" fill="#333333" d="M994.2,653.9c3.7-8.5,6-17.3,6.9-26.4c1.2-7.5,2.1-15,2.6-22.6
					c0.4-2.8-0.4-5.3-2.3-7.4c-1.8-2.3-4.1-3.5-6.9-3.5c-3.7,1.2-7,3.1-9.8,5.8c-3.1,2.4-5.6,5.3-7.4,8.7c-0.6,7.4-1.4,14.7-2.4,22
					c-0.7,8.7-2.6,17.1-5.7,25.3c-0.3,2.8,0.5,5.2,2.4,7.3c1.7,2.3,3.9,3.6,6.6,3.8c3.5-0.9,6.5-2.6,9.2-5
					C990.4,659.7,992.6,657.1,994.2,653.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1019.9,689.1c2.9-8.2,4.6-16.7,5.2-25.3c0.9-7.7,1.7-15.5,2.6-23.2
					c0.6-4.8-3.9-11.1-9-11.1c-3.4,0.9-6.4,2.5-9,4.9c-2.9,2.1-5.1,4.7-6.6,7.8c-1,7.7-1.8,15.3-2.4,23c-0.5,8.3-1.9,16.5-4.3,24.6
					c-0.2,2.7,0.7,5.1,2.6,7.1c1.6,2.3,3.7,3.6,6.4,4c3.2-0.7,6-2.2,8.5-4.4C1016.6,694.6,1018.6,692.1,1019.9,689.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1054.5,648.6c1.3-15.1,3-30.2,4.6-45.2c-0.2-3.5-2-5.7-5.4-6.4
					c-5.5,0.1-10.3,2.2-14.3,6.3c-7.8,5.7-15.1,11.9-22,18.6c-4.7,3.3-7.6,7.6-8.6,13c-0.7,7.6-1.5,15.2-2.5,22.7
					c-0.9,7-1.6,14-2,21c0.1,3.5,1.9,5.7,5.3,6.5c5.4,0,10.2-1.9,14.2-5.9c7.7-5.5,15.1-11.4,22-17.9
					C1050.5,658.1,1053.4,653.9,1054.5,648.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1005.5,639.9c1.2-6.5,2.1-13,2.7-19.5c0.9-10.1,1.9-20.2,3-30.3
					c-0.8-3-2.6-5.2-5.4-6.4c-3.8-1-7.1,0-10.1,2.8c-5.1,3.5-9.7,7.4-14,11.8c-3.3,2.4-4.7,5.6-4.4,9.5c-1.1,10-2,20-3,30.1
					c0.2-1.4,0.3-2.8,0.5-4.2c-0.7,4.8-1.3,9.7-2,14.5c-1.1,3-0.6,5.7,1.4,8.1c2,2.5,4.5,3.5,7.7,3c4.8-2,9.1-4.7,13-8.2
					C999.1,647.9,1002.6,644.2,1005.5,639.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1027.3,633.3c1.5-15.1,3.2-30.2,4.7-45.3c-0.4-3.3-2.2-5.5-5.4-6.4
					c-4.8-0.3-9,1.3-12.6,4.9c-6.7,4.8-12.9,10.1-18.7,15.9c-4.1,2.9-6.4,6.8-6.9,11.6c-1.5,14.7-3.2,29.3-4.6,44
					c0.4,3.3,2.1,5.5,5.3,6.5c4.7,0.5,8.9-1.1,12.5-4.5c6.6-4.6,12.9-9.7,18.7-15.3C1024.4,641.8,1026.8,638,1027.3,633.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1050.4,666.6c1.4-15.1,3-30.1,4.6-45.2c-0.5-3.3-2.3-5.4-5.4-6.4
					c-4.6-0.5-8.7,1-12.1,4.5c-6.4,4.5-12.3,9.5-17.9,15c-4,2.8-6.1,6.5-6.5,11.2c-1.5,14.7-3.1,29.3-4.5,44
					c0.4,3.3,2.2,5.4,5.3,6.5c4.6,0.6,8.6-0.8,12-4.1c6.4-4.3,12.3-9.2,17.9-14.5C1047.9,674.8,1050,671.1,1050.4,666.6z"/>
				<path opacity="0.1091" fill="#333333" d="M997.6,667.7c2.9-8.3,4.7-16.7,5.3-25.4c0.9-7.7,1.7-15.5,2.6-23.2
					c0.2-2.7-0.7-5.1-2.6-7.1c-1.6-2.3-3.8-3.6-6.5-4c-3.2,0.7-6.1,2.3-8.6,4.5c-2.7,2-4.8,4.5-6.2,7.5c-1,7.7-1.8,15.3-2.4,23
					c-0.5,8.3-1.9,16.5-4.3,24.6c0,2.7,0.9,5,2.7,7c1.6,2.2,3.7,3.6,6.3,4.1c3-0.6,5.7-1.9,8-4C994.6,672.8,996.4,670.5,997.6,667.7
					z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1055.1,581.9c2.9-7.8,3.8-15.7,2.7-23.9c0.5-3-0.3-5.5-2.6-7.6
					c-2.1-2.2-4.7-3-7.7-2.4c-4.2,2.1-7.8,5-10.9,8.5c-3.4,3.2-6.2,6.9-8.2,11.1c1.1,7.7,0.4,15.2-2,22.6
					c-1.1,5.6,4.5,11.2,10.1,10.1c4.1-1.9,7.6-4.6,10.6-7.9C1050.5,589.5,1053.2,585.9,1055.1,581.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1028.6,563.4c4-8,5.3-16.4,3.8-25.1c0.4-2.9-0.5-5.4-2.8-7.4c-2.1-2.2-4.6-3-7.5-2.5
					c-4,2-7.4,4.6-10.3,7.9c-3.2,3-5.8,6.6-7.6,10.6c1.4,8.1,0.4,15.9-2.9,23.5c-1.3,5.3,5.1,10.9,10.1,10.1
					c3.8-1.7,7.1-4.1,9.9-7.2C1024.5,570.5,1026.9,567.2,1028.6,563.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1035.1,517.1c-0.1-7.1,0-14.3,0-21.4c-0.7-3.3-2.8-5.2-6.1-5.8
					c-4.8,0.1-8.8,2.2-11.9,6.1c-6.1,5.4-11.7,11.3-16.9,17.6c-3.8,3.3-5.7,7.3-5.6,12.2c-0.2,6.8-0.2,13.5,0,20.3
					c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8c6.1-5.2,11.8-10.9,17-17C1033.2,525.8,1035.1,521.8,1035.1,517.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1055.4,548.1c-0.2-7.1-0.2-14.2-0.1-21.3c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.2c-3.6,3.1-5.3,6.9-5,11.5c-0.2,6.8-0.1,13.5,0.1,20.3
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1053.9,556.3,1055.6,552.5,1055.4,548.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1059.3,512c-0.4-7.1-0.4-14.2-0.2-21.4c-0.3-3.7-2.3-5.6-6-5.9
					c-6,1.1-10.9,4.1-14.8,9c-7.9,7.3-15.4,15-22.5,23.2c-4.8,4.1-7.6,9.1-8.5,15.1c-0.5,6.6-0.4,13.3,0.3,19.9
					c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5C1055.5,522.9,1058.4,517.9,1059.3,512z"/>
				<path opacity="0.1091" fill="#333333" d="M1013.5,547.9c3.4-8,4.5-16.2,3.4-24.7c0.2-2.9-0.8-5.3-3-7.2c-2-2.1-4.5-3-7.3-2.7
					c-3.7,1.7-6.9,4.1-9.6,7.2c-3,2.8-5.3,6.1-6.9,9.9c1.2,7.9,0.3,15.6-2.5,23.2c-1.1,5.1,5.3,10.7,10.1,10.1
					c3.6-1.5,6.6-3.7,9.2-6.6C1009.9,554.6,1012,551.5,1013.5,547.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1038.3,583.8c2.5-8.1,3.4-16.3,2.7-24.7c0-4.7-5.1-10.6-10.1-10.1
					c-3.2,1.1-6,3-8.2,5.5c-2.6,2.3-4.4,5-5.6,8.2c0.8,8,0.3,15.9-1.7,23.7c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4
					c3-1,5.6-2.6,7.7-5C1035.6,589.4,1037.3,586.8,1038.3,583.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1072.3,543c-0.3-7.1-0.3-14.2-0.1-21.4c-0.5-3.5-2.5-5.4-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.5c-7.1,6.4-13.7,13.2-19.8,20.4c-4.3,3.7-6.7,8.2-7.2,13.6c-0.4,6.7-0.3,13.4,0.2,20
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1069.5,552.8,1071.9,548.3,1072.3,543z"/>
				<path opacity="0.1091" fill="#333333" d="M1024.5,529.8c0-7.1,0-14.2,0-21.3c-1-3-3-4.9-6.1-5.8c-4-0.5-7.4,1-10.1,4.2
					c-4.9,4.2-9.4,8.9-13.5,14c-3.2,2.9-4.4,6.3-3.9,10.3c0.1,6.8,0,13.7-0.1,20.5c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C1023.8,537.1,1025.1,533.7,1024.5,529.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1045.5,527.5c-0.1-7.1-0.1-14.3,0-21.4c-0.7-3.3-2.7-5.3-6.1-5.9
					c-5,0.3-9.1,2.4-12.3,6.4c-6.3,5.7-12.2,11.7-17.7,18.3c-3.9,3.4-5.9,7.6-6,12.5c-0.2,6.7-0.2,13.5,0,20.2
					c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1c6.3-5.5,12.2-11.4,17.7-17.7C1043.4,536.5,1045.4,532.4,1045.5,527.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1068.4,561c-0.2-7.1-0.2-14.2-0.1-21.3c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-11,10.4-15.8,16.2c-3.6,3.1-5.3,6.9-5,11.5c-0.2,6.8-0.1,13.5,0.1,20.3
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1066.9,569.3,1068.6,565.5,1068.4,561z"/>
				<path opacity="0.1091" fill="#333333" d="M1016.5,561.9c2.5-8.1,3.4-16.3,2.7-24.7c0-4.8-5-10.6-10.1-10.1
					c-3.3,1.2-6,3.1-8.3,5.7c-2.6,2.3-4.5,5.1-5.7,8.4c0.8,8,0.3,15.9-1.7,23.7c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3
					c3.1-1,5.7-2.7,7.9-5.2C1013.8,567.6,1015.5,565,1016.5,561.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1118.3,669.1c3-8.9,5.2-18,6.6-27.2c1.6-8.9,3.2-17.9,4.8-26.8
					c3.3-17.9,6.6-35.9,9.9-53.8c1.2-2.9,0.9-5.6-1-8.1c-1.8-2.6-4.2-3.7-7.4-3.5c-4.8,1.6-9.1,4-13.1,7.1
					c-4.2,2.8-7.9,6.2-10.9,10.2c-3.3,17.9-6.6,35.9-9.9,53.8c-1.1,6-2.2,12-3.3,18c-1.6,11.8-4.1,23.4-7.5,34.8
					c-1.2,2.8-0.9,5.5,0.9,8.1c1.7,2.6,4.1,3.8,7.2,3.7c4.7-1.4,9-3.6,12.9-6.6C1111.7,676.2,1115.3,673,1118.3,669.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1090.2,651.5c5.5-9.3,8.9-19.2,10.1-29.8c1.5-8.9,3.1-17.7,4.7-26.6
					c3.3-18,6.6-35.9,9.9-53.8c0.9-2.8,0.4-5.4-1.5-7.8c-1.7-2.5-4-3.8-6.9-3.8c-4.2,1.2-7.9,3.1-11.3,5.8c-3.7,2.4-6.7,5.3-9.1,8.9
					c-3.3,17.9-6.6,35.9-9.9,53.8c-1.1,6-2.1,12-3.2,18c-1,12.9-4.3,25.1-10.1,36.7c-1.9,5,3,11.6,8.1,11.8
					c3.9-0.9,7.5-2.6,10.7-5.1C1085.2,657.5,1088,654.8,1090.2,651.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1098.3,605.6c6.3-35.6,13-71.2,19.5-106.8c-0.3-3.2-2-5.5-4.9-6.8
					c-4.3-0.9-8.3,0.1-11.8,3.2c-6.3,3.8-12.2,8.1-17.7,12.9c-4,2.4-6.2,5.8-6.6,10.2c-6.4,35.2-13,70.4-19.4,105.6
					c0.3,3.2,1.9,5.5,4.8,6.9c4.3,1.1,8.2,0.1,11.7-2.8c6.3-3.6,12.2-7.7,17.8-12.3C1095.6,613.3,1097.8,610,1098.3,605.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1118.6,636.4c6.3-35.6,13-71.2,19.5-106.8c-0.3-3.3-1.9-5.5-4.9-6.8
					c-4.5-0.9-8.5,0.2-12.1,3.4c-6.5,3.9-12.6,8.3-18.4,13.3c-4.1,2.5-6.4,5.9-7,10.4c-6.4,35.2-13.1,70.3-19.4,105.5
					c0.2,3.3,1.8,5.6,4.8,6.9c4.4,1,8.4,0,12-3c6.5-3.7,12.6-7.9,18.4-12.7C1115.7,644.3,1118,640.9,1118.6,636.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1122,601.7c6.1-35.7,13-71.3,19.5-107c0.2-3.6-1.5-5.9-4.9-6.8
					c-5.7-0.2-10.8,1.7-15.3,5.7c-8.5,5.4-16.7,11.3-24.4,17.7c-5.1,3-8.5,7.3-10.1,12.7c-3.3,17.9-6.6,35.9-9.9,53.8
					c-1.1,6-2.2,12-3.3,17.9c-2.5,11.1-4.5,22.2-6.2,33.4c-0.2,3.6,1.4,5.9,4.8,6.9c5.6,0.3,10.7-1.4,15.2-5.2
					c8.5-5.1,16.6-10.7,24.4-16.9C1116.9,611.2,1120.3,607.1,1122,601.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1077.3,634.9c3.7-9.4,6.3-19,7.9-29c1.7-8.9,3.4-17.9,5.1-26.8
					c3.3-17.9,6.5-35.9,9.8-53.8c0.6-2.8,0-5.3-1.8-7.5c-1.5-2.4-3.7-3.8-6.4-4.2c-3.7,0.7-7,2.3-9.9,4.6c-3.2,2-5.8,4.5-7.7,7.7
					c-3.3,17.9-6.5,35.9-9.8,53.9c-1.1,5.9-2.2,11.7-3.3,17.6c-1.6,12.7-4.4,25.1-8.6,37.3c-1.3,4.7,3.3,11.4,8.2,11.8
					c3.5-0.6,6.6-2,9.4-4.2C1073.1,640.4,1075.5,638,1077.3,634.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1101.1,671.7c3.7-9.4,6.4-19,7.9-29c1.7-9,3.5-18.1,5.2-27.1c3.4-18,6.7-35.9,10-53.9
					c0.9-4.8-2.8-11.4-8.1-11.8c-3.5,0.6-6.8,2.1-9.6,4.3c-3.1,1.9-5.6,4.4-7.5,7.4c-3.3,18-6.5,35.9-9.9,53.9
					c-1.1,6-2.3,12-3.4,18.1c-1.6,12.7-4.4,25.1-8.6,37.3c-0.4,2.7,0.2,5.2,1.9,7.4c1.4,2.4,3.5,3.9,6.2,4.4c3.3-0.5,6.4-1.8,9-3.9
					C1097.1,677,1099.4,674.6,1101.1,671.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1135.9,631.4c6.2-35.7,13-71.3,19.5-106.9c0.1-3.6-1.5-5.8-4.9-6.8
					c-5.5-0.3-10.5,1.5-14.8,5.3c-8.2,5.2-16.1,10.8-23.6,17c-5,2.9-8.2,7-9.7,12.4c-3.3,17.9-6.6,35.9-9.9,53.8
					c-1.1,6-2.2,12-3.3,17.9c-2.4,11.1-4.5,22.2-6.2,33.4c-0.2,3.5,1.4,5.8,4.8,6.9c5.5,0.4,10.4-1.2,14.7-4.8
					c8.2-4.9,16.1-10.3,23.6-16.3C1131.1,640.7,1134.4,636.6,1135.9,631.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1085.8,623c2.9-8.9,5-17.9,6.4-27.1c1.6-8.9,3.2-17.9,4.8-26.8
					c3.3-17.9,6.6-35.9,9.9-53.8c1.2-2.9,0.9-5.6-1-8.1c-1.8-2.6-4.3-3.7-7.4-3.5c-4.8,1.6-9.2,4-13.2,7.2c-4.3,2.8-7.9,6.2-11,10.3
					c-3.3,17.9-6.6,35.9-9.9,53.8c-1.1,6-2.2,12-3.3,18c-1.6,11.8-4.1,23.3-7.4,34.7c-1.2,2.9-0.9,5.5,0.9,8.1
					c1.7,2.6,4.1,3.8,7.2,3.7c4.7-1.5,9.1-3.7,13-6.7C1079.2,630.2,1082.8,626.9,1085.8,623z"/>
				<path opacity="0.1091" fill="#333333" d="M1108.4,616.2c6.6-35.6,13.2-71.3,19.7-107c-0.2-3.3-1.8-5.6-4.9-6.8
					c-4.7-0.8-8.9,0.5-12.6,3.7c-6.8,4.2-13.3,8.8-19.4,14c-4.3,2.5-6.8,6.2-7.5,10.8c-3.3,17.9-6.6,35.9-9.9,53.8
					c-3.1,17.2-6.5,34.5-9.7,51.7c0.1,3.3,1.7,5.6,4.8,6.9c4.6,0.9,8.8-0.2,12.5-3.4c6.8-3.9,13.2-8.4,19.3-13.4
					C1105.1,624.3,1107.6,620.8,1108.4,616.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1131.6,649.4c6.6-35.6,13.2-71.3,19.7-107c-0.2-3.3-1.8-5.6-4.9-6.8
					c-4.7-0.8-8.9,0.5-12.7,3.7c-6.8,4.2-13.3,8.8-19.4,14c-4.3,2.5-6.8,6.1-7.5,10.8c-3.3,17.9-6.6,35.9-9.9,53.8
					c-3.1,17.2-6.5,34.4-9.7,51.7c0.1,3.3,1.7,5.6,4.8,6.9c4.6,0.9,8.8-0.2,12.6-3.3c6.8-3.9,13.3-8.4,19.4-13.4
					C1128.2,657.6,1130.8,654.1,1131.6,649.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1078.3,650.5c3.9-9.4,6.6-19.1,8.1-29.1c1.7-9.1,3.5-18.1,5.2-27.1
					c3.4-18,6.7-35.9,10-53.9c0.3-2.7-0.3-5.1-2-7.3c-1.4-2.4-3.5-3.9-6.1-4.5c-3.2,0.4-6.1,1.7-8.7,3.7c-2.8,1.7-5,4-6.6,6.8
					c-3.3,18-6.5,35.9-9.9,53.9c-1.1,6-2.3,12-3.4,18.1c-1.6,12.7-4.4,25.1-8.6,37.3c-0.2,2.7,0.6,5,2.2,7.2
					c1.4,2.3,3.4,3.9,5.9,4.6c3-0.3,5.6-1.4,8-3.2C1075,655.3,1077,653.2,1078.3,650.5z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1137.4,495.7c1.5-4.9,2.9-9.9,4.4-14.8c1.1-6.6,2.7-13,4.8-19.3
					c1.2-4.3,2.6-8.5,4.3-12.6c2.2-6.2,4.8-12.2,7.7-18c1.4-2.8,1.3-5.5-0.4-8.2c-1.6-2.7-4-4-7.2-4c-5,1.3-9.6,3.4-13.8,6.4
					c-4.5,2.5-8.4,5.7-11.8,9.6c-1.6,4.3-3.3,8.5-4.9,12.8c-2.4,6.5-4.4,13.1-6,19.8c-1.3,4.3-2.3,8.8-3,13.2
					c-1.2,7-2.9,13.9-5.1,20.7c-1,2.9-0.6,5.5,1.4,7.9c1.8,2.5,4.2,3.7,7.3,3.5c4.5-1.6,8.6-3.9,12.3-7
					C1131.3,503,1134.6,499.6,1137.4,495.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1109.8,477.9c2.5-5.8,4.9-11.5,7.4-17.3c1.1-6.5,2.7-12.8,4.8-19
					c1.2-4.3,2.6-8.5,4.4-12.6c2.2-6.2,4.9-12.2,7.9-18.1c2.3-5.1-2.2-11.8-7.6-12.1c-4.2,0.9-8.1,2.5-11.6,5
					c-3.8,2.1-7,4.8-9.6,8.2c-1.8,4.3-3.7,8.6-5.5,12.9c-2.7,6.5-4.9,13.2-6.7,20c-1.4,4.3-2.5,8.7-3.2,13.1
					c-1.2,7.9-3.8,15.5-7.6,22.7c-1.9,5,3.7,11.4,8.7,11.4c3.9-1.1,7.4-3,10.5-5.6C1104.9,484.2,1107.7,481.3,1109.8,477.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1117.5,431.9c1.1-8.9,2.8-17.7,5.4-26.3c-0.4,1.5-0.8,3.1-1.2,4.6
					c1.7-6.5,3.8-12.8,6.4-19c2.2-6.2,4.9-12.3,7.9-18.2c1.9-2.8,1.9-5.7,0.2-8.6c-1.9-2.8-4.5-4-7.8-3.5
					c-5.8,1.9-11.2,4.5-16.2,7.9c-5.3,3-10,6.8-14.2,11.1c-3.2,6.7-6,13.6-8.4,20.6c-2.6,6.9-4.9,13.9-6.7,21
					c-0.4,1.5-0.8,3.1-1.2,4.6c-2.4,8.7-4.2,17.5-5.2,26.5c0.4,3.2,2.1,5.5,5.1,6.7c4.4,0.8,8.3-0.4,11.8-3.6
					c6.3-4,12.1-8.5,17.6-13.5C1114.9,439.8,1117.1,436.3,1117.5,431.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1137.8,462.7c1.1-8.9,2.9-17.7,5.4-26.3c-0.4,1.6-0.9,3.1-1.3,4.7
					c1.7-6.5,3.8-12.8,6.3-19.1c2.2-6.2,4.8-12.3,7.8-18.2c2-2.8,2.2-5.8,0.5-8.8c-2-2.8-4.7-4-8.1-3.4c-6.1,2.1-11.9,4.9-17.3,8.6
					c-5.6,3.3-10.7,7.2-15.3,11.8c-2.5,5.9-4.8,11.8-6.7,17.9c-1.3,3.7-2.5,7.5-3.6,11.3c-1.6,5.3-3.1,10.7-4.3,16.1
					c-2.2,8.6-3.8,17.3-4.8,26.1c0.4,3.2,2.1,5.5,5.1,6.7c4.4,0.8,8.4-0.4,11.9-3.6c6.3-4,12.2-8.5,17.8-13.5
					C1135.2,470.7,1137.4,467.2,1137.8,462.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1141.3,427.7c0.7-4.4,1.3-8.8,2-13.2c1.4-6.6,3.1-13,5.3-19.4
					c1.2-4.2,2.6-8.4,4.4-12.4c2.2-6.1,4.8-12,7.9-17.8c0.4-3.5-1.1-5.9-4.5-7.1c-5.5-0.6-10.6,0.9-15.2,4.4
					c-8.6,4.7-16.9,9.9-24.8,15.6c-5.2,2.6-8.7,6.6-10.5,11.8c-1.8,4.7-3.6,9.4-5.4,14.1c-2.5,7-4.7,14.1-6.5,21.4
					c-1.3,4.6-2.5,9.3-3.5,14c-1.7,6.3-2.8,12.8-3.2,19.3c-0.1,3.6,1.6,5.8,5.1,6.7c5.7,0,10.8-2,15.1-6.1
					c8.4-5.6,16.4-11.8,24-18.4C1136.5,437.5,1139.8,433.2,1141.3,427.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1094.3,463c2.2-5.7,4.4-11.4,6.7-17.1c1.8-6.8,3.8-13.6,6.2-20.2
					c1.3-4.3,2.8-8.5,4.6-12.6c2.3-6.2,5-12.2,8-18c0.7-2.7,0.2-5.2-1.4-7.5c-1.3-2.5-3.4-4.1-6.1-4.7c-3.6,0.5-6.9,1.7-9.9,3.8
					c-3.2,1.7-5.8,4.1-7.9,7c-2,4.3-3.9,8.6-5.9,12.8c-2.8,6.6-5.3,13.3-7.4,20.1c-1.6,4.5-2.9,9.1-4,13.7c-2,8-4.5,15.8-7.5,23.5
					c-0.3,2.7,0.4,5.2,2.2,7.3c1.6,2.3,3.7,3.7,6.5,4.1c3.4-0.8,6.4-2.3,9.1-4.6C1090.4,468.7,1092.7,466.1,1094.3,463z"/>
				<path opacity="0.1091" fill="#333333" d="M1120.3,498.1c1.7-5.3,3.5-10.6,5.2-15.9c1.4-6.8,3.2-13.4,5.6-19.8
					c1.3-4.3,2.9-8.5,4.7-12.6c2.3-6.1,5-12.1,8.1-17.9c0.7-2.7,0.3-5.2-1.3-7.6c-1.3-2.5-3.4-4.1-6.1-4.6c-3.6,0.5-7,1.8-10,3.9
					c-3.3,1.7-6,4.1-8.1,7.1c-1.8,4.1-3.6,8.2-5.4,12.3c-2.7,6.4-5,13-6.9,19.6c-1.6,4.4-2.7,8.8-3.6,13.4
					c-1.5,7.5-3.5,14.9-5.9,22.2c-0.3,2.7,0.5,5.1,2.3,7.2c1.5,2.3,3.6,3.7,6.3,4.2c3.3-0.6,6.2-2,8.7-4.2
					C1116.7,503.5,1118.8,501.1,1120.3,498.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1155,457.7c1-9,2.8-17.8,5.4-26.4c2.4-8.6,5.5-17,9-25.1c-0.8,1.7-1.6,3.5-2.4,5.2
					c2.7-5.7,5.4-11.4,8.1-17.1c0.4-3.5-1.1-5.9-4.5-7.1c-5.5-0.6-10.6,0.8-15.1,4.3c-8.6,4.6-16.8,9.8-24.7,15.5
					c-5.2,2.6-8.7,6.5-10.5,11.7c-1.6,4.5-3.1,9.1-4.7,13.6c-2.2,6.8-4.1,13.8-5.7,20.8c-1.2,4.6-2.2,9.2-3.2,13.8
					c-1.6,6.3-2.6,12.7-3.1,19.2c0,3.5,1.7,5.8,5.1,6.7c5.4,0.2,10.2-1.6,14.4-5.5c7.9-5.2,15.5-11,22.7-17.3
					C1150.7,467.1,1153.7,463,1155,457.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1105.6,449.1c2.7-11.2,4.9-22.5,8.4-33.5c1.2-4.3,2.7-8.5,4.4-12.7
					c2.3-6.2,4.9-12.3,8-18.2c2.6-5.5-1.5-12-7.6-12.1c-4.8,1.2-9.2,3.2-13.2,6c-4.3,2.4-8,5.5-11.2,9.3c-2,4.5-3.9,8.9-5.9,13.4
					c-2.8,6.7-5.2,13.6-7.2,20.6c-1.5,4.5-2.7,9-3.6,13.7c-1.5,6.7-3,13.5-4.5,20.3c-1.1,2.9-0.7,5.6,1.2,8.1
					c1.9,2.5,4.4,3.6,7.5,3.3c4.8-1.8,9.1-4.3,13-7.6C1099,456.8,1102.6,453.3,1105.6,449.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1127.6,442.4c1.2-8.9,3-17.7,5.5-26.4c-0.5,1.6-1,3.2-1.5,4.8
					c1.8-5.9,3.7-11.7,5.5-17.6c-0.5,1.6-1,3.2-1.6,4.8c2.9-8.3,6.3-16.5,10.2-24.4c2.1-2.8,2.3-5.8,0.6-8.9c-2-2.9-4.8-3.9-8.3-3.3
					c-6.3,2.2-12.2,5.2-17.8,8.9c-5.8,3.4-11.1,7.4-15.8,12.2c-2.7,6-5.1,12.1-7.2,18.3c-1.3,3.8-2.6,7.7-3.9,11.6
					c-1.8,5.4-3.3,10.9-4.6,16.5c-2.3,8.7-4,17.5-5.1,26.4c0.3,3.3,2,5.5,5.1,6.7c4.7,0.6,8.9-0.7,12.5-4.1c6.7-4.3,13-9.2,19-14.5
					C1124.6,450.8,1127,447.1,1127.6,442.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1150.7,475.8c1.2-9,3.1-17.8,5.6-26.5c-0.5,1.6-1,3.2-1.4,4.8
					c1.8-5.9,3.7-11.7,5.5-17.6c-0.5,1.6-1,3.2-1.6,4.7c2.9-8.3,6.3-16.5,10.2-24.4c0.6-1.6,1.3-3.1,1.9-4.7
					c-0.4-1.4-0.7-2.9-1.1-4.3c-1.1-0.9-2.3-1.9-3.4-2.8c-3.2-1-6.3-0.5-9,1.3c-7,2.9-13.3,6.9-19.6,11.1c-3,1.7-5.8,3.8-8.3,6.3
					c-2.9,1.7-4.6,4.2-5.2,7.5c-1.5,4.4-3.1,8.8-4.6,13.2c-2.2,6.7-4.1,13.5-5.6,20.3c-1.2,4.5-2.2,9-3.1,13.6
					c-1.5,6.4-2.6,12.9-3.3,19.4c0.3,3.3,2,5.5,5.1,6.7c4.6,0.7,8.7-0.6,12.2-3.8c6.5-4.2,12.7-8.9,18.5-14.1
					C1147.8,484,1150.1,480.4,1150.7,475.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1097.8,476.8c1.8-5.4,3.6-10.7,5.3-16.1c1.4-6.7,3.2-13.3,5.5-19.8
					c1.3-4.3,2.8-8.5,4.6-12.6c2.3-6.2,5-12.1,8-18c0.5-2.7-0.1-5.1-1.7-7.4c-1.3-2.4-3.2-4-5.8-4.9c-3.2,0.2-6.1,1.3-8.8,3.1
					c-2.9,1.5-5.1,3.6-6.8,6.4c-1.9,4.2-3.9,8.5-5.8,12.7c-2.8,6.5-5.2,13.1-7.2,19.8c-1.6,4.4-2.9,8.9-3.7,13.5
					c-1.6,7.5-3.6,15-6,22.3c-0.1,2.7,0.8,5,2.5,7.1c1.5,2.3,3.6,3.7,6.2,4.3c3-0.5,5.7-1.7,8-3.7
					C1094.6,481.8,1096.5,479.6,1097.8,476.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1144,303.3c-0.7-5.7-1.4-11.4-2.1-17.2c-1.6-6-3.1-12.1-4.7-18.1
					c-1.1-3.9-2-7.9-2.7-11.9c-1.3-5.9-2.3-11.9-3-17.9c-0.8-5.3-8-8.9-12.7-6.6c-3,2.6-5.4,5.8-7.1,9.5c-2.1,3.5-3.3,7.2-3.7,11.2
					c1.9,10.3,4,20.5,6.8,30.5c1.1,3.9,2.2,7.8,3.4,11.7c2.3,7.1,3.8,14.4,4.5,21.8c1,2.6,2.8,4.4,5.5,5.4c2.5,1.3,5.1,1.4,7.6,0.4
					c2.7-2.4,4.6-5.3,5.9-8.7C1143.3,310.3,1144.1,306.9,1144,303.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1118.3,284.4c-0.7-5.6-1.3-11.2-2-16.8c-2.2-5.6-4-11.4-5.3-17.3c-1.1-4-2-8-2.7-12.1
					c-1.3-6-2.3-12.1-3-18.2c-0.8-5.6-7.6-9.3-12.7-6.6c-3.2,3-5.8,6.6-7.7,10.7c-2.3,3.9-3.7,8-4.3,12.4
					c1.8,10.7,3.8,21.3,6.6,31.8c0.9,4.1,2.1,8,3.7,11.9c2.8,6.7,4.2,13.8,4.4,21.2c0.8,5.5,8.6,8.4,13.1,5.7
					c3-3.1,5.2-6.6,6.7-10.6C1117,292.7,1118,288.6,1118.3,284.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1122.2,236.6c-1.4-3.8-2.9-7.7-4.3-11.5c-1.9-5.9-3.6-11.9-5-18c-1.1-4-2-8-2.6-12
					c-1.3-6-2.2-12-2.9-18c-1.6-3.1-4.1-4.4-7.5-3.8c-4.8,1.9-8.3,5.4-10.3,10.5c-4.7,7.7-8.8,15.8-12.4,24.1
					c-2.9,4.6-3.8,9.4-2.5,14.5c2.2,11,4.5,22,7.6,32.8c1.1,4.2,2.4,8.4,3.7,12.6c1.6,5.8,3.7,11.4,6.2,16.9c1.8,3,4.3,4.1,7.7,3.4
					c4.7-2.3,8-6.1,9.7-11.3c4.2-8.1,7.9-16.5,10.9-25.2C1123.2,246.5,1123.8,241.6,1122.2,236.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1142.4,268.6c-1.5-3.8-2.9-7.5-4.4-11.3c0.4,1.5,0.9,2.9,1.3,4.4
					c-1.6-5.4-3.3-10.9-4.9-16.3c0.4,1.5,0.9,3,1.3,4.4c-1.7-6-3.2-12-4.3-18.1c-1.3-6-2.3-12-3-18.1c0.2-3.4-1.2-5.9-4.2-7.5
					c-3-1.5-5.9-1.2-8.6,0.9c-4,4.5-7.4,9.6-10,15.1c-3,5.3-5.2,10.9-6.6,16.9c1.9,9.3,3.8,18.4,6.2,27.5c1.3,4.9,2.7,9.8,4.3,14.7
					c2.3,7.7,5,15.4,8.1,22.8c1.9,2.6,4.5,3.7,7.7,3.4c4.1-1.2,6.8-3.9,8.3-8.1c3.3-6.1,6.1-12.4,8.3-19
					C1144.2,276.2,1144.3,272.4,1142.4,268.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1145.1,231.3c-1.5-3.8-3-7.6-4.4-11.4c-1.9-6-3.5-12-4.9-18.1c-1.1-4-1.9-8-2.6-12.1
					c-1.2-6-2.1-12-2.7-18.1c-1.4-3.5-3.8-4.8-7.5-3.9c-5.5,2.9-9.4,7.3-11.8,13.3c-5.6,9.5-10.7,19.4-15.2,29.5
					c-3.5,5.4-4.8,11.2-4,17.3c2.5,10.9,5.1,21.7,8.3,32.4c1.2,4.2,2.5,8.3,3.9,12.5c1.6,5.7,3.7,11.2,6.4,16.4
					c1.6,3.4,4.2,4.5,7.7,3.4c5.2-3.2,8.8-7.8,10.8-13.9c5-9.8,9.3-19.8,13.1-30.1C1145.4,243.1,1146.4,237.3,1145.1,231.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1102.4,268.6c-0.9-4.9-1.9-9.9-2.8-14.8c-2.1-5.8-3.9-11.7-5.2-17.7
					c-1.1-4-2-8.1-2.7-12.2c-1.3-6-2.2-12.1-2.9-18.3c-0.8-5.7-7.6-9.4-12.7-6.6c-3.3,3.2-5.9,6.9-7.8,11.1c-2.3,4-3.8,8.3-4.5,12.8
					c0.7,4.4,1.4,8.7,2,13.1c1.1,6.5,2.5,13,4.3,19.4c0.9,4.2,2.1,8.3,3.6,12.3c2.4,6.4,4,13,4.9,19.9c1.2,5.7,8.1,8.9,13.1,5.7
					c3.1-3.4,5.5-7.3,7.1-11.6C1100.9,277.6,1102.1,273.2,1102.4,268.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1127.2,303.6c-1-5-2.1-9.9-3.1-14.9c-2.4-5.7-4.2-11.5-5.4-17.5
					c-1.1-4-1.9-8.1-2.6-12.2c-1.2-6-2.2-12.1-2.8-18.2c-1-2.5-2.8-4.3-5.2-5.4c-2.3-1.4-4.8-1.9-7.4-1.4c-2.5,1.7-4.5,3.9-5.7,6.7
					c-1.6,2.6-2.4,5.4-2.4,8.5c1.6,10.5,3.5,20.9,6,31.2c0.8,4.1,2,8.1,3.6,11.9c2.6,6.4,4.4,12.9,5.7,19.7c1.3,2.3,3.2,3.9,5.7,4.8
					c2.4,1.2,4.8,1.6,7.4,1c2.3-1.6,3.9-3.7,4.9-6.4C1127.1,309,1127.5,306.4,1127.2,303.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1158.3,264.6c-3.1-7.7-5.7-15.6-7.8-23.7c-2.2-8-3.9-16.1-5.2-24.3
					c0.3,1.7,0.5,3.4,0.8,5.1c-0.8-5.8-1.6-11.5-2.5-17.3c-1.6-3-4.1-4.3-7.5-3.9c-4.7,1.7-8,4.9-10,9.7
					c-4.5,7.2-8.4,14.8-11.7,22.6c-2.8,4.4-3.5,8.9-2.2,13.7c2.5,10.5,5.1,20.9,8.2,31.2c1.2,4.1,2.5,8.1,3.9,12.1
					c1.8,5.7,3.8,11.3,6.2,16.8c1.8,2.8,4.4,3.9,7.7,3.4c4.5-1.8,7.5-5.1,9.1-9.8c3.8-7.2,7.1-14.6,9.8-22.3
					C1159.6,273.5,1160,269,1158.3,264.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1111.4,250.4c-3.1-7.6-5.8-15.4-7.8-23.4c0.4,1.5,0.8,3.1,1.2,4.6
					c-1.4-5.6-2.8-11.2-4.2-16.7c0.4,1.5,0.8,3.1,1.1,4.6c-1.8-8-3.2-16.1-4.2-24.2c0.2-3.4-1.2-6-4.1-7.6c-3.1-1.5-5.9-1.1-8.6,1.1
					c-4.1,4.7-7.5,9.9-10.1,15.6c-3.1,5.5-5.3,11.2-6.8,17.3c1.8,10.4,3.7,20.7,6.3,30.9c1.2,4.3,2.4,8.6,3.5,12.9
					c2.2,7.9,4.8,15.6,8,23.2c1.9,2.6,4.5,3.8,7.7,3.4c4.2-1.4,7.1-4.4,8.6-8.8c3.5-6.5,6.5-13.3,8.8-20.4
					C1113,258.6,1113.2,254.5,1111.4,250.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1132.4,247.8c-1.4-3.8-2.9-7.7-4.3-11.5c-1.9-5.9-3.6-12-5-18.1c-1.1-4-2-8-2.6-12.1
					c-1.3-6-2.2-12-2.9-18.1c-1.6-3-4.1-4.3-7.5-3.9c-4.8,1.8-8.1,5.2-10.1,10.1c-4.6,7.5-8.6,15.3-12,23.3
					c-2.9,4.5-3.7,9.2-2.4,14.1c2.3,10.9,4.7,21.6,7.8,32.3c1.2,4.2,2.4,8.3,3.8,12.4c1.7,5.8,3.7,11.4,6.2,16.9
					c1.8,2.9,4.4,4,7.7,3.4c4.6-2.1,7.8-5.7,9.4-10.7c4.1-7.7,7.5-15.7,10.4-24C1133.5,257.4,1134,252.6,1132.4,247.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1156.6,286.1c-3.2-9.9-6.6-19.8-9.2-29.9c-1.1-4-2-8-2.8-12.1c-1.3-6-2.3-12-3.1-18.1
					c0-3.3-1.5-5.7-4.3-7.2c-2.9-1.5-5.7-1.3-8.4,0.6c-3.8,4.1-6.9,8.6-9.3,13.7c-2.8,4.9-4.8,10-5.9,15.5
					c2.2,10.4,4.5,20.7,7.5,30.9c2.9,9.9,6.4,19.6,9.8,29.3c0.3,3.2,2,5.4,4.9,6.7c2.9,1.3,5.7,1,8.2-0.9c3.4-4,6.1-8.5,8-13.5
					C1154.4,296.4,1155.9,291.4,1156.6,286.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1104.2,284.3c-0.7-5.7-1.5-11.4-2.2-17c-1.6-6.2-3.1-12.5-4.6-18.7c-1-4-1.9-8-2.6-12
					c-1.3-6.1-2.3-12.2-2.9-18.3c-0.7-5-8.2-8.7-12.7-6.7c-2.9,2.3-5.1,5.2-6.7,8.7c-1.9,3.2-3.1,6.7-3.3,10.4
					c0.6,4.3,1.3,8.7,1.9,13c1.1,6.4,2.5,12.7,4.1,19c1,4.1,2,8.2,3.2,12.3c2.2,7.3,3.7,14.7,4.5,22.3c1,2.6,2.8,4.4,5.5,5.3
					c2.5,1.3,5.1,1.4,7.6,0.4c2.6-2.4,4.6-5.2,5.8-8.5C1103.5,291.2,1104.3,287.9,1104.2,284.3z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1078.2,277c-0.2,14.9-0.4,29.8,0.1,44.7c0.5,15.3,1.5,30.6,2.7,45.9
					c0.8,3.3,2.8,5.2,6.2,5.7c4.9-0.3,9-2.5,12.1-6.6c6.2-5.7,11.9-11.9,17.2-18.4c3.8-3.4,5.8-7.6,5.7-12.6
					c-1-14.8-1.8-29.6-2.1-44.4c-0.3-14.2,0-28.4,0.2-42.5c-0.6-3.3-2.5-5.3-5.8-6.1c-4.8-0.1-8.9,1.8-12.2,5.6
					c-6.4,5.2-12.3,10.8-17.8,16.8C1080.5,268.2,1078.4,272.2,1078.2,277z"/>
				<path opacity="0.1091" fill="#333333" d="M1052.2,258c-0.1,14.9-0.3,29.7,0.2,44.6c0.5,15.3,1.6,30.5,2.8,45.8
					c0.6,3.4,2.7,5.3,6.1,5.8c5.2-0.6,9.5-3,12.9-7.4c6.7-6.2,12.9-12.8,18.7-19.9c4.1-3.6,6.3-8.1,6.5-13.3
					c-0.8-14.9-1.4-29.8-1.6-44.8c-0.2-14.2,0.2-28.3,0.4-42.5c-0.4-3.4-2.4-5.5-5.8-6.1c-5.2,0.2-9.6,2.4-13.2,6.5
					c-7,5.7-13.6,11.9-19.7,18.6C1055.1,248.5,1052.7,252.8,1052.2,258z"/>
				<path opacity="0.1091" fill="#333333" d="M1057.8,212.9c-3.2,12.4-4.2,25-2.9,37.8c0.1,6.8,0.3,13.6,0.6,20.3
					c0.6,11.6,1.3,23.2,2.2,34.8c-0.4,3,0.5,5.5,2.8,7.5c2.2,2.1,4.7,2.9,7.7,2.3c4.1-2.1,7.6-5,10.6-8.5c3.3-3.2,5.9-6.9,7.8-11.1
					c-0.9-15.2-1.6-30.3-1.9-45.5c-0.1-5.9-0.2-11.8-0.3-17.7c-0.6-9.3,0.2-18.5,2.6-27.6c0.6-2.9-0.2-5.5-2.3-7.6
					c-2-2.3-4.5-3.2-7.5-2.8c-4.1,1.8-7.8,4.4-10.9,7.7C1062.7,205.4,1059.9,208.9,1057.8,212.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1077.4,248.5c-0.2,5.2-0.4,10.5-0.7,15.7c-0.3,6.7-0.3,13.3,0.2,20
					c0.5,18.4,1.6,36.7,3,55c1.1,2.9,3.1,4.9,6.2,5.7c3.9,0.5,7.2-0.9,9.8-4.1c4.7-4.1,8.9-8.6,12.7-13.6c3-2.8,4.1-6.2,3.4-10.1
					c-0.9-12-1.5-23.9-1.9-35.9c-0.4-11.8-0.5-23.6-0.5-35.3c-0.1,1.4-0.1,2.9-0.2,4.3c0.3-7,0.6-14.1,0.9-21.1
					c-0.9-3-2.9-5-5.8-6.1c-3.8-0.8-7.1,0.4-9.9,3.3c-4.9,3.7-9.3,7.9-13.3,12.6C1078.2,241.4,1076.9,244.7,1077.4,248.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1078.8,211c-1,6.9-2,13.7-3,20.6c-0.1,9.2,0,18.4,0.2,27.5c0.3,15.4,1.4,30.7,2.6,46
					c0.1,2.7,1.2,5,3.3,6.8c1.9,2.1,4.2,3.1,6.9,3.2c3.2-1.1,5.9-3,8.1-5.5c2.5-2.3,4.3-5,5.4-8.2c-1.1-15.2-2.1-30.3-2.4-45.5
					c-0.1-6-0.3-12.1-0.3-18.1c-0.5-9.7,0.2-19.3,2.2-28.9c-0.1-2.7-1.1-5-3.2-6.9c-1.7-2.1-4-3.3-6.7-3.6c-3.1,0.9-5.7,2.5-7.9,4.8
					C1081.6,205.5,1079.9,208,1078.8,211z"/>
				<path opacity="0.1091" fill="#333333" d="M1035,244.6c-0.1,14.9-0.3,29.7,0.2,44.6c0.5,15.3,1.7,30.5,2.9,45.7
					c0.7,3.4,2.7,5.3,6.1,5.8c5.2-0.5,9.4-2.9,12.8-7.2c6.6-6.1,12.8-12.7,18.5-19.7c4.1-3.6,6.2-8,6.4-13.2
					c-0.7-15-1.2-30-1.3-45.1c-0.1-14.2,0.3-28.5,0.5-42.7c-0.4-3.4-2.4-5.5-5.8-6.1c-5.2,0.2-9.7,2.4-13.3,6.5
					c-7,5.7-13.6,11.9-19.8,18.6C1037.9,235.1,1035.5,239.4,1035,244.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1057.7,282.5c0,14.9-0.2,29.8,0.3,44.7c0.5,15.4,1.6,30.6,2.8,45.9
					c0.5,3.6,2.5,5.5,6.1,5.7c5.8-1.1,10.5-4,14.2-8.8c7.5-7.1,14.6-14.7,21.2-22.7c4.6-4,7.2-9,7.8-14.8
					c-0.8-14.8-1.6-29.6-1.8-44.4c-0.2-5.9-0.2-11.7,0-17.6c0.2-8.2,0.3-16.4,0.2-24.7c-0.3-3.6-2.2-5.7-5.8-6.1
					c-5.7,0.6-10.6,3.2-14.5,7.7c-7.8,6.5-15.2,13.5-22.1,20.9C1061.4,272.2,1058.6,276.8,1057.7,282.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1094.7,239.1c-1.3,6.9-2.5,13.9-3.8,20.8c0.2,8.8,0.4,17.6,0.7,26.5
					c0.5,15.3,1.5,30.6,2.7,45.8c-0.3,2.9,0.7,5.4,3,7.3c2.1,2.1,4.6,2.9,7.5,2.5c3.8-1.9,7.1-4.5,9.8-7.8c3.1-3,5.4-6.4,7.1-10.4
					c-1.1-15-2.1-30-2.5-45c-0.2-5.8-0.4-11.5-0.5-17.3c-0.8-9.4,0.1-18.8,2.8-28c0.8-5.2-4.5-11-9.8-10.4c-3.7,1.5-7,3.7-9.7,6.6
					C1098.7,232.4,1096.3,235.5,1094.7,239.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1046.2,230.5c-0.2,14.8-0.3,29.6,0.2,44.5c0.5,15.3,1.6,30.5,2.8,45.8
					c0.9,3.1,3,5,6.1,5.8c4.4,0.1,8-1.6,10.8-5.2c5.4-4.8,10.3-10,14.7-15.7c3.4-3.1,4.9-6.8,4.5-11.1c-0.7-15.1-1.3-30.2-1.5-45.3
					c-0.2-14.3,0.2-28.6,0.5-42.9c-0.7-3.2-2.7-5.2-5.8-6.1c-4.4-0.4-8.1,1.1-11.2,4.5c-5.7,4.5-11,9.5-15.9,14.9
					C1047.8,222.4,1046.1,226.1,1046.2,230.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1068.8,222.8c-2.2,7.9-3.1,15.8-2.8,23.8c0.1,5.9,0.2,11.8,0.4,17.7
					c0.5,18.4,1.6,36.7,3,55c1.2,2.8,3.3,4.7,6.1,5.8c2.6,0.7,4.7,0.2,6.5-1.6c3.2-1.9,6-4.3,8.4-7.3c3.4-3.3,6.1-7.2,8.1-11.5
					c-0.4-7.4-0.9-14.8-1.3-22.2c0.1,1.4,0.1,2.7,0.2,4.1c-0.3-7.4-0.6-14.8-0.9-22.2c0,1.4,0.1,2.7,0.1,4.1
					c-0.3-13.1-0.5-26.2-0.7-39.4c0,1.4,0,2.7,0.1,4.1c0.8-6.2,1.6-12.4,2.4-18.6c0.6-3-0.1-5.5-2.3-7.7c-2-2.3-4.5-3.2-7.5-2.7
					c-4.2,1.9-8,4.6-11.2,8C1073.8,215.1,1070.9,218.7,1068.8,222.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1090.6,257.2c-0.4,6.7-0.7,13.3-1,20c-0.3,6.6-0.2,13.3,0.2,19.9
					c0.5,18.4,1.6,36.6,3,55c1.1,2.9,3.2,4.9,6.2,5.7c3.9,0.5,7.2-0.9,9.8-4.1c4.7-4.1,8.9-8.7,12.7-13.7c3-2.8,4.2-6.2,3.4-10.1
					c-1-11.9-1.7-23.8-2.1-35.7c-0.4-11.7-0.6-23.5-0.6-35.2c-0.1,1.4-0.1,2.9-0.2,4.3c0.3-7,0.6-14.1,0.9-21.1
					c-0.9-3-2.9-5-5.8-6.1c-2.7-0.8-5-0.2-7,1.6c-3.6,1.9-6.9,4.5-9.7,7.5C1096.5,248.7,1093.2,252.7,1090.6,257.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1035.7,260.7c0,14.9-0.2,29.8,0.4,44.6c0.6,15.3,1.7,30.5,3,45.8
					c0.4,3.6,2.5,5.5,6.1,5.8c5.8-1.1,10.5-4,14.3-8.8c7.6-7.1,14.7-14.7,21.3-22.7c4.6-4,7.2-9,7.9-14.8
					c-0.7-14.9-1.2-29.9-1.4-44.8c-0.2-5.9-0.1-11.8,0.2-17.7c0.3-8.2,0.4-16.4,0.2-24.7c-0.2-3.6-2.1-5.7-5.8-6.1
					c-5.8,0.7-10.8,3.3-14.8,7.9c-8,6.7-15.5,13.8-22.7,21.3C1039.7,250.2,1036.8,254.9,1035.7,260.7z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1086.6,522c0.2,4.7,0.4,9.5,0.5,14.2c0.4,7.5,1.2,14.9,2.5,22.3
					c0.6,5.1,1.6,10.1,2.8,15.1c1.5,7.6,3.4,15.2,5.7,22.6c1,3.3,3.2,5,6.6,5.2c5-0.8,8.9-3.4,11.7-7.8c5.8-6.4,11.1-13.1,15.9-20.3
					c3.6-3.8,5.2-8.2,4.8-13.2c-1.1-4.5-2.2-9-3.3-13.5c-1.5-6.8-2.7-13.7-3.5-20.7c-1.5-11.2-1.9-22.3-2.3-33.6
					c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.1-8.9,2.1-12.2,6c-6.3,5.4-12.1,11.2-17.4,17.5C1088.7,513.1,1086.7,517.2,1086.6,522z"/>
				<path opacity="0.1091" fill="#333333" d="M1060.7,502.9c0.2,4.7,0.4,9.4,0.7,14.1c0.4,7.5,1.2,14.9,2.6,22.2
					c0.7,5.1,1.6,10.1,2.9,15c1.5,7.6,3.5,15.1,5.9,22.5c1,3.3,3.2,5,6.6,5.2c5.1-0.9,9.1-3.7,12-8.2c6-6.6,11.5-13.6,16.5-21
					c3.7-3.9,5.4-8.4,5.1-13.6c-0.9-4.7-1.8-9.3-2.8-14c-1.3-7-2.2-14.1-2.8-21.2c-1.2-11.3-1.4-22.6-1.9-33.9c-0.6-3.4-2.5-5.4-6-6
					c-5.2,0.3-9.5,2.6-13,6.8c-6.8,5.9-13.1,12.2-19,19C1063.4,493.4,1061.1,497.7,1060.7,502.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1066.4,457.5c-2.4,6.4-3.2,12.8-2.6,19.3c0.3,5.5,1,10.9,1.8,16.4
					c0.8,5,1.5,10.1,2.3,15.1c1.9,10.2,4.3,20.3,7.3,30.3c1.5,2.6,3.7,4.3,6.6,5.2c3.3,0.6,6.1-0.5,8.2-3.4
					c3.6-3.5,6.6-7.4,9.2-11.7c2.3-2.7,2.7-5.7,1.3-8.8c-1-4.9-2.1-9.9-3.1-14.8c0.2,1.3,0.4,2.6,0.6,3.9c-1-6.2-2-12.5-2.9-18.7
					c0.2,1.3,0.3,2.6,0.5,3.9c-1.3-10.8-2.5-21.7-3.4-32.6c0.1,1.3,0.2,2.6,0.3,4c0.8-5.4,1.5-10.7,2.3-16.1
					c0.8-5.6-4.4-11.1-10.1-10.1c-4,1.9-7.5,4.5-10.5,7.8C1070.9,450.1,1068.3,453.5,1066.4,457.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1085.9,493.4c0,4.8,0,9.7,0,14.5c0.5,7.4,1.4,14.7,2.7,22c0.6,5.1,1.6,10.1,2.8,15
					c1.5,7.6,3.4,15.1,5.8,22.5c1.4,2.8,3.6,4.6,6.6,5.2c3.9,0.1,7-1.5,9.4-4.9c4.3-4.5,8.1-9.3,11.4-14.6c2.7-3.1,3.6-6.5,2.5-10.3
					c-1.1-4.7-2.2-9.4-3.3-14.1c-1.5-7.1-2.7-14.2-3.5-21.4c-0.7-4.7-1.2-9.4-1.5-14.1c-0.8-6.8-1-13.6-0.6-20.5c-1-3-3-4.9-6-5.9
					c-3.8-0.6-7.1,0.6-9.8,3.6c-4.8,3.9-9.1,8.2-12.9,12.9C1086.4,486.3,1085.2,489.6,1085.9,493.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1087.2,455.8c-0.7,5.8-1.4,11.5-2.2,17.3c0.4,7.6,1.2,15.1,2.5,22.6
					c0.6,5.1,1.5,10.2,2.7,15.2c1.5,7.6,3.4,15.2,5.8,22.6c0.4,2.7,1.7,4.8,4,6.4c2,1.9,4.4,2.8,7.1,2.7c3-1.3,5.4-3.3,7.3-5.9
					c2.2-2.4,3.7-5.2,4.4-8.3c-1.3-4.8-2.5-9.7-3.8-14.5c-1.8-7.3-3.1-14.6-4-22.1c-0.8-4.9-1.3-9.7-1.8-14.7
					c-1.3-7.9-1-15.8,0.7-23.7c-0.2-2.7-1.3-4.9-3.4-6.7c-1.8-2.1-4-3.2-6.7-3.4c-3,0.9-5.5,2.6-7.6,4.9
					C1089.8,450.3,1088.2,452.8,1087.2,455.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1043.6,489.4c0.2,4.7,0.4,9.4,0.7,14.1c0.4,7.5,1.3,14.9,2.7,22.2
					c0.7,5,1.7,10,2.9,14.9c1.6,7.6,3.6,15,6,22.4c1,3.2,3.2,5,6.6,5.2c4.9-0.8,8.8-3.3,11.7-7.7c5.8-6.3,11.1-13,15.8-20.1
					c3.6-3.8,5.2-8.2,4.8-13.1c-0.8-4.8-1.7-9.6-2.5-14.4c-1.1-7.2-2-14.5-2.5-21.7c-1-11.4-1.2-22.8-1.6-34.3
					c-0.6-3.4-2.5-5.4-5.9-6c-5.2,0.3-9.5,2.5-12.9,6.7c-6.8,5.8-13.1,12.1-18.9,18.9C1046.2,480,1043.9,484.3,1043.6,489.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1066.2,527.6c0.3,4.7,0.5,9.4,0.8,14.1c0.4,7.5,1.2,15,2.6,22.4
					c0.7,5.1,1.6,10.1,2.9,15.1c1.5,7.7,3.5,15.2,5.9,22.7c0.7,3.6,3,5.3,6.6,5.2c5.7-1.6,10.2-4.9,13.5-10.1
					c7-7.8,13.4-16,19.4-24.6c4.2-4.4,6.4-9.6,6.6-15.5c-1-4.5-1.9-9-2.9-13.5c-1.4-6.9-2.4-13.8-3.1-20.7
					c-1.3-11.1-1.6-22.2-2.2-33.3c-0.4-3.6-2.3-5.6-6-5.9c-5.7,0.8-10.5,3.5-14.3,8.2c-7.6,6.8-14.8,14-21.6,21.7
					C1069.7,517.1,1067,521.9,1066.2,527.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1102.9,484c-2.7,7.1-3.5,14.2-2.4,21.3c0.5,4.1,1,8.1,1.5,12.2
					c0.7,5.7,1.6,11.3,2.7,16.9c1.9,10.2,4.4,20.2,7.5,30c1.5,2.6,3.7,4.3,6.6,5.2c3.3,0.6,6-0.5,8.2-3.3c3.5-3.5,6.5-7.3,9.1-11.6
					c2.2-2.7,2.6-5.6,1.3-8.7c-2.7-9.3-4.9-18.8-6.6-28.3c0.2,1.3,0.4,2.5,0.6,3.8c-0.9-6.1-1.8-12.1-2.7-18.2
					c0.2,1.3,0.3,2.5,0.5,3.8c-0.7-5.6-1.4-11.3-2.2-16.9c0.1,1.3,0.3,2.5,0.4,3.8c0.7-5.5,1.4-10.9,2.2-16.4
					c0.7-5.2-4.8-10.9-10.1-10.1c-3.7,1.6-6.9,3.9-9.6,6.9C1106.8,477.1,1104.5,480.3,1102.9,484z"/>
				<path opacity="0.1091" fill="#333333" d="M1054.4,475.7c0.3,4.6,0.6,9.2,0.9,13.7c0.4,7.4,1.3,14.8,2.6,22.2
					c0.7,5.1,1.6,10.1,2.8,15c1.5,7.6,3.4,15.1,5.8,22.5c1.3,2.9,3.5,4.7,6.6,5.2c4.2-0.1,7.5-1.9,10-5.6c4.7-4.9,8.9-10.2,12.6-16
					c3-3.2,4-6.9,3.1-11c-0.9-4.9-1.8-9.7-2.7-14.6c-1.2-7.3-2.2-14.6-2.8-22c-1.1-11.5-1.4-22.9-1.9-34.4c-0.8-3.1-2.8-5.1-5.9-6
					c-4.3-0.3-8,1.3-11,4.8c-5.5,4.6-10.6,9.6-15.2,15.1C1055.7,467.7,1054.1,471.3,1054.4,475.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1077.2,467.5c-1.9,6.2-2.6,12.5-2.1,18.7c0.2,5.5,0.8,11,1.8,16.4
					c0.8,5,1.6,10,2.3,15.1c1.9,10.2,4.3,20.2,7.3,30.2c1.5,2.7,3.7,4.4,6.6,5.2c3.5,0.5,6.3-0.8,8.5-3.8c3.7-3.7,7-7.9,9.7-12.4
					c2.4-2.8,2.9-5.9,1.6-9.1c-2.4-9.6-4.3-19.3-5.7-29.1c-1.2-9.4-2.2-18.9-3-28.4c0.1,1.3,0.1,2.7,0.2,4
					c0.6-5.2,1.2-10.4,1.8-15.6c0.5-3-0.3-5.5-2.5-7.6c-2.1-2.2-4.6-3.1-7.6-2.5c-4.1,2-7.7,4.7-10.8,8.1
					C1081.9,459.8,1079.2,463.4,1077.2,467.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1098.8,506.4c0,4.8,0,9.7,0,14.5c0.5,7.4,1.4,14.7,2.7,22c0.7,5.1,1.6,10.1,2.8,15
					c1.5,7.6,3.4,15.1,5.8,22.5c1.3,2.9,3.5,4.6,6.6,5.2c4,0.1,7.2-1.6,9.6-5.1c4.4-4.6,8.3-9.7,11.8-15.1c2.8-3.1,3.7-6.6,2.7-10.5
					c-1.2-4.6-2.4-9.2-3.6-13.7c-1.7-6.9-2.9-14-3.8-21c-0.8-4.6-1.3-9.3-1.7-13.9c-0.8-6.8-1.1-13.5-0.7-20.4c-1-3-3-4.9-6-5.9
					c-3.8-0.6-7.1,0.6-9.8,3.6c-4.8,3.9-9.1,8.2-12.9,13C1099.4,499.3,1098.2,502.6,1098.8,506.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1044.4,505.6c0.3,4.7,0.5,9.4,0.8,14c0.4,7.5,1.3,14.9,2.7,22.2c0.7,5,1.7,10,3,15
					c1.6,7.6,3.6,15,6.1,22.4c0.8,3.5,3,5.3,6.6,5.2c5.5-1.4,9.9-4.6,13.1-9.5c6.7-7.5,12.9-15.3,18.6-23.6c4.1-4.3,6.2-9.3,6.3-15
					c-0.8-4.7-1.7-9.4-2.5-14.1c-1.1-7.1-2-14.2-2.5-21.3c-1-11.2-1.2-22.5-1.8-33.8c-0.3-3.6-2.3-5.6-5.9-6
					c-5.8,0.8-10.6,3.6-14.4,8.2c-7.7,6.8-15,14.1-21.8,21.8C1048,495,1045.2,499.8,1044.4,505.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1246.9,900.3c2.9-10.9,3.2-21.8,1.1-32.7c0.2-3-0.9-5.4-3.2-7.3
					c-2.3-2-4.8-2.7-7.7-1.9c-3.9,2.3-7.2,5.3-9.9,8.9c-3.1,3.3-5.4,7.1-7,11.3c2,10.4,1.9,20.8-0.4,31.2
					c-0.6,5.5,5.4,10.7,10.8,9.4c3.8-2.1,7-4.8,9.6-8.2C1243.1,907.9,1245.4,904.3,1246.9,900.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1220.9,881.4c3.2-11,3.7-22.1,1.5-33.2c0.2-3-0.9-5.4-3.3-7.2c-2.2-2-4.8-2.7-7.7-2
					c-3.8,2.2-7.1,5.1-9.7,8.7c-3,3.3-5.3,7-6.8,11.1c2.1,10.5,1.9,21-0.7,31.5c-0.6,5.4,5.7,10.6,10.8,9.4c3.7-2,6.8-4.6,9.4-7.9
					C1217.3,888.8,1219.4,885.4,1220.9,881.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1227,834.8c-0.8-9.8-1.4-19.6-2-29.4c-0.9-3.2-3.1-5-6.5-5.4c-4.9,0.6-8.8,3-11.7,7.2
					c-5.8,6-11.2,12.5-16.1,19.3c-3.6,3.6-5.3,7.9-5,12.8c0.4,9.4,1.1,18.7,1.9,28.1c0.9,3.2,3,5.1,6.4,5.5
					c4.8-0.4,8.7-2.7,11.7-6.9c5.9-5.8,11.3-12.1,16.2-18.7C1225.6,843.8,1227.3,839.6,1227,834.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1247.2,865.9c-0.8-9.7-1.5-19.5-2.1-29.2c-1.1-3.1-3.2-4.9-6.5-5.4
					c-4.5,0.2-8.1,2.2-10.8,6.1c-5.3,5.3-10,11-14.3,17.1c-3.3,3.3-4.7,7.2-4.1,11.7c0.5,9.4,1.2,18.7,2,28.1c1,3.1,3.1,4.9,6.4,5.5
					c4.4-0.1,8-2,10.8-5.8c5.3-5.1,10.1-10.7,14.4-16.6C1246.3,874.2,1247.7,870.3,1247.2,865.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1251,829.5c-1-9.7-1.8-19.5-2.2-29.3c-0.6-3.7-2.7-5.5-6.5-5.4
					c-5.9,1.6-10.7,5-14.2,10.2c-7.5,8-14.5,16.3-21,25.1c-4.5,4.5-7,9.7-7.5,15.8c0.2,9.3,0.9,18.5,2.1,27.7
					c0.5,3.7,2.7,5.5,6.4,5.5c5.9-1.4,10.6-4.7,14.3-9.8c7.5-7.7,14.5-15.8,21.1-24.3C1247.9,840.7,1250.5,835.5,1251,829.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1205.9,865.6c2.4-10.7,2.6-21.5,0.7-32.3c0.1-2.9-1.1-5.3-3.4-7.1
					c-2.2-2-4.7-2.7-7.6-2.1c-3.7,2.1-6.8,4.8-9.3,8.2c-2.9,3.1-5,6.7-6.4,10.6c1.9,10.2,1.9,20.5,0,30.8
					c-0.4,5.3,5.7,10.5,10.8,9.4c3.5-1.8,6.5-4.3,9-7.5C1202.6,872.7,1204.6,869.3,1205.9,865.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1230.2,901.9c1.7-11,1.8-21.9,0.5-32.9c-0.3-2.7-1.6-4.9-3.8-6.6
					c-2-1.9-4.3-2.9-7-2.8c-3,1.3-5.6,3.2-7.6,5.9c-2.3,2.4-3.9,5.2-4.8,8.4c1.5,10.6,1.6,21.2,0.5,31.8c0.4,2.6,1.7,4.8,3.9,6.4
					c1.9,1.9,4.2,2.9,6.9,3c2.9-1.1,5.2-2.8,7.1-5.3C1228.1,907.5,1229.5,904.9,1230.2,901.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1263.9,861c-0.9-9.8-1.6-19.5-2.1-29.3c-0.8-3.4-3-5.2-6.4-5.4
					c-5.3,0.9-9.5,3.7-12.6,8.3c-6.4,6.7-12.4,13.8-17.9,21.3c-4,3.9-5.9,8.5-5.9,13.9c0.3,9.3,1,18.6,2,27.9
					c0.8,3.4,2.9,5.2,6.3,5.5c5.2-0.8,9.4-3.4,12.6-7.9c6.5-6.5,12.5-13.4,18-20.7C1261.8,870.8,1263.8,866.2,1263.9,861z"/>
				<path opacity="0.1091" fill="#333333" d="M1216.5,847.3c-1-9.6-1.7-19.3-2.3-28.9c-1.2-3-3.4-4.7-6.5-5.4
					c-4.1-0.1-7.5,1.6-10,5.2c-4.8,4.7-9,9.8-12.8,15.4c-3,3.1-4.1,6.7-3.3,10.8c0.6,9.4,1.3,18.7,2.1,28.1c1.1,3,3.3,4.8,6.4,5.5
					c4.1,0.2,7.5-1.5,10-4.9c4.8-4.6,9.1-9.6,13-15C1216.2,854.9,1217.3,851.4,1216.5,847.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1237.3,845.3c-0.8-9.8-1.5-19.5-2.1-29.3c-0.9-3.3-3.1-5.1-6.5-5.4
					c-5,0.7-8.9,3.1-11.9,7.4c-6,6.2-11.5,12.8-16.5,19.7c-3.7,3.7-5.5,8-5.2,13.1c0.4,9.4,1.1,18.7,1.9,28c0.9,3.3,3,5.1,6.4,5.5
					c4.9-0.5,8.9-2.9,11.9-7.1c6-6,11.5-12.3,16.6-19.1C1235.7,854.5,1237.5,850.2,1237.3,845.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1260.2,878.9c-0.8-9.7-1.5-19.4-2.1-29.2c-1.1-3.1-3.2-4.9-6.4-5.4
					c-4.4,0.1-8,2.1-10.6,5.9c-5.2,5.2-9.8,10.7-14,16.7c-3.3,3.3-4.6,7.1-3.9,11.5c0.5,9.4,1.2,18.7,2,28.1
					c1.1,3.1,3.2,4.9,6.3,5.5c4.4,0,7.9-1.9,10.7-5.6c5.2-5,9.9-10.4,14.2-16.3C1259.4,887,1260.8,883.3,1260.2,878.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1208.7,879.7c1.6-10.9,1.7-21.9,0.4-32.9c-0.3-4.8-5.7-10.3-10.8-9.4
					c-3.2,1.4-5.9,3.6-8,6.4c-2.5,2.5-4.2,5.5-5.2,8.9c1.5,10.6,1.6,21.2,0.5,31.8c0.3,2.7,1.6,4.9,3.8,6.6c2,1.9,4.3,2.9,7,2.8
					c3-1.3,5.6-3.2,7.6-5.9C1206.3,885.6,1207.9,882.9,1208.7,879.7z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1256,839.6c1.4-6.2,2.7-12.4,4.1-18.6c0.5-4.3,0.7-8.5,0.8-12.8
					c0.4-5.9,0.6-11.8,0.7-17.8c0.3-9.1,0.3-18.2-0.1-27.3c-0.9-2.9-2.8-5-5.6-6.3c-3.5-1-6.6-0.2-9.4,2.5
					c-4.6,3.2-8.7,6.9-12.4,11.1c-3,2.4-4.2,5.4-3.5,9c0.1,8.9-0.1,17.8-0.7,26.7c-0.3,8.6-0.9,17.1-1.5,25.7
					c0.1-1.4,0.3-2.7,0.4-4.1c-1.2,5.8-2.4,11.7-3.7,17.5c-1,2.9-0.5,5.5,1.4,7.9c1.8,2.5,4.2,3.6,7.3,3.4c4.5-1.6,8.6-3.9,12.2-7
					C1250,846.9,1253.3,843.6,1256,839.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1228.4,821.8c2.3-7,4.6-14,6.9-21c0.6-8.7,0.9-17.4,1.1-26.1
					c0.2-9.2,0.1-17.9-0.1-27.2c0.6-2.9-0.2-5.4-2.3-7.6c-1.9-2.3-4.3-3.3-7.3-3.1c-4.1,1.6-7.6,4-10.8,7.1
					c-3.4,2.8-6.2,6.1-8.2,9.9c0.1,7.2,0.1,14.3,0.2,21.5c0-1.3,0-2.6,0-3.9c-0.1,8.6-0.4,17.1-0.8,25.7c0.3,9.7-1.8,18.9-6.2,27.8
					c-1.5,5.1,3.5,11.4,8.7,11.3c3.9-1.1,7.4-3,10.5-5.7C1223.6,828.2,1226.4,825.3,1228.4,821.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1236.1,775.8c1.3-11.5,2.3-23,2.9-34.6c0.5-12.1,0.6-24.2,0.1-36.3
					c-0.6-3.3-2.4-5.3-5.6-6.3c-4.6-0.3-8.6,1.3-11.9,4.8c-6.2,4.7-12,9.9-17.3,15.5c-3.9,2.9-5.9,6.7-6.1,11.3
					c0.4,11.4,0.3,22.9-0.3,34.3c-0.7,10.8-1.7,21.7-2.9,32.5c0.4,3.2,2.1,5.5,5.2,6.7c4.4,0.8,8.4-0.5,11.8-3.6
					c6.3-4.1,12.1-8.6,17.6-13.7C1233.6,783.8,1235.7,780.3,1236.1,775.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1256.4,806.6c1.3-11.5,2.3-23,2.9-34.5c0.5-12.1,0.5-24.2,0.1-36.3
					c-0.6-3.2-2.5-5.3-5.6-6.3c-4.5-0.4-8.4,1.1-11.7,4.6c-6,4.5-11.7,9.6-16.9,15.1c-3.8,2.9-5.8,6.5-5.9,11.1
					c0.1,11.6-0.2,23.3-1,34.9c-0.8,10.9-1.9,21.8-3.2,32.6c0.4,3.2,2.1,5.5,5.1,6.7c4.4,0.8,8.4-0.4,11.9-3.6
					c6.3-4,12.2-8.6,17.7-13.6C1253.9,814.6,1256,811.1,1256.4,806.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1259.9,771.5c1.2-11.6,2.1-23.2,2.8-34.8c0.5-12.2,0.6-24.3,0.1-36.5
					c-0.1-3.7-2-5.8-5.6-6.3c-5.9,0.6-10.9,3.1-15.1,7.6c-8.2,6.5-16,13.4-23.4,20.9c-4.9,3.6-8,8.3-9.2,14.1
					c0.2,11.4-0.1,22.8-0.8,34.1c-1.1,10.7-2.1,21.4-3,32.1c-0.1,3.6,1.6,5.8,5.2,6.7c5.7,0,10.8-2.1,15.1-6.2
					c8.4-5.7,16.4-11.9,24-18.6C1255.2,781.4,1258.5,777.1,1259.9,771.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1213,806.9c5.1-12.6,7.8-25.6,8.1-39.1c0.6-11.8,0.6-23.7,0.1-35.6
					c0.4-2.8-0.5-5.3-2.5-7.3c-1.8-2.3-4.2-3.4-7-3.3c-3.7,1.3-7,3.4-9.8,6.2c-3.1,2.5-5.5,5.5-7.2,9c0.6,11.3,0.6,22.5,0.2,33.8
					c-0.1,12.8-2.3,25.2-6.6,37.3c-0.3,2.7,0.4,5.2,2.3,7.3c1.6,2.3,3.7,3.7,6.5,4c3.4-0.8,6.5-2.3,9.1-4.7
					C1209.1,812.6,1211.4,810,1213,806.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1238.9,842c4-12.3,6-24.8,5.9-37.7c0.7-12.2,0.8-24.3,0.3-36.5
					c0.2-2.8-0.7-5.1-2.6-7.2c-1.7-2.2-3.9-3.5-6.7-3.7c-3.4,0.9-6.3,2.6-8.9,5c-2.8,2.1-5,4.8-6.4,7.9c0.4,11.9,0.2,23.8-0.4,35.8
					c0.2,12.4-1.5,24.6-5,36.6c-0.2,2.7,0.6,5.1,2.4,7.2c1.5,2.3,3.7,3.7,6.4,4.1c3.2-0.6,6.1-2.1,8.7-4.3
					C1235.4,847.4,1237.5,845,1238.9,842z"/>
				<path opacity="0.1091" fill="#333333" d="M1273.6,801.6c1.2-11.5,2.2-23.1,2.8-34.6c0.5-12.1,0.5-24.2,0-36.2
					c-0.3-3.5-2.1-5.6-5.6-6.3c-5.5,0.2-10.2,2.5-14.1,6.6c-7.6,5.9-14.7,12.2-21.5,19.1c-4.6,3.4-7.4,7.8-8.2,13.2
					c-0.1,11.6-0.5,23.1-1.3,34.7c-1.1,10.8-2.2,21.6-3.2,32.3c0,3.5,1.7,5.8,5.1,6.7c5.4,0.2,10.2-1.7,14.4-5.6
					c7.9-5.3,15.4-11.1,22.5-17.4C1269.3,811,1272.4,806.9,1273.6,801.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1224.3,793c1-5.9,1.9-11.8,2.9-17.7c0.6-4.3,1-8.6,1-12.9c0.4-5.9,0.6-11.8,0.7-17.8
					c0.3-9.1,0.3-18.1-0.1-27.2c-0.8-3-2.7-5.1-5.6-6.3c-3.8-0.8-7.2,0.2-10.1,3.2c-5,3.7-9.6,7.8-13.8,12.4
					c-3.2,2.6-4.7,5.8-4.2,9.7c0.4,8.6,0.4,17.3,0.1,25.9c-0.1,8.5-0.5,16.9-1.2,25.3c0.2-1.4,0.3-2.8,0.5-4.2
					c-0.8,5.5-1.7,11.1-2.5,16.6c-1.1,2.9-0.7,5.6,1.2,8.1c1.9,2.5,4.4,3.6,7.5,3.2c4.8-1.8,9.1-4.4,13-7.7
					C1217.8,800.7,1221.3,797.2,1224.3,793z"/>
				<path opacity="0.1091" fill="#333333" d="M1246.3,786.3c1.5-11.5,2.5-23.1,3-34.7c0.5-12.1,0.6-24.2,0.1-36.3
					c-0.5-3.3-2.4-5.4-5.6-6.3c-4.9-0.2-9,1.6-12.5,5.3c-6.6,5-12.7,10.5-18.4,16.5c-4.1,3-6.3,7-6.7,11.8c0.2,11.5,0,23-0.6,34.4
					c-0.8,10.9-1.8,21.7-3.1,32.5c0.3,3.3,2,5.5,5.2,6.7c4.7,0.6,8.9-0.8,12.5-4.2c6.7-4.4,13-9.3,18.9-14.7
					C1243.3,794.7,1245.7,791,1246.3,786.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1269.3,819.7c1.5-11.5,2.5-23.1,3-34.7c0.5-12.1,0.5-24.1,0-36.2
					c-0.6-3.3-2.4-5.4-5.6-6.3c-4.6-0.4-8.6,1.2-11.9,4.7c-6.2,4.7-12,9.8-17.3,15.4c-3.9,2.9-5.9,6.6-6.1,11.3
					c-0.1,11.7-0.5,23.4-1.3,35.1c-0.9,11-2.1,21.9-3.4,32.8c0.3,3.3,2,5.5,5.1,6.7c4.6,0.7,8.6-0.6,12.2-3.9
					c6.5-4.2,12.6-8.9,18.4-14.1C1266.5,827.9,1268.8,824.3,1269.3,819.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1216.4,820.7c4.2-12.3,6.2-24.9,6.2-37.9c0.7-12.2,0.9-24.4,0.5-36.6
					c0.1-2.7-0.8-5.1-2.7-7.1c-1.7-2.2-3.9-3.5-6.6-3.8c-3.3,0.8-6.1,2.4-8.5,4.8c-2.7,2-4.7,4.6-6.1,7.7
					c0.5,11.9,0.4,23.7-0.2,35.6c0.2,12.4-1.4,24.6-4.9,36.6c-0.1,2.7,0.8,5,2.6,7.1c1.5,2.3,3.6,3.7,6.2,4.3c3-0.5,5.7-1.7,8-3.8
					C1213.3,825.7,1215.2,823.5,1216.4,820.7z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1224.4,783c-0.9-3.3-1.4-6.7-1.4-10.1c-0.2-2.4-0.2-4.8-0.1-7.2
					c-0.2,1.6-0.3,3.2-0.5,4.7c0.4-2.9,0.8-5.7,1.3-8.6c-0.3,1.6-0.5,3.2-0.8,4.8c0.5-2.5,1.3-4.8,2.6-7c-1.2,2.1-2.3,4.1-3.5,6.2
					c3-4.4,5.5-9.1,7.3-14.1c0-2.9-1.2-5.3-3.6-7c-2.3-1.9-4.8-2.5-7.7-1.8c-2.7,1.5-4.9,3.5-6.8,5.9c-2.6,2.7-4.8,5.6-6.6,8.9
					c-2.2,4.3-3.4,8.9-3.6,13.8c-0.5,4.4-0.2,8.8,0.8,13.1c0.5,4.4,1.3,8.8,2.5,13.1c1.2,2.5,3.2,4.1,5.9,4.8c2.6,1.1,5.2,1,7.6-0.3
					c2.4-2.6,4.1-5.6,5-9C1224.3,789.9,1224.8,786.5,1224.4,783z"/>
				<path opacity="0.1091" fill="#333333" d="M1197.9,766.4c-0.8-3.5-1.1-7.1-1-10.7c0-2.5-0.1-5.1-0.1-7.6
					c-0.2,1.8-0.5,3.7-0.7,5.5c0.5-3.2,0.9-6.4,1.4-9.5c-0.3,1.8-0.7,3.6-1,5.4c0.6-2.9,1.6-5.6,2.9-8.2c-1.2,2.2-2.5,4.4-3.7,6.5
					c1.9-3.3,3.8-6.7,5.7-10c0.7-1.7,1.4-3.3,2.1-5c0.2-2.3-0.5-4.1-2.2-5.4c-0.8-1.3-1.9-2.2-3.3-2.6c-1.7-1.3-3.7-1.5-5.8-0.8
					c-10.7,14.8-20.5,25.8-18.6,42.6c0.3,1.7,0.5,3.5,0.8,5.2c0.3,4.7,1,9.4,2,14c1,2.8,2.9,4.6,5.7,5.4c2.8,1.1,5.4,0.7,7.8-0.9
					c2.7-3.4,4.6-7.2,5.8-11.4C1197.3,775,1198,770.8,1197.9,766.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1201,718.5c-0.6-1.5-1.3-3.1-1.9-4.6c0.4,1.8,0.8,3.7,1.3,5.5c-0.7-3.3-1.4-6.5-2-9.8
					c0.4,1.8,0.8,3.7,1.1,5.5c-0.3-2.9,0-5.7,0.7-8.5c-0.9,3.1-1.8,6.2-2.8,9.2c1.4-4.4,2.8-8.7,4.1-13.1c-1.1,2.9-2.1,5.9-3.2,8.8
					c2.5-5.3,5-10.5,7.6-15.8c1.2-3.5,0.4-6.4-2.3-8.8c-3-1.9-6.1-1.9-9.1,0.2c-5.4,4.7-10.2,10-14.4,15.9
					c-4.5,5.6-8.3,11.7-11.3,18.2c-0.9,4.6-1.4,9.3-1.4,14c-0.1,4.6,0.2,9.1,0.9,13.6c0.6,4,1.4,7.9,2.4,11.8c1,3.4,2.4,6.6,4.1,9.7
					c2,2.9,4.7,3.8,8,2.7c4.6-2.9,7.5-7,8.8-12.5c3.6-8.7,6.5-17.7,8.9-26.8C1202.9,728.6,1203,723.5,1201,718.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1221.2,750.9c-0.6-1.5-1.3-3.1-1.9-4.6c0.6,1.4,1.1,2.8,1.7,4.2
					c-0.9-2.9-1.2-5.9-1-8.9c-0.5,2.4-1,4.8-1.5,7.2c1.2-5.2,2.3-10.4,3.5-15.6c-0.8,2.4-1.6,4.7-2.3,7.1c1.5-3.7,2.9-7.3,4.4-11
					c-1,2.3-1.9,4.7-2.9,7c0.6-1.2,1.2-2.3,1.8-3.5c1.2-2.3,2.3-4.6,3.5-6.9c0-1.6-0.1-3.1-0.1-4.7c-0.9-1.2-1.7-2.3-2.6-3.5
					c-2-1.7-4.2-2-6.4-1c-2.2,0.1-3.9,1.2-5.1,3.1c-2.4,1.9-4.5,4.2-6.3,6.7c-2.4,2.7-4.6,5.5-6.5,8.6c-2.7,3.4-4.9,7.2-6.5,11.3
					c-0.6,7.4-1,14.7,0.5,22.1c1.3,6.7,3.9,12.8,6.8,18.9c2.2,2.3,4.8,3.2,8,2.7c3.9-1.5,6.4-4.4,7.5-8.7c2.8-6.2,4.9-12.7,6.5-19.3
					C1223.7,758.3,1223.5,754.5,1221.2,750.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1223.6,713.4c-0.6-1.5-1.3-3.1-1.9-4.6c0.3,2.1,0.7,4.2,1,6.2
					c-0.6-3.5-1.1-7.1-1.7-10.6c0.3,2.1,0.6,4.2,0.8,6.3c-0.2-2.9,0.1-5.8,1-8.6c-1.2,3.7-2.4,7.4-3.6,11.1c1.7-5,3.4-9.9,5.1-14.9
					c-3.9,8.4-7.7,16.8-11.6,25.3c5.4-10.7,10.8-21.3,16.2-32c0.3-2,0.6-4.1,0.9-6.1c-0.8-1.3-1.5-2.6-2.3-4c-1.5-0.4-3-0.7-4.5-1.1
					c-4,1-7.2,3.3-9.5,6.7c-6.9,7.2-12.8,15.1-18.4,23.3c-2.8,3.6-5.3,7.4-7.4,11.5c-2.6,3.2-3.8,6.9-3.6,11c0,4.5,0.4,9,1,13.4
					c0.5,3.3,1.2,6.6,2,9.8c0.7,3.1,1.5,6.1,2.7,9c1.2,3.2,2.7,6.2,4.5,9c1.9,3.2,4.5,4.1,8,2.7c4.9-3.7,8.1-8.6,9.5-14.8
					c4.1-10.2,7.5-20.6,10.4-31.2C1225,725.2,1225.4,719.3,1223.6,713.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1182.1,750.1c-1-2.8-1.5-5.7-1.7-8.7c-0.3-2.4-0.4-4.8-0.2-7.3
					c-0.3,2-0.6,3.9-0.9,5.9c0.5-3.4,1.1-6.8,1.6-10.1c-0.4,1.9-0.8,3.8-1.1,5.7c0.8-3.1,1.9-6,3.3-8.8c-1.2,2.2-2.5,4.3-3.7,6.5
					c2-3.4,4-6.8,5.9-10.2c0.7-1.6,1.3-3.3,2-4.9c-0.3-1.3-0.5-2.5-0.8-3.8c-0.9-1.1-1.9-2.2-2.8-3.2c-1.7-1.6-3.6-2.2-5.7-1.9
					c-1.6-0.3-3,0.2-4,1.6c-1.8,1.1-3.3,2.6-4.5,4.3c-3.6,4.1-6.8,8.4-8.5,13.7c-2.4,7.3-4.6,14.7-4.5,22.4
					c-0.1,3.1,0.1,6.1,0.6,9.1c0.5,4.2,1.4,8.2,2.7,12.2c1.6,5.8,8.8,8.3,13.6,4.5c2.9-3.9,5-8.2,6.3-12.9
					C1181.1,759.6,1182,754.9,1182.1,750.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1206.4,785.8c-0.4-2.2-0.9-4.3-1.3-6.5c0.1,1.2,0.2,2.4,0.3,3.6
					c-0.3-3.3-0.5-6.6-0.8-9.9c0,1.2,0.1,2.4,0.1,3.7c0-2.6,0-5.2,0-7.7c0,1.2,0,2.5,0,3.7c0.2-1.3,0.4-2.6,0.6-3.9
					c-0.6,1.6-1.2,3.1-1.8,4.7c1.1-2.9,2.2-5.9,3.3-8.8c-0.7,1.5-1.4,3.1-2.1,4.6c1.4-2.9,2.8-5.7,4.2-8.6c-0.8,1.5-1.6,3.1-2.4,4.6
					c1.7-2.7,3.4-5.5,5.1-8.2c1.6-2.4,1.5-4.8-0.1-7.1c-1.7-2.3-4-4.1-6.7-5.1c-2.6-1-4.9-0.4-6.8,1.7c-2.7,2.4-4.8,5.2-6.5,8.4
					c-3.1,5.3-5.8,11.3-6.3,17.5c-0.2,2.8-0.3,5.6,0,8.4c0.4,5.1,1.5,9.9,2.7,14.9c1.5,2.1,3.6,3.5,6.1,4.2c2.5,1,4.9,1.1,7.4,0.3
					c2.1-1.8,3.5-4,4.2-6.7C1206.8,791.1,1207,788.5,1206.4,785.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1237.1,747c-0.6-1.6-1.2-3.2-1.9-4.7c0.5,1.6,1,3.2,1.5,4.8c-0.7-2.3-0.9-4.6-0.8-6.9
					c-0.2-2.3,0.1-4.5,0.8-6.6c-0.8,2.8-1.6,5.7-2.5,8.5c1.3-4.2,2.5-8.4,3.8-12.6c-1,2.8-2,5.6-3,8.4c1.7-4.1,3.4-8.2,5.2-12.4
					c-1.2,2.8-2.5,5.6-3.7,8.4c2.1-3.9,4.2-7.8,6.2-11.8c0.1-1.8,0.2-3.6,0.3-5.4c-0.8-1.2-1.6-2.5-2.5-3.7
					c-1.4-0.4-2.8-0.9-4.3-1.3c-3.6,0.5-6.4,2.2-8.4,5.1c-5.9,5.7-10.8,12.3-15.5,19c-2.4,3-4.4,6.1-6,9.6c-2.2,2.8-3.1,5.9-2.6,9.5
					c0.4,3.5,0.9,7,1.4,10.4c0.5,2.7,1.2,5.3,2,7.9c1.6,5.8,4,11.3,6.7,16.6c2.1,2.6,4.8,3.5,8,2.7c4.2-2,6.9-5.4,8-10.2
					c3.1-7.2,5.6-14.7,7.5-22.3C1239.3,755.5,1239.2,751.2,1237.1,747z"/>
				<path opacity="0.1091" fill="#333333" d="M1190.3,732.6c-0.6-1.6-1.2-3.1-1.9-4.7c0.5,1.5,1,3.1,1.5,4.6
					c-0.8-2.2-1.1-4.4-0.9-6.6c-0.1-2.2,0.1-4.4,0.7-6.5c-0.7,2.5-1.3,5-2,7.6c1.8-5.2,3.6-10.4,5.3-15.6c0.4-3.3-0.7-5.9-3.5-7.8
					c-2.8-1.8-5.7-1.7-8.5,0c-4.4,4-8.1,8.6-11.1,13.7c-3.4,4.9-6.1,10.1-7.9,15.8c-0.7,4.6-1.1,9.2-1.1,13.9c0,3.2,0.4,6.5,1,9.6
					c1,6.2,3.2,12.1,5.8,17.9c2.1,2.5,4.8,3.4,8,2.6c4.2-2,6.8-5.3,7.9-10c3-7.1,5.4-14.4,7.2-21.9
					C1192.6,740.9,1192.5,736.7,1190.3,732.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1211.3,730c-0.6-1.6-1.3-3.1-1.9-4.7c0.4,1.7,0.9,3.5,1.3,5.2c-0.7-2.2-1-4.4-0.9-6.7
					c-0.1-2.2,0.1-4.4,0.7-6.5c-0.9,3-1.8,5.9-2.6,8.9c1.3-4.3,2.6-8.6,4-12.9c-1,2.9-2.1,5.7-3.1,8.6c2.5-5.3,5-10.5,7.5-15.8
					c1.2-3.5,0.4-6.5-2.3-8.9c-3.1-1.9-6.1-1.9-9.2,0.3c-5.5,4.8-10.3,10.1-14.5,16.1c-4.6,5.7-8.4,11.8-11.5,18.4
					c-0.6,4.3-0.8,8.6-0.7,12.9c0.1,4.3,0.5,8.5,1.3,12.7c0.6,3.8,1.5,7.5,2.6,11.2c1.1,3.3,2.5,6.5,4.2,9.5c2,2.8,4.7,3.7,8,2.7
					c4.4-2.5,7.3-6.4,8.5-11.6c3.4-8.1,6.2-16.5,8.3-25C1213.2,739.5,1213.3,734.7,1211.3,730z"/>
				<path opacity="0.1091" fill="#333333" d="M1235.7,768.8c-1.1-2.6-1.8-5.2-2-8c-0.3-2.3-0.5-4.5-0.5-6.8
					c-0.4,2.2-0.8,4.3-1.2,6.5c1-4.8,2.1-9.7,3.1-14.5c-0.7,2.2-1.4,4.4-2.1,6.6c1.4-3.5,2.7-6.9,4.1-10.4c-0.9,2.2-1.8,4.4-2.6,6.6
					c0.6-1.1,1.1-2.2,1.7-3.2c1.1-2.2,2.2-4.4,3.3-6.6c-0.1-1.5-0.1-3-0.2-4.6c-0.9-1.2-1.8-2.3-2.6-3.5c-2-1.7-4.1-2.1-6.3-1.2
					c-2.1,0.1-3.8,1.1-5,2.9c-2.4,1.8-4.4,4-6.1,6.4c-2.4,2.5-4.5,5.3-6.3,8.2c-2.6,3.3-4.7,6.9-6.3,10.9
					c-0.2,5.7-0.3,11.2,0.8,16.8c1.1,6,3,11.8,5.1,17.4c0.7,3,2.5,5.1,5.5,6c3,1,5.6,0.6,8-1.5c3-4.1,5.1-8.6,6.5-13.5
					C1234.6,778.8,1235.6,773.9,1235.7,768.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1183.9,766.1c-0.9-4.8-1.3-9.7-1.2-14.6c-0.1,1.6-0.2,3.2-0.3,4.8
					c0.2-3.2,0.4-6.4,0.6-9.6c-0.1,1.6-0.3,3.1-0.4,4.7c0.2-3.1,1-6,2.4-8.8c-0.9,1.9-1.7,3.8-2.6,5.6c1.6-3.2,3.2-6.4,4.7-9.6
					c-1,1.8-1.9,3.6-2.9,5.4c1.9-3,3.7-6,5.6-8.9c1.8-2.5,1.9-5,0.3-7.6c-1.7-2.4-3.9-4.1-6.7-5.1c-2.9-0.9-5.3-0.1-7.2,2.2
					c-2.9,2.8-5.3,5.9-7.2,9.4c-3,5.2-5.2,10.7-6.5,16.5c-0.5,2.5-0.8,5.1-0.9,7.7c-0.6,8,0.5,15.8,1.9,23.7
					c1.2,2.6,3.1,4.2,5.9,4.9c2.7,1,5.2,0.9,7.7-0.4c2.5-2.8,4.2-5.9,5.2-9.6C1183.7,773.4,1184.2,769.8,1183.9,766.1z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1214.8,843.1c-2.4-5.2-4.8-10.5-7.2-15.7c-1.5-2.3-3.7-3.7-6.5-4.1
					c-2.7-0.7-5.2-0.3-7.6,1.2c-2.1,2.8-3.4,6-3.9,9.5c-0.9,3.4-1,6.9-0.2,10.3c2.4,4.9,4.9,9.9,7.4,14.8c1.6,2.2,3.7,3.6,6.5,4
					c2.7,0.7,5.2,0.4,7.5-1c2.1-2.7,3.3-5.7,3.8-9.1C1215.6,849.6,1215.7,846.4,1214.8,843.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1188.8,825.8c-2.2-5.3-4.5-10.6-6.7-15.9c-2.3-5.4-9.9-6.8-14.1-2.7
					c-2.3,4-3.8,8.3-4.4,13c-1.1,4.5-1.3,9.1-0.7,13.7c2.2,4.9,4.4,9.8,6.6,14.6c2.3,5.2,9.8,6.9,14,2.9c2.3-3.8,3.8-8,4.5-12.4
					C1189.2,834.6,1189.4,830.2,1188.8,825.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1190.9,777.8c-2.6-3.8-5.2-7.6-7.8-11.4c-2.3-2.7-5.1-3.3-8.3-1.6
					c-4.3,3.7-6.7,8.5-7.2,14.3c-2.5,9.6-4.4,19.3-5.6,29.1c-1.7,5.6-1.2,10.9,1.4,16c2.4,3.5,4.9,6.9,7.3,10.4
					c2.3,2.7,5.1,3.3,8.2,1.7c4.3-3.6,6.7-8.2,7.3-14c2.6-9.4,4.6-19,5.9-28.7C1193.9,788,1193.5,782.8,1190.9,777.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1211.3,810.9c-2.6-3.8-5.1-7.6-7.7-11.4c-2.5-2-5.2-2.6-8.3-1.6
					c-3.7,1.9-5.8,5.1-6.3,9.4c-1.9,6.5-3.2,13.1-3.8,19.8c-1.2,4.2-0.4,7.9,2.3,11.1c2.4,3.6,4.8,7.2,7.3,10.9
					c2.4,2,5.2,2.6,8.2,1.7c3.7-1.9,5.8-5,6.4-9.3c2-6.4,3.3-12.9,4.1-19.6C1214.6,817.8,1213.9,814.1,1211.3,810.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1213.1,773.2c-2.6-3.8-5.2-7.6-7.8-11.4c-2.3-3-5-3.5-8.3-1.6
					c-4.4,4.3-7,9.7-7.6,16.1c-2.8,10.8-4.9,21.6-6.4,32.6c-1.8,6.2-1.5,12.1,1,17.8c2.4,3.4,4.9,6.8,7.3,10.2c2.2,3,5,3.6,8.2,1.7
					c4.5-4.2,7-9.5,7.7-15.8c2.8-10.6,5.1-21.3,6.7-32.1C1215.8,784.7,1215.6,778.9,1213.1,773.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1172.9,808.7c-2.5-4.1-5-8.1-7.5-12.2c-1-3-3.1-4.8-6.3-5.4c-3.1-0.6-5.7,0.3-7.8,2.7
					c-2.4,4.7-4,9.7-4.8,15c-1.2,5.2-1.6,10.4-1.1,15.7c2.4,3.8,4.8,7.7,7.2,11.5c1,3,3.1,4.8,6.2,5.4c3.1,0.7,5.7-0.2,7.8-2.6
					c2.5-4.6,4.1-9.5,4.9-14.7C1172.9,819,1173.3,813.9,1172.9,808.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1196.8,845.5c-2.4-5-4.9-10.1-7.3-15.1c-1.7-2-3.9-3.2-6.6-3.6
					c-2.6-0.7-5.1-0.5-7.4,0.7c-1.9,2.2-3.1,4.7-3.5,7.6c-0.8,2.8-0.7,5.6,0.2,8.4c2.5,4.7,5,9.4,7.5,14.1c1.8,1.9,4,3.1,6.6,3.4
					c2.6,0.7,5,0.5,7.4-0.5c1.9-2,3-4.3,3.4-7.1C1197.9,850.7,1197.8,848.1,1196.8,845.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1227.2,806.9c-2.6-3.8-5.1-7.7-7.7-11.5c-2.4-2.2-5.2-2.8-8.3-1.6
					c-3.9,2.4-6,6-6.6,10.8c-2.1,7.3-3.5,14.8-4.4,22.3c-1.3,4.6-0.6,8.7,2,12.4c2.4,3.6,4.8,7.2,7.2,10.8c2.4,2.2,5.1,2.8,8.2,1.7
					c3.9-2.3,6.1-5.8,6.7-10.6c2.2-7.2,3.7-14.6,4.6-22C1230.4,814.7,1229.8,810.6,1227.2,806.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1180.4,792.5c-2.6-3.9-5.1-7.8-7.7-11.6c-2.4-2.3-5.2-2.9-8.3-1.6
					c-4,2.7-6.2,6.6-6.7,11.6c-2.2,7.9-3.7,15.9-4.6,24c-1.4,4.8-0.8,9.3,1.9,13.3c2.4,3.6,4.8,7.2,7.2,10.8
					c2.4,2.3,5.1,2.9,8.2,1.7c4-2.6,6.2-6.4,6.8-11.4c2.2-7.8,3.9-15.6,4.9-23.7C1183.6,800.9,1183,796.5,1180.4,792.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1201.3,789.8c-2.6-3.8-5.2-7.7-7.7-11.5c-2.4-2.5-5.1-3.1-8.3-1.6
					c-4.1,3.2-6.4,7.5-7,13c-2.3,8.7-4.1,17.6-5.2,26.6c-1.5,5.2-1,10.1,1.6,14.6c2.4,3.5,4.8,7.1,7.3,10.6c2.3,2.5,5.1,3.1,8.2,1.7
					c4.1-3.1,6.5-7.3,7.1-12.7c2.4-8.6,4.2-17.3,5.4-26.2C1204.3,799.1,1203.8,794.3,1201.3,789.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1226.1,829.4c-2.3-4.8-4.7-9.6-7-14.3c-1.1-2.9-3.2-4.6-6.3-5.1
					c-3-0.7-5.6,0.1-7.8,2.4c-2.4,4.3-3.9,8.9-4.6,13.7c-1.2,4.8-1.5,9.6-0.9,14.4c2.3,4.5,4.6,8.9,6.8,13.4
					c1.1,2.8,3.2,4.5,6.2,5.1c3,0.7,5.6,0,7.8-2.2c2.4-4.1,4-8.6,4.7-13.3C1226.3,838.8,1226.7,834.1,1226.1,829.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1174.8,825c-2.3-5.4-4.5-10.8-6.8-16.3c-2.1-5-10.2-6.3-14-2.7
					c-2.2,3.5-3.6,7.3-4.2,11.5c-1,4-1.2,8.1-0.4,12.2c2.3,5,4.5,10,6.8,15.1c2.2,4.8,10.1,6.3,14,2.9c2.2-3.3,3.6-7,4.2-10.9
					C1175.3,832.9,1175.5,829,1174.8,825z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1199.1,897.2c2-4.4,3.4-9,4.1-13.8c0.5-2.9,0.9-5.7,1.4-8.6c0.2-1.4,0.4-2.7,0.7-4.1
					c-0.5-1.3-1.1-2.7-1.6-4c-1.1-1-2.2-1.9-3.2-2.9c-2.5-1.2-4.8-1-7,0.5c-5,2-9.4,5.2-13.6,8.5c-2.1,1.4-3.9,3.1-5.4,5
					c-2.1,1.6-3,3.7-2.7,6.4c-0.6,3.5-1.1,6.9-1.7,10.4c0.2-1.4,0.5-2.7,0.7-4.1c-1.1,3.7-2.2,7.3-3.3,11c-1.2,2.8-0.9,5.5,0.9,8.1
					c1.7,2.6,4.1,3.8,7.2,3.7c4.7-1.4,9-3.6,12.9-6.6C1192.5,904.3,1196.1,901.1,1199.1,897.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1171.1,879.6c5.6-7.4,8.6-15.6,8.9-24.7c0.9-2.8,0.4-5.4-1.5-7.8
					c-1.6-2.5-3.9-3.8-6.9-3.9c-4.2,1.1-7.9,3.1-11.3,5.8c-3.6,2.3-6.7,5.3-9.1,8.9c-0.2,8.5-2.7,16.2-7.5,23.3
					c-0.8,2.8-0.2,5.3,1.5,7.6c1.5,2.5,3.8,3.9,6.6,4.1c3.9-0.9,7.5-2.6,10.7-5.1C1166,885.6,1168.8,882.9,1171.1,879.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1179.1,833.7c1.1-7.1,2.4-14.2,3.8-21.3c-0.3-3.2-1.9-5.5-4.9-6.8
					c-4.3-1-8.2,0.1-11.8,3.1c-6.3,3.7-12.2,8-17.8,12.8c-4,2.4-6.2,5.8-6.7,10.2c-1.4,6.8-2.6,13.5-3.6,20.4
					c0.3,3.2,1.9,5.5,4.8,6.9c4.3,1,8.2,0.1,11.7-2.8c6.3-3.6,12.2-7.7,17.8-12.4C1176.4,841.4,1178.6,838.1,1179.1,833.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1199.4,864.5c1.1-7.1,2.4-14.2,3.8-21.3c-0.2-3.3-1.9-5.6-4.9-6.8
					c-4.4-0.9-8.5,0.2-12.1,3.3c-6.5,3.9-12.6,8.3-18.4,13.2c-4.1,2.4-6.4,5.9-7,10.4c-1.4,6.7-2.6,13.5-3.6,20.3
					c0.2,3.3,1.8,5.6,4.8,6.9c4.4,1,8.4,0,12-3c6.5-3.7,12.6-8,18.4-12.7C1196.5,872.4,1198.8,869,1199.4,864.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1202.8,829.8c1-7.2,2.2-14.3,3.7-21.4c0.2-3.6-1.4-5.9-4.9-6.8
					c-5.7-0.2-10.8,1.7-15.3,5.6c-8.5,5.3-16.7,11.2-24.5,17.6c-5.1,3-8.5,7.2-10.2,12.7c-1.6,6.6-2.8,13.3-3.4,20.1
					c-0.2,3.6,1.4,5.9,4.8,6.9c5.6,0.3,10.7-1.4,15.2-5.2c8.5-5.1,16.6-10.8,24.4-16.9C1197.7,839.3,1201.1,835.2,1202.8,829.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1158.1,863c3.8-7.6,6.1-15.7,7-24.1c0.6-2.8,0-5.2-1.8-7.5c-1.5-2.4-3.6-3.8-6.4-4.3
					c-3.6,0.7-6.9,2.2-9.9,4.6c-3.2,2-5.8,4.5-7.7,7.7c-0.6,8.1-2.7,15.9-6,23.4c-0.5,2.7,0.1,5.2,1.9,7.4c1.5,2.4,3.6,3.8,6.3,4.3
					c3.5-0.6,6.6-2,9.4-4.2C1153.9,868.5,1156.3,866.1,1158.1,863z"/>
				<path opacity="0.1091" fill="#333333" d="M1181.9,899.8c3.8-7.6,6.2-15.7,7-24.1c0.9-4.8-2.9-11.4-8.1-11.8
					c-3.5,0.6-6.7,2.1-9.6,4.3c-3.1,1.9-5.6,4.4-7.4,7.4c-0.6,8.1-2.6,15.9-6,23.4c-0.4,2.7,0.2,5.2,2,7.3c1.4,2.4,3.5,3.9,6.2,4.4
					c3.3-0.5,6.4-1.8,9-3.9C1177.9,905.1,1180.2,902.7,1181.9,899.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1216.7,859.5c1-7.2,2.3-14.3,3.8-21.4c0.1-3.6-1.5-5.8-4.9-6.8
					c-5.5-0.3-10.5,1.4-14.8,5.2c-8.2,5.1-16.1,10.7-23.6,16.9c-5,2.9-8.2,7-9.7,12.3c-1.6,6.6-2.7,13.3-3.5,20.1
					c-0.2,3.5,1.4,5.8,4.8,6.9c5.5,0.4,10.4-1.2,14.7-4.9c8.2-4.9,16.1-10.3,23.6-16.3C1212,868.8,1215.2,864.7,1216.7,859.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1166.7,851.1c1.9-4.4,3.2-9,3.9-13.7c0.5-2.9,1-5.7,1.4-8.6c0.2-1.4,0.5-2.7,0.7-4.1
					c-0.6-1.3-1.1-2.7-1.7-4c-1.1-0.9-2.2-1.9-3.3-2.8c-2.5-1.1-4.8-1-7,0.5c-5,2.1-9.5,5.3-13.7,8.7c-2.1,1.4-4,3.1-5.5,5.1
					c-2.1,1.6-3,3.8-2.8,6.5c-0.6,3.5-1.1,6.9-1.7,10.4c0.2-1.4,0.5-2.7,0.7-4.1c-1,3.6-2.1,7.3-3.1,10.9c-1.2,2.9-0.9,5.5,0.9,8.1
					c1.7,2.6,4.1,3.8,7.2,3.7c4.7-1.5,9.1-3.7,13-6.7C1160,858.3,1163.6,855,1166.7,851.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1189.2,844.3c1.3-7.2,2.6-14.3,3.9-21.4c-0.2-3.3-1.8-5.6-4.9-6.8
					c-4.7-0.8-8.9,0.4-12.6,3.7c-6.8,4.1-13.3,8.8-19.4,13.9c-4.3,2.5-6.8,6.1-7.5,10.8c-1.4,6.8-2.6,13.5-3.7,20.4
					c0.1,3.3,1.7,5.6,4.8,6.9c4.6,0.9,8.8-0.2,12.5-3.4c6.8-4,13.2-8.4,19.3-13.4C1185.9,852.4,1188.4,848.9,1189.2,844.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1212.4,877.5c1.3-7.2,2.6-14.3,3.9-21.4c-0.2-3.3-1.8-5.6-4.9-6.9
					c-4.7-0.8-8.9,0.4-12.6,3.7c-6.9,4.1-13.3,8.7-19.5,13.9c-4.3,2.5-6.8,6.1-7.6,10.8c-1.4,6.8-2.6,13.5-3.7,20.3
					c0.1,3.3,1.7,5.6,4.8,6.9c4.6,0.9,8.8-0.2,12.6-3.4c6.8-3.9,13.3-8.4,19.4-13.4C1209,885.7,1211.6,882.2,1212.4,877.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1159.1,878.6c4-7.6,6.4-15.7,7.3-24.3c0.3-2.7-0.4-5.1-2.1-7.3
					c-1.4-2.4-3.5-3.9-6.1-4.5c-3.2,0.4-6.1,1.7-8.7,3.7c-2.8,1.7-5,4-6.5,6.8c-0.6,8.1-2.6,15.9-6,23.4c-0.2,2.7,0.6,5,2.2,7.2
					c1.4,2.3,3.4,3.9,5.9,4.6c3-0.3,5.6-1.4,8-3.2C1155.8,883.4,1157.8,881.3,1159.1,878.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1191.1,943.9c1-4.2,2.1-8.4,3.1-12.6c1.4-5.8-4.7-11.1-10.2-10
					c-4.1,2.1-7.8,4.9-10.9,8.4c-3.4,3.2-6.2,6.8-8.2,11c-0.9,3.9-1.7,7.7-2.6,11.6c-1.2,5.6,4.6,11.1,10.1,10.1
					c4.1-1.9,7.6-4.6,10.6-7.9C1186.5,951.5,1189.2,947.9,1191.1,943.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1164.6,925.4c1.4-4.7,2.9-9.3,4.3-14c0.4-2.9-0.5-5.4-2.7-7.4
					c-2.1-2.2-4.5-3.1-7.5-2.6c-3.9,1.9-7.4,4.5-10.3,7.7c-3.2,3-5.8,6.4-7.6,10.4c-1.2,4.3-2.3,8.6-3.5,12.9
					c-0.3,2.9,0.6,5.3,2.8,7.3c2,2.2,4.4,3.1,7.3,2.8c3.8-1.7,7.1-4.1,9.9-7.2C1160.5,932.5,1162.9,929.2,1164.6,925.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1171.1,879.1c0-3.2,0-6.4,0-9.6c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.4c-3.8,3.3-5.7,7.3-5.7,12.1c0,2.9,0.1,5.8,0.1,8.7c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C1169.2,887.8,1171.1,883.8,1171.1,879.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1191.4,910.1c0-3.1-0.1-6.3-0.1-9.4c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.6-11.3,5.3c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,2.9,0.1,5.8,0.1,8.7c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1189.9,918.3,1191.6,914.5,1191.4,910.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1195.3,874c-0.1-3.1-0.2-6.3-0.3-9.4c-0.3-3.7-2.3-5.7-6-5.9c-6,1.1-10.9,4-14.8,8.9
					c-8,7.2-15.5,14.9-22.6,23.1c-4.8,4-7.7,9.1-8.6,15c0.2,2.7,0.4,5.5,0.6,8.2c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6
					c7.9-7.1,15.4-14.6,22.5-22.5C1191.5,884.9,1194.4,879.9,1195.3,874z"/>
				<path opacity="0.1091" fill="#333333" d="M1149.5,909.9c1.2-4.5,2.5-9.1,3.7-13.6c0.2-2.9-0.7-5.3-2.9-7.2
					c-2-2.2-4.4-3.1-7.3-2.8c-3.7,1.6-6.9,3.9-9.5,6.9c-3,2.7-5.3,5.9-6.9,9.6c-1,4.3-2,8.6-3,12.9c-0.2,2.8,0.8,5.2,3,7.1
					c1.9,2.1,4.3,3.1,7.2,3c3.6-1.5,6.6-3.7,9.2-6.6C1145.9,916.6,1148,913.5,1149.5,909.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1174.3,945.8c0.9-4.3,1.9-8.5,2.8-12.8c-0.1-2.7-1.2-5-3.3-6.8
					c-1.8-2.1-4.1-3.2-6.8-3.3c-3.2,1.1-5.8,2.9-8.1,5.4c-2.5,2.2-4.3,4.9-5.4,8.1c-0.7,4-1.4,8.1-2.2,12.1c0.2,2.7,1.3,4.9,3.4,6.8
					c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C1171.6,951.4,1173.3,948.8,1174.3,945.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1208.3,905c-0.1-3.1-0.1-6.3-0.2-9.4c-0.5-3.5-2.5-5.5-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.5c-7.1,6.3-13.7,13.1-19.9,20.2c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,2.8,0.2,5.6,0.4,8.4
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1205.5,914.8,1207.9,910.3,1208.3,905z"/>
				<path opacity="0.1091" fill="#333333" d="M1160.5,891.8c0-3.2,0-6.4,0-9.6c-1-3-3-5-6-5.9c-4-0.5-7.4,0.9-10.1,4.2
					c-4.9,4.2-9.4,8.8-13.5,13.9c-3.2,2.8-4.5,6.2-3.9,10.2c0,3-0.1,6-0.1,9c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C1159.8,899.1,1161.1,895.7,1160.5,891.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1181.5,889.5c0-3.2-0.1-6.4-0.1-9.5c-0.7-3.3-2.7-5.3-6-5.9c-5,0.2-9.1,2.3-12.3,6.4
					c-6.4,5.6-12.3,11.6-17.7,18.1c-3.9,3.4-6,7.5-6.1,12.5c0.1,2.9,0.1,5.8,0.2,8.6c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C1179.4,898.5,1181.4,894.4,1181.5,889.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1204.4,923c0-3.1-0.1-6.3-0.1-9.4c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,2.9,0.1,5.8,0.1,8.7c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C1202.9,931.3,1204.6,927.5,1204.4,923z"/>
				<path opacity="0.1091" fill="#333333" d="M1152.5,923.9c0.9-4.3,1.9-8.6,2.8-12.8c-0.1-2.7-1.2-5-3.3-6.9
					c-1.8-2.1-4.1-3.2-6.9-3.2c-3.2,1.1-5.9,2.9-8.2,5.5c-2.6,2.3-4.4,5-5.5,8.2c-0.7,4.1-1.5,8.1-2.2,12.2c0.2,2.7,1.3,5,3.3,6.8
					c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2C1149.8,929.6,1151.5,927,1152.5,923.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1169.1,969.9c0.8-1.5,1.7-3,2.5-4.5c0.5-3-0.3-5.5-2.5-7.6c-2.1-2.2-4.6-3.1-7.6-2.6
					c-4.1,2-7.7,4.7-10.8,8.1c-3.4,3.1-6.1,6.7-8.1,10.8c-0.7,1.4-1.4,2.7-2.2,4.1c-0.5,3,0.4,5.5,2.6,7.5c2.1,2.2,4.6,3.1,7.5,2.6
					c4.1-1.9,7.6-4.6,10.6-7.9C1164.5,977.5,1167.2,973.9,1169.1,969.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1140.1,956c0.9-1.5,1.7-3.1,2.6-4.6c0.9-1.5,1.7-3.1,2.6-4.6
					c-0.1-1.3-0.2-2.6-0.3-3.8c-0.8-1.2-1.6-2.4-2.4-3.6c-1.5-1.8-3.3-2.7-5.5-2.6c-1.6-0.5-3-0.1-4.2,1.1c-1.9,0.9-3.6,2.1-5.1,3.7
					c-1.9,1.5-3.7,3.2-5.2,5.2c-2.2,2.1-3.9,4.6-5,7.4c-0.8,1.4-1.5,2.9-2.3,4.3c-0.3,2.9,0.6,5.3,2.8,7.3c1.5,1.8,3.3,2.7,5.4,2.6
					c2.4,0.6,4.6-0.2,6.4-2.3C1133.8,963.1,1137.2,959.8,1140.1,956z"/>
				<path opacity="0.1091" fill="#333333" d="M1149.1,905.1c0-0.7,0.1-1.4,0.1-2c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.3-11.8,11.1-17,17.3c-3.8,3.3-5.7,7.3-5.7,12.1c0,0.4-0.1,0.9-0.1,1.3c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C1147.2,913.8,1149.1,909.8,1149.1,905.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1169.4,936.1c0.1-0.7,0.1-1.4,0.2-2.1c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.6-11.3,5.3c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c-0.1,0.5-0.1,0.9-0.2,1.4
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1167.9,944.3,1169.6,940.5,1169.4,936.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1173.3,900c-0.2-0.5-0.3-1-0.5-1.5c-0.6-1.4-1.2-2.8-1.7-4.2
					c-1.4-0.6-2.8-1.1-4.3-1.7c-2,0.6-3.9,1.2-5.9,1.8c-5.3,3.7-10.2,7.9-14.6,12.5c-7.1,6.8-14.1,13.9-20,21.8
					c-2.4,2-3.8,4.5-4.3,7.5c-1.4,2.5-1.1,4.9,0.9,7.2c0.1,0.3,0.3,0.6,0.4,0.9c3.3,2.3,6.6,2.3,10.1,0c5.3-3.6,10.2-7.8,14.7-12.3
					c5.9-5.4,11.5-11,16.7-17.1C1169.5,910.9,1172.4,905.9,1173.3,900z"/>
				<path opacity="0.1091" fill="#333333" d="M1125.3,940.2c0.8-1.5,1.6-2.9,2.4-4.4c0.8-1.4,1.5-2.9,2.3-4.3
					c-0.2-1.2-0.3-2.5-0.5-3.7c-0.8-1.2-1.6-2.3-2.5-3.5c-1.4-1.8-3.2-2.7-5.4-2.7c-1.5-0.6-2.8-0.3-4,0.9c-1.8,0.8-3.4,1.9-4.7,3.4
					c-1.8,1.4-3.4,2.9-4.7,4.7c-2,1.9-3.5,4.2-4.5,6.9c-0.7,1.4-1.4,2.8-2.1,4.2c-0.2,2.8,0.8,5.2,3,7.1c1.4,1.8,3.2,2.7,5.3,2.7
					c2.3,0.7,4.3,0,6.1-2C1119.5,946.8,1122.6,943.7,1125.3,940.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1150.7,975.4c0.9-1.5,1.8-3.1,2.6-4.6c1.9-2.2,2.2-4.6,0.9-7.1
					c-1.4-2.6-3.4-4.6-5.9-6c-2.5-1.3-4.9-1-7.1,0.9c-3,2.1-5.6,4.7-7.7,7.7c-0.8,1.5-1.6,3-2.4,4.4c-1.9,2.2-2.1,4.5-0.8,7
					c1.4,2.6,3.4,4.5,5.9,6c2.5,1.3,4.8,1.1,7-0.8C1146.1,980.9,1148.6,978.4,1150.7,975.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1186.3,931c-0.1-0.6-0.1-1.1-0.2-1.7c-0.5-3.5-2.5-5.4-6-5.9c-5.4,0.5-9.8,3-13.4,7.4
					c-7,6.2-13.6,12.9-19.8,20.1c-4.3,3.6-6.7,8.1-7.2,13.5c0.1,0.4,0.1,0.7,0.2,1.1c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1183.5,940.8,1185.9,936.3,1186.3,931z"/>
				<path opacity="0.1091" fill="#333333" d="M1138.3,922.1c0.3-0.9,0.5-1.7,0.8-2.6c1-3.1,0.3-5.9-2-8.2c-2.4-2.3-5.1-2.9-8.2-1.8
					c-4.9,2.8-9.2,6.4-13,10.6c-4.1,3.9-7.5,8.3-10.2,13.2c-0.2,0.6-0.4,1.2-0.5,1.9c-1,3.1-0.3,5.8,2,8.1c2.3,2.3,5,2.9,8.1,2
					c4.8-2.7,9.1-6.1,12.9-10.2C1132.2,931.3,1135.6,927,1138.3,922.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1159.5,915.5c0-0.7,0-1.3,0-2c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.2-9,2.3-12.3,6.3
					c-6.3,5.6-12.2,11.6-17.7,18c-3.9,3.3-6,7.5-6.1,12.4c0,0.4,0,0.9,0,1.3c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C1157.4,924.5,1159.4,920.4,1159.5,915.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1182.4,949c0.1-0.7,0.1-1.4,0.2-2.1c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.6-11.3,5.3c-5.7,4.9-11,10.3-15.8,16c-3.6,3.1-5.3,6.9-5.1,11.4c-0.1,0.5-0.1,0.9-0.2,1.4
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1180.9,957.3,1182.6,953.5,1182.4,949z"/>
				<path opacity="0.1091" fill="#333333" d="M1128.8,953.6c0.9-1.5,1.8-3.1,2.6-4.6c1.9-2.2,2.3-4.6,1-7.2c-1.4-2.6-3.4-4.6-6-5.9
					c-2.6-1.3-5-1-7.2,1c-3.1,2.2-5.7,4.8-7.8,7.9c-0.8,1.5-1.6,3-2.4,4.4c-1.9,2.2-2.2,4.6-0.9,7.1c1.4,2.6,3.4,4.5,6,5.9
					c2.5,1.3,4.9,1,7.1-0.9C1124.2,959.2,1126.7,956.6,1128.8,953.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1121.2,978.6c0.2-5,0.4-10.1,0.6-15.1c0.2-5.5-6.7-10-11.7-8.2
					c-3.5,2.4-6.4,5.4-8.6,9c-2.6,3.4-4.5,7.1-5.5,11.2c0,4.7-0.1,9.4-0.1,14.1c0,5.4,6.6,10,11.6,8.3c3.4-2.2,6.2-5.1,8.4-8.5
					C1118.4,986.1,1120.2,982.5,1121.2,978.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1094.4,961c0.5-5.5,1-11,1.5-16.5c0.5-5.7-6.8-10-11.7-8.2c-3.6,2.5-6.5,5.6-8.9,9.4
					c-2.7,3.5-4.6,7.3-5.8,11.5c-0.3,5.1-0.6,10.2-0.9,15.3c-0.3,5.5,6.8,9.9,11.7,8.3c3.5-2.3,6.3-5.2,8.6-8.8
					C1091.5,968.7,1093.3,965,1094.4,961z"/>
				<path opacity="0.1091" fill="#333333" d="M1099.9,913.9c-0.7-3.8-1.4-7.7-2-11.5c-1.2-3.2-3.5-4.8-6.9-4.8
					c-4.9,1.1-8.7,4-11.3,8.5c-5.4,6.7-10.4,13.8-14.8,21.3c-3.4,4-4.8,8.5-4.1,13.5c0.7,3.5,1.3,7.1,2,10.6
					c1.2,3.2,3.5,4.8,6.9,4.9c4.9-1,8.6-3.8,11.3-8.3c5.5-6.6,10.4-13.5,14.8-20.8C1099.2,923.3,1100.5,918.8,1099.9,913.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1120.3,945c-0.8-3.7-1.5-7.4-2.3-11.1c-1.4-2.9-3.7-4.6-6.9-4.8
					c-4.4,0.5-7.7,2.8-10.1,6.8c-4.7,5.6-8.8,11.5-12.5,17.8c-2.9,3.6-3.9,7.5-2.9,11.8c0.7,3.5,1.4,7,2.1,10.5
					c1.3,3,3.6,4.6,6.9,4.9c4.4-0.4,7.7-2.6,10.1-6.6c4.7-5.5,8.9-11.4,12.6-17.6C1120.3,953.1,1121.3,949.2,1120.3,945z"/>
				<path opacity="0.1091" fill="#333333" d="M1123.6,908.5c-0.7-3.8-1.5-7.6-2.2-11.4c-0.9-3.6-3.2-5.2-6.9-4.8
					c-5.8,2.1-10.2,6-13.4,11.5c-6.8,8.6-13.1,17.6-18.8,27c-4.1,4.9-6.2,10.4-6.2,16.5c0.8,3.4,1.5,6.8,2.3,10.2
					c0.9,3.6,3.2,5.2,6.9,4.9c5.7-2,10.2-5.7,13.3-11.2c6.8-8.4,13.1-17.3,18.8-26.4C1121.5,920,1123.6,914.6,1123.6,908.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1078.2,946.5c0.5-5.5,1-11.1,1.5-16.6c0.5-5.6-6.9-9.9-11.7-8.2
					c-3.5,2.5-6.4,5.5-8.7,9.2c-2.7,3.4-4.5,7.2-5.6,11.4c-0.3,5.1-0.6,10.2-0.9,15.4c-0.3,5.4,6.9,9.9,11.7,8.3
					c3.4-2.3,6.2-5.1,8.4-8.6C1075.4,954.1,1077.2,950.5,1078.2,946.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1103.2,981.6c0.1-5,0.1-10.1,0.2-15.1c-0.7-2.6-2.2-4.6-4.5-6.1
					c-2.1-1.7-4.5-2.5-7.2-2.3c-2.8,1.4-5,3.4-6.6,6c-2,2.4-3.2,5.2-3.6,8.2c0.2,4.7,0.3,9.4,0.5,14.1c0.8,2.5,2.3,4.5,4.6,5.9
					c2.1,1.7,4.4,2.5,7.1,2.4c2.6-1.2,4.7-3.1,6.3-5.6C1101.9,987.1,1102.9,984.5,1103.2,981.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1136.4,940.6c-0.7-3.8-1.4-7.7-2.1-11.5c-1.1-3.3-3.4-4.9-6.9-4.8
					c-5,1.2-8.9,4.2-11.5,8.9c-5.6,6.9-10.7,14.2-15.3,21.9c-3.5,4.1-5,8.7-4.4,13.9c0.7,3.5,1.4,7,2.1,10.6
					c1.1,3.2,3.4,4.9,6.8,4.9c5-1.1,8.8-4,11.5-8.6c5.6-6.8,10.8-13.9,15.4-21.5C1135.5,950.3,1136.9,945.7,1136.4,940.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1089.2,926.8c-0.7-3.8-1.4-7.5-2.1-11.3c-1.4-2.9-3.7-4.5-6.9-4.8
					c-4.2,0.4-7.5,2.5-9.7,6.3c-4.4,5.3-8.4,10.9-11.8,16.9c-2.8,3.4-3.6,7.2-2.5,11.3c0.7,3.6,1.3,7.2,2,10.8
					c1.4,2.9,3.7,4.5,6.9,4.9c4.2-0.3,7.4-2.4,9.7-6.2c4.5-5.2,8.4-10.8,11.9-16.7C1089.5,934.6,1090.3,930.9,1089.2,926.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1110.2,924.6c-0.7-3.8-1.4-7.7-2-11.5c-1.2-3.2-3.5-4.8-6.9-4.8
					c-4.9,1.1-8.7,4-11.3,8.5c-5.5,6.7-10.4,13.8-14.8,21.3c-3.4,4-4.8,8.5-4.1,13.5c0.7,3.5,1.4,7.1,2,10.6
					c1.2,3.2,3.5,4.8,6.9,4.9c4.9-1,8.6-3.8,11.3-8.3c5.5-6.6,10.4-13.5,14.8-20.8C1109.4,934,1110.8,929.6,1110.2,924.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1133.1,958.2c-0.7-3.8-1.4-7.5-2.1-11.3c-1.4-2.9-3.7-4.5-6.9-4.8
					c-4.2,0.4-7.5,2.5-9.7,6.3c-4.5,5.3-8.4,10.9-11.8,16.9c-2.8,3.4-3.7,7.2-2.6,11.3c0.7,3.6,1.3,7.2,2,10.7
					c1.4,2.9,3.7,4.5,6.8,4.9c4.2-0.3,7.5-2.3,9.8-6.1c4.5-5.2,8.5-10.8,12-16.7C1133.3,966,1134.2,962.2,1133.1,958.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1082.1,958.9c0-5,0-9.9,0.1-14.9c-0.5-2.7-1.9-4.8-4.3-6.3c-2.2-1.8-4.7-2.4-7.4-2
					c-3.1,1.7-5.5,4.1-7.4,7.1c-2.2,2.8-3.7,5.9-4.3,9.3c0.2,4.7,0.3,9.5,0.5,14.2c0.5,2.7,2,4.8,4.3,6.2c2.2,1.7,4.6,2.4,7.3,2.1
					c3-1.6,5.3-3.9,7.1-6.8C1080.2,965.2,1081.5,962.2,1082.1,958.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1238.1,823.4c1.7-5.3,3.5-10.7,5.2-16c1.5-2.8,1.4-5.5-0.3-8.2c-1.6-2.7-4-4.1-7.2-4
					c-5,1.3-9.6,3.4-14,6.3c-4.5,2.5-8.5,5.7-12,9.5c-1.6,5.1-3.2,10.2-4.7,15.2c-1.5,2.8-1.4,5.5,0.2,8.2c1.6,2.7,3.9,4.1,7,4.2
					c4.9-1.2,9.5-3.1,13.8-5.9C1230.7,830.3,1234.6,827.2,1238.1,823.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1211.5,804.7c2.6-5.9,5.2-11.8,7.8-17.7c2.2-5-2-11.8-7.4-12.2
					c-4.2,0.8-8,2.4-11.6,4.8c-3.8,2-7,4.7-9.6,8c-2.3,5.6-4.6,11.3-6.9,16.9c-2,4.9,1.9,11.8,7.3,12.3c4-0.7,7.8-2.1,11.2-4.4
					C1205.9,810.4,1209,807.9,1211.5,804.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1218.2,760.3c1.2-5.1,2.4-10.2,3.6-15.2c-0.1-3.2-1.6-5.6-4.4-7.2
					c-4.1-1.3-7.9-0.7-11.6,2c-6.3,3.1-12.2,6.8-17.9,11c-4,2-6.3,5.2-7,9.4c-1.2,4.8-2.4,9.7-3.6,14.5c0.1,3.2,1.5,5.6,4.3,7.2
					c4,1.4,7.9,0.8,11.5-1.8c6.3-3,12.2-6.5,17.9-10.6C1215.2,767.6,1217.5,764.5,1218.2,760.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1238.3,791.1c1.3-5.1,2.6-10.2,3.8-15.4c0-3.3-1.5-5.7-4.4-7.2
					c-4.4-1.2-8.5-0.4-12.3,2.4c-6.8,3.4-13.2,7.3-19.3,11.8c-4.3,2.1-6.9,5.4-7.8,9.9c-1.2,4.8-2.4,9.7-3.6,14.5
					c0,3.3,1.4,5.7,4.3,7.2c4.3,1.3,8.4,0.6,12.2-2.2c6.8-3.3,13.2-7.1,19.3-11.4C1234.8,798.8,1237.4,795.5,1238.3,791.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1241.4,756.8c1.3-5.1,2.5-10.3,3.8-15.4c0.4-3.5-1.1-5.9-4.4-7.2
					c-5.5-0.7-10.6,0.7-15.2,4.2c-8.6,4.5-17,9.6-25,15.3c-5.2,2.5-8.8,6.4-10.7,11.7c-1.1,4.8-2.2,9.5-3.4,14.3
					c-0.4,3.5,1,5.9,4.3,7.2c5.4,0.8,10.5-0.5,15.1-3.9c8.6-4.4,16.9-9.3,24.8-14.7C1235.9,765.8,1239.5,762,1241.4,756.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1196.3,789.8c2.4-5.9,4.8-11.9,7.2-17.8c0.7-2.7,0.2-5.2-1.3-7.5
					c-1.3-2.5-3.3-4.1-6-4.8c-3.5,0.4-6.7,1.5-9.6,3.5c-3.2,1.6-5.7,3.9-7.7,6.8c-2.1,5.8-4.3,11.6-6.4,17.3
					c-0.6,2.7-0.1,5.2,1.5,7.5c1.3,2.5,3.2,4.1,5.9,4.9c3.4-0.3,6.4-1.4,9.3-3.3C1192,794.8,1194.5,792.6,1196.3,789.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1220.7,826.2c2.4-5.9,4.7-11.8,7.1-17.7c1.8-4.6-2.4-11.6-7.3-12.3
					c-3.6,0.4-6.9,1.6-9.9,3.7c-3.2,1.7-5.9,4-8,6.9c-2.1,5.8-4.3,11.5-6.4,17.3c-0.7,2.7-0.2,5.2,1.3,7.5c1.3,2.5,3.3,4.1,5.9,4.8
					c3.5-0.3,6.7-1.5,9.6-3.4C1216.2,831.3,1218.7,829.1,1220.7,826.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1255.8,786.2c1.2-5.1,2.5-10.3,3.7-15.4c0.4-3.5-1-5.9-4.4-7.2
					c-5.5-0.7-10.6,0.7-15.3,4.2c-8.7,4.5-17,9.6-25,15.2c-5.2,2.5-8.8,6.4-10.7,11.6c-1.1,4.8-2.2,9.5-3.3,14.3
					c-0.4,3.5,1,5.9,4.3,7.2c5.4,0.8,10.5-0.5,15.1-3.9c8.6-4.3,16.9-9.2,24.8-14.7C1250.3,795.1,1253.8,791.3,1255.8,786.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1204.6,777.9c2.2-5.6,4.4-11.3,6.6-16.9c1.3-2.8,1.2-5.5-0.5-8.1
					c-1.6-2.7-3.9-4.1-7-4.1c-4.7,1.1-9.1,3.1-13.1,5.8c-4.3,2.4-8,5.4-11.2,9.1c-2,5.4-4,10.7-6,16.1c-1.3,2.8-1.1,5.4,0.5,8
					c1.5,2.7,3.8,4.1,6.8,4.3c4.6-1,8.9-2.8,12.9-5.5C1197.9,784.4,1201.5,781.5,1204.6,777.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1228.2,770.8c1.3-5.1,2.6-10.2,3.8-15.4c0-3.3-1.4-5.7-4.4-7.2
					c-4.5-1.2-8.7-0.3-12.6,2.6c-6.9,3.5-13.6,7.6-19.8,12.1c-4.3,2.2-7,5.5-8,10c-1.2,4.8-2.4,9.6-3.6,14.5
					c-0.1,3.3,1.4,5.7,4.3,7.2c4.4,1.3,8.6,0.5,12.5-2.3c6.9-3.4,13.5-7.3,19.8-11.7C1224.5,778.6,1227.2,775.3,1228.2,770.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1251.3,804.1c1.3-5.2,2.6-10.3,4-15.5c0.1-3.4-1.3-5.7-4.4-7.2
					c-4.7-1.1-9.1-0.1-13.1,2.9c-7.3,3.7-14.2,7.9-20.9,12.7c-4.5,2.2-7.4,5.7-8.6,10.3c-1.2,4.8-2.4,9.7-3.7,14.5
					c-0.1,3.3,1.3,5.8,4.3,7.3c4.6,1.2,9,0.3,13-2.6c7.2-3.5,14.2-7.6,20.8-12.2C1247.3,812.1,1250.1,808.7,1251.3,804.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1197.5,805.2c2.5-6,4.9-12,7.4-17.9c0.4-2.6-0.1-5.1-1.6-7.3c-1.2-2.4-3.1-4.1-5.7-5
					c-3.1,0.1-6,1.1-8.5,2.9c-2.8,1.4-5,3.5-6.6,6.1c-2.1,5.8-4.3,11.6-6.4,17.3c-0.3,2.6,0.2,5.1,1.8,7.3c1.2,2.4,3.1,4.1,5.5,5
					c2.9,0,5.6-0.9,8-2.6C1194,809.7,1196,807.7,1197.5,805.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1215.6,795.7c3-7,5.8-14.2,8.4-21.3c0.1-3.1-1-5.7-3.5-7.7c-3.5-1.9-7.1-1.9-10.8,0.1
					c-6,2.1-11.7,4.7-17.2,7.9c-3.9,1.5-6.3,4.2-7.1,8.1c-2.5,6.9-5.3,13.8-8.2,20.6c-0.2,3.1,1,5.7,3.4,7.7c3.4,2,7,2,10.7,0.1
					c6-1.9,11.7-4.4,17.2-7.5C1212.3,802.2,1214.7,799.5,1215.6,795.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1186.7,781c5.8-6.6,9.7-14.2,11.7-22.7c1.4-2.6,1.4-5.2,0-7.9c-1.1-2.8-3.1-4.5-6-5.1
					c-4.3,0.3-8.3,1.5-12.1,3.5c-4,1.6-7.5,3.9-10.5,6.9c-1.8,8-5.3,15.3-10.5,21.7c-2.8,4.6,0.7,11.9,5.8,13.1
					c4.1-0.2,7.9-1.2,11.6-3C1180.5,786,1183.9,783.8,1186.7,781z"/>
				<path opacity="0.1091" fill="#333333" d="M1194,737.1c2.6-7.1,5.3-14.1,8-21.2c0.2-3.2-1-5.7-3.5-7.6c-3.6-1.9-7.3-1.8-11.1,0.3
					c-6.2,2.2-12.1,4.9-17.8,8.2c-4,1.5-6.5,4.3-7.4,8.2c-2.6,6.8-5.2,13.6-7.8,20.4c-0.2,3.2,0.9,5.7,3.4,7.7
					c3.6,1.9,7.2,1.9,11-0.1c6.2-2.1,12.1-4.7,17.7-7.9C1190.6,743.7,1193,741,1194,737.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1214.2,767.8c2.6-7.1,5.3-14.2,8.1-21.2c0.4-3.3-0.8-5.8-3.5-7.6
					c-4.2-1.7-8.4-1.4-12.6,1c-7.2,2.6-14.1,5.8-20.7,9.5c-4.5,1.6-7.5,4.6-8.9,8.9c-2.8,6.7-5.3,13.5-7.8,20.3
					c-0.4,3.3,0.7,5.8,3.4,7.7c4.1,1.8,8.3,1.6,12.5-0.7c7.1-2.5,14-5.5,20.6-9.1C1209.7,774.9,1212.7,772,1214.2,767.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1216.8,733.9c2.5-7.2,5.3-14.3,8.1-21.3c0.7-3.4-0.5-6-3.5-7.6
					c-5.2-1.4-10.1-0.7-15,2.1c-8.7,3.3-17.1,7.2-25.3,11.7c-5.3,1.8-9,5.2-11.3,10c-2.9,6.6-5.4,13.3-7.6,20.2
					c-0.7,3.4,0.4,6,3.4,7.7c5.1,1.5,10,0.9,14.8-1.8c8.6-3.1,17-6.8,25.1-11.1C1210.8,742,1214.5,738.7,1216.8,733.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1171.2,766.5c5.4-7.1,9.4-14.9,12-23.4c1.8-4.4-1-11.7-5.8-13.1
					c-3.4-0.1-6.6,0.6-9.7,2.2c-3.2,1.2-5.9,3.1-8.1,5.7c-2.4,8.2-5.9,15.8-10.7,22.8c-0.8,2.6-0.6,5.1,0.7,7.5
					c0.9,2.6,2.6,4.4,5.1,5.6c3.2,0.2,6.2-0.4,9-1.9C1166.7,770.7,1169.2,769,1171.2,766.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1193.8,803.7c6.2-7.1,10.8-15.1,13.9-23.9c2.1-4.7-0.6-11.8-5.9-13
					c-3.9,0.1-7.5,1.1-11,2.9c-3.6,1.4-6.8,3.5-9.4,6.3c-2.7,8.4-6.8,16-12.3,22.9c-1,2.6-0.9,5.1,0.4,7.7c1,2.6,2.8,4.5,5.4,5.4
					c3.6,0,7-0.8,10.2-2.4C1188.5,808.4,1191.4,806.4,1193.8,803.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1231.9,762.9c2.5-7.2,5.2-14.3,8.1-21.3c0.9-3.5-0.3-6-3.5-7.6
					c-5.5-1.3-10.7-0.5-15.8,2.4c-9.2,3.5-18.2,7.7-26.9,12.4c-5.5,1.9-9.6,5.4-12.2,10.4c-2.9,6.5-5.5,13.2-7.5,20.1
					c-0.9,3.5,0.3,6,3.4,7.7c5.4,1.4,10.6,0.7,15.6-2.1c9.2-3.4,18.1-7.3,26.7-11.8C1225.2,771.2,1229.3,767.8,1231.9,762.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1179,754.6c5.9-6.6,9.9-14.2,12-22.8c1.5-2.6,1.6-5.3,0.2-8c-1.2-2.8-3.3-4.5-6.3-5
					c-4.6,0.5-9,1.8-13.1,4c-4.3,1.7-8.1,4.2-11.5,7.4c-1.9,8.1-5.5,15.4-10.8,21.9c-3,4.8,0.3,12,5.8,13.1
					c4.4-0.3,8.6-1.5,12.6-3.5C1172.2,759.9,1175.8,757.6,1179,754.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1204,747.5c2.6-7.1,5.3-14.2,8.1-21.2c0.4-3.3-0.8-5.8-3.5-7.6
					c-4.2-1.7-8.3-1.4-12.4,0.9c-7,2.6-13.8,5.7-20.3,9.4c-4.4,1.6-7.3,4.6-8.7,8.8c-2.8,6.7-5.3,13.5-7.8,20.3
					c-0.4,3.2,0.8,5.8,3.4,7.7c4.1,1.8,8.2,1.6,12.3-0.7c7-2.4,13.7-5.4,20.2-9C1199.7,754.6,1202.6,751.7,1204,747.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1227.4,780.6c2.6-7.1,5.4-14.2,8.2-21.3c0.6-3.3-0.6-5.9-3.5-7.7
					c-4.7-1.6-9.3-1.1-13.8,1.5c-8,3-15.7,6.5-23.1,10.6c-4.9,1.7-8.3,4.9-10.2,9.5c-2.8,6.7-5.4,13.4-7.8,20.2
					c-0.6,3.3,0.5,5.9,3.4,7.7c4.6,1.7,9.2,1.2,13.7-1.2c7.9-2.8,15.5-6.1,22.9-10.1C1222.2,788.2,1225.5,785.2,1227.4,780.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1172.6,781.6c5.5-7.1,9.6-14.9,12.2-23.5c0.8-2.6,0.5-5.1-0.8-7.5
					c-0.9-2.6-2.6-4.4-5-5.6c-3.1-0.2-6.1,0.4-8.9,1.8c-3,1.1-5.4,2.9-7.3,5.3c-2.4,8.2-5.9,15.8-10.7,22.8c-0.6,2.6-0.3,5,1,7.4
					c0.9,2.5,2.5,4.4,4.8,5.7c2.8,0.3,5.5-0.2,8.1-1.5C1168.8,785.6,1171,783.9,1172.6,781.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1201.1,789.9c1-5.5,2-10.9,3-16.4c1-5.8-4.5-11.2-10.2-10c-4.1,2.1-7.8,4.9-10.9,8.4
					c-3.4,3.2-6.2,6.9-8.2,11.1c-0.8,5.1-1.6,10.2-2.4,15.4c-0.9,5.6,4.4,11.2,10.1,10.1c4.1-1.9,7.6-4.6,10.6-7.9
					C1196.5,797.5,1199.2,793.9,1201.1,789.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1174.6,771.4c1.4-5.9,2.8-11.9,4.2-17.8c1.3-5.5-5-11-10.2-10
					c-3.9,1.9-7.4,4.5-10.3,7.8c-3.2,3-5.8,6.5-7.6,10.4c-1.1,5.6-2.3,11.1-3.4,16.7c-1.1,5.4,4.9,10.9,10.1,10.1
					c3.8-1.7,7.1-4.1,9.9-7.2C1170.5,778.5,1172.9,775.2,1174.6,771.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1181.1,725.1c0-4.5,0-9,0-13.5c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.5c-3.8,3.3-5.7,7.3-5.7,12.1c0,4.2,0.1,8.4,0.1,12.6c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C1179.2,733.8,1181.1,729.8,1181.1,725.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1201.4,756.1c0-4.5-0.1-8.9-0.1-13.4c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.3c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,4.2,0.1,8.4,0.1,12.6
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1199.9,764.3,1201.6,760.5,1201.4,756.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1205.3,720c-0.1-4.5-0.2-8.9-0.3-13.4c-0.3-3.7-2.3-5.7-6-5.9
					c-6,1.1-10.9,4.1-14.8,8.9c-8,7.3-15.5,15-22.5,23.1c-4.8,4.1-7.7,9.1-8.6,15c0.2,4.1,0.3,8.1,0.5,12.2c0.3,3.7,2.3,5.7,6,5.9
					c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5C1201.5,730.9,1204.4,725.9,1205.3,720z"/>
				<path opacity="0.1091" fill="#333333" d="M1159.5,755.9c1.2-5.8,2.4-11.7,3.6-17.5c1.1-5.2-5.2-10.8-10.2-10.1
					c-3.7,1.6-6.9,3.9-9.6,7c-3,2.7-5.3,5.9-6.9,9.6c-1,5.5-1.9,11.1-2.9,16.6c-0.9,5.1,5.2,10.8,10.1,10.1c3.6-1.5,6.6-3.7,9.2-6.6
					C1155.9,762.6,1158,759.5,1159.5,755.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1184.3,791.8c0.9-5.5,1.7-11,2.6-16.6c-0.1-2.7-1.2-5-3.3-6.9
					c-1.8-2.1-4.1-3.2-6.8-3.3c-3.2,1.1-5.9,2.9-8.1,5.4c-2.5,2.2-4.3,4.9-5.4,8.1c-0.6,5.3-1.2,10.6-1.9,15.9
					c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C1181.6,797.4,1183.3,794.8,1184.3,791.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1218.3,751c-0.1-4.5-0.1-8.9-0.2-13.4c-0.5-3.5-2.5-5.5-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.5c-7.1,6.3-13.7,13.1-19.9,20.3c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,4.1,0.2,8.2,0.3,12.4
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1215.5,760.8,1217.9,756.3,1218.3,751z"/>
				<path opacity="0.1091" fill="#333333" d="M1170.5,737.8c0-4.5,0-9,0-13.5c-1-3-3-5-6-5.9c-4-0.5-7.4,0.9-10.1,4.2
					c-4.9,4.2-9.4,8.8-13.5,13.9c-3.2,2.8-4.5,6.3-3.9,10.3c0,4.3-0.1,8.6-0.1,12.9c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C1169.8,745.1,1171.1,741.7,1170.5,737.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1191.5,735.5c0-4.5,0-9-0.1-13.5c-0.7-3.3-2.7-5.3-6-5.9c-5,0.2-9.1,2.4-12.3,6.4
					c-6.4,5.6-12.3,11.7-17.7,18.2c-3.9,3.4-6,7.5-6.1,12.5c0.1,4.2,0.1,8.4,0.2,12.6c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C1189.4,744.5,1191.4,740.4,1191.5,735.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1214.4,769c0-4.5-0.1-8.9-0.1-13.4c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,4.2,0.1,8.4,0.1,12.6c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C1212.9,777.3,1214.6,773.5,1214.4,769z"/>
				<path opacity="0.1091" fill="#333333" d="M1162.5,769.9c0.9-5.5,1.7-11.1,2.6-16.6c-0.1-2.7-1.2-5-3.2-6.9
					c-1.8-2.1-4.1-3.2-6.9-3.2c-3.2,1.1-5.9,3-8.2,5.5c-2.6,2.3-4.4,5-5.5,8.2c-0.6,5.3-1.3,10.6-1.9,15.9c0.2,2.7,1.3,5,3.3,6.8
					c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2C1159.8,775.6,1161.5,773,1162.5,769.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1200.9,806.2c1.3-4.2,2.7-8.4,4-12.6c1.1-3.4,1.8-6.9,2.3-10.4
					c0.8-4.3,1.5-8.6,2.1-12.9c1.1-6.7,1.8-13.4,2.2-20.1c-0.5-3.1-2-5.4-4.7-7c-3.5-1.4-6.8-1-10,1.4c-5.2,2.7-10.1,6-14.6,9.7
					c-3.4,2-5.1,4.9-5.2,8.7c-0.8,6.5-1.8,12.9-3,19.3c-1.1,6.2-2.3,12.3-3.7,18.5c0.4-1.4,0.8-2.7,1.1-4.1
					c-1.2,3.9-2.4,7.9-3.7,11.8c-1.6,2.7-1.5,5.5,0,8.2c1.5,2.8,3.8,4.2,7,4.3c5-1.1,9.7-3,14-5.7
					C1193.4,812.9,1197.4,809.9,1200.9,806.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1174.2,787.5c4.2-5.5,6.7-11.5,7.5-18.1c0.6-3.5,1.1-6.9,1.6-10.4
					c0.6-3.8,1.1-7.6,1.6-11.3c0.8-5.7,1.4-11.5,1.7-17.3c-0.6-2.9-2.2-5.3-4.7-7c-3-1.6-5.9-1.4-8.8,0.6c-4.4,2.2-8.5,4.9-12.2,8.2
					c-3,1.9-4.3,4.5-3.9,7.9c-0.5,4.3-0.9,8.5-1.4,12.8c0.2-1.3,0.3-2.5,0.5-3.8c-0.8,5.5-1.6,11-2.5,16.5c0.2-1.3,0.4-2.5,0.6-3.8
					c-0.8,5.1-1.6,10.2-2.4,15.3c0.2-1.3,0.4-2.5,0.6-3.8c-2,4.5-4,8.9-6,13.4c-2.2,4.9,1.7,11.8,7,12.5c4-0.6,7.8-2,11.2-4.2
					C1168.5,793.2,1171.6,790.7,1174.2,787.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1181,743.2c2-8.2,3.7-16.4,5.1-24.7c1.5-8.9,2.5-17.8,3-26.8c-0.2-3.2-1.8-5.6-4.7-7
					c-4.2-1.1-8.1-0.3-11.7,2.6c-6.3,3.5-12.2,7.4-17.8,11.9c-4,2.2-6.2,5.5-6.8,9.8c-0.6,8.2-1.5,16.4-2.9,24.5
					c-1.4,7.7-3.1,15.3-4.9,22.9c0,3.2,1.4,5.6,4.2,7.3c3.9,1.5,7.8,1,11.4-1.5c6.3-2.8,12.2-6.2,17.9-10.2
					C1177.9,750.4,1180.3,747.4,1181,743.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1201.2,774c2.1-8.2,3.8-16.4,5.3-24.7c1.4-8.9,2.5-17.7,3-26.7
					c-0.1-3.3-1.7-5.6-4.7-7c-4.4-1-8.5-0.1-12.2,2.9c-6.6,3.7-12.9,7.8-18.8,12.6c-4.2,2.3-6.6,5.7-7.3,10.2
					c-0.9,8.4-2.2,16.7-3.9,25c-1.7,7.7-3.5,15.4-5.4,23c-0.1,3.3,1.3,5.7,4.1,7.3c4.3,1.4,8.4,0.7,12.3-1.9
					c6.8-3.1,13.3-6.8,19.5-11C1197.5,781.6,1200.1,778.4,1201.2,774z"/>
				<path opacity="0.1091" fill="#333333" d="M1204.2,739.8c2-8.3,3.8-16.6,5.3-25c1.5-8.9,2.5-17.9,3.1-26.9c0.3-3.6-1.3-5.9-4.7-7
					c-5.6-0.4-10.7,1.2-15.2,4.9c-8.6,5-16.8,10.5-24.7,16.5c-5.2,2.8-8.6,6.8-10.4,12.2c-0.8,8.1-1.9,16.2-3.4,24.2
					c-1.8,7.5-3.4,15-5,22.6c-0.5,3.5,0.9,5.9,4.2,7.3c5.4,0.9,10.4-0.3,15.1-3.5c8.6-4.2,16.9-8.9,24.9-14.1
					C1198.6,748.6,1202.2,744.9,1204.2,739.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1159,772.7c4.9-8.5,7.9-17.6,8.9-27.4c1.7-9,2.8-18,3.5-27.1c0.7-2.7,0.1-5.2-1.5-7.5
					c-1.4-2.5-3.5-4-6.2-4.5c-3.6,0.6-6.9,1.9-9.9,4.1c-3.2,1.8-5.8,4.3-7.8,7.3c-0.5,8.7-1.5,17.3-3,25.8c-0.8,9.2-3.3,18-7.6,26.2
					c-0.6,2.7-0.2,5.1,1.3,7.5c1.2,2.5,3.1,4.2,5.7,5c3.3-0.2,6.4-1.2,9.2-3.1C1154.7,777.6,1157.1,775.5,1159,772.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1183.6,809c4.8-8.5,7.7-17.5,8.7-27.2c1.6-8.9,2.7-17.9,3.2-26.9
					c0.7-2.7,0.2-5.2-1.5-7.5c-1.4-2.5-3.5-4-6.2-4.5c-3.6,0.6-6.9,1.9-9.9,4.1c-3.2,1.8-5.8,4.2-7.8,7.3
					c-0.7,8.8-1.8,17.4-3.4,26.1c-0.9,9.3-3.4,18-7.7,26.3c-0.7,2.7-0.3,5.2,1.2,7.6c1.2,2.5,3.2,4.2,5.8,4.9
					c3.5-0.3,6.8-1.4,9.8-3.3C1178.9,814.1,1181.5,811.9,1183.6,809z"/>
				<path opacity="0.1091" fill="#333333" d="M1218.6,769.1c2-8.2,3.7-16.5,5.2-24.8c1.4-8.9,2.4-17.8,2.9-26.7
					c0.3-3.5-1.3-5.9-4.7-7c-5.5-0.5-10.5,1.1-15,4.7c-8.4,4.8-16.5,10.2-24.2,16.1c-5.1,2.7-8.5,6.7-10.2,12
					c-1.1,8.3-2.5,16.5-4.2,24.7c-2,7.5-3.7,15.1-5.3,22.7c-0.5,3.5,0.9,6,4.1,7.3c5.4,0.9,10.5-0.3,15.2-3.6
					c8.7-4.2,17.1-8.9,25.2-14.2C1213,777.9,1216.6,774.2,1218.6,769.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1167.2,760.8c1.9-4.5,3.9-9.1,5.8-13.6c0.9-3.4,1.5-6.8,2-10.2
					c0.8-4.3,1.4-8.6,1.9-12.9c1-6.7,1.7-13.3,2.1-20.1c-0.5-3-2.1-5.4-4.7-7c-3.4-1.5-6.7-1-9.8,1.3c-5.1,2.7-9.8,5.9-14.2,9.6
					c-3.4,2-5,4.9-5,8.6c-0.3,6.3-1,12.5-2,18.7c-0.8,6-1.7,12-2.8,17.9c0.3-1.3,0.6-2.6,0.9-4c-1.7,4.2-3.4,8.5-5.1,12.7
					c-1.3,2.7-1.2,5.4,0.4,8c1.4,2.7,3.7,4.2,6.7,4.4c4.6-0.9,8.9-2.6,12.9-5.1C1160.5,767.1,1164.1,764.3,1167.2,760.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1191,753.7c2.1-8.2,3.9-16.4,5.3-24.8c1.5-8.9,2.5-17.8,3.1-26.7
					c-0.1-3.3-1.7-5.6-4.7-7c-4.6-1-8.8,0.1-12.6,3.2c-6.9,3.8-13.4,8.2-19.6,13.1c-4.3,2.4-6.9,5.8-7.7,10.4
					c-0.7,8.2-1.9,16.4-3.3,24.6c-1.6,7.7-3.3,15.3-5.1,22.9c-0.1,3.3,1.3,5.7,4.2,7.3c4.4,1.3,8.5,0.7,12.5-2
					c6.9-3.2,13.6-7,19.9-11.3C1187.2,761.4,1189.9,758.2,1191,753.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1214.2,787c2.2-8.2,4-16.5,5.4-24.8c1.4-8.8,2.4-17.7,3-26.7c0-3.3-1.6-5.7-4.7-7
					c-4.7-0.9-9,0.2-12.9,3.3c-7,3.9-13.7,8.4-20.1,13.4c-4.4,2.4-7.1,5.9-8,10.6c-1.1,8.4-2.6,16.8-4.4,25.1
					c-1.8,7.7-3.7,15.4-5.6,23.1c-0.2,3.3,1.2,5.8,4.1,7.3c4.6,1.3,9,0.5,13.1-2.4c7.4-3.4,14.4-7.4,21.1-11.9
					C1210,794.9,1212.9,791.6,1214.2,787z"/>
				<path opacity="0.1091" fill="#333333" d="M1160.2,788c5.1-8.5,8.1-17.7,9.1-27.5c1.7-8.9,2.8-18,3.5-27c0.4-2.7-0.2-5.1-1.8-7.3
					c-1.3-2.4-3.3-4-5.9-4.7c-3.2,0.3-6.1,1.4-8.7,3.4c-2.8,1.6-5.1,3.8-6.7,6.6c-0.5,8.7-1.6,17.4-3.1,25.9
					c-0.8,9.3-3.3,18-7.6,26.3c-0.4,2.6,0.2,5.1,1.6,7.3c1.2,2.4,3,4.1,5.4,5.2c2.9,0,5.6-0.8,8.1-2.4
					C1156.7,792.4,1158.7,790.6,1160.2,788z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1205.1,723.9c2.9-9.1,3.8-18.4,2.7-27.9c0.5-3-0.3-5.5-2.6-7.6
					c-2.1-2.2-4.7-3-7.7-2.4c-4.2,2.1-7.8,5-10.9,8.5c-3.4,3.2-6.2,6.9-8.2,11.1c1.1,9,0.4,17.9-2,26.6c-0.9,5.6,4.4,11.2,10.1,10.1
					c4.1-1.9,7.6-4.6,10.6-7.9C1200.5,731.5,1203.2,727.9,1205.1,723.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1178.6,705.4c4-9.4,5.2-19.1,3.8-29.1c0.4-2.9-0.5-5.4-2.8-7.4
					c-2.1-2.2-4.6-3-7.5-2.5c-4,2-7.4,4.6-10.3,7.9c-3.2,3-5.8,6.6-7.6,10.6c1.4,9.4,0.4,18.5-2.9,27.5c-1.1,5.4,5,10.9,10.1,10.1
					c3.8-1.7,7.1-4.1,9.9-7.2C1174.5,712.5,1176.9,709.2,1178.6,705.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1185.1,659.1c-0.1-8.5,0-17,0-25.4c-0.7-3.3-2.8-5.2-6.1-5.8
					c-4.8,0.1-8.8,2.2-11.9,6.1c-6.1,5.4-11.7,11.3-16.9,17.6c-3.8,3.3-5.7,7.3-5.6,12.2c-0.2,8.1-0.2,16.2,0,24.3
					c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8c6.1-5.2,11.8-10.9,17-17C1183.2,667.8,1185.1,663.8,1185.1,659.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1205.4,690.1c-0.2-8.4-0.2-16.9-0.1-25.3c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.2c-3.6,3.1-5.3,6.9-5,11.5c-0.2,8.1-0.1,16.2,0.1,24.3
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1203.9,698.3,1205.6,694.5,1205.4,690.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1209.3,654c-0.3-8.5-0.4-16.9-0.2-25.4c-0.3-3.7-2.3-5.6-6-5.9
					c-6,1.1-10.9,4.1-14.8,9c-7.9,7.3-15.4,15-22.5,23.2c-4.8,4.1-7.6,9.1-8.5,15.1c-0.5,8-0.4,15.9,0.3,23.9c0.3,3.7,2.3,5.7,6,5.9
					c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5C1205.5,664.9,1208.4,659.9,1209.3,654z"/>
				<path opacity="0.1091" fill="#333333" d="M1163.5,689.9c3.3-9.3,4.5-18.9,3.4-28.7c0.2-2.9-0.8-5.3-3-7.2c-2-2.1-4.5-3-7.3-2.7
					c-3.7,1.7-6.9,4.1-9.6,7.2c-3,2.8-5.3,6.1-6.9,9.9c1.1,9.2,0.3,18.3-2.5,27.2c-0.9,5.1,5.2,10.8,10.1,10.1
					c3.6-1.5,6.6-3.7,9.2-6.6C1159.9,696.6,1162,693.5,1163.5,689.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1188.3,725.8c2.4-9.4,3.3-19,2.7-28.7c0-4.7-5.1-10.6-10.1-10.1c-3.2,1.1-6,3-8.2,5.5
					c-2.6,2.3-4.4,5-5.6,8.2c0.7,9.3,0.1,18.5-1.7,27.7c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5
					C1185.6,731.4,1187.3,728.8,1188.3,725.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1222.3,685c-0.2-8.5-0.3-16.9-0.1-25.4c-0.5-3.5-2.5-5.4-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.5c-7.1,6.4-13.7,13.2-19.8,20.4c-4.3,3.7-6.7,8.2-7.2,13.6c-0.3,8-0.3,16,0.2,24
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1219.5,694.8,1221.9,690.3,1222.3,685z"/>
				<path opacity="0.1091" fill="#333333" d="M1174.5,671.8c0-8.4,0-16.9,0-25.3c-1-3-3-4.9-6.1-5.8c-4-0.5-7.4,1-10.1,4.2
					c-4.9,4.2-9.4,8.9-13.5,14c-3.2,2.9-4.4,6.3-3.9,10.3c0,8.2,0,16.3-0.1,24.5c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C1173.8,679.1,1175.1,675.7,1174.5,671.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1195.5,669.5c-0.1-8.5-0.1-16.9,0-25.4c-0.7-3.3-2.7-5.3-6.1-5.9
					c-5,0.3-9.1,2.4-12.3,6.4c-6.3,5.7-12.2,11.7-17.7,18.3c-3.9,3.4-5.9,7.6-6,12.5c-0.2,8.1-0.2,16.1,0,24.2
					c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1c6.3-5.5,12.2-11.4,17.7-17.7C1193.4,678.5,1195.4,674.4,1195.5,669.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1218.4,703c-0.2-8.4-0.2-16.9-0.1-25.3c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-11,10.4-15.8,16.2c-3.6,3.1-5.3,6.9-5,11.5c-0.2,8.1-0.1,16.2,0.1,24.3
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1216.9,711.3,1218.6,707.5,1218.4,703z"/>
				<path opacity="0.1091" fill="#333333" d="M1166.5,703.9c2.4-9.4,3.3-19,2.7-28.7c0-4.8-5.1-10.6-10.1-10.1
					c-3.3,1.2-6,3.1-8.3,5.7c-2.6,2.3-4.5,5.1-5.7,8.4c0.7,9.3,0.1,18.6-1.7,27.7c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3
					c3.1-1,5.7-2.7,7.9-5.2C1163.8,709.6,1165.5,707,1166.5,703.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1209.1,647.9c1.2-3,2.3-6,3.5-9c0.5-3-0.3-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.6-2.4
					c-4.1,2.1-7.8,4.9-10.9,8.4c-3.4,3.2-6.2,6.9-8.2,11c-1,2.7-2,5.4-2.9,8.1c-0.5,3,0.4,5.5,2.6,7.5c2.1,2.2,4.6,3.1,7.5,2.6
					c4.1-1.9,7.6-4.6,10.6-7.9C1204.5,655.5,1207.2,651.9,1209.1,647.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1182.6,629.4c1.5-3.4,3-6.9,4.5-10.3c0.4-2.9-0.5-5.4-2.7-7.4c-2-2.2-4.5-3.1-7.5-2.6
					c-3.9,1.9-7.4,4.4-10.3,7.7c-3.2,3-5.8,6.4-7.6,10.4c-1.3,3.1-2.5,6.2-3.8,9.3c-0.3,2.9,0.6,5.3,2.8,7.3c2,2.2,4.4,3.1,7.3,2.8
					c3.8-1.7,7.1-4.1,9.9-7.2C1178.5,636.5,1180.9,633.2,1182.6,629.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1189.1,583.1c0-1.9,0-3.8,0-5.6c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.4c-3.8,3.3-5.7,7.3-5.7,12.1c0,1.6,0,3.2,0,4.8c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C1187.2,591.8,1189.1,587.8,1189.1,583.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1209.4,614.1c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-10.9,10.3-15.7,16.1c-3.6,3.1-5.3,6.9-5,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C1207.9,622.3,1209.6,618.5,1209.4,614.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1213.3,578c-0.1-1.8-0.3-3.6-0.4-5.3c-0.3-3.7-2.3-5.7-6-5.9c-6,1.1-10.9,4-14.8,8.9
					c-8,7.2-15.5,14.9-22.5,23.1c-4.8,4-7.6,9.1-8.6,15c0.2,1.4,0.4,2.8,0.6,4.2c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6
					c7.9-7.1,15.4-14.6,22.5-22.5C1209.5,588.9,1212.4,583.9,1213.3,578z"/>
				<path opacity="0.1091" fill="#333333" d="M1167.5,613.9c1.3-3.3,2.7-6.7,4-10c0.2-2.9-0.8-5.3-2.9-7.2c-2-2.2-4.4-3.1-7.2-2.9
					c-3.7,1.6-6.8,3.8-9.5,6.8c-3,2.7-5.2,5.8-6.8,9.5c-1.1,3.2-2.3,6.3-3.4,9.5c-0.2,2.8,0.8,5.2,3,7.1c1.9,2.1,4.3,3.1,7.2,3
					c3.6-1.5,6.6-3.7,9.2-6.6C1163.9,620.6,1166,617.5,1167.5,613.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1192.3,649.8c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.8
					c-1.8-2.1-4.1-3.2-6.8-3.3c-3.1,1-5.8,2.8-8,5.3c-2.5,2.2-4.3,4.9-5.3,8c-1,3-2,6-3,9c0.2,2.7,1.3,4.9,3.4,6.8
					c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C1189.6,655.4,1191.3,652.8,1192.3,649.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1226.3,609c-0.1-1.8-0.2-3.6-0.2-5.4c-0.5-3.5-2.5-5.5-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.4c-7.1,6.3-13.7,13-19.9,20.2c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,1.5,0.2,3,0.3,4.5c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1223.5,618.8,1225.9,614.3,1226.3,609z"/>
				<path opacity="0.1091" fill="#333333" d="M1178.3,600.1c0.1-1.9,0.2-3.9,0.3-5.8c0.9-3.1,0.2-5.8-2.1-8.1
					c-2.3-2.3-5.1-2.9-8.2-1.9c-4.8,2.8-9.1,6.3-12.8,10.5c-4.1,3.8-7.4,8.2-10.1,13.1c-0.1,1.8-0.3,3.5-0.4,5.3
					c-1,3.1-0.3,5.8,2,8.1c2.3,2.3,5,2.9,8.1,2c4.8-2.7,9.1-6.1,12.9-10.2C1172.2,609.3,1175.6,605,1178.3,600.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1199.5,593.5c0-1.9,0-3.7,0-5.6c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.2-9.1,2.3-12.3,6.4
					c-6.3,5.6-12.2,11.6-17.7,18.1c-3.9,3.4-6,7.5-6.1,12.5c0,1.6,0.1,3.1,0.1,4.7c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C1197.4,602.5,1199.4,598.4,1199.5,593.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1222.4,627c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C1220.9,635.3,1222.6,631.5,1222.4,627z"/>
				<path opacity="0.1091" fill="#333333" d="M1170.5,627.9c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.9
					c-1.8-2.1-4.1-3.2-6.9-3.3c-3.2,1.1-5.9,2.9-8.1,5.4c-2.5,2.2-4.3,4.9-5.4,8.1c-1,3-2,6-3,9c0.2,2.7,1.3,5,3.3,6.8
					c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2C1167.8,633.6,1169.5,631,1170.5,627.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1169,813.2c-0.7-0.7-1.3-1.4-2-2.1c2.2,2.9,4.5,5.8,6.7,8.6c-1.3-2.3-2.7-4.5-4-6.8
					c0.8,1.6,1.7,3.2,2.5,4.8c-0.9-2.3-1.8-4.7-2.7-7c0.1,4,0.1,7.9,0.2,11.9c1.8-1.8,3.7-3.6,5.5-5.4c-0.9,0.7-1.8,1.4-2.7,2.2
					c1.6-0.9,3.3-1.4,5.1-1.5c2.8-0.2,4.7-1.8,5.5-4.5c1.4-3.4,2.2-6.9,2.4-10.6c0.4-2.9-0.6-5-3-6.5c-2.6-1.4-5.3-2-8.2-1.8
					c-2.5,0.7-4.9,1.3-7.4,2c-1.5,0.8-3,1.7-4.4,2.7c-3.4,2.2-6.5,4.8-9.5,7.5c-2.1,1.9-2.6,4.2-1.6,6.8c0.3,1.5,0.7,2.9,1,4.4
					c0.3,2.3,1.3,4.4,2.7,6.2c2.1,3.3,4.8,6,7.8,8.6c2,2.1,4.3,2.7,7,1.6c2.7-1.1,4.9-2.9,6.5-5.3c1.5-2.4,1.5-4.8-0.2-7.2
					C1174.1,818.5,1171.8,815.7,1169,813.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1139.4,791.3c-0.3-0.2-0.6-0.4-0.9-0.6c4.1,4.3,8.1,8.6,12.2,12.8
					c-2.1-3-4.2-5.9-6.3-8.9c3.6,5.6,7.2,11.2,10.8,16.8c-1.6-3.2-3.2-6.3-4.8-9.5c1.8,5.1,3.6,10.3,5.4,15.4
					c-0.7-8.8-1.3-17.7-2-26.5c0.3-3.7-1.1-6.5-4.1-8.4c-2.8-1.3-5.6-1.4-8.4-0.3c-3.8,3.1-7.5,6.1-11.3,9.2
					c-2.1,1.5-3.1,3.4-2.7,5.8c-0.5,3.3,0.5,6.3,3.2,8.9c2,2.8,3.9,5.7,5.9,8.5c3.4,4.9,7.5,9.2,12.2,12.8c0.8,0.2,1.7,0.5,2.5,0.7
					c3,1.5,5.9,1.2,8.5-0.9c2.5-2.2,3.4-4.9,2.5-8.2c-2.6-5.5-5.8-10.5-9.9-15.1C1148.6,799.1,1144.3,794.9,1139.4,791.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1135.7,748.4c4.5,3,9.1,5.9,13.6,8.9c-2.4-2.8-4.9-5.7-7.3-8.5
					c-2.3-2.6-4.8-3.4-7.7-2.5c-1.8-0.2-3,0.5-3.5,2.3c-1.5,1.4-1.8,3-1,5c-0.1,3,0.8,5.7,2.7,8.1c1.8,4.1,4,8.1,6.6,11.8
					c4,6.8,8.5,13.4,13.3,19.8c2.4,2.8,4.9,5.7,7.3,8.5c1.8,1,3.6,1.9,5.4,2.9c-4.6-21.7-9.1-43.4-13.7-65c-2.1,0.4-4.1,0.8-6.2,1.2
					c-1.8,0.5-3.6,1.1-5.4,1.6c-2.5,0.3-4.6,1.3-6.3,3c-1.3,0.8-2.2,1.9-2.9,3.2c-1,1.3-1.8,2.7-2.5,4.2c-0.1,4.1,1.1,7.8,3.8,10.9
					c3.5,6.3,7.5,12.3,12.1,17.8c3.4,4.1,6.7,8.3,10.1,12.4c3.3,5.3,7.8,8.8,13.5,10.5c0.8-0.1,1.7-0.3,2.5-0.4
					c3.7,0.1,5.9-1.7,6.5-5.3c-0.4-6-2.7-11.2-7.1-15.6c-6.2-8.6-13-16.9-20.2-24.7C1146.1,753.3,1141.5,749.9,1135.7,748.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1183.1,806.7c-2.2-10-4.3-20-6.5-30.1c0.1,8.1,0.2,16.3,0.3,24.4c0.7-2.7,1.3-5.3,2-8
					c0.7-2.9,1.3-5.9,2-8.8c1.3-3.1,1.1-5.9-0.7-8.3c-0.4-1.5-1.4-2.5-2.9-2.9c-1.8-1.4-3.9-1.8-6.2-1.2c-3.1-0.3-5.5,0.6-7.2,2.7
					c-3.2,2.9-5.9,6.2-8.1,10c-0.9,1.1-1.7,2.3-2.6,3.4c-1,2.6-0.6,5,1.1,7.1c1,2.4,2.4,4.6,4.1,6.5c2.5,3.7,5.4,7.1,8.7,10.3
					c1.7,1.3,3.4,2.6,5.1,4c1.4,0.2,2.8,0.3,4.2,0.5c2.4-0.4,4.1-1.6,5.2-3.6C1183.1,811,1183.7,809.1,1183.1,806.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1189.5,791.6c-3.4,1.3-6.8,2.7-10.2,4c0.9-1.7,1.8-3.3,2.8-5c1.2-8.1,1.5-16.3,1-24.5
					c-0.1-8.2-1-16.4-2.8-24.4c-1.1-3.8-3.6-5.9-7.4-6.2c-3.9,1-7.6,2.5-11,4.7c-3.2,1.1-5.7,3-7.5,5.7c-1.9,1.8-2.6,4-2.1,6.4
					c-0.4,4.2,1,8,4,11.5c2.3,3.9,4.7,7.8,7,11.7c4,6.7,8.8,12.8,14.2,18.4c1.7,0.8,3.3,1.5,5,2.3
					C1185.9,796.3,1188.3,794.8,1189.5,791.6z"/>
				<path opacity="0.1091" fill="#333333" d="M1122.2,776.4c-0.2-0.2-0.5-0.4-0.7-0.6c8.1,9.8,16.2,19.5,24.2,29.3
					c-2.9-4-5.7-8.1-8.6-12.1c3.7,6.8,7.4,13.5,11.1,20.3c-4-11.3-7.9-22.6-11.9-33.9c1.3,9.3,2.6,18.7,3.9,28
					c0.6-9.5,1.2-18.9,1.8-28.4c1-3.8,0.2-7-2.4-9.6c-2.5-1.9-5.2-2.4-8.2-1.8c5.3,19.1,10.6,38.2,15.9,57.4
					c3.5-0.1,5.7-1.9,6.5-5.3c0.2-3.8-0.9-7.1-3.4-9.9c-3.2-5.6-7-10.8-11.3-15.7C1134.1,787.6,1128.5,781.7,1122.2,776.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1151.8,817.7c-0.7-0.7-1.4-1.5-2.1-2.2c2.5,3.3,5.1,6.6,7.6,9.8
					c-1.7-3.4-3.4-6.8-5.1-10.1c0.8,3.2,1.6,6.3,2.4,9.5c0.4-1.8,1.3-3.3,2.6-4.5c-0.9,0.7-1.8,1.4-2.7,2.1c1.6-1.2,3.1-2.4,4.7-3.6
					c-1,0.5-2,1-3,1.5c2.2-0.9,4.5-1.8,6.7-2.7c1.9-2,3.1-4.4,3.5-7.2c0.8-2.7,0.7-5.4-0.2-8c-1.7-2-3.9-3.1-6.6-3.5
					c-2.6-0.7-5-0.6-7.4,0.5c-2,0.3-3.8,0.9-5.4,1.9c-2.4,1.1-4.6,2.7-6.4,4.9c-1.2,0.9-2.3,1.9-3.4,2.9c-1.7,1.1-2.9,2.6-3.6,4.5
					c0,1.5,0,3,0.1,4.5c0.5,2.8,1.4,5.5,2.6,8c2.6,4.8,6.4,8.7,10.4,12.3c2.1,2.4,4.6,3.2,7.5,2.3c2.8-1,4.9-2.8,6.5-5.3
					c1.5-2.7,1.2-5.3-0.8-7.8C1157.6,823.7,1155,820.5,1151.8,817.7z"/>
				<path opacity="0.1091" fill="#333333" d="M1181,780.5c2.1,8.3,4.3,16.5,6.4,24.8c1.1-2.1,2.2-4.1,3.4-6.2
					c2.5-6.1,4.4-12.4,5.7-18.8c1.5-4,1.1-7.7-1.2-11.1c-2.3-2.2-4.9-3-8.1-2.4c-2,0.4-4,0.8-6,1.2c-2.8,1.1-5.4,2.5-7.9,4.2
					c-1.6,0.5-2.6,1.5-3.1,3.1c-1,1.6-1.8,3.2-2.6,4.9c-1.4,2.3-1.4,4.5-0.1,6.7c1,3.6,2.6,6.9,4.8,9.9c2.1,3.1,4.4,6,7,8.7
					c2,2.2,4.4,3.8,7.2,4.7c2.9,0.8,5.5,0.2,7.8-1.8c2.4-1.8,3.5-4.2,3.3-7.2c-1.5-4.3-3.8-8.1-6.9-11.5
					C1188.1,785.9,1184.8,782.9,1181,780.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1126.3,761.3c5.2,2.6,10.3,5.2,15.5,7.8c5.8,12,11.6,23.9,17.5,35.9
					c2.1-17.4-0.8-33.2-8.7-47.4c-2.4-2.2-5.2-2.7-8.3-1.6c-3.8,2.4-6,5.9-6.4,10.6c-0.8,3.6-1.7,7.3-2.5,10.9
					c-4-5.3-8-10.5-12-15.8c-2.7,2.9-3.2,6.2-1.4,9.9c2.8,5.6,6.2,10.9,10,15.8c4.4,6.4,9.2,12.5,14.4,18.4
					c3.2,5.2,7.6,8.6,13.3,10.2c0.9,0,1.8,0,2.7-0.1c3.7,0,5.8-1.7,6.5-5.3c-0.3-5.9-2.6-11-6.9-15.4c-6.1-8.5-12.7-16.6-19.8-24.3
					C1136.6,766.1,1132.1,762.8,1126.3,761.3z"/>
				<path opacity="0.1091" fill="#333333" d="M1153.7,762.8c7.3-2.7,7.9-5.3,1.7-7.7c-1.5,0-3,0-4.4,0.1c-3.3,1.4-4.9,4-4.9,7.7
					c0.5,5.1,1.7,10,3.4,14.8c1.7,6.1,3.9,11.9,6.4,17.7c1.4,2.6,2.8,5.2,4.2,7.8c-0.7-8.3-1.5-16.7-2.2-25
					c-0.7-8.3-1.5-16.7-2.2-25c-2.9,1.3-5.7,2.6-8.6,3.9c-2.8,2.3-4.8,5.2-6,8.7c-0.5,3.5,0.3,6.6,2.5,9.4
					c2.6,5.3,5.9,10.2,9.7,14.7c2.8,3.4,5.5,6.7,8.3,10.1c2.8,4.5,6.7,7.2,11.7,8.1c0.8-0.1,1.6-0.1,2.4-0.2c3.4-0.3,5.6-2,6.5-5.3
					c0.4-3.5-0.5-6.6-2.8-9.3c-2.8-5.2-6.2-10-10.2-14.4C1164.7,772.9,1159.5,767.6,1153.7,762.8z"/>
				<path opacity="0.1091" fill="#333333" d="M1186.5,804.9c-2.2-2.2-4.4-4.5-6.6-6.7c3,4.7,6,9.3,9,14c1.3-6.1,2.6-12.1,3.9-18.2
					c0.1-1.8,0.2-3.6,0.2-5.4c-0.6-2.2-1.9-3.6-3.9-4.3c-1.2-1-2.5-1.4-4-1.3c-2-0.6-4-0.2-5.7,1.3c-0.9-0.1-1.8-0.2-2.7-0.3
					c-2.6,1.1-5,2.6-7.3,4.3c-2.3,1.7-5.8,3.8-6.3,6.9c0.1,1.7,0.2,3.4,0.3,5.2c0.4,1.5,0.8,3,1.2,4.5c1.2,2.9,2.9,5.6,4.9,8
					c0.9,1.3,1.9,2.5,3,3.6c2.1,2.9,4.8,5.2,8,6.7c2.8,0.3,5.2-0.5,7.3-2.4c2.3-1.7,3.6-3.9,3.8-6.6
					C1190.6,810.6,1188.9,807.5,1186.5,804.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1125,792c-0.3-0.3-0.6-0.5-1-0.8c6.6,7.8,13.2,15.7,19.8,23.5
					c-2.4-3.4-4.8-6.8-7.1-10.2c2.8,5.5,5.6,11,8.4,16.5c-2.7-9.8-5.4-19.6-8.1-29.5c0.9,5.3,1.9,10.7,2.8,16
					c0.2-3.2,0.4-6.4,0.5-9.6c-0.7,4.3-1.4,8.6-2.1,12.9c1-2.9,1.9-5.7,2.9-8.6c1.6-4.2,2.6-8.5,3-13c0.8-2.6,0.3-4.7-1.5-6.3
					c-1.1-1.8-2.8-2.8-5.1-3.1c-2.8-0.8-5.3-0.3-7.6,1.3c-5.4,2.5-9.9,6.7-14.5,10.4c-2.2,1.6-3,3.6-2.6,6c-0.4,3.6,0.9,6.8,3.7,9.6
					c2.2,3.1,4.4,6.2,6.5,9.3c3.8,5.3,8.2,10.1,13.3,14.2c0.9,0.3,1.8,0.6,2.8,0.9c3.1,1.7,6,1.6,8.8-0.5c2.6-2.4,3.3-5.2,2.2-8.6
					c-2.9-6-6.6-11.4-11-16.4C1135,800.7,1130.3,796,1125,792z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M1219.1,861.9c0.8-1.5,1.7-3,2.5-4.5c0.5-3-0.3-5.5-2.5-7.6c-2.1-2.2-4.6-3.1-7.6-2.6
					c-4.1,2-7.7,4.7-10.8,8.1c-3.4,3.1-6.1,6.7-8.1,10.8c-0.7,1.4-1.4,2.7-2.2,4.1c-0.5,3,0.4,5.5,2.6,7.5c2.1,2.2,4.6,3.1,7.5,2.6
					c4.1-1.9,7.6-4.6,10.6-7.9C1214.5,869.5,1217.2,865.9,1219.1,861.9z"/>
				<path opacity="0.1091" fill="#333333" d="M1190.1,848c0.9-1.5,1.7-3.1,2.6-4.6c0.9-1.5,1.7-3.1,2.6-4.6
					c-0.1-1.3-0.2-2.6-0.3-3.8c-0.8-1.2-1.6-2.4-2.4-3.6c-1.5-1.8-3.3-2.7-5.5-2.6c-1.6-0.5-3-0.1-4.2,1.1c-1.9,0.9-3.6,2.1-5.1,3.7
					c-1.9,1.5-3.7,3.2-5.2,5.2c-2.2,2.1-3.9,4.6-5,7.4c-0.8,1.4-1.5,2.9-2.3,4.3c-0.3,2.9,0.6,5.3,2.8,7.3c1.5,1.8,3.3,2.7,5.4,2.6
					c2.4,0.6,4.6-0.2,6.4-2.3C1183.8,855.1,1187.2,851.8,1190.1,848z"/>
				<path opacity="0.1091" fill="#333333" d="M1199.1,797.1c0-0.7,0.1-1.4,0.1-2c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.3-11.8,11.1-17,17.3c-3.8,3.3-5.7,7.3-5.7,12.1c0,0.4-0.1,0.9-0.1,1.3c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C1197.2,805.8,1199.1,801.8,1199.1,797.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1219.4,828.1c0.1-0.7,0.1-1.4,0.2-2.1c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.6-11.3,5.3c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c-0.1,0.5-0.1,0.9-0.2,1.4
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1217.9,836.3,1219.6,832.5,1219.4,828.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1223.3,792c-0.2-0.5-0.3-1-0.5-1.5c-0.6-1.4-1.2-2.8-1.7-4.2
					c-1.4-0.6-2.8-1.1-4.3-1.7c-2,0.6-3.9,1.2-5.9,1.8c-5.3,3.7-10.2,7.9-14.6,12.5c-7.1,6.8-14.1,13.9-20,21.8
					c-2.4,2-3.8,4.5-4.3,7.5c-1.4,2.5-1.1,4.9,0.9,7.2c0.1,0.3,0.3,0.6,0.4,0.9c3.3,2.3,6.6,2.3,10.1,0c5.3-3.6,10.2-7.8,14.7-12.3
					c5.9-5.4,11.5-11,16.7-17.1C1219.5,802.9,1222.4,797.9,1223.3,792z"/>
				<path opacity="0.1091" fill="#333333" d="M1175.3,832.2c0.8-1.5,1.6-2.9,2.4-4.4c0.8-1.4,1.5-2.9,2.3-4.3
					c-0.2-1.2-0.3-2.5-0.5-3.7c-0.8-1.2-1.6-2.3-2.5-3.5c-1.4-1.8-3.2-2.7-5.4-2.7c-1.5-0.6-2.8-0.3-4,0.9c-1.8,0.8-3.4,1.9-4.7,3.4
					c-1.8,1.4-3.4,2.9-4.7,4.7c-2,1.9-3.5,4.2-4.5,6.9c-0.7,1.4-1.4,2.8-2.1,4.2c-0.2,2.8,0.8,5.2,3,7.1c1.4,1.8,3.2,2.7,5.3,2.7
					c2.3,0.7,4.3,0,6.1-2C1169.5,838.8,1172.6,835.7,1175.3,832.2z"/>
				<path opacity="0.1091" fill="#333333" d="M1200.7,867.4c0.9-1.5,1.8-3.1,2.6-4.6c1.9-2.2,2.2-4.6,0.9-7.1
					c-1.4-2.6-3.4-4.6-5.9-6c-2.5-1.3-4.9-1-7.1,0.9c-3,2.1-5.6,4.7-7.7,7.7c-0.8,1.5-1.6,3-2.4,4.4c-1.9,2.2-2.1,4.5-0.8,7
					c1.4,2.6,3.4,4.5,5.9,6c2.5,1.3,4.8,1.1,7-0.8C1196.1,872.9,1198.6,870.4,1200.7,867.4z"/>
				<path opacity="0.1091" fill="#333333" d="M1236.3,823c-0.1-0.6-0.2-1.1-0.2-1.7c-0.5-3.5-2.5-5.4-6-5.9c-5.4,0.5-9.8,3-13.4,7.4
					c-7,6.2-13.6,12.9-19.8,20.1c-4.3,3.6-6.7,8.1-7.2,13.5c0.1,0.4,0.1,0.7,0.2,1.1c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C1233.5,832.8,1235.9,828.3,1236.3,823z"/>
				<path opacity="0.1091" fill="#333333" d="M1188.3,814.1c0.3-0.9,0.5-1.7,0.8-2.6c1-3.1,0.3-5.9-2-8.2c-2.4-2.3-5.1-2.9-8.2-1.8
					c-4.9,2.8-9.2,6.4-13,10.6c-4.1,3.9-7.5,8.3-10.2,13.2c-0.2,0.6-0.4,1.2-0.5,1.9c-1,3.1-0.3,5.8,2,8.1c2.3,2.3,5,2.9,8.1,2
					c4.8-2.7,9.1-6.1,12.9-10.2C1182.2,823.3,1185.6,819,1188.3,814.1z"/>
				<path opacity="0.1091" fill="#333333" d="M1209.5,807.5c0-0.7,0-1.3,0-2c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.2-9,2.3-12.3,6.3
					c-6.3,5.6-12.2,11.6-17.7,18c-3.9,3.3-6,7.5-6.1,12.4c0,0.4,0,0.9,0,1.3c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C1207.4,816.5,1209.4,812.4,1209.5,807.5z"/>
				<path opacity="0.1091" fill="#333333" d="M1232.4,841c0.1-0.7,0.1-1.4,0.2-2.1c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.6-11.3,5.3c-5.7,4.9-11,10.3-15.8,16c-3.6,3.1-5.3,6.9-5.1,11.4c-0.1,0.5-0.1,0.9-0.2,1.4
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C1230.9,849.3,1232.6,845.5,1232.4,841z"/>
				<path opacity="0.1091" fill="#333333" d="M1178.8,845.6c0.9-1.5,1.8-3.1,2.6-4.6c1.9-2.2,2.3-4.6,1-7.2c-1.4-2.6-3.4-4.6-6-5.9
					c-2.6-1.3-5-1-7.2,1c-3.1,2.2-5.7,4.8-7.8,7.9c-0.8,1.5-1.6,3-2.4,4.4c-1.9,2.2-2.2,4.6-0.9,7.1c1.4,2.6,3.4,4.5,6,5.9
					c2.5,1.3,4.9,1,7.1-0.9C1174.2,851.2,1176.7,848.6,1178.8,845.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M903,489.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C892.4,486.2,897.3,489,903,489.8z"/>
				<path opacity="0.1091" fill="#333333" d="M874.9,467.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C867.7,467.3,871,468.5,874.9,467.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M873.5,428.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C868,426,870.5,427.6,873.5,428.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M899.3,459.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C891,458.1,894.8,459.8,899.3,459.6z"/>
				<path opacity="0.1091" fill="#333333" d="M893.6,424.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C886.6,420.2,889.9,422.6,893.6,424.2z"/>
				<path opacity="0.1091" fill="#333333" d="M854.2,453.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C848.3,451,851,452.8,854.2,453.9z"/>
				<path opacity="0.1091" fill="#333333" d="M883.6,491.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C874.5,489.5,878.6,491.6,883.6,491.8z"/>
				<path opacity="0.1091" fill="#333333" d="M915.6,455.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C906.4,453.6,910.6,455.7,915.6,455.9z"/>
				<path opacity="0.1091" fill="#333333" d="M863.6,440.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C857.6,437.7,860.4,439.5,863.6,440.6z"/>
				<path opacity="0.1091" fill="#333333" d="M882.7,437.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C875.3,432.7,878.7,435.2,882.7,437.1z"/>
				<path opacity="0.1091" fill="#333333" d="M914,474.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C903.6,471,908.4,473.6,914,474.4z"/>
				<path opacity="0.1091" fill="#333333" d="M854.6,466.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C847.4,462.6,850.7,465.1,854.6,466.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M891,500.1c2.3-7,4.1-14.1,5.4-21.3c1.3-8.6,2.3-17.2,2.9-25.8
					c0.9-8.9,1.4-17.8,1.5-26.7c0.2-12.1-0.1-24.2-1-36.2c-0.9-2.9-2.7-5.1-5.5-6.4c-3.5-1.1-6.6-0.3-9.4,2.4
					c-4.6,3.2-8.8,6.8-12.7,10.9c-3,2.4-4.3,5.4-3.7,9c0,5.8,0.1,11.7,0.1,17.5c-0.1,8.7-0.5,17.4-1.3,26.1
					c-1.1,14.2-3,28.2-5.1,42.3c0.3-1.4,0.5-2.7,0.8-4.1c-1.4,5.4-2.8,10.8-4.2,16.3c-1.3,2.8-1.1,5.5,0.6,8.1c1.6,2.7,4,4,7.1,3.9
					c4.8-1.3,9.2-3.4,13.3-6.3C884.1,507,887.9,503.9,891,500.1z"/>
				<path opacity="0.1091" fill="#333333" d="M864.8,481.1c5.6-11.1,8.4-22.9,8.6-35.3c0.5-5.9,1-11.8,1.5-17.7
					c0.6-7.2,0.9-14.5,0.9-21.7c0.2-12.1-0.2-24.1-1.2-36.1c-1-2.9-2.8-5-5.5-6.4c-3.2-1.2-6.1-0.6-8.7,1.8c-4.2,2.8-7.9,6-11.3,9.8
					c-2.7,2.3-3.7,5.1-3,8.4c0.7,11.5,0.9,22.9,0.6,34.4c0.1-1.3,0.1-2.6,0.2-3.9c-0.4,7-0.7,14-1.1,21c0.1-1.3,0.2-2.6,0.3-3.9
					c-0.7,8.3-1.5,16.7-2.4,25c-0.4,8.9-2.6,17.4-6.7,25.6c-1.7,5.1,2.3,11.7,7.8,12c4-0.8,7.7-2.5,11-4.9
					C859.4,487.1,862.4,484.4,864.8,481.1z"/>
				<path opacity="0.1091" fill="#333333" d="M871.4,436.6c2.7-16.9,5.5-33.8,6.4-50.9c0.2-6,0.5-11.9,0.7-17.9
					c0.2-12.1-0.2-24.2-1.1-36.2c-0.5-3.2-2.4-5.4-5.5-6.4c-4.6-0.4-8.5,1.1-11.9,4.5c-6.2,4.5-12,9.5-17.3,15
					c-3.9,2.8-5.9,6.5-6.1,11.1c0.7,8.2,0.9,16.5,0.9,24.7c0.2,8.3-0.1,16.6-0.7,24.9c-0.5,5.5-1,11.1-1.5,16.6
					c-1.4,10.4-3,20.8-4.9,31.2c0.2,3.2,1.7,5.6,4.6,7c4.2,1.2,8.1,0.4,11.7-2.4c6.3-3.4,12.3-7.2,17.9-11.7
					C868.5,444.1,870.8,440.9,871.4,436.6z"/>
				<path opacity="0.1091" fill="#333333" d="M891.4,467.5c3-16.9,5.8-33.8,6.7-51c0.2-5.9,0.4-11.9,0.7-17.8
					c0.2-12-0.2-24.1-1.1-36.1c-0.6-3.2-2.4-5.4-5.5-6.4c-4.5-0.5-8.4,1-11.7,4.3c-6.1,4.4-11.8,9.3-17.1,14.7
					c-3.8,2.8-5.8,6.4-6,11c0.2,17,0.2,33.8-1.5,50.8c-0.6,5.6-1.3,11.2-1.9,16.9c-1.6,10.5-3.4,21-5.5,31.4
					c0.1,3.3,1.6,5.6,4.6,7.1c4.4,1.1,8.4,0.2,12.1-2.7c6.6-3.5,12.9-7.6,18.8-12.2C888.2,475.3,890.6,471.9,891.4,467.5z"/>
				<path opacity="0.1091" fill="#333333" d="M894.6,432.9c2.9-17.1,5.8-34.1,6.8-51.4c0.2-6,0.5-12,0.7-18
					c0.2-12.2-0.1-24.3-1-36.5c-0.1-3.6-1.9-5.8-5.5-6.4c-5.8,0.4-10.9,2.8-15.1,7.2c-8.2,6.3-16.1,13-23.5,20.3
					c-5,3.5-8.1,8.1-9.3,13.8c0.4,16.4,0.5,32.7-0.9,49.1c-0.6,5.5-1.2,11-1.7,16.5c-1.7,10.3-3.4,20.6-5.2,30.9
					c-0.3,3.6,1.2,5.9,4.6,7.1c5.6,0.5,10.6-1,15.2-4.7c8.5-4.8,16.7-10.2,24.6-16C889.4,442.2,892.8,438.2,894.6,432.9z"/>
				<path opacity="0.1091" fill="#333333" d="M849.7,466.1c4.9-11.6,7.8-23.8,8.6-36.4c1.2-8.7,1.8-17.5,2-26.3
					c0.4-8.9,0.5-17.8,0.2-26.6c-0.2-6-0.5-11.9-0.9-17.9c0.4-2.8-0.4-5.3-2.4-7.4c-1.8-2.3-4.1-3.4-6.9-3.4c-3.7,1.2-6.9,3.2-9.7,6
					c-3.1,2.4-5.5,5.3-7.3,8.8c0.4,6.8,0.8,13.6,1.2,20.4c0-1.2-0.1-2.5-0.1-3.7c0.1,6.8,0.3,13.6,0.4,20.5c0-1.2,0-2.4,0-3.7
					c0,11.2-0.6,22.3-1.9,33.3c-0.5,12.1-3,23.8-7.4,35.2c-0.5,2.7,0,5.2,1.7,7.4c1.4,2.4,3.4,4,6.1,4.6c3.4-0.5,6.5-1.7,9.3-3.8
					C845.5,471.4,847.9,469,849.7,466.1z"/>
				<path opacity="0.1091" fill="#333333" d="M873.8,502.7c4-8.8,6.5-18,7.3-27.5c1.4-8.8,2.3-17.6,2.9-26.5c0.2-6,0.5-12,0.7-17.9
					c0.2-12.1-0.2-24.2-1.1-36.2c0.3-2.8-0.6-5.2-2.5-7.2c-1.6-2.3-3.8-3.6-6.6-3.8c-3.4,0.9-6.4,2.5-9,4.9
					c-2.9,2.1-5.1,4.7-6.6,7.8c0.8,17.5,1.4,35-0.1,52.5c-0.6,5.8-1.1,11.6-1.7,17.4c-0.6,12.1-3.2,23.9-7.5,35.2
					c-0.5,2.7,0,5.2,1.7,7.4c1.4,2.4,3.4,4,6.1,4.6c3.4-0.4,6.5-1.7,9.3-3.7C869.6,507.9,872,505.6,873.8,502.7z"/>
				<path opacity="0.1091" fill="#333333" d="M908.7,462.5c2.9-17,5.7-33.9,6.6-51.2c0.2-5.9,0.4-11.9,0.6-17.8
					c0.1-12.1-0.3-24.1-1.3-36.1c-0.2-3.5-2-5.7-5.5-6.4c-5.5,0.2-10.2,2.3-14.1,6.4c-7.7,5.8-14.9,12-21.8,18.7
					c-4.7,3.3-7.5,7.6-8.4,13c-0.1,16.8-0.4,33.6-2.3,50.3c-0.7,5.6-1.4,11.2-2.1,16.7c-1.9,10.4-3.7,20.7-5.5,31.1
					c-0.3,3.5,1.2,5.9,4.6,7.1c5.5,0.6,10.4-0.9,14.9-4.4c8.4-4.7,16.4-9.9,24.1-15.6C903.6,471.6,907,467.7,908.7,462.5z"/>
				<path opacity="0.1091" fill="#333333" d="M858.2,454.2c2.7-7,4.6-14.2,5.8-21.6c1.3-8.5,2.2-17.1,2.8-25.8
					c0.9-8.9,1.3-17.7,1.3-26.6c0.1-12-0.3-24.1-1.2-36.1c-0.8-3-2.6-5.1-5.5-6.4c-3.8-0.9-7.1,0.1-10,2.9c-5,3.5-9.6,7.5-13.8,12
					c-3.2,2.5-4.7,5.7-4.3,9.5c0.3,5.6,0.6,11.1,0.9,16.7c0.3,8.4,0.3,16.8-0.2,25.1c-0.5,13.9-2.1,27.5-3.8,41.3
					c0.2-1.4,0.5-2.7,0.7-4.1c-1.5,5.5-3,11-4.5,16.4c-1.3,2.8-1,5.5,0.7,8.1c1.6,2.6,4,3.9,7.1,3.9c4.7-1.3,9-3.3,13-6.2
					C851.5,461.1,855.2,458,858.2,454.2z"/>
				<path opacity="0.1091" fill="#333333" d="M881.1,447.3c1.8-8.3,3.3-16.6,4.3-25c1.4-8.7,2.3-17.4,2.7-26.2
					c0.2-6,0.5-11.9,0.7-17.9c0.2-12.1-0.2-24.1-1-36.2c-0.4-3.3-2.3-5.4-5.5-6.4c-4.8-0.3-9,1.4-12.5,5c-6.6,4.9-12.8,10.2-18.5,16
					c-4.1,2.9-6.4,6.8-6.8,11.6c0.5,8.3,0.6,16.6,0.4,24.9c0,8.3-0.3,16.7-1,25c-0.6,5.6-1.1,11.1-1.7,16.7
					c-1.4,10.5-3.2,20.9-5.4,31.3c0.1,3.3,1.6,5.7,4.6,7.1c4.5,1,8.7,0.1,12.5-2.9c6.8-3.7,13.4-7.9,19.5-12.7
					C877.6,455.2,880.2,451.8,881.1,447.3z"/>
				<path opacity="0.1091" fill="#333333" d="M904.3,480.5c1.8-8.3,3.2-16.6,4.2-25c1.3-8.7,2.2-17.4,2.6-26.2
					c0.2-5.9,0.4-11.9,0.6-17.8c0.1-12-0.2-24.1-1.2-36.1c-0.5-3.3-2.3-5.4-5.5-6.4c-4.6-0.4-8.6,1.1-12,4.6
					c-6.3,4.6-12.1,9.6-17.6,15.2c-3.9,2.8-6,6.6-6.3,11.2c-0.1,17.2-0.5,34.2-2.4,51.3c-0.7,5.7-1.4,11.3-2.1,17
					c-1.7,10.5-3.6,21.1-5.7,31.5c0,3.3,1.5,5.7,4.6,7.1c4.6,1,8.9,0,12.7-3c7-3.8,13.7-8.1,20-12.9
					C900.7,488.6,903.4,485.1,904.3,480.5z"/>
				<path opacity="0.1091" fill="#333333" d="M850.8,481.6c4.2-8.9,6.8-18.1,7.6-27.8c1.4-8.8,2.4-17.7,3-26.6c0.3-6,0.5-12,0.8-18
					c0.2-12.1-0.1-24.3-0.9-36.4c0.2-2.7-0.7-5.1-2.6-7.1c-1.6-2.2-3.8-3.6-6.5-3.9c-3.2,0.8-6.1,2.3-8.6,4.6
					c-2.7,2-4.8,4.5-6.2,7.5c1,17.4,1.8,34.8,0.5,52.2c-0.5,5.8-1.1,11.5-1.6,17.3c-0.5,12.1-3,23.9-7.4,35.2c-0.3,2.6,0.4,5,2,7.2
					c1.3,2.4,3.2,4,5.8,4.8c3-0.2,5.6-1.2,8-3C847.5,486.3,849.5,484.2,850.8,481.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M905.5,415.5c2.4-7,3.8-14.2,4.4-21.5c0.6-6.7,1-13.5,1.3-20.3
					c0.2-6.3,0.5-12.6,0.7-18.9c0.3-12.7,0.1-25.4-0.4-38.1c-1-2.9-2.9-5-5.7-6.2c-3.5-1-6.6-0.1-9.3,2.6c-4.5,3.3-8.6,7-12.2,11.2
					c-2.9,2.5-4,5.5-3.4,9c0.2,12.5,0,24.9-0.5,37.3c-0.6,12.1-1.4,24.2-2.3,36.2c0.1-1.4,0.2-2.7,0.4-4.1
					c-1.3,6.1-2.5,12.3-3.8,18.4c-1,2.9-0.5,5.5,1.5,7.9c1.8,2.5,4.3,3.6,7.3,3.4c4.5-1.6,8.5-3.9,12.2-7
					C899.6,422.8,902.9,419.4,905.5,415.5z"/>
				<path opacity="0.1091" fill="#333333" d="M878,397.6c5.1-9.8,7.6-20,7.5-30.8c0.6-9.3,1-18.5,1.2-27.8c0.2-12.7,0-25.3-0.4-38.1
					c0.5-2.9-0.3-5.4-2.4-7.6c-1.9-2.3-4.4-3.3-7.3-3c-4,1.7-7.6,4.1-10.7,7.3c-3.4,2.8-6.1,6.2-8.1,10.1c0.2,7.5,0.3,14.9,0.5,22.4
					c0-1.3,0-2.6-0.1-3.9c0.1,12.3-0.2,24.6-0.9,36.9c0.6,13-1.6,25.5-6.8,37.6c-1.5,5.1,3.5,11.4,8.8,11.3
					c3.9-1.1,7.4-3.1,10.5-5.7C873.2,404,875.9,401.1,878,397.6z"/>
				<path opacity="0.1091" fill="#333333" d="M885.6,351.6c1.5-15.2,3-30.4,3.5-45.7c0.3-6.3,0.5-12.6,0.4-18.9
					c0.1-9.5,0-19.1-0.5-28.6c-0.6-3.3-2.5-5.3-5.7-6.2c-4.7-0.2-8.6,1.4-11.9,5.1c-6.2,4.8-11.9,10.2-17.2,15.9
					c-3.9,3-5.9,6.8-6,11.5c0.1,6,0.3,12,0.4,18c0.1,9-0.1,18.1-0.5,27.1c-0.6,14.5-2.1,28.8-3.6,43.3c0.4,3.2,2.1,5.5,5.2,6.6
					c4.4,0.7,8.4-0.5,11.8-3.7c6.3-4.1,12.1-8.7,17.6-13.8C883.1,359.6,885.3,356.1,885.6,351.6z"/>
				<path opacity="0.1091" fill="#333333" d="M905.9,382.4c1.5-15.2,3-30.3,3.5-45.6c0.3-6.3,0.5-12.6,0.4-18.9
					c0.1-9.5,0-19-0.5-28.6c-0.7-3.2-2.6-5.3-5.7-6.2c-4.5-0.3-8.4,1.2-11.6,4.7c-6,4.6-11.5,9.7-16.6,15.3
					c-3.8,2.9-5.7,6.6-5.7,11.2c0.1,15.3-0.1,30.5-0.9,45.8c-0.8,14.6-2.4,29-3.9,43.5c0.4,3.2,2.1,5.5,5.2,6.6
					c4.4,0.8,8.4-0.5,11.8-3.7c6.3-4.1,12.1-8.6,17.6-13.7C903.4,390.4,905.6,386.9,905.9,382.4z"/>
				<path opacity="0.1091" fill="#333333" d="M909.5,347.3c1.4-15.3,2.9-30.6,3.5-45.9c0.3-6.3,0.5-12.7,0.4-19
					c0.1-9.6,0-19.2-0.5-28.8c-0.2-3.7-2.1-5.7-5.7-6.2c-5.9,0.7-10.9,3.3-15,7.9c-8.2,6.7-15.9,13.8-23.2,21.3
					c-4.9,3.7-7.9,8.5-9.1,14.3c0.1,15,0.1,30-0.7,44.9c-0.7,14.3-2.4,28.6-3.7,42.8c-0.1,3.6,1.7,5.8,5.2,6.6
					c5.7-0.1,10.8-2.1,15.1-6.3c8.4-5.7,16.3-12,23.9-18.7C904.8,357.3,908,352.9,909.5,347.3z"/>
				<path opacity="0.1091" fill="#333333" d="M862.6,382.7c2.1-7.3,4.2-14.5,6.3-21.8c1.5-9.3,2.3-18.7,2.4-28.1
					c0.6-15.7,0.3-31.3-0.2-47.1c0.3-2.8-0.5-5.3-2.6-7.3c-1.9-2.2-4.2-3.3-7.1-3.2c-3.7,1.4-6.9,3.5-9.7,6.4
					c-3.1,2.5-5.5,5.6-7.1,9.2c0.5,15.1,0.9,30.2,0.5,45.3c0,5.9-0.4,11.8-1.1,17.7c-0.4,10.2-2.4,20.2-6,30
					c-0.3,2.8,0.4,5.2,2.3,7.3c1.6,2.3,3.8,3.6,6.5,4c3.4-0.8,6.5-2.4,9.1-4.7C858.7,388.4,861,385.8,862.6,382.7z"/>
				<path opacity="0.1091" fill="#333333" d="M888.5,417.8c1.6-6.9,3.2-13.7,4.8-20.6c0.9-9.4,1.5-18.9,1.9-28.3
					c0.4-6.3,0.6-12.7,0.5-19c0.2-9.6,0-19.1-0.5-28.7c0.2-2.8-0.8-5.1-2.7-7.1c-1.7-2.2-3.9-3.4-6.7-3.6c-3.3,1-6.3,2.7-8.8,5.1
					c-2.8,2.1-4.9,4.8-6.3,8c0.1,6.2,0.3,12.5,0.4,18.7c0.1,9.4-0.1,18.7-0.6,28.1c-0.2,6.2-0.6,12.4-1,18.5
					c-0.2,9.9-1.8,19.6-4.7,29.2c-0.2,2.7,0.6,5.1,2.4,7.2c1.6,2.3,3.7,3.7,6.4,4.1c3.2-0.7,6.1-2.1,8.6-4.3
					C884.9,423.3,887,420.8,888.5,417.8z"/>
				<path opacity="0.1091" fill="#333333" d="M923.1,377.4c1.5-15.2,2.9-30.4,3.4-45.7c0.3-6.3,0.4-12.6,0.3-18.9
					c0.1-9.5-0.1-19-0.6-28.6c-0.3-3.5-2.2-5.6-5.7-6.2c-5.5,0.3-10.1,2.6-13.9,6.8c-7.5,6-14.5,12.5-21.1,19.4
					c-4.6,3.4-7.2,7.9-8,13.3c-0.1,15.2-0.4,30.4-1.3,45.6c-0.9,14.4-2.6,28.8-4,43.2c0,3.5,1.8,5.7,5.2,6.6
					c5.4,0.1,10.2-1.7,14.4-5.6c7.9-5.3,15.4-11.1,22.5-17.4C918.9,386.8,921.9,382.7,923.1,377.4z"/>
				<path opacity="0.1091" fill="#333333" d="M873.8,368.8c1.3-6.8,2.4-13.7,3.2-20.6c0.9-6.8,1.3-13.6,1.4-20.4
					c0.2-6.3,0.4-12.6,0.7-18.9c0.2-12.7,0.1-25.4-0.5-38c-0.9-3-2.8-5.1-5.7-6.2c-3.9-0.8-7.2,0.4-10.1,3.4c-5,3.8-9.6,8-13.7,12.7
					c-3.2,2.6-4.6,5.9-4.2,9.8c0.5,12.1,0.7,24.2,0.4,36.3c-0.3,12-0.9,23.9-1.9,35.8c0.2-1.4,0.3-2.8,0.5-4.2
					c-0.9,5.8-1.7,11.6-2.6,17.4c-1.1,2.9-0.7,5.6,1.3,8.1c1.9,2.5,4.4,3.6,7.5,3.2c4.8-1.8,9.1-4.4,13-7.8
					C867.4,376.6,870.9,373,873.8,368.8z"/>
				<path opacity="0.1091" fill="#333333" d="M895.8,362.1c1.6-15.2,3.1-30.5,3.7-45.8c0.3-6.3,0.5-12.6,0.4-18.9
					c0.1-9.5,0-19.1-0.5-28.6c-0.5-3.3-2.4-5.4-5.7-6.2c-4.9-0.1-9,1.7-12.4,5.5c-6.5,5.2-12.6,10.8-18.3,16.8
					c-4.1,3.1-6.2,7.1-6.5,12c0.2,15.1,0.2,30.2-0.5,45.3c-0.7,14.5-2.3,28.9-3.8,43.3c0.3,3.3,2,5.5,5.2,6.6
					c4.7,0.6,8.9-0.8,12.5-4.2c6.7-4.4,13-9.3,18.9-14.7C892.9,370.5,895.2,366.8,895.8,362.1z"/>
				<path opacity="0.1091" fill="#333333" d="M918.8,395.5c0.7-6,1.3-11.9,2-17.9c0.9-9.3,1.5-18.6,1.7-27.9
					c0.3-6.3,0.4-12.6,0.4-18.9c0.1-9.5-0.1-19-0.6-28.5c-0.6-3.2-2.5-5.3-5.7-6.2c-4.6-0.3-8.5,1.3-11.8,4.9
					c-6.1,4.7-11.8,9.9-17,15.6c-3.8,2.9-5.8,6.7-5.9,11.3c-0.1,15.4-0.4,30.8-1.3,46.1c-0.9,14.6-2.6,29.1-4.2,43.7
					c0.3,3.3,2.1,5.5,5.2,6.7c4.6,0.7,8.6-0.6,12.2-3.9c6.5-4.2,12.6-9,18.3-14.2C916.1,403.7,918.3,400.1,918.8,395.5z"/>
				<path opacity="0.1091" fill="#333333" d="M866,396.5c1.6-6.9,3.3-13.8,4.9-20.8c0.9-9.4,1.6-18.9,2-28.4
					c0.4-6.4,0.6-12.7,0.5-19.1c0.2-9.6,0.1-19.2-0.4-28.7c0.1-2.7-0.8-5.1-2.8-7.1c-1.7-2.2-3.9-3.4-6.7-3.7
					c-3.3,0.9-6.1,2.6-8.5,5c-2.7,2.1-4.7,4.7-6,7.8c0.5,15.6,0.8,31,0.1,46.6c-0.2,6.2-0.5,12.3-1,18.5c-0.2,9.9-1.7,19.6-4.6,29.2
					c-0.1,2.7,0.8,5,2.6,7.1c1.5,2.2,3.6,3.7,6.2,4.2c3-0.5,5.7-1.8,8-3.8C862.9,401.6,864.8,399.3,866,396.5z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M877.1,479.9c1.2-3,2.3-6,3.5-9c0.5-3-0.3-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.6-2.4
					c-4.1,2.1-7.8,4.9-10.9,8.4c-3.4,3.2-6.2,6.9-8.2,11c-1,2.7-2,5.4-2.9,8.1c-0.5,3,0.4,5.5,2.6,7.5c2.1,2.2,4.6,3.1,7.5,2.6
					c4.1-1.9,7.6-4.6,10.6-7.9C872.5,487.5,875.2,483.9,877.1,479.9z"/>
				<path opacity="0.1091" fill="#333333" d="M850.6,461.4c1.5-3.4,3-6.9,4.5-10.3c0.4-2.9-0.5-5.4-2.7-7.4c-2-2.2-4.5-3.1-7.5-2.6
					c-3.9,1.9-7.4,4.4-10.3,7.7c-3.2,3-5.8,6.4-7.6,10.4c-1.3,3.1-2.5,6.2-3.8,9.3c-0.3,2.9,0.6,5.3,2.8,7.3c2,2.2,4.4,3.1,7.3,2.8
					c3.8-1.7,7.1-4.1,9.9-7.2C846.5,468.5,848.9,465.2,850.6,461.4z"/>
				<path opacity="0.1091" fill="#333333" d="M857.1,415.1c0-1.9,0-3.8,0-5.6c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.4c-3.8,3.3-5.7,7.3-5.7,12.1c0,1.6,0,3.2,0,4.8c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C855.2,423.8,857.1,419.8,857.1,415.1z"/>
				<path opacity="0.1091" fill="#333333" d="M877.4,446.1c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-10.9,10.3-15.7,16.1c-3.6,3.1-5.3,6.9-5,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C875.9,454.3,877.6,450.5,877.4,446.1z"/>
				<path opacity="0.1091" fill="#333333" d="M881.3,410c-0.1-1.8-0.3-3.6-0.4-5.3c-0.3-3.7-2.3-5.7-6-5.9c-6,1.1-10.9,4-14.8,8.9
					c-8,7.2-15.5,14.9-22.5,23.1c-4.8,4-7.6,9.1-8.6,15c0.2,1.4,0.4,2.8,0.6,4.2c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6
					c7.9-7.1,15.4-14.6,22.5-22.5C877.5,420.9,880.4,415.9,881.3,410z"/>
				<path opacity="0.1091" fill="#333333" d="M835.5,445.9c1.3-3.3,2.7-6.7,4-10c0.2-2.9-0.8-5.3-2.9-7.2c-2-2.2-4.4-3.1-7.2-2.9
					c-3.7,1.6-6.8,3.8-9.5,6.8c-3,2.7-5.2,5.8-6.8,9.5c-1.1,3.2-2.3,6.3-3.4,9.5c-0.2,2.8,0.8,5.2,3,7.1c1.9,2.1,4.3,3.1,7.2,3
					c3.6-1.5,6.6-3.7,9.2-6.6C831.9,452.6,834,449.5,835.5,445.9z"/>
				<path opacity="0.1091" fill="#333333" d="M860.3,481.8c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.8
					c-1.8-2.1-4.1-3.2-6.8-3.3c-3.1,1-5.8,2.8-8,5.3c-2.5,2.2-4.3,4.9-5.3,8c-1,3-2,6-3,9c0.2,2.7,1.3,4.9,3.4,6.8
					c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C857.6,487.4,859.3,484.8,860.3,481.8z"/>
				<path opacity="0.1091" fill="#333333" d="M894.3,441c-0.1-1.8-0.2-3.6-0.2-5.4c-0.5-3.5-2.5-5.5-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.4c-7.1,6.3-13.7,13-19.9,20.2c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,1.5,0.2,3,0.3,4.5c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C891.5,450.8,893.9,446.3,894.3,441z"/>
				<path opacity="0.1091" fill="#333333" d="M846.3,432.1c0.1-1.9,0.2-3.9,0.3-5.8c0.9-3.1,0.2-5.8-2.1-8.1
					c-2.3-2.3-5.1-2.9-8.2-1.9c-4.8,2.8-9.1,6.3-12.8,10.5c-4.1,3.8-7.4,8.2-10.1,13.1c-0.1,1.8-0.3,3.5-0.4,5.3
					c-1,3.1-0.3,5.8,2,8.1c2.3,2.3,5,2.9,8.1,2c4.8-2.7,9.1-6.1,12.9-10.2C840.2,441.3,843.6,437,846.3,432.1z"/>
				<path opacity="0.1091" fill="#333333" d="M867.5,425.5c0-1.9,0-3.7,0-5.6c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.2-9.1,2.3-12.3,6.4
					c-6.3,5.6-12.2,11.6-17.7,18.1c-3.9,3.4-6,7.5-6.1,12.5c0,1.6,0.1,3.1,0.1,4.7c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C865.4,434.5,867.4,430.4,867.5,425.5z"/>
				<path opacity="0.1091" fill="#333333" d="M890.4,459c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C888.9,467.3,890.6,463.5,890.4,459z"/>
				<path opacity="0.1091" fill="#333333" d="M838.5,459.9c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.9
					c-1.8-2.1-4.1-3.2-6.9-3.3c-3.2,1.1-5.9,2.9-8.1,5.4c-2.5,2.2-4.3,4.9-5.4,8.1c-1,3-2,6-3,9c0.2,2.7,1.3,5,3.3,6.8
					c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2C835.8,465.6,837.5,463,838.5,459.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M915.4,523.6c1-2,1.9-4,2.9-6.1c1.7-2.7,1.8-5.4,0.3-8.3c-1.5-2.8-3.8-4.3-7-4.4
					c-5.2,1-10.1,2.9-14.7,5.6c-4.8,2.3-9.1,5.2-12.9,8.9c-0.8,1.8-1.7,3.6-2.5,5.4c-1.7,2.7-1.9,5.4-0.4,8.2
					c1.4,2.8,3.7,4.4,6.9,4.5c5.1-0.9,10-2.7,14.6-5.3C907.3,530.1,911.6,527.2,915.4,523.6z"/>
				<path opacity="0.1091" fill="#333333" d="M888.3,505c2.2-2.7,4.3-5.4,6.5-8.1c1.2-2.7,1.1-5.3-0.4-7.8c-1.3-2.7-3.3-4.3-6.2-4.8
					c-4.2,0.5-8.1,1.8-11.8,4c-3.9,1.8-7.2,4.2-10,7.3c-1.9,2.5-3.8,5-5.7,7.5c-1.1,2.7-1,5.3,0.5,7.8c1.2,2.7,3.2,4.3,6,4.9
					c4.1-0.4,7.9-1.6,11.4-3.7C882.4,510.4,885.6,508,888.3,505z"/>
				<path opacity="0.1091" fill="#333333" d="M895.3,460.8c0.6-1.9,1.2-3.7,1.8-5.6c0-3.2-1.3-5.7-3.9-7.4c-3.8-1.6-7.6-1.3-11.3,1
					c-6.2,2.6-12.2,5.7-17.8,9.4c-4,1.8-6.4,4.7-7.2,8.8c-0.6,1.7-1.1,3.3-1.7,5c-0.1,3.2,1.2,5.7,3.8,7.5c3.8,1.7,7.5,1.4,11.2-0.9
					c6.2-2.5,12.2-5.5,17.8-9.1C892.1,467.8,894.5,464.9,895.3,460.8z"/>
				<path opacity="0.1091" fill="#333333" d="M915.5,491.6c0.6-1.9,1.2-3.7,1.8-5.6c0.2-3.3-1.1-5.8-3.9-7.5
					c-4.3-1.5-8.4-1-12.4,1.6c-7,3-13.7,6.5-20.1,10.5c-4.4,1.8-7.2,5-8.4,9.3c-0.5,1.6-1,3.2-1.6,4.9c-0.2,3.3,1,5.8,3.8,7.5
					c4.2,1.6,8.4,1.1,12.4-1.4c7-2.8,13.6-6.2,20-10.2C911.5,499,914.3,495.9,915.5,491.6z"/>
				<path opacity="0.1091" fill="#333333" d="M918.3,457.5c0.5-1.9,1-3.7,1.6-5.6c0.6-3.5-0.7-6-3.9-7.4c-5.3-1.1-10.4-0.1-15.1,3
					c-8.7,3.9-17.1,8.3-25.2,13.2c-5.2,2.2-8.9,5.7-11,10.7c-0.4,1.5-0.8,3.1-1.1,4.6c-0.6,3.5,0.7,6,3.8,7.5
					c5.3,1.2,10.2,0.3,15-2.8c8.6-3.7,17-8,25-12.8C912.6,466,916.2,462.5,918.3,457.5z"/>
				<path opacity="0.1091" fill="#333333" d="M873,490.3c2.1-2.7,4.2-5.5,6.3-8.2c0.8-2.6,0.4-5.1-1-7.5c-1.1-2.5-3-4.3-5.5-5.2
					c-3.4,0.1-6.5,1-9.4,2.7c-3.1,1.4-5.7,3.4-7.7,6.1c-1.9,2.6-3.8,5.3-5.6,7.9c-0.7,2.6-0.3,5.1,1.1,7.5c1.1,2.5,2.9,4.3,5.5,5.2
					c3.3,0,6.3-0.9,9.1-2.5C868.6,494.9,871,492.9,873,490.3z"/>
				<path opacity="0.1091" fill="#333333" d="M895.1,527.9c2.4-2.9,4.8-5.8,7.2-8.7c0.9-2.6,0.7-5.2-0.8-7.6c-1.1-2.6-3-4.3-5.7-5.1
					c-3.6,0.2-7,1.2-10.2,3.1c-3.4,1.5-6.2,3.6-8.5,6.5c-2.1,2.7-4.2,5.5-6.3,8.2c-0.8,2.6-0.5,5.2,0.9,7.6c1.1,2.6,3,4.3,5.6,5.2
					c3.5-0.1,6.7-1,9.7-2.8C890.3,532.7,892.9,530.6,895.1,527.9z"/>
				<path opacity="0.1091" fill="#333333" d="M933.1,486.7c0.5-1.8,1-3.7,1.5-5.5c0.7-3.5-0.6-6-3.9-7.5c-5.5-1-10.7,0-15.6,3.2
					c-9,4-17.7,8.5-26,13.6c-5.4,2.2-9.2,5.8-11.5,11c-0.3,1.5-0.7,3.1-1,4.6c-0.7,3.5,0.6,6,3.8,7.5c5.4,1.1,10.6,0.1,15.4-3
					c8.9-3.8,17.5-8.2,25.9-13.2C927,495.3,930.8,491.7,933.1,486.7z"/>
				<path opacity="0.1091" fill="#333333" d="M881,478.4c2-2.6,3.9-5.1,5.9-7.7c1.4-2.7,1.4-5.4-0.1-8c-1.4-2.8-3.5-4.3-6.6-4.6
					c-4.6,0.8-9,2.4-13.1,4.8c-4.3,2-8.1,4.7-11.3,8.1c-1.7,2.3-3.4,4.6-5.1,6.9c-1.4,2.7-1.4,5.3,0.1,8c1.3,2.7,3.4,4.3,6.4,4.8
					c4.5-0.6,8.8-2.1,12.7-4.4C874.2,484.3,877.9,481.7,881,478.4z"/>
				<path opacity="0.1091" fill="#333333" d="M905.3,471.3c0.6-1.9,1.2-3.7,1.8-5.6c0.2-3.3-1.1-5.8-3.9-7.4
					c-4.3-1.5-8.5-0.9-12.5,1.6c-7,3-13.7,6.5-20.1,10.6c-4.4,1.9-7.2,5-8.4,9.4c-0.5,1.6-1,3.2-1.6,4.9c-0.2,3.3,1.1,5.8,3.8,7.5
					c4.2,1.6,8.4,1.1,12.4-1.4c7-2.9,13.6-6.3,20-10.3C901.3,478.7,904.1,475.6,905.3,471.3z"/>
				<path opacity="0.1091" fill="#333333" d="M928.6,504.5c0.6-1.9,1.2-3.8,1.8-5.6c0.4-3.4-0.9-5.8-3.9-7.5
					c-4.7-1.4-9.2-0.7-13.5,2.1c-7.6,3.3-15,7.1-22,11.5c-4.7,1.9-7.9,5.2-9.4,9.9c-0.5,1.6-1,3.2-1.5,4.8c-0.4,3.3,0.9,5.8,3.8,7.5
					c4.6,1.4,9.1,0.8,13.4-1.9c7.6-3.2,14.9-6.9,21.9-11.1C923.9,512.3,927.1,509.1,928.6,504.5z"/>
				<path opacity="0.1091" fill="#333333" d="M874.2,505.5c2.2-2.8,4.4-5.6,6.5-8.4c0.6-2.6,0.2-5.1-1.2-7.4
					c-1.1-2.5-2.8-4.3-5.3-5.3c-3-0.1-5.9,0.6-8.5,2.2c-2.8,1.2-5.1,3.1-6.8,5.6c-1.9,2.6-3.8,5.3-5.6,7.9c-0.5,2.6,0,5,1.3,7.3
					c1.1,2.5,2.8,4.3,5.2,5.4c2.9,0.2,5.6-0.5,8.1-2C870.6,509.7,872.7,508,874.2,505.5z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M927,571.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C916.4,568.2,921.3,571,927,571.8z"/>
				<path opacity="0.1091" fill="#333333" d="M898.9,549.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C891.7,549.3,895,550.5,898.9,549.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M897.5,510.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C892,508,894.5,509.6,897.5,510.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M923.3,541.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C915,540.1,918.8,541.8,923.3,541.6z"/>
				<path opacity="0.1091" fill="#333333" d="M917.6,506.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C910.6,502.2,913.9,504.6,917.6,506.2z"/>
				<path opacity="0.1091" fill="#333333" d="M878.2,535.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C872.3,533,875,534.8,878.2,535.9z"/>
				<path opacity="0.1091" fill="#333333" d="M907.6,573.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C898.5,571.5,902.6,573.6,907.6,573.8z"/>
				<path opacity="0.1091" fill="#333333" d="M939.6,537.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C930.4,535.6,934.6,537.7,939.6,537.9z"/>
				<path opacity="0.1091" fill="#333333" d="M887.6,522.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C881.6,519.7,884.4,521.5,887.6,522.6z"/>
				<path opacity="0.1091" fill="#333333" d="M906.7,519.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C899.3,514.7,902.7,517.2,906.7,519.1z"/>
				<path opacity="0.1091" fill="#333333" d="M938,556.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C927.6,553,932.4,555.6,938,556.4z"/>
				<path opacity="0.1091" fill="#333333" d="M878.6,548.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C871.4,544.6,874.7,547.1,878.6,548.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M847.1,989.9c2.9-11.1,3.8-22.4,2.7-33.8c0.5-3-0.4-5.5-2.6-7.6
					c-2.1-2.2-4.7-3-7.7-2.4c-4.2,2.2-7.8,5-10.9,8.6c-3.4,3.2-6.2,7-8.2,11.2c1.1,10.9,0.4,21.7-2.1,32.4c-0.5,3,0.4,5.5,2.6,7.5
					c2.1,2.2,4.6,3.1,7.5,2.6c4.1-1.9,7.6-4.6,10.6-7.9C842.5,997.5,845.2,993.9,847.1,989.9z"/>
				<path opacity="0.1091" fill="#333333" d="M820.6,971.4c4-11.4,5.3-23,3.9-34.9c0.4-3-0.5-5.4-2.8-7.4c-2.1-2.2-4.6-3-7.5-2.5
					c-4,2-7.4,4.6-10.3,8c-3.2,3.1-5.8,6.6-7.6,10.6c1.4,11.3,0.4,22.3-2.9,33.2c-1,5.4,4.9,10.9,10.1,10.1c3.8-1.7,7.1-4.1,9.9-7.2
					C816.5,978.5,818.9,975.2,820.6,971.4z"/>
				<path opacity="0.1091" fill="#333333" d="M827.1,925.1c0-10.5,0-20.9,0.1-31.4c-0.8-3.3-2.8-5.2-6.1-5.8
					c-4.8,0.2-8.8,2.2-11.9,6.1c-6.1,5.4-11.7,11.3-16.9,17.6c-3.8,3.3-5.7,7.4-5.6,12.2c-0.2,10-0.2,20-0.1,30
					c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8c6.1-5.2,11.8-10.9,17-17C825.2,933.8,827.1,929.8,827.1,925.1z"/>
				<path opacity="0.1091" fill="#333333" d="M847.4,956.1c-0.1-10.4-0.1-20.8,0-31.2c-0.8-3.2-2.9-5.1-6.1-5.8
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.3c-3.6,3.1-5.2,7-5,11.5c-0.2,10-0.2,20,0,30c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C845.9,964.3,847.6,960.5,847.4,956.1z"/>
				<path opacity="0.1091" fill="#333333" d="M851.3,920c-0.3-10.4-0.4-20.9-0.2-31.3c-0.3-3.7-2.4-5.6-6.1-5.8
					c-6,1.1-10.9,4.2-14.8,9.1c-7.9,7.3-15.4,15.1-22.4,23.3c-4.8,4.1-7.6,9.1-8.5,15.1c-0.5,9.9-0.4,19.7,0.1,29.6
					c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5C847.5,930.9,850.4,925.9,851.3,920z"/>
				<path opacity="0.1091" fill="#333333" d="M805.5,955.9c3.4-11.3,4.5-22.8,3.5-34.5c0.2-2.9-0.8-5.3-3-7.2c-2-2.1-4.5-3-7.4-2.7
					c-3.7,1.7-6.9,4.2-9.6,7.3c-3,2.8-5.3,6.1-6.9,9.9c1.1,11.1,0.3,22.1-2.5,32.9c-0.8,5.1,5.1,10.8,10.1,10.1
					c3.6-1.5,6.6-3.7,9.2-6.6C801.9,962.6,804,959.5,805.5,955.9z"/>
				<path opacity="0.1091" fill="#333333" d="M830.3,991.8c2.4-11.5,3.3-23,2.7-34.7c0-4.7-5.1-10.6-10.1-10.1c-3.2,1.1-6,3-8.2,5.5
					c-2.6,2.3-4.4,5-5.6,8.2c0.7,11.3,0.1,22.5-1.7,33.7c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5
					C827.6,997.4,829.3,994.8,830.3,991.8z"/>
				<path opacity="0.1091" fill="#333333" d="M864.3,951c-0.2-10.4-0.2-20.9-0.1-31.3c-0.5-3.5-2.6-5.4-6.1-5.8
					c-5.4,0.6-9.9,3.2-13.4,7.6c-7,6.4-13.6,13.2-19.8,20.5c-4.3,3.7-6.7,8.2-7.1,13.7c-0.4,9.9-0.4,19.8,0,29.8
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C861.5,960.8,863.9,956.3,864.3,951z"/>
				<path opacity="0.1091" fill="#333333" d="M816.5,937.8c0-10.4,0-20.8,0-31.2c-1-3-3-4.9-6.1-5.8c-4-0.4-7.4,1-10.1,4.3
					c-4.9,4.2-9.4,8.9-13.4,14c-3.2,2.9-4.4,6.3-3.8,10.3c0,10.1,0,20.1-0.2,30.2c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C815.8,945.1,817.1,941.7,816.5,937.8z"/>
				<path opacity="0.1091" fill="#333333" d="M837.5,935.5c-0.1-10.4-0.1-20.9,0-31.3c-0.7-3.3-2.7-5.3-6.1-5.8
					c-5,0.3-9.1,2.4-12.3,6.5c-6.3,5.7-12.2,11.8-17.6,18.4c-3.9,3.4-5.9,7.6-6,12.6c-0.2,10-0.3,20-0.1,29.9c0.7,3.3,2.6,5.3,6,5.9
					c4.9-0.1,9-2.2,12.3-6.1c6.3-5.5,12.2-11.4,17.7-17.7C835.4,944.5,837.4,940.4,837.5,935.5z"/>
				<path opacity="0.1091" fill="#333333" d="M860.4,969c-0.1-10.4-0.1-20.8,0-31.2c-0.8-3.2-2.9-5.1-6.1-5.8
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.3c-3.6,3.1-5.3,7-5,11.5c-0.2,10-0.2,20,0,30c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C858.9,977.3,860.6,973.5,860.4,969z"/>
				<path opacity="0.1091" fill="#333333" d="M808.5,969.9c2.4-11.5,3.3-23,2.7-34.7c0-4.8-5-10.6-10.1-10.1c-3.3,1.2-6,3.1-8.3,5.7
					c-2.6,2.3-4.5,5.1-5.7,8.4c0.7,11.3,0.1,22.6-1.7,33.7c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2
					C805.8,975.6,807.5,973,808.5,969.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M849.2,1013.8c1.7-4.6,3.4-9.1,5.2-13.7c-1.6,3.6-3.1,7.2-4.7,10.7
					c1.7-2.7,3.4-5.5,5.1-8.2c-2.9,3.8-5.7,7.6-8.6,11.4c4-3.8,8.1-7.6,12.1-11.4c1.2-1.5,2.3-3,3.5-4.5c0.1-1.3,0.2-2.6,0.3-4
					c-0.5-2.3-1.6-4.1-3.5-5.3c-0.9-1.2-2.1-1.8-3.6-1.7c-1.6-0.7-3.2-0.4-4.6,0.8c-2.2,0.7-4.1,1.9-5.9,3.4
					c-6.2,4.4-4.5,2.8,5.1-4.7c-3.9,2-7.3,4.7-10.2,8.1c-3.2,3.1-5.7,6.6-7.4,10.7c-0.8,3.1-1.6,6.3-2.4,9.4
					c0.2,2.1,1.2,3.8,2.9,4.9c1.5,1.6,3.3,2.6,5.6,3c1.2-0.1,2.5-0.2,3.7-0.4C844.8,1020.2,847.3,1017.3,849.2,1013.8z"/>
				<path opacity="0.1091" fill="#333333" d="M822.3,996.6c1.5-3.3,3-6.7,4.5-10c-1.2,2.1-2.4,4.2-3.5,6.4c1.9-3,3.8-6.1,5.7-9.1
					c-2.9,3.9-5.8,7.8-8.7,11.8c2.3-2.6,4.6-5.3,7-7.9c-1.8,1.7-3.6,3.4-5.4,5.1c3.7-3.3,7.4-6.6,11-9.9c1-1.4,2.1-2.8,3.1-4.3
					c0-1.3,0.1-2.6,0.1-3.8c-0.5-2.2-1.7-4-3.6-5.2c-0.9-1.2-2-1.8-3.5-1.8c-1.5-0.7-3-0.5-4.3,0.6c-10.9,4.5-16.4,13.8-21.3,25.1
					c-1.9,2.9-1.9,5.8-0.2,8.6c1.3,1.9,3,3.1,5.2,3.6c1.8,1.1,3.7,1.1,5.8,0.2C817.6,1003.4,820.3,1000.3,822.3,996.6z"/>
				<path opacity="0.1091" fill="#333333" d="M832.3,943.2c0.1-0.6,0.1-1.2,0.2-1.8c-5.9,10.1-11.9,20.1-17.8,30.2
					c5.6-7.6,11.2-15.1,16.9-22.7c-5.4,6.1-10.8,12.3-16.1,18.4c7.5-6.2,14.2-13,20.1-20.6c2.5-2.1,3.6-4.7,3.6-8
					c-0.6-1.3-1.2-2.7-1.8-4c-1.7-2.1-3.8-2.8-6.2-2.1c-2.3-0.2-4.3,0.6-5.9,2.3c-4.6,2.5-8.7,5.7-12.5,9.3
					c-5.4,8.8-10.8,17.7-16.2,26.5c-0.2,2.8,0,5.6,0.6,8.3c1.4,3.1,3.8,4.6,7.2,4.4c3.5-0.8,6.1-2.7,7.8-5.8
					c4-4.4,7.4-9.2,10.2-14.4C826.4,956.9,829.7,950.2,832.3,943.2z"/>
				<path opacity="0.1091" fill="#333333" d="M839.1,999.1c2.2-4.1,4.4-8.3,6.6-12.4c-2.6,2.6-5.1,5.2-7.7,7.8
					c7.9-6.3,14.8-13.4,20.9-21.3c1.5-3.1,1.2-6-0.9-8.6c-2.3-2.5-5.1-3.3-8.4-2.3c-15.1,7.1-24.2,20.3-27.3,39.5
					c1.5,2.8,3.9,4.3,7.2,4.4C833.9,1005.5,837,1003.1,839.1,999.1z"/>
				<path opacity="0.1091" fill="#333333" d="M855.6,932.4c0.9,5.3,1.5,5.7,1.7,1.2c1-2.7,0.4-5-1.9-7c-1.5-0.3-3-0.7-4.5-1
					c-4,1.1-7.2,3.4-9.5,6.8c-3.3,3.2-6.3,6.7-8.9,10.5c-5.1,6.3-9.8,12.9-14.1,19.7c-1.8,3.3-3.7,6.7-5.5,10
					c-0.3,2.1-0.6,4.1-0.8,6.2c13-17.7,25.9-35.5,38.9-53.2c-4,1.1-7.2,3.4-9.5,6.8c-5.1,5.2-9.8,10.8-13.8,16.9
					c-3.1,4.4-6.1,8.9-9.2,13.3c-4.2,4.7-6.3,10.1-6.4,16.1c0.2,2.1,0.7,4,1.6,5.9c1.1,3.6,3.5,5,7.2,4.4c5.6-2.4,9.8-6.5,12.6-12.1
					c6.2-8.9,11.9-18.2,17.1-27.8C854.2,944.1,855.9,938.5,855.6,932.4z"/>
				<path opacity="0.1091" fill="#333333" d="M809.6,975.4c0.5-1.3,1-2.5,1.4-3.8c-1.2,2.1-2.3,4.3-3.5,6.4c0.7-1.1,1.4-2.1,2-3.2
					c-1.5,1.8-3.1,3.6-4.6,5.5c2.4-2.7,4.8-5.3,7.1-8c-1.7,1.6-3.3,3.2-5,4.8c3.4-2.1,6.5-4.5,9.3-7.3c2.1-1.7,3.8-3.8,5-6.2
					c-0.1-1.2-0.1-2.4-0.2-3.7c-0.7-2.2-1.9-3.9-3.7-5.1c-0.9-1.2-2-1.8-3.4-1.8c-2.2-0.9-4.2-0.5-6.2,1.3
					c-5.2,4.7-10.4,9.3-15.6,14c-2.3,3.2-3.9,6.8-4.9,10.6c-1.9,3-2,5.9-0.2,8.7c1.8,2.4,4.2,3.9,7.2,4.4c3.3,0.2,5.9-1.1,7.7-4.2
					C805,984,807.6,979.9,809.6,975.4z"/>
				<path opacity="0.1091" fill="#333333" d="M835.1,1009.5c0.4-1.1,0.8-2.2,1.2-3.3c-1.7,2.7-3.4,5.4-5.2,8.1
					c2.3-2.6,4.6-5.2,6.8-7.8c-2.5,1.8-5,3.6-7.5,5.3c0.4,0,0.8,0,1.2,0c3.2-0.8,6-2.3,8.5-4.6c2.7-2,4.7-4.5,6.1-7.5
					c0.1-2.7-0.7-5.1-2.6-7.1c-1.6-2.2-3.8-3.5-6.5-3.9c-3.1-0.6-5.9,0-8.4,1.9c-2.3,1.3-4.2,2.9-5.7,4.9c-1.6,1.6-2.9,3.4-4.1,5.3
					c-1.5,2.4-2.6,4.9-3.2,7.6c-1.1,2.4-0.7,4.6,1.2,6.4c2,2.1,4.4,3.5,7.2,4.4c2.5,0.8,4.6,0.2,6.3-1.8
					C832.4,1015.1,834,1012.5,835.1,1009.5z"/>
				<path opacity="0.1091" fill="#333333" d="M835.3,1001.1c11.6-11.2,23.2-22.3,34.8-33.5c-6.7,9.8-13.4,19.7-20.1,29.5
					c3.1-3.7,6.1-7.3,9.2-11c-3.1,3.3-6.2,6.6-9.3,9.9c9.5-8.2,18.1-17.1,25.9-26.8c2.1-3.3,2.1-6.4-0.1-9.5
					c-2.8-2.5-5.9-2.9-9.4-1.3c-9.5,6.2-10.9,7.1-4.1,2.7c6.8-4.4,5.4-3.5-4.1,2.7c-7.3,5.6-14.2,11.7-20.6,18.2
					c-4.5,3.2-7.1,7.5-7.8,12.7C830.1,998.2,831.9,1000.3,835.3,1001.1z"/>
				<path opacity="0.1091" fill="#333333" d="M822.1,955.5c0.1-0.6,0.1-1.2,0.2-1.8c-4.7,8.1-9.4,16.3-14.1,24.4
					c4.6-6.2,9.3-12.4,13.9-18.7c-4.3,4.8-8.7,9.7-13,14.5c4.8-4.6,9.6-9.2,14.3-13.8c1.3-1.7,2.7-3.5,4-5.2
					c0.2-1.4,0.3-2.8,0.5-4.3c-0.4-2.4-1.6-4.1-3.6-5.2c-1-1.2-2.3-1.7-3.9-1.4c-1.9-0.5-3.6,0-5.1,1.4c-11.6,6-25,19.4-24.2,33.1
					c-0.4,3.4,0.8,6,3.6,7.8c2.9,1.7,5.8,1.6,8.6-0.3c4.3-4.2,8-9,11-14.3C817.7,966.7,820.3,961.3,822.1,955.5z"/>
				<path opacity="0.1091" fill="#333333" d="M842.5,954c0-0.6,0-1.2,0.1-1.8c-3.4,6.5-6.9,12.9-10.3,19.4c2.5-4,5.1-8,7.6-12
					c-5,6.8-10.1,13.7-15.1,20.5c3-3.6,6-7.2,9-10.8c-2.9,3-5.8,6.1-8.7,9.1c6-6,12-11.9,18-17.9c1.8-2.2,3.6-4.4,5.4-6.7
					c0.4-1.6,0.8-3.3,1.1-4.9c-0.2-2.6-1.4-4.4-3.6-5.2c-1.3-1.3-2.7-1.6-4.4-0.9c-2.4-0.1-4.4,0.7-6.2,2.5c-3,1.7-5.8,3.7-8.4,6.1
					c-11.9,8.5-18.4,21-19.5,37.8c1.4,3.1,3.8,4.6,7.2,4.4c3.4-0.7,6-2.6,7.8-5.6c3.9-4.3,7.3-9.1,10.1-14.2
					C836.8,967.5,840,960.9,842.5,954z"/>
				<path opacity="0.1091" fill="#333333" d="M858.8,1002c-5.1,2.6-10.2,5.3-15.4,7.9c2.3-1.3,4.6-2.6,6.9-3.9
					c6.1-4.3,11.7-9,17-14.2c3.8-2.7,5.8-6.3,6-10.7c-0.5-3.2-2.3-5.4-5.4-6.5c-1.3-0.3-2.6-0.6-3.9-0.8c-3.1-0.3-5.7,0.7-7.7,3
					c-4,2.8-7.5,6.1-10.6,9.9c-2.3,2.8-4.6,5.5-6.9,8.3c-3.3,3.1-4.8,6.9-4.2,11.2c0.8,2,1.5,4,2.3,6c1,1.1,2,2.1,3,3.2
					c2.1,1.5,4.2,1.7,6.3,0.6c2-0.2,3.6-1.3,4.6-3.2C854,1009.6,856.7,1006,858.8,1002z"/>
				<path opacity="0.1091" fill="#333333" d="M813.7,987.1c0.4-1,0.7-2.1,1.1-3.1c-0.9,1.7-1.8,3.4-2.7,5.2c0.6-0.8,1.1-1.7,1.7-2.5
					c-1.3,1.5-2.5,2.9-3.8,4.4c2-2.1,4-4.1,6-6.2c-2.7,1.9-5.3,3.9-8,5.8c1.9-0.7,3.8-1.4,5.7-2.1c3.2-1.9,6-4.2,8.4-7
					c1.8-1.5,2.4-3.3,1.8-5.3c0-2.1-0.9-3.8-2.5-5.4c-1.6-2.2-3.8-3.5-6.5-3.9c-6.7-0.3-12.2,4-15.8,9c-0.8,1.2-1.6,2.3-2.4,3.5
					c-1.8,2.6-3.1,5.5-3.8,8.6c-1.5,2.7-1.3,5.1,0.6,7.4c1.9,2.2,4.3,3.7,7.2,4.4c2.9,0.6,5.2-0.4,6.9-2.9
					C810.2,994,812.2,990.7,813.7,987.1z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M845,1039.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C834.4,1036.2,839.3,1039,845,1039.8z"/>
				<path opacity="0.1091" fill="#333333" d="M816.9,1017.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2
					C809.7,1017.3,813,1018.5,816.9,1017.9z"/>
				<path opacity="0.1091" fill="#333333" d="M815.5,978.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C810,976,812.5,977.6,815.5,978.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M841.3,1009.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C833,1008.1,836.8,1009.8,841.3,1009.6z"/>
				<path opacity="0.1091" fill="#333333" d="M835.6,974.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C828.6,970.2,831.9,972.6,835.6,974.2z"/>
				<path opacity="0.1091" fill="#333333" d="M796.2,1003.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9
					c-1.1-3.2-2.9-5.9-5.4-8.1c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C790.3,1001,793,1002.8,796.2,1003.9z"/>
				<path opacity="0.1091" fill="#333333" d="M825.6,1041.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C816.5,1039.5,820.6,1041.6,825.6,1041.8z"/>
				<path opacity="0.1091" fill="#333333" d="M857.6,1005.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C848.4,1003.6,852.6,1005.7,857.6,1005.9z"/>
				<path opacity="0.1091" fill="#333333" d="M805.6,990.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C799.6,987.7,802.4,989.5,805.6,990.6z"/>
				<path opacity="0.1091" fill="#333333" d="M824.7,987.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C817.3,982.7,820.7,985.2,824.7,987.1z"/>
				<path opacity="0.1091" fill="#333333" d="M856,1024.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C845.6,1021,850.4,1023.6,856,1024.4z"/>
				<path opacity="0.1091" fill="#333333" d="M796.6,1016.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C789.4,1012.6,792.7,1015.1,796.6,1016.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M841.1,955.9c2.5-7.9,3.4-15.9,2.8-24.1c-0.2-7.3-0.2-14.5-0.1-21.8
					c0.5-3-0.3-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.7-2.4c-4.2,2.2-7.8,5-10.9,8.5c-3.4,3.2-6.2,6.9-8.2,11.2c-0.1,7.2,0,14.4,0.2,21.6
					c0.6,7.7-0.1,15.4-2.2,22.9c-0.9,5.6,4.3,11.2,10.1,10.1c4.1-1.9,7.6-4.6,10.6-7.9C836.5,963.5,839.2,959.9,841.1,955.9z"/>
				<path opacity="0.1091" fill="#333333" d="M814.6,937.4c3.5-8.3,4.8-16.7,4-25.4c-0.2-7.2-0.3-14.4-0.2-21.6
					c0.4-2.9-0.5-5.4-2.8-7.4c-2.1-2.2-4.6-3-7.5-2.5c-4,2-7.4,4.6-10.3,8c-3.2,3-5.8,6.6-7.6,10.6c-0.1,7.1,0,14.3,0.3,21.4
					c0.8,8.1-0.3,16.1-3.1,24c-1,5.4,4.9,10.9,10.1,10.1c3.8-1.7,7.1-4.1,9.9-7.2C810.5,944.5,812.9,941.2,814.6,937.4z"/>
				<path opacity="0.1091" fill="#333333" d="M821.1,891.1c0-14.5,0.1-28.9,0.1-43.4c-0.8-3.3-2.8-5.2-6.1-5.8
					c-4.8,0.1-8.8,2.2-11.9,6.1c-6.1,5.4-11.7,11.3-16.9,17.6c-3.8,3.3-5.7,7.4-5.6,12.2c0,14.1-0.1,28.1-0.1,42.2
					c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8c6.1-5.2,11.8-10.9,17-17C819.2,899.8,821.1,895.8,821.1,891.1z"/>
				<path opacity="0.1091" fill="#333333" d="M841.4,922.1c-0.1-14.4-0.1-28.8-0.1-43.2c-0.8-3.2-2.9-5.1-6.1-5.8
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.3c-3.6,3.1-5.3,7-5,11.5c0,14-0.1,28.1,0,42.1c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C839.9,930.3,841.6,926.5,841.4,922.1z"/>
				<path opacity="0.1091" fill="#333333" d="M845.3,886c-0.3-14.5-0.2-28.9-0.2-43.3c-0.3-3.7-2.4-5.6-6.1-5.8
					c-6,1.1-10.9,4.1-14.8,9.1c-7.9,7.3-15.4,15.1-22.4,23.3c-4.8,4.1-7.6,9.1-8.5,15.1c0.1,7.3,0.1,14.5-0.2,21.8
					c-0.3,6.6-0.2,13.3,0.3,19.9c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5
					C841.5,896.9,844.4,891.9,845.3,886z"/>
				<path opacity="0.1091" fill="#333333" d="M799.5,921.9c2.9-8.2,4.1-16.5,3.5-25c-0.1-7.2-0.1-14.4-0.1-21.6
					c0.2-2.9-0.8-5.3-3-7.2c-2-2.1-4.5-3-7.3-2.7c-3.7,1.7-6.9,4.2-9.6,7.3c-3,2.8-5.3,6.1-6.9,9.9c-0.1,7.1,0,14.1,0.2,21.2
					c0.6,8.1-0.3,16-2.7,23.8c-0.8,5.1,5.2,10.8,10.1,10.1c3.6-1.5,6.6-3.7,9.2-6.6C795.9,928.6,798,925.5,799.5,921.9z"/>
				<path opacity="0.1091" fill="#333333" d="M824.3,957.8c2-8.1,2.9-16.3,2.6-24.6c0.1-7.4,0.1-14.8,0.3-22.3
					c0.1-4.8-5.1-10.6-10.1-10.1c-3.2,1.1-6,3-8.3,5.6c-2.6,2.3-4.4,5-5.6,8.3c-0.2,7.4-0.2,14.7-0.1,22.1c0.4,8-0.2,15.9-1.8,23.8
					c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C821.6,963.4,823.3,960.8,824.3,957.8z"/>
				<path opacity="0.1091" fill="#333333" d="M858.3,917c-0.2-14.4-0.1-28.9-0.1-43.3c-0.5-3.5-2.5-5.4-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.6c-7,6.4-13.6,13.2-19.8,20.4c-4.3,3.7-6.7,8.2-7.1,13.7c0.1,7.3,0,14.5-0.1,21.8
					c-0.2,6.7-0.1,13.4,0.2,20.1c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8
					C855.5,926.8,857.9,922.3,858.3,917z"/>
				<path opacity="0.1091" fill="#333333" d="M810.5,903.8c0-14.4,0-28.8,0-43.2c-1-3-3-4.9-6.1-5.8c-4-0.4-7.4,1-10.1,4.2
					c-4.9,4.2-9.4,8.9-13.5,14c-3.2,2.9-4.4,6.3-3.8,10.3c0,14.1,0,28.2-0.1,42.4c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C809.8,911.1,811.1,907.7,810.5,903.8z"/>
				<path opacity="0.1091" fill="#333333" d="M831.5,901.5c-0.1-14.5,0-28.9,0-43.4c-0.7-3.3-2.7-5.3-6.1-5.8
					c-5,0.3-9.1,2.4-12.3,6.5c-6.3,5.7-12.2,11.8-17.6,18.3c-3.9,3.4-5.9,7.6-6,12.5c0,14-0.2,28,0,42.1c0.7,3.3,2.6,5.3,6,5.9
					c4.9-0.1,9-2.2,12.3-6.1c6.3-5.5,12.2-11.4,17.7-17.7C829.4,910.5,831.4,906.4,831.5,901.5z"/>
				<path opacity="0.1091" fill="#333333" d="M854.4,935c-0.1-14.4-0.1-28.8-0.1-43.2c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.2c-3.6,3.1-5.3,7-5,11.5c0,14-0.1,28.1,0,42.1c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C852.9,943.3,854.6,939.5,854.4,935z"/>
				<path opacity="0.1091" fill="#333333" d="M802.5,935.9c2-8.1,2.9-16.3,2.7-24.7c0.1-7.4,0.1-14.8,0.3-22.3
					c0.1-4.8-5-10.6-10.1-10.1c-3.3,1.2-6.1,3.1-8.4,5.7c-2.6,2.3-4.5,5.1-5.7,8.4c-0.2,7.4-0.2,14.7-0.1,22.1
					c0.4,8-0.2,15.9-1.8,23.8c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2
					C799.8,941.6,801.5,939,802.5,935.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M849.5,876.3c1.9-4,3.3-8.2,4.1-12.5c0.8-3.9,1.6-7.7,2.3-11.6c-0.5-3.1-2-5.4-4.7-7
					c-3.5-1.4-6.8-1-10,1.4c-5.2,2.7-10.1,6-14.6,9.8c-3.4,2-5.2,4.9-5.2,8.7c-0.6,3.1-1.2,6.2-1.8,9.3c0.3-1.4,0.6-2.7,0.9-4.1
					c-1.1,3.3-2.2,6.5-3.2,9.8c-1.3,2.8-1.1,5.5,0.6,8.1c1.6,2.7,4,4,7.1,3.9c4.8-1.3,9.2-3.4,13.3-6.3
					C842.5,883.2,846.3,880.1,849.5,876.3z"/>
				<path opacity="0.1091" fill="#333333" d="M823.2,857.3c4.5-6.4,7-13.4,7.3-21.2c0.9-2.8,0.5-5.4-1.2-7.8
					c-1.6-2.5-3.8-3.9-6.7-4.1c-4.2,1-8,2.8-11.5,5.4c-3.7,2.2-6.8,5.1-9.4,8.5c-0.2,7.2-2.3,13.9-6.3,20c-2.2,4.9,2.6,11.7,7.8,12
					c4-0.8,7.7-2.4,11-4.8C817.8,863.3,820.8,860.6,823.2,857.3z"/>
				<path opacity="0.1091" fill="#333333" d="M829.8,812.8c1-6.4,2.2-12.7,3.8-19c-0.2-3.2-1.8-5.6-4.7-7c-4.2-1.1-8.1-0.3-11.7,2.6
					c-6.3,3.5-12.2,7.5-17.8,12c-4,2.2-6.3,5.5-6.8,9.8c-1.5,6-2.7,12-3.7,18.1c0.2,3.2,1.7,5.6,4.6,7.1c4.2,1.2,8,0.4,11.7-2.3
					c6.3-3.3,12.3-7.2,17.9-11.6C826.9,820.3,829.2,817.1,829.8,812.8z"/>
				<path opacity="0.1091" fill="#333333" d="M849.8,843.7c1.2-6.4,2.6-12.8,4.1-19.2c-0.1-3.3-1.7-5.6-4.7-7
					c-4.4-1-8.5-0.1-12.2,2.9c-6.6,3.7-12.9,7.9-18.9,12.6c-4.2,2.3-6.6,5.7-7.4,10.2c-1.5,6-2.8,12.1-3.9,18.1
					c0.1,3.3,1.6,5.6,4.6,7.1c4.4,1.1,8.4,0.3,12.1-2.6c6.6-3.5,12.9-7.5,18.8-12.1C846.6,851.5,849,848.2,849.8,843.7z"/>
				<path opacity="0.1091" fill="#333333" d="M853,809.2c1.1-6.5,2.5-12.9,4.1-19.3c0.3-3.6-1.3-5.9-4.7-7c-5.6-0.4-10.7,1.2-15.3,5
					c-8.6,5-16.8,10.5-24.7,16.6c-5.2,2.8-8.6,6.9-10.4,12.2c-1.7,5.9-3,11.8-3.7,17.9c-0.3,3.6,1.2,5.9,4.6,7.1
					c5.5,0.5,10.6-1,15.2-4.6c8.5-4.8,16.7-10.1,24.6-15.9C847.8,818.4,851.2,814.5,853,809.2z"/>
				<path opacity="0.1091" fill="#333333" d="M808.1,842.3c4-6.8,6.5-14.1,7.5-22c0.6-2.7,0.1-5.2-1.5-7.5c-1.4-2.5-3.5-4-6.2-4.5
					c-3.6,0.6-6.9,1.9-9.9,4.1c-3.2,1.8-5.8,4.2-7.8,7.3c-0.6,7.5-2.8,14.6-6.4,21.3c-0.5,2.7,0,5.2,1.7,7.4c1.4,2.4,3.4,4,6.1,4.6
					c3.4-0.5,6.5-1.7,9.3-3.8C803.9,847.6,806.3,845.3,808.1,842.3z"/>
				<path opacity="0.1091" fill="#333333" d="M832.2,878.9c4-6.8,6.5-14.1,7.4-21.9c0.6-2.7,0.1-5.2-1.5-7.5c-1.4-2.5-3.5-4-6.2-4.5
					c-3.6,0.5-6.9,1.9-9.8,4c-3.2,1.8-5.8,4.2-7.8,7.2c-0.6,7.5-2.8,14.6-6.4,21.3c-0.5,2.7,0,5.2,1.7,7.4c1.4,2.4,3.4,4,6.1,4.6
					c3.4-0.4,6.5-1.7,9.3-3.7C828,884.1,830.4,881.8,832.2,878.9z"/>
				<path opacity="0.1091" fill="#333333" d="M867.2,838.7c1.1-6.5,2.5-12.9,4.1-19.3c0.3-3.6-1.3-5.9-4.7-7
					c-5.5-0.5-10.5,1.1-15,4.8c-8.4,4.9-16.5,10.3-24.3,16.2c-5.1,2.7-8.5,6.7-10.2,12c-1.7,5.9-3,11.8-3.7,17.9
					c-0.3,3.5,1.2,5.9,4.6,7.1c5.5,0.6,10.4-0.9,14.9-4.4c8.4-4.6,16.5-9.8,24.2-15.5C862.1,847.9,865.4,843.9,867.2,838.7z"/>
				<path opacity="0.1091" fill="#333333" d="M816.6,830.4c2.3-4.1,3.9-8.3,4.6-12.9c0.7-3.8,1.5-7.7,2.2-11.5c-0.5-3-2.1-5.4-4.7-7
					c-3.4-1.4-6.7-1-9.9,1.3c-5.1,2.7-9.9,5.9-14.3,9.6c-3.4,2-5,4.9-5,8.6c-0.6,3.1-1.1,6.1-1.7,9.2c0.3-1.4,0.5-2.7,0.8-4.1
					c-1.2,3.4-2.5,6.7-3.7,10.1c-1.3,2.8-1,5.5,0.7,8.1c1.6,2.6,4,4,7,4c4.7-1.2,9-3.3,13-6.1C809.9,837.3,813.5,834.2,816.6,830.4z
					"/>
				<path opacity="0.1091" fill="#333333" d="M839.5,823.5c1.4-6.5,2.8-12.9,4.3-19.3c-0.1-3.3-1.7-5.6-4.7-7
					c-4.6-1-8.8,0.1-12.6,3.2c-6.9,3.9-13.4,8.2-19.6,13.1c-4.3,2.4-6.9,5.8-7.7,10.5c-1.5,6-2.8,12.1-4,18.2
					c0.1,3.3,1.6,5.7,4.6,7.1c4.5,1.1,8.7,0.1,12.5-2.9c6.9-3.7,13.4-7.9,19.5-12.6C836.1,831.5,838.6,828,839.5,823.5z"/>
				<path opacity="0.1091" fill="#333333" d="M862.8,856.7c1.4-6.5,2.8-12.9,4.2-19.3c0-3.3-1.6-5.7-4.7-7c-4.7-0.9-9,0.2-12.9,3.3
					c-7.1,3.9-13.8,8.4-20.1,13.4c-4.4,2.4-7.1,5.9-8,10.6c-1.5,6-2.8,12.1-4,18.2c0,3.3,1.5,5.7,4.5,7.1c4.6,1,8.9,0,12.8-3
					c7-3.8,13.7-8,20-12.9C859.1,864.8,861.8,861.3,862.8,856.7z"/>
				<path opacity="0.1091" fill="#333333" d="M809.2,857.8c4.2-6.8,6.8-14.2,7.7-22.1c0.4-2.7-0.2-5.1-1.8-7.3
					c-1.3-2.4-3.3-4-5.9-4.7c-3.2,0.3-6.1,1.4-8.7,3.3c-2.8,1.6-5,3.8-6.6,6.5c-0.6,7.5-2.8,14.6-6.4,21.3c-0.3,2.6,0.4,5,2,7.2
					c1.3,2.4,3.2,4,5.7,4.8c2.9-0.2,5.6-1.1,8-2.9C805.9,862.5,807.9,860.4,809.2,857.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M871,963.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C860.4,960.2,865.3,963,871,963.8z"/>
				<path opacity="0.1091" fill="#333333" d="M842.9,941.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C835.7,941.3,839,942.5,842.9,941.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M841.5,902.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C836,900,838.5,901.6,841.5,902.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M867.3,933.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C859,932.1,862.8,933.8,867.3,933.6z"/>
				<path opacity="0.1091" fill="#333333" d="M861.6,898.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C854.6,894.2,857.9,896.6,861.6,898.2z"/>
				<path opacity="0.1091" fill="#333333" d="M822.2,927.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C816.3,925,819,926.8,822.2,927.9z"/>
				<path opacity="0.1091" fill="#333333" d="M851.6,965.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C842.5,963.5,846.6,965.6,851.6,965.8z"/>
				<path opacity="0.1091" fill="#333333" d="M883.6,929.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C874.4,927.6,878.6,929.7,883.6,929.9z"/>
				<path opacity="0.1091" fill="#333333" d="M831.6,914.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C825.6,911.7,828.4,913.5,831.6,914.6z"/>
				<path opacity="0.1091" fill="#333333" d="M850.7,911.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C843.3,906.7,846.7,909.2,850.7,911.1z"/>
				<path opacity="0.1091" fill="#333333" d="M882,948.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C871.6,945,876.4,947.6,882,948.4z"/>
				<path opacity="0.1091" fill="#333333" d="M822.6,940.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C815.4,936.6,818.7,939.1,822.6,940.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M911.1,919.9c2.9-9.8,3.8-19.7,2.7-29.9c0.5-3-0.4-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.7-2.4
					c-4.2,2.1-7.8,5-10.9,8.5c-3.4,3.2-6.2,6.9-8.2,11.2c1.1,9.6,0.4,19.1-2,28.5c-0.9,5.6,4.4,11.2,10.1,10.1
					c4.1-1.9,7.6-4.6,10.6-7.9C906.5,927.5,909.2,923.9,911.1,919.9z"/>
				<path opacity="0.1091" fill="#333333" d="M884.6,901.4c4-10.1,5.3-20.4,3.9-31c0.4-2.9-0.5-5.4-2.8-7.4c-2.1-2.2-4.6-3-7.5-2.5
					c-4,2-7.4,4.6-10.3,8c-3.2,3-5.8,6.6-7.6,10.6c1.4,10,0.4,19.8-2.9,29.4c-1.1,5.4,4.9,10.9,10.1,10.1c3.8-1.7,7.1-4.1,9.9-7.2
					C880.5,908.5,882.9,905.2,884.6,901.4z"/>
				<path opacity="0.1091" fill="#333333" d="M891.1,855.1c0-9.1,0-18.3,0-27.4c-0.8-3.3-2.8-5.2-6.1-5.8c-4.8,0.1-8.8,2.2-11.9,6.1
					c-6.1,5.4-11.7,11.3-16.9,17.6c-3.8,3.3-5.7,7.4-5.6,12.2c-0.2,8.7-0.2,17.5,0,26.2c0.7,3.3,2.7,5.2,6,5.9
					c4.8,0,8.7-1.9,11.9-5.8c6.1-5.2,11.8-10.9,17-17C889.2,863.8,891.1,859.8,891.1,855.1z"/>
				<path opacity="0.1091" fill="#333333" d="M911.4,886.1c-0.1-9.1-0.2-18.2-0.1-27.2c-0.8-3.2-2.9-5.1-6.1-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.3c-3.6,3.1-5.3,7-5,11.5c-0.2,8.7-0.1,17.4,0,26.2c0.8,3.2,2.8,5.2,5.9,6
					c4.5,0.2,8.2-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C909.9,894.3,911.6,890.5,911.4,886.1z"/>
				<path opacity="0.1091" fill="#333333" d="M915.3,850c-0.3-9.1-0.4-18.2-0.2-27.3c-0.3-3.7-2.4-5.6-6.1-5.8
					c-6,1.1-10.9,4.1-14.8,9c-7.9,7.3-15.4,15.1-22.4,23.2c-4.8,4.1-7.6,9.1-8.5,15.1c-0.5,8.6-0.4,17.2,0.2,25.8
					c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6c7.9-7.1,15.4-14.6,22.5-22.5C911.5,860.9,914.4,855.9,915.3,850z"/>
				<path opacity="0.1091" fill="#333333" d="M869.5,885.9c3.4-10,4.5-20.2,3.4-30.6c0.2-2.9-0.8-5.3-3-7.2c-2-2.1-4.5-3-7.3-2.7
					c-3.7,1.7-6.9,4.1-9.6,7.3c-3,2.8-5.3,6.1-6.9,9.9c1.1,9.8,0.3,19.5-2.5,29.1c-0.9,5.1,5.2,10.8,10.1,10.1
					c3.6-1.5,6.6-3.7,9.2-6.6C865.9,892.6,868,889.5,869.5,885.9z"/>
				<path opacity="0.1091" fill="#333333" d="M894.3,921.8c2.4-10.1,3.3-20.3,2.7-30.7c0-4.7-5.1-10.6-10.1-10.1
					c-3.2,1.1-6,3-8.2,5.5c-2.6,2.3-4.4,5-5.6,8.2c0.7,10,0.1,19.9-1.7,29.7c0.2,2.7,1.3,4.9,3.4,6.8c1.8,2.1,4.1,3.2,6.8,3.4
					c3-1,5.6-2.6,7.7-5C891.6,927.4,893.3,924.8,894.3,921.8z"/>
				<path opacity="0.1091" fill="#333333" d="M928.3,881c-0.2-9.1-0.3-18.2-0.1-27.3c-0.5-3.5-2.5-5.4-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.6c-7.1,6.4-13.6,13.2-19.8,20.4c-4.3,3.7-6.7,8.2-7.1,13.6c-0.3,8.6-0.3,17.3,0.1,25.9
					c0.5,3.5,2.5,5.5,5.9,6c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C925.5,890.8,927.9,886.3,928.3,881z"/>
				<path opacity="0.1091" fill="#333333" d="M880.5,867.8c0-9.1,0-18.2,0-27.3c-1-3-3-4.9-6.1-5.8c-4-0.4-7.4,1-10.1,4.2
					c-4.9,4.2-9.4,8.9-13.5,14c-3.2,2.9-4.4,6.3-3.8,10.3c0,8.8,0,17.6-0.1,26.4c1,3,3,5,6,5.9c4,0.5,7.4-0.8,10.1-4
					c5-4.1,9.5-8.6,13.6-13.6C879.8,875.1,881.1,871.7,880.5,867.8z"/>
				<path opacity="0.1091" fill="#333333" d="M901.5,865.5c-0.1-9.1-0.1-18.3,0-27.4c-0.7-3.3-2.7-5.3-6.1-5.8
					c-5,0.3-9.1,2.4-12.3,6.5c-6.3,5.7-12.2,11.8-17.6,18.3c-3.9,3.4-5.9,7.6-6,12.5c-0.2,8.7-0.2,17.4,0,26.1
					c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1c6.3-5.5,12.2-11.4,17.7-17.7C899.4,874.5,901.4,870.4,901.5,865.5z"/>
				<path opacity="0.1091" fill="#333333" d="M924.4,899c-0.1-9.1-0.2-18.2-0.1-27.2c-0.8-3.2-2.8-5.1-6-5.9
					c-4.5-0.1-8.3,1.7-11.3,5.4c-5.7,5-10.9,10.4-15.7,16.2c-3.6,3.1-5.3,6.9-5,11.5c-0.2,8.7-0.1,17.4,0,26.2
					c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1c5.7-4.8,11-10.1,15.8-15.8C922.9,907.3,924.6,903.5,924.4,899z"/>
				<path opacity="0.1091" fill="#333333" d="M872.5,899.9c2.4-10.1,3.3-20.4,2.7-30.7c0-4.8-5.1-10.6-10.1-10.1
					c-3.3,1.2-6,3.1-8.3,5.7c-2.6,2.3-4.5,5.1-5.7,8.4c0.7,10,0.1,19.9-1.7,29.7c0.2,2.7,1.3,5,3.3,6.8c1.8,2.1,4.1,3.2,6.8,3.3
					c3.1-1,5.7-2.7,7.9-5.2C869.8,905.6,871.5,903,872.5,899.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M947,931.8c2.1-5.3,3.9-10.7,5.3-16.2c1.4-5.3,2.7-10.5,4-15.8
					c-0.3-3.1-1.8-5.5-4.4-7.2c-3.5-1.6-6.9-1.2-10.2,1.1c-5.4,2.6-10.6,5.7-15.3,9.3c-3.6,1.9-5.5,4.7-5.7,8.6
					c-1.1,4.5-2.2,9-3.4,13.5c0.4-1.4,0.7-2.7,1.1-4.1c-1.4,4.5-2.9,9-4.3,13.5c-1.5,2.8-1.4,5.5,0.2,8.2c1.5,2.7,3.9,4.1,7,4.2
					c4.9-1.2,9.6-3.1,13.8-5.9C939.6,938.6,943.6,935.5,947,931.8z"/>
				<path opacity="0.1091" fill="#333333" d="M920.4,913c5.5-9.1,9-18.8,10.5-29.3c1.1-2.7,0.8-5.4-0.9-7.8
					c-1.4-2.6-3.6-4.1-6.6-4.4c-4.2,0.8-8.1,2.5-11.7,4.9c-3.8,2.1-7,4.8-9.7,8.1c-1.4,9.9-4.5,19.3-9.4,28.1
					c-2.1,4.9,1.9,11.8,7.2,12.3c4-0.6,7.8-2.1,11.2-4.4C914.8,918.8,917.9,916.2,920.4,913z"/>
				<path opacity="0.1091" fill="#333333" d="M927.2,868.7c2.1-9.2,4.4-18.3,6.9-27.3c-0.1-3.2-1.6-5.6-4.4-7.2
					c-4.1-1.3-7.9-0.7-11.6,2c-6.3,3.1-12.2,6.8-17.9,11c-4,2-6.3,5.2-7,9.4c-2.4,8.7-4.7,17.5-6.8,26.4c0.1,3.2,1.5,5.6,4.3,7.3
					c4,1.4,7.8,0.9,11.5-1.7c6.3-3,12.2-6.5,17.9-10.5C924.1,876,926.5,872.9,927.2,868.7z"/>
				<path opacity="0.1091" fill="#333333" d="M947.3,899.5c2.2-9.2,4.6-18.3,7.1-27.4c0-3.3-1.5-5.7-4.4-7.2
					c-4.4-1.2-8.5-0.4-12.3,2.4c-6.8,3.4-13.3,7.3-19.4,11.8c-4.3,2.1-6.9,5.4-7.8,9.9c-2.4,8.7-4.7,17.5-6.9,26.3
					c0,3.3,1.4,5.7,4.3,7.3c4.3,1.3,8.4,0.6,12.3-2.1c6.8-3.2,13.2-7,19.3-11.3C943.7,907.1,946.3,903.9,947.3,899.5z"/>
				<path opacity="0.1091" fill="#333333" d="M950.4,865.2c2.2-9.2,4.5-18.4,7.1-27.6c0.4-3.5-1.1-5.9-4.4-7.2
					c-5.5-0.7-10.6,0.7-15.2,4.2c-8.6,4.5-17,9.6-24.9,15.3c-5.2,2.5-8.8,6.4-10.7,11.7c-2.6,8.6-4.9,17.3-6.7,26.1
					c-0.4,3.5,1,5.9,4.3,7.3c5.4,0.8,10.5-0.5,15.1-3.8c8.6-4.3,16.9-9.2,24.8-14.6C944.9,874.1,948.4,870.3,950.4,865.2z"/>
				<path opacity="0.1091" fill="#333333" d="M905.3,898.2c4.9-9.5,8.4-19.6,10.6-30.1c1.3-4.7-2.1-11.6-7.3-12.3
					c-3.6,0.4-6.8,1.6-9.8,3.6c-3.2,1.6-5.8,3.9-7.9,6.8c-1.9,10.2-5.1,20-9.5,29.4c-0.6,2.7-0.1,5.2,1.4,7.5
					c1.3,2.5,3.2,4.1,5.8,4.9c3.4-0.3,6.4-1.3,9.3-3.2C900.9,903.2,903.4,901,905.3,898.2z"/>
				<path opacity="0.1091" fill="#333333" d="M929.7,934.6c4.8-9.5,8.3-19.5,10.5-30c0.8-2.7,0.4-5.2-1.2-7.6
					c-1.3-2.5-3.3-4.1-6-4.8c-3.7,0.4-7,1.7-10.1,3.7c-3.3,1.7-6.1,4-8.2,7c-1.9,10.2-5.1,20-9.4,29.4c-0.7,2.7-0.2,5.2,1.3,7.5
					c1.3,2.5,3.2,4.1,5.9,4.8c3.5-0.3,6.7-1.5,9.6-3.4C925.1,939.7,927.7,937.4,929.7,934.6z"/>
				<path opacity="0.1091" fill="#333333" d="M964.7,894.6c2.1-9.2,4.5-18.4,7.1-27.5c0.4-3.5-1-5.9-4.4-7.2
					c-5.5-0.7-10.6,0.7-15.3,4.2c-8.7,4.5-17,9.6-25,15.2c-5.2,2.5-8.8,6.4-10.7,11.6c-2.6,8.6-4.9,17.3-6.7,26.1
					c-0.5,3.5,1,5.9,4.3,7.3c5.4,0.8,10.5-0.4,15.1-3.8c8.6-4.3,16.9-9.2,24.9-14.6C959.2,903.5,962.8,899.7,964.7,894.6z"/>
				<path opacity="0.1091" fill="#333333" d="M913.6,886.3c4.6-9.1,7.8-18.7,9.6-28.8c1.3-2.8,1.2-5.5-0.5-8.1
					c-1.6-2.7-3.9-4.1-7-4.1c-4.7,1.2-9.1,3.1-13.2,5.9c-4.3,2.4-8,5.4-11.2,9.1c-1.6,9.6-4.6,18.9-8.8,27.7c-1.3,2.7-1.2,5.4,0.5,8
					c1.5,2.7,3.8,4.1,6.8,4.3c4.6-1,8.9-2.8,12.9-5.4C906.8,892.7,910.5,889.9,913.6,886.3z"/>
				<path opacity="0.1091" fill="#333333" d="M937.2,879.2c2.2-9.2,4.6-18.3,7.1-27.5c0-3.3-1.4-5.7-4.4-7.2
					c-4.5-1.2-8.7-0.3-12.6,2.6c-6.9,3.5-13.6,7.6-19.8,12.1c-4.3,2.2-7,5.5-8,10c-2.5,8.7-4.8,17.5-6.9,26.3
					c-0.1,3.3,1.4,5.7,4.3,7.3c4.4,1.3,8.6,0.5,12.5-2.3c6.9-3.3,13.5-7.2,19.8-11.6C933.4,886.9,936.1,883.7,937.2,879.2z"/>
				<path opacity="0.1091" fill="#333333" d="M960.3,912.5c2.4-9.2,4.8-18.4,7.2-27.6c0.1-3.4-1.3-5.8-4.4-7.2
					c-4.7-1.1-9.1-0.1-13.1,2.9c-7.3,3.7-14.3,7.9-20.9,12.7c-4.5,2.2-7.4,5.7-8.6,10.3c-2.5,8.7-4.8,17.5-6.9,26.3
					c-0.2,3.3,1.3,5.8,4.2,7.3c4.6,1.2,9,0.3,13-2.5c7.3-3.5,14.2-7.6,20.8-12.2C956.2,920.5,959.1,917.1,960.3,912.5z"/>
				<path opacity="0.1091" fill="#333333" d="M906.4,913.6c5.1-9.6,8.7-19.6,10.9-30.2c0.5-2.7,0-5.1-1.6-7.4
					c-1.2-2.4-3.1-4.1-5.7-5c-3.2,0.2-6.1,1.1-8.7,2.9c-2.9,1.4-5.1,3.5-6.8,6.2c-1.9,10.2-5.1,20-9.5,29.4
					c-0.3,2.6,0.2,5.1,1.7,7.3c1.2,2.4,3,4.1,5.5,5.1c2.9,0,5.6-0.9,8-2.5C902.9,918,905,916.1,906.4,913.6z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M931.9,818.3c-1.1-4.2-1.6-8.4-1.6-12.8c0,1.5-0.1,3.1-0.1,4.6
					c0.1-2.7,0.2-5.4,0.2-8.2c-0.1,1.6-0.2,3.1-0.3,4.7c0.5-2.7,1-5.4,1.3-8.2c-0.6-1.1-1.2-2.2-1.7-3.3c-2.1-2.1-4.7-3.3-7.6-3.5
					c-2.4-0.2-4.1,0.7-5.2,2.6c-3.9,4.4-6.7,10.4-7.5,16.3c-0.7,3.4-0.5,6.8,0.5,10.3c0.5,4.3,1.4,8.4,2.6,12.5
					c1.3,2.4,3.4,4,6.1,4.6c2.6,1,5.2,0.8,7.6-0.5c2.3-2.6,3.9-5.6,4.7-9.1C932,825.1,932.4,821.7,931.9,818.3z"/>
				<path opacity="0.1091" fill="#333333" d="M905.5,801.5c-0.8-3.3-1.2-6.7-1.1-10.1c-0.1-2.3-0.1-4.7,0-7
					c-0.2,1.8-0.4,3.7-0.6,5.5c0.5-3,0.9-6.1,1.4-9.1c-0.6-2.9-2.3-5-5.1-6.1c-2.7-1.3-5.3-1.3-7.9,0.2c-3.1,3.2-5.5,6.9-7.1,11
					c-2.1,4-3.3,8.2-3.7,12.6c0.4,7.8,1.6,15.6,3.2,23.3c1,2.8,3,4.5,5.9,5.3c2.8,1,5.4,0.6,7.8-1.2c2.6-3.5,4.4-7.4,5.5-11.7
					C905.1,810.1,905.8,805.9,905.5,801.5z"/>
				<path opacity="0.1091" fill="#333333" d="M908.4,753.6c-0.6-1.4-1.2-2.8-1.8-4.2c0.5,1.8,0.9,3.7,1.4,5.5
					c-0.9-2.6-1.1-5.2-0.7-7.8c-0.7,3.2-1.4,6.4-2.2,9.7c1-4.4,2-8.9,3-13.3c0.5-3.8-0.8-6.7-4-8.5c-3.6-1.1-6.6-0.2-9,2.8
					c-4.5,6.4-8.2,13.3-11.2,20.5c-3.4,7.1-6,14.4-7.7,22.1c0.6,5,1.4,9.8,2.6,14.7c1.1,4.4,2.8,8,4.9,12c2.1,2.9,4.8,3.7,8.1,2.4
					c4.5-3.1,7.3-7.4,8.4-12.9c3.3-8.9,6-18,8.2-27.3C910.5,763.7,910.5,758.6,908.4,753.6z"/>
				<path opacity="0.1091" fill="#333333" d="M930.5,790.3c-1.2-2.8-2.5-5.6-3.7-8.3c0.6,1.4,1.2,2.8,1.8,4.2
					c-0.9-2.6-1.1-5.3-0.9-8.1c-0.4,2.4-0.8,4.8-1.3,7.2c0.7-3.7,1.4-7.4,2.1-11.1c0-3.4-1.6-5.8-4.6-7.2c-3.1-1.3-5.9-0.9-8.5,1.3
					c-3.8,4.7-6.8,9.8-9,15.3c-2.7,5.4-4.5,11-5.6,16.9c0.7,4.2,1.4,8.2,2.9,12.2c1.4,3.7,3,7.1,4.8,10.5c2.2,2.3,4.9,3.1,8.1,2.4
					c2.8-0.9,4.7-2.8,5.5-5.6c2.3-4,4-8.2,5.1-12.8C929.2,801.8,930.3,796.1,930.5,790.3z"/>
				<path opacity="0.1091" fill="#333333" d="M930.9,748.6c-0.6-1.4-1.2-2.8-1.9-4.2c0.4,2.1,0.8,4.1,1.2,6.2
					c-0.6-3.4-1.2-6.8-1.8-10.2c0.3,2.1,0.7,4.2,1,6.3c0.1-1.3,0.1-2.7,0.2-4c-0.9,3.8-1.9,7.6-2.8,11.3c1.3-5,2.6-10.1,3.9-15.1
					c-0.2-2.1-0.3-4.2-0.5-6.3c-1-1.1-2.1-2.3-3.1-3.4c-1.5,0-3.1,0-4.6,0c-3.7,2-6.4,5-7.9,8.9c-5.2,8.8-9.3,18.1-13.1,27.5
					c-2,4.3-3.6,8.7-4.8,13.2c-1.9,3.8-2.3,7.7-1.2,11.9c0.9,4.8,2.4,9.3,4.6,13.5c1,2.1,2.1,4.2,3.5,6.1c2,3.2,4.7,4,8.1,2.4
					c4.8-3.8,7.9-8.8,9.1-15.1c3.8-10.3,6.9-20.8,9.4-31.4C932.6,760.3,932.8,754.4,930.9,748.6z"/>
				<path opacity="0.1091" fill="#333333" d="M889.7,785c-1-2.5-1.6-5.2-1.8-7.9c-0.4-2.2-0.4-4.4-0.1-6.6c-0.3,2-0.5,4-0.8,5.9
					c0.5-3.3,1.1-6.5,1.6-9.8c-0.5-3-2.2-5.1-5-6.3c-2.8-1.3-5.4-1.2-8,0.4c-3.2,3.4-5.7,7.4-7.4,11.7c-2.2,4.2-3.5,8.6-3.9,13.3
					c-0.2,3.9,0,7.8,0.6,11.7c0.5,3.9,1.5,7.6,2.8,11.2c0.8,3,2.8,4.9,5.8,5.7c3,0.9,5.6,0.4,8-1.6c2.8-4.1,4.8-8.6,6-13.4
					C889,794.8,889.8,789.9,889.7,785z"/>
				<path opacity="0.1091" fill="#333333" d="M913.9,821c-0.4-2.1-0.9-4.2-1.3-6.3c0.1,1.2,0.3,2.4,0.4,3.5
					c-0.3-3.2-0.6-6.4-0.8-9.6c0.1,1.2,0.2,2.4,0.2,3.6c-0.2-2.4,0-4.7,0.7-7c0.9-2.6,0.3-4.8-1.8-6.5c-2.2-1.9-4.8-3-7.6-3.5
					c-2.7-0.5-4.7,0.5-6.1,2.9c-2,2.7-3.3,5.7-4.2,9c-0.4,3.4-0.5,6.7-0.2,10.1c0.4,4.9,1.4,9.5,2.7,14.2c1.6,2.1,3.7,3.4,6.3,4
					c2.5,0.9,5,1,7.4,0.1c2.1-1.8,3.4-4.1,4-6.8C914.5,826.3,914.6,823.7,913.9,821z"/>
				<path opacity="0.1091" fill="#333333" d="M944.5,782.3c-0.6-1.5-1.2-2.9-1.8-4.4c0.5,1.6,1.1,3.1,1.6,4.7
					c-0.8-2.8-1-5.5-0.7-8.4c-0.6,2.8-1.1,5.6-1.7,8.4c2.9-5.8,3.5-11.6,1.7-17.4c-1.1-1-2.2-2-3.3-3c-3.4-1.2-6.3-0.5-8.8,2.1
					c-3.1,4-5.6,8.2-7.6,12.8c-2.9,5.5-5.3,11.1-7.4,17c-2.3,3.6-2.9,7.3-1.8,11c1.4,7.2,4.5,13.8,8,20.1c2.2,2.5,4.9,3.3,8.1,2.4
					c4.1-2.1,6.7-5.5,7.7-10.3c2.8-7.2,5.1-14.6,6.7-22.2C947,790.6,946.7,786.4,944.5,782.3z"/>
				<path opacity="0.1091" fill="#333333" d="M897.7,768c-0.6-1.5-1.2-2.9-1.8-4.4c0.6,1.5,1.1,3.1,1.7,4.6
					c-0.9-2.7-1.1-5.4-0.8-8.1c-0.5,2.6-1,5.3-1.5,7.9c0.8-3.9,1.5-7.8,2.3-11.7c0.1-3.5-1.4-6-4.5-7.5c-3.2-1.3-6-0.7-8.6,1.6
					c-3.9,5-7,10.5-9.4,16.4c-2.8,5.7-4.8,11.7-5.9,17.9c0.4,5,0.8,9.9,2.3,14.7c1.3,4,2.8,7.8,4.6,11.6c2.2,2.5,4.9,3.3,8.1,2.4
					c4.1-2.1,6.7-5.6,7.6-10.3c2.8-7.3,5-14.7,6.7-22.3C900.2,776.4,900,772.1,897.7,768z"/>
				<path opacity="0.1091" fill="#333333" d="M918.7,765.2c-0.6-1.4-1.2-2.9-1.8-4.3c0.5,1.7,1,3.4,1.5,5.2
					c-0.8-2.6-1.1-5.3-0.8-8.1c-0.7,3.1-1.3,6.1-2,9.2c1-4.3,1.9-8.7,2.9-13c0.5-3.7-0.9-6.5-4.1-8.3c-3.5-1.2-6.5-0.3-8.9,2.5
					c-4.4,6.1-8,12.6-10.8,19.6c-3.3,6.8-5.7,13.8-7.3,21.2c0.7,4.7,1.6,9.3,2.9,13.8c1.2,4.2,2.9,7.8,5,11.6
					c2.1,2.7,4.8,3.5,8.1,2.4c4.4-2.7,7.1-6.7,8.1-11.9c3.1-8.3,5.7-16.7,7.6-25.4C920.9,774.7,920.8,769.9,918.7,765.2z"/>
				<path opacity="0.1091" fill="#333333" d="M943.2,804.2c-1.1-2.5-1.7-5-1.8-7.7c-0.3-2.1-0.4-4.2-0.3-6.3c-0.3,2.1-0.7,4.3-1,6.4
					c0.6-3.4,1.2-6.7,1.8-10.1c-0.2-3.2-1.8-5.5-4.8-6.8c-2.9-1.3-5.7-1.1-8.3,0.9c-3.5,4.1-6.3,8.6-8.3,13.7
					c-2.5,4.8-4.1,9.9-4.9,15.2c1.2,6.3,3.2,12.5,5.4,18.5c0.8,3,2.7,4.9,5.7,5.8c3,1,5.6,0.4,8-1.6c2.8-4.1,4.8-8.6,6-13.4
					C942.4,814,943.2,809.2,943.2,804.2z"/>
				<path opacity="0.1091" fill="#333333" d="M891.5,801.1c-1-4.6-1.4-9.2-1.2-13.9c-0.1,1.6-0.1,3.2-0.2,4.8c0.2-3,0.4-6,0.6-9
					c-0.1,1.6-0.2,3.2-0.3,4.8c0.3-1.4,0.6-2.7,0.9-4.1c0.2-1.6,0.4-3.1,0.6-4.7c-0.6-1.1-1.2-2.1-1.8-3.2c-1.2-0.8-2.3-1.6-3.5-2.4
					c-1.9-1.2-3.9-1.4-5.9-0.7c-1.6,0-2.7,0.7-3.4,2.1c-1.4,1.3-2.4,2.8-3.1,4.6c-1.2,1.8-2.1,3.8-2.7,5.8c-1.2,2.4-1.7,5-1.7,7.7
					c-0.4,3.6-0.5,7.2-0.2,10.8c0.3,4.8,1,9.5,2.1,14.2c1.2,2.5,3.2,4.1,6,4.8c2.7,1,5.3,0.7,7.7-0.7c2.4-2.9,4.1-6.2,4.9-9.9
					C891.5,808.5,891.9,804.8,891.5,801.1z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M874.2,178c0.5-2.2,1-4.4,1.5-6.6c0.3-2.5,0.5-5.1,0.6-7.6c0.5-2.4,0.2-4.7-0.8-7
					c-0.9-3.6-2.1-7.1-3.7-10.5c-1.2-2.8-3.3-4.7-6.2-5.7c-3.5-0.8-6.4,0.3-8.8,3.1c-4.1,3.5-7.7,7.3-10.9,11.6
					c-2.6,2.6-3.4,5.6-2.4,9c0.9,3.7,1.4,7.5,1.6,11.3c0.1-1.4,0.2-2.7,0.2-4.1c-0.4,4-1.1,7.9-2.3,11.7c-1,2.9-0.6,5.5,1.3,8
					c1.8,2.5,4.2,3.7,7.3,3.5c4.5-1.5,8.7-3.8,12.4-6.9C868.1,185.3,871.4,182,874.2,178z"/>
				<path opacity="0.1091" fill="#333333" d="M846.5,160.2c2.2-5.8,4.8-11.6,4.8-18c0.2-1.8-0.2-3.6-1-5.2c-1-3.5-2.3-6.9-3.9-10.1
					c-1.3-2.7-3.3-4.6-6.2-5.7c-3.3-0.8-6.2,0.1-8.5,2.8c-3.9,3.2-7.3,6.9-10.2,11c-2.5,2.6-3.2,5.5-2.1,8.7c1.6,3,2.9,6.2,3.6,9.5
					c0-1.3,0-2.6,0-3.9c-0.9,4.7-2.2,9.2-3.9,13.6c-1.7,5.1,3.4,11.5,8.6,11.4c3.9-1.1,7.4-2.9,10.5-5.5
					C841.7,166.5,844.4,163.7,846.5,160.2z"/>
				<path opacity="0.1091" fill="#333333" d="M854.3,114.3c0.3-4.1,0.1-8.1-0.6-12.1c-1-4.9-2.5-9.7-4.4-14.4
					c-0.8-3.2-2.8-5.2-6.1-5.8c-4.8,0.2-8.7,2.2-11.8,6.2c-6,5.5-11.5,11.3-16.6,17.7c-3.7,3.3-5.5,7.4-5.4,12.2
					c1.3,2.6,2.6,5.2,3.9,7.7c-0.6-1.3-1.3-2.6-1.9-4c0.9,2,1.4,4.1,1.6,6.2c0.4,2.2,0.5,4.5,0.2,6.7c0.4,3.2,2.1,5.5,5.1,6.7
					c4.4,0.8,8.3-0.3,11.8-3.4c6.3-3.9,12.2-8.4,17.7-13.3C851.7,122.2,853.9,118.7,854.3,114.3z"/>
				<path opacity="0.1091" fill="#333333" d="M874.6,145.1c0.4-4,0.2-8-0.7-12c-1-4.8-2.5-9.5-4.5-14c-0.9-3.1-2.9-5.1-6.1-5.8
					c-4.5-0.1-8.2,1.8-11.1,5.5c-5.6,5-10.7,10.4-15.3,16.3c-3.5,3.2-5.1,7-4.8,11.5c0.8,3.2,1.3,6.4,1.4,9.7
					c0.3,3.2,0.2,6.3-0.3,9.5c0.3,3.3,2,5.5,5.1,6.7c4.4,0.8,8.4-0.3,11.9-3.5c6.3-4,12.3-8.4,17.9-13.4
					C871.9,153,874.2,149.5,874.6,145.1z"/>
				<path opacity="0.1091" fill="#333333" d="M878.1,110.1c0.3-4.1,0.1-8.3-0.7-12.3c-1-5.1-2.4-10.1-4.4-14.9
					c-0.4-3.7-2.4-5.6-6.1-5.8c-5.9,1.1-10.8,4.1-14.6,9c-7.8,7.3-15.1,15-22,23.1c-4.7,4.1-7.5,9.1-8.3,15c1,2.5,1.5,5,1.6,7.7
					c0.6,2.8,0.7,5.6,0.4,8.4c-0.1,3.6,1.6,5.8,5.1,6.7c5.7,0.1,10.8-1.9,15.1-5.9c8.4-5.5,16.4-11.6,24.1-18.1
					C873.3,119.9,876.6,115.6,878.1,110.1z"/>
				<path opacity="0.1091" fill="#333333" d="M831,145.4c1.9-5.7,4.5-11.7,4.1-17.9c-0.3-5.4-2.6-10.8-4.4-15.8
					c-1.3-2.7-3.4-4.6-6.2-5.7c-3.1-1-5.8-0.2-8.1,2.3c-3.6,3-6.8,6.4-9.4,10.2c-2.3,2.5-2.9,5.2-1.7,8.3c1.9,2.8,3.3,5.8,4.2,9
					c-0.1-1.2-0.1-2.4-0.2-3.7c-0.4,4.7-1.4,9.3-2.9,13.8c-0.4,2.7,0.4,5.2,2.2,7.3c1.5,2.3,3.7,3.7,6.4,4.1
					c3.4-0.7,6.4-2.2,9.1-4.5C827.1,151,829.4,148.5,831,145.4z"/>
				<path opacity="0.1091" fill="#333333" d="M857.1,180.5c1.3-5.4,2.9-10.8,2.3-16.5c-0.6-5.6-3.1-11.1-5.1-16.2
					c-1.4-2.6-3.4-4.5-6-5.9c-2.6-1.3-5-0.9-7.3,1.1c-3.1,2.3-5.8,5-8,8.1c-2,2.2-2.3,4.7-1,7.3c1.7,4,2.9,8,3.7,12.3
					c-0.1-1.2-0.2-2.3-0.4-3.5c-0.3,4.5-0.9,8.9-2.1,13.3c-0.3,2.7,0.5,5.1,2.3,7.2c1.5,2.3,3.6,3.7,6.3,4.2c3.3-0.6,6.2-2,8.7-4.2
					C853.4,185.8,855.6,183.4,857.1,180.5z"/>
				<path opacity="0.1091" fill="#333333" d="M891.8,140c0-1.9,0.1-3.8,0.1-5.7c-0.3-3.3-1-6.6-2-9.8c-1-3.7-2.4-7-3.9-10.6
					c-0.6-3.5-2.6-5.4-6.1-5.8c-5.3,0.6-9.7,3.1-13.2,7.5c-6.9,6.3-13.4,13.1-19.3,20.3c-4.2,3.7-6.5,8.2-6.9,13.6
					c0.5,6.1,0.3,12.3-0.2,18.5c0,3.5,1.7,5.8,5,6.7c5.4,0.2,10.3-1.6,14.5-5.4c8-5.2,15.6-10.9,22.8-17.1
					C887.4,149.5,890.5,145.3,891.8,140z"/>
				<path opacity="0.1091" fill="#333333" d="M842.9,127.4c0.8-4.3,1-8.6,0.7-12.9c-1.2-4.8-2.8-9.4-4.7-13.8
					c-1.1-3-3.1-4.9-6.2-5.7c-4-0.4-7.4,1.1-10,4.4c-4.8,4.3-9.2,9-13.2,14.2c-3.1,2.9-4.3,6.3-3.6,10.3c1.5,2.6,3,5.2,4.5,7.8
					c-0.7-1.3-1.4-2.5-2.1-3.8c1.4,2.1,1.9,4.4,1.7,6.8c0.2,2.5,0,5.1-0.6,7.6c0.6,3,2.3,5.3,5.1,6.7c3.6,1.2,6.9,0.6,9.9-2
					c5-3,9.7-6.5,14-10.5C841.5,134.1,843.1,131.2,842.9,127.4z"/>
				<path opacity="0.1091" fill="#333333" d="M864.4,124.8c0.4-4.1,0.3-8.1-0.5-12.2c-1-4.9-2.5-9.7-4.4-14.3
					c-0.7-3.3-2.8-5.2-6.1-5.8c-4.9,0.3-9,2.5-12.1,6.5c-6.2,5.7-12,11.8-17.3,18.3c-3.9,3.4-5.8,7.6-5.8,12.5
					c1.2,2.7,1.9,5.4,2.2,8.3c0.5,3,0.5,6,0.1,9c0.3,3.3,1.9,5.6,5.1,6.7c4.7,0.7,8.8-0.6,12.5-4c6.7-4.3,13.1-9.1,19-14.3
					C861.4,133.1,863.8,129.5,864.4,124.8z"/>
				<path opacity="0.1091" fill="#333333" d="M887.5,158.1c0.5-4,0.3-8.1-0.6-12c-1-4.8-2.6-9.4-4.6-13.9c-0.9-3.1-2.9-5.1-6.1-5.8
					c-4.5-0.1-8.2,1.7-11.1,5.4c-5.6,5-10.7,10.4-15.3,16.2c-3.5,3.1-5.1,7-4.8,11.4c0.5,3.5,0.6,7,0.3,10.5c0,3.3-0.3,6.5-0.8,9.8
					c0.3,3.3,2,5.5,5,6.7c4.6,0.7,8.7-0.5,12.3-3.7c6.6-4.1,12.8-8.8,18.6-13.9C884.6,166.3,886.9,162.7,887.5,158.1z"/>
				<path opacity="0.1091" fill="#333333" d="M834.5,159.2c1.4-5.4,3.4-11.1,2.6-16.7c-0.8-5.7-2.8-11.2-4.6-16.6
					c-1.4-2.6-3.4-4.6-6-5.9c-2.7-1.2-5.1-0.8-7.3,1.2c-3.2,2.3-5.9,5.1-8.1,8.3c-2,2.3-2.4,4.7-1.1,7.4c2,3.7,3.5,7.5,4.5,11.5
					c-0.2-1.1-0.3-2.3-0.5-3.4c-0.1,4.4-0.7,8.8-1.8,13.1c-0.1,2.7,0.7,5,2.5,7.1c1.5,2.3,3.5,3.7,6.1,4.4c3-0.4,5.7-1.6,8-3.6
					C831.4,164.2,833.3,161.9,834.5,159.2z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M863,151.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C852.4,148.2,857.3,151,863,151.8z"/>
				<path opacity="0.1091" fill="#333333" d="M834.9,129.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C827.7,129.3,831,130.5,834.9,129.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M833.5,90.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C828,88,830.5,89.6,833.5,90.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M859.3,121.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C851,120.1,854.8,121.8,859.3,121.6z"/>
				<path opacity="0.1091" fill="#333333" d="M853.6,86.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C846.6,82.2,849.9,84.6,853.6,86.2z"/>
				<path opacity="0.1091" fill="#333333" d="M814.2,115.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C808.3,113,811,114.8,814.2,115.9z"/>
				<path opacity="0.1091" fill="#333333" d="M843.6,153.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C834.5,151.5,838.6,153.6,843.6,153.8z"/>
				<path opacity="0.1091" fill="#333333" d="M875.6,117.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C866.4,115.6,870.6,117.7,875.6,117.9z"/>
				<path opacity="0.1091" fill="#333333" d="M823.6,102.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C817.6,99.7,820.4,101.5,823.6,102.6z"/>
				<path opacity="0.1091" fill="#333333" d="M842.7,99.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C835.3,94.7,838.7,97.2,842.7,99.1z"/>
				<path opacity="0.1091" fill="#333333" d="M874,136.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C863.6,133,868.4,135.6,874,136.4z"/>
				<path opacity="0.1091" fill="#333333" d="M814.6,128.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C807.4,124.6,810.7,127.1,814.6,128.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M853.1,221.9c1.2-3,2.3-6,3.5-9c0.5-3-0.3-5.5-2.6-7.6c-2.1-2.2-4.7-3-7.6-2.4
					c-4.1,2.1-7.8,4.9-10.9,8.4c-3.4,3.2-6.2,6.9-8.2,11c-1,2.7-2,5.4-2.9,8.1c-0.5,3,0.4,5.5,2.6,7.5c2.1,2.2,4.6,3.1,7.5,2.6
					c4.1-1.9,7.6-4.6,10.6-7.9C848.5,229.5,851.2,225.9,853.1,221.9z"/>
				<path opacity="0.1091" fill="#333333" d="M826.6,203.4c1.5-3.4,3-6.9,4.5-10.3c0.4-2.9-0.5-5.4-2.7-7.4c-2-2.2-4.5-3.1-7.5-2.6
					c-3.9,1.9-7.4,4.4-10.3,7.7c-3.2,3-5.8,6.4-7.6,10.4c-1.3,3.1-2.5,6.2-3.8,9.3c-0.3,2.9,0.6,5.3,2.8,7.3c2,2.2,4.4,3.1,7.3,2.8
					c3.8-1.7,7.1-4.1,9.9-7.2C822.5,210.5,824.9,207.2,826.6,203.4z"/>
				<path opacity="0.1091" fill="#333333" d="M833.1,157.1c0-1.9,0-3.8,0-5.6c-0.7-3.3-2.7-5.2-6-5.9c-4.8,0.1-8.8,2.1-11.9,6
					c-6.1,5.4-11.8,11.2-17,17.4c-3.8,3.3-5.7,7.3-5.7,12.1c0,1.6,0,3.2,0,4.8c0.7,3.3,2.7,5.2,6,5.9c4.8,0,8.7-1.9,11.9-5.8
					c6.1-5.2,11.8-10.9,17-17C831.2,165.8,833.1,161.8,833.1,157.1z"/>
				<path opacity="0.1091" fill="#333333" d="M853.4,188.1c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-10.9,10.3-15.7,16.1c-3.6,3.1-5.3,6.9-5,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.2-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C851.9,196.3,853.6,192.5,853.4,188.1z"/>
				<path opacity="0.1091" fill="#333333" d="M857.3,152c-0.1-1.8-0.3-3.6-0.4-5.3c-0.3-3.7-2.3-5.7-6-5.9c-6,1.1-10.9,4-14.8,8.9
					c-8,7.2-15.5,14.9-22.5,23.1c-4.8,4-7.6,9.1-8.6,15c0.2,1.4,0.4,2.8,0.6,4.2c0.3,3.7,2.3,5.7,6,5.9c5.9-1,10.8-3.8,14.8-8.6
					c7.9-7.1,15.4-14.6,22.5-22.5C853.5,162.9,856.4,157.9,857.3,152z"/>
				<path opacity="0.1091" fill="#333333" d="M811.5,187.9c1.3-3.3,2.7-6.7,4-10c0.2-2.9-0.8-5.3-2.9-7.2c-2-2.2-4.4-3.1-7.2-2.9
					c-3.7,1.6-6.8,3.8-9.5,6.8c-3,2.7-5.2,5.8-6.8,9.5c-1.1,3.2-2.3,6.3-3.4,9.5c-0.2,2.8,0.8,5.2,3,7.1c1.9,2.1,4.3,3.1,7.2,3
					c3.6-1.5,6.6-3.7,9.2-6.6C807.9,194.6,810,191.5,811.5,187.9z"/>
				<path opacity="0.1091" fill="#333333" d="M836.3,223.8c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.8
					c-1.8-2.1-4.1-3.2-6.8-3.3c-3.1,1-5.8,2.8-8,5.3c-2.5,2.2-4.3,4.9-5.3,8c-1,3-2,6-3,9c0.2,2.7,1.3,4.9,3.4,6.8
					c1.8,2.1,4.1,3.2,6.8,3.4c3-1,5.6-2.6,7.7-5C833.6,229.4,835.3,226.8,836.3,223.8z"/>
				<path opacity="0.1091" fill="#333333" d="M870.3,183c-0.1-1.8-0.2-3.6-0.2-5.4c-0.5-3.5-2.5-5.5-6-5.9
					c-5.4,0.6-9.9,3.1-13.4,7.4c-7.1,6.3-13.7,13-19.9,20.2c-4.3,3.6-6.7,8.2-7.2,13.6c0.1,1.5,0.2,3,0.3,4.5c0.5,3.5,2.5,5.5,5.9,6
					c5.4-0.5,9.8-2.9,13.4-7.2c7.1-6.1,13.7-12.7,19.8-19.8C867.5,192.8,869.9,188.3,870.3,183z"/>
				<path opacity="0.1091" fill="#333333" d="M822.3,174.1c0.1-1.9,0.2-3.9,0.3-5.8c0.9-3.1,0.2-5.8-2.1-8.1
					c-2.3-2.3-5.1-2.9-8.2-1.9c-4.8,2.8-9.1,6.3-12.8,10.5c-4.1,3.8-7.4,8.2-10.1,13.1c-0.1,1.8-0.3,3.5-0.4,5.3
					c-1,3.1-0.3,5.8,2,8.1c2.3,2.3,5,2.9,8.1,2c4.8-2.7,9.1-6.1,12.9-10.2C816.2,183.3,819.6,179,822.3,174.1z"/>
				<path opacity="0.1091" fill="#333333" d="M843.5,167.5c0-1.9,0-3.7,0-5.6c-0.7-3.3-2.7-5.3-6-5.9c-4.9,0.2-9.1,2.3-12.3,6.4
					c-6.3,5.6-12.2,11.6-17.7,18.1c-3.9,3.4-6,7.5-6.1,12.5c0,1.6,0.1,3.1,0.1,4.7c0.7,3.3,2.6,5.3,6,5.9c4.9-0.1,9-2.2,12.3-6.1
					c6.3-5.5,12.2-11.4,17.7-17.7C841.4,176.5,843.4,172.4,843.5,167.5z"/>
				<path opacity="0.1091" fill="#333333" d="M866.4,201c0-1.9,0-3.7,0-5.6c-0.8-3.2-2.8-5.1-6-5.9c-4.5-0.1-8.3,1.6-11.3,5.3
					c-5.7,4.9-11,10.3-15.8,16.1c-3.6,3.1-5.3,6.9-5.1,11.4c0,1.6,0,3.2,0,4.8c0.8,3.2,2.8,5.2,5.9,6c4.5,0.2,8.3-1.5,11.3-5.1
					c5.7-4.8,11-10.1,15.8-15.8C864.9,209.3,866.6,205.5,866.4,201z"/>
				<path opacity="0.1091" fill="#333333" d="M814.5,201.9c1.2-3.2,2.3-6.3,3.5-9.5c-0.1-2.7-1.2-5-3.3-6.9
					c-1.8-2.1-4.1-3.2-6.9-3.3c-3.2,1.1-5.9,2.9-8.1,5.4c-2.5,2.2-4.3,4.9-5.4,8.1c-1,3-2,6-3,9c0.2,2.7,1.3,5,3.3,6.8
					c1.8,2.1,4.1,3.2,6.8,3.3c3.1-1,5.7-2.7,7.9-5.2C811.8,207.6,813.5,205,814.5,201.9z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M841,293.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C830.4,290.2,835.3,293,841,293.8z"/>
				<path opacity="0.1091" fill="#333333" d="M812.9,271.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C805.7,271.3,809,272.5,812.9,271.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M811.5,232.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C806,230,808.5,231.6,811.5,232.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M837.3,263.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C829,262.1,832.8,263.8,837.3,263.6z"/>
				<path opacity="0.1091" fill="#333333" d="M831.6,228.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C824.6,224.2,827.9,226.6,831.6,228.2z"/>
				<path opacity="0.1091" fill="#333333" d="M792.2,257.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C786.3,255,789,256.8,792.2,257.9z"/>
				<path opacity="0.1091" fill="#333333" d="M821.6,295.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C812.5,293.5,816.6,295.6,821.6,295.8z"/>
				<path opacity="0.1091" fill="#333333" d="M853.6,259.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C844.4,257.6,848.6,259.7,853.6,259.9z"/>
				<path opacity="0.1091" fill="#333333" d="M801.6,244.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C795.6,241.7,798.4,243.5,801.6,244.6z"/>
				<path opacity="0.1091" fill="#333333" d="M820.7,241.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C813.3,236.7,816.7,239.2,820.7,241.1z"/>
				<path opacity="0.1091" fill="#333333" d="M852,278.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C841.6,275,846.4,277.6,852,278.4z"/>
				<path opacity="0.1091" fill="#333333" d="M792.6,270.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C785.4,266.6,788.7,269.1,792.6,270.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M833,315.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C822.4,312.2,827.3,315,833,315.8z"/>
				<path opacity="0.1091" fill="#333333" d="M804.9,293.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C797.7,293.3,801,294.5,804.9,293.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M803.5,254.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C798,252,800.5,253.6,803.5,254.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M829.3,285.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C821,284.1,824.8,285.8,829.3,285.6z"/>
				<path opacity="0.1091" fill="#333333" d="M823.6,250.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C816.6,246.2,819.9,248.6,823.6,250.2z"/>
				<path opacity="0.1091" fill="#333333" d="M784.2,279.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C778.3,277,781,278.8,784.2,279.9z"/>
				<path opacity="0.1091" fill="#333333" d="M813.6,317.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C804.5,315.5,808.6,317.6,813.6,317.8z"/>
				<path opacity="0.1091" fill="#333333" d="M845.6,281.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C836.4,279.6,840.6,281.7,845.6,281.9z"/>
				<path opacity="0.1091" fill="#333333" d="M793.6,266.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C787.6,263.7,790.4,265.5,793.6,266.6z"/>
				<path opacity="0.1091" fill="#333333" d="M812.7,263.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C805.3,258.7,808.7,261.2,812.7,263.1z"/>
				<path opacity="0.1091" fill="#333333" d="M844,300.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C833.6,297,838.4,299.6,844,300.4z"/>
				<path opacity="0.1091" fill="#333333" d="M784.6,292.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C777.4,288.6,780.7,291.1,784.6,292.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M807,267.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C796.4,264.2,801.3,267,807,267.8z"/>
				<path opacity="0.1091" fill="#333333" d="M778.9,245.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C771.7,245.3,775,246.5,778.9,245.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M777.5,206.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C772,204,774.5,205.6,777.5,206.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M803.3,237.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C795,236.1,798.8,237.8,803.3,237.6z"/>
				<path opacity="0.1091" fill="#333333" d="M797.6,202.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C790.6,198.2,793.9,200.6,797.6,202.2z"/>
				<path opacity="0.1091" fill="#333333" d="M758.2,231.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C752.3,229,755,230.8,758.2,231.9z"/>
				<path opacity="0.1091" fill="#333333" d="M787.6,269.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C778.5,267.5,782.6,269.6,787.6,269.8z"/>
				<path opacity="0.1091" fill="#333333" d="M819.6,233.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C810.4,231.6,814.6,233.7,819.6,233.9z"/>
				<path opacity="0.1091" fill="#333333" d="M767.6,218.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C761.6,215.7,764.4,217.5,767.6,218.6z"/>
				<path opacity="0.1091" fill="#333333" d="M786.7,215.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C779.3,210.7,782.7,213.2,786.7,215.1z"/>
				<path opacity="0.1091" fill="#333333" d="M818,252.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C807.6,249,812.4,251.6,818,252.4z"/>
				<path opacity="0.1091" fill="#333333" d="M758.6,244.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C751.4,240.6,754.7,243.1,758.6,244.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M827,363.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C816.4,360.2,821.3,363,827,363.8z"/>
				<path opacity="0.1091" fill="#333333" d="M798.9,341.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C791.7,341.3,795,342.5,798.9,341.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M797.5,302.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C792,300,794.5,301.6,797.5,302.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M823.3,333.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C815,332.1,818.8,333.8,823.3,333.6z"/>
				<path opacity="0.1091" fill="#333333" d="M817.6,298.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C810.6,294.2,813.9,296.6,817.6,298.2z"/>
				<path opacity="0.1091" fill="#333333" d="M778.2,327.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C772.3,325,775,326.8,778.2,327.9z"/>
				<path opacity="0.1091" fill="#333333" d="M807.6,365.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C798.5,363.5,802.6,365.6,807.6,365.8z"/>
				<path opacity="0.1091" fill="#333333" d="M839.6,329.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C830.4,327.6,834.6,329.7,839.6,329.9z"/>
				<path opacity="0.1091" fill="#333333" d="M787.6,314.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C781.6,311.7,784.4,313.5,787.6,314.6z"/>
				<path opacity="0.1091" fill="#333333" d="M806.7,311.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C799.3,306.7,802.7,309.2,806.7,311.1z"/>
				<path opacity="0.1091" fill="#333333" d="M838,348.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C827.6,345,832.4,347.6,838,348.4z"/>
				<path opacity="0.1091" fill="#333333" d="M778.6,340.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C771.4,336.6,774.7,339.1,778.6,340.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M803,375.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C792.4,372.2,797.3,375,803,375.8z"/>
				<path opacity="0.1091" fill="#333333" d="M774.9,353.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C767.7,353.3,771,354.5,774.9,353.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M773.5,314.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C768,312,770.5,313.6,773.5,314.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M799.3,345.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C791,344.1,794.8,345.8,799.3,345.6z"/>
				<path opacity="0.1091" fill="#333333" d="M793.6,310.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C786.6,306.2,789.9,308.6,793.6,310.2z"/>
				<path opacity="0.1091" fill="#333333" d="M754.2,339.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C748.3,337,751,338.8,754.2,339.9z"/>
				<path opacity="0.1091" fill="#333333" d="M783.6,377.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C774.5,375.5,778.6,377.6,783.6,377.8z"/>
				<path opacity="0.1091" fill="#333333" d="M815.6,341.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C806.4,339.6,810.6,341.7,815.6,341.9z"/>
				<path opacity="0.1091" fill="#333333" d="M763.6,326.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C757.6,323.7,760.4,325.5,763.6,326.6z"/>
				<path opacity="0.1091" fill="#333333" d="M782.7,323.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C775.3,318.7,778.7,321.2,782.7,323.1z"/>
				<path opacity="0.1091" fill="#333333" d="M814,360.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C803.6,357,808.4,359.6,814,360.4z"/>
				<path opacity="0.1091" fill="#333333" d="M754.6,352.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C747.4,348.6,750.7,351.1,754.6,352.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M833.9,379.1c1.3-1.2,2.6-2.5,3.9-3.7c1.7-2.8,1.7-5.6-0.1-8.4
					c-3.2-3.3-7.3-4.7-12.1-4.3c-7.7-0.6-15.5-0.5-23.2,0.2c-4.8-0.4-8.8,1.1-12,4.5c-1.1,1.1-2.2,2.1-3.3,3.2
					c-1.7,2.8-1.7,5.6,0,8.4c3.2,3.3,7.1,4.8,11.9,4.4c7.7,0.6,15.3,0.7,23,0C826.7,383.8,830.7,382.4,833.9,379.1z"/>
				<path opacity="0.1091" fill="#333333" d="M801.5,362.6c3.4-1.3,6.8-2.6,10.2-3.9c2.4-1.8,3.5-4.2,3.3-7.2c0.1-3-1-5.4-3.4-7.1
					c-4.1-1.5-8.4-2.1-12.8-1.8c-4.4-0.2-8.6,0.5-12.8,2c-3,1.2-6,2.5-9,3.7c-2.3,1.8-3.4,4.2-3.2,7.2c-0.1,3,0.9,5.3,3.2,7.2
					c3.9,1.5,8,2.1,12.2,1.9C793.5,364.8,797.6,364.1,801.5,362.6z"/>
				<path opacity="0.1091" fill="#333333" d="M805.8,323.7c3.2-1.3,6.5-2.6,9.7-3.8c2.4-1.8,3.5-4.2,3.4-7.2c0.1-3-1.1-5.4-3.5-7.1
					c-4.2-1.4-8.6-2-13-1.8c-4.5-0.2-8.8,0.5-13,2c-2.9,1.2-5.7,2.4-8.6,3.6c-2.3,1.8-3.4,4.2-3.3,7.2c-0.1,3,1,5.4,3.3,7.2
					c4,1.5,8.2,2.1,12.5,1.9C797.6,325.9,801.8,325.2,805.8,323.7z"/>
				<path opacity="0.1091" fill="#333333" d="M832.3,351.2c1.2-1.2,2.4-2.5,3.7-3.7c1.7-2.8,1.7-5.6-0.1-8.4
					c-3.2-3.3-7.2-4.7-12-4.2c-7.7-0.5-15.4-0.5-23,0.3c-4.8-0.4-8.8,1.1-11.9,4.5c-1.1,1.1-2.1,2.1-3.2,3.2c-1.7,2.8-1.7,5.6,0,8.4
					c3.1,3.3,7.1,4.8,11.8,4.4c7.6,0.6,15.2,0.6,22.8,0C825.3,355.9,829.2,354.5,832.3,351.2z"/>
				<path opacity="0.1091" fill="#333333" d="M833.9,318.1c1.2-1.2,2.5-2.5,3.7-3.7c1.7-2.8,1.6-5.6-0.1-8.4
					c-3.1-3.3-7.1-4.7-11.8-4.2c-7.5-0.5-15.1-0.4-22.6,0.3c-4.7-0.3-8.6,1.2-11.7,4.5c-1.1,1.1-2.1,2.1-3.2,3.2
					c-1.7,2.8-1.7,5.6,0,8.4c3.1,3.3,6.9,4.8,11.6,4.3c7.5,0.6,14.9,0.6,22.4-0.1C827,322.9,830.9,321.4,833.9,318.1z"/>
				<path opacity="0.1091" fill="#333333" d="M785.5,350c4.3-1.8,8.6-3.6,12.9-5.4c1.7-2.1,2.4-4.5,2.2-7.2c0.2-2.7-0.6-5.1-2.2-7.2
					c-2.6-1.5-5.4-2.1-8.4-1.9c-3-0.2-5.8,0.4-8.4,1.9c-2.9,1.3-5.9,2.7-8.8,4c-1.6,2.1-2.3,4.5-2.1,7.2c-0.2,2.1,0.2,4,1.3,5.7
					c0.6,1.8,2,2.8,4.1,2.9C779.2,350.7,782.4,350.7,785.5,350z"/>
				<path opacity="0.1091" fill="#333333" d="M811.2,385.2c2.7-1.2,5.3-2.5,8-3.7c2.5-1.7,3.7-4.1,3.6-7.2c0.1-3.1-1.2-5.4-3.7-7.1
					c-4.5-1.5-9.1-2-13.9-1.8c-4.8-0.2-9.4,0.5-13.9,2c-2.3,1.1-4.7,2.2-7,3.4c-2.5,1.7-3.7,4.1-3.6,7.1c-0.1,3,1.1,5.4,3.6,7.2
					c4.4,1.5,8.8,2.1,13.5,1.9C802.3,387.3,806.8,386.7,811.2,385.2z"/>
				<path opacity="0.1091" fill="#333333" d="M850.3,347.1c1.1-1.3,2.2-2.5,3.3-3.8c2.2-2.8,2.2-5.6-0.1-8.4
					c-4.5-3.4-9.7-4.8-15.5-4.2c-9.9-0.5-19.9-0.4-29.8,0.3c-5.9-0.5-11,1-15.4,4.5c-0.8,1.1-1.7,2.1-2.5,3.2
					c-2.2,2.8-2.2,5.6,0,8.4c4.4,3.5,9.5,4.9,15.3,4.4c9.8,0.6,19.6,0.6,29.4,0C840.8,352,845.9,350.6,850.3,347.1z"/>
				<path opacity="0.1091" fill="#333333" d="M795.4,335.8c3.3-1.3,6.7-2.7,10-4c2.1-1.9,3.1-4.3,2.9-7.2c0.1-2.9-0.8-5.3-2.9-7.2
					c-3.5-1.5-7.2-2.1-11-1.9c-3.8-0.2-7.5,0.4-11,1.9c-3,1.3-6,2.7-9,4c-2,1.9-3,4.3-2.8,7.2c-0.2,2.8,0.8,5.2,2.8,7.2
					c3.4,1.5,6.9,2.1,10.6,1.9C788.5,337.9,792,337.3,795.4,335.8z"/>
				<path opacity="0.1091" fill="#333333" d="M818.9,333.8c1.4-1.2,2.8-2.4,4.1-3.6c2.9-1.6,4.3-4,4.3-7.2c-0.1-3.3-1.6-5.6-4.5-7.1
					c-5.4-1.4-10.9-2-16.5-1.7c-5.6-0.2-11.1,0.6-16.5,2.1c-1.3,1.1-2.5,2.2-3.8,3.2c-2.9,1.5-4.3,3.9-4.3,7.2
					c0,3.3,1.5,5.6,4.4,7.2c5.4,1.5,10.8,2.1,16.4,1.9C808,336,813.5,335.3,818.9,333.8z"/>
				<path opacity="0.1091" fill="#333333" d="M846.7,364.2c1.1-1.3,2.3-2.5,3.4-3.8c2.1-2.8,2.1-5.6-0.1-8.4
					c-4.2-3.4-9.2-4.8-14.8-4.2c-9.5-0.5-19-0.4-28.4,0.3c-5.6-0.5-10.6,1-14.7,4.5c-0.9,1.1-1.8,2.1-2.7,3.2
					c-2.1,2.8-2.1,5.6,0,8.4c4.1,3.5,9,4.9,14.6,4.4c9.4,0.6,18.7,0.7,28.1,0C837.7,369.1,842.6,367.6,846.7,364.2z"/>
				<path opacity="0.1091" fill="#333333" d="M789.4,363.3c3.1-1.3,6.2-2.7,9.2-4c1.7-2.1,2.5-4.4,2.3-7.2c0.2-2.7-0.6-5.1-2.3-7.2
					c-2.7-1.5-5.7-2.1-8.8-1.9c-3.1-0.2-6.1,0.4-8.8,1.9c-2.8,1.3-5.6,2.7-8.3,4c-1.7,2.1-2.4,4.5-2.2,7.2c-0.2,2.7,0.6,5.1,2.2,7.2
					c2.6,1.5,5.4,2.1,8.3,1.9C784.1,365.4,786.9,364.8,789.4,363.3z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M857,317.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C846.4,314.2,851.3,317,857,317.8z"/>
				<path opacity="0.1091" fill="#333333" d="M828.9,295.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C821.7,295.3,825,296.5,828.9,295.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M827.5,256.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C822,254,824.5,255.6,827.5,256.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M853.3,287.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C845,286.1,848.8,287.8,853.3,287.6z"/>
				<path opacity="0.1091" fill="#333333" d="M847.6,252.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C840.6,248.2,843.9,250.6,847.6,252.2z"/>
				<path opacity="0.1091" fill="#333333" d="M808.2,281.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C802.3,279,805,280.8,808.2,281.9z"/>
				<path opacity="0.1091" fill="#333333" d="M837.6,319.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C828.5,317.5,832.6,319.6,837.6,319.8z"/>
				<path opacity="0.1091" fill="#333333" d="M869.6,283.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C860.4,281.6,864.6,283.7,869.6,283.9z"/>
				<path opacity="0.1091" fill="#333333" d="M817.6,268.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C811.6,265.7,814.4,267.5,817.6,268.6z"/>
				<path opacity="0.1091" fill="#333333" d="M836.7,265.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C829.3,260.7,832.7,263.2,836.7,265.1z"/>
				<path opacity="0.1091" fill="#333333" d="M868,302.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C857.6,299,862.4,301.6,868,302.4z"/>
				<path opacity="0.1091" fill="#333333" d="M808.6,294.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C801.4,290.6,804.7,293.1,808.6,294.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M853,267.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C842.4,264.2,847.3,267,853,267.8z"/>
				<path opacity="0.1091" fill="#333333" d="M824.9,245.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C817.7,245.3,821,246.5,824.9,245.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M823.5,206.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C818,204,820.5,205.6,823.5,206.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M849.3,237.6c3.2-0.8,5.2-2.8,5.9-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C841,236.1,844.8,237.8,849.3,237.6z"/>
				<path opacity="0.1091" fill="#333333" d="M843.6,202.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C836.6,198.2,839.9,200.6,843.6,202.2z"/>
				<path opacity="0.1091" fill="#333333" d="M804.2,231.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C798.3,229,801,230.8,804.2,231.9z"/>
				<path opacity="0.1091" fill="#333333" d="M833.6,269.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C824.5,267.5,828.6,269.6,833.6,269.8z"/>
				<path opacity="0.1091" fill="#333333" d="M865.6,233.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C856.4,231.6,860.6,233.7,865.6,233.9z"/>
				<path opacity="0.1091" fill="#333333" d="M813.6,218.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C807.6,215.7,810.4,217.5,813.6,218.6z"/>
				<path opacity="0.1091" fill="#333333" d="M832.7,215.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C825.3,210.7,828.7,213.2,832.7,215.1z"/>
				<path opacity="0.1091" fill="#333333" d="M864,252.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C853.6,249,858.4,251.6,864,252.4z"/>
				<path opacity="0.1091" fill="#333333" d="M804.6,244.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C797.4,240.6,800.7,243.1,804.6,244.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M843,239.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C832.4,236.2,837.3,239,843,239.8z"/>
				<path opacity="0.1091" fill="#333333" d="M814.9,217.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C807.7,217.3,811,218.5,814.9,217.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M813.5,178.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C808,176,810.5,177.6,813.5,178.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M839.3,209.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C831,208.1,834.8,209.8,839.3,209.6z"/>
				<path opacity="0.1091" fill="#333333" d="M833.6,174.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C826.6,170.2,829.9,172.6,833.6,174.2z"/>
				<path opacity="0.1091" fill="#333333" d="M794.2,203.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C788.3,201,791,202.8,794.2,203.9z"/>
				<path opacity="0.1091" fill="#333333" d="M823.6,241.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C814.5,239.5,818.6,241.6,823.6,241.8z"/>
				<path opacity="0.1091" fill="#333333" d="M855.6,205.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C846.4,203.6,850.6,205.7,855.6,205.9z"/>
				<path opacity="0.1091" fill="#333333" d="M803.6,190.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C797.6,187.7,800.4,189.5,803.6,190.6z"/>
				<path opacity="0.1091" fill="#333333" d="M822.7,187.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C815.3,182.7,818.7,185.2,822.7,187.1z"/>
				<path opacity="0.1091" fill="#333333" d="M854,224.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C843.6,221,848.4,223.6,854,224.4z"/>
				<path opacity="0.1091" fill="#333333" d="M794.6,216.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C787.4,212.6,790.7,215.1,794.6,216.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M843,219.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C832.4,216.2,837.3,219,843,219.8z"/>
				<path opacity="0.1091" fill="#333333" d="M814.9,197.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C807.7,197.3,811,198.5,814.9,197.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M813.5,158.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C808,156,810.5,157.6,813.5,158.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M839.3,189.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C831,188.1,834.8,189.8,839.3,189.6z"/>
				<path opacity="0.1091" fill="#333333" d="M833.6,154.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C826.6,150.2,829.9,152.6,833.6,154.2z"/>
				<path opacity="0.1091" fill="#333333" d="M794.2,183.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C788.3,181,791,182.8,794.2,183.9z"/>
				<path opacity="0.1091" fill="#333333" d="M823.6,221.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C814.5,219.5,818.6,221.6,823.6,221.8z"/>
				<path opacity="0.1091" fill="#333333" d="M855.6,185.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C846.4,183.6,850.6,185.7,855.6,185.9z"/>
				<path opacity="0.1091" fill="#333333" d="M803.6,170.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C797.6,167.7,800.4,169.5,803.6,170.6z"/>
				<path opacity="0.1091" fill="#333333" d="M822.7,167.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C815.3,162.7,818.7,165.2,822.7,167.1z"/>
				<path opacity="0.1091" fill="#333333" d="M854,204.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C843.6,201,848.4,203.6,854,204.4z"/>
				<path opacity="0.1091" fill="#333333" d="M794.6,196.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C787.4,192.6,790.7,195.1,794.6,196.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M843,215.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C832.4,212.2,837.3,215,843,215.8z"/>
				<path opacity="0.1091" fill="#333333" d="M814.9,193.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C807.7,193.3,811,194.5,814.9,193.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M813.5,154.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C808,152,810.5,153.6,813.5,154.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M839.3,185.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C831,184.1,834.8,185.8,839.3,185.6z"/>
				<path opacity="0.1091" fill="#333333" d="M833.6,150.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C826.6,146.2,829.9,148.6,833.6,150.2z"/>
				<path opacity="0.1091" fill="#333333" d="M794.2,179.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C788.3,177,791,178.8,794.2,179.9z"/>
				<path opacity="0.1091" fill="#333333" d="M823.6,217.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C814.5,215.5,818.6,217.6,823.6,217.8z"/>
				<path opacity="0.1091" fill="#333333" d="M855.6,181.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C846.4,179.6,850.6,181.7,855.6,181.9z"/>
				<path opacity="0.1091" fill="#333333" d="M803.6,166.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C797.6,163.7,800.4,165.5,803.6,166.6z"/>
				<path opacity="0.1091" fill="#333333" d="M822.7,163.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C815.3,158.7,818.7,161.2,822.7,163.1z"/>
				<path opacity="0.1091" fill="#333333" d="M854,200.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C843.6,197,848.4,199.6,854,200.4z"/>
				<path opacity="0.1091" fill="#333333" d="M794.6,192.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C787.4,188.6,790.7,191.1,794.6,192.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M841,185.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C830.4,182.2,835.3,185,841,185.8z"/>
				<path opacity="0.1091" fill="#333333" d="M812.9,163.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C805.7,163.3,809,164.5,812.9,163.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M811.5,124.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C806,122,808.5,123.6,811.5,124.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M837.3,155.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C829,154.1,832.8,155.8,837.3,155.6z"/>
				<path opacity="0.1091" fill="#333333" d="M831.6,120.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C824.6,116.2,827.9,118.6,831.6,120.2z"/>
				<path opacity="0.1091" fill="#333333" d="M792.2,149.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C786.3,147,789,148.8,792.2,149.9z"/>
				<path opacity="0.1091" fill="#333333" d="M821.6,187.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C812.5,185.5,816.6,187.6,821.6,187.8z"/>
				<path opacity="0.1091" fill="#333333" d="M853.6,151.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C844.4,149.6,848.6,151.7,853.6,151.9z"/>
				<path opacity="0.1091" fill="#333333" d="M801.6,136.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C795.6,133.7,798.4,135.5,801.6,136.6z"/>
				<path opacity="0.1091" fill="#333333" d="M820.7,133.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C813.3,128.7,816.7,131.2,820.7,133.1z"/>
				<path opacity="0.1091" fill="#333333" d="M852,170.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C841.6,167,846.4,169.6,852,170.4z"/>
				<path opacity="0.1091" fill="#333333" d="M792.6,162.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C785.4,158.6,788.7,161.1,792.6,162.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M845,169.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C834.4,166.2,839.3,169,845,169.8z"/>
				<path opacity="0.1091" fill="#333333" d="M816.9,147.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C809.7,147.3,813,148.5,816.9,147.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M815.5,108.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C810,106,812.5,107.6,815.5,108.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M841.3,139.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C833,138.1,836.8,139.8,841.3,139.6z"/>
				<path opacity="0.1091" fill="#333333" d="M835.6,104.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C828.6,100.2,831.9,102.6,835.6,104.2z"/>
				<path opacity="0.1091" fill="#333333" d="M796.2,133.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C790.3,131,793,132.8,796.2,133.9z"/>
				<path opacity="0.1091" fill="#333333" d="M825.6,171.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C816.5,169.5,820.6,171.6,825.6,171.8z"/>
				<path opacity="0.1091" fill="#333333" d="M857.6,135.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C848.4,133.6,852.6,135.7,857.6,135.9z"/>
				<path opacity="0.1091" fill="#333333" d="M805.6,120.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C799.6,117.7,802.4,119.5,805.6,120.6z"/>
				<path opacity="0.1091" fill="#333333" d="M824.7,117.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C817.3,112.7,820.7,115.2,824.7,117.1z"/>
				<path opacity="0.1091" fill="#333333" d="M856,154.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C845.6,151,850.4,153.6,856,154.4z"/>
				<path opacity="0.1091" fill="#333333" d="M796.6,146.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C789.4,142.6,792.7,145.1,796.6,146.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M929,271.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C918.4,268.2,923.3,271,929,271.8z"/>
				<path opacity="0.1091" fill="#333333" d="M900.9,249.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C893.7,249.3,897,250.5,900.9,249.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M899.5,210.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C894,208,896.5,209.6,899.5,210.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M925.3,241.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C917,240.1,920.8,241.8,925.3,241.6z"/>
				<path opacity="0.1091" fill="#333333" d="M919.6,206.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C912.6,202.2,915.9,204.6,919.6,206.2z"/>
				<path opacity="0.1091" fill="#333333" d="M880.2,235.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C874.3,233,877,234.8,880.2,235.9z"/>
				<path opacity="0.1091" fill="#333333" d="M909.6,273.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C900.5,271.5,904.6,273.6,909.6,273.8z"/>
				<path opacity="0.1091" fill="#333333" d="M941.6,237.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C932.4,235.6,936.6,237.7,941.6,237.9z"/>
				<path opacity="0.1091" fill="#333333" d="M889.6,222.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C883.6,219.7,886.4,221.5,889.6,222.6z"/>
				<path opacity="0.1091" fill="#333333" d="M908.7,219.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C901.3,214.7,904.7,217.2,908.7,219.1z"/>
				<path opacity="0.1091" fill="#333333" d="M940,256.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C929.6,253,934.4,255.6,940,256.4z"/>
				<path opacity="0.1091" fill="#333333" d="M880.6,248.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C873.4,244.6,876.7,247.1,880.6,248.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M907,235.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C896.4,232.2,901.3,235,907,235.8z"/>
				<path opacity="0.1091" fill="#333333" d="M878.9,213.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C871.7,213.3,875,214.5,878.9,213.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M877.5,174.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C872,172,874.5,173.6,877.5,174.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M903.3,205.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C895,204.1,898.8,205.8,903.3,205.6z"/>
				<path opacity="0.1091" fill="#333333" d="M897.6,170.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C890.6,166.2,893.9,168.6,897.6,170.2z"/>
				<path opacity="0.1091" fill="#333333" d="M858.2,199.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C852.3,197,855,198.8,858.2,199.9z"/>
				<path opacity="0.1091" fill="#333333" d="M887.6,237.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C878.5,235.5,882.6,237.6,887.6,237.8z"/>
				<path opacity="0.1091" fill="#333333" d="M919.6,201.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C910.4,199.6,914.6,201.7,919.6,201.9z"/>
				<path opacity="0.1091" fill="#333333" d="M867.6,186.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C861.6,183.7,864.4,185.5,867.6,186.6z"/>
				<path opacity="0.1091" fill="#333333" d="M886.7,183.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C879.3,178.7,882.7,181.2,886.7,183.1z"/>
				<path opacity="0.1091" fill="#333333" d="M918,220.4c3.6-0.4,5.6-2.4,6-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C907.6,217,912.4,219.6,918,220.4z"/>
				<path opacity="0.1091" fill="#333333" d="M858.6,212.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C851.4,208.6,854.7,211.1,858.6,212.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M905,179.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C894.4,176.2,899.3,179,905,179.8z"/>
				<path opacity="0.1091" fill="#333333" d="M876.9,157.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C869.7,157.3,873,158.5,876.9,157.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M875.5,118.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C870,116,872.5,117.6,875.5,118.5
					z"/>
				<path opacity="0.1091" fill="#333333" d="M901.3,149.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-6,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C893,148.1,896.8,149.8,901.3,149.6z"/>
				<path opacity="0.1091" fill="#333333" d="M895.6,114.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C888.6,110.2,891.9,112.6,895.6,114.2z"/>
				<path opacity="0.1091" fill="#333333" d="M856.2,143.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C850.3,141,853,142.8,856.2,143.9z"/>
				<path opacity="0.1091" fill="#333333" d="M885.6,181.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C876.5,179.5,880.6,181.6,885.6,181.8z"/>
				<path opacity="0.1091" fill="#333333" d="M917.6,145.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C908.4,143.6,912.6,145.7,917.6,145.9z"/>
				<path opacity="0.1091" fill="#333333" d="M865.6,130.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C859.6,127.7,862.4,129.5,865.6,130.6z"/>
				<path opacity="0.1091" fill="#333333" d="M884.7,127.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4
					c-1.8-3.9-4.4-7.4-7.7-10.3c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4
					c1.8,3.9,4.4,7.4,7.7,10.3C877.3,122.7,880.7,125.2,884.7,127.1z"/>
				<path opacity="0.1091" fill="#333333" d="M916,164.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-6,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C905.6,161,910.4,163.6,916,164.4z"/>
				<path opacity="0.1091" fill="#333333" d="M856.6,156.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C849.4,152.6,852.7,155.1,856.6,156.8z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<path opacity="0.1091" fill="#333333" d="M857,145.8c3.6-0.3,5.6-2.3,6-5.9c-0.8-5.8-3.6-10.6-8.3-14.5
					c-6.8-7.7-14.1-15-21.8-21.9c-3.9-4.7-8.7-7.4-14.4-8.3c-3.6,0.3-5.6,2.3-6,5.9c0.8,5.8,3.6,10.6,8.3,14.5
					c6.8,7.7,14.1,15,21.8,21.9C846.4,142.2,851.3,145,857,145.8z"/>
				<path opacity="0.1091" fill="#333333" d="M828.9,123.9c3-1,5-3,6-5.9c0.6-3.9-0.6-7.2-3.7-9.9c-3.9-4.8-8.3-9.2-13.2-13.2
					c-2.7-3.1-6-4.4-9.9-3.8c-3,1-5,3-6,5.9c-0.6,3.9,0.6,7.2,3.7,9.9c3.9,4.8,8.3,9.2,13.2,13.2C821.7,123.3,825,124.5,828.9,123.9
					z"/>
				<path opacity="0.1091" fill="#333333" d="M827.5,84.5c2.7-0.2,4.9-1.4,6.7-3.4c2-1.8,3.2-4,3.4-6.7c-0.9-3-2.5-5.5-4.9-7.5
					c-2.1-2.3-4.6-4-7.5-4.8c-2.7,0.2-4.9,1.4-6.7,3.4c-2,1.8-3.2,4-3.4,6.7c0.9,3,2.5,5.5,4.9,7.5C822,82,824.5,83.6,827.5,84.5z"
					/>
				<path opacity="0.1091" fill="#333333" d="M853.3,115.6c3.2-0.8,5.2-2.8,6-6c0.2-4.5-1.5-8.3-5.2-11.3
					c-4.9-5.7-10.2-11-15.9-15.9c-3.1-3.6-6.8-5.3-11.3-5.2c-3.2,0.8-5.2,2.8-5.9,6c-0.2,4.5,1.5,8.3,5.2,11.3
					c4.9,5.7,10.2,11,15.9,15.9C845,114.1,848.8,115.8,853.3,115.6z"/>
				<path opacity="0.1091" fill="#333333" d="M847.6,80.2c2.9,0.3,5.3-0.7,7.3-2.9c2.2-2,3.1-4.4,2.8-7.3c-1.6-3.7-3.9-6.9-7-9.6
					c-2.7-3-6-5.3-9.7-6.9c-2.9-0.3-5.3,0.7-7.3,2.9c-2.2,2-3.1,4.4-2.8,7.3c1.6,3.7,3.9,6.9,7,9.6
					C840.6,76.2,843.9,78.6,847.6,80.2z"/>
				<path opacity="0.1091" fill="#333333" d="M808.2,109.9c2.7-0.1,5-1.2,6.9-3.3c2.1-1.8,3.2-4.1,3.3-6.9c-1.1-3.2-2.9-5.9-5.4-8.1
					c-2.2-2.5-4.9-4.4-8.1-5.4c-2.7,0.1-5,1.2-6.9,3.3c-2.1,1.8-3.2,4.1-3.3,6.9c1.1,3.2,2.9,5.9,5.4,8.1
					C802.3,107,805,108.8,808.2,109.9z"/>
				<path opacity="0.1091" fill="#333333" d="M837.6,147.8c3.3-0.6,5.3-2.6,6-5.9c-0.1-4.9-2.2-9.1-6.2-12.4
					c-5.5-6.4-11.5-12.4-17.9-17.9c-3.3-4-7.4-6.1-12.4-6.2c-3.3,0.6-5.3,2.6-6,5.9c0.1,4.9,2.2,9.1,6.2,12.4
					c5.5,6.4,11.5,12.4,17.9,17.9C828.5,145.5,832.6,147.6,837.6,147.8z"/>
				<path opacity="0.1091" fill="#333333" d="M869.6,111.9c3.4-0.6,5.3-2.6,5.9-6c-0.2-5-2.3-9.2-6.4-12.5
					c-5.6-6.5-11.7-12.6-18.2-18.2c-3.4-4-7.5-6.1-12.5-6.3c-3.4,0.6-5.3,2.6-5.9,6c0.2,5,2.3,9.2,6.4,12.5
					c5.6,6.5,11.7,12.6,18.2,18.2C860.4,109.6,864.6,111.7,869.6,111.9z"/>
				<path opacity="0.1091" fill="#333333" d="M817.6,96.6c4.7,0,10.6-5.1,10.1-10.1c-1.1-3.2-3-5.9-5.5-8.2c-2.3-2.6-5-4.4-8.2-5.5
					c-4.7,0-10.6,5.1-10.1,10.1c1.1,3.2,3,5.9,5.5,8.2C811.6,93.7,814.4,95.5,817.6,96.6z"/>
				<path opacity="0.1091" fill="#333333" d="M836.7,93.1c2.9,0.4,5.4-0.5,7.4-2.7c2.2-2,3.1-4.5,2.7-7.4c-1.8-3.9-4.4-7.4-7.7-10.3
					c-2.9-3.3-6.4-5.8-10.3-7.6c-2.9-0.4-5.4,0.5-7.4,2.7c-2.2,2-3.1,4.5-2.7,7.4c1.8,3.9,4.4,7.4,7.7,10.3
					C829.3,88.7,832.7,91.2,836.7,93.1z"/>
				<path opacity="0.1091" fill="#333333" d="M868,130.4c3.6-0.4,5.6-2.4,5.9-6c-0.7-5.7-3.4-10.4-8-14.2
					c-6.7-7.6-13.8-14.7-21.3-21.3c-3.8-4.6-8.5-7.2-14.2-8c-3.6,0.4-5.6,2.4-5.9,6c0.7,5.7,3.4,10.4,8,14.2
					c6.7,7.6,13.8,14.7,21.3,21.3C857.6,127,862.4,129.6,868,130.4z"/>
				<path opacity="0.1091" fill="#333333" d="M808.6,122.8c2.9,0.3,5.4-0.6,7.4-2.8c2.2-2,3.1-4.4,2.8-7.3c-1.7-3.8-4.1-7.2-7.3-10
					c-2.8-3.1-6.1-5.6-10-7.3c-2.9-0.3-5.4,0.6-7.4,2.8c-2.2,2-3.1,4.4-2.8,7.3c1.7,3.8,4.1,7.2,7.3,10
					C801.4,118.6,804.7,121.1,808.6,122.8z"/>
			</g>
		</g>
	</g>
</g>
</svg>
</div>)}}