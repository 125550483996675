import React from "react";

export default class CloakroomSVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lOn: false,
			l1: false,
			l2: false,
		}
	}

	componentDidMount(){
		setInterval(() => {
			this.setState({lOn: false})
			if (this.props.isNotebookOnceOpened){
				setTimeout(() => {
					this.setState({ lOn: true })
				}, 1000);
			}
		}, 2000);

		setInterval(() => {
			this.setState({ l1: false })
			if (this.props.isCommutatorActivated) {
				this.setState({ l1: true })
				setTimeout(() => {
					this.setState({ l1: false })
				}, 300);
				setTimeout(() => {
					this.setState({ l1: true })
				}, 500);
				setTimeout(() => {
					this.setState({ l1: false })
				}, 800);
				setTimeout(() => {
					this.setState({ l1: true })
				}, 1000);
				setTimeout(() => {
					this.setState({ l1: false })
				}, 1200);
				setTimeout(() => {
					this.setState({ l1: true })
				}, 1300);
				setTimeout(() => {
					this.setState({ l1: false })
				}, 1600);
			}
		}, 3000);


	}

	render() {
		let color4 = this.props.selectedDresses.hat ? '#0d0' :'#2A2A2A'
		let color4a = this.props.selectedDresses.hat ? '#3d5' : '#36353C'
		let color4b = this.props.selectedDresses.hat ? '#2d3' : '#4B4950'

		let color2 = this.props.selectedDresses.shoes ? '#0d0' : '#995C20'
		let color2a = this.props.selectedDresses.shoes ? '#050' : '#734518'

		let color3 = this.props.selectedDresses.pants ? '#0d0' :'#2E3192'
		let color3a = this.props.selectedDresses.pants ? '#273' : '#140F4B'
		let color3b = this.props.selectedDresses.pants ? '#293' : '#1B1464'

		let ledOn = this.state.lOn ? '#0d0' : '#070505'
		let led1 = this.state.l1 ? '#0d0' : '#070505'
		let led2 = this.state.l1 ? '#0d0' : '#070505'
		//let noteBookXY = (this.props.selectedDresses.hat && this.props.selectedDresses.shoes) ? 'M764.7,2694.8' : 'M864.7,2994.8'
		return (   

<svg version="1.1" 
	 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 992.1 1417.3"
	 enable-background="new 0 0 992.1 1417.3" >
<switch>
	<foreignObject requiredExtensions="&ns_ai;" x="0" y="0" width="1" height="1">
	
	</foreignObject>
	<g>
		<g id="Слой_9">
			<g>
				<defs>
					<rect id="SVGID_1_" width="994" height="1417.3"/>
				</defs>
				<clipPath id="SVGID_2_">
					<use href="#SVGID_1_"  overflow="visible"/>
				</clipPath>
				<rect clip-path="url(#SVGID_2_)" fill="#42403D" width="992.1" height="1417.3"/>
				<g opacity="0.6" clip-path="url(#SVGID_2_)">
					
						<image overflow="visible" width="990" height="1436" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA94AAAWcCAYAAADbLVx2AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAACoNhJREFUeNrsvcu2JDuOXWurRrZ1
G1JD+i411NHn3Y4a+sWkKs45O8IffAAgSDNzn7NGVmZstweNRiO5CBA4DgAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgPuhOxX2f/y3/688FV6q/u/Rv63n8Ru/
3eW3Ufv/oZTS/cZ6v8+ce/XfP/nZ7v7svXb9eO7zb3r6/fknPZ2vx2Hw9bMpjXvr+f7P19BjAY/n
y6t1+UOdex+Vb736bMfLs3X6iFEd/7m+6tc/nuuue/nHcvy8m6NWdy/Prnr9vz1bbyqj9zL+NBtJ
7dem9lRJR/vdtNpkr157z/ZasbLWa6N+/npvjd/+Kv/RKL/h+uWw1GtrDPvzXl7r6+86K8e//13+
Ouiv//vr739f9OeYv/7v3+X3ef8u//5949L5z+/j//3vh+v/80Tl9XOs9Ffl/f3/9TwP9VP+syx/
numf96jy0Jn88//0z1suP7//+rP++tNfb+flf/99yZ/rlN91rH+u8/7feqrf8X8/n1/7rdWen39/
br3vbcT2m3W+Uzuv1w+2jvf0n6NrRK8bEne6lbyb4v//P//3Vg/7rzsU8r//1/9SDgAAAAAAAID/
5H//r/9Z7iTA/wPRDQAAAAAAAHcW4AhvRDcAAAAAAAB8sfi+rPBGdAMAAAAAAMAniO9LCm9ENwAA
AAAAAHyK+P4PXg8AAAAAAADAFwlvrN0AAAAAAAAQ4apWbyzeAAAAAAAAAAhvAAAAAAAAAIQ3AAAA
AAAAwKlc0d0c4Q0AAAAAAACA8AYAAAAAAABAeAMAAAAAAAAAwhsAAAAAAAAA4Q0AAAAAAACA8AYA
AAAAAAAAhDcAAAAAAAAAwhsAAAAAAAAA4Q0AAAAAAAAACG8AAAAAAAAAhDcAAAAAAAAAwhsAAAAA
AAAAEN4AAAAAAAAACG8AAAAAAAAAhDcAAAAAAAAAILwBAAAAAAAAEN4AAAAAAAAACG+qAAAAAAAA
AADhDQAAAAAAAIDwBgAAAAAAAACENwAAAAAAAADCGwAAAAAAAADhDQAAAAAAAAAIbwAAAAAAAACE
NwAAAAAAAADCGwAAAAAAAAAQ3gAAAAAAAAAIbwAAAAAAAACENwAAAAAAAAAgvAEAAAAAAAAQ3gAA
AAAAAAAIbwAAAAAAAABAeAMAAAAAAAAgvAEAAAAAAAAQ3gAAAAAAAACA8AYAAAAAAABAeAMAAAAA
AAAAwhsAAAAAAAAA4Q0AAAAAAACA8AYAAAAAAAAAhDcAAAAAAAAAwhsAAAAAAAAA4Q0AAAAAAAAA
CG8AAAAAAAAAhDcAAAAAAAAAwhsAAAAAAAAAEN4AAAAAAAAACG8AAAAAAAAAhDcAAAAAAAAAILwB
AAAAAAAAEN4AAAAAAAAACG8AAAAAAAAAQHgDAAAAAAAAILwBAAAAAAAAAOENAAAAAAAAgPAGAAAA
AAAAQHgDAAAAAAAAAMIbAAAAAAAAAOENAAAAAAAAgPAGAAAAAAAAAIQ3AAAAAAAAAMIbAAAAAAAA
AOENAAAAAAAAAAhvAAAAAAAAAIQ3AAAAAAAAAMIbAAAAAAAAABDeAAAAAAAAAAhvAAAAAAAAAIQ3
AAAAAAAAACC8AQAAAAAAABDeAAAAAAAAAIDwBgAAAAAAAEB4AwAAAAAAACC8AQAAAAAAAADhDQAA
AAAAAIDwBgAAAAAAAEB4AwAAAAAAAADCGwAAAAAAAADhDQAAAAAAAIDwBgAAAAAAAACENwAAAAAA
AADCGwAAAAAAAADhDQAAAAAAAAAIbwAAAAAAAACENwAAAAAAAADCGwAAAAAAAAAQ3gAAAAAAAAAI
bwAAAAAAAABAeAMAAAAAAAAgvAEAAAAAAAAQ3gAAAAAAAACA8AYAAAAAAABAeAMAAAAAAAAgvAEA
AAAAAAAA4Q0AAAAAAACA8AYAAAAAAABAeAMAAAAAAAAAwhsAAAAAAAAA4Q0AAAAAAACA8AYAAAAA
AAAAhDcAAAAAAAAAwhsAAAAAAAAA4Q0AAAAAAAAACG8AAAAAAAAAhDcAAAAAAAAAILwBAAAAAAAA
EN4AAAAAAAAACG8AAAAAAAAAQHgDAAAAAAAAILwBAAAAAAAAEN4AAAAAAAAAgPAGAAAAAAAAQHgD
AAAAAAAAILwBAAAAAAAAAOENAAAAAAAAgPAGAAAAAAAAQHgDAAAAAAAAAMIbAAAAAAAAAOENAAAA
AAAAgPAGAAAAAAAAAIQ3AAAAAAAAAMIbAAAAAAAAABDeAAAAAAAAAAhvAAAAAAAAAIQ3AAAAAAAA
ACC8AQAAAAAAABDeAAAAAAAAAAhvAAAAAAAAAEB4AwAAAAAAACC8AQAAAAAAABDeAAAAAAAAAIDw
BgAAAAAAAEB4AwAAAAAAACC8AQAAAAAAAADhDQAAAAAAAIDwBgAAAAAAAEB4AwAAAAAAAADCGwAA
AAAAAADhDQAAAAAAAAAIbwAAAAAAAACENwAAAAAAAADCGwAAAAAAAAAQ3gAAAAAAAAAIbwAAAAAA
AACENwAAAAAAAAAgvAEAAAAAAAAQ3gAAAAAAAAAIbwAAAAAAAABAeAMAAAAAAAAgvAEAAAAAAAAQ
3gAAAAAAAACA8AYAAAAAAABAeAMAAAAAAAAgvAEAAAAAAAAA4Q0AAAAAAACA8AYAAAAAAAAAhDcA
AAAAAAAAwhsAAAAAAAAA4Q0AAAAAAAAACG8AAAAAAAAAhDcAAAAAAAAAwhsAAAAAAAAAEN4AAAAA
AAAACG8AAAAAAAAAhDcAAAAAAAAAILwBAAAAAAAAEN4AAAAAAAAACG8AAAAAAAAAQHgDAAAAAAAA
ILwBAAAAAAAAEN4AAAAAAAAAgPAGAAAAAAAAQHgDAAAAAAAAAMIbAAAAAAAAAOENAAAAAAAAgPAG
AAAAAAAAAIQ3AAAAAAAAAMIbAAAAAAAAAOENAAAAAAAAAAhvAACAcymlNP/e+w0A+B4AAOENAAAA
E6K7dQwiAwAAAOENAACAgJ44v3Yt698AAAAA4Q0AAID4Dorpnus5AAAAILwBAAC+QnDPCOPIuQhx
AAAAhDcAAAAgmAEAAADhDQAAkCegd4tqy/1+3NQR/AD7vjsAAIQ3AADASZPys4U5ggEAAADhDQAA
8PHie9e9ENkAAAAIbwAAgNuKZKuL99XEOIIcAAAA4Q0AAHAb8X3V8iOsAQAAEN4AAAC3Ftyec36O
3SWGrfdBnMO3fr+fcG8AQHgDAAAwiT9pgo4QAL5bAACENwAAwO0n8Z80yUewAAAAILwBAABOEZ4I
UgAAAEB4AwAAXEio80wAAACA8AYAAHAKzm37sjt/L85zAPieAQAQ3gAAAEzmjaK7dUy54XMCAAAA
whsAABDU3WN//uMV0MYb1IX2r/v9pCIbnAMAAAD35l9UAQAAgEE//+d/5Dz+eBHzT6L78X9L9d8y
y/9wXUm8UAAAgI1g8QYAADAK6Jgk1rMFuyasG39bZfUeWfgBvvqb59sAAIQ3AADASZNmj/v67+Nl
v8aD6/nOukJkAAAAILwBAADOE/G//59+i+MSuopiotp4v3L0o6R7BLhlzzvAlu+PNggACG8AAIBv
m6jrWZB3xfqPtXu9cMiMiv5afwhwQHwDACC8AQAANqna8kd8h/Z7T4iHjtX7LUL6wsBsiHBA8AMA
ILwBAICJcqrsLb2/NMT3cyTzTTnCX597cSoyBAnQrwAAILwBAADSxPf7mQ8B03bOx3u5wE8UJIgS
+FzxS8o9AEB4AwAA+GRzyT7nj/h+sm+XjXu6BxHSd1m9EeAAAAAIbwAA+AZxbRF+5QhGJB//rex5
yEtYunvvABEOp37juXek0gEA4Q0AABCdPxfL4cVx0R9BsEsYWO+z2OptEeEAdxbftGEAQHgDAABE
BF6xiW+3or+a6AYAAACENwAAwFbB/SSSB0cVw4FPZ/wEYdIVK+pUh1ks33BvCLAGAAhvAABAcFt0
p1t8M0FHgAMAACC8AQAA4eY72/6Ly9r9emy5auVdxkMdAQ4363moAgBI419UAQAAXFlwR6fL9VP/
uIb/+l1asN8bzO9VwlMA8r9/AACENwAAwOkT7od92Y9btD9aRP+yNP950Kvo3cf3jAgHAACENwAA
wJ0E99BlfOV+7zvU87UE+OO7F/vlAQAA4Q0AAHBt0V1OPPviNfzwv3V9AY4FHA7czQHgcyC4GgAA
fIHQ/DaRbamb8iBu/ojwn/99tt4hEBuCm3YHAAhvAACAC06yi1tPf/sE+12AP7+XawhwAACAu4Or
OQAAfKCwKouO/WQB/gu9iHG9CfIzPMB/tRFcz2FdfwEAsB4s3gAA8DnSkTl5kgB//Hd5ET7nCS7c
gBHdAAAIbwAAACbSHyzI2/vCzxLhQF8BAIDwBgAA2DmRHqYQu5fMvXLpHl/dVQQ4YvyT+4pz+yna
FQBkwB5vAABAqF6ovOV2T3H+PvBHYUQ6sk/9rnWwlwQA7gwWbwAA2D+hXmZBuv7EvBj+5pUY747g
O5+ivg+cdGSQhr725gCA8AYAALiQ6L6JviqdqfyPfNXko5dTn65c7mXgjv4pepv3BgAIbwAAgFME
7B3Ud2mI7tIR4j8CvBivrYf/XOepyyUs348CvCbG4bYf/DfcHAAQ3gAAAHPiJy7nHq93HWFnmbKr
8vdX8ex9HA3+fY33fs33hAD/7P4CAADhDQAATKJnROxmITdzqx8xrYbQbl3fYvV+tZ5fT6a8piC7
ptBFgAMAAMIbAACgruOSpPEa8V3br10M4tlbjta/y4Vf3JUt4MC7AABAeAMAwJdOws9VkpoQsy3L
teWRXs+9TiC1PHGFtRnGorsgygEA4Q0AAGARV1HB3T91nyL37J22BkeLiGfLIoDn/ie1iqqYuoKg
YiHgbqL8HtcGAEB4AwAAgtsojqPBz1ou5jLeu7VPnHaDAP8OyKUNAPfnX1QBwL0mpBITELh+O42f
a5XAJzzX4bc2W77WaN5uGcS+pxxXbUdn93lXKcf39if7v3MAgBVg8Qb4YBEDsLutrhPd527yVkV8
RwR79IleXcf1Ze3qau0ba/j22j9FhAMAILwBAOBSYmSdCLlOkiw1/ndLIK/a4205rzQEO+I7tywI
8M0f3p4XTJ0DAMIb4FuEzF0moIDgXjfXvVZW6pk93kfSk5Tk6921zZEHHAAAEN4AsExsM6mDTxU+
z5e7luC2CN+adflKVubyUrPlg9rizDs8oy+H6Q7itLrm3QIAwhvgA4W25TjEONx9wlm2y6Icwa0T
BN2KZ/o0AT7qE3flQKdfXvkdUrcAgPAGgITJ2OMEkokdXE3UrLnHPeumtt/7itbubxLgtXbbe28r
n58+Okto194aAMA9IZ0YwEUmjEzU4GoCZvmkutSn2dec/D//TQNBd1eRoxcxqg9oyz9pwGrvrFSe
f9X9Ye7708ltifcIALNg8QY4ScBkWbUR7HBL0T2Ut/cRBJ8UQbzmjv0JEdFb7uh6EXRYv6/YD1F/
AIDwBoCAmGYSBlcUJdvb5Y0+AznE98e2kw8Q4WemAaPfj9XX06JIObM+sXYDAMIb4NJCe9ckAUEP
d2kzZ7mYr0oB9uh2/gn5sqP1Uj7oO2Df92VeztY65f0AAMIb4IZimyBpgOC2iLNy8v3HWMX0N9rD
XgXqra3iv76JTQIcgtpbZ7d2AACEN8ClBPlKUYNOh4w2erpau8EtS+DYb/o8R272dxbgKyOfv+41
Z/F1fhykDgEA4Q3wJWJltVWbOQXMtt/LTPAvEMW8BI5vGdp0+tPA7rZTEsR42exC/VEvAAAA4Q3w
fWKbiRHcQXBfTbCcNYP2iGRPailCLnnf/U36/863U5Kf78ygb4C2BwCEN8DlhLbluJyyzP0OtOXr
Cu5ziVioe3u8P/ZTLJ/fFsydsTWA5mTfXA3yRmePFAYAhDfAN4htz8RntTv5n/kfwdrgfbJ+tf2i
byLr6Q/nWLuLo+yv52hSFvRc1S9nMY8m8i62375BgK8ap76hf7c948KFcMZQAEB4A+wf+JcGSXPN
AcrsXBA+VHBfUbOV4R/OLZ8Sv9UsV/TLiW91Xm4xNABDtLXbC/BaB6x14nun19WFXwCdPwAgvAHu
JKhX/T41ZdCj0MbdHNa1w1Viuy+4zy+jGrqyND7Hn3M0EMdW0awrVEKkoDWzfDGc99gGNBbg5V4f
YV2Mb5KI3yy+C3UKAAhvgPuK7bMH3HexbQ39BN/Ujq9m5a6KpQspqJ6gbmnBUdHl/BJvG3itVXnq
i+jqMS0BX6nwW+fNruUBX+iV9OlCsVz2NTP+AgDCG8A1KG4Jkub5/ac8ev/TaErKPODzBffVxHYZ
/vFaivx1j3cxHKtjbNjVwqfSthc46Lhqx9cqtDSEdkucd8p0ewG+qY++YnyH1eO4EMQAgPAGuLbY
tu6PW+pO/jihfLxP15fVsqGSCQiCe73grqqJYm7xp/MoqI9jLiia5RhNXFu7XmpxFKDERHTzfi0X
9k8Q4I8LqVorvun7AQAQ3gCnTy4uFSTNOvP6fYzP3Zzcr9/Xvndrs/Ioti8uuEvj37U1rpr2G1my
Z4WxJv6WetOWID4MAllGoS6jAB/sA7e870sJ9criKuJ7wbe98XkZYwFgln9RBfCJwkU6d3fl4+R9
lIpI1ohq8LHt9RbWbamh5KxO3Hvp7efW4HeL6O2J86g1W1d48a/u4epUUpkouCdXm2x96ZFQrCXi
e/N49NqfnD0eRuuuGNc1AADuAhZvuI04yRIvmSKnNxedD03usac/m4xYmUdwp4ju1/RJXfNkueYz
DATzaPty9mLAjnOG3YQnMbl1j3f0hVhd2DstTZ3TyjU+9rf/aXYe2TB+3mWcP//5bhsuEQAuAhZv
uOwAbFmlv8KE4skSU7EMvv3+1/jNAP7NbfvyYnsoVy2K6roCvKcbR7r0Y6bmcr7G0lC6u5rCi8nb
sn2gZphvecDvfICqA0nlM8wcJn5vvXp4WbrzOKSDBB8AcDuweMOtBMmuIGnpIiqpPKX7C7OQK7ft
e6QD68XxfpQt12xrHhfjVkzDNU+mE88evL7ISoNlj3d64zyGEdCNhvMTe8xSrStDWvCl49etoqOX
deN+STgCAADhDbcV2h6xva/chkmxdd92WTfU425+HcF9tXnrWyq74Wz/fm1JzmMtXtheEb9GlS5q
CKqIaXkb03nlL85HO/URGuL79RM8yw39Mn1WKVNjJwAAwhsQ2x8oHItHbF+kfuG7BPfREtzms+7R
trweqMV8VU0K/ZOt3qMt+jONqZzcqEtbjLea+CjY+noxafMeKU/7wz8/gvfwvlpYbsZPAEB4A4Ll
3InB+22KrxyT5ZxxhUWI758wXs7CXV6m9zt9Wc9aVKh+J+oKdZvw0uTXm7/IYP5tlCasHFfwxc5p
AK8CvDzr3a4h9ZxBpVuSx3Fxt/jetYhYuttcAAAQ3gBpwu+KYmUoZsq+6cHveeSwmpionNHGryi6
q/r6wxdiHlP4edy+5TpHySWW+Vue3ladtcf7VmK87o9erpX0u71qUHnrZ/QzO714inFwLonPBgCA
8IaPFdvXC5LWnwac5UXec5UknNp1BPe1yvXaZsrHWrl9AvfKZdKe8j6uMGTs8b6VAu/5o/v71vz+
t3bFUnkZ54nv/fe9ZnoxhDoAILzh0kLbI7Z3CpTRgN8+5nmStPqZetF5RxMVJgmfK7gf46JVBfcH
L820gl7vyTKkSYGsib+NNbXrhI9oJqXT5o1xzR0CfK9HfmkI8fOikWff9/UaSrhG7ncOAIDwhhuI
7fsIv/ImyN9FzLFlhvrmZOjc5I3QXjvRvJLgbregzxbco92gedNlyy5qJV9T06WU9+SaAL9cMyqO
33vm/MHm9mJrc1kx6vytvp007zus4Hh6AQDCG6AqVK40aJfBX+rFKIPfN0wkBp7CTEKu0Y53iu73
Cfm182vvmnDXrd1Xslop/RxlFm2Lq/lMOx0tu4zkcG95xhBh/Hj2qiiHxxtpz5fwCeLbfK2SVw+s
VwPADv5FFYB1IJRkOu5qE3UZBtvnR1tv3R6VyzQZaBo+cI+70iQyX2zvm97fTZTnakUlnM97yl20
KJ0OsCaqa1biMni/v1Y51T2kDMaQcuzohdt3eXPd1p4xIXJfPLQA4JvA4g1TA+LlgqQZ1cvIuh24
+lzZHo8plWMHZpU3L1EmM6H2fjULd93TgXfb0kQ2a/cap3SY6RmtVvDa0koncFp3n06xF834rf46
Vhf8Ss+0gke3pRVH69kwewAAQHjDOYPz5YKkGcR224b9GiTtpPKXxnPotZhltkZgcnJ4juD+Dldy
q+CW4+922e75vXX8bNC1nNLfd1mh18Ytu6wDitlSjt7lXopRSqXbvlA/9wnB2PZWqmpTiqVzLABA
eMOXi+27Ce2+2N4zcg/3bRsFeX8iWmyGGwb8Wwju94kdYrs1BS6pV8s4VFueXDvur9VvcXSDXjzx
4ijsbCvpCPCOcf1RfB8X/oIfF9F3CvLefUrwLUXHOcZGAEB4wy3FyWkDWDEM3OZE3OsTwxTL7UvO
u4PrC+56OjAEd0SSXUN1nmFnTpTjCujkLW/bHwxtWasrlQ/4pf5KWdU+14jvM8aO8H209vrlSs8K
AAhvuLfouP0g0U33oqHY3vVkXYuHemLbujjwMCNGp91WcO+Z9n2O6O7pw6UfspI6A6uoleciG1Sy
si7fSvVlsVy39nifsRDwKsYf/q2xXv+UucKycdMR2fzRH2RXscOiHgEOgPAGxPbVgqRNaPDL5AWx
WLeL+eE8ex8Z5O8luL8nHVim/lsw1U891CWslV325xtrxUvY/nbL8pYwOfI8jz938DU39p273M8z
qv/Udw8AgPBGaM+K7auIle7Q9xJ8rHgudsaQnHLgu1BjSnDChNEhttm/naf3iutoq+DTkXrZWaGt
JWp9j042n1RbdGpduHXsxQeshwZbOk9yhz513/xFgXPKZesOAADh/YVi+65Cu696PMF29oqup8yz
3WIW55VL5S7l9u/9LmLbKpvb6cCYiM3Wu3tXc6RbWNGV6KT7rnwOrfqqRhbwK2+JGovvlDHwK0Vk
WVp3JBkEAIQ3pIiSKw+spViPKTbFc5LYfjymjI4oe949bXud4JZJcONKHv2WXo+fcjA+e0atwG/6
xLc+2uPtsWqXGz3reLgqFxbdr31uVt9bv0Zx9B2L/Pp1LHk2LOAACG+4odi4u+gyG7UbAcnL9JXX
PM+rhVutcg0Da5Xkmvw8sb3but2zxdXb6b3F9tVKLvMROl+8Ri3sqanLrqjaRxvf9THfT/VLavia
vy3W6foC/Lx5hj3Anrdsby0O8zcAILwR26Njriq4i2MsfRcwnuFy3WjZHfLL4JmrxczMIfYdgntn
ex2FdPq7TK9t9r6CwWqbtx5zelNd6S5+yqT805SAaVnrA+qg2B9HfzqTUlpj4rW6/hTLd+JX4IrV
Vs57ZgBAeMONhHZUbF9WmJcHd3LTKH2edbt4T9iUBqd86Ldwtjt5XXDXPBQ+x8KtRaL8I9poT3yb
U4HtK6h21UfoxJ//ePZ4f8IX9jDwPVXBs7vUozX86uLb641UdcUeNrCZJb/+vTNbXK8OEO0ACG+4
kdi+S8ftcicvniuWpDsvEtzNYt44seuHC26zqKyW6b6i+/W5WztuLRNUr99JzgS3E5H8EppNl7jE
tked3oQvQ0v6wI7T4Wte+7Mu+Ui+/tvzDCX1m2EgBgCEN0J7ciA7LUia9ZhajLTfkzY9TDCuJ2p+
F7M8P0+7mLtcjz8jsvn1opOXy+UDX/HcPSFdjn4YLE8s6kuJWtOlB2pS/cNaNmf983/rn005ujjU
SWYK8E/58l6/HHXefT3ux0h8Xymco9cSrpPfTkbsVizaAIDw/iKBfjWh3RXbzZG2XO75FClW6c1G
97ibI7iDSyH1fGAfJbhfxZg1U7Jp//vgWzqlZqfSYMn5k0xpz9QVXsayOlX1PZy41Wgp+oCv8DB8
Pap8beWte/r7P1cLN+rv52f6/ncnpNK9bu9eGn67AAAI748S0VcPkrZCmD/PL0r2lVPLbEwSkz5R
LMep1bJ0EobgPv9bLB15qKNv3fbEoo59DUq5ikl86108K7X0Mh4h20UVV9XLBbguc5GbfJnxsIWP
fWhvQe1KlvBqDZT8Wp1raVr+/ACA8IbNnetdg6RpUj+Xo3XwvslWGfw49CLPlX1Lr3SVwf4KEcqr
YvtDLdwl+LuniXuzL8vcw8gvFT0bzJMu6ZnEq/Os8iwYJHWTwVq+gFD9tC/V+pz18dIVzfu1D7hT
93eRcpKnGwAQ3jcS25/WSfuM1cVierzm2G5Svfku5anBZxDcz4L7Q6bqribqnJxbhXvPAm53Ma9J
P63/egYpwDWletuiW06J/t1QJ+9fX3kS3y1PIkuYunt2h+P4JhbvqmJqZwhrAEB430Zoe8XH1YW5
2THOLLbPHdzehIaOifhuZVn52hapcR3vblNnuZMjuPfLoZ7L+atFtZgF91mCTYbfWrZrha6IpIR0
8Wnt5x4a4M6u8cxeOPs5I3NBAEB4I6AXdZif0skWz1x1yhVb255hKkHZvA96uN6tzohntL0rpgO7
uzt5mXn2hWLbuse7dCVrot/0iuuaJHee7G+eo8jJSPrvEt96V9I//fGoD3zJH76lu/zZaucYO4vx
mqldyYVEPgAgvD9ebEev/emrmhrp594wZvZPP3EeI8/Br89bpm9fq8ErJ9q5XLC0mwvuctwjsZJn
r/juZ5H7IIXt3dqW5uy2t/iYUl2/xyhjJfgcHr1a3Su7z6w3GytfSbgvihoAEN6nCfS7BkmbHZqK
TwUZr1y2PkP34HLN91HPdrvf3XynO7lLiH6oS/mdFgV0vEdH32ftfv1JsWuu2F6uwW8uIS/TYsLo
YawB0pX0Tva+CKgqaoeCTXfNnmgqClx/ZV9bNtYbACC8v1ps30E8rxTbZTQ/Km65tOUZXktTplW7
KgsLSi/zsgWHCcF9KSF68wjldxDckcnxchfz6v0VuJ+cLt6q380orOcfP+rELvfRmimislsWInzN
yD4W35ldbGz8KGvHO/W/rehz19c5UOUACG/Edrro+ASrdk9syzX/07WfVZUDZb3qmr3draKUjJd6
dcFdHHu3bz6J+fRESUufz5USLBbl3JbrK0lom6zeepCh9WWG/v38Alaz70ibXzpMfrnjiOFZb695
KVlEbFnTLA1NzFYHCGwAhDeERMQ3rlDGYqTV5OJU9LXQoO6a9E8phOKVxguH6ZLejrdHJ7fo6AuL
bWvE/7vs4Z5thUtdzKvffz+yuJJ2aLuCrSnpmIeSqvL32FP4MnlfywIOZ/Zme8YEbek3VswvAQAQ
3sfafdt3Q1b93JtgXyhV2H79HI11nBxhfcEEYHvu7VctrcYBHyC4v4FpN+VN/d3y6ynwW8PqrQtl
+danNQ5wjj56Gies40VJmFOVyuBRXseKaJ+t1fWGOAdAeCO4p8T2V6QLc6lbZUnhqeeQ54Hj+vmo
+6if/CYngqxdQnCrd8D1vple8asTvA+fb2nHDSzCzehHmpaBa/226zu/Nbj9DOHVT0fp40fptscS
uM7+WdLfj/88D0JjAyC84UMFdGQo7c3Dils/nxdMLcWxPXSw/3lrcj0v3Ix/Qn2GO/mdBffrm2/l
tS7eT8OSn6vcuW+ZFHhy/qhZ9T7oXD5CyFrjmi8uQihsOqbz877wdv23LOH1sbm43vnM1pXXIYWW
AwAIb0T5NqGad3C533OmGebNNvac2wWev9V+L7F/+6aCu6XFqoKzFynvwwT3T1FPl0MK/RQX32lm
89at5T/ulMjpZ71kBPiVZxPWcSb8Bl/dzWdak+zC/U5zIgBAeCOgLzZ1cY8bxX3VpeV3C9opoT2a
iWfL9bDsPv1baGrpmwru2ptvWrdHfujHYfNVv5G+0JHUL8h3uhy3sWW31mQdqCqMZdvc7RbdF1rb
oFRfJb7jW/N8IRD+vlcxDuSWv2aPOL0hjLRiAAhvBPeXDIvmY0p0DjPc1Xr6M+acFL9gilwPV6u2
fwPmWGgXFdyWqOPVhZ6WSm/5oVt91W9v0JPvULcxU4uKqP6xb2WTaeFB3lUF12PIs8LgfBneBYyp
ywPzldjHWxlT9o2BZceEAwAQ3ojtO4rtknGxE8R2ynCmrLufkD7spbovE9rNpKP/KfxJ32iGR/fb
m29Zt2W4+OgYnf6Z7dPmIT06cK+WvyA6DPm/5eleZDzC4SruVb2LFTIaGiLjxfgjelnlasUPTdi/
rZXPAgAIb4T2dwry5rw+FJXMoibWCqf9wcjjwdSm5HpjZjuehJTlU2KX4D6x/ZfMkllfZM8KLqNw
9xTwcl2hQu16/g5OEZvQi/XKoc7Du52LBvHQNNrkflUTNer96+YjObOGWVu5lt+HuSsAwhs+lHBU
79a+1NCMabNr87aTzpgaVC6ksa4oiwd4e2rtawludZq7+4LF8S1FG4Z1j/cFrOHuiMPLA4DJLerU
nZjHpLjVEN+1eiurzhr14RK9GiwlLH6t8KVSvZjbRi/HeBm0XIUbYd+fqjVW7k7jCQD7+BdV8D1D
1HDo+EfA/fVf4T5f22f7U+nCXBV08sKA8yJ9u3beOyoXTCtneWLNlqxsbBAet44LzNd0uXvL3mWZ
I5Kr+5Wp0RfqovWWX/vlw55t/hPXwuM/ed7STwN48rhS0f5//U0Z1y4PfQkAILyv1kn/dFKNHm/0
+0cPXJmqVpYpwl4l+3o3WU/S7MywhKZJKbWidgUU0zROk9/bbnWaL7hDxT/7kdJS311McGtBYcqa
ez9K7rL4EU+RFik31brGqcvXoKs3LMeZ/mFXWpQojveoQWWVi77tmbktogXg7nykq/lPJ/X6398s
tt2BgMPmwP0u5WV15S1UDCtryzLd9H4bd3Mnr3llm0r2elA5+ZFm9nhrb9H6R61S7OO90Qpc13I9
uc/cKDwjacgUGTzib23vB7T/ztZXVuuvvioedok08zIcr3Lem729eO/pOR4XdACEN1wcRQVfybjL
fl0yNTnZKLpTNFxpCETz83nyrXrE9nnRyacCpp0trrPb6WUCU+nka8s+idaiuoxctyWadVI/HM4V
diUznU4tVytDoAbHvf7vsqBLme4/t/cQyWk8Tun3Yzdt7VUHgOvDHu8vEdvmPd5XmyetntKFfNRz
Bk8trgSb8/vfOb3b2zMuO2PJu/snie1aeyif/RFb0m3Xg7z13Vqb1y29H1TPm71cA9r2sD+Wrcw0
jDO8t3Xc2tw76o9VEdc9D52sPeArr51RL/3GMGgU2tHwfglgvbiB72uof4vv8lAW41Phtw6A8Ib8
gaxsu+O5it1dgsgGzcRnT9/j3fnba3uw3Pvqgrtc5iIXFq8fZwxRRRyXwTelqjWxeL5Cc2iEgaxS
8XcXL6K5dJIuPf5/i6iW41izWjQ8yNTYpPu27V4Mv96Yrc4oY425aBX9x4vY12BhIGs8qwUja3kC
tNa90o2/ijzHfRrma1kR4gB7+HhX829xxSlZc27d77nP3eMd90FNK3ux/tS3bfztujaawFzDnfxa
jebiH8Xt51POPFeBFGEjsW69nimius873lTS2pKEjHnL346NjBWKvMfkZnGTluzplorzUYvx+uXo
rwF5UyyWlDoptnrzWJZPmf8V9/FXmKbiug6A8IazxoiN4jOryKESnKjYU3wEejM155x4POBeK/d2
qGifLLh70eQ2yeGQgpT9DpFcupouv0euG85XpxRqXLGx11sT2YUtAjwsyIPtSBkN0X2h88atlqjt
WbVL4D69pywNUd4qR+m8t3UGZ8NejTL5Xsox9iCTRePf32rM/nGAteBq/iGkJYqa6mv3Zx6d9hZP
Ke7F9ngPXsP7z6VTmxfdv112tekP6AzKukuvad0yXan67SvSWTz/0E/j7ehx/jpUnbP0IFr+OUL7
BeF5nbGaf5mOc+m60D7/9agjSpm4z1ERyKPkCOWIua0XZy9QPA/XC7rQaXu1PNuhCdXQE+zzXLW/
MQUvwGo+xuLN6tzzwGc27hn3CV9d3biNmanWwFeTy6apnmwzoGJ6TZqt0T3vswxmla/HfUu3UDMb
lrz5oFZ8WIOoaOp0T4reQJ4K7Kg6x+ducT9Xbme8dYnFVY27u2edctepoqzoskqgTEO3dUNqyZ/j
IiUsO2sqLXBf/CJXnsJiBQfIA4v3B4nt6YukRdbdF372pNho13lvtXen6Otep1TL7HGWfcth83j5
zA4hqU2PNebk/mDH1TT7EBNz7rfjeoGVNVpSGBdUXcEkcwC18ic6W3A1NrhM2DzNfr1zYqmtG78e
r9zqzpTdD/xz0af7/jQJ+a4xGlA0EJU/95ayarqMG4jTSWVjc8iegG0T4b+rBks4AML723EN4CVz
DNDWZ5weptIHUp/6TdvjnTasnye4u7lpLb6LZfbOHyDKPyKYWnBHcWNl4Pd8OTHY2nNs7hK4rlVY
v/yrkRKseVKZfRN11/grjGt3ZYm0mgxr/juauKefVaO99fZJl7oQ11SMxERFXFlA02Ff4LqbeEaE
A5wHwdU+SGxPbe08ca/zqRWX6tOowRtZNJmUU8lueE1WA7UrYFptoleO0R+Ptg92uHQf3pbPUFFK
vlaC2/ZQ36reCRv2aWvib6YLNgKyWdqJsmtTd2y8a8pTDE9dVt2g1T2WgciudZ+Nd/zb9bwX6FGv
ArzMN6OuNR736N0iHADaYPH+tM4vdUKUtRxQ0p9xzeT/4u8r+XGz710c9w2/3JJ5Zzmuw6QipW60
+ZOsBUdTWpGPF1O06bywK70OX8jq5NfnzRO+slDurdsXyK+5xGO5FdtC0ZuX9gDRi8imox+bMxD9
9fftHRVXonOGX/f49/mzgVo+8zuKb6zfAG2weN9YYL/+77SV8qlZwLlpxUIXKee/w2UXKqtvbrQx
F0PANJfo7plVrIlwPE8A/Tqeu6Y5DfRw8t4KU+bMpf1qtX555HHc9fr95Oky1X+i9z+3n1GjFQ9F
3ph8zWZyLFFGs83X02GdbE0nFl63eL1B5NNtdbNHR2BrcJ2QZXTwkZTMl7MkysR3zU8JxAbQBIv3
t6v35YFD8i+eYoMsx7v725J6aO+WnLY4Oyri+V45tm7TtsCS3TRmwrQp8gSntPFrCu5y3q1XzH0j
HUnFwF1Gc3hjoHRTdcsoujfVszwW8FDH/XxSqv/JZg21bY93y/Ksye+8lLG4NoduH2/yVqAfCvVS
pXVvXaUHvLUA/11/WMEB/gKL942nwekX1G1Ke9MinVsPJUEymo3Tr2lmRgZm851TSnfY3M5LQpk2
NCmtbrGab7+dcspzzkSgNLkFp/64unaOf6+jhgV8ZHHt/O1NCKinxrW9y5E3jVxC8M47+qSk7/HW
y396UTtDKRYrJxXD4d0bRaMAdhZGZX8Sb10rGvQxLFY/V4RjBQfA4n3vjuxIsphu2ev9+O+UBCK3
XiYpGe9wKtjqL2Us882tYrv56GGNWpK/GO/vNRtbuWKTuu6lvRuXw6JsHNb87z9l3HO8TDFQ6vHK
N1m6ExJpl9mXXWyHlst+Cvcj7EpVfDOLvw6xpFKY2OQdaJ+ejJKle62zWtXfdf8ToO4TjcTsA4dv
5z8+6UP+NtH9raVdtl6weQ+gNl9Igbdj0sujYDuhC+y2JlsjoSO6r/Vstn3Act9IlStr/uHim7wr
sr5eQjUvE+wojI8etkmHwgZocyPPt6+n7PFu7eV2W7V7gtkS1tzTf5bELsK3xzvevZzTM756jn3S
TBcLOHwrWLxvjHbcoFyztLr5W1NG5S6shBI5SLMXOHtqEd3jvWH330eK7T3x/PXymp5zcRtEp+Fd
Vw2Nf/3Rat5+va5cNagdNWv4vrX0S17QZ7ov+Wim1+7S27rUaVf+xxVTT2Lw0mmJ1nDnhoZebFUw
7MfLsoa6RID/9fj6PBHOPnD4NtjjjejePGN7/NvczcqtKlrryh9OtVLetu4N7RG9g8wGjXID0W15
kG+IfK6l1/XFB3PIS5sReSzWW/etKF6fB7kqLWi4ybtq7/ZEhNfD/61uFjoGEdlDn5aOnv9CWHNq
z3dhOWtqj3eoG9VggcES1rw2CFh2tO9UeJXsGmX9O08d0Mv7lq5ajRfDfy41z6qIcIBPBYv3jXGu
GZ+sbH9d7D/SSvw6pKca5rfsfd87lE+/6mkPwYu4aac0eK38UD5LbFs/Tnl+VFN0Nc+sJARW1rPV
nlFj0V3/ixZUbOkuL0zl5M5Oc++6Xj3i+YpiZfUu27zU3DcrlUadkVZrfpbiHd+V8f2+dizljNbS
E6j/FGtiH/irxu1dp0TqN0F8YwEHhPcVp8qskK0dG3TNp9BHvLNHp1fFJ8CzlTHyKNwiZq8uunXj
ZzvhgzcIaYUuYtjT3dXpGvY7Pf+cYp61x5/6vVR6+YMhPHzxvKqgADfGxPh57rLsexmnpCoX7GWU
fcF6Qw0MAKMIbZElhIQnl+lj6bzvf1YkRvvh9fxtPLXb7SLcsHBR6qJ82PJfRG0rw9vrI+8W4Yhv
QHgjWz9tityeZd5Xwd+meZRdNdO8UeOHrxHcWbPdOz+vNl7K4mKuBR+u/AWv7NFuiu+qofDvaXuk
W5H1yQabvJtR3DsCvPXv+beheHvasbV7sUq/7h7v3mJUpvje00MV74G36LrLhmvqSYD/1XOov1y4
Q4QTBR0+DfZ4f5JAXNXfLx1jNVW017UC3b716fx3XaID/4Uif2+pnA0tbuEt0i6t7IvZxVPoIEd2
r9+/6xjvD3d45qryEmrrD6oo6bdAy9bIy6u7Fp15bf8ub6V9GLF7pkQ1T9njfRzvO4SvORdqysBi
fRuK6dnLVIV3fPU05vdreyKPrx718W4FhDdcphteMpk+rY+zz+K3Cu1FqcYeAx29/vfWVlRmBvpP
FdvWllfW3+Iel14yh1Xl89PsfTToddS4gjxfdr2w6vQp8XczkhreCNyaa1yy3SO3r7O1El2mZS8s
Qlo6sev2wKsXeq69iL9ykbsuwN9ym50wEyAFGSC84TqD0LEwILnBarR+0rI5yfaHUlxHjhzJvklw
t/LZLnAH2ZY6bKcEV13OyVc6ud5V6yynyrUFNTfN5FVV37KdY8pSPngoWQ5RjkBWa+1iT1T1Vpsy
X0ZrC9d6FW7NXMvjPfWyrhiazj756Y9KE2PWZacdmTHMTep3KMINGn1agAMgvM/obqY+PhkV5j3G
nfDA7bnwTXKYfZ4s35nGZORO/cnu5N56SuwzFu+VeE9bpeyLTrbfidIZ664pwapqbGwnfp7zq7PA
4DB5//6n9WHUr5See7yzon/k8qf1r91nCj+wzuuajiNpj/d9xz4l3lvB5b/vGAr7ItxoKEd8A8L7
u7jyoPPtuvBi70Y7hZJ8lW0pS/H82BLc3y62y3HvaAIJlkbX4/tybc+nU9aLdtZAfFut35aHNvze
E8xS8xxZr7F0ASV4ynANQQvczpPHjIvl+o7386P+LazeN/RcMlWYgjWs2MXgUWV3vpcVAvzH9RwR
DghvVOXpJdZHVc/IsVP7iqf7t8Diaj3fLrjDivOzegXN3e/t7tr7Ufn2hKpzjmNa37xpbzFC8cS8
tgLZm4haz5/uO/EmwvM7wldXai26zEX6h4/a4+1/X4xYJ4rvUmYPCYtwAIT3pQRzhvX0ni5Hyr6Y
rvD4Gk+ab9+CIyGlMmduDGTg/d7f26kmv2hv9ztK4+3f1zu/ybu1T9seRtJmb98QXL4pwDMHoaYA
n37Atnt/phV/uyZNqZc77PEeRz0bl7oythm8Mj5lXvHJAhzxDQjv00ahM0bNa3XJ5ezXcLGLadfz
LjaxZw3/S4LPwEV7xR15pBwzf6+L+dASW7l/Z9/2YVgacG1dVyuzl/1rVbBf6KXxfjpCj/82BGAz
D6vO954lwFNE+NbL7hmz0k66t8RU5vtHbU/ONAbh7n7vA8+Zdzy6nyPEAeF9K+U3a93WR9TC9M22
OQm0LW26zBu5/2QQ7iW490WPNp7ucc01hTXv7+F+9/ZuuUn3Bem4dxkvHngDnKmRbqwpukP77+V7
mc1THG0tKMBN1wtF5rvejMVcqrSo5hfe4+3ceaCs967ndsf4myXC+6I5/a6Ib0B430mnZ0k2nf5I
d9+h6pvBXSD1mC51Gbhl/3OS4E7oLDynK0GYjetT/tNNwdleVLWOd2u3Q1iHm8TIv777aoPR1Tpt
T0Zhc0Iab3NBctYv7AWJp8970TbuEy+8x1u9TsL3RGt6L8gT4PotlLOt1YhvQHif1mu7c844JO3M
DGFd9zYbh/ezxq6qPSz/eWWZDM8+xXwAojsNReXUNqPLXzotMrTmHsD3SBGfc2dhE7xo9fJgCtXd
s8lbxkeX6ud3VgoS355xvByIcHNBnKnNLtee1yQF788Wanu80zrLi40QxpdR7vhsCPBqC0d8A8Ib
AjO6+7mopw+MuoIwJzjKpwzNkWM+VXCnlkexg02nT22c7AvCp/vLIRh7JVFkYaDS19Ss5havaXca
bw20vo70rdKbHYu07aPRXB2due/8ynHRNs3C7K+gBG7EDGLdKF+a4nvF/m8AhPdHDgfnuqhryVUX
Vstnhl9f+KyBKfMFx5uacH79mwZD9QkK9DKXvnyDtcbxMvmc2yOmt3dnvufuHmxffjs41rOoIrp7
pnS9CHU10njnZD94Xc7Iaq4hTwy11hKC19olgK8w5Gj2yb5BXI49xrSyIYF7gpItlhHfgPA+u99d
vqSd7aK+Rhd824tPc9Gdfe0nzSXPHHqsgnuUVTyn7TKp2qcmRpbkmTcq85ff1+UaTr6fa6Xyr6Hp
Wp3FALfJu/5VaBwAPm2sMqRk0oTr+pQI1+G00Cf0Kmfm+J4OMLe/PyyJj1ZSq1JnVw3i+0Es434O
CO/bKeyzx5wsF3WNJ3GT0+kLZgVLXGTYvPtdF6mr4LUzh6eWpdojuD8zWGBsUhheNNoRQE1j4RoT
zoZo3qbgaDI8TCePd+pH3v5b3WW8/jQZQcH6sd0NvvLa+w2s6wtXRIC7Yq913ajm3oD1bjfz3neP
yL6mNE8W3z/Xww0dEN4zo+hC48w61bTK1evC9sBbhF+fjJLmWdnQhrZf7KI6a4+11TXcKri990dw
R+f+QXHnSRXtNILqGBt86xrcF2fdZbDu633HRx7r/EZBzYcBGTXukt0d1bAN5FrAzx/M9hQiv/jX
iGqujTMiV+dx3UaE+F50PcQ3ILwzJpQr4p5tEeUZLuqNSeWVBhCPAVprblbP2al9z+50PQi1hGI/
rAyKkB34zOJSXhPy32b51qL91j15vSo1tEXk24tvsKFLScGn9ft6viezR81+tXIv90waBDXPSkW2
Yp+2fo9rGuf8Tqq8+QWo+CqzFvQq7wPR2eLD/rK0bMngewX27IL8LvGNlRoQ3ncTcbfpn1dFUe9P
6XVcZQfYGQ1I1aWKLTWx2t28tAdTi8v3Kiv06LxXsa2rV3ZSIadjEbjDIre+hqh+0OBL0rioxnl4
1eIsSz7usVBuiW9V7/1oOVe7nDJ5yVfvLesRcr0sY3MyBnXbNFjoJTRceP+38yuJbbnY0GtppnBX
2+P9UuPKKH1x3E5nVcvp4tsyHl9FhAMgvO/8kc2ujJ/uoj5nKZdpepo0Fi13xV6x3HGxKGnJk55I
ULPZQdhiPc91Nf+ilGE5M/Tpr2gUb0qWuqymJlNbipk8Rvp18Es3S8FPWar3zm0tUa85DZYCpdWb
vA1X8Lel0KKSKwDbeWPF6itNaeu7DE7NeY2Gs6Cp59VEBsQPENe9sb01Ds+I+KvpAoQ8ILyz9Khr
wpTU4S5TpnPS0XcPZT9U3ntMTRejwTKGHLdO3BvemWHJq/TKYXZftwQ127XinSfyF6YM+7AJms68
y9DXvC770oJR6/3mkvXd6/0Squ0dr8v/rho/KnvcpWrdWXx2gpu8DW+wlyqtL8KzG6M7qPlEn6Er
f8wXXhDMLFYJ3lG3ecK4wO4FNy2GEnuvsVuAP7qfI54B4X2VgWema8zOCnainm0pBX9sXp3zLreP
babdmMGFjBOep/gGxysENZtLJ7bYwr3kfa1Ia5Q0P581takjJpVUJzUlLueeZD2/YLn6nt7CQGcg
UKNP0Uh0z6Zic2/yXtKUmwI8dH9j/Hx3o9fhis2/yQtOOzuLDxHv37iTeySeS/K1z1qwnxHfCHdA
eF+9h8/fcj15UH4wNx0WA7TW1vMFRtR+pN8bDOPFNxhnDsoZRf78dGJJgtspYHP3jXb2bfdE90yd
yHrcYIlM3n71XYz1xZeM91QwQvprOf6I9pTvRvaSVF92lgU84FYva0q0yYFFrmvnrsDfsV8snvWO
lOcv3XbX+gbv+mYi332ZPDgaN+ZK4hsA4X2HYea6WcGWrSxYRbjO3FO7SMcM92auEFS7jokOwhsn
D5+XTkxzrcUpuM2x0YI3V0pDHTtKy9UzHU8btwc7vI1dvDWtn61M4/5E6d+S+9ozRnGnADeLMlmv
o/OGaLMq/+K0VXoXvbnVoXGb+2ZTeAkMtJ1jfmnfkf69ovhGtAPCO6lvnZJDO7KCLd9yfYV846co
4WWDuDyT8uRJ9NQge8NP+UrRVHOXFNw79p2KRws+d6cd1WztHl/Ts0vT7CL+aA1X+2JydC2RdGLd
Z5LxLRiCmo+XSjQuw9ArXAlt11FNzuY45xqu+Hd1ltuOe3VEW4pkF8XrStEzFdzVkyA8XvYEuCXf
Z0CArxzb2fsNCO8NAjty2vZtwrqWns1YWYilPtKautb+W89dfJPV44Zjz9we72v1PXub4jm11I9i
rs7f5X80w2cjJVZPL9T5w2budAcVJfUYHQGuI7QRYWkbfE0XlvMxKbHI58qyqcDln71vJ1mSz07U
dPUHtYcsH4U7f/w9sMn7KhZwxDogvDd2bTrrxr0Z1al6duTyqe6E6VmYnzA4aU2d5Wl7o6tDc9uk
/IW6yXhyuT3eaQVQ/HkmyrAy/3DEVm3pXxradnys/Pd82iGtvvV7eDXJuee3HhG9VrqnKOxvgcQ1
3kA/H9Tcc/mpBqQlCcKMsQN2CfDuatWCUfP0SY71DS02k1S+k2a3caNFCnPTdZmZKwdb3dCdm7w/
bysZILw/SGLrWJsSWuHhMKGz/oisYGoKcd+UwmLaShyVJupMrlYbDD/9RVxij3ei4F6rmuut0LwF
PBDW3PtElvRfwzTeo13eqvVCjbsMIp5Lvof663jJIKw9/UbFki6F+yhrRcsifAdpD4etJSvwWqAu
TNcb7idXe2Ekra+4QqevU25jSbUHk4QEeLGf2ztm4IJ+etVg9QaEd2xIkEvUXXpYqo31JxY6b/O7
VpfhlH3g0TY5MYUbnfKY07vc57v27QNLjBC+QHCvMzgFo5Y7K8IcnG0Yunwgy1TzVJdRzPvqQQ9i
Vi+R0tVxYe8HQ6/tHe9FQdfA6t1S8JW+Q69ScDYw24yVOHfF0Cy+jcO8Sc47V9rzhxHt7bIuP7/T
qmp8aWP3qU333u5gloC6NJ5Q4GX8EwDC+wvE+4dlBdu+eqBQyPFIfmzDda1FSVqP8cb88QVlSxyl
Z37fPJnYrJQTxXYwYvlkuOjuqblhzQenatjOqwHCooGz1P5jrHV08jeoJqTVKdN7B6MX0T8sRW/T
uizbeTT1umdcv/Nyxa8cmC+nME98gL396bvvwMZqNi0UztTbhRqf28Lde47SEODBJKWdAG0pj44F
GxDeKzvu/edbhdj2wftCLuq29QHLEKy9DzEXyNn4t6wAQsaxVLOD8vr5wb3E9s7p68pnz+8JW3Pa
fow19ZOBP4lWex7v9jXa/Yac36saoltDEf1u9ZbhGVolkGVZJhTU3C7i7VsF7H3c4zYmk7u4cUlh
2we9UZfdwmYrY6eh6BPJ/x5u5jrgbpbmsT4ad3yU0ftoi++KQLakJkNWA8L7Aya8vTnJin3kS0fR
C7mox/Njy5hSKBa0bHqBIuE1TFvlIpHfamPkVwnZ+8jiS9e7gu01y+T9KDprEdfi+nzc76u7PNDQ
FLIJakcMC3sg90Fcc9eeAm3/ml+jnish/Lu8ArzzPCHr/qWN2rt6NXVnAGesAdxWjae884FQnhbg
DWH/qLT150+W9GSZYCWHLxfeRrHk7n1sIbFGu40+aMv15oLXp1N+/ajEiji2ZgWztsDUqi+HbVEa
IbtvWcC3eXq5co7HXasIEFVa9DCt2IRSPvr7s0fXqO63lt3nXaZFhtr9NDL0T3UqoTzepu5RpjHa
ZIUPdnTm/drLg5pnB1vzfZE3CFy+roF7z1bmHFOmPnEF6yy+2ljCUlfalWK86mEy5AHC++rifbqT
8Q18p+vZyM2yXdS1/rlygpYNJv8LDexLRV1tfGtdulzna90a1TxcxdcR3ApfO2Bd6kZ6NrqYO+uu
GdP8Nbp450P9MTTXd137xLce/l92VPPQuCT7ouU4enz/pK4MDAlcm3iPPNuyz/KwPeeZfefKVIJL
xu/lngCqLNho071PxjSARt3LMwrwmIfsRWWr/mfX3bFgA8J7l6xeHTd7nBv2Txefq2eXq/gdm98H
t1BlQj+aJMaCljnexEID+0rD51Bwl4tNwhZPA+L1mfRhKK8HUsK1h0+l2fp6U6yp3dg4nZhB5qof
1dxW2Imo5tLY20UaeKor11VEVk3eUOduAZ7Uzlp/NUUgzGuc67x2tODIK/T/ajvBaH399LeP7Knd
4nzKNe/3LKH6kgS8IZhHrucACO/tQ83a/nHGAbif5Xpjd3+Wi3qgDGuygs2ENdd8vQxix2yZLJX3
ce4K1MT2TGTlvJlwO9yWUu5tL5zComJlYLa2wNaR0FdULcvt77fpFv0Q8bwV7Kynqf/ocy2Nav7W
6vRumR/mPA4FNU/cvz3r4h1Mf6QpN/bn6ygQa+AGM6IPmtxdsFyLnNSm+8+TZPzUDOC3yi5NAS5D
Xf1YvX/9t8UCjpUcvlt4K7l3G/o2xRVmJEiWOsO+Z6q0bbBZlR87oIHVnWQnu3krSUEr66UEn64l
uC8wzpSK2Naq8Po75jIL7rfBCSVUAzIJbP92ndH+6Ix0YpKG/bJVK6uXomgU1fwlPVhddI+eSkMB
bM80YVsRzIjHplHfavShlsHbzNLwFU0LGNmPbR77viNjd//9zS0MqTsfvGb91vJ1L/cIu2xNACC8
T+ioPbGhIwmdsxSmf7i2/03moUU7BxOLITmSomtD0LKp924S5cexVEMq+AldbPROFdsbmvcZon/2
+vEAavU/Dl27NeqzLHuPvQ/pSSdmqdZn5dd/JPXL5fFlH+TxdomUQUWbU5F10o/NvTfHxmnnOKKk
7ybz4s04XDvF9+lRLL1mhvnm9L63W4bGZunjLrZGUjwK/S4yPl5GrxUbqzcgvEOC+owpuAxy2CfY
x+Ox1SI/6TuV7Vp+WtCy6JaBxE3e6k+Alo/bF0wnpgv7HIbefIKL35wrril8lmEi3p6dRyItNEv3
lsa7cU9ZJsaHKZ3YSPBKNnOkOs8vT8t/vJ+U1n4jm3GGT5IbZe19YSNjKpASztwQiFD9j0nJxZ5p
AQp3NnmFWZZaM0n4X3VmCwAI7/to8/l8IQnnqDM5nbN5x6KoK/RbSiquRXo2Mq+aW6wevAm3i7rH
xTFQYTdJJ3adTkSLTtsnuM3pmR038mXOke9cr1X492mG6OIvf5DkFN/PUc29Pb9T9/uOG8ZWe13Z
s/UwwYRz7lgEuW7FWQsBluWVeCnmjNvxVGOx+64ZKGI274SsEuHxX4H3dEUR/hkTAes+7+dzSupx
AF8kvA2KzesdvrfnmxLs7ceTU0jPhHwzVNquYG6vgXHzsoId+1zUBxONVUaw8gXdRLBycoKnxb8o
3/UVrx+Nb+SJpD78Bqsmb0ue7nYKMTUCsNXSiUXFbutkr/G3Jpp75fB6Bfe9oP1B0Lp9khaK9aTc
XaZtLKb95LNW0rZXyGXCs2VGf3cpXsO+/EjBVRvRVWnK2lTBYylc+3ryE858305xAIT3wglz6j2U
LCynizdWpjLEUV/7WjR/yKasYHPZ3JNd1LXwI7n4Hu/0huqOiqyZ3iSszOe3Wq5LM2C3qxkXK3zm
cqPulf+rlcziuxbV/M+/1+XxHof81HNAt3FstfbVZhthxNNjYAEP55fXSIDHPofuppOJ4A/5fc2n
z/VmL95bAJdt3reYkvHY8tztLhOBflmznwSrN3yX8PZ0eYmBn+OFUkfRrcrm6XWMVmUSYnE5r0/W
fE4C1snxdFjz6UYVcVGfK0B7PXvJDomL7fFOtzTotNNPXDwI6qCZD6kbevxdXMwL7kqKLllE+HM6
sboIl0l8d9R/5X8a83j/I3zVE93SlMnbFsQuoitluu8waJt5CWLFAmbSJMAcbM0ZGS6pU7pH2Eq9
/VdKZq8VNhPN9WaW8rSimgMAwvs6avyMvMjZgn1x6Xvu6H1L+UxAOedzZYQ1Pz0r2NmBAA2CW2d+
DskpwyZF6honGy1+FmdAr4yJ/GivrqZK+KZnX7d9j0Swhi7r3vBOagjptXm8m/7neu4f34V+kiKw
BzU3tZGuAHd9R6Ek5W89z7J+xbzvwOlY/wkrgq7nUX49yNYf5e7M3h82bmz63deYekWJW6j/nLly
n7f3WIDPEt6eDYYjwZmfxnvp4oFXlCs5PLY992v7pjnZ0OU6zPO32axgynxWeeaWgX3jJ+/xvk7a
sPMCqM13acGo5eY+xBIcS4f1X4ZE3x0R2/u9bcXOzONtchCfyeP9qgiqojvH5C3LC5ZHRPv6smbL
1dFNIS5TG7O/MnkFeKSPmAi74BP1i7pGzfVxrmcOZZ9TbBx0F320ij9fD/v3eO9deS/B3wAQ3qdN
kKO9zkC9ZKTxvopgN2nW+AX8VWVxUV/oM25978FN3jIWJXW9O9NSuXlr160Ft7mZBgT3pCnRFAVd
45vZs4nZTd6abbMvBdDkBaX5JcDVebzHgva1H5V5CcW+iCqnGMtIW2bTOVP7v2dLrj392VwWAl8w
u3mnHcuVHAFclVpbUxp+9/CcJkSHL3bfwP9YlFe3+dpvq0X+72OwegPCe7IT27V4lynYM54hGhZV
VmntE+V2a5E6jzAZ1nx2CHS6qM8MzD6bY1IDPyGWyjLB7Rasip06FFv2ye6s+IzXiybLpIlZpyVi
+eu/J7dqREOVN1KJjcW87DpbtS9Dk0mrjO9Ox2FLOzeOfpaxRUN9ddvUk0ruV8xp9zTRt8k+gOcE
XTxjMta+xttGtsZWr2qoWE0WXb05SNZY22rYzi02FaGav8d73USgdmV1xDFZTQHhfSdVbgiaIY/+
1f7ypkXvjg6aF8oKNp7STIQ1Nx3m2+TdssQoenvjo4XSrX5KFHP3jFST7XBzcWc+bac5fWzkGZe+
a1MdzJhr+7f7Ac7sUcpH4jklj7fGH6hS9gj3hMzxO6WZO2e7dy+2WbSaLuxYROkJdz0t/SrsF75R
iDZFmU7tVDZ5lr/J3pR5i/ujMXjkDOZGC5fH316zq4Vs2ONdDLepWbhbxVRoYeF5n7f7GbB6A8J7
/SBi3cGnHQPxXBrvNed5ymgyMmtFVrAj3UXdGnmtd+pgf6J1kWPxfGfFGHx59arjYhHLTS82J6f2
mrL1vklD1/EmsPX27+59q+nAOvu9//lPuyyJKc1keX/PybtHjjp/l98Xl02eD968F9uy13Uumls8
k0PHgumMy+B1I1eOm9PwgJixwCfbVuVgGR+i+fFdgznKxPLM7Sa/SjnIpOktcVvZ4w0I76+S3C6b
d3tQnXbPTjJZ27NRxaol4xHkqtlQXFGlPODEO1Fr0tBvbs1SuoPoJbwjfX5foKX9TCB+/wrBrYSy
aaJPMDbGmuC27+D1W7VzhPvDf4+s3dVYGnKW9yWJlip1+NAH6bVypYF4Hrw8U2aQfkRy77xfSZ1T
htZQMMe2LEHmzIVVrE8xrcZtz7XaOGR264uvFKPqUe3fswXSzrr3yuc1V+25kJf51mMugPUJf1my
rdZsrN6A8B4OLZ5AOjmr29Ude6k+7Z7ImvJWWPoYsSArmEFyz7qoz0Yw1ftArsQqnx3MN0Y1Py+g
2sqUN8GI5WZlMKOHbW7hSnrGfow1VQWsR4nU0om1r+kX4a/u6yPxbXl11d5HjRSOb1bvnuj25LHu
C3BTTPxAEHWTlbxZb4EFWPXGjdz935b6OSXYWugqK7ff2Be8PTEN4mU07kcMXXW1s/nK8dG+98yb
hfRTdrUBILwNYs6qSfJTM0wM3m9DkN4mJPNDsidtiuYue15WMMN1emHNLaHrI4ni389xxV3RsSRo
3MrR8SMEt/w9Rtf4nBCcrd+0ghbuJxU9v59XSe9HHbfr/nlzv/f7AL2VTWqER9P7cb+P1UQFHI8X
ltenP6TRzEEgPaHKs75itTVlsx8KXtyymKaX/9suvld7Mk3E12g256fvR+9dUspiRGvsV2MxYPWw
M7eUoL0vMjRdKMFjo/u8Z8CaDQjvl17ZvWftsLoBtjvcdeIhGkNT8/rLfQHZPLi9gr0zyYsOodYL
9G6ncMEbk9DeOVYX9ci0xhNkTbktW9kReJR6YOAy45j9K+a6dg0je9nsJm/jWoTNFB1PA9ZJf5aQ
x/voWKctO9ZbotsaXE2tA5UdNUsPAeNagjzgLTEQ4O5o6bUu0pkmqyeGZwTk/sX6hMAWwa4xFKhv
djlh9maOiPG2b0zBtnIhm/cCxy+s2QAfL7wtEjnS5fWkm+9vqjg6t6V1bpQ0WYTyyqjfs4I9a33g
iLmo5wyeAdu8PK17vA/ThCUCyhUEd6KS1eb7ZczAkoyGxrIEFhWGabzlm98e9nRikejlTaGs9vl1
8d0u21RwNa8eeDvO/uXV6lny9zeRLH5dAe7aghE3g2rWpX7GKnrkuaLn5OBO7vi0+PjaaYF7jltS
hhehAmW8tov6jpAwJflCKxYIsIzDFwlvT0qbdSNHS2z7/jaWipHz1ChpvIs/L02X+3Y9Q7IsTuTj
QVQzdRQQ/ct2jl01qFryHDB/X2R2AYxLQ935sWy30IpK9Sxr9SKKj4Sg7Zqqq3ezGPWenxVcrbUY
8HpM1Gvc8i7qe+kbbuSTHijNVt84Rd3zupu84+3b6/nitiirvp6QPI5cpl9vjaK+OIqJ0vj1C1XO
/U3PszLo68UFMQDC+64CfDAMTQQ1Xx0HTYNn8tnJFay1gGBX8MHnKiZ9bLJ4xEeGxfUu6pbBPPgd
XGhyNqsGcwX3xH7uQJl918pYiHTksjObvG0u5pF0Ypm5ulujyEh8axhRLhhczbGg8GTpfgnO5o9l
X5GzsufkjuT8bp9r6xL79xzvOR965yjYYTUXDgahP83ZvsYHrTA+xD3WNfqf799BxJw9G4/mKVyC
TKecEx0o9LWllWC3i7nvfmX4i+V6RDcHhPfsRNkbyNramUfTivVyPKe6VEdHIJ9gN00K3JMYefNt
zU08Aluu7aJ8InycI0XT96EcwT2RhktDcZogdpcI7mgANcPVDebYuXRi72JUlu+1k8c7akFuWqaP
/iLAePGgJ7oXBSc77IsjwZYxEKaDTtmsqX3BQma3x3icUGr3UlLUsJyga331nz4UKbJ4bdtiMOzS
lTyCNnOH3yWvp461UeUBEN4fNPked9dK3HikzLQUntDssj+ZPSxQbHLm2w81adkdKmE9H6fKyN4L
/uuNg3ZkGuYDzv6bvAK232T3RuyAcp5z9HSUXfHzx0bpiCS31Y3FBhdJJ7aiOT3vQx9bp/+I/Jbo
bMX6sJTFb63/29L9EAdaL0pZCR4gr+7/rbc+3PutI8W1Vv1hRBOtqCvAdcTcjuU/19fXP99g6CWf
3F3LqtnNGU41XHfJeZTXAIa+L2T9cKp1l5249JR999XU7DSdl+jNSlL5W3fC6g3fJ7z9/Zfy8lJM
y9XweZ54HrKGjstI05UQdT5i9jddpqegZZ/xyLWsMFln9nrQqR+ZTjt9lfbNFd2Gx08IXqyERYXI
uxllspp96WNrdd01fLZpvUZFf9+HLXPfLsc9x6LbfDFnHm/bEoq1Xk3Xf4utJt9oOeyqfVGpnwW4
NUPF7qROSRLOKexdlwgHn5dpCLddbzBBkOdhDPOJVE19zf38RDMH+HDhbRk0pvZjK3i8916efntl
1G9ZxhZ1BXva+7O8CGW/CMfxL67tWVnBQi7qai2aOO+Q7lun+KV3+6xp4XUDYYWXOQwHOpNAGu+h
yGuLcGcf4tjTLWMqrkdr92NQtXqgNJmdMdRKV2R4hmBMuKe93jPvxtTumpZtDevbGhQtI1qDR+xP
KPp4B+NJeaXIN3nuMmzoSNVHuHYi7/je78iVbMeoarXP23Mf23Jy9SHUquTLxqM8lmys3vBRwvtN
MPb6mizdZdlbbBHs3ihpCwzCaT2pZtN0+RLAqSpB1VG6vc1bVtd2GetcfV3c0c1yj/wnpnkxTqHd
JczbiL1NOccNXTOJanzS3JrTO/sh1VGJT1bhZbM29cW3FD+/oYKfn6uXEkyda8ggprOrypLH2yha
FVx8OeyhocxO1Oag5n6X4oVBzZvjlBYsnppjt20Xcq0FGMtebiX1Gp2pQEqk3Tvtio7E/QCADxPe
oxHOOjmVw8H6aFswWuc1o6TJp2h7M+sZy3ZScNCzg4/ty6utwTt2Kej3ibdhfcc+PT3nq1TCp+wR
+eGyTIhKGSfdVnlhi+M2I80DXikBk/dw32VgwiqjOB1au0dlecnPLYcotrZvaxmr+7QbdVV3udeD
1d6uv516+XBFNbcHNR+Pp82xwr7Ju5u6zNBlNOcOnnTgTjNrpvjWSLuuXtf0dJuT88OxLUSd7Xiq
p3i7tKbW0cuIkym+S0YxS+PfgYuP3d7nEnl7rd5YvuGDhPdcD6dq8Be/2VzRQdJ5pbCyblrYtdaD
27Sn3P54s8nRfO8gwfVgoKBHYtkTCG/xDCZRCq+X9KayBEOPey1do2srsWztBQG/83pkSev93gZP
l4GL+auY7EX+rt3aFtW8EiBMvn3mf/673/eMUpvJYYVv5Rf/yemdOZ7W83j72rmciv7NUSNzXqC0
7sY4zsv5zY+K73m2BJf8yfgX/ppRcA41NU2p3NMyH5QjtkFyCrQLwR5vgK8S3j79ZA2iVh0GDNrY
5zZ9rDMPuvZNyuFK7xDs2bdTfbj2TnsydgfW69noZ6ieWmhZiTqBYDS3RrJaDM+fnpEE60iM5j33
cJ5UQyGxnOFRrrETqpyiyrqXuyZCJZ9VvFWA0aKCNdK4Dluws2dhbtSeb6L6WUy3RHe1tPrz3zbx
3G9IkmMJaKTgDB+XBv1gP1p3NH2lQt+SjHvMMhYqfddwxTV3dsNy3Tk2ZWls49Jhin9qzb5tGo+T
hfN0ZpILTbdT1Lvn775D+if+skr/Y5m2XMtrxcbqDZ8vvFP1wZ4oacbxpjdTvphgr4veqC6trqlY
s4k5RHlqoxu6qHd8QUMKWuP3k/KcN4pYvvJ+Tlf1rkv5ktfiXFSwqbOmqD3c+5M1FNzRinjbb/0i
vt+W7l6+xa6Y7uy5leUatlhvE/25Zl6V6XrRZjL8Diz37yw2NjdzvHp/DLV1R4BXvetf2kTm6toS
VR7cqjX5TK61VlkXNQ5fzjivY5u1DIZ25V4cusk4yR5vgC8W3kqbwfZ7vfr+rhxXZTV74HeLjTqd
d45g9wxm1k3eDYEo5/UH6xyhLdfpCzOBpYWOYNdki148m7vZ6Jw1y1B3WpVtMX/6Nc3kPSG25d/4
+L6fOGkXYiCg2UhTPJ/rbzrV87sLNKpavWOzczVbYlg8D/N41/syU4SDrmBVP0e0Zf/26wCZGNS8
OgdxJPL2BDV/vIamNHFWeDV/nxPM1DnRjwfHav/epZyipIjwFXu9n0nJ463GvyeeuFhLXWLPhNUb
EN4OIf40VA1WVt/dlG3WanWuJ5fQtwu3LME+Lx5HSrinpfV+fMLmcG/Qsuj0Nk/hq/uGMyICzM4S
otaLzFJp0Zn+gGdZmyCNgjujRoIW7phgW9AqO3m8q9bukUJXp4+XtT/r3Fe1MWHWpd4eaK7VTl7f
Z/39TuTxjuwD0dFZQLabvJOcrFd0n3ZBauhiZOlSTIvuxjF1U+A1U8ya1JfzuOg1OGv4KYZs3jnT
r82wxxsA4T3e390RfDWLqWV1thdErKcvLf3p5HpyWLCbhveI26LZc0z9eqsFLeuYvIdBy6wu6kmC
3T1TawzuvpgB8wFrdNKH7JrzBSzBE7HNEirQkb6sb/JOF85RL/TaOa97vSPW7nYk8LGLuOUb/tPX
zJWzL7o7z1KxerdEt9R301dqWkJPHu/j6JmU43m8/f2k2j3mIE7GIH3VpLFV3RZqvU6Wxfq5HDEP
gGibiox7g367t8BlbNIa9CHuxZPoeDydVDxrAEseDK0OF8Z93mW2IA9W6VVWbyzfCO+PENjtlGAT
/Y/7uHgCz7Zg16QNPCbYfY9u2eQdTXA+s65g2DulusRvL7iMJwZb9pQb0/JkDr17Ipf3p+8KF3TC
WhfsJGwWcw3v/r4PO/4SosI5+q0aUl+nuZjbP9P3lFvSi4dSwI1cr7mwnT2rd3+7Gvd6vE7dTb3/
Pv8sXiTm8Tb4dMfyeM/2MGouxvfLM45o6ZmTyCvAB+J73vsgoxucu5F8s7rkUcc6X0lc6zhJ98bL
xY5ugO8V3pXhqhf8Zq4b0VS5zFYuY+qtoWBfkFfbak/SUGYO3OvNLr2+2K5mwT40cvQFu1UJzIg9
JS8AzS7ErBDcpuJPrApku6rbLOYZhfVbuT3n6niNNxat5PnNipYI5685rKvHNV296ysSZuu0Jaq5
J5Cc+pHTNRD4vUWX7qKEIsHuHHm8Dd+tP4+3+uXwtlFN9EZWL7un55apf0/vgx3+97EUjf2zXYYC
qTG/e6xCHab0X2qP89VFdv1ZBsgaWdJG1Nq6bdZedGU8qYMy+Hfrb43DfraJF9dN29fqHoMFG75Z
ePc6uvbebKdws/6W7Zq6SbDvklY2jejaHH6Mdtv3BqGmi3pHh6sj2NsGdrsID+/l1bhGY9Fdstru
wvut0/9HJELD9DNOmLx1vIqtiOU/uDSheioqj4t5NI9390MdWcMtXq/D6O32/eXWPdvhZqx3tef1
fPjJDZ6Wx9u75UOeoFgzm7zVHZdMVuhQcpH24tRcYEZH8LalvcHMAOFzxvfMadxe8rMe/bMR01cO
bbuGeQCE9z1l9HQPMMqLXDtWleNMx3YK0TTsam/9yRF8LJqMPBhnzL3qr2zBbshTPNxT3tPFttzi
0ppPYang1vw3vXI3um0OPHYFty2SKKHkWnauPN+RQyybtdPgGlbB3cxx/SK+zQK++Tx+t3Drh/wc
pG3d1Du8Z9/aAiP7f1te3L8La83jPdzk3S9VJCDcbKelQAfhWYNN6q9Xbc1pjc+/3b9VO0HTVfkm
fOWUxN7UZx8mVcvME9nN0883Sy/9Q0EqFuxsqzdWcoT3Z6OAqM2IkjZUtOP7mQOM9R4rsnVaw2lh
fUqumteTOtePxua2iVV/O+mk7pFTsGss2FWbXDbViiepigL7tM4Q3JOXCSbGPjeAmvc0e+HaAYac
+8dDRmWrW/LsNd6PV+87UXsa3HJFr02eLeJ7aO3uLrL9EcNmEW88bhRcLRLVfG8e75r613wvVhXg
0f3oRlE3oU+jsbMet0fNxfnSmo5RmepeR7wXem/jvZOt00PXv283ucbWDfBVwlvJg1123LWhovXc
pGaC7gl2eQS7z9Ve46WB6l48a1awpge3aWV/gWBXfw7YtQzK9zffDnZV95yPnkFJ31jGJMnthKq4
cjZn1DFOKrJjCkW2cKdtIQ/slemn8+pfL2rRtghhSeY2//sZGt+uJsr43IG1v9Jm5jE9i/F21HLF
3fBfpdkwuNqL6B3m8VbKolL9G9Egj3diXupHI35zD3u/p5bFOt7cJmTo5R2ZEuTuC4zeL96VD60Y
p+USuerELpf7mdpvfzzHSd77vXKC/UI59t7PcmO7Ib2WyNv/RFix4SuEd6SjsXiMe8awVU6yscvM
bfJu2hBmKqgh9C17oGfSePsXURbsehrmAW1b060TdNukzd/I1q5lTwju5fOQYMqxoHLOjFreLpj3
5kqrzxnL6FlzuVh08Vfxehweu6h/33V7AeP9Ny0IrlbPOqDww3nyeHdecsXtV08RvzXXZpr7v/Uu
+kbB1noflNqiNya+12QzV7Dr2fpBd0vtuVn/36rOs87RzJa7xNLFAQDCu9efdhSbJ8SVJ8919VjV
5wctE3TE9pk+oA2zgrVcqg9DXu3OYNUq2FCwjyepccEedYkPzfo7LurOqD8Bl/7dgts9sV7S5hMs
5in5vuxvop/G2+lqb6jk50DMNpXYji3ms3b7o5ofbzm4h/fpLapWg6HZgsSp1W+ZopofT8J5JLo9
oj0vuFp9C40nq1g8j3dH+nWUYMyd3PP52lKNxSyccvRY9uv4utnnu5sWxq2dfMvdrWYLePVC6H3Q
at2u4R0jo6tdZK3AsMKckkxtkyf4zy3KTBk9J1sioDdOs1m//3miFws29mxAeDcltAaz1IiCH/Wa
cohKr2BvxGNfusk7UC9eq/hk+u/m/vHm5HVesCvfRz3+++QMYO+yzqqrZ2ykdgjOUH8xOMtp8s79
1bYf/kmtSVPvyCO6I1HNNdjaYT6/8/H38mM/nt82FmdENbctptWimkcFeLdb8rQPWcS6J4+3LXKa
RotbVQHeEUAzGcxypVyk0+oP2UFl310DDCyAy5IizCCw46PJa350PQV6m9nrrzV5MK6nzAHgvsL7
aQ+F0cIjs21H7aDmDd3r87bO6eCUJNjHDt0vI6b5uXp+c71Aa6PZ3TBPV+duShPsEV3sj8yu4Zy1
lSLKP7SfR8zVbTJpd3qav7i9yNtHtD1LnPvRrRbrHwEZqRrFK3smqnmz5QfTkunx+U0Bywafszuq
uQy9yOxiiC+4mkZN6J8fTdbs6Tze1pGyOgyaO4ZXl/VeoeM7ReQYOB7Sww3mKpa2oOboGBfg/aan
vuh/GvI12LM/8DBUpw81fE7xmD6ZFTYxlm/Q10tyeUdv/GDa9gRMr174l+Zw7N0ui44FhPelp/JD
O4Bhk3cvz7U1qHlLVE6EkjEebZnSN/aM6RhNezqDuzff1QrB3p71KijYZdy4ptEGzgQB76/T/iGa
/9xCfuKxiOUrNnlv2TTefyRDurKZPeBv+ZDlCKQouRtpT9SOxPRsVPOWtdsc1FFtO5tlfVAaRIxY
EtVcxuOyGrInuNrUXRx5vGNdZ1Pkz4QPrwl1t2jt5CEf3FqmTqTbqp2PrkjVW1pwYIaXO57JNFYa
rNxpwlfjQLWXVdUAcHPhbVivdsbNGI21nri9TcFejVkmk+if1g7T+5yDg6kigt37ILILBqdgr/1Z
GonwmIt5byJvnDIu97iYUM6TKcIyizmxn9sZQSgt6vgxeREFy5oguC1CMCOqee+1qPeDdS/6MdFf
haOaH52FBT30SVlRza1tyxBcbbjQMzZ5x/N4H8dwb43rWzd+eWoIRWOwNUX8w4NbXdoXzhHfrlwH
Gj29utG/rS32MFnNnQuAx6bActV37Qgc2GmDsXaXMEo/5vGOnPdz4+I71XbEQ6H+sU6bUo1jyYbP
FN6jD982EGnR3YeCs6E/rZbzcX7s57HK7gWgGQVtNkzMuluvsLC3BXv72WrWcBkmawrUXT2gVE67
zk0Zlim44xJCR0zx2jKVpZq8nfVuv7crEFpEqE+nsUrsfSXjZFsDkSPDo7/voZZhD3huVPN6v1Hz
ALC71UfKZNivrVFaMWcFuPJ4dyJ6V0W+3Y3cnCJsuCgknwAfiO9+hPlgt+Pv3PrjnrUfdG7xjr6D
+jPokDcYvPrvUMHNYOPAgskDKQAgvMOSQZWBTd2DDSJPHc9oOZTM3COrs+CgziR0nFd7PGEcGqs9
gt1YS7PVqpBgrwlsVX/XKDKyRucp4VMwuHIor06j0zT79Z2W2oko30tC3QSFezD+u3uOWHVF3yCk
FQl4Zj3+qAlOVb+z3/GDexHGK3u7reWPRDVXxTpeu9+a16RmFciycmCITzHTen15vGdGks4FVRNY
bT92tX5zGBm16jXP7ANYFGbjbYbStdQeJvcDNWZKf+aHnboeegs6VjOmFgYWtYXE6fb265dOI3vZ
5x27sEy3fDvGaPXGOo7w/hgtrm4HanDi7uW5bmzylkfIG921bDmm1dG3VhHWSdNVGbxmXNHdqb+O
ecGe0+nL9Td11XjvPPsEvecLKXc7uuKimnyHBieCKy4+tv9Yz5tYFlCuK3pUYEdd0CNRzavpGWV9
TLVTdQ3E99vzdKKa90R7tvfAo9V7XH/jwcqyp7sdXM2hwB+Omcvj7ROwwy9NY8FYH561uhcc9hmR
tFbD0WMiEptM8xn151NejRzqs9/vL3Mk3qAwd77luhHGH5BSWMsBEN7rRicNNfKckquJTcM9h5u8
uybvTt3YzjNv4Wpact4ty14Le3McNu2PHi9gxCYyvfvGIlt3owLI9rdxGRNygW79bn0TN12qT5kX
zi5xckT31r4cF9hfbbFSW4OsWc5Vc7FKpm/lPWq3rX4ygnw939v3rWuBy79/e8BcKrKXeOi+5icN
Pkezybs9CiTk8R6nv4rs31Z3vmLpWpTQ3w3ju834tFs9EdsdZbWVdd/3g8xs3U6xWnx/11VnyY67
+WzCglMGTs2XZcbw+xi63JHT233Ug3UaqzcgvB3fvyGoeVJ/F8g3NZc23C7Gm8q2PXkf5tXWaIAc
CPaj7Q42LdhNb0npbzdmvZJ7r+e7a6qhgaSEInamsUoQ3D77s8NtdWJSokzhPOPqbb1nYF+2Vdx5
LNxzUc1bEcxl7iLaWz8cTv5PFu5K39V43q413+Eh0Pr3MML6EQ3IpmbkZ1f/PPHZXTmPt0V8P/6f
aZJi8TWTbeFjxqJvvMlUj91bctD7NMKQzjvuNfW2VWVizuk+V3ERXg97CAAI720qXNU9lvbpuJou
15Zjm3NEh9Ierfm/Pq8G3XA/TZfPpdq0iO+0sFdTeI3SePcEe8dttHdvW5TxoBV9xz7a6ElJgtuu
rYO7q7t/lP1aEwHUqm1tRjh7X5PDah0V3N4FoNijK1RdUl8MD+/RiJZ9HCPL2Vh9NhcZuvvD24Hb
VrmlvwZks73LiTztb2Oaw5Iuq6TanMe7Vz9e7/5BOXwdfiDieLej1WxED9taw+snNuWqHQhq0mln
OmwxJlweDN73qqRK15I3GCPL8PsYKb3MXrpMFfeXNdti0cbqjfC+v+Dub/I2dBaNCzTzXMshrseC
XZb79YR+c3iQcxHApqB9NkWbhf3YJdgVXVb2pAXLG8TsaZRkb+amQdg23Zr0Hrc9lk0528s2W9hA
vuvfhzvdvWfSfUXP6bmMe12cMyZ1qu2flm0ry8gSXLM6t8Wx6ta4nkA2LGzK0JYsXgVP+80Nbcfu
geBIvSSj7a0X+fyyebytAnzw8DreXZC9e8mtiwCrLaDGXGsaDuyqFl1DP7hxHdojhD907ZkCU4vO
6bZvrN4ACO/Mjj7lFB3meBlBU6IvBWfMAmQVqqM5Qrw2a5ORTAu7IeeNV7A361KHXefaLN+acCMd
T4itc5DchGNxC0gggFr2XHDiCX4LZ6c4iBrFQ67sF033ZYsK/moNtT2LakL87X4vNlf1O9XXSOLS
qP9oB1Xs5ZZvednIaCVf8Sn9LHT09XVnEcbV4BtyoXGNjDze3kqqCnYZdbap21Zd46f0ue/SNxah
JLq5vJmo3bzeoWEqVbviViuIrmfSkBDUXKs+3qtPxqN5vGvXcdZNyMb8Ypku5tOwesMHW7ztOavb
HVk7K5g9Z6c93ZZ1gGho2DwFPSiKBrdT6i5pnSDYnyfVdtHbTus2ftZca2EwwJp2f5DeXNQry5bo
4uw+b/Me8AmhvPc+Nfdjo2Nx5TuXuyx9S3Y/YYG6/c/YU6ffx1lFd83qbcqnbVlsii72NO4/inwu
w3W6otgVgM2Tx3tsMR9tex7mdY4o40C6sooOdl1jau/40LrvyJUu4w1VX4ZQaw6io5ItRPZFE9PQ
HAxrDgAI793KuhaoRG9y0RD4y95PO9N414WwWqJyoA08W37UW3CYFOw2a3An52ze+sCcYFdvNaPj
btuzahkM8+s/j4wUJplm5uyI5QuTyWYJZ7XOU7RxLxPPWQHU4uUK7PcdWEJbfa1tHUuTz2f/GnvP
2nb519PChPmdDAS4JRXeVH9WW/TsCfCjF73fqqnV7SWHqf8G+t0fIXU0fxlZbf/UiD/i+Ptzqjkj
OeaM29ExTLOJSztD+mgiJU1HNXe919l5wWBxRoc1TtCg7c29icUnV3jZ59065Oe/zfbmjVZv6x5x
QHifqMkt5mBLkLR2L9Be47Qcr3EwMI2u+b6/2xrySx0xaslelqWgu+7WpsFUC9rOxAJDxwRms4r1
AyjJG7zJtHg0HzzJH/AsoIkDwdO61zpTOEf3jztVzoogat793K2ytNusU9SpFdV81A+Ovhm97+vs
fWeNqObPP9fSLo6/a8t+7Jpr+igo25x6fn2fg3fvbNuK9ExJebzNcSVDY5ffBK2nK/bN6EPxbQtw
EVDYsve5/Qh3g7nMu1v4KO+0XbZXvjfZlqOqpZfvvp7lIABAeJ8osicO9ARJa5mLTZu4jKEt2qby
1ojcvIYMCjom2MfHPd9O1hcRjjI+Wk7ZIdj7E9T+QNqbGPvzeNubSrrgTghzaxPJ0bzW/muZrGCu
J/PvH5dbo/uEsUVMa8LqK6195lbN1r+rx+9R/gWFxh7xpjCXhhbs1kKb9Zy07uzRrdu80JPsvWIN
rqZeho68PN7R+Ygq15zxQnqPpm66++SMaj5yuryNoZZ1prqvu77qoKPjZd5bs1DgWWTxSIhNWkeL
Cbsm094UenNzjiMnovnIbN34ffde75EF++d3LN0I71sq8g1BzYeasuuj7k0zIsd4O/O3rmA/5gT7
qD93CgeLqJY1rdARSL5imqD6RUv+otToDdlScCmpNJ05lzvQ4IQN3HDEPuH8fJ7xiQKWaHOQspHF
1Hntnou5Kx90zZLatK468k4/luMxvVZVYA/lwFHXjb5FtrnYDocrTVgsr3pSVPOOAB9/ULl5vM2W
R8v242qwNbVuZje567VrMqb7cgQC8/V64/GjJzabRz4FsJwTd10/kta7VGVBxSBQ1R1bZRKvGe7u
AIDwXiCyNczqYerERtZmywKnSbDLJ2Y17taNCjp5YaFhdandrheMaHg7PU10XQrGcIbN7d1wrHxu
e+uCV6k/J00T8vMid+bwzLJMpPFOE9zPbXxNRayMcB4RdaZFrdfFtUAwwjfrmSdY27gL/NOdWlOV
pb4bLW8H2VHNIwK89ZuGC2b1cXokrt1B2np9juNQ2xQlYZ/wTHaxxETe5tFX4zmRK0NG79FknAK+
WuQV7Ei688uABbyzIOTbj79Z7JcFJxn2eYeLELR62y5duv8GhPcV1fjR7sZanVEtXqV3n3c/snUl
ZFenjHGTtzS6dm1U6d9vtJfZbt5uWHKGUcZ9c4nwsLExLVhU4Awn0TpSI8yvEtw6LIHFtbQo69J4
Wy3Wr1budeJppp25xVag/npC7dB4B23V+tpbN230N60+SoH2GIndcHT2aNf2xdeCq2lRqjnJ1jdK
45m/Sb+ps2SuhxSgDwbkpmBW3e7cayzjIG2OPN7D53Z6BzV/1GEyN8jSecqpzQK5tQyCsfrXXszc
xva66kxI79HT9XuriCdKQG+GWO9HhCUbAOF9a20tbwdtm1hFhbDMKqhvgrYuRtonhpbgbfbFjKFg
7+7PMv7tGAcT6kcZH0983/fpKZBuRxO/21MF2feAv7u4eVzYfBJ6b8Tybhw3Z9aC8J5y1USb1Sob
qLtFbuWZQtr+jby4mLujbf+orU7AM0v/o/eI3dYBqCeOR8K6foxtQc8SXC3aRurXOCmq+eEMeNYS
4Gq7+gayitXnGC86e1VsaM86pTxWYIMXtt0warXU1gKb/RHD3ZRdxubQmwXJs0+w5m/u2u89HYBl
9sQjo3Wmz+Wni1KWHt6/VgmehgUb4f3NWMOoPghh157u0YhgdfduSbP5OCi2Ig+1rdJfS69STfsK
Z4KWyZHHO7AX1irUvce1JpZLxsNZb/JABPSuRg5cK8Xk7fSxiO//XjdZ2uWCbhKjpiWoikeRaf/2
KOBlZT95JYq3x7o8jkXRTnvYF/aDJ5nol/p9SzSq+djk3Yu91Q/a2d6v3RRizbRg0cBrA9dy1Z67
ExZUfUFtdUP+c5zhnKj3UdNa/XjvWflo3U99GL0LHXd1BCwfPidGbgCE9911szdPt2kvs9HluGUV
bQUKa+f/ljn/d0RBy7Fp0ZMOdizYO7b1BXsa5/JIVgS7M/J46zdbfmR7/c5MU/IEd1ZaL7vgtst7
z7XiAdQ8ojuWBzxuhd6VIix+/7klj99u+t3+VJXP+T2z7eun39su0jpmKHaHARwV+OaTFzzNBcj2
cOmcI8v+64G4P17XzeMm7/rCmTHSqWF/sl7Hfu/eKUN35hPfz4WY2eLtsXM8V8NA+TbDmvv3DY32
nffyjEfSm8k4d83a653Ss2hPfxQvZXn+n6NiPUQ8d9uiH6zX2LHhK4S357NXt1N7G/I6IdBUHfzb
u3bk3Efey6s9m6ZLpgWC99mxIaZ50HrbnaSqr4s9qwHh1EjJE9n+3yxbGizP0482aL9LbTIXjTx2
hIK4BJOI2RYAQsGpfItTkTzgM3OmFe7iGW7oTSux5GsBlVRdr9HIe92C5LjPdP+hhuh+EP+tfMZD
i3V7j3dtIeDsqOam77a1bbrhnm5tM/Xgau83G313MhnD7RuYh6mtDZfuzTzGMS7UPz9VWfeilqkz
PFS+E40Cj8m38KH3d/v7m7C4IWpwe4u2xhIOgPC+n8T2BZmqBc9Iy5TZi4Qp71Na03TZgseNh1n7
/cwTrgUu1RniYo07pq18/Xtr/nmV+akFrcKzKjJBna6LWK70Al9RcGecZ3ExH9bG2wsYh6lq9VO9
3SWuZ37K2/36Pbf60VaANIOcfHNB9y+QXDKqeWQ1a3LK8O73EPsORwbz0WgqrwCPiO+q+PPvnx59
d+Ou0RaQTkdd8MofObT9jBqtlSl23dfn6ClyjSaMr/PPeYd9T0NWIJ2eq0w/lmbNFnxin3dGbu8N
Vm/2hSO876XHLRJ6NDHcllfbfr/EIOOHDHObaJTxuWHi/7H3LdqR5LiOwp77/78cO91dduoBkKAi
0mVXSXv2TpczM94hESQBMDux+23fJaDxhip6ngyqxZgZiN/WZ8b+XXtKQK0a89UQuBfjYxu511zG
3RvfA52f4uMt38fkRmHy+JFP71w5n0A6isnAlzgmq9TDnB/m9w/k9uaimiDA/jYV5YtVzb25rOAH
3XU+xO3fzdStZNakiMWzCqLmVR9vKBXut4RNWMFtwRXC6phKZ94giRb9Hh3EjbrM0yI49NxRCWCq
1ZZT6T7jjAO8/yjwHaI/lR2uI9pdm64I0eJtCDo4/bItWB50WbpzVLSsBvSfBsQlW7CH7ZqewfM3
Fvunusk3PVef4IUXN/ZYOqEEbhvhs77F7/n3+njT5z4R3wKS1l4hah4qWDObMvRt32LSEeB7IeIM
FfB8jt5Rw6/OMS7XP76X71U1j74DY3N604n/NrxXfQTgGnFnbuVVH++dVvBQeO024b6wlNy01cZu
ugH73426TphVWcTPhsjruGGqmyhJbxL21+E7iZyvkau4go1d+dfmS1H4yXootar3qWAf4P13DCt7
q+3EVrMw5dWd1xer7d4lH+8MnG8kCLZUxg17r6ot1hP2Oc7+btmC3fTxRlA62eNhFgKnnarwg0BZ
/izem7mtO1Hne+y+KtmWp/jVT33/2bZmhOAJUQQ7gXgqVrmAby6utszn4CJfsF0DJjV191X9qHpj
q8T23kX0IVVz3k0i1osUDpFty2sU+Xln4Dz38Xb7OfZ8vLFu5I5GabV5J/ljuA4gQaGBzAkGYoD0
jIuXjeiRblobAivqvvlO4lS5zzjjAO8/HWnPTOc8c6+xH+xY+UbiddhfDUI7ci9+guBu0DckAYAb
928PLKPi4x1Y99hX4QsAe0UkCVFYMlPMHiFCPwi4N0AzHgLcNcrpnk4ADPRxV9TsLT7e5sXJOkby
5hwUAFM9qNbH6zEpWRIwml8d7J1feo/jCZLg3KEB3H0f3W+Gj9QNVXNkc+AGAKfPaeTj/XDpb0dE
jf4uUT2nVXOT01ujeMNf+lG8cgVl95kWojtpwLsR00AN/gFGXYk3E25l94gHIvD0YK47e7i6/y1s
6MoP8rpxaKeefcZfCryLAQ7gzR5VtC1UOeXEetfHO/XcrgV76hwqHfnPJXjvcVOfqI4jIb+juL3t
5/VtyapN2xK84Y7Ch+S3BJt2APcm6H5fg9+959q6ME85Fyz86yw6j6qZKx977voB44277xxizwsj
u7KC7r6VWlXvkc1nuap5JRnzHVTN5SNGnr90VjCX89pzbSQAqCp7Dlpv+3jDBOl3JyW/xam+/kPA
eBh2XGh8ziC8eczV8/n967UcEp2TNUGBUNQ8SsqdccYZB3h/b/DcTcg6kx3w/cIF416rdmrT5WUC
UjD/KGK3RHvuC5M5YAWpYNGzwmY7lSAgyPTv+HjfTDrQSj2ioGYyuN1QLL97zI8g4BsAUf8kbm59
RtvtzYJJT/h4A6Xzr1fnPQ9m/kisJG+kwCPrZgHneYqsKVMy/xSDgzE3oKZqvs6h+voj6TSq8PJd
SsG2qnmFQ17Ojpk5PEDMkaYInxM3vNvHGzEwRMa+rXp/v1MzDDsq3tiyZHO/WGJiwTm/FmVXSseZ
kTLshetOAufOuFX1njfUV8GNfSY/sQ9t4m1nFfMqz/vwwg/w/vGwvdFQisVbKDCuXd52rd17y8e7
wDXfC94LZfttkI3S9/AOH28TMOMOYFcCe0HAuw9w7kVHTyqWP1Gl3gHb+rEsAG7s8t9hURLe1l7u
S+V//m/W1FA63sUfu7XMyxlpiybGCjERvGRCZimonMXgwP4cV8Ma4YlHJpYjR13TX4AbL7G6d8n1
AHBD1dyroNudCQVV80VvD9XZi4i0SY21xMfbEzUvg6IKOKqA71TgDXESIaZ4I0g9TN9YLj+CY/DP
Oc934V5rdsx3aKfcfcYZB3j/cChdyIhmDmJfZdOVQmjYx6D3B2t/7kKwq+flBG3bIHIDlD4CXuVi
vu/jrex47iaYdn033y+gttEW/ljIEssd7QDnXcD7LQC3+WRsiatFLeYF0ekh35IcK+C+Q69qt9ME
wIAeEITrhIoyQxQ8zQf+bFs3klMsWXqzWr7/HMJf0TdVzSVYD6vz5IFlz+AdH+8cB8bgG+3r+Sxm
67lfdMXG094sr/L4/U+iImRoPdYbeJp099z65z9jbwzeN0Zfss6uiKg/X837m31IV2lzp4p9gPff
OxCpe99H0L7IOPRhVHy8b+4v9/F+1k5sFyBv+QY/UhUvcHsL2xwBu5+6iITh7vLtc2ztRXr6Z3V+
NB555YtbenM1fscvuySIVgjYAOj31gRO8p1lf3G4s93+s8YfJqLJPbTja4bGGJvF299Wa6yxJZzv
93WeSOagesdBQwHYYKruptZj3tzsPbuZ+vyuqnkCYKz2+On5ZaFC5uNtgka8RUDjDXZl2zM2Pres
HQuYjR8CFXQDNJIqzMf7NDxHjlRBumg6T+MZZ5xxgPd3w8dtm6YigY5m6t5CtO0ZBN1qx3BzfxzE
I70uu3ZiIWAvV4SfFVNTvuIuaEJr6fmXguXyW1B4Tt+IeJ8QTH+sLb1tgpQNO66n+LPbxz5do7yC
bPB0i63CPIBewbAG3DDudwcdwJWK6fMuvou02sMVJnsxNpDtcFuyGsd7nou5sjpPfmadG5YzMOrP
jpsctdr9W3tQ1dyZr9umMhhH4nAnOwKM2y4wRqP2Y6CRD+7N9+FvzKJGFGo8QK+A2C8af5eWA0J6
9eS6h+B5SS+bQd2upFcjusszyR08tJ1sXOItN3nealPX+pG/nfdVvU+F/ADvHzsqEARkSoun9Hs+
3rDDoKotWLSEW2FXebbM7MSqYBmFyuGddnP927wlNLJNcqyEkOQt+njZq3buL3Aofv8dCBwPAOEq
aN1ObTzgK3/n2d+NaVzQo69Nws2NNB8SQStMpc683ZQRaEE53q9nCXLuQg9U2MuZgsrMbjDiePcJ
gRh077+GCPu10RpX0EbtHXBe21yg7SlVc9Re9tBOjAEzDS4HTAvvmOn2HvTxDqvruwA8Wj8dUDlp
f8bt/Nr6DJlQ3WMiZ2rRtNpzzjjjjAO8fyByFmvl8P9ja9gwlgq51XvWmgn4y8C5vz8Y+65VXOy4
81EA8RRoqft4e1c8BDBWQgFxoHL7+rpKCM8JqD1T8sY20ng34N728X5DRXy+Zo4gWgq6pbhawQ6q
5S3m2bYkYAx52ZBFblqxElkvLmpOEpkf1yqi9USAZKcBRt62+Lncw1MzZ/8972P6XO6omrdkHoJq
UYd8fiIZLztVlJdGp62838fbDSLQvG6a/U55PLo9Z77U6xjIUUHj7SXvgrT7QZZGnjzfd/D/8fCN
39qx67p9+Zu9ZeTNq96X/PqpZB/g/aeP3+7jjfpxtMwPd94f9P6KVeXtyrTRLv67bcHu4r/wPIz7
A5e+gN3S9Q1ZlM3+y3vSOirgeU98cUdAbcvH+41VcevYK77FT+0yCHAZHxth3Jm3SKvCowJPCijP
UzbrMcp96Anotny8OQ8cSm/kgfcESbJmAOCusFuSWKlSeIbr5Kqah7g2JTm0UKStaR0MsJ2Kkrfj
470ruvboZPkucDxV35eWcNyYrEKbNaYxgVsn4nXlnHHGGQd4/2QQ3S/A5uxc9/GOtnfTxzvatgHo
dveXKq4WVMafABMVW7CvBezkahXbzZ34YeG9RhVE6H2W28/ZR08JqL3NIzwB0Y6K+A1QuvOMfSXg
vnVugKUWnm6StTELAJQfXmBVhBWQc5AVzH7ggFuF1QuN5rPaDT1HCxRd53jLSYDOka4CffV5RfPe
sTsdNo+pmiMDzLotnGdwmmfRnZ5Mzcd7B3xXfbxrAmojXIW18IFyzZeaMtYzsSBvXZ90eZhGmz0j
gYMgfCtUhC1OP3ZmyYeDbfuYvqLq3aua95XjTZ73fNjX+JG/rYtWvvXXr+XfpxJ+gPcfhtbdqeEd
Pt6C+40Kw3snRYC3qoy/A6w4+4TRe4mIF1BOPiBPjjSHS18A+neuH+5syQus73aTc+BetDrbqf7d
EFBzwTbeJM7Gjl0K0WW35gkBNePesZpxCt4bA+xoofbFnIQAaxmOreSWa8uyUqkFEUo+3iP4h040
AgMcwdPPVuUZVs9OOtd6VIVXZ0K0Cgaq5jeNvF3sxN4yyGPFDYp3FRhnEY2e7iW/fwcXimRFHn9B
PPerErqsmIvPwN5nVHIn0X0gd/8Uu8844wDvPxRLSzVPL3Us1ms3vqy4kEXt2G7ArwD7kLV9n8q4
C0xQ2N9TgH33e5GqOYysudeC7nLmxT280TYcFgNw4x3cAPm3vLK3gXN7u0jbV247U36PwYPrNnyj
cyFpMW8tmSwhAuVucoYCzywBEbWXBwAS4iWZq90wXmzwk7PnLQTHkHK8NxNC6RplibXFv5mfm/g5
31Q1p3NGFXEjWfNzaVaT4u1PqW/y8YbFLY99YuxVKtkVZDC2t3zlS8E0L0DdKCT/Des+u4/cs2Fz
KX33TYL+a/PYLu9jlH8ptnfZ2/iocEeV7lMFP8D7DwHtD4g9lV3I4B9PbqztA883qox/tS2Y1w7p
T8tIkScX24H5HEXU/oTYGj4L+TV7Z4tzu1Xy3gXc+8rje1WHd7eKb1Uhm9/KmyUskIIa//ot/F7o
CjeQg7DJKnnEquAJFckr7yroYc5m5jtnoN0J2NnTq/ybOx9v1vkDRyW0+lybHRrzd5zn0HvvRC8A
m/Oy7diSLRCVdA7AaTs7aUm28vwB4nZ9vEMf7od9vDH9v+jEUCKNQ7aJo7W4pR/ue+jHa5V1WWlM
sNt7xhlnHOD9U1Fyt06h1bLU63ZkjlIo2a5z8k1f7ejoTCC3nUB4CBy8A5A8CtitSjFxw3RUzZWA
kgGDXKCvAPu2zWwkJnRLj2e3xxz1tvLbu/j9reJv9fEGbpoCYgDKt+ygmtFiLqueCPxvG1kL5mtg
6ktDQxCVNx24s0u1W3uCL5zuXljKygwmicENjrfbTbMkAVRFWq193oMUi1s8omqeJItYIsDhfzOQ
iDF5hMZtsebn2/XxTsE3ggQPTfq0egE0ETCTOQZEoHZKarjJ6SqhDiz+w1LgNq+emRmI56XyGgcr
heXk/pyf7B7WG0bP476Czzc2K/79RNU7/uoV/vuMA7z/mOG2/UQezxEQj0TGs/jHBRbZNmOP6Put
5JXvYLPN9w5g96v8ecwXWshZrfSQkEQt7Nni92Xc+wfgMJovCui0p9YA9w07ozf87qlnvz/hJzpS
gWfOG9k2odixThtx3tqKeatwn/GxI4ABXxluo/HvgoN39favwnGgz/cT90p3RjSvnF3q2jDF+dTx
PaZqXkWWEaRJIBS8J9VCiA+2lD/u4+3O49g8Jgnw+TXWvuEkfZLO4Wkf/Guf4PHhKX6fccYB3t9+
eBkfJOFlxhXrF3GoGdWE61ooLQ+q81ZwAPb+d4BnBYx/NWB/IqlCJZkCP2AnqHP5+DHluxD0lK83
Nh7nANQ8JaBWuXe3rJPeA7i/jY93r6x99xwnULN9bBgBY9RiLgE3U/oXoTVoxUyBIM7Gnbn/midO
rtHCEe+rZpAvz9Lym7RJZ408KkkQPQdWN41F8yUK70nq53uomrfBwi09Gqlqjnju6cKR/nnPKpSu
j3covHYHRDs+3iUF77yCP3dpQcwJandIAP1yYRAZcYtkuNFREV8SrcQedju8w4+7+vKZ33/c1i7c
oVsdvm59ZbvqfarXB3ifYcwvwENb8sD1zuw3ti06iOqun3XN9ma38m1bZDlVbiKc5ARZsRr6rp1c
Amxw8zF8cC198h27LaD2zJ73we0DoNvbV0E5/CnAjQfagIeANOohHdGSBNwRqBpQZjIXIFAk1yTv
qdUUtGNId77wSnfkETwC4dHrW4Pu+3M5lI1SBwuRgE6dyPYSPR/JnkjVvH2RqnmN0sEKqRDUI4+c
AxUuRFd8x2P7VhY02Ie2Tmh523OikFYkeavXxHUDQJk6byi8nZL3GWcc4P3nQWhjapy/iihY3FBM
fhA/FLXV/AUEfuDxDv/sKqB3j8Nte123ad7vEDRXLXU0YF/baVX7JMz7gwLOgxHEbLSJVwTUNpXH
3d3gC71dngTcZXE1B/iEse2e1djqta32m4A6tBQ4qQQWAo4uB9oTKxz8DWT6GmDnwkD3UIgFP+Z9
Y+jWi7oBGTSurK5YEhouSK48/79P1bwyhxiq5tI+jpKu1ztIuP+7Pt6eKNr6Gz/uCny8gyYWLHV/
DuoBBprF92HDcTHHtJzTHTXTBGvYSPvLfcfhxCJWYfye4kcpidI2zuNWzH8l53clf7/aTgX8Tu36
buH78L4P8P4D8Dreuin/b0hBdeQrzazKlC2Wu+27quZfAcgdqOmLnvXX0mz+o3xOB+CL7T2UvcF3
Mg0tAu47CaqvOO3nxdMMP+OquFr0fUfVHK58XlxxnCvVUMmcVLyKwRdhqTVXjwcl89gJnB2zO5t8
BtpSrZwl8KCtumTL+Hg+/ruuA1SbLnTHQ7pSAQ9eiFzV3OOxeyruLeCTI+EbFSEN1HWGoHgXhcTa
TR73G3y802OBPva5xR2ZUTj0eeCz6wIhSPeq7ow8UL9XZ5xxxgHe3yOGv6PVC7JgFpZ0tT2mmAxa
RceinrkGPRYrWUNCt3+5PVPFrqqMv8M3vG2qmlfE1bhwVSSSVrcvg/2MjZXPLCGiduCEp1z13Exl
3ALcvngaTE/Zu0mJR1vFp+gvFnN+qi3fMQ1rC0VDA2Ek90/0ZfcV4ghwY43WlT7DCs6iCJ47VAwV
4jlhMLdKESXzZf9YQXfKaRYq7bMWxJIc7Fq2B0iG/N7sJFGRANwsaVtWWq9m5eZ5PjseI6GUvkUG
+EbjStn5lIGkKoqb1g/FmGvbx9vlVzXdphKcY9ZEzl0S1nd30OlZyuLQQm7OGgZn5b8fn7qbkxoD
eHJXePgUrweuz3Xr6+9WOH/95FS4D/D+i0eRxW0tNIbMiIk7nUrGx4KyK2dT+97HvvANfMN5658G
twhl4IM4ccO+LHdKcRrG8G3ekRJ1cEN53AGy+8D53YDbxRh1cbX4+Sl6L2/fvyylw6kTYSuw6xWP
rBWdmTJj/1kF6BysfIPD9Cp08ioD3c+9aysgt9THA/sxB8BrMyaPXmOvG8BGR0ymao5gjkZxykDS
wVtRpG/2dUfgK1/3Cq/5eLOWcaZqvuNV3ieKdwo2y3UvzBdZ99AZZ5xxgPePRMFomTET4eUVqmow
AvExKDLAr6USnh9LVK31FbV9gbEYKD8B2DdbqBP7JExB7G6qhVU5UOF7p/fF89J9aiF3q90siHkq
2LgPuL0Szx0/bo83D+u2MUBTrdZHgCetOZmKQqljtNHWP4qa6xZzZAmEpdWUGnmnzgI2x7v3XQbv
UwWyXpem+bnABKDJtYcG15gSdsNaNCUBpHXYBiWDK8qbGzaeO91yD1ldj4Thysmih1XNmVaAKnlz
fQCRtAm405TL/Z19vNNtiGtfCh9Q43zbfuHk3qUJMbTU7yZUON+9FwXBefd6f4uR+XhXt1X/2tZe
cXjeB3if8YakAG5+N7cV2wGtO4C/enozUN/1DY9UzTMFczPrYF3PXJ+2KpLWNgC7AZbQatfruzz/
Oz7gZpC9VeG2UcnatQDcOZ4bFfOWVLmf8mI2vwNN8l5ExxTgBg3ixy+oGBlLG3U3F834ApVAnMNt
dOcEyvFm6H9+bvJU2Ay61+PEjUcdViJINSNg971F09zmoAKbJbUcoMW7n+6qmrdQbC28tjOIgwDf
zy39JR/vuvBaMeeXtYaEAN8Bq1rSPdIYdShk0C6I9SXxjDPOOMD72+HaaXHKoCivKlYESfqgUgdi
5e0ZR6PtVPxVmAVlGQC4nTtgd82g9FUVyzMbpd3vpsD4jkgaDfBVUJiomtugoSWRnKN6Dusd8QP+
uhXX25IIG2VA110AhWqa29prA56s4P2UqjkyUSvwGRhJV0kmTACtpK0vRMTxbtx6UEmbI2iTxvpe
RK3Io3XZyCtfEgjifVxFOlF6/gJIShWanbVKWpI5vGKzYps9jiHHvTwdOKrm6g6bVWAJAsGvZxYv
PezjXUPW8BDtcsygoBZdfT+/VjpUA3jy7fW+oLWwRh1D8tQwBN6czZ/5O3Ss/fuPN+3imXF9zfYe
Ujj/XUXrUy3/+vF/5xL8xoQBImkuD5xfQaDVv08VgbPKHsf28stMJ1T61i4zQTBGSfh18p9bgOYH
qmvZf+W6Xt9A1Br02mG8OP36nr721xj0X5F4yHV7Cf49z//9d+bdBwRrMf0nMLvCc/I44YVrU+0i
wBWD6ez5Ic8gvTZkW3JWAAcatXRaoOb87zwwTTtXfypIEkTI2TDX6/Jek0icDJLJnLPUOYMJibfM
1ztjPrbDAi8+5cCeg557J4PN27vmFxOKWB8Eouj2+fn1+Xm52Dpw1U/147naAcBzLuB/69fFttQf
b+Vwb7PJkIcGfRv+YsP3H4wOz8l8KtAKNInp/f33UmX7+wTtV/0gzzjjjD9u/NCKd1Khm1sIaUeR
EPVZuh6Z+m5WIS4CCNYOp0Axs1OxeNebyQCoNkYfsFe4zLuq5rxtca/d3Lt+zMBlTyRt/M+4iiRZ
B58qxlzgSSZd6P5hPNr71e5dAbW3qo/DqE5k0e32d4qYOuVx609r8P8j6E1UzR1++Ujy7mJpPr9y
9ECeo7BKrE4QJsd7PC6AiTyZOAhT1Y48m5rjjaUKT+dQ1NcH9n6gIC8xXrNNVXNVtUWE0yZ1+1Rc
re8QyMrrIBz2u6rmoBjTUTUP27/JNUGgMdB/B4b6uttODtchOlgTMdSuQ9PO5VqBtMH3522vY8wd
IAofyT2Xcwv18Ybot/CKFHC6U+4Xtu+vcF9WDbjac2X2y/xbu2/knef8pp88k7Q51e4DvM/oAi0e
DJhEoQq/D7D/XHF74YCdBLUSVKHAHfd142EALBaoRpUlWk1PRNJCGzJX1Rw/nSv2vj60nVZ0n5+K
PEFSVCRKO7iF17Z7fJl3QaZzjgKwhwLuJEXVg+UQcCOYW6DjzV7UbJZ0o9zkSektErEcwPdg7twr
kEO/z0S34iWunnOXNch7rQGl579/X8j74/iER++d55ONMEHgJuaiY3DXmPwJL6qaw+iCEgB88fHe
BN9NAdQdsDRtD1bCtrpCQCfYkn3Z7fdpHiYLfGo6O4fefcYZB3j/SchVB8SBNzYMThWwp+yIDPgV
OcytsJ3IAgvbyDpUArOCRhn8wmtPt/iJVI15XyRtPYbUFczIZLSbgH0MMWgLPZ57s7IoRQWZ/DLc
68R4CmzPz0oFlmuvcZejvZd4wPbxOSBnRYkcSmR2ZNBPDTT4jdWisV5vWiXWZSeMRGu5HjSSGBjm
cDa3JvMSkLwfQLLfNoFp7gkeKbpveWaLKjo23QPu2Ip9/hb+vJHuSYnIJXoDUZ5I/pc89nht0utc
a2Xl8fB6+9VtkU1qLetgCN9hpGtoXLBGso6SpJmzti2Z0ZwQgyTuzJniIrm27fttrj5Q+3g2ZbAr
zOevik9XvWF/9bpxCrtF6Gr1+lS7D/A+wwre7k83jsCY09pdTRCwdso7/ET7u1VpduSAO+bECoXj
ZfmCQjbkuGqWJjy4xA99Hb6XgJr+WUXV3KmYZ7B6/7RARSGRIDjXl2ZFITzODlrMI2XrANQr86kV
cPf5Odb4Cy3SRm+5BsxQSbQWcOnhh6MIlJYzgcqwuiyPbb51eCsFJKuA22tUizrXI3oNfGpHompu
XGALgCN7J6f3Bsm7G9qJCaDk+mjD9LSSx4Dgga95YiVfQ/3d2Hz2Ku/3GWeccYD3dwrJvaku4ZJS
38XZZ9auakwT98cka1W5vVaqWqDj5c6dxQBJoJd9trc4Mm9sVfke1dejooJll2ZzrgsV9qcBe3Bc
cJMkqNypp+gLb7L+2kTOFTDrKfF70Pm+5VgH1OBvr/Ja0iQR1LwaEV9X9eZVgwAx4F6NvAngnNXD
gwKbqUL/WUUfav6ovBGdxVjA8VZzsuCBQ3mCJ6JQ2TPmaJpXAPid9nTnuR158j4lq2QdlgI9T9V8
efBEokX+VlYis468pA2cOMJAvu8VlDm//9j8veLD9+A+0V1Iwq4X3URzvEsrIdyqePNz6waBHiyR
KP5kPNyF+/Su9br6xSd8vPtREMXbrXp/VJ9F1fuff69/2z/H+ben+n2A9xlm4BH+zSdd3zPshlcp
aAI0rsEkcturdFYWLX5BWJmLpJlArSiSxqskkFhDwfHoGfhJxe4dwG3/plK9Km0bpVfpScDtBiQW
qHhPwZu+c1GLuSW0xQJHBC3jmEWq1uNBJ9KkRdo4x3vo7BnE2Ioc72amSzNwucwBKD7roIA8uzdv
5Xi3GxzvXh8heXfi927FzeNDxlqdc2KTeqxp5dxJcKfgF4UNYUy6Te9p3pp+D2ChqVjCTyLGVX3U
rEXxwLmdkvcZZxzg/XMR6UOTmCX45c2a4Iiy1BZnBQHTdnFDqVu1Tz2hFu6omru+2ap9kuP8KfjM
b3iEnynweUrVHClgR4vb0jn4uPsSlCjZFhjcDHhFgL5TFX8ScLse925F2n3nvXPJztQRJsSTBe8W
VbgXATQG4jNQj7ECtnqDTwJ1CE5i+M34Xaafgbsc70ihfRI+Y88DdGNv8UGE8Zwg1T2R75CDmRR4
//h/1c4zdo+AQnPAM6rmYUF4uEccNCLk9fv8Y/mJa9RQ3M6YN4B+56d3bn1252uJdXpBjI516z1S
BUpo58Lx2MBvq2yd9yKUpqbn74Pjd9ZjPLDZggL51ihU0R+uej9djD7V7QO8z7gBPirzFJpOusI8
DlstHGjRGtMHrZ5NTQnnx63XjlUOuTCRUN0dVXOlhBsvS56quVTI/+HvxzantOX6B1XAbVl/WRXz
rCp9T9U8/Nz8iwvcQSLDIfA2AHil4P15aTK1ZyW2GYmpIerOEcnKNtNZChxvM5kzz7vUiG05h6nW
GTybMNuV7wgklSvg2N93xPFWmg2ZLVn8jnyBqnl4jyA7WSx8HQr2wW9nD6rYFQBeLRhXiCAc38O2
EjUe9FYXzzvjjDMO8P7uAXnxUyiFzTjyoOB0CbSKlTt6lEkl2+KNE//ZNMgJF48xIEV2DjRwLajp
phFv9D2E+X8WECNduiPOdXtEJE0C/LDg3bXKhs+8pxqf5Q6qt+6173siSe8WcfJC92cB944P+D1V
c//zVxDKoISnao4A767t34ir5nOwS626ML5uRLgN7J0MJcFHkSgMVTd3bv3Y0KaPd7cmSNCNVqIg
KAC+4+ONhphD7lAJHEsnNK+CaUxOkHO6M3dofztX1Vwzk31oOXSCO3hczwYt72WHBPZwkyEp1VwL
uGFYu9vKWZa3yVKvUSsrS1ml6ahaQtR5CsDve5ASctearAvDrcPfU1l/qur97oTGxz6u0i/8Lwuu
96+blBWrs2r2qXYf4H3GBoC4+z0XsKfbKc6PcQVdg0Tfy9sLuuzEQvs6kbRIQC5tz7XXKvx178qT
Am1eh+7amgEjCHJ8wO+rmo/AEUGvOD9XlPaj90GeYcK/Duvo88bXrFPTLejrp6GoWQiIuhZvIu61
xfGGqJJnzxr0A4shSaETJ/K6Dv80tQfwbHV8O29FzgUNoW88tvNo4HodtvolLACO5J2USTI5iTzg
Q437IuOtBUkveMmUrWUS8Y+ZEwz6RFd1F6fYfcYZB3j/OcF2HizquXuv4lbiY5vb+Yr2YGzaltki
JAVbHEcNHSS4CY8z4uw7QlhlkTTcEEkT/+qON6p7pu32SbIF1ukjuX/P+NHf+d4u2L4vYgaqJ8DD
NUfVHD64KKua73w+Joki4J5X0QlfVQbLJEEh+No0Ide4cBvjeMdFs4DjbT+jNznePV9dgG6L420k
d/r5LHTXyGphgDW5I/N9TteaFquaz/dSrSXGCzRffth+09TLjnukG69PXVjNexIQShAm3S+ZIwGj
cDVwTjbytQakcX7YLxoxIhxfeASJTQClqZgmRYwkfRZs1V1FouPNhDj0tuQ23EfvrWHt78hmXPZH
JZ31qeodnRqrYKuq9qmGH+B9xhcDamyKu6VRuWPd8hvV13PPzSdUzZ8USWtpEJZKa8GzHPvpz/QT
20VJlT8HLk4IEFekHZ/aVmop7oF5tKk7LBDn9xgK3rrFPEtQqMopMkElyfFWgBeKnr3+95KH+yKO
t0hizFxuWImT5s/J4h0KOzNSf/bclsydJ1IV/xbhDxCdLVNcLVir6on+EPWmICdsjk5EzWHdUO8e
Z5YIcW63Run4EtDW2x+4W8eahDvjjDMO8P55AXnTwjCsruKEyenizn6F2OHRDhg2K3Wq5Vrxu6O/
zd7Ymao5uu+FwUzrWy9/r/q6DHufVjVPn1bQSOcjWOfVhQV9p8GA7em9BJF5YPLuavc7QLdWW4Zp
Obaqf8F7XLxXPKuyuKrmJTQcg3b6ihZ/3zQGpW/NsnkgBNfL88JavXvQD+INJdqCJcfb9YMXHO/R
5opcu+EaghYOEXutFW7/3CPAWvybEJ8MCALwwXWaXCl2tIBw/O2MUstoD0mLugDfSEE2ApDOuhzy
XgeYPt7huiFED32hM3jHMSfKML3P03v5eiyzTAKS89dKq6B0lvgZoo+aWzK3KsmovvLPJyHEc7G3
+bs87w/u9bt13nsuuV/1Tr49fXGqen8YecP9+fHpPsD7jK9PSRhWaDMQRmiNMyUwRMu3adwlwwHe
Hhqsp8jEp1YxIx58xiJpQLB63hZJQ6B0ylvjogUXJij+Y572gjgbTP8gp1XcW9/NaCsreFdUzaXY
UxGQm8kJD9WhyeoVEpXpteSdtrVCgud15uEcbwQzFAnKG5a5FwA9GGU3Keej8HnN/Y8wIL6c8mA5
JiN73s0g+oaffbWLS+Z/FOBCpjuQAw8FzT0eOagAl6dq3sic0kN0xEC1MWAfrHe7dmLJCi/NQZLn
EEXCRfRcAYoq41t+NdMz/IwzzjjA+4fDT/HfyawNIqwRqY/fAQw72wEV5dmtOrbaZ6bf9rLvSCzN
Ul8PuHnsYDKRNLSSSJpedB8WSaMSzEhwWqe5upNR9kje4lL/nmp3ic9tlpptyGBl+PcUy1uzDLoo
wLKq9YXPJUjMfg9tnYOlXRcCaIj7ZrScD8fHeOH97wsc75ZwvNMUI+F4p7JeU5t4ZCYMoMRAiAE4
RL5Dc3el0N4D73L6WeSTZVckRdeU3WGiISQIoB9f2VzPOoPuIbRLAWqXJMJe5bG6X4g1EEs6mnh1
J0kG/t6yjUBWw7fepYKOC52rkhUlXceR5oVkVsTp89qLvm9t6Mbv353iUFXv2Mi7XPVeqtfuz6/h
f+X3Dg4+wPuMPSByC0yH+/E4h2MSwJu0nON0FMurImnh9+6KpGFDJA2v/00K5Ualq36//9CXxY+c
HBFAq+DNufWpjzZCrWmx+4o9TFItF9V0TEChF9xCcq2pbk/Ij4Yd8Srw9wHAmOgaIpljCAshxAbC
LG+6zFvMnzh1eEBluqLz5NAZi1eVU9zAFuu8c2M3kDJ53CxR9O2+E5Ars/fWtQ1Hx1BCYTwTF3ql
px7huunfB983VM2x0bbeWt74ztZOo4DvvENb634x9sKM3d1YR530GWeccYD3j4uv1awNEaXAs66S
auUmHzurlO8GIJaFVilCiYKtfMkFYAVrEMAkXoK4aFkoShTsbw5S3y6SFlVFETiIzgAGG0H6QmzF
px2QBTCzO/Obqt2WIJqhN+AAvAheRyEv3/STqua8WuEcqfc5mmOxZO/RKRE23oUSAu4sfmXOChCt
owbHe1xrGD0nfoyoCnug/N13C31yvJGB7gK/MQDggAPI464DVc19AoAD2m85FNmb1vLyOjoBeJB5
li+HCLN5S44giWWo+NmNkIASQcg1HqrQjv+6iTEXagcVMxh1TCCOHtKLO44zMtFBwPNaB7IJYYpH
0syEkyx5KMFgLEf+PONXvbWT+c4ZlDTEN8c1nUid620d4VytLqiYZ5+xQztc8AO8z3gyQUGDjLlN
GgEHeg5eVNDiirkJKJAcJwcJOshJhcJckTREjXxJiygFNSPwcIE+HFB/sumJunLCgRaJI0do3MjJ
baqaV1TPufibV01HkiCCeW3m73RBe9ZiXgB3ChCkHG8EtmZDci6SSIw53koZXYHAMZGyqcKkQN2n
Undu3VUQNR/vwYY4aAWAO4Js7jOTHkcB+EsbU9RmZfbKP0jxbmpCeETV3FRiYwrfNhAniYnsHu+s
hEuCmnnbFzaM/pKXugLPOOOMA7y/U1Qtg5DZ35NFEwoIggRULQR22YK+BHLFat9WZbvPCitl1Y8q
ChB0wsE8bQTB2srp1B640f6moH1TJI1dowQ/h1HSsIvPQLsokpaooauDmjmZVDW5OXGCL4nniD89
4du989s0RoQjmORWpecgPX5enlU1Z5+74m/sc6cl1eVAsjZ6kUxCRtPIq5dR5Q299zUDZ/19Y4C0
/80kUtU2Od4M2CshtxHMglSh73G8YwAOLmpuANJVnOo5jndZ1bxVBNhq9mb0fSBzquWNLhG3EAWM
VM1d/jUJnVDC0jBE2qC533m3/RKP+X0DcWISbeKOo5Xn/swUUPxH8r18WonXaZ2cvdUUAXf/819x
b2e3xu+qeqPdM/L+mstzPXy1z/jTgfcZNVAC3H+h4aXbgcr+gkoXItGyGT9XVM3VMhWLpI0LpCGS
NnwWi6StiYWWJhZArvty3H9TWr0UjBuSZibg3ixv8+fkfQVv+mOH7snejXtt9ARYEAG0uCKLgUO9
brrK8dYgz6IwNf7uoT+exkH3PMN8Qpm0Ejz/L4JED5Zu3dgX3eB4I68oM3XoOy3m90AxxmRHkcOc
Ps8ZTWlOmloTExaA7azBEfiuqprLhJ7zdfqMEeqHUXpfnk/EE+GudrhKQa/0tN2CyBlnnHGA948N
tFsSEcFClIhakAtg16pSJ17VEa+7FGAUADqC7zwhktbgbLMmkoYE6w6Bb2PcaZRF0sYo12HPIg5h
4amhx4mF7Ch2noNAtAvZ53iE273Du3QLyQYluAy4n+SPi7Av/7xQ8HZBPXZyd6rtVjxpStiMJxDE
/Kiq1UIggXO8WwjMh31jEmBzrtG0DcZlBTvWj99AJ9h2Od4RAAccQL73vt4B4CwxMbeju6CaenwX
8mj0WWfJT/DYIqR4bPBndwyrMhuwwu5L34saahY1czURAQngNxPkkOmWFnK8oZL6MBJ7maNNrIez
t+ond2GZklDf3M2jeo6p/lVJjojrnUua7xbBV+r39dhRn3GA9xnvyFGE4IVDxdkbmmaGkYUE4ZIf
7i9KjlRF0uj+UpE0yHwPRMAwU7kRAv0pCSHB/7pYI+oiPFn2PNAHfLAdKnevrRkaWn+1qnkTFIck
hNtuow8q4EmLuayWqer2PKtAeYU/wPEmv5s53r139qfSOni7MIChsu5zmgscb6HfIQG4y/GG947d
SShH3Q1zYsCurmdYE6yir6ragURhlLkCswCEBcBtVfMSv4VvCN4ssVp+tRjkpjeGJqHVftFKVpnO
eQcAHzhe3GecccZfDryx8ckK6u4F9jvf31JUDdCWXX0JgEJ47SIgmIiWhcs48v09JpLW1vbLMKQh
lXNPJE2F9UwkzWkhDKKi7FnpAEH47GL/TXsKIJffB8X7c3y+n1Q1n/3q2x3+uGNHNsXv0T5RBPVu
wE6/E+hszI/QBG5AuK2xF284rfCq9BzIz1xZBBxv8jvn/aDWYoi7pObfYKCrPOXjHdxOsq1djrcL
wK1umeA+6+MJbpEh2KYBeO7cgaZpTmlKhd1mrOuTJfjliOMFgmZNrXmz9oG9b4QAXoLvNCVB6rXQ
T8twxkbDTHqvhOy8Q6njau1kmxbfWrQwwaAppcmQ6nP2mAP4Y8jg2cHqx0Et+W7V+6by+HK0i0/4
qX8f4H3G1yYvDJG0kbfMFhJXtOyeSBrK+2tGda3bJoIgwFAfRktaQJPEAtLEwoRuRMX1jBgwPqZq
bl5zR9U841FbnFNfmU0CEelnjAT5RlZVdD9IATcD5prjHYPn6CQVxxtRy8qcgyNJhQFMgwEzzxGC
gTLG8W5YBS3thFTywDIxTBdoxwVKX4285JwWVfUDG7k4/RasIQpCWe6PiPNZu6rm6NBkApgRlLyR
BhCJcr54h8GSZmytBHLx0CwZEK3a0BzvIMNefgbPOOOMA7x/Jlrczu6j9HdnO1Uu9u4+l0XKPRfB
N9P42eCDi0BBcpnxgEhatL/mcaeXFLdSd5/DgLJI2hSAMZG0KKCC9i6OGMLLNm5Ybm7GGYX3ygvi
2XOL2kGUVc09Kx3kjb+Frk9s3Qtd0lDiaurguKg5vLsm9y2CawjQHIh/qX/T9vKE440Cx3sA5cw1
Qrg1oCE+pgl8LxV1dOrwCEA3atF9LGTmPcRbmgzRb9HGFnxnPkCmeg4vvyB1AszpAARbCs4xLGAG
ar3H1gCkoNBVNdcUjNVynohDUACfJ1+iSrf2NE9mb5XEltchtpTDhj2cEc3UluNiAfq5QDuKPUqZ
mfcH98l4j8v3RVe48bMHDwJ7hW9V7T75oAO8z3h62nxCmG0OPqAbxbwF6oZI2rxcF0TSmBYOxDYz
uxDMi3uqvp4vzIgCgUZQ+nQewzU96fXCkr4hMvSUqjmQAnP5edBrnhe8QYkQBKkuyQ5U8xWY32nR
Yh4dH1r87/Y+jncPoMPPPjnePKkQJkOjynuSEmSdC7A53hPAsBIsmCrI+uEF4sRJ1E4yCr75ybkV
gM8Wm5kvfOU91iJVFExOdpCcasWNySB3MO8csuRNE3GbOMzHS2gt62aABuFaT2PKeNyEpaDHgNKy
8DvA5BlnnHGA928CmDEfcp1H80oxW8zRnud1W4rP0fEl/O+hmoXXAlyjhXFOpyOS1gJRtqpIWri/
VCStGSrYrASNQCQtytaDIxBLfV1jwnwhl0yx4FnzKkPV59cTiapte4fXbQn/WOD1IVXzO9+JgA4L
UqfWUljCbbV2/HDf7GFCbKklN4rk/Z639wTHe/5v1TbLACeM5xoZhQWdJzoH3Wi4FdqPQmaggJzf
XxgZomIb+TJdbvp4L23JU88F6t0tTVbXWRJFaEQjN7HGArvXvwerljmH76uaw71kFTAPEkHAc6iJ
Vc3FbQG45guq1wpBuqS+npavm/wTjHtspaK3chrhKvIN8xBRIfqyfj3/N/ItX89U4DNudsrtPnj4
AO8zvlc24ymRNAVQ1f4QhBR7ImnB0tgrODceCGSJhWHLyBIUWn0dJKjXlUENgk7V2wwP0tjkQVXz
u8AdmayQ3gYqJfHp0sCG6S28VlGLeWYnFgHdeVvLebsq1xPHGyxxAXCNLZNjTjneUeUdwkFiOqYx
OfxGjjdJHKD51XwGyNNkS5QoKAGAlaq1AnCkom1x+qnRkrd6c0LOsuw0z8C30IWAmYRsAY0jSE7x
d1c858M7H7QrY+oUaHOyCVZ4kcY65D0dnvVA0POMM844468C3pFWCpIEG7BXdYs+d31Fo6AHhu93
up0toa1cbGYB24lIGvr/c1skDfsiaXPQwipMWIMPQdoj8QL038j+IvX1PrBoKrCrFa/NN6jJhED1
3XCq3THG3PA2MsGqJ6Bm/iVTNXeAO5sD+me5QhJftoGAz2wYeRs8zQiksXcBKtCucrw/wabD8Uad
463edcLfngN22g6P2AZKV8mFjzcThDLXtDrHu7UYHwdALbmv+THlnQuVEiOM61Gp0qtVBuK9Tl8n
gC87UyOWXXoOjhkDhHVUzZunal4U0eNrJbmViExKyaqa2JjRI4FBwyGiCxboZzo1iJrdINf+3cJx
mDAC2/+9JvtHf3kj8/FxJnOF9zJ+d6/qTfZy+fsff3eVrkFVyfwomx/gfcaXZS+CAGUWGdkWSRu3
uSeStmwoXNCGJXEI0PLEhTqTNbGglHXEcTYObKDa4dGaKHi3heQd1tDOoPfyAVAu740L3InAWRbw
vICaCezLBe/YQqi5SYGp+qlazHMOeixUpjneuuVYVpqnSvxgBTbxu5US+viK3uB4Q3kLay7zp6f4
Wzje8TS8PJutJsgWATNo1BVkBMYW8eZalzmwQ3aw8M4rmt9SLestpjqMiQ40jzpfUDU3YSo7HhuI
ky4zKuwWTWIIEnllUIYQgEexkpPsOuOMMw7w/uFhc5uUrkR7o8ETUgqWNFj6okm20kr3CgTz9Q6L
Iq7wV80CsKJIWiTKZoukkSy4K5LG2s1BjlMFAmZhfvx+ArpK6utToCLVcYPAsJ6HeW+12/69VDKv
Vrs9y64YdfDn0fbKDt+oAuCWwWMUaGcFb9H6WziGEQjr7QwA193upo93X4WeQbLieCOxPVsA8rCf
JzneDJQh1GvY0SHJOd4m/97xbobz6oIC4fk5CfUBgmsOx6i5AMChfLYtAMpdR9bzSsTVKqrS4Ild
BrDXlK6pam7p1ySWZuyALas9abfSFK1LrkvItMhV1gppQkYLx+VKG+zUTDKS/5zcCtDvfhdfdCC6
Gr5/JBd5e651oxf9JN/6le85q3Y/fOfP+JOB9xlvTmaEnyFV/PahKZ/6IpG0lbetj1MFKrQjMeOn
JyVoBErDqiJETqr7vmg5JhvX3cFnOt16+relu40HTQB3KsME47lBrU0+SQOFwXmF86nANAIQkPJq
t32850Rjs5MBYBXCskbYTY53w+r/DdamTaYsvIPjvfKi1WOOhjTBZtuHRQmWSvJGgXv1t0ryEpmY
2sgXTjvE6LMa0xViZfGWAublvCuKYEFSIXx3wvdr5HjTeTlXR8tvG0QBBp6y/hlnnHHGXwq8ha9g
Ynni/M35HDf2oZTLQdr1om0NoYipeD4GnVW8wtVt3yGSxhdWVyQNtAUxEslabMOi4yQBAwsUeFbe
Bey1YBAtVsTnz1IewHxptbvF7eBIKkgW7XO/4N0iDl62cemJS9quPU6yBvbscwdMw+R4r/zrhJtr
AFkQXnbFx3sWQ2Mt4/RcgBZm9hiQHqrd7WGOd1tBN8Q6QkCEAzjDtx6Ox3qKvdLEj02QcSrmpco/
EwzVADz0DA/nVA8YKgYBbPANQwysoDQdU7zdPawgO3i/Xp0o09LcnMK+SXlT11Ktj4B5DPDWOvO2
BBfxKZL3zd+ifmJ3Dm3z2D/0xefKNozftbaj/s32QlTOq1VvUrlWSuVZtbttn9sZB3if8eZkxhzV
qmjh3SJppHmc+aGCL/KOmJsv/AJjUX+FlE7+gh+BFr4ZxLfa6Ll8Ct57j/dXFLyd4NHnadeNvG03
M1b5jZICbNvgQDW148JDPt4EjMt3XexHcbytZNMTHO+p6s1Bd+Bu8FaOt1A+J9lDi+PtVsDbmGBQ
v+v/nu8fadIg9gxvIfhO7b6AAKSvWiCv/9+S5y8XmlWYzrNC2wN1WDTKs7syJXrA57Zn0CTvMDmV
7zPOOOOvAN5ztgbZMhJM/kA2gfqBVX0Sdj29PTuh8v5dxXOa6QUFfbZImhA/q4mktTH7H7XqIVYa
nVu4kbXFcyPvIDBSSEmLsiHTNccep9mtsvzuare3zSTIkmJHoF0JMsi0xdF4wMqAe8jBLgqPO/Hj
Itdm6qBlshhclZpU9oySGCJglvl4BxzvPlmw7KMXRWtEJK21UNxtFljrAej7ON7zXY2Umj+e952W
612ONxLngSTxE1TUKx1oEQAfv9PqiSjoZBLM+dTtCEAga57hX2pr3QutQCriiIOcyu6Gbzabryh3
G1m2YKZx5c+YniOxHDacdzO6d876xh72UDk2TzzIdwfx+Vt65fQxcJTe/TP5muHvT1XD+8/3jr6v
cENvVFS9pQs4NE6ao7602n2UzQ/wPuObDRP7pSJpQUzuiqQBgY9pIpI2bzPBz9ouqJEW9FQkLbqG
LEGABEyRDaEKtc9w0OtdVfO6uJpGA9ymrFkF77TVF8FLiri6HW2bAqisDdvx8TY43krBPBLtWv5t
cLzTVv0nON4DsMg53stTaHF285q3x/HGIKqZAf4wmdf27Dmd6jYoSyrJ9lRpMoSSNd4KWDALQbIQ
LbX/kAv2axqJ9VuipIFKxpVDDMR6KyFd/okZHzyecJI/Z5xxxhl/AfC+n2G700Lk/dbz484z+i3n
a9PFsAtCzDBr/l10ViHgiClcC0huclv6Pla44WhpiUWIpEUt5ZlIWlsBCIKzS6TkpYKvOGcew+yp
m4a8ULOFEwU+b/j8IefOuXWE3DYue2bYk4dNcbUmrcEcfj/1A14qbxFwBgXO3gXW75MCu2gZ3QSJ
cNZEwyDbSQXR+o6EyBoMyX+jvZ3jPQICvs2RnRO0QmfK0OlM+zDHO+t2KM036rtI2/P1Z2PiQ3Uj
gZTFvXZt0JnJdXNAWpEG7YBxZ0VI2F/rYmK2aDJZNHecNVMIFjoZt754UdJLBVaTjg14UmRc+1jy
APIK6zUcG3FwOQWRV72RS3ha8UXQtOoeavXs5sq2qnCzY9mvBV/kKKYdTFXvbF+sMD1Xvd3q9Ukr
HeB9xg9Lb8RLaOMLypZIGvM+IV6qTCSt2waCaDMtWs+iNalImm5x5os2YsAOLkqEjTt2RjPAR5Hk
vUUS9xJqDaZZjOzlzYBz04koKZ+/YRXFqsCRjzf16o053gAXXBsAWX6gFsc7U4d+juMdge4IqSqr
uGnuzEXNxb1UHG+UATMF4GKV2eVvZ0rmcVIv10pgFqYQby3r/EqTPfOrF4BSIL8eFHO5CmpLvnGz
8o2oo2RpEl97idD23Tx65XVy34Czup5xxhl/KfA23BSN6A+3ONu7v4XiLoNxyuOlkmYZHW4bA6fN
VEqH1y7LCwUcdFdF0lZwjQQwBTQ0JpLG8DPGADrCMvN/5D7fkE92GMAk5Kyh8tbcikTADHvEl772
TkGQQEMVc9XZAfFsFFVoJYeavCBeUdhoAa7Y+rAk1A6BPOV4m+emgms5z5he0fPpJrZrEKDrk+9N
2uGf4Xgb79NbOd58vYsBOCggt6zIXLcFi1ONQkeYJ+Dm7LNyjNF2Mwp0+D6BCmRbLIGej495bUVF
XA2LVkKY7MuwPZsb1ALVd20QzgycHSNImUTq8JOnfaucY/JRTWW/JRzzqHBRuNeP9OjHn/3eZEW8
d8apZq7brFJeH5f+k+J6/yJ5L78MKtkfVe4qV/swuw/wPuPbZj9MjuC8QAqbr5JIWiSGjpUxSU3W
4J2DK6uSi6S1sihbrKI+AQDMyY3ziD6+bsuibk1czUqqmN95BcWQ7elRkshTqx7j2J1z+wxkEQBw
8cyrQJp2kRD175Djbfhbz/PFFscbPMk5AMug7foux7v2HBocb5KAjDneua2ZI9hIn6MUuAdTsAnO
4+NbqRvytcJqiYWWP+c8YTtvJZr7M7pYl6jJxNVu2ETl0yy6jjTWerFepz5ZD9JBcCecWTneZvbm
jDPOOONPBd7YwYVbOLPGAx8Dheh3nnL56kecqHVlHpTJSsPFZcSxfwaVWAILvsj/WiR3RdKmMAQF
wpcMKUNRNkT2oIaKOqmg3BBla5mQDau23+jieOpNRfSy2tXupxJFkNcciem30s2NtA5CMG0qhruf
h6rpzSl4m8QFKvxG2sMBDeZbo4C8mUJt/ecVcbMw2RByvMV9kxxvSLBHdT4aLO/pOsf71S47gFep
IO4A8iaUz1W7810bsXrLunpUZ2swNtdktmRM+h8Qdl/ZHJMkmsZ7EXXlZOB7StQ1wzrTfpd0otgJ
5BDOdEUWMWZPcz+CBLKaMsIYY7wGz66pYbfj1tb89nk8cOD73tzPXcdZudwJH5mqOKt6X1tHMf14
LrVf+TVTRe1ytfsomx/gfcbPTn60JhTKQ5MP/usRBENC6DEhLQIVKMdOxItpyLmORdKQVcg2RNkE
rpLJgDPuP+dU6igSV2sazGqWRvwd3gnhW6FlwFkHsE1U1vW5pa3aoUo5EYGzON5YPa9FIgCOPxum
6jpQ5njjUzl6bmWPRSx7kHyb4x1su4VVYcO6CsHT9wTHe57nUeF4uy3nhtibNaeaAlqi5O1R6TIL
RBfPTG3JsyChuDpzlw1uz608cbR+B5Po2UQPQtv28AZYMmzmeJ+19IwzzvjrgDe3Tsr4ZxD2MvMi
W/lvJ7Dhvyt4EDu8NiZCZgIulM5rbXTOeOhy4YdCFePqz0TS2gKudUs3VG/7tD+o8tW0TV5pZPsT
banz/ixRNlHty5RAwc93R1G/8qyXUjNFrYSQBq0uSVrtDraNQFs4ElcLt8k2g/DeZp9nO4gV08W2
sZG8UeJKi8ds0GKe+T0bHG8KuBXHO7PxUuuIw/GuqprbrdR1jvdn2/dyaxkAh7jOjniZ6SdQBOAZ
UI453j1Ay44P1rwTbyWrpNZUzdVqE54D5sr2OEMhOxSRaCtP8fOzlNj3UQCOSuKjtbQ2nDmVhPwE
fVdo4lV2pQjf98Ip6YTLBtdbbQ/q2as5fDvbSLJEz2EH4xPmuj3zu+9VvckvVNUb7S6xXO/9evYq
n/FHAu8zfmyiJFpZQu9TsrBJ0JT7dClRtlD4pfFiHIIEwSxchNL+YInf9MH+uI8zfX5d/o8Y7Siw
6Ah/F3jYPTezauSNFj+Tw7OVZdkcwE1iw6iCTcXwUo43Fl9si+MdtHh//H3g16LWnvuqmu+pmj/G
8Q6A4wusYuJd80qw5QHfnuZ4t9Adgm47AL+Al9SbvzOYa4TV+eh4uKr52vgBkQgcgTHqFG8BggKu
GN4/r8ouGXJdXq8iSkmfIEUQvINnVT3jjDMO8A4W6bWqnHkQO39Tn1fUV8NVncWni0ANYgEX8vs5
KJXn0YjXLrgVFiaQCKwCZ+MNWGNsKJ4hAaA01I3x87KkZlWLVCRNdbmrJRwFvhy8AD5PW09CdA8E
DE5QHIV2UWeHe3xVRfS52hNtC2kyiHXaYLEV4gkjowFcueII4F5Ru2ecTgTyxBC8SgpIG6w2+oE/
nLSYZ37PGcebVYyf4nhD9WQzjjdiT+47qua7HG9HhE/bbBkcb8T2Ws9yvG8kGYLtvFqOozYL79i1
YJ8xtQO8SwVRpTYB3zRpFImGCptC8hmap1+h19ZEvNRKtYzPf/j8tEC7ItsXoBzaW8RMAJTkq5aP
oLHUO5IApmaAX+euVZifP/Z4LnDUya9g86zqXT8vQ+EcyS/+KVlvXlDJDT/Y+ADvM35yEsSbHzOf
61mMDNoXrFsYsYDgYbnPuLNBYMATBGp/IsBX+5t5f1gVjmVwcwrev+Ehb0IgLxOdQsv5mFycTQfV
LQCozQLT2TOr7IXUKTFVZg6GEQNui+M935f7HO+5DRtNCGY67bYzuG+tIbg3tOqtQHfFco4gzqTT
fKlMZwJltAupvYPjPWW9rKxahUstWtZFcipN4k98dLD2GJWED8EvUgCbn2Ukqfj+mm/f2TGvo580
Dqw6KMC9ejc6esj4DG5mes4444wz/mjgHVnfqnB2ozp9x/ebxwFIQt94AQViFljaroZEVWf5XbP8
M0G9lpn6s7b+4OE0NfKOWXBpW+J0rMSyC9PfPFE2EXBTUTakgbdMViQ8vyd0wd9d7fYD7sIx0MBV
eICnv1/BGU+uCBJebnZNgkZDnM19LpCpoOccb2Qe3w5tNrLKe4TjPQmRzYD7AY43my4pxxvoOk4K
HO+GRIV5nuMF2ILP8V5LvkYyJVgLXbXxpzneYd943/GRPVAxXl9XaVHpDmewpEPn9VoEXUHRPNnR
DqDMwKPKeeXd7iexZE5CYFcWNh2Jhcxf/+Y5PFqz10TJ0t2YxKKAueqyewA3dnH+1POAZvs19lwU
Amx17nLCLAbwLdne1mHyLzlV7/7fjIId8b7zcfF/zrLqwTlWBchdJfSjbH6A9xk/PStSEeGSHGhd
8Yt002fF87QFPJrM55ZQIxmhKn8MIGCyklFr6doe7AQkZ7zvOS8ZeW+SvNdYvaKaLgGtSByN/OJK
4kdYhUkw3Nptjjd5h+9yvFkFLts2u9h3ON6fXS+sNjmAM9jvfgTAwSJ0tMUeK6NvvXjn7+V4Izon
BYiBRJCt2kCAsGU/W04ksHMs1hG3mSPzXSUW3WU9s8emUXD1N0yd8lgW45sU73qnwBlnnHHG3we8
b2S5uIdvVWV51x/ZqVzzv5YWQAiVVENFJuxaXgoN2mOUbkeIrPXVq8/tRGqojLPGRNmQBS6Ef0q8
trP9LUAcLa8kgqmfZ4kFN2mgoxHc9PZ+R7W79v4mwbozPVSq3eJvCB50s+DdcpK3AZrl9hMwkXG8
sQJQB3ArNf6sxbzK8R6OCJzPHXpjR6JmSOzQ2lRBHardGMXpoud9k+Ot1o5PQbgdjreTYDFAa/V9
zb8TP8d7pmqMp869133PcHFd4LZ7r2vwXKxOnwvyYve3PlU1F5PD+By10BLRWqPcJKEueefPSrWY
GwhMLu8sYnaX7Lik/0Dt9IoaKe76iHaj8L1tBg5jjXw2GeL4eH+MKzhiVel+rOrtyKaX/brjf590
0wHeZ/wtI8fPK89xnqcJWMaiXoLMhYxWm5ke7cLNHnIbtfZNGmxE3FPGt4VXgT/j/Y/yMwVvhJZW
SyRd+a3LH28JrzwBKO/jeEO2j3++5onYlcXxnhJ8shU8SQIxm0gwbYjhemCa9yBBd1XVvMzxbkjb
1Fn1GuQ+fRXHO1NUr07KqWe4oLBhSswsUwIMS8zGREKRoRRzye3bkX/v4gHM70WbElzKQYI+oeV9
qwQMDr/7jDPO+KuBN5wFE7VFiCyaKPoe59n6uZUS03b5pC8riMvxVBXP+yA2+J2y0xBquguAJTtF
938iTlTIgc5AArMSawk4oSJpKO0PgQo2PR+QfSfc8BDUs+tV1iL4qmp3FCwpz+2gXUKq9wfPIQ3a
g8pfUO0O+bNNtfi2ULV8KVQGATLleCMSfkMoFLU8k8QzOwVTAccbZY43a4GeEnOMVy2CaERq3VG/
LeV7d6rmYl7lysYTEHd8rVSSr5+H8SaON53T8yTHHQB+h+OdAuYE3Efzn1Qux4YuhQLybbWKzE4h
6HXKS95bujXgrl7QScUsUcA0DCLtnjXZxd40MrMvFBa1P6YREHdPyvefB2Itr+8inmLxRj1x8BUk
TvzA2EYx5H/49Ko/U3bbH//N/l7bstjAFWzXPIm1OH45R3LGAd5n/InDYn9DQODZx5q1v08czQiY
0hZjxPsLAUzTraSrmFsaJlDAASscOePrHuhE1VyA59y+DhJsw+pzj/MdFEhvtJMywHuH4w2m9gwN
ktn71MDtwlKOd58IBfi7mtjdgYDuJZlCwMwgGIcMdPvh7H2Od6TUD3ty/10cb2WFFiUPqonKAX8q
fj92GmkDVfNW4CqTRoHfuXbQpLloqWbv0r3p2vQKPeOMM874u4G3uyiy/+4X8z0OrPpuXhn3toVE
ZUUt2aPlRsIdmiv6QLqQY/ZQQktIp1PAmgQI6E2+w3gCsfJwA13E58hoaOFjvtrw9jeDCoSKNy1U
yWYgKgT1JEnBVah5F4fz7OcczJ1qdxJcPpHqlpUkEaBHLdikitKaoVocRPhSWTwJ0h1EhfVhk8kn
RO0yv4njzZJKUM+i4HhHquZRu3zUqt+D70XV/PM9Y9cUek36mH8t54iIC46hev4OjvdS8xIV6SEx
YtjdobCmv4fjvYKwJwB4OaZI/x5KiJK5H22UH9Xza9+twWgziJIswbMk10lVNVVK2Zg45/NzQeOP
Fj7DrQlqAiApBE1owqQkbSAPIKbESJxA3UgQUD5Uy4VJsPPsOok4+C/BbczweoJ3K7tR1Tv6u7/l
eqiTUb3tavdRNj/A+4y/ZDjty2q1bRFYZkGFu4AgX8Db2oabWTc5xykXQcSB6xtXrDPuPNsV4GwC
kZXjn4AP6JbfzLrOeTbT9uICx5vbojEQ9NLzXuNzhKrmto93m5Nz8K7Rsh3F0WU5QYzcVcSc8IqV
F0Ouz3C89WPeH99dIbav43hrAB7ZiCHwFXesxlIrUHLBt1TNp14B7IK4BzHR0lIfJCzHd+9ZfjqM
BPUZZ5xxxl8GvDWnDPAAyFPe3FZw0GJ/xzjQyBZN8j0g3hjCmt3Q+jlkuIHgCFZeeHTX+uAyRgy6
4xYIAmDGnQ6AcAMoxzuz/6L7mH1+K8cJ5IB9vl4QwVkBimf2QHfzJ6wSodpH6b2lx5fUX7JqdyIw
xuh36s1hQkkpIGs5x1sCbgc+dNXGit0Yfb5NjnfY9p61mIvf8vZ30GtJE1LwhONYKzp/Z3mFrlc0
j1rSMdhxTcrTCYhAJOTYeguysW38aznejL/fhLQ235e6hunEc5vjXetKU4kGNQexJESsao5lwYsA
dqRqviZVKomJYEaCeHfnHRYS3hi65XSiIuo6G9dXltwcXqT1/raW2MQlV1IJ7rnXOyw0s8o3WqzL
GjG0n6OrVbjp7YEnsLSuTeOpqrf6+1XeByN3X8M/1+L11f9PEx/LfWTK5qfqfYD3GX/ggBtTcf3f
5e8rd3oE1wgCcGh9Ns2npWum12rHALsCWLFf8EOr5hlf8Lwjpw5Q0MMoCDlnF66qDaUneMePllAx
Eo63DbgZxnIsxJRYZsTxbowPzsBVIQHbCDBsrLKJLpaeHB1QS4h5CZo3cbyDJF+l40IKmUXnJO+F
w/Gu+HjfAA2hqjlbiwKOd8GPGrWj/JooQLqRxJoKuHn0wFe1Op9xxhkHeP9pAa0UqREZ+OJMu6ts
Pv4O9j5zxfN44R/sZowzxor+gvVRZEhJK+Cod4YwPhirzfpoIcsxTYJZ9l1k9WG2PSgxGA6QS1x0
FtQjBucKxNWetjvvXdRkAVnt9mPRoDyWyJg7NYrQfgvBb6FfQH1PVsAZgeOUA27fiAJIbisVgj1r
Du+7wvGWgJsp/SsuN2mPUdZAkcbB8j2oeXXmeGPRi8AMauf7uFTJW6qbkXsld5XIwcj5KznecQVx
dCOA3ZWxdlnsgbD7HO9Vhd9OdpDrANFannbyTOAb0s3AoL10OgWTAmCbK6pb1s3UrpMGLa9/Yn0v
EPLJyHw9+YBCxlu8e2K1yeNr2tIRFdjMxZHHE+bZ+ym5p2y5a/v+qrPFI9tzq97932pbTpTOycXJ
fLo9c3DyrVP1PsD7jL9oZArLIIEqFT0h6sBBRNQnDaSvNnSLeAuqeAzYLIFvqJ8D2daMk6r/EY90
VpzKwPFgPxWppEdt7KLNXXGzK+hm8XAOON6jV3VgL0Z6W1ogFKeqZEMVO6i6jq3gGP3BkSRZwM6R
JJ1mcbUZ+PbK5j+c4+0cU6aqvwjliZL3OznexOdOJMkVtSt/d8hDyw8vUSX/KfRkRBzvDILh6WOB
FmQ844wzzvgbgfew1hatKLHTP2ZUuPPFcuU2ct537g067ir2j4TiZUMZEK+gdRYZ14bdpLITgugR
COumhPmaQrSYksOSAkaCc550Ryzc8CwucNp7m67Qhxzv1niVv+Vq5U8FEzXP2qjajca96BGh0/AZ
m3mSrGLNQVNbAsC02h1Y0qEFbgUZx1t9HlUEM5pC1sY+J5UKZa6wPTxoMQ8BN+0wMTneUaeT6KwI
AXubwDdG5wbGWx/mOHKfacpECbmlPt59ggALEEQsI5/i0Uc43vzRnt7VCsdbH/PTHO9W/D0D64Pp
RDYvh8ra62FFqhcDvb7AO86XCgSOCSQ5hvxmjnoxJDYC1oI8ncsQdhpgebdnAD6fhVD5cPjr5GBB
7x9fawDzBeh1HqKlQn0QtyqQ/9xULt9KqDybBHmq6q3+3l/i+1zvFsuxT1VvV8l8+dZ1lf5+hj/+
71yCM35ewgX/mzqudPqk3OlLxZb4NSVdjRKl8d+PPxbdfv/sePqK9oWLHtO/v0F2nP8FGf1k9/Fb
QE2sZ/y89KEVIo4gLvp8jocvr3qoExnrej2+M0ECJuF4X8tLiTjgV3RY2YmCBcx+vE+wAmZ4cxEF
T1dgSTYnaa4xSXTNOYZf730ToLvY0/jxu8+5ZUoaXfS6j5POGkz6B/F53Nd6bT7ntI959JqOeZ5v
23htLjHRM47357NA5tNojo0D6cSoKEimDHP9v/ecX7v/vofyrn/MzMg0BcKuAdzfX5sTVGd9PeOM
M54dP6/ivdVdxPvQssqGN1nvtSLBDvQSjh+cz1Vlevpu/3eY0AC8wkqzzUBDcm6stTHjz4GoLWll
8rkKxfmWnEs9HejEuZ6fJ6T84aQ6igBUzb7cylYKhbekVLXe/Syrdhf+nlG8W6TRqjQEODgKKxDR
G0JU9QF2DEiUykG7LED+n7gA3oTJgErG8TbFsRTHu28xXyrc3ECYXMvpGMkHkAG8bhF/6WnEv18L
mozLud571Yrcz90R17sH3fMzMmuKRNoj0fX4So53+J3Ccdf8wOscbwS/t1820ZePEBB68y5dChAb
nSxLP3gXENQOjGOh3UVkzULwuIEqkiOIB4KIAUlMNb+bs4J64hxTsfFeRRP1uvlQn1rLFdlb8blG
sIc47tvMfT4LKG6My/j7vsq5uc9rTDhWq92Zsvnreycb9XcB7zPOCKfRiONtZj52OdctbtVNwVBB
Cf2Mv+R57hW7XbsxIswWi7FN2xb2YPVoyeF48+QnEAWIJseb0BZmXnczVc1lojXhj4f2d4qiFCh3
898YQo5FAKot4t7M8W46+RL6sYs2cuee5pOzx/FWvt6A2SofPmvcPnOcGv48SwtNI2T+6O+ei49l
yBlnnPHXAm/kKtQf30Ntu+sCzwOfjJOlf8cPe+XizeeRBYStsLhjCV8gwGnIPWKJ4f6awyywYQoc
IGyKGJhoCLxkOPiAFEJFyMttzKcYCTAJYrrIx3vlwWKxNBoOi3HroAPkVuB6f021OwJdRJmX2r2B
ilOtdZFatbuB3MSdajcXH0iuAzNsQgqeU3AeAAV1Pu/ieC86EhEIMzje1H8bpsVZD5p7SDMDqEHS
Yu6w4fdnEWlra8JjfQa6pAK4jkc/by7cyx6QNrM7IQCPEhC9iePN1jcoEcHQKuzrON6ZSn6m3dKo
uKA4NowkXrj82N6GEN55hZQLNj+jhR08OrKDfq+XwGOswoP6WhuNXxBce5I8ZGsryBqBRQYE8c7J
upAx8C3e/eLEouvSVecR9zlB8AzubPYZPfKvG32FG09tUJejgx9lG/V/darefx3wPuOMfBFvLa64
9Qs9qx4xEMwqKMp/d0wQzIAdy/FJS7FAufyol/+BzzMB3I5llwTciEGxpYbt+niz7SAIWJuiwwQV
cNPHexZIXCggxCUpC2yZ0jaWBAvGlncp3sYTsMr2cQHTIyJtMTL2Ei/h/aZV8Ps+3nnijWdKnQo6
Ehu5SGNO/a3led7QazwC5zoRtl6Hne72H7euC+HYd59q9GwdcfMzzjjj7wbeU1azZNNRWLFS5XJr
X97vck4XdPsVWxyId/YQ0mOEdMqfshEVX2aHxf8bYlGNTr6tft9qQUwqxjNAdnyHndVdgXNylRPQ
5HG8m616Li998h7439n5rOLbzavdEp7FoTHlAq/7iavd+llbEzAzGJtBdQuq5p6P8QjUPI43UOPB
Wj7eWxxvpa9xj+MNVfmbQW9kE4T1GFeVY/DnJOm4wYaq+XBOQkn9db3WToNBAZo8Fpl1l36OvpDj
PetKFxXLo4Yf9tkTHG8HfFvJA/rfTLd8+r8lnY+RRA2ZRuk7inZI3usat1S5QWnZIkR4tbFBcsSZ
3szrumCOj4hXN3u34KxtqtMx9gkNvglxjg/F0k2vwVvi4sWqNnZ/eGvfX5dJmVXOr1tbaVE5Wv+m
sqfruvX5GXwcVfMzfv6ozGDzd43171OZ/Aq2Mbs89BX0CxQ3X037eF+TTvDFzmHPn+KMn/RcbyQ9
KKUh8ZtWCar+b1cTyitXnEXEpZJfjSYSsyBVJSpY9f2fuIDaZ13Xr3MKQNK/75mjaP5L/fhfp4Gp
2v2QqjlLDPQq3jQB8WEnE+huD8EgECdGmar5pMTdH8ci1ENUzLOj6lXFV4Xp+btrMuWl/H0JTJQt
HqIC/XFABEjPquSfx1GUyB4U1+lyg79g/sNoNzh89EXy7afEfcYZZzw4fnbFO2oBY16JrKLVmJqp
V+FOk31ORbEF/O+IGzp8Tnovm1Dn7AGeVc2dPWlF2za6TDLJv+vAmfAX2c+wnsP8PeXh+3ktMP2Y
teBG3ssZ55pUoMbj1wu6UrqeH16o4+o5deAgB4VnN3t+658Fegu0nzPQREALOdzLc0f4h3DeMVbZ
Tqrd9PobVfOl6BeIqMmOD4Pj/VU+3tQbGuNcsbZh3+d4z++AjKOT6nwjlXi2JtiuFkLVfJGdJsBw
rnrPnTVz9ZzeQ2B0cUA0N3iq5pHQ3PgIPMnxZtt0dAzQYqePIn92k+PdXzfl400TRfPaTi7e53XJ
FPuXibTmDRO+8nT7emOcO51x+AnHOyvHOiRvdkTkvqXxXBBo6flCu27EMaD7aKIFJO9k0xD/hebc
6uZeeufsEkX7u2Diqa1F43p4Q1e6p6Jz+FV770/V+28D3meccTNaAAX+MxcUI3+zORzvOUOg99dU
22oGSg/H++95jM22VPpcMNE9YUkmkZDJ8WagitnvRC3mdY43wnZf0KBXBNPQYFsFzkzzoVc1t5JK
k1gW3HkNGixnAlchAAcH5BlADmXNJSiOpruI4+0XI8dnBIuYqX88GVw3kjBtg+NNZM3/9JkffdIf
BAh/wXxLIfGpgJ9xxhl/N/CGtUpmKqYZZ9qdrKPf+SqX2X5aS0mF3ZfpN5lfN9kuqDuX8vHmcfon
R0qImOnfFbj0U1VOCdm4wmRcGXyNukB4aaKZtsHgbkFwvJkiNwVWCO8WBwomP1EFh/lnJWK3/ghB
6FugeK8VCf0D7ucO/XP5/KxS/+N3popEM3UGPoFa3cdbVUcpgJueM9aNkVVRFy4ziDp/mePNAKeu
GI7K4uJZlx7gQtXcXRvEvqMA/zMB0Au1KdCNoJoF4/6p94x2RjQilMeTMO/neLeyYnn/LKy5A64d
8ATHuyXn3eJXlnpsq7VlFASNUwWLj/dnB9t4jJjiCpPh3UbLP7WGjY+xXOPzVrJgHhrn26E3rz9n
nt9a1efVam5xupv3rEqFc5QTUPFrnWzPUFqPNFc23LtVxncbIjgx5FeMD5Xz23uEs6fqkdW3cqre
fx3wPuOMm5OpU4BGIGoCHRgB5AuYAhihjizDCsM+7Yw//cH2S3Gzj7cE6EHLsuvjzUDV8jwnLeZV
H2+laj5Xu1lr5/x5D1IYAKN0AQb80FN1eFAOaQ/mV0wRqJqvx7LOjQqAg/XwotkJFkX3uefjnX0X
ZoJwBwCQ7b7bx3vrCP+wOa5hcv4ga/Jb938q3GecccYB3nm6x7DkEGvnsl3HvgLKh+QTWDl8JSyo
KvYzVv+NUC9Mtmix4BD0dILLj6kSgli8rNvPQLsEk09aW2eXKkvi3QsQBWLlYZpwvGl18gs53opr
L328FxNzI7Bs8fW03sXQ+gYUVvEOFOHFPT9voc93k36poWp5cG15QbtWEY+qO5ny/wA6qxxvJ1Fl
cLwdju7r+Y0EvDKON1NTX68NoP89Pt/Kh4y9E5OrBNUI8a2t+JrEK+29VzgFchPol++vvH+e5WJL
EiraaUH5v/nPz32Ot8hPSOCMlLOfJUVUoiRTO1/Wv2DRX/RSYIDSiYsdCxtal3mhqShn6GxjiAjD
r9a58TFpijJmHnwQH0HEcTIuNG38VIKwdNGX+SdKUrmJJri7c47I/zmyDzd+tjEeq0QX93nrV9d7
JQY/qtnfuer90yrup+J9xhkiiODgKBGLIxxvRD7eNPkSAJjhV4fj/dc+o2HBO+F4i+9XOd5CoXAF
TNBg/vXKIAbcYWURrdHzQ56Ea5Nvel9lYzaIVLhqAsNY7cPipGsbwDeM3tFVYI0F81j55sHtm58B
BsjzBEsUDcf3lT/Xz3K8GahGBKrCRMbDHO8IgAN/XQV2Tsa3KDnx+PwK2tZ/xhlnnHGA98OLXxjp
VrcBpTSeKaiKgDFVJuetixH7eKnUhhnbmZPavEwtVjVN18cbqecxCXZnv9s27nNWP0cEphNdoq/m
eEMJM70OqC23HL/tDdSvDuW1JtsJIlXkYb7kyYeq5TS5wu9z+B1REefFenV8WCu6Cjwb9x6BuNj8
zozvvahgAikeY9oECoCzauXqN62UwLG+o9MUM7aiayDHjqsVVc3XdmPCp0RwLp9gAwnoRjIrG8+J
yfFGyvHuAHzUifFbOd78RL+C4z10KAQ6A1yDz844dMmGCEMSH2/G455oUlb2o9sOTWJjXRPQwO3a
5vmwQVTBETzb4PNRY90CWN5fSJV5hCtaNldArmWRwvmNRb70s2SxteLA5PzeEoP4u/gdNdPrt2+A
b+LacA7973eH6/3XAO8zzrg9bQNBngGUzxmsLc9yvCFjqcPxPiMNqJi4HlDjeKtkIZD97WUdBkAk
3WJ1aVfVvNLiGdnzDKJmRmKVC7xFYpsBbaisah4kqxZP8RHsRQAcAActtzjeMXefpsu+hOPNt5sp
j+edGEWOdwDA/96pnmstvLvaPfzvWVHOOOOMA7xz4KS9sbNtcN4gC7ZYBhxT4OR4XzIPSYTcx2Wv
cnvz38aKH4Itkcxy04vfIErUxxGWj/e4Dygfz7nSIzyrw0B1jk2Zj7uqfKbg/P0c7yY43kNFHKTx
llHnTHsq58J6HvRrMOkF5416ca/JjqZ9vhHct5YBVQJM5vOkz+R8vuCHlD1XwUQ2+Mk7HG/UOd7D
/ATXQapv2dZ6BCsAv6dqPiqQE94nZjVg8OxDW+dfJYZm50KYqjlTe1/m/bHqrX2/sWex1VbufJio
IWsCZxR0TgtAmBjKlNa/huOdV7odkL/D8Q7fUZOEje4dwXTRlhV3iFO8a5mQvGkSJ2USy0WgaS0a
sGeO/CYzK2Br4cyhD+4rksuCrCuGXPas6o2w6DyKfXzOB8ZdzZoPk5RXy428xXKef1CORZ4YP4rn
je0NlDTMT9X7ufF/5xKccUY+n+PC/yacS4KVa5oER473RZIZ/feIwN7H5PUZ4F7D7//ZX//fZ5wR
gWfLOi+IX/pHcv7d+PyJ6l6pxTwOqNDySiYHUP05dO/U/z7og4UF7E7b+fjux39/gJx//3vgU4Na
I2mBxGtMxlwxOLsu3XqMj9PDf3PTP/NXAw+KPn97xc9Fnzi4hvmpfV7Y/jCWXU37AYnkPo7ldZyY
QuFr3Zwx/QHjdtBdC0wHMj6PlziuloJx97vxPf675/bX+1NB0beXe5tCcsYZZ5xRHT++4o0Br3iK
zDYfCzMrtCZuAjWZJ22CkUvQ53nav1Oq31gWttDvu7VOXXc8B82LHO8ReK1bBntj9ZZnRLna+XRf
Ce85UkP/2C6MSkrM8Y63x64FZHt7ACxIC+i4XzfIQfGz56vd9NmFAmCx6B3p628t6JLgFeLoNSIq
+fN/glfE2eezFzsIt29uyQ6f14c43qMEhGpDVxXupgtqCwCvqZo3oxK5qpo3omoeK4IDSpG8kWp6
cK0J6F7sqQjQfVFZMawjM+hGuye4yNqoEXZa8LVyvG5k3k+oDvx5/lqOd/gMZgrlZmv50PaPpDMl
ElcL2hyk93NLKnrCx7s1LNxvS3e7t19Yps3ubmOs3A9z5LLm9kr/S6ZRPkeYJicY12645yIzyTu1
3M5L1lUZ3017u10HAawFAvEabpwG4kUlP+TWSqr05YD8fT/ZHtcX//4q/PAzSWn+5CsThj8xOXk4
3meckU6+sMEKqBAKacEbbEraBLqDhRVT4uZk489I8233Od6RjzcDD4r7OyeVPj+Fr2qu/ZO1gCUV
SAsSPgBvANfJpFkxu2/LVxxvcjzEdi26warlHcNxYLlPlSRbDsB5EqZlsNbk5SMEIu/ieCetttBg
fAFqRSDuJkP/prUXUcL98Z1Ci9qeccYZZ/wtwHvIamT8IbicJc0BQ6IqylZyyfsLS8KQII0FHBC8
N7298W8D0IvOMPTbJeeFbtvSi1zFOND7YGACUyVb3RjEC/VYVRbnu15WuY+1YzxRmGaXfeDfcf5p
ZvPzOv4ACCm/UiMQ18FplClHUO0OLk5O8W5zFWeomAjqmONvvVQnm1HtjrjbgvsdJnsExTv1am5P
cbwhfbwpUGjMEsnjeGeq5su8hOy5Zd8B7RyKVc1Hsa2hwipfaBXMgwLtGPBiEmzj640Dqm1A3bSw
GKvQ8231CQijeyjigWNWgf8qjneNMx+B8+yaV8E25SS3tatmViiPYpNN6/LWpu40ZGt6os0C4pyy
do2whYE/F46uXZhQabybprnJuVTrhsdfERf6sXxAWd1c/Uas2exMzGsUBHT5dzYTPS8K1RNbLOKc
3R9hcz+X3u9cSP7OVe/fsb8743C8zzjDWWuueLJeOd4Q+QkGVKL2UBAQWJsAzzjPb43jDfkOKP/h
NEovtJjnwCQHWUp0rQ8eLvGCY7JTmveRcrw/ud2gPub9MV7DSV3j8V/JNbhEku9z2/8d23Xh8/v9
92aO8+va8KTUNV2jkC9OAqHXfoJcacCl5lz878Pxzq5NFVwfjncA0F1R1f1dTgnCv8tD/Ywzznjf
+HGt5ij8dXtrQ5YzCfhQnPqF4jkA4xiD8moP0qSP9fxV5YmrWkxHP9l+j9lxrzZbCPnaszd3tACD
eH+zKtsnH4yoI1MwkmbJx2ogVV3vtpe2rH8ef1elQOBf3ZfpVMmOmpWi8Lj6Xrd68xETG0bQpb4a
lrTX/wQHobFGQVDZxPx8xcBz1kbg9zT3rpfWdwHlQd4oxM8G+nfM8Y8ldlMuxzuz74LiUUYpBQZ6
aRUrVzUfeNnkXiBTnGYtyS32Zu5V619V7+kxxH1O9/JvNKpIj2mVmH/Dt++3rmffobSG38TxZu8P
CgDN5XjnquqRqxz0e086PoafKL2D4hoyv11g3UL93Dd7vy9BGGvnX6Mg+kwOgR1SnnsLHTaEqGGS
VJJ6EWIRq/pey4p7p3nBHSjqcXPt2DyG+U5kv3Ho1kffv+p9lX/ocbuF3vk3q3r/1MTkqXifcUYe
vo/VnhYonH9UKTD9+3NFvHRE0ib7qOuii/DV3sxxO+MPf6ANsC0BAKxIL2rtz1rMveRPFPxC2kIN
7+IlVM27BCnf91g1/1AYH+N1aP51cL184U4MzgbqO//9x/VZ9Y6q1JcqRTP18em+fyQjLviB4FwB
Z/saj23tTBiCQQJW+flmVfKP7oPgeywjLyr8H8dxKth7ay+do/CE/ZS+t2sy/16S64wzzjjjY/xs
cTU0K/PqC50Yk+osgjP9fdk+ENeDIcS0LJGVzP+bLBwtPu3BXzbhNwvm5JTRTrL1DRkOWAJ2VlHm
55xwgsCq7LwaTtXSW2tLCYj9L90/r8gPz9LnQepM+Fz8lvu3neU9wBMVUuhvEHuTQiqP83NOjbyb
o3ruVbvX7TdZ7QYFskg/b/KyFSuDWcU847Q2xRWGdb+rHO/QJ1fyhvnnMKqPmOfsyRHDUTUfKukV
PnWrC5cN3wHvrJm39UQFXPOu144GJqLXdyqlwnslwTdeQW+iCv1OjjcViyvEILv3KMT/YF1vCE/m
lfjK1gjPQzzrJnPmtTZ1mIzvED/+OI7I3azpcxo9O42vUfFVKyqTG8KCT2qsQjjfRE//swrkSG6X
julg3+tCwqf9HirfRf7tFKgvd7tXssNoG9+c6/0Txql4n3HGRvDjcPhmbiQIcBiXjWtYdy4m8PdR
hlmyNWdyO2MnuEooL/89iF1waobjBY53SwB4Cj4oeET6m2t6pcZkgVZHv65pHvio9v6qan4mFPBf
Xdq1uJw51LmP92VwvH9tByt/W81hKoFxzWX9i+Ckaw1er4knnell9L/po7u1Ar2Ux9fnj/K473O8
oY/CKamf4QIfaEB4H+R56YW7yZIzzjjjjH780XZiGW8ai6XNXrDnRLhD2yGSzLLKsjJlcvDjF3qb
Q2DZlgx5vK59ZpZHJNiQl8NF+xbja6/BHFVIZVVpxd8m/4ahkAmH07UECKJq1ma7oJbdrDbdIg7C
p0rQWn1qqx/q9KzZvvYtyK5HDwH8d5D/S7kFTLsYuIJkXwvPfj2WuNo9f4TlvaT3Pfu8uT7eGqAu
An2MusCfLqJW3YiqdJO/jx4QhPMJKC84TGZRDrqu5EUgHG20KwIibuZka5U4TrCD4fZhLQDPouqN
9bnov/Nlqubs2jOqPMhCQirgqdd3M5TqW1aFfp+P97C2J112IZ+fPauJcGBF/Wa9PpDz8npvEHh8
G5k+WQ1/TdyIKq30GmI5ndHRhWvZYIql6P1o8f7UWpKLseX0mHnj2R2mPP1mzttJGqKkITAtn0gO
IyMqVTnu70jK/M6qt73vJK+pud2X/J3KH36HqvdPrqQfH+8zzriX3fHWNyAJAkVyZFpVAQ5Lj6X3
GYWHtrHWShar9FQQIEDlZgvya5u1CrdMDhXErfQ+iegPS5DNvwNowIuOhhQfI8uJYrH6y64NB+0T
QJzav+cEyGKnKO6dBAoAV54ehLISf3aK4xeiRoj5HWjxTh/vJTFqPJdnRMANNGGiBHDvL+fgyehz
/84444y/E3jrpbU+IU6VQrC/k0U2OBqWbsQEppbFfSx5tDkbq4I9uVkWlA3nmPhJ9tVwppIeROlg
3slIL31bq+YaDCi1cVqJTqpRrLoIVQ1Hzlej1bWm+I3sN+p+11TJ0TSgyosTCKvd/ge5p2dQhF2r
3YGRd6ysz/fFqt3psVMRvGmD0g9efC4OnVW7o+c9eqbnThnpmTz5f6O9l+NNNRZaSwENV7VugY80
998F6RhRD+Xsnw1yPilQAxbQPYMIBm77ijsiMAfUi1kKgBuq5gq0K3vEvqsA9IM4cSPffzTN8W7t
S328K0oamaK5R7kgnG+suhNOUXpspCjWGTFZY7rPIfIPlXp5di0quyoXdEl5GeK42Xs/3l9nDoH/
IttJBs3pLrlHoKWtFXC3FYcR+Ry2Me9FcdTvHh4VexW0lL+7mrXF/LPvxfX+abXvU/E+44wvSBTl
3dmsCsaCwsi66mTjz9hPZdJgY7IZZN2laAGYYkmLpMVcinGJCmkTgDVMKGVgbAC98Ts9KJjP1e6m
j/kFFrDYi2VAgoLuGQjN7ckQwJh3G4eBKpJ71mYQRlvNndbuqH024EOEImuInzdCUai28J/Z+KGZ
aera6EF+l2l4bq8A1WI59/KMM874q4G3ihPc7DEs7iJffNmPdPWJ/5X7Euc5wZi3PZ8TJKBjjFcg
8LPu/4XXb7sILDz3nmscBSUY6sFIFZHptScVazDv8MzHG0h9lNHWyhcyb13SwhrxsiKr5TmY5Dca
3Ge6DNVjPYLY5zpJMMhdsGo3mwdAAGAErNS7gxZWu+fnJVAep5Xnprfxbh/vSBRtAWNv5HgvLc2R
f3GLOdwzwIs40hSUT5WoiOM9bourmnst842uLQtAn6p8rOoNwSeuWJIpAE6rlo6qeXgMHFQ7iZu5
oI1W4XjzrIKvou9xvGVCRDmeBO90ycc7msfFoqISGlQlvT+HfhvNKx727zDrWPugImBC1UAYPSXP
mK7bo01dEg3B+8o6THbWzzmu1FvI7iXS8m8/V9xNxWZrQJY4aaGjS+Uibno13MQaPyjlMpHCF464
rHbnopdyl8fX++8B3mec8SMSRKlwVCT0pdpmt1bBM87YilPewfFOW8wF4KZVbMk9zry+g/fyA5i0
WB19BL6YAnusSb+2gu6xvZsB60wRHQFwA616sySNpAM0zvGeAS2jFb346rGdWP/88DbXJzjeaK3l
11UBrBD4OgKVZ77emobYK9Yn7N5yVZfEOA5H/4wzzjjAW8SJdCHkyqAqCFPrZMT9G8OV2asXrPLX
c4dp8Ri0nXjOOGdob4wxkPgWj9VwL5U97mfQ1obg+s0B3VIWW4P//jNZ7Z52ki6SU3UR5EZJH295
vEK9XYLuhOMt2zGD25FUCvog333LIoVZGlKXqt3ME1R8d6B4okUkMKpSPl+tULV8Pk+8pdp9y8eb
VC3V9ntXAgST0zs53rMN3hLgCsCNQAxMq5oLUNo43zLy8V4qpfCqVxnopiCerF+8dd2dL4L5WwDw
QZBt+NykFCBfl6OOCe0NjGU+yjneKskCo0uhxvFOLnER/xmVcfVcLyVtJOcyas2AL8ThPESfpaR9
Dy3uZFkTGHkyDfO8Nz8jEmwnHAtTvd3j98f3AFlS6LHoGYW/x8cd1u6TeBrGX+6elQUemhE7fcux
2i92/5NXuzeUzV+ff33Ve97GT0uHnYr3GWe8LSkU22KkHG8aABZtjM4448Yz+xTHexQRC1rMI8Ad
CKXNIBmJAGOUVECXLBtb0bXS8apqTtqBCegejQ8U0AoSM4FdUX8cfeVZiutJQMNt3GxV86CCmD0/
I34sAKM0eZOcH2BVtSsA/IxS1mFJuAzvoguGtxMewibujDPOOOMAbz0pVgGJroaveWVU90nK2lo4
MrO9YdX02dMmWPr7gKZCWHoZa+cZ66Xij8RftY0cV5AAM/IDboGPd1uD99THG8LHe+Cgje0KTvVL
xYjskgCwOd6SrTZ1PETHBqnorE8gkEgwBOVai7QNEBb62f3r+W08qTG8zznFe/S5X84Pe/8mf9vy
8W4FH+8WOCSAPB5PcbyjYwa//7GllAZFDJjR7icwW7NY1ZzNNcvKAA5+UQBmUdsypnlQ8ddLKtxI
RNaSrgBFKYDRISEBvQDg428KHO/QpSGzN9MA3OF4Z9137P9n20FBel0/zcrphN+PwRZ73q/VWdZW
JwCsL/48Py/LfhJ3gS0JNI8IHSo1pusgVipwLR0QinPWbbgm9MJAzMw75FxvdQsV5SNzUHXX90I2
ON8GzA1sH8L3Sbxc7vWSRuCX+OLW3v771m/kev9EN+9T8T7jjN+cIBo/yjjeR8H8jC98ZHc53hGo
C1rMI8DdAqATgW1+PAF3vKt291Zlc+V19BKe1MPZ/psr6EX2gwRofLZnYxCrGo4HoMAYJdpJv/+E
452qmvuAXlEJIqtPBcDXToncmlQB6CxZcsZTS+fk5/5GjjeCfZ97esYZZ/zVwBsPzrxIKx3xDC1s
k1PFc1WvnJXJ0ZidlPIN7yvgMy81L2uDeVvDUDxv0D7Gy3F1a6jgxErOtevjHfx73tbMbVy2HQhC
ySpe3yFAg1ZVmaWa8EFoMD6EA1dT+E0vPFy32o0g6Iko1zTrznxDwfe7srozinf8hZzivVa7Ax/v
j2dJXXemFYBAvjf18RaqsapiPgAlcg9ld0fwzFkVwgURCY4tEALuLGmlPZiDNmemau7ROAWAB0kA
eN1Rjk/7DN5n0J3NdzSpkih2V1S+m8jL6N+21PZNteJH4ldIVNf1nGR4QU/Pj6tqj6L6vR2zsLV0
UAlvU+yA8B16vbMvJ5JO7TAMxgYl/NmhIZnfeOJk7UIZOoSgXC9I2bkVaQKEEw4xR0Gtw1SVv08e
BPc7SyxsJMuqCQe+NOXl7h2/9FG7Jd6ovDJ46KR/8kiL2eoL+8rmn1v+oqo3+90/f/tJle//O7mH
M874DkkktGjq6OOBqx3K2RlfnuJsSozK4bTaLeZBNpOD00sAo/y3n4FC9999UIvhnPH5vY/j//e/
/zmCf/5wfYQakIH0v9+9RFLxWo9j2A9+zQ9Xf9WuEXT35/35m//+/M9vf/2ij1aGe/Mx/6gkwBjw
rAmNfzYzXsdf/3P9B55kYDbsbzyGz2Oaj4VEdgzczvv8xDzdpeQUgV/XTQWHCcj+KbY2P3Eaerta
/PQcXSOp/NyHM84449b4oRVv7FoBTosrzGpEH5C1yBhYb6P7XyYMAm9NbzSKaG3wwUTyG6WGrnh5
/e8+gmNlDTQH2uhTmYYX77zNkVu2VtNBKuyZCnrGAw+u4lIRx3DtREWmsZZUfnOoCJBqMwWpGDI+
4RK4uO9HEJAEDxisIDW+DvS7/RtEnuexahlwwMGPmL2bYSV49jyen1NRHR//tnpnpz7erLQQ+HiP
GggUTi//m1Xc2bNKDmOZV6go4Fx5zHjiUh1bcZ45L7u/Hr0AWfToInq/pgTFq9Vb8YA5qACQqBqT
FnRa7jcAhuo0gKqIt7BKHKvD+6rm9DdhdRzh8ch1ELUKfIuPvuTVnV2f7FpGxxFOqYNqPJqLYz/n
1cnD2+mtYD/A5G+yrDusCyVjWiFxjFgESkGlHdCi2LAZqx/0Q5XEWXzd82+w2hYm+gx7kmHtv0Ty
btrpBObWjVK6nTz+k8csZS4+lzXpfWXz1/e+lut9/dA7eyreZ5zxLXJJCGe3AaidasoZv/Ex9WKt
KDk5tgZffeU1bL9ek1TXZYDa6fPPSvVwBteUXGuDcNIL8P5XSW6/WtteVfDX/8r9NuZP/KrMzvX7
V0V/rFz/Vx1fr22/71dl/b9r9NrUtQZTwPQjcYOvaLr6VdGfrmOb/jaf87y7tQp+DdfvVUVvy/36
PM/WPxdrBf0jUXb1F2n4bvB7csy8I+CMZ+cdnoB5B+d6yQFOoPWMM8444874A8TVHlA0l6Ipxp5B
BD/AxVp4ixTGSqT6+1zp68V+xLWYaV1ookJCytqL/3fCqel54apCwRbNhbNteFBbFWq0knL5qnQ+
3ks0LXC0HMlwLchzFQCJBZiQaz/7XCNSyRcCsvoNUhzr/lO+BfoZgkx66r09n0CggByo5EdYgmb1
AfncRdVoR/l8rGg7yvAIp73Mx5uqbzft403PAbHgX3QDQUA3veehannoFJWqmi/dTUQUrVE+qTrn
+T0mCtSN+W5z0D38Vu6XXy/pVQ6uVxG+a0yAjLY8JZZvDR7HvKBqrub43E1Mrx1rZwHETKEq8DnH
23U/cTj+9Hvrgvaa0TAGArbiPfr3dixTh0rbGO+/4+MdIV4mBci5/GBTWZujFxjP4xKbRMeKcX5D
QVkbpFOQAntj23iK712ouvNnCel20uI1bh7wG3neP0rZvKxkniubf9eq93wbf0IC9FS8zzjjm6SP
HJsI63tnnPElT6xOekTWcJEtUpz4XMWwlF93CFz+qVZ3JduFWz3tvFcG/+97ePF/zQ4UgFdQh4o3
45p//gbtY3ftEoC/u+pj5Xmqek/fWQreDsd7pi1gncRmUc/X7/m1GI5pDtp+8ej7bV7jDWuvC9S6
e9sGfvnreoSpoP9954pv7eF4/4YZB18DgJaixJUmRM4444wz3PGzK95YrWYyX81XYEbDw2UpVjxt
yXdFkuJFzKfp/xvCV3YtCE6lPUOB/BU8ZTwiQjhWVXR1KUV2l33vY5trdYpXtGcuFuVvExEgGOaQ
CMq+Az/182/quq3BKgI++FIRW6V5ye+ix0/fY14pS5+Eddu213dc7ebvjKRpTz9Eizjg60dE8T2o
dq8/42q7KTebXBvLx1t93kTlG8Q7oeLj3UZng/lDm+OdgO61xbwlatOi74LO/VxZHPP9nn2UW0tF
2rg6OhYbL1nNDPydfXn16HmF1O5gz8yobA2vs4BWxZt0nijznJt2CKHq9+K4xlZhiLhAKePr+TDy
aFexR3g9G39W9328RfwALMmTlkQCch6xUnSTwwoPiTo18zaKdiD2gcm6/5Y3A2LCG+ZPFQeITp8s
gmLrNrEH9brGoncna40P6v+etPl8p6xYI7A8qT6CzWzcaHA3/AOr3vmYjbyzGnSubP67q9799z6o
Wz8xyXkq3mec8dOSTaeacsZ3eiSzpGgUik2V50hsL1ImZ8FntJ2eU728UhBJvp7P/SsKmdXRs8BY
AedP4vZQef9HUfnXN34d6HW1RS2R2kUu12UNHC+lai4SRit/e62AX02pmo+88/EQ76ma45Nfnimm
TwAHM62m43i3w/H+rhPNu4vOs5nn9eEecKrdZ5xxxgPj//2JJxW1GWrrm1QukwaXo+o2i6sSH2/M
6p46Xhi9OR3O9VQTAD9L7hqdcJzaWsGDUlaetx94bkvObH9vjfXPVi7HfI0nVWH0hX6tkDtf9z5g
X74KpuAaKLuSqhbLlDeAbEsdLGx1fv3ZQ9XuSPk1cnKJPMXbws6U258PCioDTxV5+dShwEtf3Y0c
alxl87VCCFrtVtVAx8cbYhP04AkHOAPQLVG7zlTNtfKzqi4xhfPX/NqrkYfVyKFK3W9HV7vHd4N3
ITWrMjwdD3k30LlwwPBhT1XNCX9bbUM5ZEhPdefeG57ffCvZVBZpdO9xvJ3nxolPIk431Lv2qTre
e16nV6VNQhTiqiCg1kK7KzQjhpj1rrHKCqguk/kdWorkqhNQOYY0cO0UY12MQgS6bgfRaJa4vBsn
A6D7Zq+IdsNx1dCj08nUFtRF5d0Kf4OI+WX91a12j98PGd/fVeH8ByU+/18744wzfk5S6VyCM77l
gwkLZMUAuoWAm4GbnFq07nMREDPAgQv0oyCXge6ohfglPjnakynQza2CSKJxESVLEoo0to0t3wYg
j5a0gTfLpqz/MwSAoK3qgW4ATwQgc79MBQPPeMcUgy+53ogoIGecccYZfwPwvgabE4MTY8IXpSq9
VmnWIE5l4xTfG0ycOfIKb01nGdvKL3ZVkKNjQS553VVCxn/TgLH1PGyh+hp4By/7Dbjcr0C6BSrP
2scbyDoI+L4dH+/heUPg6ayid+Ydrm4q6xhwjLzfXu0Wm0LMu5Je26zSxSne629FNZlW6rJqNuFH
az0DcczLsQc+4ETResYpSu23bfl4r9uCep4I8FPvFtfcaFaQ63gbL+8Ck0ZfAH9Xbf7833qAPle7
VYdPFOCnFe+2epIviY/WVTvZuQQAnPO5G61owvTxzhTR+d8Gx+n0+HVSJwDnYWeEy/H2VNx34hRW
VbR9vIN5dVQuz6fj5ch4xkSsX694I3QlwdTiBAhnDz4/ZV1c6P5PwPCmdn3WM8F44Wo9TFoydhTO
6UyR2KDROb2lYWSYmPO6EquxO6q7uPGrfO79xohJBCeX+bv8s+9Q9Wb87p9S8z4c7zPO+EnjcLzP
+AmP6RYQ2QPc/Hsd3QYrCPhQJMevKMKx65n53FeB4/0JVGZF8i5gUJz1f627P7neV2sCdI8+3ld6
X6520bjMUzX/xd2ek5EXUYC+pnBw3h4Yx3sOpFY+u6dqjkHVnIFxdq2UCv3hcX/tUhfNAV9RgYYp
bHbGGWec4Y4f32puqRs6GVnaxZRUMMX/lT62kXd2i7KoGJVBTc71kGZuU3tgeCwgKuPBxc/8vslv
pN+34FwPfzMUjas+3nNQN7aEcnVxiIozEt905uM9+oGKKo9QMR+VmMdOhtUrHcPjkb5LS+AReLpG
nOvIo5RtH4nXdnMqTrmRd2hRk1XEjW0s1e5JP8Ddd+Yhj6VCq71tfR9vqBe92z5T2Fc8aF1xDH0V
zAo4krIOmsfHHfijhqr5KOaGVV3YWI9QTC40ZD7eXeW+7ziaOiuW53meu1JV87aKm0C7cSiPbvlW
iyWLv4+5qjlL8GQe8KzlHfTZR/5sNaUKnvvFs4uw1IGhtx9XarE+z1D3c+WA97xy9uzIWIU2D2Gs
7ir7hBCoT5x1Q7sHEwXFUqVvrAV+prK0dP5ScRB+RyJAvZfVfaMasa/zSlU73//un55KqXK7p+t4
Rdsccpv5kTxU9Z4VzX/yOBXvM84444wz3hLAMd72GkhewWctD1gpgIBo8UfnXz0qqsfA/tVs8p+q
OfMAT4LrviI7nYOqun5U1f+rT2OwyuaguxBo9arj7a6q+WLjPQbqCKrwrFK/VJzHP1/XLOi2r2oe
VbVPpfu3TiDre9jer2qunpXD8T7jjDOeGD+z4o21ahMHYYy3FfNlGOc69WkOl41ue/3ngWAIZZNj
/RyTGjpVwU58vNXFWPh7Hz9HUCmcT2IOvlWFb6i2gN/04Z/M81b7eI/VYWif7MaOQ/PGuRI965bg
Pt7KE354RgfPU+3jHT6A4JXu6Fmd75x+VHIf0njbck/L+7j6z46Vc8j3c0yjZ5oC/XcU95vxoxdA
wXYjqumZD3gm9DV0S4DMJJaPN5ZnjPt4r9/BpCzsAWZPvCw6Z8ZxXq4dPMeKSFDJaX8dvXRjl404
IRHmNFq4gH3y1dugQRFVrWduP1c118Jm6zuY3LfGK3pS4T4T8OO/avl0xJTLvXu1vgPP+XiDdBKg
1Xy85Zout2dUZnfga9d9Ec2t9GgRqY/zf8drEVatnWlejN1VImFFn2IDMpd6cWSdkx9zvZGcUxoo
0NUq2DJbjmvJEBq6IYgpC5t3XXO+wbisT6+trTqq6b+T602T1Qd4n3HGGWeccUYLgIcf3HuAGyv4
jCpXs+eQVCvHAIhYK3ra6g6t0s3B95wEmBIaor3cBUhaPG0S7STIB81QmJ9Al5tY0QC8A3DzLVQW
X21H1fyM7zFXTCBuciF4972KrOPOOOOMM/5e4G1UE4IfL1kS5Jueqm99YMUWexje2c55jfzNTJi6
3ztTSdd1EpDqx/qLxSe0IfTE7L+3BJyZj7eoYMd3NvfxXraNyYV2rmYIju9wvUeCdejjjcXHW+CB
4RxWNdcxEAfpZIgztLAr0ir+iPw/o/NfQvS5lWV933hob1C8lSqufiZg8sMbgmvKisrO7xvCf1OQ
KbosCMIIfLyVy4NWfl6ObwBGkKDZnatdH++4Uo3U09sBsdwLfOXHDvxPq6I57ddMYECC9le1/QXY
hTNz4IdNAXRQta6pmoNqc0il9WR7NsfbUDV37z2/n4YNnkju0G4V6pWc+3ivuhI+t3WwVWuTBkF3
XIqEP3fv6HV31VVpxLfbW78wHOPS0EPWLRazIJrrgsyNVSAOON+0+ytQOH868WCbnSCLqNd/+b7k
ML721Hnj1mbxI+qr1xu+//VV75nfjcJvv+M4HO8zzjjjjDO+fHj87xYCNUe0iAm5vbjT/63kVOCo
zUDqPz7wB1/7aqPJQHYsFwEBPYd45ki/eNT43FcfdizgZOBKVwLPied+Tedyzem1/1TWJ4r3QvLG
oDA+cev7QAxEaZ3ey6s74rU1uL+nXDG9LWD8uER84/lhprRMtqTvr3i/jAjgCMeeccYZZxjjZ1e8
WbbyzmTMAjXxHUnxFnypMWtPmL6M3wyQgtVaWRpa7ZpB4qU0T6TJRYhqKvsNIhXSOZtMKuy00toC
L+3WVXma4muNXrtNqlUrBXKxb+ahDRFARJ7OS2DYb8H18V73q/4tA9Og2q0eENvPO+CNj9oNmqec
crhVt4Ppkz3/LftcAT5a1Wni98zHmwDIJehcpaJJtbl/F9bLtvDUMc4L87sjfakbZLV7PY8mAbTT
Us7m/bFSqdu2l6BdrCPczSGeGz5/39oq8iZA97w+wAIVmap5W7nmTVVzkSZAZIcDI1oHmhmr8waS
7zTdit8Cb+vgmUkr5+T6ZorlQNZLtgEUo04k08c7+jtTP98Nn5CIVYNFPVkrYLADwPHthtZDgbeu
sQvMdRsiLR6RKICeO8Z1I1ITR+1epOr22dOD7JUhzgW1ON523nar4VWed+HLf0IyRjKlL/9X38HX
e+f7v2ucivcZZ5xxxhlfMmbAN/soVwTHcpvIRsXo/vXF/qxc/6qyJj7eH8nE61f1Fnh5gbtRB1dw
72q4i6c0hir3vLslodHyyjG7flSV/d9DZde7q9B3Sc3rs3h8kYB39NKOnom5Cj57jYN5fhfOkWEF
6S9+xjebPL4Y6Hyiy+tWUuKMM844ox9/pLgaD9DWasjMo2rBMo7El3SuZPdz9+rvOO9HV0EHT0v5
+0YCp5WfLauzrDKa+XgDpFqnuH3994g/tjwF0ArIro83jKpwo9VB38cbDezS80q26eMNoSMwPwvE
uj3gKcd8P0uZvFTtRlztblGFXXE8W+iFHbYHBtdHVrsb/w2taKhqd1RxD+YdKcw1x4ji3uQ+3pEf
d+C8IH+P4PjnOTKusFYq4JGvtXrvQW2LQOdZfh8mfvkE1vVcxX3RYQg5LZVY2t2CZe1jBWkqsqYW
uvB6IuR4r1xy8Gsvv5NX4qPrJX3km3KT2Kt6j3OyGU9gqkSCuzks+5zvAkZf7X5tyZTeV00HcB/v
SIMAGBYiyDot00+Z7ohwpZFzOe1CI3OxWHXk8yF0Bpa/sWNdkmPNmg9KrtSP8b1x42/JR675jfgL
No58/5yrW/i9GZnrLb+9jG3/nqo3yN/6f/6EtOlRNT/jjDPOOONLRqQivfPvDAwCuhVzEEUjVm1L
0m4GJM0XiYtAnGqbpUJtwzFiTQQV1JcjAB7ZjrVQCfz1pVcSoQ4UGAAfQZkHqtekJ+KEw6lqfsM5
Y6RqfZXy/GKSdUreZ5xxxl8NvOdqIYmuMnuXZVGmK/BazVj2KX2Y5YGPgVKb+JUfx09+pypSQ/G+
xT7P46+IqmhrIuAJ/3P5zZgprlXYd328VzVzflNmtfcGkZXG+hxUfbxbYJ0cPT+SiMaqk05i+vMe
GwrGrVkbt6vd0X9h9YmG5EfnHG7ZFaF8splqPfLP52f9FRzya8S0EaiP9wwSEuV1iA4b38cb67sx
nyfiaveiM9EiESRD9fkuII+49y1XaIfq4GFzSd+JgqnaHfBBVfW8WcA0eUYw+4gzHrwG4ClQhsOp
TrpqqqrmyfZjUD11tSUAfBWuY/cO1nOVVVKlj3clm1Xw8e6r2CjgSYs73JjHBcakTejjbdBJsKr/
Zx2Jw5y4dHshdl1oNut4dLMRsuqpUrtUMo/vQ9/RCdR9u9W5+YkOtV6p59k2zdaxETU2YS06rfZO
Fb/8E7nelzzNa2Mrv/71pqr3zpF953E43mecccYZZ3x97tRoMXd+k302f+f6FSF8KBZnquav1vNf
POVfSZmlBQ7GuQp+9gdnmvHdP7jd3X9Q0D1zsN3rz/nP+KWlPn9/Vg1v9LyG7f763Ws/I3/bVzVf
r2Mf9v5/9r5FsZFdVRb6nv//5NbdMxPbElQBajuJkwX7rDOx3Q+1Wg+kgqoBHLn7vZGbqeiJO7/7
7caJcHPnc8en+3ghHQzR1tb2GvvvhJonUoyQvTrNz1LIJq5OCNJeW2MUVD3TszkAaxuTlGnEjq0R
y7li1ItOiCRXb3WLTB4X2aSHOdcWSd7Q8V5DSfFD4LJKkvcqhFh71dqG9S0VHW9111NFesvquQp0
3QZWoBGuIiGztG2caDdZA5FvlB/P0G7egAk7eNDuuBNF2hlBo7Pf0TUkzLHWpV3C8+H7BwtTmBt+
UcdbC6h8EFa96OWC3FasnatPsZinC3Ml4xEJEYGs40GYdPT3inIniKby/NIU8basImgTQ4wEk+Lr
43DxcBD2daNYZ1ofE8qlEPOV1Tw+VoMS6zL3aZp7jN8XuxNC22vtZrkKGj9JWREXwKOuvY637ffE
U3BzitfxniLyKm21CqzqOte45mXz/mkaggCwU7fHDzqvoRSIrc2AeE6jh0meK351Y0H4NF46FhVc
y6j32vw0r4hrTn88mu233+LY8VV2Va2bI866ceEvRr2TG/yEDbJGvNva2travmc/dEJ5K7JhbJEZ
H796indyltsCZST52dMC2iLT9u/qJoEt2xjiWc11RYf//j2Gc2KRMz6Kdb84QHcmcYtCc5cmQs1H
4kM/QHOPeHNWc7R5t1TicvHbXhDSWteZnb7tnQeJYKH3uWOTFICYtra2th37eYg3yt+cd3+LO4Jh
rjaWWxbiAaS7Z6s293qkI8ZhetLRDqnZLcZJxwRij5iVbZoWQE8FhTtGesWuKDivFDNcEx1vvx3v
638+ZDpuybdCDOTg/uu9dU/HO2s/S06j4OsFyAt7bvs6s4XBfKMcqaZHoQcMnahHdSq+RCGHW5mm
KtHxFtn7HbZrxFQfSL3uoN2I9EvlBTreFu1WSccFx/AL2z5+/mjhXNHpdgsnQqQFSdKiHE6SH11d
5K+64ZrnVKuJRllyxKtMzjECprKi/8u4lxCkVd9P9i5mTXKk3830wS3PAdPIdsiccpr/IOABR30k
GyUiWkAFn8mXlYRzpTiuZ9+BfOfca2JF1QW5NOw1IkijPbnFXO8asIbD+qX5vyA6YoNzYmfzT6o1
qxwJ17A95Xflud6kH5WapInEC6K9uD9R96CxX6hbdXz5x89KDP8my9Hu9cixddVl3zM/axP1VniN
+NrvaI14t7W1tbV9uc0o8o4zWwnddhty07HL/Uau0fsgctKH/regvOwNiTNATgb1zAHqPdympd8A
KDkdJD+bId4ofzt6N14GJic8GubYBQEf80nDLfpu72O5tyrVWm8A8+eME9+zsLnleOcRLW1tbW1V
+/E53ozRHB3Hd/ZzHW9h7JrTz5A5nOXQbD0fkI0hO7feydE1VxnuqFqNTuV61fNz3XPJ+HGL87ip
4/3IbzPIhQTorHVYgQ5qNJkvTM2oUKmON9MTZfewbKugrbh2qtDZnnO3fc5ugeEf1ScsD2+lDO12
2LkWilFMh4uup0oOUNxGQ7TbvvegjinaXdXxRuhbgMhv6XhrgqBL0p9p29aEBVpKyHVVBxoutoO8
6qpq/apiYcqV6P563WyOdIvpNVU5NHstuhmhNuddY5mwYGMD12fcpxbt8Ei/e2bZVh8tpPJaVnMU
pYSjjDLUO2aY3tk8cjrbJoKN6ZkreDak411hyVczVqsK0PHWWMe7OjjPY4yCwczcx0XEgYiVNRoh
khEUyhyuU4QI7/drH0PlUKp6Yb+PNho1ZDj/vI2ATI0g9cBTh/2ZXG4tfxlfcAsv/yV7Lgztfo7Z
HJ/zatQ70xS/3e8npA414t3W1tbW9uVWYS2XwqKB5wWpS0u4Od0LIjpix89peH+kWaN873yBtabX
rPnTY12wjfkx/uyRn49cb7DoVj2cM1Opf8dQfitNQBDm8sLBNayn5JjdJZIpHMYxBAvZsW4R1VjN
237cOLEzFrx4fJoR77a2trZX2I9CvHnm7uN3xgJJr4byeQPHLtVhnvMp1eemKHAMkRa0inLdQnfs
WhZFaDgAbdWwnEf5djrnaClC24n+cMZcbnMPhet4K9DxXli/hfnfDN1e6xXVG2QPtzvgsI6Zjjcj
fApQY/T8kuSp4oYsIZT7aAjw9FCXXvl36XUcAqVBbl6Qw01fuTmXoBy8SgJ9a0GoLz/fVxFgs2Zo
N6ziJ3S87aIG1THIJUaIkQZ8FJzhPHamK7nHAtFZHOCQMZKjeyN9doje6zqe2bxKJRq+muSTM9Tb
Eavpgcs/1dMjOsYjxao8kxSOGpUoBZ3rg18QcjMgBPxJVnNXx/bCukYOrcf7tpzxAGigCoDamB8v
4jFdwyiueHDUApeIlMZ/Oz+tSd4urkMz9mflm2pmHPY63hEpR4DibuSOo7Gc0wwoaEMhgVAczccn
9tI8vcwvmvXwZL6X/Hz6fqsETKXfFPobeuXaP9xG8XeGLD/HbI6v8nLUe1y7/rtZI95tbW1tbd+z
mcokuYKFOSLxQk5rJNs15wNnOt5uRTcxkC+52VUyuWkRtyKzBPG+H/MP9fZa4Md0f93wSFY3B9bH
5AXN7r9jHweoP9M5dwzjQ+D7WRe8w6R42xQAgTneKtJ45Y8eHyTcFPq0+94ICSXZ2Ghra2vbtB+E
eNdzrxU6d8EAqowInDNPP/wckOeb5uXd8u34oL/OPOB3LDzuKsHm42iAnFMnNkrlU02Zyx91rKs+
aYKGP/Ly7YWi/M2ZedwjyaqFfFx5TsdbxeeJc+1W4t8bpN1t/DOEUQ2iZXPdp/YXKQDU8p8I5Tj6
KWE6RfeA79Q5ZUH5CnStUa7rsrgp6HhrwFC+reMN3o9F7C/reMMxiYw9dgGpStHuta+S3MYUJYzz
dN1n6qjHecqqcYSD+08UspHbayO0e/keIqfBvERQb7ToXqMm9O9vM+qtwlDWeH6k7OPK89+X86wi
BcsxJ7ndbu4i/ZW1HZRvqyQqBM+hGPVG7VYJucNTOt42Ikz96OjnZHHIaqwesN7AMrFAHW+pYZ3r
s6njqVhY98m8b5n/V/8HzAsS+HFLu4h5ZmwUUrz8VzoPhkv8CwzpgpRaXrDJUfmutv2hwRSsaThH
qLW9/ah64ZdnD34v20e711/GpbutG63pWQHqPTar/903Wxvxbmtra2v7nu1Uq10tmHgq+kwXPcpR
cIeO6n55KRO5zCRrAWIPiZmyjaJDHgj1uujO6pVtukBWdpDkjVjTHfs40OSmG2sFcH59X1OZdMwp
3nIX69YdxL/t7ccH0S1w45XjUjbutLW1tV2xt0e8qWzJPAiTAyIWVBxZiNh5Ndb8FoykehzP7pq7
EvFczRDlEp/XXdTxZiznoLoEp11DqHyZNKOcpbV2rul440gEoCdLcvtW7VaQNwffh0Adb2Ua7JBv
AO/CO4baZKc9RvCB7rlaR5+/8ui+YR43Qrs11vX0Ot0C+wv2gdbddcYQH7I3R3ra4HyGdocstZGO
t8rKWg7QbueQwheW6HgTPWnX2DUaJ4XfY/luj+E8YqJ2/UQkQYLjfPCKbvWdGVwwWs2fAbclNbwf
8Jldmee89XqOurvvNJe5/GeEQCeDAgyfJxwhKBdbo7oJ+urjNZK8cLJAg1wEghFxge8JteEqU3/M
vF5mPheeZhG15Wg011fpeE9jjyrn04g2xaK17dp3MIeNArbzcuZxVE7GXyDzuFqoIzX8AVvvTWkU
43r9OJoy9Mukzlhe25fQ8Lcd/XDoM+010Y17XGc3f6etmWH+zfKnM2bz70a9/bHXSvYO1oh3W1tb
W9u3WkhsBBxHvNgG1xzrwugWslZhJGcLTIvkzvne82IGSWnZRbe9RnUxXT4u8HgwA/i/b9brcsZy
qJtNvC11aDRfWMINd/M8j+Jq+H7afvTAgDe6vmCJk407bW1tbVfsx+l4R7rd8e5xcB5zAsgnooBd
0hSv7ChqlvernonYHWsyQ9l11erI3nZvFV8LTYYIjYf6ty6HLdfxdjWuhODZOt9TaiHS5k7fg9EQ
RYcqjSLItLnV6OtGjfR2U5Ppqyj3UCA7PsvR11onMT8FedwwtY3ADihaRbN7B/WS7kRzREGlJgPl
jtcL51d0vEGe5/y4l3S8Wf6reyagVzv1B5RXryD3VFUrI7evswwZlZreN4PoITpZzAdf2O0ZS7kk
C2GYN5zoeCPUm2lrk7z4mdm8gkBH9SF2XKXHPMa4LJIhReHtNR2yqaB/RRrdeNMGzmEE9V77gCbz
fU4YuOZSW6UONe9ewvoUwkEQrG9hf/cvR9cc8iJsufLpI/4RDed9IV5DJYdYA59PRUAuOYhKEB/V
xBQH4JxFiRfzY5YRAUZ5XtwQUCnkru9dW0tKKgUPX6VEgF4vZxBN8/I1yvdt0LDc7QqCjH9fCTC/
C/VWqUhkjk97p6+2Rrzb2tra2r7NUjmrIvkQDn/+3/8dD+d5oPsOtpmBCcNsjrdnA38QrEWbMmUd
b7QpeL93VEf/XBaargUYwNfaQ4g10e0evhwsd99FGQAUewBE3aHZCaLf9hvGBrJQ1a8bk5rNvK2t
7ZV2/NiSh5rGhk061JX2OTKe7RLkfgvZzXYM00KSjdbkaZ+bqiFKtkxKYlHdOHdKcUI2qGKdcqkT
LV5Z8yF5faMd61zHW8WzmtrjvCwr09Ke//W779s63so14efvVbjW6prinecHiuSRFEj3XDMPR6Lr
oCgH0I/Wm4b1YRuKVWVH6KoyOniCwDyLdqtUdLzzhWKo421yViHaDTpRRceby1r5Mqx1DMYx1zay
duqPjXK6I9RTAdIJ6z6KfCos5CGKfijMy94K+5ZndLzVod6MVR5xKcyot4B892hsgRzDdk7TQOs7
Q9BRmQSj8DSvu4h6VxZ1GPX245FW5eOKxHmVduTeC5z/svCfT9DxVqMfYqNzkL+w8T2s30gc3tRP
DsyT6Bnajrzmhq8ndk6V8VxKkQrEszJ9/qJjTcugdFLaZUKPcsPpdTSIUd0F2aXKa5P/9A7bgAO3
tgtot/nlG1Hvq9cfb7ox24h3W1tbW9v3WkJ2FjlqKTmZPHKtIVoNdLyt/NfidE1o7D1X/M+XQ2BY
+Kt0vEuLEbMBM0bAcG7g5Tu6bK43Ck45cpRGsexL2VyZHh8rTO1tv25g8ECKfAEKrdIs5m1tbZ9i
x09/AC06c+j7ZV870xUMdhbd34gVe9JY1lLuKn8GTfJmXVEQq3GEShe0uZEmKn2ECUne1fGWArO3
ZRVdGGct6lrV8VaM9HHmcn/EXDMY+RGHWDAE1WkpI+dbAMM6vGaeO8d1t4VrdMtVtJuUSnfKgvtJ
qpUNFltwkVTS8Y7Din171NXJU11R3xfoeDNWY4+oY3ZyzEzOkZyMZTxCilXyvOEoH3Me4zRhkt5x
4v+GqgMd753J6DU63lpgVD8eMmOoXkgeuBRY3lnbpuOSyKIdDvulSLFM4J7i5zLEg4Lqi2lzwzxw
4X0kJrtmqLKGbST9TvJFoV+oXtDx1jUz+zFHyZaONyOtsSwqNoIv23CbzoSbTqiuFM7tUSQZbzNx
TjaIcIG3iaOvcHtRPgbtbWmk/kbUv2nZko02NmfVHfxi5IgkufCftK8y5PuJ+HZzu3mVDPjxq1Fv
u+GaXePdN2gb8W5ra2trexvLwys3JMSWvx9wdYR4LwvB4e+NdLzn61lZn1fqeK/XGEKJ2f63+B7j
/EiDHqFeOmIk/yeZzeuTOjYlVvP83boyNdL9nx0L2Ib5p9+3c7zb2to+wX4m4q17B3n+WIw4mKTq
+8SPx3wD/TkWUsMOSvyTFeVUcz7QjU4QdmHHFjS3WR2vu7UEGQEMpRwN98mBTscbU5e7e4VoOH2+
QMdbCjreC3N5nNddaa6zLu3SuoKcVpefWMGQITVrkG8dbGarb2zunbrjGNoNhLxdn+RC3xTt9t1E
fX2FyDCPDmHnhzm/hBmdsjfLczreaf61MhHyFV2NNMq1gvaHebZK23vkDEeLcA3vCdieC4z4mY53
5qC/Tsebo7WVGRGymmfkdkLytyVBJKcIjvh+SlFwOJ6Cfsz06UN+B08KMhWba3xHcwB8Pt3T8a70
F1jft42eu9+icR+R1+l4y+TrqBR0vKPGVuxDmkT4qGatOGi7YO7w4x5TguARjarq2lRlDNlDllH/
1XW8yyKHNpqAZn5YoZ6jHPwoMDRFtS8sLUqeW6Gs74J6j4vn0Yf9BtS7WtL6r73wbmtra2trKy+4
odcWxf3+cYOOYDGkZIPmiIn67CLUkaGJlnW8Y0cbO7D5Yj+OCIjC8LHsWLxAjhSmsgW/FK7f9l/r
+xKSJX7FmNP63W1tbf/xhTfWvF1mfXCkaoYIxkzg8wDMCGBdBKFDhH3ONboZ1/FOyoIcRMZyDhwd
NUQmdtJZdmg1qCghjOIXdLzvJdNgF3k65o4eQ0Z4nVAfCd+9kNeUMpfb/DGInk/OuUhJy1t9cAXJ
CbWF0wBViHbmWS1HaPeKRqeM52BxhFco+Pcsh5vlgJeYyRHavXN+Ucd7QXzFaNLDl1/T8Q5RUVUY
oSNLP+LM5BGrdraQhDmTF5BD19fmV+5Ym6drHHvoIyy7aEkPHD6Lq7NHTjYKF0eodyQhlkYNSK6Z
Xcsz1lI+/h0ZDgio8SKf5+m795Kh3glPgNkNAWM6GRNA2y1L3W18VtBfSprn9o1pDXFEOdyWu2NH
x3s+FiqWTI6RigZRgngsY8g/qish3BJrPWTtDpUp9iXs3Fdhb5cSiSROZ/lUz/tJNvYai3kZe5Zs
sKvmeWtlvVH4aZatfBfU+/njvz/Xe/e8d7bO8W5ra2trexvbXqRUnL3D63jPbvCsD71sDmzoeD/+
XnW8+SbEsSyy/+Vk+99wHR1FHe8/1znxVufHcwxbf7M2+XQc0vG+1c+Q4crhwgSZ5vfwdTNff0jn
d/+HR4NviXpYiFGPRrzb2tpeZz841DzWJMaIJtMeXOFEVZgGfb/27YcViLIoJ2bVdNeZEUIrYUF0
o93vQnROFR8XMTUvEw9ADVIdb1v3BR3vNRwAM5WuVT/tngd5jprplJs8rUjHW03eMYymcAzlAr6P
wl0V/I1rzukAu0gB9QzQttUrZhKP0G5XlgUlEc54rhzttkEgVl99uR7ShzWa1opywAMdbNpuGKt4
dn6RGV0DwqBndLw92s3bwPp7pNlq3moAf1zSySaouUe3uO637Q8qJFwaol4kLHxeuGusvV0hhYsW
1vY7lQn1XnS8D/deH9evqyA9mNZ5dBh8D7SNMwRekvxtCfNOMaJd2GxRz7ZPFSxcv8dM5lEkWcQ4
X2kfNa9HaH+4b4zAfoEHeudyXCyj2iivRMdbsjDukPdAYdSaqlJ0M+fPJozyEnFF1IpeqdMKgzu/
luUm0FiPPUHcw3G6gE5Xy4quEU0/1P/QWnlkqzVI7MQWfnoX1PupMWZ5ku9Dva9c/51rvRHvtra2
trb3mfhVM0+WLJyihWHOSI4XpTXEm+l4442Z416mwX5TeaGO9wP1HsDrUcQc/qfskJXcINJOi5sr
eVMCzwa02zYXgG1tbW0/1X4FuZrNk5R0zyPO1UZOHmO/rXKA3kuY5FyHV4FlYRrT6EokIV0NKkiT
zOcnmcMxk/cjAFlJdLxnGREF913evckbXxnhvY6349bOdLylwFxeYR9HesVEr9Y71R6NqaFOiBG7
0mJZX/GwdqbMDVF7JBO/l+Jd1/FW/nm9j4Zoty9XnRndtkeYW/qEjrdHJdV1c1BtqUwPYvN9Bu3e
WjAjjoQ0r3iNHEkJxIQfez/n8PnZ+zreh1t0+/L4+kGoN383gY43m9tQ3Vi9b5RLXyBKm9nxtxjf
QZ9SqTJ9s/ZncqQZT4FwnXCN+CLQ6FeQuYvqdG7PS1QQm7TSEX0m/cO/yTxHzu8eKpHUdbx1ch/Q
2LP8rrZd2M0w4FeBTSfWL53qgW3HsiLr/t3wXOuob2Y68CyFpr4RYdh5lv5LDk+iHeqod9S3d7zm
2F/YXBnMzbXaSfYPCqaCIcC3+AE20idaP3496h1ko9sivmFSeLOat7W1tbW9224q8w/JJoOmudki
F6W/6D4oXmQ9SNCOj4XEAS+Bz5cSWVImKfbvnj60HS9ylW4i2gV5JkWUkdRFBGrZe2n7Lw8H+p+8
d1tb2++zn7PwhrtgBHG0gyYCF7NcbYmvve46T/nNIiZvKt7nQoSvtx9UYkQI5Y4uzytKHZylGgWz
wUZoopuQ1NddVcf7TkIE37W45zVy4fyUTOt52U0XrOOtCGkWrONtkUyE6Lnnn6p7oRooajCD51P3
PlZXF9dYFe22bWf9/5fQbhddwJnRaQ73XIA5goO04TBXO2EVh+cHLNdIu5nXES4DLz9qr/i9ubbn
0MCcidy3jRoS6RenwfkkggnrTdfC0NfxZirHUct1/Be1c0xdey+H95abrYW6mE/SRYkh1vGusJpH
C2HHdk8Q8ZlpnW+GrGXe0Q1m/YWhlNl7WPrBxA0Sod6hfreYSKqozQXt/IqOt2jM9IyYz5dIMJj8
XW8veT8xs0Gmd1zSbvbtW1GkmAqNHsvIDzTpH1jHm/kWe9EZSsZk1BeqfajmUlc3vojvmGQExb/p
zptY/b70GhcWFZvRrNUq+mNDYj/03WykD/mdDOf1Mo83pj9vxLutra2t7W0N60kLXMAsoaLWzWRS
YgcPo/a/HXgjwUoyTqHllGyJIuYF8h+NF2kLsVlB6kqDBR2kJFVMbpRGC2wswNvaqov+T723dDtt
a2v7jy+8eWq2ndmBpyKRFjRyqNYB2E8CEYOkRRPWr6l+M8mxxmUB5ZacuXxhgP74LWR4FpOfahjG
w4lyRpIXNAvls01F1YfOtU+JBajYnA8e6HhLVccb5V4m7cheb45awEhNxFwugWOOvXG4s5xR3AYM
6pqIAER63CWGUo1783qnQMc76noS62Aj9DhiFd/RAXfKAERD/ZqONxdHdlEKDNnUWGMb69QyFCjO
P2YONMyDhA73nn7xbdFc0vE+dGEuR463Botv1g7WsgrULme5oyjfGul4a5Knf2WDA2trm7nFIuDk
elk+eJTbzdoT3WyQiGzOtSCPZCPmdIJ6w/lI+QYLzDeX+HnnuVCYX5LMCfT7pU3LRAgoVMdbZ46U
yzrea2t9KGKoG180G0NIFMz6PjFPRMYcrsSH5DreyXgE32/OElRj7o+v8vC/ChtvT2yIaRZ6GPo+
UhrHdKOZl/O8C0uI9AoJ6v2TWM45s/k75Hr/fDbOZjVva2tra/sxroBbpBzrxlpEkIbCz0Idb0B4
NIjze2M4X/KphbO0W2f3X9lyZ/u2+B+L3jbb0Pjz34ldKeLpsEXgfLyi8HHjPbF6Wny4ZjRvK24K
fdu9W8e7ra3thfbDEG+ABipjmExyoeZ/maZ2WAY1eblWKxzkA0M2VMNYvTBfP54xIvpJiYA0OZaw
i2tCRsTQ8DWnLnqL4hFqkRDF5DrkHP2gjcBwxXrG6fXy87uu5GOrgpOFoHlAOzZyOCzTq9j7MS3r
pU2wfOAA7V7+JCLbwnRjcd9VQTrd5k4uBZyzmKfcBkvRQZ2D8rnr7aDdghnqQ3Zw96KFLN4E6Nna
uvN5n49XUtEi9l2sjGBLglYqf3aUy+yQmSNYkEsdbY10vB3DsaxkZbXFgTrG9XTBKwz1PsL6uZzj
jQYYcHxEkOZC/iVB1DT+LpvvIjZyjzijsVxCxnZV3h8jgjtlUSsv84CE6HiDvqXAEUBzcO78BG1p
nesedXw1yTvnlVnHYg0Z5isPgNvpwR6U5l/DeQltkG3kFevVfvySTY/cP4Rz9dV3IQEaHmh2qz7Z
ozb4Bq722Z/Ecj7oN+/GcM5v+q77uo14t7W1tbX9GItkd+4EZChve3YdXqLj/Qd1Ph3ivZ4PXLdp
UTbopkXsNcwa4rBOls8PHe/VySFa3Ew3fQxYD8vG3vCRA8PphXcbbrve578a/W4ugra2tlfaz8vx
zlJi7AaWKj2tlqs9a396XyQqFErVRqjZ3XFBjiTU8ZZ4x9iJfqJriWNpzkh4RFluePyidNb9XHbG
8fWX+tYHhbnCCsU63s4hlFUTtaTjjZ7Fvvcs95CQQilBISJGXP/oQU6vzMg8zpFmrwHXiUWnd/S4
czQZN2WAdmd9v4CIcx3vGO12Q0KAfuP2kORgl3W8sR4sztFX+H5UY7UCVc7Uu4d2++8jRDxCu1H/
UMH6vsvi1/bBTR1vUQX64HsLAstIvqfjjcuINdavI95ZjndFTgzl/SrpG9W8bTb/I9Sb6n4HGsrK
JwF/nB3PPPlI6LKoKkWCqzreO4tPlbg8jNU81PHWTazO6Xirm8NVkCKMAC3tZJ4C5IRK36li6b45
igzWZQGKVe5HQB4B5Sz57lk17s+MoFEFRwE9s/mgWo8yUEIYo+Xk64IfEJ2rG6dq9anqlyM48Vva
SH/5etS7zmj+/jXcrOZtbW1tbT/L6EL5gyQNOZ2EFC13YMkmEnCaRcVJHtU2zvZ0vNF5zFF2eec7
ZGfC05NSQiYbJi+NGra9pu9/+S277ba1tf0XFt6PUDaM6iobjD0dJXAOKrna7NqLO2ecwQCRRiAq
0xFXhy2CoiQ6kYH2MZtUILKnheQXXfasH36XREjCY8czy9eGLz7M48/zxpdcP5VFO93fW+G7iRxU
ndqWkufX5O+wHkAZQatc3yVuubBOXP+xZ3rg4iLarcIQda9FDRx4LThGSto8JGW/oOMtHnVD7wbn
YHPnkup4Kx6DlggHtOBTG7GSs46j8BnVndxwTR3aqM6ctJfrH1bfOuiTf+W9ruh4m1z5Ys7m2q/0
KR3vSj2/CvGOPrvIo6KcGGIdr9SDz10Wep3lO4J6o0glZeOr4siRKNoozPWWvK5eoePtJe7ytWwW
AZBzGPiRXDXr9/GGkn2fsL2IGsUI7LZppRKSDaeo3FRtAMp0k7BEWtfX8+/DOVGlNKZHex6RMsv1
PRPUfjS9Q+X6OBZgt+KuV/nftc3Hf++8ocNL9p253kN2fnnX2m3Eu62tra3th5pnOFfoRB7UyWQ6
3lj+D28QrLrdPjyWEcuh8PHbhuSxISOULd6iBfnV0FNxkpHPX7etLWujX5/jfTSzeVtb23984W0Q
bajpfHOIgIOmGuTszl+quvyuR47lnp5ziJpZ/1ETFEUBu7LaejB5vU76RWm+eehogtxwyXZG1dcb
4aL3+drL9Yg+9lwvCqIQgI6nBju99+tqrNseSZhqbWvY13Hi5GtC94nqXSP9ePrSbJ+wjciUN0Fn
7HewX9LTSY66yed2bdpemOh4y4t0vOliSPlYs6fjbdjrJdLx1vq72GQojxZ5WPebtHeWW7nUD0e7
fZ40WIRbJExJeUMdb4RWI3TzCMcCq+N9uP79+Hwki3M8fnyujjdrZwp4LFg0WsjCjfqS8mMF8gdY
nyDQOkdIOmTN5nwJwTARcxlofSMF6XiXNzio/wADxyBK7NoFaZexu6ZObWNhW1FzjPC0CObhwfeh
AjbP1rvDa6pH/qP26tuBxnMz839CH6LCi2LdDd0aF66i3iX/h57/ik0Nrf2uG+d/0l7L223hjCs/
fS3qHeZ3g9/Gm6d5N+Ld1tbW1vYrjIaUKifx4gsZEGoNN7uMOCQii1vkhx6L7tktPtQvW49EVinT
8WaLad1E02EufZLjvefyt7XFi0ipqXd9zr3b2traeuEtNN/JgXMZCSUY1ZWwo3tnbnbKfD6wRaIn
t2VycmZECz2D1WgWp83NvVCBzOW8LoCUjlZ0vB/1sep4Y1mf9bkynes1ugCK9IBd7YqO95onJqGO
t5BICa5Rqw/EvaLNDf7WsuMt7r7wXSnJU0/QbpRSvIN2+2iG9fr2Bp71G/S9LGKDRZeIYLRb8HNV
dLwpUzOU1L2i4801U72ON0PeZ6WAPA9bLyDi0XNljOZoQfv338Pn4fpcaRwy/q/ZWVI1pf020vGe
xy9EakbzeE0/OwLm6+P2O2B25si3LuceL9Dx1qKON0PAqwt7lrML+xDiaoEa8+bawb18H8FlkYIW
vSrqg0+ER5cY0iMd73VyjiK0rI63kjk1LqpOUUVRbJvkOt7h1ppVK8Fj8VKCDJVXn6KC2wnwHQoR
JxEPi27kWSvxR65tUlYJD1WSUU5qeepo7pvrOrs38UnSOREXV9N7yR5CvtGcbzX27cDsJbTbHvEO
ut6DIN/vCX034t3W1tbW9iss1vEm5IQwxHOVKDsKMl7R4u7GtG4XxXocf/9DTtvt+6Ow4WGPOcBi
nSHeV1jNIfpfCFNta7var7+rPTVXQVtbWy+8+RApnOHXHslztdWyOOuKSCOtSeRs7Ol4s79jhlTK
ws5YnEl1ldBweV7HG1bO7LuJyWNWxAYtD2Ta5OUCyVGo480YwsPy2TYlilEYOoGzPLH4vpHWrm8b
iAE+EKWkefcE7bZ/aiH6IMroDhPWOJqdRjOAnGzP7o/zrNFzVXS83fsKlBK2dLzV7uQjTWJNEGk0
nghZSGox1y/KXS2g5VBr/BrajVvPP9Izdd8BUrTb/zIm6SBSgC1YGTHTfcE9/32PujnoAoAuutEA
uLGgyHK8VRWMr7GOd8TcHTHa08gRxGMA8nkz1Bu+LyF55Vuod4TyBWH/tp4Mp0ek4x1GLgjpd8tY
kyGadrq4ruOtUMf7UVLbzzWTrWPvijCG2wjDmG3+gNQ7mPye3E+Us51LHPHDx96E3VuJ38HGhiQn
5LmcbC1+l22mXj9/7zyl/pJWr/dT9mhGXl7dvshnot7DHzV2r9YL77a2tra2thcaJknjMm9C85dv
DutBNibUbOjdnXm20DzULbTXxdgBzuGot118Mv3wz0G864u/traX9Ovv0O++Rb80W0FbW1svvJnT
dHOz4MEJksn1pDMW7vkgruONUTqm473sDEf5pqA+VAhzeYIeomO1srM36Xgv+e3oFF11vlUJIgGq
jUHs13S81b00lYhoidWZSNjcAPOvd7rVoRxRW4dkTvN90fsklba+pzWJ294xQoXj3HKOdqvjRMDH
uAIkbTTOAQ/QUvdcwf1Yfh5Fuzd1vJXlxOFxgC4uCRt/zqSb8AmwPioJkm6fC6FAO2h3EA6NUFv8
dyVqhTORCyBig+WaFt2+jo9Fazy/v0Beisoi5RrirS6qiyLgjP1egNZ0If8fRfXAeyWotyjn0LA6
zUoUBuI8cNS3tfxuIvb0WN4u0vFW2aK7M+03Z8ee+FpMrrcEHtoO+z2bO4WqSkzPogXea4Km43F5
xujt/fC7AhN35sTEm4MSXOtJnxqOJ+X899pGCm9DabO/N66nNLl14+hd1PuJWvgSS4BqdOjli70A
9X7jmvxvLrzb2tra2tpui3Wfzy1OVoylCs0L8mUz7FC4qDkM+zlfvOuCYKvmZET3hc+fcwxp2kHS
TKwjytH653O8RSuSmm1tn7MY/KIbh+kibW1tbb9+4a0rLmycCUr+6wbTMFdbTV7l7Ag9kvDCW7hf
Mh1vipyQ8tvTwA4wL8rDOVXdQMMv6HjfmYUldtQci7q7zvoQc964OqZug/SJQc4KOuwaMLk79vCy
NremKEbG+MuadpSHTdEbiUk7Edptyxz1Ao3yuIOcY3/o3C5snjZAwxmj/UMo29wv0vFG9QoQ+yVa
gRy3q+NNNJQ1i0SRIP9f09YYLwYDpGNF+niOd6xvzNFuYazFAUwyLxCPO8s51tUWgOodob6y8sWt
fBCnIX3xUA9aA4ZfX6ZDsca9iqTs5pUFeI54x9wC4ljgmb42aRsBV4BnP+cRFaoajI+cBwG+r5Rb
A183aqsKxp85vzvS8U4cJjK2K5y7K8zlNif9eR1vhZEvQiT9wkg2zfvYcpt5k83WD+37G+NmmoQd
6GQnWu+wQaufH8VNf34e0CrPjcbHqNb8KFCMtA3u7rvg6+sTHWfj2Hfep3kp2l286NOo9wh+HhL9
9Y6vohHvtra2trZfYQrJEx+z71EMm19kx44PErZ5YX0o2kcKHH2DjIOFK96MlCVv+4Ba4FM5Z5Z0
c1yUJ76LJnY+d9uX9utvanMHWpi3tbW1/RcW3hk5s3WchLCBM5RBNGYaZ7taa14t4EAmutO5jrea
HFhQzkjHe0b/1LNws0e7I+FyRcdbeX779HLUJdsxevdYxxu8dH5cltvv8rFRTqmS52BOrF7KZWPM
5bTgqL6YBq6oKRluNw7tXhr6PtqtYJWigY43Q7vtPa7qeGtJx1tAnYFngGPHkzreSeiOSqDZrnEO
7PocuUZ3bQFWYKam45/p9pfQboIsf/SlQ9Utj62mt12AHhnD+WGIzQSg3ShCIOz7kjv6CNk9QB75
5oJ6fv6Dopm+zqrthx1bRb1ROSNmdAlyxPkxbD6J2NF9n7TXUSXvL6mDYKompIBMxzvXbfdM8ppN
s87nurdf4cirqUg6i0D+Bk24NYQh07oNmcK2ZZy/ta/aOdaPU2j+RRFJymFvWM5XbAukig70RQmJ
JLuyUacb3ytx6nM+AklVeXgRtFq+d9yrYcD0U8zm2cXXj6/I9R7wm6sa4F9v/9d7D21tbW1tv8Vm
dBq5EX/WqOekRaJmylaXinLcF9Xjz2z+sbj+8/f9u8S5vG+4mGNv/9pr3RdoNw/idpwAoq/DbyTY
6xwf58477SfxTJAmuD9e5T2FWtp+cc/+hrGkke62trbX2o8MNUd5KgK+U1XKlh3lavvca7OTrQEa
nrBiUh1vojuc6moKYLTOdI9RsQto+KNOLRqOJiuDVGrMfMzzxgMdb1l1j5Hu55LIb/RBswmd5WNb
mXHVhKCIMZcnk3uUA+obDUCHWP6hxqrxIdrtziqgveChtKDjDY8JcriXSA2YB2jZ6SMdb9QXwW8A
XVJ5UsebIoBWBzdjmfW5qYjTIsy1LiGacx8tHE/RRd1Duw9lrXG5hpprPxaT6qKhdA5JV4AkFyJU
HrndAvO1YRSCu1Yhb1ZiFJmG3U91cGwkVN6OP4pI9lKOpD09wunxNTMFDzqmbjCiW16XtD+B6lIs
8hy/I9cGQSSZXNTxZtEm0zgaaUizGUlnbe6iz6ZilVv8mKmF96jBfKoaI8RzGskSmRZyGcRSeuur
DhB5NO5FUU+hFjnzLae/Z44LhR5t2F4qLDV7LN8xZ0l+jYD1PPi2snfyGhT7Kur9RZs7Gdr9I3K9
0W8aHjreEPruHO+2tra2tt9jGsgRHRrme+MNILSJ67W3Y8RbPZmbSOjgH4DECm0ml0ifFid83QV1
i+lkQ7ut7Vu69Texmre1tbX1wrswWGJEk+/1cbTbj78YkcYoK8oncz4S0uT10I0prwZ5qzIpauOt
To3YjdU/Q7qbpz6bmZNiqtHQFVgWFYQY5Qzfk/cdl8Xkfc6a4ogpV7WIlCM9bpQTWEC9meOB0bbJ
T/aNC3QFo2EesG5DHe9Aj9s344DtW1CKt9J+gtoz7tNXdbxxOVlUBhw7ntXxdu8ZO4RIR3n5F7X/
gCG5wj5e/Y32iey+so92I8mfZZF6YI1epOM9s5/PC2u8JJdFd9uh38JRuAPW2b9GdiTyRV6WjKDN
x8FR72ABrgjd1nXTwubI+40EMn455mnMWh22Ga1tdrA2yDW4lWzgVFB1PjYIQLHx5kyCbiY63iIK
owyukudBnRCNNJitjjdHZ/24axF1rdULmU91s40wHe+5L9ajA0QgQpsAwpQ03E5FL9gMKzPSk3fH
fdHnNyvicQQ7Aft31UJxN6Ih2S/vsHfzArT7e1Fvc/rYeyvvZp3j3dbW1tb2a8yhwmCx9C9X+5/T
+G8iH7Fz/uEI/s3F/vD+/uVlP04NHfwPr3pMi1zrmvxx8M9zLcef406yQIkWhSi8zi74xqPgty/o
gnWMzudu++5+/fWo99+231Xf1tb2QvsZiHcpiQjhspTHHOdqL8meHlHied3GwUJ/V3W8LYgeMUTO
aeqASZsWF6LxUtPxtvUDT1S3+6+ignV3MWO0wmfGOt4RcqtLWZiOt+aMnkry/qeSa8qcnu/Cs9zw
CG1cilpg50btQtU/G0K7YXuVUKpbYI5YnuIN+1XIlpvoeD/6MtPxlhjtlrVNi3i0W+WijjfRSLZ9
cWW+lbANORIuJf0ZLVxJPmuUr8uQqVSb2SyY2YLyXldHxDB90P7Lnv1Ywq9XtFuD/on0wG1d2PvO
i27U1P8svjO0m9U9qvcF9T4STXarWMAiI0j0yJFwXLg5RLDiRjWyInonlc8ecQfteBv1VuhOPObF
WBedvuOLOt5MuYKNo1vrWpC2EE6A1Fda5WHUtVEeoZfOp8Sj0UI03NqXDxMFx/0kNtc67pRlLIza
bD4OZG3gPm9o8B53GlEWbbbBiP+0ZnZ4iWKed+gGq0jo3xSLfQFZf8osVK3Fz0Hp3gX1fpz9M0k+
O8e7ra2tre1X2SNkfA2X/rcRd7jNPEYcdvPaDueI54u+5RLHv4WOIyazutvmt3lBmxGVIZLHYG8q
33hsa3uzPv3liHdXe1tbWy+8/WCMB8sI8Xo4Yfd/0x06sGtrJbiV6/3aQVzJ7rr9vXIsLqO+QMf7
8XvElH4P7VSl+dr3d6VA4zLQ8YZ54xGtrMTvOmw/+gghWFnicQgCZS4HxWJtieaGVnNnxSIq6wtb
GPyFacpH1WnbmwpFu1U42q0S538HiDZkFi+MA3s63qCtMFSO5W9e1PHWgo53XH6Altt2KUUGdNWS
01nRHS7lwxZ1ujO0246/aKyiyDh08A8Y2qqMq8FwQqyIs4YLa/t3Vj783vgi5YZ635jd7/8JZspW
0g4cEiwzyr1uYEQbESE/QIgq5+230jaVRH/5UQkpd+gyT7K2HerUq4aRFFE/jOeDdUNoeSLd1/Fm
ZHzz9Vc6mA0dbzf8qp/3A2WAeGxUOM/WFu0atwnT/7ONMNbO4siLaPwnY/wLNyRgXRWUFkRla07w
9yzO64Gm9+U87/TpdnO9r+zkfCLqrZufg9J9O+o94h9GfmAvvNva2tra2l5qE4EZJ106fFg38H2O
MKQ+YAO3rvNM0gRQbkTkdke9jygMe3WYq6jgvABva/sBnfpLEe+OBGlra/tPLbwpOczHHzY3G3Eu
r3LQFlVV6iypUUSEkDlDUxMoMdPxXnLdGELtrqVQG5Llm8O8Vd3V8Q6e0aCb6uoL+KpUx9tX/eO6
Fp0GGrjzc005pCVGT8UPyLRE7TGw7mVvFxjeD14pzlGfD9Fgl1c1Q7vJT1LT+C7lfwvoWwHvgUWk
r+t4a4h2uxzY9eS1XcL6Vtjfs/agRjR+Lj/KC/e53Vlkj0fn6DWqaPmFvFvEVG4XlktVI7TXSIUt
9yBrZJ/7PKHdJWRVSW63QE3uOyM57PPH/f7ZoqBUp1Af3MTJHEry5ZUitmhjwjKkR5sBoXb8/P4r
+shgDuP3k7T9Y9TbjxuurGT8Xe+9oV6B8sHvZSmiuay/X9Dx1oto3cwXwOb27B5Itg/5LCkLPHgv
cEFvfaOF14Y/L+Svse8zUv+rjJsavKBZNhAyHuHoKN18s1BVh9GyF5DwnbGOtxMNn6AY8FBDzjX0
6Mrnlvy46/tU678vYDb/btR7uAcc4RG98G5ra2tra/siu0uIEYmoJTz9hv5qEBYOHP6jmuN9I340
i24kB2g3UW5h4gj19ovXnajTRvPaflB//o4c70a829rafvvC2yPdVwY+lYiENWQs1j0dbxWi0W2Z
tNExrFyuTACJSBHOmkaqe2add8Q58r5c6+PeKoxNGiNpyhijxVzX6niXUGPlkQh0kp3JjcGOsUMa
fNV7hmOeJ7aTb0fPdWy3hgX2rknsH9Yhscqh6Uoed6bxHeV/U61s0k9dPUkSuZLqeCcawWH+dHCN
QMdbg6gb165hPmeAWqgm5+P2FF2jmrOXMbOjcqW53Qna7STEwOs+KCJ/gHHgQ087QNDmxffSfOcF
M0J7g3p6jEFZNM7HvQ/OLXEcWM8Zhco71BvmoAK+ERthMJ17sLHLtYHkGIB6+xxqrbcxsNmhISu5
R72tWoa/ftRPcA7tmkO/RlQpGQaZjredlHTS1t5ZcNr4iAXAdGzrhgdDg4g9p6Nu+vjCZp6NMbg/
aT68wnPuUQDp+TnDNkPSy+kgIcO54rH/+i5DuEVXycEOFUfSDQ2VjcANWtD9+qjSlAdREftLlJSJ
/TU7R7eF1eZntDYjpxRWdfGZl3K9R+mnd1XBbMS7ra2tre3X2d8FtVn4HmplDfV+7O0/d52bHNe8
kDl0y/m6L4Ldwvn4+x90Yo/DhawfEypvF90PUrie1tt+Y3/+ej6CRrzb2tr+EwvvMQrUenaHFGx1
hZlLBX3sDx9LFAJzFR1v8L1B9BQLBfsboolgoRT1qATbeYUs6axOhLPLunqYL6Qm546SED20brF2
qsDK0OkeDnkEROWPvxO2XvOAWmArz7REJcnVzib6MOc3Og+9S09lbK6VsJb7GgI3YznOtfxvkShP
mrfn6zre4NEp4mfeqe1TRR1vi3ZHOt4rw/6jz9DhkaHYiR48j6J4EdqdMZpXj6voKtuJziFnEc8B
QbvtvQ6A1i3h4R+yZgfLaT+mhflh2sFRXBCw8emQmQMAod0Z6q1C0HkpoHgW9U603FHfC1Fvwcfz
6/MxE6He7jeU660B6g3rSR3TdZj/HswlVQiQSjCbiIXaQjPL/+bz3/31aqy4kHGquPYRReyIep8A
MY5XFvxETjAa62zbQXMFjjhK+Buo72lcR7Ua8MWwvw1fBL4fp1ei9FZaoKbB3+uL87wVubCl4/Av
lzD30HvbRsKrOt5Cfg8u+W2o96AfLtV7L7zb2tra2tpeaB4l00UCy4XrK1+oHIqR8uqiZUard1jN
V8df042Itrbf2ZeFat5/6k3b2traeuEtsbPjdl91zaFyO5FES5tqL0//qnfaVtQ5Y02PkeRFgzmh
/8RApsa78KBsjzqZZzx/M4cCJMiEEB3v6CG8jremz/44jtc5bj8KowFYXjdm5K4zPjPt7kpbZ+fZ
/P8wC8ymeIPfdvK4MVpu2O3BeZm+PN0xT1j/azreAvo9R53nk6/qeHu0m79rHMXBpAQitDzR8QYV
E+otJ4g417bljOYa5N7mOt8TSgxT9lY5r4Mxwc+Lc+DoH5C1+ACa2fNi/TNYzSVddOuUJ6tJtMOM
eoshnBPGPK4aa2yT/PgdDXg2HqP70nNtfxN1yLFG10lRb9DXwkgR1DqFqiY4RYUl/cC0caLjveRf
sw2qko73qiTCxlyVNfqGztcwnEoXXfC8zWTtKY5as1/e5tBDDzd3QB3vJDrLtT3emct+rgLum0ub
khrrWO9ocYfllY0k7a3v43mfbuAU0XJ9psilCIPK3Z/Y/Hkh2p1d8ukzE9R71ef+2RtijXi3tbW1
tf1KswuUfwvJxyJaS9GXurCaH4SJ/DNZzddQcb4p0drDbb+2L38Dd0H3pba2tv/4wjtKfjYa2JVc
SGG5sU/oeK+jthvANUrKkWkHVflOqy3nEiKpmpM0ImbMhCl9DnPk+dp2J1YhZOeR+fV5UUkYsmuf
XUEup8s5UoXMvZFWtv89aIvCWVd3NI/1Qr4Va3+oj8AmkbCWuzNCtLyY/414D2ietNK2uq/jbfom
QQVR/7+i4436TQXthvn3pF8gBE4yXoG0rUvYT6r/SvYZ9Bc7Ri2fD9Rf/6HMTpv7CFQOzPu/o916
AG4QxkTun/n4VFbzA9exeU+LJnny/tYFvGmPldx9NDZFbN1ROyZzJuyfc3theeIR1wjUfCfs+mKY
wsHzsnbsNaxVIniOzfmSzI3hHHAvu5kLoW+QX2opGWA1X+rT6HgTsDvdPENjHe6fduw0Poe5DpcS
tJnK2P+kY6ySuYJUsgYqHnl7UKhdro+KxC+2wisAL1rftGC8PVohYJramGtzigunciXvWmPfhl1n
59jCCod/o/sXqzKXj/ot3ovhHF2/F95tbW1tbW1fv1ULUm3mkPRS2CRgNUcyXjHijSXPQsJF1SWs
2BKv2fP+lGlmSW9r+119+Rsc5Ea829raeuFdGKBBPidiGId5XZLreK9XQgnfK2LnkR+JGcTRpriY
fDdfRKgxa51QSRDXlSmc74L6nWulu4sqTMc73sFVcBzNz1H0zhUyNcN6MddhuqnCohA0yw3P87ez
3G+GClkmYjE63mijG/PpV9jHE9ZzhlSz/O8gcgSh3TteWazjLXDx4t8XQLJAW3HlJ2j3ysQrwgqH
0G6OyAf5elXeAN1dSO7ldkumy31/Rlz/KdqdMDSrJVJz2t12DDwg8rxIkx3qxtXbeLWylMd1jhfi
ezq/ljn9OOa88yOM8oDt3XToB2r5OP9QBXraPJqM5WVHfCcLY3nEvwGmbYZWr2obUT9JUG9SdpWE
24C0CVR3j4CwdcS+63u7NqSEyV2jKZDPOUJ0vGfUPPTFsvF6jlxb3w+KbIraDB0bI0+R6FAjrXv/
7kh0FhhPI3b1WJ9d83mumDJzdbPS+beb3jgdZ5NNluc1vfWJCA7i/lw69lnU+6nF0D+rotlvmus9
li/UnWM/DfJLL7zb2tra2to+eyPWbVDgxXfki3i02rOax+RIZiEPN9NwyDOSAsOL7kbn2v4b/fgL
b9oV39bW9l9feJP837uHZMfM+raZEi1tXfJPg2NFcgZxy7StRquUoIFw8plzm2XeQY11npXk8kmA
poplOXdF8Hmoql7D0t/nkRvmaFijVzZpmFtEPcr7EosQZwzpCUq+y37OFgOVBQO6d3QNtpuP23yV
tZz9RHS1JUCCEau51eFGCgQMBSjreJt6lBjthoEqWzrePP8TEWP53OwYWVbG0F3lEwgXg9X2HrTP
pJ71iHPrHeJ4EI1mmVE/H449533PY/oBZCruoeHHwccGwmq+oN1oge+4ItSNn5Rzg44Bce7vnzIc
hxZz9K1MmrkeGUMYN8bhpNgkRbThMSSvej6enxvMg+HcHaDeWcQcqlfx0VtayOUVPgSnm0bwO9Up
GqqqelKAywir+cyrgvyIcK481Gx2KdRER/0Tqp0EOhmsrymIvDOeXDD/krkiUc/IlFJwOScfCEQu
orx+imVr1IY0mDd3N1B2Njf29a2v6WtnHW13Q2YvYuAFKt5cxzu76di/xdfleu8j2OMNQe//672H
tra2trZfa5bVfPyZjMfG6TdH//jfeed9QfHnGn9+ul2qQoh2C4K7LSKH2YC8levvtca/f5l4Cg/H
33u+tra26iKxra2t7Tn7QYh3wLqID12/mJk1AYKHckEpajnvfkMd7zi3lOp427IE18K5Tr64PPeT
VZ2KA0YJq+Wys2odXCejro49M93whvnJhEXc3VhdeZRGJoBHpc+loW9v5X5CjdqISZWgilAyyCH6
IE8esek7xBPUaJ7ivSCaV9Bu2m8jVnDzjuimNNXxxrmCEdrt+r97+aAPA1TZo928QUHNX8K+D8sY
tKP5yhrqDtcRbpr7LTmjOa7/GtqtGcPxoeBdk2P1eDCCu2nkAM/9IGE61EYw/Dv+OLwiw+37OVd8
qcODo94zqg7re8o1r7zTtJ04lnPcj1DbupdZa22APc9Sd1pomwT1tuM16gfRxoYm+eSqDGldx2T0
Tijbu6GQEYkVOZCO9w5utih/MB1vBtkXFq8uyAQSC/r+XYkGUKofb+dEPOza+l76ronoY1GJyuYB
58eqRJFljmrEbAY+vSGpBUp5mVjRg01N3G+L0XhQl1zretuRhI9yx0A3APCd3O0rv7Ajn8oZH5un
jOdv8Smo90DnsRrRtyc37xzvtra2trb/hC2s5hsO6hrOfMDQ5YM4rmzRPYe+s8U32thgi25VaR3v
traXjhdtbW1t/8GFNwSwxe6aqUHcpmSj4Jo4F9Tmm0FYe7kzvAHSbw4QvggJjxjA151XrcDa4BqK
AUeTn4YkcZb6VIMUJDnUUMdbeW4WfNtEj3W9x9wsMANvjW040h3GT7mLcF/S7C52JCX6o2XWcnc4
b9dMazv7PdLxRi9VXZtI2NJtmdWPJestrup4E0ZbcI+FIThAu7P2YPMqRQKWadD2onaJ2meFb8Ax
XxNGc183hAk9ei69IcVrv2as5svid8pFtmOZzr+5IAbPau778T9kGy26JXg/EeqN6kjAePaQF6sx
0M8560tdaYYwk5xVsDkRRSxweTX/TtMxOlBEcJsnaL4OmNmjUH9cfhBlAZHLvD9GfZFOfnM5hCDv
F1mgHccLcb8Ujq/xuPaIQJmfG0sFiuXAUYX+A+orEWr6uB+a3+P6m6PoFo6Eao57qd4DF1Slzkqe
chTlmt6f5r/kzfrFGyi6/02Jg+G5XO/tk/XiY79RrndUAz8xqaoR77a2tra2/4Rdkd5RuHAEi1mw
gDqAw3sPl99YBFiyOLwxcTTi3db20vGiXeS2trZeeNuR0X8lRNNZDLqyIBboXLyraXHXx01iNJwy
N4fPJ1DHGxRGRBi76vyciQ6m+p1jrDk67fzD4+xOtIlSIDuwzrG0SDfMgxOgE+zR94U5l7ahHOFG
ZRWJt72znFh2n8yR9vqrYkJj1eVnKWNhBR8QizdFu5Oc6graDT9r2jW2dbzX9osZ+U3n8fUU6nhj
tHtGsUMdb13b4rooI9v9CUtuVG87ecDR79kxUR/Az0zuBZ5zYSgXMeh1rPPtke7DyXmJy+N+LL6z
d3RnUz5s+Y8FUbZO/x31Tpiv142AfOyqvGPLis7mC7HjenHcRBrcKGecDVII9cbPErXTQlkCJNux
ymeoveQ54O4e4iOyVEl/FnmJjvdcvyvj/J6Ot7v+vezzewY63or61YGjQpK8Ylc3JjoFb3w90Gk+
T3vfxN2UTWFLeoj17yTwI8k8dGGzTVOvRTxfDJsLy3eMf1fdPadynIbM5HHVVSDsq6j3F2yQfgHa
LeaUz0O9R3iFd9fu/l0L77a2tra2ts9wRIjDHG2W3W70N28bOfdusWzDuTHSxtA3/H2j3m1tbW1t
bb3wfsZRon8DREAemoaL0xQwOSPkbj0U63ijtB2GKiL0esk/BDlJlAHdoLmPRyRMtOLvHeaD25wm
D53CsqDq5s6rTkyisxZykidkUKil7kHIp2q+UwyJjaZ/VVEdq6Bkmits5ejvyMnnLNPsnatcSPHG
zOFEmxl953IohTD3Tven7OiT/izooImOt4JoDdxu5+/2dbxJHnhwD6+6kCNnru7mfiEEodBIZzhH
rquf8XXxfdnfOdp9wJHLooMWoba5motWt/pxQ63esQImcimgnAs/xgGmpMN1zjvqTdQS5nNuDOmP
sh2AmE0Xsjf2/vHvBz5W47ZVioQwnChunjLH2vmTtyGehx2h3hqwpj/uG1/HR2Woy6HFesjVfaMC
cs4imz5Bx1vVRhUGHDt6fXPIjeOyalivPqBuXdc5yEZ3POJUcOO/YzI3XDZrR3dzFfUpNNgUXPwi
q9/t1WXsIKTJZmNV03vnPSZ7m+QdYnT8ap73Duqtm1dPa+ZSN/jEjdVx/Rafhnq74x6fND3y/RDw
Rrzb2tra2n69XUl9jjYYjkPpRp9dmK/XOLw/t+RpH0F5VpRcgeTR7XOnere1tbW1tfXC+0nvyX9E
0svpSeJ3R5kzxH5QxbnfjLl8zplCxULOHWOL3tLxjnQhDWv7fVcWMjCTXN4Q0ZMlbxwhyILeg8w7
tTu5QWv+lWMr39HmBs+yw0qe5YlXEWzROIdUYZTE9BtjB7YRFRrnc9k6RDnZFO1G7VkivgLBaDct
C3mBLKccoeoao92u//tVU1B/StuqJqzGkHlfEoZjN1YRhGMTua7mcTO0G6PxLN+7jnY/0NhYyQGF
d6PndTm0x4H7GQkjx9dUt+iOOByOe47pAUnX/KK7vtLOUO9o/MpQbyUqE3bOOi7wC6DxoqIv7/tV
johHdePnCDIObfYzeNyCJAM2cjDGL5EAopd0vNdJeB44N3W8/ci2dH2kq+1VCHykhp0bub8Xo6ow
UuOujjGP25hHBvp6gnP//aGc2RxFYyEilldnC8MxCfgWOxuW9Y3PXJnm8sJB6/e7dpyW1inX7v/M
GU+ss94m13ukT67wWr3wbmtra2tr+xa7wvYdL5iy0FvCMq6yLDzRfeAiIyzf50v4tLW1tbW1tf0n
F94rc+uHl7H+qfNx81mrm+LyhByLJ9bxXnOUAOpLdLyt87UwPc+7vuaSEOF2z0tY1L1H5pw1peno
avILBV5/zkNUkHsuxF29s/3OSKDGeT4P9m5cHs0czwSRxvnDjO1eUy3jPXZ0XD52Dr42fpkKUF77
fCFTeJQLmGmyqqRo+KPFcB1vpD9b1fGef0Not2v5WzrehkVeBDLt+3us0TMMGdYiqZfC+6v/rbgw
q7Jix22Tt1nMR0H6jc1rJrnRLs/S8R8orJN/qDZg7z6OsNx3xuVg0fyPmVzdovvvPT+u71Do/31/
AGZlpBH++N4/wz+0cI4IUHy/DYUFG/1A2xbJf0eod8qaDyIjdlFvVm/2+pKWBfXvFR317RZww0AZ
72C+cH5OMs8QjhWm431ZM9rAwI/n0sCHK84J7N25V8nVWCrqIGzzKiMv1ATNXvwaUcB1wSWXsVtJ
Iiqtf0v0uxGbeZTbHcke1jcpd76rot6IqT9h75c4QqBEEK/FjG4Nfy3+kK2DPml59ceeAI0/P9f7
gYGP7FK98G5ra2tra/uZpoUUldtmwH1RbvOvzYJ+/vtGfrarH8zlxdra2tra2tp64V1zJy4cz3Y8
wVVTZFGzP4XQn0+/8mQPTfIR6bXSvCKiKQyZ0nXVkVSjeI4QAsE63uwhZh1vAv+RZ9eSfI9jtqVs
mUoYv4kjq3HuJGMSz3S6d5jLfZUU88aD5/OoN3w4eA5kMC2g3dlCAfUHhHbT3r6h4+2/e1LHO2J9
R5K6tG8ZDWb2LkgbwffXUhuqIJyld5ixSWvMLn0Z7b5FwEyL3GVh+xeR5uieO9awkB8h4iwUfV/a
4qFu0b1ylAAJsRvqbVFUUYw6u6iJGHHMUO8dboq/qL7kURolbgua4x8fW81J59wDmj+natDP2Rim
pX4D60TU8TKw/mxf8SPK4qKON4xs0D0d7wVhB/nNc864mLx247toAaG3qiMKf1MQobdqps9a3scB
Yw3NM/C8Zk3azVrvdOox/skzvrJAVRf2AFr1EwIelnjDMlA6ufp8sEPwsVA36cevpqy/FvX+JBvP
l+m1qLemVxhvW5k/buHd1tbW1tb2HlZFvJeFi/gQcuZz3UPF9Xq5ak5kW1tbW1tbWy+8q45G+HnV
tV68kQB5uV8oYFdemCZR3rM9lgK7s2bolP+37LxjhNk6Ww6B1uDYRX8UlGc+fkHLQE6W1VK/HaE8
V87eeJWc5KiqZzcHGqiVHDQRmvOvUBs8ZiuPnN+Kg2yddPys9evbMruGnUZbaIh2s47IteaT3+GL
CFDpJ3S8IcnDszreTs9bAJoR67yHUQ7FxZ3A+4Md+kL+K0K5WJlVN/SRwbNpUB8UJbfoos2bnrS7
D1Wigf1AfNd+ByZJVcdqvmpbPxbNnID/Y7Qyi26Urzzf97ZQn5HpbAyJkFqLmt/qIGxTLC9aQTs4
9I58V9sSGl/Re45Q6YxTI/4OzadJWyY53I9jfF63ZaVnSd4ahCLZ+Q5FKKgkUVUTgq6yQRio+bxj
1aPTwLY8eGppW3M92qgAi25v7V6pcmQ56EvweBUy/ksEHRO/lDOcK5yT1fG1UP3uzE9C9chDDOLx
Kc0H31WvyRz2pB1rjF9rBkvrRgtTWoTtPnahZT+/yHqLXG9cvEHO6oV3W1tbW1vbL7AdpvDbYj2U
CxOwiaDrb21tbW1tbW2/w37OwptskXqG2fQCsfYvPUWhsHOo4w1YNRd9ygwNF44eot1/oTre0e/i
87U5qD05h+rKQh3UOS8s0a/FTOmaoNmyMKlGbKaK8PQic7j/mzQWeCzPod1hEy6Xc2m3mY63r3sJ
2jK9ji1nFe1mVRjkWdsOX9Hx9lEokS5rQcc7Yn2naHec95+ykgda7yHqUNBnhm2ygEzi8il5ZXGe
7VW0O1UnkBUxi46dmc/X3O+p7Mf6Lg7VGGRRcLz5/gDv7IA5rnpH6v1/x3JdJkuWod7xhoOG17rV
tbJrFPKqS1rxEuVuY43x+3yUMO/DtibBfKA8qsWzmWPtadx/HioMSsaQ+obRBR1vMqYueecVHe85
tA1RjEx53rrM6R7VtvMTrks2v9v5guif6wOFPUjUBp0fSJtZokCgk2nLyKItgAN0JcVbAjZzxA2C
5pKte5TFrRN/SIrfMYS84PBvrEm0cp0dsXW9UIjPWm+9AEJ+Deo94C/5ub3wbmtra2tr+7F2ZcHj
NLqVE24d98V2I95tbW1tbW2/zX7UwntB6ALtv9Vv0RWdlXiX/f4/TVA8FbwDfc/VfpRZtZgbKyva
pYg8k+6m8Wdcdv1FTB65fS6s56wJY7va48T5lsR5nXW8IfTIndmF6TW+j2a5SWnulpi8RUmOr5Mw
wXaI8jWrOba3dwGaiGcK5/nbO2h3xIvw6IYceUda2/NB+qyONxkv9KqON0OUl132hPE4Fs2l75f2
U9oHFf5WiVSptFXcp2O029+Po4hM01wF50Mi7ononFmnG9bJX2bxA5x7a9fHsqi+s6KDMesIxxvM
s3FsL/ZxHrlS5G5FvStqDP/mvINca2U5lwpruShEsLPNDJUi/4DmYyjcLAlQb6WM/ZLkf4PxCg8l
dNylwzLiwHB9Yh5nN3S85YU63ho9hR8PbBSOZey3HDn2WVQ1eTapMRKC6EpNcvQZl45VI4DTaMhw
rn5OXNRjiH43iI5SreX2syZZQb6vb2zWOAjqw+Qj8iLSB6lFz+49X4XTgPpTeq1OL9t43Y2eR71r
v6g54l1x70a829ra2traSn6Tbh/PNmn4BoTX/m5ra2tra2v7+fbjFt6hO+ITfNGfOFeloq0pKJ+U
I0SaiPhlutv2BIV03PgaOUK9srMvO/hBWR556rOfmOUHCrhHkF8kU46XKQZG1C3awhEblJe2g1iz
vO5KHu4OQ7orS1EDfKcdu4cKtK6XzwjNliC3u4KYC2FolSCPTmLgxKE6lj1fQV+o6HjT/NOg7BmC
LR7NV5K/nmlz47LFCAfLF9ek7fp/k+ee7qGg/q6i3VEkk38nBDEz9ePWy4dBK4+1zo6EhyIdCxi7
+A35Pta8bTX63mskziESsWMnqPft7+OIeS54m8bPlPWFir68JG2BXmeJ+uKbIGwOhdEZoukYICXd
bj7+qI2iUz/3qGa5/OK0qYVwoYQ63lN5GIs+avwLw7ZL8tZ1vqdzqeAoMDidER3vaT5F7OhrfzpM
bjwk/HjMgQF3gJvIoPKFSuASEV8Rz4eYzbzoe6Hfzfmpn665gDVT/qmwm9dXBvqUtGLpXOifXFcH
cMd95x7sW+V6Pz799G3pRrzb2tra2to+w285dDPk/hai3Yh3W1tbW1vbb7OfufDGQtSzZ7PoeIf6
w0xTOGUcV8daOuGtECGE11GBuc1WOzvV8Y60ipddZgV5Q4rrNmN/hzreGAHjpyHtbHI7LaAL4Hha
/C1tbpvfGyPQV75f/kYETKQuEAqgNhJB1aPLoK2gtgbbHkK7lZ+TIuKu/cZ51vDVWx3voA1jtFvg
PaN8fs+KDM6TTMM0QAWzrhdEb2jCim7bpOuHlYWi8vqLGMpDrW+Kdh9LfVoU7oauHmryto8pPxuh
yvcF77GwgtsKV7AodmzmNxRt0v2+lV1VQqZ6i6KX0EQ4rkn4jjmqjPWmYS6qiRrQ5N2ieqf1kPzL
vsv6kNO4DlHvRFNclEZjCMrZJfwnkM6kgsJLnItvmdvdeD+N46/Q8V40xKfBWBM/SpN3t17/MJ9t
/8yeJeGNKWx2weioQh57pmQTOLlwnvIROVdozRUrhdh2mPhObODRKv04e3yNxzP4dnYZwSkhfOaP
VhKutfR8W33uGfHvq+usYf59wq6j3nvXv9/lTZO8G/Fua2tra2v7JLNkZ9EmSXXTsK2tra2tre3n
2Q9dePN8RJOtw8+RMCvEOUCxjrcWipnoeGfa2aZ8cMeYMKfCi7k8RvJ8riy6oJxsn5hqZwfM5bY8
s5an05xWFHngtZCV3EMTLUqONuVs5ZlO8+65ogqRk4pzXsm1gl8HyDVFu4Wfk10XlUtTHW8JIlfQ
+11RGFdOlTIjOf4O6bsWuQQm+AvlQ69jSDA4oHFLigzRYBwNUXNFGuX4PCkwmitgja+i3bD/HOv0
dphccn6s3u95GNR6qTchUSrCxnLEno7Yhw2SP2kJz2Vjet63Z/z32wE1ujPU25ZNw4ikIlv9QXKy
SRRXtR2X8s/JsSpB+0xQ78r1/fgjQdQJihYR2O+U5PZGvgzs90kahAsA1P2NIa7j7eP6lmgBRQoC
QC3A1zDX8Q7b+8xHczzud6wRNZqM83ROWPwhP88490tzHW/Ii6KMzVy4CLZqnvet/Hc+OIB5uMKF
VPFjNlpgSkOQ3r9w1lei0V9ydfkUiPrZS2pyznsreP/ohXdbW1tbW9vPtZI8U1tbW1tbW9uvsbdf
eEdZNGtqqNUxnj4BRCvS8Wa/TwUCaNx0A8X6pODBHHp9WccblXv+DuSOu8LIugutC+uoho4j0vGm
qDHJdUPl8Y+ZML7Te8SZPC7/DzjCFQ3kCpLNfleoTZ1cA6LrvqW4HW8lkRMbedoZGu6uUUHRXW4g
0/H29WJ1cW1/gm23oOOtsoN2I0Rdt/NUEdqN3nucd671NkyY0un1HNIX3B98fuRVKz///vkw7wrx
Y5goGPtWDqwXrmrQPYDoHRPyrWLYzR0qrstYcr/GgXPHH8zh6tG7i5JiWR5/nktt0f35GsfjGGH5
4Qg1XXPsIZoM8qhZOaPQ/Az1XoO7sH44ZUyfy6isvWrKym/fCZ5Xc3ZnqnhgfBnVwBcRqUVjSVF9
Y0fHuxi1pUA1wEeTSdgmtKS+oqW+tfgshCtg5hkS1dDvxHN4UG+uz13zraMUYq34WVNllCKx2DEm
+gIxntfScVRI8BEvUVlMOz9Gd6+jO/eql+MTFmFvgXqPS3fphXdbW1tbW1tbW1tbW1tb23/Kft7C
O0pZdTrekBw61/FmmtELqqxhmebjfWETbdSSjjfYpQeM64yl2GKCFvlhZVEVo42suU6p2Qlnpy2Y
vOrCFF+5zyN3c/1dwU40f0ZJ7pEzlNN6AN9XUHKVGHWU4HeVWOt4+Yy4AYh49pZGdzX/e1fHG2q6
ItbzFaXgZQA61AW0G6EUUEWAvP/5WVAOt8u9LmhzZ4ggbEcVRBFqfIsfQxJ0SsSPg6q6hXYfRa6M
w+hk3xnGSctbUPQ591slrG+MNB9/0W6UW3zLHRXSrj0niN7zth853KuGN0cBc1bxeHxbnym6lnun
h+HpODZY/QvM5rZew/YPBTw01gIPcmx9u4zY/R9OCYqYoYzwIGdXFPPclPylRVc7mX/UqkNorKiB
L1TU8fZ+jCRjPmKEZ6ojsG3SdjT7ICqE4of2KynqeGNCnzoHkR+TVq1yqN+dQcKJok3Ej6PwUmWF
609dOITNlIoQ5Wo7ddQ75U+/vAb69PXWG+V6j83qeUfc++ctvEfw1Rj//pt+uH00X7svBzhg+e7v
12P6d6RvdtDCDnf99f4DPvD9mPE4Zkz/jon3f4AHH9N1xuNCU9UN/zimLH+PsxU7/HO65/n4Lzpt
vvvtee4lLNzn9gzrrcdSR7f3wp9RknuM8NphPYDv52vQY8zxErRf+/swz43a5RDUVgZu2x8vhP4+
9x9wjP1uLcuAFxqsKLbu5nYG3u/9Z1qGAe450nc6xgBjzKNO0esdqP/e/nPlNr11jLAtRmWk9Wau
S8+f+uEAp7P+gNv7oL89rn3iMfl//5yoYYBh+XbcOMf9mnjs8fcY54mvDep7wPHplPM8/bF//ney
c+a2sI7l599z/pX/PMd67EDtCfct1P7i8W19puha7p2eY5mfbu8hGiOr5XP1JnH7Q+2DnefGzaC9
wfl2oPHhNg/6sY71De+HuBfC5zAy9oxpXgnnn/m/29wJxqxkAp3uB+aFWzsd3o+RZMz31Xj7PR/L
hpkfXf3cfZCb34PuGYyTzn3y/pug32WEC4ZBfM/7GGDfnYD5I3pnYIync0nS3OYx6XsXQiN284b1
AJD/XVuUDHrMSM4e1Uf5+vUWW4B/wpr+2X2H8Y37FL934d3W1tbW1tbW1tbW1tbW9oPs7RfeGKN7
/DZhvOs5804/QFmHkN15cEeMhtsvZIbUDLrKYfr592VPbEYrhhCE0D/wWg/Td8sOJt+nXMqy7EiD
3WdX5zUUSNwOc7yLbO8By+0grBxVma+w7HKPyk57fkzl/vT6oCayurTIykBNZEbzTNujbQ21PYIc
03OS61rU2favYS8zfL1M+ATsT7DtDvTzPuq9jETuOUeKCrJ2KeyYUUN0ym0YIBwR2shQb3g8+Hw/
7xz8/PtnjnrDvwFm9kBZ7fhkI0t83Zx/UOv5mHO+xuO3uY4tknZ+IOf2nfxBrc/z1ubXucIiw+U5
M0PREzT5X52cboj5d+75OEYkRGjXSA0TdYBQ5OH7MytnFtEB+4N3CSDavUZeYLR7jAHKO/82+G/g
neB5NXv/A0YBIV9mjMAXkWL0lhQiw6bItjGCZ8DwcVi2M4mi8UNkwRexQ2ox+m3xWQYeq8c8j4wR
+p14Dg/qzfW5a751hFiPip81VQaba6N5bb3G2taGDDjPxz7VSAF9V6Iikl05ZuxeZ+zcq16OT1iE
/Qi0+5mje+Hd1tbW1tb2iy1ayIwxuoLa2tra2tp+mf3QhTfOb7I7eDR/dNRzaJZdSbBLW9rVFSE5
e+RYcUV15RsIuwZozRCSO45yANHzubKMBe1ke+i2DPfjgiRvW57bTvG6e2+vP8I82UHuEeUpzdeK
XmoF3Wb5cDvn2t3k7BqpA19JB6yg0+NCHneQ/y00aGHgzjCE8jHgNryi6Qj1pkj72EG948gaeI0F
LbIInWlD0eCAxq2kvQ2WSy4J2ughH5DXSlBvwWgpRwrPpT4HyDl11z/XXORzrHwW/NhHLviKZp9r
vdl3G0UuzM/gcnk5l8iM9q25q+ffv32e9+MZb/ng5zk22seAHSzMq5X83f6r4zxnuoJ2l8ZMdz18
rOW3QHMnRLuL1/fjTxSRwPLCfb+zQXilfGDU70fSLuz1x/7G0JpXPNwcsHgSjgfGcvYMF5UzfA3j
3Gr4nn30ndwiPobhKDgRap1toqEoNlBi5H4RLgw4166VhKdJhugjXhTgK2N3KI40hLg5jfDY9GN2
tjhHoY2G9y+cNa5c+WUroc+xN8rtnp+5wJPXC++2tra2trb/olkysp0FY1tbW1tbW9vvsZ+58IaJ
hYvXsuTXhDnWDEVLmcpXdGFm4Ha5riO4zogYg0GeOMttRQDK9C9kWUZ1MNdtEhng69yUa+CHdzvW
Bi2iBK0gFxFftea8WrQvRlMsGsdzGq9+v/wdsNLbukAs1S5fzSEDA7aVNE8btd9iHrfre6iPmCRu
iHo71nLfd++8BqQND8hoLvCeEHVy72E+N84RryyyRhqV4/spa1OVNg77IbnO8vfg9Reh3ozZ3B63
liNGvW+I1F90eMqp/vN3xKj9yAM/F1TaVvgtv3t+n6dFDW/IpWMyP9dcT5JPvOaPj0sLcMain+V4
s5zRtay2bFEbW5nN47G8knte40dgfcjxeJDcXNguh8A2h/rJgtou7QJzmwwyOFXQfVondm6z4/00
jo8xwnm09NPMbTANxiPxo0by7tbrm4iTYftn9ixxWykhr0gBIw0hI1GONScXzlM+r/tKkveIEeuE
2fwSr87YYO4e8XgG385WNYwgxTvzR59Eu1EYSeUSX4mqW0j6DdDu6vXvd3lT+LsR77a2tra2ts/w
XU5O+sRD5GuLgLa2tra2trafZT9u4R26I07HG+9apTre5Du8S8v0t2Ok3f5Oc7Gpjjd4BnZseL/h
9DOjsjzy1KfildhCxeVqhzlFt/xuUwyEGiDIM0ICIpQQOsIUjee53NF1Kki7K8uIEfboWiNrl2Mj
T5ug2uU8bnaMkHw1CXS8Jd40HlPe4HoCQ6S9jjfadq/oeLMccfruhOt0U+bijXefMfyOwXkzorbr
/02ee7rHAPXHcsLdsUIYhMOcevsMvJ9CluTT8Faca52dIfpaGAtO0p8//rvlc5/nWt9Wy/vfb6dI
ooe8PvdHtMA5M7Hbe2XtHyDGW/nldW6CqC3Q68zjSIh24zkU5q1LIQqrMiYPPv7cyz7QnFdX01gj
W4Tq0kPOFIv0j8/Q8R5u/LNzL4ruwtPZqtW9jLNTRIDXmZ6f+ZzGiWXiQxMZqUMyzw40zwwJXCLi
K+L5cMxjDtPzZr4X+t2cn/rpBW4ZxFye9fnUoUBewBN7mGOD2LysVl4tz9g8/jMXXN+Kdqv79NO3
pRvxbmtra2trK/khY/v4SArOO5sfi+mzEe+2tra2trbfZj9q4b0gzhmj8MLW7XOlIx3vIQEiOaea
gnxvsbvTMsLdP4v4XdbxDp7R7/YjZNvUhZD8NIqgC81jc8Udnjl3uDpM8rEMcp3dJ9KftM4wRj9M
rmCQ91u6t/DyIqTBPmSIGt3eBWgiDMmGWtU7iPXIf4d8CqDNrrvgTMebscUC/U8ZdLwYBR1vRHtO
89vGKDOjh+0iQ8gZOhGh2UUt+rDzFtqfrz/hbXVgfW1YB+7ZCUMx4J6IzlnywVGdnOdfLe8B89FX
8rRzQspQRMJZyHdf6iFB0vG7OeF4yZUVVrQ7yh1e1SROcq0H2j3IuAVzpuUCE/8m2i2FPPEB5juY
ty6MwwB/58fpAbW1R5DbytRPbJsZwXkyQDRQ3uVr8ne7Ot4VNnA7F7KIlSE8yjHRuh7Zw7txORjX
XT428ZkQdwK+HQmeGH5OHPOzMHZzH/E3Rg2hZU3yCkYdtqOo8cavprSS4Pndg85fUYVsp+5nTzPy
Sv5yRvMXXuoq2p39MswR78pw3oh3W1tbW1vbZcdQ8k3Cyc7B0xfO+0K7Ee+2tra2trbfZj9n4U12
SK12aW2XqpirYpldqY73ejhjIA91vJfbElQKoOiDCoBP9w1/t7m6kmuKW4S9gJx5jU5ynlVdBmzL
/l6y7LKPwR1fiDEk+XG7+d742IRNunBcthgYjJE6QJphu0Xvo4B60+iQKO+2RELLdLwT5n0beZKh
6ZGON2gvoXb48KPCvSSJzvsg9wnbSsZbUGDWh21yRONthdGat9VIq5jqf1uUaeR94P75HKVjb0jd
mNjRXdnP9V2cY5RYn+3ie/7+BO/sHD6fm+l3z8zPd4T9zHJ/fW53vuEwwmvd6ppxEyC0OxrL+Dsr
MqO7NsNzcXF0G2fgj9n9sbIKHs8HZ/sfRm1CxqUNoBo2mWwwIdWIjKNkGZ+GQwmXCK17hIr4CB3w
3Nj/CHS8BWurL+/mznsj9ygVrkZCxhoYIQF8MuhscSZzcrNrpOYS5HojHhE0l2zdYxQLIIk/JMXv
UJTDqDn8G2uSUbnOzl7quFCIz1pvvUVut1fu1vK5vfBua2tra2v7sbaz4LlJirFzzsGJM8/W8W5r
a2tra/t19mMX3rG83arjPXk3MKfXXSjIV7Vs4PYewx6bodrD6IouKMCGjjfUBQfHAqbwpTzz8URT
HFb6mLJ0LYu49179KxmCn3d+LZZ91B5XQIXXd2RZQJGmeJ2JvOKU1/IW5fL1bZldw464AmwfMW0j
YumvRGnQ5wA63r69Rzre5oUSlnWocb+j4y3C0ej5jEFyxCVmu4fj2YYWvb8/4Fkt8ATkes81Zmqo
0S0xszm8ZhH1ntHhc0KRI/3p87T9Di+QZ13utW7OlUmcBmd8jFbnyd/fwAvzvznm5yiPIRFajFDV
84zVEai+9QDt4BwL2l1pS2h8Re+5ipDvf4fm06Qtg6iQ9Toe7fba0yMcb0XiiDDE2eC0yoXkIE/j
6Ct0vGffoqbjja85ovnsXOsRoeFjp8BmDqnoRZd8gCFk/BcfUihgfkPtDs0hQpRADP+HZzev+ScY
lR+pz1DR78aa9jl+jhHsQkK0UFcIryCyyNmd3O4dAHxD5vzLFllPgMevQbt58ZSc1Qvvtra2tra2
X2A7pIlzetHyvVl8L1szfxfy537U4wZJVltbW1tbW1svvJE7ceF4xmoJrppqKY/sT4G7gQGSnDKU
2xsE2srRvRASvOzEL/li0zl3RzFgK7YIe/IQQyzbZuAZsgiFwNl1DLPBbjRmlyWO7IiFFCGKHiDj
o6jFHVfJCFl/UW4uyIAz3j58OHgOikaQDCkoIAmoP4xkdxkh0PZ8GCnC0PSqjjfsVywPXMC/QUSC
DKh5XWkjUR561oYyboFqBAZjzB5BpNHKlE2uMyHM62s5lwpYtLaX/OyTskfDY02+/ZlFvNjyoLZ4
jgXFtu8NnfuPUX3V637U1QDjDUZe2UCL0G70/uNF/spiPrLjxsg3MzbQbhlxe424CEYh3zws/0iU
C4QT6VXqdJ6XZRBt6sGVUx4I7AgBz7KO98zXIsXU1RkJHwBJBfrdTo1grGNjtME0Bo7wW38bBDkH
zz0+ImOEzxcCNtisSxi1m7Xe6dRj/JNnfGXZQqpH1U8IeFjiDcs46uY1GO8IYeaxQ/okz6HdL1nu
fKa9gNH8dWj3TblbS2e9X2X+uIV3W1tbW1vbe1gV8b6FoN+I2Gazi+/57z8O/r/w9/NyuaINgLa2
tra2trZeeO+6P+J2PwfS8V7zjFCKp2MbNbnDC2ow52CP9SqCtGQjBu8FZZ7vjy8Jd+Td8wao9+qV
mSuQXdXbVRakWOD1HzvFBsGSHMF96HiDZycO750gFZRnZI5xgjzj/GG8+5qhLPPfY+TIOSsfOyfS
UbaVjnS8HeeBBCjyCNpWAfUeUkFuV24EhHpf0vE213bX3dXxZu0DspJH91gVGTwCxxZWQZ4vaEfu
N3mOkyB8hwljdIQ8RsenqPf8vgnPxMxYbuvkXx43YDGfdL5RuW9s4nd0/ERa4GNBqebF97zoXs85
72zpMcfE/L1/hn/s5qdjts7Qbti2Ah4A2LYgcvnof2cw1rFzYp3tOheB64PCuUkGiiChaDdWBvDs
5iTtNpovnJ+TzDMEpUTjKPMDahtNmzrehDekFkVzn1DM5Uai452Vn7X5k5Yfjs1sHrmx0w8B7QK/
LuxW8kjMYaoj1PMmn107ZPNMmre9FyUVN1qB+d2+/ySRHRJHaRTS4IMoT/BCSk8mFwHa92U0fy3a
zY8McfA3TvhuxLutra2t7dfbuJD4HDuT2abEGW66nsFCN5P38ueNlzxvW1tbW1tbWy+8qa+z5pSx
3SqGNs9fISZwcg2iGw71kO2uK8k7ZQ4TuhbT8R5Uxxscg/IAhywalvZiA4QODBk5gj5kyQu7X6Ki
4y27bJdr/pVDHkYt505EMPt7AYGu5HJnTr3TQafMxD4Pbi17Rcc7z2NeXuwgaAVio6eMveg5JSzn
szre9jeEem/peNs6Cutv0LbKdvwR6h0he6OQh+7qbewhThVkEn3HkHwZK59EipJPi1r7OcxpBug4
zcc2DMd/r2pYzVPGdnjNAdD6RG5smJxzMD89Fun1hTZDu8eoR/MwrexIA37ui2dhbAwR6020WyR6
f/Wy4DmCjEOb/QweNwZAM3F/tr7Gw1+w3lJxQ8dBhGPNya7qeK+1vHT9RcdbvI9zKw/cjCKa6Ogd
sK9g7rUM59sMEME0Ur4d0hfCEYMzmaO87iGvxT7xZqH3LXY3OcfVpGh5BVnkeI5GvPbUpXXKtfs/
c8YT66wLqPHnoN2aPvmA1+qFd1tbW1tb27fYuMQ/xBdM58k3Ts4zCqE+vZ825g2EMyiPJYHDC+l/
eeL9ztva2tra2nrhveX5VP4eANQb4okfAeLJ2I6pNrc5VkyODtT89uVe8tVkENbfhGkV5FKvjJ7Y
YRzikQqSpLuic8NVGCwLqm7uAY9VZ1Isii702XH+JnaEKRqIdFAH4S8dqI6hOHQ5N9s65zuM5xzp
Ye98UN3UpRkMUE/CUN8CO7nNx4wYTImO93iBjreTdxpEH3VHx9v2KQnywIN7zOgRYj6v6L8v/UJw
1AJm1uW515W2x8rHUG8pcyKQ65hF6IjqZNL0vh9vxrVF3mv4cWNYvePhF9ZDagzxruxjXXTbznnL
Hcda5Os5N2K2R9m89vj8O39P7PczRbtR2ypFSRhOFDdPmWPt/MnbEEe0OfN+wDMwv8/kOh4NH44n
A0cJ1dwhqOI8siNkQauHBBF2yd1hGxeDw4Y0K9d2hpBM351fxvmAY+u61s6x5mQj34q9Y6jFPTwX
zdrgcJtC8yis1MUvKup5p5FjGT4/knYjtbmLtH/Ggs5yvq+03riZWK6avatvkqVfWBi92J5gNP/8
3G51n0Z65Puh3414t7W1tbX9N+xZf6VIcndHvdGiU/xC2y7EobQYDY0/v9Yxa2tra2tra/uPLrwr
esDjgQkv+3rz7iJE4whD5nInlD+My2IR9dIuIchT5XmxSW44yc+16MUI2EFvxyEd7xGVZYhDjTJC
ILtze783YmIl+VeWYX0IR2/ncmf52f77mHQp0/Nm96mwsVodXzGI3wBtMmObj1Bv/92gbdW1mUDD
PtPxDrrGlo63vfZ4Vscb9jmLes3/knu4Jsl1deEJqI0V620nzzb6fVe72ed1FxnRwXP+qf+ZsMyR
lwVIyjlspM75yK810TWnQ5tHMQ/+IS225qafZJz9QLtB2DiXDxuuPPH0yd8xQ9ZDtDvgD6BtAmkZ
s5zrgeehHbTb6qGnZSlFGmGdejqmS/Y+sbb0fUwnbNbuXUxz7SUdb6fysuZkV/OL5z609POJp9vN
Y65fnXATC/kDTKVkmKhHGMlgNLz5PO19E7+7RkZ4wBM0BOt4r3NLHrm3sweZUUUOiXlfxvYd498R
iv38tZ/R7x6FW4z9U16emX+xytnxb5PbPX+MGc7fGeH+fQvvtra2tra28gJg/xy7QF8Xumzz57YR
YGXCcpketAjnC8x5ET+azbyt7WXjxdmV0NbW9t9beKNNML/TaRnDkxyj6Sz7pd9J9zreDNVeroVy
8kLmw0HzuSMG8OFYR2nN0bLDXfjJq7tHDcDcNsssHwhyC0oNHyBvDzm/A79twqKL/OEFHRfCGJ2g
04Non1LEf1OXu6p/eaUjjQtJ3gO2m/XhGfs+bLPJ75GON3qpVR1vV9cz6g2jHtzAEDCagzomqDe6
x4IeAQS42h7W8xMtcND2onaJ2memYW/HGFguPyqQhWXhucYHQmwXso4l+VwWwbdFtUe+H/c8B9Z+
Ryga0tr+S3h2e7azoHIgt2fJ9ZNhVNINoT9POB9w5vAT19VI3jeLuIAbFx4hziIsGFdDOEaPYP50
aL0Uxno0ZhSZy+2GChfyTvtj1Bfp5DeXQ7COt4Q63pXNqcnvGkyoYgRFG3Dx6zkOkJIDkJmBXCy1
za5lBBueGwLWS/QKJhSdV+i4WO+BC0r0vLOXPKTangRGirzcf8mbdX0NcW3lkX9TRruvlWBcKf4X
oN6fj3bzGnh/fPuHLrzb2tra2tqe33sa+yiWIf+b2cRZuPrsJ8xkZsuC8cQM6dHmxj+CtJMsmKUR
77a2l44XbW1tbf/ZhXddQxBD5JY1eD3wqo43BsNjre4I2V7KElxrS8cb5qdyx1RGlAM9Val4tIeV
xbJpDils+N5ZV+0Bwb4jQds9kSfLAQMnVDS/zW48qnvmSOPr8ZxLxGS91pl5NvPdQnO6FB7UKEK9
af734Gzk9rvgd3i8ILT+uo73IFwHHsVCVZOg3gK0x2F+ZpBjFqDesG2wMma5u8E1s7YYtecM9Zak
XBT1PnPEFPULixwPhD7e/zwfOaVuGkG5zo9F9+lyvc9lYW0X3ysL+dq3I7SbLb5tXZ7neVknexAW
Z5TbPRA3hi3zqLUB9jxL3Y1C2xwC25Adr1E/WP8e9DroHlkO9yCdfgQ5x2sEnfFdaH629U32dLyX
OdvMq1s63mG0AXEGJr4StiHFnAA2DizNYgxhw66t76XvWh4QgtoPNg8gRvMgtGyQXPFxMa+bzZ+R
n/eos8DxYb7sqCHgPuLgwUtQ2Q6JohXgeqCU351c5hKmvY92jyeusc1Qrs/f4tVot7qfNKmR8fYw
eCPebW1tbW2/1+wCfNNhfZBCndNCJpZsxNc514WEFT9iC1Cz4RaRhzXi3db2yqGj+1JbW9t/fuGN
d5PRhm6oFZmxoQPglOl4S6KXbW6y/O4YYgEqSCeCOXdI1lxs53ASHW+G+iz1encMgSPrrr/uJlZY
xO/H+S16CU67HzecXiVHvmQMnNeNX1GQNx6jfDRaAr2PAmv6bl4t+m5AHW+/E+/ebpDkjZlO91Fv
yvgPUG+IfC8NgHE34IUS1R2/rONt6yGKCPGLJs84XmMFd322iFRLAT3O23vQPpN6tovhq6j347vh
yMj+lvsEWvczE/owY9cwGt8CdtbF61vPaPQQpM9t806HGz/9wpyPP9EYM+dtn+cIc6lX1Pb0dWVz
VMF8guaFc9FSJ2i0AIZygGpTNY77XB2j3XAeDOdu0gYF65dDZYMB5mbhgPEoJquW2MTZd5+g421z
uwcaUwb2I8K58j4+2M0oNJbnviJ/yiwHnM/vI5zjyVyRqFAMgnlG/sKwkYhQz3vE0VJsjndPPOrt
NjxuZ3MDnTs2zrjAkj6u9AXZLudzTwlMzb+jeNM3yu1Wet5GGd8Q/W7Eu62tra3t15oPn5b7YrTs
MjlSSS+zJaHs37luoATSVku5Cdo9P9cjFLbRubb/Rj/+wpt2xbe1tfXCO3eUkI63/31kO9YykvEX
sKibGw4hTKsjKDdCdoWwfrM8RbYDm6Glw+tnU33HZZd20J3FIVgDeiRRBwMcR1ObBtFuHyM5UWC+
9MJ4zp5rYHSjikjbMjBUMdNJHvZv4+DDFLpYGTRBvSv53wixBt9F7OOSMxNHHTTjWpivyRmJr+p4
27Y/fL+JdLzdc42QxZhzORTzLzfb7I6293p9c9xS3gHGZBJWjVDvADlkrOZ8DDzDSIN5wYtYzRfU
e8R1butrjL1c3HXxfSvbaVjKpfaeyOTzQNAe55+IWVwCFv0A7a4cA/PIB5kzhaDUYgjuwn4ywuuw
sg+Jc+eFtAlUdw/RDxNvN23KiNkQeoWOt32fw/pSRR3vkY7Xjydz+uFjlLk5orEryrdlUWF0Q8xw
dHC+EqbAIG48hD2PRbJQboDXbnDgqMErN7mKikdtdWPT5CJLP3V/Lh37WrXzSxf7BNT7K9HuNc97
uHPsp3fX9P6/3ntoa2tra/uVm7Af3tVtKl43426/x5Pz33Dz41gWMidx9pXEtc2LeJ2ODTc6ptSD
FfH+c+6xXOPPv/8W343Qtf3Wvvz19zwb8W5ra3ux/TDE2+cRzz+tu+IfbtaIs3lGhpRZOtGqjreZ
KaiOtwPWxsQsnut4P3aLZw/PFRE//PSB5hSafER3XMhOiTUrUx1vlsc/PDOwfXaPFnmW74WxFVyf
5SoOqAlKWNZBPthOfmz0d/Rd6KkMkiMtCWu54Pw58I/5rsKW7rkNIh1vq+GL+wND0jFTaqZRfV3H
e73/YMoI5L1Cpn4ZYb8QxCodMJaLH4JSHeM8RzhDvUdYrsuotzxytq1OtmMIH3iMu+t131Dvypg+
8DP/Rb1RlErQvx+pmQWiNslZzS1yH+tlY+4PNFaG7dW846WNFtpVNGem7OWMbT3og5CZeSRoN8k3
FzLuP8Y6SFQSbhrZMU6SuTFdrc5otRs/ri58H1wtQ4yCyawoI2gMTYorXKM8VDIY4mnBBXD/JKSE
47R54dj/pGPsIHMFqWQOdlfag4eKvZ433BXkRStod1faL87tRmj9CNuYa3OEtXzIlfzuEfs27Dqf
hnY/kQ9v21GGem8wmn99bvfu9Xvh3dbW1tbW9i1btcOwmt9lgoohmneStUkybJfV/L5xeZ7BJge4
r/xD3Nc8bwkWzM1q3vZL+3KRk+G19+y+1NbW1gvvfGB0bOYrSQ1kSh51He+B0rrFsh/j3fT7/YK8
Nls2EZwnBh/blXFssJbaOpkuhjZWxWgTD8z0KkAbm+6vAh1vl1c9CvuCw6OeQzLUOMkjjNpEwkBa
0T0eG3qoMas1wqqjnXhQ8gD13sv/nsJ6yXns3TAN7uydCiwORlVRA8rYZvd0vP2YYceGCuqdLvAI
KsnQVYbCSsBOPoq/Rb/T4xzjNl9cos+zZjZGH1YU/GRM8B/H3lFvw2p+AhFny2o+pvzuE+RG2sW3
XVygnGz0ulF9uIX7kJCYDUYRzO/kHJi/QkyOMZpvqJ5ynvcZ6oOT+9JzBbDrJ9wDax2Ju46PssIa
y1zDGvQ/ElGDEGSMAq+bMlYkJBovaSREpOMdjLmW1ZzO1wT2fkQsSqHNVJBVHlljv7zNoeddPpBF
XJj5NpqviLKDdyhrfi7Wvd73nbNc+EuRdkIUVcpbpTvfx/M+GzvL+uDPFLly9qjc/YnNHwYwM//n
zXK70dkfyVq98G5ra2tra3s3o4vq+0LFpqEQP87ldXtW89ghHXc5r3nxbRfd7r4G7fbSY/Hiu63t
9/Rl3G8+/aZtbW1tv33hrZHw2rLxatBTq+Mt+G/moyDWcZvWnep4C8llFnBtwi0dprIPkCPutBoR
es90vOP8rioy7yp3WPZYhtaPhcXUvlv/sNN9h0EXwOUt2bwk+dv2AUeahyp0V94eHOXORs47ZwfO
OADAu3Qp8PZaCWu5ryFwM44AhOdFiHGAari+lep4E6R5aSuErdi+U9unwvx09Hw8TBih3iXuCsKo
KwnqiRQARojc7enTp2h29Tj6mferE3A/0H7yceyZPd/px6s5PPz22TKOo8X3yoI+0rDaqA4ei3gv
RYbHoAnhNhsWsca17V9+jD9JbjfU7Gb52Ukk1cJ8Tq8fMcn7/lBHuwW2XVxPPnc+zm0PFoJVdmjh
G0kjUQKoXW0uDp//5jxjP4aGnlowLg4+1pkrIzbx/KnwewqVCpZz7Nzg54oByx8j+Nz3FB/lAPS8
qxsNO2M+a/ehavrgKef5Hsgoo9brOFls25QQfdCP1/TCaasP/d/aQsr8+wLU+9tzu5V+uFTv32XN
at7W1tbW9uvsz+LzD5v5X97yj83cE8gv/ZnA54XuH8bw5TqA1RxvQnLHYUa7Z1bz8+SI+R3tnjcD
PhbxNxbz41DDkN4IXdtv7c/jy1t353i3tbW92t4O8fZo9zXtwGhH0yHCNH8S5/au7NsQzjK53+QY
Vi5XJgnzeGEhhTDCBoje7bT7cUBDFV7r5owKQxqZjjd4VqjjbWupshMbsBjTSXbeHM4ZpAchyOXs
56Y+Rj3fjp7r0Mmxtpc7Q7N/2CFcq7qm1W3bWpzHzc4TQhIf9VNXT7Kr4821eSMET+SajvdyFEDR
UscP5nMGPLuEQZxdP8xlfyXqLRmzOWGTHjkqHilV/PnxpIoBJxgHCOoNkJ3T5dNOqPensZqv+t0o
Iuc8B8yNRhrFFu0WGvFhUOGJqM6OR2fCUo55VQpo9xCgHz7qbSzgEoBqFADtHhKg3SkiOrh0HCqz
jT6gfc1cexgG9o19mVTH202YAlnNM8WXhQNk0S4fSz+MxxjcnyrBAeicOxKdnj/Sr8fYYD8vLPxR
XvfLttuieUhiLpKl7428qvDzjxdod1+pj6pYdxXtLt7ymdzwzUdLUe8Co/l7MZlr6Sd9U5LzzvFu
a2tra/t1diNsE7SxaDfJPojEuKTQacit/OLyM1nNZ7Q72ozjxEGf7OC1tX12f/4Gxv5GvNva2v4z
C2+U543koW8eC9z7Gz6353GhKuv4po53nK5EUbgB8r4z1uZJyZroeI+4jDbfLGMZl1U/mz7jAGzW
sYSuRzrBTq8FZIdhWB1Ix3sMo71al91heWsOkc5Qv0IOWzTpZxrj4YsAh4wwv1VWHXbb8dhPm3nc
+OhAp7TAe7Cv4z1o3xOmwfuEjjdURpDNnP6xlh+z2q834TrCvp25Kh6DtkV2fvR3uHA8n0O95x8h
2kpoJpzm96x9HZR9AJK2BVUWjNi9htW8WKfuvXntaId2o3EuuNcJoMVzxBJnI2hXGdodPfOQbHNC
0vYPx6Thxw1XVjL+rvfeYIQG7+KBBo/agpT194kLI2IITy5VO28eu8jcnt0DkRginyXS9V7KQvov
epwxq7ZIoMgiOGLSRyAEVVYZN0fwgsa82Yj0vAdkM9/N7IdRkAjt3uYRqG1+vDa/21XSFvi9zYU+
oo+fgHbvot5oPVY/dO/MTbRb3QNqeEQvvNva2tra2r7CgOb24kR/OPOW+Az5PWe4+VBYNdzc0nO4
xTdbXNx9wI/jENoNw+03UME7yt+tpe1ndOovRaC/A2Fva2v7/fYrdbzdjiQArD+G8ce/6a5azDq+
hC0yVFvAvSlRzygfi8s47WqPBEEHyLdFtUeQk77uxONnuL8rsGs8Ajjc63hLulMcveuw/Yx7hRkm
aQy3ohzgiP0cPWSm6T0KzOVLSxnrC3vkZVpt23CtkaDe+/nfGAn2J0aIdRj9QXWgWFtkzych6j2k
gHpLrOMd5peT54nLP7gm68BcDyXUemPcLed6X9bpfiwWlyEYhY2zfP8gr9s73CfMtaa5pSZ/ePnv
DMLXyUI8YzVni2/0jh/a4uOx2D4HEDiYxoxM/eGji57mfZ1Odi0qM2pzwbg38vZbaZsjyCnHaLeA
KJkR5JKjHHQ7x9b70yg+Z6TjLSNeSNZ1vNfrL8Iq8kh1QOO4BC7LcFFqYhQfwuU4qbtiVABZ3I+A
n2Uec1h7Hhl3AW2D0fhPxvgXbhDAuqoQNxaQ7bj9Fef1SEnnwmZOZb4rcpzv3O4VV8ttN7e7ICj1
vbnd0Qlqfn5f9LtZzdva2trafpXddLf/TL1/lpz/72MS9hI+/8LV5tD0w6Y5/bmWPHapGZHbjV0c
L7LHnZBrvn6kS3xHu8GG3I3FfP33mvvYqF7bT+nTX57j3dXe1tb2YvsZiHdpo3IIy2pBFxwol2PR
FF1ZZkWCXIwEqV6cG4rCxQzP0FF6ALRLsnQF1bZOKL0XfYZ5lzbYeZeZ3RQhIThKgGnrLuUaa86W
DJvD69lnUf4TndApumAV3YdDiWCzGiPV/GYoeqS/vBQVop6ovZnfAGztUB54IugblTzu4LyoX4VI
ymUd76BvMub9J3W8Heod6HiLbWkjzlkbBJEfI0ZVUH8Y0W/F/O+RaDg77eiLqPe/v0/af9mzn1br
dkyod9A/IdoOc71JTjRo6n9Cys+zngccqR88EPfzI2w9VmlwHAZAzx3PxwnRXIJe2/G/pDWfvJPK
Z4ZsOmS/ohsfsqLP8+KA7yh9x+xdXHGdAkR6a11L0PBoHqXcG2P149Y64pwp6XxKPBqmTsLGrX8R
MAFrvcQRSmg+XFUCojabjwNZG3hwlATvcWu3LvbL63nZu9sbY/MSxfzu0A0eIqF/Uyz2d6HdL2A0
fxu0211kyE/J656tEe+2tra2tl9jf50+1Yde9jTFw0VVgchM7X4oCSeliPcsUaT/EPY/yPd8a7Tg
Pmc5qYk4SY9jut9NizxHu7MNRvQcjYi3vUe/lmY1b2tr+/H2O8nVgPbz5HpQp+TmIPlvg9xwDImt
GtiZjnfAqmyvtzhyEuSuoWOWnPSATXpIAdWWWj42PGVQnU3LuD2Qjveo8EhaLVo2qRrkenjt9QGi
H9hdRQQznhd0R3ecAJwPPjkovnGBrsAjBSLUO9LqDvO4GRIsPk98BIzoqD3TxU5Zxxv3RzZ8RKg3
vAbQ8a7sxlM2ZtImmS73GM6Dhe2uijZWfqN9QvKc1jT3GzCgQ0T39reVChu+L97+O8eKevMef7v3
ybkahDMtn4QhfZYOo+3ixow+OEXygnazBbdB/uew9vmzHVhOoyARRT24Z0SKAfMxo9BmCnrIseya
1ZHG99hDu/nY4CKRRqyWQftSyjngtdqvhGgzHW9OcDbxi8jAkUeCfCj8bDaHPqyXVMd7lOdUdN25
L1YZ4P1YXziFkILDafwFed0lZZfg3XFf9PnNingcwU7A5fzuC0j02Dn+HfZuXsBo/i5ot9KTf84m
WbOat7W1tbX9HhuBtNk5nOTYSLzjISiv+7HormyeuZDZjCFdJrTb/kc2M5LYT0qWBRfYwQK9re1b
uvV3tMFu921tbb3w9vlnzEGgJDgyobiIvVVIPu8gueFUIBaVPdbxnu8/8sQQt3M8EgZyWmzZ1fFG
jK/eMX3obbMcV5JfOyKkULCOt4BwNIfsogCCjV3NMcQC2WNInJ8LEPAK2j2oA49CBRBDPGF5prv2
AyLaYf54hRUcPNQoJHnz6A+OLj+n483z4ima/aSOt0tADxnHTZ72KDKVm7YL26XEuvFxbi3Ws6bH
D9auxpK/vYt6w6YyReLMiPg5xlpeMy7cUe+zFhFgGcRHwMjMdJrXa9WQqDTHOxhf3II7WXCcCAUv
tJFKe3qE0eNrRsz/YyP3GnOWeC6BIZL3J1BdEBEt5OGvbdCj44yBnvX9aG4Zck3H2/GiSCF11XhR
Q3Ak0ezHZO9xBPNpHiHw8E+YjrfPvWfXQK860JtH497g9T6E3Z9HESzxe8tmG/Row/YScrQEpa60
HzwfZ9fg7PaRB1XZOyl7YLvoeGnf5os2d16Ael+/yfpRLxWc/TbCQ1n613da53i3tbW1tf0a+4NC
6589ZTjh/lk48sX6zfnUKZ7tL6qtxyoZRhZ7NMd7ctZ1OjbcgDOL5zHle/+9z0cu+5/NAT2CHO+Z
4b3ghd7yz9dNim9yFtvavrHNdY53W1vbq+3HIN5IKYXv2hnHaqD8VDO4jlh3m+1mrZuXADMfTDvZ
StrIsjNp2ZEHKmeUwzQzPF/W8UbMrv4ZHu9nGMJwnFc4hq1AruNtvxwsbwztbEsQPmm+HhM7/BJK
urShQZ7D1B9gmI9y2Vw7HTFy6AqO6mtg1HVlBxfabkLU2yE/QdtY/iFIME7ydseEuWUJ82qu452z
weMc9vG0jrcUdLx5NMBI8j7R8404t3XD6WSauehfoeOf6fY7qPfg/B1jiiA4h+fjsDnKFjk8M6WD
cx0fZ4T9jnqjSISw7xdIpEDUi0Pm/+SAb+b2zs9/UsZtX2fV9lPJ74ftRwKm/B20O+BdGYNH96B2
itnLAes14mgo5HJXoqCYjvcYmNdjV8dbNpipt3W8wygqO2aCKK9RqTsbIreZ547alnH+BuIBWGY7
ouggIGVEUERcTjtP5/JXbDIE86IfEjjCXB+Hxsb3gzj1WRyGjcjcK9pr0PFvsicYza9ffP34CrRb
4Tfrt/rGZOeNeLe1tbW1/X/2vkXLcR3Xld7n/v8nF+50VyeRSICk7KSe0lozuyuJ37IkEgTwI9pN
0dw8+vzPdeStQfW4BRE31PtvUPvfPxTdojK6j3EOuajFP5D6uP8uszViaPcNub57i//d15/P/p0n
Wai/ETG3c4via9vsttvp9/qT+twbsTPbbbfddrvSvh3HGzNLaFoIUX6z9C0kXF/i3TnSYypuOJ8u
4r5TH2+3AkNGKJtWfMhFdkDu1xkfb1jCxyaXe7/Xucr45HeLmBAPVQw2PsNZndv/LvDHgo+36CIq
uxwJ3unCNNGPJ887R8BV12bZaLBnYdbTHwX3HfXnvIJ6s0gF2XMQqLfkcBc+3jozH9FjsG3FdcFz
m8/4eE+nl4l+ISJsRWDEEJVqex2UQvrMzmj+uiL6hLqdQr3jezVWCY1q5eEdJ+MCC1aZG8Qbuw4b
UG9b4QqD8sLVOb0pVNjqsnKmS9H5u8MvnlwhzEovd9k3LOlHFhFaqTSeVLSBqZJnzyv1AmfXUics
QMafkVpw1sdbA4Wgc3dRaGF3PjRQ+pDzawOvJGKVBoGLHe3/YDnXOq+KtEGzBfH+tJD0YtxE9VCS
qgJ0kl1Rz0ZVGsC4PpKuhGyguEX/yZIWUo+oLztRPANb4I+f/V293v5y7Vtyu4/k68Oyf33FR7FV
zXfbbbfddvsR7WYbRoMnxTyhyQUn3vTmbcLGoLtj3YS78JpaRLOS9Ps2TtTsTQR/Psx/E8mNKsCu
ky21XeNuuz3tvT5hifakA1Orwt122223Xxt4y0WOGERLrq9CntVRlDcshSA5l3lGvaKAz+ypbcMi
K1d6ZVztEtW2Cpk3eg2P8x2FhzgdaPIgHryz/fPkdDs0E4xFZtwIv96Q8AmR+o2n3c0vbMliIii0
NlH0CU0aj8uep7hp0feb8Z+ITgJDgqCVyTv+349dNPjgRR897+OdoN6JKjv1lj3r4624p2S8okrN
FtFBEFo4Goq5aJdn++tKPJ4TTm2GekuUUgSHTCV53sZkQFrNN0z3gR07nBcJvh/X8zb5gtfHN6rB
0eXnqwA8C9B9eTwg9il50jGBkSmfY0GZnPUtkPcZMtlRbdvxAmfvNtrPJkVrCx9vlhx6oLddDXKb
KhV6SvvKx1v+Wo67+TjG+i7yMQmga5EyuE/HZeIBgnThwibuahGT9Bk4WZTnJQZ6iTYUc0B5lKQP
ld3+3rlIPdHSKVznd1/0Uv+swOmpqPdHK5l/qTt5qm2O92677bbbbj+k/UGnzN5FzQ+XJLVyYT8v
AN+tSm4q3z6o8XSaMWD5u/V4fIea/Qm+j//+c+fxFhfA/4L0jJ/9rnAek8ZdsUa2yPbnzpOKj99t
DuxuL3+v8Vmq5s+SL9ttt912+3Gl5iwDKRAj01zfoEqJGRqU3HDTYjkUqVZi3v483b759y7jz643
uV1QExvZT1A8l89iXvSq7Pa4SVRvT3y8Hcf64ePtDjMtkJm3bZ9oNPUp44iNnsQVVzE/boZKxb6B
eEMzOwCi4D79CDl/3IDyGmA9/29RH1FWaVhJfXPPq+XjHStM4mnx8wMu+nj7cwCvBpBIIzkXJJz6
FJlOgy+2nb+3q8rm7rMV1Jvs/y96HD4bx5S3eVyrlH0Vd9gSn2ZRpnoLqqd/3yVE3uj9Zu//G1xl
VDNAOYd4F/3Oj8mikgCJJRvtN9Dz4Vy0lCPW6fMKmgwK0a4V1NlgpO6v50yH/V3x8VbaG9NY0/Xx
Hiq41qLXwaOc+3iP+DHCfJ5cq3pWqQc2QpKMv2dv1GSFW7WL4/kEXdHf9TmrtZXVazayVkqWzY2l
0EX+82ISAy3hprPn0rgZq2j3d8nRPEXR/PVo93GIz5s7/ori5hvx3m233Xbb7Ue0mwf3X+vt/3Oo
7ZEkBtk+3g2z7/7X/8ngcA6URn/uOyL89kDJR4/sMfgO670E7YY7HvPefi3iDafgjqYY1G67nXuv
P6s/feaxd9ttt5/XvjfiLVDDYA1dJd0w81r9NlA7geMYGvFvRo5Uax9vm5C5wIWsfLzH36yg2phV
z8fP4mYI93xOqnKP4WnJi/i7iE5wH+8UZUUDFTU4b9fkWl2ZA5KyVa+UHBXdGx6t1W8r9AWJ77Pv
89VLgmizPXfPhq+6Jf7fxMc7IsLk3bvg420tH+9EVwHsPU6QulUfb38fRdY/PuoErb4h0hMiaWVA
1gnaeKVP4r+c7vsc6h28rO8q5m/ufCHfW3mNXrGcKVarRbt7z94Sh4K32/fE91kh3jYIvb2J/a8G
FDLYDtv4qrGaK185N0S18viuqfeL9Wkkx4rvSMXfztBu9g6eUCJPxlUkz0+NPeY8tvPFgs1K6lZM
EebGtZnibTUquHpfRocGvyQSrgfVyVumB6HugX4P8z4ftVLQ8iT36wK7zOtGu4S+qojgkHle/cHG
lurYYk1Szon8dFsINjr3pPN7fsc+HY394tzuvhf3wYXPv6iZ90a8d9ttt912+xHttsgOEy6sJUI3
/n1DvUceM+hCD/T4N0/xw6IN4cOD+9HepmTYMXyWJ97ezy8u4zoUArXvCvG+BR4Zx3tjhLs97b1m
Cd+PPPZuu+2225Pa90S8vacziOLybXFAlgGQNigRnorJ454PdJgiKh9vZW8jlDWZR/B8H3zGfl5Y
UcXzZIF3//eij/e06EWeB4+oKFxWmHi42owOMqXhiMyj9FZl3rOPBed4z+v7Nt2Utud3wgXrZpLh
PVJJvytXGLQE4xTqPX6WsL7J5sIr13l2hz7td+w9vls+3kyPIUe9w/M76+MdzgGu7+bIY/tZLCDa
GVo4o7F6AFOq15YoFd/R7AmNNHdcrx6OYCNm0L7ho2UYGwtm5JQhoG9FkDxf+1uikP7mvKmzcvEV
f+Eq4EbBzVYIt/92Ra069+bWv2XfQXB+6T0iCDE7l/CeJEMninfHn2eV/BnnLEuef1r1RtcPCnEl
/d73C9Evq2SY9vE2p0+Q6wyoFR59HgQZBuaj030iIuVZf439APncrNY/6RqCVfrlCYNajb7fD1cS
EXnFyytSdOh9v4Jgvyjh8uXyOKdQ749FuxVwrXjeXxTovreNeO+222677fZNm2Mav/0lUQfU+09Q
fAx55mnx/KYXm4ebxXkQgMkO7JbPfnC9jxCsHv/gdEyfzWj7W2Ev5FFphlJ30O4zZdD3BODtmH/+
PK7vd7fdOkmhjz322/bx3m233Z7WvjTi/VhIaAXsYXSc12LGOYMx841JpVyqhBs7FYKuIvPxtvhv
aB9vluFEy5t6/j6qOftbKTK4HQ75eO0O7wg8a3cRoz82Er4xAP7gU5/1jo93zIzrrDPos1GLg3EL
iL6KRACpFkHiAQDplfOz5D2X3pPw/vgt/T7Ztg3utGcEzurW/hw4ol1y1ZTKf8vHO6lWAXnXoJ/N
so+36L8AH4NmDjkpjIavdKlRb6Oq7JmfcM0fD2NUcs8y1HsaOdF4JweP7ExxPOzDPFce+fhO3yuk
fGOICgBVfaO8zPEEVfPs74A6E5VzLchWe7xnFRX+fe84PIA4ZDAUG1yuOvwuHs9of2XbsftfXm81
nopt5zGp6gsOtUeXs2vTuOZHcqB6761MBs06LFwTAMzJhaD35U1I3o/qvKV6OVvaTGuIzr1WWh8X
c5YL/PLsutGE4funz/oPyiO00Hi74P196vdxy8PsTkH6sskuHZmRq4lfv4bbfdjKN1/17m7Ee7fd
dtttt+/VhpLXmc89+HjPmtvvUueNIMJ/N+0fwo4NPAl0Z18f87kct+0GjvRdSf3fr47G0uWhoj4v
VmIi7+3vUZ+lam4dH+8QtG7UcLfnvPsfH4Tsvrvbbrs9p30/jndDrXBebOUZ52lozVQVAy+4Pqk1
H2+l0M44aZargopkakCzheJ5hnBf9fGeMr5CqXq638CgyspuKOGF+2smPt4VR1+jy2H1GX4P0VFm
Xh3Hntm2Wtk0V5LGqBpvCerfWttwRNoot1HvLPf/FsfEwrsPkwi4r/xgqPcVH+9avOukj3c4f+bj
rTjkvGoGyPiR0PzCpjWVQr0rZXPlaMA5vph5qOI6AgeZoYIZWjx4LT9+a/l7T+9Lxk8XSr/k3un7
Z09DvDm6DYlwx87lx72GV7c1UDckfc1Qot0Qsiu5BgIfu+i73VIi54OZRL8LH+/VgDFW94jfjM+S
vHerSTKEOX78fvb6Du+eKW68eFcVOjo9UwSfcP++87VYw5saeh2BInmnKSH5PBidD0CmSJyyR+tV
4eWTtNKL6J1Kr7Io3RYLm6IfdnR3J/DhL9m+Cre79fnC2X+VthHv3Xbbbbfdvk0LHtnDdDv5eP/n
fbwrrjZZJB4OOSdian7RPPp4PxYMkY+dJ4NzvBvEN9yj1CPvHCx5KYPbAJ+X2/xZ0B9NwbDjWyw9
d/uK7/xK0uGZx956Bbvtttuz2jdDvDVy5n+Higs1/nfifiW7dVtqbvjMczZSagfGtnXo7YgOZ/7b
KL73nt8ZijCeH6xYnDJU26suV7bhiL9D4pHouWtqcRj9c9WzdBzuxMcbU3XAsAdwDiwE+Zl6dHs/
92KR4Xmd5o/H7qmvhIDrp+6eZMlshnrn20IqoRMb7/CmQymTkz6LjA/u350n+Xiz90IrOJ/x8eYd
gPt4+3vn/HmnR7LC1TZxPTooVL+VyuZmKeodENq3XPG4w1ue+k7i4z3zgd8WA4KImtfB7Hzsx//e
0vuDJ/h4W9PHm80TYwUCrOCOI/+smu+QzK0K7a74zKwKgb2PmVc3lE7E01ZAcV0QucWE226Kt8vn
n35wOs91j3t80se7IQQI57gB66Ov7Hi8n76pC6UcbvHmtzUh0HnuH5wYQGN9SOfqs8/CEscY6DXc
uVtQI+nX+ODzFkd6/75W00jyV/DtLk/2xLnswHu33XbbbbfdRFJrViX/G4B7EbRx67dE1dtqe7Rs
2cX51ESULSRNVMlyw6JJBN/xvN6mEvROcI6myBQVXrNMuFHQhXbbrZEk+rRjb0Xz3Xbb7bcH3pAL
IUbynhc77p/ip5FzOC7Qetxwt1cnwBMWfKhV1Pm5kPM2hQq7hVPgavdR7drHez7u/XfEa3zaxiER
D16Su3dQPt73A8brmjxIq+W0ce4l1vzbJ79xksbNFEHRDAYsXL/bX8K5ngOBeB6ZjS9HvZNt2c+R
v83zkcjiv5GmhiNxS9T6oo+3ZWOFe2/O+XjH/qzGPsohJ50O6ClM84qIhrL0qld4oh4d3HGrAPKN
nJdAyHMfbzj+ORdpQ8Fv9D7e8RoEvzf8z8Q9fa2PN/zcMv6GDjOQXsxaE0MH/zpZMexXKforf+VE
gR+iKoaLn+v52MhzqrjslY93+z3IPp/6tFPed0mUSZNgvHcSmjR6/rPGxvi9DRVYoIkrqfMAMlNM
z4XcS6sV1SHWkLzyx8qEGefoV8Ila0KQai+P9VedELuCoK+g3brrNPpTs5sv87uTEMIWnVPGj24q
5tU88RVjrMcV+CuKX38M2v39qUqb473bbrvtttvXXQQknO6w+L3xk5XauQi61fH+fHf83+wHngaD
9xXe2/+2/+/u6+0Vx28+3lHVHMMi5CgXuD2Od76AZ6j+US50eZltumBf+e1uu32RvgLbHO/ddtvt
ee37IN4MFRTZ75BZJNyumav12A6FZ23GDZ95bdNSLF1AUcxv4jKLBSNBAgMabQkymKkxw1JEL0yG
iPcOQOrjfd87HEOr8htHzEZXiVPIUszhlBARfID4qo+8fYeQgHnF+lMSvNYOb5zfQ6KKTysYkCiz
angaiSe9cPg2jnoLJfT5xTQjqIglnvQe0X48R92HU9T6jI+3CsJIkBK5oTqykj7edFDLOeSh7wUE
LkcZM4Xerk1VKygE96OHQLf02MKVdM/5eA98T/QQ2DDO/yn7btyLcSM45XYjfNFUiby6LlUJIfrW
hHAXKNmkrpwpoRdBPe0rDR/52CUG1NWglfcnDQ/BEzeU6Py8He8vr/LxntDscZ6l1U/9/lK/J242
OEvxpvOQq6Dw1QkwXZFWIPMdCklI8sm1RW9MzMY+M5QJrqvJALYuRI9YTfkkac4NnTuer0Nid3qG
1nh+iefik/mjw/J16FdrR3mRn4d2Hwtc7uP4usj4Rrx322233Xb7Wm1Eg93k6hHwMdFwVxQnauQe
de4EGEGBG51F9M29++3v9viHfo+7uOrj/bie971FxPvN5pJb7eNtXR9vICxxSvuv7LcWVc43srjb
maD/px57t912+3ntR4irQSAkVVYtoZeGRQmA5awYwYcmJLu3HxTnIjiGQokyLLY8QuhvTIRrp32h
4ek5ciQjH5ufIwIfW/B8hY/3VA1AeOMtH2+GOosFK0SmHeDnCSjE0sRiniHvbCFLOOTtPHEX9WbI
NbtHC6i3aRV1iY4UVRnMtzv38UZEuJJ3S6HetD+7/kj9f9s+3vGcIgIiVJmN+5XXOgdMRb2Pclfj
Nv09cwNooKyW8ZQ9j7mhCH7nfnddJOi1vs3/pecT789NQT2i3Yqb/xaVmLPkBrs3DFksBNe093tP
zE0Jr0lfbssTIPFvzGhwgnaH+ZKh3abGnQLRtLzkv+PjLX3HyxF9eB6W6OMMcy/ncT84/S28PPh4
x7Fn+h6+X7i/2boqzCN6LRR4474fG3EySLQYbFh3ZO9m5iXP5/PVRICvJBzfX/Fz5Put3Gw6a/M+
2m3lemExMhi7a/clWf9RA+3+DirmYzvKK5r//Hgl88Rp3J/iF0S+N+K922677bbbl0uk3ifMu6c2
/xvD3yMqPqG2aaIsX3Aehf83wMQcj/tixU/8fwLjv/zvJED/ywtv3ivO8X6g76OPdyaEOS2thgDn
WFqYI03Avu/vuLCY3u23jge77bbbbj+hfWPEO8kfSdVqlp91CN5NfVYtIypu+D2zKXiVfj/eO5yh
zcg52vPiKXoNB35doXh+3w/xLEXGvwbJcK74eE+oA0zf+pmrJxeFVQXAmBkfFFcZqg3nj0xROs/3
Ds9aL/iDx2+iND6ewci5nPo/ycAH1FMInqsMNc3Hz1aqmptNvLktUqej/6uwClcItOSASzQ96Tdn
fbxFSa0/59QKiow36vxhic6AOQTJ6PBCtlXjp+/S66h3x/t76tvTuO7+Jvt5qGwLZe5FH++/37+h
5GW3OLD37d6SoHs81xntvnHF/XN97L/vZ+stwLLnVT5T4xoB93PKqjMIdDrdK+N6GtU9Z2i3rG4K
732BdiPnx57pH71Vj8n34cGnR2lkMVbXANeD3FDBxcZpMa9m39PjOCR8fMaZL0allb2ikp+52eCE
iKDWJejsyztUIOdbN9HXPtqN1jzA5iS/j5YTilo8tJ92pzf0konZV99JxTy/pMP9d/7zU3y7G/v/
ynd9I9677bbbbrt9nYnfq5hXfw+rVVVW9ldE7b9DJw3d8TOuOA2uJpXx28LyuCcVPWr+vv8H9zv6
cs+8cB6kH4OIO1M1f9/PbUF+mBYhne6rS9IcCwvxM6rmGccbGxb/7aPBU4TEThz1kW7cPt677bbb
E9s3RLwTKUXGCWQLJv4LsrjhiwyF6CLzKIazqmTnzjcTnzcQ6H8/yDjl3vNbeZfPx0RBzuXerEb4
2OnjHfjYKWIxTdC3f4/3feauoevjzS4POd974oyJ/jgpFFvi5268b0m0h9LSkQiYZyiQussaua4U
yS3dloYC9HyN9N0qEc+5m3pxH7yyV328ybOJ/THqU6z5eMuhTypf8/fQuTBIvi7nJHc9lv1/tc+x
xd+ofRJepOcMt3y8M463P3dDqmKvkgk+QL8F0DHotuG7uZKI+Xh3rsG/Eli8zywgrlXNhyoubTkv
PMPB3TbYc7Gk/yFHE6nWQhjTxZhA+m7bsm3Rx9u/Lyt+9o/3urT1ftwJ4eM98rvP+3iT54GHS7wJ
3Rjt482vld0ro9Uu/j5U/Y6dU76W8HNfNVZkiSr+XF+fHGhcYisB1+N8Q67Ul064UkKvnHFOoN2j
BOVnJw6Pp/3+M5XMX42Sf3zbiPduu+22225ftkUV82QlN0mXj3N94uM9BoYH8fH+70iDa+bjfUe9
/4HeBzmvEaVGoSwuE3EZyl8EhepviXiz5FezRHbe/0H/Zvd383t/+7tvkWLyQWMOqnL43XbbbbcT
7XtyvBdVCBnDO1fpnRW+ORUzcsOnPcCraKuF0+x/PW8/8sTZwgR8tZN4E4cbohaUkiOrfKXH3635
eN/5mBb58/P5IBwrRa7l9Y3cv/kag4/3dMqFj7e4xhKLuKH0lqAclqilG/HxVvfD89HBNMkFEo7k
7fLPgaHegN7WoebhnRSougyGjCskRz6nXuBT1HvFxxuN/ojcr7f28Y73HsIvPL6zvC+Y9DlmQV1y
/xpoIJzSf0f9ugoekR6TcLVRzSSQfM++j7dDdSuF9CEhMKLdmY93Z0aslN7ZeEWVUSjHmfRh9zz4
8UARbsiKMPTR7gYPm/G5UaDdag6g14e1KovO+8KTHJjXHdn52ah30LF3acxkE0oOrq1R7RLWfodg
OecfqHpxI2EkveT5+iusE+g1WN13k+de3Sg27Ux6F4mewpm1N6p1WOM+p9UYybn0nYz6l9dauTXO
9aug3ddRb9AffAbaXZ1p/9vPbRvx3m233Xbb7cu0CuFWnG+/zcjRVj7e79znQy48j8S3uvLxHn25
Gcd7RORHrna94Djj492hQzzU4eH+bgf4DbGzyp+8u7/dfsdYMAvU4uOOi7XE1m677bZbp317H2+c
WQQQ5I3/NMueJv9miKlh5jGla4qc8yszydQH1Rx6r1G8cT+UO0s55g3Fdef7+TgX49c27qLDxwYC
R9USH+/82Q4LQKZWLp4N+8Uk0IKM2/b4W5WyKt/RadE/ocoa9VEwA8scozAB8Nt7LmDYLyrXb3Z7
O+eS+HirbmM1+pg5ALRQc3Af77D9RR/vcPdTJJ/xx7mKPucw+gUp8UqmAWleGVAho2GIY0gNEMak
zMe7s7D+q2guXB66kxHAUXOg0mwYj33Bx9t5c6d+6Q2ON32G4M7QHf52fU7kmKQipYN2M09mJB7L
ys+bjUmQ43aSXEj7O7i/M3qqzHAofu4BTXy8HcvbV/J1fbyzNcRd62QaDtGiMXA1fTenMVcDsAUS
mQUzH++Ugw0yN7PD1BoRFO1+Aq9bqZtnfvKtc+sm1Tq9B8ur/mTvOLOb5XbY10G7hfv2wu3+fG43
bJ3nfXxxIvhGvHfbbbfddvvkDCoCsqpVzefJV6HE97LK//7iz1xYiSDed0VzNolDJBWEj/fffZv3
FB+OOVzjiHi/L1eOxm3r+Hj3fI9GP/RpoX2My7gjXUxj6ZGjcU51wLrbjx4YJr2BD0OhYbvaYrfd
dntJ+76Id5JtDAM1Ks+/ebEy8Xem38ChM2w1NvxjJnKL4+LhHT7tFFIJ2S9KJqRIqZiLTHO2YhoR
tpaKOqL/d8fH2/OOqPKpL5tkfOxA2ROZ9gnAILxxeuO8UirjFBaK58li03spl/zclF/J1fdTLdWW
5yZDfcl7NB80vR++o8z/4qh3eD+9an5hSVQpUWeo9ykfb9Souee5XvHxZv7dWZDkUf2ge8FI3hL1
tlQR3f838+UuuchJpYpEFe2kz/gbuFbAAseb9QWUXFwQH2/9fCkwNSLK5B2JCFeNTIEolWtP9QLF
Zedk3JUkoHQM4QZDqfWcKPnclqHdkH7NCqltc/HbAZ+a/xpcbInG57mxdJ/jkyt8vFc/p/cXSPvo
pCtSiq+jpfNBxzuyjszHSLSzVGU1TlKJOb/zJxfWlvlxo1gv9FJyPZXzuPBA8t0q4IyF8+14d39m
O3hvK8+tVDT/JLT7yv6/KvK9Ee/ddtttt90+rSlf7kN9nyDcHln+u7O3//3nQJiVbwvTGyp9O/h0
nAUf76Bofud68/OEM57x+5eIN2YRztzHu1/6Od/3no93z/IoT3AcySI8TZJtRPIXjA08OfLqSt7N
8d5tt91e1b4d4o1kdM4VYpPtxJ5VEZ1aCqBx4JZ/tPTxjhl9SFfyyDTmwuaOs3lbsGUIeUAsjJyL
r0DAKR/vcMeVLTWZmG/rTuZ/2uKNs6es1LSp2q1JXhRGX9NyIQkLTF8wr+boy8z0AOTapY16e9RX
fmVW6BHkj6GjhdBDD66i3q/y8c4Ciis+3gr1DovKcE2EVzu8D8wFglWQrHD/pntWoJ6p8vW0Hz1H
VM9fqVJnPt5UCyA5LyB6bUiv7KBsbhTVV1zpabyhntlinsnuM2o+/ujdnPK3rfYWD/tU2hgJ2p26
QqRot5Ex0HjCRM73TTV+3+fY/fC6J+R+elXzDnLK3vf4cDBzwBs+3n43IFVmXNem1iLhh4cu5nPr
D1iuG/HQZiAmELaw3pTVEStJLFDv8dNZCJQT8PK+OXqNxZMy4cG9HBmoCbzh330lRvm8RM0h/j6P
emP64jPQ7n+OnA30+njZM/31gfduu+22224/q3UCM5tTTyTgVItbn1iETEaUZejWtU2DKxUna8tC
0Kxzr1YQ5mpJDBKshQRrZUWUBGO8pL0n5KaCWi9IuT2Xf9zAIIQYX/+c8YUCqt122+3ntC8feEtL
mWEiVpSSTEWRUycJlxvgCCFTyhx+RXLi81+BjpJwNVMlY5uQ0yl5rNKpEyKrphnzQCv3aY23Ti6Q
+QKK82f5odwCCyqbTdR1wTPqwccbDR9vv3imHN64GFQ8ykl5ddxfQ9EdqUp7vF+mrJvlUli9YQL1
pp8h7T/Exjsy6NIqU8Q1P1BGGkoDQAYuqY83eSUWUPMxqAHxPD/j4x3unfIXBunsyMbJSnFeIY25
wnmpbE3eq64HMhLl7hQ5Rs/HG/KdFYH9aR/v9eB79DZvocsN8WGakGCIvfUQdhnkh/v8GIsl33ua
45LKD9Ne3fE5sT6cK/VX+gcq2ZM910rMrqVXEL6/7uONYewBGj7e0sEj78cm1n2PsT+vjkvie32e
QDqWe1eV7BhOh3HhuYGZ5hjTKNIuPcW6zNb8uGvnv4bn/FIuo+GwfTk3UiD0Z0TYPyt2cv+tUO9K
0fyz0G7923Nn9hXa5njvtttuu+32KS0LLo7jmFdt4u8xwPEK5EFR3AlvhuTD0T9ftlg+gkr5vKCY
fnfCx/uvVRhuyutv//vvf/I61+/9cBMQb0oqPiX+lvu33qI7KymfV5Sw9k53+z7jg0/ofBDvGr3I
crfddtttuX2jUvNe+Rn7pc/qUr4a211DpXplUoDzjkwVwufVBpmMyAkrH+9pS62OPn6MhATj70Xl
+T0ugld9vCnqK/hx44FhCJUArDJBzaeUN24Z35vdj2iDEo/BbxkcKh8AcQiEGw7TAvMQxXQfe8yu
GvVO+d/SiztRamfP1G2aIg2NlHqGso3f93y89f5LH+9kwXnFx7sKSAKyZxlqCI6GU7Q3Rxw7SLXi
K89jD3vkdQlzhvCCeF4zH2+G1Gdl79d9vNn9egvn9Cipf4toN0VZ8/lR+dtHJxGxna9W6pSwB1SO
oHRJBQtT7Z6U5JVjA51Duec367eVon8+F4DqOwTXD8TRMc7JFspwln28LVZInPHxnq8NVIF+Uusn
8z4QXU2mucySJZOompHig1B9uFiLGuQ8mKYWFmgmdHbEcxIRDXc/a16UmWVq8ij9uVMP7uVLxYlv
rv74a7UK9a4UzT8b7T4Wb/9Xx8A34r3bbrvtttvnpFMDSnw0fLy5CvrNN3tawP6bscdtPeLNfLzD
guvgwVgIIA93bU/08b4F0rdvHvfrgXyfEjJyC/7bud3u1GHHlP7KgoS87N3k8fT2Q5B0zAmigyDx
V3zFd/uK44MFmsKHHPeWDLAepWO33Xbbrdu+lbhaVdTm8vbFYKm9l6Xa85h1FSsKyqeE2jfuhuBM
jZouIYSP9/ghhPpnvE44717O0XvcbVAkzj+N2sd7PpmOj3eoEJi4sF4BNlvxcR79rBTPtk18vOk9
Vh7iTM00IvPG7nDqXV++MJZ5b7qVDt08zX4nJO9yP1NafCZ5M9QbqYe50fs7bcvQSMsR0HDsgBCj
fE6pj7fjFYf34IKP96xNoMuFuV+5V+/m1SBA7WOb8TfV56UAGUVnhXbBAkcawesYMuiGI24y1Pu6
jzd/awPy7c/fvKhcxCkZB7scNQqP9elVBlLLZSi+tn+WKM4hOWeqswHQtcAMqEL25bp6I0nSWC3o
V4GNmW5BNTg+3cfbRvs6BK2Ox7jRpSPEtZcfxxEqypKeiwSbR9Y38vc3XWdR729ogRWzVBOnm35Q
8zRI9US2UOuj3SvK5bAVAfKlNU3HxvsH5066mt991Bu9HSd7eTa32yfFj+N7PquNeO+222677fbx
iVSg9OIeF67STmRcIU6/+VfWesyobVxgH/OqjZ3Tu6F3CIZ98Flez8DHbvt4/93mbRJ7GzniI/K9
skqSglGc4t0S+0p1ieAR9Ih4h6TR8QhIxsoETNUN73+ZSBDYsd+1bz1ONJJ0rxqfNsq92267Pbt9
ezuxjnf2uODiSEeSVk/26anWDFfli5Wl/KAFjqtX2GYTFJEjVyrmHmlA8MFUXrmYdW3kvRtVtT17
LJ1lw++AIisMwW1O0QGG1oucduXjbdEPNT9G5fnN+inP9GO4xZCIbLdva9RbqZdStB6xHxeHNLGL
U5cAVQYhNQhyhDm+h/weS92qjo+3/+4ZPt7FgpLus+LGgaH9a0rmZk30VJ234B4j1YHIZ5HZ0ULz
mBXHe0SXVCXQko93FjQLfvioiD6OoysK4tV9h+XvVEDdx+sybkHm+/FclVXUuBX6AOa1Cmj/zO9D
/E2eZGgrljsf71lHxt8zwln2LizOjWXZx/uGYAd7OFBthXLn8s1TPt5w1+BcT0iFzTjmqHWXH7sg
qp8Cv9zY8cArEMF1BeZzqPtbWLuFr16VFMgR7K7yeTbUov9Fc82/9Ov8HNZ2862aQr0rRfO1vf/7
69lod3Hs2/GObwCDb8R7t9122223D2+ZKvj4fYWAK372XVHcQBTQH0HKYQloPm5zmAi0x9XZQa/z
zl2/8c2dErm8F4PA2rTY/re/d+Tc7pzvM4vgeJ3HHJweRxpEpgElSe6MSH5YyB886XOvOiCq5jO9
oq9EPVDsd/s248RHRkBo2/bttttuu3Xb90O8GeI5KLmm3t5k+KZ83pSGo7nhamJQXM0Ju7wjnsIL
uvTbXvfxpr7EYb823ZOAHDHkJfMrDqdyzcd7ukyioOuvDSOnfETohFI89/EG9fFWiCeI92ummF8h
K7wvdKx+Ej1WrHHLUs/O8K6I36UoteCJw7QHLOOKGqOPJ6h243var6f/apQifS/8sxh53HiSjzc5
ThjrknFhCjrDRny/8fMaUZXHLHnFghed8MWZ5kDF342fedQ/KqOz5+v54Bnnm76bqjLFZrQbXksD
+v53n0/1LEYnCc7fRqorEvebuRqQQdJbkcnxlKCRJdqtlevTMXkleGtygNNxvfrsWT7eGCbuiUY8
q5egUqeWY4YSNEzeTyouzisFs/e8VWnTSUp1kgtozKe9vNI0HlVDSbdbgjiYVNVWej3RX0HzdeES
Zn3+yx8mfd719V5TNH+9b7e6u+pYXxn53oj3brvttttun9JYwKc9rHuq53HfjzK0KfnAVM0PoiZu
3s5Jlzdn5z4i3o9rSRbvzLbs3xfvauk2q5tfuPfT3wLxZoj+koq4SGYjC1DdoccExaSGrwIxi0mG
x2IPW/b8ewwSC4Hmk8cmnEya7LbbbruJ9t+vudKElwVpi13xszz3adhH6qmNXJ3cbRuToODc1RwM
NzNQbhGlV2V8V7cZRxkjh3z28bbSx/txAX412nncc2kmFGMV6lzttI/37IfKsr/DORVopAUOd4Qn
g483nI932LfvF0l/p6h3ktFWKDk6yPkayRv6qWZrt/k5gj/X6nu2D0s51os+3qxChqmeixcl9ycG
8ehOrhOJf7F5xLxSMkfJ6e6KGkkUVnhKK8gzBqA2+ycXiYJwjxC53mwfavvO9SM4OXBOu0e7FQKf
I7XcF92QD8sYldTRe64Z/xwXVc2Zj3c5/5hJ3YL5Pff3s9dv5gSB8PG2RO8g7BfSxzuHNn3Vxvwp
9fHu9NUuFO+q9kL38hoBYBo1fDyVzzoZP+S8BnLspYC8KVyikPpmRWc3sSA1iND/LTvxTAcm635X
uN/dzpd6hHf7b3Ps+Kh2nPy9RppXFM0/Bu322z3hkX1a24j3brvttttuH58LLTjdDMlm23gfcP+d
/w3PkWkf79EPPCRm3CJUC69jQGaPgN7L4PkewM8lsccx88DtWEfkFFp9uGXkkSbmmsFMskCdvj/y
vnJd1dyRw52/+m5fcJxYTMg9c3z6DA/x3Xbb7We374t4C2XvcRXU4chxri3j7M3IofTx5h/zlUpQ
+XYZZbIdR18dkkx/SyYyT4JKOWUF0ku2mfmg0Asv6/l4G/HxDln/oMjOH8qMNptDx9lDRFRMd89J
orcN73V+P3OSd6muK1GFNc/ebOf8q4Qbzv6FyB2LiK07Q8WXbnDAFeIcdQby731fv/9XcKxTH2/C
TweQvWTxnGzRx5shYuo6s4AKcFU+WsE8vEsk+FpFxClHGXmgmapXD4rOqV3WhIKNfXlUFFdq3AIF
nTy3O9OX0iPA4GlecM4tt2xSHt11lUKljqy9v9lvnqVq7sutGMoePbjZs0OrX0GhpWd8vFVnwIob
y6Aib2f4vXxdA8ftdpmqAJ/DGmsD5SYD5uOd9o4h2UbqITINCOsjpBDrBT9epIke5BohmZJ+fNfV
GrSvCtBPdqj5KuN24+RumU5Rst8zwPk3QbvPtucomn8w2v1DAu+NeO+222677fYx+dIBZFQBh0K8
MzXz29+HRzDBEOwxwI5WjaGUDTPCqoOunOM9/+Ydea2Fom4BxjGh39M+bueGtdUixDne0HRK8S4X
5SJxdKwt5FkyRKuaF8rT4hmteJDv9pljxmwD91GoN4xbhu622267XWk/kuONbAEDxlGtfbyrjKpj
3Lmx2iNB/jgafR/RX8jtycoBwvObqRA3EGjO53U3Qk2Ks6HocEwUl0BUqlGI8ajfQT1DsfhiPt4e
jaE+3mC3ngvEiGcz9VHPA6dJdOfjbVbziFGgUcni1f+qjXonSLg8RfJ2sRcwRbiTC0z9q1F/P7+X
lPQf3oGWjzdbdLKAZnwduz7eRvQkkFfUqLERcvvMt1sEaON7f5ID3lUjr1C2x3uXI/OP93KuitJo
twmVboeKNrjQ87vBro8gYMYRbmRaIQxAgj5e14ddaYPo36yp4avfdhH/2JeQBvozOn7CxxsWtFtm
xw5yfP8USMXc+DeShFgsgAHx8bbcx/v+ez1Xx7WM12AZuOothX+25EnG4iJRlI03tJ/4OY9Wp+kq
BkD4sy+sd5+TjMCFz4qv0Ely6E9w4szPX/PqHj43IXO8ZNuOovnHoN0hcU4+G//8Dqj4Rrx32223
3Xb7kDajwlyEKkO4Oz7fLPk27gMuwK5WAXflc2NlpFi69pul+Ix8i2BssAKaA+n3E/Uc9tUFoEar
PXquKwzsSIKxhKaQn0emav4I2I5j+qq1b/9bFOex21caPD44yCGWdLvttttuV9v3RrybyotLA61Z
Lqgh/Lj56MxVgE1ws++fTHw/v1uXeYZFfmWVpKTAuFb9NpvPrePFmXMj42K04+MN5eM9Pf+IRMMI
YuF8uVVJGRePd8de8fFG7eMd/xnxAPkgLR5X/U23o8rk2cJjBfW2HPUOHEGOekO+gBPJO1638OmG
Jb72qL+Xi33JUxeK6AIlYkgM8AQfb98/iS8re3cUenwfHwrUu6P6vKJy7rnLAFPhTkqSxTxCK30s
HxvG4H5Em+kUolTiW0FgzqOP3O7BU5uqmK/5eE/uCkyFXDhWIC/ToBVOKoDPuOlpVUQRYLH720W7
e/zftfVIZ42yCjTCCDJ8IbgEqoCZrHqQrw2yA8jEl+/XEHo3ZQU5ZEcDcopFOv8bPb0wdrjMVXJr
Vr29sSAJkFe5oTIhcWvY7jq+zahH88MX8rt/giDjOUXzz0G77cm//6y2Ee/ddtttt90+vL2jlg8M
8hkq52Fx/g9Ufuwjij8ejmsd94fATVf2k+m54MHPxoB8q7XeJI428a4R9vFQ/F5ZeAiO/b/7ca8W
mBTDvc3h47tDJFNCqg1nVc192uMICSVWwj2tCoe/J//v3b7e+GBRjGwMel9doeATkFv1frfddntG
+/4cb/Q52WrhoRKuZVbQGYBPIBQiqi2P07oucB9vscnksU3AcGSTHfXxZmiDU2SWCy+hlkw4rvTf
Am0uJ23FxxZrz5FTzny8KRrj7zfmxSksQ30zH2/j1575eE8+rjUvPn1XEtQ78+hEZz+JN7gjIMf3
jfRC2aETDjispxjOb0K9D496E1p/vn2jWiFHLf3JCh/vcD/iPgAeZMrzE6h3jRbq/tlBxBVHcwxi
u8evFKdjQDDrM8xcb1XBQPqUU2uu3llAB7PAcN1TJUBEuNMEgDmf6YIfLdFmFGriZIeKX0sHI6B3
rNB/Yt/uPnv+PBuq/XSY1PozEIkZY9oJxMc7GaGT/gXi4z3sy/cHxs0voXjiqsJU5ZtK4uM5ClHx
kFAL70021iUIe4sNnSQNuGq3Ujh/vsgc0PusT/HO7k2zRqOLap9M7VzZ7fdIxBwv+P3Ho93TvxuP
6qsj3xvx3m233Xbb7UNa5HAf8nu1wJ742omv9y3TiMMmRfJIK/Fq2VEF/Q8yPzOAiBVScu6PhbJD
97NkBkh5/r/A4J0e7nyoL/p4T4nd6dyEsGimEG5EwG/hPHkp7zEFIsdoxy28lkCSSR4AP3v/dnv1
WBGTdyYcBV41Vnmbv9122223q+17It6IyryVimrMcCfcVDOuJN3waRa/mBdgdFGgFi4Zd+i29JtL
H2t/zIjIqpvhF5aPxHydiX6s0ObrgOLhPMHHm/J7QZ6799lUqCOHnpd8vGeuNrkH0gd+9B4dforc
p11+CE25yqoukF4T+U6voJJ9yyOF91F63FuTA95AnDOv2ez7zIcbxfbSZ3zoM7CGh/Wyj7e7T4jc
SCC+qCC/md4L9IOqM5zu7Pcxpsz2WXPq867M0Hbvo53fBywGp7oKZH7ZH8F6ojo+aAvAzQ1+DqkU
0ROKd6kmzu85q/LhsZCu/6iGI1YB0HtWWeVB6khgDR9vxIul/P9FH28Ykv09kaPu1zng4xsKZBSC
Cz0PWVBTHrkfiI4jULowbL2CMknQSSrBekmEDC3uP5J1X+8+2g06WyV7ZtPxSm8SRjNI1pQLu+9W
VH6B1lMdP4d6H40jfia3m/3mu6TGNuK922677bbb89vgP50pk4/c6RXVcoV43xYE7/981yO/83pJ
IvFwC9/o+z0j86OadwxaknP9d0+OJkrtLcqmBPMRVc2Xlx5JguewI11kAjPv/VZZQJNrJ1TNzama
P/js8V7Dcc3Dfo6DPqOx76nz2Cj4pw4g9B36uEfSTwbutttuu3Xbt+d4t5jc1YAJCOBZZRaR/n9A
MCF4ln4BlCBEY/5Q/i5Ib1pQ7+6dS8PHe7w9Qn27WuxBmQwP/2bozFkfb3YuklPO/MYbPt42+XiD
+3hPH9U+3uwa5mQ9ptOeaeaeazd7fpfvUsLHRkIAR/aOkX1HD1eCepPzrRl2PZ42UjVUpX5f7wOE
KI0i8KHfVT7Kd//n+JzZKxZ9vPM+rV5UpP7fc5/WPrn5SL6iap4nAZoc84lX3OB4w2k2e9S7MR9h
dYGPStX8UQ00iVI59XypIB7KyixFy8MYIK4RYryQb7WYsvj72OR4tyosPCfePXPj3O6ybxlXE89G
Y+2akvt4G/sO9Tt2v26o54lpiTD7hIP2HblWEbo0U8UNhbx1rib2o57u+3T9sloSJBHAx0kI73U9
R7DxXyTjXpww5RWHi8de5mezKpxnRgLW6A8/pflE7LF2H49sn//++mC02/O8v3PbiPduu32rTNPO
uu/2DbqpU8RWC87DoZGAtTne+UJ2NoA+mN/0XdV8eK2OOKlnSKhfiMtzt6ga/u69XR/nLng5JGD/
4fh3tP6hat57LtmaFR1UHjMy/vffqbjmkSY6/IoKYZX2vo9JBHA08pbl6JDXVgVDFRq+28dPdYo+
+Lpz+IBAd7fddvtV7Vsg3gebYAusq1p+PAZ2peIrHG/b3FTjCsHT7giiDpSW3Ob5xYl6MzsxdS4t
Tg8cri18h5niORIOeVRRJ8cF9/GeJkmCdCP4YUYeuFpcsoUX8/GGVJV2/Q2Jp7NebSz4eGeq8EiP
sRbsL/p3q10hl0hlqHcQTJqiG/beCA/wCtHmww2vQkD+Plh2zuHcc59x6gtL/Y5P+ng7rq/fl1RM
BwjvFmUJL1McX+F46+DIIVWiymdSlB6R4sKDVqLfyAMEJMhoC72fnpUKMB/MS0ylMEb7DAtqJLrt
n6/VvGXSeQR3VyOXsFoBXfWRquJCK94nKPHiKmQ9UJzneelZvroGgh9H1znDSuVezR9j8R0s0ZLx
VTtEayD8PpvbqNje3Ju41kCyjwalnimho1pb+d+j+Xk1UiR6G3JMt3IZaWkHRK8PneJ3L677z221
mLz8WhGTWJwcze3q776CbzfI778LEr4R7912+0ZtZ953+5odk4uQHYQLrRBH5uvtJ2OPWCpV88fn
M4oegt0uSuz2ASFGdBxF0PXvRze0uBJYwyCSNqHmh1NGP6z08Vb2TlTd/MgHnXs5cKpqLuwoM8G6
QyyX7yTveIrpPSRJllrVHIZqsPUE990+YIjBh9zvLCG222677Xa1/fcTLypTkdX8utrhmk2+M/JL
JnSkmPWsBGywzLJ4sg23CqG9/Yqfo6ZVz7hXdkuod2/hnc0QsNLH2z/bJ/l4gymlex/dwcEknLsE
jgc/0sTH24KPN/u8UMympQxoKLGiRAfq71ZR75obHs4XiasRct5Zjkhpk9cU9aZcaPIoBEIDxpXL
fLwLH/KoFA1XHbLq482OwXdBT155C6eBHgpPcrTRzKxbcQ/u+bd3UNfxPOm5e6svs4lLnSK/pp0m
Oh69AVkTPr+zqnk+H/qqI65qzhHu7DfZ80DJ8Ya8xsx//JyquYLutB+4Nb3pUy/o5H4g4XRXPt7T
TI7yrpgr2xF3RSRvxu+KeVeuIbzSh6OZ+wRWrCgRlTcwU6psSOgRrTLzYp9YTh6oYz4/6OcOAqKX
IHfpQesasstB8taFiSl/038Dhdt6quPrXO+j/NVXQLtfsf1Hto1477bb98oq7Xuw29fqkmza95C3
mH5vHOvHonTgY7ttHrzbBGUdEGHP886C7vCOTbDoH8VuUDG1Lsd7DJSOmzz3cViKBA+l77ij4xi8
xh+8579c6wSmvQmxo7CMvP29rmoOomo+XsdRBL4iQDnmJzgHLs9RNZ/7zEAJOvIA+FCJpN1eOtC8
+naDvBsb8d5tt92e1b434u29F1NPTMYpVKigyGKP/0VPdZTPGjlPnKqQq2VKJHmXftzMx7vlIjmp
nY6LqmQzz1v191WgiZ4Xy1BI6muc+Hg/Fr8NxfNsQQWXeb9/pu6bzb81j17P50vRLFJOAaFyrh4G
GtdYcRrlvq+i3tmCHNKK223ISd56W4J6KzXrRM18WqAVPtzq3qQ+3ph/o44ftA08P5uMmXTYI8g+
9/HmYsP+2TDUOb6zGa8W1sEz+djP9SXgn7ebP2BerdzoPDLOPff9WI56U86/L2lt8GbDXBH6GiTa
zfrMhCwSFWzO+yZm2gJxanHXE69vVim1qmp+rwSj64IO5zzvhc/08Z6Ok4mKJScJS1TVgUVkVYwj
rUD2oXmeLIlmR+iRQ87GMjLv1Zz70ZIsYXlD8LXl7xsrKDZvo6FZ1BR4C24Ki77dySJB7qOqH1Xz
eXrQhvA5mv2uze9uaxt8p+SL9/GusGfkX9vno91e2Rz2vZDuW9uI9267fYUcUvNHOPa92u2r9Ngj
LszEhDsC4Nx/+pGYenCp/WT/ZyF5uL+V7zafkEdP7CzQORx/+RgVtUlisLxbg1ibvwcy0Tcsnu/F
w0wZ/a6QjiRGh6M16XV1T9Ucjm9P1OANSeAiAs0jCz7OqJqz4/GzmTD+OsqRq9CNjL5qxMHHBD/I
9Aj2s91tt92utR/A8cb1yU9mpBtHnsi4zNdVeK0S6fJJ9dp/7uG6kVeoFjTDZvcJC8XkAiN8bTQ5
5Yk/N90GlGetJlz1N/1s0cc7CCa5ZylV1NmZEP9zFD7e82OYfYSRMKi8j3c8CP0zWwZb7hVvaZa7
l63PUW9aOQB1psnLWtt4myUcfNbvMu51iooz1fMMyWi6FFCke6wUIMfo+3gn45d64h5pDM9GcH9T
TncKZiZK2hEB8vcdvlrCo62o/ZVBPLVHxBxCTExxzmGV1VUMannlCtIgJdozuzmMotl5dULG8ZbJ
iKxHJVUoUdWcbZboiQAls/Qsx7tah6Bhe9baLk5oM996GDubWoKPTRFZ3np7zFVoYs6s5uM5CRd7
Exc7AxvKzK9eOohxWJtk5+o807FAaoZygzmhZP60MviFajnel1Dup0SscfGE8azrrtehn9mO5R9U
qHetaP5Vud3+MX4HBHwj3rvt9iXyRz0RmmNn3Hf7It30IOik5HhPfz+Qal9yfsCCUvaMgCP87fnb
7/vlC4XKDvL+m/9tfAw87AlgBdKgWyHthvG6beIX88XtUCZ/zMKSd4T5ZkJ+v5cHeV5jmXJj7Omo
mqNelk4J2KMbPPr+Mz8TrWp+e06Hfqb/9gelhHrwhKg6583x/shxhyf0X3HvQQLkvHR7t912263f
vinijSVaiAxlkkwhMo/tphq04oczlA79GIxCm4DlhVgVpTmxyBm3A7j6rVqGYUwNpzwovk/lFx7V
m4fPBAKIxMe7XkaOi+6I6GU+3uO18AVwju4ppJ2heJYoN6+J0yD13M64gWglFfL7QH87vkGC306R
LkHylh7hw4llat+8kqTw4Q77cDx+9BXNM9ozRb1P+XjrBW6b463eC+f/rXZcq5hzYvEUuBoTVZsD
aIYQA9m8ANHz4bjeSnckPozgqkDnlNkzXJxoB+ILav6pqjlQ8u8zH28U6vXls09VzZGej5wHS453
skYwzfFeQb+rfl7tK0Vzoeb6Yb1QC67PCYlh+56ANJdIh1O4CPMOswkvRcHzsXOsnHpcDxvHsrWh
NWY/6E7VoHvEea//gNW+xuSBeod76uSrXGmFdqO59wVi99qJ/bB2TP+R3xeoN9v8yyqZf9MnuxHv
3Xb7Eqmkpn3QgMocm++920clOu0Ii3Tmja0m3nvFhh3Rr9sewO0fyPswhpJr7vFB0NRZ1TznLVNu
9b+dHAPaPVUYS6Epzjt/CLC5a0nQu5A0oAnXf+g8WXF7pFyJYrGS15LjbQie6nq9jDzxyzjeC6rm
7LwVqn6YSevPWTugIW63Od4fPwxZz5rt2nHc2ADk3o+77bbbbgvtW3O88cRxEMhJRyj5yvwrVD7e
5K/H/mZMavwM80xE9vswoEbCk+ULqnlBxlSB44INUZ2YViZE3qBUaoZAClE/vzYvPFwruAI7lI82
Uyh395uiZoxLzdCSjif33Anh3Nhdh4x7kKgOUs9taYcrFteKEzZn4BXHnKHSViTDa5I3W0+lqHei
OE49133/oqrlybuFon9Dvx8R9YZ8hikqL+6wUmQOlT6ynIeLlFG1atRcZS72DO0djbkEe0lMXC3S
3bWpsbMK/iVibDly303ksfvslbOXfbbHrxtqyh2EWamaq2eabc+CNlSewFmfb3C8W4mek4E7IObS
u5DecFWFj/esaG+TW8djsxzpBEG2M0V9Oe7aqJlN1hTmfLbBV1XugtbWgbBUWG2eY8U8TPorqIYP
krlcD0TPTvD0BM1rtBuLxxhfvQ7aLe/MC/nd36aVAubqB7WieXnoD0K72XbHcdh3wqE24r3bbh+W
KULjq3/qvaI8LUN8dtvt+cnNGfF9R6rdJM5Uzb2y+Dgb//OlPhx/9/CfDyi5DcGAR2MP59P8QDAT
1DYR0bxxzREWJsO1HS4AHjneN29td/zb72o0ef5OlxofMxI8+IJjRLjhaDYnAz2ePByqFpAEV0dT
KbpQNc+TrXFZWSdI4q87ZeIl2m+1r/tua/0NRoRsXzLm6WPvaobddtvtavvvR1xFUwG0t5hggt8I
ywmsHpNkUjW9VaMo8fvhrOZaSD2djDwf1H7W8/cQPC0rFrao/c7HLHlIXmv185nDmvO370IpC4rn
ElF0MAEK9WZ1+cqZiPOx5ux5QL/EPbX8V0nfxYJHt3tmrVc1E5fKVkQJh1tWErCKhAJBZwFLhnp3
/iaftXy8AV1poTxyfW3Nio93WdG5yNG1pDrEqJ06vbY+Fxb0t1NlSKJqno3HVC2eCY4BqddzvHbU
aDd0MNhWrrYErRZ/s/sMMjsqVXOlRp/2qEGUbt4GLMRPVdH5WIT8fBJ9gcyDWyLwpKLA/6/az5qP
t/psHCf1vAUyhcAft2H/MlUuKKsNIIzPYdov1l1gUwJZwkRtHDX3Ip+plLI6KUhCS6TQGkrmfeed
sfImW4vU59ZRtl/UfjmRCTmnhn49WfKtFM095LyscL50tPdffZKSef8Mv1bbiPduu70qH2S5eq8K
Drka823Air/bWfjdntlnp6KtSTl8VI1eUzUfEXCvXn77/njA6mwX96TEhDS6F+aGys9+3/E9ycro
71zzf4vymwo5TTJYVBs3G1DvARF+R8Pf79LDe7sIyCHKg+Hum1hwH2PQduRc7PAsikTBdOv/HOPQ
5+j59uWymCY2UJ14VNHn3ZGK/B2Z8vrCGKvu18a+VwaimA2caCH9yPPEoREDxj3H7rbbbk9q3x7x
1tlKnWWOE2/8d4/bMiMmEAM1FWWNVVOOVgThLTwsdNIJa/hz8ofONxnxtx4CPh8HNnNXkeRAZ4RP
pzUrlXJ1bmhl4gliTRXJEyQdqk8Mx5CLRphlqKT0lk0eR8MLfY0j1kW9I2Tc8++eVVWR/XbiguUk
78gdZ1EB68wW3rWpv06/6WkH0GeRVY1UFSX+HRJ9eurDQ+UJksGJo5bPUTUPXr0eASU7LpFSAdlI
j2sDDb402ux6ufe7rt7LMBdBfG4Dwhv9pqMveHe8SMZvUVkAxD5A/ZCp17dJHY6uqjlMWb7Fipap
cgvMi10FxWjwrdcqCIpbfCIAzD+D6tdwFXClj/esAQE+EafjEO1LhY936jNPq3Sy5Igf0jD3gQL2
rfQMugIQLav0ooIIRZ94ThoAlpqUL553qk9erKfR+OTqVbWCB2usnb5kI5SuMdnXRL3PKJp/Btrt
9/Hd0mI/o9R8t92+YlII1UKKlYwh+S0ma7vddnt+pyWBPXwAShakLOBRCYJEqHDalgTgIZATFmKl
D3PmBXwrMxUWZTRYDkKYCFZuKvj2Ym/zNlUwL5ILQ5AelNGTgJstNGnpOEUFeaDtrZlk8F7alcGq
mL+zkM/u65yw6QXFHcuvPV6fG4bYK4YXc7wtEWTc1WW77bbbrwy8GRVNTYBIFCb1fMgnaAg4HAv8
qmlZo3jd1WTEPpW8dILukOUU0PGzxoCaafVPo4tucyI/6s5gmlzVglCi3szHm1nyVD7e0KglJgTU
n0/eJzxnMSwu+M9p0EB9bMODTtRXlxYulZK67pM91NtkVlyh1gEdN44ZaF2tmuTNAfHno95LPt6J
PoI8R2TjCSKa/yyOdxibvOtBphzMA3B2L5nvLlMUn/iOTNVcBFnzvoQae8un2UTwBxqozcfmPGAr
lcjz+2a0+0NwvosES3oOXNUcVnOzPaCNFY63qNyo+Nz8nNc43kaqI7ribVm/6vt4QwaWbN6hlQTM
ycJ5eZczB4b5XCT38JjAkzGret/ULOBWGdO6Bcn7ijQhs6giRM57wSW7xf0edU5wIt/TEYBd2A+s
RLtXCnQuZ5KXt/5GiRbH6Q4U7wr1PgFEfya3+zP2/6sD7912+z4pIj38K443+4EXBuNCdTsbv9u1
ZQm0GpGN5apldbH6kTFP6hxBHAPwDLmmwbUKqgok3aQVGLdunJN7qjTcSHJu/s39kzYqq5QZjaLd
lEagY1OdX7Ea9dVUIgwBcRXoVvSvJGqU54e8v4En3ZdsvvZo/JyRCcTmM3C8n3enQcrsAexnudtu
u/3WwBvJBI2T+/KcMaYcDnkG8/pU+WqzlcOolhzrqbxFDV9c6MXnvABQCqZksT36dzr0uVq2Iyie
Ww6zDv6gQBIOnPXxzspJTfl4C/52w5OULZZ1mSXbRj1vWBltJMfs9JXOAhOaeJYsnHhPSTdDDG+8
z3fL1zNRYo2l/Y1zT3y8TfhRl9+LUy99vJH0xeQcgdp7+ZUcb8lHN+t5gstAKaNnJPzspqp5KA8n
11OWJhONEeZM4BMIDO2WwfdKHCJUy1ng/ugXHOH2wXamap6VmutcAPT7X3K8iyRL1p9XVeIXUqCq
OqBb1kznG+KaAuvRk2e5hwUkdgiGp2QLmn2w+BKu+qN7L1YOtTS1+bGDap1YoJDE3JB2qVk/+zOW
gFa4f1ivN8PKcrrT2uaVNzisp3p+4nZ9hXTLUfwdPj2K7Q5r7bH+7muh3d9NuHIj3rvtdikXhN4A
D6T+WLH8GamtTpA0w6YR7raymhElwR/A8YahLKXtJIMAXapelueisFAilBCVBPVr8IB6y3NkiTEs
IWxSQM6XRPuACHnwbOLZ+aSASrDMMTJc39HPNbeSu8LxbuctrWWbVFRohCTb5gifDLg4EDJViDzx
loKIG87v+n5+u+222w68W5NjPbGiPdGadTNrZOKdPu96YLufAwRAA12EcO7tgGqDqHZLv+xhKiyU
0hk3kKp9u20IRZkrpDLlceXjTf5GI01KfbyFQrLyWo6/Iwv6pDuwhQXoyjMqi8I/K/Ay9Q7n0LL8
gVylZunqBGmmqYXco9zHhBRhD37Q8Vxy/rdYFCaCdxT1Jv3ilI83Fny8k/KCgLy9kOMd3iV0x2zt
+6xVzRUqiPDGVBxvb2dkKYLF3w8uzGZJVQNx5CDuCzjL8c6qW4TuRakoDxOl60j7SIWkm+d4Y4Xj
rZKdjZr6BY43q3pTwlzSqz1JJOngPRHhqz4THHg9FGBYP3R9vJHO2y5LQ10HsjVVSGD7cZwtKmyk
ryTvh+XHU3NJHagXiSwW9Bf7yWkZvf6ynISSN69xJDpXZ+uApdW3PZOC8BmI6rF67IKqXaHeK4rm
XwHt/i587l8XeO+228cmdOKkjWC25Sx7gjgaWcSZE5oKwczOwu+20n91rMQR8OdyvI2JY6HPp5Uc
8Ok7tBOnMY6B5FnTQJuhyylP3MpArFdmbDkVAeP52FQ+Xt3fMkDvqJpbDFzQockowbcvxPFO0fU9
Hl8fo1JxyNrC79qx+wmq3XbbbbffFXg364x6WePmIO79Vt3njHeXHgliEdhaiBEPcfGzFGNnCtuT
dVWdOYfTSJarMSNIXZPC7NFqWOHjXaHajGMK9Hy8CWIl/0uPD36MsS/dT5KjNnPARAoplHH86QRD
zkXj4AeSPqSQZhCwnPt8h5eJeoLr68597Tuq55FPaVRkKv/e5G1bLMOuVNErTqup4Oz5HO+q5Fyr
TKuAqeNo4cZsX+KdIOdzdVFTNMEdt3eNYt5CLNln+zobJDA0N2oB9CgFY1l7lijpnjcgMe3Ity2C
ap38WON4rwTcmRL66jPSiSBzFWygaxY+3qExR6AIVqHdFVRgne6QcLxN6XVU64gK5QXvp1nfMT5H
5XcNrTnIwrpS975n0sxKwVd2/MY1Lqc90Fms6hXVM9XMPxPtHv8+Ghsc3f32yON8H19Myfw7tv+3
b8Fuu/UH4WrRMAXkB+GEZlWCf74+8L/NjiTYPu7ncNge0Ha7sLq6TYhjX/vfZyxw/jN5/unL8xzK
t2eT8u2d+dNn/+4Px7T5cUBO4bd36DGBz3v337//fUznimRVztDw4891H+/v4bxw4GJv47m///n+
rv/dhXtTEfZ5Hy7IteZjUjYOzCW7KEU4w8kk62i46/i7j2Ntvfs47qEX0xkXnyQx5j7QCS6SMLIK
iNj7U5zfbifnXnUv8ZrxMSYz9zPcbbfdntO+HeK94ph9em9CuENxjtbsDblUTI8LVBGDWbZbXC3Y
lyyHS3BteG/oPh5uzFObHGtEeKia9DgpEsQPAlGWPHA24XcUwAl/mCFS7bJLePukhNs81v0qvh2t
Ab2KeucLU6To/sK7m9CsQ9ZCA6MWwfSOJZV/p/SJIbV4c4s36O9f6eMtH1RRboLxHatKfck+UQxh
Wck5fd9W1HZNcGan8Z0YqdP9jAEZ6EI8PXe3oPf2YWp7j3YbqWx5BsId/m6omoNsw/ffR/mr3zB0
nHLhG3P+VY63LYr7ZddyyccbixzcYa5g2p/INCnAq3Y6K63BPC8uYcaxD77ijrt8KLVz0MnDjUeA
lbh7GPMami/JsMvON68qOqUvrxHqif4Sn07/kZ6cz5mI5akV+oUIAGtfHRcjjDPtOPvrY3GLY/0s
tm/3c9pGvHfb7eKorkStjjsiN4AjA/JnbiHC0at3+OkBUp425tjtF/ffDEmdy+oOimCzIM9PwtNE
HFD14QdjZYdFhLpCff2u/cL2gc4fJHg2nUgZ9/O/bf+i3glyzgQzb1UB+Ivo/0O93cnezg0eNXUV
MllFyw1gHitrDneMR+7wPbD7W23QEFL04xBsRXyxUy6uF29r3PO1JMPjMYBy9cdH4as7cClZyX9T
VjVsdDVNuJvZSyyfwMbHrWq+2267Pal9G8R7mqSkGiKbsNYy6ywTWe6NoBjlCoEQqL3PNgrl3ClD
Lfc3fzYpomdXqPyI1XVh2DdAF7t6lkMLYZ5KsoH6wSyg2pD+y/EWylk6Q6IUmMIWEnc0gqNxDNGf
kSgEqJHyKk8ueMcTVjw6inojy+BDrbjmbVLVcsc8SwHxmj7AUG+I86P9RHmpNyKHXFyo7+PdQsuz
++CqOiZ0SqiMr3C8mWuCGpvDuIS6pDhVwCaIHx+qo6o5H+s7qubMe7uyBMOAdhPEz/3+w1TNS0G8
B5LWEcvLxksE67beHP9KjnedGOB2Ym3hvM7xHBoMG1FjmxJDaKxNCmH+Irs3c/zTOb0InOHVx01w
vMPEwPtFV3hdVYCAPU9om7s6nFfXrrzB4yTytBzAEiU642CLOZtdSfMeJQu6Ztpk9VZgAEeescfF
OOfsRjh5nIQb7vOCX53b/Z3Q741477ZbYzBOF0WqxPcYFxACOfw7uR//ULIbJ3SexAPFFM3VxG6/
udMOXY4HBIdHo//NpgEBt74A3HGC430Lzrocbg95z4jlyEU/3HuENIE5vcV43+FhhL+dRiR4vMd4
XE/kiR+CI43hF0e5CL2j5+Zp2bjvcxJmO4HGriXlKgEuUhWRIvv5c1Ln/WqOtzoWlMhqJ+mlqhF+
sTiRshbDK8MhZpm322677fak9u3txOCCmTPWLTqg8hrKa+VG0cq69v3NxnmG1NTbpSTvgcYEIYbt
PTKjN3TOkfV+3PnkyRAxBKV0sQ8I9XGKSubZU4YW+v4y+XwWyvEUfUwWGHCVCwTc8J1L+kS3eVJP
Qb2LhWviSYoig55zxLRPtUnFdNTPCNlrJFBvi6i2R43Z97WPd1L9wp50gWRwRJn0n/H4BnKsgb1J
vY31oBh1D5InDEiFdjnOe/4nHK+74YHtVc3VorxEdY15x2uF9AfSboM1GEe6H3cLp4OSUtU8/JbP
lfN9I+O+QreRvYtz9Y96ph/J8c546N1Au8Px9i8/FmB5NWKWIKf06saEnrd5uvd9gAybw9OGq1ga
x8gw5z6s+8K4Ad2P4AYnNO6dtwvkwTqbuxpCfWHyQCvR1N7vQDvpsaxrHZfqMtbR7vj4nqeUfn4X
Xx7tvrD9sbDhV0a7vyPPeyPeu+3WmbusURbsJp47Vsg43jiG3901jc08x/ZO5owLCWyW925JfzyG
Kos1jnecnYGi34uky9HieM+q5pHD7bnB7vrGV+WI/34snI9WAHY7n/ePT3C8b6j+7VU+vKr5g2ts
k0vBcKWA1UjwMamhzyj1GPDkYpFwyaFD3GdUGVazljVSeM5pgi9TNV9LKjz61Gs43l3E3WxzvPvP
TUiMvfDewNYqFXbbbbfdVtp/P+VCtIfr6j4IgguWjQTlgcOrhTYmltqPGwWaDbk//9ms+IlkT97K
I9evxKD8O/EmS27TkFlnquxkO+at3fMFzQNqjNzxwgM5BtpRFhYMFBT9inlHh2MDFP0dOd5zlUA8
fuDfXl5UPB/1RqokGy+E2XgjPmj93NzJpKi39BdnfdJfL7eoKftVMpCNqtc1aniG4+3GJ1F9oZVy
ozL29O8wziHvC/76SKD24EEjjCUzR3pN1VwG3UuL+VjZgkS9GAMqhzkSoQj5Kif5huDBFv3UEetN
WDnwQxcg4W+zKorgx+0//iiONxqq4mhX1J3ieDfLnkekGO6mhRl3Wqd0NXFySeiQgK4p3skkkOjp
gPU5ss1KrH4fRyHnR6Znkd2Wkm6QSOugmGc1Ru+rjzoO5rZQQcm2KR52Np3XXyyvRZ7RDvsOaPeQ
1D6eedwjvbvbt/t624j3brtZwt0+Ytl3Vjo1BVPHoTnexzi5e453UqprUtNlt93oDPkMjne3zJGh
1WMQ8M5HZhxvrWoe9umvD4MYznGGL8zuz3FHveVCYkK558Dmz/Xd1MEBxfE252U+7PPIGd5T8E3P
DzONgaiaK6XokRc+rbTIYFSJFtK+kS7lsBBzvpLjjURob/Zct+SYS8ipeqH+cfjPL9C/92AGQmd7
5dSHSix3t9122+1C+xGId0/dfP37zmyNrgzoNJhXi1iVISUlzcV2jg0Y120eqUW2MkFYgKHzVEZu
F+Ew63tWexrfr9V5gHIfc/I7K1D5xKsa7vz0Xa453pZwvB9LQSE2M3l6hwf8ASuHvL/0Ue9iP3x9
LLeERUd65vEKotSaqp4XqDf9DXif5mi9Oj9oTQWKGtpTON5OHtmI5DRFKCnImXK84xiGTMUcKlDD
hP6qCoa+qjkZWxfFsyL6hXifkFzLLUlHqCYoS2sW+0mD+hzVpbXGAIKVm0C3geSQH8vxrpBvVO+R
rXG8LVPvp/mWdlnDEMBmSQdMiwqvhM4MUtKXxnzfMMfhJoOk0yqgFR1GKvEQ3RGg1Lfh5gJa4AMy
h4n+FdZZiY5LWRUhU5zJmu/CJL+sZn4Glc7mzWemUXDp689Kah2fvoOeovlGu3fgvdtuLw4jEQKy
TMSLlaNPAR9bdGaJEZAInwai2Dzv39pHkYkwgooOSuseH4yhEYzJiAxUkNFvlgXcmaAVD3SGsvJm
kBXR79GOaETZku2mEnzwJGAakPEFPhrBTB5821RCfj8n5OJ7vg9UtoVphqXRX7iomCUCe2i/A3mS
Ce1S8dxy7blJf27FFy/2t3COZ2dS0GD+deMrWraPu+22226/LPBO4Ei50HHIIvISNpXlDvtXGesy
Gx7Mn2nAxjLx/N/ILa3VhMXQFtDLSW6/UzyXqHY8jp9k0xLGAdXO1MnlYluhwayiwFRpJlHI/gCO
NwTHWy2e4/mu+dr3F5PXUG+PdUnOuBH8uyB5gz6DRDG9o3reRLT1IVC8IhmUT5DlLsfb1jneysc7
jbUEETwErqmWRa5qzkpQjahteyGtqGreeSfmMRtUX6O3Hz0ncb76w00CQVV7Hm9Qe3M3+klH7T/6
sxtXxGf3WKDbqBI49mqOtw7Oq/myDKorJfQsqEwm/cDeBkNtjbzTQ/q265yFxriBuP+VnSHjC98r
u1w3sZjQJmma3koS4Ch1lpyR2YJe/wDQqxwsvgfEOm0tZXRqyS3OqL85qi9PbHaiHfbN+N3//vlK
nPmGYn9ltPu7Ie2b473bj25ozhQhSB5FxqeVnyh5JhP40zjeas23E/G/uGMHMnXshAcJcA+uej5O
4qs+3smh78HBYz/vnx2Wcbz9peWq5hN3mCiZH6bVskdV8PtOcEhVc5qggQUq0V8+9YG7NbdXHD+O
2aP5xhMfx4+jwVe/85qDjPucaKxFLucbPwUxkxbAFY43V6PPOtXHcLyx8NoNegLP5ngnZ/UrOd5j
9QmrDXs1Ev3LKgx22223j2k/oNRcZ9LVBEjDM6xuky+GsgkfJ/c/76cZfaHw50a+XybCCeeFiVLx
fPBzLReDZBHXsXJRPsnGy0NLn25a9u0v6uM53ibOy3tAi6dF+90KP7H29kW5mC5WwfqrCyRv/hiF
Yvr0VaJ63kS0/fPg+45oPTqcOmDi/pYc7wIRrzjeqQJ1wtGl6t0lx3td1fxeCZN6bfuqJ5Qdb94O
tjA0DdvwYysRp7HcfSwZj8wUr9Gh34WOgniX420lx/tRpYOMv/2pHG+Q4gx8CMdbcaRbhmqo55Zp
roMaEGIXGPnd/hw9Mtz28Z7oIGoOm7uxnOPrUrJkHJrH24nwBYSAPtQlhoqDOmGUDeKtviq9sVEt
e8vnotYg2TRer2L49TbIFb21w0lv8e4a8iPa05B1dI60embre9nc7rW2Ee/dfnYrK6duvtrzQubu
xfvPV3uc9KPwBOd4H+OK4tDH4xB7FphuH+9f142HflD6/zIf7/GzRPVc+Xhb18fbo4DzyzBUgmBS
LR99vAPC3VQ1B7k3N1TZo9738m2nSH5/BQcv7xmtBk20HiRZ8RhDzFUmRFXzAXp//7Otam73KoVj
0gtvWC2KgO9wgdAxPe8jT1LgDHp8NAOW6z7eNvqr/6u0yBOQ56aWMz7eMnh/nPD0758++k8JA8/1
/giOd5IQ22233XY727434g39MROwmTN56vtErRYrrKHeAA3LPMfZ50pxUymokxVEE63BhC4wPJdk
f5VnuFywC24WRV2ID7JSLWfPwnPPGaqRqKiDCQN9Eseb+iFDL7hbWlkL/Xf9OyRi+YmAnEDfaKbe
mhxwIpaXeYenqufub6SIteaRB/CL+Dyn9/cMx5simesc79YznZBaPMa9JY43U44X51sVWaCodiHi
ayrobo33d4426WOlqvks2haF1ebnTZ8hMPk3V9UJIQBiHG9wpffYBQoBPvsIjjf0fGkdp5H+lyn6
7QI6hPWKVs6e5hZy8x5BKtLkGMJA2gskW6883b/eGVQpRVeUMBVWL8YKkcnilYHor+eShZYeL6Bu
ULEG7HZNV1G1RPGG+Bes86ite+s7Vwc16z8nmPgIE5jjyTs6yiOtHVEpmuvfb7T7dwXeu+3WXzfo
+Q1yDg7BVDZHzgsiFUCbK0uHLh1Vl/ehVmG7fcV+nZbUsgB8WvDXNItKcKvd/6D3I5X/U2Vrdv5c
1RyTQjkLXuZgjAtxNegoXjnc0WogkVpGgemKY/Hg2ycRsSBMFPpIuG8sIGaWbyapW3XwQpItTRul
TChNByir54M5EY9KpR0ycb+S+OTa7vgF4x+mhNuHcrxbGZjddtttt98WeI/CNnYFyesrUa5MpDSr
iX7G0nPP2EKVnyML1ECo3QiK6FFlEyGYnBav2h1nmkDDorXh481VijXvB6Y55Cg41/S3zVJ17gve
Px5FWVH/LuyfCLfrSlOcfFfOf7cmFAwZqLBwLF00gwUv8Tg4o3rOEiYE0Q6LfVkKvO7F3eV4Ayt9
ssfxrtS0/bEmOz1c5XiT64Mur2bBMJTd2cQbjkEt7SfI76OvbqLcdzc2zxZlcQweK2xYsAxXnpsF
3N3kpEeNkQTGr+d4o1YNR+4IwjnjSYKhkVCo1Ms7742eVnWVD/y7sppgcLow4UkD9OhFpkgmtEG4
36mAadDScI4fyKsEZ7o7ZjcV15Hv3uHI+4Cc24pqRpN9XP0S4hpflegdxpbF5PDSopz2b1y/Hpy+
8Je0w53aMxTN+U8Ovc3KkQo0e6PdvzXw3u3Xt0pJt2OVw0TfVFBNBZ38REtKj+cAGeH8qJAXFd/Z
Pt4/uj+DpRXQft40AE8ChVYAgMZxVBAiE3JDyXnq453TNqBcwVxZcgi6fWk/6mQQEsoOLHp5g6xY
oagoUyIlWUuO5cYqCdy0NUqrJlSSpwyQeyve6z7eWLJwi4meqtIiF65a9fFG9b1AzNV9OIEffL95
PSTjPgbvz0VE9xy12267/brAGzlKNP4Oa/vNBveZj10oRcvt+GmPCqvxd50Fhp7Q9ewMRS+cFsbz
4pJf5ITgRAHn/Ewwa4wyhVyPbk2TcqP0euJBC3SK+zQLdeSAMBfqmxWftkDsKad8PC2nWsyCnMyb
59nINv8O5cIFAq0A4WmH2zwgZxkH3CoP7RT1rtDqhP+d8Mj1fWChzXmOd3+hzxM93biKlykb7dvj
/b3K8a6C3TIZYB41j+XqQdU8JM/48wnK6L5L0SB0KKNnHHPTquZ+vkIzyZKWS6u4nmQ+XsPxBk1G
8qCa/91VPn8Fx7uFjoffQJ/bWC0AK10KpoEP6jkuZN/omIno+mFWj2FG0G//XoeFx4jGsoofqKGC
3xLazwv3ByBev6242BA3koaCeC8JwLnYUiF99T1o9hMkffDMbr+bvOBNxfxpZ51JoksU+mjstL/V
RrvPt61qvtuPamHiHcTCw4ylBo4gMk72KScEXrYaPJOP5vHW1j+7/bT+DD4TBuVy1j+Ej3fanw6u
oN718aYB+OTj7f49BFSjyvm8fsh9vGdP7iMmDCaPcCYIdkzJko5/9rx9HFKAhzNCXKTcVM2d7/Rh
zhXhoEHAw7/85uFt/zzDjzKwqZ7V7b6Owcbs5Z6rmgO9/XcHN4lW23wfZNKgk/1NO7RX2P9AH2+S
eBgFznVg9nMmCFW4ocT8XjsW/7z7u9tuu318+36I94oCIp2QKmXas4Px2sSKtgRkLj+ZZlFNlXEa
rXOcSxezM1A+3qqkG4M3aH5tRO/czPLnA1J7qsvFvRq4501a2I6eLfPxhihptVqdGk2Ot0KzJwQ+
VCViuS+/7rssi74ktbpi422ZamwH9VY8OxXkZKrnSsE+Q6in78E/X/Xxbo2veBXHOwZHkVe7zvH2
wmbTr5W6cOKCMHlpJ9vnqtuD2Bni+CxdM8YqKBQ+wIh9ZB6PstJZpPdDqZp/KMe7UZoNW9MvkPt5
Ese7URbS8/EuNV/4ocNUkJXNg0z9iiuvDtA4F4nK+4qYpLuhULaO64FkxYBiTeXfTV9Ng9yqD531
n7+uRMkcPemZlbRG3luXid2ZDku+7jutiv7MgOJC6+DMYxrveMUxjxngiTnkirPduzsb7f5tgfdu
v76hGaCl3OkkMKDIAbHzKRfuZmmg5BMX/Dw1pxzYfLOf0Jv7/dslZVAHuT4oY6sc1ic7/nPXOd66
5Px5HG9ilqUSXlhfnCkqikrAsaTOPFZxxWa4ZMC8QE+e20LAOd5oMJ8mpmpuL+Z436kjLlEhaDsd
fYM84Lb8fNrBQiPobt2PIuHCOSY/Y2QUKvp5+HbxeCGZuGeo3XbbbQfeYZjV5Uj5pH9iNC73RdEL
wdeCV9V0V1WXrs37iQs+SyeseYGHvNLujryoHOlEepwXb+k9HY5d8rH8PQVdcHMqrbDdMYKye1/U
LLjpJn+rtWiCtLPjSdTAeAWC6vd40sriuag3GiqwxUKTLdAC/1sr4LMAiXu00peRPkvIyo2IHkn0
GqQ/dANw+zgf7xWO92QXhEJkrcHxpo4Gqby/SJqJSqjwvVMYfyDbIMmSdVXzlM+M5L0G0w5BGnDL
V63SbnsRx3u6P1ig4ITkzMdxvNn2zIJM+XjTawV3AlCnlamOT1IAqFjEzUt241jL8q8BcY5jN9ja
CJi7k6R9JenMScuGPzuEqwA/0yb9Jibe2PPjcw3QfAHuY0CQf+hlkCpXlfBP9Ldvv8hX9rMeS5xt
Fdo93uLj0p4HRPvIL+YGSq+i3vdfCVR7o93X2+Z47/ZNsiz5hH3nwCHjeD8CiAOHdWyb/lIv7zY8
t+2MBic3bqT6ji2U7/s8wymvhLB2+xZd+mDP90iWi+z7sS8cBw1Gb3Nu4Eh7jvfwwWGv5njPvOQ/
f0de9EmO9z/eNOOPj8GpD2b8wuJ9+/cjBrsyt9APvOwbJ/sYr/H9GQFuZTZyvI/x6sakJ/59foTj
YZ0nRR/n4fKwxzS2vJjj7YPLg4+DnuNN+7Kt+j1f43gzcVQAMhl4NN4d0mnL/CPqR/2lx8SHXkEl
oXWKd7hwLshtB3fbbbfdTrQfWWoOcLQDBYrZHb1RZJ6rzyXvujzeMCEsIPyYJpL6iqFm9BBQzl8q
Tt5D+deopzg7GUgOLkPwGuhv6s9jtUo+258qlRWodKaMKq3E1HlmpcLmv1vpbRcXTcgWMaSXLfh6
c+Qc+WpTcO1A/wVdFoxk20YiJD6TiOSeKgdffhCWXi9FwCuOd8PqLON4hwqHe8UJUXducrwZUTaz
leqM8b7EGWSBDsOMek+8VVIJ5Ei4M8KbvSB1gg0jx9oGNfCiXPwzOd4tnYLxvmcOJCsB1iLHW45D
1ZiWndftHoDZUzKqcjaBgjzq0f4C5eAL92zcRLRC8a7neROOKcQ6D1N1HMogHH78gKVBfVifiWqD
bE4LeHjihpOvPE4gxNdn8kbSozgyrhz7o672OdroSq2cfX4O7T74x8nN8cn8GvXuKZpvtHsH3rv9
tISJ9YIwbibHitucp3ZY4OogCYp6qgIN1AsLddElx7tjJyWC/92+cn9HLswmVtzs+8qSqR20u/V5
9nKqvplb7/C+PyYgygC8yfGmImvgImn3NzpLmiKOP0BdOkvpQcQejPHFPdrN7BDPInFVf/kojndX
nC8LgJFdk+mEyxWOdysO7gYMVE0seX3AislRB+Tlp/j0UTGKjSF9l84kW6r39Mvcjt12220H3l85
VAteq42RcxW1PjuQI1Gq7BwzV4EWk1GhuBknd82LxLwSTuO8mZeVPzXGh0yscKvL4IGJ6RLswNVO
FdF7gXPg9oLzwdPzRH6eGf+3RnnrxfCVdwJov7HJ4lZw8gHNGbcmMoS0ViNHvUNuBPE9wEKixDh3
egVB7gVUxis0WorEOcebBU4pcgmW5Kq3pQF4l+NtOoJSbgOZiJfMzsEmBwf2Pk/87vs1ze9Cdr6h
T1rG8Z6D6I5n9odxvFEfa43jrXnrz+Z4VwF7TMogTTqo9xbsIMjQWj0ijgmqhp71YuoAU+VOeEdh
S0nhOJcmfihAUpUnfLGVJzRDtk3r5Vg6k5jcZ+82ZJ7e4kIQKxKqGUL1hasr4G5SB5f22ZkA63YW
x+2i3esq5gyRPqY/laK5AqUr1LtSNN9o9/Pa5njv9oXyKI3FkP3jcaOaqyJ3evzJ0UUxJh7lnYTN
f4cHz9D76voFxe232Xnq1ZUO6LAh76/ft6sJrOJ4s+dN6tmomMu/vvn+efQfVpNrl+N958AO/t9d
jvf74n3w3CZcdAw8aM8BD97Xw8J79iM/poTYMb7TB7+mP9vPlTMoxoosEDsGz+8MwRuszPz1fGOO
95IA1aG/H3n7h0B7X8nxBhFpiMJ7tcAlCyRHjr+J0nP/He7v1crUOgqhvHvef+rckXG8USXPnmvz
AeCSD/tuu+22G2s/oNR8HYWLXqpWKoi3FwpqAl6IMXU5lfDeJt+DcKPmoI2fDCbl50Qd2xC9LVFF
w17BF0mA7dLzyYpiiTst6tNmXiUJcNE7nl/0p4vN0jc84XgnQRGj9AXf7ybHtVqMqAtCsvpb0NGt
8zHdcaBCvQuUmlcnJO9iRV4XFQ2yHBzo171Sd6EoQ1t5y4dbix6fmy5aFzjeEYCNKsIwbV3GOd6m
hZKSqgptBebKnwPH2waeba3qD2e2XJctVxzv0dMbD1DwUzjegwVYg0aDhTldPcaP5Xj3A+ulNUX5
eZYoYNUpMGJcZ/KVlxUSZY1RnrBj86SyRFMq32B8ahOfiTkYgiixpCjP1ieF9ki46Wl2XTwjhnSf
WDpDHBPpYuVE3z2pVP6SXMdMzngW2n2kKbsze15f6lQ5/a7CuU/Ab7R7B967/dDWkryDsq6Y/V6d
klsIbvViMgmEkR+vEjlTAXTkoqNcnLLAHU8sE9vtGR26Z5mULlZhmmORBe7pAiqPTrjllA7tyiBk
geOd+XeD0VzQ8HZ218FKyVOON5ygJfISchncWaRAzbpVShQUc0IhtRLrh5wfyvGu7k3F8UZOS3kd
x/t6wC2DbpFM6z89wfHue0NNgd1CQcIL1wBUvVDkInEiQVs9vzMErd1222233xB4wxqLDSyNm0rJ
lorxLPNgOR/b708rl4vJhXDaK40QSgOv+NhAiz+rBNsZ9xzD/6Xc9wTdQXfyNsV71oEtC+K7x1Of
BeRPbAem4KzQ/66w2+LKZM2bu9FX/bNvBQua6033Hby6kzgXeSljisYpziDq4DrjYMtECkiQhsJj
fgocmbpxErRPAeM6xzuPQPKAm/p3I7mnvnIEgretVM3VdSs4jeUwXPHPhHrLd4cn39ApmS19vB+B
Mh4Zig/meOe8/05QO3Ozi9TBUzneaI9/UvBtdfxU6m7h3nV8xjM8GgQJt4tzBTloMVehgaAyu0Sm
Gi5nDrA3jYzswS1BHe9RFchvl6g6ZM+eL8SsrgkrmNtYsGA7ue7OWOJpslfuY3HJ/+TLW90sQ7sP
eyLaLcTHjwsX0Ua9d3y8A+/dfkFDkVsxouxLJh0/mUZEiSuPsOovhkyz47GgGIuLWbroyOx0pnJS
o2jYbp/XlWlusKNtVpXj0hJ065XyFglLLdiGxkXyIES/Q4WtGAvAXaCV+uxC2PeoyhZU76Eqla+D
FYTgIQZU1GfBHXNMyPqFPYAlFe4UvQYvQa+Cdiq0h2sBdxUUT7Zr7JqWON4riQCums8TIaPNF0Gr
0ai+MuLQoQaW5Sm3aTP2EWMn/HvhA15VNQLDRW1zhIRiL5Gy22677fZ7Au8LabAsS8km3JXP69/U
mVF17kvjv1IubyAqqUxXABp0Np7uRwikPeZWzblWC+IsYpci9y5gheBszQnpFU45WdwjXkNYzIvz
rLmdDKkXuMfpvtvZfhX17lSLdBfn7dyGkiPWz9OshXqn55BysHtwQLo+hmmtALatQNtVX/5ojrdC
6GMCyiTHO7MKM1FN0U0iwClOY0K7k8oERvJOtBxYF5Wo2p1b/bi/z+Z4d1Dflfe1/k3ej5/H8cbT
Ss6n7aprt0zVvDd1d1TN0QC81eAw9yNLHEjQm6OaDwy6rKvuK+itY+R4YILjDdQSNKrv0j+wdnmZ
x/uFNBNW7tXqb7rG32ku6TkJkFlWMG9HcsZOejfdZukowsab7niRg135fO8U0w68d/tJmRFg4bdZ
MFkFpnrvc3a8E7xaebwOeq2QP7/f1DrMr4VAgg+ztkDRbs/u5ypwPg15twLwPse7CN4h+i3slBDW
MznexugXjcW6Kitf4ngbqL1gVbI+B1FIhZuyBMAd7Z4YvdFeakmczK5yvFHaD0IKzLHffSzHu5M8
WPPxRmE71RFMi/vpJGSt4GijEvlCeL060hIvGkZFZsDn2REm42tm3gv+57vttttuvzvwTuyqZcYR
TW/QYiJd3cc8vleM4bNIeaGyPF078omHLLx6iruatw3/u/KRIgnMc+Y3Kr6RP1fmyw3PwdRPTKLy
JJBRx+v2rXqRjadIxXw06o3FBRBK1Fo862a/V2J25CVrBs4xAI9rxj4Pu+wXqHy/haK6SnwxHYdq
wZtwvG2Z4x2HLWAIpMCTVaosPXvvUseEUY/Dq5ojWoB1ArIwPlTvloJBhyD9JRzvMtjuVdN8FMc7
KFM3BOnQRGB5wF5bhlWZMISkykKgONAOoGBz1EnqfjCLErGWlTTWSBh0dUyqtRTIeEP1C4oETbEW
zTRrynmwo2Te/shlZlBUFJ2Guk0r0Je7QWe3jXt5PljI8GIGOGdo9zmldIF2eyPwC4rm3d9vRfMd
eO/23XMiTXFbjl5xkTRAhZTc9gukJhgqSM4mdlY6i/kTHZQtHC8SyIPFkQz2t433pyT+YjUpD6M7
onLVb1K+dcOuqU6a5X026ApUQdrLON7Fsgw177vF8Xa1ubDIs67XhFzcDSERKRIAQNS38OcArEK0
axxvF1QDVgSf3PP8IzneWQSN1kAJmRTKBAnTzxyVgSYCgHR4NySq5ieQW1JHYaeESS4PpaAVJj6p
5V9/XAS8R3rI3AeTBNVuu+222w68x0kuop5VWVznM/W9F125GqWy0rPzyuWjj3ZxHUZUN8GDYrhA
duJ/sYUAWTNDBQuIS3VqotR0roFSxzbCb0QWfNcBtV/QlyXt2fGqdWDSjyAW0OffqfXvuqg31l6P
3jkkqE5rbQ6kSSCmPB731Qm3UVO8C9Xz7AC+bFiWpCuOd5YsY0iW5UkAVgViFzjeY5H1FCgngW+a
XECxH9Y/vKr9HfVGHtCHzmNLHO9K1fwUx1sK0qEMkLPY/9Uc79XwWe2H+Xh3BMwU8t0tOc853jG7
lw1ZyG4C0DybxAbRuHh5mhQmh0RVcSeqwVpTIFZTmvw81G8gkjdZARV3NNDDSbxhL7QMRfqgkrvY
+2vl+T3n3POx4LAanT6S3Su0e60d9UFRbPEHkT55QyXqvWPjHXjv9q3TICQY6kVV1E4t260qIWOW
YkXEjmrCYZzysDDSCsz18VBr3pDAChvy/vBuDcuUdnlwmy/8it/QgLZ5gMIjPhMWMhSBPqzmmScc
b3sGxxtR5+Aqx/suVDYmkSr/dXKPrnC8Z569CEYWVc0Voj1ytx8BKCulrgIODEnZ13K8033LpEIl
6qdzeaNu5Qo9Zj4fTPdIdHNOEyOZDpyoFubpl6RiC68fV2WVDnhiAkFE9DTJWwp+AviQy99tt912
4P3VV7yP+QAVoq0mX/5559/lmcrtMg6yUduZ9Bhq0u3w7JauK7KRm0LBInhAEkHrgHa+vNwXDFJ6
ej4eqnptZOWF7HhCwdkfjyjCW+Ypyri46mzAr3fFl/5MX2+v+pIOlL3HjfivOIbyBjfJ/6bYPIWl
q+BR7Qbps62+rw4Ay/3KK473ajSXBuAk4MZTON5J/3kSxxskWUY53vd3bZHjrfzDxRbIyTvW4XhP
x0xqzas5tngLZcDd/U3F8c6F0kZl7o4gQT025HupKuhqzDtXcLH6GoBQ9UNF6Col0lZGolg1VO4k
2fRQJBCLlU05N1ZVgEbHEvU+kmcpq1JALwsLl6QR5lg+1SqWY3+hStRU59JJ9nQ2vVy3t/QNQ7E9
Ss4Q80v8bmUQDjtLHq+PvhXNd+C927dOhZQDeW6chnqJAeXVCZIQEBZnkp4KNUPzgDiIpCWborhe
isonxyMLAbms27y0p/VzJIFttqhYUR2vfsO+73LIUUQTXJTM5iRYFbi7AFztvyo5P8fxHhJbT+J4
P9BqPwatcbxHdW/K8UZSyg2rOd6NcNDKgLuZkDKW6BC9D6/meBfe2lYn4+X7os5jlV4DyIAoVHq0
lr9JKbj1Stun8xqpCYLyFPQIRrT98tjKqRLxN47j7azO7DQNO6rvwwEAdcJmt9122+0XBN6VS8bT
jrOIes8LgWw79C4AUKEo30YhmRXCqRTPs7Q4xllP88xcGDxkd5MycxQc794aSC5nwrqHHS+zdzHN
f2PBQUTnIIS5OrcdWmjLL2cuenY/401NLW1aGgu48gIXi+OkCgVoBeAaYylsuE764GqV8oRDbn3+
eOWvoAL3gGaz4N4HbWnsuMbxlgv7J3C8U+PoMVHhUG+5f/9ZZmum4jjrc7xnQS/TPHyo+5wHyBne
/XyO94q3Np0e02A9TYTQ/k/QeYBKc6NH8hZjfaKbYTXJm2k+VIFw712qE2/ZPF15LizNBPBJgv4K
EgXHW9NzqDz6S9a7z/G1hnXrJy7OvrUveZ0heto9HAXCKx9vLyruvzuPdhOZcoV2H/U96yqUl2e1
Fc134L3bV854NHHuoAzK5yrGm5QBbVb2h6gRHLEEWDdiryc4UlIu/bfTiD0sqDMEkSKHk7rv7qJP
X/HISnOctPHuC5iV3zdEs8TaOA9CSVXHmWujJdRVyXkSiNyPAbbgntHuFsc74Rn78UIt5OuSWU7x
0GXt7rtncLxb/bAm9wKNYGhAQ5/H8e6W4s/3RA7B6KHhdUIgD6qnCv4c8Kb93P8b1O9rneQdbOwS
uy9q9v3kwOjxboHPn+Q+jfoumUXpmeUMHKcOFcdut9122+0nBt69Uiy5tHwM8Rd8uc9uC+GDzVEQ
KyZL8ilQKrSH0tHk3MN2aGqDUlFYD+0i/n9LJM2ffybyk4uycZE0kiQgZaYqmPH/qFXWIXv2ino1
vWbUfPzuO/MMRHxVmRwofNfJQl+r94u+QSzAWsGLv9tgi+aOjTdqLuWKVRTzA16DvAvuwuK1mQiO
5TjTEGgrON7h2BnHG1GZXQbDNAhKON5J7esVVfMzHG+U1QkOnfX9qMPxXuinPTGzvltIzvHujUFs
Dl5xZ0tfByyOvCB+2tbLeTPdiPE9KYumWfWLSDwuoZiZoKjIpkNwvLECe7M3OHkwobJh5RqLr/rz
qNC2yfduy9zuqxX0BU3HvgQWkB+949V920tHDd2WjmYS7T7G7w+haK6O8u+7ZdR7x8U78N7tKyU7
er9EZ+YFGhMHEY2Dm1GyYEPZr0CcJ7PqkKrmLsBriaTp+wKQBXXKKeeL6Beajfy+Pl/DulpcrQcL
txX+q++l7U9Wrt+DvKMN2JVr8yNFUnJOy9ZbHG+uaP6IJ2PQXa/fQS0CuxzvWxB+VtX8KRxvKD5+
DLCXA2SDpmQ8g+OdecOXHG815ubnhUowrLg/TEwN4q0NU1kjcA2vXhLgoFvC6/ezKhSuxBUXFhq6
osSCvgukDyPODfhCaBXGwYvddtttt18YeOPyb86qlve3RWu7Fq8Ple9l/HpSzG2cnQ+JJXIg/jtN
kyhDcIpQIj1SeXfpMXi4zYSy3G8rBdSLImmZKFsmrqUWN1AdQC4K++9MjiThvKd3so8zCufN2M8q
SoG2L2qKq/Vg4bi+FWXjaFw89QMGR/bHXfHyVZ1O618bGSMzVLwqW+9yvL1QVLMEO+d4F/8OHO9F
VXNDfa52huONpRcI6I60Md2X5ZNKj3sWcMNOjjfqt1gQpNPlFlDl0Ob9mskY0yB5J34K6fOYovcA
uYOLkjVHxVib1awSkuepx5cx0A7UtM7MhTqYz8rBoPzSHcc/7kIrgFCFfia85wGK0kLvmakAosie
JG874n4rCZ+lPZ1A65WPN0N6D3Eu19FuIlMu0O7qWAzQvqHccH8/I5rabQfeu31SfiMtg0fzZWag
tQkldDxBlA1xn/I8kQXVLLG+LpJWl8Qji2f4jtBJVey2tPiwnmp4moBqbC+fbaV9AJyx8RaBT/KS
CsSUVxdrQbQx+E6DUlqSr/9Wi+yU421W2hshiNOd4HiLcwpK0XLxf43jfe+FrVLwBsfbYoCcc7zr
oDjleFsX0V5PBjLhNS4u2lSwRzIvDZUBgKBWkRSe+nQGvFEE9XrCfgwjKKl8GaJ+Guk272SnJ0yI
AroF6n8+4oOvJ7Ik8G677bbbjwy8fdYG3JWylQ3LlcaHia2BaK8Pxmjur6d4vnx8wl1C4zgTQWya
t5F4nGIOTBU3i8x4lUjN45Saomwi2Mb0uwLTZqvIQiQti9hrUTbUIm3Vc1sIwyPyact9/irq3dtn
pRAMsVjVJf8rllxIglP6mxFRtUR1fJGG3cgZUIRrmabbVF6nAXijuEKVDtOLVX9DnB/EMQxSTfy5
HO9Gn34px/s8KhwVyyv+PQr/sVWPe2jaQHI/0dE6IcJrnaEVYtkhfZmRB9PZu8OrVNCKrwXgPSXq
uBRjIa5mliDq/HxogqkyTZkGS1+FVvexRK2EWtaX72b27DrzG1WC7PPIy0J39wiz62+4addoty0Z
1y3+5pmtfzyveM6+P3f2oy469E49t5tsXV2aR7u7qPdWNN+B925froFPinRyqSFvlPLkc2DPyI2r
omxwomOWiqQVFitMlC29Y5U6dfwHR05BF1hBYQo4TXXb3fskjRl68ct2Xi140u3lMrO/cTvQKDje
GQou3+tGyTkLwFPOt0WON13LAzSAro5TcbxR8B6ex/F2ZaBF2TSKh80C7DzRZEL9XnG8fb+7aisW
KyMqjvdZHrjqzy0hN4F8lzFZSDojSeyiB/cihtY9cTWhHbE8gGaHwtoc6YPGjiVkexKATspeoCTu
tttuu/3wwFuUhaGpMNscXFcR8M4xkE7siwrkhihuUywQHou81YCF1usJvW7QIHyFq31OlI0kDJgK
NhNlQx6S69sLWoLLEXEVVKtbjnamXPUr3ZeSrEnj/XgG17uxHLYSYRA8vRQsWVYlF8+wGbnTwLkK
bhch7wzjgPDKlUmn5ObQANzyUu+K0xwDQ875pgH0iGz7ihJYej9XON5TUOcSX8/meMOq4HvMVaJ9
X4tcKeUCPZ3jvVKq3BTwW1ORrpI/mMqOZSVOOqb2MqIqn5KNzfCJkMTlYTlgPqlqnqOpuQjMoxLF
Tc2NpGXfDFQlW5RAI5rn0OO/o/lYyuzIM/IMl7bF+oVdObWT565QbTS2MzvD72ZHIRj2sbh/gkgH
1NoftUK9d0y8A+/dPj5d0fsu54H1yo+QlkFl1fanRdkq/mtFxGaLRuK7jXYAXYmykVAJHFaVYtMb
8j7X+z8C8pbPD2Vwy7pht0y+CnyS1XwrSM+C4+zNr0qsmep5h+NNE5pJmeuEMFuf413dj6dwvMER
uEcgSIasMRHZ4FN3ZM17HO+hz1zleCcRF+tXKwG8EnTEah06Ccx0FG2yLD+NZ9pBdzK3hki+uJal
a8+zLum7k75f4Bxvz+G5EJWxZNXmeO+222478E5ioAzXAUpCkbQSURnPzoR++TqBpfvRVi73SIkq
zyvnVORVbk7sjP+2I3amF3ldUTYjgU0QVBGibJPvdiOxC5HppudZirJBLFCSNU6xcF5YP9qXQb2B
trpv/r4qrvN5yFuqmus9Wy6uluf8s8Ai5ZBbhz8OzuVdOIc54EQ7yG3td4HjPQWvjn7hUXCGjjLv
62z/83FewfGeKTeZRskZh45xPjjH8bYCCly0nJvOSfc/6fSA/J6j9knrnzKttILlYiXFOA12XVnA
jDWkETGBp5JwMUlU+5hlPvZVwo9ywQ3kPRDDPbKEqktzdpZ4qGTnTBTisVWHTNUG6knXxGy8tKbh
ZL+fXFqgX/0tPuhEqNb4xTM5yNtzxJ2uot23PR31kbuo904p7cB7t0/JZiQItk9KIKla74qRrIiy
mT1BlM1FSA3Up7aZ0iJp/ZpxEoiG8ncV4LnAChWGtht9lsWK+6qqeR0YI5TGLwXgBJnq88P1ywql
2NMW1CJBhSg/l971aoEOERizwNUnEZFbpQVedcLxDqJsUPHQRY43pQxl1lgYxsO+DeA5jncSGBOa
VEnjklaJqK2hLOmj9/vZRNebAbcWc2SBOBlT/DNUSvzEhguDkF6vFJxUcDQC4fQ7cj7o+kMRWhgV
bcwGMViZKOwHXqgTWCdBlY2Y77bbbj8m8IaeB0tBkc5guMr37gu56AG9o8pa7gfdfKnJiT/bgRJJ
i4GpyXRsR+ysOl5PlM0F4VZM2rT0rSnKBh0sRFE2zoR/LGSSlUrfOn7hDWLd8bWoN9DcPlWzXU0q
nUOlZeDsEjbc9qujJisoBt3KBXqcjB8u+OVV6abyK0sSb1LULHVuyn2876XKguMdAnCIQGZRkDcc
kylcP5XjPZeSTxZOJ5wunsPxZuMVWpoAXBU/O6dEKwBFn2xcIy05XylZF7MMxHtdvk6AJaLmM3B7
kq8b+p0Q8JSTbBHAa6AdCwKmNN+UVJqRWTWlXYkzKQGETPTVyhL+ztQGvSh5AcWb7/vaUS7lQays
1Lhw3Uw5vNquRqQP8u/EAfzoH3/e7li6BxXqvRXNd+C925fKaDBxFhGiII2WxcCJ9HjPFGXjBV9d
UTbtz2xhYudctJ4oGy9/CwtypEs8l3zYrU401YvgMnAWyS40AuMqeK7eJ2TnXCxs0xUNWFqokbBI
7hViTStHqjs+3vRRoqW6LS+ZIc2+FF8Fe+nxyTGsyfEG0rwqBvsvjLSewad5SlJc5niTsm3ro/lW
BeushH0hcVAFGcj2A3E9IrGRBjDpKA2jGGtGV4ZK0ZpI4RIbQayomvc1HToxOhuDoCc12pfmVw/k
DjaDX9rvQRNoEyUPu5Jst91224F3slZdV2xWqPK8qFnnz5ld8wLPlKm7yuWPc0c5+fIStZYkmQxM
oYJts3ZgelaUzVQZd7F8GZF6buOdiaShEGVjnHJUsVAWOcnkA8q+VqHedf9e79vnqk1U5UqF4pWL
zRbF+2Lg/IzfZA4NNAiOi2l92fyTzh2Vx2adKSk5b/GIy5wBsfwSSKnieIdtfFBV2aE9jeONQSGe
iziiMaZX71nN8S6SO0llxpJieRguz1WQIaEYmBV+8cX7GM+FJRrAw+RmBQ4L2HtBd3cMP2kDhiez
hqUuJbgwXWv8TuaNgZpFE/hYvVdlWn9pPl2+b/IjNJ7xonIKll/fa33tA5v0y7araHey5+M5auJd
xXKJeu94eAfeuz0zIbGg7toYbCuRNDStMFZE2dQ0uiTKRuabriibnFAwX0+a90A1QZpA0OsVCthz
2Wj3QgB+YlHwxOC6qiSX348evms23oJDnSTbAF2F3hF8n95pF8yicX5XfLzBrcxUKm9SE7e5rDz9
zoR681WOt+ivsiwYAxb4Eo43ogBptv9MPC+J4CYBtQW6Fw2Kp/1Uugkr7zG3L4WK+ADyTqNInHpF
8iI4pGPCCVXz1cCrENYrRRONJ7J1ddE0WZ+LDuluOZBxveB6z8m77bYD75+3gr4UjJ5Fsvlk39/u
zKUvX4tQTZUZ8meIpMGJpJ0WZUPveKkoWyJ2VoiyzeJsuhw3iqS5RZTkvqsFG6TXaaWJXV3byjrl
agxfo95rGgpa4bwfBGu8dh0FQ3EbVyna6987W52MilCIoyFb6JYBmSUe54TvLUrOlzjehY939hma
eglZ0J29Q4wHnnK8gSjQhsEPPVJKndVW38HgHMcbQYcEF4SmIAbytr2Yu6ept3bxHqk+1BdZE/qa
UMF0NT2DBOWgcwB80N0d4IOquU5IxRxRU9W8yfenSQrLyu6L0buskjO9HrjPm0j6y8qUimqW7u37
un7cyYV2tvZA48TOZHdec4UZqn1tr+JdZUe7ehLIFc27Z3lDvXc6aAfeu31SDsP43BMnf+1ZcVkk
TR/PniDKZudF2cBnnr76KyzzLCkXC5T77lfmVwT1flNnb5SKt4TX9Bp24fD0CJXVWR5fZUTOysy+
8hRmGYQ1yBv0OCSw7nC82z7e6LAy5uAanJctsghz8MVQ3lHVnXG8CzFNPp7x381D2As43ugly4Cm
KF8W/D5hbc4q4osXsnhVYHXcQeg+aJSCs0SpEldrnDMrr26rmmM1rsKUCsvO37+XTISRCtOh4MRb
fm1Vp0KqZ4BedrQ97u+222478P7OQePCN1Pwd3kd/1y+d2tBVCqeL+yvmyG/IJKWhS8dUTYsHi9T
Cz8nyqaEa+YkBAuoVkXZ2r6WKUrHIpJiYf9SP83nv0eZ0E07wGiVAjTctytVc8abRoZeV0G6i4Oz
YyJHKOICHRfK6InHuVa10iXnSQB+1sc7BPgrHO9QVt1/P8Bs1JBXPfltMFXbKLFJXFrcT9uSfaGJ
RKvfVJ+ja/GFfIRl90Q+omVbM3//OSebzhKL3F6qV6J8wlPeVj10M0/0vJwFPVXzNFJvqJrLVEbt
Vc2XCiD70JS1js37/Gw5xx/lPM20AMS6IBV8JffXKd9XVRtpJufUEmHdS/vVkcFzG+NzF2i3/rNx
uOO5Z7sVzXfgvdtHJib8IpiuTKbJColImhaw1cYfakIGDcqvibJlgVUtygY5L3m1VibAVHLfqao5
OjN9Lta8U+ytABxdmPoZquYsALfF4DhZPnG7MyNFKimOF+7NGv88STKgKGNXpeYK3SZB8rM53tl2
M/o7JIOGsnFV3u5Ly8e+2hMlrHjHHY53ntPtOPilZeuLgbj8TVEa3qZ9NbSmoj0dxLyi3yLA6pvq
Xwyry+TR+A6GNX6L2BF6owRjjmeTUr1SYUG3FD5MSyjOXbd6IVBo4ey22267/djAOxXTaHiRVIHZ
ord3i7Pd8OU+u0CZfts8ZxlnAt1bYGk2l0xkGcLXE7++IMoGFyIviLL5zEK1GMnTGBa576Iv5tz3
zpJotR8kAR2q73FB3Xy9r8ekUT1kYC22tk6kclrVvCXzVvc0JTGMJFG0auONkyW9pWjUgt1Zhkhr
fm7F++5zvBly3a4ycvtg3HLKR5+sy4SPN7DQy5OAexIyU2jv9fd1OeBmz1rMm+UYZLpkuXPr/P0H
U+s0R22CThgLzXNbZRCHoLu9jDqvan5unaaTHXHu/P/sfYmS5LiOpGg2///J6zs93VkVknA4QFBH
JDC2268yJIqieMBxOBwDBpS4eig6IWxjgG8gtQ3/ui4pnYNOFRo9qcz4a0aJtvK3ExUIJntVY9C4
ksRO8nZvwv+W/5T1LR+d0lEvtdXrlgbeLUkgFTUiuLseuAQzYAtp6UwIls8WztOYZEnZdLZxEGw7
GVI2O/cdwrhDUvp+zwKYPJaRwM1FrOZi2CYHjnl96nwzWcb7tDa8/HP/tXHWO0GwXkugTM3xlj3h
lMKo5HhLuvzJJnc0/EH3mlul1HxgaoHvM2M2DOOSD7Cl9CCitjMQJh+1Cd+QBv07cL7x4Jyez06U
FRSw5qJRsQQgw2q+GQaqQ3uZGovM5eIcg/CKRj/EMHacyu8x35BXgfQc7yn2lI4+a2lpeS3wVrVF
I78QnuX+2E7Mc+2B4Uhe9yzj+SfhmOxB/8h909i8IYMRVVUhSNmORD/nd7aeh32/JkjZjmNknaVe
BZk9eTlEpf+kEMG2Y4Ps1F4Z3BPCueQzlLph4cicZxsTyoertLuANM5qzgLwXDi2v7/x/GipemKH
vGErvpQFI1IYveIVhlf6D0rO5wH8GTneR6/6yeN5zPHejBxveCDeAXOQwfcpReWz/ycDZF2Ot23g
EMjtkjnewJbOC5fOz1Ad7y0AzqXyeiFw79RxtioxwDBMGXXkcYDTFug29xrhXPIrFoAA8IqBBgbQ
VvYsLZrvI6mCXI44F1bDFt77vXKjyv9wriMMg+ZL6tbZKT8w2Odv8waKMm/1aj+u5jfGJrq2q7qD
Nb1vaeDdUm2rMMus6HmAcqQcNj1nkGPm1cKyvH5SB98KUjZYgXxyH47qNEfKBsPwgA3uQdhbqQvC
V7Gak4S3DKt5tcubzx/XozI0gG8SuwmgRiqRZCu4cKsH7OtoayH2BTneEjXTIccbu7rnutVLzCMl
qiX4VkUBjvyAco9xegvkeCcBbgTU8jnvm10rPNqPQNqMml6RKD+l4u8KVnNlQ4AHCLElIt9l1IfN
N+ht22YSvQF8WH0K9sHK8QZjyT73AQfPeR/RLS0NvN+rYOshh2cdxWZ21gieZQWJqzsMRcFP57hl
GdHd4wjUNVAVELjAJZ6hJIfvUaRsEoA+5puTz7OA8AZuSG1StqOycwA1qgInus+FzuKDpZigaSXM
8Ku93lGipr1SRLCQs4iCZTUX5lo+HDvutQbZU+53KOs3MzZMKsbHnAIHsCnNWmK9V3O8YZaVOnv2
cjneJqpVQt7/RlTs+S1mc7zNkm8inwNCRhEtOmY25FzaH46h+lqXj2d5tpKC3Ef9W+pRUGdf7QnX
mazmAXBMTQWI55Bm4PKeyxaOEYE2NP0N8jmozEeQVbN9gshgfXjAfZ6rDcEybMyaGEhDBQzdI2v2
cFP+Zt5gTaXuvQSypGfKeJ+TuYVLhnH7oN6Cvb6lgfevk0jIW+Y3+TlOOQzHgGEetUQ/mdx1lpSN
OiNMUrYtTMqGwwmrA5O9p07NRQdbLXoLfIuvXCx8bDdDzkZFckM01nDh2KtZzaGXOoP8O05gQuEQ
phjfN9WrelK4nO+meWz/MIiHc7wVRd3J8bbssWIJMWNRUsY0ebuSgeinYw4fobUgPg6T4330cDP2
iwLATev5EjvgLnffCX0PGel0kipjMNx2IJjCti3Aak6lkChnSTAH/rg2uZD6/Xlqp6wHyW/Zcz+o
e+2GkDZ2CCR+HZXW0tLA+yt1b+1/e3k3Ui6eFppalbMayVsL5o7bvwVBmxk2bjzbAsk/iiHIRCcL
KB1AgqaoREnZFPV480jZ9MMWROCBTcp29FL4vHaBkisP9np/KugggTVVpYDVpSiuIdYjUMVqToBf
wYgEF4TaYNEDKGffFIz5gzODsVGDzw9Bl4nb9uDR//bnWuLwIxWUNiSSLTgACLtnQjW+xet4WxEY
Qh3vzc4L1wxM2OpyvNXfzIpcoLY9LeTcLw943tJNLv8DR4e8j3q1q4Os5vAg4WfoDLipwwByOMYz
o53zf4/HIRzDKGSb0pHPRznHUmsplI8t7FXOieKe49CHxjzA4Bt1y6pxVzi1ay+c7MjJbyz+T+NP
svx4n091ttnbx+6/6nWNgxt4t9RsCIBP4XvM1bZJ3Q5HtEF0EjludN8KBIVCU8girOY5UrZ9+FiA
lE1xXR9D4uEhYOlcVxldOWD8NbOdBOBumLoLdzNsNSQAd1O8Ha+1C34DGqXttkyyuYvBrKIN6nwr
zMLJEhh0c7w3e3w0wrMtkOP95+/adiEYXhnywsMG4wCBD4I5yPNYGl7fwDJtviLLdyGXduI8Cu56
YIgYfUOcZlgBZR/EmTzzeCZYYOO0p8RZzVVOAO8kN7ZKfU0ZYA/c300jAAnPJDZ+Df6CfkCm2FtL
S0sD7+fBS13n0wCjs9kzzMniwW2cSiDzwbcEOY303+0AkJn+yG1A9wL8UVBloA/lQFONAmw/NaDk
9NMzDhyvcx2+LCmbOVshahKnEkCS4gVNa+Mt1fC8mA/3epcDddZrrJCXEdOliNWc9cCRANsBeOr2
FLxfVKCNkHMJgLsA4ARmITCp42xEI3K8N4EcDi5J30H5PvJLAHo0EPbEaNshhFdW5fN1vM9mT4nW
XDNMGn41JHK8NYb5KKu5VKvbOXc4A5wFq60zjaHkgEzWJoBu+MHdNtwzIgVIvjQy0/cv3GcUK4gk
KGeCUXzwHvhlyeC8v2KA2T7SE8goQHWqJTkTzSeUlq1G7hay2tzEj+T9o3pABBnbTG4394ixf99/
/v3P/wN7+zD/3dLAu6UYVITARmRvYwBzFFQzFLpRwwTgAvXjqeGTsjFBfkWkbI7y48IuwOnDd8zp
ZQCcBZWUKY9tOsJavsXiIJ0QZMYokHWQi92VvM8mqZFXd1oAF0JpMSXfSOVlkEJhYeapnyNrRMMU
vEJEoFNYNT8aDsD3M1zdI5GK0JqfvIPW1PUiFTbfw83uE/badsA1IGAsqPsppHPGTclijI4G3icw
yKWs5sY33pxyYrZ9F7bh4A6waRi5Nwrzo1nNW1oaeH8L6IwdbvGD0PYmIlHfW3z6BbtyuPQLMUoQ
ym6A/G5u259KEdNPyxvG4HgXQJ8fOEXKtiVI2VhbB+yoDi7F+z6vN3tdKO+bbZsF4Ecug22mRngg
nB3YAWuwEcqh3/c8BhZju25UEOatE3Iugp1jvrYVvbMpOeY41u/lcrzh5Hh7HBWAZwjUDAAfowUI
TG6fQHYLBtlCBZBWWwBEsK+unUw4M8g1C77ONyyuEGIBHYff4kFxo+KMKgze8pl1HOpmPz0J44z9
sWkI3vyeUh42+e130SaCu/4YQo9TPIicuiWt/yO5YozH24q2cz6UamAJVCvYiExtkWRRb0ttg516
S9XaOywZvLc7xLN+9HbDupRnOXdzvdsr3sC7ZQ1IAYjQeoawLbgFwmXyVZRnQvlCwFU3TcpGHqZa
nvUyUjZWEfhFa2WKIVlRuBEs5M2EqoNwD0YcTx6TulfqTAfX/HNsryqEKe3l0xqs52IYqATqPcKq
AyADuBzvQ173MccbRI43sjneVvkqQAxXVwG3Nq67f4IzVCFWeiytayO4gEWDjV86j7O/KcZXsKRj
YoJW8Nx0gDGdsB88+52qgTPfGFpqlXtfFrNBSKMQvhJLREfg25aWlgbe71O0g7/K3DQxlvGdZ+UT
KGYZyo+HuAEsWQDts4UfwAoLTD+VDPB5bpiJZw1dB5OyRawvawBoSeWBCJINza2clG3/PgBnzJA7
wRPbRD/dJwixQHKV4SnTPuiwctBKeKZGOHMNnP75wJj7/c81m6HMb3YNcRgR3l7IuVzE6rhHbuIV
+KD5h6ZTgwElm53jHSrrd87x3gN1ywDw90w4hbPv3gEhMAAFYEenshZ98PdcgQ7AmXrQ0N142HJh
Hno1aGbvMAwU1v/6WBDYrPrFpG/1oxGQnGveiaa71QXj2elccowrrpNXJzbcs/tv5xrmkdxu9fzT
68PzbN9c2TmJOM/ntrfWgXeaB+tvMHXGSYPQrAbPXTIusGr8PGOE7uAv3nu7x9h/JM8p3V7tBt4t
SZCwwhBh2dLjHmqhDZxD+KBjd1Vx3lSQzJdDNR0MjFLpsprrDwT5wApSNjevljQgvW19pAH45qdK
cO3XA3D32RFWc9ejztYI91Qp1ze9fdan19n2YdZgVisCHYeGKOclG/LkdSMTg/l7wskQuzLHGzpI
wekdcMr91sEyp/nOeLHDdb2Rf7bFaq4ZoODW2rbWiLBOPSI+KfQX0rsyCNuulOCa/OAY1Vh075UT
U4xwx3WbM0vkOG0AoU69tEb5ib5p9dlbWloaeL9QEy/aw0I5e6R//QhGE2FJoFiDjZDrC9nCQ8/D
EURy9cH/PhoEgIah6F7Aau46uCtJ2Q5uj6JFEPF6817eeaPTTCg55WcOAnB2aLhreQ9eBICzwHnT
lGyNsImptHYuwrt5IeciWy4c5Rp7eInTeMk53jYVlZHjbZZgPBsQ0zneOwCqAXkix5vLmSDmCU55
1vQamigj9icbPBnFdTIQc9UFN5pcTQXGjsHsZAk4zL/j/za95pEazEhsjvF21HJiDuAWEPF25Ek/
bS9aeVDp+XtLFVOdT27z8FzJVgS3xjzrWz5voc+B8ZnzuMrbzfwtK4Es7ZHsgeLtrnZSt9e7gXdL
AGiE/raYLdz8m46E5QMGnsJl+ubNv50VXVmpnGM1t5GqF+GdJ2VTgMLR4IB3zfMMORoi6HcBAI8s
JY4EDQWkb5/9c1ievahrFj+BAMcHY4VEgIaIy1sxOsHJIz+B4g+y4mM6jbz6pFQjHFJ8gjneG+kR
86pJnPYABOc6FMZy+9tYBrGwhzuooJus5ruybfbagRdGpJKrSfHe2hclgoEhNjNBsIaDeYZtCCLC
E0nxZqCYVZMbVHV5tT27CgJ40lgb08dsWS0tLQ28XwZFicNiM8vUnJS+jzw8y0pOg4Ef5Y3Kc+as
nTFgEghqCrKFW+UsMf39LECjeZj2Sq6Zs8QAUhpA30fKZtpDEkqE/6UmDDTKN6wE4AGk7od9Krcw
9AI2WRrDah4H4Dyreax+HSQr0YTL+xztrXvARZCn1E/fK+nbodavAWY2ljRrvwIRDBilcry1PVnJ
Az9jPGcSBEqChQ0sThkwj/CTuYZZI2aEloeFGOMU86Pu8D5PPKX6B1nGW197PKrd266EtTRTTkwG
/Zn7laCZ7Zx/DWYcFRANwYBjfIbwOSh+Z47+hba2nLk4BIsqgkaMjd0ULlb1xQtDHOKEjMMzODbz
ktzuj5/Pf8u/Y9f5buDdQigJkT0pYr218rsrWdQtxdEkv5sdsqg33zGKSOR50wC6gJTNN2Dgpcvh
OgA+d9s5SgKkog7S+DHHam6N6VqXtwx8rbJOm1PvmWV8PxA1QTfCnbRSEMYyI8d7z3MRyPEOlGEU
wffn28Den23vstY3HnBXrO0ZQG9NVfgTWTXSWcv6HL0UOaS5XGB4a/KwbuDW0ba8wfI6BJnPzdTx
NvsAY8LP0qgb+xu1NpJzL7K+W1paGng/XU3XFVorlwZcyGkG+Jw4MwMs37NlwSxCMETCxveNmQA1
xuqOU5i5P4J+LuXxcNNZZqM1Nh1vpQugLSVJ/R+EQg7xcE/YcQgdhWRHLfB6s2vhqCyFgQClCDEe
a3DzhFPjQ8a2jEfdTuvQwESM1VyeG8r6lxbFEUio7uvPbwmVPA2Sy9tIfTkC6T9zUdpbZ3O8Aee5
RyOale8rrx9qjRBpSgCo0O90yLkCGP/cC37fcJ+kGdLglbhTbU36/4JPTMbFUnhezOie8jeqgxgx
C43uIL84RuYa9kPq7KqBcM7R/XWsAQanaBLWmw3vCsKwAvOf2Q8Pwv4TS8zKzrlaDWWbHJe9DL69
rLf70HzW+Rz1WreXu4F3y6eSpZUGI0PoNgQ2yQCr7aSu9qE4MsAKgRrePGRWa3KqbROAcYLVXDpR
WVI2YHs5afk6WpgMCOf113gdbsYAZX7PSVZz6/r1rOZS7WtPQbXyiJXLRcD8GX4O3ei3CWRr0PeG
k/L+GTHzmSOOZB1vuvydUcc7GOmCA4HbKQQdc2HjFR5u1jhn9YE9Y/wZbnmoL2A1N0Cx6eV2PM00
bjkFyiDXjgfOoJUWs58FNhmbLWUX0rWQHtaWlpYG3g9V2DejBvf+EojnpOwPPdedlUMis4znartK
SBVI74PWpwxRlQ+grVD0HKu5pTAxoe8V7OtbaPxYUjYfQGtA3Rpecb4IirJpwVcNBYwCRSgWhlEH
iRyzlSGtG+jg0+BVceMCVaKMap2tAe7M0QOPgRhd4YXkHr1FTMi5k7uqKuSWlxMy6DaNfjuyNAF0
M7o9zozqx7lp1vGW+g3hPAyeD9L6yNTxtkA+vL3b85BK3/6YzmDleB8aBFykJTima1jNNwG2Y9tM
L7DIap4A3cdQcZjeZePbmM+Okb7hNA+MaAflrg1n/Pv5bjBTtRRrnWhos6p8QLQzno+58/mMzfBy
h6MPnALfcS275BS8ztowtjqDfoApfcZ5/I/nOejtrvJWt9e7gfevEy3fOs7dLSlZPviIAmgbVM9W
Dt+Cz7O1XJcp/AhYXZxD5uTCVhPOAJpgZYfnq7zpjJue/3GP/SU534gG2Pms5r4H/kAQBIK7IEKy
qNTzld5bBdfM7HOI67TIfLjmAh+4ScgcQuLtrizYiaLaKHe26dvZHvDjEKLv53ifuMNJD9wMT4bt
XWa158XFjJhQjSSE0IyX7J4ls5pL50A2FzjDXC5sJyC80dGQ9fwRbhvVxI0AZ6+6leoQTPFm9jFt
/YKyZmLbmta8paXlm4A3DhqlBxllZSUWkvpn8xdzeNYRpMEFY4zCIai2nme3iuxMACtWG9Ec+Fli
ObPtMJU7C6C3Tc4zJVnN6TYdQwjj0bbN/uIa4QH4NXW8V1kF2JJifr8DzOef5au2mOdBVTitWcDT
pzts0n7IuVI92l7wGimalcsOee3tlvYxLPxM3mF+/DPbuuQhtLz32K2Rzz3cBt847aeR+afPFAje
O+6sUr3b4M948bfAdLQibhDeDnhWcyjoOAS4d+0p0QKevjRjL2FVD0TmF/H9JYLWbfNz0yHsHpC3
ASnL4+968Yr92eDaJQIHt2fLcx7JDznnCseiR9TIuKa9UfPUu5zP7fVu4P2dMo1adaWO91ZHuhAj
5uJzsTczv5sF2RRBXTLMnPJAB0jZdDABDrAh7sk6pi5ECP1un/+p0GyEyNfWAPAIqznTn7zGosdB
gGu6AFxrc1Eorn1GGk7UgQbctNQOnEJHP/aiI0cVIsYrJdHk450kIjDRKHXM8SZUexzm27mfmJjq
BumYYRiKse9DLm+oMdoLgJsjeONQhMb3Qe8ZjsMb7p5o5Iyzdbwnt3YzrD1Zxzu7u4nf3QGq2nzB
5hG38iCZgbwwqiCGj8SWlpYG3s8Et9LZZNCiaAyb1BGtH857JnGCJIYqxeX3xfLQst5bxgvC5ZNP
x6FtqVz5H0WfhUfh08/xxoUBNJ/7nqnPm3sz0qQQ8XqTfczkfcvqXJ7AybuH8xRmanVvFNCJgGU3
EYHSfn1KJ7bG+efeCGUqYbMB+FmZll3ecI131tw/5FRDylPGHqAa587fbuGsvAN6BYI/Od46GMfJ
2HOuC35mQ0/alo73eHuI1jAx71RytE1grRe/b6DUGJSxZ6MCIqzmO86ETYxXFtn9NfCnhIhDAsYq
CSFs0FtQx3vPmi4bK/w2lLEPqQ8IaXnwiVrU9ejHocNnsJc885FY+ujyAzHXHys/+d0VdbxH6rIs
m/ms07m91g28f42wsDFUpsrdpDlFggL9GxzPSAWAPj4bNAlctJxaKIfdYIeWALRVms0kOqeYipKs
5jzsvXWNhPzdqADgnMaYDVXn7pvwWCML/A3AavSN0a2R/n6eWQe+UUtzq7oaJpyIkU93JGLVIKTm
FWJDqCWN9BFSt5lPD+4OsFfUo5cYzSAaiCIGoMi6sTyo2HSeDp9cTTg3gITNcoLVnEFtRMw5qHUV
W8BijW4Q1xAPRiBpHJudlhB/vmzhQyKR/TTuiOXvm4u7paWlgfd7wO9MrrN9wGXZiKXavlBq4MAF
inOkZVHitNkw5CiongbQJHKB5o7eeZwCYd1MWbCIoYYkZdNfA2LNWjPMnDLmGyF4gZrcMIAQDwq4
e6Je46Vs6SG2Io5cLbQOgFDf4Gxsds1ur8a5EulzICUzAfiRzVrsKsS8YnVemzneAnEIcAC7Z+Z3
KTt9R3JFRP3vyqFptcYV8I0/VQ72by+vkTPAzgDmLctqnqktHtxPTvu81x/PWBVmNRem3caxmquz
A5Y+hLn63ky+vXk9S/xJ6ifY9DAVsxBHxDwN0UuN7RBFcspJkd324nc1OhGvy10M4LHZxiJUPgrF
rzgKxmdMXZ7yOSc81e3dbuD9fVJojZyoYiWQ4rAKeITVPNb2rAe6Zkxz6QC2ecK+EvCORSQAtJVH
T5KyhYAsnrXGZsJAA/dd8dqR/HIESRHVaN1Q7qnz7EpWc6WGuNiMEXK+ucBG8thDBnynMmD70Gz1
nZU+s7sJpPqUm527qodYb1p+1KaFmvPGnvx+kc3xpefw0cAplUtzASpJhUXVGY+ymuugOwI6TnW8
hUhwJMhdMVN6iiLF46Ye2OlphFzjcA8Y1nTlPf5Gdzixi1R0jx8Z0dLS0sD7GxA0eyzLDLUIMHok
cPwsoGAB9IxBQAfsNd7xCjATNQi4RyR8pmCpESqvO8JqfrieZjU/hF2GVSKXelUASQFzBiYA+IZ8
6DaTZ32d3aHOU/4Z6cClbMfrmecsGjI49teKnTt7Bjps9YEjyZo9u4+e4k2qwa09T/J6q2WRNr20
4CEv3IGNNrTCwYM/S8B5TBmKsJoH5v8+wsYjwAI1/7bEPjTPag7TZGR9yk8WcpEngNzeIfyf11Cc
QE34bmcLgah/7WuB6xErOBkkjOsRY+4RjxmYpgqBm0KZkWIdbykvfHOiGYhpaOZ2r6gZx4f+Y5mx
4Se/23q/4fydzA8vYjL/v3s7v7uB928XZFB0uCWQyC2+Ee68s1SYeX1oufV7GiQbrObSN/KYzsUS
Qc64A8y42gB6U5V88nNtdV5c3LjGsgB8zo3N5Xxn876jAL40VP0DEPuk5URYbxWr+WYZMTc95NwC
4FJuNdS48xNAFI1q5jufS4tB7r62c5xB1EkB1ffJYyklvYLZ/F5uEZR9EnaZxpFNN2rSHm6Pl83k
ydAjLs4hwoVEjCKrOcS67X6syBmYyqAeG+M+put4TxweCDF5+cxfrmGA5JI4/SrmrpPluDNPrCDP
aGlpaeB9l3DWHSvchywdsW2HUCMQ7TtKeRjI+mXEGCZxkC5uUAzrc9dEQHsUyGT0BZ1cDWRkABzo
rb8HQqRsm00rHRpvJKaz4/VOlgNN63pTseTrmM8zc7Y8VL0KgP+ATMc2SOfoAicga091wz22acza
GkGh5ZaCCJZPHioVaQtjdDLufYJ3qIvnRGDl4SWHusIOUdcBtrtaqDTfj3lIcQ3Az3kngZGZxw7b
G/533texmivI8kAhII2BmdjkdMvwVhfX8T6ZzDJ1vDcDzGOTC50xxIJalQPr+8CqNa5wABBk5vaQ
6F5vcK7sdYKa62cZ2WMPZD3BY+qSdG53e6obeLf4ewxTwmuvZNnek53iROCpaCg5iN+ydbUrAfSM
hzENhMLlvXQcYVrCqTBLuwhehtX8qtBpj2QrdMYiEa4ua9k+9lsIwGfuq5r7R+DDjClH/jf33vDa
VNMrmDBin7oJx1bBzvGD5xJSygdB4qkYAgCnrNMmEcdhm7FFWd9KJdmrqgEfvcVKgTgYhxjjqAXI
+B3IgsjRJG8EIYqY5F2wn/N1vGfPDZY7E8QakwxXKuGZCG4lAkPE07VwBtMSyRoQgqEtLS0NvB8G
kXfMr8E6tQrg0QmBNEDlHLag/Ah674gctJSmXKz8ryRUmylDxrCaa0B43ybxjekIB5uUTZovmlEl
pBPBVhejkasMyVZYCy8AizEAzoGUbP3v6vbD5GrbTFbfoWY1XZucKSO2bafoErUeORQW4uOfpLxZ
6OWolPFyuNBF0LzLff5sR8u5PdboFnJ57Q3f3i+O4fYpBnFwdbPVHGzt7JtNZwhZGTa6vPIKVvMj
vhPxslFO7ESu5oBU08v91DreVkbKqf45W187SGIm8R7imGpCj97GddKPkcioxRthHhLXBmlfKHKC
F4pXx3vkejH0f69mMj9G+HY+dwPv75QocBTZzowNC8FnMkoQ/JB0s+0H1NWere3NlbzhDwIWCJ+G
kpxHMA86UqmQlF13zBYef9j4Gsve94kQr5UB8FowXW3UMgE7OQ6wBsJk+EZq/E4gBjrgFo2Hp1Bv
fYqJ26Lw/P0ahTQEtuECSBwXsEHoZgCiz7JgAi8F2NpKkXlNGoLkPHDOKR5Z1XOs5uR5oEYjyShb
ZjU3ysXR4AYqezdmQLczO837let9krZozWyhPQX0BlO89f6w2BweCZ7wb8ift6WlpYH3l4FnAxDQ
J6Gx54ILDbceIeNnA5iyCrribT0qVep9C+pqz9bxvgLExIwKpJ1G8Dbpk4YNc4947vMGiGm+pkgs
oXlbgngt7JXeUgh8dTh5ur74Zofj2+mXLAUU8n0Sy4ix+eGbkBd7Bi2yEiwvXAtsW/k+mnFul9Ms
eU/h5e3yMdAy+N73wduvZ/gMzLly+m5aJMPGXXMpq3kkeo5gNRdo9pgtM8pcXrBUWROS9/E3tmq3
+XJuNDfsILTM3omAAQ3ah/Kq4oD6zuyUq1WboyEOT5BsHe/B/YzwnUp7g27jx7ttebnbA97A+ytQ
up9B5O9/vt3YPpL3ChX7vOhWiZJKkpflEpMM5CHG82D+Fhg2czpnMlBGKzRHnXGZMr37EQUTDm8f
gEf0zAwITwP3+0PVpb6rwMb7NMmSYd660/ah3f7qGrIkhRXqziiV5dPBswEWjmOrMaNbK+fkbWfC
QD/D1RUA/QG+QypzcD6GSPTCpRQFNnIrWvoiVvMw/jZADNS+Ip57fghBT9dSDxThVhntC+t4Mxnc
8rzHmTfhqOk4v0Faz4aHHV7/DFhLHmktLS0NvF+AoaEcuO5GqHKbB0FJtJxUoG2CJCz7PD+slA8X
qwDjK1jUK0CUCITBtwdSyYDE4AwO19Cl1zZSaYILrwngnEPh6TByCYRHhmGqbFkt4Ek84Aw2s+8G
30BHk2NpoSI4c2oA/Kw41wiX039oQyjO726Blr0HTGDy1qIIhPE45Xyz+5QGxhM53pkoJGzcGpsx
5VzHai7naMNC2XD6SeU5QdFL5IMjW8dbBNFlwN6p4y2dJ0J5PRwNA2ABrnJfVLXTyurBfAXzWyPE
9E66RpDZJYuV7ZC1ZTUA+Kzj/ekNHsLfHJG83WP/E9/WmM7tbu92A+/vAuseklE3VKTahcTcFsz9
BuNREOvYErTmBmhL534T+d3XAujZmrexVAHm+yBdyJtXilC5TjIAPKkEzBiwIsRi2VD3dXW8iydp
EoCj6pEKqD8Bjc2mz/ir8dplsNgqYn89qbbx4U+/HEJOdc0eI5kA+Vz4fC4+vd3WNoApg9HegEHW
NmZAEGAW8GS67XnZa1jNmVKjNqu5FnEN6aF766C6Duw63jzoLt8s2WcgNxFVjzUmNiuH+O1sT8HU
i4DZMFtaWhp4vx44Gwr1TsEiOGlA8pRQpaWSgNEqPRE25FLgwe91pPTWKvBbAcjZHHaK1Zx5N0fD
1J+DMHBmSqHUBBNzALwkDn0GWERYwzNq1lPqeCvAEdm2D/nKOhghIzSkv8AHZuq32Ret1rkRDNB9
UpjN6hWHG6WogD9eb308Ycy8aOlI+7M5Od5JSAF4bt759ejOyxJWcyGFwQHloMLP/Zrc9oeAk+Oc
UzawIXwPC7rduZQ88stAfWC/1M8xCL06k0BK/YS0F5nvi3Xvu6L+963e7h/RWM2PMvhm2SbFxwzx
yUO9vD3bDby/XCLA1adHkY4hG+pY+eCRLG/2ec62Tj4vvrHCsU7MsqhXAKFYH3wFGbCpf1yF2TL+
4FhLHPGwcgTXCQoXXQKAwxqABwHw6DNmjUclehFjMKLGRgco4h9gkQwJbQH89AVEUA+pftOfuaTn
eOMzJFZanESail31QE4bOobRH41zQNUyZMjQlDzfwBqYKkcnfKc5VvNg+VGT1Xy3a8uhFlqKdD7J
e7K24r4z5XW8nSRvva62MnSwS3DDYC2HEbav7z+zBgrwB3A7v1taGng/HTDPw8H9EQPzVPIV0ek6
3lG3OdY9T9Z54I5LNszaUrhWliHLAJ1oSKRVMizsJUVF6aAJLoMJJa/E4b3V5WNHa2Fniddur+N9
GCO/3BN84DNDvCaEbvs5tyC+95mITHuGRYoGw3MOrw3gkCMOPVxeKg0WzvGGYs+APA4E4KZMr4jP
nRIPt9mvJKs5tV9H9ipoqDC22x7IwKJ0qGK5LwGol9bxpsbLdAdzKth02Y1NLYOGTV5LsmHFr+Pt
BzLs50u2JLlsT4img02g/ypvd7oLQ1nlyfzuz9sPP/HtyN5u/fJ8HnhLA+/3CizgupF1wSrwOmrw
+uTz/LLhuuKneXxXsKizSh0LxnlQEwDOCOZiI84MY4XJTzi85etVJJ0gXguW9KqslAMEW8oA/QBw
zzJNc1EPMbodWORqJABHhOmfJbQ6glztkqMaetizOM5ITOd7/skLP6xFKPnbOwPFRx1vew9KGpNo
8AmJyc7dfxkyN7/fcLYKktXc+Ibh8mba/DUKSMjmAK8+NFfHewbMROt4i8C+ZMf+G1exaevhTL2m
XC+V2fNC1o8pLtjPI4ac3z00mdnY0tLSwPtN2JnaxuAaTUsd0HN43QglV/qsPg8BIMyAhNyZnw55
Dfdvvo535rm7K0mDAO+9L9C8kBzPjfVcJ4nXMl7lMqXFLik1VWM8eN9UHW+25FPRjGeZsqGGZcMM
5PA99HuABWKvcmv1mvni3vmAQwS9Vv5LGt9Yjje7dvFZl5wh3WMB+kxKRTByoozVnOIj8AC3MGHd
MGjX7BQzjpq51lhWxzu2Gc/Ra2JDYrbbxgvqqW5pQOkfzvwI6ad3nX/8HFuovCdkfPzXGxElw3ps
3N/oLq3zdrc08P4KmO6BUY3gbL6Odyw/GyqY4+O0YvngDDANuPiTXuZoHe8ab3bsPtNwEg2JD5T0
KTEaVISOJ68qCx+fyvvm+z1dY/xtANwlV0v21wk5FwG4G/P8UZ7oo7SX/Lk+QB1LMPfTb0h/dlg/
hBxvK8xzn+NtRLIUhN2evp0zHgC4aIlkyLnLFXCcRyyr+VwZbxnAq+XEYLeITWA9zwHLE+jmIWYI
vJrPsAgSN6+aGAyIerjCyEu363oRYB7p0fI/kZvv0D7vlpYG3q+BzpDOv41l0YjX8eZzq8NlkCJu
c+36SJkzM/86AeA2Ps97FiQfWXtL5lKiDJmZ152qiZsEz5aRgiGRWVpre6VXrAaEy7cEAHh2vBaz
+7N5tYh4QSsBuDRBLQCufSMzTxkGGFPyxSVNXKY1F/Yy/CWDI3K8o3W8dfB9Hn9YoVtbLQ8BxQ9g
zh+eHT/Nak6FUFCB6MJ3JdODCup4Z4B5tI43DT9zTdHfDmHTBFLGC/ZCcBif7WoRqRsIfXbblpSM
q5QxMVFODZH53YO7he7asQ73Vuvtbu94A+93g/bb63gbm9wkft6Cud9svqOiM1Ih+bUBVqxSWku2
piqrWq3eAnB0EUabg5WToY1+HWTkHp8iRQvq9Jk63tvzfBxUjnik/rfHq2CFnIuuRCt/99Mr9rEG
ccyRRWg9HUuL+QBLm9w421CpOt7efpbL8bY80nFgHjfcWCtX58+DSwbnoBr9vtC8ttaxQaaGzU3z
Yep4H8E0HFBfXsdb3XDdLPgA2Jb/CA9qY5P3DCH9Y/9fiUPiTKZmQX2I0YzyyGAKZLe0tDTwfja8
Pm19EulOjJwswHQ9Cz5SdbyZ3Cikws4zb+mVE+O/nw8UEPCux+t4k6OQ8Jxrk2mGtMjX3aCS8E7B
w8q874RmaNebXgTAs3W8CUA7Vcd7ywEyGoRPgm+f/0JSWWMkcbLXzOsv52eTGcX1/dUKYGINAgBn
0oRQPSFDRFmB1DgyP8fDvRGAl5gjZtk6606znNg5yRvbDAG3txOur+OtEqiRHCEgJwN0i4GySQRH
LuDBP6YV6iURYdbs1v+GQJkAy5gyh9ajp+EUKS7bmSlv96erOshg7nRyqox348yW3wm8M5s21L/j
dF2+rnYsH1x/nqZOVJc987w92XJivFeaZ1HPgJOoAloBoGPKN29AiM59G28/gXjNh9ZcWzP1cRaV
+wqgrsrSeBXXp8jViFkoARu9DM/fm3DY/7Rp/MlmbxPrQ63BDbpE1CF0nV2qp9rdq1TjxCEKLnLg
nA8ue7dBWCLsxA+l7RQ49xj+4KFImkwNie+IRXW88/qTs8P6Sd7OdZ/Gs4OX2jmrIiHhav73yeE9
NVgGAG9paWng/S0A28SfijkxQWuOJKDdXSPmibMK+sqyZ3NAmAnOlD3QFB4pBylaG/mQynj/akLL
J8IxKmt2JxqvAvKBxjJvNw04T7qzg0Su8n6vavvIcM4AM+8zwsy/gQC69wD9M51BzYsWo2DOJdcg
1fU29mhgdk76Yx2toS1/y0AONmNgCkRVrGI1t8kKNqeetmx5YTLZqW0F5+eWlPsqr809X8YZ5nvU
HnvetRbPAgTFzIoIgIO3Z0qKcXS/3k/rkp5S3y7VlWE0NvzLjkMRuOXclRG6t/O1G3h/J+g2LyBZ
t9U6XRvrgA7+jX/e5WXPgkA4U07MBvhBj3sCQCOYBF8NZCDls1L5qJaSjXxSAfJfrYr53Eaq5VYB
cR0gDdxjHnr2w4freE+A5Oq2vZBzkdkaRFmek5f7mEfOV1rYPUcB3dz+cFx/Ui1xiGsOyriCIsFc
Y4gBtglWc6bPgVzhAKs5F7IMB3B74Nw+X8U63rC6XFDHOwHkIiXJaMc5FeVuGCus+/EB9Cw0C29q
GGUPIwpM1EDc3u+Wlgbe70XYEM9RH+T4WTNBSh09sjAIGmJ1vC07qFaswx9bEADRBasL6mpXerVX
eAIxUcc7alxYeqAjj3iThOmV6L4ufV0E4LUdnhmqFR7tqhJ8VLkuF9lgkwt/655FbZ+y7GvIVKOA
zHAuzxaBaolkNffAd234/+f1RFj5DKt5jFQhGQZlnY35pBQvGCdcxxsxEPt5mptebqLGNWhkOeMG
960VUEjNUBCmDS/tI1k1BWLqoYP+4SmMR/1zHW2tPkcynnRSPtnEpzoe9B4P5X/rf3LaG/l76Ue0
h7yB94vg+AkEOAAXqqIEYzuyjj4p9xuG39EA0JE63gSgc8ueAYTpIQZeKaU8yKLOKPJpEFEMXOy/
8SFkXI31zSp5zCuaO12rkniNABsViqAGwDPe/ARGiHrMnwjAZ9tVSQvd2tAS67C2TcHdFoDAc6b3
D63iATYtXecveZO3r/qs5hFytSewmqu4TFh0kTmj15DG6e/s8eLnnfsrOgS4s6DbAYuZOuC5jUuN
p7chnlIVxi6N5rq3TwMv8TYAPqu5Gf2oAtpiRaOlpaWB97VAm93ZHH0bwmZPaNY6ATpXAxdqO4jt
0hH9hy17lsO4J0IilAHhWZBGvJBYZ3c+vD2a1z0dSpocPz4aNBlQnsjXjnMoR9pKevPhGaQ8wF5D
YJY1NGXbTuVzM/M9+AX+GDXM/RTCctbJ1TSvNwRCsUgVBf9MwXYuqza/H1bPmZkVmcZvE6zmJ88z
lLJd3n5kspp7+8CFdbzJ7SxWy3vfidJsH+/xOPSXysmfyJ6GHwmID+AfGVew3wKJb1a18NiRwzX7
Ub6Xh2Rtr1sf9OVP9Ha3NPD+LomcBIearPS5a50HII0CSvPhcmZBo/gpEgoeAKk2qMhtU16WsLfZ
H1i2DFm1ohtrG76uV3FmJ9ImqgA4R3RO5IvO5oLQi98C4NuCOfCMtiXiLg+Few4kZSd0xhRn/gRm
1zz1XwLigZz2YBSSVeIQTuLpmjSaOlZzwuF9/jssYpVzQ2Y5MbW0FZLbAWycDem9C+t4G8DuBy67
9+TQljpO+2fPQjQQgBTK0GFmxkeKEvjv2Z7tlpYG3q8EyxGkqTrEOcopqNupza6GzQDqjOJu1PHW
ATR3NFhh7kloLo6LB6CDRdRtFnWifJkJoE/Ghnjw3VydXL5Gb7QM2+dayAdshuDwJF6fKD2WKVSa
TAQ/T70I83li7BYRqX1euxLg7+fpASw7ucBqpILBAkntPxDI3MhVYQNjwsgAHo7Y4Ds3PzTjyNWs
5h7BOL1JqZWmDKb7LeTw1nWMA0CbhHol2BgRanEilZcPkUYgX1ypVqC0EUlMAWWQiOhjZ+MIvMRs
/eMsmR9VJ/Bluvx0V8bSy+22RvK29o038P4q8bZabd2z+debeRTAAFGcFRZpoH9W6JkjK1DHu5BG
XWQYniwnxmSjs0iKYe5lf6sEMlQIO1IUaJefpILTp7ZvAVbeGUVAnqc8cGeJtWYBeKUBqRKowymp
cPR0GruKuBZ0bADln4qxLjEfETD8nfYgA9Tv+xmILJgG6XlWc8Lh7X8joTOwAPcxRtws+CV7rENh
7er5yrx3sPKB+iPcnnONEcSzMyUeyVthGTtMbKxHE0AEz0fjk9SpFK25qvuh3d8tLQ2834ex4Z5/
xBHk56wwRk0qfFw+zGC8XghAM6dZNMw9kg9eUfZMBOpBpbcCQE+Abe+QXudVhHfil5qyKgB4FvBW
NT7h8FaBdB6ALqytemMdb90YBX4wA4N7rtd9bCbmVWQqP8IzDiypz47l8+ASVvPIQhJqfHu85DaJ
GcNq7tQDt/acWIo3oaJgfhpgYp9D3TZFn77wdaLpwuHbnu/BVQGPpd+Q3EhM/RJlud0h3rsZhSEr
Y8FNP/ndY0EXDl7rWkf6MP/d0sD7gWDc3yipTXAKmBJWgHwEN4nak3/bPS9gJKDLkFmPS3rxZj3Q
iMU4MM+dUYzrlRnvCyXAaREw4PK+K/TATPh6nngNU8Cd9Zi/p463xc4dqlQgpYCoaSFgS27v+/HJ
Bi7cfCzfSDNhB3knuLEH9Q3vZjXPAG5QCypGruZdSwfBMx7aiTre5rtiU3LFHfKzYAnGwMqPwOgT
uDQBN0gDBjVywW95fDQZyg/zbAUViYXYxGhpaWngfRm+jl8IDYKoFLmyMudaCcH1F95mDPqddA80
V8ebBtDKL3VlzySllfnMxvEKXDpDvdBjBBnTKSV8Oo0sWZargEEVsT/wCmOiLWxUxd3Am60H7lkA
jhUl+LJhzJgfWXldQSQjow0XpzBuGL9tYskzaUzZtBQmvzu/+LAPb6c+RDGng+UVVw4B0+ft4OU8
gZq97UID/JEd+eBJ9c07U8lVpt7CKV6BoHlshAMA5ygVk6fBIDX3ADsS7xLK645+elwffg7PcBBb
zKneVzh0h9OO8nvO0Z7zdv/jufa81z+/t5e7gfdLwDhfc3p/APOuYK5Gt3Y9xJxynDA+Q36GJICW
GXQJvG6A1wDl58aGd894mWsNOS7gN1nUuffP5IKGcjoRVQAnyc2qlPQJ9jQOgHN4ABVx6NkE8iAi
zRJszYJ0pi9evjINwJWoFbj7oLdmIHi5jHt2QFtb/xCNA966BvG9LHI1iuQNcUMS0qzm/txGZmfi
HN4ULsrU0ebOrjhigZGTHILeZtWH1AsaGo6x57pFy+FrPOBBKl99TSJ486kd1d4j9lxyJFtaWhp4
34auxQ1M28Qydb590GLV497oetyMdxnbxju8reOeCmmLAmheCSnA64E+QT3KbBZ1KJpcoOSYqhBf
43Cf8a7MqS+LAXgVkJ25LVWHuxC4LyBIy4Dr2VxxgOOjcNeMYZTwADgXwY3J9+NXo53SrO1BOrla
ZCJzEOMmVnNAx45AwOGNIMQxGlTLkCXArKq/eB7VvyMSZxw/vydUjcTfrqpLOlt1stPWkEjqHVAS
5k1/10qScjO3mz1VV/Hxr7xZECK/e3z8l/YvT3i7o9cwHvKWBt5PxOUmiFUxOcgAKgSeFeqoA6CZ
07aU/ATO41DKpJ0C0JFwdpdFPVDHWxkr5l1r63gnC/Lg6gUZA+DbUh66TA7+bP52ALhvQtjtBRYc
XJSWoZMSHkEeSYbG5IMTgFk0wR2Malw0C04AksH32h7Hlh87gfWkdzlji4iSrMFZLCDasQF3JBzd
vsZ/1saTqWHzgRnLCWNuGvzWjGQbPjt9FjeHqM6NePPDRYoTBJoOIqTkm6XOWGd58DxIHqMtLS0N
vC9C2qFbwHOd5OpU2W1h/iEAbx+ow+vwFZeLAfQWGAdbGQbp1d8uLUNGe7dUBRCli2yOeA3ll8Zv
zedhh2vezQD3RCNX1OXOAGwuTUIq8UOS9R0Bt5Kj/efLw95ULdBNM5i7BI4QQ8f98cKqVfcx7nWs
5pHeeQYnG/gheABygyQCbraON5nnK4K3kj33CDUzGeLE09kkbzj6kfgpJT2CeLiWy+xFFHpKQ6KC
WnoFPxpsJzr242qefacRH5urc7srrmlp4P3cxU+D2Xlac5WUbdtC9bjFUC/recJ9PtCP9HMz3p2F
cYH48goADf05eg578ggkCeGCmMwfUd4SwE9z6kDnVL3JCHPutZLsblyaY1khbx64R8pkJYcyA769
HOFo3nfeAAARCB4HE8SCpEt+UUDX9oyL/yYMjUx1B8aAtyN6I+YOzy9xJK0rYDVnydUKcQHjsQ5t
mp4127EvgaTtRioPG5M7SAUwJOLnYIwbtgDRXAh/67oN5J5fjU8z5cNR3e+WlpYG3twuBFFRD1Rv
VsjQQF1rny+c/5XLz4HybjAOvAoPdBqv1wJoq+xZAAlbOrKlhMaxNS5bAXWKE39RDIAns7nNP4Jv
a6KQtzjX5gp5xz5TALivIF2baXveMKBtV7IhxPbGRvPE/XILqpea9F57JGlVkQxHQja2jFg+9/UU
b7DRywcslDCMw4gTWZ3ZxXN1vMlJVgy6gzsubOBeWMZb/T5nDD3DVxKtQwZzniFNspb0ecdpAwrP
+qXaxl6qHL6fXvQx2/SY6i6bo93e7gbe78fagQ0+xmm+aCNKEIAg05Z1g+OBdgF0iAUOOQAdASpk
2HzWIsxxM+VM26GSSp9Kc2SClOjVHACfROVBWM23OZl+bs9TFgxvhTnciwF4BfGaV0oLmCBUgkBm
FChX8BevR8ojyazmcN5XYnsHETnhgW8xNL3UWBKoi54xJKWura3jrQNurtO2WX//fxwKRRJ0yy2k
c7G5h0zt2BZExVmNILzawVQmaGsLE6kDCXsztnQ8ulwzvqWlpYF3NeCeDefFzIFEPAnkMzVmN4me
nAHZwfvoA0Itk3MG4a7awAJoylMN9xDO5SJbz81lyVn3xet4o37i1ligguu2mvn8yj3FIndCbKiz
wD0C3hOlyljAxpbBY++1SNiYtXJ0OIMcn4qQZyC+rt1SYAmjSB5wJzzTCvCT2mDAu76C1tTxDufB
k6A7tteSSd45PE6/sJsHPmXBZLzRcAwT51lmfu8PEwhhW0pqDHqInkmoN0ffsPRsW6YcO8zjrnx6
twd/S/iqD6806+3eCq9raeD9aBBup3jnac2hNQqbsI3b9HzA6CNT58Ao8UDPFfnwiKQBvuUsaXzu
hlgIPmzqY+O+iJLMKQ7vzPNKEK+VES3ON06VKFoA3BFfTFOgchbkeb9ZXmIWvID10H7W8DbSU0zP
+M4D7r/zqvBxCcD74+cfVgCzVeJstIgahixWcw+fJut4Uys0mnsNEnSXYiRsXlyAPxiCsYU2FgQq
Vrj6DGx9ipjB83v2+fmgmXgDY4L8V5Yrq0TPLEyVEmtpafkVwJvbzE4bCgjU7oH1WCHvCmh4uk0v
YwULEoY90BkALZKkTALo2WHVy4Qxpc1sZVTF2Iayeb6vgoEsVvS9ZqpOZv1Nhprsnp7I156Atkkd
OJkjXxwJoIa5X1yuDEEiMb+0mOQthrjO/gT96ijZBd2AHxGDgEECAuCUnrfmM0EdgmxN9QjeDqWH
FNXxpvphViWzXeZIUpKHz77MZ07VlNsKI4KM9esQ0/3R6hJkbjjphwEDRdbYwH7QG6t/3vrgTPvD
mGQf+d1INwzqkadr2tvd8r3A295mU/mnVbtGoDSXhdf11Gm411k57PJ9JMuoBqCRGikK/HOoP+/N
gYqg9XeTSOAQDtfjxk72rtXMaxp8J/O+i9LF4zphBeCdIF7jGg+j4xAAj4Ss0+vvAB6fWK5M3ytx
UvBP6nzAxWqX++IDVqP2Dg18H4nSGEOF9slpoAxnvTP1w0Ox62vqeIO+Zm+0mTqGlHbyJGoO+RmS
204SUqvnHrsPZjOoUsD2HB+BqIHCIYQLfWe3y6BWQEtLSwPvcpDLlQmicLKuWwR6pW63YtQSKBA+
UxdUxZ2TBokah/csTGQBtNFpn1pd0PmyJb8iZci4+sae7aTczHQ5AK8O06xiZc/mYCdfaaYRrAqq
PwO+8JrYtlq29UgVCSu3FeDaYD6aETatblMugMbHnoTJcm2fQJ7zS0qBrTGuAfBAV2Gfr6jjHaWa
ICtm7YEVScSGlLsTU/vkZhinYs+P79rH0qkglDCkkni89RsokTd/LBR8JwgGFwQ76hiQgu+Ueu1s
He/PfO5gjvigr/jo1H+e6Upvd0sD7y8RBLy7H5s5l+Itt49YKHbcihzdkplDEPId0K/0z91aD7Sl
UIQep4VlCqRzUug+yBM1y+6L0mT02OmI+eWWyo3PAfDJUHbMr6/ZrsgKGigTAZLPzjKmuwasBABn
GLmReJbp2TXre2v9A7XvWYYyGIvd+tLxyJccgV1+Ip9D77HmKS+o423trTDKSgW0GJfZjIDe2AIG
zRrQPX9qIaHh1Z5noM5KIu2lzAmNMM/OinXT0tLyi4D3zpJEKuWgMyWx0XwZyOjyRTVXlY1YQqbY
dCuBH5Z/OPHp96oA0IG64SSAtowToJXjHGCO56n7eZJQkFgukO0eyeH2xQmG4cWYY7TP+Iigzvtg
WbdI+DmQ9oCnIxVoVnMSvBzWVJgBXGwj8j7k3iYYPeSw9VnqY5uhXJ4eB25yawqp5GqKd5vOzd4C
hsOL63gXGDRhheEZ+2CccVww+Kin45amtrCnnqGX4Xjk4/CmhEVlk6PAvHrhzpRbB1bdAZs4yy+I
Pi9pfhQ9+MMbfijnHW/4H8wR8GCPRde2NPB+mPJOKCHYgmHNNYW1EQCmWQDtb30JF1lmXGjjuQeg
OUwzC6CZKWEQG88PJsPqu/B0xNwsecjJXwHAuZBzpPcLArcHXNq1v2KjyazSxGtHkMcD6gyrORwy
Bvp+gGgjx2pu96c00efQ13xJMHdbiswPEHnhoUSR59TxvsbQSYYUJ6KHEWzDHRLwAFfeUeMVPbIf
xE5X+Av+ITowmLMkbhZ/57nc0tLy1cDb1H/Bw3Vr564B0JIBWwbQ/PMWnuUuEIZsAzgg2gyA1gcP
VHlzV1kM57eXFi5zlVLdOxnMXUC8/3cc9KlKbqVznofXcDF3Fbs7CaInWNwjg7wnrSM95mzudAGr
+bn8l6DopvLCfdBdzWp+NhrKJdEwUVc8SuSm38OAaBvnul7xjYkS8UD0kVSvto738Qn2vMJE6SbM
4GK1ndg2u386FQzHbvJQ/ibx4+Dwv60FDe1xkD8ZVGvNvJmEKONWUtrrIlz984gx08fIzcP5t3Eb
l9/94e2OP6al5ZcA72DJ3+MGHnXWcnvomrw092LmFLTqlEsn5swAiZdYADrATV9KEIf6SekSs+s5
tAG+NwO45SfZ1QCcqp97SUp2kvk8CJxV8DEz8FGWH/XhKBvPi6qTle7C0Tzzv/+1wsOrWc11o8T5
N1AO6VjOPUGuFno5x1PNfmSh1BciBFRbPMVbDPgmydTUa8UNAan+mczuRevrMoIxVPc68jD73yCM
Ls/AytiUInjL1NiWlpYvAd62Hy8eI1UR5OcBzvTLSa5dC0Ardcgw0S8/d9rA+VuA5Ej4h+vVJmdF
6vvRtcHdmmPu32JxDmD8DtuMPlBbaqwQgCPegRhhejBvushAEG2njDU9wSQfKyfmE6vNguMTyRox
5/+8w+YTs+U8zLOs5nA93PNl3Y5GAyeMhiZX88qI5YpWiQ8Xcfssy/fZEHD0tLKmFbr0GCyLQhZ0
zxoXAn7x0OeEXZ40vYGCw82qrohzcN8UAJXKxUk6TqGzBkXtkDK2a5/HPJjP7xauSORfd852y68A
3uHFzIJMt7A2i9gR4B5jPdDb+hRvM0oLmwo14dvn3brhKwE0/eJQFWe/j1oIvhySuR8OK6k84qlB
ADEXn9ZXNmPWAQqEWmNbQ7xW4vHgO2ezphOgfYa3jgJ+KGjDA5wwwbMJ3o0avEx4+6cRDgHj2g4q
hlnN/evgGJei5Gr6NRNLYyKMIr2LqWA9GoYey+3NApAZsvVP4zfSSxRrNkYwm1ME5GZ3ofMct25m
1cPQv1+nXLdLvKXlFwDv+XqDZj0qChQjBKAtUD7jgS4bP2x87rSo484VeWThN48loRosQoR5onFB
0Dihj4f7tBkW9YKlcC9y5lShVZ3gPNZ+uS9OmUNBz7HsXkTWUQI8x3OKcx5x1Tt8qA1EA3z1feLh
6OxC3rOar1OYs+RqtAkog67L6ng7DOETdbzdi2YCD6IbBLaSlKtoul7ulABxbGtpT1CcKJgeyhNc
R9BsIqQ31H7sZ8uYeaPPOt4j8LDy3n90RPBce4+NervbO97A++tFC/NC8MQJ+WfLyxblALR0uVWn
/FLTiauzVAJoO/QAGoA28gyhxsRvVA671/sse7WVbMfntBcC8CsBfy02CUHaJRy1E0ng57JPXCQA
CvYEqZwYjmX/EPOAp8OpuYK8e+U+kAutvaNHniZfU7sGJFbzWnI1H44lEkqCdbxJVM26e5FYudm0
YbV1ZHcMfiWjoI3lB0SMCTyi04SNLZgcBG/vufNou+qYb2lp4P19AFv6b93Ggal+XQmg/ZDx6LHn
eWZhZqTF4AoY+ugUECFc0ObAwHg31aCASo8uHHxd6IG5XCXYcsreZGnvStWFc5hXdDaYGxu8F9uR
+y87yNhm4yvjrOYK8IW2U0EhOOQ83V5JNKktjtVcBuhIeMPrydWM76vmQPvrNl7HG3Y/onN0Jqzc
I1MTK7uB2t/L9+AA1xq18l0qe24UZXsnFP3u6CknSo8ZeeBSJNpfMtiywtz1udx0Cn6WhfgC0M0w
lQfZy30n+qDbMq9p73XLrwPe2GKlF6oZ1AoAjwugiWaLUrxDxwJFB0aHrBbUGwensAmntgqgQQJo
loG3hEV9tkbr5Bl/BwCnnz1RXqs6dNL3qPjxopWs53V1m8mcc4JXEAtiqUF7lj7Yvj9/D3qD//zX
AcRmEctEfrs2nieSuUB0i0+uZuzz0TpiLsDFlp993EmnhRjb/cHGlgzLbeeYIFFD6FOUgvjCBxUV
1EqeOqy+UnCoLjj713zPJXC7paWB91cJa33ezijLB2BRAM1zslUCaLb1SJ1yCtQy/aKBo0MsR9UN
t/32iHdvi5QErgR0GppD6GErM6fXLuQQSE54mu8lXguEUdqFvJcA8Jn7zmuHDzm3gOf5b7yniXHm
YrKf532F9KJ/nkfQQtfxMcYwWM8rV7LvWcZm1LQurePN75PQd0wHR2Mjwp/SW24skNoH3TX7LPhd
BVVzKnPuOfu2ETMAckrD2UNie/XEeTydNl51gBUfhqy3nnAqc3W6nY58eK+plPNEbnd7yBt4/wL8
bfmR4RJjnCEbWXPZaC8cdl0AoOUDngXQc0HqsVRtIc8yU8jbAdJqpF8SQIcpXlIaFpf3d1UeXh4A
360CTJQeQ2TtZpXobHZ/NGokh6ZzjNYrPNtWGS0+z/r4Nxccm/uZxWounQkxEjk2J9xux2YoZ1jN
tV/zzs9IHW8DKc+keK9TQvKXk8AEzJZCJXlnaxguGi/MbXHxjwO7kImlu1BrJsGsUx2+tkDmQG9L
SwPvrwPa+c04WpezAkCjDEBzTrWiEP1QUrly2CHYPovzAwi61DudOjVhmsuvIMxdpm09rvpIVT0k
bB5EQPFg2Q7vDIlbkrQshML1Uj1VIec6zoZ7kbZfMqCbBstEzoDk7fbRCHOWFZUEO4J0ci+jSuU5
6N0EpXSuOMh7srgU/qSKkp8ZZRNd0E3vM4V1zzMGhvSQV+UPGnwzqH33FLcp5r7LquN3zN48Pjp2
/PfEG9P53SP3Ts1k3tLAe5nCn43xgXHCkFbPUgAdTDhnL2ZSvI3nJ2Aph/OvzLlOAOgdAADmB2Yz
DBrFEPx1AHzV84LuvWTZ8QKDAGlKycaTEygOxCaWZi2XoI/GLg7I7AGHtWgSpnnRT14bvg1g8lzC
zKei2ru2jjfIM8YA90cCLhdwx1znpzmRTCcp3e8CoceY3vOKt+Joia4IE13UP8L2gZhXYOb2xdOl
4pzsit4tLb8deBsbK7hkq3i5atCX8G0+AkD7NOqZV0g9TsnZrmNmT4w53AlH/g0OXues8ddGpV0F
wIMAMqzxF7Q9C8CTH6rM4S3OR9sTJj9bR2Qg96RMHW8KsAMu3PD3EptxXOvX+X0M4Cp4uyWHPcM4
/jPmnE3Fj+streMdqUOt7INwwXN0fWXc4AwwO3seryO7LAq4T/JShCqtkR4IaNOMLlHq3QRVV6lS
0bYtZCd9vLo9LSP499gl9o3/eKP/80i3t7ulgfeiZQ8RQCL8DKQP4lhL84h2ywPoihRvlKRq0+OI
qbk0lypg67BwoIz+FvNhkFi2AtdbvEkm7S0TGci9QS7FG1s4yiXJ7qYTDGHiOyaZmeHvZx4Qz9bx
1gJJNHsXItULTGBt7z0eQEegXrUGvn9qbleepzxoJ+t4E+7yE5N/pV6Asu2GPOcRXPNb3vNaEOFU
wpe2RWMFofx1ngg0+rvPai4T0c3Pk/cC8M7xbmn5FcDbM22yCiMCRbu2E9Ote58aGx3J87UehSlc
fkuKd15FUVuaq9g+mSowlVRuv4dHAoctQex2Ozye6eoEEM0hXxLgbimXu8l7lFysYQZobz0H2NNd
qoaM14z0fsdrbMt/QPC5kfnN9lHzdm8K8dsZuMPMZGHGgwsnD9TxDtSMds9TE7QZHmsmD5zEtaru
gMC6CIYWo5CgxDz1LiRQW2Mv5vWDP5nyqrMA+xSCV4DnFbndUEH3dDeH8u9E474RYEx1POK9bhbz
li8D3puYujW9AbLtqLHR1C7vALc5nLhof6WAfMxjBvIK+WpoitamEBJZWqDnjmfrhjO8OtN4vVAj
QlUjybrb04h9pWaI+NDlY96nYLUJnmeKgE8YJ5iQcyxTUA3QTCFKAnQDxnvpBlGL6A3hPPmKoTrU
IKfHhwwbj3q4nTkmQwqQIF2DZZN5ysFsI37qfb5tKSuoOIIPUes2vaycU95t8k0YQ60bEn/t4Xvp
V7njJG5paeD99C0gREjmPGDGunkNJ1vN+UOFgcNwsKPu+zEfAtUfInA9EzKu2QEmegligiD7hPLy
LBOUdXcQr92D2zcqLLdqoBKbScLhnQNdSOwhWa83QBkLPkO5NdDNpgxDyyEl3gHIgW8wh0CKIS1S
xxvueOfqeOdZFyjjQXbDmAVjEVpzZNbkfdAoHZGmEaypHpd8CF+mJTbEHMqcrhlV3AqBlz1tVF5W
c1XU293S8jXAe8VOwZKu1XcFdfeRrmMLQFc52PlDbIFLPxRB4LigkzVPMuVHywlNcbeq9RLm84mw
ylXeIswCXmkYE/njmPg2uZDzWH61BoxFMI+a0EscQPcZKBMeyuC8Oea6z02uPSAGpbpP1PGmWvLq
eCN2WrpbdQxw78ETEy1Vs4FdWpCBnpTJfSP4Qlrk3Hy6nKMghCLPCH2iFL0+LT7hL2BtmNnS8quB
N7c5odAbiioT6hH4pdq0ADQXCJ6pyhnzhNN1yIzb2XpiAoA+xNgxeJzG69MzCvF76x5+pYpXdHth
QuIEOMXUA7NIls+9thd3ht2dGxtGPYfbQVwyG/ch8n4+tkSuJu2KSAQ3Y/PZ0sW7pPxwgSEtvWLc
Ot4yq3lm/lBfLZfiTZ9qiBNsVCxZvd9BYBziNC/Yrs1a6wn9SKuL7vJJzE7sRArCo8/SRdadkjre
SQQ/sg8bRf3XntTe7pbfB7yJnSXiVI3ogBFvunGfVa86sjVnAHQMHtI06lu+vMumI2TwgzSTa3c3
edzJmICyt/zStT4JMOLFhrO4Pfbt3G+fBcExEB8OkifZyG0ATobIQ84fh3Kt7TVPzEAGQCj9sEG2
lQ++LElh4+p4IzszzIvgbcrgAHcUfpuAe2aMwT2rKgIP0x320POCowgZsxuT+01s6ViQeSUe4aj4
MPfozg/sZUtLA+9bNwnHA8UiUzaSG1Nd9ftrMFMy98VHbT8wsjowU8gbVQNTjLI5kvj1IeOTkQDi
dcivg+cs62mloZbdNVl6DLk+x9qaBeBBIwCbP26QkXmgjmH2tkEr/5t2isBJuIZXfkBRuEXQr25E
NhnbZ273p7c7ERexcXW8A6zmAVrzyPS2zQ8hdO4D7qwBDRtllIcVJkC5qlG/jRJmkCljjkmCB+XV
iktzwbc3gK4IfgdJSWq1lfXg6lDz2POG+wvTHuvFbm93yy8B3hWK5vwODiO3j/+brwRl7qMAdOis
IuHnxXXIwmRnsB/H8q5jZowSKsuaQNwHA/BiIvdrApdnOkDWBzARFUkUiBWDGin3p7Ofh8nISYDK
NqTVy/YJ40CCbw9Iw33kT+1uYM2clut4B1jNAwvALPGFCdgxu2G68dp1tOZi6apJ0P1o1U07RZOV
auYrfkGo1F3wfOp9ClIvLpaGmS0tvxJ4x0ASvyXPAGgtt0+yqGcPzqjPO1PIewJAWxdPeFwzVUPm
yp7NHH2zdcO92U2mUTCn51gFdOfCNleBxavTxyv0pwlHd6Iv/v4ScngL6JEBizLmrfF6MyBXzetW
+2qVMmPAJ9igAWLMAkGtVB3vCKs5v7ZM4BxiGudCjn24lVlTc/5OFMfjzNXHLt74Lqq0hclnciSD
sy+QIWB7tlHlCnv9KG6oc7tbGnhnlzsy/JqsN0I/TEvBhHP8InrftPJdDKCZGG5Wp5o0m8w8bn3I
eCKpPHNUIwjAC2c2ahuc9rDNPy8BTle/Olm7G9qlyOw+0m1zIeczEMNqE2wpMAPcM0a7HZDXQmtB
ngRkSbTcZGLqeM+wmsvecaqcmItZEsHOTHh5EHBf73MuKHeQ3BqpJ1dHX8w+LFKqjVpjSM6VB0Ho
BQbsZjdvafl64B3Djqyz947ALZAvcwxDy4Rwpq+LAujqkPFE07F2YBzKIGbKLHncJlcwo5POC8t3
LTxB1xmt7gLgbBUFI1fbRdGY3FSCO8UptzRLvBap6x4gCDPCru375n5nEIzmLceBxf143Z9rMTEA
u9rZHmFFMj6dJqOK1MleRA5n0Jqr+1CycSbbA4f/W6o/LNwC6z6Ofwl26wfnLakE3Ho1sSeiGerY
PS/8xLFWMurCSF47Ek+ZdUS3J7ulgffkgRTZQqPbLcjMIbBJydm3da0MiA5YTRdJIwn7PZmQcZ8v
NkpxHxlnY0YF0wJzvD5Jd0bulPt9ADzRNtLLHpOwPOG1nHjHfMg5x2Ig5x7r2DQKqo9ecz2UnZ8r
KXI17Dk5IN4X8fhG63hPhmC74eQgxi0RweSRdq0EvQpx21U6zrQ1ZRlW53OYEXjDOZiaU5W4N1jG
xrL4fOShdDRDrb3iLQ28f51M0JpbYDpbRqwSbHuvh4JhqXgFhEZ2OmQ8/4IF9VCC5HGxamkJBtu3
VDG5aC/A0n0mEY9CxXCC/2WiLNoEwicfBQsPbrFyYnP53S4QdkA3Dgu1ilzNAyUSqzk+9iAYVgof
YHP53OZHz2J2s4nc5oT5JXswnke2U8SemwytjhEwLrKoz5yn8GfWil54wwPNkIPKjjzlwB3LWrWy
2Mb87KE7wL7hP57uZjNvaeC98AgAudmjLNl28p41nGw1fQRzQOHCkPGJl2LzseHoEXA+Q8I4vhwf
v8VAP5nu+Dg7wwoAfp1NwViTxNaRKCeWB85wamjnwLv67Ci5GnzujiireZgTRVgglPcalbsVYiev
sQBy+dz7qiIoioKL7/kJ0E3PhpIig4vAOJv+tYXHWubv+XLlFyUXueBaA9wR0N1wtqWB95tQ9UT5
4tII8BkgvDr0u6AodQW8zdQBRcnArCt7Bka9mchpD+e3RUjV3q5UZMtObddzO1xGmq5SkCO8LbG9
N7NgHVK1SDkxj0wt8nfvt79EZFBeB3YbMzxpGd5Lto436fCOrhjqHmz5MB0jKGIPkjOQKsk+VrZw
Y8B45aaC4mHinpVM4s4mY4PenlzdaHWgOYRPTbXvnvXzvRzEY47XwOgmUiD8b353qtBLwIPd3u6W
XwG8Eb0YF3ZqBYCeDf2ejHpHQZEUPngMxitMhIwvICELG2hKnB8Lj/QbAPgy/0KmrlHmVtfawbU2
R6WDyXGZDe1EbHIHnsKWE4uBtWx4BHxgLgJ4L6xe6joUP+1Cjnxs6lqgAbea012xv0LHpKefLsxM
Vvk1Z+iu/bxrTL3RHcqYh3nlCA9Ix/jn9bPlxyIREuUcPLEvCQG4jhQwvUcRkFq2POBfn/HW0sD7
1RAcE/euAtAVudOrD0DUgescpNZUpeKQ8QyISuats5GxpZmKs/lmN9YFwWMC/CY8XAXhe0GntNt+
jDDdKM9F980t5K39c9rogMkGaTDt/Zbx3JKx4j74hgHY7fnCHVXK9zXnaSaeiSdAR/ZJVE53Lnhm
5X4Wt2EZFgp2a0ifOUxLgfB5lI5WHJhP2Puq0sFHxQSCB42vP20lDzgWqCNMO+3tbmngndpIkoHL
eDiApoyviL9nUVdt9tkZr9p9dcioFO+No32afldsgTIqCaPUhazmXwfA6duSzOcTiCKuQCPeN1Az
cYuGnGs41f99ro43wLJ7INBftmYw9veIKa5ZyKknbIP5wM5j6QJydO64vu+BnfFBp2VoH8pGr89W
CKRB96Kt8aLY8/D2YKFlxJdHaj0Vf4PhfIb6ErjXJl11jndLA++3g3DyNzy+3ytKjpEGCOFvOAeD
pRDtdO3xBXXIWP60zBtPGRKuPPmidUHeAsDvIF6rcRdNvkswG7ciN5R3eGd6eMKrUh54DEyzv3t/
P9QYVsqfycj1s3pBEOzBQr0H8B6qq53D7snADDtWI7SOZos7BwF3SY4+WT7vQcfFcsCW3Tsn9y5m
P0LpCy1g54+e9TdOJqsreS/33zt/8rtDNcPb293SwHtye3hExQxo+ta2jid0jqrMAz5wQgFiDgbW
WhJVSusn1coiZL6FALnnRDrk1fy4AYA/RSd8FPN5gTHBnzMTC8kv5G1cWkNowdXx/klItgjYYIJ6
vwIYFPCtQvSTt1us472zQGwVDm8DBOtTgQ4UpsA6YxHdlNFY5KGuOESwkQb0BIHaFTj3Vg0Poh41
azfKzen4Ayo+EYyjek2Od0tLSwPvqV1xITMpVu7eme75wd+25/qKz4L5SxbUIStM8Q4+fIXCiLkl
8i0s59PbwQTxWrJ9/tcrzAUznObbFgg8juWLs71HPLdULkdmY+pzHe/NAPM2NmW+h58BJYSqs2Hd
J6PEVuodT1kDZpcvBeQx4WgNlFIJrLn6vebbdb3ZxsmQcdw3putzvLeXKgJ2X6vfpL3dLb8LeNMb
apDpgo0GwpXvkHtPNsjbD/xGsocPqkN2tJAXhIwjcWX+wCdOURROuxtzvJ+t1CH05ePIOREXUseC
5GwvnIGAAymbT0Z4/AMY5nOdZE3K1/65FvDrik8g/zDf2BGYwwP/25TDW/8wJPmYuSdFa3+H+oyS
KU+ltVD8ZMiuNLHDmFu9a7ZFhGdV/KmQ5yWofSpsMRFOdAhT+V4W+OtzvL/Z8t7S0sD7XmV7Imdy
NYBGArhVcMBxIebku1acxQ8JGUflRAsnlUe8IMmT/UE53s/fRO4hXouwPTOGg3BtZ+dBMac1YveS
zN9nIEt4ng9/KKnjveBEoVPABfBOfTXwO0wpiA6lZldtTlW05nOF3sLFIUuYxBMGZ1TN4sgJGj2F
C/KJ0uc/Et/pEtw+AfPfqwiszO9ub3dLA+/QpnzF82eIxvhC3j4uZTm0p+uQ1X6yCRc0lsyg4pDx
yGGNLcYzVHHmPqyIJh578CP35R8GwPO6vF1+zAPZdO+OHmkozzSMVacL01ueBLqZHThDI4d9HncU
fddMRBJwM83OAN1gZe5Z0JNYozwHKrYcF0r9fkx5SwPGs9nOPIeCDCuGe5lm29LS0sD7hm0psmXP
AKlZpBhk7g39jfdUXwpmmOTpDDfOBKm5pxiXfPfZOmQVh/NMGbHxpBWOxwLw9Ge8yg4YnAI5mwAD
5mNlxDjSr+hLOiAZUb8WzsBwYR1v/z1wcne7HmNnoGkSNKfEd/67BULKg+cIitZNZeMII+OFiPy2
LTfn756ZTudSqyAmG7PHPQxBS6xs7sVPl3wfo97r9na3/F7gXW2BhbdT5pFixlprkeawreOS8S6y
R7BK1RQQqrBi316HbEVDf8/XB+Z4DwGAY6m1omx2XLINrnhmFZkbNqYOb4ItgiE7U35kx4ut483g
5ak63oc8cYj9897KZ1fjTjhsbEhtRfk6eHsrSWp+HMGs+oA08Vki9Js++77NH+orG7XpBzDG/rmh
1hqr+e/Lzm4g3NLA+1Fb9FVKNyYe4HOM45rXuYrsrKAPa3B+Ia/5BAlcBK8vO80fpm9IykQZAJ9C
rzo/Qw352rrc2bVuLY3vARSAjn030IhFVdg/QPkGfieRWM2vqOMNxTiwj6KHvimGyyAvKPqdhalJ
WnPEUb7aDio99s/RiL5IuXtgv4reZ1S+B0ouul4D+MfrPGQTw89PTH73j/f6n/8ynuz2drc08E7t
cVjQJgvBfav7WsKv7T6ys4yOToxjPMD1ojpkRe+eSNetOYQ/AfiD1vJyiz5mFhXK10F2B0JB23Tu
5+wmlEDgnMd6c/ZbwphB1PGOdCRcxxtw02H+6aMdqY5aGwv8/QtlgBsF69L5ax3Kp6bVOnMXFlz5
hP0fOgMd1o8PDtPwjtEdwbdc833vAp0HT4ACfv9g8ZaWBt7vg+axzTMeJonini4flmoLwQVEYSj5
EiAAQ25cSsPdK8/Sh5YTk974eQC84NsUWlqQbjvB0uygfVBLKBe8K5OT66XFPp8slROT4DAg/MNg
NV9dx5saHfDM6n4uvX2TCSbTgHt+swyU8Z5fliTovnPvXFV5cNn5vTxH+sx9A23efVtEfyjHe9zQ
AcH6/4OsIf859PRG6S0NvBObZRnThndqwlTKgmfveqB9V8h4cVJ5ZDxR9iUKSqJNHNhZEripoZfO
1oemtmF7S4GT5wDw3K3JYlFGTTCcEar0zy1RYIms8+38jQLKoJ73eT00hO3W8cZ5WArPAx9gc/nc
Zr4tomt5Jhz8CKDm18F8tVFU7AbpnfBxhI9LHo6ap6JSxwS1J96Gp+/RTAM9HPtnfrqzcQTQpSdv
S0sD7weowK5KUs0htnwPfJCFIO5gh3HvQhf0gkp1vLkn+UEyhYQfWE5s5bpuQ8EluJ2fr3Yh7y3D
hi0C7Akc7+77sACppvSTrOYxh3cAcEOfsXTB98I88OQpouZfR+04kTxux82fMgDgiYv66l0NpgZw
rxngC3b0KatSBtp71n3Fu/6JrA843HJaVxsf2kPe0sB7Ia68IpUZVw1CES6t7jjPoLttfpzBhS8R
1Dlyn2FG4Queg1o7D6JGHdUf5asMBVcQpKG8RdNG5BfyVgAv48KMlBPTWc3Z9YrTYwUPqMdq/t81
YKwCmvFhI0uCERP2zFTCMbXpz897uBEhPCONQZiuRzZ5EN4CJB+mvHmbRpqZEfHv8DKMHZ6W9Fmf
DTtnwumGcptMnlbyOi0tDbyfue8vJ+jGbAMpRHvZCY3JOmR5O3ciZHyG1JwwtMRGAms/DItqHwS+
Bz3qTwLhct70GudSMHCX9mRWbIBw53mlw7uinBg3DvjII8cB6mp9yrOa++Abp/JjIdU8FMqNqRWx
HNS+FYE+Lgn72v2UMbcvG2ZsTh3v2XF7eKkQWhHwQuUYkG5co9xW5XRu73VLA++Lziu40OhlZ/oX
JpWv5pxfVm+8rNdF9dm8Wz45S8Z71nXM5l6gMJbi+CRxWfgGih4roezLBgQ+FZ1LquAc3prX22/b
HQYcidl0VnMq5XuG1dxq3K3j/UmwVjORMZUYXQvgqNMiwK5G54UHFuodzOXfmN6ivzlWDeNhjr1n
NEf0nEyW55NRMpu/PcI4vKFySwPvB23D2GJ5spVcWdNe7auA4o2GVziVyvlcKuIlImT0C8cMoVmb
KFfzy9hDLmc1Xzruq4nRSECb1tixzfGRc5GiHNu27lXyy4l9tJEgZ7NeqprVXPWlA+k9ih1obISH
25g71GwJpe+AB91V7bnU4Gc/Lco3nSds+vdYpN3a9C11iD0M7+fw9gm78z9dNzTtIW9p4L3+WLid
DfSBLOO2agAVcJ+VnBsOdqwZM5Q1mSOBE/1mbKdecpaMDDZ8CQCfKoV9Hal5YdtI7S8saLdKi7HP
xAG861HdhDddC/umWM0l8K7kiJ+IxOFHGuRSvOlPVzmvZ0Lbp1bCPK35xGHCm/hR8ryHgG5CtyjO
h5Pg/a2RcJceUynAHUDGn1XBAuFr7eVuaeD9ZUj78j3VeuAXhYznFCSsGWtc/+i5xi8KS3jhaSYd
wo8A4M9t7tZRsiO+Yfwd8VdjAmFQODwWGscRus9/cws/p3YMhbRcH1LcOgePrOU1i+lBgPuOfQO3
bg8vUzGrFDU8/UV58vIjaLZAtfbbTSpKxHv9z7Xt7W5p4D25x005kq8gO1seMv4FdciwzYXgL643
HiNJu+kwHu9cylle1edrO8kQ1VB4ePVyn6P4h4nSox4+mDiZ20tB3INQGUmLUg5b4puB/AoQxtgA
3KHDClx/3HlXFb6SJeDbzNLb6wwKd4bthIkacUmX2MFfSbUG499vtE2Ec703BxxrjZGgmin/teps
/wHQDaJbGnivQtd3dwdvfs0YekXqGLtYkbgF5xeRpEXfY4GXfzxkad2e433h2lsTfp4kR0s+HCUT
Fe6vCO1M285jbY+BV+DQsygE3lnxooNFu2XTJIdaJ8n3aTiktuNWJcPS02jOeP9w9utbtkEstE/D
n3O/cfiHA7ijQJ0E3Kt1jCzYbpDe0sD7yVi/2qv6wDpkDMULluRdPctYwdQbf/zhGjs3zTP56i5/
K2NvCpUUAXBq5iaJ1zRP7OmfmBwTsNc5vlhE99Uz8rO9qGwcPJIWEaGsGQrXTYjPchGBWpKFGUzN
8ILIkVhOfy3gfqtnljaulLz/sEG3sgbf+mUy635MXizpC8EU71tBd0tLA+8Ldp7weTtLdnargZsP
irZbwD3f8vJzjeMo5zpSlaO4RtPxIskYkL6qy/kc74VQfZnN5aIIiXlsHX+4Bb5RNCYSOxJiA7In
ZgMfsq7trSAOAih7isSVBhTNF4rWvK75DOBOh4AQfw1P+kiMxHZZOtjvg+zzvf/Njm0GLK84p6/S
H2ZAdwP2lgbei5RhTN6/flf/9XXILgPbUCp2Wjmay8YMHBoKZwWPjWYg1qzVdxygdVbyRQD8faVe
H6KQBjyaDgkbCjoNFaAToPuAiiVmchhWBmxcySQbfP9tSC0/Zg10wrgMr90qD3ehSWHVnoEnL+YH
hwlVdmskn4jXvGEeDHuA2+txtI3MNbNAu3O5Wxp4L1pcl+6Rywm6q8nO5lzzCIDMZXaFB5TxsLPa
Czr4ECUoY52uDhljDuzaHO/FHvDXAPDVGrkRB8KkRbuFvPW16JUcYzPd4Zf4Ngy4kHdnPZpVHjkP
SB/KiU1N0jQxWXwu4eP/qvdNrGMVWbiVo37l4t43qhsPXceZt3ueNyO8wqJSA8it0fDIy9nzezUM
rsQFDdpbGnhXnhe44ZkLIWAlgGfh+neHZkEwP1wc247Fr1eYj1UJhtkc81qoiLVjjYpmMFfDeKIY
cw2puZP7zBDKkcZQEWybtbZZMjPlqiPD+dHrLTx/dy1iNco3Ylc6XYFaI8u5rvI8iK9YI8f1smqN
hoP7FwbYrEHl15/wIzLiqOj9CDwOdw3LI0G5pQ/c1tcGyi0NvG/CSyu9p7eU8KpmxUZAsXyosWSR
ix2pXO1ynE+PTWomDP6y4XShOqyM8bBLB/5vK0GL2ZhbeiNNrIKpj+GX2kJqXe8BeB17+0bni2fK
iam53asgUirFO/7BUwYkcGvCbbswY2qeByFvBMGCXeV8EN0NZPiPhfgRd9tIvwl0V5zrq8F2h5S3
NPB+yiaNNc2u3aifW4cMF322Z+ahFeZqe3/HBXN/1B6wkWsG8SZMjvkTlYCrwfd677cOFGeJ1yIh
nCYeRGDF0qRqUi42v1ZZb3Wl5cJLhYfX4XCKN6bnGTWHV5wFeMYKfk73RxB2XTcia2kzZiI0fnGZ
t6cYBorB9md7DeRbGnivPOAuj7K6guws5oW5JIgaz5oRuDofAQ8Zq2TblcfQIFS9DMnbb5TrAHjy
diOpG8EJqu54ZiFvL8QcxMsIDWPFIrcZJc7PnsyuDkeHE0+4YWFOzf/Vi+QSS0L1rjwedWgjOHLh
MHNr3Te+/nrQ/ekxb+95SwPvK/HvhBfoggcuPwh/D4iBCFxwVa72wzjZ7jxiJADO5pjXz93WsDjV
d8Wi2EiytLnvqEJwA7Az2d/Q/hVymR8NAKzB2KnjDWVoQx558nsQ4eCVHu6pPO6aWnnB8bihEkj6
4qfleMd6NkqHEncPzW+D1RRIfhqAb2lp4P0Viuqaipx46rCUu9gfXN8J1e/4AqQ9CcCl3+vz+RaW
Gnu1dWtNXjlcoAIWPhMQWQO5zufB+eLcziKVI7NKbh1Lkil1vLeaOt7HrP6q6ZrO6UZhWwtOlaVH
/+VL9eHn5VWDZJK2NeK+G3SXPqlBd0sD72/YuFcwuL1sSCLDguu/Vx+d7zuG7ylT8otKjYWMAjLx
WoDUPLHP2axfu+eDHGCHFVn1OiO410jh7kSkdtzhLfeVrQk+TS1xCQflfYXBlgfEVW0ZT+FFu9nG
MM1q7i+4lvJTXk5xqPRyNxlbSwPvy7blQJZQOGlxNmR83UY+tnsqqz0SXAhvi1WuEBhTZbUJAbH5
8aaj+V5U+uymy1ImMPcC0QrOsecnFlRVWoflmGb7Dht8i12FD5yzoMD/euR5CXtO0h1BbL4/bj6j
rCO8tgBh/Mo2y4edEOTHGG98t18NuPV10mHlLQ28vx2fu8D9Em12ySt9JxkV42LHw+bU02dOy1vn
ShjwFAKhGZsCZm6mxzNhDqDI2Q6g+OCytrzXMLap6WIaSnRBVUkwD2yjgPV8GqFOsszVlNDjOxIv
n2fgSWRueiAwhbVJrILVTdt5PeD++01XeKUbdLc08H6FJjt7BOIrRmH6YbiqU7DU4teD2FYFWi4H
4AWLBvE/BJGIDYxBgFDG2uDvLiGHN7WwoXCvyXRuWy2tuXU59Nm2ajMDO0/CbT+LuTvcq7F5kboJ
MPQsVvNocBeqvjv2867P31WAey1AbtDd0sD7EfAEi579rC3uOxA/nv91ljrYJXUfC+fHb6yK/f0A
/Jp9Nx/+G8oPF/PFiWLUqhKd9HYqDu/j89acSAr4Pg3RkRCOLAlGH6vB754A3ChPTi/v5nOO0bKb
3g0zUfn9G3EXgG2LNO3n/9XncTfobmngfevWu4L4DK8ciTKtBE98q+8hUUMRW/HcgdnSyD2KRnnm
8dB15PbrRV3HU3GNOt5hW0MmyJvb00rTiYLltjDdHgm4p19Qt5LeTaA2dR6XjMtxUo9nbkbwT67w
2UaUwmsMHkK///6/uUtSoLulpYH31wH4d/QYXzU8+Tpk+ILpUP3IEZo9DcAXwJr37QqYe57tRcI1
ayiIquR7wA+V+lDL2wyb9nzq2wWJ0rC5Cfj1adqOh7sMWGJRMw/ZH8bsxU/a8+Pfq0+s3wW4G3S3
NPB+PbBuu+YzwOjDvg2uw2GIGhmYvozIjw3Az+Pz8//eDsCvtD8g9NM0qZhXykub4/F6aImxhVP+
C+o9YNu4BteUeofLGPlXHlAomZzX9HZkb3pojrd3JoaJCWH8FeVT51eBbmO9rPJwd0h5SwPvS9f5
zGKrKAv2TDV6rGr4UoD9QDvA91k2DHDpAfAG4cX1wC6sXFb0oHBxYe6aFD9WuFIklw8Oor1ztjXs
YHsROIMA4V4bwqBYJdASJby+keSxKoX9EadQCjM/2flwk/Fit7LfmWJ4Gdg2ELXz8436f0vLffI/
PQTfoTiMj/8uOffG6rPH6/0DSoR9CWTMaXCo0vK+wPix2DO0ZCFLTePy8cP/Pn2o+4jcuxEeGgjf
Z5x/j3BxHm7HiI7gX8/12LUJ6h4fZJNzkSJz3n8lzIRlK+1ft0bOc2qww4GKvSK2o2xbQZT4lKFg
bM/O8Va6DfZUmtizH3vMDWMWLUG8VDj5WrzfoLvlvdKh5l8GpMo8Erfta7zr77ZcdlQ2iw/iFtQp
ptGPPayJMIjJ8psOQhhq86JHvKPpJforlXocfQ6cXUfLz0ZkZcudtRze+W/j8Vwg2NpkDjQJ8HFD
IW88ZmYv7MJX5Xgrr4m1D3i2OXll5Nm53UG6rldrAh1W3tLA+2uV6pbTTvrAMPOR0/cePvtw/7ce
rHJ2pTLwREXnghn3BgB+EZ02FfCZKGZBZW4f0sHDW5kFvrV2JW+3lOIdIU9769FJ06bzE7EuvDz3
TG23TKdZTDmqn5/j/bdHMI8nRHaR8YC5PQmK63Z5GXCzQHf1qd+Au6WBd4PtR/QaKxtGj/Hqrl92
lIzgD9P4+Zu94BIAf/v+d1Xet6A6Y9XOFglT0WqBe+RsBzR8cJsjua3QNG5OIe+It/voga72SKfa
WzQtsfBZt56ApTne4wknV/h1RvTCVxxTK87TsUnmH6muNogWVgLuBt0t3ySvz/H+Z0EC7aVe7pAe
z3qLF2WhmYrooFR0YjBmBoLRrzDb+NPXqBfWcWGO9+07yVi8b/z7DP9Jfpa32AasW6Q74I4A1L4h
OLL8+OLYIzjfCPl9aHqqGK+1vgKy/nA8eJcpSVYpyfE+5nWvAN2Fewrxm7yb/9eH4eR1fLw+bmc5
H+FLxg6nD+G/OhEaM+xXmtQbcLc08G553CF+KxZJNVZDAzdSqm8QMGD9IGN7hvHA/dRlAPzJQJXt
IwQA/m3gu2iXQcVCgDIJh9vbPZmZXExszL6bq7wfn2H9hTUpYvoDlJGcrZp6wYsDFHPfeV5Hpo/Z
O8KaQq+ccfkUC/HuWQ/AnVu7QxE35kf3Txsktr8SbDfgbmng3fJI1XisaviS3s4/aLxqoM8NlZ3p
g78Mx7+MIOy0LqKx9TAawENWFKPCf0PcxS0bwvbp/+XXWoCTWmvr6DxWIemwubAFpd2DKe77Wwnp
kMElbR/88964bFpgZhNVlxZSGKoerOYOBqZ/Y7bBkVuL/KCA6L113t+0X459IQGEj5wry3o672Bc
jU3O2M9G1l/5tRpwt/wG6RzvVokvekDX6v7bc9R5mxYNBGXh1rjGkG2AfvLSbyNTIN08GxeyB95H
TIhLngEZw2425bjWxQDwc6mXEXlwCHAu+Z7ERFk7lxZ46JEdhPgoPzfHW3ovD3KxTOgwrAMj/t3A
D4H71xfleEuE4t8CUX883A26Wxp4v2zh/jYZv7i3S0LMPT34ybADc5d56lUKP78OgHuF+R7E2P52
AqjSDoC6HeEHYUM5lEWQSA7Cf2C+OwxbAbIbBV0ebMvfmGISv3KS15sTSljNJX6ssDN+OAieKZ8Y
2T9H4RaBkIqR317u2RmPgPubNN0G2y2/VTrU/DeD77qUa+IhdQfY+yit9DDzccVAaOXBRu2jPs9Q
WM4T3PW1vbakTuKZM3BhlHt5UCiVvz3zbkqPBTIylD1Tf1E3p5uJSR/seFox9RM7DCpmzzrsTE+v
3ybpwQi6fwd77cxEj88dNXJc+OHPn/CkWTVOZ+nX6a0Ntlt+uXSo+YvP1/IG8ZrevrRL944DGxzo
tcH4LE6hcdHobfXJJb3bfD9Ttp0bptRyD3hBjInRz1A9ZeRXGV1/+1gOzOnDeYyUXG0vosZN8YZy
LfRBw1XTMDARU3MWV0/95Xuw5ugfmXV1tLNgYnv0bqJCpMbGMR6MqTmwYzBM7NaDnNtXzrNvxaXt
4W5p+Vfa4/2b5RJS5vrGSwnJbkqU/1TTxwUDMabgNjeEYsGtH72ozHGccfNr7nfqDYjnfRur+bJV
N/foofz7SqMFuNt3bOpaX61yXG7DG+n5XjvOWO7hxrpZeLFTc1zV6BThGghECHnsIi9IADD586B+
ig15bQ+hdGFLHGx3PEpLy17a4/1yzCxBhPu0gmt9EKOqkXH/N1zW0FivBVop3p96ljrUEUe1eZPl
QxpW74g3aLHHeK5NsI92tWwtmDxYj1diZEMEDsrPy6d4M+ECMIwEBCNa+IutBN2oBd3H5nDf6vG2
PVQ8QMv9jn5rbZu1wKvnbU95PZ1FMio/Dion8q8F3O3dbmmRpT3eXwzIw1rAMhWj/h1R0b3xsu9V
/Lpj4btYKd6mD5lNw4b3R8Z7rXnApWtaSta4se6WLMlTo/9460bhVqHXE3Mqmrm4KYQ9xvrPh0vW
Awq+ySok+8ATkfVu0w8fOphlbJUa4E4M60gM3DC6AWZC3brd/wNW333eNNhuaWng/avUX60S8XVA
dLxz4Eq6jsMR75/iy0qEmyEQ45LPNAj4GgbgcDCy6pZBde++fC5f0NdRtBrUtjzkkEdK0ED36dEI
dRt8F/wGNW83pprKfTGqPvuTF1TtvghnyZZFrrHJ44PYIklgP5gy3vgEav+kIXGIF9kf/31IK4oN
ultaGni3rNVpXX1nVCru17mQS20EZefxCPXyR9WYepfAOwzqinrlhM2QVvUnz0mtPoSBDpHePVxx
GwTufMHuhX+8PkVGBwQ2DHv7+9svBIGZxXAO+r69l14dJVR+iXv3+Oc8ec0SvbzRoWPS3e+RtCUW
2MN47tQ3R+04/j+pddz9hV8Athtwt7Q08P5tm1/FUVTqer02zxtVg3fDd0P1BCC11LFuJk2964gC
cE1pBNXqUqPD7RtCEQD3qw4mrHZOyLnWUhqAEc8Lg1ErYEJ43h5y+8HmcEwUrCkjvqSLSkAi/MNK
aFXR8dKWx2ojw9D/OSYBu3adtnY/ednGqBzp/3YmawvH5DZxySR8D3jt/O2Wljn5GnK13gjOGIQO
Ayw5aMbt750qw1KmTsVygsvKBhMlqQf1mTA7otd8T5YHbWyPqPZ1KVbQOONQ03z5woL9R6MwV3CV
sWCQYDr7+d+B5Q7ir6jdjO8Dn0Xce2XbM2556lRXVmxZI9Ent2rYEMpGKtdlejiuHKlV1o6QDvt8
sN16dktLA+8W5QC9x/M9bn/vezS8cU/fLcycUnEi7DgPAeBjppEvB+GL9FNuycwsLNAAPLSoSA8s
zHuiTN4wwTeOV2B/36sPocQcmdqOkW3ougoGI7WW4luYRWp+/N8jAbojz3X7OWbmF9y5V0KijsiX
/R5psN3S0sC7ZaUmkD5D7i8jlurBWN0r/+rpMHmCqpxS7MZIPOwBADzStW8G4FZ1tYvwfQqNgX9C
hlUb0/3n34UaC6ueGHxQgd141PKM47//0/69eh6hYiKGG7rv3GLLiUWJ1yKk5lKE3DD6YRkOxoJ5
YfceJWf5+Pn/POsevDP02vm0Gmw34G5paeCd3kR+I35OvzXe99434ucplbGs74P9SbOy4OPA9TwE
98Zwlzz52z3gTN3dV4lUhBlTe5gHoZHood4uwv3jo/Vl8H0CzeABd+qsQOY7Fk+Ll8zkyLY0gq86
yPbHZht/EdztR8mYDG7cEHjyLfrfCF//BDW1wXZLSwPvloJtf1z2xJfleJczm1X6ziYUT9TMBx+A
3/vdS+D/NwFwK8f7awQmQAYJov0gdvexiVWNBFDd9LJgwkUIgugSjzYNfhG/5UvA9mfXNY/xMJat
VxiR2QtHYFitYnyo3oMNfBwqR44FWx0y7/E+krQG3C0t10qzmn+hSDwhSX6T16ji0yW5rMFbhOLL
QvSUPo+NK8liHbo/P9llUO8tuTX99DdUDIt4sI8T6+mk7ZPERuI6OrQpl/XyYOkIMBxjB4+HR/N8
7NQ0uRNH214WOo6b5smLZQRfbwjTZwV1g1XG+wnj4q23lXsLN/Ekj/V1m+4Qiqf/RK/97dvnfxtk
t7TcKe3x/gUgPHXYjYqnXPue00rJqBxh/1FlIdOsS3vkXzjmAX+mF9zt2VuY0EdyUTxuF7qq7UCe
OBaNZaZdDTDjjnHeJpKxn4SYcWu/NI8xnOuO/3ss2FKm98/Ld4hRaym4Zd/PPbQ91S0tDbyfpZf+
txkd//tbJE0+JukkqRju68HWvecgrn1coFeDXCtRAP7kMHQNQ3+GdYa7fzcol5iMxvLpWYjH5vOy
mR6gdBi49hC+80KkkvF2o9josMiWkX/aPRYpBLccbf/+Wm0mRQw63POq5rvx8yX6zMj1DbZbWhp4
Pw5w22Gz41eC8NJa0YM9/K5FKSk+KY9ONqQtjKlhnUY8hwHgqqJicr09H4Bv1QD8Ca+kuc0e4K0v
Kwd1JThNdBzJLuMBw3LdQxcCXDx+BEMwsYwc9fWYewS+I5xbxkO/dr1u29LS0sC75Y0AfHz850W0
6CX5aRfn+Y4LPuq4TCl4fhj6NABfbVNg4zwZmuO3A/AlmCzgbQe7n3lc5uGevJk77PAG+MJ3u3bP
b5j1MQZ3pVeQ5x/ztwbcLS0tDbx/iaSBqRavm1K9rw9Bv+amB4SZj/Nwg3he9YH+tjD041ilTQNS
EdwZu0Okds8LAHhovaD+iervU6Tmcz5vkD2H1GcO9+cxcwopwzR5LP+sLb8Ulg96blgs3sOZuUhP
Qpt0RTsrG3C3tDTwft+W3JsWfSSoEdcpxK5p+1j+HlNPmqIYH+nHbdsEYY6i0frZAOsZV98IwKd7
xn7Isem1emBYB7I53o/bCpGa1/NPAL9JoAb/IQi+mVx18QFOXjY8j/9T63v9djf5L9RHarSGMbl3
YPlzWndtaWng/d2b+i/ezCJOtjq283tA1XQ++xJTxgX9d3O871p3AQA+xuPWx8iuHw84W41713x1
rW4HdAVrRtOuZae9fVdyqBzuxbABAMjxwsT4FiLixswtmfPCX0QHkrzBUc9cza/Q+LmlpeVXA+8G
4XEQnncl41YQfl2Yeb5BKdQZmUckw+OvXgN0GPoYj9RYmEhx0exiebelxgdxzVegmiCTWTgEGou6
6IDuU98sXzOMq1A40siD8yCcXtx8S+srucUrnCnXnYG/ipu+paWlgXcD8BJ1eCo3Fbf0P+x/nkbs
+aTLtdxufqHvK9eCiq3xfABuYenP/w4NgDP52d41bI734yE25hpiPcxkHjcHKWfGACIoBlfIm4Ly
z/zO3avfBZX5fft47sTKAf77LK6CB/fX8vroI/Zb66QtLQ28W74csK8p0T3e954lSdc5CD0uGLVB
zF+NeOZSAI73AnAoU2mwN0b01xeFmI8n2AdWlBC3C3kvBX8s+EYkVP31IDbNDNdysTbBnjPpLzjG
9PbIGO7Gi3WilpaWBt4Nrh8GJKRTaISR4n1h5ppevMbYMAehJW63Mfn+M6rMHQB8jPcDcDZSnF4a
XwLA9W4uYVHTfzTLEgXAWji45U0g0GFqu6oLqVrdDbjvBd3y+GuM4vLZPELfHBNrbYz3rtKWlpYG
3l8HrH+uiQCQbwHsYUMuFWZ+hW9XVwemEXsaQt/8JUd+zl5d1uTtAFyaSi7jwZfb+HDFAxi8RdYC
LnNWY+Jdvv+rtbxeQ5CIJ2rPj2HOx5Fo53ot6d/X3+tBHVHe0tLA+/ceH2OErvtmT7gZ8Uq8Nnvd
VWijPGKcjlfP53izjxqL5/sjAbh0wYvywDPXfNPe8jRfJO5oD4nflPxuTFQXftRYPnFytARPH+zO
Cfa8GAU61RAOj3FGvrk9G6vHbf5sbmlpaeD9WgD+Gz3bdccGg9hLgr9TCmE4Ov5axL7IPDHK5/Ej
8sBTFz0fgH8rED+mT8yEhsbXv13RG0qCdrZe9zT4Dg6JBr5t0jYPAWv/9u+YelzLC1byuFknwiX7
xgr9sqWlpaWBd8EmGQEhT998R+S6oTA7vyTnkXZej0iX14XSW3xcYx1yL5v7JQCcRekvV3K+FXxf
kmiCyCXItYnkwzOYgWIzxweOVQC3mwuP6mG+EHA3el+/ctd5a6VgJvYzj1G3e2JiafNj0AC8paWB
d0spCP+2IzcLwkV2qRv8etFw3zF9Xko+dpT3+UlA7lIAzr7Py0H4N3m/Jbx5tdc7/jyEynjvfejg
tPlS5936hPLn1ehul/m6k30z987P7bVii82dH2Pm7QJLCtHhCQ9re8RbWhp4t0wcDt9IyhYB4bXx
3OPSdzAvHs/8HhCHfY5UbQaAXzGXQyacBuC3fwscQPfSkHO3dBhyba4gWiOBOWbi0xGDzyynOYq+
SYPtqwUMEszg8ZJzGcXTKhLJN/tq48Jxa2lpaeD99YB6pu1vt2iCPsVgIPYHIwtELt4EWFyvmDwZ
lD3aC/5iAP4WEM7M5avfJZ5/DYoLrSZL/A4Qe+sjvqZXz98xxuaiQcmFjeu2z6ovO6Z4WZ6pQ7a0
tDTwbhA+sdn+inJj04WzQ4nVZe8w5Xsfaw50pkUa6Nww9+4A4HQu+BetvTuBueRDtfoytgtCzrEW
tGXrF6DiVSINYd0YtDx9hziszP/2vcHEiOOMxy84LD56P0JvWozEl+0cjc9bWloaeN8A2KOA5A1h
6BRoVRPC08fqUrVlfJ68aWaodeBbD7q8Psycne+PAuFfGoJ+p2fcA+DHtOZBtbICMldcb8Nvj1H9
6jdv+U2gO4n4Djj95l5fdDbdrwu2tLQ08G65cPP9po2XjhqnQfi4/33S6Abl74GKvj9gbVxaE7wB
eMka8J4zSADOJ19IMBTrVw/su0CAbq9xNmf6OZW6nyY9JufV9zeW5N8tTTZ2MgFp79wOfeZ1xgA8
qHnWYLmlpaWB9+tB+FtJ2WAp/UT3horYr1OuvDByE3xMhdIvBJQPnjOPBuBfWJ2AAegjuz6MMYZz
LegdJlZfmto+FjTJXQ/nN3BljbLYv3aUHwmy3r1S2feUz8vI9jWOe8Cbtr+H9FM6w9qL3dLS0sD7
ZUD8m0jZcrj03pqY0TJkfiu4bizH+9bD48jYvhCAs/Naw3rYbO82AmOcWw2F3m+vlJdAsobS3oO8
gqwNzlKRE4C7XPCYRl6yMseWZRz53EOP63JsNOXaLbrQOGy/d2FzXDj/GqC3tDTwbnkxWH86sDaj
y/FcBHmCz1OFtMUC58u/yVsO+Abg17+3paBby1MaP292S2XwLpep+tkI/gSqcBcYowLjwX8S4C77
WNjujIa67tSUVo9Uw2Kctqef0PJKAHrHPj+z94+h/yH6LLhrt6WlpSUn/9ND8C5A/YYw9KkU7z96
xr/K1hifCkiortcluvufaixuN8dFCuN45JzIrgsAl81XkON2RZ+uXKcW4dkxL1sC1RJAhzEba1fx
QvgeYQR3XhxKX7mCZXXjdOnMRcXN3xpSrq0caV7LRfikrXy9SXdez2HOINzYf+484N75W86KlpaW
Bt4NxF8Comhv+PjvoAPb4ih6cv2hbHdzXNHNr1sPVwJwFoTv+/Q841D2vUGuNhDrI5JmXYPwxnM0
+MpOvGVKoaqB4YzhF26cf+pnf6yeMWjgh0e+0ggFDEXJQ1E2F9+3b7e0tLxTOtT8C0D4W0nZNIVi
xFnZbgEr4dz1uQLn5caOt62FO4nYxDzmU5/G67+AF9DL5odWkbW9AmRSFOW4vaO4ajxSN36GlFum
oNfvZPrBtxuCsRvTz4wXaxu8e12xzOGeLjLcCVZjeJeeXTnjzPD/zu9uaWng3fJ+IP50UrYRUNz2
rxBV9dcpayZ8Hs47i90sBGzj+9fA3UzoMgA/ztnx2o9RBa696XjZ6GBhm7dgwm/zzLEF6d4+BoN/
HfzdTD63u6eD7iuPL7j9ifT9vnduaWlp4N3yJSD86QdDJAxdOhyfRiVzgs/jqCAwL5AJpxy/cv7f
QcRmQgNxno7tmzzgdwvoK3A/TkPyHlQ2/kSAar1klB//xaD7sKmceBzxHl7H6/UMPnoszKOzCXyo
LS0tLUXSOd4N1B8D1jnCkv+OxlHQ2EIw8qlbwbpiPOvbv3luX0Vkw+Qxn9I1L4rGeCMcyeZ1pmb1
3Tnf1vPx0D7fs4ICgzBe8K78cfUTaf5kXq5qo+e/bSG0O+x/XUQrh6J3w+86k1taWnRpj3cD64e+
T0SvuS8M0evmTl00uzmCLUuFmb6D0XxWGXxSOTK5Gtm3ePHugzK2So46JXplmPrVz135Hli1qizv
+MPX0dDzjkby9rfpGnedvOOXcBm2tLQ08G550cH49Hxw6yDVsczwj+WbveF1F54Vz4Zz14NwVok+
g/AG4DMK8QhdzVxLxnmjoPPpSPElaHfug2D2JiaRw7v2faB7vGwHuGw/HfHSm3/vGY8du5aWlpYG
3g3EwyD8aQfIuBhYR/s2nAsG/XJWa82c+gSFMaKTywC8gfgyrFeFpFHY2RIMzeVKY8VHuPzrjuUz
YfLk2Z8/Ty2qndg7rwXcc8N/67dvaWlpaeDdkjncHg3YhgVycVaAxj1eYVPvMosls2HmH4pnY7Yv
AOCt2EWWPq7EgrMPYLnEzHtYBI9141DSPFOsj9jzblkvYzv7rD/PmwPoPl7yhbrCsnOTff7/Xvdp
Yrqq29nxaWN4S8vvliZXayk7JG47UCiycKII6gWq0WAvGNd+u984p/EQ9qLjZwKOQKNFg2BrVu4s
m9kdbGj6MzHbFJ7wtdd+8dQhA20By39+6m78z3543Asv48kQnh1D54vA8YJp3+dxS0vLP9Ie7wYh
rzs42PxtG8PenPs7SL/2sFoaXEN96Ktj8sRx6TxwTt8epa1VXIpL3hxXPB+rv6JbhVn5wtLfGU/5
zC4+5H8aqeZHIusng+7PvfDKsHLtOSP5lbLnXJ+NLS0tV0p7vFvCB9CPlTptrb4BiOsgfBig57r+
YyhW9lPtpDE7Ii3KvMeD6vd0OTIfEw7y7zlkGfVa43DfWq/3m6t45/cuD3BPF5vTnzX4Qf4E3U/a
iT/P96vBZvmzx5WTG2U6QoP8lpaW9ni3TB0STyNlo73hqdzasa5vn9cMJScctl44+oCfnu/vyAPv
b6uBykFd9TBm8F55Gx/ZoeV4E27o0wwZfNfItbq9AHTfsaemAHrhuXp1Sy0tLS2atMe7pfTgfooX
nIbSKgD/9Fxd36/P/gHRRu7IMf3O+f7EPPDOAbeXQ93sr8j1bqkTL8cbG8cAQBhYHcAtpXhjK6Xo
mDpF7jIgZkqUPk1naGlpaVkp7fFuWXYAP41BPRdm7nnJLwDiw2LAboj9lHl8Bwg/lyL77tngvZ0M
lZ+k1KP8HlR27RKC9Jl5qt0LpdOWBxzhfo1tb5YdmxuMdAvovmuvvLytUTcOHSTW0tLSwLvl0YDk
jgP5CkVGJkC/zpdxUhODZL4dZr4OgD+GYHBYiuJ3A3APetVCbRC/obhNTPcS0ZshDOzjplEkFUgr
WUaA88HNOQ/ir5v1erTLN4DuipnQ0tLS8lTpUPOWyw7dx5GyeW7mzQM31ykeoOH/aCB+0Xx/6hw+
E7J9ZxinlAiyntZsFnR/3s+wcyPco/Sm8vrZoL0MlFnDAW5v7l1rit2v6X1O9HUfstoI+W9bMFcH
pV+U73UdAt/S0lIr7fFuuQWIP46UzQHhdBnwBSqXFWLekPreef7UcmSnaA3bRf4LBA/vE67pLzbd
w31ZqPldwHzogHvE99b6/VdqcQgfA5eejXedyTNmjdV9bGN2S0tLA++W14LwJx5kY/i/7wivrlIb
3WFqC/2dAPzxjOhfrjB+UiIisD4QugfFPQa9lqfD6QcBuL9qcW6b5tUej7JcemHy2+2g+0pDI12d
pIF0S0vLy6RDzVteCdSvPCy9ck7/hrhNoHgSTGSUjVYorp+7j2P2/4jixOd8/DIm4WPo+TkUHdT6
8lfjzOpdY2igfsPGV9ratveF0xjx3+OwgY7APLroUDHh52d6y5V7+tXPOu+bbUhuaWn5LmmPd8uv
B9ZVOt9t8egNtB85zx8Zhn5U/kMA4B1zKwp1B93qLLs4pt+rBJhWsYJlrYErJrUVRT648bzMAT6O
neXTrb4VdIeei4X97vOzpaWlgXfLbwfhTwvjPXYD1kVMzHoJMGgQ3gA8jlN2AHyMBbPwXgAeuRZb
Puraplp7qNeuIsf7znBtgxuNebVbXwHyUTDG9ThQ2p8es2dZ/ejzraWl5YXSoeYtrwLizyNlG5xa
/U9/CkJ7R+KXlmfN7aeEoYuc5+I8/cycHo8GlCMAnjWwvGYl3ezt3jY9FAATAz2ctqs3OqfUl8cT
b3CaXzdDARND/gDwK7aJ43n6CmPtcM7jiYHz0wwuSURoaWlp4N3S8jwQXnHQlusBkvfA+b3ld87t
pwHwP0Blxxpo+XKfXa7MUpGx2SWhZnDkNeXMbrAFVIH2IsBlfTPLmDIesNKYY6D6qLgrZP0x87el
paWlgXdLSw0Qvyt3/FPZG0eFfjrMnO3nGUJ0mHkD8BKw+knEdnLDDQ26PxZwS8C4EnSvAOW4amC0
60bCchHZxixAf5hiwxmja6IXUov99D+v3KLfch5kDenXGeAb7be0tDTwbml5nJLB0lH9IWUTgU3L
b5yvT4rggAAcbCX0OV5wKIB3KL9HQXIE3Ed+uw0wem5hJEB1FHAb1zB76a3j5wDuq/eU1xtf/3mH
/90LYQxtH5ctLS1vkyZXa/laYH1F3lrEJx2LL4y5ib5C0WpZPl8z83sc5zDFhn5vkeThmAMs0D0m
QXfUKBD9W+lDpU8lpfhbydIWKB+bTx9OMK2zu+GzSnOP0/jcRZb2Vfvjje/bZ2xLS8ustMe75VcA
latI2Wi4LChlsrY/HI25lYNvntNPzAM/AQuzj8/ygB+9oiDvyVyzymO+9qM6QNq7LhJyTgRMjPcu
4NM7XgW60eHQLS0tLQ28W1qeAsL/KCiLQU3IZ/1/cXN7jXv/KsPV8VoagF+J1WD+8VkA3Mvr1gC6
hzVX54NfvilJFgk2x1sD1p5HfeS6+XjQfcEevSNM+6LKFla1ELTRoaWlpYF3S8s7gfgj8sH/w9x/
c9aqSNdavnEeP7sc2a342sSPR6AMz6BAKP1Zb/jzJ5wyeN5WNDl4rwfch8TjlcfLbwgpxyM/c5+/
LS0teekc75YG4ReB9OFp6BvPeNtnf8/jJynIJ4fno5Jt7ZRkz6ELB1Oy4ABPGIRMR0FaHKxcbid/
+2HThQPZR8B9oRHhN4O/0WPa0tLSwLul5fuAzSNI2YZ+NcVx1fK1c/SJiqIPwMft/UPhWmXaqrrm
UrHI0gYxAYY/OV4Hto+brrQBL8pN/1zvvxsg9oHX0tLybulQ85ZfD7Bnr6lQhngP9/ND51vuncNP
CEU/OTp3f7g+iDQS8v3p6R7E/YN8/isAt/R5RvCjO7+NJ797doNeCA13edy/YH/n+Ff+naRjRU2x
rlPW0tKyUNrj3dKy8WHmVyg+HWbeMjuXn6KgP82rmcGTs6D7nZPo++cCvRmzxtfJvVlat21I/epV
1tLS0sC7paWFBeKtFLU0AM+Cruv7FQHdEa9s+8a+D3APB3CP4vc7erX7bGmY39LS0sC7paVB+ILf
/ef3N2iZB+CPUOYfkO89EtczoeK9TH+XEWEGcFemOTUqbmlpaWng3dLyawH6UlK2VjxaCubob1Oe
x+J7xi/EBV5d9PHW8RBqSFe+y+c50d7tmnOwx7ClpeUt0uRqLS0LwAsLwlthaLlr/t5Bwnb2Hl9L
tJZZbSwx22trdheO5+vrcH/T+3wRyIZX0++xu0dLS0vLXtrj3dKyGIRfAbDbc9LyljlzrpA3rnvu
gns+TQe/BXyPL4IlIqnZovfpPTqBui8c0/4+LS0tDbxbWr4IiDdAbnkq8Lh8Xr5oGYB4hW9f1UwZ
7zftz1fP+d73c+MFBYdfP55No9jS0tLAu6Xl1SC8QlFrha7lbeDgG4J6JdD94/1+NThVAPfb5/Ux
t/oTTq0myes9ena8evxaWloaeLe0tBQqGa2ctTwJpCxPjzjp08+c/2xY9f9n78613LbBAIwKOe7T
pMn7p0mR90qTJi+AZGzL5lAiRVAg+AO8t7Fn0cZFB9+AInufD3t2xu6RgvvZOsoH/4HBEU519798
8rYEILyhs8CuEeEGAbQOl6O2uV435fzk//fZ7p5mvUcI7Pn2uRa8udHr9x5dM7od5g0Ib+DgQdna
75lNIdr2un9gvfydWAGwfB723vbEkYJ76TDyV+sodba/CPHbzWHnQM9cTgw6CBsDOaJtpzUvR/bp
kkE/BtbxZrmWAvzZZ7zzrfXF0l4/7zTotli6LHLA58XmN4jPy7rhZRG/rVuz78B+ZrxBhMOu7bTu
pXyeJVKcbX3654CjLk1WGv5X3eYivgc6AgkA4Q1AxzEUJ8Dzwv/nz7b0s97pjSXz6mcjnGn9vp1F
fC6Cu/GO12YFW+aA8Iarhg30FOHHjHfPDfA8C9nSQM5vvqJ5QOeLbVfRtm/B3Xzpa2JAeAMiHGoF
+MfNogb41kO9Sz/jnXc+j/ziPnuf7Y4StyL77OV/3n4OUJOTqwFwWLB8jcAdJ0C6D7aXb9p27ved
z3gv3SYXPPY8pEc+zVOkGW6icFIzoH9mvAE4PGBGmAEvGfof+RnvvOF28We7H9ebGW62rp8e7xtA
eAPQwWD73IrcErulEbzlJc1v2+pa1K0jV/CwHsBJIAPCGwDajciXajM1eeg9wf3qPnJBiG+J75jX
7f786l4fyRAl+LAuAIQ3ABcaSKfZFy3H5e881PxEa9PPi6/NpG85XHwe7QGvcD0LbtfhBkB4A0D4
+P50dvEU+xJDaSWUbwshvvcz30tfx1jvcWe4Bfe47xcAwhsAKgbt2nejPNf0JL6Xzs2+9eRo82jP
oV51ChXc04gT3EPs8Fd4cEB4A8D7AXS1sfHaRdD2XiYszgnXYh7wPo1swd2XLICBgbiONwCnxNCe
63uPUOBbTrpWesh5OuVVLD96hLZN0T+HwN4CP+HBbUeA8AaAXTPFUZ9vP68lhYnt6R9yzGiPul9n
CwTomkPNATgtlo7pwP7DKwV/dtNVd+bEssPIr/Jece77lO0KqMGMNwCnDmqPOeSc+n8KiDXDzfXe
K27eKwDhDQDnp2Hu7ZjzmEtx9vXtIbjPWMRim69/qLODA8IbAHYOpqvPZJUU+DTorjqoj32yNNHN
se8XAMfzGW8AQgymq+VjKr7FlZf8p2UwXw0RTgwuugEYgRlvAAaOyiy6N7z2+cnSQjxDwX3tvfek
9f/tZGo3s+pAdWa8ARhqoJ1OvHX82H6c4Y7Wt84iTYQAB6jNjDcA4QbZb882/ZjsXpr1nnw/PX5r
/OUcOLBMNAIgvAGgowBfrPLH/z6N8nGWaOzYBoDBOdQcgNABvifOvh5Qndaj+/7zxwOw34x5Dluv
XG87ARDeANBFqKXtP0l7QjrFju+UQp0wTUzR0TuPRQBU41BzALoK8A9bD0H/3HizQ8yrHk2eDdI3
rDcAuCoz3gB0GXJlMffqc92THxXNek8LPkdcUKf+OcAMN31zpj9AeANAedil7UldFvOp1Qu20gFA
eANAtDpf/fL5r6eCO/1xlrZg8X3ibLeZbo7arkZ+PEB4A0D/A/K0Z6Y7bfpeavMiJ0fBp5DrQHAz
TgTbjgHhDQBlw+dU+zb5IeZToyDeFN8Hz3ZPI0hsA4DwBoDbjvZeuGX6FN3tnnx6eBYv4/vwpyS4
abettefEaoDwBoCiwfmeYfvqAeXfHys9+43U5rrei/HdcLYbRD/ANq7jDQBrdZunmZsXo7s04wtG
/ou3/vn00qGhnw6+fwgZ3Ca9gYrMeAMw1mD5MHlTRv+c9c5NYjXVS/yH5edwcsbepwHaMeMNACtR
m9Mkuncdxv09wtMv//9TOIW28fHeTZP5ydIgSnTnbNoZGIMZbwCGG6wfdMdFqf1z1nvjfXz87ITr
FAttABDeAHBuyE+CeV+i5tfX4F743lFJLLhhff8AEN4AcFaA7wz2h/tIz8+QfuS1wO+xLSoAQHgD
QJXIrBmk6b0ns3wN7qWZ9IMvCQYACG8ACBfou+5/w/drnpX8rNcJAAhvAET1aTG6Ft+p8DZgfwYQ
3gBgcO81AQDCG4Crh7QYBQCENwA0jvERo9wfGABAeAPAECHa7PPiQhr7LYDwBoARBu2llyOLGN9i
BdEt+AHhDQAG0TufvyAAAOENAN1H95bbzH+nZRCvPZYwBwDhDQCXjPnajyWwAUB4A8Dlort1DJ85
6w4AbPPFIgCA5ajNOYcK6+XfEdxQdb/LlgNQjxlvADghlgEA4Q0A3OqdTfzoy5oBAMIbAIYJ8L2/
/+qz4UIbAIQ3AIjuN2+/NchFOAAIbwBgZ7CvhbjgBgDhDQBUim/X54b9+xCA8AYAAACENwAAAAhv
AAAAEN4AAACA8AYAAADhDQAAAMIbAAAAEN4AAAAgvAEAAEB4AwAAAMIbAAAAhDcAAAAIbwAAAEB4
AwAAgPAGAAAA4Q0AAAAIbwAAABDeAAAAgPAGAAAA4Q0AAADCGwAAABDeAAAAILwBAABAeAMAAADC
GwAAAIQ3AAAACG8AAABAeAMAAIDwBgAAAOENAAAACG8AAAAQ3gAAACC8AQAAAOENAAAAwhsAAAAQ
3gAAACC8AQAAQHgDAAAAwhsAAACENwAAAAhvAAAAQHgDAACA8AYAAADhDQAAAAhvAAAAEN4AAAAg
vAEAAADhDQAAAMIbAAAAhDcAAAAgvAEAAEB4AwAAAMIbAAAAhDcAAAAIbwAAAEB4AwAAgPAGAAAA
4Q0AAAAIbwAAABDeAAAAILwBAAAA4Q0AAADCGwAAAIQ3AAAAILwBAABAeAMAAIDwBgAAAIQ3AAAA
CG8AAABAeAMAAIDwBgAAAOENAAAACG8AAAAQ3gAAACC8AQAAAOENAAAAwhsAAACENwAAACC8AQAA
QHgDAACA8AYAAACENwAAAAhvAAAAEN4AAACA8AYAAADhDQAAAAhvAAAAEN4AAAAgvAEAAADhDQAA
AMIbAAAAhDcAAAAgvAEAAEB4AwAAgPAGAAAAhDcAAAAIbwAAABDeAAAAgPAGAAAA4Q0AAADCGwAA
ABDeAAAAILwBAABAeFsEAAAAILwBAABAeAMAAADCGwAAAIQ3AAAACG8AAABAeAMAAIDwBgAAAOEN
AAAACG8AAAAQ3gAAACC8AQAAAOENAAAAwhsAAACENwAAACC8AQAAQHgDAACA8AYAAACENwAAAAhv
AAAAQHgDAACA8AYAAADhDQAAAAhvAAAAEN4AAAAgvAEAAADhDQAAAMIbAAAAhDcAAAAgvAEAAEB4
AwAAgPAGAAAAhDcAAAAIbwAAABDeAAAAgPAGAAAA4Q0AAAAIbwAAABDeAAAAILwBAAAA4Q0AAADC
GwAAAIQ3AAAAILwBAABAeAMAAIDwBgAAAIQ3AAAACG8AAAAQ3gAAAIDwBgAAAOENAAAAwhsAAAAQ
3gAAACC8AQAAAOENAAAAwhsAAACENwAAACC8AQAAQHgDAACA8AYAAACENwAAAAhvAAAAEN4AAACA
8AYAAADhDQAAAMIbAAAAEN4AAAAgvAEAAEB4AwAAAMIbAAAAhDcAAAAgvAEAAEB4AwAAgPAGAAAA
hDcAAAAIbwAAAHjDH3/+lYQ3AAAAXIjwBgAAgCuF99///JusFgAAAEpFPMw8ZHgDAADASEKGt1lv
AAAASkSd7Q4b3uIbAACAEaI7dHiLbwAAAHqP7vDhfY9vAQ4AAECP0f3hSy8L9B7fv//2a7Z5AQAA
iG0AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACO858AAwAJGnUy61ATfQAAAABJRU5ErkJggg==" transform="matrix(1 0 0 1 -0.1565 -7)">
					</image>
				</g>
				<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="494.4532" y1="1392.5853" x2="494.4532" y2="29.1707">
					<stop  offset="0" 
						style={{stopСolor:"#534741"}}/>
					<stop  offset="2.135801e-02" 
										style={{ stopСolor:"#4E433D"}}/>
					<stop  offset="0.1732" 
										style={{ stopСolor: "#322B27" }}
					/>
					<stop  offset="0.3362" 
										style={{ stopСolor: "#1C1816" }}
					/>
					<stop  offset="0.5138" 
										style={{ stopСolor: "#0C0A0A" }}
					/>
					<stop  offset="0.7165" 
										style={{ stopСolor:"#030202"}}
					/>
					<stop  offset="1" 
										style={{ stopСolor: "#000000" }}
					/>
				</linearGradient>
				<path opacity="0.55" clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" d="M941,1392.6H47.9c-6.6,0-12-5.4-12-12V41.2
					c0-6.6,5.4-12,12-12H941c6.6,0,12,5.4,12,12v1339.4C953,1387.2,947.6,1392.6,941,1392.6z"/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M40.9,1389c23.6-24,47.1-48,70.7-72c-2.4-408.6-4.8-817.1-7.2-1225.7
					c-9.3-8-18.6-16.1-28-24.4C63.4,55.4,50.8,44,38.4,32.7C38.4,32.7,38.4,32.7,40.9,1389z"/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M38.5,32.8c23.5,19.5,46.9,39,70.4,58.5c257.3,0,514.6,0,771.9,0
					c24.9-20.7,49.7-41.4,74.6-62.1C649.8,30.4,344.2,31.6,38.5,32.8z"/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M952.6,1389c-23.4-25.8-46.7-51.6-70.1-77.4
					c-0.7-406.8-1.4-813.6-2.1-1220.4c10.6-8,21.2-16.1,31.9-24.4C927,55.4,941.3,44,955.4,32.7C955.4,32.7,955.4,32.7,952.6,1389z"
					/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M38.2,1392.6c24.9-29.2,49.8-58.5,74.7-87.7c256.2,2.2,512.4,4.5,768.5,6.7
					c24.6,27.5,49.2,55.1,73.8,82.6C649.5,1393.7,343.9,1393.2,38.2,1392.6z"/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M566.2,741.1c-175.5-21.3-351.1-42.6-526.6-63.9c21-6.2,42-12.4,63-18.6
					c146.9,17.8,293.7,35.6,440.6,53.4C550.9,721.8,558.6,731.5,566.2,741.1z"/>
				<path clip-path="url(#SVGID_2_)" fill="#131111" d="M573.8,735.6c120.3-34.3,240.6-68.5,360.9-102.8c-15.8-4.5-31.5-9-47.3-13.6
					c-100.6,28.7-201.3,57.3-301.9,86C581.5,715.3,577.7,725.4,573.8,735.6z"/>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M129.5,1311.5c51.1-64.7,100.5-130.8,147.9-198.2c13.5-19.2,26.8-38.4,39.9-57.8
							c0.1-0.1-1.8-0.1-2.1,0.4c-46.4,68.2-94.6,135.1-144.7,200.6c-14.2,18.6-28.6,37.1-43.2,55.5
							C127.3,1312,129.1,1312,129.5,1311.5L129.5,1311.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M202.9,1295.7c63.3-77.1,130.5-151,201.5-221.1c20.3-20.1,41-39.9,61.9-59.4c1.1-1-0.6-1.8-1.5-1.1
							c-73.1,68.1-142.6,139.9-208.2,215.2c-18.8,21.6-37.4,43.5-55.5,65.7C200.2,1296.1,202.2,1296.5,202.9,1295.7L202.9,1295.7z"
							/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M298.6,1271.4c80-99.5,160.1-199.1,240.1-298.6c22.5-28,45-56,67.5-84c0.9-1.1-1.2-1.5-1.8-0.7
							c-80,99.5-160.1,199.1-240.1,298.6c-22.5,28-45,56-67.5,84C295.9,1271.7,297.9,1272.2,298.6,1271.4L298.6,1271.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M359,1307.7c55.9-60.6,112.1-120.9,168.5-181c56.6-60.4,113.4-120.5,170.5-180.5
							c32-33.7,64.2-67.3,96.4-100.8c0.9-1-1.1-1.4-1.8-0.7c-57.1,59.5-113.9,119.2-170.5,179.1c-56.9,60.1-113.5,120.5-169.9,181.1
							c-31.7,34-63.3,68.1-94.9,102.3C356.2,1308,358.3,1308.4,359,1307.7L359,1307.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M484.5,1330.5c72.9-97.7,150.4-192,232.3-282.4c22.8-25.2,45.9-50,69.4-74.6c0.9-0.9-1.2-1-1.8-0.4
							c-83.9,87.8-163.6,179.6-238.8,274.9c-21.4,27.2-42.5,54.6-63.1,82.3C481.8,1331.2,484,1331.1,484.5,1330.5L484.5,1330.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M606.8,1333.1c63.7-59.9,124.2-123.2,181-189.6c16.2-19,32.1-38.2,47.8-57.7c0.5-0.7-1.6-0.5-2,0
							c-54.5,67.9-112.6,132.8-174.2,194.4c-17.8,17.8-35.9,35.4-54.3,52.6C604.2,1333.7,606.3,1333.6,606.8,1333.1L606.8,1333.1z"
							/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M693.6,1329.5c51.1-42.9,97.9-90.9,139.3-143.2c12-15.1,23.5-30.5,34.5-46.3c0.1-0.1-1.8-0.1-2.1,0.4
							c-38.5,54.8-82.4,105.6-131.2,151.5c-13.8,13-28,25.6-42.5,37.8C691.2,1330,693.2,1329.8,693.6,1329.5L693.6,1329.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M774.7,1325.2c50.5-50.3,100.2-101.4,149.1-153.2c0.5-0.5-1.6-0.3-2,0.1
							c-48.9,51.8-98.7,102.9-149.1,153.2C772.2,1325.8,774.3,1325.6,774.7,1325.2L774.7,1325.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M848.4,1315.9c17.1-19.4,35.2-37.9,54.4-55.3c0.4-0.4-1.6-0.1-2,0.2c-19.2,17.5-37.4,36-54.6,55.5
							C846.2,1316.4,848,1316.3,848.4,1315.9L848.4,1315.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M111,1232.8c39.2-62.5,80-124,122.5-184.3c42.7-60.6,87-120,132.9-178.1c25.8-32.6,52-64.9,78.8-96.7
							c0.5-0.6-1.6-0.4-2,0.1c-47.5,56.5-93.4,114.3-137.7,173.2c-44.5,59.2-87.4,119.7-128.7,181.2
							c-23.1,34.5-45.7,69.5-67.8,104.7C108.5,1233.6,110.6,1233.3,111,1232.8L111,1232.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M100,1100.6c60-105.4,120-210.8,180.1-316.1c16.9-29.7,33.9-59.5,50.8-89.2c0.2-0.4-1.8-0.2-2.2,0.4
							c-60,105.4-120,210.8-180.1,316.1c-16.9,29.7-33.9,59.5-50.8,89.2C97.6,1101.4,99.7,1101.2,100,1100.6L100,1100.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M71.3,944.5c54-79.1,108.1-158.3,162.1-237.4c15.4-22.5,30.8-45.1,46.1-67.6c0.5-0.8-1.6-0.6-2,0
							c-54,79.1-108.1,158.3-162.1,237.4c-15.4,22.5-30.8,45.1-46.1,67.6C68.8,945.3,70.9,945.1,71.3,944.5L71.3,944.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M51.8,846.2c36.6-77.3,79.1-151.7,127.2-222.5c13.9-20.5,28.3-40.6,43.1-60.5c0.8-1.1-1.3-1.3-1.8-0.5
							c-51.4,68.8-97.5,141.6-137.6,217.5c-11.5,21.7-22.4,43.6-32.9,65.8C49.2,847.1,51.4,847,51.8,846.2L51.8,846.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M61.9,225c44.6-65,94.3-126.4,148.8-183.4c0.5-0.5-1.6-0.3-2,0.1C165.9,86.5,126,134,89.2,183.8
							c-10.1,13.7-19.9,27.5-29.5,41.5C59.5,225.6,61.5,225.5,61.9,225L61.9,225z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M92.6,439.5c65.7-93.9,140.6-181.3,223.4-260.6c24-22.9,48.6-45.2,73.8-66.7c1.1-1-0.6-1.7-1.4-1
							c-87.7,74.9-168,158.3-239.2,248.9c-20.2,25.7-39.6,51.9-58.4,78.7C90,440,92.1,440.3,92.6,439.5L92.6,439.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M-46.3,726.1c63.4-92.7,136.9-178,214.2-259.3c77.6-81.8,158.9-159.9,238.6-239.7
							C451,182.5,495,137.3,537.6,90.8c0.9-1-1.2-1.3-1.8-0.6c-76.1,83.2-156.9,161.9-237,241.3c-79.9,79.1-159.2,159.1-232.1,244.8
							C25.9,624-12.7,673.6-48.2,725.5C-48.9,726.6-46.9,726.9-46.3,726.1L-46.3,726.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M44.5,868.4c37.2-47.6,77.2-92.9,119.3-136.2c41.9-43.2,85.7-84.5,130.5-124.7
							c44.8-40.1,90.6-79.1,136.7-117.6c46.2-38.6,92.6-76.9,138.7-115.6c23.1-19.4,46.2-39,69-58.8c22.8-19.8,45.5-39.9,65.5-62.6
							c5.2-5.9,10.1-11.9,14.9-18.1c0.1-0.1-1.8-0.1-2.1,0.4c-19.3,25.1-42.2,47.1-65.9,68c-22.5,19.9-45.5,39.4-68.5,58.8
							c-46.2,39-92.9,77.5-139.3,116.2c-46.2,38.5-92.1,77.3-137.1,117.2c-44.8,39.7-88.7,80.6-130.8,123.2
							c-41.7,42.2-81.6,86.1-118.7,132.3c-4.8,5.9-9.5,11.9-14.2,17.9C42.2,869,44.1,868.9,44.5,868.4L44.5,868.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M158.1,904.3c44-53.5,88.9-106.1,134.7-158c45.6-51.6,92.2-102.4,139.7-152.4
							c47.7-50.2,96.2-99.5,145.7-147.9c49.6-48.6,100.1-96.3,151.4-143.1c51.2-46.6,103.2-92.3,156-137.1
							c6.5-5.5,13.1-11.1,19.7-16.6c1.1-0.9-0.6-1.7-1.4-1c-53,44.5-105.2,90-156.6,136.4C696,330.9,645.6,378,596.1,426.1
							c-49.7,48.2-98.5,97.3-146.4,147.2c-48.1,50.2-95.3,101.2-141.6,153.1c-46.1,51.6-91.2,104.1-135.4,157.4
							c-5.5,6.6-10.9,13.2-16.4,19.8C155.4,904.7,157.5,905,158.1,904.3L158.1,904.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M301,1071.2c75.3-84.7,150.9-169.2,226.7-253.5s151.8-168.4,228.1-252.3
							c42.7-47,85.5-93.8,128.3-140.7c0.5-0.5-1.6-0.3-2,0.1c-76.5,83.6-152.8,167.5-228.9,251.6S501.3,844.7,425.7,929.2
							c-42.3,47.3-84.6,94.7-126.7,142.1C298.5,1071.8,300.6,1071.6,301,1071.2L301,1071.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M311.5,1279.5c53.4-54.5,107.1-108.8,161.1-162.8c53.7-53.8,107.6-107.4,161.8-160.7
							c30.7-30.2,61.5-60.4,92.4-90.5c0.9-0.9-1.2-0.8-1.7-0.3c-54.7,53.3-109.1,106.8-163.3,160.5
							c-54,53.5-107.7,107.3-161.2,161.3c-30.3,30.6-60.6,61.3-90.8,92.1C308.9,1280,311,1280,311.5,1279.5L311.5,1279.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M678.6,1009.6c47.9-43.6,97.8-85.1,149.4-124.2c14.7-11.2,29.6-22.1,44.7-32.9
							c0.9-0.6-1.3-0.4-1.7-0.1c-52.8,37.8-103.8,78-152.9,120.5c-14,12.1-27.8,24.4-41.5,36.8
							C676.1,1010.1,678.2,1009.9,678.6,1009.6L678.6,1009.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M597,1240.7c69.5-65.7,136.9-133.5,202.2-203.3c18-19.3,35.8-38.7,53.5-58.3c0.6-0.7-1.6-0.6-2,0
							c-63.6,70.4-129.4,138.9-197.2,205.3c-19.3,18.8-38.7,37.5-58.3,56C594.4,1241.2,596.5,1241.2,597,1240.7L597,1240.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M762,1227.2c51-55.3,103.4-109.4,157.1-162.1c0.9-0.9-1.2-0.8-1.7-0.3
							c-53.8,52.8-106.3,106.9-157.4,162.4C759.4,1227.8,761.6,1227.6,762,1227.2L762,1227.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M160,1013.7c19.9-26.6,39.7-53.1,59.6-79.7c0.8-1.1-1.2-1.5-1.8-0.7c-19.9,26.6-39.7,53.1-59.6,79.7
							C157.3,1014.1,159.4,1014.5,160,1013.7L160,1013.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M103.5,1158.6c0.4,0,2.4-0.8,1.4-0.8C104.5,1157.8,102.5,1158.6,103.5,1158.6L103.5,1158.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M107.7,1156.2c8.1-9.8,16.2-19.6,24.3-29.3c0.9-1.1-1.2-1.5-1.8-0.7c-8.1,9.8-16.2,19.6-24.3,29.3
							C105,1156.6,107,1157,107.7,1156.2L107.7,1156.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M124.6,1165.7c12.3-12.9,24.5-25.9,36.8-38.8c0.9-1-1.2-1.1-1.8-0.5c-12.3,12.9-24.5,25.9-36.8,38.8
							C121.9,1166.2,124,1166.4,124.6,1165.7L124.6,1165.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M125.8,1202.8c0.9,0,2.3-1.3,0.8-1.3C125.7,1201.4,124.3,1202.8,125.8,1202.8L125.8,1202.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M129.4,1199.5c18-22.4,37.4-43.7,58-63.7c0.9-0.9-1.2-0.9-1.7-0.4c-20.7,20.2-40.1,41.6-58.2,64.1
							C126.8,1200.2,129,1200.1,129.4,1199.5L129.4,1199.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M146.9,1231.6c18.7-22.8,37.4-45.6,56-68.4c0.6-0.8-1.6-0.7-2-0.1c-18.7,22.8-37.4,45.6-56,68.4
							C144.2,1232.3,146.4,1232.1,146.9,1231.6L146.9,1231.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M108.6,1278.8c34.3-27.3,65.7-58.1,93.6-91.9c0.4-0.5-1.6-0.2-2,0.2c-27.9,33.8-59.3,64.6-93.6,91.9
							C106.1,1279.3,108.1,1279.1,108.6,1278.8L108.6,1278.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M192.8,1265.7c35.9-35,74.2-67.5,114.7-97.1c1.2-0.9-0.6-1.7-1.4-1c-40.6,29.7-79.1,62.3-115.1,97.5
							C190,1266,192.1,1266.4,192.8,1265.7L192.8,1265.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M258.6,1282.6c39.5-33.6,78.5-67.8,116.9-102.7c0.9-0.8-1.3-0.6-1.7-0.2
							c-38.4,34.9-77.3,69.1-116.9,102.7C256,1283.1,258.2,1283,258.6,1282.6L258.6,1282.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M451.5,1253.5c2.4-1.6,4.9-3.2,7.3-4.8c0.4-0.3,0.2-0.5-0.2-0.6c-0.4-0.2-1.2,0.2-1.5,0.4
							c-2.4,1.6-4.9,3.2-7.3,4.8c-0.4,0.3-0.2,0.5,0.2,0.6C450.4,1254.1,451.1,1253.7,451.5,1253.5L451.5,1253.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M402.4,1324c17.9-18.7,35.8-37.4,53.7-56c0.9-0.9-1.2-0.9-1.7-0.4c-17.9,18.7-35.8,37.4-53.7,56
							C399.7,1324.5,401.9,1324.5,402.4,1324L402.4,1324z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M403.1,1335.1c39-27.6,77.7-55.8,116-84.4c1.2-0.9-0.6-1.3-1.4-0.7c-38.3,28.6-77,56.7-116,84.4
							C400.6,1335.2,402.4,1335.6,403.1,1335.1L403.1,1335.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M465.4,1310.9c23.1-14.5,44.8-31.1,64.8-49.7c0.5-0.4-1.6-0.2-2,0.1c-19.9,18.5-41.5,35-64.5,49.5
							C462.8,1311.3,464.9,1311.1,465.4,1310.9L465.4,1310.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M506.4,1323.3c22.3-18.3,44.8-36.3,67.6-53.9c1.2-0.9-0.6-1.5-1.4-0.9c-22.8,17.6-45.3,35.6-67.6,53.9
							C503.8,1323.4,505.6,1324,506.4,1323.3L506.4,1323.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M541.3,1325.9c17.5-10.6,34-22.6,49.6-35.8c0.4-0.4-1.6-0.1-2,0.2c-15.5,13.1-31.9,25-49.3,35.6
							C538.8,1326.5,540.9,1326.2,541.3,1325.9L541.3,1325.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M112.3,1141.5c7.1,57.5,12.9,115.1,17.3,172.9c0.1,1.2,2.3,0.9,2.2-0.1
							c-4.4-57.9-10.2-115.7-17.3-173.3C114.3,1139.9,112.1,1140.4,112.3,1141.5L112.3,1141.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M127.8,1134c-3.9,67.1,1.8,134.6,17,200.1c0.2,0.7,2.3,0.1,2.2-0.6c-15.2-65.4-20.9-132.8-17-199.8
							C130.1,1132.8,127.9,1133.3,127.8,1134L127.8,1134z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M148.6,1151.3c9.3,46.1,18,92.4,26.1,138.7c0.2,0.9,2.3,0.4,2.2-0.6c-8.1-46.3-16.8-92.6-26.1-138.7
							C150.6,1149.8,148.4,1150.4,148.6,1151.3L148.6,1151.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M161,1151.4c16.8,61.3,27.4,124.2,31.4,187.7c0.1,0.9,2.2,0.4,2.2-0.6
							c-4.1-63.4-14.6-126.4-31.4-187.7C162.9,1149.8,160.7,1150.4,161,1151.4L161,1151.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M190.2,1124.6c25.5,59.3,40.2,123,43.3,187.5c0,0.4,2.2,0,2.2-0.6c-3.2-64.5-17.9-128.1-43.3-187.5
							C192.2,1123.5,190.1,1124.2,190.2,1124.6L190.2,1124.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M226.8,1194.8c8.9,48.1,17.8,96.3,26.7,144.4c0.1,0.7,2.3,0.2,2.2-0.6c-8.9-48.1-17.8-96.3-26.7-144.4
							C228.8,1193.5,226.6,1194,226.8,1194.8L226.8,1194.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M266.9,1182.8c9.8,56.1,19.5,112.2,29.3,168.3c0.1,0.8,2.3,0.3,2.2-0.6
							c-9.8-56.1-19.5-112.2-29.3-168.3C268.9,1181.4,266.7,1181.9,266.9,1182.8L266.9,1182.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M289.5,1170.4c12.4,42.8,22.9,86.2,31.5,129.9c0.2,1,2.4,0.4,2.2-0.6c-8.6-43.7-19.1-87.1-31.5-129.9
							C291.4,1168.8,289.2,1169.4,289.5,1170.4L289.5,1170.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M326.2,1188.2c13.8,41.9,23.5,85,28.9,128.7c0.1,0.9,2.3,0.4,2.2-0.6c-5.5-43.7-15.2-86.9-28.9-128.7
							C328,1186.7,325.9,1187.3,326.2,1188.2L326.2,1188.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M361.7,1207.3c10.3,39.2,17.5,79.2,21.5,119.6c0.1,0.7,2.3,0.3,2.2-0.6c-4-40.4-11.2-80.3-21.5-119.6
							C363.7,1205.9,361.5,1206.5,361.7,1207.3L361.7,1207.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M377,1219.2c13.5,31.1,21.9,64.2,24.6,98c0,0.5,2.2,0.1,2.2-0.6c-2.8-33.8-11.1-66.9-24.6-98
							C378.9,1218,376.8,1218.7,377,1219.2L377,1219.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M426.2,1221.5c10.3,30.2,16.9,61.4,19.7,93.2c0,0.4,2.2,0,2.2-0.6c-2.8-31.7-9.4-63-19.7-93.2
							C428.2,1220.4,426,1221.1,426.2,1221.5L426.2,1221.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M441.7,1215.1c14.9,23.9,22.2,51.6,21.2,79.7c0,1,2.2,0.6,2.2-0.2c1.1-28.4-6.4-56.3-21.4-80.4
							C443.2,1213.5,441.1,1214.2,441.7,1215.1L441.7,1215.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M401.4,1219.3c9.6,34.4,16.3,69.6,19.9,105.2c0.1,1,2.3,0.5,2.2-0.6c-3.6-35.6-10.3-70.8-19.9-105.2
							C403.3,1217.7,401.1,1218.3,401.4,1219.3L401.4,1219.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M456,1240.5c11.9,21.9,20.8,45.2,26.6,69.4c0.1,0.5,2.3,0,2.2-0.6c-5.8-24.2-14.8-47.5-26.6-69.4
							C457.9,1239.4,455.8,1240.2,456,1240.5L456,1240.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M481.5,1248.6c10.1,27.1,17.9,54.9,23.4,83.2c0.2,1,2.4,0.5,2.2-0.6c-5.5-28.3-13.3-56.1-23.4-83.2
							C483.3,1247,481.2,1247.6,481.5,1248.6L481.5,1248.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M506,1260.7c11.4,22.6,20.1,46.4,26.1,71c0.1,0.5,2.3-0.1,2.2-0.6c-6-24.6-14.7-48.4-26.1-71
							C507.9,1259.6,505.8,1260.4,506,1260.7L506,1260.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M541.1,1255.6c11.3,22.9,20.7,46.6,28.2,71.1c0.3,1.1,2.5,0.5,2.2-0.6c-7.5-24.6-17-48.4-28.4-71.5
							C542.6,1253.7,540.6,1254.5,541.1,1255.6L541.1,1255.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M574.9,1270.2c9.1,22.7,17.6,45.7,25.2,68.9c0.2,0.7,2.4,0.1,2.2-0.6c-7.7-23.2-16.1-46.2-25.2-68.9
							C576.8,1268.9,574.7,1269.6,574.9,1270.2L574.9,1270.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M610.7,1282.7c8,14.7,15.9,29.4,23.9,44.2c0.3,0.5,2.3-0.3,2.2-0.6c-8-14.7-15.9-29.4-23.9-44.2
							C612.6,1281.7,610.5,1282.5,610.7,1282.7L610.7,1282.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M66.3,189.8c0.9-6.7,4.2-12.6,9.6-16.7c0.9-0.7-1.2-0.7-1.7-0.3c-5.5,4.3-9,10.4-10,17.3
							C64,190.8,66.2,190.4,66.3,189.8L66.3,189.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M66.3,194.5c-0.7-5.3,1-10.3,4.7-14.1c0.9-1-1.2-1.1-1.8-0.5c-4,4.1-5.8,9.5-5,15.2
							C64.2,196,66.4,195.4,66.3,194.5L66.3,194.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M51.6,245.8c7.9-15.1,17.6-28.9,29.1-41.5c0.5-0.6-1.6-0.3-2,0.1c-11.5,12.6-21.3,26.6-29.2,41.7
							C49.2,246.6,51.3,246.4,51.6,245.8L51.6,245.8z"/>
					</g>
				</g>
				<g opacity="0.45" clip-path="url(#SVGID_2_)">
					<path fill="#B7AF8B" d="M453.9,621.7c-0.1-0.3-0.2-0.6-0.3-0.9C453.9,621,454,621.3,453.9,621.7z"/>
					<path fill="#F5F0BC" d="M378.8,621.4c0.6-1.2,1.6-0.5,2.4-0.5c23.8,2.5,47.6,5.1,71.4,7.6c-0.4,1.3-1.4,0.7-2.1,0.6
						c-20.7-2.2-41.4-4.5-62.2-6.7C385.2,622.2,382,621.8,378.8,621.4z"/>
					<path fill="#F5F0BC" d="M330.1,616.1c0.4-1,1.2-0.6,1.8-0.5c13.7,1.5,27.3,2.9,41,4.4c0.6,0.1,1.5-0.1,1.7,0.9
						c-12.1-1.3-24.2-2.6-36.3-3.8C335.6,616.8,332.8,616.5,330.1,616.1z"/>
					<path fill="#F5F0BC" d="M343.4,616c0.8-0.7,1.7-0.6,2.6-0.5c33.1,3.6,66.2,7.1,99.2,10.7c-0.6,1.4-1.8,0.6-2.6,0.5
						c-26-2.8-52.1-5.6-78.1-8.4C357.5,617.5,350.4,616.7,343.4,616z"/>
					<path fill="#FBEFB2" d="M214.4,602.3c4.7,0.5,9.5,1,14.2,1.5c0,0.1,0,0.2,0,0.4c-4.7-0.5-9.5-1-14.2-1.5
						C214.4,602.6,214.4,602.5,214.4,602.3z"/>
					<path fill="#FBEFB2" d="M210.4,602.4c-5-0.5-9.9-1.1-14.9-1.6C200.5,601.3,205.5,600.6,210.4,602.4z"/>
					<path fill="#32613E" d="M182.2,592.6c0.8-0.4,1.7-0.1,2.5,0c52.1,5.6,104.2,11.2,156.3,16.8c33.3,3.6,66.6,7.2,99.9,10.8
						c0.8,0.1,1.6,0,2.2,0.5c-8.3-0.9-16.5-1.7-24.8-2.6c-78.1-8.4-156.2-16.8-234.3-25.2C183.4,592.8,182.8,592.7,182.2,592.6z"/>
					<path fill="#32613E" d="M176.6,591.3c1.2,1.1,3,0.1,4.3,1.1c-1.7-0.2-3.5-0.1-5.2-0.8c0,0-0.1-0.1,0-0.2
						c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0,0.3,0,0.5,0C176.5,591.2,176.6,591.2,176.6,591.3z"/>
					<path fill="#CEC69D" d="M327.7,607.4c3.2-0.4,6.3,0.4,9.4,0.7c1.9,0.2,4-0.1,5.8,1C337.8,608.5,332.7,607.9,327.7,607.4z"/>
					<path fill="#E9E4B6" d="M179.9,589.8c0.5,0.1,0.8,0.4,0.7,0.9C180.1,590.7,179.8,590.4,179.9,589.8z"/>
					<g>
						<path fill="#B7AF8B" d="M454.1,623.9c-0.1,1.5-0.4,3-0.4,4.5c0,1.8-0.8,2.1-2.4,1.9c-29.2-3.2-58.3-6.4-87.5-9.5
							c-23.9-2.6-47.9-5.1-71.8-7.6c-9.2-1-18.4-2.2-27.7-3c-6.9-0.6-13.7-1.2-20.6-2c-8.8-0.9-17.4-2.5-26.2-3.1
							c-11.5-0.9-22.9-2-34.3-3.4c-5.6-0.7-11.1-1.6-16.7-2.1c-1.9-0.2-1.6-1.3-1.5-2.5c2.1-19,4.1-38,6.1-57c3-27.8,6-55.7,9-83.5
							c3.9-36,7.8-72,11.7-108.1c3.9-36.2,7.8-72.3,11.7-108.5c1.6-14.7,3.2-29.5,4.8-44.2c0.2-2,0.8-2.5,2.8-2.2
							c59.2,6.4,118.4,12.8,177.5,19.2c25.6,2.8,51.3,5.5,76.9,8.3c1.3,0.1,2.7,0.3,4,0.5c-0.2,0.3-0.3,0.5-0.5,0.8
							c-11.3-1.2-22.6-2.4-33.9-3.6c-1.1-0.1-2.3-0.2-3.4-0.4c-15.4-1.7-30.8-3.3-46.2-5c-2.7,0.2-5.4-0.3-8-0.6
							c-2.1-0.9-4.3-0.8-6.5-1c-12.5-1.3-25.1-2.6-37.6-3.9c-0.6-0.1-1.1-0.2-1.7-0.3c-3.8-0.4-7.6-0.9-11.4-1.3
							c-13.7-1.4-27.4-2.9-41.1-4.3c-0.3-0.1-0.6-0.1-0.9-0.2c-0.2,0-0.4-0.1-0.6-0.1c-22-2.4-43.9-4.7-65.9-7.1
							c-2-0.2-2.2,0.6-2.4,2.2c-1.7,15.6-3.3,31.2-5.1,46.8c-2.1,18.6-3.7,37.3-6.3,55.9c-0.8,5.9-1,11.9-1.7,17.8
							c-1.2,11.2-2.4,22.3-3.8,33.4c-2.2,17.6-3.9,35.3-5.8,52.9c-1.9,17.8-3.8,35.7-5.8,53.5c-5,46.3-10,92.7-15,139
							c-0.2,2.1,0.3,2.6,2.3,2.8c22.7,2.4,45.3,4.8,68,7.3c17.5,1.9,35,3.8,52.6,5.6c12.8,1.4,25.6,2.7,38.4,4c1,0.1,2,0.2,3,0.3
							c2.7,0.3,5.5,0.7,8.2,1c12.1,1.3,24.2,2.6,36.3,3.8c1.4,0.2,2.8,0.3,4.3,0.5c3.2,0.4,6.3,0.7,9.5,1.1
							c20.7,2.2,41.4,4.5,62.2,6.7c0.7,0.1,1.7,0.7,2.1-0.6c0.1-0.9,0.2-1.8,0.3-2.7C453.1,625.1,452.8,624,454.1,623.9z"/>
						<path fill="#FBEFB2" d="M378.8,621.4c-1.4-0.2-2.8-0.3-4.3-0.5c-0.1-1-1-0.8-1.7-0.9c-13.7-1.5-27.3-3-41-4.4
							c-0.6-0.1-1.4-0.5-1.8,0.5c-1-0.1-2-0.2-3-0.3c-0.3-1.2-1.4-0.8-2.1-0.9c-7.7-0.9-15.4-1.7-23-2.5c-0.7-0.1-1.5,0.1-1.9-1.1
							c14.3,1.5,28.6,3.1,43,4.6l0.2,0l0.2,0c7.1,0.8,14.1,1.5,21.2,2.3c26,2.8,52,5.6,78.1,8.4c0.9,0.1,2,0.9,2.6-0.5
							c2.8,1.3,5.7,0.5,5.5-3.3c0-0.7,0.1-1.4,0.2-2.1c1.6,0.4,1.9-0.5,2-1.9c0.7-7,1.5-13.9,2.2-20.8c0.1-0.7-0.3-1.8,1-2.1
							c-0.6,5.7-1.2,11.4-1.8,17.1c-0.1,0.9-0.5,1.8,0.2,2.7c0.2,1.8-0.6,3.3-1,5c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.8,0.1,1.5,0.2,2.3
							c-1.3,0.1-1,1.2-1.1,1.9c-0.2,0.9-0.2,1.8-0.3,2.7c-23.8-2.6-47.6-5.1-71.4-7.6C380.5,620.9,379.4,620.2,378.8,621.4z"/>
						<path fill="#B7AF8B" d="M470,479.1c-0.1,1-0.2,2-0.3,3c-0.6,0.8-0.7,1.6-0.3,2.5c-0.3,1.6-0.6,3.2-0.8,4.8
							c-1.6,14.6-3.2,29.2-4.7,43.8c-0.3,2.5,0.1,5-0.9,7.3c-0.2,0-0.4,0-0.6-0.1c1-13.9,2.9-27.7,4.2-41.6c0.2-2.1,0.5-4.2,0.7-6.3
							c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3l-0.1,0c0.5-4.6,1.5-9.1,1.9-13.7c0.5-6.1,1.1-12.1,1.7-18.2
							c1-10.3,1.9-20.6,3.5-30.9c1-6.2,1.4-12.5,1.9-18.8c0.7-9.6,2-19.1,3-28.6c0.3,0,0.6,0,0.9,0.1c-1.6,16.3-3.3,32.5-4.9,48.8
							c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.3c-0.9,4.9-1.3,9.9-1.7,15c-0.3,3.9-0.7,7.7-1.1,11.6c-0.7,7-1.5,13.9-2.3,20.9
							c0,0-0.1,0.1-0.1,0.1C469.9,478.9,469.9,479,470,479.1z"/>
						<path fill="#B7AF8B" d="M496.2,224.4c1.4-0.1,1.2,0.9,1.1,1.7c-0.4,3.7-0.8,7.5-1.2,11.2c-3,27.8-5.9,55.6-9.1,83.4
							c-0.9,7.7-1.4,15.5-2.5,23.2c-0.6,0.8-0.7,1.6-0.3,2.5c-0.3,2.4-0.5,4.8-0.8,7.2c-0.6,0.8-0.7,1.6-0.3,2.5
							c-0.2,2.3-0.5,4.5-0.7,6.8c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,1.1-0.2,2.3-0.4,3.4c-0.4,0.2-0.4,0.5-0.1,0.8
							c-0.2,2-0.4,4-0.7,5.9c-0.3,0.1-0.5,0.2-0.8,0.3c1.9-17.8,3.8-35.7,5.7-53.5c3.3-31,6.7-62,10-93c0.1-0.8,0.3-1.6-0.2-2.3
							C496.1,224.9,496.1,224.7,496.2,224.4z"/>
						<path fill="#CEC69D" d="M462.4,540.5c0.2,0,0.4,0,0.6,0.1c-1.1,17.6-3.6,35.1-5.3,52.7c-0.1,0.6-0.1,1.1-0.6,1.5
							c-0.3,0-0.5-0.1-0.8-0.1c0-0.1,0-0.3,0-0.4c0.3-2.4,0.5-4.8,0.8-7.2c0.6-5.8,1.2-11.6,1.9-17.4c0.8-7.8,1.6-15.5,2.5-23.3
							c0.1-0.6,0.1-1.1,0.2-1.7c0-0.3,0.1-0.6,0.1-0.9C462.1,542.7,461.7,541.5,462.4,540.5z"/>
						<path fill="#CEC69D" d="M496.2,224.4c-0.1,0.3-0.1,0.5-0.2,0.8c-2.4-0.1-4.8-1.1-7.2-0.4c-1-0.1-2-0.2-2.9-0.3
							c-1.8-1-3.9-0.7-5.9-1c-0.3,0-0.6-0.1-0.9-0.1c-3.4-0.4-6.8-0.8-10.2-1.2c0.2-0.3,0.3-0.5,0.5-0.8
							C478.4,222.4,487.3,223.1,496.2,224.4z"/>
						<path fill="#B7AF8B" d="M456.3,594.7c0.3,0,0.5,0.1,0.8,0.1c-0.9,7-1.1,14-2.6,20.9c-0.6-0.8-0.3-1.8-0.2-2.7
							c0.6-5.7,1.2-11.4,1.8-17.1C456.2,595.6,456.2,595.1,456.3,594.7z"/>
						<path fill="#CEC69D" d="M480.5,373.9c0.3-0.1,0.5-0.2,0.8-0.3c-0.2,2.8-0.3,5.7-1.2,8.4c-0.3,0-0.6,0-0.9-0.1
							C479.6,379.3,479.4,376.5,480.5,373.9z"/>
						<path fill="#CEC69D" d="M484.3,346.5c-0.4-0.9-0.4-1.7,0.3-2.5C484.4,344.8,484.4,345.6,484.3,346.5z"/>
						<path fill="#CEC69D" d="M469.4,484.6c-0.5-0.9-0.4-1.7,0.3-2.5C469.6,483,469.5,483.8,469.4,484.6z"/>
						<path fill="#CEC69D" d="M483.2,356.2c-0.5-0.9-0.4-1.7,0.3-2.5C483.4,354.5,483.3,355.4,483.2,356.2z"/>
						<path fill="#CEC69D" d="M482,367.7c-0.3-0.3-0.3-0.6,0.1-0.8C482,367.1,482,367.4,482,367.7z"/>
						<path fill="#CEC69D" d="M482.4,363.4c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C482.5,363.2,482.4,363.3,482.4,363.4z"/>
						<path fill="#CEC69D" d="M470,479.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C470,478.9,470,479,470,479.1z"/>
						<path fill="#CEC69D" d="M475.1,431.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C475.2,431,475.1,431.1,475.1,431.2z"/>
						<path fill="#FBEFB2" d="M485.9,224.5c1,0.1,2,0.2,2.9,0.3c1.6,1.1,3.5,0.7,5.3,1c1.5,0.3,2.5,0.5,1,2.2
							c-0.6,0.7-0.4,1.6-0.5,2.5c-5.4,50-10.8,100.1-16.2,150.1c-0.1,0.6-0.1,1.3-0.2,1.9c-0.1,0.5-0.2,1-0.8,1
							c-0.6,0-0.9-0.4-0.9-0.9c0.1-0.5,0.1-1.1,0.2-1.6c0.1-0.7,0.2-1.5,0.2-2.2c4-36.8,7.9-73.7,11.9-110.5
							c1.2-11.3,2.4-22.7,3.6-34c0.1-0.9,0.3-1.8,0.3-2.7c0-0.8,0.7-1.9-0.6-2.4c-0.9-1.6-2.5-2.5-4.2-2c-4,1.1-7.7-0.5-11.5-0.5
							c-1,0-1.5-1.5-2.4-0.9c-1.3,0.8-2.5,0.4-3.7,0.2c-10.2-1.1-20.5-2.2-30.7-3.3c-1.9-0.2-3.8-0.1-5.5-1.3
							c-0.8-0.5-2.3-0.7-3.1-0.3c-1.5,0.7-2.9,0.4-4.4,0.2c-49.6-5.3-99.3-10.7-148.9-16c-0.3,0-0.5-0.1-0.8-0.1
							c-16.3-1.7-32.5-3.5-48.8-5.2c-0.4,0-0.8-0.1-1.2-0.1c-0.9-0.1-1.7-0.2-2.6-0.3c-3.7-0.4-7.3-0.8-11-1.2c-1,0.2-2,0.7-1.7,1.7
							c0.3,1.4-0.3,2.6-0.5,3.9c-0.7,6.7-1.4,13.5-2.2,20.2c-1.2,9.8-1.9,19.7-3.1,29.6c-1.5,12-2.7,24.1-3.9,36.2
							c-0.1,0.7-0.4,1.5,0.2,2.2c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.2-0.3,0.3-0.4,0.5c-0.4,3.7-1.2,7.5-1.2,11.2
							c0,3.7-1.6,7.2-1.5,11c0.1,3.6-0.2,7.4-0.9,11.1c-1.3,7.2-1.6,14.5-2.8,21.7c-0.5,2.9-1.3,5.8-1.5,8.8
							c-0.1,1.6,0.4,3.3-0.1,4.9c-1.1,3.3-1,6.8-1.4,10.2c-1,9.1-1,18.2-2.2,27.3c0,0.4-0.1,0.7-0.1,1.1c-0.5,4.4-0.9,8.7-1.4,13.1
							c-0.7,0.5-0.6,1.2-0.7,1.9c-0.4,2.3-0.1,4.7-0.3,7c-1.3,12.2-2.6,24.5-3.9,36.7c-0.2,1.5-1.4,2.5-1.4,4.1
							c0,4.2-1.4,8.3-0.6,12.6c0.5,2.6-1.2,5.2-1.5,7.7c-0.4,4.7-0.6,9.3-1.2,14c-0.4,3.1,0.5,6.4-0.6,9.3c-2,5.2-1.5,10.7-2.5,16
							c0,0.3-0.1,0.6-0.1,0.8c0.1,1.7-0.2,3.5,0.3,5.2c-1.2,11.4-2.5,22.9-3.7,34.3c-0.4,0-0.7,0-0.8,0.4c-0.1,0.6,0.3,0.7,0.7,0.8
							c0,0.4-0.1,0.9-0.1,1.3c-0.9,0-1,0.7-1,1.3c-0.1,0.8-0.2,1.7-0.3,2.5c-0.1,0.5-0.1,1.1-0.9,0.9c-0.6-0.2-0.4-0.7-0.2-1.1
							c0.6-1.1,0.5-2.4,0.6-3.5c1.4-14.1,3.4-28,4.2-42.2c0.2-4.1,1.2-8.1,1.6-12.2c1.6-15.2,3.9-30.4,4.6-45.8
							c0.2-4.4,1.4-8.7,1.8-13c9.1-85,18.3-170.1,27.5-255.1c0.3-2.9,0.3-2.9,3.3-2.6c20.8,2.2,41.7,4.5,62.5,6.7
							c0.8,0.1,1.9,0.8,2.4-0.5c0.3,0.1,0.6,0.1,0.9,0.2c0.2,1,1,0.8,1.7,0.9c16.4,1.8,32.7,3.5,49.1,5.3c0.7,0.1,1.5,0.4,1.8-0.6
							c0.6,0.1,1.1,0.2,1.7,0.3c0.2,1.1,1.2,0.8,1.9,0.9c13.3,1.5,26.7,2.9,40,4.3c0.7,0.1,1.6,0.4,2.2-0.3c2.7,0.2,5.3,0.7,8,0.6
							c0.1,1.1,0.9,0.9,1.6,1c14.3,1.5,28.6,3.1,42.9,4.6c0.7,0.1,1.5,0.4,1.8-0.6c1.1,0.1,2.3,0.2,3.4,0.4c0.1,1.1,0.9,0.9,1.6,1
							c13.6,1.5,27.2,2.9,40.7,4.4c0.7,0.1,1.5,0.4,1.8-0.6c0.3,0,0.6,0.1,0.9,0.1C481.7,225.2,483.8,224.7,485.9,224.5z"/>
						<path fill="#F5F0BC" d="M278.2,201.7c-0.5,1.3-1.6,0.6-2.4,0.5c-20.8-2.2-41.7-4.5-62.5-6.7c-3-0.3-3-0.3-3.3,2.6
							c-9.2,85-18.3,170.1-27.5,255.1c-0.5,4.4-1.6,8.6-1.8,13c-0.7,15.3-3,30.5-4.6,45.8c-0.4,4.1-1.4,8.1-1.6,12.2
							c-0.8,14.1-2.8,28.1-4.2,42.2c-0.1,1.2-0.1,2.4-0.6,3.5c-0.2,0.4-0.3,0.9,0.2,1.1c0.7,0.2,0.8-0.4,0.9-0.9
							c0.1-0.8,0.2-1.7,0.3-2.5c0.1-0.7,0.1-1.3,1-1.3c0.2,1.3-1.1,2.8,0.5,3.9c-0.1,1.2-0.3,2.5-0.4,3.7
							c-0.8,7.2-1.6,14.5-2.4,21.7c-0.4-0.1-0.7-0.3-0.7-0.7c0.5-2.6-0.7-5.1-0.1-7.7c0.6-3.1,0.7-6.3,1.1-9.5
							c0.1-1.3,0.3-2.5,0.4-3.8c0-0.4,0.2-1-0.3-1.1c-0.6-0.1-0.8,0.4-0.9,0.9c-0.1,0.5-0.1,1-0.2,1.5c-0.4,2.3,0.4,4.7-0.4,7
							c-0.8,2.3-0.7,4.7-1.2,7.1c-0.4,2.2-0.1,4.5-0.6,6.7c-0.4,1.3,0.4,2,1.8,2.2c5.9,0.6,11.8,1.3,17.8,1.9c0.4,0,0.9,0.3,1.1-0.4
							c-0.1-0.4-0.5-0.5-0.8-0.4c-3.8,0.5-7.3-1-11-1.2c-0.8-0.1-1.8,0-2.1-1c3,0.1,5.9,0.9,8.8,1.3c7.6,0.9,15.2,1.5,22.9,2.2
							c11.2,1,22.3,2.1,33.4,3.9c4.4,0.7,8.9,0.8,13.3,1.2c11.2,1,22.3,2.4,33.5,3.5c2.2,0.2,4.1,1.4,6.4,1.6c2.7,0.3,5.3-0.3,8,0
							c13.7,1.5,27.4,3,41.1,4.4c0.8,0.1,1.8,0,2.1,1c-14.3-1.5-28.6-3.1-43-4.6c0.4,1.2,1.2,1,1.9,1.1c7.7,0.8,15.4,1.7,23,2.5
							c0.7,0.1,1.8-0.3,2.1,0.9c-12.8-1.3-25.6-2.6-38.4-4c-17.5-1.9-35-3.8-52.6-5.6c-22.7-2.4-45.3-4.9-68-7.3
							c-2-0.2-2.5-0.8-2.3-2.8c5.1-46.3,10-92.7,15-139c1.9-17.8,3.8-35.7,5.8-53.5c1.9-17.6,3.6-35.3,5.8-52.9
							c1.4-11.1,2.7-22.3,3.8-33.4c0.6-5.9,0.8-11.9,1.7-17.8c2.6-18.6,4.2-37.2,6.3-55.9c1.7-15.6,3.4-31.2,5.1-46.8
							c0.2-1.6,0.5-2.4,2.4-2.2c22,2.4,43.9,4.8,65.9,7.1C277.8,201.6,278,201.7,278.2,201.7z M214.4,602.3c0,0.1,0,0.2,0,0.4
							c4.7,0.5,9.5,1,14.2,1.5c0-0.1,0-0.2,0-0.4C223.9,603.4,219.2,602.8,214.4,602.3z M210.4,602.4c-4.8-1.7-9.9-1.1-14.9-1.6
							C200.5,601.3,205.4,601.8,210.4,602.4z"/>
						<path fill="#F5F0BC" d="M331.6,207.5c-0.3,1-1.1,0.7-1.8,0.6c-16.4-1.8-32.8-3.5-49.1-5.3c-0.6-0.1-1.5,0.1-1.7-0.9
							c13.7,1.4,27.4,2.9,41.1,4.3C324,206.6,327.8,207,331.6,207.5z"/>
						<path fill="#F5F0BC" d="M431.7,218.2c-0.3,1-1.1,0.7-1.8,0.6c-14.3-1.5-28.6-3.1-42.9-4.6c-0.7-0.1-1.5,0.1-1.6-1
							C400.9,214.9,416.3,216.6,431.7,218.2z"/>
						<path fill="#F5F0BC" d="M377.4,212.7c-0.7,0.7-1.5,0.4-2.2,0.3c-13.3-1.4-26.7-2.9-40-4.3c-0.7-0.1-1.7,0.2-1.9-0.9
							c12.5,1.3,25.1,2.6,37.6,3.9C373.1,211.9,375.4,211.8,377.4,212.7z"/>
						<path fill="#F5F0BC" d="M479.1,223.4c-0.3,1-1.1,0.7-1.8,0.6c-13.6-1.5-27.2-2.9-40.7-4.4c-0.7-0.1-1.5,0.1-1.6-1
							c11.3,1.2,22.6,2.4,33.9,3.6C472.4,222.6,475.7,223,479.1,223.4z"/>
						<path fill="#CCDEC7" d="M343,615.9c-0.4-1-1.3-1-2.1-1c-13.7-1.5-27.4-2.9-41.1-4.4c-2.7-0.3-5.3,0.3-8,0
							c-2.2-0.2-4.1-1.4-6.4-1.6c-11.2-1.1-22.3-2.5-33.5-3.5c-4.4-0.4-8.9-0.5-13.3-1.2c-11.1-1.8-22.2-2.9-33.4-3.9
							c-7.6-0.7-15.3-1.3-22.9-2.2c-2.9-0.3-5.8-1.2-8.8-1.3c-0.7,0.2-1.4,0.5-2,0.5c-1.1,0-2.2-0.2-1.7-1.8
							c0.8-7.2,1.6-14.5,2.4-21.7c0,0.4,0.1,0.8,0.1,1.3c-0.7,6.4-1.3,12.7-2,19.1c-0.2,1.5,0.3,2,1.7,2.1c4.7,0.5,9.4,1.1,14.2,1.6
							c16.8,1.8,33.6,3.6,50.4,5.4c20.3,2.2,40.5,4.3,60.8,6.5c33.5,3.6,66.9,7.2,100.4,10.8c16.3,1.7,32.6,3.4,48.9,5.2
							c1.7,0.2,2.3-0.2,2.5-2c1.1-11,2.4-22,3.6-33c1.9-17.8,3.8-35.6,5.8-53.4c2-18.7,4.1-37.4,6.1-56.1
							c1.9-17.4,3.8-34.9,5.6-52.3c1.9-18.1,3.9-36.2,5.8-54.2c1.9-17.8,3.9-35.6,5.8-53.4c2.3-22.3,4.8-44.6,7.4-66.9
							c0.3-2.2,0.4-4.4,0.6-6.6c0.6-6.3,1.2-12.6,1.7-18.9c0.2,0,0.3,0,0.5,0c1.3,0.5,0.6,1.6,0.6,2.4c0,0.9-0.2,1.8-0.3,2.7
							c-0.7,0.5-0.5,1.3-0.6,2c-1.1,10-2.2,20-3.2,30c-0.1,0.7-0.4,1.4,0.2,2c-4,36.8-7.9,73.7-11.9,110.5c-1.4,0.6-1.4,1.3-0.2,2.2
							c-0.1,0.5-0.1,1.1-0.2,1.6c-1.8,2.2-1.3,4.8-1.4,7.4c0,0.1,0.3,0.2,0.5,0.3c-0.1,1-0.2,1.9-0.3,2.9c-0.5,0.1-0.8,0.2-0.9,0.7
							c-0.3,2.3-0.5,4.6-0.7,6.9c-0.1,0.5,0.3,0.8,0.7,0.9c-3.2,29.4-6.3,58.7-9.5,88.1c-0.9,3.6-1.4,7.2-1.2,10.9
							c0.2,2.5-1.5,4.7-1.5,7.1c-0.1,3.4,0.1,6.7-0.7,10c-0.7,2.6,0,5.4-0.5,8c-0.7,6.4-1.4,12.7-2.1,19.1c-1.5,2.3-1.5,4.9-1.6,7.5
							c-0.3,6-0.5,11.9-1.9,17.8c-0.2,1-0.6,2.1,0,3.1c0.6,0.6,0.2,1.3,0.2,2c-0.4,3.9-0.8,7.7-1.2,11.6c-0.6,3.2-1.5,6.3-1.9,9.5
							c-0.8,7.6-1.6,15.2-2.5,22.8c-0.1,0.6-0.2,1.3,0.6,1.6c-0.1,0.7-0.2,1.4-0.2,2.1c0.2,3.8-2.7,4.6-5.5,3.3
							c-33.1-3.6-66.2-7.1-99.2-10.7c-0.9-0.1-1.8-0.2-2.6,0.5l-0.2,0L343,615.9z"/>
						<path fill="#F5F0BC" d="M450.9,620.7c-0.9-0.2-0.7-0.9-0.6-1.6c0.8-7.6,1.7-15.2,2.5-22.8c0.3-3.2,1.3-6.4,1.9-9.5
							c0.6,0.1,1.1,0.1,1.7,0.2c-0.2,1.8-0.4,3.7-0.6,5.5c-0.1,0.7-0.4,1.5,0.6,1.8c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.8-0.2,1.3
							c-1.3,0.2-0.9,1.3-1,2.1c-0.8,6.9-1.5,13.9-2.2,20.8C452.7,620.2,452.5,621.1,450.9,620.7z"/>
						<path fill="#FBEFB2" d="M466.6,498.9c-0.6-0.1-0.8-0.5-0.7-1.1c0.2-1.6,0.3-3.2,0.5-4.8c0.1-0.6,0.3-0.9,0.9-0.9
							c0,0,0.1,0,0.1,0c0,0.1,0,0.3,0,0.4C467.1,494.7,466.8,496.8,466.6,498.9z"/>
						<path fill="#CEC69D" d="M467.3,492.6c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.2,0.1,0.3C467.5,492.5,467.4,492.5,467.3,492.6z"/>
						<path fill="#F5F0BC" d="M455.6,573.2c-0.7-1-0.3-2.1,0-3.1c1.4-5.9,1.6-11.8,1.9-17.8c0.1-2.6,0.1-5.2,1.6-7.5
							c0.8-0.1,1.7-0.1,2.5-0.2c-0.1,0.6-0.1,1.1-0.2,1.7c-1,0.1-0.9,1-0.9,1.6c-0.7,6.6-1.4,13.3-2.1,19.9
							c-0.1,0.7-0.4,1.5,0.6,1.8c-0.6,5.8-1.2,11.6-1.9,17.4c-0.3,0-0.5-0.1-0.8-0.1c0.4-3.6,0.7-7.3,1.2-10.9
							c0.3-1.9-0.6-3.4-0.7-5.4C456,571.6,455.7,572.4,455.6,573.2z"/>
						<path fill="#FBEFB2" d="M461.6,544.7c-0.8,0.1-1.7,0.1-2.5,0.2c0.7-6.4,1.4-12.7,2.1-19.1c1.5,0,1.7,0.7,1.5,2
							c-0.6,4.7-1,9.4-1.5,14.1c-0.1,0.7-0.6,1.6,0.5,2C461.7,544.1,461.6,544.4,461.6,544.7z"/>
						<path fill="#FBEFB2" d="M459,569.7c-1-0.3-0.7-1.1-0.6-1.8c0.7-6.6,1.4-13.3,2.1-19.9c0.1-0.7-0.1-1.5,0.9-1.6
							C460.6,554.2,459.8,561.9,459,569.7z"/>
						<path fill="#FBEFB2" d="M456.3,587c0.3,0,0.5,0.1,0.8,0.1c-0.3,2.4-0.5,4.8-0.8,7.2c-1-0.3-0.6-1.1-0.6-1.8
							C455.9,590.7,456.1,588.8,456.3,587z"/>
						<path fill="#F5F0BC" d="M485.9,224.5c-2.1,0.2-4.2,0.8-5.9-1C482,223.8,484,223.4,485.9,224.5z"/>
						<path fill="#CCDEC7" d="M172.6,570.2c-1.6-1.1-0.2-2.6-0.5-3.9c0-0.4,0.1-0.9,0.1-1.3c0-0.4,0.1-0.8,0.1-1.3
							c1.2-11.4,2.5-22.9,3.7-34.3c1.3-0.2,0.9-1.3,1-2c0.9-8.3,1.8-16.6,2.7-25c3.1-28.6,6.2-57.3,9.2-85.9
							c0.1-0.8,0.8-1.9-0.5-2.4c0.5-4.4,0.9-8.7,1.4-13.1c1.2-0.1,1-1.1,1-1.8c-0.3-2.9,0.7-5.6,0.9-8.4c0.9-11.3,3.3-22.5,2.8-33.9
							c-0.1-2.3,1.3-4.1,1.5-6.3c1.9-18,3.9-36.1,5.8-54.1c0.1-1.1,0.1-2.1-0.1-3.2c0.1-0.4,0.1-0.9,0.2-1.3
							c0.8-0.6,0.6-1.4,0.6-2.2c0.6-4.9,1.1-9.9,1.6-14.8c2.5-23,4.9-45.9,7.4-68.9c0.1-0.8,0.7-1.9-0.5-2.4
							c0.3-1.3,0.8-2.5,0.5-3.9c-0.2-1,0.8-1.6,1.7-1.7c0,0.3,0,0.5-0.1,0.8c-0.5,0.7-0.5,1.5-0.6,2.2c-1.5,14-3.1,28.1-4.6,42.1
							c-1.8,16.9-3.7,33.9-5.5,50.8c-2.1,19.1-4.1,38.1-6.2,57.2c-1.8,17-3.7,34-5.5,51.1c-4,36.6-7.9,73.3-11.9,109.9
							c-2,18.9-4.1,37.7-6.1,56.6C172.9,569.4,172.7,569.8,172.6,570.2z"/>
						<path fill="#F5F0BC" d="M188.6,414.1c1.3,0.6,0.6,1.6,0.5,2.4c-3.1,28.6-6.1,57.3-9.2,85.9c-0.9,8.3-1.8,16.6-2.7,25
							c-0.1,0.7,0.3,1.8-1,2c-0.6-1.7-0.2-3.5-0.3-5.2c0-0.3,0-0.6,0.1-0.8c1-5.3,0.5-10.8,2.5-16c1.1-2.9,0.2-6.2,0.6-9.3
							c0.6-4.6,0.8-9.3,1.2-14c0.2-2.6,2-5.1,1.5-7.7c-0.9-4.3,0.6-8.4,0.6-12.6c0-1.6,1.2-2.6,1.4-4.1c1.2-12.2,2.6-24.5,3.9-36.7
							c0.2-2.3-0.1-4.7,0.3-7C188,415.3,187.8,414.5,188.6,414.1z"/>
						<path fill="#F5F0BC" d="M201.9,293.4c0.2,1,0.3,2.1,0.1,3.2c-2,18-3.9,36.1-5.8,54.1c-0.2,2.2-1.6,4-1.5,6.3
							c0.5,11.4-2,22.6-2.8,33.9c-0.2,2.8-1.2,5.5-0.9,8.4c0.1,0.7,0.2,1.7-1,1.8c0-0.4,0-0.7,0.1-1.1c1.1-9.1,1.2-18.2,2.2-27.3
							c0.4-3.4,0.3-6.9,1.4-10.2c0.5-1.6,0-3.2,0.1-4.9c0.2-3,1-5.9,1.5-8.8c1.2-7.2,1.6-14.5,2.8-21.7c0.7-3.7,0.9-7.5,0.9-11.1
							c-0.1-3.8,1.5-7.2,1.5-11c0-3.7,0.7-7.5,1.2-11.2C201.5,293.7,201.7,293.6,201.9,293.4z"/>
						<path fill="#F5F0BC" d="M211.2,203.9c1.3,0.5,0.6,1.6,0.5,2.4c-2.4,23-4.9,45.9-7.4,68.9c-0.5,4.9-1.1,9.9-1.6,14.8
							c-0.1,0.7,0.1,1.6-0.6,2.2c-0.6-0.7-0.3-1.5-0.2-2.2c1.3-12.1,2.4-24.2,3.9-36.2c1.2-9.8,1.9-19.7,3.1-29.6
							C209.8,217.3,210.4,210.6,211.2,203.9z"/>
						<path fill="#F5F0BC" d="M228.3,199.8c16.3,1.7,32.5,3.5,48.8,5.2c-0.7,0.6-1.5,0.2-2.2,0.2c-7.2-0.8-14.3-1.5-21.5-2.3
							c-0.7-0.1-1.5-0.4-2.2,0.2c-0.8-0.1-1.7-0.2-2.5-0.3c-0.1-0.3-0.3-0.3-0.4,0c-3.8-0.4-7.6-0.8-11.4-1.2
							c-0.6-0.9-1.7-0.6-2.5-0.8C232.2,200.4,230.1,200.8,228.3,199.8z"/>
						<path fill="#F5F0BC" d="M488.9,268.1c-0.5-0.6-0.3-1.4-0.2-2c1.1-10,2.1-20,3.2-30c0.1-0.7,0-1.4,0.6-2
							C491.3,245.5,490.1,256.8,488.9,268.1z"/>
						<path fill="#CCDEC7" d="M251.1,203.1c0.7-0.6,1.5-0.2,2.2-0.2c7.2,0.8,14.3,1.5,21.5,2.3c0.7,0.1,1.5,0.4,2.2-0.2
							c0.3,0,0.5,0.1,0.8,0.1c0,0.3,0,0.5,0,0.8C268.9,205,260,204,251.1,203.1z"/>
						<path fill="#CCDEC7" d="M228.3,199.8c1.8,1.1,4,0.6,5.9,1.1c0.9,0.2,1.9-0.2,2.5,0.8c-4.2-0.1-8.3-0.4-12.4-1.3
							c0.9-0.3,2.1,0.8,2.7-0.6C227.5,199.7,227.9,199.7,228.3,199.8z"/>
						<path fill="#FBFBF9" d="M213.4,199c0-0.3,0-0.5,0.1-0.8c3.7,0.4,7.3,0.8,11,1.2c0,0.1,0,0.3,0,0.4c-3.3-0.2-6.5-1.1-9.8-0.7
							C214.2,199,213.8,199,213.4,199z"/>
						<path fill="#F5F0BC" d="M224.4,199.8c0-0.1,0-0.3,0-0.4c0.9,0.1,1.7,0.2,2.6,0.3c-0.6,1.4-1.8,0.3-2.7,0.6l0,0
							C224.4,200.1,224.4,199.9,224.4,199.8z"/>
						<path fill="#F5F0BC" d="M476.7,380.9c-1.2-0.9-1.1-1.6,0.2-2.2C476.9,379.4,476.8,380.1,476.7,380.9z"/>
						<path fill="#F5F0BC" d="M172.4,563.7c0,0.4-0.1,0.8-0.1,1.3c-0.4-0.1-0.8-0.3-0.7-0.8C171.7,563.7,172.1,563.7,172.4,563.7z"
							/>
						<path fill="#FBEFB2" d="M169.8,595.6c-0.5,1.6,0.6,1.8,1.7,1.8c0.7,0,1.4-0.3,2-0.5c0.4,1,1.3,1,2.1,1c3.7,0.3,7.2,1.7,11,1.2
							c0.3,0,0.7,0,0.8,0.4c-0.2,0.6-0.7,0.4-1.1,0.4c-5.9-0.6-11.8-1.3-17.8-1.9c-1.5-0.1-2.2-0.9-1.8-2.2c0.6-2.2,0.2-4.5,0.6-6.7
							c0.4-2.4,0.4-4.8,1.2-7.1c0.7-2.2-0.1-4.7,0.4-7c0.1-0.5,0.1-1,0.2-1.5c0.1-0.5,0.3-1.1,0.9-0.9c0.6,0.1,0.4,0.7,0.3,1.1
							c-0.1,1.3-0.3,2.5-0.4,3.8c-0.3,3.2-0.5,6.4-1.1,9.5c-0.5,2.6,0.6,5.2,0.1,7.7C169,595.3,169.4,595.5,169.8,595.6z"/>
						<path fill="#FBEFB2" d="M475.7,390.2c1.3,0,1.8,0.4,1.6,1.8c-0.4,2.8-0.6,5.6-0.9,8.5c-3.1,29-6.2,57.9-9.4,86.9
							c-0.1,1.2,0.1,2.8-2,2.5c3.2-29.4,6.3-58.7,9.5-88.1c0.3-2.9,0.6-5.7,0.9-8.6C475.5,392.1,475.6,391.1,475.7,390.2z"/>
						<path fill="#FBEFB2" d="M455.6,573.2c0.1-0.9,0.4-1.6,1.2-2.5c0.1,2,1,3.5,0.7,5.4c-0.5,3.6-0.8,7.3-1.2,10.9
							c-0.6-0.1-1.1-0.1-1.7-0.2c0.4-3.9,0.8-7.7,1.2-11.6C455.9,574.6,456.2,573.9,455.6,573.2z"/>
						<path fill="#F5F0BC" d="M475.4,393.1c-0.3,2.9-0.6,5.7-0.9,8.6c-0.4-0.2-0.7-0.4-0.7-0.9c0.2-2.3,0.5-4.6,0.7-6.9
							C474.6,393.3,475,393.1,475.4,393.1z"/>
						<path fill="#CCDEC7" d="M488.9,228.6c-10.7-1.1-21.5-2.2-32.2-3.3c-17.1-1.8-34.2-3.7-51.3-5.5c-18.8-2-37.5-4-56.3-6
							c-4.9-0.5-9.9-0.9-14.8-1.4c-0.8-0.1-1.6,0-2.2-0.6c14.3,0.6,28.4,2.7,42.6,4.2c37.2,3.9,74.4,8,111.6,12
							C487.2,228.1,488.1,227.8,488.9,228.6z"/>
						<path fill="#CCDEC7" d="M296.5,208c-6.1-0.3-12.3-0.8-18.3-2c5.7-0.2,11.2,0.9,16.8,1.4C295.5,207.4,296.1,207.4,296.5,208z"
							/>
						<path fill="#CCDEC7" d="M332,211.8c-11.6-1.2-23.2-2.5-34.8-3.7c0.6-0.6,1.3-0.3,2-0.2c10.3,1.1,20.6,2.2,30.8,3.3
							C330.8,211.2,331.6,211.1,332,211.8z"/>
						<path fill="#CCDEC7" d="M491.8,229c-0.4-0.1-0.8-0.2-1.2-0.2C491,228.4,491.5,228.3,491.8,229z"/>
						<path fill="#CCDEC7" d="M248.6,202.8c-0.1,0-0.3,0-0.4,0C248.3,202.5,248.4,202.5,248.6,202.8z"/>
						<path fill="#CCDEC7" d="M224.3,200.2c-3.2-0.4-6.5-0.8-9.7-1.2c3.3-0.4,6.5,0.5,9.8,0.7C224.4,199.9,224.4,200.1,224.3,200.2z
							"/>
						<path fill="#0A4E98" d="M191.9,398.2c5.7-52.7,11.3-105.4,17-158.1c1.3-12.4,2.7-24.9,4.1-37.3c0.3-2.6,0.7-3,3.2-2.7
							c90.5,9.7,181.1,19.5,271.6,29.2c3.2,0.3,3.5,0.6,3.1,3.9c-5.1,47-10.1,94.1-15.2,141.1c-5.9,54.3-11.7,108.6-17.5,162.9
							c-3.1,28.4-6.1,56.8-9.2,85.2c-0.3,2.8-0.6,3.1-3.5,2.8c-40.2-4.3-80.4-8.6-120.6-13c-50.6-5.4-101.1-10.9-151.7-16.3
							c-2.1-0.2-2.5-0.8-2.3-2.9C177.9,528.1,184.9,463.2,191.9,398.2C191.9,398.2,191.9,398.2,191.9,398.2z M218,205.3
							c-0.7,1.4,0,3-1,4.3c-0.1,0.4-0.1,0.7,0.2,1c0,0.3-0.1,0.5-0.1,0.8c-0.6,0.6-0.5,1.3-0.6,2c-1.8,16.1-3.4,32.2-5.3,48.2
							c-1.5,13.2-2.6,26.4-4.5,39.5c-1.8,12-2.5,24.1-3.6,36.2c-0.4,4.2-1.2,8.3-1.7,12.4c-8.6,79.3-17.1,158.6-25.7,237.9
							c-0.1,1.2-0.2,2.4,0.1,3.6c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0.2,0,0.2c1.7,0.7,3.4,0.6,5.2,0.8c0.4,0,0.9,0.1,1.3,0.1
							c0.6,0.1,1.3,0.2,1.9,0.2c78.1,8.4,156.2,16.8,234.3,25.2c8.3,0.9,16.5,1.7,24.8,2.6c1.2,0.4,1.5-0.4,1.6-1.3
							c0.1-0.8,0-1.7,0.1-2.5c2.9-27.2,5.9-54.3,8.8-81.5c3.8-35.6,7.7-71.2,11.5-106.7c5.6-52.2,11.3-104.5,16.9-156.7
							c0.1-0.8,0.4-1.5,0-2.3c0.1-0.4,0.1-0.8,0.2-1.2c0.9-0.3,0.7-1.2,0.8-1.8c0.2-1.9,0.4-3.8,0.6-5.7c0.9-7.8,1.5-15.7,2.8-23.5
							c0.6-3.6,0.5-3.6-3.2-4c-50.9-5.5-101.8-11-152.7-16.4c-34.9-3.8-69.9-7.5-104.8-11.2c-1.3-0.1-2.6-0.1-3.7-0.7
							C220.4,204.1,219.3,204.8,218,205.3z"/>
						<path fill="#51727B" d="M175.9,591.2c-0.2-1.2-0.2-2.4-0.1-3.6c8.5-79.3,17.1-158.6,25.7-237.9c0.5-4.1,1.3-8.3,1.7-12.4
							c1.1-12.1,1.8-24.2,3.6-36.2c2-13.1,3.1-26.4,4.5-39.5c1.8-16.1,3.5-32.2,5.3-48.2c0.1-0.7,0-1.4,0.6-2
							c-1,9.2-1.9,18.5-2.9,27.7c-0.9,8.9-1.8,17.8-2.8,26.7c-1.1,9.9-2.2,19.8-3.3,29.6c-2,18.1-4,36.2-5.9,54.2
							c-2,18.1-3.9,36.2-5.9,54.2c-2,18.2-3.9,36.4-5.9,54.7c-1.9,17.9-3.9,35.7-5.8,53.6c-1.5,13.8-2.9,27.6-4.4,41.3
							c-0.6,5.8-1.2,11.6-1.9,17.4c-0.1,0.9-0.2,1.7-0.3,2.6c-0.8,5.9-1.4,11.9-1.9,17.8C176.2,591.2,176.1,591.2,175.9,591.2z"/>
						<path fill="#51727B" d="M482.1,269.5c0.4,0.8,0.1,1.5,0,2.3c-5.6,52.2-11.3,104.5-16.9,156.7c-3.8,35.6-7.7,71.2-11.5,106.7
							c-2.9,27.2-5.9,54.3-8.8,81.5c-0.1,0.8,0,1.7-0.1,2.5c-0.1,0.9-0.4,1.7-1.6,1.3c-0.7-0.5-1.5-0.4-2.2-0.5
							c-33.3-3.6-66.6-7.2-99.9-10.8c-52.1-5.6-104.2-11.2-156.3-16.8c-0.8-0.1-1.7-0.4-2.5,0c-0.4,0-0.9-0.1-1.3-0.1
							c-1.3-1-3-0.1-4.3-1.1c1.6,0.1,3.2,0.3,4.8,0.4c0.7,0.1,1.4,0.2,2.1,0.3c31,3.3,62,6.6,92.9,9.9c17.1,1.8,34.2,3.6,51.2,5.5
							c5.1,0.6,10.1,1.1,15.2,1.7c8.7,0.9,17.5,1.9,26.2,2.8c14.6,1.6,29.2,3.1,43.7,4.7c9.7,1.1,19.3,2.1,29,3.2
							c1.9,0.2,2.4-0.3,2.6-2.1c2.9-26.9,5.8-53.8,8.7-80.7c2-18.2,3.9-36.4,5.9-54.6c2-18.1,3.9-36.3,5.9-54.4
							c1.9-17.9,3.8-35.9,5.8-53.8c3.8-34.1,7.4-68.2,11.1-102.3C481.8,271,482,270.3,482.1,269.5z"/>
						<path fill="#CCDEC7" d="M482.1,269.5c-0.1,0.8-0.3,1.5-0.4,2.3c-3.7,34.1-7.3,68.2-11.1,102.3c-2,17.9-3.8,35.9-5.8,53.8
							c-2,18.1-3.9,36.3-5.9,54.4c-2,18.2-3.9,36.4-5.9,54.6c-2.9,26.9-5.9,53.8-8.7,80.7c-0.2,1.8-0.6,2.4-2.6,2.1
							c-9.7-1.2-19.3-2.2-29-3.2c0.7-0.5,1.5-0.2,2.3-0.1c8.7,0.9,17.5,1.9,26.2,2.8c0.9,0.1,2.1,0.7,2.2-1.1
							c0.1-0.6,0.2-1.3,0.2-1.9c4.2-39.2,8.4-78.4,12.6-117.6c0.7-0.6,0.5-1.4,0.6-2.2c4.5-41.3,8.9-82.6,13.4-123.9
							c3.8-35.1,7.6-70.2,11.3-105.3c0.2,0,0.3,0,0.5,0c0,0.3,0.1,0.6,0.1,0.9C482.2,268.7,482.2,269.1,482.1,269.5z"/>
						<path fill="#CCDEC7" d="M211.5,265.7c0.9-8.9,1.8-17.8,2.8-26.7c1-9.2,1.9-18.5,2.9-27.7c0-0.3,0.1-0.5,0.1-0.8
							c0.2-0.4,0.2-0.8-0.2-1c1-1.3,0.3-2.9,1-4.3c2.6-0.1,5.2,0.5,7.8,0.8c17.8,1.9,35.6,3.8,53.4,5.7c18.3,2,36.6,3.9,54.9,5.9
							c18.1,1.9,36.1,3.9,54.2,5.8c31.3,3.3,62.7,6.7,94,10c3.8,0.4,3.8-0.5,3,4.2c-0.8-0.8,0.3-1.9-0.5-2.6c0,0,0-0.1,0-0.1
							c-0.1-1.2-1.1-0.9-1.8-1c-11.4-1.2-22.8-2.5-34.2-3.7c-0.7-0.1-1.5-0.4-1.8,0.6c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6-0.1-0.8-0.1
							c-0.1-1.1-0.9-0.9-1.6-1c-1.7-0.2-3.3-0.3-5-0.5c-12.5-1.4-25-2.7-37.4-4.1c-0.6-0.1-1.5-0.4-1.8,0.6l0,0
							c-0.4-0.1-0.8-0.1-1.2-0.2c-0.6-0.6-1.4-1-2.3-1c-3.4,0-6.8-0.5-10.1-1c-9-1.3-18-1.9-27-3c-0.7-0.1-1.5-0.4-1.8,0.6
							c-1-0.1-1.9-0.2-2.9-0.3c-1.2-1-2.7-1.1-4.2-1.3c-4.3-0.6-8.7-0.1-13-1.2c-1.3-0.3-3-0.6-4.5,0.1c-0.3,0-0.5-0.1-0.8-0.1
							c-1.5-2-3.7-0.8-5.5-1.4c-0.5-0.1-0.7,0.3-0.9,0.7c-0.1,0-0.3,0-0.4,0c-0.1-0.9-0.7-0.9-1.4-1c-8.1-0.9-16.3-2.1-24.4-2.5
							c-4.9-0.3-9.8-1-14.7-1.4c-6.6-0.5-13.2-1.7-19.8-2.4c-11.9-1.3-23.8-2.7-35.7-3.7c-1.9-0.2-3.9-0.6-5.8,0.1
							c-0.6-0.1-1.3-0.1-1.9-0.2c-3.5-0.3-3.1-0.7-3.5,2.8c-2.9,27-5.9,54.1-8.8,81.1c-0.6-0.7-0.2-1.5-0.1-2.2
							c0.7-6.8,1.5-13.6,2.2-20.4C211.6,267.3,211.9,266.5,211.5,265.7z"/>
						<path fill="#51727B" d="M485.3,237.7c0.8-4.7,0.8-3.8-3-4.2c-31.3-3.4-62.7-6.7-94-10c-18.1-1.9-36.1-3.9-54.2-5.8
							c-18.3-2-36.6-3.9-54.9-5.9c-17.8-1.9-35.6-3.8-53.4-5.7c-2.6-0.3-5.2-0.9-7.8-0.8c1.3-0.5,2.4-1.2,4-0.4
							c1.1,0.5,2.5,0.5,3.7,0.7c34.9,3.8,69.9,7.5,104.8,11.2c50.9,5.5,101.8,10.9,152.7,16.4c3.7,0.4,3.8,0.4,3.2,4
							c-1.3,7.8-1.9,15.7-2.8,23.5c-0.2,1.9-0.4,3.8-0.6,5.7c-0.1,0.7,0.1,1.5-0.8,1.8c0-0.3-0.1-0.6-0.1-0.9l0,0
							c0.6-1.1,0.6-2.3,0.4-3.6c0.6-5.6,1.2-11.2,1.8-16.8c0.5-2.8,0.7-5.7,0.9-8.6C485.2,238.2,485.3,238,485.3,237.7z"/>
						<path fill="#51727B" d="M217,209.6c0.4,0.3,0.4,0.6,0.2,1C216.9,210.3,216.9,209.9,217,209.6z"/>
						<path fill="#F5F0BC" d="M443.5,618.2c0,1.7-1.3,1.1-2.2,1.1c-8.8-0.9-17.5-1.9-26.2-2.8c-0.7-0.1-1.5-0.4-2.3,0.1
							c-14.6-1.6-29.2-3.1-43.7-4.7c-8.7-0.9-17.5-1.9-26.2-2.8c-1.8-1-3.8-0.8-5.8-1c-3.1-0.3-6.2-1.1-9.4-0.7
							c-17.1-1.8-34.2-3.6-51.2-5.5c-31-3.3-62-6.6-92.9-9.9c-0.7-0.1-1.4-0.2-2.1-0.3c-0.6-0.8-1.6-0.7-2.3-0.7
							c-2,0-2.3-0.8-2.1-2.6c0.6-4.3,1-8.5,1.4-12.8c0.1-0.7,0.4-1.5-0.2-2.2c0.1-0.9,0.2-1.7,0.3-2.6c0.3,0,0.6,0.1,0.8,0.1
							c-0.6,5.6-1.2,11.1-1.8,16.7c-0.3,2.6-0.3,2.6,2.3,2.3c0,0.5,0.2,0.9,0.7,0.9c16.5,1.8,32.9,3.8,49.4,5.3
							c16.4,1.5,32.7,4.6,49.2,4.6c3.9,0,7.8,1.5,11.6,1.9c14.1,1.4,28.1,4.2,42.4,3.9c3.4-0.1,6.8,1.5,10.3,1.1c0.3,0,0.8,0,1,0.2
							c1.3,1.3,3,1,4.6,1.3c6.4,1,12.8,1,19.2,2c4.6,0.7,9.2,0.8,13.8,0.8c5.6,0.1,11.2,1.6,16.7,2.4
							C413.7,616.1,428.5,617.7,443.5,618.2z"/>
						<path fill="#E9E4B6" d="M179.3,570.9c-0.3,0-0.6-0.1-0.8-0.1c0.6-5.8,1.3-11.6,1.9-17.4c1.5-13.8,2.9-27.6,4.4-41.3
							c1.9-17.9,3.9-35.7,5.8-53.6c2-18.2,3.9-36.4,5.9-54.7c1.9-18.1,3.9-36.2,5.9-54.2c2-18.1,3.9-36.2,5.9-54.2
							c1.1-9.9,2.2-19.8,3.3-29.6c0.4,0.8,0.1,1.5,0,2.3c-0.7,6.8-1.5,13.6-2.2,20.4c-0.1,0.7-0.4,1.5,0.1,2.2
							c-0.1,0.7-0.2,1.5-0.2,2.2c-0.9,2.2-1.1,4.4-0.7,6.7c-0.4,3.6-0.8,7.1-1.1,10.7c-0.9,2.9-1.3,5.8-0.9,8.8
							c-0.3,2.6-0.5,5.1-0.8,7.7c-0.6,0.4-0.5,1.1-0.6,1.7c-0.9,7.9-1.7,15.7-2.5,23.6c-0.1,0.6-0.3,1.3,0.2,1.8
							c-0.9,8.5-1.8,17-2.7,25.6c-0.1,0.8-0.5,1.7,0.1,2.5c-2.1,10.9-2.6,22-4,33C190.5,466.9,184.9,518.9,179.3,570.9z"/>
						<path fill="#E9E4B6" d="M178.2,573.4c0.6,0.7,0.2,1.5,0.2,2.2c-0.4,4.3-0.8,8.6-1.4,12.8c-0.2,1.8,0.1,2.7,2.1,2.6
							c0.8,0,1.7-0.1,2.3,0.7c-1.6-0.1-3.2-0.3-4.8-0.4c-0.1-0.1-0.2-0.1-0.2-0.1C176.8,585.2,177.4,579.3,178.2,573.4z"/>
						<path fill="#FBEFB2" d="M179.3,570.9c5.6-52,11.2-104,16.8-156c0.3,0,0.6,0.1,0.9,0.1c-2.9,27.3-5.9,54.7-8.8,82
							c-0.1,1-1.3,2.6,1.1,3.2c0.8,0.2,1.2,1.3,2.8,1.1c2.9-0.4,6,0,9,0.1c7.6,0.3,15.1,1.5,22.7,2.3c0.6,0.1,1.4,0,1.8,0.4
							c2.4,1.8,4.8,1,7.3,0c4.3,0.4,8.5,0.6,12.7,1.5c0.2,0.4,0.5,0.4,0.8,0.1c2.3,0.2,4.5,0.5,6.8,0.7c0.1,0.2,0.3,0.3,0.4,0
							c1.1,0.1,2.3,0.2,3.4,0.3c0.4,0.5,0.8,0.6,1.3,0.2c0.4,0,0.8,0.1,1.3,0.1c2.6,1.1,5.4,0.9,8.1,1.2c1.4,0.2,2.8,0.8,4.1,0.1
							c0.6,0.1,1.2,0.1,1.8,0.2c1.1,0.8,2.2,0.3,3.4,0.3c0.3,0,0.6,0.1,0.8,0.1c0.7,0.3,1.4,0.4,2.1,0.2c1,0.1,1.2-0.6,1.3-1.3
							c0.5-0.4,1.2-0.9,0.1-1.4c0.2-2.2,0.5-4.5,0.7-6.7c0.8-3,0.8-6,1.2-9c1.1-9.4,2.1-18.8,3.2-28.2c0.9-0.1,0.8-0.9,0.9-1.5
							c0.6-5.9,1.3-11.8,1.9-17.7c0.1-0.6,0.3-1.3-0.6-1.6c0.6-5.5,1.2-11,1.7-16.6c1.4-0.2,0.6-1.5,1.2-2.1
							c1.2-1.5,0.2-2.4-0.8-3.4c-0.2-0.3-0.4-0.6-0.6-0.9c-0.3-0.6-0.9-0.5-1.5-0.6c-7.7-0.8-15.3-1.7-23-2.5
							c-4.9-0.5-9.8-1.1-14.7-1.6c-15.2-1.6-30.3-3.2-45.5-4.9c-1.9-0.2-4.6,0.6-4.6-3c-0.5,1.3-0.4,2.8-2.3,2.3
							c1.6-15.1,3.2-30.1,4.9-45.2c0.1-0.8-0.3-2,1.1-2.2c1.4,0,2.8,0.6,4.2,0.2c1.1,0.1,2.3,0.2,3.4,0.4c0.4,0.4,0.9,0.4,1.4,0.4
							c22.4,2.4,44.9,4.8,67.3,7.2c3,0.3,6,0.7,9.1,0.9c0.8,0.1,1.7,0.5,2.4-0.2c0.4,0,0.8,0.1,1.3,0.1c0.8,0.9,1.9,0.7,2.9,0.7
							c0.5,0,1.1-0.3,0.6-1.1c0.5-6.2,1.3-12.4,2.2-18.6c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0.8-7.8,1.6-15.5,2.5-23.3
							c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.5,0.1-0.8c1.5-0.8,0.7-1.9,0.3-3c0.6-6.5,1.1-13,2.1-19.5
							c0.4-0.2,0.9-0.5,0.2-0.9c0.1-0.7,0.1-1.4,0.2-2.1c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1-1.1,0.2-2.3,0.4-3.4
							c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0.4-4.5,0.8-9.1,1.2-13.6c0-0.1,0-0.3,0.1-0.4c0.1-0.6,0.1-1.1,0.2-1.7
							c0.1-0.2,0.1-0.4-0.1-0.6c0,0,0-0.1,0-0.1c0-0.1-0.1-0.3-0.1-0.4c0.2-0.2,0.7-0.3,0.7-0.5c0-0.9-0.8-0.6-1.3-0.7
							c-10.9-1.2-21.9-2.4-32.8-3.5c-0.7-0.1-1.5-0.3-1.7,0.7c-8.9-1-17.7-1.9-26.6-2.9c-0.5-0.1-1-0.2-1.4,0.2
							c-0.7,0-1.4,0-2.1-0.1c-0.1,0-0.3,0-0.4,0c-1.3-0.2-2.6-0.4-3.8-0.6c-0.3-0.1-0.6-0.4-0.9-0.4c-5.9-0.7-11.8-1.3-17.8-1.9
							c-0.4,0-0.9-0.1-1.2,0.2c-0.3,0-0.6,0-0.9,0l0.1-0.2c-0.1-0.3,0.1-1-0.5-0.9c-0.4,0.1-0.4,0.6-0.4,1c-0.1,0.5-0.3,1-0.4,1.6
							c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.1,2.4-0.3,4.8-0.8,7.2c-0.6,0.1-0.7,0.6-0.8,1.1c-0.2,2.3-0.5,4.7-0.8,7
							c-0.1,0.5-0.1,1,0.5,1.2c0.1,0.9,0,1.7-0.3,2.6c-1-0.1-1.4,0.4-1.1,1.2c0.3,1.1,0.1,2.3-0.1,3.4c-1,9.6-2.1,19.3-3.1,28.9
							c-0.1,0.8-0.5,1.7,0.1,2.4c0,0.1,0,0.3,0,0.4c0,0.8-0.4,1.5,0,2.3c-0.1,0.5-0.1,1-0.2,1.6c-0.6,0.8-0.3,1.7-0.6,2.6
							c-0.6,0.4-0.5,1.1-0.6,1.7c-0.4,3.6-0.8,7.3-1.2,10.9c-0.1,0.6-0.3,1.3,0.2,1.8c0,0.4-0.1,0.8-0.1,1.3c-0.8,0.3-0.5,1-0.6,1.6
							c-0.5,1.8,0.3,3.8-0.3,5.6c-1.8,0-0.4-1.9-1.7-2.7c-0.1,1-0.2,1.8-0.3,2.5c-0.8,7.8-1.7,15.5-2.5,23.3
							c-0.1,0.7,0.1,1.6-0.7,2.1c-0.6-0.8-0.2-1.6-0.1-2.5c0.9-8.5,1.8-17,2.7-25.6c1-9,1.9-18.1,2.9-27.1c0.3-2.6,0.5-5.1,0.8-7.7
							c0.3-2.9,0.6-5.9,0.9-8.8c0.4-3.6,0.8-7.1,1.1-10.7c0.2-2.2,0.5-4.5,0.7-6.7c0.1-0.7,0.2-1.5,0.2-2.2
							c2.9-27,5.9-54.1,8.8-81.1c0.4-3.5,0-3.2,3.5-2.8c0.6,0.1,1.3,0.1,1.9,0.2c13.2,1.4,26.4,2.8,39.6,4.3c-1.7,1.2-2.8,3-2.3,4.9
							c0.5,2.1,0.3,4.3-0.4,6c-1.2,2.7,1.5,2.5,2.2,3.8c0.9,1.6,1-0.4,1.7-0.4c1.6-0.1,3.1-0.2,4.3,1.1c0.8,1,1.5,1,2.5,0.2
							c1.1-1,2.3-1,3.7-0.1c3,2,4.3,1.7,5.9-0.9c0.5-0.8,0.5-1.8,0.5-2.7c0-1.8,0.8-2.4,2.5-1.9c-0.1,0.3-0.1,0.5-0.2,0.8
							c-0.6,1.2-1.5,2.5-0.2,3.8c0.1,0.1,0.1,0.1,0.2,0.2c0.4,1.7,1.6,2.4,3.2,2.2c0.9-0.1,1.6,0.1,2.5,0.2c1.8,0.3,3.9,1.1,5.4-0.9
							c0.6-0.8,2-0.5,2.2,0.1c0.3,1.6,1.4,1,2.2,1.2c1.7,0.4,3.7-0.2,5.2,1.3c0.1,0.1,0.6,0.1,0.8,0.1c2.9-1,5.6,0,8.2,1.1
							c1.2,0.5,1.8,0.2,2.4-0.8c0.5-1.1,0.9-2.3,0.6-3.5c-0.2-1-0.2-2,0.2-2.9c1.2-2.3,0.6-4.5,0-7.1c3.1,0.3,6.1,0.7,9.1,1
							c0.1,0,0.3,0,0.4,0c0.6,0.1,1.1,0.1,1.7,0.2c-0.3,0.4-0.6,0.8-0.4,1.4l0,0c-1.6,0-3.3-0.1-5.6-0.2c2,1.1,1.1,2.2,0.7,3.3
							c0.8,0.1,1.4,0.2,2.1,0.2c0.2,0,0.4,0.1,0.6,0.1c0.1,1.3-0.4,2.4-1.3,3.3c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.2,0.1-0.3,0.2
							c-0.8,1.8-0.3,2.9,1.2,3.3c-0.3,1.5,0.5,2.5,1.7,2.7c1.9,0.3,3.8,0.9,5.8-0.1c1.3-0.6,2.6-1,2.8-2.8c1-0.2,1-1.5,2.1-1.8
							c0,1.1,1,2.3,0.4,3.1c-1.1,1.5,0.8,0.6,0.8,1.1l0.2,0l0.1,0.1c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0.1-0.2,0.1
							c0,0.1,0.1,0.2,0.1,0.3c2.1,0.2,4.3,0.5,6.4,0.7c0.1,0.2,0.1,0.6,0.3,0.6c0.4,0.1,0.5-0.3,0.6-0.6l0,0
							c0.8,0.2,1.5,0.1,2.1-0.6c0.8-1,1.5-0.7,2.2,0.3c1.6,2.2,3.1,2.4,5.2,0.7c0.4-0.3,0.6-1.1,1,0c0.1,0.3,0.6,0.5,1,0.8
							c2.2,1.8,5.1,1.2,7.5,2.2c1.9,0.8,3.8,1.4,5.2-0.9c0.4-0.6,1.6-1.1,1.9-0.2c0.8,2.4,3,2,4.1,1.6c1.9-0.7,3.3,0.1,4.8,0.7
							c1.6,0.6,3,0.6,4.1-0.9c0.6-0.8,1.4-1.7,2.2-2.3c1.8-1.3,2.5-3.2,2.8-5.3c1.1-0.7,2.3-1.7,2.1-2.9c-0.2-1.3-1.8-1.2-3-1.4
							c-1.8-0.3-3.4,1-5.2,0.4c-0.1,0-0.2-0.1-0.2-0.2c-1.2-1.5-2.8-1.1-4.3-0.8c-0.1,0-0.3,0-0.4,0l0,0c-0.1-0.4-0.5-0.5-0.9-0.5
							c-1.1-0.1-2.2-0.1-3.1-0.4c-1.8-0.6-3.4,1-5.2,0.2c-0.2-0.3-0.3-0.5-0.5-0.8c0-0.3,0.1-0.6,0.1-0.8c9.3,1,18.7,2,28,3
							c0,0.5-0.1,0.9-0.1,1.4c-0.2,0.1-0.3,0.2-0.5,0.3c-1.2-0.4-2.1,0.3-2.9,0.9c-0.7,0.5,0.1,1.2,0.3,1.8c0.2,0.5,0,0.9-0.4,1.4
							c-1,1.2-1.4,2.7-2.5,3.8c-0.6,0.6-1.3,2,0.6,2.3c0.1,0.1,0.2,0.3,0.3,0.4c0.1,2.3,0.4,2.3,2.9,1.8c1.5-0.3,3.4-2.5,4.7,0.4
							c0,0.1,0.3,0.2,0.3,0.2c2-1.1,4,0.3,5.9,0c3.4,0.4,6.8,0.8,10.3,1.2c0,0.2,0,0.4,0,0.6c-0.1,0.7-0.5,1.6,0.4,1.8
							c1.4,0.4,2.8,0.3,3.6-1.2c1.7-0.1,3.4-1.1,4.9,0.5c0.3,0.3,0.8-0.1,1.2-0.2c1.6-0.5,3.2-0.4,4.7,0.5c1.1,0.6,1.9,0.8,3.1,0.3
							c0.8-0.4,1.8-0.9,2.7,0.1c1.1,1.2,2.4,1.2,3.6,0.4c1.6-1.1,2.7-0.8,4,0.6c0.8,0.8,1.9,1.1,2.9,0.6c1.1-0.5,2.1-1.2,2.1-2.8
							c0.1-2.8,0.4-5.6,1.4-8.2c0.4-1.1,0.3-1.6-0.6-2c-0.5-0.2-0.9-0.4-0.9-1c12,1.3,24,2.6,36,3.8l0,0.1c-0.3,3-0.7,6-1,9
							c-0.1,0.9-0.6,1.9,0.1,2.8c0.1,0,0.3,0.1,0.4,0.1c-0.6,5.6-1.2,11.2-1.8,16.8c-0.1,1.2-0.3,2.4-0.4,3.6l0,0
							c-0.2,0-0.3,0-0.5,0c-0.8,0.7-0.5,1.7-0.6,2.6c-8.2,76.2-16.4,152.5-24.7,228.7c-4.2,39.2-8.4,78.4-12.6,117.6
							c-0.1,0.6-0.2,1.3-0.2,1.9c-14.9-0.5-29.8-2-44.6-4.1c-5.6-0.8-11.1-2.3-16.7-2.4c-4.6-0.1-9.3-0.1-13.8-0.8
							c-6.4-1-12.8-1-19.2-2c-1.6-0.2-3.3,0.1-4.6-1.3c-0.2-0.2-0.7-0.2-1-0.2c-3.5,0.4-6.8-1.2-10.3-1.1
							c-14.3,0.3-28.3-2.5-42.4-3.9c-3.8-0.4-7.7-1.9-11.6-1.9c-16.6,0-32.8-3.1-49.2-4.6c-16.5-1.5-32.9-3.5-49.4-5.3
							c0-0.5-0.2-0.8-0.7-0.9c-2.6,0.4-2.6,0.4-2.3-2.3C178.1,582,178.7,576.4,179.3,570.9z M417.4,569c0.4,0,0.7,0.1,1.1,0.1
							c0.9,0.2,1.7,0,2.4-0.6c0.5,0.4,0.6,1.1,1.4,1.1c0.7,0,1.1-0.2,1.1-0.9c0.1-0.6,0.3-1.3-0.3-1.7c-0.7-0.4-0.7,0.3-0.9,0.7
							c-0.4,0.7-0.9,0-1.3,0.1c-0.4-0.2-1,0.1-1.2-0.5c0-0.3,0.1-0.8-0.4-0.8c-0.1,0-0.3,0.4-0.4,0.6c-0.3,0.4-0.6,0.4-1,0
							c0.1-1,1-1.2,1.6-1.7c1.1,0,2.2,0.3,3.3-0.1c0.5-0.1,0.9-0.1,1.4-0.2c1.8,1,3.8,0.7,5.8,1.1c0.2,0.1,0.6,0,0.8-0.2
							c0.3-0.4-0.1-0.6-0.3-0.9c-0.6-0.9-1.7-1-2.4-0.9c-1.4,0.1-2.6-0.3-3.8-0.6c-0.7-0.4-1,0.1-1.2,0.7c-2.8-1.3-5.9-0.9-8.8-1.5
							c-0.8-0.4-1.6-1.8-2.5-0.1c0,0.6-0.4,1.3,0.6,1.5c0.1,0.8-0.7,1.9,0.7,2.3c-0.7,1.6,0,2.3,1.6,2.4c0.6,0,1.2,0.1,1.9,0.1
							c0.1,0.2,0.2,0.6,0.3,0.6C417.4,569.8,417.4,569.3,417.4,569L417.4,569z M261.7,575.6c0.1,0,0.2,0,0.4-0.1
							c0.5-0.1,0.8-0.3,0.9-0.8c0.3-2.5,0.6-5.1,0.8-7.6c0.1-0.6-0.3-0.9-0.9-1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-1.1-1.3-1.1-2.2-1.3
							c-2.1-0.4-4.4,0.3-6.4-1.1c-0.8-0.5-1.9-0.3-1.8,0.9c0.3,3.1-0.5,6.2-0.7,9.3c-0.1,0.9,0.1,1.5,1.2,1.5c0.1,0,0.3,0.1,0.4,0.1
							c0.5,1.2,1,1.3,1.7,0.2c1.1,0.1,2.3,0.2,3.4,0.4c1,1.7,2.5,1.1,4,0.9c-0.1-0.2-0.1-0.4-0.2-0.5
							C261.6,576.1,261.6,575.8,261.7,575.6z M245.6,583.1L245.6,583.1c1.4,1.2,4.4,0.5,4,3.5c0.1,0.9,0.7,0.9,1.3,1
							c2.8,0.3,5.6,0.6,8.5,0.9c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.9,0.2,1-0.3c0.2-0.6-0.1-1.1-0.5-1.5c-0.4-0.5-0.9-0.7-1.2,0.1
							c-0.9,0.3-1.4-0.7-2.4-0.8c-2-0.3-4.2,0.7-6.1-0.6c0.1-0.6,0.1-1.3,1-1.2c1.7,1.2,3.2-0.3,5.4-0.3c-1.7-1.4-3.3-1-4.7-1.3
							c-2.4-0.9-5,0-7.5-0.6c-0.3-0.1-0.6-0.1-0.9-0.2c-2.2-1.2-2.7-1.1-3.6,1.6c1.1-1.2,2-0.4,3-0.3c0.2,0.1,0.5,0.1,0.7,0.2
							C245.4,584,245.7,583.8,245.6,583.1z M252.9,545.6c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-1.3-1.2-1.1-2-0.8c-0.7,0.2-0.7,1.2-0.5,1.7
							c0.4,1,1.5,0.4,2.2,0.6c0.2,0.1,0.4,0.1,0.6,0.2c0.5,0.6,1,0.7,1.6,0.1c1.7,1.1,3.7,0.8,5.6,1c1.2,0.3,2.4-0.7,3.6-0.1
							c1.6,0.7,3.3,0.6,5,0.6c0.8,0,1.6-0.1,1.4-1c-0.1-0.7-0.6-1.3-1.5-0.9c-1.4,0.6-2.8,0.2-4.3,0c-1.3-0.2-2.6-0.7-4-0.4
							c-1.8-0.8-3.7-1.5-5.6-0.5C254,545.3,253.5,545.3,252.9,545.6z M439.6,352c0.5,0,1,0.2,1.4-0.1c-0.8-1,0.4-2.5-1.1-3.3
							c0-0.9-0.6-1.3-1.4-1.3c-4.9,0-9.7-0.9-14.6-1.3c-1-0.1-2.4,0.3-2.6-1.5c-0.1-0.7-0.6,0-1,0.1c-0.7,0.2-0.6,1.4-1.6,1.3
							c0.6-1.8,0.3-3.7,0.6-5.5c0.7,0,0.9-0.6,0.9-1.2c0.4-4,0.9-8,1.3-11.9c0.1-0.6,0-1.1-0.7-1.3c-0.1-0.9,0.5-1.7-0.1-2.6
							c0-0.3,0.1-0.6,0.1-0.9c0.9,0.1,1.3-0.3,1.3-1.2c0.1-1.4,0.2-2.8,0.3-4.1c0-0.6-0.5-0.5-0.9-0.6c-0.1-0.3-0.1-0.5-0.2-0.8
							c0.6,0,1.1,0.1,1.7,0.1c0.5,0,1.3,0.3,1.4-0.5c0.1-0.8-0.5-1.1-1.2-1.2c-2-0.1-4.1-0.4-6.1-0.4c-4-0.2-8.1,0.1-12-0.7
							c-3.9-0.9-7.9-1.4-11.9-1.3c-0.5,0-1.4,0-1.6-0.3c-1.9-2.6-2.6-0.4-3.6,0.8c-0.4,0.1-0.9,0.1-1.3-0.1
							c-0.5-1.5-1.4-2.2-3.1-1.9c-2.3,0.3-3.1-0.5-2.8-2.8c0.1-0.8,0-1.6,0.3-2.3c0.8-1.8,0.1-3.4-1-4.4c-1-0.9-2-1.6-2-3.6
							c-0.8,2.2-0.8,2.2-3,3.5c-0.2,0.1-0.3,0.4-0.5,0.7c-0.3,0-0.6,0-0.8-0.1c-0.8-1.2-1.7-2-3.4-1.4c-1.8,0.7-2.4,2.6-1.9,3.8
							c1,2.4-0.2,3.9-1.1,5.7c-1.2-0.7-1.2-1.7-1.1-2.8c0.8-7.3,1.6-14.5,2.3-21.8c0.1-0.7,0.7-1.7-0.6-2.2
							c-0.1-0.9-0.3-1.9-1.4-1.5c-1,0.5-2,0.3-2.9,0.1c-3.6-0.6-7.2-1-10.9-1.2c-7.3-0.4-14.5-1.9-21.9-2.3
							c-3.8-0.2-7.4-1.4-11.1-1.6c-1.2-0.1-2.4-0.4-3.2,0.9c-1.1,0.9-1.6,1.9-1.2,3.3c0.3,1.3,0.2,2.7,0,4
							c-1.6,10.2-1.2,20.6-4.1,30.6c-0.8,2.9,0.7,6-0.2,9c-1.1,3.7-0.1,7.9-2.1,11.5c-0.2,0.3-0.2,1,0,1.2c0.9,1.3,0.4,2.6,0.3,3.9
							c-0.4,3.8-1.2,7.7-1.1,11.5c0.2,5.1-3.2,9.8-1.7,15c0,0.2,0,0.5-0.1,0.6c-1.9,1.6-0.3,3,0.3,4.4l0.1,0l0.1,0
							c-0.1,0.1-0.2,0.3-0.2,0.4c-0.5,0.4-0.9,0.7-0.3,1.3c0.3,0.3,0.6,0.9,1.2,0.5c1.1-0.7,2.2-0.2,3.3-0.1
							c3.2,0.3,6.2,1.4,9.4,1.4c1.9,0,3.5,0.1,3.1,2.6c-0.1,0.6,0.5,0.8,1,0.8c0.4-0.1,0.7-0.4,0.5-0.8c-0.9-1.4,0.4-2.2,1.1-2.2
							c1.3,0,0.8,1.4,0.8,2.2c0,0.1,0,0.3,0,0.4c-4.9,45.2-9.7,90.4-14.6,135.6c-0.1,0.8-0.2,1.5-0.4,2.3c-0.4,1.5-0.4,2.5,1.6,2.7
							c0.9,0.1,1.8,0.3,2.7,0.4c14.1,1.5,28.2,3,42.3,4.6c15.2,1.6,30.4,3.3,45.5,4.9c0.8,0.1,1.9,0.8,2.4-0.5
							c0.7,0.1,1.4,0.2,2.1,0.3c0.3,1.3,1.3,0.9,2.1,1c12.6,1.4,25.1,2.7,37.7,4.1c2.1,0.2,2.6-0.3,2.8-2.3c2-19.5,4.2-39,6.3-58.5
							c0.1-0.7,0-1.7,0.4-2c1.5-1.4,0.4-2.5-0.1-3.7c-0.7,0.2-0.5,0.8-0.8,1.2c-0.3-0.1-0.5-0.1-0.8-0.2l0.1-0.2c0-0.1,0-0.3,0-0.4
							c-0.5-0.8-1.3-1-2.2-1.1c-8.7-0.9-17.5-1.9-26.2-2.8c-0.7-0.1-1.5-0.3-1.7,0.7c-1.3-0.1-2.5-0.3-3.8-0.4c-0.1-1.2-1-1-1.7-1.1
							c-3.5-0.4-6.9-0.8-10.4-1.1c-0.6-0.1-1.2-0.1-1.2-0.9c0-0.6,0.4-0.9,1-0.8c2.5,0.2,5,0.1,7.4,0.5c2.7,0.5,5.5,0.7,8.2,1.1
							c1.4,0.2,2.2-0.4,2.4-1.8c0.3-2.5,0.6-5.1,0.8-7.6c0.1-1.6-1.6-3.7-3-3.3c-1.6,0.4-3.1-0.5-4.7,0.2c-0.8,0.4-2.1,0-3.1-0.4
							c-3.3-1.4-6.7-1.8-10.2-0.9c0.7-9.4,2.6-18.7,3.4-28.1c0.5-5.8,1.4-11.5,1.6-17.4c0.1-1.9-0.5-5,3.5-5.7
							c-2.5-0.8-3.6-1.9-2.4-4c0.1,0,0.3,0,0.4,0.1c0.4,0.5,0.7,1.2,1.5,0.8c0.2-0.1,0.4-0.4,0.4-0.7c0.1-0.8-0.7-0.8-1.2-1
							c-1.1-0.4-1.2-1.3-1-2.3c1.1-5.7,1.6-11.5,2.3-17.3c0.1-0.9,0.5-1.8-0.4-2.6c0.1-0.4,0.1-0.8,0.2-1.3c0.7-1.6,0.9-3.3,0.5-5.1
							c0-0.4,0.1-0.8,0.1-1.3c1.7-1.1,0.1-2.9,0.8-4.2c0.8-0.6,2.1-0.3,2.7-1.4c4.5,0.5,9,0.9,13.4,1.8c1.7,0.4,3.7,1,5.3-0.7
							c1.2,0.1,2.3,0.1,1.4-1.6c-0.3-0.7-0.3-1.1,0.2-1.7c1.2-1.5,0.2-1.7-1-1.8c-0.1,0-0.3,0-0.4,0c0-0.3,0.1-0.6,0.1-0.8l0.2,0
							L439.6,352z M410.7,600.5c0.2,0.5,0.5,0.6,0.9,0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0,0.4-0.1,0.8-0.1,1.3
							c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3,0c-0.7-0.1-1.4-0.1-2.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.7-0.8-1.7-0.2-2.6-0.5
							c-0.5-0.2-1,0.2-1,0.8c0,0.5,0.1,1.1,1,0.9c0.7-0.1,1.5,0,2.3,0.1c0.2,0.4,0.5,0.5,0.9,0.1c0.8,0.1,1.6-0.2,2.2,0.6
							c-0.4,0.2-0.7,0.5-1.1,0.7c-0.6-0.1-1.1-0.2-1.7-0.3c-1-0.6-2.1-0.6-3.1-0.4c-0.1,0-0.3-0.1-0.4-0.1c-0.4-0.2-0.7-0.3-1,0.1
							c-1.3,0-2.6-0.6-3.9-0.5c-0.6,0-1-0.3-1.1,0.6c-0.1,0.7,0.1,1,0.9,1.1c2,0.2,4,0.6,6.1,0.5c-0.1,0.7,0.4,1.2,0.8,1.7
							c-0.2,1,0.2,1.4,1.2,1.4c1.4,0,2.8,0.1,4.1,0.1c0.3,0.9,0.8,0.9,1.5,0.5c1.6,0.4,3.2,0.9,4.7,1.3c0.4,0.2,0.7,0.7,1.1,0.3
							c0.5-0.5,0.4-1-0.1-1.5c0.1-0.3,0.2-0.5,0.2-0.8c0.7-0.5,2.2,0.2,2.3-1.4c0.9,0,1.8,0.1,2.5-0.6c-0.3-0.6-0.9-0.5-1.4-0.7
							c0-0.4,0-0.7,0.1-1.1c1.2-1.7-0.5-1.8-1.2-1.8c-2.7,0-5.3-0.5-7.9-0.6c-0.2-0.3-0.5-0.4-0.9-0.4l0,0c0.5-1.5,0.5-1.5,1.5-0.9
							c0.5,0.3,1.1,0,1.6-0.3c0.3-0.2,0.6-0.5,0.4-0.9c-0.2-0.4-0.6-0.6-1-0.7c-1-0.2-2.1-0.4-3.1,0.2c-0.4-0.4-0.7-0.9-1.2-1.1
							c-0.5-0.3-1.2-0.2-1.5,0.3C409.9,599.3,409.3,600.2,410.7,600.5z M218.6,584.1c0.9,0.1,1.8,0.2,2.7,0.2
							c1.1,0.1,2.2,0.2,3.3,0.3c0.7,0,1.2-0.2,1.1-0.9c0-0.7-0.7-1.5-1.1-1.1c-1.1,0.9-2.2-0.2-3.2,0.2c-0.8-1-1.7-0.6-2.6-0.3
							c-0.4-0.3-0.7-0.7-1.2-0.6c-0.8-0.3-1.5-1.2-2.5-0.7c-1.2,0.7-2.1-0.2-3.2-0.4c0.6-0.3,1.1-0.4,1.6-0.5c1.7-0.3,1.6-1.5,1-2.5
							c-1.1-1.9-3.4-2-5.1-0.2c-0.6-0.2-1.1-0.4-1.7-0.5c-0.1-0.3-0.2-0.3-0.4,0c-1.1-0.1-2.2-0.3-3.4-0.4c-0.7,0-1-0.5-1.5-0.9
							c-0.8-0.8-1.8-1.3-2.7-0.1c-0.3,0.7-1.9,0.9-1.1,2.2c-0.2,0.5-0.4,1.1-0.6,1.6c-0.3,0.6-0.8,0.7-1.4,0.7
							c-1.2-0.3-2.5-0.5-3.7-0.4c-0.3,0-0.6-0.1-0.9-0.1c-0.8-0.4-1.6-0.7-1.9,0.6c-0.8-1.9-2.6-1-3.9-1.5c-0.4-0.1-0.7,0.6-0.7,1.1
							c0,0.5,0.3,0.8,0.8,0.8c1.3,0.1,2.5,0.3,3.8,0.4c0.1,0.3,0.2,0.5,0.3,0.8c2,1.3,4.2,1.2,6.3,0.9c1.1-0.2,1.2,0.2,1.2,1
							c-1.2,1.8-0.1,2.2,1.4,2.2c0,0.7,0.4,0.9,1.1,1c3.1,0.3,6.2,0.6,9.2,1c0.7,0.1,0.9-0.2,0.7-0.8c0.1-0.1,0.1-0.1,0.2-0.1
							c0.9-0.1,1.7,1.3,2.6-0.1c0.8-1.3,0.2-2-1-2.6c-0.1,0-0.1-0.4-0.1-0.6c0.4-0.4,0.9-0.3,1.3-0.2c1.2,0.9,2.6,0.4,3.9,0.4
							C217.8,584,218.2,584,218.6,584.1z M283.8,234c-0.5,0.2-0.5,0.7-0.5,1.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0-0.4,0-0.6,0
							c0-1.1-0.7-0.8-1.4-0.9c-2.1-0.4-4.6,0.7-6.3-1.4l0-0.1c-0.1-1-0.6-1.7-1.5-1.8c-2.2,0-4.2-1.1-6.5-0.5
							c-1.4,0.4-3,0.4-4.1-0.2c-2-1-2,0.2-2.2,1.4c-0.2,1.3-0.2,2.7-0.5,4c-0.2,0.7-1.1,1.2,0.2,1.9c0.8,0.4,1.2,1.5,2.2,1.6
							c1.1,0.1,1.8-0.8,2.6-1.3c0.9-0.6-0.4-0.9-0.6-1.5c-0.4-1.4,1-1.3,1.5-1.8c0.4-0.4,1.3,0.1,1.1,0.8c-0.5,1.6-0.6,3.2-0.7,4.8
							c-0.1,0.8-0.8,1.3-1.6,1.4c-0.6,0-1.2,0-1.3,0.8c-14.5-1.5-29.1-3.1-43.6-4.6c-2.8-0.3-3-0.2-3.3,2.6
							c-0.7,6.6-1.3,13.1-2.2,19.7c-0.2,1.9,0.3,2.4,2.1,2.6c12.9,1.3,25.8,2.8,38.7,4.1c7.3,0.8,14.7,1.6,21.9,2.7
							c2.9,0.4,3,0.2,3.3-2.6c0.7-6.8,1.5-13.5,2.2-20.3c0.7,0.1,1.4,0.3,1.9-0.5c0.3-0.5,0.1-1-0.3-1.4c-0.7-0.8-1.5-1.5-2.5-1.1
							c-0.8,0.3-1.3,0-2-0.1c-1.5-0.2-3-0.3-4.5,0.2c-0.3,0-0.6-0.1-0.8-0.1c0-1.2-0.6-1.9-1.8-1.9c-1.1,0-2.5-0.2-2.9,1.3
							c-0.6-0.1-1.1-0.1-1.7-0.2c0.1-1,0.2-1.9,0.3-2.9c0.1-0.7,0.6-1.1,1.1-0.5c1.9,2,3.1-0.1,4.5-0.6l0.1-0.1l0.1,0
							c1.1-0.1,2.5-1.3,3,0.7c0.3,0.9,0.7,1.5,1.7,1.4c1.2-0.1,2.3-0.3,2.4-1.8c0,0.2,0,0.3,0,0.5c0.2,0.1,0.3,0.2,0.5,0.3
							c0.2,0.1,0.3-0.1,0.1-0.2c-0.1-0.1-0.4-0.1-0.5-0.1c0.2-0.9,2,0.2,1.8-1.3c0.9-0.4,1.9-1.1,1.5,0.9c-0.2,1,0.7,1.6,1.1,2.4
							c0.2,0.4,1.4,0.3,2.1,0.5c0.7,0.2,0.8-0.8,0.9-1.4c0.2-1.5,0-3.1,0.5-4.7c0.3-0.1,0.6-0.2,0.8-0.3c-0.1,1.2-0.2,2.4-0.3,3.5
							c-0.1,0.9,0,1.7,1.2,1.7l-0.1,0.1l0.1-0.1c-0.2,0.8,0.4,0.8,0.9,1.1c2.5,1.1,5.1,0,7.7,0.5c2,0.4,4,0.2,6,0.8
							c1.3,0.4,2.7,0.7,3.6-1.1c0.3-0.6,0.4,0.3,0.6,0.4c0.4,0.7,0.8,1.4,1.3,2.2c0.5-0.6,1-1,1.2-1.6c1.2-2.7,1.2-2.7,2.9-0.9
							c0,0.3-0.1,0.6-0.1,0.8c-0.3,0-0.7,0-0.7,0.4c0,0.1,0.4,0.3,0.6,0.4c0.3,1,1.3,1.4,1.6,0.7c0.7-1.6,1.9-0.9,3-1.1
							c0.9,0.1,1.9,0.2,2.8,0.3c1.4,0.2,2.9,1,4.2-0.3c0.6,0,1.2,0.1,1.8,0.1c0.4,0.9,1.6,1,2.1,1.4c1.3,1.4,2.2,1.4,3.7,0.4
							c0.7-0.4,1.9-0.2,2.4-1.2c0.5-1.2,0.4-2.4-0.7-3.3c-0.2-1.2-1.3-1.1-2.1-1.4c-0.5-0.9-1.1-0.8-1.7-0.3c-0.8,0.3-1.7,0.1-2.2,1
							c-0.2,0.1-0.4,0.2-0.6,0.2c-0.8-0.1-1.5-0.3-2.3-0.4c-1.4-0.7-2.9-1-4.4-0.4c-0.7-0.2-1.3-0.5-2-0.7c-0.4-0.4-0.7-1.3-1.2-0.1
							c-0.3,0-0.6-0.1-0.8-0.1c-0.8-0.7-1.7-0.7-2.5-0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4-0.1-0.9-0.2-1.3-0.3
							c-0.2-0.1-0.3-0.3-0.5-0.4c-0.9-1.6-1.8-1.6-3.3-0.1l-0.1,0.1c-2.3-1.4-4.7-1.9-7.4-1.7c-0.3,0-0.5-0.1-0.8-0.2
							c-1.6-0.6-3.2-0.5-5-0.5c-1.5,0-3.3,0-4.5-1.1c-0.9-0.8-1.7-0.8-2.7-0.4c-0.2,0-0.3-0.1-0.3-0.3c0.1-0.5,0.1-0.9-0.7-0.9
							c-1.1,0.1-2.2,0-3.3-0.6C284.7,232.5,284.2,233.2,283.8,234z M355.8,565.2c0,0.2-0.1,0.6,0.3,0.5c0.4-0.1,0.3-0.5,0.2-0.8
							l0-0.2c1.3,1.6,3.2,1.4,4.8,1.1c1.2-0.2,2.7,0.9,3.6-0.8c0.3,0.1,0.5,0.2,0.8,0.3c0.5,1,1.1,1.1,1.7,0.1c0.4,0,0.9,0,1.3,0
							c0.3,1,0.9,1,1.7,0.6c0.3,0,0.5,0,0.8,0.1c1.3,0.9,2.6,1.5,3.9,0c0.3,0.1,0.6,0.1,0.8,0.2c-0.2,1.5,0.9,2.3,1.8,1.7
							c1.6-1.1,2.9-0.3,4.4,0c1,0.2,1.7,0.1,1.8-1.1c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.6-0.2,0.6-0.3c0.1-0.5-0.3-0.5-0.6-0.5
							c-0.7-0.2-1.4-0.3-2-0.5c0-0.6,0.2-1.2-0.5-1.6c-0.9-0.6-1.3-1.5-1.2-2.7c1.4-1.1,1.3-1.5-1-2.5c-3-2.2-6.5-1.7-9.8-1.7
							c-1.5,0-3.5,0.1-4.6-0.2c-1.8-0.6-3.7-1.1-5.4-1.2c-2.2-0.2-4.6-0.6-6.6,0.9c-0.1,0-0.3,0-0.4-0.1c-0.1-0.6-0.5-0.7-1-0.6
							c-0.7,0-1.5-0.4-2.2,0.1c-0.4,0-0.9,0-1,0.5c-0.1,0.5,0.3,0.7,0.8,0.7c0,0.1,0,0.2,0,0.3c-1.1,1.2-0.9,3-1.8,4.3
							c-1.2,1.5-0.2,1.8,1,2.1c0,0.3,0,0.7,0.5,0.8C351.4,564.5,353.6,564.9,355.8,565.2L355.8,565.2z M313.1,571.6
							c-0.3,0-0.6-0.1-0.9-0.1c-1.6-0.6-3.3-0.7-5.1-0.6c-0.3,0-0.6,0-1,0c-0.1-0.2-0.1-0.2-0.2,0c-0.2,0-0.4,0-0.6,0
							c-1,0-0.9,0.8-0.9,1.3c-0.2,2-0.4,4-0.6,6.1c-0.4,1.5-0.7,2.9,1.7,2.8c1.1,0.7,2.2,0.9,3.4,0.5c0.6,0,1.3,0,1.9,0
							c0.3,0,0.7,0.1,1,0.1c3.8,0.9,7.7,0.9,11.5,1.4c0.8,0.1,1.3-0.2,1.3-1c0-1.3,0.6-2.6,0.4-4c0-0.4,0.1-0.9,0.1-1.3
							c0.4-0.8,0.3-1.8,0.5-2.7c0.1-0.8-0.1-1.4-1-1.5C320.8,572.2,317,571.4,313.1,571.6z M317.2,552.1c-0.8-1.2-1.6-0.7-2.4-0.1
							c-1.6-0.1-3.2-2.4-4.9-0.1c-0.1,0.1-0.2-0.4-0.3-0.6c-0.2-0.4-0.5-0.8-0.9-0.5c-0.4,0.3-1.1,0.5-1.1,1.2c0,0.6,0.4,0.9,0.9,1
							c2.1,0.1,4.1,0.5,6.2,0.2c0.7,1,1.5,0.9,2.4,0.3c1.3,1,2.8,0.1,4.2,0.7c0.5,0.2,0.9-0.3,1-0.9c0.1-0.6-0.2-1-0.8-1
							C320,552.3,318.6,551.6,317.2,552.1z M439.2,251.2c0-0.3,0.1-0.5,0.1-0.8c-0.1-2.6-2.2-1.4-3.3-1.6c-1.3-0.2-0.6,1-0.6,1.7
							c0,0.4,0,0.8,0,1.2c0,0.1-0.1,0.2-0.2,0.3c-0.7,0-1.4-0.1-2.2-0.1c-0.9-1.3-2-1.3-3.3-0.7c-0.9-0.6-2,0.1-3-0.4
							c-0.1,0.1-0.2,0-0.3-0.1c-2.3-1.2-4.8-0.7-7.2-0.7c-0.4-0.1-0.8-0.1-1.2-0.2c-0.1-0.5,0.3-1.1-0.3-1.5c-0.5,0.5-1,0.9-1.4,1.4
							c-0.8,0-1.6-0.1-2.1-0.8c-1.1-1.3-2.5-1.3-3.9-0.7c-1,0.4-1.6,0.2-2.5-0.3c-1-0.6-2.6-1.8-3.3,0.5c-3.5-1.4-7.4-0.8-10.9-1.7
							c-2-0.5-3.1,1-4.7,0.7l0,0c0.3-1.6-0.8-1.6-1.8-1.5c-0.7,0-1.4,0.2-2.1,0c-1.2-0.4-2.8,0.3-3.5-1.3c-1.4,0.7-3.1,0.4-4.6,0.9
							c-0.4,0.1-0.8,0.1-1.1,0.2c-0.1-0.6-0.6-0.9-1.2-0.9c-0.1-0.4,0-0.9-0.5-1.2c0-0.1,0-0.3,0-0.4c0.1-0.7-0.3-1-1-1.1
							c-0.7-0.1-1.4-0.1-2.1-0.2c-2-0.5-3.1,0-3,2.3c0.1,1.1-0.4,2.1-0.9,3c-0.4,0.9-1.3,1.6-2.2,1.2c-1.1-0.5,0-1.2,0.1-1.9
							c0.1-0.7,0.3-1.4,0.3-2.1c0-0.7-0.4-1.5-1.2-1.4c-2.2,0.2-4.5-0.6-6.7,0.6c-0.5,0-1.1-0.3-1.3,0.2c-0.6,1.3-1.1,2.1-1.6-0.1
							c-0.1-0.6-0.9-0.8-1.2-0.4c-0.9,1.2-0.7,2.6-0.7,4c0,0.5,0.7,0.6,1.2,0.4c0.1,0,0.2-0.2,0.2-0.3c0.7-1.9,1.2-1.7,1.5,0.2
							c0.1,0.5,0.6,0.5,0.9,0.8c3.1,3.2,7.4,1.7,11.1,2.3c1.6,0.3,3.7,0.2,5.5,0.1c0.8-0.1,1.7,0.5,2.4-0.3c0.4-0.2,1.2-0.2,1.3-0.5
							c1.1-2.8,1.2,0.2,1.7,0.3c0.3,2.1,2,1,3,1.3c3.4,1,7.1,0.1,10.4,1.8c1.1,0.6,2.9,1,3.4-1.2l0,0c0.5,0.1,1.1,0.2,1.6,0.3l0.1,0
							l0,0.1c0.1,0.2,0.2,0.5,0.3,0.5c3.4,0.3,6.8,1.9,10.3,0.9c0.7-0.1,1.3-0.2,2-0.3c0.1,0,0.2,0.1,0.2,0.2c1,1.3,2.2,1.6,3.4,0.3
							l0.1-0.1l0.1,0c0.8,0.1,1.6,0.1,2.3,0.2l0,0c0,1.6,1.5,1.2,2.3,1.6c0.8,0.3,1-0.5,1-1.1l0.1-0.1l0.1,0
							c0.8,0.1,1.7,0.2,2.5,0.2l0,0c0.6,1.4,3,1.8,4.5,0.7c0.1-0.1,0.2-0.1,0.4-0.1c2.5,1.7,5.5,1.3,8.2,1.8c0.8,0.1,1.9,0,2.2,0.4
							c1.7,2.1,1.8,0,2.3-0.8c0-0.2,0.1-0.3,0.2-0.3c0.6,0,1.3,0,1.9,0c0.8-0.2,2.2-0.9,2.3-0.7c1.1,1.7,2.2,0.2,3.2,0.1
							c1.3,0.1,1.3-0.7,0.9-1.5c-0.5-1.1-1.5-1.5-2.6-0.8c-0.3,0-0.5,0-0.8,0.1c-1.3-1.4-3-1.2-4.7-1.1l-0.1-0.1
							C440.1,251.8,439.9,251.3,439.2,251.2z M208.4,540.6c-0.2,0-0.3-0.1-0.5-0.1c-2.4-1-5.1-0.7-7.6-1.2c-0.6-0.9-1.4-0.9-2.2-0.2
							c-0.5,0.4-0.6,1-0.2,1.5c0.5,0.8,1.2,0.9,2,0.5c0.2-0.1,0.4-0.1,0.6-0.2c2.2,1.6,4.7,0.3,7.1,0.9c0,0,0.1-0.5,0.2-0.8
							c2.1,1.7,4.6,1.8,7.1,1.5c1.5,0.9,3.1,0.8,4.7,0.8c0.3,0,0.6,0.1,0.8,0.1c1-0.2,2.6,1.2,2.8-0.4c0.1-1-1.8-1.4-3.1-1.5
							c-0.1,0-0.3,0.1-0.4,0.2c-1.9-0.4-3.7-1.5-5.7-0.5c-0.3-0.1-0.7-0.1-1-0.2c-0.3-0.2-0.7-0.3-1.1-0.2c-1.1-0.3-2.2-0.2-3.4-0.1
							c0-0.2-0.1-0.4-0.1-0.6C208.5,540.3,208.4,540.4,208.4,540.6z M379.8,600.6c1.1-1.4,0.5-2-1-2.1c-2.1-0.1-4.3-0.2-6.4-0.3
							c-0.7-1.3-1.5-0.1-2.5-0.1c0.2-1.2,0.3-2.2,1.9-1.4c0.5,0.3,1.2,0.5,1.8,0.6c0.4,0.1,0.7-0.2,0.9-0.5c0.2-0.3,0.1-0.7-0.2-0.7
							c-0.9-0.3-1.4-1.5-2.5-1.4c-1.3,0-2.5,0.2-3.7-0.8c-0.6-0.5-1.5-0.1-1.8,0.9c-0.2,0.7-0.3,1.4,0.8,1.5c0.6,0,1.3,0.2,1.2,0.8
							c-0.1,0.9-0.9,0.3-1.4,0.3c-0.7,0.1-1.4,0.2-2.1-0.3c-1.2-0.8-2-0.2-2.1,1.2c-0.6-0.1-1.2-0.1-1.3-0.8
							c-0.1-1.9-1.4-1.3-2.1-0.8c-0.8,0.7-1.9,1.2-2.2,2.4c-0.3-0.1-0.5-0.1-0.8-0.2c-0.1-0.3-0.4-0.6-0.6-0.3
							c-0.3,0.4,0.1,0.5,0.4,0.6c-0.1,0.3-0.1,0.6-0.2,0.8c-0.3,0-0.6,0.1-0.6,0.4c0.1,0.4,0.4,0.3,0.6,0.1c0.4,0,0.9,0,1.3,0
							c0,0.2,0.1,0.3,0.1,0.5c0.1-0.1,0.2-0.2,0.4-0.3c0.2,0.1,0.5,0.1,0.7,0.2c0,1.1,0.8,1.2,1.6,1.1c1.3,0,2.4,0.5,3.3,1.2
							c0.1,0.6,0.5,0.8,1,0.9c3.6,0.4,7.3,0.3,10.7,1.7c0.2,0.1,0.6-0.5,0.9-0.8c1.9-0.1,3.6-1.2,5.4-1.7c0.6-0.2,0.7,1.1,1.3,0.5
							c0.5-0.5,1.1-1.2,1.1-1.8c0-0.7-1-0.5-1.6-0.6c-0.6-0.1-1.3-0.1-1.9-0.1c-0.3,0-0.6,0-0.9,0
							C379.7,601.3,379.7,601,379.8,600.6z M312.9,593.4L312.9,593.4c4.4,1.2,9.3,0.3,13.6,2.4c0.1,0.1,0.6-0.5,0.9-0.8
							c1.1-0.5,1.4-1.7,2-2.6c0.5,0.1,1.1,0.1,1.6,0.2c-0.3,0.3-1,0.3-0.6,1.2c0.3-0.8,1.4-0.3,1.6-1.2c0.5-0.5,1-1.1-0.1-1.4
							c-0.8-0.2-1.5-0.6-2.4-0.7c-4.4-0.4-8.9-1-13.3-1.5c-0.6-0.1-1.3-0.1-1.9-0.2c-0.4,0-0.8,0-1.2,0c0.1-0.7-0.3-1-0.9-1.1
							c-1.6-0.2-3.2-0.3-4.8-0.5c-0.6-0.1-0.9,0.2-1.1,0.7c-2.2-0.1-4.4-0.1-6.6-0.6c-2-0.4-4.1-0.2-6.1-1c-0.8-0.3-1.3,0-1.4,1
							c0,0.9,0.3,0.8,1,1c1.1,0.2,2.3,0.6,3.5,0.7c0.7,0.9-0.3,2.1,0.7,3.1c1.7,1.8,3.6,2.8,6.1,1.8c0.6-0.2,0.9,0.2,1.3,0.3
							c-0.2,0.6-0.2,1.1,0.6,1.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0,0.2,0,0.3c0.1-0.1,0.1-0.1,0.2-0.2c3.5,0.5,7.2,0.2,10.6,1.6
							c1.2,0.5,1.1-0.8,1.5-1.3c0.6-0.6,0-0.9-0.7-1c-2.2-0.4-4.4-0.6-6.6-0.8c-0.9-0.1-1.6,1.2-2.5,0.1c0.2-0.9,0.7-1.3,1.7-1.1
							c0.9,0.2,1.8,0.2,2.7,0.3C312.5,593.7,312.7,593.5,312.9,593.4z M366,254.9L366,254.9c-0.7-0.1-1.5-0.1-2.2-0.2
							c-0.1,0-0.2,0-0.3-0.1c-0.5-1.4-2-2-3.3-1.3c-0.8,0.4-1.4,1.2-2.4,0.2c-0.3-0.3-0.5,0.2-0.7,0.6c-1.5-1.1-2.8-0.7-4.1,0.7
							c1,0.2,1.7,0.3,2.3,0.4c0.6,2.6-1.7,1.1-2.6,1.8c1.1,1.3,1.9,2.7,3.8,1.4c0.2,0.4,0.6,0.8,0.9,0.5c1.2-1.1,1.9-0.1,2.8,0.4
							c0.2,0.2,0.4,0.6,0.6,0.5c1.6-0.4,3.2-0.4,4.7,0.6c0.2,0.1,0.3-0.5,0.4-0.9c0.3,0,0.5,0.1,0.8,0.1c0.1,0.3,0.2,0.7,0.3,0.7
							c1.9,0.3,3.8,0.7,5.7,0.6c1.4-0.1,3.2-0.3,4.3,0.7c0.7,0.7,1,0.4,1.5,0.2c1.8-0.8,1.4-2.5,1.7-4c0.3-2.1-0.6-3.2-2.5-3.4
							c-3.1-0.4-6.1-0.6-9.2-0.7c-0.6,0-1.5-0.3-1.7,0.8l0,0C366.5,254.5,366.2,254.5,366,254.9z M212.7,561.8
							c-0.2-0.2-0.5-0.4-0.7-0.6c-1.9-1-3.8-1.1-5.8-0.6c-0.3,0-0.6-0.1-0.9-0.1c-1.5-0.9-3-1.4-3.7,0.9c-0.8,2.5-1.5,5.1-1.3,7.7
							c0.1,1.2,1.1,2.5,2.6,2.7c2.2,0.3,4.4,0.5,6.6,0.7c1.3,0.1,2.3-0.4,2.4-1.8C212.2,567.7,212.9,564.8,212.7,561.8z
							 M478.9,273.9c0.2-1.9,0.2-3.8,0.6-5.7c0.4-1.9-0.3-2.4-2.1-2.6c-20.6-2.2-41.2-4.4-61.9-6.6c-2.6-0.3-3.2,0.1-3.3,2.7
							c-0.3,6.4-1.3,12.7-1.9,19.1c-0.3,2.9-0.3,2.9,2.7,3.3c21,2.3,42.1,4.5,63.1,6.8c-1.5,13.6-2.9,27.3-4.4,40.9
							c-1.5,13.9-3,27.8-4.5,41.7c-0.1,0.7-0.7,1.9,0.6,2.1c1.3,0.1,0.9-1.2,1-1.9c0.4-3.7,0.8-7.3,1.2-11
							c3.1-28.7,6.2-57.5,9.3-86.2C479.5,275.5,480.2,274.5,478.9,273.9z M312.1,282.8c-1.6,0.3-1.6,1.6-1.7,2.8
							c-2.5,23-4.9,45.9-7.5,68.8c-0.2,2.1,0.4,3.9,0.6,6.4c1.2-5.9,2.1-11.2,1.6-16.6c-0.1-0.6,0-1,0.3-1.5
							c0.9-1.4,1.4-3.3,0.9-4.5c-0.6-1.3-0.5-2.4-0.4-3.6c1.2-10.9,2.3-21.8,4.3-32.5c1-5.6,1.3-11.4,1.9-17.1
							C312.2,284.2,312.6,283.5,312.1,282.8z M310.8,539.8c-0.5,4.7-0.5,4.7,4.1,5.2c5.2,0.6,5.2,0.6,5.7-4.5
							c0.5-4.6,0.5-4.6-4.2-5.1C311.3,534.8,311.3,534.8,310.8,539.8z M213.6,525.3c-5-0.5-5-0.5-5.5,4.3c-0.5,4.8-0.5,4.8,4.3,5.2
							c4.9,0.5,4.9,0.5,5.4-4.4C218.3,525.7,218.3,525.7,213.6,525.3z M256.2,534.1c-0.5,4.9-0.5,4.9,4.4,5.3
							c4.7,0.4,4.7,0.4,5.2-4.5c0.5-4.7,0.5-4.7-4.1-5.1C256.7,529.2,256.7,529.2,256.2,534.1z M240.4,593.8
							c-7.3-0.8-14.7-1.6-22-2.4c-0.6-0.1-1.6-0.6-1.7,0.5c-0.1,0.9,0.8,1,1.5,1.1c0.3,0,0.6,0.1,0.8,0.1
							c14.2,1.5,28.4,3.1,42.5,4.6c0.5,0.1,1,0.1,1.5,0.1c0.5,0,0.8-0.2,0.9-0.7c0.1-0.5-0.2-0.8-0.7-0.9c-0.6-0.1-1.1-0.2-1.7-0.2
							C254.5,595.3,247.5,594.5,240.4,593.8z M416.5,585.5C416.5,585.5,416.5,585.5,416.5,585.5c-2.5-0.2-5.1-0.4-7.6-0.8
							c-1.3-0.2-1.9,0-1.9,1.5c0,1.1-0.7,2.2,1.3,2.4c5.2,0.4,10.5,1,15.7,1.6c1.6,0.2,1.5-0.8,1.7-1.8c0.2-1.1,0.2-2-1.3-2.1
							C421.8,586.1,419.1,585.7,416.5,585.5z M367,550.7c4.9,0.5,5,0.5,5.5-4.5c0.6-5.9,1-4.6-4.1-5.2c-5-0.5-5-0.5-5.6,4.4
							C362.2,550.2,362.2,550.2,367,550.7z M419.8,547.3c-2.6-0.3-4.9,1.7-5.1,4.3c-0.2,2.4,1.7,4.7,4.2,5c2.6,0.3,5.1-1.7,5.3-4.1
							C424.3,549.8,422.4,547.6,419.8,547.3z M311.3,603.1c6.1,0.7,12.3,1.3,18.4,2c0.7,0.1,1.9,0.7,2-0.7c0.1-1.2-1.1-0.9-1.8-1
							c-12.3-1.3-24.5-2.7-36.8-4c-0.7-0.1-1.7-0.6-1.9,0.6c-0.2,1.2,1,1,1.7,1C299,601.8,305.1,602.4,311.3,603.1z M229.7,209.5
							c0,0.1,0,0.3,0,0.4c9.7,1,19.4,2.1,29,3.1c0-0.1,0-0.3,0-0.4C249.1,211.6,239.4,210.6,229.7,209.5z M340.8,245
							c0.4,0.6,0.3,1.6,1.3,1.4c1-0.2,0.7-1.1,0.8-1.7c0.1-0.6,0.2-1.3,0.2-1.9c0-0.9-0.6-1.1-1.3-0.9c-0.8,0.3-1.6,1.6-2.3-0.3
							c-0.1-0.3-1.1-0.2-1.2,0.4c-0.2,1.2-0.4,2.4-0.4,3.6c0,0.7,0.8,0.7,1.4,0.5C339.9,245.8,340.3,245.4,340.8,245z M258.2,584.5
							c1.2,1.3,2.7,0.7,4,0.8c2.6,0.3,5.2,0.6,7.9,0.9c0.8,0.1,1.5,0,1.5-1c-0.1-0.8-0.6-0.9-1.4-0.8c-1.1,0.2-2.1,0-3.2,0
							C264,584.3,261.2,582.8,258.2,584.5z M346.6,254.3c-0.7-1.5-1.1-1.8-1.7-1.4c-0.4,0.3-1,3.6-0.6,4c1.3,1.3,1.9-0.5,3-0.8
							c0.2,0.6,0,1.8,1.2,1.5c1-0.2,0.6-1.2,0.8-1.8c0.1-0.4,0.1-0.8,0.1-1.2c0.1-0.5,0.3-1-0.2-1.3c-0.5-0.4-1.1-0.1-1.6,0.2
							C347.2,253.6,346.9,253.9,346.6,254.3z M349,245c-0.1-1,1-2.6-1.2-2.6c-1.5,0-3.6-0.7-3,2.2c0.1,0.3-0.3,0.6-0.4,0.9
							c-0.2,0.4-0.3,0.9,0.2,1.1c0.5,0.3,1,0.2,1.2-0.4c0.2-0.5,0.5-1.2,1.1-0.5c0.4,0.5,0.2,1.6,1.2,1.3
							C349.2,246.8,348.7,245.7,349,245z M373.7,581.9c-0.5-0.1-0.9-0.1-1.2-0.1c-1-0.1-1.9-0.2-2,1.3c-0.1,1.6,0.9,1.5,1.9,1.6
							c0.8,0.1,1.7,0.2,2.5,0.3c0.8,0.1,1.1-0.2,1.3-1C376.7,581.4,374.7,582.2,373.7,581.9z M364.8,583.9c0.6,0.1,1,0.1,1.3,0.1
							c1.1,0.2,1.7,0,1.9-1.3c0.2-1.4-0.6-1.5-1.6-1.5c-0.9,0-1.8-0.1-2.7-0.3c-0.8-0.2-1.1,0.1-1.3,0.9
							C361.9,584.3,363.9,583.6,364.8,583.9z M340.1,253.1c0-0.2,0.2-0.7,0.3-1.3c0.1-0.5,0.3-1.2-0.4-1.5c-1-0.3-1.4,0.8-2.2,1
							c-0.4,0.1-0.9,0.7-0.6,0.9c1.5,0.9,0.8,2.2,0.8,3.3c0,0.5,0.3,0.8,0.7,0.9c0.5,0.1,1,0,1.1-0.7
							C339.9,255.1,340,254.3,340.1,253.1z M408.3,564.1c0.3,0,1.2,0.4,2-0.2c0.3-0.2,0.5-0.5,0.4-0.9c0-0.1-0.3-0.4-0.4-0.3
							c-1.4,0.5-2.1-0.6-3.1-1.2c-0.7-0.4-1.4-0.3-1.7,0.5c-0.2,0.7-0.4,1.6,0.7,1.9C406.6,564,407.2,564,408.3,564.1z M381.1,584.3
							c0.1-0.8,0.2-1.6-0.9-1.7c-0.9-0.1-1.3,0.3-1.3,1.2c0,0.8-0.1,1.6,1,1.6C380.7,585.5,381.1,585.2,381.1,584.3z M260.2,551.1
							c-1.2-0.6-1.6-1.8-2.7-1.2c-0.5,0.3-0.7,1.2-0.3,1.5C258,552.4,258.8,551.3,260.2,551.1z M224.5,209c-0.1,0.5,0.2,0.6,0.5,0.6
							c0.2,0,0.6-0.1,0.7-0.2c0.2-0.4-0.2-0.5-0.5-0.5C225,208.9,224.8,209,224.5,209z M263.4,551c0-0.3-0.3-0.4-0.7-0.5
							c-0.4,0-0.9-0.1-0.9,0.5c0,0.6,0.5,0.6,0.9,0.6C263,551.7,263.4,551.6,263.4,551z"/>
						<path fill="#FBFBF9" d="M456.4,498.7c8.2-76.2,16.4-152.5,24.7-228.7c0.1-0.9-0.1-1.9,0.6-2.6c-3.8,35.1-7.6,70.2-11.3,105.3
							c-4.4,41.3-8.9,82.6-13.4,123.9C456.9,497.3,457,498.1,456.4,498.7z"/>
						<path fill="#F5F0BC" d="M325.4,217.9c-3-0.3-6-0.6-9.1-1c0.6,2.5,1.2,4.8,0,7.1c-0.5,0.9-0.4,1.9-0.2,2.9
							c0.3,1.2-0.1,2.4-0.6,3.5c-0.5,1.1-1.2,1.3-2.4,0.8c-2.6-1.1-5.3-2-8.2-1.1c-0.2,0.1-0.7,0.1-0.8-0.1
							c-1.5-1.5-3.5-0.9-5.2-1.3c-0.8-0.2-1.9,0.4-2.2-1.2c-0.1-0.6-1.5-0.9-2.2-0.1c-1.6,2-3.6,1.2-5.4,0.9
							c-0.8-0.1-1.6-0.3-2.5-0.2c-1.6,0.2-2.8-0.5-3.2-2.2c1.4,0.4,2.7,1.1,4-0.2c0.7,0.1,1.4,0.1,2.1,0.2c1.1,1.6,2.4,1.4,3.8,0.4
							c1.4,0.1,2.8,0.3,4.1,0.4c0.7,1.5,2.1,1.2,3.1,0.9c1.1-0.3,0.2-1.4,0.1-2.1c-0.7-3-1.3-6-2-9c1.7-1.4,1.5,0.4,2,1.1
							c-0.4,1.4,0.7,2.6,0.5,4.1c-0.4,2.2,0.4,2.8,2.6,2.7c0.1,0.8,0.3,1.7,0.2,2.5c-0.2,1.3,0.5,1.3,1.4,1.5
							c2.1,0.4,2.6-0.7,2.4-2.3c-0.2-1.4,0.4-1.6,1.7-1.6c1.8,0,2.4,0.7,2,2.5c-0.2,1-0.6,2,1.2,2.2c1.6,0.1,2.3-0.2,2.4-1.9
							c0.1-2.3,0.4-4.5,0.7-6.8c0.2-1.4,0.3-2.4-1.7-2.6c-2-0.2-1.9,0.8-1.9,2c0,1.3-0.4,1.8-1.9,1.7c-2-0.1-2-1.1-1.7-2.5
							c0.2-0.9,0.3-1.8-1-1.8c-1,0-2.3-0.8-2.6,1.1c-0.2,1.6-0.6,3.2-0.9,4.8c-0.9-1.2-0.8-2.6-0.8-4c0-0.9-0.3-1.3-1.2-1.3
							c0.6-1.2,2-1.1,3-1.8c-0.2-0.2-0.3-0.4-0.4-0.4c-5.3-0.6-10.7-1.2-16-1.8c-1.7-0.2,0.1,1-0.5,1.3c-2.8-0.2-5.6-0.4-8.4-0.9
							c-1.4-1.7-3.4-2.2-5.6-1.4c0.9,2.1,2.9,1.2,4.4,1.7c-0.3,2-0.5,4.1-0.8,6.1c-0.1,0.8-0.2,1.7-0.9,2.1c-1,0.6-1.4,1.3-0.9,2.3
							c0.5,1,1.6,0.7,2.5,0.5c1.1-0.2,1.8-0.6,2.1-1.9c0.3-1.5,0.8-3,0.9-4.5c0.1-1,0.5-1.3,1.5-1.2c1.8,0.1,2.1,0.9,0.9,3.3
							c-1.7-0.6-2.5,0-2.5,1.9c0,0.9,0,1.8-0.5,2.7c-1.6,2.6-2.9,2.9-5.9,0.9c-1.4-0.9-2.5-0.8-3.7,0.1c-1,0.8-1.6,0.7-2.5-0.2
							c-1.1-1.3-2.7-1.2-4.3-1.1c-0.6,0.1-0.8,2-1.7,0.4c-0.7-1.2-3.3-1-2.2-3.8c0.7-1.7,0.9-3.9,0.4-6c-0.4-1.8,0.6-3.7,2.3-4.9
							c-13.2-1.4-26.4-2.8-39.6-4.3c1.9-0.7,4-0.2,5.8-0.1c11.9,1.1,23.8,2.4,35.7,3.7c6.6,0.7,13.1,1.9,19.8,2.4
							c4.9,0.4,9.7,1.1,14.7,1.4c8.2,0.4,16.3,1.6,24.4,2.5C324.7,217,325.4,217,325.4,217.9z M266.1,216.7c0.1-0.1,0.2-0.3,0.3-0.4
							c0.4,0,0.9,0.1,1.3,0.1c1.7-0.2,2.2,0.6,1.9,2.2c-0.3,1.5-0.3,3-0.5,4.4c-0.3,1.5,0.7,1.7,1.7,1.8c1,0.1,1.9,0.1,2-1.4
							c0.1-2.4,0.5-4.8,0.7-7.2c0-0.2,0-0.4,0.1-0.6c0-0.5,0.1-1,0.1-1.5c0.2-0.3,0.1-0.4-0.2-0.3c-3-0.4-6-0.7-9-1.1
							c-1.4-0.2-1.7,0.4-1.8,1.6c-0.2,2.4-0.5,4.8-0.7,7.2c-0.1,0.8-0.7,1.9,0.3,2.3c1,0.4,2.2,0.7,3.1-0.3c0.4-0.3,0.4-0.6,0.1-0.9
							C265.7,220.6,265.9,218.7,266.1,216.7z M268.5,220.7c0.1-1,0.2-1.9,0.3-2.7c0.1-0.6-0.1-1-0.8-1c-0.6,0-0.8,0.4-0.9,0.9
							c-0.1,0.8-0.3,1.7-0.3,2.5c0.1,1.1-1.1,2.9,0.4,3.1C269.1,223.6,267.9,221.5,268.5,220.7z M312.8,217c-1.3-0.7-3-1-5.2-0.7
							C311,218.1,311,218.1,312.8,217z M309.8,226.6c-0.4,0-0.7,0.2-0.8,0.7c-0.1,0.5,0.1,0.8,0.6,0.9c0.6,0.1,0.9-0.2,1-0.8
							C310.6,226.9,310.3,226.6,309.8,226.6z"/>
						<path fill="#E9E4B6" d="M378.2,224.9c0.1,0,0.3,0,0.4,0c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.5,0.4,1,0.6,1.6
							c-0.1,0.6-0.2,1.2-0.3,1.9c-0.9-0.3-2.2,0.5-2.5-1.1c0.4,0,0.8,0.2,1.1-0.3c0-0.1-0.2-0.4-0.3-0.4c-0.5-0.2-0.7,0.3-0.8,0.7
							c-0.7-0.3-1.3-1-1.7,0.3c-0.7,2.2,1,6.3,3,7.3c0.7,0.3,1.1,0.4,1.5-0.5c0.4-1.1,0.9-2.3,0.1-3.4c-0.6-0.9-0.4-1.7-0.2-2.6
							c0.4,0,0.8,0.1,1.3,0.1l0,0c-0.1,0.4,0,0.8,0.4,0.9c0.1,0.1,0.1,0.2,0.2,0.4c-0.4,0.1-0.6,0.3-0.4,0.7c0.2,0.5,0.5,0.6,1,0.5
							l0,0.1c-0.3,0.5-0.4,1,0.1,1.5c-0.3,0.4-0.6,0.8-1,1.2c-0.3,0.1-0.5,0.3-0.4,0.6c0,0.5-1.3,1.2-0.2,1.5c1.3,0.3,2.8,1,4-0.8
							c1.3-2,2.8-3.9,4.2-5.8c0.3,0,0.6,0.1,0.9,0.1c-0.4,2.1-1,4-2.8,5.3c-0.8,0.6-1.5,1.4-2.2,2.3c-1.2,1.5-2.5,1.5-4.1,0.9
							c-1.5-0.6-2.8-1.4-4.8-0.7c-1.1,0.4-3.4,0.9-4.1-1.6c-0.3-0.9-1.5-0.5-1.9,0.2c-1.5,2.3-3.4,1.7-5.2,0.9
							c-2.4-1-5.3-0.4-7.5-2.2c-0.3-0.3-0.9-0.4-1-0.8c-0.4-1.1-0.6-0.4-1,0c-2.1,1.7-3.6,1.5-5.2-0.7c-0.7-1-1.4-1.2-2.2-0.3
							c-0.6,0.7-1.3,0.8-2.1,0.6c-0.3-0.3-0.5-0.6-0.8-1c3.9-1,4.3-1.7,2.8-5.5c-0.2-0.5,0.1-0.7,0.3-1.1c1.5-2.2,0.9-3.9-1.6-4.4
							c-2-0.4-4.1-0.6-6.1-0.8c-0.9-0.1-1.1,0.4-1.2,1.1c-0.3,3.3-0.6,6.6-0.9,10c0.1-0.5-1.8,0.4-0.8-1.1c0.6-0.8-0.4-2-0.4-3.1
							c-1.1,0.3-1.1,1.6-2.1,1.8c-0.2,0-0.4-0.1-0.5-0.1c3.3-5.5-0.1-10.8-6.4-10c-0.1,0-0.3,0-0.4,0c-0.1-0.5,0.2-0.9,0.4-1.4
							c1.6,0.2,3.1,0.3,4.7,0.5c0.3,0,0.5,0.1,0.8,0.1c4.7,0.5,9.3,1,14,1.5c0.9,1.2,2.1,1.8,3.5,2.1c0.7,0.1,1.7-0.2,1.7,1
							c-0.8,0.1-2.1-0.8-2.5,0.4c-0.4,1.5-1,3.2-0.1,4.7c0.3,0.5,0.9,0.9,1.2,0c0.4-1.7,1.6-3.2,1.4-5.1c0.8,0,1.4-1,2.4-0.5
							c-0.7,3.4-2.7,6.2-4.1,9.3c-0.1,0.3-0.9,0.7-0.2,1c1,0.5,2.2,0.8,3.2,0.2c2-1.3,3.1-1.2,4.6,0.4c0.8,0.9,2,0.9,2.9,0.6
							c1-0.3,0.2-1.2,0.1-1.8c-0.4-1.8-1-3.6-1.3-5.4c-0.4-2.6-1-4.9-4.4-4.5c-0.1,0-0.3-0.1-0.4-0.1c-0.4-0.2-0.5-0.6-0.5-1
							c0-0.1,0.1-0.3,0.1-0.4c1,0.1,1.9,0.2,2.9,0.3c0.6,0.1,1.1,0.2,1.7,0.2c0.3,1.6,1.8,1.7,3.6,2.5c-3,0.3-2.5,1.4-1.4,2.6
							c0.6,0.6,0.6,1.3,0.4,2c-0.2,1,0.2,1.7,1,2.5c0.9-1,0.8-2.2,0.7-3.3c-0.1-1.1-0.1-2.3-0.1-3.3c0.1-2.1,1.9-1.5,3.2-1.3
							c0,0.1-0.1,0.3-0.1,0.4c-1-0.2-1.3,0.3-1.4,1.2c-0.2,2.8-0.5,5.5-0.9,8.2c-0.2,1.2,0,1.8,1.4,1.8c1.2,0,2.3,0.2,2.2-1.5
							c0-0.4,0-0.8,0.1-1.3c0.1-0.3,0.1-0.7,0.5-0.8c0.4-0.1,0.6,0.2,0.8,0.5c0.5,0.9,0.9,1.7,1.4,2.6c0.5,0.8,3.6,1.9,4.1,1.3
							c0.9-1-0.5-1.7-0.7-2.6c-0.1-0.3-0.3-0.6-0.5-1c-1.9-4.1-1.9-4.1,1.9-6.8c0.7,0,1.2-0.3,1.3-1.1
							C377.7,224.4,378,224.5,378.2,224.9z"/>
						<path fill="#E9E4B6" d="M448.7,231.2c0,0.6,0.4,0.8,0.9,1c0.9,0.4,1,0.9,0.6,2c-1,2.6-1.3,5.4-1.4,8.2c-0.1,1.6-1,2.3-2.1,2.8
							c-1,0.5-2.2,0.2-2.9-0.6c-1.2-1.3-2.4-1.7-4-0.6c-1.2,0.9-2.5,0.9-3.6-0.4c-0.9-1-1.9-0.5-2.7-0.1c-1.2,0.6-2.1,0.3-3.1-0.3
							c-1.4-0.8-3.1-1-4.7-0.5c-0.4,0.1-0.9,0.5-1.2,0.2c-1.5-1.6-3.3-0.6-4.9-0.5c-0.2-0.3-0.3-0.6-0.5-0.9
							c0.1-1.1,0.2-2.3,0.3-3.4c1.8,0.5,2.6-0.2,2.6-2.1c0-1.1,0.2-2.1,0.3-3.2c0.1-0.6,0.1-1.2-0.7-1.3c-0.6-0.1-1.3-0.4-1.7,0.3
							c-0.2,0.3-0.1,0.9-0.7,0.8c-0.4-0.4,0.1-1.3-0.7-1.4c-1-0.1,0,1-0.6,1.3c-0.2,0.1-0.4,0.1-0.6,0.2c0-0.7,0-1.4-0.1-2.1
							c0-0.3,0-0.6,0.1-0.9c0.1-0.1,0.2-0.3,0.3-0.4c2,0.2,3.9,0.4,5.9,0.7c-0.3,2.8-0.6,5.6-0.8,8.5c-0.1,0.8-0.9,2,0.5,2.4
							c1.2,0.3,2.6,0.7,3.6-0.8c0.9-1.3,2-2.4,3.2-3.8c0.5,1.5,0.1,2.6,0,3.7c-0.3,1.6,0.6,1.7,1.8,1.9c1.4,0.2,1.7-0.3,1.8-1.5
							c0.3-3,0.7-6,1-9.1c1.1,0.1,2.2,0.2,3.2,0.3c-0.3,3.1-0.6,6.2-0.9,9.3c0,0.6-0.5,1.4,0.4,1.6c1.4,0.2,2.8,0.9,4-0.9
							c0.8-1.2,1.6-2.6,3.1-3.4c0.3,1.3,0,2.3-0.1,3.3c-0.1,0.9-0.6,1.8,1,1.9c1.3,0.1,2.4,0.2,2.5-1.6c0.1-2.3,0.5-4.6,0.7-7
							c0.1-0.8,0.7-2-0.4-2.2c-1.1-0.2-2.4-1-3.6,0.5c-1.1,1.3-1.8,2.9-3.6,4.2c0.2-2.1,0.4-3.7,0.6-5.3c2,0.5,3.6-0.6,5.3-1.2
							c0.1,0,0.2,0,0.4,0C447.5,231,448.1,231.1,448.7,231.2z"/>
						<path fill="#F5EBB0" d="M423.5,230.2c-2-0.2-3.9-0.4-5.9-0.7c-0.1,0-0.3-0.1-0.4-0.1l0-0.1c-0.4-1.4-1.6-0.8-2.4-0.9
							c-1.6-0.3-3.2,0.8-4.8,0.2l0,0c-0.8-1.7-2.5-0.7-3.7-1.1c-1.2-0.1-1.3,0.8-1.5,1.7c-0.5,1.8,0.3,3.4,0.7,5
							c-0.1,0.6-0.1,1.1-0.2,1.7c-1.1-0.2-2.8,0-2.7-1.6c0.1-1.4-0.8-2.6-0.7-3.9c0.2-2-0.7-3.2-2-4.5c0,0,0,0,0,0
							c15.3,1.6,30.5,3.3,45.8,4.9c0.3,0,0.6,0.1,0.8,0.1c-1.7,0.5-3.3,1.7-5.3,1.2c-1.2-0.2-2.3-1-3.6-0.4
							c-1.1-0.1-2.2-0.2-3.2-0.3c-2.9-1.2-3.6-1-6,2c-1.7,0.2-1-1.3-1.5-2c0-0.3,0-0.6,0-0.9C425.9,229.3,424.8,229,423.5,230.2
							L423.5,230.2z"/>
						<path fill="#E9E4B6" d="M399.9,225.9c1.2,1.2,2.2,2.5,2,4.5c-0.1,1.2,0.8,2.4,0.7,3.9c-0.1,1.6,1.5,1.5,2.7,1.6
							c0,0.4-0.2,0.9,0.2,1.3c0,0.3-0.1,0.6-0.1,0.9c-0.1,0-0.2,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2,0.1,0.3,0.1,0.5
							c0,0.2,0,0.5,0,0.7c0,0.2-0.1,0.3-0.1,0.5c-2,0.3-3.9-1.2-5.9,0c-0.1,0-0.3-0.1-0.3-0.2c-1.3-2.9-3.2-0.7-4.7-0.4
							c-2.5,0.5-2.8,0.5-2.9-1.8c1.1-0.2,2.2,0.5,3.3-0.3c1.6-1.3,3.2-1,4.5,0.6c0.8,1,2,0.8,2.9,0.6c1-0.2,0.4-1.2,0.3-1.8
							c-0.5-2.1-1-4.1-1.5-6.2c-0.8-3.4-0.8-3.4-4.3-3.9c0-0.5,0.1-0.9,0.1-1.4c0.7,0.1,1.4,0.1,2.2,0.2
							C399.1,225.8,399.5,225.9,399.9,225.9z"/>
						<path fill="#F5F0BC" d="M445.8,230.9c-15.3-1.6-30.5-3.3-45.8-4.9c0.3-1,1.1-0.7,1.8-0.6c12.5,1.3,25,2.6,37.4,4.1
							c1.7,0.2,3.4,0.3,5,0.5C444.8,230,445.7,229.8,445.8,230.9z"/>
						<path fill="#F5F0BC" d="M398.7,225.8c-0.7-0.1-1.4-0.1-2.2-0.2c-9.3-1-18.7-2-28-3c-0.3,0-0.6-0.1-0.8-0.1
							c-2.8-0.3-5.7-0.6-8.5-0.9c-0.6-0.1-1.1-0.2-1.7-0.2c0.3-1,1.1-0.7,1.8-0.6c9,1.1,18,1.8,27,3c3.3,0.5,6.7,1,10.1,1
							C397.3,224.8,398.1,225.2,398.7,225.8z"/>
						<path fill="#F5F0BC" d="M448.7,231.2c-0.6-0.1-1.2-0.2-1.7-0.2c0.3-1,1.1-0.7,1.8-0.6c11.4,1.2,22.8,2.4,34.2,3.7
							c0.7,0.1,1.7-0.2,1.8,1C472.7,233.8,460.7,232.5,448.7,231.2z"/>
						<path fill="#F5F0BC" d="M347,220.3c-4.7-0.5-9.3-1-14-1.5c1.5-0.8,3.2-0.5,4.5-0.1c4.3,1.1,8.7,0.6,13,1.2
							c1.5,0.2,2.9,0.3,4.2,1.3c0,0.1-0.1,0.3-0.1,0.4C352.1,220.6,349.5,220.5,347,220.3z"/>
						<path fill="#F5F0BC" d="M483.9,247c-0.7-0.9-0.2-1.9-0.1-2.8c0.3-3,0.6-6,1-9c0.8,0.7-0.2,1.9,0.5,2.6c0,0.3-0.1,0.5-0.1,0.8
							C484,241.2,484.4,244.2,483.9,247z"/>
						<path fill="#F5F0BC" d="M332.2,218.6c-1.6-0.2-3.1-0.3-4.7-0.5c-0.6-0.1-1.1-0.1-1.7-0.2c0.1-0.4,0.4-0.8,0.9-0.7
							C328.5,217.8,330.7,216.7,332.2,218.6z"/>
						<path fill="#CCDEC7" d="M483.9,247c0.5-2.8,0.1-5.8,1.3-8.5c-0.1,2.9-0.4,5.7-0.9,8.6C484.2,247,484.1,247,483.9,247z"/>
						<path fill="#CCDEC7" d="M482.1,267.4c0.1-1.2,0.3-2.4,0.4-3.6C482.8,265.1,482.8,266.3,482.1,267.4z"/>
						<path fill="#F5F0BC" d="M204.5,361c-1.4,0.2-1,1.4-1.1,2.2c-1.7,15.1-3.3,30.1-4.9,45.2c-1,0-0.9,0.8-1,1.5
							c-0.2,1.7-0.4,3.5-0.6,5.2c-0.3,0-0.6-0.1-0.9-0.1c1.4-11,1.9-22.1,4-33c0.8-0.5,0.6-1.4,0.7-2.1c0.9-7.7,1.7-15.5,2.5-23.3
							c0.1-0.7,0.2-1.5,0.3-2.5c1.3,0.8-0.1,2.7,1.7,2.7c0,0.3,0,0.6-0.1,0.9c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
							c0.2,0.7-0.5,1.6,0.3,2.2c0.1,0.1,0.2,0.2,0.2,0.3C205.3,360.6,204.8,360.6,204.5,361z"/>
						<path fill="#F5F0BC" d="M205.6,326.8c-1,9-1.9,18.1-2.9,27.1c-0.5-0.5-0.3-1.2-0.2-1.8c0.8-7.9,1.7-15.7,2.5-23.6
							C205.1,327.9,205,327.2,205.6,326.8z"/>
						<path fill="#CCDEC7" d="M207.4,310.3c-0.3,2.9-0.6,5.9-0.9,8.8C206.1,316.1,206.4,313.2,207.4,310.3z"/>
						<path fill="#CCDEC7" d="M209.2,292.9c-0.2,2.2-0.5,4.5-0.7,6.7C208.2,297.3,208.4,295.1,209.2,292.9z"/>
						<path fill="#1D190E" d="M408.5,528.8c-0.7-0.1-1.4-0.2-2.1-0.3c-0.5-0.5-1.2-0.4-1.8-0.5c-18.1-1.9-36.2-3.6-54.2-5.8
							c-9.7-1.2-19.4-2-29-3.1c-2.7-0.3-5.5-0.3-8.2-1c-0.1-0.2-0.2-0.4-0.3-0.7c0.1-0.7,0.1-1.4,0.2-2.1
							c1.7-16.1,3.5-32.2,5.2-48.2c1.6-15.4,3.2-30.9,4.9-46.4c1.6-14.8,3.2-29.6,4.8-44.4c0.1-0.3,0.2-0.6,0.3-0.9
							c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.1-0.3-0.1-0.5-0.2c-4.3-1-8.7-1.4-13.1-1.4c-2.3-0.3-4.6-0.6-6.9-0.9
							c-0.1-0.2-0.2-0.4-0.3-0.7l-0.1,0l-0.1,0c0.7-5.3,1.4-10.5,1.9-15.8c1.2-11,2.3-22,3.5-33c1.6-15.1,3.3-30.2,4.9-45.3
							c2.9-0.6,5.7,0.3,8.5,0.6c0.1,0.2,0.3,0.2,0.4,0c5.2,0.5,10.5,1.1,15.7,1.6c0.7,0.5,1.4,0.6,2.1,0.2c0.7,0.1,1.4,0.1,2.1,0.2
							c3.8,0.9,7.6,1.4,11.4,1.2c3.3,0.4,6.7,0.5,10,1.2c-0.9,8.3-1.7,16.5-2.6,24.8c-0.2,1.5,0.1,2.1,1.7,2.1
							c0.9,0,1.6,0.1,1.4,1.4c-0.2,1.1-0.6,1.5-1.6,1.2c-1.9-0.5-2.5,0.3-2.1,2.1c-0.4,1.1-1.3,0.9-2.1,0.8
							c-0.9-0.2-0.8-0.9-0.6-1.5c0.6-2.2-0.5-2.7-2.4-2.7c-0.6,0-1.1-0.1-1.7-0.2c-0.7-0.1-1.8,0.2-1.7-1c0.1-1.4,1.1-0.9,1.9-0.8
							c1.2,0.1,2.4,0.3,3.6,0.4c1.3-0.3,0.8-1.6,1.2-2.4c0-0.3,0.1-0.6,0.1-0.9c0.6-5.6,1.1-11.1,1.7-16.7c0.5-4.4,0.5-4.4-4-5.1
							c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.2-0.3-0.2-0.4,0c-0.6,0-1.1-0.1-1.7-0.1c-2.6-0.9-5.3-0.6-7.9-1.2c-1.9-0.4-2.8,0.2-2.5,2.2
							c-0.3,1.2-0.6,2.5,0,3.7c-0.3,0.3-0.4,0.6-0.4,1c0,0.3,0,0.5,0,0.8c-1.4,3.3-1.4,6.7-1.3,10.2c-0.1,0.7-0.1,1.4-0.2,2.1
							c-0.7,0.5,0,1.1-0.2,1.7c-0.1,0.7-0.1,1.4-0.2,2.1c0.1,0.2,0.1,0.3,0.2,0.5l0.1,0.1c0.5,0.8,0.3,1.6-0.2,2.3
							c-0.5,0.5-0.6,1.1-0.5,1.8c-0.5,1,0,2.2-0.8,3c-0.2,0-0.4,0-0.6,0.1c-1.1-0.2-0.7-1-0.6-1.7c0.2-2,0.4-3.9,0.6-5.9
							c0.1-0.8,0.2-1.7,0.3-2.5c0.6-3.8,1-7.6,1.2-11.5c0.2-2,0.4-3.9,0.6-5.9c0.2-0.4,0.2-0.9,0.1-1.3c0-0.4,0.1-0.8,0.1-1.2
							c0.5-1.2,0.1-1.8-1.2-1.9c-7.5-0.8-15.1-1.6-22.6-2.5c-1.8-0.2-2.1,0.4-2.3,2c-0.9,8.5-1.9,17.1-2.8,25.6
							c-1.2,10.9-2.3,21.9-3.4,32.8c-0.5,5-0.5,5,4.5,5.4c0.3,0,0.5,0.1,0.8,0.1c1.2-0.1,0.8-1,0.8-1.6c0.3-2.2,0.6-4.5,0.7-6.7
							c0.1-1.4,0.6-1.7,2-1.6c3,0.3,6,0.9,9,0.8c0.3,0.1,0.5,0.3,0.8,0.4c-0.1,1.1-0.4,2.2-0.2,3.4c-0.2,1.5-0.3,3.1-0.5,4.6
							c-0.2,1-1,2.2,1,2.2c1.4,0.1,2.8,0.3,4.2,0.4c1.8,0.7,2.9,0.4,2.8-1.8c0-0.4,0.1-0.8,0.1-1.3c0.7-7.1,1.4-14.1,2.1-21.2
							c0.1-0.1,0.2-0.3,0.2-0.4c0.6,0.1,1.1,0.2,1.7,0.2l0,0c-1,9.3-1.9,18.7-2.9,28c-0.2,0.1-0.3,0.2-0.5,0.3
							c-0.4-0.1-0.9-0.3-1.3-0.4c0-1-0.5-1.3-1.4-1.4c-5.1-0.4-10.3-0.9-15.4-1.4c-0.4-0.3-0.8-0.3-1.3-0.1c-2.2-0.3-4.3-0.7-6.5-1
							c-0.9-0.1-1.5,0.1-1.6,1.2c-0.2,2.8-0.8,5.6-0.7,8.4c0,0.3,0.2,0.4,0.5,0.4c0.6,0.1,1.1,0.1,1.7,0.2c0.8,0.1,1.7,0.3,2.5,0.4
							c6.6,0.7,13.2,1.5,19.8,2.2c1.5,0.2,1.4-0.8,1.5-1.7l0,0c0.6,0,1.1,0.1,1.7,0.1l0,0c-0.2,1.6-0.5,3.2-0.6,4.8
							c-0.1,1.3-0.8,1.5-1.8,1.3l0,0c-0.7-0.8,0.7-2.7-1.5-2.9c-7.7-0.7-15.4-1.4-23-2.6c-1.2-0.2-1.7,0.2-1.7,1.4
							c-0.2,2.6-0.5,5.2-0.8,7.8c-0.2,1.4,0.3,1.8,1.6,2c7.6,1.1,15.2,1.6,22.8,2.3c1.1,0.1,1.6-0.1,1.6-1.3c0-1.5,0.7-1.8,2-1.4
							c0,0.7-0.1,1.3-0.1,2c-0.3,1.5,0.8,1.1,1.5,1.1l0,0c0.1,0.3,0.3,0.3,0.5,0.1c0.7,0.3,1.8-0.2,2,1c0.1,0.7,0.2,1.7-1,1.9
							c-3-0.8-6-0.7-9.1-1.1c-1-0.2-1.4,0.4-1.4,1.3c-0.2,3-0.5,6.1-0.8,9.1c0,0.3,0,0.5-0.1,0.8c-0.5,3.7-1.1,7.3-1.2,11
							c0,0.3,0,0.6,0,0.8c-0.1,0.8-0.2,1.7-0.3,2.5c0,0.3-0.1,0.6-0.1,0.9c-1,9.1-2.1,18.2-3.1,27.2c-1.5,13.8-3,27.6-4.5,41.4
							c-0.3,0.4-0.3,0.8-0.2,1.2c-1,0.6-0.6,1.8-0.6,2.5c0,2.3-1.4,4.5-0.3,6.8c-0.6,0.4-0.5,1-0.5,1.6c-0.7,2.6-0.8,5.3-1.1,8
							c-0.1,0.6,0.1,1,0.6,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.8-0.1,1.6-0.3,2.3c-0.5,1.8,0.2,2.5,2.1,2.6c3.3,0.2,6.5,0.6,9.7,1
							c0.7,0.1,1.5,0,1.5,1.1c-0.1,1.1-0.9,0.9-1.6,0.8c-3.5-0.3-6.9-0.6-10.4-1.1c-1.4-0.2-1.9,0.3-2,1.6
							c-0.4,4.5-0.8,8.9-1.4,13.3c-0.2,1.7,0.2,2.2,1.9,2.3c17.1,1.8,34.3,3.6,51.4,5.5c1.5,0.2,2-0.2,2.2-1.7
							c0.4-4.6,0.9-9.2,1.4-13.8c0.2-1.3-0.3-1.7-1.4-1.8c-0.1-0.3-0.2-0.3-0.4,0c-9.4-0.7-18.6-2.1-28-3.1
							c-0.9-0.8-2.1-0.4-3.1-0.7c-0.1-0.3-0.1-0.6-0.2-0.9l0.1,0.1l0.1,0c0.3,0,0.6-0.1,0.7-0.5c0.9,0.1,1.7,0.2,2.6,0.3
							c0.6,0.8,1.5,0.6,2.3,0.7c9.7,1.1,19.5,2.1,29.2,3.2c0.7,0.1,1.5-0.1,2.3,0.9c-2.7-0.2-2.9,1.5-3,3.4c-0.3,4-0.8,8-1.2,12.1
							c-0.1,0.7-0.5,1.4,0.3,2c0.4,0.1,0.7,0.3,1.1,0.4c0.2,0.5,0.6,0.5,1.1,0.6c3.5,0.4,6.9,0.7,10.4,1.1c0.5,0.1,1,0.2,1.4-0.3
							c2.7,0.1,5.4,0.3,8.1,0.8c0.1,0,0.2,0,0.3,0c1.6-0.1,1-1.5,1.4-2.3c1-2.5,1.1-5.2,1.3-7.9c0-0.2-0.3-0.4-0.4-0.7
							c0.1-0.5,0.1-1.1,0.2-1.6c0.8-0.4,0.8-0.8,0.1-1.3c0.1-1,0.2-2,0.3-3c0.1-1-0.3-0.9-1-0.6c-0.3,0-0.6-0.1-0.9-0.1
							c-1.2-1-3.1-0.1-4.6-1.4c1.9-0.3,3.5,0.1,5.1,0.3c1.5,0.1,2.3-0.4,2.4-1.9c0.2-1.8,0.4-3.6,0.6-5.4c0.5,0.1,1.1,0.1,1.6,0.2
							c-0.7,6.8-1.4,13.5-2.1,20.3c-0.2,1.1-0.5,2.2-0.3,3.4c-0.6,2.2,0.5,2.8,2.5,2.8c2.3,0.1,4.5,0.6,6.7,0.8
							c12.5,1.3,24.9,2.6,37.4,3.9c0.8,0.1,1.6,0.4,2.1-0.6c0.4-0.6,0.5-1.1,0.1-1.7l-0.2,0c0-0.1,0-0.3,0.1-0.4
							c0-0.3,0.1-0.6,0.1-0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.4,0.3-0.8,0.4-1.2c0.7-6.5,1.4-13,2.1-19.5c0.1-0.5,0.2-1-0.2-1.4
							l-0.2,0c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.6-0.8,0.7-1.7,0.3-2.6c-0.1,0-0.3-0.1-0.4-0.1c0-0.1-0.1-0.2-0.1-0.3
							c-0.5-0.8-1-0.7-1.6-0.2l0,0.1c-0.8-0.4-1.7-0.4-2.6-0.3l0-0.1c-0.3-0.5-0.8-0.5-1.3-0.6c-6.2-0.7-12.5-1.3-18.7-2
							c-0.5-0.1-1.1-0.2-1.6,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.6-0.1-1.2-0.2-1.7-0.3l0,0c0.2-1.8,0.4-3.7,0.6-5.5
							c0.7-0.8,1.2-0.7,1.7,0.2c0,0.7-0.1,1.4-0.1,2.1c0,0.2,0,0.4-0.1,0.6c0,0.3,0.1,0.6,0.1,0.9c0.1,0.3,0.3,0.3,0.6,0.2l-0.1,0.1
							c0.2,0.4,0.5,0.5,0.9,0.1c1,0.1,2,0.2,3,0.3c0.3,0.4,0.7,0.4,1.1,0.5c6.4,0.7,12.8,1.4,19.1,2c0.5,0.1,1,0.1,1.3-0.3
							c0.4-0.3,0.5-0.7,0.7-1.1c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3c0.1-0.7,0.1-1.4,0.2-2.1c0.3-0.1,0.3-0.3,0-0.4
							c0.1-1.1,0.2-2.3,0.2-3.4c0-0.2,0-0.3,0-0.5l0.1,0.1c0.9-2.5,1-5,0.9-7.6c0-0.3,0.1-0.6,0.1-0.9c1.4-0.9,0.6-2.3,0.7-3.5
							c0-2.2,0.9-4.4,0.4-6.6c0-0.4-0.1-0.7-0.1-1.1c-2.8-0.7-5.6-0.8-8.4-1c-0.7,0-1.5-0.1-2.2-0.1c-5.1-0.5-10.1-1.3-15.2-1.5
							c-0.4-0.1-0.6,0-0.6,0.4c-0.3,0.4-0.3,0.8-0.1,1.2l-0.1-0.1c-0.3,0.4-0.3,0.9-0.1,1.3c-0.1,1-0.2,2-0.3,2.9
							c0,0.2,0,0.3-0.1,0.5c-0.5,2.3-0.3,4.8-1,7.1c-0.4,0-0.8,0-1.2-0.1c0-2.2,0.7-4.3,0.5-6.5c0.4-0.7-0.2-1.4-0.2-2.1
							c0.6-0.8,0.4-1.8,0.6-2.7c0.1-0.3,0.2-0.6,0.3-0.9c0.4-1.4-0.2-1.9-1.5-2c-2.7-0.2-5.3-0.5-8-0.8c-3.3-0.9-6.7-1.5-10.2-1.1
							c-0.2,0.1-0.4,0.2-0.7,0.3c-0.6,0.1-0.9,0.6-1,1c-0.6,2.6-0.8,5.2-0.9,7.8c0,0.2,0.4,0.4,0.6,0.6c-1.2,3.9-1.1,8-1.6,12.1
							c-0.2,1.5-0.7,2-2.1,1.7c0.1-1.3,0.1-2.6,0.2-3.9c0.4-1.8,0.6-3.6,0.6-5.5c0.1-0.3,0.2-0.5,0.3-0.8c1.4-1,0.9-2.6,0.9-3.9
							c0-1.8,1-3.6,0.1-5.5c0-0.4,0.1-0.8,0.1-1.2c1-0.4,0.7-1.3,0.8-2c0.3-2.4,0.6-4.8,0.9-7.2c0.4,0,0.8,0.1,1.2,0.1
							c-0.1,1-0.2,2.1-0.3,3.1c0,0.5-0.1,1.1,0.7,0.7c0.3,0,0.6,0,0.9,0c0.6,0.1,1.1,0.2,1.7,0.4c1.5,0.6,3,0.8,4.6,0.5
							c0.1,0,0.3-0.1,0.4-0.1c0.1,0.1,0.2,0.2,0.4,0.3c0.6,1.1,1.4,0.5,2.2,0.3c4.5,0.4,9,0.8,13.5,1.2c8.8,0.9,17.5,1.8,26.3,2.7
							c0.1,0,0.3,0,0.4,0.1l-0.1,0.2c-0.5,5.3-1.1,10.6-1.6,15.9c-1.7,16.2-3.4,32.3-5.1,48.5l0,0c-0.2,0-0.3,0-0.5,0
							c-9.1-0.9-18.3-1.9-27.5-2.8C417.1,529.6,412.8,529.2,408.5,528.8z M329.2,343.3c0.1-0.6,0.1-1.1,0.2-1.7
							c0.3-2.7,0.3-2.7-2.5-3c-0.2,0-0.4,0-0.6-0.1c-4.5-0.5-4.5-0.5-4.9,4.1c-0.4,3.9-0.4,3.9,3.5,4.3
							C328.8,347.3,328.8,347.3,329.2,343.3z"/>
						<path fill="#1D190E" d="M386.8,312.1c0.4,0.2,0.8,0.3,1.3,0.1c9.9,1.3,19.8,2.2,29.7,3.1c1,0.4,2,0.4,3,0.3
							c0.2,0,0.5,0.1,0.7,0.1c0.1,0.3,0.1,0.5,0.2,0.8c-0.5,2-0.7,3.9-0.7,5.9c0,0.3-0.1,0.6-0.1,0.9c-1,7.6-1.9,15.2-2.4,22.9
							c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.1-0.3,0.1-0.4,0.2c-1.7-0.1-2.1-0.8-1.9-2.6c1-8.1,1.7-16.2,2.7-24.3
							c0.2-1.7-0.3-2.2-1.9-2.3c-9.5-0.9-18.9-2-28.4-3c-0.4-0.4-0.8-0.4-1.2-0.1c-0.3,0-0.6,0-0.9,0.1c-0.3,0-0.4,0.2-0.4,0.5
							c-1.3,12.2-2.6,24.4-3.9,36.7c0,0.2,0,0.4-0.1,0.6c-0.1,0.8-0.2,1.6-0.3,2.3c-0.1,0.3-0.1,0.4,0.2,0.5
							c0.3,0.1,0.6,0.2,0.9,0.3c4.2,0.8,8.4,1.3,12.7,1.3c0.9,0.1,1.7,0.2,2.6,0.3c0.8,0.6,1.7,0.3,2.6,0.5c0.6,0.2,1.7-0.2,1.7,0.6
							c0,1-1,0.6-1.6,0.7c-0.1,0-0.2,0.1-0.4,0.2c-2.6-0.2-5.1-0.4-7.7-0.6l0.1-0.1l-0.1-0.1c-2.8-0.7-5.6-1-8.5-0.9
							c-2.9-0.2-2.9-0.2-3.2,2.7c-0.2,1.9-0.3,3.8-0.4,5.7c-0.1,0.3-0.2,0.5-0.2,0.8c-0.4,1.5-0.6,3.1-0.5,4.7
							c-0.2,1.5-0.3,3-0.6,4.4c-0.2,1.2,0.3,1.6,1.4,1.7c3.2,0.3,6.5,0.6,9.7,0.9c1.7,0.2,2.9,0.7,2.3,2.8c-0.9,0-1.8,0-2.7-0.1
							c-7-0.7-13.9-1.4-20.9-2.1c-0.6-0.2-1.2-0.3-1.8-0.5c-0.8-0.6-1.6-0.7-2.5-0.3c-0.9,0-1.8-0.1-2.6-0.1
							c-1.4-0.1-2.8-0.2-4.2-0.3c-0.7-0.1-1.4-0.2-2.1-0.3c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6-0.1-0.8-0.1
							c-0.2-0.1-0.5-0.2-0.7-0.2c-0.3,0-0.6,0-0.9,0c-1.2-0.9-0.4-1.9-0.2-2.8c0.4,0.1,0.9,0.1,1.3,0.2c1.3,0.4,1.9,0,2-1.5
							c0.7-6.5,1.5-12.9,2.2-19.4c2.5-0.6,1.2-2.4,1.1-3.8c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-1,0.2-2,0.3-3.1
							c0.6-0.3,0.9-0.7,0.9-1.3c0-3.3,0.5-6.6,1.1-9.9c0.2-1.1-0.5-1.8-1.1-2.6c-0.2-1.9,0-3.7,0.6-5.6c0.1-0.3,0.1-0.5-0.2-0.6l0,0
							c0-0.3,0-0.6,0-0.9l0.1-0.1c1,0.1,1.9,0.2,2.9,0.3c0,0.2,0.1,0.5,0.1,0.7c-0.3,1.2,0.6,1.1,1.2,1.1c2.3,0.2,4.6,0.4,7,0.6
							c0.2,0,0.4,0.1,0.6,0.1c0.4,0,0.7,0,1.1,0c2.7,0.7,5.5,0.9,8.2,1c0.3,0,0.5-0.2,0.4-0.6c0-0.3,0-0.6,0-0.9
							c0.2-0.7,0.4-1.4,0.2-2.1c0.3-0.5,0.3-1.1,0.2-1.7c0.3-1.1,0.4-2.2,0.4-3.4c0.1-0.6,0.2-1.1,0.2-1.7c0.2-0.3,0-0.5-0.3-0.6
							l0,0c0-0.5,0.1-1.1,0.1-1.6c1.1-1.6,0.7-2.4-1.3-2.3c-0.3,0-0.7,0-1,0c-1-0.3-0.7-1.1-0.6-1.8c0.2-0.8,0.9-0.8,1.5-0.7
							C383,311.9,384.9,312,386.8,312.1z M361.1,344.3L361.1,344.3c0,0.3,0.1,0.4,0.3,0.2c3.9,0.4,7.7,0.7,11.6,1.3
							c2.2,0.4,4.4,0.8,6.7,0.5c0.6-0.5,0.8-1.1,0-1.6c-5.4,0.2-10.6-1.6-16-1.9c-0.1-0.2-0.2-0.2-0.4,0c-0.9,0.1-2.2-0.7-2.4,1.1
							C360.7,344.2,360.8,344.3,361.1,344.3z M359.8,360.1c-0.1,0.1-0.3,0.2-0.4,0.3l0,0.1c-2.2,0.3-1,2-1.1,3.1
							c-0.4,3.6-0.8,7.3-1.1,11c-0.1,0.8,0,1.5,1.1,1c0.3,0,0.6,0.1,0.9,0.1c0.4,0.4,0.9,0.4,1.4,0.5c3.2,0.5,6.4,0.5,9.6,1.4
							c1.6,0.4,3.5,1,5.1-0.1c0.3,0,0.6,0,0.9,0.1c0.5,0.4,0.8,0,0.8-0.4c0.4-4.5,1.2-9,1.3-13.6c-0.1-0.9-0.3-1.5-1.4-1.2
							c-0.5-0.3-1.1-0.4-1.7-0.2c-2.3-0.4-4.5-0.7-6.8-1.1C365.5,360.1,362.7,359.7,359.8,360.1L359.8,360.1z M380.3,340.8
							c0.7-0.2,0.7-0.6,0.4-1.1c-0.2-0.5-0.6-0.6-1-0.7c-2.2-0.8-4.6-0.8-6.9-0.9c-1-0.1-2.1-0.2-3.1-0.3c-0.7-0.4-1.5-0.5-2.3-0.2
							c-0.2,0-0.4,0-0.7-0.1c-0.2,0-0.4,0-0.6-0.1c-1.1-0.1-2.1-0.2-3.2-0.3c-0.4-0.3-0.7-0.4-0.9,0.2c-0.2,0.7-0.4,1.4,0.6,1.7
							c-0.2,0.3-0.4,0.6-0.5,0.9c-1,1-0.9,1.7,0.6,1.9c1.4,0.2,2.8,0.3,4.2,0.5c1.7,0.6,3.5,0.7,5.2,0.6c2.2,0.3,4.4,0.5,6.5,0.9
							c1.3,0.2,1.6-0.4,1.6-1.5c-0.2-0.2-0.3-0.4-0.5-0.7C379.7,341.1,379.9,340.9,380.3,340.8z M381,333.3L381,333.3
							c-0.2-0.5-0.5-1,0.3-1.3c0.3-1,0.3-1.7-1-1.8c-5.5-0.6-10.9-1.2-16.4-1.9c-1.3-0.1-1.4,0.6-1.3,1.5c0.3,0.6,0.7,0.7,1.2,0.3
							c3.3,0.3,6.6,0.4,9.8,1.2c0.2,0,0.3,0.1,0.5,0.1c0.3,0,0.4,0.2,0.5,0.5c-2-0.2-4.1-0.5-6.1-0.6c-1.9-0.1-3.7-0.9-5.6-0.2
							c-1.3,1.4-0.5,1.7,0.9,1.9c3.9,0.4,7.9,1,11.8,1.3c1.8,0.2,3.7,1,5.6,0.1C381.6,334,381.7,333.6,381,333.3z M361.4,354.3
							c-0.5-0.2-1-0.4-1.4,0.2c-0.2,0.6-0.2,1.1,0.3,1.6c0.3,0.4,0.8,0.4,1.2,0.2c1.3,0.1,2.6,0.3,3.9,0.4c0.1,0,0.2,0,0.4,0
							c1.2,0.1,2.3,0.2,3.5,0.3c0.3,0.3,0.7,0.3,1,0.2c2.7,0.3,5.4,0.6,8.1,0.9c0.2,0.1,0.5,0.1,0.4-0.3c0.1-0.3,0.1-0.6,0.2-0.9
							c0.2-0.2,0.1-0.4-0.2-0.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.3,0-0.4,0C372,355.9,366.8,354.8,361.4,354.3z M369.4,352.2
							c-0.2,0-0.4,0-0.6,0c-0.4,0-0.9-0.1-1.3-0.1c-0.5-0.4-1.1-0.4-1.6-0.2c-0.3,0-0.6-0.1-0.9-0.1c-0.8-0.4-1.6-0.5-2.5-0.3
							c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.2-0.2-0.2-0.4-0.1c-0.6,0.1-1.1,0.4-1,1.1c0.1,0.9,0.9,0.8,1.5,0.8c4,0.5,8,1,12,1.4
							c1.8,0.2,3.6,0.9,5.4,0.2c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0-0.3c-0.2-0.3-0.4-0.7-0.6-1c-2.3-0.9-4.7-1-7.2-0.9
							C370.7,352.5,370.1,352.4,369.4,352.2z M359.6,357.5c-0.3,1.1,0.2,1.5,1.2,1.6c0.1,0.2,0.2,0.2,0.4,0.1
							c5.2,0.2,10.2,1.3,15.4,1.7c0.7,0.1,1.7,0.5,1.8-0.7c0.1-1.3-1-0.9-1.7-1c-5.6-0.7-11.2-1.3-16.8-2
							C359.6,357.1,359.4,357.1,359.6,357.5z M362.2,334.2c-0.1,0.4-0.2,0.8-0.3,1.2c-0.3,0.4,0,0.4,0.2,0.4
							c6.1,0.7,12.3,1.3,18.4,2c0.3,0,0.5,0,0.3-0.3c0-0.3,0.1-0.6,0.1-0.9c0.2-0.3,0.1-0.4-0.2-0.4c-6.1-0.7-12.1-1.4-18.2-2.2
							C362.3,333.8,362.2,334,362.2,334.2z M370.5,351.3c1.9,0.5,4.6,0.6,7.3,0.9c0.6,0.1,1.4,0.2,1.4-0.8c0.1-0.8-0.5-0.9-1.1-1
							c-5.4-0.6-10.9-1.3-16.3-1.9c-0.6-0.1-1.4-0.2-1.4,0.8c-0.1,1,0.7,0.9,1.3,1C364.5,350.7,367.2,351,370.5,351.3z M370.7,348.5
							c1.9,0.4,4.5,0.6,7.1,0.9c0.6,0.1,1.5,0.4,1.7-0.7c0.2-1.1-0.7-1-1.4-1.1c-5.2-0.6-10.4-1.2-15.6-1.8
							c-0.7-0.1-1.7-0.5-1.8,0.8c-0.1,1.2,1,0.9,1.7,1C365,347.9,367.5,348.1,370.7,348.5z"/>
						<path fill="#3E3821" d="M277.8,508.9c-0.3,0-0.6-0.1-0.8-0.1c-1.1-0.4-2.2-0.5-3.4-0.3c-0.6-0.1-1.2-0.1-1.8-0.2
							c-4.1-0.6-8.1-1.2-12.3-1.3c-0.4,0-0.8-0.1-1.3-0.1c-0.4-0.4-0.8-0.4-1.3-0.2c-1.1-0.1-2.3-0.2-3.4-0.3c-0.1,0-0.3,0-0.4,0
							c-2.3-0.2-4.5-0.5-6.8-0.7c-0.3,0-0.6-0.1-0.8-0.1c-4.2-0.9-8.5-1.1-12.7-1.5c-2.2-0.3-4.4-0.5-6.7-0.8
							c-1.2-0.4-2.4-0.5-3.6-0.4c-0.6-0.1-1.3-0.1-1.9-0.2c-2-0.3-4-0.6-6-0.9c0-0.3,0.1-0.6,0.1-0.8c3.8,0.3,7.6,0.7,11.4,1
							c0.3,0,0.7,0.1,1,0.1c6.5,0.7,12.9,1.5,19.4,2.2c2.3,0.4,4.5,0.7,6.8,0.7c1.6,0.2,3.1,0.3,4.7,0.5c0,0.3,0.1,0.6,0.4,0.5
							c0.1,0,0.2-0.2,0.4-0.4c0.7,0.1,1.4,0.2,2.1,0.3c0.8,0.8,1.6,0.9,2.6,0.3c0.3,0,0.5,0.1,0.8,0.1c0.8,0.8,1.6,0.9,2.6,0.3
							c3.6,0.3,7.3,0.7,10.9,1c0.7,0.1,1.5,0.5,2.1-0.4c0.3-0.4,0.4-0.9,0.2-1.4c0.5-4.6,0.9-9.1,1.4-13.7c0.6-1.9,0.5-3.8,0.8-5.7
							c0.1-0.5-0.2-0.8-0.7-0.8c-0.7-0.1-1.4-0.1-2.1-0.2c-2.1-0.4-4.2-0.6-6.3-0.7c-0.4,0-0.8-0.1-1.3-0.1c-1.5-0.8-3-1-4.7-0.4
							c-0.4-0.1-0.8-0.1-1.3-0.2c-0.4-0.6-0.8-0.6-1.3-0.1c-0.4-0.1-0.8-0.1-1.2-0.2c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.5,0-0.8-0.1
							c-0.3-0.5-0.5-0.5-0.9-0.1c-0.4-0.1-0.8-0.1-1.3-0.2c-0.5-0.7-1.1-0.8-1.7-0.2c-2.7-0.3-5.4-0.6-8-0.9
							c-0.4-0.1-0.8-0.2-1.2-0.3c-0.3-0.3-0.6-0.4-0.9-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1-0.3-0.3-0.3-0.5-0.1
							c-0.4-0.1-0.8-0.1-1.2-0.2c-0.8-0.4-1.7-0.5-2.6-0.3c-2.4-0.2-4.8-0.5-7.1-0.7c-0.1-0.3-0.3-0.3-0.5,0c-0.8,0-1.7-0.1-2.5-0.1
							c-0.1,0-0.3,0-0.4-0.1c-0.7-0.1-1.4-0.2-2.1-0.3c-0.5-0.4-1.1-0.5-1.8-0.2c-2.7-0.2-5.4-0.5-8-0.7c-1.4-0.7-2.8-1-4.2-0.4
							c-2.1-0.2-4.2-0.5-6.4-0.7c-3.4-0.5-6.8-0.9-10.2-1.1c-1-0.1-2-0.2-3-0.3c-0.7-0.1-1.4-0.1-2.1-0.2c-0.3,0-0.6-0.1-0.8-0.1
							c-0.1,0-0.3-0.1-0.4-0.1c-1-0.1-0.7,0.7-0.8,1.1c-0.7,6.5-1.3,12.9-2,19.4c-0.2,1.4,0.4,1.9,1.6,2.1c-0.5,0.6-1.4-0.1-1.8,0.7
							c-1.8,0.1-1.2-1.1-1.1-2c1.5-14.3,2.9-28.5,4.6-42.8c1.6-13.5,2.9-27.1,4.3-40.7c0.5-4.7,0.5-4.7,5-4.2
							c21.5,2.2,43.1,4.4,64.6,6.9c7.4,0.9,14.8,1.5,22.2,2.2c0.2,0.3,0.4,0.6,0.6,0.9c-0.2,1.8-0.5,3.6-0.7,5.4
							c-1,6.8-1.7,13.6-2.2,20.4c-0.2,2.1-0.4,4.2-0.7,6.3c0,0.2,0,0.3,0,0.5c-0.2,2.3-0.5,4.5-0.7,6.8c-0.1,0.4-0.3,0.8-0.3,1.2
							c-1,8.6-2,17.2-2.8,25.7c-0.5,4.6-1.2,9.1-1.3,13.7c-0.2,2.2-0.5,4.5-0.7,6.7c-0.3,0.5-0.3,0.9-0.1,1.4
							c-0.1,0.7-0.3,1.4-1.3,1.3C279.2,508.8,278.5,508.7,277.8,508.9z M284.1,463.4c-0.2,0-0.4,0-0.6,0c-1.7-0.2-3.4-0.4-5.1-0.5
							c-1.9-0.5-3.9-0.6-5.9-0.6c-1.1-0.1-2.3-0.2-3.4-0.3c-2.2-0.4-4.5-0.7-6.8-0.7c-5.9-0.7-11.8-1.4-17.8-2c-0.2,0-0.3,0-0.5-0.1
							c-0.5-0.2-1.1-0.3-1.7-0.1c-3.8-0.7-7.6-1.1-11.5-1.2c-9-0.9-18.1-1.9-27.1-2.8c-0.1,0-0.3,0-0.4-0.1
							c-2.4-0.3-4.8-0.5-7.2-0.8c-0.4-0.2-0.7-0.2-0.8,0.3c-0.1,0.6-0.1,1.1-0.2,1.7c0,0.1-0.1,0.3-0.1,0.4
							c-1.1,5.7-1.3,11.5-2.1,17.3c-0.2,1.3,0.9,1.3,1.7,1.3c11.8,1.2,23.5,2.4,35.3,3.6c0.1,0,0.3,0,0.4,0
							c16.8,2,33.6,3.9,50.4,5.6c1.4,0.1,1.5-0.5,1.6-1.5c0.7-6.2,1-12.5,2.2-18.6C284.7,463.6,284.5,463.4,284.1,463.4z
							 M199.4,414.7c0,0.2,0,0.4-0.1,0.7c-0.4,4.4-0.8,8.7-1.2,13.1c-0.4,0.4,0.1,0.8-0.1,1.2c-0.8,7.3-1.6,14.5-2.4,21.8
							c-0.1,0.6-0.1,1.2,0.7,1.2c2.2,0.5,4.5,0.7,6.8,0.8c0.6,0.1,1.1,0.1,1.7,0.2c1.8,0.4,3.6,0.6,5.5,0.5c0.8,0.1,1.5,0.3,2.3,0.3
							c12.9,1.4,25.9,2.8,38.8,4.2c1,0.2,2,0.4,3,0.3c0.9,0.1,1.7,0.7,2.6,0.4c0.4,0,0.8,0,1.2,0c8.4,0.9,16.8,1.8,25.1,2.7
							c1,0.1,1.4-0.2,1.5-1.2c0.7-6.8,1.4-13.6,2.1-20.3c0.1-1-0.2-1.4-1.2-1.5c-1.8-0.1-3.7-0.4-5.5-0.6
							c-11.1-1.2-22.2-2.1-33.2-3.7c-0.1,0-0.2,0-0.3,0c-12.4-1.3-24.7-2.5-37.1-3.8c-0.4,0-0.9,0-1-0.7c0.6-0.4,1.3-0.2,1.9-0.2
							c6.8,0.7,13.5,1.4,20.3,2.1c18.1,1.9,36.1,3.9,54.2,5.8c1.7,0.2,2.2-0.2,2.4-1.9c0.4-4.8,0.9-9.6,1.5-14.4
							c0.2-1.5-0.3-1.9-1.7-2.1c-11.6-1.3-23.3-2.3-34.9-3.7c-14.6-1.7-29.2-3.1-43.8-4.7c-1-0.1-2-0.1-3-0.2
							c-0.4,0-0.8-0.1-1.3-0.1c-4.4-0.9-4.4-0.9-4.8,3.3C199.4,414.5,199.4,414.6,199.4,414.7z"/>
						<path fill="#1D190E" d="M214.8,271.6c0.1-0.5,0.3-1,0.4-1.6c0.3,0,0.6-0.1,0.9-0.1l-0.1,0.2c0.5,0.6,1,0.6,1.7,0.3
							c0.6,0.1,1.1,0.1,1.7,0.2c4.3,0.9,8.7,1.4,13.1,1.4c0.9,0.1,1.7,0.2,2.6,0.3c0.5,0.5,1.1,0.5,1.7,0.2l0.1-0.1l-0.1-0.1
							c1.3,0.2,2.6,0.4,3.8,0.6c0.1,0.2,0.3,0.3,0.4,0c0.7,0,1.4,0,2.1,0.1c2.4,0.3,4.8,0.6,7.2,1c0.1,0.2,0.3,0.2,0.4,0
							c6.9,0.7,13.9,1.2,20.8,2.3c0.2,0.3,0.5,0.4,0.8,0.1c0.7,0.1,1.4,0.1,2.1,0.2c0.4,0.1,0.8,0.3,1.2,0.4
							c8.6,0.9,17.2,1.9,25.8,2.8c0.5,0.1,1,0.2,1.4-0.2c0.7,0,1.4,0,2.1,0l-0.1,0.1c0.4,0.5,0.9,0.5,1.4,0.3c0,0,0,0.1,0,0.1
							c-0.2,0.2-0.1,0.4,0.1,0.6c-0.1,0.6-0.1,1.1-0.2,1.7c-0.3,0.1-0.3,0.3-0.1,0.4c-0.4,4.5-0.8,9.1-1.2,13.6c0,0.1,0,0.3,0,0.4
							c-0.1,1.1-0.2,2.3-0.4,3.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.7-0.1,1.4-0.2,2.1c-0.1,0.3-0.1,0.6-0.2,0.9c-1,6.5-1.5,13-2.1,19.5
							c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.4-0.5,0.8-0.1,1.2c0,0.4,0.1,0.9,0.1,1.3c0,0.3-0.1,0.5-0.1,0.8c0,0.1,0,0.3,0,0.4
							c-0.8,7.8-1.6,15.5-2.5,23.3c0,0.1,0,0.3,0,0.4c-0.8,6.2-1.7,12.4-2.2,18.6c-0.5,0.5-1.1,0.5-1.7,0.4c-0.2,0-0.4,0-0.6,0
							c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8-0.1-1.3-0.1c-2.3-0.7-4.7-0.6-7.1-0.9c-0.2-0.2-0.4-0.2-0.6-0.1c-0.2,0-0.4,0-0.6-0.1
							c-0.5-0.3-1-0.4-1.5-0.2c-0.5,0-1.1,0-1.6,0c-3.5-0.4-7-0.8-10.6-1.2c-0.2-0.2-0.3-0.3-0.5-0.1c-0.4,0-0.8-0.1-1.2-0.1
							c-2.5-0.7-5.1-0.9-7.7-0.9c-1.1-0.2-2.2-0.4-3.3-0.5c-4.6-0.6-9.4-0.7-14-1.7c1.3-1.3,2.8-0.5,4.3-0.5
							c2.4,0.5,4.9,0.9,7.4,0.8c0.7,0.1,1.3,0.1,2,0.2c0.9,0.4,1.8,0.5,2.7,0.3c2.8,0.3,5.6,0.7,8.4,1c0.4,0.2,0.8,0.2,1.3,0.1
							c0.4,0,0.9,0.1,1.3,0.1c0.3,0,0.6,0.1,0.8,0.1c0.4,0,0.8,0.1,1.3,0.1c1.1,0.3,2.2,0.5,3.4,0.4c0.3,0,0.6,0.1,0.9,0.1
							c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0.1,0.9,0.1c0.1,0,0.3,0,0.4,0.1c0.3,0,0.6,0.1,0.9,0.1c0.3,0,0.5,0.1,0.8,0.1
							c0.3,0,0.6,0.1,0.9,0.1c0.1,0,0.3,0,0.4,0c3.9,0.6,7.9,1,11.9,1.2c0.4,0.1,0.9,0.1,1.3,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.1,0.1
							c0,0-0.1,0-0.1,0c0.1-0.1,0.2-0.3,0.3-0.4c0.7-0.5,0.4-1.3,0.5-2c0.5-3.1,0.4-6.4,1.2-9.5c0.1,0,0.1-0.1,0.1-0.1
							c0-0.1,0-0.2-0.1-0.3c0.2-2,0.4-4,0.6-5.9c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0-1,0-1.9,0.1-2.9
							c0-0.4,0.1-0.7,0.1-1.1c0-0.1,0-0.2,0-0.3c1.3-7.2,1.8-14.4,2.4-21.6c0.2-0.4,0.3-0.9,0.5-1.3c-0.1-0.1-0.1-0.2-0.2-0.4
							c0.1-0.9,0.2-1.7,0.3-2.6c0.5-0.9,0.6-1.9,0.3-2.9c0.4-4.1,0.9-8.3,1.3-12.4c0.1,0,0.1,0,0.2-0.1c0-0.1-0.1-0.2-0.1-0.3
							c0.1-0.4,0.2-0.9,0.2-1.3c0.1,0,0.2-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.3c0-0.6,0-1.2,0-1.8c0-0.3,0.1-0.5,0.1-0.8
							c0.2-0.2,0.6-0.3,0.3-0.7c0.4-3.9,0.8-7.7,1.1-11.6c0.7-1.7,0.9-3.5,0.6-5.4c0.1-0.5,0.1-0.9,0.2-1.4c0.3-0.2,0.2-0.4,0-0.6
							c-0.1-0.1-0.3,0-0.5,0.1c-10.2-1.1-20.4-2.1-30.6-3.2c-1.2-0.7-2.4-0.8-3.7-0.4c-6.6-0.8-13.3-1.6-20-2
							c-0.8-0.1-1.5-0.3-2.3-0.4c-10.1-1.1-20.2-2.2-30.4-3.2c-1.2-0.7-1.5-0.7-1.8,0.2c-0.6,6.3-1.3,12.5-1.9,18.8
							c0,0.4,0,0.9,0,1.3c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3,0-0.7-0.1-1-0.1c0-0.7,0-1.4,0-2.1
							c0.3-0.8,0.4-1.7,0.3-2.6c0.5-3.1,0.9-6.2,1-9.3c0.5-2.4,0.7-4.8,0.8-7.2C214.8,271.9,214.8,271.7,214.8,271.6z"/>
						<path fill="#1D190E" d="M416.7,362.4c0,0.4-0.1,0.8-0.1,1.3c-0.4,1.7-0.6,3.4-0.5,5.1c-0.1,0.4-0.1,0.8-0.2,1.3
							c-1.2,5-1.4,10.1-2,15.2c-2.4,20.9-4.6,41.9-6.8,62.8c-2,0.4-2.6-0.3-2.4-2.4c0.7-5.6,1.2-11.3,1.8-16.9
							c0.7-6.1,1.7-12.2,1.9-18.4c0.2-1.2,0.4-2.5,0.6-3.7c0-0.2,0-0.4,0.1-0.6c0.1-0.5,0.1-1,0.2-1.5c1-6.2,1.4-12.5,2.2-18.7
							c0.2-1.8-0.4-2.3-2.1-2.5c-2.7-0.3-5.5-0.1-8.1-1.2c0-0.5,0-1.1,0-1.6c2.3,0.2,4.6,0.4,6.9,0.6c0.8,0.1,1.7,0.3,2.5,0.3
							c0.8,0.1,1.4-0.3,1.4-1.2c0.1-1.3,0.6-2.5,0.2-3.8c0-0.3,0-0.6,0-0.9c0.1-0.1,0.1-0.2,0-0.3c0.1-0.3,0.2-0.6,0.3-0.8
							c0.1-0.2,0.1-0.4-0.1-0.5c0.5-3.7,1-7.4,1.5-11.1c0.2-1.1,0.1-2-1.4-1.9c-0.6,0-1.2-0.1-1.3-0.8c-0.1-0.7,0.3-1,0.9-1.2
							c1.5,0.5,2.4,0.5,2.1-1.6c-0.3-1.9,0.6-2.6,2.7-2.6c0.8,1.1-0.1,2.3,0.2,3.5C416.8,359.5,416.6,360.9,416.7,362.4z"/>
						<path fill="#F5F0BC" d="M327.8,376.4c-1.6,14.8-3.2,29.6-4.8,44.4c-1.6,15.4-3.2,30.9-4.9,46.4c-1.7,16.1-3.5,32.2-5.2,48.2
							c-0.1,0.7-0.1,1.4-0.2,2.1c-0.1,0.3-0.1,0.5,0.3,0.7c2.7,0.7,5.5,0.7,8.2,1c9.7,1,19.4,1.9,29,3.1c18.1,2.2,36.2,3.9,54.2,5.8
							c0.6,0.1,1.3,0,1.8,0.5c-0.5,1.3-1.6,0.6-2.4,0.5c-15.2-1.6-30.4-3.2-45.5-4.9c-14.1-1.5-28.2-3-42.3-4.6
							c-0.9-0.1-1.8-0.4-2.7-0.4c-2-0.2-2-1.2-1.6-2.7c0.2-0.7,0.3-1.5,0.4-2.3c4.9-45.2,9.7-90.4,14.6-135.6c0-0.1,0-0.3,0-0.4
							c0-0.9,0.5-2.2-0.8-2.2c-0.7,0-2,0.8-1.1,2.2c0.2,0.3,0,0.7-0.5,0.8c-0.6,0.1-1.1-0.1-1-0.8c0.3-2.6-1.2-2.6-3.1-2.6
							c-3.2,0-6.3-1.1-9.4-1.4c-1.1-0.1-2.2-0.6-3.3,0.1c-0.6,0.4-0.8-0.2-1.2-0.5c-0.7-0.6-0.2-1,0.3-1.3c0.2,0.1,0.4,0.2,0.5,0.2
							c2.3,0.3,4.6,0.6,6.9,0.9c4.3,0.9,8.7,1.2,13.1,1.4c0.2,0.1,0.3,0.1,0.5,0.2c0,0.1,0,0.2,0,0.3
							C327.7,375.8,327.6,376.1,327.8,376.4z"/>
						<path fill="#E9E4B6" d="M220.7,502.7c0.6,0.1,1.3,0.1,1.9,0.2c1.2,0.4,2.4,0.6,3.6,0.4c2.2,0.3,4.4,0.5,6.7,0.8
							c-2.4,0.9-4.8,1.7-7.3,0c-0.5-0.3-1.2-0.3-1.8-0.4c-7.6-0.8-15.1-1.9-22.7-2.3c-3-0.1-6-0.5-9-0.1c-1.6,0.2-2-0.9-2.8-1.1
							c-2.5-0.6-1.2-2.1-1.1-3.2c2.9-27.3,5.8-54.7,8.8-82c0.2-1.7,0.4-3.5,0.6-5.2c0.1-0.7,0-1.5,1-1.5c1.9,0.6,1.8-1,2.3-2.3
							c0,3.6,2.7,2.8,4.6,3c15.2,1.7,30.3,3.3,45.5,4.9c4.9,0.5,9.8,1.1,14.7,1.6c7.7,0.8,15.3,1.6,23,2.5c0.5,0.1,1.2,0,1.5,0.6
							c-7.4-0.7-14.8-1.4-22.2-2.2c-21.5-2.5-43-4.7-64.6-6.9c-4.5-0.5-4.5-0.5-5,4.2c-1.4,13.6-2.7,27.1-4.3,40.7
							c-1.7,14.2-3.1,28.5-4.6,42.8c-0.1,0.9-0.6,2.1,1.1,2c0.3,0.1,0.5,0.2,0.8,0.3c0.3,0.3,0.6,0.3,1,0.2c2.2,0.2,4.4,0.5,6.7,0.7
							c1.4,0.5,2.9,0.6,4.4,0.5c0.7,0.1,1.3,0.1,2,0.2c0.2,0,0.4,0,0.6,0.1c2.2,0.2,4.4,0.4,6.6,0.7
							C215.1,502.5,217.9,502.7,220.7,502.7z"/>
						<path fill="#E9E4B6" d="M364.8,565.1c-0.9,1.6-2.4,0.6-3.6,0.8c-1.6,0.3-3.5,0.4-4.8-1.1c0.5-0.8,1.3-0.4,1.9-0.3
							c1.5,0.1,2.9,0.2,4.4,0.3c0.6,0,0.9-0.3,0.9-0.9c0-0.6-0.2-0.9-0.9-0.9c-3,0.3-5.8-0.4-8.7-0.7c-2.1-0.2-4.4-0.1-6.4-1.1
							c1-1.3,0.8-3.1,1.8-4.3c0.1,0.1,0.2,0.2,0.3,0.3c3.4,1,7.1,0.6,10.6,1.4c0.4,0.1,0.9-0.1,0.5-0.7c-0.3-0.4,0.3-1.4-0.8-1.3
							c-2.3,0.3-4.5-0.9-6.8-0.6c2-1.6,4.4-1.2,6.6-0.9c1.8,0.2,3.7,0.7,5.4,1.2c1.1,0.3,3.1,0.2,4.6,0.2c3.3,0,6.8-0.4,9.8,1.7
							c-0.2,0.2-0.3,0.4-0.5,0.5c-5.3-0.5-10.6-0.9-15.9-1.8c-0.1,1,0.2,1.6,1.1,1.7c2.4,0.4,4.7,0.4,7.1,0.6c2.5,0.2,5,0.6,7.5,1
							c0.4,0.5,1.1,0.3,1.6,0.5c-0.2,1.1,0.3,2.1,1.2,2.7c0.7,0.5,0.6,1,0.5,1.6c-1,0.2-1.9,0-2.9-0.1c-0.5,0-0.9,0-0.9,0.6
							c0,0.5,0.2,0.9,0.7,0.8c1.5-0.1,3,0.8,4.5,0.6c-0.1,1.2-0.8,1.3-1.8,1.1c-1.4-0.3-2.7-1-4.4,0c-0.9,0.6-2.1-0.2-1.8-1.7
							c0.6-0.1,1.1-0.5,1-1.1c-0.1-0.8-0.8-0.6-1.3-0.7c-1.3-0.1-2.5-0.3-3.8-0.4c-1.5-1.2-3.2,0.2-4.8-0.5
							C365.5,563.1,364.8,563.8,364.8,565.1z M369.7,562.4c0.8,0.1,1.7,0.2,2.5,0.3c1.4-0.2,2.7,0.1,4,0.6c0.5,0.2,0.7-0.3,0.8-0.7
							c0.1-0.6,0.1-1.2-0.7-1.3c-1.3,0-2.6-0.7-3.9,0c-0.7-0.1-1.4-0.1-2.1-0.2c-0.4-0.6-1-0.9-1.5-0.3c-0.3,0.3-0.8,0.9-0.5,1.5
							C368.6,562.8,369.2,562.7,369.7,562.4z M356.3,561.2c0.9,0.1,1.3,0.2,1.7,0.2c0.6-0.1,1.4,0.3,1.6-0.8
							c0.2-1.4-0.8-0.8-1.3-0.8c-2.1-0.2-4.2-0.4-6.3-0.7c-0.6-0.1-1.3-0.6-1.4,0.4c-0.1,0.8,0.3,1.3,1.3,1.3
							C353.5,560.7,355.2,560.9,356.3,561.2z"/>
						<path fill="#B7AF8B" d="M282.4,246.7c-0.7,6.8-1.5,13.5-2.2,20.3c-0.3,2.8-0.4,3-3.3,2.6c-7.3-1.1-14.6-1.9-21.9-2.7
							c-12.9-1.4-25.8-2.8-38.7-4.1c-1.8-0.2-2.4-0.7-2.1-2.6c0.8-6.5,1.4-13.1,2.2-19.7c0.3-2.8,0.5-2.9,3.3-2.6
							c14.5,1.6,29.1,3.1,43.6,4.6c1.6,0.2,3.1,0.3,4.7,0.5c0.6,0.1,1.1,0.1,1.7,0.2c1.5,0.2,3.1,0.4,4.6,0.5c0.3,0,0.6,0.1,0.8,0.1
							c2,0.3,4,0.6,6,0.9C282.4,245,282.6,245.6,282.4,246.7z M232.9,240.5c-4.3-0.5-8.7-0.7-12.9-1.5c-2.2-0.4-2.5,0.3-2.7,2.1
							c-0.6,6.1-1.2,12.2-2,18.2c-0.2,1.9,0.1,2.6,2.2,2.8c19.7,2,39.4,4.2,59.1,6.4c1.9,0.2,2.6-0.2,2.8-2.2c0.5-6.5,1.3-13,2-19.4
							c0.1-0.8-0.2-1.1-0.9-1.1c-4.9-0.2-9.6-1.8-14.5-1.8c-0.6-0.1-1.1-0.3-1.7-0.3C253.9,242.4,243.4,241.2,232.9,240.5z"/>
						<path fill="#F5F0BC" d="M408.5,410.4c-0.2,6.2-1.3,12.2-1.9,18.4c-0.6,5.6-1.1,11.3-1.8,16.9c-0.3,2.1,0.4,2.8,2.4,2.4
							c0.2,0,0.4,0,0.7,0c3.5-0.9,6.9-0.5,10.2,0.9c0.9,0.4,2.3,0.8,3.1,0.4c1.6-0.7,3.1,0.2,4.7-0.2c1.4-0.3,3.2,1.7,3,3.3
							c-0.2,2.5-0.5,5.1-0.8,7.6c-0.2,1.4-1,2-2.4,1.8c-2.7-0.4-5.5-0.6-8.2-1.1c-2.4-0.4-4.9-0.3-7.4-0.5c-0.6,0-1,0.2-1,0.8
							c0,0.8,0.7,0.8,1.2,0.9c3.5,0.4,6.9,0.7,10.4,1.1c0.7,0.1,1.7-0.1,1.7,1.1c-3-0.1-5.9-1.1-8.9-0.6c-0.1,0-0.3,0.1-0.4,0.1
							c-0.3-0.1-0.5-0.2-0.8-0.3c-1.1-0.6-2.2-0.8-3.4-0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.6-0.1-1.1-0.2-1.7-0.4
							c0-0.8-0.1-1.6-0.7-2.2c-0.1-0.6-0.1-1.2-0.2-1.8c-0.8-1-1.7-1.6-2.5,0c-0.2,1.9-0.4,3.9-0.7,5.8c-0.3,0-0.6,0-0.8,0
							c0-0.6,0-1.1,0.1-1.7c0.6-2.2-0.2-3.1-2.4-3c-1.2,0-2-0.5-2.2-1.7c1.5-0.5,3.2,0.9,4.6-0.5c0.3-0.1,0.6-0.3,0.8-0.4
							c0.1-0.1,0.2-0.2,0.3-0.3c0.9-0.7,1.7-1.4,2.6-2.1c0.6-0.5,0.9-0.9,0.3-1.7c-2-2.6-3.9-5.4-7.9-4.8c0.4-2.6,2.3-1.2,3.3-0.9
							c2.3,0.7,2.2,0.9,2.5-1.6c1.2-11.1,2.4-22.3,3.6-33.4C407.4,411.7,406.9,410.4,408.5,410.4z M408.2,449.1
							c-0.3,2.5-0.5,5.1-0.9,7.6c-0.3,1.5,0.4,1.9,1.7,2c1.9,0.2,3.8,0.5,5.7,0.7c4,0.4,7.9,1.2,12,1c1.1-2.7,1.2-5.6,1-8.5
							c-0.5-1.6-2-0.8-3-1.2c-0.2-0.4-0.5-0.4-0.8-0.1c-4.4-0.3-8.8-0.9-13.1-1.6c-0.1-0.2-0.3-0.2-0.4-0.1c-0.3,0-0.6-0.1-0.9-0.1
							C408.9,448.5,408.5,448.3,408.2,449.1z"/>
						<path fill="#F5F0BC" d="M478.9,273.9c1.3,0.6,0.6,1.6,0.5,2.4c-3.1,28.7-6.2,57.5-9.3,86.2c-0.4,3.7-0.8,7.3-1.2,11
							c-0.1,0.7,0.3,2-1,1.9c-1.3-0.1-0.7-1.3-0.6-2.1c1.5-13.9,3-27.8,4.5-41.7c1.5-13.6,2.9-27.3,4.4-40.9c1-0.4,1-1.3,1.1-2.1
							C477.8,283.7,478.4,278.8,478.9,273.9z"/>
						<path fill="#B7AF8B" d="M478.9,273.9c-0.6,4.9-1.1,9.9-1.7,14.8c-0.1,0.9-0.1,1.7-1.1,2.1c-21-2.3-42.1-4.5-63.1-6.8
							c-3-0.3-3-0.3-2.7-3.3c0.6-6.4,1.6-12.7,1.9-19.1c0.1-2.6,0.7-3,3.3-2.7c20.6,2.2,41.2,4.5,61.9,6.6c1.8,0.2,2.5,0.7,2.1,2.6
							C479.1,270,479.1,272,478.9,273.9z M477.5,278c0.3-3.2,0.7-6.3,1-9.5c0.1-1.1,0.1-1.9-1.4-2.1c-5.7-0.5-11.4-1.1-17.1-1.7
							c-5.1-0.6-10.2-1.4-15.3-1.7c-2.8-0.1-5.6-0.9-8.4-0.3c-0.6-0.8-1.6-0.9-2.5-1c-1.7-0.2-3.4-0.4-5.1-0.5
							c-4.2-0.4-8.3-1-12.5-1.3c-1.3-0.1-2.9-0.5-3.1,1.8c-0.6,6.4-1.3,12.9-2,19.3c-0.2,1.5,0.3,2,1.8,2.1
							c6.7,0.7,13.4,1.4,20.1,2.1c13.6,1.5,27.3,2.9,40.9,4.4c1.8,0.2,2.4-0.3,2.5-2.1c0.2-2.8,0.6-5.5,0.9-8.3
							C477.4,278.9,477.5,278.4,477.5,278z"/>
						<path fill="#32613E" d="M439.2,351.9c0,0.3-0.1,0.6-0.1,0.8c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.6-0.1,1.1-0.2,1.7
							c-0.1,1.8-1.1,2-2.7,1.9c-5.6-0.5-11.1-1.6-16.7-1.8c-0.2-0.2-0.3-0.4-0.5-0.5c0.4-3.1,0.8-6.2,1.2-9.2
							c6.4,0.7,12.7,1.4,19.1,2.1C439.2,349.7,439.2,350.8,439.2,351.9z M426.8,350.2c-1.1-1.2-2.7,0-3.8-0.7
							c-0.7-0.4-1-0.4-1.2,0.4c-0.2,0.6-0.2,1.5,0.7,1.4c1.8-0.2,3.4,0.8,5.2,0.5c0.4,0,0.7,0,1.1,0c1.1,0.9,2.5-0.2,3.7,0.5
							c1.4,0.7,2.9,0.1,4.3-0.2c0.5-0.1,0.8-0.3,0.7-0.8c-0.1-0.5-0.6-0.6-1-0.4c-1.9,0.7-3.7-0.1-5.5-0.6c-0.8-0.2-1.4-0.3-1.6,0.7
							C428.7,350.2,427.6,350.8,426.8,350.2z M428.1,355c1.7,0.3,3.4,0.7,5.2,0.9c0.7,0.1,1.2-0.1,1.2-1c0-0.6,0-1.5-0.9-1.4
							c-1.9,0.2-3.9-0.5-5.8,0.1c-0.7-0.3-1.4-0.5-2.1-0.9c-0.7-0.3-1.3-0.2-1.5,0.5c-0.3,0.9,0.2,1.3,1,1.4
							C426.1,354.8,427.1,354.9,428.1,355z"/>
						<path fill="#E9E4B6" d="M217.4,584c-1.3,0-2.7,0.5-3.9-0.4c-0.3-2.4-2.3-1.7-3.7-2c-0.3-0.3-0.7-0.3-1.2-0.2
							c-0.5,0-0.9-0.1-1.4-0.1c-0.5-0.6-1.1-0.6-1.6,0c-0.3,0.4-0.5,0.7-1,0.2c-0.9-1.1-2.2-0.6-3.3-0.9c-0.4-0.1-0.9-0.1-0.9,0.5
							c-0.1,0.6,0.3,1.1,0.8,1c2.1-0.4,3.9,1,5.9,0.8c0.5,0,0.9,0.1,1.3-0.3c0.4,0,0.8,0,1.2-0.1c0.7,0.7,1.6,1.1,2.5,1.2
							c0,0.2,0,0.5,0.1,0.6c1.2,0.5,1.8,1.2,1,2.6c-0.8,1.4-1.7,0-2.6,0.1c-0.1-1-0.6-1.7-1.6-1.7c-0.9,0-1.9,0.2-2.6-0.6
							c-0.7,0.8-2.3-0.9-2.7,0.9l0,0c-0.9-1.1-1.9-1.5-3.3-1.1c-0.8-0.3-1.6-0.9-2.5-0.7c0-0.8-0.1-1.1-1.2-1
							c-2.1,0.3-4.4,0.4-6.3-0.9c0.7-0.6,1.9-0.9,1.6-2.3c0.3,0,0.6,0.1,0.9,0.1c0,0.7-0.5,1.9,0.7,1.7c1-0.2,1.9,0.4,2.8,0.2
							c2.8,0.6,3.1,0.3,2.5-2.1c0.3,0,0.7,0,0.7-0.5c0,0-0.1-0.1-0.1-0.1c-0.5-0.1-0.5,0.3-0.6,0.6c-0.3,0-0.5,0-0.8-0.1
							c0.2-0.5,0.4-1.1,0.6-1.6c0.6,0.1,1.3,0.2,2.2,0.4c-0.5-1-0.2-2-1.1-2.6c0.9-1.3,1.9-0.8,2.7,0.1c0.5,0.5,0.8,0.9,1.5,0.9
							c0.1,0.6,0.1,1.2,0.2,1.8c1.1-0.9,1.8,1.1,2.5,0.5c1.2-1,1.7,0.3,2.6,0.3c0.1,0.4-0.3,1.2,0.4,1.1c0.5-0.1,0-0.7,0.1-1.1
							c0.7,0.1,1.6,0.6,1.7-0.7c0.1-0.6-0.2-1.1-1-1c-0.3,0.1-0.7,0-1,0c1.6-1.8,4-1.7,5.1,0.2c0.6,1.1,0.8,2.2-1,2.5
							c-0.5,0.1-1.1,0.2-1.6,0.5c1.1,0.1,1.9,1,3.2,0.4c1-0.6,1.7,0.4,2.5,0.7c-0.3,0.7-1,0.6-1.5,0.9
							C216.6,583.2,217.2,583.3,217.4,584z"/>
						<path fill="#1D190E" d="M205.7,360.5c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-1.2,0.3-2.4,0.3-3.6c0.1-2.4,0.9-4.7,0.4-7.1
							c0-0.4,0.1-0.8,0.1-1.3c0.1-0.2,0.2-0.5,0.4-0.7c0-0.2,0.1-0.4,0.1-0.6c0.2-2.2,0.5-4.4,0.7-6.6c0-0.2,0-0.4,0.1-0.6
							c0-0.4,0.1-0.7,0.1-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-1.4,0.3-2.7,0.4-4.1c0.4-0.9,0.5-1.8,0.3-2.7c0.1-0.5,0.1-1,0.2-1.6
							c0.3-0.8,0.6-1.5,0-2.3c0-0.1,0-0.3,0-0.4c0.8-2,0.5-4.2,0.9-6.2c0-0.2,0-0.4,0.1-0.6c0-2.1,0.1-4.2,0.5-6.3
							c0.3-0.1,0.4-0.3,0.2-0.5c0.5-4.5,0.9-8.9,1.4-13.4c0.2-0.2,0.2-0.4,0.1-0.6c0.2-1.6,0.3-3.3,0.5-4.9c0.2-0.2,0.2-0.3,0.1-0.6
							l0.1,0c0.7,1.1,0.6,2.3,0.5,3.5c-0.5,4.7-1,9.4-1.5,14.1c-0.1,0.7-0.4,1.5,0.2,2.2c0.1,0.4-0.5,0.8,0,1.3
							c-0.1,0.1-0.1,0.2-0.2,0.3c-0.8,1.5-0.8,3.1-0.6,4.8c0,0.1,0,0.3,0.1,0.4c-0.5,1.9-0.7,3.9-0.6,5.9c0,0.4-0.1,0.9-0.1,1.3
							c-0.2,0.4-0.3,0.8-0.1,1.2c0,0.4-0.1,0.9-0.1,1.3c-0.4,1.2-0.5,2.5-0.4,3.8l-0.1,0c-0.4,0.9-0.7,1.7-0.2,2.6
							c0.1,0.1,0.1,0.2,0.1,0.3c0,0.4-0.1,0.7-0.1,1.1c-0.1,0.4-0.3,0.8-0.4,1.1c-0.8,2.9-0.8,6-1.1,8.9c-0.3,2.1-0.5,4.2-0.8,6.2
							c-0.3,0.1-0.3,0.3,0,0.4c0.3,1.6-0.8,3.1,0,4.7c0.1,0.3,0.2,0.3,0.4,0.1c11.3,1.2,22.6,2.3,33.9,3.5c0.1,0,0.3,0,0.4,0
							c0.6,0.3,1.2,0.5,1.6,1.1c-2.8,0.3-5.6-0.4-8.4-0.6c-0.2-0.3-0.4-0.3-0.6-0.1c-1.2-0.1-2.4-0.2-3.7-0.4
							c-1.1-0.4-2.3-0.6-3.5-0.4c-0.7-0.1-1.3-0.1-2-0.2c-1.4-0.5-2.9-0.6-4.4-0.5c-0.4,0-0.8-0.1-1.1-0.1c-0.2-0.3-0.3-0.3-0.6-0.1
							c-0.2,0-0.5,0-0.7-0.1c-0.2-0.3-0.4-0.3-0.6,0c-1.2-0.1-2.5-0.3-3.7-0.4c-2.3-0.6-4.6-0.9-6.9-0.7
							C206.6,360.7,206.1,360.6,205.7,360.5z"/>
						<path fill="#F5F0BC" d="M312.1,282.8c0.5,0.7,0.2,1.4,0.1,2c-0.6,5.7-0.9,11.5-1.9,17.1c-2,10.8-3.1,21.7-4.3,32.5
							c-0.1,1.2-0.2,2.3,0.4,3.6c0.5,1.2,0.1,3.2-0.9,4.5c-0.4,0.5-0.4,0.9-0.3,1.5c0.5,5.4-0.4,10.7-1.6,16.6
							c-0.3-2.5-0.9-4.3-0.6-6.4c2.6-22.9,5-45.9,7.5-68.8C310.5,284.4,310.4,283.1,312.1,282.8z"/>
						<path fill="#1D190E" d="M405.3,238.1c0-0.3,0.1-0.6,0.1-0.9c0.3-1.1,0.5-2.2,0.4-3.4c0.1-1,0.2-2,0.3-3c0-0.1,0-0.3,0-0.4
							c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.3,0-0.4c0.1-0.3,0.2-0.6,0.2-0.8c1.2-0.1,2.3,0,3.5,0.3l0,0c-0.2,2-0.3,4-0.7,5.9
							c-0.3,1.5,0.1,2.1,1.7,2.3c1.8,0.2,2-0.7,2.1-2c0-1.1,0.2-2.1,0.3-3.2c0.3-2.9,0.3-2.9,3.1-2.5c0.2,0,0.4,0.1,0.6,0.1l0,0.1
							c0,0.2,0.1,0.3,0.1,0.5c0,0.3,0,0.6-0.1,0.9c-0.3,0.7-0.4,1.4-0.2,2.1c0,0.4,0,0.8-0.1,1.2c0,1.1-1.2,2.3,0.4,3.2
							c0.6,0.4,1.3,0.2,2,0.4c0.1,0.2,0.1,0.4,0.1,0.5c-0.1,1.1-0.2,2.3-0.3,3.4c-1.1,2-2.4,0.9-3.6,0.3c0-0.2,0-0.4,0-0.6l0,0
							c0.3-0.2,0.3-0.4,0-0.6l0,0c-0.2-1-1.1-0.7-1.7-0.7c-2.8-0.4-5.7-0.7-8.5-1.1c0-0.2-0.1-0.3-0.1-0.5
							C405.3,238.4,405.3,238.2,405.3,238.1z"/>
						<path fill="#1D190E" d="M279.5,214.3c2.8,0.6,5.6,0.8,8.4,0.9c0.6,0.7,0.3,1.5,0.2,2.2c-0.2,2.8-0.5,5.6-0.8,8.3
							c-1.3,1.2-2.6,0.6-4,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-1.3,0.2-2.6,0.2-3.8c0.1-0.3,0.1-0.5,0.2-0.8c1.1-2.4,0.9-3.2-0.9-3.3
							c-1-0.1-1.5,0.2-1.5,1.2c-0.1,1.6-0.6,3-0.9,4.5c-0.3,1.3-1.1,1.7-2.1,1.9c-0.9,0.2-2,0.5-2.5-0.5c-0.4-0.9,0-1.7,0.9-2.3
							c0.7-0.4,0.8-1.3,0.9-2.1c0.3-2,0.6-4.1,0.8-6.1C278.7,214.5,279.1,214.4,279.5,214.3z"/>
						<path fill="#F5F0BC" d="M317.2,277.9c-1.6,15.1-3.3,30.2-4.9,45.3c-1.2,11-2.3,22-3.5,33c-0.6,5.3-1.3,10.5-1.9,15.8
							c-0.6-1.4-2.1-2.8-0.3-4.4c0.1-0.1,0.2-0.4,0.1-0.6c-1.5-5.2,1.9-9.9,1.7-15c-0.1-3.8,0.7-7.7,1.1-11.5
							c0.1-1.3,0.6-2.6-0.3-3.9c-0.2-0.3-0.1-0.9,0-1.2c1.9-3.6,1-7.7,2.1-11.5c0.8-3-0.7-6.1,0.2-9c2.8-10,2.5-20.4,4.1-30.6
							c0.2-1.3,0.4-2.7,0-4c-0.4-1.4,0.2-2.5,1.2-3.3c0,0.1,0.1,0.1,0.1,0.2C316.9,277.5,316.9,277.7,317.2,277.9z"/>
						<path fill="#E9E4B6" d="M388.9,247.3c1.6,0.2,2.7-1.3,4.7-0.7c3.5,1,7.4,0.3,10.9,1.7c0.1,0.1,0.1,0.2,0.2,0.3
							c-1.1,0.8-1.8,1.9-1.2,3.2c0.7,1.7,2.3,1.6,3.7,1.4c0.2,0.2,0.4,0.4,0.5,0.6c-3.5,1-6.9-0.6-10.3-0.9c-0.1,0-0.2-0.4-0.3-0.5
							c0.7,0,1.3,0.1,1.6-1c0.2-1,0.9-0.9,1.3-0.1c0.2,0.5,0.1,1.4,1,1.3c1-0.2,0.6-1.1,0.8-1.8c0.1-0.4,0.1-0.8,0.1-1.3
							c0-0.5,0.3-1-0.4-1.3c-0.6-0.2-0.9,0.1-1.1,0.6c-0.6,1.1-1.2,0.9-1.4-0.2c-0.1-0.6-0.5-0.9-1-0.8c-0.6,0.1-0.7,0.7-0.7,1.1
							c-0.1,1.1-0.2,2.1-0.3,3.2c-0.5-0.1-1.1-0.2-1.6-0.3c0.1-0.8,0.2-1.7,0.2-2.5c0-0.6,0.5-1.5-0.5-1.8c-0.7-0.3-1.2,0.2-1.6,0.7
							c-0.2,0.3-0.4,0.6-0.9,0.4c-0.4-0.4,0.2-1.6-1-1.4c-0.9,0.1-0.6,1-0.7,1.5c-0.1,0.6-0.2,1.3-0.2,1.9c-0.1,0.6,0.3,1.2,0.8,0.9
							c1.3-0.8,2.4-0.8,3.5,0.3c0.1,0.1,0.3,0,0.4-0.1c-0.5,2.2-2.3,1.7-3.4,1.2c-3.3-1.7-7-0.7-10.4-1.8c-1-0.3-2.7,0.7-3-1.3
							c1.4,1.3,2.8,0.6,3.9-0.3c1-0.8,0.9-2.1,0.1-3.1c-0.9-1.1-2.4-1.4-3.4-0.5c-1.6,1.6-1.8,0.4-2.2-0.8c1.5-0.5,3.1-0.2,4.6-0.9
							c0.7,1.7,2.3,0.9,3.5,1.3c0.7,0.2,1.4,0,2.1,0C388,245.7,389.2,245.7,388.9,247.3c-0.5-0.2-1-0.3-1.5-0.4
							c-1.2-0.2-2.3-0.2-2.9,1.1c-0.4,0.9-0.4,1.8,0.3,2.6c1,1,2.1,1,3.3,0.6c0.4-0.2,1-0.4,0.9-1c-0.2-0.6-0.8-0.5-1.3-0.4
							c-0.6,0.2-1.2,0.6-1.6-0.3c-0.2-0.4-0.2-0.8,0.2-1.2c0.5-0.6,1-0.1,1.5,0.1C389,248.8,389.5,248.6,388.9,247.3z"/>
						<path fill="#14653A" d="M325.2,576.7c0,0.4-0.1,0.9-0.1,1.3c-0.3,0.8-0.8,1.6-0.8,2.4c0,2-0.9,2.2-2.7,2
							c-3.2-0.4-6.4-1-9.7-0.8c-0.3,0-0.7-0.1-1-0.1c0.1-0.3,0.3-0.7,0.4-1l0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.5l0.1,0.1
							c0.8-0.7,0.7-1.6,0.5-2.5c0-0.3,0-0.7,0-1c0-0.4,0-0.8,0.1-1.1c0.1-0.1,0.1-0.3,0.2-0.4c0.5-0.5,0.5-1,0.2-1.6
							c0-0.3-0.1-0.6-0.1-0.9c0.7-0.6,1.5-0.3,2.2-0.2c2.3,0.2,4.6,0.4,6.9,0.7c0.2,0,0.5,0.1,0.7,0.1c0.9,0.1,1.8,0.2,2.7,0.3
							C325.6,574.3,324.6,575.6,325.2,576.7z M319.5,578.3c0.2,1.1-1.7,1.3-0.4,2.1c1,0.6,1.8-0.4,2.6-1c0.6-0.5,1.9-0.9,1.6-1.7
							c-0.6-1.3-1.4-2.6-2.9-3.2c-0.6-0.2-1.4,0-0.9,0.8c1.3,2-0.2,1.4-1.1,1.3c-0.8,0-1.5-0.2-2.3-0.2c-0.5,0-1,0.1-1.1,0.7
							c-0.1,0.7,0.4,0.8,0.9,0.9C317,578,318.3,578.1,319.5,578.3z"/>
						<path fill="#1D190E" d="M337.7,232c0.3-3.3,0.6-6.6,0.9-10c0.1-0.7,0.3-1.2,1.2-1.1c2,0.3,4.1,0.4,6.1,0.8
							c2.5,0.6,3,2.3,1.6,4.4c-0.2,0.4-0.5,0.6-0.3,1.1c1.5,3.8,1.1,4.5-2.8,5.5c-2.1-0.2-4.2-0.4-6.4-0.6l-0.1-0.1L337.7,232z
							 M343,230c0.5-0.2,1.6,0.3,1.7-0.8c0.1-1.1-1-1.2-1.8-1.4c-0.8-0.2-1.4,0.1-1.5,1C341.4,229.9,342.1,229.9,343,230z
							 M343.2,225.9c0.7,0,1.5,0,1.5-1c0-1-0.9-1-1.5-1.1c-0.7-0.1-1.2,0.1-1.3,1C341.9,225.8,342.6,225.8,343.2,225.9z"/>
						<path fill="#D02826" d="M310.8,539.8c0.5-5,0.5-5,5.5-4.4c4.7,0.5,4.7,0.5,4.2,5.1c-0.5,5-0.5,5-5.7,4.5
							C310.3,544.5,310.3,544.5,310.8,539.8z M314.6,536.7c-0.6,0-1.2,0.2-1.3,1c-0.3,1.2,1.5,5.4,2.6,6.1c0.5,0.4,1,0.4,1.5-0.2
							c0.5-0.6,0.2-0.9-0.4-1.2c-1.9-1-2.9-2.4-1.7-4.6C315.4,537.2,315.4,536.8,314.6,536.7z"/>
						<path fill="#F5F0BC" d="M408.5,528.8c4.3,0.4,8.6,0.8,12.9,1.2c9.2,0.9,18.3,1.8,27.5,2.8c0.2,0.2,0.3,0.2,0.5,0h0
							c1-0.3,0.6-1.1,0.7-1.8c0.3-2.1,0.5-4.2,0.8-6.3c0.8-0.3,0.6-1,0.7-1.6c1.3-11.8,2.6-23.6,3.5-35.5c0-0.3-0.1-0.7-0.1-1
							c0-0.3,0.1-0.6,0.1-0.9c0.4-0.5,0.4-1.1,0.4-1.8c0.4-4.5,0.9-9,1.3-13.5c0.7-0.2,0.7-0.5,0.1-0.9c0.1-0.3,0.1-0.7,0.2-1
							c0.2-0.4,0-1,0.8-1.2c0.5,1.2,1.6,2.3,0.1,3.7c-0.4,0.4-0.3,1.3-0.4,2c-2.1,19.5-4.2,39-6.3,58.5c-0.2,2-0.8,2.6-2.8,2.3
							c-12.6-1.4-25.1-2.7-37.7-4.1C409.9,529.7,408.8,530,408.5,528.8z"/>
						<path fill="#D02826" d="M213.6,525.3c4.7,0.5,4.7,0.5,4.2,5.1c-0.5,4.8-0.5,4.8-5.4,4.4c-4.8-0.5-4.8-0.5-4.3-5.2
							C208.6,524.8,208.6,524.8,213.6,525.3z M213.9,530.7c0.1-1,0.4-2-0.1-2.9c-0.2-0.4-0.2-0.9-0.8-0.9c-0.5,0-0.6,0.5-0.8,0.9
							c-0.6,1.4-0.5,2.9-0.7,4.4c-0.1,0.7,0.3,1,1,1.1c0.7,0.1,1.1-0.1,1.2-0.8C213.8,531.9,213.9,531.3,213.9,530.7z"/>
						<path fill="#D02826" d="M256.2,534.1c0.5-4.9,0.5-4.9,5.5-4.3c4.6,0.5,4.6,0.5,4.1,5.1c-0.5,4.8-0.5,4.8-5.2,4.5
							C255.7,539,255.7,539,256.2,534.1z M259.4,533.5c0.3,0.5-1,2,0.7,2c1.2,0.1,3.2,0.9,3.1-1.8c0-1.4,0.1-2.4-1.8-2.6
							C259.6,531,259.3,531.8,259.4,533.5z"/>
						<path fill="#1D190E" d="M327.1,219.5c0.1,0,0.3,0,0.4,0c6.3-0.8,9.7,4.5,6.4,10c-2.5,2.5-5.3,1.9-8.2,0.9
							c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.3-0.5-0.5c-0.4-0.8-1-1.5-0.7-2.5c0.4,0,0.9,0,1.3,0
							c1.4-0.1,1.9,1.6,3.2,1.7c1.2,0.5,2.8,0.5,3-0.8c0.2-1.7-1.6-0.8-2.6-1.2c-0.1,0-0.1,0-0.2,0c-0.5-1.1-0.6-2.1,1.1-2.1
							c0.8,0.1,1.7,0.9,2.2,0.1c0.5-0.8-0.4-1.3-1-1.7l-0.1,0c-0.8-0.7-1.6-0.7-2.4-0.2c-0.1,0-0.2,0-0.3,0.1
							c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8-0.1-1.2-0.1c-0.2,0-0.4-0.1-0.6-0.1
							C323.8,220.6,325.9,220.5,327.1,219.5L327.1,219.5z"/>
						<path fill="#F5F0BC" d="M240.4,593.8c7.1,0.8,14.1,1.5,21.2,2.3c0.6,0.1,1.1,0.1,1.7,0.2c0.5,0.1,0.7,0.4,0.7,0.9
							c-0.1,0.5-0.4,0.7-0.9,0.7c-0.5,0-1-0.1-1.5-0.1c-14.2-1.5-28.4-3.1-42.5-4.6c-0.3,0-0.6-0.1-0.8-0.1
							c-0.7-0.1-1.6-0.2-1.5-1.1c0.1-1.1,1.1-0.6,1.7-0.5C225.7,592.2,233.1,593,240.4,593.8z"/>
						<path fill="#14653A" d="M416.5,585.5c2.6,0.3,5.2,0.6,7.8,0.8c1.5,0.1,1.4,1,1.3,2.1c-0.1,1-0.1,2-1.7,1.8
							c-5.2-0.6-10.4-1.2-15.7-1.6c-2-0.2-1.3-1.4-1.3-2.4c0-1.4,0.6-1.6,1.9-1.5C411.4,585,414,585.2,416.5,585.5
							C416.5,585.5,416.5,585.5,416.5,585.5z"/>
						<path fill="#CEC69D" d="M407.7,448c-0.2,0-0.4,0-0.7,0c2.2-20.9,4.4-41.9,6.8-62.8c0.6-5.1,0.8-10.2,2-15.2
							c0.9,0.8,0.5,1.7,0.4,2.6c-0.6,5.8-1.1,11.6-2.3,17.3c-0.2,1-0.1,1.8,1,2.3c0.5,0.2,1.2,0.1,1.2,1c0,0.2-0.2,0.6-0.4,0.7
							c-0.8,0.3-1.1-0.3-1.5-0.8c-0.1-0.1-0.2-0.1-0.4-0.1c-1.2,2.1-0.1,3.2,2.4,4c-4.1,0.7-3.5,3.8-3.5,5.7
							c-0.2,5.8-1.1,11.6-1.6,17.4C410.3,429.3,408.5,438.6,407.7,448z"/>
						<path fill="#32613E" d="M212,561.2c0.2,0.2,0.5,0.4,0.7,0.6c-0.4,2.7-0.8,5.3-1.1,8c-0.1,1.3-0.6,1.7-1.9,1.5
							c-2.3-0.3-4.6-0.6-7-0.7c-1.3-0.1-1.5-0.7-1.4-1.8c0.3-1.7,0.5-3.4,0.7-5c0.4-3.2,0.4-3.2,3.3-3.3c0.3,0,0.6,0.1,0.9,0.1
							C208.1,560.9,210.1,561.2,212,561.2z M210.3,566.1c-0.1-0.2-0.1-0.7-0.3-0.8c-1.1-0.4-1.9-0.8-1.8-2.2c0-0.4-0.8-0.7-1-0.5
							c-1,1.1-2.2,1.8-3.5,2.6c-0.3,0.2-0.3,1.1,0.3,1.3c1,0.3,1.6,0.7,1.5,1.9c0,0.4,0.5,0.6,0.9,0.7
							C206.8,569.2,210.3,566.6,210.3,566.1z"/>
						<path fill="#F5F0BC" d="M382.5,316.7c0,0.5-0.1,1.1-0.1,1.6c-0.3,0-0.6,0-0.9,0c-0.9,0.2-2-0.9-2.7,0.6
							c-0.5-0.1-0.6-0.4-0.8-0.8c-0.9-2-2.2-3.6-3.9-4.9c-1.6-1.2-2.6,0.7-4,0.9c-0.1,0-0.1,0-0.2,0l0,0c-0.2-0.2,0.5-1.1-0.4-0.6
							c-0.8,0.4-0.9,1.5-1.4,2.2c-0.5-0.3-0.8-0.8-0.7-1.4c1.3-0.8,2.2-1.9,3-3.2c1.4,0.1,2.8,0.7,4.2,0.1c0.3,0,0.6,0,0.8,0.1
							c0.3,0.4,0.5,0.5,0.9,0.1c2.9,0.6,2.9,0.6,3.2-2.3c0.2-1.7,0.3-3.5,0.5-5.2c0.1-0.8,0.3-1.6-0.9-1.7c-0.3-0.3-0.6-0.3-0.9-0.1
							c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.1-0.3-1.7-0.4c0.2-0.2,0.3-0.5,0.5-0.7c2.2-1.3,2.2-1.2,3-3.5c0,2,1,2.7,2,3.6
							c1.1,1,1.8,2.6,1,4.4c-0.3,0.7-0.2,1.5-0.3,2.3c-0.2,2.3,0.5,3.2,2.8,2.8c1.7-0.3,2.6,0.4,3.1,1.9c-1.9-0.1-3.8-0.2-5.7-0.3
							c-0.6,0-1.3-0.1-1.5,0.7c-0.2,0.7-0.4,1.4,0.6,1.8C381.2,314.9,381.4,316.2,382.5,316.7z"/>
						<path fill="#D02826" d="M367,550.7c-4.8-0.5-4.8-0.5-4.2-5.2c0.6-4.9,0.6-5,5.6-4.4c5.1,0.6,4.7-0.7,4.1,5.2
							C372,551.2,371.9,551.2,367,550.7z M370.9,547.7c0.5-5.1,0.5-5.1-4.5-5.6c-2.1-0.2-2.5,0.4-1.7,2.5c1.2-1.3,2.3-2.7,4.3-1.4
							c1.7,1.1,2.1,2.6,1.2,4.4c-2.6,2.3-3.9,2-6-1.1c-0.9,2.2-0.7,2.6,1.5,2.8c1,0.1,2,0.1,2.9,0.3
							C370.4,550,371.2,549.5,370.9,547.7z M367.5,547c0.6,0,1.1-0.2,1.2-0.8c0.1-0.7-0.3-1.2-1-1.3c-0.6,0-1.1,0.2-1.1,0.9
							C366.5,546.5,366.8,546.9,367.5,547z"/>
						<path fill="#1D190E" d="M396.5,227c3.4,0.5,3.4,0.5,4.3,3.9c0.5,2.1,1,4.1,1.5,6.2c0.1,0.6,0.8,1.6-0.3,1.8
							c-0.9,0.2-2.1,0.4-2.9-0.6c-1.2-1.6-2.9-1.9-4.5-0.6c-1.1,0.8-2.2,0.1-3.3,0.3c-0.1-0.1-0.2-0.3-0.3-0.4
							c1.6-3.4,3.2-6.8,4.9-10.2C396.2,227.2,396.3,227.1,396.5,227z M397.7,231.4c-0.6,0.9-1.5,1.7-1.1,2.8c0.2,0.5,1,0.5,1.3,0.3
							C399,233.6,397.8,232.6,397.7,231.4z"/>
						<path fill="#E9E4B6" d="M290.8,241.1c-1.2,0-1.3-0.8-1.2-1.7c0.1-1.2,0.2-2.4,0.3-3.5c0-0.4,0-0.7,0-1.1
							c1-0.4,1.8-0.4,2.7,0.4c1.2,1.1,3,1.1,4.5,1.1c1.7,0,3.3-0.1,5,0.5c0.3,0.1,0.5,0.2,0.8,0.2c2.6-0.2,5.1,0.3,7.4,1.7
							c-0.1,1.4-0.2,2.8-0.3,4.1c-0.1,0-0.2,0-0.3,0.1c-0.3-0.1-0.4-0.9-0.6-0.4c-0.8,1.7-2.3,1.5-3.6,1.1c-2-0.7-4.1-0.4-6-0.8
							c-2.6-0.5-5.2,0.6-7.7-0.5C291.2,241.9,290.7,241.9,290.8,241.1c1.3,0.3,2-0.7,2.8-1.4c0.7,0.4,0.1,1.6,1.2,1.5
							c1-0.1,0.8-1,0.9-1.7c0.1-0.5,0.1-1,0.2-1.5c0.2-1.4-0.6-1.4-1.6-1.1c-0.7,0.3-1.2,2-1.8-0.2c-0.2-0.6-1.3-0.4-1.4,0.6
							C291,238.7,290.9,239.9,290.8,241.1z M302.1,239.6c0.9-2-0.6-2.3-2.4-2.3c-0.7,0-1.8-0.6-2.1,0.4c-0.4,1.2-0.7,2.5-0.2,3.7
							c0.2,0.6,1,0.5,1.3-0.2c0.2-0.4,0.2-1,0.2-1.4c0.1-0.5,0.1-1.1,0.7-1.1c0.8,0,0.7,0.7,0.7,1.3c0,0.4-0.1,0.7-0.1,1.1
							c0,0.6,0.2,1,0.8,1c0.5,0,0.8-0.3,0.8-0.8C302,240.8,302,240.3,302.1,239.6z M305.9,242.6c1.5,0,2.6-0.4,2.8-2
							c0.2-1.6-0.8-2.6-2.3-2.7c-1.5-0.1-2.6,0.6-2.8,2.3C303.5,241.7,304.5,242.3,305.9,242.6z"/>
						<path fill="#1D190E" d="M419.8,547.3c2.6,0.3,4.5,2.5,4.3,5.1c-0.2,2.5-2.7,4.4-5.3,4.1c-2.5-0.3-4.4-2.6-4.2-5
							C414.9,549,417.2,547.1,419.8,547.3z"/>
						<path fill="#F5F0BC" d="M267.8,243c-1.6-0.2-3.1-0.3-4.7-0.5c0.1-0.8,0.7-0.7,1.3-0.8c0.9-0.1,1.6-0.5,1.6-1.4
							c0.1-1.6,0.2-3.2,0.7-4.8c0.2-0.7-0.7-1.1-1.1-0.8c-0.5,0.5-1.9,0.4-1.5,1.8c0.2,0.5,1.4,0.8,0.6,1.5
							c-0.8,0.5-1.5,1.4-2.6,1.3c-1-0.1-1.4-1.1-2.2-1.6c-1.2-0.7-0.4-1.2-0.2-1.9c0.4-1.3,0.3-2.7,0.5-4c0.2-1.2,0.3-2.4,2.2-1.4
							c1.1,0.6,2.7,0.6,4.1,0.2c2.2-0.6,4.3,0.5,6.5,0.5c0.9,0,1.4,0.8,1.5,1.8c-1.5-0.7-3.1-0.4-4.6-0.7c-1.2-0.3-1.7,0.3-1.7,1.5
							c0,0.6-0.2,1.1-0.1,1.7c0.2,1-1.2,2.7,0.7,3c2.1,0.3,1-1.7,1.4-2.6c0.1-0.2,0.1-0.6,0.1-0.8c0.1-0.6,0.5-0.8,1.1-0.7
							c0.5,0.1,1,0.3,0.9,0.9c-0.1,0.6-0.1,1.3-0.2,1.9c-0.3,1.3,0.1,2,1.6,2c-1.5,0.6-2.7,2.6-4.5,0.6c-0.5-0.6-1-0.2-1.1,0.5
							C268,241.1,267.9,242,267.8,243z M264.4,231.6C264.4,231.6,264.4,231.6,264.4,231.6c-0.6-0.1-1.3-0.1-1.9-0.2
							c-0.8-0.1-1.3,0.4-1.1,1.1c0.4,1.2,0,2.4-0.1,3.5c0,0.7-0.2,1.5,0.8,1.6c1.2,0.2,1.2-0.7,1.3-1.5c0.1-0.6,0.1-1.1,0.1-1.7
							c0-1.1,0.5-1.5,1.6-1.3c0.6,0.1,1.6,0.5,1.6-0.6c0-1.1-1-0.8-1.6-0.9C264.9,231.7,264.7,231.7,264.4,231.6z"/>
						<path fill="#F5F0BC" d="M311.3,603.1c-6.1-0.7-12.3-1.3-18.4-2c-0.7-0.1-1.8,0.2-1.7-1c0.2-1.2,1.2-0.7,1.9-0.6
							c12.3,1.3,24.5,2.6,36.8,4c0.7,0.1,1.9-0.2,1.8,1c-0.1,1.4-1.3,0.8-2,0.7C323.5,604.4,317.4,603.8,311.3,603.1z"/>
						<path fill="#E9E4B6" d="M370.3,311c-0.8,1.3-1.6,2.4-3,3.2c-1.1-0.5-1.9,0.8-3,0.5c-0.4-1.8,0.3-2.6,2.1-2.1
							c1.1,0.3,1.5-0.1,1.6-1.2c0.2-1.3-0.6-1.4-1.4-1.4c-1.5,0-1.8-0.7-1.7-2.1c0.9-8.3,1.7-16.5,2.6-24.8c0-0.2,0.1-0.4,0.1-0.6
							c0.2,0,0.3,0,0.5,0c1.3,0.4,0.7,1.4,0.6,2.2c-0.7,7.3-1.5,14.5-2.3,21.8c-0.1,1.1-0.1,2.1,1.1,2.8c1-1.8,2.2-3.3,1.1-5.7
							c-0.5-1.3,0.1-3.1,1.9-3.8c1.6-0.7,2.5,0.2,3.4,1.4c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.3,0-0.6,0-1-0.1
							c-0.4,0.1-0.7,0.2-0.8,0.6c-0.3,2.7-0.6,5.4-0.9,8c-0.1,0.5,0.3,0.7,0.7,0.9C370.3,310.7,370.3,310.8,370.3,311z"/>
						<path fill="#E9E4B6" d="M304.7,594.3c-0.4-0.2-0.8-0.6-1.3-0.3c-2.5,1-4.3,0-6.1-1.8c-1-1,0.1-2.2-0.7-3.1
							c0.8-0.8,1.3-0.2,1.7,0.5c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.2-0.1,0.3-0.2c4.8-0.2,9.6,0.2,14.3,1c1.6,0.3,2.3,0,1.4-1.7
							c0.6,0.1,1.3,0.1,1.9,0.2c-1,1.7-0.4,2.3,1.4,2.3c4-0.1,7.9,0.4,11.9,1c-0.5,1-0.9,2.1-2,2.6c0-0.5,0.7-1.5-0.6-1.3
							c-1.7,0.2-3.1-0.9-4.8-0.8c-1.6,0.2-3.2,0-4.6-0.5c-1.4-0.5-2.6-0.2-3.9,0.3c-2.1-1.5-4.5-1.2-6.8-1.4
							c-1.5-0.1-3.1-0.3-4.6-0.4c-0.5-0.4-1.2-0.8-1.6,0c-0.5,0.8,0.2,1.3,0.9,1.5c0.2,0.1,0.4,0.2,0.5,0.3c1.4,0.3,2.8,0.3,4.2,0.1
							c-0.2,0.5-0.4,1-0.6,1.6l0,0C305.2,594.3,304.9,594.3,304.7,594.3z"/>
						<path fill="#888158" d="M358.7,601.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4,0-0.9,0-1.3,0
							c0-0.2,0-0.4-0.1-0.6c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.1,0.2-0.2,0.1-0.4c0.3,0.1,0.5,0.1,0.8,0.2c1,0.2,2,0.6,2.8,0.6
							c4.6-0.2,9,1,13.5,1.4c1.7,0.2,3.7,0.9,5.4-0.3c0,0.5-0.1,1.1,0.7,0.7c0.3,0,0.6,0,0.9,0c-0.1,0.4-0.2,0.9-0.2,1.3
							c-4.2-0.3-8.5-0.6-12.7-1c-0.6-0.1-1.2-0.2-1.4,0.4c-0.1,0.6,0.5,0.7,1,0.7c2,0.2,3.9,0.2,5.9,0.5c1.5,0.2,2.5,0.4,3.1,1.8
							c-0.3,0.3-0.7,0.9-0.9,0.8c-3.4-1.4-7.1-1.3-10.7-1.7c-0.6-0.1-0.9-0.3-1-0.9c0.3-0.4,1-0.5,1-1.2c0.3,0.1,0.7,0.2,0.9-0.2
							c0.1-0.1-0.1-0.4-0.2-0.5c-0.3-0.2-0.5,0-0.7,0.2C362.7,601,360.7,601.1,358.7,601.2z"/>
						<path fill="#1D190E" d="M381,230.2c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.3-0.3-0.6-0.4-0.9l0,0c-0.1-0.8-0.4-1.5-1-2
							c-0.2-0.5-0.4-1-0.6-1.6c1.4-0.3,2.7,0.1,4,0.3c0,0.1,0.1,0.2,0.2,0.2c0.2,1.1,0.4,2.6,1.3,2.9c1,0.4,1.2-1.2,1.8-1.9
							c1.1-1.3,2.6-0.9,3.4-0.4c1.2,0.7-0.5,1.4-0.7,2.1c-0.1,0.4-0.4,0.7-0.6,1.1c-1.4,1.9-2.9,3.8-4.2,5.8c-1.1,1.7-2.7,1.1-4,0.8
							c-1.1-0.3,0.2-0.9,0.2-1.5c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.4,0.6-0.8,1-1.2c0.7-0.6,0.3-1-0.1-1.5c0,0,0-0.1,0-0.1
							C381.6,231,381.4,230.6,381,230.2z"/>
						<path fill="#F5F0BC" d="M357.5,243.4c2.1-1.2,4.4-0.4,6.7-0.6c0.8-0.1,1.2,0.7,1.2,1.4c0,0.7-0.2,1.4-0.3,2.1
							c-0.1,0.6-1.2,1.4-0.1,1.9c0.9,0.4,1.7-0.3,2.2-1.2c0.5-1,0.9-1.9,0.9-3c-0.2-2.3,1-2.8,3-2.3c0.7,0.2,1.4,0.2,2.1,0.2
							c0.7,0.1,1.1,0.3,1,1.1c-0.7-0.1-1.4-0.4-2.1,0.2c-2.2-0.6-3.5,0.1-3.4,2.6c0,0.5-0.2,1.1-0.5,1.6c-1.4,2-0.3,2.1,1.3,1.8
							c0.3,0,0.5-0.2,0.5-0.5c0.1-0.3,0.3-0.7,0.4-1c0.6-0.5,0.3-2.1,1.8-1.5c0,0.6-0.1,1.2-0.1,1.9c0,0.6-0.5,1.3,0.4,1.6
							c0.7,0.2,1.4,0.2,1.7-0.7c0.4,0,0.8,0.1,1.2,0.1c0.1,0.4,0.2,0.8,0.3,1.2c-0.7,0.8-1.6,0.3-2.4,0.3c-1.8,0.1-3.9,0.2-5.5-0.1
							c-3.7-0.6-7.9,0.9-11.1-2.3C357.9,246.7,357.4,245,357.5,243.4z M361.8,248.4c3.2,1.1,1.9-1.7,2.1-3c0.4-2.5-1.9-1.3-2.8-1.6
							c-1.6-0.4-2.2,1-2.3,2.3C358.8,248,359.9,248.6,361.8,248.4z"/>
						<path fill="#E9E4B6" d="M366.9,254.6c0.1-1.1,1.1-0.9,1.7-0.8c3.1,0.2,6.1,0.4,9.2,0.7c1.9,0.2,2.8,1.3,2.5,3.4
							c-0.2,1.5,0.1,3.2-1.7,4c-0.5,0.2-0.8,0.5-1.5-0.2c-1.1-1-2.9-0.8-4.3-0.7c-2,0.1-3.8-0.3-5.7-0.6c-0.1,0-0.2-0.5-0.3-0.7
							c0.9-0.7,3.1,0.9,3-1.9c0-1.8,0.3-3.1-2.1-2.9C367.4,254.9,367.2,254.7,366.9,254.6z M371.3,259.6c1,0.7,1.5,0.1,2.3-0.4
							c1-0.7,0.7,1.5,1.8,1c0.1-0.1,0.3-0.1,0.4-0.2c0.6-1.2,1.1-0.6,1.6,0.1c0.4,0.5,1.1,0.7,1.5,0.3c0.5-0.4,0-0.9-0.3-1.3
							c-0.5-0.8-0.8-1.4,0.2-2.1c0.6-0.4,0.3-1-0.3-0.9c-1.9,0.3-3.7,0.3-5.5-0.6c-0.3-0.1-0.9,0.1-0.7,0.4
							C373.5,257.6,371.7,258.4,371.3,259.6z"/>
						<path fill="#F5F0BC" d="M368.2,282.5c-0.2,0-0.3,0-0.5,0c-13.5-1.5-27-2.9-40.5-4.4c-3.4-0.4-6.7-1.1-10.1-1
							c0-0.1-0.1-0.1-0.1-0.2c0.7-1.4,2-1,3.2-0.9c3.7,0.3,7.3,1.4,11.1,1.6c7.3,0.4,14.5,1.8,21.9,2.3c3.7,0.2,7.3,0.6,10.9,1.2
							c0.9,0.1,1.8,0.3,2.9-0.1C367.8,280.6,368.1,281.7,368.2,282.5z"/>
						<path fill="#F5F0BC" d="M421.4,315.8c-0.2,0-0.5-0.1-0.7-0.1c-1-1.4-1.8-1.4-3-0.3c-9.9-0.9-19.8-1.8-29.7-3.1
							c1-1.2,1.7-3.4,3.6-0.8c0.2,0.3,1.1,0.3,1.6,0.3c4-0.2,8,0.4,11.9,1.3c3.9,0.9,8,0.6,12,0.7c2,0.1,4.1,0.3,6.1,0.4
							c0.7,0,1.3,0.4,1.2,1.2c-0.1,0.8-0.9,0.5-1.4,0.5C422.6,315.9,422,315.8,421.4,315.8z"/>
						<path fill="#3E3821" d="M335.8,241.2c1.1,0.9,1.2,2.1,0.7,3.3c-0.5,1.1-1.7,0.8-2.4,1.2c-1.6,1-2.4,1-3.7-0.4
							c-0.4-0.5-1.6-0.5-2.1-1.4c0-1.1,0-2.2,0.9-3.1c0.2-0.1,0.4-0.2,0.6-0.2c0.8-0.2,2.3,1.2,2.2-1c0.7-0.5,1.3-0.6,1.7,0.3
							C333.3,242,335.1,240.7,335.8,241.2z"/>
						<path fill="#888158" d="M314,588.9c0.9,1.6,0.3,2-1.4,1.7c-4.7-0.8-9.5-1.2-14.3-1l-0.1-0.1c-0.5-0.6-0.9-1.2-1.7-0.5
							c-1.2-0.1-2.3-0.5-3.5-0.7c-0.8-0.2-1.1-0.1-1-1c0-1,0.6-1.3,1.4-1c2,0.8,4.1,0.6,6.1,1c2.2,0.4,4.4,0.5,6.6,0.6
							c2.2,0.8,4.4,1,6.7,0.9C313.3,588.9,313.6,588.9,314,588.9z"/>
						<path fill="#E9E4B6" d="M214,361.4c1.2,0.1,2.5,0.3,3.7,0.4c0.2,0,0.4,0,0.6,0c0.2,0,0.5,0,0.7,0.1c0.2,0,0.4,0.1,0.6,0.1
							c0.4,0,0.8,0.1,1.1,0.1c1.4,0.5,2.9,0.6,4.4,0.5c0.7,0.1,1.3,0.1,2,0.2c1.1,0.4,2.3,0.5,3.5,0.4c1.2,0.1,2.4,0.2,3.7,0.4
							c0.2,0,0.4,0,0.6,0.1c2.8,0.2,5.6,0.9,8.4,0.6c0.1,0,0.3,0,0.4,0c4.6,1,9.3,1.1,14,1.7c1.1,0.2,2.2,0.3,3.3,0.5
							c2.5,0.6,5.1,0.8,7.7,0.9c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0.1c3.5,0.4,7,0.8,10.6,1.2c0,0.1,0,0.3,0,0.4
							c-22.4-2.4-44.9-4.8-67.3-7.2c-0.5-0.1-1-0.1-1.4-0.4C212.8,361.5,213.4,361.4,214,361.4z"/>
						<path fill="#F5F0BC" d="M439.2,348.6c-6.4-0.7-12.7-1.4-19.1-2.1c-1.4,2.9-1.3,6.1-1.2,9.2c0.2,0.2,0.3,0.4,0.5,0.5
							c0.3,0.2,0.5,0.3,0.8,0.5c-0.6,1.1-1.8,0.8-2.7,1.4c-0.1,0-0.2-0.1-0.4-0.1c-0.2-1.2,0.7-2.4-0.2-3.5c0.1-0.8,0.2-1.6,0.3-2.5
							c0.4-0.5,0.8-0.9,0.5-1.6c0.4-0.1,0.6-0.4,0.6-0.8c-0.3-0.9,1.1-1.8-0.2-2.6c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.1,0.2-0.2,0.4-0.4
							c0.9,0.1,0.9-1,1.6-1.3c0.3-0.1,0.9-0.7,1-0.1c0.2,1.8,1.6,1.4,2.6,1.5c4.9,0.4,9.7,1.4,14.6,1.3c0.8,0,1.4,0.4,1.4,1.3l0,0
							C439.7,348.6,439.5,348.6,439.2,348.6z"/>
						<path fill="#F5F0BC" d="M455.8,468.1c-8.8-0.9-17.5-1.8-26.3-2.7c-0.9-1-2.2-0.5-3.3-0.8c0.2-1,1-0.8,1.7-0.7
							c8.7,0.9,17.5,1.9,26.2,2.8c0.9,0.1,1.7,0.3,2.2,1.1C456.1,467.9,455.9,468,455.8,468.1z"/>
						<path fill="#B7AF8B" d="M362.8,598.4c0.2-1.4,0.9-2,2.1-1.2c0.7,0.5,1.4,0.3,2.1,0.3c0.5,0,1.3,0.5,1.4-0.3
							c0.1-0.7-0.7-0.8-1.2-0.8c-1.1-0.1-1-0.8-0.8-1.5c0.2-1,1.2-1.4,1.8-0.9c1.2,1,2.4,0.8,3.7,0.8c1.1,0,1.5,1.1,2.5,1.4
							c0.2,0.1,0.4,0.4,0.2,0.7c-0.2,0.3-0.5,0.6-0.9,0.5c-0.6-0.1-1.2-0.3-1.8-0.6c-1.6-0.8-1.7,0.1-1.9,1.4c0.9,0,1.8-1.2,2.5,0.1
							c0,0.4-0.1,0.8-0.1,1.1C369,599.7,365.9,599.4,362.8,598.4z"/>
						<path fill="#3E3821" d="M456.6,470.4c-0.4,4.5-0.9,9-1.3,13.5c-0.5,0.5-0.5,1.1-0.4,1.8c0,0.3-0.1,0.6-0.1,0.9
							c-0.1,0.6-0.3,1.2-0.4,1.9c-1.1,10.6-2.3,21.3-3.4,31.9c-0.2,1.5-0.5,2.9-0.3,4.4c-0.2,2.1-0.5,4.2-0.8,6.3
							c-0.1,0.6,0.2,1.5-0.7,1.8c1.7-16.2,3.4-32.3,5.1-48.5c0.6-5.3,1.1-10.6,1.6-15.9c0.3,0.1,0.5,0.1,0.8,0.2
							c-0.1,0.3-0.1,0.7-0.2,1C456.4,469.8,456.4,470.1,456.6,470.4z"/>
						<path fill="#3E3821" d="M273.8,239c-1.4,0.1-1.9-0.6-1.6-2c0.1-0.6,0.1-1.3,0.2-1.9c0.1-0.7-0.4-0.9-0.9-0.9
							c-0.6-0.1-1,0.1-1.1,0.7c0,0.3,0,0.6-0.1,0.8c-0.4,0.9,0.7,2.9-1.4,2.6c-1.9-0.3-0.5-1.9-0.7-3c-0.1-0.5,0.1-1.1,0.1-1.7
							c0-1.2,0.5-1.7,1.7-1.5c1.5,0.3,3.1,0.1,4.6,0.7c0,0,0,0.1,0,0.1c-0.2,2-0.5,3.9-0.7,5.9l-0.1,0L273.8,239z"/>
						<path fill="#3E3821" d="M375.5,250.2c-0.1-0.4-0.2-0.8-0.3-1.2c0.4-1.1,0.4-2.3,0.5-3.4c0.4-0.1,0.8-0.1,1.1-0.2
							c0.4,1.2,0.6,2.4,2.2,0.8c1-0.9,2.5-0.6,3.4,0.5c0.8,0.9,0.9,2.2-0.1,3.1c-1.1,0.9-2.5,1.6-3.9,0.3c-0.6-0.1-0.6-3.1-1.7-0.3
							C376.7,250,376,250,375.5,250.2z"/>
						<path fill="#F5F0BC" d="M271.1,275.5c0.3-1,1-0.8,1.7-0.7c10.9,1.2,21.9,2.3,32.8,3.5c0.5,0.1,1.3-0.2,1.3,0.7
							c0,0.2-0.4,0.3-0.7,0.5c-0.4,0-0.9,0.1-1.3,0.1l0.1-0.1c-3.3-1.1-6.7-1-10.1-1.4c-2.4-0.3-4.9-0.9-7.4-0.5
							c-0.3,0-0.5-0.1-0.8-0.1c-0.6-0.6-1.5-0.5-2.2-0.5C280.1,276.5,275.6,276,271.1,275.5z"/>
						<path fill="#E9E4B6" d="M439.5,256.7c-0.5,0.9-0.6,2.9-2.3,0.8c-0.4-0.4-1.5-0.3-2.2-0.4c-2.8-0.5-5.7-0.1-8.2-1.8
							c-0.6-3-0.6-3,0-4.6c0.9,0.5,2.1-0.2,3,0.4c-1,0.9-1.7,1.9-1.1,3.3c0.5,1.1,1.5,1.4,2.6,1.4c2.1-0.1,2.7-1.6,1.8-4
							c0.7,0,1.4,0.1,2.2,0.1c-0.1,0.9-0.2,1.8-0.2,2.7c0,0.5-0.3,1.2,0.4,1.5c0.6,0.2,1.1,0,1.5-0.4c0.3-0.3,0.5-0.7,1.2-0.5
							C437.8,256.5,438.1,257.3,439.5,256.7z"/>
						<path fill="#888158" d="M262.2,566c0.2,0.1,0.5,0.2,0.7,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c-0.7,0.2-1.9-1.1-2,0.4
							c-0.2,2.4-1,2.8-3,1.5c-0.3-0.2-0.8-0.3-1.2-0.4c-0.6-0.1-0.9,0.6-0.6,0.7c1.8,0.7,0.7,2.1,0.8,3.1c-0.4,1.3-1.4,0.4-2.2,0.5
							c0.2-1.9,0.4-3.7,0.6-5.6c0.1-0.6,0.5-1.3-0.6-1.3c-0.9-0.1-1.1,0.4-1.2,1.1c-0.3,2.7-0.5,5.4-0.8,8c-0.2,0-0.3,0-0.5,0
							c-0.1-2.9,0.8-5.7,0.9-8.6c0.1-1.1,0.8-1.1,1.7-1C257.3,565.5,259.8,565.7,262.2,566z"/>
						<path fill="#888158" d="M347.6,561.2c2,1,4.2,0.9,6.4,1.1c2.9,0.3,5.8,1,8.7,0.7c0.6-0.1,0.9,0.3,0.9,0.9
							c0,0.6-0.3,0.9-0.9,0.9c-1.5-0.1-2.9-0.2-4.4-0.3c-0.7,0-1.4-0.4-1.9,0.3c0,0,0,0.2,0,0.2c-0.2,0.1-0.3,0.2-0.5,0.2
							c0,0,0,0.1,0,0.1c-0.1-1-0.8-1.2-1.6-1.2c-1.9-0.2-3.7-0.4-5.6-0.6C347.5,563.1,346.4,562.7,347.6,561.2z"/>
						<path fill="#E9E4B6" d="M362.8,598.4c3.1,1.1,6.2,1.4,9.4,1c2.2,0.6,4.6,0.2,6.7,1.2c-0.1,0.1-0.1,0.3-0.1,0.4
							c-1.7,1.2-3.6,0.4-5.4,0.3c-4.5-0.4-8.9-1.6-13.5-1.4c-0.8,0-1.9-0.4-2.8-0.6c0.4-1.1,1.4-1.7,2.2-2.4
							c0.7-0.6,1.9-1.1,2.1,0.8C361.6,598.3,362.3,598.3,362.8,598.4z"/>
						<path fill="#3E3821" d="M370.4,247.6c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.2-0.3,0.3-0.5,0.5c-1.6,0.3-2.7,0.2-1.3-1.8
							c0.3-0.4,0.5-1.1,0.5-1.6c-0.1-2.5,1.2-3.1,3.4-2.6c0.7,0.1,1.4,0.2,2.1,0.3c0.5,0.3,0.4,0.8,0.5,1.2
							c-0.4,1.4-0.6,2.8-0.5,4.3c-0.3,0.8-1,0.9-1.7,0.7c-0.9-0.3-0.4-1-0.4-1.6c0-0.6,0.1-1.2,0.1-1.9c0.3-0.6,0.1-1.1-0.6-1.2
							c-0.5-0.1-1,0.1-1.1,0.7C370.4,246.3,370.4,246.9,370.4,247.6z"/>
						<path fill="#CCDEC7" d="M205.3,560.4c-2.9,0.1-2.9,0.1-3.3,3.3c-0.2,1.7-0.4,3.4-0.7,5c-0.2,1.1,0.1,1.7,1.4,1.8
							c2.3,0.2,4.7,0.4,7,0.7c1.3,0.2,1.8-0.2,1.9-1.5c0.3-2.7,0.7-5.3,1.1-8c0.2,3-0.5,5.9-0.8,8.9c-0.1,1.3-1.1,1.9-2.4,1.8
							c-2.2-0.2-4.4-0.5-6.6-0.7c-1.6-0.2-2.5-1.5-2.6-2.7c-0.2-2.6,0.5-5.2,1.3-7.7C202.3,559,203.8,559.5,205.3,560.4z"/>
						<path fill="#888158" d="M329.2,592.4c-3.9-0.6-7.9-1.1-11.9-1c-1.8,0-2.5-0.6-1.4-2.3c4.4,0.5,8.8,1,13.3,1.5
							c0.9,0.1,1.6,0.5,2.4,0.7c1.1,0.3,0.6,0.9,0.1,1.4c-0.3-0.2-0.6-0.2-1,0C330.3,592.5,329.8,592.4,329.2,592.4z"/>
						<path fill="#888158" d="M311.2,580.5c-0.1,0.3-0.3,0.7-0.4,1c-0.6,0-1.3,0-1.9,0c0-0.3,0-0.7,0-1c1.2-0.8,0.3-1.7,0.2-2.6
							c-1,0.3-1.8,1-2.8,0.7c0.3-2.6,0.5-5.1,0.8-7.7c1.7-0.2,3.4,0,5.1,0.6c0,0.3-0.1,0.5-0.1,0.8c-1.3,0.2-0.7,1.4-1.1,2.1
							c-0.8-0.3-1.6-0.2-2.3,0.2c-0.2,0.2-0.4,0.4-0.7,0.5c-0.9,0.3-1.2,0.9-1.1,1.8c0,0.2,0.2,0.4,0.3,0.4c1-0.1,0.7-1,1.1-1.6
							c0.1-0.1,0.2-0.2,0.3-0.3c-0.1,1.1,0,2,1.5,1.8C311,578.1,310.5,579.5,311.2,580.5z"/>
						<path fill="#888158" d="M409.3,604.5c0.6,0.1,1.1,0.2,1.7,0.3c0,1.2,2.1,0.4,1.8,1.9c-1.1,1.1,0,1.4,0.6,1.3
							c2.3-0.2,4.4,0.3,6.6,0.8c-0.1,0.3-0.2,0.5-0.2,0.8c-0.8,0-0.9,0.6-1,1.2c-1.6-0.4-3.2-0.9-4.7-1.3c-0.3-0.6-0.8-0.7-1.5-0.5
							c-1.4,0-2.8-0.1-4.1-0.1c-1,0-1.4-0.4-1.2-1.4c0.1-0.1,0.3-0.2,0.4-0.4c0.9,0.3,1.9,0.2,2.7,0.8c0.5,0.4,1,0,1.2-0.5
							c0.3-0.7-0.3-0.8-0.8-0.9c-0.7-0.1-1.4-0.2-2.1-0.3C409,605.7,409.2,605.1,409.3,604.5z"/>
						<path fill="#888158" d="M307.9,594.6c0.9,1.1,1.6-0.2,2.5-0.1c2.2,0.2,4.4,0.4,6.6,0.8c0.7,0.1,1.3,0.4,0.7,1
							c-0.4,0.4-0.3,1.8-1.5,1.3c-3.4-1.4-7.1-1.1-10.6-1.6c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.2-0.2-0.3c0-0.4,0.1-0.9,0.1-1.3
							c0,0,0,0,0,0C306.3,594.4,307.1,594.5,307.9,594.6z"/>
						<path fill="#F5F0BC" d="M274,238.9c0.2-2,0.5-3.9,0.7-5.9c1.7,2.1,4.2,1,6.3,1.4c0.7,0.1,1.4-0.2,1.4,0.9
							c-1.2,0.3-2.2,1.7-3.6,0c-0.6-0.7-2.3,0.3-2.7,1.2c-0.6,1.3,1.2,1.2,1.7,2.1c0.2,0.4,0.8,0.6,1.2,0c1.2-1.8,1.7-0.5,2.3,0.5
							c-0.2,1.5-1.2,1.7-2.4,1.8c-1.1,0.1-1.5-0.4-1.7-1.4C276.5,237.6,275.1,238.8,274,238.9z"/>
						<path fill="#F5F0BC" d="M229.7,209.5c9.7,1,19.4,2.1,29,3.1c0,0.1,0,0.3,0,0.4c-9.7-1-19.4-2.1-29-3.1
							C229.7,209.8,229.7,209.7,229.7,209.5z"/>
						<path fill="#3E3821" d="M319.5,243.8c-1,0.1-2.2-0.5-3,1.1c-0.3,0.7-1.4,0.3-1.6-0.7c0-0.3,0.1-0.5,0.1-0.8
							c0-0.3,0.1-0.6,0.1-0.8c0.5-1.1,0.2-2.2,0.4-3.3c0.1-0.1,0.2-0.2,0.3-0.3c0.8,0.2,1.7,0.6,2.5,0.1c0.3,0,0.6,0.1,0.8,0.1
							c0.2,0.2,0.4,0.3,0.7,0.5C320.8,241.1,320.4,242.5,319.5,243.8z"/>
						<path fill="#F5F0BC" d="M407.7,253.8c-0.2-0.2-0.4-0.4-0.5-0.6c1-0.8,1.6-1.9,1.1-3.1c-0.7-1.6-2.2-1.6-3.6-1.5
							c-0.1-0.1-0.1-0.2-0.2-0.3c0.8-2.3,2.3-1.1,3.3-0.5c0.9,0.5,1.6,0.6,2.5,0.3c1.4-0.5,2.7-0.5,3.9,0.7c0.6,0.6,1.4,0.7,2.1,0.8
							c0,0.2,0,0.4,0,0.6c-1.5,1.2-1.5,2.5-0.4,3.9c-0.8-0.1-1.6-0.1-2.3-0.2c1-1.2,0.7-2.8,0.3-3.8c-0.3-0.9-2-1.2-3.2-0.9
							c-1.1,0.3-0.6,1.4-0.8,2.2c-0.1,0.7-0.1,1.4-0.2,2.1C409,253.6,408.3,253.7,407.7,253.8z"/>
						<path fill="#E9E4B6" d="M262.2,566c-2.5-0.2-4.9-0.4-7.4-0.7c-0.9-0.1-1.6-0.1-1.7,1c-0.1,2.9-1,5.7-0.9,8.6
							c0,0.1,0,0.3-0.1,0.4c-1.1,0-1.2-0.6-1.2-1.5c0.2-3.1,1-6.1,0.7-9.3c-0.1-1.2,1.1-1.4,1.8-0.9c2,1.4,4.3,0.7,6.4,1.1
							C260.9,564.8,261.8,564.9,262.2,566z"/>
						<path fill="#F5F0BC" d="M212.3,294.6c-0.2,1.6-0.3,3.3-0.5,4.9c-0.2,0.2-0.2,0.4-0.1,0.6c-0.5,4.5-0.9,8.9-1.4,13.4
							c-0.1,0.2-0.1,0.4-0.2,0.5c-0.4,2.1-0.5,4.2-0.5,6.3c-0.3,0.2-0.3,0.4-0.1,0.6c-0.4,2.1-0.1,4.2-0.9,6.2
							c-0.6-0.8-0.2-1.6-0.1-2.4c1-9.6,2.1-19.3,3.1-28.9c0.1-1.1,0.4-2.2,0.1-3.4c-0.2-0.9,0.1-1.4,1.1-1.2c0,0.7,0,1.4,0,2.1
							c-0.1,0.3-0.1,0.5-0.2,0.8c0,0-0.1,0-0.1,0C212.2,294.1,212.1,294.3,212.3,294.6z"/>
						<path fill="#888158" d="M313.4,592.7c1.3-0.5,2.4-0.8,3.9-0.3c1.5,0.5,3.1,0.6,4.6,0.5c1.7-0.2,3.1,1,4.8,0.8
							c1.4-0.2,0.6,0.8,0.6,1.3c-0.3,0.3-0.7,0.9-0.9,0.8c-4.3-2.1-9.1-1.2-13.6-2.4C313,593.2,313.2,592.9,313.4,592.7z"/>
						<path fill="#888158" d="M353.3,556c2.3-0.3,4.5,0.9,6.8,0.6c1.1-0.2,0.6,0.8,0.8,1.3c0.4,0.6-0.1,0.8-0.5,0.7
							c-3.5-0.8-7.1-0.4-10.6-1.4c-0.1,0-0.2-0.2-0.3-0.3c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.8,0.2-1.2c0.7-0.5,1.5-0.1,2.2-0.1
							c0.2,0.4,0.5,0.7,1,0.6C353,556,353.1,556,353.3,556z"/>
						<path fill="#3E3821" d="M340.8,245c-0.5,0.4-0.9,0.9-1.4,1.1c-0.5,0.2-1.3,0.2-1.4-0.5c0-1.2,0.1-2.4,0.4-3.6
							c0.1-0.6,1.1-0.7,1.2-0.4c0.6,1.9,1.4,0.6,2.3,0.3c0.6-0.2,1.3,0,1.3,0.9c0,0.6-0.1,1.3-0.2,1.9c-0.1,0.6,0.2,1.5-0.8,1.7
							C341.1,246.6,341.2,245.6,340.8,245z"/>
						<path fill="#888158" d="M258.2,584.5c3-1.7,5.9-0.2,8.7-0.1c1.1,0.1,2.2,0.2,3.2,0c0.8-0.1,1.3-0.1,1.4,0.8
							c0.1,1-0.7,1.1-1.5,1c-2.6-0.3-5.3-0.6-7.9-0.9C260.9,585.2,259.4,585.8,258.2,584.5z"/>
						<path fill="#F5F0BC" d="M287.2,451.7c0.2-2.1,0.4-4.2,0.7-6.3c0.6-1.2,0.3-2.6,0.7-3.8c0.9,0.3,0.6,1,0.6,1.6
							c-0.6,5.9-1.3,11.8-1.9,17.7c-0.1,0.6,0,1.3-0.9,1.5c0-1.1,0.1-2.3,0.1-3.4c0.2-2.3,0.5-4.5,0.7-6.8c0,0,0.1-0.1,0.1-0.1
							C287.3,451.9,287.2,451.8,287.2,451.7z"/>
						<path fill="#3E3821" d="M346.6,254.3c0.3-0.3,0.6-0.7,0.9-0.9c0.5-0.3,1-0.6,1.6-0.2c0.5,0.4,0.2,0.9,0.2,1.3
							c-0.1,0.4,0,0.9-0.1,1.2c-0.2,0.6,0.2,1.5-0.8,1.8c-1.2,0.3-0.9-0.9-1.2-1.5c-1.1,0.3-1.7,2.1-3,0.8c-0.4-0.4,0.2-3.7,0.6-4
							C345.5,252.5,345.9,252.8,346.6,254.3z"/>
						<path fill="#E9E4B6" d="M289.1,236.1c-0.5,1.5-0.3,3.1-0.5,4.7c-0.1,0.6-0.1,1.6-0.9,1.4c-0.7-0.2-1.9-0.1-2.1-0.5
							c-0.4-0.8-1.3-1.4-1.1-2.4c0.4-1.9-0.6-1.2-1.5-0.9c-0.4-0.3-0.9-0.7-1.4-1c0.5-0.7,2-0.6,1.4-1.9c0.1-0.1,0.1-0.1,0.2-0.2
							c0.3,0,0.6,0.1,0.9,0.1c1.4,0.2,2.1,0.7,1.8,2.3c-0.2,0.8-1.1,2.4,0.5,2.6c1.8,0.2,0.9-1.6,1.3-2.5
							C288,237,287.5,235.7,289.1,236.1z"/>
						<path fill="#3E3821" d="M349,245c-0.2,0.7,0.2,1.8-0.8,2c-1,0.3-0.8-0.9-1.2-1.3c-0.6-0.7-0.9,0-1.1,0.5
							c-0.3,0.6-0.7,0.6-1.2,0.4c-0.5-0.3-0.4-0.7-0.2-1.1c0.1-0.3,0.5-0.7,0.4-0.9c-0.6-3,1.5-2.2,3-2.2
							C349.9,242.4,348.9,244,349,245z"/>
						<path fill="#3E3821" d="M357.5,243.4c-0.1,1.6,0.4,3.3-0.9,4.7c-0.3-0.2-0.9-0.2-0.9-0.8c-0.3-1.9-0.8-2.1-1.5-0.2
							c0,0.1-0.1,0.3-0.2,0.3c-0.5,0.2-1.2,0.1-1.2-0.4c0-1.3-0.3-2.8,0.7-4c0.3-0.4,1-0.1,1.2,0.4c0.5,2.1,1,1.4,1.6,0.1
							C356.4,243.1,357,243.4,357.5,243.4z"/>
						<path fill="#3E3821" d="M322.4,240c1.5-0.6,3-0.3,4.4,0.4c-0.1,1.1-0.2,2.3-0.3,3.4c-1.3,1.4-2.8,0.5-4.2,0.3
							C321.4,242.7,321.6,241.3,322.4,240z"/>
						<path fill="#3E3821" d="M433,251.9c0.9,2.4,0.3,3.9-1.8,4c-1.1,0-2.1-0.3-2.6-1.4c-0.6-1.4,0.1-2.4,1.1-3.3
							C431,250.5,432.1,250.6,433,251.9z"/>
						<path fill="#32613E" d="M307.1,570.9c-0.3,2.6-0.5,5.1-0.8,7.7c-0.1,0.4-0.1,0.8-0.2,1.2c-0.5,0.3-0.5,0.7-0.6,1.2
							c-2.3,0.1-2.1-1.3-1.7-2.8c1.1-2.4,0.4-5.1,1.5-7.4c0.2,0,0.4,0,0.6,0c0,0,0.1,0.1,0.1,0.1l0.1,0
							C306.5,570.9,306.8,570.9,307.1,570.9z"/>
						<path fill="#CEC69D" d="M252.2,575.3c0-0.1,0-0.3,0.1-0.4c0.2,0,0.3,0,0.5,0c0.3,0,0.5,0.1,0.8,0.1c0.4,0.1,0.7,0.2,0.9-0.3
							c0.1-0.7,0.2-1.4,0.3-2c0.7-0.1,1.7,0.7,2.2-0.5c1.3-0.3,0.4,1.4,1.4,1.4c0.3-0.7,0.4-1.4,0.2-2.2c-0.1-0.5-0.3-1.1,0.4-1.2
							c0.6-0.1,1.2,0.3,1.3,0.8c0.2,1.1,0.2,2.7-0.6,3.2c-2.1,1.1,0.3,1.2,0.3,1.7c-0.7,0.4-1.4,0-2.2,0.1c-1.1-0.1-2.3-0.2-3.4-0.4
							c-0.6-0.1-1.1-0.1-1.7-0.2C252.5,575.4,252.3,575.3,252.2,575.3z"/>
						<path fill="#32613E" d="M373.7,581.9c1,0.3,3-0.5,2.4,2c-0.2,0.8-0.5,1.1-1.3,1c-0.8-0.1-1.7-0.2-2.5-0.3c-1-0.1-2,0-1.9-1.6
							c0.1-1.5,1-1.4,2-1.3C372.9,581.8,373.2,581.9,373.7,581.9z"/>
						<path fill="#3E3821" d="M283.1,235.4c0.6,1.3-0.9,1.3-1.4,1.9c0.5,0.4,0.9,0.7,1.4,1c0.2,1.5-1.6,0.4-1.8,1.3c0,0,0,0,0,0
							c0-0.2,0-0.3,0-0.5c-0.6-1-1.1-2.3-2.3-0.5c-0.4,0.6-1,0.4-1.2,0c-0.4-0.9-2.3-0.8-1.7-2.1c0.5-0.9,2.2-1.9,2.7-1.2
							c1.4,1.7,2.4,0.4,3.6,0C282.6,235.4,282.8,235.4,283.1,235.4z"/>
						<path fill="#888158" d="M422.9,565.3c-1.1,0.5-2.2,0.2-3.3,0.1c-2.3-1.1-4.8-0.6-7.2-1.2c-1-0.2-0.6-1-0.6-1.5
							c0.8,0.4,1.6,0.8,2.5,0.1c2.9,0.5,6,0.2,8.8,1.5C423,564.6,423,565,422.9,565.3z"/>
						<path fill="#3E3821" d="M409.6,253.5c0.1-0.7,0-1.4,0.2-2.1c0.2-0.8-0.3-1.9,0.8-2.2c1.2-0.3,2.9,0,3.2,0.9
							c0.4,1.1,0.8,2.6-0.3,3.8l-0.1,0l-0.1,0.1c-1.1-0.1-2.3-0.2-3.4-0.3C409.9,253.5,409.8,253.5,409.6,253.5z"/>
						<path fill="#3E3821" d="M366.9,254.6c0.3,0.1,0.5,0.4,0.7,0.3c2.5-0.3,2.1,1.1,2.1,2.9c0,2.8-2.1,1.1-3,1.9
							c-0.3,0-0.5-0.1-0.8-0.1c-1.7-1.8-1.7-2.8,0.1-4.7l0,0C366.3,254.8,366.6,254.7,366.9,254.6L366.9,254.6z"/>
						<path fill="#888158" d="M260.1,546.3c1.4-0.2,2.7,0.2,4,0.4c1.4,0.2,2.9,0.6,4.3,0c1-0.4,1.4,0.3,1.5,0.9c0.1,1-0.6,1.1-1.4,1
							c-1.7,0-3.4,0.1-5-0.6c-1.2-0.6-2.4,0.5-3.6,0.1C260,547.5,260,546.9,260.1,546.3z"/>
						<path fill="#888158" d="M409.6,602.1c0.7,0.1,1.4,0.1,2.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0-0.1,0.1-0.2,0.1-0.3
							c0.1-0.1,0.1-0.2,0.2-0.3c0.4,0.1,0.7,0.1,1.1,0.2c0,0,0,0,0,0c0.1,0.6,0.3,0.9,0.9,0.4c2.7,0.1,5.3,0.6,7.9,0.6
							c0.8,0,2.4,0.1,1.2,1.8c-0.2-1.6-1-0.8-1.6-0.3c-2-0.2-4-0.4-6-0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.5-0.6-1.1-0.7-1.8-0.2
							c-0.2,0-0.4,0-0.7,0c-0.6-0.8-1.4-0.5-2.2-0.6C409.8,603,409.7,602.6,409.6,602.1z"/>
						<path fill="#CEC69D" d="M360.3,259.3c-0.9-0.5-1.6-1.5-2.8-0.4c-0.3,0.3-0.7-0.1-0.9-0.5c1.7-1.3,1.9-2.7,0.5-4.4
							c0.1-0.3,0.4-0.9,0.7-0.6c0.9,1,1.6,0.3,2.4-0.2c1.4-0.7,2.8-0.1,3.3,1.3c-1-0.1-2-0.2-2.9-0.2c-0.5,0-1.3,0.2-0.6,0.7
							c1.4,1.1,0.4,2.3,0.6,3.5C360.4,258.8,360.4,259,360.3,259.3z"/>
						<path fill="#32613E" d="M364.8,583.9c-0.9-0.3-2.9,0.5-2.4-2c0.2-0.8,0.4-1.1,1.3-0.9c0.9,0.2,1.8,0.3,2.7,0.3
							c1,0,1.8,0.1,1.6,1.5c-0.2,1.2-0.8,1.5-1.9,1.3C365.8,583.9,365.4,583.9,364.8,583.9z"/>
						<path fill="#E9E4B6" d="M328.1,228.3c-1.3-0.1-1.8-1.8-3.2-1.7c-0.3-0.4-0.8-0.3-1.2-0.3l-0.1,0c0.9-0.9,1.4-2,1.3-3.3
							c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0c0.3-0.1,0.6-0.2,0.9-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.1-0.1,0.3-0.1
							c0.8,0.1,1.6,0.1,2.4,0.2c0,0,0.1,0,0.1,0c-0.1,0.8-1,0.9-1.2,1.6c-1.7,0-1.5,1-1.1,2.1C328.3,227,328.2,227.6,328.1,228.3z"
							/>
						<path fill="#3E3821" d="M357.1,253.9c1.4,1.6,1.2,3.1-0.5,4.4c-1.9,1.2-2.7-0.1-3.8-1.4c0.9-0.7,3.2,0.8,2.6-1.8
							c-0.6-0.1-1.4-0.2-2.3-0.4C354.3,253.2,355.6,252.8,357.1,253.9z"/>
						<path fill="#F5F0BC" d="M376.2,605.4c-0.6-1.3-1.6-1.6-3.1-1.8c-2-0.3-3.9-0.2-5.9-0.5c-0.5-0.1-1.2-0.1-1-0.7
							c0.1-0.6,0.7-0.5,1.4-0.4c4.2,0.4,8.5,0.7,12.7,1c0.3,0.4,0.7,0.8,1.3,0.7C379.7,604.2,378.1,605.3,376.2,605.4z"/>
						<path fill="#3E3821" d="M439.5,256.7c-1.4,0.5-1.7-0.2-1.4-1.4c-0.7-0.2-0.9,0.2-1.2,0.5c-0.5,0.4-1,0.6-1.5,0.4
							c-0.7-0.3-0.4-0.9-0.4-1.5c0.1-0.9,0.2-1.8,0.2-2.7c0.1,0,0.2-0.1,0.2-0.3c1.5-0.3,1.3,1,1.7,2c0.8-1.1,1.6-1.7,2.8-1.3
							l0.1,0.1c-0.1,1.3-0.2,2.6-0.2,3.8C439.6,256.5,439.5,256.6,439.5,256.7z"/>
						<path fill="#B7AF8B" d="M286.4,458.9c0,1.1-0.1,2.3-0.1,3.4c-1.1,9.4-2.1,18.8-3.2,28.2c-0.4,3-0.3,6.1-1.2,9
							c0.1-4.6,0.8-9.2,1.3-13.7c0.9-8.6,1.9-17.2,2.8-25.7C286.2,459.8,286.3,459.4,286.4,458.9z"/>
						<path fill="#CEC69D" d="M271.1,275.5c4.5,0.5,9,1,13.5,1.5c0.8,0.1,1.6-0.1,2.2,0.5c-4.1-0.4-8.2-0.7-12.3-1.1
							c-0.7-0.1-1.4-0.1-2.1-0.2c-0.3,0-0.6-0.1-0.8-0.1c-6.9-1.1-13.8-1.6-20.8-2.3c-0.1,0-0.3,0-0.4,0c-2.4-0.3-4.8-0.6-7.2-1
							c0.4-0.4,0.9-0.2,1.4-0.2C253.4,273.6,262.3,274.6,271.1,275.5z"/>
						<path fill="#B7AF8B" d="M251.5,585.5c1.9,1.3,4.1,0.3,6.1,0.6c0.9,0.1,1.4,1.1,2.4,0.8c-0.2,0.5-0.4,1-0.6,1.5
							c-2.8-0.3-5.6-0.6-8.5-0.9c-0.7-0.1-1.3-0.1-1.3-1C250.2,586.3,250.9,585.9,251.5,585.5z"/>
						<path fill="#3E3821" d="M426.7,250.8c-0.6,1.6-0.6,1.6,0,4.6c-0.1-0.1-0.3,0-0.4,0.1c-1.3-0.4-2.4-1.9-3.9-0.6
							c-0.1,0.1-0.4-0.1-0.6-0.2c0,0,0,0,0,0c0.1-1,0.2-2.1,0.3-3.1c0.1-0.7,0.6-1.5,1.1-1c1.2,1.1,2.1,0.1,3.1,0.1
							C426.5,250.8,426.6,250.8,426.7,250.8z"/>
						<path fill="#E9E4B6" d="M426.5,250.7c-1,0-2,1-3.1-0.1c-0.5-0.5-1.1,0.3-1.1,1c-0.1,1-0.2,2.1-0.3,3.1
							c-0.8-0.1-1.7-0.2-2.5-0.2c0.3-0.5,1.2-0.6,0.9-1.3c-0.4-0.6-1.1-0.4-1.7-0.2c-0.4,0.2-0.8,0.3-1.1-0.2
							c-0.3-0.5-0.2-1,0.1-1.4c0.3-0.4,0.7-0.3,1.1-0.1c0.5,0.3,1.2,0.9,1.6,0.1c0.4-0.8-0.7-1-1.1-1.6
							C421.7,250,424.2,249.4,426.5,250.7z"/>
						<path fill="#3E3821" d="M340.1,253.1c-0.1,1.2-0.2,2-0.3,2.7c-0.1,0.6-0.6,0.7-1.1,0.7c-0.5-0.1-0.8-0.4-0.7-0.9
							c0-1.2,0.7-2.5-0.8-3.3c-0.3-0.2,0.2-0.8,0.6-0.9c0.8-0.2,1.2-1.3,2.2-1c0.7,0.3,0.5,0.9,0.4,1.5
							C340.3,252.5,340.1,253,340.1,253.1z"/>
						<path fill="#F5F0BC" d="M325.7,230.4c2.9,1,5.7,1.6,8.2-0.9c0.2,0,0.4,0.1,0.5,0.1c-0.1,1.7-1.4,2.1-2.8,2.8
							c-2,0.9-3.9,0.4-5.8,0.1c-1.2-0.2-2.1-1.2-1.7-2.7l0.1-0.1c0.2,0,0.4,0,0.5,0c0.2,0.2,0.4,0.3,0.6,0.5
							C325.5,230.2,325.6,230.3,325.7,230.4z"/>
						<path fill="#3E3821" d="M419.2,249.9c0.4,0.5,1.5,0.7,1.1,1.6c-0.4,0.8-1.1,0.2-1.6-0.1c-0.4-0.2-0.8-0.3-1.1,0.1
							c-0.3,0.4-0.4,0.9-0.1,1.4c0.3,0.5,0.7,0.4,1.1,0.2c0.6-0.3,1.4-0.5,1.7,0.2c0.4,0.7-0.6,0.8-0.9,1.3l-0.1,0l-0.1,0.1
							c-1.1-0.1-2.3,0.5-3.3-0.4l0,0c-1-1.4-1-2.8,0.4-3.9c0.6-0.2,1.1-0.3,1.7-0.5C418.4,249.8,418.8,249.9,419.2,249.9z"/>
						<path fill="#888158" d="M372.9,301.2c0.3,0,0.6-0.1,0.9-0.1c0.3,0,0.6,0,0.8,0.1c0.6,0.1,1.1,0.3,1.7,0.4
							c0.1,0.3,0.3,0.6,0.4,0.9c-0.1,1-0.7,2.1,0.4,3c0,0.3-0.1,0.5-0.1,0.8c-0.5-0.1-0.9-0.2-1.2-0.6c0-0.5,0.3-1.3-0.5-1.3
							c-1-0.1-0.9,1-1.4,1.6c-0.5,0.6-0.4,1.9-1.8,1.5c-0.4-1.6-0.1-3.2,0.4-4.7c0.2,1.5,1.3,0.8,2.1,0.8c0.4,0,0.7-0.4,0.5-0.7
							c-0.4-0.9-1.2-0.3-1.8-0.5c-0.2-0.1-0.4,0-0.6,0C372.9,301.9,372.9,301.6,372.9,301.2z"/>
						<path fill="#CEC69D" d="M251.5,585.5c-0.6,0.4-1.3,0.7-1.9,1.1c0.4-3-2.6-2.3-3.9-3.5c0-0.3-0.1-0.7-0.1-1
							c2.5,0.6,5.1-0.3,7.5,0.6c-0.4,0.5-0.6,1-0.6,1.6C251.6,584.3,251.6,585,251.5,585.5z"/>
						<path fill="#CEC69D" d="M388.3,229.8c0.2-0.4,0.5-0.7,0.6-1.1c0.2-0.8,1.9-1.4,0.7-2.1c-0.9-0.5-2.4-0.9-3.4,0.4
							c-0.6,0.7-0.8,2.3-1.8,1.9c-0.9-0.3-1.1-1.8-1.3-2.9c1.8,0.6,3.4-0.6,5.2-0.4c1.2,0.2,2.8,0.1,3,1.4c0.2,1.2-0.9,2.2-2.1,2.9
							C388.9,229.8,388.6,229.8,388.3,229.8z"/>
						<path fill="#E9E4B6" d="M360.3,259.3c0-0.2,0.1-0.5,0.1-0.7c1.1,0.5,1.5,0,1.5-1.1c0.1-0.6,0.3-1.2,0.4-1.7
							c0.7-0.1,1.3-0.2,1.3-1.1c0.7,0.1,1.5,0.1,2.2,0.2c-1.8,1.9-1.8,2.8-0.1,4.7c-0.1,0.3-0.1,1-0.4,0.9c-1.5-1-3-0.9-4.7-0.6
							C360.7,259.8,360.5,259.5,360.3,259.3z"/>
						<path fill="#E9E4B6" d="M396,227.2c-1.6,3.4-3.2,6.8-4.9,10.2c-1.9-0.3-1.2-1.7-0.6-2.3c1.1-1.1,1.5-2.7,2.5-3.8
							c0.3-0.4,0.5-0.9,0.4-1.4c-0.2-0.6-1-1.3-0.3-1.8C393.9,227.5,394.8,226.8,396,227.2z"/>
						<path fill="#1D190E" d="M289.1,236.1c-1.6-0.4-1.1,0.9-1.4,1.7c-0.4,0.9,0.6,2.8-1.3,2.5c-1.6-0.2-0.7-1.8-0.5-2.6
							c0.3-1.7-0.5-2.1-1.8-2.3c0-0.3,0-0.6,0-0.8c1.9-0.8,3.7,0.2,5.6,0c0,0.2,0.1,0.3,0.3,0.3c0,0.4,0,0.7,0,1.1
							C289.7,235.9,289.4,236,289.1,236.1z"/>
						<path fill="#B7AF8B" d="M313.4,592.7c-0.2,0.3-0.4,0.5-0.5,0.8l0,0c-0.2,0.1-0.4,0.3-0.6,0.4c-0.9-0.1-1.8-0.2-2.7-0.3
							c-1-0.2-1.5,0.2-1.7,1.1c-0.8-0.1-1.7-0.2-2.5-0.3c0.2-0.5,0.4-1,0.6-1.6c1.1-0.1,1.2-0.6,0.6-1.4
							C308.9,591.5,311.3,591.2,313.4,592.7z"/>
						<path fill="#888158" d="M418,567.2c0.3,0.4,0.7,0.3,1,0c0.3,0,0.5,0.1,0.8,0.1c0.2,0.6,0.8,0.3,1.2,0.5c0,0.2,0,0.5-0.1,0.7
							c-0.7,0.6-1.5,0.8-2.4,0.6c-0.3-0.5-0.7-0.5-1.1-0.1c0,0,0,0,0,0c-0.3,0-0.5,0-0.8,0c-0.6-0.1-1.2-0.1-1.9-0.1
							c-1.6-0.1-2.3-0.8-1.6-2.4C414.7,567.4,416.4,566.9,418,567.2z"/>
						<path fill="#B7AF8B" d="M200.3,539.3c2.5,0.5,5.1,0.2,7.6,1.2c0,0.2,0,0.3-0.1,0.5c-0.1,0.3-0.2,0.8-0.2,0.8
							c-2.3-0.6-4.9,0.7-7.1-0.9C200.5,540.3,200.4,539.8,200.3,539.3z M201.4,540.1L201.4,540.1l-0.1,0L201.4,540.1z"/>
						<path fill="#3E3821" d="M447.2,255.8c-1.1,0.1-2.1,1.6-3.2-0.1c-0.1-0.2-1.5,0.4-2.3,0.7c-1-0.9,1.4-1.3,0.3-2.2
							c-0.3-0.2-0.5-0.6-0.1-0.9c0.3-0.3,0.7,0.2,0.9,0.2c0.6,0,1.2,1.8,1.9,0.1c0.3,0,0.5,0,0.8-0.1
							C445.9,254.4,447.5,254.3,447.2,255.8z"/>
						<path fill="#888158" d="M423.4,606.1c0.5,0.1,1.1,0,1.4,0.7c-0.7,0.8-1.6,0.6-2.5,0.6c-2.9,0.2-5.7-0.5-8.4-1.3
							c0.4-0.8,1.2-0.5,1.8-0.7c1.4,0.2,2.8,0.4,4.2,0.6c0.3,0.4,0.5,0.4,0.9,0.1c0.3,0,0.5-0.1,0.8-0.1
							C422.2,605.9,422.8,606,423.4,606.1z"/>
						<path fill="#E9E4B6" d="M418,567.2c-1.6-0.3-3.3,0.2-4.9-0.7c-1.4-0.4-0.7-1.4-0.7-2.3c2.4,0.6,4.9,0.1,7.2,1.2
							C419,566,418.1,566.2,418,567.2z"/>
						<path fill="#B7AF8B" d="M314.7,553.2c-2.1,0.3-4.1-0.1-6.2-0.2c-0.5,0-0.9-0.4-0.9-1c0-0.7,0.6-0.9,1.1-1.2
							c0.5-0.3,0.7,0.1,0.9,0.5c0.1,0.2,0.2,0.7,0.3,0.6c1.7-2.3,3.3,0,4.9,0.1C314.7,552.4,314.7,552.8,314.7,553.2z"/>
						<path fill="#B7AF8B" d="M260.1,546.3c-0.1,0.6-0.1,1.2-0.2,1.8c-1.9-0.3-3.9,0-5.6-1c0.1-0.4,0.1-0.8,0.2-1.3
							C256.5,544.8,258.3,545.5,260.1,546.3z"/>
						<path fill="#888158" d="M379,600.5c-2.2-1-4.5-0.5-6.7-1.2c0-0.4,0.1-0.8,0.1-1.1c2.1,0.1,4.3,0.2,6.4,0.3
							c1.5,0.1,2.1,0.7,1,2.1C379.5,600.6,379.3,600.5,379,600.5z"/>
						<path fill="#888158" d="M406.6,605.8c-2,0.1-4-0.3-6.1-0.5c-0.8-0.1-0.9-0.4-0.9-1.1c0.1-0.9,0.5-0.6,1.1-0.6
							c1.4-0.1,2.6,0.5,3.9,0.5c0.4,0.6,0.7,0.2,1-0.1c0.1,0,0.3,0.1,0.4,0.1C406.3,604.7,406.5,605.3,406.6,605.8z"/>
						<path fill="#B7AF8B" d="M209.3,579.2c-0.8,0-1.4-1.4-2.6-0.3c-0.7,0.6-1.4-1.4-2.5-0.5c-0.1-0.6-0.1-1.2-0.2-1.8
							c1.1,0.1,2.2,0.3,3.4,0.4l0.2,0l0.2,0c0.6,0.2,1.1,0.4,1.7,0.5c0.3,0,0.7,0.1,1,0c0.8-0.1,1,0.3,1,1c-0.1,1.3-1,0.8-1.7,0.7
							C209.6,579,209.5,579,209.3,579.2z"/>
						<path fill="#CEC69D" d="M315.5,239.2c-0.2,1.1,0.1,2.3-0.4,3.3c-1.7-1.8-1.7-1.8-2.9,0.9c-0.2,0.5-0.7,1-1.2,1.6
							c-0.5-0.8-0.9-1.5-1.3-2.2c0.1,0,0.2-0.1,0.3-0.1c0.9,0.3,1.5,0.3,1.6-0.9c0.1-1.3,0.1-2.5,2-2.1c0.3,0.1,0.5-0.4,0.6-0.8
							C314.6,239.1,315.1,239.2,315.5,239.2z"/>
						<path fill="#E9E4B6" d="M414,606.1c2.8,0.8,5.5,1.6,8.4,1.3c0,1.6-1.5,0.9-2.3,1.4c-2.2-0.5-4.3-1-6.6-0.8
							c-0.7,0.1-1.7-0.2-0.6-1.3C413.5,606.9,413.8,606.7,414,606.1z"/>
						<path fill="#E9E4B6" d="M210.6,587.1c0.2,0.6,0,0.9-0.7,0.8c-3.1-0.4-6.2-0.7-9.2-1c-0.6-0.1-1.1-0.3-1.1-1
							c0.2-0.3,0.5-0.3,0.9-0.3c1,0.4,2.9,0.3,3.4-0.1l0,0C206.1,586.3,208.3,586.9,210.6,587.1z"/>
						<path fill="#F5F0BC" d="M421,325.9c0.7,0.2,0.7,0.7,0.7,1.3c-0.4,4-0.9,8-1.3,11.9c-0.1,0.6-0.2,1.1-0.9,1.2
							C419.9,335.6,420.5,330.8,421,325.9z"/>
						<path fill="#888158" d="M408.3,564.1c-1.1-0.1-1.6-0.1-2.1-0.2c-1.1-0.3-0.9-1.2-0.7-1.9c0.3-0.9,1-1,1.7-0.5
							c1,0.6,1.7,1.7,3.1,1.2c0.1,0,0.4,0.2,0.4,0.3c0.1,0.4-0.1,0.7-0.4,0.9C409.5,564.5,408.5,564.1,408.3,564.1z"/>
						<path fill="#F5F0BC" d="M206.7,347.6c-0.1,0.2-0.2,0.5-0.4,0.7c-0.5-0.5-0.3-1.2-0.2-1.8c0.4-3.6,0.8-7.3,1.2-10.9
							c0.1-0.6,0-1.3,0.6-1.7c0.1,0.1,0.2,0.1,0.3,0.1c-0.1,1.4-0.3,2.7-0.4,4.1c-0.3,0.1-0.3,0.3-0.1,0.6c0,0.4-0.1,0.7-0.1,1.1
							c-0.3,0.2-0.3,0.4-0.1,0.6c-0.2,2.2-0.5,4.4-0.7,6.6C206.5,347.1,206.4,347.3,206.7,347.6z"/>
						<path fill="#B7AF8B" d="M364.8,565.1c0.1-1.4,0.8-2,2-1.5c1.6,0.6,3.3-0.7,4.8,0.5c0,0.6,0,1.1-0.1,1.7
							c-0.2,0.1-0.4,0.2-0.5,0.4c-0.3,0-0.5,0-0.8-0.1c-0.3-1-0.9-1.1-1.7-0.6c-0.4,0-0.9,0-1.3,0c-0.6-0.3-1.1-0.3-1.7-0.1
							C365.3,565.3,365,565.2,364.8,565.1z"/>
						<path fill="#E9E4B6" d="M420.2,356.8c-0.3-0.2-0.5-0.3-0.8-0.5c5.6,0.1,11.1,1.3,16.7,1.8c1.6,0.1,2.6,0,2.7-1.9
							c0.5,0.5,0.2,1.1,0.2,1.7c-1.6,1.6-3.6,1-5.3,0.7C429.2,357.6,424.7,357.3,420.2,356.8z"/>
						<path fill="#888158" d="M424.3,563.6c1.3,0.3,2.4,0.7,3.8,0.6c0.7-0.1,1.8,0,2.4,0.9c0.2,0.3,0.6,0.5,0.3,0.9
							c-0.1,0.2-0.5,0.3-0.8,0.2c-1.9-0.4-4-0.1-5.7-1.1C424.3,564.6,423.9,564.1,424.3,563.6z"/>
						<path fill="#B7AF8B" d="M219.7,543.3c-1.6,0.1-3.3,0.2-4.7-0.8c0.1-0.7-0.7-0.9-0.8-1.4c2-1,3.8,0.1,5.7,0.5
							C219.8,542.2,219.8,542.7,219.7,543.3z"/>
						<path fill="#888158" d="M214.2,541.1c0.1,0.6,0.9,0.7,0.8,1.4c-2.5,0.3-5,0.2-7.1-1.5c0-0.2,0-0.3,0.1-0.5
							c0.2,0,0.3,0.1,0.5,0.1l0.2,0l0.2,0c1.1-0.1,2.2-0.2,3.4,0.1c0.2,0.8,0.6,0.8,1.1,0.2C213.5,541,213.8,541,214.2,541.1z"/>
						<path fill="#E9E4B6" d="M439.9,252.4c-1.2-0.4-2,0.2-2.8,1.3c-0.4-1-0.2-2.3-1.7-2c0-0.4,0-0.8,0-1.2c0-0.6-0.7-1.9,0.6-1.7
							c1.1,0.2,3.2-1,3.3,1.6c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1-0.9-1.4,0c-0.2,0.8,0.6,1.2,1.3,1.3c0.5,0,0.9-0.2,1.4-0.4
							C439.9,251.3,440.1,251.8,439.9,252.4z"/>
						<path fill="#3E3821" d="M314.2,239c-0.1,0.3-0.3,0.8-0.6,0.8c-1.8-0.4-1.9,0.8-2,2.1c-0.1,1.1-0.7,1.2-1.6,0.9
							c0.1-1.4,0.2-2.8,0.3-4.1c0,0,0.1-0.1,0.1-0.1c1.1,0,2.2,0.1,3.3,0.1C313.9,238.7,314,238.8,314.2,239z"/>
						<path fill="#CEC69D" d="M426.2,464.6c1.1,0.3,2.4-0.2,3.3,0.8c-4.5-0.4-9-0.8-13.5-1.2c-0.7-0.3-1.5-0.4-2.2-0.3
							c-0.1-0.1-0.2-0.2-0.4-0.3c3-0.5,6,0.5,8.9,0.6C423.6,464.3,424.9,464.5,426.2,464.6z"/>
						<path fill="#CEC69D" d="M377.3,224.5c-0.1,0.8-0.5,1.1-1.3,1.1c-1.1-1.2-3.1-1.3-4.5-0.2c-0.7,0.6-1.4,2-2.3,1.7
							c-1-0.4,0.1-1.8-0.4-2.7l0.2-0.1c1.7,0.8,3.4-0.8,5.2-0.2C375.2,224.3,376.3,224.3,377.3,224.5z"/>
						<path fill="#F5F0BC" d="M282.4,246.7c0.2-1.1,0-1.7-1.4-2c-2-0.4-4-0.7-6-0.9c1.5-0.5,3-0.4,4.5-0.2c0.7,0.1,1.2,0.4,2,0.1
							c1-0.4,1.9,0.4,2.5,1.1c0.3,0.4,0.5,0.9,0.3,1.4C283.9,247,283.1,246.8,282.4,246.7z"/>
						<path fill="#E9E4B6" d="M421,325.9c-0.5,4.8-1,9.6-1.5,14.4c-0.3,1.8,0,3.7-0.6,5.5c-0.1,0.1-0.2,0.2-0.4,0.4
							c0.5-7.6,1.4-15.3,2.4-22.9C421.4,324.2,420.9,325.1,421,325.9z"/>
						<path fill="#E9E4B6" d="M444.6,253.6c-0.7,1.7-1.2-0.1-1.9-0.1c-0.3,0-0.6-0.4-0.9-0.2c-0.4,0.3-0.2,0.6,0.1,0.9
							c1,0.9-1.3,1.3-0.3,2.2c-0.6,0-1.3,0-1.9,0c0.1-1.3,0.2-2.6,0.2-3.8C441.7,252.4,443.3,252.2,444.6,253.6z"/>
						<path fill="#B7AF8B" d="M317.2,552.1c1.5-0.6,2.9,0.1,4.3,0.2c0.6,0,0.9,0.4,0.8,1c-0.1,0.5-0.4,1.1-1,0.9
							c-1.4-0.6-2.9,0.3-4.2-0.7C317.1,553.1,317.1,552.6,317.2,552.1z"/>
						<path fill="#888158" d="M210.6,587.1c-2.3-0.2-4.5-0.7-6.7-1.5c0.4-1.8,2-0.1,2.7-0.9c0.7,0.9,1.7,0.6,2.6,0.6
							c1,0,1.5,0.7,1.6,1.7C210.8,587,210.7,587,210.6,587.1z"/>
						<path fill="#888158" d="M383.7,566.9c-1.6,0.3-3-0.7-4.5-0.6c-0.5,0-0.7-0.4-0.7-0.8c0-0.6,0.4-0.6,0.9-0.6
							c1,0.1,1.9,0.3,2.9,0.1c0.7,0.2,1.4,0.3,2,0.5c0,0.3,0,0.6,0,0.8C384.2,566.6,384,566.8,383.7,566.9z"/>
						<path fill="#B7AF8B" d="M259.5,507c4.1,0.1,8.2,0.7,12.3,1.3c-1.4,0.7-2.8,0.1-4.1-0.1C264.9,507.9,262.1,508.1,259.5,507z"/>
						<path fill="#1D190E" d="M363.8,254.7c0,0.9-0.7,1-1.3,1.1c-0.8,0.4-0.6,1.1-0.4,1.7c-0.1,1-0.4,1.6-1.5,1.1
							c-0.2-1.1,0.8-2.4-0.6-3.5c-0.7-0.5,0.2-0.8,0.6-0.7c1,0,2,0.1,2.9,0.2C363.5,254.7,363.6,254.7,363.8,254.7z"/>
						<path fill="#888158" d="M221.4,582.8c1-0.4,2.1,0.7,3.2-0.2c0.5-0.4,1.1,0.4,1.1,1.1c0,0.7-0.4,1-1.1,0.9
							c-1.1-0.1-2.2-0.2-3.3-0.3C221.3,583.8,221.4,583.3,221.4,582.8z"/>
						<path fill="#E9E4B6" d="M327,219.5c-1.2,0.9-3.3,1.1-2.8,3.3c-0.6-0.1-1.2-0.1-2.1-0.2c0.4-1.1,1.4-2.1-0.7-3.3
							C323.8,219.4,325.4,219.5,327,219.5z"/>
						<path fill="#B7AF8B" d="M288.5,441.6c-0.4,1.2-0.1,2.6-0.7,3.8c0.4-6.8,1.2-13.6,2.2-20.4l0.2,0l0.1,0.1
							C289.7,430.6,289.1,436.1,288.5,441.6z"/>
						<path fill="#E9E4B6" d="M364.6,602.4c0,0.7-0.6,0.8-1,1.2c-1-0.8-2.1-1.3-3.3-1.2c-0.7,0-1.6,0-1.6-1.1c2-0.1,4-0.2,5.9,0.7
							C364.6,602.1,364.6,602.2,364.6,602.4z"/>
						<path fill="#888158" d="M311.9,581.6c3.3-0.1,6.5,0.4,9.7,0.8c1.8,0.2,2.7-0.1,2.7-2c0-0.8,0.5-1.6,0.8-2.4
							c0.3,1.4-0.3,2.6-0.4,4c0,0.8-0.6,1.1-1.3,1C319.5,582.5,315.7,582.4,311.9,581.6z"/>
						<path fill="#888158" d="M371.6,565.8c0-0.6,0.1-1.1,0.1-1.7c1.3,0.1,2.5,0.3,3.8,0.4c0.5,0.1,1.2-0.1,1.3,0.7
							c0.1,0.7-0.3,1-1,1.1c-0.3-0.1-0.6-0.1-0.8-0.2C373.8,566.1,372.7,565.9,371.6,565.8z"/>
						<path fill="#CEC69D" d="M235.1,272.2c-0.9-0.1-1.7-0.2-2.6-0.3c-4.3-0.7-8.7-1.1-13.1-1.4c-0.6-0.1-1.1-0.1-1.7-0.2
							c-0.3-0.1-0.5-0.2-0.8-0.3c0.4-0.3,0.8-0.3,1.2-0.2c5.9,0.6,11.8,1.3,17.8,1.9c0.3,0,0.6,0.3,0.9,0.4l0.1,0.1l-0.1,0.1
							C236.2,272.3,235.7,272.3,235.1,272.2z"/>
						<path fill="#B7AF8B" d="M190.3,581.1c-1.3-0.1-2.5-0.3-3.8-0.4c-0.5-0.1-0.8-0.3-0.8-0.8c0-0.5,0.3-1.3,0.7-1.1
							c1.3,0.4,3.1-0.4,3.9,1.5C190.3,580.5,190.3,580.8,190.3,581.1z"/>
						<path fill="#E9E4B6" d="M312.9,588.9c-2.3,0.1-4.6-0.1-6.7-0.9c0.1-0.6,0.5-0.8,1.1-0.7c1.6,0.2,3.2,0.3,4.8,0.5
							C312.6,587.8,312.9,588.2,312.9,588.9z"/>
						<path fill="#B7AF8B" d="M413.4,602.2c-0.4-0.1-0.7-0.1-1.1-0.2c0-0.4,0.1-0.8,0.1-1.3c0-0.2,0.1-0.3,0.1-0.5
							c0.1-0.2,0.3-0.4,0.4-0.6c1-0.5,2.1-0.4,3.1-0.2c0.4,0.1,0.8,0.3,1,0.7c0.1,0.4-0.1,0.7-0.4,0.9c-0.5,0.2-1.1,0.5-1.6,0.3
							C413.9,600.7,413.9,600.7,413.4,602.2z"/>
						<path fill="#F5F0BC" d="M290.3,425l-0.1-0.1l-0.2,0c0.2-1.8,0.5-3.6,0.7-5.4c1,0.9,2,1.9,0.8,3.4
							C290.9,423.6,291.7,424.9,290.3,425z"/>
						<path fill="#888158" d="M219.7,543.3c0.1-0.6,0.1-1.1,0.2-1.7c0.1-0.1,0.3-0.2,0.4-0.2c1.3,0.1,3.2,0.5,3.1,1.5
							c-0.2,1.6-1.8,0.3-2.8,0.4C220.3,543.4,220,543.3,219.7,543.3z"/>
						<path fill="#E9E4B6" d="M322.4,240c-0.8,1.4-1,2.7-0.1,4.1c-0.9-0.1-1.9-0.2-2.8-0.3c0.9-1.3,1.3-2.7,0.4-4.1
							c0.2-0.1,0.4-0.3,0.5-0.4C321.1,239.5,321.7,239.7,322.4,240z"/>
						<path fill="#32613E" d="M381.1,584.3c0.1,0.9-0.3,1.2-1.2,1.1c-1.2-0.1-1-0.8-1-1.6c0-0.9,0.4-1.2,1.3-1.2
							C381.3,582.7,381.1,583.5,381.1,584.3z"/>
						<path fill="#F5F0BC" d="M438.9,357.9c0-0.6,0.3-1.2-0.2-1.7c0.1-0.6,0.1-1.1,0.2-1.7c0.6-0.4,0.4-1.1,0.6-1.6
							c1.2,0.1,2.3,0.4,1,1.8c-0.5,0.6-0.5,1-0.2,1.7C441.2,358,440.2,358,438.9,357.9z"/>
						<path fill="#F5F0BC" d="M214,279.2c-0.1,3.1-0.4,6.2-1,9.3c-0.6-0.2-0.6-0.7-0.5-1.2c0.3-2.3,0.5-4.7,0.8-7
							C213.2,279.8,213.3,279.3,214,279.2z"/>
						<path fill="#E9E4B6" d="M262.7,566.6c0.1-0.1,0.1-0.3,0.2-0.4c0.6,0.1,0.9,0.4,0.9,1c-0.3,2.5-0.5,5.1-0.8,7.6
							c-0.1,0.5-0.4,0.7-0.9,0.8c0-0.9,0.1-1.8,0.1-2.7c0-0.5,0.1-1.1,0.1-1.6c0-0.1,0-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-0.9
							c0.1-0.1,0.1-0.3,0-0.4C262.7,568.6,262.9,567.6,262.7,566.6z"/>
						<path fill="#B7AF8B" d="M252.5,584.4c0-0.6,0.3-1.1,0.6-1.6c1.4,0.3,3-0.1,4.7,1.3C255.7,584.1,254.2,585.6,252.5,584.4z"/>
						<path fill="#888158" d="M322.3,572.9c-0.2,0-0.5-0.1-0.7-0.1c-2.7-1-5.7-0.6-8.5-1.3c3.9-0.1,7.7,0.7,11.5,1
							c0.9,0.1,1.2,0.7,1,1.5c-0.1,0.9,0,1.8-0.5,2.7c-0.6-1.1,0.4-2.4-0.3-3.5C324.1,572.7,323.2,572.6,322.3,572.9z"/>
						<path fill="#888158" d="M196.5,581.6c-0.9,0.2-1.8-0.4-2.8-0.2c-1.2,0.2-0.7-1-0.7-1.7c1.3-0.1,2.5,0.2,3.7,0.4
							C196.7,580.6,196.6,581.1,196.5,581.6z"/>
						<path fill="#B7AF8B" d="M381.6,603.7c-0.6,0-1-0.3-1.3-0.7c0.1-0.4,0.2-0.9,0.2-1.3c0.6,0,1.3,0,1.9,0.1
							c0.6,0.1,1.6-0.1,1.6,0.6c0,0.6-0.6,1.3-1.1,1.8C382.3,604.8,382.2,603.5,381.6,603.7z"/>
						<path fill="#F5F0BC" d="M421,322.5c0-2,0.2-4,0.7-5.9c0.4,0.1,0.9,0,0.9,0.6c-0.1,1.4-0.2,2.8-0.3,4.1
							C422.2,322.2,421.9,322.7,421,322.5z"/>
						<path fill="#CEC69D" d="M280.9,369.1c0-0.1,0-0.3,0-0.4c0.5,0,1.1,0,1.6,0c0.5,0.2,1,0.3,1.5,0.2c0.2,0,0.4,0,0.6,0.1
							c0.2,0,0.4,0,0.6,0.1c2.4,0.3,4.8,0.2,7.1,0.9c-0.7,0.7-1.6,0.3-2.4,0.2C286.9,369.8,283.9,369.5,280.9,369.1z"/>
						<path fill="#CEC69D" d="M406.6,605.8c-0.1-0.5-0.3-1.1-0.4-1.6c1.1-0.3,2.1-0.2,3.1,0.4c-0.2,0.6-0.3,1.1-0.5,1.7
							c-0.6,0-1,0.3-1,0.9c-0.1,0.1-0.3,0.2-0.4,0.4C407,607,406.5,606.6,406.6,605.8z"/>
						<path fill="#888158" d="M200.3,539.3c0.1,0.5,0.2,1.1,0.2,1.6c-0.2,0.1-0.4,0.1-0.6,0.2c-0.8,0.4-1.5,0.4-2-0.5
							c-0.3-0.5-0.2-1.2,0.2-1.5C198.9,538.4,199.7,538.4,200.3,539.3z"/>
						<path fill="#F5F0BC" d="M274.2,243.7c-1.5-0.2-3.1-0.4-4.6-0.5c0.4-1.6,1.8-1.3,2.9-1.3C273.5,241.8,274.1,242.6,274.2,243.7z
							"/>
						<path fill="#CEC69D" d="M414,606.1c-0.1,0.6-0.5,0.8-1,0.6c0.3-1.5-1.9-0.6-1.8-1.9c0.4-0.2,0.7-0.5,1.1-0.7
							c0.2,0,0.4,0,0.7,0c0.5,0.8,1.1,0.8,1.8,0.2c0.4-0.1,0.8-0.2,1.3-0.2c0,0.4-0.1,0.9-0.1,1.3
							C415.1,605.6,414.3,605.3,414,606.1z"/>
						<path fill="#E9E4B6" d="M348.7,563.4c1.9,0.2,3.7,0.5,5.6,0.6c0.8,0.1,1.5,0.3,1.6,1.2c-2.3-0.4-4.5-0.7-6.8-1.1
							C348.6,564.1,348.7,563.7,348.7,563.4z"/>
						<path fill="#888158" d="M206.2,349.6c0.5,2.4-0.3,4.7-0.4,7.1c0,1.2-0.2,2.4-0.3,3.6c-0.8-0.6-0.1-1.5-0.3-2.2
							c0.1-0.1,0.1-0.3,0-0.4c0-0.3,0-0.6,0.1-0.9c0.6-1.8-0.1-3.7,0.3-5.6C205.7,350.6,205.4,349.8,206.2,349.6z"/>
						<path fill="#888158" d="M252.1,546.8c-0.7-0.3-1.8,0.3-2.2-0.6c-0.2-0.5-0.2-1.4,0.5-1.7c0.8-0.3,1.7-0.4,2,0.8
							C252.3,545.8,252.2,546.3,252.1,546.8z M251.3,545.9c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.2
							C251.2,546,251.3,545.9,251.3,545.9z"/>
						<path fill="#B7AF8B" d="M326.5,243.8c0.1-1.1,0.2-2.3,0.3-3.4c0.8,0.1,1.5,0.3,2.3,0.4c-0.8,0.9-0.9,2-0.9,3.1
							C327.7,243.8,327.1,243.8,326.5,243.8z"/>
						<path fill="#E9E4B6" d="M289.7,234.5c-1.9,0.2-3.7-0.8-5.6,0c-0.1-0.2-0.2-0.3-0.4-0.5c0.4-0.8,1-1.5,2-1
							c1,0.5,2.1,0.6,3.3,0.6C289.8,233.6,289.8,233.9,289.7,234.5z"/>
						<path fill="#B7AF8B" d="M209.9,581.6c1.4,0.3,3.4-0.4,3.7,2c-0.5,0-1-0.2-1.3,0.2c-1-0.2-1.8-0.5-2.5-1.2
							C209.8,582.2,209.8,581.9,209.9,581.6z"/>
						<path fill="#888158" d="M409.1,603.3c-0.8,0-1.5-0.2-2.3-0.1c-0.9,0.1-1-0.4-1-0.9c0-0.6,0.5-1,1-0.8c0.8,0.3,1.8-0.2,2.6,0.5
							C409.2,602.5,409.2,602.9,409.1,603.3z"/>
						<path fill="#F5F0BC" d="M338,232.2c2.1,0.2,4.2,0.4,6.4,0.6c0.3,0.3,0.5,0.6,0.8,1l0,0c-0.3,0-0.5-0.1-0.8-0.1
							c-2.1-0.2-4.3-0.5-6.4-0.7c0-0.1,0-0.3,0.1-0.4C338,232.4,338,232.3,338,232.2z"/>
						<path fill="#888158" d="M317.2,552.1c0,0.5,0,0.9-0.1,1.4c-0.9,0.6-1.7,0.6-2.4-0.3c0-0.4,0-0.8,0.1-1.2
							C315.6,551.4,316.4,551,317.2,552.1z"/>
						<path fill="#B7AF8B" d="M244.2,583.3c-0.9-0.1-1.9-1-3,0.3c0.9-2.7,1.3-2.9,3.6-1.6C244.5,582.4,244.4,582.9,244.2,583.3z"/>
						<path fill="#CEC69D" d="M415.5,242c1.3,0.6,2.6,1.7,3.6-0.3c0.2,0.3,0.3,0.6,0.5,0.9c-0.9,1.5-2.3,1.6-3.7,1.2
							C415,243.6,415.4,242.7,415.5,242z"/>
						<path fill="#E9E4B6" d="M374.1,248.9c-0.1-1.5,0.1-2.9,0.5-4.3c0.6,0.1,1.1,0.3,1.2,0.9c-0.1,1.2-0.1,2.3-0.5,3.4
							C374.9,249,374.5,248.9,374.1,248.9z"/>
						<path fill="#E9E4B6" d="M421.9,254.7c0.2,0.1,0.5,0.2,0.6,0.2c1.5-1.3,2.6,0.2,3.9,0.6C424.8,256.5,422.5,256.1,421.9,254.7z"
							/>
						<path fill="#B7AF8B" d="M196.5,581.6c0.1-0.5,0.2-1,0.2-1.5c0.6,0,1.1-0.2,1.4-0.7c0.3,0,0.5,0,0.8,0.1l0,0
							C199.6,581.9,199.3,582.2,196.5,581.6z"/>
						<path fill="#B7AF8B" d="M379.1,558.8c0.2-0.2,0.3-0.4,0.5-0.5c2.3,1,2.4,1.4,1,2.5c-0.6-0.1-1.2,0-1.6-0.5
							C379,559.8,379.1,559.3,379.1,558.8z"/>
						<path fill="#CEC69D" d="M221.4,582.8c0,0.5,0,1-0.1,1.6c-0.9-0.1-1.8-0.2-2.7-0.2c0.1-0.5,0.1-1.1,0.2-1.6
							C219.7,582.2,220.6,581.8,221.4,582.8z"/>
						<path fill="#CEC69D" d="M420.9,568.5c0-0.2,0-0.5,0.1-0.7c0.4-0.1,0.9,0.6,1.3-0.1c0.2-0.4,0.2-1.2,0.9-0.7
							c0.7,0.4,0.4,1.1,0.3,1.7c-0.1,0.7-0.5,0.9-1.1,0.9C421.5,569.6,421.4,568.9,420.9,568.5z"/>
						<path fill="#B7AF8B" d="M200.5,585.6c-0.3,0-0.7,0-0.9,0.3c-1.5-0.1-2.6-0.4-1.4-2.2c1-0.2,1.7,0.5,2.5,0.7
							C200.6,584.8,200.5,585.2,200.5,585.6z"/>
						<path fill="#888158" d="M412.8,599.6c-0.1,0.2-0.3,0.4-0.4,0.6c-0.2,0.1-0.4,0.2-0.6,0.4c-0.1,0-0.3,0-0.4,0
							c-0.2-0.4,0-1-0.6-1.1c0,0-0.2,0.6-0.2,0.9c-1.3-0.2-0.8-1.1-0.5-1.7c0.3-0.5,1-0.6,1.5-0.3
							C412.1,598.7,412.5,599.2,412.8,599.6z"/>
						<path fill="#888158" d="M259.4,588.4c0.2-0.5,0.4-1,0.6-1.5c0.3-0.8,0.8-0.6,1.2-0.1c0.4,0.4,0.7,0.9,0.5,1.5
							c-0.2,0.5-0.6,0.4-1,0.3C260.2,588.5,259.8,588.5,259.4,588.4z"/>
						<path fill="#888158" d="M260.2,551.1c-1.3,0.2-2.1,1.2-3,0.3c-0.4-0.4-0.1-1.2,0.3-1.5C258.5,549.3,259,550.6,260.2,551.1z"/>
						<path fill="#F5F0BC" d="M405.3,240.2c0-0.2,0.1-0.3,0.1-0.5c3.4,0.4,6.8,0.7,10.2,1.1l0,0c0,0.2,0,0.4,0,0.6l0,0
							C412.1,241,408.7,240.6,405.3,240.2z"/>
						<path fill="#B7AF8B" d="M305.5,581c0.1-0.4,0.1-0.9,0.6-1.2c0.8,0.9,1.9,0.5,2.8,0.7c0,0.3,0,0.7,0,1
							C307.7,581.9,306.6,581.7,305.5,581z"/>
						<path fill="#32613E" d="M313.1,571.6c2.8,0.7,5.7,0.3,8.5,1.3c-2.3-0.2-4.6-0.5-6.9-0.7c-0.7-0.1-1.5-0.4-2.2,0.2
							c-0.1,0-0.3-0.1-0.4-0.1c0-0.3,0.1-0.5,0.1-0.8C312.5,571.5,312.8,571.5,313.1,571.6z"/>
						<path fill="#888158" d="M190.3,581.1c0-0.3,0-0.6,0.1-0.9c0.2-1.4,1-1.1,1.9-0.6c0.3,1.3-0.9,1.6-1.6,2.3
							C190.5,581.6,190.4,581.3,190.3,581.1z"/>
						<path fill="#E9E4B6" d="M257.7,575.9c0.7,0,1.5,0.3,2.2-0.1c0.3,0,0.6,0,0.9,0c0.1,0.3,0.4,0.4,0.8,0.5
							c0.1,0.2,0.1,0.4,0.2,0.5C260.2,577,258.7,577.6,257.7,575.9z"/>
						<path fill="#888158" d="M199.8,575.6c1,0.6,0.7,1.7,1.1,2.6c-0.9-0.2-1.6-0.3-2.2-0.4C197.9,576.5,199.5,576.3,199.8,575.6z"
							/>
						<path fill="#888158" d="M305.4,570.8c-1.1,2.4-0.4,5.1-1.5,7.4c0.2-2,0.4-4.1,0.6-6.1C304.5,571.6,304.4,570.8,305.4,570.8z"
							/>
						<path fill="#E9E4B6" d="M447.2,255.8c0.4-1.5-1.2-1.4-1.7-2.3c1.2-0.7,2.1-0.3,2.6,0.8C448.5,255.1,448.4,255.9,447.2,255.8z"
							/>
						<path fill="#888158" d="M439.2,251.2c-0.5,0.1-0.9,0.4-1.4,0.4c-0.7-0.1-1.5-0.5-1.3-1.3c0.3-0.9,0.9,0,1.4,0
							c0.5,0,1,0.1,1.5,0.1C439.3,250.6,439.2,250.9,439.2,251.2z"/>
						<path fill="#F5F0BC" d="M439.9,348.6c1.5,0.7,0.3,2.3,1.1,3.3c-0.4,0.4-0.9,0.1-1.4,0.1C439.7,350.8,439.8,349.7,439.9,348.6
							L439.9,348.6z"/>
						<path fill="#F5F0BC" d="M323.6,226.7c-0.2,1,0.4,1.7,0.7,2.5c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.1c-1.6-0.4-2-1.5-1.2-3.3
							c0.1,0,0.2-0.1,0.3-0.2c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0C323.5,226.4,323.5,226.5,323.6,226.7z"/>
						<path fill="#B7AF8B" d="M218.8,582.5c-0.1,0.5-0.1,1.1-0.2,1.6c-0.4,0-0.8-0.1-1.2-0.1c-0.2-0.6-0.9-0.8-1.4-1.2
							c0.5-0.3,1.3-0.2,1.5-0.9C218.1,581.8,218.4,582.2,218.8,582.5z"/>
						<path fill="#CEC69D" d="M382.9,225.7c-1.3-0.3-2.6-0.6-4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4C380.1,224.6,381.7,224.2,382.9,225.7z"
							/>
						<path fill="#E9E4B6" d="M371.6,565.8c1.1,0.1,2.2,0.3,3.3,0.4c-1.3,1.5-2.6,0.9-3.9,0C371.2,566,371.4,565.9,371.6,565.8z"/>
						<path fill="#888158" d="M254.5,545.8c-0.1,0.4-0.1,0.8-0.2,1.3c-0.6,0.6-1.1,0.4-1.6-0.1c0.1-0.5,0.1-0.9,0.2-1.4
							C253.5,545.3,254,545.3,254.5,545.8z"/>
						<path fill="#CEC69D" d="M423.4,606.1c-0.6-0.1-1.2-0.1-1.8-0.2c0.1-0.4,0.2-0.8,0.2-1.2c0.7-0.5,1.4-1.3,1.6,0.3
							C423.5,605.3,423.4,605.7,423.4,606.1z"/>
						<path fill="#F5F0BC" d="M415.9,254.1c1,0.9,2.2,0.3,3.3,0.4c-0.1,0.6-0.2,1.5-1,1.1C417.3,255.3,415.9,255.7,415.9,254.1z"/>
						<path fill="#F5F0BC" d="M409.9,253.7c1.1,0.1,2.3,0.2,3.4,0.3C412,255.3,410.9,255,409.9,253.7z"/>
						<path fill="#888158" d="M214,361.4c-0.6,0-1.2,0-1.8,0.1c-1.1-0.1-2.3-0.2-3.4-0.4c-0.6-0.1-1.1-0.3-1.7-0.4
							C209.4,360.6,211.7,360.8,214,361.4z"/>
						<path fill="#CCDEC7" d="M212,561.2c-2,0-3.9-0.2-5.8-0.6C208.2,560,210.2,560.2,212,561.2z"/>
						<path fill="#E9E4B6" d="M313.7,238.6c-1.1,0-2.2-0.1-3.3-0.1C312,236.9,312.9,236.9,313.7,238.6z"/>
						<path fill="#CEC69D" d="M417.2,358.1c0.1,0,0.3,0,0.4,0.1c-0.7,1.3,0.9,3.1-0.8,4.2C416.6,360.9,416.8,359.5,417.2,358.1z"/>
						<path fill="#B7AF8B" d="M335.8,241.2c-0.7-0.5-2.5,0.8-2.1-1.4C334.5,240.1,335.6,240,335.8,241.2z"/>
						<path fill="#CEC69D" d="M293.6,370c0.4,0,0.8,0,1.2,0c0.2,0,0.4,0,0.6,0c0.6,0.1,1.2,0.1,1.7-0.4c0.6,0.8,0,1.1-0.6,1.1
							C295.5,370.7,294.4,371,293.6,370z"/>
						<path fill="#E9E4B6" d="M418,249.7c-0.6,0.2-1.1,0.3-1.7,0.5c0-0.2,0-0.4,0-0.6c0.5-0.5,0.9-0.9,1.4-1.4
							C418.3,248.6,417.9,249.2,418,249.7z"/>
						<path fill="#3E3821" d="M244.2,583.3c0.2-0.5,0.4-0.9,0.6-1.4c0.3,0.1,0.6,0.1,0.9,0.2c0,0.3,0.1,0.7,0.1,1l-0.1,0
							c-0.3,0-0.6,0.1-0.7,0.4C244.6,583.5,244.4,583.4,244.2,583.3z"/>
						<path fill="#B7AF8B" d="M331.9,239.6c0.1,2.2-1.4,0.8-2.2,1C330.2,239.7,331.2,239.8,331.9,239.6z"/>
						<path fill="#E9E4B6" d="M414.3,562.8c-0.9,0.7-1.7,0.3-2.5-0.1C412.7,561,413.5,562.4,414.3,562.8z"/>
						<path fill="#CEC69D" d="M301.9,323.4c0.1-0.1,0.2-0.3,0.3-0.4c0.4,1.1,1.2,2.1-0.3,3c0-0.4-0.1-0.9-0.1-1.3
							C301.8,324.3,301.8,323.8,301.9,323.4z"/>
						<path fill="#CEC69D" d="M416,368.7c0-1.7,0.1-3.4,0.5-5.1C416.9,365.4,416.7,367.1,416,368.7z"/>
						<path fill="#E9E4B6" d="M418.9,610.8c0.1-0.6,0.2-1.2,1-1.2c0.5,0.4,0.6,1,0.1,1.5C419.6,611.4,419.3,610.9,418.9,610.8z"/>
						<path fill="#CEC69D" d="M318.4,239c-0.9,0.6-1.7,0.1-2.5-0.1C316.7,238.3,317.6,238.3,318.4,239z"/>
						<path fill="#B7AF8B" d="M273.6,508.5c1.1-0.2,2.3-0.1,3.4,0.3C275.8,508.8,274.6,509.3,273.6,508.5z"/>
						<path fill="#CEC69D" d="M366.8,223.7c0-0.1,0.1-0.3,0.1-0.4c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0.1,1.3,0.1c0.2,0.3,0.3,0.5,0.5,0.8
							l-0.2,0.1C368.2,224.1,367.5,223.9,366.8,223.7z"/>
						<path fill="#E9E4B6" d="M207.1,360.7c0.6,0.1,1.1,0.3,1.7,0.4c-1.4,0.4-2.8-0.1-4.2-0.2c0.3-0.4,0.7-0.4,1.1-0.4
							C206.1,360.6,206.6,360.7,207.1,360.7z"/>
						<path fill="#E9E4B6" d="M368.6,565.5c0.8-0.5,1.4-0.4,1.7,0.6C369.5,566.5,368.8,566.5,368.6,565.5z"/>
						<path fill="#F5F0BC" d="M283.4,221.9c-0.1,1.3-0.2,2.6-0.2,3.8C281.9,224.4,282.8,223.2,283.4,221.9z"/>
						<path fill="#CEC69D" d="M424.3,563.6c-0.4,0.5,0,1,0,1.5c-0.5,0.1-0.9,0.1-1.4,0.2c0.1-0.3,0.1-0.7,0.2-1
							C423.3,563.7,423.6,563.3,424.3,563.6z"/>
						<path fill="#E9E4B6" d="M365.6,565.4c0.6-0.2,1.2-0.2,1.7,0.1C366.6,566.5,366.1,566.4,365.6,565.4z"/>
						<path fill="#F5F0BC" d="M224.5,209c0.2,0,0.5-0.1,0.7-0.1c0.3,0,0.6,0.1,0.5,0.5c-0.1,0.2-0.4,0.2-0.7,0.2
							C224.8,209.6,224.5,209.5,224.5,209z"/>
						<path fill="#E9E4B6" d="M439.9,348.6c-0.1,1.1-0.2,2.3-0.3,3.4l-0.2,0l-0.2,0c0-1.1,0-2.2,0-3.3
							C439.5,348.6,439.7,348.6,439.9,348.6z"/>
						<path fill="#E9E4B6" d="M330.8,592.6c0.3-0.2,0.7-0.2,1,0c-0.2,0.9-1.2,0.4-1.6,1.2C329.8,592.9,330.5,592.8,330.8,592.6z"/>
						<path fill="#888158" d="M263.4,551c-0.1,0.6-0.4,0.7-0.8,0.7c-0.4,0-0.9-0.1-0.9-0.6c0-0.6,0.5-0.5,0.9-0.5
							C263.1,550.6,263.4,550.8,263.4,551z"/>
						<path fill="#E9E4B6" d="M252.6,575.4c0.6,0.1,1.1,0.1,1.7,0.2C253.6,576.7,253.1,576.6,252.6,575.4z"/>
						<path fill="#CEC69D" d="M320.4,239.3c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2-0.2-0.4-0.3-0.7-0.5C319.7,238,320,238.9,320.4,239.3z"/>
						<path fill="#B7AF8B" d="M281.2,507.8c-0.2-0.5-0.1-1,0.1-1.4C282.4,506.9,281.7,507.3,281.2,507.8z"/>
						<path fill="#CEC69D" d="M409.1,603.3c0.1-0.4,0.2-0.8,0.3-1.3c0.1,0,0.2,0,0.3,0.1c0.1,0.4,0.2,0.9,0.3,1.3
							C409.6,603.8,409.3,603.7,409.1,603.3z"/>
						<path fill="#B7AF8B" d="M379,600.5c0.3,0,0.5,0.1,0.8,0.1c0,0.3-0.1,0.7-0.1,1c-0.8,0.4-0.7-0.2-0.7-0.7
							C378.9,600.8,378.9,600.6,379,600.5z"/>
						<path fill="#CEC69D" d="M305.4,594.3c0,0.4-0.1,0.9-0.1,1.3c-0.8-0.2-0.8-0.7-0.6-1.3C304.9,594.3,305.2,594.3,305.4,594.3z"
							/>
						<path fill="#CEC69D" d="M412.7,609c0.6-0.3,1.1-0.1,1.5,0.5C413.5,609.9,413,609.9,412.7,609z"/>
						<path fill="#B7AF8B" d="M257,506.7c0.5-0.3,0.9-0.2,1.3,0.2C257.8,507.4,257.4,507.2,257,506.7z"/>
						<path fill="#888158" d="M208.2,334c-0.1,0-0.2,0-0.3-0.1c0.3-0.8,0-1.8,0.6-2.6C208.6,332.2,208.5,333.1,208.2,334z"/>
						<path fill="#E9E4B6" d="M349.6,555.4c-0.1,0.4-0.1,0.8-0.2,1.2c-0.4-0.1-0.8-0.2-0.8-0.7C348.8,555.3,349.2,555.4,349.6,555.4
							z"/>
						<path fill="#CEC69D" d="M262.2,572.9c0,0.9-0.1,1.8-0.1,2.7c-0.1,0-0.2,0-0.4,0.1c-0.1-0.1-0.1-0.1-0.2-0.2
							C261.9,574.6,261.5,573.6,262.2,572.9z"/>
						<path fill="#CEC69D" d="M216.1,269.9c-0.3,0-0.6,0.1-0.9,0.1c0-0.4,0-1,0.4-1C216.2,268.9,216,269.6,216.1,269.9z"/>
						<path fill="#CEC69D" d="M410.7,600.5c0.1-0.3,0.2-0.9,0.2-0.9c0.6,0.1,0.4,0.7,0.6,1.1C411.1,601,410.9,600.9,410.7,600.5z"/>
						<path fill="#E9E4B6" d="M368.5,223.4c-0.4,0-0.8-0.1-1.3-0.1c0.2-0.3,0.3-0.5,0.5-0.8c0.3,0,0.6,0.1,0.8,0.1
							C368.6,222.9,368.5,223.1,368.5,223.4z"/>
						<path fill="#B7AF8B" d="M277.8,508.9c0.7-0.2,1.4-0.1,2.1,0.2C279.2,509.3,278.4,509.2,277.8,508.9z"/>
						<path fill="#888158" d="M208.6,327.5c0.6,0.8,0.3,1.5,0,2.3C208.2,329,208.6,328.2,208.6,327.5z"/>
						<path fill="#CEC69D" d="M374,243.4c-0.7-0.1-1.4-0.2-2.1-0.3c0.7-0.6,1.4-0.2,2.1-0.2C374.1,243.1,374,243.3,374,243.4z"/>
						<path fill="#F5F0BC" d="M283.8,234c0.1,0.2,0.2,0.3,0.4,0.5c0,0.3,0,0.6,0,0.8c-0.3,0-0.6-0.1-0.9-0.1
							C283.3,234.7,283.3,234.3,283.8,234z"/>
						<path fill="#E9E4B6" d="M419.8,567.3c-0.3,0-0.5-0.1-0.8-0.1c0.1-0.2,0.3-0.6,0.4-0.6C419.9,566.5,419.8,567,419.8,567.3z"/>
						<path fill="#E9E4B6" d="M384.4,566.4c0-0.3,0-0.6,0-0.8c0.3,0.1,0.7,0.1,0.6,0.5C385,566.2,384.6,566.3,384.4,566.4z"/>
						<path fill="#E9E4B6" d="M416.6,569c0.3,0,0.5,0,0.8,0c-0.1,0.3,0,0.7-0.5,0.6C416.8,569.6,416.7,569.3,416.6,569z"/>
						<path fill="#E9E4B6" d="M344.3,233.7c0.3,0,0.5,0.1,0.8,0.1c-0.1,0.3-0.1,0.7-0.6,0.6C344.4,234.3,344.4,233.9,344.3,233.7z"
							/>
						<path fill="#CEC69D" d="M315,243.4c0,0.3-0.1,0.5-0.1,0.8c-0.2-0.1-0.6-0.2-0.6-0.4C314.3,243.4,314.7,243.4,315,243.4z"/>
						<path fill="#CEC69D" d="M261.5,576.3c-0.3,0-0.6-0.1-0.8-0.5c0.2-0.4,0.5-0.4,0.9-0.4c0,0.1,0.1,0.1,0.2,0.2
							C261.6,575.8,261.6,576.1,261.5,576.3z"/>
						<path fill="#3E3821" d="M216.9,270.1c0.3,0.1,0.5,0.2,0.8,0.3c-0.6,0.3-1.2,0.3-1.7-0.3C216.3,270.1,216.6,270.1,216.9,270.1z
							"/>
						<path fill="#888158" d="M244.9,583.5c0.1-0.3,0.4-0.5,0.7-0.4C245.7,583.8,245.4,584,244.9,583.5z"/>
						<path fill="#B7AF8B" d="M414.3,602.6c-0.6,0.6-0.8,0.2-0.9-0.4C413.8,602.2,414.1,602.3,414.3,602.6z"/>
						<path fill="#E9E4B6" d="M356.4,599c0,0.1,0,0.3-0.1,0.4c-0.3-0.1-0.8-0.2-0.4-0.6C356,598.5,356.3,598.8,356.4,599z"/>
						<path fill="#CEC69D" d="M405.8,604.1c-0.3,0.3-0.6,0.7-1,0.1C405,603.8,405.4,603.8,405.8,604.1z"/>
						<path fill="#E9E4B6" d="M356.1,600.2c0,0.2,0,0.4,0.1,0.6c-0.3,0.2-0.6,0.3-0.6-0.1C355.5,600.3,355.8,600.2,356.1,600.2z"/>
						<path fill="#E9E4B6" d="M439.5,352.8c-0.1,0.6,0,1.2-0.6,1.6c0.1-0.6,0.1-1.1,0.2-1.7C439.2,352.8,439.4,352.8,439.5,352.8z"
							/>
						<path fill="#B7AF8B" d="M213.1,540.9c-0.5,0.7-0.8,0.6-1.1-0.2C212.4,540.7,212.8,540.7,213.1,540.9z"/>
						<path fill="#B7AF8B" d="M252.9,545.6c-0.1,0.5-0.1,0.9-0.2,1.4c-0.2-0.1-0.4-0.1-0.6-0.2c0.1-0.5,0.2-1,0.2-1.5
							C252.6,545.4,252.8,545.5,252.9,545.6z"/>
						<path fill="#3E3821" d="M305,279.6c0.4,0,0.9-0.1,1.3-0.1c0,0.1,0.1,0.3,0.1,0.4C305.9,280.1,305.4,280.1,305,279.6z"/>
						<path fill="#E9E4B6" d="M355.8,565.2c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0.3,0.3,0.7-0.2,0.8C355.8,565.8,355.9,565.4,355.8,565.2z"
							/>
						<path fill="#CEC69D" d="M304.3,303.5c0.1-0.3,0.1-0.6,0.2-0.9C305.2,303.1,304.7,303.3,304.3,303.5z"/>
						<path fill="#E9E4B6" d="M366.9,254.6c-0.3,0.1-0.6,0.2-0.9,0.3C366.2,254.5,366.5,254.5,366.9,254.6z"/>
						<path fill="#F5F0BC" d="M281.2,239.7C281.2,239.7,281.2,239.7,281.2,239.7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.1,0.3-0.1,0.2
							C281.5,239.9,281.4,239.8,281.2,239.7z"/>
						<path fill="#B7AF8B" d="M245.6,505.5c0.3,0,0.6,0.1,0.8,0.1C246.1,506,245.8,506,245.6,505.5z"/>
						<path fill="#E9E4B6" d="M417.4,569c0.4-0.4,0.7-0.4,1.1,0.1C418.1,569.1,417.8,569.1,417.4,569z"/>
						<path fill="#E9E4B6" d="M352.9,555.9c-0.5,0-0.8-0.2-1-0.6C352.3,555.2,352.8,555.3,352.9,555.9z"/>
						<path fill="#E9E4B6" d="M357.5,600.8c0.2,0.1,0.3,0.2,0.5,0.2c-0.1,0.1-0.2,0.2-0.4,0.3C357.6,601.1,357.5,600.9,357.5,600.8z
							"/>
						<path fill="#B7AF8B" d="M208.7,540.7l-0.2,0l-0.2,0c0.1-0.2,0.2-0.3,0.2-0.5C208.6,540.3,208.7,540.5,208.7,540.7z"/>
						<path fill="#B7AF8B" d="M253.2,506.4c0.1,0,0.3,0,0.4,0C253.4,506.6,253.3,506.6,253.2,506.4z"/>
						<path fill="#CEC69D" d="M323.1,226.3c-0.1,0.1-0.2,0.2-0.3,0.2C322.9,226.4,323,226.3,323.1,226.3z"/>
						<path fill="#E9E4B6" d="M337.9,232.5c0,0.1,0,0.3-0.1,0.4c0-0.1-0.1-0.2-0.1-0.3C337.8,232.6,337.9,232.6,337.9,232.5z"/>
						<path fill="#3E3821" d="M306.6,280.6c-0.2-0.2-0.3-0.4-0.1-0.6C306.7,280.2,306.7,280.4,306.6,280.6z"/>
						<path fill="#3E3821" d="M306.4,282.7c-0.3-0.2-0.2-0.3,0.1-0.4C306.4,282.5,306.4,282.6,306.4,282.7z"/>
						<path fill="#CEC69D" d="M305.1,296.8c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.2,0.1,0.3C305.2,296.7,305.1,296.7,305.1,296.8z"/>
						<path fill="#CEC69D" d="M304.6,300.6c0-0.1,0-0.3,0.1-0.4c0,0.1,0.1,0.2,0.1,0.3C304.8,300.5,304.7,300.5,304.6,300.6z"/>
						<path fill="#CEC69D" d="M299.2,351c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.2,0.1,0.3C299.4,350.9,299.3,351,299.2,351z"/>
						<path fill="#888158" d="M306.2,570.8l-0.1,0c0,0-0.1-0.1-0.1-0.1C306,570.6,306.1,570.6,306.2,570.8z"/>
						<path fill="#3E3821" d="M241,272.8c-0.2,0.2-0.3,0.2-0.4,0C240.7,272.8,240.9,272.8,241,272.8z"/>
						<path fill="#CEC69D" d="M455.8,468.1c0.2-0.1,0.3-0.2,0.5-0.4c0,0.1,0,0.3,0,0.4C456.1,468.2,455.9,468.2,455.8,468.1z"/>
						<path fill="#CEC69D" d="M214.8,271.6c0,0.2,0,0.3,0,0.5c0-0.1-0.1-0.2-0.1-0.3C214.6,271.7,214.7,271.6,214.8,271.6z"/>
						<path fill="#3E3821" d="M307.4,372.6c-0.2-0.1-0.4-0.2-0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.4C307.2,372.2,307.3,372.4,307.4,372.6z
							"/>
						<path fill="#E9E4B6" d="M207.8,577l-0.2,0l-0.2,0C207.6,576.7,207.7,576.7,207.8,577z"/>
						<path fill="#CEC69D" d="M411.9,600.6c0.2-0.1,0.4-0.2,0.6-0.4c0,0.2-0.1,0.3-0.1,0.5C412.2,600.7,412,600.6,411.9,600.6z"/>
						<path fill="#CEC69D" d="M412.1,602.3c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.1-0.2-0.2-0.3C411.9,602.3,412,602.3,412.1,602.3z"/>
						<path fill="#888158" d="M312.3,593.8c0.2-0.1,0.4-0.3,0.6-0.4C312.7,593.5,312.5,593.7,312.3,593.8z"/>
						<path fill="#CEC69D" d="M305.4,595.9c0.1,0,0.2,0,0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2C305.4,596.1,305.4,596,305.4,595.9z"/>
						<path fill="#CEC69D" d="M301.7,327.3c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.2,0.1,0.3C301.9,327.2,301.8,327.2,301.7,327.3z"/>
						<path fill="#1D190E" d="M303.8,223.3c0.3-1.6,0.7-3.2,0.9-4.8c0.3-1.9,1.6-1.1,2.6-1.1c1.3,0,1.2,1,1,1.8
							c-0.3,1.4-0.3,2.4,1.7,2.5c1.5,0.1,1.9-0.4,1.9-1.7c0-1.2,0-2.3,1.9-2c2,0.2,1.8,1.2,1.7,2.6c-0.3,2.2-0.6,4.5-0.7,6.8
							c-0.1,1.7-0.8,2-2.4,1.9c-1.8-0.1-1.5-1.2-1.2-2.2c0.4-1.7-0.1-2.4-2-2.5c-1.2,0-1.8,0.1-1.7,1.6c0.2,1.6-0.3,2.8-2.4,2.3
							c-0.9-0.2-1.6-0.2-1.4-1.5c0.1-0.8-0.1-1.6-0.2-2.5C303.7,224.1,303.7,223.7,303.8,223.3z"/>
						<path fill="#1D190E" d="M273.6,214.1c0,0.5-0.1,1-0.1,1.5c-0.3,0.2-0.3,0.4-0.1,0.6c-0.3,2.4-0.6,4.8-0.7,7.2
							c-0.1,1.5-1,1.5-2,1.4c-1-0.1-2-0.4-1.7-1.8c0.3-1.5,0.2-3,0.5-4.4c0.3-1.6-0.1-2.4-1.9-2.2c-0.4,0-0.8,0-1.3-0.1
							c-0.4-0.1-0.5,0-0.3,0.4c-0.2,2-0.4,3.9-0.6,5.9c0,0.3-0.1,0.6-0.1,0.9c-1,1-2.2,0.6-3.1,0.3c-1-0.4-0.4-1.5-0.3-2.3
							c0.2-2.4,0.5-4.8,0.7-7.2c0.1-1.2,0.4-1.8,1.8-1.6c3,0.4,6,0.7,9,1.1C273.5,213.9,273.5,214,273.6,214.1z"/>
						<path fill="#1D190E" d="M293.2,226.5c-1.4,1-2.7,1.1-3.8-0.4c0.6-0.2,0.4-0.8,0.5-1.2c1.5-1.3,1.6-3.3,2.6-4.9
							c2.5-4.2,2.5-4.2,5.9-3.3c0.7,3,1.3,6,2,9c0.2,0.7,1,1.8-0.1,2.1c-1,0.3-2.4,0.6-3.1-0.9C296.1,225.5,294.7,225.4,293.2,226.5
							z M296,220.4c-0.4,1-1.6,1.8-1,3.1c0.2,0.4,1.2,0.5,1.5,0.1C297.2,222.5,296.6,221.5,296,220.4z"/>
						<path fill="#F5EBB0" d="M298.5,216.7c-3.4-0.9-3.4-0.9-5.9,3.3c-1.4-1-1.2,0.8-1.2,1c-0.4,1.3-2.4,2.1-1.4,3.8
							c-0.1,0.4,0.1,1-0.5,1.2c-0.7-0.1-1.4-0.1-2.1-0.2c0.3-2.8,0.5-5.6,0.8-8.3c0.1-0.7,0.4-1.5-0.2-2.2c0.6-0.3-1.2-1.5,0.5-1.3
							c5.3,0.6,10.7,1.2,16,1.8c0.1,0,0.2,0.2,0.4,0.4c-1,0.7-2.4,0.6-3,1.8c-0.4,0-0.8-0.1-1.3-0.1
							C300.1,217.1,300.2,215.3,298.5,216.7z M288.6,224c-0.1,0.1-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1-0.2,0.2-0.3
							C288.8,224.1,288.7,224.1,288.6,224z"/>
						<path fill="#FBEFB2" d="M300.5,217.8c0.4,0,0.8,0.1,1.3,0.1c0.9,0,1.2,0.4,1.2,1.3c0,1.4-0.1,2.8,0.8,4c0,0.4-0.1,0.8-0.1,1.3
							c-2.2,0.1-3-0.5-2.6-2.7C301.3,220.4,300.1,219.2,300.5,217.8z"/>
						<path fill="#FBEFB2" d="M268.5,220.7c-0.5,0.8,0.6,3-1.2,2.8c-1.5-0.2-0.3-2-0.4-3.1c-0.1-0.8,0.2-1.7,0.3-2.5
							c0.1-0.5,0.3-1,0.9-0.9c0.6,0,0.8,0.5,0.8,1C268.7,218.8,268.6,219.7,268.5,220.7z"/>
						<path fill="#F5EBB0" d="M279.5,214.3c-0.4,0.1-0.8,0.2-1.2,0.3c-1.5-0.5-3.5,0.3-4.4-1.7C276.1,212.1,278.1,212.6,279.5,214.3
							z"/>
						<path fill="#FBEFB2" d="M312.8,217c-1.8,1-1.8,1-5.2-0.7C309.8,216.1,311.5,216.3,312.8,217z"/>
						<path fill="#CCDEC7" d="M293.2,226.5c1.5-1.1,2.9-1,4.1,0.4C296,226.7,294.6,226.6,293.2,226.5z"/>
						<path fill="#FBEFB2" d="M309.8,226.6c0.6,0.1,0.8,0.3,0.8,0.8c0,0.6-0.4,0.9-1,0.8c-0.5-0.1-0.7-0.4-0.6-0.9
							C309,226.8,309.3,226.6,309.8,226.6z"/>
						<path fill="#B7AF8B" d="M265.4,223.5c0-0.3,0.1-0.6,0.1-0.9C265.8,222.9,265.8,223.2,265.4,223.5z"/>
						<path fill="#888158" d="M273.6,214.1c-0.1-0.1-0.1-0.2-0.2-0.3C273.7,213.8,273.8,213.9,273.6,214.1z"/>
						<path fill="#888158" d="M273.4,216.3c-0.2-0.2-0.2-0.4,0.1-0.6C273.5,215.9,273.5,216.1,273.4,216.3z"/>
						<path fill="#B7AF8B" d="M266.1,216.7c-0.1-0.3,0-0.5,0.3-0.4C266.3,216.4,266.2,216.6,266.1,216.7z"/>
						<path fill="#1D190E" d="M366.8,223.7c0.7,0.2,1.4,0.4,2.1,0.6c0.5,0.9-0.6,2.3,0.4,2.7c0.8,0.3,1.5-1,2.3-1.7
							c1.4-1.1,3.4-1,4.5,0.2c-3.9,2.7-3.9,2.7-1.9,6.8c0.1,0.3,0.4,0.6,0.5,1c0.1,0.9,1.6,1.5,0.7,2.6c-0.5,0.6-3.6-0.5-4.1-1.3
							c-0.5-0.9-0.9-1.7-1.4-2.6c-0.2-0.3-0.4-0.6-0.8-0.5c-0.4,0.1-0.5,0.5-0.5,0.8c-0.1,0.4-0.1,0.8-0.1,1.3
							c0.1,1.8-1,1.5-2.2,1.5c-1.4,0-1.6-0.6-1.4-1.8c0.4-2.7,0.6-5.5,0.9-8.2C365.5,224,365.7,223.5,366.8,223.7z"/>
						<path fill="#1D190E" d="M355.1,222.5c0.1,0,0.3,0.1,0.4,0.1c3.4-0.3,4,1.9,4.4,4.5c0.3,1.8,0.9,3.6,1.3,5.4
							c0.1,0.6,0.9,1.5-0.1,1.8c-0.9,0.3-2.1,0.3-2.9-0.6c-1.5-1.6-2.6-1.7-4.6-0.4c-1,0.7-2.2,0.3-3.2-0.2c-0.7-0.3,0.1-0.7,0.2-1
							c1.3-3.1,3.4-5.9,4.1-9.3C354.8,222.7,354.9,222.6,355.1,222.5z M356.6,226.4c-0.7,1.5-1.6,2.4-1,3.5c0.2,0.5,1.2,0.6,1.5,0.2
							C357.7,229,357.1,228,356.6,226.4z"/>
						<path fill="#FBEFB2" d="M379.1,228.8c-0.2,0.9-0.4,1.8,0.2,2.6c0.8,1.1,0.4,2.2-0.1,3.4c-0.3,0.9-0.8,0.8-1.5,0.5
							c-2-1-3.7-5.1-3-7.3c0.4-1.3,1.1-0.7,1.7-0.3l0,0C376.9,229.3,378.3,228.5,379.1,228.8C379.1,228.8,379.1,228.8,379.1,228.8z"
							/>
						<path fill="#FBEFB2" d="M367.8,222.5c-0.2,0.3-0.3,0.5-0.5,0.8c-0.1,0-0.3,0-0.4,0c-1.3-0.1-3.2-0.7-3.2,1.3
							c0,1,0,2.2,0.1,3.3c0.1,1,0.2,2.3-0.7,3.3c-0.8-0.7-1.2-1.5-1-2.5c0.1-0.7,0.1-1.4-0.4-2c-1.1-1.2-1.6-2.4,1.4-2.6
							c-1.8-0.8-3.3-0.9-3.6-2.5C362.1,221.9,364.9,222.2,367.8,222.5z"/>
						<path fill="#FBEFB2" d="M352.2,223.4c0.1,1.9-1,3.3-1.4,5.1c-0.2,1-0.9,0.6-1.2,0c-0.8-1.6-0.3-3.2,0.1-4.7
							C350.1,222.6,351.4,223.5,352.2,223.4C352.2,223.4,352.2,223.4,352.2,223.4z"/>
						<path fill="#FBEFB2" d="M355.1,222.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.9-0.5-1.5,0.5-2.4,0.5c0,0,0,0,0,0c-0.1-1.2-1-0.9-1.7-1
							c-1.4-0.3-2.6-0.9-3.5-2.1c2.6,0.3,5.1,0.3,7.6,1.2C354.6,221.9,354.7,222.3,355.1,222.5z"/>
						<path fill="#CEC69D" d="M379.4,226.9c0.5,0.6,0.8,1.3,1,2c-0.4,0-0.8-0.1-1.3-0.1c0,0,0,0,0,0
							C379.2,228.2,379.3,227.5,379.4,226.9z"/>
						<path fill="#CEC69D" d="M381,230.2c0.4,0.3,0.5,0.7,0.5,1.2c-0.5,0.1-0.8,0-1-0.5C380.4,230.5,380.6,230.3,381,230.2z"/>
						<path fill="#FBEFB2" d="M376.6,227.7c0.1-0.4,0.3-0.8,0.8-0.7c0.1,0,0.3,0.3,0.3,0.4C377.5,227.9,377,227.7,376.6,227.7
							L376.6,227.7z"/>
						<path fill="#CEC69D" d="M381.6,231.5c0.4,0.5,0.8,0.9,0.1,1.5C381.2,232.6,381.3,232,381.6,231.5z"/>
						<path fill="#CEC69D" d="M380.4,228.9c0.1,0.3,0.3,0.6,0.4,0.9C380.4,229.7,380.3,229.3,380.4,228.9z"/>
						<path fill="#CEC69D" d="M380.8,234.2c-0.1,0.2-0.3,0.4-0.4,0.6C380.3,234.5,380.5,234.3,380.8,234.2z"/>
						<path fill="#1D190E" d="M428.5,233.4c2.4-3,3.2-3.2,6-2c-0.3,3-0.7,6-1,9.1c-0.1,1.3-0.5,1.7-1.8,1.5c-1.2-0.2-2-0.3-1.8-1.9
							c0.2-1.1,0.5-2.2,0-3.7c-1.2,1.4-2.3,2.5-3.2,3.8c-1,1.5-2.4,1.1-3.6,0.8c-1.3-0.3-0.5-1.6-0.5-2.4c0.2-2.8,0.5-5.6,0.8-8.5
							l0,0.1c1.1-0.2,2.3-0.1,3.4,0.2c0,0.3,0,0.6,0,0.9c-0.1,0.5-0.3,0.9-0.3,1.4c0,0.8-0.7,2.1,0.2,2.4
							C427.9,235.6,427.9,234,428.5,233.4z"/>
						<path fill="#1D190E" d="M437.7,231.7c1.3-0.6,2.4,0.2,3.6,0.4c-0.2,1.6-0.3,3.2-0.6,5.3c1.8-1.3,2.6-2.9,3.6-4.2
							c1.2-1.5,2.5-0.6,3.6-0.5c1.1,0.2,0.5,1.4,0.4,2.2c-0.2,2.3-0.5,4.6-0.7,7c-0.1,1.8-1.2,1.7-2.5,1.6c-1.6-0.1-1.1-1.1-1-1.9
							c0.1-1,0.4-2.1,0.1-3.3c-1.5,0.8-2.3,2.2-3.1,3.4c-1.1,1.7-2.6,1.1-4,0.9c-0.9-0.2-0.5-1-0.4-1.6
							C437.1,237.9,437.4,234.8,437.7,231.7z"/>
						<path fill="#FBEFB2" d="M417,234.2c0-0.4,0-0.8,0.1-1.2l0.1,0l0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0.6-0.3-0.4-1.3,0.6-1.3
							c0.8,0,0.3,1,0.7,1.4c0.7,0.2,0.6-0.5,0.7-0.8c0.5-0.7,1.1-0.4,1.7-0.3c0.8,0.1,0.7,0.8,0.7,1.3c-0.1,1.1-0.3,2.1-0.3,3.2
							c0,1.9-0.7,2.6-2.6,2.1c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.4,0-0.7C419.7,235.2,418.5,234.6,417,234.2z"/>
						<path fill="#CEC69D" d="M417.3,233l-0.1,0l-0.1,0c-0.2-0.7-0.1-1.4,0.2-2.1C417.3,231.6,417.3,232.3,417.3,233z"/>
						<path fill="#CEC69D" d="M417.3,229.9c0-0.2-0.1-0.3-0.1-0.5c0.1,0,0.3,0,0.4,0.1C417.5,229.7,417.4,229.8,417.3,229.9z"/>
						<path fill="#CEC69D" d="M417.2,229.4c-0.2,0-0.4-0.1-0.6-0.1c-2.8-0.5-2.8-0.5-3.1,2.5c-0.1,1.1-0.3,2.1-0.3,3.2
							c-0.1,1.4-0.3,2.3-2.1,2c-1.6-0.2-2-0.8-1.7-2.3c0.3-1.9,0.5-3.9,0.7-5.9c1.6,0.6,3.2-0.5,4.8-0.2
							C415.6,228.6,416.8,228,417.2,229.4z"/>
						<path fill="#E9E4B6" d="M406.1,230.9c-0.1,1-0.2,2-0.3,3c-0.1,0.1-0.2,0.3-0.4,0.4c-0.4-1.7-1.2-3.3-0.7-5
							c0.2-0.8,0.3-1.7,1.5-1.7c-0.3,0.4-0.5,0.8,0.2,0.8c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
							c0,0.3-0.1,0.6-0.1,0.9c0,0-0.1,0.1-0.1,0.1C406,230.7,406.1,230.8,406.1,230.9z"/>
						<path fill="#B7AF8B" d="M428.5,233.4c-0.6,0.6-0.5,2.2-1.6,1.8c-0.9-0.3-0.2-1.6-0.2-2.4c0-0.5,0.2-0.9,0.3-1.4
							C427.4,232.1,426.8,233.6,428.5,233.4z"/>
						<path fill="#CEC69D" d="M406.5,228.4c-0.8-0.1-0.6-0.4-0.2-0.8c1.2,0.4,2.9-0.6,3.7,1.1C408.9,228.4,407.7,228.2,406.5,228.4z
							"/>
						<path fill="#B7AF8B" d="M426.9,230.5c-1.1-0.3-2.2-0.5-3.4-0.2C424.8,229,425.9,229.3,426.9,230.5z"/>
						<path fill="#CEC69D" d="M405.5,234.3c0.1-0.1,0.2-0.3,0.4-0.4c0.1,1.2,0,2.3-0.4,3.4c-0.3-0.4-0.2-0.9-0.2-1.3
							C405.3,235.4,405.4,234.8,405.5,234.3z"/>
						<path fill="#CEC69D" d="M415.6,240.8c-3.4-0.4-6.8-0.7-10.2-1.1c0-0.2,0-0.5,0-0.7c2.8,0.4,5.7,0.7,8.5,1.1
							C414.5,240.2,415.4,239.8,415.6,240.8z"/>
						<path fill="#CEC69D" d="M405.3,238.1c0,0.1,0,0.3,0,0.4c0-0.1-0.1-0.2-0.1-0.3C405.2,238.2,405.3,238.1,405.3,238.1z"/>
						<path fill="#E9E4B6" d="M205.2,357.6c0,0.1,0,0.3,0,0.4c0-0.1-0.1-0.2-0.1-0.3C205,357.7,205.1,357.6,205.2,357.6z"/>
						<path fill="#FBEFB2" d="M397.3,458.2c0.2,1.3,1,1.8,2.2,1.7c2.2-0.1,3,0.8,2.4,3c-0.1,0.5-0.1,1.1-0.1,1.7
							c-0.2,1.7-0.4,3.4-0.5,5c-0.1,0.7-0.3,1.4,0.7,1.7c-0.1,1.1-0.2,2.3-0.4,3.4c-1.8,0.6-0.8,1.6-0.3,2.5c0,0.4,0,0.9,0,1.3
							c-0.1,0.3-0.2,0.5-0.3,0.8c-1.7,1.5-1,3.5-1.3,5.3c-0.1,1-0.3,1.9-0.4,2.9c-0.1,0.5-0.1,1.4-0.8,1.2c-2-0.3-4.1,0.4-6-0.3
							c-1.1-0.4-2.1-0.4-3.3-0.1c-2,0.5-4-0.1-6.3-0.4c1.1-1.1,2.5,0.1,2.8-1.5c0.6-0.3,1.7,0.4,1.8-0.9c0.1-0.6,0.2-1.2-0.6-1.4
							c-1.8-0.2-3.5-0.6-5.3-0.5c-0.9,0-2.3,0.6-1,2.1c0.3,0.3,1,0.6,0.4,1.2c-0.4,0.4-1,0.8-1.5,0.5c-2.1-1.5-4.5-0.7-6.7-1.4
							c-2.3-0.6-3.3,0.5-3.9,2.4c-0.5,1.5-0.4,3,0,4.4c0.3,0.9,0.9,1.8,2.1,1.8c5.4,0.2,10.9,0.3,16.3,1.1c0.8,0.1,1.6,0.3,1.9-0.8
							c0.6,0.8,0.2,1.6,0.1,2.4c0,2.2-1.1,4.4-0.3,6.7c-0.3,0-0.5,0.1-0.8,0.1c-0.4-0.1-0.9-0.1-1.3-0.2c-1.1,0.9-1.4,1.8,0.3,2
							c3.1,0.4,6.1,0.6,9.2,0.8c0.3,0,0.6,0.1,0.9,0.1c0.3,0.2,0.7,0.4,1,0.6c-0.1,1-0.2,2-0.3,3c-0.3,0.4-0.3,0.9-0.1,1.3
							c-0.1,0.5-0.1,1.1-0.2,1.6c-0.6,2.8-0.9,5.7-0.9,8.5c-0.4,0.8,0.1,2.2-1.4,2.3l-0.2-0.1l-0.2,0.1c-2.7-0.5-5.3-0.8-8.1-0.8
							c-4.2-0.7-8.5-1.2-12.8-1.3c-0.4-0.1-0.7-0.3-1.1-0.4c0.5-4.9,1.1-9.8,1.6-14.8c0.1-1.2,0.1-2.3,1.9-1.9c0.7,0.2,1-0.4,1-1
							c0.1-0.6-0.2-0.9-0.8-1c-0.5-0.1-1-0.1-1.5-0.1c-6.6-0.7-13.2-1.5-19.8-2.2c0.1-1.2-0.8-1.1-1.6-1.2
							c-4.1-0.4-8.2-0.9-12.4-1.3c-0.7-0.1-1.4-0.4-1.7,0.6c-0.3,0-0.6,0-0.8-0.1c-1.3,0.9-1.1,1.9,0.6,2.6c0.1,0.2,0.3,0.3,0.4,0.1
							l0,0c0.5,1,1.4,1,2.4,1.1c4.6,0.5,9.1,1,13.7,1.5c4.4,0.5,8.9,1,13.4,1.4c1.6,0.1,2.3,0.9,2.1,2.5c-0.4,3.7-0.8,7.3-1.2,11
							c-0.1,1.3-0.7,1.6-1.9,1.5c-4.2-0.2-8.4-1.1-12.6-1.5c-12.2-1-24.4-2.5-36.7-3.8c-1.5-0.2-3.5-0.1-1.4-2.4
							c0.2-0.2,0.2-0.6,0.1-0.8c-1.3-3.2,0.2-6.3,0.2-9.4c0-1.6,1-2.4,2.7-2.2c0.4,0,0.9,0.2,1.3,0c2.7-0.9,5.3-0.5,7.9,0.8
							c1.1,0.6,2.2,0,2.8-0.9c0.7-0.9-0.2-1.8-0.9-2.1c-2.2-0.9-4.4-2.2-7.1-1.5c-1,0.3-2,0.3-1.9-1.2c-0.6,0.1-0.5,0.6-0.7,0.8
							c-0.6,0.6-3.2,0.2-3,0c1.1-1.2-0.4-2.9,0.9-3.9c2,0.2,3.9,0.4,5.9,0.6c0.6,0.1,1.4,0.5,1.8-0.5c0.1-0.3,0.2-0.6,0.3-1
							c0.7-2.6,1.2-5.2,0.7-8l0,0c-0.4-0.9-1.2-0.7-2-0.8c-2.2-0.2-4.5-0.4-6.7-0.7c0-0.3,0.1-0.6,0.1-0.8c2,0.2,3.9,0.5,5.9,0.6
							c0.9,0.1,1.9,0.8,2.8-0.2v0c0.7-0.2,0.7-0.6,0.3-1.1c0.1-1.2,0.2-2.4,0.4-3.5c0.5-0.4,0.6-0.9,0.2-1.5
							c0.1-0.9,0.2-1.8,0.2-2.7c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c0,0,0,0,0,0c-0.3-0.9-1.2-0.6-1.8-0.7
							c-2.2-0.3-4.3-0.5-6.5-0.8c2.5-23.6,5.1-47.2,7.6-70.9c0.1-0.7,0.6-1.6-0.5-2c0-0.3,0-0.6,0-0.8c2.1-1.2,4.1-0.3,6.2-0.1
							c1.4,0.2,2.7,0.5,4-0.2c0.4-2.7,0.7-5.4,1.1-8.1c0.1-0.7-0.2-1.1-0.9-1.2c-2.8-0.5-5.7-0.8-8.5-0.5c-0.2-0.3-0.4-0.6-0.7-0.9
							c0-0.3,0-0.5,0.1-0.8c0.4,0,0.8,0,1.2,0.1c2.5,0.3,4.9,0.5,7.4,0.8c1.2,0.2,1.7-0.2,1.7-1.4c0.2-2.5,0.4-5.1,0.7-7.6
							c0.1-0.4,0.2-0.8,0.4-1.1c1.2-0.2,1.1-1.2,1-1.9c-0.1-1.2-1.3-0.7-2-1c-0.2,0-0.3,0-0.5-0.1l0,0c-0.5-0.4-1-0.7-1.5-1.1
							c0-0.7,0.1-1.3,0.1-2c0.1-0.7,0.5-1.6-0.7-1.8c-0.6-0.1-1.4-0.4-1.6,0.5c-0.3,1.2-0.7,2.2-2.1,2.3c-1.2,0.1-2.3,0.4-3.6,0.3
							c-3-0.4-6-0.9-8.9-0.8c-3.2,0.1-6-1.2-9.1-1.2c-0.8,0-1.2-0.8-1.1-1.7c0.2-1.8,0.5-3.5,0.6-5.3c0.1-1.5,0.7-1.9,2.1-1.7
							c5.9,0.7,11.8,1.3,17.8,2c1.6,0.2,3.5-0.1,4.3,2c0.1,0.2,0.9,0.2,1.4,0.3l0,0c0,0.4-0.4,1.2,0.3,1.2c0.6,0,1.9,0.2,1.9-0.4
							c0-1.7,1.4-2.8,1.5-4.3c0.1-0.9,0.4-2.4-1.3-2.6l0,0c0-0.5,0.1-1.1-0.7-1.3c-0.9-0.1-1,0.5-1.1,1.1l0,0
							c-0.8,0.4-1.7,0.3-2.5,0.2c-5.2-0.6-10.3-1.1-15.5-1.7c-1.9-0.2-3.8-0.4-5.6-0.6c-0.5-0.1-1.4-0.1-1.1-0.6
							c0.8-1.7-0.6-3.3-0.2-5c0.5-1.8,1.3-2.3,2.9-1.4c0.5,0.3,0.9,0.5,1.4,0.1c0.9-0.7,1.8-0.4,2.7-0.3c4.4,0.5,8.9,1,13.3,1.4
							c2,0.2,4,0.2,5.5,1.8c0.5,0.5,1,0.6,1.1-0.3c0-0.1,0.1-0.3,0.1-0.4c0.2-0.1,0.3-0.2,0.5-0.3c1-0.2,0.8-1,0.9-1.7
							c0.9-8.1,1.8-16.2,2.6-24.4c0.1-0.7,0.6-1.6-0.6-2l0,0c-0.4-1.2-1-1.2-1.7-0.2c-0.1,0.1-0.2,0.3-0.2,0.4
							c-1.2,0-1.1,0.9-1.2,1.6c-0.4,2.3,0.3,4.7-0.4,6.9c-1,3.5-1.1,7.1-1.5,10.6c-0.2,1.9-0.4,3.8-1.9,5.1
							c-1.4-0.1-2.8-0.3-4.2-0.4c0-0.9,0.2-1.8-1-2.2c0.2-1.5,0.3-3.1,0.5-4.6c1.8-0.8,1.7-2,0.7-3.4c0,0,0.1-0.1,0.1-0.1
							c0,0-0.1,0.1-0.1,0.1c-0.2,0-0.3,0-0.5,0c-0.3-0.1-0.5-0.3-0.8-0.4c0-0.6-0.2-1-0.8-1c-2.6-0.3-5.2-0.7-7.8-0.8
							c-1.5,0-3,0.4-3.1,2.6c-0.1,2.8-0.5,5.6-0.8,8.4c-0.3,0-0.5-0.1-0.8-0.1c-0.3-0.5-0.8-0.5-1.3-0.5c-2.9-0.4-2.9-0.4-2.6-3.3
							c2-18.4,4-36.8,5.9-55.2c0.2-1.5,1.2-2.9,0.6-4.6c-0.2-0.5,0.5-0.7,1.1-0.7c2-0.1,4.1-0.1,6.1,0c4.5,0.2,8.9,0.9,13.3,1.3
							c1.8,0.2,3.4,0.5,4.8,1.8c0,0.4-0.1,0.8-0.1,1.2c-0.4,0.1-1.2-0.3-1.3,0.3c0,0.2,0.7,0.7,1.1,1c-0.2,2-0.4,3.9-0.6,5.9
							c-0.5,0.3-0.7,0.7-0.8,1.2c-0.3,3.1-0.7,6.2-1,9.3c0,0.5,0.1,0.8,0.6,1c-0.1,0.8-0.2,1.7-0.3,2.5c-0.5,0.1-0.7,0.4-0.8,0.9
							c-0.4,2.1-0.2,4.3-1,6.4c-0.3,0.9,0.7,1.3,1.2,1.8c0.5,0.4,1-0.1,1.4-0.5c0.7-0.6,1.1-1.4,1.1-2.4c0.7,0.1,1.1,0.6,1.5,1.1
							c1.9,2.9,3.5,6,5.6,8.7c1.9,2.3,0.6,4.9,0.5,7.3c0,0.6-0.6,0.8-1.1,0.7c-2.1-0.3-2.5,0.8-2.7,2.6c-0.4,4.8-0.7,9.7-1.6,14.4
							c-0.6,3.4,0.5,5.6,2.7,7.7c-2.3,21.6-4.6,43.2-7,64.9c0,0.4-0.1,0.8-0.1,1.3c0.5,1.8-1.3,2.3-2.1,3.4c-0.9,0.2-0.9,0.9-1,1.7
							c-0.6,5.9-1.2,11.8-1.8,17.7c-0.1,0.6-0.4,1.3,0.6,1.5c0.6,0.3,1.3,0.4,2,0.2c0.9,0.9,0.2,1.9,0.3,2.8c-0.3,0.5-0.3,1-0.2,1.6
							c-0.2,2.1-0.4,4.3-0.7,6.4c0,0.1,0,0.3,0,0.4c-0.5,4.5-1,9.1-1.5,13.6c-0.7,2.2-0.8,4.4-0.8,6.7c-0.1,0.8-0.3,1.5-0.3,2.3
							c-0.1,2.7-1,5.3-0.5,8c0.1,0.3,0.2,0.4,0.5,0.1c0.3,0,0.5,0.1,0.8,0.1c0.1,0.3,0.3,0.3,0.5,0.1c1.8,0.2,3.6,0.4,5.4,0.6
							c1.7,0.6,3.4,0.7,5.2,0.5c0.3,0,0.5,0.1,0.8,0.1c0.3,0.3,0.6,0.4,0.9,0.1c0.1,0,0.3,0,0.4,0l0.2,0.1l0.2,0
							c0.2,0.2,0.3,0.2,0.5-0.1c0.1,0,0.2,0,0.3,0c0.8,0.1,1-0.4,1-1c0.1-0.9,0.2-1.8,0.3-2.7c0.3-1.9-0.4-2.9-2.4-3.1
							c-2.5-0.2-4.9-0.6-7.4-0.8c-1.2-0.1-1.8-0.5-1.6-1.8c1.1-10.3,2.2-20.5,3.3-30.8c0.1-0.6,0.1-1.2,0.9-1.5
							c2.2-0.7,2.5-1.7,1.8-4.1c0-0.3,0.1-0.6,0.1-0.9c0.1,0,0.2-0.1,0.2-0.1c1.2-5.5,1.3-11.2,2.1-16.8c0-0.3-0.1-0.7-0.4-0.5
							c-0.2,0.1-0.3,0.6-0.4,0.9c-0.3-0.1-0.5-0.2-0.8-0.3c0.1-0.9-0.8-1.5-0.7-2.6c1-8.6,1.9-17.2,2.8-25.8c0.1-1.3,0.7-1.6,2-1.5
							c3.2,0.4,6.5,0.7,9.7,1.1c0.4,0,0.9,0,0.9,0.3c0.2,1.1-1.8,0.3-1,1.5c0.6,0.9,1.2,2,2.6,1.9c3.8,0.4,7.6,0.8,11.5,1.1
							c0.2,0,0.4-0.3,0.6-0.5c0.5,0,1.1-0.2,1.5,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.3,0,0.6-0.1,0.9-0.1
							c0.4,0.7,1.1,0.5,1.7,0.6c1,1.7,2,2,3.3,0.7l0,0c0.6,0.1,0.9-0.2,0.9-0.8c0-0.2,0-0.4,0-0.7c0.8-0.5,1.7-1.1,2.5-1.6
							c2.6,0.3,5.3,0.6,7.9,0.8c0.6,0.1,0.9-0.1,1-0.7l0,0c1.6-0.3,0.8-1.7,1.1-2.6c0.3-0.8-0.3-2.1,1.2-2.3c-0.2,2-0.4,4.1-0.5,6.1
							c0,0.6-0.7,1.4,0.5,1.7c0.3,0.1,0.6,0.1,0.9,0.2c1.1,0.4,2.2,0.6,3.3,0.3c1.4,0.1,2.9,0.2,4.3,0.4c0.3,0.1,0.7,0.1,1,0.2
							c-0.1,0.5-0.1,1-0.2,1.5c-0.3,0.2-0.3,0.4-0.1,0.6c-0.2,1.2-0.4,2.5-0.6,3.7c-1.5,0.1-1,1.3-1.1,2.1
							c-1.2,11.1-2.4,22.3-3.6,33.4c-0.3,2.5-0.2,2.3-2.5,1.6c-1-0.3-2.9-1.7-3.3,0.9c0,0.2,0,0.5-0.1,0.7c-0.4,0.1-1.3-0.4-0.9,0.6
							c0.3,0.7,0.1,1.4,0.2,2c0.3,1.4-1.1,3.4,1.5,3.9c0,0-0.1,0.5-0.1,0.8C397.9,456.9,396.7,456.9,397.3,458.2z M390.5,402.9
							c-0.9,0.1-1,0.8-1.2,1.5c-0.4,2.2-0.1,4.6-1.4,6.7c-0.6,0.8,0.1,2.2,1.5,2.5l0.1,0c0.2,2.4-0.9,4.4-1.8,6.6
							c-0.8,1.9-0.3,4.2-1.5,6.3c-0.4,0.7,0.8,2.2-1.2,2.3c2.4,2.9,5.2,3.4,8.5,2.2c0.9-0.4,1.7-0.6,1-2.1c-0.9-1.9-0.6-4.4,0.1-5.9
							c1-2.1,0.3-3.2-0.8-4.5c-1-1.1-1.1-2.1,0.1-3c0.4-0.3,0.7-0.7,0.6-1.2c0-0.1,0.1-0.2,0.3-0.3c0.2,0,0.2-0.1,0.2-0.3
							c0-0.2,0.1-0.3,0.1-0.5c1.2,0,1-0.9,1.1-1.7c0.2-1.9,0.1-3.8,0.5-5.6c0.4-1.6-0.6-2.5-1.2-3.6c-0.2-0.4-4.8-0.6-5-0.3
							C390.5,402.2,390.5,402.6,390.5,402.9L390.5,402.9z M387.1,435.6c-1-0.2-1.2,0.4-1.3,1.2c-0.3,2.5-0.5,5-0.8,7.6
							c-0.1,0.6,0,1.1,0.7,1.3c1.4,1.8,3.4,1.3,5.1,1.1c1.1-0.1,0.6-1.8,0.8-2.8c0.2-0.8,0.2-1.7,0.3-2.5c0.1-1.7,1.1-3.7,0.1-4.9
							c-0.9-1.1-3.1-0.5-4.7-0.8C387.3,435.6,387.2,435.6,387.1,435.6z M386.4,463c3.8,0.5,3.1,0.2,3.5-2.6c0.2-1.5,0.4-2.9,0.5-4.4
							c0.1-0.9,0.2-1.6,1.3-1.4c1.4,0.2,1.4-0.8,1.5-1.8c0-0.7,0.6-1.7-0.5-2c-1-0.3-2.7-1-3.1,0c-0.7,1.6-1.7,1.4-2.8,1.3
							c-1.2-0.2-1.7,0.3-1.7,1.5c-0.2,2.3-0.3,4.5-0.7,6.7C384,462.3,384.5,463.4,386.4,463z M383,473.6
							C383,473.6,383,473.6,383,473.6c-0.1,0.9-0.2,1.8-0.4,2.7c-0.5,2.8-0.6,2.8,2.3,3.1c0.3,0,0.6,0,0.8,0.1
							c1.4,0.3,2.1,0,2.2-1.6c0.1-2.2,0.4-4.4,0.9-6.5c0.4-2-0.2-2.7-2.3-2.8c-2.9-0.2-2.9-0.3-3.2,2.7
							C383.1,472,383.1,472.8,383,473.6z M375.2,426.3c-0.3-0.8,1-2.1-0.4-2.9c-2.3-1.3-4.1-0.3-4.4,2.3c-0.1,0.6-0.1,1.3-0.2,1.9
							c-0.3,1.3,0.3,1.6,1.4,1.7C374.8,429.5,374.8,429.6,375.2,426.3z M369.6,477.2c0.3-3.1,0.3-3-2.1-3.4c-2-0.4-2.6,0.4-2.6,2.2
							c0,0.8-0.2,1.7-0.3,2.5c-0.1,0.6,0,1,0.7,1.3C368.4,480.7,369.3,480.2,369.6,477.2z M398.3,409.2c0.3,0,0.6,0,0.6-0.4
							c0-0.3-0.3-0.4-0.6-0.4c-0.3,0-0.6,0-0.6,0.4C397.7,409.1,398,409.2,398.3,409.2z"/>
						<path fill="#FBEFB2" d="M450.4,503.2c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.1-0.3,0.3-0.1,0.5l0.2,0c-0.1,0.8-0.3,1.5-0.4,2.3
							c-0.7,6.3-1.4,12.6-2,18.9c-0.5,0.4-0.5,0.8-0.1,1.3c0,0.3-0.1,0.6-0.1,0.8c-0.3,0.1-0.3,0.3-0.1,0.4l0.2,0
							c-0.1,0.3-0.2,0.5-0.3,0.8c-0.9,0.4-1.7,0.8-2.8,0.7c-13.8-1.5-27.7-3-41.5-4.5c-3-0.3-3.6-1.2-3.3-4.2
							c0.8-7.3,1.6-14.7,2.4-22c0.8-1.7,2.2-1.2,3.4-0.9c3.6,0.7,7.3,1.2,10.9,1.6c0.7,0.1,1.6,0.5,2-0.5c0.2-0.1,0.3-0.2,0.5-0.3
							c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0,0.3,0,0.5,0.1c0.1,0,0.3,0,0.4,0.1c8.8,0.9,17.5,1.9,26.3,2.8c1.1,0.1,2.2,0.3,3.3,0.8
							c0.1,0,0.2,0.1,0.3,0.1C450.3,502.3,450.4,502.8,450.4,503.2z"/>
						<path fill="#FBEFB2" d="M452.8,482c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.2,0.5-0.3,0.8c-0.3,0.1-0.3,0.2-0.1,0.5
							c-0.2,2.1-0.3,4.2-0.5,6.4l-0.1-0.1c-0.1,0-0.3,0-0.4,0c-0.7,0-0.9,0.6-0.9,1.2c-0.1,1.1-0.3,2.1-0.3,3.2
							c0,1.8-0.6,2.4-2.5,2.2c-6.7-0.8-13.3-1.6-20-2.2c-1.9-0.2-2.5-0.8-2-2.6c0.1-0.3,0.1-0.7,0.1-1.1c0-0.1,0.1-0.1,0.1-0.2
							c0,0.1-0.1,0.1-0.1,0.2c-0.2,0-0.4,0-0.6-0.1c-0.2-0.4-0.5-0.6-1-0.7c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.2-0.1-0.3-0.1-0.5
							c1.1-0.5,0.7-1.6,0.8-2.4c0.1-1.3,0.5-2.4,1.3-3.4c0.7-2.3,0.5-4.8,1-7.1c0.3-0.1,0.3-0.3,0.1-0.5c0.1-1,0.2-2,0.3-2.9
							c0.4-0.4,0.4-0.9,0.1-1.3l0.1,0.1c0.8-0.6,1.6-0.7,2.6-0.6c4.4,0.5,8.8,1,13.3,1.4c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.6
							c-0.2,2.2-0.5,4.4-0.7,6.6c-0.1,0.8-0.1,1.7,0.9,1.8c1.3,0.1,2.6,0.8,4,0.1c0.3,0,0.5,0,0.8,0.1c1.4,1.1,3.3,0.1,4.6,1.3
							C452.7,481.7,452.7,481.8,452.8,482z"/>
						<path fill="#14653A" d="M419.5,498.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.2-0.3-0.2-0.4,0
							c-0.7,0-1.5,0-2.2,0c-0.3,0-0.5-0.1-0.8-0.1c-1.1-0.2-2.3-0.4-3.4-0.6c-0.1-0.2-0.3-0.2-0.4,0c-0.3,0-0.6-0.1-0.9-0.1
							c-0.5-0.6-1.3-0.5-2-0.6c-3.3-0.4-6.5-0.7-9.8-1.1c-2.7-1.1-5.6-0.9-8.5-1.2c-0.1,0-0.3,0.4-0.5,0.6c-0.2,0-0.4,0-0.6,0.1
							c-5.9-0.6-11.7-1.2-17.6-1.8c-1.4-0.1-1.9-0.6-1.6-2c0.1-0.6,0.2-1.1,0.2-1.7c0-1.4,0.6-1.8,2-1.6c13.1,1.5,26.2,2.8,39.4,4.3
							c4.8,0.5,5.1,0.4,4.8-4.6c0-0.4-0.2-0.6-0.5-0.8c-1.4-0.9-1.3-2.4,0.2-3.5c0.1,0.2,0.2,0.3,0.4,0.5c-1,1.4,0.6,1.9,1.1,2.6
							c0.5,0.8,1.6,0.9,2.2,0.4c0.7-0.7,2.5-0.7,1.8-2.5c0.4-0.7,1-1.3,0.5-2.1c-0.2-0.3-0.5-0.2-0.7,0c-1.7,1.1-1.2-0.2-1.1-1
							c0.1-0.7,0.2-1.4,0.3-2.1c0.2-1.2-0.3-1.5-1.4-1.4c-1.9,0.6-1.7,2.3-2,3.8c-0.5-0.2-0.8,0.1-1,0.5c-1.2-0.3,0.2-2.1-1.3-2.2
							c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.3-0.5,0.4-0.8c1.7-0.6,0.7-2.2,1.2-3.3c0.1-0.1,0.1-0.2,0.1-0.3
							c0.4,0.1,0.8,0.1,1.3,0.2c0.1,0,0.3,0,0.4,0.1c0.2,0,0.5,0,0.7,0c0.6-0.1,1.2,0.6,1.8,0.3c0.3,0,0.5,0,0.8,0.1
							c0.5,0.4,1.1,0.4,1.7,0.3c0.3-0.1,0.7-0.1,1-0.2c0.4,0,0.7,0,1.1,0c0.2,2.2-0.5,4.3-0.5,6.5c-0.2,0.3-0.4,0.5-0.6,0.8
							c-0.4,0-0.7,0.1-0.8,0.5c-0.4,1.7-0.4,3.6-1,5.3c-0.1,0.3-0.2,0.6-0.3,0.8c-0.9,0.3-1.3,0.9-1.4,1.9
							C420.1,493.3,419.8,495.7,419.5,498.1z"/>
						<path fill="#F5EBB0" d="M364.3,314.7c1.1,0.3,1.9-1.1,3-0.5c-0.1,0.6,0.2,1.1,0.7,1.4c0,1.1-0.9,1.7-1.5,2.4
							c-1,1.3-0.6,2,0.6,2.6c0,0.3-0.1,0.5-0.1,0.8c-2.3,0.7-4.5-0.1-6.7-0.3c-0.4,0-0.9-0.6-1-1c-1.2-3.3-3.8-5.6-5.7-8.4
							c-2-2.9-1.9-4.5,0.9-6.4c0,0,0-0.1,0.1-0.2c0.6-0.6,0.6-1.4,0.7-2.2c0.4-4,0.8-8,1.3-12c0.3-2.2-0.1-4.5,1.1-6.6
							c0.6,0,1.1,0.1,1.7,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c4.5,0.6,4.5,0.6,4,5.1c-0.6,5.6-1.1,11.1-1.7,16.7
							c-0.2,0.3-0.3,0.6-0.1,0.9c-0.4,0.8,0.2,2.1-1.2,2.4c-1.2-0.4-2.4-0.6-3.6-0.4c-0.8-0.1-1.8-0.6-1.9,0.8c-0.1,1.3,1,0.9,1.7,1
							c0.6,0.1,1.1,0.2,1.7,0.2c1.9-0.1,3,0.5,2.4,2.7c-0.2,0.6-0.3,1.3,0.6,1.5C363,315.6,363.9,315.8,364.3,314.7z"/>
						<path fill="#14653A" d="M367.1,321.4c0-0.3,0.1-0.5,0.1-0.8c0.3-2.9,2.2-4.7,4.4-6.3c1-0.8,1.6-0.8,2.4,0.1
							c2.2,2.4,3.9,5,2.7,8.4c-0.1,0-0.3,0-0.4-0.1c-0.5-0.7-0.9-1.5-1.6-2c-1-0.4-0.7,0.5-0.9,0.9c-0.6,0.5-0.5,1.1-0.5,1.7
							c-0.4,3.3-0.4,3.2-3.8,3c-1.8-0.1-3.7-1-5.5-0.2c-0.3,0-0.5,0-0.8,0.1c0-0.2-0.1-0.5-0.1-0.7c-0.9-1-1.8-1.4-2.9-0.3
							c0,0-0.1,0.1-0.1,0.1c-0.5,0-0.8,0.2-0.8,0.7c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0-0.5-0.1-0.7-0.1c-2,1.1-2.2,3.3-0.7,6.5l0.1,0
							l0.1,0l0,0.1c-0.1,0.5,0.1,1,0.6,1.2c0.9,0.3,1,1,0.7,1.7c-0.3,0.9-1,0.5-1.6,0.4c0.2-1.2-0.3-1.8-1.5-1.9
							c-2-1.2-1.5-3-1.3-4.7c0.1-0.5,0.1-1,0.2-1.5c0.7-3-0.2-5.5-1.9-7.9c-2-2.8-3.9-5.8-5.7-8.8c-0.9-1.4-1.6-3,0.8-3.9
							c0.7-0.3,0.7-0.9,0.3-1.4c-0.5-0.7-1.1-1.3-1.6-2c-0.1-0.1-0.3-0.1-0.4-0.2c0-0.5-0.3-0.8-0.8-0.9c0.1-0.7,0.1-1.4,0.2-2.1
							c1.3-0.4,2,0.3,2.6,1.3c0.5,0.9,1.2,2.1,2.4,0.5c0.4-0.3,0.9-0.6,1.3-0.9c0.6,1.1,1,0.5,1.5-0.2c0.3-0.5,0.9-0.4,1.3,0
							c0.3,0.4,0.2,0.9-0.1,1.2c-0.5,0.4-0.9,1.2-1.8,0.8c-0.5-0.2-1.1-0.4-1.3,0.2c-0.6,1.9-1.1,3.9-1.6,5.9
							c-0.1,0.2,0.2,0.5,0.4,0.7c1.6,1.7,2.3,3.9,3.8,5.7c0.9,1.1,2,2,1.5,3.7c-0.1,0.3,0,0.7,0.4,0.9c1.7,0.6,2.9,2,5,2
							c2.3,0,4.6,0.4,7,0.7c2.9,0.3,3.3-0.2,2.6-3.1C369.1,319.9,368.4,321.9,367.1,321.4z"/>
						<path fill="#E9E4B6" d="M319.8,492.5c-1.2,1.1,0.2,2.7-0.9,3.9c-0.2,0.2,2.4,0.6,3,0c0.2-0.2,0.1-0.7,0.7-0.8
							c0,1.5,1,1.4,1.9,1.2c2.6-0.7,4.8,0.6,7.1,1.5c0.7,0.3,1.6,1.2,0.9,2.1c-0.6,0.9-1.7,1.5-2.8,0.9c-2.5-1.4-5.1-1.7-7.9-0.8
							c-0.4,0.1-0.8,0-1.3,0c-1.7-0.2-2.6,0.6-2.7,2.2c0,3.1-1.5,6.2-0.2,9.4c0.1,0.2,0,0.6-0.1,0.8c-2,2.3-0.1,2.2,1.4,2.4
							c12.2,1.3,24.4,2.8,36.7,3.8c4.2,0.3,8.4,1.3,12.6,1.5c1.2,0.1,1.8-0.2,1.9-1.5c0.3-3.7,0.8-7.3,1.2-11
							c0.2-1.6-0.4-2.3-2.1-2.5c-4.5-0.4-8.9-0.9-13.4-1.4c-4.6-0.5-9.1-1-13.7-1.5c-0.9-0.1-1.8-0.2-2.4-1.1c0-0.1,0-0.2,0-0.3
							c1,0,2,0.1,3,0.1c9.3,1,18.6,2.4,28,3.1c0.1,0,0.3,0,0.4,0c1.1,0.1,1.6,0.5,1.4,1.8c-0.6,4.6-1,9.2-1.4,13.8
							c-0.1,1.5-0.7,1.9-2.2,1.7c-17.1-1.9-34.3-3.7-51.4-5.5c-1.7-0.2-2.1-0.6-1.9-2.3c0.6-4.4,1-8.9,1.4-13.3
							c0.1-1.4,0.5-1.8,2-1.6c3.4,0.5,6.9,0.8,10.4,1.1c0.7,0.1,1.5,0.3,1.6-0.8c0.1-1.1-0.8-1-1.5-1.1c-3.2-0.3-6.5-0.8-9.7-1
							c-1.9-0.1-2.6-0.7-2.1-2.6c0.2-0.8,0.2-1.5,0.3-2.3C318.7,492.4,319.2,492.5,319.8,492.5z"/>
						<path fill="#E9E4B6" d="M416.4,487.5c0.3,5,0.1,5.1-4.8,4.6c-13.1-1.5-26.2-2.8-39.4-4.3c-1.4-0.2-2,0.2-2,1.6
							c0,0.6-0.1,1.1-0.2,1.7c-0.3,1.4,0.3,1.9,1.6,2c5.9,0.5,11.7,1.2,17.6,1.8c-0.1,0.1-0.2,0.2-0.2,0.3c-0.3,1.1-1.1,0.9-1.9,0.8
							c-5.4-0.7-10.8-0.9-16.3-1.1c-1.2,0-1.8-0.9-2.1-1.8c-0.4-1.4-0.5-2.9,0-4.4c0.6-1.9,1.6-3.1,3.9-2.4c2.2,0.6,4.7-0.1,6.7,1.4
							c0.5,0.3,1.1-0.1,1.5-0.5c0.5-0.6-0.1-0.8-0.4-1.2c-1.3-1.5,0-2,1-2.1c1.8-0.1,3.5,0.3,5.3,0.5c0.8,0.1,0.7,0.8,0.6,1.4
							c-1.4-1-3.1-0.6-4.7-0.9c-0.5-0.1-1.1-0.1-1.1,0.6c0,0.7,0.6,0.7,1.1,0.8c1,0.1,1.9,0.3,2.9,0.4c-0.3,1.6-1.7,0.4-2.8,1.5
							c2.3,0.3,4.3,0.9,6.3,0.4c1.1-0.3,2.2-0.3,3.3,0.1c2,0.7,4,0,6,0.3c0.7,0.1,0.7-0.7,0.8-1.2c0.1-1,0.3-1.9,0.4-2.9
							c0.2,0.1,0.5,0.1,0.7,0.2c-0.1,1.3-0.1,2.6-0.2,3.9c1.6,1.2,2.4,1,2.6-0.8c0.4-3.8,0.6-7.6,1.5-11.3c0.7,0.1,0.7,1.5,1.8,0.9
							c0.3-0.2,0.7-0.7,1,0c0.2,0.5,0,1.1-0.7,1c-1.9-0.1-1.8,1.3-2.1,2.5c-0.6,2.4,0.1,4.9-0.3,7.4c-0.1,0.9,0.7,1.1,1.4,1.2
							c2.8,0.3,5.6,0.6,8.4,0.9c0.5,0,1,0.2,1.3-0.4c0.3-0.6-0.2-1-0.4-1.5c-0.4-0.8-1.8-0.4-1.7-1.6c0.2,0,0.4,0,0.6,0
							C414.5,487.2,415.5,487.4,416.4,487.5z"/>
						<path fill="#32613E" d="M357.3,336.2c0.6,0.1,1.3,0.5,1.6-0.4c0.3-0.7,0.2-1.4-0.7-1.7c-0.5-0.2-0.7-0.7-0.6-1.2
							c0.7-0.1,1.3-0.3,2-0.4c0.6,0.8,1.3,1.5,1.1,2.6c-0.6,3.3-1.1,6.6-1.1,9.9c0,0.6-0.3,1.1-0.9,1.3c-0.5-0.2-0.8-0.6-1.3-0.5
							c-1.2,0.1-2.5-0.7-3.7,0c-0.7-0.2-1.4-0.7-2.2-0.8c-2.1-0.2-2.4,0.2-1.9,2.4c-0.1,0.4-0.2,0.8-0.3,1.2c-0.2,0.2-0.2,0.3,0,0.5
							c0,0.4,0,0.8,0,1.2c-0.3,0.1-0.5,0.2-0.8,0.4c-0.1,0-0.2-0.1-0.3-0.1c0.2-2,0.4-3.9,0.6-5.9c0.6-2.6,0.9-5.3,0.8-8
							c0-0.3,0.1-0.6,0.1-1c0.6-1.1,0.3-2.4,0.6-3.6c3.1,0,3.1,0,3.4-3c0.1-0.8,0.1-1.6,0.3-2.3c0.3-1.4-0.1-2.5-0.9-3.7
							c-2.8-4.3-5.5-8.8-8.2-13.2c0.5-0.7,0.7-1.5,0.2-2.3c1.8-0.6,0.8-2.3,1.3-3.4c0.5,0.7,1.1,1.3,1.6,2c0.4,0.5,0.4,1.2-0.3,1.4
							c-2.4,0.9-1.6,2.5-0.8,3.9c1.8,3,3.7,5.9,5.7,8.8c1.7,2.4,2.7,4.9,1.9,7.9c-0.1,0.5-0.1,1-0.2,1.5c-0.2,1.7-0.7,3.6,1.3,4.7
							l-0.1,0.1c-2.1,0.4-3.4,1.8-4.2,3.7c-0.3,0.1-0.8,0-1,0.2c-0.5,0.3-1.1,4.9-0.7,5.3c0.8,0.8,1.8,0.8,2.7,0.5
							c0.8-0.2,0.3-1,0.2-1.6c-0.2-1.5-0.8-2.9-1.2-4.4c0.9,0.8,1.5-0.6,2.3-0.3c0.3,0.8-0.2,1.5-0.1,2.3c0.1,0.9-0.9,2.3,0.5,2.5
							c1.7,0.2,0.8-1.6,1.2-2.4c0.3-0.6-0.2-1.5,0.5-2c0.8-0.5,0.9,1,1.7,0.7c0.3-1.4,0.3-1.4-0.4-2.7L357.3,336.2z M351.5,333.1
							c-0.7-0.1-0.8,0.4-0.9,0.9c0,0.6-0.3,1.3,0.6,1.5c0.7,0.2,1-0.3,1.1-0.9C352.3,334,352.2,333.4,351.5,333.1z"/>
						<path fill="#32613E" d="M370.5,320.5c0.7,2.9,0.3,3.4-2.6,3.1c-2.3-0.2-4.7-0.7-7-0.7c-2.1,0-3.3-1.4-5-2
							c-0.4-0.2-0.5-0.5-0.4-0.9c0.5-1.7-0.7-2.6-1.5-3.7c-1.5-1.8-2.2-4-3.8-5.7c-0.2-0.2-0.4-0.5-0.4-0.7c0.5-2,1-4,1.6-5.9
							c0.2-0.6,0.8-0.4,1.3-0.2c0.9,0.4,1.3-0.4,1.8-0.8c0.3-0.3,0.4-0.8,0.1-1.2c-0.4-0.4-0.9-0.5-1.3,0c-0.5,0.7-0.9,1.2-1.5,0.2
							c0.4-1,1.6-1.5,1.7-2.6c0.5,0,1,0.2,1.4-0.1c1.5-1.4,0.5-5.7-1.5-6.3c-0.5-0.2-0.9-0.3-0.9-0.9c0.1-0.1,0.1-0.1,0.2-0.2
							c1-0.2,2.4,1.2,2.9-0.8c0.1-0.6,0.1-1.2,0.2-1.8c0-1,0.9-2.3-0.9-2.7c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-0.5,0-0.8-0.1
							c-1.4-0.5-2.7-0.8-4.1,0.1c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.2-0.3-0.2-0.5-0.1c-0.4-0.1-0.8-0.3-1.2-0.4
							c-0.3-2,0.6-2.6,2.5-2.2c2.6,0.5,5.3,0.3,7.9,1.2c-1.2,2.1-0.8,4.4-1.1,6.6c-0.6,4-0.9,8-1.3,12c-0.1,0.8-0.1,1.6-0.7,2.2
							c-1,0.1-2.5-1.2-3,0.6c-0.5,2-0.7,4.1,0.8,6c0.9,1.1,1.9,2.4,2.4,3.7c2.3,5.5,6.2,8,12.3,7.7C369.9,323,370,323.4,370.5,320.5
							z"/>
						<path fill="#D02826" d="M327.8,491.6c-0.1,0.3-0.2,0.6-0.3,1c-3.1-0.3-6.1-0.6-9.2-0.9c-0.4-0.3-0.7-0.7-0.6-1.3
							c0.3-2.7,0.4-5.4,1.1-8c3.2,0.4,6.4,0.8,9.6,1.3c0,0,0,0,0,0C328.2,486.3,328,489,327.8,491.6z M323.9,490.1
							c0-1.6,0.7-3.3,0-4.9c-0.2-1.2-0.8-0.7-1.1-0.1c-0.8,1.5-0.8,3.2-0.9,4.9c0,0.6,0.4,0.9,0.9,1
							C323.4,491,324.1,491.1,323.9,490.1z"/>
						<path fill="#D02826" d="M448,480.2c-0.3,0-0.5,0-0.8-0.1c-0.5-0.1-1-0.2-1.5-0.2c-2.5,0.2-2.9-1.3-2.5-3.2
							c0.5-2.4-0.6-5.3,2.1-7c2.8,0.2,5.6,0.3,8.4,1c-0.4,0.1-0.9,0.2-0.8,0.8c0.4,1.5-0.1,2.9-0.2,4.4
							C452.3,480.3,452.3,480.3,448,480.2z M447.1,471.7c-0.8,0.2-1.8,0.2-1.7,1.3c0.2,2.2,0.5,4.4,2.4,5.9c0.6,0.4,1.1,0.7,1.7,0.1
							c0.7-0.8-0.1-1-0.5-1.5c-1.2-1.3-3.1-2.5-1.4-4.8C448.1,472.2,447.9,471.8,447.1,471.7z"/>
						<path fill="#CEC69D" d="M345.5,284.8c-1.3-1.3-3-1.6-4.8-1.8c-4.4-0.5-8.9-1.1-13.3-1.3c-2-0.1-4.1-0.1-6.1,0
							c-0.5,0-1.3,0.2-1.1,0.7c0.6,1.7-0.5,3-0.6,4.6c-1.9,18.4-3.9,36.8-5.9,55.2c-0.3,2.8-0.3,2.9,2.6,3.3c0.5,0.1,1,0.1,1.3,0.5
							c-5-0.4-5.1-0.4-4.5-5.4c1.1-10.9,2.3-21.9,3.4-32.8c0.9-8.5,1.9-17.1,2.8-25.6c0.2-1.6,0.5-2.2,2.3-2
							c7.5,0.9,15.1,1.7,22.6,2.5C345.6,283,346,283.6,345.5,284.8z"/>
						<path fill="#E9E4B6" d="M337.1,364.9c-0.5-0.1-1.3,0-1.4-0.3c-0.8-2.1-2.7-1.8-4.3-2c-5.9-0.7-11.8-1.2-17.8-2
							c-1.5-0.2-2.1,0.2-2.1,1.7c-0.1,1.8-0.3,3.5-0.6,5.3c-0.1,1,0.3,1.7,1.1,1.7c3.1,0,6,1.3,9.1,1.2c3-0.1,6,0.5,8.9,0.8
							c1.2,0.2,2.4-0.2,3.6-0.3c1.4-0.1,1.8-1.1,2.1-2.3c0.2-0.9,1-0.6,1.6-0.5c1.2,0.2,0.8,1.1,0.7,1.8c-1.3-0.5-2.1-0.1-2,1.4
							c0,1.1-0.5,1.4-1.6,1.3c-7.6-0.7-15.3-1.2-22.8-2.3c-1.4-0.2-1.8-0.6-1.6-2c0.4-2.6,0.6-5.2,0.8-7.8c0.1-1.2,0.6-1.6,1.7-1.4
							c7.6,1.2,15.3,1.9,23,2.6C337.8,362.2,336.4,364,337.1,364.9z"/>
						<path fill="#D02826" d="M329.2,477.2c-0.1,1.2-0.2,2.4-0.4,3.5c-0.1,0.4-0.2,0.7-0.3,1.1c0,0,0,0,0,0c-3.1-0.3-6.2-0.6-9.3-1
							c-1.1-2.3,0.3-4.5,0.3-6.8c0-0.8-0.3-1.9,0.6-2.5c3.1,0.4,6.2,0.8,9.3,1.1l0,0c0,0.1,0,0.2,0.1,0.3c-0.1,0.9-0.2,1.8-0.2,2.7
							C329.2,476.2,329.1,476.7,329.2,477.2z M322.7,480c0.4-1,0-2.4,1.5-2.2c2.4,0.3,2.4-1.4,2.6-3c0.2-1.7-1-1.6-2-1.7
							c-1.1-0.1-1.9-0.1-2.1,1.4C322.4,476.3,322.7,478.1,322.7,480z"/>
						<path fill="#E9E4B6" d="M339.6,352.3c-0.2,0.9-0.6,0.8-1.1,0.3c-1.5-1.6-3.5-1.6-5.5-1.8c-4.4-0.5-8.9-1-13.3-1.4
							c-0.9-0.1-1.8-0.4-2.7,0.3c-0.4,0.3-0.9,0.1-1.4-0.1c-1.6-0.9-2.4-0.4-2.9,1.4c-0.4,1.7,1,3.3,0.2,5c-0.2,0.5,0.7,0.5,1.1,0.6
							c1.9,0.2,3.8,0.4,5.6,0.6c5.2,0.6,10.3,1.1,15.5,1.7c0.9,0.1,1.7,0.2,2.5-0.2c-0.1,0.9-0.1,1.8-1.5,1.7
							c-6.6-0.8-13.2-1.5-19.8-2.2c-0.7-0.8-1.6-0.8-2.5-0.4c-0.6-0.1-1.1-0.1-1.7-0.2c-0.2-0.1-0.3-0.2-0.5-0.4
							c-0.1-2.8,0.5-5.6,0.7-8.4c0.1-1.1,0.6-1.3,1.6-1.2c2.2,0.3,4.3,0.7,6.5,1c0.4,0.6,0.8,0.6,1.3,0.1c5.1,0.5,10.3,1,15.4,1.4
							c1,0.1,1.4,0.4,1.4,1.4C338.6,352.1,339.1,352.2,339.6,352.3z"/>
						<path fill="#F5EBB0" d="M329.2,343.3c-0.4,4-0.4,4-4.3,3.6c-3.9-0.4-3.9-0.4-3.5-4.3c0.5-4.6,0.5-4.6,4.9-4.1
							c0.2,0,0.4,0,0.6,0.1c2.7,0.3,2.7,0.3,2.5,3C329.3,342.1,329.3,342.7,329.2,343.3z"/>
						<path fill="#B7AF8B" d="M402.3,498.5c-0.8,7.3-1.6,14.7-2.4,22c-0.3,3.1,0.3,3.9,3.3,4.2c13.8,1.5,27.7,3,41.5,4.5
							c1.1,0.1,1.9-0.3,2.8-0.7c0.1,0.3,0.1,0.6,0.2,0.9c-0.5,0.9-1.3,0.6-2.1,0.6c-12.5-1.3-24.9-2.6-37.4-3.9
							c-2.2-0.2-4.5-0.7-6.7-0.8c-2-0.1-3.1-0.7-2.5-2.8c0.4-1.1,0.6-2.2,0.3-3.4c0.7-6.8,1.4-13.5,2.1-20.3c0-0.1,0.1-0.3,0.1-0.4
							C401.8,498.4,402,498.4,402.3,498.5z"/>
						<path fill="#F5F0BC" d="M328.4,398.3c1,0.5,0.5,1.4,0.5,2c-2.5,23.6-5.1,47.2-7.6,70.9c-0.3-0.3-0.5-0.6-0.8-0.9
							c1.5-13.8,3-27.6,4.5-41.4c1-9.1,2-18.2,3.1-27.2c0.4-0.3,0.4-0.5,0.1-0.9C328.2,400,328.3,399.1,328.4,398.3z"/>
						<path fill="#E9E4B6" d="M348.9,344.7c-0.2,2-0.4,3.9-0.6,5.9c-0.2,0.6-0.6,1.2-0.1,1.8c0.5,0.5,1.1,0.5,1.7,0.4
							c0.4,0.1,0.8,0.2,1.3,0.3c0,0.2-0.1,0.4-0.1,0.6c-0.5,0-1.7-0.8-1,0.8c0.2,0.5,0.3,1.1-0.1,1.6c-2.2-2.1-3.3-4.4-2.7-7.7
							c0.9-4.7,1.2-9.6,1.6-14.4c0.2-1.8,0.6-2.9,2.7-2.6c0.5,0.1,1.1-0.1,1.1-0.7c0.2-2.5,1.4-5.1-0.5-7.3
							c-2.2-2.7-3.8-5.8-5.6-8.7c-0.4-0.5-0.7-1-1.5-1.1c0-0.6,0.4-1.4-0.5-1.8c-0.1-0.7-0.1-1.3,0.5-1.8c2.7,4.4,5.3,8.9,8.2,13.2
							c0.8,1.2,1.1,2.3,0.9,3.7c-0.1,0.8-0.2,1.5-0.3,2.3c-0.3,3.1-0.3,3.1-3.4,3c-0.9,1.1-0.6,2.3-0.6,3.6c0,0.3-0.1,0.6-0.1,1
							C349.1,339.4,348.9,342,348.9,344.7z"/>
						<path fill="#F5F0BC" d="M425.5,491.1c0,0.4,0,0.7-0.1,1.1c-0.5,1.8,0.1,2.5,2,2.6c6.7,0.6,13.4,1.3,20,2.2
							c1.9,0.2,2.6-0.4,2.5-2.2c0-1.1,0.2-2.1,0.3-3.2c0.1-0.6,0.2-1.1,0.9-1.2c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,1.1-0.2,2.3-0.2,3.4
							c0,0.1,0,0.3,0,0.4c-0.1,0.7-0.1,1.4-0.2,2.1c0,0.1,0,0.3,0,0.4c-0.1,0.4-0.2,0.9-0.7,1.1c-0.5-0.1-1.1-0.2-1.6-0.3
							c-0.8-0.5-1.7-0.6-2.6-0.3c-5.8-0.5-11.6-1.1-17.4-1.6c-1-0.1-2-0.2-3-0.3c-0.3,0-0.6-0.1-0.9-0.1l0.1-0.1
							c-0.2-0.1-0.4-0.1-0.6-0.2c0-0.3-0.1-0.6-0.1-0.9c0.2-0.2,0.3-0.4,0.1-0.6c0-0.7,0.1-1.4,0.1-2.1c0.1-0.3,0.2-0.6,0.3-0.8
							C425,491,425.2,491,425.5,491.1L425.5,491.1z"/>
						<path fill="#3E3821" d="M355.4,500.9c6.6,0.7,13.2,1.5,19.8,2.2c0.5,0.1,1,0.1,1.5,0.1c0.6,0.1,0.9,0.4,0.8,1
							c-0.1,0.6-0.3,1.2-1,1c-1.8-0.5-1.8,0.7-1.9,1.9c-0.5,4.9-1.1,9.8-1.6,14.8c-0.8-0.6-0.4-1.3-0.3-2c0.4-4,0.9-8,1.2-12.1
							c0.2-1.9,0.3-3.6,3-3.4c-0.7-1-1.6-0.8-2.3-0.9c-9.7-1.1-19.5-2.1-29.2-3.2c-0.8-0.1-1.7,0.2-2.3-0.7c2.8,0.2,5.7,0.4,8.5,0.6
							C352.9,500.5,354.1,500.7,355.4,500.9z"/>
						<path fill="#F5F0BC" d="M450,501.9c-1-0.5-2.1-0.7-3.3-0.8c-8.8-0.9-17.5-1.9-26.3-2.8c0-1.2,0.4-2,1.7-1.1l0,0
							c0.4,1.2,1.1,0.5,1.7,0.3c0.1,0,0.3-0.1,0.4-0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.6,0.6,0.6,1,0.1c3.9,0.4,7.8,0.8,11.8,1.2
							c2.5,0.6,5.1,0.9,7.7,0.8c0.3,0,0.6-0.1,0.8-0.1l0,0.1c0.8,0.5,1.7,0.6,2.6,0.3l0-0.1c0.5,0.3,1.1,0.3,1.6,0.2
							c0,0.1,0,0.2,0.1,0.3C450.2,500.9,450.1,501.4,450,501.9z"/>
						<path fill="#32613E" d="M350.5,302.9c-1.2,1.7-1.8,0.4-2.4-0.5c-0.6-1-1.3-1.8-2.6-1.3c-0.1-3.5-0.1-6.9,1.3-10.2
							c1.2,0.7,2.6,0.7,3.9,0.7c1,0,1.4-0.8,1.3-1.6c0-0.8-0.8-0.5-1.4-0.4c-0.5,0.1-0.9,0.4-1.4-0.1c1.1-0.8-0.2-1.8,0.2-2.7
							c0.7,0.5,1.2,1.4,2.2,1.1c0.7,0.6,1.3,1.2,1.5-0.4c0.1-0.7,0.6,0.2,0.9,0.1c0,0.2,0.1,0.5,0.1,0.7c-0.6,0.5-0.3,1,0.2,1.3
							c0.5,0.3,0.6,0.7,0.5,1.2c-1.8-0.6-2.1-0.5-2.2,1c-0.1,0.1-0.1,0.1-0.2,0.2c-1.1,0.5-2.2,0.8-3.4,0.5c-0.9-0.2-1.3,0.1-2,0.9
							c-1.6,1.9-0.5,3.4,0.1,5.1C347.4,300.6,348.8,301.9,350.5,302.9z"/>
						<path fill="#CEC69D" d="M336.2,348c1.6-1.4,1.7-3.2,1.9-5.1c0.4-3.6,0.5-7.2,1.5-10.6c0.7-2.2,0-4.6,0.4-6.9
							c0.1-0.7,0-1.7,1.2-1.6c-0.7,7.1-1.4,14.1-2.1,21.2c0,0.4-0.1,0.8-0.1,1.3C339.1,348.4,338,348.7,336.2,348z"/>
						<path fill="#888158" d="M416.1,474.4c0,0.1,0,0.2-0.1,0.3c-1.9,0.6-0.7,2.2-1.2,3.3c-0.1,0.3-0.3,0.5-0.4,0.8
							c-0.1,0-0.3-0.1-0.4-0.1c0.2-0.5,0.3-1.1,0.5-1.6c0.6-3.4,0.8-6.8,0.8-10.3c0.2,0.1,0.5,0.1,0.7,0.2c-0.2,1.2,0,1.8,1.4,1.9
							c2.7,0.2,5.4,0.7,8.2,0.5c-0.1,0.3-0.2,0.6-0.3,0.9c-0.8-0.2-1.8-0.6-2.2,0.3c-0.3,0.6-1.1,1.3-0.6,2c0.5,0.9,1.4,0.7,2.2,0.4
							c0,0.7,0.5,1.4,0.2,2.1c-0.4,0-0.7,0-1.1,0c-0.5-1.1-1.9-0.9-2.5-1.9c-0.1-0.7-0.1-1.4-0.2-2.2c1.1-0.9,0.5-1.3-0.5-1.3
							c-1,0-1.3,0.8-1.6,1.5c-0.6,0.4-1.3,0.8-0.9,1.6c0.4,0.8,1,0,1.5,0.1c0.1,0.7,0.4,1.4,0.8,2c-0.7,0.3-1.2-0.3-1.8-0.3
							c0.2-1.4-0.9-1.1-1.7-1.2C416.1,473.3,415.8,473.6,416.1,474.4z M417.6,469.3c-0.9-0.1-1.3,0.3-1.3,1.2c0,0.6-0.4,1.4,0.6,1.5
							c1.2,0.1,0.8-1.2,1.3-1.9C418.5,469.6,418.1,469.4,417.6,469.3z"/>
						<path fill="#3E3821" d="M396.3,507.1c-3.1-0.3-6.2-0.4-9.2-0.8c-1.7-0.2-1.4-1.2-0.3-2c0.4,0.1,0.9,0.1,1.3,0.2
							c0.3,0,0.5-0.1,0.8-0.1c2.1,0.5,4.1,0.8,6.3,0.9c2.9,0.3,3.6-0.3,3.7-3.2c0-0.6-0.1-1.3-0.1-1.9c0.1-0.6-0.1-1.5,0.9-1.6
							l0.1,0.1c-0.2,1.8-0.4,3.6-0.6,5.4c-0.2,1.5-0.9,2.1-2.4,1.9c-1.6-0.1-3.1-0.6-5.1-0.3C393.1,507,395,506.1,396.3,507.1z"/>
						<path fill="#CEC69D" d="M343.2,323.6c1.2,0.4,0.7,1.3,0.6,2c-0.8,8.1-1.7,16.3-2.6,24.4c-0.1,0.6,0.2,1.5-0.9,1.7
							C341.2,342.3,342.2,332.9,343.2,323.6z"/>
						<path fill="#CEC69D" d="M318.4,346.2c0.3-2.8,0.7-5.6,0.8-8.4c0.1-2.2,1.6-2.6,3.1-2.6c2.6,0,5.2,0.5,7.8,0.8
							c0.6,0.1,0.8,0.5,0.8,1c-3,0.1-6-0.5-9-0.8c-1.4-0.1-1.9,0.2-2,1.6c-0.1,2.3-0.4,4.5-0.7,6.7
							C319.2,345.2,319.6,346.1,318.4,346.2z"/>
						<path fill="#B7AF8B" d="M317,277.2c3.4-0.1,6.8,0.6,10.1,1c13.5,1.4,27,2.9,40.5,4.4c0,0.2-0.1,0.4-0.1,0.6
							c-3.3-0.7-6.7-0.8-10-1.2c-3.8-0.4-7.6-0.8-11.4-1.2c-0.7-0.1-1.4-0.1-2.1-0.2c-0.7-0.1-1.4-0.2-2.1-0.2
							c-5.2-0.5-10.5-1.1-15.7-1.6c-0.1,0-0.3,0-0.4,0c-2.8-0.4-5.6-1.3-8.5-0.6C316.9,277.7,316.9,277.5,317,277.2z"/>
						<path fill="#B7AF8B" d="M341,376.3c-0.1,0.4-0.2,0.8-0.3,1.1c-2.6-0.3-5.2-0.7-7.8-1c-0.7-0.1-1.4-0.1-1.5,0.8
							c-0.2,2.8-1.1,5.6-0.5,8.4c-0.4,0-0.8,0-1.2-0.1c0.3-3,0.6-6.1,0.8-9.1c0.1-0.9,0.4-1.5,1.4-1.3
							C334.9,375.6,338,375.5,341,376.3z"/>
						<path fill="#14653A" d="M425.6,469.3c-2.8,0.3-5.5-0.3-8.2-0.5c-1.4-0.1-1.7-0.7-1.4-1.9c0-0.1,0.1-0.3,0.1-0.4
							c2.7,0.3,5.3,0.6,8,0.8C425.5,467.4,426.1,467.9,425.6,469.3z"/>
						<path fill="#F5F0BC" d="M443,470.7c-4.4-0.5-8.8-0.9-13.3-1.4c-1-0.1-1.8,0-2.6,0.6c-0.2-0.4-0.1-0.8,0.1-1.2
							c0.2-0.1,0.4-0.3,0.6-0.4c5.1,0.1,10.1,1,15.2,1.5C443.1,470,443.1,470.3,443,470.7z"/>
						<path fill="#B7AF8B" d="M415.3,466.8c0.1,3.4-0.2,6.9-0.8,10.3c-0.3-0.1-0.6-0.3-1-0.4c1.2-2.2,0.5-4.7,1.1-7
							c0.4-1.5-0.2-2.3-1.7-2.5c-2.5-0.3-5-0.8-7.5-0.5c-0.1-0.3-0.1-0.6-0.2-0.9c0.2-0.1,0.4-0.2,0.7-0.3
							C409,465.9,412.2,466,415.3,466.8z"/>
						<path fill="#B7AF8B" d="M422.2,497.2c-1.4-0.9-1.7-0.2-1.7,1.1c-0.1,0-0.3,0-0.4-0.1c1-3,1.9-6,1.7-9.2
							c0.1-0.3,0.2-0.6,0.3-0.8c0.6-0.1,1.1-0.2,1.7-0.3c0.1,0.8,0.2,1.6,0.2,2.4c-0.7,0.2-1.2,0.5-1.1,1.4
							C422.6,493.5,422.4,495.3,422.2,497.2z"/>
						<path fill="#CEC69D" d="M344.6,311.7c0.9,0.4,0.5,1.1,0.5,1.8c0,1-0.4,1.8-1.1,2.4c-0.4,0.4-1,0.9-1.4,0.5
							c-0.5-0.5-1.5-0.9-1.2-1.8c0.8-2.1,0.6-4.3,1-6.4c0.1-0.5,0.3-0.8,0.8-0.9c-0.2,2-0.4,3.9-0.6,5.9c-0.1,0.7-0.4,1.5,0.6,1.7
							c0.2,0.2,0.4,0.2,0.6-0.1C344.6,313.9,344.1,312.7,344.6,311.7z"/>
						<path fill="#B7AF8B" d="M338.6,397.2c-1.3,0.7-2.6,0.4-4,0.2c-2.1-0.2-4.2-1.1-6.2,0.1c0.1-3.7,0.6-7.4,1.2-11
							c0.2,0.3,0.4,0.6,0.7,0.9c-0.2,2.4-0.4,4.9-0.7,7.3c-0.2,1.3,0.3,1.8,1.5,1.8C333.6,396.7,336.1,397,338.6,397.2z"/>
						<path fill="#3E3821" d="M404.3,476.8c-0.9,3.7-1,7.5-1.5,11.3c-0.2,1.8-1,2-2.6,0.8c1.4,0.3,1.9-0.3,2.1-1.7
							c0.5-4,0.5-8.1,1.6-12.1c0,0,0.2-0.1,0.1-0.1c0.1,0.2,0.2,0.3,0.3,0.5C404.4,475.9,404.4,476.3,404.3,476.8z"/>
						<path fill="#B7AF8B" d="M443,470.7c0-0.4,0.1-0.7,0.1-1.1c0.7,0,1.5,0.1,2.2,0.1c-2.7,1.7-1.6,4.6-2.1,7
							c-0.4,1.9,0.1,3.4,2.5,3.2c0.5,0,1,0.1,1.5,0.2c-1.3,0.6-2.6,0-4-0.1c-1-0.1-1-1-0.9-1.8c0.2-2.2,0.5-4.4,0.7-6.6
							c0.3-0.2,0.3-0.4,0-0.6C443,470.9,443,470.8,443,470.7z"/>
						<path fill="#3E3821" d="M327.3,374.9c-4.4-0.2-8.8-0.5-13.1-1.4C318.6,373.5,323,373.9,327.3,374.9z"/>
						<path fill="#3E3821" d="M374,522.3c4.3,0.1,8.6,0.6,12.8,1.3c-0.4,0.5-0.9,0.4-1.4,0.3c-3.5-0.4-6.9-0.7-10.4-1.1
							C374.7,522.9,374.2,522.8,374,522.3z"/>
						<path fill="#E9E4B6" d="M339.4,358.7c1.7,0.2,1.3,1.7,1.3,2.6c-0.1,1.5-1.4,2.6-1.5,4.3c0,0.6-1.2,0.4-1.9,0.4
							c-0.7,0-0.4-0.7-0.3-1.2c1,0.1,1.7,0,1.8-1.3C338.9,361.9,339.2,360.3,339.4,358.7z"/>
						<path fill="#B7AF8B" d="M319.3,480.8c3.1,0.3,6.2,0.6,9.3,1c-0.8,1-1.9,0.2-2.8,0.2c-2-0.1-4-0.4-5.9-0.6
							c0,0.3-0.1,0.6-0.1,0.8c2.2,0.2,4.5,0.5,6.7,0.7c0.8,0.1,1.6-0.1,2,0.8c-3.2-0.4-6.4-0.8-9.6-1.3
							C318.8,481.8,318.7,481.2,319.3,480.8z"/>
						<path fill="#CCDEC7" d="M349.5,286.8c-0.4,0.9,1,1.9-0.2,2.7l0,0c-0.2,0-0.3-0.1-0.5-0.1c-0.6-0.1-1.1-0.2-1.7-0.3
							c-0.5-1.2-0.2-2.5,0-3.7c0.4,0.1,0.8,0.3,1.2,0.4c0.2,0,0.3,0.1,0.5,0.1c0.2,0.2,0.4,0.3,0.6,0.5
							C349.5,286.6,349.5,286.7,349.5,286.8z"/>
						<path fill="#CEC69D" d="M343.4,304.8c-0.5-0.2-0.6-0.5-0.6-1c0.3-3.1,0.7-6.2,1-9.3c0.1-0.5,0.3-0.9,0.8-1.2
							C344.4,297.1,344,301,343.4,304.8z"/>
						<path fill="#3E3821" d="M447.9,525.2c0.7-6.3,1.3-12.6,2-18.9c0.1-0.8,0.3-1.5,0.4-2.3c0.4,0.4,0.3,0.9,0.2,1.4
							c-0.7,6.5-1.4,13-2.1,19.5c0,0.4-0.2,0.8-0.4,1.2C448,525.8,447.9,525.5,447.9,525.2z"/>
						<path fill="#3E3821" d="M437.4,499c-3.9-0.4-7.8-0.8-11.8-1.2c-0.3,0-0.7-0.1-1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
							c0.4-0.5,1-0.4,1.6-0.3c6.2,0.7,12.5,1.3,18.7,2c0.5,0.1,1,0.1,1.3,0.6c-0.3,0-0.6,0.1-0.8,0.1C442.6,499.3,440,499,437.4,499
							z"/>
						<path fill="#3E3821" d="M428.8,496.2c5.8,0.5,11.6,1.1,17.4,1.6c0.8,0.3,1.7,0.4,2.6,0.3c0.5,0.1,1.1,0.2,1.6,0.3
							c-0.4,0.5-0.9,0.4-1.3,0.3c-6.4-0.7-12.8-1.4-19.1-2C429.5,496.6,429,496.6,428.8,496.2z"/>
						<path fill="#CEC69D" d="M349.4,289.5c0.4,0.5,0.9,0.2,1.4,0.1c0.5-0.1,1.3-0.3,1.4,0.4c0,0.7-0.4,1.6-1.3,1.6
							c-1.3-0.1-2.7,0-3.9-0.7c0-0.3,0-0.5,0-0.8c0.9,0.1,1.7,0.6,2.6-0.1C349.6,289.9,349.6,289.7,349.4,289.5L349.4,289.5z"/>
						<path fill="#3E3821" d="M405.3,465.7c0.1,0.3,0.1,0.6,0.2,0.9c-1.2,2.7-0.8,5.6-1.3,8.4c0,0-0.2,0.1-0.2,0.1
							c-0.2-0.2-0.6-0.4-0.6-0.6c0.1-2.6,0.3-5.2,0.9-7.8C404.4,466.3,404.7,465.8,405.3,465.7z"/>
						<path fill="#CCDEC7" d="M424.7,472.9c-0.8,0.3-1.7,0.5-2.2-0.4c-0.4-0.8,0.3-1.4,0.6-2c0.4-0.9,1.4-0.5,2.2-0.3
							C425.1,471.1,425.3,472.1,424.7,472.9z"/>
						<path fill="#3E3821" d="M402.6,464.6c0.2-1.9,0.4-3.9,0.7-5.8c0.3,0,0.5,0,0.8,0c-0.3,2.4-0.5,4.8-0.9,7.2
							c-0.1,0.7,0.2,1.6-0.8,2C402.5,466.9,402.6,465.8,402.6,464.6z"/>
						<path fill="#3E3821" d="M401.3,478.6c0-0.4,0-0.9,0-1.3c0.3-0.8,0.4-1.7,0.3-2.5c0.1-1.1,0.2-2.3,0.4-3.4
							c0.1-0.7,0.3-1.4,0.4-2.1c1,1.8,0,3.6-0.1,5.5C402.2,476,402.6,477.6,401.3,478.6z"/>
						<path fill="#B7AF8B" d="M448,480.2c4.3,0.1,4.3,0.1,4.7-4.3c0.1-1.5,0.6-2.9,0.2-4.4c-0.1-0.6,0.3-0.7,0.8-0.8
							c0,0.4,0.1,0.7,0.1,1.1c-0.4,3.2-0.8,6.5-1.2,9.7C451.3,480.3,449.4,481.3,448,480.2z"/>
						<path fill="#3E3821" d="M396.7,522.3c0-2.9,0.3-5.7,0.9-8.5c0.1,0.2,0.4,0.5,0.4,0.7C397.7,517.1,397.7,519.8,396.7,522.3z"/>
						<path fill="#B7AF8B" d="M318.3,491.6c3.1,0.3,6.1,0.6,9.2,0.9c-0.4,1-1.2,0.6-1.8,0.5c-2-0.2-3.9-0.4-5.9-0.6
							c-0.6,0-1.1-0.1-1.7-0.1C318.2,492.1,318.3,491.9,318.3,491.6z"/>
						<path fill="#CEC69D" d="M402.3,469.3c-0.1,0.7-0.3,1.4-0.4,2.1c-1-0.3-0.7-1-0.7-1.7c0.2-1.7,0.4-3.4,0.5-5c0.3,0,0.6,0,0.8,0
							c-0.1,1.1-0.1,2.3-0.2,3.4C402.4,468.4,402.3,468.8,402.3,469.3z"/>
						<path fill="#E9E4B6" d="M351.6,500.3c-2.8-0.2-5.7-0.4-8.5-0.6c-0.9-0.1-1.7-0.2-2.6-0.3c-0.5-0.2-0.7,0-0.7,0.5l-0.1,0
							l-0.1-0.1c-0.5-0.1-0.7-0.4-0.7-0.9c0.3,0,0.6,0,0.8,0.1C343.7,499.4,347.7,499.8,351.6,500.3z"/>
						<path fill="#B7AF8B" d="M423.8,482.3c0.2-0.3,0.4-0.5,0.6-0.8c0.4,0,0.8,0,1.2,0.1c-0.8,1-1.2,2.1-1.3,3.4
							c-0.1,0.8,0.3,1.9-0.8,2.4C423.1,485.7,423.8,484,423.8,482.3z"/>
						<path fill="#3E3821" d="M452.6,481.6c0.4-3.2,0.8-6.5,1.2-9.7c0.5,2.2-0.3,4.4-0.4,6.6c0,1.2,0.7,2.6-0.7,3.5
							C452.7,481.8,452.7,481.7,452.6,481.6z"/>
						<path fill="#3E3821" d="M346.1,280.7c3.8,0.4,7.6,0.8,11.4,1.2C353.6,282,349.8,281.6,346.1,280.7z"/>
						<path fill="#3E3821" d="M415.3,466.8c-3.1-0.8-6.2-0.9-9.3-1.4c3.5-0.4,6.8,0.2,10.2,1.1c0,0.1-0.1,0.3-0.1,0.4
							C415.7,466.9,415.5,466.8,415.3,466.8z"/>
						<path fill="#CEC69D" d="M400.4,484.9c-0.2-0.1-0.5-0.1-0.7-0.2c0.3-1.8-0.4-3.9,1.3-5.3C401,481.3,400.8,483.1,400.4,484.9z"
							/>
						<path fill="#B7AF8B" d="M320.4,470.3c0.3,0.3,0.5,0.6,0.8,0.9c2.2,0.3,4.3,0.5,6.5,0.8c0.6,0.1,1.5-0.2,1.8,0.7
							c-3.1-0.4-6.2-0.8-9.3-1.1C320.2,471.1,320.1,470.7,320.4,470.3z"/>
						<path fill="#51727B" d="M346.5,304.3c-0.4,1.1,0.6,2.8-1.3,3.4c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.1-0.3-0.2-0.5
							c0.1-0.7,0.1-1.4,0.2-2.1c0.3-0.3,0.6-0.6,0.9-0.8C346.2,304.1,346.4,304.2,346.5,304.3z"/>
						<path fill="#CEC69D" d="M331.8,337.4c0.2,0,0.3,0,0.5,0l0,0c1,1.4,1.1,2.6-0.7,3.4C331.3,339.6,331.6,338.5,331.8,337.4z"/>
						<path fill="#3E3821" d="M405.8,458.9c0.1,0.6,0.1,1.2,0.2,1.8c-0.3,0.7-0.4,1.4-0.2,2.2c-0.9,0.4-0.8-0.3-0.7-0.7
							c0.1-1,0.2-2.1,0.3-3.1C405.5,458.9,405.6,458.9,405.8,458.9z"/>
						<path fill="#F5F0BC" d="M338.9,498.9c0,0.5,0.1,0.8,0.7,0.9c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.2,0,0.3
							l0,0c-0.1,0-0.3,0-0.4-0.1C337.8,500.8,337.6,499.8,338.9,498.9z"/>
						<path fill="#3E3821" d="M452.4,483.7c0.1-0.3,0.2-0.5,0.3-0.8c0,2.6,0,5.2-0.9,7.6c0.2-2.1,0.3-4.2,0.5-6.4
							C452.3,484,452.3,483.8,452.4,483.7z"/>
						<path fill="#CEC69D" d="M331,345.4c1.2,0.4,1,1.3,1,2.2C330.1,347.6,330.8,346.4,331,345.4z"/>
						<path fill="#CEC69D" d="M405.8,458.9c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0-0.8-0.1-1.2-0.1c-0.3,0-0.5,0-0.8,0
							C404.1,457.3,405,457.8,405.8,458.9z"/>
						<path fill="#E9E4B6" d="M337.7,358.6c0.1-0.6,0.2-1.3,1.1-1.1c0.8,0.1,0.7,0.7,0.7,1.3C338.8,358.7,338.3,358.6,337.7,358.6z"
							/>
						<path fill="#888158" d="M349.4,290.1c-0.8,0.6-1.7,0.2-2.6,0.1c-0.1-0.4,0.1-0.7,0.4-1c0.6,0.1,1.1,0.2,1.7,0.3
							C349.1,289.6,349.2,289.8,349.4,290.1z"/>
						<path fill="#3E3821" d="M412.1,463.4c0.3,0.1,0.5,0.2,0.8,0.3c-1.6,0.3-3.1,0.1-4.6-0.5c0.1-0.1,0.3-0.1,0.4-0.2
							C409.9,463.3,411,463.5,412.1,463.4z"/>
						<path fill="#3E3821" d="M413.8,463.9c0.8-0.1,1.5,0,2.2,0.3C415.2,464.5,414.4,465,413.8,463.9z"/>
						<path fill="#CEC69D" d="M345.2,287.4c-0.4-0.3-1.2-0.8-1.1-1c0.1-0.6,0.8-0.2,1.3-0.3C345.5,286.5,345.5,286.9,345.2,287.4z"
							/>
						<path fill="#32613E" d="M357.7,309.3c1.2-0.2,2.4-0.1,3.6,0.4C360,309.5,358.9,309.4,357.7,309.3z"/>
						<path fill="#CEC69D" d="M341.4,323.3c0.7-1,1.3-0.9,1.7,0.2C342.6,323.5,342,323.4,341.4,323.3z"/>
						<path fill="#CEC69D" d="M405.8,462.9c-0.2-0.8-0.1-1.5,0.2-2.2c0.6,0.6,0.7,1.4,0.7,2.2C406.3,462.9,406.1,462.9,405.8,462.9z
							"/>
						<path fill="#3E3821" d="M399.3,519c0.3,1.2,0,2.3-0.3,3.4C398.9,521.3,399.1,520.2,399.3,519z"/>
						<path fill="#3E3821" d="M422.8,491.6c-0.2-0.9,0.3-1.2,1.1-1.4c0.4,0.1,0.8,0.3,1,0.7c-0.1,0.3-0.2,0.6-0.3,0.8
							C424,490.9,423.5,490.9,422.8,491.6z"/>
						<path fill="#3E3821" d="M339.9,501.3c0-0.2-0.1-0.4-0.1-0.6c1,0.3,2.2-0.1,3.1,0.7C341.9,501.4,340.9,501.3,339.9,501.3z"/>
						<path fill="#32613E" d="M346.1,304.1c-0.3,0.3-0.6,0.6-0.9,0.8c0.1-0.6-0.5-1.2,0.2-1.7C345.8,303.3,346.1,303.6,346.1,304.1z
							"/>
						<path fill="#CEC69D" d="M313.8,357.6c0.9-0.4,1.8-0.4,2.5,0.4C315.5,357.9,314.6,357.7,313.8,357.6z"/>
						<path fill="#B7AF8B" d="M423.9,497.6c-0.6,0.2-1.4,0.9-1.7-0.3C422.8,497.3,423.3,497.4,423.9,497.6z"/>
						<path fill="#B7AF8B" d="M448.5,500.2c-0.9,0.3-1.8,0.2-2.6-0.3C446.8,499.7,447.7,499.8,448.5,500.2z"/>
						<path fill="#3E3821" d="M450.4,503.2c0-0.4,0-0.8,0-1.2c0.1-0.4,0.2-0.9,0.3-1.3C451.1,501.5,451,502.4,450.4,503.2z"/>
						<path fill="#B7AF8B" d="M338,372.3c0.5,0.4,1,0.7,1.5,1.1C338.7,373.4,337.7,373.8,338,372.3z"/>
						<path fill="#CEC69D" d="M321.6,348.7c-0.5,0.5-0.9,0.4-1.3-0.1C320.8,348.3,321.2,348.4,321.6,348.7z"/>
						<path fill="#3E3821" d="M398.2,507.8c-0.3-0.2-0.7-0.4-1-0.6C397.8,506.8,398.3,506.8,398.2,507.8z"/>
						<path fill="#3E3821" d="M397.7,512.1c-0.2-0.5-0.1-0.9,0.1-1.3C398.6,511.3,398.5,511.8,397.7,512.1z"/>
						<path fill="#3E3821" d="M341.8,280.2c0.7,0.1,1.4,0.2,2.1,0.2C343.2,280.8,342.5,280.8,341.8,280.2z"/>
						<path fill="#CEC69D" d="M339.6,352.3c-0.5-0.1-1-0.2-1.2-0.8c0.4,0.1,0.9,0.3,1.3,0.4C339.7,352,339.7,352.2,339.6,352.3z"/>
						<path fill="#3E3821" d="M447.7,529.4c-0.1-0.3-0.1-0.6-0.2-0.9c0.1-0.3,0.2-0.5,0.3-0.8C448.2,528.3,448.1,528.9,447.7,529.4z
							"/>
						<path fill="#3E3821" d="M450.2,500.2c-0.6,0.1-1.1,0.1-1.6-0.2C449.1,499.5,449.7,499.5,450.2,500.2z"/>
						<path fill="#3E3821" d="M401.5,498.4c0,0.1-0.1,0.3-0.1,0.4c-0.5-0.1-1.1-0.1-1.6-0.2c0,0-0.1-0.1-0.1-0.1
							c0-0.1,0-0.1,0.1-0.2c0.4,0,0.9-0.1,1.3-0.1C401.3,498.3,401.4,498.3,401.5,498.4z"/>
						<path fill="#B7AF8B" d="M427.1,469.7c0.3,0.5,0.3,0.9-0.1,1.3C426.8,470.6,426.8,470.2,427.1,469.7z"/>
						<path fill="#B7AF8B" d="M450.7,500.6c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0-0.2,0-0.3-0.1c0.1-0.5,0.2-0.9,0.2-1.4
							C450.4,500.5,450.5,500.6,450.7,500.6z"/>
						<path fill="#B7AF8B" d="M447.9,525.2c0.1,0.3,0.1,0.6,0.2,0.9c-0.1,0.1-0.2,0.3-0.3,0.4C447.3,526,447.4,525.6,447.9,525.2z"
							/>
						<path fill="#B7AF8B" d="M328.1,400.8c0.3,0.3,0.3,0.6-0.1,0.9C328,401.4,328,401.1,328.1,400.8z"/>
						<path fill="#3E3821" d="M339.8,499.9c0-0.5,0.3-0.6,0.7-0.5C340.4,499.8,340.1,499.9,339.8,499.9z"/>
						<path fill="#3E3821" d="M424.9,495.8c0.3,0,0.6,0.1,0.9,0.1C425.5,496.2,425.2,496.2,424.9,495.8z"/>
						<path fill="#32613E" d="M362.4,307.3c-0.1-0.3-0.1-0.7,0.1-0.9C362.5,306.6,362.4,307,362.4,307.3z"/>
						<path fill="#B7AF8B" d="M327.8,376.4c-0.2-0.3-0.2-0.6,0-1c0.1,0,0.2,0.1,0.3,0.1C328,375.8,327.9,376.1,327.8,376.4z"/>
						<path fill="#B7AF8B" d="M447.6,527.8c-0.3-0.2-0.2-0.3,0.1-0.4C447.7,527.5,447.6,527.6,447.6,527.8z"/>
						<path fill="#3E3821" d="M394.9,524.5l0.2-0.1l0.2,0.1C395.1,524.5,395,524.5,394.9,524.5z"/>
						<path fill="#3E3821" d="M313.1,518.2c-0.3-0.1-0.4-0.3-0.3-0.7C312.9,517.7,313,518,313.1,518.2z"/>
						<path fill="#B7AF8B" d="M339.5,373.4c0.2,0,0.3,0,0.5,0.1C339.8,373.6,339.6,373.6,339.5,373.4z"/>
						<path fill="#3E3821" d="M449.3,532.7c-0.2,0.2-0.3,0.2-0.5,0C449,532.8,449.2,532.7,449.3,532.7z"/>
						<path fill="#3E3821" d="M325.7,278.5c0.1,0,0.3,0,0.4,0C326,278.7,325.8,278.7,325.7,278.5z"/>
						<path fill="#32613E" d="M359.8,284.5c-0.1,0-0.3,0-0.4,0C359.5,284.3,359.7,284.2,359.8,284.5z"/>
						<path fill="#32613E" d="M345,307c0.1,0.2,0.1,0.3,0.2,0.5C345.1,307.4,345,307.2,345,307z"/>
						<path fill="#32613E" d="M343.8,314.8c-0.2,0.3-0.4,0.3-0.6,0.1C343.3,314.8,343.5,314.8,343.8,314.8z"/>
						<path fill="#FBEFB2" d="M427.9,468.1c-0.2,0.1-0.4,0.3-0.6,0.4C427.4,468.2,427.5,468,427.9,468.1z"/>
						<path fill="#B7AF8B" d="M450.1,504c-0.3-0.2-0.2-0.3,0.1-0.5C450.2,503.7,450.2,503.9,450.1,504z"/>
						<path fill="#3E3821" d="M328.1,375.5c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.1,0-0.2,0-0.3C327.9,375.2,328,375.4,328.1,375.5z"/>
						<path fill="#3E3821" d="M371.3,504.5c-0.1,0-0.3,0-0.4,0C371,504.2,371.2,504.2,371.3,504.5z"/>
						<path fill="#B7AF8B" d="M451.5,490.9c-0.1-0.2-0.2-0.3-0.3-0.5c0.1,0,0.3,0,0.4,0C451.6,490.6,451.6,490.7,451.5,490.9z"/>
						<path fill="#B7AF8B" d="M426.6,474c0.2,0.2,0.2,0.3-0.1,0.5C426.6,474.3,426.6,474.2,426.6,474z"/>
						<path fill="#B7AF8B" d="M424.4,495.4c0.2,0.1,0.4,0.1,0.6,0.2C424.7,495.8,424.5,495.7,424.4,495.4z"/>
						<path fill="#3E3821" d="M451,497.2c0-0.1,0-0.3,0-0.4c0,0.1,0.1,0.2,0.1,0.3C451.2,497.2,451.1,497.2,451,497.2z"/>
						<path fill="#B7AF8B" d="M424.4,493.9c0.2,0.2,0.2,0.4-0.1,0.6C424.4,494.3,424.4,494.1,424.4,493.9z"/>
						<path fill="#CEC69D" d="M311.6,357.1c0.2,0.1,0.3,0.2,0.5,0.4C311.8,357.5,311.6,357.4,311.6,357.1z"/>
						<path fill="#3E3821" d="M451.3,494.7c0-0.1,0-0.3,0-0.4C451.6,494.4,451.6,494.6,451.3,494.7z"/>
						<path fill="#14653A" d="M351.1,353.1c-0.4-0.1-0.8-0.2-1.3-0.3c-0.1-0.9-1.4-0.3-1.5-1.2c0-0.3,0.1-0.6,0.1-0.9
							c0.3-0.1,0.5-0.2,0.8-0.4c0.3,0.1,0.5,0.1,0.8,0.2c1.5,0.7,3.1,0.5,4.6,0.8c0.1,0.5,0.5,1.4,0.4,1.5c-2.2,1.3-1,3.2-1.2,4.9
							c-0.4,4.8-0.3,9.8-2.2,14.4c-0.3,0.7-0.6,1.6,0,2.4c-0.2,1-1,2,0.2,2.8c-0.3,3-0.7,6-1,9.1c-0.1,1-0.7,2.1,0.1,3.1l0,0
							c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0,0.2,0,0.2,0.1c0.7,0.5,1,1.6,2.2,1.2c3.1-1,6.1,0,9.1,0.6c1,0.2,1.6,0.3,2.3-0.3
							c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.2l0.1-0.1c0.1-0.2,0.3-0.5,0.4-0.7c0.3,0,0.6,0,0.9,0.1c0.5,0.5,1,0.6,1.6,0.2
							c0.6,0.1,1.2,0.1,1.8,0.2c1.1,0.5,2.1,0.7,3.3,0.4c0.6,0.1,1.2,0.1,1.8,0.2c0.6,0.5,1.3,0.6,2,0.2c0.3,0,0.6,0,0.9,0.1
							c-0.8,2.5-1,5.2-1.1,7.8c0,1.3-0.4,1.4-1.4,1.3c-2.3-0.3-4.6-0.8-6.9-0.7c-2.4,0.1-3.9-0.3-3.8-3l-0.1-0.1
							c-0.3-0.1-0.6-0.4-0.9-0.4c-4-0.5-8.1-0.9-12.1-1.3c-0.5-0.1-1-0.1-1.3,0.4l0.1,0c-0.9,0.4-0.6,1.2-0.7,1.8
							c-1,8.8-1.9,17.7-2.9,26.6c-0.1,0.7-0.4,1.4,0.2,2c0.1,0.2,0.3,0.4,0.4,0.6c1.9,2.3,2,3,0.1,4c-0.6-0.6-1.2-1.2-1.7-1.9
							c-0.6-0.8-1.2-0.8-2-0.3c-1.3-0.1-2,0.4-2,1.7c0,0.1,0,0.2-0.1,0.2c-0.5,0.4-0.5,0.9-0.4,1.5c0.2,0.2,0.5,0.3,0.7,0.5
							c0,0.3,0,0.8,0.1,1c1.5,2.3-0.2,3.8-1.3,5.4c-0.4,0-0.8,0-1.2,0c-0.7-2.3,0.1-4.5,0.3-6.7c0.2-2.4-0.2-5.2,3.2-6
							c0,0,0-0.1,0.1-0.2l0-0.1c1.4-0.3,0.9-1.4,1-2.2c2.5-22.9,5-45.9,7.4-68.8c0.1-0.6,0.3-1.2,0.1-1.7
							C351.7,352.5,351.4,352.8,351.1,353.1z M370.5,397.1c1,0.1,1.9,0.2,2.8,0.3c0.8,0,1.9,0.6,2-0.7c0.1-1.3-1.1-1-1.9-1.1
							c-0.3,0-0.6-0.1-0.8-0.1c-2.2-0.2-2.2-0.2-1-2.2c-2.2-0.9-3.1,1.2-4.5,2c-0.4,0.3-0.5,0.8-0.1,1.1c1.2,1.1,1.6,3.3,3.9,2.9
							C371.3,398.4,370.3,398.1,370.5,397.1z"/>
						<path fill="#F5EBB0" d="M375,362.1c0.6-0.2,1.1-0.1,1.7,0.2c-0.4,4-0.9,8-1.3,12.1c-0.1,1-0.2,1.8-1.6,1.6
							c-4.3-0.6-8.6-1.1-12.9-1.5c-1.3-0.1-1.9-0.6-1.7-2c0.4-2.7,0.7-5.5,0.9-8.2c0.1-1.4,0.4-2.6,1.3-3.7c0.4,0,0.8,0.1,1.3,0.1
							c-0.2,1.2,0.7,1.2,1.5,1.3c3.1,0.3,6.2,0.7,9.2,1C374.1,362.9,374.9,363.1,375,362.1z"/>
						<path fill="#E9E4B6" d="M417.2,352.1c-0.1,0.8-0.2,1.6-0.3,2.5c-2.1,0-3,0.7-2.7,2.6c0.3,2.1-0.5,2.2-2.1,1.6
							c0.6-0.4,0.9-0.9,1.1-1.7c0.3-1.2-0.4-1.5-1.1-1.1c-1,0.6-2,0.5-3,0.4c-0.9-0.1-1,0.5-1.1,1.2c-0.5-0.6-1.3-0.5-2-0.7
							c-2.8-0.7-5.4,1.4-8.3,0.5c-0.9-0.1-1.7-0.2-2.6-0.3c-0.8-0.2-1.7-0.3-2.5-0.5c2-1.1,3.8-1.4,5.9,0c0.8,0.6,2,0.4,2.9-0.3
							c1.2-0.9,2.2-2.4,3.9-0.6c0.3,0.3,0.8,0,1.2-0.2c1.3-0.8,1.6-2.7,0.3-3.7c-0.6-0.5-0.9-1-0.4-1.6c0.9-1,1-2.3,1.4-3.5
							c0.6-1.7,3.4-3,5.2-2.3c1,0.4,1.8,0.3,1.8-1.1c0-1.3,0.1-2.6,0.3-4c0.6-5.8,1.2-11.6,1.9-17.4c0.3-2-0.1-2.8-2.2-2.9
							c-4.5-0.3-9-0.8-13.5-1.4c-4-0.5-8.1-0.5-12-1.7c-1.7-0.5-2.6,0.2-2.8,2.1c-1.1,10.8-2.2,21.6-3.5,32.4
							c-0.2,1.6,1.8,2.8,0.6,4.4c0.3,0.1,0.6,0.3,0.9,0.4c0.6,0.2,1.3,0,1.7,0.6c-1.3,0-2.5-0.1-3.8-0.1c-0.3-0.1-0.6-0.2-0.9-0.3
							c-0.1-0.2-0.1-0.4-0.2-0.5c0.1-0.8,0.2-1.6,0.3-2.3c0.2-0.2,0.3-0.4,0.1-0.6c1.3-12.2,2.6-24.4,3.9-36.7
							c0.1-0.2,0.2-0.3,0.4-0.5c0.3,0,0.6,0,0.9-0.1c0.4,0.2,0.8,0.3,1.2,0.1c9.5,1,18.9,2.1,28.4,3c1.6,0.2,2.1,0.6,1.9,2.3
							c-1,8.1-1.7,16.2-2.7,24.3c-0.2,1.8,0.2,2.5,1.9,2.6c0.1,0.8-0.8,1.9,0.6,2.5c-0.1,0.4-0.2,0.7-0.6,0.8
							c-0.8-0.9-1.7-1.7-2.5-2.6c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.4-0.5l0,0c-1.2-2-3-1-4.8-1.3c0.3,0.6,0.3,0.8,0.4,1
							c0.3,0.5,1.2,0.9,0.8,1.6c-0.3,0.6-1,0.2-1.6,0.2c-0.8,0-1.1,0.4-1.3,1.1c-0.5,2.2-0.2,2.6,2.3,2.6c1.1,1.7-1.6,1.4-1.3,2.7
							c2.3,0.6,4.6,1,6.1-1.7C416.1,353.4,416.4,352.3,417.2,352.1z"/>
						<path fill="#FBEFB2" d="M357.9,378.1c1.4,0.1,2.8,0.2,4.2,0.3c0.2,0.5,0,1.2,0.4,1.6c-2.2,1.8-2.4,4.1-0.4,7.1
							c-0.1,1.1,0.7,1,1.4,1l0.2,0.1c-0.1,0.6-0.1,1.2-0.2,1.8c-3-0.3-6-0.7-9.1-0.9c-1.5-0.1-2.1-0.6-1.9-2.2
							c0.4-2.7,0.6-5.5,0.9-8.2c1,0.1,2,0.2,2.9,0.3C357.1,379.1,357.8,379.2,357.9,378.1z"/>
						<path fill="#CEC69D" d="M353.4,378.8c-0.3,2.7-0.5,5.5-0.9,8.2c-0.2,1.6,0.3,2.1,1.9,2.2c3,0.2,6,0.6,9.1,0.9
							c1.1-0.2,2.3-0.3,3.4-0.5c4.9,0.1,9.7,2,14.6,0.8c0.3-0.1,0.5,0.1,0.7,0.4c0.5,1.1,1.3,0.7,1.9,0.4c0.8-0.4,1.3-0.6,2,0.1
							c0.7,0.6,1.7,0.9,2.5,1.4c1.8,1,2.8,0.3,3-1.8c0.3-2.7,0.6-5.3,0.9-8c0-0.4,0.1-0.9,0.1-1.3c0.7-2.1-0.5-2.6-2.3-2.8
							c0.8-0.8,2.2,0.6,2.8-0.9c1.2-0.3,0.7-0.8,0.4-1.5c-0.4-0.9-0.4-1.8,1-1.8c-0.6,6.2-1.3,12.5-1.9,18.7
							c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0-0.2,0.1-0.2,0.2c-1.9-0.3-3.9-0.6-5.8-0.9c0-0.1,0-0.2-0.1-0.3c0.1-0.8-0.5-0.9-1-1
							c-2.3-0.2-4.6-0.4-6.9-0.6c-0.3,0-0.6,0-0.9-0.1c-0.7-0.2-1.3-0.3-2-0.2c-0.6-0.1-1.2-0.1-1.8-0.2c-1.1-0.3-2.2-0.4-3.3-0.4
							c-0.6-0.1-1.2-0.1-1.8-0.2c-0.5-0.1-1.1-0.2-1.6-0.2c-0.3,0-0.6,0-0.9-0.1c-0.4,0.1-0.4,0.4-0.4,0.7c0,0-0.1,0.1-0.1,0.1
							c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0.1c-4.5-0.5-9-1-13.5-1.4c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.3-0.2-0.4l0,0
							c0.1-0.8,0.2-1.7,0.4-2.5c0.5-3.1,0.2-6.3,1.4-9.3C353,378.1,353.2,378.4,353.4,378.8z"/>
						<path fill="#E9E4B6" d="M361.5,360.5c-0.9,1.1-1.2,2.3-1.3,3.7c-0.2,2.8-0.5,5.5-0.9,8.2c-0.2,1.4,0.3,1.9,1.7,2
							c4.3,0.4,8.6,1,12.9,1.5c1.4,0.2,1.5-0.7,1.6-1.6c0.4-4,0.8-8,1.3-12.1c1.1-0.3,1.3,0.3,1.4,1.2c-0.8,3.1-0.9,6.3-1.3,9.5
							c-0.2,1.5,0,3.1-0.8,4.6c-0.3,0-0.6,0-0.9-0.1c-5.4-0.7-10.7-1.6-16.1-1.8c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.7-0.3-1.5-0.3-2.2
							c0.5-4.3,0.9-8.5,1.4-12.7l0-0.1c0.1-0.1,0.3-0.2,0.4-0.3l0,0C360.4,360.2,360.9,360.3,361.5,360.5z"/>
						<path fill="#F5F0BC" d="M380.2,367c0.1-0.3,0.2-0.5,0.2-0.8c1.1-0.9,0.7-2.2,0.8-3.4c0.6-4.7,0.5-4.6,5.1-4.2
							c2,0.2,4,0.9,6.1,0.3c2.6,0.2,5.1,0.4,7.7,0.6c0.4,0.1,0.8,0.2,1.2,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0.1,0.1,0.1
							c-0.3,0.2-0.6,0.3-0.9,0.5c-2.3-0.3-4.6-0.6-6.9-0.8c-0.7-0.1-1.4-0.3-1.9,0.5c-1.6-0.2-3.2-0.5-4.8-0.7
							c-5.2-0.6-5.3-0.6-5.4,4.7c0,1.4-1.5,3.2-0.1,4.2c1,0.8,2.8,0.6,4.3,0.7c4.9,0.5,4.9,0.5,5.6-4.2c0-0.2,0.1-0.4,0.2-0.6
							c2.4,1.1,1.5,3.3,1,4.8c-0.4,1.3-1.9,2-3.6,2.1c-2,0.1-4.8-1.8-5.7,2.1c-0.1-2,0.3-4.1-2.6-3.6
							C379.6,369.1,380.9,367.8,380.2,367z"/>
						<path fill="#FBEFB2" d="M376.3,322.8c0.1,0,0.3,0,0.4,0.1c1.1,0.2,1.6-0.4,1.8-1.3c0.2-0.9,0.2-1.8,0.3-2.7
							c0.6-1.5,1.8-0.4,2.7-0.6c0,0.9-0.7,2.1,1,2.2c0.1,1.2-0.1,2.3-0.4,3.4c-0.5,0-1.1-0.1-1.3,0.6c-0.2,0.9,0.4,1,1.1,1.1
							c0.1,0.7,0,1.4-0.2,2.1c-1.4-0.3-2.7-0.1-4,0.3c-1.2,0.3-2.1-0.2-2.8-1.1c-0.6-0.8-0.2-1.8,0-2.7
							C375,323.5,376.3,323.8,376.3,322.8z"/>
						<path fill="#E9E4B6" d="M357.3,353.9c-0.7,6.5-1.5,12.9-2.2,19.4c-0.1,1.4-0.7,1.9-2,1.5c-0.1-0.4-0.2-0.8-0.2-1.2
							c0.7-6.2,1.5-12.3,2.2-18.5C355.3,353.3,356.2,353.6,357.3,353.9z"/>
						<path fill="#E9E4B6" d="M392.6,381.6c0,0.4-0.1,0.9-0.1,1.3c-6.4-0.7-12.7-1.3-19.1-2c-3-0.3-3-0.3-1.9,2.5
							c0.9,2.4-0.6,4.8-3.1,4.7l-0.1-0.2c2.4-2.7,2.5-4.6,0.4-7.4c0.1-0.4,0.6-0.7,0.3-1.1c7,0.7,13.9,1.4,20.9,2.1
							C390.8,381.6,391.7,381.6,392.6,381.6z"/>
						<path fill="#E9E4B6" d="M362.6,334.1c6.1,0.7,12.1,1.4,18.2,2.2c0.1,0.1,0.1,0.3,0.2,0.4c0,0.3-0.1,0.6-0.1,0.9
							c-0.1,0.1-0.2,0.2-0.3,0.3c-6.1-0.7-12.3-1.3-18.4-2c-0.1-0.1-0.2-0.2-0.2-0.4c0.1-0.4,0.2-0.8,0.3-1.2
							C362.3,334.2,362.5,334.1,362.6,334.1z"/>
						<path fill="#E9E4B6" d="M359.9,357.2c5.6,0.6,11.2,1.3,16.8,2c0.7,0.1,1.8-0.2,1.7,1c-0.1,1.2-1.1,0.8-1.8,0.7
							c-5.1-0.5-10.2-1.6-15.4-1.7c-0.1,0-0.3,0-0.4-0.1c-0.9-0.1-1.5-0.6-1.2-1.6C359.7,357.4,359.8,357.3,359.9,357.2z"/>
						<path fill="#888158" d="M354.7,351.4c-1.5-0.3-3.2-0.1-4.6-0.8c0.3-0.3,1.5,0.2,1.1-0.7c-0.4-0.9,0.5-2.7-1.6-2.5
							c-0.5-2.2-0.1-2.6,1.9-2.4c0.8,0.1,1.4,0.6,2.2,0.8c0.1,0.5-0.7,1.6,0.2,1.5c1.2-0.1,2.8,0,3.6-1.5c0.5-0.1,0.9,0.3,1.3,0.5
							c-0.1,1-0.2,2-0.3,3.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2-1.2-0.8-1.9-2-1.1c-1.3,0.8-0.1,1.9,0.2,2.6c0.3,0.9,1.4,0.6,1.9-0.3
							c0.1,1.4-0.4,2.1-1.9,1.3c-0.6-0.3-1.1-0.6-1.7-0.9c0-1.7-1.5-1.2-2.4-1.9c0.1,1.7,1,2,2.3,2
							C354.7,351.1,354.7,351.2,354.7,351.4z"/>
						<path fill="#E9E4B6" d="M373.6,331.4c-3.2-0.8-6.5-0.9-9.8-1.2c-0.4-0.1-0.8-0.2-1.2-0.3c-0.1-1,0-1.7,1.3-1.5
							c5.5,0.6,10.9,1.2,16.4,1.9c1.2,0.1,1.3,0.8,1,1.8c-2.2,0-4.4-0.9-6.6,0c0-0.3-0.2-0.5-0.5-0.5
							C374,331.5,373.8,331.5,373.6,331.4z"/>
						<path fill="#E9E4B6" d="M366.8,342.1c-1.4-0.1-2.8-0.3-4.2-0.5c-1.5-0.2-1.6-0.8-0.6-1.9c5.3,0.5,10.5,1.5,15.8,1.7
							c0.2,0,0.4,0,0.6,0.1c0.6,0.2,1.2,0.4,1.8,0.5c0,1.1-0.3,1.7-1.6,1.5c-2.2-0.4-4.3-0.6-6.5-0.9
							C370.3,342.1,368.6,342,366.8,342.1z"/>
						<path fill="#E9E4B6" d="M370.5,351.3c-3.4-0.4-6.1-0.6-8.7-0.9c-0.6-0.1-1.4,0-1.3-1c0.1-0.9,0.8-0.9,1.4-0.8
							c5.4,0.6,10.9,1.2,16.3,1.9c0.6,0.1,1.2,0.2,1.1,1c-0.1,0.9-0.8,0.8-1.4,0.8C375.2,351.9,372.5,351.8,370.5,351.3z"/>
						<path fill="#E9E4B6" d="M379,354.9c-1.8,0.7-3.6,0-5.4-0.2c-4-0.4-8-0.9-12-1.4c-0.6-0.1-1.4,0-1.5-0.8c-0.1-0.7,0.3-1,1-1.1
							c0.1,0,0.3,0,0.4,0.1c0.3,0,0.6,0.1,0.9,0.1c0.8,0.3,1.6,0.4,2.5,0.3c0.3,0,0.6,0.1,0.9,0.1c0.5,0.3,1.1,0.3,1.6,0.2
							c0.4,0,0.9,0.1,1.3,0.1c0.2,0,0.4,0,0.6,0c0.6,0.1,1.3,0.2,1.9,0.3c2.3,0.7,4.8,0.7,7.2,0.9c0.2,0.3,0.4,0.7,0.6,1
							C379.1,354.6,379.1,354.7,379,354.9z"/>
						<path fill="#E9E4B6" d="M363.8,342.9c5.4,0.3,10.6,2.1,16,1.9c0,0.5,0,1,0,1.6c-2.3,0.3-4.5-0.2-6.7-0.5
							c-3.8-0.6-7.7-0.9-11.6-1.3c-0.1-0.2-0.2-0.2-0.3-0.2l0,0c0-0.2,0-0.3-0.2-0.4c0.2-1.8,1.5-0.9,2.4-1.1c0,0,0.2,0.1,0.2,0.1
							L363.8,342.9z"/>
						<path fill="#E9E4B6" d="M381.2,334.5c-1.9,0.9-3.7,0.1-5.6-0.1c-3.9-0.3-7.9-0.9-11.8-1.3c-1.4-0.2-2.1-0.5-0.9-1.9
							c6,0.7,12.1,1.4,18.1,2.1l0,0C381.1,333.7,381.1,334.1,381.2,334.5z"/>
						<path fill="#E9E4B6" d="M370.7,348.5c-3.2-0.3-5.7-0.6-8.3-0.9c-0.7-0.1-1.8,0.2-1.7-1c0.1-1.3,1.1-0.9,1.8-0.8
							c5.2,0.6,10.4,1.2,15.6,1.8c0.7,0.1,1.6,0,1.4,1.1c-0.2,1.1-1,0.7-1.7,0.7C375.3,349.1,372.7,348.9,370.7,348.5z"/>
						<path fill="#E9E4B6" d="M360.3,356.1c-0.5-0.5-0.5-1-0.3-1.6c0.5-0.1,1-0.1,1.4-0.2c5.3,0.5,10.6,1.6,16,1.8
							c0.1,0,0.3,0,0.4,0c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.1,0.3,0.2,0.4c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.1-0.2,0.2-0.4,0.3
							c-2.7-0.3-5.4-0.6-8.1-0.9c-0.3-0.3-0.6-0.3-1-0.2c-1.2-0.1-2.3-0.2-3.5-0.3c-0.1-0.1-0.2-0.2-0.4,0c-1.3-0.1-2.6-0.3-3.9-0.4
							C361.1,356.2,360.7,356.2,360.3,356.1z"/>
						<path fill="#F5F0BC" d="M379.6,339c0.4,0.1,0.8,0.2,1,0.7c-0.1,0.2-0.2,0.5-0.4,0.7c-2.5,0-5-0.8-7.6-0.5
							c-0.7-0.1-1.4-0.2-2.1-0.3c-1-0.4-2-0.5-3-0.3c-1.7-0.1-3.4-0.2-5.1-0.3c-1.1-0.2-0.8-1-0.6-1.7c0.3-0.1,0.6-0.1,0.9-0.2
							c1.1,0.1,2.1,0.2,3.2,0.3c0.2,0.3,0.4,0.3,0.6,0.1c0.2,0,0.4,0,0.7,0.1c0.7,0.4,1.5,0.5,2.3,0.2c1,0.1,2.1,0.2,3.1,0.3
							c0.2,0.7,0.8,0.7,1.3,0.8C375.9,339.2,377.8,339.2,379.6,339z"/>
						<path fill="#32613E" d="M401.4,359.9c-0.4-0.1-0.8-0.2-1.2-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.6-0.2,1.7,0.3,1.6-0.7
							c0-0.9-1.1-0.5-1.7-0.6c-0.9-0.2-1.8,0-2.6-0.5c2.8,0.9,5.4-1.3,8.3-0.5c0.7,0.2,1.4,0.1,2,0.7c0,0.4-0.1,0.8-0.1,1.2
							c-0.7,5.4-1.5,10.7-2.2,16.1c-0.6-0.7-0.2-1.5-0.2-2.2c0.4-4.3,0.9-8.6,1.4-12.9c0.2-1.8-0.8-1.9-2.1-2.1
							c-1.4-0.2-2,0.1-2.2,1.7c-0.4,4.3-0.9,8.6-1.4,12.9c-0.1,0.7,0.1,1.6-0.6,2.1c0.2-1.9,0.4-3.8,0.6-5.7c0.2-2.8,1-5.5,0.6-8.4
							l-0.1-0.1C401.8,360,401.7,359.9,401.4,359.9z"/>
						<path fill="#32613E" d="M354.7,351.4c0-0.2,0-0.3-0.1-0.5l0.1-0.1c0.6,0.3,1.1,0.6,1.7,0.9c1.4,0.8,1.9,0.2,1.9-1.3
							c0-0.1,0.1-0.3,0.2-0.3c0.1,1.4,1.4,3.2-1.1,3.8c-1.1-0.3-2-0.6-2.2,1.1c-0.7,6.2-1.5,12.3-2.2,18.5c0,0.4,0.1,0.8,0.2,1.2
							c-0.4-0.1-0.9-0.1-1.3-0.2c-0.6-0.8-0.3-1.7,0-2.4c1.9-4.6,1.7-9.6,2.2-14.4c0.2-1.8-1-3.7,1.2-4.9
							C355.3,352.8,354.9,351.9,354.7,351.4z"/>
						<path fill="#F5F0BC" d="M393.1,377.9c-0.5,1.5-2,0.1-2.8,0.9c-3.2-0.3-6.5-0.6-9.7-0.9c-1.2-0.1-1.6-0.5-1.4-1.7
							c0.2-1.5,0.4-2.9,0.6-4.4c0.8-0.1,0.6-1.1,1.2-1.3c0.2,1.8-1,3.7,0.2,5.5c1.4,2.2,3.7,0.5,5.4,1.2c0.1,0.1,0.3,0,0.4,0
							C389,377.4,391.1,377.6,393.1,377.9z"/>
						<path fill="#E9E4B6" d="M376.3,322.8c0,0.9-1.3,0.6-1.4,1.4c-0.1,0.9-0.6,1.8,0,2.7c0.7,0.9,1.6,1.4,2.8,1.1
							c1.3-0.4,2.6-0.6,4-0.3c0,0.3,0,0.6,0,0.9c-0.1,0.2-0.3,0.4-0.4,0.6c-2.8-0.1-5.5-0.3-8.2-1c0.3-0.5,0.9-1,0.6-1.7
							c0-0.4,0.1-0.9,0.1-1.3c0.2-0.4,0.3-0.8,0.2-1.2c0-0.7,0.1-1.5,0.1-2.2c0.2-0.3,0.4-0.6,0.6-0.9
							C375.4,321.3,375.8,322.1,376.3,322.8z"/>
						<path fill="#E9E4B6" d="M375,362.1c-0.1,1-0.9,0.9-1.6,0.8c-3.1-0.3-6.1-0.7-9.2-1c-0.8-0.1-1.6-0.1-1.5-1.3
							c1.8,0.1,3.7,0.2,5.5,0.4C370.5,361.3,372.8,361.7,375,362.1z"/>
						<path fill="#3E3821" d="M359.3,360.4c-0.5,4.2-0.9,8.5-1.4,12.7c-0.1,0.8-0.3,1.5,0.3,2.2c-1.1,0.6-1.2-0.1-1.1-1
							c0.4-3.7,0.7-7.3,1.1-11C358.3,362.4,357.1,360.8,359.3,360.4z"/>
						<path fill="#3E3821" d="M362.5,338.9c1.7,0.1,3.4,0.2,5.1,0.3c1,0.4,2,0.5,3,0.3c0.7,0.1,1.4,0.2,2.1,0.3
							c0.9,0.2,1.7,0.4,2.6,0.6c0.4,1.4,1.8,0.5,2.6,1.1c-5.3-0.2-10.5-1.2-15.8-1.7C362.2,339.5,362.4,339.2,362.5,338.9z"/>
						<path fill="#E9E4B6" d="M359.6,332.4c-0.7,0.1-1.3,0.3-2,0.4c0,0,0-0.1,0-0.1c0.1-1.1,0.2-2.1,0.2-3.2
							c0.1-0.8,0.2-1.7,0.3-2.5c0.3-0.1,0.6-0.3,0.6-0.7c0.1-0.1,0.3-0.1,0.4-0.2c0.3,0.1,0.5,0.1,0.8,0.2l0,0
							c0.1,0.2,0.1,0.4,0.2,0.6C359.6,328.7,359.4,330.5,359.6,332.4z"/>
						<path fill="#3E3821" d="M381,333.4c-6-0.7-12.1-1.4-18.1-2.1c1.9-0.7,3.7,0.1,5.6,0.2c2,0.1,4.1,0.4,6.1,0.6
							c2.2-0.9,4.4,0,6.6,0C380.5,332.4,380.8,332.9,381,333.4z"/>
						<path fill="#CEC69D" d="M392.5,359c-2.1,0.6-4.1-0.1-6.1-0.3c-4.6-0.4-4.5-0.5-5.1,4.2c-0.1,1.1,0.3,2.5-0.8,3.4
							c0.1-1.9,0.3-3.8,0.4-5.7c0.3-2.9,0.3-2.9,3.2-2.7c2.8,0.5,5.6,0.8,8.5,0.9l0.1,0.1L392.5,359z"/>
						<path fill="#888158" d="M373.6,326.4c0.3,0.7-0.3,1.2-0.6,1.7c-0.4,0-0.7,0-1.1,0c-0.2,0-0.4-0.1-0.6-0.1
							c-2.3-0.2-4.6-0.4-7-0.6c-0.6-0.1-1.6,0-1.2-1.1c0.3,0,0.5,0,0.8-0.1c2.8,1,5.8,1,8.7,0.9
							C372.9,327.1,373.2,326.7,373.6,326.4z"/>
						<path fill="#3E3821" d="M359,375.5c5.4,0.2,10.7,1.1,16.1,1.8c-1.7,1-3.5,0.5-5.1,0.1c-3.2-0.9-6.4-1-9.6-1.4
							C359.9,375.9,359.4,376,359,375.5z"/>
						<path fill="#CCDEC7" d="M358.4,350.1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.6,0.8-1.6,1.2-1.9,0.3c-0.3-0.7-1.5-1.8-0.2-2.6
							c1.2-0.8,1.8,0,2,1.1c-0.1,0.2-0.1,0.4,0.2,0.4C358.3,349.8,358.4,350,358.4,350.1z"/>
						<path fill="#3E3821" d="M376,377.4c0.8-1.4,0.6-3,0.8-4.6c0.4-3.2,0.5-6.4,1.3-9.5c-0.1,4.6-0.9,9.1-1.3,13.6
							C376.8,377.4,376.5,377.8,376,377.4z"/>
						<path fill="#32613E" d="M352.8,377.8c-1.2,3-0.8,6.2-1.4,9.3c-0.1,0.8-0.2,1.7-0.4,2.5c-0.7-1-0.2-2.1-0.1-3.1
							c0.3-3,0.6-6,1-9.1c0.3,0,0.6,0,0.9,0C352.9,377.6,352.9,377.7,352.8,377.8z"/>
						<path fill="#32613E" d="M382.6,355.9c1.3,0,2.5,0.1,3.8,0.1c2.1,0.2,4.3,0.4,6.4,0.7c0.8,0.2,1.7,0.3,2.5,0.5
							C391,357.2,386.8,356.7,382.6,355.9z"/>
						<path fill="#F5F0BC" d="M352.8,377.8c0.1-0.1,0.1-0.2,0.1-0.3c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0.2,0.1,0.4,0.3,0.5
							c0.3,0.1,0.5-0.1,0.6-0.4c0.3,0,0.6,0,0.8,0c0.1,0.3,0.3,0.3,0.5,0c0.7,0.1,1.4,0.2,2.1,0.3c-0.1,1-0.8,1-1.5,0.9
							c-1-0.1-2-0.2-2.9-0.3C353.2,378.4,353,378.1,352.8,377.8z"/>
						<path fill="#32613E" d="M379.6,339c-1.9,0.3-3.7,0.2-5.6-0.1c-0.5-0.1-1.2,0-1.3-0.8C375,338.1,377.4,338.2,379.6,339z"/>
						<path fill="#B7AF8B" d="M375.2,340.4c-0.9-0.2-1.7-0.4-2.6-0.6c2.5-0.2,5,0.5,7.6,0.5c0,0.1,0,0.3,0,0.4
							c-0.3,0.1-0.6,0.3-0.5,0.7c0,0-0.1,0.1-0.1,0.1c0.4-0.8-0.3-0.8-0.7-0.8C377.7,340.6,376.5,340.5,375.2,340.4z"/>
						<path fill="#E9E4B6" d="M382.5,316.7c-1.1-0.4-1.3-1.8-2.3-2.3c0.3,0,0.7,0,1,0C383.2,314.3,383.6,315,382.5,316.7z"/>
						<path fill="#32613E" d="M392.6,358.7c-2.8,0-5.7-0.4-8.5-0.9C387,357.7,389.8,358.1,392.6,358.7z"/>
						<path fill="#3E3821" d="M368.3,360.9c-1.8-0.1-3.7-0.2-5.5-0.4c-0.4,0-0.8-0.1-1.3-0.1c-0.6-0.1-1.1-0.3-1.7-0.4
							C362.7,359.7,365.5,360.1,368.3,360.9z"/>
						<path fill="#B7AF8B" d="M362.5,380.1c-0.5-0.5-0.2-1.1-0.4-1.6c0.9,0,1.8,0.1,2.6,0.1c0,0.3-0.1,0.6-0.1,0.9
							C363.9,379.7,363.2,379.9,362.5,380.1z"/>
						<path fill="#32613E" d="M364.6,379.5c0-0.3,0.1-0.6,0.1-0.9c0.9-0.4,1.7-0.4,2.5,0.3c0,0.3,0,0.5,0,0.8
							C366.3,379.6,365.5,379.5,364.6,379.5z"/>
						<path fill="#CEC69D" d="M380.9,370.4c-0.6,0.2-0.4,1.2-1.2,1.3c-0.1-1.6,0-3.2,0.5-4.7c0.7,0.8-0.6,2.1,0.7,2.7
							C381,369.9,380.9,370.2,380.9,370.4z"/>
						<path fill="#3E3821" d="M378.5,353.5c-2.4-0.2-4.8-0.2-7.2-0.9C373.8,352.5,376.2,352.6,378.5,353.5z"/>
						<path fill="#E9E4B6" d="M382.5,320.5c-1.7-0.1-1-1.4-1-2.2c0.3,0,0.6,0,0.9,0l0,0c0.1,0.2,0.2,0.4,0.3,0.6
							C382.6,319.4,382.5,320,382.5,320.5z"/>
						<path fill="#E9E4B6" d="M417.8,315.4c1.2-1.1,2-1.1,3,0.3C419.7,315.8,418.7,315.7,417.8,315.4z"/>
						<path fill="#FBEFB2" d="M418.4,349.7c-1.4-0.5-0.4-1.6-0.6-2.5c0.1-0.1,0.3-0.1,0.4-0.2C419.4,347.9,418,348.8,418.4,349.7z"
							/>
						<path fill="#32613E" d="M360.1,325.3c1.1-1.2,2-0.7,2.9,0.3C362.1,325.5,361.1,325.4,360.1,325.3z"/>
						<path fill="#888158" d="M366.8,342.1c1.8-0.1,3.5,0,5.2,0.6C370.3,342.8,368.5,342.7,366.8,342.1z"/>
						<path fill="#E9E4B6" d="M381.9,325.6c-0.7-0.1-1.2-0.2-1.1-1.1c0.1-0.8,0.7-0.7,1.3-0.6C382.2,324.5,382.2,325.1,381.9,325.6z
							"/>
						<path fill="#CEC69D" d="M367.2,379.7c0-0.3,0-0.5,0-0.8c0.6,0.2,1.2,0.3,1.8,0.5c0.2,0.5-0.2,0.7-0.3,1.1
							C368.2,380.2,367.7,380,367.2,379.7z"/>
						<path fill="#3E3821" d="M365,351.8c-0.9,0.1-1.7,0.1-2.5-0.3C363.3,351.3,364.2,351.4,365,351.8z"/>
						<path fill="#888158" d="M369.6,337.8c-0.8,0.3-1.6,0.2-2.3-0.2C368.1,337.3,368.9,337.4,369.6,337.8z"/>
						<path fill="#32613E" d="M379.8,346.4c0-0.5,0-1,0-1.6C380.6,345.3,380.3,345.8,379.8,346.4z"/>
						<path fill="#3E3821" d="M367.5,352.1c-0.6,0.2-1.1,0.1-1.6-0.2C366.4,351.7,367,351.7,367.5,352.1z"/>
						<path fill="#3E3821" d="M379.6,341.6c0,0,0.1-0.1,0.1-0.1c0.1,0.2,0.3,0.4,0.4,0.7c-0.6-0.2-1.2-0.4-1.8-0.5
							C378.8,341.6,379.2,341.6,379.6,341.6z"/>
						<path fill="#FBEFB2" d="M354.5,377.8c-0.1,0.3-0.2,0.5-0.6,0.4c-0.1-0.1-0.2-0.3-0.3-0.5C353.9,377.7,354.2,377.8,354.5,377.8
							z"/>
						<path fill="#32613E" d="M360,326.3c-0.3-0.1-0.5-0.1-0.8-0.2c0.1-0.5,0.4-0.7,0.8-0.7C360,325.7,360,326,360,326.3z"/>
						<path fill="#32613E" d="M380.3,340.8c0-0.1,0-0.3,0-0.4c0.1-0.2,0.2-0.5,0.4-0.7C380.9,340.2,381,340.6,380.3,340.8z"/>
						<path fill="#3E3821" d="M381.2,334.5c-0.1-0.4-0.1-0.8-0.2-1.2C381.7,333.6,381.6,334,381.2,334.5z"/>
						<path fill="#3E3821" d="M361.4,354.3c-0.5,0.1-1,0.1-1.4,0.2C360.4,354,360.9,354.2,361.4,354.3z"/>
						<path fill="#3E3821" d="M362.6,329.9c0.4,0.1,0.8,0.2,1.2,0.3C363.3,330.6,362.9,330.6,362.6,329.9z"/>
						<path fill="#3E3821" d="M360.3,356.1c0.4,0.1,0.8,0.1,1.2,0.2C361,356.5,360.6,356.5,360.3,356.1z"/>
						<path fill="#32613E" d="M388.2,315c-0.4,0.1-0.8,0.1-1.2-0.1C387.4,314.6,387.8,314.6,388.2,315z"/>
						<path fill="#3E3821" d="M369.2,357.1c0.4-0.2,0.7-0.1,1,0.2C369.8,357.3,369.5,357.3,369.2,357.1z"/>
						<path fill="#888158" d="M362.8,337.1c-0.3,0.1-0.6,0.1-0.9,0.2C362.1,336.7,362.5,336.8,362.8,337.1z"/>
						<path fill="#32613E" d="M361.1,344.3c0.2,0,0.3,0,0.3,0.2C361.2,344.8,361.1,344.6,361.1,344.3z"/>
						<path fill="#32613E" d="M361,343.9c0.1,0.1,0.2,0.2,0.2,0.4C360.8,344.3,360.7,344.2,361,343.9z"/>
						<path fill="#3E3821" d="M365.3,356.7c0.1-0.1,0.3-0.1,0.4,0C365.6,356.7,365.5,356.7,365.3,356.7z"/>
						<path fill="#3E3821" d="M378.3,358.1c0.1-0.1,0.2-0.2,0.4-0.3C378.8,358.3,378.5,358.2,378.3,358.1z"/>
						<path fill="#3E3821" d="M359.9,357.2c-0.1,0.1-0.2,0.2-0.3,0.3C359.4,357.1,359.6,357.1,359.9,357.2z"/>
						<path fill="#32613E" d="M363.8,342.9l-0.2,0.1c0,0-0.2-0.1-0.2-0.1C363.5,342.7,363.7,342.7,363.8,342.9z"/>
						<path fill="#3E3821" d="M379,354.9c0-0.1,0.1-0.2,0.1-0.4c0,0.1,0,0.2,0,0.3C379.2,354.8,379.1,354.8,379,354.9z"/>
						<path fill="#32613E" d="M386.1,314.9c-0.1,0.2-0.2,0.3-0.4,0.5C385.7,315.1,385.8,315,386.1,314.9z"/>
						<path fill="#CEC69D" d="M382.7,318.8c-0.1-0.2-0.2-0.4-0.3-0.6C382.7,318.4,382.8,318.5,382.7,318.8z"/>
						<path fill="#3E3821" d="M369.4,352.2c-0.2,0-0.4,0-0.6,0C369,352.2,369.2,352.2,369.4,352.2z"/>
						<path fill="#3E3821" d="M377.8,356.2c-0.1,0-0.3,0-0.4,0C377.5,356.2,377.7,356.2,377.8,356.2z"/>
						<path fill="#3E3821" d="M361.6,351.5c-0.1,0-0.3,0-0.4-0.1C361.3,351.3,361.5,351.3,361.6,351.5z"/>
						<path fill="#32613E" d="M381.8,352.1c0.2,0.2,0.2,0.4-0.1,0.6C381.8,352.5,381.8,352.3,381.8,352.1z"/>
						<path fill="#888158" d="M366.6,337.5c-0.2,0.2-0.4,0.2-0.6-0.1C366.2,337.4,366.4,337.4,366.6,337.5z"/>
						<path fill="#3E3821" d="M373.6,331.4c0.2,0,0.3,0.1,0.5,0.1C374,331.5,373.8,331.5,373.6,331.4z"/>
						<path fill="#32613E" d="M358.3,349.7c-0.3,0-0.3-0.2-0.2-0.4c0.1,0,0.3,0.1,0.4,0.1C358.5,349.5,358.4,349.6,358.3,349.7z"/>
						<path fill="#3E3821" d="M360.8,359.1c0.1,0,0.3,0,0.4,0.1C361,359.3,360.9,359.3,360.8,359.1z"/>
						<path fill="#32613E" d="M371.3,328.1c0.2,0,0.4,0.1,0.6,0.1C371.7,328.1,371.5,328.1,371.3,328.1z"/>
						<path fill="#3E3821" d="M378.9,357c-0.1-0.1-0.1-0.3-0.2-0.4C378.9,356.6,379,356.7,378.9,357z"/>
						<path fill="#3E3821" d="M359.8,360.1c-0.1,0.1-0.3,0.2-0.4,0.3C359.5,360.3,359.6,360.2,359.8,360.1z"/>
						<path fill="#CEC69D" d="M381.2,329.1c0.1-0.2,0.3-0.4,0.4-0.6C381.7,328.9,381.6,329.1,381.2,329.1z"/>
						<path fill="#32613E" d="M360.2,326.9c-0.1-0.2-0.1-0.4-0.2-0.6C360.3,326.4,360.3,326.6,360.2,326.9z"/>
						<path fill="#3E3821" d="M380.5,337.8c0.1-0.1,0.2-0.2,0.3-0.3C381,337.9,380.8,337.9,380.5,337.8z"/>
						<path fill="#3E3821" d="M361.9,335.5c0.1,0.1,0.2,0.2,0.2,0.4C361.9,335.8,361.6,335.8,361.9,335.5z"/>
						<path fill="#B7AF8B" d="M355.8,377.8c-0.2,0.3-0.3,0.3-0.5,0C355.5,377.8,355.6,377.8,355.8,377.8z"/>
						<path fill="#32613E" d="M381.5,355c0.1,0.2,0.1,0.4,0.2,0.5C381.4,355.5,381.4,355.3,381.5,355z"/>
						<path fill="#3E3821" d="M381,336.6c-0.1-0.1-0.1-0.3-0.2-0.4C381,336.2,381.2,336.3,381,336.6z"/>
						<path fill="#3E3821" d="M362.6,334.1c-0.1,0.1-0.3,0.1-0.4,0.2C362.2,334,362.3,333.8,362.6,334.1z"/>
						<path fill="#F5EBB0" d="M258.2,459.4c-0.4,0-0.8,0-1.2,0c-0.6-1.7-1.5-1.8-2.6-0.4c-1,0.1-2,0-3-0.3c-0.4-1.6-1.9-1.5-3-1.7
							c-2.9-0.5-5.9-0.2-8.9-0.5c-9-1.1-18-2-27.1-2.9c-0.7-0.1-1.7-0.6-2.1,0.6c-1.9,0-3.7-0.2-5.5-0.5c0-0.5-0.2-0.9-0.8-1
							c-0.6-0.1-0.8,0.3-0.9,0.8c-2.3-0.1-4.5-0.2-6.8-0.8c0.1-0.3,0.4-0.6,0.4-1c0-6.3,1.2-12.6,1.8-18.8c0.1-1.2,0.9-2.2,2-2
							c2.3,0.5,4.6,0.4,6.8,0.7c25.6,2.7,51.1,5.5,76.7,8.2c1.6,0.2,2,0.7,1.8,2.3c-0.7,5.6-1.3,11.3-1.8,17c-0.2,1.7-0.7,2.2-2.5,2
							c-7.1-0.9-14.1-1.6-21.2-2.3C259.6,458.7,258.6,458.1,258.2,459.4z M226.6,438.4c0-1.6-1.3-1.3-2.3-1.5c-0.3,0-0.6,0-0.9,0
							c-2.6,0.4-5-1.2-7.6-0.8c-1.2,0.2-2.4-0.8-3.6,0c-0.1-0.2-0.3-0.4-0.4-0.7c-0.3-0.8-0.9-0.7-1.5-0.4c-0.9,0.5-0.6,1.5-0.6,2.3
							c-0.9,0.8-1,1.7-0.2,2.7c-0.2,0.8-0.5,1.5,0.8,1.6c0.8,0.1,1.6,0.4,2.4,0.7c0.9,1.1,1.8,1.6,2.2-0.3c1.2-0.3,2.1,0.7,3.2,0.7
							c3.4,0,6.6,1.4,10,0.9c0.5-0.1,1,0,1.3,0.5c0.3,0.4,0.9,0.7,1,0.3c0.2-0.9,2-0.9,1.3-2.3c0.1-0.5,0-1-0.6-1.1
							c-0.7-0.1-1,0.3-1.1,0.9c-0.3,0-0.5-0.1-0.8-0.1c0-0.9-0.6-1.1-1.3-1.2c-0.7-0.1-1.5,0-1.5-1c0.4-0.2,1.2-0.1,1.1-0.9
							C227.6,438.3,227,438.4,226.6,438.4z M248.8,438.7c-0.1-0.7-0.6-0.7-1.1-0.4c-0.3,0-0.6,0-0.9-0.1c-0.5-0.4-1.1-0.5-1.6-0.2
							c-0.3,0-0.6-0.1-0.9-0.1c-1.4-0.5-2.8-0.8-4.2-0.3c-1.2,2.4-1,5.1-1.4,7.6c-0.2,1.3,0.3,1.8,1.6,1.8c1.8,0.1,3.7,0.4,5.5,0.6
							c1.9,0.7,2.9,0.1,2.9-2c0-1.3,0.3-2.6,0.4-4C249,440.6,249.7,439.6,248.8,438.7L248.8,438.7z M275.3,456.1
							c-1.8-0.4-3.6,0.2-5.4-0.1c1.7,1,3.4,1.4,5.4,0.9c0.8,0.7,2,0.5,2.9,1c0.5,0.3,1.1,0.2,1.3-0.6c0.1-0.8-0.5-0.9-1-1
							C277.3,456.3,276.3,456.2,275.3,456.1z M258,455.7c1.7,0.1,3.4,0.2,5.1,0.4c0.3,0,0.8,0.1,0.9-0.3c0.2-0.5-0.1-0.8-0.6-1
							c-0.6-0.2-1.2-0.3-1.9,0c-1-0.4-2.2,0.2-3.1-0.5c-0.9-0.3-2-0.3-2.1,0.8C256.3,456.2,257.3,455.7,258,455.7z M205.6,448.5
							c0.1,0,0.2,0.1,0.3,0.1c1.3,1.6,3.5,1.8,5.1,1.9c1.4,0.1,2.9-0.3,4.3,0c5.6,1,11.4,0.2,16.8,2.5c0.1,0.1,0.4-0.1,0.6-0.2
							c1.5-1.1,1.3-2.9-0.4-3.6c-0.6-0.3-1.2-0.5-1.8-0.5c-7.7-0.9-15.4-1.7-23-2.5c-1-0.1-1.9,0.1-2.5,0.9
							C204.4,447.7,204.5,448.3,205.6,448.5z M204.2,438.7C204.2,438.7,204.2,438.7,204.2,438.7c0.1-1.2,0.3-2.4,0.4-3.6
							c0.1-1.2-0.6-1.5-1.4-0.9c-0.8,0.6-1.7,1.2-2.6,1.8c-0.4,0.3-0.5,0.7-0.3,1.1c0.2,0.4,0.6,0.3,0.9,0.1
							c1.1-0.6,1.2,0.1,1.1,0.9c-0.1,1.2-0.2,2.4-0.4,3.6c-0.1,0.7-0.2,1.3,0.7,1.5c1,0.1,1.1-0.6,1.2-1.3
							C204,440.8,204.1,439.7,204.2,438.7z M280.8,447.4c-0.1-0.4-0.2-0.5-0.2-0.6c-4.2-2.2-9-1.6-13.4-2.3c-0.4-0.1-0.8,1-1.4,0.1
							c-0.2-0.3-0.6-0.5-0.9-0.2c-0.2,0.2-0.2,0.6-0.2,1c0,0.7,0.5,0.5,0.9,0.5c1.5-0.2,2.8,1.2,4.6,0.1c0.5-0.3,2.4,0.7,3.7,0.8
							C276.2,446.8,278.4,447.6,280.8,447.4z M260.6,451.8c0.7,0.4,1.6,0.8,1.8-0.2c0.2-1.1-0.9-0.9-1.6-1.2
							c-1.2-0.6-2.5,0.2-3.6-0.5c-0.4-0.2-0.8,0-0.9,0.5c-0.1,0.6,0.2,1.2,0.7,1C258.3,451,259.3,452.8,260.6,451.8z M268.5,452.4
							c2.3,1.2,4.4,0.4,6.3,0.9c0.3,0.1,0.6-0.2,0.7-0.6c0.1-0.4-0.1-0.8-0.4-0.9c-1-0.1-2.5-0.6-3.1-0.1
							C270.9,452.7,269.9,452.4,268.5,452.4z M270.8,442.2c0.8,0.4,1.6,0.5,1.9-0.6c0.1-0.5-0.5-0.7-0.9-0.5
							c-0.9,0.3-2.2-0.9-2.6,0.4C268.9,442.7,270.1,442.2,270.8,442.2z M259,448.3c-0.1,1.4,0.9,0.2,1.3,0.7c0.5,0.6,0.7,0,0.7-0.4
							c0.1-0.8-0.4-0.9-1.1-1C259.4,447.5,259.1,447.7,259,448.3z M278.1,452.1c-0.7,0.4-1.5,0.6-1.5,1.4c0,0.3,0.3,0.5,0.6,0.4
							C277.9,453.7,278,453,278.1,452.1z M203.5,432.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2
							C203.4,433.1,203.4,432.9,203.5,432.8z M275.8,442.5c0-0.1,0-0.1-0.1-0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.2
							C275.7,442.7,275.7,442.6,275.8,442.5z"/>
						<path fill="#E9E4B6" d="M258.2,459.4c0.4-1.3,1.4-0.7,2.1-0.7c7.1,0.7,14.1,1.4,21.2,2.3c1.8,0.2,2.3-0.3,2.5-2
							c0.5-5.7,1.1-11.3,1.8-17c0.2-1.6-0.2-2.1-1.8-2.3c-25.6-2.7-51.1-5.5-76.7-8.2c-2.3-0.2-4.6-0.1-6.8-0.7
							c-1.1-0.3-1.9,0.7-2,2c-0.5,6.3-1.7,12.5-1.8,18.8c0,0.3-0.3,0.6-0.4,1c-0.8,0-0.8-0.6-0.7-1.2c0.8-7.3,1.6-14.5,2.4-21.8
							c2.9,0.3,5.9,0.6,8.8,0.9c0.6,0.1,1.3,0.5,1.7-0.4c-0.8-0.6-1.6-0.5-2.5-0.5c0-0.1,0.1-0.1,0-0.2c1.2-0.8,2.3-1.4,3.8-0.7
							c0.8,0.4,1.7,0.2,1.9-0.9c0.5-0.3,1.6-0.5,0.4-1.3c-0.3-0.2-1,0.1-1-0.7c0.1-0.9,0.7-0.6,1.3-0.6c0.5,0,0.5-0.4,0.5-0.8
							c5.9,0.6,11.8,1.2,17.7,1.9c0.7,0.1,1.5,0,1.9,0.7c0,0.9,0.2,1.8,0.8,2.7c0.7-0.5,0.6-1.2,0.9-1.7c0.2,0.3,0.5,0.6,0.7,1
							c0.1,0.9,0.6,1.3,1.5,1.3c0.6,0.9,4.2,1.9,5.4,1.4c0.3-0.1,0.5-0.6,0.8-0.9c0.7,0.2,2-0.2,1.7-0.3c-1.6-0.9,0.3-1.4,0-2.1
							c0.2-0.3,0.5-0.9,0.7-0.9c0.5,0,0.5,0.7,0.6,1.1c-0.3,1.4-0.3,1.5,1.2,2.3c0.1,0.1,0.5,0.1,0.5,0.1c0.4-0.8,0.5-1.6-0.1-2.3
							c0.2-0.9,1.1-1.3,1.6-1c1.4,0.8,3.1,0.7,4.4,1.7c-0.3,0.5-1.7-0.4-1.3,0.9c0.1,0.6-0.5,1.4,0.6,1.7c0.7,0.2,1.2,0.5,1.4-0.5
							c0.4,0.3,0.8,0.6,1.2,0.8c0.7,0.4,1.2,0.1,1.6-0.5c0.3-0.5,0.3-1.1-0.1-1.5c-0.5-0.5-1.2-0.6-1.7-0.3
							c-0.4,0.2-0.6,0.7-0.9,1.1c-0.7-0.5,0.3-1.3-0.3-1.8c1.7,0.1,3.4-1.3,5.1,0.1c-0.3,0.9-1.7,1.6-1,2.7c0.5,0.7,1.6,0.6,2.4,0.1
							c0.2,0,0.3,0,0.5,0.1c0.6,0.9,1.3,1.8,2.2,0.2c0.5-0.2,0.8-1.5,1.4-0.2c0.1,0.3-0.4,1,0.3,1.2c0.9,0.1,0.7-0.7,0.9-1.2
							c0.2-0.4,0.4-0.8,0.6-1.1c0.1,0,0.2,0,0.4,0.1c-0.3,2.4,0.1,3,1.8,2.4c1.1-0.4,0.7-1.4,0.8-2.1c0.5,0,0.8-0.2,0.9-0.8
							c0-0.1,0-0.3,0-0.4c1.7,0.4,3.5,1.3,5.3,0.3c0.8-0.5,1.1,0,1.5,0.5c-0.9,1.1-0.5,2.4-0.8,3.6c-0.1,0.6,0.2,0.8,0.7,1.1
							c2.8,1.3,6,0.5,8.9,1.3c0.8,0.2,0.9-0.7,1-1.3c0.4-3.6,0.8-7.2,1.2-10.8c0.1-0.6-0.2-0.9-0.7-1c0.9-2.5,0.9-2.5-1.8-2.8
							c-24.6-2.7-49.2-5.3-73.9-7.9c-0.6-0.1-1.3-0.1-1.9-0.1c-0.4,0-0.6,0.3-0.7,0.7c0,0.3,0,0.7,0.3,0.7c2.3,0.3,4.2,2.2,6.7,1.6
							c0.6-0.1,1.1-0.5,1.8-0.3c4.2,1.4,8.6,1.5,13,2c-0.2,0.5-0.4,1-0.6,1.5c-0.3-0.1-0.6-0.1-0.8-0.2c-0.6-0.1-1.1-0.3-1.7-0.4
							c-0.7-0.7-1.1-0.4-1.3,0.4c-2-0.9-2.4,0.2-2.4,1.9c-0.4-0.4-0.8-0.3-1.2-0.1c-0.1-0.2-0.3-0.4-0.4-0.5c0-0.7-0.5-0.8-1-1
							c0.2-0.3,0.3-0.7-0.2-0.8c-0.5-0.1-1-0.3-1.4,0.2c-0.6,0.9-0.6,1.9,0,2.8c-0.6,0.2-1.1,0.3-1.5-0.4c0-1.1,0.7-2.2-0.1-3.2
							c-0.1-0.3-0.2-0.7-0.3-1c0,0,0.1-0.1,0.1-0.1c0,0.1-0.1,0.1-0.1,0.2c-0.9-0.1-1.9-0.3-2.3,0.9c-0.1,0.3-0.3,0.6-0.3,0.8
							c0.3,0.6-1.5,3.5,1.5,1.6c0.3-0.2,0.5,0.5,0.6,0.9c-0.5,1.2-1.5,1.1-2.5,1c-2.3-0.4-4.4-0.2-6.8,0.8c1.2,0.5,2.2,0.9,3.1,1.2
							c-0.7,0.6-1,1.4-1.1,2.3c-2.3-0.3-4.5-3.1-4.3-5.4c0.2-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.3,1-0.5c0.5,0.1,1.1,0.2,1.6,0.3
							c0.3,0.3,0.3,0.9,0.8,0.9c0.6-0.1,0.5-0.6,0.4-1c-0.3-0.9-0.2-1.8-0.1-2.7c0.2-1,0-1.6-1.2-1.7c-1.1-0.1-2-0.2-2.1,1.3
							c-0.1,0.6,0,1.3-0.6,1.8c-1.5-0.8-0.7-1.9-0.3-2.8c0.7-1.3,1.4-2.5-0.2-3.6c-0.2-0.1,0-0.7-0.1-1c1,0.1,2,0.1,3,0.2
							c14.6,1.5,29.2,2.9,43.8,4.7c11.6,1.4,23.3,2.5,34.9,3.7c1.5,0.2,1.9,0.5,1.7,2.1c-0.6,4.8-1.1,9.6-1.5,14.4
							c-0.1,1.7-0.7,2.1-2.4,1.9c-18-2-36.1-3.9-54.2-5.8c-6.8-0.7-13.5-1.4-20.3-2.1c-0.6-0.1-1.3-0.3-1.9,0.2
							c0.1,0.8,0.6,0.7,1,0.7c12.4,1.3,24.7,2.5,37.1,3.8c0,0,0.1,0.1,0.1,0.1l0.2,0c11,1.6,22.1,2.5,33.2,3.7
							c1.8,0.2,3.7,0.4,5.5,0.6c1,0.1,1.3,0.5,1.2,1.5c-0.7,6.8-1.4,13.5-2.1,20.3c-0.1,1-0.6,1.3-1.5,1.2
							C275,461.2,266.6,460.3,258.2,459.4z M222,428.6c0.2-0.4,0.5-0.9,0.6-1.3c0.1-0.6-0.4-1-1-1.2c-0.6-0.1-1.2-0.2-1.4,0.5
							c-0.3,0.8-0.5,1.7-0.1,2.5c0.4,0.7,0.8-0.2,1.2-0.2c0.2,0.5,0.6,0.8,1.1,0.9c3.1,0.3,6.2,0.7,9.3,1c0.3,0,0.9,0.1,0.8-0.4
							c-0.1-0.3-0.6-0.5-0.9-0.7c0.1-0.9,1-2.1-0.7-2.5c-1.5-0.4-1.2,1-1.5,1.7c-0.2,0-0.4,0-0.6-0.1c-0.3-0.6,0.7-1.5-1-1.7
							c-1.5-0.1-1.4,0.6-1.6,1.4c-0.2,0-0.3,0-0.5-0.1c0-0.9,0-2-1.2-2c-1.3,0-1,1.2-1.3,2c0,0.1,0,0.3,0,0.4
							C222.7,429.3,222.3,429.3,222,428.6z M214.2,418.9c0-1-0.5-1.6-1.5-1.7c-1,0-1.9,0.4-2,1.5c-0.1,1,0.2,2.1,1.4,2.2
							C213.3,421,214,420.1,214.2,418.9z M216.5,427.4c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.9-0.6-1.3-1.5-1.1c-0.6,0.1-1.1,0.4-1,1.1
							c0,0.8,0.5,1.4,1.3,1.4c1,0.1,2,0,3,0.1c0.5,0,0.8-0.1,0.8-0.7c-0.1-0.5,0.4-1.1-0.4-1.4C217,425.4,217.6,427.1,216.5,427.4z
							 M274.1,434.4c0.7,0.1,0.9-0.4,1.1-1.1c0.2-1-0.4-1.5-1.2-1.6c-0.9-0.2-1,0.7-1.1,1.2C272.7,433.6,273,434.3,274.1,434.4z
							 M251.1,430.6c0-0.7-0.4-1.4-1.1-1.5c-0.7-0.2-1.2,0.3-1.4,1c-0.2,0.8,0.2,1.4,1.1,1.5C250.4,431.6,250.9,431.3,251.1,430.6z
							 M270.4,434.1c0.4-0.4,1.5,0,1.5-1.2c0-0.5,0.4-1.2-0.7-1.1c-0.8,0.1-1.5,0.2-1.6,1.2C269.6,433.4,269.5,434,270.4,434.1z
							 M207.6,421.6c-0.1,0.3-0.2,0.5-0.2,0.7c0,0.1,0.1,0.2,0.1,0.4c0.3-0.1,0.3-0.4,0.3-0.7C207.8,421.9,207.7,421.8,207.6,421.6z
							"/>
						<path fill="#F5F0BC" d="M203.8,454.8c9,0.9,18.1,1.9,27.1,2.8c3.1,1.3,6.5,0.9,9.7,1.5c0.6,0.1,1.3,0.4,1.8-0.3
							c0.6-0.1,1.1-0.1,1.7,0.1c0.1,0.4,0.3,0.5,0.5,0.1c5.9,0.7,11.8,1.4,17.8,2c0,0.3-0.1,0.5-0.1,0.8c-1.3,0.5-2.9-0.8-4.1,0.7
							c0.7,0.3,1.3,0.7,2,0.8c1.6,0.1,3.2,1.2,4.7-0.3c1.2,1.1,5.5,1.3,6.9,0.2c-0.4-0.6-1-0.4-1.5-0.3c-1.1,0.3-1.2-0.4-1.1-1.2
							c1.1,0.1,2.3,0.2,3.4,0.3c0,0.5,0.1,0.9,0.7,0.9c0,0.6-0.1,1.3,0.8,1.4c0.4,0,0.9,0.1,1.3,0.1c0.6,0.1,0.9-0.2,1-0.7
							c0.7-0.4,1.5-0.7,2.2-1.1c1.7,0.2,3.4,0.4,5.1,0.5c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.2
							c-1.1,6.2-1.5,12.4-2.2,18.6c-0.1,1-0.2,1.7-1.6,1.5c-16.8-1.7-33.6-3.5-50.4-5.6c-0.1-0.3-0.3-0.3-0.4,0
							c-11.8-1.2-23.5-2.4-35.3-3.6c-0.8-0.1-1.8,0-1.7-1.3c0.7-5.8,1-11.6,2.1-17.3c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.2,0-0.3
							c0.1-0.6,0.1-1.1,0.2-1.7c0.3-0.1,0.5-0.2,0.8-0.3c2.4,0.3,4.8,0.5,7.2,0.8C203.5,455,203.7,455,203.8,454.8z M262.4,464.9
							c-2.4-0.2-4.8-0.5-7.2-0.7c-0.6-1.7-2.2-1.9-3.5-2.1c-1.7-0.2-3.3-0.4-4.8-1.2c-0.7-0.4-1.9-0.9-2.5,0.5
							c-0.6-0.1-1.1-0.1-1.7-0.2c0-1.1-0.9-0.9-1.6-1c-7.7-0.9-15.4-1.5-23.1-2.9c-1.8-0.3-3.7,0-6,0c1.1,0.9,1.9,0.8,2.6,0.9
							c5.5,0.6,11,1.2,16.5,1.8c2.8,0.3,2.8,0.3,2,2.8c-0.2,0.8-0.2,1.7-0.3,2.5c-0.1,0.5,0.2,1.2-0.6,1.4c-1.5-2.7-1.5-2.7-4.5-3.7
							c-0.4-0.1-0.5-0.4-0.6-0.8c0.6-0.4,1.8-0.7,1.6-1.4c-0.2-0.9-1.3-0.6-2.1-0.7c-2-0.1-4-0.5-6-0.7c-0.3,0-0.6,0-0.9,0
							c-1.3,0.4-2.4-0.4-3.7-0.4c-2.2-0.1-4.6,0.3-6.5-1.4c-0.5-0.4-1.1-0.3-1.5,0.1c-0.6,0.4-0.7,1.1-0.6,1.7
							c0.1,0.8,0.8,0.5,1.3,0.6c2.9,0.4,5.8,0.7,8.6,1c-0.1,0.7-0.6,0.8-1.2,0.8c-4.2-0.5-8.5-0.9-12.7-1.3
							c-0.1-2.9-2.3-4.4-4.6-3.1c-0.7,0.4-1.6,1-1.1,1.8c0.5,0.9,1.3,0.5,1.9-0.2c0.5-0.6,1.1-0.6,1.7-0.1c0.5,0.6,0.4,1.2-0.1,1.7
							c-0.7,0.8-1.5,1.5-2.4,2c-0.8,0.4-1.3,1-1.7,1.8c-0.5,1-0.3,1.6,0.9,1.6c1.6,0.1,3.1,0.3,4.7,0.4c0.2,0.7,0.4,1.1-0.7,1.3
							c-1.6,0.2-3,0.2-4.5-0.7c-1.6-1-2.2-0.5-2.1,1.2c0.1,0.7-0.1,1.1-0.5,1.7c-1.5,2.3-0.7,4.1,2,4.4c8.2,0.9,16.4,1.8,24.5,2.6
							c0.6,0.1,1.2,0.3,1.7,0.1c1.8-0.7,3.5-0.8,5.2,0.4c0.5,0.3,0.6-0.3,0.8-0.7l0.1,0.1c2,1.2,4.3,1.4,6.5,1.7
							c2.7,0.3,5.4,0.9,8.3,0.3c2.1-0.5,4.5,1.1,6.6,1.2c5.6,0.3,11.2,1.5,16.8,1.8c4.1,0.2,8.1,0.8,12.1,1.6
							c1.6,0.3,2.1-0.3,2.2-1.8c0.5-5.1,1.1-10.2,1.6-15.3c0.1-0.5,0.3-1.2-0.2-1.6c-1.5-1.3-4.5-0.2-4.9,1.8
							c-2.4,0-4.8-0.3-7.3-0.6c-0.8-0.1-2.5-0.6-2.6,1.2c-0.6,0.6-1.2,0.6-1.8-0.1c0.3-0.6,1.2-1.5-0.2-1.7
							C265,464.8,263.7,464.3,262.4,464.9z"/>
						<path fill="#F5F0BC" d="M246.5,504.2c-6.5-0.7-12.9-1.5-19.4-2.2c0-0.1,0-0.2,0-0.3c-0.6-1.5-0.5-2.9-0.5-4.5
							c0-2.3,1.3-4.1,1.7-6.2c0.4-2.1,1.4-2.1,2.9-0.5c0.4,0.5,0.9,0.9,1.2,1.5c-0.4,0.1-0.9-0.1-1.3,0.3c-0.4,0-0.9,0-1.3-0.1
							c-0.1,0-0.3,0-0.4-0.1c-0.7,0.5-0.6,1.1-0.2,1.7l0.1,0c-0.1,0.3-0.2,0.6-0.3,0.8c-0.7,1.9-0.6,4-0.8,6
							c0.6,0.9,1.5,0.8,2.5,0.7c0.8,0.5,1.6,0.8,2.5,0.3c0.7,0,1.4,0.1,2.1,0.1c3.5,0.7,7.2,0.3,10.6,1.5c0.3,0.1,0.8-0.4,1.3-0.6
							c0.3-0.1,0.3-0.2,0.1-0.4c0.1-0.4,0.1-0.9,0.2-1.3c0.5-0.4,0.6-0.8,0.2-1.3c0.1-1.7,0.3-3.3,0.4-5c0.1-0.3,0-0.5-0.3-0.6
							c-0.7-0.1-1.3-0.2-2-0.3c-0.7-0.5-1.5-0.6-2.3-0.3c-0.2,0-0.4,0-0.7-0.1c-0.2-0.2-0.4-0.3-0.6-0.1c-0.4,0-0.7-0.1-1.1-0.1
							c-1-0.5-2.1-0.6-3.1-0.3c-0.4,0-0.7-0.1-1.1-0.1c-1.1-0.6-2.3-0.3-3.4-0.6c0-1.1,0-2.1-0.3-3.1c-0.3-1-0.7-2.2-0.2-3.3
							c0.8-1.8,0.6-3.6,0-5.3c0.1-0.1,0.1-0.3,0.1-0.4c0.8,0,1.7,0.1,2.5,0.1c0.2,0,0.3,0,0.5,0c2.4,0.2,4.8,0.5,7.1,0.7
							c0.8,0.4,1.7,0.4,2.6,0.3c0.4,0.1,0.8,0.1,1.2,0.2c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0.1,0.4,0.2c0.3,0.5,0.8,0.5,1.2,0.5
							c-0.4,1.2-1.3,0.7-2.1,0.6c-0.7-0.1-1.5-0.4-2.2-0.6c-0.2,0-0.3,0-0.5,0c-0.1-0.1-0.2-0.1-0.4-0.1c-2.5-1-5.2-0.7-7.8-1.1
							c-0.3,0-0.6,0.2-0.7,0.6c0,0.2-0.1,0.3-0.1,0.5c-0.5,0.8-0.6,1.7-0.2,2.5c-0.1,0.9-0.2,1.7-0.3,2.6c-0.1,0-0.2,0.1-0.2,0.1
							c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.1,0.6-0.1,0.8c-0.5,0.5-0.7,1.1,0,1.7c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.5,0.8,0.5,1.3,0.2
							c0.3,0,0.5,0.1,0.8,0.1c0.4,0.7,0.9,0.2,1.4,0.1c0.8,0.1,1.7,0.2,2.5,0.3c0.2,0.2,0.3,0.2,0.5,0c0.3,0,0.5,0.1,0.8,0.1
							c0.5,0.5,1.1,0.3,1.7,0.3c0.9,1.3,2.2,1.2,3.6,1.1c0.6-0.1,1.7-0.6,1.7,0.5c0,1.6,1.2,2.7,1.5,4.3c-0.1-1-0.2-2,0.9-2.3
							c0,0.6,0.2,0.9,0.8,0.9c0.6,1.1,2.2,2.4-0.6,2.9c-0.3,0-0.6,0.6-0.5,1.1c0.1,0.5,0.5,0.8,0.9,0.7c1.5-0.3,3,0.9,4.5,0.3
							c0.6-0.2,0.8,0.4,1,0.8c-0.1,0.8,0.1,1.6,0.4,2.4c0.5-0.4,0.8-0.7,1.1-1c0.9,2,2.2,0.4,3.4,0.4c0.6-0.4,0.7-0.8,0.1-1.3
							c0-0.1,0-0.3,0-0.4c0.7-1,1.2-1.9-0.6-2.2c0.2-0.8-0.9-0.8-0.9-1.5c1-0.6,2.2,0.4,3.3-0.3c0.2,0,0.3,0,0.5,0l0.2,0l0.2,0
							c0.4,0,0.9,0.1,1.3,0.1c0.1,0,0.2,0,0.3,0.1c0.4,0,0.9,0,1.3,0.1c0.3,0.8,0.7,1.2,1.6,0.6c0.4-0.3,0.8-0.3,1.1,0.2
							c0.4,0.8-0.4,0.7-0.7,0.9c-1.8,1.2-3.7,0-5.5,0.1c0-0.4,0-0.8-0.4-1.1c0,0-0.2,0.1-0.2,0.1c-0.1,0.5,0.3,0.7,0.6,1
							c-0.2,1.4-0.2,2.2,1.8,2.1c2.1-0.1,4.4,0.1,6.4,0.7c1.7,0.5,2.6,0.3,3.1-1.7c0.6,1.1,0.8,1.9,0.7,2.7c-0.1,0-0.3,0-0.4,0
							c-0.3,0-0.6-0.1-0.8-0.1c-0.4,0-0.8-0.1-1.3-0.1c-0.9-0.3-1.6,0.1-2.3,0.6c-0.4-0.1-0.8-0.1-1.2-0.2c-0.1,0-0.2,0-0.3,0
							c-0.5-0.8-1.1-1.5-1.9-0.2c-0.1,0-0.2,0-0.3,0c-0.3-0.5-0.8-1.3-1.4-0.7c-0.5,0.5-0.7,1.4,0.3,1.9c0.4,1.6,1.8,1.2,2.6,1
							c1.6-0.2,3.6,1,4.7-1.1c0.1,0,0.3,0,0.4,0c0.5,2.3,2.5,0.8,3.7,1.4c0.6,0.3,0.5-1.2,0.6-1.9c0.4-3.4,0.7-6.7,1.1-10.1
							c0.1-1,0.1-1.7-1.2-1.8c0.1-2.7-2-0.7-2.8-1.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.3-0.4,0.4
							c-1.6-2.9,2.5-2,2.3-3.9c0.6,2,0.6,1.9,2.2,1.6c1.6-0.3,0-1.6,0.8-2.3c0.5-0.4-0.1-0.8-0.6-0.9c-0.5-0.1-1.2,0-1.5-0.6
							c0.3,0,0.7,0,0.9-0.3c0.7,0.1,1.4,0.1,2.1,0.2c-0.1,0.8-0.3,1.7-0.2,2.5c0,1.4-0.9,2.7,0.1,4.1c-0.5,4.6-0.9,9.1-1.4,13.7
							c-0.3,0.4-0.4,0.9-0.2,1.4c-0.5,0.9-1.4,0.5-2.1,0.4c-3.6-0.3-7.3-0.7-10.9-1c-0.9-0.1-1.7-0.2-2.6-0.3
							c-0.3,0-0.5-0.1-0.8-0.1c-0.9-0.1-1.7-0.2-2.6-0.3c-0.7-0.1-1.4-0.2-2.1-0.3c-0.2-0.4-0.5-0.4-0.8-0.2
							c-1.6-0.2-3.1-0.3-4.7-0.5C251.1,504.3,248.8,504,246.5,504.2z M254.8,501.7c-0.3,0-1.2-0.9-1.6,0.5c-0.1,0.4-0.2,1,0.3,1.1
							c1,0.2,2,0,3.1,0.3c0.4,0.1,0.5-0.6,0.4-1C256.9,501.1,255.9,501.9,254.8,501.7z M265.8,504.6c0.1-1.3-0.1-2-0.9-1.9
							c-0.9,0-0.9,1-1.1,1.6c-0.2,0.6,0.4,0.8,0.9,0.9C265.5,505.2,265.8,504.7,265.8,504.6z M252.7,502.4c0.1-0.6,0-1.1-0.8-1.2
							c-0.7-0.1-1,0.4-1.2,1c-0.2,0.6,0.2,0.9,0.8,1C252.2,503.3,252.6,503.1,252.7,502.4z"/>
						<path fill="#F5F0BC" d="M230.3,479.8c0.7,0.1,1.4,0.2,2.1,0.3c0,0.1-0.1,0.3,0,0.4c-0.6,0.1-1.1,0.2-1.7,0.2
							c-1.6-0.1-1.5,1.1-0.9,1.7c1.7,1.7,0.2,2.4-0.6,3.5c-1,1.4-2.2,1.3-3.2,0.4c-1-0.9-1.7-1.3-2.9-0.4c-0.4,0.3-1.3,0.6-1.4-0.2
							c-0.1-1.3-1.3-0.8-1.7-1.4c0-0.1,0-0.3,0-0.4c1.7,0.1,3.3,0.2,5,0.4c0.8,0.5,1.5-0.1,2.3,0c0.6,0.5,1.4,0.9,1.8,0.1
							c0.5-1-0.9-0.8-1.2-1.4c-0.7-0.9-1.6-1.1-2.6-0.5c-0.3-0.1-0.5-0.1-0.8-0.2c-0.6-0.1-1.1-0.1-1.7-0.2
							c-2.2-0.2-4.5-0.4-6.7-0.6c-1.2,0-2.3-0.9-3.5-0.3c-2.1-0.5-4.4,0.3-6.2-1.5c-0.3-0.3-1.3-0.2-1.2,0.1
							c0.6,1.1-0.7,1.9-0.4,2.9c-1,0.2-2.6,0.2-2.8,0.7c-0.4,0.9,0.3,2.1,1.1,2.9c0.1,0.1,0.3,0.1,0.4,0.1c0,0.2,0.1,0.4,0.1,0.6
							c0.1,0,0.1-0.1,0.2-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c0.1-0.2,0.2-0.5,0.3-0.5c0.7,0.1,0.5,0.7,0.6,1.2c-0.1,0.6-0.1,1.2-0.2,1.9
							c-2-0.1-4,1.8-6.1,0c-2.5,2.2-3,0-3.8-1.7c-0.1-0.3-0.5-0.5-0.7-0.7c0.1-0.7-0.1-1.7,1.2-1.5c-0.6,1.6,0.6,2.5,1.6,2.9
							c1.2,0.6,2.7,0.3,3.7-0.8c0.9-0.9,1.1-2,0.5-3.1c-0.3-0.5-0.9-0.9-0.2-1.6c0.9-1,0.7-2.1-0.2-3c-1-0.9-2.2-1-3.4-0.4
							c-0.6,0.3-1.5,0.7-1.1,1.5c0.4,1,1.1,0.4,1.7,0c0.5-0.4,1-0.5,1.4,0.1c0.4,0.7,0.1,1.2-0.5,1.5c-1.6,0.6-2,1.2,0,1.9
							c0.8,0.3,1.1,1,0.6,1.7c-0.5,0.7-1.3,1.1-1.9,0.2c-0.6-0.9-1.4-0.8-2.2-1c0.9-1.3,1.3-2.4-0.1-3.8c-0.8-0.8-0.8-2.2,0.4-3.2
							c0.4-0.4,0.7-0.9,0.8-1.4c0.3-0.1,0.6-0.2,0.9-0.3c1,0.1,2,0.2,3,0.3c3.3,0.9,6.7,1.3,10.2,1.1c2.1,0.2,4.2,0.5,6.4,0.7
							c1.3,0.8,2.8,0.9,4.2,0.4c2.7,0.2,5.4,0.5,8,0.7C229.1,479.9,229.7,480,230.3,479.8z M202.3,481.5c0.1,0.1,0.1,0.3,0.2,0.4
							c0.2,0.1,0.4,0,0.4-0.2c0-0.2-0.1-0.4-0.2-0.5C202.5,481,202.4,481.2,202.3,481.5z"/>
						<path fill="#F5F0BC" d="M263.4,483.5c0.4,0.1,0.8,0.1,1.2,0.2c0,0.1-0.1,0.3,0,0.4c0.1,0.7-0.9,1.6-0.2,1.9
							c1.6,0.9,1.2,3,2.6,3.9l0,0c-1-0.4-2,0.8-3,0.1c-0.2-0.1-0.7,0-0.8,0.5c-0.1,0.6,0.1,1,0.6,1.2c2,0.7,4,0.8,6-0.1l0,0
							c0,0.9,0.5,1.1,1.3,0.7c1.4-0.8,0.4-1.6-0.2-2.7c1.1,0.1,1.8,0.2,2.4,0.3c0.9,0.1,1,0.7,0.7,1.4c-0.2,0.4-1.2,0.8-0.6,1.3
							c0.5,0.4,1.3,0.1,1.9-0.4c1.3-0.5,2,1.3,3.3,0.7c0.1,0.7-0.9,0.9-0.8,1.4c1,2.6-0.8,1.9-2.1,1.8c0.2-1.2-0.4-1.4-1.5-1.2
							c-0.7,0.1-1.4,0.7-2.2,0.1c-1.6-1.2-3.4-1-5.2-0.8c-4.4-0.4-8.8-0.9-13.2-1.3c-0.7-0.1-1.6-0.5-1.9,0.7
							c-0.6-0.7-1.7-1.3-1.5-2.3c0.3-2.3,1.9-4.7-0.6-6.9c1,0.9,4-0.1,2.5,2.8c1.4,0.7,0.2,1.4,0,2.1c-0.2,0.6,0,1.2,0.7,1.3
							c2.4,0.6,5,0.1,7.2,1.5c0.3,0.2,0.7-0.4,0.9-0.8c0.8,0.2,1.4-0.2,1.5-1c0-0.7-0.6-0.8-1.2-0.8c-0.9-0.2-2.1-1.7-2.6,1
							c-0.4-1.5-0.9-2.1-2.1-1.3c-0.6,0.4-1.4,0.3-1.8-0.5l0,0c1.2-0.8,2.2-1.6,2.2-3.3c0-0.9,0.9-0.1,1.4-0.2
							c0.3-0.1,0.6-0.1,0.6-0.4c0.1-0.4-0.1-0.7-0.5-0.8c-1.9-0.2-3.7-0.3-5.7-0.3c-0.8,0-2.3,0.2-2.9-1.1c0.2-0.1,0.3-0.2,0.5-0.3
							c2.7,0.3,5.4,0.6,8,0.9c0.5,0.7,1.1,0.7,1.7,0.2c0.4,0.1,0.8,0.1,1.3,0.2c0.3,0.5,0.5,0.6,0.9,0.1c0.3,0,0.5,0,0.8,0.1
							c0,0.1,0.1,0.2,0.1,0.3C263.1,483.7,263.3,483.6,263.4,483.5z M259.2,486c-0.4,0.1-0.8,0.2-1.2,0.2c-0.4,0.1-0.9,0.1-0.9,0.7
							c0,0.5,0.2,0.8,0.6,1c0.5,0.2,1,0.4,1.4,0C259.8,487.4,259.4,486.7,259.2,486c0.4-0.1,1.1,0.7,1.3-0.3c0.1-0.5,0-1.1-0.6-1.1
							C259,484.6,259.4,485.5,259.2,486z M264.6,487.9c0-0.6-0.2-0.9-0.9-0.9c-0.5,0-1.1-0.2-1.2,0.5c-0.1,0.7,0.4,0.9,1,1
							C264.1,488.6,264.6,488.5,264.6,487.9z M263.5,485.3c-0.1-0.5-0.3-0.9-0.8-1c-0.7-0.1-1.1,0.2-1.2,0.8c-0.1,0.5,0.1,1,0.7,1
							C262.8,486.2,263.3,486,263.5,485.3z M251.7,491.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0.2-0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2
							C251.5,491.4,251.6,491.2,251.7,491.1z"/>
						<path fill="#F5EBB0" d="M205.8,420.9c-0.2,2.3,2.1,5.1,4.3,5.4c0.2,1,0.9,1.3,1.8,1.6c-0.3,1.1-1.1,1.3-1.9,0.9
							c-1.5-0.7-2.6,0-3.8,0.7c-0.4,0-0.9-0.1-1.3-0.1c0.2-1.4-0.9-1.1-1.7-1.2c-0.8-0.1-1.6-0.3-1.7,0.9c-0.7-0.1-1.4-0.2-2.1-0.2
							c-0.1-0.3-0.3-0.5-0.3-0.8c0.5-4.9,1-9.7,1.5-14.6c0.1-1.1,0.8-1.4,1.8-1.2c1,0.1,1.7,0,1.9-1.2c0.4,0,0.8,0.1,1.3,0.1
							c0,0.3-0.1,0.9,0.1,1c1.6,1.2,0.9,2.3,0.2,3.6c-0.5,0.9-1.3,2,0.3,2.8c-0.1,0.3-0.3,0.7-0.4,1
							C205.3,420.1,205.4,420.5,205.8,420.9z"/>
						<path fill="#F5F0BC" d="M193.8,476c0.3,0,0.6,0.1,0.8,0.1c0,0.1,0,0.2,0,0.3c-0.8,1.7-1.9,3.4-0.6,5.4c0.1,0.2,0,0.8-0.2,1
							c-1.2,1-0.8,2.4-1.1,3.7c0.9,2.4,0.3,4.8,0.1,7.2c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,0.8,0.3,1.3,1.1,1.2c2.9-0.2,5.6,0.6,8.5,0.9
							c7.3,0.8,14.6,1.6,22,2.4c0.7,0.1,1.6-0.1,2.2,0.6c-0.1,0.1-0.1,0.2-0.1,0.3c-3.8-0.3-7.6-0.7-11.4-1
							c-6.2-0.7-12.4-1.3-18.7-2c-0.7-0.3-1.4-0.4-2.1-0.2c-0.6-0.1-1.1-0.1-1.7-0.2c-1.2-0.2-1.8-0.7-1.6-2.1
							c0.7-6.4,1.3-12.9,2-19.4c0-0.5-0.2-1.3,0.8-1.1C193.5,476.2,193.6,476.3,193.8,476z"/>
						<path fill="#F5F0BC" d="M210.3,454.2c0.4-1.3,1.4-0.7,2.1-0.6c9,0.9,18.1,1.8,27.1,2.9c3,0.4,5.9,0,8.9,0.5
							c1.1,0.2,2.6,0.1,3,1.7c-12.9-1.4-25.9-2.8-38.8-4.2C211.9,454.5,211.1,454.3,210.3,454.2z"/>
						<path fill="#E9E4B6" d="M204.3,411c-0.2,1.1-0.9,1.3-1.9,1.2c-1-0.1-1.7,0.1-1.8,1.2c-0.5,4.9-1,9.7-1.5,14.6
							c0,0.2,0.2,0.5,0.3,0.8c-0.5,0.2-0.9,0-1.3-0.3c0.4-4.4,0.8-8.7,1.2-13.1c0.2-0.2,0.2-0.4,0.1-0.7c0-0.1,0-0.3,0.1-0.4
							C199.9,410.1,199.9,410.1,204.3,411z"/>
						<path fill="#1D190E" d="M192.3,498.6c0.6,0.1,1.1,0.1,1.7,0.2c0.6,0.6,1.4,0.7,2.1,0.2c6.2,0.7,12.4,1.3,18.7,2
							c0,0.3-0.1,0.6-0.1,0.8c-0.7,0-1.5,0-2.2,0c-2.2-0.2-4.4-0.4-6.6-0.7c-0.2-0.3-0.4-0.3-0.6-0.1c-0.7-0.1-1.3-0.1-2-0.2
							c-1.4-0.5-2.9-0.7-4.4-0.5c-2.2-0.2-4.4-0.5-6.7-0.7c-0.3-0.3-0.6-0.3-1-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
							C190.9,498.5,191.8,499.2,192.3,498.6z"/>
						<path fill="#E9E4B6" d="M269.1,461.8c-0.1,0.8,0,1.4,1.1,1.2c0.5-0.1,1.1-0.3,1.5,0.3c-1.4,1.1-5.7,1-6.9-0.2
							c-0.3-1.5-1.7-0.8-2.6-1.2c0-0.3,0.1-0.5,0.1-0.8C264.6,461.2,266.9,461.4,269.1,461.8z"/>
						<path fill="#E9E4B6" d="M242.4,458.9c-0.5,0.7-1.2,0.4-1.8,0.3c-3.2-0.6-6.6-0.2-9.7-1.5C234.8,457.8,238.6,458.2,242.4,458.9
							z"/>
						<path fill="#32613E" d="M198.1,428.5c0.4,0.3,0.8,0.5,1.3,0.3c0.7,0.1,1.4,0.2,2.1,0.2c1.1,0.1,2.3,0.2,3.4,0.3
							c0.4,0,0.9,0.1,1.3,0.1c0,0.1,0,0.1,0,0.2c0.2,0.3,0.5,0.6,0.7,0.9c-2.9-0.3-5.9-0.6-8.8-0.9
							C198.1,429.3,197.7,428.9,198.1,428.5z"/>
						<path fill="#E9E4B6" d="M278.4,462.8c-0.7,0.4-1.5,0.7-2.2,1.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0-0.9-0.1-1.3-0.1
							c-0.3-0.2-0.6-0.4-0.9-0.5c-0.5-0.1-0.7-0.4-0.7-0.9C274.5,462.2,276.5,462.3,278.4,462.8z"/>
						<path fill="#888158" d="M281.4,492c-1-1.3-0.1-2.7-0.1-4.1c0-0.8,0.2-1.7,0.2-2.5c0.5,0.1,0.8,0.3,0.7,0.8
							C282,488.2,282.1,490.2,281.4,492z"/>
						<path fill="#E9E4B6" d="M209.9,477.8c-3.5,0.3-6.8-0.1-10.2-1.1C203.2,476.9,206.6,477.3,209.9,477.8z"/>
						<path fill="#888158" d="M267.2,484c1.6-0.6,3.2-0.4,4.7,0.4c0,0.1,0,0.3-0.1,0.4c-1-0.1-2-0.1-3-0.2
							C268.3,484.3,267.5,484.7,267.2,484z"/>
						<path fill="#888158" d="M220.5,478.9c-1.5,0.5-2.9,0.3-4.2-0.4C217.8,477.9,219.2,478.1,220.5,478.9z"/>
						<path fill="#888158" d="M212.4,501.8c0.7,0,1.5,0,2.2,0c2,0.3,4,0.6,6,0.9C217.9,502.7,215.1,502.5,212.4,501.8z"/>
						<path fill="#B7AF8B" d="M246.5,504.2c2.3-0.3,4.6,0.1,6.8,0.7C251,505,248.8,504.6,246.5,504.2z"/>
						<path fill="#E9E4B6" d="M279.5,485.2c-0.2,0.3-0.5,0.3-0.9,0.3c-0.6-0.1-1.1-0.1-1.7-0.2c-1.2-0.3-2.6,0.1-3.8-0.8
							C275.2,484.6,277.4,484.8,279.5,485.2z"/>
						<path fill="#F5F0BC" d="M273.1,484.6c1.1,0.9,2.5,0.5,3.8,0.8c-1.8,0.5-3.5,0.3-5.1-0.6c0.1-0.1,0.1-0.2,0.1-0.4
							C272.3,484.5,272.7,484.5,273.1,484.6z"/>
						<path fill="#E9E4B6" d="M254.4,459c1.1-1.4,2-1.3,2.6,0.4C256.1,459.7,255.3,459.1,254.4,459z"/>
						<path fill="#F5F0BC" d="M267.2,484c0.3,0.7,1.1,0.3,1.6,0.6c-1.2,0.9-2.3,0-3.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4
							C266.3,483.8,266.7,483.9,267.2,484z"/>
						<path fill="#888158" d="M226.2,503.3c-1.2,0.2-2.4,0-3.6-0.4C223.8,502.7,225,502.9,226.2,503.3z"/>
						<path fill="#F5F0BC" d="M203.1,453.5c0.1-0.5,0.3-0.9,0.9-0.8c0.6,0.1,0.8,0.4,0.8,1C204.3,453.6,203.7,453.6,203.1,453.5z"/>
						<path fill="#888158" d="M260,483.2c-0.6,0.5-1.2,0.5-1.7-0.2C258.9,482.4,259.5,482.5,260,483.2z"/>
						<path fill="#888158" d="M245.6,481.4c-0.9,0.2-1.8,0.1-2.6-0.3C243.9,480.9,244.8,480.9,245.6,481.4z"/>
						<path fill="#888158" d="M265.9,483.8c-0.2,0.1-0.3,0.3-0.5,0.4c-0.3-0.1-0.6-0.1-0.9-0.2c0-0.1,0-0.3,0-0.4
							C265.1,483.2,265.5,483.1,265.9,483.8z"/>
						<path fill="#CEC69D" d="M250.2,482.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0-0.6-0.1-0.8-0.1c-0.5-0.1-1,0-1.2-0.5
							c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.6,0.1,0.9,0.1C249.4,481.9,249.8,482,250.2,482.1z"/>
						<path fill="#1D190E" d="M260.9,505.9c0.9,0.1,1.7,0.2,2.6,0.3C262.6,506.8,261.7,506.7,260.9,505.9z"/>
						<path fill="#1D190E" d="M264.3,506.3c0.9,0.1,1.7,0.2,2.6,0.3C265.9,507.2,265.1,507.1,264.3,506.3z"/>
						<path fill="#E9E4B6" d="M194.6,476.5c0-0.1,0-0.2,0-0.3c0.7,0.1,1.4,0.1,2.1,0.2c-0.3,0.1-0.6,0.2-0.9,0.3
							C195.5,476.6,195.1,476.6,194.6,476.5z"/>
						<path fill="#888158" d="M262.1,483.4c-0.3,0.5-0.6,0.5-0.9-0.1C261.5,482.9,261.8,482.9,262.1,483.4z"/>
						<path fill="#888158" d="M230.3,479.8c-0.6,0.1-1.2,0.1-1.8-0.2C229.2,479.3,229.8,479.4,230.3,479.8z"/>
						<path fill="#B7AF8B" d="M258,505.4c0.3-0.3,0.6-0.2,0.8,0.2c-0.1,0.1-0.2,0.4-0.4,0.4C258.1,506,258,505.7,258,505.4z"/>
						<path fill="#888158" d="M279.9,507.2c-0.2-0.5-0.2-1,0.2-1.4C280.2,506.2,280.2,506.7,279.9,507.2z"/>
						<path fill="#B7AF8B" d="M226.1,501.9c0-0.1,0-0.2,0.1-0.3c0.3,0,0.6,0.1,0.9,0.1c0,0.1,0.1,0.2,0,0.3
							C226.8,502,226.5,502,226.1,501.9z"/>
						<path fill="#888158" d="M284.6,464c-0.2-0.1-0.4-0.2-0.6-0.2c0-0.1,0.1-0.3,0.1-0.4C284.5,463.4,284.7,463.6,284.6,464z"/>
						<path fill="#888158" d="M249,481.9c-0.3,0-0.6-0.1-0.9-0.1C248.4,481.5,248.7,481.5,249,481.9z"/>
						<path fill="#E9E4B6" d="M196.2,454c-0.3,0.1-0.5,0.2-0.8,0.3C195.5,453.7,195.8,453.8,196.2,454z"/>
						<path fill="#E9E4B6" d="M244.6,459.1c-0.2,0.4-0.4,0.4-0.5-0.1C244.3,459,244.4,459.1,244.6,459.1z"/>
						<path fill="#E9E4B6" d="M193.8,476c-0.2,0.2-0.3,0.2-0.4-0.1C193.6,476,193.7,476,193.8,476z"/>
						<path fill="#32613E" d="M247.1,434.9l-0.2,0c0,0-0.1-0.1-0.1-0.1C246.9,434.8,247,434.8,247.1,434.9z"/>
						<path fill="#E9E4B6" d="M195.2,456c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.2,0.1C195.1,456.3,195.2,456.1,195.2,456z"/>
						<path fill="#888158" d="M199.4,414.7c0.2,0.2,0.1,0.5-0.1,0.7C199.3,415.2,199.4,415,199.4,414.7z"/>
						<path fill="#888158" d="M247.3,481.6c-0.2,0-0.3-0.1-0.5-0.1C247,481.3,247.2,481.4,247.3,481.6z"/>
						<path fill="#E9E4B6" d="M284.1,463.4c0,0.1-0.1,0.3-0.1,0.4c-0.2-0.2-0.3-0.3-0.5-0.5C283.7,463.3,283.9,463.3,284.1,463.4z"
							/>
						<path fill="#E9E4B6" d="M203.8,454.8c-0.2,0.2-0.3,0.2-0.4-0.1C203.6,454.8,203.7,454.8,203.8,454.8z"/>
						<path fill="#888158" d="M235.9,480.4c-0.2,0-0.3,0-0.5,0C235.5,480.1,235.7,480.1,235.9,480.4z"/>
						<path fill="#E9E4B6" d="M230,478.6c0.2-0.2,0.3-0.2,0.4,0C230.3,478.6,230.1,478.6,230,478.6z"/>
						<path fill="#888158" d="M263.4,483.5c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.1-0.3C263.1,483.5,263.2,483.5,263.4,483.5z"
							/>
						<path fill="#888158" d="M232.4,480.6c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0,0.4,0.1c0,0.1,0,0.3-0.1,0.4
							C232.7,480.6,232.5,480.6,232.4,480.6z"/>
						<path fill="#B7AF8B" d="M287.2,451.7c0,0.1,0.1,0.2,0.1,0.4c0,0-0.1,0.1-0.1,0.1C287.2,452,287.2,451.9,287.2,451.7z"/>
						<path fill="#F5EBB0" d="M298.2,346.2c0,0.1,0,0.2,0,0.3c-3.8-0.4-7.6-0.8-11.4-1.2c-14.4-1.6-28.8-3.2-43.2-4.8
							c-0.7-0.5-1.5-0.4-2.2-0.5c-6-0.7-12-1.3-18.1-2c-0.2-1.3-1.2-0.9-2-1c-3.9-0.7-7.8-0.8-11.7-1.7c-0.1-0.5-0.1-0.9-0.2-1.4
							l0.1,0c0.4-0.1,0.5-0.4,0.5-0.8c1-0.2,2.6,1,2.7-1.1c0.1-1.9-1.6-1.1-2.4-1.5c0-0.1,0-0.2,0-0.3c0.1-0.4,0.1-0.9,0.2-1.3
							c0.5-0.3,0.8-0.5,1.6-0.3c2.2,0.5,4.5,1.6,6.8,0.3c1.2-0.7,2.3-0.6,3.4-0.4c0.2,0.1,0.4,0.1,0.6,0.2c0.5,1.6,2.3,0.4,3.2,1.4
							c0.8,0.9,1.4,0.4,1.9-0.5c0.3,0,0.6,0,0.9,0c0,0.2,0.1,0.4,0.1,0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.4,0,0.8,0,1.2-0.1
							c0.1,0.4,0.3,0.5,0.6,0.1c0.8-0.1,1.5-1.4,2.4-0.1c0.2,0.3,1,0.3,1.3-0.2c0.2-0.3,0.2-0.8,0.1-1.2c-0.2-0.6-0.7-0.5-1.2-0.5
							c-1.3,0.1-2.6,0.3-4,0c-2.3-0.4-4.6-0.6-6.9-0.9c-0.5,0.1-0.9,0.3-1.4,0.3c-1.3,0-2.5-0.7-2.5-1.9c-0.1-1.4,1.5-2.2,2.2-3.4
							c0.5-0.8,1.3,0.4,2.1,0.3c0.3,0.3,0.6,0.7,1,0.7c5.8,0.2,11.6,1.2,17.4,1.6c1.7,0.6,3.3-0.6,5,0.1c0.3,0.1,0.6,0.8,1,0.2
							c0.3-0.4-0.1-0.7-0.5-1c-1.7-1.4-3.5-1.2-5.3-0.4c-0.1-0.4-0.2-0.8-0.8-0.8c-3.9-0.3-7.8-0.6-11.7-1.1c-1.4-0.2-2.7-0.3-3.9-1
							c-0.1-2.3,1-2.6,3.1-2.5c4.5,0.3,8.8,2,13.4,1.1c0.8-0.2,1.7,0.1,2.5,0.4c0.4,0.2,0.9,0.3,1.2,0.2c2.1-0.8,4.3-0.3,6.3,0.1
							c2.9,0.6,5.9,0.8,8.8,1.2c0.7,0.1,1.6,0.5,1.9-0.6c0.4,0,0.9,0.1,1.3,0.1c0.3,0.7,0.6,1.4,1.3,0.2c0.7,0.1,1.4,0.2,2.1,0.3
							c0.9,0.6,1.9,0.7,3,0.3c6.9,0.7,13.8,1.4,20.8,2.2c0.8,0.7,1.6,0.8,2.5,0.3c2.3,0.3,4.5,0.5,6.8,0.8c-0.7,6.4-1.4,12.9-2,19.3
							C298.1,344.6,297.8,345.4,298.2,346.2z M254.5,323c0,0.1-0.1,0.1-0.1,0.2c-1.4-0.1-2.7-0.2-4.1-0.3c-1.2-0.2-2.4-0.4-2.1,1.5
							c-0.7,2.1-0.6,4.3-0.9,6.4c-0.1,0.8,0.3,1.2,1,1.3c2.2,0.1,4.3,0.8,6.4,0.5c0.4,0,0.8,0.1,1.2,0.1c0.2,0.2,0.3,0.3,0.5,0.1
							c0.4,0,0.8,0.1,1.2,0.1c0.3,0.3,0.6,0.4,0.9,0.1c0.3,0,0.5,0.1,0.8,0.1c0.2,0.3,0.3,0.3,0.5,0c0.8,0.1,1.7,0.2,2.5,0.3
							c0.4,0.4,0.8,0.4,1.2,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0.2,0.4,0.2,0.7,0.1c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3-0.1,0.5-0.1
							c1-0.3,0.7-1.3,0.8-2c0.4-2,0-4.2,1.6-6c0.7-0.8,0-1.1-0.7-1.4c-2.9-1-6-0.9-9-1.3C257.3,322.9,255.9,322.3,254.5,323z
							 M294.2,327.6c-2,0-3.2-0.6-4.6-0.7c-6.4-0.6-12.7-1.4-19.1-2.1c-0.5-0.1-1.1-0.1-1.2,0.6c-0.1,0.7,0.6,0.6,1,0.9
							c1.2,0.7,2.5,0.1,3.7,0.1c2.8,0.1,5.4,1.2,8.3,1c2.4-0.1,4.9,0.5,7.4,0.7C291,328.2,292.4,328.8,294.2,327.6z M279.6,343.7
							c0.5,0.1,1,0.1,1.5,0.2c0.8,0.1,1.2-0.2,1.3-1c0.1-1.1-0.6-0.7-1.2-0.8c-1.6-0.3-3.2,0-4.7-0.6c-1-0.4-0.8,0.7-1,1.2
							c-0.3,0.7,0.3,0.8,0.7,0.6C277.3,342.9,278.4,343.9,279.6,343.7z M270.4,334.8c0.7,0.3,1.2,0.6,1.6-0.1c0.1-0.2,0.1-0.6,0-0.8
							c-0.5-0.7-0.9-0.2-1.5,0c-0.3,0.1-0.7,0.2-1,0.1c-0.4,0-1-0.6-1.2,0.2c-0.1,0.8,0.6,0.8,1.2,0.8
							C269.9,334.9,270.3,334.8,270.4,334.8z M289.8,343c-0.5,0.1-1.4-0.8-1.3,0.3c0,0.8,1.1,1.1,1.8,1.4c0.5,0.2,0.9-0.3,0.9-0.9
							C291.1,342.8,290.4,342.8,289.8,343z M283.8,344.1c0.5-0.1,0.6-0.5,0.6-0.9c0-0.4-0.2-0.9-0.7-0.9c-0.6,0-0.5,0.6-0.6,0.9
							C283.1,343.6,283.2,344.1,283.8,344.1z M287.3,343.7c0.3-0.3,0.4-0.4,0.5-0.5c0-0.1,0-0.2-0.1-0.4c-0.2,0.1-0.4,0.1-0.5,0.2
							C287.2,343.2,287.3,343.4,287.3,343.7z"/>
						<path fill="#F5EBB0" d="M243.6,364.3c-0.1,0-0.3,0-0.4,0c-0.4-0.6-1-0.8-1.6-1.1c-0.1-0.4-0.3-0.2-0.4,0
							c-11.3-1.2-22.6-2.3-33.9-3.5c-0.1,0-0.3,0-0.4-0.1c-0.8-1.6,0.3-3.1,0-4.7c0-0.1,0-0.3,0-0.4c0.3-2.1,0.5-4.2,0.8-6.2
							c0.2-0.4,0.4-0.9,0.6-1.3c2.4,0.3,3.5,2.3,2.5,4.2c-0.4,0.3-0.8,0.5-0.6,1.2c0.4,1.4,2.4,2.3,4,1.8c1.6-0.6,2.4-2.3,1.7-3.7
							c-0.2-0.5-1.1-0.8-0.4-1.4c1.6-1.4,0.7-2.6-0.3-3.8c0.3-0.2,0.7-0.6,0.2-0.7c-1.4-0.3-2.8-1.1-4.1,0.1
							c-1.2,1.1-1.8,0.9-2.1-0.7c0-0.1-0.3-0.2-0.5-0.3c0-0.3,0.1-0.6,0.1-0.9c1-0.2,1.8-0.6,2.3-1.5c0.7-1.1,1.7-1.5,3-1.4
							c2.8,0.4,5.7,0.6,8.5,0.9c0.7,0.1,1.5,0.4,1.7-0.6c5.6,0.6,11.2,1.2,16.8,1.8c0.7,0.1,1.5,0.4,2.3-0.1c3.1,0.3,6.2,0.7,9.3,1
							c0,0.3-0.1,0.8,0.3,0.9c0.6,0.2,0.5-0.4,0.7-0.8c0.7,0.1,1.4,0.1,2,0.2c-0.1,0.7,0.2,1.2,1,1.3c0.9,0.1,1.1-0.4,1.2-1.1
							c0.8,0.1,1.7,0.2,2.5,0.3c0.6,1.3,1.3,1.3,2.1,0.3c0.6,0.1,1.1,0.2,1.7,0.3c0.3,1.8,1,3,3.1,2.6c0,0,0.1,0.1,0.1,0.2
							c0.1,1.6,1.7-0.1,2,0.9c-0.2,1.4-1.5,0-2,0.8c-0.2,0.3-1.2,0.4-0.5,0.9c1,0.7,2.2-0.1,3.3-0.1c0.6,0,0.1-0.9,0-1.5
							c0.2-0.4,0.4-0.8,0.6-1.2c0.7,2.1,2.6,1.8,4,1.5c2.2-0.4,4.3-0.6,6.5-0.4c0.1,1.5,1.2,0.4,1.8,0.6c0.1,0,0.2,0,0.3,0
							c0.7,1.6,2.5,0,3.4,1.2c0,0.1,0,0.3,0,0.4c-1.6,1.4-0.7,3.4-0.9,5.1c-0.9-0.2-2.1,0.5-2.8-0.6c-0.1-0.1-0.6,0-0.8,0.1
							c-0.8,1.1-2.7,2.1-0.9,3.8c0.7,0.6-0.1,1.2,0,1.7c-0.4,0.1-0.9,0.1-1.2,0.3c-1.3,0.8-0.8-0.3-0.9-0.8
							c-0.4-1.2,0.2-2.3,0.5-3.4c0.2-0.8-0.1-0.8-0.6-0.9c-0.8,0.1-2.3-1.5-2.4,0.8c-0.4,1.2-1.6,2.1-0.9,3.5
							c-0.8,0.8-0.8-0.4-1.2-0.5c-0.2-1.1,0-2.4-1.8-2.6c0.3,1.2,0.1,2.6,1.8,2.6c-0.6,1.3-0.6,2.3,1.4,2.1c0.8-0.1,1.6,0.2,2.4,0.4
							c1.4,0.2,3.2,0.3,4.2-1.2c0.3-0.3,0.6-0.3,0.8,0.1c-0.8,1.5,0.6,1.6,1.4,1.8c1.7,0.4,0.9-1.1,1.2-1.8c0.3,0.2,0.4,0.7,1.1,0.4
							c0.6-0.3,1.6-1,1.8-0.8c1.2,1.1,3.1,0.1,4.3,1.5c0.6,0.7,1.7,0.8,2.7,0.8c0.3,1.1-0.7,1.6-1.1,2.5c-3.3-0.3-6.5-0.7-9.8-1
							c-0.8-0.8-1.9-0.7-2.8-1.1c-0.3-0.2-0.8-0.1-0.8,0.4c0,0.3,0.3,0.9,0.4,0.9c1.1-0.1,1.9,1.1,3.1,0.6c2.7,0.2,5.4,0.8,8,1.3
							c-0.5,1.9,1.8,0.9,2.1,2c-0.5,0.5-1.4,0-1.6,0.9c-4-0.2-7.9-0.6-11.9-1.2c-0.1-0.2-0.3-0.2-0.4,0c-0.3,0-0.6-0.1-0.9-0.1
							c-0.2-0.4-0.5-0.4-0.8-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.2-0.2-0.3-0.4-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.2-0.3-0.2-0.4,0
							c-0.3,0-0.6-0.1-0.9-0.1c0-0.4-0.7-0.9,0.4-0.8c2,0.3,4.1,0.3,6.1,0.6c0.7,0.1,0.9-0.3,1.1-0.7c0.4-0.9-0.6-0.7-0.9-0.7
							c-3.5-0.4-7.1-0.8-10.8-1.2c0.3,1.1,0.4,1.7,0.6,2.4c-0.4,0-0.8-0.1-1.3-0.1c-0.2-0.4-0.5-0.4-0.8-0.1c-0.4,0-0.9-0.1-1.3-0.1
							c-0.4-0.5-0.8-0.5-1.3-0.1c-2.8-0.3-5.6-0.7-8.4-1c-0.9-0.5-1.8-0.6-2.7-0.3c-0.7-0.1-1.3-0.1-2-0.2c-2.4-0.6-4.9-0.9-7.4-0.8
							C246.4,363.8,244.9,362.9,243.6,364.3z M275.9,363.1c0.5-0.1,1.4,0.2,1-0.7c-0.5-1.1-1.7-0.4-2.6-0.6
							c-0.4-0.1-0.7,0.1-0.4,0.8c0.2,0.5,0.2,0.8,0.7,0.3C275.1,362.5,275.5,362.5,275.9,363.1c-0.1,0-0.2,0.1-0.3,0.1
							C275.7,363.2,275.8,363.2,275.9,363.1z M230.8,344.1c-0.1-0.1-0.3-0.1-0.4,0c-1.2-0.2-2.4-0.6-3.6-0.7
							c-0.8-0.1-1.1,0.5-1.1,1.2c-0.1,0.7,0.6,0.7,1,0.7c1.2,0,2.5,0.5,3.7,0.1c1.2,0.1,2.5,0.2,3.7,0.3c0.1,0.1,0.3,0.4,0.4,0.4
							c3,0.4,6,0.7,9.1,1c0.4,0,0.6-0.3,0.6-0.7c0.9-0.5,1.7-0.7,2.2,0.5c0.3,0.7,0.8,0.6,1.4,0.5c0.5-0.1,0.7-0.5,0.7-0.9
							c0-0.7-0.4-1-1.1-1.1c-1.4-0.1-2.9-0.7-4.3-0.2c-1.5-0.3-3.1-0.3-4.6-0.6c-1.3-0.2-2.8-0.9-4.2-0.2c-0.8-0.1-1.7-0.2-2.5-0.3
							C231.4,343.9,231.1,343.7,230.8,344.1z M274.8,349.6c0-0.5-0.1-1.1-0.8-0.5c-0.5-0.1-1.1-0.4-1.6-0.4
							c-0.6-0.1-1.1,0.3-0.8,0.9c0.4,0.8,1.3,1,2.1,1.2C274.6,351,274.7,350.3,274.8,349.6z M229.2,354.7c0.3,0.6-0.2,1.7,1.1,1.5
							c3.3-0.4,6.5,0,9.7,0.7c0.7,0.2,1.5,0.3,1.7-0.7c0.2-1-0.6-1.3-1.5-1.3c-2.2,0-4.4-0.4-6.6-0.7c-1.4-0.2-2.9-0.5-4.4-0.1
							c-1-0.2-1.4,0.7-2.7,1.1C228,355.6,228.6,355.2,229.2,354.7z M254,358.5c4.5,0.5,4.5,0.5,5.1-3.9c0.7-4.8,0.7-4.8-4.2-5.3
							c-4.3-0.4-4.3-0.4-4.9,3.8C249.3,358,249.3,358,254,358.5z M221.2,348c-0.8-0.2-1.6-0.6-1.9,0.5c-0.4,1.3,0.9,0.9,1.4,1.3
							c0.8,0.7,1.6,0.1,2.4,0.2c3,0.6,6.2-0.2,9.1,1.1c0.3,0.1,0.8,0,1-0.2c0.5-0.5-0.1-0.7-0.3-1c-0.6-1.2-1.6-1.3-2.7-1.2
							C227,349,224.1,348.2,221.2,348z M227.2,360.4c0.6,0,1.3-0.5,2.2-0.2c0.6,0.2,1.3,0.3,1.4-0.7c0.1-1-0.7-0.9-1.3-1
							c-3.3-0.4-6.5-0.8-9.8-1.3c-0.7-0.1-1.3-0.4-1.5,0.5c-0.3,1,0.4,1.2,1.2,1.3C221.9,359.6,224.6,359.3,227.2,360.4z
							 M242.7,352.3c0.6,0,1.3,0.1,1.9,0c0.4,0,0.6-0.4,0.6-0.8c0-0.4-0.2-0.7-0.6-0.7c-2.5-0.1-4.9-1.5-7.6-0.8
							c-0.4,0.1-0.9-0.9-1.1,0.4c-0.2,1.2,0.4,1.2,1.1,1.2C238.9,351.5,240.7,352.8,242.7,352.3z M221,343.2
							c-0.1,1.5,1.1,1.6,2.1,1.7c0.6,0.1,1-0.3,1.1-0.8c0.2-1.5-1.2-1-1.9-1.5c-0.1,0-0.1-0.1-0.1-0.2c-0.6-0.7-1.3-1-2-0.3
							c-0.6,0.6-0.7,1.4-0.3,2.2C220.7,344.3,220.4,343.5,221,343.2z M268.2,365.3c0.6-0.5,1.2,0.2,1.9,0.2c0.6,0,1.2-0.2,1.2-0.9
							c0.1-0.8-0.7-1-1.2-0.8c-0.8,0.2-1.4-0.2-2-0.1c-1,0.1-2.8-0.8-2.9,0.5c-0.1,1.4,1.8,0.3,2.6,1
							C267.9,365.3,268,365.2,268.2,365.3z M220.7,352.9c-0.8-0.1-1.9-0.8-2,0.5c-0.1,1.9,1.9,0.7,2.7,1.4c0.5,0.4,1.3-0.1,1.3-0.8
							C222.7,352.7,221.5,353,220.7,352.9z M269.4,354.1c0.6,1.1,1.4,1.8,2.7,1.5c0.4-0.1,0.5-0.5,0.4-0.9
							C271.7,353.4,270.5,354.1,269.4,354.1z M280.8,362.5c-0.9,0.2-1.9-0.3-2.7,0.5c-0.2,0.2-0.1,0.6,0.2,0.7
							C279.3,363.8,280.3,363.6,280.8,362.5z M274.2,355c0-0.4-0.1-0.7-0.6-0.7c-0.4,0-0.7,0.2-0.7,0.6c0,0.3,0.2,0.5,0.5,0.6
							C273.8,355.6,274.1,355.4,274.2,355z M277.4,349.4c-0.2-0.1-0.4-0.3-0.6-0.3c-0.3-0.1-0.6,0.1-0.5,0.4
							c0.1,0.2,0.4,0.3,0.7,0.3C277.2,349.9,277.4,349.8,277.4,349.4z"/>
						<path fill="#F5EBB0" d="M250.6,275.1c6.7,0.5,13.3,1.2,20,2c0.1,0.1,0.2,0.2,0.3,0.3c0.5,1,1.5,0.7,2.2,0.8
							c9.8,1.1,19.6,2.1,29.4,3.2c0.8,0.1,2-0.3,2.1,1.1c-0.2,1.8-0.4,3.6-0.6,5.3c-0.3,3-0.7,5.9-1,8.9c-0.1,1.4-0.7,1.8-2,1.6
							c-2-0.3-4.1-0.6-6.1-0.7c-2.6-0.1-5.2-1.3-8-0.3c-0.2,0.1-2.1,0.8-2.3-0.8c-0.2-0.5-0.5-0.5-0.8-0.1c-1.2-0.4-0.7-1.2-0.6-1.9
							c0.5,0.1,1.1,0.2,1.6,0.3c0.7,0.9,1.1,0.4,1.4-0.4c0.8,0.1,1.7,0.3,2.5,0.4c0.2,0.1,0.3,0.1,0.5,0.2l0.2,0l0.1,0.1
							c0,0.5,0.1,1,0.7,1.1c0.6,0.1,0.9-0.2,1-0.7l0,0c0.6,0.1,1.1,0.1,1.6-0.2c2-0.3,1.3-1.9,1.3-3c0-1.2-0.7-0.1-1.2,0
							c-1.6-0.8-3.5-1.3-5.2-1.4c-3.3-0.2-6.7-0.6-10-1.1c-0.6-0.1-1.2-0.1-1.2-0.9c0-0.8,0.7-1,1.2-0.9c2.3,0.3,4.8,0.2,7,0.7
							c2.2,0.5,4.5,0.3,6.7,0.9c0.3-0.1,0.5-0.1,0.8-0.2c1.8,1.4,3.1,1.3,4.1-0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.2,1.2,1,1,1.7,0.8
							c1.1-0.3,1.6-2.7,0.8-4.1c-0.4-0.7-0.7-2-1.8-1.8c-1.6,0.4-2.9-0.2-4.4-0.5c-1.2-0.2-2.5,0-3.7-0.4c-0.8-0.3-1.7-0.2-2,1
							c-0.2,0.4-0.6,0.4-1,0.4c-0.1-0.2-0.2-0.3-0.2-0.5c0.2-1.1-0.5-1.2-1.4-1.4c-2.8-0.5-5.6-0.4-8.4,0.1
							c-0.2-0.1-0.5-0.1-0.7-0.2c-0.4-0.7-0.9-0.8-1.3,0c-0.6,1.2-0.2,2.3-0.1,3.6c0.9-0.6,0.3-1.6,1.4-2c0,0.8,0,1.5,0,2.3
							c-0.1,0.4-0.4,0.8-0.4,1.2c-0.1,1.5-1.6,2.8-2.8,2.1c-2.8-1.5-5.8-1-8.7-1.6c-0.9-0.2-1.5,0.2-1.6,1.2
							c-0.3,0.3-0.6,0.4-0.9,0.1c-0.7-0.5-1.4-0.5-2.1,0.2c-0.4,0.4-0.4,0.9-0.2,1.4c0.4,0.8,1.1,1.1,1.9,0.7c0.2,0,0.4,0,0.6,0
							c0.5,0.2,1.1,0.3,1.6,0.5c1.8,1.4,4,1.5,6,1.6c3.6,0.3,7.1,0.9,10.6,1.2c0.4,0,1.2-0.1,1.1,0.6c-0.1,0.7-0.8,0.7-1.4,0.6
							c-1.7-0.2-3.4-0.5-5.1-0.7c-5.8-0.5-11.5-1.4-17.3-2.1c-4-0.5-8.1-0.5-12.2-1.2c-3.5-0.7-7.2-0.4-10.7-1.1
							c-2.7-0.5-5.6-0.3-8.3-1.1c-2.9-0.9-5.9,0.4-8.8,0c-1.9-0.3-2.6-1-2.4-2.9c0.5-4.3,1.1-8.6,1.4-12.9c0.1-2,0.9-2.3,2.7-2.1
							c9.8,1.1,19.6,2.2,29.4,3.2C249.2,275.7,250.2,276.3,250.6,275.1z M240.4,282.8c0.9-0.5,0.1-1.8,1.1-2.4
							c0.5,0.2,1.1,0.5,1.6,0.7c0.2,0.7-0.9,1.5,0.1,2.1c0.7,0.4,1.7,0.5,2.5,0c0.8-0.6,0.6-1.7,0.4-2.4c-0.3-1.6-1.7-1-2.8-1.1
							c-0.4,0-0.9,0-1.3,0c-0.8,0.1-1.9-1.2-2.5,0.5c-0.4,0-0.8-0.1-1.2-0.1c-0.2-1.3-1.1-1.3-2.1-1c-0.9,0.3-1.1,1-1,1.9
							c0.1,0.9,0.6,1.4,1.5,1.5c0.8,0.1,1.4-0.1,1.3-1.1c0.7,0.1,0.7,1.6,1.8,1c0.1,0.1,0.2,0.2,0.2,0.3
							C240,282.9,240.2,283,240.4,282.8z M236.6,289.8c0.2,0.3,0.4,0.5,0.8,0.5c1.1,0.7,2.1,0.7,3-0.2c2.4,0.2,1.9-1.2,1.5-2.6
							c0-0.6,0.7-1,0.3-1.5c-0.5-0.6-3.9,0.3-4.5,1.2c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5-0.9-1.5-0.6-2.3-0.7c-0.5-0.1-0.9,0.3-0.6,0.8
							C234.6,288.5,234.7,290.1,236.6,289.8z M225.7,282.3c0.1-1.8,0.1-3.3,0.2-4.7c0.1-1-0.7-1.1-1.4-1.2c-0.7,0-1.7-0.4-1.9,0.7
							c-0.2,1.3-0.6,2.4-1.2,3.6c-0.2,0.4-0.3,1.3,0.7,0.7C223.4,280.7,224.5,281.2,225.7,282.3z M230,286.4
							c-0.6-1.6-1.6-1.3-2.3-0.8c-0.9,0.5-1.1,1.5-0.6,2.4c0.5,0.8,1.3,1.2,2.2,0.5c0.6-0.5,1.1-0.5,1.6,0.2c0.7,1,1.4,0.4,2-0.1
							c0.5-0.4,0.7-1,0.1-1.7c-0.6-0.7-1.3-0.7-1.9-0.1c-0.6,0.5-1.3,0.8-2.1,0.8c-0.4,0-0.9,0.1-1-0.5c-0.1-0.7,0.3-0.9,0.9-0.8
							C229.2,286.4,229.5,286.4,230,286.4z M264.5,282c-2.2-0.3-1.2,1.6-1.5,2.4c-0.2,0.5-0.3,2,0.3,1.7c1.1-0.6,2.9-0.3,3.2-2.1
							C266.6,282.5,265.5,282.3,264.5,282z M251.1,283.9c0.8,0.5,1.2-0.6,1.7-0.4c0.5,0.2,0.9,1.4,1.5,0.3c0.3-0.6,0.3-1.4,0.2-2.1
							c0-1.2-0.9-1.2-1.8-1.2c-0.8,0-1.7,0.3-1.4,1.3C251.6,282.7,251,283.3,251.1,283.9z M229.8,280.1c0.3-1-0.2-1.6-1.1-1.9
							c-1.1-0.3-1.8,0.3-1.9,1.4c-0.2,1,0.2,1.8,1.3,2.1C229.4,281.9,229.6,281,229.8,280.1z M267.3,284.2c0,0.9-0.3,2.4,1.5,0.8
							c0.3-0.2,0.5-0.2,0.6,0.1c0.1,0.4,0.1,1,0.7,0.8c0.6-0.2,0.5-0.8,0.5-1.3c-0.1-0.7,1.1-3-1.4-1.4c-0.2,0.1-0.3,0.1-0.5,0
							C267.4,282.7,267.4,282.8,267.3,284.2z M250.2,282.5c1.5-3.6-2.5-0.1-2.4-2c-0.9,0.2-0.5,1-0.7,1.5c-0.8,3,2.6-0.1,2.5,1.7
							C250.4,283.5,250.1,282.8,250.2,282.5z M261.2,285.1c1-2,1-3.1,0.2-3.5c-1.6-0.8-2.6,0.1-2.6,2.1c0,0.3-0.1,0.8,0.3,0.9
							c0.5,0.1,0.5-0.3,0.7-0.6c0.2-0.5-0.1-1.6,0.7-1.5c1,0.1,0.3,1.2,0.4,1.8C260.8,284.5,261,284.7,261.2,285.1z M234.1,280.6
							c-0.1-0.6,0.5-1.3-0.2-1.7c-0.6-0.4-0.9,0.2-1.2,0.6c-0.5,0.6-0.6-0.1-0.8-0.3c-0.6-0.4-0.9-0.2-0.9,0.5c0,0.6-0.4,1.4,0,1.9
							c0.5,0.6,1,0,1.4-0.4c0.2-0.2,0.4-0.1,0.7,0.1C233.9,282,234,281.9,234.1,280.6z M247.1,290.5c0.7,0.3,1.4,0.3,1.4-1
							c0-0.8-0.2-1.3-1.1-1.4c-1.1-0.1-1.3,0.6-1.3,1.5C246.1,290.1,245.9,290.7,247.1,290.5z M254.5,290.5
							c-0.3-0.7-1.2-1.1-1.8-1.4c-0.7-0.3-0.6,0.7-0.7,1.1c-0.1,0.9,0.4,1.3,1.3,1.2C253.7,291.3,254.3,291.4,254.5,290.5z
							 M244.1,289.9c0.7,0.5,1.2,0.4,1.3-0.5c0.1-0.7,0.1-1.2-0.9-1.2c-0.8,0-1.4-0.2-1.4,0.9C242.9,289.9,243.2,290.2,244.1,289.9z
							"/>
						<path fill="#F5F0BC" d="M276.7,283.1c2.8-0.6,5.6-0.6,8.4-0.1c0.9,0.1,1.6,0.3,1.4,1.4c-1.4,0.6-2.7-0.5-4.5,0
							c2,1,0.1,1.9,0,3c1.5-0.2,2.8-0.2,4,0.4c0.5,0,1,0,1.5,0.1c0.6,1.2,1.9,0.4,2.1,0.1c0.5-0.7,0.7-1.7,0.5-2.6
							c-0.3-0.9-1.2-0.7-2-0.7c-0.1-0.1-0.3-0.1-0.4-0.1c0.2-1.1,1.1-1.3,2-1c1.2,0.4,2.5,0.2,3.7,0.4c1.5,0.3,2.8,0.8,4.4,0.5
							c1.1-0.3,1.4,1,1.8,1.8c0.8,1.4,0.3,3.8-0.8,4.1c-0.7,0.2-1.6,0.4-1.7-0.8c0.1-0.1,0.3-0.1,0.4-0.2c1-0.8,1.7-2,0.9-3.1
							c-0.7-0.9-1.8-1.1-2.7,0.2c-0.3,0.5-0.7,0.1-0.9-0.4s-0.4-0.9-1.1-0.7l0,0c-0.8-0.1-1.9-0.9-2,0.6c-0.2,1.2-0.6,2.4-0.1,3.7
							c-2.2-0.6-4.5-0.5-6.7-0.9c-2.2-0.5-4.6-0.4-7-0.7c-0.5-0.1-1.2,0.1-1.2,0.9c0,0.8,0.6,0.8,1.2,0.9c3.3,0.6,6.6,0.9,10,1.1
							c1.7,0.1,3.5,0.6,5.2,1.4c-0.2,1,0.4,2-0.1,3c-0.5,0.3-1,0.3-1.6,0.2c0.2-0.8,1.2-1.6,0.2-2.4c-0.6-0.5-1.4-0.6-1.8,0.5
							c-0.2,0-0.3,0-0.5,0c0-1.6-1.4-1-2-1c-1,0-0.5,1.2-0.8,1.9c-0.3,0.8-0.6,1.3-1.4,0.4c0.7-0.6,1.3-1.3,0.6-2.3
							c-0.4-0.5-1-0.6-1.5-0.4c-1.1,0.5-0.7,1.5-0.7,2.4c-0.1,0.7-0.6,1.6,0.6,1.9c0.3,0,0.6,0.1,0.8,0.1c0.3,1.6,2.1,0.9,2.3,0.8
							c2.7-0.9,5.3,0.2,8,0.3c2.1,0.1,4.1,0.3,6.1,0.7c1.4,0.2,1.9-0.2,2-1.6c0.2-3,0.6-5.9,1-8.9c0.2,0,0.3,0.1,0.5,0.1
							c-0.4,3.9-0.8,7.7-1.1,11.6c-0.3-0.1-0.6,0-0.6,0.4c-22.9-2.4-45.7-4.9-68.6-7.3c-6.7-0.7-13.4-1.5-20.1-2.2
							c0.6-6.3,1.3-12.5,1.9-18.8c0.6-0.1,1.2-0.1,1.8-0.2c10.1,1.1,20.2,2.2,30.4,3.2c0.8,0.1,1.5,0.2,2.3,0.4
							c-0.5,1.2-1.4,0.6-2.2,0.5c-9.8-1-19.6-2-29.4-3.2c-1.8-0.2-2.5,0.1-2.7,2.1c-0.3,4.3-0.8,8.6-1.4,12.9
							c-0.2,1.9,0.5,2.7,2.4,2.9c3,0.4,5.9-0.8,8.8,0c2.7,0.8,5.6,0.6,8.3,1.1c3.6,0.7,7.2,0.4,10.7,1.1c4,0.8,8.1,0.7,12.2,1.2
							c5.8,0.7,11.5,1.6,17.3,2.1c1.7,0.2,3.4,0.5,5.1,0.7c0.6,0.1,1.3,0.1,1.4-0.6c0.1-0.7-0.7-0.6-1.1-0.6
							c-3.5-0.3-7-0.9-10.6-1.2c-2-0.2-4.2-0.2-6-1.6c0.6-0.4,1-1.7,1.9-0.4c0.6,0.9,1.3,1,2.2,0.3c0.6-0.5,0.6-1.1,0.2-1.7
							c-0.8-0.9-1.5-0.8-2.3,0.1c-0.3,0.3-0.9,0.6-1,0c-0.2-1.5-1.3-0.9-2.1-1.1c0.1-1,0.7-1.3,1.6-1.2c2.9,0.6,6,0,8.7,1.6
							c1.2,0.7,2.8-0.6,2.8-2.1c0-0.4,0.3-0.8,0.4-1.2c0.5,0.1,1.3,0.2,1.1-0.4C276.8,285.3,278.4,284,276.7,283.1z M275,291.4
							c-0.4,0.1-0.8,0.2-1.2,0.4c-1-1.5-2.6-0.5-3.9-0.9c-1-0.3-1.4,0.3-1.4,1.2c0,1,0.8,1.3,1.4,1.1c0.9-0.4,1.4-0.3,2.2,0.3
							c0.6,0.5,1.2,0,1.6-0.6c0.8,1,1.9,0.8,2.8,0.6c0.6-0.2,0.5-1.2,0.3-1.8c0-0.6-0.1-1.2-0.8-1.2
							C275.3,290.4,275.1,290.9,275,291.4z M279.7,283.8c-0.9,0-1.8,0.5-1.8,1.5c0,1,0.4,2.4,1.8,2.3c1,0,1.7-0.9,1.8-1.9
							C281.7,284.5,280.9,283.9,279.7,283.8z M278.7,294.2c0.9,0.1,1.2-0.4,1.2-1.2c0-0.7,0.2-1.4-0.7-1.5c-0.8-0.2-1.4,0.3-1.5,1.1
							C277.5,293.5,278,294,278.7,294.2z M281.7,291.7c-0.6,0-1,0.2-0.8,0.8c0.2,0.6-0.3,1.5,0.6,1.6c0.9,0.1,0.8-1,1.1-1.6
							C282.8,292,282.2,291.8,281.7,291.7z"/>
						<path fill="#3E3821" d="M214.2,290.5c6.7,0.7,13.4,1.5,20.1,2.2c22.9,2.4,45.7,4.9,68.6,7.3c0.1,0.1,0.2,0.3,0.4,0.4
							c0,0.3-0.1,0.5-0.1,0.8c-0.2,0-0.3,0-0.5,0.1c-0.5-0.1-1-0.2-1.5-0.3c-17.9-1.9-35.7-3.9-53.6-5.8
							c-11.2-1.2-22.3-2.3-33.5-3.4C214.2,291.4,214.2,290.9,214.2,290.5z"/>
						<path fill="#3E3821" d="M290.9,323.5c-6.9-0.7-13.8-1.4-20.8-2.2c-1-0.3-2-0.4-3-0.3c-0.7-0.1-1.4-0.2-2.1-0.3
							c-0.4-0.1-0.8-0.1-1.3-0.2c-0.4,0-0.9-0.1-1.3-0.1c-0.7-0.1-1.4-0.2-2.1-0.3c-16.2-1.7-32.5-3.4-48.7-5.1
							c-0.5-0.4,0.1-0.8,0-1.3l0.1,0c0.1,0,0.3,0.1,0.4,0.1c2.2,0.6,4.5,0.7,6.8,0.8c0.1,0,0.3,0,0.4,0c0.7,0.2,1.4,0.3,2.1,0.2
							c0.3,0.1,0.6,0.2,0.8-0.2l0.2,0l0.1-0.1c0.4,0.6,0.9,0.5,1.4,0.4c8,1.2,16.1,2,24.2,2.6c3.6,0.4,7.2,0.8,10.8,1.3
							c2.2,0.3,4.5,0.1,6.7,1c6.9,0.8,13.9,1.6,20.8,2.3c3.4,0.4,6.8,1,10.3,1c1.2,0,2.4,0,3.5,0.1c0.1,0,0.3,0,0.4,0l0.2,0l0.2,0
							c-0.2,0.4-0.3,0.9-0.5,1.3c-0.2,0-0.3,0-0.5,0c-2.3-0.3-4.5-0.5-6.8-0.8C292.6,323.5,291.7,323.4,290.9,323.5z"/>
						<path fill="#E9E4B6" d="M267.4,346.8c0-0.1-0.1-0.2-0.1-0.2c-2.1,0.4-2.8-0.8-3.1-2.6c11.3,1.2,22.6,2.4,33.9,3.5
							c0,1,0,1.9-0.1,2.9c0,0.1,0,0.3-0.1,0.4c-0.2,2-0.4,4-0.6,5.9c0,0.1,0,0.3-0.1,0.4c-0.8,3.1-0.7,6.3-1.2,9.5
							c-0.1,0.7,0.2,1.4-0.5,2c-0.2,0-0.4,0.1-0.6,0.1c-0.4-0.1-0.9-0.1-1.3-0.2c0.2-0.8,1.1-0.3,1.6-0.9c-0.3-1.1-2.7-0.1-2.1-2
							c0.6,0,1.3,0.3,1.8,0.1c1-0.3,1-0.8,0-1.2c0.3-0.8,1.3-1.4,1.1-2.5c0.4-3.9,0.9-7.7,1.3-11.6c0.2-1.8-0.7-2.8-2.5-2.6
							c-1.5,0.1-3.1,0.1-4.6,0.7c-0.4-0.1-0.9-0.1-1.3-0.2c-0.3-0.5-0.4-1.2-1.2-0.5c-0.1,0-0.3,0-0.4,0c-0.9-0.5-1.9,0.7-2.8-0.5
							c-0.4-0.6-1,0.3-1.2,0.9c-0.1,0-0.2,0-0.3,0c-0.2-1.4-1.2-0.3-1.8-0.6c-2.2-0.2-4.3,0-6.5,0.4c-1.4,0.2-3.3,0.6-4-1.5
							C269.7,346,268.5,345.4,267.4,346.8z"/>
						<path fill="#3E3821" d="M298.1,347.5c-11.3-1.2-22.6-2.4-33.9-3.5c-0.6-0.1-1.1-0.2-1.7-0.3c-0.7-0.3-1.4-0.3-2.1-0.3
							c-0.8-0.1-1.7-0.2-2.5-0.3c-0.7-0.1-1.4-0.1-2.2-0.2c-0.7-0.1-1.4-0.1-2-0.2c-0.3,0-0.6-0.1-0.9-0.1c-3.1-0.3-6.2-0.7-9.3-1
							c-6.8-0.7-13.5-1.4-20.3-2.1c-4.6-0.5-9.3-1-13.9-1.5c0-0.4,0.1-0.7,0.1-1.1c4.6,0.5,9.3,1,13.9,1.4
							c6.8,0.9,13.6,1.6,20.4,2.1c14.4,1.6,28.8,3.2,43.2,4.8c3.8,0.4,7.6,0.8,11.4,1.2C298.2,346.8,298.1,347.2,298.1,347.5z"/>
						<path fill="#F5F0BC" d="M214.2,291.8c11.2,1.1,22.3,2.2,33.5,3.4c17.9,1.9,35.7,3.9,53.6,5.8c0.5,0.1,1,0.2,1.5,0.3
							c0,0.3-0.1,0.5-0.1,0.8c-10.8-1.2-21.7-2.3-32.5-3.5c-16.3-1.8-32.6-3.5-48.9-5.2c-1-0.1-2-0.2-2.9-0.3
							c-0.7-0.1-1.4-0.2-2.1-0.2c-0.5-0.1-0.9-0.2-1.3,0.3c-0.2,0.1-0.3,0.2-0.5,0.4c0-0.4,0.2-0.9-0.2-1.3
							C214.1,292,214.1,291.9,214.2,291.8z"/>
						<path fill="#F5F0BC" d="M304.7,282.7c-0.1-1.4-1.3-1.1-2.1-1.1c-9.8-1.1-19.6-2.1-29.4-3.2c-0.8-0.1-1.8,0.2-2.2-0.8
							c1.1,0,2.3,0,3.4,0.1c10.2,1.1,20.4,2.1,30.6,3.2c0.2,0.2,0.3,0.4,0.5,0.6c-0.1,0.5-0.1,0.9-0.2,1.4
							C305.1,282.7,304.9,282.7,304.7,282.7z"/>
						<path fill="#CEC69D" d="M272.9,366.5c-0.2-0.7-0.3-1.4-0.6-2.4c3.7,0.4,7.2,0.8,10.8,1.2c0.3,0,1.2-0.1,0.9,0.7
							c-0.2,0.4-0.4,0.8-1.1,0.7c-2-0.3-4.1-0.3-6.1-0.6c-1.1-0.2-0.5,0.4-0.4,0.8C275.2,367,274,366.8,272.9,366.5z"/>
						<path fill="#3E3821" d="M274.5,276.5c4.1,0.4,8.2,0.7,12.3,1.1c0.3,0,0.5,0.1,0.8,0.1c0.8,0.1,1.5,0.3,2.3,0.4
							c4.3,0.5,8.7,1,13,1.5c-0.5,0.3-1,0.2-1.4,0.2c-8.6-0.9-17.2-1.8-25.8-2.8C275.3,276.8,274.9,276.6,274.5,276.5z"/>
						<path fill="#3E3821" d="M212.5,294c0.1-0.3,0.1-0.5,0.2-0.8c0.3,0,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0c-0.1,0.3-0.2,0.6-0.2,0.9
							c-0.4,0.8-0.4,1.7-0.3,2.6c-0.3,0.7-0.3,1.4-0.2,2.1c-0.3,1.3-0.4,2.5-0.4,3.8c-0.3,1.4-0.5,2.8-0.4,4.2c0,0.1,0,0.3,0,0.4
							c-0.3,1-0.4,2-0.3,3c-0.3,1-0.4,2-0.3,3c0,0.1,0,0.3,0,0.4l-0.1,0c-0.6-0.7-0.2-1.5-0.2-2.2c0.5-4.7,1-9.4,1.5-14.1
							C213.1,296.2,213.2,295.1,212.5,294z"/>
						<path fill="#E9E4B6" d="M302.6,302.1c0-0.3,0.1-0.5,0.1-0.8c0.2,0,0.3,0,0.5-0.1c0,0.6,0,1.2,0,1.8c0,0.1-0.1,0.3-0.1,0.4
							c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0.1-0.1,0.2,0,0.3c-0.4,4.1-0.9,8.3-1.3,12.4c-0.3,0.9-0.4,1.9-0.3,2.9
							c-0.1,0.9-0.2,1.7-0.3,2.6c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0C301.1,316.2,301.8,309.1,302.6,302.1z"/>
						<path fill="#CEC69D" d="M300.2,324.6c0.2,0,0.3,0,0.5,0c-0.7,7.2-1.2,14.5-2.4,21.6c-0.4-0.7-0.1-1.5-0.1-2.3
							C298.8,337.5,299.5,331,300.2,324.6z"/>
						<path fill="#CEC69D" d="M302.9,279.5c-4.3-0.5-8.7-1-13-1.5c-0.8-0.1-1.5-0.2-2.3-0.4c2.5-0.4,4.9,0.2,7.4,0.5
							c3.4,0.4,6.8,0.3,10.1,1.4C304.3,279.5,303.6,279.5,302.9,279.5z"/>
						<path fill="#3E3821" d="M219.4,270.6c4.4,0.3,8.8,0.7,13.1,1.4C228.1,271.9,223.7,271.4,219.4,270.6z"/>
						<path fill="#32613E" d="M304.7,282.7c0.2,0,0.3,0,0.5,0c0.3,1.8,0.1,3.6-0.6,5.4c-0.2,0-0.3-0.1-0.5-0.1
							C304.3,286.2,304.5,284.4,304.7,282.7z"/>
						<path fill="#32613E" d="M274.3,277.5c-1.1,0-2.3,0-3.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3C271.9,276.8,273.1,276.9,274.3,277.5z"/>
						<path fill="#888158" d="M268.6,367.4c-2.6-0.1-5.2-0.3-7.7-0.9C263.5,366.4,266.1,366.6,268.6,367.4z"/>
						<path fill="#888158" d="M247.9,363.7c2.5-0.2,5,0.1,7.4,0.8C252.7,364.6,250.3,364.3,247.9,363.7z"/>
						<path fill="#32613E" d="M218,271.5c-0.6,0.1-1.2,0.1-1.8,0.2C216.4,270.8,216.7,270.8,218,271.5z"/>
						<path fill="#32613E" d="M301.2,320.3c-0.1-1,0-1.9,0.3-2.9C301.8,318.4,301.7,319.4,301.2,320.3z"/>
						<path fill="#888158" d="M257.2,364.7c0.9-0.2,1.8-0.2,2.7,0.3C259,365.2,258.1,365.1,257.2,364.7z"/>
						<path fill="#3E3821" d="M235.1,272.2c0.6,0.1,1.1,0.1,1.7,0.2C236.2,272.7,235.6,272.7,235.1,272.2z"/>
						<path fill="#32613E" d="M303.2,300.4c-0.1-0.1-0.2-0.3-0.4-0.4c0.1-0.4,0.3-0.5,0.6-0.4C303.9,300.1,303.5,300.2,303.2,300.4z
							"/>
						<path fill="#32613E" d="M305.4,281.3c-0.2-0.2-0.3-0.4-0.5-0.6c0.2,0,0.4-0.1,0.5-0.1C305.7,280.8,305.8,281.1,305.4,281.3z"
							/>
						<path fill="#888158" d="M283.9,368.9c-0.5,0.2-1,0.1-1.5-0.2C283,368.5,283.5,368.6,283.9,368.9z"/>
						<path fill="#CEC69D" d="M268.3,366c0.5-0.4,0.9-0.4,1.3,0.1C269.1,366.2,268.7,366.2,268.3,366z"/>
						<path fill="#3E3821" d="M301.9,323.4c0,0.4-0.1,0.8-0.1,1.2C301.4,324.2,301.4,323.8,301.9,323.4z"/>
						<path fill="#E9E4B6" d="M214.2,292.1c0.4,0.4,0.2,0.8,0.2,1.3c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2,0-0.3,0
							C213.9,293,214,292.5,214.2,292.1z"/>
						<path fill="#3E3821" d="M271.5,276.2c0.3,0,0.6,0.1,0.8,0.1C272.1,276.5,271.8,276.5,271.5,276.2z"/>
						<path fill="#CEC69D" d="M270.8,366.3c0.3-0.3,0.6-0.3,0.8,0.1C271.4,366.3,271.1,366.3,270.8,366.3z"/>
						<path fill="#CEC69D" d="M279.7,367.2c0.3-0.3,0.6-0.3,0.8,0.1C280.3,367.2,280,367.2,279.7,367.2z"/>
						<polygon fill="#CEC69D" points="295.3,369.1 295.4,369.1 295.3,369.1 						"/>
						<path fill="#3E3821" d="M250.3,273.8c0.1,0,0.3,0,0.4,0C250.6,274.1,250.5,274,250.3,273.8z"/>
						<path fill="#3E3821" d="M298,350.9c0-0.1,0-0.3,0.1-0.4c0,0.1,0.1,0.2,0.1,0.3C298.1,350.8,298,350.8,298,350.9z"/>
						<path fill="#32613E" d="M303.1,303.4c0-0.1,0.1-0.3,0.1-0.4c0,0.1,0.1,0.2,0.1,0.3C303.2,303.3,303.1,303.4,303.1,303.4z"/>
						<path fill="#32613E" d="M302.8,305c-0.1-0.1-0.1-0.2,0-0.3c0,0.1,0.1,0.2,0.1,0.3C302.9,305,302.8,305,302.8,305z"/>
						<path fill="#888158" d="M270.3,367.5c-0.2,0-0.3,0-0.5-0.1C270,367.3,270.2,367.3,270.3,367.5z"/>
						<path fill="#888158" d="M285.2,369c-0.2,0-0.4,0-0.6-0.1C284.8,368.8,285,368.8,285.2,369z"/>
						<path fill="#888158" d="M295.3,370c-0.2,0-0.4,0-0.6,0C294.9,370,295.1,370,295.3,370z"/>
						<path fill="#3E3821" d="M297.3,357.2c0-0.1,0-0.3,0.1-0.4c0,0.1,0.1,0.2,0.1,0.3C297.4,357.2,297.3,357.2,297.3,357.2z"/>
						<path fill="#32613E" d="M300.7,323.2c0-0.1,0-0.3,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.4c0,0-0.2,0-0.2,0L300.7,323.2z"/>
						<path fill="#CEC69D" d="M277.2,366.9c0.2-0.2,0.3-0.2,0.4,0C277.5,366.9,277.3,366.9,277.2,366.9z"/>
						<path fill="#CEC69D" d="M278.5,367c0.2-0.2,0.3-0.2,0.4,0.1C278.7,367.1,278.6,367.1,278.5,367z"/>
						<path fill="#CEC69D" d="M281.4,367.4c0.1-0.2,0.3-0.2,0.4,0C281.7,367.4,281.6,367.4,281.4,367.4z"/>
						<path fill="#CEC69D" d="M295,368.8c0.2,0,0.4-0.1,0.6-0.1c-0.1,0.1-0.2,0.3-0.3,0.4c0,0,0,0,0,0
							C295.2,369,295.1,368.9,295,368.8z"/>
						<path fill="#14653A" d="M400.7,374.3c0.8-0.6,0.6-1.4,0.6-2.1c0.5-4.3,1-8.6,1.4-12.9c0.1-1.5,0.8-1.9,2.2-1.7
							c1.3,0.2,2.3,0.4,2.1,2.1c-0.5,4.3-1,8.6-1.4,12.9c-0.1,0.7-0.5,1.5,0.2,2.2c0.1,0.2,0.3,0.5,0.4,0.7c0.7,0.5,1.5,0.3,2.2,0.5
							c1.4,0.5,2.3,1.5,2.4,3c0.1,1.7-1.2,1.2-2.2,1.4c-0.2,0-0.3,0.4-0.5,0.6c-2.3-0.2-4.6-0.4-6.9-0.6c-0.2-0.1-0.4-0.2-0.5-0.2
							c0-0.1,0-0.2,0-0.4c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.2,0.1-0.3-0.1c-0.6-0.4-1-0.3-1.2,0.4c0.2,1.9-0.1,3.7-0.6,5.6
							c0,0.2,0.1,0.3,0.1,0.5c-0.1,0.7-0.1,1.4-0.2,2.1c-0.9,3.7-0.9,7.6-1.4,11.4c-2.9,0.3-5.7-0.4-8.6-0.4
							c-0.4-0.1-0.8-0.1-1.2-0.2c-0.4-0.1-0.9-0.1-1.3-0.2c-0.1,0.1-0.3,0.2-0.4,0.3c0,0.2-0.1,0.4-0.1,0.5c-0.8,0.1-1.3,1-2.1,0.6
							c0-0.3,0-0.6,0-0.8c0.8-0.5,1-1.1,0.1-1.7c0-0.3,0.1-0.6,0.1-0.9c0.3-1.1,0.9-2.2,0.4-3.4c0-0.3-0.1-0.6-0.1-0.9
							c0.8,0.1,1.7,0.2,2.5,0.3c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.6,0.1,1.1,0.7,1c1.7-0.4,3.4,0.4,5.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
							c0.4-0.3,0.3-0.7,0.4-1.1c0.6-6.2,1.3-12.5,1.9-18.7l0.1-0.1c0.4,0.1,0.8,0.3,1.2,0.2C397.5,374.5,399.2,375.7,400.7,374.3z"
							/>
						<path fill="#F5EBB0" d="M408,358.8c0-0.4,0.1-0.8,0.1-1.2c0.1-0.7,0.2-1.3,1.1-1.2c1,0.1,2,0.2,3-0.4c0.7-0.4,1.5-0.1,1.1,1.1
							c-0.2,0.7-0.5,1.3-1.1,1.7c-0.6,0.2-1,0.5-0.9,1.2c0.1,0.8,0.7,0.9,1.3,0.8c1.5-0.1,1.5,0.8,1.4,1.9c-0.5,3.7-1,7.4-1.5,11.1
							c0,0.2,0.1,0.3,0.1,0.5c-0.1,0.3-0.2,0.6-0.3,0.8c-1.1-0.4-2.1-0.9-3.3-0.8c-1.7,0.1-2-0.8-1.8-2.3c0.5-4.2,0.9-8.4,1.4-12.6
							C408.8,359,409.2,358,408,358.8z"/>
						<path fill="#E9E4B6" d="M398.6,386.1c0.4-1.8,0.8-3.7,0.6-5.6c0.4-0.1,0.8-0.3,1.2-0.4c0.1,0.1,0.2,0.1,0.3,0c0,0,0.1,0,0.1,0
							c0.2,0.1,0.4,0.2,0.5,0.2c0,0.5,0,1.1,0,1.6c-0.2,2.2-0.4,4.4-0.6,6.6c0,0.5-0.1,1-0.1,1.5c-0.1,0.8-0.3,1.6-0.4,2.4
							c0,0.5-0.1,1.1-0.1,1.6l0,0c-0.3,0.1-0.7,0-0.7,0.2c-0.2,0.5,0.3,0.5,0.6,0.6c0,0.3,0,0.5-0.1,0.8c-1.5,0.2-0.9,1.5-1.2,2.3
							c-0.4,0.9,0.5,2.3-1.1,2.6c0-0.2,0-0.3,0-0.5c0.3-3.8,1-7.6,1.1-11.4c0.1-0.7,0.1-1.4,0.2-2.1c0-0.1,0.1-0.1,0.1-0.2
							C398.8,386.3,398.7,386.2,398.6,386.1z"/>
						<path fill="#888158" d="M400.6,388.6c0.2-2.2,0.4-4.4,0.6-6.6c2.6,1.2,5.4,0.9,8.1,1.2c1.7,0.2,2.3,0.7,2.1,2.5
							c-0.8,6.2-1.2,12.5-2.2,18.7c-0.3-0.1-0.7-0.1-1-0.2c1.5-2.8,1.3-5.9,1.3-8.9c0.2-0.3,0.3-0.5,0.5-0.8
							c0.2-0.6,0.2-1.1-0.6-1.2l-0.1-0.1c0.3-1.4,0.6-2.8,0.8-4.2c0.8-4.6,0.6-4.8-4-5.2c-0.4,0-0.8-0.1-1.3-0.1
							c-3.4-0.3-3.5-1-3.9,3.2C401,387.6,401.1,388.2,400.6,388.6z"/>
						<path fill="#E9E4B6" d="M408,358.8c1.2-0.9,0.8,0.1,0.8,0.7c-0.4,4.2-0.8,8.4-1.4,12.6c-0.2,1.5,0.1,2.3,1.8,2.3
							c1.2,0,2.2,0.4,3.3,0.8c0.1,0.1,0.1,0.2,0,0.3c-0.3,0.2-0.5,0.4-0.8,0.6c-1.8-0.2-3.5-0.4-5.3-0.6c-0.1-0.2-0.3-0.5-0.4-0.7
							C406.5,369.5,407.3,364.2,408,358.8z"/>
						<path fill="#32613E" d="M406.2,375.6c1.8,0.2,3.5,0.4,5.3,0.6c0.1,0.3,0.2,0.5,0.2,0.8c0.2-0.1,0.3-0.3,0.5-0.4
							c0.4,1.3-0.1,2.5-0.2,3.8c0,0.9-0.6,1.2-1.4,1.2c-0.8-0.1-1.7-0.2-2.5-0.3c0.2-0.2,0.3-0.6,0.5-0.6c1-0.1,2.3,0.4,2.2-1.4
							c-0.1-1.5-1-2.5-2.4-3C407.7,375.9,406.9,376.1,406.2,375.6z M411.7,378.8c-0.3,0.4-0.3,0.8-0.2,1.2
							C411.8,379.6,411.8,379.2,411.7,378.8z"/>
						<path fill="#F5EBB0" d="M412.3,376.5c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1-0.3-0.2-0.5-0.2-0.8c0.3-0.2,0.5-0.4,0.8-0.6
							C412.3,375.9,412.3,376.2,412.3,376.5z"/>
						<path fill="#888158" d="M409,406.6c-0.2-0.2-0.2-0.4,0.1-0.6C409.1,406.2,409,406.4,409,406.6z"/>
						<path fill="#32613E" d="M412.6,374.4c0-0.2-0.1-0.3-0.1-0.5C412.7,374,412.8,374.2,412.6,374.4z"/>
						<path fill="#888158" d="M198.9,500.4c1.5-0.2,2.9-0.1,4.4,0.5C201.8,501,200.3,500.9,198.9,500.4z"/>
						<path fill="#888158" d="M191.2,499.6c0.4-0.2,0.7-0.2,1,0.2C191.8,499.9,191.5,499.8,191.2,499.6z"/>
						<path fill="#888158" d="M205.2,501.1c0.2-0.2,0.4-0.2,0.6,0.1C205.6,501.1,205.4,501.1,205.2,501.1z"/>
						<path fill="#888158" d="M379.1,558.8c-0.1,0.5-0.1,1-0.2,1.5c-2.5-0.4-5-0.8-7.5-1c-2.3-0.2-4.7-0.2-7.1-0.6
							c-1-0.2-1.3-0.7-1.1-1.7C368.6,557.9,373.8,558.4,379.1,558.8z"/>
						<path fill="#888158" d="M356.3,561.2c-1.2-0.3-2.8-0.6-4.5-0.5c-0.9,0-1.4-0.5-1.3-1.3c0.2-1,0.8-0.5,1.4-0.4
							c2.1,0.3,4.2,0.4,6.3,0.7c0.5,0.1,1.5-0.6,1.3,0.8c-0.2,1.2-1,0.8-1.6,0.8C357.6,561.4,357.2,561.3,356.3,561.2z"/>
						<path fill="#B7AF8B" d="M372.5,561.2c1.3-0.7,2.6,0,3.9,0c0.8,0,0.7,0.7,0.7,1.3c-0.1,0.5-0.3,0.9-0.8,0.7
							c-1.3-0.5-2.6-0.8-4-0.6C372.9,562.3,373,561.8,372.5,561.2z"/>
						<path fill="#888158" d="M372.5,561.2c0.5,0.6,0.4,1-0.2,1.4c-0.8-0.1-1.7-0.2-2.5-0.3c0-0.5,0.3-0.9,0.6-1.3
							C371,561.1,371.7,561.2,372.5,561.2z"/>
						<path fill="#B7AF8B" d="M370.3,561.1c-0.3,0.4-0.6,0.8-0.6,1.3c-0.5,0.3-1.1,0.4-1.4-0.1c-0.3-0.5,0.2-1.1,0.5-1.5
							C369.3,560.2,369.9,560.5,370.3,561.1z"/>
						<path fill="#FBEFB2" d="M266,243.9c4.9,0,9.6,1.6,14.5,1.8c0.7,0,0.9,0.4,0.9,1.1c-0.7,6.5-1.4,13-2,19.4
							c-0.2,2-0.9,2.4-2.8,2.2c-19.7-2.2-39.4-4.3-59.1-6.4c-2-0.2-2.4-0.9-2.2-2.8c0.8-6.1,1.4-12.1,2-18.2
							c0.2-1.8,0.4-2.5,2.7-2.1c4.2,0.8,8.6,1,12.9,1.5c0.2,1.2,0.3,2.5,0.5,3.7c0.1,0.7,0.5,0.5,0.8,0.1c0.6,0.3,1.3,0.5,2,0.2
							c0.3,0.7,0.7,0.8,1.3,0.3c0.5,0,0.9,0.1,1.4,0.1c1,0.6,2.1,0.9,3.3,0.4c0.8,0,1.5-0.2,2.4-0.1c1.6,0.3,3.3,1,4.8,0.8
							c2.9-0.3,5.6,0.8,8.5,0.6c1.6-0.1,3.6-0.5,5.2,0.5c1,0.6,1.9,0.7,2.6-0.3c0.7-1,0.1-1.7-0.5-2.5
							C265.6,244.5,265.9,244.2,266,243.9z M246,251.1c0.1,0,0.2-0.1,0.3-0.2c0.6,2,2.5,1.3,3.7,1.3c2-0.1,3.9-0.2,5.8,0.4
							c0.9,0.3,2.1,0.9,2.8,0.6c2.5-1.2,4.7,0.6,7.3,0.2c2.7-0.4,5.8,1.5,9,0.7c0.1,0,0.3,0.2,0.3,0.4c0.4,0.7,1,0.7,1.6,0.4
							c0.5-0.3,0.6-0.8,0.6-1.3c-0.1-0.8-0.6-1-1.3-1.1c-1.1-0.1-2.3-0.6-3.3-0.6c-2.4,0.1-4.5,0.2-6.8-0.7
							c-1.9-0.7-4.3-0.6-6.4-0.6c-1.9,0-4-1.9-5.7,0.3c-0.3-1.8-1.9-0.4-2.8-1c-1.3-0.9-3-0.9-4.6-0.1c-0.4-0.6-1-0.5-1.6-0.3
							c-0.5-0.5-1.1-1.1-1.8-0.5c-0.7,0.7,0,1.4,0.1,2.1c0.7-0.2,0.6-1.2,1.4-1c0,0.3,0,0.6,0,0.8C245,251.5,245.4,252.2,246,251.1z
							 M230,252.3c-0.9-0.2-1.9-1.3-2.6,0.3c-1.1-1.3-2.6-0.9-4-0.9c-0.8,0-0.6,1-0.4,1.9c0.6-0.7,1-1.5,1.7-0.2
							c0.4,0.8,1.6,0.9,2.5,1.1c3.4,0.6,6.8,0.1,10.2,1c1.2,0.3,3,0.4,3.6-0.2c1.1-1.2,1.1,0.3,1.6,0.3c2.8,1.1,5.7,0.5,8.6,1.2
							c0.9,0.2,0.9-0.8,0.9-1.4c0-0.8-0.6-1.2-1.3-1c-2,0.4-3.9-0.3-5.9-0.2C239.8,253.3,234.9,252.8,230,252.3z M269,264.1
							c2,1.2,4.3,0.7,6.4,1.1c0.5,0.1,1.1,0.2,1.1-0.5c0-0.7-0.7-0.6-1.1-0.7c-2.1-0.2-4.2-0.4-6.4-0.6c-16.2-1.9-32.5-3.5-48.8-4.5
							C236.5,260.8,252.6,263.1,269,264.1z M238.3,250.5c0.6,0.1,0.8,0.2,1,0.2c0.7-0.1,1.8,0.6,2-0.8c0.2-1.6-1.1-1.3-1.9-1.3
							c-2.8,0.1-5.7-0.1-8.4-1c-1.4-0.4-2.7,0.5-3.9,0c-1.7-0.6-3.3-1.3-5.1-1.3c-0.3,0-0.7-0.6-0.9,0.1c-0.2,0.7-0.2,2-0.1,2
							c1.5-0.5,2.2,1.4,3.4,1.1c1.7-0.5,3.2-0.1,4.7,0.5c1,0.4,2.3-0.7,2.6-0.4C234,251.5,236.6,249.4,238.3,250.5z M256.1,257
							c0.4-0.6-0.2-1.6,0.7-1.7c1.1-0.1,0.3,0.9,0.6,1.4c0.4,0.8,2.7,0.9,3.7,0.1c-0.2-0.8-1.3,0.3-1.5-0.7c-0.1-0.9,0.4-0.9,1-0.8
							c0.1,0,0.3-0.3,0.3-0.4c-0.1-0.4-0.4-0.7-0.7-0.7c-1.7,0.5-3.3-0.7-4.9-0.6c-0.7,0-1.5-0.2-1.8,0.6c-0.3,0.8-0.7,1.7,0.1,2.4
							c0.9-0.3,0.2-1.9,1.3-1.7C255.7,255.2,254.7,256.8,256.1,257z M262.6,256c-0.1,0.9,1.1,1.8,2.3,2c0.8,0.1,1.6,0,1.6-1.1
							c0.1-2.6-2.4-0.9-3.4-1.9C262.8,254.7,262.7,255.6,262.6,256z M274.3,260c-1.8-1.3-3.9-1.3-6-1.5c-0.4,0-0.7,0-0.7,0.5
							c0,0.4,0.4,0.7,0.7,0.7C270.3,259.2,272.2,260.6,274.3,260z"/>
						<path fill="#E9E4B6" d="M234.3,244.4c-0.3,0.4-0.7,0.6-0.8-0.1c-0.2-1.2-0.4-2.5-0.5-3.7c10.5,0.7,21,1.8,31.4,3
							c0.6,0.1,1.1,0.2,1.7,0.3c-0.1,0.4-0.4,0.6-0.7,0.7c-1.9,0.1-4,0.9-5.5,0.2c-1.4-0.6-3.1-0.9-4.2-0.9
							c-2.5-0.1-5.2-1.7-7.6,0.2c-0.1,0-0.3,0-0.3,0c-1.1-2.7-1.7,0.1-2.6,0.3c0.3-1.5-0.3-2.2-1.8-1.9c-2.1,0.4-4.3-0.2-6.4-0.3
							C234,241.8,233.7,241.8,234.3,244.4z"/>
						<path fill="#32613E" d="M409.4,448.8c0.3,0,0.6,0.1,0.9,0.1c0.1,0,0.3,0,0.4,0.1c4.4,0.7,8.7,1.3,13.1,1.6
							c0.3,0,0.6,0.1,0.8,0.1c1,0.5,2.5-0.3,3,1.2c-0.7,2.8-0.7,5.6-1,8.5c-4,0.3-8-0.6-12-1c-1.9-0.2-3.8-0.5-5.7-0.7
							c-1.2-0.1-1.9-0.5-1.7-2c0.4-2.5,0.6-5.1,0.9-7.6C408.6,449,409,448.9,409.4,448.8z M410.4,451.7c-0.1,0-0.2,0.1-0.2,0.1
							c0,0.1,0,0.2,0.1,0.4c0,0.3,0,0.5,0,0.8c-0.3,0.3-0.6,0.5,0,0.8c1.2-0.6,2.8,0.8,3.9-0.7c0.3-0.2,0.3-0.3,0-0.4
							c-0.9-0.6-1.9-0.6-2.9-0.7C411,451.4,410.8,451.3,410.4,451.7L410.4,451.7z M418.5,452.9c-0.3-0.3-0.6-0.3-0.9-0.2
							c-0.8,0.3-2.2-1-2.4,0.4c-0.2,1.1,1.3,1.1,2.2,1.4c0.6,0.1,1.2,0.3,1.8-0.2c0.8,0.1,1.5,0.2,2.2,0.5c1.1,0.4,2.3,0.6,3.5,0.2
							c0.6-0.2,1.1-0.5,1-1.2c-0.1-0.7-0.7-0.7-1.2-0.6c-1.6,0.2-3-0.4-4.5-0.4c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0-0.4,0
							C419,453,418.8,452.9,418.5,452.9z M418.1,457.7c0.9,0.1,1.8,0.2,2.7,0.3c0.7,0.1,1.7,0.4,1.7-0.9c0-1.1-0.8-1.1-1.6-1.3
							c-2.4-0.5-5,0.4-7.4-0.8c-0.6-0.3-1.1,0.1-1.1,0.8c0,0.6,0.1,1,0.9,1C415,457.1,416.5,457.7,418.1,457.7z"/>
						<path fill="#14653A" d="M397.9,449.1c0-0.2,0-0.5,0.1-0.7c4-0.6,5.9,2.2,7.9,4.8c0.6,0.8,0.4,1.2-0.3,1.7
							c-0.9,0.7-1.7,1.4-2.6,2.1c-0.2-0.1-0.4,0-0.3,0.3c-0.3,0.1-0.6,0.3-0.8,0.4c-1.2-0.2-2.5-0.2-3.3-1.3c0-0.3,0.1-0.8,0.1-0.8
							c-2.5-0.6-1.1-2.6-1.5-3.9C399.7,450.8,399.7,450.8,397.9,449.1z"/>
						<path fill="#32613E" d="M398.5,456.4c0.8,1.1,2.1,1.1,3.3,1.3c-1.4,1.4-3.1,0-4.6,0.5C396.7,456.9,397.9,456.9,398.5,456.4z"
							/>
						<path fill="#CEC69D" d="M426.6,460.3c0.3-2.8,0.4-5.7,1-8.5C427.8,454.7,427.7,457.6,426.6,460.3z"/>
						<path fill="#CEC69D" d="M412.1,463.4c-1.2,0.1-2.3-0.1-3.4-0.4C409.9,462.6,411.1,462.8,412.1,463.4z"/>
						<path fill="#CEC69D" d="M409.4,448.8c-0.4,0.1-0.8,0.1-1.2,0.2C408.5,448.3,408.9,448.5,409.4,448.8z"/>
						<path fill="#CEC69D" d="M424.7,450.6c-0.3,0-0.6-0.1-0.8-0.1C424.1,450.2,424.4,450.2,424.7,450.6z"/>
						<path fill="#CEC69D" d="M410.7,449c-0.1,0-0.3,0-0.4-0.1C410.4,448.8,410.6,448.8,410.7,449z"/>
						<path fill="#CEC69D" d="M402.7,457.3c0-0.3,0.1-0.3,0.3-0.3C402.9,457.2,402.8,457.2,402.7,457.3z"/>
						<path fill="#FBEFB2" d="M460.1,264.8c5.7,0.6,11.4,1.1,17.1,1.7c1.4,0.1,1.5,0.9,1.4,2.1c-0.4,3.2-0.7,6.3-1,9.5
							c-1.3-0.1-2.8-1-3.8,0.6c-0.3,0.1-0.7,0.2-1,0.2c-1.8-0.5-3.6-1.4-5.5-0.5c-0.8-0.6-1.6-0.8-2.6-0.4c-0.2-0.4-0.4-0.6-0.8-0.6
							c-2.4,0.2-4.8-0.5-7.2-0.6c-1.1,0-2.5,0-3.4-0.5c-2.3-1.4-2.5-1.4-3,1.1c-0.1,0.3-0.3,0.7,0.1,0.9c0.5,0.2,0.7-0.2,0.9-0.6
							c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0-0.5,0.5-0.5c-0.2,1.3,0,1.9,1.7,2.1c2.4,0.3,4.7,0.6,7.1,0.5c1.2-0.1,2.2,1.3,3.4,0.3
							c0.3,0,0.6,0.1,0.8,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.3,0.4,0.6,0.3,0.9-0.1c0.3,0,0.6,0,0.9,0.1
							c1.8,0.9,3.6,1,5.5,0.6c1.3,0.4,2.3,0.3,2.4-1.4c0.7,0.1,1.3,0.1,2,0.2c-0.3,2.8-0.7,5.5-0.9,8.3c-0.1,1.8-0.8,2.3-2.5,2.1
							c-13.6-1.5-27.3-3-40.9-4.4c-6.7-0.7-13.4-1.5-20.1-2.1c-1.4-0.1-1.9-0.6-1.8-2.1c0.7-6.4,1.5-12.8,2-19.3
							c0.2-2.3,1.8-1.9,3.1-1.8c4.2,0.3,8.3,0.9,12.5,1.3c1.7,0.2,3.4,0.4,5.1,0.5c0.9,0.1,1.8,0.2,2.5,1c-0.5,0.1-0.8,0.4-1,0.8
							c-0.8,0.5-2.3-0.7-2.7,1c0.1,0.4-0.8,1,0.2,1.3c0.1,0.5,0.5,0.6,0.9,0.6c0,0.4-0.3,1,0.4,1c0.9,0,1.7-0.1,2.2,0.8
							c1.3-0.8,3.1,1.4,4.1-0.7c1.1,0.5,2.3,0.1,3.4,0.4c0.5,0.9,1.2,1,2.1,0.3c0.3,0,0.6,0,0.8,0c0.1,2.2,1.3,1,2.2,0.7
							c0.2,0.1,0.5,0.2,0.7,0.3c0.6,1.4,1.2-0.1,1.7,0c1.4-0.1,2.7-0.5,4.2-0.2c1.1,0.2,2.5,1.4,3.7,0.1c1.9,0,1.2-1.4,1.1-2.2
							C460.3,266.2,459.9,265.5,460.1,264.8z M445.6,271.4c-0.9,0.7-1.5-0.7-2.5-0.3c-1.4,0.5-2.9,0.2-4.2-0.6
							c-0.2-0.2-0.4-0.4-0.7-0.5c-4.3-0.5-8.6-0.4-12.9-1.4c0-1.1-0.7-1.2-1.4-0.8c-1.2,0.7-1,1.9-0.6,3c1.3-0.3,0.5-2.2,2-2.2
							c-0.5,1.3-0.6,2.9,1.2,2.6c2.7-0.5,5,1.3,7.7,0.9c1.3-0.2,2.7,0.3,3.9-0.7c0,0.5,0.2,0.9,0.8,1.1c0.2,0.5,0.4,0.9,0.9,0.3
							c0.9,0.3,1.7-0.4,2.5-0.3c0.1,0.5,0.4,0.8,1,0.6c0.8,0.3,1.6,0.3,2.3,0.2c1,0.5,1.9,0.4,2.6-0.4c0.5-0.5,0.1-1.2-0.3-1.7
							C447,269.7,446.4,271.3,445.6,271.4z M431,274.7c0.2,0.6-1.2,1.7,0.4,1.7c3.3,0,6.5,1.2,9.8,1c0.6,0,1.7,0.3,1.7-1
							c0-1.2-0.6-1.1-1.6-1c-0.9,0.1-2,0.2-2.7-0.3c-1.9-1.3-3.9-0.2-5.8-0.6c-0.2-0.2-0.4-0.4-0.7-0.5c-2.4-0.7-4.9-0.6-7.4-0.6
							c-0.8,0-1.4,0.3-1.8-0.8c-0.3-0.9-3.1-1.2-3.8-0.5c-0.4,0.4-0.2,1,0.3,0.9c1.6-0.3,1.5,0.6,1.5,1.7c0,0.2,0.3,0.5,0.5,0.6
							c0.5,0.1,0.5-0.3,0.6-0.7c0.1-0.4,0.1-0.8,0.2-1.2c1.1,0.4-0.4,2.1,1,2c0.9,0,2.4,1.2,2.5-1.1
							C427.3,276.4,428.5,276.9,431,274.7z M414.6,279.5c0,0.1,0,0.3,0,0.4c19.5,2.1,38.9,4.2,58.4,6.3c0-0.1,0-0.3,0-0.4
							C453.5,283.7,434.1,281.6,414.6,279.5z M461.1,273.3c-0.2,1.6,0.7,1.7,1.7,1.8c0.6,0.1,1.4,0,1.8,0.4c1,1,1.5-0.1,2.2-0.3
							c0.5-0.2,0.6-0.8,0.4-1.3c-0.2-0.8-0.9-0.9-1.7-1c-4.7-0.6-9.6-0.6-14.3-1.5c-1.2-0.2-1.4,0.8-1.7,1.5c-0.4,1.1,0.6,0.8,1.1,1
							c1.3,0.7,3-0.7,4.1,1c0,0,0.4-0.2,0.5-0.3c0.7-0.5,1.4-0.2,2.2-0.1C458.6,274.6,460.3,275.6,461.1,273.3z M416.4,272.5
							c0.5,0.5,0.2,1,0.3,1.5c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.4-0.2,0.5-0.3c0.2-0.7,0.4-1.4,0.1-2.1c-0.3-0.7-0.7-1-1.5-0.9
							c-0.5,0.1-1,0-1.4-0.2c-0.8-0.3-1.7-0.2-2.1,0.5c-0.5,0.7-0.7,1.6-0.1,2.5c0.2-0.2,0.4-0.2,0.4-0.3c0.3-0.5,0.1-1.5,1-1.4
							c0.9,0,0.3,0.9,0.5,1.3c0.1,0.2,0.2,0.6,0.4,0.6c0.4,0.1,0.6-0.2,0.7-0.6C415.9,273.3,415.8,272.8,416.4,272.5z M447.2,278.2
							c1.1,0.3,1.7-0.1,1.8-1.1c0.1-0.8-0.2-1.5-1.1-1.4c-1.2,0.2-3.2-0.6-3.4,0.9C444.3,278.2,446.5,277.4,447.2,278.2z"/>
						<path fill="#E9E4B6" d="M460.1,264.8c-0.2,0.8,0.2,1.4,0.4,2.2c0.2,0.8,0.8,2.2-1.1,2.2c0.4-1,0.7-2.2-0.6-2.4
							c-1.2-0.2-2.8-1-3.6,1.2c-0.6-2.3-1.5-2.3-2.6-0.2c-0.2-1.1-0.1-2-1.3-2.1c-1.5-0.1-1.2,1.2-1.9,2.4c-0.6-2-2.2-2.1-3.7-2.7
							c-2.3-1-2.3,1.3-3.4,2.1c-0.2-1.1,1-2.2-0.3-2.5c-1-0.2-2.1-0.2-3.2-0.4c-0.6-0.2-1,0.2-1.1,0.9c-0.4,1.9-0.1,2.2,2.8,2.2
							c-1,2.2-2.8-0.1-4.1,0.7c-0.5-0.8-1.4-0.8-2.2-0.8c-0.7,0-0.4-0.6-0.4-1c0.1-1.6,1.2-1.9,2.5-1.7c0-0.8-0.7-0.7-0.9-1.2
							c0.2-0.4,0.6-0.7,1-0.8c2.8-0.6,5.6,0.2,8.4,0.3C449.9,263.3,455,264.2,460.1,264.8z"/>
						<path fill="#CEC69D" d="M473.7,278.6c1-1.6,2.5-0.7,3.8-0.6c0,0.4-0.1,0.9-0.1,1.3c-0.7-0.1-1.3-0.1-2-0.2
							C475,278.5,474.3,278.6,473.7,278.6z"/>
						<path fill="#51727B" d="M427.8,353.6c1.9-0.6,3.9,0.1,5.8-0.1c0.9-0.1,0.8,0.7,0.9,1.4c0,0.9-0.5,1.2-1.2,1
							c-1.7-0.3-3.4-0.6-5.2-0.9C428.2,354.5,428.2,354,427.8,353.6z"/>
						<path fill="#51727B" d="M429.3,351c0.2-1,0.8-0.9,1.6-0.7c1.8,0.5,3.5,1.4,5.5,0.6c0.4-0.2,0.9-0.1,1,0.4
							c0.1,0.5-0.2,0.7-0.7,0.8c-1.4,0.3-2.9,0.9-4.3,0.2c-1.2-0.6-2.5,0.4-3.7-0.5C429.1,351.7,429.3,351.4,429.3,351z"/>
						<path fill="#51727B" d="M427.7,351.8c-1.8,0.3-3.4-0.7-5.2-0.5c-0.9,0.1-0.8-0.8-0.7-1.4c0.2-0.7,0.5-0.8,1.2-0.4
							c1.2,0.7,2.7-0.5,3.8,0.7C425.7,351.5,427.3,351.3,427.7,351.8z"/>
						<path fill="#888158" d="M427.8,353.6c0.5,0.4,0.4,0.9,0.3,1.4c-1-0.1-1.9-0.2-2.9-0.3c-0.8-0.1-1.3-0.6-1-1.4
							c0.3-0.7,0.8-0.8,1.5-0.5C426.3,353.1,427.1,353.3,427.8,353.6z"/>
						<path fill="#E9E4B6" d="M418.9,355.7c-0.1-3.2-0.2-6.3,1.2-9.2C419.8,349.6,419.3,352.6,418.9,355.7z"/>
						<path fill="#B7AF8B" d="M427.7,351.8c-0.3-0.5-1.9-0.3-0.8-1.6c0.7,0.6,1.8,0,2.5,0.7c-0.1,0.4-0.3,0.7-0.6,0.9
							C428.4,351.8,428,351.8,427.7,351.8z"/>
						<path fill="#888158" d="M207.2,582.9c-2,0.2-3.8-1.1-5.9-0.8c-0.5,0.1-0.8-0.5-0.8-1c0.1-0.6,0.5-0.6,0.9-0.5
							c1.1,0.3,2.4-0.2,3.3,0.9c0.4,0.5,0.7,0.2,1-0.2c0.5-0.6,1-0.6,1.6,0C207.3,581.8,207.3,582.4,207.2,582.9z"/>
						<path fill="#888158" d="M200.5,585.6c0.1-0.4,0.1-0.8,0.2-1.2c1.3-0.3,2.4,0.1,3.3,1.1C203.3,585.9,201.5,586,200.5,585.6z"/>
						<path fill="#B7AF8B" d="M207.2,582.9c0-0.6,0.1-1.1,0.1-1.7c0.5,0,0.9,0.1,1.4,0.1c-0.1,0.4-0.1,0.8-0.2,1.2
							C208.2,583.1,207.7,582.9,207.2,582.9z"/>
						<path fill="#888158" d="M208.5,582.6c0.1-0.4,0.1-0.8,0.2-1.2c0.4-0.1,0.8-0.2,1.2,0.2c0,0.3-0.1,0.7-0.1,1
							C209.3,582.6,208.9,582.6,208.5,582.6z"/>
						<path fill="#FBEFB2" d="M209.3,579.2c0.2-0.2,0.3-0.3,0.5,0c0,0.4,0.5,1-0.1,1.1C208.9,580.4,209.4,579.6,209.3,579.2z"/>
						<path fill="#FBEFB2" d="M199,579.5c0.1-0.3,0.1-0.7,0.6-0.6c0,0,0.1,0.1,0.1,0.1C199.7,579.5,199.3,579.4,199,579.5
							C199,579.5,199,579.5,199,579.5z"/>
						<path fill="#F5F0BC" d="M211.7,315c16.2,1.7,32.5,3.4,48.7,5.1c0.7,0.1,1.4,0.2,2.1,0.3c-0.3,1.2-1.2,0.7-1.9,0.6
							c-2.9-0.4-5.9-0.6-8.8-1.2c-2-0.4-4.2-1-6.3-0.1c-0.3,0.1-0.9,0-1.2-0.2c-0.8-0.4-1.7-0.6-2.5-0.4c-4.6,0.9-9-0.8-13.4-1.1
							c-2.1-0.2-3.3,0.1-3.1,2.5c-0.5-0.3-1.1-0.4-1.6-0.3c1.5-2.4,1.5-2.5-1.6-2.8c-0.7-0.1-1.4-0.2-2.1-0.2
							c-0.4,0-0.9-0.1-1.1,0.4c-0.3,0.5-0.1,0.9,0.3,1.3c1.3,1.2,2.8,1.4,4.5,1.3c0.1,0.8,0.7,1.6-0.5,2.1c-0.8,0.1-1.6-1.1-2.1-0.3
							c-0.7,1.2-2.3,2-2.2,3.4c0.1,1.1,1.2,1.9,2.5,1.9c0.5,0,0.9-0.2,1.4-0.3c-0.1,0.6-0.3,1.1-0.4,1.7c-1.2-0.2-2.3-0.3-3.4,0.4
							c-2.3,1.3-4.6,0.2-6.8-0.3c-0.8-0.2-1.1,0-1.6,0.3l0,0c0-0.5,0.2-0.9-0.3-1.3c0-0.4,0.1-0.9,0.1-1.3c0.7-0.3,0.5-1.1,0.7-1.6
							c1.4,0.1,1.9-0.7,2-2.1c0.1-1.3-0.8-1.2-1.6-1.3c0-0.4,0-0.7-0.4-0.9c-0.1-0.1-0.1-0.2-0.1-0.4c0.2-0.6,0.3-1.2,0.5-1.7
							c0.2,0.1,0.4,0.1,0.6,0.2c1.2,0.8,1.9-0.5,2.8-0.7c0.3-0.1,0.5-0.5,0.3-0.9c-0.5-0.9-1.5-0.5-2.3-0.7
							c-0.4-0.1-0.8-0.1-1.2-0.1c0-0.3-0.1-0.6-0.1-0.9C211.5,315.2,211.6,315.1,211.7,315z M215.5,326.3c1.2-1.2,2.3-2,3.1-3
							c1-1.3,0.9-2.7-0.4-3.8c-1.2-1-3.3-0.8-4.3,0.3c-0.4,0.4-0.9,0.9-0.3,1.4c0.4,0.4,0.9,0.4,1.3,0c0.6-0.7,1.3-1.4,2.1-0.4
							c0.8,1.1-0.1,1.8-0.8,2.5c-1,0.9-2.1,1.7-3,2.6c-0.9,0.9-1.1,1.8,0.7,1.9c0.9,0.1,1.8,0.2,2.7,0.3c0.7,0,1.8,0.6,2-0.5
							c0.1-1.3-1.2-0.8-1.9-1C216.5,326.5,216.2,326.5,215.5,326.3z"/>
						<path fill="#F5F0BC" d="M209.2,338c4.6,0.5,9.3,1,13.9,1.5c0.3,0.4,0.8,0.3,1.2,0.4c-0.3,1-1.1,0.7-1.7,0.6
							c-2.8-0.3-5.7-0.5-8.5-0.9c-1.4-0.2-2.4,0.2-3,1.4c-0.6,0.9-1.3,1.3-2.3,1.5c0-1.1,0-2.3,0.1-3.4
							C208.9,338.8,209.1,338.4,209.2,338z"/>
						<path fill="#F5F0BC" d="M223.2,338.4c-4.6-0.5-9.3-1-13.9-1.4c0-0.1,0-0.2-0.1-0.3c0.1-0.4,0.2-0.9,0.4-1.3
							c3.8,0.9,7.8,1,11.7,1.7c0.7,0.1,1.8-0.3,2,1C223.2,338.2,223.2,338.3,223.2,338.4z"/>
						<path fill="#3E3821" d="M208.8,339.2c0,1.1,0,2.3-0.1,3.4c0,0.3-0.1,0.6-0.1,0.9c-0.3,1.1-0.4,2.2-0.4,3.4
							c-0.2,0.4-0.4,0.9-0.6,1.3C207.9,345.1,208,342.1,208.8,339.2z"/>
						<path fill="#CEC69D" d="M210.9,320.5c0.4,0.2,0.4,0.5,0.4,0.9c-0.1,1.1-0.2,2.3-0.4,3.4c-0.2,0.6,0.1,1.3-0.7,1.6
							C210.3,324.4,210.4,322.4,210.9,320.5z"/>
						<path fill="#888158" d="M211.4,315.3c0,0.3,0.1,0.6,0.1,0.9c-0.1,0.7-0.1,1.4-0.2,2.1c-0.2,0.6-0.3,1.2-0.5,1.7
							C210.7,318.5,210.7,316.9,211.4,315.3z"/>
						<path fill="#888158" d="M225,362.6c-1.5,0.1-3,0-4.4-0.5C222.1,362,223.6,362.1,225,362.6z"/>
						<path fill="#CEC69D" d="M210,333.3c-0.1,0.3-0.1,0.7-0.5,0.8c-0.1-1.3,0-2.5,0.4-3.8c0.1,0,0.3,0.1,0.4,0.1c0,0.1,0,0.2,0,0.3
							C210.2,331.6,210.1,332.4,210,333.3z"/>
						<path fill="#888158" d="M230.5,363.1c-1.2,0.2-2.4,0-3.5-0.4C228.2,362.6,229.4,362.7,230.5,363.1z"/>
						<path fill="#888158" d="M209.6,335.4c-0.1,0.4-0.2,0.9-0.4,1.3c-0.5-0.9-0.2-1.8,0.2-2.6C209.5,334.4,209.5,334.9,209.6,335.4
							z"/>
						<path fill="#F5F0BC" d="M210.4,330.4c-0.1,0-0.3-0.1-0.4-0.1c0-0.4,0.1-0.9,0.1-1.3c0.1,0,0.3,0,0.4,0l0,0
							C210.5,329.5,210.4,329.9,210.4,330.4z"/>
						<path fill="#CEC69D" d="M210.5,329c-0.1,0-0.3,0-0.4,0c-0.2-0.4-0.1-0.8,0.1-1.2C210.7,328,210.5,328.5,210.5,329z"/>
						<path fill="#3E3821" d="M206.8,359.5c0.1,0,0.3,0,0.4,0.1C207,359.8,206.9,359.8,206.8,359.5z"/>
						<path fill="#888158" d="M218.2,361.9c-0.2,0-0.4,0-0.6,0C217.9,361.6,218,361.6,218.2,361.9z"/>
						<path fill="#888158" d="M219.5,362c-0.2,0-0.4-0.1-0.6-0.1C219.1,361.7,219.3,361.7,219.5,362z"/>
						<path fill="#888158" d="M207.4,340.4c-0.2-0.2-0.2-0.4,0.1-0.6C207.5,340,207.5,340.2,207.4,340.4z"/>
						<path fill="#888158" d="M212.3,294.6c-0.2-0.2-0.1-0.4,0.1-0.6C212.5,294.2,212.5,294.4,212.3,294.6z"/>
						<path fill="#888158" d="M234.8,363.6c-0.2,0-0.4,0-0.6-0.1C234.4,363.3,234.6,363.3,234.8,363.6z"/>
						<path fill="#3E3821" d="M206.9,354.4c0,0.1,0,0.3,0,0.4C206.6,354.6,206.6,354.5,206.9,354.4z"/>
						<path fill="#888158" d="M211.7,300.1c-0.2-0.2-0.1-0.4,0.1-0.6C211.9,299.7,211.9,299.9,211.7,300.1z"/>
						<path fill="#888158" d="M206.7,347.6c-0.2-0.2-0.2-0.4,0.1-0.6C206.7,347.2,206.7,347.4,206.7,347.6z"/>
						<path fill="#888158" d="M207.6,338.6c-0.2-0.2-0.2-0.4,0.1-0.6C207.7,338.3,207.7,338.5,207.6,338.6z"/>
						<path fill="#888158" d="M210.1,314c0.1-0.2,0.1-0.4,0.2-0.5C210.5,313.7,210.3,313.9,210.1,314z"/>
						<path fill="#888158" d="M209.5,320.9c-0.2-0.2-0.2-0.4,0.1-0.6C209.6,320.5,209.5,320.7,209.5,320.9z"/>
						<path fill="#F5F0BC" d="M417,234.2c1.5,0.4,2.8,1,2.3,2.9c-0.7-0.1-1.4-0.3-1.9,0.3C415.8,236.5,416.9,235.3,417,234.2z"/>
						<path fill="#CEC69D" d="M417.3,237.4c0.6-0.5,1.3-0.4,1.9-0.3c0,0.2,0,0.4,0,0.7C418.7,237.6,417.9,237.8,417.3,237.4z"/>
						<path fill="#CEC69D" d="M406.3,229.6c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C406.3,229.3,406.3,229.5,406.3,229.6z"/>
						<path fill="#CEC69D" d="M406.1,230.9c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C406.2,230.6,406.1,230.7,406.1,230.9z"/>
						<path fill="#CEC69D" d="M415.6,241.4c0-0.2,0-0.4,0-0.6C415.9,241,415.9,241.2,415.6,241.4z"/>
						<path fill="#3E3821" d="M404.6,248.6c1.5-0.1,2.9-0.1,3.6,1.5c0.5,1.3-0.1,2.3-1.1,3.1c-1.5,0.2-3,0.3-3.7-1.4
							C402.8,250.4,403.5,249.4,404.6,248.6z"/>
						<path fill="#3E3821" d="M395.4,251.9c-0.1,0-0.4,0.1-0.4,0.1c-1.1-1.1-2.2-1.2-3.5-0.3c-0.5,0.3-0.8-0.4-0.8-0.9
							c0.1-0.6,0.1-1.3,0.2-1.9c0.1-0.6-0.1-1.4,0.7-1.5c1.2-0.2,0.6,1,1,1.4c0.5,0.2,0.7-0.1,0.9-0.4c0.4-0.5,0.9-1,1.6-0.7
							c1,0.4,0.5,1.2,0.5,1.8C395.6,250.3,395.4,251.1,395.4,251.9L395.4,251.9z"/>
						<path fill="#3E3821" d="M388.9,247.3c0.6,1.3,0.1,1.6-1.1,1.1c-0.5-0.2-1-0.7-1.5-0.1c-0.3,0.3-0.3,0.8-0.2,1.2
							c0.4,0.9,1,0.4,1.6,0.3c0.5-0.1,1.1-0.2,1.3,0.4c0.2,0.6-0.4,0.8-0.9,1c-1.2,0.4-2.4,0.4-3.3-0.6c-0.7-0.8-0.7-1.7-0.3-2.6
							c0.6-1.3,1.7-1.3,2.9-1.1C387.9,246.9,388.4,247.1,388.9,247.3C388.8,247.2,388.9,247.3,388.9,247.3z"/>
						<path fill="#3E3821" d="M397,252.2c0.1-1.1,0.2-2.1,0.3-3.2c0-0.5,0.1-1.1,0.7-1.1c0.6-0.1,0.9,0.1,1,0.8
							c0.2,1.2,0.8,1.3,1.4,0.2c0.3-0.5,0.5-0.8,1.1-0.6c0.6,0.3,0.4,0.8,0.4,1.3c0,0.4-0.1,0.8-0.1,1.3c-0.1,0.6,0.2,1.6-0.8,1.8
							c-0.9,0.2-0.8-0.7-1-1.3c-0.4-0.8-1.1-0.9-1.3,0.1c-0.2,1-0.8,1-1.6,1l0-0.1L397,252.2z"/>
						<path fill="#CCDEC7" d="M319.5,578.3c-1.2-0.1-2.5-0.3-3.7-0.4c-0.5-0.1-1-0.2-0.9-0.9c0.1-0.6,0.5-0.8,1.1-0.7
							c0.8,0.1,1.5,0.2,2.3,0.2c0.9,0.1,2.4,0.7,1.1-1.3c-0.5-0.8,0.4-1,0.9-0.8c1.4,0.6,2.3,1.9,2.9,3.2c0.4,0.9-0.9,1.3-1.6,1.7
							c-0.7,0.6-1.6,1.6-2.6,1C317.8,579.6,319.7,579.3,319.5,578.3z"/>
						<path fill="#CCDEC7" d="M311.2,580.5c-0.7-1-0.3-2.3-1.1-3.2c-0.3-0.5-0.9-1.1,0.3-1.3c0.6,0.2,1.1,0.4,1.7,0.6
							c0,0.3,0,0.7,0,1c-0.5,0.8-0.3,1.7-0.5,2.5l-0.1-0.1C311.5,580.1,311.4,580.2,311.2,580.5L311.2,580.5z"/>
						<path fill="#CEC69D" d="M312.1,572.3c0.1,0.1,0.3,0.1,0.4,0.1c0,0.3,0.1,0.6,0.1,0.9c-0.3,0.5-0.3,1-0.2,1.6
							c-0.1,0.1-0.1,0.3-0.2,0.4c-0.5,0.1-0.8-0.3-1.2-0.6c0-0.1,0-0.2,0-0.4C311.4,573.7,310.8,572.5,312.1,572.3z"/>
						<path fill="#32613E" d="M311,574.7c0.4,0.3,0.7,0.6,1.2,0.6c0,0.4,0,0.8-0.1,1.1c-0.6-0.2-1.1-0.4-1.7-0.6
							C310.6,575.5,310.8,575.1,311,574.7z"/>
						<path fill="#32613E" d="M322.3,572.9c0.9-0.4,1.8-0.3,2.7,0.3C324,573.1,323.2,573,322.3,572.9z"/>
						<path fill="#32613E" d="M311.7,580c0.1-0.8,0-1.7,0.5-2.5C312.4,578.4,312.5,579.3,311.7,580z"/>
						<path fill="#32613E" d="M312.4,574.9c-0.1-0.6-0.1-1.1,0.2-1.6C312.9,573.9,312.9,574.4,312.4,574.9z"/>
						<path fill="#888158" d="M311.3,580.4c0.1-0.2,0.2-0.3,0.3-0.5C311.5,580.1,311.4,580.2,311.3,580.4z"/>
						<path fill="#CEC69D" d="M343,230c-0.9-0.1-1.6-0.1-1.5-1.2c0.1-0.9,0.6-1.2,1.5-1c0.8,0.2,1.9,0.3,1.8,1.4
							C344.6,230.3,343.5,229.8,343,230z"/>
						<path fill="#CEC69D" d="M343.2,225.9c-0.6-0.1-1.3-0.1-1.3-1.1c0-0.8,0.5-1.1,1.3-1c0.7,0.1,1.5,0.1,1.5,1.1
							C344.7,225.9,343.9,225.9,343.2,225.9z"/>
						<path fill="#CEC69D" d="M314.6,536.7c0.8,0.1,0.8,0.5,0.5,1.1c-1.2,2.2-0.2,3.6,1.7,4.6c0.7,0.4,0.9,0.6,0.4,1.2
							c-0.4,0.6-0.9,0.5-1.5,0.2c-1.1-0.7-2.8-4.9-2.6-6.1C313.4,536.9,314,536.7,314.6,536.7z"/>
						<path fill="#CEC69D" d="M450.8,524.7c-0.2-1.5,0.1-3,0.3-4.4c1.1-10.6,2.3-21.3,3.4-31.9c0.1-0.6,0.2-1.2,0.4-1.9
							c0,0.3,0.2,0.7,0.1,1c-0.8,11.9-2.2,23.7-3.5,35.5C451.5,523.7,451.6,524.4,450.8,524.7z"/>
						<path fill="#CEC69D" d="M455,485.7c-0.2-0.6-0.1-1.2,0.4-1.8C455.4,484.5,455.4,485.1,455,485.7z"/>
						<path fill="#CEC69D" d="M456.6,470.4c-0.3-0.3-0.2-0.6,0.1-0.9C457.3,469.9,457.3,470.2,456.6,470.4z"/>
						<path fill="#CEC69D" d="M213.9,530.7c-0.1,0.6-0.1,1.1-0.2,1.7c-0.1,0.7-0.5,0.9-1.2,0.8c-0.7-0.1-1.1-0.4-1-1.1
							c0.2-1.5,0.1-3,0.7-4.4c0.2-0.4,0.3-0.9,0.8-0.9c0.5,0,0.6,0.5,0.8,0.9C214.3,528.8,214,529.8,213.9,530.7z"/>
						<path fill="#B7AF8B" d="M259.4,533.5c-0.1-1.7,0.2-2.5,2-2.3c1.9,0.2,1.8,1.1,1.8,2.6c0,2.7-1.9,1.9-3.1,1.8
							C258.4,535.4,259.6,534,259.4,533.5z"/>
						<path fill="#CEC69D" d="M328.1,228.3c0.1-0.7,0.2-1.3,0.3-2c0.1,0,0.1,0,0.2,0c0.9,0.3,2.8-0.5,2.6,1.2
							C330.9,228.8,329.3,228.8,328.1,228.3z"/>
						<path fill="#CEC69D" d="M329.4,224.2c0.3-0.7,1.1-0.8,1.2-1.6c0.6,0.5,1.5,0.9,1,1.7C331.1,225.1,330.2,224.3,329.4,224.2z"/>
						<path fill="#CEC69D" d="M330.6,222.6c-0.8-0.1-1.6-0.1-2.4-0.2C329,221.9,329.8,221.9,330.6,222.6z"/>
						<path fill="#CEC69D" d="M323.6,226.7c0-0.1,0-0.3,0-0.4c0.4,0,0.9,0,1.2,0.3C324.4,226.6,324,226.7,323.6,226.7z"/>
						<path fill="#CEC69D" d="M327.9,222.5c-0.2,0.1-0.3,0.2-0.5,0.3C327.6,222.7,327.7,222.6,327.9,222.5z"/>
						<path fill="#CEC69D" d="M326.5,223.1c-0.2,0-0.3,0-0.5,0C326.2,223.1,326.3,223.1,326.5,223.1z"/>
						<path fill="#CEC69D" d="M324.2,229.7c0-0.2,0.1-0.3,0.1-0.5c0.2,0.2,0.3,0.3,0.5,0.5C324.6,229.7,324.4,229.7,324.2,229.7z"/>
						<path fill="#CEC69D" d="M325.7,230.4c-0.1-0.1-0.2-0.2-0.4-0.2C325.5,230.2,325.6,230.3,325.7,230.4z"/>
						<path fill="#CEC69D" d="M210.3,566.1c-0.1,0.5-3.5,3.1-3.8,3.1c-0.4-0.1-1-0.2-0.9-0.7c0.1-1.2-0.6-1.5-1.5-1.9
							c-0.5-0.2-0.6-1.1-0.3-1.3c1.2-0.8,2.4-1.6,3.5-2.6c0.2-0.2,1,0.1,1,0.5c-0.1,1.4,0.7,1.8,1.8,2.2
							C210.2,565.4,210.3,565.9,210.3,566.1z"/>
						<path fill="#E9E4B6" d="M378.8,318.8c-0.1,0.9-0.1,1.8-0.3,2.7c-0.2,0.9-0.7,1.6-1.8,1.3c1.2-3.5-0.5-6-2.7-8.4
							c-0.8-0.9-1.4-0.9-2.4-0.1c-2.2,1.7-4,3.4-4.4,6.3c-1.3-0.6-1.6-1.3-0.6-2.6c0.6-0.7,1.5-1.3,1.5-2.4c0.5-0.7,0.6-1.8,1.4-2.2
							c1-0.5,0.2,0.5,0.4,0.6c0,0.1-0.1,0.1-0.1,0.2c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0c1.4-0.2,2.4-2.1,4-0.9
							c1.7,1.3,3.1,2.9,3.9,4.9C378.2,318.4,378.4,318.7,378.8,318.8z"/>
						<path fill="#32613E" d="M376.8,302.5c-0.1-0.3-0.3-0.6-0.4-0.9c0.6,0.1,1.1,0.2,1.7,0.3c0.3,0.2,0.6,0.2,0.9,0.1
							c1.2,0.1,1,0.9,0.9,1.7c-0.2,1.7-0.4,3.5-0.5,5.2c-0.3,2.9-0.3,2.9-3.2,2.3c0-0.2,0.1-0.4,0.1-0.5l0-0.1
							c1.5-1.2,1.2-2.7,0.9-4.3c0-0.3,0.1-0.5,0.1-0.8C377.8,304.4,378.7,303.2,376.8,302.5z"/>
						<path fill="#B7AF8B" d="M370.3,311c0-0.1,0-0.2,0-0.4c0.3-0.2,0.6-0.3,0.9-0.5c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1
							c0.1,0.1,0.2,0.1,0.3,0.2c0.9,0.1,1.8,0.2,2.7,0.3c0,0.3-0.1,0.6-0.1,0.9C373.1,311.6,371.7,311,370.3,311z"/>
						<path fill="#888158" d="M374.5,311.1c0-0.3,0.1-0.6,0.1-0.9c0.5-0.3,0.7-0.7,0.6-1.3c0.3,0,0.7,0,1,0.1c0.3,0.5,0.2,1,0,1.5
							c0,0,0,0.1,0,0.1c-0.3,0.2-0.6,0.3-0.9,0.5C375.1,311.1,374.8,311.1,374.5,311.1z"/>
						<path fill="#B7AF8B" d="M375.4,311.2c0.3-0.2,0.6-0.3,0.9-0.5c0,0.2-0.1,0.4-0.1,0.5C375.9,311.7,375.6,311.5,375.4,311.2z"/>
						<path fill="#888158" d="M379,302c-0.3,0.1-0.6,0.1-0.9-0.1C378.4,301.7,378.7,301.8,379,302z"/>
						<path fill="#B7AF8B" d="M370.2,547.7c0.9-1.8,0.5-3.3-1.2-4.4c-2-1.3-3.1,0.1-4.3,1.4c-0.7-2.2-0.3-2.8,1.7-2.5
							c5,0.5,5,0.5,4.5,5.6C370.7,547.7,370.5,547.7,370.2,547.7z"/>
						<path fill="#CEC69D" d="M370.2,547.7c0.2,0,0.4,0,0.7,0c0.3,1.8-0.5,2.3-2.2,1.9c-1-0.2-1.9-0.2-2.9-0.3
							c-2.2-0.2-2.4-0.6-1.5-2.8C366.4,549.7,367.6,550,370.2,547.7z"/>
						<path fill="#CEC69D" d="M367.5,547c-0.6-0.2-1-0.6-1-1.2c0-0.7,0.5-1,1.1-0.9c0.8,0,1.1,0.6,1,1.3
							C368.6,546.8,368.1,547,367.5,547z"/>
						<path fill="#CEC69D" d="M397.7,231.4c0.1,1.1,1.3,2.2,0.2,3.1c-0.3,0.3-1.1,0.2-1.3-0.3C396.1,233.1,397,232.3,397.7,231.4z"
							/>
						<path fill="#3E3821" d="M290.8,241.1c0.1-1.2,0.2-2.4,0.3-3.6c0.1-1,1.2-1.3,1.4-0.6c0.6,2.1,1.2,0.4,1.8,0.2
							c0.9-0.4,1.7-0.3,1.6,1.1c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6,0.2,1.5-0.9,1.7c-1.1,0.1-0.5-1.2-1.2-1.5
							C292.8,240.4,292.1,241.4,290.8,241.1l-0.1,0.1L290.8,241.1z"/>
						<path fill="#3E3821" d="M302.1,239.6c-0.1,0.8-0.1,1.2-0.2,1.6c-0.1,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.8-0.4-0.8-1
							c0-0.4,0.1-0.7,0.1-1.1c0-0.6,0.2-1.2-0.7-1.3c-0.7,0-0.7,0.6-0.7,1.1c-0.1,0.5,0,1-0.2,1.4c-0.3,0.7-1,0.8-1.3,0.2
							c-0.5-1.2-0.2-2.5,0.2-3.7c0.3-1,1.4-0.4,2.1-0.4C301.4,237.3,302.9,237.6,302.1,239.6z"/>
						<path fill="#3E3821" d="M305.9,242.6c-1.4-0.2-2.5-0.8-2.3-2.5c0.1-1.7,1.2-2.4,2.8-2.3c1.6,0.1,2.5,1,2.3,2.7
							C308.5,242.1,307.4,242.6,305.9,242.6z M306.1,241.3c0.7-0.1,0.8-0.5,0.8-1c0-0.5-0.1-1-0.8-1.1c-0.6,0-0.8,0.5-0.8,1
							C305.3,240.7,305.5,241.1,306.1,241.3z"/>
						<path fill="#3E3821" d="M264.4,231.6c0.3,0,0.6,0,0.8,0.1c0.6,0.1,1.6-0.2,1.6,0.9c0,1.1-1,0.7-1.6,0.6
							c-1.1-0.2-1.6,0.2-1.6,1.3c0,0.6-0.1,1.1-0.1,1.7c-0.1,0.8-0.1,1.6-1.3,1.5c-1-0.1-0.8-0.9-0.8-1.6c0-1.2,0.5-2.3,0.1-3.5
							c-0.2-0.7,0.3-1.2,1.1-1.1C263.1,231.5,263.7,231.6,264.4,231.6C264.4,231.6,264.4,231.6,264.4,231.6z"/>
						<path fill="#32613E" d="M371.2,310.1c-0.3,0.2-0.6,0.3-0.9,0.5c-0.4-0.2-0.8-0.4-0.7-0.9c0.3-2.7,0.6-5.4,0.9-8
							c0-0.4,0.4-0.5,0.8-0.6c0.3,0.2,0.6,0.3,1,0.1c0.3,0,0.5,0.1,0.8,0.1c0,0.4-0.1,0.7-0.1,1.1c-0.1,0.2-0.1,0.3-0.2,0.5
							c-0.5,1.5-0.8,3.1-0.4,4.7c-0.5,0.7-0.6,1.5-0.7,2.3c0,0-0.1,0.1-0.1,0.1C371.4,309.9,371.3,310,371.2,310.1z"/>
						<path fill="#888158" d="M372.1,301.1c-0.3,0.2-0.7,0.2-1-0.1C371.5,301.1,371.8,301.1,372.1,301.1z"/>
						<path fill="#888158" d="M306.6,591.3c0.7,0.8,0.6,1.3-0.6,1.4c-1.4,0.2-2.8,0.1-4.2-0.1c0-0.6,0.1-1.2,0.1-1.8
							C303.5,591,305,591.2,306.6,591.3z"/>
						<path fill="#B7AF8B" d="M301.9,590.9c0,0.6-0.1,1.2-0.1,1.8c-0.2-0.1-0.4-0.2-0.5-0.3c-0.8-0.2-1.4-0.7-0.9-1.5
							C300.7,590.1,301.4,590.5,301.9,590.9z"/>
						<path fill="#FBEFB2" d="M298.4,589.6c-0.1,0.1-0.2,0.1-0.3,0.2C298.2,589.7,298.2,589.6,298.4,589.6
							C298.3,589.5,298.4,589.6,298.4,589.6z"/>
						<path fill="#F5F0BC" d="M364.6,602.4c0-0.2,0-0.3,0-0.5c0.2-0.2,0.4-0.4,0.7-0.2c0.1,0.1,0.3,0.4,0.2,0.5
							C365.3,602.6,364.9,602.5,364.6,602.4z"/>
						<path fill="#3E3821" d="M361.8,248.4c-1.9,0.2-3-0.3-3-2.2c0.1-1.4,0.7-2.7,2.3-2.3c1,0.3,3.2-0.9,2.8,1.6
							C363.7,246.6,365,249.5,361.8,248.4z"/>
						<path fill="#CEC69D" d="M370.4,247.6c0-0.7,0.1-1.4,0.1-2c0.1-0.6,0.5-0.8,1.1-0.7c0.7,0.1,0.9,0.6,0.6,1.2
							C370.6,245.5,370.9,247.1,370.4,247.6z"/>
						<path fill="#CEC69D" d="M369.4,249.1c0.2-0.2,0.3-0.3,0.5-0.5C369.9,249,369.7,249.1,369.4,249.1z"/>
						<path fill="#1D190E" d="M371.3,259.6c0.4-1.2,2.2-2,1-3.6c-0.2-0.3,0.4-0.5,0.7-0.4c1.8,0.8,3.6,0.8,5.5,0.6
							c0.7-0.1,0.9,0.5,0.3,0.9c-1,0.7-0.7,1.3-0.2,2.1c0.2,0.4,0.8,0.9,0.3,1.3c-0.5,0.4-1.2,0.2-1.5-0.3c-0.5-0.7-1-1.3-1.6-0.1
							c-0.1,0.1-0.2,0.2-0.4,0.2c-1.1,0.5-0.8-1.8-1.8-1C372.8,259.7,372.3,260.2,371.3,259.6z"/>
						<path fill="#32613E" d="M417.2,352.1c-0.8,0.2-1.1,1.2-2.1,1.1c0.8-1.8,0.4-3.5,0.1-5.3c0.8,0.9,1.7,1.7,2.5,2.6
							C418.1,351.2,417.7,351.7,417.2,352.1z"/>
						<path fill="#32613E" d="M261.6,575.4c-0.3,0-0.7,0-0.9,0.4c-0.3,0-0.6,0-0.9,0c0-0.6-2.3-0.6-0.3-1.7c0.8-0.4,0.8-2,0.6-3.2
							c-0.1-0.6-0.8-0.9-1.3-0.8c-0.7,0.1-0.5,0.7-0.4,1.2c0.2,0.8,0.1,1.5-0.2,2.2c-1,0-0.1-1.7-1.4-1.4c-0.2-1.1,1-2.4-0.8-3.1
							c-0.2-0.1,0-0.8,0.6-0.7c0.4,0.1,0.9,0.2,1.2,0.4c1.9,1.2,2.8,0.9,3-1.5c0.1-1.5,1.3-0.2,2-0.4c0.2,1-0.1,2-0.2,2.9
							c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.3-0.1,0.6-0.1,0.9c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0.1,0.3
							c0,0.5-0.1,1.1-0.1,1.6C261.5,573.6,261.9,574.6,261.6,575.4z"/>
						<path fill="#14653A" d="M254.7,572.6c-0.1,0.7-0.2,1.4-0.3,2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0-0.5-0.1-0.8-0.1
							c0.3-2.7,0.5-5.3,0.8-8c0.1-0.7,0.3-1.2,1.2-1.1c1.1,0.1,0.7,0.8,0.6,1.3C255.1,568.9,254.9,570.7,254.7,572.6z"/>
						<path fill="#E9E4B6" d="M309,580.5c-1-0.1-2,0.2-2.8-0.7c0.1-0.4,0.1-0.8,0.2-1.2c1.1,0.3,1.9-0.4,2.8-0.7
							C309.3,578.8,310.2,579.7,309,580.5z"/>
						<path fill="#14653A" d="M311,574.7c-0.2,0.4-0.4,0.8-0.6,1.2c-1.2,0.2-0.7,0.8-0.3,1.3c-1.5,0.2-1.6-0.8-1.5-1.8
							c0-0.3,0-0.5,0-0.8c0.7-0.4,1.5-0.5,2.3-0.2C311,574.5,311,574.6,311,574.7z"/>
						<path fill="#CCDEC7" d="M308.7,574.6c0,0.3,0,0.5,0,0.8c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.5-0.1,1.5-1.1,1.6
							c-0.1,0-0.3-0.3-0.3-0.4c-0.1-0.9,0.2-1.5,1.1-1.8C308.2,575,308.4,574.7,308.7,574.6z"/>
						<path fill="#E9E4B6" d="M407.8,607.1c0.1-0.6,0.4-0.9,1-0.9c0.7,0.1,1.4,0.2,2.1,0.3c0.5,0.1,1.1,0.2,0.8,0.9
							c-0.2,0.5-0.7,0.9-1.2,0.5C409.7,607.4,408.7,607.5,407.8,607.1z"/>
						<path fill="#888158" d="M253.5,575c0.3-0.1,0.6-0.2,0.9-0.3C254.2,575.1,253.9,575.1,253.5,575z"/>
						<path fill="#FBEFB2" d="M421.9,604.6c-0.1,0.4-0.2,0.8-0.2,1.2c-0.3,0-0.5,0.1-0.8,0.1c0-0.3,0.1-0.6-0.3-0.6
							c-0.2,0-0.4,0.3-0.6,0.5c-1.4-0.2-2.8-0.4-4.2-0.6c0-0.4,0.1-0.9,0.1-1.3C417.9,604.3,419.9,604.5,421.9,604.6z"/>
						<path fill="#FBEFB2" d="M414.6,604.3c-0.7,0.6-1.3,0.6-1.8-0.2C413.5,603.6,414.1,603.7,414.6,604.3z"/>
						<path fill="#CCDEC7" d="M376.3,310.6c0.2-0.5,0.3-1,0-1.5c-0.2-0.6-0.1-1.4-0.8-1.7c0.2-0.6-0.9-1.5,0.5-1.7
							c0.3,0.4,0.7,0.6,1.2,0.6C377.5,307.8,377.8,309.4,376.3,310.6z"/>
						<path fill="#51727B" d="M375.4,307.3c0.7,0.4,0.7,1.1,0.8,1.7c-0.3,0-0.7,0-1-0.1c-0.2-0.4-0.1-1.3-1-0.9
							c-1,0.4-2.4,0.2-2.3,1.9c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.8,0.2-1.6,0.7-2.3c1.4,0.4,1.3-0.9,1.8-1.5
							C374.1,306.8,374.6,307.3,375.4,307.3z"/>
						<path fill="#14653A" d="M375.4,307.3c-0.9,0-1.3-0.5-1.3-1.4c0.4-0.6,0.3-1.7,1.4-1.6c0.8,0.1,0.5,0.8,0.5,1.3
							C374.5,305.8,375.6,306.8,375.4,307.3z"/>
						<path fill="#CEC69D" d="M376.8,302.5c1.9,0.7,1,1.9,0.4,3C376.1,304.6,376.7,303.5,376.8,302.5z"/>
						<path fill="#CCDEC7" d="M372.7,302.7c0.1-0.2,0.1-0.3,0.2-0.5c0.2,0,0.4-0.1,0.6,0c0.6,0.2,1.5-0.5,1.8,0.5
							c0.1,0.4-0.2,0.7-0.5,0.7C374,303.6,372.9,304.3,372.7,302.7z"/>
						<path fill="#888158" d="M362,257.5c-0.1-0.6-0.3-1.3,0.4-1.7C362.3,256.3,362.1,256.9,362,257.5z"/>
						<polygon fill="#888158" points="201.4,540.1 201.3,540.1 201.3,540 						"/>
						<path fill="#CEC69D" d="M419.9,605.9c0.2-0.2,0.4-0.5,0.6-0.5c0.4,0,0.3,0.4,0.3,0.6C420.5,606.3,420.2,606.3,419.9,605.9z"/>
						<path fill="#888158" d="M262.5,570c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C262.6,569.7,262.6,569.8,262.5,570z"/>
						<path fill="#888158" d="M262.3,571.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1C262.3,571,262.3,571.1,262.3,571.2z"/>
						<path fill="#FBEFB2" d="M251.3,545.9c0,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1
							C251.3,545.8,251.3,545.8,251.3,545.9z"/>
						<path fill="#B7AF8B" d="M296,220.4c0.6,1.1,1.3,2.1,0.5,3.1c-0.3,0.4-1.3,0.3-1.5-0.1C294.4,222.3,295.5,221.5,296,220.4z"/>
						<path fill="#F5F0BC" d="M290,224.9c-1-1.8,1-2.5,1.4-3.8c0.1-0.2-0.2-2,1.2-1C291.6,221.6,291.5,223.6,290,224.9z"/>
						<path fill="#F5F0BC" d="M288.6,224c0.1,0.1,0.2,0.1,0.3,0.2c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.2
							C288.5,224.2,288.5,224.1,288.6,224z"/>
						<path fill="#B7AF8B" d="M356.6,226.4c0.5,1.6,1.1,2.6,0.5,3.7c-0.3,0.5-1.2,0.3-1.5-0.2C355,228.7,355.9,227.9,356.6,226.4z"
							/>
						<path fill="#14653A" d="M351.4,490c-0.1,0.1-0.3,0.1-0.4,0c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1
							c-1.7-0.4-3.4-0.6-5.2-0.5c-1.8-0.2-3.6-0.4-5.4-0.6c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.3-0.1-0.5-0.1
							c-0.5-2.7,0.4-5.3,0.5-8c0-0.8,0.2-1.6,0.3-2.3c0.8-2.2,0.9-4.4,0.8-6.7c0.5-4.5,1-9.1,1.5-13.6c0.1,0,0.1-0.1,0.1-0.1
							c0-0.1-0.1-0.2-0.1-0.3c0.2-2.1,0.4-4.3,0.7-6.4c0.4-0.5,0.5-1,0.2-1.6c-0.1-0.9,0.6-2-0.3-2.8c-0.1-0.1-0.2-0.2-0.3-0.3
							c0.2-0.8-1-0.8-0.9-1.5c0.9-0.5,2,0.4,2.8-0.3c0.9,0.1,1.7,0.2,2.6,0.3c0.1,0.9,0.1,0.9,1.7,0.2c0.9,0.2,1.7,0.6,1.5,1.6
							c-0.2,1.2-1.3,0.4-1.9,0.6c-0.3,0.1-0.7,0.2-1,0.2c-1,0.4-0.7,1.3-0.8,2c-1.2,10.5-2.3,21-3.4,31.5c-0.1,0.7-0.4,1.4,0.2,2
							c0.1,0.2,0.3,0.3,0.4,0.5c0.5,0.7,1.3,0.5,1.9,0.6c2.4,0.3,4.9,0.6,7.3,0.8c0.9,0.1,1.6,0.4,2.1,1.1c0,0.3-0.1,0.5-0.1,0.8
							C352.5,487.8,352.5,489.1,351.4,490z"/>
						<path fill="#14653A" d="M386.4,463c-2,0.3-2.4-0.7-2.1-2.7c0.4-2.2,0.5-4.5,0.7-6.7c0.1-1.2,0.6-1.6,1.7-1.5
							c1.2,0.2,2.2,0.4,2.8-1.3c0.4-1,2.1-0.3,3.1,0c1.1,0.3,0.5,1.3,0.5,2c0,1-0.1,1.9-1.5,1.8c-1.1-0.1-1.3,0.5-1.3,1.4
							c-0.1,1.5-0.3,3-0.5,4.4C389.5,463.2,390.3,463.5,386.4,463z"/>
						<path fill="#D02826" d="M338.6,397.2c-2.5-0.2-5-0.5-7.6-0.7c-1.3-0.1-1.7-0.5-1.5-1.8c0.3-2.4,0.5-4.9,0.7-7.3
							c2.9-0.3,5.7,0,8.5,0.5c0.6,0.1,1,0.5,0.9,1.2C339.3,391.8,339,394.5,338.6,397.2z M333.4,392.5c0.4,0.9-1.1,2.7,0.8,2.9
							c1.8,0.2,1.2-1.7,1.3-2.8c0.1-1.1,0.5-3-0.8-3.1C333.3,389.4,333.8,391.4,333.4,392.5z"/>
						<path fill="#CEC69D" d="M345,447.3c0.3-0.1,0.7-0.1,1-0.2c0.7-0.2,1.7,0.6,1.9-0.6c0.2-1.1-0.6-1.5-1.5-1.6
							c-0.2-0.1-0.5-0.2-0.7-0.3c0.2-1-0.2-1.9-0.5-2.8c2.5-0.7,2.6-0.7,2.7,2c0,0.5,0.2,0.5,0.6,0.4c0.8,2.4,0.4,3.3-1.8,4.1
							c-0.8,0.3-0.9,0.8-0.9,1.5c-1.1,10.3-2.2,20.6-3.3,30.8c-0.2,1.3,0.4,1.7,1.6,1.8c2.5,0.2,4.9,0.6,7.4,0.8
							c2,0.2,2.7,1.2,2.4,3.1c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,0.6-0.2,1.1-1,1c0-1.1,0.5-2.3,0.1-3.4c0-0.3,0.1-0.5,0.1-0.8
							c0.6-1.3-0.3-1.4-1.2-1.5c-3.4-0.3-6.8-0.7-10.1-1c-0.1-0.2-0.3-0.3-0.4-0.5C342.3,471,343.7,459.1,345,447.3z"/>
						<path fill="#D02826" d="M399.8,498.3c0,0.1-0.1,0.1-0.1,0.2c-1,0.1-0.9,1-0.9,1.6c-0.1,0.6,0.1,1.3,0.1,1.9
							c-0.1,3-0.8,3.5-3.7,3.2c-2.1-0.2-4.2-0.4-6.3-0.9c-0.7-2.3,0.3-4.5,0.3-6.7c0-0.8,0.5-1.7-0.1-2.4c0.1-0.1,0.1-0.2,0.2-0.3
							c0.2,0,0.4,0,0.6-0.1c2.9,0.3,5.9,0.7,8.8,1c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0.1,0.3,0.1,0.4c-0.2,0.7-0.3,1.3,0.5,1.6
							C399.7,498.1,399.7,498.2,399.8,498.3z M392.3,502.9c0.3-0.8,0-2.3,1.3-2.1c2.4,0.4,2.2-1.1,2.3-2.6c0.2-1.5-0.4-1.9-1.8-2.1
							c-1.4-0.2-1.8,0.4-2,1.7C392,499.6,392.1,501.2,392.3,502.9z"/>
						<path fill="#D02826" d="M409.6,395.4c0,3,0.2,6.1-1.3,8.9c-1.4-0.1-2.9-0.2-4.3-0.4c-1.1-0.3-2.2-0.4-3.3-0.3
							c-0.3-0.1-0.6-0.1-0.9-0.2c-0.4-3.1,0.4-6.1,0.6-9.2c0.2-0.1,0.4-0.2,0.5-0.3c0.8,0.1,1.7,0.2,2.5,0.3c0.3,0,0.5,0.1,0.8,0.1
							c0.9,0.1,1.7,0.2,2.6,0.4C407.5,395.6,408.6,395,409.6,395.4z M405.3,400.1c0.1-0.5,0.1-0.9,0.1-1.2c0.1-0.9,0.2-2.3-0.7-2.4
							c-1.2-0.2-0.9,1.3-1.1,2.1c-0.1,0.4-0.2,0.9-0.2,1.2c0.2,0.9-1,2.4,0.7,2.6C405.8,402.5,404.9,400.8,405.3,400.1z"/>
						<path fill="#D02826" d="M330.8,385.7c-0.6-2.9,0.3-5.6,0.5-8.4c0.1-0.9,0.8-0.9,1.5-0.8c2.6,0.3,5.2,0.7,7.8,1
							c-0.2,2.5-0.5,5.1-0.7,7.6c-0.1,1.2-0.5,1.6-1.7,1.4C335.8,386.1,333.3,385.9,330.8,385.7z M333.5,384
							c0.8-0.3,0.8-1.4,1.7-1.3c3,0.5,2.2-1.6,2.4-3.2c0.2-1.5-0.8-1.7-1.9-1.8c-1.2-0.2-1.9,0.1-1.9,1.5
							C333.7,380.8,333.6,382.4,333.5,384z"/>
						<path fill="#14653A" d="M387.1,435.6c0.1,0,0.1,0,0.2,0.1c1.6,0.3,3.8-0.3,4.7,0.8c1,1.2-0.1,3.2-0.1,4.9
							c0,0.8-0.1,1.7-0.3,2.5c-0.2,1,0.3,2.7-0.8,2.8c-1.7,0.2-3.7,0.7-5.1-1.1C386.2,442.3,386.7,438.9,387.1,435.6z"/>
						<path fill="#14653A" d="M383,473.6c0.1-0.8,0.2-1.6,0.3-2.3c0.3-3,0.3-2.9,3.2-2.7c2,0.1,2.7,0.8,2.3,2.8
							c-0.4,2.1-0.7,4.3-0.9,6.5c-0.1,1.6-0.8,1.9-2.2,1.6c-0.3-0.1-0.6-0.1-0.8-0.1c-2.8-0.3-2.8-0.3-2.3-3.1
							C382.8,475.4,382.9,474.5,383,473.6C383,473.6,383,473.6,383,473.6z"/>
						<path fill="#F5F0BC" d="M349.9,356c0.4-0.5,0.3-1.1,0.1-1.6c-0.7-1.6,0.4-0.8,1-0.8c0,0.9,0,1.8-0.1,2.8
							c-1.4,12.6-2.7,25.3-4.2,37.9c-1,8.9-1.6,17.8-3,26.6c-0.2,0-0.5,0-0.7-0.1C345.3,399.3,347.6,377.6,349.9,356z"/>
						<path fill="#CEC69D" d="M347.5,426.6c-0.1-0.2-0.3-0.4-0.4-0.6c1.1-10.1,2.3-20.3,3.4-30.4l-0.1,0c4.8,0,9.6,0.8,14.3,1.4
							c0,0,0.1,0.1,0.1,0.1c-0.2,1.6-1,3.4,1.6,3.7c-0.3,0.2-0.6,0.4-1,0.6c-1.4,0.1-2-1-2.6-1.9c-0.8-1.3,1.1-0.4,1-1.5
							c-0.1-0.3-0.5-0.2-0.9-0.3c-3.2-0.4-6.5-0.7-9.7-1.1c-1.2-0.2-1.8,0.1-2,1.5c-0.9,8.6-1.8,17.2-2.8,25.8
							c-0.1,1.1,0.8,1.6,0.7,2.6C348.7,426.5,348.1,426.5,347.5,426.6z"/>
						<path fill="#E9E4B6" d="M394.6,414.3c0,0.5-0.2,0.9-0.6,1.2c-1.2,0.9-1.1,1.9-0.1,3c1.1,1.3,1.8,2.4,0.8,4.5
							c-0.7,1.5-1,4.1-0.1,5.9c0.7,1.5-0.1,1.7-1,2.1c-3.2,1.2-6,0.7-8.5-2.2c2-0.1,0.8-1.6,1.2-2.3c1.2-2,0.7-4.3,1.5-6.3
							c0.9-2.2,1.9-4.2,1.8-6.6C391.2,413.8,392.9,414.1,394.6,414.3z M393.5,425.4c0.1-0.6,0.1-1.3,0.2-1.9c0.7-4.2,0.7-3.5-3.3-4
							c-1.2-0.2-1.5,0.3-1.8,1.4c-0.4,2.3-0.6,4.6-0.9,6.9c-0.3,1.7,0.3,2.4,2,2.5c3.2,0.3,3.2,0.4,3.5-2.8
							C393.3,426.8,393.4,426.1,393.5,425.4z M392.3,417.2c-0.1-0.6,0.4-1.5-0.6-1.7c-1.2-0.2-1,0.9-1.1,1.6
							c-0.1,0.6-0.4,1.5,0.7,1.6C392.3,418.8,392.1,417.8,392.3,417.2z"/>
						<path fill="#32613E" d="M366.6,400.8c-2.6-0.3-1.8-2.2-1.6-3.7c-0.1,2.7,1.5,3.1,3.8,3c2.3-0.1,4.6,0.4,6.9,0.7
							c1,0.1,1.4,0.1,1.4-1.3c0.1-2.6,0.3-5.3,1.1-7.8c2.3,0.2,4.6,0.4,6.9,0.6c0.6,0.1,1.2,0.1,1,1c-0.8-0.1-1.7-0.2-2.5-0.3
							c-0.8-0.1-1.5-0.2-2.3-0.2c-0.8,0-1,0.6-0.8,1.1c0.4,1,1.6,0.9,2.4,1.3c0.8,0.4,0.4-0.9,0.7-1.3c0.5,1.2-0.1,2.3-0.4,3.4
							c-0.1-1-0.8-1.3-1.7-1.5c0.1,2.7,0.1,2.7,1.6,2.3c0.9,0.6,0.7,1.2-0.1,1.7c-0.6-0.2-1.2-0.5-1.8-0.6c-0.4-0.1-0.6-1.3-1.2-0.5
							c-0.4,0.6-1.4,1.2-0.6,2.2c0.3,0.3,0.5,0.7,0.5,1.1c-0.3,0-0.6,0.1-0.9,0.1c0,0,0,0,0,0c-0.5-0.4-1-0.2-1.5-0.2
							c-3.2-0.7-6.6-0.8-9.8-1.1c-0.1,0-0.3,0-0.4,0C367.1,400.9,366.8,400.9,366.6,400.8z M378.3,401.3c0.3-1.5,0.9-2.5,1.4-3.5
							c0.3-0.5,0.8-1.2-0.1-1.6c-0.6-0.2-1.4,0.1-1.5,0.8C377.9,398.3,377.3,399.7,378.3,401.3z M378.3,395.4
							c1.8-0.3,1.3-1.4,1.2-2.3c0-0.3-0.3-0.6-0.6-0.4C378.1,393.4,378.4,394.4,378.3,395.4z"/>
						<path fill="#32613E" d="M394.6,414.3c-1.7-0.2-3.4-0.4-5.1-0.6c0,0-0.1,0-0.1,0c0.4-3.6,0.7-7.1,1.1-10.7c0,0,0,0,0,0
							c1.3,0.1,2.5,0.2,3.8,0.3c1.4,0,1.7,0.7,1.6,2c-0.3,2.6-0.8,5.3-0.7,8c0,0.2-0.1,0.3-0.1,0.5c-0.2,0-0.2,0.2-0.2,0.3
							C394.8,414,394.7,414.1,394.6,414.3z M391.2,410.2c-0.5,1.5,0.5,2.3,1.6,2.5c1.3,0.2,0.7-1.2,0.9-1.9c0.6-1.6,0.2-3.3,0.9-4.9
							c0.2-0.5,0.3-1-0.4-1.3c-0.5-0.2-1.1-0.3-1.6,0.2c-0.3,0.3-0.9,0.4-0.6,1.2c0.3,1.1,1.8,2.4-0.6,3.1
							C391.3,409.1,391.3,409.8,391.2,410.2z"/>
						<path fill="#1D190E" d="M375.2,426.3c-0.4,3.3-0.4,3.3-3.5,2.9c-1.2-0.1-1.7-0.4-1.4-1.7c0.1-0.6,0.1-1.3,0.2-1.9
							c0.2-2.6,2-3.6,4.4-2.3C376.2,424.1,374.8,425.4,375.2,426.3z"/>
						<path fill="#1D190E" d="M369.6,477.2c-0.3,3-1.3,3.6-4.2,2.6c-0.7-0.2-0.8-0.7-0.7-1.3c0.1-0.8,0.3-1.7,0.3-2.5
							c0-1.8,0.7-2.5,2.6-2.2C369.9,474.2,370,474.1,369.6,477.2z"/>
						<path fill="#32613E" d="M342.1,444.4c-0.9,0.7-1.9-0.2-2.8,0.3c-0.1,0.8,1.1,0.8,0.9,1.5c-0.6,0.1-1.1-0.3-1.7,0.1
							c-0.9-0.2-0.6-0.9-0.6-1.5c0.6-5.9,1.2-11.8,1.8-17.7c0.1-0.7,0.1-1.4,1-1.7c0.8,0.2,1.7,0.6,2.6,0.2c0,0.1,0,0.2-0.1,0.2
							c-3.4,0.7-3,3.6-3.2,6c-0.2,2.2-1,4.5-0.3,6.7c-0.1,0.4-0.7,0.8-0.2,1.3c0.1,0.4,0.1,0.8-0.1,1.1c-0.7,3-0.5,3.4,1.9,2.9
							C341.8,443.9,342.1,444,342.1,444.4z"/>
						<path fill="#E9E4B6" d="M395.2,413.2c-0.2-2.7,0.4-5.3,0.7-8c0.2-1.3-0.1-2-1.6-2c-1.3,0-2.5-0.2-3.8-0.3c0-0.3,0-0.7,0.2-1
							c0.2-0.3,4.8,0,5,0.3c0.6,1.1,1.6,2,1.2,3.6c-0.5,1.8-0.4,3.7-0.5,5.6C396.2,412.3,396.4,413.2,395.2,413.2z"/>
						<path fill="#CEC69D" d="M350.1,426.8c0.1-0.3,0.2-0.8,0.4-0.9c0.3-0.2,0.4,0.2,0.4,0.5c-0.7,5.6-0.8,11.3-2.1,16.8
							c0,0.1-0.1,0.1-0.2,0.1c-0.5-1.3,0.5-2.5,0.1-3.8c0-0.4,0.1-0.8,0.1-1.3c0-0.2,0-0.3,0-0.5c0.1-1,0.2-2,0.3-2.9
							c0.1-0.7,0.1-1.4,0.2-2.1C349.6,430.7,349.8,428.8,350.1,426.8z"/>
						<path fill="#32613E" d="M350.1,426.8c-0.2,2-0.5,3.9-0.7,5.9c-2,0.2-4.1,0.2-3.9,3.1c0,0.2-0.5,0.5-0.7,0.7l-0.1-0.1
							c0.5-1.3,0.5-2.6,0.6-3.9c0.1-0.6,0.2-1.2,1-0.7c2.2,1.5,1.3-0.4,1.4-1.2c1.8-1,1.8-1.7-0.1-4c0.6,0,1.1,0,1.7-0.1
							C349.5,426.6,349.8,426.7,350.1,426.8z"/>
						<path fill="#CEC69D" d="M380.1,402.1c0-0.4-0.2-0.8-0.5-1.1c-0.8-1,0.2-1.6,0.6-2.2c0.6-0.8,0.8,0.3,1.2,0.5
							c0.6,0.2,1.2,0.4,1.8,0.6c0,0.3,0,0.6,0,0.8c-0.3,1.6,0.6,1.8,1.8,1.8c0.1,0.1,0.1,0.2,0.2,0.2c-0.1,0.2-0.1,0.5-0.2,0.7l0,0
							c-1.1-0.2-2.2-0.5-3.3-0.7C381.2,402.6,380.5,402.8,380.1,402.1z"/>
						<path fill="#F5F0BC" d="M351.6,500.3c-4-0.4-7.9-0.9-11.9-1.3c0.3-1,1.1-0.7,1.7-0.6c4.1,0.4,8.2,0.9,12.4,1.3
							c0.8,0.1,1.6,0,1.6,1.2C354.1,500.7,352.9,500.5,351.6,500.3z"/>
						<path fill="#E9E4B6" d="M390.5,402.9c-0.4,3.6-0.7,7.1-1.1,10.7c-1.5-0.3-2.1-1.7-1.5-2.5c1.4-2.1,1-4.5,1.4-6.7
							C389.5,403.7,389.6,403,390.5,402.9z"/>
						<path fill="#F5F0BC" d="M367.8,401c3.3,0.3,6.6,0.4,9.8,1.1c-0.2,0.2-0.4,0.5-0.6,0.5c-3.8-0.4-7.6-0.7-11.5-1.1
							c0.3-0.2,0.6-0.4,1-0.6c0.3,0,0.6,0.1,0.9,0.1C367.5,401.1,367.7,401.1,367.8,401z"/>
						<path fill="#32613E" d="M385.6,486.6c-1-0.1-1.9-0.3-2.9-0.4c-0.5-0.1-1.1-0.1-1.1-0.8c0-0.7,0.6-0.7,1.1-0.6
							c1.6,0.3,3.2-0.1,4.7,0.9C387.3,487,386.2,486.4,385.6,486.6z"/>
						<path fill="#F5F0BC" d="M387.1,435.6c-0.5,3.4-0.9,6.7-1.4,10.1c-0.7-0.2-0.8-0.7-0.7-1.3c0.3-2.5,0.6-5,0.8-7.6
							C385.9,436,386.1,435.4,387.1,435.6z"/>
						<path fill="#888158" d="M348.7,439.5c0.4,1.3-0.6,2.5-0.1,3.8c0,0.3-0.1,0.6-0.1,0.9c-0.4,0.1-0.6,0.1-0.6-0.4
							c-0.2-2.6-0.2-2.7-2.7-2l-0.2-0.1c-0.1-0.2-0.3-0.4-0.4-0.7c0.8-1,2.3-0.3,3.1-1.4C348,439.6,348.4,439.5,348.7,439.5z"/>
						<path fill="#CEC69D" d="M397.5,400.1c0,0.2,0,0.3,0,0.5c0,0,0,0,0,0c-0.3,0.2-0.7,0.5-1,0.7c-2.6-0.3-5.3-0.6-7.9-0.8
							c0-0.2,0-0.5,0-0.7c2.9,0,5.7,0.6,8.6,0.4c0,0,0.2,0,0.2,0L397.5,400.1z"/>
						<path fill="#888158" d="M400.3,394.3c-0.2,3.1-1,6.1-0.6,9.2c-1.2-0.3-0.5-1.1-0.5-1.7c0.1-2,0.4-4.1,0.5-6.1
							c0-0.3,0-0.5,0.1-0.8c0-0.3,0.1-0.5,0.1-0.8c0,0,0,0,0,0C400,394.2,400.2,394.3,400.3,394.3z"/>
						<path fill="#F5F0BC" d="M343.3,425.7c-0.9,0.4-1.7,0-2.6-0.2c0.7-1.1,2.5-1.5,2.1-3.4c0.2,0,0.5,0.1,0.7,0.1
							C343.5,423.4,343.4,424.5,343.3,425.7L343.3,425.7z"/>
						<path fill="#F5F0BC" d="M388.5,399.7c0,0.2,0,0.5,0,0.7c-0.8,0.5-1.7,1.1-2.5,1.6c-0.2-0.1-0.3-0.1-0.5-0.2
							c0-0.4,0-0.8,0.1-1.3c0.4-0.7,1.4-0.3,1.7-1.1C387.7,399.6,388.1,399.7,388.5,399.7z"/>
						<path fill="#F5F0BC" d="M397.9,449.1c1.7,1.7,1.7,1.7-0.7,2.7c0-0.7,0.1-1.3-0.2-2C396.6,448.6,397.5,449.2,397.9,449.1z"/>
						<path fill="#B7AF8B" d="M327.8,491.6c0.2-2.7,0.4-5.3,0.7-8C329,486.4,328.5,489,327.8,491.6z"/>
						<path fill="#F5F0BC" d="M381.8,402.8c1.1,0.2,2.2,0.5,3.3,0.7C383.8,404.8,382.8,404.5,381.8,402.8z"/>
						<path fill="#888158" d="M338.5,471.5c0.1,2.3,0,4.5-0.8,6.7C337.8,475.9,337.8,473.6,338.5,471.5z"/>
						<path fill="#888158" d="M344.1,489.3c1.7-0.1,3.5,0.1,5.2,0.5C347.5,490,345.8,489.9,344.1,489.3z"/>
						<path fill="#32613E" d="M352.7,486.6c0.4,1.1-0.1,2.3-0.1,3.4c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.5,0.1l-0.2,0l-0.2-0.1
							C352.5,489.1,352.5,487.8,352.7,486.6z"/>
						<path fill="#CEC69D" d="M401.6,474.8c0.1,0.9,0,1.7-0.3,2.5C400.8,476.4,399.7,475.4,401.6,474.8z"/>
						<path fill="#E9E4B6" d="M398.3,409.2c-0.3,0-0.6-0.1-0.6-0.4c0-0.4,0.3-0.4,0.6-0.4c0.3,0,0.6,0.1,0.6,0.4
							C398.9,409.2,398.6,409.2,398.3,409.2z"/>
						<path fill="#888158" d="M400.6,403.6c1.1-0.1,2.3,0,3.3,0.3C402.8,404.2,401.7,404,400.6,403.6z"/>
						<path fill="#E9E4B6" d="M343.6,422.2c-0.2,0-0.5-0.1-0.7-0.1c0-0.4,0.1-0.8,0.1-1.3c0.2,0,0.5,0,0.7,0.1
							C343.7,421.4,343.6,421.8,343.6,422.2z"/>
						<path fill="#D02826" d="M388.1,504.5c-0.5-0.1-0.9-0.1-1.3-0.2C387.2,504.4,387.6,504.4,388.1,504.5z"/>
						<path fill="#888158" d="M338.5,446.4c0.5-0.4,1.1,0,1.7-0.1c0.1,0.1,0.2,0.2,0.3,0.3C339.9,446.8,339.2,446.7,338.5,446.4z"/>
						<path fill="#888158" d="M340.9,449.4c0.3,0.6,0.3,1.1-0.2,1.6C340.6,450.5,340.6,449.9,340.9,449.4z"/>
						<path fill="#F5F0BC" d="M385.1,403.5c0.1-0.2,0.1-0.5,0.2-0.7c0.2,0,0.5-0.1,0.7-0.1C386,403.3,385.7,403.6,385.1,403.5z"/>
						<path fill="#CEC69D" d="M386,402.7c-0.2,0-0.5,0.1-0.7,0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0.1-0.2,0.3-0.5,0.4-0.7
							c0.2,0.1,0.3,0.1,0.5,0.2C386,402.2,386,402.5,386,402.7z"/>
						<path fill="#B7AF8B" d="M329.2,477.2c-0.1-0.5-0.1-1,0.2-1.5C329.8,476.2,329.8,476.7,329.2,477.2z"/>
						<path fill="#E9E4B6" d="M396.5,401.3c0.3-0.2,0.7-0.5,1-0.7C397.4,401.2,397,401.4,396.5,401.3z"/>
						<path fill="#B7AF8B" d="M328.6,481.8c0.1-0.4,0.2-0.7,0.3-1.1C329.2,481.2,329.3,481.6,328.6,481.8z"/>
						<path fill="#888158" d="M350.1,489.9c0.3,0,0.6,0.1,0.9,0.1C350.7,490.3,350.4,490.2,350.1,489.9z"/>
						<path fill="#CEC69D" d="M379.2,402.2C379.2,402.2,379.3,402.2,379.2,402.2c0,0.1-0.1,0.2-0.2,0.3
							C379.1,402.4,379.2,402.3,379.2,402.2z"/>
						<path fill="#B7AF8B" d="M329.7,473c-0.1-0.1-0.1-0.2-0.1-0.3c0.1,0.1,0.2,0.1,0.2,0.2C329.8,472.9,329.7,473,329.7,473z"/>
						<path fill="#CEC69D" d="M332.2,337.4c0,0,0.1-0.1,0.1-0.1C332.3,337.3,332.3,337.4,332.2,337.4L332.2,337.4z"/>
						<path fill="#E9E4B6" d="M339.4,501.5c0.1,0,0.3,0,0.4,0.1C339.7,501.8,339.5,501.8,339.4,501.5z"/>
						<path fill="#888158" d="M340,457.4c0,0.1,0.1,0.2,0.1,0.3c0,0-0.1,0.1-0.1,0.1C340,457.7,340,457.6,340,457.4z"/>
						<path fill="#888158" d="M351.8,490.1c0.2,0,0.3,0,0.5-0.1C352.1,490.3,351.9,490.3,351.8,490.1z"/>
						<path fill="#888158" d="M338.2,488.7c0.2,0,0.3,0,0.5,0.1C338.5,488.9,338.4,488.9,338.2,488.7z"/>
						<path fill="#888158" d="M337,488.4c0.2,0,0.3,0.1,0.5,0.1C337.2,488.8,337.1,488.8,337,488.4z"/>
						<path fill="#E9E4B6" d="M394.9,414c0-0.2,0-0.3,0.2-0.3C395.1,413.9,395.1,414,394.9,414z"/>
						<path fill="#F5F0BC" d="M399.7,498c-0.8-0.3-0.7-1-0.5-1.6c0.6,0,1.2,0.3,1.7-0.1c0.3,0,0.5,0.1,0.8,0.1
							c4.5,0.9,9,1.5,13.6,1.5c0.3,0,0.5,0.1,0.8,0.1c0.7,0.6,1.7,0.3,2.5,0.6c-0.4,1.1-1.3,0.6-2,0.5c-3.7-0.3-7.3-0.9-10.9-1.6
							c-1.2-0.2-2.7-0.8-3.4,0.9c-0.3,0-0.5-0.1-0.8-0.1c-0.1,0-0.3-0.1-0.4-0.1C400.7,497.8,400.2,497.8,399.7,498z"/>
						<path fill="#32613E" d="M421.7,489c0.1,3.2-0.7,6.2-1.7,9.2c-0.1,0-0.3,0-0.5-0.1c0.3-2.4,0.6-4.9,0.8-7.3
							C420.4,489.9,420.8,489.3,421.7,489z"/>
						<path fill="#B7AF8B" d="M418.6,498.6c-0.8-0.3-1.8,0-2.5-0.6c0.7,0,1.5,0,2.2,0c0.1,0,0.3,0,0.4,0c0.1,0.1,0.3,0.2,0.4,0.3
							C419,498.4,418.8,498.5,418.6,498.6z"/>
						<path fill="#32613E" d="M423.8,482.3c0.1,1.7-0.6,3.4-0.2,5.1c0,0.2,0.1,0.3,0.1,0.5c-0.6,0.1-1.1,0.2-1.7,0.3
							c0.6-1.7,0.6-3.5,1-5.3C423.1,482.4,423.4,482.3,423.8,482.3z"/>
						<path fill="#B7AF8B" d="M452.4,483.7c0,0.2-0.1,0.3-0.1,0.5C452,483.9,452.1,483.8,452.4,483.7z"/>
						<path fill="#F5F0BC" d="M425.5,491.1c0-0.1,0.1-0.1,0.1-0.2C425.5,491,425.5,491,425.5,491.1L425.5,491.1z"/>
						<path fill="#F5F0BC" d="M443,471.1c0.3,0.2,0.3,0.4,0,0.6C443,471.5,443,471.3,443,471.1z"/>
						<path fill="#CCDEC7" d="M419,477.6c1.1-0.2,1.7,0.2,1.4,1.4c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,0.8-0.6,2.1,1.1,1
							c0.2-0.2,0.6-0.3,0.7,0c0.5,0.8-0.1,1.5-0.5,2.1c-2.8,1.8-2.8,1.8-5.1-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c0-0.4-0.1-0.8-0.1-1.3
							c0.2-0.4,0.5-0.7,1-0.5c0.2,0.5,0.4,1,1,1c0.5,0,0.4-0.6,0.5-1C418.6,480.1,418.8,478.8,419,477.6z"/>
						<path fill="#32613E" d="M416,481.9c0,0.4,0.1,0.8,0.1,1.3c-1.5,1.1-1.5,2.6-0.2,3.5c0.3,0.2,0.5,0.5,0.5,0.8
							c-0.9-0.1-1.9-0.2-2.8-0.4c0.2-2.4,0.4-4.8,0.6-7.2c0.1-0.1,0.3-0.2,0.4-0.3C416.1,479.8,414.7,481.6,416,481.9z"/>
						<path fill="#32613E" d="M416.4,483.7c2.3,2.3,2.3,2.3,5.1,0.5c0.7,1.7-1.1,1.8-1.8,2.5c-0.6,0.6-1.7,0.4-2.2-0.4
							C416.9,485.5,415.4,485.1,416.4,483.7z"/>
						<path fill="#3E3821" d="M398.7,495.9c-2.9-0.3-5.9-0.7-8.8-1c0.2-0.2,0.3-0.6,0.5-0.6c2.8,0.3,5.8,0.1,8.5,1.2
							C398.8,495.6,398.8,495.7,398.7,495.9z"/>
						<path fill="#B7AF8B" d="M415.3,497.9c-4.6-0.1-9.1-0.6-13.6-1.5c3-0.1,5.9,0.6,8.9,0.7c0.3,0,0.6,0.1,0.9,0.1
							c0.1,0,0.3,0,0.4,0C413.1,497.5,414.2,497.7,415.3,497.9z"/>
						<path fill="#32613E" d="M419,477.6c-0.2,1.2-0.4,2.5-0.6,3.7c-0.1,0.4,0,1-0.5,1c-0.5,0-0.8-0.5-1-1
							C417.3,479.9,417.1,478.2,419,477.6z"/>
						<path fill="#32613E" d="M410.6,497.1c-3-0.2-5.9-0.8-8.9-0.7c-0.3,0-0.5-0.1-0.8-0.1c-0.6-0.3-1.2-0.4-1.8-0.3
							c-0.1,0-0.2,0-0.3-0.1c0.1-0.1,0.1-0.3,0.2-0.4c3.3,0.4,6.5,0.7,9.8,1.1C409.4,496.6,410.1,496.5,410.6,497.1z"/>
						<path fill="#CCDEC7" d="M420.4,474.9c-0.5-0.6-0.7-1.3-0.8-2c0.7-0.3,1.2,0,1.8,0.3c0.6,1,2,0.8,2.5,1.9
							c-0.3,0.1-0.7,0.1-1,0.2c-0.5-0.4-1.1-0.4-1.7-0.3C420.9,474.9,420.6,474.9,420.4,474.9z"/>
						<path fill="#CEC69D" d="M414.2,479.9c-0.2,2.4-0.4,4.8-0.6,7.2c-0.2,0-0.4,0-0.6,0l0,0c0.3-2.8,0.7-5.7,1-8.5
							c0.1,0,0.3,0.1,0.4,0.1c0,0.3,0,0.6-0.1,0.9C414.3,479.7,414.2,479.8,414.2,479.9z"/>
						<path fill="#CCDEC7" d="M416.1,474.4c-0.3-0.8,0-1,0.7-0.9c0.8,0.1,1.8-0.2,1.7,1.2c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0-0.4-0.1
							C417,474.5,416.5,474.4,416.1,474.4z"/>
						<path fill="#32613E" d="M414.9,477.9c0.5-1.1-0.7-2.7,1.2-3.3C415.6,475.7,416.6,477.3,414.9,477.9z"/>
						<path fill="#888158" d="M421.2,474.9c0.6-0.2,1.2-0.1,1.7,0.3C422.3,475.3,421.7,475.4,421.2,474.9z"/>
						<path fill="#888158" d="M414.2,479.9c0-0.1,0-0.3,0.1-0.4c0.1,0,0.2,0,0.3,0.1C414.5,479.7,414.4,479.8,414.2,479.9z"/>
						<path fill="#32613E" d="M418.7,498c-0.1,0-0.3,0-0.4,0C418.4,497.8,418.6,497.8,418.7,498z"/>
						<path fill="#32613E" d="M411.9,497.3c-0.1,0-0.3,0-0.4,0C411.7,497,411.8,497,411.9,497.3z"/>
						<path fill="#888158" d="M417.4,474.5c0.1,0,0.3,0,0.4,0.1C417.7,474.6,417.5,474.6,417.4,474.5z"/>
						<path fill="#E9E4B6" d="M370.5,320.5c-0.5,2.8-0.6,2.5-3.4,2.6c-6,0.3-10-2.2-12.3-7.7c-0.6-1.3-1.5-2.6-2.4-3.7
							c-1.5-1.9-1.4-4-0.8-6c0.5-1.8,2-0.5,3-0.6c0,0.1,0,0.2-0.1,0.2c-2.8,1.9-2.9,3.5-0.9,6.4c1.9,2.8,4.5,5.1,5.7,8.4
							c0.2,0.4,0.7,0.9,1,1c2.2,0.2,4.4,1,6.7,0.3C368.4,321.9,369.1,319.9,370.5,320.5z"/>
						<path fill="#CEC69D" d="M350.5,302.9c-1.7-1-3.1-2.3-3.3-4.5c0.9-0.7,1.3,0.6,2.2,0.5c0.2-1.1,0.4-2.2,0.5-3.3
							c0.1-1.3,0.7-1.6,1.8-1.1c-0.1,1.3-0.3,2.6-0.4,3.9c-0.1,0.9,0.2,1.2,1,0.6c0.3-0.3,0.7-0.4,1.1-0.4c0,0.3,0,0.5,0.1,0.8
							c-0.1,1.2-1.3,1.7-1.7,2.6C351.4,302.4,351,302.6,350.5,302.9z"/>
						<path fill="#32613E" d="M373.6,326.4c-0.3,0.2-0.6,0.7-1,0.7c-2.9,0.1-5.9,0.1-8.7-0.9c1.9-0.8,3.7,0.1,5.5,0.2
							c3.4,0.2,3.4,0.3,3.8-3c0.1-0.6,0-1.3,0.5-1.7c0,0,0.1,0,0.1,0l0.1,0c0,0.7-0.1,1.5-0.1,2.2c-0.4,0.3-0.5,0.7-0.2,1.2
							C373.7,325.6,373.6,326,373.6,326.4z"/>
						<path fill="#32613E" d="M358.2,327c-0.1,0.8-0.2,1.7-0.3,2.5c-0.6,1-0.4,2.1-0.5,3.2c-1.5-3.2-1.3-5.4,0.7-6.5
							C358.1,326.4,358.1,326.7,358.2,327z"/>
						<path fill="#32613E" d="M355.8,334.3c1.2,0,1.7,0.7,1.5,1.9c0,0-0.1,0.1-0.1,0.1C356.5,335.8,356.1,335.1,355.8,334.3
							L355.8,334.3z"/>
						<path fill="#B7AF8B" d="M357.4,332.7c0.1-1.1,0-2.2,0.5-3.2c-0.1,1.1-0.2,2.1-0.2,3.2c0,0-0.1,0-0.1,0L357.4,332.7z"/>
						<path fill="#888158" d="M374,321.7l-0.1,0c0,0-0.1,0-0.1,0c0.3-0.3-0.1-1.3,0.9-0.9C374.4,321.1,374.2,321.4,374,321.7z"/>
						<path fill="#B7AF8B" d="M358.2,327c0-0.3-0.1-0.5-0.1-0.8c0.2,0,0.5,0.1,0.7,0.1C358.7,326.6,358.5,326.8,358.2,327z"/>
						<path fill="#F5EBB0" d="M404.3,476.8c0-0.4,0.1-0.9,0.1-1.3c2.9,0.4,5.9,0.9,8.8,1.3c0.2,0.7-0.9,0.9-0.6,1.8
							c0.6,1.8,0.4,3.6-0.8,5.3c-1,1.5-0.5,2.8,1.2,3.2l0,0c-0.1,1.2,1.3,0.8,1.7,1.6c0.2,0.5,0.7,0.9,0.4,1.5
							c-0.3,0.6-0.8,0.4-1.3,0.4c-2.8-0.3-5.6-0.6-8.4-0.9c-0.8-0.1-1.5-0.3-1.4-1.2c0.4-2.5-0.4-4.9,0.3-7.4
							c0.3-1.2,0.2-2.6,2.1-2.5c0.7,0,0.9-0.6,0.7-1c-0.3-0.7-0.7-0.2-1,0C405,478.3,405,476.9,404.3,476.8z"/>
						<path fill="#CEC69D" d="M355.7,334.4c0.4,0.7,0.9,1.4,1.5,1.9c0.7,1.3,0.7,1.3,0.4,2.7c-0.8,0.3-1-1.2-1.7-0.7
							c-0.7,0.4-0.2,1.4-0.5,2c-0.4,0.8,0.5,2.6-1.2,2.4c-1.4-0.2-0.4-1.6-0.5-2.5c-0.1-0.8,0.4-1.5,0.1-2.3c-0.8-0.3-1.5,1-2.3,0.3
							l0,0C352.3,336.2,353.6,334.8,355.7,334.4z"/>
						<path fill="#14653A" d="M351.5,338.1c0.3,1.5,1,2.9,1.2,4.4c0.1,0.6,0.6,1.3-0.2,1.6c-0.9,0.3-2,0.3-2.7-0.5
							c-0.4-0.4,0.3-5,0.7-5.3C350.7,338.1,351.2,338.2,351.5,338.1C351.5,338.1,351.5,338.1,351.5,338.1z"/>
						<path fill="#CCDEC7" d="M357.4,345.8c-0.8,1.4-2.3,1.3-3.6,1.5c-0.9,0.1,0-1-0.2-1.5C354.9,345.1,356.2,345.9,357.4,345.8z"/>
						<path fill="#CCDEC7" d="M349.6,347.4c2.1-0.2,1.2,1.6,1.6,2.5c0.4,0.9-0.7,0.4-1.1,0.7c-0.3-0.1-0.5-0.1-0.8-0.2
							c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.5C349.4,348.2,349.5,347.8,349.6,347.4z"/>
						<path fill="#14653A" d="M351.5,333.1c0.7,0.2,0.8,0.8,0.7,1.5c-0.1,0.6-0.3,1.1-1.1,0.9c-0.9-0.2-0.6-0.9-0.6-1.5
							C350.7,333.5,350.8,333,351.5,333.1z"/>
						<path fill="#888158" d="M348.9,344.7c0-2.7,0.3-5.3,0.8-8C349.7,339.5,349.4,342.1,348.9,344.7z"/>
						<path fill="#888158" d="M349.8,335.8c0-1.2-0.3-2.5,0.6-3.6C350.1,333.4,350.4,334.7,349.8,335.8z"/>
						<path fill="#888158" d="M348.5,350.7c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.2,0.6-0.2,0.8c-0.6-0.6-0.2-1.2,0.1-1.8
							C348.4,350.7,348.5,350.7,348.5,350.7z"/>
						<path fill="#888158" d="M349.3,348.6c0,0.2,0,0.4,0,0.5C349.1,349,349.1,348.8,349.3,348.6z"/>
						<path fill="#14653A" d="M353.5,299.4c0-0.3,0-0.5-0.1-0.8c0.1-0.6,0.1-1.2,0.3-1.8c0.4-1.8-0.3-2.4-2-2.2
							c-1.1-0.5-1.7-0.2-1.8,1.1c-0.1,1.1-0.3,2.2-0.5,3.3c-0.9,0.1-1.3-1.2-2.2-0.5c-0.6-1.7-1.7-3.2-0.1-5.1
							c0.7-0.8,1.1-1.1,2-0.9c1.2,0.3,2.3,0,3.4-0.5c0,0.6,0.4,0.8,0.9,0.9c1.9,0.6,2.9,4.9,1.5,6.3
							C354.5,299.7,354,299.4,353.5,299.4z"/>
						<path fill="#CEC69D" d="M352.7,291.8c0.1-1.5,0.5-1.6,2.2-1c0.2,0.1,0.4,0.1,0.6,0.2C355.1,293,353.7,291.7,352.7,291.8z"/>
						<path fill="#888158" d="M349.5,286.8c-0.1-0.1-0.1-0.2,0-0.4c1.3-0.9,2.7-0.5,4.1-0.1c-0.7,0.4-2.3-0.3-1.8,1.5
							C350.7,288.2,350.2,287.3,349.5,286.8z"/>
						<path fill="#B7AF8B" d="M351.7,287.9c-0.5-1.8,1.1-1.1,1.8-1.5c0.3,0,0.5,0,0.8,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.2,0.6-0.3,1
							c-0.3,0-0.8-0.8-0.9-0.1C353,289.2,352.4,288.5,351.7,287.9z"/>
						<path fill="#CCDEC7" d="M354.1,287.6c0.1-0.3,0.2-0.6,0.3-1c0.1,0,0.3,0,0.4-0.1c1.9,0.4,0.9,1.7,0.9,2.7
							c-0.8,0.2-1.1-0.4-1.5-0.9C354.2,288.1,354.2,287.9,354.1,287.6z"/>
						<path fill="#B7AF8B" d="M354.3,288.3c0.4,0.5,0.7,1.1,1.5,0.9c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2-0.1-0.4-0.1-0.6-0.2
							c0.1-0.5,0-0.9-0.5-1.2C354,289.3,353.7,288.9,354.3,288.3z"/>
						<path fill="#888158" d="M348.9,285.9c-0.2,0-0.3-0.1-0.5-0.1C348.6,285.7,348.8,285.7,348.9,285.9z"/>
						<path fill="#888158" d="M354.8,286.6c-0.1,0.1-0.3,0.1-0.4,0.1c0-0.1,0-0.1,0-0.2C354.5,286.5,354.7,286.5,354.8,286.6z"/>
						<path fill="#B7AF8B" d="M323.9,490.1c0.2,0.9-0.5,0.8-1,0.8c-0.5,0-1-0.4-0.9-1c0.1-1.7,0.1-3.4,0.9-4.9
							c0.3-0.6,0.8-1.1,1.1,0.1c-0.8,0.7-1.6,4.1-1.1,4.8C323.1,490.5,323.5,490.4,323.9,490.1z"/>
						<path fill="#F5EBB0" d="M323.9,490.1c-0.4,0.2-0.8,0.3-1.1-0.1c-0.5-0.8,0.3-4.1,1.1-4.8C324.6,486.9,323.9,488.5,323.9,490.1
							z"/>
						<path fill="#B7AF8B" d="M447.1,471.7c0.7,0.1,1,0.5,0.5,1.1c-1.7,2.2,0.2,3.4,1.4,4.8c0.4,0.5,1.2,0.7,0.5,1.5
							c-0.5,0.6-1.1,0.3-1.7-0.1c-2-1.5-2.2-3.7-2.4-5.9C445.4,471.9,446.3,471.9,447.1,471.7z"/>
						<path fill="#B7AF8B" d="M322.7,480c0-2-0.3-3.8,0-5.6c0.2-1.5,1-1.5,2.1-1.4c1.1,0.1,2.2,0.1,2,1.7c-0.2,1.7-0.1,3.3-2.6,3
							C322.6,477.7,323,479.1,322.7,480z"/>
						<path fill="#32613E" d="M343.6,422.2c0-0.4,0.1-0.9,0.1-1.3c1.5-8.8,2-17.8,3-26.6c1.4-12.6,2.8-25.3,4.2-37.9
							c0.1-0.9,0.1-1.8,0.1-2.8c0-0.2,0.1-0.4,0.1-0.6c0.2-0.3,0.6-0.7,0.8-0.2c0.2,0.5,0,1.1-0.1,1.7c-2.5,22.9-4.9,45.9-7.4,68.8
							c-0.1,0.8,0.4,2-1,2.2C343.4,424.5,343.5,423.4,343.6,422.2z"/>
						<path fill="#32613E" d="M348.2,352.4c0.1-0.3,0.2-0.6,0.2-0.8c0.1,0.9,1.4,0.3,1.5,1.2C349.3,352.9,348.7,353,348.2,352.4z"/>
						<path fill="#B7AF8B" d="M448.7,498.1c-0.9,0.1-1.8,0.1-2.6-0.3C447,497.5,447.9,497.6,448.7,498.1z"/>
						<path fill="#B7AF8B" d="M437.4,499c2.6,0,5.1,0.3,7.7,0.8C442.5,500,440,499.7,437.4,499z"/>
						<path fill="#B7AF8B" d="M424.7,497.7c0.3,0,0.7,0.1,1,0.1C425.3,498.3,424.9,498.3,424.7,497.7z"/>
						<path fill="#E9E4B6" d="M413,487.1c-1.6-0.4-2.2-1.7-1.2-3.2c1.1-1.7,1.3-3.5,0.8-5.3c-0.3-1,0.8-1.1,0.6-1.8
							c0.1-0.1,0.2-0.2,0.3-0.2c0.3,0.1,0.6,0.3,1,0.4c-0.2,0.5-0.3,1.1-0.5,1.6C413.7,481.5,413.4,484.3,413,487.1z"/>
						<path fill="#14653A" d="M421.3,473.1c-0.6-0.2-1.1-0.6-1.8-0.3c-0.5,0-1.1,0.7-1.5-0.1c-0.4-0.8,0.3-1.2,0.9-1.6
							c0.7-0.1,1.4-0.1,2.1-0.2C421.2,471.7,421.3,472.4,421.3,473.1z"/>
						<path fill="#CCDEC7" d="M417.6,469.3c0.5,0.1,0.9,0.3,0.6,0.8c-0.5,0.6-0.1,1.9-1.3,1.9c-1-0.1-0.6-0.9-0.6-1.5
							C416.3,469.6,416.7,469.2,417.6,469.3z"/>
						<path fill="#CCDEC7" d="M421.1,471c-0.7,0.1-1.4,0.1-2.1,0.2c0.3-0.7,0.6-1.5,1.6-1.5C421.6,469.7,422.2,470,421.1,471z"/>
						<path fill="#D02826" d="M413.5,476.6c-0.1,0-0.2,0.1-0.3,0.2c-2.9-0.4-5.9-0.9-8.8-1.3c-0.1-0.2-0.2-0.3-0.3-0.5
							c0.5-2.8,0.1-5.7,1.3-8.4c2.5-0.3,5,0.2,7.5,0.5c1.5,0.2,2.1,1,1.7,2.5C414,471.9,414.7,474.4,413.5,476.6z M408.7,471.7
							c0,0.4-0.1,0.8-0.1,1.3c-0.1,0.7-0.2,1.5,0.8,1.6c1.1,0.1,1.2-0.7,1.3-1.5c0.1-0.8,0.2-1.5,0.2-2.3c0-0.9,0-2-0.9-2.2
							c-1.1-0.2-1.1,1.1-1.2,1.8C408.8,470.9,408.8,471.3,408.7,471.7z"/>
						<path fill="#32613E" d="M349.4,290.1c-0.2-0.2-0.3-0.5-0.5-0.7c0.2,0,0.3,0.1,0.5,0.1C349.6,289.7,349.6,289.9,349.4,290.1z"
							/>
						<path fill="#B7AF8B" d="M399.7,498c0.5-0.2,1-0.3,1.4,0.2c-0.4,0-0.9,0.1-1.3,0.1C399.7,498.2,399.7,498.1,399.7,498z"/>
						<path fill="#CEC69D" d="M370.5,397.1c-0.1,1,0.8,1.4,0.4,2.2c-2.3,0.4-2.7-1.8-3.9-2.9c-0.4-0.3-0.3-0.8,0.1-1.1
							c1.4-0.9,2.3-2.9,4.5-2c-1.1,2-1.1,2,1,2.2c0.3,0,0.6,0,0.8,0.1c0.8,0.1,2-0.2,1.9,1.1c-0.1,1.3-1.3,0.8-2,0.7
							C372.3,397.3,371.4,397.2,370.5,397.1z"/>
						<path fill="#CEC69D" d="M347.7,430.6c-0.1,0.8,0.9,2.8-1.4,1.2c-0.8-0.6-0.9,0.1-1,0.7c-0.2,1.3-0.1,2.7-0.6,3.9
							c-2.3-0.6-0.6-2.3-1-3.5c-0.1-0.5,0.5-1.2-0.2-1.5c-0.6-0.3-0.9,0.4-1.4,0.7c-0.2-0.2-0.5-0.3-0.7-0.5c0.1-0.5,0.3-1,0.4-1.5
							c0.1,0,0.1-0.1,0.1-0.2c0.8-0.4,1.5-1,2-1.7c0.8-0.5,1.4-0.5,2,0.3C346.5,429.3,347.1,429.9,347.7,430.6z"/>
						<path fill="#32613E" d="M342.2,432.2c0.4-0.3,0.8-1,1.4-0.7c0.6,0.3,0,1,0.2,1.5c0.3,1.2-1.4,2.9,1,3.5c0,0,0.1,0.1,0.1,0.1
							c-0.2,0.4,0,1.4-1,0.7c-0.3-0.2-0.6-0.4-0.8,0c-0.2,0.4-0.2,0.9,0.3,1.1c0.5,0.2,1.3,0.4,1.6,0.1c1.3-0.9,2.6-0.3,3.9-0.2
							c0,0.4-0.1,0.8-0.1,1.3c-0.4,0-0.7,0.1-1.1,0.1c-2.1-1.1-4.5,0.4-6.6-1c1.1-1.6,2.8-3.1,1.3-5.4
							C342.2,432.9,342.2,432.5,342.2,432.2z"/>
						<path fill="#32613E" d="M350.5,395.6c-1.1,10.1-2.3,20.3-3.4,30.4c-0.6-0.6-0.3-1.4-0.2-2c0.9-8.9,1.9-17.7,2.9-26.6
							C349.9,396.8,349.7,396,350.5,395.6z"/>
						<path fill="#CCDEC7" d="M341.1,438.6c2,1.4,4.5-0.1,6.6,1c-0.7,1.1-2.3,0.4-3.1,1.4c-0.7,0-1.5,0.4-2-0.5
							c-0.4-0.8-1.1-0.6-1.8-0.1c-0.3-0.3-0.6-0.6-1.1-0.5c-0.5-0.5,0-0.9,0.2-1.3C340.2,438.6,340.6,438.6,341.1,438.6z"/>
						<path fill="#32613E" d="M351.4,390.2c4.5,0.5,9,1,13.5,1.4c-0.7,0.6-1.3,0.5-2.3,0.3c-3-0.5-6-1.6-9.1-0.6
							C352.4,391.8,352.1,390.7,351.4,390.2z"/>
						<path fill="#32613E" d="M364.8,397c-4.8-0.5-9.5-1.3-14.3-1.4c0.3-0.5,0.8-0.4,1.3-0.4c4,0.4,8.1,0.9,12.1,1.3
							C364.2,396.6,364.5,396.8,364.8,397z"/>
						<path fill="#32613E" d="M344,428.3c-0.5,0.7-1.2,1.3-2,1.7C342,428.7,342.7,428.2,344,428.3z"/>
						<path fill="#32613E" d="M370.3,391c1.1,0,2.2,0,3.3,0.4C372.4,391.6,371.3,391.5,370.3,391z"/>
						<path fill="#32613E" d="M375.3,391.5c0.7-0.1,1.4,0,2,0.2C376.7,392.1,376,392,375.3,391.5z"/>
						<path fill="#32613E" d="M366.8,390.5c0.5,0.1,1.1,0.2,1.6,0.2C367.9,391.1,367.3,391.1,366.8,390.5z"/>
						<path fill="#32613E" d="M341.9,430.2c-0.1,0.5-0.2,1-0.4,1.5C341.4,431.2,341.3,430.6,341.9,430.2z"/>
						<path fill="#32613E" d="M365.6,391.1c0-0.3,0-0.6,0.4-0.7C365.8,390.7,365.7,390.9,365.6,391.1z"/>
						<path fill="#32613E" d="M365.1,391.5c0.1-0.1,0.2-0.2,0.3-0.2C365.4,391.4,365.3,391.5,365.1,391.5z"/>
						<path fill="#32613E" d="M351.1,389.6c0.1,0.1,0.1,0.3,0.2,0.4C351.2,389.9,351.1,389.7,351.1,389.6z"/>
						<path fill="#FBEFB2" d="M392.8,356.7c-2.1-0.2-4.3-0.4-6.4-0.7c-0.4-0.6-1.2-0.4-1.7-0.6c-0.3-0.1-0.6-0.3-0.9-0.4
							c1.2-1.6-0.8-2.8-0.6-4.4c1.3-10.8,2.4-21.6,3.5-32.4c0.2-1.9,1.1-2.6,2.8-2.1c3.9,1.2,8,1.1,12,1.7c4.5,0.6,9,1.1,13.5,1.4
							c2.1,0.2,2.5,1,2.2,2.9c-0.7,5.8-1.3,11.6-1.9,17.4c-0.1,1.3-0.3,2.6-0.3,4c0,1.4-0.8,1.5-1.8,1.1c-1.8-0.7-4.6,0.6-5.2,2.3
							c-0.4,1.2-0.5,2.5-1.4,3.5c-0.5,0.6-0.2,1,0.4,1.6c1.2,1.1,1,2.9-0.3,3.7c-0.4,0.3-0.9,0.5-1.2,0.2c-1.7-1.7-2.7-0.3-3.9,0.6
							c-0.9,0.7-2.1,0.9-2.9,0.3C396.6,355.3,394.7,355.6,392.8,356.7z M400.5,347.5c-0.7-0.1-1.4-0.1-2.1-0.2
							c-1.5-0.3-1.9,0.2-2,1.7c-0.2,1.6,0.3,2,1.8,2.1c1.2,0,2.4,0.2,3.6,0.4c1.4,0.2,2.3,0.2,2.5-1.7c0.2-1.9-0.7-2-2-2
							C401.6,347.7,401,347.6,400.5,347.5z M385.7,353.8c0.2-0.2,0.4-0.3,0.4-0.5c0-0.2-0.1-0.3-0.2-0.5c-0.1,0.1-0.4,0.2-0.4,0.4
							C385.5,353.3,385.6,353.5,385.7,353.8z"/>
						<path fill="#14653A" d="M415.2,347.9c0.4,1.8,0.8,3.5-0.1,5.3c-1.5,2.7-3.8,2.3-6.1,1.7c-0.3-1.3,2.4-1.1,1.3-2.7
							c0-0.1,0-0.3-0.1-0.4c-0.4-0.6-1.8-0.5-1.4-1.9c0.4-1.2,1.1-1,1.9-1c0.4,0,0.7-0.2,0.8-0.6c0.1-2.4,1.5-1.8,2.9-1.2
							c0,0,0,0,0,0c0,0.3,0.2,0.5,0.4,0.5C415,347.7,415.1,347.8,415.2,347.9z"/>
						<path fill="#32613E" d="M414.5,347c-1.4-0.6-2.8-1.2-2.9,1.2c0,0.4-0.4,0.6-0.8,0.6c-0.8,0-1.5-0.2-1.9,1
							c-0.4,1.4,0.9,1.3,1.4,1.9c0.1,0.1,0.1,0.3,0.1,0.4c-2.5,0-2.8-0.5-2.3-2.6c0.2-0.7,0.5-1.1,1.3-1.1c0.5,0,1.2,0.4,1.6-0.2
							c0.4-0.7-0.5-1.1-0.8-1.6c-0.1-0.2-0.2-0.4-0.4-1C411.5,346.1,413.3,345.1,414.5,347z"/>
						<path fill="#32613E" d="M414.9,347.5c-0.3-0.1-0.4-0.2-0.4-0.5C414.6,347.2,414.7,347.4,414.9,347.5z"/>
						<path fill="#1D190E" d="M367.2,379.7c0.5,0.3,1,0.6,1.5,0.8c2.1,2.8,2,4.7-0.4,7.4c-1.5,0.6-3.1,0.7-4.7,0.4l-0.2-0.1
							c-0.5-0.3-0.9-0.7-1.4-1c-2-3-1.8-5.3,0.4-7.1c0.7-0.2,1.4-0.4,2.1-0.7C365.5,379.5,366.3,379.6,367.2,379.7z"/>
						<path fill="#E9E4B6" d="M363.6,388.3c1.6,0.3,3.2,0.2,4.7-0.4l0.1,0.2c-0.4,0.6-1.4,0.6-1.5,1.5c-1.1,0.2-2.3,0.3-3.4,0.5
							C363.5,389.5,363.6,388.9,363.6,388.3z"/>
						<path fill="#E9E4B6" d="M362.1,387.2c0.5,0.3,0.9,0.7,1.4,1C362.7,388.3,362,388.3,362.1,387.2z"/>
						<path fill="#FBEFB2" d="M366.9,389.6c0.1-0.9,1.2-0.9,1.5-1.5c2.5,0.1,4.1-2.3,3.1-4.7c-1.1-2.8-1.1-2.8,1.9-2.5
							c6.4,0.7,12.7,1.3,19.1,2c-0.3,2.7-0.6,5.3-0.9,8c-0.2,2.1-1.2,2.8-3,1.8c-0.8-0.5-1.8-0.7-2.5-1.4c-0.7-0.7-1.2-0.6-2-0.1
							c-0.6,0.4-1.5,0.7-1.9-0.4c-0.1-0.3-0.4-0.5-0.7-0.4C376.5,391.6,371.8,389.7,366.9,389.6z"/>
						<path fill="#FBEFB2" d="M380.9,370.4c0-0.2,0-0.5,0-0.7c2.9-0.5,2.5,1.6,2.6,3.6c0.9-3.9,3.8-2,5.7-2.1
							c1.7-0.1,3.2-0.8,3.6-2.1c0.5-1.5,1.4-3.7-1-4.8c-0.2-1.4,0.3-2.7,0.3-4c0.5-0.7,1.2-0.5,1.9-0.5c2.3,0.3,4.6,0.6,6.9,0.8
							c0.1,3.5-1,7-0.9,10.6c0.1,1.9-1.1,2.5-2.9,1.9c-1.2-0.4-2.1,0.1-2.6,1.2l-0.1,0.1c-1.4,0-1.4,0.9-1,1.8
							c0.3,0.7,0.8,1.3-0.4,1.5c-2.1-0.2-4.1-0.5-6.2-0.7c-0.1,0-0.3,0-0.4,0c-1.7-0.7-4,1-5.4-1.2
							C379.9,374.1,381.2,372.3,380.9,370.4z"/>
						<path fill="#32613E" d="M392.1,394.5c-1.7,0.6-3.4-0.3-5.1,0.1c-0.6,0.1-0.8-0.4-0.7-1C388.2,394,390.1,394.3,392.1,394.5z"/>
						<path fill="#32613E" d="M392.7,393.3c0,0.4,0,0.8-0.4,1.1C392.4,394,392.6,393.6,392.7,393.3z"/>
						<path fill="#D02826" d="M392.2,360.4c0,1.3-0.5,2.6-0.3,4c-0.1,0.2-0.1,0.4-0.2,0.6c-0.7,4.7-0.7,4.8-5.6,4.2
							c-1.5-0.2-3.3,0.1-4.3-0.7c-1.3-1,0.1-2.7,0.1-4.2c0.1-5.2,0.3-5.2,5.4-4.7C389,359.9,390.6,360.2,392.2,360.4z M388.8,367.1
							l-0.1,0.1c-1,0.2-1.9,0.9-3.1,0.3c-1-0.5-1.6-1.4-2.3-2.3c0.1,1-1.3,2.5,0.9,2.7c1.7,0.2,3.4,0.3,5.1,0.4
							c1-0.3,0.6-1.2,0.8-1.8c1-4.9,1-4.9-3.7-5.6c-0.3-0.1-0.7-0.1-1-0.2c-1.9-0.2-2.2,0.3-1.4,2.3c0.7-0.6,1.4-1.4,2.5-1.5
							c2.4-0.1,3.5,1.4,3.3,4.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.2-0.4,0.4-0.3,0.7C389.1,366.7,389,366.8,388.8,367.1z
							 M386.9,363.4c-0.7,0-1.1,0.4-1.2,1c-0.1,0.6,0.3,1.1,1,1.2c0.7,0.1,1.2-0.3,1.3-1.1C388,363.8,387.5,363.5,386.9,363.4z"/>
						<path fill="#CEC69D" d="M394.7,374.4c0.5-1.1,1.4-1.6,2.6-1.2c1.8,0.6,3,0,2.9-1.9c-0.1-3.6,0.9-7,0.9-10.6
							c0.3-0.1,0.6-0.3,0.9-0.5c0.3,2.8-0.4,5.6-0.6,8.4c-0.2,1.9-0.4,3.8-0.6,5.7c-1.5,1.3-3.2,0.2-4.9,0.3
							C395.5,374.7,395.1,374.5,394.7,374.4z"/>
						<path fill="#CEC69D" d="M401.4,359.9c0.3-0.1,0.4,0,0.5,0.3C401.7,360.1,401.6,360,401.4,359.9z"/>
						<path fill="#CCDEC7" d="M354.7,350.9c-1.2,0.1-2.2-0.3-2.3-2C353.2,349.6,354.8,349.1,354.7,350.9
							C354.7,350.8,354.7,350.9,354.7,350.9z"/>
						<path fill="#1D190E" d="M379.6,341.6c-0.4,0-0.8,0-1.2,0c-0.2,0-0.4,0-0.6-0.1c-0.8-0.6-2.2,0.3-2.6-1.1
							c1.2,0.1,2.5,0.2,3.7,0.4C379.4,340.8,380,340.8,379.6,341.6z"/>
						<path fill="#B7AF8B" d="M370.6,339.5c-1,0.2-2,0.1-3-0.3C368.6,339,369.6,339.1,370.6,339.5z"/>
						<path fill="#888158" d="M373.7,325.1c-0.3-0.4-0.2-0.8,0.2-1.2C374,324.4,373.9,324.7,373.7,325.1z"/>
						<path fill="#D02826" d="M245.7,447.6c-1.8-0.2-3.6-0.5-5.5-0.6c-1.3-0.1-1.8-0.5-1.6-1.8c0.4-2.5,0.2-5.2,1.4-7.6
							c1.4,0.2,2.8,0.6,4.2,0.3c0.3,0,0.6,0.1,0.9,0.1c0.5,0.2,1.1,0.3,1.6,0.2c0.3,0,0.6,0,0.9,0.1c0.4,0.1,0.7,0.3,1.1,0.4
							c0,0-0.1-0.1-0.1-0.1c-0.1,0.1-0.1,0.2-0.1,0.4c-0.6,2.7-0.8,5.5-0.9,8.2C247,447.4,246.4,447.5,245.7,447.6z M242.9,445.3
							c0.2,0.2,0.3,0.4,0.5,0.7c0.4,0.5,0.8,1,1.5,0.8c0.2,0,0.4-0.3,0.4-0.4c0-0.8-0.7-1-1.2-1.3c-0.2-0.1-0.4-0.1-0.6-0.2
							c-0.3-1.4-1.4-2.6-0.9-4.2c0.2-0.5,0-1-0.4-1.3c-0.2-0.2-0.5-0.2-0.8,0c-0.5,0.4-0.5,1-0.4,1.5
							C241.7,442.4,242,444,242.9,445.3z"/>
						<path fill="#F5F0BC" d="M205.6,448.5c-1.1-0.1-1.1-0.8-0.6-1.5c0.6-0.8,1.4-1,2.5-0.9c7.7,0.9,15.4,1.7,23,2.5
							c0.6,0.1,1.2,0.3,1.8,0.5c1.7,0.8,1.8,2.6,0.4,3.6c-0.2,0.1-0.4,0.2-0.6,0.2c-5.4-2.3-11.2-1.5-16.8-2.5c-1.4-0.3-3,0.1-4.3,0
							c-1.6-0.1-3.7-0.3-5.1-1.9c0.2,0,0.3-0.1,0.5-0.1c8.3,0.9,16.6,1.8,24.9,2.6c0.2,0.2,0.4,0.2,0.5-0.1l0.1,0
							c0.3,0,0.6-0.1,0.6-0.4c0-0.4-0.3-0.5-0.7-0.5c-8.4-0.9-16.9-1.8-25.3-2.7C205.4,447.2,205.5,447.9,205.6,448.5z"/>
						<path fill="#F5F0BC" d="M209.5,440c-0.8-1-0.7-1.8,0.2-2.7c0.5,0.6,1,0.1,1.6,0.2c0.4,0,0.9,0.1,1.3,0.1
							c0.7,0.5,1.5,0,2.2,0.1c2.8,0.2,5.4,1.8,8.2,0.9c0.4,0,0.8,0.1,1.1,0.1c0.2,0,0.4,0,0.6,0.1c0.5,0.4,1.1,0.7,1.7,0.7
							c0,1,0.8,1,1.5,1c0.8,0.1,1.4,0.3,1.3,1.2c-2.6,0.3-5.1-1-7.7-0.9c0.1-0.4,0.6-0.8-0.2-0.9c-0.7-0.1-1.6-0.5-1.9,0.7
							c-1.6,0.5-3.2,0.1-4.7-0.4c-0.4,0.7,0.1,1.2,0,1.7c-0.4,2-1.3,1.5-2.2,0.3c1.1-0.8,1.3-1.8-0.1-2.1
							C211.6,440,210.5,439.4,209.5,440z"/>
						<path fill="#888158" d="M221.6,440.9c2.6-0.1,5.1,1.2,7.7,0.9c0.3,0,0.5,0.1,0.8,0.1c0.6,0.1,1.1,0.1,1.7,0.2
							c0.7,1.4-1.1,1.4-1.3,2.3c-0.1,0.4-0.7,0.1-1-0.3c-0.3-0.4-0.9-0.5-1.3-0.5c-3.4,0.5-6.6-1-10-0.9c-1.1,0-2-1-3.2-0.7
							c0.1-0.5-0.5-1.1,0-1.7c1.5,0.5,3.1,0.9,4.7,0.4C220.2,441,220.9,441,221.6,440.9z"/>
						<path fill="#3E3821" d="M204.2,438.7c-0.1,1.1-0.2,2.1-0.3,3.2c-0.1,0.7-0.1,1.5-1.2,1.3c-0.9-0.1-0.8-0.8-0.7-1.5
							c0.1-1.2,0.2-2.4,0.4-3.6c0.1-0.8,0.1-1.5-1.1-0.9c-0.3,0.2-0.7,0.3-0.9-0.1c-0.2-0.4-0.1-0.9,0.3-1.1
							c0.9-0.6,1.7-1.2,2.6-1.8c0.8-0.6,1.5-0.3,1.4,0.9C204.5,436.3,204.3,437.5,204.2,438.7C204.2,438.7,204.2,438.7,204.2,438.7z
							"/>
						<path fill="#B7AF8B" d="M280.8,447.4c-2.4,0.2-4.7-0.7-7-0.7c-1.3,0-3.2-1.1-3.7-0.8c-1.7,1.1-3.1-0.3-4.6-0.1
							c-0.4,0-0.9,0.2-0.9-0.5c0-0.3,0-0.8,0.2-1c0.3-0.3,0.7-0.1,0.9,0.2c0.6,0.9,0.9-0.2,1.4-0.1c4.5,0.7,9.2,0.1,13.4,2.3
							C280.7,446.9,280.7,447.1,280.8,447.4z"/>
						<path fill="#888158" d="M223,438.7c-2.9,0.9-5.5-0.8-8.2-0.9c-0.7,0-1.5,0.4-2.2-0.1c-0.2-0.5-0.4-1-0.3-1.5
							c1.2-0.8,2.4,0.1,3.6,0c2.6-0.4,5,1.2,7.6,0.8C223.3,437.5,223.1,438.1,223,438.7z"/>
						<path fill="#CEC69D" d="M260.6,451.8c-1.3,0.9-2.3-0.8-3.6-0.4c-0.5,0.2-0.8-0.4-0.7-1c0.1-0.5,0.5-0.8,0.9-0.5
							c1.1,0.7,2.5-0.1,3.6,0.5c0.6,0.3,1.8,0.1,1.6,1.2C262.2,452.7,261.3,452.2,260.6,451.8z"/>
						<path fill="#888158" d="M209.5,440c1.1-0.6,2.2,0,3.1,0.2c1.4,0.3,1.2,1.3,0.1,2.1c-0.8-0.2-1.6-0.6-2.4-0.7
							C208.9,441.5,209.3,440.8,209.5,440z"/>
						<path fill="#CEC69D" d="M268.5,452.4c1.5,0,2.5,0.3,3.5-0.6c0.6-0.5,2,0,3.1,0.1c0.3,0,0.5,0.5,0.4,0.9
							c-0.1,0.4-0.4,0.7-0.7,0.6C272.9,452.8,270.8,453.6,268.5,452.4z"/>
						<path fill="#888158" d="M263.1,456.1c-1.7-0.1-3.4-0.2-5.1-0.4c-0.2-0.6,0.1-1,0.5-1.4c1,0.7,2.1,0,3.1,0.5
							C262.1,455.2,262.6,455.6,263.1,456.1z"/>
						<path fill="#888158" d="M275.2,456.9c-1.9,0.5-3.7,0.1-5.4-0.9c1.8,0.3,3.6-0.4,5.4,0.1C275.3,456.4,275.3,456.7,275.2,456.9z
							"/>
						<path fill="#B7AF8B" d="M275.2,456.9c0-0.3,0-0.6,0-0.8c1,0.1,2.1,0.2,3.1,0.3c0.6,0.1,1.2,0.1,1,1c-0.1,0.8-0.7,0.9-1.3,0.6
							C277.2,457.5,276.1,457.7,275.2,456.9z"/>
						<path fill="#888158" d="M270.8,442.2c-0.7,0-1.9,0.5-1.5-0.7c0.4-1.3,1.7-0.1,2.6-0.4c0.4-0.2,1,0,0.9,0.5
							C272.4,442.8,271.6,442.6,270.8,442.2z"/>
						<path fill="#CEC69D" d="M245.7,447.6c0.7-0.1,1.3-0.2,2-0.4c0.7-2.7,0.8-5.5,0.9-8.2c0-0.1,0-0.2,0.1-0.4c0.9,0.9,0.2,2,0.2,3
							c0,1.3-0.4,2.6-0.4,4C248.6,447.7,247.6,448.3,245.7,447.6z"/>
						<path fill="#888158" d="M211.3,437.6c-0.5-0.1-1.1,0.4-1.6-0.2c0-0.8-0.3-1.7,0.6-2.3c0.6-0.3,1.2-0.4,1.5,0.4
							c-0.8,0-1.6-0.1-0.7,1.1C211.3,436.8,211.2,437.2,211.3,437.6z"/>
						<path fill="#888158" d="M224.7,438.9c-0.2,0-0.4,0-0.6-0.1c0-0.6,0.1-1.3,0.1-1.9c1,0.2,2.3-0.2,2.3,1.5
							C225.9,438.4,225.1,437.8,224.7,438.9z"/>
						<path fill="#CEC69D" d="M259,448.3c0-0.5,0.4-0.7,1-0.7c0.6,0,1.2,0.2,1.1,1c-0.1,0.4-0.3,1-0.7,0.4
							C260,448.4,258.9,449.7,259,448.3z"/>
						<path fill="#CEC69D" d="M224.7,438.9c0.4-1,1.2-0.5,1.8-0.5c0.4,0,1,0,1,0.4c0.1,0.7-0.7,0.6-1.1,0.9
							C225.8,439.6,225.2,439.3,224.7,438.9z"/>
						<path fill="#CEC69D" d="M258.4,454.3c-0.4,0.4-0.6,0.8-0.5,1.4c-0.6,0-1.7,0.5-1.6-0.5C256.4,454,257.5,454,258.4,454.3z"/>
						<path fill="#B7AF8B" d="M211.3,437.6c0-0.3,0.1-0.8-0.1-1c-0.8-1.1-0.1-1.1,0.7-1.1c0.1,0.2,0.3,0.4,0.4,0.7
							c-0.2,0.6,0,1,0.3,1.5C212.1,437.6,211.7,437.6,211.3,437.6z"/>
						<path fill="#CEC69D" d="M278.1,452.1c-0.1,0.9-0.2,1.6-0.9,1.9c-0.3,0.1-0.6-0.1-0.6-0.4C276.6,452.7,277.3,452.6,278.1,452.1
							z"/>
						<path fill="#CEC69D" d="M263.1,456.1c-0.5-0.4-1-0.8-1.5-1.3c0.6-0.2,1.3-0.2,1.9,0c0.4,0.1,0.8,0.5,0.6,1
							C263.8,456.2,263.4,456.1,263.1,456.1z"/>
						<path fill="#CEC69D" d="M224.3,436.9c0,0.6-0.1,1.3-0.1,1.9c-0.4,0-0.8-0.1-1.1-0.1c0.1-0.6,0.3-1.2,0.4-1.8
							C223.7,436.9,224,436.9,224.3,436.9z"/>
						<path fill="#CEC69D" d="M244.2,437.9c-1.4,0.3-2.8-0.1-4.2-0.3C241.5,437.1,242.9,437.4,244.2,437.9z"/>
						<path fill="#F5F0BC" d="M231.7,442.1c-0.6-0.1-1.1-0.1-1.7-0.2c0.1-0.6,0.4-1,1.1-0.9C231.8,441.2,231.8,441.6,231.7,442.1z"
							/>
						<path fill="#888158" d="M205.6,448.5c0-0.6-0.1-1.3,0.9-0.9c0,0.3-0.1,0.6-0.1,1c-0.2,0-0.3,0.1-0.5,0.1
							C205.7,448.5,205.7,448.5,205.6,448.5z"/>
						<path fill="#E9E4B6" d="M203.5,432.8c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.3,0.2-0.3
							C203.2,432.6,203.4,432.7,203.5,432.8z"/>
						<path fill="#CEC69D" d="M246.8,438.2c-0.6,0.2-1.1,0.1-1.6-0.2C245.7,437.7,246.2,437.8,246.8,438.2z"/>
						<path fill="#CEC69D" d="M248.8,438.7c-0.4-0.1-0.7-0.3-1.1-0.4C248.2,438,248.7,438,248.8,438.7z"/>
						<path fill="#888158" d="M275.8,442.5c0,0.1-0.1,0.2-0.1,0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3
							C275.7,442.4,275.8,442.5,275.8,442.5z"/>
						<path fill="#FBEFB2" d="M276.7,430.9c-0.4-0.5-0.7-1-1.5-0.5c-1.8,1-3.5,0.2-5.3-0.3c0-0.1,0-0.3,0-0.4
							c0.1-0.8,0.6-1.2,1.4-1.1c0,0.7,0.2,1.2,0.9,1.5c0.1-0.3,0.2-0.6,0.3-1c2.3-1.6,4.6,0,6.9,0c1.9,0,2.8-0.4,2.9-2.4
							c0-0.8-0.4-2.1,1.1-2.4c0.5-0.1,0.9-0.2,0.6-0.8c-0.2-0.5-0.6-1-1.1-0.7c-1.5,0.9-2.9,0.2-4.3,0.1c-16-1.7-32.1-3.4-48.1-5.1
							c-4.3-0.5-8.8-0.6-13-2c-0.7-0.2-1.2,0.2-1.8,0.3c-2.5,0.5-4.4-1.4-6.7-1.6c-0.3,0-0.3-0.4-0.3-0.7c0-0.4,0.2-0.7,0.7-0.7
							c0.6,0,1.3,0.1,1.9,0.1c24.6,2.6,49.2,5.3,73.9,7.9c2.7,0.3,2.7,0.3,1.8,2.8c-0.1,0.6,0.2,0.9,0.7,1
							c-0.4,3.6-0.8,7.2-1.2,10.8c-0.1,0.6-0.1,1.5-1,1.3c-2.9-0.8-6,0-8.9-1.3c-0.5-0.3-0.8-0.5-0.7-1.1c0.6,0.2,1.2,0.2,1.2-0.6
							C277.2,433,278.3,431.7,276.7,430.9z"/>
						<path fill="#F5EBB0" d="M270,429.7c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.4c-1.3-0.8-2.6-0.4-3.9,0.1c-0.3-0.1-0.5-0.1-0.8-0.2
							c-0.1-0.2-0.2-0.2-0.4,0c-0.3,0-0.6,0-0.8,0c-0.5-0.7-1.1-0.9-1.7-0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1-1.1-0.6-1.2-1.4-0.4
							c-0.4-0.3-0.7-0.7-1.2-0.7c-1.7-1.3-3.4,0-5.1-0.1c-0.1,0-0.3,0-0.4,0c-1.3-0.9-3-0.9-4.4-1.7c-0.5-0.3-1.4,0.1-1.6,1
							c-0.6,0-1.1,0-1.7,0c-0.2-0.4-0.1-1-0.6-1.1c-0.2,0-0.5,0.6-0.7,0.9c-2-0.2-2.4,0.7-1.7,2.4c-0.3,0.3-0.4,0.8-0.8,0.9
							c-1.3,0.5-4.8-0.4-5.4-1.4c0.4-0.2,0.8-0.4,1.2-0.6c1.2,0.1,1.4-0.6,0.7-1.4c-0.8-0.9-1.6-1.8-2.7-2.3
							c-0.7-0.3-1.3-0.6-1.8,0.3c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,0.9
							c-0.1,0-0.2,0.1-0.4,0.1c-0.4-0.8-1.2-0.7-1.9-0.7c-5.9-0.6-11.8-1.3-17.7-1.9c-0.5-0.3-1.1-0.6-1.8-0.5
							c-1-0.4-1.9-0.8-3.1-1.2c2.4-1,4.5-1.2,6.8-0.8c1,0.2,2,0.3,2.5-1c0.3,0,0.5,0,0.8,0.1c0.3,0.7,0.9,0.6,1.5,0.4
							c0,0,0,0.1,0,0.1c0.1,0.8,0.4,0.5,0.7,0.2c0.1,0,0.3,0.1,0.4,0.1c0,0.6,0.2,0.8,0.9,0.7c1.8-0.1,3.6-0.2,5.4-0.2
							c0.1,0,0.3,0.1,0.4,0.1c0.1,0.4,0.3,0.4,0.5,0.1c0.4,0,0.8,0,1.2,0c0.4,0.8,1.1,0.8,1.8,0.8c1.4,0.1,2.6,0.5,3.8,1.2
							c1.7,0.9,2.6,0.3,3.4-1.2c0.2-0.2,0.4-0.3,0.6-0.5c0.8,0.4,0.9,0.3,1.3-1.4c0.5-2,1.3-2.3,3.2-1c-0.1,0.5-0.2,1-0.2,1.4
							c0,0.5-0.6,1.4,0.4,1.5c0.7,0.1,0.5-0.8,0.6-1.3c0.1-0.3,0.1-0.8,0.5-0.8c0.6,0,0.6,0.4,0.6,0.9c0,0.6-0.7,1.7,0.9,1.4
							c0.2,0.5,0.5,0.8,0.9,0.9c0.1,0.6,0.4,0.9,1,0.9c0.7,0.1,1.4,0.4,1.6-0.7l0,0c0.7,0.2,1.3,0.4,1.9-0.3c0.1,0,0.3,0.1,0.4,0.2
							c-0.3,1.4,0.7,1.5,1.7,1.4c0.8,0,1.8-0.5,2.5-0.2c3.2,1.4,6.6,1.1,9.9,1.5C267.5,427.7,268.9,428.4,270,429.7z"/>
						<path fill="#F5F0BC" d="M246.9,424.7c-0.5-0.1-0.8-0.4-0.9-0.9c0.3-3.4,0.3-3.4-2.8-3.2c-1.9-1.3-2.7-1-3.2,1
							c-0.4,1.7-0.5,1.7-1.3,1.4c-0.3-1,1-2.5-0.6-3.1c-0.7-0.3-1.5-0.4-2.2,0.2c-0.8,0.7-0.5,2-0.4,2.7c0.2,1.2,1-0.3,1.5-0.1
							c0.4,0.1,0.7,0.6,1.1,0.9c-0.7,1.5-1.7,2.1-3.4,1.2c-1.2-0.7-2.4-1.1-3.8-1.2c-0.7-0.1-1.5,0-1.8-0.8c0-0.1,0.1-0.3,0.1-0.4
							c1.6-0.6,0.8-1.8,0.7-2.9c0.2-0.5,0.4-1,0.6-1.5c16,1.7,32,3.5,48.1,5.1c1.4,0.1,2.9,0.8,4.3-0.1c0.5-0.3,0.9,0.3,1.1,0.7
							c0.3,0.6-0.2,0.7-0.6,0.8c-1.5,0.3-1,1.6-1.1,2.4c-0.1,2-1,2.4-2.9,2.4c-2.3,0-4.6-1.6-6.9,0c-0.4-0.1-0.4-1.3-1.2-0.6
							c-0.8-0.1-1.3,0.3-1.4,1.1c-1.1-1.4-2.5-2-4.3-2.2c-3.3-0.4-6.7-0.1-9.9-1.5c-0.7-0.3-1.6,0.2-2.5,0.2c-0.9,0-2,0-1.7-1.4
							c0.6,0.1,0.9-1.1,1.8-0.4c1,0.8,1.1,0.7,1.3-0.4c0.3-1.5,0.1-1.8-1.6-1.5c-0.5,0.1-0.4-0.2-0.5-0.4c-0.1-0.4-0.4-0.6-0.8-0.5
							c-0.4,0.1-0.4,0.5-0.4,0.9c-0.1,0.7-0.2,1.4-0.2,2.1c-0.5,0.7-1.2,0.4-1.9,0.3c0.2-0.3,0.6-0.5,0.5-1c0.2-0.4,1-0.4,0.8-1
							c-0.1-0.4-0.7-0.3-1-0.4c0.2-0.7,0.5-1.4-0.8-1.3c-0.7,0-1.6-0.3-1.7,0.9C247.3,422.9,247,423.8,246.9,424.7z M233.2,421.9
							c-0.1,1,0.6,1,1,0.6c0.6-0.8,0.8-2,0.2-2.7c-0.6-0.7-1,0.2-1.4,0.7c-0.7,0-0.3-2.2-1.6-0.9c-0.6,0.6-0.7,1.8-0.2,2.6
							C232,423.4,232.5,421.7,233.2,421.9z M261.1,424.2c-0.3,1.1,0,1.9,1.3,2c1.2,0.1,1.9-0.2,2.1-1.7c0.1-1.5-0.5-1.9-1.7-2.1
							C261.4,422.2,261.1,423,261.1,424.2z M269.1,425.6c0,0.7-0.5,1.6,0.3,2.1c0.4,0.3,3.2-1.9,3.2-2.5c0.2-1.6-1.1-1.3-2-1.5
							C268.8,423.3,269.3,424.8,269.1,425.6z M268.3,425.1c0.3-1.3,0-2.1-1.5-2.3c-2-0.2-0.8,1.9-1.7,2.6c-0.4,0.3,0.5,1.2,1.3,1.2
							C267.5,426.6,268.6,426.6,268.3,425.1z M260.2,424.5c0.1-1.3,0-2.3-1.4-2.5c-1.8-0.2-1.3,1.6-1.8,2.4
							c-0.5,0.7,0.4,1.3,1.2,1.3C259,425.7,260.3,426.1,260.2,424.5z M273.6,425.7c0.1,0.6-0.6,2.3,1.3,1c0.6-0.4,0.7,0,0.9,0.3
							c0.4,0.5,0.8,0.4,0.9-0.2c0.2-0.8,0.6-1.8,0.1-2.4c-0.4-0.6-1,0.1-1.4,0.4c-0.2,0.1-0.3,0.1-0.5,0
							C273.7,424.2,273.7,424.2,273.6,425.7z M281.1,424.6c-0.9,0.2-1.6,1.7-2.2,0c-0.1-0.3-0.5-0.3-0.7,0c-0.4,0.8-0.6,1.7-0.3,2.5
							c0.4,1,1.5,0,2.4,0.5c0.8,0.4,0.6-0.8,0.7-1.4C281.1,425.8,281.1,425.3,281.1,424.6z"/>
						<path fill="#F5EBB0" d="M228.6,428.7c0.2,0,0.4,0,0.6,0.1c0.5,1,1.6,0.3,2.2,0.8c0.3,0.2,0.8,0.5,0.9,0.7
							c0.1,0.5-0.5,0.4-0.8,0.4c-3.1-0.3-6.2-0.7-9.3-1c-0.5-0.1-0.9-0.4-1.1-0.9c0.3-0.1,0.5-0.1,0.8-0.2c0.3,0.7,0.7,0.6,1.1,0.1
							c0.9,0.2,1.8,0.7,2.5-0.3c0.2,0,0.3,0,0.5,0.1C226.8,429.4,227.7,429,228.6,428.7z"/>
						<path fill="#3E3821" d="M205.7,419.6c0.1-0.3,0.3-0.7,0.4-1c0.5-0.5,0.5-1.2,0.6-1.8c0.2-1.4,1-1.3,2.1-1.3
							c1.2,0.1,1.4,0.7,1.2,1.7c-0.2,0.9-0.2,1.8,0.1,2.7c0.1,0.4,0.2,1-0.4,1c-0.5,0-0.6-0.5-0.8-0.9c0.1-0.3,0-0.6-0.3-0.9
							c0.2-1,1-2.6-0.2-2.7c-1.2-0.2-0.8,1.6-1,2.6c0,0.3-0.1,0.5-0.1,0.8c-0.3,0.2-0.6,0.3-1,0.5
							C206.1,420.1,205.9,419.9,205.7,419.6z"/>
						<path fill="#F5F0BC" d="M227,422.3c-1.8,0.1-3.6,0.1-5.4,0.2c-0.7,0.1-0.9-0.1-0.9-0.7c0.8,0,1.4-0.4,1.8-1.1
							c0.4-0.2,0.8-0.2,1.2,0.1c0.4,1.2,1,1.4,1.3,0c0.2-0.9,0.7-1.3,1.1-1.9c0.2-0.8,0.6-1.1,1.3-0.4
							C226.7,419.8,226.6,421.1,227,422.3z"/>
						<path fill="#888158" d="M214.2,418.9c-0.1,1.2-0.8,2.1-2,2c-1.2-0.1-1.5-1.2-1.4-2.2c0.1-1.1,1-1.5,2-1.5
							C213.6,417.2,214.2,417.9,214.2,418.9z"/>
						<path fill="#888158" d="M216.5,427.4c1.1-0.3,0.4-2,1.9-1.4c0.8,0.3,0.3,1,0.4,1.4c0.1,0.6-0.2,0.7-0.8,0.7c-1,0-2,0-3-0.1
							c-0.8-0.1-1.3-0.6-1.3-1.4c0-0.7,0.4-1,1-1.1c0.9-0.2,1.3,0.3,1.5,1.1C216.4,426.8,216.5,427.1,216.5,427.4z"/>
						<path fill="#888158" d="M227,422.3c-0.3-1.3-0.3-2.5,0.5-3.7c0.6,0.1,1.1,0.3,1.7,0.4c-0.2,1,0,2.1,0.1,3.1
							c0,0.1-0.1,0.3-0.1,0.4c-0.4,0-0.8,0-1.2,0c-0.2,0-0.3-0.1-0.5-0.1C227.3,422.4,227.1,422.4,227,422.3z"/>
						<path fill="#3E3821" d="M218.2,421.1c-0.3,0-0.5,0-0.8-0.1c-0.1-0.4-0.2-1.1-0.6-0.9c-3,1.9-1.2-0.9-1.5-1.6
							c-0.1-0.2,0.2-0.5,0.3-0.8c0.9,0.9,1.7,1.9,2.6,0C218.9,418.9,218.1,420,218.2,421.1z"/>
						<path fill="#888158" d="M222.5,420.8c-0.4,0.6-1,1.1-1.8,1.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2-0.1-0.5-0.1-0.7-0.2
							c0,0,0-0.1,0-0.1c-0.6-0.9-0.6-1.9,0-2.8c0.3-0.5,0.9-0.3,1.4-0.2c0.5,0.1,0.4,0.5,0.2,0.8c-0.4,0.1-0.7,0.3-0.5,0.7
							c0.3,0.8,1,0.2,1.5,0.3C222.3,420.4,222.4,420.6,222.5,420.8z"/>
						<path fill="#B7AF8B" d="M265.2,430.5c0.3,0.1,0.5,0.1,0.8,0.2c0,0.1,0,0.3,0.1,0.4c-0.2,0.4-0.4,0.8-0.6,1.1
							c-0.2,0.4,0,1.3-0.9,1.2c-0.8-0.1-0.2-0.8-0.3-1.2c-0.6-1.3-0.9,0.1-1.4,0.2c-0.4-0.1-1-0.2-1-0.7c0-0.4,0.5-0.5,0.9-0.6
							c0.5,0,0.9-0.2,1.2-0.6c0.3,0,0.6,0,0.8,0c0,0,0.2,0,0.2,0L265.2,430.5z"/>
						<path fill="#888158" d="M222,428.6c-0.3,0.1-0.5,0.1-0.8,0.2c-0.4,0-0.8,1-1.2,0.2c-0.4-0.7-0.1-1.7,0.1-2.5
							c0.2-0.7,0.9-0.6,1.4-0.5c0.6,0.2,1.1,0.5,1,1.2C222.5,427.8,222.2,428.2,222,428.6z"/>
						<path fill="#888158" d="M211.3,424c0.7-0.1,1.2,0.2,1.8,0.5c0,0.4,0,0.7-0.5,0.8c-0.5,0-1.2-0.3-1.3,0.6c0,0.7,0.7,0.5,1,0.7
							c1.1,0.8,0.1,1-0.4,1.3c-0.8-0.2-1.6-0.6-1.8-1.6C210.2,425.4,210.6,424.6,211.3,424z"/>
						<path fill="#888158" d="M274.1,434.4c-1.1-0.1-1.4-0.8-1.2-1.5c0.1-0.5,0.2-1.4,1.1-1.2c0.8,0.2,1.4,0.6,1.2,1.6
							C275,433.9,274.8,434.5,274.1,434.4z"/>
						<path fill="#888158" d="M225.5,428.4c-0.7,1.1-1.6,0.6-2.5,0.3c0-0.1,0-0.3,0-0.4c0.3-0.8,0-2,1.3-2
							C225.6,426.4,225.5,427.5,225.5,428.4z"/>
						<path fill="#888158" d="M269,431.3c-0.1,0.7,0.4,1.7-0.8,2.1c-1.6,0.6-2.1,0.1-1.8-2.4C267.3,431.2,268.1,431.3,269,431.3z"/>
						<path fill="#888158" d="M254,431.1c0.3-0.4,0.5-0.9,0.9-1.1c0.6-0.3,1.2-0.2,1.7,0.3c0.5,0.5,0.5,1,0.1,1.5
							c-0.4,0.6-0.9,0.9-1.6,0.5c-0.4-0.2-0.8-0.5-1.2-0.8C254,431.3,254,431.2,254,431.1z"/>
						<path fill="#888158" d="M258.8,429.4c0.5,0,0.8,0.4,1.2,0.7c0.1,0.7,0.2,1.3,0.2,2c-0.8,0.6-1.9,0.7-2.4-0.1
							C257.1,431,258.5,430.3,258.8,429.4z"/>
						<path fill="#888158" d="M251.1,430.6c-0.2,0.8-0.8,1.1-1.5,1c-0.9-0.1-1.2-0.7-1.1-1.5c0.2-0.7,0.7-1.1,1.4-1
							C250.8,429.2,251.1,429.8,251.1,430.6z"/>
						<path fill="#888158" d="M242.7,430.8c-0.7-1.7-0.3-2.6,1.7-2.4c0.3,0.6-1.6,1.2,0,2.1C244.6,430.6,243.3,431,242.7,430.8z"/>
						<path fill="#B7AF8B" d="M254,431.1c0,0.1,0,0.3-0.1,0.4c-0.2,0.9-0.7,0.7-1.4,0.5c-1-0.4-0.4-1.2-0.6-1.7
							c-0.3-1.4,1-0.4,1.3-0.9c0.1,0,0.3,0,0.4,0C254.3,429.8,253.3,430.6,254,431.1z"/>
						<path fill="#888158" d="M245.7,428.6c0.6,0,1.1,0,1.7,0c0.6,0.8,0.5,1.6,0.1,2.3c0,0.1-0.4,0-0.5-0.1
							C245.5,430.1,245.4,430,245.7,428.6z"/>
						<path fill="#888158" d="M228.6,428.7c-0.9,0.2-1.8,0.6-2.6-0.3c0.2-0.8,0.1-1.5,1.6-1.4C229.3,427.2,228.3,428.2,228.6,428.7z
							"/>
						<path fill="#B7AF8B" d="M276.7,430.9c1.6,0.8,0.4,2,0.4,3.1c0,0.8-0.6,0.8-1.2,0.6C276.2,433.3,275.8,432,276.7,430.9z"/>
						<path fill="#888158" d="M237.6,429.7c-0.4,0.2-0.8,0.4-1.2,0.6c-0.9,0-1.4-0.4-1.5-1.3c0.1-0.5,0.3-0.9,0.4-1.4
							C236.2,428.3,237.2,428.6,237.6,429.7z"/>
						<path fill="#888158" d="M231.4,429.6c-0.6-0.6-1.7,0.1-2.2-0.8c0.3-0.7,0-2,1.5-1.7C232.4,427.5,231.5,428.7,231.4,429.6z"/>
						<path fill="#3E3821" d="M226.1,419.1c-0.4,0.6-1,1-1.1,1.9c-0.2,1.3-0.9,1.1-1.3,0C223.7,419.3,224.1,418.1,226.1,419.1z"/>
						<path fill="#888158" d="M270.4,434.1c-0.9-0.1-0.8-0.6-0.7-1.1c0-1.1,0.7-1.1,1.6-1.2c1.1-0.1,0.7,0.6,0.7,1.1
							C271.9,434,270.8,433.7,270.4,434.1z"/>
						<path fill="#B7AF8B" d="M218.1,417.8c-0.9,1.9-1.7,0.8-2.6,0c0.4-1.2,1.4-1,2.3-0.9l0,0C217.9,417.2,218,417.5,218.1,417.8z"
							/>
						<path fill="#F5F0BC" d="M269,431.3c-0.8-0.1-1.7-0.2-2.5-0.3c-0.1,0-0.2,0-0.4-0.1c0-0.1-0.1-0.2-0.1-0.4
							c1.3-0.5,2.6-0.8,3.9-0.1C269.8,431.1,269.5,431.4,269,431.3z"/>
						<path fill="#888158" d="M232.6,427.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.6,0.1,0.9,0.1l0.1,0.1l0.2,0c0,0.3,0.1,0.6,0.1,0.8
							c-0.3,0.5-0.2,1.2-0.9,1.7C232.8,429,232.6,428.1,232.6,427.1z"/>
						<path fill="#888158" d="M262.8,431.1c-0.4,0.1-0.9,0.2-0.9,0.6c0,0.6,0.6,0.6,1,0.7c-0.9,1.5-1.5,0.7-2.2-0.2
							c0-0.6,0.3-1.1,0.6-1.6c0.3-0.1,0.6-0.2,0.9-0.3C262.4,430.5,262.6,430.8,262.8,431.1z"/>
						<path fill="#3E3821" d="M229.2,422.2c-0.1-1-0.3-2.1-0.1-3.1c0.3,0.1,0.6,0.1,0.8,0.2C230.1,420.3,230.9,421.6,229.2,422.2z"
							/>
						<path fill="#F5F0BC" d="M261.3,430.5c-0.3,0.5-0.6,1-0.6,1.6c-0.2,0-0.3,0-0.5-0.1c-0.1-0.7-0.2-1.3-0.2-2
							C260.7,429.4,261.2,429.4,261.3,430.5z"/>
						<path fill="#F5F0BC" d="M234.2,428c0-0.3-0.1-0.6-0.1-0.8c0.5,0,1-0.1,1.3,0.4c-0.1,0.5-0.3,0.9-0.4,1.4
							C234.7,428.7,234.5,428.4,234.2,428z"/>
						<path fill="#3E3821" d="M206.8,430.6c-0.2-0.3-0.5-0.6-0.7-0.9c0.8,0,1.7-0.1,2.5,0.5C208.2,431.2,207.4,430.7,206.8,430.6z"
							/>
						<path fill="#32613E" d="M207.2,419.9c0-0.3,0.1-0.5,0.1-0.8c0.4,0.1,0.8,0.1,1.2,0.2c0.3,0.2,0.3,0.5,0.3,0.9
							C208.3,420.1,207.7,420,207.2,419.9z"/>
						<path fill="#F5F0BC" d="M222.1,420.2c-0.5-0.1-1.2,0.4-1.5-0.3c-0.2-0.4,0.2-0.6,0.5-0.7C221.7,419.4,222.2,419.6,222.1,420.2
							z"/>
						<path fill="#F5EBB0" d="M207.6,421.6c0.1,0.2,0.2,0.3,0.2,0.4c0,0.3,0,0.5-0.3,0.7c0-0.1-0.1-0.2-0.1-0.4
							C207.4,422.1,207.5,421.9,207.6,421.6z"/>
						<path fill="#F5F0BC" d="M287.7,424.8c-0.5-0.1-0.8-0.4-0.7-1C287.5,423.9,287.7,424.2,287.7,424.8z"/>
						<path fill="#32613E" d="M205.7,419.6c0.2,0.3,0.4,0.5,0.6,0.8c-0.2,0.2-0.3,0.4-0.5,0.5C205.4,420.5,205.3,420.1,205.7,419.6z
							"/>
						<path fill="#B7AF8B" d="M217.8,416.8c0-0.1,0.1-0.1,0.1-0.2C217.9,416.7,217.8,416.8,217.8,416.8L217.8,416.8z"/>
						<path fill="#F5EBB0" d="M278.1,466.2c0.4-2,3.5-3.2,4.9-1.8c0.5,0.5,0.3,1.1,0.2,1.6c-0.5,5.1-1.1,10.2-1.6,15.3
							c-0.1,1.5-0.6,2.2-2.2,1.8c-4-0.8-8-1.4-12.1-1.6c-5.6-0.3-11.1-1.4-16.8-1.8c-2.1-0.1-4.5-1.7-6.6-1.2
							c-2.9,0.6-5.6,0.1-8.3-0.3c-2.2-0.3-4.5-0.4-6.5-1.7c0.3,0,0.6-0.2,0.8-0.1c2.3,0.6,3.2-1.2,4.5-2.5c0.5-0.5,0.4-0.9-0.3-1.1
							c-2.1-0.9-5.1,1.2-5.1,3.6c-0.1,0.4-0.3,1-0.8,0.7c-1.7-1.1-3.4-1.1-5.2-0.4c-0.5,0.2-1.1,0-1.7-0.1
							c-8.2-0.9-16.4-1.8-24.5-2.6c-2.7-0.3-3.5-2.1-2-4.4c0.4-0.6,0.5-1,0.5-1.7c-0.2-1.8,0.4-2.2,2.1-1.2c1.5,0.9,2.9,0.9,4.5,0.7
							c1.1-0.1,0.9-0.6,0.7-1.3c0.2-0.5,0.2-1,0.1-1.5c0.2-1.3,0.3-2.7,0.5-4c0.1-0.1,0.1-0.2,0.2-0.4c4.2,0.4,8.5,0.9,12.7,1.3
							c0.7,0.1,1.1-0.1,1.2-0.8c0.4,0,0.8,0,1.2,0c0.6,0.1,1.2,0.2,1.8,0.3c2.3,0.3,4.5,0.6,6.8,0.8c0,0.4,0.2,0.7,0.6,0.8
							c2.9,1,2.9,1,4.5,3.7c0.8-0.2,0.5-0.9,0.6-1.4c0.1-0.8,0.1-1.7,0.3-2.5c0.7-2.5,0.8-2.5-2-2.8c-5.5-0.6-11-1.2-16.5-1.8
							c-0.7-0.1-1.5,0-2.6-0.9c2.4,0,4.3-0.3,6,0c7.7,1.3,15.4,2,23.1,2.9c0.7,0.1,1.5,0,1.6,1c-1.7,0-3.3-0.7-5,0.5
							c-2.2,1.5-2.2,3.5-2.5,5.6c-0.2,1.3,0.3,1.8,1.5,1.9c-0.4,2.2,1.7,2.9,2.6,4.3c-0.9,0.9-1.8,1.7-2.7,2.6
							c-0.5,0.5-0.7,0.9,0.2,1.2c1.9,0.6,5-1,5.4-2.9c0.1-0.6-0.5-0.6-0.8-0.8c0.1-1,0.9-1.1,1.7-1.2c1.1-0.2,1.9-0.7,2.1-1.9
							c0.7-0.4,0.4-1.1,0.5-1.7c0.1-0.1,0.2-0.3,0.4-0.4c0.7,0,1.5,0.5,1.9-0.6c0.8-2.3-1.1-5.8-3.6-6.4c0.6-1.4,1.8-0.8,2.5-0.5
							c1.5,0.8,3.1,1,4.8,1.2c1.3,0.2,3,0.4,3.5,2.1c-0.9,0.7-0.7,1.1,0.3,1.4c0.4,1.3,1.7,1.4,3,0.2c0.8,0.1,1.7,0.2,2.5,0.3
							c0.8,0.7,1.6,0.8,2.5,0.2c1,0.1,2,0.2,3,0.3c0.5,0.7,1.1,0.7,1.8,0.1c0.5,0.1,1.1,0.2,1.6,0.3c0.1,0.2,0.2,0.3,0.4,0.1
							c0.4,0.1,0.9,0.1,1.3,0.2c0.3,1.2,1.2,0.2,1.7,0.5c0.4,0,0.7,0.1,1.1,0.1c0.4,0.1,0.7,0.1,1-0.3c0.4,0,0.8,0.1,1.2,0.1
							c0.7,0.9,1.8,0.6,2.7,0.8c0.6,0.1,1.1,0,1.4-0.6c0.3-0.5,0-1-0.4-1.3c-0.5-0.6-1-0.8-1.6-0.1
							C278.6,466.3,278.3,466.2,278.1,466.2z M263.5,473.7c0.8,0.8,1.8,0.6,2.7,0.4c0.3-0.1,0.4-0.5,0.2-0.7
							c-0.6-0.8-1.6-0.3-2.3-0.6c-0.1,0-0.3,0-0.4,0c-0.4-0.2-1-0.8-1.3,0C262.1,473.7,263,473.6,263.5,473.7z M273.9,473.8
							c-1.7-0.2-3.5-0.3-5.2-0.5c-0.3,0-0.7-0.1-0.9,0.3c-0.1,0.4,0.1,0.8,0.5,0.8c1.7,0,3.2,1.6,5,0.6c1,0.2,2.1,0.2,3.1,0.8
							c0.4,0.3,1,0.1,1.2-0.5c0.2-0.5,0.1-1-0.4-1C276,474.2,275.1,473.2,273.9,473.8z M213.6,467.4c1.6,0.4,3.3-0.3,5,0.4
							c0.7,0.3,1.3-0.4,1.4-1.1c0.1-0.7-0.6-1.1-1.1-1c-1.8,0.4-3.4-0.5-5.1-0.3c-2-0.1-4.1-0.2-6.1-0.3c-0.6,0-1.2,0.3-1.1,1
							c0.1,0.6,0.7,0.6,1.2,0.6C209.8,466.4,211.7,467,213.6,467.4z M268.3,471.8c1.2,0.4,2.3,0,3.5,0.1c0.4,0,0.7,0.1,0.9-0.3
							c0.2-0.5-0.2-0.8-0.6-0.9c-0.5-0.2-0.9-0.4-1.4-0.4c-5,0-9.8-0.9-14.8-1.2c-0.6,0-1.5-0.4-1.6,0.4c-0.2,1.1,1,0.9,1.5,0.9
							C260,470.8,264.1,471.7,268.3,471.8z M243.4,477.6c2.5,0.8,4,0.3,5.6-1.8c0.6-0.8,0.4-1-0.4-1.3
							C246.4,473.9,245.4,474.5,243.4,477.6z M268.3,476.6c-0.6,1.1-0.3,2-1.2,2.1c-0.5,0-1-0.1-0.9-0.6c0.3-2-1-0.9-1.7-0.9
							c-0.9,0-2-0.1-1.8,1c0.1,0.7,1.9,1.7,2,1.6c1.5-1.5,3.3,0.3,4.9-0.6C269.2,478.4,268.1,478.1,268.3,476.6z M258.6,472.3
							C258.6,472.3,258.6,472.3,258.6,472.3c-0.8-0.2-1.7-0.4-2.5-0.3c-0.4,0-1.1,0-1.1,0.7c0,0.5,0.6,1,1,0.8
							c1.2-0.4,2.2,0.3,3.3,0.4c0.7,0,1.9,0.3,2-0.6c0.2-1.2-1.1-0.6-1.7-0.9C259.3,472.2,258.9,472.3,258.6,472.3z M224.5,475.5
							c1.5-0.3,1.8-1.2,2.4-1.8c0.3-0.3,0.7-0.6,0.5-1.1c-0.2-0.6-0.8-0.5-1.3-0.4C223.9,472.4,225.3,474.3,224.5,475.5z
							 M270.9,479.3c0.6,0.3,1,0.5,1.5,0.1C272,479.2,271.6,479.2,270.9,479.3z M271.1,477.7c-0.1,0.1-0.2,0.2-0.2,0.3
							c0,0.1,0.1,0.2,0.1,0.4c0.1-0.1,0.2-0.2,0.2-0.3C271.2,478,271.1,477.9,271.1,477.7z"/>
						<path fill="#3E3821" d="M202.7,464.7c0.2,0.5,0.1,1-0.1,1.5c-1.6-0.1-3.1-0.3-4.7-0.4c-1.3-0.1-1.4-0.6-0.9-1.6
							c0.4-0.8,0.9-1.4,1.7-1.8c1-0.5,1.8-1.2,2.4-2c0.4-0.5,0.6-1.2,0.1-1.7c-0.5-0.5-1.2-0.5-1.7,0.1c-0.6,0.7-1.4,1.1-1.9,0.2
							c-0.5-0.8,0.4-1.4,1.1-1.8c2.3-1.3,4.5,0.2,4.6,3.1c-0.1,0.1-0.1,0.2-0.2,0.4c-0.4,0.5-0.9,0.9-1.2,1.4
							c-0.6,0.7-2.1,1-1.9,1.9C200.3,464.9,201.8,464.3,202.7,464.7z"/>
						<path fill="#CCDEC7" d="M244.3,461.3c2.5,0.6,4.4,4.1,3.6,6.4c-0.4,1.1-1.2,0.7-1.9,0.6c0.8-2.3,0-4.2-1.8-5.8
							c-1.8-1.5-3.7-1.5-5.5,0c-2.5,1.7-2.5,4-1.6,6.6c-0.2,0-0.3,0-0.5,0c-1.2-0.1-1.7-0.6-1.5-1.9c0.3-2.1,0.3-4.2,2.5-5.6
							c1.8-1.2,3.4-0.4,5-0.5C243.2,461.2,243.7,461.2,244.3,461.3z"/>
						<path fill="#888158" d="M218.5,460.9c-0.4,0-0.8,0-1.2,0c-2.9-0.3-5.8-0.7-8.6-1c-0.5-0.1-1.3,0.3-1.3-0.6
							c-0.1-0.6,0.1-1.3,0.6-1.7c0.5-0.4,1.1-0.5,1.5-0.1c1.9,1.7,4.3,1.3,6.5,1.4c1.3,0,2.4,0.8,3.7,0.4
							C219.8,460.1,218.7,460.2,218.5,460.9z"/>
						<path fill="#888158" d="M227.1,462.1c-2.3-0.3-4.5-0.6-6.8-0.8c0.1-0.6,0.2-1.3,0.3-1.9c2,0.2,4,0.6,6,0.7
							c0.8,0.1,1.9-0.2,2.1,0.7C228.8,461.4,227.7,461.6,227.1,462.1z"/>
						<path fill="#D02826" d="M229,476.5c0.1-2.4,3-4.5,5.1-3.6c0.6,0.3,0.7,0.7,0.3,1.1c-1.2,1.3-2.2,3.1-4.5,2.5
							c-0.2-0.1-0.5,0.1-0.8,0.1C229.1,476.6,229,476.5,229,476.5z"/>
						<path fill="#B7AF8B" d="M269.8,467.1c-0.5-0.1-1.1-0.2-1.6-0.3c0.2-1.9,1.8-1.3,2.6-1.2c2.4,0.3,4.8,0.7,7.3,0.6
							c0.3,0.1,0.5,0.1,0.8,0.2c0.1,1.9-1.7,0.6-2.2,1.3c-0.4,0-0.8-0.1-1.2-0.1c-0.6-0.7-0.8-0.1-1,0.3c-0.4,0-0.7-0.1-1.1-0.1
							c-0.4-0.6-1.1-0.4-1.7-0.5c-0.4-0.1-0.9-0.1-1.3-0.2C270.1,467,270,467,269.8,467.1z"/>
						<path fill="#B7AF8B" d="M262.2,461.9c0.9,0.4,2.2-0.3,2.6,1.2c-1.5,1.5-3.1,0.4-4.7,0.3c-0.6-0.1-1.3-0.5-2-0.8
							C259.3,461.1,260.9,462.3,262.2,461.9z"/>
						<path fill="#888158" d="M255.4,465.5c-0.9-0.3-1.1-0.8-0.3-1.4c2.4,0.2,4.8,0.5,7.2,0.7c0,0.3-0.1,0.6-0.1,0.9
							c-0.4,0.1-0.9,0.2-1.3,0.3c-0.8-0.1-1.7-0.2-2.5-0.3C257.4,465.7,256.4,465.6,255.4,465.5z"/>
						<path fill="#B7AF8B" d="M262.3,465.8c0-0.3,0.1-0.6,0.1-0.9c1.3-0.5,2.6,0,3.8,0.1c1.4,0.2,0.5,1.1,0.2,1.7
							c-1-0.1-2-0.2-3-0.3C263.1,466.1,262.7,466,262.3,465.8z"/>
						<path fill="#B7AF8B" d="M220.6,459.3c-0.1,0.6-0.2,1.3-0.3,1.9c-0.6-0.1-1.2-0.2-1.8-0.3c0.2-0.7,1.2-0.8,1.2-1.7
							C220,459.3,220.3,459.3,220.6,459.3z"/>
						<path fill="#888158" d="M274.1,463.6c0.4,0,0.9,0.1,1.3,0.1c0,0.3-0.1,0.6-0.1,0.9c-0.4,0-0.9-0.1-1.3-0.1
							C274,464.2,274,463.9,274.1,463.6z"/>
						<path fill="#CEC69D" d="M274.1,463.6c0,0.3-0.1,0.6-0.1,0.9c-0.9-0.1-0.8-0.8-0.8-1.4C273.5,463.3,273.8,463.4,274.1,463.6z"
							/>
						<path fill="#CEC69D" d="M275.2,464.6c0-0.3,0.1-0.6,0.1-0.9c0.3,0,0.6,0.1,0.9,0.1C276.1,464.4,275.8,464.6,275.2,464.6z"/>
						<path fill="#FBEFB2" d="M232.4,480.6c0.1,0,0.3,0,0.4,0c0.6,1.8,0.8,3.5,0,5.3c-0.5,1.1-0.1,2.2,0.2,3.3
							c0.3,1,0.4,2.1,0.3,3.1l0,0c-0.2,0-0.4,0-0.6-0.1c-0.1,0-0.2,0-0.3-0.1c-0.2-0.6-0.8-1-1.2-1.5c-1.5-1.7-2.4-1.7-2.9,0.5
							c-0.4,2.1-1.7,3.9-1.7,6.2c0,1.5-0.1,3,0.5,4.5c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.6-1.5-0.5-2.2-0.6c-7.3-0.8-14.6-1.6-22-2.4
							c-2.8-0.3-5.6-1.2-8.5-0.9c-0.8,0.1-1.2-0.4-1.1-1.2c0.1-0.9,0.2-1.8,0.3-2.7c0.2-2.4,0.8-4.8-0.1-7.2c0.3,0,0.6,0.1,0.8,0.1
							c0.3,0.2,0.6,0.4,0.7,0.7c0.8,1.7,1.3,3.8,3.8,1.7c2,1.8,4-0.1,6.1,0c0.9-0.1,1.9,0.3,2.3-1l0.1,0c0.2,2,2.2,1,3,1.8
							c-0.3,1.2,0.5,1.8,1.4,2c1.9,0.4,3.9,0.6,5.8,0.7c0.8,0,2.1-0.2,1.9-1.5c-0.2-1.4-0.3-3-1.9-3.7c-0.1-0.1-0.4,0.3-0.7,0.4
							c-0.1-0.1-0.2-0.3-0.4-0.4c0.5-0.6-1-1.8-0.1-1.6c1.8,0.4,2.8-0.6,3.9-1.5l0.2,0l0.2,0c0.4,0.6,1.6,0.2,1.7,1.5
							c0,0.8,1,0.5,1.4,0.2c1.1-0.9,1.9-0.5,2.9,0.4c1,0.9,2.2,0.9,3.2-0.4c0.9-1.1,2.3-1.8,0.6-3.5c-0.6-0.6-0.7-1.8,0.9-1.7
							C231.3,480.8,231.8,480.6,232.4,480.6z"/>
						<path fill="#F5EBB0" d="M275.8,496c1.3,0.1,3.1,0.8,2.1-1.8c-0.2-0.4,0.9-0.7,0.8-1.4c0.1-0.1,0.3-0.2,0.4-0.3
							c1.2,0.1,1.3,0.8,1.2,1.8c-0.4,3.4-0.7,6.7-1.1,10.1c-0.1,0.7,0,2.2-0.6,1.9c-1.2-0.6-3.2,1-3.7-1.4c0.2-0.4,0.4-0.8,0.6-1.2
							c0.3,0,0.6,0.1,0.8,0.1c0,0.5,0.1,1,0.6,1.2c0.1,0,0.4-0.1,0.4-0.2c0.1-0.5-0.3-0.7-0.6-0.9c0.1-0.9,0-1.6-0.7-2.7
							c-0.5,2-1.4,2.2-3.1,1.7c-2.1-0.6-4.3-0.8-6.4-0.7c-2,0.1-2-0.8-1.8-2.1l0,0c1.8-0.1,3.7,1.1,5.5-0.1c0.4-0.2,1.1-0.1,0.7-0.9
							c-0.2-0.5-0.7-0.5-1.1-0.2c-0.8,0.6-1.3,0.2-1.6-0.6c-0.9-3.8-3.4-0.5-5-1.1c0.4,0.3,0.8,0.5,1.2,0.8c-1,0.7-2.2-0.3-3.3,0.3
							c0,0.7,1.1,0.7,0.9,1.5c0.1,0.8-0.7,1.8,0.6,2.2c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.8-0.1,1.3c-1.1,0-2.5,1.6-3.4-0.4
							c0.1-1.1-0.6-1.4-1.5-1.4c-0.2-0.5-0.5-1-1-0.8c-1.6,0.5-3-0.6-4.5-0.3c-0.4,0.1-0.8-0.2-0.9-0.7c-0.1-0.5,0.2-1,0.5-1.1
							c2.8-0.4,1.2-1.7,0.6-2.9l0,0c1.2,0.2,1.8-0.3,1.9-1.5c2.3-0.6,4.6,0.2,6.9-0.1c0.2,0.4,0.5,0.4,0.9,0.4
							c1.8,0.4,3.6,0.4,5.5,0.4c2.2,0.3,4.4,0.7,6.7,1C274.6,496.3,275.2,496.2,275.8,496z M260.3,502.9c0.4,0,0.7,0,0.8-0.3
							c0.1-0.4-0.2-0.5-0.5-0.4c-0.3,0-0.6,0.1-0.6,0.4C259.9,502.7,260.2,502.8,260.3,502.9z"/>
						<path fill="#F5EBB0" d="M248.9,482.3c0.3,0,0.6,0.1,0.8,0.1c0.6,1.3,2.1,1.1,2.9,1.1c1.9,0,3.8,0.1,5.7,0.3
							c0.4,0,0.6,0.3,0.5,0.8c-0.1,0.3-0.4,0.3-0.6,0.4c-0.5,0.1-1.4-0.7-1.4,0.2c0,1.7-1,2.5-2.2,3.3c-0.2,0.1-0.6-0.2-0.5,0.3
							c0.2-0.1,0.3-0.2,0.5-0.3c0.4,0.7,1.2,0.9,1.8,0.5c1.2-0.8,1.7-0.2,2.1,1.3c0.5-2.7,1.7-1.2,2.6-1c-0.3,0.6-0.4,1.2-0.3,1.8
							c-0.2,0.4-0.6,1-0.9,0.8c-2.2-1.4-4.9-0.9-7.2-1.5c-0.7-0.2-0.9-0.7-0.7-1.3c0.2-0.7,1.4-1.4,0-2.1c1.5-2.9-1.5-1.9-2.5-2.8
							c2.5,2.2,0.9,4.6,0.6,6.9c-0.1,1,0.9,1.6,1.5,2.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.5-0.2,1-0.2,1.5l0,0c-1.1,0.4-1,1.3-0.9,2.3
							c-0.4-1.6-1.5-2.7-1.5-4.3c0-1.1-1.2-0.6-1.7-0.5c-1.4,0.2-2.7,0.3-3.6-1c0.2-1.3,0.5-2.5,0.3-3.8c0-0.5,0.1-1,0.1-1.5
							c0.8-1.2,0.6-2.6,0.5-3.9c0.7,0.2,1.5,0.5,2.2,0.6C247.6,483,248.5,483.6,248.9,482.3z"/>
						<path fill="#32613E" d="M242.6,493.5c0.2,0,0.4,0,0.7,0.1c0.7,0.3,1.5,0.4,2.3,0.3c0.7,0.1,1.3,0.2,2,0.3
							c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,1.7-0.3,3.3-0.4,5c-0.2,0.4-0.3,0.8-0.2,1.3c-0.1,0.4-0.1,0.9-0.2,1.3c0,0.1-0.1,0.3-0.1,0.4
							c-0.4,0.2-0.9,0.7-1.3,0.6c-3.4-1.2-7.1-0.8-10.6-1.5c0.5-1.3,0.9-2.7,0.6-4.2c-0.1-0.2-0.1-0.3-0.2-0.5
							c0.5-1.2,1.1-2.4,0.4-3.7c-0.2-0.5,0.4-0.3,0.7-0.5c0.4,0,0.7,0.1,1.1,0.1c1,0.3,2.1,0.5,3.1,0.3c0.4,0,0.7,0.1,1.1,0.1
							C242.2,493.5,242.4,493.5,242.6,493.5z M242.4,498.9c0.5,1-1.3,1.1-0.3,1.9c0.6,0.5,1.3,0,1.9-0.4c2.2-1.5,2.3-1.9,0.6-3.9
							c-0.5-0.6-1-1.4-1.9-1c-1,0.5,0.6,1.2-0.1,1.9c-1-0.1-2-0.2-3.1-0.3c-0.5-0.1-1.1,0-1.1,0.7c0,0.6,0.5,0.8,1,0.8
							C240.4,498.7,241.4,498.8,242.4,498.9z"/>
						<path fill="#F5EBB0" d="M264.6,484c0.3,0.1,0.6,0.1,0.9,0.2c1.1,0.4,2.1,1.2,3.4,0.4c1,0.1,2,0.1,3,0.2
							c1.6,0.9,3.3,1.1,5.1,0.6c0.6,0.1,1.1,0.1,1.7,0.2c0.3,0.6,1,0.5,1.5,0.6c0.6,0.1,1.1,0.5,0.6,0.9c-0.8,0.7,0.9,2-0.8,2.3
							c-1.6,0.3-1.6,0.3-2.2-1.6c0.2,1.9-3.9,1.1-2.3,3.9c0,0.1-0.1,0.2-0.1,0.4c-0.6,0.5-1.4,0.8-1.9,0.4c-0.6-0.5,0.3-0.9,0.6-1.3
							c0.3-0.7,0.2-1.3-0.7-1.4c-0.7-0.1-1.4-0.2-2.4-0.3c0.7,1,1.6,1.9,0.2,2.7c-0.8,0.4-1.2,0.3-1.3-0.7c0-0.1,0.1-0.1,0.1-0.2
							c0,0.1-0.1,0.1-0.1,0.2c-2,0.9-4,0.8-6,0.1c-0.5-0.2-0.8-0.6-0.6-1.2c0.1-0.4,0.6-0.6,0.8-0.5c1.1,0.7,2-0.5,3-0.1
							c1,0.4,1.9-0.4,3,0.1c-0.6-1.8-1.7-2.6-3.5-3.2c0.6,1.2,1.7,2.1,0.4,3.1c-1.4-0.9-1-3-2.6-3.9
							C263.7,485.6,264.7,484.7,264.6,484z"/>
						<path fill="#32613E" d="M244.1,486.2c0,0.5-0.1,1-0.1,1.5c-0.4,0.6-0.4,1.3-0.4,2c0,1.1-0.6,1.4-1.6,1.5
							c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.3,0-0.5,0c-0.8-0.1-1.7-0.2-2.5-0.3c-0.4-0.3-0.9-0.3-1.4-0.1c-0.3,0-0.5-0.1-0.8-0.1
							c-0.4-0.3-0.8-0.3-1.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.6,0-1.1,0-1.7c0-0.3,0.1-0.6,0.1-0.8c0-0.1,0-0.3,0-0.4
							c0.1-0.9,0.2-1.7,0.3-2.6c0.3-0.8,0.4-1.7,0.2-2.5c0-0.2,0.1-0.3,0.1-0.5c2.9-0.4,5.7,0.7,8.6,0.5c0.1,0,0.2,0.1,0.4,0.1
							C244.3,483.6,243.7,484.9,244.1,486.2z M239.6,489.3c0.1-1.6,2-1.4,2.7-2.6c0.3-0.5,0-1.1-0.8-1.2c-0.7-0.1-1-0.3-1-1.1
							c0-0.7-0.2-1.1-0.9-1.1c-0.7,0-1.1,0.3-1.1,1c-0.1,1.3-2.1,0.4-2.1,1.9c0,1.3,2.1,0.7,1.8,2.2
							C238.1,489,238.6,489.2,239.6,489.3z"/>
						<path fill="#888158" d="M236.7,492.9c-0.2,0.2-0.9,0.1-0.7,0.5c0.7,1.4,0.1,2.5-0.4,3.7c-0.4-0.2-0.7-0.4-1.1-0.5
							c0.4-0.6,0.3-1.1-0.4-1.3c-0.6-0.2-1.2-0.2-1.4,0.7c-0.5,0.1-1,0.2-1.6,0.3c0-0.4,0-0.9,0.1-1.3c0.8-0.5,2.5,0.4,2.5-1
							c0-0.8-1.5-0.4-2.3-0.5c-0.1-0.4-0.3-0.7-0.4-1.1c0.4-0.4,0.9-0.2,1.3-0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0.3,0.4,0.5,0.6,0.1
							l0,0C234.5,492.6,235.7,492.3,236.7,492.9z"/>
						<path fill="#14653A" d="M231.1,492.4c0.1,0.4,0.3,0.7,0.4,1.1c-0.1,0.5-0.1,1-0.2,1.6c0,0.4,0,0.9-0.1,1.3
							c-0.1,0.5-0.2,1.1-0.2,1.6c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.4-0.2,0.8-0.4,1.2c-1.2,0.6-1.7,0.3-1.5-1.1
							c0.2-1.8,0.7-3.6,0.3-5.4l-0.1,0c0.1-0.3,0.2-0.6,0.2-0.9c0.3-0.2,0.4-0.4,0.5-0.8C230.2,492.3,230.7,492.4,231.1,492.4z"/>
						<path fill="#F5EBB0" d="M268.1,503.7c0.1,0,0.2,0,0.3,0c0.1,0.5-0.2,1.1,0.5,1.2c0.8,0.2,1-0.6,1.4-1c0.1,0,0.2,0,0.3,0
							c0.2,1.3,0.7,0.9,1.2,0.2c0.7-0.6,1.4-0.9,2.3-0.6c0,0.5-0.2,1,0.3,1.3c-1.1,2.1-3.1,0.9-4.7,1.1c-0.8,0.1-2.3,0.6-2.6-1
							C267.6,504.7,268,504.3,268.1,503.7z"/>
						<path fill="#888158" d="M230.9,499.3c0.1-0.4,0.1-0.9,0.2-1.3c1.1,0.5,1.2-0.6,1.6-1.1c0,1,0.6,1,1.3,0.7
							c0.4,0.9,0.8,1.8,1.3,3c0.2-1.2,0.4-2.1,0.6-2.9c0.3,1.5-0.1,2.8-0.6,4.2c-0.7,0-1.4-0.1-2.1-0.1c0-0.2,0-0.4-0.1-0.6
							c0.6-0.5,1-1.1,0.5-1.8c-0.7-0.8-1.5-0.2-2.3,0C231.1,499.3,231,499.3,230.9,499.3z"/>
						<path fill="#E9E4B6" d="M254.8,501.7c1.1,0.3,2.1-0.6,2.2,0.9c0,0.4-0.1,1.1-0.4,1c-1-0.3-2.1-0.1-3.1-0.3
							c-0.5-0.1-0.4-0.7-0.3-1.1C253.7,500.7,254.5,501.6,254.8,501.7z"/>
						<path fill="#CEC69D" d="M264.4,497.8c-0.4-0.3-0.8-0.5-1.2-0.8c1.6,0.5,4-2.7,5,1.1c-0.4,0-0.9,0-1.3-0.1
							c0-0.2-0.1-0.4-0.1-0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.4,0-0.9-0.1-1.3-0.1c-0.1-0.3-0.2-0.3-0.3,0
							C264.7,497.8,264.5,497.8,264.4,497.8z"/>
						<path fill="#888158" d="M229.2,494c0.4,1.8-0.1,3.6-0.3,5.4c-0.1,1.4,0.3,1.7,1.5,1.1c0,0.3,0.1,0.6,0.1,0.9
							c-0.9,0.1-1.8,0.3-2.5-0.7c0.8-1.9,0.7-3.9,0.8-6C229,494.5,229.1,494.2,229.2,494z"/>
						<path fill="#F5EBB0" d="M265.8,504.6c0,0.2-0.3,0.6-1.1,0.5c-0.5-0.1-1.1-0.2-0.9-0.9c0.2-0.6,0.2-1.5,1.1-1.6
							C265.7,502.6,265.9,503.3,265.8,504.6z"/>
						<path fill="#B7AF8B" d="M275.3,492c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.1,0,0.3-0.1,0.4-0.1
							c0.8,0.6,2.9-1.4,2.8,1.3c-0.1,0.1-0.3,0.2-0.4,0.3C277.3,493.3,276.6,491.5,275.3,492z"/>
						<path fill="#E9E4B6" d="M252.7,502.4c-0.1,0.7-0.5,1-1.2,0.8c-0.5-0.1-0.9-0.5-0.8-1c0.2-0.5,0.5-1,1.2-1
							C252.7,501.2,252.8,501.7,252.7,502.4z"/>
						<path fill="#B7AF8B" d="M243.8,482.1c-2.9,0.2-5.7-0.9-8.6-0.5c0.1-0.4,0.4-0.7,0.7-0.6C238.6,481.4,241.3,481.2,243.8,482.1z
							"/>
						<path fill="#CEC69D" d="M270.2,503.9c-0.4,0.4-0.6,1.2-1.4,1c-0.6-0.2-0.4-0.8-0.5-1.2C269.2,502.4,269.7,503.1,270.2,503.9z"
							/>
						<path fill="#CEC69D" d="M268.1,503.7c-0.1,0.6-0.5,1-1,1.2c-1.1-0.5-0.8-1.4-0.3-1.9C267.3,502.4,267.8,503.2,268.1,503.7z"/>
						<path fill="#CEC69D" d="M257.6,501.8c1,0,1.6,0.3,1.5,1.4c-0.3,0.3-0.6,0.6-1.1,1C257.7,503.3,257.4,502.6,257.6,501.8z"/>
						<path fill="#CEC69D" d="M262.7,501.8c-1.3-0.4-0.5-1.4-0.6-2.2C263.8,499.9,263.3,500.8,262.7,501.8z"/>
						<path fill="#B7AF8B" d="M230.6,501.4c0-0.3-0.1-0.6-0.1-0.9c0.1-0.4,0.2-0.8,0.4-1.2c0.1,0,0.2,0.1,0.3,0.1
							c-0.5,1.7,0.7,1.7,1.8,1.8c0,0.2,0,0.4,0.1,0.6C232.2,502.2,231.4,501.9,230.6,501.4z"/>
						<path fill="#CEC69D" d="M228.9,494.8c-0.1,2,0,4-0.8,6C228.3,498.8,228.2,496.7,228.9,494.8z"/>
						<path fill="#B7AF8B" d="M244.1,486.2c-0.4-1.3,0.2-2.6,0.1-3.9c0.2,0,0.3,0,0.5,0C244.6,483.6,244.9,485,244.1,486.2z"/>
						<path fill="#B7AF8B" d="M241.9,491.2c0.9-0.1,1.6-0.4,1.6-1.5c0-0.7,0-1.4,0.4-2c0.1,1.3-0.1,2.6-0.3,3.8
							C243,491.6,242.4,491.7,241.9,491.2z"/>
						<path fill="#E9E4B6" d="M274.4,504.8c-0.5-0.4-0.3-0.9-0.3-1.3c0.4,0,0.8,0.1,1.3,0.1c-0.2,0.4-0.4,0.8-0.6,1.2
							C274.6,504.8,274.5,504.8,274.4,504.8z"/>
						<path fill="#E9E4B6" d="M276.6,503.7c0.3,0.2,0.7,0.4,0.6,0.9c0,0.1-0.3,0.3-0.4,0.2c-0.5-0.2-0.6-0.7-0.6-1.2
							C276.3,503.7,276.5,503.7,276.6,503.7z"/>
						<path fill="#888158" d="M240.9,493.4c-1.1,0.2-2.1,0-3.1-0.3C238.9,492.8,239.9,492.9,240.9,493.4z"/>
						<path fill="#B7AF8B" d="M235.1,482.1c0.2,0.9,0.1,1.7-0.2,2.5C234.5,483.8,234.6,482.9,235.1,482.1z"/>
						<path fill="#CEC69D" d="M271.8,504.1c-0.5,0.7-1,1.1-1.2-0.2C271,504,271.4,504.1,271.8,504.1z"/>
						<path fill="#888158" d="M245.6,493.9c-0.8,0.1-1.6,0-2.3-0.3C244.1,493.3,244.9,493.4,245.6,493.9z"/>
						<path fill="#B7AF8B" d="M234.4,488.5c0,0.6,0,1.1,0,1.7C233.7,489.6,234,489.1,234.4,488.5z"/>
						<path fill="#F5EBB0" d="M264.6,499.9c-0.3-0.2-0.7-0.5-0.6-1c0,0,0.1-0.1,0.2-0.1C264.6,499.1,264.6,499.5,264.6,499.9
							L264.6,499.9z"/>
						<path fill="#B7AF8B" d="M236.8,490.7c0.5-0.2,0.9-0.2,1.4,0.1C237.7,491,237.2,491.4,236.8,490.7z"/>
						<path fill="#CEC69D" d="M229.3,493.1c-0.1,0.3-0.2,0.6-0.2,0.9c-0.4-0.6-0.4-1.2,0.2-1.7C229.4,492.5,229.3,492.8,229.3,493.1
							z"/>
						<path fill="#F5EBB0" d="M252.1,496c-0.5-0.1-0.8-0.4-0.8-0.9l0,0C251.9,495.1,252.2,495.4,252.1,496L252.1,496z"/>
						<path fill="#CEC69D" d="M247.3,501c-0.1-0.4-0.1-0.9,0.2-1.3C247.9,500.3,247.9,500.7,247.3,501z"/>
						<path fill="#B7AF8B" d="M234.7,490.4c0.5-0.1,0.9,0,1.3,0.2C235.5,490.8,235.1,490.9,234.7,490.4z"/>
						<path fill="#CEC69D" d="M262.5,503.5c0-0.4,0.1-0.8,0.1-1.3C263.2,502.7,263.1,503.2,262.5,503.5z"/>
						<path fill="#888158" d="M229.3,493.1c0-0.3,0-0.6,0-0.8c0.1,0,0.3,0,0.4,0.1C229.8,492.7,229.7,492.9,229.3,493.1z"/>
						<path fill="#F5EBB0" d="M266.5,498c0.1-0.2,0.2-0.4,0.3-0.5c0,0.2,0.1,0.4,0.1,0.6C266.7,498,266.6,498,266.5,498z"/>
						<path fill="#F5EBB0" d="M276.3,491.1c-0.1,0-0.3,0.1-0.4,0.1c0.1-0.2,0.1-0.4,0.2-0.5C276.1,490.9,276.2,491,276.3,491.1z"/>
						<path fill="#B7AF8B" d="M234.6,487.2c0,0.1,0,0.3,0,0.4c0-0.1-0.1-0.2-0.1-0.3C234.4,487.3,234.5,487.3,234.6,487.2z"/>
						<path fill="#B7AF8B" d="M240.6,491.1c0.2,0,0.3,0,0.5,0C240.9,491.3,240.8,491.3,240.6,491.1z"/>
						<path fill="#888158" d="M242.6,493.5c-0.2,0-0.4-0.1-0.6-0.1C242.3,493.3,242.5,493.3,242.6,493.5z"/>
						<path fill="#CEC69D" d="M247.9,494.8c-0.1-0.2-0.2-0.4-0.3-0.6C247.9,494.3,248,494.5,247.9,494.8z"/>
						<path fill="#F5EBB0" d="M264.9,497.8c0.1-0.2,0.2-0.2,0.3,0l-0.2,0L264.9,497.8z"/>
						<path fill="#CEC69D" d="M247.1,502.7c0-0.1,0.1-0.3,0.1-0.4C247.4,502.5,247.4,502.6,247.1,502.7z"/>
						<path fill="#3E3821" d="M194.8,485.1c0.8,0.3,1.6,0.1,2.2,1c0.6,0.9,1.4,0.4,1.9-0.2c0.5-0.7,0.2-1.4-0.6-1.7
							c-2-0.7-1.5-1.3,0-1.9c0.7-0.3,1-0.8,0.5-1.5c-0.4-0.6-0.9-0.5-1.4-0.1c-0.6,0.5-1.3,1-1.7,0c-0.4-0.9,0.5-1.2,1.1-1.5
							c1.2-0.6,2.4-0.5,3.4,0.4c1,0.9,1.2,2,0.2,3c-0.7,0.7-0.1,1.1,0.2,1.6c0.6,1.1,0.4,2.2-0.5,3.1c-1,1-2.5,1.4-3.7,0.8
							C195.4,487.6,194.1,486.8,194.8,485.1C194.8,485.2,194.8,485.1,194.8,485.1z"/>
						<path fill="#888158" d="M225,484.3c-1.7-0.1-3.3-0.2-5-0.4c-0.1,0-0.3,0-0.4,0c-1.3,0.1-2.7,0.2-3.7-1
							c0.1-0.5,0.1-0.9,0.2-1.4c2.2,0.2,4.5,0.4,6.7,0.6c0,0.6,0.3,0.9,0.9,0.9c0.5,0,0.7-0.3,0.8-0.7c0.3,0.1,0.5,0.1,0.8,0.2
							C225.2,483.1,225.1,483.7,225,484.3z"/>
						<path fill="#888158" d="M204.8,482.7c-0.3-1,1-1.7,0.4-2.9c-0.1-0.3,0.9-0.4,1.2-0.1c1.8,1.8,4.1,1,6.2,1.5
							c-0.1,0.6-0.1,1.2-0.2,1.7C209.8,483.2,207.3,481.9,204.8,482.7L204.8,482.7z"/>
						<path fill="#F5EBB0" d="M193.6,486.7c-0.3,0-0.6-0.1-0.8-0.1c0.3-1.3-0.1-2.7,1.1-3.7c0.2-0.2,0.3-0.8,0.2-1
							c-1.3-2-0.2-3.7,0.6-5.4c0.4,0,0.9,0.1,1.3,0.1c-0.1,0.6-0.4,1.1-0.8,1.4c-1.2,1-1.2,2.4-0.4,3.2c1.3,1.4,1,2.5,0.1,3.8
							c0,0,0,0,0,0C193.5,485,193.7,486,193.6,486.7z"/>
						<path fill="#F5EBB0" d="M203.4,486.5c-0.1,0-0.3-0.1-0.4-0.1c-0.9-0.8-1.5-2-1.1-2.9c0.3-0.6,1.8-0.5,2.8-0.7l0,0
							c0,0.1-0.1,0.4,0,0.4c2,0.9,2.1,2,0.3,3.3c-0.1,0,0,0.4,0.1,0.6c-0.3,0.1-0.5,0.2-0.8,0.3c-0.1-0.4,0.1-1-0.6-1.2
							C203.7,486,203.5,486.3,203.4,486.5L203.4,486.5z"/>
						<path fill="#B7AF8B" d="M212.3,482.9c0.1-0.6,0.1-1.2,0.2-1.7c1.2-0.6,2.3,0.3,3.5,0.3c-0.1,0.5-0.1,0.9-0.2,1.4
							c-0.6,0.1-1.3-0.3-1.7,0.4C213.2,484.9,212.9,483.5,212.3,482.9z"/>
						<path fill="#B7AF8B" d="M225,484.3c0.1-0.6,0.2-1.2,0.2-1.9c1-0.5,1.9-0.3,2.6,0.5c-0.6,0.2-0.5,0.8-0.6,1.3
							C226.5,484.2,225.8,484.8,225,484.3z"/>
						<path fill="#888158" d="M204.3,487.2c0.3-0.1,0.5-0.2,0.8-0.3c0.7,0.1,1,0.7,1.4,1.2c-0.3,1.3-1.4,0.9-2.3,1
							C204.2,488.4,204.3,487.8,204.3,487.2z"/>
						<path fill="#888158" d="M227.3,484.3c0.1-0.5,0-1.1,0.6-1.3c0.3,0.6,1.7,0.4,1.2,1.4C228.7,485.1,227.8,484.8,227.3,484.3z"/>
						<path fill="#B7AF8B" d="M224.5,482.3c-0.1,0.4-0.3,0.8-0.8,0.7c-0.6,0-0.9-0.3-0.9-0.9C223.3,482.2,223.9,482.2,224.5,482.3z"
							/>
						<path fill="#F5EBB0" d="M202.3,481.5c0.1-0.3,0.2-0.5,0.4-0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.2,0.4-0.4,0.2
							C202.4,481.8,202.4,481.6,202.3,481.5z"/>
						<path fill="#F5EBB0" d="M203.4,486.5c0.1,0.2,0.2,0.3,0.3,0.5c-0.1,0-0.1,0.1-0.2,0.1C203.5,486.8,203.5,486.6,203.4,486.5
							L203.4,486.5z"/>
						<path fill="#B7AF8B" d="M219.6,483.9c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c0,0-0.2,0-0.2,0l-0.2,0
							C219.5,484.2,219.5,484.1,219.6,483.9z"/>
						<path fill="#888158" d="M251.6,493.5c0,0,0.1-0.1,0.1-0.1c0.3-1.1,1.2-0.7,1.9-0.7c4.4,0.4,8.8,0.9,13.2,1.3
							c0.1,0.4,0.2,0.7,0.4,1.1c-1.8-0.1-3.7,0-5.5-0.4c-0.2-0.4-0.3-1-0.9-0.4c-2.3,0.3-4.6-0.5-6.9,0.1
							C253.2,494.2,252.4,493.8,251.6,493.5z"/>
						<path fill="#B7AF8B" d="M267.3,495.1c-0.1-0.4-0.2-0.7-0.4-1.1c1.8-0.2,3.6-0.3,5.2,0.8c0.8,0.6,1.5,0.1,2.2-0.1
							c1.1-0.2,1.7-0.1,1.5,1.2c-0.6,0.3-1.2,0.3-1.8,0.2C271.7,495.8,269.5,495.5,267.3,495.1z"/>
						<path fill="#F5EBB0" d="M259.2,486c0.2,0.7,0.6,1.4-0.1,1.9c-0.4,0.3-1,0.1-1.4,0c-0.4-0.2-0.7-0.5-0.6-1
							c0-0.5,0.5-0.6,0.9-0.7C258.4,486.2,258.8,486.1,259.2,486L259.2,486z"/>
						<path fill="#888158" d="M264.6,487.9c0,0.6-0.5,0.7-1,0.7c-0.6-0.1-1.1-0.3-1-1c0.1-0.7,0.7-0.5,1.2-0.5
							C264.4,486.9,264.7,487.3,264.6,487.9z"/>
						<path fill="#F5EBB0" d="M263.5,485.3c-0.2,0.7-0.7,0.9-1.3,0.9c-0.6,0-0.8-0.5-0.7-1c0.1-0.6,0.5-1,1.2-0.8
							C263.3,484.4,263.5,484.9,263.5,485.3z"/>
						<path fill="#888158" d="M260.9,491.1c-0.1-0.6,0-1.2,0.3-1.8c0.6,0,1.3,0.1,1.2,0.8C262.4,490.9,261.8,491.3,260.9,491.1z"/>
						<path fill="#F5EBB0" d="M259.2,486c0.2-0.5-0.2-1.4,0.7-1.4c0.5,0,0.7,0.6,0.6,1.1C260.3,486.8,259.6,485.9,259.2,486
							L259.2,486z"/>
						<path fill="#F5EBB0" d="M251.7,491.1c-0.1,0.1-0.1,0.3-0.2,0.3c-0.1,0-0.2-0.1-0.3-0.2c0.1-0.1,0.1-0.3,0.2-0.3
							C251.4,490.9,251.5,491.1,251.7,491.1z"/>
						<path fill="#E9E4B6" d="M204.9,429.4c-1.1-0.1-2.3-0.2-3.4-0.3c0.1-1.2,0.9-1,1.7-0.9C203.9,428.3,205,428,204.9,429.4z"/>
						<path fill="#3E3821" d="M196.1,499c-0.8,0.5-1.5,0.4-2.1-0.2C194.7,498.6,195.4,498.7,196.1,499z"/>
						<path fill="#14653A" d="M255.9,332.8c-0.4,0-0.8-0.1-1.2-0.1c-0.1-0.6,0.2-1.2-0.2-1.8c0-0.5,0.1-0.9,0.1-1.4
							c0.1,0,0.1,0,0.2,0c0-0.1-0.1-0.2-0.1-0.2c0-0.4-0.1-0.9-0.1-1.3c0.1-0.3,0.1-0.6,0.2-1c0.4-0.8,0.5-1.6,0.3-2.5
							c0.1-0.3,0.2-0.6,0.3-0.9c0.4,0,0.8,0,1.2,0c0.3,0,0.6,0.1,0.9,0.1c0.5,0.3,1.1,0.4,1.7,0.2c0.6,0.1,1.1,0.1,1.7,0.2
							c0.4,0.2,0.8,0.3,1.3,0.1c0.4,0,0.8,0.1,1.2,0.1c0.4,0.2,0.8,0.3,1.3,0.2c0.3,0.1,0.6,0.2,0.8,0.3c0.1,0.2,0.2,0.3,0.4,0.1
							c1.2,0.2,0.5,1.1,0.6,1.7c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c-0.2,2-0.4,4-0.6,6c-0.4,0.2-0.5,0.5-0.4,0.9
							c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.7-0.7-0.7-1.2-0.2c-0.8-0.1-1.7-0.2-2.5-0.3
							c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5-0.1-0.8-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0-0.8-0.1-1.2-0.1
							C256.3,332.8,256.1,332.8,255.9,332.8z M260.2,331.3c0.2,0.2,0.3,0.3,0.3,0.3c2.1,0.7,3.2-1,4.1-2.2c0.7-0.9-1-1.8-1.5-2.7
							c-0.4-0.7-1.1-0.8-1.8-0.7c-0.2,0-0.4,0.3-0.4,0.4c1.2,2.7-1.1,1.1-1.7,1.3c-0.7,0.2-1.9-0.7-2,0.7c-0.1,1.2,1.2,0.9,1.9,1.1
							c0.6,0.1,1.1,0.1,1.7,0.2c0.2,0,0.3,0.2,0.6,0.3C260.9,330.4,260.6,330.8,260.2,331.3z"/>
						<path fill="#888158" d="M255.3,323.6c-0.1,0.3-0.2,0.6-0.3,0.9c-1.4,0.7-1.9,1.4-0.3,2.5c-0.1,0.3-0.1,0.6-0.2,1
							c-0.6,0-1.5-0.7-1.4,0.6c0.1,0.9,0.3,1.8,0.6,2.6c0.2,0.4,0.5-0.1,0.8-0.3c0.4,0.6,0.1,1.2,0.2,1.8c-2.2,0.3-4.3-0.4-6.4-0.5
							c-0.8,0-1.1-0.5-1-1.3c0.3-2.1,0.2-4.3,0.9-6.4l0.2,0l0.1,0.1c-0.1,1.1-0.3,2.3-0.4,3.4c-0.1,0.7-0.2,1.4-0.3,2
							c0,0.4,0,0.7,0.5,0.7c0.4,0,0.9,0.1,0.9-0.5c0-0.4,0-0.8,0-1.2c0.1-0.1,0.1-0.2,0.1-0.3c0.4-0.4,0.5-0.8,0.4-1.3
							c0-0.3,0.1-0.6,0.1-0.9c0.2-1.1,0.3-2.3,0.5-3.4c1.4,0.1,2.7,0.2,4.1,0.3C254.7,323.3,255,323.5,255.3,323.6z M252.8,325.1
							c0.1-0.5,0-0.8-0.4-0.9c-0.7-0.2-1.4-0.4-1.9,0.4c-0.2,0.3-0.2,0.9,0.2,0.9C251.4,325.4,252.2,325.6,252.8,325.1z"/>
						<path fill="#888158" d="M223.1,322.2c1.2-0.4,0.6-1.3,0.5-2.1l0,0c0.6-0.1,1.1,0,1.6,0.3c1.2,0.7,2.5,0.8,3.9,1
							c3.9,0.5,7.8,0.8,11.7,1.1c0.6,0,0.6,0.4,0.8,0.8c-0.1,0.4-0.1,0.8-0.2,1.2c-5.8-0.4-11.6-1.4-17.4-1.6
							C223.7,322.9,223.4,322.5,223.1,322.2z"/>
						<path fill="#888158" d="M294.2,327.6c-1.8,1.2-3.2,0.6-4.5,0.5c-2.5-0.2-4.9-0.8-7.4-0.7c-2.9,0.1-5.5-0.9-8.3-1
							c-1.2,0-2.5,0.6-3.7-0.1c-0.4-0.2-1.1-0.2-1-0.9c0.1-0.6,0.7-0.6,1.2-0.6c6.4,0.7,12.7,1.5,19.1,2.1
							C291.1,327,292.3,327.6,294.2,327.6z"/>
						<path fill="#888158" d="M222.4,328.6c0.1-0.6,0.3-1.1,0.4-1.7c2.3,0.3,4.6,0.5,6.9,0.9c1.4,0.2,2.6,0.1,4,0
							c0.5,0,1.1-0.1,1.2,0.5c0.1,0.4,0.1,0.9-0.1,1.2c-0.3,0.5-1.2,0.5-1.3,0.2c-0.9-1.4-1.6,0-2.4,0.1c-0.2-0.3-0.3-0.4-0.6-0.1
							c-0.4,0-0.8,0-1.2,0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.3,0-0.6,0-0.9,0c-1.6-0.5-3.2-1.3-5-0.9
							C222.8,328.7,222.6,328.7,222.4,328.6z"/>
						<path fill="#E9E4B6" d="M257.4,323.7c-0.3,0-0.6-0.1-0.9-0.1c-0.6-0.6-1.4-0.3-2.1-0.5c1.4-0.7,2.8-0.1,4.1,0.1
							c3,0.4,6.1,0.2,9,1.3c0.8,0.3,1.5,0.6,0.7,1.4c-1.6,1.8-1.2,4-1.6,6c-0.1,0.7,0.2,1.6-0.8,2c0-0.3-0.1-0.5-0.1-0.8
							c0.2-2,0.4-4,0.6-6c0.1-0.1,0.1-0.2,0-0.4c-0.1-0.6,0.6-1.5-0.6-1.7c-0.1,0-0.3-0.1-0.4-0.1c-0.3-0.1-0.6-0.2-0.8-0.3
							c-0.4-0.5-0.8-0.5-1.3-0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.4-0.4-0.8-0.5-1.3-0.1c-0.6-0.1-1.1-0.1-1.7-0.2
							C258.6,323.4,258,323.3,257.4,323.7z"/>
						<path fill="#CEC69D" d="M279.6,343.7c-1.2,0.2-2.2-0.8-3.5-0.4c-0.4,0.1-1,0-0.7-0.6c0.2-0.5,0-1.6,1-1.2
							c1.5,0.6,3.2,0.3,4.7,0.6c0.6,0.1,1.3-0.3,1.2,0.8c-0.1,0.8-0.5,1.1-1.3,1C280.6,343.8,280.1,343.8,279.6,343.7z"/>
						<path fill="#B7AF8B" d="M241.4,324.6c0.1-0.4,0.1-0.8,0.2-1.2c1.8-0.7,3.6-1,5.3,0.4c0.3,0.3,0.7,0.6,0.5,1
							c-0.4,0.6-0.6,0-1-0.2C244.8,324,243.1,325.2,241.4,324.6z"/>
						<path fill="#CEC69D" d="M223.2,338.4c0-0.1,0-0.2,0.1-0.3c6,0.7,12,1.3,18.1,2c0.8,0.1,1.6,0,2.2,0.5
							C236.8,340,230,339.3,223.2,338.4z"/>
						<path fill="#F5F0BC" d="M210,333.3c0.1-0.9,0.2-1.7,0.3-2.6c0.9,0.4,2.5-0.5,2.4,1.5C212.6,334.3,211,333.1,210,333.3z"/>
						<path fill="#F5F0BC" d="M223,328.8c1.8-0.4,3.4,0.4,5,0.9c-0.4,0.9-1,1.4-1.9,0.5C225.3,329.2,223.5,330.4,223,328.8z"/>
						<path fill="#B7AF8B" d="M270.4,334.8c-0.2,0-0.5,0.1-0.9,0.2c-0.6,0-1.3,0.1-1.2-0.8c0.1-0.9,0.7-0.2,1.2-0.2
							c0.3,0,0.7,0,1-0.1c0.5-0.2,1-0.7,1.5,0c0.1,0.2,0.1,0.6,0,0.8C271.7,335.4,271.1,335,270.4,334.8z"/>
						<path fill="#32613E" d="M250.3,322.9c-0.2,1.1-0.3,2.3-0.5,3.4c-0.5-0.6-0.2-1.7-1.4-1.8l-0.1-0.1l-0.2,0
							C247.8,322.6,249,322.7,250.3,322.9z"/>
						<path fill="#CEC69D" d="M289.8,343c0.7-0.2,1.3-0.2,1.4,0.9c0,0.5-0.4,1.1-0.9,0.9c-0.7-0.3-1.8-0.6-1.8-1.4
							C288.4,342.2,289.2,343.1,289.8,343z"/>
						<path fill="#CEC69D" d="M283.8,344.1c-0.6,0-0.7-0.4-0.6-0.9c0-0.4,0-0.9,0.6-0.9c0.5,0,0.7,0.5,0.7,0.9
							C284.4,343.6,284.4,344,283.8,344.1z"/>
						<path fill="#CEC69D" d="M267.1,321c1-0.1,2,0,3,0.3C269,321.8,268.1,321.6,267.1,321z"/>
						<path fill="#888158" d="M262.4,333.4c0.5-0.5,0.9-0.5,1.2,0.2C263.1,333.9,262.7,333.8,262.4,333.4z"/>
						<path fill="#CEC69D" d="M290.9,323.5c0.9-0.1,1.7,0,2.5,0.3C292.5,324.3,291.6,324.2,290.9,323.5z"/>
						<path fill="#CEC69D" d="M287.3,343.7c0-0.3-0.1-0.6-0.1-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0,0.1,0.1,0.3,0.1,0.4
							C287.7,343.4,287.6,343.5,287.3,343.7z"/>
						<path fill="#F5F0BC" d="M263.7,320.6c0.4,0.1,0.8,0.1,1.3,0.2C264.4,322,264.1,321.3,263.7,320.6z"/>
						<path fill="#B7AF8B" d="M254.5,323c0.7,0.2,1.5,0,2.1,0.5c-0.4,0-0.8,0-1.2,0c-0.3-0.1-0.6-0.2-1-0.3
							C254.4,323.2,254.4,323.1,254.5,323z"/>
						<path fill="#888158" d="M257.7,332.9c0.3,0,0.6,0.1,0.9,0.1C258.2,333.3,257.9,333.2,257.7,332.9z"/>
						<path fill="#F5F0BC" d="M228.9,329.7c0.1,0,0.3,0.1,0.4,0.1c-0.1,0.2-0.2,0.3-0.3,0.5C229,330.1,228.9,329.9,228.9,329.7z"/>
						<path fill="#888158" d="M265.9,332.9c0,0.3,0.1,0.5,0.1,0.8c-0.2,0-0.3,0.1-0.5,0.1C265.4,333.4,265.5,333.1,265.9,332.9z"/>
						<path fill="#F5F0BC" d="M230.5,329.7c0.2-0.3,0.4-0.2,0.6,0.1C230.8,330.2,230.6,330.1,230.5,329.7z"/>
						<path fill="#888158" d="M264.4,333.8c0.2,0,0.4,0.1,0.7,0.1C264.8,334,264.6,334,264.4,333.8z"/>
						<path fill="#888158" d="M259.3,333.1c0.2,0,0.3,0,0.5,0C259.7,333.4,259.5,333.4,259.3,333.1z"/>
						<path fill="#888158" d="M255.9,332.8c0.2,0,0.4,0,0.5,0.1C256.3,333,256.1,333,255.9,332.8z"/>
						<path fill="#FBEFB2" d="M290.3,348.8c1.5-0.6,3-0.6,4.6-0.7c1.8-0.2,2.7,0.8,2.5,2.6c-0.4,3.9-0.9,7.7-1.3,11.6
							c-1,0-2.2-0.1-2.7-0.8c-1.2-1.4-3.1-0.4-4.3-1.5c-0.3-0.3-1.2,0.5-1.8,0.8c-0.7,0.3-0.8-0.2-1.1-0.4c-0.9-0.2-1.7,0.4-2.6,0
							c-0.2-0.4-0.5-0.4-0.8-0.1c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.1-0.5-0.2c-0.1-0.6,0.6-1.1,0-1.7
							c-1.8-1.7,0.1-2.7,0.9-3.8c0.1-0.2,0.7-0.2,0.8-0.1c0.7,1.1,1.9,0.4,2.8,0.6c1.9-0.3,2-1.5,1.1-3.3c1.3,0.3,2.1,0.5,3.1,0.5
							c1.6-0.1,0.6-0.8,0.5-1.3c-0.1-0.2-0.3-0.3-0.4-0.5l0,0C290.1,349.9,290.2,349.3,290.3,348.8z M293.3,351.7
							c0-0.4-0.3-0.4-0.5-0.5c-0.4-0.1-0.7,0.1-0.8,0.5c-0.1,0.3,0.2,0.7,0.6,0.6C292.9,352.2,293.3,352.1,293.3,351.7z"/>
						<path fill="#D02826" d="M254,358.5c-4.8-0.5-4.8-0.5-4.1-5.3c0.6-4.3,0.6-4.3,4.9-3.8c4.9,0.5,4.9,0.5,4.2,5.3
							C258.5,359,258.5,359,254,358.5z M255.5,354c0,0.1,0.1-0.4,0.1-0.9c0-0.6,0-1.4-0.8-1.5c-0.7-0.1-0.9,0.6-1,1.2
							c-0.2,1-0.3,2.1-0.4,3.2c-0.1,0.6,0.3,0.9,0.9,1c0.6,0.1,0.9-0.3,1-0.8C255.3,355.6,255.4,355.1,255.5,354z"/>
						<path fill="#3E3821" d="M215.1,345c1,1.2,1.9,2.3,0.3,3.8c-0.7,0.6,0.2,0.9,0.4,1.4c0.6,1.4-0.1,3.2-1.7,3.7
							c-1.6,0.6-3.6-0.3-4-1.8c-0.2-0.6,0.2-0.9,0.6-1.2c0.4,0.2,0.9,0.3,1.1,0.6c0.6,0.8,1.2,1.4,2,0.5c0.7-0.8,0.7-1.7-0.5-2.2
							c-1.1-0.5-1.1-1,0-1.5c0.7-0.3,1.4-0.6,0.9-1.5c-0.5-0.9-1.2-0.4-1.7,0.1c-0.5,0.4-1.1,0.4-1.5-0.2c-0.3-0.5,0.1-0.9,0.5-1.3
							C212.6,344.5,213.8,344.7,215.1,345z"/>
						<path fill="#888158" d="M221.2,348c2.9,0.2,5.9,0.9,8.9,0.8c1.1,0,2.1,0,2.7,1.2c0.2,0.3,0.8,0.5,0.3,1
							c-0.2,0.2-0.7,0.3-1,0.2c-2.9-1.3-6.1-0.5-9.1-1.1c-0.7-0.1-1.6,0.5-2.4-0.2c-0.5-0.4-1.8,0-1.4-1.3
							C219.5,347.4,220.3,347.8,221.2,348z"/>
						<path fill="#F5F0BC" d="M215.1,345c-1.3-0.3-2.5-0.5-3.6,0.4c-0.4,0.3-0.8,0.8-0.5,1.3c0.3,0.6,0.9,0.6,1.5,0.2
							c0.6-0.5,1.2-1,1.7-0.1c0.5,0.9-0.2,1.2-0.9,1.5c-1.1,0.5-1.1,1,0,1.5c1.1,0.5,1.2,1.5,0.5,2.2c-0.8,0.9-1.4,0.3-2-0.5
							c-0.2-0.3-0.7-0.4-1.1-0.6c1.1-2-0.1-3.9-2.5-4.2c-0.1-1.1,0.1-2.3,0.4-3.4c0.2,0.1,0.5,0.2,0.5,0.3c0.2,1.7,0.8,1.8,2.1,0.7
							c1.3-1.2,2.8-0.4,4.1-0.1C215.8,344.4,215.4,344.8,215.1,345z"/>
						<path fill="#888158" d="M229.3,354.2c1.5-0.4,2.9-0.1,4.4,0.1c2.2,0.3,4.4,0.7,6.6,0.7c0.9,0,1.7,0.3,1.5,1.3
							c-0.2,0.9-1,0.8-1.7,0.7c-3.2-0.7-6.4-1.2-9.7-0.7c-1.3,0.2-0.9-0.9-1.1-1.5C229.2,354.5,229.2,354.3,229.3,354.2z"/>
						<path fill="#888158" d="M227.2,360.4c-2.6-1.1-5.3-0.8-7.8-1.3c-0.8-0.2-1.4-0.4-1.2-1.3c0.2-0.9,0.8-0.6,1.5-0.5
							c3.3,0.5,6.5,0.9,9.8,1.3c0.6,0.1,1.4,0,1.3,1c-0.1,0.9-0.8,0.9-1.4,0.7C228.5,359.9,227.8,360.4,227.2,360.4z"/>
						<path fill="#888158" d="M234.2,344.5c1.4-0.7,2.9-0.1,4.2,0.2c1.5,0.3,3.1,0.3,4.6,0.6c0.5,0.3,0.4,1.1,1.1,1.2
							c0,0.4-0.2,0.7-0.6,0.7c-3-0.3-6-0.7-9.1-1c-0.2,0-0.3-0.3-0.4-0.4C234.1,345.3,234.1,344.9,234.2,344.5z"/>
						<path fill="#888158" d="M242.7,352.3c-2,0.5-3.8-0.7-5.7-0.7c-0.7,0-1.3,0-1.1-1.2c0.2-1.3,0.7-0.3,1.1-0.4
							c2.7-0.7,5,0.7,7.6,0.8c0.5,0,0.6,0.3,0.6,0.7c0,0.4-0.2,0.7-0.6,0.8C244,352.3,243.3,352.3,242.7,352.3z"/>
						<path fill="#CEC69D" d="M290.5,351c0.1,0.5,1.1,1.1-0.5,1.3c-1,0.1-1.9-0.2-3.1-0.5c0.9,1.8,0.8,3-1.1,3.3
							c0.3-1.7-0.7-3.6,0.9-5.1c1.1,0.6,2.2,0.1,3.3,0.4l0,0C290.2,350.6,290.3,350.8,290.5,351z"/>
						<path fill="#FBEFB2" d="M275.8,359.6c-0.7-1.4,0.5-2.4,0.9-3.5c1,0.2,1.9,0.3,2.4-0.8c0.6,0.1,0.9,0.2,0.6,0.9
							c-0.3,1.1-0.8,2.2-0.5,3.4C278.1,360.1,277,359.2,275.8,359.6z"/>
						<path fill="#FBEFB2" d="M295,364.7c1,0.4,1,0.9,0,1.2c-0.6,0.2-1.2-0.1-1.8-0.1c-2.7-0.5-5.3-1.1-8-1.3c0-0.3,0.1-0.6,0.1-0.8
							C288.5,364,291.7,364.3,295,364.7z"/>
						<path fill="#CEC69D" d="M275.8,359.6c1.1-0.4,2.3,0.4,3.4,0c0.1,0.5-0.4,1.6,0.9,0.8c0.3-0.2,0.8-0.2,1.2-0.3
							c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0.3,0.2,0.3,0.3,0c0.2,0,0.3,0,0.5,0c-1.1,1.6-2.8,1.5-4.2,1.2c-0.8-0.1-1.7-0.4-2.4-0.4
							c-2,0.2-1.9-0.7-1.4-2.1l0,0C275,359.2,275,360.4,275.8,359.6z"/>
						<path fill="#888158" d="M221,343.2c-0.7,0.2-0.3,1.1-1.1,1.1c-0.5-0.8-0.3-1.7,0.3-2.2c0.7-0.7,1.4-0.3,2,0.3
							c0,0.1,0.1,0.1,0.1,0.2c0.7,0.5,2.1,0,1.9,1.5c-0.1,0.5-0.5,0.9-1.1,0.8C222.1,344.8,220.9,344.8,221,343.2z"/>
						<path fill="#888158" d="M230.3,345.4c-1.2,0.4-2.5-0.1-3.7-0.1c-0.5,0-1.1,0-1-0.7c0.1-0.7,0.4-1.3,1.1-1.2
							c1.2,0.2,2.4,0.5,3.6,0.7C230.4,344.5,230.3,344.9,230.3,345.4z"/>
						<path fill="#E9E4B6" d="M224.3,340c-0.4-0.1-0.9,0-1.2-0.4c6.8,0.7,13.5,1.4,20.3,2.1c-0.7,0.5-1.5,0.2-2.3,0.1
							C235.6,341.2,230,340.6,224.3,340z"/>
						<path fill="#CEC69D" d="M268.2,365.3c-0.2,0-0.3,0-0.4,0c-0.7-0.8-2.7,0.4-2.6-1c0.1-1.3,1.9-0.4,2.9-0.5
							c0.6-0.1,1.2,0.3,2,0.1c0.5-0.1,1.2,0,1.2,0.8c-0.1,0.7-0.6,0.9-1.2,0.9C269.5,365.4,268.8,364.7,268.2,365.3z"/>
						<path fill="#888158" d="M220.7,352.9c0.8,0.1,2-0.2,2.1,1.2c0,0.7-0.8,1.2-1.3,0.8c-0.8-0.7-2.8,0.5-2.7-1.4
							C218.8,352.1,219.9,352.8,220.7,352.9z"/>
						<path fill="#B7AF8B" d="M244.1,346.4c-0.7-0.1-0.6-0.9-1.1-1.2c1.5-0.5,2.9,0.1,4.3,0.2c0.6,0,1.1,0.4,1.1,1.1
							c0,0.5-0.2,0.8-0.7,0.9c-0.6,0.1-1.1,0.2-1.4-0.5C245.8,345.7,245,346,244.1,346.4z"/>
						<path fill="#F5F0BC" d="M290.1,350.5c-1.1-0.3-2.3,0.2-3.3-0.4c0-0.1,0-0.3,0-0.4c0.4-0.5,0.6-1,0.6-1.6c0.1,0,0.3,0,0.4,0
							c0.4,0.2,0.8,0.4,1.2,0.5c0.4,0.1,0.9,0.1,1.3,0.2C290.2,349.3,290.1,349.9,290.1,350.5z"/>
						<path fill="#CEC69D" d="M287.4,348c0,0.6-0.2,1.1-0.6,1.6c-0.9-1.2-2.7,0.4-3.4-1.2c0.2-0.6,0.8-1.5,1.2-0.9
							C285.5,348.7,286.5,347.6,287.4,348z"/>
						<path fill="#CEC69D" d="M285.2,363.7c0,0.3-0.1,0.6-0.1,0.8c-1.2,0.5-2-0.7-3.1-0.6c-0.1,0-0.4-0.6-0.4-0.9
							c0-0.4,0.4-0.5,0.8-0.4C283.3,363,284.5,362.8,285.2,363.7z"/>
						<path fill="#B7AF8B" d="M267.4,346.8c1.1-1.4,2.2-0.8,3.4-0.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0-0.5-0.1-0.7-0.1
							C269.1,346.7,267.5,348.4,267.4,346.8z"/>
						<path fill="#B7AF8B" d="M269.4,354.1c1.1,0,2.3-0.7,3,0.7c0.2,0.3,0,0.8-0.4,0.9C270.7,356,270,355.2,269.4,354.1z"/>
						<path fill="#CEC69D" d="M274.8,349.6c-0.1,0.7-0.2,1.4-1.1,1.2c-0.8-0.2-1.7-0.4-2.1-1.2c-0.2-0.5,0.2-1,0.8-0.9
							c0.5,0.1,1.1,0.3,1.6,0.4C274.1,349.5,274.4,349.6,274.8,349.6z"/>
						<path fill="#3E3821" d="M230.3,345.4c0-0.4,0-0.9,0.1-1.3c0.1-0.1,0.3-0.1,0.4,0c0.2,0.6,0.5,0.5,0.8,0.1
							c0.8,0.1,1.7,0.2,2.5,0.3c0,0.4-0.1,0.8-0.1,1.2C232.8,345.6,231.6,345.5,230.3,345.4z"/>
						<path fill="#E9E4B6" d="M269.4,347.7c0.2,0,0.5,0.1,0.7,0.1c0.1,0.5,0.6,1.5,0,1.5c-1.1,0-2.3,0.8-3.3,0.1
							c-0.7-0.5,0.3-0.6,0.5-0.9C268,347.8,269.3,349.1,269.4,347.7z"/>
						<path fill="#CEC69D" d="M283.5,360.3c0.9,0.4,1.7-0.2,2.6,0c-0.3,0.7,0.5,2.2-1.2,1.8C284.1,362,282.7,361.9,283.5,360.3z"/>
						<path fill="#CEC69D" d="M275.9,363c-0.4-0.5-0.8-0.5-1.2-0.1c-0.5,0.5-0.5,0.2-0.7-0.3c-0.3-0.7,0.1-0.8,0.4-0.8
							c0.9,0.2,2.1-0.5,2.6,0.6C277.3,363.3,276.4,363,275.9,363L275.9,363z"/>
						<path fill="#FBEFB2" d="M274.6,359.1c-1.7,0-1.5-1.4-1.8-2.6C274.6,356.7,274.4,358,274.6,359.1L274.6,359.1z"/>
						<path fill="#CEC69D" d="M280.8,362.5c-0.5,1.2-1.5,1.3-2.5,1.2c-0.3,0-0.4-0.5-0.2-0.7C278.9,362.1,279.9,362.6,280.8,362.5z"
							/>
						<path fill="#888158" d="M279.1,355.3c-0.5,1.1-1.4,1.1-2.4,0.8C276.8,353.7,278.2,355.4,279.1,355.3z"/>
						<path fill="#E9E4B6" d="M255.7,343c0.7,0.1,1.4,0.1,2.2,0.2c-0.1,0.7-0.3,1.2-1.2,1.1C255.9,344.2,255.6,343.7,255.7,343z"/>
						<path fill="#E9E4B6" d="M260.4,343.5c0.7-0.1,1.4,0,2.1,0.3C261.7,344.8,261,344.7,260.4,343.5z"/>
						<path fill="#B7AF8B" d="M274.2,355c-0.1,0.4-0.3,0.6-0.7,0.5c-0.3-0.1-0.5-0.2-0.5-0.6c0-0.4,0.3-0.6,0.7-0.6
							C274,354.3,274.2,354.6,274.2,355z"/>
						<path fill="#B7AF8B" d="M229.3,354.2c0,0.2-0.1,0.3-0.1,0.5c-0.6,0.5-1.2,0.9-2.6,0.7C227.9,354.9,228.3,354,229.3,354.2z"/>
						<path fill="#E9E4B6" d="M277.4,349.4c0,0.4-0.2,0.5-0.4,0.4c-0.3-0.1-0.6-0.2-0.7-0.3c-0.1-0.3,0.2-0.5,0.5-0.4
							C277,349.1,277.2,349.3,277.4,349.4z"/>
						<path fill="#CEC69D" d="M281.3,347.8c0.5,0.4,1.6-0.7,1.8,0.6C282.5,348.2,281.5,349.3,281.3,347.8z"/>
						<path fill="#E9E4B6" d="M252.7,342.6c0.3,0,0.6,0.1,0.9,0.1c-0.1,0.4-0.1,1-0.7,0.8C252.7,343.5,252.7,343,252.7,342.6z"/>
						<path fill="#888158" d="M231.6,344.2c-0.3,0.4-0.6,0.5-0.8-0.1C231.1,343.7,231.4,343.9,231.6,344.2z"/>
						<path fill="#E9E4B6" d="M274.8,349.6c-0.3,0-0.7-0.1-0.8-0.5C274.7,348.6,274.7,349.1,274.8,349.6z"/>
						<path fill="#CEC69D" d="M275.9,363.1c-0.1,0-0.2,0.1-0.3,0.1C275.7,363.2,275.8,363.1,275.9,363.1L275.9,363.1z"/>
						<path fill="#888158" d="M241.2,363.1c0.2-0.2,0.3-0.3,0.4,0C241.4,363.1,241.3,363.1,241.2,363.1z"/>
						<path fill="#888158" d="M262.4,289.7c0.8,0.2,1.9-0.4,2.1,1.1c0.1,0.6,0.7,0.3,1,0c0.8-0.9,1.5-1,2.3-0.1
							c0.4,0.6,0.4,1.2-0.2,1.7c-0.8,0.7-1.5,0.5-2.2-0.3c-0.9-1.3-1.4,0-1.9,0.4c-0.5-0.2-1.1-0.3-1.6-0.5
							c-0.3-0.5,0.3-1.3-0.4-1.8c-0.8,0.6,0.1,1.2-0.2,1.7c-0.9,0.4-1.5,0.1-1.9-0.7c-0.2-0.4-0.2-1,0.2-1.4
							c0.6-0.7,1.3-0.7,2.1-0.2C261.8,290,262.1,290,262.4,289.7z"/>
						<path fill="#3E3821" d="M225.7,282.3c-1.2-1.1-2.2-1.7-3.6-0.9c-1,0.6-0.9-0.3-0.7-0.7c0.6-1.1,1-2.3,1.2-3.6
							c0.2-1.1,1.2-0.7,1.9-0.7c0.7,0,1.4,0.1,1.4,1.2C225.8,279.1,225.7,280.5,225.7,282.3z M224.7,278.9c0-0.5,0.4-1.4-0.5-1.5
							c-0.9,0-0.7,1-0.9,1.6c-0.2,0.5-0.2,1,0.5,1.1C224.6,280.3,224.6,279.6,224.7,278.9z"/>
						<path fill="#888158" d="M230,286.4c-0.5,0-0.8,0-1.1,0c-0.6-0.1-1,0.1-0.9,0.8c0.1,0.6,0.5,0.5,1,0.5c0.8,0,1.4-0.3,2.1-0.8
							c0.6-0.5,1.3-0.5,1.9,0.1c0.6,0.6,0.3,1.3-0.1,1.7c-0.6,0.5-1.3,1.1-2,0.1c-0.5-0.7-0.9-0.7-1.6-0.2c-0.9,0.7-1.7,0.3-2.2-0.5
							c-0.6-0.9-0.3-1.9,0.6-2.4C228.5,285.1,229.4,284.9,230,286.4z"/>
						<path fill="#3E3821" d="M286.1,287.8c-1.2-0.6-2.6-0.6-4-0.4c0.1-1.1,1.9-2,0-3c1.8-0.5,3.1,0.6,4.5,0
							c0.1,0.2,0.2,0.3,0.2,0.5C285,285.5,286.5,286.9,286.1,287.8z"/>
						<path fill="#32613E" d="M293.7,285.4c0.7-0.3,0.9,0.2,1.1,0.7s0.6,0.9,0.9,0.4c0.9-1.4,2.1-1.1,2.7-0.2
							c0.8,1.1,0.2,2.3-0.9,3.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.6-0.6-0.6-1.5-0.9-1.9
							c-1-0.3-0.6,1.1-1.5,0.6C293.9,287.1,293.8,286.3,293.7,285.4z"/>
						<path fill="#3E3821" d="M264.5,282c1.1,0.2,2.1,0.5,1.9,2c-0.3,1.8-2.1,1.5-3.2,2.1c-0.6,0.3-0.5-1.2-0.3-1.7
							C263.3,283.6,262.3,281.7,264.5,282z"/>
						<path fill="#888158" d="M251.1,283.9c-0.1-0.7,0.5-1.3,0.2-2.1c-0.3-1,0.6-1.3,1.4-1.3c0.9,0,1.8,0,1.8,1.2
							c0,0.7,0.1,1.5-0.2,2.1c-0.6,1.2-1-0.1-1.5-0.3C252.3,283.4,251.9,284.5,251.1,283.9z"/>
						<path fill="#F5F0BC" d="M237.8,287.1c0.6-0.9,4-1.8,4.5-1.2c0.4,0.5-0.4,1-0.3,1.5c-1.8,0-2.4,1-1.5,2.6c-1,0.9-2,0.9-3,0.2
							c0.7-0.7,2.1-0.7,1.9-2.1C239.2,287.4,238.5,287.2,237.8,287.1z"/>
						<path fill="#888158" d="M243.2,279.8c1.1,0.1,2.5-0.5,2.8,1.1c0.1,0.7,0.4,1.8-0.4,2.4c-0.7,0.5-1.7,0.3-2.5,0
							c-1-0.5,0.1-1.4-0.1-2.1C243.1,280.7,243.2,280.2,243.2,279.8z"/>
						<path fill="#32613E" d="M229.8,280.1c-0.2,0.9-0.5,1.9-1.8,1.6c-1.1-0.3-1.4-1.1-1.3-2.1c0.2-1,0.8-1.7,1.9-1.4
							C229.6,278.4,230.2,279.1,229.8,280.1z"/>
						<path fill="#32613E" d="M267.3,284.2c0.1-1.5,0.1-1.5,1.3-0.9c0.2,0.1,0.4,0.1,0.5,0c2.5-1.6,1.3,0.7,1.4,1.4
							c0.1,0.4,0.1,1.1-0.5,1.3c-0.6,0.2-0.6-0.4-0.7-0.8c-0.1-0.4-0.4-0.4-0.6-0.1C267,286.7,267.3,285.1,267.3,284.2z"/>
						<path fill="#3E3821" d="M250.2,282.5c0,0.3,0.3,1-0.5,1.2c0.1-1.9-3.3,1.2-2.5-1.7c0.2-0.5-0.2-1.3,0.7-1.5
							C247.7,282.3,251.6,278.8,250.2,282.5z"/>
						<path fill="#3E3821" d="M261.2,285.1c-0.2-0.5-0.3-0.6-0.3-0.8c-0.1-0.6,0.6-1.6-0.4-1.8c-0.8-0.1-0.5,0.9-0.7,1.5
							c-0.1,0.3-0.2,0.7-0.7,0.6c-0.5-0.1-0.3-0.5-0.3-0.9c0-2.1,1-3,2.6-2.1C262.2,282.1,262.2,283.2,261.2,285.1z"/>
						<path fill="#32613E" d="M234.1,280.6c-0.1,1.3-0.2,1.3-1.1,0.6c-0.3-0.2-0.4-0.3-0.7-0.1c-0.4,0.4-0.9,1-1.4,0.4
							c-0.5-0.5,0-1.2,0-1.9c0-0.7,0.4-0.9,0.9-0.5c0.3,0.2,0.3,0.9,0.8,0.3c0.3-0.3,0.6-0.9,1.2-0.6
							C234.6,279.4,234,280.1,234.1,280.6z"/>
						<path fill="#888158" d="M276.7,283.1c1.7,0.9,0.1,2.2,0.4,3.3c0.1,0.5-0.7,0.4-1.1,0.4c0-0.7,0-1.4,0-2.3
							c-1.1,0.4-0.4,1.4-1.4,2c-0.1-1.3-0.5-2.4,0.1-3.6c0.4,0,0.9,0,1.3,0C276.2,283,276.5,283.1,276.7,283.1z"/>
						<path fill="#F5F0BC" d="M294,287.9c0.9,0.5,0.5-0.9,1.5-0.6c0.3,0.4,0.2,1.4,0.9,1.9c-1,1.6-2.2,1.7-4.1,0.3
							c0.1-0.3,0.2-0.6,0.3-1l0.2,0l0.2-0.1C293.4,288.6,294,288.7,294,287.9L294,287.9z"/>
						<path fill="#32613E" d="M237.8,281.4c0.2,1.1-0.5,1.2-1.3,1.1c-0.9-0.1-1.4-0.7-1.5-1.5c-0.1-0.8,0.2-1.6,1-1.9
							c0.9-0.3,1.9-0.3,2.1,1c-0.6,0.4-1.9-0.9-1.9,0.6C236.2,282,237.3,281.2,237.8,281.4z"/>
						<path fill="#CEC69D" d="M236.6,289.8c-1.8,0.3-2-1.3-2.6-2.4c-0.2-0.5,0.1-0.8,0.6-0.8c0.8,0.1,1.7-0.2,2.3,0.7
							C236.8,288.2,236.7,289,236.6,289.8z"/>
						<path fill="#888158" d="M247.1,290.5c-1.3,0.3-1-0.4-1-0.9c0-0.9,0.2-1.5,1.3-1.5c0.9,0,1.1,0.5,1.1,1.4
							C248.5,290.7,247.8,290.7,247.1,290.5z"/>
						<path fill="#888158" d="M240.4,290.1c-0.9-1.7-0.3-2.6,1.5-2.6C242.4,288.9,242.8,290.4,240.4,290.1z"/>
						<path fill="#888158" d="M236.6,289.8c0.1-0.8,0.2-1.6,0.3-2.4c0.3-0.1,0.6-0.2,0.9-0.2c0.7,0.1,1.4,0.2,1.6,1.1
							c0.2,1.4-1.3,1.4-1.9,2.1C237,290.3,236.7,290.2,236.6,289.8z"/>
						<path fill="#888158" d="M283.3,294.7c0-0.9-0.4-1.9,0.7-2.4c0.5-0.2,1.2-0.1,1.5,0.4c0.7,1,0.1,1.7-0.6,2.3
							C284.4,294.9,283.9,294.8,283.3,294.7z"/>
						<path fill="#888158" d="M286.3,294.6c0.3-0.6-0.2-1.8,0.8-1.9c0.6,0,2-0.6,2,1c-0.1,0.4-0.2,0.8-0.4,1.3
							C288,294.9,287.1,294.8,286.3,294.6z"/>
						<path fill="#CEC69D" d="M286.1,287.8c0.4-0.9-1-2.3,0.7-2.9c0.4,0.1,0.8,0.1,1-0.4c0.1,0,0.3,0,0.4,0.1
							c0.2,0.8,0.6,1.6-0.6,2.1c-0.3,0.1-0.1,0.8,0,1.2C287.1,287.9,286.6,287.8,286.1,287.8z"/>
						<path fill="#3E3821" d="M292.5,288.6c-0.1,0.3-0.2,0.6-0.3,1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.4-1.2,0-2.5,0.1-3.7
							c0.2-1.5,1.3-0.8,2-0.6c0.1,0.7-2.1,0.7-0.6,1.9c0,0.1,0,0.3-0.1,0.4C292.7,287.9,292.5,288.2,292.5,288.6z"/>
						<path fill="#F5F0BC" d="M237.8,281.4c-0.5-0.2-1.6,0.6-1.6-0.6c0.1-1.5,1.3-0.3,1.9-0.6c0.4,0,0.8,0.1,1.2,0.1
							c0.9,0.6,0.3,1.4,0.2,2.2C238.5,283.1,238.6,281.5,237.8,281.4z"/>
						<path fill="#888158" d="M289.6,293.8c0.4-1.1,1.2-0.9,1.8-0.5c1.1,0.8,0,1.6-0.2,2.4l0,0c-0.6-0.1-1.1-0.2-1.7-0.3l-0.1-0.1
							c0,0-0.2,0-0.2,0l0-0.1c0.4,0.1,0.3-0.2,0.3-0.4C289.6,294.4,289.6,294.1,289.6,293.8z"/>
						<path fill="#888158" d="M254.5,290.5c-0.1,0.9-0.8,0.9-1.3,0.9c-0.9,0.1-1.4-0.3-1.3-1.2c0.1-0.5-0.1-1.5,0.7-1.1
							C253.2,289.4,254.1,289.8,254.5,290.5z"/>
						<path fill="#3E3821" d="M239.7,282.4c0-0.7,0.7-1.5-0.2-2.2c0.5-1.7,1.6-0.4,2.5-0.5c-0.1,0.2-0.3,0.5-0.4,0.7
							c-0.9,0.5-0.2,1.8-1.1,2.4c-0.1,0-0.3-0.1-0.4-0.1C239.9,282.5,239.8,282.4,239.7,282.4z"/>
						<path fill="#888158" d="M244.1,289.9c-0.9,0.3-1.2,0-1.1-0.9c0.1-1,0.7-0.9,1.4-0.9c1,0,1,0.5,0.9,1.2
							C245.2,290.3,244.8,290.3,244.1,289.9z"/>
						<path fill="#B7AF8B" d="M292.8,295.4c0.5-1-0.1-2,0.1-3c0.4-0.1,1.1-1.1,1.2,0C294.1,293.5,294.9,295.2,292.8,295.4z"/>
						<path fill="#B7AF8B" d="M241.4,280.4c0.1-0.2,0.3-0.5,0.4-0.7c0.4,0,0.9,0,1.3,0c-0.1,0.5-0.1,0.9-0.2,1.4
							C242.5,280.9,242,280.7,241.4,280.4z"/>
						<path fill="#F5F0BC" d="M289.6,295.3c0.6,0.1,1.1,0.2,1.7,0.3c-0.1,0.6-0.4,0.8-1,0.7C289.7,296.3,289.6,295.9,289.6,295.3z"
							/>
						<path fill="#F5F0BC" d="M261.2,292c0.3-0.5-0.6-1.2,0.2-1.7c0.7,0.4,0.1,1.2,0.4,1.8C261.6,292,261.4,292,261.2,292z"/>
						<path fill="#E9E4B6" d="M289.6,293.8c0,0.3,0,0.6,0,0.9c-0.1,0.1-0.2,0.3-0.3,0.4l0,0.1c-0.2-0.1-0.3-0.1-0.5-0.2
							c0.1-0.4,0.2-0.8,0.4-1.3C289.3,293.8,289.5,293.8,289.6,293.8z"/>
						<path fill="#F5F0BC" d="M276,283c-0.4,0-0.9,0-1.3,0C275.1,282.2,275.5,282.3,276,283z"/>
						<path fill="#E9E4B6" d="M284.7,296.7c-0.3,0-0.6-0.1-0.8-0.1C284.2,296.2,284.5,296.2,284.7,296.7z"/>
						<path fill="#B7AF8B" d="M239.9,282.7c0.1,0,0.3,0.1,0.4,0.1C240.2,283,240,282.9,239.9,282.7z"/>
						<path fill="#3E3821" d="M279.7,283.8c1.2,0.1,2,0.7,1.9,1.9c-0.1,1-0.9,1.8-1.8,1.9c-1.4,0.1-1.8-1.3-1.8-2.3
							C277.9,284.3,278.8,283.8,279.7,283.8z"/>
						<path fill="#888158" d="M273.6,292.9c-0.4,0.6-1,1-1.6,0.6c-0.7-0.6-1.3-0.7-2.2-0.3c-0.6,0.2-1.4-0.1-1.4-1.1
							c0-1,0.4-1.6,1.4-1.2c1.3,0.4,2.9-0.6,3.9,0.9C273.7,292.1,273.7,292.5,273.6,292.9z M270.8,291.7c0-0.1,0-0.2,0-0.3
							c-0.1,0-0.3,0.1-0.3,0.1c-0.1,0.1,0,0.2,0,0.4C270.6,291.9,270.7,291.8,270.8,291.7z"/>
						<path fill="#32613E" d="M287.6,287.9c-0.1-0.4-0.4-1,0-1.2c1.2-0.5,0.8-1.3,0.6-2.1c0.7,0,1.7-0.2,2,0.7
							c0.3,0.9,0.1,1.9-0.5,2.6C289.5,288.2,288.2,289.1,287.6,287.9z"/>
						<path fill="#888158" d="M278.7,294.2c-0.7-0.3-1.2-0.8-1.1-1.6c0.1-0.8,0.7-1.3,1.5-1.1c0.9,0.2,0.7,0.9,0.7,1.5
							C279.8,293.9,279.5,294.3,278.7,294.2z"/>
						<path fill="#32613E" d="M273.6,292.9c0.1-0.4,0.1-0.7,0.2-1.1c0.4-0.1,0.8-0.2,1.2-0.4c0.5,0.9,1.1,0,1.7,0.2
							c0.2,0.7,0.3,1.7-0.3,1.8C275.5,293.7,274.4,293.9,273.6,292.9z"/>
						<path fill="#888158" d="M281.7,291.7c0.5,0.1,1.1,0.2,0.8,0.9c-0.3,0.6-0.2,1.6-1.1,1.6c-0.8-0.1-0.4-1-0.6-1.6
							C280.7,291.9,281.1,291.7,281.7,291.7z"/>
						<path fill="#B7AF8B" d="M276.7,291.6c-0.6-0.1-1.2,0.8-1.7-0.2c0.1-0.5,0.3-1,0.9-1C276.6,290.4,276.7,291,276.7,291.6z"/>
						<path fill="#888158" d="M293.1,287.3c-1.6-1.2,0.7-1.2,0.6-1.9l0,0c0.1,0.8,0.2,1.7,0.3,2.5c0,0-0.1,0-0.1,0
							C293.5,287.9,293.7,287,293.1,287.3z"/>
						<path fill="#F5EBB0" d="M214,293.4c0.1,0,0.2,0.1,0.3,0.1c0.2-0.1,0.3-0.2,0.5-0.4c0.4,0.2,0.2,1.1,0.7,1.1
							c0.7-0.1,0.4-0.9,0.6-1.4c0.7,0.1,1.4,0.2,2.1,0.2c0.3,1.2,0.2,2.3,0.1,3.5c-0.1,0.5-0.5,0.7-0.9,0.8c-0.4-0.8-1.2-1.5-2-1.1
							c-1.2,0.6-0.7,2-0.8,3.1c-0.1,1.1,0.7,1,1.4,1c1.3,0.1,0.7,1.1,0.8,1.7c0.2,0.9-0.9,2.1,0.5,2.8c0.8,0.7,1.8,1.1,2-0.4
							c0.3-2.7,0.8-5.4,0.6-8.2c0-1.2,0.6-2.1,1.2-3c16.3,1.7,32.6,3.5,48.9,5.2c10.8,1.2,21.7,2.3,32.5,3.5
							c-0.8,7-1.5,14.1-2.3,21.1c-1.2,0-2.4,0-3.5-0.1c-10.3-1.4-20.7-2.3-31.1-3.3c-2.2-0.8-4.5-0.7-6.7-1
							c-3.6-0.5-7.2-0.9-10.8-1.3c-0.1-1.2-1.1-0.9-1.8-1c-2.6-0.5-5.3,0-7.7-1.6c-0.5-0.3-1.5-0.3-2,0c-1,0.5-2,0.4-3.1,0.3
							c-2.5-0.3-5-0.6-7.6-0.8c-0.7-0.1-1.6-0.5-2,0.6c-0.6,0.1-1.1,0.2-1.4-0.4c-1-1.4-3.4-0.1-4-2.2c0,0-0.4,0.1-0.5,0.2
							c-2.3,1.2-3.7,0.8-5-1.5c-0.2-0.4-0.5-0.7-0.9-0.8c-0.1-1,0-2,0.3-3c0.2,0,0.5,0,0.5-0.1c0.1-0.4-0.3-0.2-0.5-0.3
							c-0.1-1.4,0.1-2.8,0.4-4.2c0.9-1.2,1.1-2.5,0.4-3.8c-0.1-0.7,0-1.4,0.2-2.1c0.8-0.8,0.9-1.6,0.3-2.6
							C213.9,293.9,214,293.6,214,293.4z M256,300.4L256,300.4c-0.3,0.1-0.7-0.2-0.5,0.4c-0.1,0.4-0.2,0.9-0.2,1.3
							c-0.5,0.5-0.6,1.1-0.2,1.7c-0.1,0.9-0.2,1.7-0.3,2.6c-0.5,0.5-0.6,1-0.2,1.6c0,0.3,0,0.6,0,0.9c-0.3,0.2-0.2,0.3,0.1,0.4l0,0
							c2.3,1.4,4.9,0.7,7.3,1.3c1.4,0.4,2.2-0.3,2.4-1.6c0.3-2.2,0.6-4.3,0.7-6.5c0.1-1-0.2-1.7-1.5-1.3c-0.6-0.1-1.2-0.1-1.8-0.2
							c-0.2-0.4-0.4-0.4-0.7-0.1c-0.5,0-0.9-0.1-1.4-0.1c-0.5-0.5-1.1-0.6-1.7-0.1c-0.6-0.1-1.1-0.2-1.7-0.2
							C256.3,300.2,256.2,300.2,256,300.4z M278.3,308.5c0.7,0,1.4,0.5,1.9-0.2c0.2-0.3-0.1-0.7-0.5-0.8
							C278.9,307.1,278.5,307.7,278.3,308.5c-0.4-0.2-0.8-0.4-1.3-0.5c-1.6-1-3.4-0.5-5.1-1c-0.4-0.1-0.7,0.1-0.7,0.6
							c0,0.7,0.5,0.9,1,0.9c1.6-0.2,3.2,0.5,4.8-0.2C277.3,308.9,277.8,309,278.3,308.5z M278.5,311.1c0.5,0.2,1.2,0.4,1.3-0.4
							c0.1-0.9-0.7-0.6-1.2-0.6c-1.5-0.3-3-0.5-4.6-0.9c-0.8-0.2-1,0.3-1.3,0.8c-0.2,0.3,0,0.5,0.4,0.5
							C274.9,310.8,276.6,311.8,278.5,311.1z M227,303.7c0.3,0.1,0.6,0.2,0.8,0.3c1.5,1.3,3.2,0.7,4.8,0.4c1.3,0.1,2.6-0.1,3.9,0.3
							c2.5,0.7,5.1,0.8,7.7,0.6c0.3,0,0.9-0.4,1,0.3c0.2,1.5,0.7,0.8,1.2,0.2c0.4-0.5,0.7-1.1,1.1-1.7c-2.9-0.3-5.8-0.3-8.5-1.5
							c0-0.5,0.1-0.9,0.1-1.4l0,0c0.2,0,0.4,0,0.6,0c0.5,0.1,1,0.1,1.4,0.3c0.7,0.3,1.5,0.7,1.9-0.1c0.5-1-0.8-1.1-1.3-1.6
							c-0.2-0.2-0.6-0.4-1-0.4c-2.8,0.1-5.5-0.1-8.1-1.2c-1.6-0.9-3.3-1.2-5.1-0.5c-1.1-0.9-1.8-0.3-2.4,0.7
							c-1.9,0.8-0.1,2.2-0.6,3.3c-0.6,1.7,0.6,1.8,1.8,1.8c0,0.2-0.2,0.5,0.2,0.6C226.8,304.2,226.9,303.8,227,303.7z M289.3,318.2
							c1,0.9,2.4,0.4,3.5,0.9c0.3,0.1,0.7-0.1,0.7-0.5c0.1-0.5-0.3-0.8-0.7-0.8c-1.2,0.1-2.3-0.6-3.5-0.1c-1.8-1.1-3.8-0.6-5.7-1
							c-0.4-0.1-1.1-0.4-1.3,0.2c-0.2,0.7,0.5,0.9,1,1.1C285.3,318.9,287.3,318.4,289.3,318.2z M281.5,311.3c2.2,1.2,4.7,0.8,7,1.1
							c0.4,0.1,0.6-0.3,0.6-0.7c0-0.4,0-0.8-0.5-0.8c-2.4,0.2-4.7-0.5-7.1-0.4c-0.6-0.2-1.2-0.3-1.2,0.5
							C280.3,311.6,281,311.5,281.5,311.3z M281.6,313.9c0.1,0,0.1,0.1,0.2,0.1C281.7,314,281.6,314,281.6,313.9
							c0.1-0.6,0.3-1.1-0.8-1.2c-3-0.2-5.9-1.1-9-0.4c-0.4,0.1-0.8-1-1.1-0.1c-0.2,0.7,0.3,1.1,1,1
							C275,312.9,278.2,314.8,281.6,313.9z M235.5,311.9C235.5,311.9,235.5,311.9,235.5,311.9c4,0.4,8,0.8,12.1,1.2
							c0.5,0,1.1,0.2,1.1-0.5c0-0.5-0.5-0.6-0.9-0.6c-0.3-0.1-0.6-0.1-0.8-0.1c-7.6-0.8-15.2-1.5-22.9-2.3c-0.5,0-1.4-0.4-1.4,0.3
							c-0.1,0.9,0.9,0.7,1.5,0.7C227.9,311.1,231.7,311.5,235.5,311.9z M271.8,303.8c2.7,1.4,5.6,0.5,8.4,0.6c0.4,0,0.7-0.2,0.7-0.6
							c0-0.4-0.4-0.6-0.7-0.6c-1.9-0.2-3.8-0.6-5.6-0.5C273.7,302.6,272.3,302.3,271.8,303.8z M274,315.7
							C274,315.7,274,315.7,274,315.7c-0.9-0.1-1.8-0.2-2.7-0.3c-0.4,0-1.1-0.5-1.1,0.4c0,0.5,0,1.1,0.8,1.2c2,0.2,3.9,0.4,5.9,0.6
							c0.5,0.1,1-0.1,1-0.8c0.1-0.6-0.4-0.8-0.9-0.9C275.9,315.9,275,315.8,274,315.7z M290,311.8c1.2,1.2,2.6,1.1,3.9,1.3
							c0.5,0.1,1,0,1.1-0.7c0-0.6-0.3-0.9-0.9-1C292.8,311.2,291.5,311.3,290,311.8z M214,303.8c0.2,0.8-0.7,2,0.4,2.2
							c1,0.2,1.3-1.1,1.4-2c0.1-0.8,0.3-2-0.8-2.1C213.7,301.8,214.5,303.2,214,303.8z M289.8,304.8c-1.3-0.5-2.3-0.9-3.4-1.4
							c-0.4-0.2-0.8-0.2-1,0.3c-0.1,0.4,0,0.8,0.3,0.9C286.9,305.2,288.1,306.2,289.8,304.8z"/>
						<path fill="#F5F0BC" d="M224,315c0.4-1,1.3-0.7,2-0.6c2.5,0.2,5,0.5,7.6,0.8c1,0.1,2,0.3,3.1-0.3c0.6-0.3,1.5-0.3,2,0
							c2.4,1.6,5.2,1.1,7.7,1.6c0.7,0.1,1.7-0.2,1.8,1C240.1,317,232,316.2,224,315z"/>
						<path fill="#F5F0BC" d="M212.1,310.3c0.5,0.1,0.7,0.4,0.9,0.8c1.4,2.3,2.7,2.7,5,1.5c0.2-0.1,0.5-0.2,0.5-0.2
							c0.6,2.1,3,0.8,4,2.2c0,0-0.1,0.1-0.1,0.1s-0.2,0-0.2,0c-0.3-0.2-0.6,0-0.8,0.2c-0.7,0.1-1.4,0-2.1-0.2
							c-0.1-0.4-0.3-0.4-0.4,0c-2.3-0.1-4.5-0.2-6.8-0.8c-0.1-0.2-0.3-0.4-0.4-0.6C211.7,312.3,211.8,311.3,212.1,310.3z"/>
						<path fill="#32613E" d="M265.7,319.8c10.4,1,20.8,1.9,31.1,3.3c-3.5,0-6.9-0.6-10.3-1C279.5,321.5,272.6,320.6,265.7,319.8z"
							/>
						<path fill="#F5EBB0" d="M221.5,314.9c0.2-0.3,0.5-0.4,0.8-0.2C222.1,315,221.8,315,221.5,314.9z"/>
						<path fill="#F5EBB0" d="M218.9,314.6c0.2-0.4,0.3-0.4,0.4,0C219.2,314.7,219.1,314.6,218.9,314.6z"/>
						<path fill="#E9E4B6" d="M211.8,313.3c0.1,0.2,0.3,0.4,0.4,0.6c-0.1,0-0.3-0.1-0.4-0.1C211.7,313.6,211.8,313.4,211.8,313.3z"
							/>
						<path fill="#CEC69D" d="M289,348.6c-0.4-0.2-0.8-0.4-1.2-0.5C288.5,347.4,288.7,348.1,289,348.6z"/>
						<path fill="#E9E4B6" d="M218.4,296.5c0.1-1.2,0.2-2.4-0.1-3.5c1,0.1,2,0.2,2.9,0.3c-0.6,0.9-1.3,1.8-1.2,3
							C219.4,296.4,218.9,296.5,218.4,296.5z"/>
						<path fill="#E9E4B6" d="M216.1,292.8c-0.2,0.5,0.2,1.3-0.6,1.4c-0.5,0.1-0.3-0.8-0.7-1.1C215.2,292.6,215.7,292.7,216.1,292.8
							z"/>
						<path fill="#E9E4B6" d="M213.3,298.9c0.7,1.4,0.5,2.6-0.4,3.8C212.8,301.4,212.9,300.1,213.3,298.9z"/>
						<path fill="#E9E4B6" d="M213.8,294.2c0.6,0.9,0.5,1.8-0.3,2.6C213.4,295.9,213.5,295,213.8,294.2z"/>
						<path fill="#F5F0BC" d="M212.4,306.9c0.2,0.1,0.5,0,0.5,0.3c0,0.1-0.3,0.1-0.5,0.1C212.4,307.2,212.4,307.1,212.4,306.9z"/>
						<path fill="#CEC69D" d="M383.8,394c-0.4,0.4,0,1.7-0.7,1.3c-0.8-0.4-2-0.2-2.4-1.3c-0.2-0.5,0-1.2,0.8-1.1
							c0.8,0,1.5,0.1,2.3,0.2C383.7,393.4,383.8,393.7,383.8,394z"/>
						<path fill="#888158" d="M397.5,400.1l-0.2,0c0,0-0.2,0-0.2,0c0.5-3.8,0.5-7.7,1.4-11.4C398.5,392.5,397.8,396.3,397.5,400.1z"
							/>
						<path fill="#32613E" d="M385.5,401.9c-0.1,0.2-0.3,0.5-0.4,0.7c-1.2,0-2.2-0.2-1.8-1.8c0.9,0.3,1.4-0.6,2.1-0.6
							c0.1,0.2,0.1,0.3,0.2,0.5C385.6,401,385.6,401.4,385.5,401.9z"/>
						<path fill="#CEC69D" d="M383.4,398.2c-1.5,0.4-1.5,0.4-1.6-2.3c0.9,0.2,1.6,0.5,1.7,1.5C383.4,397.7,383.4,397.9,383.4,398.2z
							"/>
						<path fill="#CEC69D" d="M385.6,400.6c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.2,0.1-0.4,0.1-0.5c0.1-0.1,0.3-0.2,0.4-0.3
							c0.4,0.1,0.9,0.1,1.3,0.2C387,400.3,386,399.9,385.6,400.6z"/>
						<path fill="#32613E" d="M400.4,380.2c-0.4,0.1-0.8,0.3-1.2,0.4C399.4,379.8,399.8,379.8,400.4,380.2z"/>
						<path fill="#32613E" d="M386,399.4c-0.1,0.1-0.3,0.2-0.4,0.3C385.7,399.5,385.8,399.4,386,399.4z"/>
						<path fill="#888158" d="M398.6,386.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0-0.1,0.1-0.1,0.2C398.7,386.4,398.7,386.3,398.6,386.1z"/>
						<path fill="#32613E" d="M400.7,380.2C400.7,380.2,400.7,380.2,400.7,380.2c0-0.1,0-0.3,0-0.4
							C400.8,380,400.8,380.1,400.7,380.2z"/>
						<path fill="#D02826" d="M400.6,388.6c0.4-0.5,0.3-1.1,0.4-1.6c0.4-4.3,0.5-3.5,3.9-3.2c0.4,0,0.8,0.1,1.3,0.1
							c4.7,0.5,4.8,0.7,4,5.2c-0.2,1.4-0.5,2.8-0.8,4.2c-2.1-0.2-4.2-0.6-6.4-0.6c-1.8,0-2.7-0.7-2.5-2.6
							C400.5,389.6,400.6,389.1,400.6,388.6z M404,387.3c0.1,0.7-0.8,1.9,1.1,2.1c1.9,0.2,2.2-0.9,2.4-2.4c0.2-1.5-0.4-1.7-1.7-1.9
							C404.1,384.8,404,385.9,404,387.3z"/>
						<path fill="#888158" d="M400.5,390.1c-0.2,1.9,0.7,2.5,2.5,2.6c2.1,0,4.2,0.4,6.4,0.6l0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.3
							c-2.6-0.2-5.2-1-7.9-0.5c-0.2-0.1-0.4-0.2-0.5-0.2c-0.3-0.2-0.6-0.3-0.8-0.5C400.2,391.7,400.4,390.9,400.5,390.1z"/>
						<path fill="#FBEFB2" d="M400.1,392.5c0.3,0.2,0.6,0.3,0.8,0.5c0,0.3-0.1,0.6-0.1,0.9c-0.2,0.1-0.4,0.2-0.5,0.3
							c-0.1,0-0.2,0-0.3-0.1C400,393.6,400,393,400.1,392.5z"/>
						<path fill="#FBEFB2" d="M400,394.1c0,0.3-0.1,0.5-0.1,0.8c-0.3-0.1-0.8-0.2-0.6-0.6C399.3,394.1,399.7,394.1,400,394.1z"/>
						<path fill="#F5F0BC" d="M400.8,393.9c0-0.3,0.1-0.6,0.1-0.9c0.2,0.1,0.4,0.2,0.5,0.2c2.6,0.1,5.2,1.1,7.9,0.5
							c0-0.1,0.1-0.2,0.1-0.3c0.8,0.2,0.7,0.7,0.6,1.2c-1.1,0-2.2-0.6-3.3,0c-0.9-0.1-1.7-0.2-2.6-0.4c-0.2-0.4-0.5-0.4-0.8-0.1
							C402.5,394.1,401.7,394,400.8,393.9z"/>
						<path fill="#FBEFB2" d="M406.7,394.7c1.1-0.7,2.2,0,3.3,0c-0.2,0.3-0.3,0.5-0.5,0.8C408.6,395,407.5,395.6,406.7,394.7z"/>
						<path fill="#F5EBB0" d="M411.7,378.8c0.1,0.4,0.1,0.8-0.2,1.2C411.3,379.6,411.3,379.2,411.7,378.8z"/>
						<path fill="#888158" d="M234.3,244.4c-0.6-2.5-0.2-2.5,2.5-2.4c2.1,0.1,4.2,0.7,6.4,0.3c1.6-0.3,2.1,0.4,1.8,1.9
							c1-0.2,1.6-3,2.6-0.3c0,0,0.3,0.1,0.3,0c2.4-1.9,5.2-0.3,7.6-0.2c1.1,0.1,2.8,0.3,4.2,0.9c1.5,0.7,3.7-0.1,5.5-0.2
							c0.6,0.7,1.2,1.5,0.5,2.5c-0.7,1-1.6,0.9-2.6,0.3c-1.6-1-3.6-0.6-5.2-0.5c-2.9,0.2-5.6-1-8.5-0.6c-1.5,0.2-3.2-0.5-4.8-0.8
							c-0.9-0.2-1.6,0.1-2.4,0.1c0.1-0.6,0.2-1.3,0.3-1.9c0-0.6-0.9-0.8-0.9-0.7c0.1,1.9-2.1,1.1-2.6,2.2c-0.5,0-0.9-0.1-1.4-0.1
							c-0.3-0.5-0.7-0.7-1.3-0.3C235.6,244.9,234.9,244.7,234.3,244.4z"/>
						<path fill="#B7AF8B" d="M246.5,249.7c1.5-0.7,3.3-0.7,4.6,0.1c0.9,0.6,2.5-0.8,2.8,1c1.7-2.3,3.9-0.4,5.7-0.3
							c2.1,0,4.5-0.1,6.4,0.6c2.3,0.9,4.5,0.8,6.8,0.7c1-0.1,2.2,0.4,3.3,0.6c0.7,0.1,1.2,0.3,1.3,1.1c0.1,0.5-0.1,1-0.6,1.3
							c-0.6,0.3-1.2,0.3-1.6-0.4c-0.1-0.2-0.3-0.4-0.3-0.4c-3.1,0.8-6.2-1.1-9-0.7c-2.6,0.4-4.8-1.4-7.3-0.2
							c-0.7,0.3-1.9-0.4-2.8-0.6c-1.9-0.5-3.8-0.5-5.8-0.4c-1.2,0.1-3,0.7-3.7-1.3C246.4,250.5,246.4,250.1,246.5,249.7z"/>
						<path fill="#B7AF8B" d="M269,264.1c-16.3-1-32.5-3.3-48.7-5.2c16.3,0.9,32.6,2.5,48.8,4.5C269,263.6,269,263.9,269,264.1z"/>
						<path fill="#888158" d="M242.5,255.4c-0.5,0.1-0.6-1.4-1.6-0.3c-0.6,0.7-2.5,0.5-3.6,0.2c-3.4-0.9-6.8-0.3-10.2-1
							c-0.9-0.2-2-0.2-2.5-1.1c-0.7-1.3-1.1-0.6-1.7,0.2c-0.2-0.9-0.3-1.9,0.4-1.9c1.3,0,2.9-0.4,4,0.9c0,1.2,0.9,1.1,1.7,1.2
							c1.2,0.1,0.6-0.9,0.9-1.4c4.9,0.5,9.8,1.1,14.8,1.6c-0.3,0.2,0.7,1.7-0.9,0.9C243.4,254.5,242.8,254.9,242.5,255.4z"/>
						<path fill="#B7AF8B" d="M238.3,250.5c-1.7-1.1-4.3,1-6.4-1c-0.3-0.3-1.6,0.8-2.6,0.4c-1.5-0.6-3-1.1-4.7-0.5
							c-1.2,0.4-2-1.5-3.4-1.1c-0.1,0-0.1-1.3,0.1-2c0.2-0.7,0.6-0.1,0.9-0.1c1.8,0,3.5,0.7,5.1,1.3c1.2,0.4,2.5-0.5,3.9,0
							c2.7,0.8,5.5,1.1,8.4,1c0.7,0,2.1-0.2,1.9,1.3c-0.2,1.4-1.3,0.7-2,0.8C239.1,250.7,238.9,250.6,238.3,250.5z"/>
						<path fill="#B7AF8B" d="M256.1,257c-1.3-0.2-0.3-1.8-1.3-2c-1.2-0.2-0.4,1.4-1.3,1.7c-0.8-0.7-0.3-1.6-0.1-2.4
							c0.3-0.9,1.1-0.6,1.8-0.6c1.7-0.1,3.2,1.1,4.9,0.6c0.3-0.1,0.6,0.3,0.7,0.7c0,0.1-0.2,0.4-0.3,0.4c-0.6-0.1-1.2,0-1,0.8
							c0.1,1,1.2-0.1,1.5,0.7c-0.9,0.8-3.3,0.7-3.7-0.1c-0.2-0.5,0.5-1.5-0.6-1.4C255.9,255.4,256.5,256.5,256.1,257z"/>
						<path fill="#B7AF8B" d="M242.5,255.4c0.3-0.5,1-0.9,1.4-0.6c1.5,0.9,0.5-0.6,0.9-0.9c2-0.1,3.9,0.6,5.9,0.2
							c0.7-0.1,1.3,0.3,1.3,1c0,0.6,0,1.6-0.9,1.4C248.2,255.9,245.2,256.5,242.5,255.4z"/>
						<path fill="#888158" d="M262.6,256c0.1-0.4,0.2-1.3,0.6-0.9c1,1,3.5-0.7,3.4,1.9c0,1.1-0.8,1.2-1.6,1.1
							C263.7,257.8,262.5,256.9,262.6,256z"/>
						<path fill="#CEC69D" d="M269,264.1c0-0.2,0-0.5,0-0.7c2.1,0.2,4.2,0.4,6.4,0.6c0.5,0,1.1,0,1.1,0.7c0,0.7-0.7,0.6-1.1,0.5
							C273.2,264.8,270.9,265.3,269,264.1z"/>
						<path fill="#CEC69D" d="M274.3,260c-2.1,0.5-3.9-0.9-6-0.4c-0.3,0.1-0.7-0.3-0.7-0.7c0-0.5,0.4-0.6,0.7-0.5
							C270.4,258.7,272.5,258.7,274.3,260z"/>
						<path fill="#E9E4B6" d="M238.9,245.1c0.5-1.1,2.7-0.2,2.6-2.2c0-0.2,0.9,0,0.9,0.7c0,0.6-0.2,1.3-0.3,1.9
							C241,246,240,245.7,238.9,245.1z"/>
						<path fill="#B7AF8B" d="M230,252.3c-0.3,0.5,0.3,1.5-0.9,1.4c-0.8-0.1-1.7,0.1-1.7-1.2C228.1,251,229,252.1,230,252.3z"/>
						<path fill="#B7AF8B" d="M244.6,250c-0.8-0.2-0.6,0.8-1.4,1c-0.1-0.8-0.8-1.5-0.1-2.1c0.7-0.7,1.3,0,1.8,0.5
							C244.8,249.6,244.7,249.8,244.6,250z"/>
						<path fill="#888158" d="M244.6,250c0.1-0.2,0.2-0.4,0.3-0.7c0.6-0.2,1.1-0.2,1.6,0.3c-0.1,0.4-0.1,0.8-0.2,1.2
							c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5-0.1-0.9-0.1-1.4-0.2C244.6,250.6,244.6,250.3,244.6,250z"/>
						<path fill="#E9E4B6" d="M236.3,244.6c0.6-0.4,1-0.2,1.3,0.3C237,245.4,236.6,245.3,236.3,244.6z"/>
						<path fill="#B7AF8B" d="M244.6,250.9c0.5,0.1,0.9,0.1,1.4,0.2C245.4,252.2,245,251.5,244.6,250.9z"/>
						<path fill="#888158" d="M418.1,457.7c-1.6-0.1-3.2-0.6-4.8-0.8c-0.8-0.1-0.9-0.4-0.9-1c0-0.7,0.5-1.1,1.1-0.8
							c2.4,1.2,5,0.3,7.4,0.8c0.8,0.2,1.6,0.2,1.6,1.3c-0.1,1.3-1,0.9-1.7,0.9C420,457.9,419.1,457.8,418.1,457.7z"/>
						<path fill="#888158" d="M419.2,453c0.1,0,0.2,0,0.4,0c0.2-0.1,0.4-0.1,0.6-0.2c1.5,0.1,3,0.6,4.5,0.4c0.5-0.1,1.1-0.1,1.2,0.6
							c0.1,0.7-0.4,1-1,1.2c-1.2,0.4-2.4,0.2-3.5-0.2c-0.7-0.3-1.5-0.4-2.2-0.5C419.2,453.9,419.2,453.5,419.2,453z"/>
						<path fill="#888158" d="M414.1,453c-1.1,1.5-2.6,0.1-3.9,0.7c0-0.3,0-0.5,0-0.8c0-0.3,0-0.5,0-0.8c0-0.2,0.1-0.3,0.1-0.5
							c0,0,0,0,0,0c0.3,0.1,0.5,0.1,0.8,0.2c1,0.2,2,0.1,2.9,0.7C414.1,452.7,414.1,452.9,414.1,453z"/>
						<path fill="#888158" d="M417.4,454.6c-0.9-0.3-2.4-0.4-2.2-1.4c0.2-1.4,1.6-0.1,2.4-0.4c0.4-0.1,0.7-0.1,0.9,0.2
							C418.4,453.6,418,454.2,417.4,454.6z"/>
						<path fill="#51727B" d="M417.4,454.6c0.7-0.4,1-1,1.2-1.7c0.2,0.1,0.5,0.1,0.7,0.2c0,0.4-0.1,0.9-0.1,1.3
							C418.6,454.9,418,454.7,417.4,454.6z"/>
						<path fill="#51727B" d="M410.2,453c0,0.3,0,0.5,0,0.8C409.7,453.5,409.9,453.2,410.2,453z"/>
						<path fill="#51727B" d="M411.2,451.9c-0.3-0.1-0.5-0.1-0.8-0.2C410.8,451.3,411,451.4,411.2,451.9z"/>
						<path fill="#51727B" d="M410.4,451.7c0,0.2-0.1,0.3-0.1,0.5c0-0.1-0.1-0.2-0.1-0.4C410.2,451.8,410.3,451.7,410.4,451.7z"/>
						<path fill="#51727B" d="M420.2,452.9c-0.2,0.1-0.4,0.1-0.6,0.2C419.8,453,420,452.9,420.2,452.9z"/>
						<path fill="#51727B" d="M414.1,453c0-0.1,0-0.3,0-0.4C414.5,452.7,414.4,452.9,414.1,453z"/>
						<path fill="#888158" d="M440.5,267.5c-2.9-0.1-3.2-0.3-2.8-2.2c0.1-0.7,0.5-1.1,1.1-0.9c1.1,0.3,2.2,0.2,3.2,0.4
							c1.3,0.3,0.1,1.4,0.3,2.5c1.1-0.8,1.1-3.1,3.4-2.1c1.4,0.6,3,0.7,3.7,2.7c0.7-1.1,0.5-2.5,1.9-2.4c1.2,0.1,1.1,1.1,1.3,2.1
							c1.1-2.2,2-2.1,2.6,0.2c0.7-2.2,2.4-1.4,3.6-1.2c1.3,0.3,1,1.5,0.6,2.4c-1.2,1.3-2.6,0.1-3.7-0.1c-1.5-0.2-2.8,0.1-4.2,0.2
							c-0.6-0.9-1.2-0.3-1.7,0c-0.2-0.1-0.5-0.2-0.7-0.3c-0.5-1-1.3-1-2.2-0.7c-0.3,0-0.6,0-0.8,0c-0.2-0.7,0.7-1.2,0.3-2.1
							c-0.9,0.6-1.9,0.9-2.4,1.8C442.8,267.6,441.6,268.1,440.5,267.5z"/>
						<path fill="#CEC69D" d="M414.6,279.5c19.5,2.1,38.9,4.2,58.4,6.3c0,0.1,0,0.3,0,0.4c-19.5-2.1-38.9-4.2-58.4-6.3
							C414.6,279.8,414.6,279.7,414.6,279.5z"/>
						<path fill="#B7AF8B" d="M461.1,273.3c-0.8,2.3-2.5,1.3-3.6,1.2c-0.8-0.1-1.5-0.4-2.2,0.1c-0.2,0.1-0.5,0.3-0.5,0.3
							c-1-1.7-2.8-0.2-4.1-1c-0.5-0.3-1.5,0.1-1.1-1c0.3-0.8,0.5-1.8,1.7-1.5c4.7,0.9,9.5,0.9,14.3,1.5c0.7,0.1,1.4,0.2,1.7,1
							c0.2,0.5,0,1.1-0.4,1.3c-0.7,0.2-1.2,1.3-2.2,0.3c-0.4-0.4-1.2-0.4-1.8-0.4C461.8,275,460.8,274.9,461.1,273.3z"/>
						<path fill="#B7AF8B" d="M464.1,279.6c-1.2,0.9-2.2-0.4-3.4-0.3c-2.4,0.1-4.7-0.2-7.1-0.5c-1.7-0.2-1.8-0.8-1.7-2.1
							c-0.5,0-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.4-0.1,0.6c-0.2,0.4-0.4,0.8-0.9,0.6c-0.4-0.2-0.2-0.6-0.1-0.9c0.5-2.5,0.7-2.5,3-1.1
							c0.9,0.5,2.2,0.5,3.4,0.5c2.4,0.1,4.8,0.8,7.2,0.6c0.4,0,0.7,0.2,0.8,0.6C464.3,278.4,464.1,278.9,464.1,279.6z"/>
						<path fill="#B7AF8B" d="M438.2,271.3c-1.2,1.1-2.6,0.6-3.9,0.7c-2.7,0.4-5-1.4-7.7-0.9c-1.9,0.3-1.8-1.2-1.2-2.6l0,0
							c4.2,1,8.6,0.9,12.9,1.4c0.2,0,0.4,0.3,0.7,0.5C438.7,270.7,438.5,271,438.2,271.3z"/>
						<path fill="#B7AF8B" d="M431,274.7c-2.5,2.2-3.8,1.7-5.2-0.5c-0.1,2.3-1.6,1-2.5,1.1c-1.4,0.1,0.1-1.6-1-2
							c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.4-0.1,0.8-0.6,0.7c-0.2,0-0.5-0.4-0.5-0.6c0-1,0.1-2-1.5-1.7c-0.4,0.1-0.7-0.5-0.3-0.9
							c0.7-0.7,3.4-0.4,3.8,0.5c0.4,1.1,1,0.8,1.8,0.8c2.5,0.1,5-0.1,7.4,0.6c0.2,0.1,0.5,0.3,0.7,0.5
							C432.1,275.6,432.1,275.6,431,274.7z"/>
						<path fill="#888158" d="M431,274.7c1.1,0.9,1.1,0.9,1.9-0.3c1.9,0.4,3.9-0.6,5.8,0.6c0.7,0.5,1.8,0.4,2.7,0.3
							c0.9-0.1,1.5-0.2,1.6,1c0,1.3-1.1,1-1.7,1c-3.3,0.2-6.5-1-9.8-1C429.9,276.4,431.2,275.3,431,274.7z"/>
						<path fill="#B7AF8B" d="M467.2,278.3c1.9-0.9,3.7,0,5.5,0.5c0.1,0.5,0.2,1.1,0.3,1.6c-1.9,0.5-3.8,0.4-5.5-0.6
							C467.4,279.4,467.3,278.8,467.2,278.3z"/>
						<path fill="#B7AF8B" d="M416.4,272.5c-0.6,0.3-0.5,0.8-0.6,1.2c-0.1,0.4-0.3,0.7-0.7,0.6c-0.2-0.1-0.3-0.4-0.4-0.6
							c-0.2-0.5,0.4-1.3-0.5-1.3c-0.8,0-0.7,0.9-1,1.4c-0.1,0.1-0.2,0.2-0.4,0.3c-0.7-0.9-0.5-1.8,0.1-2.5c0.5-0.6,1.3-0.8,2.1-0.5
							c0.4,0.2,1,0.2,1.4,0.2c0.8-0.1,1.2,0.2,1.5,0.9c0.3,0.7,0.1,1.4-0.1,2.1c-0.1,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.2-0.5-0.5
							C416.7,273.5,416.9,273,416.4,272.5z"/>
						<path fill="#888158" d="M438.2,271.3c0.2-0.3,0.4-0.6,0.7-0.8c1.3,0.9,2.7,1.2,4.2,0.6c1-0.4,1.6,1.1,2.5,0.3
							c0.6,0.5,0.4,1.1,0.2,1.6c-0.8,0.1-1.6,0-2.4-0.2c-0.1-0.5-0.4-0.8-1-0.6c-0.9-0.1-1.6,0.6-2.5,0.3c-0.2-0.3-0.6-0.4-0.9-0.3
							C438.5,272.2,438.3,271.8,438.2,271.3z"/>
						<path fill="#B7AF8B" d="M447.2,278.2c-0.7-0.7-2.9,0-2.7-1.5c0.2-1.5,2.2-0.7,3.4-0.9c0.9-0.1,1.2,0.5,1.1,1.4
							C448.9,278.1,448.4,278.4,447.2,278.2z"/>
						<path fill="#B7AF8B" d="M445.8,273.1c0.2-0.6,0.4-1.2-0.2-1.6c0.8-0.1,1.4-1.7,2.5-0.5c0.4,0.5,0.8,1.2,0.3,1.7
							C447.7,273.5,446.8,273.6,445.8,273.1z"/>
						<path fill="#888158" d="M467.2,278.3c0.1,0.5,0.2,1,0.2,1.6c-0.3,0-0.6,0-0.9-0.1c-0.3-0.4-0.6-0.4-0.9,0.1
							c-0.2,0-0.3-0.1-0.5-0.1c0-0.3-0.1-0.3-0.3-0.1c-0.3,0-0.6-0.1-0.8-0.1c0-0.6,0.2-1.1,0.6-1.6
							C465.6,277.5,466.5,277.7,467.2,278.3z"/>
						<path fill="#888158" d="M435.4,263.6c0.2,0.5,1,0.3,0.9,1.2c-1.3-0.2-2.4,0.1-2.5,1.7c-0.4,0-0.8-0.1-0.9-0.6
							c0.3-0.5,0.3-0.9-0.2-1.3C433.1,262.9,434.5,264.1,435.4,263.6z"/>
						<path fill="#888158" d="M473,280.5c-0.1-0.5-0.2-1.1-0.3-1.6c0.3-0.1,0.7-0.2,1-0.2c0.6,0.1,1.2-0.1,1.7,0.5
							C475.3,280.7,474.3,280.8,473,280.5z"/>
						<path fill="#B7AF8B" d="M425.4,268.5c-1.5,0-0.7,1.9-2,2.2c-0.3-1.1-0.6-2.3,0.6-3C424.6,267.3,425.4,267.5,425.4,268.5
							C425.4,268.6,425.4,268.5,425.4,268.5z"/>
						<path fill="#E9E4B6" d="M443.9,268c0.6-0.9,1.6-1.2,2.4-1.8c0.4,0.8-0.6,1.3-0.3,2.1C445.1,268.9,444.4,268.8,443.9,268z"/>
						<path fill="#E9E4B6" d="M446.9,268.2c0.9-0.4,1.7-0.4,2.2,0.7C448.2,269.2,447,270.4,446.9,268.2z"/>
						<path fill="#E9E4B6" d="M449.8,269.2c0.6-0.3,1.2-0.9,1.7,0C450.9,269.1,450.3,270.6,449.8,269.2z"/>
						<path fill="#E9E4B6" d="M432.7,264.6c0.4,0.4,0.5,0.8,0.2,1.3C431.9,265.6,432.8,265,432.7,264.6z"/>
						<path fill="#B7AF8B" d="M442.5,272.3c0.6-0.1,0.9,0.1,1,0.6C442.9,273,442.6,272.8,442.5,272.3z"/>
						<path fill="#B7AF8B" d="M439,272.3c0.4-0.1,0.7-0.1,0.9,0.3C439.4,273.3,439.2,272.8,439,272.3z"/>
						<path fill="#B7AF8B" d="M465.8,279.9c0.2-0.5,0.5-0.5,0.9-0.1C466.4,280.2,466.1,280.3,465.8,279.9z"/>
						<path fill="#B7AF8B" d="M464.9,279.6c0.2-0.2,0.3-0.1,0.3,0.1C465.1,279.8,465,279.7,464.9,279.6z"/>
						<path fill="#3E3821" d="M215.5,326.3c0.7,0.2,0.9,0.2,1.2,0.3c0.7,0.2,2-0.3,1.9,1c-0.1,1.1-1.3,0.6-2,0.5
							c-0.9,0-1.8-0.2-2.7-0.3c-1.7-0.1-1.5-1-0.7-1.9c0.9-1,2-1.7,3-2.6c0.7-0.7,1.7-1.4,0.8-2.5c-0.8-1-1.5-0.2-2.1,0.4
							c-0.4,0.4-0.9,0.4-1.3,0c-0.6-0.6-0.1-1,0.3-1.4c1-1.1,3.1-1.3,4.3-0.3c1.3,1.1,1.4,2.5,0.4,3.8
							C217.8,324.3,216.7,325.1,215.5,326.3z"/>
						<path fill="#F5EBB0" d="M223.5,320.2c-1.7,0.1-3.2-0.1-4.5-1.3c-0.4-0.4-0.6-0.8-0.3-1.3c0.2-0.5,0.7-0.4,1.1-0.4
							c0.7,0.1,1.4,0.1,2.1,0.2C225,317.7,225,317.7,223.5,320.2L223.5,320.2z"/>
						<path fill="#FBEFB2" d="M211.3,318.4c0.1-0.7,0.1-1.4,0.2-2.1c0.4,0,0.8,0,1.2,0.1c0.8,0.2,1.7-0.2,2.3,0.7
							c0.2,0.3,0,0.8-0.3,0.9c-1,0.2-1.6,1.5-2.8,0.7C211.8,318.5,211.5,318.5,211.3,318.4z"/>
						<path fill="#FBEFB2" d="M211,324.8c0.1-1.1,0.2-2.3,0.4-3.4c0.8,0.1,1.7,0,1.6,1.3C212.9,324.1,212.4,324.8,211,324.8z"/>
						<path fill="#F5F0BC" d="M369.9,314c0,0.1-0.1,0.1-0.1,0.2C369.8,314.1,369.9,314.1,369.9,314L369.9,314z"/>
						<path fill="#CCDEC7" d="M371.9,309.9c-0.1-1.7,1.4-1.5,2.3-1.9c0.9-0.3,0.7,0.5,1,0.9c0,0.5-0.1,1-0.6,1.3
							C373.7,310.1,372.8,310,371.9,309.9z"/>
						<path fill="#51727B" d="M371.2,310.1c0.1-0.1,0.2-0.2,0.3-0.2C371.4,310,371.3,310.1,371.2,310.1z"/>
						<path fill="#CEC69D" d="M306.1,241.3c-0.6-0.1-0.8-0.5-0.7-1.1c0-0.5,0.2-1,0.8-1c0.6,0,0.8,0.5,0.8,1.1
							C306.9,240.7,306.7,241.2,306.1,241.3z"/>
						<path fill="#32613E" d="M345,447.3c-1.3,11.8-2.6,23.7-4,35.5c-0.6-0.6-0.3-1.3-0.2-2c1.1-10.5,2.2-21,3.4-31.5
							C344.3,448.6,344,447.7,345,447.3z"/>
						<path fill="#B7AF8B" d="M342.1,444.4c-0.1-0.4-0.4-0.5-0.7-0.6c0-0.1,0-0.3,0-0.4c0-0.9,0.2-1.5,1.3-0.9
							c0.5,0.3,1.1,0.4,1.7,0.6c0.2,0.5,0.4,0.9,0.2,1.5c0,0.1,0,0.2,0.1,0.2C343.9,444.6,343,444.5,342.1,444.4z"/>
						<path fill="#32613E" d="M341.4,483.3c3.4,0.3,6.8,0.7,10.1,1c0.9,0.1,1.8,0.2,1.2,1.5c-0.5-0.7-1.2-1-2.1-1.1
							c-2.4-0.2-4.9-0.5-7.3-0.8C342.7,483.9,341.9,484,341.4,483.3z"/>
						<path fill="#32613E" d="M345.7,444.6c0.2,0.1,0.5,0.2,0.7,0.3c-1.6,0.7-1.6,0.7-1.7-0.2c-0.1-0.1-0.1-0.1-0.1-0.2
							C345,444.5,345.3,444.6,345.7,444.6z"/>
						<path fill="#CEC69D" d="M333.4,392.5c0.4-1.1-0.1-3.1,1.3-3c1.3,0.1,0.9,2,0.8,3.1c-0.1,1,0.5,2.9-1.3,2.8
							C332.3,395.2,333.8,393.5,333.4,392.5z"/>
						<path fill="#888158" d="M345.7,444.6c-0.3,0-0.7-0.1-1-0.1c0.2-0.5,0-1-0.2-1.5c0.1-0.5,0-1.1,0.5-1.4l0.2,0.1
							C345.5,442.7,345.9,443.6,345.7,444.6z"/>
						<path fill="#B7AF8B" d="M392.3,502.9c-0.2-1.7-0.3-3.4-0.1-5c0.2-1.3,0.6-1.8,2-1.7c1.4,0.2,2,0.6,1.8,2.1
							c-0.2,1.5,0,3-2.3,2.6C392.2,500.7,392.6,502.1,392.3,502.9z"/>
						<path fill="#B7AF8B" d="M399.1,496c0.6-0.1,1.3,0,1.8,0.3c-0.6,0.4-1.2,0.1-1.7,0.1C399.1,496.3,399.1,496.1,399.1,496z"/>
						<path fill="#E9E4B6" d="M405.3,400.1c-0.4,0.6,0.5,2.4-1.1,2.3c-1.7-0.1-0.5-1.7-0.7-2.6c-0.1-0.4,0-0.8,0.2-1.2
							c0.2-0.8-0.1-2.2,1.1-2.1c0.9,0.1,0.7,1.5,0.7,2.4C405.4,399.3,405.4,399.6,405.3,400.1z"/>
						<path fill="#FBEFB2" d="M403.3,394.2c0.3-0.3,0.6-0.3,0.8,0.1C403.8,394.3,403.6,394.3,403.3,394.2z"/>
						<path fill="#B7AF8B" d="M333.5,384c0.1-1.6,0.2-3.2,0.2-4.8c0-1.3,0.7-1.6,1.9-1.5c1.2,0.1,2.1,0.3,1.9,1.8
							c-0.2,1.5,0.6,3.7-2.4,3.2C334.3,382.6,334.3,383.7,333.5,384z"/>
						<path fill="#14653A" d="M393.5,425.4c-0.1,0.7-0.2,1.4-0.2,2.1c-0.3,3.2-0.3,3.1-3.5,2.8c-1.8-0.1-2.3-0.8-2-2.5
							c0.4-2.3,0.5-4.6,0.9-6.9c0.2-1.1,0.5-1.6,1.8-1.4c4,0.5,4-0.2,3.3,4C393.6,424.1,393.6,424.7,393.5,425.4z"/>
						<path fill="#FBEFB2" d="M392.3,417.2c-0.2,0.6,0.1,1.6-1,1.5c-1.1-0.1-0.8-1-0.7-1.6c0.1-0.7-0.1-1.8,1.1-1.6
							C392.7,415.7,392.2,416.6,392.3,417.2z"/>
						<path fill="#CEC69D" d="M378.3,401.3c-1-1.6-0.5-3-0.2-4.3c0.1-0.6,0.9-1,1.5-0.8c0.9,0.3,0.4,1,0.1,1.6
							C379.2,398.8,378.6,399.8,378.3,401.3z"/>
						<path fill="#CCDEC7" d="M378.3,395.4c0.2-1-0.2-2,0.7-2.7c0.2-0.2,0.6,0.1,0.6,0.4C379.5,394,380,395.2,378.3,395.4z"/>
						<path fill="#CEC69D" d="M367.8,401c-0.2,0.2-0.3,0.2-0.4,0C367.6,400.9,367.7,400.9,367.8,401z"/>
						<path fill="#14653A" d="M391.2,410.2c0.1-0.4,0.1-1.1,0.3-1.2c2.4-0.6,0.9-2,0.6-3.1c-0.2-0.7,0.3-0.8,0.6-1.2
							c0.5-0.5,1-0.4,1.6-0.2c0.6,0.2,0.5,0.8,0.4,1.3c-0.6,1.6-0.3,3.3-0.9,4.9c-0.2,0.7,0.4,2.1-0.9,1.9
							C391.8,412.5,390.7,411.7,391.2,410.2z"/>
						<path fill="#E9E4B6" d="M341.4,443.4c0,0.1,0,0.3,0,0.4c-2.4,0.5-2.6,0.1-1.9-2.9c0.4,0.1,0.8,0.1,1.2,0.2l0.1,0.1
							c0.1,0.2,0.1,0.3,0.2,0.5C341,442.4,340.6,443,341.4,443.4z"/>
						<path fill="#888158" d="M340.8,441.2c-0.4-0.1-0.8-0.1-1.2-0.2c0.2-0.3,0.3-0.7,0.1-1.1c0.5-0.2,0.8,0.2,1.1,0.5
							C340.8,440.6,340.8,440.9,340.8,441.2z"/>
						<path fill="#14653A" d="M348.8,438.2c-1.3,0-2.6-0.6-3.9,0.2c-0.4,0.2-1.1,0-1.6-0.1c-0.5-0.1-0.5-0.7-0.3-1.1
							c0.2-0.4,0.5-0.3,0.8,0c0.9,0.6,0.7-0.4,1-0.7c0.3-0.2,0.7-0.5,0.7-0.7c-0.2-2.9,1.9-3,3.9-3.1c-0.1,0.7-0.1,1.4-0.2,2.1
							c-1.5,0.8-1.3,1.8-0.3,2.9C348.8,437.9,348.8,438.1,348.8,438.2z"/>
						<path fill="#32613E" d="M348.8,437.8c-1-1.1-1.2-2.1,0.3-2.9C349,435.8,348.9,436.8,348.8,437.8z"/>
						<path fill="#32613E" d="M340.8,441.2c0-0.3,0-0.6,0.1-0.8c0.6-0.5,1.3-0.7,1.8,0.1c0.5,0.9,1.3,0.5,2,0.5
							c0.1,0.2,0.3,0.4,0.4,0.7c-0.6,0.3-0.4,0.9-0.5,1.4c-0.6-0.2-1.2-0.3-1.7-0.6c-1.1-0.6-1.3,0-1.3,0.9c-0.8-0.4-0.4-1-0.4-1.6
							C341.1,441.6,341.1,441.4,340.8,441.2L340.8,441.2z"/>
						<path fill="#32613E" d="M351.7,294.6c1.7-0.2,2.5,0.4,2,2.2c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,0-0.8,0.1-1.1,0.4
							c-0.8,0.7-1.1,0.3-1-0.6C351.4,297.2,351.6,295.9,351.7,294.6z"/>
						<path fill="#CEC69D" d="M408.7,471.7c0-0.4,0.1-0.9,0.1-1.3c0.2-0.8,0.2-2,1.2-1.8c0.9,0.1,0.9,1.3,0.9,2.2
							c0,0.8-0.1,1.6-0.2,2.3c-0.1,0.8-0.3,1.6-1.3,1.5c-1-0.1-0.9-0.9-0.8-1.6C408.6,472.6,408.7,472.1,408.7,471.7z"/>
						<path fill="#14653A" d="M400.5,347.5c0.6,0.1,1.1,0.1,1.7,0.2c1.3,0.1,2.3,0.2,2,2c-0.2,1.9-1.1,2-2.5,1.7
							c-1.2-0.2-2.4-0.3-3.6-0.4c-1.4,0-1.9-0.5-1.8-2.1c0.2-1.5,0.6-2,2-1.7C399,347.5,399.8,347.5,400.5,347.5z"/>
						<path fill="#E9E4B6" d="M385.7,353.8c-0.1-0.3-0.3-0.5-0.2-0.6c0-0.1,0.3-0.2,0.4-0.4c0.1,0.2,0.2,0.3,0.2,0.5
							C386.1,353.5,385.9,353.6,385.7,353.8z"/>
						<path fill="#B7AF8B" d="M389.8,365.9c0.2-3.1-0.9-4.5-3.3-4.4c-1.1,0-1.7,0.8-2.5,1.5c-0.8-2-0.4-2.5,1.4-2.3
							c0.4,0,0.7,0.1,1,0.2c4.7,0.7,4.7,0.7,3.7,5.6c-0.1-0.1-0.2-0.1-0.2-0.2C390,366.2,389.9,366,389.8,365.9z"/>
						<path fill="#CEC69D" d="M389.3,368.3c-1.7-0.1-3.4-0.3-5.1-0.4c-2.3-0.2-0.8-1.7-0.9-2.7c0.6,0.9,1.3,1.8,2.3,2.3
							c1.1,0.6,2-0.2,3.1-0.3C388.6,367.7,389.1,367.9,389.3,368.3z"/>
						<path fill="#CEC69D" d="M386.9,363.4c0.6,0.1,1,0.4,1,1.1c0,0.7-0.5,1.2-1.3,1.1c-0.6-0.1-1-0.5-1-1.2
							C385.8,363.8,386.2,363.4,386.9,363.4z"/>
						<path fill="#B7AF8B" d="M389.3,368.3c-0.2-0.4-0.8-0.6-0.7-1.1c0,0,0.1-0.1,0.1-0.1c0.2,0,0.3,0,0.5,0
							c-0.1-0.3,0.1-0.5,0.3-0.7c0.1,0,0.2,0,0.3-0.1c0.1,0.1,0.1,0.1,0.2,0.2C390,367.1,390.3,368,389.3,368.3z"/>
						<path fill="#CEC69D" d="M389.3,367.1c-0.2,0-0.3,0-0.5,0C389,366.8,389.1,366.7,389.3,367.1z"/>
						<path fill="#CEC69D" d="M390,366.3c-0.1,0-0.2,0-0.3,0.1c0.1-0.1,0.1-0.3,0.2-0.4C389.9,366,390,366.2,390,366.3z"/>
						<path fill="#B7AF8B" d="M242.9,445.3c-1-1.3-1.3-2.9-1.8-4.4c-0.2-0.6-0.1-1.1,0.4-1.5c0.2-0.2,0.5-0.2,0.8,0
							c0.4,0.3,0.6,0.8,0.4,1.3c-0.5,1.6,0.6,2.8,0.9,4.2C243.3,445,243.1,445.1,242.9,445.3z"/>
						<path fill="#888158" d="M248.7,439c-0.1,2.8-0.2,5.5-0.9,8.2C247.9,444.5,248.1,441.7,248.7,439z"/>
						<path fill="#CEC69D" d="M242.9,445.3c0.2-0.2,0.4-0.3,0.6-0.5c0.2,0.1,0.4,0.1,0.6,0.2c0.5,0.3,1.2,0.5,1.2,1.3
							c0,0.2-0.2,0.4-0.4,0.4c-0.7,0.2-1.1-0.3-1.5-0.8C243.3,445.7,243.1,445.5,242.9,445.3z"/>
						<path fill="#32613E" d="M206.3,448.5c0-0.3,0.1-0.6,0.1-1c8.4,0.9,16.9,1.8,25.3,2.7c0,0.3,0.1,0.6,0.1,0.9c0,0-0.1,0-0.1,0
							c-0.2,0-0.4,0-0.5,0.1C222.9,450.2,214.6,449.4,206.3,448.5z"/>
						<path fill="#888158" d="M231.8,451c0-0.3-0.1-0.6-0.1-0.9c0.4-0.1,0.7,0,0.7,0.5C232.4,450.9,232.1,451.1,231.8,451z"/>
						<path fill="#888158" d="M231.2,451.1c0.2,0,0.4,0,0.5-0.1C231.6,451.3,231.4,451.3,231.2,451.1z"/>
						<path fill="#F5EBB0" d="M221.6,440.9c-0.7,0.1-1.4,0-2.1-0.3c0.3-1.2,1.2-0.8,1.9-0.7C222.2,440.1,221.7,440.6,221.6,440.9z"
							/>
						<path fill="#F5EBB0" d="M271.4,428.6c0.8-0.8,0.8,0.5,1.2,0.6c-0.1,0.3-0.2,0.6-0.3,1C271.6,429.9,271.4,429.3,271.4,428.6z"
							/>
						<path fill="#888158" d="M238.1,423.4c-0.4-0.3-0.7-0.7-1.1-0.9c-0.5-0.2-1.4,1.3-1.5,0.1c-0.1-0.7-0.4-1.9,0.4-2.7
							c0.7-0.6,1.5-0.5,2.2-0.2c1.6,0.7,0.3,2.1,0.6,3.1C238.5,423.1,238.3,423.2,238.1,423.4z"/>
						<path fill="#32613E" d="M251.3,424.6c0.1-0.7,0.2-1.4,0.2-2.1c0-0.3,0-0.7,0.4-0.9c0.4-0.1,0.7,0,0.8,0.5c0,0.2,0,0.5,0.5,0.4
							c1.6-0.3,1.9-0.1,1.6,1.5c-0.2,1.2-0.3,1.3-1.3,0.4c-0.9-0.8-1.1,0.4-1.8,0.4C251.6,424.7,251.5,424.6,251.3,424.6z"/>
						<path fill="#32613E" d="M246.9,424.7c0.1-0.9,0.4-1.8,0.4-2.7c0.1-1.3,0.9-0.9,1.7-0.9c1.2-0.1,0.9,0.7,0.8,1.3
							c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.4-0.7-0.5-0.9,0.1c-0.2,0.5-0.4,1.1,0.6,1c0.3,0.1,0.5,0.3,0.8,0.4c0.1,0.5-0.3,0.7-0.5,1l0,0
							c-0.6,0-1.4-0.3-1.6,0.7C247.3,425.6,247,425.2,246.9,424.7z"/>
						<path fill="#3E3821" d="M243.2,420.6c3.1-0.2,3.1-0.2,2.8,3.2c-1.5,0.3-0.8-0.8-0.9-1.4c0-0.5,0-0.9-0.6-0.9
							c-0.4,0-0.5,0.4-0.5,0.8c-0.1,0.5,0.1,1.4-0.6,1.3c-0.9-0.1-0.4-1-0.4-1.5C243,421.5,243.1,421,243.2,420.6z"/>
						<path fill="#E9E4B6" d="M235.4,427.6c-0.3-0.5-0.8-0.4-1.3-0.4l-0.2,0l-0.1-0.1c0-0.3,0.1-0.6,0.1-0.9
							c0.5-0.8,1.1-0.6,1.8-0.3c1.1,0.5,1.9,1.4,2.7,2.3c0.7,0.8,0.5,1.4-0.7,1.4C237.2,428.6,236.2,428.3,235.4,427.6z"/>
						<path fill="#F5F0BC" d="M262.8,431.1c-0.2-0.3-0.4-0.5-0.5-0.8c0.6-0.7,1.2-0.5,1.7,0.2C263.7,430.8,263.3,431.1,262.8,431.1z
							"/>
						<path fill="#F5F0BC" d="M247.9,425.6c0.2-1,0.9-0.7,1.6-0.7C249.3,426,248.6,425.7,247.9,425.6z"/>
						<path fill="#F5F0BC" d="M233.9,426.3c0,0.3-0.1,0.6-0.1,0.9c-0.3,0-0.6-0.1-0.9-0.1c0-0.3,0.1-0.6,0.1-0.9c0.1,0,0.3,0,0.4,0
							C233.6,426.2,233.8,426.3,233.9,426.3z"/>
						<path fill="#E9E4B6" d="M233.5,426.2c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.2,0-0.3C233.2,426,233.3,426.1,233.5,426.2z"/>
						<path fill="#E9E4B6" d="M219.6,421.6c0.2,0.1,0.5,0.1,0.7,0.2C220,422.1,219.6,422.4,219.6,421.6z"/>
						<path fill="#F5F0BC" d="M227.4,422.5c0.2,0,0.3,0.1,0.5,0.1C227.7,422.9,227.5,422.8,227.4,422.5z"/>
						<path fill="#F5F0BC" d="M265.2,430.5l-0.2,0c0,0-0.2,0-0.2,0C265,430.2,265.1,430.2,265.2,430.5z"/>
						<path fill="#888158" d="M261.1,424.2c0-1.2,0.2-2,1.7-1.7c1.2,0.2,1.8,0.6,1.7,2.1c-0.1,1.5-0.9,1.8-2.1,1.7
							C261.1,426.1,260.8,425.3,261.1,424.2z"/>
						<path fill="#3E3821" d="M269.1,425.6c0.2-0.9-0.3-2.3,1.6-1.9c0.9,0.2,2.1-0.1,2,1.5c-0.1,0.6-2.8,2.8-3.2,2.5
							C268.6,427.2,269.2,426.4,269.1,425.6z"/>
						<path fill="#888158" d="M268.3,425.1c0.2,1.5-0.8,1.5-1.8,1.5c-0.8,0-1.7-0.9-1.3-1.2c0.9-0.7-0.3-2.7,1.7-2.6
							C268.3,423,268.6,423.9,268.3,425.1z"/>
						<path fill="#888158" d="M260.2,424.5c0.2,1.6-1.2,1.2-2,1.2c-0.8,0-1.6-0.6-1.2-1.3c0.5-0.8-0.1-2.6,1.8-2.4
							C260.2,422.2,260.3,423.2,260.2,424.5z"/>
						<path fill="#32613E" d="M273.6,425.7c0.1-1.5,0.2-1.5,1.3-0.9c0.2,0.1,0.3,0.1,0.5,0c0.5-0.3,1-1,1.4-0.4
							c0.5,0.7,0.1,1.6-0.1,2.4c-0.1,0.5-0.5,0.6-0.9,0.2c-0.2-0.3-0.3-0.8-0.9-0.3C273,428,273.7,426.3,273.6,425.7z"/>
						<path fill="#32613E" d="M281.1,424.6c0,0.7,0,1.2-0.1,1.6c-0.1,0.5,0.1,1.8-0.7,1.4c-0.9-0.5-2,0.5-2.4-0.5
							c-0.3-0.8-0.1-1.7,0.3-2.5c0.1-0.3,0.6-0.3,0.7,0C279.5,426.3,280.2,424.8,281.1,424.6z"/>
						<path fill="#888158" d="M233.2,421.9c-0.7-0.2-1.2,1.4-1.9,0.3c-0.5-0.8-0.4-2,0.2-2.6c1.2-1.3,0.8,0.9,1.6,0.9
							C233,421,234.1,421.3,233.2,421.9z"/>
						<path fill="#3E3821" d="M233.2,421.9c0.9-0.6-0.2-0.9-0.2-1.4c0.3-0.5,0.8-1.4,1.4-0.7c0.6,0.7,0.4,2-0.2,2.7
							C233.8,422.9,233,422.9,233.2,421.9z"/>
						<path fill="#B7AF8B" d="M250,423.8c-0.3-0.1-0.5-0.3-0.8-0.4c0.1-0.4,0.2-0.7,0.3-1.1c0.1,0,0.2,0,0.3,0.1
							c0.4,0.1,0.9,0,1,0.4C250.9,423.4,250.1,423.4,250,423.8z"/>
						<path fill="#CEC69D" d="M208.6,419.3c-0.4-0.1-0.8-0.1-1.2-0.2c0.2-1-0.2-2.7,1-2.6C209.5,416.7,208.7,418.3,208.6,419.3z"/>
						<path fill="#B7AF8B" d="M268.3,471.8c-4.2,0-8.3-1-12.5-1.4c-0.6-0.1-1.7,0.2-1.5-0.9c0.1-0.9,1-0.5,1.6-0.4
							c4.9,0.4,9.8,1.2,14.8,1.2c0.5,0,0.9,0.3,1.4,0.4c0.4,0.1,0.8,0.4,0.6,0.9c-0.1,0.4-0.5,0.4-0.9,0.3
							C270.6,471.8,269.4,472.1,268.3,471.8z"/>
						<path fill="#D02826" d="M241.3,473.5c0.3,0.2,0.9,0.2,0.8,0.8c-0.4,1.9-3.5,3.5-5.4,2.9c-0.9-0.3-0.7-0.7-0.2-1.2
							c0.9-0.9,1.8-1.7,2.7-2.6C239.9,473.4,240.6,473.5,241.3,473.5z"/>
						<path fill="#D02826" d="M243.4,477.6c2-3.1,3-3.7,5.2-3.1c0.9,0.3,1,0.5,0.4,1.3C247.5,477.9,246,478.5,243.4,477.6z"/>
						<path fill="#F5F0BC" d="M241.3,473.5c-0.7,0-1.4-0.1-2.1-0.1c-1-1.4-3-2.1-2.6-4.3c0.2,0,0.3,0,0.5,0c0.9,0.5,1.1,1.7,2.2,1.9
							c0.3,0.1,0.6,0.2,0.8,0.3c1.8,0.5,3.3-0.4,4.9-0.9c-0.2,1.2-1,1.7-2.1,1.9C242.2,472.4,241.4,472.5,241.3,473.5z"/>
						<path fill="#51727B" d="M245,470.4c-1.6,0.4-3.1,1.3-4.9,0.9c0.7-0.5,2.1-0.2,2.1-1.2c-0.1-2,1.6-3.4,1.6-5.4
							c0.1-0.1,0.2-0.1,0.2-0.2c1.1,1.1,0.9,2.8,1.5,4.1C245.4,469.3,245.7,470,245,470.4z"/>
						<path fill="#888158" d="M213.6,467.4c-1.9-0.4-3.8-1-5.8-0.9c-0.5,0-1.1,0-1.2-0.6c-0.1-0.7,0.5-1,1.1-1
							c2,0.1,4.1,0.2,6.1,0.3C215.2,466.1,213.8,466.7,213.6,467.4z"/>
						<path fill="#B7AF8B" d="M213.6,467.4c0.2-0.7,1.6-1.2,0.2-2.1c1.7-0.2,3.4,0.7,5.1,0.3c0.5-0.1,1.2,0.4,1.1,1
							c-0.1,0.8-0.7,1.5-1.4,1.1C217,467,215.3,467.8,213.6,467.4z"/>
						<path fill="#F5F0BC" d="M268.3,476.6c-0.1,1.6,0.9,1.8,1.2,2.6c-1.6,0.9-3.4-0.9-4.9,0.6c-0.1,0.1-1.9-0.9-2-1.6
							c-0.2-1.1,0.9-1.1,1.8-1c0.8,0,2-1.1,1.7,0.9c-0.1,0.5,0.5,0.7,0.9,0.6C268,478.6,267.7,477.7,268.3,476.6z M264.5,477.6
							c-0.2,0.2-0.6,0.3-0.6,0.5c-0.1,0.4,0.3,0.4,0.5,0.4c0.3,0,0.6,0,0.6-0.4C265,477.9,264.7,477.8,264.5,477.6z"/>
						<path fill="#888158" d="M258.6,472.3c0.4,0,0.8-0.1,1,0c0.6,0.3,1.9-0.3,1.7,0.9c-0.1,0.9-1.3,0.7-2,0.6
							c-1.1-0.1-2.1-0.7-3.3-0.4c-0.5,0.2-1-0.4-1-0.8c0-0.6,0.7-0.7,1.1-0.7C256.9,472,257.7,472.2,258.6,472.3
							C258.6,472.3,258.6,472.3,258.6,472.3z"/>
						<path fill="#888158" d="M273.2,475.1c-1.8,1-3.3-0.6-5-0.6c-0.4,0-0.6-0.4-0.5-0.8c0.2-0.4,0.6-0.3,0.9-0.3
							c1.7,0.1,3.5,0.3,5.2,0.5C273.7,474.3,273.5,474.7,273.2,475.1z"/>
						<path fill="#D02826" d="M224.5,475.5c0.8-1.3-0.6-3.1,1.7-3.3c0.4,0,1.1-0.2,1.3,0.4c0.2,0.5-0.2,0.8-0.5,1.1
							C226.4,474.3,226,475.2,224.5,475.5z"/>
						<path fill="#32613E" d="M245.6,468.7c-0.6-1.4-0.3-3-1.5-4.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0.1-0.4-0.6-1,0.2-1.4
							c1.8,1.6,2.6,3.4,1.8,5.8C245.8,468.4,245.7,468.6,245.6,468.7z"/>
						<path fill="#F5F0BC" d="M276.6,467.7c0.6-0.8,2.4,0.6,2.2-1.3c0.6-0.7,1.1-0.4,1.6,0.1c0.3,0.4,0.6,0.8,0.4,1.3
							c-0.3,0.6-0.9,0.7-1.4,0.6C278.4,468.3,277.3,468.6,276.6,467.7z"/>
						<path fill="#B7AF8B" d="M273.2,475.1c0.2-0.4,0.4-0.8,0.6-1.2c1.2-0.7,2.1,0.4,3.2,0.5c0.5,0,0.6,0.5,0.4,1
							c-0.2,0.6-0.8,0.8-1.2,0.5C275.3,475.3,274.3,475.3,273.2,475.1z"/>
						<path fill="#CEC69D" d="M202.7,464.7c-0.9-0.3-2.4,0.2-2.6-0.7c-0.3-0.9,1.3-1.2,1.9-1.9c0.4-0.5,0.8-0.9,1.2-1.4
							C203,462,202.9,463.3,202.7,464.7z"/>
						<path fill="#888158" d="M263.7,472.8c0.1,0,0.3-0.1,0.4,0c0.8,0.3,1.7-0.2,2.3,0.6c0.2,0.2,0.1,0.7-0.2,0.7
							c-0.9,0.2-1.9,0.3-2.7-0.4C263.5,473.4,263.6,473.1,263.7,472.8z"/>
						<path fill="#F5F0BC" d="M255.4,465.5c1,0.1,2,0.2,3,0.2C257.1,467,255.8,466.9,255.4,465.5z"/>
						<path fill="#F5F0BC" d="M262.3,465.8c0.4,0.2,0.8,0.4,1.2,0.6c-0.9,0.6-1.8,0.5-2.5-0.2C261.4,466,261.8,465.9,262.3,465.8z"
							/>
						<path fill="#B7AF8B" d="M263.7,472.8c-0.1,0.3-0.1,0.6-0.2,0.9c-0.5-0.1-1.3,0-1.1-0.8C262.6,472,263.2,472.6,263.7,472.8z"/>
						<path fill="#F5F0BC" d="M270.9,479.3c0.7-0.2,1.1-0.2,1.5,0.1C271.9,479.8,271.5,479.7,270.9,479.3z"/>
						<path fill="#F5F0BC" d="M271.5,467.3c0.6,0.1,1.3-0.1,1.7,0.5C272.7,467.4,271.8,468.4,271.5,467.3z"/>
						<path fill="#F5F0BC" d="M274.4,467.9c0.3-0.4,0.4-1,1-0.3C275.1,468,274.7,468,274.4,467.9z"/>
						<path fill="#CEC69D" d="M271.1,477.7c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.2-0.2,0.3c0-0.1-0.1-0.2-0.1-0.4
							C270.9,477.9,271,477.8,271.1,477.7z"/>
						<path fill="#F5F0BC" d="M269.8,467.1c0.1,0,0.3,0,0.4,0.1C270.1,467.3,270,467.3,269.8,467.1z"/>
						<path fill="#0A4E98" d="M239.3,471c-1.1-0.2-1.4-1.4-2.2-1.9c-0.9-2.5-0.9-4.9,1.6-6.6c0.4,0.1,1.1-0.2,0.6,0.7
							c-0.6,1-0.9,2.1,0,3.1C240.1,467.8,239.8,469.4,239.3,471z"/>
						<path fill="#51727B" d="M239.3,466.3c-1-1-0.6-2.1,0-3.1c0.6-0.9-0.2-0.7-0.6-0.7c1.8-1.4,3.7-1.4,5.5,0
							c-0.8,0.4-0.1,0.9-0.2,1.4c-0.1,0-0.1,0-0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0-0.2,0.1-0.2,0.2
							C242.1,464.5,240.1,463.7,239.3,466.3z"/>
						<path fill="#F5F0BC" d="M219.6,483.9c0,0.1,0,0.3,0,0.4c-1.1,0.9-2.1,1.9-3.9,1.5c-0.9-0.2,0.6,1,0.1,1.6
							c-0.7,0.2-1,1.7-1.7,1c-0.9-0.7-1.8-0.7-2.6-0.5c-1.7,0.2-3.4-1.4-4.9,0.2l-0.1,0c-0.4-0.5-0.7-1-1.4-1.2
							c0-0.2-0.2-0.5-0.1-0.6c1.8-1.2,1.7-2.3-0.3-3.3c-0.1,0,0-0.3,0-0.4c2.6-0.8,5.1,0.6,7.6,0.3c0.5,0.5,0.9,1.9,1.8,0.3
							c0.4-0.6,1.1-0.2,1.7-0.4C216.9,484.1,218.2,484,219.6,483.9z"/>
						<path fill="#F5F0BC" d="M216.1,487.8c0.2-0.2,0.5-0.5,0.7-0.4c1.6,0.7,1.7,2.3,1.9,3.7c0.2,1.2-1.1,1.5-1.9,1.5
							c-1.9-0.1-3.9-0.3-5.8-0.7c-0.9-0.2-1.7-0.8-1.4-2c1.6,0.3,3.2-0.1,4.7,0c0.6,0,1.4,0.8,1.9,0.1
							C216.7,489.4,216.5,488.6,216.1,487.8z"/>
						<path fill="#888158" d="M216.1,487.8c0.4,0.7,0.6,1.6,0.1,2.2c-0.5,0.6-1.3-0.1-1.9-0.1c-1.6-0.1-3.2,0.2-4.7,0
							c-0.9-0.8-2.9,0.2-3-1.8c1.6-1.6,3.3,0.1,4.9-0.2c0.7-0.1,1.7-0.2,2.6,0.5c0.7,0.6,1.1-0.8,1.7-1
							C215.9,487.6,216,487.7,216.1,487.8z"/>
						<path fill="#F5F0BC" d="M233.3,492.3c-0.2,0.4-0.4,0.3-0.6-0.1C232.9,492.2,233.1,492.3,233.3,492.3z"/>
						<path fill="#F5F0BC" d="M251.6,493.5c0.8,0.3,1.6,0.6,2.4,1c-0.1,1.2-0.7,1.7-1.9,1.5c0-0.6-0.2-0.9-0.8-0.9
							C251.5,494.5,251.6,494,251.6,493.5z"/>
						<path fill="#CEC69D" d="M260.3,502.9c-0.1-0.1-0.4-0.2-0.4-0.3c0-0.3,0.3-0.4,0.6-0.4c0.3,0,0.6,0.1,0.5,0.4
							C261,502.9,260.7,502.9,260.3,502.9z"/>
						<path fill="#B7AF8B" d="M260.9,494.4c0.6-0.6,0.7-0.1,0.9,0.4C261.4,494.8,261.1,494.7,260.9,494.4z"/>
						<path fill="#F5F0BC" d="M254.7,488.5c-0.2,0.1-0.3,0.2-0.5,0.3C254.1,488.3,254.5,488.6,254.7,488.5L254.7,488.5z"/>
						<path fill="#CEC69D" d="M242.4,498.9c-0.9-0.1-2-0.2-3-0.3c-0.5-0.1-1.1-0.2-1-0.8c0-0.6,0.6-0.7,1.1-0.7
							c1,0.1,2.1,0.2,3.1,0.3c0.7-0.7-0.9-1.4,0.1-1.9c0.9-0.4,1.4,0.4,1.9,1c1.7,1.9,1.6,2.3-0.6,3.9c-0.6,0.4-1.3,0.9-1.9,0.4
							C241.1,500.1,242.9,499.9,242.4,498.9z"/>
						<path fill="#CEC69D" d="M235.9,497.6c-0.2,0.8-0.3,1.7-0.6,2.9c-0.5-1.2-0.9-2.1-1.3-3c0-0.4,0.1-0.8,0.6-0.9
							c0.4,0.2,0.7,0.4,1.1,0.5C235.7,497.3,235.8,497.5,235.9,497.6z"/>
						<path fill="#F5F0BC" d="M267,489.9c1.3-1,0.2-1.9-0.4-3.1c1.7,0.6,2.8,1.4,3.5,3.2C268.8,489.5,267.9,490.3,267,489.9
							L267,489.9z"/>
						<path fill="#F5F0BC" d="M269.8,491.5c0-0.1,0.1-0.1,0.1-0.2C269.9,491.4,269.8,491.4,269.8,491.5L269.8,491.5z"/>
						<path fill="#CEC69D" d="M239.6,489.3c-0.9-0.1-1.5-0.4-1.4-0.9c0.3-1.5-1.7-1-1.8-2.2c0-1.6,2-0.6,2.1-1.9c0-0.7,0.5-1,1.1-1
							c0.7,0,1,0.5,0.9,1.1c0,0.7,0.3,1,1,1.1c0.7,0.1,1.1,0.7,0.8,1.2C241.6,487.9,239.7,487.8,239.6,489.3z"/>
						<path fill="#CCDEC7" d="M231.3,495c0.1-0.5,0.1-1,0.2-1.6c0.8,0.1,2.3-0.3,2.3,0.5C233.8,495.4,232.1,494.5,231.3,495z"/>
						<path fill="#32613E" d="M234.6,496.7c-0.5,0.1-0.5,0.5-0.6,0.9c-0.7,0.3-1.3,0.4-1.3-0.7c0.1-0.3,0.1-0.6,0.2-0.9
							c0.2-0.9,0.8-0.9,1.4-0.7C234.9,495.6,234.9,496.1,234.6,496.7z"/>
						<path fill="#CEC69D" d="M232.8,496c-0.1,0.3-0.1,0.6-0.2,0.9c-0.4,0.5-0.5,1.6-1.6,1.1c0.1-0.5,0.2-1.1,0.2-1.6
							C231.8,496.2,232.3,496.1,232.8,496z"/>
						<path fill="#CCDEC7" d="M233,501.2c-1.1-0.1-2.3-0.1-1.8-1.8c0.8-0.1,1.6-0.7,2.3,0C234.1,500,233.6,500.7,233,501.2z"/>
						<path fill="#CEC69D" d="M260.2,331.3c0.4-0.5,0.8-0.9,1.2-1.3c-0.3-0.2-0.5-0.3-0.6-0.3c-0.6-0.1-1.1-0.1-1.7-0.2
							c-0.8-0.1-2,0.1-1.9-1.1c0.1-1.3,1.3-0.5,2-0.7c0.7-0.2,2.9,1.4,1.7-1.3c0-0.1,0.2-0.3,0.4-0.4c0.7-0.1,1.4,0,1.8,0.7
							c0.5,0.9,2.2,1.8,1.5,2.7c-0.9,1.2-2,2.9-4.1,2.2C260.4,331.6,260.4,331.5,260.2,331.3z"/>
						<path fill="#CEC69D" d="M254.5,330.8c-0.2,0.2-0.6,0.7-0.8,0.3c-0.3-0.8-0.5-1.7-0.6-2.6c-0.1-1.4,0.9-0.6,1.4-0.6
							c0,0.4,0.1,0.9,0.1,1.3c-0.1,0.1-0.1,0.2,0,0.3C254.6,329.9,254.6,330.3,254.5,330.8z"/>
						<path fill="#B7AF8B" d="M254.8,326.9c-1.6-1-1.1-1.8,0.3-2.5C255.3,325.3,255.2,326.1,254.8,326.9z"/>
						<path fill="#B7AF8B" d="M257.4,323.7c0.6-0.4,1.2-0.3,1.7,0.2C258.5,324,258,323.9,257.4,323.7z"/>
						<path fill="#B7AF8B" d="M263.3,324.3c0.5-0.4,0.9-0.3,1.3,0.2C264.2,324.6,263.7,324.5,263.3,324.3z"/>
						<path fill="#B7AF8B" d="M260.8,324c0.5-0.3,0.9-0.3,1.3,0.1C261.6,324.3,261.2,324.3,260.8,324z"/>
						<path fill="#888158" d="M254.6,329.4c-0.1-0.1,0-0.2,0-0.3c0,0.1,0.1,0.2,0.1,0.2C254.7,329.4,254.7,329.4,254.6,329.4z"/>
						<path fill="#888158" d="M266.5,326.5c0.1,0.1,0.1,0.2,0,0.4c0-0.1-0.1-0.2-0.1-0.3C266.4,326.5,266.4,326.5,266.5,326.5z"/>
						<path fill="#888158" d="M265.4,324.7c0.1,0,0.3,0.1,0.4,0.1C265.7,325,265.5,325,265.4,324.7z"/>
						<path fill="#14653A" d="M248.4,324.5c1.2,0.1,0.8,1.3,1.4,1.8c0,0.3-0.1,0.6-0.1,0.9c-0.5,0.3-0.4,0.8-0.4,1.3
							c0,0.1,0,0.2-0.1,0.3c-0.6,0-0.8-0.6-1.1-1C248.2,326.8,248.3,325.7,248.4,324.5z"/>
						<path fill="#CCDEC7" d="M252.8,325.1c-0.6,0.5-1.4,0.3-2.1,0.3c-0.4,0-0.3-0.6-0.2-0.9c0.5-0.8,1.2-0.6,1.9-0.4
							C252.9,324.3,253,324.6,252.8,325.1z"/>
						<path fill="#32613E" d="M248,327.9c0.3,0.4,0.5,1,1.1,1c0,0.4,0,0.8,0,1.2c-0.1,0.6-0.6,0.5-0.9,0.5c-0.5,0-0.5-0.3-0.5-0.7
							C247.8,329.3,247.9,328.6,248,327.9z"/>
						<path fill="#32613E" d="M249.3,328.6c0-0.5-0.1-1,0.4-1.3C249.7,327.8,249.7,328.2,249.3,328.6z"/>
						<path fill="#888158" d="M293.3,351.7c-0.1,0.4-0.4,0.6-0.7,0.6c-0.4,0.1-0.7-0.3-0.6-0.6c0.1-0.3,0.4-0.6,0.8-0.5
							C293.1,351.2,293.4,351.3,293.3,351.7z"/>
						<path fill="#F5EBB0" d="M282.1,360.3c-0.1,0.2-0.2,0.2-0.3,0C281.9,360.3,282,360.3,282.1,360.3z"/>
						<path fill="#F5F0BC" d="M290.5,351c-0.1-0.2-0.3-0.3-0.4-0.5C290.2,350.6,290.3,350.8,290.5,351z"/>
						<path fill="#E9E4B6" d="M255.5,354c-0.1,1.1-0.1,1.6-0.2,2.1c-0.1,0.5-0.3,0.9-1,0.8c-0.6-0.1-0.9-0.4-0.9-1
							c0.1-1.1,0.2-2.1,0.4-3.2c0.1-0.5,0.3-1.3,1-1.2c0.8,0.1,0.8,0.9,0.8,1.5C255.6,353.6,255.5,354.1,255.5,354z"/>
						<path fill="#CEC69D" d="M224.7,278.9c-0.1,0.6,0,1.4-1,1.3c-0.7-0.1-0.6-0.6-0.5-1.1c0.2-0.6,0-1.7,0.9-1.6
							C225,277.5,224.6,278.4,224.7,278.9z"/>
						<path fill="#32613E" d="M293.1,287.3c0.6-0.3,0.4,0.6,0.8,0.6c0.1,0.8-0.5,0.7-1,0.7c0-0.3,0-0.5,0.1-0.8
							C293,287.6,293,287.5,293.1,287.3z"/>
						<path fill="#888158" d="M293,287.8c0,0.3,0,0.5-0.1,0.8c0,0-0.2,0.1-0.2,0.1l-0.2,0C292.5,288.2,292.7,287.9,293,287.8z"/>
						<path fill="#F5F0BC" d="M289.3,295.1c0.1-0.1,0.2-0.3,0.3-0.4C289.6,294.9,289.7,295.2,289.3,295.1z"/>
						<path fill="#F5EBB0" d="M270.8,291.7c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.1-0.3,0-0.4c0-0.1,0.2-0.1,0.3-0.1
							C270.8,291.5,270.8,291.6,270.8,291.7z"/>
						<path fill="#D02826" d="M235.5,311.9c-3.8-0.4-7.6-0.8-11.4-1.2c-0.6-0.1-1.6,0.2-1.5-0.7c0.1-0.7,0.9-0.4,1.4-0.3
							c7.6,0.7,15.2,1.5,22.9,2.3c0.3,0,0.6,0,0.8,0.1c0.4,0.1,1,0.1,0.9,0.6c0,0.7-0.7,0.6-1.1,0.5
							C243.6,312.7,239.5,312.3,235.5,311.9C235.5,311.9,235.5,311.9,235.5,311.9z"/>
						<path fill="#D02826" d="M261.9,301c0.6,0.1,1.2,0.1,1.8,0.2c0.8,1.1,0.6,7.9-0.2,8.7c-0.4,0.4-0.9,0.3-1.4,0.2
							c-0.3-0.1-0.6-0.3-0.4-0.6c1.4-1.8-0.3-1.7-1.2-2c-0.7-0.2-0.9-0.8-1.3-1.3c-1.4-2,0.6-3.6,0.7-5.4c0.5,0,0.9,0.1,1.4,0.1
							C261.4,300.9,261.7,301,261.9,301z"/>
						<path fill="#888158" d="M225.4,298.5c0.6-0.9,1.3-1.5,2.4-0.7c-0.1,0.6-1.1,0.3-1,1.1c1,0.8,1.7-1.1,2.6-0.4
							c1.3,0.9,2.4,0.3,3.5-0.3c2.6,1.1,5.3,1.3,8.1,1.2c0.3,0,0.8,0.1,1,0.4c0.5,0.5,1.7,0.6,1.3,1.6c-0.4,0.8-1.2,0.4-1.9,0.1
							c-0.4-0.2-1-0.2-1.4-0.3c-0.1-0.2-0.1-0.5-0.2-0.5c-0.3-0.1-0.3,0.3-0.4,0.5l0,0c-1-0.3-2,0.4-3.1,0.1c-2.2-0.7-4.6-0.5-6.8-1
							C228,300,225.6,301.3,225.4,298.5z"/>
						<path fill="#E9E4B6" d="M225.4,298.5c0.2,2.8,2.6,1.5,3.9,1.8c2.2,0.5,4.6,0.3,6.8,1c1.1,0.3,2.1-0.3,3.1-0.1
							c0,0.5-0.1,0.9-0.1,1.4c-2.4,0.9-4.9-0.6-7.4-0.1c0.3,0.7,0.6,1.3,0.9,1.9c-1.6,0.3-3.3,0.9-4.8-0.4c0.6-0.1,1.3-0.2,1.2-1.1
							c-0.1-0.7-0.6-1.1-1.3-1.1c-1,0.1-1.9-0.6-2.9-0.1C225.3,300.7,223.5,299.3,225.4,298.5z"/>
						<path fill="#CEC69D" d="M259.8,300.8c0,1.8-2.1,3.4-0.7,5.4c0.4,0.5,0.6,1.1,1.3,1.3c0.9,0.3,2.6,0.2,1.2,2
							c-0.2,0.2,0.1,0.5,0.4,0.6c0.5,0.1,1,0.2,1.4-0.2c0.8-0.8,1-7.6,0.2-8.7c1.3-0.5,1.6,0.3,1.5,1.3c-0.2,2.2-0.4,4.4-0.7,6.5
							c-0.2,1.3-1,2-2.4,1.6c-2.4-0.6-5,0.1-7.3-1.3c1.6,0.2,3.2,0.7,5,0.3c-1.5-2.9-4-5.5-1.6-8.9
							C258.7,300.2,259.3,300.3,259.8,300.8z"/>
						<path fill="#888158" d="M232.6,304.3c-0.3-0.6-0.5-1.1-0.9-1.9c2.5-0.5,4.9,1,7.4,0.1c2.7,1.1,5.6,1.2,8.5,1.5
							c-0.4,0.6-0.7,1.2-1.1,1.7c-0.5,0.6-1,1.4-1.2-0.2c-0.1-0.7-0.7-0.3-1-0.3c-2.6,0.2-5.2,0.1-7.7-0.6
							C235.2,304.2,233.9,304.4,232.6,304.3z"/>
						<path fill="#D02826" d="M258.2,300.7c-2.4,3.4,0.1,6,1.6,8.9c-1.8,0.4-3.4-0.1-5-0.3l0,0c0-0.1,0-0.3-0.1-0.4
							c0-0.3,0-0.6,0-0.9c0.3-0.5,0.3-1.1,0.2-1.6c0.1-0.9,0.2-1.7,0.3-2.6c0.2-0.5,0.3-1.1,0.2-1.7c0.1-0.4,0.2-0.9,0.2-1.3
							c0.1-0.1,0.3-0.3,0.4-0.4l0,0c0.2,0,0.3,0,0.5,0C257,300.5,257.6,300.6,258.2,300.7z"/>
						<path fill="#3E3821" d="M218.4,296.5c0.5-0.1,1.1-0.1,1.6-0.2c0.2,2.8-0.3,5.5-0.6,8.2c-0.1,1.4-1.1,1.1-2,0.4
							c0.2-1.5,0.3-2.9,0.6-4.4c0.1-1,0-1.6-1.2-1c-0.3,0.2-0.7,0.2-0.9-0.2c-0.2-0.5,0-0.8,0.4-1.1c0.4-0.3,0.8-0.6,1.2-0.8
							C217.9,297.2,218.3,297,218.4,296.5z"/>
						<path fill="#B7AF8B" d="M281.6,313.9c-3.4,0.9-6.6-1-9.9-0.7c-0.7,0.1-1.2-0.3-1-1c0.3-0.9,0.7,0.1,1.1,0.1c3-0.7,6,0.2,9,0.4
							C281.9,312.8,281.7,313.3,281.6,313.9L281.6,313.9z"/>
						<path fill="#B7AF8B" d="M271.8,303.8c0.5-1.4,1.9-1.1,2.7-1.2c1.9-0.1,3.8,0.3,5.6,0.5c0.4,0,0.7,0.2,0.7,0.6
							c0,0.4-0.3,0.6-0.7,0.6C277.4,304.3,274.5,305.2,271.8,303.8z"/>
						<path fill="#B7AF8B" d="M274,315.7c1,0.1,2,0.2,2.9,0.3c0.5,0.1,1,0.3,0.9,0.9c-0.1,0.6-0.5,0.8-1,0.8c-2-0.2-3.9-0.4-5.9-0.6
							c-0.8-0.1-0.8-0.7-0.8-1.2c0.1-0.9,0.7-0.4,1.1-0.4C272.2,315.5,273.1,315.6,274,315.7C274,315.7,274,315.7,274,315.7z"/>
						<path fill="#E9E4B6" d="M217.4,297.4c-0.4,0.3-0.8,0.6-1.2,0.8c-0.4,0.3-0.6,0.6-0.4,1.1c0.2,0.4,0.6,0.4,0.9,0.2
							c1.2-0.6,1.3,0,1.2,1c-0.2,1.5-0.4,2.9-0.6,4.4c-1.5-0.7-0.4-1.9-0.5-2.8c-0.1-0.7,0.5-1.7-0.8-1.7c-0.7,0-1.6,0.1-1.4-1
							c0.1-1.1-0.3-2.5,0.8-3.1C216.2,295.9,217,296.6,217.4,297.4z"/>
						<path fill="#B7AF8B" d="M281.5,310.5c2.4,0,4.7,0.6,7.1,0.4c0.4,0,0.5,0.4,0.5,0.8c0,0.4-0.2,0.8-0.6,0.7
							c-2.3-0.3-4.8,0-7-1.1C281.5,311.1,281.5,310.8,281.5,310.5z"/>
						<path fill="#B7AF8B" d="M289.3,318.2c-2,0.2-4,0.6-5.9-0.2c-0.5-0.2-1.2-0.3-1-1.1c0.2-0.7,0.9-0.3,1.3-0.2
							c1.9,0.4,4-0.1,5.7,1C289.3,317.9,289.3,318.1,289.3,318.2z"/>
						<path fill="#888158" d="M224.8,301.7c1-0.5,1.9,0.2,2.9,0.1c0.8-0.1,1.2,0.4,1.3,1.1c0.1,0.9-0.6,1-1.2,1.1
							c-0.3-0.1-0.6-0.2-0.8-0.3c-0.1-0.1-0.2-0.1-0.4-0.1C225.4,303.5,224.3,303.5,224.8,301.7z"/>
						<path fill="#CEC69D" d="M278.5,311.1c-1.9,0.7-3.6-0.4-5.3-0.5c-0.4,0-0.5-0.2-0.4-0.5c0.3-0.5,0.5-1,1.3-0.8
							c1.5,0.3,3,0.6,4.6,0.9C278.6,310.5,278.5,310.8,278.5,311.1z"/>
						<path fill="#888158" d="M277,308.2c-1.6,0.7-3.2,0-4.8,0.2c-0.5,0.1-1-0.2-1-0.9c0-0.4,0.4-0.7,0.7-0.6c1.7,0.4,3.5,0,5.1,1
							C277,308,277,308.1,277,308.2z"/>
						<path fill="#B7AF8B" d="M290,311.8c1.5-0.5,2.7-0.6,4-0.3c0.5,0.1,0.9,0.4,0.9,1c0,0.7-0.5,0.7-1.1,0.7
							C292.6,312.9,291.3,313,290,311.8z"/>
						<path fill="#E9E4B6" d="M232.8,298.3c-1.1,0.5-2.2,1.2-3.5,0.3c-1-0.7-1.6,1.2-2.6,0.4c-0.1-0.8,0.9-0.6,1-1.1
							C229.5,297.1,231.2,297.3,232.8,298.3z"/>
						<path fill="#E9E4B6" d="M214,303.8c0.5-0.6-0.4-2,1-1.9c1.1,0.1,0.9,1.2,0.8,2.1c-0.1,1-0.3,2.2-1.4,2
							C213.4,305.8,214.3,304.6,214,303.8z"/>
						<path fill="#B7AF8B" d="M289.8,304.8c-1.6,1.5-2.9,0.4-4.2-0.1c-0.3-0.1-0.4-0.6-0.3-0.9c0.2-0.5,0.6-0.5,1-0.3
							C287.4,303.8,288.5,304.3,289.8,304.8z"/>
						<path fill="#888158" d="M289.3,318.2c0-0.2,0.1-0.3,0.1-0.5c1.2-0.5,2.3,0.1,3.5,0.1c0.4,0,0.8,0.3,0.7,0.8
							c-0.1,0.4-0.5,0.7-0.7,0.5C291.7,318.6,290.3,319.2,289.3,318.2z"/>
						<path fill="#CEC69D" d="M278.3,308.5c0.2-0.8,0.6-1.3,1.5-1c0.3,0.1,0.7,0.5,0.5,0.8C279.7,309,279,308.4,278.3,308.5
							L278.3,308.5z"/>
						<path fill="#888158" d="M281.5,310.5c0,0.3,0,0.5-0.1,0.8c-0.5,0.1-1.1,0.3-1.1-0.3C280.3,310.2,281,310.3,281.5,310.5z"/>
						<path fill="#888158" d="M278.5,311.1c0-0.3,0.1-0.6,0.1-0.9c0.5,0,1.3-0.3,1.2,0.6C279.7,311.5,279,311.3,278.5,311.1z"/>
						<path fill="#CEC69D" d="M277,308.2c0-0.1,0-0.2,0-0.3c0.4,0.2,0.8,0.4,1.3,0.5l0,0C277.8,309,277.3,308.9,277,308.2z"/>
						<path fill="#CEC69D" d="M255.3,302.1c0.1,0.6,0,1.1-0.2,1.7C254.7,303.2,254.8,302.6,255.3,302.1z"/>
						<path fill="#CEC69D" d="M254.9,306.4c0.2,0.6,0.1,1.1-0.2,1.6C254.3,307.5,254.3,306.9,254.9,306.4z"/>
						<path fill="#CEC69D" d="M261.9,301c-0.2,0-0.5-0.1-0.7-0.1C261.5,300.6,261.7,300.6,261.9,301z"/>
						<path fill="#CEC69D" d="M256,300.4c-0.1,0.1-0.3,0.3-0.4,0.4C255.3,300.3,255.8,300.5,256,300.4z"/>
						<path fill="#E9E4B6" d="M239.2,301.2c0.1-0.2,0.1-0.5,0.4-0.5c0.1,0,0.2,0.3,0.2,0.5C239.6,301.2,239.4,301.2,239.2,301.2z"/>
						<path fill="#E9E4B6" d="M226.6,303.6c0.1-0.1,0.3,0,0.4,0.1c-0.1,0.2-0.2,0.5-0.2,0.5C226.4,304.1,226.6,303.8,226.6,303.6z"
							/>
						<path fill="#CEC69D" d="M256.5,300.4c-0.2,0-0.3,0-0.5,0C256.2,300.2,256.3,300.2,256.5,300.4z"/>
						<path fill="#B7AF8B" d="M281.6,314c0.1,0,0.1,0.1,0.2,0.1C281.7,314,281.7,314,281.6,314L281.6,314z"/>
						<path fill="#CEC69D" d="M254.7,308.9c0.1,0.1,0.2,0.2,0.1,0.4C254.4,309.2,254.3,309.1,254.7,308.9z"/>
						<path fill="#B7AF8B" d="M404,387.3c0-1.5,0.1-2.5,1.9-2.2c1.3,0.2,1.9,0.4,1.7,1.9c-0.2,1.5-0.6,2.5-2.4,2.4
							C403.2,389.2,404.1,388,404,387.3z"/>
						<path fill="#FBEFB2" d="M409.3,393.7c-2.7,0.6-5.2-0.3-7.9-0.5C404.1,392.7,406.7,393.6,409.3,393.7z"/>
						<path fill="#888158" d="M340.8,441.3c0.2,0.1,0.3,0.3,0.2,0.5C341,441.6,340.9,441.4,340.8,441.3z"/>
						<path fill="#E9E4B6" d="M249.4,422.3c-0.1,0.4-0.2,0.7-0.3,1.1c-1,0.1-0.8-0.5-0.6-1C248.7,421.9,249.1,421.9,249.4,422.3z"/>
						<path fill="#CEC69D" d="M239.3,466.3c0.8-2.6,2.8-1.8,4.6-1.6c0,1.9-1.7,3.3-1.6,5.4c0,0.9-1.4,0.6-2.1,1.2
							c-0.3-0.1-0.6-0.2-0.8-0.3C239.8,469.4,240.1,467.8,239.3,466.3z"/>
						<path fill="#F5EBB0" d="M264.5,477.6c0.2,0.2,0.5,0.3,0.5,0.5c0,0.4-0.3,0.4-0.6,0.4c-0.2,0-0.7,0-0.5-0.4
							C264,477.8,264.3,477.7,264.5,477.6z"/>
						<path fill="#CEC69D" d="M244,464.2c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.1,0,0.2-0.1C244,464,244,464.1,244,464.2z"/>
					</g>
				</g>
				<g opacity="0.44" clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#B34F4E" d="M267.9,1340c-0.2,0.6-0.4,1.1,0.1,1.6l0,0c-0.2,0.1-0.2,0.3-0.1,0.5c0,0,0,0,0,0
							c-0.4,0.8-0.2,1.6,0,2.3l0,0c-0.2,2.2-0.4,4.3-0.7,6.5c0,0.4,0,0.9-0.5,1.1c0-1.7-0.1-3.4,0-5.1c0-0.8-0.6-2-0.5-2.1
							c1-1.1,0.5-2.4,0.2-3.3c-0.8-2.3-0.7-4.7-1.2-7c-0.1-0.4,0.2-1.2-0.5-1.1c-0.5,0-0.9,0.6-1,1.1c-0.1,0.4-0.1,0.9-0.1,1.3
							c0,0.5,0.3,1.1-0.3,1.3c-0.6,0.1-0.6-0.6-0.8-1c-0.8-1.9-0.8-4-1.3-6c-0.2-0.8-0.4-1.6-1.4-2.6c0.9,5.2,2.5,9.8,2,14.9
							c-0.8-0.5-0.7-1.2-0.8-1.7c-0.2-2.6-0.6-5.1-0.9-7.7c-0.7-5.6-2.8-10.7-4.6-16c-0.5-1.4-1.4-2.6-1.5-4.1
							c1.3,1.1,1.5,3,2.7,4.4c0.1-0.5-0.1-0.9,0.2-1.2c1.1,1,1.2,2.5,1.8,3.8c0.4,0.9,0.4,2.1,1.7,2.4c0,0,0.1,0,0.1,0
							c0,0-0.1,0-0.1,0c-0.2-0.3,0-0.5,0.1-0.8c0.7-1.4,0.2-3,0.6-4.5c-1.7-1.2-1.1-3.4-2.2-4.9c-0.1-0.2-0.2-0.4,0-0.5
							c0.1-0.1,0.4-0.1,0.5,0c0.4,0.2,0.6,0.6,0.8,1c0.3,0.7,0.4,1.6,1.4,1.8c0.1,0.2,0.2,0.4,0.3,0.7c-0.4,0.9,0,1.5,0.8,1.8
							c0.2,0.5,0.4,1.1,0.6,1.6c-0.4,0.5-0.3,0.8,0.3,0.9c1.5,3.6,2.4,7.5,3.1,11.3c0.5,3,1.3,6,1,9
							C267.7,1339.7,267.9,1339.9,267.9,1340z"/>
						<path fill="#F4F4F5" d="M226.7,1290.7c-0.1-0.3-0.3-0.6,0-0.9c1-1.4,3.5-1.5,4.5-0.1c0.4,0.6,1,1,1.7,1.1
							c1.2,0.2,1.3,2.2,2.3,1.7c1-0.4,1.9-0.8,3-0.9c0.8-0.1,1.5-0.2,2.2,0.6c-1.7,0.3-3.3,0.4-4.8,0.9c-0.2,0.2-0.5,0.3-0.3,0.6
							c-1.5,0.5-2.8,1.4-4.2,2.2c-0.8,0-1.1,0.5-1.3,1.2c-0.8,1.1-1.5,2.3-2.3,3.4c-0.5,0.4-0.7,0.9-0.8,1.6c1.1-0.2,1.6-1,2.1-1.7
							c0.3-0.2,0.5-0.3,0.8-0.5c0.4,0.6,0.8,1.3,1.7,0.6c0.2-0.2,0.7,0,0.9,0.3c0.2,0.3-0.1,0.4-0.3,0.6c-1.6,1.3-2.8,2.9-3.7,4.8
							c-2.4,5-2.9,10.3-2,15.7c0.2,1.3,1.2,2.7,1.3,3.8c0.2,1.9,1,3.6,1.6,5.3c1.8,5,3.9,9.9,6.4,14.6c0.2,0.3,0.4,0.7,0.6,1
							c1.5,1.3,1.7,3.4,2.7,5c0.2,0.3,0.1,0.6-0.2,0.8c-1.1-1.8-2.3-3.5-3.2-5.4c-1-2-2.1-4-3.1-6c-0.1-0.1-0.2-0.1-0.3-0.2
							c0-0.1-0.1-0.2-0.2-0.2c-0.3-0.8-0.6-1.7-0.9-2.5c-3-7-5.7-14-6.7-21.6c-0.7-5.6-0.9-11.1,1.7-16.4c0.1-0.1,0.3-0.2,0.3-0.3
							c1.4-2.5,3-4.8,5.6-6.2c0.7-0.4,0.4-0.8-0.1-1.1c-0.7-0.4-1.5-0.8-2-1.5c-0.2,0-0.4-0.1-0.5-0.1l0,0
							C228.6,1290.6,227.6,1291.1,226.7,1290.7z M231.5,1292.1c0.1,0,0.2,0,0.2,0c0,0,0-0.2,0-0.2c-0.1,0-0.2,0-0.2,0
							C231.5,1291.9,231.5,1292,231.5,1292.1z"/>
						<path fill="#C3B3A6" d="M260.4,1322.2c-1.3-0.2-1.3-1.5-1.7-2.4c-0.6-1.3-0.8-2.7-1.8-3.8c-0.5-1-0.9-2-1.4-3
							c0.1-0.6-0.1-1-0.6-1.2c-0.2-0.5-0.5-0.9-0.7-1.4c0.2-0.4,0.1-0.7-0.4-0.7c-1.2-1.4-1.8-3.1-2.8-4.6c0.3-0.9-0.4-1.5-0.7-2.2
							c-0.1-0.3-0.6-0.7-0.2-1c0.4-0.3,1.2-0.4,1.5,0.1c0.5,0.8,1.6,1,1.7,2.1c0,0.5,0.6,0.4,1,0.4c0.3,0,0.6-0.1,0.7-0.5
							c0.1-0.5-0.4-0.6-0.7-0.6c-0.5-0.1-0.7-0.3-0.5-0.8c0.2-0.5,0-0.9-0.3-1.2c-0.4-0.4-0.3-0.6,0.1-0.9c3.8,3.9,6,8.7,8.1,13.7
							c-1-0.2-1.1-1.1-1.4-1.8c-0.2-0.4-0.3-0.8-0.8-1c-0.2-0.1-0.4,0-0.5,0c-0.2,0.1-0.1,0.3,0,0.5c1.1,1.5,0.5,3.7,2.2,4.9
							c-0.4,1.5,0.1,3.1-0.6,4.5C260.4,1321.7,260.3,1321.9,260.4,1322.2L260.4,1322.2z"/>
						<path fill="#81070E" d="M255.5,1313.1c0.5,1,0.9,2,1.4,3c-0.3,0.3-0.1,0.7-0.2,1.2c-1.2-1.3-1.3-3.2-2.7-4.4
							c-0.2-0.4-0.4-0.7-0.7-1.1c-1.1-3-3.2-5.3-5.3-7.7c-1.8-2-3.9-3.8-6.3-4.9c-3.5-1.6-7-2.2-10.5-0.1c-0.6,0.3-1.3,0.5-1.7,1.1
							c-0.3,0.2-0.5,0.3-0.8,0.5c-0.4-0.2-0.9,0.9-1.3,0c0.8-1.1,1.5-2.3,2.3-3.4c0.8,0,0.9-0.8,1.3-1.2c1.3-0.8,2.7-1.7,4.2-2.2
							c0.3,0.2,0.5,0.1,0.7-0.2c0.5,0,0.9-0.1,1.3-0.3c0.5,0.5,1,0.5,1.5-0.1c0.6,0,1.1,0,1.7,0c0.2,0.4,0.4,0.5,0.7,0.1
							c0.6,0.2,1.3,0.2,1.9,0.6c-0.2,0.7,0.3,1.5-0.2,2.1c-0.6,0.7-0.7,0.7,0.2,1.2c0.5,0.3,0.8,0.7,1.3,1c2.4,1.8,4.5,3.9,6.1,6.4
							c0.1,0.2,0.4,0.2,0.6,0.4c1,1.5,1.6,3.3,2.8,4.6c-0.3,0.4-0.2,0.7,0.4,0.7c0.2,0.5,0.5,0.9,0.7,1.4
							C254.7,1312.5,254.9,1312.9,255.5,1313.1z"/>
						<path fill="#B34F4E" d="M250.9,1305.1c-0.2-0.1-0.5-0.2-0.6-0.4c-1.6-2.5-3.7-4.6-6.1-6.4c-0.4-0.3-0.8-0.7-1.3-1
							c-0.9-0.5-0.8-0.5-0.2-1.2c0.5-0.6,0-1.4,0.2-2.1c0.2-0.3,0.4-0.2,0.7-0.1c0.1,0.1,0.1,0.3,0.2,0.4c2.7,1.7,5.5,3.3,8.1,5.2
							c0.1,0.1,0.3,0,0.4,0c0.4,0.4,0.7,0.7,1.1,1.1c-0.4,0.3-0.5,0.6-0.1,0.9c0.3,0.3,0.6,0.7,0.3,1.2c-0.2,0.5-0.1,0.7,0.5,0.8
							c0.3,0,0.7,0.1,0.7,0.6c0,0.3-0.4,0.4-0.7,0.5c-0.4,0-0.9,0.1-1-0.4c-0.1-1.1-1.2-1.3-1.7-2.1c-0.3-0.5-1.1-0.4-1.5-0.1
							c-0.4,0.3,0.1,0.7,0.2,1C250.5,1303.7,251.2,1304.2,250.9,1305.1z"/>
						<path fill="#C3B3A6" d="M202.4,1301.5c4.3-2.1,8-5,11.5-8.1c0.6-0.5,1.2-1,1.9-1.4c1.2-0.7,2.6-0.4,3.9-0.5
							c0.6-0.1,1.1-0.2,1.7-0.3c0,0.1,0.1,0.2,0.1,0.3c-0.2,0.6-0.9,0.6-1.4,1c-0.5,0.4-1.4,0.3-1.5,1.2c-0.2,0.1-0.3,0.2-0.5,0.4
							c-0.7,0-1.4,0.2-2,0.6c-2.3,0.5-4.3,1.6-6,3.1c-0.3,0.2-0.7,0.8-0.5,0.9c1.5,0.7,0.8,2.3,1.5,3.3c-0.1,0.4-0.1,1-0.7,1
							c-0.6,0-0.2-0.5-0.4-0.8c-0.2-0.4-0.3-0.8-0.5-1.2c-0.1-0.4-0.1-0.8-0.7-0.8c-0.5,0-0.6,0.4-0.8,0.7c-0.5,1.1-2.3,1.9-3.7,1.5
							c-0.5-0.3-1.1-0.4-1.7-0.4l-0.1,0C202.5,1301.8,202.4,1301.7,202.4,1301.5z M212.6,1295.8c1.3-0.7,2.3-1.2,3.3-1.7
							c0.3-0.1,0.5-0.4,0.3-0.7c-0.2-0.3-0.5-0.3-0.8-0.1C214.4,1293.8,213.4,1294.3,212.6,1295.8z"/>
						<path fill="#C3B3A6" d="M229.9,1291.5c0.5,0.7,1.2,1.1,2,1.5c0.5,0.3,0.9,0.7,0.1,1.1c-2.6,1.4-4.2,3.7-5.6,6.2
							c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2-0.3-0.4-0.5-0.6-0.8c0.7-1,1.4-2,2-3c1.3-0.5,2-1.6,2.9-2.6c-2-0.9-4-1.2-6-0.8
							c-1.7,0.3-2.4-0.2-2.5-1.9c0.9-0.4,1.8-0.9,2.8-0.4c0,0.3,0.7,0.7-0.1,0.8c-0.3,0.1-0.8-0.1-0.7,0.4c0,0.2,0.3,0.5,0.6,0.6
							c0.5,0.1,0.7-0.3,0.9-0.6c0.3-0.5-0.1-0.8-0.3-1.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2,0.1,0.3,0.2,0.5,0.2c0.8,1.1,1.8,1.2,3.3,0.5
							l0,0C229.5,1291.4,229.7,1291.5,229.9,1291.5z"/>
						<path fill="#C3B3A6" d="M252.4,1299.5c-0.1,0-0.3,0.1-0.4,0c-2.6-1.9-5.4-3.5-8.1-5.2c-0.1-0.1-0.1-0.3-0.2-0.4
							C247.1,1295,250,1296.9,252.4,1299.5z"/>
						<path fill="#F4F4F5" d="M224.8,1290.9c-1-0.5-1.9,0.1-2.8,0.4c-0.2,0-0.3,0.1-0.5,0.1c0-0.1-0.1-0.2-0.1-0.3
							c0-1.1,0.6-1.5,1.6-1.3c0.9,0.2,2-0.2,2.7,0.8c-0.2,0.1-0.3,0.2-0.5,0.3C225,1290.9,224.9,1290.9,224.8,1290.9z"/>
						<path fill="#B34F4E" d="M229.4,1291.4c-1.5,0.7-2.5,0.6-3.3-0.5c0.2-0.1,0.4-0.1,0.6-0.2
							C227.6,1291.1,228.6,1290.6,229.4,1291.4z"/>
						<path fill="#C3B3A6" d="M262.8,1316.7c-0.8-0.4-1.2-0.9-0.8-1.8C262.4,1315.4,262.8,1316,262.8,1316.7z"/>
						<path fill="#C3B3A6" d="M238.7,1293.3c-0.4,0.6-0.9,0.6-1.5,0.1C237.7,1293,238.2,1292.9,238.7,1293.3z"/>
						<path fill="#C3B3A6" d="M263.7,1319.2c-0.7-0.1-0.7-0.4-0.3-0.9C263.6,1318.5,263.8,1318.8,263.7,1319.2z"/>
						<path fill="#C3B3A6" d="M235.9,1293.7c-0.1,0.3-0.4,0.4-0.7,0.2c-0.2-0.4,0.1-0.5,0.3-0.6
							C235.7,1293.4,235.8,1293.5,235.9,1293.7z"/>
						<path fill="#C3B3A6" d="M241.1,1293.4c-0.3,0.4-0.5,0.3-0.7-0.1C240.6,1293.1,240.9,1293.1,241.1,1293.4z"/>
						<path fill="#C3B3A6" d="M253.4,1311.8c0.2,0.4,0.4,0.7,0.7,1.1c0.2,1.5,1,2.7,1.5,4.1c1.8,5.3,3.9,10.4,4.6,16
							c0.3,2.6,0.7,5.1,0.9,7.7c0,0.5-0.1,1.2,0.8,1.7c0.5-5.1-1-9.7-2-14.9c0.9,0.9,1.1,1.7,1.4,2.6c0.6,2,0.5,4.1,1.3,6
							c0.2,0.4,0.2,1.2,0.8,1c0.6-0.1,0.3-0.8,0.3-1.3c0-0.4,0-0.9,0.1-1.3c0.1-0.5,0.5-1.1,1-1.1c0.7,0,0.4,0.7,0.5,1.1
							c0.5,2.3,0.5,4.7,1.2,7c0.3,1,0.8,2.2-0.2,3.3c-0.1,0.1,0.5,1.3,0.5,2.1c0,1.7,0,3.4,0,5.1c0,0,0,0,0,0
							c-0.5,0.4-0.1,0.8-0.1,1.2c0,0.9-0.1,1.9-0.3,2.8c-0.1,0.5-0.3,0.9,0.1,1.4c0,0,0,0,0,0c-1.2,0.4-0.7,2-1.7,2.5
							c-0.1-0.4-0.3-0.7-0.4-1.1c-0.1-1.1-0.6-1.7-1.8-1.9c-0.1,1,0.8,1.7,0.6,2.7c-0.1,0.6-0.6,0.8-0.7,0.5c-0.5-0.9-2-1.3-1.6-2.7
							c0.5-0.4,0.9-0.8,1.4-1.2c1-0.1,1.7-0.3,1.4-1.6c-0.1-0.3,0.2-0.8,0.2-1.1c0.1-2,1.6-4.1-0.4-5.9c-0.1-0.1-0.1-0.4,0-0.6
							c0.1-0.5,0-1.1-0.5-1.2c-0.6,0-0.5,0.6-0.6,1.1c-0.4,2.6-0.3,5.2-0.9,7.7c-0.5,0-0.7-0.4-1-0.7c0.4-1.4-0.5-2.8,0.1-4.1
							c0.6-1.2,0.4-2.6-0.1-3.2c-1.4-1.8,0.2-4.1-1.3-5.8c-0.1-0.1,0-0.5,0.1-0.7c0.5-1.7,0.2-2.7-1.2-3.6c-0.6-0.4-1.1-0.7-0.5-1.4
							c0.2-0.2,0.2-0.6,0-0.8c-0.3-0.3-0.5,0.1-0.7,0.2c-1.7,0.8-2.8,0.4-3.5-1.5c-1.3-3.5-1.8-7-1.3-10.7c0.2-1.2,0.7-2.3,0.7-3.3
							c0-2.5-0.3-5-0.2-7.5C253,1311.8,253.2,1311.8,253.4,1311.8z M261.7,1343.1c-0.4,0.4-0.4,0.8,0,1.2
							C262.1,1343.9,262.1,1343.5,261.7,1343.1z"/>
						<path fill="#EDECEC" d="M268,1341.7c1.4,0.1,0.8,0.9,0.6,1.5l0,0c-0.2-0.4-0.4-0.7-0.6-1.1l0,0
							C268.1,1342,268.1,1341.9,268,1341.7L268,1341.7z"/>
						<path fill="#EDECEC" d="M267.6,1357.5c-0.2,0.2-0.5,0.3-0.7,0.5c-0.1-0.2-0.2-0.4-0.3-0.5c0,0,0,0,0,0
							c0.2-0.2,0.5-0.4,0.7-0.6l0.1,0C267.4,1357,267.5,1357.3,267.6,1357.5z"/>
						<path fill="#B34F4E" d="M266.8,1353.3c-0.1-0.4-0.4-0.8,0.1-1.2C267.1,1352.5,267,1352.9,266.8,1353.3z"/>
						<path fill="#EDECEC" d="M268.4,1348.6c0.1,0.3,0.2,0.5,0,0.8c0,0-0.1,0-0.1,0C268.1,1349.1,268.2,1348.8,268.4,1348.6
							L268.4,1348.6z"/>
						<path fill="#EDECEC" d="M267.9,1353.5c0,0.2,0.1,0.4-0.1,0.5c0,0-0.2,0-0.2-0.1C267.6,1353.7,267.7,1353.6,267.9,1353.5
							L267.9,1353.5z"/>
						<path fill="#EDECEC" d="M267.4,1355.7c-0.1-0.2-0.1-0.4,0.1-0.5c0,0,0.2,0,0.2,0.1C267.7,1355.5,267.6,1355.6,267.4,1355.7
							L267.4,1355.7z"/>
						<path fill="#EDECEC" d="M268.6,1343.2c0.2,0.4,0.1,0.7-0.1,1.1l0,0C268.3,1343.9,268.3,1343.5,268.6,1343.2L268.6,1343.2z"/>
						<path fill="#EDECEC" d="M268.5,1348.2c0-0.1,0-0.2-0.1-0.4c0,0,0.1,0,0.1,0C268.5,1348,268.5,1348.1,268.5,1348.2
							L268.5,1348.2z"/>
						<path fill="#EDECEC" d="M268,1344.5C268,1344.5,268,1344.5,268,1344.5c0.2-0.1,0.3-0.1,0.5-0.2l0,0c0,0.1,0,0.2,0,0.4
							C268.3,1344.6,268.1,1344.5,268,1344.5z"/>
						<path fill="#EDECEC" d="M268.6,1345.6C268.6,1345.7,268.6,1345.7,268.6,1345.6c0.1,0.1,0,0.1,0,0.2
							C268.5,1345.8,268.5,1345.7,268.6,1345.6C268.5,1345.7,268.5,1345.7,268.6,1345.6z"/>
						<path fill="#EDECEC" d="M268.4,1346.5c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
							C268.4,1346.6,268.4,1346.6,268.4,1346.5z"/>
						<path fill="#EDECEC" d="M268.5,1348.2c0,0.1-0.1,0.3-0.1,0.4l0,0C268.4,1348.5,268.5,1348.3,268.5,1348.2L268.5,1348.2z"/>
						<path fill="#EDECEC" d="M267.9,1353.5c0,0,0-0.1,0-0.1C267.9,1353.4,267.9,1353.5,267.9,1353.5L267.9,1353.5z"/>
						<path fill="#EDECEC" d="M267.3,1356.8c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0C267.3,1356.7,267.3,1356.8,267.3,1356.8L267.3,1356.8z
							"/>
						<path fill="#EDECEC" d="M267.4,1355.8c0,0,0,0.1,0,0.1C267.4,1355.8,267.4,1355.8,267.4,1355.8L267.4,1355.8z"/>
						<path fill="#81070E" d="M166.1,1386.9c-7.3,2.8-14.5,2.9-21.5-1c-0.3-0.2-0.6-0.5-1-0.5c-1.4-1.3-3.2-2.1-4.4-3.7
							c0.5-0.4,0.9-0.1,1.4,0c2.3,0.5,4.7,1,7,1.5c2.4,0.5,4.7,1.2,7.2,1.1c1.3-0.1,2.6-0.2,3.8-0.3c1.9,0.3,3.7-0.3,5.5-0.9
							c0.2,0,0.3-0.1,0.5-0.1c0.3,0.2,0.6,0.4,0.8,0c0.1-0.1,0.2-0.1,0.3-0.1c0.8,0.7,1.8,0.6,2.7,0.6c2.1,0,4-0.6,6-1.3
							c1.3-0.5,2.9-0.1,4.2-0.9l0,0c0.2,0,0.4,0,0.6,0c0.1,0,0.3-0.1,0.4-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.3,0.1,0.4,0.1
							c0.1,0.2,0.2,0.4,0.5,0.4c0.1,0.2,0.2,0.4,0.5,0.4c0.3,0.8,0.8,1.4,1.7,1.5c0.3,0.3,0.4,0.8,0.8,1c0.3-0.3-0.3-0.6,0-0.9
							c1.6-0.6,2.3-2.9,1.3-4.4c-0.5-0.8-1.1-1.5-1.7-2.2c1.5-0.2,3.1-0.7,4.6-0.6c0.9,0,1.7,0.1,2.5-0.2c0.7-0.3,1.5-0.6,2.3-0.5
							c1.8,0,3.7-0.1,5.5-0.1c1.9,0,3.8,0.3,5.6,1c-0.5,0.3-1.1,0.1-1.6,0.1c-2.6-0.3-5.3-0.5-7.9-0.2c-0.5,0.1-1,0-1.3,0.5
							c-1.6,2.4-4.1,4.1-5.6,6.6c-0.2,0.3-0.4,0.8-1,0.7c-0.2-0.4,0.5-0.9-0.2-1.1c-0.4-0.1-0.7,0.1-1,0.4c-0.4,0.4-1.1,0.9-1.1,1.3
							c0,1.2-0.7,0.9-1.4,0.8c-2.9-0.4-5.7-0.3-8.3,1.5c-0.7,0.5-1.8,1.4-2.8,0c-0.3-0.4-1.2-0.3-1.8,0.4c-1,1-2.2,1.9-3.4,2.7
							c-0.3,0.3-0.8,0.6-1.2,0.2c-0.5-0.4-0.1-0.9,0.1-1.3c0.3-0.6,0.7-1.2,0.6-1.9c0,0,0-0.1,0-0.1
							C167.2,1387.2,166.9,1387,166.1,1386.9z M157,1387.6c1.1,0.8,2.2,0.2,3.3,0.3c0.2,0,0.5-0.2,0.5-0.4c0-0.4-0.3-0.5-0.6-0.4
							C159.1,1387,158.1,1387.1,157,1387.6z"/>
						<path fill="#81070E" d="M154.3,1375c-0.6-0.1-1.1-0.2-1.7-0.4c0.1-1.3,1.5-1.5,1.9-2.6c0,0,0.1-0.1,0.1-0.1
							c1-0.8,2.5-0.5,3.3-1.7c0.1-0.1,0.2-0.1,0.3-0.2c0.3,0.1,0.6,0.1,0.8-0.1l0.1,0c0.3,0.1,0.6,0,0.8-0.1l0.1,0
							c0.8,0.6,1.3-0.1,2-0.3l0,0c0.5,0.4,1,0.2,1.6,0.1c1.6-0.2,3.1,0.1,4.7,0.6c2,0.6,3.7,1.9,5.7,2.5c1.4,0.4,2.5-0.1,3.7-0.3
							c1.9-0.3,1.1-2,1.5-3c0.1-0.4,0.1-0.8,0.2-1.1c0.8-1.8,0-3.1-1.3-4.3c-0.8-0.7-1.4-1.2-2.5-0.3c-1.8,1.5-2.7,1.2-3.3-0.5
							c1.3-1,2.1-2.4,3.2-3.6c0.6-0.4,1-0.9,1-1.6c0.2-0.1,0.5-0.2,0.7-0.3c0.5,0.1,1.1,0.3,1.6,0.4c0.1,0.7,0,1.5,0.4,2.1
							c1,1.6,1.5,3.2,1.3,5.1c-0.1,0.3,0.1,0.3,0.3,0.3c-0.4,1.8-0.8,3.6-1.1,5.3c-0.4,0.9-0.7,1.9-0.5,2.9
							c-0.4,0.7,0.1,1.7-0.7,2.4c-0.2-0.7-0.8-0.6-1.3-0.5c-1.3,0.2-2.5,0.1-3.8-0.1c-0.2-0.4-0.3-0.8-0.6-1.2
							c-0.2-0.4-0.5-0.8-1-0.6c-0.5,0.1-0.3,0.6-0.3,1c0,0.2-0.1,0.4-0.1,0.6c-1.1,0.1-2.2,0.4-3.3,0.3c-3.7-0.2-7.5,0-11.2-0.4
							C156.2,1375.1,155.3,1374.7,154.3,1375z"/>
						<path fill="#CA0E21" d="M154.5,1372c-0.3,1.1-1.8,1.4-1.9,2.6c-0.3,0-0.5,0-0.8,0c-1.4-1.1-2.5-1.2-3.3-0.2
							c-4.7-0.8-9.3-2.3-13.7-4.2c0.1-1.3-1.2-1.9-1.5-3.1c-0.5-1.9-1.9-3.5-1.6-5.6c0-0.4,0.4-0.6,0.7-0.7c0.6,3.1,2.4,5.1,5.1,6.5
							c1.7,0.9,3.5,1.4,5.5,0.8c1-0.3,1.9-0.7,2.9-1c0.7,0.6,1.7,1.4,2.5,0.8c0.7-0.5,1.4-1.5,0.9-2.7c0.4-0.3,0.9-0.5,1.3-0.8
							c0.1,0.1,0.2,0,0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.3c1.6,2.1,1.6,4.8,2.3,7.2C153.8,1371.6,153.7,1372.3,154.5,1372z"/>
						<path fill="#C3B3A6" d="M173.6,1375.4c1.3,0.2,2.5,0.2,3.8,0.1c0.5-0.1,1.1-0.2,1.3,0.5c0,0.1,0.1,0.2,0.1,0.2
							c-0.5,0.5-0.3,1.2-0.5,1.8c0,0.4,0,0.8-0.1,1.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-1.1-1.2-2.5-2-3.7-3.1
							c-0.3-0.3-0.9-0.6-1.2-0.1c-0.3,0.4,0.3,0.6,0.5,0.9c0.9,1,2.3,1.4,3.3,2.4c-0.4,0.2-0.7,0.3-1.1,0.5
							c-0.3,0.1-0.5,0.1-0.8,0.2c-3.1-0.9-6.2-0.9-9.3-0.6c-2,0.2-3.5-0.2-5.2-1.3c-1.7-1.1-3.7-1.8-5.5-2.6c-0.4-0.2-0.9-0.3-1-0.8
							c0.9-0.3,1.8,0.1,2.7,0.2c3.7,0.4,7.5,0.2,11.2,0.4c1.1,0.1,2.2-0.2,3.3-0.3C172.2,1375.9,172.9,1375.7,173.6,1375.4z"/>
						<path fill="#C3B3A6" d="M154.8,1384.5c-2.5,0.1-4.8-0.6-7.2-1.1c-2.3-0.5-4.7-1-7-1.5c-0.5-0.1-0.9-0.4-1.4,0
							c-0.5,0.2-0.7-0.2-0.9-0.5c-1.2-1.4-2.3-3-3.1-4.7c0.4,0.3,0.8,0.6,1.1,0.9c0.9,0.9,0.8,0.9,1.4-0.1c0.3-0.4,0.7-0.6,1.3-0.2
							c1,0.7,2.1,1,3.3,0.8c0.9-0.1,1.7,0.6,2.4,0.9c1.2,0.6,2.3,1.2,3.4,2c1.7,1.3,3.7,2.2,5.8,2.8
							C154.2,1383.9,154.7,1384,154.8,1384.5z"/>
						<path fill="#B34F4E" d="M131.8,1361.5c-0.2,2.1,1.1,3.7,1.6,5.6c0.3,1.2,1.6,1.8,1.5,3.1c-2.1-0.5-3.6-1.6-4.2-3.8
							c-0.2-0.6-0.3-1.2-0.7-1.7c-0.1-0.4-0.3-0.7-0.4-1.1c0.1-0.5-0.1-0.8-0.3-1.2c-0.2-0.7-0.4-1.3-0.6-2c0-1-0.7-2-0.5-3
							c1.3,0.2,1.3,1.4,1.6,2.2C130,1360.8,130.5,1361.5,131.8,1361.5z"/>
						<path fill="#C3B3A6" d="M153.6,1376.2c0.8,0.6,1.1,1.5,1.5,2.3c0.6,1.2,1.3,1.9,2.8,1.4c0.4-0.1,1-0.2,1.2,0.2
							c0.3,0.5-0.2,0.8-0.6,1.1c-0.7,0.6-1,1.6-1.9,2c-0.6,0.2-1,0.2-1.5-0.1c-0.6-0.4-0.4-0.8-0.3-1.3c0.1-0.4,0.3-1-0.1-1.2
							C152.8,1379.5,154.2,1377.6,153.6,1376.2z"/>
						<path fill="#CA0E21" d="M178.6,1381.5c-1.3,0.8-2.9,0.4-4.2,0.9c-1.9,0.7-3.9,1.4-6,1.3c-1,0-1.9,0-2.7-0.6
							c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.6,0,0.9-0.2c1.7-0.4,3.4-0.8,5.1-1.2c0.2,0.3,0.5,0.1,0.8,0.1
							C174.7,1381.6,176.6,1380.9,178.6,1381.5z"/>
						<path fill="#B34F4E" d="M178.6,1381.5c-2-0.5-4,0.2-6,0.1c-0.3,0-0.6,0.1-0.8-0.1c1.1-0.6,2.5-0.5,3.5-1.2
							c0.3-0.1,0.5-0.1,0.8-0.2c1.2,0.4,2.5,0.4,3.6,1.1l-0.1,0.1c-0.1,0-0.3,0.1-0.4,0.1C179.1,1381.5,178.9,1381.5,178.6,1381.5
							L178.6,1381.5z"/>
						<path fill="#C3B3A6" d="M164.2,1383.3c-1.8,0.6-3.6,1.2-5.5,0.9C160.3,1382.9,162.9,1382.4,164.2,1383.3z"/>
						<path fill="#81070E" d="M148.6,1374.4c0.8-1,1.9-0.9,3.3,0.2C150.7,1375.3,149.6,1375.1,148.6,1374.4z"/>
						<path fill="#C3B3A6" d="M166.8,1382.6c-0.3,0.2-0.6,0.3-0.9,0.2c0-0.3-0.1-0.7,0.3-0.8C166.6,1382,166.6,1382.4,166.8,1382.6z
							"/>
						<path fill="#C3B3A6" d="M165.5,1383.2c-0.3,0.4-0.5,0.3-0.8,0C164.9,1382.8,165.2,1382.8,165.5,1383.2z"/>
						<path fill="#81070E" d="M131.8,1361.5c-1.3,0-1.8-0.8-2.2-1.9c-0.3-0.9-0.3-2-1.6-2.2c-1.3-4.4-1.7-8.9-1.6-13.4
							c0.1-6.2,1.3-12.2,3.9-17.9c1.6-3.4,3.8-6.4,6-9.4c2.3-2.2,4.7-4.3,7.7-5.5c0.9-0.3,1.8-0.6,2.6-0.9c-0.6,0.9-1.6,1.3-2.5,1.9
							c-0.8,0.6-1.2,1.2-0.6,2.2c0,0.5-0.3,0.9-0.7,1.2c-1.4,1.1-2.9,1.9-4.2,3.1c-1.3,1.1-0.9,2.9-2.2,3.9c0,0,0,0.2,0.1,0.3
							c0.6,0.8,0.2,1.4-0.4,1.9c-0.8,0.8-1.1,1.8-1.4,2.7c-0.2,0.7-0.4,1.3-1.2,1.6c-0.5,0.3-1,1.1-1.1,1.7c0,0.5,2.2,1,2.8,0.7
							c1.1-0.5,2-1.4,3.3-1.3c0.4,0,0.8-0.2,1.1,0.2c0.3,0.5-0.3,0.6-0.5,0.9c-0.2,0.3-0.5,0.6-0.5,1c-1.2,1.9-1.9,4-1.1,6.3
							c-1.4,1.7-2.3,3.7-3.1,5.8c-1.6,3.9-2.7,7.9-2.6,12.1c0,1.4,0.2,2.8,0.6,4.1l-0.1,0C132.1,1361,131.8,1361.1,131.8,1361.5z"/>
						<path fill="#81070E" d="M163.4,1309.3c0.5,0,1.1,0.1,1.6,0.1c0.5,0.3,0.9,0.9,1.4,0c0.1-0.1,0.2-0.1,0.3-0.1
							c0,1.6,1.6,1.3,2.4,2c0.2,0.2,1.2-0.4,1.6-0.8c0.3-0.2-0.7-0.3-0.5-0.8c0.8-0.4,1.2-1.1,1.6-1.8c1.1-1.9,2.8-2.7,4.9-3
							c1.5,0.2,3,0.5,4.5,0.5c0.7,0,1.4,0.7,2,0c0,0.1,0.1,0.2,0.1,0.3c-0.7,0.6-1.6,0.7-2.4,0.9c-1.2-0.1-2.4-0.3-3.6-0.4
							c-0.4,0-0.8,0-0.8,0.5c0,0.3,0,0.7,0.3,0.7c1.2-0.2,1.9,0.9,2.9,1.2c0.6,1.3-0.2,1.1-1,1c-0.6-0.1-1.2-0.4-1.1,0.6
							c-0.1,0-0.1,0.1-0.2,0.1c-0.3,1.1,0.7,1.2,1.2,1.7c0.3,0.3,1.1,0.4,1,1c-0.1,1.2,0.5,2.1,1.4,3.2c-1.6-0.4-2.5-1-3.1-2.3
							c-0.4-0.9-1.8-1.4-1.4-2.8c0,0-0.4-0.2-0.6-0.3c-0.5-0.6-0.9-1.2-1-2c-0.1-0.5-0.4-1-0.9-1.3c-0.3-0.2-0.5-0.5-0.9-0.3
							c-0.3,0.2-0.3,0.5-0.3,0.8c0.1,0.8,0.1,1.6-0.8,2.2c-0.2,0.2,0,0.5,0.1,0.8c-3.3,2.7-5.1,6.7-8.8,8.9c1.3-0.3,2.7-0.5,3.7-1.5
							c0.2-0.2,0.6-0.7,0.9-0.2c0.3,0.3,0,0.8-0.4,1c-1.2,0.6-2.3,1.5-3.6,1.7c-0.6,0.2-1.7-0.8-1.9,0.7c-0.2,1.7-0.6,3.4-0.9,5
							c-0.3,2.2-1.2,4.1-3,5.5c-0.6-0.2-0.9-0.6-1.2-1.1c0-0.2,0.1-0.5,0.1-0.7c0.2-0.1,0.4-0.2,0.6-0.3c1.1-1.5,1-3.3,1.3-5
							c0.1-0.6,0-1.4,0.6-1.7c1.1-0.5,1-1.4,1.2-2.3c0.6,0,0.7-0.6,1-0.9c0.2-0.1,0.5-0.3,0.5-0.6c0.2-0.3,0.5-0.7,0.7-1
							c0.1-0.1,0.2-0.3,0.2-0.4c0.4-0.2,0.7-0.6,0.8-1.1c0.1-0.2,0.2-0.3,0.4-0.5c0.5-0.2,0.8-0.6,0.8-1.1c0.2-0.1,0.3-0.3,0.3-0.5
							c0.6-0.2,0.9-0.6,1.1-1.2c0.3-0.3,0.7-0.4,0.8-0.9c1-1.3,1-1.3-0.6-1.8c0,0-0.1-0.1-0.1-0.1c-0.8-0.8-1.5-0.8-2.4-0.1
							c-0.1-0.3,0.3-1.1-0.2-0.9C163.2,1310.7,163.7,1309.6,163.4,1309.3z"/>
						<path fill="#81070E" d="M216.1,1294.6c0.6-0.3,1.3-0.6,2-0.6c0.3,1-1,1.3-0.9,2.2c-1.7,1-2.6,2.6-3.8,4
							c-0.5,1.3-1.7,2.2-2.2,3.6c-0.3,0.9-1.1,1.1-1.9,0.2c-0.3-0.4,0.1-0.8,0.1-1.3c0-0.2-0.1-0.3-0.2-0.5
							c-0.2,0.1-0.5,0.2-0.5,0.2c0.1,1-1,1.8-0.4,2.8l0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.7-0.9,0.7-1.5,0.5
							c-0.4-0.7-1.2-0.8-1.7-1.2c-0.3-0.2-1.1-0.1-1.5,0.3c-0.2,0.2-0.2,0.6,0,0.6c1.2,0.2,1.1,1.6,2,2.1c0.4,0.3,0.9,0.6,1.3,1
							c0.2,0.2,0.8,0.2,0.6,0.7c-0.2,0.5-0.6,0.3-1,0.2c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1-0.2-0.2-0.4-0.4-0.5c-0.6-1-2.4-1.6-3.4-0.8
							c-1,0.7-1.1,0-1.4-0.5c0.7-0.3,1-1,1.3-1.7c-0.1-0.3,0.7,0.2,0.3-0.4c-0.3-0.4-0.7-0.8-1.1-1.3c-0.1-0.2-0.3-0.4-0.6-0.2
							l-0.1,0c-0.1-0.3-0.2-0.7-0.3-1c0.5,0,0.6,0.6,1.1,0.5c0.2-0.2,0.5-0.4,0.7-0.5c0.2,0,0.5,0,0.7,0c0.7,0.3,1.3,0,1.9-0.4
							c1.4,0.3,3.2-0.4,3.7-1.5c0.2-0.4,0.3-0.7,0.8-0.7c0.6,0,0.5,0.5,0.7,0.8c0.1,0.4,0.3,0.8,0.5,1.2c0.1,0.3-0.2,0.8,0.4,0.8
							c0.6,0,0.6-0.5,0.7-1c0.9-1.2,1.8-2.4,2.9-3.5C215,1297.3,215.8,1296.1,216.1,1294.6z"/>
						<path fill="#EDECEC" d="M136.3,1316.7c-2.2,3-4.4,6-6,9.4c-2.6,5.7-3.8,11.7-3.9,17.9c-0.1,4.5,0.3,9,1.6,13.4
							c-0.1,1.1,0.5,2,0.5,3c-1.3-0.9-2.2-2-1.8-3.8c0.1-0.6,0-1.3-0.2-1.9c-0.8-4.4-1.2-8.8-0.8-13.2c0.3-3.5,0.9-7,1.9-10.5
							c0.9-2.8,2-5.5,3.4-8.1c1.2-2.2,2.6-4.2,4.4-6C135.7,1316.8,135.9,1316.5,136.3,1316.7z"/>
						<path fill="#81070E" d="M148.8,1310.1c4-0.4,7.9-0.9,11.9-0.6c0.1,0.7,0.9,0.9,1.3,1.6c-1.4,0.1-2,0.8-1.9,2
							c-0.3-0.1-0.6-0.2-0.9-0.1c-0.2-0.3-0.3-0.6-0.7-0.6c-0.7,0-1.2-0.9-2-0.7c-0.1,0-0.3-0.1-0.4-0.1c-0.8-0.8-1.7-1.4-3-1.1
							c-0.6,0.1-0.8,0.7-1.2,1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.4-0.5-0.9-0.4-1.4-0.1c-0.2,0.1-0.4,0.2-0.2,0.5c0.7,1-0.1,0.8-0.7,0.9
							c-1.2,0.1-2.1,0.5-2.5,1.7c-0.2,0.2-0.5,0.5-0.7,0.7c-0.7-0.3-1.6-0.5-1.7-1.4c-0.1-0.9,0.8-1.3,1.4-1.7
							C146.6,1311.3,147.8,1310.7,148.8,1310.1z M159.9,1310.6c-0.2-0.4-0.6-0.3-0.9-0.2c-0.4,0.1-0.5,0.4-0.4,0.8
							c0.1,0.4,0.5,0.4,0.8,0.3C159.7,1311.3,160,1311.1,159.9,1310.6z"/>
						<path fill="#EDECEC" d="M200.8,1304.1c0.4,0.4,0.8,0.8,1.1,1.3c0.4,0.5-0.4,0.1-0.3,0.4c-0.8,0.8-2,0.2-2.8,0.9l0,0
							c-0.2,0-0.4-0.1-0.5-0.1c-0.7-0.2-1.4-0.5-2.1-0.1c-0.1-0.3-0.4-0.4-0.7-0.5c-1.6-0.2-3.2-0.3-4.6-1c0,0-0.2,0.2-0.3,0.3
							c-0.7,0.3-0.8-0.3-1.1-0.6c-0.5-0.5-0.8-0.3-1.1,0.2c-0.4-0.9-1.4-0.9-2.1-1.2c-0.1-0.2-0.1-0.4-0.2-0.5
							c1.4-0.2,2.9-0.4,4.3,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0.1,0.1c1.8,0,3.6-0.2,5.3,0.1c1.5,0.3,3,0.5,4.5,0.3
							c0,0,0.1,0,0.1,0C200.4,1304,200.6,1304,200.8,1304.1z"/>
						<path fill="#B34F4E" d="M160.2,1313.1c-0.1-1.2,0.5-1.9,1.9-2c-0.5-0.7-1.3-0.9-1.3-1.6c0.9-0.5,1.7-0.4,2.6-0.2
							c0.3,0.3-0.2,1.4,1,0.9c0.5-0.2,0,0.6,0.2,0.9l0,0c-0.5,0.5-2-0.1-1.6,1.4c-0.3,0.1-0.8,0.3-0.6,0.6c0.3,0.4,0.9,0.8,1.5,0.6
							c0.3,0.8,1.2,0.9,1.8,1.4c0,0.2-0.1,0.4-0.3,0.5c-0.8-0.5-1.7-1.1-2.7-0.3l0,0C162.6,1313.8,160.9,1314,160.2,1313.1z"/>
						<path fill="#B34F4E" d="M186,1303.1c0.1,0.2,0.1,0.4,0.2,0.5c0,1.3-1,0.4-1.4,0.6c-1.5-0.8-1.5-0.8-1.6,1.2
							c-0.7,0.7-1.4,0-2,0c-1.5,0-3-0.3-4.5-0.5c2.7-0.9,5.4-1.6,8.2-2c0.1,0.1,0.3,0.2,0.4,0.3c0-0.2,0-0.3,0-0.5
							C185.5,1302.9,185.8,1303,186,1303.1z"/>
						<path fill="#B34F4E" d="M200.1,1303.9c-1.5,0.2-3,0-4.5-0.3c-1.8-0.3-3.6-0.1-5.3-0.1c0,0-0.1-0.1-0.1-0.1
							c0-0.1,0-0.2,0.1-0.3c2.7-0.3,5.4-0.9,8.2-0.8c0,1.2,0.5,1.2,1.3,0.6l0,0C200,1303.2,200,1303.5,200.1,1303.9z"/>
						<path fill="#C3B3A6" d="M148.8,1310.1c-1.1,0.6-2.2,1.2-3.3,1.9c-0.6,0.4-1.5,0.8-1.4,1.7c0.1,0.9,1,1.1,1.7,1.4
							c0,0.1-0.1,0.1-0.2,0.1c0,0.1-0.1,0.3-0.1,0.4c-0.9,0.1-1.3-0.8-2.1-1.1c-0.5-1-0.2-1.6,0.6-2.2c0.8-0.6,1.9-1,2.5-1.9
							C147.3,1309.9,148.1,1309.7,148.8,1310.1z"/>
						<path fill="#B34F4E" d="M170.2,1309.8c-0.1,0.5,0.8,0.6,0.5,0.8c-0.4,0.4-1.4,1-1.6,0.8c-0.8-0.6-2.4-0.3-2.4-2
							C167.9,1309.2,169,1309.2,170.2,1309.8z"/>
						<path fill="#EDECEC" d="M129.2,1362.4c0.3,0.3,0.4,0.7,0.3,1.2c-0.4,0.2-0.9,0.4-1.1-0.2
							C128.3,1362.7,128.9,1362.7,129.2,1362.4z"/>
						<path fill="#EDECEC" d="M204.3,1302.3c-0.6,0.4-1.1,0.7-1.9,0.4c0.2-0.3,0.3-0.5,0.1-0.8
							C203.2,1301.9,203.8,1302,204.3,1302.3z"/>
						<path fill="#81070E" d="M199.8,1302.9c-0.8,0.6-1.3,0.7-1.3-0.6c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2,0,0.2,0
							C199.5,1302.4,199.7,1302.7,199.8,1302.9z"/>
						<path fill="#EDECEC" d="M201.7,1302.7c-0.2,0.2-0.5,0.4-0.7,0.5c-0.3-0.6-0.3-1.1,0.3-1.5
							C201.7,1302,201.5,1302.5,201.7,1302.7z"/>
						<path fill="#B34F4E" d="M166.4,1309.5c-0.5,0.9-1,0.3-1.4,0C165.5,1309.2,165.9,1309.2,166.4,1309.5z"/>
						<path fill="#EDECEC" d="M202.4,1301.5c0,0.1,0.1,0.3,0.1,0.4c-0.2,0-0.4,0.1-0.5-0.1C202,1301.5,202.2,1301.5,202.4,1301.5z"
							/>
						<path fill="#B34F4E" d="M199.4,1302.2c-0.1,0-0.2,0-0.2,0c-0.1-0.2-0.1-0.4,0.2-0.4C199.5,1301.8,199.5,1302,199.4,1302.2z"/>
						<path fill="#EDECEC" d="M185.3,1302.8c0,0.2,0,0.3,0,0.5c-0.1-0.1-0.3-0.2-0.4-0.3C185,1302.9,185.1,1302.9,185.3,1302.8z"/>
						<path fill="#F4F4F5" d="M268.5,1344.3c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2-0.8-0.4-1.5,0-2.3c0.2,0.4,0.4,0.7,0.6,1.1
							C268.3,1343.5,268.3,1343.9,268.5,1344.3z"/>
						<path fill="#F4F4F5" d="M268,1341.7c0.1,0.2,0.1,0.3-0.1,0.5C267.8,1342,267.8,1341.8,268,1341.7z"/>
						<path fill="#C3B3A6" d="M260.4,1322.2c0,0,0.1,0,0.1,0C260.5,1322.2,260.5,1322.2,260.4,1322.2L260.4,1322.2z"/>
						<path fill="#EDECEC" d="M252.9,1311.7c-0.2,2.5,0.2,5,0.2,7.5c0,1-0.6,2.2-0.7,3.3c-0.5,3.7,0,7.3,1.3,10.7
							c0.7,1.9,1.8,2.4,3.5,1.5c0.2-0.1,0.4-0.5,0.7-0.2c0.3,0.2,0.2,0.6,0,0.8c-0.6,0.8-0.2,1,0.5,1.4c1.4,0.9,1.7,1.9,1.2,3.6
							c-0.1,0.2-0.2,0.6-0.1,0.7c1.5,1.6-0.1,4,1.3,5.8c0.5,0.6,0.6,2.1,0.1,3.2c-0.6,1.3,0.3,2.7-0.1,4.1c-0.6,0.5-0.3,1.5-1,2
							c-1.3,0.5-1.1,2.1-2.1,2.8c-0.5-0.4-0.1-1.1-0.4-1.6c-0.6,0.2-0.5,0.7-0.7,1.1c-0.5,1-0.8,2.2-1.9,2.9c-1.5,1-3.4,1.1-5.1,1.6
							c-0.4,0.1-1-0.4-1.3,0.2c-0.2,0.5,0.4,0.7,0.7,1c0.4,0.5,0.9,0.9,1.5,1.2c0.8,0.5,1.6,1.2,2.3,1.8c0.2,0.2,0.4,0.5,0.3,0.8
							c-0.1,0.3-0.5,0.3-0.8,0.2c-0.8,0-1.3-0.5-1.9-1c-2.9-2.6-5.5-5.4-7.7-8.6c-1.4-2-3-3.7-3.9-6c0.3-0.2,0.3-0.5,0.2-0.8
							c-1-1.6-1.2-3.6-2.7-5c-0.3-0.3-0.5-0.6-0.6-1c-2.5-4.7-4.6-9.6-6.4-14.6c-0.6-1.7-1.3-3.4-1.6-5.3c-0.1-1.1-1.1-2.5-1.3-3.8
							c-1-5.4-0.4-10.7,2-15.7c0.9-1.9,2.1-3.5,3.7-4.8c0.2-0.2,0.5-0.3,0.3-0.6c-0.2-0.3-0.7-0.5-0.9-0.3c-0.9,0.6-1.3,0-1.7-0.6
							c0.4-0.6,1.1-0.8,1.7-1.1c0.9,1.6,2.2,0.6,3.4,0.6c0.5,0,1.1-0.3,1.3,0.3c0.2,0.7-0.5,0.6-0.9,0.8c-0.3,0.1-0.6,0.3-0.8,0.3
							c-1.4,0.1-1.9,1.3-1.9,2.2c-0.2,2.4-1.1,4.8-0.4,7.3c0.8,3.3-0.3,6.9,1.8,10c0.1,0.2,1,1.6-0.7,1.1c-0.4-0.1-0.5,0.2-0.5,0.5
							c0,0.5,0.1,1,0.2,1.4c0.3,0.8,0.5,1.6-0.8,1.6c-0.4,0-0.3,0.5-0.2,0.5c1.4,0.2,1.1,0.9,0.6,1.8c-0.1,0.1,0.1,0.4,0.2,0.5
							c1,0.9,1.3,2,1.3,3.3c0,0.5,0.2,0.9,0.7,0.7c0.6-0.1,0.2-0.5,0.1-0.8c-0.1-0.3-0.3-0.6,0-0.8c0.2-0.2,0.5,0,0.6,0.2
							c0.2,0.2,0.5,0.4,0.7,0.1c0.3-0.3-0.1-0.5-0.3-0.7c-0.3-0.4-0.1-0.6,0.4-0.7c0.7-0.1,1.1,0.3,1.3,0.9c0.6,2.1,1.3,4.1,2,6.1
							c1.2,3.5,1.7,7.2,3.1,10.7c0.9,2.3,1.9,4.4,3.1,6.5c-0.5-2.6-2.2-4.7-2.8-7.2c-0.4-1.6-1.5-3.1-0.8-4.9
							c0.1,0.4,0.3,0.7,0.4,1.1c1.3,4.8,3.2,9.4,6,13.5c0.9,1.3,2,2.9,3.8,2.5c1.7-0.4,2.2-2.2,2.6-3.8c0.9-4.3,0.3-8.5-0.5-12.7
							c0.1-0.2,0.1-0.4-0.1-0.6c0-0.1-0.1-0.2-0.1-0.3c0.3-0.6,0.4-1.2-0.3-1.6c-0.5-1.2-1-2.3-1.5-3.5c0.6-0.3,1.1-0.6,0.9-1.3
							c-0.3-1.2-0.7-2.4-1.1-3.6c-0.1,0-0.2,0-0.3,0c-0.2,1.2-0.3,2.5-0.5,3.7c-0.3,0.8-0.5,1.6-0.8,2.3c-0.9,1.9-1.9,3.8-4.2,4.4
							c-1.6-1.1-3.1-2.3-3.7-4.2c-0.1-1.2-1.1-2.2-1.1-3.4c0.6-0.2,0.6-0.8,0.6-1.2c0.2-2.4,0.5-4.7,0.6-7.1c0-1.2,0.5-2.4,0-3.6
							c0.6-0.6,0.5-1.2,0.2-1.9c0.1-0.3,0.3-0.6-0.1-0.9l0,0c0.6-0.4,0.6-0.9,0.3-1.5l0,0c0.6-0.3,0.4-0.7,0.1-1.1l0,0
							c0.5-0.3,0.3-0.6,0.2-1c0.2-0.1,0.2-0.2,0.1-0.4c0-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.1,0-0.2-0.1c0,0.1,0,0.2,0,0.2
							c-0.2,0.1-0.2,0.2-0.1,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1-0.2-0.3-0.4-0.4-0.6c-0.4-0.4-1-1-0.3-1.4c0.7-0.4,1-1.9,2.2-0.9
							c0.4,1.1,0.5,2.2,0.2,3.4c-0.3,1.1-0.2,2.2,0.2,3.3c0.5,1.2,1.7,1.1,2.5,0.9c0.9-0.3,0.3-1.2,0.1-1.7
							c-0.5-1.6-1.1-3.1-1.8-4.6c-0.2-0.4-0.5-0.7-0.1-1.1c0.3-0.3,0.5-0.9,1.1-0.8c0.6,0.1,0.7,0.6,0.9,1.1
							c0.4,1.3,0.6,2.7,1.1,3.9c0.5,1.4,0.7,1.4,1.9,0.4l0,0.1c0.3,0.9-0.4,1.5-0.6,2.4c1.5-0.7,2-1.9,1.4-2.9
							c0.4-0.8,0.4-1.6-0.4-2.4c-0.1,0.7-0.3,1.3-0.4,1.9c-0.7-0.7-0.6-1.8,0.2-2.8c0.2,0.1,0.2,0,0.1-0.1c0.6-2-0.1-2.9-3.1-3.3
							c0.8-0.5,1.6-0.6,2.4-0.4c0.3,0.1,0.6,0.1,0.8-0.2c0.2-0.3-0.1-0.5-0.3-0.7c-0.5-0.6-1-1.2-1.4-1.8c0-0.1-0.1-0.2-0.1-0.3
							c0.3,0,0.6-0.1,0.9-0.1C251.2,1308.9,252.2,1310.2,252.9,1311.7z M248.3,1334.1c0.5-0.4,1.6-0.5,1.2-1.6
							c0.5-0.4,0.3-0.8,0-1.2c0-0.4,0-0.8,0-1.2l0.1-0.1l-0.1-0.1c0-0.1,0-0.2,0-0.3c0.4-1.9,0.1-3.7-0.7-5.4
							c-0.5-1.1-1.5-1.1-2.3,0.1c-0.8,0.7-1.4,1.5-1.6,2.6c-0.5,0.6-0.4,1.4-0.8,2.5c1-0.9,1.7-0.1,2.4-0.3c0.8,1.2,0.7,2.5,0.8,3.8
							c-0.2,0.3-1.1,0.3-0.5,1c0.5,0.5,0.9,1.1,1.7,0.6C248.7,1334.4,248.4,1334.2,248.3,1334.1z M244.8,1333.7
							c0.2,0,0.6-0.1,0.5-0.3c-0.3-0.8-0.8-1.6-1.2-2.4c-0.5,0.7-0.4,1.4-0.2,2c-0.5,1.2-0.6,2.5,0,4.2
							C244.3,1335.7,244.6,1334.7,244.8,1333.7z M248.4,1338.7c0.5-0.4,1-1,0.9-1.8c-0.1-0.5-0.9-0.5-1.3-0.2c-0.5,0.4-1.2,1-1,1.8
							C247.2,1339.1,247.8,1339,248.4,1338.7z M238.1,1340.6c-0.3,1,0,1.4,0.8,1.4C238.5,1341.7,238.6,1341.1,238.1,1340.6z"/>
						<path fill="#C3B3A6" d="M238.7,1352.6c0.9,2.2,2.6,4,3.9,6c2.2,3.2,4.8,6,7.7,8.6c0.6,0.5,1.1,1,1.9,1c0.3,0,0.6,0.1,0.8-0.2
							c0.1-0.3-0.1-0.6-0.3-0.8c-0.8-0.6-1.5-1.3-2.3-1.8c-0.5-0.3-1-0.7-1.5-1.2c-0.3-0.3-0.9-0.5-0.7-1c0.3-0.7,0.9-0.1,1.3-0.2
							c1.7-0.6,3.6-0.6,5.1-1.6c1-0.7,1.3-1.9,1.9-2.9c0.2-0.4,0.1-0.9,0.7-1.1c0.2,0.5-0.1,1.2,0.4,1.6l0,0
							c-0.2,0.1-0.5,0.2-0.1,0.4c0,0.2-0.1,0.4-0.1,0.6c-0.4,0.6-0.9,1.1-1.3,1.7c-1.4,0.8-2.2,2-2.5,3.5c-0.7,0.2-0.9,0.8-0.5,1.3
							c0.5,0.6,1.2,0.9,1.9,0.3c0.2,0,0.3,0.1,0.5,0.1c0.5,0.6,1.1,1.2,1.8,1c0.9-0.2,1.1,0.3,1.4,0.8c-0.2,0.2-0.4,0.5-0.6,0.7
							c-2.4,0.7-4.8,0.3-7.1-0.3c-1.7-0.7-3.1-1.8-4.5-2.9c-2.2-1.7-3.7-4.1-5.6-6c-0.5-0.7-1-1.3-1.4-2c-0.4-0.7-1-1.1-1.8-1
							c-0.5-0.9-0.9-2.1-1.9-2.4c-1.1-0.3-1.1-1.5-1.7-2c-1.2-1-0.1-1.6,0.2-2.3c-0.2-0.9-0.8-1.7-1.2-2.5c-1.7-3.3-3.5-6.4-4.9-9.8
							c-0.1-0.3-0.2-0.5-0.3-0.8c0.6,0,0.7,0.4,0.9,0.9c0.7,2.1,1.7,2.7,3.4,2.4c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2
							c1,2,2.1,4,3.1,6C236.4,1349.1,237.6,1350.8,238.7,1352.6z"/>
						<path fill="#B34F4E" d="M231.9,1340.8c-1.7,0.3-2.7-0.4-3.4-2.4c-0.2-0.5-0.3-0.9-0.9-0.9c-0.4-0.8-0.9-1.6-1.3-2.4
							c0-0.2,0-0.5-0.1-0.7c-1.6-4-2.8-8.1-3.6-12.3c-0.8-4.3-1.4-8.6-0.7-13c0.5-3.4,1.2-6.7,3.5-9.5c0.2,0.3,0.4,0.5,0.6,0.8
							c-2.6,5.2-2.5,10.8-1.7,16.4c1,7.6,3.7,14.6,6.7,21.6C231.3,1339.2,231.6,1340,231.9,1340.8z"/>
						<path fill="#B34F4E" d="M227.5,1300.7c0.5,0.9,0.9-0.3,1.3,0c-0.5,0.7-1,1.5-2.1,1.7C226.8,1301.6,226.9,1301.1,227.5,1300.7z
							"/>
						<path fill="#EDECEC" d="M231.1,1296.1c-0.4,0.4-0.5,1.2-1.3,1.2C229.9,1296.6,230.2,1296.1,231.1,1296.1z"/>
						<path fill="#EDECEC" d="M231.5,1292.1c0-0.1,0-0.2,0-0.2c0,0,0.2,0,0.2,0c0,0.1,0,0.2,0,0.2
							C231.7,1292.1,231.6,1292.1,231.5,1292.1z"/>
						<path fill="#B34F4E" d="M232.4,1341.2c-0.1-0.1-0.2-0.1-0.3-0.2C232.1,1341.1,232.2,1341.1,232.4,1341.2z"/>
						<path fill="#B34F4E" d="M229.9,1291.5c-0.2,0-0.4-0.1-0.5-0.1C229.5,1291.4,229.7,1291.5,229.9,1291.5z"/>
						<path fill="#B34F4E" d="M255.5,1313.1c-0.7-0.2-0.9-0.6-0.6-1.2C255.4,1312.1,255.6,1312.5,255.5,1313.1z"/>
						<path fill="#B34F4E" d="M254.1,1310.5c-0.5-0.1-0.6-0.3-0.4-0.7C254.2,1309.8,254.4,1310.1,254.1,1310.5z"/>
						<path fill="#C3B3A6" d="M242.8,1320.2c0.3,0.7,0.4,1.3-0.2,1.9c-0.1,0-0.1,0-0.2,0c-0.1-0.3-0.3-0.5-0.1-0.8
							c0.1-0.3,0.2-0.6-0.1-0.7c-0.3-0.1-0.8,0-1.1,0.1c-0.4,0.2-0.1,0.5,0,0.8c0.1,0.6,0.3,1.1,1,1.2c-0.1,0.6-0.1,1.3-0.2,1.9
							c-0.1,1.7-0.2,3.3-0.4,5c0,0.4-0.1,0.9-0.6,0.9c-0.4,0-0.4-0.5-0.6-0.8c-0.3-0.6-0.1-1.4-0.9-2c-0.3,1.1-0.6,2-0.2,3.1
							c0.1,0.6,0.2,1.3,0.4,1.8c1.2,2.8,1.2,6,2.1,8.9c-0.7,1.8,0.5,3.3,0.8,4.9c0.6,2.5,2.2,4.7,2.8,7.2c-1.2-2.1-2.3-4.3-3.1-6.5
							c-1.3-3.5-1.8-7.2-3.1-10.7c-0.7-2-1.4-4.1-2-6.1c-0.2-0.6-0.6-1-1.3-0.9c-0.5,0-0.7,0.2-0.4,0.7c0.1,0.2,0.5,0.4,0.3,0.7
							c-0.3,0.3-0.5,0.1-0.7-0.1c-0.2-0.2-0.4-0.3-0.6-0.2c-0.3,0.2-0.1,0.5,0,0.8c0.1,0.3,0.5,0.7-0.1,0.8
							c-0.5,0.1-0.7-0.3-0.7-0.7c0-1.3-0.3-2.4-1.3-3.3c-0.1-0.1-0.3-0.4-0.2-0.5c0.5-0.9,0.8-1.6-0.6-1.8c-0.1,0-0.1-0.5,0.2-0.5
							c1.3,0,1.1-0.8,0.8-1.6c-0.1-0.5-0.2-1-0.2-1.4c0-0.3,0.1-0.6,0.5-0.5c1.6,0.5,0.8-0.9,0.7-1.1c-2.2-3.1-1-6.7-1.8-10
							c-0.6-2.5,0.2-4.9,0.4-7.3c0.1-0.8,0.5-2.1,1.9-2.2c0.3,0,0.6-0.2,0.8-0.3c0.4-0.1,1.2,0,0.9-0.8c-0.2-0.6-0.8-0.4-1.3-0.3
							c-1.2,0-2.5,1-3.4-0.6c3.5-2.1,7-1.5,10.5,0.1c2.5,1.1,4.5,2.9,6.3,4.9c-0.6,0.6,0,0.9,0.3,1.3c0.5,0.8,1.4,1.3,1.6,2.3
							c-0.3,0-0.6,0.1-0.9,0.1c-2.2,0.1-4.1,2-4.7,4.5c-0.4,0.4-0.2,0.7,0,1.1l0,0c-1.2-1-1.5,0.5-2.2,0.9c-0.7,0.4-0.1,1,0.3,1.4
							c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.1,0.3,0.2,0.4l0,0c-0.2,0.3-0.3,0.7-0.1,1.1c0,0,0,0,0,0c-0.3,0.5-0.4,0.9-0.3,1.5l0,0
							C242.6,1319.6,242.4,1320,242.8,1320.2z M233.1,1309.9c-0.4,2.6,1.2,4.5,1.6,6.8c0.1-0.9,0-1.9,0.2-2.7
							C235.3,1312.1,234.5,1311,233.1,1309.9z M237.9,1323.8c-0.9,1,0.1,1.7,0.2,2.5c0.1,0.6,0.5,0.8,1,0.8c0.3,0,1.3-2,1.2-2.3
							C239.8,1323.8,238.8,1324,237.9,1323.8z M239.5,1308.5c-1.6-0.5-4.2,0.3-4.9,1.2c-0.2,0.3-0.3,0.5-0.1,0.8
							c0.3,0.4,0.6,0.2,0.9,0C236.7,1309.8,237.9,1309.1,239.5,1308.5z M241.5,1306c-0.2-0.4-0.6-0.7-0.8-0.4
							c-0.8,0.9,0.4,1.7,0.3,2.6c0,0.3,0.4,0.6,0.6,0.3C242.3,1307.7,241.5,1306.8,241.5,1306z M238.6,1316.6
							c-0.3-0.3-0.5-0.8-0.9-0.5c-0.5,0.4-0.3,1.1-0.1,1.6c0.1,0.4,0.4,0.8,0.9,0.4C239.1,1317.7,238.6,1317.1,238.6,1316.6z
							 M237,1304.9c-0.1-0.3-0.3-0.6-0.6-0.4c-0.3,0.2-0.6,0.5-0.4,1c0.2,0.3,0.5,0.5,0.9,0.3C237.3,1305.5,237.1,1305.2,237,1304.9
							z"/>
						<path fill="#B34F4E" d="M250,1307.8c-0.2-1-1-1.6-1.6-2.3c-0.3-0.4-0.9-0.7-0.3-1.3c2.1,2.3,4.1,4.7,5.3,7.7
							c-0.2,0-0.4,0-0.5,0C252.2,1310.2,251.2,1308.9,250,1307.8z"/>
						<path fill="#CA0E21" d="M226.3,1335.1c0.4,0.8,0.9,1.6,1.3,2.4c0.1,0.3,0.2,0.5,0.3,0.8c-0.6,1,0.3,1.7,0.6,2.4
							c0.7,1.5,0.2,3.4,1.6,4.7c0.9,0.9,1.3,1.8,1.6,3c0.3,1.1-0.3,2,0.1,3c-0.1,0.1-0.2,0.1-0.4,0.2c-0.2-0.1-0.4-0.2-0.4,0.2
							c-7.1,3.5-13.2,8.5-19.7,13c-3.2,2.2-6.4,4.5-9.6,6.8c0.2-0.9,0.8-1.4,1.4-1.9c1.3-1.2,2.6-2.3,3.9-3.5
							c1.9-1.7,2.8-4.4,5.4-5.4c0.1-0.1,0.2-0.4,0.3-0.7c0.2-0.9,1.1-1.2,1.4-2.1c1,0.4,1.3-0.6,1.9-1c0.2-0.2,0.5-0.4,0.7-0.4
							c0.6,0,1.3,0,1.3-0.6c0-0.6-0.6-1.1-1.2-1.4c-0.7-0.4-1.3-0.1-1.9,0.4c-0.5,0.4-1,0.8-1.4,1.2c0-1.7,0-1.7-1.7-1.1
							c-0.5,0.2-1,0.4-1-0.4c0-0.3,0.2-0.6-0.2-0.8c-0.4-0.1-0.6,0.2-0.9,0.4c-0.3,0.3-0.6,0.7-0.9,0.9c-1.1,0.9-1.6,3.3-1,4.7
							c-1.2,1-1.4,2.5-2,3.8c-1.2,2.3-2.3,4.6-4.4,6.2c1-2.9,2.5-5.6,4.1-8.1c1.1-1.8,1.4-3.9,0.2-5.1c-0.6,0.2,0.2,1.4-0.8,1.2
							c-0.9-0.1-1-0.8-1-1.5c0-0.5-0.1-0.9-0.4-1.2c0.7-3.7,1.2-7.5,1.2-11.3c-0.9,2.3-0.7,4.9-1.6,7.4c-0.7-2.3,0.1-4.4,0-6.5
							c-1.7,2.8-2.3,5.9-2.4,9.2c-0.5-0.6-0.7-1.2-0.9-1.8c-0.1-0.2,0-0.4,0-0.6c0.2-0.5,0.3-1.1,0.2-1.8c-0.6,0.3-1,0.9-1.4,1.3
							c-0.3,0.1-0.5,0.3-0.4,0.7c-0.1,0.1-0.2,0.3-0.3,0.4c-0.8-0.1-1.1,0.6-1.5,1c-0.3,0.3-0.7,0.6-1,0.2c-1.1-1.4-3.5-2-3.1-4.5
							c0.1-0.4-0.3-0.8-0.5-1.2c2.2-3.6,5-6.7,8-9.7c0.2-0.2,0.3-0.8,0.8-0.4c0.4,0.3,0,0.6-0.2,0.9c-0.7,1-1.5,2-2.2,3.1
							c-1.3,2-2.6,4.1-3.2,6.4c-0.1,0.5-0.2,1.1,0.4,1.5c0.6,0.3,1-0.1,1.4-0.4c0.5-0.5,1.1-1,1.3-1.7c0.1-0.1,0.3-0.2,0.4-0.3
							c1.6-1.9,2.8-4,3.4-6.3c0.3-0.9,0.8-1.7,1-2.6c0.6-2.4,1.7-3.4,4.2-3.5c0.5,1.2-0.7,2.1-0.4,3.3c0,0,0,0.1,0,0.1
							c0,0,0-0.1,0-0.1c0.6-0.3,0.5,0.2,0.7,0.6c0.3,0.5,0.9,0.6,1.4,0.5c0.3,0,0.5-0.3,0.3-0.5c-0.7-1.6,0.2-3.3,0-4.9
							c-0.2-1.1-0.3-2.6,0.3-3.3c0.9-0.9,0.7-2.1,1.3-3c0.2-0.3,0.4-0.7,0-0.9c-1.4-0.9-0.8-2.2-0.5-3.3c0.4-1.4,1-2.6,0.1-4.2
							c-1.1-2.1-0.6-4.4-0.2-6.7c0.3-1.6,0.2-3.3,0.3-4.9c0.2-0.2,0.3-0.5,0.2-0.7c0-0.1-0.2-0.1-0.2-0.1c-0.3,0.2-0.2,0.5-0.1,0.8
							c-0.8,1.9-0.4,4.1-1.1,6c-1.6,1.1-2.7,2.7-4.3,3.7c-0.2,0.1-0.7,0.3-0.4,0.8c1.8-0.5,3.2-1.7,4.5-2.9
							c-0.5,4.3-0.4,8.6-0.4,12.9c0,1.4,0.1,2.8-0.9,4c-0.6,0.3-1.2,0.6-1.7,1.1c-0.9,0.9-1.9,2.2-3,2.4c-1.6,0.3-2.8,1-4,1.9
							c-3.6,2.8-7,6-9.6,9.8c-0.5-0.4-1.1,0.2-1.7-0.2c-1.1-1-2.6-0.9-3.9-1.4c-0.4-0.2-1.2-0.3-1.4,0.6c-0.2,0-0.4-0.2-0.5-0.1
							c-0.3,0.2,0.1,0.3,0.1,0.4c0.2,0.3,0.5,0.6,0.7,0.9c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4-0.1-0.7-0.2-1.1-0.3c-1.4-0.4-2-2-3.3-2.5
							c-0.1-0.6-0.3-1.1,0.3-1.6c0.4,0.3,0.9,0.6,1.3,0.9c0.2,0.2,0.5,0.5,0.8,0.2c0.3-0.2,0.2-0.5,0.2-0.8
							c-0.1-1.6-0.8-2.3-2.3-2.5c-0.5-0.1-0.9,0-1.2,0.3c-0.3,0.5,0.4,0.6,0.5,0.9c-0.4,0.2-1.1,0-0.6,0.8c0.3,0.5,0.4,1.2,0.6,1.8
							c0,0.1-0.1,0.2-0.1,0.3c-0.4,0.1-0.9-1-1.3,0c-0.3-0.7-0.6-1.4-0.8-2.1c-0.2-0.8,0.5-1.5,0.2-2.3c0-0.1-0.1-0.3-0.1-0.4
							c0.2,0.1,0.4,0.4,0.7,0.1c0.6-0.1,0.7-0.6,1-1c0.3-0.5,0.7-1,0.2-1.6c-0.4-0.5-0.9-1-1.7-0.9c-0.9,0.1-0.3,1-0.8,1.4
							c-0.3-1.2-0.4-2.5-1.3-3.6c0,0,0,0,0,0c0-0.5-0.1-0.9-0.5-1.2c0.3-0.2,0-0.5,0.1-0.8c0,0,0.1,0,0.1,0c0,0-0.1,0-0.1-0.1
							c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.1,0.1-0.1,0.1-0.2c0.4-0.2,0.4-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.5c0-0.1,0.1-0.1,0.1-0.2
							c0.7-1.6-0.3-4.3-2.1-5.8c-0.3-0.6-0.8-1.3,0.5-1.1c2,0.3,3.6-0.7,5.2-1.6c0.7-0.4,0.8-0.9,0.5-1.6c0.3-1.6,0.2-3.2-0.8-4.6
							c-0.2,1.3,0.9,2.7-0.6,3.8c-0.4-0.4-0.9-0.7-1-1.4c-0.3-2.7-0.3-2.8-2.9-2.5c-0.8,0.1-1.7-0.5-2.4,0.4
							c0.4,0.5,1.1-0.1,1.3,0.5c0.2,0.7-1.1-0.1-0.8,0.8c0.3,0.7,0.8,0.4,1.3,0.3c1.2-0.4,2.2,0.1,2.4,1.4c0.2,0.8,0.6,1.2,1.1,1.7
							c1.3,1.2,1.3,1.2-0.4,1.9c0.3-1.6-1.2-2.5-1.8-3.6c-0.2-0.3-0.6,0-0.8,0.3c-0.8,1.1-2,1.6-3.3,1.9c-0.6,0.2-1,0.4-0.7,1.1
							c-0.9-0.7-2.1-0.6-2.9-1.3c1.4-0.2,2.5-1.1,3.7-1.7c0.3-0.2,0.6-0.7,0.4-1c-0.3-0.5-0.7,0-0.9,0.2c-1,1-2.3,1.2-3.7,1.5
							c3.7-2.3,5.5-6.3,8.8-8.9c1.8-1,2.2,0.4,2.9,1.6c-0.4-1-0.1-1.6,0.9-1.8c0.2,0.1,0.6,0.2,0.6,0.3c-0.4,1.4,1,1.8,1.4,2.8
							c0.5,1.3,1.5,1.9,3.1,2.3c-0.9-1.1-1.5-2-1.4-3.2c0.1-0.5-0.7-0.6-1-1c-0.5-0.5-1.5-0.7-1.2-1.7c1.1,1.4,2.6,0.6,3.9,0.9
							c-1-1-2.5-0.5-3.7-1c-0.1-1,0.5-0.7,1.1-0.6c0.7,0.1,1.6,0.2,1-1c1.4-0.1,1.5-0.9,1.2-2c0.8-0.2,1.7-0.3,2.4-0.9
							c0.4-0.5,1.6,0.5,1.6-0.8c0.4,0.2,0.8,0.3,1.4,0.6c-1.2,0.5-2.1,1.6-3.4,1.4c-0.1-0.1-0.2-0.3-0.4-0.1c0,0,0.1,0.2,0.1,0.3
							l0,0c-0.6,0-1.3,0.1-1.1,0.9c0.2,1,0.9,1,1.7,0.7c0.7,0.8,1.8,1.3,2.1,2.5c-0.7,0.1-1.4,0-2.1-0.2c-0.6-0.8-0.8,0-1.2,0.3
							c-0.1,0-0.1,0-0.2,0c0.1,0,0.1,0,0.2,0.1c0.3,0.4,0.6,0.4,0.9,0.1c0.4,0.3,0.8,0.5,1.3,0.8c1.4,2.8,2.2,3.3,4.3,2.8
							c-0.5-0.7-1.1-0.8-1.9-1c-1.1-0.2-1.7-0.8-1.3-2c0.4-0.1,0.8-0.2,1.2-0.2c0.6,0.2,1.2,0.1,1.7,0c0.5,0,0.9-0.3,0.5-0.9
							c-0.1-0.3-0.1-0.7,0.4-0.4c0.3,0.2,0.5,0.5,0.8,0.7c-0.2,0.2-0.8,0.4-0.1,0.6c0.5,0.1,1.1,0.1,1.6,0c1.4-0.2,2.8,0.2,4.5-0.3
							c-2.1-0.8-3.9-0.4-5.6-0.5c-0.2-0.5,0-0.9,0.4-1.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1c0-0.9,0.5-1.3,1.3-1
							c1,0.3,1.4-0.2,1.3-1.2c0.5,0.3,0.4,1.3,1.1,0.9c0.7-0.3,0.2-0.9,0-1.4c0.3,0,0.6-0.1,0.9-0.1c0.1,0.5,0.2,0.9,0.4,1.4
							c0.2,0.4,0.6,0.6,1,0.3c0.5-0.5-0.2-0.4-0.4-0.6c-0.4-0.4-0.4-0.7,0.2-1c0.3-0.1,0.6-0.4,1-0.6c0.2,0,0.4,0.1,0.5,0.1
							c0,0.2,0.1,0.3,0.2,0.1c0.1,0,0.3,0.1,0.4,0.1l0.1,0.1l0.1,0c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.1,0.2,0.1
							c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.5,0.4,1.3,1.4,0.5c1.1-0.8,2.8-0.2,3.4,0.8c-1.2,0.3-2.5,0.5-3.7,1.1c-0.5,0.2-1,0.3-1.4,0.8
							c0.6,0.1,1.1,0,1.6-0.3c1.3-0.3,2.6-0.5,3.9-1c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0.1,0.8,0.3,1-0.2c0.2-0.4-0.4-0.5-0.6-0.7
							c-0.4-0.3-0.8-0.6-1.3-1c0.4-0.6,0.8-1.2,1.2-1.9c0.6,0.2,1.2,0.3,1.5-0.5c0.1,0.1,0.3,0.1,0.3-0.1l0,0c0.3-0.4,0.7-0.8,1-1.3
							c0.7,1,1.5,0.7,1.9-0.2c0.5-1.4,1.7-2.3,2.2-3.6c0.4,0.6,0.4,1.2,0.1,1.8c1.3-0.5,3.8-4.6,3.8-5.9c-0.1-0.9,1.2-1.2,0.9-2.2
							c0.2-0.1,0.3-0.2,0.5-0.4l0,0c0.5,0.7,1.3,0.6,2,0.6l0,0c0.2,0.2,0.5,0.3,0.7,0l0,0c-0.1,0.3,0.2,0.3,0.3,0.4
							c0.1,0.4,0.3,0.7,0.7,0.9c0.4,0.1,1.1-0.1,1.2,0.6c0,0.6-0.7,0.4-1,0.6c-0.2,0.1-0.5,0.2-0.4,0.5c0,0.1,0.2,0.3,0.4,0.4
							c0.5,0.2,0.9,0.3,1.4,0c0.5-0.3,0.8-0.1,1,0.4c-1.8,0.4-2.8,1.6-3.8,3.1c-0.6,0.9-0.9,1.9-1.5,2.6c-1.4,1.5-1.5,3.4-1.9,5.2
							c-0.2,0.7-0.7,1.4-0.2,2.2c0.8,1.3,0.7,2.7,0.4,4.1c-0.4,1.5-0.4,2.8,0.5,4.2c0.7,1.1,0.3,3,2.3,3.4c0.1,0,0.3,0.4,0.3,0.6
							c0.9,2.6,2.4,4.9,3.7,7.4c0.1,0.2,0.3,0.5,0.2,0.7C224.5,1333.1,225.6,1334,226.3,1335.1z M191.3,1316.6
							c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.6,0.3,0.8,0.2c2.4-0.7,5.1-0.9,7.2-2.5c-2.5,1.1-5.3,1.5-8,2.3
							C191.5,1316.7,191.4,1316.6,191.3,1316.6c-0.2-0.2-0.5-0.3-0.8-0.2c0,0,0,0.2,0,0.2C190.8,1316.9,191,1316.7,191.3,1316.6z
							 M198.2,1319.6L198.2,1319.6L198.2,1319.6c-1.6-0.6-3.1-0.1-4.5,0.5c-0.6,0.1-1.2,0.1-1.8,0.2c-0.4-1-1.4-0.7-2.1-1
							c-1.2-0.4-2.2-1.4-3.6-1.6c-1.3-0.6-2.4-1.5-3.1-2.7c-0.3-0.5-0.5-0.6-0.9-0.1c-1.1,1.3,0.2,1.7,0.8,2.3
							c-0.8,1.5-0.6,2.7,0.7,3.4c0.6,0.3,1.1,0.8,1.6,1.3c1.1,1.3,3,1.6,4,0.3c0.9-1.2,1.8-1.6,3.3-1.3c0.8,0.2,1.6,0.2,2.3,0.1
							c1.2-0.1,2.3-0.2,3.2-1.1C198.1,1319.7,198.2,1319.7,198.2,1319.6z M203.1,1313.6c-0.4,0.3-0.8,0-1.1,0
							c-0.3,0-0.4,0.3-0.4,0.5c0,0.3,0.2,0.4,0.5,0.4c0.6-0.1,1.4,0.1,1.2-1c1.6-0.7,3-1.8,4.5-2.7c0.3-0.2,0.8-0.4,0.6-0.9
							c-0.2-0.4-0.6-0.1-0.9,0c-1.3,0.3-2.3,1-3.5,1.5C202.8,1311.9,202.4,1312.5,203.1,1313.6z M206.3,1342c-1.1,0.9-0.7,1.9-0.2,3
							c0.4,0.8,1,0.8,1.7,0.3c1.1-0.8,1.2-3.1,0.2-3.9C207.2,1340.8,206.6,1341,206.3,1342L206.3,1342z M212.2,1344.3
							c-0.1,0.4-0.2,0.6-0.1,0.8c0.3,2.4,1.4,4.6,1.5,7c0,0.2,0.3,0.5,0.4,0.5c0.9,0.2,1.5,1,2.4,1.3c0.4,0.1,0.9,0.6,1.3,0
							c0.3-0.5,0.6-1,0.1-1.6c-0.6-0.9-1.4-1.7-1.8-2.6c-0.5-1.1-2-1-2.1-2.3C213.8,1346.1,213.1,1345.3,212.2,1344.3z
							 M181.3,1331.9c1.4-0.4,2.3-1.2,3.2-1.9c2.4-1.8,4.5-3.8,5.8-6.6c0.2-0.4,0.6-0.9,0.2-1.2c-0.6-0.4-0.9,0.4-1.1,0.7
							c-0.9,1.4-2.2,2.4-3.2,3.6C184.9,1328.4,183.2,1330,181.3,1331.9z M216.9,1349.7c0.3-1.8-0.3-3.1-1.7-4.2
							C214.9,1347.3,215.9,1348.4,216.9,1349.7z M205.6,1315c-2.1,1.5-4.2,3-6.5,4.2C201.8,1318.5,203.8,1316.9,205.6,1315z
							 M200.7,1329c1.4-0.5,1.7-1.7,2.1-2.8c0.1-0.2,0.1-0.5-0.2-0.6c-0.3-0.1-0.5,0.1-0.6,0.3
							C201.6,1326.9,201.1,1327.9,200.7,1329z"/>
						<path fill="#B34F4E" d="M224.8,1298c-0.2-0.5-0.5-0.7-1-0.4c-0.5,0.3-1,0.1-1.4,0c-0.2-0.1-0.4-0.2-0.4-0.4
							c-0.1-0.3,0.2-0.4,0.4-0.5c0.4-0.2,1,0.1,1-0.6c0-0.7-0.7-0.5-1.2-0.6c-0.4-0.1-0.6-0.5-0.7-0.9c0.3-0.2,0.8,0.1,1-0.3
							c-0.5-0.2-0.9-0.2-1.4,0l0,0c-0.2-0.2-0.5-0.2-0.7,0l0,0c-0.5-0.7-1.2-0.8-2-0.6l0,0c0.1-0.9,1-0.8,1.5-1.2
							c0.5-0.3,1.2-0.3,1.4-1c0.2,0,0.3-0.1,0.5-0.1c0.1,1.6,0.8,2.2,2.5,1.9c2-0.4,4-0.1,6,0.8c-1,1-1.6,2.2-2.9,2.6
							C226.6,1297.1,225.7,1297.6,224.8,1298z M224,1294.5c-0.3-0.2-0.6-0.3-1,0C223.4,1294.8,223.7,1294.7,224,1294.5
							c1,0.6,2,0.4,3,0.2C226,1294.5,225,1294.3,224,1294.5z"/>
						<path fill="#B34F4E" d="M216.1,1294.6c-0.3,1.5-1.1,2.7-2.2,3.8c-1.1,1.1-2,2.3-2.9,3.5c-0.7-1,0-2.6-1.5-3.3
							c-0.2-0.1,0.2-0.7,0.5-0.9C211.8,1296.2,213.8,1295.1,216.1,1294.6z M213.8,1297c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0
							c0,0,0,0.2,0,0.3C213.6,1297,213.7,1297,213.8,1297z"/>
						<path fill="#B34F4E" d="M212.6,1295.8c0.8-1.5,1.8-2,2.8-2.5c0.3-0.1,0.6-0.2,0.8,0.1c0.2,0.3,0,0.6-0.3,0.7
							C214.9,1294.6,213.9,1295.1,212.6,1295.8z"/>
						<path fill="#81070E" d="M224.8,1298c0.9-0.5,1.8-0.9,2.7-1.4c-0.7,1-1.4,2-2,3c-2.3,2.8-3,6.1-3.5,9.5
							c-0.7,4.4-0.1,8.7,0.7,13c0.8,4.2,2,8.3,3.6,12.3c0.1,0.2,0,0.5,0.1,0.7c-0.7-1.1-1.7-2.1-1.6-3.6c0-0.2-0.1-0.5-0.2-0.7
							c-1.2-2.5-2.7-4.8-3.7-7.4c-0.1-0.2-0.2-0.6-0.3-0.6c-2-0.3-1.5-2.2-2.3-3.4c-0.9-1.4-0.8-2.7-0.5-4.2
							c0.3-1.4,0.5-2.8-0.4-4.1c-0.5-0.8,0-1.5,0.2-2.2c0.4-1.8,0.5-3.7,1.9-5.2c0.7-0.7,1-1.8,1.5-2.6
							C221.9,1299.7,223,1298.5,224.8,1298z"/>
						<path fill="#B34F4E" d="M224.8,1290.9c0.1,0,0.2,0,0.3,0c0.2,0.4,0.5,0.7,0.3,1.2c-0.2,0.3-0.4,0.8-0.9,0.6
							c-0.2-0.1-0.6-0.4-0.6-0.6c0-0.5,0.4-0.3,0.7-0.4C225.5,1291.6,224.8,1291.2,224.8,1290.9z"/>
						<path fill="#B34F4E" d="M257.3,1360c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.3,0.1-0.4l0,0c1-0.7,0.9-2.3,2.1-2.8
							c0.2,0.8,1.2,0.6,1.4,1.3c-0.4,1.4,1.1,1.7,1.6,2.7c0.1,0.2,0.6,0.1,0.7-0.5c0.2-1-0.7-1.7-0.6-2.7c1.1,0.2,1.7,0.8,1.8,1.9
							c0,0.4,0.2,0.7,0.4,1.1c-0.2,1.3-0.6,2.6-1.4,3.7c-0.2-0.3-0.3-0.5-0.5-0.8c0.3-1.3-0.5-1.9-1.6-2.2c-0.2-0.2-0.5-0.3-0.5-0.1
							c-0.1,1.1-0.5,0.4-0.8,0.2c-0.8-0.7-0.4-1.3,0.2-1.8c0.5-0.4,0.3-0.8,0-1.1c-0.4-0.4-0.8-0.2-1,0.3c-0.4,1-0.8,2-1.2,3
							c-0.1,0.2,0,0.4,0.3,0.4c0.6-0.1,0.9,0.8,1.6,0.6c0.4,0.1,0.8,0.2,1.3,0.3c-1.4,1.1-1.4,1.1-1.9,0.6c-1-1.2-1.3-1.1-2.2,0.2
							c-0.4,0.2-0.7,0.3-1.1,0.5c-0.7,0.7-1.5,1.2-2.5,1.3c0.2-1.6,1-2.8,2.5-3.5c0.2,0.1,0.5,0.2,0.7,0.2l0,0.1l0-0.1
							C257.2,1361.4,257.5,1360.8,257.3,1360z"/>
						<path fill="#EDECEC" d="M261.6,1354.8c0.6-2.6,0.5-5.2,0.9-7.7c0.1-0.5,0-1.2,0.6-1.1c0.5,0,0.6,0.6,0.5,1.2
							c0,0.2-0.1,0.5,0,0.6c2,1.9,0.5,3.9,0.4,5.9c0,0.4-0.3,0.8-0.2,1.1c0.3,1.3-0.5,1.5-1.4,1.6
							C262.1,1355.8,261.9,1355.3,261.6,1354.8z"/>
						<path fill="#F4F4F5" d="M263.5,1363.7c0.9-1.1,1.2-2.4,1.4-3.7c1-0.6,0.5-2.1,1.7-2.5c0.1,0.2,0.2,0.4,0.3,0.5
							c-0.3,0.9-0.8,1.8-0.9,2.8c-0.5,1.1-1,2.2-1.6,3.3c-0.5,0.5-1,1-1.2,1.7c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
							c-0.1,0.1-0.1,0.2-0.2,0.2c-0.4,0.2-0.7,0.4-1.1,0.5c-0.3-0.5-0.1-0.9,0.2-1.3c0.3-0.3,0.8-0.5,0.5-1.1
							C262.4,1364.6,263.1,1364.3,263.5,1363.7z"/>
						<path fill="#81070E" d="M261.6,1354.8c0.3,0.5,0.6,1,0.9,1.5c-0.5,0.4-0.9,0.8-1.4,1.2c-0.2-0.7-1.3-0.6-1.4-1.3
							c0.7-0.5,0.4-1.5,1-2C260.9,1354.4,261.1,1354.8,261.6,1354.8z"/>
						<path fill="#B34F4E" d="M261.7,1343.1c0.4,0.4,0.4,0.8,0,1.2C261.4,1343.9,261.4,1343.5,261.7,1343.1z"/>
						<path fill="#81070E" d="M264.4,1364c0.5-1.1,1-2.2,1.6-3.3c0.1,0.1,0.2,0.3,0.3,0.3c1,0.6-1.2,1.4,0.1,1.9
							c1.3,0.5,0.5,1.1,0.1,1.8c-0.1,0.1,0.1,0.3,0.1,0.4c-1.3,0.4-0.4,1.8-1.1,2.5c-0.2,0.2-0.5,0.6-0.7,0.7
							c-1,0.1-1.7,0.2-0.7,1.4c0.3,0.4-0.5,0.7-0.9,0.5c-1.9-1-2.5,0.9-3.2,1.8c-0.6,0.7-1.1,1.4-1.8,2c-0.1,0-0.1,0-0.2,0
							c-0.4-1.1-1.2-0.6-1.8-0.4c-0.7,0.2-1.3,0.5-1.9,0.8c-2.5,1-4.7,2.3-7.2,3.3c-1,0.4-2,1.2-3,1.8c-0.7,0.4-1.6,0.8-2.3,0.8
							c-0.9,0-1.5,0-2.2,0.8c-0.4,0.6-1,0.3-0.9-0.5c0.1-0.6,0.1-1.2,0.3-1.7c0.4-1.4-0.3-1.4-1.3-1.2c-2.9,0.5-5.9,0.6-8.9,0.6
							c-2.2,0-4.4,0.1-6.6,0.2c-0.2-0.1-0.3-0.2-0.5-0.3c1.3-0.8,2.8-0.7,4.2-0.9c3-0.4,5.9-1.1,9-0.7c1.7,0.2,3.9,0.2,5.1-1.8
							c0.2-0.3,0.7-0.4,1-0.5c3.4-1.3,6.9-2.5,9.8-4.8c2.3,0.5,4.7,0.9,7.1,0.3c1.6-0.1,2.9-0.9,3.8-2.1c0.1-0.2,0.3-0.4,0.4-0.6
							c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.3,0.6-0.5c0,0.3-0.2,0.7,0.1,1c0.1,0.1,0.5-0.1,0.5-0.1
							C263.7,1365.6,264.8,1365,264.4,1364z"/>
						<path fill="#B34F4E" d="M166.1,1386.9c0.8,0,1.1,0.3,0.5,1C166.4,1387.6,166.3,1387.3,166.1,1386.9z"/>
						<path fill="#CA0E21" d="M221.7,1377.9c-1.6,0.3-3.3,0-4.9-0.2c-0.1,0-0.3-0.1-0.4-0.1l0,0c-3.6-1.1-7.2-0.9-10.8-0.4l0,0
							c-0.2-0.2-0.5-0.1-0.7,0l0,0c-0.1,0-0.3-0.1-0.4-0.1l0,0c-1.8-0.7-3.7-1-5.6-1c-1.8,0-3.7,0.1-5.5,0.1c0.8-0.6,1.7-1,2.7-1.2
							c4.1-0.9,7.7-2.8,11.3-4.9c0.6-0.4,1.3-0.7,2-0.9c6.7-2.6,13.3-5.5,19.8-8.6c2.1-1,4.2-2.2,6.6-2.7c1.5,0.9,3.5,0.8,4.6,2.5
							c0,0.1,0.3,0,0.4,0c2,1.9,3.4,4.3,5.6,6c1.4,1.1,2.8,2.2,4.5,2.9c-2.9,2.3-6.4,3.5-9.8,4.8c-0.4,0.1-0.8,0.3-1,0.5
							c-1.2,2-3.4,2-5.1,1.8c-3.1-0.4-6,0.3-9,0.7C224.4,1377.2,223,1377.2,221.7,1377.9z M243.6,1370.7c0.5-0.3,1-0.5,0.7-0.8
							c-0.5-0.6-0.7-1.7-1.8-1.6c-0.5,0.1-1,0-1.3-0.4c-0.5-0.5-1-0.4-1.6-0.2c-0.6,0.2-1.3,0.6-2,0.5c-1.1,0-1.7,0.6-2.3,1.3
							c-0.9,1-2.4,0.9-3.3-0.1c-0.4-0.4-1-0.9-1.4-0.9c-1.1,0.1-1.7-0.7-2.3-1.3c-0.4-0.4-0.7-0.7-1.2-0.5c-0.3,0.1-0.5,0.2-0.4,0.5
							c0.2,0.5,0.3,1.2,0.9,1.3c1.2,0.1,1.4,1.1,1.2,1.6c-0.8,1.6,0.1,2.1,1.3,2.6c0.4,0.1,0.7,0.4,0.9,0.7c1.1,1.1,2.4,1.2,3.8,1.2
							c1,0,1.4-0.4,1.1-1.4c-0.3-0.8,0.2-1.1,0.8-1.3c0.6-0.3,1.2-0.3,1.9-0.4C240.2,1371.3,242,1370.9,243.6,1370.7z"/>
						<path fill="#EDECEC" d="M235.7,1357.9c-2.4,0.5-4.5,1.7-6.6,2.7c-6.5,3.1-13.1,6-19.8,8.6c-0.7,0.3-1.4,0.6-2,0.9
							c-3.6,2-7.2,3.9-11.3,4.9c-0.9,0.2-1.8,0.6-2.7,1.2c-0.8-0.1-1.6,0.3-2.3,0.5c-0.8,0.3-1.6,0.2-2.5,0.2
							c-1.5-0.1-3.1,0.4-4.6,0.6c-1.1-0.1-2.1,0.3-3.1,0.7c-0.8-0.2-1.6,0.3-2.4-0.2c0.1-0.6,0-1.3,0.5-1.8c0.2-0.1,0.5-0.2,0.7-0.2
							c0.5,0.3,1,0.2,1.5,0.2c0.7,0.7,1.4,0.2,2.1-0.1c1.1-0.2,2.1-0.2,3.2,0c0.6,0.1,1.3,0.4,2,0.1c2.3-0.7,4.7-1.3,7-2.2
							c2.1-0.8,4.3-1.2,6.1-2.5c3.2-2.2,6.4-4.6,9.6-6.8c6.5-4.5,12.6-9.5,19.7-13c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2
							c0.8-0.2,1.7-0.2,2.3-0.9c-0.3,0.7-1.4,1.3-0.2,2.3c0.7,0.5,0.6,1.7,1.7,2c1.1,0.3,1.5,1.5,1.9,2.4
							C236.8,1357.5,236.2,1357.7,235.7,1357.9z"/>
						<path fill="#CA0E21" d="M180.8,1378.2c1-0.4,2-0.7,3.1-0.7c0.6,0.7,1.2,1.5,1.7,2.2c1,1.5,0.3,3.8-1.3,4.4
							c-0.1-0.1-0.2-0.3-0.2-0.4c-1.6-1.8-3-3.7-5.5-4.3c-0.1,0-0.1-0.1-0.1-0.2c0.8-0.6,1.6-0.4,2.4-0.1c0.8,0.3,1.4,0.2,1.8-0.9
							C181.8,1378.5,181.2,1378.8,180.8,1378.2z"/>
						<path fill="#B34F4E" d="M157,1387.6c1.1-0.5,2.1-0.6,3.2-0.6c0.3,0,0.6,0.1,0.6,0.4c0,0.3-0.3,0.5-0.5,0.4
							C159.2,1387.8,158.1,1388.4,157,1387.6z"/>
						<path fill="#B34F4E" d="M183.4,1384.1c-0.9-0.2-1.3-0.8-1.7-1.5l0,0c0.7,0.3,1.3,0.7,1.7,1.4
							C183.4,1384,183.4,1384,183.4,1384.1z"/>
						<path fill="#B34F4E" d="M184,1383.7c0.1,0.1,0.2,0.3,0.2,0.4c-0.3,0.3,0.2,0.6,0,0.9c-0.5-0.2-0.5-0.7-0.8-1
							c0-0.1,0-0.1,0.1-0.2C183.6,1383.9,183.8,1383.8,184,1383.7z"/>
						<path fill="#B34F4E" d="M181.7,1382.6c-0.3,0-0.4-0.2-0.5-0.4l0,0C181.4,1382.3,181.5,1382.4,181.7,1382.6L181.7,1382.6z"/>
						<path fill="#B34F4E" d="M180.2,1381.6c-0.2-0.1-0.4-0.2-0.6-0.3c0,0,0.1-0.1,0.1-0.1C180,1381.2,180.1,1381.4,180.2,1381.6
							L180.2,1381.6z"/>
						<path fill="#B34F4E" d="M181.2,1382.1c-0.3,0-0.4-0.2-0.5-0.4l0,0C180.9,1381.8,181.1,1381.9,181.2,1382.1L181.2,1382.1z"/>
						<path fill="#CA0E21" d="M178.7,1381.4c0.2,0,0.4,0,0.6,0C179.1,1381.5,178.9,1381.5,178.7,1381.4z"/>
						<path fill="#B34F4E" d="M180.6,1381.8c-0.1,0-0.3-0.1-0.4-0.1l0,0C180.4,1381.7,180.5,1381.7,180.6,1381.8L180.6,1381.8z"/>
						<path fill="#CA0E21" d="M172.5,1363.1c0.7,1.8,1.5,2,3.3,0.5c1.1-0.9,1.7-0.5,2.5,0.3c1.3,1.2,2.1,2.5,1.3,4.3
							c-0.2,0.3-0.1,0.8-0.2,1.1c-0.4,1.1,0.4,2.7-1.5,3c-1.2,0.2-2.4,0.6-3.7,0.3c-2-0.5-3.7-1.8-5.7-2.5c-1.6-0.5-3.1-0.8-4.7-0.6
							c-0.5,0.1-1.1,0.3-1.6-0.1c0.4-0.4,1-0.5,1.5-0.5c0.9,0,1.8-0.4,2.4-1.1C167.7,1365.7,170.3,1364.7,172.5,1363.1z"/>
						<path fill="#B34F4E" d="M172.5,1363.1c-2.2,1.6-4.8,2.6-6.6,4.9c-0.5,0.7-1.4,1-2.4,1.1c-0.6,0-1.1,0.1-1.5,0.5l0,0
							c-0.7-0.2-1.3,0.1-2,0.3c0,0-0.1,0-0.1,0c-0.3-0.1-0.6,0-0.8,0.1c0,0-0.1,0-0.1,0c-0.3-0.1-0.6-0.1-0.8,0.1
							c-0.1,0.1-0.2,0.1-0.3,0.2c-1.3,0.1-2.4,0.6-3.3,1.7c0,0-0.1,0.1-0.1,0.1c-0.8,0.3-0.7-0.3-0.8-0.8c-0.8-2.4-0.8-5-2.3-7.2
							c0.7-0.4,1.3-0.7,2-1.1c-0.2,1,0.1,1.8,1.2,1.9c2.1,0.2,4.2-0.5,6.2,0c0.2,0,0.4,0,0.6-0.1c1.1-0.8,2.2-0.3,3.4-0.1
							c0.3,0.1,0.6,0.3,1,0.1c3.4-1.3,7-2.3,9.1-5.6c0.5-0.8,0.7-0.4,0.9,0.2C174.6,1360.6,173.8,1362.1,172.5,1363.1z"/>
						<path fill="#EDECEC" d="M153.4,1363c-0.7,0.4-1.3,0.7-2,1.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.2,0.1-0.2,0.1
							c-0.4,0.3-0.9,0.5-1.3,0.8l-0.1-0.1c-0.2-0.4-0.4-0.9-0.6-1.3c0.1-0.1,0.1-0.2,0.2-0.2c0.4-0.2,0.7-0.4,1.1-0.6
							c0.5-0.3,1-0.6,1.5-0.8c3.4-1.5,6.7-3.1,10.1-4.5c1.7-0.6,3.3-1.5,4.9-2c3.7-1.1,7.4-1.1,11.1-0.1c0.1,0.1,0.2,0.2,0.4,0.4
							c-0.7,0.4-0.6,0.9-0.4,1.5c-0.2,0.1-0.5,0.2-0.7,0.3c-2.8,0-5.4,0.4-6.8,3.3c-0.4,0.7-0.7,0.5-1.1,0.1
							c-1.1-0.9-2.4-1.2-3.7-1.3C160.8,1359.6,157,1360.9,153.4,1363z"/>
						<path fill="#CA0E21" d="M153.4,1363c3.6-2.1,7.5-3.4,11.7-3c1.3,0.1,2.6,0.4,3.7,1.3c0.4,0.3,0.8,0.6,1.1-0.1
							c1.4-2.8,4-3.3,6.8-3.3c-0.1,0.7-0.4,1.2-1,1.6c-0.2-0.6-0.4-1-0.9-0.2c-2.1,3.4-5.7,4.4-9.1,5.6c-0.4,0.1-0.6-0.1-1-0.1
							c-1.1-0.3-2.3-0.7-3.4,0.1c-0.1,0.1-0.4,0.2-0.6,0.1c-2.1-0.6-4.2,0.2-6.2,0C153.5,1364.8,153.2,1364,153.4,1363z"/>
						<path fill="#CA0E21" d="M180.7,1365.1c0.3-1.9-0.3-3.5-1.3-5.1c-0.4-0.6-0.3-1.4-0.4-2.1c0.4,0.1,0.9,0.2,1.2,0.5
							c0.1,1,0,2.1,1.1,2.8c0.1,0.9,0.7,0.5,1.1,0.3c0.3,0.4,0.5,0.9,0.9,1.5c0.1-1.1-0.6-1.9-0.1-3.2c0.7,1.8,1.4,3.2,1.9,4.7
							c0.3,0.9,1,1.7,0.6,2.8c-0.5-0.1-0.7,0.3-1,0.6c0-0.9-0.5-1.6-0.9-2.4c-0.4-0.8-0.6-0.1-0.9,0.1c-0.4-0.5-0.2-1.3-0.7-1.7
							c-0.6,0.2-0.5,0.9-0.9,1.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0C181,1365.1,180.9,1365.1,180.7,1365.1
							L180.7,1365.1z"/>
						<path fill="#C3B3A6" d="M181.3,1365c0.4-0.4,0.3-1.1,0.9-1.3c0.6,0.4,0.3,1.2,0.7,1.7c0,0,0,0.1,0,0.1
							c-0.1,0.3-0.1,0.5,0.1,0.7c0.1,0.2,0.2,0.5,0.2,0.7c0,0.1,0,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5c-0.4,1.1,0.4,1.8,0.8,2.7
							c-0.2,0.7,0.2,1.2,0.5,1.7c-0.2,0-0.5-0.1-0.7-0.1c-0.4-0.8-1.4-1-1.7-1.9c-0.3-1-0.9-0.9-1.3,0.1c-0.2,0.7-0.7,0.7-1.2,0.4
							c0.4-1.8,0.8-3.6,1.1-5.3l0,0c0.2-0.1,0.2-0.2,0.1-0.3C181.2,1365.1,181.2,1365.1,181.3,1365
							C181.2,1365.1,181.3,1365,181.3,1365z"/>
						<path fill="#B34F4E" d="M179.9,1370.8c0.6,0.4,1,0.3,1.2-0.4c0.4-1,0.9-1,1.3-0.1c0.3,0.9,1.3,1.1,1.7,1.9
							c-0.6,0.3-1.3,1.2-1.9,0.7c-1.5-1.2-2.1-0.6-2.7,0.8l-0.1,0C179.2,1372.7,179.5,1371.7,179.9,1370.8z"/>
						<path fill="#C3B3A6" d="M180.3,1358.4c-0.3-0.3-0.8-0.4-1.2-0.5c-0.5-0.1-1.1-0.3-1.6-0.4c-0.2-0.6-0.2-1.2,0.4-1.5
							c0.4,0.1,0.8,0.3,1.2,0.4c0.3,0.4,0.7,0.7,1,1.1c0.1,0.3,0.2,0.5,0.3,0.8C180.4,1358.3,180.3,1358.3,180.3,1358.4
							L180.3,1358.4z"/>
						<path fill="#CA0E21" d="M173.6,1375.4c-0.7,0.3-1.4,0.4-2-0.2c0-0.2,0.1-0.4,0.1-0.6c0.1-0.4-0.1-0.8,0.3-1
							c0.5-0.1,0.8,0.3,1,0.6C173.3,1374.6,173.5,1375,173.6,1375.4z"/>
						<path fill="#CA0E21" d="M154.6,1371.9c0.9-1,1.9-1.6,3.3-1.7C157.2,1371.5,155.6,1371.1,154.6,1371.9z"/>
						<path fill="#B34F4E" d="M179.6,1376.1c-0.2,0.1-0.5,0.2-0.7,0.2c0-0.1-0.1-0.2-0.1-0.2c0.7-0.6,0.3-1.6,0.7-2.4l0.1,0
							C179.9,1374.5,179.7,1375.3,179.6,1376.1z"/>
						<path fill="#CA0E21" d="M160.1,1369.8c0.6-0.2,1.2-0.5,2-0.3C161.4,1369.7,160.9,1370.4,160.1,1369.8z"/>
						<path fill="#CA0E21" d="M158.3,1370.1c0.3-0.2,0.5-0.2,0.8-0.1C158.8,1370.1,158.5,1370.2,158.3,1370.1z"/>
						<path fill="#CA0E21" d="M159.2,1369.9c0.3-0.2,0.5-0.3,0.8-0.1C159.8,1370,159.5,1370,159.2,1369.9z"/>
						<path fill="#CA0E21" d="M181.1,1365.5c-0.2,0-0.4,0-0.3-0.3l0,0C180.8,1365.2,180.9,1365.3,181.1,1365.5L181.1,1365.5z"/>
						<path fill="#EDECEC" d="M145.9,1367.1c-1,0.3-1.9,0.7-2.9,1c-1.9,0.6-3.8,0.1-5.5-0.8c-2.7-1.4-4.5-3.5-5.1-6.5l0.1,0
							c1.6-0.6,2.7-1.9,3.9-3c0.2,1.2,0.6,2.4,1,3.5c1,2.6,3.2,4,5.9,3.9c0.5,0.1,0.9,0.5,1.4,0.2c0.3,0.8,1.1,0.9,1.7,1.3
							C146.2,1366.8,146.1,1367,145.9,1367.1z"/>
						<path fill="#81070E" d="M145.9,1367.1c0.2-0.1,0.3-0.3,0.5-0.4c0.9-0.5,1.9-1,2.8-1.6l0.1,0.1c0.5,1.2-0.1,2.2-0.9,2.7
							C147.6,1368.4,146.7,1367.7,145.9,1367.1z"/>
						<path fill="#B34F4E" d="M150.5,1364.5c0.1,0,0.2-0.1,0.2-0.1C150.8,1364.5,150.7,1364.6,150.5,1364.5z"/>
						<path fill="#81070E" d="M180.6,1381.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.2-0.3-0.4-0.5-0.4c-1.1-0.7-2.4-0.7-3.6-1.1
							c0.4-0.2,0.7-0.3,1.1-0.5c0.3,0,0.7,0,1-0.1c0.1,0,0.2,0,0.2-0.1c2.5,0.6,3.9,2.5,5.5,4.3c-0.2,0.1-0.4,0.1-0.5,0.2
							c-0.4-0.6-1-1.1-1.7-1.4c-0.2-0.1-0.4-0.3-0.5-0.4C181.1,1381.9,180.9,1381.8,180.6,1381.8z"/>
						<path fill="#B34F4E" d="M178.2,1379.5c-0.3,0-0.7,0-1,0.1c-1-1-2.4-1.3-3.3-2.4c-0.2-0.3-0.8-0.5-0.5-0.9
							c0.3-0.4,0.9-0.2,1.2,0.1C175.8,1377.5,177.2,1378.3,178.2,1379.5z"/>
						<path fill="#B34F4E" d="M180.8,1378.2c0.4,0.6,1,0.3,1.7,0.1c-0.4,1.1-1,1.2-1.8,0.9c-0.8-0.3-1.6-0.5-2.4,0.1
							c0-0.4,0-0.8,0.1-1.2C179.2,1378.5,180,1378,180.8,1378.2z"/>
						<path fill="#CA0E21" d="M143.5,1314.5c0.7,0.3,1.1,1.2,2.1,1.1c0.2,0.1,0.3,0,0.3-0.2l0,0c0.1,0.1,0.1,0.2,0.2,0.3
							c0.2,0.1,0.2-0.1,0.3-0.2l0,0c-0.2,0.6,0.2,0.5,0.6,0.4l0,0c0,0.3,0.2,0.4,0.5,0.5c2.2,1.1,4.4,2.3,6.7,3.4
							c1.9,0.9,3.8,1.8,5.7,2.7c0.1-0.1,0.1-0.3,0.2-0.4c-2.6-1.6-5.6-2.6-8.2-4.3c-0.1-0.4,0-0.7,0.3-1c0.2,0,0.5,0.1,0.7,0.1
							c0.5,0.7,1.2,0.9,1.9,1.2c0.4,0.4,0.8,0.7,1.3,0.6c0.1,0,0.3-0.1,0.4-0.1c0.2,0.5,0.7,0.6,1.1,0.9c1.1,0.7,2.6,0.6,3.5,1.7
							c-0.2,0.9-0.1,1.8-1.2,2.3c-0.6,0.3-0.5,1-0.6,1.7c-0.3,1.7-0.2,3.5-1.3,5c-0.6-1,0.3-2.5-1.2-3.1c0.1-0.1,0.1-0.2,0.2-0.4
							c0.8-1.4,0.7-2-0.7-2.8c-1.9-1.2-4-1.9-5.9-3.1c-1.1-0.7-2.2-1.6-3.6-1.9c-1-0.2-2.1-0.6-2.8,0.8c-0.5,1-0.1,3.1,0.7,3.6
							c1.6,1.2,3.2,2.4,4.1,4.3c0.5,1,1,1.6-0.4,2.4c0.5,0.1,0.7,0.2,0.9,0.2c2,0.6,4.3,0.7,6.1,2c-1.4,0.3-2.6-0.3-3.8-0.7
							c-2.9-0.8-5.9-0.9-8.9-1.3c-1.7-0.2-2.1,0.5-1.3,2c0.8,1.6,1.6,3.1,2.4,4.7c-1.4-1.1-2-2.8-3.1-4.2c-0.6-0.9-1.1-0.8-1.8-0.2
							c-0.1-0.5,0.2-0.7,0.5-1c0.2-0.3,0.8-0.4,0.5-0.9c-0.2-0.4-0.7-0.2-1.1-0.2c-1.3-0.1-2.2,0.8-3.3,1.3
							c-0.6,0.3-2.9-0.2-2.8-0.7c0.1-0.6,0.5-1.4,1.1-1.7c0.8-0.4,1-0.9,1.2-1.6c0.3-0.9,0.6-2,1.4-2.7c0.5-0.5,1-1.2,0.4-1.9
							c-0.1-0.1-0.1-0.2-0.1-0.3c1.3-1,0.9-2.8,2.2-3.9c1.3-1.1,2.9-2,4.2-3.1C143.3,1315.3,143.5,1315,143.5,1314.5z M141.9,1326.5
							c0.2,1,0.3,1.5,0,2.1c-0.3,0.5-0.1,1.1,0.5,1c1.7-0.3,3.3,0.3,5,0C145.7,1328.7,143.8,1328.1,141.9,1326.5z"/>
						<path fill="#C3B3A6" d="M136.4,1357.8c-1.2,1.1-2.3,2.4-3.9,3c-0.5-1.3-0.6-2.7-0.6-4.1c-0.1-4.2,0.9-8.3,2.6-12.1
							c0.9-2,1.7-4,3.1-5.8c0.5,1.2,1.6,1.8,2.5,2.7c-1.3,2.3-2.1,4.7-2.8,7.2c-0.8,2.9-1.4,5.9-0.8,9
							C136.5,1357.7,136.5,1357.7,136.4,1357.8z"/>
						<path fill="#EDECEC" d="M140.1,1341.5c-0.8-0.9-2-1.5-2.5-2.7c-0.8-2.3,0-4.3,1.1-6.3c0.7-0.7,1.2-0.7,1.8,0.2
							c1,1.4,1.7,3.1,3.1,4.2c0.2,0.1,0.4,0.3,0.7,0.4c0.3,0.9,1.1,1.2,1.7,1.8c0.4,0.4,0.7,0.8-0.2,0.9c-0.1,0-0.2,0-0.3-0.1
							c-0.1-0.1-0.1-0.1-0.2,0c-0.3,0.1-0.7,0.1-1,0.2c-0.9-0.2-1.5,0.6-2.4,0.6c-0.4,0-0.8,0.2-1.1,0.5
							C140.6,1341.3,140.3,1341.4,140.1,1341.5z"/>
						<path fill="#B34F4E" d="M155.3,1332.2c-1.8-1.3-4.1-1.4-6.1-2c-0.2-0.1-0.4-0.1-0.9-0.2c1.5-0.8,0.9-1.4,0.4-2.4
							c-1-1.9-2.5-3.2-4.1-4.3c-0.8-0.5-1.1-2.6-0.7-3.6c0.6-1.3,1.7-1,2.8-0.8c1.4,0.3,2.5,1.2,3.6,1.9c1.9,1.2,4,2,5.9,3.1
							c1.4,0.8,1.5,1.4,0.7,2.8c-0.1,0.1-0.1,0.2-0.2,0.4c1.5,0.6,0.6,2.2,1.2,3.1c-0.2,0.1-0.4,0.2-0.6,0.3
							c-0.4-0.5-0.7-0.9-1.7-0.7c0.7,0.5,0.8,1.3,1.6,1.4c0.3,0.5,0.6,0.9,1.2,1.1c0.1,0.1,0.1,0.1,0,0.2c-0.3,0.2-0.7,0.4-1,0.5
							C156.8,1332.4,156,1332.4,155.3,1332.2z"/>
						<path fill="#EDECEC" d="M158.3,1332.5c0.1-0.1,0.1-0.1,0-0.2c1.8-1.4,2.7-3.3,3-5.5c0.2-1.7,0.6-3.4,0.9-5
							c0.2-1.5,1.3-0.5,1.9-0.7c0.8,0.8,2.1,0.6,2.9,1.3c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.8,1.4,1.2,1.9,2c0.8,1.9,1.5,3.8,2.6,5.6
							c0.1,0.2,0.2,0.3,0.4,0.5c0,0-0.1,0.1-0.1,0.2c-0.4,0.3-0.3,0.5,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0,0-0.1,0.1-0.1,0.2
							c-0.6,0.3,0.4,1.4-0.7,1.4c-0.2-0.2-0.3-0.5-0.5-0.7c0.1-0.3,0-0.5-0.4-0.5c-0.1-0.1-0.2-0.3-0.4-0.4
							c-0.4-0.9-0.8-1.9-1.8-2.4c-1-1.5-2.1-2.9-3.1-4.4l0,0c0-1-0.7-1.5-1.3-2c-0.4-0.3-0.6-0.1-0.5,0.2c0.4,2.3-0.7,4.3-1.4,6.4
							C161.2,1330.7,160.5,1332.5,158.3,1332.5z"/>
						<path fill="#B34F4E" d="M152,1316.8c-0.3,0.3-0.4,0.6-0.3,1c-1.4-0.7-2.9-1.4-4.3-2.1c0.3-0.2,0.8-0.4,0.5-1
							c-0.3-0.5-0.7-0.6-1.2-0.4c0.4-1.2,1.4-1.6,2.5-1.7c0.6,0,1.4,0.2,0.7-0.9c-0.2-0.3,0-0.4,0.2-0.5c0.5-0.3,1-0.4,1.4,0.1
							c-0.3,1.4,0.9,1.4,1.7,1.8c0.2,0.1,0.6-0.1,0.7-0.4c0.2-0.5-0.2-0.6-0.5-0.7c-0.4-0.2-0.8-0.3-1.2-0.5c0.4-0.3,0.6-0.9,1.2-1
							c0.7,1.2,1.5,2.1,3,1.1c0.1,0,0.3,0.1,0.4,0.1c-0.8,1,0,1.4,0.7,1.6c0.8,0.3,1.3,0,1.3-1c0.4,0,0.5,0.3,0.7,0.6
							c-0.1,0.4-0.8,0.8-0.3,1.1c1.2,0.5,2.2,1.6,3.7,1.3l0,0c0.1,1.1,1.4,0.7,1.8,1.5c-0.1,0.2-0.2,0.3-0.4,0.5
							c-1.3-1-2.8-1.5-4.3-2c-1-0.3-1.2,0.4-0.8,1.3c-0.5-0.1-0.9-0.3-1.4-0.2c-0.4,0-1.1,0.2-0.8,0.8c0.3,0.5,1.1,0.6,1.7,0.6
							c0.5,0,0.8-0.6,0.5-1.1c1.7,0,3,0.9,4.3,1.8c-0.1,0.1-0.2,0.3-0.2,0.4c-1.3-0.2-2.4-2-4.1-0.9c0.9,0.8,2,1.1,2.7,1.9
							c-1.6,0.5-2.4-1-3.7-1.5c-0.7-0.3-1.2-0.5-1.7,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2-0.5,0.9-0.9,0.1-1.4c-0.6-0.3-1.1,0.2-1.4,0.6
							c0,0,0,0.1-0.1,0.1c-0.7-0.4-1.4-0.6-1.9-1.2c0.2-0.4,0.9-0.6,0.2-1.2c-0.4-0.4-0.9-0.3-1.3,0
							C151,1316.4,152,1316.5,152,1316.8z M156.8,1315.4c0.4,0.1,0.8,0.1,1.3,0.2c0.3,0,0.5-0.3,0.6-0.5c0.1-0.2,0-0.4-0.2-0.4
							c-1.2-0.6-2.5-1.1-3.7-1.6c-0.3-0.1-0.7,0-0.8,0.4c-0.1,0.4,0.3,0.4,0.5,0.6c0.5,0.4,1.3,0.4,1.5,1.2c-0.6,0.3-1-0.3-1.5-0.4
							c-0.5-0.1-0.9-0.3-1.2,0.2c-0.4,0.7,0.4,0.6,0.6,0.9c0.4,0.4,0.9,0.5,1.4,0.7C156.5,1316.8,156.9,1316.5,156.8,1315.4z
							 M152.4,1313.8c-0.5-0.3-0.7-1.1-1.5-0.7c-0.3,0.1-0.6,0.3-0.3,0.8c0.4,0.5,0.8,1.1,1.7,0.8
							C152.8,1314.5,152.5,1314.2,152.4,1313.8z"/>
						<path fill="#B34F4E" d="M176,1310.9c-1,0.2-1.3,0.8-0.9,1.8c-0.7-1.2-1.1-2.7-2.9-1.6c-0.1-0.3-0.3-0.7-0.1-0.8
							c0.8-0.6,0.8-1.3,0.8-2.2c0-0.3,0-0.6,0.3-0.8c0.4-0.2,0.6,0,0.9,0.3c0.4,0.3,0.7,0.8,0.9,1.3
							C175.2,1309.7,175.6,1310.3,176,1310.9z"/>
						<path fill="#B34F4E" d="M181,1306.6c0.3,1.1,0.2,1.9-1.2,2c-1-0.3-1.7-1.4-2.9-1.2c-0.3,0.1-0.4-0.4-0.3-0.7
							c0.1-0.5,0.4-0.6,0.8-0.5C178.6,1306.4,179.8,1306.5,181,1306.6z"/>
						<path fill="#C3B3A6" d="M163.4,1318.4c-1.3-0.9-2.6-1.9-4.3-1.8l0,0c-0.4-0.9-0.2-1.6,0.8-1.3c1.5,0.5,3,1.1,4.3,2
							C164.1,1317.8,163.8,1318.2,163.4,1318.4z"/>
						<path fill="#C3B3A6" d="M156.3,1318.6c0.5-0.8,1-0.5,1.7-0.3c1.3,0.4,2.1,1.9,3.7,1.5l0,0c0.1,0.2,0.1,0.3,0.2,0.5
							c-0.4,0.3-0.5,0.8-1,0.9c-0.9-1.1-2.4-1-3.5-1.7C157,1319.3,156.5,1319.2,156.3,1318.6z"/>
						<path fill="#C3B3A6" d="M161.8,1319.8c-0.7-0.9-1.8-1.1-2.7-1.9c1.7-1.2,2.7,0.7,4.1,0.9c-0.2,0.3-0.5,0.7-0.7,1
							C162.2,1319.7,162,1319.6,161.8,1319.8L161.8,1319.8z"/>
						<path fill="#C3B3A6" d="M165.7,1315.2c-0.6-0.5-1.5-0.6-1.8-1.4l0,0c0.2-0.1,0.1-0.3,0-0.4c0.5-0.4,1.1,0.3,1.6-0.1
							c0.3,0.5,1,0.2,1.3,0.7C166.6,1314.5,166.3,1315,165.7,1315.2z"/>
						<path fill="#C3B3A6" d="M185,1304.9c-0.1,1.3-1.2,0.3-1.6,0.8c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-2,0.1-2,1.6-1.2
							C184.8,1304.5,184.9,1304.7,185,1304.9z"/>
						<path fill="#B34F4E" d="M164.6,1311.1c0.8-0.7,1.6-0.6,2.4,0.1c-0.5,0.2-0.6,0.6-0.8,1.1c-0.1,0.1-0.2,0.1-0.2,0.2
							C165.2,1312.3,164.5,1312.1,164.6,1311.1L164.6,1311.1z"/>
						<path fill="#C3B3A6" d="M164.6,1316.8c-0.4-0.8-1.7-0.4-1.8-1.5c1-0.7,1.8-0.1,2.7,0.3
							C165.4,1316.2,165.1,1316.6,164.6,1316.8z"/>
						<path fill="#C3B3A6" d="M166.1,1312.3c0.2-0.4,0.3-0.9,0.8-1.1c0,0,0.1,0.1,0.1,0.1c1.6,0.5,1.6,0.5,0.6,1.8
							C167.1,1312.9,166.6,1312.6,166.1,1312.3z"/>
						<path fill="#B34F4E" d="M177.7,1310.3c1.2,0.5,2.6,0,3.7,1c-1.3-0.3-2.8,0.5-3.9-0.9C177.6,1310.4,177.6,1310.3,177.7,1310.3z
							"/>
						<path fill="#CA0E21" d="M166.1,1312.3c0.5,0.3,1,0.5,1.5,0.8c0,0.5-0.5,0.6-0.8,0.9c-0.3-0.5-1-0.2-1.3-0.7
							c0.3-0.2,0.5-0.4,0.3-0.7C166,1312.5,166,1312.4,166.1,1312.3z"/>
						<path fill="#CA0E21" d="M157.1,1331.2c-0.8-0.1-0.8-0.9-1.6-1.4c1-0.2,1.3,0.3,1.7,0.7C157.2,1330.7,157.2,1331,157.1,1331.2z
							"/>
						<path fill="#B34F4E" d="M161.8,1319.8c0.2-0.2,0.4-0.2,0.7,0c0,0.3-0.2,0.4-0.5,0.6C161.9,1320.2,161.8,1320,161.8,1319.8z"/>
						<path fill="#B34F4E" d="M217.2,1296.2c0.1,1.3-2.4,5.3-3.8,5.9c0.3-0.7,0.3-1.3-0.1-1.8
							C214.6,1298.8,215.5,1297.2,217.2,1296.2z"/>
						<path fill="#B34F4E" d="M206.5,1305.8c-0.4,0.6-0.8,1.2-1.2,1.9c-0.9-0.5-0.8-1.9-2-2.1c-0.2,0-0.2-0.4,0-0.6
							c0.4-0.5,1.2-0.6,1.5-0.3C205.3,1305,206.1,1305.1,206.5,1305.8z"/>
						<path fill="#C3B3A6" d="M205.5,1309.3c-1.2,0.5-2.6,0.8-3.9,1c-0.1-0.2-0.1-0.3-0.2-0.5c1.2-0.6,2.4-0.8,3.7-1.1
							C205.3,1308.8,205.4,1309,205.5,1309.3z"/>
						<path fill="#C3B3A6" d="M199.3,1306.9c-0.1,0-0.3-0.1-0.4-0.1c0-0.1-0.1-0.1-0.2-0.2c0.8-0.7,2.1-0.1,2.8-0.9
							c-0.4,0.6-0.6,1.3-1.3,1.7c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0
							C199.5,1306.9,199.4,1306.8,199.3,1306.9z"/>
						<path fill="#C3B3A6" d="M209.3,1304c-0.3,0.4-0.7,0.8-1,1.3c-0.5-1,0.5-1.8,0.4-2.8c0-0.1,0.3-0.2,0.5-0.2
							c0.1,0.2,0.1,0.3,0.2,0.5C209.4,1303.1,209,1303.5,209.3,1304z"/>
						<path fill="#B34F4E" d="M200.8,1304.1c-0.2-0.1-0.4-0.1-0.6-0.2C200.5,1303.7,200.6,1303.9,200.8,1304.1z"/>
						<path fill="#C3B3A6" d="M208.3,1305.2c0,0.2-0.1,0.2-0.3,0.1C208.1,1305.3,208.2,1305.3,208.3,1305.2z"/>
						<path fill="#C3B3A6" d="M162.8,1315.3c-1.5,0.3-2.5-0.8-3.7-1.3c-0.5-0.2,0.2-0.7,0.3-1.1c0.3-0.2,0.6,0,0.9,0.1
							C160.9,1314,162.6,1313.8,162.8,1315.3z"/>
						<path fill="#C3B3A6" d="M158.7,1312.5c0,0.9-0.5,1.3-1.3,1c-0.7-0.3-1.5-0.7-0.7-1.6C157.5,1311.6,157.9,1312.5,158.7,1312.5z
							"/>
						<path fill="#C3B3A6" d="M156.3,1311.7c-1.5,0.9-2.3,0.1-3-1.1C154.6,1310.2,155.5,1310.9,156.3,1311.7z"/>
						<path fill="#C3B3A6" d="M152.2,1311.6c0.4,0.2,0.8,0.3,1.2,0.5c0.3,0.1,0.7,0.3,0.5,0.7c-0.1,0.3-0.5,0.5-0.7,0.4
							c-0.7-0.4-2-0.4-1.7-1.8C151.7,1311.4,151.9,1311.5,152.2,1311.6z"/>
						<path fill="#CA0E21" d="M146.6,1314.3c0.5-0.2,0.9-0.1,1.2,0.4c0.3,0.6-0.1,0.8-0.5,1c-0.2,0-0.5-0.2-0.5,0.2l0,0
							c0.1-0.5-0.2-0.5-0.6-0.4l0,0c-0.1-0.2-0.2-0.3-0.4-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.2-0.1
							c0.1,0,0.1,0,0.2-0.1C146.1,1314.8,146.4,1314.6,146.6,1314.3z"/>
						<path fill="#B34F4E" d="M159.9,1310.6c0.1,0.4-0.2,0.6-0.5,0.8c-0.3,0.2-0.7,0.2-0.8-0.3c-0.1-0.3,0-0.7,0.4-0.8
							C159.3,1310.4,159.7,1310.3,159.9,1310.6z"/>
						<path fill="#81070E" d="M185,1304.9c-0.2-0.2-0.3-0.4-0.2-0.7c0.4-0.3,1.4,0.7,1.4-0.6c0.7,0.4,1.7,0.3,2.1,1.2
							c-0.1,0.2-0.2,0.5,0.1,0.6c0.8,0.2,1.1,0.8,0.9,1.4c-0.2,0.9,0.4,1.4,0.9,1.8c0.4,0.4,0.7,0.8,1,1.3c-0.4,0.4-0.6,0.8-0.4,1.3
							c-0.2,0.1-0.3,0.1-0.5,0.2c-0.3-0.3-0.5-0.6-0.8-0.7c-0.6-0.3-0.5,0.1-0.4,0.4c-0.8,0.5-1.7-0.1-2.5,0.4
							c-0.4-0.1-0.7-0.2-1.1-0.3c-0.4-1.1-1.4-1.6-2.1-2.5c-0.3-0.5-0.2-1.2-0.6-1.6l0,0c0.1-0.1,0.2-0.1,0.3-0.2
							c1.3,0.2,2.1-0.8,3.4-1.4C185.8,1305.3,185.4,1305.1,185,1304.9z"/>
						<path fill="#B34F4E" d="M191.1,1309.9c-0.2-0.5-0.5-0.9-1-1.3c-0.5-0.4-1.1-0.9-0.9-1.8c0.2-0.6-0.1-1.3-0.9-1.4
							c-0.3-0.1-0.3-0.3-0.1-0.6c0.3-0.5,0.6-0.7,1.1-0.2c0.3,0.3,0.4,0.9,1.1,0.6c0.5,0.4,1.4,0.5,0.7,1.4
							c-0.2-0.2-0.4-0.5-0.7-0.2c-0.1,0-0.1,0.2-0.1,0.2c0.3,0.3,0.5,0,0.8,0c0.1,0.6-0.4,1.2,0.5,1.9c-0.4-1,0.3-2,0.6-1.9
							c1,0.4,2.1-0.8,2.9,0.4c0.2,0.5,0.7,1.1,0,1.4c-0.7,0.4-0.5-0.6-1.1-0.9c0.1,1-0.3,1.5-1.3,1.2c-0.9-0.3-1.3,0.1-1.3,1
							c0,0-0.1,0.1-0.1,0.1C191.2,1310,191.2,1309.9,191.1,1309.9z"/>
						<path fill="#C3B3A6" d="M195.1,1307.2c-0.8-1.2-1.9,0-2.9-0.4c-0.2-0.1-1,0.8-0.6,1.9c-0.9-0.7-0.3-1.4-0.5-1.9l0,0
							c0.7-0.9-0.2-1.1-0.7-1.4c0.1-0.1,0.2-0.3,0.3-0.3c1.4,0.8,3,0.8,4.6,1c0.3,0,0.5,0.2,0.7,0.5c0,0.2-0.1,0.4-0.1,0.6
							C195.7,1307.1,195.4,1307.1,195.1,1307.2z"/>
						<path fill="#B34F4E" d="M196,1307.1c0-0.2,0.1-0.4,0.1-0.6c0.7-0.4,1.4-0.1,2.1,0.1c-0.3,0.2-0.6,0.5-1,0.6
							c-0.6,0.2-0.6,0.6-0.2,1c0.2,0.2,0.9,0.1,0.4,0.6c-0.3,0.3-0.8,0.1-1-0.3C196.2,1308,196.1,1307.5,196,1307.1z"/>
						<path fill="#B34F4E" d="M198.7,1306.6c0.1,0,0.1,0.1,0.2,0.2C198.7,1306.9,198.7,1306.8,198.7,1306.6
							C198.7,1306.7,198.7,1306.6,198.7,1306.6z"/>
						<path fill="#81070E" d="M165.9,1312.5c0.2,0.4-0.1,0.6-0.3,0.7c-0.5,0.4-1.1-0.4-1.6,0.1l-0.1,0c-0.3-0.3-0.6-0.5-0.8-0.8
							c-0.5-1.5,1.1-0.9,1.6-1.4C164.5,1312.1,165.2,1312.3,165.9,1312.5z"/>
						<path fill="#C3B3A6" d="M163,1312.5c0.3,0.3,0.6,0.5,0.8,0.8c0,0.1,0,0.3,0.1,0.4l0,0c-0.6,0.2-1.2-0.1-1.5-0.6
							C162.2,1312.8,162.7,1312.7,163,1312.5z"/>
						<path fill="#EDECEC" d="M145.7,1315.2c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.3,0.2
							C145.6,1315.4,145.7,1315.3,145.7,1315.2z"/>
						<path fill="#81070E" d="M241.9,1341.4c-0.9-2.9-0.9-6-2.1-8.9c-0.2-0.6-0.3-1.2-0.4-1.8c1.7,0.1,1.8,0.2,1.5,1.9
							c-0.2,0.7-0.1,1.1,0.6,1.4c0.1,1.2,1,2.2,1.1,3.4c-0.4,0.2-0.9,0.4-0.3,1c0.4,0.5,0.8,1,0.7,1.8c-0.1-0.1-0.1-0.1-0.2-0.2
							c0,0,0,0.1-0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.9,1.5,1.8,1.9,3.4,1.4c2.3-0.6,3.3-2.5,4.2-4.4c0.4-0.7,0.6-1.5,0.8-2.3
							c0.3,0.4,0.6,0.8,0.9,1.1c0.5,1.2,1,2.3,1.5,3.5c-0.8,0.7,0.2,1.1,0.3,1.6c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.1,0.4,0.1,0.6
							c0.8,4.2,1.4,8.4,0.5,12.7c-0.3,1.6-0.9,3.4-2.6,3.8c-1.8,0.5-2.9-1.1-3.8-2.5c-2.9-4.1-4.7-8.7-6-13.5
							C242.2,1342.1,242,1341.8,241.9,1341.4z"/>
						<path fill="#C3B3A6" d="M249.2,1308.1c0.5,0.6,0.9,1.2,1.4,1.8c0.2,0.2,0.5,0.4,0.3,0.7c-0.2,0.3-0.5,0.3-0.8,0.2
							c-0.8-0.2-1.6-0.2-2.4,0.4c3,0.5,3.8,1.3,3.1,3.3c0,0.1-0.1,0.1-0.1,0.1c-0.9,0.9-1,2.1-0.2,2.8c0,0.3,0,0.6,0,0.9
							c-1.1,1-1.3,1-1.9-0.4c-0.5-1.3-0.7-2.6-1.1-3.9c-0.1-0.5-0.3-1-0.9-1.1c-0.6-0.1-0.8,0.5-1.1,0.8c-0.4,0.4-0.2,0.7,0.1,1.1
							c0.7,1.5,1.2,3,1.8,4.6c0.2,0.6,0.8,1.5-0.1,1.7c-0.9,0.2-2,0.3-2.5-0.9c-0.4-1.1-0.6-2.2-0.2-3.3c0.4-1.2,0.2-2.3-0.2-3.4
							l0,0c0.3-0.2,0.4-0.5,0.3-0.8C246.2,1311.2,247.2,1309.1,249.2,1308.1z"/>
						<path fill="#81070E" d="M249.5,1329.5c0,0.1,0,0.2,0,0.3c0,0-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.4,0,0.8,0,1.2
							c0,0.3-0.1,0.6-0.1,0.9c-0.6,0.3-1.2,0.6-1.8,0.8c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-1.3-0.1-2.6-0.8-3.8
							c-0.1-1.1-0.5-1.9-1.6-2.3c0.2-1.1,0.9-1.9,1.6-2.6c0.5,0.2,0.7,0.8,1,1.2C248.4,1326.7,248.5,1328.3,249.5,1329.5z"/>
						<path fill="#B34F4E" d="M241.4,1334c-0.7-0.2-0.7-0.7-0.6-1.4c0.4-1.7,0.2-1.8-1.5-1.9c-0.5-1-0.1-2,0.2-3.1
							c0.8,0.6,0.6,1.4,0.9,2c0.2,0.3,0.2,0.8,0.6,0.8c0.5,0,0.5-0.5,0.6-0.9c0.2-1.7,0.3-3.3,0.4-5c0-0.6,0.1-1.3,0.2-1.9
							c0.1-0.2,0.2-0.3,0.3-0.5c0.1,0,0.1,0,0.2,0c0.5,1.2,0,2.4,0,3.6c-0.1,2.4-0.4,4.7-0.6,7.1
							C242,1333.2,241.9,1333.8,241.4,1334z"/>
						<path fill="#C3B3A6" d="M249.5,1329.5c-1-1.2-1.1-2.8-2-4.1c-0.3-0.4-0.4-1-1-1.2c0.8-1.1,1.7-1.2,2.3-0.1
							C249.6,1325.8,249.9,1327.7,249.5,1329.5z"/>
						<path fill="#B34F4E" d="M246.2,1341.6c-1.6,0.4-2.5,0.1-3.4-1.4l0,0c0.2-0.7-0.3-1.3-0.7-1.8c-0.5-0.6-0.1-0.8,0.3-1
							C243.2,1339.3,244.6,1340.5,246.2,1341.6z"/>
						<path fill="#C3B3A6" d="M252.3,1336c-0.3-0.4-0.6-0.8-0.9-1.1c0.2-1.2,0.3-2.5,0.5-3.7c0.1,0,0.2,0,0.3,0
							c0.4,1.2,0.7,2.4,1.1,3.6C253.4,1335.4,252.8,1335.8,252.3,1336z"/>
						<path fill="#F4F4F5" d="M249.2,1308.1c-2,1-3.1,3.1-4.6,4.6c-0.1-0.1-0.2-0.2-0.2-0.3c0.5-2.6,2.5-4.5,4.7-4.5
							C249.1,1307.9,249.2,1308,249.2,1308.1z"/>
						<path fill="#F4F4F5" d="M248.4,1338.7c-0.6,0.3-1.1,0.4-1.3-0.2c-0.3-0.8,0.4-1.4,1-1.8c0.3-0.3,1.1-0.3,1.3,0.2
							C249.4,1337.6,248.8,1338.2,248.4,1338.7z"/>
						<path fill="#C3B3A6" d="M244.9,1326.8c1.1,0.4,1.5,1.2,1.6,2.3c-0.7,0.2-1.4-0.7-2.4,0.3
							C244.5,1328.2,244.4,1327.5,244.9,1326.8z"/>
						<path fill="#C3B3A6" d="M244.8,1333.7c-0.3,1-0.5,2-0.9,3.4c-0.6-1.7-0.5-2.9,0-4.2C244.2,1333.2,244.5,1333.4,244.8,1333.7z"
							/>
						<path fill="#B34F4E" d="M244.8,1333.7c-0.3-0.2-0.6-0.5-0.9-0.7c-0.2-0.7-0.3-1.3,0.2-2c0.4,0.9,0.9,1.6,1.2,2.4
							C245.4,1333.6,245.1,1333.7,244.8,1333.7z"/>
						<path fill="#F4F4F5" d="M247.5,1333.1c0.6-0.3,1.2-0.6,1.8-0.8c0,0.1,0.1,0.2,0.1,0.2c0.4,1.1-0.7,1.2-1.2,1.6
							C247.6,1334.1,247.4,1333.7,247.5,1333.1z"/>
						<path fill="#F4F4F5" d="M251.3,1317.9c0.6,1,0.1,2.1-1.4,2.9c0.2-0.9,0.9-1.5,0.6-2.4C250.9,1318.4,251.1,1318.3,251.3,1317.9
							z"/>
						<path fill="#B34F4E" d="M251.3,1317.9c-0.1,0.3-0.4,0.5-0.7,0.5c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-0.9c0.1-0.6,0.2-1.2,0.4-1.9
							C251.6,1316.3,251.6,1317.1,251.3,1317.9z"/>
						<path fill="#C3B3A6" d="M247.5,1333.1c-0.1,0.6,0.1,1,0.7,1c0.1,0.1,0.4,0.3,0.2,0.4c-0.8,0.4-1.2-0.1-1.7-0.6
							c-0.7-0.7,0.3-0.7,0.5-1C247.3,1333,247.4,1333,247.5,1333.1z"/>
						<path fill="#C3B3A6" d="M238.1,1340.6c0.5,0.6,0.4,1.1,0.8,1.4C238.1,1341.9,237.8,1341.5,238.1,1340.6z"/>
						<path fill="#C3B3A6" d="M254,1341.1c-0.1-0.5-1.1-0.9-0.3-1.6C254.4,1339.9,254.4,1340.5,254,1341.1z"/>
						<path fill="#B34F4E" d="M242.8,1319.4c-0.1-0.5,0-1,0.3-1.5C243.4,1318.5,243.4,1319,242.8,1319.4z"/>
						<path fill="#C3B3A6" d="M249.5,1332.5c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.3,0.1-0.6,0.1-0.9C249.8,1331.7,250,1332.1,249.5,1332.5z
							"/>
						<path fill="#B34F4E" d="M242.8,1320.2c-0.4-0.3-0.2-0.6-0.1-0.9C243.2,1319.6,243,1319.9,242.8,1320.2z"/>
						<path fill="#B34F4E" d="M243,1317.9c-0.2-0.4-0.1-0.7,0.1-1.1C243.4,1317.2,243.6,1317.6,243,1317.9z"/>
						<path fill="#B34F4E" d="M243.1,1316.9c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0.1,0,0.1,0
							C243.5,1316.2,243.6,1316.6,243.1,1316.9z"/>
						<path fill="#C3B3A6" d="M254.2,1342c-0.2-0.2-0.2-0.4-0.1-0.6C254.3,1341.6,254.3,1341.8,254.2,1342z"/>
						<path fill="#C3B3A6" d="M249.5,1330.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0.1,0,0.1-0.1l0.1,0.1L249.5,1330.1z"/>
						<path fill="#B34F4E" d="M250.7,1314.7c0.1,0,0.1-0.1,0.1-0.1C250.9,1314.7,250.9,1314.8,250.7,1314.7z"/>
						<path fill="#B34F4E" d="M243.4,1315.4c0.1,0.2,0.1,0.3-0.1,0.4c0,0-0.1,0-0.1,0C243.2,1315.6,243.2,1315.5,243.4,1315.4
							L243.4,1315.4z"/>
						<path fill="#B34F4E" d="M243.4,1315.4c0-0.1,0-0.2,0-0.2c0.1,0,0.1,0,0.2,0.1C243.5,1315.3,243.4,1315.3,243.4,1315.4
							C243.4,1315.4,243.4,1315.4,243.4,1315.4z"/>
						<path fill="#EDECEC" d="M261.9,1365c0.2,0.6-0.3,0.8-0.5,1.1c-0.4,0.4-0.6,0.8-0.2,1.3c-0.5,0.4-1.2,0.4-1.5,1
							c-0.4,0.1-0.7,0.3-1.1,0.4c-0.3-0.5-0.5-1.1-1.4-0.8c-0.7,0.2-1.3-0.4-1.8-1c0.4-0.2,0.9-0.3,1.2-0.7c-0.2-0.5-0.7-0.5-1-0.7
							c0.3-0.5,1-0.9,0.5-1.6c0.4-0.2,0.7-0.3,1.1-0.5c0.4,0.6,0.6,1.4,1.5,1.3c0.4,0,0.5,0.2,0.5,0.6c-0.1,1.1,0.5,0.6,1.1,0.6
							C261,1365.9,261.2,1365.1,261.9,1365z"/>
						<path fill="#81070E" d="M233.9,1350.6c-0.6,0.7-1.5,0.6-2.3,0.9c-0.4-1,0.2-2-0.1-3c-0.3-1.2-0.8-2.2-1.6-3
							c-1.3-1.3-0.9-3.2-1.6-4.7c-0.3-0.7-1.2-1.4-0.6-2.4c1.3,3.4,3.2,6.6,4.9,9.8C233.1,1348.9,233.7,1349.7,233.9,1350.6z"/>
						<path fill="#81070E" d="M256,1363.9c0.5,0.7-0.2,1.1-0.5,1.6c-0.4,0.4-0.5,0.8-0.7,1.3c-0.8,0.6-1.5,0.3-1.9-0.3
							c-0.4-0.5-0.1-1.1,0.5-1.3C254.4,1365.1,255.3,1364.7,256,1363.9z"/>
						<path fill="#81070E" d="M235.7,1357.9c0.6-0.2,1.1-0.4,1.7-0.6c0.8-0.1,1.4,0.3,1.8,1c0.4,0.7,1,1.3,1.4,2
							c-0.1,0-0.4,0.1-0.4,0C239.1,1358.7,237.1,1358.8,235.7,1357.9z"/>
						<path fill="#B34F4E" d="M258.5,1368.8c0.4-0.1,0.7-0.3,1.1-0.4c1,0.2,1.4-0.7,2.1-1c-1,1.3-2.2,2.1-3.8,2.1
							C258.1,1369.2,258.3,1369,258.5,1368.8z"/>
						<path fill="#B34F4E" d="M254.8,1366.9c0.1-0.5,0.3-1,0.7-1.3c0.3,0.3,0.8,0.3,1,0.7c-0.3,0.4-0.8,0.5-1.2,0.7
							C255.2,1366.9,255,1366.9,254.8,1366.9z"/>
						<path fill="#81070E" d="M256.7,1361.9c-0.2-0.1-0.5-0.2-0.7-0.2c0.4-0.6,0.9-1.1,1.3-1.7
							C257.5,1360.8,257.2,1361.4,256.7,1361.9L256.7,1361.9z"/>
						<path fill="#EDECEC" d="M257.4,1358.9c0,0.1,0,0.3-0.1,0.4C256.9,1359.1,257.2,1359,257.4,1358.9z"/>
						<path fill="#EDECEC" d="M233.1,1309.9c1.4,1.1,2.2,2.2,1.8,4c-0.2,0.8,0,1.8-0.2,2.7C234.2,1314.4,232.6,1312.5,233.1,1309.9z
							"/>
						<path fill="#B34F4E" d="M237.9,1323.8c0.9,0.2,1.9,0,2.4,1.1c0.1,0.3-0.8,2.2-1.2,2.3c-0.5,0.1-1-0.2-1-0.8
							C238,1325.5,237,1324.7,237.9,1323.8z"/>
						<path fill="#EDECEC" d="M239.5,1308.5c-1.6,0.6-2.8,1.3-4,2c-0.3,0.2-0.6,0.3-0.9,0c-0.2-0.3-0.1-0.5,0.1-0.8
							C235.3,1308.7,238,1308,239.5,1308.5z"/>
						<path fill="#EDECEC" d="M241.5,1306c0,0.8,0.7,1.6,0.1,2.5c-0.2,0.3-0.6-0.1-0.6-0.3c0.1-0.9-1.1-1.7-0.3-2.6
							C240.9,1305.3,241.3,1305.7,241.5,1306z"/>
						<path fill="#EDECEC" d="M238.6,1316.6c0,0.6,0.5,1.2-0.1,1.6c-0.5,0.3-0.7-0.1-0.9-0.4c-0.2-0.6-0.4-1.2,0.1-1.6
							C238.1,1315.8,238.3,1316.3,238.6,1316.6z"/>
						<path fill="#EDECEC" d="M242.5,1322.1c-0.1,0.2-0.2,0.3-0.3,0.5c-0.7,0-0.9-0.6-1-1.2c0-0.3-0.3-0.6,0-0.8
							c0.3-0.1,0.7-0.2,1.1-0.1c0.3,0.1,0.3,0.4,0.1,0.7C242.2,1321.6,242.4,1321.8,242.5,1322.1z"/>
						<path fill="#EDECEC" d="M237,1304.9c0.1,0.3,0.3,0.7-0.1,0.9c-0.4,0.2-0.7,0.1-0.9-0.3c-0.3-0.4,0-0.8,0.4-1
							C236.7,1304.3,236.8,1304.6,237,1304.9z"/>
						<path fill="#EDECEC" d="M244.4,1312.4c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.3,0,0.6-0.3,0.8C244.3,1313.2,244,1312.8,244.4,1312.4z"
							/>
						<path fill="#81070E" d="M180.5,1358.3c-0.1-0.3-0.2-0.5-0.3-0.8c0.4-0.2,0.7-0.4,1.1-0.6c0.2,0.3,0.5,0.7,0.9,1.4
							c0-1.3,0.1-2.2,0.1-3.2c0.2-0.2,0.5-0.3,0.7-0.5c1.5,2.1,1.8,4.7,2.5,7.2c0.6,2.3,0.9,4.7,2.1,6.9c0.5,0.9,1.1,1.2,1.9,0.6
							c1.4-1,3-1.9,4-3.4c0.7-1.1,0.6-2.4,1.1-3.6c0.3-0.7,1-1.3,0.6-2.2l0,0c0.6-0.4,1.1-1,1.3-2c-0.7,0.6-1.2,0.9-1.4,1.5
							c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1-0.5-0.3-0.9-0.4-1.4c0.2-0.1,0.4-0.3,0.4-0.5c0.2-0.2,0.3-0.4,0.2-0.7
							c0.6-0.2,1.1-0.6,1.4-1.2c0.7-0.2,0.8,0.5,1.1,0.8l0,0c-0.2,0.2-0.6,0.2-0.5,0.6c0.1,0.3,0.4,0.1,0.6,0
							c0.3,0.6,0.4,1.4,1.3,1.5c1,0.1,2.2-0.7,2.5-1.8c0.2-0.6,0.1-1.3,0.7-1.6c0.3,0.3,0.3,1,0.8,0.9c0.8-0.1,0.7-0.8,1-1.3
							c0.3,0.4,0.4,0.8,0.4,1.2c0,0.7,0.1,1.4,1,1.5c1,0.1,0.2-1,0.8-1.2c1.2,1.2,0.9,3.4-0.2,5.1c-1.6,2.6-3,5.3-4.1,8.1
							c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.3-0.3,0.4c-1,0.7-2.1,0.5-3.1,0.5c-3.2,0.1-6.4,0.4-9.4-0.7c-0.8-0.3-0.9,0.2-1,0.8
							c0,1-0.1,2-0.1,3.1c-1.3,0.5-2.7,0.9-3.8,1.7c-0.7,0.3-1.4,0.8-2.1,0.1c1-1.4,2-2.7,3.7-3.4c0.1,0,0-0.3,0-0.5
							c-0.3-0.5-0.7-1-0.5-1.7c0-1-0.4-1.8-0.8-2.7c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.5-0.2-0.7
							c0.1-0.3,0.1-0.5-0.1-0.7c0,0,0-0.1,0-0.1c0.3-0.3,0.5-0.9,0.9-0.1c0.4,0.8,0.9,1.5,0.9,2.4c0.2,1.6,0.4,3.2,1,4.8
							c0.1,0.4,0.1,0.9,0.6,0.8c0.7-0.2,0.2-0.7,0.2-1.1c-0.2-0.9-0.4-1.9-0.5-2.8c0.5-2.2,0.7,0.8,1.2-0.1
							c-0.4-0.7-0.6-1.7-1.4-2.2l0,0c0.3-1.1-0.3-1.9-0.6-2.8c-0.5-1.5-1.2-2.9-1.9-4.7c-0.5,1.3,0.2,2.2,0.1,3.2
							c-0.4-0.6-0.6-1-0.9-1.5c-0.2-0.5-0.3-1-0.5-1.6c-0.5,0.4-0.5,0.8-0.6,1.2c-1.1-0.6-1-1.7-1.1-2.8c0,0,0,0,0,0
							c0.1,0.1,0.2,0.2,0.3,0.3C180.5,1358.5,180.5,1358.4,180.5,1358.3z M201.9,1360c-0.5-1-1.3-1.1-1.8-0.3
							c-1.2,2-1.1,4.1-0.4,6.2c0.2,0.7,0.5,1.9,1.2,1.8c0.8-0.1,0.7-1.3,0.9-2C202.4,1363.9,202.6,1361.9,201.9,1360z M197.9,1363.1
							c-0.7-1.5-1.7-1.8-2.5-0.5c-0.7,1.2-1.2,2.5-1,4c0.1,0.6,0.3,0.8,0.9,0.9c0.9,0.1,1.5,0,2-0.8
							C198.1,1365.7,198.5,1364.5,197.9,1363.1z"/>
						<path fill="#EDECEC" d="M175.3,1340.2c0,0.1,0.1,0.3,0.1,0.4c-0.4-0.1-0.8-0.5-1.2,0.3c-1.2,2.7-1.5,5.9-3.4,8.4
							c-0.4,0.7-0.9,1.4-1.4,2.1c-0.9,1.1-2,1.9-3.5,1.6c-1.4-0.3-1.6-1.6-1.8-2.7c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2
							c0,0-0.1,0-0.1-0.1c0,0.1,0,0.2,0,0.2c-1,1.4-1.8,3-3.1,4.2c-1.3,1.1-2.8,1.8-4.6,1.2c-1.8-0.6-2.5-2-2.9-3.7
							c-0.2-0.7-0.2-1.4-0.2-2.1c0.2-0.7,0.3-1.3,0.5-2c1.3-0.2,2.5,0.4,3.8,0.1c0.1,0.2,0.1,0.4,0.2,0.5c-0.2,0.7-0.2,1.5-0.1,2.2
							c0.2,0.8,0.5,1.7,1.4,1.9c0.9,0.2,1.5-0.2,2.1-0.8c0.9-1,1.6-2.1,2.1-3.3c0.3-0.3,0.5-0.6,0.8-0.8c1.4,0.7,2.9,0.1,4.3,0.5
							c0.2,0.5,0.5,0.9,1.2,0.8c0.9-0.2,0.9-1,1.1-1.6c0.8-1.3,1.1-2.8,1.3-4.3c0.3-0.5,0.6-0.9,0.4-1.5c0.1-0.2,0.1-0.4,0.2-0.6
							c0.6-0.1,1.1-0.3,1.7-0.4c0.5,0.3,0.5-0.5,0.9-0.4C175,1339.8,175.2,1340,175.3,1340.2z"/>
						<path fill="#B34F4E" d="M187.1,1374.4c0-1,0.1-2,0.1-3.1c0-0.6,0.2-1.1,1-0.8c3.1,1.1,6.3,0.9,9.4,0.7c1.1,0,2.2,0.1,3.1-0.5
							c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.3-0.2c2.1-1.6,3.2-3.9,4.4-6.2c0.6-1.3,0.8-2.8,2-3.8c1.8-0.5,3-1.9,4.5-2.9
							c0.3-0.1,0.5-0.3,0.6-0.6c0.2-0.1,0.3-0.2,0.5-0.3c0.5-0.4,1-0.8,1.4-1.2c0.6-0.5,1.2-0.8,1.9-0.4c0.6,0.3,1.2,0.8,1.2,1.4
							c0,0.6-0.7,0.6-1.3,0.6c-0.2,0-0.5,0.2-0.7,0.4c-0.6,0.5-0.9,1.4-1.9,1c-0.3,0.8-1.2,1.2-1.4,2.1c-0.1,0.2-0.1,0.6-0.3,0.7
							c-2.6,1-3.5,3.8-5.4,5.4c-1.3,1.1-2.6,2.3-3.9,3.5c-0.6,0.5-1.3,1.1-1.4,1.9c-1.8,1.3-4,1.7-6.1,2.5c-2.3,0.9-4.7,1.5-7,2.2
							C188,1375.7,187.5,1375.1,187.1,1374.4z"/>
						<path fill="#C3B3A6" d="M189.3,1346.9c2.6-3.8,6-6.9,9.6-9.8c1.2-0.9,2.4-1.7,4-1.9c1.1-0.2,2.1-1.4,3-2.4
							c0.5-0.5,1.1-0.8,1.7-1.1c-0.1,1-0.2,2-0.7,2.9c-2.5,0.2-3.6,1.1-4.2,3.5c-0.2,0.9-0.7,1.7-1,2.6c-0.1,0-0.3-0.1-0.4-0.1
							c0.3-0.9,0.8-1.6,0.8-2.6c0-0.1,0-0.2-0.1-0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-2.4,2.4-4.1,5.3-5.6,8.3c-0.3,0.6-1,1.6-0.3,2.1
							c0.7,0.5,1.1-0.7,1.8-0.9c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c-0.2,0.8-0.8,1.2-1.3,1.7c-0.4,0.4-0.8,0.8-1.4,0.4
							c-0.5-0.3-0.5-0.9-0.4-1.5c0.6-2.4,1.9-4.4,3.2-6.4c0.7-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.5-0.6,0.2-0.9
							c-0.4-0.4-0.6,0.2-0.8,0.4c-2.9,3-5.8,6.1-8,9.7c-0.1,0.2-0.3,0.3-0.4,0.5c-0.5-0.6-1.6-0.4-2-1.2
							C189.4,1347,189.4,1347,189.3,1346.9z"/>
						<path fill="#B34F4E" d="M206.9,1334.7c0.5-0.9,0.6-1.9,0.7-2.9c1-1.2,0.9-2.7,0.9-4c0-4.3-0.1-8.6,0.4-12.9l0,0
							c0-0.2,0.1-0.3,0.1-0.5c0-0.4,0-0.8,0.1-1.2c0.7-1.9,0.3-4.1,1.1-6c0.1,0,0.1,0,0.2,0.1c-0.1,1.6,0,3.3-0.3,4.9
							c-0.5,2.3-1,4.7,0.2,6.7c0.9,1.6,0.3,2.8-0.1,4.2c-0.3,1.1-0.9,2.4,0.5,3.3c0.3,0.2,0.1,0.7,0,0.9c-0.6,0.9-0.4,2.1-1.3,3
							c-0.6,0.6-0.5,2.2-0.3,3.3c0.2,1.7-0.7,3.3,0,4.9c0.1,0.3-0.1,0.5-0.3,0.5c-0.5,0-1.1,0-1.4-0.5c-0.2-0.3-0.1-0.8-0.7-0.6l0,0
							C206.2,1336.8,207.4,1335.9,206.9,1334.7z"/>
						<path fill="#C3B3A6" d="M203.4,1355.3c-0.3,0.5-0.2,1.3-1,1.3c-0.6,0-0.5-0.6-0.8-0.9c-0.6,0.4-0.5,1.1-0.7,1.6
							c-0.3,1.1-1.5,1.9-2.5,1.8c-0.9-0.1-0.9-0.9-1.3-1.5c0-0.2-0.1-0.4-0.1-0.6l0,0c0.4-0.9,0.7-1.8,1-2.7
							c0.3-0.9,0.3-2.2,1.6-2.6c0,0.2-0.1,0.4,0,0.6c0.2,0.6,0.4,1.2,0.9,1.8c0.1-3.3,0.7-6.3,2.4-9.2c0.1,2.1-0.7,4.2,0,6.5
							c0.9-2.5,0.7-5.1,1.6-7.4C204.6,1347.8,204.1,1351.6,203.4,1355.3z"/>
						<path fill="#81070E" d="M191.3,1348.3c0.1-0.2,0.3-0.3,0.4-0.5c0.2,0.4,0.6,0.8,0.5,1.2c-0.4,2.5,2,3.1,3.1,4.5
							c0.3,0.4,0.7,0.1,1-0.2c0.5-0.4,0.8-1.1,1.5-0.9c-0.7,1-0.9,2.3-2.3,2.8c-2.5-0.2-5-0.3-7.5,0.3c-0.9,0.1-1.7,0.6-2.5,0.9
							c-0.4-0.7-0.7-1.4-1.1-2.2c1.2-0.1,2.4,0.1,3.6-0.2c1.6,0.3,3.1-0.3,4.4-0.9c1-0.5,0.3-0.8-0.3-1.1c0-0.2-0.1-0.3-0.3-0.3
							c-0.1-0.2-0.3-0.4-0.5-0.4c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0.1,0.2,0.3,0.4,0.5,0.4c0.1,0.1,0.2,0.2,0.3,0.3
							c-1.1,1.2-2.6,1.1-4,1.2c-0.7,0-1.4,0.1-2.1,0.1c-0.3-0.8,0.1-1.7-0.5-2.4c0.7-0.2,1.4-0.2,2-0.6c0.4-0.2,0.9,0.1,1.2-0.3
							c0.5-0.6,1.6,0,1.9-0.9C191,1349.1,191.2,1348.8,191.3,1348.3z"/>
						<path fill="#81070E" d="M191.9,1320.3c0.6-0.1,1.2-0.1,1.8-0.2c1.5,0.5,3-0.1,4.4-0.3c-0.8,1-2,1.1-3.2,1.1
							c-0.8,0.1-1.6,0.1-2.3-0.1c-1.4-0.3-2.4,0.1-3.3,1.3c-1,1.3-2.9,1.1-4-0.3c-0.4-0.5-0.9-1-1.6-1.3c-1.3-0.7-1.6-1.9-0.7-3.4
							c1.2,0.7,2.4,1.5,3.9,1.9l0,0c0.1,0.1,0.2,0.1,0.4,0.2c0.5,0.2,1.1,0.3,1.6,0.5C189.9,1320.1,190.9,1320.3,191.9,1320.3z"/>
						<path fill="#B34F4E" d="M169.3,1324.5c-0.5-0.7-1.5-1.1-1.9-2c2.1,0.5,4.1,0.7,6.2,0.1c1.7-0.7,1.7-0.7,0.4-1.9
							c-0.5-0.4-0.9-0.9-1.1-1.7c-0.3-1.3-1.2-1.7-2.4-1.4c-0.5,0.2-1,0.5-1.3-0.3c-0.4-0.9,0.9-0.1,0.8-0.8c-0.2-0.6-0.9,0-1.3-0.5
							c0.7-0.9,1.7-0.3,2.4-0.4c2.6-0.3,2.6-0.2,2.9,2.5c0.1,0.7,0.6,1,1,1.4c-0.2,1.3,0,1.4,1.5,0.9c0.3,0.7,0.2,1.3-0.5,1.6
							c-1.7,0.8-3.2,1.9-5.2,1.6c-1.3-0.2-0.8,0.5-0.5,1.1l-0.1,0.1C169.9,1324.8,169.7,1324.4,169.3,1324.5z"/>
						<path fill="#81070E" d="M212.2,1344.3c0.9,1,1.6,1.8,1.7,3.1c0.1,1.2,1.6,1.2,2.1,2.3c0.4,1,1.2,1.8,1.8,2.6
							c0.4,0.6,0.2,1.1-0.1,1.6c-0.4,0.6-0.9,0.1-1.3,0c-0.9-0.3-1.5-1.1-2.4-1.3c-0.2,0-0.4-0.3-0.4-0.5c0-2.4-1.1-4.6-1.5-7
							C212,1344.9,212.1,1344.7,212.2,1344.3z"/>
						<path fill="#81070E" d="M212.3,1357.2c-1.5,1-2.7,2.4-4.5,2.9c-0.6-1.5-0.2-3.8,1-4.7c0.3-0.3,0.6-0.6,0.9-0.9
							c0.2-0.3,0.5-0.6,0.9-0.4c0.4,0.1,0.1,0.5,0.2,0.8c0.1,0.8,0.5,0.6,1,0.4c1.7-0.7,1.7-0.7,1.7,1.1c-0.2,0.1-0.3,0.2-0.5,0.3
							C212.6,1356.7,212.4,1356.9,212.3,1357.2z"/>
						<path fill="#C3B3A6" d="M170.8,1349.3c1.9-2.5,2.2-5.6,3.4-8.4c0.3-0.7,0.7-0.4,1.2-0.3c0.4,0.8-0.3,1.5-0.2,2.3
							c-0.4,1-0.6,2-0.9,3c-1.2,1.5-0.7,3.1-0.5,4.7c-0.4,0.2-0.7,0.3-1.1,0.5C171.6,1351.2,171.4,1350,170.8,1349.3z"/>
						<path fill="#81070E" d="M173.7,1322.6c-2.1,0.6-4.2,0.4-6.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3-0.7,0.1-0.9,0.7-1.1
							c1.3-0.3,2.5-0.8,3.3-1.9c0.2-0.3,0.6-0.6,0.8-0.3C172.5,1320.2,173.9,1321,173.7,1322.6z"/>
						<path fill="#B34F4E" d="M181.3,1331.9c1.9-1.8,3.6-3.5,5.1-5.3c1-1.2,2.3-2.2,3.2-3.6c0.2-0.3,0.5-1.1,1.1-0.7
							c0.4,0.3-0.1,0.8-0.2,1.2c-1.3,2.8-3.4,4.8-5.8,6.6C183.6,1330.7,182.7,1331.5,181.3,1331.9z"/>
						<path fill="#B34F4E" d="M170.6,1331.6c0.1,0.1,0.2,0.3,0.4,0.4c-0.2,0.4,0.1,0.5,0.4,0.5c0.2,0.2,0.3,0.5,0.5,0.7
							c0,0.4,0.1,0.7,0.7,0.6c0.1,0.4,0.2,1,0.9,0.7l0,0c-0.4,0.1-0.4,0.5-0.6,0.8c-0.6-0.1-1-0.6-1.4-1c-1.4-0.9-2.7-2-3.2-3.6
							c-0.4-1.4-1.1-2.6-1.8-3.8c-0.8-0.5-1.3-1.1-0.7-2.1c1,1.5,2.1,2.9,3.1,4.4C169,1330.3,169.2,1331.4,170.6,1331.6z"/>
						<path fill="#81070E" d="M189.3,1346.9c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.4-0.4,0.6c-1,0.6-2.1,0.7-3.1,0.8
							c-0.6-0.3-1.4,0-1.8-0.7c-0.3-0.9-1.2-1.2-1.7-1.9c0.2-0.8,1-0.7,1.4-0.6c1.3,0.5,2.8,0.4,3.9,1.4
							C188.2,1347.2,188.9,1346.5,189.3,1346.9z"/>
						<path fill="#C3B3A6" d="M206.3,1342c0.3-1,0.9-1.2,1.7-0.6c1.1,0.9,1,3.1-0.2,3.9c-0.7,0.5-1.3,0.4-1.7-0.3
							C205.5,1344,205.2,1343,206.3,1342c0.9,0.1,1.7,0.4,1.7,1.5C207.9,1342.5,207.7,1341.6,206.3,1342z"/>
						<path fill="#B34F4E" d="M195.5,1355.2c1.3-0.5,1.6-1.8,2.3-2.8c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.3-0.4,0.4-0.6
							c0.4-0.5,0.7-1,1.4-1.3c0.1,0.7,0,1.2-0.2,1.8c-1.3,0.4-1.3,1.7-1.6,2.6c-0.3,0.9-0.6,1.8-1,2.7c-0.4-0.3-0.4-1-1.1-0.8
							C195.9,1355.9,195.7,1355.5,195.5,1355.2z"/>
						<path fill="#81070E" d="M203.1,1313.6c-0.6-1.1-0.2-1.7,0.8-2.1c1.2-0.5,2.2-1.2,3.5-1.5c0.3-0.1,0.7-0.4,0.9,0
							c0.2,0.5-0.2,0.7-0.6,0.9c-1.5,0.9-2.9,2-4.5,2.7L203.1,1313.6z"/>
						<path fill="#81070E" d="M177.6,1342.2c-0.1-0.3-0.8-0.5-0.5-0.9c0.2-0.4,0.7-0.4,1.2-0.3c1.5,0.2,2.1,1,2.3,2.5
							c0,0.3,0.1,0.6-0.2,0.8c-0.3,0.2-0.5-0.1-0.8-0.2c-0.4-0.3-0.9-0.6-1.3-0.9C178.1,1342.8,177.8,1342.5,177.6,1342.2z"/>
						<path fill="#B34F4E" d="M187,1319.1c-1.4-0.3-2.6-1.1-3.9-1.9c-0.6-0.6-1.9-1-0.8-2.3c0.5-0.6,0.7-0.4,0.9,0.1
							c0.7,1.3,1.8,2.1,3.1,2.7C186,1318.5,186.9,1318.6,187,1319.1z"/>
						<path fill="#EDECEC" d="M177.4,1345c0.1-0.1,0.1-0.2,0.1-0.3c0.2,0,0.3-0.1,0.5-0.1c1.4,0.5,1.9,2.1,3.3,2.5
							c-0.2,0.1-0.3,0.2-0.5,0.3c-0.8-0.1-1.3,0.3-1.8,0.9C178,1347.6,177.7,1346.3,177.4,1345z"/>
						<path fill="#81070E" d="M174.6,1338.2c0.5-0.3-0.2-1.2,0.8-1.4c0.8-0.1,1.3,0.3,1.7,0.9c0.5,0.6,0.1,1.1-0.2,1.6
							c-0.2,0.4-0.4,0.9-1,1c-0.2-0.4-0.5-0.6-0.8-0.9c-0.2-0.3-0.3-0.6-0.5-0.9C174.7,1338.4,174.6,1338.3,174.6,1338.2z"/>
						<path fill="#C3B3A6" d="M176.5,1320.5c-1.5,0.6-1.6,0.4-1.5-0.9c1.5-1,0.4-2.5,0.6-3.8
							C176.6,1317.3,176.8,1318.9,176.5,1320.5z"/>
						<path fill="#81070E" d="M209.1,1313.2c0,0.4,0,0.8-0.1,1.2c0,0.2-0.1,0.3-0.1,0.5l0,0c-1.4,1.2-2.7,2.4-4.5,2.9
							c-0.3-0.5,0.2-0.6,0.4-0.8C206.4,1315.9,207.5,1314.3,209.1,1313.2z"/>
						<path fill="#C3B3A6" d="M177.4,1345c0.3,1.3,0.6,2.5,1.7,3.4c-0.1,0.4-0.3,0.8-0.4,1.2c-0.3-0.6-0.7-1.3-1-1.9
							c-0.4-0.9-0.8-0.6-1.3-0.1c-0.2-0.5-0.4-1-0.7-1.5c0.1-0.4,0.2-0.7,0.3-1.1C176.6,1344,177,1345.1,177.4,1345z"/>
						<path fill="#B34F4E" d="M201.3,1340.7c0.1,0,0.3,0.1,0.4,0.1c-0.7,2.4-1.8,4.5-3.4,6.3c-0.1-0.1-0.3-0.1-0.4-0.2
							C199,1344.9,200.4,1342.9,201.3,1340.7z"/>
						<path fill="#B34F4E" d="M216.9,1349.7c-1-1.3-2-2.5-1.7-4.2C216.6,1346.6,217.1,1347.9,216.9,1349.7z"/>
						<path fill="#B34F4E" d="M185.7,1312.2c-0.4,1.2,0.2,1.8,1.3,2c0.7,0.1,1.4,0.3,1.9,1c-2.1,0.5-2.9,0-4.3-2.8
							C184.8,1312,185.2,1312,185.7,1312.2z"/>
						<path fill="#C3B3A6" d="M190.3,1311.4c0.2-0.1,0.3-0.1,0.5-0.2c1.7,0.2,3.5-0.3,5.6,0.5c-1.7,0.5-3.2,0.1-4.5,0.3
							c-0.5,0.1-1.1,0.1-1.6,0C189.5,1311.8,190.1,1311.6,190.3,1311.4z"/>
						<path fill="#B34F4E" d="M205.6,1315c-1.8,1.9-3.8,3.5-6.5,4.2C201.4,1317.9,203.5,1316.4,205.6,1315z"/>
						<path fill="#C3B3A6" d="M180.9,1347.5c0.2-0.1,0.3-0.2,0.5-0.3c0.4,0.1,0.7,0.2,1.1,0.3c0.1,0,0.2,0,0.3-0.1
							c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.7-0.9c-0.1-0.1-0.4-0.2-0.1-0.4c0.1-0.1,0.4,0,0.5,0.1c0.4,0.7,1.4,1,1.7,1.9
							c-0.4,0.2-0.8,0.4-1.2,0.6C182.2,1348.1,181.4,1348.1,180.9,1347.5z"/>
						<path fill="#B34F4E" d="M191.7,1316.7c2.7-0.7,5.4-1.1,8-2.3c-2.1,1.6-4.7,1.8-7.2,2.5C192.2,1317,191.9,1316.8,191.7,1316.7
							L191.7,1316.7z"/>
						<path fill="#B34F4E" d="M200.7,1329c0.4-1,0.9-2,1.3-3.1c0.1-0.2,0.3-0.4,0.6-0.3c0.3,0.1,0.3,0.4,0.2,0.6
							C202.4,1327.3,202.1,1328.5,200.7,1329z"/>
						<path fill="#B34F4E" d="M172.3,1330.6c-0.1-0.2-0.2-0.3-0.4-0.5c0-2-0.7-3.6-1.8-5.3l0.1-0.1
							C172,1326.3,173,1329,172.3,1330.6z"/>
						<path fill="#81070E" d="M176.1,1345c-0.1,0.4-0.2,0.7-0.3,1.1c-0.5,0.4-1,0.4-1.4-0.2c0.2-1,0.4-2,0.9-3
							C175.5,1343.6,175.8,1344.3,176.1,1345z"/>
						<path fill="#C3B3A6" d="M187,1319.1c-0.1-0.5-1-0.6-0.7-1.3c1.3,0.2,2.3,1.2,3.6,1.6c-0.3,0.1-0.6,0.3-0.8,0.4
							c-0.5-0.2-1.1-0.3-1.6-0.5C187.3,1319.2,187.2,1319.1,187,1319.1L187,1319.1z"/>
						<path fill="#C3B3A6" d="M174.6,1338.2c0,0.1,0,0.2,0.1,0.4c-0.2,0.3-0.4,0.6-0.5,0c-0.3-1.1-0.7-2.2-1.4-3.1
							c0.2-0.3,0.1-0.7,0.6-0.8C174.2,1335.7,174.3,1337,174.6,1338.2z"/>
						<path fill="#B34F4E" d="M198.1,1319.8c-1.5,0.3-2.9,0.8-4.4,0.3c1.4-0.6,2.9-1.1,4.5-0.6c0,0,0,0,0,0
							C198.2,1319.7,198.1,1319.7,198.1,1319.8z"/>
						<path fill="#EDECEC" d="M185.7,1312.2c-0.4-0.2-0.8-0.2-1.2,0.1c-0.4-0.3-0.8-0.5-1.3-0.8c0.1-0.2,0.2-0.3,0.2-0.5
							c0.7,0.2,1.4,0.3,2.1,0.2c0.4,0.1,0.7,0.2,1.1,0.3c0.1,0.2,0.2,0.3,0.2,0.5C186.4,1312,186,1312.1,185.7,1312.2z"/>
						<path fill="#B34F4E" d="M182.7,1307.1c0.4,0.5,0.3,1.1,0.6,1.6c-0.8,0.3-1.5,0.3-1.7-0.7
							C181.5,1307.1,182.1,1307.1,182.7,1307.1z"/>
						<path fill="#B34F4E" d="M178,1344.6c-0.2,0-0.3,0.1-0.5,0.1c-0.2-0.6-0.3-1.2-0.6-1.8c-0.4-0.8,0.3-0.7,0.6-0.8
							c0.2,0.3,0.5,0.6,0.7,0.9C177.7,1343.5,177.9,1344.1,178,1344.6z"/>
						<path fill="#B34F4E" d="M186.8,1311.9c-0.1-0.2-0.2-0.3-0.2-0.5c0.8-0.5,1.7,0.1,2.5-0.4c0.3,0.6,0,0.8-0.5,0.9
							C188,1312,187.4,1312.2,186.8,1311.9z"/>
						<path fill="#B34F4E" d="M203.1,1313.6l0.1,0c0.2,1-0.6,0.9-1.2,1c-0.3,0-0.5-0.1-0.5-0.4c0-0.3,0.2-0.5,0.4-0.5
							C202.3,1313.5,202.7,1313.8,203.1,1313.6z"/>
						<path fill="#C3B3A6" d="M172.5,1333.9c-0.5,0.1-0.7-0.2-0.7-0.6c1.1,0,0.1-1.1,0.7-1.4c0.1,0.2,0.3,0.3,0.4,0.5l0,0.1
							c0,0.3,0.3,0.6-0.1,0.8C172.7,1333.6,172.6,1333.7,172.5,1333.9z"/>
						<path fill="#B34F4E" d="M188.9,1319.8c0.3-0.1,0.6-0.3,0.8-0.4c0.7,0.2,1.7,0,2.1,1C190.9,1320.3,189.9,1320.1,188.9,1319.8z"
							/>
						<path fill="#C3B3A6" d="M218.6,1293.6c0.8-0.2,1.5-0.1,2,0.6C219.8,1294.3,219.1,1294.3,218.6,1293.6z"/>
						<path fill="#B34F4E" d="M183.4,1311c-0.1,0.2-0.2,0.3-0.2,0.5c-0.4,0.2-0.7,0.3-0.9-0.1l0,0
							C182.6,1311,182.8,1310.2,183.4,1311z"/>
						<path fill="#B34F4E" d="M201.4,1309.8c0.1,0.2,0.1,0.3,0.2,0.5c-0.5,0.3-0.9,0.5-1.6,0.3
							C200.4,1310.1,200.9,1310,201.4,1309.8z"/>
						<path fill="#C3B3A6" d="M221.2,1294.3c0.4-0.2,0.9-0.2,1.4,0c-0.3,0.5-0.7,0.2-1,0.3C221.4,1294.6,221.2,1294.5,221.2,1294.3z
							"/>
						<path fill="#C3B3A6" d="M175.2,1339.5c0.3,0.2,0.6,0.5,0.8,0.9c-0.3,0.3-0.5,0-0.7-0.1c-0.1-0.2-0.2-0.4-0.4-0.6
							C175,1339.6,175.1,1339.5,175.2,1339.5z"/>
						<path fill="#B34F4E" d="M210.2,1307.2c0-0.3-0.1-0.6,0.1-0.8c0,0,0.2,0,0.2,0.1c0.2,0.3,0,0.5-0.2,0.7
							C210.3,1307.2,210.3,1307.2,210.2,1307.2z"/>
						<path fill="#B34F4E" d="M191.3,1316.6c-0.3,0.1-0.5,0.3-0.8,0.1c0,0,0-0.2,0-0.2C190.8,1316.3,191,1316.5,191.3,1316.6
							L191.3,1316.6z"/>
						<path fill="#EDECEC" d="M172.5,1333.9c0.1-0.2,0.3-0.4,0.4-0.6c0.3,0.4,0.5,0.8,0.5,1.2
							C172.6,1334.9,172.6,1334.4,172.5,1333.9z"/>
						<path fill="#C3B3A6" d="M172.3,1331.4c-0.4-0.1-0.5-0.3-0.1-0.6c0.1,0.2,0.2,0.3,0.4,0.5
							C172.5,1331.3,172.4,1331.3,172.3,1331.4z"/>
						<path fill="#B34F4E" d="M172.3,1331.4c0.1-0.1,0.2-0.1,0.2-0.2c0.4,0.1,0.4,0.3,0.1,0.6
							C172.5,1331.6,172.4,1331.5,172.3,1331.4z"/>
						<path fill="#C3B3A6" d="M197.9,1346.9c0.1,0.1,0.3,0.1,0.4,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1-0.1-0.2-0.1
							C197.8,1347.2,197.8,1347.1,197.9,1346.9z"/>
						<path fill="#C3B3A6" d="M220.6,1294.3c0.2-0.2,0.5-0.2,0.7,0C221,1294.6,220.8,1294.5,220.6,1294.3z"/>
						<path fill="#81070E" d="M198.6,1351.3c-0.1,0.2-0.3,0.4-0.4,0.6C198.1,1351.6,198.3,1351.5,198.6,1351.3z"/>
						<path fill="#B34F4E" d="M183,1306.9c-0.1,0.1-0.2,0.1-0.3,0.2c0-0.1-0.2-0.3-0.1-0.3C182.8,1306.5,182.9,1306.8,183,1306.9z"
							/>
						<path fill="#B34F4E" d="M199.3,1306.9c0.1-0.1,0.2,0,0.2,0.1C199.5,1307,199.4,1307,199.3,1306.9L199.3,1306.9z"/>
						<path fill="#B34F4E" d="M191.7,1316.8c-0.1,0-0.3-0.1-0.4-0.1l0,0C191.4,1316.6,191.5,1316.7,191.7,1316.8L191.7,1316.8z"/>
						<path fill="#B34F4E" d="M172.9,1332.6l0-0.1C172.9,1332.5,173,1332.5,172.9,1332.6C173,1332.5,173,1332.5,172.9,1332.6z"/>
						<polygon fill="#81070E" points="198.2,1319.6 198.2,1319.5 198.2,1319.5 						"/>
						<path fill="#B34F4E" d="M206.6,1338c0,0,0,0.1,0,0.1C206.5,1338,206.5,1338,206.6,1338L206.6,1338z"/>
						<path fill="#81070E" d="M231.3,1351.6c-0.1,0.1-0.2,0.1-0.4,0.2C230.9,1351.4,231.1,1351.5,231.3,1351.6z"/>
						<path fill="#B34F4E" d="M199.7,1307c0.1,0,0.2,0,0.2,0.1C199.8,1307.1,199.8,1307.1,199.7,1307z"/>
						<path fill="#B34F4E" d="M182.2,1311.3c-0.1,0-0.1,0-0.2-0.1C182.1,1311.3,182.2,1311.3,182.2,1311.3L182.2,1311.3z"/>
						<path fill="#C3B3A6" d="M224,1294.5c1-0.2,2,0,3,0.2C226,1294.9,225,1295.1,224,1294.5L224,1294.5z"/>
						<path fill="#C3B3A6" d="M224,1294.5c-0.3,0.2-0.6,0.3-1,0C223.4,1294.2,223.7,1294.3,224,1294.5
							C224,1294.5,224,1294.5,224,1294.5z"/>
						<path fill="#81070E" d="M213.8,1297c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0,0,0.2,0,0.3,0
							C213.7,1296.8,213.8,1296.9,213.8,1297z"/>
						<path fill="#C3B3A6" d="M261.9,1365c-0.6,0.1-0.9,0.9-1.7,0.9c-0.6,0-1.2,0.4-1.1-0.6c0-0.3-0.1-0.6-0.5-0.6
							c-1,0.1-1.2-0.7-1.5-1.3c0.9-1.4,1.2-1.4,2.2-0.2c0.5,0.5,0.5,0.5,1.9-0.6c-0.5-0.1-0.9-0.2-1.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3
							c0.2,0,0.2-0.1,0.1-0.3c0-0.3-0.1-0.6-0.1-0.9c0.3,0.2,0.7,0.9,0.8-0.2c0-0.3,0.4-0.2,0.5,0.1c0.4,0.9,0,2.3,1.6,2.2
							c0.2,0.3,0.3,0.5,0.5,0.8C263.1,1364.3,262.4,1364.6,261.9,1365z"/>
						<path fill="#81070E" d="M260,1360.9c0,0.3,0.1,0.6,0.1,0.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.3
							c-0.7,0.1-0.9-0.7-1.6-0.6c-0.2,0-0.3-0.2-0.3-0.4c0.4-1,0.8-2,1.2-3c0.2-0.5,0.6-0.7,1-0.3c0.3,0.3,0.5,0.7,0,1.1
							C259.6,1359.6,259.3,1360.2,260,1360.9z"/>
						<path fill="#EDECEC" d="M263,1362.9c-1.6,0.1-1.2-1.3-1.6-2.2C262.5,1361.1,263.3,1361.6,263,1362.9z"/>
						<polygon fill="#81070E" points="256.7,1361.9 256.6,1362 256.7,1361.9 						"/>
						<path fill="#B34F4E" d="M264.4,1364c0.4,1-0.7,1.6-0.6,2.5c0,0-0.4,0.2-0.5,0.1c-0.3-0.3-0.1-0.6-0.1-1
							C263.4,1365,263.9,1364.5,264.4,1364z"/>
						<path fill="#C3B3A6" d="M261.7,1367.3c-0.7,0.3-1.2,1.2-2.1,1c0.3-0.6,1-0.6,1.5-1c0.4-0.2,0.7-0.4,1.1-0.5
							C262,1366.9,261.9,1367.1,261.7,1367.3z"/>
						<path fill="#EDECEC" d="M262.6,1366.2c-0.1,0.1-0.2,0.2-0.3,0.3C262.4,1366.4,262.5,1366.3,262.6,1366.2z"/>
						<path fill="#CA0E21" d="M238.7,1380.4c-1.4-0.3-2.7-1.1-4.2-0.1c0,0-0.1,0.1-0.1,0.1c-1.8-0.6-3.1,1-4.7,1.1c0,0,0,0,0,0
							c-1.6,0.4-2.9-0.4-4.2-1.1c-1.1-0.6-2.2-1-3.5-0.6c-0.3-0.6,0.1-1,0.1-1.5c2.2-0.1,4.4-0.2,6.6-0.2c3,0,6-0.2,8.9-0.6
							c1.1-0.2,1.7-0.2,1.3,1.2C238.7,1379.2,238.7,1379.8,238.7,1380.4z"/>
						<path fill="#81070E" d="M205.5,1377.3c3.6-0.5,7.3-0.7,10.8,0.4c-0.6,0.4-1.2,0.2-1.8,0.2
							C211.5,1377.7,208.5,1377.5,205.5,1377.3z"/>
						<path fill="#81070E" d="M233.9,1381.3c0.5-0.2,2.4,0.4,2.5,0.8c0.2,0.5-0.2,0.6-0.5,0.7c-0.9,0.3-1.7,0-2.3-0.7
							C233.3,1381.8,233.5,1381.5,233.9,1381.3z"/>
						<path fill="#81070E" d="M204.9,1377.3c0.2-0.1,0.5-0.2,0.7,0C205.3,1377.3,205.1,1377.3,204.9,1377.3z"/>
						<path fill="#81070E" d="M204.5,1377.2c0.1,0,0.3,0.1,0.4,0.1C204.7,1377.2,204.6,1377.2,204.5,1377.2z"/>
						<path fill="#81070E" d="M216.3,1377.7c0.1,0,0.3,0.1,0.4,0.1C216.6,1377.7,216.5,1377.7,216.3,1377.7z"/>
						<path fill="#B34F4E" d="M243.6,1370.7c-1.6,0.3-3.3,0.6-5.1,0.8c-0.6,0.1-1.3,0.1-1.9,0.4c-0.6,0.3-1,0.5-0.8,1.3
							c0.3,0.9-0.2,1.4-1.1,1.4c-1.4,0-2.7-0.1-3.8-1.2c-0.3-0.3-0.6-0.5-0.9-0.7c-1.2-0.5-2.1-1-1.3-2.6c0.3-0.5,0-1.6-1.2-1.6
							c-0.6,0-0.7-0.8-0.9-1.3c-0.1-0.3,0.2-0.4,0.4-0.5c0.6-0.2,0.9,0.1,1.2,0.5c0.6,0.7,1.2,1.4,2.3,1.3c0.5,0,1,0.5,1.4,0.9
							c0.9,1,2.4,1,3.3,0.1c0.6-0.7,1.2-1.3,2.3-1.3c0.6,0,1.3-0.3,2-0.5c0.6-0.2,1.1-0.3,1.6,0.2c0.4,0.4,0.8,0.4,1.3,0.4
							c1.1-0.1,1.3,1,1.8,1.6C244.6,1370.2,244,1370.4,243.6,1370.7z"/>
						<path fill="#CA0E21" d="M184.8,1372.3c0,0.2,0.1,0.5,0,0.5c-1.7,0.7-2.7,2.1-3.7,3.4c-0.5,0-1,0.1-1.5-0.2
							c0.1-0.8,0.3-1.6-0.1-2.3c0.5-1.4,1.2-2,2.7-0.8c0.6,0.5,1.3-0.3,1.9-0.7C184.3,1372.3,184.5,1372.3,184.8,1372.3z"/>
						<path fill="#CA0E21" d="M187.1,1374.4c0.5,0.6,0.9,1.2,1.4,1.8c-0.7,0.2-1.3,0-2-0.1c-1.1-0.2-2.2-0.2-3.2,0
							C184.4,1375.3,185.8,1375,187.1,1374.4z"/>
						<path fill="#CA0E21" d="M170.8,1349.3c0.6,0.7,0.7,1.9,2,1.8c0.6,0.8,1.4,0.1,2,0.1c0.7,0,1.7-0.3,2,0.4
							c0.5,1.2,1.3,2.5,0.6,4c-3.7-1-7.4-1-11.1,0.1c-1.7,0.5-3.3,1.3-4.9,2c-3.5,1.4-6.8,3-10.1,4.5c-1-1.5-1.8-3.2-3.9-3.4
							c-0.6-0.1-1.3,0-1.8-0.5c0.9-0.3,1.7-0.7,2.4-1.4c2.1-2.2,3.1-4.9,3.8-7.8c0.4,0.1,0.9,0.2,1.3,0.4c-0.1,0.7,0,1.4,0.2,2.1
							c0.4,1.7,1.1,3.1,2.9,3.7c1.8,0.6,3.3-0.1,4.6-1.2c1.4-1.1,2.1-2.7,3.1-4.2l0.1,0c0,0.1,0.1,0.2,0.1,0.3
							c0.2,1.2,0.4,2.4,1.8,2.7c1.5,0.4,2.6-0.4,3.5-1.6C170,1350.7,170.4,1350,170.8,1349.3z"/>
						<path fill="#B34F4E" d="M145.7,1358.3c0.5,0.5,1.2,0.4,1.8,0.5c2.1,0.2,2.9,1.9,3.9,3.4c-0.5,0.3-1,0.6-1.5,0.8
							c-1.6-1.6-3.7-2.4-5.7-3.4c-1.5-0.8-3.1-1.4-4.9-0.6c-0.1,0.1-0.2,0.1-0.4,0.2c-1.7,0.3-1.2-1.1-1.4-1.9l0,0
							c0-0.1,0.1-0.3,0.1-0.4l0,0c0-0.2,0.1-0.3,0.1-0.5c0.6-2.2,1.7-4,3.9-5c0,0.7-0.1,1.4-0.1,2.1c-0.9,0.9-0.8,2.8-2.7,3
							c1.4,0.4,2.7,1.2,4.2,0.9C143.8,1357.9,144.7,1358.2,145.7,1358.3z"/>
						<path fill="#81070E" d="M138.9,1359.1c0.1-0.1,0.2-0.1,0.4-0.2c0.7,0.4,1.4,0.4,2.1,0.5c2.9,0.5,5.4,1.9,7.4,4.1
							c0,0.1-0.1,0.2-0.2,0.2c-1.2,0.7-2.5,1.2-3.9,1.6c-0.5,0.3-0.9-0.2-1.4-0.2c-0.2-0.5-0.6-0.9-1-1.3c-1.2-1.2-2.4-2.3-3.4-3.6
							C138.6,1359.9,138.3,1359.5,138.9,1359.1z"/>
						<path fill="#81070E" d="M177.5,1355.6c0.6-1.5-0.1-2.8-0.6-4c-0.3-0.7-1.3-0.4-2-0.4c-0.7,0-1.4,0.7-2-0.1
							c0.4-0.2,0.7-0.3,1.1-0.5c1.3-0.6,1.7-2,2.5-3c0.4-0.5,0.9-0.8,1.3,0.1c0.3,0.7,0.7,1.3,1,1.9c0.2,1.5,1,2.8,1.3,4.3
							c0.2,0.4,0.4,0.9,0.6,1.3c-0.5,0.4-1,0.8-1.5,1.2c-0.4-0.1-0.8-0.3-1.2-0.4C177.8,1355.9,177.6,1355.7,177.5,1355.6z"/>
						<path fill="#CA0E21" d="M148.8,1363.6c-2-2.3-4.5-3.6-7.4-4.1c-0.7-0.1-1.5-0.2-2.1-0.5c1.7-0.7,3.3-0.1,4.9,0.6
							c2,1,4.1,1.9,5.7,3.4C149.5,1363.2,149.1,1363.4,148.8,1363.6z"/>
						<path fill="#C3B3A6" d="M144.7,1365.4c1.4-0.4,2.7-0.9,3.9-1.6c0.2,0.4,0.4,0.9,0.6,1.3c-0.9,0.5-1.9,1-2.8,1.6
							C145.8,1366.3,145,1366.3,144.7,1365.4z"/>
						<path fill="#B34F4E" d="M186,1369.4c0.2,0.9,0.4,1.9,0.5,2.8c0.1,0.4,0.5,0.9-0.2,1.1c-0.5,0.1-0.5-0.4-0.6-0.8
							c-0.5-1.6-0.8-3.2-1-4.8c0.3-0.2,0.5-0.6,1-0.6l0,0C185.9,1367.9,185.2,1368.7,186,1369.4z"/>
						<path fill="#B34F4E" d="M181.3,1361.2c0.1-0.4,0.1-0.9,0.6-1.2c0.2,0.6,0.4,1.1,0.5,1.6C182,1361.6,181.5,1362,181.3,1361.2z"
							/>
						<path fill="#81070E" d="M181,1365.4c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.3,0,0.4,0C181.2,1365.2,181.2,1365.4,181,1365.4z"/>
						<path fill="#81070E" d="M181.3,1365C181.3,1365,181.2,1365.1,181.3,1365c-0.1,0-0.2-0.1-0.2-0.2
							C181.2,1364.9,181.2,1365,181.3,1365z"/>
						<path fill="#B34F4E" d="M183.5,1368c0.4,0.9,0.8,1.7,0.8,2.7C183.8,1369.8,183.1,1369.1,183.5,1368z"/>
						<path fill="#B34F4E" d="M182.9,1365.6c0.2,0.2,0.3,0.4,0.1,0.7C182.8,1366.1,182.8,1365.9,182.9,1365.6z"/>
						<path fill="#B34F4E" d="M183.3,1367.1c0,0.1,0,0.3,0.1,0.4C183.3,1367.4,183.3,1367.2,183.3,1367.1z"/>
						<path fill="#B34F4E" d="M179.1,1356.4c0.5-0.4,1-0.8,1.5-1.2c0.3,0.5,0.5,1.1,0.6,1.6c-0.4,0.2-0.7,0.4-1.1,0.6
							C179.8,1357.1,179.5,1356.8,179.1,1356.4z"/>
						<path fill="#B34F4E" d="M180.5,1358.3c0,0.1,0.1,0.3,0.1,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
							C180.3,1358.3,180.4,1358.3,180.5,1358.3z"/>
						<path fill="#CA0E21" d="M138.9,1359.1c-0.6,0.4-0.4,0.8,0,1.2c1,1.3,2.3,2.4,3.4,3.6c0.4,0.4,0.8,0.8,1,1.3
							c-2.7,0.1-4.9-1.3-5.9-3.9c-0.4-1.1-0.8-2.3-1-3.5c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.3,1-0.5
							C137.7,1358,137.2,1359.4,138.9,1359.1z"/>
						<path fill="#81070E" d="M155.3,1332.2c0.7,0.2,1.5,0.2,2,0.9c0,0.5-0.6,0.6-0.9,0.8c-2.2,0.6-4.3,1.6-6.1,3.2
							c-0.5,0.1-1-0.3-1.6,0l0,0.1c-0.2-0.3-0.4-0.2-0.6-0.1c-1.3-0.2-2.6-0.2-3.9,0.3c-0.2-0.1-0.4-0.3-0.7-0.4
							c-0.8-1.6-1.6-3.2-2.4-4.7c-0.8-1.5-0.4-2.2,1.3-2c3,0.4,6,0.5,8.9,1.3C152.7,1331.9,153.9,1332.4,155.3,1332.2z"/>
						<path fill="#EDECEC" d="M146.8,1315.9c0.1-0.4,0.3-0.2,0.5-0.2c1.4,0.7,2.9,1.4,4.3,2.1c2.6,1.7,5.6,2.7,8.2,4.3
							c-0.1,0.1-0.1,0.3-0.2,0.4c-1.9-0.9-3.8-1.7-5.7-2.7c-2.2-1.1-4.5-2.3-6.7-3.4C147.1,1316.3,146.8,1316.2,146.8,1315.9z"/>
						<path fill="#B34F4E" d="M141.9,1326.5c1.9,1.6,3.7,2.2,5.5,3.1c-1.7,0.2-3.3-0.3-5,0c-0.6,0.1-0.8-0.5-0.5-1
							C142.2,1328,142.1,1327.5,141.9,1326.5z"/>
						<path fill="#C3B3A6" d="M152,1316.8c0-0.4-1-0.4-0.4-1c0.4-0.4,0.9-0.4,1.3,0c0.7,0.5-0.1,0.8-0.2,1.2
							C152.4,1316.9,152.2,1316.9,152,1316.8z"/>
						<path fill="#C3B3A6" d="M154.6,1318.2c0,0,0-0.1,0.1-0.1c0.4-0.4,0.9-1,1.4-0.6c0.7,0.4-0.3,0.9-0.1,1.4
							C155.4,1318.9,155,1318.6,154.6,1318.2z"/>
						<path fill="#EDECEC" d="M146.3,1315.5c0.3-0.1,0.6-0.1,0.6,0.4C146.5,1316,146.1,1316.1,146.3,1315.5z"/>
						<path fill="#EDECEC" d="M145.9,1315.4c0.2-0.1,0.3-0.1,0.4,0.1c-0.1,0.1-0.1,0.3-0.3,0.2
							C145.9,1315.6,145.9,1315.5,145.9,1315.4z"/>
						<path fill="#81070E" d="M137.5,1357.2c-0.3,0.2-0.6,0.3-1,0.5c-0.6-3.1,0-6,0.8-9c0.6-2.5,1.5-4.9,2.8-7.2
							c0.3-0.1,0.5-0.2,0.8-0.3c0.9,1.3,2.6,1.7,3.4,3.2c-1.1,1.4-1.6,3-2,4.7c-0.2,0.5-0.3,1.1-0.5,1.6c-0.2,0.1-0.4,0.3-0.1,0.5
							l0,0.1c-2.2,1-3.3,2.8-3.9,5c0,0.2-0.1,0.3-0.1,0.5l0,0C137.6,1356.9,137.6,1357,137.5,1357.2L137.5,1357.2z"/>
						<path fill="#CA0E21" d="M144.3,1344.3c-0.8-1.4-2.5-1.8-3.4-3.2c0.3-0.3,0.6-0.5,1.1-0.5c0.2,0.2,0.3,0.5,0.5,0.7
							c0.6,0.7,1.4,1.3,2.2,0.8c0.8-0.6,0.1-1.4-0.3-2.1c0.3-0.1,0.7-0.1,1-0.2c0,0,0.1,0.1,0.1,0.1l0.1-0.1c0.1,0,0.2,0.1,0.3,0.1
							c0.7,0.5,1.6,0.7,2.4,1c0.2,0.2,0.4,0.3,0.5,0.5c-0.5,1.3,0.2,1.7,1.2,1.9c0.9,0.2,1.6-0.4,2.3-0.7c0.5,0.3,1.3,0.1,1.6,0.8
							c-0.4,0.2-0.8,0.5-1.3,0.7c-0.5-0.1-0.8,0.1-1.2,0.2c-0.6-0.1-1.3,0.4-1.9,0.1c-1.7-0.6-3.4-0.9-5-0.1
							C144.4,1344.3,144.3,1344.3,144.3,1344.3z"/>
						<path fill="#C3B3A6" d="M154,1343.4c-0.3-0.7-1.1-0.5-1.6-0.8c0.5-0.8-0.3-0.8-0.7-1c-0.4-0.2-1,0-1.3-0.6
							c0.4-0.4,1.3,0.4,1.5-0.5c0.1-0.5-0.1-0.9-0.5-1.1c-0.8-0.6-3.6-0.5-4.6,0.2c0.3,0.6,1.1,0.5,1.5,1.1c0,0.1-0.1,0.2-0.1,0.3
							c-0.8-0.2-1.7-0.4-2.4-1c0.9-0.1,0.6-0.5,0.2-0.9c-0.6-0.6-1.4-0.9-1.7-1.8c1.3-0.5,2.6-0.5,3.9-0.3c0.2,0,0.4,0.1,0.6,0.1
							c0,0,0-0.1,0-0.1c0.6,1.6,1.6,1.3,2.7,0.6c0.1,0.1,0.3,0.1,0.4,0.2c1,0.8,1.8,1.6,0.9,3.1c-0.4,0.7-0.1,1.1,0.7,1.2
							c0.5,0.1,1,0.2,1.6,0.3c0.1,0.1,0.3,0.3,0.4,0.4c-0.2,0.4-0.1,1-0.9,0.9c-0.1,0-0.1-0.1-0.1-0.2
							C154.4,1343.4,154.2,1343.3,154,1343.4L154,1343.4z"/>
						<path fill="#81070E" d="M144.3,1340.1c0.3,0.7,1.1,1.5,0.3,2.1c-0.8,0.6-1.6-0.1-2.2-0.8c-0.2-0.2-0.3-0.5-0.5-0.7
							C142.8,1340.7,143.4,1339.9,144.3,1340.1z"/>
						<path fill="#C3B3A6" d="M145.5,1339.9l-0.1,0.1c0,0-0.1-0.1-0.1-0.1C145.4,1339.8,145.5,1339.8,145.5,1339.9z"/>
						<path fill="#CA0E21" d="M156.4,1333.9c0.3-0.2,0.8-0.3,0.9-0.8c0.3-0.2,0.7-0.4,1-0.5c2.2,0,2.9-1.8,4.1-3.1
							c0.1,1,1.1,1.2,1.7,1.7c0.8,0.6,2.3-0.4,2.4-1.4c0.1-0.9-0.3-1.8-0.2-2.7c0.7,1.2,1.4,2.4,1.8,3.8c0.5,1.7,1.8,2.7,3.2,3.6
							c0.5,0.3,0.8,0.9,1.4,1c0.7,0.9,1.1,2,1.4,3.1c0.2,0.7,0.3,0.4,0.5,0c0.2,0.3,0.3,0.6,0.5,0.9c-0.1,0.1-0.2,0.1-0.3,0.2
							c-0.4-0.1-0.4,0.7-0.9,0.4c-0.1-0.4-0.3-0.9-0.7-1c-1.7-0.4-3.5-0.3-5.2-0.8c1.7,2,2,2.1,4.2,2.3c-0.1,0.2-0.1,0.4-0.2,0.6
							c-0.9,0.4-1.8,0.8-2.8,0.7c-0.1-0.3,0.5-0.7-0.3-0.8c-1.7-0.1-3.2,0.6-3.8,2.1c-1.5,0.1-2.7,1-4.2,1.1
							c-0.9,0.1-1.9-0.1-2.7,0.4c0-0.1-0.1-0.1-0.1-0.2c0.2-0.6,0.8-1,0.6-1.7c0.6-0.2,1.3-0.4,1.9-0.7c0.4-0.2,1,0,1-0.6
							c0-0.5-0.4-0.8-0.9-1.1c-1.4-0.8-2.8-1.6-3.9-2.8c-0.8-0.9-1.7-1-2.5-0.1c-0.5,0.5-0.8,0.3-1.2-0.1c0.6-0.4,1.3-0.7,1.9-1.1
							C156,1335.6,156.6,1334.9,156.4,1333.9z M166.5,1338.5c-0.1-0.8-0.5-1.2-1-1.3c-1.5-0.4-2.5-1.5-3.8-2.2
							c-0.7-0.4-1.3-0.9-1.9-1.3c-0.6-0.4-1.2-0.2-1.5,0.4c-0.3,0.7,0.2,1.1,0.9,1.2c0.3,0.1,0.6,0.2,1,0.3
							C162.4,1336.1,164.3,1337.5,166.5,1338.5z"/>
						<path fill="#81070E" d="M166.4,1327c-0.1,0.9,0.3,1.8,0.2,2.7c-0.1,1-1.6,2-2.4,1.4c-0.6-0.5-1.5-0.7-1.7-1.7
							c0.7-2.1,1.8-4.1,1.4-6.4c-0.1-0.4,0.1-0.6,0.5-0.2c0.2,0.8,0.8,1.4,1.3,2l0,0C165,1325.8,165.5,1326.4,166.4,1327z"/>
						<path fill="#C3B3A6" d="M170.2,1324.9c1,1.6,1.8,3.3,1.8,5.3c-1.1-1.8-1.8-3.7-2.6-5.6
							C169.7,1324.4,169.9,1324.8,170.2,1324.9z"/>
						<path fill="#C3B3A6" d="M170.6,1331.6c-1.3-0.3-1.6-1.4-1.8-2.4C169.8,1329.7,170.1,1330.7,170.6,1331.6z"/>
						<path fill="#B34F4E" d="M165.6,1324.8c-0.5-0.6-1.1-1.2-1.3-2C165,1323.3,165.6,1323.8,165.6,1324.8z"/>
						<path fill="#C3B3A6" d="M171.3,1332.6c-0.3,0-0.6-0.1-0.4-0.5C171.3,1332.1,171.4,1332.2,171.3,1332.6z"/>
						<path fill="#C3B3A6" d="M156,1315.2c-0.2-0.8-1-0.8-1.5-1.2c-0.2-0.2-0.6-0.2-0.5-0.6c0.1-0.4,0.5-0.5,0.8-0.4
							c1.3,0.5,2.5,1.1,3.7,1.6c0.2,0.1,0.3,0.2,0.2,0.4c-0.1,0.2-0.3,0.5-0.6,0.5c-0.4,0-0.8-0.1-1.3-0.2l0,0
							C156.7,1315.1,156.4,1314.9,156,1315.2L156,1315.2z"/>
						<path fill="#C3B3A6" d="M156.8,1315.4c0.1,1.1-0.3,1.4-1.4,1c-0.5-0.2-1.1-0.3-1.4-0.7c-0.2-0.3-1.1-0.2-0.6-0.9
							c0.3-0.5,0.7-0.3,1.2-0.2c0.5,0.1,0.9,0.7,1.5,0.4l0,0C156.2,1315.5,156.5,1315.7,156.8,1315.4L156.8,1315.4z"/>
						<path fill="#C3B3A6" d="M152.4,1313.8c0.1,0.3,0.4,0.6-0.2,0.8c-0.8,0.3-1.2-0.2-1.7-0.8c-0.4-0.4,0-0.6,0.3-0.8
							C151.6,1312.7,151.9,1313.5,152.4,1313.8z"/>
						<path fill="#C3B3A6" d="M159.1,1316.6c0.3,0.6,0,1.1-0.5,1.1c-0.6,0-1.4-0.1-1.7-0.6c-0.3-0.5,0.4-0.7,0.8-0.8
							C158.1,1316.3,158.6,1316.5,159.1,1316.6L159.1,1316.6z"/>
						<path fill="#B34F4E" d="M163.9,1313.8c0-0.1,0-0.3-0.1-0.4c0,0,0.1,0,0.1,0C164.1,1313.5,164.1,1313.6,163.9,1313.8z"/>
						<path fill="#C3B3A6" d="M191.2,1306.7c-0.3,0-0.5,0.3-0.8,0c0,0,0-0.2,0.1-0.2C190.8,1306.3,191,1306.5,191.2,1306.7
							L191.2,1306.7z"/>
						<path fill="#B34F4E" d="M242.8,1340.2c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0.1-0.1C242.7,1340,242.8,1340.1,242.8,1340.2
							L242.8,1340.2z"/>
						<path fill="#CA0E21" d="M195,1360.1c0.5,0.9-0.3,1.5-0.6,2.2c-0.5,1.2-0.4,2.5-1.1,3.6c-1,1.6-2.6,2.4-4,3.4
							c-0.8,0.5-1.4,0.3-1.9-0.6c-1.2-2.2-1.4-4.6-2.1-6.9c-0.7-2.4-1-5-2.5-7.2c0-0.1,0-0.1,0-0.2c0.2,0,0.5,0,0.7,0
							c0.4,0.9,0.9,1.7,1.3,2.6c-0.2,1.3,0.3,2.3,1.3,3c0.2,2.4,0.7,4.7,1.2,7c0.1,0.4-0.2,1,0.5,1c0.5,0,0.3-0.6,0.3-1
							c0.1-2.3-0.4-4.5-1-6.8c0.8,0.2,1.7,0.4,2.5,0.6c0.5,0.2,0.9,0.4,1.4,0.6c-0.7,0.8-0.1,1.8-0.3,2.6c0,0.1,0.2,0.4,0.3,0.4
							c0.3,0.1,0.4-0.1,0.6-0.3c0.5-0.8,0.7-1.6,0.8-2.5C193.8,1361.8,194.3,1360.8,195,1360.1z"/>
						<path fill="#C3B3A6" d="M195,1360.1c-0.7,0.7-1.2,1.8-2.5,1.6c-0.5-0.2-1-0.3-1.5-0.3c-0.5-0.2-0.9-0.4-1.4-0.6
							c0.2-0.4,0-0.8-0.2-1.1c-0.8-1.3-1.4-2.7-1.5-4.3c2.5-0.6,5-0.5,7.5-0.3c0.2,0.3,0.3,0.7,0.5,1c-0.3,0.6-0.8,1-1.4,1.2
							c-0.1,0.1-0.3,0.1-0.5,0.2c-0.6-0.1-1.3,0-1.9,0.2c-0.8,0.2-2,0.2-2,1.3c0,1.1,1.3,1.2,2.1,1.5c0.3,0.1,0.6,0,0.8-0.1
							c0.5,0,0.9,0,1.2-0.4c0.2-0.1,0.4-0.3,0.5-0.4C194.9,1359.8,195,1359.9,195,1360.1L195,1360.1z"/>
						<path fill="#CA0E21" d="M201.9,1360c0.6,1.9,0.5,3.8-0.1,5.7c-0.2,0.7-0.2,1.9-0.9,2c-0.7,0-1-1.1-1.2-1.8
							c-0.7-2.1-0.8-4.2,0.4-6.2C200.6,1358.9,201.4,1359.1,201.9,1360z"/>
						<path fill="#CA0E21" d="M197.9,1363.1c0.5,1.4,0.2,2.6-0.6,3.7c-0.5,0.8-1.2,0.9-2,0.8c-0.6-0.1-0.8-0.4-0.9-0.9
							c-0.3-1.5,0.2-2.8,1-4C196.2,1361.4,197.2,1361.6,197.9,1363.1z"/>
						<path fill="#C3B3A6" d="M181.2,1356.9c-0.1-0.6-0.2-1.1-0.6-1.6c-0.2-0.4-0.4-0.9-0.6-1.3c0.2-0.1,0.4-0.2,0.6,0
							c0.5,0.4,0.6,1.3,1.6,1.2l0,0c0,0.9,0,1.8-0.1,3.2C181.6,1357.6,181.4,1357.2,181.2,1356.9z"/>
						<path fill="#CA0E21" d="M186,1369.4c-0.8-0.7-0.1-1.5-0.2-2.2c0.9,0.5,1,1.5,1.4,2.2C186.7,1370.2,186.5,1367.3,186,1369.4z"
							/>
						<path fill="#CA0E21" d="M194.4,1360.1c-0.3,0.3-0.7,0.4-1.2,0.4c0.1-0.7,0.5-1.2,0.8-1.8l0,0
							C194.1,1359.1,194.2,1359.6,194.4,1360.1z"/>
						<path fill="#CA0E21" d="M195,1360.1c0-0.2-0.1-0.3-0.1-0.5c0.2-0.6,0.7-1,1.4-1.5C196.1,1359.1,195.6,1359.7,195,1360.1z"/>
						<path fill="#C3B3A6" d="M182.8,1354.4c0,0.1,0,0.1,0,0.2c-0.2,0.2-0.5,0.3-0.7,0.5l0,0c-0.1-0.2-0.2-0.4-0.2-0.6
							C182.3,1354.4,182.5,1354.2,182.8,1354.4z"/>
						<path fill="#CA0E21" d="M197.1,1357c0,0.2,0.1,0.4,0.1,0.6c-0.2,0.1-0.5,0.3-0.6,0C196.5,1357.3,196.9,1357.2,197.1,1357z"/>
						<path fill="#CA0E21" d="M194.1,1357.6c0.2-0.1,0.3-0.1,0.5-0.2c0,0.3,0,0.5-0.2,0.7l0,0C194.3,1358,194.2,1357.8,194.1,1357.6
							z"/>
						<path fill="#CA0E21" d="M194.4,1358.1c0,0.3-0.2,0.4-0.4,0.5l0,0C194,1358.4,194.1,1358.2,194.4,1358.1L194.4,1358.1z"/>
						<path fill="#CA0E21" d="M201.1,1370.3c-0.1,0.1-0.2,0.3-0.3,0.4C200.8,1370.6,201,1370.5,201.1,1370.3z"/>
						<path fill="#81070E" d="M158.3,1344.5c0.8-0.5,1.8-0.3,2.7-0.4c1.5-0.1,2.7-1,4.2-1.1c0,0.2,0.1,0.4,0.1,0.5
							c-0.5,1.2-0.9,2.5-1.4,3.7c-0.3,0.3-0.5,0.6-0.8,0.8c-1.8,0-3.6-0.3-5.5,0.1c-0.1-0.2-0.1-0.4-0.2-0.5
							C157.7,1346.6,158,1345.5,158.3,1344.5z"/>
						<path fill="#C3B3A6" d="M163.9,1347.2c0.5-1.2,0.9-2.5,1.4-3.7c1.2-0.4,2.6-0.4,3.7-1.2c-0.4,1.8-0.6,3.6-0.8,5.4
							C166.8,1347.4,165.2,1347.9,163.9,1347.2z"/>
						<path fill="#CA0E21" d="M157.6,1348.1c1.8-0.4,3.6,0,5.5-0.1c-0.5,1.2-1.2,2.4-2.1,3.3c-0.6,0.6-1.2,1.1-2.1,0.8
							c-0.9-0.3-1.2-1.1-1.4-1.9C157.4,1349.6,157.4,1348.9,157.6,1348.1z"/>
						<path fill="#C3B3A6" d="M158.3,1344.5c-0.3,1-0.6,2.1-0.9,3.1c-1.3,0.3-2.5-0.4-3.8-0.1c-0.3-0.8-0.9-0.5-1.4-0.3
							c-0.4-0.3-0.8-0.6-1.1-1c0.1-0.2,0.3-0.4,0.4-0.6c0.3-0.2,0.7-0.3,1-0.5c1,0.6,1-0.7,1.6-0.8c1.4,1,2.7,0.1,4.1,0
							C158.2,1344.3,158.3,1344.4,158.3,1344.5z"/>
						<path fill="#81070E" d="M168.2,1347.7c0.2-1.8,0.4-3.6,0.8-5.4c0.1-0.2,0.2-0.4,0.4-0.6c1,0.2,1.9-0.3,2.8-0.7
							c0.2,0.6-0.1,1.1-0.4,1.5c-1.8,1.4-2.2,2.7-1.3,4.3c-0.2,0.6-0.2,1.4-1.1,1.6C168.6,1348.7,168.4,1348.3,168.2,1347.7z"/>
						<path fill="#81070E" d="M172.4,1340.5c-2.2-0.2-2.5-0.3-4.2-2.3c1.7,0.5,3.5,0.4,5.2,0.8c0.5,0.1,0.6,0.6,0.7,1
							C173.5,1340.2,172.9,1340.3,172.4,1340.5z"/>
						<path fill="#CA0E21" d="M170.4,1346.9c-0.8-1.6-0.4-2.9,1.3-4.3C171.5,1344.1,171.3,1345.6,170.4,1346.9z"/>
						<path fill="#B34F4E" d="M152.2,1347.2c0.5-0.3,1.1-0.6,1.4,0.3c-0.2,0.7-0.3,1.3-0.5,2c-0.4-0.1-0.9-0.2-1.3-0.4
							C152,1348.5,152.1,1347.8,152.2,1347.2z"/>
						<path fill="#CA0E21" d="M163.9,1349.9c0-0.1,0-0.2,0-0.2c0,0,0.1,0,0.1,0.1c0,0.1,0,0.2-0.1,0.2
							C164,1349.9,163.9,1349.9,163.9,1349.9z"/>
						<path fill="#CA0E21" d="M212.3,1357.2c0.1-0.3,0.3-0.5,0.6-0.6C212.8,1356.9,212.6,1357.1,212.3,1357.2z"/>
						<path fill="#CA0E21" d="M197.9,1346.9c-0.1,0.1-0.1,0.3-0.2,0.4c0,0-0.1,0-0.1,0.1c-0.7,0.3-1.1,1.4-1.8,0.9
							c-0.7-0.5,0-1.5,0.3-2.1c1.5-3,3.2-5.9,5.6-8.3c0.1,0,0.3,0.1,0.4,0.1c0,1-0.5,1.7-0.8,2.6
							C200.4,1342.9,199,1344.9,197.9,1346.9z"/>
						<path fill="#B34F4E" d="M189,1347.7c0.1-0.2,0.2-0.4,0.4-0.6c0.4,0.8,1.4,0.6,2,1.2c-0.1,0.5-0.3,0.8-0.8,0.9
							C190.2,1348.5,189.5,1348.2,189,1347.7z"/>
						<path fill="#CA0E21" d="M201.7,1337.9c0.1-0.1,0.2-0.1,0.3-0.2c0,0.1,0,0.2,0.1,0.4C202,1338,201.9,1338,201.7,1337.9z"/>
						<path fill="#CA0E21" d="M208.9,1314.8c0-0.2,0.1-0.3,0.1-0.5C209,1314.5,208.9,1314.7,208.9,1314.8z"/>
						<path fill="#EDECEC" d="M188,1355.5c0.1,1.6,0.8,2.9,1.5,4.3c0.2,0.3,0.4,0.7,0.2,1.1c-0.8-0.2-1.7-0.4-2.5-0.6
							c-0.3-0.3-0.6-0.4-1-0.3c-1-0.8-1.5-1.7-1.3-3c0.3-0.1,0.5-0.2,0.6-0.5C186.3,1356.1,187.1,1355.6,188,1355.5z"/>
						<path fill="#C3B3A6" d="M189,1347.7c0.5,0.5,1.3,0.7,1.6,1.5c-0.3,0.9-1.4,0.3-1.9,0.9c-0.4-0.7-1-0.6-1.6-0.5l0,0
							c-0.1,0-0.3-0.1-0.4-0.1l0,0c-0.1,0-0.3-0.1-0.4-0.1l0,0c-0.1-0.2-0.2-0.2-0.4-0.1l0,0c-0.1-0.2-0.2-0.2-0.4-0.1l0,0
							c0.1-0.2,0.3-0.4,0.4-0.6C186.9,1348.5,188,1348.4,189,1347.7z"/>
						<path fill="#EDECEC" d="M182.8,1354.4c-0.3-0.2-0.6-0.1-0.9,0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.8-0.6,1.2-0.9
							c0.3-0.1,0.7-0.1,1-0.2c0.7,0.3,1.3,0.7,2.1,0.4c0.7,0,1.4-0.1,2.1-0.1c-0.3,0.2-0.2,0.5-0.1,0.8c-1.2,0.3-2.4,0.1-3.6,0.2
							c-0.3-0.2-0.6,0-0.8,0.1C183.3,1354.4,183.1,1354.4,182.8,1354.4z"/>
						<path fill="#CA0E21" d="M186,1353.4c-0.8,0.3-1.4-0.1-2.1-0.4c0.3-0.9,0-1.8-0.5-2.6c0.5,0.1,0.9,0.1,1.4,0.2
							c0.2,0.1,0.4,0.3,0.6,0.4C186.1,1351.7,185.7,1352.7,186,1353.4z"/>
						<path fill="#C3B3A6" d="M188,1354.1c-0.1-0.3-0.2-0.5,0.1-0.8c1.4-0.1,2.9,0,4-1.2l0,0c0.6,0.2,1.3,0.6,0.3,1.1
							C191.1,1353.8,189.6,1354.4,188,1354.1z"/>
						<path fill="#C3B3A6" d="M185.5,1351c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.5-0.5-1-0.7-1.5c0.4-0.1,0.9,0.3,1.3,0l0,0
							c0.1,0.2,0.2,0.2,0.4,0.1l0,0c0.1,0.2,0.2,0.2,0.4,0.1l0,0c0.1,0,0.3,0.1,0.4,0.1l0,0c0.1,0,0.3,0.1,0.4,0.1l0,0
							c0.1,0.3,0.3,0.5,0.4,0.8C186.8,1350.9,186.1,1350.8,185.5,1351z"/>
						<path fill="#C3B3A6" d="M183.6,1354.4c0.3-0.1,0.5-0.3,0.8-0.1c0.4,0.7,0.7,1.4,1.1,2.2c-0.1,0.3-0.3,0.4-0.6,0.5
							C184.5,1356.1,184,1355.3,183.6,1354.4z"/>
						<path fill="#B34F4E" d="M187.4,1350.4c-0.1-0.3-0.3-0.5-0.4-0.8c0.6-0.1,1.2-0.2,1.6,0.5
							C188.3,1350.5,187.8,1350.3,187.4,1350.4z"/>
						<path fill="#B34F4E" d="M191.3,1351.4c0.3,0,0.4,0.2,0.5,0.4l0,0C191.5,1351.8,191.4,1351.7,191.3,1351.4L191.3,1351.4z"/>
						<path fill="#B34F4E" d="M192.1,1352.1L192.1,1352.1c-0.1-0.1-0.2-0.2-0.3-0.3l0,0C192,1351.8,192.1,1351.9,192.1,1352.1z"/>
						<path fill="#B34F4E" d="M191.3,1351.4c0,0-0.1,0-0.1,0C191.2,1351.4,191.2,1351.4,191.3,1351.4L191.3,1351.4z"/>
						<path fill="#B34F4E" d="M187,1319.2c0.2-0.1,0.3,0,0.4,0.2C187.2,1319.3,187.1,1319.2,187,1319.2z"/>
						<path fill="#B34F4E" d="M176.5,1347.6c-0.8,1-1.2,2.4-2.5,3c-0.3-1.6-0.7-3.3,0.5-4.7c0.4,0.7,0.9,0.6,1.4,0.2
							C176,1346.6,176.3,1347.1,176.5,1347.6z"/>
						<path fill="#B34F4E" d="M185.4,1349.1c-0.4,0.3-0.9-0.1-1.3,0c-0.4,0-0.7-0.1-1.1-0.1c-0.1-0.2-0.2-0.4-0.2-0.6
							c0.4-0.2,0.8-0.4,1.2-0.6c0.4,0.7,1.2,0.5,1.8,0.7C185.7,1348.7,185.6,1348.9,185.4,1349.1z"/>
						<path fill="#B34F4E" d="M206.3,1342c1.4-0.4,1.6,0.5,1.7,1.5C207.9,1342.4,207.2,1342.1,206.3,1342
							C206.3,1342,206.3,1342,206.3,1342z"/>
						<path fill="#CA0E21" d="M182.8,1348.4c0.1,0.2,0.2,0.4,0.2,0.6c-1.3-0.6-2,0.1-2.5,1.2c0.4,0.4,0.9-0.1,1.3,0.1
							c0.6,0.9,0.8,1.9,1.1,2.8c-0.4,0.3-0.8,0.6-1.2,0.9c-0.2-0.4-0.4-0.7-0.6-1.2c-0.2,0.5-0.3,0.8-0.4,1.1
							c-0.2-0.1-0.4-0.1-0.6,0c-0.3-1.5-1.1-2.8-1.3-4.3c0.1-0.4,0.3-0.8,0.4-1.2c0.4-0.6,1-1,1.8-0.9
							C181.4,1348.1,182.2,1348.1,182.8,1348.4z"/>
						<path fill="#CA0E21" d="M182.6,1347.1c0.1,0.1,0.1,0.2,0.2,0.3c-0.1,0-0.2,0-0.3,0.1C182.5,1347.4,182.6,1347.2,182.6,1347.1z
							"/>
						<path fill="#B34F4E" d="M260,1362.1c0-0.1,0.1-0.2,0.1-0.3C260.3,1362,260.2,1362,260,1362.1z"/>
						<path fill="#EDECEC" d="M152.2,1347.2c-0.1,0.6-0.2,1.3-0.3,1.9c-0.7,2.9-1.7,5.6-3.8,7.8c-0.7,0.7-1.5,1.1-2.4,1.4
							c-0.9-0.1-1.9-0.4-2.6-1c-1-1.1-1.2-2.5-1.5-3.9c0-0.7,0.1-1.4,0.1-2.1c0,0,0-0.1,0-0.1c0.2-0.1,0.2-0.3,0.1-0.5
							c0.2-0.5,0.3-1.1,0.5-1.6c0.6,0,0.9-0.3,1.3-0.7c0.9-0.9,0.6-2.5,2.2-2.9c-0.6-0.4-1.2-0.5-1.3-1.2c1.7-0.8,3.4-0.5,5,0.1
							c0,0.1,0,0.2,0,0.3c-0.2,0.5-1.2-0.1-1.1,0.6c0.1,0.7,0.9,0.7,1.4,0.9c0.4,0.1,0.9,0.1,1.3,0.1
							C151.5,1346.5,151.8,1346.9,152.2,1347.2z M150,1347.4c-0.5-0.1-1-0.4-1.5-0.4c-0.6-0.1-1.1-1.1-1.7-0.6
							c-0.4,0.4-0.4,1.2-0.6,1.9c-0.1,0.2,0,0.5,0,0.7c-0.4,1.1-0.4,2.3-0.4,3.5c0,0.8,0.3,1.5,1.1,1.7c0.8,0.2,1.3-0.4,1.7-0.9
							c1.1-1.6,1.6-3.5,1.9-5.3C150.7,1347.4,150.5,1347.3,150,1347.4z"/>
						<path fill="#CA0E21" d="M141.6,1353.4c0.3,1.4,0.5,2.8,1.5,3.9c-1.5,0.3-2.8-0.5-4.2-0.9
							C140.8,1356.2,140.7,1354.4,141.6,1353.4z"/>
						<path fill="#CA0E21" d="M137.5,1357.2c0-0.1,0.1-0.3,0.1-0.4C137.6,1356.9,137.6,1357,137.5,1357.2z"/>
						<path fill="#CA0E21" d="M137.7,1356.8c0-0.2,0.1-0.3,0.1-0.5C137.7,1356.5,137.7,1356.6,137.7,1356.8z"/>
						<path fill="#B34F4E" d="M156.4,1333.9c0.2,1.1-0.4,1.8-1.2,2.3c-0.6,0.4-1.3,0.7-1.9,1.1c-0.5,0.2-0.9,0.5-1.4,0.7
							c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4-0.2-0.8-0.5-1.2-0.7C152.1,1335.4,154.1,1334.5,156.4,1333.9z"/>
						<path fill="#EDECEC" d="M150.3,1337c0.4,0.2,0.8,0.5,1.2,0.7c-1.1,0.7-2.1,0.9-2.7-0.6C149.3,1336.8,149.8,1337.1,150.3,1337z
							"/>
						<path fill="#EDECEC" d="M148.8,1337.1c-0.2,0-0.4-0.1-0.6-0.1C148.4,1336.9,148.6,1336.9,148.8,1337.1z"/>
						<path fill="#C3B3A6" d="M144.5,1344.3c0.1,0.6,0.7,0.8,1.3,1.2c-1.6,0.4-1.3,2.1-2.2,2.9c-0.4,0.4-0.7,0.7-1.3,0.7
							c0.3-1.7,0.8-3.4,2-4.7C144.3,1344.3,144.4,1344.3,144.5,1344.3z"/>
						<path fill="#B34F4E" d="M141.8,1350.6c0.1,0.2,0.1,0.4-0.1,0.5C141.4,1350.9,141.6,1350.8,141.8,1350.6z"/>
						<path fill="#EDECEC" d="M150.4,1341c0.3,0.5,0.9,0.4,1.3,0.6c0.4,0.2,1.2,0.2,0.7,1c-0.7,0.4-1.4,0.9-2.3,0.7
							c-1.1-0.2-1.8-0.6-1.2-1.9c0.2-0.1,0.5-0.3,0.7-0.4C149.8,1341,150.1,1341,150.4,1341z"/>
						<path fill="#81070E" d="M151.1,1346.2c-0.4,0-0.9,0-1.3-0.1c-0.5-0.1-1.3-0.2-1.4-0.9c-0.1-0.7,0.9-0.1,1.1-0.6
							c0-0.1,0-0.2,0-0.3c0.7,0.4,1.3-0.1,1.9-0.1c-0.1,0.5-0.3,0.9,0,1.4C151.3,1345.8,151.2,1346,151.1,1346.2z"/>
						<path fill="#81070E" d="M154.1,1344.3c-0.6,0.1-0.6,1.4-1.6,0.8c0.1-0.4,0.1-0.7,0.2-1.1c0.4-0.2,0.8-0.5,1.3-0.7l0,0
							c0.1,0.1,0.3,0.2,0.4,0.2c0,0.1,0.1,0.1,0.1,0.2C154.4,1344,154.3,1344.1,154.1,1344.3z"/>
						<path fill="#EDECEC" d="M152.7,1344c-0.1,0.4-0.1,0.7-0.2,1.1c-0.3,0.2-0.7,0.3-1,0.5c-0.3-0.5,0-0.9,0-1.4
							C151.9,1344.1,152.3,1343.9,152.7,1344z"/>
						<path fill="#C3B3A6" d="M149.5,1341.1c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2-0.2-0.4-0.3-0.5-0.5c0-0.1,0.1-0.2,0.1-0.3
							C148.7,1340.8,149.1,1340.9,149.5,1341.1z"/>
						<path fill="#81070E" d="M151.9,1337.9c0.5-0.2,0.9-0.5,1.4-0.7c0.4,0.4,0.7,0.6,1.2,0.1c0.8-0.8,1.7-0.7,2.5,0.1
							c1.1,1.2,2.5,2,3.9,2.8c0.5,0.3,0.9,0.6,0.9,1.1c0,0.6-0.7,0.4-1,0.6c-0.6,0.3-1.3,0.4-1.9,0.7c-0.3,0-0.5,0.1-0.8,0.1
							c-0.9-0.7-1.9-1.5-2.9-0.2c-0.5-0.1-1-0.2-1.6-0.3c-0.7-0.1-1-0.6-0.7-1.2C153.7,1339.6,152.9,1338.7,151.9,1337.9z"/>
						<path fill="#81070E" d="M149.5,1341.1c-0.4-0.1-0.8-0.3-1.2-0.4c-0.4-0.6-1.2-0.5-1.5-1.1c0.9-0.7,3.7-0.7,4.6-0.2
							c0.4,0.3,0.6,0.7,0.5,1.1c-0.2,0.9-1.1,0.1-1.5,0.5C150.1,1341,149.8,1341,149.5,1341.1z"/>
						<path fill="#EDECEC" d="M158,1342.7c0.3,0,0.5-0.1,0.8-0.1c0.2,0.7-0.4,1.1-0.6,1.7c-1.4,0.1-2.7,1-4.1,0
							c0.2-0.2,0.3-0.4,0.5-0.5c0.7,0.1,0.7-0.5,0.9-0.9C156.3,1343.1,157.2,1343.1,158,1342.7z"/>
						<path fill="#CA0E21" d="M158,1342.7c-0.8,0.4-1.7,0.5-2.6,0.2c-0.1-0.1-0.3-0.3-0.4-0.4C156.1,1341.2,157,1342,158,1342.7z"/>
						<path fill="#CA0E21" d="M154.4,1343.6c-0.1-0.1-0.3-0.2-0.4-0.2C154.2,1343.3,154.4,1343.4,154.4,1343.6z"/>
						<path fill="#B34F4E" d="M166.5,1338.5c-2.2-1-4.1-2.4-6.4-2.9c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.1-1.2-0.4-0.9-1.2
							c0.3-0.6,0.9-0.8,1.5-0.4c0.7,0.4,1.2,1,1.9,1.3c1.3,0.7,2.3,1.8,3.8,2.2C166,1337.4,166.4,1337.7,166.5,1338.5z"/>
						<path fill="#EDECEC" d="M169.3,1341.7c-0.1,0.2-0.2,0.4-0.4,0.6c-1.1,0.8-2.5,0.8-3.7,1.2c0-0.2-0.1-0.4-0.1-0.5
							c0.7-1.5,2.1-2.2,3.8-2.1C169.8,1341,169.3,1341.4,169.3,1341.7z"/>
						<path fill="#B34F4E" d="M156.8,1315.4c-0.4,0.3-0.6,0.1-0.8-0.3C156.4,1314.9,156.7,1315.1,156.8,1315.4z"/>
						<path fill="#C3B3A6" d="M186.2,1360c0.4-0.1,0.7,0,1,0.3c0.5,2.2,1.1,4.5,1,6.8c0,0.4,0.2,1-0.3,1c-0.7,0-0.4-0.6-0.5-1
							C186.9,1364.7,186.4,1362.4,186.2,1360z"/>
						<path fill="#81070E" d="M191.1,1361.4c0.5,0,1,0,1.5,0.3c-0.1,0.9-0.4,1.7-0.8,2.5c-0.1,0.2-0.3,0.5-0.6,0.3
							c-0.2-0.1-0.4-0.3-0.3-0.4C191,1363.2,190.4,1362.3,191.1,1361.4z"/>
						<path fill="#81070E" d="M194.4,1358.1c-0.2,0.1-0.4,0.3-0.4,0.5c-0.3,0.6-0.7,1.1-0.8,1.8c-0.3,0.2-0.5,0.2-0.8,0.1
							c-0.8-0.3-2.1-0.4-2.1-1.5c0-1.1,1.2-1,2-1.3c0.6-0.2,1.2-0.3,1.9-0.2C194.2,1357.8,194.3,1358,194.4,1358.1z"/>
						<path fill="#B34F4E" d="M180.6,1353.9c0.1-0.3,0.2-0.6,0.4-1.1c0.3,0.5,0.5,0.9,0.6,1.2c0.1,0.1,0.2,0.3,0.3,0.4
							c0.1,0.2,0.2,0.4,0.2,0.6C181.3,1355.2,181.2,1354.3,180.6,1353.9z"/>
						<path fill="#B34F4E" d="M187,1349.6c-0.1,0-0.3-0.1-0.4-0.1C186.8,1349.6,186.9,1349.6,187,1349.6z"/>
						<path fill="#B34F4E" d="M186.6,1349.5c-0.1,0-0.3-0.1-0.4-0.1C186.4,1349.4,186.5,1349.5,186.6,1349.5z"/>
						<path fill="#B34F4E" d="M186.2,1349.4c-0.2,0.1-0.3,0.1-0.4-0.1C186,1349.2,186.1,1349.2,186.2,1349.4z"/>
						<path fill="#B34F4E" d="M185.8,1349.3c-0.2,0.1-0.3,0.1-0.4-0.1C185.6,1349.1,185.7,1349.1,185.8,1349.3z"/>
						<path fill="#B34F4E" d="M182.9,1353.2c-0.3-1-0.5-2-1.1-2.8c0.6-0.2,1.1-0.2,1.7,0.1c0.5,0.8,0.7,1.6,0.5,2.6
							C183.6,1353.1,183.2,1353.1,182.9,1353.2z"/>
						<path fill="#EDECEC" d="M183.4,1350.5c-0.5-0.3-1.1-0.3-1.7-0.1c-0.4-0.2-0.9,0.3-1.3-0.1c0.5-1.1,1.2-1.7,2.5-1.2
							c0.4,0,0.7,0.1,1.1,0.1c0.2,0.5,0.5,1,0.7,1.5C184.4,1350.6,183.9,1350.5,183.4,1350.5z"/>
						<path fill="#CA0E21" d="M150,1347.4c0.4-0.1,0.7,0,0.6,0.5c-0.4,1.9-0.8,3.7-1.9,5.3c-0.4,0.6-0.9,1.1-1.7,0.9
							c-0.8-0.2-1.1-1-1.1-1.7c-0.1-1.2-0.1-2.4,0.4-3.5c0.8-0.4,1.7-0.9,2.6-0.9C149.4,1348.1,149.8,1347.9,150,1347.4z"/>
						<path fill="#B34F4E" d="M150,1347.4c-0.2,0.5-0.6,0.7-1.2,0.7c-1,0-1.8,0.5-2.6,0.9c0-0.2,0-0.5,0-0.7
							c0.2-0.7,0.2-1.5,0.6-1.9c0.6-0.6,1,0.5,1.7,0.6C149,1347,149.5,1347.2,150,1347.4z"/>
					</g>
				</g>
				<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="477.6045" y1="1429.0597" x2="442.6826" y2="1029.9006">
					<stop  offset="0" style={{stopColor:"#534741"}}/>
									<stop offset="3.217822e-02" style={{
										stopColor:"#4C413C",
										stopOpacity:0}}/>
					<stop  offset="0.1739" 
										style={{
											stopColor: "#322B27",
											stopOpacity: 0.1465
										}}
										/>
					<stop offset="0.336" 
									style={{
										stopColor: "#1C1816",
										stopOpacity: 0.314
									}}
									/>
					<stop  offset="0.5136" 
										style={{
											stopColor: "#0C0A0A",
											stopOpacity: 0.4975
										}}/>
					<stop  offset="0.7164" 
										style={{
											stopColor: "#030202",
											stopOpacity: 0.707
										}}
										/>
					<stop  offset="1" 
										style={{
											stopColor: "#000000",
										}}/>
				</linearGradient>
				<path opacity="0.83" clip-path="url(#SVGID_2_)" fill="url(#SVGID_4_)" d="M910,1391.2c-290.5-0.7-581-1.4-871.5-2.2
					c0.5-108,1-216,1.6-323.9C330,1173.8,620,1282.5,910,1391.2z"/>
				<path clip-path="url(#SVGID_2_)" fill="#262222" d="M35.9,319.4c24.1-25.1,48.3-50.3,72.4-75.4c258.1,2.3,516.1,4.5,774.2,6.8
					c23.5,22.9,47,45.8,70.5,68.7C647.3,319.4,341.6,319.4,35.9,319.4z"/>
				<rect x="35.9" y="319.4" clip-path="url(#SVGID_2_)" fill="#131111" width="917.1" height="31.7"/>
				
					<rect x="35.2" y="709.4" transform="matrix(0.9923 0.124 -0.124 0.9923 92.8349 -32.7967)" clip-path="url(#SVGID_2_)" fill="#1E1A1A" width="549.2" height="39.5"/>
				
					<rect x="567.8" y="672.1" transform="matrix(0.9578 -0.2873 0.2873 0.9578 -165.9886 247.3305)" clip-path="url(#SVGID_2_)" fill="#1E1A1A" width="383.6" height="34.1"/>
				<path clip-path="url(#SVGID_2_)" fill="#191818" d="M918.9,1393.1L41.4,1089.9c-0.4-16.2-0.8-32.4-1.2-48.6
					c297.3,104.5,594.6,209.1,891.9,313.6L918.9,1393.1z"/>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M19.7,350.8c3.3-4.9,6.6-9.7,9.8-14.6c0.6-0.9-1.6-0.9-2.1-0.2c-3.3,4.9-6.6,9.7-9.8,14.6
							C17,351.6,19.2,351.6,19.7,350.8L19.7,350.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M17.8,363.5c6.4-5.8,12.9-11.5,19.3-17.3c0.5-0.4-1.6-0.3-2,0.1c-6.4,5.8-12.9,11.5-19.3,17.3
							C15.3,364,17.4,363.9,17.8,363.5L17.8,363.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M21.8,370.6c3.3-2.4,6.5-4.9,9.8-7.3c1.2-0.9-0.6-1.3-1.4-0.8c-3.3,2.4-6.5,4.9-9.8,7.3
							C19.3,370.7,21.1,371.1,21.8,370.6L21.8,370.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M19.7,384.7c3.3-2.4,6.5-4.9,9.8-7.3c0.4-0.3-1.5-0.1-2,0.2c-3.3,2.4-6.5,4.9-9.8,7.3
							C17.3,385.3,19.3,385.1,19.7,384.7L19.7,384.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M19.8,411.8c1.6-1.6,3.3-3.3,4.9-4.9c0.4-0.4-0.3-0.5-0.5-0.4c-0.5,0-1.1,0.2-1.4,0.5
							c-1.6,1.6-3.3,3.3-4.9,4.9c-0.4,0.4,0.3,0.5,0.5,0.4C18.9,412.3,19.5,412.2,19.8,411.8L19.8,411.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M7.8,446.1c3.8-7.2,9.4-13.1,16.5-17.1c1.3-0.7-0.7-1-1.3-0.6c-7.3,4.2-13.2,10.2-17.2,17.7
							C5.3,446.9,7.4,446.7,7.8,446.1L7.8,446.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M26.8,458.5c2.4-1.6,4.9-3.3,7.3-4.9c1.2-0.8-0.7-1-1.3-0.6c-2.4,1.6-4.9,3.3-7.3,4.9
							C24.3,458.7,26.2,458.9,26.8,458.5L26.8,458.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M25,458.1c1.6-4.1,3.3-8.1,4.9-12.2c0.2-0.5-1.9-0.3-2.2,0.3c-1.6,4.1-3.3,8.1-4.9,12.2
							C22.6,458.9,24.7,458.7,25,458.1L25,458.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M21.9,458.2c8.9-10.6,14.9-23.1,17.6-36.7c0.2-1-2-0.7-2.2,0.2c-2.7,13.3-8.5,25.5-17.2,36
							C19.2,458.7,21.3,458.9,21.9,458.2L21.9,458.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M17.5,453.4c3.6-8.8,8.2-17,13.8-24.7c0.8-1.1-1.3-1.3-1.8-0.6c-5.7,7.8-10.4,16.2-14,25.1
							C15,454.4,17.2,454.3,17.5,453.4L17.5,453.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M17.5,443.4c1.6-4.1,3.3-8.1,4.9-12.2c0.2-0.5-1.9-0.3-2.2,0.3c-1.6,4.1-3.3,8.1-4.9,12.2
							C15.1,444.2,17.2,444,17.5,443.4L17.5,443.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M5,485.1c8.9-8.9,17.9-17.9,26.8-26.8c0.9-0.9-1.2-1.1-1.8-0.5c-8.9,8.9-17.9,17.9-26.8,26.8
							C2.3,485.6,4.4,485.7,5,485.1L5,485.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M27.4,475.8c4-4.1,8-8.3,12-12.4c0.9-1-1.2-1.2-1.8-0.6c-4,4.1-8,8.3-12,12.4
							C24.6,476.2,26.7,476.5,27.4,475.8L27.4,475.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#1A1A1A" d="M27.2,504.5c0.8-0.8,1.6-1.6,2.4-2.4c0.4-0.4-0.3-0.5-0.6-0.5c-0.5,0-1.1,0.2-1.4,0.6
							c-0.8,0.8-1.6,1.6-2.4,2.4c-0.4,0.4,0.3,0.5,0.6,0.5C26.2,505.1,26.8,504.9,27.2,504.5L27.2,504.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M39.4,645.7c-2.5-12.2-5-24.4-7.6-36.5c-0.1-0.6-3,0.1-2.9,0.8c2.5,12.2,5,24.4,7.6,36.5
							C36.6,647.1,39.5,646.4,39.4,645.7L39.4,645.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M47.9,625c-1.4-12.2,2.5-24.5,11.1-33.3c4.6-4.7-4.1-5.6-7-5.5c-6.3,0.3-14.7,2.4-19.3,7.1
							c-10.3,10.5-15.5,24.7-13.7,39.4C19.7,639.2,48.9,633.3,47.9,625L47.9,625z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M59.4,646.8c-9.9-5.7-14.3-18.2-9.9-28.3c2.9-6.6-2.7-11.9-9-12.8c-6.8-1-15.9,2.3-18.8,9
							c-7.7,17.5-1.3,39.5,15.5,49.2c6.5,3.7,14.9,3,20.8-1.6C62.5,658.8,65.9,650.6,59.4,646.8L59.4,646.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M60.9,578.5c-2.1-9.7-4.2-19.3-6.3-29c-1.7-8-30.9-0.9-29,7.8c2.1,9.7,4.2,19.3,6.3,29
							C33.7,594.3,62.8,587.2,60.9,578.5L60.9,578.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M60.3,537.2c-2.6-6.4-5.2-12.9-7.8-19.3c-2.7-6.6-10.9-8.2-17.2-6.2c-5,1.5-14.5,7.2-11.8,14
							c2.6,6.4,5.2,12.9,7.8,19.3c2.7,6.6,10.9,8.2,17.2,6.2C53.5,549.7,63,544.1,60.3,537.2L60.3,537.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M52.8,512.8c0-0.8,0-1.6,0-2.4c-0.3-1-0.9-1.6-1.9-1.9c-1-0.7-2.2-1-3.5-1.1c-1.7-0.2-3.3-0.2-5,0
							c-1.9,0.1-3.8,0.4-5.7,1c-1.3,0.4-2.5,0.8-3.8,1.2c-2.1,0.8-4.1,1.9-6,3.2c-0.6,0.6-1.3,1.2-1.9,1.8c-1,1-1.4,2.1-1.2,3.5
							c0,0.8,0,1.6,0,2.4c0.3,1,0.9,1.6,1.9,1.9c1,0.7,2.2,1,3.5,1.1c1.7,0.2,3.3,0.2,5,0c1.9-0.1,3.8-0.4,5.7-1
							c1.3-0.4,2.5-0.8,3.8-1.2c2.1-0.8,4.1-1.9,6-3.2c0.6-0.6,1.3-1.2,1.9-1.8C52.6,515.4,53,514.2,52.8,512.8L52.8,512.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M37,517.4c1.6,0,3.3,0,4.9,0c2-0.1,4-0.6,5.8-1.4c1.8-0.5,3.5-1.4,5.1-2.6c1.4-0.9,2.6-2.1,3.4-3.6
							c1-1.1,1.5-2.5,1.7-4c0.2-2.8-0.9-5.5-3.5-7.1c-0.9-0.5-1.9-0.9-2.8-1.4c-2.3-0.7-4.7-1-7.1-0.8c-1.6,0-3.3,0-4.9,0
							c-2,0.1-4,0.6-5.8,1.4c-1.8,0.5-3.5,1.4-5.1,2.6c-1.4,0.9-2.6,2.1-3.4,3.6c-1,1.1-1.5,2.5-1.7,4c-0.2,2.8,0.9,5.5,3.5,7.1
							c0.9,0.5,1.9,0.9,2.8,1.4C32.2,517.3,34.6,517.6,37,517.4L37,517.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M40,507.9c6.5,0,15.4-2.1,19.1-7.9c3.7-5.7-3.1-8.6-7.9-8.6c-6.5,0-15.4,2.1-19.1,7.9
							C28.5,505,35.3,507.9,40,507.9L40,507.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M52.3,499.8c2.1-11.8,6.7-22.8,14-32.3c4-5.2-3.5-6.6-7.3-6.4c-6,0.2-15.3,2.3-19.3,7.4
							c-8.2,10.6-13.8,22.6-16.2,35.8C22,513,50.8,508.1,52.3,499.8L52.3,499.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M63.4,885.7c-6.2-19.4,5.2-38,8.4-56.8c3.1-18.7-2-36.8-14.2-51.3c0.2,0.8,0.5,1.7,0.7,2.5
							c-18.3,0.5-24.4,2.3-18.5,5.3c0.2,0.1,0.4,0.3,0.6,0.4c0.7,0.9,1.4,1.5,1.9,2.6c1,2.1,1.4,4.5,1.6,6.8
							c0.6,6.4,0.1,13-1.1,19.2c-2.1,10.9-6.5,21.1-9.2,31.8c-2.5,9.8-4.1,20.5-0.2,30.2c1.9,4.8,5.5,8.6,7.5,13.3
							c0.8,1.7,1.7,4.1,1.3,6c-0.1,0.5-0.4,1-0.5,1.5c-0.2,0.3-0.5,0.5-0.7,0.8c17.3,1.7,23.5,0.1,18.6-4.7
							c-4.7-7.4-31.6,3.7-29,7.8c5.7,8.9,21.8,3.5,29.3-0.1c6.4-3.1,12.8-8.3,10.9-16.2c-1.8-7.5-8.1-12.9-9.9-20.5
							c-1.9-7.9-0.6-16,1.5-23.7c4.6-17.2,11.7-33.4,10.6-51.7c-0.4-7.2-3.1-13.7-10.8-15.3c-6.6-1.4-14.1,0.2-20.3,2.5
							c-4.7,1.7-9.9,4.2-12.2,8.9c-0.5,1-0.2,1.9,0.7,2.5c11.9,14.1,16,33.3,11.9,51.1c-4.1,17.8-13.7,36.2-7.7,54.7
							C36.7,900.5,65.5,892.2,63.4,885.7L63.4,885.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M67.6,1010.4c-0.1-19,2.6-37.7,7.8-56c1.9-6.7-8.7-8.5-13-8c-6.6,0.8-14.2,4-16.2,10.9
							c-5.2,18.2-7.9,37-7.8,56c0,6.7,7.2,8.7,13,8C56.3,1020.7,67.6,1017.2,67.6,1010.4L67.6,1010.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M57.7,927.5c0-0.8,0-1.6,0-2.4c-0.3-0.9-0.9-1.5-1.8-1.8c-1-0.6-2.2-0.9-3.5-1c-1.7-0.2-3.3-0.1-5,0.1
							c-1.9,0.1-3.8,0.5-5.7,1c-1.3,0.4-2.5,0.8-3.8,1.2c-2.1,0.8-4.1,1.8-6,3.1c-0.7,0.6-1.3,1.1-2,1.7c-1,0.9-1.4,2-1.2,3.3
							c0,0.8,0,1.6,0,2.4c0.3,0.9,0.9,1.5,1.8,1.8c1,0.6,2.2,0.9,3.5,1c1.7,0.2,3.3,0.1,5-0.1c1.9-0.1,3.8-0.5,5.7-1
							c1.3-0.4,2.5-0.8,3.8-1.2c2.1-0.8,4.1-1.8,6-3.1c0.7-0.6,1.3-1.1,2-1.7C57.5,929.9,57.9,928.8,57.7,927.5L57.7,927.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M62.6,959.2c-0.8-3.3-1.6-6.5-2.4-9.8c-0.7-2.7-3.5-4.5-6-5.2c-3.3-1-7.7-0.9-11,0.1
							c-3.4,1-7.2,2.6-9.5,5.4c-1.8,2.1-3.3,4.6-2.5,7.5c0.8,3.3,1.6,6.5,2.4,9.8c0.7,2.7,3.5,4.5,6,5.2c3.3,1,7.7,0.9,11-0.1
							c3.4-1,7.2-2.6,9.5-5.4C61.8,964.6,63.3,962.1,62.6,959.2L62.6,959.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M57.7,951.9c0-8.1,0-16.3,0-24.4c0-4.8-29-0.7-29,7.8c0,8.1,0,16.3,0,24.4
							C28.7,964.5,57.7,960.3,57.7,951.9L57.7,951.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M57.7,668.9c0-1.6,0-3.3,0-4.9c0-1.6-4.5-1.8-5-1.8c-3.4-0.1-7.3,0.6-10.6,1.5c-3.4,1-7,2-9.9,4
							c-1.5,1-3.5,2.1-3.5,4.1c0,1.6,0,3.3,0,4.9c0,1.6,4.5,1.8,5,1.8c3.4,0.1,7.3-0.6,10.6-1.5c3.4-1,7-2,9.9-4
							C55.7,672.1,57.7,671,57.7,668.9L57.7,668.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M55.5,666.5c-0.8-4.9-1.7-9.7-2.5-14.6c-0.3-2-3.9-2.6-5.3-2.7c-3.4-0.4-7.4,0.2-10.7,1.2
							c-3.4,1-7,2.1-9.8,4.3c-1.5,1.2-3.6,2.8-3.2,5c0.8,4.9,1.7,9.7,2.5,14.6c0.3,2,3.9,2.6,5.3,2.7c3.4,0.4,7.4-0.2,10.7-1.2
							c3.4-1,7-2.1,9.8-4.3C53.8,670.3,55.9,668.7,55.5,666.5L55.5,666.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M56,592.5c3.4,0.4-1.2-0.7,1.1,0.3c-0.6-0.3-1.2-0.6-1.8-1c-1.6-0.9,0,0.4-1.2-1
							c-0.6-0.7-1.1-1.3-1.6-2c-1.5-2.1-2.1-5.2-1.7-7.7c2.1-12.6-27.2-8.1-29.1,3.1c-2.5,14.9,10,25.2,23.6,26.6
							c6.5,0.7,15.7-2.4,19-8.3C67.7,596.3,61.2,593,56,592.5L56,592.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M866.8,27.6c-16.2-7.9-33.6-14.7-51.8-9.8c-5,1.3-9.4,3.5-13.8,6.2c-1,0.6-3.9,2.6-4.1,2.5
							c0.1,0,2.5-0.6,2.5-0.7c0.9,0.2,0.6,0.1-1-0.4c-0.5-0.2-0.9-0.4-1.4-0.6c-1.3-0.6-2.8-1.8-4-2.7c-2.7-2-5.1-4.4-7.4-6.9
							c-5-5.3-13.4-4.9-19.6-1.9c-4.4,2.1-12,9.2-7,14.5c13.3,14,30.2,24,49.7,15.4c3.5-1.6,7.7-4.7,10.3-5.9c1-0.4,1-0.5,0.1-0.1
							c1.8-0.4,2.1-0.5,0.8-0.3c1.6-0.4,1.6-0.2,3.6,0c7,0.7,14.2,4,20.6,7.1c6.7,3.3,14.7,2.6,20.7-1.9
							C869.2,39.1,873.5,30.9,866.8,27.6L866.8,27.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M771.5,21.6c-16.8-4.8-35.6-1.3-49.6,9.1c-4.5,3.3-7.9,9.8-1.9,13.6c5.8,3.7,15.4,1.8,20.7-2.1
							c0.8-0.6,1.6-1.2,2.5-1.8c-1.2,0.8,0.3-0.2,0.8-0.4c0.8-0.4,2-0.8,0.5-0.3c0.3-0.1,4-1,2.5-0.7c-1.3,0.2,1.5-0.1,1.8-0.2
							c2.4-0.2-1.6-0.1,0.9,0c2.3,0.1,2.4,0.1,4.9,0.8c6.8,1.9,15.6-0.2,20.5-5.5C779.2,29.6,777.9,23.4,771.5,21.6L771.5,21.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M915.2,72.1c2.7-5.1,6-9.7,10.3-13.6c3.4-3.1,8.2-5.9,10.7-6.8c2.2-0.7,11.9-4.1,8.8-7.6
							c-3-3.3-15.6,1-18.6,2c-16.5,5.6-31.4,15.6-39.7,31.3c-2.3,4.3,10.1,2.4,11.4,2.2C902.7,78.8,912.6,77,915.2,72.1L915.2,72.1z
							"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M943.4,50.8c11.5-3.4,22.1-10.9,26.4-22.4c-9.4,0.7-18.9,1.5-28.3,2.2c2.6,7.5-1.1,14.2-4.4,22.1
							c-3.8,9-7.1,18.6-4.3,28.4c3.8,13.3,32.8,5.5,29-7.8c-2.2-7.6,1.9-14.6,5.1-22.3c3.7-8.9,6.9-18.6,3.6-28.1
							c-3.9-11.1-24.7-7.5-28.3,2.2c-0.9,2.4-1.5,3.8-3.1,5.4c-1.1,1.1,1.3-0.4-1.3,0.9c2.2-1.1-2.8,0.6,0.4-0.4
							c-5.6,1.7-13.7,6.7-11.8,13.8C928.1,51.6,937.9,52.4,943.4,50.8L943.4,50.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M951.5,14.7c-13.8-4-28.6-1.5-40.1,7.3c-4.9,3.7-7.4,10.8-1.6,14.9c5.9,4.2,15.4,2.3,20.8-1.8
							c3.4-2.6-1.7,1.8,1.5-1c-1.4,0.4-1.6,0.5-0.5,0.2c0.7-0.2,1.3-0.4,2-0.5c-2.9,0.6,1.9,0.3-0.8,0.1c0.5,0,3.9,0.8,2.1,0.3
							c7,2,15.7,0.3,20.5-5.5C959.4,23.8,958.1,16.6,951.5,14.7L951.5,14.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M925,25.9c-0.8-0.8-1.6-1.6-2.4-2.4c-0.9-0.8-2-1.3-3.2-1.5c-1.5-0.5-3-0.6-4.5-0.5
							c-1.9,0-3.8,0.2-5.6,0.7c-2,0.3-3.9,0.9-5.7,1.7c-1.1,0.5-2.2,1-3.3,1.6c-1.8,0.9-3.3,2.2-4.6,3.7c-0.4,0.6-0.7,1.3-1.1,1.9
							c-0.4,1.3-0.1,2.4,0.9,3.4c0.8,0.8,1.6,1.6,2.4,2.4c0.9,0.8,2,1.3,3.2,1.5c1.5,0.5,3,0.6,4.5,0.5c1.9,0,3.8-0.2,5.6-0.7
							c2-0.3,3.9-0.9,5.7-1.7c1.1-0.5,2.2-1,3.3-1.6c1.8-0.9,3.3-2.2,4.6-3.7c0.4-0.6,0.7-1.3,1.1-1.9C926.3,28,926,26.9,925,25.9
							L925,25.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M957.2,1325.4c41.3-59,15.1-133.7,8.3-198.5c-7.3-70.5,0.9-141.4,1.8-212.1
							c0.5-40.1-1.3-80.5-9.3-119.9c-2.4-11.7-31.4-4.4-29,7.8c13.8,69,8.5,139.4,5.7,209.1c-1.4,35-2.1,70,0.3,104.9
							c2.5,35.7,10.7,70.5,14.3,106.1c3.5,34.5,0.9,71.4-19.5,100.6c-4.1,5.8,3.5,10,8.4,10.4C945.4,1334.4,953,1331.4,957.2,1325.4
							L957.2,1325.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M969.8,1059.6c2.3-14.1,0.9-28.7-4.3-42c-2.6-6.7-10.9-8.3-17.2-6.4c-5.1,1.6-14.4,7.2-11.7,14.2
							c4.6,11.8,5.9,23.8,3.9,36.3c-1.2,7.1,7.9,10.5,13.5,10C960.7,1070.9,968.6,1067,969.8,1059.6L969.8,1059.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M957.8,776.4c0-5.7,0-11.4,0-17.1c0-6.8-7.2-9.1-13.1-8.4c-3.8,0.5-7.8,1.6-10.9,3.9
							c-2.1,1.6-5.2,4.2-5.2,7.2c0,5.7,0,11.4,0,17.1c0,6.8,7.2,9.1,13.1,8.4c3.8-0.5,7.8-1.6,10.9-3.9
							C954.8,781.9,957.8,779.3,957.8,776.4L957.8,776.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M962.8,749.9c0-5.7,0-11.4,0-17.1c0-7.2-7-10.8-13.6-10.1c-5.9,0.6-15.9,4.7-15.9,12.1
							c0,5.7,0,11.4,0,17.1c0,7.2,7,10.8,13.6,10.1C952.9,761.4,962.8,757.3,962.8,749.9L962.8,749.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M965.4,605.5c-1.1-11.7-3.7-23.1-7.8-34.1c-2.9-7.9-31.5,0.9-29,7.8c4.1,11,6.7,22.4,7.8,34.1
							C937,620,966.2,614.3,965.4,605.5L965.4,605.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M952.3,541.6c6.1-12.6,12.2-25.7,11.5-40c-0.6-11.9-6.4-22.9-16.9-28.8c-6.1-3.4-15.1-1.3-20.6,2.4
							c-4.3,2.9-8.3,9.2-2.2,12.6c7.3,4.1,10.6,13,10.7,21.2c0.1,11-5.1,21.5-9.7,31.1C918.6,553.4,947.7,551.2,952.3,541.6
							L952.3,541.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M962.7,444.8c1.1-7.5,0.3-15.2-2.4-22.3c-2.6-6.6-11-7.7-17.1-5.8c-4.8,1.5-14.5,6.9-11.9,13.6
							c2.2,5.7,2.9,10.8,2.1,16.8c-1,7,7.8,9.8,13.3,9.3C953.1,455.7,961.6,452.1,962.7,444.8L962.7,444.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M937.4,397.2c6.3,0,15.2-2.3,19.2-7.5c4.1-5.4-3.5-7.1-7.5-7.1c-6.3,0-15.2,2.3-19.2,7.5
							C925.8,395.5,933.4,397.2,937.4,397.2L937.4,397.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M98.4,37.7c17.9-1.1,34.8,3.7,50,13.3c7.6,4.8,34.4-6.5,23.2-13.6c-18.2-11.5-40.2-16.7-61.7-15.4
							c-6.5,0.4-15.2,2-19.2,7.8C87,35.1,93.8,37.9,98.4,37.7L98.4,37.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M19.9,575.8c2.4-2.4,4.9-4.9,7.3-7.3c0.4-0.4,1.1-1,0.9-1.7c-0.2-0.7-1.1-0.9-1.6-1.1
							c-1.8-0.3-4.8,0.3-6.1,1.6c-2.4,2.4-4.9,4.9-7.3,7.3c-0.4,0.4-1.1,1-0.9,1.7c0.2,0.7,1.1,0.9,1.6,1.1
							C15.5,577.8,18.5,577.2,19.9,575.8L19.9,575.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M117.4,1104.5c-0.2-8.3,3.1-16,9.5-21.4c1.1-1-0.6-1.7-1.4-1c-6.7,5.7-10.4,13.7-10.2,22.5
							C115.3,1105.8,117.5,1105.4,117.4,1104.5L117.4,1104.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M129.3,1102c2.5-4,4.9-8.1,7.4-12.1c0.5-0.9-1.6-0.7-2-0.1c-2.5,4-4.9,8.1-7.4,12.1
							C126.7,1102.8,128.9,1102.6,129.3,1102L129.3,1102z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M130.2,1100c3.2,1.6,6.5,3.3,9.7,4.9c0.6,0.3,2.6-0.6,1.7-1c-3.2-1.6-6.5-3.3-9.7-4.9
							C131.4,1098.7,129.4,1099.6,130.2,1100L130.2,1100z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M158.7,1112c1.6-1.6,3.3-3.3,4.9-4.9c0.9-0.9-1.2-0.9-1.7-0.4c-1.6,1.6-3.3,3.3-4.9,4.9
							C156.1,1112.6,158.2,1112.6,158.7,1112L158.7,1112z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M166.2,1119.5c2.4-1.5,4.8-2.9,7.2-4.4c-0.6,0.1-1.2,0.2-1.9,0.3c7.2,3.7,4.3,14.2,11.7,17.7
							c3.1,1.5,7.6,1.3,8.9-2.4c-0.7,0.2-1.4,0.3-2.2,0.5c1.5,2.5,3,5,4.5,7.5c0.2,0.4,1.2,0,1.5-0.1c4.8-1.7,9.3-3.9,13.6-6.7
							c-0.6,0.1-1.2,0.2-1.9,0.3c1.7,1.9,3.3,3.8,5,5.7c0.3,0.4,1.9-0.2,2.1-0.6c0.9-1.9,1.8-3.9,3.1-5.6c2.2-3,7.2-5.9,10.7-3.4
							c3.5,2.6,0.8,7.3,4.3,9.8c1,0.7,2.3,1,3.5,1.2c2.5,0.4,2.8,1.1,3.1,3.7c0.7,5.4,0.6,11,1,16.4c0,0.4,1.6-0.1,1.7-0.2
							c13.1-6.2,25.9-13.2,38.2-20.8c-0.6,0.1-1.3,0.2-1.9,0.3c2.2,3.8,3.4,7.9,3.2,12.4c-0.4,6.5-1.1,1,0.4,4.4
							c0.8,1.7,1.9,3.2,2.5,5.1c2.6,7.3,1.4,15.2-2.9,21.6c-0.2,0.4,1.1,0.1,1.2,0.1c18-4.5,36.5-5.4,54.8-2.8
							c0.5,0.1,2.4-0.8,1.8-0.8c-19-2.7-38.1-1.7-56.7,3c0.4,0,0.8,0.1,1.2,0.1c4-6,5.3-13.4,3.3-20.4c-1.1-3.9-3.2-6.6-3.3-10.8
							c-0.2-4.6-0.9-8.3-3.2-12.4c-0.3-0.5-1.6,0.1-1.9,0.3c-12.2,7.5-24.8,14.4-37.8,20.6c0.6-0.1,1.1-0.1,1.7-0.2
							c-0.4-6.7-0.8-13.3-1.2-20c0-0.1-0.3-0.1-0.4-0.1c-2.8-0.1-6.6-0.3-7.9-3.4c-0.9-2.2,0.1-4.6-1.4-6.6c-1.5-2-4.2-2.6-6.6-2.5
							c-6.6,0.3-10,6.2-12.6,11.5c0.7-0.2,1.4-0.4,2.1-0.6c-1.7-1.9-3.3-3.8-5-5.7c-0.3-0.4-1.6,0.2-1.9,0.3
							c-4,2.6-8.2,4.6-12.7,6.3c0.5,0,1,0,1.5-0.1c-1.5-2.5-3-5-4.5-7.5c-0.3-0.4-2,0-2.2,0.5c-1.8,5.4-8.3-1.2-9.3-3.7
							c-1.5-3.3-1.7-7.2-3.9-10.2c-3.9-5.2-7.8-0.4-12.1,2.2C163.6,1120,165.8,1119.7,166.2,1119.5L166.2,1119.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M322.4,1175c-0.8-4.4,0.8-8.8,4.4-11.4c1.2-0.9-0.6-1.5-1.4-0.9c-4.1,3-6.2,7.9-5.2,12.9
							C320.4,1176.5,322.6,1175.9,322.4,1175L322.4,1175z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M353.4,1180.7c11.1-3.7,21.2-9.7,29.7-17.7c0.5-0.5-1.6-0.3-2,0.1c-8.3,7.7-17.8,13.4-28.5,17
							C351.2,1180.5,352.6,1181,353.4,1180.7L353.4,1180.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M408.2,1199.2c-2.4-6.1,0.1-13.4,6.1-16.3c1.3-0.6,0.1-1.9-1-1.3c-6.7,3.3-10.1,11.2-7.3,18.3
							C406.4,1200.8,408.5,1200.2,408.2,1199.2L408.2,1199.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M409.6,1210.1c8.7-4.2,16.3-10,22.5-17.3c0.9-1-1.2-1.3-1.8-0.6c-6.1,7-13.3,12.6-21.7,16.7
							C407.3,1209.5,408.5,1210.6,409.6,1210.1L409.6,1210.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M437.9,1207.9c7.6-0.1,14.6-4,18.3-10.7c0.5-0.9-1.7-0.7-2-0.1c-3.1,5.7-8.8,9.5-15.4,9.5
							C437.9,1206.6,436.5,1207.9,437.9,1207.9L437.9,1207.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M458.4,1209.7c3.3-2.4,6.5-4.9,9.8-7.3c1.2-0.9-0.6-1.6-1.4-1c-3.3,2.4-6.5,4.9-9.8,7.3
							C455.8,1209.6,457.6,1210.4,458.4,1209.7L458.4,1209.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M473,1217.3c3.2-1.6,6.5-3.3,9.7-4.9c1.3-0.7,0.1-1.4-0.9-0.9c-3.2,1.6-6.5,3.3-9.7,4.9
							C470.8,1217.1,472,1217.8,473,1217.3L473,1217.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M485.4,1221.5c6.5-4.1,13-8.1,19.5-12.2c0.9-0.6-1.3-0.3-1.7,0c-6.5,4.1-13,8.1-19.5,12.2
							C482.9,1222,485.1,1221.7,485.4,1221.5L485.4,1221.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M503.8,1236.9c0.8,0,2.3-1.2,0.8-1.2C503.8,1235.6,502.3,1236.9,503.8,1236.9L503.8,1236.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M520.9,1237c1,0,2.2-1.5,0.8-1.5C520.7,1235.5,519.4,1237,520.9,1237L520.9,1237z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M525,1246.8c8-5,16.1-10.1,24.1-15.1c0.9-0.6-1.3-0.3-1.7-0.1c-8,5-16.1,10.1-24.1,15.1
							C522.4,1247.3,524.6,1247,525,1246.8L525,1246.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M553.5,1251.5c4.1-1.6,8.1-3.3,12.2-4.9c1.4-0.6,0.1-1.3-0.9-0.9c-4.1,1.6-8.1,3.3-12.2,4.9
							C551.2,1251.2,552.5,1251.9,553.5,1251.5L553.5,1251.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M568,1267.8c5-3.1,10-6.2,14.9-9.3c1.2-0.8-0.7-0.9-1.3-0.5c-5,3.1-10,6.2-14.9,9.3
							C565.5,1268.1,567.4,1268.2,568,1267.8L568,1267.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M590.5,1268.6c6.5-3.3,12.9-6.7,19.4-10c1.3-0.7-0.7-0.8-1.3-0.5c-6.5,3.3-12.9,6.7-19.4,10
							C587.9,1268.8,589.8,1268.9,590.5,1268.6L590.5,1268.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M620.1,1273.4c3.2-3.3,6.4-6.6,9.5-10c0.9-1-1.2-1.2-1.8-0.5c-3.2,3.3-6.4,6.6-9.5,10
							C617.3,1273.8,619.5,1274,620.1,1273.4L620.1,1273.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M639.5,1289.8c4.6-6.7,10.2-12.5,16.8-17.1c0.4-0.3-1.5-0.1-2,0.2c-6.7,4.7-12.4,10.6-17,17.4
							C637.3,1290.3,639.2,1290.3,639.5,1289.8L639.5,1289.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M722.1,1323.8c4-8.5,9.8-15.7,17.1-21.5c0.9-0.7-1.2-0.6-1.7-0.2c-7.5,6-13.4,13.4-17.6,22.1
							C719.8,1324.6,721.8,1324.4,722.1,1323.8L722.1,1323.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M747.2,1316.9c10.6,4.1,21.1,8.1,31.7,12.2c0.6,0.2,2.2-0.7,2.1-0.7c-10.6-4.1-21.1-8.1-31.7-12.2
							C748.6,1315.9,747,1316.8,747.2,1316.9L747.2,1316.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M696.4,1307c14.4-0.1,27.5,6.1,40.7,11.4c12.3,5,24.7,9.6,37.2,13.9c24.2,8.3,50.1,18,76.1,16.9
							c1.1-0.1,1.8-1.4,0.3-1.3c-14.4,0.7-28.4-2.4-42.1-6.3c-12.9-3.7-25.7-7.9-38.4-12.3c-12.9-4.5-25.7-9.5-38.4-14.7
							c-11.1-4.6-22.4-8.9-34.6-8.8C696.3,1305.7,694.9,1307,696.4,1307L696.4,1307z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M887.8,1368.1c3.3-1.6,6.5-3.3,9.8-4.9c1.3-0.6-0.7-0.6-1.3-0.4c-3.3,1.6-6.5,3.3-9.8,4.9
							C885.2,1368.4,887.2,1368.4,887.8,1368.1L887.8,1368.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M70.9,706.6c0.8-1.6,1.7-3.2,2.5-4.9c0.2-0.3-0.8-0.2-0.9-0.2c-0.4,0.1-1.1,0.2-1.3,0.6
							c-0.8,1.6-1.7,3.2-2.5,4.9c-0.2,0.3,0.8,0.2,0.9,0.2C70,707.2,70.7,707,70.9,706.6L70.9,706.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M104.9,700c4.1-2.4,8.1-4.9,12.2-7.3c1.3-0.8-0.6-1.1-1.4-0.7c-4.1,2.4-8.1,4.9-12.2,7.3
							C102.3,700.1,104.2,700.4,104.9,700L104.9,700z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M112.1,707.7c4.8-1.7,9.7-3.4,14.5-5.2c1.5-0.5,0.1-0.7-0.7-0.4c-4.8,1.7-9.7,3.4-14.5,5.2
							C109.9,707.7,111.2,708,112.1,707.7L112.1,707.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M128.5,714.9c10.9-0.6,21.1-3.9,30.2-9.7c1.2-0.8-0.6-1.4-1.4-0.9c-8.7,5.6-18.3,8.6-28.6,9.2
							C127.5,713.5,127,715,128.5,714.9L128.5,714.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M160.3,715.5c11.7-1.6,23.1-4.3,34.3-8.1c1.3-0.4,0.9-1.9-0.4-1.4c-11,3.7-22.2,6.4-33.7,8
							C159.3,714.1,158.8,715.7,160.3,715.5L160.3,715.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M196,719.4c8.4,1.8,16.9-0.1,23.9-5.1c0.4-0.3-1.5-0.1-2,0.2c-5.9,4.3-13,5.6-20.1,4.1
							C197.4,718.5,195.5,719.3,196,719.4L196,719.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M226.4,715.4c3.3-0.8,6.5-1.6,9.8-2.4c1.3-0.3,1-1.4-0.3-1c-3.3,0.8-6.5,1.6-9.8,2.4
							C224.9,714.6,225.1,715.7,226.4,715.4L226.4,715.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M242.5,720c19.5-0.6,38.6-4.3,56.9-11c1.5-0.5,0.1-0.8-0.7-0.5c-17.6,6.4-36,10-54.8,10.6
							C243.5,719.1,241.5,720,242.5,720L242.5,720z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M290,734.2c7.7-2.6,14-8,17.7-15.2c0.2-0.4-1.9-0.2-2.2,0.4c-3.4,6.7-9.1,11.8-16.3,14.3
							C287.8,734.1,289.1,734.5,290,734.2L290,734.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M312.3,744.1c13.8-9.9,27.5-19.7,41.3-29.6c1.2-0.9-0.6-1.5-1.4-0.9c-13.8,9.9-27.5,19.7-41.3,29.6
							C309.7,744,311.4,744.7,312.3,744.1L312.3,744.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M352.9,732.1c0.8,0,1.6,0,2.4,0c0.4,0,1.1-0.3,1.2-0.8c0.1-0.6-0.6-0.6-1-0.6c-0.8,0-1.6,0-2.4,0
							c-0.4,0-1.1,0.3-1.2,0.8C351.8,732,352.5,732.1,352.9,732.1L352.9,732.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M385.7,741.6c3.2-4.1,6.5-8.1,9.7-12.2c0.9-1.1-1.2-1.5-1.8-0.7c-3.2,4.1-6.5,8.1-9.7,12.2
							C383,742,385.1,742.4,385.7,741.6L385.7,741.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M392.9,746c4.9-6.5,9.8-13,14.6-19.5c0.6-0.8-1.6-0.7-2-0.1c-4.9,6.5-9.8,13-14.6,19.5
							C390.3,746.7,392.5,746.6,392.9,746L392.9,746z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M410,749.2c4.8-3.3,9.6-6.7,14.5-10c1.2-0.8-0.6-1.6-1.4-1c-4.8,3.3-9.6,6.7-14.5,10
							C407.3,749,409.1,749.8,410,749.2L410,749.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M653.5,730.9c5.1-5.5,10.3-10.9,15.4-16.4c0.5-0.5-1.6-0.3-2,0.1c-5.1,5.5-10.3,10.9-15.4,16.4
							C651,731.5,653.1,731.3,653.5,730.9L653.5,730.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M675.6,723.9c9-7.3,18-14.5,27-21.8c0.9-0.7-1.2-0.6-1.7-0.2c-9,7.3-18,14.5-27,21.8
							C673,724.5,675.2,724.3,675.6,723.9L675.6,723.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M715,702.9c7.3-5.1,13.8-11.1,19.4-18c0.6-0.8-1.6-0.7-2-0.1c-5.4,6.6-11.7,12.4-18.7,17.3
							C712.4,703,714.2,703.5,715,702.9L715,702.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M764.5,670.1c8.4,2.5,13.5,11.4,11.4,19.9c-0.1,0.5,2,0.2,2.1-0.4c2.2-8.8-3.1-17.9-11.8-20.4
							C765.7,669,763.8,669.9,764.5,670.1L764.5,670.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M786.6,685.8c6-5.1,12-10.3,18-15.4c-0.6,0.1-1.3,0.1-1.9,0.2c2.6,3.5,3.7,7.1,3.6,11.5
							c0,1,1.6,0.5,2,0.1c3.7-3.3,7.4-6.6,11-10c-0.6,0.1-1.3,0.1-1.9,0.2c1.9,2,3.7,3.9,5.6,5.9c0.4,0.4,1.3,0.3,1.7-0.1
							c12.8-9.9,25.7-19.9,38.5-29.8c1.2-0.9-0.6-1.4-1.4-0.8c-12.8,9.9-25.7,19.9-38.5,29.8c0.6,0,1.1,0,1.7-0.1
							c-1.9-2-3.7-3.9-5.6-5.9c-0.5-0.5-1.5-0.2-1.9,0.2c-3.7,3.3-7.4,6.6-11,10c0.7,0,1.3,0.1,2,0.1c0.1-4.5-1.1-8.5-3.8-12.1
							c-0.4-0.6-1.5-0.1-1.9,0.2c-6,5.1-12,10.3-18,15.4C783.8,686.2,786,686.3,786.6,685.8L786.6,685.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M876.1,648.7c0.1,2.2,0.3,4.4,0.4,6.6c0,0.7,1.2,0.5,1.6,0.3c7.2-3.3,27.5,3.9,28.4-9.7
							c0.1-1.1-2.1-0.8-2.2,0.2c-0.5,8.1-10.5,7.2-16.2,6.8c-3.9-0.3-7.4-0.2-11,1.5c0.5,0.1,1,0.2,1.6,0.3
							c-0.1-2.2-0.3-4.4-0.4-6.6C878.2,647.2,876,647.7,876.1,648.7L876.1,648.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M41.9,650.4c-3.8-6.5-5.6-13.5-5.3-21c0-1.1-2-0.5-2.2,0.2c-2.7,9.3-5.5,18.6-8.2,27.9
							c0.7-0.1,1.5-0.3,2.2-0.4c-4.6-16.8-5.4-34.3-2.2-51.4c-0.7,0.1-1.5,0.3-2.2,0.4c1.2,10.7,1.5,21.4,0.8,32.2
							c-0.1,1,2,0.5,2.2-0.2c3.6-12.7,7.1-25.5,10.7-38.2c0.2-0.9-2-0.6-2.2,0.2c-3.6,12.7-7.1,25.5-10.7,38.2
							c0.7-0.1,1.5-0.2,2.2-0.2c0.7-10.9,0.4-21.7-0.8-32.5c-0.1-0.9-2.1-0.3-2.2,0.4c-3.2,17.3-2.5,34.8,2.2,51.8
							c0.2,0.9,2,0.2,2.2-0.4c2.7-9.3,5.5-18.6,8.2-27.9c-0.7,0.1-1.5,0.2-2.2,0.2c-0.3,7.8,1.5,15,5.5,21.7
							C40.3,652,42.4,651.2,41.9,650.4L41.9,650.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M29.5,626.8c1,12.4,4.2,24.4,9.5,35.7c0.2,0.5,2.1,0,2.2-0.6c0.2-4.6,0.4-9.2,0.6-13.7
							c0-0.3-2.1-0.1-2.2,0.6c-0.2,4.6-0.4,9.2-0.6,13.7c0.7-0.2,1.4-0.4,2.2-0.6c-5.3-11.3-8.5-23.2-9.5-35.7
							C31.7,625.8,29.5,626.2,29.5,626.8L29.5,626.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M44.3,645.7c-0.1-6.9,1.5-13.4,4.8-19.4c0.2-0.3-1.8-0.2-2.1,0.4c-3.4,6.1-5,12.7-4.9,19.6
							C42.2,646.7,44.3,646.4,44.3,645.7L44.3,645.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M29,653.2c-2.9-11.7-0.8-23.6,6-33.6c0.7-1-1.6-1-2.1-0.2c-7,10.1-9.1,22.5-6.1,34.4
							C27.1,654.8,29.2,654.2,29,653.2L29,653.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M34.4,640.8c1.7-19.3,4.3-38.4,7.7-57.5c-0.7,0.1-1.4,0.2-2.2,0.3c-3.3,21.4-8.6,42.4-15.7,62.9
							c0.7-0.1,1.4-0.3,2.2-0.4c-1.4-4.9-2-9.8-1.7-14.9c0-0.9-2.1-0.4-2.2,0.3c-0.3,5.2,0.2,10.2,1.7,15.2c0.2,0.8,2,0.1,2.2-0.4
							c7.1-20.5,12.3-41.5,15.7-62.9c0.1-0.8-2-0.4-2.2,0.3c-3.4,19-6,38.2-7.7,57.5C32.2,641.9,34.4,641.5,34.4,640.8L34.4,640.8z"
							/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M645.8,201.2c15.8-15.1,31.5-30.2,47.3-45.3c0.5-0.5-1.6-0.3-2,0.1c-15.8,15.1-31.5,30.2-47.3,45.3
							C643.3,201.7,645.4,201.5,645.8,201.2L645.8,201.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M136.8,87.5c5.1-9.2,11.6-17.2,19.6-24.1c1-0.9-1.1-1.1-1.8-0.5c-8,7-14.7,15.2-19.8,24.5
							C134.2,88.3,136.4,88.2,136.8,87.5L136.8,87.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M199.8,67.6c37.4-7.7,75.9-8.5,113.6-2.5c0.6,0.1,2.5-0.9,1.3-1.1c-38.3-6.2-77.3-5.3-115.2,2.6
							C198.2,66.9,198.5,67.9,199.8,67.6L199.8,67.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M87.2,311.3c11.8-10.9,23.6-21.8,35.4-32.7c1-0.9-1.1-1.2-1.8-0.6c-11.8,10.9-23.6,21.8-35.4,32.7
							C84.5,311.6,86.6,311.9,87.2,311.3L87.2,311.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M116.5,302.6c4.7-1.1,8.2-3.7,11.8-6.7c1.2-1,2.4-2.1,3.7-3c2.5-1.8,7.1-4,10.2-3.5
							c4.2,0.7,6.9,5.4,5.4,9.3c-0.3,0.7,1.3,0.4,1.5,0.3c13.9-5,27-11.6,39.3-19.6c-0.6-0.1-1.2-0.1-1.8-0.2
							c-2,9.1,6.9,15.1,15.1,14.4c4.5-0.4,8.7-2.3,12.5-4.7c3.3-2.2,6.6-5.2,10.6-6.2c4.4-1.1,9.2,0.7,10.4,5.2
							c0.2,0.8,2.4,0.2,2.2-0.6c-2-7.2-10.9-7-16.4-4.3c-6.9,3.4-12,9.5-20.3,9.5c-7.2,0-13.6-6.1-12-13.5c0.2-0.8-1.5-0.4-1.8-0.2
							c-12.2,8-25.2,14.4-38.8,19.4c0.5,0.1,1,0.2,1.5,0.3c2.5-6.1-4-10.9-9.7-10.3c-9.7,1-14.7,11.4-24,13.5
							C114.9,301.8,115.2,302.9,116.5,302.6L116.5,302.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M251,291.5c16.4-4.1,32.7-8.2,49.1-12.3c6.1-1.5,21-8.1,24.1,0.4c1.6,4.4-0.8,9.8,3.4,13.1
							c0.5,0.4,2.4-0.5,2-0.8c-5-3.9-0.3-11.8-5.2-15.9c-4-3.4-10.6-1.1-14.9,0c-19.6,4.9-39.2,9.8-58.7,14.8
							C249.5,291,249.7,291.8,251,291.5L251,291.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M338.7,304.7c25.8-10.1,52.6-17.3,80-21.7c1.1-0.2,1.7-1.7,0.2-1.4c-27.8,4.4-55,11.8-81.2,22
							C336.3,304.1,337.6,305.1,338.7,304.7L338.7,304.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M418.9,287.4c26-5.5,52.1-11.1,78.1-16.6c1.3-0.3,1-1.4-0.3-1.1c-26,5.5-52.1,11.1-78.1,16.6
							C417.2,286.6,417.6,287.7,418.9,287.4L418.9,287.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M482.5,300.7c15.4-4,30.1-9.9,44.1-17.5c1.3-0.7,0.1-1.8-0.9-1.2c-13.8,7.5-28.4,13.3-43.5,17.3
							C480.8,299.6,481.2,301,482.5,300.7L482.5,300.7z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M512.3,309.9c23.2-13.6,47.6-25,72.9-34c1.4-0.5,0.1-1.4-0.9-1.1c-25.5,9.1-50,20.6-73.4,34.3
							C509.6,309.8,511.5,310.3,512.3,309.9L512.3,309.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M578.6,302.9c13.6-8.5,27.2-16.9,40.9-25.4c0.9-0.6-1.2-0.5-1.7-0.2c-13.6,8.5-27.2,16.9-40.9,25.4
							C576,303.3,578.2,303.2,578.6,302.9L578.6,302.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M636.8,302.8c16.9-11.2,34.8-21,53.3-29.2c1.4-0.6,0.1-1.7-0.9-1.2c-18.7,8.3-36.7,18.2-53.8,29.5
							C634.1,302.6,635.9,303.3,636.8,302.8L636.8,302.8z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M678,295.2c4.1-3.2,8.1-6.5,12.2-9.7c1.2-0.9-0.6-1.8-1.5-1.1c-4.1,3.2-8.1,6.5-12.2,9.7
							C675.4,295,677.1,295.9,678,295.2L678,295.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M687.3,299.5c10.8-5.3,21.6-10.5,32.3-15.8c1.4-0.7,0.1-1.4-0.9-1c-10.8,5.3-21.6,10.5-32.3,15.8
							C685,299.2,686.3,300,687.3,299.5L687.3,299.5z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M726.8,292.6c2.4-1.6,4.9-3.3,7.3-4.9c1.2-0.8-0.7-1.1-1.3-0.6c-2.4,1.6-4.9,3.3-7.3,4.9
							C724.2,292.7,726.1,293,726.8,292.6L726.8,292.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M881.2,296.9c-1.3-10.4-4.7-20.2-10.1-29.2c-0.4-0.7-2.5,0.1-2.2,0.6c5.4,9,8.8,18.8,10.1,29.2
							C879.1,298.1,881.3,297.6,881.2,296.9L881.2,296.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M854.3,282.2c-0.8-2.4-1.7-4.9-2.5-7.3c-0.2-0.4-2.3,0.2-2.2,0.6c0.8,2.4,1.7,4.9,2.5,7.3
							C852.2,283.3,854.4,282.6,854.3,282.2L854.3,282.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M827.3,296.9c-0.8-5-3.4-9.3-7.5-12.3c-0.6-0.4-2.6,0.4-2,0.8c4,2.9,6.6,7.1,7.4,12
							C825.3,298.2,827.5,297.7,827.3,296.9L827.3,296.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#131111" d="M793.7,309c-0.8-3.3-1.6-6.5-2.4-9.8c-0.2-0.8-2.4-0.2-2.2,0.6c0.8,3.3,1.6,6.5,2.4,9.8
							C791.7,310.3,793.9,309.8,793.7,309L793.7,309z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M904.1,265.2c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C894.9,262,899.1,264.4,904.1,265.2z"/>
							<path opacity="0.1091" fill="#131313" d="M879.7,246.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C873.4,245.6,876.3,246.7,879.7,246.2z"/>
							<path opacity="0.1091" fill="#131313" d="M879.1,212.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C874.3,210.1,876.5,211.5,879.1,212.3z"/>
							<path opacity="0.1091" fill="#131313" d="M901,238.9c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C893.7,237.5,897,239,901,238.9z"/>
							<path opacity="0.1091" fill="#131313" d="M896.2,208.1c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C890.1,204.6,892.9,206.7,896.2,208.1z"/>
							<path opacity="0.1091" fill="#131313" d="M862.2,234.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C857,231.9,859.4,233.5,862.2,234.5z"/>
							<path opacity="0.1091" fill="#131313" d="M887.2,267c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C879.2,265,882.9,266.8,887.2,267z"/>
							<path opacity="0.1091" fill="#131313" d="M915.2,235.6c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C907.1,233.6,910.8,235.4,915.2,235.6z"/>
							<path opacity="0.1091" fill="#131313" d="M870.4,222.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C865.2,220.3,867.6,221.9,870.4,222.9z"/>
							<path opacity="0.1091" fill="#131313" d="M886.7,219.4c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C880.2,215.5,883.2,217.8,886.7,219.4z"/>
							<path opacity="0.1091" fill="#131313" d="M913.8,251.7c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C904.7,248.7,908.8,251,913.8,251.7z"/>
							<path opacity="0.1091" fill="#131313" d="M862.1,245.4c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C855.8,241.7,858.7,243.8,862.1,245.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M828.5,250.5c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C819.3,247.4,823.5,249.8,828.5,250.5z"/>
							<path opacity="0.1091" fill="#131313" d="M804.1,231.6c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C797.8,231,800.7,232.1,804.1,231.6z"/>
							<path opacity="0.1091" fill="#131313" d="M803.5,197.7c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C798.6,195.4,800.9,196.9,803.5,197.7z"/>
							<path opacity="0.1091" fill="#131313" d="M825.4,224.2c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C818.1,222.9,821.4,224.4,825.4,224.2z"/>
							<path opacity="0.1091" fill="#131313" d="M820.6,193.4c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C814.5,190,817.3,192,820.6,193.4z"/>
							<path opacity="0.1091" fill="#131313" d="M786.6,219.9c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C781.4,217.3,783.8,218.9,786.6,219.9z"/>
							<path opacity="0.1091" fill="#131313" d="M811.6,252.3c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C803.6,250.4,807.2,252.2,811.6,252.3z"/>
							<path opacity="0.1091" fill="#131313" d="M839.6,221c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C831.5,218.9,835.2,220.8,839.6,221z"/>
							<path opacity="0.1091" fill="#131313" d="M794.8,208.3c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C789.5,205.7,792,207.3,794.8,208.3z"/>
							<path opacity="0.1091" fill="#131313" d="M811.1,204.7c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C804.6,200.9,807.6,203.1,811.1,204.7z"/>
							<path opacity="0.1091" fill="#131313" d="M838.2,237c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C829.1,234,833.2,236.4,838.2,237z"/>
							<path opacity="0.1091" fill="#131313" d="M786.5,230.7c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C780.2,227.1,783.1,229.2,786.5,230.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M864.8,257.5c0.8-1.6,1.7-3.2,2.5-4.8c0.5-2.6-0.3-4.8-2.2-6.6
								c-1.8-1.9-4-2.7-6.6-2.2c-3.6,1.8-6.8,4.2-9.5,7.2c-3,2.7-5.4,5.9-7.2,9.5c-0.7,1.4-1.4,2.9-2.1,4.3
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7C860.8,264.2,863.1,261.1,864.8,257.5z"/>
							<path opacity="0.1091" fill="#131313" d="M841.6,241.4c0.9-1.8,1.9-3.5,2.8-5.3c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-3.9-2.7-6.5-2.4c-3.4,1.6-6.4,3.8-8.9,6.6c-2.8,2.5-5,5.5-6.6,8.9c-0.8,1.6-1.6,3.2-2.4,4.8
								c-0.3,2.5,0.5,4.7,2.4,6.4c1.7,1.9,3.9,2.7,6.4,2.4c3.3-1.5,6.2-3.6,8.7-6.4C838,247.6,840.2,244.7,841.6,241.4z"/>
							<path opacity="0.1091" fill="#131313" d="M847.1,201.1c0-0.8,0.1-1.6,0.1-2.4c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.2,0.1-7.7,1.8-10.4,5.2c-5.3,4.7-10.3,9.8-14.8,15.2c-3.3,2.9-5,6.4-5,10.6c0,0.6,0,1.1-0.1,1.7c0.6,2.9,2.4,4.6,5.2,5.2
								c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C845.4,208.7,847.1,205.2,847.1,201.1z"/>
							<path opacity="0.1091" fill="#131313" d="M864.9,228.2c0-0.8,0.1-1.6,0.1-2.3c-0.7-2.8-2.5-4.5-5.3-5.2
								c-4-0.1-7.2,1.4-9.9,4.6c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,0.6-0.1,1.2-0.1,1.7c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C863.6,235.4,865.1,232.1,864.9,228.2z"/>
							<path opacity="0.1091" fill="#131313" d="M868.2,196.7c-0.1-0.7-0.3-1.3-0.4-2c-0.3-3.2-2-4.9-5.3-5.2
								c-5.2,0.9-9.5,3.5-12.9,7.7c-6.9,6.3-13.5,13-19.7,20.1c-4.2,3.5-6.7,7.9-7.5,13.1c0.1,0.4,0.2,0.8,0.4,1.3
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C864.9,206.2,867.4,201.9,868.2,196.7z"/>
							<path opacity="0.1091" fill="#131313" d="M828.6,227.6c0.8-1.6,1.6-3.2,2.4-4.8c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.7-1.9-3.8-2.7-6.3-2.5c-3.2,1.3-5.9,3.3-8.2,5.9c-2.6,2.3-4.5,5.1-5.9,8.3c-0.7,1.5-1.4,3-2.1,4.6
								c-0.1,2.5,0.7,4.6,2.6,6.3c1.7,1.9,3.8,2.7,6.3,2.6c3.1-1.3,5.8-3.2,8.1-5.8C825.4,233.5,827.3,230.8,828.6,227.6z"/>
							<path opacity="0.1091" fill="#131313" d="M849.1,261.9c0.8-1.6,1.6-3.2,2.4-4.8c1.7-1.9,2-4,0.8-6.2c-1.2-2.2-3-4-5.2-5.2
								c-2.2-1.1-4.3-0.9-6.2,0.8c-2.7,1.9-4.9,4.1-6.8,6.8c-0.8,1.6-1.5,3.1-2.3,4.7c-1.7,1.9-1.9,4-0.8,6.2c1.2,2.2,3,4,5.2,5.2
								c2.2,1.2,4.2,0.9,6.2-0.7C845.1,266.8,847.3,264.5,849.1,261.9z"/>
							<path opacity="0.1091" fill="#131313" d="M879.6,223.8c-0.1-0.7-0.1-1.4-0.2-2.1c-0.4-3-2.2-4.8-5.3-5.2
								c-4.7,0.5-8.6,2.6-11.7,6.5c-6.2,5.5-12,11.4-17.3,17.6c-3.8,3.2-5.9,7.1-6.3,11.8c0.1,0.5,0.1,1,0.2,1.5
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C877.1,232.4,879.2,228.5,879.6,223.8z"/>
							<path opacity="0.1091" fill="#131313" d="M837.8,215.9c0.2-0.9,0.4-1.8,0.5-2.7c0.8-2.7,0.2-5.1-1.8-7.1
								c-2.1-2-4.4-2.5-7.2-1.6c-4.2,2.5-8,5.5-11.3,9.2c-3.6,3.4-6.5,7.2-8.9,11.5c-0.1,0.7-0.3,1.4-0.4,2.1
								c-0.8,2.7-0.2,5.1,1.8,7.1c2,2,4.4,2.6,7.1,1.7c4.2-2.4,8-5.4,11.3-9C832.4,223.9,835.4,220.1,837.8,215.9z"/>
							<path opacity="0.1091" fill="#131313" d="M856.2,210.2c0-0.8,0-1.5,0-2.3c-0.6-2.9-2.3-4.6-5.3-5.2c-4.3,0.2-7.9,2-10.8,5.5
								c-5.6,4.9-10.7,10.2-15.5,15.8c-3.4,2.9-5.2,6.6-5.3,10.9c0,0.5,0,1.1,0,1.6c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C854.3,218.1,856,214.5,856.2,210.2z"/>
							<path opacity="0.1091" fill="#131313" d="M876.2,239.5c0-0.8,0.1-1.6,0.1-2.3c-0.7-2.8-2.5-4.5-5.3-5.2
								c-4-0.1-7.3,1.4-9.9,4.6c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,0.6-0.1,1.2-0.1,1.7c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C874.9,246.7,876.4,243.4,876.2,239.5z"/>
							<path opacity="0.1091" fill="#131313" d="M830,242.9c0.8-1.6,1.6-3.2,2.4-4.8c1.7-1.9,2-4,0.9-6.3c-1.2-2.3-3-4-5.2-5.2
								c-2.3-1.1-4.4-0.8-6.3,0.9c-2.7,1.9-5,4.2-6.9,6.9c-0.8,1.6-1.5,3.1-2.3,4.7c-1.7,1.9-2,4-0.8,6.2c1.2,2.2,3,4,5.2,5.2
								c2.2,1.1,4.3,0.9,6.2-0.8C825.9,247.8,828.1,245.5,830,242.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M891.1,191.2c1.5-4.1,2.8-8.3,3.9-12.6c1.1-4.1,2.2-8.3,3.2-12.4
								c-0.3-2.7-1.5-4.8-3.8-6.3c-3.1-1.4-6.1-1.1-9,0.9c-4.8,2.2-9.3,4.9-13.5,8.1c-3.1,1.6-4.8,4.1-5,7.5
								c-0.9,3.5-1.8,7-2.6,10.4c0.3-1.2,0.6-2.4,1-3.6c-1,3.4-2.1,6.8-3.1,10.2c-1.3,2.4-1.3,4.8,0.1,7.2c1.3,2.4,3.4,3.6,6.1,3.7
								c4.3-1,8.4-2.7,12.2-5.1C884.6,197.2,888.1,194.5,891.1,191.2z"/>
							<path opacity="0.1091" fill="#131313" d="M868.1,174.7c4.1-7,6.7-14.5,7.9-22.4c0.9-2.4,0.7-4.7-0.8-6.9
								c-1.3-2.3-3.2-3.6-5.7-3.9c-3.7,0.7-7.1,2.1-10.2,4.2c-3.3,1.8-6.2,4.1-8.5,7.1c-1.1,7.6-3.4,14.7-7,21.5
								c-1.8,4.3,1.6,10.3,6.3,10.8c3.5-0.6,6.8-1.8,9.8-3.8C863.1,179.7,865.8,177.5,868.1,174.7z"/>
							<path opacity="0.1091" fill="#131313" d="M873.5,136.1c1.5-7.1,3.3-14,5.3-21c-0.1-2.8-1.4-4.9-3.8-6.3
								c-3.5-1.2-6.9-0.6-10.1,1.6c-5.5,2.7-10.7,5.8-15.6,9.5c-3.5,1.8-5.6,4.5-6.1,8.2c-1.9,6.7-3.6,13.4-5.2,20.2
								c0.1,2.8,1.3,4.9,3.7,6.4c3.5,1.3,6.8,0.8,10.1-1.4c5.5-2.6,10.7-5.6,15.6-9.1C870.8,142.5,872.9,139.8,873.5,136.1z"/>
							<path opacity="0.1091" fill="#131313" d="M891.1,163.1c1.7-7.1,3.5-14.1,5.5-21.1c0-2.9-1.3-5-3.8-6.3
								c-3.8-1.1-7.4-0.4-10.8,2c-6,2.9-11.6,6.4-17,10.2c-3.7,1.8-6,4.7-6.9,8.6c-1.9,6.7-3.7,13.5-5.3,20.3c0,2.9,1.2,5,3.7,6.4
								c3.8,1.2,7.3,0.6,10.7-1.8c5.9-2.8,11.6-6.1,17-9.9C887.9,169.8,890.2,167,891.1,163.1z"/>
							<path opacity="0.1091" fill="#131313" d="M893.6,133.2c1.7-7.1,3.6-14.2,5.6-21.3c0.4-3.1-0.9-5.2-3.8-6.3
								c-4.8-0.6-9.3,0.6-13.3,3.6c-7.6,3.9-14.9,8.3-21.8,13.2c-4.6,2.2-7.7,5.6-9.4,10.1c-2.1,6.6-3.8,13.4-5.3,20.2
								c-0.4,3.1,0.8,5.2,3.7,6.4c4.8,0.7,9.2-0.4,13.2-3.3c7.5-3.8,14.8-8,21.8-12.7C888.8,141,891.9,137.7,893.6,133.2z"/>
							<path opacity="0.1091" fill="#131313" d="M855.1,161.5c3.6-7.3,6.2-15,7.8-22.9c1.1-4.1-1.7-10.1-6.3-10.8
								c-3.1,0.3-6,1.4-8.6,3.1c-2.8,1.4-5.1,3.4-6.9,6c-1.5,7.7-3.8,15.2-6.9,22.4c-1.4,3.9,2.2,10.1,6.3,10.8
								c3-0.2,5.7-1.2,8.2-2.8C851.3,165.9,853.4,164,855.1,161.5z"/>
							<path opacity="0.1091" fill="#131313" d="M876.5,193.3c3.5-7.3,6.1-14.9,7.7-22.9c0.7-2.4,0.4-4.6-1-6.7
								c-1.1-2.2-2.9-3.6-5.3-4.2c-3.2,0.4-6.2,1.4-8.9,3.2c-2.9,1.5-5.3,3.5-7.3,6.1c-1.5,7.7-3.8,15.2-6.9,22.4
								c-1.5,4,2,10.2,6.3,10.8c3.1-0.3,5.9-1.3,8.5-3C872.4,197.8,874.7,195.9,876.5,193.3z"/>
							<path opacity="0.1091" fill="#131313" d="M906.2,158.8c1.7-7.1,3.5-14.2,5.5-21.2c0.4-3.1-0.9-5.2-3.8-6.3
								c-4.8-0.6-9.3,0.6-13.4,3.6c-7.6,3.9-14.9,8.3-22,13.2c-4.6,2.2-7.7,5.6-9.4,10.1c-2.1,6.6-3.8,13.3-5.3,20.1
								c-0.4,3.1,0.8,5.2,3.7,6.4c4.8,0.7,9.2-0.4,13.3-3.3c7.6-3.8,14.9-8,21.9-12.7C901.4,166.7,904.5,163.4,906.2,158.8z"/>
							<path opacity="0.1091" fill="#131313" d="M861.8,151.4c3.6-7,6-14.4,7.4-22.1c1.2-2.4,1-4.8-0.4-7.1
								c-1.4-2.4-3.4-3.6-6.1-3.6c-4.1,1-8,2.7-11.5,5.1c-3.8,2-7,4.7-9.8,7.9c-1.3,7.4-3.5,14.5-6.8,21.3c-1.2,2.4-1,4.7,0.4,7
								c1.3,2.4,3.3,3.6,5.9,3.8c4-0.8,7.8-2.4,11.3-4.7C855.9,157,859.1,154.5,861.8,151.4z"/>
							<path opacity="0.1091" fill="#131313" d="M882.2,145.4c1.7-7.1,3.5-14.1,5.5-21.1c0-2.9-1.2-5-3.8-6.3c-3.9-1-7.6-0.3-11,2.2
								c-6.1,3-11.9,6.5-17.4,10.5c-3.8,1.9-6.2,4.8-7.1,8.7c-1.9,6.7-3.7,13.5-5.3,20.3c-0.1,2.9,1.2,5,3.7,6.4
								c3.8,1.1,7.5,0.5,10.9-1.9c6.1-2.9,11.8-6.3,17.3-10.1C878.9,152.1,881.3,149.3,882.2,145.4z"/>
							<path opacity="0.1091" fill="#131313" d="M902.4,174.5c1.8-7.1,3.7-14.2,5.6-21.2c0.1-2.9-1.1-5-3.8-6.3
								c-4.1-1-7.9-0.2-11.5,2.5c-6.4,3.2-12.5,6.9-18.4,11c-4,1.9-6.5,4.9-7.6,9c-1.9,6.7-3.7,13.5-5.4,20.3
								c-0.1,2.9,1.1,5,3.7,6.4c4,1.1,7.8,0.3,11.4-2.2c6.4-3.1,12.5-6.6,18.3-10.6C898.8,181.5,901.4,178.5,902.4,174.5z"/>
							<path opacity="0.1091" fill="#131313" d="M856.2,174.9c3.7-7.3,6.3-15,8-23c0.4-2.3,0-4.5-1.3-6.5c-1.1-2.1-2.7-3.6-5-4.4
								c-2.8,0.1-5.3,1-7.7,2.5c-2.5,1.3-4.5,3.1-6,5.4c-1.5,7.7-3.8,15.2-6.9,22.4c-0.3,2.3,0.2,4.4,1.5,6.4c1,2.1,2.7,3.6,4.8,4.4
								c2.6,0,5-0.8,7.1-2.2C853,178.8,854.9,177.1,856.2,174.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M921.3,184.1c6.8-11.4,13.8-22.6,20.9-33.8c0.8-2.7,0.2-5.1-1.7-7.2
								c-3-2.2-6.4-2.7-10.2-1.5c-6.1,0.9-12,2.4-17.8,4.3c-3.9,0.6-6.7,2.6-8.4,6c-6.9,11-13.9,21.9-20.6,33.1
								c-0.8,2.7-0.3,5.1,1.6,7.2c3,2.3,6.3,2.8,10.1,1.7c6-0.8,12-2.1,17.7-4C916.8,189.3,919.6,187.4,921.3,184.1z"/>
							<path opacity="0.1091" fill="#131313" d="M893.4,171c6.6-4.8,11.7-10.8,15.4-17.8c3.4-5.7,6.9-11.4,10.5-17
								c1.6-2,2.1-4.3,1.4-6.8c-0.5-2.6-2-4.4-4.3-5.4c-3.8-0.4-7.5-0.1-11.2,1c-3.8,0.7-7.2,2.1-10.4,4.2
								c-3.6,5.5-7.1,11.2-10.4,16.9c-3.4,6.7-8.1,12.4-14.1,17.2c-3.2,3.5-1.4,10.4,2.8,12.2c3.5,0.5,7,0.3,10.5-0.7
								C887.2,174.3,890.4,173,893.4,171z"/>
							<path opacity="0.1091" fill="#131313" d="M899.2,136.3c4.2-5.4,8-11,11.5-16.9c3.6-5.7,7.1-11.4,10.7-17.1
								c1.9-1.9,2.6-4.2,1.9-6.9c-0.6-2.7-2.3-4.4-4.9-5.2c-4.4-0.2-8.8,0.3-13.1,1.5c-4.4,0.9-8.5,2.5-12.3,4.8
								c-3.6,5.7-7.1,11.4-10.7,17.1c-3.4,5.6-7,11.1-10.9,16.4c-1.9,1.9-2.6,4.2-1.9,6.9c0.6,2.7,2.1,4.5,4.7,5.3
								c4.3,0.3,8.6-0.1,12.8-1.3C891.4,140,895.4,138.5,899.2,136.3z"/>
							<path opacity="0.1091" fill="#131313" d="M919.6,159.8c7-11.3,14.1-22.6,21.2-33.9c0.9-2.7,0.3-5.1-1.8-7.2
								c-3.4-2.2-7.1-2.6-11.1-1.2c-6.7,1.1-13.2,2.7-19.6,4.8c-4.2,0.6-7.3,2.7-9.3,6.2c-6.9,11-13.9,22-20.7,33.1
								c-0.9,2.7-0.4,5.1,1.6,7.2c3.3,2.2,7,2.7,10.9,1.4c6.6-0.9,13.1-2.4,19.4-4.4C914.5,165.2,917.6,163.2,919.6,159.8z"/>
							<path opacity="0.1091" fill="#131313" d="M921.3,130.7c7.1-11.3,14.2-22.6,21.4-33.9c1-2.8,0.5-5.2-1.8-7.2
								c-3.8-2.1-7.9-2.4-12.2-0.9c-7.4,1.3-14.6,3-21.7,5.4c-4.5,0.7-8,2.8-10.4,6.5c-6.9,11-14,22-20.8,33
								c-1.1,2.8-0.5,5.2,1.7,7.2c3.7,2.2,7.7,2.6,12,1.2c7.3-1.1,14.5-2.8,21.5-4.9C915.4,136.3,918.9,134.2,921.3,130.7z"/>
							<path opacity="0.1091" fill="#131313" d="M881.8,158.1c5-5.5,9.4-11.4,13.2-17.7c3.7-5.8,7.4-11.5,11.1-17.2
								c1.1-2.1,1.4-4.3,0.7-6.6c-0.4-2.4-1.5-4.3-3.5-5.6c-2.7-0.7-5.5-0.6-8.2,0.2c-2.8,0.5-5.3,1.6-7.4,3.4
								c-3.7,5.7-7.3,11.4-10.9,17.2c-3.6,6.2-7.6,12-12.2,17.5c-1,2.1-1.2,4.3-0.5,6.5c0.4,2.3,1.5,4.2,3.3,5.7
								c2.5,0.7,5,0.7,7.6,0C877.6,160.9,879.9,159.8,881.8,158.1z"/>
							<path opacity="0.1091" fill="#131313" d="M902.8,190.1c5.3-5.1,9.8-10.9,13.2-17.3c3.4-5.8,7-11.4,10.6-17.1
								c1.6-2,2-4.3,1.3-6.8c-0.5-2.5-1.9-4.4-4.3-5.4c-3.7-0.4-7.4-0.1-11,0.9c-3.7,0.7-7.1,2.1-10.2,4.2
								c-3.6,5.6-7.1,11.3-10.5,17c-3.3,6.1-7.4,11.7-12.3,16.8c-2.9,3.6-1.7,10.3,2.8,12.2c3.5,0.5,7,0.3,10.5-0.7
								C896.6,193.4,899.8,192.1,902.8,190.1z"/>
							<path opacity="0.1091" fill="#131313" d="M935.3,155.8c7-11.4,14.2-22.6,21.3-34c1.3-2.9,0.8-5.2-1.8-7.2
								c-4.6-2-9.3-2.2-14.2-0.5c-8.7,1.5-17.2,3.6-25.6,6.3c-5.1,0.7-9.3,3.1-12.4,7c-6.9,11-14,21.8-20.6,32.9
								c-1.4,2.8-0.8,5.2,1.6,7.2c4.5,2.1,9.1,2.3,14,0.7c8.6-1.4,17-3.3,25.3-5.8C928.1,161.9,932.2,159.6,935.3,155.8z"/>
							<path opacity="0.1091" fill="#131313" d="M889.7,147.1c5.4-5.1,9.8-10.9,13.3-17.3c3.4-5.8,7-11.5,10.6-17.1
								c1.6-2,2-4.3,1.3-6.8c-0.5-2.6-2-4.3-4.3-5.4c-3.7-0.4-7.4-0.1-11,1c-3.7,0.7-7.1,2.1-10.2,4.2c-3.6,5.6-7.1,11.3-10.5,17
								c-3.3,6.1-7.4,11.7-12.3,16.7c-2.9,3.6-1.6,10.3,2.8,12.2c3.5,0.5,7.1,0.3,10.5-0.7C883.4,150.4,886.7,149.1,889.7,147.1z"/>
							<path opacity="0.1091" fill="#131313" d="M911,142c6.8-11.4,13.8-22.6,20.9-33.8c0.8-2.7,0.2-5.1-1.8-7.2
								c-3.1-2.2-6.5-2.7-10.2-1.4c-6.1,0.9-12.1,2.4-17.9,4.4c-3.9,0.6-6.7,2.6-8.4,6c-6.9,11-13.9,21.9-20.6,33.1
								c-0.8,2.7-0.2,5.1,1.6,7.2c3,2.2,6.4,2.8,10.1,1.6c6.1-0.8,12-2.2,17.8-4.1C906.4,147.2,909.2,145.3,911,142z"/>
							<path opacity="0.1091" fill="#131313" d="M931.6,171c7-11.4,14.2-22.6,21.3-33.9c1.1-2.8,0.6-5.2-1.7-7.2
								c-4.1-2.1-8.3-2.4-12.9-0.8c-7.8,1.3-15.5,3.2-23,5.6c-4.7,0.7-8.4,2.9-11,6.6c-6.9,11-14,21.9-20.7,33
								c-1.2,2.8-0.6,5.2,1.6,7.2c4,2.2,8.2,2.5,12.7,1.1c7.7-1.2,15.3-2.9,22.8-5.2C925.3,176.8,929,174.7,931.6,171z"/>
							<path opacity="0.1091" fill="#131313" d="M883.6,171c5.1-5.5,9.5-11.4,13.3-17.7c3.7-5.8,7.4-11.5,11.1-17.2
								c1.1-2.1,1.3-4.3,0.6-6.6c-0.4-2.4-1.5-4.2-3.4-5.6c-2.7-0.7-5.3-0.7-8,0.1c-2.7,0.4-5.2,1.6-7.2,3.3
								c-3.7,5.7-7.3,11.4-10.9,17.2c-3.6,6.2-7.6,12-12.2,17.5c-1,2.1-1.1,4.3-0.5,6.5c0.4,2.3,1.4,4.2,3.2,5.7
								c2.4,0.8,4.9,0.8,7.3,0C879.5,173.8,881.7,172.8,883.6,171z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M952.5,194.7c-1-5.1-2.1-10.1-3.1-15.2c-1.7-5.7-3.3-11.4-4.8-17.2
								c-1.1-3.6-1.9-7.3-2.5-11c-1.2-5.5-2.1-11.1-2.5-16.7c-0.6-4.6-7.4-7.5-11.3-5.5c-2.6,2.3-4.5,5.1-5.9,8.3
								c-1.7,3.1-2.7,6.3-2.9,9.8c0.7,3.8,1.3,7.7,2,11.5c1.1,5.7,2.5,11.3,4.3,16.9c1,3.7,2.2,7.4,3.4,11.1
								c2.2,6.5,4.1,13.2,5.5,19.9c1,2.2,2.7,3.6,5.1,4.4c2.2,1,4.5,1,6.7,0c2.2-2.1,3.7-4.7,4.6-7.6
								C952.2,200.6,952.7,197.7,952.5,194.7z"/>
							<path opacity="0.1091" fill="#131313" d="M930,178c-1-4.9-2-9.7-3-14.6c-2.3-5.3-4-10.7-5.3-16.3c-1.1-3.7-2-7.5-2.6-11.3
								c-1.2-5.6-2-11.2-2.5-16.9c-0.6-5-7.1-7.8-11.3-5.5c-2.8,2.7-4.9,5.9-6.4,9.5c-1.9,3.4-3,7.1-3.5,11c0.6,4,1.3,8.1,1.9,12.1
								c1,6.1,2.5,12,4.3,17.9c0.9,3.9,2.2,7.7,3.7,11.3c2.6,6.1,4.3,12.4,5.3,19c1.1,4.8,7.8,7,11.7,4.3c2.4-2.9,4.2-6.1,5.4-9.7
								C929.2,185.4,930,181.8,930,178z"/>
							<path opacity="0.1091" fill="#131313" d="M932.9,136.5c-3.4-7-6.1-14.3-8.2-21.8c-2.2-7.3-3.8-14.8-5-22.4
								c0.2,1.6,0.5,3.2,0.7,4.8c-0.7-5.3-1.4-10.6-2-16c-1.4-2.7-3.7-3.7-6.6-3.2c-4.2,1.8-7.1,5-8.8,9.5
								c-3.9,6.9-7.4,14.1-10.3,21.5c-2.5,4.1-3.1,8.4-1.9,12.8c0.8,4.2,1.6,8.5,2.5,12.7c1.3,6.3,3,12.4,5,18.5
								c1.2,4,2.5,8,3.9,11.9c1.7,5.5,3.9,10.8,6.6,15.9c1.7,2.6,4,3.4,6.9,2.5c4.1-2.3,6.7-5.9,8-10.6c3.3-7.5,6.2-15.1,8.5-23
								C934.2,145.3,934.5,140.9,932.9,136.5z"/>
							<path opacity="0.1091" fill="#131313" d="M950.6,164.6c-1.5-3.5-3.1-6.9-4.6-10.4c0.5,1.3,0.9,2.5,1.4,3.8
								c-2.7-7.3-5-14.7-6.7-22.3c-1.7-7.4-2.9-14.9-3.7-22.5c0.1-3-1.2-5.1-3.8-6.5c-2.7-1.2-5.2-0.9-7.4,1
								c-3.4,4-6.2,8.5-8.3,13.4c-2.5,4.7-4.3,9.6-5.3,14.8c2.3,12.1,4.9,23.9,8.9,35.5c0.4,1.3,0.9,2.5,1.3,3.8
								c2.4,7.3,5.3,14.4,8.7,21.2c1.8,2.1,4.1,3,6.9,2.6c3.5-1.2,5.8-3.7,6.8-7.4c2.6-5.4,4.7-11,6.2-16.8
								C952.5,171.2,952.4,167.8,950.6,164.6z"/>
							<path opacity="0.1091" fill="#131313" d="M952.7,132c-1.5-3.5-3.1-7.1-4.6-10.6c-2-5.5-3.7-11.1-5-16.8
								c-1.1-3.7-1.9-7.4-2.4-11.2c-1.1-5.6-1.9-11.2-2.2-16.8c-1.3-3-3.5-4.1-6.6-3.2c-4.7,2.7-8,6.6-10,11.9
								c-4.7,8.5-8.9,17.2-12.5,26.1c-2.9,4.8-3.9,9.9-3.1,15.2c1,4.1,1.9,8.3,2.9,12.4c1.5,6.1,3.3,12.2,5.5,18.2
								c1.3,3.9,2.6,7.8,4.1,11.7c1.8,5.4,4,10.6,6.7,15.6c1.6,2.9,3.9,3.7,6.9,2.6c4.4-3.1,7.3-7.3,8.8-12.7
								c3.8-8.8,7.2-17.8,10-27C953.6,142.4,954.1,137.2,952.7,132z"/>
							<path opacity="0.1091" fill="#131313" d="M916.2,163.9c-1.3-4.2-2.5-8.4-3.8-12.6c-2.2-5.4-3.9-10.9-5.2-16.6
								c-1.1-3.7-1.9-7.5-2.5-11.3c-1.2-5.6-2-11.3-2.4-17c-0.6-5.1-7-8-11.3-5.4c-2.8,2.9-5.1,6.2-6.7,10
								c-1.9,3.6-3.2,7.4-3.7,11.4c0.6,4.2,1.2,8.3,1.7,12.5c1,6.2,2.4,12.3,4.1,18.4c0.9,4,2.1,7.9,3.6,11.7
								c2.1,5.8,4.1,11.7,5.9,17.6c0.6,2.6,2.2,4.3,4.8,5.2c2.5,1,4.8,0.7,7-0.9c2.6-3.3,4.5-6.9,5.8-10.9
								C915.1,172.1,916,168.1,916.2,163.9z"/>
							<path opacity="0.1091" fill="#131313" d="M937.8,194.2c-1.4-4.2-2.7-8.4-4.1-12.6c-2.4-5.2-4.2-10.6-5.4-16.2
								c-1.1-3.7-1.9-7.5-2.5-11.3c-1.2-5.6-1.9-11.2-2.3-17c-1-2.1-2.5-3.6-4.7-4.6c-2-1.2-4.2-1.5-6.5-1c-2.2,1.5-3.8,3.5-4.8,5.9
								c-1.3,2.3-1.9,4.8-1.8,7.4c0.5,3.9,1.1,7.9,1.6,11.8c0.9,5.9,2.2,11.8,3.9,17.5c0.8,3.9,2,7.6,3.6,11.2
								c2.3,5.7,4.4,11.5,6.5,17.3c1.3,1.9,3,3.2,5.2,3.9c2.1,1,4.3,1.1,6.5,0.5c1.9-1.5,3.2-3.5,3.9-5.9
								C938,198.9,938.3,196.6,937.8,194.2z"/>
							<path opacity="0.1091" fill="#131313" d="M964.4,161.3c-3.3-7.1-6-14.4-8.1-22c0.4,1.4,0.8,2.9,1.1,4.3
								c-1.3-5.2-2.7-10.4-4-15.6c0.3,1.4,0.7,2.9,1,4.3c-1.7-7.5-2.9-15-3.7-22.6c-0.2-1.5-0.5-3-0.7-4.5c-0.9-0.9-1.9-1.8-2.8-2.7
								c-1.3-0.2-2.6-0.3-3.9-0.5c-2.9,0.9-4.9,2.7-6.2,5.4c-3.9,5.6-6.9,11.7-9.6,17.9c-1.5,2.8-2.6,5.7-3.3,8.8
								c-1.4,2.6-1.5,5.4-0.4,8.2c0.9,3.9,1.8,7.8,2.8,11.8c1.5,5.9,3.3,11.7,5.3,17.4c1.2,3.8,2.6,7.6,4.1,11.3
								c1.9,5.4,4.1,10.6,6.6,15.7c1.8,2.3,4.1,3.2,6.9,2.6c3.8-1.7,6.2-4.6,7.4-8.8c2.9-6.3,5.4-12.8,7.3-19.5
								C966.1,168.8,966.2,165,964.4,161.3z"/>
							<path opacity="0.1091" fill="#131313" d="M923.5,148.7c-3.4-7-6.1-14.2-8.2-21.7c0.4,1.4,0.8,2.7,1.2,4.1
								c-1.8-5.5-3.1-11.2-4.1-16.9c-1.2-5.6-2.1-11.2-2.6-17c0.1-3-1.1-5.2-3.8-6.6c-2.7-1.2-5.2-0.8-7.5,1.2
								c-3.5,4.3-6.4,8.9-8.6,14c-2.6,4.9-4.4,10-5.6,15.4c1.1,8.4,2.8,16.8,4.9,25c0.7,2.7,1.5,5.4,2.3,8.1
								c0.8,2.7,1.6,5.4,2.4,8.1c2.3,7.5,5.1,14.7,8.5,21.7c1.8,2.2,4.1,3.1,6.9,2.5c3.7-1.5,6.1-4.3,7.2-8.4
								c2.8-6,5.1-12.2,6.9-18.6C925.3,155.9,925.3,152.3,923.5,148.7z"/>
							<path opacity="0.1091" fill="#131313" d="M941.8,146.5c-2.6-5.3-4.7-10.7-6.4-16.3c-1.9-5.5-3.5-11.1-4.7-16.8
								c0.3,1.5,0.6,3.1,0.9,4.6c-1-5.3-2-10.6-3-15.9c0.3,1.5,0.5,3.1,0.8,4.6c-0.5-3.8-1-7.5-1.5-11.3c-0.2-1.5-0.4-3.1-0.6-4.6
								c-0.9-0.9-1.8-1.8-2.8-2.8c-1.3-0.1-2.6-0.3-3.9-0.4c-3,1-5.1,2.9-6.3,5.7c-4,5.9-7.1,12.3-10,18.8c-1.5,2.9-2.7,6-3.5,9.2
								c-1.4,2.7-1.6,5.6-0.5,8.5c0.9,4.1,1.7,8.3,2.6,12.4c1.4,6.1,3.1,12.2,5.1,18.2c1.2,3.9,2.5,7.8,4,11.7
								c1.8,5.4,4,10.7,6.6,15.8c1.7,2.5,4,3.3,6.9,2.6c4-2.1,6.5-5.4,7.7-9.9c3.2-7,5.8-14.2,8-21.6
								C943.3,154.8,943.5,150.7,941.8,146.5z"/>
							<path opacity="0.1091" fill="#131313" d="M963.2,179.9c-3.5-9.2-7-18.3-9.6-27.8c-1.1-3.7-2-7.4-2.6-11.2
								c-1.2-5.5-2.1-11.2-2.6-16.8c-0.1-2.8-1.4-4.9-4-6.1c-2.6-1.2-5-1-7.3,0.6c-3.2,3.6-5.8,7.6-7.7,12
								c-2.3,4.2-3.9,8.7-4.7,13.5c0.8,3.9,1.6,7.7,2.4,11.6c1.3,5.8,2.9,11.6,4.8,17.3c2.9,9.3,6.6,18.3,10.3,27.3
								c0.5,2.7,2,4.5,4.6,5.5c2.6,1,4.9,0.6,7.1-1.1c2.7-3.6,4.8-7.5,6.2-11.8C961.9,188.7,963,184.4,963.2,179.9z"/>
							<path opacity="0.1091" fill="#131313" d="M917.8,177.5c-1.1-5-2.1-10-3.2-15c-1.8-5.5-3.4-11.1-4.8-16.7
								c-1.1-3.7-1.9-7.5-2.5-11.3c-1.2-5.7-2-11.4-2.4-17.1c-0.5-4.5-7.5-7.3-11.3-5.5c-2.5,2.2-4.4,4.8-5.7,7.8
								c-1.6,2.9-2.5,6-2.7,9.3c0.5,4.1,1.1,8.3,1.6,12.4c0.9,6.1,2.3,12.2,4,18.1c0.9,3.8,2,7.6,3.3,11.3c2.2,6.4,4,13,5.4,19.6
								c1.1,4.3,8.2,6.5,11.8,4.3c2.2-2.3,3.8-5,4.8-8.1C917.5,183.8,918,180.7,917.8,177.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M897.3,93.4c-1.6-2.8-3.3-5.5-4.9-8.3c-1.8-1.6-3.8-2.4-6.3-2.2
								c-2.4-0.2-4.5,0.6-6.3,2.2c-1.3,2.6-1.8,5.4-1.7,8.4c-0.2,2.9,0.4,5.7,1.7,8.4c1.6,2.6,3.3,5.1,4.9,7.7
								c1.8,1.6,3.9,2.3,6.3,2.1c2.4,0.2,4.5-0.6,6.3-2.1c1.3-2.5,1.8-5.2,1.7-8.1C899.1,98.6,898.6,95.9,897.3,93.4z"/>
							<path opacity="0.1091" fill="#131313" d="M874.9,77.7c-1.5-2.3-3-4.7-4.5-7c-2.9-4.5-9.7-4.3-12.5,0.2
								c-1.2,4.1-1.7,8.3-1.5,12.6c-0.1,4.3,0.5,8.5,1.8,12.6c1.4,2.1,2.8,4.2,4.2,6.2c2.9,4.3,9.6,4.4,12.5,0
								c1.3-4,1.9-8.1,1.6-12.3C876.8,85.8,876.2,81.7,874.9,77.7z"/>
							<path opacity="0.1091" fill="#131313" d="M875.2,36.6c-1.6-1.5-3.1-2.9-4.7-4.4c-2.5-2-4.9-2-7.4,0.1c-3,4.2-4.2,9-3.7,14.3
								c-0.4,9.2-0.3,18.3,0.3,27.5c-0.4,5.4,0.9,10.1,4,14.2c1.4,1.2,2.7,2.4,4.1,3.6c2.5,2,4.9,2,7.4,0c3.1-4.1,4.3-8.8,3.8-14.1
								c0.5-9.1,0.5-18.1,0-27.2C879.5,45.4,878.3,40.7,875.2,36.6z"/>
							<path opacity="0.1091" fill="#131313" d="M893.5,66c-1.5-1.4-3-2.9-4.6-4.3c-2.5-1.3-4.9-1.2-7.4,0.1c-2.8,2.2-4,5.2-3.7,9
								c-0.5,5.8-0.4,11.5,0.2,17.2c-0.2,3.8,1.1,6.7,3.9,8.9c1.4,1.4,2.7,2.7,4.1,4.1c2.5,1.3,4.9,1.3,7.4,0
								c2.8-2.2,4.1-5.2,3.8-8.9c0.6-5.7,0.6-11.4,0-17.2C897.5,71.2,896.3,68.2,893.5,66z"/>
							<path opacity="0.1091" fill="#131313" d="M894.3,33.6c-1.6-1.4-3.1-2.9-4.7-4.3c-2.5-2.1-4.9-2.1-7.4,0.1
								c-3,4.4-4.3,9.4-3.7,15c-0.5,9.6-0.4,19.2,0.3,28.7c-0.5,5.5,0.9,10.5,4,14.9c1.3,1.2,2.7,2.3,4,3.5c2.5,2.1,4.9,2.1,7.4,0
								c3.1-4.3,4.4-9.2,3.8-14.7c0.6-9.5,0.6-18.9,0-28.4C898.6,42.8,897.3,37.9,894.3,33.6z"/>
							<path opacity="0.1091" fill="#131313" d="M857.8,60.6c-1.5-1.4-3.1-2.8-4.6-4.3c-2.5-1.3-4.9-1.3-7.4,0.1
								c-2.8,2.4-4,5.6-3.7,9.5c-0.5,6.1-0.4,12.1,0.3,18.2c-0.2,3.9,1.1,7,4,9.4c1.4,1.3,2.7,2.6,4.1,4c2.5,1.4,4.9,1.3,7.4,0
								c2.8-2.4,4.1-5.5,3.8-9.4c0.5-6,0.5-12,0-18.1C861.9,66.1,860.7,63,857.8,60.6z"/>
							<path opacity="0.1091" fill="#131313" d="M881.4,96.6c-1.6-2.8-3.3-5.6-4.9-8.4c-1.8-1.4-3.9-2.1-6.3-1.9
								c-2.4-0.2-4.4,0.5-6.3,1.9c-1.3,2.2-1.8,4.7-1.7,7.3c-0.2,2.6,0.4,5,1.7,7.3c1.6,2.5,3.3,5,4.9,7.6c1.8,1.4,3.9,2,6.3,1.8
								c2.3,0.2,4.4-0.5,6.3-1.8c1.3-2.1,1.8-4.4,1.7-6.9C883.2,101,882.7,98.7,881.4,96.6z"/>
							<path opacity="0.1091" fill="#131313" d="M907.4,62.5c-1.5-1.4-3.1-2.8-4.6-4.2c-2.5-1.4-4.9-1.3-7.4,0.1
								c-2.8,2.5-4.1,5.7-3.7,9.6c-0.5,6.2-0.4,12.3,0.2,18.5c-0.3,4,1,7.1,3.9,9.6c1.4,1.3,2.7,2.6,4.1,3.9c2.5,1.4,4.9,1.4,7.4,0
								c2.9-2.4,4.1-5.6,3.8-9.5c0.6-6.1,0.6-12.3,0-18.4C911.5,68.1,910.2,65,907.4,62.5z"/>
							<path opacity="0.1091" fill="#131313" d="M866.4,49.7c-1.5-1.5-3.1-3-4.6-4.5c-2.5-1.7-4.9-1.7-7.4,0.1
								c-2.9,3.4-4.2,7.5-3.7,12.2c-0.4,7.8-0.3,15.6,0.3,23.3c-0.4,4.7,1,8.7,4,12.1c1.4,1.3,2.7,2.6,4.1,3.9
								c2.5,1.7,4.9,1.7,7.4,0c3-3.3,4.2-7.3,3.8-12c0.5-7.7,0.5-15.4,0-23.1C870.7,57,869.4,53,866.4,49.7z"/>
							<path opacity="0.1091" fill="#131313" d="M884.6,47.7c-1.5-1.5-3.1-3-4.6-4.5c-2.5-1.8-4.9-1.8-7.4,0.1
								c-3,3.6-4.2,7.8-3.7,12.6c-0.5,8.1-0.4,16.1,0.3,24.1c-0.4,4.8,0.9,9,4,12.5c1.4,1.3,2.7,2.5,4.1,3.8c2.5,1.8,4.9,1.8,7.4,0
								c3-3.5,4.3-7.6,3.8-12.4c0.6-8,0.6-15.9,0-23.9C888.8,55.3,887.6,51.2,884.6,47.7z"/>
							<path opacity="0.1091" fill="#131313" d="M907.4,82.7c-1.5-3-3-6-4.6-8.9c-1.5-2.1-3.6-3.1-6.3-3c-2.7-0.1-4.7,1-6.2,3.1
								c-1.3,3.8-1.8,7.7-1.5,11.6c-0.2,4,0.4,7.9,1.8,11.6c1.4,2.6,2.9,5.3,4.3,7.9c2.4,4.4,9.9,3.7,12.5,0
								c1.3-3.6,1.9-7.3,1.7-11.2C909.2,90.1,908.7,86.3,907.4,82.7z"/>
							<path opacity="0.1091" fill="#131313" d="M862.3,78.4c-1.5-2.8-3-5.5-4.6-8.3c-2.5-4.6-10-3.7-12.5,0.2
								c-1.2,3.9-1.7,7.8-1.5,11.9c-0.1,4.1,0.5,8,1.8,11.8c1.4,2.5,2.8,4.9,4.3,7.4c2.5,4.4,9.9,3.8,12.5,0
								c1.3-3.7,1.8-7.6,1.6-11.5C864.2,86,863.7,82.1,862.3,78.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M887.1,177.4c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C877.8,174.2,882,176.6,887.1,177.4z"/>
							<path opacity="0.1091" fill="#131313" d="M862.7,158.4c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C856.4,157.8,859.3,158.9,862.7,158.4z"/>
							<path opacity="0.1091" fill="#131313" d="M862,124.5c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C857.2,122.3,859.4,123.7,862,124.5z"/>
							<path opacity="0.1091" fill="#131313" d="M883.9,151.1c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C876.7,149.7,880,151.2,883.9,151.1z"/>
							<path opacity="0.1091" fill="#131313" d="M879.1,120.3c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C873,116.8,875.9,118.8,879.1,120.3z"/>
							<path opacity="0.1091" fill="#131313" d="M845.1,146.7c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C839.9,144.1,842.3,145.7,845.1,146.7z"/>
							<path opacity="0.1091" fill="#131313" d="M870.1,179.2c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C862.2,177.2,865.8,179,870.1,179.2z"/>
							<path opacity="0.1091" fill="#131313" d="M898.1,147.8c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C890.1,145.8,893.7,147.6,898.1,147.8z"/>
							<path opacity="0.1091" fill="#131313" d="M853.3,135.1c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C848.1,132.5,850.5,134.1,853.3,135.1z"/>
							<path opacity="0.1091" fill="#131313" d="M869.6,131.6c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C863.1,127.7,866.1,130,869.6,131.6z"/>
							<path opacity="0.1091" fill="#131313" d="M896.7,163.9c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C887.6,160.9,891.7,163.2,896.7,163.9z"/>
							<path opacity="0.1091" fill="#131313" d="M845,157.6c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C838.7,153.9,841.6,156,845,157.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M872.4,170.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C863.2,166.9,867.4,169.3,872.4,170.1z"/>
							<path opacity="0.1091" fill="#131313" d="M848,151.1c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C841.7,150.5,844.6,151.6,848,151.1z"/>
							<path opacity="0.1091" fill="#131313" d="M847.4,117.2c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C842.5,114.9,844.8,116.4,847.4,117.2z"/>
							<path opacity="0.1091" fill="#131313" d="M869.3,143.7c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C862,142.4,865.3,143.9,869.3,143.7z"/>
							<path opacity="0.1091" fill="#131313" d="M864.5,112.9c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C858.4,109.5,861.2,111.5,864.5,112.9z"/>
							<path opacity="0.1091" fill="#131313" d="M830.5,139.4c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C825.3,136.8,827.7,138.4,830.5,139.4z"/>
							<path opacity="0.1091" fill="#131313" d="M855.5,171.8c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C847.5,169.9,851.2,171.7,855.5,171.8z"/>
							<path opacity="0.1091" fill="#131313" d="M883.5,140.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C875.4,138.4,879.1,140.3,883.5,140.5z"/>
							<path opacity="0.1091" fill="#131313" d="M838.7,127.8c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C833.4,125.2,835.9,126.8,838.7,127.8z"/>
							<path opacity="0.1091" fill="#131313" d="M855,124.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C848.5,120.4,851.5,122.6,855,124.3z"/>
							<path opacity="0.1091" fill="#131313" d="M882.1,156.5c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C873,153.6,877.1,155.9,882.1,156.5z"/>
							<path opacity="0.1091" fill="#131313" d="M830.4,150.2c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C824.1,146.6,827,148.7,830.4,150.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M822.9,152.5c0.9,5.3,1.4,10.7,1.3,16c0.2,5.3,0.1,10.7-0.5,16
								c0.1-1.5,0.2-3.1,0.4-4.6c-0.6,7-1.7,13.9-3.3,20.7c-0.2,1.5-0.4,3-0.6,4.5c0.6,1.1,1.2,2.3,1.9,3.4c1.2,0.5,2.4,1.1,3.6,1.6
								c3,0,5.5-1.2,7.5-3.4c5.4-4.2,10-9.3,14.4-14.5c2.2-2.3,4.1-4.7,5.7-7.5c2.1-2.1,3-4.7,2.8-7.8c1.1-8,2-15.8,2.2-23.9
								c0-3.9,0-7.9,0-11.8c0-7.2-0.6-14.4-1.8-21.5c-1-2.8-2.9-4.3-5.9-4.4c-4.3,0.8-7.7,3.2-10.1,7.2c-4.9,5.7-9.5,11.8-13.5,18.2
								C823.7,144.2,822.4,148.1,822.9,152.5z"/>
							<path opacity="0.1091" fill="#131313" d="M800.6,135.4c0.9,5.3,1.4,10.7,1.2,16.1c0.2,5.4,0,10.8-0.6,16.1
								c0.2-1.6,0.3-3.1,0.5-4.7c-0.6,5.1-1.3,10.1-1.9,15.2c0.2-1.6,0.4-3.1,0.6-4.7c-0.7,3.4-1.4,6.8-2,10.3
								c-0.3,1.6-0.5,3.1-0.8,4.7c0.6,1.2,1.2,2.3,1.8,3.5c1.2,0.5,2.4,1,3.6,1.5c3.2-0.2,5.8-1.4,7.9-3.8
								c5.7-4.6,10.7-10,15.4-15.5c2.4-2.4,4.4-5.1,6.2-8c2.2-2.2,3.3-5,3.2-8.2c0.3-3.8,0.6-7.6,0.9-11.5
								c0.3-5.7,0.4-11.5,0.1-17.2c0-3.8-0.2-7.5-0.5-11.3c-0.2-5.2-0.8-10.4-1.7-15.6c-0.9-2.8-2.9-4.3-5.9-4.4
								c-4.4,0.9-7.8,3.3-10.2,7.3c-5,5.8-9.6,11.9-13.7,18.4C801.5,127,800.2,130.9,800.6,135.4z"/>
							<path opacity="0.1091" fill="#131313" d="M803.6,96.8c-0.3,4.4-0.5,8.9-0.8,13.3c0.6,5.2,0.7,10.3,0.3,15.5
								c0,3.5-0.3,7-0.8,10.4c-0.5,5.1-1.3,10.2-2.6,15.2c-1,5,4.4,9.6,9.2,8.5c3.5-1.8,6.5-4.3,9.1-7.3c2.8-2.8,5.1-6,6.6-9.6
								c0.4-3.5,0.9-7.1,1.3-10.6c0.6-5.4,0.8-10.8,0.7-16.2c0.2-3.5,0-7-0.5-10.5c-0.8-5.8-0.8-11.7-0.2-17.6
								c0.1-4.7-5.6-8.8-10-7.5c-3,1.9-5.5,4.3-7.5,7.2C806.2,90.3,804.5,93.4,803.6,96.8z"/>
							<path opacity="0.1091" fill="#131313" d="M821.7,123.6c0.8,9,1.5,17.9,1,26.9c0,3.6-0.3,7.1-0.9,10.6
								c-0.5,5.2-1.4,10.4-2.7,15.5c-0.7,2.7,0.1,5,2.1,6.9c2.1,1.9,4.4,2.4,7.1,1.6c4-2.4,7.5-5.4,10.6-9c3.3-3.3,6-7.1,8.1-11.2
								c0.5-3.8,1.1-7.5,1.6-11.3c0.7-5.7,1-11.4,1-17.1c0.1-9.1-0.7-18.1-1.6-27.2c0.3-2.8-0.6-5-2.9-6.6c-2.2-1.7-4.6-2-7.2-0.8
								c-3.7,2.8-6.9,6.1-9.5,10C825.6,115.3,823.3,119.3,821.7,123.6z"/>
							<path opacity="0.1091" fill="#131313" d="M822.6,94.6c-0.1,4.4-0.2,8.8-0.4,13.2c0.4,5.5,0.4,10.9-0.2,16.4
								c-0.1,3.6-0.5,7.2-1.2,10.7c-0.6,5.2-1.6,10.4-3,15.5c0.2,2.4,1.2,4.4,3,5.9c1.7,1.8,3.7,2.7,6.1,2.7c2.7-1,5-2.6,6.9-4.9
								c2.1-2,3.6-4.4,4.5-7.2c0.6-3.6,1.3-7.2,1.9-10.8c0.8-5.5,1.3-11,1.5-16.5c0.4-3.7,0.4-7.4,0-11c-0.4-6-0.5-12-0.5-18
								c-0.6-2.2-1.9-4-3.9-5.3c-1.8-1.5-3.8-2.3-6.2-2.2c-2.4,1-4.2,2.6-5.6,4.8C823.9,89.8,822.9,92.1,822.6,94.6z"/>
							<path opacity="0.1091" fill="#131313" d="M785.9,123.4c1.1,7.2,1.5,14.4,1.2,21.6c0.1-1.5,0.1-3,0.2-4.5
								c-0.3,5.1-0.6,10.2-0.9,15.3c0.1-1.5,0.2-3,0.4-4.6c-0.6,7-1.8,13.9-3.4,20.8c-0.3,1.5-0.5,3.1-0.8,4.6
								c0.6,1.2,1.2,2.3,1.8,3.5c1.2,0.5,2.4,1,3.6,1.5c3.1-0.1,5.7-1.3,7.8-3.7c5.6-4.5,10.5-9.8,15.2-15.2c2.3-2.4,4.4-5,6.1-7.8
								c2.2-2.2,3.2-4.9,3.1-8c0.5-9.3,0.8-18.4,0.4-27.7c-0.1-3.7-0.3-7.4-0.7-11c-0.3-5.2-0.9-10.4-1.8-15.5c-1-2.8-3-4.2-5.9-4.4
								c-4.2,0.7-7.4,2.9-9.8,6.7c-4.7,5.4-9.1,11.2-12.9,17.3C786.5,115.4,785.3,119.1,785.9,123.4z"/>
							<path opacity="0.1091" fill="#131313" d="M805.3,157.6c1.2,7.1,1.7,14.2,1.4,21.4c-0.2,7-0.9,14-2.1,20.9
								c0.3-1.7,0.7-3.5,1-5.2c-1.1,5.1-2.2,10.2-3.2,15.3c0.4,3.2,2.2,4.8,5.4,5c5-1,9.1-3.6,12.3-7.8c6.5-6.3,12.6-13,18.3-20
								c4-3.6,6.2-7.9,6.7-13c0.4-4,0.7-8,1.1-11.9c0.4-5.9,0.5-11.9,0.3-17.8c0-3.9-0.1-7.7-0.4-11.6c-0.1-5.3-0.7-10.5-1.8-15.6
								c-0.7-3.1-2.7-4.6-5.9-4.4c-4.9,1.5-8.8,4.5-11.5,9.1c-5.9,7-11.3,14.3-16.3,21.9C807.1,147.9,805.3,152.4,805.3,157.6z"/>
							<path opacity="0.1091" fill="#131313" d="M837.3,117.7c-0.7,5-1.3,10-2,15c0.6,5.2,0.6,10.4,0.2,15.6c0,3.6-0.4,7.1-1,10.6
								c-0.5,5.2-1.5,10.4-2.8,15.5c-1,4.8,4.8,9.4,9.2,8.5c3.3-1.7,6.2-3.9,8.6-6.8c2.7-2.6,4.7-5.6,6.1-9.1
								c0.6-3.8,1.3-7.5,1.9-11.3c0.9-5.7,1.3-11.4,1.3-17.1c0.3-3.6,0.2-7.3-0.2-10.9c-0.7-6.4-0.4-12.8,1-19.2
								c0.4-4.7-5.9-8.7-10.1-7.5c-3,1.9-5.5,4.3-7.5,7.3C839.9,111.2,838.3,114.3,837.3,117.7z"/>
							<path opacity="0.1091" fill="#131313" d="M794.8,111.7c0.5,3.4,0.9,6.9,1.4,10.3c-0.1-1.3-0.2-2.6-0.3-3.9
								c0.5,7.1,0.4,14.3-0.2,21.4c-0.6,7-1.7,13.8-3.3,20.6c-0.9,2.8-0.4,5.3,1.8,7.3c2.2,1.9,4.7,2.3,7.4,1.2
								c4.5-2.9,8.5-6.4,11.9-10.4c3.8-3.8,6.9-8,9.5-12.7c0.9-7.3,1.3-14.5,1.4-21.8c0.1-4.4,0-8.7-0.3-13.1
								c-0.2-2.9-0.4-5.9-0.6-8.8c-0.3-4.6-0.9-9.3-1.8-13.8c-1.2-2.5-3.2-4-5.9-4.4c-3.6,0.1-6.4,1.7-8.4,4.9
								c-3.9,4.3-7.3,8.9-10.3,13.8C794.6,105.1,793.9,108.3,794.8,111.7z"/>
							<path opacity="0.1091" fill="#131313" d="M814.2,104c-0.5,4.7-0.9,9.3-1.4,14c0.6,5.2,0.7,10.4,0.3,15.6
								c0,3.5-0.3,7-0.9,10.5c-0.5,5.2-1.4,10.3-2.6,15.3c-1,5.1,4.3,9.7,9.2,8.5c3.6-2,6.7-4.5,9.4-7.7c3-2.9,5.3-6.2,7-10
								c0.5-3.6,1-7.2,1.4-10.9c0.6-5.5,0.9-11,0.8-16.6c0.2-3.6,0.1-7.2-0.4-10.8c-0.8-6.1-0.7-12.2,0.4-18.3
								c0.3-4.9-5.5-8.9-10-7.5c-3.2,2.1-5.8,4.7-7.9,7.8C817,97.1,815.3,100.4,814.2,104z"/>
							<path opacity="0.1091" fill="#131313" d="M833.3,139c0.4,3.5,0.8,7,1.3,10.5c-0.1-1.3-0.3-2.6-0.4-3.8
								c0.5,7.2,0.4,14.3-0.2,21.4c-0.6,7-1.7,13.9-3.4,20.8c-0.7,2.7,0,5.1,2.1,7c2.1,1.9,4.4,2.4,7.1,1.5c4-2.4,7.6-5.5,10.6-9.1
								c3.4-3.3,6.1-7.1,8.2-11.3c1.2-5.8,2-11.6,2.4-17.4c0.7-5.8,0.9-11.6,0.8-17.5c-0.2-8.5-1.1-16.8-2.1-25.3
								c-1.2-2.5-3.2-3.9-5.9-4.4c-3.5,0.1-6.3,1.6-8.3,4.8c-3.8,4.2-7.2,8.7-10.1,13.6C833,132.5,832.3,135.6,833.3,139z"/>
							<path opacity="0.1091" fill="#131313" d="M786.9,137.5c1.2,7.1,1.6,14.3,1.3,21.6c-0.2,7.1-1,14.2-2.3,21.3
								c0.3-1.7,0.6-3.4,1-5.1c-1.1,5.1-2.2,10.3-3.3,15.4c0.4,3.2,2.2,4.8,5.4,5c5-0.9,9.1-3.5,12.3-7.7
								c6.5-6.2,12.6-12.8,18.3-19.8c3.9-3.5,6.2-7.8,6.7-12.9c0.2-3.8,0.4-7.6,0.6-11.4c0.2-5.7,0.1-11.4-0.2-17.1
								c-0.1-3.7-0.3-7.5-0.6-11.2c-0.2-5.2-0.8-10.3-1.9-15.4c-0.8-3-2.8-4.5-5.9-4.4c-4.7,1.2-8.4,4-11,8.4
								c-5.6,6.5-10.7,13.3-15.3,20.5C788.4,128.3,786.7,132.6,786.9,137.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M782.6,158.7c1.1,3.6,2.3,7,4.2,10.2c2.5,4.4,5.7,8.6,9.3,12.2
								c1.7,1.7,3.5,3.3,5.4,4.9c1.4,1.3,3,2.5,4.6,3.6c3,2.4,6.2,4.5,9.7,6.1c3.3,0.2,5.7-1.3,7.2-4.5c1.9-4.7,3.3-9.6,4.2-14.6
								c1.5-6.1,2.5-12.3,3.2-18.6c1.4-4.8,0.9-9.3-1.4-13.6c-2.6-2.1-5.2-4.3-7.8-6.5c1,0.9,2,1.7,3,2.6c-2.1-2.4-4.2-4.8-5.7-7.6
								c-0.6-1.1-1.2-2.3-1.7-3.5c-0.9-1.8-1.6-3.8-2-5.8c-1.1-2.8-3.1-4.2-6.1-4.1c-4.3,1.1-7.6,3.7-9.8,7.9
								c-4.7,6.1-9,12.5-12.7,19.2C783.1,150.2,782,154.3,782.6,158.7z"/>
							<path opacity="0.1091" fill="#131313" d="M760.4,141.4c0.5,1.7,1,3.3,1.6,5c1.2,2.7,2.7,5.3,4.5,7.7
								c2.6,3.8,5.6,7.2,9.1,10.3c4.5,4,9.3,7.5,14.5,10.5c2.1,2,4.6,2.4,7.3,1.3c3.4-2.3,5.3-5.7,5.7-10c1.8-6.7,3-13.5,3.6-20.5
								c1.1-4.2,0.5-7.9-1.9-11.3c-1.4-2.3-2.9-4.6-4.6-6.7c-1.2-2-2.3-4-3.2-6.1c-2.1-4.6-3.6-9.6-4.7-14.6
								c-1.1-2.8-3.1-4.1-6.1-4.1c-4.3,1.1-7.5,3.6-9.7,7.7c-4.7,6-8.8,12.3-12.6,18.9C760.9,133.1,759.8,137,760.4,141.4z"/>
							<path opacity="0.1091" fill="#131313" d="M763.4,102.6c0,4.7,0.6,9.3,1.9,13.9c1,3.2,2.6,6,4.9,8.4
								c2.9,3.9,6.3,7.3,10.1,10.3c4,3.4,8.3,6.4,12.9,8.9c2.2,1.3,4.6,1.8,7.2,1.6c2.5-0.3,4.2-1.7,4.9-4.3c1.3-3.2,2-6.4,2.2-9.8
								c0.5-2.6-0.4-4.6-2.6-5.9c-1.7-1.3-3.3-2.5-5-3.8c-2.5-2.1-4.8-4.5-6.8-7c-3.2-3.7-5.6-7.7-7.9-12c0.4,1,0.8,2,1.2,3
								c-0.9-4.2-1.4-8.4-1.6-12.7c-0.4-4.5-6-8.5-10.4-7c-2.8,1.9-5.1,4.3-6.9,7.3C765.4,96.2,764.1,99.3,763.4,102.6z"/>
							<path opacity="0.1091" fill="#131313" d="M781.9,133.4c0.7,3.6,2,7.1,3.8,10.3c1.7,2.8,3.6,5.3,5.8,7.7
								c2.2,2.7,4.7,5.2,7.4,7.4c3.7,3.2,7.7,6.1,11.9,8.6c2.2,1.6,4.6,2,7.3,1.2c3.1-1.6,4.8-4.2,5.2-7.9
								c1.4-5.4,2.3-10.8,2.7-16.3c0.8-3.6,0-6.6-2.4-9.1c-2.2-1.7-4.4-3.4-6.5-5.3c-1.7-1.7-3.2-3.5-4.6-5.4
								c-1.5-1.8-2.7-3.7-3.6-5.8c-1.2-2.8-2.1-5.7-2.6-8.6c-1.4-2.4-3.4-3.7-6.1-4.1c-3.4,0.1-6,1.7-7.8,4.8
								c-3.4,4.2-6.4,8.6-8.9,13.4C781.2,127.2,780.7,130.2,781.9,133.4z"/>
							<path opacity="0.1091" fill="#131313" d="M782.3,100.6c0.3,4.4,1.2,8.7,2.6,12.8c1.5,2.8,3.2,5.5,5.3,7.9
								c2.9,3.8,6.2,7.2,9.9,10.2c3.9,3.4,8,6.5,12.5,9c2.3,1.2,4.7,1.7,7.2,1.6c2.3-0.1,3.9-1.2,4.7-3.5c1.2-2.6,1.8-5.4,1.9-8.3
								c0.3-2.4-0.7-4.1-2.8-5.1c-1.8-1.1-3.6-2.2-5.4-3.3c-2.6-1.8-5-3.8-7.1-6c-1.5-1.3-2.9-2.8-4.1-4.4c-2-2.1-3.7-4.4-5.2-6.9
								c0.5,0.9,1,1.7,1.4,2.6c-1.3-3.7-2.2-7.5-2.7-11.4c-0.8-2.2-2.1-3.9-4.2-5c-1.9-1.4-3.9-2.1-6.2-1.9c-2.3,1.1-4,2.7-5.3,4.9
								C783.2,95.8,782.4,98.1,782.3,100.6z"/>
							<path opacity="0.1091" fill="#131313" d="M745.8,129.3c0.7,3.6,2.1,6.9,4.3,10c1.7,2.8,3.6,5.4,5.9,7.7
								c5.6,6.3,12.3,11.2,19.3,15.9c2.2,1.7,4.6,2.1,7.3,1.2c2.3-1,3.7-2.7,4.1-5.2c1.6-3.5,2.5-7.2,2.9-11.1
								c1-4.8,1.3-9.6,0.8-14.4c-3.7-6.3-7.7-12.4-10.2-19.3c-2-5.2-3.3-10.7-4.4-16.2c-1.2-2.7-3.2-4-6.1-4.1
								c-4.1,0.8-7.1,3.1-9.2,6.9c-4.3,5.5-8.2,11.3-11.6,17.4C745.9,121.5,745,125.3,745.8,129.3z"/>
							<path opacity="0.1091" fill="#131313" d="M765.2,163.8c0.9,3.6,2.4,7,4.4,10.2c1.9,3,3.9,5.8,6.2,8.5c3.1,3.7,6.6,7,10.5,9.9
								c4.1,3.2,8.5,5.8,13.2,8c3.5,0.1,5.9-1.5,7.3-4.9c2-5.2,3.5-10.4,4.5-15.9c1.6-6.7,2.8-13.4,3.6-20.2
								c1.5-5.1,1.1-10-1.1-14.7c-1.1-1.9-2.3-3.6-3.8-5.2c-1.1-1.6-2-3.3-2.9-5.1c-1-1.7-1.8-3.5-2.3-5.5c-1.2-2.4-2.1-5-2.6-7.6
								c-0.9-3.1-2.9-4.4-6.1-4.1c-4.9,1.8-8.5,5-11.1,9.8c-5.5,7.3-10.6,14.9-15.2,22.9C766.5,154,765,158.7,765.2,163.8z"/>
							<path opacity="0.1091" fill="#131313" d="M796.7,123.9c-0.3,4.9,0.1,9.7,1.2,14.5c0.7,3.2,2.3,5.8,4.8,8
								c3,3.4,6.3,6.6,9.8,9.6c3.8,3.2,7.7,6,12,8.5c2.3,1.5,4.7,1.9,7.3,1.2c2.9-1.2,4.6-3.5,5-6.9c1.3-4.7,2.1-9.5,2.3-14.4
								c0.7-3.3-0.2-6-2.6-8.1c-2.2-1.1-4.4-2.3-6.6-3.4c1.1,0.6,2.2,1.2,3.3,1.7c-3-1.6-5.7-3.6-8.1-6c-2.3-1.9-4.4-4-6.1-6.4
								c0.4,1.1,0.7,2.1,1.1,3.2c-0.8-4-1.2-8.1-1.2-12.2c-0.2-4.7-6-8.6-10.4-6.9c-3,2.1-5.4,4.7-7.2,7.9
								C799,117.1,797.5,120.3,796.7,123.9z"/>
							<path opacity="0.1091" fill="#131313" d="M754.8,117.6c0.7,3.6,2.1,6.9,4.1,10c1.6,2.9,3.5,5.5,5.6,7.9
								c2.2,2.8,4.6,5.4,7.3,7.7c3.6,3.3,7.5,6.2,11.6,8.9c2.3,1.4,4.7,1.8,7.3,1.2c2.2-0.6,3.5-2,3.9-4.1c1.4-2.8,2.2-5.8,2.5-9
								c0.8-3.9,0.9-7.8,0.2-11.8c-4.6-5.9-9.2-11.5-12.3-18.4c-2.4-5.3-4-10.8-5.2-16.5c-1.3-2.4-3.4-3.8-6.1-4.1
								c-3.5,0.2-6.2,1.9-8,5.1c-3.5,4.3-6.6,8.9-9.3,13.9C754.2,111.2,753.6,114.3,754.8,117.6z"/>
							<path opacity="0.1091" fill="#131313" d="M773.8,109.9c-0.3,4.8,0.2,9.6,1.3,14.3c0.7,3.2,2.3,5.9,4.7,8.2
								c3,3.5,6.2,6.8,9.6,9.9c3.7,3.3,7.7,6.3,11.9,8.9c2.3,1.3,4.7,1.7,7.3,1.2c2.7-0.8,4.3-2.7,4.8-5.7c1.2-3.9,1.9-8,2-12.1
								c0.5-2.9-0.4-5.2-2.8-6.9c-3.4-2.9-6.6-5.8-9.4-9.2c-1.2-1.3-2.2-2.7-3.3-4.2c-1.5-1.9-2.8-4-3.9-6.2
								c0.4,1.1,0.7,2.1,1.1,3.2c-0.7-4.1-1-8.3-1-12.5c-0.2-4.8-5.9-8.6-10.4-7c-3,2.2-5.5,4.8-7.4,8
								C776.2,103,774.6,106.3,773.8,109.9z"/>
							<path opacity="0.1091" fill="#131313" d="M793,145.2c1,3.5,2.4,6.7,4.3,9.8c1.7,2.8,3.6,5.4,5.7,7.8c2.1,2.8,4.6,5.3,7.4,7.5
								c3.7,3.3,7.7,6.2,12,8.7c2.2,1.9,4.6,2.3,7.3,1.2c3.3-2.1,5.1-5.2,5.5-9.3c1.6-6.3,2.7-12.7,3.3-19.2c1-4,0.3-7.5-2.1-10.6
								c-2-1.1-3.9-2.3-5.9-3.4c1.1,0.7,2.2,1.3,3.3,2c-2.3-1.5-4.5-3.1-6.8-4.6c1,0.7,2.1,1.5,3.1,2.2c-2.7-2.4-5.2-4.7-7.2-7.8
								c-0.8-1.1-1.5-2.3-2.1-3.6c-1-1.9-1.8-3.8-2.2-5.9c-1.3-2.4-3.4-3.8-6.1-4.1c-3.6,0.3-6.3,2.1-8.1,5.3
								c-3.6,4.5-6.8,9.3-9.5,14.4C792.5,138.6,791.9,141.8,793,145.2z"/>
							<path opacity="0.1091" fill="#131313" d="M746.9,143.4c0.5,1.6,1.1,3.3,1.6,4.9c1,1.6,1.9,3.3,2.9,4.9
								c2.1,3.3,4.6,6.3,7.4,9.1c2.1,2.1,4.2,4.1,6.6,5.9c4.9,3.8,10,6.8,15.4,9.7c2.4,0.4,4.2-0.2,5.4-2.1c2.6-2.1,4-5,4.1-8.7
								c0.8-3.5,1.5-6.9,2.3-10.4c1.3-6,1.8-12,1.6-18.1c-1.7-4.1-3.6-8.1-5.9-11.9c-1-2.2-2-4.5-2.7-6.8c-0.9-2.2-1.5-4.4-1.9-6.8
								c-1-2.7-1.7-5.5-2.2-8.4c-1-2.9-3-4.3-6.1-4.1c-4.6,1.4-8.1,4.3-10.4,8.7c-5.1,6.6-9.8,13.6-13.9,20.9
								C747.8,134.4,746.5,138.7,746.9,143.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M886.8,148.6c1.8-0.1,3.5-0.1,5.3-0.2c-1.7-0.3-3.4-0.7-5.1-1
								c3.5,0.7,6.9,1.4,10.4,2.1c-1.7-0.4-3.4-0.7-5.2-1.1c1.6,0.5,3.3,1,4.9,1.5c2.3,0.8,4.5,1.9,6.6,3.3c3.5,2.1,6.2,4.6,9.1,7.4
								c-2.1-2.4-4.3-4.9-6.4-7.3c3.1,3.7,6.3,7.3,9.4,11c-2-2.6-3.9-5.2-5.9-7.8c2.8,3.9,5.7,7.8,8.5,11.7
								c-1.8-2.7-3.6-5.4-5.4-8.1c3.1,5.6,6.3,11.1,9.4,16.7c-1.4-2.9-2.7-5.9-4.1-8.8c1.8,4.4,3.7,8.9,5.5,13.3c-1.1-3-2.3-6-3.4-9
								c1.4,4.6,2.9,9.2,4.3,13.7c1.1,1.4,2.2,2.8,3.2,4.1c1.3,0.3,2.6,0.6,3.9,1c1.1-0.7,2.3-1.4,3.4-2.1c1.8-3,2.4-6.3,1.7-9.7
								c-0.2-8.4-1.5-16.7-3-25c-0.5-3.9-1.3-7.6-2.6-11.3c-0.4-3.5-1.9-6.4-4.6-8.8c-3.2-3.5-6.7-6.6-10.5-9.4
								c-1.9-1.3-3.8-2.6-5.7-3.9c-6.6-4.3-14-7.4-21.5-9.7c-6.3-1.9-12.7-3-19.3-3.5c-3.1-0.2-6.2-0.4-9.3-0.4
								c-3.4-0.5-6.7-0.4-10.1,0.3c-3.1,0.6-4.7,2.4-4.8,5.6c1.1,4.9,3.7,8.9,8,11.9c6.4,6.2,13.1,11.9,20.2,17.2
								C877.5,146.4,881.8,148.4,886.8,148.6z"/>
							<path opacity="0.1091" fill="#131313" d="M858.7,130.2c5.1,0.4,10.5,0.4,15.3,2.2c1.5,0.6,3,1.3,4.6,1.9
								c2.8,1.4,5.5,3.1,7.9,5.2c-1.5-1.3-3-2.7-4.6-4c3.7,3.6,7.4,7.2,11,10.8c-1.4-1.6-2.7-3.2-4.1-4.7c2.3,2.9,4.5,5.7,6.8,8.6
								c-1.3-1.7-2.5-3.4-3.8-5.1c2,3.1,4.1,6.2,6.1,9.2c-1.1-1.8-2.3-3.6-3.4-5.5c1.8,3.3,3.6,6.5,5.4,9.8c-1-1.9-2-3.9-3.1-5.8
								c1.5,3.5,3,6.9,4.5,10.4c-0.9-2.1-1.8-4.1-2.6-6.2c1.2,3.6,2.4,7.3,3.7,10.9c1.6,2.6,3.8,3.7,6.8,3.4
								c2.9-0.5,4.8-2.3,5.6-5.2c0.6-5.5,0.3-11-0.6-16.5c-0.6-5.5-1.9-10.9-3.9-16c-3.6-4.6-7.3-8.8-11.6-12.7
								c-5-4.5-10.7-8.1-16.8-11c-5.3-2.6-10.7-4.4-16.5-5.5c-2.8-0.6-5.7-1-8.6-1.1c-3.4,0.2-6.8,0.1-10.3-0.2
								c-2.7-0.5-5,0.3-6.8,2.5c-1.8,2.1-2.3,4.5-1.4,7.1c2.5,3.8,5.5,7.2,9.2,10C850.7,125.9,854.5,128.4,858.7,130.2z"/>
							<path opacity="0.1091" fill="#131313" d="M862,96.5c3.5,1.5,7.2,2.3,10.9,2.4c2.4,0,4.7,0.5,6.9,1.5c-1.1-0.5-2.2-1-3.3-1.5
								c2.6,1.3,5.2,2.5,7.8,3.8c-1.1-0.6-2.2-1.1-3.2-1.7c2.8,1.5,5.4,3.3,7.8,5.4c2.4,2.1,4.5,4.4,6.4,6.9
								c-0.9-1.3-1.7-2.5-2.6-3.8c1.7,2.6,3.5,5.1,5.2,7.7c-0.8-1.3-1.5-2.6-2.3-3.9c1.5,2.7,3,5.4,4.5,8.1c-0.7-1.3-1.3-2.7-2-4
								c1.3,2.8,2.6,5.6,3.8,8.5c-0.6-1.4-1.1-2.8-1.7-4.2c1,2.9,2.1,5.9,3.1,8.8c-0.5-1.5-0.9-2.9-1.4-4.4c0.8,3.1,1.5,6.1,2.3,9.2
								c0.5,2.4,2,3.9,4.5,4.2c2.5,0.4,5,0,7.3-1c2.2-1,3.3-2.8,3.1-5.3c0.1-3.1-0.3-6.2-1.3-9.2c-1-3.8-2.4-7.6-4.2-11.2
								c-1.7-3.6-3.8-7-6.1-10.2c-4.1-5.6-9-10.4-14.7-14.4c-3.4-2.4-7-4.5-10.8-6.3c-3.4-1.7-7.1-2.9-10.9-3.5
								c-4.6,0.3-9.1-0.3-13.4-1.9c-2.3,0.3-4.2,1.5-5.7,3.4c-1.7,1.7-2.5,3.8-2.4,6.1c1.1,2.6,2.7,4.7,5,6.4
								C856.9,94.6,859.2,95.9,862,96.5z"/>
							<path opacity="0.1091" fill="#131313" d="M883.7,122.7c1.8,0,3.5-0.1,5.3-0.1c-1.4-0.1-2.8-0.1-4.2-0.2
								c4.2,0.7,8.2,1.5,12.2,3.1c3.8,1.6,6.9,3.7,10.3,6.2c-1.7-1.6-3.4-3.1-5.1-4.7c2.9,2.6,5.7,5.3,8.6,7.9
								c-1.6-1.6-3.1-3.3-4.7-4.9c2.6,2.8,5.2,5.7,7.8,8.5c-1.4-1.7-2.9-3.4-4.3-5.1c3.2,4.4,6.3,8.8,9.5,13.2
								c-1.2-1.8-2.3-3.7-3.5-5.5c1.8,3.3,3.6,6.6,5.5,10c-1-1.9-2-3.8-3-5.7c1.5,3.4,3,6.9,4.6,10.3c-0.9-2-1.7-3.9-2.6-5.9
								c0.5,1.6,1,3.1,1.5,4.7c0.7,2,1.4,4,2.1,6c1,0.9,2,1.7,3,2.6c1.3,0.2,2.5,0.3,3.8,0.5c2.3-0.2,3.8-1.2,4.6-3.2
								c1.2-1.3,1.7-3,1.3-4.8c0.3-2.5,0.2-5.1-0.2-7.6c-0.1-3-0.6-5.9-1.2-8.8c-0.5-3.6-1.4-7-2.9-10.4c-2.3-3.9-4.9-7.5-7.8-10.9
								c-2.9-3.4-6.1-6.5-9.6-9.4c-5.2-4.2-10.8-7.8-16.9-10.7c-12.3-5.7-26.2-7.6-39.7-6.9c-2.7,0.9-4.3,2.8-4.8,5.6
								c0.2,4,2,7.2,5.3,9.6c4.7,4.7,9.7,9,15.1,12.8C876.4,121.9,879.8,123.1,883.7,122.7z"/>
							<path opacity="0.1091" fill="#131313" d="M879.2,92.7c4.2,2.3,8.7,3.4,13.4,3.4c3.3,0.7,6.5,1.9,9.5,3.5
								c-1.3-0.8-2.6-1.6-4-2.4c2.7,1.7,5.5,3.4,8.2,5c-1.2-0.8-2.5-1.7-3.7-2.5c2.3,1.5,4.4,3.2,6.2,5.1c2,1.8,3.8,3.8,5.3,6.1
								c-1-1.5-2-3-3-4.6c1.9,3,3.9,5.9,5.8,8.9c-0.9-1.5-1.7-3.1-2.6-4.6c1.6,2.9,3.2,5.9,4.8,8.8c-0.7-1.5-1.5-3.1-2.2-4.6
								c1.3,3,2.7,5.9,4,8.9c-0.6-1.5-1.2-3.1-1.8-4.6c1.1,3,2.1,6.1,3.2,9.1c-0.5-1.5-1-3.1-1.4-4.6c0.8,3.1,1.6,6.2,2.4,9.3
								c0.5,2.5,2,3.9,4.5,4.3c2.5,0.4,5,0,7.3-1c2.2-1.1,3.3-2.8,3.1-5.4c0.1-3.2-0.3-6.3-1.3-9.3c-1.1-4-2.5-7.9-4.3-11.7
								c-1.8-3.7-4-7.3-6.4-10.6c-4.1-5.6-8.7-10.6-14.2-14.9c-4.7-3.7-10-7-15.6-9.3c-4-1.6-8.1-2.7-12.3-3.4
								c-4.6,0.2-9.1-0.7-13.4-2.8c-2.6-0.1-4.7,0.9-6.3,2.9c-1.8,1.9-2.4,4.1-1.8,6.6c1.8,3.3,4.3,6,7.3,8.3
								C872.6,89.5,875.7,91.4,879.2,92.7z"/>
							<path opacity="0.1091" fill="#131313" d="M844.7,118.4c3.5,1.4,7.2,2.2,10.9,2.5c2.5,0.1,4.8,0.6,7.1,1.7
								c-1.2-0.6-2.3-1.1-3.5-1.7c2.7,1.4,5.4,2.7,8.1,4.1c-1.2-0.6-2.3-1.3-3.5-1.9c2.4,1.5,4.9,3,7.3,4.5
								c-1.1-0.7-2.3-1.4-3.4-2.1c2.2,1.6,4.4,3.2,6.6,4.8c-1.1-0.8-2.2-1.6-3.3-2.4c3.3,2.9,6.3,5.8,8.7,9.5
								c2.4,3.7,4.1,7.5,5.6,11.5c-0.6-2.2-1.2-4.3-1.8-6.5c1,3.5,1.9,7.1,2.9,10.6c0.6,1.6,1.2,3.1,1.9,4.7c1,0.7,1.9,1.3,2.9,2
								c1.3,0.1,2.5,0.2,3.8,0.3c2.1-0.1,3.7-0.9,4.7-2.6c1.2-1,1.7-2.2,1.4-3.9c0.4-2,0.4-4,0-6c0-2.4-0.3-4.7-0.9-7
								c-0.3-2.9-1.2-5.6-2.5-8.2c-2-3.6-4.4-7-7.1-10.1c-1.6-2-3.3-3.8-5.1-5.6c-3.6-3.5-7.8-6.8-12.2-9.2
								c-1.4-0.7-2.9-1.5-4.3-2.2c-3.3-1.6-6.7-3-10.1-4.2c-1.7-0.3-3.4-0.7-5.1-1c-4.5,0.5-8.9-0.1-13.2-1.7
								c-2.4,0.3-4.3,1.4-5.7,3.4c-1.7,1.7-2.5,3.8-2.4,6.2c1.1,2.6,2.8,4.8,5.1,6.5C839.5,116.4,842,117.7,844.7,118.4z"/>
							<path opacity="0.1091" fill="#131313" d="M869.8,150.5c1.8,0,3.6,0,5.5,0c-1.5-0.1-3-0.3-4.5-0.4c4.3,0.8,8.3,1.6,12.3,3.4
								c3.8,1.7,6.9,3.8,10.2,6.4c-2-1.9-4-3.9-6-5.8c3.1,3.1,6.3,6.1,9.4,9.2c-1.9-2.1-3.8-4.2-5.7-6.3c4.6,6.1,9.1,12.2,13.7,18.4
								c-1.5-2.6-2.9-5.2-4.4-7.8c2.1,4.1,4.2,8.2,6.3,12.3c-1.3-2.7-2.6-5.5-3.9-8.2c1.8,4.3,3.5,8.7,5.3,13
								c-1.1-2.9-2.2-5.8-3.3-8.6c1.4,4.5,2.8,9.1,4.2,13.6c1.1,1.4,2.1,2.7,3.2,4.1c1.3,0.3,2.6,0.6,3.9,0.9c1.1-0.7,2.3-1.4,3.4-2
								c1.8-3,2.4-6.1,1.8-9.5c-0.1-8.2-1.4-16.4-2.9-24.5c-0.5-3.8-1.3-7.5-2.5-11.1c-0.4-3.4-1.9-6.3-4.5-8.6
								c-4.7-4.1-9.4-7.9-14.7-11.1c-5.9-3.6-12.4-6.2-19.1-8.1c-11.6-3.4-23.9-3.8-35.9-3c-2.8,0.8-4.4,2.7-4.8,5.6
								c0.4,4.2,2.3,7.5,5.9,10.1c5,5,10.4,9.6,16.2,13.8C862,149.4,865.6,150.8,869.8,150.5z"/>
							<path opacity="0.1091" fill="#131313" d="M898.1,119.5c1.8,0,3.5-0.1,5.3-0.1c-1.6-0.2-3.1-0.4-4.7-0.6
								c5,0.8,9.9,1.8,14.4,4.1c4.3,2.2,7.8,5,11.3,8.3c-1.7-1.8-3.4-3.6-5.1-5.4c2.7,3,5.5,6,8.2,8.9c-1.5-1.8-3-3.7-4.6-5.5
								c3.3,4.5,6.5,9.1,9.8,13.6c-1.2-1.9-2.4-3.8-3.6-5.7c1.9,3.4,3.7,6.8,5.6,10.2c-1-1.9-2.1-3.9-3.1-5.8
								c1.5,3.5,3.1,7,4.6,10.5c-0.9-1.9-1.7-3.9-2.6-5.8c0.5,1.6,1.1,3.2,1.6,4.9c0.7,1.9,1.4,3.9,2.1,5.8c1,0.8,2,1.7,3,2.5
								c1.3,0.1,2.5,0.3,3.8,0.4c2.2-0.2,3.8-1.2,4.6-3.1c1.2-1.3,1.7-2.9,1.3-4.7c0.3-2.5,0.2-4.9-0.2-7.4
								c-0.1-2.9-0.5-5.7-1.2-8.5c-0.4-3.5-1.4-6.8-2.9-10c-2.3-4.4-5-8.4-8.2-12.3c-3.1-3.8-6.5-7.3-10.3-10.5
								c-6.5-5.6-13.8-9.9-21.7-13.2c-5.5-2.3-11.1-3.8-16.9-4.9c-3.7-0.7-7.4-1.2-11.2-1.5c-3.5-0.6-7.1-0.7-10.6-0.2
								c-2.9,0.8-4.5,2.6-4.8,5.6c0.6,4.4,2.8,8,6.7,10.7c5.5,5.4,11.4,10.4,17.6,14.9C889.7,118,893.6,119.6,898.1,119.5z"/>
							<path opacity="0.1091" fill="#131313" d="M853.1,107c3.5,1.5,7.2,2.3,11,2.5c2.4,0,4.8,0.6,7,1.6c-1.2-0.6-2.4-1.2-3.5-1.7
								c2.7,1.4,5.4,2.7,8.1,4.1c-1.2-0.6-2.3-1.3-3.5-1.9c2.5,1.5,5,3.1,7.5,4.6c-1.1-0.7-2.2-1.4-3.4-2.1c3.6,2.8,7,5.6,9.8,9.2
								c2.7,3.5,4.8,7.2,6.7,11.2c-0.7-1.8-1.3-3.6-2-5.4c1.2,3.2,2.3,6.4,3.5,9.6c-0.5-1.9-1.1-3.8-1.6-5.7c0.9,3.3,1.8,6.7,2.7,10
								c0.4,2.9,1.9,4.9,4.6,5.7c2.6,0.6,5,0.3,7.3-0.9c2.4-1.5,3.4-3.8,3-6.7c0-4.1-0.5-8.1-1.6-12c-1.4-3.4-2.9-6.7-4.7-9.8
								c-1.7-2.9-3.8-5.7-5.9-8.3c-2.5-2.9-5.2-5.7-8.1-8.2c-3.8-3.3-8-6.2-12.4-8.6c-4.2-2.3-8.6-4.3-13.3-5.3
								c-1.7-0.5-3.4-0.7-5.1-0.5c-3.7,0.2-7.4-0.4-10.9-1.9c-2.4,0.3-4.3,1.4-5.8,3.3c-1.7,1.8-2.5,3.8-2.3,6.2
								c1.2,2.7,3,5,5.4,6.8C847.7,104.8,850.2,106.2,853.1,107z"/>
							<path opacity="0.1091" fill="#131313" d="M869.5,103.8c2.8,0.9,5.7,1.8,8.5,2.6c2.3-0.2,4.5,0.1,6.6,0.9
								c4.2,1.1,7.6,3,11.3,5.2c-1.3-0.9-2.7-1.9-4-2.8c2.6,1.9,5.2,3.9,7.8,5.8c-1.3-1-2.5-2-3.8-3c2.4,2.1,4.8,4.3,7.2,6.4
								c-1.2-1-2.3-2.1-3.5-3.1c2.3,2.3,4.4,4.8,6.3,7.5c1.8,2.7,3.4,5.5,4.6,8.5c-0.7-1.9-1.4-3.9-2.2-5.8
								c1.3,3.5,2.5,6.9,3.8,10.4c-0.6-2-1.1-4-1.7-5.9c1,3.5,1.9,7.1,2.9,10.6c0.4,3,1.9,4.9,4.6,5.9c2.6,0.6,5,0.3,7.3-1
								c2.4-1.6,3.4-3.9,3-6.8c0-4.1-0.5-8.2-1.6-12.2c-1.4-3.9-3.2-7.6-5.3-11.2c-1.1-1.8-2.2-3.5-3.4-5.2
								c-3.9-5.6-8.6-10.5-13.9-14.8c-5.8-4.7-12.3-8.6-19.4-11.1c-3.9-1.4-7.9-2.3-12-2.8c-4.3,0.4-8.5-0.4-12.6-2.2
								c-2.6-0.2-4.7,0.7-6.4,2.8c-1.8,1.9-2.4,4.2-1.7,6.7c2,3.4,4.5,6.3,7.6,8.6C862.6,100.4,865.8,102.4,869.5,103.8z"/>
							<path opacity="0.1091" fill="#131313" d="M896.7,135.4c1.7-0.1,3.5-0.1,5.2-0.2c-1.7-0.3-3.4-0.6-5.1-1
								c3.4,0.7,6.8,1.4,10.3,2c-1.7-0.3-3.4-0.7-5.1-1c4,1.2,7.7,2.5,11.3,4.5c3.4,2,6.1,4.4,9,7.1c-2-2.2-4-4.5-6-6.7
								c3,3.4,6,6.8,9,10.2c-1.8-2.3-3.6-4.6-5.4-7c2.7,3.6,5.4,7.2,8.1,10.7c-1.6-2.4-3.2-4.8-4.9-7.2c3,5.2,6.1,10.3,9.1,15.5
								c-1.2-2.5-2.4-5-3.7-7.5c1.7,4,3.4,8,5.2,12c-1-2.5-2-5-3-7.6c1.4,4.1,2.7,8.2,4.1,12.3c1.4,2.9,3.8,4.3,6.9,4.1
								c3.1-0.7,4.9-2.7,5.4-5.9c0.4-6.4,0-12.8-1.2-19.2c-0.7-6.4-2.2-12.6-4.5-18.7c-2.8-4.1-5.9-7.9-9.3-11.4
								c-3.4-3.5-7.1-6.6-11.1-9.4c-4.6-3.3-9.5-5.9-14.6-8.3c-7.2-3.4-14.8-5.7-22.6-7c-4.4-0.6-8.8-1-13.2-1.2
								c-3.5-0.6-6.9-0.6-10.3,0.1c-3.1,0.6-4.7,2.4-4.8,5.6c1.1,4.9,3.7,8.9,8,11.8c6.4,6.2,13.1,11.9,20.2,17.2
								C887.3,133.1,891.7,135.1,896.7,135.4z"/>
							<path opacity="0.1091" fill="#131313" d="M846,130.8c2.8,0.8,5.6,1.6,8.4,2.4c2.1-0.3,4.2,0,6.2,0.7c3.9,1,7.2,2.7,10.7,4.8
								c-1.4-1-2.7-1.9-4.1-2.9c2.6,1.9,5.1,3.8,7.7,5.7c-1.3-1.1-2.6-2.2-4-3.3c2.4,2.1,4.8,4.3,7.2,6.4c-1.3-1.2-2.5-2.4-3.8-3.6
								c2.2,2.4,4.5,4.7,6.7,7.1c-1.2-1.3-2.4-2.7-3.6-4c2.1,2.6,4.1,5.2,6.2,7.8c-1.1-1.5-2.3-2.9-3.4-4.4c1.9,2.8,3.8,5.7,5.6,8.5
								c-1.1-1.6-2.1-3.2-3.2-4.8c2.2,4.1,3.8,7.7,5.1,12.2c0.7,2,1.4,4.1,2.1,6.1c1,0.9,2,1.8,3,2.7c1.3,0.2,2.5,0.3,3.8,0.5
								c2.3-0.2,3.8-1.3,4.6-3.2c1.3-1.4,1.7-3,1.3-4.9c0.3-2.6,0.2-5.2-0.2-7.7c-0.1-3-0.5-6-1.2-9c-0.4-3.7-1.4-7.2-2.9-10.6
								c-3.2-4-6.5-7.8-10.3-11.2c-4.5-4.1-9.5-7.5-14.9-10.3c-4.8-2.5-9.7-4.4-15-5.5c-2.6-0.6-5.2-1-7.8-1
								c-4,0.9-7.9,0.4-11.7-1.5c-2.5,0-4.6,1-6.2,3c-1.8,1.9-2.4,4.1-2,6.5c1.7,3.1,4,5.8,6.8,8C839.7,127.8,842.7,129.6,846,130.8
								z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M923.6,177.1c1.4-3.1,2.3-6.3,2.7-9.6c0.6-3,0.6-6,0-9c-0.5-8.6-1.7-17.2-4.3-25.5
								c-2.8-8.8-6.5-17.5-11.4-25.4c-1.8-2.7-3.5-5.4-5.3-8c-4-5.5-8.4-10.6-13.2-15.3c-1.9-1.7-4.2-2.7-6.8-2.8
								c-2.7,0-4.7,1.4-5.8,4c-1.9,3.3-3.4,6.7-4.3,10.4c-1,2.6-0.6,4.9,1.3,6.9c1.8,2.4,3.7,4.9,5.5,7.3c2.5,3.6,4.7,7.4,6.4,11.4
								c2.9,6.2,5.1,12.7,6.6,19.4c1.1,5.1,2,10.2,2.5,15.4c0.7,7.1,0,14.2-0.3,21.2c0.2-1.2,0.4-2.4,0.6-3.6
								c-0.7,2.3-1.5,4.6-2.2,6.9c-1.1,2.5-0.9,4.8,0.7,7.1c1.5,2.3,3.6,3.4,6.3,3.3c4.1-1.2,7.9-3.1,11.4-5.7
								C917.7,183.2,920.9,180.4,923.6,177.1z"/>
							<path opacity="0.1091" fill="#131313" d="M899,161.6c3.7-3.5,5.6-7.5,5.8-12c0.3-2.9,0.3-5.7-0.2-8.6
								c-0.6-8.4-2.1-17.1-4.8-25.1c-3-8.7-6.8-17.2-11.9-25c-1.8-2.6-3.6-5.2-5.4-7.8c-3.9-5.1-8.2-9.9-12.8-14.4
								c-1.9-1.8-4.2-2.7-6.9-2.6c-3,0.4-5,2.1-6,5c-2.1,3.9-3.6,8-4.7,12.3c-1.2,2.9-0.8,5.5,1.2,7.7c1.7,1.8,3.4,3.7,5.2,5.5
								c2.5,2.9,4.7,5.9,6.7,9.2c3.4,5.3,6.2,11,8.4,17c1.7,4.6,3.1,9.4,4.1,14.2c1.4,6.4,1.6,13.1,1.9,19.6
								c0.1-1.1,0.2-2.2,0.3-3.3c-1.6,3.1-3.2,6.2-4.8,9.3c-0.7,2.4-0.3,4.7,1.2,6.7c1.3,2.2,3.2,3.4,5.8,3.7
								c3.4-0.8,6.6-2.2,9.4-4.3C894.5,166.9,897,164.5,899,161.6z"/>
							<path opacity="0.1091" fill="#131313" d="M905.9,121.8c0.6-3.6,0.9-7.1,0.9-10.7c0.2-4,0-8.1-0.6-12.1
								c-1.1-7.4-2.9-14.6-5.4-21.6c-2.6-7.2-6.1-14.3-10.3-20.8c-1.8-2.6-3.6-5.3-5.4-7.9c-3.9-5.2-8.2-10.1-12.8-14.6
								c-1.6-2.5-3.9-3.4-6.9-2.7c-4,2.1-6.7,5.4-8,9.9c-3.4,7.1-6.2,14.3-8.5,21.8c-2.1,4.2-2.4,8.5-0.9,12.7
								c4.2,4.4,8.2,8.9,11.4,14.2c2.7,4.5,4.9,9.1,6.9,14c2,4.8,3.7,10,4.3,15.2c0.7,6.1,0.3,11.9-0.4,17.9
								c0.2,2.8,1.6,4.9,4.1,6.1c3.7,1,7.1,0.2,10.2-2.3c5.5-3.1,10.7-6.6,15.5-10.5C903.4,128.4,905.4,125.5,905.9,121.8z"/>
							<path opacity="0.1091" fill="#131313" d="M923.7,148.7c0.8-7.6,1.3-15,0.2-22.7c-1.1-7.3-2.9-14.4-5.5-21.3
								c-2.6-7.1-6.1-14.1-10.3-20.4c-1.8-2.6-3.6-5.1-5.3-7.7c-3.8-5.1-8-9.9-12.6-14.3c-1.8-2.1-4.1-3-6.9-2.7
								c-3.5,1.1-5.8,3.5-6.9,7.2c-2.6,5.3-4.8,10.8-6.4,16.5c-1.7,3.5-1.6,6.8,0.2,10c4.1,6,7.9,11.9,10.6,18.7
								c2.2,5.5,3.8,11.2,5.1,16.9c1.3,5.6,2.2,11.4,2.2,17.1c0,6.3-0.8,12.5-1.7,18.7c0.2,2.9,1.5,4.9,4.1,6.1
								c3.8,0.9,7.3,0.1,10.6-2.5c5.7-3.2,11.1-6.8,16.2-10.9C921,155.5,923.1,152.6,923.7,148.7z"/>
							<path opacity="0.1091" fill="#131313" d="M926.5,118.4c0.6-4.9,1-9.9,1-14.9c-0.2-5.8-0.9-11.5-2.2-17.2
								c-1.7-7.3-3.9-14.5-7.2-21.3c-2.2-4.5-4.7-8.7-7.4-12.9c-2.4-3.9-5-7.6-8-11.1c-4.2-5.1-8.7-9.7-13.7-14
								c-3.4-0.7-6.2,0.4-8.2,3.5c-3,4.8-5.5,9.8-7.6,15.1c-2.9,6.4-5.4,12.9-7.6,19.6c-2.5,4.8-3.1,9.8-1.8,14.9
								c3.3,4.9,6.4,9.8,8.6,15.3c1.9,4.6,3.4,9.3,4.7,14.1c1.3,4.8,2.4,9.8,2.6,14.8c0,2,0,4.1,0,6.1c-0.5,3.8-1,7.6-1.3,11.4
								c-0.2,3.1,1.1,5.2,4.1,6.1c4.9,0.4,9.3-1.1,13.3-4.4c7.5-4.4,14.6-9.2,21.4-14.5C922,126.6,925,123.1,926.5,118.4z"/>
							<path opacity="0.1091" fill="#131313" d="M888.1,146.9c2.5-3.3,3.7-6.9,3.5-10.7c0.1-3-0.1-5.9-0.4-8.9
								c-0.7-8.2-2.2-16.8-5-24.5c-3.1-8.7-7.2-17.1-12.4-24.7c-1.9-2.6-3.7-5.1-5.6-7.7c-4-5.1-8.3-9.8-13.1-14.2
								c-1.9-1.9-4.2-2.7-6.9-2.6c-3.1,0.5-5.1,2.3-6.1,5.4c-2.2,4.2-3.8,8.5-4.9,13.1c-1.3,3-0.9,5.7,1,8.1
								c1.8,1.6,3.7,3.1,5.5,4.7c2.7,2.5,5.2,5.2,7.4,8.1c3.9,4.8,7.2,10,9.8,15.6c2.1,4.3,3.9,8.8,5.3,13.4
								c1.8,6.1,2.4,12.7,3.3,19c0-1.1,0-2.1,0-3.2c-1,2.9-2.1,5.7-3.1,8.6c-1.4,4,3,10,7,10.4c3-0.5,5.8-1.7,8.3-3.6
								C884.4,151.6,886.6,149.5,888.1,146.9z"/>
							<path opacity="0.1091" fill="#131313" d="M909.1,179c2.5-3.3,3.7-6.8,3.5-10.6c0-3.1-0.1-6.1-0.5-9.2
								c-0.8-8.6-2.2-17.4-5.2-25.6c-3.1-8.8-7.3-17.1-12.7-24.7c-1.9-2.6-3.8-5.1-5.6-7.7c-4-5.1-8.4-9.8-13.2-14.2
								c-2-1.6-4.2-2.6-6.7-3c-2.3-0.4-4.1,0.5-5.3,2.6c-1.7,2.4-2.8,5-3.4,7.9c-0.7,2.3-0.1,4.2,1.7,5.6c1.9,2,3.9,4.1,5.8,6.1
								c2.8,3.1,5.3,6.5,7.5,10.1c3.7,5.8,7,11.9,9.4,18.4c1.8,5.1,3.4,10.2,4.4,15.5c1.5,7.3,1.6,15,2.3,22.4c0-1,0-2.1,0-3.1
								c-1,2.9-2.1,5.8-3.1,8.6c-0.4,2.4,0.1,4.5,1.6,6.5c1.2,2.1,3,3.4,5.4,3.9c3-0.4,5.7-1.6,8.1-3.4
								C905.5,183.6,907.6,181.6,909.1,179z"/>
							<path opacity="0.1091" fill="#131313" d="M938.7,144.3c0.7-7.7,1.3-15.2,0-22.9c-1.2-7.3-3.1-14.3-5.8-21.2
								c-2.8-7-6.4-13.9-10.7-20.1c-1.8-2.5-3.7-5-5.5-7.6c-3.9-5-8.2-9.7-12.8-14c-1.7-2.4-4-3.3-6.9-2.7c-3.8,1.6-6.3,4.6-7.5,8.7
								c-3.1,6.3-5.6,12.8-7.6,19.5c-1.9,3.9-2.1,7.7-0.4,11.5c3.4,6.3,6.5,12.5,8.4,19.3c1.6,5.5,2.7,11.1,3.5,16.8
								c0.8,5.5,1.3,11.1,1,16.7c-0.3,6.2-1.5,12.2-2.3,18.3c-0.2,3.1,1.2,5.1,4.1,6.1c4.8,0.4,9.1-0.9,13-4.1
								c7.3-4.2,14.2-8.9,20.8-14C934.4,152.4,937.3,148.9,938.7,144.3z"/>
							<path opacity="0.1091" fill="#131313" d="M895.1,136.8c1.4-3.1,2.3-6.3,2.8-9.7c0.6-2.9,0.6-5.9-0.1-9
								c-0.6-8.6-1.8-17.1-4.6-25.3c-3-8.7-6.9-17.1-11.9-24.8c-1.8-2.6-3.6-5.2-5.4-7.8c-3.9-5.1-8.1-9.9-12.7-14.4
								c-1.8-2.2-4.1-3.1-6.9-2.6c-3.6,1.4-6,4-7.1,7.8c-2.8,5.7-5.1,11.6-6.8,17.8c-1.8,3.6-1.8,7.2,0,10.6c4,3.8,7.7,7.6,11,12
								c3.3,4.5,6.1,9.3,8.4,14.4c1.8,4.1,3.5,8.2,4.7,12.5c1.7,6.1,1.9,12.6,2.3,18.9c0.2-1.2,0.4-2.4,0.6-3.6
								c-0.7,2.3-1.4,4.6-2.1,6.8c-1.1,2.5-0.8,4.8,0.7,7.1c1.5,2.3,3.6,3.4,6.3,3.3c4.1-1.2,7.9-3.1,11.4-5.7
								C889.3,143,892.4,140.2,895.1,136.8z"/>
							<path opacity="0.1091" fill="#131313" d="M914.7,131c0.7-3.6,1.1-7.2,1-10.8c0.2-4,0-8.1-0.6-12.1
								c-1.1-7.3-2.8-14.5-5.4-21.5c-2.6-7.2-6.1-14.2-10.3-20.6c-1.8-2.6-3.6-5.2-5.4-7.8c-3.9-5.1-8.1-9.9-12.8-14.4
								c-1.7-2.5-4-3.4-6.9-2.7c-3.9,1.9-6.5,5.1-7.8,9.4c-3.2,6.8-6,13.7-8.2,20.9c-2.1,4.1-2.3,8.2-0.7,12.2
								c3.9,5,7.6,10,10.4,15.8c2.3,4.8,4.2,9.8,5.8,14.9c1.6,5.1,2.9,10.4,3.2,15.7c0.4,6.1-0.3,12-1.2,18.1
								c0.1,2.9,1.5,4.9,4.1,6.1c4,0.8,7.6-0.1,11-2.8c6-3.4,11.6-7.2,17-11.5C911.7,138,914,135,914.7,131z"/>
							<path opacity="0.1091" fill="#131313" d="M935,160.1c0.7-3.6,1-7.2,0.9-10.8c0.2-4-0.1-8-0.7-11.9c-1.1-7.2-3-14.3-5.5-21.2
								c-2.6-7.1-6.1-14-10.2-20.4c-1.8-2.6-3.5-5.2-5.3-7.7c-3.8-5.1-8-10-12.5-14.5c-1.9-2-4.2-2.9-6.9-2.7
								c-3.3,0.8-5.5,2.9-6.5,6.3c-2.4,4.7-4.3,9.7-5.7,14.8c-1.5,3.2-1.3,6.2,0.6,9.1c4,6.8,7.7,13.5,10,21c1.9,6,3.1,12.1,4,18.3
								c0.9,5.9,1.4,12,1.1,18c-0.3,6.4-1.4,12.8-2.5,19.1c0.1,2.9,1.4,4.9,4.1,6.1c4,0.8,7.7-0.1,11.1-2.8
								c6-3.4,11.8-7.2,17.2-11.5C931.9,167.2,934.2,164.1,935,160.1z"/>
							<path opacity="0.1091" fill="#131313" d="M889.1,160.4c3.6-5.2,4.7-10.8,3.4-16.9c-0.1-1-0.1-2-0.2-3
								c-0.5-7.3-1.6-14.5-3.4-21.6c-1-4.5-2.5-8.9-4.3-13.2c-1.9-4.4-4-8.6-6.5-12.6c-2.3-4.2-4.9-8.1-7.8-11.8
								c-3.9-4.9-8.1-9.5-12.7-13.7c-2-1.7-4.3-2.6-6.9-2.7c-2.7,0.1-4.6,1.4-5.7,4c-1.9,3.3-3.2,6.7-4.1,10.4
								c-1,2.6-0.5,4.9,1.5,6.8c4.5,4,8.8,8.1,12.5,12.9c4,5.2,7.4,10.9,10,16.9c2.1,4.8,3.8,9.8,5.1,14.8
								c1.8,7.1,2.2,14.7,3.1,21.9c-0.1-1-0.1-2-0.2-3c-1,2.9-2.1,5.8-3.1,8.6c-0.2,2.3,0.4,4.4,1.9,6.3c1.2,2,2.9,3.4,5.1,4.1
								c2.6-0.2,5-1.2,7.1-2.8C886.2,164.6,887.9,162.8,889.1,160.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M877.9,134.6c-1.5-5.1-4.4-9.2-8.6-12.2c-3.4-3-6.9-5.9-10.5-8.7
								c-0.8-0.6-1.7-1.2-2.5-1.9c-5.2-3.8-10.6-7.2-16.4-10.2c-2.5-0.7-4.9-0.7-7.4,0.2c-2.4,0.9-3.6,2.7-3.7,5.3
								c-0.5,3.4-0.4,6.8,0.2,10.1c0.2,2.6,1.5,4.4,3.9,5.2c2.5,1.5,5.1,3.1,7.6,4.6c-0.8-0.6-1.7-1.2-2.5-1.8
								c3.2,2.3,6.5,4.6,9.7,7c-0.8-0.6-1.7-1.2-2.5-1.9c5.1,4.2,10.2,8.4,15.4,12.6c-0.8-0.7-1.6-1.3-2.4-2
								c1.8,2.9,3.5,5.8,5.3,8.7c1.6,1.8,3.6,2.7,6,2.9c2.4,0.4,4.5-0.1,6.5-1.5c1.6-2.4,2.4-5.1,2.6-8
								C879.1,140.1,878.9,137.3,877.9,134.6z"/>
							<path opacity="0.1091" fill="#131313" d="M855.3,119.5c-1.3-4-3.3-7.5-6.1-10.5c-2.1-2.5-4.6-4.7-7.4-6.5
								c-2.4-1.9-4.8-3.8-7.1-5.7c-5.1-3.7-10.5-7.1-16.1-10.1c-2.5-1-5-0.9-7.4,0.4c-2.6,1.9-3.8,4.6-3.4,8c-0.3,5,0,10,0.8,15
								c0,3.4,1.4,5.9,4.2,7.6c4.8,2.6,9.4,5.6,13.7,8.9c3.9,3.2,7.8,6.5,11.6,9.7c-0.8-0.9-1.6-1.8-2.4-2.6c1.5,2.7,3,5.4,4.6,8.1
								c2.5,4.4,9.1,5.3,12.5,1.3c1.7-3.7,2.7-7.5,2.9-11.6C856.3,127.5,856.2,123.5,855.3,119.5z"/>
							<path opacity="0.1091" fill="#131313" d="M856.4,78.1c-4.9-5.3-9.8-10.5-15.5-15c-2.4-2.1-4.9-3.9-7.6-5.6
								c-4-2.9-8.1-5.4-12.4-7.7c-2.6-1.9-5-1.8-7.4,0.4c-2.9,4.3-3.9,9.1-3.1,14.4c-0.1,9.2,0.3,18.3,1.3,27.5
								c-0.3,5.4,1.2,10.1,4.5,14.1c5.2,3.6,10.2,7.3,14.8,11.6c4.4,4.1,8.2,8.7,12.3,13c2.2,2.2,4.7,2.5,7.3,0.8
								c3.5-3.6,5.2-8,5.3-13.2c1.5-8.7,2.4-17.4,2.8-26.2C859.7,87.1,859,82.4,856.4,78.1z"/>
							<path opacity="0.1091" fill="#131313" d="M874.4,107c-4.8-5-9.7-9.9-15.2-14.3c-2.3-2-4.8-3.9-7.4-5.5
								c-3.9-2.8-7.9-5.3-12.2-7.5c-2.5-1.2-5-1-7.4,0.4c-2.7,2.3-3.8,5.4-3.4,9.1c-0.3,5.7,0,11.5,0.9,17.2
								c-0.1,3.8,1.4,6.7,4.3,8.7c5.7,4,11.2,8.1,16.3,12.8c4.6,4.3,8.8,8.8,12.9,13.5c2.3,1.6,4.7,1.8,7.3,0.8
								c3-1.9,4.6-4.8,4.8-8.5c1.2-5.7,1.8-11.4,1.9-17.2C877.9,112.7,876.9,109.6,874.4,107z"/>
							<path opacity="0.1091" fill="#131313" d="M875.6,74.6c-5-5.2-9.9-10.3-15.6-14.8c-2.4-2-4.9-3.8-7.6-5.4
								c-4-2.7-8.1-5.2-12.5-7.3c-2.6-2-5-1.9-7.4,0.3c-2.9,4.5-3.9,9.5-3.1,15c-0.1,9.5,0.3,19,1.3,28.5
								c-0.3,5.5,1.2,10.4,4.5,14.6c5.1,4.1,10,8.2,14.6,12.8c1.9,1.7,3.6,3.6,5.1,5.7c2.2,2.8,4.5,5.3,7,7.8
								c2.2,2.4,4.6,2.7,7.3,0.8c3.5-4,5.3-8.8,5.4-14.3c1.6-9.4,2.6-18.9,3.1-28.4C878.9,84.3,878.1,79.2,875.6,74.6z"/>
							<path opacity="0.1091" fill="#131313" d="M838.8,101.8c-1.8-2-3.6-3.9-5.4-5.9c-3.1-3.1-6.3-6-9.8-8.7
								c-2.3-2-4.8-3.9-7.5-5.6c-3.9-2.8-8-5.3-12.3-7.5c-2.5-1.2-5-1.1-7.4,0.4c-2.7,2.6-3.8,5.8-3.3,9.7c-0.2,6.1,0.1,12.3,1,18.3
								c-0.1,3.9,1.3,7,4.3,9.3c2.2,1.2,4.5,2.5,6.7,3.7c3.4,2,6.6,4.2,9.5,6.8c2,1.5,3.9,3.2,5.7,5c2.7,2.4,5.1,5,7.3,7.8
								c2.3,1.5,4.7,1.8,7.3,0.8c3-1.8,4.6-4.6,4.7-8.3c1.1-5.5,1.7-11.1,1.8-16.8C842.3,107.2,841.3,104.2,838.8,101.8z"/>
							<path opacity="0.1091" fill="#131313" d="M861.9,138.2c-1.5-5.2-4.3-9.3-8.5-12.4c-3.4-3-6.9-6-10.4-8.8
								c-5.2-3.9-10.6-7.2-16.2-10.6c-1.8-1.5-3.9-2.1-6.3-1.9c-2.4-0.1-4.4,0.6-6.2,2.2c-1.2,2.4-1.7,4.9-1.5,7.6
								c-0.1,2.7,0.5,5.2,1.8,7.5c3.4,2,6.9,3.9,10.3,5.9c-0.9-0.5-1.7-1-2.6-1.5c5,3.1,9.7,6.5,14.1,10.3
								c5.4,3.1,9.3,7.6,11.6,13.5c1.7,1.5,3.7,2.3,6,2.4c2.3,0.4,4.4,0,6.4-1.1c1.5-1.9,2.3-4,2.4-6.4
								C863.3,142.6,863,140.3,861.9,138.2z"/>
							<path opacity="0.1091" fill="#131313" d="M888.2,103.9c-4.8-5.2-9.6-10.2-15.2-14.6c-2.3-2-4.8-3.9-7.4-5.5
								c-3.9-2.8-7.9-5.3-12.2-7.5c-2.5-1.3-5-1.1-7.4,0.4c-2.7,2.6-3.8,5.8-3.3,9.7c-0.2,6.1,0.1,12.2,0.9,18.3
								c-0.1,3.9,1.3,7,4.3,9.3c5.6,4.3,11,8.7,16,13.7c4.5,4.5,8.6,9.3,12.7,14c2.3,1.7,4.7,2,7.3,0.8c3.1-2.2,4.8-5.4,4.9-9.4
								c1.2-6.2,1.9-12.5,2.1-18.9C891.7,110.2,890.8,106.7,888.2,103.9z"/>
							<path opacity="0.1091" fill="#131313" d="M847.3,91.1c-4.8-5.4-9.7-10.5-15.3-15c-2.4-2.1-4.8-3.9-7.5-5.6
								c-3.9-2.8-8-5.4-12.3-7.7c-2.5-1.6-5-1.5-7.4,0.4c-2.8,3.6-3.9,7.7-3.2,12.4c-0.2,7.8,0.2,15.7,1.2,23.4
								c-0.2,4.7,1.3,8.7,4.4,12c5.4,3.4,10.5,6.8,15.3,11c4.6,4,8.5,8.5,12.5,13c2.3,1.9,4.7,2.2,7.3,0.8c3.3-2.8,5-6.5,5-11
								c1.3-7.2,2.1-14.5,2.3-21.9C850.8,98.5,849.9,94.6,847.3,91.1z"/>
							<path opacity="0.1091" fill="#131313" d="M865.5,88.9c-4.8-5.3-9.7-10.4-15.3-14.9c-2.4-2-4.9-3.9-7.5-5.5
								c-3.9-2.8-8-5.3-12.3-7.5c-2.5-1.7-5-1.6-7.4,0.4c-2.8,3.7-3.9,7.9-3.2,12.7c-0.2,8,0.2,16.1,1.2,24.1
								c-0.2,4.8,1.3,8.9,4.4,12.3c5.3,3.8,10.4,7.7,15.2,12.2c4.4,4.2,8.3,8.9,12.4,13.4c2.2,2,4.7,2.3,7.3,0.8
								c3.4-3.1,5.1-7,5.1-11.8c1.4-7.8,2.2-15.6,2.5-23.5C868.9,96.9,868.1,92.7,865.5,88.9z"/>
							<path opacity="0.1091" fill="#131313" d="M887.8,123.5c-1.2-4.1-3.2-7.7-6.1-10.6c-2.2-2.5-4.6-4.7-7.4-6.6
								c-2.4-1.9-4.8-3.9-7.1-5.8c-5.1-3.8-10.5-7.3-16.1-10.3c-2.5-0.9-5-0.8-7.4,0.4c-2.6,1.7-3.7,4.1-3.5,7.3
								c-0.3,4.6-0.1,9.1,0.7,13.6c0.1,3.2,1.5,5.5,4.2,6.9c4.9,3.2,9.5,6.7,13.8,10.6c3.9,3.6,7.7,7.2,11.6,10.8
								c-0.8-0.9-1.6-1.7-2.4-2.6c1.5,2.8,3,5.6,4.5,8.4c2.4,4.4,9.1,5.2,12.4,1.4c1.7-3.6,2.7-7.4,2.9-11.5
								C888.8,131.4,888.6,127.5,887.8,123.5z"/>
							<path opacity="0.1091" fill="#131313" d="M842.7,120.6c-0.7-5-2.7-9.2-6.1-12.4c-2.2-2.3-4.6-4.4-7.3-6.2
								c-2.4-1.9-4.8-3.8-7.2-5.6c-5.2-3.7-10.6-7-16.2-10c-2.5-0.9-5-0.8-7.4,0.4c-2.6,1.8-3.7,4.3-3.4,7.6
								c-0.3,4.8,0,9.5,0.8,14.2c0,3.3,1.5,5.7,4.2,7.2c4.9,2.3,9.5,5.1,13.9,8.2c3.9,3,7.8,6,11.6,9c-0.8-0.8-1.6-1.6-2.4-2.4
								c1.6,3.3,3.1,6.6,4.7,9.9c2.1,4.4,9.5,4.6,12.5,1.3c1.7-3.3,2.6-6.7,2.8-10.4C843.8,127.8,843.6,124.2,842.7,120.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M803.3,135.5c5.6,0.4,11.2,1.3,16.6,2.7c5.5,1.1,10.9,2.6,16.3,4.4
								c3.5,1.3,6.9,2.5,10.4,3.8c6.7,2.7,13.3,5.8,19.6,9.4c3.2,0.5,5.3-0.8,6.3-3.9c0.4-5.2-1.1-10-4.4-14.4
								c-4.4-8.2-9.3-16.2-14.7-23.8c-2.4-4.9-6-8.3-10.9-10.4c-11.5-4.1-22.9-7.9-34.8-10.5c-3.9-0.8-7.9-1.6-11.8-2.4
								c-7.2-1.7-14.5-2.7-21.9-3.1c-3.2-0.1-5.1,1.5-5.7,4.6c0.2,5.2,2.2,9.7,6,13.5c5.3,7.5,11.1,14.6,17.2,21.4
								C794.4,131.2,798.3,134.1,803.3,135.5z"/>
							<path opacity="0.1091" fill="#131313" d="M779.2,116.3c3.7,0.5,7.3,1,11,1.4c-1.3-0.2-2.5-0.4-3.8-0.6
								c3.7,0.8,7.3,1.5,11,2.3c5.4,1.2,10.8,2.8,16,4.8c3.5,1.1,6.9,2.4,10.3,3.9c5.1,2,10,4.3,14.8,6.8c2.5,1.5,4.9,1.6,7.4,0.1
								c2.4-1.5,3.5-3.8,3.3-6.6c-1.4-4.8-3.5-9.4-6.4-13.6c-2.5-4.4-5.5-8.4-9.2-11.8c-7.1-3.1-14.4-5.8-21.8-8.1
								c-5.6-1.8-11.2-3.3-16.9-4.6c-10.4-2.4-20.8-4-31.4-5.3c-2.7,0.6-4.6,2.1-5.7,4.6c-0.9,3.4,0,6.5,2.5,9.2
								c3.1,4.7,6.6,9.1,10.5,13.1C772.8,114.9,775.6,116.4,779.2,116.3z"/>
							<path opacity="0.1091" fill="#131313" d="M778.2,81.6c6.1,1.9,12.3,3.5,18.6,4.8c5.6,1.1,11,2.5,16.4,4.4
								c3.5,1.2,6.9,2.5,10.4,3.7c6.7,2.6,13.3,5.7,19.7,9.1c2.3,0.4,4.5-0.1,6.4-1.5c2.1-1.1,3.6-2.8,4.3-5c-0.2-2.7-1-5.3-2.6-7.5
								c-1.3-2.5-3.1-4.4-5.5-5.8c-10.5-5.1-21-9.9-32.1-13.3c-3.8-1.1-7.5-2.2-11.3-3.2c-8-1.5-16-3.2-23.9-5.1
								c-2.3,0-4.4,0.8-6.1,2.4c-1.9,1.4-3.1,3.2-3.6,5.5c0.5,2.6,1.6,4.9,3.4,6.8C773.8,79,775.8,80.6,778.2,81.6z"/>
							<path opacity="0.1091" fill="#131313" d="M800.2,108.8c7.5,0.6,14.8,1.9,22,3.8c-1.4-0.3-2.7-0.7-4.1-1
								c7.2,1.7,14.3,3.9,21.3,6.6c6.8,2.6,13.4,5.6,19.9,9c2.5,1.7,5,1.9,7.6,0.4c2.5-1.7,3.5-4,3.1-7c-1.7-5.2-4-10.1-7.1-14.7
								c-2.7-4.8-6-9.1-9.9-12.9c-7.3-3.3-14.8-6.3-22.5-8.8c-4.5-1.5-9-2.8-13.5-4c-4.5-1.2-9.1-2.3-13.7-3.3
								c-7.3-1.7-14.6-2.8-22-3.4c-2.8,0.4-4.7,1.9-5.7,4.6c-0.6,3.9,0.5,7.3,3.3,10.2c3.6,5.4,7.7,10.4,12.2,15.1
								C793.2,106.7,796.3,108.5,800.2,108.8z"/>
							<path opacity="0.1091" fill="#131313" d="M796.7,78.7c6.2,2.7,12.5,4.6,19.1,5.8c5.5,1,10.8,2.4,16.1,4.2
								c3.5,1.3,7,2.5,10.5,3.8c6.9,2.7,13.5,5.9,20,9.4c2.4,0.7,4.6,0.3,6.7-1.1c2.2-1.2,3.6-3,4-5.4c-0.5-3.3-1.7-6.3-3.6-9
								c-1.6-2.9-3.7-5.4-6.4-7.3c-10.9-5.3-21.8-10.5-33.5-14c-3.8-1.1-7.7-2.1-11.5-3.2c-8.2-1.2-16.2-3.2-23.9-6
								c-4.3-1.3-9.8,3.6-9.7,7.9c0.9,3.2,2.6,6.1,4.8,8.6C791.3,74.9,793.8,77.1,796.7,78.7z"/>
							<path opacity="0.1091" fill="#131313" d="M761.6,104.3c6.1,1.8,12.2,3.3,18.4,4.6c5.4,1.1,10.7,2.5,15.9,4.2
								c3.4,1.2,6.8,2.4,10.3,3.6c6.7,2.6,13.2,5.5,19.5,8.9c4.1,2,10.3-2.3,10.7-6.5c-0.5-3.4-1.8-6.5-3.7-9.3
								c-1.6-3-3.8-5.6-6.6-7.6c-10.4-4.5-20.7-8.8-31.6-11.8c-3.5-0.9-7.1-1.9-10.6-2.8c-7.9-1.4-15.8-3-23.6-4.9
								c-4.2-0.9-9.7,3.6-9.8,7.8c0.8,3,2.2,5.7,4.3,8C756.6,101,758.8,103,761.6,104.3z"/>
							<path opacity="0.1091" fill="#131313" d="M786.6,137.2c7.5,0.7,14.9,2.1,22.2,4c7.2,1.8,14.3,4.2,21.2,7
								c-1.5-0.6-3-1.2-4.5-1.8c4.8,2.1,9.7,4.2,14.5,6.2c-1.5-0.7-2.9-1.3-4.4-2c4.7,2.3,9.4,4.7,14.1,7c3.1,0.3,5.1-1,6.3-3.9
								c0.7-4.6-0.5-8.9-3.4-12.8c-3.8-7.2-8.1-14.2-12.8-20.8c-2.2-4.4-5.5-7.3-9.9-8.8c-11.1-3.9-22.2-7.5-33.7-10
								c-3.8-0.8-7.7-1.6-11.5-2.3c-7.2-1.5-14.5-2.6-21.9-3.1c-3,0.2-4.9,1.7-5.7,4.6c-0.2,4.5,1.3,8.4,4.5,11.8
								c4.4,6.4,9.2,12.4,14.5,18C778.6,134.1,782.1,136.5,786.6,137.2z"/>
							<path opacity="0.1091" fill="#131313" d="M814.3,105.3c5.6,0.5,11.2,1.4,16.6,2.7c5.5,1.1,10.9,2.5,16.2,4.2
								c-1.4-0.4-2.8-0.9-4.3-1.3c5.3,1.6,10.5,3.5,15.5,5.7c5.1,2,10,4.3,14.8,6.9c2.5,1.9,5.1,2.1,7.8,0.7
								c2.5-1.8,3.5-4.2,2.9-7.3c-1.9-5.6-4.5-10.9-7.8-15.8c-2.9-5.2-6.5-9.8-10.6-14c-7.5-3.5-15.2-6.6-23-9.2
								c-3.3-1.2-6.7-2.2-10.1-3.2c-5.8-1.9-11.8-3.4-17.8-4.6c-7.3-1.8-14.7-3-22.2-3.6c-2.9,0.3-4.8,1.8-5.7,4.6
								c-0.4,4.2,1,7.9,4.1,11.1c4.1,6,8.6,11.6,13.6,16.8C806.7,102.7,810,104.8,814.3,105.3z"/>
							<path opacity="0.1091" fill="#131313" d="M769.7,92.5c6.1,1.9,12.3,3.5,18.6,4.8c5.6,1.1,11.1,2.6,16.4,4.5
								c3.5,1.3,6.9,2.5,10.4,3.8c6.7,2.6,13.2,5.7,19.5,9.1c2.4,0.7,4.6,0.3,6.6-1.1c2.2-1.2,3.6-3,4.1-5.4
								c-0.4-3.2-1.6-6.1-3.4-8.8c-1.5-2.9-3.6-5.2-6.2-7.1c-10.4-4.8-20.8-9.4-31.8-12.7c-3.8-1.1-7.5-2.1-11.3-3.2
								c-8.1-1.4-16-3.1-24-5.1c-4.1-1-9.7,3.7-9.8,7.9c0.7,2.9,2.1,5.5,4.1,7.8C764.7,89.3,767,91.2,769.7,92.5z"/>
							<path opacity="0.1091" fill="#131313" d="M785.9,88.4c6.2,3.2,12.6,5.2,19.4,6.2c5.5,0.9,10.9,2.2,16.1,4
								c3.5,1.2,7,2.5,10.4,3.7c6.8,2.6,13.4,5.7,19.8,9.2c4.4,2.2,10.4-1.9,10.7-6.6c-0.7-3.7-2.2-7.1-4.3-10.2
								c-1.8-3.3-4.2-6.2-7.2-8.5c-10.8-5-21.6-9.8-33.1-13.1c-3.8-1-7.7-2-11.5-3c-8.4-0.9-16.4-2.9-24.1-6.3
								c-4.6-1.5-10,3.3-9.7,7.9c1.2,3.5,3,6.6,5.5,9.4C780,84.1,782.7,86.5,785.9,88.4z"/>
							<path opacity="0.1091" fill="#131313" d="M812.7,121.6c3.7,0.5,7.4,0.9,11.1,1.4c5.5,1,11,2.3,16.4,4c3.6,0.9,7.1,2,10.5,3.4
								c5.2,1.7,10.2,3.8,15.1,6.1c-1.5-0.7-3-1.5-4.6-2.2c4.7,2.3,9.5,4.7,14.2,7c3.1,0.3,5.2-1,6.3-3.9c0.6-4.8-0.7-9.2-3.7-13.3
								c-4-7.5-8.5-14.7-13.4-21.7c-2.2-4.5-5.6-7.6-10.2-9.2c-11.6-4.5-23.1-8.7-35.2-11.6c-4-0.9-7.9-1.8-11.9-2.7
								c-7.3-1.8-14.6-2.9-22-3.3c-3.1,0-5,1.6-5.7,4.6c0.1,4.9,1.9,9.2,5.5,12.9c5,7.1,10.4,13.8,16.3,20.2
								C804.1,117.7,807.9,120.4,812.7,121.6z"/>
							<path opacity="0.1091" fill="#131313" d="M762,115.3c6,2.4,12.2,4.2,18.5,5.1c5.5,0.9,10.8,2.2,16.1,4
								c3.5,1.2,6.9,2.4,10.4,3.6c6.7,2.6,13.3,5.5,19.7,8.8c4.8,2.3,10.6-1.2,10.7-6.5c-1-4.2-2.8-8.1-5.2-11.7
								c-2.1-3.8-4.8-7.1-8-9.9c-10.5-4.3-21.1-8.4-32.1-11.2c-3.7-0.9-7.5-1.8-11.2-2.6c-8-0.8-15.8-2.6-23.3-5.2
								c-4.8-1.3-10.1,2.8-9.8,7.8c1.3,3.7,3.2,7,5.9,10C755.8,110.6,758.6,113.2,762,115.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M794.4,170.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C785.1,166.9,789.3,169.3,794.4,170.1z"/>
							<path opacity="0.1091" fill="#131313" d="M770,151.1c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C763.7,150.5,766.6,151.6,770,151.1z"/>
							<path opacity="0.1091" fill="#131313" d="M769.3,117.2c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C764.5,114.9,766.7,116.4,769.3,117.2z"/>
							<path opacity="0.1091" fill="#131313" d="M791.2,143.7c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C784,142.4,787.3,143.9,791.2,143.7z"/>
							<path opacity="0.1091" fill="#131313" d="M786.5,112.9c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C780.4,109.5,783.2,111.5,786.5,112.9z"/>
							<path opacity="0.1091" fill="#131313" d="M752.4,139.4c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C747.2,136.8,749.6,138.4,752.4,139.4z"/>
							<path opacity="0.1091" fill="#131313" d="M777.4,171.8c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C769.5,169.9,773.1,171.7,777.4,171.8z"/>
							<path opacity="0.1091" fill="#131313" d="M805.4,140.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C797.4,138.4,801,140.3,805.4,140.5z"/>
							<path opacity="0.1091" fill="#131313" d="M760.6,127.8c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C755.4,125.2,757.8,126.8,760.6,127.8z"/>
							<path opacity="0.1091" fill="#131313" d="M776.9,124.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C770.4,120.4,773.5,122.6,776.9,124.3z"/>
							<path opacity="0.1091" fill="#131313" d="M804,156.5c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C794.9,153.6,799.1,155.9,804,156.5z"/>
							<path opacity="0.1091" fill="#131313" d="M752.3,150.2c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C746,146.6,749,148.7,752.3,150.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M772.1,91.7c0.9-3.2,1.8-6.3,2.6-9.5c1.4-5-4.3-9.7-9-8.8c-3.6,1.8-6.8,4.3-9.5,7.3
								c-3,2.8-5.4,6-7.1,9.7c-0.7,2.9-1.5,5.7-2.2,8.6c-1.3,4.9,4.2,9.7,8.8,8.9c3.6-1.7,6.7-4,9.3-7
								C768.1,98.3,770.4,95.2,772.1,91.7z"/>
							<path opacity="0.1091" fill="#131313" d="M749,75.5c1.2-3.6,2.5-7.2,3.7-10.7c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.7-6.5-2.3c-3.4,1.6-6.4,3.9-9,6.8c-2.8,2.6-5,5.6-6.6,9.1c-1,3.3-2,6.6-3.1,9.8c-0.3,2.5,0.5,4.7,2.4,6.4
								c1.7,1.9,3.9,2.7,6.4,2.4c3.3-1.5,6.2-3.6,8.7-6.4C745.4,81.7,747.5,78.8,749,75.5z"/>
							<path opacity="0.1091" fill="#131313" d="M754.4,35.2c0-2.3,0-4.6,0-6.9c-0.6-2.9-2.4-4.6-5.3-5.1c-4.2,0.1-7.7,1.9-10.4,5.3
								c-5.3,4.7-10.3,9.8-14.8,15.3c-3.3,2.9-5,6.4-5,10.6c0,2,0,4.1,0,6.1c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5
								c5.3-4.6,10.3-9.6,14.9-14.9C752.7,42.9,754.4,39.4,754.4,35.2z"/>
							<path opacity="0.1091" fill="#131313" d="M772.2,62.3c0-2.3-0.1-4.5-0.1-6.8c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,2,0.1,4.1,0.1,6.1c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C770.9,69.5,772.4,66.2,772.2,62.3z"/>
							<path opacity="0.1091" fill="#131313" d="M775.5,30.9c-0.1-2.3-0.2-4.5-0.2-6.8c-0.3-3.2-2-4.9-5.3-5.1
								c-5.2,0.9-9.5,3.5-13,7.8c-7,6.4-13.5,13.1-19.7,20.2c-4.2,3.5-6.7,7.9-7.5,13.2c0.1,1.9,0.2,3.9,0.4,5.8
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C772.2,40.4,774.7,36,775.5,30.9z"/>
							<path opacity="0.1091" fill="#131313" d="M735.9,61.8c1-3.4,2-6.8,3-10.2c0.2-2.5-0.7-4.6-2.6-6.3c-1.7-1.9-3.8-2.7-6.4-2.5
								c-3.2,1.4-6,3.4-8.3,6.1c-2.6,2.4-4.6,5.2-6,8.4c-0.8,3.2-1.7,6.4-2.5,9.6c-0.1,2.5,0.7,4.6,2.6,6.3c1.7,1.9,3.8,2.7,6.3,2.6
								c3.1-1.3,5.8-3.2,8.1-5.8C732.7,67.6,734.6,64.9,735.9,61.8z"/>
							<path opacity="0.1091" fill="#131313" d="M757.9,92.9c0.7-3.2,1.5-6.4,2.2-9.5c-0.1-2.4-1-4.4-2.9-6c-1.6-1.8-3.6-2.8-6-2.9
								c-2.8,0.9-5.1,2.5-7.1,4.7c-2.2,1.9-3.8,4.3-4.7,7c-0.6,3-1.2,6.1-1.8,9.1c0.1,2.4,1.1,4.3,2.9,5.9c1.6,1.8,3.6,2.8,5.9,2.9
								c2.7-0.9,5-2.4,6.8-4.5C755.5,97.8,757,95.5,757.9,92.9z"/>
							<path opacity="0.1091" fill="#131313" d="M786.9,57.9c0-2.3-0.1-4.6-0.1-6.8c-0.4-3.1-2.2-4.8-5.3-5.2
								c-4.7,0.5-8.6,2.7-11.8,6.5c-6.2,5.5-12,11.4-17.4,17.7c-3.8,3.2-5.9,7.2-6.3,11.9c0.1,2,0.1,4,0.2,5.9
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C784.4,66.5,786.5,62.6,786.9,57.9z"/>
							<path opacity="0.1091" fill="#131313" d="M745.3,46.2c0-2.3,0-4.6,0-6.8c-0.9-2.6-2.6-4.3-5.3-5.1c-3.5-0.4-6.5,0.8-8.9,3.6
								c-4.3,3.7-8.3,7.7-11.8,12.1c-2.8,2.5-3.9,5.5-3.4,9c0,2.1-0.1,4.2-0.1,6.3c0.8,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.5-0.7,8.9-3.5
								c4.4-3.6,8.3-7.6,11.9-11.9C744.6,52.6,745.8,49.7,745.3,46.2z"/>
							<path opacity="0.1091" fill="#131313" d="M763.5,44.4c0-2.3,0-4.6,0-6.9c-0.6-2.9-2.4-4.6-5.3-5.1c-4.3,0.2-7.9,2.1-10.8,5.6
								c-5.6,4.9-10.7,10.2-15.5,15.9c-3.5,2.9-5.2,6.6-5.3,10.9c0,2,0.1,4.1,0.1,6.1c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C761.6,52.3,763.4,48.7,763.5,44.4z"/>
							<path opacity="0.1091" fill="#131313" d="M783.6,73.7c0-2.3-0.1-4.5-0.1-6.8c-0.7-2.8-2.5-4.5-5.3-5.2
								c-4-0.1-7.3,1.4-9.9,4.6c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,2,0.1,4.1,0.1,6.1c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C782.2,80.9,783.7,77.6,783.6,73.7z"/>
							<path opacity="0.1091" fill="#131313" d="M738.8,73.7c0.7-3.2,1.5-6.4,2.2-9.6c-0.1-2.4-1-4.4-2.9-6c-1.6-1.8-3.6-2.8-6-2.8
								c-2.8,1-5.2,2.6-7.1,4.8c-2.2,2-3.8,4.4-4.8,7.1c-0.6,3-1.2,6.1-1.8,9.1c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9
								c2.7-0.9,5-2.4,6.9-4.6C736.4,78.8,737.9,76.5,738.8,73.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M750,33.9c20.4,23.5,39.8,48.8,63.2,69.5c10.5,9.3,22.1,17.4,35,22.8c13.8,5.8,28.7,7.9,43.4,9.6
							c18.2,2.1,36.6,3.8,53.6,11.2c0.3,0.1,0.8-0.3,0.4-0.5c-28.1-12.2-59.6-8.6-88.4-17.6c-28.6-8.9-50.3-30.8-69.7-52.7
							c-12.4-14-24.6-28.3-36.9-42.4C750.4,33.3,749.7,33.6,750,33.9L750,33.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M708.6,29c2.5,18.5,12,34.7,23.5,49.2c13.1,16.5,28.2,31.6,42.7,46.7c14.1,14.6,28,30,44.2,42.3
							c15.6,11.8,33.3,18.2,52.3,22.1c24.7,5.1,49.7,9,74.6,13.5c0.4,0.1,0.7-0.5,0.2-0.6c-21.3-3.8-42.7-7.6-64-11.5
							c-20.8-3.8-40.8-8.8-58.4-21c-16.5-11.5-30.3-26.6-44.3-41c-14.1-14.6-28.4-29.1-41.5-44.5c-13.7-16-25.8-34.1-28.7-55.4
							C709.2,28.5,708.5,28.7,708.6,29L708.6,29z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M685.9,25.2c-10.6,20.8,4.2,43.4,16.5,59.8c17.2,22.9,34.5,45.7,51.8,68.5
							c17.1,22.7,34.2,45.3,51.4,68c4.2,5.6,8.5,11.2,12.7,16.9c3.7,4.9,7.1,10.3,11.8,14.3c8.1,6.9,19.6,4.6,29.5,4.5
							c30.2-0.5,61.2,0.4,90.7-7.2c0.4-0.1,0.3-0.6-0.1-0.4c-25.8,6.6-52.6,6.5-79.1,7c-6,0.1-12,0.2-18,0.3
							c-7.2,0.1-14.9,1.1-21.1-3.5c-4.8-3.6-8.2-9-11.8-13.7c-3.9-5.1-7.7-10.2-11.6-15.3c-32-42.3-64-84.6-96-126.9
							c-14.4-19.1-39.1-46.4-26-72C686.8,24.9,686.1,24.9,685.9,25.2L685.9,25.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M879.7,68.1c0.2,0,0.8-0.4,0.3-0.4C879.7,67.8,879.2,68.1,879.7,68.1L879.7,68.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M684.6,68.3c41.3-0.6,82.7-1.2,124-1.7c26.4-0.4,58-1.1,76.3-23.3c0.2-0.2-0.5-0.2-0.7,0
							c-20.6,25-58,22.6-87.3,23c-37.4,0.5-74.7,1.1-112.1,1.6C684.6,67.9,684.1,68.3,684.6,68.3L684.6,68.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M752.3,136.9c27.2-13.2,54.3-26.4,81.5-39.6c23.4-11.4,47-23.1,66.9-40.1c0.4-0.3-0.2-0.6-0.5-0.4
							c-20.4,17.4-44.5,29.2-68.5,40.8c-26.6,12.9-53.2,25.9-79.8,38.8C751.5,136.6,751.9,137.1,752.3,136.9L752.3,136.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M782.1,189.4c12-10.8,27-16.6,41.4-23.4c13.4-6.4,25.8-14.4,37.1-24c11.4-9.7,21.7-20.7,31.7-31.9
							c10.1-11.4,20.6-22.8,29.2-35.4c8.8-12.9,15.3-27.5,14.9-43.4c0-0.2-0.7,0-0.7,0.2c0.7,31.1-23.7,55.7-42.9,77.4
							c-20.6,23.3-42.7,44.4-71.2,57.7c-14,6.6-28.5,12.4-40.2,22.9C781.2,189.6,781.9,189.5,782.1,189.4L782.1,189.4z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M935.9,24.3c0.4,0,0.6-0.5,0.1-0.5C935.5,23.8,935.4,24.3,935.9,24.3L935.9,24.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M935.8,24.3c0.3,0,0.8-0.4,0.3-0.4C935.8,23.8,935.3,24.3,935.8,24.3L935.8,24.3z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M935.9,21.9c0.4,0,0.6-0.5,0.1-0.5C935.5,21.4,935.4,21.9,935.9,21.9L935.9,21.9z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M872,250.2c39.7-46.3,55.3-107.2,72.8-164.2c5.9-19.1,11.7-38.2,17.6-57.3c0.1-0.3-0.6-0.2-0.7,0.1
							c-9.9,32.1-19.6,64.3-29.6,96.4c-9,28.7-18.8,57.5-33.1,84.1c-7.8,14.6-16.9,28.3-27.7,40.9
							C871.1,250.4,871.8,250.4,872,250.2L872,250.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M816.5,231.2c10.8-9.1,20.4-19.6,28.4-31.3c8.7-12.8,14.6-27.2,23.4-40c8.8-12.7,19-24.3,27.8-37
							c8.6-12.4,16.5-25.4,23.5-38.8c8.1-15.5,15.1-31.6,20.9-48.1c0.1-0.2-0.6-0.1-0.7,0.1c-10.2,28.8-23.9,56.3-40.9,81.7
							c-8.4,12.6-18,24.3-27.1,36.4c-8.7,11.7-14.8,24.6-22.2,37.2c-8.8,15.1-20.3,28.5-33.7,39.8
							C815.7,231.4,816.4,231.4,816.5,231.2L816.5,231.2z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M874.7,251c6.7,14.1,11.8,28.9,15,44.2c1.6,7.6,2.8,15.4,3.5,23.1c0.7,7.6,0.1,15.5,1.1,23
							c0.9,7,5.4,11.3,10.4,16c6.2,5.9,12.5,11.7,18.7,17.6c5.6,5.2,11.1,10.7,16.8,15.7c4.5,3.9,9.2,7.2,15.3,7.5
							c0.3,0,0.7-0.5,0.3-0.5c-8.4-0.4-14.4-6.8-20.1-12.2c-5.8-5.4-11.5-10.8-17.3-16.2c-5.6-5.2-11.3-10.3-16.7-15.7
							c-6.2-6.2-6.7-12.5-6.9-20.9c-0.5-28.3-7.2-56.3-19.3-81.8C875.3,250.5,874.6,250.7,874.7,251L874.7,251z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M882.2,272.6c14.1,9,23.1,22.1,28.3,37.7c2.4,7.2,6.3,8.3,13.4,8.7c9.6,0.5,19.3,1,28.9,1.5
							c0.2,0,0.8-0.3,0.5-0.3c-9-0.5-18-1-27-1.4c-4.8-0.3-10.5,0.2-13.4-4.4c-1.9-2.9-2.4-6.9-3.7-10.1
							c-5.1-13.2-14.4-24.3-26.3-31.9C882.7,272.2,882.1,272.5,882.2,272.6L882.2,272.6z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<path fill="#4D4D4D" d="M916.6,353.1c8.8-11.9,13.8-25.9,17.7-40.1c4.3-16.1,8.2-32.3,11.8-48.5c7.3-32.3,13.3-65.1,13.9-98.2
							c0.3-19.2-1.3-38.4-5.4-57.3c0-0.2-0.8,0-0.7,0.2c14.3,66.2-2.5,134.1-18.9,198.3c-4.1,16.1-9.1,32.2-19.1,45.8
							C915.9,353.3,916.5,353.3,916.6,353.1L916.6,353.1z"/>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M847.5,1304.8c0.3,1.5,0.5,3.1,0.4,4.6c0-1.6,0.1-3.1,0.1-4.7
								c-0.2,0.6-0.3,1.1-0.5,1.7c1.3-2.4,2.5-4.8,3.8-7.2c-0.3,0.5-0.6,0.9-0.9,1.4c4.7-5.7,9.4-11.4,14.2-17.1
								c-5.9,5.8-11.8,11.6-17.6,17.4c7.4-5.6,14.8-11.2,22.2-16.8c-2.9,1.6-5.8,3.1-8.7,4.7c2-0.7,4-1.5,6.1-2.2
								c-2.6,0.8-5.3,1.7-7.9,2.5c1.8-0.3,3.7-0.5,5.5-0.8c-2.3,0.2-4.6,0.5-6.9,0.7c1.7,0.1,3.3,0.3,5,0.4
								c-2.6-0.9-5.3-1.9-7.9-2.8c1.1,0.7,2.2,1.4,3.3,2.1c-1.5-1.5-2.9-3-4.4-4.4c0.8,0.9,1.5,1.7,2.3,2.6c-0.8-1.5-1.5-3-2.3-4.4
								c0.5,1,0.9,2,1.4,3c-0.2-1.3-0.4-2.6-0.6-3.9c0.2,1.1,0.3,2.2,0.5,3.2c0.4-2,0.8-4,1.1-6c0-2.7-0.5-5.3-1.6-7.8
								c-0.5-1.8-1.5-2.7-3.2-2.9c-1.6-0.6-3.3-0.7-5.1-0.1c-2.3,0.3-4.2,1.3-5.8,3c-2.6,4.1-3.6,8.6-3,13.5c0.5-2.1,1-4.2,1.4-6.3
								c-1.2,2.3-2,4.7-2.4,7.3c-1.4,3.6-1.5,7.3-0.3,11.1c0.9,2.4,1.8,4.8,2.7,7.3c1.7,2.2,3.4,4.4,5.2,6.6
								c1.2,1.8,2.7,2.7,4.7,2.9c1.8,0.8,3.6,0.5,5.4-0.8c4.8-4.8,9.7-9.6,14.5-14.4c-4.9,0-9.7,0-14.6-0.1c0.8,0.2,1.5,0.4,2.3,0.7
								c-1.4-0.5-2.9-1-4.3-1.6c0.4,0.3,0.8,0.6,1.3,1c-0.7-0.9-1.4-1.9-2.2-2.8c8.6,12.8,18,9.6,38.4,17.4
								c-7.9-5.4-15.9-10.7-23.8-16.1c5.6,4.8,11.2,9.6,16.8,14.5c-5.3-5.4-10.6-10.9-15.9-16.3c5,6.1,10.1,12.2,15.1,18.4
								c-5.3-8.5-10.6-17-15.8-25.6c5.1,12.2,10.2,24.4,15.3,36.6c-2.8-9.9-5.6-19.8-8.3-29.7c0.9,8,1.9,16.1,2.8,24.1
								c-0.4-7.2-0.9-14.4-1.3-21.6c-0.3,7.7-0.6,15.3-0.9,23c0.8-6.9,1.5-13.8,2.3-20.8c-1.4,7.4-2.9,14.8-4.3,22.2
								c1.9-6.7,3.8-13.4,5.7-20.1c-2.7,7.2-5.3,14.5-8,21.7c2.8-6.1,5.6-12.1,8.4-18.2c-3.5,6.6-7,13.3-10.5,19.9
								c5.2-7.2,10.5-14.4,15.7-21.7c-6.9,7-13.8,13.9-20.8,20.9c4.5-3.9,9-7.8,13.4-11.8c-5.3,3.8-10.5,7.6-15.8,11.4
								c4.9-3.2,9.8-6.3,14.6-9.5c-5.4,2.9-10.9,5.8-16.3,8.7c7.1-2.8,14.3-5.7,21.4-8.5c-5.9,1.8-12,2.7-18.1,2.8
								c2.3-0.2,4.5-0.3,6.8-0.5c-2.6-0.3-5.3-0.6-7.9-0.9c3.5,0.8,7,1.5,10.5,2.3c-3.7-2.3-7.5-4.7-11.2-7c2.1,2,4.1,4,6.2,6.1
								c-0.7-1.4-0.7-2.8,0-4.1c0.8-1.4,1.7-2.8,2.5-4.2c-0.6,0.9-1.3,1.8-1.9,2.7c3.2-3.8,6.5-7.6,9.7-11.4c1.2-1.3,2.1-2.7,3-4.2
								c1.2-1.9,2.3-3.8,3.3-5.8c2.5-4.6,5.3-9,8.4-13.3c3-4.4,0.5-8.3-3.6-10.6c-1.4-1.1-3-1.4-4.8-1c-2.9,1.6-5.3,3.7-7.2,6.5
								c-1.6,2.2-3.2,4.5-4.6,6.9c-1.4,2.4-3,4.6-4.9,6.7c0.7-0.9,1.3-1.7,2-2.6c-5,5.6-10.4,10.9-14.5,17.3
								c-2.2,3.4-3.9,7.2-6.2,10.5c-0.7,0.9-1.3,1.9-1.6,2.9c-0.7,1-1,2.1-0.8,3.2c0,3.6,1.1,6.6,2.2,10c1.1,2.7,3.2,4.1,6.3,4.2
								c4.1,0.8,8.3,1,12.5,0.7c7.7-1.2,15.5-2.5,23.2-3.7c3-1,4.7-3.2,4.9-6.5c-0.2-6.5-1.2-13-2.9-19.3
								c-1.3-6.4-3.3-12.6-6.1-18.5c-1.7-2.8-4.1-4-7.3-3.6c-6.6,1.3-13.1,2.6-19.7,3.9c4.2,0.3,8.4,0.6,12.6,0.9
								c-3.1-0.5-6.2-1-9.3-1.5c1.8,0.3,3.5,0.6,5.3,0.9c-2.9-0.7-7.7-2.7-10.8-1.4c-1.5,1-2.9,1.9-4.4,2.9
								c-3.7,4.4-5.9,9.7-6.6,15.7c4.8-6.7,9.6-13.3,14.4-20c-2,0.1-4,0.3-6,0.4c1,0.3,2.1,0.6,3.1,1c-1.2-1.2-2.4-2.3-3.5-3.5
								c0.4,0.9,0.9,1.7,1.3,2.6c-0.2-1.2-0.4-2.4-0.7-3.6c-1.1,3.1-2.3,6.3-3.4,9.4c1.9-2,3.8-4,5.7-6c-5-3.6-10-7.2-15-10.8
								c-2.6,3.2-3.9,6.8-4.2,10.7c-0.7,3-0.6,6.1,0.1,9.1c-0.1-1-0.1-2-0.2-3c-0.3,4.2,0.2,8.3,1.5,12.3c1.3,5.9,4.1,11,8.6,15.3
								c1.7,1.7,3.6,2.1,5.7,1.3c3.4-0.3,6.1-1.9,8.3-5c2.6-2.5,5.2-5,7.8-7.4c4.4-4.3,8.3-9.1,11.5-14.3c1.2-4.1,1.9-7.7,1.9-12
								c0-2.6-0.4-5.1-0.9-7.6c-1-2.8-3.1-4.2-6.1-4.2c-4.3,1-7.6,3.6-9.9,7.6c-4.8,6-9.1,12.3-13,18.9
								C848.1,1296.4,846.9,1300.4,847.5,1304.8z"/>
							<path opacity="0.1091" fill="#131313" d="M825.3,1287.6c-2,0.8-4,1.6-6,2.4c7.9-7.1,15.8-14.2,23.6-21.3
								c-6.5,4.2-13,8.5-19.4,12.7c8.8-4.8,17.5-9.5,26.3-14.3c-10.2-0.2-20.4-0.4-30.6-0.6c9.5,2.2,19,4.4,28.6,6.5
								c-4.4-2.1-8.8-4.2-13.2-6.4c3.7,2.4,7.5,4.8,11.2,7.2c-3.3-2.8-6.5-5.5-9.8-8.3c2.9,3.1,5.8,6.1,8.7,9.2
								c-2.5-5.3-5-10.5-7.5-15.8c-0.9-2.9-2.7-4.7-5.6-5.2c-2.6-0.2-5,0.6-7,2.2c-2.6,2-4.6,4.4-5.9,7.3c-0.9,1.7-1.7,3.4-2.4,5.1
								c-1,1.9-1.6,4-2,6.1c-0.5,2-0.8,4-0.9,6c0.4,1.2,0.8,2.4,1.1,3.6c0.8,2.3,2,4.3,3.6,6.1c0.7,0.9,1.4,1.7,2,2.6
								c1.9,1.7,4,2.9,6.4,3.8c1.7,1.3,3.5,1.6,5.4,0.9c2.6-0.2,4.5-1.7,5.8-4.3c3-4,6.1-8.1,9.1-12.1c-3.7,0-7.4-0.1-11.2-0.1
								c2.8,0.4,5.5,1.2,8.1,2.4c-1.1-0.6-2.2-1.1-3.3-1.7c7.2,4.5,14.9,7.8,23.2,9.8c-3.6-2.2-7.2-4.3-10.8-6.5
								c3.7,2.6,7.4,5.2,11.1,7.9c-3.4-2.9-6.8-5.8-10.2-8.7c3.3,3.5,6.6,6.9,9.9,10.4c-3.1-3.7-6.2-7.5-9.3-11.2
								c1.4,2.4,2.7,4.9,4.1,7.3c-1.6-3.9-3.2-7.8-4.8-11.6c0.5,8.2,1.1,16.3,1.6,24.5c0-5.5,0-11,0-16.5c-0.6,3.2-1.3,6.4-1.9,9.5
								c0.6-2.8,1.2-5.5,1.8-8.3c-1.1,3.2-2.3,6.4-3.4,9.5c1-2.8,2.1-5.6,3.1-8.4c-2.7,6.1-5.3,12.2-8,18.3
								c4.1-7.2,8.1-14.5,12.2-21.7c-9.8,7.9-19.7,15.8-29.5,23.7c8.5-4.5,17-9,25.6-13.5c-6.7,2.4-13.5,4.9-20.2,7.3
								c10.1-1.7,20.2-3.4,30.3-5.2c-8.7-3.6-17.4-7.2-26.1-10.9c5.4,5.5,10.8,10.9,16.1,16.4c-1.5-6.4-2.9-12.9-4.4-19.3
								c0,5.3,0.1,10.6,0.1,16c1.3-8,2.6-16.1,3.9-24.1c-1.1,3.6-2.2,7.1-3.3,10.7c1.9-3.7,3.8-7.4,5.7-11c-0.9,1.6-1.8,3.2-2.7,4.8
								c4.1-6.9,8.4-13.7,12.9-20.4c1.7-2.3,1.9-4.6,0.6-7c-1.4-2.2-3.4-3.7-5.8-4.5c-2.7-0.7-4.9,0.1-6.7,2.4c-2.8,2.7-5.1,5.7-7,9
								c-0.4,0.7-0.8,1.3-1.2,2c1.1-1.5,2.2-3,3.3-4.6c-1.7,2.3-3.4,4.5-5,6.8c-2.9,2.9-4.9,6.3-6.1,10.1
								c-2.4,6.2-2.7,12.3-3.5,18.9c0.8-3.3,1.6-6.6,2.4-9.9c-1.2,1.8-2.4,3.7-3.6,5.5c1-0.8,2-1.5,3-2.3c-1.9,0.5-3.6,1.3-5.1,2.5
								c-2.3,1-4.2,2.5-5.7,4.5c-0.4,0.8-0.9,1.5-1.3,2.3c-1.7,1.8-2.4,3.9-2.2,6.3c-0.5,2.3-0.3,4.5,0.7,6.7
								c1.3,2.7,2.5,5.5,3.8,8.2c2.6,3.8,7.1,5,11.4,5.6c1.5,0.2,2.9,0.3,4.4,0.5c2.2,0.5,4.2,0.3,6.2-0.6
								c6.4-1.8,11.9-5.3,16.6-10.6c1.3-1.3,2.6-2.6,3.9-3.9c1.9-2.3,3.7-5.9,4.9-8.6c0.8-2,1.6-4.1,1.8-6.3
								c0.9-2.2,0.7-4.1-0.7-5.9c-1.3-3.1-3.1-5.8-5.5-8.2c-1.7-1.8-3.3-3.6-5-5.4c-2-2.7-4.6-3.8-7.8-3.4c-2.8,0-5.6-0.1-8.5-0.1
								c3.6,0.6,7.1,1.2,10.7,1.9c-1.6-0.9-3.2-1.7-4.9-2.5c-4.4-1.9-9-3.1-13.9-3.5c-2-0.5-3.8-0.3-5.6,0.4
								c-1.6,0.1-2.7,1.1-3.2,2.8c-0.9,1.3-1.7,2.6-2.4,3.9c-2.2,3.7-3.9,7.6-5.2,11.7c4.2-5.6,8.3-11.3,12.5-16.9
								c-1.8,1.3-3.5,2.6-5.3,3.9c1.5-0.8,3.1-1.6,4.6-2.4c-5.5,0.9-11,1.9-16.5,2.8c6.2-0.2,12.4-0.4,18.6-0.6
								c-0.1-0.1-0.3-0.2-0.4-0.3c0.5,1,0.9,2,1.4,3c-0.3-0.9-0.6-1.8-0.9-2.7c-1.3,3.1-2.6,6.2-3.9,9.3c1.4-0.8,2.7-1.7,4.1-2.5
								c-4.9-7.5-9.9-15-14.8-22.6c-0.6,0.5-1.2,1.1-1.8,1.6c-2.3,1.5-3.9,3.5-4.9,5.9c-0.8,1.6-1.6,3.3-2.3,5
								c-1.5,2.8-2.4,5.8-2.7,9c0.3,1.9,0.7,3.9,1,5.8c0.4,1.9,0.8,3.8,1.2,5.7c0.3,5,2.3,8.4,6,10.2c1.6,1.3,3.6,1.8,5.7,1.5
								c3.2,0,5.8-1.2,7.9-3.5c4.3-3.1,8.2-6.6,11.7-10.6c2.6-2.9,5.2-5.8,7.8-8.6c3.6-3.1,5.5-6.8,5.7-11.3c-0.5-4.4-1-8.6-2-12.9
								c-1-2.8-3.1-4.2-6.1-4.2c-4.3,1-7.6,3.5-9.9,7.5c-4.8,5.9-9.1,12.2-12.9,18.7C825.9,1279.3,824.7,1283.2,825.3,1287.6z"/>
							<path opacity="0.1091" fill="#131313" d="M828.3,1248.9c-2.5-5.5-4.9-11-7.4-16.5c0.2-1.2,0.5-2.4,0.7-3.7
								c-3.1,5.3-4.6,11-4.8,17.1c-0.4,5.2,1.2,9.6,5,13.1c1.4,1.4,3.1,2.5,4.8,3.4c2.7,1.4,5.7,1.7,8.8,0.9
								c2.5-1.4,4.4-3.4,5.8-5.9c1.7-2.3,2.7-4.9,3-7.7c-0.3-2.4-0.6-4.8-0.9-7.2c-3.5,3.6-7,7.2-10.5,10.8c5,0.2,9.9,0,14.8-0.6
								c4.9-0.1,9.8,0.2,14.7,0.7c-1.6-0.1-3.1-0.3-4.7-0.4c3,0.5,5.9,1,8.9,1.5c-3-0.9-6-1.9-8.9-2.8c2.2,1.2,4.5,2.3,6.7,3.5
								c-1.3-0.7-2.6-1.5-3.9-2.2c2.4,1.9,4.8,3.8,7.3,5.7c-1-1.1-1.9-2.1-2.9-3.2c1.3,1.6,2.6,3.2,3.9,4.8c-1-2.3-2-4.6-2.9-6.8
								c-0.3,1.4-0.7,2.7-1,4.1c-0.7,2-1.7,4-2.9,5.7c-1.3,2.1-2.3,3.1-4.3,4.5c3.3,0.1,6.7,0.2,10,0.3c-11.4-4-22.8-8-34.2-12
								c9.6,7.6,19.3,15.3,28.9,22.9c-1.6-12.3-3.1-24.5-4.7-36.8c-1.7,8.1-3.5,16.3-5.2,24.4c2.9-8.3,5.7-16.6,8.6-24.9
								c-1.1,2.8-2.1,5.7-3.2,8.5c2.2-4.8,4.5-9.5,6.7-14.3c-1.4,2.7-2.8,5.3-4.1,8c2.6-4.5,5.1-9,7.7-13.5c0.9-1.9,1.8-3.9,2.8-5.8
								c-0.1-1.3-0.2-2.7-0.3-4c-0.8-1-1.6-2-2.4-3c-1.8-1.4-3.6-1.8-5.5-0.9c-1.8,0.1-3.3,1-4.3,2.6c-2,1.6-3.8,3.5-5.2,5.7
								c-2,2.2-3.8,4.7-5.4,7.2c-2.2,2.9-4,6.1-5.3,9.5c-1.6,7-3.2,14-4.7,21c-1.4,6.6,0,12.7,4.2,18.4c1.2-11.7,2.3-23.3,3.5-35
								c-1.7,1.1-3.4,2.3-5.1,3.4c1.1-0.5,2.3-1.1,3.4-1.6c-4.2,1.7-8.3,3.6-12.4,5.7c-4.5,2.9-7.3,7-8.4,12.2c-1,7.2,2.1,14.8,7,20
								c1.9,2.3,4.3,3.8,7.3,4.5c2.6,1.1,5.2,1.8,8,2c2.7,0.4,5.3,0.4,8,0.1c2.4-0.8,4.6-1.9,6.7-3.2c5.7-3.2,11.6-7.4,15.6-12.6
								c2.6-4.1,4.8-8.4,6.6-12.8c2.2-2.9,2.7-5.9,1.3-9c-1.1-2-2.2-3.9-3.5-5.7c-1.4-2.3-3.1-4.3-5.3-6c-2.3-2.2-5-3.8-8.1-4.6
								c-3.6-1-7.3-1.5-11.1-1.4c1.6,0.1,3.1,0.3,4.7,0.4c-6.5-0.5-13-1-19.6-0.1c-1.5,0.2-2.9,0.5-4.4,0.7
								c-3.3,1.1-5.8,3.4-7.4,6.7c4.7,1.8,9.3,3.6,14,5.5c0-0.5,0-1,0.1-1.5c-3.4,2.6-6.8,5.3-10.2,7.9c2.2,0.4,4.5,0.8,6.7,1.1
								c1.3,0,2.6-0.1,3.8-0.1c1.1-0.7,2.1-1.4,3.2-2.1c0.5-1.3,1-2.6,1.5-3.9c-0.2-3.9-0.8-7.7-2.1-11.4
								c-1.5-5.7-3.3-11.4-6.1-16.7c-0.4-2-1.6-3.4-3.3-4.2c-1.5-1.5-3.4-1.9-5.6-1.1c-5.8,0.6-10.2,3.5-13.1,8.6
								c-1.1,2.1-2.1,4.3-3,6.5c-1.8,5-2.6,10.2-1.3,15.4c1,2.7,2.1,5.3,3.3,7.9c0.9,1.9,1.8,3.8,2.8,5.6c1.4,2.2,3.3,3.8,5.6,4.9
								c2.4,0.5,4.8,0.7,7.2,0.7c3.5-1.7,6.6-3.9,9.3-6.8c2.9-2.6,5.3-5.7,7-9.2c0.3-4.1,0.6-8.1,0.9-12.2c-0.3-2.5-1.5-4.4-3.6-5.7
								c-2-1.5-4.2-2-6.7-1.4c-2.9,1.9-5.2,4.3-7,7.3C830.5,1242.5,829,1245.5,828.3,1248.9z"/>
							<path opacity="0.1091" fill="#131313" d="M846.2,1275.9c0.2,1.2,0.3,2.5,0.2,3.7c0.1,1.1-0.1,2.2-0.6,3.2c2.3-4,4.6-8,6.9-12
								c-2.2,2.8-4.4,5.6-6.5,8.4c5.3-4.8,10.7-9.6,16-14.4c-4.4,2.6-8.7,5.2-13.1,7.8c3.9-1.6,7.9-3.1,11.8-4.7
								c-3.9-0.6-7.8-1.2-11.7-1.8c3.8,1,7.6,1.9,11.4,2.9c-3.4-2.4-6.8-4.7-10.3-7.1c2.7,3.3,5.5,6.6,8.2,10
								c1.1-4.7,0.6-9.4-1.3-14.2c-0.4-2.9-1.3-5.7-2.8-8.2c-0.6-2-1.9-3.2-4.1-3.6c-1.3,0-2.5,0.1-3.8,0.1
								c-2.6,0.4-4.4,1.8-5.5,4.1c-0.9,1.4-1.9,2.8-2.8,4.2c-0.9,1.6-1.6,3.2-1.9,5c-0.8,2.8-1.1,5.8-1.2,8.8c0,1.2,0,2.5,0,3.7
								c0.4,1.2,0.8,2.4,1.1,3.6c0.6,2.3,1.7,4.4,3.2,6.3c1.6,2.4,3.7,4.3,6.3,5.7c2.2,1.3,4.5,1.5,6.8,0.5
								c4.7-3.9,9.4-7.9,14.1-11.8c-3.2,0.6-6.3,1.3-9.5,1.9c1.2,0,2.5,0.1,3.7,0.1c-2.1-0.1-4.3-0.3-6.4-0.4
								c0.9,0.1,1.7,0.3,2.6,0.4c-1.6-0.5-3.2-1-4.8-1.5c4.3,1.7,8.6,3.1,13.1,4c7.1,1.7,14.2,3.4,21.2,5c-6.6-3-13.3-6-19.9-9
								c6.8,4.4,13.5,8.8,20.3,13.2c-5.4-5.4-10.8-10.8-16.3-16.1c2,2.9,4,5.7,6,8.6c-1.3-2.3-2.6-4.6-3.9-7c1.3,3,2.7,5.9,4,8.9
								c-0.8-2.4-1.6-4.9-2.4-7.3c1.3,5.6,2.7,11.2,4,16.7c-0.8-7-1.7-14-2.5-20.9c-0.2,5.3-0.5,10.7-0.7,16
								c0.1-2.5,0.2-4.9,0.3-7.4c-0.6,3.3-1.3,6.5-1.9,9.8c0.5-2.3,1-4.6,1.6-7c-1,3.1-2.1,6.2-3.1,9.3c0.9-2.2,1.8-4.4,2.7-6.7
								c-1.5,2.9-3,5.9-4.5,8.8c1.2-2,2.4-4,3.6-6.1c-1.8,2.7-3.7,5.4-5.5,8.1c2.8-3,5.7-5.9,8.5-8.9c-7.3,3.9-14.5,7.7-21.8,11.6
								c4.5-1.8,9-3.7,13.5-5.5c-2.7,0.5-5.5,1-8.2,1.5c3.9-0.4,7.8-0.8,11.7-1.2c-4.1-1.4-8.2-2.8-12.4-4.2c3.5,1.8,7,3.5,10.4,5.3
								c-3.1-2.5-6.3-4.9-9.4-7.4c2.8,3.7,5.7,7.4,8.5,11.1c0.2-6.5,0.5-13,0.7-19.4c-0.7,4.4-1.4,8.9-2.1,13.3
								c1.5-5,3.1-10,4.6-15.1c-0.8,2.1-1.7,4.3-2.5,6.4c2.3-4.3,4.5-8.7,6.8-13c-1.1,2.1-2.2,4.2-3.3,6.3
								c3.9-7.9,8.3-15.6,13.1-23.1c0.8-1.7,1.6-3.4,2.3-5.1c-0.1-1.2-0.3-2.5-0.4-3.7c-0.8-1-1.6-2-2.4-2.9
								c-1.6-1.5-3.4-1.9-5.3-1.2c-1.7-0.1-3,0.6-4,2.1c-1.8,1.3-3.4,2.9-4.7,4.8c-1.8,1.9-3.4,4-4.8,6.3c-2,2.5-3.5,5.3-4.6,8.4
								c-0.6,2.2-1.2,4.4-1.8,6.5c3.1-6,6.2-12,9.2-17.9c-1.8,3-3.8,5.9-6.1,8.7c-1.1,1.2-2,2.6-2.8,4c-1.8,2.6-3.1,5.3-4.1,8.3
								c-1.5,1.6-2.9,3.3-4.4,4.9c-1.9,2.1-4,4.2-5.9,6.3c-1.4,1-2.4,2.4-2.9,4c-1.4,2.6-1.8,5.4-1.1,8.5c0.5,2,1.1,3.9,1.6,5.9
								c1.7,2.5,3.5,5,5.2,7.5c1,1.7,2.5,2.8,4.2,3.3c1.2,0.6,2.4,1.1,3.7,1.2c3.5,0.8,7.4,1.1,11,0.6c4.3-2.2,8.6-4.4,13-6.6
								c5.1-7.9,10-16,14.6-24.3c0.6-2.5,0.1-4.6-1.5-6.5c-1.8-3.4-4.1-6.5-6.9-9.2c-2-2-4-4-6-6c-2.3-2.9-5.2-4.2-8.7-4
								c-5,1.7-9.8,3.6-14.8,5.4c9.1,1.7,18.2,3.4,27.2,5.1c-2-1.7-4.2-3-6.7-4c-2.2-1.2-4.6-2.1-7.2-2.6c-1.5-0.4-3-0.8-4.6-1.1
								c-3.5-0.3-6.9-0.4-10.4-0.5c1.9,0.1,3.7,0.2,5.6,0.3c-3.1-0.5-6.3-0.7-9.5-0.6c-1.8,0.2-3.2,1.1-4.2,2.7
								c-1.1,1.2-2.2,2.4-3.3,3.6c-1.2,3.1-2.2,6.3-3,9.5c4.1-4.9,8.1-9.8,12.2-14.7c-1.3,0.2-2.5,0.4-3.8,0.6
								c2.1,0.9,4.2,1.7,6.2,2.6c-1.1-1.2-2.3-2.3-3.4-3.5c0.7,0.8,1.4,1.5,2.1,2.3c-0.7-0.8-1.4-1.6-2.1-2.3
								c0.2,2.2,0.4,4.4,0.6,6.6c0.2-1.9,0.4-3.9,0.6-5.8c-1.6,3-3.1,5.9-4.7,8.9c0.7-0.6,1.4-1.1,2.1-1.7c-2.2,0.4-4.4,0.9-6.5,1.3
								c0.9,0.3,1.8,0.6,2.7,0.9c-1.8-8.4-3.7-16.9-5.5-25.3c-3,2.4-5.1,5.3-6.5,8.7c-1.3,1.9-2,3.9-2.1,6.2
								c-0.8,3.2-1.1,6.5-0.9,9.9c0.1,1.2,0.2,2.5,0.4,3.7c0.8,1.7,1.7,3.5,2.5,5.2c0.6,2.9,1.9,5.4,3.9,7.4c0.8,1.1,1.7,2,2.8,2.7
								c1.3,1.5,3.1,2,5.2,1.5c1.2-0.1,2.4-0.1,3.6-0.2c1.6-1,3.2-2.1,4.8-3.1c4.2-2.9,7.1-6.6,10.1-10.5c2.1-2.5,3.8-5.3,5.1-8.3
								c0.1-0.9,0.2-1.8,0.4-2.7c0.2-2.6,0-5.1-0.3-7.7c0.2-2.8-0.8-5-3.1-6.5c-2.3-1.6-4.7-1.8-7.2-0.6c-3.6,2.9-6.7,6.4-9.1,10.3
								C849.8,1267.4,847.7,1271.5,846.2,1275.9z"/>
							<path opacity="0.1091" fill="#131313" d="M847.1,1246.8c-0.1,2.3-0.5,4.5-1,6.7c0.3-1.1,0.5-2.1,0.8-3.2
								c-0.3,1-0.6,1.9-0.9,2.9c1.8-2.4,3.6-4.7,5.5-7.1c-1.4,1.4-2.7,2.9-4.1,4.3c2.1-1.6,4.2-3.3,6.3-4.9
								c-1.4,0.7-2.7,1.4-4.1,2.2c3.8,1.4,7.6,2.9,11.5,4.3c-1.1-1.9-2.2-3.8-3.3-5.8c-0.5,3.3-1,6.7-1.4,10
								c1.2-1.5,2.4-2.9,3.6-4.4c2.3-2.9,2.8-6.3,1.7-10c-0.8-6-2-11.9-3.9-17.6c-0.5-3.9-2.5-6.6-5.8-8.3c-2.7-0.8-5.1-0.3-7.2,1.6
								c-3.1,3.2-5.4,6.9-6.8,11c-0.9,2.5-1.8,5-2.7,7.5c-0.8,3.4-1.1,7.5-0.3,10.9c0.9,4.1,2.8,7.3,5.8,10.4
								c0.9,0.8,1.8,1.7,2.7,2.5c1.6,0.8,3.3,1.4,5.1,1.8c1,0,1.9,0.1,2.9,0.1c1.6-0.7,2.7-1.9,3.5-3.3c1.6-2.2,3.4-5.3,2.9-8.1
								c-0.4-2.5-0.9-5.1-1.3-7.6c-1.9,4.2-3.8,8.4-5.7,12.6c2.6-0.6,5.3-1.3,7.9-1.9c-1.2,0.3-2.3,0.5-3.5,0.8
								c2.7-0.5,5.4-0.9,8.1-1.2c-2.1,0.1-4.1,0.2-6.2,0.2c3.5-0.1,7-0.2,10.5-0.3c-2.2-0.1-4.3-0.2-6.5-0.3
								c3.4,0.2,6.9,0.4,10.3,0.6c-2.2-0.3-4.4-0.6-6.5-0.9c6.4,0.6,12.7,1.8,18.8,3.8c-2-0.6-4-1.1-6-1.7c2.6,1,5.2,2,7.8,3.1
								c-1.8-0.8-3.6-1.6-5.3-2.5c2.2,1.2,4.4,2.5,6.6,3.7c-1.5-1.1-3.1-2.2-4.6-3.2c1.8,1.5,3.6,2.9,5.4,4.4
								c-2.7-4.1-5.4-8.2-8.1-12.3c0.6,2.8,1.1,5.6,1.7,8.4c-0.2-1.2-0.4-2.4-0.6-3.6c0.1,2.7,0.2,5.3,0.2,8c0-1.1,0.1-2.2,0.1-3.4
								c-0.3,2.5-0.6,5-0.9,7.5c0.2-1.1,0.5-2.1,0.7-3.2c-2.2,2.6-4.5,5-7.2,7.1c-0.9,0.8-1.8,1.5-2.9,2c-1.3,0.9-2.7,1.4-4.3,1.6
								c2.6,3.3,5.1,6.6,7.7,9.9c-0.3-9.6-0.5-19.1-0.8-28.7c-0.6,6.5-1.2,12.9-1.9,19.4c1.4-8.6,2.9-17.1,4.3-25.7
								c-1.8,6.5-3.6,13-5.4,19.4c3.2-8.7,6.3-17.4,9.5-26.1c-1.2,3.2-2.4,6.5-3.6,9.7c2.3-5.1,4.7-10.2,7-15.3
								c-1.6,3.2-3.3,6.4-4.9,9.6c2.7-4.9,5.5-9.9,8.2-14.8c1.2-2.3,2.4-4.7,3.6-7c0-1.5,0.1-3,0.1-4.6c-0.7-1.1-1.5-2.1-2.2-3.2
								c-2-1.4-3.9-1.6-5.9-0.4c-2.1,0.4-3.8,1.6-4.9,3.5c-2.4,2.1-4.4,4.5-6.2,7.2c-2.4,2.8-4.6,5.8-6.5,9
								c-2.7,3.6-4.9,7.5-6.7,11.7c-1,1.4-2.1,2.7-3.1,4.1c0.8-1,1.5-2.1,2.3-3.1c-1.9,1.5-3.9,3-5.8,4.4c-1.6,0.8-2.9,2-4,3.4
								c-1.6,1.8-2.9,3.4-3.8,5.6c-1.5,2.8-2.1,5.9-1.8,9.3c0.6,2.4,1.2,4.8,1.8,7.2c1.2,3,3,6,5.1,8.4c1.5,1.8,3.5,3.1,5.7,3.9
								c1.7,1.3,3.5,2,5.6,2.1c3,0.5,6.8,1,9.8,0.1c1.8-0.8,3.6-1.6,5.4-2.4c1.7-0.9,3.4-1.8,5.1-2.7c1.8-1.3,3.7-2.6,5.5-4
								c2.7-1.9,5.2-4,7.5-6.2c2.1-1.2,3.3-3.1,3.6-5.6c0.9-2.2,1.5-4.4,2.1-6.6c0.9-1.6,1.1-3.3,0.6-5c-0.2-2.2-1-4.3-2.4-6.2
								c-0.9-1.3-1.9-2.7-2.8-4c-1.8-2.1-3.6-4.3-5.4-6.4c-1.4-0.7-2.9-1.4-4.3-2.1c-3.1-1.1-6.4-1.8-9.7-2.1
								c-3.4-0.5-6.5-0.8-9.9-0.6c-3.8,0.1-7.5,0.6-11.2,1.3c-1.2,0.3-2.3,0.5-3.5,0.8c-0.8,0.5-1.6,1-2.3,1.5
								c-4.8,3.4-5.7,7.1-7.1,12.1c4.2-2.3,8.3-4.6,12.5-7c-1.9-0.8-3.8-1.6-5.7-2.5c2.5,2.2,4.9,4.5,7.4,6.7
								c-1.1-2-2.2-4.1-3.2-6.1c0.7,3.3,1.4,6.5,2.1,9.8c0.9-2.9,1.2-5.9,1.1-9c-3,2.8-5.9,5.5-8.9,8.3c2.2,0.2,4.4,0.3,6.6,0.5
								c1.3,0.1,2.5,0.1,3.8,0.2c2.2-0.4,3.7-1.6,4.3-3.6c1.1-1.5,1.4-3.1,0.9-5c0.1-4.1-0.5-8.1-1.6-12.1c-0.8-2.9-1.6-5.8-2.3-8.7
								c-0.9-2-1.7-3.9-2.6-5.9c-1.3-1.9-3-2.8-5.1-2.5c-2-0.4-3.7,0.1-5.3,1.7c-2.6,0.9-4.8,2.5-6.4,4.8c-3.5,4.3-5.6,9.2-6.4,14.7
								c-0.9,3.7-0.8,7.5,0,11.2c1.5,4.1,3.3,8.1,5.5,11.8c-0.5-1.1-1-2.3-1.5-3.4c0.9,2.7,2.3,5.2,4.2,7.3c0.9,0.7,1.9,1.4,2.8,2.1
								c3.8,2.1,8.2,0.6,11.7-1.3c4.1-2.2,6.7-6.1,8.4-10.3c0.9-1.8,1.5-3.7,1.8-5.7c0.3-2.1,0.4-4.3,0.5-6.4
								c-0.7-2.2-2.1-3.9-4.1-5.1c-1.8-1.5-3.9-2.1-6.2-2c-2.3,1.1-4.1,2.7-5.4,4.9C848.2,1242,847.3,1244.3,847.1,1246.8z"/>
							<path opacity="0.1091" fill="#131313" d="M810.6,1275.5c0.1,0.8,0.3,1.6,0.4,2.4c0-1.5,0-2.9-0.1-4.4c0,2.2,0,4.5,0,6.7
								c1.1-3.8,2.2-7.5,3.3-11.3c-1.7,3.3-3.3,6.7-5,10c6.1-7.9,12.2-15.9,18.3-23.8c-6.1,5.7-12.1,11.4-18.2,17.2
								c10.2-6.8,20.4-13.6,30.6-20.4c-8.2-2-16.4-4-24.6-6c-1.9-0.8-3.7-0.7-5.3,0.4c-1.9,0.6-3.3,1.8-4.1,3.7
								c-3,3.4-5.1,7.3-6.3,11.7c-1.7,3.5-2,7-1,10.4c0.5,3.2,2,6,4.6,8.4c3.5,4,7.9,6,13.2,6c2.3,0.4,4.1-0.3,5.5-2.1
								c2.6-2,4.8-4.3,6.6-7.1c1.3-2,2.6-4,3.9-6c2.1-2.6,2.5-5.3,1.3-8.2c-0.4-0.5-0.8-1.1-1.3-1.6c-4.3,2.2-8.5,4.4-12.8,6.6
								c5.9,1.4,11.8,2.8,17.7,4.1c-1.2-0.3-2.3-0.6-3.5-0.9c2.6,0.8,5.2,1.6,7.8,2.4c-1.2-0.4-2.3-0.8-3.5-1.2
								c2.5,1,4.9,1.9,7.4,2.9c-1.2-0.5-2.4-1.1-3.6-1.6c1.9,1.1,3.8,2.2,5.7,3.3c-1.1-0.7-2.3-1.4-3.4-2c1.7,1.3,3.3,2.6,5,4
								c-1.1-0.8-2.1-1.7-3.2-2.5c1.9,1.9,3.5,4.2,4.7,6.7c-0.8-3.4-1.6-6.8-2.4-10.2c-0.6,1.1-1.1,2.3-1.7,3.4
								c0.5-0.9,1-1.8,1.5-2.7c-0.3,0.2-0.6,0.5-0.8,0.7c1.1-0.6,2.2-1.1,3.3-1.7c-2.7,1.3-5.6,2.2-7.1,5c2.1-0.9,4.2-1.7,6.4-2.6
								c-12.5,1.9-25.1,3.9-37.6,5.8c14.1,3.9,28.1,7.8,42.2,11.7c-4.8-10.5-9.6-21-14.5-31.5c-1.2,5.5-2.3,10.9-3.5,16.4
								c1.9-4.3,3.8-8.6,5.7-12.9c-0.9,1.7-1.9,3.4-2.8,5.1c4.3-7.5,8.9-14.8,13.8-21.9c2.9-4.5,0.4-8.3-3.7-10.5
								c-1.4-1.1-3-1.4-4.8-1c-2.9,1.6-5.2,3.7-7.1,6.4c-1.6,2.4-3.2,4.9-4.9,7.3c1.1-1.5,2.2-3.1,3.3-4.6c-3,3.6-5.6,7.5-7.9,11.7
								c-2.2,3.1-3.7,6.4-4.7,10.1c-0.4,2-0.9,4-1.3,6.1c-0.4,6,0.6,11.8,3,17.5c1.2-6.8,2.4-13.6,3.5-20.4
								c-1.4,0.9-2.8,1.7-4.3,2.6c1.2-0.4,2.4-0.8,3.6-1.2c-2.3,0.4-4.7,0.9-7,1.3c-2.5,1.3-5,2.6-7.5,3.9c-2.5,1.8-4.9,4.7-6.5,7.3
								c-1.2,1.5-1.7,3.2-1.4,5.2c-0.6,2.2-0.5,4.3,0.3,6.4c0.2,1.8,0.8,3.4,1.7,4.9c0.6,1.4,1.3,2.6,2.2,3.8c1,1.7,2.3,3.2,3.9,4.5
								c1.6,0.7,3.2,1.4,4.9,2.1c1.7,0.3,3.4,0.7,5.2,1c1.6,0.1,3.2,0.3,4.7,0.4c2.8,0.1,5.5-0.2,8.1-1.1c1.6-0.7,3.2-1.5,4.8-2.2
								c2.4-1.4,4.7-2.8,6.9-4.3c3.3-2.1,6.3-4.4,8.9-7.3c2.7-3,5.1-6.2,7-9.7c1-1.4,1.6-3,2.1-4.7c1.1-1.8,1-3.7-0.3-5.6
								c-0.5-1.8-1.1-3.5-2.1-5.1c-2.4-4.2-5.7-7.8-9.7-10.5c-2.6-1.4-5.4-2.6-8.2-3.5c-1.5-0.6-3-1.1-4.5-1.4c-1.5-0.4-3-0.8-4.5-1
								c-2.7-0.7-5.4-1-8.2-1.1c-2.1-0.4-4,0-5.7,1.1c-1.7,2.8-3.5,5.6-5.2,8.4c4.4-1.2,8.9-2.4,13.3-3.6c-2.9-0.5-5.8-0.8-8.7-1
								c-7-0.7-13.8,0.8-20.4,4.4c9.7,1.1,19.5,2.2,29.2,3.2c-0.9-0.9-1.7-1.9-2.6-2.8c-1.3,4-2.6,7.9-3.9,11.9
								c1.9-0.3,3.7-0.6,5.6-0.9c1.2-0.5,2.3-1,3.5-1.6c0.7-1,1.4-2.1,2.2-3.1c0-1.3,0-2.7,0-4c-1.5-3.4-3.5-6.5-5.9-9.3
								c-3.4-4.5-7-8.9-11.4-12.4c-1.1-1.6-2.6-2.4-4.5-2.4c-2-0.7-3.8-0.3-5.5,1.2c-0.9,0.5-1.7,1.1-2.6,1.6
								c-2.1,1.1-3.8,2.8-4.9,4.9c-1.4,1.6-2.5,3.4-3.2,5.4c-1.6,2.8-2.6,5.9-3,9.2c0.5,2.3,0.9,4.6,1.4,6.9
								c0.6,2.1,1.2,4.2,1.7,6.3c1.1,4.7,3.5,8,7.3,9.8c1.8,1.4,3.9,1.8,6.3,1.2c3.1,0,5.7-1.1,7.8-3.4c4.3-3,8.1-6.4,11.6-10.4
								c2.6-2.8,5.2-5.7,7.8-8.5c3.6-3,5.4-6.7,5.6-11.2c-1.1-2.2-1.8-4.4-2.1-6.8c-0.3-1.5-0.7-2.9-1.1-4.4
								c-1.1-2.7-3.1-4.1-6.1-4.2c-4.1,0.8-7.2,3-9.4,6.8c-4.5,5.5-8.5,11.3-12,17.4C810.9,1267.7,809.9,1271.4,810.6,1275.5z"/>
							<path opacity="0.1091" fill="#131313" d="M830,1310c0.4,1.5,0.5,3,0.4,4.5c0.2-1.8,0.4-3.5,0.6-5.3c-0.2,0.6-0.4,1.1-0.5,1.7
								c7-10.2,14.1-20.5,21.1-30.7c-9,10-17.9,20.1-26.9,30.1c11.1-9.5,22.3-18.9,33.4-28.4c-9.8,4.9-19.6,9.8-29.3,14.7
								c8.5-3,17-6,25.4-9c-8.3,0.7-16.6,1.5-24.9,2.2c7.5,0.5,15.1,0.9,22.6,1.4c-2.9-0.9-5.7-1.8-8.6-2.8c1.4,0.7,2.9,1.5,4.3,2.2
								c-2-1.2-4-2.4-6.1-3.6c1,0.9,2,1.9,3,2.8c-2-2.6-3.9-5.3-5.9-7.9c1,2.6,2,5.1,2.9,7.7c-0.2-2-0.4-4.1-0.5-6.1
								c-0.1-2.7-0.7-5.4-1.8-7.9c-0.5-1.8-1.6-2.7-3.3-2.8c-1.7-0.6-3.4-0.6-5.1,0c-2.3,0.4-4.2,1.4-5.7,3.2
								c-2,2.8-3.2,5.8-3.7,9.2c0.4-1.2,0.9-2.3,1.3-3.5c-2.3,3.7-3.9,7.8-4.6,12.2c0.1,2.5,0.4,4.9,1,7.3c0.8,2.5,1.9,4.9,3.2,7.2
								c2,3.6,4.9,6.5,8.6,8.7c2.5,1.4,5,1.3,7.4-0.4c6.7-5.4,13.4-10.8,20.1-16.2c-5.3-0.8-10.6-1.6-16-2.3
								c1.4,0.9,2.7,2.1,3.7,3.4c7,3.7,14.4,6.7,22.1,9.1c-6-4-12.1-8-18.1-11.9c6.3,8.7,12.6,17.3,19,26
								c-3.8-8.4-7.6-16.8-11.4-25.1c0.8,3.5,1.5,7,2.3,10.5c-0.8-6.2-1.5-12.3-2.3-18.5c-0.1,10.7-0.2,21.5-0.3,32.2
								c0.9-7.2,1.8-14.4,2.6-21.7c-1.8,7.6-3.6,15.3-5.3,22.9c2-7.2,4.1-14.5,6.1-21.7c-2.8,7.7-5.7,15.4-8.5,23.1
								c3.1-6.8,6.2-13.6,9.3-20.4c-3.9,7.2-7.8,14.4-11.6,21.7c5.9-8.4,11.8-16.8,17.7-25.3c-7.5,8-14.9,16.1-22.4,24.1
								c5.4-4.8,10.7-9.7,16.1-14.5c-6.1,4.6-12.1,9.1-18.2,13.7c5.9-4,11.9-8.1,17.8-12.1c-6.4,3.6-12.8,7.2-19.3,10.8
								c8.8-3.9,17.6-7.8,26.4-11.8c-9.7,1.5-19.3,3-29,4.6c7.2,0.6,14.4,1.1,21.6,1.7c-4.4-1.1-8.8-2.2-13.2-3.3
								c3.9,1.5,7.8,3.1,11.7,4.6c-4-2.2-8.1-4.4-12.1-6.6c2.7,2.2,5.4,4.4,8.1,6.6c-1-2.1-2.1-4.1-3.1-6.2c0.4,1.1,0.9,2.1,1.3,3.2
								c-0.2-1.7-0.4-3.5-0.6-5.2c0,0.9,0,1.9-0.1,2.8c0.3-2.7,0.6-5.4,1.1-8c-0.4,1.2-0.8,2.5-1.2,3.7c3.1-4.5,6.3-9,9.4-13.5
								c-0.6,0.7-1.2,1.4-1.8,2.1c4.2-3.1,7.4-6.9,9.6-11.4c1.4-2.8,3.1-5.4,5.1-7.8c1.7-1.9,2.7-4.2,3-6.7c-0.6-2.2-1.9-4-3.8-5.3
								c-1.8-1.5-3.8-2.3-6.1-2.3c-2.3,1-4.2,2.5-5.6,4.7c-1.9,2.4-3.6,4.9-5.2,7.5c-1.5,2.6-3.5,4.8-6,6.6
								c-0.6,0.7-1.2,1.4-1.8,2.1c-6,5.4-9.9,11.9-11.8,19.4c0.3-0.8,0.7-1.7,1-2.5c-0.9,3.1-2.2,5.9-4.1,8.5c0.8-1,1.6-1.9,2.3-2.9
								c-0.8,0.7-1.7,1.5-2.5,2.2c-1.6,1.2-2.6,2.7-3.1,4.6c-0.9,1.6-0.9,3.3-0.2,5c0.8,10.7,5.3,17.9,13.4,21.7
								c2.1,0.5,4.3,0.9,6.5,1.1c2.3,0.7,4.4,0.1,6.4-1.6c8.4-9.3,16.4-19,24-29.1c1.8-2.3,2.1-4.7,0.9-7.3
								c-2.9-4.1-6.4-7.6-10.5-10.6c-3.8-3.3-8-6-12.6-8c-2.8-0.6-5.2,0.2-7,2.4c-1.5,1.4-3.1,2.7-4.6,4.1
								c8.8,1.3,17.5,2.5,26.3,3.8c-3.4-2.9-6.7-5.6-10.8-7.7c-1.3-0.6-2.5-1.3-3.8-1.9c-3-1.7-6.2-2.7-9.7-3.1
								c1.3,0.4,2.6,0.8,3.9,1.2c-1.4-0.5-2.8-1-4.3-1.5c-1.1-0.6-2.2-0.6-3.4-0.2c-2.3,0.9-4.6,1.8-6.9,2.7c-4.8,4.9-7.9,11-9.4,18
								c5.5-7.1,11-14.3,16.5-21.4c-1.9,0.6-3.8,1.3-5.6,1.9c1.2-0.1,2.5-0.1,3.7-0.2c-1.5,0.1-3.1,0.2-4.6,0.2
								c1.1,0.3,2.2,0.6,3.3,0.9c-1-0.3-2.1-0.6-3.1-0.9c0.9,0.6,1.8,1.3,2.7,1.9c-1.3-1.1-2.5-2.3-3.8-3.4c0.5,0.9,0.9,1.8,1.4,2.7
								c-0.4-1.1-0.7-2.2-1.1-3.4c-1.2,3.1-2.3,6.3-3.5,9.4c1.7-2,3.4-4,5.1-6c-5.1-3.5-10.2-7-15.3-10.5c-1.6,2.8-2.7,5.8-3.2,8.9
								c-0.2,1.7-0.4,3.4-0.6,5c0.3-1.2,0.7-2.4,1-3.6c-0.5,1.7-0.9,3.3-1.4,5c-1.4,2.8-1.7,5.6-1.1,8.5c-0.1,1.6,0.3,3,1.2,4.3
								c2.8,5.6,5.8,11.1,9.1,16.5c3,1.8,5.9,1.5,8.7-0.7c4.2-3.5,8-7.4,11.5-11.6c4.6-5,8.8-10.3,12.8-15.8c3.8-3.8,5.8-8.2,6-13.3
								c0-2.1-0.1-4.2-0.4-6.3c-0.3-1.6-0.6-3.1-1.1-4.7c-0.9-3.1-2.9-4.5-6.1-4.2c-4.9,1.7-8.6,4.9-11.2,9.6
								c-5.6,7.2-10.8,14.7-15.5,22.6C831.5,1300.2,829.9,1304.8,830,1310z"/>
							<path opacity="0.1091" fill="#131313" d="M861.7,1270c-0.4,2.6-1,5.2-1.7,7.7c0.5-1.5,1-2.9,1.6-4.4c-1,2.6-2,5.1-2.9,7.7
								c3.1-4.8,6.3-9.7,9.4-14.5c-3.3,3.8-6.6,7.7-10,11.5c2.9-2.9,5.9-5.8,8.8-8.8c-2.1,1.4-4.1,2.8-6.2,4.2
								c3.1-1.7,6.2-3.4,9.3-5.1c-0.5,0.1-0.9,0.1-1.4,0.2c1,0.4,1.9,0.9,2.9,1.3c-1.3-0.7-2.7-1.3-4-2c0.8,0.6,1.6,1.3,2.5,1.9
								c-1.4-3.1-3.6-5.5-6.5-7.3c1.2,1.8,2.4,3.6,3.5,5.3c-0.1-1.2-0.3-2.5-0.4-3.7c-0.5,2.1-1.1,4.3-1.6,6.4
								c1.9-1.8,3.4-3.9,4.5-6.3c0.7-3.6,0.7-7.1-0.1-10.7c-0.4-3.6-1.5-7-3.4-10.1c-3.5-3.4-10.1-2-12.4,2.1
								c-1.4,2.6-2.9,5.2-4.3,7.8c-1.5,3.7-1.8,7.4-1,11.2c0.1,2.6,0.7,5.2,1.7,7.6c0.6,3.8,2.4,7,5.3,9.6c1.9,1.8,4.1,2.9,6.6,3.3
								c2,0.3,3.5-0.3,4.5-1.8c1.8-1.6,3.2-3.6,4.1-5.9c1.6-2.7,2.4-5.6,2.5-8.7c-0.6-1.5-1.2-3.1-1.7-4.6
								c-2.2,4.1-4.4,8.1-6.7,12.2c2.1-0.2,4.2-0.4,6.4-0.6c-1.3,0.1-2.7,0.1-4,0.2c1.1,0,2.3-0.1,3.4-0.1c-2.2,0-4.3,0-6.5,0
								c4.2,0.1,8.3,0.1,12.5,0.2c-2.8-0.2-5.6-0.4-8.3-0.6c0.5,0.1,1.1,0.2,1.6,0.3c-2.1-0.6-4.1-1.2-6.2-1.8
								c2.5,0.8,5,1.5,7.6,2.3c-2.2-0.8-4.3-1.6-6.5-2.4c11.9,4.4,23.7,8.8,35.6,13.2c-6.2-5.8-12.4-11.7-18.6-17.5
								c4.2,5.5,8.4,10.9,12.6,16.4c-3.2-4.7-6.4-9.5-9.5-14.2c2.9,5.6,5.8,11.1,8.7,16.7c-2.1-4.9-4.1-9.8-6.2-14.7
								c1.7,5.7,3.5,11.3,5.2,17c-0.9-6.9-1.8-13.9-2.7-20.8c0.1,6.8-0.8,13.4-2.4,20c0.5-2.1,0.9-4.2,1.4-6.3
								c-1,3.2-1.9,6.3-2.9,9.5c0.8-1.9,1.6-3.9,2.4-5.8c-1.6,2.7-3.2,5.5-4.9,8.2c1-1.7,2.1-3.4,3.1-5.1c-1.8,2.5-3.7,5-5.5,7.4
								c2.4-2.4,4.9-4.7,7.3-7.1c-1.9,1.1-3.8,2.1-5.7,3.2c1.2-0.7,2.4-1.3,3.6-2c-3.4,0.9-6.7,1.8-10.1,2.7
								c1.4-0.1,2.7-0.2,4.1-0.3c-1.9-0.1-3.8-0.3-5.6-0.4c1.3,0.1,2.5,0.2,3.8,0.3c-0.4-0.2-0.8-0.4-1.3-0.6
								c0.6,0.8,1.3,1.6,1.9,2.4c-0.8-1.1-1.6-2.2-2.5-3.3c0.5,0.9,1,1.8,1.4,2.7c-0.4-1.2-0.8-2.4-1.3-3.6c0.3,1,0.6,2,0.8,3
								c-0.3-1.4-0.6-2.8-0.9-4.1c-1.5,3-3,6-4.5,9.1c5.7-3.4,9.4-8.5,11.1-15.2c4.9-8.4,9.4-17.1,13.6-25.9
								c-1.4,2.6-2.7,5.2-4.1,7.8c2.4-4.2,4.9-8.4,7.3-12.6c1-2,2-4,3-6c-0.1-1.4-0.1-2.7-0.2-4.1c-0.8-1-1.5-2-2.3-3.1
								c-1.8-1.5-3.7-1.7-5.6-0.8c-1.9,0.2-3.4,1.1-4.5,2.8c-2.1,1.7-3.9,3.7-5.5,5.9c-2.1,2.4-4,4.9-5.6,7.6
								c-2.3,3-4.2,6.4-5.6,9.9c0-0.2,0-0.5,0.1-0.7c1-1.6,1.9-3.1,2.9-4.7c-2.4,4.3-5.4,7.9-9.1,10.9c-1.9,1.6-3.8,3.4-5.6,5.2
								c-2.2,1.6-3.8,3.5-5,5.7c-0.8,1.4-1.6,2.8-2.3,4.3c0,1-0.1,2.1-0.1,3.1c-0.4,2.1-0.3,4.1,0.4,6c0.8,2.9,1.9,5.7,3.6,8.2
								c0.8,1.2,1.6,2.3,2.5,3.3c1.2,1.3,2.4,2.6,3.7,3.8c3.3,2.7,8.4,2.8,12.4,2.9c1.6,0,3.2,0,4.8,0c3.7,0.7,6.7-0.4,9.2-3.4
								c1-1,2-2.1,3.1-3.1c4.6-7.3,9.1-14.7,13.5-22.1c2.1-2.1,2.6-4.6,1.6-7.4c-1.8-3.4-4.1-6.5-6.8-9.2c-3-3.6-6.3-7-10-10.1
								c-2.5-3.1-5.7-4.6-9.6-4.4c-5.6,1.9-10.9,4.1-16.1,6.8c10.8,1.9,21.6,3.8,32.5,5.7c-1.8-1.7-3.8-2.9-6.1-3.8
								c-2.5-1.3-5.1-2.1-8-2.4c-1.5-0.3-2.9-0.6-4.4-0.9c-3.1-0.3-6.3-0.3-9.4-0.1c-1.5,0.1-3.1,0.2-4.6,0.3
								c-2-0.2-3.8,0.2-5.6,1.2c-1.6,2.2-3.2,4.3-4.7,6.5c-0.8,2.5-1.4,5-2,7.6c3.5-4.2,7-8.4,10.5-12.6c-1.3-0.2-2.6-0.3-3.9-0.5
								c2,0.9,4,1.8,6,2.8c-1-0.9-2-1.8-3-2.7c1.2,2.1,2.4,4.3,3.6,6.4c-0.7-3.4-1.9-6.7-3.5-9.7c-1,4.1-2,8.1-2.9,12.2
								c2.1-1.4,4.1-2.8,6.2-4.3c2.3-1.7,3.2-4,2.7-7c-0.1-4.2-0.8-8.2-2.1-12.2c-0.5-3-2.1-4.9-4.8-5.7c-2.6-0.5-5-0.1-7.3,1.2
								c-4.3,3.4-7.2,7.7-8.7,12.9c-0.5,2.1-0.9,4.3-1.2,6.4c-0.1,3.5,0,7,0.3,10.6c1.7,3.5,3.6,7,5.7,10.3
								c-0.6-1.1-1.1-2.2-1.7-3.3c0.6,2.3,1.7,4.3,3.3,6c1.2,1.7,2.7,3.1,4.4,4.3c2.4,1.2,4.7,1,7-0.5c2.9-0.5,5.4-1.9,7.5-4.1
								c1.4-1.6,2.8-3.2,4.2-4.9c2.4-2.9,4.2-6.1,5.2-9.7c1.1-3.7,1.7-7.6,2.2-11.4c-0.2-2.5-1.4-4.5-3.6-5.8
								c-2.1-1.6-4.3-2-6.7-1.3c-3,2-5.4,4.6-7.3,7.7C864.1,1263.3,862.5,1266.5,861.7,1270z"/>
							<path opacity="0.1091" fill="#131313" d="M819.6,1263.8c0.1,0.8,0.3,1.5,0.4,2.3c5.3-9.1,10.6-18.3,15.9-27.4
								c-8.7,3.2-17.4,6.4-26.2,9.6c12.3,0.5,24.5,1,36.8,1.5c-9.4-2.9-18.7-5.8-28.1-8.6c-2.1-0.4-3.7,0.2-5,1.7
								c-1.7,1-2.7,2.5-3.1,4.6c-1.4,5.1-3.1,11-1.5,16.3c2,4.5,5.3,7.8,9.7,9.9c2.1,0.9,4.4,1.2,6.7,1.1c2.1,0.5,3.8,0,5.1-1.5
								c2.3-1.6,4.2-3.6,5.7-6c1.6-2.5,3.2-5,4.8-7.5c-2.3,0.5-4.5,1-6.8,1.5c3.5-0.2,7,0,10.5,0.6c-1.4-0.3-2.8-0.6-4.3-0.9
								c4.3,0.7,8.6,1.6,12.8,2.9c-1.4-0.6-2.8-1.2-4.2-1.8c2.2,1.1,4.4,2.3,6.5,3.4c-1.3-0.7-2.6-1.5-3.9-2.2
								c2.4,1.9,4.7,3.9,7.1,5.8c-1-1.1-2-2.2-3.1-3.3c1.3,1.7,2.6,3.4,3.9,5.1c-1.2-2.5-2.3-4.9-3.5-7.4c-0.1,0.6-0.2,1.1-0.4,1.7
								c0.5-0.9,1-1.7,1.6-2.6c-0.8,1.4-1.7,2.8-2.5,4.2c0.7-0.8,1.3-1.6,2-2.4c-1.5,1.3-2.7,2.7-3.7,4.4c-0.3,0.6-0.8,1.2-1.5,1.6
								c1-0.5,1.9-0.9,2.9-1.4c-11,0.5-22,1-33,1.5c13.2,4.2,26.3,8.5,39.5,12.7c-4.1-10.1-8.3-20.2-12.4-30.2
								c-1.5,7.1-3,14.2-4.5,21.3c2.7-7.7,5.4-15.4,8.2-23c-0.9,2.4-1.8,4.8-2.7,7.2c2.2-4.4,4.4-8.8,6.6-13.2
								c-1.2,2.2-2.3,4.5-3.5,6.7c2.4-4.1,4.8-8.1,7.3-12.2c0.7-1.6,1.4-3.3,2.1-4.9c-0.2-1.2-0.4-2.4-0.5-3.6
								c-0.8-1-1.6-1.9-2.4-2.9c-1.6-1.4-3.3-1.9-5.2-1.3c-1.6-0.1-2.9,0.5-3.9,1.9c-1.8,1.2-3.3,2.8-4.5,4.5
								c-1.7,1.8-3.2,3.8-4.5,5.9c-1.9,2.4-3.3,5.1-4.2,8c-2,4.9-4.2,9.8-5.5,15c-0.6,2.6-1.1,5.3-1.7,7.9c-1.2,7,0.7,13.6,5.5,19.6
								c0.8-10.4,1.7-20.9,2.5-31.3c-1.3,1-2.6,1.9-4,2.9c1.1-0.6,2.3-1.1,3.4-1.7c-1.8,0.6-3.5,1.2-5.3,1.7c1.2-0.3,2.4-0.6,3.7-1
								c-2.3,0.5-4.6,1.1-6.9,1.6c-1.7,0.8-3.4,1.8-5,2.9c-3.6,2.3-7.7,5.8-8.3,10.3c0.1,2.4,0.2,4.8,0.2,7.2c0.3,1.3,0.6,2.5,1,3.8
								c1.3,3.9,3.6,7.3,6.5,10.1c0.9,1.2,2.2,2,3.7,2.3c2.3,1.2,4.7,1.8,7.3,2c2.7,0.3,5.4,0.5,8.1,0.4c3-0.1,3.8-0.4,6.2-1.6
								c4.1-2,8.2-4.5,11.8-7.3c3-2.3,5.8-4.7,8.3-7.6c3-3.6,5.4-8.7,6.8-13.2c0.9-1.4,1-2.7,0.3-4.1c-0.2-1.2-0.7-2.4-1.3-3.4
								c-1.3-2.6-2.7-4.8-4.5-7c-2.5-3-5.8-5.5-9.4-7c-4.3-1.8-9.2-2.9-13.8-3.6c-3.9-0.5-7.9-0.8-11.8-0.7
								c-1.9-0.3-3.7,0.1-5.4,0.9c-5.7,2.4-11.4,5-17,7.7c9,1.5,18,3,27,4.5c-0.3-0.6-0.6-1.1-0.8-1.7c-2.6,3.3-5.2,6.6-7.8,9.8
								c0.9,0.2,1.9,0.4,2.8,0.6c1.3-0.1,2.5-0.2,3.8-0.3c1-0.7,2.1-1.5,3.1-2.2c0.5-1.3,0.9-2.5,1.4-3.8c-0.3-3.7-1.1-7.3-2.4-10.7
								c-1.7-5.4-3.6-10.7-6.5-15.5c-0.5-1.9-1.7-3.1-3.4-3.8c-1.6-1.4-3.4-1.6-5.5-0.7c-1.2,0.1-2.4,0.3-3.6,0.4
								c-2.6,0.6-4.7,1.9-6.4,3.9c-2.9,1.9-4.8,4.7-5.5,8.2c-2.4,3.5-3.5,7.4-3.5,11.7c0.6,2.3,1.3,4.5,1.9,6.8
								c0.7,2,1.4,3.9,2.1,5.9c0.5,2.6,1.7,4.6,3.7,6.2c1.4,1.5,3,2.7,4.8,3.6c2.7,0.6,5.1,0,7.2-1.7c4-2,7.6-4.4,10.9-7.4
								c2.4-2.1,4.9-4.3,7.3-6.4c3.4-2.3,5.2-5.4,5.5-9.3c-1.1-4.1-2-8-2.9-12.1c-1.3-2.4-3.3-3.8-6.1-4.2c-3.5,0.2-6.2,1.8-8.1,5
								c-3.7,4.3-6.8,8.9-9.6,13.8C819.1,1257.4,818.5,1260.5,819.6,1263.8z"/>
							<path opacity="0.1091" fill="#131313" d="M838.7,1256.1c-0.2,1.2-0.4,2.5-0.6,3.7c6.5-5.7,12.9-11.4,19.4-17.1
								c-5.7-3.8-11.4-7.7-17.1-11.5c-2.5-1.5-5-1.4-7.4,0.1c-2.4,1.6-3.4,3.9-3.1,6.7c-0.1,1.1-0.3,2.2-0.4,3.2
								c0.3-1.1,0.7-2.2,1-3.3c-2.7,5-4.1,10.3-4.1,15.9c0.2,2.3,0.5,4.6,1,6.9c1.8,3.9,4.6,7,8.3,9.2c1.9,0.6,3.8,1.2,5.7,1.7
								c1,0,2.1-0.1,3.1-0.1c1.9-1.1,3.4-2.6,4.5-4.5c1.2-1.3,2.1-2.8,2.8-4.4c1.2-1.8,1.9-3.9,2.1-6.1c-0.8-2-1.7-4-2.5-6
								c-3.2,3.9-6.4,7.7-9.6,11.6c4.2,0.1,8.4,0,12.6-0.2c-1.9,0-3.8-0.1-5.7-0.1c4.6,0.4,9.3,0.9,13.9,1.3c-2-0.4-4-0.7-6-1.1
								c6,1.2,11.9,2.6,17.8,4.2c-1.9-0.6-3.9-1.1-5.8-1.7c2.4,1,4.8,2,7.3,3c-1.8-0.8-3.6-1.7-5.4-2.5c2.1,1.3,4.2,2.6,6.3,3.8
								c-1.6-1.1-3.2-2.3-4.8-3.4c1.7,1.6,3.5,3.2,5.2,4.7c-3-4.4-6.1-8.9-9.1-13.3c0.8,3.3,1.5,6.6,2.3,9.9c0-1.3-0.1-2.7-0.1-4
								c-0.1,2.2-0.2,4.5-0.3,6.7c0.2-1.3,0.4-2.7,0.6-4c-0.7,2.1-1.4,4.2-2.1,6.3c0.4-1.2,0.9-2.5,1.3-3.7c-1,1.9-2,3.9-3,5.8
								c0.6-1.1,1.3-2.3,1.9-3.4c-1.2,1.8-2.5,3.6-3.7,5.4c0.8-1.1,1.7-2.1,2.5-3.2c-1.3,1.6-2.6,3.2-3.8,4.7c1-1,2-1.9,3-2.9
								c-1.7,1.4-3.3,2.9-5,4.3c4.1,1.2,8.2,2.3,12.4,3.5c-3.5-7.9-7-15.7-10.5-23.6c0.2,5,0.4,9.9,0.5,14.9
								c0.7-5.4,1.3-10.9,2-16.3c-1.6,5.6-3.2,11.2-4.8,16.8c3.3-10.2,7.5-19.9,12.6-29.4c-1.4,2.6-2.8,5.3-4.1,7.9
								c2.5-4.4,5-8.8,7.5-13.2c0.9-2,1.9-3.9,2.8-5.9c-0.1-1.3-0.2-2.7-0.2-4c-0.8-1-1.6-2-2.3-3c-1.8-1.5-3.6-1.8-5.5-0.9
								c-1.9,0.1-3.3,1-4.4,2.7c-2,1.6-3.8,3.6-5.3,5.7c-2,2.3-3.9,4.7-5.4,7.3c-2.3,3-4.1,6.2-5.4,9.7c-0.6,2.5-1.1,5.1-1.7,7.6
								c0.7-2,1.4-4,2.1-6c-2,4.2-3,8.6-4.1,13.1c-0.8,2.4-1.2,4.9-1.2,7.4c-0.7,4.8,0.1,9.6,2.3,14.2c1.7-10.7,3.4-21.5,5-32.2
								c-3.3,2.7-6.6,5.3-10,7.8c1-0.7,2-1.5,3.1-2.2c-1.9,1.1-3.8,2.2-5.7,3.3c-1.6,1.6-3.2,3.2-4.8,4.8c-0.7,1.7-1.3,3.4-1.9,5.1
								c-0.5,1.1-0.7,2.2-0.6,3.4c-0.3,1.8-0.1,3.6,0.7,5.3c0.2,2.1,0.9,4,2.1,5.8c1.4,2.6,3,4.8,5.2,6.9c1.2,1.4,2.7,2.3,4.5,2.7
								c1.5,0.5,3,1,4.5,1.4c2.8,0.6,6,0.8,8.9,0.7c1.3-0.1,2.6-0.2,3.9-0.3c1.6-0.8,3.2-1.7,4.8-2.5c4.2-2.4,7.9-5.5,11.4-8.8
								c1.5-1.3,2.9-2.7,4.1-4.3c1.6-1.4,2.7-3.1,3.1-5.3c0.9-1.9,1.5-3.9,2-5.9c0.9-1.6,1-3.3,0.4-4.9c-0.9-4.1-3.3-8.6-6-11.7
								c-1.2-1.3-2.5-2.6-3.7-3.9c-1.3-0.7-2.7-1.4-4-2.1c-3.5-1.1-7.1-2.3-10.6-3.4c-2-0.2-3.9-0.3-5.9-0.5
								c-3.9-0.2-7.8-0.4-11.7-0.2c-1.6-0.2-3,0.1-4.3,0.9c-1.5,0.6-2.6,1.7-3.3,3.2c-1.2,1-2.2,2.3-2.8,3.7c-1.2,2.1-2,4.5-2.2,7
								c2.8-3.8,5.7-7.5,8.5-11.3c-2.1,0.6-4.3,1.3-6.4,1.9c4.3,3.5,8.7,6.9,13,10.4c0.6-1.2,1.1-2.5,1.6-3.8
								c-3.2,1-6.5,2.1-9.7,3.1c1.2,0.7,2.4,1.4,3.6,2.2c-1.9-11.8-3.8-23.7-5.6-35.5c-3.9,0.9-7,2.9-9.2,6c-0.8,1-1.5,2.1-2.3,3.1
								c-2,2.9-3.2,6.2-3.7,9.7c-0.9,3-1,6-0.5,9c0.2,2.9,1.2,5.6,3,8.1c1.4,4.2,3.8,7.5,7.1,10c1.3,1.5,2.9,2,5,1.7
								c2,0.4,4,0.1,5.8-0.7c3.4-2,6.3-4.6,8.8-7.8c2.7-2.9,4.8-6.2,6.2-9.9c-0.2-1.8,0-3.6,0.6-5.3c0.4-2.3,0.7-4.5,0.8-6.8
								c-0.1-2.6-1.3-4.5-3.5-5.9c-2.1-1.6-4.3-2-6.8-1.2c-3.1,2.1-5.6,4.8-7.5,8C841.2,1249.2,839.6,1252.5,838.7,1256.1z"/>
							<path opacity="0.1091" fill="#131313" d="M857.9,1291.3c0.1,0.8,0.2,1.6,0.4,2.4c-0.2-1.3-0.3-2.6-0.5-3.8
								c0.2,1.3,0.1,2.6-0.3,3.9c2.4-4.2,4.8-8.4,7.3-12.5c-2.4,3-4.8,6.1-7.2,9.1c3.7-3.8,7.5-7.6,11.2-11.4c-2.5,2-5,4-7.5,6.1
								c3.8-2.3,7.5-4.6,11.3-6.9c-2.3,0.9-4.7,1.7-7,2.6c1.6-0.4,3.2-0.9,4.8-1.3c-2.1,0.2-4.2,0.5-6.3,0.7
								c1.5-0.1,2.9-0.1,4.4-0.2c-1.8-0.3-3.6-0.6-5.4-0.8c1.3,0.2,2.7,0.5,4,0.7c-1.5-0.6-3-1.2-4.5-1.8c1.2,0.5,2.5,1.1,3.7,1.6
								c-1.4-0.8-2.2-2-2.4-3.5c0,1,0,2,0,3c0.1-1.5,0.2-3,0.4-4.5c-0.2,1-0.5,1.9-0.7,2.9c0.8-1.8,1.6-3.5,2.4-5.3
								c0.8-2.8,0.8-5.6,0.1-8.4c-0.4-2.9-1.4-5.5-3.2-7.8c-2-1.3-4.2-1.6-6.6-1c-2.4,0.3-4.3,1.4-5.7,3.3c-2.6,3.3-3.8,7-3.7,11
								c-0.4,2.6-0.4,5.3,0,7.9c0.2-2.4,0.4-4.9,0.6-7.3c-1.7,4.5-2.1,9-1,13.6c0.9,5,3.2,9.3,6.8,12.9c1.3,1.6,2.9,2.5,4.8,2.7
								c1.7,0.7,3.4,0.6,5.1-0.5c5-4,10.1-8,15.1-12c-3.5,0.5-7.1,0.9-10.6,1.4c1.1,0.1,2.1,0.1,3.2,0.2c-4.1-0.7-8.3-1.4-12.4-2
								c0.6,0.1,1.1,0.3,1.6,0.6c11.7,7,24.4,12.3,37.9,15.7c-7.3-6.8-14.5-13.5-21.8-20.3c4.9,5.9,9.9,11.9,14.8,17.8
								c-3.9-5.7-7.8-11.3-11.7-17c3.5,6.3,7,12.6,10.5,18.8c-2.7-6-5.3-12.1-8-18.1c2.2,6.6,4.4,13.1,6.6,19.7
								c-1.5-8.7-3-17.4-4.6-26.1c-0.1,10.2-0.1,20.4-0.2,30.6c0.7-5.6,1.4-11.2,2.1-16.7c-1.4,6.3-2.7,12.6-4.1,18.9
								c1.6-5.2,3.3-10.5,4.9-15.7c-2.5,5.9-5,11.8-7.6,17.7c2.3-4.7,4.6-9.3,6.9-14c-3.1,5.3-6.3,10.6-9.4,16
								c4.3-5.4,8.6-10.7,12.9-16.1c-2.2,2.2-4.6,4.2-7.2,5.9c-2.8,2-5.9,3.7-9,5.1c2-1,3.9-2.1,5.9-3.1c-2.6,1.1-5.3,2.2-7.9,3.3
								c3.6-1,7.3-1.9,10.9-2.9c-2.4,0.1-4.8,0.2-7.2,0.3c1.4,0,2.9,0.1,4.3,0.1c-2.1-0.3-4.2-0.6-6.3-0.9c1.3,0.3,2.6,0.5,4,0.8
								c-1.7-0.6-3.5-1.3-5.2-1.9c1.2,0.5,2.4,1,3.6,1.6c-1.3-0.9-2.6-1.9-4-2.8c1,0.8,2,1.6,3.1,2.3c-1.2-1.7-2.4-3.3-3.5-5
								c0.7,1,1.4,2,2,3c0-0.2,0-0.3,0-0.5c-0.4,0.9-0.8,1.8-1.2,2.7c0.7-0.9,1.4-1.8,2-2.7c-1.8,1.2-3.6,2.4-5.4,3.6
								c2.3-0.5,4.6-1.3,6.7-2.4c2.8-3.8,5.5-7.7,8.1-11.6c3.3-5,6.2-10.1,8.8-15.5c3.6-4.9,6.5-10.1,8.8-15.7
								c0.5-5.4-4.9-9.5-9.9-7.6c-3.6,2.5-6.6,5.6-9.1,9.2c-4.3,5.4-7.3,11.4-9,18.2c2.7-5.1,5.5-10.1,8.2-15.2
								c-4.7,5.5-9.3,11-13.8,16.6c-3.5,3.2-6.7,6.7-9.5,10.5c-0.7,0.9-1.4,1.8-2.2,2.8c-1.2,1.7-2.2,3.5-3,5.4
								c-0.2,1.8-0.2,3.5,0.1,5.2c0.1,1.8,0.4,3.5,1.1,5.2c1.5,4,4.1,6.9,6.9,10.1c0.9,0.7,1.7,1.3,2.6,2c2.5,0.4,4.9,0.2,7.2-0.5
								c4.4-0.9,8.5-2.2,12.2-4.8c7.8-5.7,15.6-11.5,23.5-17.2c2.1-2.4,2.4-5,1-7.9c-3.4-5.3-7.4-10.1-12-14.5
								c-4.3-4.6-9.1-8.7-14.4-12.1c-2.9-1.5-5.5-1.2-7.9,0.9c-1.2,1-2.4,1.9-3.6,2.9c1-0.8,2.1-1.5,3.1-2.3
								c-4.3,2.8-8.4,5.6-12.5,8.7c12.5,1.4,25,2.9,37.5,4.3c-1.9-1.8-4-3.3-6.4-4.5c-2.1-1.4-4.5-2.3-7-2.7
								c-1.7-0.5-3.4-0.9-5.1-1.4c-3.5-0.4-7.1-0.6-10.6-0.8c2.1,0.2,4.1,0.4,6.2,0.6c-2.9-0.4-5.8-0.7-8.7-0.9
								c-1.6-0.4-3.3-0.2-4.8,0.5c-1.2,1.3-2.4,2.6-3.7,3.9c-2.9,3.5-4.3,7.6-4.4,12.4c5.2-5.2,10.4-10.3,15.6-15.5
								c-2.7-0.7-5.3-1.4-8-2.1c1.8,1.3,3.6,2.6,5.4,3.9c-1-2.4-2.4-4.6-4.2-6.5c0.4,2.1,0.8,4.3,1.1,6.4c0.1-2.3,0.1-4.5,0.2-6.8
								c-2.1,3.6-4.2,7.2-6.3,10.8c2.4-1.5,4.9-3,7.3-4.6c-4.4-5.3-8.8-10.7-13.2-16c-1.3,1.9-2.6,4-3.6,6.1c-1,1.7-1.6,3.5-1.8,5.4
								c-0.6,1.5-0.8,3.1-0.7,4.7c-0.1,2.5-0.1,5-0.1,7.5c0.2,1.9,0.4,3.7,0.6,5.6c2.1,3.4,4,6.8,5.6,10.5c0.8,0.9,1.7,1.9,2.5,2.8
								c3,1.1,6.1,1.9,9.2,2.5c1.3-0.2,2.5-0.3,3.8-0.5c2.8-1.8,5.3-4.1,7.4-6.7c2-1.9,3.7-4,5.3-6.3c2.2-2.6,3.9-5.4,5.2-8.5
								c0.3-1.1,0.5-2.1,0.8-3.2c0.2-1.3,0.2-2.7,0-4c-0.2-2.5-0.6-5-1-7.5c-1.3-2.5-3.3-3.8-6.1-4.2c-3.6,0.2-6.3,1.9-8.2,5.2
								c-3.7,4.4-6.9,9.1-9.7,14.1C857.4,1284.8,856.8,1287.9,857.9,1291.3z"/>
							<path opacity="0.1091" fill="#131313" d="M811.7,1289.7c0.4,1.5,0.6,3,0.4,4.6c6.5-11.3,12.9-22.6,19.4-34
								c-8.8,10.5-17.6,20.9-26.4,31.4c9.1-8.2,18.1-16.4,27.2-24.6c-7.4,5-14.8,10-22.2,15c11.9-5.8,23.7-11.6,35.6-17.4
								c-12.2-1.1-24.5-2.3-36.7-3.4c9.3,3.9,18.6,7.8,27.9,11.8c-4.6-6-9.1-11.9-13.7-17.9c-1.6-1.2-3.3-1.4-5-0.9
								c-1.9,0.1-3.6,0.8-5.1,2.2c-1.7,0.9-3,2.1-4.2,3.6c-1.4,1.5-2.6,3.1-3.8,4.7c-2.1,3.2-3.5,6.6-4.1,10.3
								c-0.5,2.1-0.9,4.2-1.2,6.3c0.4,1.2,0.7,2.4,1.1,3.6c0.8,2.5,2.1,4.7,3.8,6.6c0.8,0.9,1.7,1.9,2.5,2.8c3,2.7,6.5,4.4,10.6,4.9
								c2.5,0.1,4.5-0.8,5.9-2.8c2.9-2.4,5.3-5.2,7.3-8.4c1.5-2.3,2.9-4.6,4.4-6.9c2.3-2.8,2.9-5.9,1.8-9.2
								c-0.7-1.4-1.4-2.9-2.2-4.3c-2.2,4.5-4.5,8.9-6.7,13.4c2.1,0.4,4.3,0.9,6.4,1.3c-1.1-0.3-2.3-0.6-3.4-0.9
								c2.9,0.8,5.8,1.8,8.5,3.1c2.9,1.6,5.8,2.9,8.9,4.1c-1.3-0.8-2.7-1.6-4-2.3c1.6,1.1,3.2,2.1,4.8,3.2c-1.3-1-2.6-2-3.9-3
								c1.3,1.8,2.7,3.5,4,5.3c-1.6-3-3.3-6.1-4.9-9.1c0.4,1.6,0.8,3.2,1.3,4.7c0.1-2.5,0.2-5,0.3-7.5c-0.5,1.1-1.1,2.2-1.6,3.3
								c0.6-1,1.1-2,1.7-3c-10.5,10-21,20-31.5,30c14.2-6.1,28.4-12.2,42.7-18.2c-8-2.2-16-4.4-24-6.5c3,4.3,6,8.5,9,12.8
								c-1.1-3.2-2.3-6.4-3.4-9.6c0.8,3.4,1.6,6.8,2.4,10.2c-0.5-3.3-1-6.6-1.5-9.8c0.1,1.8,0.1,3.7,0.2,5.5
								c0.7-6.8,1.4-13.5,2.1-20.3c-1,3.1-2,6.1-3,9.2c1.7-3.3,3.8-6.4,6.1-9.3c3.1-4.1,6.3-8.3,9.4-12.4c1.8-2.1,3-4.5,3.4-7.2
								c-0.5-2.3-1.7-4.1-3.7-5.4c-1.8-1.6-3.9-2.3-6.2-2.1c-2.5,1.2-4.5,2.9-6.1,5.2c-3.7,4.6-7.8,8.9-11.4,13.7
								c-1.8,1.7-3.1,3.7-3.8,6c-2.8,6.6-5.5,13.3-8,20c1.7-1.8,3.3-3.6,5-5.4c-2.4,1.2-4.8,2.4-7.3,3.6c-1.8,1.7-3.6,3.4-5.4,5.1
								c-1.6,1.9-2.4,4.1-2.6,6.6c-1.1,2.7-0.9,5.4,0.5,8.1c0.2,2.5,0.9,4.7,2.2,6.7c0.5,1.3,1.3,2.4,2.5,3.2c3.1,2.9,6.9,4,11,4.6
								c1.6,0.1,3.2,0.3,4.8,0.4c1.5,0,3-0.1,4.5-0.1c1.4-0.5,2.7-1,4.1-1.5c2.8-1.2,5.4-2.7,7.9-4.3c3.5-2.1,6.9-4.3,9.8-7.2
								c2.3-2.3,4.2-4.7,5.9-7.5c1-1.7,1.9-3.3,2.7-5.1c1.1-2.4,2-5.1,2.3-7.7c0.3-3.4-1.9-7.7-3.9-10.4c-1-1.6-2.3-2.9-4-3.8
								c-1.6-1.6-3.5-2.8-5.5-3.8c-6.5-3.7-14.1-6.6-21.7-7.3c-1.1,0-2.2-0.1-3.3-0.1c-2.1,0.3-3.7,1.4-4.8,3.2
								c-1.4,1.1-2.6,2.5-3.5,4c-2.8,4-5,8.3-6.8,12.9c4.6-5.8,9.2-11.6,13.9-17.4c-4,3-8.1,6-12.1,8.9c3.8-2.4,7.6-4.8,11.5-7.2
								c-6.3,1-12.5,2-18.8,3c7.8,0.7,15.7,1.4,23.5,2.2c-1.1-1-2.2-2-3.3-3c0.5,1,1.1,2,1.6,3c-0.4-1-0.8-2.1-1.2-3.1
								c-3.7,3.1-7.4,6.2-11.1,9.3c2.4,0.7,4.7,1.4,7.1,2.1c-4.6-8.9-9.2-17.8-13.8-26.7c-0.6,0.4-1.3,0.8-1.9,1.1
								c-2,1.2-3.5,2.8-4.6,4.8c-1.2,1.5-2.2,3.2-3,5.1c-1.6,2.8-2.7,5.9-3.4,9.1c0.2,2.2,0.4,4.3,0.6,6.5c0.3,2.1,0.7,4.3,1,6.4
								c0.1,5.7,2.3,9.4,6.5,11.1c1.9,1.2,3.9,1.7,6.3,1.4c3.5-0.3,6.4-1.8,8.7-4.4c4.8-3.6,9.2-7.7,13.2-12.2c3-3.3,6-6.6,8.9-9.8
								c4-3.4,6.3-7.6,6.9-12.6c-1-4.1-1.9-8.3-3-12.3c-0.9-3-3-4.4-6.1-4.2c-4.6,1.4-8.2,4.2-10.6,8.6c-5.2,6.6-10,13.5-14.4,20.8
								C812.8,1280.5,811.3,1284.9,811.7,1289.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M903.5,1276.7c2.2-6,3.3-12.2,3.6-18.6c0.7-4,1.7-7.9,3.1-11.7
								c1.1-3.7,2.7-7.3,4.5-10.8c2.3-4.5,5.1-8.8,8.2-12.9c1.3-2.4,1.1-4.8-0.3-7.2c-1.4-2.4-3.5-3.5-6.2-3.5
								c-4.3,1.1-8.3,3-12,5.5c-3.9,2.2-7.3,4.9-10.3,8.3c-2.7,4.9-4.9,10.1-6.9,15.3c-1.5,4-2.7,8.1-3.6,12.3
								c-1.1,4.2-1.9,8.5-2.4,12.8c0.2,6.2-0.7,12.2-2.6,18.1c-1.1,4.9,4.3,9.7,9,8.7c3.5-1.7,6.6-4.1,9.2-7
								C899.6,1283.3,901.9,1280.2,903.5,1276.7z"/>
							<path opacity="0.1091" fill="#131313" d="M880.4,1260.4c2.9-6.2,4.4-12.8,4.5-19.6c0.7-4.1,1.8-8,3.3-11.8
								c1.2-3.8,2.8-7.5,4.7-11c2.5-4.6,5.3-8.9,8.6-13c0.9-2.4,0.6-4.7-0.9-6.8c-1.3-2.3-3.2-3.5-5.7-3.8c-3.7,0.7-7,2.2-10.1,4.3
								c-3.3,1.8-6.1,4.2-8.4,7.1c-3.3,5.1-6.1,10.4-8.6,15.9c-1.9,4.2-3.4,8.4-4.6,12.8c-1.4,4.3-2.5,8.8-3.1,13.3
								c0.1,6.6-1.1,12.9-3.6,19.1c-0.3,2.5,0.6,4.7,2.5,6.4c1.8,1.9,3.9,2.7,6.5,2.3c3.3-1.6,6.2-3.7,8.6-6.5
								C876.9,1266.8,879,1263.8,880.4,1260.4z"/>
							<path opacity="0.1091" fill="#131313" d="M885.8,1220.1c0.1-2.8,0.2-5.6,0.3-8.4c-0.2,1.5-0.4,2.9-0.6,4.4
								c0.6-4.2,1.3-8.4,1.9-12.5c-0.2,1.5-0.5,2.9-0.7,4.4c0.6-2.7,1.3-5.3,1.9-8c1.1-3.9,2.5-7.6,4.3-11.2c1-2.4,2.2-4.8,3.6-7
								c1.9-3.5,4-6.7,6.5-9.8c1.6-2.5,1.7-5,0.2-7.5c-1.6-2.4-3.9-3.5-6.8-3.1c-5,1.6-9.7,3.9-14.1,6.9c-4.6,2.6-8.7,5.9-12.3,9.7
								c-3.6,5.8-6.8,11.8-9.4,18.1c-2.6,6.2-4.7,12.5-6.2,19c-1,4.6-1.9,9.3-2.6,13.9c-1.1,5.6-1.5,11.3-1.3,17.1
								c0.7,2.9,2.4,4.6,5.3,5.1c4.2-0.1,7.7-1.9,10.4-5.3c5.3-4.7,10.2-9.8,14.8-15.3C884.2,1227.9,885.8,1224.3,885.8,1220.1z"/>
							<path opacity="0.1091" fill="#131313" d="M903.6,1247.2c0.1-2.8,0.1-5.5,0.2-8.3c-0.2,1.4-0.3,2.8-0.5,4.2
								c1.2-8.1,2.5-16.2,5.7-23.8c1.1-2.4,2.2-4.8,3.3-7.2c2.4-4.6,5.2-9,8.4-13.1c1.8-2.5,1.9-5,0.4-7.7c-1.7-2.5-4.1-3.5-7.1-3
								c-5.3,1.8-10.3,4.3-15,7.4c-4.9,2.8-9.3,6.2-13.3,10.2c-2.7,5.6-5.4,11.1-7.5,16.9c-2.3,6.4-4.2,13.2-5.2,19.9
								c-0.5,3-1,6.1-1.4,9.1c-0.3,2.1-0.5,4.3-0.7,6.4c-0.5,4.1-0.7,8.1-0.4,12.2c0.7,2.8,2.5,4.5,5.3,5.1c3.9,0.1,7.2-1.4,9.8-4.6
								c4.9-4.3,9.5-8.9,13.6-14C902.3,1254.5,903.8,1251.2,903.6,1247.2z"/>
							<path opacity="0.1091" fill="#131313" d="M906.9,1215.7c-0.3-4.2-0.1-8.3,0.8-12.4c0.5-4,1.4-7.9,2.6-11.8
								c-0.5,1.7-1.1,3.4-1.6,5.1c1.4-4.1,2.9-8.2,4.3-12.4c-0.6,1.7-1.2,3.3-1.8,5c1.4-3.6,3.1-7,5.1-10.3c1.8-3.3,3.8-6.5,6.2-9.5
								c0.8-1.5,1.5-3,2.3-4.5c-0.3-1.3-0.5-2.6-0.8-3.9c-1.1-0.8-2.1-1.6-3.2-2.3c-3.3-0.6-6.4,0.1-9.1,2
								c-7.2,3.2-13.8,7.4-20.3,11.9c-3.1,1.9-6,4-8.7,6.5c-2.9,1.7-4.8,4.1-5.7,7.4c-4.4,9.4-8.2,18.8-10.7,28.8
								c-1.6,6.4-2.8,12.9-3.5,19.5c-1.1,5.6-1.4,11.2-0.9,16.8c0.3,3.2,2.1,4.9,5.3,5.1c5.2-0.9,9.5-3.5,12.9-7.8
								c6.9-6.3,13.4-13,19.5-20.1C903.6,1225.3,906.1,1220.9,906.9,1215.7z"/>
							<path opacity="0.1091" fill="#131313" d="M867.4,1246.6c1.9-4.9,3.1-10,3.5-15.2c0.3-4,1.2-8,2.5-11.9c1-2.6,1.9-5.1,2.9-7.7
								c2.2-5,4.8-9.8,8-14.3c-0.8,1.1-1.6,2.1-2.4,3.2c2.4-3.1,4.8-6.3,7.2-9.4c0.6-2.4,0.2-4.6-1.2-6.6c-1.2-2.2-2.9-3.5-5.3-4.1
								c-3.1,0.4-6,1.5-8.6,3.3c-2.8,1.5-5.1,3.5-6.8,6.1c-3.8,5.1-7.1,10.6-10,16.3c-2.2,4.3-4.1,8.8-5.5,13.4
								c-1.7,4.4-2.9,8.9-3.6,13.6c-0.3,6.4-1.4,12.6-3.3,18.7c-0.1,2.5,0.8,4.6,2.7,6.2c1.7,1.9,3.8,2.7,6.3,2.5
								c3.1-1.4,5.8-3.4,8.1-6C864.2,1252.6,866.1,1249.8,867.4,1246.6z"/>
							<path opacity="0.1091" fill="#131313" d="M889.3,1277.8c1.3-4.9,2.2-9.9,2.8-14.9c0.3-4.2,1.3-8.2,2.8-12.1
								c1-2.5,1.9-5,2.9-7.6c2.1-4.8,4.7-9.4,7.8-13.8c-0.8,1-1.6,2.1-2.4,3.1c2.3-3,4.6-6.1,6.9-9.1c0.6-2.4,0.2-4.6-1.2-6.6
								c-1.2-2.2-3-3.5-5.4-4.1c-3.2,0.4-6.1,1.6-8.7,3.4c-2.8,1.5-5.2,3.6-7,6.2c-3.1,4.7-5.8,9.5-8.2,14.6
								c-1.9,3.9-3.5,7.9-4.7,12.1c-1.5,4.1-2.6,8.4-3.4,12.7c-0.3,6.3-1.1,12.5-2.4,18.7c0.2,2.4,1.2,4.3,3,5.9
								c1.6,1.8,3.6,2.7,6,2.9c2.7-0.9,4.9-2.4,6.8-4.6C887,1282.8,888.4,1280.5,889.3,1277.8z"/>
							<path opacity="0.1091" fill="#131313" d="M918.2,1242.9c-0.2-5.6,0.3-11.2,1.6-16.6c-0.4,1.6-0.8,3.2-1.1,4.8
								c1.1-4.3,2.2-8.5,3.3-12.8c-0.4,1.6-0.9,3.2-1.3,4.8c1.5-5.1,3.5-10,6-14.8c2.4-4.6,5.2-9,8.4-13.1c0.7-1.5,1.5-3,2.2-4.5
								c-0.3-1.3-0.5-2.6-0.8-3.9c-1.1-0.8-2.1-1.6-3.2-2.4c-3.3-0.6-6.3,0-9.1,1.9c-7.1,3.2-13.7,7.4-20.2,11.7
								c-3.1,1.8-6,4-8.6,6.4c-2.9,1.7-4.8,4.1-5.6,7.3c-2.5,5.8-4.5,11.7-6.2,17.7c-1.3,4.5-2.3,9-3,13.7
								c-0.9,4.6-1.5,9.2-1.8,13.8c-0.8,5.5-1,11-0.7,16.5c0.5,3,2.2,4.7,5.3,5.1c4.7-0.5,8.5-2.6,11.6-6.4
								c6.1-5.5,11.8-11.3,17.1-17.5C915.8,1251.5,917.9,1247.6,918.2,1242.9z"/>
							<path opacity="0.1091" fill="#131313" d="M876.7,1231c0.1-2.7,0.1-5.5,0.2-8.2c0,1.3-0.1,2.6-0.1,3.9c0.3-4.1,1-8.2,2.1-12.1
								c0.8-4,2.1-7.9,3.7-11.7c1.1-2.4,2.3-4.9,3.4-7.3c2.5-4.7,5.4-9.1,8.7-13.3c1.2-2.4,1-4.8-0.5-7.1c-1.4-2.3-3.4-3.5-6.1-3.6
								c-4.1,1-8,2.8-11.5,5.2c-3.7,2.1-7,4.8-9.7,8c-3.7,5.5-6.8,11.3-9.5,17.3c-2.7,6-4.9,12.1-6.5,18.5c-1.5,6-2.6,12-3.1,18.2
								c-0.1,4-0.3,8-0.4,11.9c0.9,2.6,2.6,4.3,5.3,5.1c3.5,0.4,6.5-0.8,8.9-3.7c4.3-3.7,8.3-7.8,11.8-12.2
								C876.1,1237.6,877.2,1234.6,876.7,1231z"/>
							<path opacity="0.1091" fill="#131313" d="M894.8,1229.3c-0.2-5.6,0.4-11.1,1.6-16.6c-0.3,1.5-0.5,3-0.8,4.5
								c0.6-2.7,1.3-5.3,1.9-8c1.1-3.9,2.5-7.6,4.2-11.2c1-2.4,2.2-4.8,3.6-7c1.8-3.4,4-6.7,6.4-9.8c1.8-2.5,2-5.1,0.5-7.7
								c-1.8-2.5-4.2-3.5-7.2-2.9c-5.5,1.9-10.6,4.5-15.5,7.7c-5,2.9-9.6,6.4-13.7,10.6c-3.3,5.8-6.1,11.7-8.5,17.9
								c-2.3,6.1-4.2,12.3-5.5,18.7c-1,4.6-1.8,9.2-2.5,13.9c-1,5.6-1.3,11.2-1.1,16.9c0.6,2.9,2.4,4.6,5.3,5.1
								c4.3-0.2,7.9-2,10.7-5.6c5.5-4.9,10.6-10.2,15.3-15.8C893,1237.2,894.8,1233.6,894.8,1229.3z"/>
							<path opacity="0.1091" fill="#131313" d="M914.9,1258.6c0.1-2.8,0.1-5.5,0.2-8.3c-0.2,1.4-0.3,2.8-0.5,4.2
								c1.2-8.1,2.5-16.2,5.7-23.8c1.1-2.4,2.2-4.8,3.3-7.2c2.4-4.6,5.2-9,8.4-13.1c1.9-2.5,2.2-5.1,0.7-7.8
								c-1.8-2.5-4.3-3.5-7.3-2.8c-5.7,2-11,4.7-16.1,8.1c-5.2,3-10,6.6-14.3,10.9c-2.4,5.4-4.7,10.8-6.5,16.5
								c-1.6,4.9-2.7,10-3.8,15c-0.7,3.6-1.3,7.2-1.7,10.9c-0.4,2.9-0.7,5.8-0.9,8.7c-0.4,4-0.5,8-0.3,12.1c0.7,2.8,2.5,4.5,5.3,5.1
								c3.9,0.1,7.2-1.4,9.8-4.6c4.9-4.3,9.4-8.9,13.6-13.9C913.7,1265.8,915.1,1262.5,914.9,1258.6z"/>
							<path opacity="0.1091" fill="#131313" d="M870.2,1258.6c1.3-4.9,2.2-9.8,2.7-14.8c0.3-4.1,1.2-8.1,2.6-12
								c0.9-2.5,1.9-5,2.8-7.5c2-4.8,4.5-9.3,7.4-13.6c-0.7,1-1.4,1.9-2,2.9c2.2-3,4.4-6,6.6-9.1c0.4-2.3-0.1-4.5-1.5-6.4
								c-1.1-2.1-2.8-3.5-5.1-4.2c-2.8,0.2-5.3,1.1-7.6,2.7c-2.5,1.3-4.5,3.2-5.9,5.6c-3.5,4.8-6.6,9.9-9.2,15.2
								c-2,4-3.7,8.1-5.1,12.4c-1.6,4.2-2.8,8.5-3.7,13c-0.4,6.4-1.2,12.7-2.6,18.9c0.1,2.4,1.1,4.4,3,5.9c1.6,1.8,3.6,2.7,6,2.8
								c2.7-1,5-2.5,6.9-4.7C867.9,1263.7,869.4,1261.4,870.2,1258.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M857.9,1290.7c-1.1-0.2-2.2-0.5-3.3-0.7c1.4,0.6,2.9,1.2,4.3,1.8
								c-1.9-1.4-3.7-2.9-5.6-4.3c0.9,1,1.8,1.9,2.7,2.9c-1-1.3-1.9-2.6-2.9-4c0.6,1.1,1.3,2.2,1.9,3.3c-0.1-2.2,0-4.4,0.5-6.5
								c0.6-2.7,1.3-5.4,2-8c1.2-2.8,1.6-5.6,1.4-8.6c-1.5-4.4-2.4-9-2.8-13.6c0,1.6-0.1,3.1-0.1,4.7c0.1-4.4,0.5-8.7,1.1-13.1
								c0.5-4.3,1.2-8.5,2-12.7c-0.3,1.7-0.6,3.3-0.9,5c0.9-3.4,1.7-6.7,2.6-10.1c-0.5,1.7-1,3.4-1.4,5.1c1.2-3.4,2.4-6.7,3.6-10.1
								c-3.6,4.5-7.1,8.9-10.7,13.4c8.4-0.8,16.8-1.6,25.2-2.4c-10.5-2.5-21.1-5.1-31.6-7.6c1.7,4.1,3.4,8.2,5.1,12.3
								c-1.1-2.9-2.1-5.8-3.2-8.8c2.4,7.7,4.9,15.3,7.3,23c-1-6.3-2-12.6-2.9-18.9c0.5,8.7,1,17.4,1.5,26.1
								c0.2-6.4,0.3-12.7,0.5-19.1c-0.7,8.6-1.5,17.1-2.2,25.7c0.5-3.2,0.9-6.3,1.4-9.5c-1.2,5.3-2.3,10.6-3.5,15.9
								c0.9-3.1,1.8-6.1,2.7-9.2c-1.8,5.1-3.6,10.2-5.4,15.3c1.3-2.9,2.6-5.8,3.9-8.7c-2.4,4.8-4.8,9.7-7.2,14.5
								c1.6-2.7,3.3-5.4,4.9-8.1c-2.9,4.5-5.8,9-8.8,13.5c4.1-4.8,8.1-9.6,12.2-14.3c-2.7,2.6-5.4,5.2-8.1,7.9c2.3-2,4.6-4,6.9-6
								c-5.8,4-11.6,8-17.4,12.1c5.1-2.9,10.1-5.8,15.2-8.7c-2.9,1.2-5.8,2.4-8.7,3.6c4.6-1.2,9.1-2.4,13.7-3.5
								c-2.9,0.2-5.8,0.4-8.7,0.6c1.6,0.1,3.3,0.2,4.9,0.3c-2.3-0.5-4.7-0.9-7-1.4c1.3,0.5,2.7,0.9,4,1.4c-1.7-0.8-3.5-1.7-5.2-2.5
								c1,0.7,2.1,1.5,3.1,2.2c-1.6-2.4-3.2-4.8-4.8-7.2c0.5,1,0.9,2,1.4,3.1c-0.7-2.4-1.4-4.7-2.1-7.1c0.2,1,0.4,2.1,0.6,3.1
								c-0.4-2.2-0.8-4.4-1.2-6.5c0,1.1,0,2.1-0.1,3.2c0.4-3.5,0.7-6.9,1.1-10.4c-0.1,1-0.3,2.1-0.4,3.1c1.6-6.2,2.5-12.4,2.5-18.8
								c-0.5,2.5-1.1,5.1-1.6,7.6c3.2-4,6.3-8,9.5-11.9c-4.3,3.4-8.5,6.8-12.8,10.2c2.7-0.4,5.3-0.9,8-1.3c-4.5-3.1-9-6.1-13.5-9.2
								c-0.7,12.6,2.6,24.7,4.9,37.2c-0.3-3.2-0.6-6.4-0.9-9.6c0.3,7.3,0.7,14.7,1,22c0.8-8.7,1.6-17.5,2.3-26.2
								c-1.6,8.1-3.1,16.3-4.7,24.4c0.7-3.1,1.5-6.3,2.2-9.4c-1.5,4.8-2.9,9.6-4.4,14.4c1.1-3,2.3-6,3.4-9.1
								c-2,4.5-4.1,8.9-6.1,13.4c1.5-2.8,2.9-5.7,4.4-8.5c-2.4,4.2-4.8,8.4-7.3,12.7c1.8-2.6,3.6-5.3,5.4-7.9
								c-2.9,3.8-5.8,7.7-8.7,11.5c2-2.4,4-4.8,6.1-7.2c-3.2,3.5-6.4,7-9.7,10.5c2.2-2.1,4.5-4.3,6.7-6.4c-3.6,3-7.2,6.1-10.8,9.1
								c2.4-1.9,4.8-3.7,7.2-5.6c-3.8,2.6-7.5,5.2-11.3,7.8c8.5-3.2,16.9-6.5,25.4-9.7c-2-1.7-4-3.4-6-5.1c1.3,1.8,2.5,3.7,3.8,5.5
								c-1.2-3.3-2.4-6.6-3.5-9.9c0.1,0.9,0.2,1.9,0.4,2.8c0.1-6,0.5-11.9,1-17.9c-0.1,1.1-0.2,2.1-0.4,3.2c1-7.9,3-15.6,6-23
								c-0.5,1.3-1,2.6-1.5,4c2.5-6.5,5.4-12.8,8.8-18.9c1.7-2.1,1.8-4.3,0.6-6.6c-1.4-2.2-3.3-3.8-5.6-4.7
								c-2.5-0.9-4.6-0.3-6.4,1.7c-2.7,2.3-4.9,5-6.7,8c-3.8,9.4-7.1,18.9-9.2,28.8c-0.7,2.6-1.2,5.2-1.4,7.8
								c-0.8,6.9-1,13.8-0.7,20.7c0.7-2.4,1.4-4.7,2.1-7.1c-2.6,4-4.7,8.3-6.4,12.7c-0.1,4.2,1,8.2,3.2,11.9
								c0.6,2.8,2.2,4.6,4.8,5.6c2.6,0.6,5,0,6.9-1.8c3.7-2,7.1-4.5,10.1-7.5c4.2-3.4,7.8-7.2,10.8-11.6c1.6-3.2,3-6.5,4.2-9.9
								c3.5-8.4,4.2-17.3,2.2-26.8c-0.1-2.3-0.2-4.6-0.3-6.9c-3.2-5.5-6.4-10.9-9.8-16.2c2.1,12,4.2,24,6.3,36
								c3.8-4.9,6.4-10.4,7.7-16.6c0.4-6.3,0-12.6-1-18.9c-0.8-2.5-1.7-4.9-2.6-7.3c-1.7-3.3-4.1-5.9-7.2-7.7
								c-2.7-0.6-5.5-0.8-8.3-0.5c-2.2,0.3-4.2,1-6.1,2.1c-2.1,0.8-3.4,2.3-3.9,4.5c-1.6,4.2-2.7,8.6-3.2,13.1
								c0.2-1.1,0.5-2.1,0.7-3.2c-1.8,6-3.1,12.1-4.1,18.3c-0.1,1.7-0.1,3.4-0.1,5.1c0,3.1,0,6.1,0.2,9.2c-0.5,4.6,0.4,8.8,2.7,12.7
								c-0.3-1-0.6-2-1-3c0.5,1.8,1.1,3.6,1.6,5.4c0.6,0.9,1.2,1.8,1.9,2.6c2.2,1.1,4.4,1.6,6.8,1.7c1.9,0.3,3.8,0.4,5.7,0.2
								c2.4,0.1,4.7-0.3,6.9-1.2c4.7-3.6,9.4-7.3,14-10.9c3-4.6,5.3-9.6,6.9-14.9c2-5.1,3.2-10.5,3.7-15.9c-0.3-1.8-0.7-3.6-1-5.4
								c-0.4,1.3-0.8,2.6-1.2,3.9c2-2.7,4-5.4,6-8c1.3-1.5,2.3-3.2,2.9-5.1c1.5-3.4,2.9-6.8,4.4-10.1c0-2.6-0.5-5.1-1.6-7.4
								c-0.7-3.2-2.2-6.1-4.6-8.5c-1-1-1.9-1.9-2.9-2.9c-1-1.2-2.3-1.9-3.8-2.1c-1.6-0.6-3.3-0.9-5.1-1.1c-2.5-0.1-4.9,0.2-7.4,0.9
								c-3.6,1.1-5.8,4.9-7.7,7.9c-1.6,2.6-2.8,5.3-3.6,8.2c-1.1,3-2,6.1-2.6,9.3c-1,5-1.5,10-2.1,15.1c0.2-1.6,0.4-3.2,0.6-4.7
								c-0.9,4-1.3,8.1-1.2,12.2c0.3,3.4,0.7,6.7,1.4,10c0-1.1,0-2.2,0-3.3c-1,4.6-2.3,9-3.9,13.4c-1,2.7-1.9,5.4-2.7,8.2
								c0.3-1,0.6-1.9,1-2.9c-1.4,2.2-2.2,4.5-2.4,7c-0.4,2.6-0.4,5.3-0.2,8c0.2-1.2,0.3-2.5,0.5-3.7c-0.2,1.4-0.4,2.7-0.6,4.1
								c-0.1,1.8,0.1,3.5,0.6,5.2c0.1,1.2,0.6,2.1,1.5,2.8c0.6,0.9,1.3,1.6,2.4,2c1.7,0.4,3.5,0.8,5.2,1.2c2.5,0.1,5,0.2,7.4,0.3
								c2.5-0.2,4.9-0.3,7.4-0.5c2.8,0.3,5-0.6,6.4-2.6c1.1-0.7,1.7-1.8,1.6-3.1c0.5-1.2,0.5-2.5,0-3.7c0-1.4-0.6-2.4-1.7-3.1
								C862.8,1291.2,860.7,1290.4,857.9,1290.7z"/>
							<path opacity="0.1091" fill="#131313" d="M859.1,1277.4c-11.8-1.7-23.7-3.5-35.5-5.2c9.4,1.8,18.8,3.6,28.2,5.4
								c-8-3.6-16.1-7.2-24.1-10.8c7.3,4.1,14.5,8.2,21.8,12.3c-5.9-5.6-11.8-11.3-17.8-16.9c3.7,4,7.4,8,11.1,11.9
								c-1.7-2.4-3.4-4.9-5.2-7.3c1.3,2.2,2.5,4.4,3.8,6.7c-2.4-4.8-4.8-9.5-7.1-14.3c1.9,4.7,3.9,9.3,5.8,14
								c-1.7-5.3-3.3-10.6-5-15.8c0.5,2.4,1,4.7,1.5,7.1c-0.4-4.7-0.8-9.5-1.3-14.2c-0.8-6.6-1.3-13.2-1.2-19.9
								c0,2.2,0.1,4.5,0.1,6.7c0.2-4.3,0.5-8.6,0.7-12.9c-0.2,2.2-0.4,4.3-0.6,6.5c0.6-4.2,1.2-8.4,1.8-12.6
								c-0.4,2.1-0.8,4.2-1.2,6.3c1-4.1,1.9-8.1,2.9-12.2c-0.6,2-1.2,4.1-1.8,6.1c1.3-3.9,2.6-7.8,4-11.8c-0.8,2-1.5,3.9-2.3,5.9
								c1.7-3.7,3.3-7.5,5-11.2c-2.1,3.6-4.3,7.2-6.4,10.8c0.3-0.3,0.6-0.6,0.8-0.9c-1.2,0.9-2.4,1.8-3.6,2.7
								c3.8-2.5,7.6-5,11.4-7.5c-3.2,1.4-6.4,2.9-9.6,4.3c1.7-0.5,3.4-0.9,5.1-1.4c-2.3-0.3-4.7-0.6-7-0.9c1.1,0.6,2.2,1.2,3.3,1.8
								c-1-2-2-4.1-2.9-6.1c0.3,2.5,1,4.8,2,7.1c0.9-2.1,1.8-4.1,2.7-6.2c-2.4,1.2-4.1,3-5.2,5.5c0.4,8.7,0,17.4-1.3,26.1
								c0.3-2.5,0.5-5,0.8-7.5c-1,7.1-2,14.2-3,21.3c0.4-2.6,0.8-5.3,1.2-7.9c-1.2,4.6-2.3,9.2-3.5,13.8c0.8-2.7,1.6-5.4,2.5-8.1
								c-1.7,4.6-3.4,9.2-5.2,13.8c1.2-2.7,2.5-5.4,3.7-8.2c-2.3,4.5-4.6,9-6.9,13.5c1.6-2.7,3.3-5.4,4.9-8c-2.8,4.3-5.7,8.7-8.5,13
								c6.1-6.5,12.1-12.9,18.2-19.4c-10.5,6.8-21,13.6-31.5,20.4c8.8-4,17.5-8,26.3-12.1c-3.6,0.8-7.2,1.7-10.8,2.5
								c5.4-0.5,10.8-1.1,16.2-1.6c-7-2.2-14.1-4.4-21.1-6.6c6.4,3.8,12.8,7.6,19.1,11.4c-5.6-5.6-11.3-11.2-16.9-16.9
								c2.8,3.7,5.5,7.5,8.3,11.2c-2.6-4.4-5.2-8.7-7.8-13.1c1.9,4.3,3.9,8.5,5.8,12.8c-1.3-4.7-2.7-9.3-4-14c0.5,2.2,1,4.3,1.4,6.5
								c-0.8-4.7-1.7-9.3-2.5-14c0.1,2.2,0.3,4.3,0.4,6.5c0.1-4.2,0.1-8.3,0.2-12.5c-0.1,2.1-0.3,4.2-0.4,6.4c0.5-4,1-8.1,1.4-12.1
								c-1,3.4-2,6.9-3,10.3c0.7-1.7,1.4-3.3,2.2-5c-0.8,1.2-1.5,2.5-2.3,3.7c2.5-2.8,5-5.6,7.5-8.4c-2.7,2.1-5.4,4.1-8.1,6.2
								c1.5-0.7,3-1.4,4.5-2.1c-2.1-0.2-4.2-0.3-6.4-0.5c1,0.6,2,1.2,3,1.8c-1.2-1.8-2.3-3.7-3.5-5.5c0.4,1.4,0.8,2.8,1.2,4.2
								c0.2-2.4,0.3-4.9,0.5-7.3c-2.5,6.9-2.5,14,0.1,21.1c-0.2-2.5-0.5-5-0.7-7.5c0.1,4.6,0.3,9.2,0.4,13.8
								c0.2-2.6,0.4-5.1,0.5-7.7c-0.7,7.2-1.3,14.5-2,21.7c0.9-5.3,1.8-10.5,2.7-15.8c-1.7,6.8-3.3,13.7-5,20.5
								c1.6-5.3,3.2-10.7,4.9-16c-2.5,6.9-5,13.7-7.6,20.6c1.1-2.7,2.1-5.5,3.2-8.2c-1.9,4-3.8,8.1-5.7,12.1
								c1.4-2.7,2.9-5.4,4.3-8.1c-2.4,4-4.8,7.9-7.2,11.9c1.8-2.7,3.6-5.3,5.5-8c-2.8,3.8-5.7,7.5-8.5,11.3c2.2-2.5,4.3-5.1,6.5-7.6
								c-3.3,3.5-6.6,7.1-10,10.6c2.5-2.4,5.1-4.8,7.6-7.2c-3.8,3.3-7.5,6.5-11.3,9.8c2.8-2.2,5.7-4.3,8.5-6.5
								c-4.1,2.8-8.2,5.7-12.3,8.5c11.3-3.5,22.5-6.9,33.8-10.4c-2.7-1.9-5-4.2-6.9-6.9c-1.6-1.9-2.8-4-3.5-6.4
								c0.2,0.9,0.5,1.9,0.7,2.8c-1.1-4.8-2.2-9.5-3.3-14.3c0.2,2.6,0.5,5.2,0.7,7.8c0.3-5.3,0.6-10.6,0.9-15.9
								c-0.2,1.4-0.5,2.9-0.7,4.3c1.2-4.4,2.3-8.7,3.5-13.1c-0.6,1.5-1.1,2.9-1.7,4.4c2.5-7,5.6-13.8,9.3-20.3
								c1.7-2.1,1.8-4.3,0.5-6.6c-1.4-2.2-3.3-3.8-5.7-4.7c-2.5-0.9-4.6-0.3-6.4,1.7c-2.7,2.3-4.9,5-6.7,8
								c-2.9,6.2-5.2,12.6-6.9,19.3c-0.5,3.1-1.1,6.3-1.6,9.4c-0.7,3.6-0.8,7.3-0.2,11c0.6,5.5,0.6,11.1,0,16.7
								c0.5-2.4,1-4.8,1.5-7.2c-2.2,3.7-4.3,7.4-6.3,11.2c-2.5,4.6-4.9,9.3-7,14.1c-2.2,2.4-2.6,5-1.2,7.9c1.8,2.6,4.2,3.6,7.4,3
								c6-1.9,11.7-4.4,17.1-7.7c5.6-2.9,10.7-6.4,15.4-10.6c3.3-4.8,5.6-10,6.7-15.7c2.4-7.4,3.3-15.1,2.7-23
								c-0.9,5.3-1.8,10.5-2.7,15.8c0.9-4.1,1.9-8.2,2.8-12.3c0.7-1.9,1.4-3.8,2.1-5.8c0.4-2,0.7-3.9,1.1-5.9
								c0.4-3.5,0.2-6.9-0.6-10.3c-0.5-3.3-0.9-6.7-2.1-9.8c-0.4-0.8-0.6-1.6-0.7-2.4c-0.2-2-0.9-3.8-1.9-5.6
								c-0.8-1.8-2.1-3.2-3.8-4c-1.3-1.2-2.9-1.7-4.8-1.3c-3.7,0-7.6,0.8-10.9,2.4c-0.9,0.6-1.9,1.2-2.8,1.8
								c-0.6,1.4-1.2,2.8-1.8,4.2c-0.6,2.5-1.3,5-1.9,7.5c-0.5,1.8-0.8,3.7-1,5.5c-0.5,2.4-0.5,4.8,0,7.2c0.3,3.5,0.4,6.9,0.4,10.4
								c0.4,2.8,0.3,5.6-0.3,8.3c-0.4,2.3-0.7,4.6-1,6.9c-0.7,3.2-0.7,6.5,0,9.7c-0.8,3-0.2,5.4,1.8,7.1c0.8,1.1,1.8,2.1,2.9,2.9
								c1.5,1.7,3.4,2.5,5.8,2.3c2.8,0.6,5.3,0.1,7.6-1.7c4.2-2,8.1-4.5,11.7-7.5c2.7-2.2,5.3-4.3,8-6.5c3.6-2.3,5.7-5.4,6.2-9.5
								c2-10.8,2.6-21.7,1.7-32.6c-0.8,6.8-1.5,13.5-2.3,20.3c2.8-6.6,4.8-13.5,6-20.6c2.1-3.7,3.9-7.5,5.5-11.4
								c1.1-3,1.9-6.2,2.5-9.3c0.5-2.4,0.1-4.7-1.2-6.8c-1.2-4-3.5-7.4-7-10.1c-1.4-1.7-3.2-2.7-5.4-3c-4.4-0.9-8.7,0.5-12.8,1.7
								c-2.9,2.3-5.2,5-6.9,8.3c-2,3.1-3.4,6.4-4,10c-1.5,6-2.7,12.1-3.8,18.1c0.1,5.9,0.2,11.9,0.4,17.8c0-2.3,0-4.6,0-6.9
								c0,2.7,0,5.5,0,8.2c0.1-1.7,0.2-3.4,0.4-5c-0.3,2.8-0.6,5.5-0.9,8.3c2.2,6.5,1.5,12.2-1.9,17c-1,1.6-1.9,3.2-2.7,4.8
								c-1.9,3.2-3,6.6-3.3,10.4c0.5,1.2,0.9,2.4,1.4,3.7c1.3,0.8,2.7,1.7,4,2.5c2.7,0.4,5.5,0.8,8.2,1.2c5.8,0.4,11.7,0.4,17.5,0
								c3.3,0.1,6.7-0.2,9.9-0.9c2.4,0.2,4.4-0.5,6-2c1.6-0.5,2.5-1.5,2.6-3.2C860.9,1281.1,860.6,1279.2,859.1,1277.4z"/>
							<path opacity="0.1091" fill="#131313" d="M817.9,1241.2c6.7-7.2,13.3-14.5,20-21.7c2.3,7.6,4.7,15.1,7,22.7
								c-2.4-9.8-4.8-19.6-7.1-29.4c0.9,3.8,1.8,7.7,2.7,11.5c-1-5.9-1.9-11.8-2.9-17.7c0.5,3.9,1,7.8,1.4,11.8
								c-0.5-5.9-1-11.8-1.5-17.7c0,3.9,0.1,7.8,0.1,11.7c0-5.9,0.1-11.8,0.1-17.7c-0.4,3.8-0.7,7.7-1.1,11.5
								c0.6-5.8,1.2-11.6,1.8-17.4c-0.7,3.7-1.5,7.4-2.2,11.1c0.8-6.8,2.3-13.4,4.2-19.9c0.8-2.9,1.8-5.8,3.1-8.5
								c-3.5,6.2-7.1,12.3-10.6,18.5c2.3-3.3,4.6-6.7,6.9-10c-2.1,2.7-4.2,5.4-6.2,8c2.5-3,5-6,7.5-9c-2.3,2.4-4.5,4.7-6.8,7.1
								c2.6-2.6,5.2-5.2,7.9-7.8c-2.4,2-4.8,4-7.2,6.1c2.7-2.1,5.4-4.3,8.1-6.4c-4.5,2.5-8.9,5.1-13.4,7.6
								c5.7-2.1,11.4-4.3,17.2-6.4c-3.9,0.8-7.8,1.6-11.7,2.4c4.4,0.3,8.7,0.6,13.1,0.9c-3.1-0.9-6.3-1.8-9.4-2.7
								c1.9,1.4,2.9,3.2,2.8,5.5c-0.2,0.9-0.3,1.8-0.5,2.7c-1.1,6.1-2.7,12.1-4.9,17.9c-0.9,4.4-2.1,8.6-3.5,12.8
								c0.5-1.4,0.9-2.7,1.4-4.1c-1.3,3.2-2.7,6.4-4,9.7c0.7-1.4,1.4-2.8,2.1-4.2c-2.2,4.8-4.8,9.5-7.8,13.8
								c5.3-0.7,10.5-1.5,15.8-2.2c-7.1-7.9-14.2-15.8-21.4-23.7c4.7,8.3,9.3,16.6,14,25c-3.4-8.2-6.8-16.4-10.2-24.6
								c2.2,6.7,4.3,13.4,6.5,20.2c-2.3-8.6-4.5-17.2-6.8-25.9c1.1,7.1,2.3,14.2,3.4,21.4c-0.9-9-1.8-18-2.7-27
								c0.2,7.2,0.4,14.4,0.6,21.6c0.2-8.9,0.3-17.8,0.5-26.7c-1.4,9.6-2.8,19.2-4.2,28.8c4.9-10.5,9.9-21.1,14.8-31.6
								c-6.7,8.4-13.4,16.9-20,25.3c4.3-2,8.7-4,13-6c-2.9,0.7-5.8,1.5-8.7,2.2c0.2,0.1,0.3,0.2,0.5,0.2c-0.9-3.2-1.8-6.5-2.6-9.7
								c-1.6,3.9-1.3,7.4,1.1,10.4c1.6,2.5,2.8,5.2,3.8,8.1c-0.4-1.1-0.7-2.3-1.1-3.4c0.8,3,1.6,6,2.4,8.9c-0.3-2.6-0.7-5.2-1-7.8
								c-0.7,2.8-1.4,5.5-2,8.3c-0.3,3.4-1.4,6.5-3.1,9.4c-0.5,1-1.1,1.9-1.7,2.8c-2,2.8-3.8,5.7-5.6,8.7c1-1.4,2-2.8,3-4.2
								c-1.9,2.4-3.8,4.8-5.7,7.2c1.3-1.4,2.5-2.8,3.8-4.1c-2,2.2-4.1,4.5-6.1,6.7c1.5-1.3,3-2.7,4.5-4c-4.9,4.5-10,8.8-15.4,12.7
								c3.9-2.6,7.7-5.2,11.6-7.8c-3,1.8-6,3.5-9,5.3c6.3,1,12.7,1.9,19,2.9c-0.3-3-0.5-6-0.8-9.1c0.1,1.2,0.1,2.5,0.2,3.7
								c0.4-3.7,0.8-7.5,1.2-11.2c-0.2,1.2-0.4,2.5-0.5,3.7c2.8-5.8,2.9-11.8,0.4-17.8c0.1,1.7,0.1,3.4,0.2,5.1c0-5.6,0-11.1,0-16.7
								c-0.3,2-0.6,4-0.9,6c1-9.4,3.4-18.5,6.9-27.2c-0.6,1.7-1.1,3.3-1.7,5c1.9-4.2,3.9-8.5,5.8-12.7c0.4-2.7-0.5-4.9-2.7-6.7
								c-2.1-1.8-4.5-2.2-7.1-1.2c-3.7,2.5-6.9,5.6-9.6,9.2c-3,3.4-5.3,7.2-7,11.3c-1.7,6.4-2.4,12.8-2.3,19.4
								c0.1,5.4,1.3,10.5,3.5,15.3c-0.4-3.3-0.8-6.7-1.3-10c-0.4,5.4-1.2,10.7-2.5,15.9c0.5-1.1,1-2.3,1.5-3.4
								c-5.5,8.1-9.9,16.7-13.1,25.9c-1.4,2.4-1.4,4.7,0,7.1c0.9,2,2.3,3.1,4.2,3.4c2.3,1,4.6,0.7,7-0.9c4.7-1.9,9.1-4.3,13.3-7.2
								c3.5-2.8,6.8-5.9,9.8-9.2c3.5-3.8,6.6-7.9,9.4-12.2c2.8-3.8,4.6-8.1,5.5-12.9c1-3.6,2.1-7.1,3.1-10.7c0.2-2.7-0.1-5.3-1-7.8
								c-0.2-1-0.5-1.9-0.7-2.9c-0.3-4-1.4-7.9-3-11.5c-0.3,3.3-0.6,6.7-0.9,10c0.3-0.7,0.5-1.3,0.8-2c1.1-2,1.5-4.2,1-6.4
								c-0.1-2.3-1-4.3-2.6-6c-5-1.5-9.9-3-14.9-4.5c-2-1.1-3.9-1-5.6,0.3c-2.2,0.7-3.4,2.3-3.7,4.9c-3,12.2-4.8,24.5-5.4,36.9
								c0.2,2.4,0.5,4.9,0.9,7.3c0.6,2.2,1.3,4.6,2.4,6.6c0.7,2.2,2.1,3.7,4.1,4.6c1.6,1.3,3.4,2.1,5.4,2.5c2.4,0.6,4.9,0.7,7.3,0.3
								c2.4-0.8,4.6-2,6.5-3.7c2.5-1.8,4.8-3.8,6.8-6.2c1.9-2.9,3.8-5.8,5.7-8.7c3.6-5.9,6.6-12.1,8.8-18.6c2-6.8,4.2-13.6,6.6-20.4
								c0.5-2.4,1.1-4.8,1.6-7.2c0.3-3.9,0.9-7.7,1.7-11.5c-0.6-8.2-5.3-14.5-14-19.1c-1.3-2.6-3.5-3.8-6.4-3.7
								c-2.9,0.6-5,2.2-6.4,4.8c-3.2,3.6-5.9,7.6-8,11.9c-3.2,5.2-5.7,10.7-7.6,16.5c-0.7,5.7-0.9,11.5-0.4,17.2
								c0.5,5.8,1.5,11.4,3.2,17c4.6,10.1,9.3,20.2,14.2,30.2c2.3,2,4.6,3.9,7,5.8c-5.6-13.7-11.3-27.4-16.9-41
								c-1.3,1.6-2.6,3-4.1,4.4c-2.9,2.9-4.8,6.4-5.5,10.5c-1.3,3.4-2.1,7-2.3,10.7c0.1,2.4,0.7,4.6,1.6,6.7c1.8,2.3,4.1,3.5,7,3.5
								c4.6,1.2,9.3,1.7,14,1.7c3.5,0,7,0.1,10.4,0.1c4.3,0.6,8-0.5,11-3.3c0.4-0.8,0.9-1.6,1.3-2.4c1.6-2.5,1.6-4.9-0.1-7.4
								c-2-2.2-4.4-3.2-7.3-3.2c-4.7-0.9-9.5-1.2-14.4-0.9C829.9,1239.2,823.8,1239.8,817.9,1241.2z"/>
							<path opacity="0.1091" fill="#131313" d="M836.3,1267.8c1.8-0.9,3.5-1.7,5.3-2.6c6.3,1.4,12.6,2.9,18.9,4.3
								c-2.7-2.6-5.3-5.2-8-7.8c2.7,3.1,5.3,6.1,8,9.2c-1.5-4.1-3-8.2-4.5-12.4c0.8,4,1.6,8.1,2.4,12.1c-0.5-6.6-0.9-13.2-1-19.9
								c-1.3-8.7-2.1-17.4-2.2-26.2c0,2.6,0.1,5.2,0.1,7.8c0.2-4.5,0.3-9,0.5-13.5c-0.3,2.6-0.5,5.2-0.8,7.9c0.6-4.5,1.2-9,1.7-13.6
								c-0.5,2.6-1,5.3-1.6,7.9c1-4.5,2-9,2.9-13.5c-0.8,2.6-1.6,5.3-2.4,7.9c1.4-4.4,2.8-8.9,4.2-13.3c-1.1,2.6-2.1,5.2-3.2,7.8
								c1.8-4.3,3.6-8.7,5.4-13c-2.9,5-5.8,9.9-8.7,14.9c1.8-2.6,3.6-5.1,5.4-7.7c-3.4,3.6-6.7,7.3-10.1,10.9
								c6.5-5.5,13-11.1,19.5-16.6c-5,3.5-10.1,7-15.1,10.5c5.2-3,10.5-6,15.7-9c-5.2,2.6-10.3,5.2-15.5,7.8
								c2.7-0.9,5.4-1.8,8.1-2.6c-4.7,0.9-9.5,1.8-14.2,2.7c4.3,0.5,8.5,1,12.8,1.5c-4.7-1.1-9.4-2.2-14.2-3.3
								c4.3,1.9,8.7,3.7,13,5.6c-3.6-2-7.2-4-10.8-6.1c2,3,4.1,5.9,6.1,8.9c-1.4-4.4-2.7-8.7-4.1-13.1c-0.7,1.4-0.8,2.8-0.3,4.3
								c0.8,8.1,2.6,16,5.6,23.7c-0.5-4.5-1-9.1-1.5-13.6c-0.3,5-0.6,10-1,14.9c0.3-2.2,0.5-4.4,0.8-6.7c-0.7,4.5-1.5,9.1-2.2,13.6
								c0.6-2.1,1.2-4.3,1.8-6.4c-1.8,6.5-3.7,13-5.5,19.5c0.6-2.2,1.3-4.3,1.9-6.5c-1.7,4.2-3.4,8.3-5.1,12.5
								c0.9-2.1,1.9-4.2,2.8-6.2c-2.1,3.9-4.3,7.8-6.4,11.7c1.2-2,2.4-4,3.6-5.9c-2.5,3.6-5.1,7.3-7.6,10.9
								c3.1-3.5,6.2-7.1,9.3-10.6c-2.1,1.8-4.1,3.7-6.2,5.5c3.3-2.3,6.6-4.5,10-6.8c-6.7,3-13.3,6-20,9c6.3-1.8,12.6-3.5,18.9-5.3
								c-4.3-0.5-8.7-1.1-13-1.6c3.4,0.9,6.8,1.7,10.2,2.6c-0.1-0.1-0.1-0.1-0.2-0.2c0.4,1,0.7,2.1,1.1,3.1
								c-1.2-5.5-3.9-10.3-8-14.5c1,1.9,2,3.7,3,5.6c-0.8-2.4-1.7-4.8-2.5-7.2c0.7,2.2,1.3,4.3,2,6.5c-0.6-3.3-1-6.6-1-9.9
								c-0.3-5.1-0.4-10.2-0.4-15.3c-0.5,4.1-1.1,8.1-1.6,12.2c4.3-8.8,8.5-17.7,12.8-26.5c-6.5,7-12.9,14-19.4,21
								c3.8-2.6,7.7-5.3,11.5-7.9c-3.7,1.8-7.4,3.7-11.2,5.5c1,0.2,2,0.4,3.1,0.6c-1.4-4.6-2.9-9.1-4.3-13.7
								c-1.3,1.4-2.6,2.8-3.8,4.2c-1.3,1.6-1.7,3.4-1.1,5.2c0,2.3,1.2,4.1,3.6,5.4c3.1,3.2,6.3,6.4,9.4,9.6
								c-2.8-3.3-5.6-6.6-8.4-9.8c2.3,3.7,4.6,7.4,6.8,11.1c-1.9-4-3.8-7.9-5.7-11.9c1.5,4.2,2.9,8.5,4.4,12.7
								c-1-4.6-2.1-9.2-3.1-13.9c0.9,7.1,1.9,14.2,2.8,21.4c-0.2-2.3-0.4-4.6-0.6-6.8c0.1,4.6,0.1,9.3,0.2,13.9
								c0.8-4.5,1.6-8.9,2.4-13.4c-1.2,4-2.4,7.9-3.5,11.9c0.8-2.1,1.6-4.2,2.4-6.3c-2.6,7.6-5.9,15-10,21.9c1.1-2,2.1-4.1,3.2-6.1
								c-2.2,3.3-4.3,6.7-6.5,10c1.3-1.9,2.6-3.9,3.9-5.8c-2.5,3-4.9,6-7.4,9c1.5-1.8,3.1-3.6,4.6-5.4c-2.8,2.8-5.6,5.6-8.4,8.4
								c1.7-1.7,3.5-3.3,5.2-5c-3.1,2.4-6.1,4.9-9.2,7.3c1.9-1.5,3.9-3,5.8-4.5c-3.3,2.1-6.6,4.2-9.9,6.3c8-1.5,16.1-3,24.1-4.6
								c-1.1-2.7-2.2-5.3-3.3-8c0.5,2.4,1,4.8,1.5,7.2c-0.1-3.9-0.1-7.8-0.2-11.7c-0.2,1.2-0.5,2.4-0.7,3.6
								c2.4-6.3,3.2-12.8,2.3-19.6c-0.1,1.4-0.1,2.7-0.2,4.1c0.5-5.1,0.9-10.2,1.4-15.4c-0.3,1.6-0.6,3.3-0.8,4.9
								c0.9-5.8,2.4-11.4,4.6-16.8c1.9-5.5,4.2-10.8,7-15.9c0.2-2.7-0.7-4.8-2.8-6.4c-2-1.8-4.3-2.3-6.9-1.5
								c-3.5,2.2-6.5,4.9-8.9,8.3c-2.8,3.1-4.9,6.5-6.4,10.4c-1.4,5.7-2.2,11.4-2.6,17.3c-0.2,4-0.3,8-0.2,12
								c0.1-1.4,0.1-2.7,0.2-4.1c-0.8,7.2-1.5,14.4-2.2,21.6c0.7-2.3,1.5-4.7,2.2-7c-5.2,7.8-9.2,16.1-12,25.1
								c-1.3,2.4-1.3,4.7,0.1,7.1c1.3,2.4,3.2,3.7,5.9,3.9c4.2-0.8,8.2-2.3,11.9-4.6c3.9-1.9,7.3-4.4,10.3-7.5
								c2.8-3.1,5.6-6.3,8.3-9.6c2.1-3.6,4.1-7.3,5.9-11.1c-1.1,2-2.1,4-3.2,6c2.2-3.7,4.1-7.5,5.9-11.4c1.4-2.8,2.3-5.7,2.6-8.9
								c0.3-2.3,0.5-4.5,0.8-6.8c-0.1-4.8-1.1-9.5-2.9-14.1c0.3,4.1,0.7,8.1,1,12.2c0.8-5.1,1.6-10.2,2.3-15.4
								c0.6-2.9,0.9-5.8,1-8.7c0.1-4.8-1.4-8.9-2.6-13.6c-0.9-2.6-2.8-4.3-5.4-5c-3-0.8-6-0.9-9-0.1c-3.2,0.6-5.4,2.4-6.6,5.7
								c-2.5,4.4-4.5,9-6,13.8c-1.3,5.5-2,11.2-2.1,16.9c-0.7,3.8-1.1,7.5-1.3,11.4c-0.4,2-0.3,4,0.2,6c0.2,5.2,1.3,10.1,3.4,14.8
								c-0.4-1.2-0.8-2.4-1.2-3.5c0.4,2.9,1.6,5.4,3.3,7.7c2,1.7,4.2,2.9,6.8,3.4c1.3,0.1,2.6,0.2,3.9,0.3c4.3,0.3,8-1,11.1-4
								c5.7-4.6,10-11.2,13-17.8c2.9-6.8,5.2-13.8,6.7-21.1c0.8-5.8,1.3-11.5,1.5-17.4c-0.5,1-1.1,2.1-1.6,3.1
								c1.4-2.2,2.7-4.5,3.9-6.9c1.2-2.5,2.7-4.9,4.4-7.2c0.9-1.5,1.6-3.1,2.1-4.7c1.3-2.6,1.3-5.3,0-8.3c-0.5-1.2-0.9-2.4-1.4-3.6
								c-1.5-4-4-7.2-7.4-9.6c-4.1-1.8-8.5-0.7-12.7-0.2c-1.2,0.4-2.5,0.8-3.7,1.2c-2.6,2.4-4.8,5.1-6.5,8.2
								c-1.7,2.3-3.1,4.8-4.4,7.4c-1.8,3-3.2,6.2-4,9.7c-0.6,4.6-0.9,9.2-0.8,13.8c0.1,7.1,1.1,14.1,2.9,21
								c-0.4-4.4-0.7-8.8-1.1-13.2c0,6.6-0.3,13.3-0.9,19.9c0.5-1,1.1-2.1,1.6-3.1c-1.5,2.7-3,5.4-4.4,8.2c-1.7,3-2.6,6.2-2.8,9.6
								c-0.2,1.7-0.5,3.4-0.8,5.1c-0.2,2.2,0.2,4.3,1,6.3c0.8,0.9,1.6,1.8,2.4,2.7c2.8,1.1,5.8,1.7,8.8,1.8c2.5,0.4,4.9,0.5,7.4,0.3
								c3,0.2,6-0.2,8.9-1.1c0.7-0.4,1.3-0.8,2-1.2c4.7-2.9,4.5-9.7-0.1-12.5c-4.3-1.3-8.7-1.8-13.2-1.5
								C845,1265.8,840.6,1266.4,836.3,1267.8z"/>
							<path opacity="0.1091" fill="#131313" d="M837.4,1238.9c7.5-3.8,15-7.5,22.5-11.3c0.9,3.6,1.8,7.2,2.7,10.8
								c-2.4-11.3-4.5-22.7-6-34.1c0.5,4,0.9,7.9,1.4,11.9c-0.5-5.9-1-11.8-1.5-17.7c0,4.1,0.1,8.2,0.1,12.3
								c0.6-11,1.1-21.9,1.7-32.9c-1.1,7.1-2.2,14.3-3.3,21.4c1.7-11.1,4.3-22,7.9-32.7c-0.9,2.9-1.7,5.9-2.6,8.8
								c1.8-4.7,3.7-9.3,5.5-14c-5.4,10.4-10.8,20.8-16.2,31.2c4.9-7.6,9.8-15.1,14.6-22.7c-4.9,7-9.7,14-14.6,20.9
								c5.5-7,11-13.9,16.5-20.9c-5.5,6.4-10.9,12.8-16.4,19.2c6-6.2,12-12.4,18-18.6c-8.4,7.4-16.8,14.8-25.1,22.3
								c11.4-5.3,22.8-10.5,34.2-15.8c-10,1.9-20,3.8-30.1,5.6c4.6,1.3,9.3,2.6,13.9,3.8c-3.1-1.5-6.1-3-9.2-4.5
								c1.4,1.4,2.7,2.8,4.1,4.2c-0.7-0.9-1.3-1.9-2-2.8c1.7,3.7,3.4,7.5,5.1,11.2c-0.9-2.7-1.8-5.4-2.7-8.1
								c0.2,6.5,0.4,13,0.6,19.6c0.3-2.2,0.6-4.4,0.8-6.6c-1.3,4.6-2.9,9-5,13.2c-1.9,4.2-3.8,8.4-5.6,12.7c0.6-1.2,1.1-2.3,1.7-3.5
								c-1.6,2.9-3.2,5.9-4.9,8.8c0.7-1.1,1.5-2.2,2.2-3.3c-2,2.7-3.9,5.5-5.9,8.2c4.8-0.1,9.6-0.3,14.3-0.4
								c-4.7-7.7-9.4-15.4-14.1-23.1c2.6,5.9,5.1,11.8,7.7,17.6c-1.5-4.6-3-9.3-4.4-13.9c0.8,3.3,1.6,6.5,2.3,9.8
								c-0.9-5-1.9-9.9-2.8-14.9c0.4,3.5,0.8,7.1,1.2,10.6c-0.8-8.8-1.7-17.7-2.5-26.5c-0.2,10-0.4,20.1-0.6,30.1
								c4.7-13.1,9.5-26.1,14.2-39.2c-8.1,10.7-16.1,21.5-24.2,32.2c4.9-4.2,9.8-8.4,14.7-12.7c-4.4,3.2-8.8,6.3-13.2,9.5
								c3.8-0.7,7.6-1.3,11.3-2c-4.1-0.6-8.3-1.2-12.4-1.8c3.2,3.5,6.2,7.3,8.9,11.2c-0.7-1.1-1.5-2.1-2.2-3.2
								c0.7,1.4,1.5,2.7,2.2,4.1c-0.5-2.7-0.9-5.3-1.4-8c-0.4,4.1-0.8,8.1-1.3,12.2c0.1-1.3,0.3-2.5,0.4-3.8
								c-0.7,3.9-1.4,7.7-2.1,11.6c0.4-1.2,0.7-2.4,1.1-3.6c-1.6,2.4-3.4,4.7-5.2,6.9c-2.4,2.8-4.6,5.9-6.5,9.2
								c0.8-1.1,1.6-2.2,2.4-3.3c-1.8,2.2-3.6,4.4-5.4,6.6c1-1,1.9-2.1,2.9-3.1c-1.9,1.9-3.9,3.8-5.8,5.8c1.1-1,2.3-2,3.4-2.9
								c-2.2,1.8-4.4,3.6-6.6,5.3c1.3-0.9,2.6-1.8,3.9-2.8c-2.3,1.5-4.6,3-6.9,4.5c1.5-0.8,3-1.7,4.4-2.5c-2.6,1.3-5.1,2.6-7.7,3.8
								c5.1,1.7,10.1,3.4,15.2,5.1c0.3-6,1.4-11.8,3.3-17.5c-0.5,1.1-1.1,2.2-1.6,3.3c4.5-4.2,6.7-9,6.7-14.5
								c0.4-6.2,0.7-12.3,0.9-18.5c-0.3,2.2-0.7,4.3-1,6.5c1.2-6.4,2.5-12.9,3.7-19.3c-0.3,1.8-0.7,3.7-1,5.5
								c1.5-4.6,3-9.1,4.6-13.7c-0.7,2-1.5,3.9-2.2,5.9c2.1-4.5,4.2-9.1,6.3-13.6c0.8-2.9,0-5.3-2.2-7.3c-2.4-1.8-4.9-1.9-7.6-0.5
								c-4.3,3.4-8.1,7.3-11.4,11.7c-3.6,4.2-6.5,8.8-8.8,13.8c-0.6,4.3-0.6,8.7,0,13c0.1,4.2,1,8.4,2.6,12.3
								c-0.1-1.6-0.2-3.2-0.2-4.7c-0.9,6.3-2,12.5-3.3,18.6c0.8-2.3,1.7-4.6,2.5-6.9c-5.8,8.2-10.4,17-13.6,26.6
								c-0.1,1-0.3,2.1-0.4,3.1c0.2,2,1,3.6,2.3,5c0.5,1.1,1.4,1.9,2.5,2.2c1.6,1.2,3.4,1.2,5.3,0c5.5-1.7,10.6-4.3,15.1-7.8
								c3.4-2.5,6.4-5.4,9.2-8.7c2.9-3.8,6-7.5,9.1-11.1c1.3-1.7,2.5-3.3,3.7-5c1.2-1.3,1.8-2.9,2-4.7c1.4-5.8,2.6-11.7,3.6-17.5
								c0.1-2.8-0.4-5.4-1.4-8c-1.4-2.4-3.2-4.5-5.5-6.2c1.4,2,2.7,4,4.1,6.1c-0.4-2-0.8-4-1.2-6.1c-0.2,2.2-0.3,4.4-0.5,6.7
								c1.1-1.7,2.2-3.4,3.1-5.3c0.9-1.7,1.5-3.5,1.8-5.4c1.5-4.2,2.1-8.6,1.7-13.1c-0.6-2.8-1.2-5.5-1.8-8.3
								c-0.7-1.1-1.4-2.2-2.1-3.3c-1.7-0.8-3.3-1.6-5-2.4c-2-0.5-4-0.6-6-0.5c-1.4-0.2-2.7,0-4,0.4c-1.8,0.4-3.7,0.9-5.4,1.6
								c-1,1.2-2,2.4-3,3.6c-0.7,2.6-1.4,5.3-2.1,7.9c-2.2,10.3-4.4,20.5-6.6,30.8c-1,3.5-1.3,7-1.1,10.5c-0.2,2.7,0,5.3,0.7,7.9
								c0.3,3.4,1.4,6.6,3.2,9.6c0.6,1.1,1.1,2.2,1.7,3.3c0.9,0.6,1.7,1.3,2.6,1.9c0.8,0.5,1.6,0.9,2.4,1.4c0.9,0.7,2,1.1,3.2,1.1
								c2.3,0.4,5.6,0.8,7.8,0c2.5-0.9,4.3-2.7,6.1-4.5c4.8-4.8,8.9-10.5,12.2-16.3c3-5.3,5.8-10.9,8.4-16.4
								c1.3-2.8,2.5-5.6,3.4-8.5c2.1-6.2,2.5-12.6,1.1-19.4c-0.1,1.1-0.1,2.2-0.2,3.2c0.3-2.7,0.5-5.4,0.8-8.1
								c-0.5,1.1-1.1,2.2-1.6,3.3c0.6-0.7,1.1-1.5,1.7-2.2c1.3-1.5,1.9-3.3,1.9-5.2c0.5-1.7,0.2-3.4-0.9-4.9
								c-2.9-6.5-5.8-13-8.6-19.5c-1.2-2.9-3.3-4.1-6.4-3.6c-4.6,1.9-7.9,5.2-10,9.9c-4.8,7.3-9,14.8-12.8,22.6
								c-2.9,4.2-4.1,8.8-3.3,13.6c0.4,5.7,1.1,11.4,2.7,17c1.5,5.4,3.6,10.5,6,15.6c0.3-14,0.7-27.9,1-41.9
								c1.3,13.6-1.1,25.9-7.1,36.9c-0.7,1.3-1.4,2.7-1.9,4c-0.3,2.2-0.7,4.3-1,6.5c-0.5,3.4-0.4,6.7,0.4,10c0.7,2.2,2.2,3.6,4.4,4
								c3,1.5,6.2,2.4,9.5,2.7c2.4,0.3,4.8,0.6,7.3,0.8c3.3,0.7,6-0.1,8.1-2.5c1.7-2.1,2.6-4.4,2.8-7.1c0-2.7-1.1-4.8-3.3-6.2
								c-4-1.3-8.1-1.8-12.3-1.6C845.5,1237,841.4,1237.5,837.4,1238.9z"/>
							<path opacity="0.1091" fill="#131313" d="M793.1,1265.6c8.5-7.9,16.9-15.8,25.4-23.7c3,4.8,5.9,9.5,8.9,14.3
								c-2.6-5.3-5.3-10.6-7.9-15.9c1.7,5.9,3.5,11.8,5.2,17.8c-1.1-5.5-2.1-11.1-3.2-16.6c0.4,2.9,0.8,5.8,1.2,8.7
								c-1.1-8-2.1-15.9-3.2-23.9c0.3,2.8,0.7,5.5,1,8.3c-0.3-4.9-0.6-9.8-0.9-14.7c0,2.6,0.1,5.3,0.1,7.9c0.2-4.9,0.4-9.7,0.5-14.6
								c-0.2,2.5-0.5,5-0.7,7.5c0.6-4.6,1.2-9.3,1.9-13.9c-0.5,2.4-0.9,4.7-1.4,7.1c1-4.4,2.1-8.9,3.1-13.3
								c-0.6,2.2-1.3,4.4-1.9,6.6c1.4-4.2,2.8-8.4,4.2-12.6c-0.8,2-1.6,4.1-2.4,6.1c1.8-4,3.5-7.9,5.3-11.9
								c-3.3,4.7-6.5,9.4-9.8,14.1c2.3-1.9,4.6-3.8,6.9-5.7c-1.2,0.7-2.4,1.4-3.6,2.1c2.2-1.5,4.5-2.8,6.9-3.9
								c-1.2,0.3-2.5,0.7-3.7,1c1.8,0,3.4,0.5,4.9,1.4c-0.7-0.7-1.5-1.3-2.2-2c1.2,1.2,2.5,2.4,3.7,3.6c-0.6-0.8-1.1-1.6-1.7-2.4
								c0.8,1.5,1.6,2.9,2.5,4.4c-0.3-0.9-0.6-1.8-1-2.7c0,1.4,0.1,2.8,0.1,4.1c0.5-2.1,0.9-4.2,1.4-6.3c-0.9,1.7-1.7,3.4-2.6,5.1
								c0.8-1,1.6-1.9,2.4-2.9c-1.5,1.4-3,2.8-4.5,4.2c1-0.7,2.1-1.4,3.1-2.1c-1.7,0.9-3.5,1.8-5.2,2.8c-2.1,1.5-3.3,3.5-3.7,6.1
								c-1.3,2.7-2.2,5.6-2.7,8.6c-1.3,5.2-2.5,10.4-3.4,15.7c0.6-2.1,1.2-4.2,1.8-6.3c-1.8,6-3.6,12.1-5.4,18.1
								c0.7-2.3,1.4-4.6,2.1-6.9c-1.7,4-3.3,8.1-5,12.1c1.1-2.4,2.2-4.8,3.3-7.2c-2.2,4-4.3,8.1-6.5,12.1
								c4.8-6.2,9.5-12.4,14.3-18.6c-10.8,8-21.5,16-32.3,24c14-4.8,28-9.6,42.1-14.4c-9.4-5.3-18.8-10.6-28.2-16
								c4.1,6.9,8.2,13.8,12.3,20.7c-3.2-7.5-6.4-14.9-9.7-22.4c1,2.6,2,5.2,3,7.7c-1.4-4.8-2.8-9.6-4.2-14.3
								c0.7,5.2,1.5,10.5,2.2,15.7c-0.2-4.9-0.4-9.8-0.6-14.7c-0.1,2.5-0.2,5-0.3,7.5c0.4-7.1,0.8-14.2,1.3-21.2
								c-1.2,6.3-2.4,12.7-3.5,19c1.4-3.3,2.2-6,4.2-8.8c-1.1,1-2.1,1.9-3.2,2.9c1.9-1,3.7-2,5.6-2.9c-1.9-0.2-3.8-0.5-5.8-0.7
								c1.3,0.8,2.7,1.6,4,2.4c-1.1-1.8-2.2-3.7-3.3-5.5c1,2.5,2,5.1,3,7.6c-0.4-2.1-0.7-4.2-1.1-6.3c-0.2,1.7-0.4,3.5-0.5,5.2
								c0.9-2.3,1.9-4.5,2.8-6.8c-2.3,2.6-3.8,5.5-4.4,8.7c-0.8,2.3-0.5,4.6,0.9,6.9c-0.2-1-0.4-2.1-0.6-3.1
								c0.1,1.8,0.3,3.7,0.4,5.5c0-1.2,0.1-2.3,0.1-3.5c-0.1,1.8-0.2,3.6-0.3,5.3c0.3-1.3,0.6-2.6,0.9-3.9
								c-0.6,3.5-1.5,6.9-2.7,10.2c-1.4,4-2.7,7.9-3.9,12c0.8-2.2,1.7-4.3,2.5-6.5c-3,7.1-6.2,14-9.7,20.8c1.3-2.5,2.6-4.9,3.9-7.4
								c-2.3,3.6-4.6,7.1-6.9,10.7c1.7-2.5,3.4-5,5.2-7.5c-2.7,3.5-5.4,7-8.1,10.5c2.1-2.5,4.2-4.9,6.4-7.4
								c-3.3,3.4-6.6,6.7-9.9,10.1c2.6-2.4,5.1-4.8,7.7-7.2c-3.8,3.2-7.5,6.4-11.3,9.6c3-2.3,5.9-4.5,8.9-6.8
								c-4.2,2.9-8.5,5.7-12.7,8.6c12-3.9,24.1-7.8,36.1-11.7c-2.1-2.2-4.2-4.4-6.2-6.7c-1.6-1.7-3-3.6-4.3-5.6c0.4,1,0.7,2,1.1,3
								c-1.5-4.8-3.1-9.6-4.6-14.4c0.3,2.8,0.7,5.5,1,8.3c0.1-5.5,0.1-11,0.2-16.4c-0.2,1.5-0.5,3-0.7,4.5c1.1-4.5,2.3-9.1,3.4-13.6
								c-0.6,1.5-1.1,2.9-1.7,4.4c2.5-7.3,5.8-14.2,9.7-20.8c1.6-2.1,1.7-4.2,0.3-6.4c-1.4-2.1-3.3-3.7-5.7-4.7
								c-2.4-0.9-4.4-0.4-6.2,1.5c-2.5,2.2-4.6,4.7-6.3,7.6c-2.5,4.8-4.5,9.7-6,14.9c-2.2,5.7-3.4,11.6-3.6,17.8
								c0.3,3,0.5,6,0.8,9.1c0.9,3.9,0.7,7.8-0.5,11.7c0.4-1.2,0.8-2.4,1.2-3.5c-5.2,8.6-10.1,17.3-14.6,26.3
								c-0.7,1.4-1.5,2.8-2.2,4.2c0.2,1.3,0.4,2.6,0.6,3.9c1,0.9,2,1.7,3,2.6c3.1,0.8,6,0.4,8.7-1.2c6.8-2.6,13.2-6.2,19.4-10
								c3-1.6,5.8-3.5,8.3-5.7c2.8-1.5,4.6-3.7,5.5-6.8c0.6-2.2,1.2-4.5,1.7-6.8c1.2-4.9,2-9.9,2.5-14.9c-1.4,4.4-2.8,8.8-4.2,13.3
								c1.7-3,3.4-5.9,4.9-8.9c1.1-2.6,2.2-5.1,3.3-7.7c1.5-3.7,2.3-7.6,2.4-11.6c0.4-2.6,0.5-5.2,0.2-7.9c0.1-2.6-0.2-5.2-1.2-7.7
								c-0.4-2.4-1.2-4.7-2.3-6.9c-0.8-2.1-1.8-4.2-2.9-6.2c0.7,2.3,1.5,4.7,2.2,7c0-3.9-0.7-7.7-2.2-11.3c-1.3-2.3-3.1-3.9-5.5-4.9
								c-1.9-0.8-3.5-0.6-5,0.7c-2.3,1.2-4.2,2.8-5.8,4.9c-2.4,2.3-4.1,5.1-5.1,8.2c-2,9.3-3.9,18.6-5.9,27.9
								c0.5-1.2,0.9-2.4,1.4-3.6c-1,1.8-1.9,3.6-2.9,5.3c-1.5,3.3-2.4,6.7-2.7,10.2c-0.6,2.3-0.7,4.6-0.4,6.9
								c-0.1,3.1,0.4,6,1.6,8.9c0.9,1,1.8,1.9,2.6,2.9c1.4,1,2.8,2.1,4.2,3.1c2.2,0.7,4.5,1,6.8,0.8c2,0.1,4-0.1,6-0.5
								c2-1.3,4-2.5,6-3.8c4-3,7.7-6.3,11.1-9.9c2.2-1.9,4-4,5.6-6.3c2.1-1.8,3-4.2,2.8-7c0.5-5.8,1.1-11.6,1.6-17.5
								c-0.9,4.2-1.9,8.5-2.8,12.7c1-2.7,1.9-5.4,2.9-8c-0.5,1.3-1,2.7-1.5,4c2.7-6.8,5.6-13.6,8.4-20.3c2.4-3.6,4.3-7.4,5.6-11.4
								c1.5-4,3.2-8.2,3.2-12.5c0.2-1.6-0.1-3.2-0.9-4.6c-1.2-1.9-2.4-3.8-3.8-5.7c-0.6-0.7-1.2-1.4-1.8-2.1c-2-2.1-4.1-4.2-6.3-6.1
								c-3-1.8-6.7-1.3-10-0.8c-2.3,0.5-4.6,1-6.9,1.5c-1.1,1.1-2.2,2.1-3.3,3.2c-2.6,3.4-4.8,7-6.5,10.9c-0.9,2.7-1.8,5.3-2.6,8
								c-1.5,5.6-2.5,11.2-2.9,17c-0.4,6-0.2,12.1,0.6,18.1c0.2-5.3,0.4-10.5,0.6-15.8c-1.5,11.4-3,22.8-4.5,34.1
								c0.3-1.3,0.6-2.5,0.9-3.8c-2.3,3.4-4.6,6.8-6.8,10.3c-1.9,2.7-2.8,5.7-2.8,8.9c0.5,3.5,2.5,5.7,6.1,6.5
								c5.6,1.3,11.2,2,17,2.2c7.1,0.6,14.2,0.9,21.3,0.7c5.5,0.8,10.4-0.3,14.9-3.2c0.8-0.8,1.5-1.6,2.3-2.3
								c0.5-1.2,1.1-2.5,1.6-3.7c-0.6-1.2-1.1-2.4-1.7-3.7c-3-2.3-6.4-3.3-10.1-3.1c-9-1-18.1-0.9-27.1-0.6
								c-4.2-0.1-8.3,0.2-12.5,0.9C799.4,1262.2,796.1,1263.3,793.1,1265.6z"/>
							<path opacity="0.1091" fill="#131313" d="M854.8,1294.6c-6.1-0.5-12.1-1-18.2-1.6c5.3,0.7,10.6,1.4,15.8,2.1
								c-2.7-0.6-5.4-1.3-8-1.9c1.9,0.7,3.8,1.4,5.8,2c-2.3-1-4.5-2-6.8-3c1.6,0.9,3.1,1.8,4.7,2.7c-1.8-1.2-3.6-2.5-5.4-3.7
								c1.2,1.1,2.4,2.1,3.6,3.2c-1.6-2.5-3.2-5-4.8-7.5c0.5,1.2,1.1,2.4,1.6,3.6c-0.7-2.2-1.3-4.5-2-6.7c0.2,1.2,0.5,2.3,0.7,3.5
								c-0.3-2-0.7-4.1-1-6.1c0,1.1,0,2.3,0.1,3.4c0.6-4.9,1-9.9,1.2-14.9c-0.3-5.1-0.8-10.3-1.3-15.4c0,1.4-0.1,2.8-0.1,4.3
								c0.2-3.4,0.5-6.8,0.7-10.1c-0.2,1.4-0.4,2.8-0.6,4.1c1-6.2,1.9-12.3,3.6-18.3c1.4-4.6,3.3-8.8,5.4-13.2
								c-1.7,1.8-3.4,3.7-5.1,5.5c1.1-0.3,1.8-0.4,2.9-0.3c-1-0.3-1.9-0.6-2.9-0.9c1.2,0.4,2.5,0.8,3.7,1.2
								c-1.8-1.3-3.6-2.6-5.4-3.9c0.4,0.7,0.8,1.4,1.2,2.1c-0.6-2.4-1.2-4.8-1.8-7.1c-0.1,1.3-0.2,2.7-0.3,4
								c0.2-1.2,0.4-2.5,0.5-3.7c-0.5,1.4-1.1,2.9-1.6,4.3c1.4-1.8,2.8-3.7,4.2-5.5c-2.5,1.9-5,3.8-7.6,5.7c-2,2.9-2.3,6.2-0.9,9.7
								c0.6,4.6,1.2,9.3,1.8,13.9c-0.3-3-0.5-6.1-0.8-9.1c0,5.3,0,10.5,0,15.8c0.2-3.2,0.5-6.4,0.7-9.6c-0.8,8.5-1.7,17-2.5,25.5
								c0.5-3.3,1-6.6,1.4-9.9c-1.2,5.3-2.4,10.7-3.6,16c1-3.3,2-6.7,3-10c-1.9,5.3-3.7,10.6-5.6,15.9c1.5-3.3,2.9-6.5,4.4-9.8
								c-2.5,5.1-5,10.3-7.6,15.4c1.9-3.1,3.8-6.3,5.7-9.4c-3.1,4.9-6.3,9.7-9.4,14.6c4.8-5.7,9.6-11.4,14.4-17.1
								c-5.5,5.7-11,11.4-16.5,17.1c5.3-5.1,10.6-10.2,15.9-15.3c-6,5.1-12,10.1-17.9,15.2c5.8-4.5,11.6-9,17.4-13.4
								c-6.4,4.2-12.9,8.4-19.3,12.7c6.2-3.7,12.5-7.5,18.7-11.2c-3.4,1.5-6.8,3-10.1,4.5c5.7-1.8,11.4-3.6,17-5.4
								c-6.2,1.2-12.5,2.4-18.7,3.6c4.9-0.6,9.9-1.1,14.8-1.7c-5.2-0.2-10.4-0.4-15.6-0.6c4.1,0.5,8.3,1,12.4,1.5
								c-4.2-1.2-8.4-2.3-12.5-3.5c3.4,1.4,6.8,2.8,10.2,4.1c-3.5-3.2-7.1-6.4-10.6-9.5c1.2,1.2,2.3,2.4,3.5,3.6
								c-1.4-2.4-2.9-4.8-4.3-7.2c0.7,1.3,1.5,2.6,2.2,3.9c-1.1-2.3-2.2-4.5-3.2-6.8c0.4,2.5,0.8,5,1.1,7.5
								c0.3-3.9,0.6-7.8,0.9-11.7c-0.1,1-0.3,2.1-0.4,3.1c1.2-7.2,2.1-14.4,3.8-21.5c-0.5,0.9-1,1.7-1.5,2.6
								c0.3-0.1,0.6-0.3,0.9-0.4c-0.9,0.2-1.9,0.4-2.8,0.6c1.5-0.2,3.1-0.3,4.6-0.5c-2.1-0.6-4.2-1.2-6.3-1.8
								c1.3,0.6,2.6,1.3,3.9,1.9c-1-0.7-2-1.5-3-2.2c1.4,1.2,2.9,2.4,4.3,3.6c-1-3.2-2-6.4-3-9.6c-0.1,2.8-0.2,5.6-0.3,8.4
								c0.4-2.4,0.9-4.8,1.3-7.2c-0.8,1.6-1.6,3.2-2.5,4.8c0.8-1,1.5-2,2.3-2.9c-2.3,1.7-4.1,3.9-5.2,6.6c-0.4,10.5,0.2,21,1.8,31.5
								c0-6.5-0.1-12.9-0.1-19.4c-0.5,8.4-0.9,16.8-1.4,25.1c1-6.6,1.9-13.3,2.9-19.9c-1.7,8.3-3.4,16.5-5,24.8
								c1.9-6.6,3.7-13.3,5.6-19.9c-2.7,8.2-5.4,16.4-8,24.6c1.3-3.3,2.5-6.7,3.8-10c-2.1,4.7-4.2,9.5-6.3,14.2
								c1.7-3.2,3.4-6.5,5-9.7c-2.6,4.5-5.2,9.1-7.9,13.6c2.1-3.1,4.2-6.2,6.3-9.3c-3.2,4.3-6.3,8.5-9.5,12.8
								c2.4-2.9,4.8-5.7,7.3-8.6c-3.6,3.9-7.1,7.9-10.7,11.8c2.7-2.6,5.5-5.2,8.2-7.9c-4,3.5-8.1,7-12.1,10.5c3-2.3,6-4.6,8.9-6.9
								c-4.3,3-8.5,6-12.8,9.1c10.5-4.5,20.9-9,31.4-13.4c-4-2.6-7.9-5.2-11.9-7.7c0.8,0.6,1.6,1.2,2.4,1.9c-0.6-2-1.1-4-1.7-6
								c0,0.8,0.1,1.6,0.1,2.4c0-3.8,0-7.6-0.1-11.4c-0.1,1-0.3,2-0.4,2.9c1.2-8.3,3.5-16.3,7-24c-0.4,1.1-0.8,2.2-1.2,3.3
								c1.8-3.9,3.7-7.8,5.5-11.6c-0.6,1.1-1.2,2.2-1.8,3.3c2-3.5,4-7.1,6.1-10.6c1.2-1.9,1.2-3.8-0.2-5.7c-1.5-2-3.4-3.6-5.6-4.8
								c-2-1.1-3.9-0.8-5.6,0.7c-2.2,1.7-3.9,3.7-5.2,6.1c-6,12-12,24.1-14,37.5c-1.2,7.9-0.2,16.1,0.3,24c0.6-2.4,1.1-4.8,1.7-7.2
								c-2.3,4.1-4.5,8.3-6.5,12.5c0.2,4.6,0.6,9.1,1.1,13.6c0.3,3,2,4.8,4.9,5.5c3.1,0.2,5.8-0.7,8-2.8c4.4-2.6,8.5-5.8,12.2-9.4
								c5-4.2,9.5-8.9,13.4-14.1c2.4-4.9,3.6-9.9,3.6-15.2c0.1-3.6,0.2-7.2,0.3-10.9c-0.6,2.5-1.2,4.9-1.8,7.4c1-3.1,2-6.2,3-9.3
								c-0.5,1.2-1,2.3-1.5,3.5c1.7-3,2.9-6.2,3.6-9.5c0.8-2.2,1.2-4.5,1-6.8c0-2.9-0.1-5.9-0.4-8.8c-0.2-2.5-0.5-4.9-1-7.4
								c-1.7-5.7-4.5-12.9-10.9-14.5c-3.5-0.9-7.2-0.1-10.7,0.9c-1.6,0.6-3.2,1.3-4.8,2.1c-1.7,0.4-2.7,1.6-3.1,3.4
								c-0.8,2.3-1.6,4.7-2.4,7c-1.1,4.7-2.2,9.3-3.2,14c-0.6,3.2-1.2,6.3-1.7,9.5c-0.4,3.4,0.2,6.6,1.7,9.8
								c-0.3-1.1-0.5-2.2-0.8-3.3c0.7,5.1,1.2,10.2,1.8,15.3c0.1-1.2,0.1-2.5,0.2-3.7c-0.6,2.5-0.6,5,0.2,7.4
								c1.3,6.9,5.6,12.3,12.7,16.3c1.9,1.6,3.8,1.8,5.8,0.8c3.4-0.6,6.2-2.5,8.2-5.8c2.5-2.8,5-5.5,7.5-8.3
								c4.3-4.8,7.9-10,10.9-15.6c1.1-4,1.8-8.2,1.9-12.3c0.5-4.1,0.4-8.2-0.2-12.4c-0.4,2.4-0.8,4.9-1.2,7.3
								c0.7-3.3,1.4-6.6,2.2-9.8c-0.5,1.2-1,2.4-1.6,3.5c5.9-8.5,12.9-18.1,12.5-29.1c-0.8-4.4-2.5-8.4-5.2-11.9
								c-1.5-1.5-2.9-3.1-4.4-4.6c-3.4-2.3-8.4-2-12.2-1.4c-1.6,0.2-3.3,0.6-4.9,1c-1.7,0.9-3,2.1-3.9,3.7c-3.9,5.4-6.3,12-8.2,18.4
								c-1.7,5.8-2.9,11.7-3.7,17.7c-0.5,2.7-0.7,5.3-0.6,8c0.3,3.7,0.3,7.4,0,11.1c0-1.1,0.1-2.2,0.1-3.3c-1,7.4-2.2,14.7-3.5,22
								c0.2-1,0.5-2,0.7-3.1c-0.9,2.6-1.8,5.2-2.8,7.8c-1.4,3.5-2.4,7.2-2.8,11.1c0.4,1.2,0.8,2.4,1.2,3.7c1.8,2.2,4.1,3.2,6.9,3.1
								c2.8,0.6,5.6,0.9,8.4,0.7c5,0.3,9.9,0.2,14.9-0.4c2.3-0.4,4.6-0.9,6.9-1.3c1.1-0.9,2.2-1.7,3.3-2.6c1.3-1.9,1.4-3.7,0.5-5.5
								C858.4,1296.8,857.1,1295.4,854.8,1294.6z"/>
							<path opacity="0.1091" fill="#131313" d="M870.5,1264c-5.3-0.3-10.7-0.6-16-0.9c3.5,0.1,7.1,0.2,10.6,0.4
								c-0.2-0.1-0.4-0.1-0.6-0.2c1.1,0.6,2.3,1.2,3.4,1.8c-1.1-0.8-2.1-1.7-3.2-2.5c1.3,1.8,2.6,3.6,3.9,5.4
								c-0.2-1-0.4-2.1-0.5-3.1c-0.4,2.2-0.7,4.4-1.1,6.6c2.4-4,3.8-8.4,4.2-13.1c0.6-10.5-0.2-21-2.4-31.4c0.3,2.6,0.6,5.3,1,7.9
								c-0.2-4.4-0.5-8.9-0.7-13.3c0,2.8,0,5.6,0.1,8.3c0.2-4.6,0.3-9.2,0.5-13.8c-0.3,2.9-0.6,5.8-0.9,8.7
								c0.6-4.7,1.2-9.4,1.7-14.2c-0.6,3-1.2,6-1.8,8.9c1-4.8,2-9.6,3-14.4c-0.9,3-1.9,6.1-2.8,9.1c1.5-4.8,3-9.6,4.4-14.4
								c-4.1,7.9-8.3,15.8-12.4,23.7c8.9-11.1,17.8-22.2,26.7-33.2c-8.2,6.5-16.3,13-24.5,19.4c6.4-4,12.8-8.1,19.3-12.1
								c-6.5,3.5-13,7-19.5,10.5c6.7-2.7,13.3-5.4,20-8.1c-9.3,2.8-18.7,5.7-28,8.5c5.8-0.9,11.6-1.9,17.4-2.8
								c-6.1,0.4-12.2,0.8-18.3,1.3c8,1.9,16,3.8,24,5.7c-7.4-2.7-14.8-5.4-22.2-8.1c3.8,2.5,7.6,5,11.5,7.6
								c-3.2-2.8-6.4-5.5-9.7-8.3c4.4,8.8,8.9,17.6,13.3,26.4c-1.2-6-2.3-11.9-3.5-17.9c-0.1,5.2-0.2,10.4-0.4,15.6
								c0.3-2.1,0.5-4.2,0.8-6.3c-0.8,4.7-1.7,9.3-2.5,14c0.6-1.9,1.1-3.9,1.7-5.8c-2,8.6-5.1,16.9-9,24.8c0.8-1.8,1.6-3.5,2.4-5.3
								c-2.1,3.7-4.2,7.3-6.3,11c1-1.6,2-3.3,2.9-4.9c-2.5,3.3-4.9,6.7-7.4,10c3.7-3.6,7.4-7.1,11.1-10.7c-1.7,1-3.5,2-5.2,3
								c1.2-0.6,2.5-1.1,3.7-1.7c-2.3,0.9-4.5,1.8-6.8,2.7c1.3-0.3,2.7-0.5,4-0.8c-1.7,0.2-3.5,0.4-5.2,0.6c1.4,0,2.7-0.1,4.1-0.1
								c-0.4-0.1-0.9-0.2-1.3-0.4c0.7,0.8,1.3,1.6,2,2.3c-0.8-1.2-1.6-2.4-2.5-3.5c0.4,0.9,0.9,1.8,1.3,2.7c-0.3-1.4-0.6-2.8-1-4.2
								c0,2.2-0.1,4.4-0.1,6.6c0.8-2.1,1.1-4.2,1-6.3c0.2-2.1-0.5-4.1-1.9-5.8c0.5,1.2,1.1,2.4,1.6,3.6c-0.5-3.5-0.4-10.4-0.5-11.2
								c0,1.6-0.1,3.2-0.1,4.8c0.8-7.4,0.8-14.8,0.2-22.1c-0.5,4.3-1.1,8.6-1.6,12.9c4.7-9.4,9.4-18.7,14-28.1
								c-7.5,8.6-15.1,17.3-22.6,25.9c5.9-1.5,11.8-2.9,17.7-4.4c-5.3-4.6-10.5-9.2-15.8-13.8c-1.2,0.3-2.5,0.6-3.7,0.9
								c-1.2,0.4-2.3,0.7-3.5,1.1c-1.8,1.2-2.7,2.7-3,4.7c-0.7,1.2-0.6,2.4,0.1,3.6c0.5,2.5,2,4.3,4.7,5.4c3.7,3,7.4,6.1,11.1,9.1
								c-3.3-3.2-6.6-6.5-9.9-9.7c3.1,4.9,6.2,9.7,9.3,14.6c-2.1-5.9-4.3-11.8-6.4-17.7c1.4,7,2.9,13.9,4.3,20.9
								c-0.3-2.1-0.5-4.3-0.8-6.4c0.6,6,1.2,12,1.9,18c0.6-5.6,1.2-11.2,1.8-16.8c-1.3,4-2.6,7.9-3.9,11.9c0.7-1.9,1.5-3.7,2.2-5.6
								c-1.6,3.8-3.2,7.6-4.8,11.3c0.9-1.7,1.9-3.4,2.8-5.1c-2.5,5-5.4,9.7-8.7,14.1c1.3-1.4,2.6-2.8,3.8-4.3
								c-3.3,4.1-6.9,7.8-10.9,11.2c1.5-1.1,3.1-2.2,4.6-3.4c-2.8,2-5.7,4-8.5,5.9c1.6-1,3.3-1.9,4.9-2.9c-3,1.7-6,3.4-9,5.1
								c5.3,1.6,10.5,3.1,15.8,4.7c-0.5-3.4-1-6.8-1.4-10.2c0.1,1.2,0.2,2.5,0.2,3.7c0.7-4.6,1.4-9.1,2.1-13.7
								c-0.3,1.2-0.6,2.4-1,3.6c2.9-3.9,4.6-8.2,5-12.8c1-5.9,1.7-11.9,2.2-17.8c-0.3,1.8-0.6,3.5-0.9,5.3
								c1.6-8.7,4.1-17.2,7.4-25.4c-0.6,1.7-1.2,3.4-1.8,5.1c1.9-4.2,3.9-8.4,5.8-12.6c0.6-2.8-0.3-5.1-2.5-6.9
								c-2.2-1.8-4.6-2.1-7.3-1c-4,2.8-7.4,6.2-10.3,10.1c-3.2,3.7-5.8,7.7-7.8,12.2c-0.5,3.9-0.7,7.8-0.6,11.8c-0.1,4,0.2,8,0.9,12
								c-0.1-1.3-0.2-2.6-0.3-4c-1.3,7.6-2.4,15.3-3.3,22.9c0.9-2.3,1.7-4.6,2.6-6.9c-5.6,7.9-9.8,16.5-12.7,25.8
								c-0.2,1.1-0.3,2.1-0.5,3.2c0.2,2,1,3.7,2.3,5c0.5,1.1,1.4,1.9,2.6,2.2c1.7,1.1,3.6,1.1,5.6-0.1c3.9-1.5,7.7-3.4,11.3-5.6
								c3.5-2.2,6.7-4.9,9.5-7.9c-1.4,1.3-2.8,2.5-4.2,3.8c2.5-1.9,4.8-4.1,6.8-6.5c2.3-2.4,4.3-5.1,6-8c-1.1,1.6-2.2,3.1-3.3,4.7
								c3.3-3.9,6-8.2,8.2-12.9c2.2-3.2,3.6-6.6,4.3-10.3c0.9-3,1.4-6.1,1.5-9.3c-0.1-5.6-1.8-10.8-4.9-15.6
								c0.7,5.8,1.5,11.5,2.2,17.3c0.9-5.1,1.7-10.2,2.6-15.3c-0.3,1.2-0.6,2.4-0.9,3.7c0.7-2.4,1.4-4.7,2-7.1
								c1.2-2.5,1.9-5.1,2-7.8c0.8-2.6,0.9-5.2,0.3-7.8c0.1-2.6-0.5-5.1-1.7-7.4c-0.4-0.7-0.8-1.4-1.2-2.1c-0.7-1.1-1.3-2.2-2-3.3
								c-2-1.3-4.1-1.9-6.4-1.9c-2.4-0.6-4.7-0.5-7.1,0.2c-1.2,0.6-2.4,1.1-3.5,1.7c-2.3,2.8-4.1,5.8-5.4,9.1
								c-2.8,5.8-5.4,11.8-6.2,18.2c-0.3,4.3-1,8.6-2.4,12.7c-0.7,2.9-1.1,5.9-1,8.9c-0.1,6.6,1.7,14.2,4.5,20.1
								c0.4,1.9,1.5,3.3,3.1,4.2c1.5,1.2,3.3,2.1,5.3,2.5c2.6,0.4,5.2,0.4,7.8,0c2.8,0.2,5.2-0.7,7.2-2.6
								c5.2-3.8,9.1-10.3,12.1-15.8c2-3.6,3.7-7.4,5.1-11.3c1.5-4.3,2.8-8.7,4.1-13.1c0.9-3.7,1.5-7.5,1.9-11.2
								c0-4.5-0.6-9-1.7-13.4c-2-6.3-5.8-11.5-11.4-15.5c3,8.4,6,16.8,9,25.2c1-1.5,2-3,3-4.5c-0.8,1-1.5,2-2.3,3
								c1.5-1.5,2.9-3,4.4-4.5c-0.9,0.9-1.8,1.8-2.7,2.6c4.2-3.3,7.6-7.2,10.2-11.9c1.3-2.2,1.8-4.6,1.4-7.1
								c0.6-5.8-1.3-11-5.8-15.6c-2.9-2.6-6.3-3.8-10.4-3.6c-3,0-5.5,1.2-7.4,3.5c-3.9,3-7.5,6.4-10.6,10.3
								c-2.3,2.8-4.6,5.6-6.9,8.4c-3.3,3.1-4.8,6.7-4.7,11c0.8,6.5,1.8,12.9,3.1,19.4c0.6,4.2,1.6,8.4,3,12.4
								c-0.4-4.7-0.7-9.5-1.1-14.2c0.3,6.1-0.5,12.1-2.4,18c0.6-1,1.2-2,1.8-3c-1.8,2.8-3.7,5.5-5.2,8.4c-0.7,1.4-1.3,2.9-2,4.3
								c-0.3,1.5-0.4,3-0.2,4.6c0.1,1.8,0.3,3.6,0.4,5.4c0,0.8,0.2,1.6,0.5,2.4c0.6,3.1,2.4,5.5,5.4,7.3c3,1.3,6.2,1.8,9.4,1.6
								c3.3,0.2,6.4-0.4,9.4-1.7c1.8-1.7,2.6-3.8,2.4-6.3C873.2,1267.8,872.4,1265.7,870.5,1264z"/>
							<path opacity="0.1091" fill="#131313" d="M804.5,1254.4c7.1-10.3,14.1-20.6,21.2-30.8c3.3,9.3,6.6,18.6,9.9,27.9
								c-2.2-9.3-4.5-18.5-6.7-27.8c0.8,3.5,1.6,6.9,2.4,10.4c-0.9-5.6-1.8-11.1-2.7-16.7c0.4,3.4,0.8,6.9,1.3,10.3
								c-0.5-5.5-0.9-11-1.4-16.5c0,3.4,0.1,6.8,0.1,10.1c0.1-5.4,0.1-10.8,0.2-16.3c-0.3,3.3-0.6,6.5-0.9,9.8
								c0.6-5.3,1.1-10.6,1.7-15.9c-0.6,3.1-1.2,6.2-1.8,9.3c1-5.1,2.1-10.3,3.1-15.4c-0.9,2.9-1.8,5.9-2.6,8.8
								c1.5-4.9,3-9.8,4.5-14.8c-1.1,2.8-2.2,5.5-3.4,8.3c1.9-4.7,3.8-9.3,5.7-14c-2.9,5-5.8,10-8.7,15c2.6-3.1,5.2-6.2,7.8-9.3
								c-1.9,1.9-3.7,3.7-5.6,5.6c2.3-2.1,4.7-4.3,7-6.4c-2,1.6-4,3.2-6,4.8c2.4-1.7,4.8-3.4,7.2-5.2c-3.7,1.9-7.4,3.8-11.1,5.7
								c2.4-0.8,4.8-1.6,7.1-2.3c-1.5,0.3-3,0.6-4.6,1c2.1-0.3,4.3-0.6,6.4-0.8c-1.4,0-2.8,0.1-4.2,0.1c1.8,0.2,3.7,0.4,5.5,0.6
								c-1.3-0.3-2.6-0.5-3.9-0.8c1.5,0.6,2.9,1.2,4.4,1.8c-1.1-0.6-2.2-1.2-3.3-1.7c0.3,0.6,0.7,1.3,1,1.9c-0.1-1-0.2-2-0.2-2.9
								c-0.1,1.7-0.2,3.4-0.3,5.1c0.3-0.9,0.5-1.9,0.8-2.8c-4.3,5.4-6.4,11.6-6.3,18.8c-1.1,5.3-2.2,10.5-3.6,15.8
								c0.5-1.8,1.1-3.6,1.6-5.3c-2.1,7.5-4.9,14.6-8.3,21.6c0.9-2,1.8-4,2.7-6c-2,3.6-4.1,7.3-6.1,10.9
								c5.2-5.2,10.3-10.4,15.5-15.6c-9.2-0.5-18.3-0.9-27.5-1.4c10.6,5.3,21.1,10.7,31.7,16c-7.8-8.5-15.6-17-23.3-25.5
								c4.7,7.9,9.3,15.7,14,23.6c-3.6-7.9-7.2-15.8-10.8-23.8c1.2,3.1,2.3,6.1,3.5,9.2c-1.5-5-3-10-4.5-15c1,6.4,2,12.8,3,19.2
								c-0.8-8.4-1.5-16.7-2.3-25.1c0.1,6.3,0.3,12.6,0.4,18.9c0.2-8.1,0.4-16.2,0.6-24.4c-1.3,8.2-2.6,16.4-3.9,24.6
								c3.2-8,6.4-16.1,9.6-24.1c-3.9,6.1-7.7,12.2-11.6,18.3c3.4-2.8,6.9-5.7,10.3-8.5c-2.9,1.6-5.9,3.1-8.8,4.7
								c1.6-0.1,3.2-0.2,4.9-0.2c-1.9-0.6-3.7-1.2-5.6-1.9c0.8,0.9,1.6,1.8,2.4,2.6c-0.7-2.1-1.3-4.1-2-6.2c0,1.2,0.1,2.4,0.1,3.7
								c0.7-2.4,1.4-4.7,2.1-7.1c-0.5,0.9-1,1.7-1.5,2.6c-1.3,1.4-1.9,3-1.9,4.7c-0.4,1.5-0.1,3,1.1,4.3c1.4,2.9,2.5,6,3.2,9.2
								c-0.4-1.4-0.8-2.7-1.2-4.1c0.3,2.1,0.7,4.3,1,6.4c-0.1-1.6-0.1-3.2-0.2-4.8c-0.7,5.1-1.9,10.1-3.4,15.1
								c0.5-1.8,1-3.5,1.4-5.3c-1.1,3-2.3,6.1-3.5,9.1c0.7-1.8,1.5-3.6,2.2-5.4c-3.1,6.3-6.2,12.5-9.5,18.6c1.1-2,2.1-4,3.2-6.1
								c-2.1,3.1-4.2,6.3-6.3,9.4c1.4-2.1,2.8-4.1,4.2-6.2c-2.4,3-4.8,6-7.2,9c1.7-2,3.5-4,5.2-6.1c-2.9,2.9-5.8,5.7-8.7,8.6
								c2.1-2,4.2-4,6.3-5.9c-3.2,2.7-6.5,5.4-9.7,8.1c2.5-1.9,4.9-3.7,7.4-5.6c-3.7,2.4-7.4,4.8-11.1,7.2
								c10.3-2.8,20.5-5.6,30.8-8.4c-1.1-2.7-1.8-5.5-2.2-8.5c-1.7-6-3.5-12-5.6-17.9c0.5,3.2,1,6.5,1.5,9.7
								c-0.3-5.5-0.5-11.1-0.8-16.6c-0.3,1.8-0.5,3.7-0.8,5.5c0.4-6.3,1.7-12.4,4-18.2c1.3-4.2,3-8.2,5.1-12.1
								c0.6-1.4,1.3-2.9,1.9-4.3c-0.2-1.1-0.4-2.2-0.6-3.3c-0.8-1-1.6-1.9-2.4-2.9c-1.5-1.5-3.1-2-5-1.7c-1.5-0.3-2.7,0.2-3.6,1.4
								c-1.6,1-3,2.2-4.2,3.8c-1.6,1.5-3,3.2-4.1,5.1c-1.8,2.1-3,4.4-3.9,7c-0.9,3.2-1.8,6.5-2.6,9.7c-1.3,4.7-1.7,9.5-1.1,14.2
								c0.1,1.9,0.3,3.7,0.5,5.6c0.1,3.2,1.1,6.2,2.7,9.1c-0.5-3.2-1-6.5-1.5-9.7c0.7,5.1,0.2,10-1.5,14.8c0.4-1.2,0.9-2.3,1.3-3.5
								c-2.4,3.8-4.6,7.6-6.7,11.6c-2.5,4.5-4.7,9.2-6.6,14c-0.6,1.3-1.1,2.6-1.7,3.9c0.3,1.3,0.5,2.5,0.8,3.8
								c0.9,0.9,1.8,1.8,2.8,2.7c2.6,1.1,5.1,0.9,7.5-0.5c5.8-2,11.1-4.9,16.3-8.1c2.5-1.3,4.8-2.9,6.9-4.8c2.4-1.3,3.9-3.3,4.4-6.1
								c1.7-3.9,3.3-7.9,5-11.8c-1.1,2-2.1,4-3.2,6.1c2.3-3.5,4.8-7.1,6.6-10.9c0.8-2,1.6-3.9,2.4-5.9c0.9-2.1,1.6-4.2,2.3-6.4
								c0.9-3.5,1.1-6.9,1-10.5c-0.1-1.4-0.1-2.8-0.2-4.3c-0.1-4.6-1.1-9-2.9-13.3c0.1,0.9,0.3,1.9,0.4,2.8
								c-0.1-1.3-0.2-2.5-0.3-3.8c-0.3-2.5-1.2-4.8-2.8-6.8c-1-1.6-2.3-2.2-4-1.9c-2-0.2-3.9,0.2-5.8,1.2c-2.5,0.8-4.6,2.3-6.2,4.3
								c-3.1,7.2-6.1,14.5-9.3,21.7c-0.8,1.8-1.7,3.7-2.5,5.5c-1.1,3.6-1.7,7.2-1.7,10.9c-0.4,3.1-0.1,6.1,1.1,9
								c-0.1,2.8,0.7,5.1,2.5,7c0.8,1.3,1.8,2.3,3.3,2.9c1.8,1.6,3.9,2.3,6.4,2.2c2.4,0.9,4.7,0.6,6.8-0.9c3.7-1.5,7.1-3.5,10.2-6
								c2.3-1.8,4.6-3.6,6.9-5.4c3.3-2,5-4.8,5.2-8.4c1.1-4.6,2.1-9.2,3.2-13.8c-0.6,1.9-1.2,3.9-1.8,5.8c3.1-7.1,5.5-14.5,7.8-21.8
								c1.3-4.7,2.9-9.3,4.7-13.9c0.9-3.9,1.8-7.7,2.6-11.6c-0.4,1.2-0.7,2.4-1.1,3.5c0.7-2.1,1.3-4.2,2-6.3c0.5-2.4,0-4.6-1.4-6.5
								c-1-2.3-2.4-4.4-4.2-6.2c-5-3.9-11-5.7-17.9-5.2c-1.2,0.4-2.5,0.8-3.7,1.2c-2.6,2.4-4.8,5.2-6.5,8.2
								c-1.7,2.4-3.1,4.8-4.4,7.5c-1.8,3-3.1,6.3-4,9.7c-1.2,5.7-1.8,11.5-1.8,17.3c-0.1,4.5,0.3,8.9,1.2,13.3
								c1.4,10.8,4.9,21.1,10.4,30.8c1.9,6.3,5.9,11,12,14c-4.6-13.9-9.2-27.7-13.8-41.6c-6.2,3.8-10.5,9-12.9,15.6
								c-2.5,4.3-3.6,8.9-3.5,13.9c0.3,3.3,2.1,5.5,5.4,6.5c5,1.2,10,2,15.1,2.1c6.3,0.6,12.6,0.8,18.9,0.6c5,0.7,9.4-0.4,13.3-3.2
								c0.6-0.8,1.2-1.6,1.8-2.5c0.5-1.2,1-2.5,1.4-3.7c-0.5-1.2-1-2.4-1.5-3.7c-2.6-2.2-5.5-3.3-8.9-3.1c-7.9-1-16-1-24-0.6
								c-3.7-0.1-7.4,0.2-11,0.9C810,1251,807,1252.1,804.5,1254.4z"/>
							<path opacity="0.1091" fill="#131313" d="M824.1,1252.6c7.5-8.1,15-16.2,22.5-24.2c1.9,6.5,3.8,13.1,5.7,19.6
								c-2.3-10.8-4.2-21.6-5.6-32.5c0.4,3.5,0.8,6.9,1.2,10.4c-0.4-5.4-0.9-10.8-1.3-16.2c0,3.5,0.1,7,0.1,10.5
								c0.1-5.5,0.1-10.9,0.2-16.4c-0.3,3.5-0.7,7-1,10.5c0.6-5.5,1.2-10.9,1.7-16.4c-0.7,3.4-1.4,6.9-2.1,10.3
								c0.8-6.5,2.2-12.8,4.1-19c0.7-2.9,1.7-5.7,3-8.4c-3.5,6-7,12.1-10.5,18.1c2.2-3.2,4.3-6.5,6.5-9.7c-2.1,2.7-4.2,5.4-6.3,8.1
								c2.4-3,4.8-5.9,7.3-8.9c-2.3,2.4-4.7,4.9-7,7.3c2.6-2.6,5.2-5.2,7.9-7.8c-2.5,2.1-5.1,4.3-7.6,6.4
								c5.7-3.6,11.4-7.2,17.1-10.9c-7.2,3.4-14.3,6.8-21.5,10.2c7.6-1.6,15.2-3.3,22.8-4.9c-6.8,0.6-13.6,1.1-20.5,1.7
								c3.9,1.6,7.8,3.2,11.6,4.8c-2.8-1.6-5.6-3.3-8.5-4.9c1,1.4,2,2.9,3,4.3c-0.6-1-1.3-2-1.9-3c0.9,6.7,1.3,13.4,1.3,20.1
								c0-1.6-0.1-3.3-0.1-4.9c-0.9,6-2.2,11.9-4,17.7c0.5-1.6,1-3.3,1.5-4.9c-1.3,3.6-2.6,7.3-3.8,10.9c0.8-1.6,1.5-3.2,2.3-4.8
								c-3,7.2-6.6,14-10.9,20.5c5.6-3.6,11.1-7.2,16.7-10.7c-6.9-5.7-13.9-11.4-20.8-17.1c3.8,7.3,7.6,14.7,11.4,22
								c-2.7-7.6-5.3-15.2-8-22.8c1.7,5.9,3.5,11.9,5.2,17.8c-1.7-8-3.4-16-5.1-24c0.9,6.3,1.9,12.6,2.8,18.9
								c-0.7-8.1-1.4-16.3-2.1-24.4c0.1,6.5,0.2,12.9,0.3,19.4c0.3-8.2,0.6-16.3,0.9-24.5c-1.4,8.7-2.7,17.5-4.1,26.2
								c4.9-9.8,9.8-19.6,14.7-29.4c-6.9,8.5-13.9,17-20.8,25.4c4.3-3.2,8.6-6.4,12.8-9.6c-3.8,2.3-7.5,4.6-11.3,6.9
								c1.9-0.5,3.7-0.9,5.6-1.4c-2.4-0.3-4.9-0.6-7.3-0.9c0.9,0.6,1.9,1.1,2.8,1.7c-1-2-2.1-3.9-3.1-5.9c0.2,1,0.3,1.9,0.5,2.9
								c0.5-2.4,0.9-4.8,1.4-7.2c-0.5,0.7-1,1.4-1.5,2.1c-1.3,2.1-1.6,4.3-1,6.6c2.3,2.6,4.6,5.3,6.8,7.9c-1-1.2-2-2.5-2.9-3.7
								c1.8,3,3.7,5.9,5.5,8.9c-1.4-2.8-2.7-5.5-4.1-8.3c1.1,3.3,2.3,6.6,3.4,9.9c-0.6-3.3-1.2-6.6-1.8-9.9
								c-0.1,3.8-0.1,7.7-0.2,11.5c0.2-1.7,0.3-3.3,0.5-5c-0.4,3.8-0.9,7.5-1.3,11.3c0.5-1.6,0.9-3.3,1.4-4.9
								c-1.3,3.2-2.6,6.3-3.9,9.5c0.7-1.6,1.4-3.2,2-4.8c-1.6,3.1-3.3,6.2-4.9,9.3c0.9-1.6,1.8-3.2,2.7-4.8
								c-1.6,2.8-3.3,5.6-4.9,8.4c1.1-1.5,2.2-3.1,3.3-4.6c-2.5,3.9-5.4,7.7-8.5,11.1c-3.5,3.4-7.3,6.6-11.4,9.4
								c1.8-1.4,3.7-2.8,5.5-4.2c-1.1,0.7-2.1,1.3-3.2,2c6.2,1,12.4,2.1,18.6,3.1c-0.4-3-0.9-5.9-1.3-8.9c0.1,1.2,0.1,2.5,0.2,3.7
								c0.4-3.8,0.7-7.6,1.1-11.4c-0.2,1.2-0.4,2.4-0.6,3.7c2.9-5.8,3.3-11.8,1.1-18c0,1.6,0.1,3.3,0.1,4.9
								c0.1-5.4,0.2-10.9,0.3-16.3c-0.3,2-0.6,3.9-0.9,5.9c1.1-9.3,3.4-18.2,6.9-26.8c-0.6,1.7-1.2,3.4-1.8,5.1
								c1.9-4.2,3.9-8.5,5.8-12.7c0.5-2.8-0.4-5-2.6-6.7c-2.2-1.8-4.5-2.1-7.1-1.1c-3.8,2.6-7.1,5.8-9.9,9.6
								c-3.1,3.5-5.5,7.4-7.3,11.7c-1.3,6.1-1.9,12.3-1.6,18.5c0.3,5.1,1.3,10,3.2,14.7c-0.3-3.2-0.7-6.5-1-9.7
								c-0.6,6.7-1.5,13.3-2.8,19.9c0.7-2.4,1.4-4.7,2.1-7.1c-5.4,7.9-9.7,16.4-12.7,25.5c-1.1,1.8-1.3,3.5-0.4,5.3
								c0.3,1.9,1.3,3.5,3,4.7c1.1,0.4,2.3,0.7,3.4,1.1c4.2-0.8,8.1-2.2,11.7-4.4c3.6-2,7-4.3,10.1-7c2.2-2.4,4.4-4.8,6.5-7.2
								c3.8-4.6,7.1-9.6,10.1-14.8c1.7-2.4,2.8-5.1,3.2-8.1c0.8-4.6,1.5-9.1,2.1-13.7c0-1.9-0.2-3.7-0.6-5.5c-0.2-3-0.6-5.9-1.2-8.9
								c0.1,1,0.2,2.1,0.3,3.1c-0.1-1.9-0.2-3.8-0.3-5.7c-0.5,2.4-0.9,4.8-1.4,7.2c0.7-1.9,1.4-3.8,2.1-5.7c1-3.5,1.4-7.1,1.2-10.7
								c0.3-2.5,0.1-4.9-0.8-7.2c-0.2-2.5-1.2-4.7-3-6.6c-1.2-0.5-2.4-1-3.5-1.5c-0.3-0.1-0.6-0.3-0.9-0.4c-2.4-1-4.8-1.1-7.3-0.4
								c-2.3,0.1-4.6,0.6-6.8,1.4c-2.4,1.5-3.7,3.6-3.9,6.4c-1.4,4.2-2.2,8.6-2.5,13.1c-0.6,6.6-1.3,13.2-1.9,19.9
								c0.2-1.3,0.3-2.6,0.5-3.9c-1.4,3.7-2.4,7.5-2.8,11.4c0,2.5,0,5.1,0,7.6c0.2,4.2,1.5,9,3.2,12.9c1,2.8,3,4.7,5.8,5.7
								c1.1,0.4,2.2,0.8,3.3,1.2c3.5,0.7,6.9,0.5,10.3-0.6c2.3-1.1,4.3-2.6,6-4.5c8.2-8.1,13.1-19.4,17-30c1.3-3.3,2.4-6.6,3.6-10
								c0.9-2.9,1.3-5.9,1.2-8.9c-0.1-4,0.5-7.8,2-11.6c0.3-2.1,1-4.1,2.1-6c1-2.3,0.9-4.6-0.2-6.8c-3.9-5.3-7.9-10.5-11.8-15.8
								c-1.3-2.6-3.5-3.8-6.4-3.7c-2.9,0.6-5,2.1-6.4,4.6c-3.1,3.6-5.8,7.5-8,11.7c-3.2,5.1-5.7,10.5-7.4,16.3
								c-0.5,4-0.6,8-0.3,11.9c0,4,0.5,8,1.3,11.9c0.6,2.6,1.2,5.2,1.8,7.8c1.5,5.1,3.4,10,5.8,14.7c-0.9-11.8-1.9-23.6-2.8-35.5
								c-1.4,9.4-2.8,18.8-4.2,28.3c0.7-1.1,1.4-2.2,2.1-3.2c-2,2.2-3.5,4.7-4.3,7.6c-0.5,1.6-1,3.1-1.5,4.7c-0.9,2.3-1.4,4.6-1.6,7
								c0,2.2,0.4,4.4,1.1,6.5c1.5,2.2,3.5,3.4,6.1,3.5c3.9,1.1,8,1.7,12.1,1.6c3,0,6,0,9,0c3.9,0.5,7-0.7,9.5-3.4
								c0.4-0.7,0.9-1.3,1.3-2c1.4-2.5,1.4-4.9-0.1-7.4c-2.5-2.8-5.7-4.1-9.7-3.8c-6.2-0.5-12.4-0.5-18.6,0.1
								C829.7,1248.4,826.5,1249.7,824.1,1252.6z"/>
							<path opacity="0.1091" fill="#131313" d="M864.6,1277.7c-0.9-0.3-1.8-0.5-2.8-0.8c1.2,0.6,2.4,1.2,3.6,1.7
								c-0.9-0.8-1.1-1.2-1.4-2.3c-0.1,1-0.3,2.1-0.4,3.1c0.3-1.6,0.7-3.2,1-4.7c-0.3,1-0.6,2-1,2.9c0.6-1.5,1.3-3,1.9-4.5
								c-0.5,0.9-1,1.8-1.4,2.7c2.6-3.1,3.9-6.7,4-10.8c0.3-5-0.1-9.9-1.2-14.8c0.2,1.9,0.4,3.8,0.6,5.8c-0.9-7.5-1.3-15-1.2-22.5
								c0,2.1,0.1,4.2,0.1,6.3c0.2-3.9,0.5-7.9,0.7-11.8c-0.2,2.2-0.4,4.4-0.6,6.6c0.6-4,1.2-8,1.7-11.9c-0.4,2.3-0.9,4.5-1.3,6.8
								c0.9-4,1.9-8,2.8-12c-0.7,2.3-1.4,4.6-2.1,6.9c1.3-4,2.6-8.1,3.9-12.1c-3.3,6-6.6,12-9.9,18c8.4-3,16.8-6,25.2-9.1
								c-11.6-1-23.1-2.1-34.7-3.1c5.2,1.7,10.5,3.5,15.7,5.2c-5.3-3.4-10.6-6.8-15.9-10.2c5.2,10.5,10.3,20.9,15.5,31.4
								c-1.5-7.4-3.1-14.7-4.6-22.1c0.4,8.1,0.8,16.2,1.1,24.2c0.2-5.3,0.4-10.7,0.6-16c-0.9,7.7-1.7,15.4-2.6,23
								c0.4-2.6,0.8-5.1,1.1-7.7c-1.2,4.8-2.4,9.6-3.6,14.4c0.7-2.4,1.4-4.8,2.2-7.3c-1.7,4.5-3.5,9.1-5.2,13.6c1-2.3,2-4.5,3-6.8
								c-2.2,4.2-4.4,8.5-6.7,12.7c1.3-2.1,2.5-4.2,3.8-6.3c-2.7,3.9-5.4,7.8-8,11.7c3.2-3.6,6.3-7.2,9.5-10.9c-3,2.1-6,4.3-9,6.4
								c1.9-1.2,3.8-2.5,5.7-3.7c-2.5,1.3-5.1,2.6-7.6,3.9c3.6-1.4,7.2-2.7,10.8-4.1c-4.5,0.4-8.9,0.7-13.4,1.1
								c3.2,0.1,6.3,0.1,9.5,0.2c-1.7-1-2.4-1.4-2.8-3.3c-1-4-1.2-7.8-1.4-11.9c0,1.2,0.1,2.5,0.1,3.7c0.1-3,0.1-5.9,0.2-8.9
								c-0.1,1.3-0.2,2.6-0.3,3.8c1.1-6.3,1.5-12.7,1.4-19.1c-0.6,3.3-1.1,6.6-1.7,9.9c4.4-7.2,8.7-14.5,13.1-21.7
								c-4.9,5.5-9.7,11.1-14.6,16.6c2.1-1.4,4.1-2.8,6.2-4.2c-6.1-1.8-12.3-3.5-18.4-5.3c-0.7,0.7-1.4,1.4-2,2
								c-1.5,2.4-1.5,4.8-0.1,7.3c4.8,7.3,9.6,14.7,14.4,22c-2.5-7.3-5.1-14.6-7.6-21.9c1.7,7.6,3.5,15.3,5.2,22.9
								c-0.3-2.7-0.6-5.4-0.9-8.1c0.7,6.6,1.3,13.2,2,19.8c0.7-7.1,1.3-14.3,2-21.4c-1.6,7.1-3.2,14.1-4.8,21.2
								c0.6-2.5,1.2-5,1.8-7.5c-1.4,4.3-2.9,8.6-4.3,12.9c0.9-2.4,1.8-4.7,2.7-7.1c-1.9,3.9-3.9,7.7-5.8,11.6
								c1.1-2.2,2.3-4.4,3.4-6.6c-2.3,3.7-4.5,7.3-6.8,11c1.4-2,2.7-4,4.1-6.1c-2.6,3.2-5.2,6.3-7.8,9.5c1.5-1.8,3.1-3.7,4.6-5.5
								c-2.8,2.9-5.7,5.8-8.5,8.7c1.7-1.6,3.4-3.3,5.1-4.9c-3.1,2.5-6.3,4.9-9.4,7.4c1.8-1.4,3.7-2.9,5.5-4.3
								c-3.3,2.1-6.6,4.2-9.9,6.4c7.5-1.2,15-2.4,22.5-3.7c-1.2-2.8-2.4-5.5-3.7-8.3c0.5,2.4,1,4.8,1.5,7.2c0-4.2-0.1-8.5-0.1-12.7
								c-0.3,1.2-0.6,2.4-0.9,3.6c2.1-4.4,3.4-9,3.8-13.8c0.7-6,1.6-12,2.6-18c-0.3,1.5-0.5,3-0.8,4.5c1.1-5.5,2.7-10.9,4.9-16
								c2-5.3,4.3-10.4,6.9-15.4c0.2-2.6-0.7-4.8-2.8-6.4c-2-1.8-4.3-2.3-6.8-1.6c-3.4,2.1-6.4,4.8-8.8,8c-2.7,3-4.8,6.4-6.2,10.1
								c-1.1,5.3-2,10.6-2.5,16c0.2-1.3,0.3-2.6,0.5-3.9c-0.4,4.2-1,8.4-1.8,12.5c-1.3,7.6-2.1,15.3-2.6,23.1
								c0.8-2.3,1.6-4.6,2.4-6.9c-2,3.5-4,7-6,10.5c-1.5,2.6-1,5.6-0.1,8.3c0.2,1.2,0.7,2.1,1.6,2.9c0.9,1.5,2.3,2.1,4.3,1.7
								c4.2,0,8.4-0.1,12.7-0.1c1.2-0.1,2.4-0.3,3.5-0.4c2.5-1.6,4.7-3.7,6.5-6.1c3.6-4.2,6.7-8.5,8.6-13.7c2-3.8,3.3-7.8,3.9-12
								c0.4-2,0.8-4.1,1.1-6.1c0.3-2.6,0.5-5.3,0.8-7.9c0.2-9.6-3.6-17.9-11.5-25c2.1,10.5,4.2,21,6.4,31.6
								c2.3-3.2,4.3-6.7,5.8-10.3c0.9-1.9,1.4-3.9,1.4-6.1c0.3-3.6,0.7-7.1,1.2-10.6c0.4-5.6-1-10.7-4.2-15.3
								c-0.9-2.6-2.6-4.3-5.3-5.2c-3.2-0.7-6.4-0.6-9.6,0.2c-2.1,0.4-4.1,1.2-5.9,2.4c-1.7,2.7-3.4,5.4-4.8,8.2
								c-1.3,3.6-2.1,7.2-2.5,11c0.2-1.3,0.5-2.7,0.7-4c-1.5,5.2-2.6,10.6-3.2,16c-0.9,4.5-1.4,9.1-1.6,13.7
								c-0.5,4.7,0.1,9.1,1.9,13.5c1.1,3.3,2.1,6.5,3.2,9.8c0.8,2.5,2.4,4.1,4.8,4.9c3.8,0.4,7.7,1.1,11.5,1.1
								c2.3,0.2,4.2-0.5,5.8-2c1.8-1.1,3.3-2.4,4.6-4.1c3.1-3.2,6.1-6.6,8.1-10.6c1.8-3.6,3.4-6.9,4.8-10.7
								c2.5-6.9,3.8-14.2,4.4-21.5c0.1-2,0.2-4,0.4-6c-0.1-4.2-0.7-8.3-1.7-12.4c-0.3-2.4-0.9-4.7-1.9-6.9
								c-1.5-5.1-4.9-8.9-10.4-11.3c3.8,10,7.5,20,11.3,29.9c4.9-5.4,9.9-10.4,12.9-17c2.1-3.4,2.3-7,0.6-10.9
								c-0.8-1.8-1.5-3.6-2.4-5.3c-1.2-2.1-2.6-4-4.3-5.8c-1.7-2.1-3.9-3.2-6.6-3.4c-2.3-0.1-4.5,0-6.7,0.3
								c-2.2,0.2-4.4,0.8-6.4,1.7c-3.4,3.1-6.2,6.8-8.4,10.9c-2.5,3.9-4.4,8.1-5.5,12.5c-0.5,5.1-0.9,10.1-0.8,15.3
								c0.1,3.9,0.3,7.8,0.5,11.8c0-2,0-4.1,0.1-6.1c-0.1,4.9-0.1,9.9-0.2,14.8c0-1.4,0.1-2.7,0.1-4.1c-0.6,4.8-2.1,9.3-4.5,13.4
								c0.5-0.9,1-1.8,1.4-2.7c-1.9,3.6-4.4,7.2-4.9,11.3c-0.7,5.2,1.4,11.1,2.6,15.8c1,2.5,3,3.9,5.8,4c0.5,0.1,1.1,0.2,1.6,0.3
								c4,0.5,8,0.5,11.9-0.1c2.9,0,5-1.3,6.2-3.9C873.1,1284,870.4,1277.6,864.6,1277.7z"/>
							<path opacity="0.1091" fill="#131313" d="M853.4,1277.9c-14.3-1.8-28.5-3.7-42.8-5.5c11.4,2,22.8,4.1,34.2,6.1
								c-9.7-3.9-19.4-7.9-29.1-11.8c8.7,4.5,17.4,9.1,26.1,13.6c-4.9-3.5-9.8-7-14.6-10.5c4.5,3.8,9,7.5,13.5,11.3
								c-6.3-6.8-12.5-13.7-18.8-20.5c3.1,4.5,6.3,9,9.4,13.5c-2.8-4.9-5.7-9.8-8.5-14.7c1.2,2.3,2.4,4.7,3.7,7
								c-2.1-5.3-4.2-10.6-6.3-15.9c1.3,4.7,2.6,9.5,3.9,14.2c-0.6-5-1.1-9.9-1.6-14.9c-0.8-6.7-1.2-13.4-1.1-20.2
								c0,2.1,0.1,4.2,0.1,6.3c0.3-4.3,0.6-8.5,0.8-12.8c-0.2,2-0.3,4-0.5,5.9c0.7-4.1,1.3-8.2,2-12.3c-0.3,1.9-0.7,3.7-1,5.6
								c1-3.9,2.1-7.9,3.1-11.8c-0.5,1.8-1,3.5-1.5,5.3c1.4-3.8,2.8-7.6,4.2-11.4c-0.6,1.7-1.3,3.3-1.9,5c1.7-3.5,3.4-7.1,5.1-10.6
								c-2.7,3.8-5.4,7.5-8.1,11.3c1.5-1,3-2,4.5-3c-1,0.7-2,1.4-3.1,2c1.6-0.8,3.2-1.7,4.7-2.5c-1.1,0.6-2.1,1.1-3.2,1.7
								c1.6-0.6,3.2-1.3,4.8-1.9c-1.1,0.4-2.2,0.8-3.3,1.2c1.6-0.4,3.2-0.8,4.8-1.2c-2-0.2-4.1-0.3-6.1-0.5c1.6,0.7,3.1,1.5,4.7,2.2
								c-0.8-0.5-1.6-1.1-2.5-1.6c1.3,1.1,2.6,2.2,3.9,3.3c-0.7-0.7-1.4-1.3-2.1-2c0.4,0.6,0.7,1.2,1.1,1.9
								c-0.3-1.2-0.6-2.4-0.9-3.6c0.5,2,1,3.9,1.4,5.9c0-1.2,0-2.5,0-3.7c-0.1,1.2-0.2,2.5-0.3,3.7c1.1-2.2,2.2-4.4,3.4-6.5
								c-1.6,2.3-3.2,4.6-4.9,6.9c1.8-1.7,3.6-3.3,5.4-5c-1.5,1-3,2-4.5,3c1.2-0.4,2.4-0.7,3.6-1.1c-2.5,0.3-4.9,1-7.1,2
								c-1.8,0.4-3,1.5-3.8,3.2c-0.8,2-1.5,4.1-2.3,6.1c-2.1,10.3-3.8,20.6-5.1,31c1.1-5.2,2.2-10.4,3.2-15.6
								c-1.9,7.1-3.7,14.1-5.6,21.2c0.9-2.8,1.7-5.6,2.6-8.4c-1.8,4.6-3.5,9.2-5.3,13.8c1.3-2.9,2.6-5.7,3.9-8.6
								c-2.4,4.6-4.7,9.2-7.1,13.8c5.5-7.4,10.9-14.8,16.4-22.1c-11.7,9.4-23.4,18.9-35.1,28.3c13-5.6,26.1-11.2,39.1-16.8
								c-10.5,2.8-21.1,5.7-31.6,8.5c11.4-1.3,22.8-2.6,34.2-3.9c-10.7-4.4-21.4-8.7-32.1-13.1c6,4.2,12.1,8.3,18.1,12.5
								c-3.9-4-7.9-8-11.8-12c3.1,3.8,6.2,7.7,9.3,11.5c-3-4.3-6-8.6-9-12.9c2.1,4.3,4.3,8.7,6.4,13c-1.7-4.8-3.4-9.6-5-14.4
								c0.5,2.2,1,4.3,1.6,6.5c-1.6-6.8-3.1-13.6-4.7-20.3c0.5,4.2,1.1,8.4,1.6,12.5c0.2-4.3,0.3-8.6,0.5-12.9c-0.1,2-0.3,4-0.4,6
								c0.5-4.1,1.1-8.2,1.6-12.3c-1,3.1-1.9,6.2-2.9,9.4c1-1.6,2-3.3,3-4.9c-0.7,1.1-1.4,2.2-2.1,3.2c1.5-1.6,3-3.2,4.4-4.8
								c-0.9,0.7-1.9,1.5-2.8,2.2c1.3-0.7,2.5-1.5,3.8-2.2c-1.1,0.5-2.1,1-3.2,1.5c1.5-0.2,2.9-0.4,4.4-0.6
								c-1.8-0.4-3.5-0.8-5.3-1.2c1,1.1,2,2.1,3.1,3.2c-1-1.9-2-3.8-3-5.7c0.4,1.9,0.8,3.8,1.2,5.7c0.2-2.4,0.4-4.9,0.6-7.3
								c-0.7,4.5-1.6,8.9-2.8,13.3c-1,5.6-1.6,11.2-1.9,16.9c0.2-2.4,0.5-4.9,0.7-7.3c-0.8,4.1-1.7,8.3-2.5,12.4
								c0.6-2.6,1.2-5.1,1.8-7.7c-1.7,6.6-3.4,13.3-5,19.9c1.7-5.4,3.3-10.8,5-16.3c-2.6,6.9-5.2,13.7-7.8,20.6
								c1.1-2.9,2.2-5.7,3.3-8.6c-1.9,4.1-3.8,8.3-5.8,12.4c1.5-2.9,3.1-5.8,4.6-8.7c-2.5,4.1-5,8.2-7.6,12.3c2-2.9,4-5.8,6-8.8
								c-3,4-6,8-9,12.1c2.4-2.8,4.8-5.7,7.3-8.5c-3.6,3.8-7.2,7.6-10.8,11.4c2.9-2.7,5.7-5.4,8.6-8.1c-4.1,3.6-8.2,7.2-12.3,10.7
								c3.3-2.5,6.5-5,9.8-7.5c-4.5,3.1-9.1,6.3-13.6,9.4c11.9-5.3,23.7-10.6,35.6-15.9c-6-2.1-10.4-5.9-13.4-11.5c1,2,2,4,3.1,6
								c-1.4-4.8-2.7-9.6-4.1-14.4c0.2,2.5,0.4,4.9,0.7,7.4c0.3-5.3,0.7-10.7,1-16c-0.2,1.3-0.4,2.6-0.6,3.9c1.3-4.4,2.6-8.8,4-13.1
								c-0.5,1.3-1,2.5-1.4,3.8c1.9-4.1,3.7-8.3,5.6-12.4c-0.7,1.2-1.3,2.5-2,3.7c2.2-3.8,4.3-7.7,6.5-11.5c1.3-2,1.3-3.9-0.1-5.9
								c-1.5-2.1-3.3-3.7-5.6-4.7c-2.1-1-4.1-0.7-5.8,0.9c-2.3,1.8-4.1,4-5.5,6.5c-4.5,9.1-8.9,18.4-10.9,28.4
								c-0.9,4.6-1.2,9.3-0.8,14c1.1,4.4,1.3,8.9,0.7,13.4c0.4-1.2,0.8-2.4,1.2-3.5c-6,10.2-11.9,20.4-17.6,30.8
								c-0.4,3.1,0.8,5.2,3.7,6.4c4.8,0.7,9.3-0.3,13.4-3.2c7.7-3.7,15.1-8,22.2-12.7c4.6-2.1,7.8-5.4,9.6-9.9
								c1.9-4.3,2.8-8.7,2.9-13.2c0.3-5.5,0.7-10.9,1.1-16.3c-0.7,4.1-1.5,8.3-2.2,12.4c1.8-5,3.6-10,5.6-14.9
								c1.1-3.4,1.5-6.8,1.3-10.3c0.2-4.2-0.3-8.3-1.5-12.3c-0.9-3.9-2.4-7.6-4.4-11c0.6,2.4,1.3,4.7,1.9,7.1
								c-0.1-3.7-1-7.2-2.6-10.6c-0.9-1.7-2.2-3-3.8-4c-1.2-1.2-2.7-1.7-4.5-1.7c-2.2-0.1-4.4,0.3-6.5,1c-1.8,0.5-3.5,1.1-5.1,2
								c1-0.4,2-0.7,2.9-1.1c-1.2,0.3-2.3,0.6-3.5,0.9c-2.6,0.8-4.1,2.5-4.5,5.4c-1.3,4.9-3.3,10.9-3.6,16.2c0.2,4.5,0.6,8.9,1,13.3
								c1.5,5.2,0.7,10.1-2.4,14.7c-0.5,2.2-1,4.4-1.4,6.6c-0.6,4.3-0.5,8.5,0.4,12.8c1.1,1.2,2.2,2.5,3.4,3.7
								c2,2.3,4.5,3.5,7.6,3.4c3.1,0.4,5.9-0.4,8.4-2.4c4.7-2.4,9.1-5.3,13.2-8.8c3-2.5,6.1-5,9.1-7.4c4-2.5,6.5-6,7.4-10.4
								c-0.2-4.7-0.3-9.4-0.5-14.1c0.3-1.3,0.5-2.7,0.8-4c1.4-3.4,2.8-6.8,4.3-10.1c1-1.6,2-3.2,3-4.9c1.5-2.3,2.9-4.7,4-7.3
								c0.8-2.3,1.6-4.6,2.3-6.8c0.2-0.7,0.4-1.4,0.5-2.2c0.7-4.6-2.1-9.1-4.6-12.7c-1.5-2.1-3.1-4-4.9-5.9
								c-0.9-0.9-1.9-1.8-2.8-2.7c-1.4-1-2.9-1.5-4.5-1.5c-2.4-0.3-4.7-0.2-7,0.1c-1.1,0.1-2.2,0.3-3.4,0.5
								c-1.3-0.1-2.3,0.4-3.1,1.5c-2.9,2.5-4.5,5.7-6.2,9.1c-1.6,3.3-2.9,6.6-4.2,10c-4,11.1-4.9,23.2-4.1,34.9c0-2.2,0-4.4,0-6.6
								c0,2.8,0,5.7,0,8.5c0.1-1.7,0.3-3.3,0.4-5c-1,8.8-2,17.7-3,26.5c0.4-1.2,0.8-2.5,1.1-3.7c-2.4,2.9-4.4,6.1-6,9.6
								c-1.8,2.8-3.2,5.8-4.4,8.9c0.3,2.7,1.6,4.3,4,4.9c3.7,2.3,7.8,3.1,12.4,2.4c4.7,0.1,9.5,0.2,14.2,0.3
								c8.1,0.1,16.1-0.5,24.1-1.9c1.6-0.9,3.2-1.8,4.8-2.6C855.6,1282.8,855.6,1280.4,853.4,1277.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M860.2,1277.4c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C851,1274.2,855.2,1276.6,860.2,1277.4z"/>
							<path opacity="0.1091" fill="#131313" d="M835.8,1258.4c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C829.5,1257.8,832.4,1258.9,835.8,1258.4z"/>
							<path opacity="0.1091" fill="#131313" d="M835.2,1224.5c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C830.4,1222.3,832.6,1223.7,835.2,1224.5z"/>
							<path opacity="0.1091" fill="#131313" d="M857.1,1251.1c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C849.8,1249.7,853.1,1251.2,857.1,1251.1z"/>
							<path opacity="0.1091" fill="#131313" d="M852.3,1220.3c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C846.2,1216.8,849,1218.8,852.3,1220.3z"/>
							<path opacity="0.1091" fill="#131313" d="M818.3,1246.7c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2
								c-2-2.2-4.4-3.8-7.2-4.8c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C813.1,1244.1,815.5,1245.7,818.3,1246.7z"/>
							<path opacity="0.1091" fill="#131313" d="M843.3,1279.2c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C835.3,1277.2,839,1279,843.3,1279.2z"/>
							<path opacity="0.1091" fill="#131313" d="M871.3,1247.8c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C863.2,1245.8,866.9,1247.6,871.3,1247.8z"/>
							<path opacity="0.1091" fill="#131313" d="M826.5,1235.1c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2
								c-2-2.3-4.4-3.9-7.2-4.9c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C821.2,1232.5,823.7,1234.1,826.5,1235.1z"/>
							<path opacity="0.1091" fill="#131313" d="M842.8,1231.6c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C836.3,1227.7,839.3,1230,842.8,1231.6z"/>
							<path opacity="0.1091" fill="#131313" d="M869.9,1263.9c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C860.8,1260.9,864.9,1263.2,869.9,1263.9z"/>
							<path opacity="0.1091" fill="#131313" d="M818.2,1257.6c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C811.9,1253.9,814.8,1256,818.2,1257.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M849.5,1296.5c-0.2-3.7-0.4-7.4-0.6-11.1c-0.6-3.1-1.1-6.2-1.3-9.4
								c-0.3-1.9-0.3-3.9-0.1-5.8c0-3,0.4-5.9,1.1-8.8c-0.4,1.4-0.8,2.9-1.2,4.3c0.9-3.3,1.9-6.6,2.8-9.9c-0.5,1.5-1,2.9-1.5,4.4
								c1.2-3.2,2.5-6.5,3.7-9.7c-0.6,1.5-1.2,2.9-1.9,4.4c1.5-3.2,3.1-6.3,4.6-9.5c-0.8,1.5-1.5,2.9-2.3,4.4c1.8-3,3.6-6.1,5.4-9.1
								c-0.9,1.4-1.8,2.9-2.7,4.3c2.1-2.9,4.2-5.8,6.2-8.7c-1,1.4-2,2.8-3.1,4.2c2.4-2.7,4.7-5.5,7.1-8.2c-1.2,1.3-2.3,2.7-3.5,4
								c2.6-2.6,5.2-5.1,7.9-7.7c-3.4,2.7-6.8,5.4-10.2,8c2.5-1.4,4.9-2.8,7.4-4.2c-7.6,2.3-15.3,4.6-22.9,6.9
								c10.1,6.4,20.1,12.8,30.2,19.2c-6.9-7.8-13.9-15.6-20.8-23.4c4.6,7,9.2,13.9,13.8,20.9c-3.9-6.6-7.7-13.2-11.6-19.8
								c3.4,7.6,6.9,15.2,10.3,22.7c-2.9-7.2-5.7-14.5-8.6-21.7c2.3,8.3,4.7,16.5,7,24.8c-1.7-7.5-3.5-15-5.2-22.4
								c1.2,8.5,2.4,17,3.7,25.4c-0.7-7.7-1.4-15.4-2.1-23.1c0.3,8.5,0.6,16.9,0.9,25.4c0-3.8,0.1-7.7,0.1-11.5
								c-0.5,5.7-1,11.4-1.4,17.1c0.5-3.7,0.9-7.4,1.4-11.1c-1,5.5-2,11-3,16.5c0.9-3.6,1.7-7.1,2.6-10.7
								c-1.6,5.2-3.1,10.3-4.7,15.5c1.2-3.3,2.4-6.7,3.6-10c-2,4.9-4,9.9-6,14.8c1.5-3.1,3.1-6.3,4.6-9.4c-2.5,4.6-5,9.3-7.5,13.9
								c1.8-2.9,3.6-5.8,5.4-8.7c-2.8,4.4-5.7,8.7-8.5,13.1c2-2.6,4.1-5.3,6.1-7.9c-3.3,4-6.5,8-9.8,12c2.2-2.4,4.5-4.8,6.7-7.1
								c-3.5,3.7-7,7.4-10.6,11.1c2.4-2.1,4.8-4.2,7.2-6.4c-4.9,4.8-10.1,9.2-15.6,13.2c-3,2.1-6.1,4-9.4,5.7
								c-6.7,3.4-13.6,5.5-20.9,7c2.5-0.2,4.9-0.4,7.4-0.6c-4,0.3-8,0.6-12,0.9c2.4,0,4.7-0.1,7.1-0.1c-4,0-8,0.1-11.9,0.1
								c2.3,0.1,4.6,0.3,6.9,0.4c-3.8-0.3-7.6-0.6-11.5-1c2.2,0.3,4.4,0.6,6.6,0.9c-3.7-0.6-7.3-1.2-11-1.8c2.1,0.4,4.2,0.9,6.3,1.3
								c-3.4-1-6.9-2-10.3-3c2,0.6,3.9,1.2,5.9,1.7c-1.4-0.6-2.9-1.2-4.3-1.8c1.7,1,3.4,2,5.1,2.9c-2.9-1.8-5.9-3.5-8.8-5.3
								c1.5,1.1,3,2.2,4.5,3.4c-2-2.1-3.9-4.3-5.5-6.7c0.6,1.1,1.1,2.3,1.7,3.4c-1.9-3.4-3.5-6.9-4.8-10.6
								c-1.4-3.8-2.6-7.7-3.7-11.7c0.5,1.5,0.9,3,1.4,4.5c-0.6-2.9-1.1-5.8-1.7-8.7c0.3,1.5,0.6,3.1,0.9,4.6
								c-0.3-2.9-0.6-5.8-0.9-8.7c0.1,1.6,0.3,3.1,0.4,4.7c0-3,0-6,0-8.9c0,1.6-0.1,3.2-0.1,4.8c0.2-2.8,0.5-5.6,0.7-8.5
								c-0.2,1.6-0.4,3.2-0.6,4.8c0.5-2.8,1.1-5.7,1.6-8.5c-0.4,1.6-0.8,3.3-1.1,4.9c0.7-2.7,1.5-5.4,2.2-8.1
								c-0.6,1.6-1.1,3.2-1.7,4.8c1.1-2.7,2.2-5.3,3.2-8c-0.8,1.6-1.5,3.2-2.3,4.9c2.1-3.5,4.1-6.9,6.2-10.4
								c-1.2,1.6-2.3,3.1-3.5,4.7c2.7-3,5.5-5.9,8.2-8.9c-1.6,1.5-3.2,2.9-4.9,4.4c2.5-2,4.9-4,7.4-6c-1.8,1.3-3.7,2.6-5.5,3.9
								c3.5-1.8,6.9-3.6,10.4-5.5c-2.3,0.9-4.6,1.9-6.9,2.8c3.2-1,6.3-2.1,9.5-3.1c-2.5,0.7-5.1,1.4-7.6,2.1c3.3-0.8,6.7-1.7,10-2.5
								c-2.7,0.5-5.4,0.9-8.2,1.4c3.6-0.4,7.2-0.9,10.8-1.3c-2.9,0.2-5.9,0.3-8.8,0.5c6.8-0.3,13.7-0.7,20.5-1
								c-6.1,0-12.2,0-18.3-0.1c7.2,0.4,14.3,0.9,21.5,1.3c-3.2-0.2-6.4-0.4-9.6-0.6c4.2,0.6,8.4,1.2,12.7,1.9
								c-6.6-1.4-13.1-2.8-19.7-4.2c7.6,2,15.1,4,22.7,6c-3.4-0.9-6.8-1.9-10.2-2.8c4.3,1.6,8.7,3.1,13,4.7
								c-6.7-3-13.4-5.9-20.1-8.9c7.6,3.7,15.2,7.5,22.7,11.2c-3.4-1.8-6.8-3.5-10.2-5.3c4.1,2.6,8.3,5.1,12.4,7.7
								c-6.4-4.6-12.8-9.1-19.1-13.7c7,5.5,14,11,21,16.6c-6.1-5.4-12.1-10.8-18.2-16.2c6.6,6.4,13.2,12.8,19.8,19.2
								c-5.5-6.1-11-12.3-16.5-18.4c5.8,7.2,11.6,14.4,17.4,21.5c-4.8-6.8-9.6-13.6-14.5-20.4c5.2,8.1,10.4,16.2,15.6,24.3
								c-3.9-7.3-7.9-14.6-11.8-21.9c4.1,8.7,8.3,17.3,12.4,26c-3.1-7.8-6.1-15.6-9.2-23.3c3.1,9.2,6.3,18.4,9.4,27.5
								c-2.1-8-4.2-16-6.2-24c2.1,9.5,4.2,18.9,6.2,28.4c-0.7-4.1-1.4-8.2-2.1-12.3c0.6,5.5,1.2,11,1.9,16.5
								c-0.3-4-0.5-8.1-0.8-12.1c0.2,5.5,0.4,11,0.5,16.5c0.2-4,0.3-8,0.5-12c-0.3,5.5-0.5,10.9-0.8,16.4c0.5-3.9,1.1-7.9,1.6-11.8
								c-0.8,6.6-1.8,13.1-3.1,19.5c-1.3,5.2-2.8,10.3-4.7,15.3c0.8-2.5,1.7-5,2.5-7.6c-2.2,4.7-4.4,9.4-6.6,14.2
								c1.1-2.3,2.3-4.6,3.4-6.9c-0.7,1.1-1.4,2.1-2,3.2c2.2-2.8,4.3-5.6,6.5-8.4c-3.8,4.6-7.7,9.2-11.5,13.8
								c2.5-2.3,4.9-4.6,7.4-6.9c-3.5,3-6.9,6-10.4,9c2.6-2,5.2-4.1,7.8-6.1c-3.8,2.5-7.5,5-11.3,7.5c5.4-3.1,10.9-6.3,16.3-9.4
								c-6.5,3.4-13.1,6.8-19.6,10.2c5.4-2.4,10.9-4.8,16.3-7.1c-6.6,2.5-13.2,5-19.7,7.5c5.3-1.6,10.7-3.2,16-4.7
								c-4.3,0.7-8.6,1.5-12.9,2.2c2.5-0.3,5.1-0.6,7.6-0.9c-4.1,0.2-8.2,0.4-12.4,0.6c2.4,0.1,4.8,0.1,7.2,0.2
								c-3.8-0.6-7.7-1.2-11.5-1.7c2.2,0.4,4.4,0.7,6.6,1.1c-3.6-1-7.2-2-10.8-3c2,0.6,4,1.3,6,1.9c-3.1-1.6-6.1-3.4-8.9-5.4
								c0.9,1,1.9,2,2.8,2.9c-3.3-3.2-6.4-6.7-9.1-10.4c0.6,1.1,1.2,2.2,1.8,3.3c-2.2-3.7-4-7.6-5.4-11.6c-0.9-2.2-1.5-4.5-1.9-6.9
								c-1.3-6.8-0.7-13.4-0.3-20.3c-0.2,1.6-0.3,3.2-0.5,4.8c0.5-3,1-5.9,1.5-8.9c-0.3,1.6-0.7,3.2-1,4.8c0.7-2.9,1.4-5.8,2.2-8.7
								c-0.5,1.6-1,3.2-1.6,4.8c2.3-6.4,4.5-12.8,6.6-19.3c-0.6,1.7-1.3,3.4-1.9,5.1c1.5-3.5,3-7,4.5-10.5c-0.8,1.7-1.7,3.4-2.5,5.2
								c3.2-6.2,6.4-12.3,10-18.3c1.5-2.5,2.9-5,4.5-7.4c3.5-5.4,6.5-11,9.1-16.8c4.2-7.9,8.5-15.8,13.2-23.4
								c7.1-11.6,15.2-22.4,23.5-33.1c1.7-2.2,1.9-4.6,0.8-7.2c-1.1-2.6-3.1-4-5.9-4.3c-4.6,0.6-9,2-13.2,4
								c-4.4,1.7-8.3,4.1-11.9,7.1c-7.6,11.9-14.9,23.8-21.3,36.5c-6.3,12.6-11.7,25.7-17,38.7c-2,4.9-3.8,9.9-5.6,14.9
								c-0.8,2.2-1.6,4.3-2.4,6.5c-1.5,4.4-2.8,8.9-3.7,13.5c1.1-3.3,2.3-6.6,3.4-9.9c-2.5,5.5-4.9,11.1-6.2,17
								c-1.1,4.7-1.9,9.6-2,14.5c-0.1,2.9-0.1,5.6,0.2,8.5c0.4,4.2,1.1,8.6,2.1,12.7c0.5,1.9,1,3.9,1.5,5.8c1.3,3,2.8,5.9,4.5,8.7
								c1.5,3,3.4,5.6,6,7.9c1.4,1.6,2.9,3.1,4.3,4.7c4.5,4.2,10.4,5.9,16.4,6.4c2.2,0.1,4.4,0.2,6.7,0.3c7.2-1.1,14.4-2.4,21.5-3.8
								c5-3.2,9.5-7.1,13.6-11.5c4.3-4.1,8.1-8.7,11.2-13.8c1.3-3.8,2.4-7.6,3.1-11.5c0.2-2,0.5-3.9,0.7-5.9
								c0.6-6,0.4-12.3-0.5-18.3c-2.7-12.1-8.2-23.1-16.4-33c-1.5-2.6-2.9-5.1-4.4-7.7c-8-7-18-9.6-29.9-7.7
								c-3.4-1.3-6.8-1.5-10.1-0.6c-2.6,0.4-5.1,1.1-7.5,2.1c-4.4,1.1-8.4,2.9-11.9,5.6c-2.4,1.6-4.6,3.5-6.4,5.9
								c-2.4,2.1-4.3,4.5-5.6,7.4c-3.2,4.4-5.3,9.3-6.5,14.7c-0.7,2.8-1.2,5.6-1.5,8.4c-0.4,2.9-0.6,5.9-0.5,8.9
								c0.1,3.9,0.5,7.7,1.4,11.5c-0.1-1.2-0.3-2.3-0.4-3.5c0.4,3.5,0.9,6.9,1.6,10.4c0.3,2.7,1.1,5.3,2.3,7.7
								c0.7,1.7,1.5,3.4,2.4,5c0.6,1.5,1.5,2.9,2.6,4c0.9,1.4,2.1,2.5,3.6,3.3c3.1,2.3,6.5,4,10.1,5.6c4.8,2.1,9.9,4,15.1,4.7
								c2.1,0.1,4.2,0.2,6.3,0.3c2.1-0.1,4.2-0.1,6.3-0.2c7.3-0.6,14.7-2.3,21.4-5c9.7-3.9,18.5-9.3,26.4-16.1
								c6.9-6,12.9-12.7,17.3-20.9c1.2-2.3,2.3-4.7,3.5-7c1-2.4,2.1-4.8,3.1-7.3c0.7-2.5,1.3-5.1,2-7.6c0.9-3.7,1.8-7.5,2.7-11.2
								c1.2-3.6,2.4-7.2,3.6-10.8c0.5-3.7,1.1-7.4,1.6-11.1c0.1-4.4-0.9-9.3-2.6-13.4c-0.5-1.6-1.3-3.2-2.2-4.6
								c-0.8-1.8-2-3.4-3.5-4.7c-1.8-1.9-4-2.6-6.5-2.2c-4-0.3-8,0-12,0.9c-2.9,0.6-5.8,1.2-8.7,1.8c-3.8,0.3-6.7,2.1-8.5,5.2
								c-1.6,4.2-3.6,8.3-5.8,12.2c-1.6,2.8-3,5.7-4.2,8.8c-2.3,5.8-4.1,12.2-4.8,18.4c-0.8,6.5-1.2,13.2-0.2,19.7
								c0.4,1.9,0.7,3.8,1.1,5.8c1.6,5.4,2.5,10.9,2.7,16.6c1,2.2,2.6,3.7,4.9,4.5c2.2,1,4.5,1.1,6.7,0.1c2.2-2.1,3.9-4.7,4.8-7.6
								C849.1,1302.5,849.7,1299.6,849.5,1296.5z"/>
							<path opacity="0.1091" fill="#131313" d="M826.9,1280c-0.2-3.6-0.3-7.2-0.5-10.8c-1.1-2.7-1.7-5.5-1.7-8.5
								c-0.3-2.1-0.3-4.2-0.1-6.3c0-3.1,0.4-6.2,1.2-9.2c-0.4,1.5-0.9,3.1-1.3,4.6c1-3.5,2-7,3.1-10.5c-0.5,1.5-1.1,3-1.6,4.5
								c1.3-3.4,2.6-6.8,3.9-10.1c-0.6,1.5-1.2,2.9-1.9,4.4c1.6-3.2,3.2-6.5,4.8-9.7c-0.7,1.4-1.4,2.8-2.2,4.3
								c1.9-3.5,4.1-6.9,6.7-10c2.5-3.1,5.3-5.9,8.4-8.5c-3.6,2.2-7.2,4.3-10.8,6.5c2.3-1.1,4.5-2.2,6.8-3.3
								c-1.9,0.8-3.8,1.6-5.8,2.3c2.3-0.9,4.6-1.8,7-2.7c-2,0.6-4,1.2-6,1.8c4.8-1.1,9.6-2.1,14.5-3.2c-5.7,0.4-11.4,0.8-17.2,1.3
								c2.1,0.6,4.3,1.3,6.4,1.9c-1.4-0.6-2.8-1.2-4.2-1.8c1.6,1.5,3.3,2.9,5,4.3c-1.2-1-2.3-2.1-3.5-3.1c2.1,4.3,4.6,8.4,7.6,12.3
								c-2-3.8-3.9-7.6-5.9-11.4c0.8,2.4,1.6,4.7,2.3,7.1c-0.4-1.8-0.8-3.5-1.2-5.3c0.4,2.5,0.8,5,1.2,7.5c-0.2-1.9-0.4-3.8-0.5-5.7
								c0.1,6.2,0.2,12.4,0.2,18.6c0-2.9,0-5.8,0-8.6c-0.2,6.4-0.5,12.9-0.7,19.3c0.5-5.9,1.1-11.9,1.6-17.8
								c-0.7,4.7-1.4,9.4-2.1,14.1c0.7-3,1.4-5.9,2.1-8.9c-2.1,9.3-4.7,18.5-7.8,27.6c1.1-3,2.2-6,3.3-9c-1.9,4.5-3.9,8.9-5.8,13.4
								c1.5-3,2.9-5.9,4.4-8.9c-2.3,4.4-4.7,8.7-7,13.1c1.8-2.9,3.6-5.7,5.4-8.6c-2.8,4.2-5.7,8.4-8.5,12.6c2.2-2.8,4.3-5.5,6.5-8.3
								c-3.3,4-6.5,8.1-9.8,12.1c2.5-2.6,4.9-5.2,7.4-7.8c-4,4.7-8.3,9.1-12.9,13.1c-3.9,3.5-7.9,6.8-12.2,9.8
								c2.1-1.7,4.3-3.3,6.4-5c-3.6,2.5-7.3,4.9-10.9,7.4c2.3-1.5,4.5-3,6.8-4.4c-3.8,2.2-7.7,4.4-11.5,6.6c2.4-1.3,4.7-2.6,7.1-3.9
								c-4,1.9-8,3.8-11.9,5.7c2.5-1.1,4.9-2.2,7.4-3.3c-4.1,1.6-8.2,3.2-12.3,4.8c2.5-0.9,5-1.8,7.6-2.7c-4.2,1.3-8.4,2.6-12.5,3.9
								c2.6-0.7,5.1-1.4,7.7-2.2c-4.2,1-8.5,1.9-12.7,2.9c2.6-0.5,5.2-1,7.8-1.6c-4,0.5-8,1-12.1,1.6c2.6-0.3,5.2-0.7,7.8-1
								c-4.1,0.3-8.1,0.5-12.2,0.8c2.6-0.1,5.2-0.3,7.8-0.4c-4-0.1-8-0.2-12-0.2c2.6,0.1,5.1,0.1,7.7,0.2c-1.3-0.2-2.7-0.3-4-0.5
								c3.5,0.8,6.9,1.6,10.4,2.4c-4.7-1.1-9.5-2.3-14.2-3.4c3.3,1,6.6,2.1,9.9,3.1c-4.5-1.6-9-3.2-13.5-4.8
								c3.1,1.3,6.2,2.6,9.3,3.9c-4.2-2-8.3-4-12.5-6.1c5.6,3.4,11.2,6.9,16.8,10.3c-6.3-4.3-12.6-8.7-18.8-13
								c5,3.8,9.9,7.7,14.9,11.5c-5.6-4.8-11.1-9.6-16.7-14.4c4.3,4.2,8.7,8.5,13,12.7c-4.8-5.3-9.6-10.6-14.4-15.9
								c3.6,4.4,7.3,8.9,10.9,13.3c-4.1-5.6-8.2-11.1-12.3-16.7c1.6,2.2,3.2,4.5,4.9,6.7c-2-3.3-4-6.6-6-9.9
								c2.4,4.8,4.8,9.6,7.2,14.4c-1.7-4-3.5-8.1-5.2-12.1c0.8,2.5,1.6,4.9,2.4,7.4c-1.3-4.1-2.6-8.2-4-12.3
								c0.5,2.4,1.1,4.9,1.6,7.3c-1.5-5.5-2.5-11-3-16.6c0,2.4,0,4.8,0,7.1c-0.3-5.5-0.1-11,0.5-16.5c-0.5,2.2-0.9,4.4-1.4,6.6
								c0.8-3.7,1.6-7.3,2.4-11c-0.7,2.1-1.3,4.1-2,6.2c1.1-3.5,2.3-7,3.4-10.6c-0.9,2-1.7,3.9-2.6,5.9c1.5-3.3,3-6.5,4.5-9.8
								c-1,1.8-2.1,3.6-3.1,5.5c1.8-3.1,3.7-6.1,5.5-9.2c-1.2,1.7-2.4,3.4-3.7,5.1c2.1-2.7,4.3-5.5,6.4-8.2
								c-1.4,1.5-2.8,3.1-4.1,4.6c2.4-2.5,4.9-5,7.3-7.5c-1.6,1.4-3.1,2.8-4.7,4.2c3.8-2.6,7.7-5.2,11.5-7.9c-1.8,1-3.5,1.9-5.3,2.9
								c2.9-1.2,5.8-2.4,8.7-3.7c-1.8,0.7-3.7,1.4-5.5,2.2c3-0.9,6-1.8,9.1-2.6c-1.9,0.5-3.9,1-5.8,1.5c3.1-0.5,6.1-1,9.2-1.5
								c-2,0.3-4,0.6-6,0.8c5.3,0.3,10.7,0.7,16,1c-2.1-0.4-4.1-0.8-6.2-1.2c3.1,0.7,6.1,1.4,9.2,2.2c-2.1-0.6-4.1-1.2-6.2-1.8
								c3,1.1,6.1,2.1,9.1,3.2c-2-0.8-4.1-1.7-6.1-2.5c2.9,1.3,5.8,2.6,8.8,3.9c-2-1.1-4-2.2-6-3.2c2.8,1.7,5.6,3.4,8.5,5
								c-2-1.3-3.9-2.6-5.9-4c5.3,4.3,10.5,8.6,15.8,12.9c-3.7-3.4-7.3-6.8-11-10.2c4.3,5.6,8.6,11.1,12.8,16.7
								c-2.9-4.4-5.9-8.8-8.8-13.2c3.2,7.1,6.5,14.2,9.7,21.4c-1.9-5.3-3.9-10.5-5.8-15.8c1.9,6.6,3.8,13.2,5.8,19.8
								c-1.3-5.6-2.6-11.2-4-16.8c1.2,7,2.4,14,3.5,21.1c-0.5-3-1-5.9-1.5-8.9c0.3,4.3,0.7,8.6,1,12.9c-0.2-3.1-0.3-6.1-0.5-9.2
								c0,4.4-0.1,8.9-0.1,13.3c0.1-3.1,0.3-6.3,0.4-9.4c-0.3,4.5-0.6,9-0.9,13.4c0.5-3.2,0.9-6.4,1.4-9.6
								c-0.7,4.4-1.4,8.8-2.1,13.2c0.7-3.2,1.5-6.4,2.2-9.6c-1,4.5-2.1,8.9-3.1,13.4c1-3.2,2-6.4,3-9.7c-2.5,8-5,16.1-7.5,24.1
								c0.8-2.4,1.6-4.7,2.3-7.1c-1.4,3.5-2.8,7-4.2,10.5c1-2.3,1.9-4.7,2.9-7c-1.7,3.4-3.4,6.9-5.1,10.3c3.2-5.3,6.3-10.7,9.5-16
								c-4.9,7.2-9.9,14.3-14.8,21.5c4.1-5,8.2-9.9,12.2-14.9c-3.3,3.6-6.7,7.1-10,10.7c2.8-2.6,5.6-5.2,8.4-7.8
								c-3.7,3.2-7.5,6.4-11.2,9.6c3.1-2.4,6.1-4.8,9.2-7.1c-4.1,2.8-8.2,5.6-12.3,8.5c6.7-3.9,13.4-7.8,20.1-11.7
								c-7.7,4.1-15.3,8.1-23,12.2c7-3.2,14-6.3,21-9.5c-8,3.2-16,6.4-23.9,9.6c7.2-2.3,14.5-4.5,21.7-6.8c-8.4,2-16.7,4.1-25.1,6.1
								c7.2-1.3,14.4-2.6,21.6-4c-8.3,0.9-16.7,1.8-25,2.7c7.2-0.4,14.3-0.8,21.5-1.1c-8.2-0.2-16.5-0.5-24.7-0.7
								c6.9,0.6,13.8,1.1,20.7,1.7c-7.9-1.3-15.8-2.5-23.7-3.8c6.6,1.4,13.3,2.9,19.9,4.3c-7.5-2.3-15.1-4.6-22.6-6.9
								c6.1,2.2,12.1,4.4,18.2,6.6c-7-3.1-13.9-6.2-20.9-9.3c5.6,2.9,11.1,5.8,16.7,8.7c-6.4-4-12.7-8-19.1-12
								c4.9,3.4,9.9,6.9,14.8,10.3c-5.7-4.6-11.4-9.2-17.1-13.7c4.4,4,8.7,7.9,13.1,11.9c-3-3.2-5.9-6.3-8.9-9.5
								c1.7,2.2,3.4,4.4,5.1,6.6c-4.1-5.5-8.2-11-12.3-16.5c3,4.5,5.9,8.9,8.9,13.4c-2.2-4-4.3-7.9-6.5-11.9c1,2.3,2,4.7,3.1,7
								c-1.7-4.1-3.4-8.1-5-12.2c0.7,2.4,1.4,4.8,2.1,7.1c-1-4.1-2-8.1-3.1-12.2c0.4,2.3,0.8,4.7,1.2,7c-0.6-4.1-1.2-8.2-1.8-12.2
								c0.1,2.3,0.2,4.7,0.3,7c-0.1-4.1-0.1-8.2-0.2-12.2c-0.2,2.2-0.3,4.5-0.5,6.7c0.3-4,0.6-8,0.9-12c-0.4,2.2-0.8,4.3-1.2,6.5
								c1.2-7.7,2.9-15.3,5.1-22.7c-0.6,2-1.1,4.1-1.7,6.1c1.3-4.1,2.6-8.2,3.9-12.3c-0.7,2-1.5,3.9-2.2,5.9c1.7-4,3.3-8,5-11.9
								c-0.9,1.9-1.8,3.8-2.8,5.6c3.5-6.8,6.9-13.6,10.9-20.1c1.5-2.5,3.1-5.1,4.7-7.6c3.5-5.4,6.6-11.1,9.4-17
								c4.3-8,8.8-15.9,13.6-23.6c7.2-11.6,15.5-22.4,23.9-33.1c1.2-2.3,1.2-4.6,0-6.9c-0.9-2.4-2.7-3.9-5.1-4.6
								c-3.7,0.2-7.1,1.1-10.4,2.8c-3.4,1.3-6.5,3.3-9.1,5.8c-5.9,7.8-11.4,15.9-16.5,24.2c-5.2,8.4-10,16.9-14.4,25.7
								c-4.4,8.9-8.6,17.9-12.7,27c-2.4,4.7-4.6,9.5-6.6,14.3c-1.6,3.5-3,7.1-4.3,10.8c-1.8,5.2-3.1,10.5-4,15.9
								c-1.1,4.5-1.7,9.1-1.7,13.7c2.5,23.9,16.1,43.1,40.8,57.8c5.3,7,14.9,0,20.5-3c5.2-2.6,10.2-5.6,15-9c4.3-2,7.1-5.1,8.5-9.4
								c1.3-2.2,2.2-4.6,2.7-7c0.2-0.8,0.5-1.7,0.7-2.5c1.1-4.6,1.6-9.6,1.5-14.3c0-4.9-0.4-9.8-1.3-14.6c-1.2-6.7-3.5-13-5.9-19.3
								c0.4,2.1,0.9,4.3,1.3,6.4c0-7.1-2-13.6-6.1-19.5c-2.1-2.7-4.3-5.2-6.6-7.7c-5-4.8-11.3-8.3-17.7-10.7
								c-6.8-2.6-14-3.9-21.3-3.6c-3.6,0.1-7.2,0.7-10.7,1.8c-1.8,0.7-3.6,1.5-5.3,2.3c-4.3,2-7.6,5.2-10.6,8.8
								c-1.4,1.2-2.4,2.7-3.1,4.4c-1.3,1.6-1.6,3.3-0.7,5.3c3.2,23.6,6.5,47.2,9.7,70.8c0.9,1.1,1.9,2.3,2.8,3.4
								c3.8,2.1,7.8,3.7,12,4.7c3.4,1.2,6.8,2.1,10.3,2.8c4.2,1.2,8.4,1.8,12.7,1.9c4.1-0.8,8.2-1.9,12.1-3.3
								c3.9-1.6,7.7-3.4,11.4-5.3c7.2-4,13.7-9.2,19.4-15.1c5.7-5.8,10.8-12.1,14.4-19.4c1.8-3.8,3.3-7.7,4.7-11.7
								c3.3-10.1,4.5-20.4,3.7-31c-2,8.2-3.9,16.4-5.9,24.6c5.5-8.9,9.6-18.3,12.3-28.2c0.9-3.5,1.3-7,1-10.6c0,1.2-0.1,2.3-0.1,3.5
								c0.2-6.6,0.3-13.4-2.7-19.5c-1.4-3-3-5.9-5.4-8.4c-1-1.5-2.4-2.4-4.1-2.8c-3.1-1.2-6.4-1.4-9.8-0.7c-3.6,0.6-7,2-10,4.1
								c-2.6,1.3-4.9,3-6.8,5.3c-9.1,9.6-15.8,21.5-19.4,34.2c-2,7.2-3,14.5-3.2,22c0,5,0.5,9.9,1.5,14.7c2.1,4.9,3,10.1,2.6,15.5
								c0.7,2.5,2.3,4.1,4.8,5c2.4,1.1,4.7,0.9,6.9-0.4c2.5-2.8,4.4-6,5.6-9.6C826,1287.3,826.9,1283.8,826.9,1280z"/>
							<path opacity="0.1091" fill="#131313" d="M830,1238.5c-0.8-2.1-1.5-4.1-2.3-6.2c0.3,1.6,0.6,3.2,0.9,4.8
								c-0.7-3.7-1.3-7.4-2-11.1c0.3,1.6,0.5,3.2,0.8,4.8c-0.5-3.1-0.6-6.2-0.4-9.4c0-3.1,0.4-6.1,1.1-9c0.5-1.9,1.1-3.9,1.6-5.8
								c1.3-3.8,3-7.5,5-11c-1.3,2.1-2.7,4.3-4,6.4c2.4-3.8,4.8-7.6,7.2-11.4c-1.4,2-2.9,4-4.3,6c2.6-3.5,5.3-7,7.9-10.5
								c-1.5,1.8-3,3.6-4.5,5.4c4.4-4.5,8.8-9.1,13.2-13.6c-6.6,4.5-13.3,9.1-19.9,13.6c6.1-3.5,12.3-6.7,18.8-9.5
								c-2.5,1.1-4.9,2.3-7.4,3.4c3.1-1.1,6.1-2.2,9.2-3.3c-2.5,0.9-4.9,1.8-7.4,2.6c3-0.7,5.9-1.4,8.9-2.2
								c-6.7,0.7-13.3,1.4-20,2.1c3,0.4,5.9,0.7,8.9,1.1c-1.6-0.3-3.2-0.7-4.7-1c2.6,0.7,5.3,1.5,7.9,2.2c-1.4-0.5-2.7-1.1-4.1-1.6
								c2.3,1,4.6,2.1,7,3.1c-1.2-0.7-2.3-1.5-3.5-2.2c1.5,1.6,2.7,3.4,3.6,5.3c1.1,1.7,1.9,3.6,2.6,5.5c0.6,3.4-0.2,6.5-1.5,9.6
								c-0.4,1.1-0.8,2.2-1.4,3.2c-0.6,1.4-1.4,2.6-2.3,3.9c-0.4,0.8-0.8,1.5-1.2,2.3c-1.9,3.6-3,7.4-3.3,11.5
								c0.4-1.5,0.8-3,1.2-4.4c-1,2.8-2,5.6-3,8.4c0.6-1.5,1.2-3,1.8-4.5c-1.3,2.8-2.5,5.5-3.8,8.3c0.8-1.5,1.6-3,2.4-4.5
								c-2.1,4-4.5,7.8-7.1,11.5c-3.1,3.8-6.3,7.5-9.6,11.1c1.5-1.6,2.9-3.1,4.4-4.7c-2.7,2.5-5.4,5.1-8.1,7.6
								c1.7-1.5,3.4-3.1,5.2-4.6c-2.9,2.5-5.8,4.9-8.7,7.4c2-1.5,4-2.9,6-4.4c-5.2,3.7-10.2,7.5-15.9,10.6c-5.5,3-11.1,5-17,7.3
								c2.1-0.8,4.1-1.6,6.2-2.4c-3.7,1.1-7.4,2.3-11.1,3.4c2.2-0.7,4.4-1.3,6.6-2c-5.3,0.9-10.6,1.7-16,2.6
								c2.4-0.3,4.9-0.6,7.3-0.9c-1.4,0.1-2.8,0.1-4.1,0.2c3.6,0.3,7.2,0.5,10.8,0.8c-8.9-0.8-17.8-1.6-26.7-2.4
								c6.4,1.2,12.9,2.4,19.3,3.5c-4.9-1.2-9.9-2.4-14.8-3.6c3.7,1.2,7.5,2.4,11.2,3.6c-4.9-1.8-9.7-3.6-14.6-5.4
								c3.7,1.6,7.4,3.2,11.1,4.8c-4.7-2.3-9.3-4.6-14-6.9c7.1,4.3,14.2,8.6,21.3,12.8c-7.7-5.1-15.4-10.2-23.1-15.4
								c6.7,5.1,13.3,10.2,20,15.3c-7.1-6-14.3-12-21.4-18c6.2,5.9,12.4,11.8,18.6,17.7c-6.6-6.8-13.1-13.6-19.7-20.4
								c5.5,6.5,11,13,16.5,19.5c-5.8-7.5-11.6-14.9-17.5-22.4c2.5,3.5,5.1,7,7.6,10.4c-2.8-4.4-5.7-8.8-8.5-13.2
								c2.2,3.7,4.3,7.5,6.5,11.2c-2.6-5.1-5.2-10.1-7.8-15.2c1.8,3.9,3.5,7.8,5.3,11.7c-2-5.3-4.1-10.6-6.1-15.9
								c1.3,4.1,2.7,8.1,4,12.2c-1.5-5.4-3-10.9-4.4-16.3c0.9,4.1,1.8,8.2,2.7,12.4c-0.9-5.5-1.8-11.1-2.8-16.6
								c0.5,4.2,1,8.4,1.4,12.5c-0.4-5.5-0.7-11-1.1-16.6c0,4.1,0.1,8.2,0.1,12.3c0.2-5.4,0.3-10.8,0.5-16.3c-0.4,4-0.7,8-1.1,12
								c0.7-5.3,1.5-10.6,2.2-16c-0.7,3.8-1.5,7.6-2.2,11.4c1.2-5.1,2.4-10.3,3.7-15.4c-1.1,3.6-2.2,7.2-3.3,10.8
								c1.8-4.9,3.6-9.7,5.3-14.6c-1.4,3.4-2.8,6.7-4.2,10.1c2.2-4.6,4.4-9.1,6.6-13.7c-1.7,3.1-3.4,6.2-5.1,9.3
								c2.7-4.2,5.4-8.3,8.1-12.5c-1.9,2.8-3.9,5.6-5.8,8.4c3-3.8,6.1-7.6,9.1-11.4c-4.5,4.8-9,9.5-13.6,14.3
								c5.8-5.5,11.6-11,17.4-16.6c-4.7,3.9-9.4,7.9-14,11.8c5.8-4.4,11.7-8.8,17.5-13.1c-4.8,3.1-9.6,6.3-14.4,9.4
								c3.9-2.1,7.8-4.2,11.6-6.3c-2.4,1.1-4.9,2.1-7.3,3.2c6.2-2.6,12.4-5.2,18.7-7.8c-4.8,1.7-9.5,3.3-14.3,5
								c3.9-0.9,7.9-1.8,11.8-2.7c-2.3,0.4-4.7,0.8-7,1.1c3.9-0.5,7.7-1,11.6-1.6c-2.2,0.1-4.5,0.2-6.7,0.3
								c3.8,0.1,7.6,0.2,11.4,0.3c-2.1-0.2-4.2-0.3-6.3-0.5c3.7,0.4,7.3,0.8,11,1.2c-2-0.4-3.9-0.7-5.9-1.1c3.4,1,6.9,1.9,10.3,2.9
								c-1.8-0.5-3.6-1.1-5.4-1.6c3.3,1.2,6.5,2.4,9.8,3.7c-1.7-0.7-3.3-1.4-5-2.1c4.2,2.2,8.2,4.8,11.9,7.7c-1.4-1-2.8-1.9-4.2-2.9
								c1.9,1.9,4,3.8,5.4,6.1c1.8,2.7,2.8,5.8,3.6,8.9c2,7.8,1,15.7,0.4,23.6c0.1-1.5,0.2-3,0.4-4.5c-0.4,2.9-0.9,5.8-1.3,8.8
								c0.3-1.5,0.5-3.1,0.8-4.6c-0.6,3.4-1.3,6.8-1.9,10.2c-0.6,3.2-1.4,6.3-2.4,9.4c0.5-1.7,0.9-3.3,1.4-5
								c-0.9,2.7-1.8,5.4-2.7,8.1c0.6-1.7,1.2-3.4,1.9-5c-1.1,2.7-2.3,5.4-3.4,8.2c0.8-1.7,1.5-3.4,2.3-5.1
								c-2.5,4.4-5.1,8.8-7.6,13.2c1.3-1.7,2.5-3.4,3.8-5c-2,2.4-4.1,4.8-6.1,7.2c1.5-1.7,3-3.3,4.4-5c-2.2,2.2-4.5,4.4-6.7,6.6
								c3.6-3,7.1-6,10.7-8.9c-2.6,1.9-5.1,3.7-7.7,5.6c2.1-1.3,4.2-2.6,6.3-3.9c-2.8,1.7-5.6,3.4-8.4,5.1c2.4-1.2,4.7-2.3,7.1-3.5
								c-3.1,1.3-6.2,2.6-9.2,3.9c2.6-0.9,5.2-1.9,7.8-2.8c-5.9,2-11.8,4.1-17.8,6.1c5.5-1.6,11.1-3.2,16.6-4.8
								c-7.6,0.6-15.2,1.2-22.8,1.9c6.4-0.2,12.7-0.5,19.1-0.7c-3.9-0.3-7.8-0.5-11.8-0.8c3.3,0.4,6.6,0.9,9.9,1.3
								c-4-0.9-8-1.8-12-2.6c3.4,0.9,6.8,1.8,10.2,2.7c-7.6-2.4-15.2-4.7-22.8-7.1c6.8,2.5,13.6,5,20.4,7.5c-7.5-3.5-15-7-22.4-10.4
								c6.7,3.5,13.4,6.9,20.1,10.4c-7.4-4.4-14.8-8.9-22.2-13.3c6.4,4.4,12.8,8.8,19.2,13.2c-7-5.5-13.9-11-20.9-16.5
								c6,5.3,12.1,10.7,18.1,16c-6.5-6.4-13-12.8-19.5-19.1c5.4,6,10.7,12.1,16.1,18.1c-5.7-7.2-11.4-14.3-17.1-21.5
								c4.7,6.7,9.3,13.5,14,20.2c-5.2-8.2-10.3-16.3-15.5-24.5c3.8,7.2,7.6,14.4,11.4,21.7c-4.1-8.7-8.2-17.4-12.3-26.1
								c2.9,7.7,5.8,15.4,8.7,23c-3-9.2-6.1-18.4-9.1-27.6c1.9,7.9,3.9,15.8,5.8,23.6c-1.9-9.4-3.9-18.9-5.8-28.3
								c0.6,4,1.3,8,1.9,12.1c-0.5-5.6-1-11.2-1.5-16.8c0.2,4,0.3,8,0.5,11.9c0-5.5,0.1-11,0.1-16.5c-0.3,3.9-0.5,7.8-0.8,11.7
								c0.6-5.5,1.2-10.9,1.9-16.4c-0.7,3.7-1.3,7.5-2,11.2c1.2-7.6,2.8-15,4.9-22.4c2.3-6.6,5-13,8.1-19.2c-1,2.3-2.1,4.5-3.1,6.8
								c2.2-4.2,4.4-8.3,6.6-12.5c-1.2,2.1-2.4,4.2-3.5,6.3c3.4-6,7-11.9,10.8-17.6c4-8.3,8.2-16.4,12.8-24.4
								c4.5-8,9.4-15.8,14.6-23.3c5.2-7.6,10.7-14.9,16.6-22c1.7-2.2,2-4.7,0.8-7.2c-1.2-2.6-3.1-4-6-4.2c-4.7,0.7-9.2,2.1-13.5,4.2
								c-4.4,1.8-8.5,4.2-12.1,7.3c-3,4.2-6.1,8.5-9.1,12.7c-4.5,6.5-8.7,13.1-12.5,20c-5.3,9.1-10.2,18.5-14.6,28.1
								c-7.9,16.9-15.6,34.3-18.7,52.8c-0.8,2.3-1,4.5-0.5,6.8c0.3,3.2,0.8,6.3,1.6,9.4c1,5.3,2,10.5,3.3,15.7
								c1.3,3.9,2.6,7.8,4,11.7c1.6,3.8,3.2,7.6,4.7,11.4c1.4,2.5,2.8,5.1,4.2,7.6c2,1.5,3.9,3.1,5.8,4.8c3.5,3.3,7.6,5.8,12.2,7.4
								c5.2,0.5,10.3,0.2,15.4-0.8c5.2-0.6,10.1-2,14.9-4.1c4.6-4.2,8.6-8.8,12.1-13.9c1-1.8,1.8-3.7,2.5-5.7
								c1.8-4.9,3.2-10.1,4.1-15.3c0.9-5,2.4-9.7,3.2-14.7c0.7-4.3,1.1-8.5,1.1-12.8c0-4.9-0.4-10-1.6-14.8c-0.6-3-1.6-5.7-3.2-8.3
								c-0.3-0.6-0.7-1.2-1-1.9c-1.4-2.7-3.1-5.2-5.3-7.4c-1.5-1.4-2.9-2.7-4.4-4.1c-1.4-1.4-2.9-2.6-4.6-3.6
								c-4-2.4-8.2-4.3-12.7-5.8c-4.4-1.7-9.1-1.8-13.8-1.5c-1.8-0.1-3.6,0.2-5.3,0.8c-2.1,0.1-3.5,1.2-4.3,3.2
								c-12.6,17.9-25.2,35.9-37.9,53.8c-0.8,3.3-0.3,6.4,1.4,9.4c1.9,5.5,4.3,10.9,7.3,15.9c2.2,3.7,4.3,7.5,6.5,11.2
								c2,4.7,5.2,8.1,9.7,10.1c1.7,0.5,3.3,1,5,1.5c16.7,2.5,31.3-0.5,43.8-8.7c3.7-2.4,7.4-4.9,10.7-7.8c2.7-2.3,5.3-4.8,7.9-7.3
								c3.8-3.6,7.1-7.6,9.7-12.1c1.8-2.9,3.7-5.8,5.7-8.6c-1.2,1.6-2.4,3.2-3.7,4.8c2.6-2.9,4.9-5.9,6.8-9.3c-1,1.5-2,3-3,4.5
								c2.8-3.3,5.1-6.9,6.8-10.9c2.3-3.7,4-7.6,5.2-11.8c-0.5,1-0.9,2-1.4,3c4.8-6.8,9-13.5,11.7-21.4c1.7-4,2.5-8.2,2.4-12.6
								c-0.6-4.3-1.5-8.6-2.7-12.8c0.2,1,0.4,2,0.6,3c-0.1-3.2-0.9-6.1-2.2-9c-4.1-6.1-10-9.8-17.8-11.1c-1.3-0.2-2.6-0.4-3.9-0.6
								c-3.5,1-6.8,2.4-9.9,4.3c-2.7,1.3-5.2,2.9-7.6,4.7c-3.1,1.9-5.9,4.1-8.4,6.8c-3.8,4.7-7.1,9.8-9.9,15.2
								c-2.8,5.3-5,10.7-6.6,16.4c-2.3,8.3-3.7,16.7-3.9,25.3c-0.1,5.6,0.4,11,1.1,16.6c0.5,4.3,1.2,8.5,2.3,12.6
								c0.7,4.4,2.1,8.7,4.1,12.7c1.6,2.6,3.9,3.5,6.8,2.7c4.1-2.2,6.8-5.6,8.2-10.3c3.5-7.3,6.5-14.8,8.9-22.6
								C831.1,1247.3,831.5,1242.9,830,1238.5z"/>
							<path opacity="0.1091" fill="#131313" d="M847.7,1266.5c-0.8-2-1.5-4-2.3-6c0.4,1.3,0.8,2.5,1.3,3.8
								c-1.1-4.1-1.7-8.3-1.8-12.6c-0.1-3.1,0.1-6.2,0.7-9.3c0.4-3,1.1-6,2.1-8.9c1.3-3.9,3-7.5,5-11c-1.2,2-2.4,3.9-3.6,5.9
								c2.3-3.6,4.5-7.2,6.8-10.9c-1.3,1.9-2.7,3.7-4,5.6c2.5-3.4,5.1-6.8,7.6-10.2c-1.5,1.8-2.9,3.5-4.4,5.3
								c2.8-3.2,5.6-6.4,8.4-9.6c-1.6,1.6-3.2,3.3-4.7,4.9c3.1-2.9,6.2-5.9,9.2-8.8c-7.2,4.8-14.3,9.7-21.5,14.5
								c6.4-3.4,12.8-6.7,19.1-10.1c-2.9,1.3-5.8,2.6-8.7,4c3.4-1.2,6.8-2.4,10.2-3.6c-6.2,1.9-12.3,3.8-18.5,5.7
								c6.5-1.7,13-3.3,19.5-5c-6.4,1.1-12.8,2.3-19.1,3.4c3.6-0.3,7.3-0.6,10.9-0.9c-5.3-0.3-10.7-0.6-16-0.9
								c8.8,1.7,17.7,3.4,26.5,5.1c-7.9-2.4-15.8-4.9-23.7-7.3c7.6,5.5,15.3,10.9,22.9,16.4c-4.3-3.9-8.6-7.8-12.9-11.7
								c4.1,4.5,8.2,9.1,12.2,13.6c-3.5-4.6-7-9.3-10.4-13.9c1.8,3.5,3.7,6.9,5.5,10.4c-1.2-2.5-2.4-5-3.5-7.5
								c1.3,3.6,2.5,7.1,3.8,10.7c-0.8-2.6-1.5-5.2-2.3-7.8c0.5,3.8,1.1,7.6,1.6,11.4c-0.4-2.6-0.7-5.2-1.1-7.8
								c0.1,3.8,0.2,7.6,0.4,11.3c0-2.6,0-5.2,0-7.9c-0.2,6.1-0.4,12.2-0.6,18.3c0.5-5.2,1-10.3,1.5-15.5c-0.9,4.7-1.8,9.3-2.7,14
								c0.6-2.4,1.2-4.9,1.8-7.3c-2.2,8.6-4.9,17.1-8,25.4c0.8-2.3,1.6-4.6,2.5-6.9c-1.8,3.9-3.5,7.7-5.3,11.6
								c1.1-2.2,2.1-4.4,3.2-6.6c-2.1,3.6-4.2,7.2-6.2,10.8c1.3-2.1,2.6-4.2,3.9-6.2c-2.4,3.4-4.8,6.9-7.3,10.3c1.5-2,3-3.9,4.5-5.9
								c-2.7,3.2-5.4,6.4-8.1,9.5c1.7-1.8,3.4-3.6,5.1-5.4c-3,3-6,6-9,9c1.9-1.7,3.7-3.3,5.6-5c-3.2,2.7-6.3,5.5-9.5,8.2
								c2.1-1.5,4.1-3,6.2-4.5c-4.7,3.8-9.6,7.2-14.8,10.3c-2.3,1.3-4.6,2.5-7,3.5c-5.5,2.5-11.1,4.1-17,5.4c2.5-0.2,5-0.4,7.5-0.6
								c-5.4,0.7-10.8,1-16.2,1c2.5,0.2,5,0.3,7.6,0.5c-3.9-0.3-7.8-0.5-11.7-0.8c2.5,0.4,5,0.7,7.5,1.1c-3.8-0.6-7.6-1.2-11.5-1.8
								c2.5,0.5,4.9,1.1,7.4,1.6c-3.7-1-7.4-1.9-11-2.9c2.4,0.7,4.8,1.5,7.2,2.2c-3.5-1.4-7.1-2.8-10.6-4.1c2.3,1,4.6,1.9,6.9,2.9
								c-3.3-1.7-6.5-3.4-9.8-5.1c4.3,2.7,8.5,5.4,12.8,8.1c-5.3-4.6-10.6-9.2-15.9-13.8c3.5,3.6,7.1,7.1,10.6,10.7
								c-2.2-2.7-4.4-5.5-6.6-8.2c1.4,2,2.9,4,4.3,6.1c-4-5.7-7.6-11.7-10.9-17.8c2.2,4.5,4.4,9.1,6.7,13.6c-1.5-3.6-3-7.3-4.4-10.9
								c0.8,2.4,1.5,4.8,2.3,7.2c-2.5-7.5-4.4-15-5.9-22.8c0.8,5.1,1.7,10.2,2.5,15.3c-1.2-7.8-1.7-15.6-1.6-23.5
								c0,2.7,0.1,5.3,0.1,8c0.3-4,0.6-8,1-12c-0.3,2.7-0.5,5.4-0.8,8.1c0.7-4,1.4-7.9,2.1-11.9c-0.5,2.7-1.1,5.4-1.6,8
								c1.1-3.9,2.2-7.9,3.4-11.8c-0.8,2.7-1.6,5.3-2.5,8c1.5-3.8,3-7.6,4.5-11.4c-1.1,2.6-2.2,5.2-3.3,7.8c2-3.7,3.9-7.4,5.9-11.1
								c-1.4,2.5-2.8,5.1-4.2,7.6c2.3-3.5,4.7-6.9,7-10.4c-3.6,4.6-7.2,9.3-10.8,13.9c4.7-5.5,9.4-11,14.1-16.5
								c-4.2,4.4-8.4,8.7-12.6,13.1c5.3-5,10.6-10,15.9-15c-4.6,3.8-9.3,7.7-13.9,11.5c5.7-4.1,11.5-8.3,17.2-12.4
								c-5.1,3.3-10.1,6.6-15.2,9.9c6.2-3.5,12.4-6.9,18.6-10.4c-5.4,2.7-10.8,5.3-16.2,8c6.6-2.7,13.2-5.5,19.7-8.2
								c-5.7,2-11.5,4-17.2,6.1c6.9-2,13.8-4,20.7-5.9c-5.9,1.4-11.8,2.7-17.7,4.1c7.1-1.2,14.3-2.4,21.4-3.6
								c-6.1,0.7-12.2,1.4-18.3,2.1c7.3-0.4,14.5-0.9,21.8-1.3c-6.1,0-12.2,0-18.3,0.1c7.3,0.3,14.5,0.7,21.8,1
								c-3.1-0.2-6.1-0.4-9.2-0.5c4.2,0.6,8.4,1.3,12.5,1.9c-3-0.5-6-1-9-1.5c4.1,1.1,8.3,2.1,12.4,3.2c-3-0.8-5.9-1.6-8.9-2.5
								c4,1.5,8,3,11.9,4.4c-2.9-1.1-5.7-2.2-8.6-3.4c3.8,1.9,7.6,3.8,11.4,5.7c-5.4-3.1-10.7-6.2-16.1-9.3
								c6.1,4,12.3,7.9,18.4,11.9c-4.9-3.6-9.9-7.2-14.8-10.8c5.6,4.6,11.3,9.1,16.9,13.7c-4.5-4.1-9-8.2-13.5-12.3
								c5.1,5.2,10.1,10.4,15.2,15.6c-3.9-4.5-7.9-9-11.8-13.5c4.3,5.6,8.6,11.3,12.9,16.9c-3.3-4.9-6.7-9.8-10-14.6
								c3.7,6.3,7.4,12.6,11,18.9c-2.6-5.1-5.3-10.2-7.9-15.2c2.8,6.6,5.7,13.2,8.5,19.8c-2-5.3-3.9-10.6-5.9-15.9
								c1.2,4.1,2.4,8.3,3.6,12.4c-0.5-2.7-1-5.4-1.5-8.2c1.2,6.9,2.5,13.9,3.7,20.8c-0.7-5.5-1.3-10.9-2-16.4
								c0.5,7,0.9,13.9,1.4,20.9c-0.2-2.7-0.3-5.4-0.5-8c0,4.2-0.1,8.4-0.1,12.6c0.1-2.6,0.2-5.3,0.3-7.9c-0.3,4.1-0.6,8.2-0.9,12.3
								c0.4-2.6,0.7-5.2,1.1-7.9c-0.7,3.9-1.4,7.8-2.1,11.7c0.6-2.5,1.1-5.1,1.7-7.6c-0.9,3.9-1.9,7.8-2.8,11.6
								c0.8-2.5,1.5-5,2.3-7.5c-1.4,5.1-3,10.2-5,15.1c-1,2.4-2.1,4.7-3.4,6.9c1.4-2.2,2.8-4.3,4.3-6.5c-2.1,3.2-4.2,6.3-6.3,9.5
								c1.6-2.1,3.1-4.1,4.7-6.2c-2.4,3-4.8,5.9-7.1,8.9c1.7-1.9,3.5-3.9,5.2-5.8c-2.6,2.7-5.2,5.3-7.9,8c1.9-1.8,3.8-3.6,5.7-5.3
								c-2.9,2.4-5.8,4.7-8.7,7.1c4.3-3,8.5-6,12.8-9c-3.2,2-6.4,4-9.6,5.9c2.3-1.2,4.7-2.3,7-3.5c-3.3,1.5-6.6,3.1-9.9,4.6
								c2.4-0.9,4.8-1.8,7.2-2.6c-5.7,2-11.5,4-17.2,6c4.8-1.4,9.7-2.8,14.5-4.1c-3.7,0.5-7.4,1-11,1.5c2.4-0.2,4.9-0.5,7.3-0.7
								c-3.7,0-7.3,0-11,0c2.4,0.1,4.9,0.1,7.3,0.2c-3.5-0.5-7-1-10.5-1.5c2.4,0.4,4.7,0.8,7.1,1.2c-3.4-1-6.8-2-10.2-2.9
								c2.3,0.7,4.6,1.4,6.9,2.1c-3.2-1.4-6.5-2.8-9.7-4.2c2.1,1,4.3,2,6.4,2.9c-3-1.8-6.1-3.6-9.2-5.4c2,1.3,4,2.5,6.1,3.8
								c-2.8-2.1-5.6-4.2-8.4-6.3c1.8,1.5,3.7,3,5.5,4.5c-2.6-2.5-5.1-4.9-7.7-7.4c1.7,1.7,3.3,3.5,5,5.2c-2.3-2.7-4.6-5.5-6.8-8.2
								c1.4,1.9,2.9,3.8,4.3,5.8c-1.9-3-3.9-6-5.8-9c1.2,2.1,2.4,4.2,3.6,6.3c-1.8-3.5-3.6-6.9-5.3-10.4c0.9,2.2,1.9,4.5,2.8,6.7
								c-1.3-3.7-2.7-7.4-4-11.1c0.7,2.4,1.4,4.7,2.1,7.1c-1-3.7-1.9-7.5-2.9-11.2c0.4,2.5,0.8,4.9,1.2,7.4
								c-0.5-3.9-1.1-7.8-1.6-11.7c0.1,2.6,0.2,5.1,0.4,7.7c-0.3-6.6-0.6-13.2-0.8-19.8c0.1,2.6,0.2,5.2,0.3,7.8
								c0.3-4.1,0.5-8.2,0.8-12.3c-0.2,2.6-0.4,5.3-0.6,7.9c0.6-4.1,1.2-8.2,1.8-12.3c-0.5,2.6-0.9,5.3-1.4,7.9c1-4.1,2-8.2,3-12.3
								c-0.7,2.6-1.5,5.3-2.2,7.9c1.4-4.6,2.7-9.2,4.1-13.9c-1,2.6-2,5.2-3.1,7.8c1.9-5.7,4.2-11.2,6.7-16.6
								c2.2-4.9,4.6-9.7,7.3-14.4c-1,1.9-2,3.8-3,5.7c3.2-5.7,6.6-11.3,10.2-16.8c3.9-8.2,8.1-16.3,12.6-24.3
								c4.5-8,9.2-15.7,14.3-23.3c5.1-7.6,10.6-14.9,16.3-21.9c2.1-2.2,2.5-4.7,1.4-7.5c-1.3-2.7-3.5-4-6.5-3.9
								c-5.5,1-10.7,2.7-15.7,5.2c-5.2,2.1-9.9,4.8-14.3,8.2c-5.6,8.2-10.8,16.6-15.6,25.3c-2.3,4.4-4.6,8.8-6.9,13.2
								c-3.3,6.7-6.4,13.5-9.2,20.4c-3,6.9-5.7,13.9-8.2,20.9c-2.5,7.2-4.7,14.6-6.2,22.1c-1.1,4.9-1.7,9.9-1.7,15
								c-0.6,4.5-0.6,9.1,0,13.6c0,1,0,2.1,0,3.1c1.1,6.5,2.1,13,3.3,19.5c1.7,5.4,4.2,10.7,7.6,15.3c1.3,1.5,2.5,3.1,3.8,4.6
								c1.5,2.5,3.6,4.2,6.2,5.3c2.8,1.7,5.9,2.8,9.2,3.3c6.5,1.2,13,2.3,19.6,3.3c1.2,0.2,2.4,0.4,3.6,0.6c3.1-0.9,5.9-2.3,8.5-4.2
								c2.3-1.3,4.4-2.8,6.4-4.5c2.7-1.8,4.9-4,6.9-6.6c4-5.3,6.9-11.2,8.5-17.6c5.4-20.7,2.6-41.4-8.4-62c-0.5-1.3-1-2.5-1.4-3.8
								c-2.7-2.7-5.6-5-8.9-6.8c-2.5-1.8-5.2-3.3-8-4.6c-3.2-1.9-6.7-3.4-10.4-4.3c-5.7-0.8-11.3-0.6-16.9,0.6
								c-2.9,0.9-5.8,1.8-8.7,2.6c-5,1.6-9.7,3.8-14.1,6.7c-2.6,2.9-5,6-7.1,9.4c-2.6,3.5-4.8,7.2-6.7,11.2
								c-1.8,3.5-2.7,7.2-2.7,11.1c-0.7,3.4-0.8,6.7-0.3,10.1c0.1,5.5,1,10.8,2.9,16c-0.6-2.5-1.1-5.1-1.7-7.6
								c0.4,3.1,1,6.2,1.8,9.2c2.4,5.8,4.9,11.5,7.4,17.2c2.8,3.3,6.1,6.1,10,8.3c3.6,2.5,7.5,4.3,11.7,5.4c9.4,0.7,17.8,2.2,26.8,0
								c3.6-0.9,7.2-2,10.8-3.3c7.6-2.8,16.1-8.1,22.5-13.4c4.4-3.6,8.5-7.4,12.1-11.8c2.2-2.4,4-5.1,5.6-7.9
								c5.5-7.9,9.6-16.5,12.3-25.8c0.9-2.2,1.9-4.4,2.8-6.6c2.3-4.6,3.7-9.5,4.2-14.7c1.6-4.7,2.2-9.4,1.9-14.3
								c-0.2-1.7-0.4-3.5-0.6-5.2c-0.3-1.6-0.6-3.3-0.8-4.9c-1.4-4.1-3.5-7.7-6.3-11c-2.1-1.8-4.5-2.5-7.2-2
								c-4.4-0.2-8.8,0.3-13.1,1.3c-3.2,0.7-6.3,1.4-9.5,2.1c-4.1,0.4-7.2,2.2-9.3,5.5c-5,8.9-10.1,17.4-13.5,27.1
								c-2.9,8-4.1,16.6-4.4,25.1c-0.3,7.4,0.4,14.6,1.9,21.8c1.5,6.8,3.6,13.1,6.2,19.4c1.8,2.2,4.1,3.1,6.8,2.7
								c3.5-1.1,5.9-3.6,7-7.3c2.7-5.4,5-11,6.7-16.7C849.5,1273.1,849.5,1269.8,847.7,1266.5z"/>
							<path opacity="0.1091" fill="#131313" d="M849.9,1234c-2-4-3.1-8.1-3.3-12.6c0.2,1.8,0.3,3.7,0.5,5.5
								c-0.2-3.9-0.5-7.9-0.7-11.8c0.1,1.8,0.2,3.7,0.3,5.5c-0.1-3.1,0.2-6.1,0.9-9.1c0.4-3,1.2-5.9,2.2-8.7
								c2.1-5.6,5-10.5,8.2-15.4c-2,2.5-3.9,5.1-5.9,7.6c3.1-4,6.2-7.9,9.3-11.9c-2.1,2.3-4.2,4.7-6.2,7c3.4-3.7,6.7-7.3,10.1-11
								c-2.2,2.1-4.3,4.2-6.5,6.3c3.6-3.3,7.2-6.6,10.8-10c-5.7,4.2-11.3,8.3-17,12.5c3.9-2.3,7.8-4.5,11.7-6.8
								c-3.4,1.9-6.9,3.9-10.3,5.8c3.9-1.9,7.8-3.9,11.8-5.8c-7,3-14.1,6-21.1,8.9c7.9-2.7,15.8-5.4,23.6-8.1c-7,1.4-14,2.8-21,4.2
								c4.3-0.4,8.7-0.8,13-1.3c-3.2,0.1-6.4,0.2-9.7,0.3c3.9,0.2,7.8,0.4,11.7,0.5c-2.9-0.4-5.8-0.7-8.7-1.1
								c6.2,1.2,12.3,2.4,18.5,3.5c-7-2.1-13.9-4.3-20.9-6.4c2.9,1.6,5.7,3.2,8.6,4.9c-1.4-1-2.7-2-4.1-3.1c5.1,3.4,9.7,7.5,13.8,12
								c-2.1-2.7-4.2-5.4-6.4-8.1c1.4,2.6,2.9,5.2,4.3,7.8c-0.4-1.3-0.9-2.5-1.3-3.8c0.9,5,1.3,10,1.1,15.1c0-1.1,0-2.3,0-3.4
								c-1.4,4-2.9,7.6-4.7,11.4c-0.5,0.9-0.9,1.9-1.4,2.8c-1.2,4.6-2.7,9-4.7,13.3c0.5-1.3,1-2.5,1.5-3.8c-1.3,2.7-2.6,5.4-3.9,8.1
								c0.6-1.2,1.3-2.4,1.9-3.6c-1.6,2.6-3.1,5.2-4.7,7.8c0.8-1.2,1.6-2.3,2.4-3.5c-1.8,2.4-3.6,4.9-5.5,7.3
								c0.9-1.1,1.9-2.2,2.8-3.3c-3,3.5-6.2,7-9.4,10.3c-3.4,3.1-7,6.1-10.6,9.1c1.4-1.1,2.8-2.2,4.3-3.2c-2.7,1.9-5.5,3.7-8.2,5.6
								c1.6-1,3.2-2,4.7-3c-3,1.7-6,3.4-9,5.2c1.7-0.9,3.4-1.8,5.1-2.7c-3.1,1.5-6.3,3.1-9.4,4.6c1.8-0.8,3.7-1.5,5.5-2.3
								c-4.6,2.1-9.4,4-14.2,5.5c-2.9,0.9-5.9,1.6-8.9,2.1c2.3-0.2,4.7-0.4,7-0.6c-3.6,0.3-7.2,0.6-10.8,0.9c2.4,0,4.9,0,7.3,0
								c-5,0.2-10,0.1-15-0.2c2.6,0.4,5.3,0.8,7.9,1.2c-3.8-0.6-7.6-1.3-11.5-1.9c2.7,0.6,5.4,1.2,8.1,1.9c-3.8-1-7.6-1.9-11.4-2.9
								c2.7,0.9,5.5,1.8,8.2,2.7c-3.8-1.5-7.5-3-11.3-4.5c2.8,1.2,5.6,2.4,8.3,3.6c-3.6-1.9-7.2-3.7-10.8-5.6
								c5.4,3.4,10.8,6.7,16.1,10.1c-5.9-4.2-11.8-8.3-17.7-12.5c5.2,4.1,10.4,8.2,15.6,12.2c-5.6-5-11.1-10.1-16.7-15.1
								c4.9,4.8,9.9,9.7,14.8,14.5c-5.3-5.7-10.5-11.5-15.8-17.2c4.5,5.5,9,11,13.5,16.5c-4.8-6.5-9.5-13-14.3-19.5
								c2.2,3,4.3,6,6.5,9c-2.4-3.9-4.8-7.8-7.2-11.7c1.9,3.3,3.8,6.7,5.7,10c-2.3-4.6-4.6-9.3-6.9-13.9c1.6,3.6,3.2,7.2,4.8,10.8
								c-1.8-5-3.6-10-5.4-15c1.3,3.9,2.5,7.7,3.8,11.6c-1.4-5.2-2.8-10.4-4.2-15.7c0.9,4.1,1.8,8.1,2.6,12.2
								c-0.8-5.5-1.7-10.9-2.5-16.4c0.5,4.3,1,8.5,1.4,12.8c-0.4-5.6-0.7-11.2-1.1-16.7c0,4.3,0.1,8.6,0.1,12.9
								c0.2-5.6,0.5-11.2,0.7-16.8c-1.1,8.6-2.2,17.3-3.3,25.9c2.6-14.3,5.1-28.5,7.7-42.8c-1.9,7.2-3.8,14.5-5.6,21.7
								c1.9-5.4,3.8-10.8,5.8-16.2c-1.7,4-3.4,8-5.1,12c2.5-5.1,4.9-10.3,7.4-15.4c-2.1,3.8-4.2,7.6-6.3,11.5c3-4.8,6-9.7,9-14.5
								c-2.4,3.5-4.9,7.1-7.3,10.6c3.5-4.4,7-8.9,10.5-13.3c-5.8,6.3-11.6,12.5-17.4,18.8c6.9-6.9,13.8-13.9,20.7-20.8
								c-6.2,5.3-12.4,10.6-18.5,15.9c7.2-5.6,14.4-11.3,21.6-16.9c-6.5,4.4-13,8.7-19.5,13.1c7.6-4.6,15.3-9.2,22.9-13.8
								c-6.6,3.4-13.1,6.8-19.7,10.1c7.8-3.6,15.5-7.2,23.3-10.7c-6.7,2.5-13.3,4.9-20,7.4c7.9-2.5,15.9-4.9,23.8-7.4
								c-3.2,0.9-6.4,1.8-9.7,2.8c4.5-0.9,9.1-1.8,13.6-2.7c-6.4,0.8-12.7,1.5-19.1,2.3c7.7-0.5,15.3-1,23-1.5c-3,0.2-6,0.3-9,0.5
								c4.3,0.1,8.7,0.2,13,0.4c-5.7-0.6-11.3-1.2-17-1.8c8.6,1,16.9,2.7,25.2,5.2c-4.9-1.6-9.7-3.2-14.6-4.8
								c3.8,1.6,7.5,3.1,11.3,4.7c-2.2-1.1-4.3-2.2-6.5-3.4c3.5,1.8,6.9,3.7,10.4,5.5c-1.9-1.3-3.9-2.6-5.8-3.9
								c3.1,2.3,6.1,4.6,9.2,6.8c-1.7-1.4-3.3-2.8-5-4.2c2.7,2.5,5.4,5,8.2,7.4c-1.4-1.5-2.8-3-4.2-4.5c3.3,4.2,6.1,8.7,8.5,13.4
								c-0.9-1.6-1.8-3.1-2.7-4.7c1.4,4.4,2.7,8.6,3.1,13.2c0.4,5.4-0.1,10.5-0.5,15.9c0.1-1.4,0.2-2.9,0.3-4.3
								c-0.4,2.9-0.9,5.8-1.3,8.7c0.3-1.4,0.5-2.8,0.8-4.2c-0.5,2.8-1.1,5.7-1.6,8.5c0.4-1.3,0.8-2.7,1.2-4c-1.2,4.3-2.5,8.6-4,12.8
								c-1.6,4.3-3.6,8.3-5.7,12.5c0.7-1.3,1.3-2.6,2-3.9c-1.4,2.4-2.8,4.7-4.2,7.1c0.8-1.3,1.5-2.6,2.3-3.9c-2.1,2.8-4.1,5-6.7,7.2
								c1.4-1,2.7-1.9,4.1-2.9c-3,1.7-5.9,3.4-8.9,5.1c1.7-0.8,3.4-1.5,5.1-2.3c-3.2,0.9-6.3,1.8-9.5,2.5c3.5-0.4,6.9-0.7,10.4-1.1
								c-2.2-0.1-4.4-0.2-6.5-0.3c1.5,0.2,3,0.4,4.5,0.6c-9.5-4.1-18.9-8.3-28.4-12.4c5.1,2.7,10.1,5.5,15.2,8.2
								c-3-2-6.1-4.1-9.1-6.1c2.4,1.9,4.8,3.8,7.2,5.6c-2.9-2.6-5.8-5.2-8.7-7.8c2.3,2.3,4.6,4.6,6.9,6.9
								c-5.2-5.4-10.3-10.8-15.5-16.2c4.4,5.1,8.8,10.2,13.2,15.3c-4.6-6.3-9.2-12.5-13.9-18.8c4,5.9,7.9,11.7,11.9,17.6
								c-4.4-7.2-8.8-14.5-13.2-21.7c3.3,6.5,6.6,13,10,19.5c-3.5-8-7.1-16-10.6-24c2.7,7.2,5.3,14.3,8,21.5
								c-2.8-8.6-5.5-17.3-8.3-25.9c1.8,7.6,3.6,15.3,5.5,22.9c-1.8-9.2-3.5-18.4-5.3-27.5c0.6,4,1.2,8.1,1.9,12.1
								c-0.5-5.6-1-11.2-1.5-16.7c0,8.3,0.1,16.6,0.1,24.9c1.5-15.8,3-31.6,4.5-47.4c-0.5,3-1,6-1.6,9c1.4-5,2.7-10,4.1-15.1
								c-0.8,3-1.6,5.9-2.5,8.9c1.8-4.9,3.7-9.8,5.5-14.7c-1.1,2.9-2.2,5.7-3.3,8.6c2.2-4.8,4.4-9.6,6.7-14.3
								c-1.4,2.8-2.7,5.5-4.1,8.3c2.5-4.6,5.1-9.3,7.6-13.9c-1.6,2.6-3.2,5.3-4.7,7.9c3.9-6.4,7.9-12.7,11.9-19
								c3.8-8.2,8-16.3,12.4-24.2c4.4-7.9,9.2-15.6,14.3-23.1c5.1-7.5,10.5-14.8,16.2-21.8c2.5-2.2,3.3-4.8,2.2-7.9
								c-1.6-2.8-4.1-4-7.4-3.5c-6.5,1.5-12.8,3.7-18.8,6.6c-6.2,2.6-12,5.8-17.4,9.7c-6,8.6-11.6,17.5-16.7,26.7
								c-2.5,4.7-4.9,9.3-7.4,14c-4.6,9.5-8.8,19.2-12.7,29c-5,12.8-8.8,25.5-10.5,39.2c-1.2,10.1-0.9,20.3,1.3,30.2
								c1,4.4,2.8,8.4,4.7,12.5c1.3,3.1,3.1,6,5.3,8.6c3.3,4.9,7.8,8.6,13.4,11.1c-3.9-3.5-7.7-6.9-11.6-10.4
								c4.2,6.3,8.1,12.9,14.3,17.5c3.7,2.7,7.6,5.1,11.9,6.7c2.2,1,4.6,1.4,7.1,1.2c3-0.3,5.9-0.6,8.9-0.8c2.2-0.3,4.3-1.1,6.1-2.4
								c1.9-1.1,3.7-2.2,5.5-3.4c6.4-4.8,10.8-11.2,14.2-18.4c1.5-3.1,2.8-6.3,3.8-9.5c0.5-1.6,1-3.2,1.5-4.8
								c1.1-3.5,1.9-7,2.4-10.6c-0.3,1.4-0.5,2.8-0.8,4.2c0.8-2.9,1.5-5.8,2-8.8c0.7-3.1,1.2-6.3,1.4-9.4c-0.1,1.1-0.3,2.3-0.4,3.4
								c0.3-2.1,0.5-4.1,0.8-6.2c0-1.8,0-3.5-0.1-5.3c0-1.7-0.1-3.4-0.4-5c-0.1-3.2-0.8-6.3-2-9.3c-1.1-3.2-2.8-6.1-5.2-8.5
								c-2.1-2.7-4.7-4.8-7.7-6.4c-19.9-2.8-39.9-5.5-59.8-8.3c-3.3,1.1-5.7,3.1-7.3,6.1c-3.8,4.5-7.1,9.4-9.9,14.7
								c-2.1,3.8-4.2,7.6-6.3,11.5c-3,4.2-4.2,8.7-3.6,13.6c0.6,4.2,1.1,8.6,2.1,12.7c0.9,3,2.1,5.8,3.4,8.6
								c1.1,2.9,2.7,5.4,4.7,7.7c1.4,2.7,3.2,4.9,5.5,6.8c1.3,1.2,2.6,2.4,3.9,3.6c3.5,3.6,8.1,5.3,13.8,5.1
								c5.9,2.3,11.9,3.8,18.1,4.5c2.8-0.5,5.5-1.1,8.3-1.6c2.7-0.9,5.5-1.8,8.2-2.7c4.5-1.8,9-4.1,13.2-6.5
								c2.6-1.5,5.1-3.2,7.6-4.9c1.6-1,3.1-2.1,4.6-3.2c3.7-2.6,7-5.6,9.9-9.1c-1.2,1.1-2.5,2.3-3.7,3.4c5.3-4.7,10.5-9.7,14.9-15.3
								c2.8-3.6,5.3-7.5,7.4-11.5c2.2-4,4.1-8.2,5.6-12.6c-0.5,0.9-0.9,1.9-1.4,2.8c2.6-3.1,4.9-6.4,6.8-10
								c2.9-3.9,4.5-8.3,4.9-13.1c1.5-4.5,1.7-9.1,0.5-13.7c-5-8.5-10.2-16.8-15.6-25c-1.4-2.1-3.3-2.9-5.6-2.4
								c-3.8-0.3-7.3,0.8-10.5,3.5c-3.6,2.1-7.1,4.1-10.7,6.2c-6.1,3.5-11.8,7.7-17,12.5c-3.2,5.1-6,10.4-8.2,15.9
								c-1,2.8-2,5.5-2.9,8.3c-2.2,7.2-3.6,14.5-4.1,22c-0.6,8.2-0.1,16.7,1.2,24.8c0.9,5.2,2.1,10.4,3.6,15.5
								c0.7,4.4,2.2,8.4,4.3,12.3c1.5,2.9,3.8,3.8,6.8,2.7c4.5-3,7.5-7.1,9.1-12.5c4.1-8.7,7.6-17.6,10.6-26.7
								C850.5,1244.3,851.2,1239.2,849.9,1234z"/>
							<path opacity="0.1091" fill="#131313" d="M813.1,1266c-0.4-2.9-0.8-5.9-1.2-8.8c-1.1-2.9-1.7-5.8-1.7-8.8
								c-0.3-2.1-0.3-4.3,0-6.5c0-3.2,0.5-6.3,1.3-9.4c-0.4,1.6-0.9,3.1-1.3,4.7c1.1-3.6,2.1-7.1,3.2-10.7c-0.5,1.5-1,3-1.6,4.5
								c1.3-3.4,2.7-6.8,4-10.2c-0.6,1.4-1.2,2.8-1.8,4.3c1.7-3.9,3.8-7.6,6.3-11.1c2.6-3.6,5.5-6.9,8.9-9.8
								c-1.4,1.2-2.8,2.3-4.2,3.5c3.1-2.5,6.1-5,9.2-7.5c-3,1.8-5.9,3.6-8.9,5.4c1.6-0.5,3.1-0.8,4.8-1c-1.2-0.1-2.4-0.2-3.5-0.3
								c1.8,0.3,3.6,0.6,5.4,1c-1.1-0.2-2.3-0.4-3.4-0.6c1.5,1,2.6,2.3,3.4,3.9c-0.4-0.9-0.8-1.8-1.2-2.7c0.8,1.7,1.6,3.5,2.3,5.2
								c-0.3-0.9-0.5-1.9-0.8-2.8c0.4,1.8,0.7,3.6,1.1,5.3c-0.1-1-0.2-1.9-0.3-2.9c0,1.9,0.1,3.8,0.1,5.6c0-1,0.1-2,0.1-3
								c-0.3,1.9-0.5,3.8-0.8,5.7c0.2-1,0.3-2,0.5-3c-0.8,1.7-1.6,3.4-2.3,5.1c-0.1,0.6-0.4,1.2-0.7,1.8c-0.6,0.9-0.9,1.8-0.9,2.9
								c-0.9,3.3-1.5,6.7-1.7,10.1c0.4-2.3,0.8-4.6,1.2-6.8c-1,3.8-1.9,7.7-2.9,11.5c0.6-2.4,1.3-4.7,1.9-7.1
								c-2.8,7.8-5.5,15.5-8.1,23.3c0.9-2.6,1.9-5.2,2.8-7.8c-1.9,3.9-3.7,7.8-5.6,11.8c1.3-2.7,2.6-5.3,3.9-8
								c-2.2,3.9-4.3,7.9-6.5,11.8c1.7-2.7,3.4-5.3,5-8c-2.7,3.9-5.5,7.8-8.2,11.7c2.1-2.6,4.1-5.3,6.2-7.9
								c-6.2,7-12.3,14-18.5,20.9c4.6-4.5,9.3-8.9,13.9-13.4c-7.3,6.8-15,13.1-23.1,19c2.3-1.8,4.7-3.5,7-5.3
								c-3.9,2.5-7.8,5.1-11.7,7.6c2.5-1.6,5.1-3.2,7.6-4.8c-4.2,2.3-8.3,4.6-12.5,6.9c2.7-1.4,5.4-2.8,8.1-4.3c-4.4,2-8.8,4-13.2,6
								c2.9-1.2,5.7-2.5,8.6-3.7c-4.6,1.7-9.1,3.4-13.7,5.1c3-1,6-2,9-3.1c-4.7,1.3-9.4,2.7-14.2,4c3.1-0.8,6.2-1.6,9.3-2.4
								c-4.8,1-9.7,2-14.5,3c3.2-0.6,6.4-1.1,9.6-1.7c-4.7,0.5-9.3,1-14,1.5c3.3-0.3,6.5-0.6,9.8-1c-4.7,0.2-9.5,0.4-14.2,0.5
								c3.3-0.1,6.6-0.2,9.9-0.2c-4.7-0.2-9.4-0.4-14.1-0.6c3.3,0.2,6.6,0.3,9.9,0.5c-1.3-0.2-2.5-0.3-3.8-0.5c9,2.4,18,4.7,27,7.1
								c-15.1-5.1-30.3-10.3-45.4-15.4c10.4,5.7,20.9,11.4,31.3,17.1c-8.2-5.3-16.3-10.7-24.5-16c6.8,5.1,13.5,10.2,20.3,15.2
								c-7.3-5.9-14.5-11.9-21.8-17.8c5.9,5.6,11.9,11.2,17.8,16.8c-6.3-6.5-12.7-13.1-19-19.6c2.6,2.8,5.2,5.7,7.8,8.5
								c-3-3.8-6.1-7.7-9.1-11.5c2.2,3,4.4,6,6.5,9c-2.6-4-5.2-8-7.7-12c1.8,3.1,3.5,6.2,5.3,9.3c-2.2-4.5-4.4-9.1-6.6-13.6
								c1.4,3.1,2.8,6.1,4.1,9.2c-1.7-4.6-3.5-9.2-5.2-13.8c1,3,2,6.1,3,9.1c-1.1-4.6-2.2-9.2-3.3-13.7c0.7,3,1.3,5.9,2,8.9
								c-0.7-4.5-1.4-9.1-2.1-13.6c0.3,2.9,0.7,5.8,1,8.7c-0.1-4.5-0.2-9-0.3-13.5c0,2.7,0.1,5.5,0.1,8.2c0.3-4.3,0.5-8.7,0.8-13
								c-0.7,5.1-1.5,10.2-2.2,15.4c1.3-8.3,3.2-16.4,5.8-24.3c-1.6,4.3-3.2,8.7-4.7,13c1.7-3.6,3.3-7.3,5-10.9
								c-1.1,1.9-2.2,3.8-3.2,5.7c1.9-3.4,3.9-6.8,5.8-10.1c-1.2,1.7-2.5,3.4-3.7,5.1c2.3-2.9,4.6-5.9,7-8.8c-1.3,1.5-2.7,3-4,4.5
								c2.6-2.6,5.1-5.3,7.7-7.9c-1.5,1.3-2.9,2.6-4.4,3.9c2.9-2.2,6-4,9.3-5.5c2.7-0.8,5.5-1.4,8.4-1.7c4.4-0.3,8.9,0.5,13.1,1.7
								c4,1.2,7.8,2.9,11.2,5.3c4.6,3.4,7.5,8.3,10.9,12.9c-1-1.4-2-2.7-3-4.1c0.7,1.6,1.3,3.2,1.8,4.8c-0.2-1.3-0.4-2.7-0.6-4
								c0.7,5.4,1.5,10.7,2.6,16c-0.3-2-0.6-4.1-0.9-6.1c0.2,3.2,0.4,6.3,0.5,9.5c-0.1-2.2-0.2-4.4-0.3-6.6
								c0.1,3.4,0.2,6.7,0.2,10.1c0.1-2.3,0.3-4.6,0.4-6.9c-0.5,7.2-1,14.4-1.7,21.5c0.4-2.6,0.7-5.1,1.1-7.7
								c-0.9,6.1-2.3,12-4.1,17.8c1.1-2.8,2.2-5.6,3.3-8.3c-2.8,7.1-5.7,14.3-8.5,21.4c0.9-2.2,1.7-4.3,2.6-6.5
								c-1.1,2.1-2.2,4.1-3.5,6c2.2-2.8,4.4-5.6,6.6-8.4c-2.9,3.7-5.9,7.3-8.8,11c2.5-2.7,5-5.5,7.5-8.2c-3.3,3.5-6.6,6.9-9.9,10.4
								c2.8-2.6,5.7-5.2,8.5-7.9c-3.8,3.2-7.6,6.4-11.5,9.6c3.2-2.5,6.4-5,9.6-7.4c-4.2,2.9-8.5,5.8-12.7,8.7
								c7.2-4.2,14.5-8.4,21.7-12.6c-8.2,4.3-16.4,8.6-24.6,12.8c7.8-3.5,15.6-7,23.4-10.6c-8.7,3.5-17.5,7-26.2,10.4
								c8.3-2.6,16.5-5.3,24.8-7.9c-9.4,2.4-18.7,4.8-28.1,7.2c8.5-1.6,17-3.2,25.5-4.8c-9.6,1.1-19.2,2.2-28.7,3.4
								c8.7-0.5,17.5-1.1,26.2-1.6c-9.7-0.1-19.4-0.2-29.1-0.2c8.6,0.6,17.3,1.2,25.9,1.8c-9.5-1.4-19.1-2.7-28.6-4.1
								c8.5,1.7,17,3.4,25.5,5.2c-9.3-2.5-18.6-5.1-27.9-7.6c7.9,2.7,15.9,5.5,23.8,8.2c-8.7-3.6-17.4-7.2-26.1-10.8
								c7.4,3.7,14.8,7.4,22.2,11.1c-8.1-4.7-16.3-9.4-24.4-14.1c6.6,4.5,13.3,8.9,19.9,13.4c-7.3-5.5-14.6-11-21.9-16.4
								c5.9,5.2,11.8,10.3,17.7,15.5c-6.4-6.3-12.8-12.5-19.2-18.8c4.9,5.5,9.9,11.1,14.8,16.6c-5.4-6.7-10.8-13.3-16.2-20
								c4,5.9,8.1,11.7,12.1,17.6c-4.6-7.4-9.1-14.9-13.7-22.3c3.1,5.9,6.2,11.9,9.3,17.8c-3.5-7.6-7-15.1-10.5-22.7
								c2.2,6,4.4,12,6.6,17.9c-2.3-7.7-4.6-15.4-6.9-23c1.4,5.8,2.8,11.7,4.1,17.5c-1.4-7.6-2.9-15.2-4.3-22.7
								c0.6,5.7,1.2,11.4,1.9,17.1c-0.4-7.5-0.8-15-1.2-22.5c0.1,2.7,0.3,5.4,0.4,8.1c0.2-4.5,0.3-9,0.5-13.5
								c-0.2,2.6-0.3,5.1-0.5,7.7c0.7-4.3,1.4-8.5,2.1-12.8c-0.4,2.4-0.8,4.8-1.2,7.1c1-4.1,2-8.2,3-12.4c-0.6,2.2-1.2,4.5-1.8,6.7
								c1.4-4.4,2.8-8.8,4.1-13.2c-0.8,2.1-1.6,4.1-2.4,6.2c1.8-4.2,3.5-8.4,5.3-12.6c-0.9,1.9-1.9,3.8-2.8,5.8
								c2.3-5.2,5-10.2,7.9-15c1.8-3,3.6-6,5.6-8.9c1.3-1.9,2.6-3.9,3.8-5.8c1.9-3.2,3.7-6.5,5.5-9.8c5.6-10.2,11.2-20.5,17.6-30.2
								c2.5-3.8,5.1-7.5,7.6-11.3c5.1-7.4,10.6-14.5,16.3-21.4c0.8-2.3,0.6-4.5-0.5-6.6c-0.8-2.3-2.3-3.9-4.5-4.9
								c-2.9-0.1-5.7,0.5-8.3,1.8c-2.8,1-5.1,2.6-7,4.9c-6.1,7.7-11.9,15.6-17.3,23.8c-2.7,4.2-5.3,8.4-8,12.6
								c-5.4,8.9-10.4,18-15,27.3c-2.4,5.4-5.1,10.7-8.1,15.8c-4,6.9-7.6,14-10.5,21.4c-1.8,4.2-3.2,8.4-4.4,12.8
								c-4.7,24.7,1.3,48.7,17.9,72.3c0.7,3.3,2.8,5.3,6.3,5.9c5.3,0.7,10.5,0.9,15.8,0.5c6.6-0.1,13.1-0.5,19.6-1.4
								c5.2,0.2,9.6-1.4,13.4-4.7c9.2-12.5,13.1-25.6,11.5-39.5c-0.1-2-0.3-3.9-0.6-5.9c-0.3-1.9-0.6-3.9-0.8-5.8
								c-0.4-2.7-1.1-5.3-2-7.9c-0.2,6.6-0.5,13.3-0.7,19.9c1.4-8,3.3-15.8,3.1-24c-0.2-7.2-1.8-13.9-5.5-20.1
								c-5.3-8.8-13.3-15.5-22.6-19.7c-2.3-0.9-4.6-1.9-7-2.8c-3.5-1.1-7.1-1.9-10.8-2.5c-5.8-0.6-11.8-0.6-17.5,1.3
								c-4.1,1.4-7.9,3.4-11.4,5.9c-3,1.9-5.8,4.2-8.1,6.9c-2.7,3.3-4.9,6.8-6.7,10.7c-5.9,18.8-5.7,38.3,0.7,58.5
								c1.1,3.3,3.2,5.8,6.2,7.4c4.6,3.8,9.5,7.2,14.8,10c3.9,2.1,7.7,4.3,11.6,6.4c4.2,3.1,8.8,4.3,13.7,3.7
								c4.4-0.4,8.7-0.9,12.9-2c4.1-1,8.1-2.5,11.9-4.4c8.4-4.1,16-10.1,22.5-16.9c5.9-6.2,11-13.2,14.7-20.9
								c1.8-3.6,3.1-7.2,3.9-11.1c1.5-6.9,2.8-13.9,3.9-20.9c-1.4,3.9-2.8,7.9-4.2,11.8c1-2.1,2.1-4.3,3.1-6.4c1.9-3,3.8-6,5.7-9.1
								c1.8-3.2,3.5-6.7,4.7-10.2c2.4-7.3,2.3-15.2,0.8-22.6c-0.4-1.8-0.8-3.6-1.4-5.3c-0.9-1.7-1.8-3.4-2.8-5.1
								c-0.4-0.6-0.7-1.2-1.1-1.8c-0.8-1.2-1.6-2.4-2.4-3.6c-0.7-0.8-1.3-1.5-2-2.3c-4.4-3-8.8-3.9-13.3-2.6c-3.2,0.7-6,2.4-8.7,4.3
								c-4,2.9-7.5,6.5-10.8,10.3c-3.2,3.7-6.2,7.7-8.6,12c-1.3,2.3-2.6,4.7-3.7,7.1c-4.4,9.1-7,19.1-7.9,29.2
								c-0.5,5.3-0.4,10.5,0.3,15.9c0.1,2.4,0.6,4.7,1.4,6.9c1.2,3.7,1.9,7.5,2.2,11.4c0.8,5.2,7.6,7.4,11.6,4.6
								c2.6-3.2,4.6-6.7,6-10.6C811.9,1274.1,812.9,1270.2,813.1,1266z"/>
							<path opacity="0.1091" fill="#131313" d="M834.2,1297.9c0-4.4-0.7-8.7-1.8-12.8c-1-3-1.3-6.2-1-9.4c-0.1,1.1-0.1,2.2-0.2,3.3
								c0.3-3.2,0.5-6.5,0.8-9.7c-0.1,1.1-0.3,2.2-0.4,3.3c0.5-4.1,1.5-8.1,2.9-12c1-2.9,2.3-5.7,3.8-8.4c1.4-2.7,3-5.3,4.8-7.7
								c2.3-3.2,5-6.1,7.9-8.7c-6.4,0-12.9,0-19.3,0c6,13.6,12.1,27.2,18.1,40.8c-1.2-10.2-2.4-20.5-3.7-30.7
								c0.1,8.3,0.2,16.6,0.3,25c0.4-7.8,0.9-15.6,1.3-23.4c-0.9,9.6-1.8,19.2-2.7,28.8c0.5-3.9,1-7.8,1.5-11.7
								c-1.1,5.6-2.3,11.3-3.4,16.9c0.9-3.9,1.9-7.7,2.8-11.6c-1.6,5.4-3.3,10.9-4.9,16.3c1.4-3.7,2.7-7.5,4.1-11.2
								c-2.2,5.3-4.4,10.5-6.5,15.8c1.8-3.6,3.5-7.2,5.3-10.8c-2.7,5-5.4,10.1-8.1,15.1c2.1-3.4,4.2-6.8,6.3-10.2
								c-3.1,4.8-6.3,9.6-9.4,14.4c2.5-3.2,4.9-6.3,7.4-9.5c-3.6,4.5-7.2,9-10.8,13.5c2.7-2.9,5.5-5.8,8.2-8.7
								c-4.2,5.1-8.8,9.9-13.8,14.2c-4,3.6-8.2,7-12.6,10c2.2-1.7,4.4-3.4,6.5-5.1c-3.7,2.5-7.3,4.9-11,7.4c2.2-1.5,4.5-3,6.7-4.4
								c-3.8,2.2-7.6,4.3-11.4,6.5c2.3-1.3,4.5-2.5,6.8-3.8c-3.9,1.9-7.7,3.7-11.6,5.6c2.3-1.1,4.6-2.1,6.9-3.2
								c-3.9,1.5-7.8,3.1-11.8,4.6c2.3-0.9,4.6-1.7,6.8-2.6c-3.9,1.2-7.9,2.5-11.8,3.7c2.3-0.7,4.5-1.4,6.8-2
								c-4,0.9-7.9,1.8-11.9,2.8c2.2-0.5,4.5-1,6.7-1.5c-3,0.3-6.1,0.6-9.1,0.7c3,0.2,5.9,0.4,8.9,0.6c-4.5-0.3-9-0.6-13.6-1
								c2.8,0.4,5.6,0.8,8.5,1.2c-4.3-0.6-8.6-1.3-12.9-1.9c2.7,0.6,5.3,1.2,8,1.8c-4.1-1.1-8.1-2.2-12.2-3.3
								c2.5,0.8,4.9,1.5,7.4,2.3c-3.8-1.4-7.6-2.9-11.4-4.3c2.3,0.9,4.5,1.9,6.8,2.8c-3.4-1.8-6.7-3.5-10.1-5.3
								c3.9,2.5,7.8,4.9,11.7,7.4c-2.9-2.4-5.8-4.9-8.6-7.3c1.6,1.4,3.2,2.9,4.8,4.3c-3.1-4-6.2-8.1-9.3-12.1c1.1,1.5,2.1,3,3.2,4.6
								c-1.4-2.1-2.4-4.4-3.1-6.7c-1.3-3.6-2.5-7.3-3.5-11c0.5,1.5,0.9,3,1.4,4.5c-0.6-3-1.2-6-1.8-9c0.3,1.5,0.6,3,0.9,4.5
								c-0.3-2.9-0.5-5.8-0.8-8.7c0.1,1.5,0.2,3,0.4,4.4c0-2.9,0-5.8,0.1-8.8c0,1.5-0.1,2.9-0.1,4.4c0.3-2.8,0.7-5.6,1-8.5
								c-0.2,1.4-0.4,2.8-0.6,4.2c0.6-2.8,1.2-5.5,1.8-8.3c-0.3,1.4-0.6,2.7-1,4.1c0.9-2.6,1.7-5.2,2.6-7.9
								c-0.5,1.3-0.9,2.6-1.4,3.9c1.1-2.5,2.3-5.1,3.4-7.6c-0.6,1.3-1.2,2.5-1.8,3.8c1.4-2.4,2.8-4.7,4.2-7.1
								c-0.7,1.2-1.4,2.4-2.2,3.6c1.7-2.3,3.3-4.5,5-6.8c-0.9,1.2-1.7,2.3-2.6,3.5c1.2-1.6,2.7-2.9,4.3-4.1c2.6-2.1,5.2-4,8.1-5.8
								c-1.3,0.9-2.7,1.8-4,2.8c1.4-0.8,2.9-1.3,4.5-1.6c-1.3,0.1-2.6,0.3-3.9,0.4c2.8-0.3,5.6-0.6,8.4-0.9
								c3.7,0.2,7.4,0.4,11.1,0.5c-2.2-0.2-4.4-0.3-6.5-0.5c3,0.5,6.1,1,9.1,1.4c-2.3-0.4-4.6-0.8-6.9-1.2c6.5,1.7,13,3.5,19.4,5.2
								c-5-1.7-10-3.4-14.9-5c3.2,1.3,6.4,2.6,9.6,3.9c-2.5-1.3-4.9-2.6-7.4-3.9c3.2,1.8,6.3,3.5,9.5,5.3c-2.5-1.6-5-3.3-7.5-4.9
								c5.8,3.8,11.5,7.6,17.3,11.4c-5-3.7-10-7.4-15-11.1c3,2.6,6,5.1,9,7.7c-2.3-2.3-4.7-4.7-7-7c5.3,5.4,10.6,10.9,16,16.3
								c-4.5-5.2-9-10.4-13.6-15.6c4.7,6.3,9.5,12.5,14.2,18.8c-4.1-5.9-8.2-11.8-12.2-17.7c4.4,7.2,8.8,14.4,13.2,21.6
								c-3.4-6.5-6.9-13-10.3-19.6c3.5,7.9,7.1,15.8,10.6,23.8c-2.8-7.2-5.5-14.3-8.3-21.5c2.8,8.5,5.6,17.1,8.4,25.6
								c-1.9-7.6-3.8-15.2-5.8-22.9c1.8,9.1,3.7,18.1,5.5,27.2c-0.7-4-1.4-8.1-2-12.1c0.6,5.4,1.1,10.8,1.7,16.2
								c-0.2-4.1-0.5-8.2-0.7-12.3c0.1,5.5,0.2,11,0.3,16.5c0.6-8.3,1.3-16.6,1.9-24.9c-1.9,15.2-3.8,30.4-5.7,45.7
								c0.6-3,1.2-5.9,1.7-8.9c-1.1,4.2-2.3,8.4-3.4,12.6c0.8-2.9,1.6-5.9,2.4-8.8c-1.4,4.1-2.8,8.3-4.2,12.4c1-2.9,2-5.8,3.1-8.6
								c-1.7,4-3.4,8-5,12c1.2-2.8,2.4-5.6,3.6-8.4c-2,3.8-3.9,7.7-5.9,11.5c6.4-9.7,12.8-19.5,19.2-29.2c-9.2,12-18.3,24-27.5,36.1
								c5-5.1,10-10.2,14.9-15.3c-4,3.5-8,7-12,10.5c3.3-2.5,6.5-5.1,9.8-7.6c-4.3,3-8.7,6-13,8.9c6.9-4.1,13.8-8.1,20.7-12.2
								c-7.8,4.2-15.7,8.5-23.5,12.7c6.9-3.1,13.9-6.3,20.8-9.4c-8,3.2-15.9,6.4-23.9,9.6c6.9-2.1,13.8-4.3,20.6-6.4
								c-8.1,1.9-16.2,3.8-24.4,5.7c6.5-1.2,13.1-2.4,19.6-3.6c-7.8,0.8-15.5,1.7-23.3,2.5c6.2-0.3,12.4-0.6,18.6-0.9
								c-4.6-0.3-9.2-0.6-13.9-0.9c2.8,0.4,5.7,0.8,8.5,1.1c-4.3-0.8-8.6-1.6-12.9-2.4c2.6,0.7,5.1,1.5,7.7,2.2
								c-4-1.6-7.9-3.1-11.9-4.7c2.2,1,4.4,2,6.7,2.9c-3.6-1.9-7.2-3.7-10.8-5.6c1.9,1.2,3.8,2.4,5.8,3.6c-3-2.4-6.1-4.9-9.1-7.3
								c1.6,1.3,3.2,2.7,4.9,4c-2.7-2.6-5.4-5.2-8-7.9c1.3,1.5,2.7,2.9,4,4.4c-2.1-3.2-3.9-6.4-5.5-9.9c-1.4-3.6-2.4-7.2-3-11
								c-0.4-3.3-0.6-6.5-0.4-9.8c0.3-4.6,1.1-9.2,2.3-13.6c-0.3,1.4-0.6,2.7-0.9,4.1c0.8-2.8,1.6-5.6,2.4-8.5
								c-0.4,1.3-0.9,2.6-1.3,3.9c1.5-3.8,2.9-7.5,4.3-11.3c1.5-4.4,3.3-8.6,5.3-12.8c-0.6,1.3-1.2,2.6-1.8,3.9
								c1.7-3.2,3.4-6.4,5.1-9.6c-0.7,1.3-1.5,2.6-2.2,3.9c3.6-6,7.4-11.8,11.2-17.7c3.7-5.7,6.4-11.9,9.7-17.9
								c4.2-7.8,8.7-15.5,13.4-23c7.1-11.3,15.2-21.9,23.5-32.4c1-2.3,0.9-4.5-0.2-6.8c-0.9-2.4-2.5-3.9-4.9-4.7
								c-3.3,0.1-6.5,0.9-9.5,2.4c-3.1,1.2-5.9,3-8.2,5.4c-8,11.4-15.8,22.9-22.6,35.1c-6.8,12.3-12.7,25-18.5,37.8
								c0.6-1.1,1.1-2.2,1.7-3.2c-4,7-7.9,14.1-11.6,21.3c-2.6,5.2-4.7,10.7-6.7,16.2c0.6-1.3,1.1-2.5,1.7-3.8
								c-4.1,8.9-7.5,17.9-8.6,27.7c-0.3,2.8-0.5,5.5-0.6,8.3c0,3,0.4,6,0.8,9c0.4,3.3,1.2,6.6,2.1,9.8c0.6,2.1,1.2,4.1,1.9,6.2
								c1.1,1.9,2.2,3.8,3.3,5.7c2.7,3.9,6.3,6.7,10.4,9.2c1.4,1.2,3,2,4.8,2.4c10,3.6,20,6.9,30.2,9.8c1.6-0.3,3.3-0.6,4.9-0.9
								c4.4-2.5,8.5-5.5,12.3-8.8c3.3-2.6,6.5-5.3,9.4-8.3c3.8-3.3,7.3-7,10.4-11c1.3-3.4,2.3-6.9,3-10.5c0.2-1.9,0.5-3.7,0.7-5.6
								c0.7-6.6,0.3-13.3-1-19.8c-1.5-7.4-3.7-14.6-7.6-21.2c-1.7-2.9-3.6-5.8-5.6-8.5c-3.8-5.5-9-9.2-15.7-11.2
								c4.1,3,8.2,6.1,12.3,9.1c-3.3-4.1-7.2-7.3-11.8-9.7c-2.9-2-5.9-3.6-9.1-5c-1.5-0.6-3.1-1.1-4.6-1.7c-3.3-1.3-6.7-2-10.2-2.2
								c-3.3-0.7-6.7-0.9-10-0.5c-1.7,0.3-3.5,0.7-5.2,1c-2,0.6-4,1.3-5.8,2.4c-2.7,1.2-5.3,2.8-7.7,4.6
								c-7.9,6.2-13.5,14.2-17.2,23.4c-2.2,5.3-3.5,11.4-3.9,17c-0.4,5.9,0.2,11.5,1.1,17.3c-0.2-1.2-0.3-2.4-0.5-3.6
								c1,7.2,2,14.1,6.9,19.9c7.2,8.4,16.5,15.6,27,19.2c4.1,1.3,8.3,2.1,12.6,2.6c3.4,0.7,6.9,0.9,10.4,0.5
								c8.2-0.7,16.3-3.5,23.6-7.1c8.2-4,16-9.2,22.6-15.6c5.6-5.5,10.8-11.5,14.6-18.4c2.4-4.3,4.3-8.8,5.9-13.5
								c0.8-2.4,1.5-4.8,2.3-7.2c0.6-4.9,1.3-9.7,1.9-14.6c0.1-7.2-0.6-14.3-2.2-21.2c-2.2,13.2-4.4,26.3-6.6,39.5
								c8.9-12.5,13.7-26,14.3-40.5c-0.1-3.7-0.5-7.3-1.4-10.8c-1.1-4.1-3.4-9.1-6.3-12.3c-2-2.5-4.8-3.9-8.2-4.2
								c-1.5-0.3-3-0.3-4.4,0.1c-2.5,0.2-4.9,0.9-7.1,2.2c-3,1.2-5.6,3.1-7.7,5.5c-10.6,10.8-18.5,24.8-21.3,39.7
								c-0.6,3.4-1.1,6.8-1.4,10.2c-0.4,4.7-0.2,9.3,0.5,13.9c1.9,4.9,3,9.9,3.4,15.2c1.2,1.9,3,3.2,5.2,3.9c2.1,1,4.2,1.2,6.5,0.7
								c1.9-1.4,3.3-3.3,4-5.5C834.3,1302.5,834.6,1300.3,834.2,1297.9z"/>
							<path opacity="0.1091" fill="#131313" d="M861.5,1263.1c-0.7-2.1-1.4-4.2-2.2-6.4c0.4,1.4,0.7,2.9,1.1,4.3
								c-1-4.2-1.6-8.5-1.7-12.8c-0.1-3.2,0.1-6.3,0.8-9.3c0.4-3.1,1.1-6.1,2.2-9c1.3-3.9,3.1-7.6,5.2-11.2
								c-1.5,2.4-3.1,4.7-4.6,7.1c2.6-4,5.3-8.1,7.9-12.1c-1.7,2.3-3.5,4.6-5.2,6.9c2.9-3.8,5.9-7.6,8.8-11.4
								c-1.9,2.2-3.8,4.3-5.7,6.5c3.3-3.6,6.5-7.2,9.8-10.7c-2.1,2-4.1,4-6.2,6.1c3.6-3.3,7.2-6.6,10.7-9.9
								c-5.7,4.1-11.3,8.3-17,12.4c3.9-2.3,7.8-4.6,11.6-6.9c-7.3,3.7-14.6,7.5-21.9,11.2c11.4-4.3,22.8-8.7,34.2-13
								c-11,2.7-21.9,5.4-32.9,8.1c8.5-1.2,17-2.3,25.5-3.5c-10.8,0.5-21.7,0.9-32.5,1.4c13.5,2.7,26.9,5.3,40.4,8
								c-9.1-3.1-18.3-6.2-27.4-9.3c6.6,3.5,13.2,7,19.7,10.5c-5.9-3.5-11.8-7-17.7-10.5c5.7,4.3,11.4,8.6,17.1,12.9
								c-5.1-4.3-10.3-8.7-15.4-13c4.9,4.9,9.9,9.9,14.8,14.8c-4.1-5-8.2-10.1-12.2-15.1c4,6.2,8,12.4,11.9,18.7
								c-3-5.2-5.9-10.3-8.9-15.5c2.8,6.3,5.6,12.6,8.5,18.9c-1.9-5.3-3.8-10.6-5.8-15.9c0.8,4.1,1.5,8.2,2.3,12.3
								c-0.4-2.6-0.8-5.2-1.1-7.8c0.3,4,0.6,7.9,1,11.9c0-2.5,0-5.1,0-7.6c-0.2,6.1-0.3,12.1-0.5,18.2c0.5-4.8,1-9.7,1.4-14.5
								c-1,4.7-2,9.4-3.1,14.1c0.6-2.2,1.1-4.4,1.7-6.6c-1.4,4.5-2.7,9-4.1,13.4c0.8-2,1.5-4.1,2.3-6.1c-1.9,5.8-4.2,11.3-7,16.7
								c-2.9,4.9-6.2,9.5-9.8,14c1.2-1.6,2.4-3.2,3.6-4.8c-2.5,2.8-5,5.6-7.5,8.4c1.3-1.4,2.6-2.9,4-4.3c-2.7,2.6-5.5,5.2-8.2,7.8
								c1.4-1.3,2.9-2.6,4.3-4c-2.8,2.3-5.5,4.7-8.3,7c1.6-1.2,3.2-2.4,4.7-3.6c-3,2.1-6.1,4.3-9.1,6.4c1.7-1,3.3-2.1,5-3.1
								c-3.2,1.9-6.5,3.8-9.7,5.7c1.7-0.9,3.5-1.8,5.2-2.7c-3.3,1.6-6.7,3.3-10,4.9c1.8-0.8,3.6-1.5,5.3-2.3
								c-3.4,1.4-6.8,2.8-10.2,4.2c1.8-0.6,3.6-1.2,5.5-1.8c-3.5,1.1-6.9,2.3-10.4,3.4c1.9-0.5,3.7-0.9,5.6-1.4
								c-3.5,0.9-7,1.7-10.5,2.6c1.9-0.3,3.8-0.7,5.7-1c-4.9,0.6-9.9,1.2-14.8,1.8c1.9,0,3.9-0.1,5.8-0.1c-4.7-0.2-9.4-0.4-14.1-0.6
								c2,0.3,3.9,0.5,5.9,0.8c-3.2-0.6-6.4-1.1-9.7-1.7c1.9,0.4,3.9,0.8,5.8,1.2c-3.1-0.9-6.1-1.8-9.2-2.6c1.9,0.6,3.8,1.2,5.7,1.7
								c-4-1.7-7.8-3.7-11.5-6c3.5,2.2,7,4.5,10.5,6.7c-2.2-1.9-4.4-3.9-6.6-5.8c1.5,1.4,3.1,2.9,4.6,4.3c-2.4-3-4.7-6-7.1-9.1
								c1.3,1.8,2.5,3.6,3.8,5.5c-1.6-2.7-3.1-5.4-4.7-8.1c1.1,2,2.2,4,3.3,6c-1.5-2.7-2.9-5.3-4.4-8c0.9,2.2,1.8,4.4,2.7,6.6
								c-1.3-3.4-2.6-6.7-3.9-10.1c0.7,2.3,1.5,4.7,2.2,7c-2.1-7.2-3.9-14.5-5.3-21.8c0.8,5.2,1.7,10.5,2.5,15.7
								c-1.1-7.7-1.6-15.5-1.6-23.4c-0.2,5.7-0.5,11.5-0.7,17.2c1-8.2,1.9-16.4,2.9-24.6c-0.6,3-1.2,6.1-1.8,9.1
								c1.2-4.2,2.4-8.4,3.5-12.7c-1,3.1-1.9,6.2-2.9,9.2c1.6-4.2,3.1-8.4,4.7-12.6c-1.3,3.1-2.6,6.1-3.9,9.2
								c2.1-4.1,4.3-8.2,6.4-12.4c-1.7,3-3.4,6.1-5.1,9.1c2.6-4,5.1-8,7.7-12c-4.4,5.7-8.7,11.4-13.1,17.1
								c5.4-6.5,10.9-13.1,16.3-19.6c-2.5,2.8-5,5.6-7.4,8.5c3.5-3.5,7.1-6.9,10.6-10.4c-5.8,4.9-11.5,9.7-17.3,14.6
								c6.8-5.2,13.7-10.3,20.5-15.5c-6.4,4.2-12.8,8.5-19.2,12.7c7.5-4.5,14.9-8.9,22.4-13.4c-6.8,3.5-13.7,6.9-20.5,10.4
								c8-3.6,16-7.1,23.9-10.7c-7.3,2.7-14.6,5.4-21.9,8.1c8.5-2.7,16.9-5.4,25.4-8.1c-7.6,1.8-15.1,3.7-22.7,5.5
								c8.7-1.7,17.5-3.5,26.2-5.2c-7.8,1-15.6,2-23.4,3c8.9-0.8,17.9-1.6,26.8-2.4c-3.9,0.2-7.8,0.4-11.6,0.6
								c5,0.1,10,0.1,15.1,0.2c-3.9-0.2-7.8-0.4-11.6-0.6c5,0.6,10,1.2,15,1.8c-3.8-0.6-7.6-1.2-11.3-1.8c4.9,1.1,9.8,2.2,14.7,3.3
								c-3.7-1-7.4-2-11-3c4.7,1.6,9.4,3.2,14.2,4.9c-3.5-1.4-7-2.7-10.5-4.1c4.5,2.1,9,4.1,13.4,6.2c-6.5-3.6-13-7.3-19.4-10.9
								c7.3,4.5,14.6,8.9,21.9,13.4c-3-2-6-3.9-9-5.9c3.8,2.9,7.5,5.8,11.3,8.7c-5.2-4.7-10.5-9.4-15.7-14
								c5.8,5.8,11.7,11.6,17.5,17.3c-4.5-5-8.9-10.1-13.4-15.1c4.9,6.1,9.9,12.3,14.8,18.4c-3.7-5.3-7.4-10.6-11-15.9
								c4.1,6.8,8.2,13.6,12.3,20.3c-2.8-5.4-5.7-10.8-8.5-16.1c3.2,6.9,6.3,13.8,9.5,20.7c-2.1-5.4-4.1-10.9-6.2-16.3
								c1.3,4.4,2.6,8.8,3.9,13.2c-0.5-2.7-1-5.4-1.6-8.1c1.3,7,2.6,14,3.9,21c-0.6-5.3-1.3-10.6-1.9-15.9c0.8,8.7,1.1,17.3,0.7,26
								c0.1-2.4,0.2-4.9,0.3-7.3c-0.7,5.3-1.4,10.6-2.1,15.9c0.5-2.2,1-4.5,1.4-6.7c-1.4,5-2.8,9.9-4.2,14.9c0.8-2,1.5-4.1,2.3-6.1
								c-1.3,3.4-2.6,6.7-4,10.1c0.9-2,1.8-3.9,2.7-5.9c-1.5,3.2-3,6.3-4.5,9.5c1-1.9,2-3.7,3-5.6c-1.7,3-3.4,6-5.1,9.1
								c1.1-1.8,2.3-3.5,3.4-5.3c-2.8,3.7-5.6,7.4-8.4,11c1.4-1.5,2.7-3.1,4.1-4.6c-1.6,1.8-3.5,3.3-5.5,4.6c1.7-1,3.5-2.1,5.2-3.1
								c-3.9,1.8-7.9,3.6-11.8,5.4c1.8-0.6,3.7-1.2,5.5-1.9c-3,0.8-5.9,1.5-8.9,2.3c1.9-0.4,3.7-0.7,5.6-1.1c-1.2,0-2.5-0.1-3.7-0.1
								c1.3,0.2,2.7,0.4,4,0.6c-2.6-0.5-5.1-0.9-7.7-1.4c1.3,0.4,2.6,0.8,3.9,1.2c-2.3-0.8-4.6-1.6-7-2.3c1.2,0.6,2.5,1.1,3.7,1.7
								c-2.2-1.1-4.4-2.2-6.6-3.3c1.2,0.7,2.4,1.5,3.5,2.2c-2-1.3-4.1-2.6-6.1-3.9c1.1,0.9,2.2,1.8,3.2,2.6
								c-2.7-2.1-5.3-4.4-7.7-6.8c-2.5-3.1-5-6.4-7.3-9.6c1.2,1.7,2.5,3.4,3.7,5.2c-1.6-2.7-3.1-5.5-4.7-8.2
								c1.1,1.9,2.2,3.9,3.2,5.8c-1.5-3.2-3-6.3-4.6-9.5c0.9,2.1,1.7,4.2,2.6,6.3c-1.1-3.4-2.3-6.9-3.4-10.3c0.7,2.3,1.3,4.6,2,6.9
								c-0.9-3.5-1.8-7-2.7-10.5c0.4,2.5,0.8,4.9,1.2,7.4c-0.5-3.7-0.9-7.5-1.4-11.2c0.1,2.7,0.3,5.3,0.4,8
								c-0.3-6.7-0.6-13.4-1-20.1c0.1,2.8,0.2,5.5,0.3,8.3c0.3-4.2,0.5-8.4,0.8-12.6c-0.2,2.9-0.4,5.8-0.6,8.7
								c0.6-4.3,1.2-8.6,1.7-12.9c-0.5,3-1.1,5.9-1.6,8.9c1.3-6.4,2.9-12.7,4.8-18.9c2.2-6.4,4.9-12.5,7.9-18.6
								c-1.1,2.3-2.1,4.6-3.2,6.9c2.2-4.2,4.5-8.4,6.7-12.6c-1.3,2.3-2.6,4.5-3.9,6.8c3.6-6.1,7.3-12.1,11.2-18.1
								c3.9-8.3,8.1-16.4,12.6-24.4c4.5-8,9.3-15.7,14.5-23.3c5.2-7.6,10.6-14.9,16.4-22c0.9-1.4,1.9-2.7,2.8-4.1
								c-0.1-1.3-0.1-2.6-0.2-3.9c-0.9-0.9-1.9-1.9-2.8-2.8c-3.1-1.1-6.2-0.9-9.1,0.6c-7.4,2.1-14.5,5.3-21.4,8.7
								c-3.3,1.4-6.4,3.1-9.3,5.1c-3.1,1.2-5.2,3.4-6.5,6.4c-5.2,8.5-10.1,17.3-14.4,26.2c-3.7,7.5-7.1,15.2-10.1,23
								c-3.9,10-7.9,20.1-10.5,30.5c-1.6,6.5-2.8,13.1-3.5,19.8c-0.5,4.8-0.6,9.6-0.5,14.4c0.2,5.9,1,11.6,2.7,17.2
								c-0.2-2.1-0.3-4.1-0.5-6.2c0.2,2.7,0.3,5.4,0.4,8.1c0.7,3.9,1.5,7.8,2.3,11.7c1,3.6,2.3,7.1,4,10.4c2.1,3.2,4.2,6.5,6.3,9.7
								c4.7,6.3,11.2,11.3,18.6,14c3.4,1.5,6.9,2.1,10.5,1.8c2.4,0,4.8-0.3,7.1-0.9c2.4-0.4,4.7-1.2,7-2.2c3.3-1.7,6.4-3.8,9.3-6.1
								c2.7-2.1,4.9-4.6,6.8-7.4c2-2.2,3.7-4.6,4.9-7.2c3-5.6,5-11.7,6.4-17.9c1.3-5.8,2.1-11.6,2.4-17.6
								c-0.3-22.2-11.9-40.4-34.8-54.8c-2.5-1.9-5.4-2.7-8.5-2.2c-5.3-0.3-10.7,0-16,1c-3.9,0.6-7.8,1.3-11.7,1.9
								c-4.8,0.2-8.7,1.9-11.7,5.3c-6,10.4-11,21.2-15.3,32.4c0.4,6.3,1.5,12.4,3.3,18.5c-0.3-2.6-0.6-5.3-0.9-7.9
								c0.3,6.9,1.4,13.7,3.1,20.5c1.1,4.7,2.9,9.1,5.6,13.1c2.2,2.9,4.8,5.3,8,7.1c3,2.1,6.2,3.6,9.8,4.3c4.8,1.6,9.8,2.6,14.8,3
								c3.5,0,7-0.2,10.5-0.7c7-0.9,13.7-3.2,20.1-6.2c7.1-3.2,13.8-7.1,19.9-12c5.5-4.4,10.1-9.7,14.7-15.1
								c4.8-5.5,8.3-12.1,11.4-18.7c6-12.5,10-25.7,12.1-39.6c0.4-3.2,0.4-6.5,0-9.7c-0.1,1.3-0.1,2.6-0.2,3.9
								c0.5-2.3,0.5-4.6,0.1-6.9c-0.3-3.6-1.4-7.1-3.2-10.3c-1.1-3.2-3.3-4.8-6.8-5c-5.1,0-10.1,0.6-15,1.6
								c-6.2,0.9-12.3,2.3-18.3,4c-5,0.6-8.9,2.7-12,6.4c-2.6,9-6.5,17.5-9,26.5c-1.6,6-2.7,12-3.1,18.2
								c-1.1,17.6,2.7,34.7,9.3,50.9c1.7,2.4,4,3.3,6.8,2.7c3.8-1.6,6.4-4.6,7.6-8.7c3.1-6.3,5.7-12.8,7.8-19.5
								C863,1270.8,863.2,1267,861.5,1263.1z"/>
							<path opacity="0.1091" fill="#131313" d="M820.6,1250.6c-0.7-2-1.5-4.1-2.2-6.1c0.4,1.4,0.8,2.7,1.2,4.1
								c-1.2-6.3-2.3-12.5-1.5-18.9c0.3-2,0.7-4.1,1-6.1c0.9-4,2.2-7.9,3.9-11.7c-0.9,1.9-1.9,3.7-2.8,5.6c1.9-3.7,3.8-7.4,5.6-11.1
								c-1,1.8-2,3.5-3.1,5.3c2.1-3.5,4.2-6.9,6.4-10.4c-1.1,1.6-2.2,3.3-3.3,4.9c2.4-3.2,4.8-6.5,7.1-9.7c-1.1,1.5-2.3,3-3.4,4.5
								c2.6-3,5.2-5.9,7.7-8.9c-1.2,1.4-2.4,2.7-3.6,4.1c1.5-1.5,3.1-2.9,4.6-4.4c-4.1,2.5-8.2,5-12.4,7.5c2.7-1.4,5.5-2.7,8.2-4.1
								c-2.1,0.9-4.2,1.8-6.3,2.7c2.7-1.1,5.4-2.3,8.2-3.4c-2.1,0.7-4.2,1.4-6.3,2.1c2.7-0.8,5.4-1.7,8.2-2.5
								c-2.1,0.5-4.2,0.9-6.3,1.4c2.8-0.5,5.6-0.9,8.4-1.4c-3.6,0-7.1,0-10.7,0.1c2.4,0.6,4.7,1.2,7.1,1.8c-1.4-0.3-2.7-0.7-4.1-1.1
								c3,1.2,5.8,2.8,8.3,4.9c-1.1-0.7-2.1-1.4-3.2-2.1c1.2,2.3,2.1,4.5,2.5,7.2c0.2,0.9,0.3,1.8,0.2,2.7c-0.1,1,0,1.9,0,2.9
								c-0.2,1.1-0.5,2.4-0.7,3.5c-0.5,1.3-0.9,2.6-1.2,3.9c-1,3-1.6,6.2-1.9,9.4c0.4-1.9,0.8-3.9,1.1-5.8c-0.7,2.5-1.6,4.9-2.7,7.2
								c-1.3,2.7-2.4,5.5-3.3,8.4c-0.5,1.3-0.9,2.6-1.4,3.9c-2.3,4.8-4.9,9.4-7.7,14c1.4-2.2,2.7-4.3,4.1-6.5
								c-2.4,3.3-4.8,6.7-7.2,10c1.7-2.2,3.3-4.3,5-6.5c-2.7,3.3-5.5,6.6-8.2,9.9c2-2.1,4-4.2,6-6.3c-3.2,3.2-6.4,6.3-9.6,9.5
								c2.3-2,4.6-4.1,7-6.1c-3.9,3.8-8,7.3-12.3,10.5c-3.9,3-7.9,5.8-12.2,8.3c2.1-1.4,4.2-2.8,6.3-4.2c-3.7,2.1-7.4,4.2-11.1,6.3
								c2.3-1.2,4.5-2.5,6.8-3.7c-3.9,1.9-7.8,3.7-11.7,5.6c2.4-1.1,4.9-2.2,7.3-3.3c-4.1,1.6-8.2,3.2-12.3,4.8
								c2.6-0.9,5.2-1.8,7.8-2.8c-6,1.5-12,3-18,4.6c2.8-0.5,5.7-1.1,8.5-1.6c-4.3,0.5-8.6,1.1-13,1.6c2.9-0.3,5.9-0.6,8.8-0.9
								c-4.4,0.2-8.9,0.4-13.3,0.6c11.5,0.8,23,1.6,34.5,2.5c-17.4-3.9-34.7-7.7-52.1-11.6c10.5,5.8,21,11.5,31.5,17.3
								c-8.2-5.4-16.5-10.7-24.7-16.1c7,5.3,14,10.5,21,15.8c-7.5-6.1-14.9-12.3-22.4-18.4c6.3,5.9,12.6,11.9,19,17.8
								c-6.7-6.8-13.4-13.7-20.2-20.5c5.5,6.4,10.9,12.8,16.4,19.2c-5.9-7.3-11.7-14.7-17.6-22c2.4,3.4,4.9,6.7,7.3,10.1
								c-2.8-4.3-5.6-8.6-8.4-12.9c2,3.5,4.1,7.1,6.1,10.6c-2.5-4.9-5-9.8-7.5-14.6c1.6,3.6,3.3,7.2,4.9,10.8c-2-5-4-10-5.9-15
								c1.2,3.7,2.4,7.3,3.7,11c-1.4-5.1-2.7-10.2-4.1-15.3c0.8,3.7,1.6,7.3,2.4,11c-0.9-5.1-1.7-10.2-2.6-15.3
								c0.4,3.6,0.8,7.3,1.3,10.9c-0.3-5.1-0.6-10.1-0.9-15.2c0,3.5,0.1,7,0.1,10.5c0.2-4.9,0.3-9.8,0.5-14.8
								c-0.9,6.7-1.8,13.4-2.7,20.1c1.8-9.5,3.6-19,5.4-28.6c-0.9,3-1.8,6-2.7,8.9c1.6-4.3,3.3-8.6,4.9-12.9
								c-1.1,2.7-2.3,5.5-3.4,8.2c2-4,4-8,6-12.1c-1.4,2.5-2.8,5-4.1,7.6c2.4-3.6,4.9-7.2,7.3-10.8c-1.6,2.3-3.1,4.5-4.7,6.8
								c2.7-3.3,5.5-6.6,8.2-9.8c-3.7,3.8-7.3,7.6-11,11.4c3.3-2.9,6.5-5.8,9.8-8.8c-2,1.5-4,2.9-6,4.4c3.2-2.3,6.4-4.5,9.7-6.8
								c-2,1.1-4.1,2.3-6.1,3.4c3.4-1.6,6.8-3.2,10.2-4.9c-2,0.8-4.1,1.7-6.1,2.5c3.4-1.2,6.9-2.5,10.3-3.7c-2,0.5-4.1,1.1-6.1,1.6
								c3.5-0.6,7-1.2,10.5-1.8c-2,0.3-3.9,0.6-5.9,0.9c3.5-0.3,6.9-0.5,10.4-0.8c-1.9,0.1-3.9,0.1-5.8,0.2c5,0.3,9.9,1,14.8,2.1
								c-1.7-0.3-3.5-0.7-5.2-1c4.4,1.2,8.6,2.8,12.7,4.7c-1.6-0.7-3.1-1.3-4.7-2c2.6,1.4,5.1,2.8,7.7,4.2c-1.5-0.8-3-1.6-4.4-2.4
								c2.4,1.7,4.8,3.3,7.2,5c-1.4-1-2.8-1.9-4.2-2.9c2.9,2.7,5.5,5.5,7.3,9.1c2.6,5.5,3,11.3,3.9,17.3c-0.3-1.8-0.5-3.6-0.8-5.4
								c0.1,3.1,0.2,6.2,0.3,9.3c-0.1-1.9-0.1-3.8-0.2-5.8c0,3.2-0.1,6.3-0.1,9.5c0.1-2,0.3-3.9,0.4-5.9c-0.7,6.5-1.3,13.1-1.9,19.6
								c0.3-2.1,0.6-4.3,0.9-6.4c-0.6,3.2-1.3,6.4-1.9,9.7c0.5-2.2,1-4.3,1.4-6.5c-0.9,3.3-1.8,6.6-2.7,9.9c0.7-2.2,1.3-4.4,2-6.7
								c-1,3.3-2.1,6.6-3.1,10c0.9-2.2,1.7-4.5,2.6-6.7c-1.7,4.5-3.5,8.9-5.4,13.2c1.3-2.3,2.6-4.6,3.8-6.9
								c-1.9,3.3-3.7,6.6-5.6,9.9c1.5-2.3,3-4.6,4.5-6.9c-2.8,4-5.7,8-8.5,12c2-2.2,4-4.4,6.1-6.7c-2.8,2.9-5.5,5.8-8.3,8.7
								c2.3-2.1,4.6-4.3,6.9-6.4c-3.2,2.6-6.5,5.3-9.7,7.9c2.6-2,5.2-4,7.9-6.1c-3.6,2.4-7.2,4.8-10.8,7.2c6-3.4,12-6.8,18-10.2
								c-7,3.5-13.9,6.9-20.9,10.4c6.5-2.9,13.1-5.7,19.6-8.6c-7.4,2.8-14.9,5.7-22.3,8.5c7-2.1,14.1-4.3,21.1-6.4
								c-8.1,1.9-16.2,3.8-24.3,5.8c7.4-1.3,14.7-2.6,22.1-3.9c-8.5,0.8-16.9,1.6-25.4,2.4c7.7-0.4,15.5-0.8,23.2-1.2
								c-8.7-0.2-17.4-0.4-26.1-0.6c7.8,0.6,15.7,1.2,23.5,1.9c-8.8-1.4-17.5-2.8-26.3-4.2c7.9,1.7,15.9,3.3,23.8,5
								c-8.7-2.5-17.5-4.9-26.2-7.4c7.6,2.7,15.3,5.4,22.9,8.1c-8.4-3.6-16.8-7.2-25.1-10.8c7.3,3.7,14.7,7.4,22,11.1
								c-8.1-4.7-16.1-9.3-24.2-14c6.8,4.6,13.6,9.2,20.3,13.7c-7.4-5.6-14.8-11.2-22.3-16.8c6.2,5.4,12.4,10.9,18.7,16.3
								c-6.7-6.5-13.4-13-20.1-19.4c5.4,6,10.7,12,16.1,18c-5.8-7.1-11.5-14.2-17.3-21.3c4.5,6.5,9.1,13.1,13.6,19.6
								c-5.1-8-10.1-16-15.2-24c3.6,6.8,7.2,13.6,10.8,20.4c-4-8.3-7.9-16.6-11.9-25c2.7,7.1,5.3,14.2,8,21.2
								c-2.8-8.6-5.6-17.3-8.4-25.9c1,3.5,2,7,3,10.5c-1.1-5.1-2.1-10.2-3.2-15.4c0.6,3.5,1.1,7.1,1.7,10.6
								c-0.4-5.2-0.9-10.3-1.3-15.5c0.1,3.4,0.3,6.9,0.4,10.3c0-5,0.1-10.1,0.1-15.1c-0.2,3.3-0.5,6.6-0.7,9.9
								c0.6-4.9,1.2-9.9,1.8-14.8c-0.5,3.1-1.1,6.3-1.6,9.4c1-4.8,2-9.6,3-14.4c-0.8,3-1.7,5.9-2.5,8.9c1.5-5.2,2.9-10.4,4.4-15.6
								c1.1-3.3,2.4-6.4,3.9-9.5c3.3-7.1,7.2-13.6,11.1-20.4c-1,1.7-2,3.3-3,5c5.1-7.3,9.4-15,13.2-23.1c3.2-6.1,6.6-12.1,10.2-18
								c3.5-6,7.2-11.8,11.2-17.4c5.2-7.6,10.8-14.9,16.7-21.9c1.3-2.3,1.4-4.6,0.2-7c-1-2.4-2.8-3.9-5.3-4.5
								c-3.9,0.3-7.6,1.4-11.2,3.2c-3.7,1.4-7,3.5-9.8,6.2c-6.3,8.1-12.2,16.5-17.7,25.1c-2.7,4.4-5.3,8.8-8,13.3
								c-8.7,14.9-15.6,30.7-22.7,46.4c-3.7,8.3-6.8,16.7-8.9,25.5c-1.2,4.5-2,9.1-2.5,13.7c-1.4,11.8,0.7,23.3,6.1,34.4
								c0.3,3.3,1.8,6,4.3,8c3.5,4.4,7.5,8.3,11.9,11.8c3.2,2.6,6.4,5.2,9.6,7.8c3.4,3.6,7.5,5.5,12.2,5.6c0.4,0,0.8,0,1.2,0
								c2.5-0.6,4.9-1.3,7.4-1.9c5.1-1.7,10-3.8,14.8-6.3c2.9-1.1,5.5-2.6,8-4.4c2.7-1.1,4.5-3.1,5.5-5.9c3.5-8,5.3-16.2,5.4-24.7
								c0.2-2.7,0.3-5.4,0.1-8.1c-0.2,1.7-0.3,3.3-0.5,5c0.5-4.6,0.6-8.7,0.3-13.3c-0.4,4.1-0.8,8.2-1.2,12.2
								c1.5-8.3,3.6-16.7,3.1-25.3c-0.2-3.7-0.8-7.6-1.8-11.1c-1.2-4.3-3.3-8.3-5.8-12c-2.8-4.3-6.5-7.8-10.6-10.9
								c-2.3-1.7-4.8-3.1-7.3-4.5c-3.3-1.8-6.6-2.8-10.2-4c1.3,0.4,2.6,0.8,3.8,1.3c-3.1-1.4-6.3-2.3-9.6-2.8
								c-3.1-0.7-6.1-0.7-9.1,0c-3,0.3-5.9,1-8.7,2.1c-10.2,5.4-19.7,12-28.5,19.7c-3,4.5-5.2,9.3-6.8,14.4c-2,5-3.2,10.2-3.7,15.5
								c3.3,10.2,6.6,20.5,9.9,30.7c1.1,3.3,3.2,5.8,6.2,7.4c4.6,3.9,9.6,7.2,14.9,10.1c3.9,2.1,7.8,4.3,11.6,6.4
								c4.2,3.1,8.8,4.3,13.8,3.8c3.8-0.7,7.6-1.4,11.3-2.6c3.6-1.1,7.1-2.6,10.4-4.4c7.5-4,14.2-9.5,20.1-15.6
								c4.4-4.6,8.5-9.5,11.9-14.8c2-3,3.7-6.2,5-9.5c1-2.3,1.9-4.7,2.7-7.1c1.9-4.7,3.3-9.5,4.3-14.6c-1.5,3.8-3,7.7-4.5,11.5
								c2-3.4,3.5-7.1,4.7-10.9c-1.6,3.3-3.2,6.6-4.7,10c1.9-2.6,3.6-5.4,5.1-8.2c0.8-1.4,1.5-2.9,2.2-4.4c2.2-4,4.8-7.7,6.7-11.9
								c2-4.1,3.6-8.6,4.2-13.2c0.2-2,0.3-4,0.5-6.1c-0.1-4.1-0.7-8.2-1.7-12.3c0.2,1,0.3,2,0.5,3c-0.1-3-0.8-5.8-1.9-8.5
								c-1.2-1.8-2.4-3.6-3.7-5.3c-0.8-1.1-1.7-2.2-2.6-3.2c-5.2-1.3-10.4-2.7-15.6-4c-4.1,0.9-7.9,2.6-11.4,4.9
								c-3.7,2-6.9,4.6-9.7,7.8c-3.8,4.4-7.2,9-10.2,14.1c-1.4,2.6-2.7,5.2-4.1,7.7c-4.3,8.8-6.6,18.6-7.7,28.3
								c-0.9,8.2-0.7,16.3,0.7,24.5c0.5,3.6,1.3,7.3,2.3,10.8c0.9,3.4,2,6.7,3.4,9.9c1.7,2.3,4,3.2,6.8,2.7c3.7-1.4,6.1-4.1,7.3-8.1
								c2.9-5.9,5.3-12,7.2-18.3C822.2,1257.8,822.3,1254.2,820.6,1250.6z"/>
							<path opacity="0.1091" fill="#131313" d="M838.9,1248.4c-0.7-2.1-1.5-4.1-2.2-6.2c0.3,1.5,0.6,3.1,1,4.6
								c-1-4.2-1.6-8.4-1.7-12.7c-0.1-3.1,0.1-6.2,0.7-9.2c0.4-3,1.1-6,2.1-8.9c1.3-3.9,3-7.5,5-11.1c-1.4,2.2-2.8,4.4-4.2,6.6
								c2.5-3.9,4.9-7.7,7.4-11.6c-1.5,2.1-3,4.1-4.5,6.2c2.7-3.6,5.4-7.2,8.1-10.8c-1.6,1.9-3.2,3.8-4.8,5.7
								c2.9-3.3,5.9-6.7,8.8-10c-4.3,3.8-8.7,7.7-13,11.5c6.4-5,12.8-10,19.1-14.9c-7.5,4.3-15,8.5-22.4,12.8
								c6.7-2.8,13.4-5.7,20.1-8.5c-2.9,1-5.7,2.1-8.6,3.1c3.2-0.9,6.5-1.7,9.7-2.6c-5.8,1-11.7,2.1-17.5,3.1
								c3.7-0.2,7.3-0.3,11-0.5c-4.7-0.3-9.4-0.6-14.1-1c8.2,1.7,16.4,3.3,24.6,5c-6.3-2.2-12.6-4.3-18.8-6.5c2.3,1.5,4.7,3,7,4.6
								c-1.3-1-2.5-2.1-3.8-3.1c1.9,1.7,3.8,3.5,5.7,5.2c-1-1.2-2-2.4-3-3.5c1.5,1.9,3.1,3.7,4.6,5.6c-0.8-1.3-1.5-2.6-2.3-3.9
								c1.2,2.2,2.4,4.3,3.7,6.5c-0.5-1.4-1-2.8-1.5-4.2c0.8,3.8,1.5,7.6,2.3,11.5c0-1.4,0-2.8,0-4.1c-0.2,2.5-0.4,5-0.6,7.5
								c0.2-1.3,0.4-2.7,0.6-4c-0.9,4.5-1.8,8.8-3.1,13.2c-1.9,6.1-4.6,12-7,17.9c0.9-1.7,1.8-3.4,2.6-5.1
								c-3.1,6.4-6.6,12.5-10.5,18.4c1.1-1.7,2.1-3.5,3.2-5.2c-2.2,3-4.4,5.9-6.6,8.9c1.3-1.7,2.6-3.4,3.9-5.1
								c-2.4,2.8-4.9,5.6-7.3,8.4c1.5-1.6,3-3.2,4.5-4.9c-2.8,2.7-5.6,5.4-8.3,8c1.7-1.5,3.5-3.1,5.2-4.6c-2.9,2.5-5.9,5-8.8,7.6
								c2-1.4,3.9-2.9,5.9-4.3c-6,4.4-11.6,8.7-18.3,12c-6.3,3.1-12.7,5.1-19.4,7.2c2-0.6,3.9-1.2,5.9-1.9
								c-3.6,0.9-7.2,1.7-10.8,2.6c5-0.7,10-1.4,15-2.1c-9.3,0.4-18.5,0.8-27.8,1.2c5.4,0.5,10.8,1,16.2,1.5c-5.6-1-11.1-2-16.7-3
								c3.1,1,6.3,2,9.4,3c-4.2-1.6-8.5-3.2-12.7-4.7c3.1,1.3,6.2,2.6,9.3,4c-4-2-8-4-12.1-6c5.8,3.6,11.7,7.2,17.5,10.8
								c-6.4-4.4-12.9-8.8-19.3-13.3c5.5,4.3,11,8.5,16.4,12.8c-5.9-5.2-11.9-10.4-17.8-15.5c5.1,4.9,10.1,9.9,15.2,14.8
								c-5.4-5.8-10.9-11.7-16.3-17.5c4.5,5.4,9,10.9,13.5,16.3c-4.8-6.4-9.6-12.9-14.5-19.3c2.1,2.9,4.2,5.8,6.3,8.7
								c-2.4-3.8-4.8-7.6-7.2-11.5c1.8,3.2,3.6,6.3,5.4,9.5c-2.2-4.5-4.4-9-6.6-13.4c1.5,3.3,2.9,6.6,4.4,9.9
								c-1.7-4.7-3.5-9.5-5.2-14.2c1.1,3.5,2.3,6.9,3.4,10.4c-1.3-4.8-2.6-9.7-3.8-14.5c0.8,3.6,1.6,7.1,2.3,10.7
								c-0.8-5-1.5-10-2.3-15c0.4,3.7,0.8,7.3,1.2,11c-0.3-5-0.6-10-0.9-15.1c0,3.6,0.1,7.3,0.1,10.9c0.2-5,0.4-10,0.6-15
								c-0.3,3.6-0.7,7.2-1,10.9c0.7-4.9,1.4-9.9,2.1-14.8c-0.7,3.5-1.4,7-2.1,10.5c1.2-4.8,2.4-9.7,3.6-14.5
								c-1,3.4-2.1,6.8-3.1,10.2c1.7-4.6,3.4-9.3,5.1-13.9c-1.4,3.2-2.7,6.5-4.1,9.7c2.2-4.4,4.3-8.8,6.5-13.3
								c-1.7,3.1-3.4,6.1-5.1,9.2c2.6-4.1,5.3-8.2,7.9-12.3c-2,2.8-3.9,5.6-5.9,8.5c3.1-3.8,6.1-7.5,9.2-11.3
								c-4.7,5-9.4,9.9-14.1,14.9c5.9-5.7,11.7-11.3,17.6-17c-5,4.2-10,8.4-15,12.6c6.1-4.6,12.2-9.2,18.3-13.8
								c-5.3,3.5-10.5,6.9-15.8,10.4c6.5-3.7,13-7.4,19.4-11.1c-5.4,2.7-10.7,5.4-16.1,8c6.6-2.9,13.3-5.7,19.9-8.6
								c-5.5,1.9-11,3.9-16.5,5.8c4.1-1.2,8.2-2.3,12.3-3.5c-2.8,0.5-5.5,1-8.3,1.5c6.8-1.2,13.6-2.3,20.3-3.5
								c-5.4,0.6-10.8,1.2-16.2,1.8c4.1-0.2,8.3-0.3,12.4-0.5c-2.6-0.1-5.2-0.3-7.8-0.4c6.6,0.4,13.1,0.7,19.7,1.1
								c-5-0.6-10-1.1-15.1-1.7c3.8,0.7,7.7,1.4,11.5,2.2c-2.3-0.7-4.7-1.3-7-2c3.7,1.1,7.4,2.1,11,3.2c-2.2-0.9-4.4-1.8-6.6-2.7
								c3.4,1.5,6.8,3,10.3,4.4c-2.1-1.1-4.1-2.2-6.2-3.3c3.2,1.8,6.4,3.5,9.6,5.3c-1.9-1.3-3.8-2.6-5.7-3.8
								c2.9,2.1,5.8,4.3,8.6,6.4c-1.7-1.4-3.4-2.9-5-4.3c2.6,2.4,5.2,4.8,7.8,7.2c-1.5-1.6-3-3.2-4.4-4.7c2.2,2.7,4.4,5.4,6.5,8.1
								c-1.3-1.7-2.5-3.4-3.8-5.1c1.8,2.9,3.6,5.7,5.4,8.6c-1-1.8-2-3.6-3-5.3c1.4,3.3,2.9,6.5,4.3,9.8c-0.8-1.8-1.5-3.7-2.3-5.5
								c1,3.4,2.1,6.8,3.1,10.2c-0.5-1.9-1.1-3.8-1.6-5.6c0.6,3.3,1.2,6.7,1.8,10c-0.3-1.9-0.6-3.8-0.9-5.7
								c0.3,3.4,0.5,6.8,0.8,10.3c-0.1-1.9-0.1-3.9-0.2-5.8c0,3.4-0.1,6.8-0.1,10.3c0.1-1.9,0.3-3.8,0.4-5.7
								c-0.6,6.7-1.2,13.4-1.9,20.1c0.3-1.9,0.5-3.9,0.8-5.8c-1.1,5.7-2.7,11.2-4.8,16.6c0.7-1.9,1.4-3.8,2.1-5.7
								c-1.2,3.1-2.5,6.1-3.7,9.2c0.9-1.9,1.7-3.8,2.6-5.7c-1.4,3-2.8,5.9-4.2,8.9c1-1.9,2-3.7,3.1-5.6c-1.7,2.9-3.3,5.8-5,8.6
								c1.2-1.8,2.4-3.7,3.5-5.5c-1.8,2.7-3.7,5.4-5.5,8.1c1.3-1.8,2.7-3.5,4-5.3c-2.1,2.6-4.3,5.1-6.4,7.7c1.5-1.7,3.1-3.4,4.6-5.1
								c-2.3,2.3-4.7,4.7-7,7c1.7-1.6,3.4-3.2,5.2-4.9c-2.7,2.1-5.4,4.2-8,6.3c4-2.8,8.1-5.6,12.1-8.4c-6.1,3.2-12.1,6.3-18.2,9.5
								c4.8-2,9.6-4.1,14.4-6.1c-5.7,2-11.4,3.9-17.1,5.9c5.1-1.5,10.3-2.9,15.4-4.4c-3.5,0.6-7.1,1.1-10.6,1.7
								c2.7-0.3,5.4-0.6,8.1-0.9c-3.6,0-7.3,0-10.9,0c4.8,0.4,9.6,0.8,14.4,1.2c-9.4-1.7-18.8-3.5-28.2-5.2
								c5.9,1.4,11.8,2.7,17.6,4.1c-3.7-1.3-7.4-2.5-11.1-3.8c2.8,1.2,5.6,2.4,8.5,3.6c-3.6-1.8-7.1-3.6-10.7-5.4
								c2.7,1.6,5.5,3.2,8.2,4.8c-6.3-3.9-12.5-7.9-18.8-11.8c5.3,3.7,10.6,7.4,15.9,11.2c-5.9-4.8-11.7-9.7-17.6-14.5
								c5,4.5,10,9,14.9,13.5c-5.4-5.5-10.8-11.1-16.3-16.6c4.4,5.1,8.8,10.2,13.2,15.3c-4.7-6.2-9.5-12.5-14.2-18.7
								c3.8,5.7,7.7,11.4,11.5,17.1c-4.3-7.1-8.6-14.2-12.9-21.4c3.1,6.1,6.3,12.2,9.4,18.4c-3.4-7.6-6.8-15.3-10.3-22.9
								c2.4,6.6,4.8,13.1,7.3,19.7c-2.5-8.1-5.1-16.1-7.6-24.2c1.6,6.8,3.2,13.6,4.8,20.4c-1.6-8.4-3.2-16.8-4.8-25.2
								c0.5,3.5,1.1,7,1.6,10.6c-0.4-5.1-0.8-10.2-1.2-15.2c0.1,3.5,0.3,7.1,0.4,10.6c0.1-5.1,0.2-10.1,0.3-15.2
								c-0.3,3.5-0.5,7.1-0.8,10.6c0.6-5.1,1.2-10.2,1.8-15.2c-0.6,3.5-1.2,6.9-1.8,10.4c1.2-7.2,2.8-14.3,4.8-21.3
								c2.2-6.5,4.9-12.9,8-19c-1,2.3-2.1,4.5-3.1,6.8c2.2-4.2,4.4-8.3,6.6-12.5c-1.2,2.2-2.4,4.3-3.6,6.5c3.4-6,7-11.9,10.8-17.7
								c3.9-8.3,8.1-16.4,12.6-24.4c4.5-8,9.3-15.7,14.4-23.3c5.1-7.6,10.6-14.9,16.4-22c2-2.2,2.5-4.7,1.3-7.5
								c-1.3-2.7-3.5-4-6.5-4c-5.3,1-10.5,2.6-15.3,5.1c-5.1,2-9.7,4.7-14,8.1c-2.9,4.2-5.8,8.5-8.7,12.7c-4.3,6.5-8.2,13.1-11.8,20
								c-5,9.1-9.5,18.4-13.7,27.9c-7.2,16.4-13.9,33.1-16.3,51c-0.5,2.8-0.8,5.7-0.8,8.6c-0.5,2.2-0.4,4.4,0.2,6.5
								c1.8,8.4,3.8,16.8,6.1,25.1c0.7,2.4,1.4,4.9,2.1,7.3c1,1.1,2,2.2,3,3.3c0.8,0.8,1.5,1.5,2.3,2.3c3.5,5.3,7.5,10.3,11.9,15
								c2.9,3.4,6.6,5.6,11.3,6.7c4.7,0.6,9.3,0.4,13.9-0.5c4.7-0.5,9.2-1.8,13.4-3.8c5.6-4.7,12-9.6,15.8-16
								c2.2-3.7,3.5-8.3,4.6-12.5c2.7-10.3,3.9-20.9,4.6-31.5c-0.1,1.4-0.2,2.8-0.3,4.2c0.6-4.3,0.7-8.6,0.2-12.9
								c-0.3-4-1.2-7.9-2.5-11.7c-0.6-1.4-1.3-2.8-1.9-4.2c-1.7-2.7-3.7-5.2-6-7.3c-1.1-1.5-2.5-2.7-4.1-3.5
								c-1.4-1.5-3.2-1.9-5.2-1.2c-22.9,7.4-45.8,14.9-68.7,22.3c-3.1,0.5-5.1,2.3-5.9,5.4c-0.7,4.4-0.9,8.9-0.5,13.4
								c0.1,5.5,0.5,11.1,1.4,16.6c-0.1,4.5,1.5,8.3,4.7,11.2c6.3,5.4,12.6,10.8,19,16.2c2.1,1.4,4.1,2.9,6.2,4.3
								c1.5,0.2,2.9,0.4,4.4,0.6c8-0.5,15.9-1,23.9-1.5c7.5-2.3,14.7-6.1,21.2-10.5c6.5-4.4,12.4-9.5,17.6-15.2
								c6-6.5,10.4-14.2,15-21.7c-1.1,1.7-2.2,3.3-3.3,5c2.6-3.6,4.9-7.4,6.8-11.4c2.5-4.3,4.7-8.8,6.5-13.5
								c-0.6,1.3-1.1,2.5-1.7,3.8c2-3.4,4-6.8,5.6-10.4c0.8-1.9,1.6-3.8,2.4-5.7c0.8-3.1,1.4-6.2,1.8-9.4c0.5-2,0.6-4,0.2-6
								c0-2-0.3-3.9-0.8-5.7c0-1.9-0.7-3.4-2.1-4.5c-4.7-5.5-9.5-10.8-14.5-16.1c-1.2-2-2.8-3-5-2.8c-3.1-0.7-6,0.1-8.7,2.3
								c-2.9,1.6-5.8,3.2-8.7,4.8c-5,2.8-9.5,6.2-13.5,10.2c-3.3,4.7-6.2,9.6-8.6,14.8c-1.1,2.6-2.1,5.2-3.2,7.9
								c-3.3,8.9-4.8,18.5-5.1,28c-0.2,6.9,0.3,13.7,1.4,20.6c0.5,3.9,1.3,7.7,2.4,11.5c0.8,4.3,2.2,8.5,4.2,12.5
								c1.6,2.5,3.9,3.4,6.8,2.7c4-2,6.6-5.2,8-9.7c3.3-6.9,6.2-14,8.5-21.4C840.2,1256.8,840.5,1252.6,838.9,1248.4z"/>
							<path opacity="0.1091" fill="#131313" d="M860.3,1281.7c-1.2-4.2-2.4-8.4-3.3-12.7c-0.5-3.1-0.7-6.3-0.5-9.4
								c0-3.1,0.3-6.1,1-9.1c0.9-3.9,2.1-7.7,3.8-11.4c-1,1.9-2,3.9-3,5.8c1.9-3.7,3.8-7.5,5.8-11.2c-1.2,1.9-2.3,3.9-3.5,5.8
								c2.2-3.6,4.5-7.2,6.7-10.7c-1.3,1.9-2.7,3.8-4,5.6c2.5-3.4,5.1-6.8,7.6-10.2c-1.5,1.8-3,3.6-4.5,5.4c4.4-4.5,8.7-9,13.1-13.5
								c-4.7,3.5-9.4,7-14.1,10.5c3.3-1.9,6.6-3.9,9.8-5.8c-3.1,1.7-6.1,3.4-9.2,5.1c3.5-1.7,7-3.4,10.5-5
								c-9.1,3.3-18.1,6.5-27.2,9.8c13.2-0.3,26.3-0.7,39.5-1c-12.7-2.9-25.5-5.8-38.2-8.7c11.2,7.9,22.3,15.8,33.5,23.7
								c-6.8-7.5-13.7-15-20.5-22.5c4.6,6.8,9.3,13.5,13.9,20.3c-3.6-6.1-7.3-12.3-10.9-18.4c3.4,7.1,6.8,14.2,10.2,21.3
								c-2.5-6.5-5-13-7.6-19.4c2.1,7.6,4.1,15.2,6.2,22.8c-1.5-6.5-2.9-12.9-4.4-19.4c1,7.6,2.1,15.1,3.1,22.7
								c-0.5-6.5-1-12.9-1.4-19.4c0.1,7.3,0.3,14.7,0.4,22c0.4-6.3,0.9-12.5,1.3-18.8c-1,8.3-2,16.5-3,24.8c0.4-3,0.8-5.9,1.1-8.9
								c-1.1,4.9-2.1,9.9-3.2,14.8c0.7-2.8,1.3-5.6,2-8.3c-1.5,4.4-2.9,8.9-4.4,13.3c0.9-2.6,1.8-5.2,2.7-7.7
								c-1.8,4.2-3.7,8.5-5.5,12.7c1.2-2.4,2.3-4.8,3.5-7.2c-2.2,3.9-4.5,7.8-6.7,11.8c1.3-2.2,2.7-4.4,4-6.6
								c-2.5,3.7-5.1,7.4-7.6,11.1c1.5-2,3.1-4,4.6-6c-2.8,3.3-5.7,6.6-8.5,9.9c1.7-1.8,3.3-3.6,5-5.4c-3.1,3.1-6.1,6.2-9.2,9.3
								c1.8-1.6,3.6-3.2,5.4-4.9c-3.2,2.7-6.3,5.5-9.5,8.2c1.9-1.4,3.9-2.9,5.8-4.3c-3.4,2.5-6.8,4.9-10.3,7.4c2-1.2,4-2.5,6-3.7
								c-3.6,2.2-7.2,4.4-10.8,6.5c2-1,4.1-2.1,6.1-3.1c-6.1,3.1-12.1,6.4-18.7,8.6c-4.3,1.4-8.5,2.3-12.9,3.1
								c2-0.2,4.1-0.4,6.1-0.6c-5.1,0.2-10.2,0.3-15.2,0.5c2,0.1,4,0.2,5.9,0.3c-3.4-0.3-6.8-0.6-10.2-0.9c1.9,0.2,3.9,0.5,5.8,0.7
								c-3.3-0.6-6.6-1.2-9.9-1.7c1.9,0.4,3.8,0.8,5.6,1.2c-1.3-0.4-2.6-0.8-3.9-1.2c1.7,0.8,3.4,1.6,5.1,2.4
								c-3-1.4-5.9-2.7-8.9-4.1c1.6,0.9,3.2,1.9,4.8,2.8c-2.7-1.6-5.3-3.2-8-4.9c1.5,1.1,3,2.2,4.5,3.3c-2.1-2-4.2-3.9-6.3-5.9
								c1.3,1.3,2.7,2.5,4,3.8c-1.8-2.2-3.7-4.4-5.5-6.6c1.2,1.4,2.4,2.8,3.6,4.2c-2.6-4.2-4.4-8.4-6.5-13c0.7,1.8,1.4,3.5,2.1,5.3
								c-1-3-1.9-6.1-2.9-9.1c0.5,1.8,1.1,3.7,1.6,5.5c-0.7-3.2-1.3-6.4-2-9.6c0.4,1.9,0.7,3.9,1.1,5.8c-0.5-3.1-1-6.2-1.5-9.2
								c0.2,2,0.4,4,0.5,6c-0.1-3.2-0.3-6.5-0.4-9.7c0,2.1,0,4.2-0.1,6.3c0.1-3.3,0.2-6.5,0.2-9.8c-0.2,2.2-0.5,4.3-0.7,6.5
								c0.4-4.5,1.1-8.9,2-13.3c-0.7,2.2-1.4,4.4-2.1,6.7c2-6.9,4.5-13.5,7.4-20.1c-2.2,4.6-4.5,9.2-6.7,13.7
								c3.4-6.7,7.4-13.2,12-19.2c-3.5,4.5-7,8.9-10.4,13.4c4.6-5.2,9.2-10.4,13.7-15.6c-4.2,4.3-8.4,8.6-12.5,13
								c5.2-4.9,10.4-9.8,15.6-14.6c-4.8,3.9-9.5,7.8-14.3,11.8c3.2-2.3,6.4-4.6,9.7-6.9c-2.8,1.6-5.5,3.3-8.3,4.9
								c6.4-3.6,12.8-7.3,19.2-10.9c-5.9,2.9-11.8,5.8-17.7,8.7c7-2.9,14.1-5.9,21.1-8.8c-6.4,2.3-12.9,4.6-19.3,6.9
								c7.6-2.3,15.1-4.6,22.7-6.8c-6.8,1.6-13.7,3.2-20.5,4.8c8-1.4,16-2.9,24-4.3c-7.3,0.9-14.5,1.7-21.8,2.6
								c8.3-0.7,16.7-1.3,25-2c-7.4,0.1-14.8,0.2-22.2,0.2c8.5,0.3,17,0.5,25.6,0.8c-3.8-0.2-7.6-0.4-11.4-0.7
								c4.9,0.6,9.8,1.2,14.7,1.8c-3.8-0.6-7.6-1.3-11.4-1.9c4.9,1.2,9.8,2.3,14.6,3.5c-3.8-1-7.6-2.1-11.4-3.1
								c4.8,1.6,9.6,3.3,14.4,4.9c-3.7-1.4-7.4-2.9-11.1-4.3c4.6,2.2,9.3,4.4,13.9,6.5c-3.6-1.9-7.2-3.7-10.8-5.6
								c4.4,2.6,8.8,5.3,13.2,7.9c-6.6-4.7-13.1-9.3-19.7-14c7.2,5.6,14.5,11.1,21.7,16.7c-6-5.3-12.1-10.7-18.1-16
								c6.6,6.4,13.2,12.7,19.8,19.1c-5.3-5.9-10.6-11.8-15.9-17.7c5.7,6.9,11.4,13.8,17,20.8c-4.5-6.4-9-12.7-13.5-19.1
								c4.9,7.7,9.8,15.4,14.7,23.1c-3.6-6.6-7.1-13.2-10.7-19.8c3.8,8,7.7,16,11.5,24c-2.6-6.8-5.3-13.7-7.9-20.5
								c2.7,8.3,5.5,16.6,8.2,24.9c-1-3.4-2-6.8-3-10.2c1.1,4.9,2.1,9.8,3.2,14.7c-0.6-3.4-1.2-6.8-1.8-10.2c0.5,4.9,1,9.8,1.4,14.7
								c-0.2-3.3-0.4-6.6-0.6-9.9c0.1,4.8,0.2,9.7,0.4,14.5c0.1-3.2,0.3-6.4,0.4-9.6c-0.3,4.7-0.5,9.5-0.8,14.2
								c0.4-3.1,0.8-6.2,1.3-9.3c-1,5.8-2.1,11.6-3.1,17.4c-0.6,2-1.2,4-1.9,6c-1.1,3.3-2.6,6.3-4.2,9.4c1.5-2.3,3-4.5,4.5-6.8
								c-2.2,3.4-4.5,6.7-6.7,10.1c1.6-2.1,3.2-4.2,4.8-6.3c-2.5,3.1-4.9,6.2-7.4,9.4c1.7-1.9,3.5-3.9,5.2-5.8
								c-2.7,2.7-5.4,5.4-8.1,8.2c1.8-1.7,3.7-3.5,5.5-5.2c-2.9,2.4-5.8,4.8-8.7,7.2c4.1-2.8,8.1-5.7,12.2-8.5
								c-3.3,2-6.6,3.9-9.8,5.9c2.2-1.1,4.3-2.1,6.5-3.2c-3.3,1.5-6.6,3-10,4.6c2.2-0.8,4.3-1.5,6.5-2.3c-3.3,1.1-6.6,2.2-9.9,3.3
								c2.1-0.5,4.3-0.9,6.4-1.4c-5,0.6-10.1,0.6-15.2-0.1c3.4,0.5,6.8,1,10.2,1.4c-4.2-0.8-8.2-2.1-12.1-3.9
								c1.2,0.7,2.3,1.4,3.5,2.2c-2.3-1.5-4.6-3-6.9-4.5c1,0.8,2,1.7,3,2.5c-4-3.3-8.2-6.4-11.1-10.7c-3.1-4.7-4.9-10-6.9-15.3
								c0.7,1.7,1.3,3.4,2,5.1c-1.2-4.5-2.1-9-2.7-13.5c0.3,1.9,0.5,3.8,0.8,5.7c-0.2-3.3-0.4-6.6-0.6-9.9c0.1,2,0.1,4.1,0.2,6.1
								c0-3.3,0.1-6.7,0.1-10c-0.2,2.1-0.4,4.2-0.5,6.3c0.7-7,1.7-14,3-20.9c-0.4,2.2-0.8,4.5-1.2,6.7c1-3.6,2-7.2,2.9-10.8
								c-0.6,2.3-1.3,4.6-1.9,6.9c1.3-4.2,2.6-8.4,3.8-12.6c-0.9,2.4-1.8,4.7-2.7,7.1c1.8-5.3,3.9-10.4,6.3-15.4
								c2.2-4.7,4.5-9.3,7.1-13.8c-1,1.8-2,3.7-2.9,5.5c3.1-5.6,6.5-11.1,10-16.5c3.9-8.2,8.1-16.3,12.5-24.2
								c4.4-8,9.2-15.7,14.3-23.2c5.1-7.6,10.6-14.9,16.3-22c2.3-2.2,2.9-4.8,1.9-7.7c-1.5-2.8-3.8-4-7-3.7
								c-6,1.2-11.8,3.2-17.4,5.9c-5.7,2.3-11.1,5.3-16,9c-4,6.1-7.6,12.4-11,18.8c-3.6,6.3-6.9,12.8-9.9,19.4
								c-3.1,6.5-5.9,13.2-8.4,20c-2.7,6.7-5.2,13.5-7.5,20.4c-2,6.7-3.8,13.4-4.9,20.2c-1.1,6.6-1.7,13.2-1.9,20
								c0.2-1.7,0.3-3.4,0.5-5.2c-0.8,4.5-1.5,9-1.9,13.5c-0.2,2.7-0.2,5.4-0.1,8.2c0.1,2.6,0.4,5.2,0.8,7.8c0.7,4.1,1.5,8.5,3,12.3
								c3.4,8.4,8.8,16.5,15.9,22.1c2.4,2,4.9,3.7,7.6,5.3c1.4,0.4,2.8,0.8,4.2,1.1c2,0.1,4,0.2,6,0.3c8.5-0.5,16.8-2.1,24.9-4.9
								c4-2.1,7.5-4.9,10.5-8.2c3.3-3.1,6.1-6.6,8.2-10.6c3.8-12.3,6-24.8,6.8-37.5c1.3-11.7-2.3-22.6-10.8-32.9
								c-1-3.1-2.9-5.4-5.7-6.9c-4.3-3.5-8.9-6.5-13.8-8.9c-3.6-1.9-7.2-3.7-10.8-5.6c-4-2.8-8.3-3.7-12.9-2.8
								c-10.2,4.6-20,9.7-29.6,15.2c-2.1,2.8-3.8,5.8-5.1,9c-2.2,4-4.1,8.1-5.7,12.4c-1.3,4.5-2,9.2-2,13.9c0.7-4.5,1.3-8.9,2-13.4
								c-1.6,4.5-2.4,9-2.4,13.7c-0.1,2.5-0.2,5.1-0.3,7.6c0.1,5.4,1.1,10.6,2.5,15.8c-0.3-1.3-0.7-2.6-1-4
								c1.3,6.4,2.6,12.5,6.6,17.9c2.2,2.6,4.6,5.1,7.2,7.4c3.1,2.1,6.5,3.8,10,5.2c5.2,1.9,10.7,2.8,16.3,2.9
								c4,0.5,7.9,0.4,11.9-0.3c8.8-1.2,17.2-4.3,25.1-8.4c5.7-3,11.1-6.6,16.1-10.7c2-1.5,3.8-3.2,5.6-5c3.4-3.3,6.5-7,9.1-11
								c3.1-4,5.7-8.3,7.9-12.8c8.5-15.2,13.4-31.2,14.8-47.9c-0.1-3.5-0.4-7-0.9-10.4c-1.2-4.4-3.3-8.4-6.3-11.9
								c-2.5-1.8-5.3-2.3-8.3-1.7c-5.1,0-10.2,0.6-15.2,1.9c-3.7,0.8-7.4,1.7-11,2.5c-4.6,0.4-8.2,2.4-10.8,6
								c-1.6,5.4-3.1,10.9-4.7,16.3c0.4-1.4,0.8-2.7,1.3-4.1c-2.7,6.4-5.2,12.7-6.5,19.5c-1,5.5-1.6,11-1.6,16.6
								c-0.1,5.7,0.3,11.4,1.5,17c0.5,2.3,1.1,4.5,1.6,6.8c1.4,4,2.8,8,4.1,12.1c0.4,2.7,1.9,4.6,4.5,5.6c2.6,1.1,4.9,0.7,7.1-1
								c2.8-3.6,5-7.5,6.5-11.8C858.8,1290.6,859.9,1286.2,860.3,1281.7z"/>
							<path opacity="0.1091" fill="#131313" d="M814.8,1279.6c0-5.9-0.6-11.8-1.7-17.5c-0.5-4.2-0.4-8.4,0.1-12.6
								c-0.2,1.3-0.5,2.7-0.7,4c0.7-3.4,1.4-6.8,2.1-10.2c-0.3,1.3-0.6,2.6-0.9,3.9c1.8-5.9,3.7-11.6,7-16.8
								c1.1-1.6,2.2-3.3,3.3-4.9c2.3-3.1,4.9-6,7.7-8.6c-1.2,1-2.4,2.1-3.6,3.1c2.7-2.3,5.4-4.5,8.1-6.8c-2.6,1.6-5.3,3.2-7.9,4.8
								c1.5-0.3,2.8-0.5,4.3-0.6c-1.1-0.1-2.2-0.2-3.4-0.3c1.5,0.1,2.5,0.8,3.2,2.1c-0.6-0.8-1.1-1.6-1.7-2.5
								c0.9,1.5,1.8,3.1,2.8,4.6c-0.4-0.9-0.9-1.8-1.3-2.7c0.7,1.7,1.3,3.4,2,5.2c-0.3-1-0.6-1.9-1-2.9c0,0.1,0,0.1,0,0.2
								c0.3-1.2,0.7-2.4,1-3.6c-1.3,2.3-2.3,4.7-2.9,7.3c0,0.9-0.1,1.8-0.3,2.7c-0.2,0.8-0.3,1.6-0.4,2.4c-1.1,5-1.6,10-1.7,15.2
								c0.4-2.9,0.8-5.7,1.2-8.6c-0.7,4.4-1.5,8.9-2.2,13.3c0.7-3,1.4-5.9,2.1-8.9c-2.7,9.1-5.4,18.1-8.1,27.2
								c1.2-3.2,2.3-6.3,3.5-9.5c-2,4.6-4.1,9.1-6.1,13.7c1.6-3.2,3.1-6.4,4.7-9.6c-2.5,4.6-4.9,9.1-7.4,13.7c2-3.1,4-6.3,5.9-9.4
								c-3,4.5-6.1,8.9-9.1,13.4c2.4-3.1,4.8-6.1,7.2-9.2c-3.5,4.4-7,8.7-10.6,13.1c2.8-2.9,5.6-5.9,8.4-8.8c-4.3,5.1-9,9.8-14,14.1
								c-4.3,3.8-8.8,7.3-13.5,10.5c2.5-1.9,5-3.8,7.6-5.7c-4.1,2.6-8.2,5.3-12.2,7.9c2.7-1.7,5.4-3.4,8.1-5.1
								c-4.3,2.4-8.6,4.7-12.9,7.1c2.8-1.5,5.7-3,8.5-4.4c-4.5,2-8.9,4.1-13.4,6.1c2.9-1.3,5.9-2.5,8.8-3.8
								c-4.6,1.7-9.2,3.4-13.8,5.1c3-1,6.1-2.1,9.1-3.1c-4.7,1.4-9.4,2.7-14.1,4.1c3.1-0.8,6.2-1.6,9.3-2.4c-4.7,1-9.5,2-14.2,3
								c3.1-0.6,6.2-1.1,9.4-1.7c-4.5,0.5-9,1-13.5,1.5c3.1-0.3,6.2-0.7,9.4-1c-4.5,0.2-9.1,0.4-13.6,0.6c3.1-0.1,6.2-0.2,9.3-0.3
								c-4.5-0.2-8.9-0.3-13.4-0.5c11.6,1.7,23.2,3.3,34.7,5c-14.9-3.4-29.8-6.7-44.7-10.1c6.3,2.3,12.7,4.7,19,7
								c-4.7-2.2-9.4-4.5-14-6.7c6.5,3.9,13,7.8,19.6,11.8c-7.2-4.8-14.4-9.6-21.5-14.4c5.7,4.3,11.4,8.7,17.1,13
								c-6.3-5.3-12.6-10.5-18.9-15.8c4.9,4.7,9.8,9.4,14.7,14.1c-5.4-5.8-10.7-11.6-16.1-17.3c2.1,2.3,4.3,4.7,6.4,7
								c-2.6-3.4-5.3-6.9-7.9-10.3c1.8,2.4,3.5,4.8,5.3,7.3c-2.2-3.5-4.4-7.1-6.6-10.6c2.6,5.1,5.1,10.2,7.7,15.2
								c-4-8.2-7.3-16.7-9.9-25.5c1.3,4.9,2.7,9.7,4,14.6c-0.8-4.2-1.7-8.3-2.5-12.5c0.3,2.4,0.5,4.7,0.8,7.1
								c-0.4-4.1-0.9-8.2-1.3-12.3c0,2.3,0,4.6,0,6.8c0.1-4,0.2-8,0.3-12c-0.2,2.1-0.5,4.2-0.7,6.4c0.4-3.8,0.9-7.7,1.3-11.5
								c-0.4,2-0.9,4-1.3,6c1-3.6,1.9-7.3,2.9-10.9c-0.6,1.8-1.2,3.6-1.8,5.5c1.3-3.5,2.5-6.9,3.8-10.4c-0.8,1.7-1.5,3.4-2.3,5
								c1.6-3.1,3.3-6.1,4.9-9.2c-0.9,1.5-1.8,3-2.6,4.6c1.9-2.8,3.8-5.7,5.7-8.5c-1,1.4-2,2.8-3,4.1c2.2-2.4,4.3-4.8,6.5-7.2
								c-1.1,1.2-2.2,2.5-3.3,3.7c2.4-2.2,4.8-4.3,7.2-6.5c-1.2,1.1-2.4,2.2-3.7,3.2c2.2-1.5,4.6-2.7,7.2-3.6
								c-1.1,0.2-2.2,0.5-3.3,0.7c3.5-0.7,7-1.6,10.6-1.7c3-0.1,6,0.4,8.9,1c2.2,0.4,4.3,1.1,6.4,1.8c3.8,1.2,7.2,3.3,10.7,5.1
								c-1.5-0.9-3-1.7-4.5-2.6c2.1,1.5,4.2,3,6.4,4.4c-1.5-1.1-3-2.1-4.5-3.2c2,1.8,4.1,3.5,6.1,5.3c-1.4-1.3-2.9-2.5-4.3-3.8
								c2.4,2.7,4.7,5.6,6.8,8.6c-1.3-1.7-2.5-3.4-3.8-5.1c1.9,3.6,3.6,7.2,5.2,10.8c-0.9-2.1-1.8-4.3-2.8-6.4
								c1,3.3,2.1,6.6,3.1,9.9c-0.7-2.4-1.4-4.8-2.2-7.2c1.4,7.3,2.7,14.6,4.1,21.9c-0.5-2.8-0.9-5.6-1.4-8.4
								c0.3,4.1,0.6,8.1,0.8,12.2c-0.2-2.9-0.3-5.9-0.5-8.8c-0.1,4.3-0.2,8.5-0.3,12.8c0.1-3.1,0.3-6.2,0.4-9.3
								c-0.3,4.4-0.6,8.7-0.9,13.1c0.5-3.2,0.9-6.4,1.4-9.7c-0.9,6.9-2.2,13.7-4.1,20.4c-1.7,5-3.6,9.9-5.8,14.7
								c1.1-2.5,2.2-5.1,3.2-7.6c-0.6,1.1-1.1,2.2-1.7,3.3c4.7-6.6,9.4-13.1,14.1-19.7c-10.2,12-20.5,24.1-30.7,36.1
								c5.8-5,11.7-9.9,17.5-14.9c-4.6,3.2-9.2,6.4-13.8,9.6c7.8-4.6,15.7-9.2,23.5-13.9c-8.8,4.7-17.6,9.4-26.4,14.2
								c8.3-3.8,16.5-7.6,24.8-11.4c-9.2,3.8-18.4,7.5-27.6,11.3c8.6-2.8,17.1-5.5,25.7-8.3c-9.6,2.5-19.3,5.1-28.9,7.6
								c8.6-1.7,17.1-3.3,25.7-5c-9.6,1.2-19.3,2.5-28.9,3.7c8.6-0.6,17.1-1.1,25.7-1.7c-9.6-0.1-19.1-0.1-28.7-0.2
								c8.2,0.6,16.5,1.1,24.7,1.7c-9.2-1.3-18.3-2.5-27.5-3.8c7.9,1.6,15.8,3.2,23.6,4.8c-8.7-2.5-17.4-4.9-26.1-7.4
								c7.2,2.5,14.3,5,21.5,7.5c-8-3.4-16-6.7-24-10.1c6.5,3.3,13,6.6,19.5,9.9c-7.3-4.4-14.6-8.7-21.9-13.1
								c5.7,3.9,11.4,7.7,17,11.6c-6.5-5-12.9-9.9-19.4-14.9c4.9,4.4,9.9,8.8,14.8,13.2c-5.5-5.6-11-11.2-16.4-16.9
								c4,4.6,8.1,9.2,12.1,13.8c-4.6-5.9-9.1-11.7-13.7-17.6c3.2,4.8,6.4,9.6,9.7,14.4c-2.4-4.3-4.8-8.5-7.2-12.8
								c1.1,2.5,2.2,4.9,3.3,7.4c-2.9-6.6-5.7-13.3-8.6-19.9c1.7,4.8,3.4,9.5,5.1,14.3c-1.1-4.3-2.1-8.6-3.2-12.9
								c0.4,2.3,0.8,4.7,1.2,7c-0.6-4.2-1.3-8.5-1.9-12.7c0.1,2.3,0.2,4.5,0.3,6.8c0-4.2,0-8.4,0-12.5c-0.2,2.1-0.3,4.2-0.5,6.3
								c0.4-4,0.7-8.1,1.1-12.1c-0.4,2-0.8,4-1.2,5.9c0.9-3.8,1.7-7.6,2.6-11.5c-0.6,1.8-1.1,3.6-1.7,5.5c2.3-7.6,4.7-15.1,7.3-22.6
								c-0.6,1.7-1.2,3.4-1.8,5c1.7-3.8,3.4-7.6,5.1-11.4c-0.7,1.6-1.5,3.1-2.2,4.7c1.9-3.6,3.9-7.2,5.8-10.8
								c-0.8,1.5-1.7,2.9-2.5,4.4c3.8-6.2,7.8-12.3,11.8-18.5c3.7-5.7,6.3-11.8,9.4-17.8c4.2-7.9,8.7-15.6,13.4-23.2
								c7.2-11.5,15.4-22.1,23.8-32.7c0.7-2.2,0.5-4.4-0.6-6.6c-0.8-2.2-2.3-3.9-4.4-4.9c-2.7-0.2-5.3,0.3-7.7,1.5
								c-2.6,0.9-4.7,2.5-6.4,4.6c-8.8,11.6-17.3,23.3-24.7,35.9c-7.4,12.7-13.9,25.8-20.1,39.1c0.6-1.1,1.1-2.1,1.7-3.2
								c-4.6,8-9.4,15.9-13.3,24.3c-2.2,4.7-4.4,9.5-5.8,14.5c-0.9,3.3-1.9,6.6-3.1,9.8c-0.5,2.4-1,4.7-1.4,7.1
								c-0.6,4.2-0.7,8.5-0.2,12.7c5.2,21.6,17.8,39.7,38,54.1c2.2,2.7,5,3.4,8.5,2.3c5.2-1.9,10.1-4.2,14.8-7.1
								c6-3.2,11.9-6.7,17.5-10.6c4.8-2.3,8.3-5.8,10.3-10.5c1.9-6.1,2.8-12.2,2.4-18.5c-0.1-3-0.5-6-1-8.9
								c-0.5-2.9-1.2-5.8-2.1-8.6c-0.9-4.3-2.4-8.5-4.3-12.5c0.2,2.2,0.4,4.4,0.6,6.6c0.3-5.2-0.1-10.3-1.2-15.4
								c-1-3.3-2.3-6.4-3.9-9.3c-3-5.2-7.3-9.7-12.2-13.1c-7.2-5-15.9-8.2-24.5-9.8c-6.3-1.1-12.9-1.4-19.1,0.2
								c-7.1,1.8-13.5,5.9-18.7,11c-2.9,2.8-5.1,6-6.8,9.5c-2.3,4.1-4.4,8.2-6.3,12.5c-1.1,3.9-1.7,7.9-1.8,12
								c1.8,14.5,5.6,28.5,11.6,42.1c0.9,1.3,1.7,2.7,2.6,4c3.8,2.9,7.9,5.4,12.3,7.3c3.5,1.9,7.1,3.6,10.8,5
								c4.3,2.1,8.8,3.6,13.5,4.7c3.4,0,6.8-0.2,10.2-0.8c2.8-0.2,5.6-0.9,8.2-1.9c2.4-0.7,4.6-1.6,6.9-2.5c3.9-1.6,7.4-3.5,11-5.7
								c5.9-3.6,11.6-8,16.4-13c8-8.3,14.8-18,18.4-29.1c0.8-2.9,1.4-5.8,1.9-8.7c0.6-3,1-5.9,1.2-8.9c0.1-2.3,0.1-4.5,0.2-6.8
								c0.2-3.6-0.1-7.3-0.6-10.9c-2,8.4-4.1,16.9-6.1,25.3c2.3-5.7,5.3-11.1,8.9-16.1c1.9-3.6,3.5-7.4,4.8-11.3
								c1.1-3.5,1.7-7.1,1.8-10.7c0.2-1.8,0.3-3.6,0.3-5.5c-0.1-2.1-0.4-4.2-0.7-6.3c-0.8-2.9-2.1-5.7-3.9-8.1
								c-1.6-2.8-3.6-5.3-6-7.5c-3.5-2.2-7.5-3.1-11.8-2.7c-6.8,0.6-12.1,5.7-16.6,10.5c-6.7,7.1-12,15.5-15.7,24.5
								c-3.3,8-5.6,16.9-6.2,25.5c-0.3,4.9-0.1,9.9,0.6,14.8c1.4,5.6,2.3,11.3,2.5,17.1c0.9,2.3,2.6,3.8,4.9,4.6
								c2.2,1,4.5,1.1,6.7,0.1c2.3-2.2,3.9-4.8,4.9-7.9C814.3,1285.8,814.9,1282.7,814.8,1279.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M887.2,1290.6c9.5-17.1,18.9-34.2,27.4-51.8c8.8-18.2,16.6-36.6,24.2-55.3
								c0.4-2.8-0.4-5.1-2.5-6.9c-3.1-1.9-6.3-2.1-9.8-0.6c-5.7,1.4-11.1,3.4-16.4,5.8c-3.7,1-6.1,3.2-7.2,6.6
								c-8,18.2-16.3,36.2-25.3,53.8c-8.7,17-18.1,33.6-27.6,50.2c-0.7,2.7-0.1,5.1,1.8,7.1c3,2.2,6.3,2.7,10,1.4
								c5.9-0.9,11.7-2.4,17.4-4.4C883,1295.9,885.7,1293.9,887.2,1290.6z"/>
							<path opacity="0.1091" fill="#131313" d="M859.3,1277.7c9.2-8.5,16.2-18.5,21-30c4-7.6,7.9-15.2,11.6-23
								c8.8-18.2,16.7-36.6,24.4-55.3c1.4-2.2,1.6-4.5,0.6-6.9c-0.8-2.5-2.4-4.1-4.8-4.9c-3.8,0-7.4,0.7-10.9,2.1
								c-3.6,1.1-6.9,2.8-9.8,5.3c-7.6,18.3-15.5,36.4-24.2,54.1c-3.7,7.6-7.5,15.2-11.5,22.6c-4.5,10.9-11,20.6-19.4,28.9
								c-2.9,3.6-1.2,10.4,3.1,12.1c3.5,0.4,7,0.1,10.4-0.9C853.3,1281.1,856.5,1279.7,859.3,1277.7z"/>
							<path opacity="0.1091" fill="#131313" d="M865.4,1242.8c3.8-5.7,7.5-11.5,10.9-17.5c5.4-9.9,10.6-19.9,15.7-30
								c10.2-20.6,19.3-41.7,27.8-63.1c0.3-2.7-0.5-5-2.5-6.9c-2.8-1.9-5.9-2.2-9.2-0.7c-5.3,1.3-10.4,3.2-15.3,5.5
								c-3.5,1-5.7,3.2-6.6,6.5c-6.3,15.5-12.6,30.9-19.8,45.9c-7.1,15-14.8,29.7-22.7,44.3c0.6-1.1,1.2-2.1,1.9-3.2
								c-2.8,4.6-5.6,9.1-8.4,13.7c-1.9,2-2.5,4.3-1.8,6.9c0.6,2.7,2.3,4.4,4.9,5.2c4.4,0.2,8.8-0.3,13-1.6
								C857.6,1246.8,861.6,1245.1,865.4,1242.8z"/>
							<path opacity="0.1091" fill="#131313" d="M885.6,1266.2c5.5-9.6,10.9-19.2,16.1-29c4-7.6,7.9-15.2,11.5-23
								c8.8-18.2,16.6-36.6,24.2-55.3c0.6-2.8-0.2-5.1-2.5-6.9c-3.6-1.8-7.3-1.8-11.1-0.1c-6.5,1.7-12.8,4-18.9,6.7
								c-4.1,1.1-6.9,3.4-8.5,7.1c-7.7,18-15.8,35.8-24.6,53.4c-3.7,7.5-7.6,15-11.6,22.4c-5.2,9.2-10.4,18.4-15.7,27.6
								c-0.8,2.7-0.2,5.1,1.8,7.1c3.4,2.1,7,2.5,11,1.2c6.6-1.1,13-2.7,19.3-4.9C880.6,1271.8,883.6,1269.7,885.6,1266.2z"/>
							<path opacity="0.1091" fill="#131313" d="M887.3,1237.1c5.6-9.6,11.1-19.3,16.3-29.1c4-7.6,7.9-15.2,11.6-23
								c8.8-18.2,16.7-36.7,24.3-55.5c0.8-2.9,0-5.2-2.5-6.9c-4.2-1.7-8.4-1.5-12.7,0.5c-7.5,2.1-14.8,4.7-22,7.9
								c-4.6,1.2-7.9,3.7-10.1,7.7c-7.4,17.8-15.2,35.5-23.7,52.8c-3.6,7.5-7.4,14.9-11.4,22.2c-5.2,9.2-10.4,18.4-15.7,27.5
								c-1,2.8-0.4,5.2,1.8,7.1c3.8,2.1,7.9,2.4,12.2,0.8c7.3-1.3,14.5-3.2,21.6-5.6C881.5,1243,884.9,1240.8,887.3,1237.1z"/>
							<path opacity="0.1091" fill="#131313" d="M847.9,1264.6c7.5-9.4,13.8-19.5,19-30.2c4.2-7.6,8.1-15.2,11.8-23
								c8.9-18.2,16.8-36.7,24.4-55.5c1.5-3.8,0.3-10.2-4-11.9c-2.9-0.4-5.7-0.1-8.4,1c-2.8,0.8-5.3,2.1-7.3,4.2
								c-7.4,18.4-15.1,36.6-23.8,54.4c-3.6,7.7-7.5,15.3-11.6,22.7c-5,10.5-10.9,20.4-17.8,29.8c-1,2.1-1.1,4.3-0.4,6.6
								c0.4,2.3,1.6,4.2,3.5,5.6c2.5,0.7,5.1,0.6,7.6-0.2C843.7,1267.6,846,1266.4,847.9,1264.6z"/>
							<path opacity="0.1091" fill="#131313" d="M868.6,1296.7c8-8.8,14.4-18.7,19.1-29.6c4.1-7.6,7.9-15.3,11.6-23
								c8.9-18.2,16.9-36.7,24.5-55.5c1.3-2.2,1.5-4.4,0.5-6.8c-0.7-2.5-2.3-4.1-4.7-5c-3.6-0.1-7,0.5-10.3,1.9
								c-3.4,1-6.5,2.7-9.2,5c-7.8,18.4-15.8,36.6-24.8,54.4c-3.8,7.6-7.7,15.2-11.7,22.7c-4.6,10.4-10.6,20-18,28.6
								c-2.7,3.7-1.4,10.3,3.1,12.1c3.5,0.5,6.9,0.2,10.3-0.9C862.6,1300.2,865.8,1298.8,868.6,1296.7z"/>
							<path opacity="0.1091" fill="#131313" d="M901.2,1262.3c5.5-9.6,10.9-19.3,16.2-29.1c4-7.6,7.9-15.2,11.5-23
								c8.7-18.2,16.6-36.7,24.1-55.4c1-3,0.2-5.3-2.5-6.9c-4.7-1.6-9.4-1.2-14.1,0.9c-8.4,2.4-16.6,5.3-24.6,8.8
								c-5,1.3-8.8,4-11.5,8.2c-7.8,17.9-15.9,35.5-24.8,52.9c-3.8,7.5-7.7,14.9-11.7,22.2c-5.3,9.1-10.5,18.2-15.7,27.4
								c-1.3,2.9-0.7,5.2,1.8,7.1c4.5,2,9.2,2.1,14,0.4c8.5-1.6,16.9-3.7,25.1-6.4C894.1,1268.5,898.2,1266.2,901.2,1262.3z"/>
							<path opacity="0.1091" fill="#131313" d="M853.5,1254.2c9.2-8.5,16.2-18.5,20.9-30c5.3-10.1,10.4-20.3,15.3-30.7
								c7.3-15.6,14-31.5,20.4-47.6c1.5-2.2,1.7-4.5,0.7-6.9c-0.8-2.5-2.5-4.1-5-4.9c-3.9,0-7.6,0.8-11.3,2.3
								c-3.7,1.1-7.1,2.9-10.1,5.4c-2.5,6.3-5,12.5-7.6,18.8c0.4-1.1,0.9-2.1,1.3-3.2c-6.6,15.5-13.4,30.8-20.9,45.9
								c-2.6,5-5.1,10-7.7,15c-4.4,10.9-10.9,20.6-19.3,28.9c-2.9,3.6-1.2,10.4,3.1,12.1c3.5,0.4,7,0.1,10.4-1
								C847.4,1257.7,850.6,1256.3,853.5,1254.2z"/>
							<path opacity="0.1091" fill="#131313" d="M876.9,1248.4c9.4-17.1,18.9-34.2,27.4-51.8c8.8-18.2,16.6-36.7,24.2-55.4
								c0.5-2.8-0.3-5.1-2.5-6.9c-3.4-1.8-6.9-1.9-10.6-0.3c-6.1,1.6-12.1,3.8-17.9,6.4c-3.9,1.1-6.6,3.4-8,6.9
								c-7.5,18-15.3,35.8-23.9,53.3c-3.7,7.5-7.5,15-11.4,22.4c-5.1,9.2-10.3,18.4-15.5,27.6c-0.7,2.7-0.1,5.1,1.8,7.1
								c3.1,2.2,6.5,2.6,10.2,1.3c6.1-1,12-2.5,17.8-4.6C872.5,1253.8,875.3,1251.8,876.9,1248.4z"/>
							<path opacity="0.1091" fill="#131313" d="M897.5,1277.5c5.6-9.6,11-19.3,16.3-29.1c4-7.6,7.8-15.2,11.5-23
								c8.7-18.2,16.5-36.6,24-55.3c0.8-2.9,0-5.2-2.5-6.9c-4.1-1.7-8.3-1.6-12.5,0.4c-7.4,2-14.6,4.6-21.6,7.7
								c-4.5,1.1-7.8,3.7-9.9,7.6c-8,18-16.2,35.8-25.2,53.3c-3.8,7.5-7.7,15-11.8,22.3c-5.3,9.2-10.6,18.3-15.8,27.5
								c-1.1,2.8-0.5,5.2,1.8,7.1c4,2.1,8.2,2.3,12.6,0.8c7.6-1.4,15.1-3.3,22.4-5.7C891.4,1283.4,895,1281.2,897.5,1277.5z"/>
							<path opacity="0.1091" fill="#131313" d="M849.6,1277.6c7.5-9.4,13.9-19.4,19.1-30.2c4.2-7.6,8.1-15.2,11.8-23
								c8.8-18.2,16.7-36.7,24.3-55.4c1.5-3.7,0.1-10.2-4-11.9c-2.7-0.5-5.3-0.2-7.9,0.9c-2.7,0.7-5,2-6.8,4
								c-7.5,18.4-15.2,36.6-23.9,54.5c-3.7,7.7-7.5,15.3-11.6,22.8c-5,10.5-10.9,20.4-17.8,29.8c-0.9,2.1-1,4.3-0.3,6.5
								c0.4,2.3,1.6,4.2,3.4,5.6c2.4,0.7,4.9,0.6,7.3-0.2C845.6,1280.5,847.7,1279.4,849.6,1277.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M910.4,1337.1c6.6-8.6,13-17.2,18.6-26.4c5.9-9.7,11.4-19.7,15.9-30.1
								c3.7-8.5,7-17.2,10-25.9c4.6-13.4,7.6-27.1,10.5-41c0.2-2.8-0.7-5-2.8-6.8c-3-1.8-6.2-1.9-9.5-0.2
								c-5.4,1.6-10.6,3.7-15.6,6.4c-3.5,1.2-5.7,3.4-6.6,6.8c-1.3,4.2-2.6,8.5-3.8,12.7c-2,6.3-4.3,12.5-6.9,18.6
								c-4.2,10.2-8.8,20.2-14.1,29.8c-4.2,7.6-8.7,15.1-13.5,22.4c-6.8,10.4-14.5,20.1-22.2,29.9c-1.2,2.6-1,5,0.6,7.3
								c2.9,2.7,6.3,3.6,10.4,3c6.5,0,12.9-0.5,19.3-1.6C904.8,1341.8,908.1,1340.2,910.4,1337.1z"/>
							<path opacity="0.1091" fill="#131313" d="M882.4,1323.2c5.8-2.8,10.3-6.7,13.7-11.8c2.7-3.9,5.4-7.9,7.9-12
								c6.1-9.6,11.7-19.4,16.6-29.7c5-10.5,9.5-21.2,13.1-32.3c1.4-4.5,2.8-9,4.1-13.5c2.6-9.1,4.7-18.3,6.3-27.7
								c0-2.6-1-4.9-2.8-6.8c-2.2-1.9-4.8-2.2-7.6-1c-4.1,1.1-8.1,2.7-11.8,4.8c-2.9,1.1-4.4,3.1-4.7,6.1c-1.8,8.6-4,17.1-6.7,25.5
								c-1.9,6.3-4.2,12.5-6.7,18.6c-2.3,6.2-5,12.2-7.9,18.1c-3.8,7.8-8,15.5-12.6,22.9c0.6-1,1.2-1.9,1.8-2.9
								c-5,8-10.2,15.9-15.5,23.8c0.6-0.9,1.3-1.9,1.9-2.8c-3.8,3.3-7.7,6.6-11.5,9.9c-3.6,3.1-3,10,1.1,12.5
								c3.5,1,7.1,1.2,10.7,0.7C875.7,1325.7,879.1,1324.8,882.4,1323.2z"/>
							<path opacity="0.1091" fill="#131313" d="M887,1289c4.6-3.2,8.4-7.1,11.6-11.5c3-3.9,5.7-7.9,8.2-12.1
								c6.1-9.6,11.6-19.4,16.4-29.7c4.9-10.5,9.3-21.2,12.8-32.2c1.4-4.5,2.7-9,4.1-13.5c2.5-9.1,4.6-18.3,6.2-27.6
								c0.2-2.7-0.7-5-2.8-6.8c-3-1.8-6.1-1.9-9.4-0.3c-5.3,1.6-10.4,3.7-15.3,6.3c-3.5,1.2-5.6,3.4-6.5,6.8
								c-1.6,8.2-3.1,16.3-5.3,24.4c-2.7,10.1-6.2,20-10.1,29.7c-3.2,7.8-6.8,15.4-10.8,22.8c-4.9,9.2-10.5,17.8-16.3,26.5
								c0.7-0.9,1.4-1.9,2.1-2.8c-3.2,3.2-6.4,6.4-9.6,9.6c-2,1.7-2.9,3.9-2.5,6.5c0.2,2.7,1.4,4.6,3.7,6c3.9,1,7.8,1.2,11.8,0.6
								C879.5,1291.6,883.4,1290.7,887,1289z"/>
							<path opacity="0.1091" fill="#131313" d="M909,1312.7c6.6-8.6,13-17.2,18.7-26.4c6-9.7,11.4-19.7,15.9-30.1
								c3.7-8.5,7-17.2,10-25.9c4.6-13.4,7.6-27.1,10.4-41c0.4-2.8-0.5-5.1-2.8-6.8c-3.6-1.7-7.3-1.5-11,0.4
								c-6.3,2-12.4,4.5-18.3,7.5c-4,1.2-6.7,3.7-8.1,7.4c-1.1,4.1-2.2,8.2-3.3,12.3c-1.7,6.1-3.8,12.1-6.1,18
								c-3.7,9.9-7.8,19.7-12.7,29.1c-3.9,7.5-8.1,14.9-12.6,22.1c-6.5,10.4-14,20.1-21.5,29.7c-1.3,2.6-1.1,5,0.6,7.3
								c3,2.7,6.6,3.6,10.7,2.9c6.7,0,13.3-0.6,19.9-1.7C903.1,1317.4,906.5,1315.8,909,1312.7z"/>
							<path opacity="0.1091" fill="#131313" d="M910.2,1283.7c2.6-3.3,5.2-6.6,7.8-9.9c4-5.3,7.8-10.8,11.2-16.5
								c6-9.7,11.5-19.8,16.1-30.2c3.8-8.6,7.1-17.2,10.2-26.1c4.7-13.5,7.8-27.3,10.7-41.3c0.7-2.9-0.2-5.2-2.8-6.8
								c-4.3-1.5-8.6-1.1-12.8,1.1c-7.5,2.5-14.7,5.5-21.8,9c-4.5,1.4-7.8,4.1-9.9,8.2c-2.2,9.9-4.6,19.6-7.7,29.2
								c-3.1,9.7-6.7,19.2-11.1,28.4c-3.5,7.4-7.4,14.7-11.5,21.8c-6.1,10.5-13.5,20.1-20.9,29.6c-1.3,2.6-1.1,5,0.7,7.3
								c3.1,2.7,6.8,3.6,11,2.9c6.9-0.1,13.7-0.7,20.5-1.8C904.2,1288.5,907.7,1286.9,910.2,1283.7z"/>
							<path opacity="0.1091" fill="#131313" d="M870.5,1310.7c7.3-6.1,13.4-13.2,18.3-21.3c2.5-3.8,4.9-7.7,7.4-11.5
								c8.4-13.8,15-28.8,20.6-44c2.6-7.1,5.1-14.3,7.1-21.6c2.5-8.8,4.4-17.4,6.2-26.3c0.2-1,0.4-2,0.6-3c-0.3-1.2-0.5-2.4-0.8-3.6
								c-0.6-1.1-1.2-2.2-1.8-3.2c-1.1-1.6-2.6-2.2-4.3-1.8c-3.1,0.1-6,1.2-8.8,2.5c-1.4,0.4-2.6,1.2-3.6,2.3
								c-1.6,0.9-2.2,2.3-2,4.2c-0.9,4.3-1.8,8.7-2.6,13c0.2-1,0.5-2,0.7-3c-1.3,5.3-2.7,10.6-4,15.9c0.3-1,0.5-2,0.8-2.9
								c-1.6,5.2-3.1,10.4-4.7,15.6c0.3-1,0.6-1.9,0.9-2.9c-2.7,8.4-5.9,16.7-9.5,24.8c-2.6,6.1-5.5,12-8.8,17.7
								c-3,5.9-6.4,11.5-10.1,17c-4.5,7.8-10.1,14.8-16.7,20.9c-1.2,2-1.7,4.1-1.3,6.4c0,2.3,0.9,4.4,2.4,6.1c2.3,1.1,4.7,1.4,7.2,1
								C866.1,1312.9,868.4,1312.2,870.5,1310.7z"/>
							<path opacity="0.1091" fill="#131313" d="M890.3,1342.9c5.1-3,9.2-6.9,12.5-11.6c2.9-3.9,5.6-8,8.2-12.1
								c6.1-9.6,11.8-19.5,16.7-29.8c5.1-10.5,9.6-21.3,13.3-32.4c1.4-4.5,2.8-9,4.2-13.5c2.6-9.2,4.8-18.5,6.6-27.8
								c-0.1-2.6-1-4.9-2.8-6.8c-2-1.9-4.4-2.3-7.1-1.2c-3.8,1-7.5,2.5-10.9,4.4c-2.7,1.1-4.1,3.1-4.2,5.9
								c-2.1,8.8-4.7,17.5-7.7,26.1c-2.2,6.4-4.7,12.7-7.4,18.9c-2.6,6.2-5.4,12.3-8.6,18.3c-4.1,7.9-8.5,15.6-13.3,23.1
								c0.6-1,1.3-1.9,1.9-2.9c-3.1,4.6-6.1,9.2-9.2,13.8c0.7-0.9,1.3-1.9,2-2.8c-3,4.4-6.1,8.7-9.1,13.1c0.7-0.9,1.4-1.9,2-2.8
								c-3.5,3.2-7,6.4-10.5,9.7c-3.6,3.3-3.3,9.9,1.1,12.5c3.7,1,7.5,1.2,11.3,0.7C883.2,1345.3,886.9,1344.5,890.3,1342.9z"/>
							<path opacity="0.1091" fill="#131313" d="M924.6,1309c6.6-8.6,13.1-17.3,18.8-26.6c6-9.7,11.4-19.8,15.9-30.3
								c3.7-8.5,7-17.2,9.9-26.1c4.5-13.5,7.5-27.2,10.2-41.1c0.9-3-0.1-5.3-2.8-6.8c-4.7-1.4-9.3-0.9-13.8,1.5
								c-8.1,2.7-16.1,6-23.8,9.7c-4.9,1.5-8.5,4.3-10.9,8.6c-2.9,9.9-6,19.7-9.8,29.3c-3.8,9.6-8,19.1-13,28.2
								c-4,7.3-8.2,14.5-12.6,21.5c-6.5,10.2-14.1,19.7-21.4,29.3c-1.7,2.6-1.5,5.1,0.6,7.3c4.1,2.7,8.7,3.6,13.8,2.6
								c8.7-0.2,17.3-1,25.9-2.3C916.7,1314,921,1312.4,924.6,1309z"/>
							<path opacity="0.1091" fill="#131313" d="M877.9,1299.6c6.6-3.9,11.8-9.1,15.5-15.6c1.9-2.6,3.6-5.3,5.3-8.1
								c4.8-7.8,9.4-15.2,13.4-23.5c6.1-12.4,11.6-25.2,15.7-38.4c1.3-4.5,2.7-9,4-13.5c2.5-9.1,4.5-18.3,6.1-27.6
								c0-2.7-0.9-4.9-2.8-6.8c-2.4-1.9-5-2.2-7.9-0.9c-4.4,1.2-8.5,2.9-12.5,5.2c-3,1.1-4.7,3.2-5,6.2c-1.4,8.4-3.3,16.8-5.5,25
								c-1.6,6.2-3.6,12.3-5.9,18.3c-2.1,6.1-4.4,12.1-7.1,17.9c-3.5,7.8-7.4,15.4-11.7,22.9c0.6-1,1.1-1.9,1.7-2.9
								c-2.8,4.6-5.6,9.2-8.4,13.8c0.6-0.9,1.2-1.9,1.7-2.8c-4,8.1-9.8,14.7-17.2,19.9c-3.3,3.1-2.7,10,1.1,12.5
								c3.2,1,6.5,1.3,9.8,0.8C871.8,1302,874.9,1301.2,877.9,1299.6z"/>
							<path opacity="0.1091" fill="#131313" d="M900.2,1294.9c6.5-8.6,12.8-17.2,18.5-26.4c6-9.7,11.4-19.7,16-30.1
								c3.7-8.5,7.1-17.2,10.1-26c4.6-13.4,7.6-27.1,10.5-41c0.4-2.8-0.5-5.1-2.8-6.8c-3.5-1.7-7-1.6-10.6,0.2
								c-6.1,1.9-12,4.3-17.7,7.3c-3.9,1.2-6.4,3.7-7.7,7.3c-0.9,4-1.8,8.1-2.7,12.1c-1.5,6.1-3.2,12-5.3,17.9
								c-3.2,9.9-7,19.7-11.5,29.1c-3.6,7.6-7.6,15-11.8,22.2c-6.2,10.5-13.5,20.2-20.9,29.9c-1.1,2.6-0.9,5,0.7,7.3
								c2.5,2.6,5.6,3.6,9.3,3c5.8,0,11.6-0.5,17.3-1.5C895.3,1299.5,898.2,1298,900.2,1294.9z"/>
							<path opacity="0.1091" fill="#131313" d="M921.3,1324c6.6-8.6,13-17.2,18.7-26.5c5.9-9.7,11.3-19.7,15.7-30.2
								c3.6-8.5,6.9-17.2,9.8-25.9c4.5-13.4,7.4-27.1,10.1-40.9c0.6-2.9-0.3-5.2-2.8-6.8c-4.1-1.5-8.1-1.3-12.2,0.8
								c-7.1,2.3-14,5.1-20.7,8.4c-4.4,1.3-7.5,4-9.3,7.9c-1.3,4.1-2.5,8.1-3.8,12.2c-2,6.1-4.2,12-6.8,17.9
								c-4.1,9.8-8.6,19.5-13.7,28.8c-4.1,7.4-8.5,14.7-13.1,21.8c-6.7,10.3-14.4,19.8-21.9,29.4c-1.6,2.6-1.4,5,0.6,7.3
								c3.8,2.7,8.1,3.6,12.9,2.7c8.1-0.1,16.1-0.8,24.1-2.1C913.9,1328.9,918,1327.3,921.3,1324z"/>
							<path opacity="0.1091" fill="#131313" d="M872.6,1323.6c5.7-4.5,10.5-9.8,14.3-15.9c4-5.5,7.7-11.1,11.2-17
								c5.8-10,10.8-20.3,15.3-30.9c4.5-10.8,8.6-21.8,11.7-33.1c2.5-9.2,4.6-18.4,6.5-27.7c0.8-2.2,0.7-4.4-0.3-6.6
								c-0.7-2.3-2.1-4-4.2-5.1c-2.7-0.3-5.3,0.1-7.8,1.2c-2.6,0.8-4.8,2.3-6.6,4.3c-1.1,5.4-2.2,10.7-3.3,16.1c0.2-1,0.4-2,0.6-2.9
								c-1.4,5.3-2.7,10.6-4.1,15.9c0.2-1,0.5-1.9,0.7-2.9c-2.4,8.6-5.2,17-8.5,25.3c-3.2,8.3-6.9,16.3-10.9,24.2
								c-4,7.9-8.5,15.4-13.4,22.8c-4.6,7.8-10.2,14.8-16.8,20.9c-1.3,1.9-1.7,4.1-1.4,6.4c0,2.4,0.9,4.4,2.5,6.1
								c2.4,1.1,4.8,1.4,7.4,1C868.1,1325.8,870.4,1325.1,872.6,1323.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M920,1315.1c2.8-13.4,4.1-26.9,4.1-40.5c0.3-6.6,0.7-13.3,1-19.9
								c1.2-23.2,2.3-46.5,3.3-69.7c1.9-43.2,3.5-86.4,4.9-129.7c0.6-2.6-0.1-4.9-2-6.8c-1.8-2-4-2.7-6.7-2.3
								c-3.8,1.8-7.1,4.2-10,7.3c-3.2,2.8-5.7,6-7.6,9.7c-1.5,43.2-3.1,86.3-5,129.5c-0.9,19.9-1.8,39.8-2.9,59.7
								c-0.5,10-1,19.9-1.5,29.9c0,13.2-1.2,26.3-3.6,39.2c-0.6,2.6,0,4.8,1.8,6.8c1.7,2,3.9,2.9,6.5,2.6c3.7-1.6,7-3.8,9.9-6.6
								C915.4,1321.6,918,1318.6,920,1315.1z"/>
							<path opacity="0.1091" fill="#131313" d="M896.4,1299.2c4.2-13.7,6.1-27.6,5.7-41.9c0.3-6.6,0.7-13.3,1-19.9
								c1.2-23.2,2.3-46.5,3.3-69.7c1.9-43.2,3.5-86.4,4.9-129.6c0.4-2.6-0.3-4.8-2.2-6.6c-1.8-2-3.9-2.8-6.5-2.4
								c-3.5,1.6-6.6,3.8-9.2,6.6c-2.9,2.6-5.2,5.6-6.9,9c-1.4,43.1-3,86.2-4.9,129.3c-0.9,19.9-1.8,39.8-2.8,59.7
								c-0.5,10-1,19.9-1.5,29.9c0.4,13.7-1.2,27.1-4.8,40.3c-0.9,4.6,3.6,9.8,8.3,9.3c3.4-1.3,6.4-3.2,8.9-5.7
								C892.5,1305.1,894.8,1302.4,896.4,1299.2z"/>
							<path opacity="0.1091" fill="#131313" d="M902.2,1259.1c5.1-86.1,8.7-172.2,11.4-258.4c-0.6-2.9-2.3-4.6-5.1-5.3
								c-4.1-0.1-7.6,1.5-10.4,4.8c-5.4,4.5-10.4,9.3-15,14.5c-3.4,2.7-5.1,6.2-5.1,10.3c-1.4,43-3,86.1-4.9,129.1
								c-0.9,19.9-1.8,39.7-2.8,59.6c-1.1,22.5-2.5,45-3.8,67.6c0.5,2.8,2.1,4.7,4.9,5.5c4,0.3,7.5-1.1,10.4-4.2
								c5.4-4.1,10.5-8.6,15.1-13.6C900.3,1266.4,902.1,1263.1,902.2,1259.1z"/>
							<path opacity="0.1091" fill="#131313" d="M920.1,1286c2.2-42.9,4.5-85.7,6.4-128.6c1.9-43.2,3.5-86.4,4.8-129.6
								c-0.6-2.8-2.3-4.6-5.1-5.3c-4-0.2-7.3,1.3-10.1,4.4c-5.1,4.2-9.9,8.8-14.3,13.8c-3.2,2.6-4.8,5.9-4.7,9.9
								c-1.5,43.1-3.1,86.2-5.1,129.3c-0.9,19.9-1.9,39.8-2.9,59.6c-1.1,22.5-2.5,45-3.7,67.5c0.5,2.8,2.2,4.6,4.9,5.5
								c3.9,0.4,7.3-0.9,10.1-3.9c5.2-3.9,10.1-8.3,14.6-13C918.4,1293.1,920,1289.9,920.1,1286z"/>
							<path opacity="0.1091" fill="#131313" d="M923.3,1255c1-19.7,2.2-39.4,3.2-59.1c1.2-23.2,2.3-46.5,3.3-69.8
								c1.9-43.2,3.5-86.5,4.9-129.7c-0.2-3.2-1.9-5-5.1-5.3c-5.2,0.8-9.5,3.2-13.1,7.3c-7.1,6.1-13.7,12.5-20,19.4
								c-4.2,3.4-6.8,7.7-7.7,12.8c-1.5,43-3.1,86.1-5.1,129.1c-0.9,19.9-1.8,39.7-2.9,59.6c-0.5,9.9-1,19.9-1.5,29.8
								c-1,12.5-1.8,24.9-2.3,37.4c0.1,3.2,1.7,5,4.9,5.5c5.1-0.5,9.5-2.7,13.1-6.6c7.2-5.7,13.9-11.7,20.3-18.2
								C919.6,1264.1,922.2,1260,923.3,1255z"/>
							<path opacity="0.1091" fill="#131313" d="M883.3,1285.7c3.4-13.7,5.2-27.6,5.4-41.8c0.3-6.6,0.7-13.2,1-19.9
								c1.2-23.2,2.2-46.5,3.3-69.7c1.9-43.2,3.4-86.4,4.8-129.6c0.3-2.5-0.6-4.6-2.4-6.4c-1.7-1.9-3.8-2.8-6.3-2.6
								c-3.3,1.3-6.1,3.3-8.5,5.9c-2.7,2.3-4.7,5.1-6.2,8.3c-1.4,43.1-2.9,86.2-4.8,129.3c-0.9,19.9-1.8,39.8-2.8,59.7
								c-0.5,9.9-1,19.8-1.4,29.7c-0.2,13.6-1.6,27-4.5,40.2c-0.7,4.3,3.9,9.6,8.4,9.3c3.1-1,5.8-2.7,8.1-5
								C880,1291.1,881.9,1288.6,883.3,1285.7z"/>
							<path opacity="0.1091" fill="#131313" d="M905.7,1316.5c2.3-13.7,3.6-27.5,4-41.4c0.4-6.7,0.8-13.4,1.2-20
								c1.3-23.3,2.3-46.5,3.3-69.8c1.9-43.2,3.4-86.4,4.8-129.7c0.1-4.2-4-9.5-8.5-9.2c-2.9,0.9-5.4,2.5-7.5,4.6
								c-2.4,1.9-4.1,4.3-5.3,7.1c-1.4,43.2-3,86.3-4.9,129.5c-0.9,19.9-1.8,39.8-2.8,59.7c-0.5,10-1,20-1.7,30
								c-0.2,13.6-1.3,27.2-3.2,40.6c0,2.4,0.8,4.4,2.5,6.1c1.5,1.9,3.4,3,5.8,3.2c2.8-0.7,5.2-2.1,7.2-4.2
								C903,1321.4,904.6,1319.2,905.7,1316.5z"/>
							<path opacity="0.1091" fill="#131313" d="M934.9,1281.6c2.2-42.9,4.6-85.8,6.5-128.8c1.9-43.2,3.4-86.4,4.8-129.6
								c-0.3-3.1-2.1-4.8-5.1-5.3c-4.8,0.4-8.8,2.5-12,6.3c-6.4,5.4-12.4,11.2-18,17.4c-3.9,3.1-6.1,7-6.7,11.8
								c-1.5,43.1-3.2,86.2-5.2,129.2c-0.9,19.9-1.9,39.8-2.9,59.6c-1.1,22.5-2.6,44.9-3.8,67.3c0.2,3.1,1.9,4.9,4.9,5.5
								c4.7-0.2,8.8-2.1,12.1-5.7c6.5-5.1,12.7-10.5,18.5-16.4C931.9,1290.1,934.2,1286.3,934.9,1281.6z"/>
							<path opacity="0.1091" fill="#131313" d="M892.9,1270.1c2.9-42.9,4.8-85.8,6.6-128.7c1.9-43.2,3.4-86.4,4.8-129.6
								c-0.8-2.6-2.5-4.4-5.1-5.3c-3.4-0.5-6.4,0.5-8.9,3.3c-4.4,3.5-8.3,7.3-11.9,11.6c-2.8,2.4-4,5.3-3.5,8.8
								c-1.4,43.1-3,86.1-4.9,129.2c-1.9,42.5-3.6,85-6.7,127.4c0.7,2.6,2.4,4.5,4.9,5.5c3.4,0.7,6.3-0.2,8.8-2.8
								c4.4-3.2,8.4-6.8,12.1-10.8C892.1,1276.3,893.3,1273.5,892.9,1270.1z"/>
							<path opacity="0.1091" fill="#131313" d="M911.3,1268.2c4.9-86.1,8.6-172.2,11.4-258.4c-0.5-2.9-2.2-4.7-5.1-5.3
								c-4.3,0-7.9,1.8-10.9,5.2c-5.6,4.7-10.9,9.8-15.8,15.3c-3.5,2.8-5.4,6.4-5.5,10.7c-1.4,43.1-3.1,86.1-5,129.1
								c-0.9,19.9-1.8,39.7-2.8,59.6c-1.1,22.5-2.5,45-3.8,67.5c0.4,2.9,2.1,4.7,4.9,5.5c4.2,0.2,7.8-1.4,10.9-4.6
								c5.7-4.4,11.1-9.1,16-14.3C909.1,1275.8,911,1272.4,911.3,1268.2z"/>
							<path opacity="0.1091" fill="#131313" d="M931.5,1297.4c2.2-42.9,4.6-85.7,6.4-128.6c1.9-43.2,3.4-86.4,4.8-129.6
								c-0.6-2.8-2.3-4.6-5.1-5.3c-4-0.2-7.4,1.3-10.2,4.5c-5.2,4.2-10,8.9-14.5,13.9c-3.3,2.6-4.9,6-4.9,10
								c-1.5,43.1-3.2,86.2-5.2,129.4c-0.9,19.9-1.9,39.8-2.9,59.7c-1.2,22.5-2.5,45-3.8,67.5c0.5,2.8,2.1,4.7,4.9,5.5
								c4,0.4,7.4-1,10.3-4c5.3-4,10.3-8.4,14.8-13.2C929.6,1304.6,931.4,1301.3,931.5,1297.4z"/>
							<path opacity="0.1091" fill="#131313" d="M886.4,1297.6c2.4-13.7,3.7-27.5,4-41.4c0.4-6.7,0.8-13.4,1.2-20
								c1.3-23.3,2.3-46.5,3.3-69.8c1.9-43.2,3.5-86.4,4.9-129.7c0.1-4.2-4-9.4-8.5-9.2c-2.9,0.9-5.3,2.4-7.4,4.6
								c-2.3,1.9-4.1,4.3-5.2,7c-1.4,43.1-3,86.3-4.8,129.4c-0.9,19.9-1.8,39.8-2.8,59.7c-0.5,10-1,20-1.6,29.9
								c-0.2,13.6-1.3,27.2-3.2,40.7c0,2.4,0.9,4.4,2.6,6.1c1.5,1.9,3.4,3,5.7,3.3c2.7-0.7,5-2,7-4
								C883.8,1302.4,885.4,1300.2,886.4,1297.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M1002.2,1136.6c2.1-9.4,2.9-18.9,2.3-28.5c0.5-8.5,1.5-16.9,3-25.3
								c0.8-5-3.8-9.8-9-8.8c-3.6,1.9-6.8,4.3-9.6,7.4c-3,2.8-5.4,6-7.2,9.7c-1.2,8.7-1.9,17.4-2.1,26.1c0.8,9.2,0.3,18.4-1.4,27.6
								c-0.5,4.8,4.8,9.3,9.5,8.2c3.3-1.8,6.1-4.2,8.4-7.2C998.9,1143.2,1000.9,1140.1,1002.2,1136.6z"/>
							<path opacity="0.1091" fill="#131313" d="M979.5,1120c2.4-9.5,3.3-19.2,2.7-29c0.5-8.5,1.6-17,3.2-25.4
								c0.8-4.9-4.1-9.7-9-8.7c-3.5,1.7-6.5,4-9.1,6.9c-2.9,2.6-5.1,5.7-6.7,9.2c-1.5,8.9-2.4,17.8-2.8,26.7
								c0.7,9.4,0,18.8-1.8,28.1c-0.6,4.8,5,9.2,9.5,8.2c3.2-1.7,6-4.1,8.2-7C976.3,1126.5,978.2,1123.5,979.5,1120z"/>
							<path opacity="0.1091" fill="#131313" d="M984.6,1079.5c-0.6-6.5-0.7-12.9-0.3-19.4c0.1-6.5,0.6-12.9,1.5-19.3
								c-0.2,1.5-0.4,3-0.6,4.4c0.6-4.2,1.3-8.4,1.9-12.5c0.2-1.5,0.4-2.9,0.7-4.4c-0.6-1.1-1.2-2.3-1.8-3.4
								c-1.2-0.6-2.3-1.1-3.5-1.7c-3-0.1-5.4,0.9-7.4,3.1c-5.3,3.9-9.9,8.7-14.3,13.6c-2.2,2.1-4.1,4.5-5.7,7.1
								c-2.1,2-3,4.5-2.8,7.5c-1.4,9.2-2.2,18.5-2.5,27.9c-0.5,8.6-0.4,17.2,0.4,25.8c0.8,2.8,2.7,4.4,5.6,4.8
								c4.2-0.4,7.7-2.4,10.2-6c5.1-5.1,9.9-10.6,14.2-16.4C983.3,1087.4,984.8,1083.7,984.6,1079.5z"/>
							<path opacity="0.1091" fill="#131313" d="M1002.3,1106.7c-0.8-8.6-0.8-17.2-0.1-25.7c-0.1,1.4-0.2,2.8-0.2,4.2
								c0.5-8.5,1.5-17,3.1-25.4c0.2-1.4,0.3-2.8,0.5-4.2c-0.6-1.1-1.2-2.2-1.9-3.4c-1.1-0.6-2.3-1.2-3.4-1.8
								c-2.8-0.3-5.1,0.6-7,2.6c-5,3.6-9.3,8-13.3,12.5c-2.1,2-3.8,4.2-5.3,6.6c-2,1.9-2.8,4.3-2.4,7.1c-0.7,6.7-1.3,13.4-1.6,20.2
								c-0.3,4.4-0.4,8.9-0.3,13.3c-0.2,6.3,0.1,12.5,0.7,18.8c0.9,2.7,2.8,4.3,5.6,4.8c3.9-0.1,7-1.8,9.4-5.1
								c4.6-4.5,8.8-9.3,12.6-14.6C1001.5,1113.9,1002.8,1110.6,1002.3,1106.7z"/>
							<path opacity="0.1091" fill="#131313" d="M1005.5,1075c-0.8-8.6-0.8-17.2-0.1-25.8c0.6-8.4,1.7-16.8,3.4-25.1
								c-0.3-3.2-2.1-4.9-5.3-5.1c-5.2,1-9.5,3.6-12.9,7.9c-6.9,6.4-13.5,13.1-19.6,20.3c-4.2,3.6-6.6,8-7.4,13.2
								c-1.1,9.3-1.7,18.6-1.9,27.9c-0.5,8.5-0.3,17,0.7,25.5c0.5,3.2,2.3,4.8,5.6,4.8c5.1-1.2,9.3-4.1,12.5-8.6
								c6.6-6.8,12.7-13.9,18.5-21.3C1002.8,1084.7,1005,1080.2,1005.5,1075z"/>
							<path opacity="0.1091" fill="#131313" d="M966.5,1105.9c1.5-9.3,2.1-18.6,1.9-28c0.5-8.6,1.7-17.1,3.3-25.5
								c0.8-4.7-4.5-9.5-9-8.7c-3.3,1.5-6.1,3.6-8.4,6.3c-2.6,2.5-4.7,5.3-6.1,8.6c-1.7,9-2.8,18-3.3,27.1
								c0.3,9.2-0.1,18.3-1.2,27.4c-0.4,4.6,5,9.2,9.5,8.2c3.1-1.6,5.7-3.8,7.9-6.6C963.6,1112.2,965.4,1109.3,966.5,1105.9z"/>
							<path opacity="0.1091" fill="#131313" d="M988,1137.4c0.8-9.4,1.3-18.8,1.6-28.3c0.7-8.6,1.9-17.1,3.6-25.6
								c-0.1-2.4-1-4.4-2.8-6c-1.6-1.8-3.6-2.8-6-2.8c-2.8,1-5.2,2.6-7.2,4.9c-2.3,2-3.9,4.4-4.9,7.2c-1.5,8.7-2.6,17.5-3.1,26.3
								c-0.1,9.2-0.3,18.5-0.6,27.7c0.4,2.3,1.5,4.2,3.4,5.6c1.7,1.7,3.7,2.5,6.1,2.6c2.5-1,4.6-2.5,6.3-4.7
								C986.2,1142.4,987.4,1140.1,988,1137.4z"/>
							<path opacity="0.1091" fill="#131313" d="M1016.8,1102.5c-0.7-8.6-0.7-17.3,0-25.9c0.6-8.6,1.8-17.1,3.5-25.5
								c-0.5-3-2.2-4.8-5.3-5.1c-4.7,0.5-8.6,2.7-11.7,6.6c-6.2,5.5-11.9,11.5-17.3,17.8c-3.8,3.2-5.9,7.2-6.2,11.9
								c-0.9,9-1.4,18-1.4,27c-0.3,8.5,0,16.9,0.8,25.3c0.7,3,2.5,4.6,5.6,4.8c4.6-0.7,8.2-3,11.1-6.9c5.7-5.7,10.9-11.7,15.7-18.1
								C1015,1111,1016.8,1107.1,1016.8,1102.5z"/>
							<path opacity="0.1091" fill="#131313" d="M975.3,1090.6c-0.2-4.2-0.4-8.5-0.6-12.7c0,1.3,0,2.6,0.1,3.9
								c-0.1-6.5,0.2-12.9,1-19.4c0.5-6.4,1.3-12.8,2.6-19.1c0.8-2.7,0.2-5.1-1.8-7.1c-2.1-2-4.4-2.5-7.2-1.6
								c-4.2,2.5-8,5.5-11.2,9.2c-3.6,3.4-6.5,7.2-8.8,11.5c-0.6,4.6-1.3,9.2-1.9,13.8c-0.8,6.8-1.2,13.6-1.2,20.4
								c-0.2,7.9,0.1,14.9,0.6,22.9c1,2.6,2.9,4.2,5.6,4.8c3.6,0.2,6.5-1.3,8.8-4.3c4.2-4,7.9-8.4,11.3-13.1
								C975,1097.2,976,1094.1,975.3,1090.6z"/>
							<path opacity="0.1091" fill="#131313" d="M993.6,1088.7c-0.6-6.5-0.7-12.9-0.3-19.4c0.1-6.5,0.6-12.9,1.5-19.3
								c-0.2,1.5-0.4,3-0.6,4.5c0.9-5.7,1.8-11.4,2.7-17.1c-0.6-2.9-2.4-4.6-5.3-5.1c-4.3,0.2-7.9,2.1-10.8,5.6
								c-5.5,4.9-10.7,10.3-15.5,16c-3.4,3-5.2,6.6-5.3,10.9c-1.2,9.2-1.9,18.3-2.1,27.6c-0.4,8.6-0.2,17.1,0.5,25.6
								c0.8,2.9,2.6,4.5,5.6,4.8c4.3-0.5,7.8-2.5,10.4-6.2c5.3-5.2,10.1-10.8,14.5-16.8C992.2,1096.8,993.8,1093,993.6,1088.7z"/>
							<path opacity="0.1091" fill="#131313" d="M1013.6,1118.1c-0.2-4.2-0.4-8.5-0.6-12.7c0,1.4-0.1,2.8-0.1,4.1
								c0.2-5.7,0.4-11.4,0.6-17.1c-0.1,1.4-0.1,2.8-0.2,4.2c0.5-8.5,1.5-17,3.1-25.4c0.2-1.4,0.3-2.8,0.5-4.2
								c-0.6-1.1-1.2-2.2-1.9-3.4c-1.1-0.6-2.3-1.2-3.4-1.8c-2.8-0.3-5.1,0.6-7,2.6c-5,3.6-9.3,7.9-13.3,12.5
								c-2.1,2-3.8,4.2-5.3,6.6c-2,1.9-2.8,4.3-2.5,7.1c-0.5,6.6-1.1,13.2-1.3,19.9c-0.2,4.4-0.3,8.8-0.2,13.2
								c-0.1,6.2,0.2,12.5,0.8,18.7c0.9,2.7,2.8,4.3,5.6,4.8c3.8,0,6.9-1.6,9.3-4.9c4.5-4.4,8.7-9.1,12.4-14.2
								C1013,1125.2,1014.1,1121.9,1013.6,1118.1z"/>
							<path opacity="0.1091" fill="#131313" d="M969.2,1118c0.8-9.4,1.3-18.8,1.5-28.1c0.7-8.5,1.9-17,3.5-25.5
								c0-2.4-1-4.4-2.8-6.1c-1.6-1.8-3.7-2.8-6.1-2.8c-2.9,1-5.3,2.7-7.3,5c-2.3,2-3.9,4.5-5,7.4c-1.6,8.8-2.8,17.7-3.4,26.6
								c-0.1,9.3-0.4,18.6-0.8,27.8c0.3,2.4,1.4,4.3,3.3,5.8c1.7,1.7,3.8,2.5,6.2,2.4c2.7-1.1,4.9-2.9,6.7-5.2
								C967.1,1123.3,968.5,1120.8,969.2,1118z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M996,1170.1c0.8-0.7,1.6-1.5,2.5-2.2c1.5-2.5,1.5-4.9-0.1-7.4
								c-2.8-2.9-6.4-4.1-10.6-3.7c-6.8-0.5-13.6-0.4-20.3,0.2c-4.2-0.3-7.7,1-10.5,3.9c-0.6,0.6-1.3,1.2-1.9,1.8
								c-1.5,2.5-1.5,4.9,0,7.4c2.8,2.9,6.2,4.2,10.4,3.8c6.7,0.6,13.4,0.6,20.1,0C989.7,1174.2,993.2,1173,996,1170.1z"/>
							<path opacity="0.1091" fill="#131313" d="M968,1155.7c2.4-0.8,4.8-1.6,7.2-2.4c2.1-1.6,3-3.7,2.9-6.3
								c0.1-2.6-0.9-4.7-2.9-6.3c-3.6-1.3-7.3-1.8-11.1-1.6c-3.8-0.2-7.5,0.4-11.1,1.7c-2.1,0.8-4.3,1.6-6.4,2.4
								c-2,1.6-3,3.7-2.8,6.3c-0.1,2.6,0.8,4.7,2.8,6.3c3.4,1.3,7,1.9,10.7,1.7C961,1157.5,964.5,1157,968,1155.7z"/>
							<path opacity="0.1091" fill="#131313" d="M971.8,1121.6c2.3-0.8,4.6-1.6,6.9-2.4c2.1-1.6,3.1-3.6,3-6.3
								c0.1-2.6-0.9-4.7-3-6.2c-3.7-1.3-7.4-1.8-11.3-1.6c-3.9-0.2-7.7,0.4-11.3,1.7c-2.1,0.8-4.1,1.5-6.2,2.3c-2,1.6-3,3.7-2.9,6.3
								c-0.1,2.6,0.9,4.7,2.9,6.3c3.5,1.3,7.2,1.8,11,1.6C964.6,1123.5,968.2,1123,971.8,1121.6z"/>
							<path opacity="0.1091" fill="#131313" d="M994.6,1145.6c0.8-0.7,1.5-1.5,2.3-2.2c1.5-2.5,1.5-4.9-0.1-7.4
								c-2.8-2.9-6.3-4.1-10.5-3.7c-6.7-0.5-13.4-0.4-20.1,0.2c-4.2-0.3-7.7,1-10.4,3.9c-0.6,0.6-1.2,1.2-1.8,1.8
								c-1.5,2.5-1.5,4.9,0,7.4c2.7,2.9,6.2,4.2,10.4,3.8c6.7,0.6,13.3,0.6,20,0C988.4,1149.8,991.9,1148.6,994.6,1145.6z"/>
							<path opacity="0.1091" fill="#131313" d="M996,1116.7c0.8-0.7,1.5-1.5,2.3-2.2c1.5-2.5,1.4-4.9-0.1-7.4
								c-2.7-2.9-6.2-4.1-10.3-3.7c-6.6-0.5-13.2-0.4-19.8,0.3c-4.1-0.3-7.6,1-10.2,4c-0.6,0.6-1.2,1.2-1.9,1.8
								c-1.5,2.5-1.5,4.9,0,7.4c2.7,2.9,6.1,4.2,10.2,3.8c6.5,0.5,13.1,0.5,19.6,0C989.9,1120.9,993.3,1119.6,996,1116.7z"/>
							<path opacity="0.1091" fill="#131313" d="M952.9,1144.6c2.1-0.8,4.3-1.6,6.4-2.4c2.4-0.3,3.8-1.5,4.4-3.8
								c0.6-2.5,0.6-4.9,0-7.4c-0.6-2.3-2-3.6-4.4-3.8c-2.8-0.6-5.7-0.6-8.5,0c-2,0.8-4,1.6-6.1,2.4c-2.3,0.3-3.7,1.6-4.2,3.8
								c-0.6,2.5-0.6,4.9,0,7.4c0.5,2.2,1.9,3.5,4.2,3.8C947.5,1145.2,950.2,1145.2,952.9,1144.6z"/>
							<path opacity="0.1091" fill="#131313" d="M976.4,1175.5c1.9-0.8,3.9-1.5,5.8-2.3c2.2-1.5,3.3-3.6,3.2-6.3
								c0-2.7-1-4.8-3.3-6.2c-4-1.3-8-1.8-12.2-1.6c-4.2-0.2-8.2,0.4-12.2,1.8c-1.7,0.7-3.3,1.3-5,2c-2.2,1.5-3.2,3.6-3.1,6.3
								c-0.1,2.7,1,4.8,3.1,6.3c3.8,1.3,7.8,1.9,11.8,1.7C968.6,1177.3,972.6,1176.8,976.4,1175.5z"/>
							<path opacity="0.1091" fill="#131313" d="M1010.2,1142.1c0.6-0.8,1.3-1.5,1.9-2.3c1.9-2.5,1.9-4.9-0.1-7.4
								c-3.9-3-8.4-4.2-13.6-3.7c-8.7-0.5-17.4-0.4-26,0.3c-5.1-0.4-9.6,0.9-13.5,4c-0.4,0.6-0.9,1.2-1.3,1.8
								c-1.9,2.5-1.9,4.9,0,7.4c3.8,3,8.3,4.3,13.4,3.8c8.6,0.6,17.2,0.5,25.8,0C1001.9,1146.4,1006.4,1145.1,1010.2,1142.1z"/>
							<path opacity="0.1091" fill="#131313" d="M962.9,1132.2c2.3-0.8,4.5-1.6,6.8-2.4c1.8-1.7,2.7-3.8,2.5-6.3
								c0.1-2.5-0.7-4.6-2.6-6.3c-3.1-1.3-6.3-1.8-9.6-1.6c-3.3-0.2-6.6,0.4-9.6,1.7c-2,0.8-4.1,1.6-6.1,2.4
								c-1.8,1.7-2.6,3.8-2.5,6.3c-0.1,2.5,0.7,4.6,2.5,6.3c3,1.3,6.1,1.8,9.3,1.6C956.9,1134.1,960,1133.5,962.9,1132.2z"/>
							<path opacity="0.1091" fill="#131313" d="M983,1130.5c0.9-0.7,1.8-1.4,2.7-2.2c2.5-1.4,3.8-3.5,3.7-6.3
								c-0.1-2.9-1.4-4.9-3.9-6.2c-4.7-1.2-9.6-1.7-14.5-1.5c-4.9-0.1-9.7,0.5-14.4,1.9c-0.8,0.6-1.6,1.2-2.4,1.8
								c-2.5,1.3-3.8,3.4-3.8,6.3c0,2.8,1.3,4.9,3.8,6.3c4.7,1.3,9.5,1.9,14.4,1.6C973.5,1132.4,978.3,1131.8,983,1130.5z"/>
							<path opacity="0.1091" fill="#131313" d="M1007.1,1157c0.7-0.8,1.3-1.5,2-2.3c1.8-2.5,1.8-4.9-0.1-7.4c-3.7-3-8-4.2-12.9-3.7
								c-8.3-0.5-16.6-0.4-24.9,0.2c-4.9-0.4-9.2,0.9-12.9,3.9c-0.5,0.6-1,1.2-1.5,1.8c-1.9,2.5-1.9,4.9,0,7.4
								c3.6,3,7.9,4.3,12.8,3.8c8.2,0.6,16.4,0.6,24.6,0C999.2,1161.3,1003.5,1160,1007.1,1157z"/>
							<path opacity="0.1091" fill="#131313" d="M955.1,1157.5c2.1-0.8,4.2-1.6,6.4-2.4c1.1-0.4,2.1-0.8,3.2-1.2
								c0.5-0.9,1-1.7,1.5-2.6c0.2-1.2,0.3-2.5,0.5-3.7c0.2-1.9-0.2-3.5-1.3-5c-0.3-1.2-1.1-1.8-2.3-1.9c-1.2-0.6-2.5-0.9-3.8-0.8
								c-1.5-0.3-3-0.3-4.5,0c-1.9,0-3.7,0.4-5.4,1.4c-2,0.8-4,1.6-5.9,2.4c-1.5,1.8-2.1,3.9-2,6.3c-0.2,1.9,0.2,3.5,1.2,5
								c0.6,1.6,1.8,2.5,3.8,2.5C949.2,1158.1,952.2,1158.1,955.1,1157.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M990.6,1134.3c1.5-7.3,3.4-14.6,4.3-22.1c1.7-15.2,2.9-30.4,3.7-45.6
								c1-19.6,1-39.2,1.6-58.7c0,1.2-0.1,2.4-0.1,3.6c0.7-13.8,1.6-27.5,3.7-41.2c0.8-5.6,1.9-11.2,3.2-16.7
								c1.7-7.2,3.9-14.2,6.6-21c1-2.5,0.7-4.9-0.9-7.1c-1.6-2.2-3.7-3.3-6.4-3.1c-4.1,1.4-7.9,3.4-11.3,6.2
								c-3.6,2.4-6.8,5.4-9.3,8.9c-2.4,7.3-4.3,14.8-5.9,22.3c-0.7,3.8-1.4,7.6-2.1,11.4c-2.3,13.6-3.3,27.5-4,41.3
								c-0.7,13.3-1.2,26.6-1.7,39.9c-0.2,6.2-0.5,12.4-0.8,18.6c-0.7,12.2-1.7,24.3-3,36.4c0.1-1.2,0.3-2.4,0.4-3.6
								c-0.8,7.1-1.5,14.1-2.3,21.2c0.1-1.2,0.3-2.4,0.4-3.6c-1.2,5.9-2.3,11.7-3.5,17.6c-0.9,2.5-0.6,4.8,1.1,7
								c1.6,2.2,3.7,3.2,6.4,3.1c4-1.3,7.6-3.3,10.9-6C985.2,1140.7,988.2,1137.8,990.6,1134.3z"/>
							<path opacity="0.1091" fill="#131313" d="M966.4,1118.8c5.5-12.2,8.3-25,8.3-38.3c1.1-12.3,1.9-24.6,2.4-36.9
								c0.5-14.4,0.5-28.9,0.9-43.3c0.4-13.9,1.2-27.8,3.1-41.6c1-7.6,2.4-15,4.2-22.4c1.8-7.2,4.1-14.3,6.8-21.1
								c1.7-4.6-2.6-10.2-7.4-10.1c-3.6,1-6.9,2.8-9.9,5.1c-3.2,2.1-5.8,4.7-7.9,7.8c-2.7,7.4-5,15-6.8,22.7
								c-1.8,7.7-3.2,15.5-4.2,23.3c-1.8,13.8-2.6,27.8-3,41.7c-0.5,14.2-0.5,28.3-1,42.5c-0.5,12.1-1.4,24.2-2.5,36.3
								c0,12.6-2.4,24.8-7.2,36.6c-1.3,4.5,2.7,10.1,7.5,10.1c3.4-0.9,6.5-2.5,9.3-4.8C962.1,1124.3,964.5,1121.8,966.4,1118.8z"/>
							<path opacity="0.1091" fill="#131313" d="M973,1078.8c2.3-18,4.5-35.8,5.6-53.9c0.9-14.5,1.1-28.9,1.4-43.4
								c0.2-12.2,0.4-24.4,1.2-36.5c0.3-3.9,0.6-7.9,0.9-11.8c0.7-7.7,1.8-15.3,3.2-22.9c-0.3,1.4-0.6,2.8-0.9,4.2
								c1.1-5.1,2.2-10.1,3.3-15.2c-0.3,1.4-0.6,2.8-1,4.1c1.8-7.2,4-14.3,6.8-21.2c1.5-2.6,1.5-5.1-0.2-7.6
								c-1.9-2.3-4.3-3.2-7.2-2.5c-5.1,2.1-9.8,4.9-14.2,8.4c-4.6,3.1-8.7,6.8-12.2,11c-2.9,7.8-5.2,15.8-7.1,23.9
								c-1.3,5.4-2.4,10.7-3.3,16.2c-2.7,15.8-3.9,31.8-4.6,47.8c-0.7,15.8-0.6,31.7-1.1,47.5c-0.8,23.3-3.6,46.5-6.6,69.6
								c0.3,2.8,1.8,4.8,4.4,5.9c3.8,0.8,7.3-0.2,10.3-2.9c5.5-3.4,10.6-7.2,15.5-11.5C970.7,1085.7,972.6,1082.6,973,1078.8z"/>
							<path opacity="0.1091" fill="#131313" d="M990.7,1105.7c2.3-17.9,4.5-35.8,5.6-53.9c0.9-14.5,1.1-28.9,1.4-43.4
								c0.2-12.2,0.4-24.4,1.2-36.5c0.3-4,0.6-7.9,0.9-11.9c0.7-7.7,1.8-15.4,3.2-23c-0.3,1.4-0.6,2.8-0.9,4.1
								c1.1-5.1,2.2-10.1,3.3-15.2c-0.3,1.4-0.6,2.7-0.9,4.1c1.7-7.2,4-14.3,6.7-21.2c1.6-2.6,1.6-5.2-0.1-7.7
								c-1.9-2.4-4.3-3.2-7.3-2.4c-5.2,2.2-10.1,5.1-14.5,8.6c-4.7,3.2-8.9,6.9-12.6,11.3c-1.7,5.2-3.2,10.4-4.4,15.6
								c-1.9,7.7-3.5,15.5-4.7,23.4c-2.4,15.8-3.5,31.7-4.3,47.6c-0.8,16.1-1.1,32.1-1.9,48.2c-1.2,23.5-4.1,46.7-7.2,70
								c0.3,2.8,1.7,4.8,4.4,5.9c3.9,0.7,7.3-0.2,10.4-3c5.6-3.4,10.8-7.3,15.7-11.6C988.4,1112.7,990.3,1109.6,990.7,1105.7z"/>
							<path opacity="0.1091" fill="#131313" d="M993.7,1075.2c1.6-11.9,3-23.8,4.3-35.7c1.1-12.3,1.9-24.7,2.4-37
								c0.5-14.5,0.5-29,1-43.4c0.4-13.9,1.2-27.8,3.1-41.6c1-7.5,2.4-14.9,4.2-22.2c1.8-7.1,4-14,6.8-20.8c0.1-3.2-1.3-5.1-4.4-5.9
								c-5-0.1-9.4,1.6-13.3,5.1c-7.4,4.8-14.5,10-21.3,15.7c-4.5,2.7-7.4,6.4-8.8,11.2c-2.5,8-4.6,16.1-6.3,24.3
								c-1.6,8-2.9,16.1-3.8,24.2c-1.6,13.9-2.4,27.8-3,41.7c-0.6,13.8-0.8,27.6-1.4,41.4c-0.6,11.9-1.5,23.8-2.7,35.6
								c-1.5,11.2-2.9,22.4-4.3,33.7c-0.1,3.2,1.3,5.1,4.4,5.9c5,0.1,9.4-1.6,13.3-5.1c7.4-4.8,14.4-10,21.2-15.7
								C989.4,1083.8,992.3,1080,993.7,1075.2z"/>
							<path opacity="0.1091" fill="#131313" d="M953.1,1105.6c4.6-12.7,7.4-25.8,8.4-39.3c1.2-12.1,2-24.2,2.4-36.3
								c0.5-14.4,0.4-28.7,0.8-43.1c0.4-13.9,1.1-27.8,3.1-41.6c1-7.6,2.5-15.1,4.4-22.5c1.8-7.3,4.2-14.5,7.1-21.4
								c1.6-4.2-3.2-10-7.3-10.2c-3.2,0.7-6.1,2.1-8.6,4.2c-2.8,1.8-5,4.1-6.7,6.9c-3.1,7.6-5.6,15.4-7.5,23.3
								c-1.9,7.7-3.4,15.6-4.5,23.5c-1.9,13.8-2.7,27.8-3.1,41.7c-0.4,14-0.3,28-0.7,41.9c-0.3,11.7-1.1,23.4-2.1,35.1
								c-0.9,13-3.2,25.7-7.1,38.1c-1,4.1,3.3,9.8,7.5,10c3-0.6,5.7-1.9,8-3.9C949.6,1110.5,951.6,1108.3,953.1,1105.6z"/>
							<path opacity="0.1091" fill="#131313" d="M976.2,1136.1c3.4-12.3,5.4-24.9,6.2-37.7c1.2-12.3,2.1-24.7,2.5-37.1
								c0.5-14.4,0.5-28.8,0.9-43.2c0.4-13.9,1.2-27.8,3.1-41.6c1-7.5,2.5-15,4.4-22.4c1.8-7.2,4.2-14.2,7-21.1
								c0.4-2.4-0.1-4.6-1.7-6.5c-1.3-2.1-3.2-3.3-5.6-3.7c-3.1,0.6-5.8,1.9-8.3,3.9c-2.7,1.7-4.8,3.9-6.4,6.6
								c-2.7,7.2-5,14.6-6.7,22.1c-1.8,7.6-3.2,15.2-4.2,23c-1.9,13.8-2.6,27.7-3.1,41.6c-0.5,14.1-0.6,28.3-1.2,42.4
								c-0.5,12.2-1.4,24.4-2.6,36.6c-0.7,12.5-2.5,24.8-5.4,36.9c-0.8,4.1,3.2,9.8,7.4,10.1c2.9-0.5,5.5-1.8,7.8-3.7
								C972.9,1140.8,974.8,1138.7,976.2,1136.1z"/>
							<path opacity="0.1091" fill="#131313" d="M1005.7,1101.4c3.1-24.1,5.8-48.2,6.5-72.5c0.4-14.4,0.4-28.8,0.8-43.2
								c0.4-13.9,1.2-27.8,3.1-41.6c1-7.6,2.5-15,4.4-22.4c1.8-7.2,4.2-14.2,7-21.1c0.1-3.1-1.4-5.1-4.4-5.9
								c-4.8-0.2-9.1,1.3-12.9,4.7c-7.1,4.5-13.9,9.5-20.4,15c-4.3,2.6-7.1,6.2-8.3,10.9c-2.2,7.7-4,15.6-5.4,23.5
								c-1.4,7.9-2.5,15.8-3.4,23.7c-1.5,13.9-2.3,27.8-3,41.7c-0.7,14-1.2,28-2,42c-0.8,12-1.8,24-3.1,35.9
								c-1.5,11.3-3,22.5-4.4,33.8c0,3.1,1.4,5.1,4.4,5.9c4.8,0.2,9-1.3,12.7-4.6c7-4.5,13.7-9.5,20.1-14.9
								C1001.8,1109.6,1004.6,1106,1005.7,1101.4z"/>
							<path opacity="0.1091" fill="#131313" d="M962.7,1093.7c1.4-6.5,2.5-13.1,3.4-19.8c0.9-6.6,1.6-13.2,2.1-19.8
								c1.2-14.3,2.2-28.7,2.5-43.1c0.2-10.3,0.3-20.6,0.5-30.9c0.3-12.1,0.9-24,1.9-36.1c-0.1,1.3-0.2,2.5-0.3,3.8
								c1.1-9.6,2.3-19.1,4.4-28.6c0.7-3.7,1.6-7.4,2.8-11c1.4-5.4,3.2-10.7,5.4-15.9c1.1-2.5,0.8-4.9-0.9-7.1
								c-1.6-2.2-3.8-3.2-6.5-3c-4.2,1.5-8.1,3.6-11.5,6.4c-3.7,2.5-6.9,5.5-9.6,9.1c-2.9,7.7-5.3,15.5-7.2,23.5
								c-1.9,7.9-3.3,15.8-4.4,23.8c-1.5,11.1-2.3,22.4-2.9,33.6c-0.7,13.9-0.9,27.8-1.1,41.6c0,10-0.4,20.1-1.2,30.1
								c-0.8,11.8-1.9,23.6-3.2,35.4c0.2-1.2,0.3-2.4,0.5-3.7c-0.9,5.7-1.9,11.4-2.8,17c-1,2.5-0.7,4.9,1,7.1c1.6,2.2,3.8,3.2,6.5,3
								c4.2-1.5,8-3.6,11.4-6.4C956.9,1100.3,960.1,1097.3,962.7,1093.7z"/>
							<path opacity="0.1091" fill="#131313" d="M981.8,1088c2.4-18,4.5-35.9,5.8-54c1.1-16.5,1.2-33,1.5-49.6
								c0.2-12.1,0.6-24.2,1.6-36.3c0.4-5.9,1-11.7,1.8-17.5c1-7.5,2.4-15,4.3-22.4c-0.4,1.4-0.8,2.9-1.1,4.3c1.4-5,2.8-10,4.2-15
								c-0.4,1.4-0.8,2.8-1.2,4.2c1.2-3.5,2.5-6.9,3.7-10.4c0.4-1.4,0.9-2.8,1.3-4.2c-0.4-1.2-0.8-2.4-1.2-3.7
								c-1-0.8-2.1-1.5-3.1-2.3c-2.9-0.6-5.4-0.1-7.7,1.7c-5.8,2.9-11,6.8-16.1,10.8c-2.5,1.7-4.7,3.7-6.7,5.9
								c-2.4,1.6-3.7,3.9-3.9,6.8c-1.3,3.9-2.5,7.9-3.6,11.9c-2.1,7.9-3.8,15.9-5.1,23.9c-2.6,15.8-3.7,31.8-4.5,47.8
								c-0.7,15.9-0.8,31.8-1.5,47.7c-1,23.4-3.8,46.5-7,69.7c0.2,2.9,1.7,4.9,4.4,5.9c4.1,0.6,7.7-0.5,11-3.4
								c5.9-3.7,11.5-7.8,16.7-12.4C979.1,1095.2,981.2,1092.1,981.8,1088z"/>
							<path opacity="0.1091" fill="#131313" d="M1002,1117.2c2.4-18,4.5-35.9,5.8-54c1.1-16.5,1.1-33,1.5-49.5
								c0.2-12.1,0.6-24.2,1.6-36.3c0.4-5.9,1-11.7,1.8-17.6c1-7.6,2.5-15.1,4.3-22.5c-0.3,1.4-0.7,2.8-1,4.2
								c1.7-7.2,4-14.3,6.7-21.2c0.4-1.4,0.9-2.8,1.3-4.2c-0.4-1.2-0.8-2.4-1.2-3.7c-1-0.8-2.1-1.5-3.1-2.3
								c-2.8-0.7-5.4-0.1-7.7,1.6c-5.8,2.9-11,6.7-16,10.7c-2.5,1.7-4.7,3.6-6.6,5.9c-2.4,1.6-3.6,3.9-3.9,6.8
								c-2.2,7.5-3.9,15.2-5.3,22.9c-1.4,7.8-2.5,15.5-3.4,23.4c-1.5,13.9-2.3,27.8-3,41.7c-0.7,14.2-1.2,28.3-2,42.5
								c-0.8,12.1-1.8,24.1-3.1,36.2c-1.4,11.4-2.9,22.7-4.5,34.1c0.2,2.9,1.7,4.9,4.4,5.9c4,0.7,7.6-0.4,10.8-3.2
								c5.8-3.6,11.3-7.6,16.4-12.1C999.4,1124.3,1001.5,1121.2,1002,1117.2z"/>
							<path opacity="0.1091" fill="#131313" d="M956.5,1117.4c3.5-12.4,5.6-25,6.4-37.8c1.3-12.3,2.2-24.7,2.6-37.1
								c0.6-14.4,0.6-28.8,1.1-43.3c0.5-13.9,1.2-27.8,3.1-41.6c1-7.5,2.4-15,4.3-22.3c1.8-7.1,4.1-14.1,6.8-20.9
								c0.3-2.4-0.4-4.5-1.9-6.3c-1.3-2-3.1-3.3-5.4-3.8c-2.8,0.4-5.4,1.6-7.6,3.4c-2.4,1.5-4.3,3.6-5.7,6.1
								c-2.9,7.3-5.2,14.7-7.1,22.3c-1.9,7.6-3.3,15.3-4.4,23.1c-1.9,13.8-2.7,27.7-3.1,41.6c-0.4,14.1-0.4,28.2-0.9,42.2
								c-0.4,12.2-1.3,24.4-2.5,36.5c-0.6,12.5-2.4,24.8-5.4,36.9c-0.1,2.3,0.6,4.4,2.1,6.2c1.3,2,3.1,3.3,5.3,3.8
								c2.7-0.4,5-1.4,7.1-3.2C953.6,1121.8,955.3,1119.9,956.5,1117.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M1009.5,1382.1c3.2-8,6.3-15.9,9.4-23.9c0.1-2.8-0.9-5-3-6.7
								c-3.1-1.7-6.2-1.7-9.5,0.1c-5.3,1.8-10.3,4.1-15.2,6.8c-3.4,1.3-5.5,3.6-6.3,7c-3,7.7-6.1,15.5-9.2,23.2
								c-0.2,2.7,0.8,5,2.9,6.8c3,1.7,6.1,1.8,9.4,0.1c5.3-1.7,10.3-3.8,15.1-6.5C1006.6,1387.7,1008.7,1385.4,1009.5,1382.1z"/>
							<path opacity="0.1091" fill="#131313" d="M984.6,1369c5.4-7.6,9.3-15.9,11.9-24.9c1.2-2.3,1.2-4.6,0.1-6.9
								c-1-2.4-2.7-3.9-5.2-4.5c-3.7,0.3-7.3,1.3-10.6,3c-3.5,1.4-6.6,3.4-9.3,6c-2.4,8.6-6.1,16.6-10.9,24.1
								c-2.2,4.1,0.3,10.4,5,11.5c3.6-0.1,7-1,10.2-2.6C979.1,1373.4,982.1,1371.5,984.6,1369z"/>
							<path opacity="0.1091" fill="#131313" d="M990.5,1330.8c2.9-8,6-15.9,9.1-23.8c0.1-2.8-0.9-5-3.1-6.7
								c-3.1-1.7-6.4-1.6-9.7,0.2c-5.4,1.9-10.6,4.2-15.5,7.1c-3.5,1.3-5.7,3.7-6.5,7.2c-3.1,7.7-6.1,15.4-9,23.1
								c-0.2,2.8,0.8,5,2.9,6.8c3.1,1.7,6.3,1.7,9.6,0c5.4-1.7,10.6-4,15.5-6.8C987.5,1336.6,989.7,1334.3,990.5,1330.8z"/>
							<path opacity="0.1091" fill="#131313" d="M1008.1,1357.7c3-8,6.1-16,9.3-23.9c0.4-2.9-0.7-5.1-3-6.7c-3.7-1.5-7.4-1.3-11,0.8
								c-6.3,2.2-12.3,5-18.2,8.2c-3.9,1.4-6.6,4-7.9,7.8c-3.2,7.6-6.2,15.3-9,23c-0.4,2.8,0.6,5.1,2.9,6.8
								c3.6,1.6,7.3,1.4,10.9-0.6c6.2-2.1,12.3-4.7,18.1-7.8C1004.1,1364,1006.8,1361.4,1008.1,1357.7z"/>
							<path opacity="0.1091" fill="#131313" d="M1010.3,1328.2c3-8,6.2-16,9.4-24c0.6-3-0.4-5.2-3.1-6.7c-4.5-1.3-8.8-0.7-13.1,1.7
								c-7.6,2.8-15,6.2-22.1,10.1c-4.6,1.6-7.9,4.5-9.9,8.7c-3.2,7.6-6.2,15.2-9,22.9c-0.7,3,0.3,5.2,2.9,6.8
								c4.4,1.4,8.7,0.9,12.9-1.5c7.5-2.7,14.9-5.9,22-9.6C1005,1335.2,1008.3,1332.3,1010.3,1328.2z"/>
							<path opacity="0.1091" fill="#131313" d="M971.3,1356.2c4.9-8.1,8.8-16.5,11.9-25.4c1.5-3.8-0.8-10.2-5-11.5
								c-3-0.1-5.8,0.5-8.5,1.9c-2.8,1-5.2,2.7-7.1,4.9c-2.9,8.6-6.5,17-10.9,25c-0.7,2.3-0.5,4.4,0.6,6.6c0.8,2.3,2.3,3.9,4.4,4.9
								c2.8,0.2,5.4-0.4,8-1.7C967.4,1359.9,969.6,1358.4,971.3,1356.2z"/>
							<path opacity="0.1091" fill="#131313" d="M990.8,1388.9c5.8-7.9,10.4-16.5,13.7-25.8c1.8-4.1-0.4-10.3-5.1-11.4
								c-3.4,0.1-6.6,0.9-9.7,2.5c-3.2,1.2-6,3.1-8.3,5.5c-3,8.8-7.2,17.1-12.3,24.9c-2.2,3.8,0.9,10.4,5,11.5c3.2,0,6.2-0.7,9-2.1
								C986.1,1392.9,988.7,1391.2,990.8,1388.9z"/>
							<path opacity="0.1091" fill="#131313" d="M1023.5,1353.5c3-8,6.1-16,9.4-24c0.8-3-0.2-5.3-3-6.7c-4.8-1.2-9.4-0.5-13.8,2.1
								c-8.1,3.1-16,6.6-23.6,10.7c-4.8,1.7-8.4,4.7-10.7,9c-3.3,7.5-6.3,15.1-9,22.9c-0.8,3,0.2,5.3,2.9,6.8
								c4.7,1.3,9.3,0.7,13.7-1.8c8-2.9,15.9-6.3,23.5-10.2C1017.6,1360.8,1021.2,1357.8,1023.5,1353.5z"/>
							<path opacity="0.1091" fill="#131313" d="M977.6,1346c5.7-7.6,9.9-16,12.5-25.1c1.3-2.3,1.4-4.6,0.3-7
								c-1-2.5-2.8-3.9-5.4-4.4c-4,0.4-7.8,1.5-11.4,3.4c-3.8,1.5-7.1,3.6-10.1,6.4c-2.5,8.7-6.3,16.8-11.5,24.3
								c-2.4,4.3,0.1,10.5,5,11.5c3.9-0.3,7.5-1.3,11-3C971.5,1350.7,974.8,1348.7,977.6,1346z"/>
							<path opacity="0.1091" fill="#131313" d="M999.2,1340c3-8,6.1-15.9,9.3-23.9c0.3-2.8-0.7-5.1-3.1-6.7
								c-3.6-1.5-7.2-1.3-10.8,0.7c-6.2,2.2-12.1,4.9-17.8,8.1c-3.9,1.4-6.4,3.9-7.7,7.7c-3.2,7.6-6.2,15.3-9,23
								c-0.3,2.8,0.6,5.1,2.9,6.8c3.5,1.6,7.1,1.4,10.7-0.5c6.1-2.1,12-4.6,17.7-7.7C995.4,1346.2,997.9,1343.7,999.2,1340z"/>
							<path opacity="0.1091" fill="#131313" d="M1019.6,1369c3.1-8,6.2-16,9.5-24c0.5-2.9-0.5-5.2-3-6.7c-4.1-1.4-8.2-1-12.1,1.3
								c-7,2.6-13.8,5.6-20.3,9.2c-4.3,1.5-7.3,4.2-9,8.2c-3.2,7.6-6.2,15.3-9.1,23c-0.5,2.9,0.4,5.2,2.9,6.8c4,1.5,8,1.1,12-1
								c6.9-2.4,13.7-5.3,20.2-8.7C1015,1375.6,1018,1373,1019.6,1369z"/>
							<path opacity="0.1091" fill="#131313" d="M972.6,1369.4c4.9-8.1,8.9-16.5,12-25.5c0.7-2.3,0.5-4.4-0.6-6.6
								c-0.8-2.2-2.3-3.9-4.4-4.9c-2.7-0.2-5.3,0.3-7.8,1.6c-2.6,0.9-4.7,2.5-6.4,4.6c-2.9,8.6-6.5,17-10.9,25
								c-0.6,2.2-0.3,4.4,0.8,6.5c0.8,2.2,2.2,3.9,4.2,5c2.5,0.3,4.9-0.1,7.2-1.3C969.2,1372.9,971.1,1371.4,972.6,1369.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M1010.3,1239.9c1.8-2.9,3.6-5.7,5.5-8.6c2.6-4.1-1.4-10.5-5.8-11.1
								c-3.7,0.5-7.1,1.6-10.3,3.5c-3.4,1.6-6.3,3.7-8.8,6.5c-1.6,2.7-3.2,5.4-4.8,8c-2.4,4.1,1.3,10.4,5.7,11.2
								c3.6-0.4,6.9-1.4,10-3.2C1005.1,1244.6,1007.9,1242.6,1010.3,1239.9z"/>
							<path opacity="0.1091" fill="#131313" d="M1015.9,1201.5c0.7-2.3,1.3-4.5,2-6.8c0-2.8-1.1-5-3.5-6.5
								c-3.3-1.4-6.6-1.1-9.9,0.9c-5.5,2.3-10.6,5-15.6,8.3c-3.5,1.5-5.6,4.1-6.3,7.7c-0.6,2.1-1.3,4.1-1.9,6.2c0,2.8,1.1,5,3.4,6.6
								c3.3,1.5,6.6,1.2,9.8-0.8c5.4-2.2,10.6-4.8,15.6-8C1013.1,1207.6,1015.2,1205.1,1015.9,1201.5z"/>
							<path opacity="0.1091" fill="#131313" d="M1035.9,1198.7c0.7-2.3,1.4-4.6,2.1-6.9c0.5-3-0.6-5.2-3.4-6.5
								c-4.7-1-9.1-0.1-13.2,2.7c-7.6,3.4-14.9,7.3-22,11.6c-4.6,1.9-7.8,5-9.7,9.4c-0.6,2-1.2,4.1-1.8,6.1c-0.5,3,0.6,5.2,3.4,6.6
								c4.6,1,9,0.2,13.1-2.4c7.6-3.3,14.9-7,21.9-11.2C1030.8,1206.1,1034.1,1203.1,1035.9,1198.7z"/>
							<path opacity="0.1091" fill="#131313" d="M997.2,1226.9c1.7-2.9,3.4-5.8,5.1-8.7c0.7-2.3,0.4-4.5-0.8-6.6
								c-1-2.2-2.6-3.7-4.9-4.5c-3,0.1-5.8,0.9-8.3,2.4c-2.8,1.2-5,3-6.8,5.4c-1.5,2.8-3,5.6-4.6,8.5c-0.6,2.3-0.3,4.5,0.9,6.6
								c1,2.2,2.6,3.7,4.8,4.6c2.9,0,5.6-0.8,8.1-2.3C993.3,1231,995.4,1229.2,997.2,1226.9z"/>
							<path opacity="0.1091" fill="#131313" d="M1003.6,1216.8c1.8-2.8,3.5-5.6,5.3-8.4c2.8-4.4-1-10.6-5.9-11.1
								c-4.1,0.7-7.9,2.1-11.4,4.2c-3.7,1.8-7,4.1-9.9,7.1c-1.6,2.6-3.1,5.2-4.7,7.8c-2.6,4.4,0.8,10.5,5.7,11.1
								c4-0.6,7.7-1.9,11.2-3.9C997.6,1222,1000.9,1219.7,1003.6,1216.8z"/>
							<path opacity="0.1091" fill="#131313" d="M1024.6,1210.7c0.7-2.3,1.4-4.6,2.1-6.9c0.2-2.9-1-5-3.4-6.5
								c-3.8-1.3-7.4-0.8-10.9,1.4c-6.1,2.6-12,5.7-17.6,9.3c-3.8,1.6-6.3,4.4-7.4,8.2c-0.6,2.1-1.3,4.1-1.9,6.2
								c-0.2,2.9,0.9,5,3.4,6.6c3.7,1.4,7.3,0.9,10.9-1.3c6.1-2.5,11.9-5.5,17.5-9C1021.1,1217.2,1023.5,1214.5,1024.6,1210.7z"/>
							<path opacity="0.1091" fill="#131313" d="M998.3,1240.2c1.8-2.9,3.5-5.9,5.3-8.8c0.5-2.3,0.2-4.5-1.1-6.5
								c-0.9-2.2-2.5-3.7-4.6-4.7c-2.7-0.1-5.2,0.6-7.5,2c-2.5,1.1-4.5,2.7-6,4.9c-1.5,2.8-3,5.6-4.6,8.5c-0.4,2.3-0.1,4.4,1.1,6.4
								c0.9,2.2,2.4,3.7,4.5,4.7c2.6,0.1,4.9-0.5,7.2-1.8C995,1243.9,996.9,1242.3,998.3,1240.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M998.4,1370.3c2.3-7.2,4.1-14.6,5.4-22.1c1.5-7.3,2.9-14.6,4.5-21.9
								c1.1-2.5,0.9-4.9-0.7-7.1c-1.5-2.3-3.6-3.4-6.4-3.2c-4.2,1.3-8.1,3.3-11.7,6c-3.8,2.3-7.1,5.3-9.8,8.7
								c-1.5,7.3-3,14.6-4.4,21.9c-1.3,7.2-3,14.3-5,21.3c-1.1,2.5-0.9,4.8,0.6,7.1c1.5,2.3,3.5,3.4,6.3,3.4c4.2-1.2,8-3,11.5-5.6
								C992.5,1376.5,995.7,1373.7,998.4,1370.3z"/>
							<path opacity="0.1091" fill="#131313" d="M973.8,1354.9c4.7-7.6,7.6-15.7,8.8-24.3c1.4-7.3,2.8-14.5,4.3-21.8
								c0.8-2.5,0.4-4.7-1.1-6.8c-1.4-2.2-3.4-3.4-5.9-3.5c-3.7,0.9-7,2.5-10,4.8c-3.2,2-5.9,4.5-8.1,7.6c-1.6,7.2-3,14.4-4.2,21.6
								c-1,8.2-3.6,15.9-7.7,23.2c-1.7,4.3,2.4,10.2,6.9,10.5c3.5-0.7,6.6-2.1,9.5-4.3C969.2,1360.1,971.8,1357.7,973.8,1354.9z"/>
							<path opacity="0.1091" fill="#131313" d="M981,1314.9c2.6-14.5,5.6-28.8,8.5-43.2c-0.2-2.8-1.6-4.8-4.2-6.1
								c-3.7-0.9-7.1-0.1-10.3,2.4c-5.5,3.1-10.7,6.7-15.6,10.7c-3.5,2-5.5,4.9-5.9,8.7c-2.9,14.1-5.9,28.2-8.5,42.3
								c0.2,2.8,1.5,4.9,4.1,6.1c3.7,1,7.1,0.3,10.2-2.2c5.5-3,10.7-6.4,15.6-10.4C978.5,1321.5,980.5,1318.6,981,1314.9z"/>
							<path opacity="0.1091" fill="#131313" d="M998.5,1342c2.8-14.5,5.9-28.9,8.8-43.4c-0.2-2.9-1.5-4.9-4.2-6.1
								c-3.9-0.9-7.4,0-10.7,2.7c-5.8,3.3-11.2,7-16.4,11.2c-3.6,2.1-5.7,5.1-6.3,9c-2.9,14.1-5.9,28.2-8.7,42.4
								c0.1,2.9,1.5,4.9,4.1,6.1c3.8,1,7.3,0.2,10.6-2.4c5.8-3.1,11.2-6.7,16.3-10.8C995.7,1348.8,997.8,1345.8,998.5,1342z"/>
							<path opacity="0.1091" fill="#131313" d="M1001.2,1311.7c2.8-14.5,5.9-29,8.9-43.5c0.2-3.1-1.2-5.2-4.2-6.1
								c-5-0.3-9.4,1.2-13.4,4.5c-7.5,4.5-14.7,9.4-21.5,14.8c-4.5,2.5-7.5,6.1-9,10.8c-2.9,14.1-6,28.1-8.7,42.3
								c-0.3,3.1,1.1,5.2,4.1,6.1c4.9,0.4,9.3-1,13.3-4.2c7.5-4.3,14.6-9,21.5-14.2C996.7,1319.9,999.7,1316.4,1001.2,1311.7z"/>
							<path opacity="0.1091" fill="#131313" d="M962.9,1340.2c2.8-7.6,5-15.3,6.4-23.3c1.5-7.4,3.1-14.8,4.7-22.2
								c0.9-4.2-2.2-10-6.9-10.4c-3.2,0.5-6.1,1.8-8.7,3.7c-2.8,1.6-5.1,3.8-6.8,6.5c-1.6,7.3-3.2,14.7-4.6,22.1
								c-1.3,7.7-3.2,15.4-5.7,22.8c-1.1,4.1,2.6,10,6.9,10.4c3-0.5,5.8-1.6,8.2-3.5C959.2,1344.9,961.3,1342.8,962.9,1340.2z"/>
							<path opacity="0.1091" fill="#131313" d="M983.9,1372.3c2.8-7.6,4.9-15.3,6.4-23.3c1.5-7.4,3.1-14.8,4.7-22.2
								c0.9-4.2-2.2-10-6.9-10.5c-3.1,0.5-6,1.7-8.6,3.6c-2.8,1.6-5,3.7-6.7,6.4c-1.6,7.3-3.2,14.7-4.6,22.1
								c-1.3,7.7-3.2,15.3-5.7,22.8c-1.1,4,2.6,10,6.9,10.5c3-0.4,5.7-1.5,8.1-3.4C980.2,1376.9,982.3,1374.8,983.9,1372.3z"/>
							<path opacity="0.1091" fill="#131313" d="M1013.6,1337.6c2.8-14.5,5.9-29,8.9-43.5c0.2-3.1-1.2-5.1-4.2-6.1
								c-4.8-0.4-9.2,1.1-13.1,4.3c-7.3,4.3-14.3,9.1-21,14.4c-4.4,2.4-7.3,6-8.8,10.6c-2.9,14.1-6,28.1-8.7,42.3
								c-0.2,3.1,1.1,5.1,4.1,6.2c4.8,0.5,9.1-0.9,13-4c7.3-4.1,14.3-8.8,21-13.8C1009.2,1345.7,1012.1,1342.2,1013.6,1337.6z"/>
							<path opacity="0.1091" fill="#131313" d="M969.8,1330.1c2.5-7.3,4.4-14.7,5.7-22.2c1.4-7.3,2.9-14.6,4.4-21.9
								c1.1-2.5,0.9-4.9-0.7-7.1c-1.5-2.3-3.6-3.4-6.4-3.2c-4.2,1.3-8,3.3-11.6,5.9c-3.7,2.3-7,5.2-9.7,8.7
								c-1.5,7.3-3,14.6-4.4,21.8c-1.3,7.3-3,14.4-5.2,21.4c-1.1,2.5-0.9,4.8,0.7,7.1c1.5,2.3,3.5,3.4,6.2,3.4
								c4.1-1.2,7.9-3,11.4-5.5C964,1336.2,967.1,1333.5,969.8,1330.1z"/>
							<path opacity="0.1091" fill="#131313" d="M989.5,1324.3c3-14.5,6-29,9-43.5c-0.1-2.9-1.5-4.9-4.2-6.1c-4-0.8-7.7,0.2-11,3
								c-6,3.5-11.7,7.4-17.1,11.8c-3.8,2.1-6,5.2-6.7,9.3c-2.9,14.2-5.9,28.3-8.8,42.5c0.1,2.9,1.4,4.9,4.1,6.1c4,0.9,7.6,0,11-2.7
								c6-3.3,11.7-7.1,17-11.3C986.5,1331.3,988.7,1328.3,989.5,1324.3z"/>
							<path opacity="0.1091" fill="#131313" d="M1009.8,1353.4c3-14.5,6-29,9-43.5c-0.1-2.9-1.5-4.9-4.2-6.1
								c-4.1-0.8-7.8,0.2-11.2,3c-6.1,3.5-11.9,7.5-17.4,11.9c-3.8,2.1-6.1,5.2-6.9,9.3c-2.9,14.2-5.9,28.3-8.8,42.4
								c0,2.9,1.4,5,4,6.2c4,0.9,7.7,0,11.1-2.7c6.1-3.3,11.9-7.1,17.4-11.4C1006.7,1360.4,1009,1357.4,1009.8,1353.4z"/>
							<path opacity="0.1091" fill="#131313" d="M963.9,1353.7c2.9-7.6,5.1-15.4,6.6-23.4c1.5-7.4,3.1-14.8,4.7-22.2
								c0.3-2.3-0.2-4.5-1.7-6.4c-1.2-2.1-2.9-3.4-5.2-4c-2.8,0.3-5.4,1.3-7.6,3.1c-2.5,1.4-4.4,3.4-5.8,5.8
								c-1.6,7.3-3.2,14.7-4.6,22.1c-1.3,7.7-3.2,15.4-5.7,22.8c-0.2,2.3,0.4,4.4,1.8,6.3c1.2,2.1,2.9,3.4,5.1,4.1
								c2.6-0.2,5-1.1,7.1-2.7C960.9,1357.9,962.7,1356.1,963.9,1353.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M1010,1238c1.2-6.4,2.5-12.8,3.7-19.1c0.7-5.9,1.4-11.9,2.1-17.8
								c1.9-15,4.4-30,7-44.9c4.8-26.7,10.7-53,17.2-79.3c1-2.5,0.7-4.9-0.9-7.1c-1.6-2.2-3.7-3.3-6.4-3.1c-4.2,1.4-8,3.5-11.4,6.2
								c-3.7,2.4-6.9,5.4-9.5,8.9c-6.2,26.7-11.9,53.5-16.5,80.5c-2,12.1-3.9,24.1-5.5,36.2c-0.9,6-1.6,11.9-2.2,17.9
								c-0.8,9.1-2.2,18.2-4.3,27.1c-0.8,2.6-0.3,4.8,1.5,6.9c1.6,2.1,3.8,3.1,6.4,2.8c3.8-1.5,7.3-3.6,10.4-6.4
								C1005.1,1244.4,1007.8,1241.5,1010,1238z"/>
							<path opacity="0.1091" fill="#131313" d="M986.2,1222.2c2-7,3.9-14.1,5.9-21.1c0.7-5.8,1.3-11.6,2-17.5
								c1.9-15,4.4-30,7.1-44.9c4.8-26.7,10.8-53.1,17.4-79.4c0.7-2.5,0.3-4.7-1.3-6.8c-1.4-2.2-3.5-3.3-6-3.3
								c-3.6,1-6.9,2.7-9.9,5.1c-3.2,2.1-5.8,4.7-8,7.8c-6.6,26.8-12.6,53.7-17.4,80.9c-2.1,12.1-4.1,24.2-5.7,36.4
								c-0.9,5.9-1.6,11.8-2.2,17.7c-0.6,9.8-2.6,19.3-5.9,28.7c-1.1,4.6,3.3,9.9,8,9.7c3.4-1.1,6.4-2.9,9.1-5.3
								C982.1,1228,984.4,1225.3,986.2,1222.2z"/>
							<path opacity="0.1091" fill="#131313" d="M992.3,1182.1c1.2-11.9,2.5-23.8,4.1-35.6c2-15,4.4-30,7.1-44.9
								c4.8-26.7,10.9-53.1,17.5-79.3c-0.3-2.8-1.7-4.8-4.3-5.9c-3.8-0.8-7.2,0.1-10.3,2.8c-5.5,3.3-10.7,7.1-15.5,11.3
								c-3.5,2.1-5.4,5.1-5.8,9c-6.7,27.2-12.7,54.5-17.5,82.2c-4.7,26.8-8.3,53.7-11.1,80.8c0.4,2.8,2,4.7,4.7,5.7
								c4,0.5,7.4-0.7,10.4-3.6c5.5-3.8,10.6-8,15.3-12.7C990.3,1189.2,992.1,1186.1,992.3,1182.1z"/>
							<path opacity="0.1091" fill="#131313" d="M1010.1,1209.1c1.2-11.9,2.5-23.8,4.1-35.6c2-15,4.4-30,7.1-44.9
								c4.8-26.7,10.9-53.1,17.6-79.4c-0.2-2.9-1.7-4.8-4.3-6c-3.9-0.8-7.4,0.2-10.6,3c-5.7,3.4-11,7.3-16.1,11.7
								c-3.6,2.2-5.6,5.2-6.1,9.2c-6.3,27-11.9,54.1-16.5,81.4c-4.4,26.7-7.9,53.5-10.6,80.4c0.4,2.8,2,4.7,4.7,5.7
								c3.9,0.5,7.3-0.6,10.3-3.5c5.4-3.7,10.4-7.9,15.1-12.4C1008.1,1216.1,1009.9,1213,1010.1,1209.1z"/>
							<path opacity="0.1091" fill="#131313" d="M1013.2,1178.3c1.2-12,2.5-23.9,4.1-35.8c2.1-15,4.4-29.9,7.1-44.8
								c4.8-26.6,10.8-52.9,17.4-79.1c0.2-3.2-1.3-5.1-4.3-6c-5-0.1-9.5,1.5-13.3,5c-7.4,4.7-14.6,9.9-21.3,15.6
								c-4.5,2.7-7.4,6.4-8.8,11.2c-6.4,27.3-12.2,54.8-16.9,82.5c-2.1,12.3-3.9,24.5-5.6,36.8c-0.8,6.1-1.6,12.1-2.5,18.2
								c-1.3,8.5-2.2,17.1-2.8,25.6c0,3.2,1.6,5.1,4.7,5.7c5.1-0.2,9.5-2.2,13.2-6c7.3-5.3,14.2-11,20.7-17.1
								C1009.2,1187.2,1012,1183.2,1013.2,1178.3z"/>
							<path opacity="0.1091" fill="#131313" d="M972.9,1208.9c1.7-7,3.4-13.9,5.1-20.9c0.9-6,1.8-12,2.7-18c2.2-15,4.4-30,7.1-44.9
								c4.9-26.8,11-53.3,17.7-79.7c1.1-4.3-2.7-10-7.2-10.2c-3.2,0.7-6.1,2-8.7,4.1c-2.8,1.7-5,4-6.7,6.8
								c-6.9,27-13.1,54.1-18.1,81.5c-2.2,12.1-4.2,24.3-5.9,36.5c-0.8,5.9-1.7,11.9-2.5,17.8c-1.1,9.8-3,19.5-5.5,29.1
								c-0.9,4.2,3.7,9.7,8,9.6c3-0.8,5.7-2.4,8.1-4.5C969.6,1214.1,971.5,1211.7,972.9,1208.9z"/>
							<path opacity="0.1091" fill="#131313" d="M995.6,1239.5c1.2-6.6,2.4-13.1,3.7-19.7c0.8-6.1,1.6-12.1,2.4-18.2
								c2.1-15.1,4.6-30.1,7.4-45c4.9-26.7,11-53.1,17.6-79.4c1.1-4.2-2.7-10-7.1-10.3c-3.1,0.6-5.9,1.8-8.4,3.8
								c-2.7,1.7-4.9,3.8-6.5,6.5c-6.6,26.6-12.5,53.3-17.3,80.4c-2.1,12.1-4.1,24.1-5.9,36.2c-1,6-1.8,12.1-2.4,18.2
								c-1,9.5-2.4,18.9-4.1,28.3c-0.6,4,3.8,9.6,8,9.7c2.8-0.7,5.3-2,7.4-4C992.7,1244.4,994.4,1242.2,995.6,1239.5z"/>
							<path opacity="0.1091" fill="#131313" d="M1025,1204.7c1.2-12,2.5-23.9,4.2-35.8c2.1-15,4.5-30,7.2-44.9
								c4.9-26.7,11-53.1,17.7-79.4c0.1-3.1-1.3-5.1-4.3-6c-4.8-0.2-9.2,1.3-12.9,4.7c-7.2,4.5-14,9.5-20.6,14.9
								c-4.3,2.6-7.2,6.2-8.4,10.9c-6.1,27.1-11.6,54.3-16,81.7c-4.3,26.7-7.9,53.5-10.5,80.3c0.1,3.1,1.7,5,4.7,5.7
								c4.8,0,8.9-1.7,12.4-5.2c6.7-4.8,13.1-10.1,19.2-15.8C1021.6,1213.1,1024.1,1209.4,1025,1204.7z"/>
							<path opacity="0.1091" fill="#131313" d="M982.5,1196.9c1.5-11.9,2.8-23.9,4.4-35.8c2-15,4.4-30,7.1-45
								c4.8-26.8,10.9-53.2,17.5-79.5c1.1-2.5,0.8-4.9-0.9-7.1c-1.6-2.2-3.8-3.3-6.5-3c-4.2,1.4-8.1,3.6-11.5,6.4
								c-3.7,2.5-6.9,5.5-9.6,9c-6.7,27.1-12.9,54.2-17.8,81.6c-2.2,12.2-4.1,24.4-5.9,36.6c-2.1,14.7-3.7,29.5-5.5,44.2
								c-1,2.6-0.5,5,1.3,7.1c1.8,2.2,4,3,6.7,2.6c4.2-1.8,8-4.2,11.4-7.3C976.9,1203.9,980,1200.7,982.5,1196.9z"/>
							<path opacity="0.1091" fill="#131313" d="M1001.3,1191.3c2.9-27,6.5-53.9,11.3-80.6c4.8-26.7,10.9-53.1,17.5-79.4
								c-0.2-2.9-1.6-4.9-4.3-6c-4.1-0.7-7.8,0.5-11,3.3c-6,3.7-11.6,7.8-16.9,12.3c-3.7,2.2-5.9,5.4-6.5,9.5
								c-6.5,27.2-12.3,54.4-17,82c-4.6,26.8-8.1,53.7-11,80.7c0.3,2.9,1.9,4.8,4.7,5.7c4.2,0.4,7.8-1,10.9-4.1
								c5.8-4.1,11.2-8.6,16.3-13.5C998.8,1198.8,1000.8,1195.5,1001.3,1191.3z"/>
							<path opacity="0.1091" fill="#131313" d="M1021.5,1220.4c1.2-11.9,2.5-23.8,4.1-35.7c2.1-15,4.4-30,7.1-44.9
								c4.9-26.7,10.9-53.1,17.6-79.4c-0.2-2.9-1.6-4.9-4.3-6c-4.1-0.7-7.8,0.4-11,3.3c-6,3.6-11.6,7.7-16.9,12.3
								c-3.7,2.2-5.9,5.4-6.6,9.5c-6.1,26.9-11.5,53.9-15.9,81.1c-4.3,26.6-7.7,53.4-10.4,80.2c0.4,2.9,2,4.8,4.7,5.7
								c4,0.5,7.5-0.7,10.5-3.7c5.5-3.8,10.7-8.1,15.5-12.8C1019.3,1227.6,1021.2,1224.4,1021.5,1220.4z"/>
							<path opacity="0.1091" fill="#131313" d="M976.1,1220.8c1.3-6.6,2.5-13.2,3.8-19.8c0.8-6.1,1.6-12.1,2.4-18.2
								c2.1-15,4.6-30.1,7.3-45c4.8-26.7,10.8-53,17.4-79.3c0.3-2.4-0.3-4.5-1.8-6.4c-1.3-2.1-3-3.4-5.4-3.9
								c-2.8,0.4-5.4,1.5-7.6,3.3c-2.5,1.5-4.4,3.5-5.7,6c-6.7,26.7-12.8,53.5-17.7,80.6c-2.2,12.1-4.2,24.2-6,36.3
								c-1,6-1.8,12.1-2.4,18.2c-1,9.5-2.4,19-4.2,28.4c0,2.4,0.8,4.4,2.4,6.1c1.4,1.9,3.3,3.1,5.6,3.5c2.7-0.6,5-1.8,7-3.7
								C973.4,1225.4,975.1,1223.3,976.1,1220.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M939.9,445.3c7.7,49.5,12.9,99.3,16,149.3c3.1,50,4.1,100.2,4.1,150.3
								c-0.2,101.1-5.1,202.1-9.6,303c0.6,2.9,2.3,4.7,5.2,5.3c4.3,0,7.9-1.8,10.7-5.2c5.6-4.7,10.7-9.8,15.5-15.2
								c3.5-2.8,5.3-6.4,5.4-10.6c4.4-102.1,8.9-204.3,8.3-306.6c-0.7-101.6-6.7-203.2-22.4-303.7c-1-2.8-3-4.3-6-4.3
								c-4.3,0.9-7.7,3.4-10,7.4c-4.9,5.8-9.3,12-13.3,18.5C940.7,436.9,939.4,440.9,939.9,445.3z"/>
							<path opacity="0.1091" fill="#131313" d="M917.6,428.1c7.7,49.5,12.9,99.4,15.9,149.4c3,50.1,4,100.3,3.9,150.5
								c-0.2,101.1-5.2,202.2-9.8,303.2c0.4,3,2.1,4.8,5.1,5.3c4.6-0.3,8.5-2.3,11.6-6c6.1-5.2,11.9-10.8,17.3-16.8
								c3.8-3,5.9-6.9,6.3-11.5c4.2-101.8,8.3-203.7,7-305.6c-0.7-50.9-2.6-101.7-6.6-152.5c-3.9-50-9.7-99.9-17.5-149.4
								c-1-2.8-3-4.3-6-4.3c-4.3,0.9-7.7,3.4-10,7.4c-4.9,5.8-9.4,12-13.4,18.5C918.4,419.7,917.1,423.7,917.6,428.1z"/>
							<path opacity="0.1091" fill="#131313" d="M920.7,389.5c-0.3,13.2,0.9,26.2,3.5,39.2c1.7,12.5,3.2,24.9,4.5,37.4
								c2.7,24.9,4.7,49.8,6.3,74.8c3.2,50,4.4,100,4.4,150.1c0.1,101-4.7,201.9-9.2,302.8c-0.6,2.6,0.1,4.9,2.1,6.7
								c1.8,2,4,2.7,6.7,2.2c3.7-1.8,7-4.2,9.9-7.3c3.1-2.8,5.6-6,7.5-9.6c4.3-101.2,8.6-202.5,7.7-303.8
								c-0.4-50.4-2.1-100.7-5.7-151c-1.8-25.1-4.1-50.2-6.9-75.2c-1.4-12.6-3-25.1-4.8-37.6c-2.6-12.4-4-25-4.1-37.7
								c-0.3-4.6-5.6-8.8-10.1-7.3c-3,1.9-5.4,4.3-7.3,7.2C923,383,921.5,386.1,920.7,389.5z"/>
							<path opacity="0.1091" fill="#131313" d="M939.2,420.1c3.3,23.6,6.6,47.1,9.2,70.7c2.7,24.9,4.7,49.9,6.3,74.9
								c3.1,50.1,4.3,100.3,4.3,150.4c0,102.4-5,204.7-9.6,306.9c0.8,2.6,2.6,4.4,5.1,5.3c3.4,0.6,6.3-0.5,8.7-3.2
								c4.3-3.4,8.2-7.2,11.7-11.4c2.8-2.4,3.9-5.3,3.4-8.7c4.3-100.4,8.8-200.9,8.3-301.5c-0.2-50.8-1.7-101.6-5.2-152.3
								c-1.7-25.3-3.9-50.6-6.7-75.9c-2.9-26-6.7-51.8-10.4-77.6c-1.3-2.4-3.3-3.8-6-4.3c-3.4,0-6.1,1.5-8,4.5
								c-3.6,4-6.7,8.4-9.4,13C938.7,413.8,938.1,416.8,939.2,420.1z"/>
							<path opacity="0.1091" fill="#131313" d="M939.6,387.3c0.5,13.3,1.8,26.5,4,39.7c1.5,12.6,2.9,25.1,4.3,37.7
								c2.6,24.9,4.6,50,6.1,75c3,50.1,4.1,100.3,4,150.5c-0.2,101.1-5.2,202.2-9.8,303.2c-0.2,4.2,4.1,9.4,8.5,9.2
								c2.9-0.9,5.4-2.5,7.5-4.7c2.3-1.9,4.1-4.3,5.2-7.1c4.5-101.4,9.2-202.8,9-304.3c-0.1-50.5-1.4-101-4.6-151.4
								c-1.6-25.2-3.7-50.3-6.4-75.4c-1.4-12.6-2.8-25.2-4.4-37.8c-2.2-12.8-3.8-25.8-4.8-38.7c-0.7-2.2-2-4-4-5.2
								c-1.8-1.5-3.9-2.2-6.2-2.1c-2.3,1-4.2,2.7-5.5,4.8C940.8,382.5,939.8,384.8,939.6,387.3z"/>
							<path opacity="0.1091" fill="#131313" d="M902.9,416.1c7.6,49.6,12.8,99.4,15.8,149.5c3,50.1,4,100.4,3.8,150.6
								c-0.3,101.2-5.4,202.3-10,303.3c0.4,3,2.1,4.8,5.1,5.3c4.6-0.3,8.5-2.3,11.7-6c6.2-5.2,12-10.9,17.4-16.9
								c3.8-3,5.9-6.9,6.3-11.5c4.1-101.5,7.9-203.1,6.3-304.8c-0.8-50.6-3-101.2-7.1-151.6c-4-49.8-10.1-99.5-17.8-148.9
								c-1-2.7-3-4.2-6-4.3c-4.2,0.7-7.4,3-9.6,6.7c-4.6,5.4-8.8,11.2-12.5,17.3C903.5,408.2,902.3,411.9,902.9,416.1z"/>
							<path opacity="0.1091" fill="#131313" d="M922.4,450.4c2.2,12.3,4,24.6,5.4,37c1.6,12.5,3.1,24.9,4.4,37.4
								c2.6,24.9,4.6,49.9,6.1,74.8c3,50,4,100.1,3.9,150.2c-0.2,101-5.2,202-9.8,302.9c0.3,3.2,2,4.9,5.2,5.3
								c5.1-0.7,9.3-3,12.7-7.1c6.8-5.9,13.3-12.2,19.4-18.9c4.1-3.3,6.6-7.5,7.4-12.6c4.3-102.1,8.5-204.4,7.5-306.6
								c-0.5-51.2-2.4-102.4-6.3-153.4c-1.9-25.5-4.3-50.9-7.3-76.3c-2.9-24.6-6.1-49.2-10.1-73.6c-0.8-3.1-2.8-4.5-6-4.3
								c-4.9,1.6-8.7,4.7-11.4,9.3c-5.8,7.1-11.1,14.5-16,22.2C924,440.6,922.3,445.2,922.4,450.4z"/>
							<path opacity="0.1091" fill="#131313" d="M954.3,410.5c-1.3,13.7-0.4,27.2,2.5,40.5c1.7,12.5,3.2,25,4.5,37.5
								c2.6,24.9,4.7,49.9,6.2,75c3.1,50.1,4.2,100.3,4.1,150.4c-0.1,101.1-5,202.1-9.6,303.1c-0.3,2.5,0.4,4.7,2.3,6.5
								c1.7,1.9,3.9,2.8,6.4,2.5c3.4-1.5,6.4-3.6,8.9-6.4c2.8-2.5,5-5.4,6.6-8.7c4.5-101.6,9.3-203.3,9.2-305.1
								c-0.1-50.8-1.3-101.6-4.6-152.3c-1.6-25.3-3.7-50.6-6.4-75.9c-1.3-12.6-2.9-25.3-4.6-37.9c-2.9-12.8-3.9-25.8-3.2-38.9
								c-0.1-4.7-5.7-8.8-10.2-7.3c-3,2-5.5,4.5-7.4,7.5C956.7,403.9,955.1,407,954.3,410.5z"/>
							<path opacity="0.1091" fill="#131313" d="M911.9,404.4c2.5,15.3,4.8,30.5,6.7,45.9c2,15.6,3.6,31.2,5.1,46.9
								c-0.1-1.3-0.2-2.6-0.3-3.9c0.7,7.5,1.3,15.1,2,22.6c-0.1-1.3-0.2-2.6-0.3-3.9c0.6,7.6,1.2,15.1,1.7,22.7
								c-0.1-1.3-0.2-2.6-0.3-3.9c0.5,7.6,1,15.1,1.5,22.7c-0.1-1.3-0.1-2.6-0.2-4c2.2,38.9,3.7,77.8,4.1,116.7
								c0.4,37.8,0.1,75.5-0.8,113.3c-1.7,75.9-5.3,151.7-8.7,227.6c0.7,2.8,2.4,4.5,5.1,5.3c3.9,0.2,7.2-1.2,9.9-4.3
								c5-4.1,9.6-8.6,13.9-13.5c3.2-2.6,4.7-5.9,4.5-9.8c4.2-101.4,8.1-202.8,6.7-304.3c-0.7-50.5-2.7-100.9-6.6-151.3
								c-3.9-49.8-9.8-99.3-17.7-148.6c-1.2-2.5-3.2-3.9-6-4.3c-3.6,0.1-6.3,1.8-8.3,4.9c-3.8,4.3-7.1,8.9-10,13.8
								C911.6,397.9,910.9,401,911.9,404.4z"/>
							<path opacity="0.1091" fill="#131313" d="M931.2,396.7c-0.9,13.4,0.2,26.7,3,39.8c1.7,12.5,3.2,25,4.5,37.5
								c2.7,24.9,4.7,49.9,6.3,74.9c3.1,50,4.3,100.1,4.4,150.2c0,101-4.8,202-9.3,302.9c-0.6,2.6,0.1,4.9,2,6.8
								c1.8,2,4.1,2.7,6.7,2.2c3.8-1.9,7.2-4.4,10.1-7.5c3.2-2.8,5.8-6.1,7.7-9.9c4.3-101.4,8.7-202.8,8.1-304.4
								c-0.3-50.5-1.9-101.1-5.4-151.5c-1.8-25.2-4-50.4-6.8-75.4c-1.4-12.6-3-25.2-4.7-37.7c-2.8-12.6-4-25.4-3.6-38.4
								c-0.2-4.8-5.4-8.9-10.2-7.3c-3.1,2.1-5.7,4.7-7.8,7.9C933.9,389.8,932.2,393.1,931.2,396.7z"/>
							<path opacity="0.1091" fill="#131313" d="M950.3,431.8c1.6,9.1,3,18.1,4.2,27.3c1.3,9.3,2.6,18.7,3.6,28
								c-0.2-1.3-0.3-2.6-0.5-3.9c0.8,7.5,1.7,15.1,2.5,22.6c-0.1-1.3-0.3-2.6-0.4-3.8c4.4,43.7,7.4,87.4,8.9,131.3
								c1.5,44,1.8,88,1.3,131.9c-1,88.5-5.3,177-9.2,265.5c-0.8,2.7-0.2,5,1.8,7c2,2,4.3,2.7,7,1.9c4.1-2.2,7.8-5,11-8.4
								c3.5-3.1,6.4-6.7,8.6-10.8c4.8-108.2,10-216.6,8.9-325c-0.5-51-2.2-102-6-152.8c-3.4-45.1-8.6-90-15.6-134.7
								c-1.3-2.5-3.2-3.9-6-4.3c-3.5,0.1-6.3,1.8-8.3,4.9c-3.8,4.3-7.1,8.9-9.9,13.8C950,425.2,949.3,428.4,950.3,431.8z"/>
							<path opacity="0.1091" fill="#131313" d="M904,430.2c3.9,24.7,7.2,49.6,9.8,74.5c2.6,25,4.5,50,6,75
								c2.9,50.1,3.9,100.4,3.7,150.6c-0.4,101.2-5.5,202.2-10.1,303.3c0.2,3.2,1.9,5,5.1,5.3c5.2-0.7,9.5-3.2,13-7.3
								c7-6.1,13.6-12.5,19.9-19.4c4.2-3.4,6.8-7.7,7.7-12.8c4.1-101.8,8-203.6,6.4-305.5c-0.8-50.8-3-101.6-7.1-152.3
								c-2-25.3-4.6-50.6-7.6-75.8c-3-24.5-6.3-49-10.2-73.4c-0.9-3-2.9-4.4-6-4.3c-4.7,1.3-8.3,4.1-10.8,8.5
								c-5.4,6.6-10.4,13.4-14.9,20.6C905.3,421,903.8,425.4,904,430.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M60.4,1403.1c2.9-11.1,5.4-22.3,7.4-33.6c2.5-10.8,5.3-21.6,8.4-32.3
								c6.2-21.4,13.5-42.5,20.6-63.6c7.2-21.6,14.2-43.2,20.4-65.1c1.9-6.7,3.7-13.5,5.3-20.3c2.8-11.4,5.1-22.8,7-34.3
								c-0.3-2.7-1.6-4.8-3.9-6.3c-3.1-1.3-6-1-8.9,1.1c-4.7,2.3-9.2,5-13.3,8.3c-3.1,1.7-4.7,4.2-4.9,7.5c-1.6,8.5-3.4,17-5.5,25.4
								c-1.9,8.4-4.1,16.8-6.4,25.1c0.3-1.2,0.7-2.4,1-3.6c-4.7,16.5-9.6,32.9-14.8,49.3c-5.1,16.3-10.5,32.5-15.4,48.8
								c-4.9,16.2-9.8,32.5-13.6,49c-1.2,5.5-2.5,11.1-3.7,16.6c-1.8,11.1-4.1,22.1-6.7,33c-0.9,2.5-0.5,4.8,1.2,7
								c1.6,2.2,3.7,3.2,6.4,3c4-1.4,7.5-3.4,10.8-6.1C55.1,1409.5,58,1406.5,60.4,1403.1z"/>
							<path opacity="0.1091" fill="#131313" d="M36.3,1387.5c5-11.4,8.2-23.2,9.9-35.5c2.5-10.9,5.3-21.6,8.5-32.3
								c5.5-18.7,11.8-37.2,18.1-55.7c6.4-18.8,12.8-37.5,18.4-56.5c3.3-11,6.3-22.1,9.1-33.3c2.8-11.4,5.1-22.8,7.1-34.3
								c0.9-2.4,0.6-4.7-0.9-6.8c-1.3-2.3-3.2-3.5-5.8-3.8c-3.7,0.8-7.1,2.2-10.2,4.4c-3.3,1.8-6.1,4.2-8.4,7.2
								c-2,11.3-4.3,22.6-7.1,33.7c-2.8,11.1-5.8,22.2-9.1,33.2c-5.7,19.2-12.1,38.2-18.4,57.3c-6.3,19-12.6,38-18.1,57.3
								c-3.2,11-6,22.1-8.5,33.2c-1.5,11.9-4.4,23.4-8.8,34.5c-1.4,4.5,3,10,7.6,9.9c3.4-1,6.5-2.6,9.2-5
								C32,1393,34.4,1390.5,36.3,1387.5z"/>
							<path opacity="0.1091" fill="#131313" d="M42.7,1347.4c0.9-8.2,2.4-16.4,4.2-24.4c1.6-8.2,3.6-16.3,5.8-24.4
								c-0.4,1.3-0.7,2.7-1.1,4c1.9-6.7,3.7-13.4,5.6-20.1c-0.4,1.3-0.7,2.7-1.1,4c9.4-30.8,20.1-61.1,30.2-91.7
								c4.4-13.6,8.8-27.2,12.5-41c1.4-5.6,2.9-11.2,4.3-16.8c2.8-11.4,5.1-22.8,7-34.3c-0.1-2.8-1.4-4.9-3.9-6.2
								c-3.6-1.1-7-0.5-10.1,1.8c-5.5,2.8-10.7,6.1-15.6,9.8c-3.5,1.8-5.5,4.6-6.1,8.3c-1.9,11.2-4.2,22.3-6.9,33.3
								c-2,8.3-4.2,16.6-6.7,24.8c-5.6,19.5-11.9,38.7-18.3,57.9c0.4-1.3,0.9-2.6,1.3-3.8c-7.9,23.5-15.9,46.9-22.5,70.8
								c-1.6,5.6-3.1,11.3-4.6,16.9c-2.2,8.4-4.1,16.9-5.8,25.4c-1.8,7.9-3.2,15.9-4.1,24c0.3,2.8,1.8,4.8,4.5,5.8
								c3.9,0.7,7.3-0.4,10.3-3.1c5.5-3.5,10.6-7.5,15.4-11.9C40.5,1354.4,42.4,1351.3,42.7,1347.4z"/>
							<path opacity="0.1091" fill="#131313" d="M60.5,1374.4c0.9-8.2,2.4-16.4,4.2-24.4c1.6-8.2,3.6-16.3,5.8-24.4
								c-0.4,1.4-0.8,2.7-1.1,4.1c1.9-6.7,3.8-13.4,5.7-20.1c-0.4,1.4-0.8,2.7-1.2,4.1c2.1-6.7,4.1-13.3,6.2-20
								c-0.4,1.3-0.9,2.7-1.3,4c4.8-14.6,9.6-29.2,14.6-43.8c4.6-13.4,9.2-26.7,13.4-40.2c3.4-10.9,6.7-21.9,9.8-32.9
								c2.3-8.4,4.4-16.8,6.2-25.3c2-8.5,3.7-17.1,5.1-25.8c0-2.9-1.3-5-3.9-6.2c-3.8-1-7.4-0.3-10.8,2.2c-5.9,3-11.5,6.5-16.8,10.5
								c-3.7,1.9-6,4.8-6.7,8.7c-2.1,11.2-4.6,22.4-7.4,33.5c-2.8,11.1-5.9,22.2-9.1,33.1c-5.7,19.3-11.9,38.5-18,57.7
								c-6.1,19.2-12.1,38.5-17.2,58c-3,11.1-5.6,22.3-8,33.6c-2.4,10.6-4.2,21.3-5.6,32.1c0.3,2.8,1.8,4.8,4.5,5.9
								c3.9,0.7,7.3-0.4,10.4-3.1c5.5-3.5,10.7-7.5,15.5-11.9C58.2,1381.4,60.2,1378.3,60.5,1374.4z"/>
							<path opacity="0.1091" fill="#131313" d="M63.5,1343.8c1.3-11,3.3-21.9,6-32.7c2.5-10.8,5.3-21.5,8.5-32.1
								c5.5-18.6,11.8-37,18.1-55.4c6.4-18.7,12.7-37.5,18.4-56.4c3.3-11,6.3-22.2,9.1-33.3c2.8-11.4,5.1-22.9,7.1-34.4
								c0.3-3.1-1-5.2-3.9-6.2c-4.8-0.5-9.3,0.7-13.3,3.8c-7.5,4.1-14.8,8.6-21.7,13.6c-4.5,2.3-7.6,5.7-9.3,10.3
								c-2,11.1-4.4,22.1-7.1,33.1c-2.8,11.1-5.8,22.1-9.1,33.1c-5.8,19.5-12.1,38.9-18.3,58.3c-6.3,19.6-12.4,39.2-17.8,59.1
								c-3.1,11.3-5.8,22.7-8.2,34.2c-2.6,10.6-4.5,21.3-5.7,32.2c-0.1,3.2,1.4,5.1,4.5,5.8c5,0,9.4-1.8,13.3-5.4
								c7.3-4.9,14.4-10.3,21-16.1C59.3,1352.4,62.2,1348.6,63.5,1343.8z"/>
							<path opacity="0.1091" fill="#131313" d="M23,1374.3c4.1-11.9,7.5-24,10.1-36.4c2.6-10.8,5.6-21.5,8.7-32.2
								c5.6-18.7,12-37.2,18.2-55.7c6.3-18.7,12.7-37.5,18.3-56.4c3.3-11,6.3-22.1,9-33.3c2.8-11.4,5.1-22.9,7.1-34.4
								c0.6-2.4,0.2-4.6-1.1-6.6c-1.1-2.2-2.9-3.6-5.3-4.1c-3.1,0.4-6,1.4-8.6,3.2c-2.8,1.5-5.1,3.5-6.9,6.1
								c-1.9,11.4-4.1,22.7-6.8,33.8c-2.7,11.2-5.7,22.2-9,33.2c-5.7,19.2-12.2,38.3-18.6,57.3c-6.5,19.1-13,38.1-18.8,57.4
								c-3.2,10.9-6.2,21.9-8.9,33c-2.5,12.1-5.5,24.1-9,35.9c-1.1,4.1,3.5,9.8,7.6,9.9c3-0.7,5.7-2,8-4.1
								C19.5,1379.2,21.5,1377,23,1374.3z"/>
							<path opacity="0.1091" fill="#131313" d="M46,1404.8c3-11.5,5.7-23.1,8-34.8c2.7-10.9,5.6-21.7,8.9-32.4
								c5.6-18.6,12-37,18.2-55.5c6.3-18.7,12.7-37.4,18.3-56.3c3.3-11,6.3-22.2,9.1-33.4c2.8-11.4,5.1-22.9,7.1-34.5
								c0.7-2.4,0.3-4.6-1.1-6.6c-1.2-2.2-2.9-3.6-5.3-4.1c-3.2,0.4-6.2,1.5-8.9,3.3c-2.9,1.5-5.3,3.6-7.2,6.2
								c-2,11.5-4.4,22.9-7.2,34.2c-2.8,11.2-5.8,22.3-9.1,33.3c-5.6,19.1-11.9,38-18.1,56.9c-6.2,18.8-12.4,37.5-17.9,56.5
								c-3.2,10.9-6,21.9-8.6,33c-2.1,11.5-4.5,23-7.1,34.4c-0.9,4,3.5,9.8,7.6,10c2.9-0.6,5.4-1.8,7.7-3.8
								C42.8,1409.5,44.7,1407.4,46,1404.8z"/>
							<path opacity="0.1091" fill="#131313" d="M75.5,1370c1.4-11,3.4-21.9,6.1-32.7c2.5-10.8,5.4-21.6,8.7-32.3
								c5.6-18.7,12-37.2,18.3-55.6c6.4-18.8,12.9-37.5,18.5-56.5c3.3-11,6.3-22.1,9-33.3c2.7-11.4,5.1-22.8,6.9-34.3
								c0.3-3.1-1-5.2-3.9-6.3c-4.8-0.6-9.3,0.7-13.3,3.8c-7.5,4-14.8,8.6-21.7,13.5c-4.5,2.3-7.6,5.7-9.3,10.3
								c-2.2,11.2-4.7,22.3-7.5,33.3c-2.8,11.1-5.9,22.1-9.1,33.1c-5.7,19.4-11.8,38.6-17.7,57.9c-5.9,19.3-11.7,38.7-16.7,58.4
								c-2.9,11.2-5.4,22.4-7.7,33.8c-2.4,10.6-4.2,21.3-5.5,32c0,3.1,1.5,5,4.5,5.9c4.8,0.2,9-1.4,12.6-4.8
								c6.9-4.6,13.5-9.7,19.8-15.1C71.7,1378.3,74.4,1374.7,75.5,1370z"/>
							<path opacity="0.1091" fill="#131313" d="M32.6,1362.3c2.1-11,4.3-22,6.6-33c2.5-10.9,5.4-21.7,8.6-32.4
								c6.3-21.5,13.8-42.6,21-63.8c7.3-21.6,14.5-43.2,20.5-65.1c1.9-6.7,3.6-13.4,5.1-20.2c2.8-11.3,5.1-22.8,7-34.3
								c-0.4-2.7-1.7-4.7-3.9-6.2c-2.9-1.4-5.8-1.1-8.5,0.8c-4.5,2.2-8.6,4.8-12.5,7.9c-2.9,1.7-4.4,4.1-4.5,7.3
								c-1,5.6-2.1,11.2-3.1,16.8c0.3-1.2,0.5-2.3,0.8-3.5c-1.5,6.7-3,13.4-4.5,20.2c0.3-1.2,0.5-2.3,0.8-3.5
								c-2.7,11.1-5.7,22.2-9,33.1c-1.7,5.5-3.4,11-5.1,16.5c-6.1,19.3-12.8,38.4-19.2,57.7c-5.5,16.5-10.8,33.1-15.5,49.9
								c-2.4,8.4-4.6,16.8-6.4,25.3c-2.2,10.9-4.3,21.7-6.3,32.7c-1,2.6-0.6,4.9,1.1,7.1c1.7,2.2,3.8,3.2,6.6,2.8
								c4.2-1.6,8-3.8,11.4-6.7C26.9,1369,30,1366,32.6,1362.3z"/>
							<path opacity="0.1091" fill="#131313" d="M51.6,1356.6c1.5-11,3.5-21.9,6.1-32.7c2.5-10.8,5.4-21.6,8.6-32.3
								c5.5-18.7,11.9-37.2,18.2-55.6c6.4-18.8,12.8-37.5,18.5-56.5c3.3-11,6.3-22.1,9.1-33.3c2.8-11.4,5.1-22.8,7-34.3
								c0-2.9-1.3-5-3.9-6.2c-3.9-1-7.6-0.2-11,2.4c-6.1,3.1-11.8,6.8-17.3,10.8c-3.8,1.9-6.1,4.9-6.9,8.9
								c-2,11.2-4.4,22.3-7.1,33.3c-2.8,11.1-5.8,22.1-9.1,33.1c-5.8,19.4-12.1,38.7-18.4,57.9c-6.3,19.4-12.5,38.8-17.9,58.5
								c-3.1,11.2-5.9,22.5-8.3,33.9c-2.4,10.7-4.4,21.4-5.8,32.3c0.2,2.9,1.7,4.9,4.5,5.8c4.1,0.5,7.8-0.6,11-3.6
								c5.9-3.8,11.4-8.1,16.6-12.7C49,1364,51.1,1360.8,51.6,1356.6z"/>
							<path opacity="0.1091" fill="#131313" d="M71.8,1385.8c1.5-11,3.6-21.9,6.2-32.8c2.5-10.9,5.4-21.6,8.6-32.3
								c5.6-18.7,12-37.2,18.3-55.7c6.4-18.8,12.8-37.5,18.5-56.5c3.3-11,6.3-22.1,9-33.3c2.7-11.3,5.1-22.8,6.9-34.3
								c0.1-2.9-1.2-5-3.9-6.3c-4.1-0.9-7.9,0-11.5,2.6c-6.3,3.3-12.4,7.1-18.2,11.3c-3.9,2-6.4,5-7.4,9.1
								c-2.2,11.2-4.8,22.4-7.6,33.5c-2.9,11.1-5.9,22.1-9.1,33.1c-5.6,19.3-11.7,38.4-17.6,57.6c-5.9,19.1-11.6,38.3-16.5,57.7
								c-2.8,11.1-5.4,22.2-7.6,33.4c-2.3,10.6-4.1,21.3-5.6,32.1c0.3,2.9,1.8,4.8,4.5,5.9c4,0.6,7.6-0.5,10.7-3.4
								c5.7-3.7,11.1-7.8,16.2-12.3C69.3,1393,71.3,1389.9,71.8,1385.8z"/>
							<path opacity="0.1091" fill="#131313" d="M26.3,1386.1c3.1-11.5,5.8-23.1,8.1-34.8c2.6-10.9,5.6-21.6,8.8-32.3
								c5.5-18.7,11.9-37.1,18.1-55.5c6.4-18.7,12.7-37.5,18.3-56.5c3.3-11,6.3-22.1,9-33.3c2.8-11.4,5.1-22.8,7-34.4
								c0.7-3.9-2.1-10-6.4-10.8c-2.8,0.2-5.4,1.1-7.7,2.6c-2.5,1.3-4.5,3.1-6,5.5c-1.9,11.4-4.2,22.7-6.9,33.9
								c-2.7,11.2-5.7,22.3-9,33.3c-5.7,19.2-12.1,38.2-18.5,57.2c-6.4,18.9-12.8,37.8-18.4,56.9c-3.2,10.9-6.2,22-8.9,33.1
								c-2.2,11.5-4.6,23-7.2,34.5c-0.1,2.3,0.6,4.4,2.2,6.2c1.3,2,3.1,3.2,5.4,3.7c2.7-0.4,5-1.5,7.1-3.3
								C23.5,1390.6,25.2,1388.6,26.3,1386.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M53.6,1394.2c0.8-6.9,1.6-13.9,2.4-20.8c0-6.3-0.1-12.5-0.1-18.8
								c0-16,0.1-32.1,0.2-48.1c0.1-28.9,0.2-57.7,0.3-86.6c0.2-57.7,0.4-115.4,0.7-173.2c0.5-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2.1c-3.7,1.9-6.8,4.4-9.6,7.5c-3,2.8-5.4,6.1-7.2,9.8c-0.2,57.7-0.4,115.4-0.7,173.2
								c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1c-0.1,6.3,0,12.6,0.1,18.9c0.4,9.7-0.2,19.4-1.9,29.1
								c-0.4,2.6,0.3,4.8,2.2,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.4-6.9C49.5,1400.8,51.9,1397.7,53.6,1394.2z"/>
							<path opacity="0.1091" fill="#131313" d="M30.4,1378.1c1.2-7.4,2.4-14.8,3.5-22.2c-0.1-6.2-0.1-12.3-0.2-18.5
								c-0.1-16,0.1-32.1,0.2-48.1c0.1-28.9,0.2-57.7,0.3-86.6c0.2-57.7,0.4-115.4,0.7-173.2c0.3-2.6-0.5-4.8-2.4-6.5
								c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4.1-9,7c-2.8,2.7-5.1,5.8-6.7,9.3c-0.2,57.7-0.4,115.4-0.7,173.2
								c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1c-0.1,6.2,0,12.4,0.1,18.7c0.6,10.1-0.3,20.1-2.7,30.1
								c-0.6,4.7,4,9.6,8.8,8.9c3.3-1.5,6.2-3.6,8.7-6.3C26.8,1384.3,28.9,1381.4,30.4,1378.1z"/>
							<path opacity="0.1091" fill="#131313" d="M35.9,1337.8c0.6-115.2,1-230.5,1.4-345.7c-0.7-2.9-2.4-4.6-5.3-5.1
								c-4.2,0.1-7.7,1.9-10.4,5.4c-5.3,4.8-10.2,9.9-14.8,15.4c-3.3,2.9-5,6.4-4.9,10.7c-0.2,57.7-0.4,115.4-0.7,173.2
								c-0.1,25.7-0.2,51.3-0.3,77c-0.1,31.4-0.4,62.8-0.6,94.3c0.6,2.9,2.3,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5
								c5.3-4.5,10.3-9.5,14.9-14.8C34.2,1345.4,35.9,1341.9,35.9,1337.8z"/>
							<path opacity="0.1091" fill="#131313" d="M53.7,1364.8c0-57.4,0.4-114.9,0.6-172.3c0.2-57.7,0.4-115.4,0.7-173.2
								c-0.7-2.8-2.5-4.5-5.3-5.1c-4-0.1-7.3,1.5-9.9,4.8c-5,4.4-9.6,9.1-13.8,14.3c-3.1,2.7-4.6,6.1-4.4,10.1
								c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77c-0.1,31.4-0.4,62.8-0.5,94.2c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.9-13.7C52.3,1372.1,53.8,1368.8,53.7,1364.8z"/>
							<path opacity="0.1091" fill="#131313" d="M57,1333.5c-0.1-28.7,0.2-57.3,0.3-86c0.1-28.9,0.2-57.7,0.3-86.6
								c0.2-57.7,0.4-115.4,0.7-173.2c-0.3-3.2-2.1-4.9-5.3-5.1c-5.2,1-9.5,3.6-12.9,7.9c-6.9,6.4-13.5,13.2-19.6,20.4
								c-4.2,3.6-6.6,8-7.4,13.2c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1
								c0,6.3,0,12.7-0.2,19c-0.3,8.9-0.3,17.9-0.1,26.8c0.2,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.3,13-7.5c7-6.2,13.6-12.7,19.7-19.6
								C53.6,1342.9,56.1,1338.6,57,1333.5z"/>
							<path opacity="0.1091" fill="#131313" d="M17.4,1364.3c0.9-7.2,1.9-14.5,2.8-21.7c0-6.2,0-12.3,0-18.5
								c0-16,0.1-32.1,0.2-48.1c0.1-28.9,0.2-57.7,0.3-86.6c0.2-57.7,0.4-115.4,0.7-173.2c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.8-1.9-3.9-2.6-6.4-2.3c-3.3,1.5-6.1,3.6-8.4,6.4c-2.6,2.5-4.6,5.4-6,8.7c-0.2,57.7-0.4,115.4-0.7,173.2
								c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1c0,6.2,0,12.4,0.1,18.5c0.4,10-0.3,19.9-2.1,29.8
								c-0.5,4.5,4.3,9.5,8.8,8.9c3.1-1.3,5.8-3.2,8.1-5.7C14.2,1370.1,16.1,1367.5,17.4,1364.3z"/>
							<path opacity="0.1091" fill="#131313" d="M39.4,1395.4c0.6-6.9,1.1-13.9,1.7-20.8c0.1-6.4,0.1-12.9,0.2-19.3
								c0.1-16.1,0.3-32.2,0.4-48.3c0.1-28.9,0.2-57.7,0.3-86.6c0.2-57.7,0.4-115.4,0.7-173.2c0-4.2-4.4-9.3-8.8-8.9
								c-2.8,1-5.3,2.6-7.3,4.9c-2.3,2-3.9,4.4-4.9,7.2c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77
								c-0.1,16.1-0.1,32.1-0.3,48.2c-0.1,6.4-0.1,12.8-0.1,19.2c0.2,10-0.2,19.9-1.1,29.9c0.1,2.4,1.1,4.3,2.9,5.9
								c1.6,1.8,3.6,2.8,5.9,3c2.7-0.8,5-2.3,6.9-4.5C37,1400.4,38.5,1398.1,39.4,1395.4z"/>
							<path opacity="0.1091" fill="#131313" d="M68.4,1360.5c0-57.5,0.5-115,0.7-172.5c0.2-57.7,0.4-115.4,0.7-173.2
								c-0.5-3.1-2.2-4.8-5.3-5.1c-4.7,0.6-8.7,2.8-11.7,6.7c-6.2,5.6-11.9,11.6-17.3,17.9c-3.8,3.2-5.9,7.2-6.2,12
								c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1c0,15.3-0.4,30.6-0.4,45.9
								c0.4,3,2.1,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.8-6.3c6.2-5.4,12-11.1,17.4-17.3C65.8,1369.1,68,1365.2,68.4,1360.5z"/>
							<path opacity="0.1091" fill="#131313" d="M26.7,1348.7c0-57.4,0.4-114.8,0.6-172.3c0.2-57.7,0.4-115.4,0.7-173.2
								c-0.9-2.6-2.7-4.3-5.3-5.1c-3.5-0.4-6.5,0.9-8.9,3.7c-4.3,3.7-8.2,7.8-11.8,12.3c-2.8,2.5-3.9,5.5-3.3,9
								c-0.4,114.8-0.6,229.6-1.4,344.4c0.8,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.4-0.7,8.9-3.4c4.4-3.6,8.3-7.5,11.9-11.8
								C26.1,1355.2,27.2,1352.2,26.7,1348.7z"/>
							<path opacity="0.1091" fill="#131313" d="M44.9,1346.9c0.5-115.2,1-230.5,1.4-345.7c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.3,0.2-7.9,2.1-10.8,5.7c-5.5,5-10.7,10.3-15.4,16.1c-3.4,3-5.2,6.6-5.2,11c-0.2,57.7-0.4,115.4-0.7,173.2
								c-0.1,25.7-0.2,51.3-0.3,77c-0.1,16-0.1,32.1-0.2,48.1c0,15.4-0.3,30.7-0.4,46.1c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.3c5.6-4.8,10.7-9.9,15.5-15.4C43,1354.8,44.8,1351.2,44.9,1346.9z"/>
							<path opacity="0.1091" fill="#131313" d="M65,1376.2c0-57.4,0.4-114.9,0.6-172.3c0.2-57.7,0.4-115.4,0.7-173.2
								c-0.7-2.8-2.5-4.5-5.3-5.1c-4-0.1-7.3,1.5-9.9,4.8c-5,4.4-9.6,9.1-13.8,14.3c-3.1,2.7-4.6,6.1-4.4,10.1
								c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77c-0.1,31.4-0.4,62.8-0.5,94.2c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.7-8.8,13.9-13.7C63.7,1383.4,65.2,1380.1,65,1376.2z"/>
							<path opacity="0.1091" fill="#131313" d="M20.3,1376.3c0.6-7,1.1-13.9,1.7-20.9c0.1-6.4,0.1-12.9,0.2-19.3
								c0.1-16.1,0.3-32.2,0.4-48.3c0.1-28.9,0.2-57.7,0.3-86.6c0.2-57.7,0.4-115.4,0.7-173.2c0-4.2-4.3-9.3-8.8-8.9
								c-2.9,1-5.3,2.7-7.4,5c-2.3,2-4,4.5-5,7.3c-0.2,57.7-0.4,115.4-0.7,173.2c-0.1,25.7-0.2,51.3-0.3,77
								c-0.1,16.1-0.1,32.1-0.3,48.2C1,1336.2,1,1342.6,1,1349c0.2,10-0.2,19.9-1.1,29.9c0.1,2.4,1.1,4.4,2.9,6
								c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,5-2.4,6.9-4.6C17.8,1381.3,19.4,1379,20.3,1376.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M46,1389.2c2.3-13.3,3.1-26.8,2.3-40.3c0.1-9.8,0.2-19.5,0.3-29.3
								c0.3-19.5,0.8-38.9,1.4-58.4c1.3-42.1,3.3-84.1,5.9-126.2c5.2-84,13.3-167.7,23.5-251.3c0.8-2.6,0.3-4.9-1.5-6.9
								c-1.7-2.1-3.9-3-6.6-2.6c-3.9,1.6-7.5,3.9-10.6,6.9c-3.4,2.6-6.2,5.8-8.4,9.4c-9.9,83.9-17.6,168.1-22.6,252.5
								c-2.3,39-4,77.9-5.2,116.9c-0.6,19.5-1,39-1.4,58.6c-0.2,13-0.4,26.1-0.4,39.1c0.8,13,0.2,26-1.7,39
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.5-1.7,6.6-4,9.3-7C42,1395.8,44.3,1392.7,46,1389.2z"/>
							<path opacity="0.1091" fill="#131313" d="M22.9,1373c3.3-13.6,4.4-27.3,3.3-41.2c0.1-9.8,0.2-19.5,0.3-29.3
								c0.3-19.5,0.8-38.9,1.4-58.4c1.3-42.1,3.3-84.2,6-126.2c5.3-84,13.4-167.8,23.7-251.4c0.6-2.5-0.1-4.8-1.8-6.7
								c-1.6-2.1-3.7-3-6.3-2.8c-3.6,1.3-6.8,3.3-9.5,6c-3,2.4-5.5,5.2-7.4,8.5C22.2,955.6,14.2,1039.9,9,1124.5
								c-2.4,39-4.2,78.1-5.6,117.2c-0.7,19.5-1.1,39.1-1.5,58.7c-0.2,13.1-0.4,26.1-0.5,39.2c1,13.3,0.2,26.6-2.5,39.7
								c-0.6,4.7,4.1,9.6,8.9,8.8c3.3-1.5,6.2-3.6,8.7-6.4C19.3,1379.2,21.4,1376.3,22.9,1373z"/>
							<path opacity="0.1091" fill="#131313" d="M28.3,1332.7c0.1-84.1,2.6-168.2,7.9-252.2c5.3-84,13.4-167.8,23.7-251.3
								c-0.5-2.8-2.1-4.7-4.8-5.6c-4-0.4-7.5,0.9-10.4,3.9c-5.5,4-10.5,8.4-15.3,13.2c-3.4,2.5-5.2,5.7-5.4,9.7
								C13.8,935.1,5.8,1019.9,0.5,1105c-5.2,84.3-7.5,168.7-7.7,253.1c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5.1
								c5.3-4.6,10.3-9.6,14.8-15C26.6,1340.4,28.3,1336.9,28.3,1332.7z"/>
							<path opacity="0.1091" fill="#131313" d="M46.1,1359.8c-0.4-84.1,2.5-168.2,7.9-252.1c5.3-84,13.5-167.8,23.8-251.4
								c-0.5-2.8-2.1-4.7-4.8-5.6c-4-0.4-7.4,0.8-10.3,3.8c-5.4,3.9-10.4,8.2-15.1,12.9c-3.4,2.4-5.2,5.6-5.3,9.6
								c-9.9,84.3-17.6,168.8-22.5,253.5c-4.9,84-7.2,168.2-7,252.3c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5
								c5-4.2,9.6-8.8,13.8-13.8C44.8,1367,46.3,1363.7,46.1,1359.8z"/>
							<path opacity="0.1091" fill="#131313" d="M49.4,1328.3c-0.3-42,0.5-84,1.8-126c1.3-42,3.3-84.1,6-126
								c5.3-83.9,13.4-167.6,23.7-251c0-3.2-1.6-5.1-4.8-5.6c-5.1,0.4-9.5,2.5-13.2,6.3c-7.3,5.5-14.2,11.4-20.7,17.8
								c-4.4,3.1-7.1,7.1-8.2,12.1c-10,84.7-17.7,169.6-22.7,254.7c-2.3,39.3-4,78.5-5.2,117.9c-0.6,19.6-1,39.3-1.3,58.9
								c-0.2,13.1-0.3,26.2-0.4,39.2c-0.4,12.3-0.5,24.6-0.2,36.9c0.3,3.2,2,4.9,5.2,5.2c5.2-0.9,9.5-3.4,12.9-7.6
								c6.9-6.2,13.5-12.8,19.7-19.8C46.1,1337.8,48.6,1333.5,49.4,1328.3z"/>
							<path opacity="0.1091" fill="#131313" d="M9.8,1359.2c2.6-13.5,3.5-27,2.7-40.7c0-9.8,0.2-19.5,0.3-29.3
								c0.3-19.5,0.8-38.9,1.4-58.4c1.3-42.1,3.4-84.2,6.1-126.3c5.4-84.1,13.5-168,23.9-251.7c0.6-4.5-3.3-9.8-8.1-9.5
								c-3.2,1.1-6.1,2.8-8.6,5.1c-2.7,2.1-4.9,4.6-6.4,7.7C10.7,940.5,2.4,1025-3,1109.9c-2.5,39.1-4.4,78.2-5.8,117.3
								c-0.7,19.6-1.2,39.1-1.6,58.7c-0.3,13.1-0.4,26.1-0.5,39.2c0.8,13.2,0.1,26.2-2,39.3c-0.4,4.5,4.3,9.4,8.9,8.8
								c3.1-1.3,5.8-3.3,8.1-5.8C6.6,1365.1,8.6,1362.4,9.8,1359.2z"/>
							<path opacity="0.1091" fill="#131313" d="M31.8,1390.4c1.5-13.6,2.1-27.1,1.8-40.8c0.2-9.8,0.4-19.6,0.5-29.4
								c0.4-19.5,0.8-39,1.5-58.4c1.3-42.1,3.4-84.2,6-126.2c5.4-84,13.5-167.8,23.8-251.3c0.5-4.2-3.4-9.7-7.9-9.7
								c-3,0.8-5.6,2.2-7.9,4.3c-2.5,1.8-4.4,4.1-5.8,6.9c-10.2,83.9-18.2,168.1-23.5,252.4c-2.4,38.9-4.2,77.9-5.6,116.9
								c-0.7,19.5-1.1,39-1.5,58.6c-0.2,13.1-0.4,26.1-0.6,39.2c0.4,13.4,0,26.7-1,40c0.2,2.4,1.1,4.3,2.9,5.9
								c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,4.9-2.4,6.8-4.5C29.4,1395.3,30.9,1393,31.8,1390.4z"/>
							<path opacity="0.1091" fill="#131313" d="M60.8,1355.4c-0.3-84.1,2.6-168.3,8-252.3c5.4-84,13.6-167.8,23.9-251.4
								c-0.2-3.1-1.8-5-4.8-5.6c-4.8,0.1-9,2-12.4,5.6c-6.7,5.1-13.1,10.5-19.2,16.4c-4.1,2.9-6.6,6.7-7.5,11.4
								C39.2,964,31.7,1048.6,27,1133.3c-2.2,39.1-3.8,78.3-4.9,117.4c-0.6,19.6-0.9,39.2-1.2,58.7c-0.4,25.4-0.7,50.8-0.6,76.1
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,11.9-11.2,17.3-17.4C58.3,1364,60.4,1360.1,60.8,1355.4z"/>
							<path opacity="0.1091" fill="#131313" d="M19.2,1343.6c-0.3-58.1,1.2-116.3,3.6-174.4c-0.1,1.3-0.1,2.6-0.2,3.8
								c0.4-7.8,0.7-15.5,1.1-23.3c-0.1,1.3-0.1,2.6-0.2,3.8c0.4-7.7,0.8-15.5,1.2-23.2c-0.1,1.3-0.1,2.5-0.2,3.8
								c2.7-48.5,6.1-97,10.4-145.4c4.3-48.4,9.6-96.7,15.6-144.9c0.9-2.6,0.5-5-1.3-7.1c-1.8-2.1-4.1-2.9-6.8-2.4
								c-4.2,1.9-8,4.5-11.4,7.7c-3.7,2.9-6.8,6.3-9.3,10.2C10,946.4,1.3,1040.9-4,1135.6c-4.2,75.7-6.1,151.5-6.3,227.3
								c0.9,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.5-0.7,8.9-3.5c4.3-3.6,8.3-7.6,11.9-12C18.5,1350.1,19.7,1347.1,19.2,1343.6z"/>
							<path opacity="0.1091" fill="#131313" d="M37.3,1341.9c0-84.1,2.6-168.2,7.9-252.2c5.3-84,13.4-167.8,23.7-251.3
								c-0.4-2.9-2-4.8-4.8-5.6c-4.2-0.3-7.9,1.2-11,4.4c-5.8,4.3-11.2,8.9-16.3,14c-3.6,2.6-5.6,6-6,10.2
								C20.9,945.9,13,1030.6,8,1115.6c-5,84.2-7.3,168.5-7.4,252.8c0.6,2.9,2.3,4.6,5.2,5.2c4.3-0.1,7.9-1.9,10.7-5.4
								c5.5-4.8,10.7-10,15.5-15.6C35.5,1349.8,37.3,1346.2,37.3,1341.9z"/>
							<path opacity="0.1091" fill="#131313" d="M57.4,1371.2c-0.4-84.1,2.5-168.2,7.9-252.1c5.3-84.1,13.5-167.9,23.8-251.4
								c-0.4-2.9-2-4.7-4.8-5.6c-4-0.4-7.6,0.9-10.6,4c-5.5,4-10.7,8.4-15.5,13.3c-3.5,2.5-5.3,5.7-5.6,9.8
								c-9.7,84.1-17.2,168.5-21.9,253.1c-4.7,83.9-6.8,168-6.7,252c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5
								c5-4.2,9.6-8.8,13.8-13.8C56.1,1378.4,57.6,1375.1,57.4,1371.2z"/>
							<path opacity="0.1091" fill="#131313" d="M12.7,1371.2c1.5-13.6,2.1-27.1,1.8-40.8c0.2-9.8,0.4-19.6,0.5-29.4
								c0.4-19.5,0.8-38.9,1.4-58.4c1.3-42.1,3.3-84.1,5.9-126.1c5.3-84,13.4-167.7,23.6-251.2c0.5-4.1-3.7-9.6-7.9-9.7
								c-2.8,0.7-5.4,2-7.5,4c-2.4,1.7-4.2,3.9-5.4,6.6c-10.3,84-18.5,168.3-23.9,252.7c-2.5,39-4.3,78-5.7,117.1
								c-0.7,19.5-1.2,39.1-1.6,58.6c-0.2,13.1-0.5,26.2-0.7,39.2c0.4,13.4,0,26.7-1.1,40.1c0.1,2.4,1.1,4.4,2.9,5.9
								c1.6,1.8,3.6,2.8,6,2.9c2.7-0.9,5-2.5,6.9-4.6C10.3,1376.3,11.8,1373.9,12.7,1371.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M33.2,1457.8c4,2.5,8,5.1,12.3,7c6.3,2.8,13.2,4.4,20,5.5c3.6,0.5,7.1,0.9,10.8,1.1
								c5.3,0.2,10.2-0.1,15.6-0.4c2.9-2,3.8-4.8,2.9-8.4c-1.5-5.4-3.5-10.6-6-15.6c-2.7-6.4-5.8-12.6-9.3-18.6
								c-1.9-5.1-5.1-8.8-9.7-11.2c-6.7-1.5-13-3.3-19.2-6.3c-2.7-1.2-5.3-2.6-7.7-4.3c-2.7-2.3-5.6-4.4-8.8-6.1
								c-2.7-1.8-5.1-1.5-7.3,0.8c-2.6,4.6-3.3,9.5-2.1,14.8c0.5,9.3,1.6,18.5,3.2,27.7C27.9,1449.3,29.7,1453.9,33.2,1457.8z"/>
							<path opacity="0.1091" fill="#131313" d="M10.4,1437.7c3.7,2.7,7.6,5,11.9,6.9c3.6,1.7,7.4,3,11.3,3.9
								c4,1.2,8.2,2.1,12.4,2.5c5.9,0.7,11.8,1,17.7,0.7c2.8,0.1,5-1,6.6-3.3c1.5-3.4,1.3-6.7-0.7-10c-2.2-5.6-4.8-10.9-8-16
								c-1.5-3.6-4-5.8-7.6-6.6c-4.6-0.7-9.1-1.9-13.4-3.4c-3.3-1.1-6.4-2.4-9.4-4c-6.5-3.2-12.1-7.4-17.9-11.6
								c-2.6-1.2-5-0.9-7.3,0.8c-2.6,3-3.5,6.6-2.6,10.7c0.2,6.6,1,13.2,2.3,19.7C5.6,1432.1,7.3,1435.4,10.4,1437.7z"/>
							<path opacity="0.1091" fill="#131313" d="M10.5,1401.1c1.8,4.5,4.8,7.4,9,8.7c4.6,2.1,9.4,3.9,14.3,5.4
								c5.5,1.7,11,2.9,16.7,3.6c6,0.7,12.1,0.8,18.1,0.5c2.5-0.4,4.8-1.4,6.7-3c1.9-1.6,2.4-3.5,1.6-5.8c-0.7-3-2-5.7-3.7-8.2
								c-1.2-2.2-3-3.1-5.4-2.7c-7.8,0.1-15.5,0-23.1-2c-6.9-1.9-13.5-4.6-20.2-7c0.9,0.4,1.8,0.8,2.8,1.2c-2-2.2-4-4.5-6-6.7
								c-2-1.3-4.1-1.7-6.4-1.2c-2.4,0.1-4.4,1-6,2.7c-1,2.4-1.3,4.9-0.8,7.6C8.1,1396.6,8.9,1399,10.5,1401.1z"/>
							<path opacity="0.1091" fill="#131313" d="M31.6,1430.5c3.7,2.6,7.6,4.8,11.8,6.7c3.6,1.7,7.4,3,11.3,4
								c4.1,1.2,8.2,2.1,12.4,2.6c5.9,0.7,11.8,1,17.8,0.7c2.8,0.1,5-1,6.6-3.3c1.5-3.5,1.2-6.9-0.9-10.3c-2.3-5.8-5-11.3-8.3-16.5
								c-1.5-3.7-4.1-6-7.7-6.9c-4.3-0.1-8.5-0.6-12.7-1.5c-3.2-0.6-6.3-1.6-9.2-2.8c-6.6-2.5-12.2-6-18-10c-2.6-1.1-5-0.8-7.3,0.8
								c-2.6,2.7-3.5,5.9-2.7,9.8c0.1,6.1,0.8,12,2.1,18C26.9,1425.5,28.5,1428.4,31.6,1430.5z"/>
							<path opacity="0.1091" fill="#131313" d="M29.5,1398.8c2.1,2.5,4.2,5,6.2,7.5c0.9,0.4,1.8,0.8,2.8,1.2
								c3.5,1.5,6.9,3,10.5,4.3c5.4,1.9,10.7,3.5,16.3,4.5c2.9,0.7,5.9,1.1,8.9,1.2c4.6,0.5,9.2,0.7,13.8,0.4c2.6-0.3,4.8-1.3,6.7-3
								c1.9-1.8,2.3-4,1.3-6.5c-0.9-3.4-2.4-6.6-4.3-9.6c-1.2-2.4-3.1-3.6-5.7-3.4c-7.6,0.4-15.1,0.7-22.5-1.1
								c-6.8-1.6-13.4-4.1-20-6.4c0.9,0.4,1.8,0.8,2.8,1.2c-2-2.2-4-4.4-6-6.6c-2-1.2-4.1-1.7-6.4-1.2c-2.4,0.1-4.4,1-6,2.7
								c-1,2.4-1.3,4.9-0.8,7.6C27.1,1394.3,28,1396.7,29.5,1398.8z"/>
							<path opacity="0.1091" fill="#131313" d="M-7,1422.3c2.2,3.6,5.1,6.5,8.6,8.7c4.5,1.8,9,3.7,13.6,5.5
								c5.4,1.8,10.9,3.2,16.6,4c6,0.9,12.1,1.3,18.2,1.1c2.6-0.3,4.8-1.4,6.6-3.3c1.7-2.3,1.9-4.7,0.6-7.4
								c-1.3-3.9-3.1-7.5-5.4-10.9c-1.3-2.7-3.4-4-6.2-3.9c-5.3-0.6-10.5-1.7-15.5-3.3c-4.8-1.5-9.5-3.5-13.9-5.8c1,0.6,2,1.2,3,1.7
								c-4.7-2.8-9.4-5.4-14.2-8c1,0.7,1.9,1.4,2.9,2.1c-1.7-2-3.4-4-5.1-6c-3.3-3.9-10.1-3.2-12.4,1.4c-0.9,4-1,8.1-0.3,12.2
								C-9.7,1414.6-8.7,1418.5-7,1422.3z"/>
							<path opacity="0.1091" fill="#131313" d="M17.4,1461.2c4,2.5,8,5,12.3,6.9c6.3,2.7,13.1,4.1,19.8,5c7.4,0.9,14.7,1,22.1,0.4
								c3,0.6,5.2-0.5,6.6-3.2c1.1-4.7,0.3-9.1-2.3-13.4c-3.2-7.7-6.9-15.2-11-22.5c-1.8-4.7-4.8-8-9.2-10c-7.2-2.2-14-4.7-20.6-8.3
								c-2.8-1.5-5.4-3.1-8-5c-2.7-2.5-5.7-4.7-8.9-6.5c-2.7-1.8-5.1-1.5-7.3,0.8c-2.6,4.5-3.3,9.4-2.2,14.7
								c0.5,9.2,1.5,18.3,3.1,27.4C12,1452.8,13.9,1457.4,17.4,1461.2z"/>
							<path opacity="0.1091" fill="#131313" d="M45.5,1426.8c3.6,2.8,7.6,5,11.8,6.8c3.7,1.7,7.4,3.1,11.4,4
								c4.1,1.3,8.2,2.1,12.5,2.6c5.9,0.7,11.9,1,17.9,0.7c2.8,0.2,5-0.9,6.6-3.3c1.4-3.6,1-7.2-1.1-10.7c-2.4-6-5.3-11.8-8.7-17.3
								c-1.5-3.8-4.2-6.2-7.9-7.3c-4.1,0.3-8.1,0.2-12.2-0.3c-3.1-0.3-6.1-1-9.1-2c-3-0.8-5.9-1.9-8.6-3.4c-3.3-1.6-6.4-3.5-9.3-5.8
								c-2.6-1-5-0.7-7.3,0.9c-2.5,2.5-3.5,5.7-2.7,9.4c0.1,5.8,0.8,11.6,2,17.2C40.8,1422.1,42.5,1424.9,45.5,1426.8z"/>
							<path opacity="0.1091" fill="#131313" d="M1.5,1410.6c1.8,4.5,4.8,7.8,8.9,9.8c4.4,1.9,8.9,3.7,13.4,5.3
								c5.6,1.9,11.3,3.2,17.2,3.9c6.2,0.8,12.3,1,18.5,0.7c2.6-0.3,4.8-1.3,6.7-3.1c1.8-2,2.2-4.3,1.1-6.8c-1-3.6-2.6-6.9-4.7-10
								c-1.2-2.5-3.2-3.7-5.9-3.6c-4.1,0-8.1-0.4-12.1-1.2c-4-0.5-7.9-1.5-11.7-2.8c-6.4-2.2-12.3-5.2-18.4-8
								c0.9,0.6,1.9,1.1,2.8,1.7c-1.9-2.4-3.7-4.8-5.6-7.2c-2.9-3.7-10.3-2.2-12.4,1.4c-0.9,3.3-1.1,6.7-0.5,10.1
								C-1,1404.3-0.1,1407.6,1.5,1410.6z"/>
							<path opacity="0.1091" fill="#131313" d="M19.7,1409c1.9,4.5,4.8,7.7,8.9,9.7c4.4,1.8,8.9,3.5,13.3,5.1
								c5.4,1.8,10.9,3,16.5,3.7c6,0.7,11.9,0.9,17.9,0.6c2.6-0.3,4.8-1.4,6.6-3.3c1.7-2.3,1.9-4.9,0.4-7.5c-1.4-4-3.3-7.7-5.7-11.2
								c-1.3-2.7-3.5-4.1-6.4-4.1c-7.2-0.1-14.3-0.5-21.3-2.5c-6-1.8-11.7-4.4-17.5-6.7c0.9,0.6,1.9,1.2,2.8,1.7
								c-1.8-2.3-3.7-4.7-5.5-7c-2.9-3.7-10.3-2.3-12.4,1.4c-0.9,3.4-1.1,6.8-0.5,10.3C17.1,1402.6,18,1405.9,19.7,1409z"/>
							<path opacity="0.1091" fill="#131313" d="M43,1442.1c3.8,2.8,7.8,5.2,12,7.2c3.7,1.7,7.5,3.1,11.4,4
								c4.1,1.3,8.3,2.1,12.6,2.6c6,0.8,12,1,18,0.7c3,0.6,5.2-0.5,6.6-3.3c1.1-4.5,0.4-8.9-2.2-13.1c-3.1-7.5-6.7-14.8-10.8-21.9
								c-1.7-4.6-4.8-7.8-9-9.7c-3.8-0.1-7.6-0.6-11.3-1.4c-2.9-0.6-5.7-1.4-8.4-2.6c-2.8-0.9-5.5-2.2-8-3.7c-3-2-6-4-9-6
								c-2.6-1.4-5.1-1.1-7.3,0.9c-2.6,3.6-3.4,7.7-2.4,12.2c0.3,7.6,1.2,15.2,2.6,22.7C38,1435.4,39.7,1439.2,43,1442.1z"/>
							<path opacity="0.1091" fill="#131313" d="M-2.1,1439c3.7,2.8,7.6,5,11.9,6.8c3.7,1.7,7.4,3,11.3,3.9
								c4.1,1.2,8.2,2.1,12.4,2.5c5.9,0.7,11.9,1,17.9,0.9c2.7,0,4.9-1.2,6.6-3.3c1.6-2.9,1.5-5.9-0.2-9c-1.9-4.9-4.2-9.6-7-14
								c-1.4-3.2-3.8-5.1-7.1-5.6c-4.9-1-9.7-2.4-14.4-4.2c-3.4-1.3-6.7-2.8-9.9-4.6c-6.6-3.5-12.5-7.9-18.4-12.3
								c-2.6-1.1-5-0.8-7.3,0.8c-2.6,2.8-3.5,6.2-2.7,10.1c0.2,6.3,0.9,12.5,2.1,18.6C-6.8,1433.7-5.2,1436.8-2.1,1439z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M60.5,1453.7c7.8-1,15.6-1.2,23.4-0.7c7.7,0.4,15.4,1.4,23,2.9
								c-1.7-0.4-3.4-0.8-5.1-1.2c5.4,1.3,10.8,2.6,16.2,3.9c-1.7-0.5-3.4-0.9-5.1-1.4c5.3,1.6,10.6,3.2,15.9,4.8
								c3.2-0.2,5-1.9,5.4-5c-0.6-5.1-2.9-9.5-7-13c-5.9-7.1-12.1-13.8-18.8-20.1c-3.3-4.3-7.5-6.9-12.6-7.8
								c-12.4-1.8-24.7-3.4-37.2-3.5c-4.1,0-8.3,0-12.4,0.1c-7.7-0.1-15.4,0.4-23,1.6c-3.1,0.7-4.6,2.6-4.6,5.7
								c1.2,4.9,3.9,8.7,8.3,11.6c6.5,6,13.3,11.5,20.5,16.6C51.2,1451.7,55.5,1453.6,60.5,1453.7z"/>
							<path opacity="0.1091" fill="#131313" d="M32.4,1435.4c11.7-0.5,23.5-1.2,35.1,0.2c3.8,0.5,7.6,1.1,11.3,1.6
								c7.4,1.3,14.7,3.1,21.9,5.4c2.7,0.9,5.1,0.4,7.1-1.5c2.1-1.9,2.7-4.3,2-7c-2.2-4.3-5-8.1-8.4-11.5c-3.1-3.7-6.8-6.7-10.9-9.2
								c-11.8-2.4-23.6-4.6-35.7-5.2c-4-0.1-8.1-0.2-12.1-0.4c-7.8,0.1-15.5,0.5-23.2,1.1c-2.7-0.4-5,0.5-6.7,2.7
								c-1.8,2.1-2.2,4.5-1.2,7.1c2.5,3.7,5.7,7,9.3,9.7C24.4,1431.4,28.2,1433.7,32.4,1435.4z"/>
							<path opacity="0.1091" fill="#131313" d="M35.8,1401.8c6.6,0.4,13.2,0.7,19.8,0.7c5.9-0.1,11.8,0.3,17.6,1.1
								c3.8,0.6,7.5,1.2,11.3,1.8c7.4,1.4,14.6,3.3,21.7,5.7c2.4,0,4.4-0.9,6.1-2.6c1.9-1.5,2.9-3.5,3.1-5.9
								c-0.8-2.8-2.3-5.2-4.4-7.2c-1.8-2.3-4.1-3.9-6.8-5c-5.8-1.9-11.6-3.4-17.6-4.5c-5.9-1.4-11.9-2.4-18-3
								c-4.1-0.3-8.2-0.7-12.2-1c-8.5,0.1-17,0.1-25.4,0c-2.3,0.4-4.2,1.6-5.6,3.5c-1.6,1.8-2.4,3.9-2.3,6.2
								c1.2,2.6,2.9,4.7,5.3,6.3C30.6,1400,33,1401.2,35.8,1401.8z"/>
							<path opacity="0.1091" fill="#131313" d="M57.5,1427.8c7.7-1,15.5-1.3,23.3-0.8c-1.4,0-2.8-0.1-4.3-0.1
								c7.7,0.3,15.4,1.1,23,2.4c7.4,1.3,14.8,3.1,22,5.4c2.8,1.3,5.3,0.9,7.6-1c2.1-2.2,2.6-4.7,1.5-7.5
								c-2.8-4.9-6.2-9.4-10.3-13.4c-3.7-4.3-8-8-12.7-11.1c-8.1-2-16.4-3.5-24.7-4.5c-4.8-0.6-9.7-1-14.6-1.3
								c-4.9-0.3-9.7-0.5-14.6-0.4c-7.8-0.2-15.5,0.3-23.2,1.3c-2.7,1-4.2,2.9-4.6,5.7c0.3,4,2.2,7.1,5.6,9.4
								c4.8,4.6,9.9,8.7,15.4,12.4C50.1,1427.2,53.5,1428.4,57.5,1427.8z"/>
							<path opacity="0.1091" fill="#131313" d="M53.1,1398.1c6.7,1.3,13.5,1.9,20.4,1.5c5.8-0.2,11.6,0,17.4,0.9
								c3.8,0.6,7.6,1.2,11.4,1.8c7.5,1.4,14.8,3.4,22.1,5.8c4.7,1.4,9.7-4.2,9.1-8.6c-1.4-3.4-3.4-6.4-6.1-9
								c-2.4-2.8-5.2-5.1-8.5-6.7c-6-2-12.2-3.5-18.4-4.7c-6.1-1.4-12.4-2.4-18.6-3c-4.2-0.3-8.4-0.6-12.5-0.9
								c-8.6,0.6-17.2,0.3-25.7-0.9c-4.9-0.4-9.2,5.1-7.8,9.8c2,3.3,4.5,6,7.6,8.2C46.3,1395,49.5,1396.9,53.1,1398.1z"/>
							<path opacity="0.1091" fill="#131313" d="M18.4,1423.6c6.6,0.4,13.2,0.7,19.8,0.7c5.9-0.1,11.8,0.3,17.7,1.2
								c3.8,0.6,7.6,1.2,11.4,1.9c7.4,1.5,14.8,3.5,21.9,5.9c2.4,0.2,4.5-0.7,6.3-2.4c1.9-1.6,2.9-3.6,3-6c-1-3-2.7-5.6-4.9-7.9
								c-2-2.5-4.5-4.4-7.4-5.6c-5.7-1.7-11.5-3.1-17.3-4.1c-5.8-1.3-11.7-2.2-17.7-2.7c-4-0.3-8.1-0.6-12.1-0.9
								c-8.4,0.2-16.9,0.2-25.3,0.1c-2.3,0.4-4.2,1.6-5.6,3.5c-1.6,1.8-2.4,3.8-2.3,6.2c1.1,2.6,2.9,4.7,5.2,6.3
								C13.2,1421.7,15.7,1423,18.4,1423.6z"/>
							<path opacity="0.1091" fill="#131313" d="M71.9,1424.6c5.8-0.8,11.6-1.1,17.5-0.8c5.8-0.1,11.6,0.3,17.4,1
								c-1.6-0.2-3.1-0.4-4.7-0.6c5.3,0.8,10.6,1.5,15.9,2.3c-1.5-0.2-3.1-0.5-4.6-0.7c7.4,1.3,14.7,3.1,21.9,5.4
								c1.5,0.3,3,0.7,4.5,1c1.2-0.5,2.4-1.1,3.5-1.6c0.6-1.1,1.2-2.3,1.8-3.4c0.2-3.1-0.8-5.7-2.9-7.8c-3.9-5.7-8.7-10.8-13.6-15.7
								c-2.1-2.4-4.5-4.5-7.1-6.4c-2-2.3-4.5-3.4-7.6-3.4c-10.6-2.2-21.1-4-31.9-5c-6.3-0.7-12.7-1-19.1-0.9
								c-7.8-0.2-15.6,0.2-23.4,1.2c-2.9,0.8-4.4,2.7-4.6,5.7c0.8,4.4,3.1,7.9,7,10.5c5.7,5.3,11.7,10.1,18.1,14.5
								C63.4,1423.3,67.4,1424.9,71.9,1424.6z"/>
							<path opacity="0.1091" fill="#131313" d="M26.8,1412.2c6.6,0.5,13.3,0.7,19.9,0.8c5.9-0.1,11.8,0.3,17.7,1.2
								c3.8,0.6,7.6,1.2,11.4,1.8c7.4,1.4,14.7,3.4,21.8,5.8c2.4,0.1,4.5-0.7,6.3-2.4c1.9-1.6,2.9-3.6,3-6c-1-3-2.7-5.6-5-7.9
								c-2-2.5-4.5-4.4-7.4-5.6c-5.7-1.8-11.6-3.2-17.5-4.3c-5.9-1.3-11.9-2.2-17.9-2.8c-4.1-0.3-8.1-0.6-12.2-0.9
								c-8.5,0.2-17,0.2-25.5,0c-4.2-0.1-8.6,5.8-7.9,9.8c1.3,2.7,3.1,4.9,5.6,6.6C21.4,1410.2,23.9,1411.6,26.8,1412.2z"/>
							<path opacity="0.1091" fill="#131313" d="M43.2,1409.1c6.6,1.1,13.3,1.5,19.9,1.2c5.8-0.2,11.6,0,17.4,0.8
								c3.8,0.6,7.6,1.1,11.4,1.7c7.5,1.4,14.8,3.3,22,5.6c4.9,1.4,9.9-3.9,9.2-8.6c-1.6-3.6-3.8-6.8-6.7-9.6
								c-2.6-3-5.6-5.5-9.1-7.3c-6-1.8-12-3.2-18.1-4.2c-6.1-1.3-12.2-2.2-18.4-2.7c-4.1-0.2-8.3-0.5-12.4-0.7
								c-8.4,0.6-16.8,0.4-25.2-0.5c-5-0.3-9.3,4.9-7.8,9.8c2.1,3.3,4.7,6.2,7.9,8.5C36.2,1405.8,39.5,1407.8,43.2,1409.1z"/>
							<path opacity="0.1091" fill="#131313" d="M70.4,1440.5c7.8-1,15.5-1.3,23.3-0.8c7.7,0.4,15.3,1.3,22.8,2.7
								c-1.7-0.4-3.4-0.8-5.1-1.1c5.4,1.2,10.7,2.5,16.1,3.7c-1.7-0.4-3.3-0.9-5-1.3c5.2,1.5,10.5,3,15.7,4.6c3.2-0.2,4.9-1.9,5.4-5
								c-0.5-5-2.7-9.2-6.6-12.6c-5.6-6.8-11.7-13.3-18.1-19.3c-3.2-4.1-7.3-6.6-12.2-7.4c-6.3-1.3-12.6-2.3-19-2.9
								c-6.3-0.8-12.7-1.3-19-1.5c-4.2,0-8.4-0.1-12.6-0.1c-7.8-0.2-15.5,0.3-23.1,1.5c-3.1,0.7-4.6,2.6-4.6,5.7
								c1.2,4.9,4,8.8,8.4,11.6c6.6,6,13.5,11.5,20.7,16.7C61,1438.5,65.4,1440.4,70.4,1440.5z"/>
							<path opacity="0.1091" fill="#131313" d="M19.7,1435.9c6.6,1.1,13.2,1.5,19.9,1.1c5.7-0.3,11.4-0.1,17.1,0.7
								c3.7,0.5,7.5,1.1,11.2,1.6c7.3,1.3,14.5,3.1,21.6,5.4c4.9,1.4,9.9-3.8,9.2-8.5c-1.6-3.6-3.8-6.8-6.6-9.6
								c-2.6-3-5.6-5.5-9.1-7.3c-11.5-2.5-22.9-4.7-34.7-5.4c-4-0.1-8-0.3-11.9-0.4c-8.2,0.8-16.4,0.8-24.7-0.1
								c-4.6-0.2-9,5.3-7.9,9.7c1.7,3.1,4,5.6,6.9,7.7C13.3,1433.2,16.3,1434.9,19.7,1435.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M48.3,1460c12.5-1.5,25.1-3,37.6-4.8c16-2.4,32-5.4,47.9-8.8
								c12.8-2.7,25.5-5.7,38.1-8.9c15.9-4.1,31.6-8.7,47.3-13.4c3-1,4.3-3.1,3.9-6.2c-1.7-4.6-4.8-8.1-9.4-10.4
								c-7.1-5.1-14.4-9.7-22.1-13.8c-4.1-3.1-8.6-4.4-13.5-3.9c-9.8,1.4-19.7,2.7-29.5,4.1c-11.4,1.7-22.8,3.6-34.1,5.8
								c0.6,0,1.2,0.1,1.8,0.1c-16.8-1.5-33.6-2.6-50.5-3.5c-15.3-0.7-30.7-1-46-1c-2.3,0.5-4.1,1.7-5.4,3.7
								c-1.3,1.3-2.1,2.9-2.2,4.6c-0.6,1.6-0.1,3.1,1.3,4.3c1.9,2.2,4.2,4.1,6.8,5.4c11.1,4.5,22.5,8,34.3,10.4
								c6.4,2.5,13,3.5,19.7,3c9.8-0.4,19.5,1.1,28.9,4.6c7.1,1.3,14.3,2.6,21.4,3.9c14.8,2.5,29.6,4.3,44.5,6
								c14.4,1.7,29.1,2.7,43.6,3.1c29.9,0.8,59.8-0.4,89.5-3.5c16-1.7,32-3.9,47.9-6.6c18.9-3.2,37.6-7.3,56.2-11.6
								c3-0.9,4.4-2.9,4.2-6c-1.4-4.7-4.3-8.3-8.7-10.8c-6.7-5.4-13.7-10.3-21-14.7c-3.9-3.3-8.2-4.8-13.1-4.5
								c-23.8,4.9-47.7,9.4-71.8,12.8c-27,3.8-54.1,6.5-81.3,8.3c-24,1.5-48,2.2-72,2.2c-10.9,0-21.8-0.2-32.7,0.4
								c-2.7,0.1-5.3,0.3-8,0.5c-5.3,0.2-10.4,1.5-15.4,3.7c0.9-0.1,1.8-0.3,2.7-0.4c-11.5-0.4-22.7-2.4-33.5-6
								c-11.3-2.9-22.3-6.9-32.9-12c0.2,6,0.3,12,0.5,18.1c10.9-0.3,21.8-0.5,32.7-0.5c11.1-0.1,22.3,0,33.4,0.2
								c6.7-0.3,13.5-0.7,20.2-1c14.9-0.5,29.7-1,44.6-1.3c-1-0.1-2.1-0.2-3.1-0.4c21.3,2.5,42.2,6.7,62.8,12.6
								c-12-11.4-24-22.8-36-34.2c-12.3,3.2-24.6,6.5-37,9.3c-15.5,3.6-31.1,6.8-46.8,9.6c-12.5,2.3-25.1,4.3-37.7,6.1
								c-15.1,2.2-30.2,3.7-45.3,5.5c-3.1,0.6-4.6,2.5-4.7,5.7c1.1,4.9,3.9,8.8,8.2,11.6c6.5,6,13.3,11.6,20.5,16.7
								C39,1458,43.3,1459.9,48.3,1460z"/>
							<path opacity="0.1091" fill="#131313" d="M23.9,1441.5c5-0.5,10.1-0.9,15.1-1.4c4.4-0.4,8.8-1,13.2-1.7c16-2.3,32-4.8,47.8-8
								c12.8-2.6,25.6-5.4,38.3-8.4c19.2-4.6,38.1-10,56.9-15.6c2.3-1.5,3.6-3.6,3.9-6.3c0.1-2.4-0.9-4.3-2.9-5.5
								c-2.4-2.6-5.2-4.7-8.4-6.4c-3.7-2.6-7.8-4.4-12.1-5.6c-13.7-1.1-27.5-1.8-41.3-2.1c-14.2-1.2-28.3-0.3-42.2,2.6
								c-15.6-0.7-31.2-1.3-46.8-1.8c-15.1-0.4-30.2-0.6-45.3-0.5c-2.6-0.2-4.8,0.8-6.4,2.9c-1.7,2-2.2,4.3-1.5,6.8
								c2.1,3.4,4.9,6.3,8.1,8.7c3,2.7,6.4,4.7,10.2,6.1c8.5,1.7,17.1,3.1,25.7,4.4c5.1,1.4,10.3,1.6,15.6,0.7
								c9.9-1.5,19.6,0.1,29,4.7c7.3,1.5,14.6,3,21.9,4.5c34.2,6.3,69.1,9,103.9,8.4c24-0.4,48-2,71.9-4.6c15.9-1.8,31.8-4,47.6-6.7
								c18.8-3.2,37.5-7.3,56.1-11.6c2.4-1.3,3.8-3.4,4.2-6c0.1-3.3-1.4-5.9-4.4-7.7c-3.9-3.4-8.1-6.4-12.6-8.8
								c-2.7-2.2-5.7-2.6-8.8-1.5c-21.2,4.5-42.4,8.7-63.9,12c-21.5,3.3-43.1,5.7-64.8,7.6c1.2-0.1,2.4-0.2,3.6-0.3
								c-7.4,0.5-14.8,1-22.3,1.5c1.2-0.1,2.4-0.2,3.5-0.2c-19,0.9-37.9,1.6-56.9,1.2c-16.2-0.4-32.3-1.4-48.4-2.9
								c-6.4-0.6-12.7-1.2-19.1-1.9c-8.3-1.4-16.6-1.3-24.9,0.1c-7-1-14.1-2-21.1-3c-16.2-2.5-31.7-7.3-46.6-14.4
								c3.5,8.2,7,16.3,10.5,24.5c13.6-1,27.1-1.9,40.7-2.8c12.6-0.3,25-2,37.1-5.1c14.5-1.3,29-1.6,43.5-1
								c20.1,2.4,39.9,6.1,59.6,10.9c-6.5-7.9-13-15.9-19.4-23.8c-24.8,6.9-49.7,13.2-75,18.3c-25,5-50.2,8.8-75.5,12.2
								c1.2-0.1,2.5-0.2,3.7-0.4c-7.2,0.7-14.4,1.4-21.6,2.1c-2.5,1.2-4,3.1-4.7,5.7c-0.3,3.3,1,6,3.9,8.1c3.7,3.7,7.7,7,12.1,9.9
								C17.8,1441.7,20.7,1442.4,23.9,1441.5z"/>
							<path opacity="0.1091" fill="#131313" d="M23.6,1408.1c7-0.2,14-0.5,20.9-0.7c6.4-0.9,12.8-1.7,19.3-2.6
								c16.2-2.3,32.2-5.2,48.2-8.5c12.8-2.6,25.6-5.6,38.3-8.8c16-4,31.8-8.5,47.6-13.2c2.4-0.5,4.2-1.8,5.4-3.9
								c1.5-2,2-4.2,1.4-6.5c-1.7-2.6-3.9-4.7-6.6-6.3c-2.5-1.9-5.3-3.1-8.4-3.5c-16.6-2-33.2-4.1-49.8-6.2c-15-1.2-29.9-0.9-44.8,1
								c-15.4-0.5-30.9-1-46.3-1.4c-15.1-0.4-30.2-0.6-45.4-0.6c-2.8-0.7-5.2,0.1-7.1,2.3c-1.8,2.3-2.1,4.8-0.8,7.4
								c3,4.2,6.6,7.8,10.8,10.9c3.9,3.4,8.2,6.2,12.9,8.3c5,0.3,10,0.6,15,0.9c6.9,0.4,13.7,0.4,20.6-0.1
								c6.5-1.1,12.8-0.1,18.9,2.9c7.5,2.3,15.1,4.3,22.8,5.8c11,2.7,22.2,4.8,33.4,6.3c7.1,0.9,14.3,1.8,21.4,2.6
								c17.3,1.7,34.7,2.3,52.1,2.3c29.4,0,58.7-2.2,87.9-6c12.7-1.6,25.3-3.6,37.9-5.8c18.8-3.2,37.5-7.4,56.1-11.7
								c2.4-0.4,4.2-1.6,5.6-3.7c1.6-1.9,2.2-4,1.9-6.4c-1.4-2.7-3.4-4.8-6-6.5c-2.3-1.9-5-3.2-7.9-3.8c-12.1,2.9-24.1,5.7-36.3,8.2
								c-15.2,3.2-30.6,5.8-45.9,8.1c-12.4,1.9-24.8,3.4-37.2,4.7c-15.7,1.6-31.5,3-47.4,3.4c-27,0.8-54.1,0-81-3.1
								c-6.9-0.9-13.8-1.9-20.7-2.8c-10-2.1-20-3.7-30.1-4.7c-6.6-0.5-13.1-1.6-19.5-3.1c-10.8-2.2-21.6-4.3-32.4-6.4
								c1.1,0.3,2.3,0.6,3.4,0.9c-9.4-3.7-18.8-7.4-28.2-11.1c6.6,9.6,13.2,19.2,19.8,28.8c12.1-1.4,24.3-2.5,36.3-4.4
								c6.4-1,13.1-1.7,19.3-3.5c4.3-1.4,8.6-2.7,12.9-4.1c8.6-0.7,17.2-1.4,25.8-2c11.2,0.4,22.2,1.7,33.2,3.9
								c15.8,2.4,31.5,5.4,47.1,8.8c-2.7-6.8-5.4-13.5-8.2-20.3c-12.3,3.7-24.7,7.4-37.1,10.7c-15.5,4.1-31.2,7.8-47,11.1
								c-12.6,2.6-25.3,5-38,7c-6.3,1-12.7,2-19,2.9c-9.8,1.7-19.7,2.6-29.7,2.6c-2.3,0.4-4.2,1.6-5.6,3.5c-1.6,1.8-2.4,3.8-2.3,6.2
								c1.1,2.6,2.9,4.7,5.2,6.4C18.4,1406.3,20.8,1407.5,23.6,1408.1z"/>
							<path opacity="0.1091" fill="#131313" d="M45.3,1434.2c12.5-1.5,25-2.9,37.5-4.8c16.1-2.4,32-5.4,47.9-8.7
								c12.8-2.7,25.5-5.6,38.2-8.8c15.9-4,31.6-8.6,47.4-13.3c2.6-1.3,3.9-3.4,3.9-6.3c-0.4-2.8-1.8-5-4.2-6.4
								c-3.3-3.2-7-5.9-11.1-8.1c-4.8-3.2-10-5.8-15.5-7.7c-13.1-0.2-26.3,0.1-39.4,0.8c-12.4,0-24.6,1.5-36.6,4.5
								c-15.8-0.9-31.6-1.7-47.5-2.5c-15.2-0.5-30.3-0.7-45.5-0.6c-4.5,0-9,5.3-7.9,9.8c1.6,3,3.9,5.5,6.7,7.5
								c2.6,2.3,5.5,3.9,8.7,4.9c8.8,2.4,17.7,4.4,26.7,6c6,2,12.1,2.7,18.3,2c10-0.9,19.7,0.7,29.2,4.9c7.3,1.4,14.5,2.8,21.8,4.3
								c28.7,5,58,7.8,87.2,8c13.3,0.1,26.6,0.1,40-0.4c16.3-0.6,32.5-2,48.7-3.8c16-1.8,31.9-4,47.7-6.7
								c18.8-3.2,37.5-7.3,56.1-11.6c2.6-1.2,4-3.2,4.2-6c-0.5-3.9-2.6-6.9-6.2-9c-5-4.2-10.4-7.9-16.1-11.2
								c-3.2-2.6-6.7-3.5-10.5-2.7c-17.8,3.9-35.7,7.7-53.7,10.8c-15,2.5-30.1,4.7-45.3,6.6c-15.2,1.8-30.4,3.4-45.7,4.4
								c-18.5,1.2-36.9,1.8-55.4,2.1c1.2,0,2.4,0,3.6,0c-12.5,0-25.1-0.3-37.6-1c-12.3-0.8-24.5-1.5-36.8-2.2
								c-12.7,2.5-25.4,1.8-37.9-2c-15.8-3-31-7.8-45.7-14.5c2.5,7.4,5,14.8,7.5,22.2c10.5-0.6,21-1,31.5-1.4
								c10.5-0.4,21.1-0.7,31.6-1c6-0.9,12.1-1.8,18.1-2.7c15-1.2,30-1.8,45.1-1.6c-0.9,0-1.8-0.1-2.8-0.1
								c20.4,2.4,40.6,6.2,60.5,11.3c-9-9.5-17.9-18.9-26.9-28.4c-12.2,3.4-24.5,6.8-36.8,9.9c-15.4,3.8-31,7.2-46.6,10.2
								c-12.5,2.4-25,4.6-37.6,6.5c-15.1,2.3-30.3,4-45.4,5.7c-2.7,1-4.3,2.9-4.7,5.7c0.3,4,2.1,7.1,5.6,9.5
								c4.8,4.6,9.9,8.8,15.4,12.5C37.9,1433.5,41.4,1434.7,45.3,1434.2z"/>
							<path opacity="0.1091" fill="#131313" d="M40.9,1404.4c10.4,1.4,20.7,1.1,30.9-1c9.5-1.4,19-2.9,28.5-4.5
								c10.2-1.8,20.3-3.8,30.4-5.9c10.2-2.1,20.2-4.3,30.4-6.8c19.1-4.7,38-10.1,56.8-15.8c2.2-1.5,3.5-3.6,3.9-6.3
								c0.2-2.3-0.7-4.1-2.6-5.3c-2.2-2.5-4.8-4.5-7.8-6c-3.5-2.4-7.3-4.1-11.4-5.1c-14.3-1.4-28.7-2.5-43.1-3.3
								c-14.4-1.3-28.7-0.6-42.8,2.2c-15.6-0.7-31.2-1.3-46.8-1.9c-15.1-0.5-30.2-0.6-45.4-0.5c-2.7-0.3-4.9,0.6-6.5,2.8
								c-1.8,2.1-2.2,4.4-1.3,7c2.4,3.6,5.3,6.7,8.7,9.2c3.2,2.9,6.8,5.1,10.8,6.6c8,0.9,16,2,24,3.1c5.2,1.1,10.5,1.5,15.8,1.1
								c10.5-0.7,20.6,1.3,30.4,6.1c7.5,1.6,15,3.2,22.4,4.8c17.6,3.3,35.5,5.4,53.4,6.7c16.9,1.2,33.8,1.8,50.7,1.5
								c29.2-0.6,58.3-3,87.3-7.1c28.4-4,56.4-9.6,84.3-16.1c1.1-0.3,2.3-0.5,3.4-0.8c0.9-0.9,1.8-1.7,2.7-2.6
								c0.5-1.2,1-2.3,1.5-3.5c0.4-2.2-0.3-4-2.1-5.3c-2.8-3.3-6.3-5.7-10-7.9c-1.6-1.2-3.3-2.1-5.3-2.7c-1.8-1.2-3.7-1.2-5.7-0.1
								c-5.9,1.4-11.9,2.8-17.8,4.2c1.1-0.3,2.2-0.5,3.4-0.8c-7.1,1.5-14.2,3.1-21.3,4.6c1.1-0.2,2.2-0.5,3.3-0.7
								c-24.2,4.8-48.4,9-72.8,11.9c-28.1,3.3-56.3,5.1-84.6,5.1c-26.5-0.1-52.8-2.1-79-5.7c-6.3-1.1-12.7-1.8-19.1-2.1
								c-6.8-0.2-13.5-1.2-20.1-3.1c-6.8-1.4-13.5-2.8-20.3-4.2c-12.7-2.1-24.7-6.2-36.3-12.2c3.9,8.5,7.8,17.1,11.7,25.6
								c13.4-1,26.8-2,40.3-3.1c9.5-0.1,18.8-1.4,27.9-3.8c10.6-2.9,21.5-4,32.6-3.3c17.1-0.3,34.1,1.4,50.8,5.3
								c-1.2-0.2-2.3-0.5-3.5-0.7c10.9,2.3,21.8,4.7,32.7,7c-5.9-7.6-11.9-15.1-17.8-22.7c-12.1,3.8-24.3,7.3-36.6,10.5
								c-12.3,3.3-24.6,6.3-37,9c1.1-0.2,2.3-0.5,3.4-0.7c-7.4,1.5-14.7,3.1-22.1,4.6c1.1-0.2,2.3-0.5,3.4-0.7
								c-12.5,2.5-25.1,4.7-37.7,6.6c-12.7,2.9-25.5,3.9-38.4,2.8c-2.6-0.1-4.7,0.9-6.2,3c-1.7,2-2.3,4.2-1.7,6.7
								c1.9,3.3,4.5,6,7.6,8.3C34.1,1401.3,37.3,1403.2,40.9,1404.4z"/>
							<path opacity="0.1091" fill="#131313" d="M6.3,1429.9c7-0.2,13.9-0.5,20.9-0.7c6.4-0.9,12.8-1.7,19.3-2.6
								c16.1-2.3,32.2-5.2,48.2-8.5c12.8-2.7,25.5-5.6,38.2-8.8c15.9-4,31.7-8.6,47.4-13.3c2.3-0.6,4.1-1.9,5.3-4
								c1.5-1.9,2-4.1,1.6-6.4c-1.5-2.5-3.5-4.5-6-6c-2.4-1.8-5-2.8-7.9-3.2c-16.7-1.8-33.3-3.9-49.9-6.3
								c-15.2-0.9-30.4-0.8-45.6,0.5c-15.5-0.6-31-1-46.5-1.4c-15.1-0.4-30.2-0.5-45.3-0.5c-2.8-0.6-5.2,0.2-7,2.4
								c-1.8,2.3-2.1,4.7-0.8,7.4c3,4.1,6.5,7.6,10.6,10.6c3.8,3.3,8,6,12.7,8c5.2,0.5,10.4,0.9,15.6,1.4c6.9,0.7,13.8,0.7,20.7-0.1
								c6.4-1.3,12.6-0.5,18.6,2.6c7.4,2.3,15,4.2,22.6,5.7c11,2.7,22.1,4.8,33.3,6.3c7.1,0.9,14.3,1.8,21.4,2.6
								c17.2,1.7,34.4,2.5,51.7,2.5c29.7,0.1,59.4-1.9,88.8-5.7c31.6-4.1,62.8-10.5,93.8-17.7c2.3-0.4,4.2-1.7,5.5-3.7
								c1.6-1.9,2.2-4,1.9-6.4c-1.3-2.6-3.2-4.7-5.7-6.3c-2.3-1.9-4.8-3.1-7.7-3.6c-12.1,2.8-24.3,5.6-36.5,8
								c-15.3,3.1-30.7,5.7-46.2,7.9c-28.5,4.1-57.4,6.9-86.3,7.4c-26.8,0.5-53.6-0.4-80.2-3.4c-6.9-0.9-13.7-1.8-20.6-2.8
								c-9.7-2.2-19.5-3.6-29.5-4.2c-6.4-0.3-12.7-1.2-19-2.6c-11-1.9-21.9-4-32.8-6.4c1.2,0.3,2.3,0.6,3.5,0.9
								c-9.4-3.7-18.8-7.4-28.3-11.1c6.4,9.4,12.9,18.8,19.3,28.3c11.5-1.4,23.1-2.8,34.6-4.3c11.8-1.3,23.2-3.8,34.4-7.6
								c15.2-2.6,30.3-2.6,45.3,0.1c20.2,3.4,40.4,6.9,60.6,10.8c-2.3-6.5-4.7-13.1-7-19.6c-12.4,3.6-24.8,7.3-37.4,10.5
								c-15.6,4.1-31.4,7.7-47.2,10.9c-12.7,2.6-25.3,4.9-38.1,6.9c-6.3,1-12.7,2-19.1,2.8c-9.8,1.7-19.7,2.5-29.7,2.6
								c-2.3,0.4-4.2,1.5-5.6,3.5c-1.6,1.8-2.4,3.8-2.3,6.2c1.1,2.6,2.9,4.7,5.2,6.4C1.1,1428,3.5,1429.3,6.3,1429.9z"/>
							<path opacity="0.1091" fill="#131313" d="M31.3,1462c12.6-1.4,25.1-2.8,37.6-4.7c16-2.4,32-5.4,47.9-8.7
								c12.8-2.7,25.5-5.6,38.2-8.9c15.9-4.1,31.6-8.6,47.3-13.3c2.6-1.3,3.9-3.4,3.9-6.2c-0.4-2.8-1.8-5-4.2-6.4
								c-3.3-3.2-7-5.9-11.1-8.1c-4.8-3.3-10-5.8-15.5-7.7c-12.7,0.1-25.4,0.7-38,1.5c-13-0.2-26,0.7-38.9,2.8
								c0.6,0,1.2,0.1,1.8,0.1c-16.8-1.4-33.5-2.4-50.3-3.2c-15.2-0.6-30.4-0.8-45.7-0.7c-2.4,0.4-4.2,1.5-5.6,3.5
								c-1.6,1.8-2.4,3.9-2.2,6.3c1.2,2.6,3,4.8,5.4,6.5c2.2,2,4.7,3.2,7.5,3.9c9.7,3.6,19.6,6.3,29.7,8.3
								c6.3,2.3,12.7,3.3,19.4,2.8c10-0.4,19.7,1.3,29.2,5.1c7.2,1.4,14.5,2.8,21.7,4.2c17.5,3,35.2,5.2,52.9,6.5
								c11.6,0.9,23,1.6,34.6,1.9c29.7,0.6,59.4-0.8,88.9-4c16-1.8,31.9-4,47.7-6.7c18.8-3.2,37.5-7.3,56.1-11.6
								c2.7-1.1,4.1-3.1,4.2-6c-0.6-4-2.7-7.1-6.4-9.2c-5.2-4.3-10.7-8.2-16.6-11.7c-3.2-2.7-6.8-3.7-10.8-2.9
								c-30.2,6.2-60.5,11.5-91.1,15.2c-30.5,3.6-61.3,6-92.1,6.6c-12.3,0.3-24.6,0.3-36.9,0.2c-14-0.2-28-1.1-42-1.4
								c-3.9-0.5-7.8-0.4-11.7,0.2c-4.1,0.5-8.3,1.1-12.4,1.6c-8-1.3-16-2.6-23.9-3.8c-14.5-3.3-28.6-8.1-42.1-14.2
								c0.5,6.3,1.1,12.6,1.6,18.9c10.7-0.5,21.3-0.9,32-1.1c10.8-0.3,21.6-0.5,32.5-0.6c6.7-0.4,13.5-0.8,20.2-1.2
								c15.4,0.2,30.9,0.2,46.3-0.1c-0.8,0-1.7,0-2.5,0c20.3,2.3,40.4,6,60.2,11.2c-9-9.5-17.9-19-26.9-28.5
								c-12.5,3.3-24.9,6.6-37.4,9.5c-15.7,3.6-31.4,6.8-47.2,9.7c-12.6,2.3-25.3,4.4-38,6.2c-15.2,2.2-30.5,3.8-45.7,5.5
								c-2.8,0.9-4.3,2.8-4.7,5.7c0.4,4.1,2.4,7.4,6,9.9c5.1,4.9,10.5,9.3,16.3,13.3C23.6,1461.1,27.2,1462.4,31.3,1462z"/>
							<path opacity="0.1091" fill="#131313" d="M59.7,1431c12.6-1.5,25.1-2.9,37.6-4.7c16.1-2.4,32.1-5.3,48-8.7
								c12.8-2.7,25.6-5.6,38.3-8.8c15.9-4,31.7-8.6,47.5-13.2c2.8-1.1,4.1-3.2,3.9-6.3c-1.4-4.4-4.3-7.7-8.7-9.9
								c-6.6-4.7-13.4-9-20.6-12.8c-3.9-2.9-8.1-4.1-12.7-3.4c-10.7,0.5-21.3,1.4-31.9,2.6c-11.4,1.3-22.7,3.3-33.8,6.1
								c-15.9-1.2-31.9-2.2-47.9-2.9c-15.2-0.6-30.5-0.9-45.7-0.8c-4.2,0-8.7,5.7-7.9,9.7c1.3,2.7,3.2,5,5.7,6.7
								c2.3,2.1,4.9,3.4,7.8,4.2c9.3,3.1,18.8,5.6,28.5,7.5c6.2,2.3,12.6,3.1,19.1,2.6c10-0.5,19.7,1.1,29.2,4.9
								c7.2,1.4,14.4,2.7,21.6,4.1c28.8,4.9,58.1,7.6,87.3,8.2c29.7,0.6,59.4-0.8,89-3.9c16-1.7,32-3.9,47.9-6.6
								c18.9-3.2,37.6-7.2,56.3-11.5c2.8-1,4.2-3,4.2-6c-1-4.4-3.6-7.7-7.7-10c-6-4.9-12.3-9.3-19-13.3c-3.6-3-7.6-4.3-12.1-3.8
								c-26.5,5.9-53,11.2-79.9,15.1c-29.9,4.4-60,7.4-90.1,8.9c-12.1,0.6-24.3,0.9-36.4,1c-14.1,0.1-28.1-0.5-42.2-0.9
								c-5.8-0.1-11.6-0.2-17.5-0.4c-5.1,0.6-10.2,1.3-15.4,1.9c-11.2-1-22.1-3-32.8-6.2c-11.1-2.6-21.8-6.2-32.2-11.1
								c0.7,6.5,1.4,12.9,2.1,19.4c10.7-0.4,21.5-0.7,32.2-0.8c10.8-0.2,21.7-0.3,32.5-0.4c6.2-0.7,12.5-1.3,18.7-2
								c14.8-1.5,29.6-2.1,44.5-1.9c-1-0.1-2.1-0.2-3.1-0.3c21,2.5,41.8,6.6,62.2,12.2c-11.1-10.8-22.1-21.5-33.2-32.3
								c-12.1,3.4-24.2,6.8-36.5,9.8c-15.3,3.8-30.7,7.2-46.2,10.2c-12.4,2.4-24.9,4.6-37.4,6.5c-15,2.3-30.2,3.9-45.3,5.6
								c-2.9,0.8-4.4,2.7-4.6,5.7c0.7,4.4,3,8,6.9,10.6c5.7,5.3,11.6,10.2,18,14.6C51.3,1429.7,55.2,1431.2,59.7,1431z"/>
							<path opacity="0.1091" fill="#131313" d="M14.7,1418.6c7-0.2,14-0.4,21-0.7c6.4-0.9,12.8-1.7,19.3-2.6
								c16.2-2.3,32.2-5.2,48.2-8.5c12.8-2.7,25.6-5.6,38.3-8.8c15.9-4,31.7-8.6,47.5-13.2c2.4-0.5,4.2-1.8,5.5-3.9
								c1.5-2,1.9-4.2,1.4-6.6c-1.7-2.7-3.9-4.8-6.7-6.4c-2.6-1.9-5.4-3.1-8.5-3.6c-8.8-1.1-17.5-2.2-26.3-3.3
								c-7.8-0.9-15.6-1.8-23.4-2.8c-12.3-1.5-24.5-1.4-36.7,0.5c-6.4,1.1-12.8,1.4-19.2,0.7c-7.7-0.2-15.3-0.4-23-0.6
								c-19-0.5-37.9-0.8-56.9-0.9c-2.9-0.7-5.3,0-7.2,2.2c-1.8,2.3-2,4.8-0.7,7.5c3.2,4.3,7,8,11.3,11.2c4,3.5,8.5,6.4,13.3,8.6
								c5.1,0.3,10.2,0.7,15.3,1c6.8,0.3,13.5-0.1,20.1-1.3c6-1.6,11.8-0.9,17.4,2.3c7.4,2.3,14.9,4.1,22.5,5.6
								c11,2.7,22.1,4.8,33.3,6.3c7.1,0.9,14.2,1.7,21.4,2.6c17.1,1.7,34.3,2.5,51.5,2.5c29.7,0.1,59.3-1.9,88.8-5.6
								c12.8-1.6,25.5-3.6,38.2-5.7c18.9-3.2,37.5-7.4,56.2-11.7c2.4-0.4,4.3-1.6,5.6-3.6c1.6-1.9,2.2-4,1.8-6.4
								c-1.5-2.7-3.6-4.9-6.2-6.6c-2.4-2-5.1-3.3-8.2-4c-12.1,2.8-24.2,5.6-36.4,8.1c-15.4,3.1-30.9,5.7-46.4,7.9
								c-28.5,4.1-57.4,7-86.2,7.5c-26.6,0.5-53.2-0.3-79.6-3.3c-6.9-0.9-13.7-1.8-20.6-2.8c-9.6-2.3-19.3-3.7-29.1-4
								c-6.1-0.2-12.2-0.8-18.2-2c-11-1.5-22-3.5-32.8-6.1c1.2,0.4,2.5,0.7,3.7,1.1c-9.5-3.8-19.1-7.5-28.6-11.3
								c6.9,9.8,13.9,19.7,20.8,29.5c12.1-1.4,24.1-2.9,36.1-4.5c3-0.4,5.9-0.9,8.9-1.6c3.4-0.5,6.8-1.2,10.2-2.1
								c4.1-1.5,8.1-3.1,12.2-4.6c8.3-0.8,16.5-1.5,24.8-2.3c11.3,0.1,22.5,1.3,33.5,3.5c15.8,2.4,31.6,5.3,47.2,8.9
								c-2.8-6.8-5.6-13.6-8.3-20.5c-12.4,3.7-24.7,7.3-37.2,10.6c-15.6,4.1-31.3,7.7-47.1,10.9c-12.6,2.6-25.3,4.9-38,6.9
								c-6.3,1-12.7,2-19,2.9c-9.9,1.7-19.8,2.5-29.8,2.5c-4.1,0.2-8.7,5.5-7.9,9.7c1.3,2.7,3.1,4.9,5.5,6.7
								C9.2,1416.5,11.8,1417.9,14.7,1418.6z"/>
							<path opacity="0.1091" fill="#131313" d="M31.1,1415.4c10.3,1.1,20.4,0.7,30.4-1.3c9.5-1.4,19-2.9,28.5-4.5
								c7-1.3,13.9-2.5,20.9-3.9c32.7-6.3,64.9-14.9,96.8-24.5c2.2-1.5,3.5-3.6,3.9-6.3c0.2-2.3-0.7-4.1-2.6-5.3
								c-2.2-2.5-4.8-4.5-7.8-6c-3.5-2.4-7.3-4.1-11.3-5.1c-14.3-1.5-28.7-2.6-43.1-3.3c-14.3-1.4-28.4-0.5-42.3,2.7
								c-15.5-0.5-30.9-1.1-46.4-1.6c-15.1-0.5-30.2-0.6-45.3-0.5c-2.7-0.4-4.9,0.5-6.7,2.7c-1.8,2.1-2.2,4.5-1.2,7.1
								c2.5,3.7,5.6,6.9,9.2,9.6c3.4,3,7.1,5.3,11.3,7c7.9,1.1,15.8,2,23.7,2.8c7.4,1.2,14.9,1.1,22.3-0.1
								c7.4,1.9,14.8,3.9,22.2,5.8c7.4,1.6,14.9,3.2,22.3,4.8c10.7,2.1,21.5,3.7,32.3,4.8c10.4,1.4,20.8,2.3,31.3,2.8
								c16.7,0.8,33.5,1,50.2,0.5c32.3-1,64.5-4.3,96.4-9.4c23.2-3.7,46-8.6,68.8-13.9c2.3-1.4,3.7-3.4,4.2-6c0.2-3.1-1-5.5-3.8-7.3
								c-3.5-3.1-7.3-5.8-11.4-8c-2.6-2-5.3-2.3-8.1-1c-6,1.4-12,2.7-17.9,4.1c1.1-0.2,2.3-0.5,3.4-0.7c-7.1,1.5-14.3,3-21.4,4.5
								c1.1-0.2,2.3-0.5,3.4-0.7c-7.2,1.4-14.4,2.7-21.5,4.1c1.1-0.2,2.3-0.4,3.4-0.6c-24.5,4.1-48.9,7.7-73.6,9.9
								c-22,2-44.1,2.9-66.2,2.8c-23-0.1-45.9-1.6-68.8-4.4c-12.1-2.3-24.3-3.4-36.7-3.1c-14.4-2.2-28.8-4.8-43.1-8
								c1.1,0.2,2.1,0.5,3.2,0.7c-9.1-3.5-18.2-6.9-27.3-10.4c4.2,8.8,8.4,17.5,12.6,26.3c13.4-1.1,26.7-2.1,40.1-3.2
								c9.3-0.1,18.4-1.5,27.2-4.2c10.1-3.2,20.5-4.5,31.3-3.9c17.3-0.6,34.3,1.1,51.2,5.1c-1.2-0.2-2.4-0.5-3.5-0.7
								c10.9,2.3,21.7,4.7,32.6,7c-5.9-7.6-11.8-15.1-17.8-22.7c-12.2,3.7-24.5,7.1-36.9,10.3c-12.4,3.2-24.8,6.1-37.3,8.8
								c1.2-0.2,2.3-0.5,3.5-0.7c-7.4,1.5-14.8,3-22.2,4.5c1.2-0.2,2.3-0.4,3.5-0.7c-12.6,2.4-25.2,4.6-37.8,6.5
								c-12.6,2.7-25.3,3.8-38.1,3c-2.6-0.1-4.7,0.8-6.3,2.9c-1.7,2-2.3,4.3-1.6,6.8c2,3.3,4.7,6.2,7.9,8.5
								C24.1,1412.1,27.4,1414.1,31.1,1415.4z"/>
							<path opacity="0.1091" fill="#131313" d="M58.3,1446.9c12.6-1.5,25.1-3,37.6-4.8c16.1-2.4,32.1-5.4,48-8.7
								c12.8-2.7,25.5-5.6,38.2-8.9c15.9-4.1,31.7-8.6,47.4-13.3c3-1,4.3-3.1,3.9-6.3c-1.8-4.7-5.1-8.3-9.9-10.7
								c-7.3-5.2-15-10-22.9-14.3c-4.2-3.2-8.8-4.6-13.9-4.2c-9.8,1.4-19.6,2.8-29.5,4.2c-10.9,2.1-21.7,4.4-32.6,6.8
								c-16.1-1.4-32.2-2.5-48.4-3.3c-15.3-0.7-30.6-1-46-1c-2.3,0.5-4.1,1.7-5.5,3.6c-1.4,1.3-2.1,2.9-2.1,4.7
								c-0.6,1.7-0.1,3.1,1.5,4.4c2,2.3,4.3,4.2,7,5.7c10.9,4.3,22.2,7.6,33.7,10c6.3,2.5,12.9,3.4,19.6,2.9
								c9.9-0.5,19.5,1.1,28.9,4.6c7.2,1.3,14.3,2.6,21.5,3.9c14.7,2.5,29.5,4.4,44.4,5.9c14.5,1.4,29,2.6,43.5,3
								c29.9,0.8,59.8-0.4,89.5-3.5c16.1-1.7,32.1-3.8,48-6.5c18.9-3.2,37.7-7.2,56.4-11.5c3-0.9,4.4-2.9,4.2-6
								c-1.5-4.7-4.4-8.4-8.9-10.9c-6.8-5.5-14-10.5-21.4-15c-3.9-3.4-8.4-4.9-13.3-4.7c-23.6,5.1-47.2,9.7-71.1,13.2
								c-26.8,4-53.7,6.9-80.7,8.8c-23.9,1.7-47.8,2.4-71.8,2.5c-11,0-21.9-0.2-32.9,0.3c-2.7,0-5.4,0.2-8.1,0.4
								c-5.3,0.4-10.5,1.5-15.6,3.4c0.9-0.1,1.8-0.3,2.7-0.4c-11.4-0.5-22.5-2.5-33.3-6c-11.3-2.8-22.2-6.7-32.7-11.7
								c0.3,6.1,0.5,12.2,0.8,18.3c10.9-0.3,21.8-0.5,32.7-0.5c11.1-0.1,22.2,0,33.3,0.2c6.5-0.4,13.1-0.9,19.6-1.3
								c14.7-1.4,29.4-2,44.1-1.9c-1.1-0.2-2.2-0.3-3.3-0.5c21.5,2.6,42.7,6.9,63.5,13c-12.5-11.7-25.1-23.4-37.6-35.1
								c-12.2,3.3-24.4,6.6-36.7,9.5c-15.4,3.7-30.9,6.9-46.4,9.8c-12.5,2.3-25,4.4-37.5,6.3c-15,2.2-30.1,3.7-45.2,5.5
								c-3.1,0.6-4.6,2.5-4.7,5.7c1.2,4.9,3.9,8.8,8.3,11.7c6.5,6,13.4,11.6,20.6,16.8C48.9,1444.8,53.3,1446.7,58.3,1446.9z"/>
							<path opacity="0.1091" fill="#131313" d="M7.5,1442.3c7.1,0,14.1,0,21.2,0c6.2-0.9,12.3-1.8,18.5-2.7
								c16.1-2.5,32.1-5.3,48.1-8.6c12.8-2.6,25.6-5.5,38.3-8.7c16-4,31.8-8.5,47.6-13.1c2.5-0.3,4.4-1.6,5.6-3.9
								c1.5-2.1,1.8-4.4,1-6.8c-2.1-2.8-4.7-5.1-7.8-6.8c-2.9-2-6.1-3.4-9.6-4c-15.2-2.2-30.5-3.9-45.9-5.2c-15-1-30-0.7-44.9,0.9
								c-15.6-0.7-31.2-1.3-46.9-1.7c-15.1-0.4-30.1-0.5-45.2-0.4c-2.7-0.3-4.9,0.6-6.6,2.8c-1.7,2.1-2.2,4.4-1.3,7
								c2.4,3.6,5.4,6.7,9,9.3c3.3,2.9,7,5.2,11.1,6.7c5.6,0.8,11.1,1.6,16.7,2.5c7.6,1.9,15.3,2.4,23,1.5
								c6.4-1.1,12.5-0.3,18.5,2.5c7.4,2.1,14.8,3.9,22.3,5.4c10.9,2.6,21.9,4.6,33,6c7.1,0.8,14.2,1.7,21.3,2.5
								c17,1.7,34.1,2.4,51.3,2.5c29.5,0.1,58.9-1.7,88.2-5.3c12.7-1.6,25.5-3.5,38.1-5.6c18.9-3.1,37.6-7.2,56.3-11.4
								c2.5-0.2,4.5-1.4,5.8-3.6c1.6-2,2-4.3,1.4-6.7c-2-3-4.5-5.4-7.5-7.3c-2.8-2.2-5.9-3.7-9.4-4.6c-12.2,2.7-24.4,5.3-36.7,7.6
								c-15.4,2.9-30.9,5.3-46.4,7.4c-28.2,3.7-56.6,6.3-85.1,6.9c-26.1,0.6-52.3-0.1-78.3-2.6c-6.7-0.8-13.4-1.5-20-2.3
								c-9.3-2.1-18.7-3.1-28.2-3c-6.4,0.2-12.8-0.5-19.1-2.1c-11.1-1.8-22.1-4-33.1-6.5c1,0.2,2.1,0.4,3.1,0.7
								c-9.1-3.5-18.3-7-27.4-10.5c4.1,8.6,8.2,17.2,12.2,25.9c13.2-1.2,26.4-2.4,39.6-3.6c12.4-0.9,24.5-2.8,36.6-5.9
								c15.1-1.8,30.1-1.7,45.2,0.2c20.3,2.6,40.3,6.4,60.1,11.4c-3.6-7.2-7.2-14.3-10.8-21.5c-12.5,3.5-25,7.1-37.6,10.2
								c-15.7,3.9-31.5,7.4-47.4,10.5c-12.7,2.5-25.4,4.7-38.1,6.7c-6.2,1-12.4,1.9-18.5,2.8c-9.6,1.8-19.3,2.4-29.1,1.8
								c-4.6,0.1-9.1,5-7.9,9.7c1.7,3.1,4,5.7,6.9,7.8C1.2,1439.5,4.2,1441.2,7.5,1442.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M237.9,1458.7c35.6-5.5,71.3-10.6,107.1-15.4c35.8-4.8,71.7-9.2,107.6-12.6
								c32.5-3,65.1-5.3,97.7-6.7c19.5-0.8,39-1.2,58.5-1c10.3,0.1,20.6,0.3,31,0.1c10.5-0.2,21.1-0.6,31.5-2.1
								c10.5-1.5,20.8-3.8,30.9-6.7c10.1-2.9,20-6.3,29.9-9.7c11.6-4,23.3-8.2,35.1-11.4c27.8-7.4,56.3-11.4,84.9-13.1
								c33.9-2.1,67.9-0.1,101.6,2.6c-13.4-13.5-26.7-26.9-40.1-40.4c-4.5,4-9.2,7.6-14.2,10.9c-6.1,4.3-12.6,8.1-19.4,11.2
								c0.9-0.5,1.8-1,2.7-1.4c-4.9,1.1-9.9,2.2-14.8,3.3c-4.8,0.2-9.5,0.5-14.3,0.7c-10.4,0.3-20.9,0.1-31.3-0.1
								c-20.3-0.2-40.7-0.4-61,1.1c-54.2,4.3-108.2,11.5-162.1,18.6c-2.5,0.3-4.4,1.5-5.7,3.6c-1.3,1.5-1.9,3.1-1.6,5
								c-0.3,2,0.6,3.7,2.5,4.9c2.7,2.6,5.8,4.8,9.2,6.6c6.8,3.2,13.7,6.3,20.7,9.2c7.3,3.2,14.9,5.9,22.6,8.2
								c8.1,1,16.1,2.1,24.2,3.1c2.4,0.3,4.9,0.4,7.3,0.4c7.6,0,15.1,1.4,22.4,4.1c6.5,1,13,2,19.5,3c4.4,0.4,8.9,0.8,13.3,1.1
								c9.7,1,19.4,1.4,29.2,1c30.4-1,60.4-6.1,90.6-8.3c9.2-0.7,18.5-1,27.8-0.9c9.1-0.2,18.1,0,27.2,0.6
								c5.9,0.5,11.8,0.9,17.8,1.4c11.7,1.2,23.3,2.8,34.8,5c-12.5-13.6-25-27.3-37.4-40.9c-2.9,4.4-5.8,8.8-8.6,13.2
								c0.6-0.7,1.3-1.4,1.9-2.1c-1.3,0.7-2.6,1.5-3.9,2.2c1.1-0.5,2.2-1,3.3-1.4c-4.1,0.7-8.1,1.5-12.2,2.2
								c1.2-0.2,2.4-0.4,3.6-0.6c-3.2,0.2-6.3,0.4-9.5,0.7c1.4,0.3,2.8,0.5,4.2,0.8c-3.7-1-7.4-1.9-11.1-2.9
								c-2.3-0.9-4.2-0.5-5.9,1.3c-1.7,1.9-2.8,4.1-3.3,6.6c-0.5,2.4,0.3,4.2,2.4,5.5c2.4,1.8,5.1,3.2,8,4.1
								c2.9,1.3,5.8,2.2,8.9,2.8c5.5,1.3,11.1,1.6,16.8,1c-0.9-0.3-1.7-0.5-2.6-0.8c10.3,4.1,21.1,7.2,32.2,8.4
								c4.2,0.5,8.3,0.9,12.5,1.1c6,0.3,12,0,18-1c5.8-0.5,11.4-1.7,17-3.4c7.1-2.1,14-4.8,20.6-8.2c6.4-3.4,12.4-7.3,18-11.8
								c5.6-4.5,10.8-9.4,15.6-14.7c2.1-2,2.6-4.4,1.7-7.2c-2.2-3.5-5.4-5.6-9.8-6.2c-6.7-2.1-13.5-3.7-20.4-4.7
								c-4.1-1.4-8-0.9-11.5,1.3c-4.4,3.5-9.1,6.6-14,9.4c-2.5,1.3-4.9,2.6-7.4,3.9c-8.4,4.3-17.3,7.5-26.5,9.9
								c-7.3,1.9-14.7,3-22.2,3.6c-3,0.1-5.9,0.6-8.7,1.4c-4,1.1-7.7,2.8-11.1,5.1c1.2-0.4,2.4-0.7,3.5-1.1
								c-3.7,0.6-7.4,1.2-11.1,1.8c0.9,0.3,1.7,0.5,2.6,0.8c-7.5-3.6-15.2-6.5-23.2-8.9c0.4,5.8,0.8,11.6,1.2,17.4
								c3.7,1.4,7.4,1.9,11.1,1.3c2.9-0.3,5.8-0.9,8.5-2c-1.2,0.2-2.4,0.4-3.6,0.6c5.7-0.1,11.3-1,16.8-2.7
								c-3.3-0.5-6.6-1.1-9.9-1.6c3.6,2.1,7.3,3.8,11.2,5.1c9.8,3.2,19.5,6.4,29.3,9.6c1.7,0.4,3.3,0.8,5,1.2
								c1.2-0.5,2.4-1.1,3.6-1.6c0.5-1.2,1-2.5,1.5-3.7c-0.3-3.4-1.8-6.3-4.4-8.6c-5.1-6.4-11-12-17.1-17.5
								c-2.7-2.7-5.5-5.1-8.7-7.2c-2.4-2.5-5.3-3.8-8.8-4c-11.9-1.1-23.8-1.8-35.8-2c-11.9-0.2-23.8,0-35.8,0.7
								c-20.3,1.1-40.5,3.5-60.6,6.8c-17.6,2.8-35.1,6.1-52.7,8.1c-9.8,1.1-19.5,1.8-29.3,2.3c-10.8,0.3-21.2,2.6-31.2,6.9
								c2.4-0.4,4.8-0.8,7.3-1.3c-7.6,0-15.3-0.1-22.9-0.1c0.6,0.1,1.2,0.1,1.7,0.2c-14.1-3.7-27.9-8.4-41.4-14.1
								c1.2,0.5,2.5,1.1,3.7,1.6c-9-4.5-18-8.9-27-13.4c1.5,6.7,2.9,13.4,4.4,20.1c28.5-3.8,57-7.6,85.5-11.2
								c12.8-1.6,25.6-3.1,38.4-4.6c15.9-1.8,31.9-3.8,47.9-4.9c17.3-1.2,34.7-1.7,52-2.5c11.4-0.5,22.8-0.9,34.2-2.1
								c5.3-0.1,10.4-1,15.3-2.9c7-1.6,13.7-4.2,20-7.7c-0.9,0.1-1.9,0.3-2.8,0.4c6.1,0.6,12.1,1.6,18.1,2.8
								c12.5,2.3,25,4.7,37.4,7.2c3.2-0.5,4.8-2.3,4.9-5.5c-1-5-3.7-9.1-8-12.2c-6.4-6.4-13.2-12.4-20.3-17.9c-3.6-3.9-8-6-13.1-6.5
								c-17.6-0.4-35.2-0.7-52.8-0.1c-14.7,0.5-29.3,1.4-43.9,2.8c-31.8,3-63.5,8.3-94.5,16.2c-18.8,4.8-37.5,10.2-56.5,14.3
								c-21.8,4.6-43.9,6.2-66.2,6.8c-27.2,0.7-54.4,1.5-81.5,2.9c-36.3,1.9-72.5,5-108.6,8.9c-32.8,3.5-65.6,7.7-98.3,12.1
								c-19.6,2.7-39.2,5.5-58.8,8.3c-18.8,2.8-37.7,5.3-56.6,8.1c-3,0.8-4.5,2.8-4.4,5.9c1.3,4.8,4.2,8.5,8.6,11.2
								c6.7,5.6,13.6,10.8,20.9,15.6C228.5,1457.1,232.9,1458.8,237.9,1458.7z"/>
							<path opacity="0.1091" fill="#131313" d="M209.7,1440.7c7.1-0.6,14.3-1.5,21.3-2.5c5.8-0.9,11.6-1.7,17.4-2.6
								c19.6-2.9,39.2-5.7,58.8-8.4c32.5-4.4,65.1-8.6,97.8-12.1c38.9-4.2,78-7.4,117.1-9c16.2-0.7,32.5-1.1,48.7-1.2
								c13.6-0.1,27.2,0,40.7-0.2c7.5-0.1,15-0.3,22.5-0.8c12.6-0.8,25.4-2.8,37.7-6c22.2-5.7,43.7-14,65.7-20.6
								c11.9-3.5,24-6.1,36.2-8.4c16.7-3.1,33.8-4.9,50.7-6c33.8-2.2,67.5-0.9,101.3,1.5c-5.4-9.2-10.8-18.4-16.2-27.6
								c-5.2,1.2-10.5,2.2-15.8,3c-7.6,1.3-15.2,2.5-22.9,3.6c-3.1,0.8-6.1,1.7-9.2,2.5c-10,3.2-20.3,5-30.8,5.3
								c1.4,0,2.9-0.1,4.3-0.1c-19.4,0.5-38.8,0.5-58.2,0.9c-8.4,0.2-16.7,0.4-25,0.8c-9.7,0.7-19.3,1.5-28.9,2.5
								c-49.1,4.6-98.1,10.7-147.1,17.1c-2.6,1.1-4.1,3.2-4.2,6c0.2,2.8,1.5,5,3.8,6.6c3.1,3.3,6.6,6.2,10.4,8.6
								c4.6,3.5,9.5,6.3,14.9,8.4c9.2,2.7,18.7,5.7,28.2,7.5c1.3,0.2,2.6,0.5,4,0.7c10.8,1.8,21.7,2.1,32.6,0.7
								c-2.1-0.1-4.3-0.3-6.4-0.4c12.9,4.3,26.1,7.3,39.5,9c13,1.7,26,2.4,39.1,2c20.9-0.6,41.5-3.7,62.2-6.3
								c21.1-2.6,42.2-4.3,63.5-4.3c11.8,0,23.6,0.5,35.3,1.5c11.5,1,23,2.4,34.5,4.3c-4.9-9.3-9.8-18.6-14.7-27.9
								c-2,1.1-4,2.2-6,3.2c2.2-0.4,4.4-0.8,6.6-1.2c-3.6-0.7-7.2-1-10.9-0.7c-3.1,0.1-6.1,0.5-9.2,1c2.1,0.5,4.2,1.1,6.2,1.6
								c-2.1-2.5-4.5-3.6-7.3-3.2c-4.7,0.1-9.2,0.9-13.7,2.3c1.6-0.2,3.2-0.4,4.9-0.5c-3.3,0-6.6,0-9.8,0c2,0.4,4,0.7,5.9,1.1
								c-4-0.8-8-1.5-12-2.3c-2.9-1.4-5.5-1.2-8,0.5c-1.6,1.3-2.5,2.9-2.7,4.9c-0.7,1.7-0.5,3.5,0.6,5.1c2.8,2.7,6.1,4.8,9.7,6.3
								c4.6,2.1,8.9,4.5,14.1,4.7c4.5,0.2,9,0.1,13.4,0.2c-0.9-0.2-1.8-0.5-2.7-0.7c7.9,3.8,16.2,6.5,24.8,8
								c8.2,1.6,16.5,2,24.9,1.3c7.7-0.7,15.2-2.1,22.6-4.1c8.1-2.2,16.3-5.4,23.7-9.3c6.4-3.4,12.5-7.3,18.2-11.8
								c5.7-4.4,11-9.3,15.9-14.7c1.5-2.2,2-4.6,1.6-7.2c-0.8-2.9-2.8-4.6-5.8-5.2c-4.2-1.5-8.4-2.5-12.8-2.9c-3-0.8-5.5,0-7.5,2.2
								c-2.3,2-4.6,4.1-6.9,6.1c-3.6,2.9-7.3,5.6-11.3,7.9c-2.6,1.7-5.2,3.2-8,4.5c-4,2.2-8.2,4.1-12.5,5.7c1.1-0.4,2.1-0.7,3.2-1.1
								c-4,1.3-8,2.7-12,4c1.1-0.3,2.2-0.6,3.2-0.9c-4.1,1-8.2,2-12.3,3.1c1.1-0.3,2.2-0.5,3.2-0.8c-4.6,1.1-9.3,1.7-14.1,1.8
								c-4.7,0.5-9.5,0.4-14.2-0.3c-2.7-0.4-5.4-0.7-8.1-1.1c-3.8-0.2-7.7,0-11.5,0.7c1.2-0.1,2.5-0.1,3.7-0.2
								c-6.2,0.5-12.3,0.2-18.4-1.1c1.3,0.3,2.6,0.7,4,1c-6-2.3-11.9-4.5-17.9-6.8c3.6,6.7,7.2,13.4,10.8,20.1
								c5.1-0.3,9.7-1.9,13.8-4.9c-2.8,0.6-5.5,1.2-8.3,1.9c5,0,10.1,0,15.1,0.1c-2.1-0.5-4.2-1.1-6.2-1.6c4,2.1,8.2,3.7,12.5,4.9
								c7.3,2.8,14.7,4.8,22.4,6.2c5.5,1.3,10.1-3.7,8.7-9c-2.3-4-5.2-7.6-8.7-10.6c-3.2-3.4-6.9-6.1-11-8.2
								c-11.7-1.5-23.5-2.5-35.3-3.1c-11.9-0.6-23.8-0.7-35.7-0.4c-23.5,0.7-46.8,3.4-70.1,6.8c-21.9,3.2-43.7,6.7-65.8,6.8
								c-10.9-0.1-21.8-0.6-32.6-1.5c-6.3,0-12.6,0.4-18.9,1.1c1.2-0.1,2.5-0.1,3.7-0.2c-12.8-0.5-25.4-2-37.9-4.8
								c1.3,0.2,2.6,0.5,4,0.7c-16.4-4.4-32-10.8-46.8-19.1c6.1,8.9,12.3,17.7,18.4,26.6c50.3-7,100.7-14.1,151.1-20.7
								c-1.3,0.2-2.7,0.3-4,0.5c12.4-1.5,24.8-2.9,37.1-4.3c10.5-1.2,21-2.4,31.5-3.9c8.8-1.3,17.7-2.7,26.4-4.9
								c5.8-1.3,11.3-3.3,16.5-6c4.8-2.6,9.9-4.3,15.3-5.2c-0.9,0-1.8,0-2.8,0c3.6,0.4,7.1,0.9,10.7,1.3c-1-0.1-1.9-0.3-2.9-0.4
								c13.7,3.1,27.6,5.1,41.7,6.3c2.7,0.6,5-0.1,6.9-2.3c1.9-2.1,2.3-4.5,1.4-7.1c-2.5-4-5.6-7.4-9.2-10.4
								c-3.4-3.3-7.2-5.9-11.5-7.9c-17.3-0.8-34.6-1.5-52-1.3c-11.6,0.1-23.1,0.5-34.7,1.1c-32.4,1.7-64.6,6.5-96.1,14.2
								c-20.8,5.1-41.2,11.8-62,17.1c-9.6,2.5-19.3,4.5-29.2,6.1c-12.3,1.9-25,2.7-37.5,3.1c-24.5,0.7-49,0.6-73.5,1.6
								c-32.8,1.3-65.6,3.6-98.3,6.6c-73.3,6.7-146.2,17.2-218.9,28.2c1.2-0.2,2.4-0.4,3.7-0.5c-6.2,0.8-12.4,1.5-18.6,2.3
								c-2.7-0.3-4.9,0.7-6.5,2.9c-1.7,2.2-2,4.6-0.9,7.1c2.6,3.6,5.8,6.7,9.6,9.2C201.7,1437,205.5,1439.2,209.7,1440.7z"/>
							<path opacity="0.1091" fill="#131313" d="M213.3,1407.2c10.5-0.2,20.9-1.2,31.3-2.9c9.9-1.4,19.7-2.8,29.6-4.1
								c16.3-2.3,32.7-4.6,49-6.8c35.8-4.8,71.6-9.3,107.5-12.7c32.4-3.1,64.9-5.5,97.5-6.9c19.4-0.8,38.9-1.3,58.4-1.2
								c10.2,0.1,20.5,0.2,30.7,0.1c10.5-0.2,21.1-0.6,31.5-2c10.6-1.4,21-3.6,31.3-6.4c22.2-6,43.6-14.5,65.8-20.5
								c27.7-7.4,56-11.5,84.7-13.4c33.6-2.2,67.4-0.6,101,1.9c-1.4-6.9-2.7-13.8-4.1-20.7c-7.9,1-15.9,1.7-23.9,1.9
								c-9.9,0.6-19.8,0.7-29.8,0.3c-3-0.9-5.9-1.1-8.9-0.6c-5,0.6-10,1.4-14.9,2.3c-5.6,0.5-11.2,1-16.8,1.5
								c-6.8,0.2-13.6,0.4-20.4,0.4c-13.2,0.2-26.3,0.2-39.5,0.5c-7.9,0.1-15.7,0.3-23.6,0.6c-9.8,0.6-19.6,1.3-29.4,2.1
								c-9.7,0.8-19.5,1.6-29.2,2.6c-38.3,3.8-76.5,8.5-114.7,13.3c-3.1,0.8-4.5,2.8-4.3,6c0.9,3.4,2.8,6,5.7,7.9
								c4.3,4.2,9.1,8,14.2,11.3c6.1,4.6,12.7,8.5,19.6,11.8c8.3,1.7,16.6,2.8,25,3.2c9.2,0.6,18.4,1.1,27.6,1.4
								c-0.9,0-1.8-0.1-2.7-0.1c13.6,5.1,27.5,8.4,41.9,10.1c13.2,1.7,26.4,2.3,39.7,1.9c21.2-0.6,42.2-4.1,63.2-6.8
								c21.1-2.7,42.2-4.4,63.5-4.4c11.7,0,23.4,0.5,35.1,1.5c11.5,1,22.9,2.5,34.2,4.4c-1-6.8-1.9-13.7-2.9-20.5
								c-3.2,0.6-6.3,1.1-9.5,1.7c0.9-0.1,1.9-0.2,2.8-0.3c-8.9-0.1-17.6-1.3-26.2-3.5c1.8,1,3.7,2.1,5.5,3.1
								c-2.4-3.7-5.5-5.7-9.3-6.2c-2.6-0.5-5.3-0.5-8-0.1c1.5,0.2,3.1,0.5,4.6,0.7c-5-0.8-9.9-1.6-14.9-2.4c2,0.4,4.1,0.8,6.1,1.3
								c-3.8-0.9-7.6-1.8-11.4-2.8c-3-1.6-6-1.6-8.7,0.2c-1.7,1.3-2.6,2.9-2.7,4.8c-0.7,1.8-0.4,3.6,0.8,5.3c3.2,2.9,6.9,5.3,11,6.9
								c3.6,2.2,7.4,3.7,11.5,4.6c3.7,0.4,7.4,0.3,11-0.3c-1.8-1.2-3.5-2.3-5.3-3.5c5.5,5.4,12.1,9,19.8,10.8
								c8.8,2.7,17.7,4.2,26.9,4.4c14,0.4,27.7-2.1,40.8-6.9c8.6-3.2,16.6-7.2,24.3-12.1c3.9-2.5,7.7-5.2,11.2-8.2
								c5.6-4.6,10.7-9.6,15.4-15.1c1.4-2.2,1.9-4.6,1.6-7.2c-0.5-2.7-2.1-4.3-4.9-5c-3.5-1.3-7.2-2.2-11-2.4
								c-2.8-0.6-4.9,0.3-6.5,2.5c-3.7,4.7-7.8,9.1-12.4,13.1c-4.6,4-9.4,7.5-14.6,10.7c0.9-0.5,1.8-1,2.6-1.6
								c-3.5,2-7.1,3.9-10.6,5.9c0.9-0.5,1.8-0.9,2.7-1.4c-5.8,2.9-11.7,5.2-18,6.9c-6.8,1.8-13.7,2.8-20.7,3
								c-7.3,0.2-14.5-0.4-21.7-2c-8.3-1.4-16.6-3.2-24.7-5.2c2.6,2,5.3,4.1,7.9,6.1c-1.6-2.6-3.7-4.7-6.3-6.2
								c-5.9-3.9-12.4-6.6-19.6-8c4.2,7,8.5,14.1,12.7,21.1c2.1-0.9,4.3-1.7,6.6-2.3c-1.6,0-3.1,0.1-4.7,0.1c3.8,0.2,7.7,0.6,11.5,1
								c4.1,0.7,8.2,0.8,12.3,0.5c-2.1-0.2-4.2-0.4-6.3-0.6c9.9,4.4,20.3,7.4,31.1,9c2.4-0.1,4.4-1.1,6-2.9c1.8-1.6,2.8-3.6,2.9-6
								c-0.9-2.7-2.5-5.1-4.7-7c-1.9-2.2-4.3-3.7-7-4.6c-11.8-1.7-23.5-3.4-35.4-4.3c-11.9-0.9-23.9-1.4-35.8-1.3
								c-12,0.1-24.2,0.7-36.2,1.8c-14.5,1.3-29,3.5-43.4,5.8c-11.4,1.8-22.8,3.5-34.3,4.6c-5.9,0.8-11.7,1.1-17.6,1.1
								c-6,0.4-12,0.4-18-0.1c-6.1,0-12.1-0.5-18.2-1.3c-5.7-0.6-11.4-1.2-17.2-1.7c-6.7-0.3-13.4-1.4-19.9-3.3
								c-9-2-18.1-3.9-27.2-5.7c1.4,0.3,2.8,0.6,4.1,0.9c-19.7-5.1-38.3-13-55.7-23.7c11.7,12.4,23.5,24.7,35.2,37.1
								c37.5-5.4,75-10.9,112.4-16.6c6.2-1,12.5-1.9,18.7-2.9c12.3-1.9,24.6-3.7,36.9-5.6c11.5-2,23-4.3,34.4-6.8
								c-1.3,0.3-2.7,0.6-4,0.9c7.8-1.5,15.6-3.3,23.3-5.3c7.7-2,15.2-4.5,22.5-7.5c-1.1,0.3-2.2,0.6-3.3,0.9
								c3.3-0.9,6.5-1.8,9.8-2.7c5.7-2.5,11.5-3.4,17.4-2.7c4.8,0.2,9.5,1,14.1,2.4c10.7,2.5,21.5,3.9,32.6,4
								c2.4-0.2,4.4-1.2,5.9-3.1c1.8-1.7,2.6-3.7,2.6-6.1c-1.1-2.7-2.8-5.1-5.1-6.9c-2.1-2.1-4.5-3.6-7.4-4.5
								c-17.4-1.2-34.7-2.4-52.1-2.7c-14.5-0.2-29-0.1-43.5,0.6c-14.5,0.7-28.9,1.9-43.3,3.8c-8.7,1.1-17.2,2.5-25.8,4.3
								c-8.6,1.6-17.1,3.5-25.5,5.9c-19.2,5.5-37.8,12.6-56.9,18.4c-11.6,3.6-23.6,6.5-35.7,7.9c-10.4,1.2-20.8,1.8-31.3,2
								c-10.4,0.2-20.8,0-31.2,0c-16.4,0-32.9,0.3-49.3,0.9c-36.1,1.3-72.2,3.8-108.2,7.3c-32.8,3.2-65.5,7.1-98.2,11.4
								c-19.6,2.6-39.2,5.4-58.9,8.1c-6.6,0.9-13.1,1.8-19.7,2.8c-13.3,2.4-26.7,4.1-40.3,5c-4.1,0.4-8.4,6-7.4,10.1
								c1.3,2.6,3.2,4.6,5.6,6.2C208,1405.5,210.5,1406.7,213.3,1407.2z"/>
							<path opacity="0.1091" fill="#131313" d="M234.9,1433c58.3-8.9,116.7-17.2,175.3-23.9c61.6-7,123.5-11.7,185.5-12
								c9.4-0.1,18.9,0,28.3,0c10.4,0,20.8,0,31.2-0.8c8.5-0.7,17.1-1.7,25.5-3.3c10.3-2,20.3-4.9,30.3-8
								c17.8-5.6,35.2-12.2,53.2-17.1c11.2-3.1,22.6-5.4,34-7.5c16.8-3.1,33.8-4.5,50.8-5.8c-1.4,0.1-2.8,0.2-4.3,0.2
								c7.1-0.3,14.1-0.7,21.2-1c-1.4,0.1-2.8,0.1-4.3,0.2c7.1-0.1,14.1-0.3,21.2-0.4c-1.4,0-2.8,0-4.3,0.1
								c11.3-0.1,22.6,0.2,33.8,0.6c11.3,0.5,22.6,1.2,33.8,2.1c-7.1-10.7-14.2-21.4-21.4-32.1c-4.4,2.6-9,4.7-13.8,6.4
								c-6.3,2.7-12.8,4.8-19.5,6.2c-8,2.1-16,4.1-24.1,5.3c-7.5,0.8-14.9,1.1-22.4,1.2c-7.4,0.2-14.9,0.3-22.3,0.2
								c-10.8,0-21.5,0.1-32.3,0.4c-4.1,0.1-8.3,0.2-12.4,0.4c-8.5,0.2-16.9,0.9-25.3,1.8c-16,1.5-31.9,3.2-47.9,5
								c-34.8,3.9-69.5,8.4-104.2,13c-2.8-0.2-4.9,0.8-6.5,3.1c-1.6,2.3-1.9,4.6-0.7,7.2c2.8,3.6,6.1,6.6,10,9.1
								c3.6,2.8,7.6,4.9,11.9,6.3c4.7,1.8,9.3,3.6,14,5.4c6.3,3.1,12.9,5,19.7,5.8c4,0.6,8.1,1,12.2,1.4c7.1,1.1,14.3,1.6,21.6,1.6
								c-0.9-0.1-1.8-0.3-2.7-0.4c6.6,1.7,13.2,3.3,19.8,5c9.8,2.1,19.6,3.4,29.6,3.9c6.4,0.7,12.8,1,19.3,0.8
								c4.3,0.2,8.7,0.1,13-0.2c6.1-0.4,12.3-0.8,18.4-1.2c6-0.6,12-1.3,17.9-1.9c8.7-1.1,17.3-2.2,26-3.3
								c9.1-1.2,18.1-2.1,27.3-2.8c-1.3,0.1-2.6,0.2-4,0.2c7.5-0.4,14.9-0.8,22.4-1.2c-1.3,0.1-2.7,0.1-4,0.2
								c6-0.1,12.1-0.2,18.1-0.3c8.9,0,17.8,0.3,26.7,1c5.9,0.3,11.7,0.7,17.5,1.5c8.7,0.8,17.3,1.9,25.9,3.5
								c-7.5-10.1-15-20.1-22.6-30.2c-1.1,2-2.2,4-3.4,6c2.1-1.3,4.1-2.7,6.2-4c-5.4,0.8-10.6,2.2-15.7,4.1c0.9,0.1,1.8,0.1,2.8,0.2
								c-1.6-0.8-3.3-1.2-5.1-1c-6,0.9-11.9,1-17.9,0.3c1.6,0.3,3.1,0.6,4.7,0.9c-3.7-0.9-7.3-1.8-11-2.7c-2.5-1.2-4.8-0.8-6.7,0.9
								c-1.8,1.8-2.9,4-3.3,6.6c-0.2,2.7,0.9,4.6,3.3,5.9c3,2.1,6.2,3.7,9.7,4.8c2.1,0.9,4.2,1.6,6.5,2.1c5.1,1.3,10.2,1.5,15.6,0.7
								c-0.8-0.3-1.7-0.6-2.5-0.9c20.9,10.6,45.5,12.2,68.2,7.8c10.8-2.1,21.7-6,31.4-11.1c6.4-3.4,12.4-7.3,18-11.8
								c5.6-4.4,10.9-9.3,15.7-14.6c1.9-2.1,2.5-4.5,1.6-7.2c-1.8-3.3-4.7-5.3-8.6-5.9c-5.9-1.9-12-3.3-18.2-4.1
								c-3.8-1.2-7.2-0.7-10.3,1.6c-4.1,3.7-8.1,7.4-12.5,10.6c-5.6,4-11.5,7.5-17.7,10.5c-5,2.4-10,4.5-15.3,6.1
								c-2.6,0.9-5.3,1.6-8,2.1c-4.1,1-8.2,1.7-12.4,2c1.1-0.1,2.2-0.2,3.4-0.2c-3.8,0.2-7.7,0.4-11.5,0.6c1.1,0,2.1-0.1,3.2-0.1
								c-6.7-0.6-13.3,0-19.8,1.9c1.2-0.1,2.5-0.3,3.7-0.4c-4.8,0-9.6,0-14.3,0c0.8,0.3,1.7,0.6,2.5,0.9c-6.8-3.9-14-6.9-21.7-8.8
								c2.3,6.3,4.7,12.5,7,18.8c2,0.2,4,0.2,6,0c2.3-0.1,4.7-0.5,6.9-1c-1.3,0.1-2.6,0.3-4,0.4c5.8,0.2,11.6-0.2,17.3-1.1
								c-2.1-0.5-4.2-1-6.2-1.5c3.8,1.9,7.8,3.5,12,4.5c7.5,2.6,15.1,4.7,22.9,6.4c2.8,1.1,5.3,0.6,7.5-1.5c2-2.2,2.4-4.8,1.2-7.5
								c-3-4.7-6.6-8.9-10.7-12.5c-3.9-4-8.2-7.4-13-10.1c-12-1.6-24-2.6-36-3.2c-6-0.2-12-0.4-18.1-0.5
								c-10.4-0.1-20.8,0.2-31.1,0.8c-20.2,1.1-40.2,3.6-60.1,7c-10.3,1.8-20.6,3.6-30.9,5.2c-10.4,1.6-20.9,2.7-31.4,3.2
								c1.1-0.1,2.2-0.1,3.3-0.2c-8.3,0.4-16.6,0.5-24.9,0.5c-8.5-0.3-17,0.4-25.4,2.2c1.2-0.1,2.5-0.3,3.7-0.4
								c-7.4-0.6-14.8-1.2-22.2-1.8c0.6,0.1,1.1,0.2,1.7,0.3c-13.5-3.3-26.7-7.6-39.6-12.8c1.6,0.7,3.1,1.5,4.7,2.2
								c-8.8-4.3-17.6-8.6-26.5-12.9c3.1,7.8,6.3,15.7,9.4,23.5c28.3-3.9,56.6-7.8,84.9-11.5c28.4-3.7,56.7-7.7,85.2-10.5
								c16.6-1.7,33.3-3,49.9-4.7c11-1.1,22-2.4,32.9-4.2c4.1-0.5,8.2-1.4,12.1-2.6c6.8-1.7,13.4-3.7,19.9-6.3c-0.9,0-1.8,0-2.7-0.1
								c3.5,0.3,6.9,0.6,10.4,0.8c-1-0.1-1.9-0.3-2.9-0.4c13.7,2.6,27.6,4.7,41.5,6.1c1.4,0.1,2.8,0.2,4.2,0.3
								c1.1-0.7,2.2-1.3,3.3-2c0.5-1.2,1.1-2.3,1.6-3.5c0.1-2.8-0.9-5.1-3-6.9c-3.8-4.8-8.5-8.9-13.3-12.8c-2.1-2-4.4-3.6-6.9-5
								c-2-1.9-4.5-2.6-7.3-2.1c-17.7-0.8-35.3-1.4-53-1.2c-14.7,0.2-29.4,0.8-44.1,1.9c-14.7,1.1-29.2,2.7-43.8,4.9
								c-8.7,1.3-17.3,2.9-25.9,4.7c-8.5,1.6-16.9,3.6-25.3,5.8c-18.7,5.1-37.1,11.3-55.9,16.1c-11.4,2.9-23.1,5.2-34.8,6.3
								c-10.3,1-20.7,1.5-31.1,1.7c-27.2,0.6-54.4,0.8-81.6,2.1c-36.3,1.6-72.6,4.4-108.7,8.2c-32.9,3.4-65.6,7.4-98.4,11.8
								c-38.6,5.2-77.1,10.8-115.6,16.5c-2.7,1.1-4.1,3.1-4.4,5.9c0.5,4,2.5,7,6,9.2c5,4.4,10.3,8.3,16,11.8
								C227.5,1432.7,231,1433.7,234.9,1433z"/>
							<path opacity="0.1091" fill="#131313" d="M230.8,1403.7c7.2-0.3,14.4-0.5,21.6-0.8c6.4-1,12.7-2,19.1-3
								c13-2,26.1-3.8,39.2-5.7c26.1-3.7,52.2-7.2,78.3-10.3c55.2-6.6,110.6-11.7,166.2-13.5c22.5-0.8,45-0.8,67.4-0.7
								c15.2,0.1,30.5-0.2,45.5-2.3c6.9-1,13.8-2.1,20.6-3.7c8.2-1.9,16.2-4.2,24.1-6.8c17.7-5.7,35.2-12,53-17.4
								c-1.1,0.3-2.3,0.6-3.4,0.9c8.4-2.3,16.9-4.3,25.4-5.8c8.4-1.8,16.9-3.2,25.5-4.3c14.1-1.8,28.2-3,42.4-3.6
								c31.2-1.4,62.2,0.1,93.3,2.5c-4-7.9-8-15.9-12-23.8c-5.8,1.5-11.7,2.8-17.6,3.9c-8.8,2-17.8,2.8-26.8,2.5
								c-3.3-0.6-6.5-0.7-9.7-0.4c-5.1,0.4-10.2,1.1-15.3,1.8c-12.3,1.1-24.5,1.3-36.8,1.4c-12.9,0.1-25.9,0.3-38.8,0.6
								c-6.2,0.1-12.4,0.4-18.6,0.7c-6.4,0.5-12.8,1.1-19.3,1.6c-14.1,1.2-28.5,2.7-42.6,4.2c-38.1,4-76.2,8.8-114.2,13.7
								c-2.7,1.1-4.1,3.1-4.3,6c0.3,2.9,1.6,5.2,4,6.8c3.2,3.4,6.8,6.4,10.9,9c4.8,3.6,9.9,6.6,15.4,8.9c6.7,1.6,13.3,3.2,20,4.8
								c2.3,0.7,4.6,1.2,7,1.6c10,1.6,20.3,1.7,30.3,3.4c7,2,14,3.9,20.9,5.9c10.1,2.3,20.2,3.6,30.5,4.1c6.5,0.7,13,1,19.6,0.7
								c9.3,0.2,18.7-0.3,28-1.3c11.8-1.2,23.4-2.9,35.2-4.5c14.9-2,29.7-3.7,44.7-4.6c15-0.9,30.1-1.1,45.1-0.4
								c5.9,0.4,11.8,0.8,17.6,1.2c11.6,1,23.1,2.6,34.6,4.6c-3.4-7.8-6.8-15.7-10.3-23.5c-2.4,1.5-4.8,3-7.1,4.5
								c2.1-0.6,4.2-1.2,6.2-1.8c-4.6,0.3-9.1,0.6-13.7,0.9c1.2,0,2.5-0.1,3.7-0.1c-4.8-0.5-9.6-0.9-14.3-1.4
								c0.8,0.3,1.7,0.6,2.5,0.9c-2.2-2-4.7-3.3-7.6-3.7c-5.9-0.1-11.7-0.8-17.4-2.3c1.6,0.4,3.3,0.7,4.9,1.1c-3.5-1-7-1.9-10.5-2.9
								c-2.7-1.3-5.1-1.1-7.4,0.7c-1.9,1.8-3,4-3.2,6.6c0,2.8,1.3,4.9,4,6.2c3.4,2.3,7,4.1,10.9,5.4c1.6,0.8,3.2,1.3,4.9,1.7
								c4.8,1.4,9.8,1.5,14.9,0.3c-2.9-1.7-5.8-3.3-8.6-5c6.6,6.5,14.5,10.8,23.6,12.8c8.6,2.4,17.3,3.7,26.2,3.8
								c11.6-0.1,23.3-1.5,34.4-5.2c8-2.6,15.8-5.8,23.2-9.8c6.3-3.5,12.2-7.5,17.8-12c5.6-4.5,10.8-9.5,15.5-14.9
								c1.7-2.1,2.2-4.5,1.6-7.2c-1.3-3.1-3.7-5-7.2-5.5c-5.1-1.7-10.2-2.8-15.5-3.4c-3.4-1-6.4-0.3-8.9,1.9
								c-3.3,4.4-6.9,8.4-10.9,12.1c-3,2.9-6.2,5.5-9.7,7.9c-3.3,2.6-6.9,4.9-10.6,6.9c-5.1,2.8-10.5,5.1-16,6.9c1-0.3,2-0.6,3-1
								c-4.4,1.5-8.9,2.6-13.6,3.1c-4.8,0.9-9.6,1.3-14.5,1.1c-3.3-0.2-6.6-0.5-9.9-0.7c-9.1-0.5-18.1-1.7-27.1-3.6
								c2.9,1.7,5.8,3.3,8.6,5c-2.8-2.6-5.8-5-8.8-7.3c-4.8-3.7-10.4-6-16.7-6.8c2.9,6.5,5.8,13.1,8.7,19.6c1.9-0.2,3.9-0.4,5.8-0.7
								c-1.2,0.1-2.5,0.1-3.7,0.2c3.7,0.3,7.4,0.7,11,1.1c4.9,0.2,9.7,0.3,14.6,0.4c-0.9-0.2-1.8-0.4-2.7-0.7
								c9.6,4.1,19.5,6.9,29.8,8.4c4.8,1,9.5-4.6,8.6-9.1c-1.6-3.3-3.8-6.2-6.6-8.6c-2.6-2.7-5.5-4.8-8.9-6.2c-12-2-24-3.4-36.1-4.4
								c-12.1-0.9-24.3-1.4-36.4-1.3c-23.6,0.2-47,2.8-70.3,6.7c-21.8,3.7-43.3,8-65.4,8.7c-11.6,0.3-23.1,0-34.6-1.1
								c-14.1,0.2-28-1.6-41.7-5.3c0.5,0.1,1.1,0.2,1.6,0.4c-8.4-2.7-16.7-5.5-25-8.3c-11.7-3.4-22.8-8.3-33.4-14.5
								c6.5,9.1,12.9,18.3,19.4,27.4c56.7-8.1,113.4-16.4,170.1-24.4c17.9-2.5,35.7-5,53.5-8.2c7.4-1.4,14.8-2.9,22.2-4.4
								c6.6-1,13.2-2.3,19.7-3.9c5.4-1.9,11-2.4,16.7-1.6c-0.9,0-1.8-0.1-2.7-0.1c13.9,2.8,27.9,4.7,42,5.8c4.9,0.5,9.4-4.6,8.3-9.4
								c-1.9-3.4-4.3-6.3-7.3-8.7c-2.8-2.7-6-4.8-9.5-6.2c-29.5-2.1-59-3.6-88.6-2.6c-14.8,0.5-29.5,1.6-44.1,3.3
								c-14.5,1.7-29.1,3.9-43.3,7.2c-6.7,1.6-13.5,3.2-20.1,5.1c-9.4,2.6-18.6,5.8-27.8,9c-9.1,3.2-18.2,6.4-27.5,9.3
								c-3.7,1.1-7.4,2.1-11.1,3.2c-7.7,2.1-15.6,3.6-23.5,4.5c1.1-0.2,2.3-0.3,3.4-0.5c-8.2,1-16.5,1.6-24.8,1.8
								c-4.2,0.1-8.4,0.1-12.5,0.2c-15.2,0.1-30.4-0.2-45.6,0c-39.7,0.5-79.3,2.7-118.9,6.2c-39.5,3.5-78.9,8-118.2,13.2
								c-16.4,2.2-32.7,4.4-49.1,6.8c-9.8,1.4-19.6,2.8-29.4,4.3c-13,2.8-26.1,4.1-39.3,4.1c-2.6,0-4.7,1.1-6.2,3.2
								c-1.6,2.1-2.1,4.3-1.3,6.8c2.2,3.2,4.9,5.9,8.2,8.1C223.6,1400.7,227,1402.5,230.8,1403.7z"/>
							<path opacity="0.1091" fill="#131313" d="M195.8,1428.8c10.5-0.2,20.9-1.2,31.3-2.9c9.9-1.4,19.7-2.8,29.6-4.1
								c16.3-2.3,32.7-4.6,49-6.8c35.8-4.8,71.6-9.2,107.6-12.6c32.5-3.1,65-5.4,97.6-6.8c19.4-0.8,39-1.3,58.5-1.1
								c10.3,0.1,20.5,0.3,30.8,0.1c10.5-0.2,21.1-0.6,31.5-2c10.5-1.5,20.8-3.7,31.1-6.6c10.1-2.8,20.1-6.1,30.1-9.5
								c11.7-3.9,23.3-8.1,35.2-11.3c27.8-7.5,56.2-11.5,84.9-13.3c33.8-2.2,67.7-0.4,101.4,2.3c-1.5-7.1-3-14.1-4.5-21.2
								c-17.7,0.4-35.4,1.2-53.2,0.6c-2.1-0.1-4.1-0.1-6.2-0.2c-3.4,0.6-6.8,1.3-10.1,2.1c-7.8,1.9-15.8,3-23.9,3.2
								c-4.6,0.1-9.3,0.3-13.9,0.4c-13.5,0.3-26.9,0.1-40.4,0.3c-13.4,0.2-26.7,0.4-40,1.3c-16.3,1.1-32.5,2.5-48.8,4.1
								c-38.3,3.7-76.5,8.5-114.7,13.4c-3.1,0.8-4.5,2.8-4.2,6c0.9,3.4,2.9,6.1,5.8,8c4.4,4.3,9.2,8.1,14.4,11.4
								c6.2,4.6,12.9,8.6,19.9,12c8.7,2.1,17.6,3.4,26.5,3.7c8.7,0.9,17.3,0.9,26,0c-0.9-0.1-1.8-0.3-2.7-0.4
								c13.3,5.3,27,8.8,41.2,10.5c13.2,1.7,26.5,2.4,39.8,2.1c21.4-0.5,42.4-3.8,63.6-6.5c21.1-2.6,42.2-4.4,63.4-4.4
								c11.8,0,23.5,0.6,35.2,1.6c11.5,1,23,2.6,34.4,4.6c-1.2-7.1-2.4-14.2-3.6-21.3c-3.2,0.2-6.4,0.4-9.7,0.6c1,0,1.9-0.1,2.9-0.1
								c-8.4-1.1-16.7-2.6-24.9-4.3c1.9,0.9,3.9,1.8,5.8,2.7c-2.1-4-5-5.9-8.7-5.7c-4.6-0.2-9.2,0.3-13.8,1.4
								c3.6,0,7.2,0.1,10.8,0.1c-5.3-0.5-10.6-1-15.8-1.5c2.3,0.4,4.6,0.8,6.9,1.3c-6.1-1.2-12.2-2.4-18.2-3.6
								c-1.2,0.4-2.4,0.8-3.6,1.2c-1.7,1.2-2.6,2.9-2.7,4.9c-0.6,1.3-0.4,2.5,0.4,3.6c0.8,2.6,2.6,4.3,5.5,5.2
								c5.7,4.3,12,7.2,18.8,8.6c4,0.6,8,0.5,12-0.3c-0.7,0.1-1.4,0.1-2.1,0.2c1.1,0.1,2.3,0.2,3.4,0.2c-0.9-0.2-1.8-0.3-2.7-0.5
								c8.3,4.8,17.1,7.9,26.5,9.5c8.5,1.5,17.1,1.9,25.8,1.2c8.2-0.6,16.2-2.1,24-4.6c5.5-1.6,10.8-3.7,15.8-6.4
								c5.8-2.8,11.3-6.1,16.5-9.8c2.8-2.2,5.7-4.4,8.5-6.6c5.5-4.7,10.6-9.7,15.2-15.2c1.2-2.2,1.9-4.6,1.9-7.1
								c0-2.4-1.1-4-3.4-4.8c-2.6-1.3-5.4-2-8.3-2.2c-2.4-0.4-4.1,0.5-5.3,2.6c-2.2,2.3-4.4,4.6-6.6,7c0.7-0.7,1.5-1.3,2.2-2
								c-3.1,2.8-6.3,5.6-9.4,8.4c0.8-0.6,1.6-1.2,2.3-1.8c-3.3,2.5-6.7,5.1-10,7.6c0.8-0.6,1.6-1.1,2.4-1.7
								c-3.5,2.3-7.1,4.5-10.6,6.8c0.8-0.5,1.7-1,2.5-1.5c-3.7,2-7.4,3.9-11.2,5.9c0.9-0.5,1.7-0.9,2.6-1.4
								c-6.6,3-13.4,5.2-20.4,6.6c-5.4,1.2-11,1.8-16.5,1.8c-5.5,0.4-11,0.2-16.4-0.6c-6.7-1.5-13.5-2.8-20.3-3.9
								c0.9,0.2,1.8,0.3,2.7,0.5c-6.1-1.6-12.3-2.8-18.6-3.4c1.5,0.4,3,0.8,4.6,1.2c-4.1-1.7-8.2-3.3-12.3-5
								c3.5,5.8,7,11.6,10.6,17.4c0.8-0.4,1.6-0.8,2.5-1.2c-1.7,0-3.4,0-5.1,0c6.1-0.6,12.1-0.1,18,1.3c-0.9-0.3-1.8-0.6-2.7-0.8
								c10.2,4.5,20.7,7.8,31.7,9.7c2.4,0,4.4-1,6.1-2.8c1.8-1.6,2.8-3.7,2.8-6.1c-1.1-2.9-2.7-5.3-5.1-7.4c-2.1-2.3-4.5-4-7.4-5
								c-11.6-1.8-23.3-3.1-35.1-4c-11.9-0.9-23.7-1.2-35.6-1.1c-21,0.2-41.9,2.2-62.7,5c-20.2,2.8-40.3,6.2-60.7,7.1
								c-12.1,0.6-24.2,0.3-36.2-0.7c-11.7-1.6-23.5-2.9-35.3-3.7c0.9,0.1,1.8,0.3,2.7,0.4c-12.8-2.6-25.7-4.8-38.7-6.5
								c1.5,0.4,3.1,0.7,4.6,1.1c-10.2-3.3-20.4-6.7-30.6-10c1.9,0.6,3.8,1.2,5.7,1.9c-10.8-5.4-21.7-10.9-32.5-16.3
								c12,12.5,23.9,24.9,35.9,37.4c53.2-7.6,106.4-15.5,159.7-23.1c16.3-2.3,32.5-5.2,48.7-8.6c7.5-1.6,14.8-3.4,22.1-5.7
								c4-1,7.8-2.5,11.5-4.4c4.5-2,8.9-4.1,13.3-6.5c-1.1,0.5-2.2,1-3.3,1.5c4.2-0.9,8.4-1.7,12.6-2.6c-0.9-0.1-1.8-0.2-2.7-0.4
								c6.6,0.8,13.1,1.9,19.6,3.3c10.9,2.4,22,3.9,33.1,4.6c4.2,0.5,9-5.2,8.5-9.2c-1.2-2.8-3-5.2-5.4-7.2
								c-2.2-2.2-4.7-3.8-7.6-4.7c-17.2-1.1-34.5-2.1-51.7-2.3c-14.4-0.1-28.8,0.1-43.1,0.9c-14.4,0.8-28.7,2.1-43,3.9
								c-8.6,1.1-17.2,2.6-25.7,4.4c-8.6,1.6-17.1,3.5-25.6,5.9c-19.4,5.4-38.3,12.4-57.6,18c-11.8,3.4-23.9,6.2-36.1,7.6
								c-10.4,1.2-20.9,1.7-31.3,2c-10.4,0.2-20.7,0-31.1,0.1c-16.4,0.1-32.8,0.4-49.1,1c-36.1,1.3-72.1,3.9-108,7.5
								c-32.8,3.2-65.4,7.2-98.1,11.5c-19.6,2.6-39.2,5.4-58.8,8.2c-6.6,0.9-13.1,1.8-19.7,2.8c-13.3,2.4-26.7,4.1-40.2,5
								c-2.3,0.5-4.1,1.8-5.4,3.8c-1.6,1.8-2.2,3.9-2.1,6.3c1.2,2.5,3,4.5,5.3,6C190.6,1427.2,193.1,1428.4,195.8,1428.8z"/>
							<path opacity="0.1091" fill="#131313" d="M220.9,1460.7c119.7-17.4,239.9-35.1,361.2-35.6c9.4,0,18.9,0.1,28.3,0.1
								c10.4,0,20.8,0.1,31.2-0.8c8.5-0.7,17.1-1.7,25.5-3.4c10.2-2,20.3-5,30.2-8.2c17.7-5.7,35.2-12.3,53.1-17.3
								c11.2-3.1,22.6-5.4,34-7.5c16.9-3.1,33.8-4.5,50.9-5.7c-1.4,0.1-2.9,0.1-4.3,0.2c7.1-0.3,14.2-0.6,21.3-0.9
								c-1.5,0-2.9,0.1-4.4,0.1c7.1-0.1,14.2-0.2,21.3-0.3c-1.5,0-2.9,0-4.4,0c7.1,0.1,14.2,0.2,21.3,0.3c-1.5-0.1-2.9-0.1-4.4-0.2
								c10,0.2,19.9,0.7,29.8,1.3c8.5,0.5,16.9,1.1,25.4,1.9c-9.5-11.7-19-23.5-28.6-35.2c-4.4,2.2-9,4.1-13.6,5.8
								c-6,2.3-12,4.6-18.1,6.8c-3.2,1.3-6.3,2.5-9.5,3.8c-4.8,1.1-9.5,2.2-14.3,3.3c-7.6,0.8-15.3,1.2-22.9,1.1
								c-7.5,0.2-15.1,0.2-22.6,0c-10.9-0.1-21.7-0.1-32.6,0.1c-4.2,0-8.3,0.1-12.5,0.3c-8.5,0.2-17,0.8-25.4,1.7
								c-16,1.4-32,3.1-48,4.9c-34.8,3.9-69.6,8.4-104.3,12.9c-2.6,0-4.7,1.1-6.1,3.4c-1.4,1.6-1.8,3.3-1.3,5.2
								c0,2.3,1.1,4.2,3.4,5.5c3.4,3.1,7.1,5.7,11.1,7.8c13.1,6.2,26.5,11.4,40.4,15.5c12,2.3,24,3.5,36.2,3.5
								c-0.9-0.2-1.8-0.4-2.7-0.6c9.7,3,19.6,5.2,29.6,6.5c9.7,1.8,19.5,2.9,29.5,3.2c7.6,0.2,15.2,0.3,22.8,0.1
								c6.2-0.4,12.3-0.7,18.5-1.1c11.9-1.1,23.7-2.5,35.6-4.1c5.7-0.7,11.4-1.3,17.1-2c12.4-1.4,24.8-2.3,37.3-2.7
								c-1.4,0-2.8,0-4.1,0.1c7.4-0.1,14.9-0.2,22.3-0.3c-1.4,0-2.8,0-4.2,0c7.4,0.2,14.8,0.4,22.1,0.6c-1.4-0.1-2.9-0.1-4.3-0.2
								c7.3,0.5,14.6,1,22,1.5c-1.4-0.1-2.9-0.2-4.3-0.3c7.3,0.8,14.5,1.6,21.8,2.4c-1.5-0.2-2.9-0.3-4.4-0.5
								c7.2,1.1,14.4,2.2,21.6,3.3c-10-11.3-20.1-22.7-30.1-34c-1.1,1.8-2.2,3.5-3.3,5.3c2.1-1.3,4.1-2.7,6.2-4
								c-3.4-0.4-6.5,0.1-9.4,1.6c-1.8,0.7-3.5,1.7-4.9,3.1c0.9-0.1,1.9-0.2,2.8-0.2c-5.2-0.8-12.8,1.5-16.8,2.3
								c1.4-0.2,2.7-0.4,4.1-0.6c-3.2,0.1-6.4,0.3-9.7,0.4c1.6,0.3,3.2,0.6,4.7,0.9c-3.8-0.9-7.7-1.8-11.5-2.7
								c-2.4-1.1-4.6-0.7-6.5,1.1c-1.8,1.9-2.9,4.1-3.2,6.6c-0.3,2.6,0.8,4.5,3.1,5.8c2.8,2,5.9,3.5,9.2,4.5
								c2.9,1.1,5.8,2.3,8.9,2.9c5.2,1,10.9-0.6,16,1.2c4.1,1.4,8.3,2.9,12.4,4.3c4.2,0.9,8.4,1.9,12.5,2.8
								c4.1,0.4,8.3,0.9,12.4,1.3c4.6,0.1,9.2,0,13.8-0.3c6.4-0.4,12.7-1.5,19-3.1c3.7-0.7,7.3-1.8,10.8-3.2c3.6-1.1,7-2.5,10.3-4.2
								c3.4-1.4,6.6-3.1,9.7-5.1c3.2-1.7,6.2-3.7,9.1-5.9c3-2,5.8-4.2,8.5-6.6c4.2-3.4,8.2-7.1,11.8-11.1c1.7-2.1,2.2-4.5,1.6-7.2
								c-1.2-3.1-3.5-4.9-7-5.5c-4.9-1.7-9.9-2.8-15-3.4c-3.3-1-6.2-0.3-8.7,2c-4.9,3.9-10,7.4-15.3,10.5c-5.3,3-10.7,5.7-16.4,8
								c-5.5,2.3-11.2,4.2-17,5.7c-5.7,1.5-11.5,2.5-17.3,3.1c1.2-0.1,2.4-0.2,3.6-0.2c-4.1,0.2-8.1,0.5-12.2,0.7
								c1.2,0,2.3,0,3.5-0.1c-5,0-9.9,0-14.9-0.1c-5.3-0.4-10.3,0.8-15.1,3.5c1.2-0.4,2.4-0.7,3.6-1.1c-3.5,0.3-7,0.5-10.5,0.8
								c0.8,0.3,1.7,0.6,2.5,0.9c-7.6-3.2-15.3-6.1-23.2-8.8c2.1,6.2,4.3,12.3,6.4,18.5c2.5,0.4,5,0.5,7.5,0.2
								c2.6-0.1,5.1-0.8,7.4-2.1c-1.4,0.2-2.7,0.4-4.1,0.6c4.5-0.3,9-0.7,13.6-1c-1.2-0.3-2.4-0.6-3.6-0.9c2.5,1.5,5.2,2.7,8,3.6
								c9.3,3.3,18.7,6.1,28.2,8.5c1.5,0.2,3,0.4,4.4,0.7c1.1-0.6,2.3-1.2,3.4-1.8c0.6-1.2,1.1-2.3,1.7-3.5c0.1-3-1-5.5-3.2-7.5
								c-4-5.4-8.9-10-13.9-14.5c-2.2-2.2-4.6-4.1-7.2-5.8c-2.1-2.1-4.6-3-7.6-2.8c-11.7-1.1-23.4-1.8-35.2-2.1
								c-11.8-0.2-23.6-0.1-35.4,0.5c-20.5,1-40.8,3.4-61.1,6.4c-18.3,2.8-36.6,5.9-55,7.4c-10.4,0.9-20.9,1.3-31.4,1.2
								c-10.1-0.7-20,0.4-29.8,3.2c1.2-0.1,2.5-0.2,3.7-0.3c-7.5-0.3-15-0.7-22.4-1c0.6,0.1,1.2,0.1,1.8,0.2
								c-14.1-3.3-27.9-7.9-41.3-13.6c1.4,0.6,2.9,1.3,4.3,1.9c-8.9-4.4-17.7-8.8-26.6-13.2c2.4,7.3,4.7,14.5,7.1,21.8
								c28.4-3.8,56.8-7.6,85.2-11.2c12.7-1.6,25.4-3.2,38.2-4.7c15.9-1.9,31.9-3.9,47.8-5.1c17.2-1.3,34.4-2.2,51.5-3.4
								c11.6-0.8,23.1-1.8,34.6-3.6c4.3-0.4,8.5-1.4,12.6-3c6.5-1.9,12.8-4.2,19.1-6.6c-0.9,0.1-1.9,0.1-2.8,0.2
								c6.3,0.6,12.6,1.6,18.9,2.9c10.3,2,20.7,3.6,31.1,4.9c1.5,0.2,3,0.4,4.5,0.5c1.1-0.6,2.2-1.3,3.4-1.9c0.5-1.2,1-2.4,1.5-3.6
								c-0.1-3-1.2-5.5-3.5-7.4c-4.3-5.3-9.4-9.8-14.6-14.1c-2.3-2.2-4.8-4-7.5-5.6c-2.2-2-4.8-2.9-7.8-2.6
								c-17.3-0.4-34.6-0.7-51.9-0.2c-14.4,0.5-28.8,1.3-43.2,2.6c-31.6,2.9-63.1,8.2-93.8,16.1c-19.3,5-38.4,10.6-57.9,14.7
								c-22.1,4.7-44.6,6.3-67.1,6.9c-27,0.7-54,1.4-80.9,2.8c-36.1,1.9-72.1,4.9-108.1,8.8c-71.5,7.7-142.6,18.3-213.7,28.5
								c-2.7,1-4.2,3-4.4,5.9c0.5,4.1,2.6,7.2,6.3,9.5c5.2,4.5,10.7,8.6,16.5,12.3C213.2,1460.2,216.8,1461.4,220.9,1460.7z"/>
							<path opacity="0.1091" fill="#131313" d="M249.4,1429.9c71.3-10.7,142.8-21.2,214.6-28c32.5-3.1,65-5.4,97.6-6.8
								c19.4-0.8,39-1.2,58.4-1.2c10.3,0,20.6,0.2,30.9,0c10.5-0.2,21.1-0.6,31.5-2c10.5-1.4,20.9-3.6,31.2-6.4
								c10.2-2.8,20.2-6.1,30.2-9.4c11.7-3.9,23.4-8.1,35.3-11.2c27.7-7.4,56.1-11.4,84.7-13.3c33.7-2.2,67.5-0.5,101.2,2
								c-11.3-11.6-22.6-23.1-33.9-34.7c-4.8,4.5-10.1,8.3-16,11.4c1.1-0.6,2.2-1.2,3.3-1.8c-4.3,1.8-8.6,3.6-12.9,5.3
								c-5.8,1.8-11.8,3.1-17.8,3.8c-4.7,1-9.5,1.5-14.4,1.4c-8,0.4-16.1,0.5-24.1,0.5c1.1,0,2.2,0,3.3,0c-18.5-0.1-37-0.3-55.5,0.3
								c-16.9,0.5-33.8,2.2-50.6,3.9c-44.4,4.5-88.6,10.3-132.9,16.1c-2.6,0.1-4.6,1.2-6,3.4c-1.4,1.6-1.8,3.3-1.4,5.1
								c-0.1,2.3,1,4.1,3.2,5.4c3.3,3,6.8,5.5,10.7,7.6c12.8,6.1,26.1,11.2,39.6,15.3c12.2,1.9,24.5,3.3,36.8,4.1
								c-0.9-0.1-1.8-0.2-2.7-0.4c13,3.7,26.2,6.2,39.6,7.6c12.8,1.4,25.6,1.8,38.4,1.4c13.4,0,26.8-1.5,40.1-3
								c17.8-2,35.6-4.6,53.5-5.8c12.2-0.9,24.4-1.2,36.7-1.1c11.9,0.1,23.7,0.7,35.5,1.8c-1.5-0.2-3-0.3-4.5-0.5
								c7.3,0.8,14.6,1.6,21.9,2.4c-1.5-0.2-3-0.4-4.5-0.6c7.2,1.1,14.4,2.2,21.7,3.2c-10.3-11.5-20.7-23.1-31-34.6
								c-2.5,3.3-5,6.6-7.6,9.9c0.7-0.7,1.4-1.4,2.1-2.1c-2.9,2.5-6.2,3.3-10,2.4c-5.1,0-10.1-0.3-15.2-0.9c1.3,0.3,2.7,0.5,4,0.8
								c-3.5-1-6.9-2-10.4-3c-2.3-1-4.3-0.6-6,1.2c-1.7,1.9-2.9,4.1-3.3,6.6c-0.4,2.4,0.4,4.3,2.5,5.6c2.5,1.9,5.3,3.3,8.3,4.2
								c2.7,1.1,5.4,2,8.2,2.6c5.4,1.3,10.9,1.6,16.6,0.8c-1.9-0.8-3.9-1.7-5.8-2.5c6,4.3,12.7,7.1,20,8.4
								c8.1,2.1,16.4,3.2,24.8,3.4c3.9-0.1,7.9-0.2,11.8-0.4c3.2,0,6.3-0.3,9.5-1c5.7-0.6,11.3-1.8,16.9-3.5
								c7.1-2.1,14-4.9,20.7-8.2c6.5-3.3,12.6-7.2,18.3-11.7c5.7-4.4,11.1-9.3,15.9-14.7c2.3-2,2.8-4.4,1.7-7.2
								c-2.6-3.7-6.3-5.8-10.9-6.4c-7.4-2.3-15-4-22.7-5.2c-4.5-1.5-8.7-1.1-12.6,1c-5.6,5.2-11.2,10.1-17.6,14.3
								c-6.2,4.1-13,8-20,10.8c-7.1,2.8-14.4,5.2-21.9,6.4c-2.4,0.4-4.9,0.7-7.3,1.1c-5.5,0.4-10.9,1.6-16.1,3.5
								c1.2-0.2,2.4-0.3,3.7-0.5c-5.2,0.1-10.4,0.2-15.7,0.3c1.9,0.8,3.9,1.7,5.8,2.5c-7.4-5.4-15.7-9.2-24.7-11.4
								c0.5,5.9,1,11.7,1.4,17.6c4.7,1.4,9.6,1.8,14.4,1.2c6.4,0.9,12.6,0.3,18.6-1.9c-2.1-0.4-4.2-0.9-6.3-1.3
								c11.3,4.8,23.4,7.8,35.2,11.2c1.5,0.2,3,0.5,4.5,0.7c1.1-0.6,2.3-1.2,3.4-1.8c0.5-1.2,1.1-2.4,1.6-3.5c0-3-1.1-5.6-3.3-7.6
								c-4.1-5.5-9.1-10.2-14.2-14.7c-2.2-2.3-4.7-4.2-7.4-5.9c-2.1-2.1-4.7-3.1-7.7-2.9c-12.1-1.5-24.3-2.5-36.5-3
								c-12.1-0.5-24.2-0.5-36.4-0.1c-20.4,0.8-40.8,3-60.9,6.5c-17.5,3.1-34.9,6.9-52.5,9.3c-10,1.3-20.1,2.3-30.2,2.8
								c-10.3,0.1-20.6,1.3-30.6,3.8c1.2-0.2,2.4-0.3,3.7-0.5c-7.8-0.6-15.6-1.2-23.4-1.8c0.5,0.1,1.1,0.2,1.6,0.2
								c-13.3-4.3-26.5-8.9-39.5-14c1.3,0.6,2.7,1.2,4,1.8c-8.7-4.3-17.4-8.6-26.1-12.9c2.2,7.2,4.4,14.3,6.5,21.5
								c28.3-3.9,56.6-7.7,84.9-11.4c12.6-1.6,25.3-3.2,38-4.8c15.8-1.9,31.5-4,47.3-5.4c16.7-1.5,33.5-2.6,50.2-3.7
								c10.8-0.8,21.7-1.5,32.5-2.5c5-0.2,10-0.9,14.9-2.1c7.5-1.1,14.8-3.3,21.7-6.6c-0.9,0-1.8-0.1-2.7-0.1
								c3.4,0.2,6.8,0.3,10.1,0.5c-1-0.2-2-0.3-2.9-0.5c13.9,2.4,27.9,4.5,41.8,6.5c1.5,0.2,3.1,0.4,4.6,0.6
								c1.1-0.6,2.3-1.3,3.4-1.9c0.5-1.2,1-2.4,1.5-3.6c-0.2-3.1-1.5-5.7-3.8-7.7c-4.6-5.5-10-10.2-15.5-14.7
								c-2.4-2.3-5-4.2-7.9-5.9c-2.3-2.1-5-3.1-8.1-2.8c-17.9-0.8-35.8-1.5-53.7-1.3c-14.9,0.2-29.8,0.8-44.7,1.9
								c-14.8,1.1-29.6,2.8-44.2,5c-8.7,1.3-17.4,2.9-26,4.8c-8.5,1.6-16.9,3.5-25.2,5.7c-18.3,5-36.3,11.1-54.7,15.9
								c-11.2,2.9-22.7,5.2-34.1,6.4c-10.3,1-20.7,1.5-31,1.7c-27.4,0.6-54.8,0.8-82.1,2c-36.4,1.6-72.8,4.4-109.1,8.2
								c-32.9,3.4-65.8,7.4-98.6,11.8c-19.7,2.6-39.3,5.4-58.9,8.3c-18.9,2.8-37.8,5.3-56.7,8.1c-2.9,0.9-4.3,2.9-4.4,5.9
								c1,4.4,3.5,7.9,7.5,10.3c6,5.1,12.3,9.8,18.9,14C240.8,1428.8,244.9,1430.2,249.4,1429.9z"/>
							<path opacity="0.1091" fill="#131313" d="M204.3,1417.6c10.5-0.2,21-1.1,31.4-2.9c9.9-1.4,19.7-2.8,29.6-4.1
								c19.6-2.7,39.2-5.5,58.8-8.1c32.5-4.3,65.1-8.1,97.8-11.3c38.9-3.8,78-6.5,117.1-7.5c15-0.4,30-0.6,45.1-0.4
								c12.4,0.1,24.8,0.2,37.1-0.4c10.7-0.6,21.3-1.7,31.9-3.6c7.9-1.4,16.7-3.8,24.4-6.1c18-5.4,35.6-11.9,53.6-17.2
								c29.4-8.7,60-13.2,90.6-15.1c33.8-2,67.5-0.5,101.2,2.1c-1.6-7.2-3.3-14.3-4.9-21.5c-17.5,0.4-35,1.5-52.5,1.2
								c-2-0.1-4.1-0.1-6.1-0.2c-3.4,0.6-6.7,1.3-10,2.1c-7.8,1.9-15.7,3-23.7,3.2c-4.6,0.2-9.2,0.3-13.8,0.5
								c-13.4,0.4-26.9,0.2-40.3,0.4c-13.4,0.2-26.8,0.3-40.1,1.3c-16.3,1.1-32.6,2.5-48.8,4.1c-38.3,3.7-76.6,8.5-114.7,13.3
								c-3.1,0.8-4.5,2.8-4.2,6c1,3.5,3,6.2,6,8.2c4.6,4.4,9.5,8.3,14.9,11.7c6.4,4.8,13.2,8.9,20.4,12.4c8.4,2,17,2.9,25.6,3
								c8.5,0.7,16.9,0.6,25.4-0.6c-0.9-0.1-1.8-0.3-2.7-0.4c13.2,5.2,26.9,8.7,41,10.4c13.2,1.7,26.4,2.4,39.6,2
								c21.1-0.5,42-3.7,62.9-6.4c21.3-2.7,42.6-4.4,64-4.3c11.8,0.1,23.6,0.6,35.4,1.7c11.5,1,23,2.5,34.4,4.5
								c-1.3-7.2-2.6-14.3-3.9-21.5c-3.1,0.3-6.2,0.7-9.2,1c1-0.1,1.9-0.1,2.9-0.2c-8.3-0.8-16.5-2.1-24.7-3.9
								c1.9,0.9,3.8,1.9,5.7,2.8c-2.1-3.9-5-5.8-8.6-5.7c-4.5-0.3-9,0.1-13.4,1.2c3.5,0.1,7.1,0.2,10.6,0.2
								c-5.2-0.5-10.4-1.1-15.6-1.6c2.3,0.4,4.6,0.9,6.9,1.3c-6-1.2-12-2.4-18.1-3.7c-1.2,0.4-2.4,0.8-3.6,1.2
								c-1.7,1.2-2.6,2.9-2.7,4.9c-0.6,1.3-0.4,2.5,0.4,3.6c0.8,2.6,2.6,4.4,5.5,5.2c5.7,4.2,12,7,18.8,8.5
								c3.9,0.7,7.8,0.5,11.7-0.5c-0.7,0.1-1.4,0.1-2.1,0.2c1.1,0,2.2,0.1,3.3,0.1c-0.9-0.2-1.8-0.3-2.7-0.5
								c8.2,4.7,17,7.8,26.3,9.3c8.5,1.4,17,1.8,25.5,1.1c11.6-1.1,22.9-3.6,33.8-8.1c6.9-2.8,13.3-6.3,19.6-10.3
								c4-2.5,7.8-5.2,11.4-8.2c5.6-4.5,10.8-9.5,15.6-15c1.3-2.2,1.9-4.6,1.7-7.2c-0.3-2.6-1.8-4.2-4.4-5
								c-3.3-1.3-6.7-2.1-10.2-2.4c-2.6-0.5-4.7,0.3-6.2,2.5c-2.9,3.5-6.2,6.8-9.7,9.8c-3.4,3.3-7.1,6.2-11.1,8.8
								c0.9-0.6,1.7-1.1,2.6-1.7c-3.6,2.2-7.2,4.5-10.8,6.7c0.9-0.5,1.8-1,2.7-1.5c-5.7,3.3-11.6,6-17.9,8.1
								c-6.4,2.2-13,3.6-19.7,4.4c-7.1,0.8-14.2,0.8-21.2-0.1c-6.6-1.4-13.3-2.6-20-3.6c0.9,0.2,1.8,0.3,2.7,0.5
								c-5.9-1.6-11.9-2.7-18.1-3.2c1.5,0.4,3.1,0.8,4.6,1.2c-4.1-1.6-8.2-3.2-12.2-4.8c3.6,5.8,7.1,11.6,10.7,17.5
								c1.6-1.1,3.2-2.2,4.8-3.3c-2.6,0.6-5.3,1.3-7.9,1.9c6.1-0.5,12-0.2,17.9,0.9c-0.9-0.3-1.8-0.5-2.7-0.8
								c10,4.5,20.4,7.6,31.2,9.5c2.4,0,4.5-0.9,6.1-2.8c1.8-1.7,2.7-3.7,2.7-6.1c-1.1-2.9-2.8-5.4-5.2-7.5c-2.1-2.3-4.6-4-7.5-5.1
								c-11.7-1.9-23.5-3.2-35.3-4.1c-12-0.9-24-1.3-36-1.2c-21.3,0.1-42.5,2-63.6,5c-19.7,2.8-39.3,6.2-59.2,7.3
								c-12,0.7-24,0.5-35.9-0.5c-11.6-1.6-23.3-2.8-35-3.5c0.9,0.1,1.8,0.3,2.7,0.4c-12.6-2.6-25.3-4.6-38.1-6
								c1.6,0.4,3.2,0.8,4.7,1.1c-10.2-3.3-20.4-6.6-30.7-9.9c1.9,0.6,3.9,1.3,5.8,1.9c-10.9-5.5-21.8-11-32.8-16.5
								c12.4,12.8,24.7,25.5,37.1,38.3c53.1-7.7,106.2-15.6,159.3-23.5c16.1-2.4,32.2-5.3,48.1-9.1c7.3-1.7,14.5-3.5,21.7-5.9
								c3.9-1,7.7-2.5,11.3-4.5c4.5-2,8.9-4.2,13.2-6.5c-1.1,0.5-2.1,1-3.2,1.4c6.1-2.8,12.4-3.5,18.7-2c14.4,3.2,29,5.4,43.7,6.7
								c4.3,0.5,9.1-5.1,8.5-9.2c-1.3-2.9-3.1-5.4-5.6-7.4c-2.2-2.3-4.8-3.9-7.8-4.9c-23.1-1.5-46.2-2.7-69.3-2.5
								c-23.1,0.2-46.3,1.6-69.2,4.7c-21.2,2.9-42.2,7.3-62.7,13.6c-7.6,2.4-15.2,4.9-22.8,7.4c-8.6,2.9-17.3,5.7-26,8.2
								c1-0.3,2-0.6,3-0.9c-4.9,1.3-9.8,2.7-14.7,4c1-0.3,2-0.5,3-0.8c-8,2-16.1,3.5-24.2,4.5c-6.2,0.8-12.5,1.3-18.8,1.5
								c-12.5,0.6-25,0.6-37.5,0.5c-41.5-0.3-82.9,1.7-124.3,4.9c-39.4,3.1-78.6,7.4-117.8,12.3c-19.6,2.5-39.2,5.2-58.8,7.9
								c-13.1,1.9-26.2,3.7-39.3,5.5c-13.3,2.5-26.8,4.1-40.3,4.9c-4.1,0.4-8.5,5.9-7.5,10.1c1.4,2.6,3.3,4.8,5.8,6.4
								C198.8,1415.7,201.4,1417,204.3,1417.6z"/>
							<path opacity="0.1091" fill="#131313" d="M220.8,1414.6c7.1-0.3,14.3-0.6,21.4-0.9c6.4-1,12.7-2,19.1-3
								c13-2,26.1-3.8,39.2-5.7c26.1-3.7,52.2-7.2,78.3-10.3c55.2-6.6,110.5-11.7,166.1-13.6c22.5-0.8,44.9-0.9,67.4-0.8
								c15.3,0,30.4-0.5,45.6-2.2c15.3-1.7,30.2-5.7,44.8-10.3c17.8-5.6,35.3-11.9,53.1-17.3c-1.1,0.3-2.3,0.6-3.4,0.9
								c8.4-2.3,16.9-4.3,25.4-5.8c8.4-1.8,16.9-3.2,25.5-4.3c14.1-1.8,28.2-3,42.4-3.7c31.1-1.4,62.1,0,93.1,2.3
								c-4.3-8.2-8.6-16.4-12.9-24.6c-7.7,1.5-15.5,2.9-23.2,4.2c-7.6,0.8-15.3,1.4-23,1.8c-2.2,0-4.4,0.2-6.6,0.6
								c-10,2.3-20.2,3.6-30.5,4c1.4-0.1,2.7-0.1,4.1-0.2c-19.1,0.5-38.3,0.6-57.4,1c-8.3,0.2-16.7,0.4-25,0.8
								c-10.4,0.8-20.7,1.7-31.1,2.6c-48.6,4.3-97,10.4-145.3,16.7c-2.8,1.1-4.2,3.1-4.2,6c0.4,3,1.8,5.3,4.3,7
								c3.4,3.6,7.3,6.7,11.5,9.4c5,3.8,10.4,7,16.2,9.4c9.5,2.5,19.2,4.5,28.9,6.1c9.7,1.2,19.4,1.8,29.1,1.5
								c-0.9-0.1-1.8-0.2-2.7-0.3c13.2,4.7,26.8,7.8,40.7,9.4c13,1.5,26,2.1,39.1,1.6c20.8-0.7,41.4-4,62.1-6.6
								c21.1-2.7,42.2-4.4,63.5-4.3c11.8,0,23.6,0.6,35.4,1.6c11.6,1,23.1,2.5,34.5,4.5c-3.8-8.2-7.5-16.4-11.3-24.6
								c-2.3,1.3-4.6,2.6-6.8,3.9c2.1-0.5,4.2-1.1,6.4-1.6c-7.6,0.3-15.1,0.1-22.6-0.6c1.9,0.9,3.9,1.7,5.8,2.6
								c-2.4-2.9-5.2-4.5-8.5-4.7c-4.3-0.6-8.6-0.4-13,0.6c1.5-0.1,3-0.2,4.4-0.2c-3.4-0.4-6.7-0.7-10.1-1.1
								c1.9,0.4,3.7,0.8,5.6,1.1c-3.8-0.9-7.5-1.8-11.3-2.6c-2.8-1.4-5.5-1.3-8,0.5c-1.6,1.3-2.5,2.9-2.7,4.8
								c-0.7,1.7-0.6,3.4,0.5,5.1c2.8,2.8,6,4.9,9.6,6.4c3.8,2.1,7.7,3.6,11.9,4.4c4,0.2,8,0.2,12.1-0.1c-0.8-0.4-1.6-0.8-2.4-1.1
								c23.1,13.8,53.2,14.5,78.3,6.1c7.5-2.5,14.8-5.3,21.8-9.1c6.3-3.4,12.3-7.4,17.9-11.9c5.6-4.5,10.8-9.4,15.5-14.8
								c1.6-2.2,2.1-4.6,1.6-7.2c-1.1-3-3.3-4.8-6.6-5.4c-4.6-1.6-9.4-2.7-14.3-3.2c-3.2-0.9-6-0.2-8.2,2.1
								c-2.7,3.3-5.7,6.3-8.9,9.1c-3.1,3-6.4,5.7-10,8.1c-2.4,1.6-4.9,3.1-7.3,4.7c-5.1,3-10.3,5.6-15.9,7.6c1-0.3,2.1-0.7,3.1-1
								c-3.9,1.3-7.8,2.5-11.7,3.8c1-0.3,2-0.6,3.1-0.9c-4.5,1.3-9.1,2.2-13.8,2.6c-4.8,0.7-9.6,0.9-14.5,0.5
								c-3.1-0.3-6.3-0.7-9.4-1c-7.8-0.3-15.6-1.1-23.3-2.4c0.8,0.4,1.6,0.8,2.4,1.1c-6.3-4.3-13.2-7.2-20.8-8.8
								c3.5,6.7,7,13.5,10.5,20.2c3-0.7,6-1.3,9-2c-1.5,0.1-3,0.2-4.4,0.2c3.9,0.1,7.8,0.2,11.7,0.4c2.6-0.2,5.1,0.1,7.6,0.8
								c-0.9-0.3-1.8-0.5-2.7-0.8c9.5,4,19.4,6.9,29.6,8.5c4.9,1.1,9.6-4.4,8.7-9.1c-1.8-3.5-4.1-6.5-7.1-9.1
								c-2.7-2.9-5.8-5.1-9.4-6.7c-11.9-1.8-23.9-3.1-35.9-4c-12.1-0.8-24.1-1.2-36.2-1c-20.9,0.3-41.7,2.2-62.3,5.4
								c-18.9,2.9-37.7,6.6-56.8,8.2c-11.4,1-22.8,1.2-34.3,0.7c-11.3-1.1-22.7-1.6-34.1-1.6c0.9,0.1,1.8,0.2,2.7,0.3
								c-7.9-1.2-15.8-2.4-23.7-3.6c0.6,0.1,1.2,0.3,1.7,0.4c-8.9-1.5-17.6-3.8-26.1-7.1c-14.5-4.2-28.2-10.3-41.1-18.1
								c9.3,10.6,18.6,21.2,27.8,31.8c50.1-7.1,100.1-14.5,150.2-21.5c16.7-2.3,33.5-4.5,50.2-7.2c8.2-1.3,16.4-2.7,24.5-4.3
								c8.4-1.6,16.7-3.8,24.9-6.6c-1.1,0.3-2.1,0.6-3.2,0.9c5.8-1.8,11.7-3.4,17.6-4.8c5-2.1,10.1-2.4,15.4-1.1
								c-0.9-0.1-1.9-0.1-2.8-0.2c13.8,2.7,27.7,4.7,41.7,5.9c5,0.6,9.6-4.4,8.3-9.4c-2-3.5-4.6-6.5-7.7-9.1c-2.9-2.8-6.2-5-9.9-6.6
								c-29.3-1.9-58.6-3-87.9-1.8c-14.6,0.6-29.2,1.8-43.8,3.6c-14.5,1.8-28.9,4-43.2,7.3c-16.3,3.8-32.3,8.6-48.2,13.8
								c-9.3,3.1-18.6,6.2-28,8.8c-3.8,1-7.5,2-11.3,3c-7.8,1.9-15.8,3.3-23.8,4.2c1.2-0.1,2.3-0.3,3.5-0.4
								c-8.3,1-16.6,1.5-24.9,1.8c-4.2,0.1-8.3,0.1-12.5,0.2c-15.1,0.2-30.3,0-45.4,0.3c-39.6,0.7-79.1,3-118.6,6.6
								c-39.4,3.6-78.8,8.1-118.1,13.4c-16.4,2.2-32.7,4.5-49,6.8c-9.8,1.4-19.6,2.8-29.4,4.3c-12.9,2.7-25.9,4.1-39.1,4.2
								c-2.6-0.1-4.7,1-6.2,3.2c-1.6,2.1-2.1,4.4-1.2,6.9c2.2,3.3,5,6,8.4,8.3C213.5,1411.5,217,1413.4,220.8,1414.6z"/>
							<path opacity="0.1091" fill="#131313" d="M248,1445.6c35.6-5.5,71.3-10.6,107-15.4c35.8-4.8,71.6-9.2,107.6-12.6
								c32.5-3.1,65-5.4,97.6-6.8c19.4-0.8,39-1.2,58.4-1.1c10.3,0.1,20.6,0.2,30.9,0c10.5-0.2,21.1-0.6,31.5-2
								c10.5-1.5,20.9-3.7,31.1-6.6c10.2-2.9,20.1-6.2,30.1-9.6c11.7-3.9,23.4-8.1,35.3-11.3c27.8-7.4,56.1-11.4,84.8-13.2
								c33.7-2.1,67.6-0.3,101.2,2.3c-13.2-13.3-26.4-26.6-39.7-39.9c-2.7,3.3-5.8,6.2-9.3,8.6c-3.8,3.3-7.8,6.1-12.3,8.4
								c-3.2,1.4-6.4,2.7-9.6,4.1c-5,0.9-10,1.9-15,2.8c-7.1,0.4-14.2,0.6-21.3,0.6c-8,0.1-16,0.1-24-0.1c-10.8-0.1-21.7-0.2-32.5,0
								c-4.2,0-8.3,0.1-12.5,0.3c-8.5,0.2-16.9,0.8-25.4,1.7c-16,1.4-32,3.1-47.9,4.9c-34.8,3.9-69.6,8.4-104.3,13
								c-2.5,0.3-4.4,1.5-5.7,3.6c-1.3,1.5-1.9,3.2-1.6,5c-0.3,2,0.6,3.7,2.6,4.9c2.8,2.7,5.9,4.9,9.3,6.6c6.6,3.1,13.3,6.1,20,9
								c7.3,3.2,14.8,5.9,22.4,8.2c8.1,1,16.2,2,24.3,3c4.3,0.6,8.5,0.9,12.8,0.9c-0.9-0.2-1.8-0.3-2.7-0.5
								c12.9,3.4,25.9,5.9,39.2,7.3c7.7,0.6,15.4,1.2,23,1.8c24.6,1.5,49.3-1.9,73.7-4.6c24.3-2.7,48.6-4.8,73.1-4.4
								c11.9,0.2,23.8,0.8,35.7,2c11.6,1.1,23.2,2.7,34.6,4.8c-12.2-13.3-24.4-26.7-36.6-40c-2.9,4.7-5.9,9.4-8.8,14.1
								c0.6-0.6,1.3-1.2,1.9-1.8c-1.3,0.6-2.7,1.1-4,1.7c1-0.4,2.1-0.7,3.1-1.1c-6.1,0.8-12.2,1.1-18.3,0.8c1.3,0.3,2.6,0.5,3.9,0.8
								c-3.5-1-7.1-2-10.6-3.1c-2.3-0.9-4.2-0.4-5.8,1.3c-1.7,1.9-2.8,4.1-3.4,6.6c-0.5,2.3,0.3,4.2,2.3,5.5c2.3,1.8,4.9,3.1,7.8,4
								c2.9,1.2,5.9,2.2,9,2.8c5.5,1.3,11.2,1.6,16.9,0.9c-0.9-0.3-1.7-0.5-2.6-0.8c5.1,2.9,10.6,4.9,16.5,5.9
								c8,2,16.2,3.2,24.5,3.5c9.2,0.5,18.4,0.1,27.5-1.6c5.7-0.9,11.2-2.4,16.5-4.4c5.4-1.6,10.5-3.7,15.5-6.3
								c6.5-3.3,12.6-7.2,18.3-11.7c5.7-4.4,10.9-9.4,15.8-14.7c2.3-2,2.9-4.4,1.7-7.2c-2.7-3.7-6.4-5.9-11.2-6.5
								c-7.6-2.3-15.4-4.1-23.2-5.4c-4.5-1.5-8.9-1.2-12.9,0.9c-3.9,3.4-8.1,6.5-12.5,9.3c-2.2,1.3-4.4,2.7-6.6,4
								c-7.7,4.5-15.9,8-24.3,10.8c-6.9,2.2-14,3.7-21.2,4.8c-3,0.4-6,1-8.9,1.9c-4,1.3-8,3-11.7,5c2.4-0.5,4.8-1,7.2-1.6
								c-5.1,0.5-10.2,1-15.3,1.5c1.9,0.8,3.9,1.7,5.8,2.5c-8-5-16.6-8.6-25.7-11c0.3,5.8,0.6,11.6,0.9,17.3c3,0.5,6,1.1,9,1.6
								c1.6-0.2,3.3-0.4,4.9-0.6c2.1-0.3,4.3-0.5,6.4-0.4c4.6,0.2,9-0.7,13.2-2.7c-3.3-0.7-6.5-1.3-9.8-2c3.5,2,7.3,3.6,11.1,4.8
								c9.6,3.2,19.2,6.3,28.8,9.3c1.6,0.4,3.3,0.8,4.9,1.1c1.2-0.6,2.4-1.1,3.6-1.7c0.5-1.2,1-2.4,1.5-3.7
								c-0.3-3.4-1.7-6.2-4.2-8.5c-5-6.3-10.8-11.8-16.7-17.1c-2.6-2.6-5.4-4.9-8.5-7c-2.4-2.4-5.2-3.7-8.6-3.8
								c-12.1-1.3-24.1-2.1-36.3-2.4c-12-0.3-24.1-0.2-36.1,0.4c-20.3,1-40.5,3.3-60.6,6.8c-17.3,3-34.5,6.5-52,8.8
								c-9.7,1.2-19.4,2.2-29.1,2.9c-9.7,0.4-19.2,2.3-28.4,5.7c1.2-0.1,2.5-0.2,3.7-0.4c-7.7-0.2-15.5-0.4-23.2-0.6
								c0.6,0.1,1.1,0.1,1.7,0.2c-13.8-4.1-27.4-8.8-40.7-14.3c1.2,0.5,2.5,1.1,3.7,1.6c-8.8-4.4-17.7-8.8-26.5-13.2
								c1.5,6.7,3.1,13.5,4.6,20.2c28.4-3.8,56.8-7.6,85.3-11.2c12.7-1.6,25.4-3.1,38.1-4.6c15.9-1.8,31.8-3.9,47.7-5
								c17.1-1.3,34.3-1.9,51.4-2.8c11.3-0.5,22.6-0.9,33.9-1.9c5.3-0.1,10.4-0.9,15.4-2.4c7.4-1.3,14.4-3.8,21-7.5
								c-0.9,0.1-1.8,0.1-2.8,0.2c6.1,0.3,12,1,18,2.3c12.4,2.1,24.7,4.4,37.1,7c3.1-0.5,4.8-2.4,4.9-5.5c-1-5-3.6-9-7.9-12.1
								c-6.3-6.3-13-12.2-20.1-17.7c-3.6-3.8-7.9-6-13-6.3c-17.8-0.6-35.6-1-53.5-0.6c-14.8,0.4-29.7,1.2-44.4,2.5
								c-14.7,1.3-29.4,3.1-44,5.4c-8.7,1.4-17.3,3-25.9,4.9c-8.5,1.7-16.9,3.6-25.2,5.7c-18.5,4.8-36.7,10.5-55.3,14.9
								c-21.5,5-43.4,6.8-65.5,7.3c-27.3,0.7-54.7,1.2-82,2.6c-36.4,1.8-72.7,4.7-109,8.6c-32.9,3.5-65.7,7.6-98.5,12
								c-19.6,2.6-39.3,5.4-58.9,8.3c-18.8,2.7-37.7,5.2-56.5,8.1c-3.1,0.8-4.5,2.8-4.4,5.9c1.4,4.8,4.3,8.6,8.8,11.3
								c6.8,5.7,13.9,11,21.4,15.8C238.5,1444,242.9,1445.7,248,1445.6z"/>
							<path opacity="0.1091" fill="#131313" d="M196.9,1441.1c10.6,0.6,21.1,0,31.5-2.1c9.7-1.5,19.4-3,29.1-4.3
								c19.6-2.8,39.1-5.6,58.7-8.2c32.5-4.3,65.1-8.2,97.7-11.4c38.9-3.8,77.9-6.6,116.9-7.7c15-0.4,30.1-0.6,45.1-0.6
								c12.4,0,24.8,0.1,37.2-0.5c16-0.8,32-2.7,47.5-6.7c21.3-5.5,41.9-13.3,62.9-19.5c29.3-8.6,59.8-13.3,90.3-15.3
								c33.6-2.2,67.2-0.8,100.8,1.5c-2.7-8-5.5-16.1-8.2-24.1c-7,0.6-14.1,1.1-21.2,1.5c-8.9,0.5-17.9,1-26.8,1.4
								c-2,0.2-4,0.4-5.9,0.7c-3.4,0.7-6.9,1.5-10.3,2.4c-7.9,2.1-15.9,3.3-24.1,3.4c-4.6,0.1-9.3,0.2-13.9,0.4
								c-13.4,0.3-26.7,0.2-40.1,0.4c-4.2,0.1-8.5,0.2-12.7,0.3c-8.1,0.1-16.3,0.5-24.4,1.3c-16.9,1.3-33.8,2.8-50.6,4.5
								c-38.2,3.9-76.3,8.7-114.3,13.7c-2.8,1-4.2,3-4.2,6c0.5,3.1,2.1,5.5,4.6,7.2c3.6,3.7,7.7,7,12.1,9.8c5.3,4,10.9,7.3,17,9.9
								c9.8,2.8,19.7,4.9,29.8,6.4c9.2,1.4,18.5,1.6,27.7,0.6c-0.9-0.2-1.8-0.3-2.7-0.5c13.1,4.8,26.6,8.1,40.5,9.9
								c13.1,1.7,26.2,2.3,39.4,2c21-0.5,41.9-3.6,62.7-6.2c21.1-2.6,42.2-4.3,63.5-4.3c11.8,0,23.5,0.5,35.2,1.5
								c11.5,1,22.9,2.4,34.3,4.3c-3.5-8-7-16-10.6-24c-1.5,0.2-3,0.4-4.5,0.6c1,0,2,0,3,0c-7.4-1.4-14.8-2.2-22.3-2.3
								c2,0.7,4,1.4,6.1,2.2c-2.1-3.4-4.8-4.9-8-4.5c-4.8,0.1-9.4,0.7-14.1,2c1.7-0.2,3.5-0.3,5.2-0.5c-3.4-0.1-6.8-0.3-10.2-0.4
								c2.2,0.4,4.3,0.8,6.5,1.2c-5.9-1.1-11.8-2.2-17.7-3.3c-2.1,0.2-3.6,1.1-4.5,2.8c-1.4,1.4-2,3.1-1.9,5.2
								c0.4,1.1,0.8,2.3,1.2,3.4c3.2,2.9,6.8,5.1,10.9,6.7c2.5,1.3,5,2.5,7.5,3.8c2.1,0.8,4.3,1.2,6.5,1.2c4.1,0.2,8.2,0,12.3,0.1
								c-0.9-0.2-1.8-0.4-2.7-0.7c8.1,4.4,16.7,7.4,25.7,9c8.4,1.4,16.8,1.8,25.3,1.2c11.6-1,23-3.4,33.9-7.6
								c7-2.7,13.6-6.1,20-10.1c4.1-2.5,8.1-5.3,11.9-8.4c5.9-4.6,11.2-9.7,16.1-15.4c1.3-2.2,2-4.6,1.9-7.1c-0.2-2.5-1.5-4.2-3.9-5
								c-3-1.4-6.2-2.2-9.4-2.5c-2.5-0.5-4.5,0.3-5.9,2.4c-2.4,2.3-4.7,4.6-7.1,6.8c0.8-0.6,1.6-1.3,2.4-1.9
								c-3.5,2.8-7,5.6-10.5,8.4c0.8-0.6,1.7-1.2,2.5-1.8c-3.8,2.5-7.6,5.1-11.4,7.6c0.9-0.6,1.8-1.1,2.6-1.7
								c-4.3,2.7-8.8,5-13.5,6.9c-4.5,2.1-9.1,3.9-13.9,5.2c-8.2,2.2-16.5,3.6-25,3.9c-3.5,0.3-6.9,0.2-10.4-0.2
								c-4.9-0.2-9.8-0.9-14.6-2.2c-8.7-1.1-17.4-1.6-26.1-2.7c1.4,0.4,2.9,0.7,4.3,1.1c-4-1.6-8-3.2-12-4.7
								c1.9,6,3.8,12.1,5.8,18.1c4.6-0.8,9-2.5,13-4.9c-2.4,0.5-4.9,1-7.3,1.5c4.3,0.4,8.6,0.8,12.8,1.2c-0.9-0.2-1.8-0.3-2.8-0.5
								c3.4,1.1,6.7,2.3,10,3.6c7.3,3,14.8,5.1,22.7,6.3c4.8,1.1,9.5-4.5,8.7-9c-1.7-3.4-3.9-6.3-6.8-8.8c-2.6-2.8-5.6-4.9-9.1-6.4
								c-11.6-1.3-23.2-2.6-34.9-3.3c-11.8-0.7-23.7-0.8-35.5-0.6c-12,0.3-24.1,1-36.1,2.1c-14.3,1.4-28.6,3.5-42.8,5.5
								c-11.2,1.6-22.4,3-33.7,3.9c-5.8,0.6-11.5,0.9-17.3,0.9c-5.9,0.3-11.7,0.3-17.6-0.2c-5.8-0.1-11.6-0.5-17.3-1.3
								c-5.5-0.8-11.1-1.2-16.7-1.1c-6,0.1-12-0.4-18-1.5c-9.2-1.2-18.3-2.6-27.4-4.2c1.4,0.3,2.9,0.6,4.3,0.9
								c-19.3-5.2-37.7-12.9-55-23.1c9.8,11,19.7,21.9,29.5,32.9c53.4-7.5,106.8-15.3,160.3-22.3c16-2.1,32.1-4.1,48.1-6.7
								c8.5-1.4,17-3,25.4-5.1c4.3-0.8,8.5-2.2,12.5-4.1c5.2-1.8,10.1-4.1,14.8-6.9c-1.1,0.5-2.2,1-3.3,1.6c4.1-1,8.1-1.9,12.2-2.9
								c-0.9-0.1-1.8-0.1-2.7-0.2c6.4,0.7,12.7,1.7,19,3.2c10.6,2.3,21.3,3.9,32.1,4.6c4.9,0.6,9.4-4.6,8.3-9.4
								c-1.8-3.3-4.2-6.2-7.2-8.6c-2.7-2.7-5.8-4.7-9.4-6.1c-22.9-1.1-45.7-1.9-68.5-1.3c-20.1,0.5-40,2-59.9,4.6
								c-20,2.6-39.9,6.3-59.4,11.6c-16.7,4.5-33.1,9.9-49.7,14.7c1.1-0.3,2.1-0.6,3.2-0.9c-5,1.3-10,2.6-15,4
								c1.1-0.3,2.2-0.5,3.2-0.8c-5.1,1.1-10.1,2.3-15.2,3.4c1.1-0.2,2.2-0.4,3.3-0.7c-5.1,0.9-10.2,1.9-15.2,2.8
								c1.1-0.2,2.2-0.4,3.3-0.6c-8.4,1.3-16.8,2.2-25.2,2.7c-4.2,0.2-8.3,0.4-12.5,0.6c-12.6,0.5-25.2,0.4-37.7,0.5
								c-42.6,0.6-85,2.8-127.4,6.6c-39.3,3.4-78.5,8-117.6,13.2c-19.6,2.6-39.2,5.3-58.8,8.1c-9.8,1.4-19.6,2.8-29.4,4.3
								c-13,2.8-26.1,4.2-39.4,4c-4.5,0.2-8.8,5.5-7.5,10c1.8,2.9,4.1,5.4,7,7.3C190.6,1438.7,193.6,1440.3,196.9,1441.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M785.8,1247.5c-7.3,2.5-14.1,5.8-20.5,10c-5.7,3.6-11.7,6.8-17.8,9.7
								c-4.1,1.9-8.2,3.8-12.2,5.7c-8.2,3.6-16.5,6.8-25,9.6c-4.6,1.7-6.5,8.2-3.4,12.1c3.1,2.2,6.6,3.8,10.3,4.6
								c3.7,1.2,7.4,1.7,11.2,1.4c13.3-4.9,26.6-10,39.3-16.4c4.2-2.2,8.4-4.4,12.6-6.6c8-5.4,16.4-9.9,25.4-13.5
								c4.5-2.1,5.8-8.7,2.3-12.3c-3.3-2-6.9-3.2-10.8-3.7C793.5,1247.1,789.7,1246.9,785.8,1247.5z"/>
							<path opacity="0.1091" fill="#131313" d="M758,1232.2c-3.8,2.4-7.5,4.9-11.3,7.3c-5.8,3.5-11.7,6.8-17.8,9.9
								c-3.9,2.2-8,4.1-12.1,5.9c-6,2.9-12.1,5.5-18.4,7.9c1.4-0.5,2.9-1,4.3-1.6c-5.6,2-11.2,4.1-16.8,6.1c-2.3,1.9-2.9,4.3-2,7.1
								c2.3,3.7,5.6,6,10.1,6.8c7,2.5,14.2,4.6,21.5,6c4.3,1.6,8.3,1.4,12.2-0.5c13.3-5.5,26.5-11.2,39.2-18
								c4.2-2.3,8.3-4.6,12.5-6.9c7.8-4.3,15.3-9,22.6-14.1c2.1-2.1,2.6-4.5,1.4-7.2c-2.6-3.5-6.3-5.5-10.9-6
								c-7.3-2-14.8-3.4-22.3-4.3C765.8,1229.3,761.7,1229.8,758,1232.2z"/>
							<path opacity="0.1091" fill="#131313" d="M759.9,1197.9c-5.5,3.9-11.3,7.5-17.2,10.7c-5.9,3.4-11.9,6.6-18,9.6
								c-4.1,1.9-8.2,3.7-12.3,5.6c-8.2,3.5-16.5,6.7-25,9.4c-2.4,1.9-3.1,4.2-2,7.1c2.7,3.9,6.4,6.3,11.2,7.2
								c7.7,2.8,15.6,5,23.6,6.7c4.6,1.8,9,1.7,13.3-0.2c12.7-5.8,25.4-11.7,37.6-18.5c4-2.3,8.1-4.7,12.1-7
								c7.7-4.3,15.1-9,22.2-14.1c2.2-2.1,2.7-4.5,1.4-7.2c-2.8-3.6-6.5-5.6-11.2-6.1c-7.6-2-15.3-3.5-23-4.5
								C768.1,1195.1,763.8,1195.6,759.9,1197.9z"/>
							<path opacity="0.1091" fill="#131313" d="M783,1222.8c-11.6,7-23.2,14.3-35.4,20.3c-4.1,1.9-8.1,3.8-12.2,5.7
								c-8.1,3.6-16.4,6.8-24.8,9.7c-2.7,0.7-4.4,2.4-5.1,5.1c-0.8,2.7-0.2,5,1.7,7c3.8,2.5,8,4.2,12.5,5.3
								c4.3,1.4,8.8,2.1,13.4,2.1c13-5.2,26-10.5,38.4-17c12.1-6.3,23.6-13.5,35.2-20.7c2.6-0.9,4.1-2.8,4.6-5.5
								c0.6-2.7-0.2-5-2.3-6.8c-4-2.1-8.2-3.5-12.7-4.1C791.9,1222.6,787.5,1222.3,783,1222.8z"/>
							<path opacity="0.1091" fill="#131313" d="M779.8,1195.8c-3.7,2.4-7.5,4.9-11.2,7.3c1.1-0.7,2.2-1.3,3.3-2
								c-3.9,2.3-7.8,4.5-11.7,6.8c-5.9,3.3-11.9,6.4-18.1,9.2c-10.1,4.7-20.3,8.7-30.8,12.6c-2.9,0.5-4.7,2.2-5.5,4.9
								c-0.8,2.8,0,5.2,2.1,7.1c4.4,2.6,9,4.5,14,5.7c4.8,1.6,9.8,2.4,14.9,2.5c16.9-7.2,33.5-14.9,49.3-24.3
								c8.7-5.2,17.1-10.6,25.6-16.1c1.7-2.2,2.2-4.6,1.4-7.2c-1.5-3.1-4.1-4.9-7.7-5.4c-5.3-1.6-10.8-2.7-16.3-3.2
								C785.4,1192.7,782.4,1193.4,779.8,1195.8z"/>
							<path opacity="0.1091" fill="#131313" d="M739.3,1219.1c-5.5,4-11.2,7.5-17.2,10.8c-5.8,3.4-11.8,6.6-17.9,9.5
								c-4,1.9-8.1,3.7-12.1,5.6c-8.1,3.5-16.3,6.6-24.7,9.4c-2.8,1.8-3.5,4.1-2,7.1c3.6,4.2,8.2,6.8,13.8,7.8
								c9.3,3.2,18.8,5.9,28.5,8c5.3,2.1,10.6,2.2,15.8,0.5c13.2-5.8,26.3-11.8,38.9-18.8c4.1-2.4,8.3-4.7,12.4-7.1
								c7.8-4.2,15.2-8.9,22.4-14.1c2.6-2,3.1-4.4,1.4-7.2c-3.9-3.9-8.7-6.1-14.4-6.7c-9.6-2.4-19.3-4.3-29.1-5.6
								C749.6,1216.6,744.3,1216.9,739.3,1219.1z"/>
							<path opacity="0.1091" fill="#131313" d="M763.9,1251.8c-3.6,2.4-7.3,4.9-10.9,7.3c1.2-0.7,2.3-1.3,3.5-2
								c-5.1,2.9-10.1,5.8-15.2,8.8c1.2-0.7,2.3-1.3,3.5-2c-7.9,4.4-16,8.4-24.2,12c-8.2,3.6-16.5,6.8-24.9,9.7
								c-2.8,0.6-4.6,2.3-5.4,5c-0.8,2.7-0.1,5.1,2,7.1c4.2,2.5,8.6,4.4,13.4,5.5c4.7,1.5,9.4,2.3,14.3,2.3
								c9.1-3.2,18-6.8,26.8-10.7c6.6-2.9,13-6.1,19.3-9.5c3.3-1.7,6.6-3.4,9.8-5.3c7.8-4.2,15.4-8.9,22.7-14.1
								c1.8-2.2,2.2-4.6,1.4-7.2c-1.8-3.2-4.6-5.1-8.4-5.5c-5.8-1.7-11.6-2.8-17.6-3.3C770,1248.8,766.8,1249.5,763.9,1251.8z"/>
							<path opacity="0.1091" fill="#131313" d="M798.1,1219.5c-7.5,2.4-14.5,5.7-21,9.9c-5.7,3.5-11.6,6.7-17.6,9.6
								c-4,1.9-8.1,3.8-12.1,5.7c-8.1,3.6-16.3,6.8-24.7,9.7c-4.4,1.6-6.3,8.3-3.4,12c2.9,2.2,6.1,3.7,9.6,4.5
								c3.4,1.2,7,1.6,10.5,1.3c12.9-5,25.7-10.2,38-16.6c4.1-2.2,8.2-4.4,12.2-6.6c7.9-5.5,16.3-10,25.3-13.5
								c4.1-1.9,5.3-9,2.4-12.3c-2.8-1.9-5.9-3-9.3-3.5C804.7,1218.9,801.4,1218.8,798.1,1219.5z"/>
							<path opacity="0.1091" fill="#131313" d="M750,1208.3c-5.5,4-11.2,7.6-17.1,10.7c-5.9,3.4-11.9,6.6-18,9.5
								c-4.1,1.9-8.2,3.7-12.2,5.6c-8.2,3.5-16.5,6.6-24.9,9.4c-2.7,1.8-3.4,4.2-2,7.1c3.3,4.1,7.6,6.6,12.9,7.6
								c8.8,3.1,17.8,5.6,26.9,7.6c5.1,2,10.1,2.1,15,0.3c13-5.8,25.8-11.8,38.3-18.8c4.1-2.4,8.2-4.7,12.3-7.1
								c7.7-4.2,15.1-8.9,22.2-14.2c2.5-2,2.9-4.4,1.4-7.2c-3.5-3.8-7.9-5.9-13.2-6.4c-8.8-2.3-17.8-4-26.8-5.2
								C759.5,1205.7,754.7,1206,750,1208.3z"/>
							<path opacity="0.1091" fill="#131313" d="M770.6,1207c-5.5,3.9-11.2,7.5-17.1,10.7c-5.9,3.5-11.8,6.7-18,9.6
								c1.3-0.6,2.6-1.2,3.9-1.8c-5.4,2.5-10.7,5-16.1,7.5c1.3-0.6,2.6-1.2,3.9-1.7c-5.4,2.3-10.9,4.5-16.3,6.8
								c1.3-0.5,2.7-1.1,4-1.6c-4.2,1.5-8.3,3.1-12.5,4.6c-1.3,0.5-2.7,1-4,1.5c-0.7,1.1-1.4,2.2-2.1,3.2c0,1.3,0.1,2.6,0.1,3.9
								c1.2,2.6,3.2,4.3,5.9,5c5.7,2.8,11.9,4.5,18,6c2.8,0.9,5.7,1.4,8.6,1.6c2.7,0.8,5.3,0.5,7.7-1.2
								c12.8-5.6,25.5-11.3,37.8-18.1c4.1-2.3,8.1-4.6,12.2-6.9c7.7-4.3,15.1-9,22.3-14.1c1.9-2.1,2.4-4.5,1.4-7.2
								c-2.1-3.4-5.2-5.3-9.4-5.7c-6.4-1.8-12.9-3.1-19.5-3.8C777.5,1204.1,773.8,1204.7,770.6,1207z"/>
							<path opacity="0.1091" fill="#131313" d="M795.6,1234.4c-7.5,2.4-14.6,5.8-21.2,10c-5.7,3.5-11.6,6.7-17.6,9.6
								c-4.1,1.9-8.1,3.8-12.2,5.7c-8.1,3.6-16.4,6.8-24.9,9.6c-4.3,1.6-6.2,8.4-3.4,12.1c2.8,2.1,5.8,3.6,9.3,4.4
								c3.3,1.1,6.7,1.5,10.2,1.2c13.1-4.9,26.1-10,38.6-16.4c4.1-2.2,8.2-4.4,12.4-6.6c8-5.4,16.5-9.9,25.5-13.5
								c4.1-1.9,5.3-9,2.4-12.3c-2.8-1.9-5.8-3-9.2-3.4C802.2,1233.8,798.9,1233.7,795.6,1234.4z"/>
							<path opacity="0.1091" fill="#131313" d="M742.5,1232.2c-5.5,4-11.2,7.5-17.1,10.8c-5.8,3.4-11.8,6.6-17.8,9.6
								c-4,1.9-8.1,3.7-12.1,5.6c-8.1,3.5-16.2,6.7-24.6,9.5c-2.6,1.8-3.3,4.2-2,7.1c3.1,4,7.2,6.5,12.4,7.4
								c8.4,2.9,17,5.3,25.8,7.2c4.9,1.9,9.7,1.9,14.4,0.1c13.4-5.7,26.7-11.4,39.5-18.4c4.2-2.3,8.4-4.7,12.5-7
								c7.8-4.2,15.3-8.9,22.5-14.1c2.5-2,2.9-4.4,1.4-7.2c-3.5-3.8-7.9-5.9-13.3-6.4c-8.9-2.3-17.8-4-26.9-5.1
								C752.1,1229.6,747.2,1230,742.5,1232.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M727.1,1269.8c-5.4-2.7-10.9-4.8-16.7-6.4c-4.5-2-8.7-4.5-12.6-7.5
								c-2.3-0.6-4.5-0.2-6.5,1.2c-2.2,1.1-3.6,2.8-4.3,5c0.2,2.9,1.1,5.6,2.7,8.1c1.3,2.7,3.2,4.8,5.6,6.5
								c4.5,3.1,9.4,5.7,14.4,7.9c5.8,1.3,11.4,3.2,16.8,5.6c2.4,0.2,4.5-0.4,6.3-2c2-1.3,3.3-3.1,3.8-5.4c-0.5-2.8-1.6-5.3-3.4-7.5
								C731.7,1272.9,729.7,1271.1,727.1,1269.8z"/>
							<path opacity="0.1091" fill="#131313" d="M701,1252.1c-2.4-0.5-4.9-0.9-7.3-1.4c1.3,0.3,2.6,0.6,3.9,0.9
								c-3.6-1-7.1-2.3-10.4-4.1c-3.4-1.5-6.6-3.3-9.6-5.5c-2.4-1.6-4.9-1.6-7.4-0.2c-2.5,1.5-3.6,3.7-3.4,6.6
								c1.3,4.8,3.3,9.4,6.1,13.6c2.4,4.4,5.4,8.4,8.9,11.9c2.7,1.4,5.3,2.8,8,4.1c3.9,1.8,7.9,3.3,12.1,4.5c5,1.6,9.7,2.5,14.9,3.5
								c2.8-0.4,4.8-1.8,5.9-4.4c0.9-3.6,0.1-6.9-2.4-9.8c-3.1-5.2-6.7-10-10.7-14.5C707.5,1254.1,704.7,1252.3,701,1252.1z"/>
							<path opacity="0.1091" fill="#131313" d="M701.8,1215.2c-5.1-0.6-10-2-14.6-4.3c1.6,0.8,3.3,1.6,4.9,2.4
								c-3.8-2-7.6-3.9-11.4-5.9c1.6,0.9,3.2,1.7,4.8,2.6c-2-1.3-4-2.6-6-3.9c-1.6-0.9-3.1-1.9-4.7-2.8c-1.3,0.2-2.7,0.3-4,0.5
								c-0.8,1.1-1.6,2.2-2.3,3.3c-0.5,3.6,0.2,7,2.2,10c3.5,8,8,15.6,12.7,23c2,3.5,4.3,6.9,6.9,10c1.7,3.2,4.3,5.5,7.7,6.8
								c5.5,2.2,11.1,4.1,16.8,5.6c4.8,1.8,9.7,2.8,14.8,3.2c3.2,0.3,5.2-1.2,5.9-4.3c0-5.3-1.9-9.9-5.5-14
								c-5.1-7.9-10.6-15.4-16.5-22.7C710.7,1220,706.8,1216.9,701.8,1215.2z"/>
							<path opacity="0.1091" fill="#131313" d="M726,1245.6c-4.9-1.2-9.8-2.7-14.7-4.3c-4.6-1.8-8.9-4.2-12.9-7.1
								c-2.4-1.4-4.8-1.4-7.2,0c-2.4,1.4-3.6,3.5-3.6,6.3c1.1,4.5,2.9,8.7,5.5,12.6c2.2,4.1,5,7.8,8.3,11c4.9,2.7,10.1,4.9,15.4,6.7
								c4.9,1.4,9.8,2.6,14.8,3.8c2.5,1.1,4.9,0.8,7.1-0.8c2.3-1.6,3.2-3.8,3-6.6c-1.5-4.3-3.7-8.2-6.5-11.8
								C732.7,1251.6,729.6,1248.3,726,1245.6z"/>
							<path opacity="0.1091" fill="#131313" d="M722.2,1214.7c-5.1-0.7-10-2.1-14.7-4.4c1.5,0.6,3,1.2,4.5,1.9
								c-4.6-1.9-8.9-4.3-12.9-7.3c-1.5-0.8-2.9-1.5-4.4-2.3c-1.3,0.2-2.6,0.4-3.9,0.7c-0.8,1-1.6,2.1-2.5,3.1
								c-0.7,3.2-0.2,6.2,1.6,9c2.9,7.1,6.8,13.7,11,20.2c1.7,3.1,3.7,6,6.1,8.7c1.6,2.9,3.9,4.8,7.1,5.7c5.2,2,10.5,3.7,15.9,5
								c4.8,1.5,9.6,2.6,14.6,3.1c3,0,5-1.5,6-4.3c0.4-4.6-1-8.6-4.1-12.2c-4.2-6.7-8.8-13.1-13.9-19.1
								C730.1,1218.3,726.6,1215.7,722.2,1214.7z"/>
							<path opacity="0.1091" fill="#131313" d="M685.5,1238.4c-5-0.6-9.9-2-14.5-4.1c1.5,0.6,3,1.2,4.5,1.9
								c-4.5-1.8-8.7-4.1-12.6-6.9c-1.4-0.7-2.8-1.4-4.2-2.1c-1.3,0.2-2.6,0.5-3.8,0.7c-0.8,1-1.7,2-2.5,3c-0.8,3.1-0.3,5.9,1.4,8.6
								c2.7,6.7,6.4,13,10.4,19.1c1.6,2.9,3.6,5.7,5.8,8.1c1.5,2.7,3.8,4.5,6.9,5.3c4.2,2,8.5,3.9,12.9,5.5c2.7,1.1,5.5,2,8.3,2.9
								c3.6,1.3,7.3,2.2,11.1,2.5c3.1,0,5-1.4,5.9-4.4c0.3-4.7-1.2-8.8-4.4-12.5c-4.4-6.9-9.2-13.5-14.4-19.7
								C693.5,1242.2,690,1239.6,685.5,1238.4z"/>
							<path opacity="0.1091" fill="#131313" d="M712.1,1273.8c-5.8-3.3-11.8-6-18-8.3c-4.5-2-8.7-4.5-12.6-7.5
								c-2.3-0.7-4.6-0.3-6.6,1c-2.2,1.1-3.6,2.8-4.2,5.2c0.3,3.2,1.4,6.1,3.1,8.8c1.4,2.9,3.4,5.2,6,7.1c4.7,3.2,9.6,6,14.8,8.2
								c6.2,2,12.3,4.5,18.1,7.4c2.4,0.4,4.6-0.2,6.5-1.8c2.1-1.3,3.3-3.2,3.6-5.6c-0.6-3.1-2-5.8-4-8.3
								C717,1277.4,714.8,1275.3,712.1,1273.8z"/>
							<path opacity="0.1091" fill="#131313" d="M740.8,1243.3c-5-2.7-10.3-4.8-15.9-6c-4.5-1.8-8.8-4.1-12.8-6.9
								c-2.4-1.3-4.8-1.2-7.1,0.3c-2.4,1.3-3.6,3.3-3.7,6.1c0.9,4.2,2.6,8.1,5,11.8c2,3.8,4.6,7.2,7.8,10.1
								c4.8,2.5,9.7,4.6,14.8,6.2c5.5,0.8,10.7,2.5,15.7,5.1c4.8,1.9,10.3-2.4,10.1-7.4c-1.2-3.9-3.1-7.4-5.6-10.5
								C746.9,1248.5,744.1,1245.6,740.8,1243.3z"/>
							<path opacity="0.1091" fill="#131313" d="M693.7,1226.8c-5.1-0.5-9.9-1.9-14.5-4.1c1.6,0.8,3.2,1.5,4.8,2.3
								c-3.8-1.9-7.5-3.7-11.3-5.6c1.6,0.8,3.1,1.6,4.7,2.4c-2-1.3-4-2.5-6-3.8c-1.5-0.9-3-1.7-4.5-2.6c-1.3,0.2-2.6,0.4-3.9,0.6
								c-0.8,1.1-1.6,2.1-2.4,3.2c-0.6,3.5,0.1,6.6,2,9.6c3.3,7.6,7.5,14.8,12,21.7c1.9,3.3,4,6.5,6.5,9.4c1.7,3.1,4.2,5.2,7.5,6.3
								c5.6,2.5,11.3,4.6,17.1,6.3c4.8,1.9,9.8,3,14.9,3.4c3.2,0.2,5.2-1.3,5.9-4.3c0-5.1-1.7-9.7-5.2-13.7
								c-4.9-7.6-10.3-15-16-21.9C702.4,1231.3,698.6,1228.3,693.7,1226.8z"/>
							<path opacity="0.1091" fill="#131313" d="M713,1226.6c-2.5-0.5-5-1.1-7.5-1.6c1.5,0.5,2.9,1.1,4.4,1.6
								c-3.8-1.4-7.7-2.9-11.5-4.3c1.5,0.6,2.9,1.2,4.4,1.7c-4.6-1.9-8.8-4.2-12.9-7.1c-1.4-0.7-2.8-1.4-4.2-2.1
								c-1.3,0.2-2.6,0.5-3.8,0.7c-0.8,1-1.7,2-2.5,3c-0.8,3.1-0.3,5.9,1.3,8.6c2.7,6.7,6.4,13,10.3,19.1c1.6,2.9,3.5,5.7,5.8,8.2
								c1.5,2.8,3.8,4.5,6.9,5.3c5.3,2.3,10.7,4.2,16.3,5.7c4.8,1.6,9.7,2.8,14.7,3.3c3-0.1,5-1.5,6-4.3c0.5-4.4-0.9-8.4-3.9-11.9
								c-4.1-6.5-8.6-12.7-13.5-18.6C720.7,1230,717.4,1227.5,713,1226.6z"/>
							<path opacity="0.1091" fill="#131313" d="M738.5,1258.5c-5.6-3.3-11.4-6.1-17.6-8.1c-4.4-1.8-8.5-4.1-12.4-6.9
								c-2.4-0.8-4.6-0.5-6.8,0.9c-2.3,1.2-3.6,3-4.1,5.5c0.5,3.4,1.7,6.6,3.6,9.5c1.6,3.1,3.7,5.7,6.5,7.8
								c4.5,2.7,9.2,4.9,14.1,6.6c6,1.6,11.7,3.9,17.2,6.8c2.4,0.4,4.6-0.2,6.5-1.7c2.1-1.3,3.3-3.2,3.6-5.6c-0.7-3.1-2-5.9-4-8.3
								C743.5,1262.2,741.3,1260.1,738.5,1258.5z"/>
							<path opacity="0.1091" fill="#131313" d="M688.1,1252.3c-2.5-0.5-4.9-1-7.4-1.5c1.4,0.4,2.7,0.8,4.1,1.2
								c-3.6-1-7.1-2.4-10.4-4.1c-3.4-1.5-6.5-3.3-9.5-5.5c-2.4-1.7-4.9-1.9-7.5-0.4c-2.5,1.6-3.6,3.8-3.3,6.8
								c1.5,5.1,3.7,10,6.6,14.5c2.6,4.7,5.7,9,9.5,12.8c2.7,1.5,5.5,2.9,8.2,4.4c4,1.9,8.1,3.5,12.3,4.8c2,0.7,4,1.3,6,1.9
								c3,0.9,6.1,1.6,9.2,2c2.9-0.3,4.8-1.7,5.9-4.4c0.8-3.9-0.2-7.4-3-10.5c-3.4-5.6-7.3-10.9-11.7-15.9
								C695.1,1254.8,692,1252.8,688.1,1252.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M718.5,1238c0.8-1.6,1.7-3.2,2.5-4.8c0.5-2.6-0.3-4.8-2.2-6.6
								c-1.8-1.9-4-2.7-6.6-2.2c-3.6,1.8-6.8,4.2-9.5,7.2c-3,2.7-5.4,5.9-7.2,9.5c-0.7,1.4-1.4,2.9-2.1,4.3
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7C714.4,1244.7,716.8,1241.5,718.5,1238z"/>
							<path opacity="0.1091" fill="#131313" d="M695.3,1221.9c0.9-1.8,1.9-3.5,2.8-5.3c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-3.9-2.7-6.5-2.4c-3.4,1.6-6.4,3.8-8.9,6.6c-2.8,2.5-5,5.5-6.6,8.9c-0.8,1.6-1.6,3.2-2.4,4.8
								c-0.3,2.5,0.5,4.7,2.4,6.4c1.7,1.9,3.9,2.7,6.4,2.4c3.3-1.5,6.2-3.6,8.7-6.4C691.7,1228.1,693.8,1225.2,695.3,1221.9z"/>
							<path opacity="0.1091" fill="#131313" d="M700.8,1181.6c0-0.8,0.1-1.6,0.1-2.4c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.2,0.1-7.7,1.8-10.4,5.2c-5.3,4.7-10.3,9.8-14.8,15.2c-3.3,2.9-5,6.4-5,10.6c0,0.6,0,1.1-0.1,1.7c0.6,2.9,2.4,4.6,5.2,5.2
								c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C699.1,1189.2,700.8,1185.7,700.8,1181.6z"/>
							<path opacity="0.1091" fill="#131313" d="M718.5,1208.6c0-0.8,0.1-1.6,0.1-2.3c-0.7-2.8-2.5-4.5-5.3-5.2
								c-4-0.1-7.2,1.4-9.9,4.6c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,0.6-0.1,1.2-0.1,1.7c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C717.2,1215.9,718.7,1212.6,718.5,1208.6z"/>
							<path opacity="0.1091" fill="#131313" d="M721.8,1177.2c-0.1-0.7-0.3-1.3-0.4-2c-0.3-3.2-2-4.9-5.3-5.2
								c-5.2,0.9-9.5,3.5-12.9,7.7c-6.9,6.3-13.5,13-19.7,20.1c-4.2,3.5-6.7,7.9-7.5,13.1c0.1,0.4,0.2,0.8,0.4,1.3
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C718.5,1186.7,721,1182.4,721.8,1177.2z"/>
							<path opacity="0.1091" fill="#131313" d="M682.3,1208.1c0.8-1.6,1.6-3.2,2.4-4.8c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.7-1.9-3.8-2.7-6.3-2.5c-3.2,1.3-5.9,3.3-8.2,5.9c-2.6,2.3-4.5,5.1-5.9,8.3c-0.7,1.5-1.4,3-2.1,4.6
								c-0.1,2.5,0.7,4.6,2.6,6.3c1.7,1.9,3.8,2.7,6.3,2.6c3.1-1.3,5.8-3.2,8.1-5.8C679.1,1213.9,681,1211.2,682.3,1208.1z"/>
							<path opacity="0.1091" fill="#131313" d="M702.8,1242.4c0.8-1.6,1.6-3.2,2.4-4.8c1.7-1.9,2-4,0.8-6.2c-1.2-2.2-3-4-5.2-5.2
								c-2.2-1.1-4.3-0.9-6.2,0.8c-2.7,1.9-4.9,4.1-6.8,6.8c-0.8,1.6-1.5,3.1-2.3,4.7c-1.7,1.9-1.9,4-0.8,6.2c1.2,2.2,3,4,5.2,5.2
								c2.2,1.2,4.2,0.9,6.2-0.7C698.7,1247.2,700.9,1245,702.8,1242.4z"/>
							<path opacity="0.1091" fill="#131313" d="M733.3,1204.3c-0.1-0.7-0.1-1.4-0.2-2.1c-0.4-3-2.2-4.8-5.3-5.2
								c-4.7,0.5-8.6,2.6-11.7,6.5c-6.2,5.5-12,11.4-17.3,17.6c-3.8,3.2-5.9,7.1-6.3,11.8c0.1,0.5,0.1,1,0.2,1.5
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C730.7,1212.9,732.8,1209,733.3,1204.3z"/>
							<path opacity="0.1091" fill="#131313" d="M691.4,1196.4c0.2-0.9,0.4-1.8,0.5-2.7c0.8-2.7,0.2-5.1-1.8-7.1
								c-2.1-2-4.4-2.5-7.2-1.6c-4.2,2.5-8,5.5-11.3,9.2c-3.6,3.4-6.5,7.2-8.9,11.5c-0.1,0.7-0.3,1.4-0.4,2.1
								c-0.8,2.7-0.2,5.1,1.8,7.1c2,2,4.4,2.6,7.1,1.7c4.2-2.4,8-5.4,11.3-9C686.1,1204.4,689.1,1200.6,691.4,1196.4z"/>
							<path opacity="0.1091" fill="#131313" d="M709.8,1190.7c0-0.8,0-1.5,0-2.3c-0.6-2.9-2.3-4.6-5.3-5.2c-4.3,0.2-7.9,2-10.8,5.5
								c-5.6,4.9-10.7,10.2-15.5,15.8c-3.4,2.9-5.2,6.6-5.3,10.9c0,0.5,0,1.1,0,1.6c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C707.9,1198.6,709.7,1195,709.8,1190.7z"/>
							<path opacity="0.1091" fill="#131313" d="M729.9,1220c0-0.8,0.1-1.6,0.1-2.3c-0.7-2.8-2.5-4.5-5.3-5.2
								c-4-0.1-7.3,1.4-9.9,4.6c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,0.6-0.1,1.2-0.1,1.7c0.7,2.8,2.4,4.5,5.2,5.2
								c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C728.6,1227.2,730.1,1223.9,729.9,1220z"/>
							<path opacity="0.1091" fill="#131313" d="M683.6,1223.3c0.8-1.6,1.6-3.2,2.4-4.8c1.7-1.9,2-4,0.9-6.3c-1.2-2.3-3-4-5.2-5.2
								c-2.3-1.1-4.4-0.8-6.3,0.9c-2.7,1.9-5,4.2-6.9,6.9c-0.8,1.6-1.5,3.1-2.3,4.7c-1.7,1.9-2,4-0.8,6.2c1.2,2.2,3,4,5.2,5.2
								c2.2,1.1,4.3,0.9,6.2-0.8C679.5,1228.3,681.8,1226,683.6,1223.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M681.7,1284.5c1.1,1.9,2.1,3.9,2.9,5.9c-0.4-1.6-0.8-3.2-1.2-4.9
								c0.3,1.9,0.5,3.8,0.8,5.6c0.6-2.9,1.2-5.8,1.8-8.7c-1.3,4.2-2.6,8.4-3.9,12.6c4.5-9.7,9-19.4,13.5-29.2
								c-4.7,8.3-9.4,16.5-14.1,24.8c4.5-5.8,9-11.7,13.5-17.5c-5.3,6.3-10.6,12.6-15.9,18.8c4.8-5.1,9.7-10.2,14.5-15.3
								c-5.7,5.5-11.4,11-17.1,16.5c5.2-4.4,10.4-8.8,15.6-13.2c-6.2,4.6-12.4,9.2-18.6,13.9c5.4-3.6,10.7-7.2,16.1-10.8
								c-6.3,3.7-12.7,7.5-19,11.2c5.5-2.8,11-5.7,16.5-8.5c-3.9,1.6-7.9,3.2-11.8,4.8c2.7-0.9,5.4-1.7,8.2-2.6
								c-3.9,1.1-7.7,2.2-11.6,3.3c2.6-0.5,5.3-1,7.9-1.4c-4.1,0.4-8.1,0.7-12.2,1.1c2.5-0.1,4.9-0.2,7.4-0.4
								c-3.9-0.1-7.7-0.2-11.6-0.3c2.3,0.2,4.6,0.4,6.9,0.7c-5-1.1-9.8-2.8-14.5-4.9c3.1,1.6,6.2,3.2,9.3,4.7
								c-2.5-1.9-5-3.7-7.5-5.6c1,0.9,2,1.9,3,2.8c-2.2-2-4.4-4.1-6.6-6.1c0.8,1,1.6,2,2.5,3.1c-1.6-2.3-3.1-4.7-4.7-7
								c0.6,1.1,1.3,2.1,1.9,3.2c-1.2-2.4-2.5-4.9-3.7-7.3c0.5,1.1,0.9,2.2,1.4,3.3c-0.8-2.6-1.5-5.1-2.3-7.7
								c-1.3-2.9-3-5.4-5.2-7.6c-1.2-1.7-2.6-2.3-4.4-1.9c-1.8-0.1-3.4,0.5-4.8,1.7c-2.1,1.2-3.4,3-4,5.3c0.1,2.2,0.2,4.3,0.3,6.5
								c0.5,2.1,1,4.2,1.5,6.3c0.7,2,1.3,4,2,6c2,3.8,4.5,7.2,7.6,10.2c1.4,2.8,3.5,4.6,6.2,5.6c1.6,0.9,3.4,1.5,5.3,1.7
								c10.5,2.5,22.3,1.7,32.7,2c1.4-0.6,2.8-1.3,4.2-1.9c3.3-3.3,6.1-6.9,8.5-10.8c2.3-3.1,4.3-6.3,6.1-9.7
								c2.5-3.9,4.5-8,6.1-12.4c0.4-3.4,0.4-6.8-0.2-10.2c-0.6-3.2-1.2-6.4-1.9-9.6c-0.6-2.6-1.5-5.1-2.5-7.5
								c-0.9-1.8-1.9-3.6-3-5.4c-1.8-2.5-4.1-3.2-7-2.2c-4,2.7-6.5,6.5-7.5,11.4c-3,7.9-5.4,15.9-7.4,24
								C680,1275.6,679.9,1280.1,681.7,1284.5z"/>
							<path opacity="0.1091" fill="#131313" d="M659.7,1266c1.1,2,2.1,4,2.9,6.1c-0.5-1.4-0.9-2.8-1.4-4.3c0.3,1,0.5,2,0.8,3
								c1.4-6,2.8-12.1,4.1-18.1c-2.8,9-5.6,18-8.4,27c5-8.7,10.1-17.4,15.1-26c-5.3,7.2-10.6,14.3-16,21.5
								c5.2-5.5,10.4-10.9,15.6-16.4c-6.1,5.7-12.1,11.5-18.2,17.2c5.8-5,11.7-9.9,17.5-14.9c-6.7,5.1-13.5,10.3-20.2,15.4
								c6.4-4.3,12.7-8.6,19.1-12.9c-7.2,4.3-14.5,8.6-21.7,12.9c6.8-3.6,13.7-7.2,20.5-10.8c-7.7,3.5-15.3,6.9-23,10.4
								c7-2.7,14-5.4,21-8.2c-7.9,2.5-15.7,4.9-23.6,7.4c7.2-1.8,14.4-3.7,21.6-5.5c-8.2,1.5-16.4,2.9-24.5,4.4
								c7.1-0.9,14.3-1.8,21.4-2.7c-8.1,0.4-16.2,0.7-24.3,1.1c7.1,0.1,14.1,0.1,21.2,0.2c-8-0.8-16-1.5-23.9-2.3
								c6.8,1,13.5,1.9,20.3,2.9c-7.5-1.7-15.1-3.5-22.6-5.2c6.4,1.8,12.8,3.7,19.2,5.5c-7.1-2.8-14.1-5.5-21.2-8.3
								c5.8,2.6,11.5,5.1,17.3,7.7c-6.4-3.5-12.8-7.1-19.3-10.6c5.2,3.2,10.4,6.5,15.6,9.7c-3.4-2.6-6.8-5.3-10.2-7.9
								c2.2,2,4.3,4,6.5,5.9c-4.9-4.9-9.7-9.7-14.6-14.6c3.8,4.2,7.5,8.4,11.3,12.5c-3.9-6.6-8.5-12.5-14-17.7c-1.3-2-3.2-3-5.7-2.9
								c-2.1,0.1-3.7,1-4.9,2.6c-1.6,1.2-2.3,2.9-2,5.1c0.7,1.8,1.4,3.6,2,5.4c1.3,2.3,2.7,4.7,4,7c22.2-10.5,44.4-20.9,66.6-31.4
								c-0.5-1.4-0.9-2.8-1.4-4.3c-0.9-1.9-1.9-3.7-2.9-5.5c-1.9-2.3-4.2-3-7-2.2c-3.7,2-6,5.2-6.9,9.5c-2.6,6.6-4.7,13.4-6.3,20.4
								C657.7,1258.4,657.8,1262.3,659.7,1266z"/>
							<path opacity="0.1091" fill="#131313" d="M660.8,1229.3c0.8,3.1,1.5,6.3,2.1,9.4c0.7,1.8,1,3.6,0.9,5.5
								c0.1-1.3,0.1-2.7,0.2-4c-0.2,0.6-0.3,1.2-0.5,1.8c0.6-1.7,1.3-3.5,1.9-5.2c-1,2.3-2,4.5-3.1,6.8c1.9-3.4,3.7-6.7,5.6-10.1
								c-1.4,2-2.7,4-4.1,6c1.2-1.6,2.5-3.1,3.7-4.7c-1.7,1.9-3.4,3.7-5,5.6c2.7-2.4,5.4-4.8,8.1-7.2c-4.9,2.7-9.9,5.4-14.8,8.1
								c2.4-1,4.9-2.1,7.3-3.1c-3,1-5.9,2-8.9,2.9c4.6-1,9.2-2.1,13.8-3.1c-5.8,0.8-11.6,1.5-17.4,1.9c3-0.2,6.1-0.3,9.1-0.5
								c-3.7-0.2-7.4-0.4-11-0.7c5.5,0.7,11,1.4,16.5,2.1c-10.1-2.3-20.1-4.6-30.2-6.9c9.1,3.1,18.2,6.2,27.4,9.3
								c-10.1-5-20.2-10-30.2-15c6.5,4,13.1,8,19.6,12c-6.9-5-13.8-10-20.7-15c6.1,4.9,12.1,9.9,18.2,14.8c-6.2-6-12.4-12-18.6-18
								c5.5,5.8,11,11.7,16.5,17.5c-6.8-9.4-14.3-18.2-22.4-26.5c-2.6-2.2-5.4-2.5-8.3-0.9c-0.7,1.1-1.5,2.2-2.2,3.2
								c-0.4,4.6,0.9,8.8,4.1,12.5c3.1,5.2,6.5,10.2,10.2,15c3.3,4.2,6.3,8.5,9.2,13c4.1,3,9.5,4.2,14.5,4.5
								c5.8,0.3,12.9-0.9,17.7-4.3c4.3-3.1,8-7.7,10.1-12.7c1-2.4,1.6-5,1.9-7.6c0.1-1.3,0.1-2.7,0.2-4c-0.4-2-1.1-3.9-2-5.7
								c-0.7-3-1.5-6-2.4-9c-1.2-2.1-3-3.4-5.3-4c-2.3-0.9-4.5-0.8-6.6,0.3c-2,2.2-3.4,4.7-4.1,7.6
								C660.7,1223.5,660.4,1226.3,660.8,1229.3z"/>
							<path opacity="0.1091" fill="#131313" d="M680.9,1258.5c0.5,1,1,2.1,1.5,3.1c-0.6-1.1-1.1-2.3-1.7-3.4
								c0.7,1.4,1.3,2.9,1.7,4.5c0.5,1.3,0.7,2.6,0.6,4c0.3-2,0.5-3.9,0.8-5.9c-1,3.5-1.9,6.9-2.9,10.4c3.1-6.7,6.1-13.3,9.2-20
								c-3.7,5.9-7.5,11.9-11.2,17.8c3.3-4.1,6.6-8.2,10-12.3c-2.4,2.5-4.8,5.1-7.2,7.6c1.9-1.8,3.9-3.5,5.8-5.3
								c-2.6,2.2-5.3,4.5-7.9,6.7c2.1-1.5,4.2-3.1,6.3-4.6c-2.9,1.9-5.9,3.8-8.8,5.7c2.3-1.3,4.5-2.6,6.8-3.9
								c-3.1,1.5-6.2,3-9.3,4.5c2.4-1,4.8-2,7.1-3.1c-3.3,1.1-6.6,2.2-9.8,3.3c2.4-0.7,4.8-1.4,7.2-2.1c-3.3,0.7-6.7,1.3-10,2
								c2.4-0.4,4.9-0.8,7.3-1.2c-3.5,0.2-7,0.4-10.5,0.6c2.4-0.1,4.8-0.2,7.3-0.3c-3.5-0.3-6.9-0.6-10.4-0.9
								c2.4,0.2,4.8,0.5,7.2,0.7c-3.3-0.8-6.7-1.5-10-2.3c2.3,0.5,4.7,1.1,7,1.6c-3.1-1.2-6.3-2.4-9.4-3.6c2.2,0.9,4.5,1.7,6.7,2.6
								c-3-1.6-5.9-3.2-8.9-4.8c2.1,1.1,4.1,2.3,6.2,3.4c-2.7-2-5.4-3.9-8.2-5.9c1.9,1.4,3.8,2.8,5.7,4.2c-2.4-2.3-4.9-4.6-7.3-6.9
								c1.7,1.7,3.4,3.3,5.1,5c-2.1-2.6-4.1-5.2-6.2-7.8c1.5,1.9,2.9,3.8,4.4,5.7c-1.8-2.6-3.6-5.2-5.3-7.9c-1.6-3-3.6-5.5-6.1-7.7
								c-1.2-1.9-3-2.7-5.4-2.5c-1.2,0.4-2.4,0.8-3.6,1.3c-2.3,1.4-3.5,3.4-3.6,6.1c0.8,5.4,1.7,10.9,4.3,15.8
								c2,3.1,4.1,6.2,6.1,9.3c1.8,2.2,3.8,4.1,6.1,5.7c1.2,1.4,2.8,2.2,4.7,2.4c3.1,1.1,6.4,1.5,9.7,1.4c2.4-0.1,4.8-0.1,7.3-0.2
								c7.1-1.3,14-3.3,20.8-6c1.2-0.5,2.3-1,3.5-1.5c2.2-2.5,4-5.4,5.3-8.5c1.3-2.4,2.4-4.9,3.3-7.5c1.4-3.1,2.3-6.3,2.6-9.6
								c-0.5-3.9-1.2-7.1-2.7-10.7c-1.3-3.2-2.9-6.3-4.4-9.4c-2-1.9-4.3-2.6-7-2.2c-3.3,1.2-5.3,3.5-6.2,7.1
								c-2.2,5.1-3.8,10.4-4.9,15.9C678.5,1252.6,678.9,1255.7,680.9,1258.5z"/>
							<path opacity="0.1091" fill="#131313" d="M679.7,1227.3c0.8,3.1,1.5,6.3,2.1,9.4c0.8,1.3,1.3,2.6,1.4,4.1
								c0.4-2.5,0.7-5,1.1-7.4c-0.7,2.2-1.3,4.3-2,6.5c0.5-1.4,0.9-2.7,1.4-4.1c-0.4,0.7-0.8,1.4-1.1,2.1c0.8-1.2,1.6-2.4,2.4-3.7
								c-1,1.4-2.2,2.5-3.7,3.5c1-0.6,2-1.2,3-1.8c-1.7,1-3.5,1.9-5.2,2.9c1.1-0.5,2.1-1,3.2-1.5c-1.9,0.8-3.8,1.5-5.7,2.3
								c1.2-0.4,2.3-0.9,3.5-1.3c-2.2,0.6-4.5,1.1-6.7,1.7c1.4,0,2.8-0.1,4.2-0.1c-2.4-0.3-4.9-0.6-7.3-0.9c1.5,0.3,3,0.6,4.6,0.9
								c-2.4-0.9-4.9-1.7-7.4-2.5c1.6,0.7,3.2,1.4,4.8,2.1c-4.8-2.6-9.6-5-14.4-7.5c4,2.5,8,5,12,7.5c-7.1-6.8-14.3-13.6-21.4-20.4
								c4.4,4.9,8.9,9.8,13.3,14.7c-4.4-5.8-8.8-11.6-13.1-17.4c-1.7-1.8-3.4-3.6-5.1-5.4c-1.4-0.5-2.7-1-4.1-1.5
								c-1.2,0.4-2.5,0.7-3.7,1.1c-0.8,1-1.6,2-2.4,3c-0.1,1.5-0.1,2.9-0.2,4.4c1,2.3,2,4.5,3,6.7c-0.8,0-1.5,0-2.3-0.1
								c0.2,1.3,0.5,2.7,0.7,4c1.5,3.4,3,6.7,4.5,10.1c1.8,3,4,6.1,6.5,8.6c2.5,2.5,5.6,4.9,8.6,6.7c3.3,2,7,3.6,10.8,4.5
								c1.3,0.5,2.7,0.7,4.1,0.6c2.2,0.3,4.4,0.1,6.5-0.7c3.7-0.7,7-1.4,10.2-3.3c1.3-0.8,2.5-1.5,3.8-2.4c1-0.7,2-1.4,2.9-2.3
								c1.2-0.8,2.1-1.9,2.7-3.2c1.5-2.3,2.7-4.5,3.6-7.1c0.6-1.2,0.9-2.4,0.7-3.7c0.1-1,0.1-1.9,0-2.9c0-0.9-0.1-1.9-0.2-2.8
								c0.2-1-0.1-1.9-0.7-2.7c-0.7-1-1.1-2.2-1.2-3.4c-0.5-2.3-1.2-4.5-1.9-6.7c-1.4-1.8-3.2-3-5.5-3.6c-2.2-0.8-4.3-0.9-6.5-0.1
								c-1.8,1.6-3,3.6-3.6,6C679.3,1222.6,679.1,1224.9,679.7,1227.3z"/>
							<path opacity="0.1091" fill="#131313" d="M645,1254.5c1.2,1.9,2.1,3.8,2.9,5.8c-0.5-1.2-1-2.5-1.6-3.7c1.2,2.6,1.2,5.2,0.2,8
								c1.7-5.2,3.3-10.4,5-15.6c-1.9,4.2-3.8,8.4-5.7,12.5c3.7-5.7,7.4-11.4,11.1-17.2c-4.4,6.2-8.7,12.3-13.1,18.5
								c4.4-5.6,8.8-11.2,13.3-16.8c-5.1,5.9-10.2,11.8-15.4,17.7c5.1-5.3,10.3-10.6,15.4-15.9c-6,5.5-12,11-18,16.6
								c5.9-5,11.9-10,17.8-15c-6.8,5.1-13.7,10.3-20.5,15.4c6.7-4.5,13.4-9,20.1-13.5c-7.6,4.4-15.2,8.8-22.8,13.3
								c7.4-3.9,14.8-7.9,22.2-11.8c-8.2,3.7-16.4,7.5-24.7,11.2c7.9-3.1,15.7-6.2,23.6-9.2c-8.7,2.7-17.4,5.4-26.1,8.1
								c8.3-2.2,16.7-4.4,25-6.6c-9.3,1.8-18.5,3.6-27.8,5.4c8.5-1.1,17-2.3,25.6-3.4c-9.4,0.5-18.8,1-28.2,1.6
								c8.7,0,17.4-0.1,26.1-0.1c-9.5-0.7-19.1-1.4-28.6-2c8.5,1.1,17.1,2.2,25.6,3.3c-9.2-1.9-18.3-3.8-27.5-5.7
								c8.3,2.2,16.5,4.4,24.8,6.7c-8.8-3.1-17.7-6.2-26.5-9.3c7.6,3.2,15.2,6.4,22.8,9.6c-8.1-4.1-16.2-8.2-24.4-12.3
								c7,4.1,14,8.3,21,12.4c-7.4-5.2-14.8-10.4-22.3-15.6c6.1,4.8,12.2,9.7,18.3,14.5c-6.3-5.8-12.7-11.7-19-17.5
								c5.1,5.4,10.3,10.9,15.4,16.3c-6.4-8.8-13.5-17-21.1-24.6c-2.6-1.9-5.2-2-7.9-0.4c-6.9,4.8,0.7,14.3,3.7,18.9
								c2.7,4.6,5.8,8.9,9.3,13c36.2,1.5,54.8-9.6,55.9-33.3c-0.7-2.3-1.7-4.5-3-6.5c-0.9-1.8-1.9-3.6-2.9-5.4c-1.9-2-4.3-2.7-7-2.2
								c-3.4,1.4-5.5,4-6.4,7.7c-2.3,5.5-4.1,11.2-5.3,17.1C642.7,1248.1,643,1251.4,645,1254.5z"/>
							<path opacity="0.1091" fill="#131313" d="M665.1,1289.5c0.9,1.4,1.7,2.9,2.3,4.4c0.8,1.4,1.2,2.9,1.3,4.5
								c-0.3-1.8-0.7-3.6-1-5.3c0,0.9-0.1,1.9-0.1,2.8c3.9-12.1,7.7-24.2,11.6-36.3c-5.4,13.8-10.8,27.7-16.2,41.5
								c3.6-6.8,7.1-13.6,10.7-20.4c-2.9,4.4-5.7,8.8-8.6,13.1c5.4-7.1,10.7-14.1,16.1-21.2c-6.1,7.4-12.2,14.9-18.3,22.3
								c5.9-6.3,11.7-12.6,17.6-18.9c-6.7,6.6-13.4,13.2-20,19.8c6.4-5.5,12.7-11.1,19.1-16.6c-7.3,5.7-14.6,11.4-21.9,17.1
								c6.7-4.6,13.4-9.3,20.1-13.9c-7.6,4.7-15.1,9.3-22.7,14c6.9-3.7,13.9-7.4,20.8-11.2c-7.8,3.6-15.6,7.1-23.5,10.7
								c6.8-2.7,13.7-5.4,20.5-8.1c-7.7,2.5-15.4,5-23.2,7.5c6.7-1.7,13.4-3.4,20.1-5.1c-7.8,1.3-15.7,2.6-23.5,3.9
								c6.3-0.8,12.7-1.6,19-2.3c-7.5,0.3-14.9,0.6-22.4,0.9c6,0.1,12,0.2,18,0.3c-4.4-0.6-8.7-1.3-13.1-1.9
								c2.7,0.6,5.4,1.1,8.1,1.7c-4-1.1-8-2.1-11.9-3.2c2.4,0.9,4.8,1.8,7.2,2.6c-3.6-1.8-7.2-3.6-10.8-5.4c2.1,1.1,4.1,2.2,6.2,3.3
								c-3.2-2.1-6.4-4.1-9.6-6.2c2.8,2.5,5.6,5,8.4,7.5c-3.6-3.7-6.8-7.6-9.6-11.9c0.5,1.2,1.1,2.5,1.6,3.7
								c-1.2-2.9-2.4-5.8-3.6-8.7c-1.4-3.1-3.3-6-5.6-8.5c-1.2-1.8-2.7-2.6-4.6-2.3c-1.8-0.2-3.5,0.3-4.9,1.6c-2.1,1.2-3.4,3-4,5.4
								c0.3,6.7,2,13,5.2,19c1.6,2.2,3.5,4.1,5.8,5.5c2.5,2.1,5.3,3.8,8.3,5.2c10.2,3.4,20.6,6.4,31,9.2c3.2,1.4,6,0.7,8.5-1.9
								c3.7-4,7-8.3,9.8-13c3.9-5.6,7.4-11.3,10.5-17.3c3.2-4.2,4.6-8.9,4.2-13.9c-0.3-3.3-0.9-6.4-1.9-9.6c-0.8-2-1.5-4.1-2.2-6.2
								c-1.3-3.8-3.1-7.1-5.2-10.5c-1.7-2.7-4.1-3.5-7-2.2c-4.2,3.2-6.8,7.4-7.9,12.7c-3.3,8.7-6,17.6-8.2,26.7
								C663.7,1279.5,663.5,1284.5,665.1,1289.5z"/>
							<path opacity="0.1091" fill="#131313" d="M693.6,1250c0.8,3,1.4,6.1,1.9,9.1c0.7,1.6,1.1,3.2,1.1,5c0.2-1.7,0.3-3.4,0.5-5.1
								c-0.2,0.9-0.3,1.8-0.5,2.7c0.8-2.2,1.6-4.3,2.4-6.5c-1.1,3-2.3,5.9-3.4,8.9c0.9-2,1.9-4.1,2.8-6.1c-0.5,0.8-1,1.6-1.4,2.4
								c1.3-1.7,2.6-3.5,3.9-5.2c-1.9,2.5-3.8,4.9-5.7,7.4c1.5-1.5,2.9-3.1,4.4-4.6c-3.1,2.6-6.2,5.1-9.4,7.7c1.7-1.2,3.4-2.3,5-3.5
								c-1.7,1-3.5,1.8-5.4,2.4c1.4-0.2,2.9-0.5,4.3-0.7c-2.5,0.3-5,0.7-7.5,1c1.4,0,2.8-0.1,4.2-0.1c-2.5,0-5,0.1-7.4,0.1
								c1.4,0.1,2.7,0.2,4.1,0.4c-2.4-0.3-4.8-0.6-7.2-0.9c1.3,0.3,2.6,0.6,4,0.9c-6.7-4.2-13.3-8.5-20-12.7c2.5,2.3,5,4.6,7.4,7
								c-3.3-4.7-6.4-9.5-9.2-14.5c-1.4-2.7-3.2-5.1-5.5-7c-1.2-1.7-2.9-2.5-5.1-2.2c-1.2,0.4-2.3,0.9-3.5,1.3
								c-2.3,1.3-3.5,3.2-3.8,5.8c0.3,3.6,0.8,7.2,1.6,10.7c0.7,2.7,1.8,5.3,3.2,7.7c1.2,2.5,2.6,4.9,4.3,7.1
								c1.7,2.3,3.7,4.3,5.9,6.1c4.8,4.2,10.7,7.1,17,8.5c4.7,1,9.6,1.5,14.4,0.2c2.8-1,5.4-2.2,8-3.7c2.9-1.1,5.3-2.8,7.4-5.1
								c2.9-3.3,5.2-6.9,7.2-10.8c0.5-1.5,1-3,1.5-4.5c1.3-4.2,2.2-8.7,1.7-13.2c-0.4-2.2-1.1-4.3-2-6.3c-0.6-2.9-1.3-5.7-2.1-8.6
								c-1.3-5-8.1-6.8-12-3.7c-2.4,3.3-4.1,6.9-5.1,10.9C694.1,1242,693.5,1246,693.6,1250z"/>
							<path opacity="0.1091" fill="#131313" d="M652.7,1237.6c0.8,3,1.4,6,1.9,9.1c1.3,2.2,1.5,4.5,0.6,7c1.2-4.2,2.5-8.4,3.7-12.5
								c-1.2,3-2.4,6.1-3.6,9.1c1.1-2.4,2.3-4.8,3.4-7.2c-1.6,2.9-3.1,5.7-4.7,8.6c3-4.5,6-9.1,9-13.6c-1.9,2.6-3.9,5.3-5.8,7.9
								c1.9-2.1,3.8-4.3,5.7-6.4c-2.3,2.5-4.5,5-6.8,7.5c2.2-2,4.4-4,6.6-6c-2.7,2.3-5.4,4.5-8.1,6.8c2.5-1.9,5-3.8,7.6-5.7
								c-6.6,3.9-13.2,7.9-19.8,11.8c6.2-3.2,12.4-6.3,18.6-9.5c-7.8,2.6-15.6,5.1-23.4,7.7c7.2-1.8,14.3-3.5,21.5-5.3
								c-8,1.4-16.1,2.8-24.1,4.1c7.5-0.9,14.9-1.8,22.4-2.7c-8.4,0.2-16.7,0.5-25.1,0.7c7.8,0.1,15.6,0.1,23.4,0.2
								c-8.6-0.7-17.2-1.5-25.8-2.2c7.8,1.1,15.7,2.2,23.5,3.3c-8.5-1.9-17-3.9-25.5-5.8c7.8,2.2,15.6,4.3,23.4,6.5
								c-8.4-3-16.8-6-25.2-9c7.4,3.2,14.8,6.4,22.3,9.5c-7.9-4.1-15.8-8.3-23.8-12.4c7,4.2,14.1,8.4,21.1,12.6
								c-7.5-5.2-14.9-10.4-22.4-15.6c6.3,5.1,12.7,10.1,19,15.2c-6.5-6-13.1-12.1-19.6-18.1c5.6,5.8,11.1,11.7,16.7,17.5
								c-5.6-6.7-11.3-13.5-16.9-20.2c-1.8-3.6-4.5-5.9-8-7c-1.3-1.1-2.7-1.2-4.1-0.4c-2.1,0.1-3.5,1.4-4.1,3.7
								c0.1,1.6,0.1,3.2,0.2,4.8c1.2,2.5,2.5,5,3.7,7.5c3,5.1,6.3,10,10,14.6c5.7,9.2,14.7,14.3,26.9,15.3c2.7,0.6,5,0.1,7.1-1.7
								c3.8-1.9,7.3-4.4,10.5-7.3c2.3-2.1,4.7-4.2,7-6.3c3.3-2.3,5-5.4,5.2-9.2c1.2-6.2,0.3-11.8-2.5-16.7c-0.7-1.4-1.5-2.7-2.3-4
								c0.6,1,1.2,2.1,1.7,3.1c-0.6-2.8-1.3-5.6-2.1-8.3c-1.3-5-8.1-6.8-12-3.7c-2.4,3.3-4.1,6.9-5.1,10.9
								C653.2,1229.6,652.6,1233.5,652.7,1237.6z"/>
							<path opacity="0.1091" fill="#131313" d="M670.7,1236.3c0.7,3.3,1.3,6.7,1.8,10c0.8,1.4,1.1,3,1.1,4.6
								c0.7-3.6,1.5-7.1,2.2-10.7c-1,3.4-2.1,6.8-3.1,10.3c0.9-2,1.8-3.9,2.7-5.9c-1.4,2.4-2.8,4.9-4.3,7.3
								c2.4-3.6,4.9-7.3,7.3-10.9c-2.3,2.6-4.6,5.1-6.9,7.7c1.7-1.5,3.4-3,5.2-4.5c-2.4,1.8-4.7,3.5-7.1,5.3
								c1.9-1.4,3.9-2.8,5.8-4.1c-2.6,1.6-5.2,3.1-7.8,4.7c3.8-1.8,7.7-3.6,11.5-5.3c-13.8-0.8-27.6-1.6-41.4-2.4c8,2,16,4,23.9,6
								c-3.5-1.5-6.9-3-10.4-4.5c2.7,1.4,5.5,2.8,8.2,4.2c-3.3-2-6.6-4-9.8-6.1c2.6,1.8,5.3,3.6,7.9,5.5
								c-5.8-4.4-11.6-8.9-17.4-13.3c5,4.2,10,8.4,15,12.6c-2.8-2.9-5.6-5.8-8.4-8.7c2.2,2.6,4.3,5.1,6.5,7.7
								c-5.4-8.3-11.7-15.9-18.6-22.8c-2.5-1.8-5.1-1.9-7.8-0.3c-2.4,1.8-3.3,4.3-2.6,7.3c2.1,5.5,4.8,10.5,8.3,15.3
								c4.2,2.9,6.3,6.5,6.3,11c-0.3,2.5,0.7,4.3,3.1,5.4c2.9,2.1,6,3.7,9.3,4.8c2.8,1.1,5.7,1.9,8.7,2.2c7.4,0.8,15.1-1.1,20.8-5.9
								c0.6-0.5,1.3-1.1,1.9-1.6c2.8-2.5,5.1-5.5,6.7-8.9c2.9-5.3,4.9-11.1,3.5-17.1c-0.5-1.8-1.2-3.5-2.1-5.1
								c-0.6-3.1-1.3-6.2-2-9.3c-1-2.3-2.7-3.8-5.2-4.4c-2.4-0.9-4.7-0.6-6.8,0.7c-2.2,2.7-3.7,5.8-4.6,9.2
								C670.9,1229.4,670.4,1232.8,670.7,1236.3z"/>
							<path opacity="0.1091" fill="#131313" d="M692.3,1269.9c1.1,1.9,2.1,3.9,2.9,5.9c-0.5-1.3-1-2.6-1.5-3.9
								c0.7,1.8,1,3.7,0.9,5.6c0.4-2.2,0.7-4.4,1.1-6.7c-1,3.7-2.1,7.4-3.1,11.1c3.4-7.3,6.7-14.6,10.1-21.9
								c-4,6.6-8,13.1-11.9,19.7c3.4-4.3,6.9-8.6,10.3-12.9c-2.6,2.8-5.1,5.6-7.7,8.4c1.9-1.8,3.9-3.6,5.8-5.3
								c-2.8,2.4-5.5,4.9-8.3,7.3c2.1-1.5,4.1-3,6.2-4.5c-3.1,2-6.1,3.9-9.2,5.9c2.1-1.2,4.3-2.4,6.4-3.6c-3.1,1.6-6.3,3.1-9.4,4.7
								c2.2-0.9,4.4-1.8,6.6-2.8c-3.3,1-6.6,2-9.9,3.1c2.1-0.6,4.3-1.2,6.4-1.8c-3.3,0.6-6.6,1.2-9.9,1.8c2.1-0.3,4.2-0.6,6.3-1
								c-2.8,0-5.5-0.3-8.2-0.9c1.3,0.5,2.7,0.9,4,1.4c-2.5-1-5-2-7.6-3c1.2,0.6,2.4,1.3,3.7,1.9c-2.3-1.3-4.6-2.6-6.9-3.9
								c1.1,0.8,2.2,1.6,3.3,2.4c-2-1.6-4-3.2-5.9-4.8c0.9,0.9,1.9,1.8,2.8,2.7c-1.7-1.8-3.5-3.7-5.2-5.5c0.8,1,1.6,2,2.4,3.1
								c-1.4-1.9-2.7-3.8-4.1-5.7c0.6,1.1,1.3,2.2,1.9,3.4c-1.1-2.1-2.1-4.1-3.2-6.2c0.5,1.2,1,2.4,1.5,3.6
								c-0.8-2.1-1.6-4.3-2.4-6.4c-1.4-3.3-3.3-6.3-5.7-9.1c-1.1-1.9-2.7-2.8-4.6-2.6c-1.8-0.3-3.5,0.1-5,1.4c-2.2,1.2-3.5,3-4,5.4
								c0,2.9,0.4,5.8,1.1,8.6c0.5,2.8,1.3,5.6,2.6,8.2c2.4,5.3,6.1,10.6,10.5,14.4c1.5,1.3,3,2.5,4.5,3.8c3.4,2,6.9,3.6,10.6,4.9
								c1.5,0.1,3.1,0.2,4.6,0.3c7.6-0.9,15-2.6,22.3-5c1.2-0.3,2.5-0.7,3.7-1c2.7-2.3,4.9-4.9,6.8-7.8c1.8-2.2,3.3-4.6,4.6-7.2
								c1.9-2.9,3.4-6,4.3-9.4c0.9-3.2,1.2-6.6,1-9.9c-0.1-2.9-0.6-5.7-1.4-8.5c-1.3-4.6-3.4-8.9-5.6-13c-1.9-2.1-4.3-2.8-7-2.2
								c-3.5,1.6-5.7,4.4-6.6,8.4c-2.4,5.9-4.3,12.1-5.6,18.3C690,1263,690.3,1266.5,692.3,1269.9z"/>
							<path opacity="0.1091" fill="#131313" d="M646.9,1267.6c1.1,1.9,2.1,4,2.9,6.1c-0.4-1.5-0.9-2.9-1.3-4.4c0.3,1,0.5,2,0.8,3
								c1.5-6.3,3-12.7,4.4-19c-3.1,9.4-6.1,18.7-9.2,28.1c3.3-6.2,6.5-12.3,9.8-18.5c-2.7,4-5.5,8-8.2,12
								c5.1-6.6,10.3-13.3,15.4-19.9c-5.9,7-11.7,14-17.6,21c5.9-6.2,11.7-12.4,17.6-18.6c-6.7,6.5-13.5,12.9-20.2,19.4
								c6.7-5.7,13.3-11.5,20-17.2c-7.6,5.9-15.2,11.8-22.7,17.7c7.3-5.1,14.7-10.1,22-15.2c-8.2,5-16.5,10-24.7,15
								c8-4.3,15.9-8.6,23.9-13c-8.8,4.1-17.6,8.2-26.3,12.4c8.3-3.3,16.5-6.6,24.8-9.9c-9.1,3-18.1,6-27.2,8.9
								c8.5-2.3,17.1-4.6,25.6-6.9c-9.5,1.9-18.9,3.8-28.4,5.7c8.5-1.2,17-2.3,25.5-3.5c-9.4,0.6-18.8,1.2-28.1,1.9
								c8.4,0,16.9-0.1,25.3-0.1c-9.3-0.7-18.6-1.3-27.8-2c8,1,16.1,2.1,24.1,3.1c-8.7-1.8-17.4-3.6-26.2-5.4
								c7.6,2.1,15.1,4.1,22.7,6.2c-8.2-3-16.4-5.9-24.6-8.9c6.8,2.9,13.5,5.8,20.3,8.7c-7.4-3.8-14.8-7.6-22.2-11.4
								c6,3.7,12.1,7.3,18.1,11c-6.6-4.8-13.1-9.6-19.7-14.3c5.1,4.2,10.3,8.3,15.4,12.5c-5.5-5.2-11-10.5-16.5-15.7
								c4.2,4.6,8.4,9.2,12.7,13.7c-4.5-7-9.7-13.4-15.6-19.1c-1.4-2.1-3.4-3.2-6-3.2c-2.2,0.1-3.8,0.9-4.9,2.6
								c-1.6,1.3-2.2,3.1-1.8,5.3c0.8,1.9,1.6,3.9,2.3,5.8c2.8,5.2,6,10,9.7,14.5c19.8-14.2,39.5-28.4,59.3-42.6
								c-0.5-2.6-1.4-4.9-2.9-7.1c-1.8-2.3-4.2-3.1-7-2.2c-3.8,2.2-6.1,5.5-7.1,9.9c-2.7,6.9-4.9,14-6.6,21.3
								C644.9,1259.6,645,1263.7,646.9,1267.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M700.8,1278.9c1.8,0.4,3.6,0.8,5.5,1.2c-1.6-0.8-3.2-1.6-4.8-2.4
								c3.3,1.7,6.6,3.3,9.9,5c-1.6-0.8-3.2-1.7-4.8-2.5c2.4,1.2,4.6,2.7,6.6,4.6c2,1.6,3.8,3.4,5.4,5.5c1.5,1.1,2.9,2.1,4.4,3.2
								c1.3,0,2.7-0.1,4-0.1c0.9-1,1.8-2,2.7-3c0.9-3.5,0.4-6.9-1.2-10.1c-2.7-8.2-6.4-16.1-10.3-23.8c-1.6-3.7-3.6-7.1-5.8-10.4
								c-1.4-3.3-3.7-5.8-7-7.4c-5.3-2.5-10.7-4.7-16.3-6.6c-2-0.7-4.1-1.3-6.1-2c-2.5-1-5.1-1.6-7.8-1.6c-3.2-0.3-5.2,1.1-6.1,4.2
								c-0.1,5.2,1.5,9.9,5,14c4.8,7.9,10,15.5,15.7,22.8C692.2,1273.9,695.9,1277.2,700.8,1278.9z"/>
							<path opacity="0.1091" fill="#131313" d="M676.9,1259.7c1.8,0.4,3.6,0.9,5.4,1.3c-1.3-0.3-2.5-0.6-3.8-0.9
								c3.4,1.1,6.5,2.7,9.5,4.7c2.9,2,5.4,4.3,7.6,7c2.3,1.9,4.7,2.3,7.4,1.1c2.6-1.3,4-3.4,4-6.3c-0.9-5.1-2.5-10-4.9-14.7
								c-2-4.8-4.6-9.2-7.9-13.3c-5.4-3.3-10.8-6.4-16.9-8.3c-4.7-1.5-9.4-2.6-14.2-3.6c-2.8,0.3-4.8,1.7-6.1,4.2
								c-1.1,3.4-0.4,6.6,1.9,9.5c2.8,5,6,9.7,9.7,14.1C670.6,1257.7,673.3,1259.5,676.9,1259.7z"/>
							<path opacity="0.1091" fill="#131313" d="M675.8,1224.4c3.2,2.4,6.7,4.2,10.4,5.3c2.5,0.7,4.8,1.9,6.8,3.6
								c-0.9-0.7-1.8-1.4-2.7-2.1c2.2,1.9,4.4,3.8,6.7,5.7c-0.9-0.7-1.7-1.5-2.6-2.2c1.2,1.3,2.3,2.6,3.5,3.9c1.2,2.2,3,3.1,5.4,2.7
								c2.5-0.4,4.8-1.4,6.7-3c1.9-1.6,2.4-3.5,1.6-5.9c-0.7-3-2-5.7-3.7-8.2c-3.6-3.7-7.4-7.9-11.9-10.6c-1.7-1-3.4-1.9-5.1-2.9
								c-4.7-1-9-2.8-13-5.4c-2.3-0.1-4.4,0.5-6.2,2c-2,1.2-3.4,2.9-4.1,5.1c0.2,2.6,1.1,4.9,2.8,6.9
								C671.6,1221.6,673.5,1223.3,675.8,1224.4z"/>
							<path opacity="0.1091" fill="#131313" d="M697.8,1252c1.8,0.5,3.7,0.9,5.5,1.4c-1.3-0.4-2.7-0.8-4-1.2
								c3.4,1.2,6.6,2.9,9.6,4.9c2.9,2,5.5,4.3,7.7,7.1c2.3,2,4.7,2.4,7.5,1.2c2.6-1.3,3.9-3.5,3.9-6.5c-1-5.3-2.7-10.4-5.2-15.2
								c-2.1-5-4.8-9.6-8.2-13.8c-2.8-2.2-5.7-4-8.9-5.6c-3-1.7-6-3.1-9.3-4.3c-4.8-1.8-9.7-3.2-14.7-4.3c-2.8,0.2-4.9,1.6-6.1,4.2
								c-0.9,3.8-0.1,7.2,2.5,10.3c3.2,5.6,6.8,10.8,10.9,15.7C691.1,1249.5,694,1251.5,697.8,1252z"/>
							<path opacity="0.1091" fill="#131313" d="M694.1,1221.1c4.3,3.1,8.9,5.7,13.8,7.8c3.2,1.7,6,3.9,8.5,6.5
								c-1-0.9-1.9-1.9-2.9-2.8c1.2,1.3,2.3,2.7,3.5,4c0.9,1,1.8,1.9,2.7,2.9c1,0.2,2.1,0.4,3.1,0.6c1.2-0.3,2.4-0.6,3.6-1
								c1.9-0.6,3.2-1.7,3.9-3.4c0.9-0.9,1.1-2,0.6-3.3c0-1.6-0.4-3-1.1-4.4c-0.5-1.7-1.2-3.3-2.2-4.8c-0.9-2-2.2-3.8-3.9-5.2
								c-3.3-3.3-6.5-6.6-10.6-9.1c-1.8-1-3.5-2-5.3-3c-4.9-1.8-9.6-4.2-14-7.1c-2.4-0.5-4.6,0-6.5,1.6c-2.1,1.3-3.4,3.2-3.8,5.6
								c0.6,3.1,1.9,6,3.9,8.5C689.2,1217.3,691.4,1219.5,694.1,1221.1z"/>
							<path opacity="0.1091" fill="#131313" d="M657.6,1245c4.4,3.1,9.1,5.8,14,8.1c2.8,1.4,5.4,3.2,7.7,5.4c-1-1-2-2.1-3.1-3.1
								c2.1,2.3,4.2,4.5,6.4,6.8c2.3,1.1,4.6,1.1,6.8-0.1c2.4-0.9,3.9-2.6,4.6-5.1c-0.2-3.6-1.1-7-2.8-10.2
								c-1.3-3.4-3.2-6.3-5.8-8.8c-3.7-2.7-7.3-5.3-11.6-7.1c-1.6-0.7-3.2-1.3-4.8-1.9c-3.9-1.3-7.6-3.2-11-5.7
								c-2.4-0.4-4.5,0.1-6.5,1.6c-2.1,1.3-3.4,3.1-3.8,5.5c0.5,3,1.7,5.8,3.7,8.2C652.8,1241.3,655,1243.4,657.6,1245z"/>
							<path opacity="0.1091" fill="#131313" d="M684.2,1280.7c1.9,0.5,3.7,0.9,5.6,1.4c-1.5-0.6-3-1.3-4.4-1.9
								c3.4,1.4,6.7,3.2,9.7,5.3c2.9,2.1,5.4,4.5,7.5,7.3c1.4,0.9,2.8,1.9,4.2,2.8c1.3-0.1,2.6-0.2,3.9-0.3c0.9-1,1.8-1.9,2.7-2.9
								c1-3.2,0.7-6.3-0.8-9.3c-2.3-7.5-5.6-14.6-9.2-21.6c-1.4-3.3-3.2-6.5-5.3-9.4c-1.3-3.1-3.5-5.2-6.6-6.5
								c-5-2.2-9.9-4.1-15.1-5.7c-1.9-0.6-3.9-1.2-5.8-1.8c-2.5-0.9-5.1-1.5-7.8-1.7c-3-0.1-5.1,1.3-6.1,4.2
								c-0.5,4.6,0.8,8.7,3.8,12.3c4,6.8,8.5,13.4,13.5,19.6C676.4,1276.9,679.8,1279.5,684.2,1280.7z"/>
							<path opacity="0.1091" fill="#131313" d="M711.8,1248.4c1.9,0.5,3.7,1,5.6,1.5c-1.4-0.5-2.8-1-4.2-1.5
								c3.4,1.3,6.6,2.9,9.6,4.9c2.9,2,5.5,4.3,7.7,7c2.2,2.1,4.8,2.5,7.5,1.4c2.7-1.4,3.9-3.6,3.8-6.7c-1.1-5.5-3-10.8-5.6-15.9
								c-2.2-5.2-5.1-10-8.6-14.5c-2.9-2.4-6-4.4-9.4-6.1c-3.1-1.8-6.3-3.4-9.7-4.7c-1.9-0.7-3.8-1.5-5.7-2.2
								c-3.1-1.2-6.2-2.1-9.5-2.7c-2.9,0.1-4.9,1.5-6.1,4.2c-0.7,4.1,0.3,7.8,3.1,11.1c3.5,6.1,7.5,11.8,12,17.2
								C704.6,1245.4,707.7,1247.7,711.8,1248.4z"/>
							<path opacity="0.1091" fill="#131313" d="M667.3,1235.5c3.2,2.3,6.7,4.1,10.4,5.3c2.5,0.7,4.7,1.9,6.7,3.6
								c-1-0.9-2-1.8-3-2.7c2.3,2,4.5,4,6.8,6c-1-1-1.9-1.9-2.9-2.9c2,2.2,4.1,4.4,6.1,6.5c2.3,1,4.5,0.9,6.7-0.3
								c2.3-0.9,3.9-2.5,4.7-4.9c-0.1-3.3-0.9-6.4-2.4-9.4c-1.2-3.1-3-5.8-5.4-8c-3.7-3.1-7.3-6-11.6-8.2c-1.6-1-3.2-1.7-5.1-1.9
								c-3.8-0.8-7.3-2.4-10.5-4.7c-2.4-0.4-4.5,0.2-6.5,1.7c-2.1,1.3-3.4,3.1-3.8,5.5c0.5,3,1.7,5.7,3.6,8.1
								C662.6,1231.9,664.7,1234,667.3,1235.5z"/>
							<path opacity="0.1091" fill="#131313" d="M683.6,1231c3.8,3.6,8.2,6.1,13.2,7.5c3.2,1.5,6.1,3.6,8.6,6
								c-1.1-1.1-2.1-2.3-3.2-3.4c2.2,2.4,4.5,4.8,6.7,7.3c2.3,1.2,4.6,1.2,6.9,0c2.4-1,3.9-2.7,4.5-5.2c-0.3-3.7-1.2-7.2-3-10.5
								c-1.4-3.5-3.4-6.5-6-9.1c-1.7-1.4-3.4-2.8-5.1-4.2c-2.6-2-5.4-3.6-8.4-4.9c-1.6-0.9-3.3-1.6-5.1-2c-4-1-7.6-3-10.7-5.9
								c-2.4-0.7-4.7-0.3-6.7,1.3c-2.2,1.4-3.4,3.3-3.6,5.9c0.8,3.5,2.4,6.7,4.6,9.6C678.2,1226.4,680.6,1229,683.6,1231z"/>
							<path opacity="0.1091" fill="#131313" d="M710.1,1264.7c1.8,0.4,3.6,0.8,5.5,1.3c-1.5-0.7-3.1-1.4-4.6-2.1
								c3.2,1.5,6.4,3,9.6,4.5c-1.5-0.7-3-1.5-4.6-2.2c2.4,1.2,4.6,2.6,6.6,4.4c2.1,1.5,3.9,3.3,5.4,5.3c1.4,0.9,2.7,1.8,4.1,2.7
								c1.3-0.1,2.6-0.2,3.9-0.3c0.9-0.9,1.8-1.9,2.7-2.8c1-3.1,0.8-6.2-0.7-9.1c-2.2-7.3-5.5-14.3-9-21.1c-1.4-3.3-3.2-6.3-5.2-9.2
								c-1.3-3-3.5-5.1-6.5-6.3c-2.2-1.2-4.4-2.4-6.6-3.7c-3.3-1.6-6.6-3-10-4.3c-2.1-0.8-4.1-1.6-6.2-2.3c-2.5-1.1-5.2-1.8-8-1.9
								c-3.1-0.2-5.1,1.2-6.1,4.2c-0.3,4.9,1.2,9.2,4.4,13.1c4.4,7.3,9.3,14.4,14.6,21.1C701.9,1260.3,705.5,1263.3,710.1,1264.7z"
								/>
							<path opacity="0.1091" fill="#131313" d="M660,1258.5c2.4,1.3,4.9,2.7,7.3,4c2.4,0.4,4.6,1.3,6.6,2.7c1.6,0.8,3,1.8,4.3,2.9
								c2.1,1.5,3.9,3.3,5.4,5.3c2.3,1.6,4.7,1.8,7.2,0.6c2.5-1.1,3.9-3.1,4.2-5.8c-0.6-4.5-2-8.8-4.1-12.9
								c-1.7-4.3-4.1-8.1-7.1-11.6c-1.6-1-3.3-2-4.9-3c-2.6-1.5-5.2-2.7-8-3.6c-1.6-0.8-3.3-1.3-5-1.5c-3.4-0.4-6.4-1.7-9.2-3.9
								c-4.7-2.3-10.4,2.3-10.3,7.1c1.1,3.9,2.9,7.5,5.4,10.8C654,1253.1,656.7,1256.1,660,1258.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M740.8,1239.9c1.6-2.4,3.1-4.9,4.7-7.3c-0.8,1.1-1.6,2.2-2.4,3.2
								c3.8-4.6,7.5-9.1,11.9-13.2c5.2-4.9,11-9.1,17-12.8c1.6-1.3,3.4-2.2,5.3-2.8c1.2-0.4,2.4-0.8,3.6-1.1c3.1-0.9,6-1.4,9.2-1.7
								c-2.5,0.1-5.1,0.1-7.6,0.2c4.9,0.1,9.9,0.3,14.8,0.4c-9.9-2.2-19.7-4.3-29.6-6.5c13,4,25.9,7.9,38.9,11.9
								c-9.8-4.9-19.7-9.8-29.5-14.6c7.5,4.5,15,9,22.5,13.4c-6.8-4.6-13.6-9.3-20.4-13.9c7.1,5.4,14.1,10.7,21.2,16.1
								c-6.4-5.4-12.9-10.8-19.3-16.3c6.7,6.2,13.3,12.3,20,18.5c-5.9-6.1-11.7-12.3-17.6-18.4c6,6.9,11.9,13.8,17.9,20.7
								c-5.2-6.8-10.5-13.6-15.7-20.4c5.3,7.6,10.6,15.2,15.9,22.7c-4.5-7.3-8.9-14.6-13.4-21.9c4.4,8.1,8.8,16.2,13.3,24.3
								c-3.7-7.8-7.4-15.6-11.1-23.4c3.6,8.6,7.1,17.2,10.7,25.8c-2.8-8.1-5.6-16.2-8.4-24.2c2.6,8.9,5.1,17.8,7.7,26.7
								c-1.9-8.4-3.8-16.7-5.7-25.1c1.5,9.1,3.1,18.3,4.6,27.4c-0.9-8.4-1.8-16.8-2.7-25.1c0.4,8.9,0.8,17.9,1.2,26.8
								c0.1-8.2,0.2-16.5,0.3-24.7c-0.7,8.8-1.4,17.5-2.1,26.3c1.1-7.8,2.2-15.6,3.4-23.4c-1.8,8.2-3.6,16.5-5.4,24.7
								c2.1-7.3,4.2-14.6,6.3-22c-2.8,7.7-5.6,15.3-8.4,23c3.1-6.6,6.2-13.2,9.3-19.9c-4.2,7-8.3,14-12.5,20.9
								c3.8-5.7,7.5-11.3,11.3-17c-4.9,5.9-9.7,11.9-14.6,17.8c4.4-4.8,8.8-9.5,13.3-14.3c-3.4,2.8-6.8,5.6-10.2,8.4
								c2.5-1.8,4.9-3.5,7.4-5.3c-6,3.7-12.1,7.4-18.1,11.1c5.1-2.8,10.2-5.6,15.3-8.4c-8.2,2.6-15.9,6.2-23.1,10.8
								c-0.6,1.1-1.2,2.2-1.8,3.3c-0.4,2.7,0.4,5,2.4,7c1.9,1.6,4,2,6.3,1.2c3.7-0.2,7.2-1.1,10.7-2.5
								c-4.9-50.6-17.3-69.3-37.4-56.2c-4.4,2.7-8.2,6.3-12,9.8c1.1-0.9,2.1-1.9,3.2-2.8c-4.8,3.8-9.4,7.9-13.8,12.3
								c-3.1,3.2-6.1,6.5-8.9,9.9c-2,2.4-3.9,4.9-5.8,7.3c-3.6,4.5-6.8,9.3-9.6,14.3c-0.8,2.7-0.2,5.1,1.7,7.2c3,2.2,6.3,2.8,10,1.6
								c6-0.8,11.9-2.2,17.6-4.1C736.4,1245.2,739.1,1243.3,740.8,1239.9z"/>
							<path opacity="0.1091" fill="#131313" d="M712.8,1227c6.2-4,11.3-9.1,15.4-15.2c3.9-4.4,8.1-8.3,12.7-11.9
								c4.4-3.5,9.1-6.5,14.1-9.2c2.3-0.9,4.6-1.6,7-2.2c-1.6,0.4-3.1,0.7-4.7,1.1c2.8-0.6,5.5-1.2,8.3-1.8
								c-1.6,0.3-3.2,0.6-4.8,0.8c2.8-0.4,5.6-0.8,8.4-1.2c-1.6,0.2-3.2,0.4-4.9,0.6c2.8-0.2,5.6-0.3,8.3-0.5
								c-1.6,0.1-3.3,0.2-4.9,0.3c1.1,0.1,2.3,0.2,3.4,0.3c-2.3-0.5-4.5-0.9-6.8-1.4c3.3,0.7,6.6,1.4,9.8,2.1
								c-2.2-0.6-4.5-1.3-6.7-1.9c3.2,1.1,6.5,2.2,9.7,3.3c-2.2-0.8-4.5-1.7-6.7-2.5c3.1,1.4,6.1,2.7,9.2,4.1
								c-4.3-2.4-8.6-4.9-12.8-7.3c2.5,1.7,5,3.4,7.4,5.1c-1.9-1.6-3.9-3.1-5.8-4.7c2.3,2,4.6,4.1,6.8,6.1c-1.8-1.8-3.6-3.6-5.4-5.5
								c2.1,2.4,4.2,4.7,6.3,7.1c-1.6-2-3.3-4.1-4.9-6.1c1.8,2.6,3.6,5.3,5.5,7.9c-1.4-2.3-2.9-4.5-4.3-6.8c1.6,2.9,3.2,5.7,4.8,8.6
								c-1.2-2.5-2.4-4.9-3.7-7.4c1.3,3.1,2.6,6.2,3.9,9.3c-1-2.6-2-5.3-3-7.9c1,3.2,2.1,6.5,3.1,9.7c-0.7-2.8-1.4-5.6-2.1-8.4
								c0.6,3.4,1.3,6.9,1.9,10.3c-0.4-3-0.8-5.9-1.3-8.9c0.3,3.6,0.7,7.1,1,10.7c-0.1-3.1-0.2-6.1-0.2-9.2
								c-0.1,3.6-0.3,7.1-0.4,10.7c0.8-5.3,1.6-10.6,2.4-15.9c-2.3,9.3-4.6,18.7-6.8,28c2-6.2,3.9-12.3,5.9-18.5
								c-2.7,6.4-5.4,12.9-8.1,19.3c3-5.9,5.9-11.8,8.9-17.7c-2.2,3.3-4.4,6.7-6.7,10c2-2.6,4-5.3,6-7.9c-2.8,2.9-5.5,5.9-8.3,8.8
								c2.4-2.4,4.9-4.8,7.3-7.1c-3.2,2.6-6.5,5.3-9.7,7.9c2.8-2,5.6-4,8.4-6c-3.6,2.1-7.2,4.2-10.8,6.3c3.1-1.6,6.3-3.2,9.4-4.8
								c-8.4,2.9-16.4,6.6-24,11.1c-2.7,0.9-4.6,2.7-5.7,5.5c0.1,1.3,0.1,2.6,0.2,3.9c0.8,1,1.6,2,2.4,3.1c2.6,1.4,5.2,1.6,7.9,0.5
								c4.7-0.7,9.2-2,13.6-3.9c-3.6,2.1-7.1,4.2-10.7,6.3c3,0.5,5.9,0.5,8.9,0c4.3-0.5,8.2-1.5,12.1-3.6c1.6-0.9,3.2-1.9,4.8-2.8
								c1.7-1.3,3.3-2.8,4.7-4.4c1.5-1.5,2.8-3.1,3.9-5c1-2.8,2-5.5,3-8.3c0.3-2.8,0.6-5.6,1-8.4c0.2-5.5-1-10.8-3.5-15.8
								c-2.6-3.5-5.7-6.4-9.3-8.8c-3.4-2.7-7.2-4.7-11.3-6.1c-5.2-1-10.3-1.2-15.6-0.6c-2.8,0.6-5.7,1.3-8.5,1.9
								c-5.1,1.5-9.9,3.9-14.4,6.6c-7.4,4.4-14.1,9.8-20.3,15.7c-3.5,3.2-6.7,6.6-9.7,10.3c-3.6,6-8.2,11.1-14,15.2
								c-1.5,2-1.9,4.3-1.2,6.7c0.5,2.5,1.8,4.3,4.1,5.5c3.5,0.5,7,0.2,10.5-0.8C706.7,1230.3,709.9,1229,712.8,1227z"/>
							<path opacity="0.1091" fill="#131313" d="M718.7,1192.2c5.7-7.1,11.1-14.7,18-20.7c2.2-1.9,4.5-3.8,6.7-5.6
								c4.6-3.5,9.4-6.6,14.5-9.3c-1.6,0.5-3.2,1-4.8,1.6c2.8-0.9,5.6-1.8,8.5-2.7c-1.6,0.4-3.1,0.9-4.7,1.3
								c2.8-0.8,5.7-1.6,8.5-2.4c-1.6,0.4-3.1,0.7-4.7,1.1c2.8-0.6,5.7-1.1,8.5-1.7c-1.5,0.3-3,0.6-4.6,0.9c2-0.5,4-0.6,6-0.5
								c2-0.1,3.9,0.1,5.9,0.5c-1.9-0.4-3.8-0.8-5.8-1.1c3.2,0.8,6.3,1.6,9.5,2.5c-1.8-0.5-3.6-1-5.5-1.4c1.3,0.5,2.6,1,3.9,1.5
								c-1.6-0.9-3.2-1.7-4.8-2.6c2.8,1.6,5.6,3.2,8.3,4.8c-2.4-1.9-4.7-3.9-7.1-5.8c1.5,1.7,2.9,3.3,4.4,5c-0.7-1-1.5-2-2.2-3
								c1.2,1.8,2.5,3.6,3.7,5.4c-0.6-1.1-1.2-2.1-1.8-3.2c0.9,2,1.7,4,2.6,6c-0.4-1.1-0.9-2.2-1.3-3.2c0.6,2,1.2,4.1,1.9,6.1
								c-0.3-1.1-0.6-2.2-1-3.3c0.4,2,0.7,4,1.1,6c-0.2-1.1-0.4-2.2-0.5-3.3c0.1,2,0.3,4,0.4,6c-0.1-1.1-0.1-2.2-0.2-3.3
								c-0.1,1.9-0.2,3.9-0.2,5.8c0.1-1.1,0.1-2.2,0.2-3.3c-0.3,1.9-0.6,3.8-0.9,5.8c0.2-1.1,0.4-2.2,0.6-3.3
								c-0.7,1.8-1.3,3.5-2.5,5c-0.3,0.5-0.8,0.9-1.3,1.3c-1.4,1-2.7,2.2-3.9,3.5c2-1,3.9-2,5.9-3c-4-0.2-8,0.2-11.9,1.1
								c-5.1,1-10,2.5-14.8,4.6c-2.9,0.5-4.7,2-5.7,4.4c-1.2,1.6-1.4,3.4-0.6,5.4c0.8,2.6,2.5,4.3,5.2,4.9c1.7,0.5,3.3,1,5,1.5
								c3,0.8,6.1,1.2,9.2,1.2c3.7,0.1,7.4-0.1,11-0.9c2.4-0.4,4.7-1.2,6.7-2.7c1.5-1,2.9-2.1,4.4-3.1c2.5-2,4.6-4.4,6.4-7.1
								c2.3-3.3,3.4-7.1,4.4-10.9c0.9-3.5,1.5-7.4,1.4-11.1c0-2.9-0.1-6.1-0.8-8.9c-0.5-1.4-1-2.7-1.5-4.1c-1.2-1.8-2.5-3.5-3.9-5.1
								c-0.9-1.3-2-2.4-3.2-3.4c-1.1-1.2-2.4-2.1-3.9-2.6c-3.9-2.1-8.4-3.6-12.7-4.6c-1.9-0.4-3.8-0.8-5.8-1.1c-1.8,0-3.6,0-5.4,0
								c-4.2,0.2-8.4,0.7-12.5,1.7c-3.6,0.9-7,1.9-10.4,3.4c-2.6,1.1-5.1,2.2-7.5,3.5c-2.9,1.9-5.8,3.7-8.6,5.6
								c-5.5,3.9-10.7,8.3-15.6,12.9c-6.9,6.6-12.5,14.4-18.4,21.9c-1.9,1.9-2.5,4.2-1.9,6.9c0.6,2.7,2.2,4.4,4.8,5.3
								c4.4,0.3,8.7-0.2,12.9-1.4C710.9,1196,715,1194.5,718.7,1192.2z"/>
							<path opacity="0.1091" fill="#131313" d="M739.1,1215.6c1.6-2.5,3.3-4.9,4.9-7.4c-0.9,1.1-1.8,2.2-2.7,3.3
								c2.8-3.4,5.7-6.7,8.5-10.1c-0.9,1.1-1.8,2.2-2.8,3.3c3.9-4.4,8.2-8.4,12.8-12c4.4-3.5,9.1-6.6,14.1-9.4
								c4.6-1.9,9.5-2.9,14.4-3.5c-2.4,0.1-4.7,0.2-7.1,0.3c3.6-0.1,7.1-0.2,10.7-0.3c-2.3,0-4.7-0.1-7-0.1
								c3.5,0.2,7.1,0.4,10.6,0.6c-2.3-0.2-4.7-0.4-7-0.6c1.1,0.2,2.3,0.4,3.4,0.6c-3.1-0.9-6.3-1.9-9.4-2.8c4.2,1.3,8.5,2.6,12.7,4
								c-3.1-1.2-6.1-2.4-9.2-3.5c4,1.7,8,3.4,12,5.1c-5.7-3.2-11.5-6.3-17.2-9.5c6.1,3.8,12.3,7.6,18.4,11.4
								c-5.3-3.7-10.5-7.3-15.8-11c5.6,4.4,11.2,8.7,16.8,13.1c-4.8-4.2-9.6-8.3-14.4-12.5c5.1,5,10.1,10,15.2,14.9
								c-4.2-4.6-8.4-9.1-12.6-13.7c4.4,5.4,8.8,10.8,13.2,16.1c-3.6-4.9-7.3-9.9-10.9-14.8c2.1,3.3,4.1,6.6,6.2,9.9
								c-1.4-2.6-2.7-5.3-4.1-7.9c1.7,3.4,3.3,6.8,5,10.3c-1.1-2.8-2.1-5.5-3.2-8.3c1.2,3.6,2.5,7.2,3.7,10.7
								c-0.7-2.8-1.5-5.6-2.2-8.4c0.8,3.6,1.6,7.2,2.4,10.9c-0.4-2.8-0.8-5.7-1.2-8.5c0.3,3.7,0.7,7.4,1,11.1
								c-0.1-2.8-0.1-5.6-0.2-8.4c-0.1,3.5-0.2,7.1-0.3,10.6c0.3-2.7,0.6-5.4,0.8-8.1c-0.6,3.5-1.3,7-1.9,10.5
								c0.6-2.5,1.2-5.1,1.8-7.6c-1.1,3.3-2.2,6.5-3.3,9.8c0.9-2.4,1.9-4.8,2.8-7.2c-2.4,5.3-4.8,10.6-7.2,15.8
								c2.4-4.5,4.8-8.9,7.2-13.4c-1.3,1.8-2.9,3.3-4.7,4.6c1.5-0.9,3-1.8,4.5-2.7c-2.4,1.4-4.8,2.7-7.2,4.1
								c1.6-0.6,3.2-1.3,4.8-1.9c-5.4,2.2-10.9,4.2-16.5,6c-4,1.1-7.7,2.8-11.1,5.2c-1.4,1.5-1.9,3.2-1.4,5.1c-0.1,2,0.6,3.7,2,5.2
								c2.4,2.1,5.1,2.6,8.2,1.4c3.1,1.4,6.2,1.9,9.4,1.5c3.1,0.1,6.2-0.5,9.1-1.8c3.4-2.5,6.4-5.5,8.7-9c2.7-3.3,4.7-7,6-11
								c0.8-9.1,1.5-18.2,2.3-27.3c-0.6-1.3-1.1-2.6-1.7-3.9c-2.9-2.8-6.1-5.1-9.6-7c-2.7-1.8-5.6-3.4-8.6-4.8
								c-3.5-2-7.1-3.5-11-4.4c-5.2-0.2-10.3,0.3-15.3,1.5c-3.3,0.8-6.5,1.9-9.6,3.2c-5.5,2.3-10.6,5.4-15.5,8.8
								c-8.8,6.2-16.8,13.1-24,21c-3.2,3.4-6.2,7-9.1,10.7c-3.8,4.5-7.1,9.3-10.1,14.4c-0.9,2.7-0.3,5.1,1.7,7.2
								c3.3,2.2,7,2.6,10.9,1.3c6.6-1,13.1-2.5,19.4-4.6C734,1221.1,737.1,1219.1,739.1,1215.6z"/>
							<path opacity="0.1091" fill="#131313" d="M740.8,1186.5c3.1-5.1,6.7-9.9,10.8-14.2c-1,1.1-2.1,2.2-3.1,3.4
								c3.9-4.4,8.1-8.3,12.7-11.9c4.4-3.5,9.1-6.6,14.1-9.3c4.6-1.9,9.5-2.9,14.5-3.5c-2.1,0.1-4.3,0.3-6.4,0.4
								c4.6,0.1,9.2,0.1,13.8,0.2c-4.7-0.7-9.5-1.4-14.2-2c4,0.9,8,1.8,12.1,2.6c-2.6-0.7-5.2-1.5-7.7-2.2c4.9,1.8,9.7,3.6,14.6,5.4
								c-4.3-2.4-8.6-4.8-12.9-7.3c2.9,2,5.7,4.1,8.6,6.1c-1.8-1.4-3.6-2.9-5.4-4.3c2.5,2.2,5.1,4.4,7.6,6.6c-1.5-1.6-3-3.1-4.5-4.7
								c1.7,2.3,3.2,4.6,4.6,7.1c-0.4-1.3-0.9-2.6-1.3-3.9c0.7,2.7,1.5,5.3,2.2,8c-0.3-1.3-0.5-2.5-0.8-3.8c0.5,2.6,0.9,5.2,1.4,7.9
								c-0.1-1.2-0.2-2.5-0.3-3.7c0,2.3,0,4.6,0.1,6.9c0-1.2,0.1-2.4,0.1-3.6c-0.2,2.2-0.5,4.4-0.7,6.7c0.2-1.1,0.3-2.3,0.5-3.4
								c-0.6,2-1.1,4-1.7,6c0.3-1.1,0.6-2.2,0.9-3.2c-0.8,1.8-1.6,3.7-2.4,5.5c0.4-1,0.9-2,1.3-3c-1.6,1.9-3,3.5-5.2,4.8
								c-2.5,1.4-4.8,1.6-7.6,1.8c1,0.2,1.9,0.4,2.9,0.6c-2.9-1.1-5.8-1.4-8.8-0.8c-3.7,0.5-7.2,1.5-10.6,3.1
								c-2.2,0.5-3.5,1.8-3.9,3.8c-0.8,1.6-0.9,3.4-0.1,5.2c0.6,2.4,1.9,4.2,4.1,5.3c3.5,1.1,6.9,2,10.5,2.4
								c4.9,0.5,10.1-0.6,14.8-1.9c2-0.4,3.8-1,5.5-2.1c1.7-0.7,3.3-1.6,4.8-2.8c2.7-1.9,5.2-3.8,7.3-6.3c1.5-1.7,2.8-3.6,3.9-5.7
								c0.5-1.5,1.1-3,1.6-4.6c0.4-1.5,0.9-2.9,1.3-4.4c0.1-1.8,0.2-3.5,0.3-5.3c0.1-1.7,0.1-3.4,0.2-5c-0.4-1.3-0.9-2.6-1.3-3.9
								c-1.9-4.4-3.7-8.9-5.6-13.3c-2-2.3-4.3-4.2-7-5.6c-3.9-2.5-7.9-4.7-12.3-6c-1.8-0.6-3.7-1-5.6-1.2c-3-0.6-6.1-0.9-9.3-0.7
								c-3.1-0.5-6.2-0.4-9.2,0.3c-3,0.4-5.9,1-8.7,1.8c-3.7,1.2-7.5,2.6-11,4.2c-9.9,4.7-18.7,11.4-26.9,18.5
								c-5,4.4-9.5,9.3-13.9,14.3c-2.2,2.4-4.4,4.9-6.4,7.6c-2.9,3.4-5.4,7-7.6,10.9c-1,2.8-0.5,5.2,1.7,7.2c3.8,2.2,7.8,2.5,12.1,1
								c7.3-1.2,14.5-2.9,21.5-5.1C734.9,1192.3,738.4,1190.1,740.8,1186.5z"/>
							<path opacity="0.1091" fill="#131313" d="M701.3,1214c3.9-3.7,7.5-7.6,10.8-11.8c2.7-3.6,5.7-6.8,9.2-9.7
								c2.2-1.8,4.5-3.7,6.7-5.5c4.5-3.4,9.2-6.4,14.2-9c-1,0.5-2.1,0.9-3.1,1.4c2.9-1,5.8-2,8.8-2.6c3.1-0.6,5.9-0.7,9.1-0.6
								c-1.5-0.2-2.9-0.3-4.4-0.5c2.6,0.3,5.3,0.7,7.9,1c-1.5-0.2-2.9-0.5-4.4-0.7c2.3,0.4,4.5,1.2,6.5,2.3c-1.3-0.7-2.7-1.5-4-2.2
								c2.3,1.4,4.6,2.8,6.9,4.2c-2-1.8-4.1-3.5-6.1-5.3c1.7,3,3.5,6,5.2,9c-0.4-1.3-0.8-2.5-1.1-3.8c0.3,2.8,0.5,5.7,0.8,8.5
								c0.2-1.6,0.3-3.1,0.5-4.7c-0.4,2.2-0.9,4.3-1.3,6.5c1.4-2.4,2.9-4.8,4.3-7.2c-11.2,9-22.4,18-33.5,26.9
								c8.6-5.3,17.2-10.6,25.8-15.9c-8.9,3-17.3,6.8-25.4,11.4c-2.9,1-5.1,2.9-6.4,5.8c0,1.3,0,2.6,0.1,3.9
								c0.9,2.3,2.5,3.5,4.8,3.5c3.4,1.1,6.8,0.8,10.3-1.1c-0.3,1.4-0.6,2.7-1,4.1c3.1,0.5,6.3,0.5,9.4,0.1c3-0.1,5.9-0.6,8.7-1.6
								c7.7-2.4,15.3-7.9,19.2-15.1c3.5-6.6,5.4-14.6,4-22c-0.6-3.5-1.8-6.8-3.5-10c-1.7-3.3-4.2-5.5-7.3-7.6
								c-3.6-2.5-8.5-4.5-12.8-5.4c-4.4-0.9-9-1.2-13.5-0.7c-7.2,0.9-14.1,3.1-20.7,6.3c-4.1,2.3-8,4.9-11.7,7.7
								c-3.9,2.7-7.5,5.7-10.9,8.9c-2.2,2.2-4.5,4.5-6.7,6.7c-3.9,5.4-8.1,10.6-12.7,15.5c-1,2.1-1.2,4.3-0.5,6.5
								c0.4,2.3,1.5,4.2,3.4,5.6c2.5,0.7,5.1,0.7,7.6-0.1C697.1,1216.9,699.4,1215.8,701.3,1214z"/>
							<path opacity="0.1091" fill="#131313" d="M722.2,1246.1c2.8-2.7,5.5-5.5,8.3-8.2c1.7-2.2,3.3-4.4,5-6.5
								c2-2.1,4.1-4.2,6.1-6.3c3.1-2.9,6.3-5.6,9.8-8c2.2-1.7,4.5-3.2,6.9-4.6c1.6-1.3,3.3-2.2,5.2-2.7c2.8-1,5.6-1.7,8.5-2.3
								c-1.8,0.3-3.5,0.5-5.3,0.8c4.1-0.4,8.2-0.7,12.3-1.1c-1.9,0-3.8,0.1-5.7,0.1c2.1,0,4.2,0.3,6.3,0.8c-2.7-0.8-5.4-1.6-8.2-2.4
								c3.7,1.2,7.4,2.5,11,3.7c-2.8-1.1-5.5-2.2-8.3-3.2c3.6,1.6,7.1,3.2,10.7,4.7c-5.4-3.1-10.9-6.1-16.3-9.2
								c5.7,3.6,11.5,7.3,17.2,10.9c-5.3-3.7-10.6-7.5-15.8-11.2c2.8,2.3,5.7,4.7,8.5,7c-2.5-2.3-4.9-4.7-7.4-7
								c5.2,5.2,10.5,10.4,15.7,15.6c-4.8-5.2-9.5-10.3-14.3-15.5c2.5,3.1,4.9,6.2,7.4,9.3c-2.1-3-4.1-6-6.2-9
								c4.3,6.6,8.7,13.3,13,19.9c-3.8-6.4-7.6-12.9-11.4-19.3c3.7,7.3,7.3,14.6,11,21.9c-3.2-7.1-6.4-14.1-9.7-21.2
								c3.1,7.8,6.1,15.7,9.2,23.5c-2.5-7.5-5-15-7.5-22.6c2.2,8.4,4.4,16.7,6.5,25.1c-1.7-8-3.5-16-5.2-24c1.3,8.8,2.7,17.6,4,26.3
								c-0.8-8.3-1.6-16.5-2.5-24.8c0.2,8.8,0.5,17.7,0.7,26.5c0.1-8.4,0.3-16.8,0.4-25.2c-0.8,8.9-1.6,17.8-2.4,26.6
								c1.2-8.2,2.4-16.4,3.6-24.6c-2,8.6-4,17.2-6,25.8c2.3-8,4.5-15.9,6.8-23.9c-3,8.2-6.1,16.4-9.1,24.7
								c3.4-7.4,6.8-14.9,10.2-22.3c-4.4,7.7-8.9,15.4-13.3,23.1c4.3-6.6,8.6-13.2,12.9-19.8c-5.4,6.7-10.8,13.4-16.2,20.1
								c5.2-5.8,10.4-11.5,15.6-17.3c-6.4,5.7-12.7,11.3-19.1,17c5.7-4.7,11.5-9.3,17.2-14c-6.9,4.5-13.8,9-20.6,13.5
								c6.3-3.6,12.5-7.2,18.8-10.9c-8.5,3.2-16.6,7.2-24.4,11.8c-2.7,0.9-4.5,2.7-5.5,5.4c-0.6,2.2-0.2,4,1.4,5.4
								c1,2,2.8,2.9,5.3,2.7c2.4-0.4,4.8-0.9,7.1-1.3c4.9-1.3,9.8-3,14.4-5.1c20.7-6.8,18.3-25.7-7-56.6c-3.9-1.8-7.8-1.9-11.7-0.1
								c-3.2,0.4-6.2,1.3-9.1,2.7c-1.2,0.6-2.4,1.1-3.6,1.7c-3.7,1.8-7.1,4.2-10.4,6.6c-7.2,5.3-14.2,11-20.2,17.6
								c-2,2.3-4,4.6-6,6.9c-3.2,5.5-7.2,10.4-12,14.7c-3.2,3.4-1.3,10.4,2.9,12.2c3.5,0.5,7,0.3,10.4-0.7
								C716.1,1249.4,719.3,1248.1,722.2,1246.1z"/>
							<path opacity="0.1091" fill="#131313" d="M754.7,1211.7c2.2-4,4.8-7.6,7.9-10.8c2.8-3.4,5.9-6.6,9.3-9.6
								c-1.3,1.1-2.6,2.3-3.9,3.4c3.5-3,7.1-5.9,10.6-8.9c-1.3,1.1-2.7,2.2-4,3.3c3.3-2.7,6.8-5,10.4-7.1c2.1-1.6,4.4-2.7,7-3.3
								c3.6-1.1,7.1-1.8,10.8-2.2c-3,0-6,0.1-9,0.1c4.2,0,8.3,0,12.5-0.1c-3-0.1-5.9-0.3-8.9-0.4c5.2,0.7,10.4,1.4,15.5,2
								c-6.8-1.7-13.5-3.3-20.3-5c5,1.5,10,3,15,4.6c-7.4-3.2-14.8-6.3-22.2-9.5c8.8,4.3,17.5,8.5,26.3,12.8
								c-6.3-4.3-12.6-8.5-18.9-12.8c6.6,4.9,13.3,9.9,19.9,14.8c-5.6-4.8-11.3-9.5-16.9-14.3c5.9,5.6,11.9,11.1,17.8,16.7
								c-4.9-5.1-9.7-10.3-14.6-15.4c5.1,5.9,10.2,11.8,15.3,17.7c-4.1-5.4-8.2-10.9-12.3-16.3c4.2,6.3,8.4,12.6,12.6,18.9
								c-3.3-5.5-6.6-11.1-9.8-16.6c3.4,6.4,6.8,12.9,10.1,19.3c-2.5-5.7-5.1-11.3-7.6-17c1.4,3.9,2.8,7.7,4.3,11.6
								c-0.8-2.8-1.5-5.7-2.3-8.5c1.6,6.6,3.3,13.1,4.9,19.7c-1.1-5.6-2.2-11.1-3.3-16.7c0.4,3.9,0.7,7.8,1.1,11.7
								c-0.1-2.7-0.1-5.4-0.2-8.1c-0.1,3.7-0.1,7.3-0.2,11c0.3-2.5,0.6-5.1,0.8-7.6c-0.7,3.5-1.4,7-2.1,10.5
								c0.6-2.3,1.1-4.6,1.7-6.9c-1.1,3.2-2.2,6.4-3.3,9.7c0.9-2.1,1.7-4.2,2.6-6.3c-1.5,2.9-3.1,5.8-4.6,8.7
								c2.2-2.9,4.4-5.9,6.6-8.8c-2.2,2-4.3,4-6.5,6.1c1.2-0.9,2.3-1.8,3.5-2.6c-2.3,1.6-4.7,3.2-7,4.9c1.3-0.7,2.5-1.3,3.8-2
								c-2.4,1-4.9,2-7.3,3c1.3-0.4,2.6-0.9,3.9-1.3c-2.5,0.6-5,1.2-7.5,1.9c1.4-0.2,2.7-0.4,4.1-0.6c-2.6,0.2-5.2,0.5-7.7,0.7
								c-3.6,0.6-7.1,1.7-10.4,3.3c-2.2,0.6-3.4,1.9-3.7,3.8c-0.8,1.7-0.8,3.4,0,5.2c0.6,2.4,2,4.2,4.2,5.2c3,1.1,6.1,1.7,9.3,1.6
								c3.2,0.3,6.4,0.3,9.6,0c2.1-0.6,4.3-1.2,6.4-1.8c3-0.5,5.7-1.8,7.9-3.8c1.6-0.8,2.8-2,3.5-3.7c1.9-2.5,3.2-5.2,4-8.3
								c0.6-2.2,1.2-4.4,1.8-6.5c0.7-8.6,1.1-17.2,1.1-25.9c-0.8-1.4-1.5-2.8-2.3-4.2c-3.7-3.1-7.6-5.8-11.9-8.1
								c-3.4-2.1-6.8-4-10.5-5.6c-4.2-2.3-8.6-4.2-13.3-5.5c-5.8,0.4-11.4,1-17,2.6c-7.9,2.2-15.1,5.9-21.8,10.5
								c-7.9,5.4-15.3,11.9-22.1,18.6c-4.8,4.7-9.2,9.6-13.4,14.9c-2,2.5-4,5-6,7.6c-2.8,3.3-5.1,6.9-7.1,10.8
								c-1.3,2.8-0.8,5.2,1.7,7.2c4.5,2.1,9.1,2.3,14,0.6c8.5-1.5,17-3.4,25.2-6C747.6,1217.8,751.6,1215.5,754.7,1211.7z"/>
							<path opacity="0.1091" fill="#131313" d="M709.2,1203c4.1-3.4,7.7-7.2,10.7-11.4c2.5-3.6,5.5-6.8,8.9-9.7
								c2.2-1.9,4.5-3.7,6.7-5.6c4.6-3.5,9.4-6.5,14.5-9.2c-1.4,0.5-2.7,1-4.1,1.5c2.6-0.9,5.2-1.8,7.8-2.7
								c-1.4,0.4-2.7,0.9-4.1,1.3c3.9-1,7.9-1.9,11.8-2.9c-1.4,0.3-2.7,0.6-4.1,0.9c2.6-0.5,5.2-0.6,7.8-0.4
								c-1.8-0.2-3.6-0.4-5.5-0.7c3.1,0.4,6.1,0.8,9.2,1.2c-1.8-0.3-3.6-0.7-5.3-1c2.9,0.7,5.8,1.5,8.6,2.2
								c-1.7-0.4-3.4-0.9-5.2-1.3c1.1,0.5,2.2,0.9,3.4,1.4c-1.6-0.8-3.1-1.7-4.7-2.5c2.6,1.5,5.1,2.9,7.7,4.4
								c-1.5-1-2.9-1.9-4.4-2.9c0.5,0.5,1,1.1,1.5,1.6c-0.7-1-1.5-2.1-2.2-3.1c1.2,1.7,2.3,3.3,3.5,5c-0.6-1.1-1.3-2.2-1.9-3.3
								c1.1,2.6,2.2,5.1,3.2,7.7c-0.4-1.2-0.8-2.4-1.1-3.7c0.5,1.8,1,3.6,1.6,5.5c-0.2-1.3-0.5-2.5-0.7-3.8c0.3,1.9,0.6,3.8,0.9,5.7
								c-0.1-1.3-0.2-2.6-0.3-3.9c0.1,2.3,0.2,4.6,0.3,6.9c0.2-1.4,0.4-2.8,0.6-4.1c-0.8,2.6-1.6,5.3-2.3,7.9c0.7-1.3,1.4-2.7,2.1-4
								c-2.3,3.8-4.7,7.6-7,11.4c2.4-3.2,4.9-6.4,7.3-9.7c-1.6,1.4-3.2,2.9-4.8,4.3c1.4-1.1,2.8-2.3,4.1-3.4
								c-8.9,3.7-17.7,7.4-26.6,11c-2,1.1-4.1,2.3-6.1,3.4c-1.9,1.5-2.7,3.3-2.2,5.4c-0.3,1.4,0.1,2.6,1.2,3.5c1,2,2.7,2.9,5.1,2.9
								c0.3,0.2,0.6,0.5,0.9,0.7c2.8,0.8,5.6,1.3,8.4,1.5c2.6,0.2,5.2,0.4,7.8,0.1c2.1-0.3,4.2-0.6,6.3-0.9c1.7-0.5,3.5-1.1,5.2-1.6
								c1.4-0.8,2.8-1.6,4.2-2.5c2.1-1.2,4-2.7,5.7-4.4c2.7-2.5,5-5.4,6.3-8.8c1-2.6,1.8-5.3,2.2-8.1c0.5-3.5,0.6-7.1,0.3-10.6
								c0-1.5-0.2-2.9-0.6-4.3c-0.1-2-0.9-3.8-2.3-5.4c-1.7-2.4-3.4-4.9-5.1-7.3c-1-0.9-1.9-1.8-2.9-2.6c-3.2-2.5-7.9-3.7-11.7-4.9
								c-4.1-1.3-8.1-1.9-12.4-1.7c-3,0.1-6,0.5-9,1c-10.1,1.9-19.4,6.2-27.8,12.2c-5.3,3.8-10.4,8-15.2,12.5
								c-3.5,3.2-6.6,6.7-9.3,10.6c-2.9,4.2-6.2,7.9-10,11.3c-3.3,3.5-1.3,10.4,2.9,12.2c3.6,0.5,7.1,0.2,10.6-0.8
								C703,1206.4,706.2,1205,709.2,1203z"/>
							<path opacity="0.1091" fill="#131313" d="M730.4,1197.8c1.5-2.4,3.1-4.9,4.6-7.3c-0.8,1.1-1.6,2.2-2.4,3.2
								c3.6-4.7,7.6-9.1,11.9-13.2c4.2-3.9,8.8-7.5,13.6-10.7c3.2-2.6,6.8-4.5,10.8-5.6c2.4-0.9,4.9-1.5,7.4-1.8
								c-1.9,0.3-3.9,0.5-5.8,0.8c3.2-0.4,6.4-0.8,9.6-1.2c-1.9,0.2-3.8,0.3-5.7,0.5c3.1-0.1,6.3-0.3,9.4-0.4
								c-1.9,0.1-3.7,0.1-5.6,0.2c1.3,0.1,2.5,0.1,3.8,0.2c-2.5-0.5-5-1.1-7.5-1.6c3.7,0.8,7.4,1.6,11.1,2.4
								c-2.4-0.7-4.8-1.4-7.2-2.1c4.5,1.7,9.1,3.4,13.6,5.1c-4.2-2.4-8.4-4.7-12.5-7.1c2.7,1.9,5.3,3.7,8,5.6
								c-1.8-1.5-3.6-2.9-5.4-4.4c2.4,2.1,4.8,4.2,7.1,6.3c-1.6-1.6-3.1-3.2-4.7-4.8c2,2.5,4.1,4.9,6.1,7.4c-1.3-1.7-2.7-3.4-4-5.2
								c1.7,2.6,3.4,5.2,5.1,7.7c-1.1-1.8-2.2-3.6-3.3-5.5c1.8,3.6,3.2,7.3,4.2,11.2c-0.6-1.9-1.3-3.8-1.9-5.8
								c0.5,2.8,0.7,5,0.4,7.8c0.3-1.4,0.7-2.8,1-4.2c-0.6,2.2-1.1,4.3-1.7,6.5c0.5-1.3,1-2.7,1.5-4c-0.8,2-1.7,4-2.5,6
								c0.7-1.2,1.4-2.5,2.1-3.7c-1.1,1.8-2.3,3.7-3.4,5.5c0.9-1.1,1.7-2.3,2.6-3.4c-1.2,1.6-2.5,3.3-3.7,4.9c1-1,2.1-2,3.1-3.1
								c-1.8,1.3-3.5,2.7-5.3,4c1.2-0.9,2.4-1.7,3.6-2.6c-2.6,1.2-5.3,2.4-8.1,3.3c1.5-0.6,3-1.1,4.5-1.7c-5,1-9.8,2.4-14.7,3.8
								c-3.3,0.8-6.4,2.1-9.2,3.9c-2.1,0.7-3.4,2.2-3.9,4.6c0.1,1.3,0.2,2.5,0.3,3.8c0.7,2.6,2.4,4.3,5,5c3.7,1.2,7.5,1.9,11.4,2.1
								c2,0,3.9,0,5.9,0c4-0.4,7.8-1.2,11.5-2.5c2.4-1.2,4.6-2.6,6.6-4.3c2-1.4,3.7-3.1,5.3-4.9c1.5-1.8,2.7-3.8,3.6-6
								c1.8-3.4,2.8-7.1,3-11.1c-0.7-8.6-2.8-16.9-6.5-24.8c-2.8-3.1-6.1-5.6-9.8-7.5c-3.6-2.2-7.4-3.8-11.5-4.6
								c-5.4-0.9-10.8-1.3-16.3-1c-3.6,0.4-7.2,1.1-10.7,2.1c-6.2,1.8-11.8,4.6-17.4,7.8c-9.5,5.5-18.2,12.7-25.8,20.6
								c-4.5,4.7-8.6,9.6-12.5,14.7c-2.6,3.3-4.9,6.7-6.9,10.3c-0.8,2.7-0.2,5.1,1.7,7.2c3,2.2,6.4,2.7,10.1,1.5
								c6.1-0.9,12-2.3,17.8-4.2C725.9,1203.2,728.7,1201.2,730.4,1197.8z"/>
							<path opacity="0.1091" fill="#131313" d="M751.1,1226.9c3.1-5.2,6.7-9.9,10.9-14.2c-1.1,1.1-2.2,2.3-3.4,3.4
								c3.2-3.2,6.5-6.4,9.7-9.6c-1.2,1.1-2.3,2.2-3.5,3.3c4.2-3.9,8.8-7.5,13.6-10.6c2.7-2.3,5.7-4,9-4.9c4.2-1.4,8.4-2.3,12.8-2.8
								c-11.3-1.1-22.6-2.1-33.8-3.2c16.7,3.1,33.4,6.1,50.1,9.2c-10.5-5.2-20.9-10.3-31.4-15.5c8,4.7,15.9,9.4,23.9,14
								c-7-4.7-14.1-9.5-21.1-14.2c7.3,5.4,14.7,10.8,22,16.3c-6.5-5.4-13-10.8-19.4-16.2c6.7,6.2,13.5,12.3,20.2,18.5
								c-5.7-6-11.5-11.9-17.2-17.9c5.9,6.7,11.8,13.3,17.6,20c-5-6.4-9.9-12.9-14.9-19.3c5,7.2,10.1,14.4,15.1,21.7
								c-4.1-6.7-8.2-13.4-12.3-20.1c4.1,7.5,8.2,15,12.4,22.5c-3.3-7-6.5-14-9.8-20.9c3.2,7.8,6.4,15.6,9.5,23.5
								c-2.4-7-4.8-14.1-7.2-21.1c2.2,7.9,4.5,15.8,6.7,23.7c-1.5-7.1-3.1-14.2-4.6-21.4c1.2,8,2.4,15.9,3.6,23.9
								c-0.7-7-1.3-13.9-2-20.9c0.2,7.6,0.5,15.2,0.7,22.8c0.2-6.7,0.4-13.3,0.6-20c-0.8,7.3-1.6,14.7-2.5,22c1-6.2,2-12.3,3-18.5
								c-1.7,6.8-3.4,13.6-5.1,20.3c1.8-5.7,3.6-11.3,5.3-17c-2.5,6.2-5,12.3-7.5,18.5c2.6-5,5.1-10,7.7-15.1
								c-2.4,3.3-4.8,6.6-7.3,9.9c1.6-2,3.2-4.1,4.9-6.1c-2.7,2.8-5.4,5.6-8.1,8.4c1.8-1.7,3.7-3.3,5.5-5c-4.2,2.8-8.6,5-13.3,6.7
								c2.1-0.9,4.1-1.8,6.2-2.7c-3.1,1-6.2,2-9.3,3c-2.9,0.6-5.5,1.8-7.9,3.5c-1.9,0.7-3,2.2-3.2,4.4c0.1,1.2,0.3,2.5,0.4,3.7
								c0.7,2.5,2.2,4.2,4.6,5.1c4,0.9,8,1.1,12.1,0.7c3.9-1.2,7.4-3.1,10.5-5.7c3.4-2.3,6.2-5,8.5-8.4c3.7-10,7.4-19.9,11.1-29.9
								c1.2-3.2,0.5-5.9-2.2-8.3c-4-3.3-8.4-6.2-13-8.6c-5.5-3.4-11.3-6.4-17.2-9c-4.3-2.8-8.8-3.8-13.7-3
								c-6.7,1.8-13.1,3.7-19.3,7.1c-5.2,2.8-10,6.3-14.5,10.1c-6.8,5.8-13.5,11.8-19.6,18.4c-4.3,4.7-8.4,9.7-12.3,14.8
								c-3.7,4.5-7,9.3-9.9,14.4c-1.1,2.8-0.6,5.2,1.7,7.2c4,2.1,8.2,2.5,12.6,1c7.7-1.2,15.2-3,22.7-5.3
								C744.8,1232.7,748.5,1230.6,751.1,1226.9z"/>
							<path opacity="0.1091" fill="#131313" d="M703,1226.9c3.9-3.7,7.6-7.6,10.9-11.8c2.7-3.6,5.8-6.8,9.3-9.7
								c2.3-1.8,4.5-3.7,6.8-5.5c4.5-3.4,9.3-6.4,14.3-9c-1.1,0.5-2.1,1-3.2,1.4c2.9-1,5.8-2,8.9-2.5c3.1-0.6,6-0.7,9.2-0.6
								c-1.6-0.2-3.3-0.4-4.9-0.6c2.8,0.4,5.7,0.8,8.5,1.1c-1.7-0.3-3.3-0.6-5-0.9c2.7,0.6,5.3,1.2,8,1.9c-1.7-0.4-3.4-0.9-5.1-1.3
								c3.4,1.2,6.7,2.7,9.9,4.4c-4.2-2.9-8.4-5.9-12.7-8.8c3,4.2,5.9,8.4,8.9,12.6c-1.7-3.4-3.4-6.9-5.2-10.3
								c-1.1,12.6-2.2,25.3-3.4,37.9c3.5-10.8,6.9-21.5,10.4-32.3c-10.9,10.1-21.7,20.2-32.6,30.3c9.3-5.8,18.5-11.7,27.8-17.5
								c-9.4,3.6-18.4,7.9-27.1,12.9c-3.1,1.1-5.3,3-6.7,6c0,1.3,0,2.6,0,3.9c1,2.3,2.6,3.5,5,3.4c3.6,1,7.2,0.6,10.8-1.4
								c3.8-1.3,7.7-2.6,11.5-4c-4.5,2.9-9,5.7-13.5,8.6c1,0.1,2.1,0.1,3.1,0.2c3.2,0.4,6.3,0.2,9.5-0.5c9.6-2,18.2-7.1,23.7-15.3
								c2.3-3.4,3.6-8.4,4.2-12.3c0.5-3.5,0.7-6.9,0.5-10.4c-0.2-3-0.7-7.1-2.2-9.7c-1.6-2.8-4-5-6.4-7.1c-2.9-2.7-6.6-4.2-10.3-5.5
								c-3-1.1-6.1-1.7-9.3-1.6c-2.9-0.4-5.7-0.4-8.6,0.1c-7.1,1-13.9,3.3-20.3,6.4c-4,2.3-7.9,4.9-11.5,7.7
								c-3.8,2.7-7.4,5.7-10.7,8.9c-2.2,2.2-4.4,4.5-6.6,6.7c-3.8,5.5-8.1,10.6-12.6,15.5c-1,2.1-1.1,4.3-0.4,6.5
								c0.4,2.3,1.5,4.2,3.3,5.7c2.4,0.7,4.9,0.7,7.3,0C699,1229.8,701.2,1228.7,703,1226.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M826.8,1221.3c1,0.3,2.1,0.5,3.1,0.8c-1.6-0.7-3.2-1.5-4.9-2.2
								c0.8,0.5,1.5,1,2.3,1.5c-2.2-2.4-4.4-4.9-6.6-7.3c3.2,4,6.3,7.9,9.5,11.9c-6.4-10.8-12.8-21.6-19.3-32.4
								c6.6,13.5,13.1,27,19.7,40.5c-2.6-7.3-5.1-14.6-7.7-21.9c1.4,5.2,2.7,10.5,4.1,15.7c-0.9-4.4-1.9-8.9-2.8-13.3
								c0.8,5.3,1.7,10.6,2.5,15.9c-0.5-4.4-1-8.8-1.5-13.2c0.3,5.2,0.6,10.4,1,15.6c-0.1-4.3-0.2-8.6-0.3-13
								c-0.2,5.1-0.4,10.3-0.7,15.4c0.9-8.3,1.8-16.5,2.8-24.8c-1.3,9.1-2.7,18.2-4,27.3c0.7-4,1.5-8,2.2-12
								c-1.2,4.7-2.4,9.5-3.6,14.2c-1.8,3.6-2.1,7.1-0.9,10.5c-0.1,1.7,0.6,2.9,2.1,3.5c0.9,1.2,2,1.6,3.5,1.2
								c2.4,0.4,4.3-0.6,5.7-3c1.3-2.5,2.6-5,3.8-7.4c1.4-3.7,2.8-7.4,4.2-11c2-5,3.4-10.1,4.2-15.4c2.1-6.9-0.2-13.1-6.8-18.6
								c-1.1-7.1-4.3-12.4-9.6-16c-3.3-2.6-6.7-4.8-10.6-6.5c-6.3-2.8-12.7-4.9-19.6-5.3c-3.2-0.2-5.2,1.3-5.9,4.5
								c0.1,5.2,2,9.8,5.6,13.7c5.1,7.7,10.7,15,16.7,22C818,1216.7,821.9,1219.8,826.8,1221.3z"/>
							<path opacity="0.1091" fill="#131313" d="M802.1,1202.1c0.8,0.5,1.6,1,2.4,1.5c-1.5-1.4-3-2.8-4.5-4.2c2.1,2,4.1,4,6.2,6
								c-3.1-3.9-6.1-7.8-9.2-11.7c2.5,3.7,5,7.5,7.5,11.2c-1.3-2.7-2.6-5.4-4-8c1.6,3.5,3.1,7,4.7,10.5c-1.1-2.8-2.2-5.7-3.4-8.5
								c1.2,3.7,2.5,7.3,3.7,11c-0.9-3-1.8-5.9-2.6-8.9c0.9,3.9,1.7,7.7,2.6,11.6c-0.6-3.1-1.3-6.2-1.9-9.4c0.5,4,1,7.9,1.5,11.9
								c-0.4-6.4-0.8-12.8-1.2-19.3c0.1,7.2,0.3,14.5,0.4,21.7c0.2-6.5,0.4-13,0.6-19.6c-0.5,7.3-1.1,14.6-1.6,21.9
								c0.9-6.5,1.7-13,2.6-19.5c-1.3,7.3-2.7,14.6-4,21.9c1.5-6.5,3.1-13,4.6-19.5c-3.4,9.3-5.9,18.9-7.3,28.7
								c0.1,3.1,1.5,5.3,4.3,6.5c2.9,0.9,5.4,0.1,7.5-2.2c3.2-4.9,5.6-10.2,7.4-15.8c2.2-5.5,3.6-11.1,4.3-16.9
								c-2.2-8.8-7-16.4-14.4-22.7c-1.5-3.2-3.9-5.3-7-6.5c-2.1-0.9-4.3-1.7-6.5-2.2c-2.6-1.3-5-1-7.3,0.7c-2.3,1.7-3.2,4-2.7,6.8
								c1.8,4.6,4.2,8.8,7.3,12.6C794.9,1195.6,798.2,1199.1,802.1,1202.1z"/>
							<path opacity="0.1091" fill="#131313" d="M801.7,1167.2c1.4,0.8,2.9,1.5,4.3,2.3c-1-0.6-1.9-1.2-2.9-1.8
								c2.1,1,3.9,2.5,5.2,4.4c-0.9-1.2-1.8-2.4-2.7-3.6c1.3,1.9,2.6,3.9,3.8,5.8c-1.4-2.7-2.8-5.3-4.2-8c0.9,2.3,1.7,4.5,2.6,6.8
								c-0.5-1.4-0.9-2.8-1.4-4.3c0.7,2.4,1.3,4.7,2,7.1c-0.4-1.5-0.7-2.9-1.1-4.4c0.4,2.4,0.8,4.8,1.2,7.2c-0.2-1.5-0.5-3-0.7-4.5
								c0.2,1.9,0.2,3.7-0.2,5.5c-0.3,1.8-0.8,3.5-1.6,5.1c0.5-1.1,1.1-2.1,1.6-3.2c-1.3,2.5-2.6,4.9-4.1,7.3
								c-1.7,3-2.8,6.2-3.5,9.6c-0.8,2-0.5,3.7,0.9,5.1c0.9,1.6,2.3,2.6,4.2,3c2.3,1,4.5,0.9,6.7-0.1c4.3-4.6,8.2-9.4,10.4-15.4
								c1.4-3.9,2.2-7.9,2.3-12c0.2-7.1-1.8-14.3-6-20c-1.9-2.9-4.4-5.1-7.3-6.8c-2.7-1.9-5.6-3.4-8.7-4.6c-2.3-0.1-4.4,0.7-6.2,2.2
								c-2,1.3-3.2,3.1-3.8,5.4c0.4,2.6,1.4,4.9,3.1,6.9C797.4,1164.5,799.3,1166.1,801.7,1167.2z"/>
							<path opacity="0.1091" fill="#131313" d="M819.8,1193.4c2.4,0.6,4.8,1.2,7.2,1.9c-1.3-0.4-2.7-0.7-4-1.1
								c0.8,0.5,1.6,0.9,2.4,1.4c-1.6-1.6-3.3-3.2-4.9-4.8c2.2,2.2,4.4,4.5,6.7,6.7c-1.5-1.7-3.1-3.3-4.6-5c0.5,0.7,1.1,1.4,1.6,2.1
								c-1.6-2.7-3.2-5.4-4.8-8.1c2.3,4.4,4.7,8.7,7,13.1c-2-5.8-4-11.5-6-17.3c2.2,7.6,4.3,15.3,6.5,22.9c-0.6-2.9-1.2-5.8-1.8-8.7
								c0.5,3.9,0.9,7.7,1.4,11.6c-0.3-2.9-0.6-5.7-1-8.6c0.1,3.8,0.2,7.6,0.2,11.4c0.2-5.6,0.4-11.3,0.6-16.9
								c-0.4,3.8-0.7,7.6-1.1,11.4c0.5-2.7,1-5.4,1.5-8.1c-1.1,7.3-2.8,14.3-5.3,21.3c-1.4,3.2-1.2,6.3,0.7,9.1
								c1.9,2,4.2,2.8,6.9,2.6c3.3-0.9,5.4-3,6.5-6.4c2.4-4.8,4.2-9.7,5.6-14.9c1.3-22-5.5-37.2-20.6-45.6
								c-4.6-2.6-9.6-3.8-14.7-4.7c-2.8,0.3-4.8,1.8-5.9,4.5c-0.7,2.7-0.2,5.1,1.5,7.2c1.8,4,4.2,7.6,7.1,11
								C811.7,1185.9,815.4,1190,819.8,1193.4z"/>
							<path opacity="0.1091" fill="#131313" d="M820.2,1164.1c1.6,0.9,3.1,1.8,4.7,2.7c-1.1-0.8-2.3-1.6-3.4-2.5
								c2.4,1.9,4.8,3.7,7.2,5.6c-1.1-0.9-2.2-1.7-3.3-2.6c0.6,0.7,1.3,1.5,1.9,2.2c-1.1-1.5-2.2-3-3.2-4.5c1.6,2.3,3.1,4.7,4.7,7
								c-1.7-3.2-3.3-6.4-5-9.5c1,2.6,2,5.1,3.1,7.7c-0.6-1.6-1.1-3.3-1.7-4.9c0.8,3.6,1.6,7.2,2.4,10.8c-0.3-1.6-0.5-3.2-0.8-4.8
								c0.2,2.6,0.4,5.2,0.6,7.9c-0.1-1.6-0.2-3.1-0.4-4.7c-0.1,2-0.4,4-0.9,6c-0.6,1.9-1.5,3.7-2.5,5.4c0.6-0.9,1.2-1.7,1.9-2.6
								c-1.2,1.7-2.4,3.4-3.6,5c-1.4,2.5-2.3,5.1-2.7,7.9c-0.5,1.8-0.1,3.2,1.2,4.3c1.1,1.4,2.5,2.3,4.3,2.7c2.2,0.9,4.3,1,6.5,0.3
								c4.3-4.4,8-9,10.2-14.8c1-2.4,1.6-4.8,2-7.4c0.5-2.3,0.5-4.5-0.1-6.8c-1.4-8.1-3.4-16.1-9-22.3c-4.3-4.9-10.1-8.1-15.8-11
								c-2.4-0.4-4.6,0.2-6.5,1.8c-2.1,1.4-3.2,3.3-3.4,5.8c0.8,3.2,2.3,6.1,4.5,8.6C814.9,1160.3,817.3,1162.5,820.2,1164.1z"/>
							<path opacity="0.1091" fill="#131313" d="M783.3,1187.6c1.6,1,3.2,1.9,4.9,2.9c-1.1-0.7-2.1-1.5-3.2-2.2
								c2.4,1.9,4.8,3.7,7.2,5.6c-2.2-2.3-4.4-4.5-6.6-6.8c2.1,2.7,4.3,5.5,6.4,8.2c-1-1.7-2-3.4-3.1-5.1c1.2,2.3,2.4,4.6,3.6,6.9
								c-1.6-3.7-3.1-7.5-4.7-11.2c0.8,2.5,1.6,5,2.5,7.4c-0.5-2-1.1-4.1-1.6-6.1c0.7,3.3,1.3,6.6,2,9.9c0-3.9,0-7.9,0-11.8
								c-1.2,9.1-2.3,18.2-3.5,27.3c1.3-5.3,2.6-10.6,4-15.8c-1.8,5.8-3.7,11.6-5.5,17.5c-0.3,2-0.6,4.1-1,6.1
								c0.1,2.3,1.1,3.8,2.9,4.7c0.9,1,2.1,1.5,3.4,1.3c1.3,0.5,2.6,0.3,3.6-0.7c1.7-0.5,2.8-1.7,3.4-3.5c1.5-2,2.6-4.2,3.4-6.6
								c1.8-4.3,3.7-8.6,5.7-12.8c0.1-1.6,0.1-3.2,0.2-4.8c-0.3-2.2-0.6-4.5-0.8-6.7c-0.7-1.9-1.3-3.8-2-5.8
								c-0.9-1.8-1.8-3.6-2.7-5.4c-4.4-6.6-11.9-10.4-18.8-13.9c-2.4-0.3-4.5,0.4-6.4,2c-2,1.4-3.2,3.2-3.6,5.6
								c0.6,2.9,1.9,5.6,3.9,7.9C778.5,1184.2,780.7,1186.2,783.3,1187.6z"/>
							<path opacity="0.1091" fill="#131313" d="M810.1,1223c1.1,0.3,2.1,0.6,3.2,0.9c-1.5-0.6-3-1.1-4.5-1.7
								c0.8,0.6,1.6,1.1,2.4,1.7c-6.5-8.6-13-17.2-19.6-25.8c7.7,13.1,15.5,26.2,23.2,39.3c-2.4-6.9-4.8-13.9-7.2-20.8
								c1.2,5,2.5,10.1,3.7,15.1c-0.9-4.3-1.8-8.6-2.7-12.9c0.8,5.2,1.6,10.4,2.3,15.6c-0.7-8.8-1.4-17.6-2.1-26.3
								c0.5,9.6,0.9,19.2,1.4,28.8c0.1-8.8,0.2-17.7,0.4-26.5c-0.5,9.6-0.9,19.3-1.4,28.9c1-8.7,2-17.4,2.9-26.1
								c-1.4,9.5-2.9,19-4.3,28.5c1.9-8.6,3.7-17.1,5.6-25.7c-3.7,12.1-6.7,24.4-8.9,36.8c-0.3,3.5,1,6.1,4.1,7.6
								c3.3,0.6,6-0.6,7.8-3.6c3.6-6.5,6.5-13.4,8.7-20.5c2.6-7,4.4-14.2,5.5-21.6c-7-14.1-16.9-24.5-29.8-31.2
								c-4.3-1.7-9.2-2.3-13.7-2.6c-3,0.1-5,1.6-5.9,4.4c-0.3,4.5,1.1,8.5,4.3,12c4.3,6.5,9,12.8,14.1,18.6
								C802.2,1219.7,805.7,1222.1,810.1,1223z"/>
							<path opacity="0.1091" fill="#131313" d="M837.8,1191c1.1,0.3,2.1,0.6,3.2,0.9c-1.4-0.5-2.8-0.9-4.2-1.4
								c0.8,0.5,1.5,0.9,2.3,1.4c-1.8-1.8-3.5-3.6-5.3-5.4c2.3,2.4,4.7,4.9,7,7.3c-1.6-1.8-3.3-3.6-4.9-5.5c0.6,0.7,1.1,1.4,1.7,2.1
								c-1.7-2.9-3.4-5.8-5.1-8.6c2.2,3.7,4.3,7.4,6.5,11.1c-1.4-2.9-2.9-5.8-4.3-8.7c1.8,3.8,3.6,7.6,5.4,11.3
								c-2.1-5.9-4.1-11.8-6.2-17.6c2.3,7.9,4.6,15.8,6.9,23.7c-0.6-2.8-1.2-5.7-1.8-8.5c0.5,3.9,0.9,7.8,1.4,11.8
								c-0.3-2.7-0.6-5.5-0.9-8.2c0.1,3.8,0.2,7.6,0.3,11.4c0.2-5.2,0.4-10.5,0.6-15.7c-0.4,3.8-0.8,7.6-1.3,11.3
								c0.5-2.4,0.9-4.9,1.4-7.3c-0.8,3.6-1.5,7.2-2.3,10.8c0.7-2.3,1.4-4.5,2.1-6.8c-3.2,6.8-5.1,13.9-5.6,21.2
								c0.7,2.5,2.2,4.3,4.7,5.1c2.4,1,4.8,0.8,7-0.6c2.6-3,4.5-6.5,5.7-10.3c6.8-17.3,3.1-34.7-11.2-52.3c-2.5-2.3-5.2-4.4-8.1-6.1
								c-4.8-2.9-10.1-4.5-15.6-5.6c-2.9,0.2-4.9,1.7-5.9,4.5c-0.5,4.2,0.7,7.9,3.7,11.1c3.9,6,8.2,11.7,13,17
								C830.3,1188.2,833.6,1190.4,837.8,1191z"/>
							<path opacity="0.1091" fill="#131313" d="M793.2,1178.1c1.4,0.8,2.9,1.5,4.3,2.3c-1.1-0.7-2.1-1.4-3.2-2.2
								c2.2,1.6,4.5,3.3,6.7,4.9c-2.1-2.2-4.3-4.3-6.4-6.5c2,2.6,4,5.3,6,7.9c-1-1.6-1.9-3.1-2.9-4.7c1.2,2.2,2.3,4.5,3.5,6.7
								c-1.4-3.4-2.9-6.8-4.3-10.2c0.8,2.4,1.6,4.8,2.4,7.3c-0.5-1.8-0.9-3.7-1.4-5.5c0.6,2.6,1.1,5.1,1.7,7.7
								c-0.3-1.9-0.6-3.8-0.9-5.7c0.3,2.7,0.6,5.3,0.9,8c-0.1-2-0.3-4-0.4-6c0.1,2.7,0.1,5.4,0.2,8.2c0-2,0.1-4.1,0.1-6.1
								c-0.3,2.8-0.5,5.5-0.8,8.3c0.2-2.1,0.5-4.2,0.7-6.2c-0.5,2.7-1,5.4-1.5,8.2c0.5-2.1,0.9-4.2,1.4-6.3
								c-0.8,2.7-1.7,5.5-2.5,8.2c0.7-2.1,1.4-4.2,2.1-6.4c-1.9,5.4-4.5,11.3-5.2,17.1c-0.7,2.1-0.3,4,1.2,5.6
								c1,0.7,2.1,1.4,3.1,2.1c1.2,0.2,2.5,0.4,3.7,0.6c2.3-0.2,3.9-1.3,4.7-3.3c1.3-1.6,2.3-3.3,2.9-5.2c0-2.7,1-3.7,2.9-3
								c0.9-2.5,1.8-5.1,2.7-7.6c0.2-1.4,0.4-2.9,0.6-4.3c0.5-4.3-0.3-8.3-1.5-12.4c-0.9-3.4-2.3-6.5-4.3-9.5
								c-1-1.5-2.1-2.9-3.1-4.4c-3.9-4.1-9.2-6.5-14.2-8.8c-2.4-0.3-4.5,0.4-6.4,2c-2,1.4-3.2,3.2-3.6,5.6c0.6,2.9,1.9,5.6,3.9,7.9
								C788.4,1174.8,790.6,1176.7,793.2,1178.1z"/>
							<path opacity="0.1091" fill="#131313" d="M809.5,1173.9c1.6,1,3.3,2,4.9,3c-1.2-1-2.5-1.9-3.7-2.9c2.6,2.1,5.1,4.2,7.7,6.3
								c-1.2-1-2.4-2-3.6-3c1.7,1.6,3.4,3.1,5.1,4.7c-2.4-2.8-4.7-5.6-7.1-8.4c1.6,2.4,3.3,4.7,4.9,7.1c-1.1-1.8-2.2-3.6-3.3-5.4
								c1.4,2.6,2.9,5.3,4.3,7.9c-0.9-1.9-1.9-3.7-2.8-5.6c1.2,2.8,2.3,5.5,3.5,8.3c-1.3-3.9-2.6-7.9-3.9-11.8
								c0.7,2.9,1.4,5.8,2.2,8.7c-0.3-2-0.7-4-1-6.1c0.4,3,0.9,5.9,1.3,8.9c-0.2-2-0.3-4.1-0.5-6.1c0.1,3,0.2,6,0.3,9
								c0-2,0.1-4,0.1-6.1c-0.5,3.8-1.1,7.6-1.6,11.4c0.4-1.9,0.9-3.9,1.3-5.8c-0.9,2.8-1.7,5.6-2.6,8.4c0.6-1.9,1.3-3.8,1.9-5.7
								c-1,2.6-1.9,5.1-2.9,7.7c-0.6,1.9-1.2,3.9-1.8,5.8c-0.1,1.5-0.3,2.9-0.4,4.4c0.5,1,1.1,1.9,1.6,2.9c1.1,0.7,2.1,1.3,3.2,2
								c1.2,0.2,2.5,0.5,3.7,0.7c1.1-0.3,2.1-0.6,3.2-1c1.8-1.8,3.1-3.9,4-6.3c2.9-5.2,3.6-10.9,2.3-17.2c2-5.8,2.1-11.3,0.3-16.6
								c-1.1-3.9-3.2-7.3-5.5-10.5c-0.9-1.7-2.1-3.1-3.6-4.2c-0.9-1-1.9-1.9-3.2-2.5c-1.3-0.8-2.6-1.6-3.9-2.5
								c-2.3-1.6-4.7-3-7.3-4.2c-2.5-0.6-4.7-0.1-6.7,1.5c-2.1,1.5-3.2,3.5-3.3,6c1,3.5,2.7,6.7,5.1,9.5
								C803.8,1169.5,806.4,1172,809.5,1173.9z"/>
							<path opacity="0.1091" fill="#131313" d="M836.2,1207.2c1,0.3,2.1,0.5,3.1,0.8c-1.6-0.7-3.1-1.3-4.7-2
								c0.7,0.5,1.5,0.9,2.2,1.4c-2.1-2.2-4.2-4.5-6.2-6.7c2.6,2.9,5.3,5.8,7.9,8.6c-6.5-9.5-12.9-18.9-19.4-28.4
								c7.1,12.8,14.2,25.7,21.3,38.5c-2.2-6.2-4.4-12.4-6.6-18.6c1.2,4.6,2.4,9.2,3.6,13.9c-0.8-3.7-1.5-7.4-2.3-11.1
								c0.7,4.6,1.4,9.3,2.1,13.9c-0.4-3.6-0.8-7.2-1.2-10.8c0.3,4.5,0.5,9,0.8,13.4c-0.1-3.5-0.1-6.9-0.2-10.4
								c-0.3,4.4-0.5,8.8-0.8,13.2c0.3-3.3,0.5-6.6,0.8-9.8c-0.7,4.2-1.4,8.4-2,12.7c0.6-3.1,1.2-6.2,1.7-9.3
								c-1.1,3.9-2.1,7.9-3.2,11.8c-1.5,2.9-1.6,5.7-0.3,8.3c0.1,1.5,0.9,2.5,2.2,3c0.9,1.1,2.1,1.5,3.5,1.3
								c2.1,0.5,3.8-0.1,5.4-1.9c1.1-1.9,2.2-3.7,3.3-5.6c1.2-2.8,2.3-5.6,3.5-8.4c4.6-9.2,3.7-18.5-2.7-27.8
								c0.7-8.8-1.7-15.9-7.3-21.1c-1.1-1.3-2.4-2.5-3.8-3.4c-2-1.6-4.1-3.1-6.3-4.4c-2.8-1.7-5.9-3.3-8.9-4.7
								c-3.8-1.7-7.4-2.5-11.4-3c-3.1-0.1-5.1,1.4-5.9,4.5c-0.1,4.9,1.6,9.2,5.1,13c4.8,7.2,10,14,15.7,20.6
								C827.7,1203.2,831.4,1206,836.2,1207.2z"/>
							<path opacity="0.1091" fill="#131313" d="M785.7,1201c1.4,0.7,2.8,1.4,4.2,2.1c-4.1-4.8-8.2-9.6-12.2-14.4
								c6.2,9.6,12.4,19.1,18.7,28.7c-2.2-7.3-4.4-14.7-6.6-22c1.6,7,2.7,14.2,3.2,21.4c-0.4-6.1-0.7-12.3-1.1-18.4
								c0,3.7,0,7.4,0,11.1c0.2-3.2,0.5-6.4,0.7-9.5c-0.6,7.1-1.2,14.2-1.7,21.3c0.9-6.5,1.7-13,2.6-19.5c-0.8,3.9-1.5,7.8-2.3,11.6
								c0.9-3.3,1.8-6.6,2.8-9.9c-3.5,9.5-6.1,19.2-7.6,29.2c0,3.2,1.4,5.4,4.3,6.6c1.3,0,2.6,0.1,3.9,0.1c3.8-1.8,6.2-4.9,7.3-9.1
								c2.3-4.8,4.1-9.8,5.6-15c4.8-11.3,1.1-22.1-11.3-32.2c-1.4-2.6-3.5-4.1-6.3-4.5c-2.6-1.5-5.3-2.8-8.1-3.8
								c-4.7-1.9-10.2,2.8-10,7.6c1.2,3.8,3.1,7.2,5.7,10.3C779.6,1196,782.3,1198.8,785.7,1201z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M882.4,1188.3c8.2-21.3,16.6-42.5,26.3-63.2c10.2-21.8,21.5-42.8,33.4-63.7
								c1.9-2.1,2.3-4.6,1.3-7.2c-1.1-2.7-3-4.1-5.9-4.4c-4.9,0.5-9.6,1.8-14.2,3.7c-4.7,1.6-9,3.9-13,6.8
								c-11.6,21.4-22.6,43.1-32.6,65.3c-10.3,23-19.3,46.5-28.3,70.1c0,2.7,1,5,3.1,6.7c3,1.7,6.1,1.6,9.2-0.1
								c5.1-1.8,10-4.1,14.6-6.9C879.7,1194,881.7,1191.7,882.4,1188.3z"/>
							<path opacity="0.1091" fill="#131313" d="M857.7,1175.3c6.3-11,11.4-22.5,15.4-34.5c4.6-11.2,9.5-22.3,14.6-33.2
								c10.2-21.7,21.6-42.8,33.6-63.6c2.4-4.2,0.4-10.4-4.6-11.7c-3.8,0.1-7.4,0.9-10.8,2.4c-3.6,1.2-6.8,3-9.6,5.5
								c-12,21.4-23.4,42.9-33.7,65.2c-5.2,11.1-10.1,22.4-14.7,33.8c-3.8,11.6-8.6,22.9-14.4,33.7c-2,4.2,0.6,10.4,5.4,11.3
								c3.6-0.3,6.9-1.2,10.1-3C852.4,1179.8,855.3,1177.9,857.7,1175.3z"/>
							<path opacity="0.1091" fill="#131313" d="M863.7,1136.9c3.7-11.3,7.8-22.4,12.5-33.2c-0.5,1.2-1,2.4-1.5,3.6
								c7.1-16.7,14.4-33.3,22.6-49.5c8.1-16.1,16.8-31.8,25.8-47.5c1.8-2.2,2.2-4.6,1.2-7.2c-1-2.6-3-4.1-5.8-4.5
								c-4.8,0.5-9.3,1.7-13.7,3.6c-4.5,1.6-8.7,3.8-12.5,6.7c-6.1,10.8-12.3,21.7-18.1,32.7c-7.2,13.8-14,27.7-20.4,41.9
								c-4.1,9.1-8.1,18.2-11.9,27.4c-1.4,3.4-2.8,6.8-4.1,10.2c-3.3,8-6.3,16.1-9.1,24.3c-0.1,2.8,0.9,5,3.2,6.6
								c3.2,1.6,6.5,1.5,9.7-0.4c5.4-2,10.6-4.5,15.6-7.4C860.7,1142.8,862.9,1140.4,863.7,1136.9z"/>
							<path opacity="0.1091" fill="#131313" d="M881.1,1163.9c3.9-11.3,8.2-22.4,12.9-33.4c4.6-11.2,9.5-22.3,14.7-33.2
								c10.3-21.8,21.8-42.8,33.8-63.6c0.5-2.8-0.4-5.1-2.7-6.9c-3.6-1.7-7.3-1.6-11.1,0.2c-6.4,1.9-12.6,4.4-18.7,7.3
								c-4,1.2-6.8,3.6-8.3,7.4c-11.7,21.7-23,43.6-33.1,66.1c-9.8,21.9-18.7,44.2-26.9,66.7c-0.3,2.9,0.8,5.1,3.2,6.7
								c3.7,1.5,7.3,1.2,10.9-0.9c6.2-2.3,12.1-5.1,17.8-8.4C877.3,1170.3,879.9,1167.7,881.1,1163.9z"/>
							<path opacity="0.1091" fill="#131313" d="M883.4,1134.3c3.9-11.3,8.3-22.5,12.9-33.6c4.6-11.2,9.5-22.2,14.6-33.1
								c10.2-21.7,21.6-42.7,33.6-63.4c0.8-2.9-0.1-5.2-2.7-6.9c-4.3-1.5-8.6-1.2-12.9,1c-7.6,2.4-14.9,5.3-22.1,8.7
								c-4.6,1.3-7.9,4-10.1,8.1c-12.1,22-23.6,44.1-34,67c-5.2,11.3-10.1,22.8-14.7,34.3c-4.6,10.7-8.8,21.6-12.6,32.7
								c-0.6,3,0.5,5.2,3.2,6.6c4.5,1.2,8.9,0.5,13-2c7.6-3,14.9-6.5,21.9-10.5C878.2,1141.5,881.4,1138.5,883.4,1134.3z"/>
							<path opacity="0.1091" fill="#131313" d="M844.5,1162.4c5.7-11.5,10.8-23.2,15.3-35.2c4.8-11.2,9.7-22.2,14.9-33.2
								c10.3-21.7,21.7-42.7,33.6-63.5c0.9-2.2,0.9-4.4-0.1-6.6c-0.7-2.3-2.1-4-4.3-5.1c-2.9-0.3-5.7,0.2-8.4,1.3
								c-2.8,0.9-5.2,2.3-7.3,4.5c-12.2,21.3-23.8,42.7-34.3,64.9c-5.2,11.1-10.3,22.4-15,33.7c-4.4,11.8-9.2,23.5-14.4,34.9
								c-0.7,2.3-0.4,4.5,0.8,6.6c0.9,2.2,2.4,3.8,4.6,4.7c2.8,0.1,5.5-0.6,8-2C840.6,1166.3,842.8,1164.6,844.5,1162.4z"/>
							<path opacity="0.1091" fill="#131313" d="M863.8,1195.2c6.8-11.5,12.6-23.5,17.5-35.9c4.6-11,9.4-21.9,14.5-32.8
								c10.2-21.6,21.5-42.6,33.4-63.4c2.3-4,0.1-10.4-4.5-11.7c-3.5,0-6.9,0.7-10.1,2.1c-3.4,1.1-6.3,2.8-8.9,5.2
								c-11.7,21.2-22.9,42.5-33,64.5c-5,10.9-9.7,21.8-14.2,32.9c-4.6,12.1-9.9,23.8-16,35.2c-1.9,3.8,1.2,10.3,5.4,11.3
								c3.1,0,6-0.8,8.8-2.3C859.3,1199.4,861.8,1197.6,863.8,1195.2z"/>
							<path opacity="0.1091" fill="#131313" d="M896.4,1159.7c3.9-11.4,8.2-22.5,13-33.6c4.6-11.2,9.6-22.2,14.7-33.2
								c10.3-21.7,21.8-42.7,33.8-63.6c0.9-3,0-5.3-2.7-6.9c-4.7-1.4-9.4-0.9-14,1.4c-8.3,2.7-16.4,5.8-24.3,9.5
								c-4.9,1.4-8.7,4.2-11.2,8.5c-11.6,21.9-22.7,44-32.8,66.7c-5,11.3-9.8,22.7-14.3,34.2c-4.6,10.7-8.7,21.5-12.4,32.5
								c-0.7,3,0.4,5.3,3.2,6.7c4.7,1.1,9.3,0.4,13.6-2.2c7.9-3.1,15.6-6.8,23-10.9C890.8,1167.1,894.2,1164,896.4,1159.7z"/>
							<path opacity="0.1091" fill="#131313" d="M850.9,1152.2c6.4-11,11.6-22.6,15.7-34.7c4.6-11.2,9.5-22.3,14.7-33.3
								c10.3-21.8,21.8-42.9,33.8-63.8c1.4-2.2,1.6-4.5,0.6-6.9c-0.9-2.5-2.6-4.1-5.1-4.7c-3.9,0.1-7.7,1-11.3,2.7
								c-3.8,1.3-7.1,3.2-10.1,5.8c-12.2,21.5-24,43.2-34.5,65.7c-5.3,11.2-10.2,22.5-14.9,33.9c-3.9,11.7-8.9,23-14.8,33.9
								c-1.3,2.3-1.3,4.6-0.1,7c1.1,2.4,2.9,3.9,5.5,4.3c3.9-0.4,7.6-1.6,11.1-3.5C844.9,1157.2,848.1,1155,850.9,1152.2z"/>
							<path opacity="0.1091" fill="#131313" d="M872.1,1146.2c6.2-16.9,12.6-33.7,20-50.2c7.4-16.5,15.4-32.7,23.7-48.7
								c-0.7,1.2-1.3,2.4-2,3.7c3.5-6.5,7.1-13,10.6-19.5c-0.7,1.2-1.4,2.4-2,3.6c3.7-6.4,7.3-12.8,11-19.2c0.4-2.8-0.5-5.1-2.7-6.9
								c-3.5-1.7-7-1.7-10.7,0.1c-6.2,1.8-12.1,4.2-17.9,7c-3.9,1.2-6.5,3.6-7.9,7.2c-12,21.8-23.6,43.8-33.9,66.4
								c-5.2,11.3-10.1,22.7-14.7,34.2c-4.5,10.8-8.7,21.7-12.6,32.7c-0.3,2.8,0.8,5.1,3.2,6.6c3.6,1.5,7.2,1.2,10.8-0.9
								c6.1-2.3,12-5.1,17.6-8.4C868.5,1152.5,871,1149.9,872.1,1146.2z"/>
							<path opacity="0.1091" fill="#131313" d="M892.6,1175.2c4-11.3,8.4-22.5,13-33.6c4.6-11.2,9.6-22.3,14.7-33.2
								c10.3-21.8,21.8-42.8,33.9-63.7c0.7-2.9-0.2-5.2-2.7-6.9c-4.1-1.6-8.3-1.3-12.4,0.7c-7.3,2.2-14.3,5-21.2,8.3
								c-4.4,1.3-7.6,3.9-9.6,7.9c-11.5,21.7-22.5,43.6-32.4,66.1c-9.7,21.9-18.4,44.2-26.7,66.6c-0.4,2.9,0.6,5.1,3.1,6.7
								c4,1.4,8,0.9,11.8-1.4c6.8-2.6,13.3-5.7,19.6-9.3C888.2,1181.9,891,1179.2,892.6,1175.2z"/>
							<path opacity="0.1091" fill="#131313" d="M845.8,1175.6c5.8-11.5,11-23.2,15.5-35.3c4.8-11.2,9.7-22.3,14.9-33.3
								c10.3-21.7,21.7-42.7,33.7-63.6c0.8-2.2,0.7-4.4-0.2-6.6c-0.7-2.3-2-4-4.1-5.2c-2.7-0.4-5.3,0-7.9,1.1
								c-2.6,0.8-4.9,2.2-6.7,4.2c-12.1,21.2-23.7,42.6-34.1,64.8c-5.2,11.1-10.2,22.3-15,33.6c-4.4,11.8-9.2,23.5-14.4,34.9
								c-0.5,2.3-0.2,4.4,1,6.5c0.9,2.2,2.3,3.8,4.4,4.8c2.5,0.2,4.9-0.3,7.2-1.6C842.4,1179.2,844.3,1177.7,845.8,1175.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M867.8,1182.9c-2.2-1.7-3.8-3.9-4.9-6.5c1,4.1,2.1,8.2,3.1,12.3
								c0.1-2.3,0.2-4.6,0.3-7c-0.2,1.2-0.4,2.4-0.7,3.5c1.7-5.2,4.1-10,5.8-15.2c1.4-4.1,2.2-8.2,2.9-12.5c0.5-2.7,0.9-5.5,1.2-8.2
								c0.7-5.6,1.7-11.2,3-16.7c-0.3,1.4-0.6,2.8-0.9,4.2c1.1-4.1,2.2-8.3,3.2-12.4c-0.4,1.4-0.8,2.8-1.3,4.2
								c1.4-4.1,2.7-8.2,4.1-12.4c-0.5,1.4-1.1,2.8-1.6,4.3c1.6-4.1,3.3-8.2,4.9-12.2c0.2-2.6-0.8-4.7-2.8-6.3
								c-1.9-1.8-4.1-2.4-6.7-1.8c-3.3,1.9-6.2,4.3-8.5,7.3c-2.6,2.8-4.6,5.9-6,9.5c-1.8,7.4-3.4,14.8-4.4,22.4
								c-1,7.7-1.4,15.3-1.7,23.1c0.1-1.3,0.1-2.7,0.2-4c-0.3,2.9-0.6,5.8-0.9,8.8c1.1-3.4,2.1-6.8,3.2-10.1
								c-2.2,3.4-4.7,6.8-6.8,10.3c-1.9,3.1-3.9,7.1-3.6,10.9c0.3,1.6,0.5,3.1,0.8,4.7c0.9,2.4,1.8,4.8,2.7,7.2
								c0.7,1.4,1.5,2.7,2.2,4.1c1.8,1.9,3.7,3.7,5.7,5.5c2.3,0.6,4.4,0.4,6.5-0.8c1.8-0.6,3.1-1.7,4-3.2c1.2-1.3,1.5-2.8,0.6-4.6
								C870.9,1188.1,869.6,1185.4,867.8,1182.9z"/>
							<path opacity="0.1091" fill="#131313" d="M842.9,1163.4c-0.7-0.5-1.4-1-2.2-1.4c2.4,4.1,4.8,8.2,7.2,12.3
								c-1.6-3.7-3.3-7.4-4.9-11.1c1.4,5.1,2.8,10.2,4.2,15.2c-0.7-3.3-1.4-6.5-2.1-9.8c0.3,2.6,0.6,5.1,0.9,7.7
								c-0.3-3.5-0.6-6.9-0.8-10.4c0,2.5,0,4.9,0,7.4c0.1-3.4,0.1-6.7,0.2-10.1c-0.3,2.4-0.5,4.8-0.8,7.2c0.5-3.3,1-6.7,1.6-10
								c-0.5,2.3-0.9,4.5-1.4,6.8c1.4-6.2,2.9-12.4,4.5-18.6c2.2-7.5,3.7-15.2,4.5-23.1c-0.2,1.5-0.5,3.1-0.7,4.6
								c0.9-4.5,1.8-8.9,2.7-13.4c-0.4,1.5-0.7,3-1.1,4.5c1.2-4.4,2.4-8.8,3.5-13.1c-0.5,1.5-0.9,3-1.4,4.5
								c1.5-4.3,2.9-8.6,4.4-12.9c-0.6,1.4-1.1,2.9-1.7,4.3c1.7-4.2,3.4-8.4,5.2-12.6c0.1-2.6-0.8-4.7-2.9-6.3
								c-1.9-1.8-4.1-2.4-6.6-1.9c-3.3,1.8-6.1,4.2-8.4,7.2c-2.6,2.7-4.5,5.9-5.8,9.4c-0.9,3.3-1.9,6.5-2.8,9.8
								c-1.3,5-2.3,10-3.1,15.1c-1.4,8.6-1.9,17.2-2.4,25.9c0.1-1.6,0.3-3.2,0.4-4.7c-1.6,6.2-3.2,12.3-4.9,18.5
								c0.4,4.7,1.5,9.2,3.3,13.6c1.5,4.5,3.6,8.7,6.4,12.5c1.6,1.7,3.5,3,5.6,4c2.3,1.7,4.7,2,7.3,0.8c2.6-1.2,3.9-3.2,4.1-6.1
								c-0.8-4.8-2.3-9.3-4.5-13.6C848.5,1171.2,846,1167.1,842.9,1163.4z"/>
							<path opacity="0.1091" fill="#131313" d="M840.7,1124.6c-0.7-0.4-1.3-0.9-2-1.3c7,12.6,13.9,25.2,20.9,37.8
								c-4.9-13.5-9.8-27-14.7-40.6c2.8,12,5.7,24,8.5,36.1c-1.6-9.6-3.1-19.3-4.7-28.9c0.9,8.8,1.7,17.7,2.6,26.5
								c-0.6-9.5-1.1-18.9-1.7-28.4c-0.1,8.7-0.1,17.3-0.2,26c0.4-9.4,0.8-18.7,1.2-28.1c-0.3,4.2-0.6,8.3-0.9,12.5
								c1.9-15.9,4.1-31.9,7.3-47.6c0.8-4.4,1.9-8.7,3.2-12.9c1.7-5.7,3.8-11.3,6.2-16.8c1.1-3,0.5-5.5-1.7-7.6
								c-2.5-1.8-5.1-2-7.8-0.4c-4.8,3.6-9,7.8-12.8,12.5c-4,4.5-7.5,9.3-10.2,14.7c-1.5,5.5-2.6,11.1-3.3,16.7
								c-1,5.6-1.5,11.3-1.7,17c0.4,4.8,1.2,9.6,2.3,14.4c1.2,7.9,2.9,15.6,5.3,23.3c1.5,4.4,2.9,8.8,4.4,13.2
								c1,5.3,3.6,9.6,7.8,12.7c1.2,0.8,2.5,1.6,3.7,2.3c1.8,1,3.7,1.7,5.7,2.1c3.1,0.9,5.3-0.1,6.7-3.1c1-5.2,0.1-10.2-2.7-14.9
								c-3.4-8.8-7.4-17.3-11.8-25.6C848.5,1131.1,845.3,1127.2,840.7,1124.6z"/>
							<path opacity="0.1091" fill="#131313" d="M867.8,1154c-2.1-1.1-4.3-2.3-6.4-3.4c2.3,6.9,4.7,13.9,7,20.8
								c-0.6-3.1-1.2-6.2-1.9-9.3c0.3,2.7,0.6,5.5,1,8.2c-0.2-6.8-0.5-13.5-0.7-20.3c-0.2,5.6-0.5,11.1-0.7,16.7
								c0.4-3.5,0.8-7,1.2-10.4c-0.5,2.7-1,5.5-1.6,8.2c1.3-6.9,2.8-13.8,4.6-20.7c1.4-8,2.7-16,4-24c1-5.8,2.4-11.6,4.1-17.3
								c1.7-5.7,3.8-11.3,6.2-16.7c0.8-2.9,0.2-5.3-2-7.3c-2.3-1.9-4.8-2.1-7.5-0.9c-4.4,3.1-8.2,6.8-11.6,11c-3.7,4-6.7,8.4-9,13.2
								c-1.4,6.5-2.5,13.1-3.1,19.8c-0.5,5-0.8,10-0.7,15c0,5,0,10,0.1,15c-1.3,4.5-1.3,8.9,0,13.2c0.4,2.2,1.6,3.6,3.6,4.2
								c1.6,1.2,3.4,1.4,5.5,0.8c1.1-0.7,2.1-1.5,3.2-2.2C865.2,1163.4,866.9,1158.8,867.8,1154z"/>
							<path opacity="0.1091" fill="#131313" d="M868.5,1132.4c-3.4-3.4-6.7-6.8-10.1-10.1c2.9,3.6,5.9,7.1,8.8,10.7
								c-0.3-0.6-0.6-1.2-0.9-1.8c1.7,4,3.4,8,5,12c-1.7-4.6-3.4-9.3-5.1-13.9c2.5,8.4,4.9,16.9,7.4,25.3
								c-2.3-9.1-4.5-18.3-6.8-27.4c1.7,8.8,3.4,17.5,5.2,26.3c-1.5-9.5-2.9-18.9-4.4-28.4c0.8,9,1.7,17.9,2.5,26.9
								c-0.5-9.6-1-19.2-1.5-28.7c-0.1,9.1-0.2,18.1-0.2,27.2c0.8-10.5,1.5-20.9,2.3-31.4C870,1123.5,869.2,1127.9,868.5,1132.4
								c1.9-8.3,3.5-16.7,4.6-25.1c0.5-6,1.4-11.9,2.7-17.8c1.1-5.8,2.5-11.6,4.3-17.2c1.8-5.7,3.9-11.2,6.3-16.6
								c0.3-1.7,0.6-3.4,1-5.2c-0.6-1.2-1.2-2.4-1.8-3.5c-1.3-0.4-2.5-0.8-3.8-1.2c-3.5,0.6-6.3,2.3-8.4,5.1
								c-6.2,5.6-11.6,12.1-16.7,18.6c-2.6,2.9-4.8,6-6.8,9.3c-2.4,2.6-3.5,5.7-3.5,9.2c-1.1,7.3-1.7,14.7-1.7,22.1
								c0,7.4,0.4,14.7,1.4,22c0.4,5.6,1.2,11.1,2.3,16.6c0.2,3.4,1.3,6.3,3.6,8.8c1.1,0.7,2.2,1.5,3.3,2.2c2.6,0.3,4.5-0.6,5.6-2.8
								c1.9-1.7,3-3.9,3.3-6.6C866,1144.5,867.5,1138.5,868.5,1132.4z"/>
							<path opacity="0.1091" fill="#131313" d="M828.3,1151.1c-0.7-0.5-1.4-0.9-2.1-1.4c3.1,5.5,6.1,11.1,9.2,16.6
								c-2-4.5-3.9-9-5.9-13.5c1.9,6.6,3.8,13.2,5.7,19.8c-1.8-7.3-3.6-14.6-5.4-21.9c1.2,6.4,2.4,12.8,3.6,19.3
								c-0.9-7.3-1.9-14.5-2.8-21.8c0.4,3,0.7,6.1,1.1,9.1c-0.1-3.9-0.3-7.7-0.4-11.6c-0.2,5.8-0.4,11.5-0.6,17.3
								c0.5-7.8,1.6-15.6,3.1-23.3c-0.4,2.5-0.9,5.1-1.3,7.6c3-11.2,5.3-22.6,6.9-34.2c-0.3,1.6-0.6,3.2-0.8,4.9
								c1-4.6,1.9-9.2,2.9-13.8c-0.4,1.5-0.7,3.1-1.1,4.6c1.2-4.5,2.5-8.9,3.7-13.4c-0.5,1.5-0.9,3-1.4,4.4c1.5-4.3,3-8.7,4.5-13
								c-0.5,1.4-1.1,2.8-1.6,4.2c1.8-4.2,3.5-8.4,5.3-12.6c0-2.5-1-4.6-3-6.2c-1.9-1.8-4.1-2.4-6.5-2c-3.1,1.7-5.8,3.9-8,6.8
								c-2.5,2.6-4.3,5.6-5.5,8.9c-1.1,3.4-2.3,6.9-3.4,10.3c-1.6,5.3-2.9,10.6-3.7,16c-1.7,9.2-2.4,18.5-3.2,27.8
								c0.2-1.7,0.4-3.4,0.6-5.1c-1.3,4.8-2.5,9.7-3.8,14.5c0.3,2.4,0.5,4.8,0.8,7.3c0.8,3.3,1.6,6.7,2.5,10
								c1.1,4.6,2.7,8.9,4.9,13.1c0.6,2,1.7,3.7,3.2,5.1c0.8,1.2,1.8,2.2,3,3c2.6,2.1,5.3,3.6,8.3,5c2.9,0.5,5.2-0.6,6.7-3.1
								c1.1-2.8,1-5.6-0.3-8.3c-1.2-4.9-3-9.6-5.4-14.1C835.3,1161.7,832.2,1156.2,828.3,1151.1z"/>
							<path opacity="0.1091" fill="#131313" d="M851.6,1185c-1.8-1.9-3.6-3.7-5.5-5.6c1.6,2.3,3.2,4.6,4.9,6.9
								c-1.4-2.5-2.7-5-4.1-7.5c1.1,3,2.2,6,3.2,9c0-0.6-0.1-1.3-0.1-1.9c-0.1,1.2-0.2,2.3-0.3,3.5c0.2-1.8,0.4-3.7,0.7-5.5
								c-0.2,1.1-0.5,2.2-0.7,3.4c0.6-2,1.2-3.9,1.8-5.9c-0.3,1.1-0.7,2.1-1,3.2c0.8-1.9,1.6-3.8,2.4-5.7c-0.4,1-0.9,2-1.3,3
								c1.5-3.4,3.3-6.7,4.8-10.2c0.5-1.3,1-2.6,1.6-3.9c0.6-2.8,1.1-5.6,1.4-8.4c0.4-2.9,0.8-5.8,1.1-8.7c0.4-4.4,1.2-8.7,2.4-13
								c0.8-2.8,1.6-5.7,2.4-8.5c1.7-5.7,3.9-11.2,6.3-16.7c0.4-1.1,0.8-2.2,1.2-3.3c-0.3-1-0.6-1.9-0.9-2.9
								c-0.8-0.9-1.6-1.9-2.4-2.8c-1.3-1.4-2.8-2.2-4.6-2.2c-1.1-0.5-2.2-0.3-3.1,0.6c-1.3,0.5-2.4,1.4-3.3,2.5
								c-1.2,1-2.3,2.2-3.1,3.6c-1.3,1.5-2.2,3.3-2.6,5.3c-3,8.1-5.5,16.4-7.2,24.9c-0.6,3.2-1.2,6.5-1.8,9.7
								c-0.5,6.6-1.1,13.1-1.8,19.7c0.8-2.7,1.6-5.4,2.4-8c-0.7,1.1-1.3,2.2-2,3.4c0.6-0.9,1.2-1.8,1.8-2.6c-2.4,3.3-5,6.7-7.2,10.2
								c-2.3,3.6-4,7.3-4,11.7c0,3.6,1.4,7.7,3.2,10.8c2.3,3.8,5.4,6.7,8.6,9.8c2.3,0.7,4.5,0.5,6.6-0.7c1.8-0.6,3.1-1.7,3.9-3.3
								c1.2-1.4,1.4-3,0.5-4.9C854.8,1190.6,853.5,1187.7,851.6,1185z"/>
							<path opacity="0.1091" fill="#131313" d="M888.9,1176.3c-4.6-9.8-9.2-19.7-13.8-29.5c2.3,6.9,4.6,13.8,6.9,20.7
								c-0.6-3-1.1-5.9-1.7-8.9c0.3,2.8,0.6,5.6,1,8.3c-0.3-3.2-0.6-6.5-0.9-9.7c0,2.9,0.1,5.8,0.1,8.7c0.1-3.3,0.2-6.7,0.2-10
								c-0.3,3-0.5,6-0.8,8.9c0.4-3.5,0.7-7.1,1.1-10.6c-0.6,3-1.1,6-1.7,9c1.3-7.4,2.9-14.7,4.7-22c1.2-8.2,2.5-16.5,4-24.7
								c1-5.9,2.4-11.7,4.1-17.4c1.7-5.8,3.8-11.4,6.3-16.9c1.3-3,0.9-5.7-1.4-8c-2.6-1.8-5.4-1.9-8.1-0.1c-5.2,4.1-9.9,8.7-14,13.9
								c-4.4,4.9-8.3,10.3-11.5,16.1c-1,6.5-1.6,13.1-1.9,19.7c-0.2,6.5,0,13.1,0.5,19.6c0.6,6.3,0.9,12.6,0.8,18.9
								c2.4,7.9,7.3,14.4,14.6,19.4c2.3,1.5,4.6,1.6,7.1,0.5C887.2,1180.9,888.6,1179,888.9,1176.3z"/>
							<path opacity="0.1091" fill="#131313" d="M832.7,1136.8c-0.7-0.4-1.3-0.9-2-1.3c4.9,7.5,9.7,15.1,14.6,22.6
								c-2.2-5-4.4-10.1-6.5-15.1c2.4,8,4.7,15.9,7.1,23.9c-2-9.4-4-18.7-6-28.1c0.5,3.8,1,7.7,1.5,11.5c-0.3-4.5-0.5-9-0.8-13.5
								c-0.1,7.4-0.2,14.8-0.4,22.2c0.5-8.2,0.9-16.4,1.4-24.6c-0.3,3.5-0.5,7-0.8,10.5c0.6-4.4,1.1-8.7,1.7-13.1
								c-0.6,3.4-1.1,6.7-1.7,10.1c2.2-9.6,3.8-19.2,4.9-29c0.7-4.5,1.5-8.9,2.4-13.3c0.8-4.4,1.9-8.8,3.3-13.1
								c1.8-5.8,3.9-11.5,6.3-17c0.6-2.8-0.2-5.1-2.3-6.9c-2.2-1.8-4.6-2.2-7.2-1.2c-4,2.7-7.5,6-10.5,9.8c-3.3,3.6-6,7.5-8,11.9
								c-1.7,5.4-3,10.8-3.9,16.3c-1.2,5.5-2,11.1-2.4,16.8c-0.5,6.1-0.7,12.2-0.5,18.3c-0.5,5.4,0.2,10.6,2.1,15.7
								c1.4,4.1,2.8,8.1,4.2,12.2c2.4,7,5.6,13.6,9.4,19.9c1.3,1,2.6,2.1,4,3.1c1.8,1.3,3.8,2.2,5.9,2.7c3.1,0.7,5.3-0.3,6.7-3.1
								c1.1-4.8,0.4-9.5-2.2-13.9c-3.2-8.1-6.8-16-11-23.6C840.1,1142.6,837.1,1139.1,832.7,1136.8z"/>
							<path opacity="0.1091" fill="#131313" d="M851.3,1136.2c1.8,1.6,3.5,3.2,5.3,4.7c2.2,7.5,4.4,15,6.5,22.6
								c-2-8.2-4-16.4-6-24.6c1.5,7.7,2.9,15.4,4.4,23.1c-1-9-1.9-18.1-2.9-27.1c-0.1,7.7-0.3,15.4-0.4,23
								c0.5-8.4,0.9-16.8,1.4-25.2c-0.3,3.8-0.6,7.5-0.9,11.3c2.1-15.4,4.3-30.7,7.4-46c0.8-4.4,1.9-8.7,3.2-13
								c1.7-5.7,3.8-11.4,6.2-16.8c1.1-3,0.6-5.6-1.7-7.7c-2.5-1.8-5.2-2-7.9-0.4c-4.9,3.7-9.2,8-13.1,12.9
								c-4.1,4.6-7.6,9.6-10.5,15c-1.3,5.3-2.2,10.7-2.7,16.1c-0.8,5.4-1.2,10.8-1.2,16.3c-0.6,4.5-0.4,8.8,0.6,13.1
								c0.9,5,2.1,9.8,3.8,14.6c1.3,3.7,2.5,7.5,3.8,11.2c2.2,6.4,5.1,12.5,8.7,18.2c2.8,2.5,5.9,4,9.4,5c3,0.6,5.2-0.5,6.7-3.1
								c1.3-4.3,0.7-8.5-1.6-12.6c-2.8-7.3-6-14.4-9.8-21.2C858.3,1141.1,855.5,1138,851.3,1136.2z"/>
							<path opacity="0.1091" fill="#131313" d="M878.8,1171.9c-2-2.3-4-4.5-6-6.8c0.9,1.1,1.8,2.1,2.8,3.2c-0.7-1.3-1.3-2.5-2-3.8
								c1.4,5.2,2.8,10.3,4.2,15.5c-0.1-7-0.1-14.1-0.2-21.1c-0.3,4.5-0.5,8.9-0.8,13.4c0.5-2.8,1.1-5.6,1.6-8.4
								c-0.5,2.2-1,4.5-1.4,6.7c1.7-5.7,3.2-11.5,4.7-17.3c1.8-7.5,3.1-15.1,4.1-22.8c1-5.8,2.3-11.4,4-17
								c1.7-5.7,3.7-11.2,6.1-16.6c0.8-2.8,0.1-5.2-2.1-7.2c-2.3-1.9-4.8-2.2-7.4-0.9c-4.3,3-8.1,6.6-11.4,10.7
								c-3.6,3.9-6.5,8.2-8.8,12.9c-1.3,7.7-2.3,15.4-2.7,23.2c-0.4,7.8-0.1,15.5,0,23.3c0.2-1.8,0.5-3.6,0.7-5.4
								c-0.5,3-0.9,6.1-1.4,9.1c2.5-6.2,5-12.4,7.6-18.5c-4,4.8-7.2,10.1-9.8,15.7c-0.8,3.6-0.8,7.2-0.1,10.8
								c0.4,3.7,1.4,7.1,3.2,10.3c1.2,2,2.6,3.8,4.1,5.5c1.4,1.8,3.1,3.4,4.8,4.9c2.3,0.9,4.5,0.8,6.7-0.3c1.9-0.6,3.2-1.8,3.9-3.5
								c1.2-1.6,1.3-3.4,0.3-5.5C882.5,1178.4,880.9,1175.1,878.8,1171.9z"/>
							<path opacity="0.1091" fill="#131313" d="M830,1163.5c-0.7-0.4-1.4-0.9-2.1-1.3c1.2,1.7,2.4,3.4,3.6,5.1
								c-0.3-0.5-0.6-1.1-1-1.6c1.1,2.6,2.2,5.2,3.4,7.8c-1.2-3.3-2.3-6.6-3.5-10c1.4,5.1,2.9,10.3,4.3,15.4
								c-0.8-3.4-1.5-6.9-2.3-10.3c0.3,2.5,0.6,5.1,0.9,7.6c-0.3-3.6-0.5-7.2-0.8-10.8c0,2.4,0,4.8,0,7.2c0.1-3.4,0.1-6.9,0.2-10.3
								c-0.2,2.2-0.5,4.5-0.7,6.7c0.7-3.4,1.3-6.8,2-10.2c-0.4,2.1-0.9,4.1-1.3,6.2c2.3-6.1,4.1-12.3,5.5-18.6
								c1.4-6,2.5-12,3.4-18.1c1.1-6.1,2.6-12.1,4.5-18c1.8-5.7,3.9-11.3,6.4-16.8c0.5-1.2,0.9-2.3,1.4-3.5c-0.3-1-0.6-2-0.8-3
								c-0.8-0.9-1.6-1.9-2.4-2.8c-1.3-1.4-2.9-2.2-4.7-2.2c-1.2-0.5-2.3-0.2-3.2,0.7c-1.4,0.6-2.5,1.5-3.4,2.7
								c-1.3,1.1-2.4,2.4-3.3,3.8c-1.4,1.6-2.4,3.4-2.9,5.6c-2.8,7.6-5.1,15.2-7,23.1c-0.9,3.5-1.5,7-2.1,10.6
								c-0.6,2.9-1,5.8-1.2,8.8c-0.5,5.2-1.3,10.4-2.4,15.5c1.2-3.7,2.4-7.4,3.5-11c-4.3,7.1-7,14.8-8.3,23.2
								c0.4,4.9,1.6,9.5,3.5,14c1.5,4.6,3.7,8.9,6.6,12.9c1.6,1.8,3.5,3.2,5.7,4.1c2.3,1.8,4.7,2.1,7.4,1c2.6-1.3,3.9-3.3,4-6.3
								c-0.9-5-2.5-9.8-4.8-14.3C835.9,1171.7,833.3,1167.4,830,1163.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M857.8,1123.7c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C848.5,1120.6,852.7,1123,857.8,1123.7z"/>
							<path opacity="0.1091" fill="#131313" d="M833.4,1104.8c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C827.1,1104.2,830,1105.3,833.4,1104.8z"/>
							<path opacity="0.1091" fill="#131313" d="M832.8,1070.8c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C827.9,1068.6,830.1,1070,832.8,1070.8z"/>
							<path opacity="0.1091" fill="#131313" d="M854.6,1097.4c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C847.4,1096.1,850.7,1097.6,854.6,1097.4z"/>
							<path opacity="0.1091" fill="#131313" d="M849.9,1066.6c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C843.8,1063.2,846.6,1065.2,849.9,1066.6z"/>
							<path opacity="0.1091" fill="#131313" d="M815.8,1093c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C810.6,1090.5,813,1092.1,815.8,1093z"/>
							<path opacity="0.1091" fill="#131313" d="M840.8,1125.5c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C832.9,1123.5,836.5,1125.3,840.8,1125.5z"/>
							<path opacity="0.1091" fill="#131313" d="M868.8,1094.1c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C860.8,1092.1,864.5,1093.9,868.8,1094.1z"/>
							<path opacity="0.1091" fill="#131313" d="M824,1081.5c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C818.8,1078.8,821.2,1080.5,824,1081.5z"/>
							<path opacity="0.1091" fill="#131313" d="M840.3,1077.9c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C833.9,1074.1,836.9,1076.3,840.3,1077.9z"/>
							<path opacity="0.1091" fill="#131313" d="M867.4,1110.2c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C858.3,1107.2,862.5,1109.5,867.4,1110.2z"/>
							<path opacity="0.1091" fill="#131313" d="M815.7,1103.9c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C809.5,1100.2,812.4,1102.4,815.7,1103.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M862.4,1079.5c1-2.5,1.9-4.9,2.9-7.4c0.5-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2.1c-3.6,1.8-6.8,4.3-9.5,7.3c-3,2.8-5.4,6-7.2,9.7c-0.8,2.2-1.6,4.4-2.4,6.5
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7C858.3,1086.1,860.7,1083,862.4,1079.5z"/>
							<path opacity="0.1091" fill="#131313" d="M839.2,1063.3c1.2-2.8,2.5-5.6,3.7-8.5c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.7-6.5-2.3c-3.4,1.6-6.4,3.9-9,6.7c-2.8,2.6-5,5.6-6.6,9.1c-1,2.6-2.1,5.1-3.1,7.7c-0.3,2.5,0.5,4.7,2.4,6.4
								c1.7,1.9,3.9,2.7,6.4,2.4c3.3-1.5,6.2-3.6,8.7-6.4C835.6,1069.6,837.7,1066.7,839.2,1063.3z"/>
							<path opacity="0.1091" fill="#131313" d="M844.7,1023c0-1.5,0-3.1,0-4.6c-0.6-2.9-2.4-4.6-5.3-5.1c-4.2,0.1-7.7,1.8-10.4,5.3
								c-5.3,4.7-10.3,9.8-14.8,15.3c-3.3,2.9-5,6.4-5,10.6c0,1.3,0,2.5,0,3.8c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5
								c5.3-4.6,10.3-9.6,14.9-14.9C843,1030.7,844.7,1027.2,844.7,1023z"/>
							<path opacity="0.1091" fill="#131313" d="M862.4,1050.1c0-1.5,0-3,0-4.5c-0.7-2.8-2.5-4.5-5.3-5.1c-4-0.1-7.2,1.4-9.9,4.7
								c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,1.3,0,2.5,0,3.8c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5
								c5-4.2,9.6-8.8,13.8-13.8C861.1,1057.3,862.6,1054,862.4,1050.1z"/>
							<path opacity="0.1091" fill="#131313" d="M865.7,1018.7c-0.1-1.4-0.2-2.9-0.3-4.3c-0.3-3.2-2-4.9-5.3-5.1
								c-5.2,0.9-9.5,3.5-13,7.8c-7,6.3-13.5,13.1-19.7,20.2c-4.2,3.5-6.7,7.9-7.5,13.2c0.1,1.1,0.3,2.2,0.4,3.4
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C862.4,1028.2,864.9,1023.9,865.7,1018.7z"/>
							<path opacity="0.1091" fill="#131313" d="M826.2,1049.6c1-2.7,2.1-5.3,3.1-8c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.7-1.9-3.8-2.7-6.3-2.5c-3.2,1.4-6,3.4-8.3,6c-2.6,2.3-4.6,5.1-5.9,8.3c-0.9,2.5-1.8,5.1-2.7,7.6
								c-0.1,2.5,0.7,4.6,2.6,6.3c1.7,1.9,3.8,2.7,6.3,2.6c3.1-1.3,5.8-3.2,8.1-5.8C823,1055.4,824.9,1052.7,826.2,1049.6z"/>
							<path opacity="0.1091" fill="#131313" d="M848.1,1080.7c0.9-2.5,1.8-5,2.7-7.5c-0.1-2.4-1.1-4.4-2.9-6
								c-1.6-1.8-3.6-2.8-6-2.9c-2.7,0.9-5.1,2.5-7,4.6c-2.2,1.9-3.7,4.3-4.7,7c-0.8,2.4-1.6,4.8-2.3,7.2c0.1,2.4,1.1,4.3,2.9,5.9
								c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,5-2.4,6.8-4.5C845.8,1085.6,847.3,1083.4,848.1,1080.7z"/>
							<path opacity="0.1091" fill="#131313" d="M877.2,1045.7c-0.1-1.5-0.1-2.9-0.2-4.4c-0.4-3.1-2.2-4.8-5.3-5.2
								c-4.7,0.5-8.6,2.7-11.7,6.5c-6.2,5.5-12,11.4-17.4,17.7c-3.8,3.2-5.9,7.2-6.3,11.9c0.1,1.2,0.1,2.4,0.2,3.6
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C874.6,1054.3,876.7,1050.4,877.2,1045.7z"/>
							<path opacity="0.1091" fill="#131313" d="M835.3,1037.8c0.1-1.5,0.2-3.1,0.2-4.6c0.8-2.7,0.2-5.1-1.8-7.1
								c-2.1-2-4.4-2.5-7.2-1.6c-4.2,2.5-7.9,5.5-11.2,9.2c-3.6,3.4-6.5,7.2-8.8,11.5c-0.1,1.4-0.2,2.8-0.3,4.1
								c-0.8,2.7-0.2,5.1,1.8,7.1c2,2,4.4,2.6,7.1,1.7c4.2-2.4,8-5.4,11.3-9C830,1045.8,833,1042,835.3,1037.8z"/>
							<path opacity="0.1091" fill="#131313" d="M853.7,1032.2c0-1.5,0-3,0-4.5c-0.6-2.9-2.4-4.6-5.3-5.1c-4.3,0.2-7.9,2.1-10.8,5.6
								c-5.6,4.9-10.7,10.2-15.5,15.9c-3.4,2.9-5.2,6.6-5.3,10.9c0,1.2,0,2.5,0,3.7c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C851.8,1040.1,853.6,1036.5,853.7,1032.2z"/>
							<path opacity="0.1091" fill="#131313" d="M873.8,1061.5c0-1.5,0-3,0-4.5c-0.7-2.8-2.5-4.5-5.3-5.2c-4-0.1-7.2,1.4-9.9,4.6
								c-5,4.3-9.6,9-13.8,14.1c-3.1,2.7-4.6,6-4.4,10c0,1.3,0,2.5,0,3.8c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4
								c5-4.2,9.6-8.8,13.8-13.8C872.5,1068.7,874,1065.4,873.8,1061.5z"/>
							<path opacity="0.1091" fill="#131313" d="M829,1061.5c0.9-2.5,1.8-5,2.7-7.6c-0.1-2.4-1-4.4-2.9-6c-1.6-1.8-3.6-2.8-6-2.9
								c-2.8,1-5.1,2.5-7.1,4.8c-2.2,2-3.8,4.3-4.7,7.1c-0.8,2.4-1.6,4.8-2.4,7.2c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9
								c2.7-0.9,5-2.4,6.9-4.6C826.6,1066.6,828.1,1064.3,829,1061.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M804.5,1123.2c2.4-7,5-13.9,7.6-20.8c2.1-5,4.5-9.7,7.2-14.3
								c2.5-4.6,5.3-9,8.4-13.3c4-5.6,8.4-10.9,13.2-15.9c2-2.1,2.5-4.5,1.6-7.2c-1-2.7-2.9-4.2-5.7-4.6c-5,0.3-9.7,1.4-14.4,3.2
								c-4.8,1.4-9.2,3.6-13.3,6.4c-4.4,5.8-8.5,11.9-12.2,18.2c-2.9,4.7-5.5,9.6-7.8,14.6c-2.5,5-4.8,10.1-6.7,15.4
								c-2.4,6.9-4.6,13.7-6.9,20.6c-1.3,2.4-1.3,4.8,0.2,7.2c1.4,2.4,3.4,3.6,6.1,3.7c4.3-1,8.4-2.7,12.1-5.1
								C798,1129.1,801.5,1126.5,804.5,1123.2z"/>
							<path opacity="0.1091" fill="#131313" d="M781.5,1106.6c3.9-6.9,7-14.2,9.4-21.8c2.1-5,4.5-9.7,7.3-14.3
								c2.5-4.6,5.4-9,8.5-13.3c4.1-5.6,8.5-10.8,13.4-15.8c1.4-2.2,1.6-4.4,0.7-6.9c-0.8-2.5-2.3-4.1-4.8-5
								c-3.7-0.1-7.3,0.6-10.8,2c-3.6,1.1-6.9,2.8-9.7,5.2c-4.9,5.7-9.5,11.7-13.7,18c-3.2,4.7-6.1,9.6-8.7,14.6
								c-2.8,5-5.3,10.1-7.4,15.4c-2.2,7.4-5,14.6-8.6,21.5c-1.9,4.3,1.7,10.3,6.3,10.8c3.5-0.6,6.8-1.8,9.8-3.8
								C776.5,1111.6,779.2,1109.4,781.5,1106.6z"/>
							<path opacity="0.1091" fill="#131313" d="M786.9,1068.1c0.9-3.4,1.9-6.8,2.8-10.2c-0.4,1.3-0.9,2.5-1.3,3.8
								c1.8-5.2,3.9-10.2,6.3-15c2.2-4.9,4.8-9.6,7.7-14.1c2-2.9,4-5.8,5.9-8.8c4.1-5.7,8.7-11,13.6-16c1.8-2.1,2.3-4.5,1.4-7.1
								c-0.9-2.6-2.8-4.2-5.5-4.7c-4.7,0.2-9.2,1.2-13.5,3c-4.5,1.4-8.6,3.4-12.4,6.1c-5.4,6.1-10.4,12.5-15,19.2
								c-4.5,6.7-8.6,13.6-12.3,20.8c-3.5,7-6.5,14.2-9.1,21.6c-1.7,4.5-3,9.1-4.1,13.8c0.1,2.8,1.3,4.9,3.7,6.3
								c3.5,1.2,6.9,0.8,10.1-1.5c5.5-2.6,10.7-5.7,15.6-9.2C784.2,1074.4,786.3,1071.7,786.9,1068.1z"/>
							<path opacity="0.1091" fill="#131313" d="M804.4,1095.1c1-3.4,2-6.9,3-10.3c-0.5,1.3-1,2.6-1.6,3.9c1.9-4.7,3.8-9.4,5.6-14
								c-0.5,1.3-1.1,2.6-1.6,3.9c1.5-3.3,3.1-6.5,4.6-9.8c2.4-4.7,5.1-9.3,8.2-13.6c1.8-3,3.9-5.8,6.1-8.5
								c3.1-4.2,6.5-8.1,10.1-11.9c2.3-2,3-4.5,2.2-7.4c-1.1-2.8-3.2-4.3-6.3-4.4c-5.7,0.6-11.2,1.9-16.5,4
								c-5.5,1.7-10.6,4.1-15.4,7.2c-4.8,6.1-9.2,12.5-13.2,19.1c-4,6.6-7.6,13.4-10.9,20.5c-2.2,4.8-4.2,9.7-6.1,14.6
								c-2.7,6.7-4.9,13.5-6.5,20.5c0,2.9,1.2,5,3.7,6.4c3.8,1.2,7.3,0.5,10.7-1.8c5.9-2.8,11.6-6.1,16.9-9.9
								C801.3,1101.7,803.6,1098.9,804.4,1095.1z"/>
							<path opacity="0.1091" fill="#131313" d="M807,1065.1c1.2-5.3,2.9-10.5,5.1-15.4c1.9-5,4.1-9.9,6.7-14.6
								c-0.8,1.4-1.7,2.9-2.5,4.3c2.6-4.4,5.2-8.9,7.8-13.3c-0.9,1.4-1.7,2.8-2.6,4.2c2.7-4.5,5.7-8.7,8.9-12.8
								c3.1-4.2,6.4-8.1,10-11.8c2.7-2,3.6-4.5,2.8-7.6c-1.4-2.9-3.7-4.3-7-4.2c-6.6,0.9-13,2.6-19.1,5c-6.3,2-12.3,4.7-18,8.2
								c-3.6,4.5-7,9.1-10.3,13.8c-4.7,6.8-9,13.8-12.9,21.1c-3.8,7.2-7.2,14.5-10.1,22.1c-2.9,6.7-5.2,13.6-6.8,20.8
								c-0.4,3.1,0.9,5.2,3.7,6.4c4.8,0.7,9.2-0.4,13.2-3.3c7.5-3.8,14.8-8,21.7-12.8C802.2,1072.9,805.3,1069.6,807,1065.1z"/>
							<path opacity="0.1091" fill="#131313" d="M768.5,1093.4c3.4-7.3,6.5-14.6,9.3-22.1c2.2-4.9,4.7-9.7,7.5-14.3
								c2.5-4.6,5.4-8.9,8.6-13.1c4.1-5.5,8.6-10.7,13.4-15.6c1-2.2,1-4.4,0-6.6c-0.6-2.3-2-4.1-4.1-5.2c-2.9-0.4-5.6,0-8.3,1.1
								c-2.8,0.8-5.2,2.2-7.3,4.2c-5.2,5.5-10,11.4-14.4,17.5c-3.4,4.6-6.4,9.4-9.2,14.4c-3,4.9-5.6,10-7.9,15.3
								c-2.7,7.5-5.6,14.9-8.7,22.2c-0.5,2.3-0.1,4.5,1.2,6.5c1.1,2.2,2.8,3.6,5.1,4.3c3-0.2,5.7-1.2,8.2-2.9
								C764.7,1097.8,766.8,1095.9,768.5,1093.4z"/>
							<path opacity="0.1091" fill="#131313" d="M789.9,1125.3c3.3-7.3,6.4-14.6,9.3-22c2.1-4.8,4.5-9.3,7.2-13.8
								c2.5-4.6,5.3-8.9,8.4-13.1c4-5.5,8.4-10.7,13.1-15.6c1.3-2.2,1.5-4.4,0.5-6.8c-0.7-2.5-2.3-4.1-4.6-5
								c-3.5-0.2-6.9,0.4-10.2,1.8c-3.4,1-6.5,2.6-9.1,4.9c-4.6,5.5-8.9,11.3-12.8,17.3c-3,4.5-5.7,9.3-8.1,14.1
								c-2.5,4.7-4.8,9.4-6.9,14.3c-2.6,7.4-5.3,14.7-8.4,21.9c-1.6,4,2.2,10.2,6.3,10.8c3.1-0.3,5.9-1.3,8.5-3
								C785.8,1129.8,788.1,1127.8,789.9,1125.3z"/>
							<path opacity="0.1091" fill="#131313" d="M819.6,1090.8c1.7-7.1,4.1-13.9,7.2-20.5c2.9-6.5,6.3-12.8,10.2-18.8
								c-0.9,1.4-1.8,2.8-2.7,4.3c2.9-4.3,5.9-8.6,8.8-12.9c-1,1.4-1.9,2.8-2.9,4.1c4.1-5.6,8.5-10.9,13.4-15.9
								c1.1-1.3,2.2-2.6,3.3-3.9c0.1-1.3,0.1-2.6,0.2-4c-0.9-1-1.7-2-2.6-3c-3.2-1.3-6.3-1.3-9.5-0.1c-7.8,1.6-15.4,4.3-22.8,7.2
								c-3.5,1.1-6.9,2.6-10.1,4.4c-3.2,1-5.7,3-7.3,6c-4.5,6.5-8.7,13.2-12.5,20.1c-2.9,5.1-5.5,10.4-7.8,15.8
								c-2.5,5.3-4.7,10.7-6.6,16.3c-2.7,6.7-4.8,13.5-6.3,20.5c-0.4,3.1,0.8,5.2,3.7,6.4c4.8,0.7,9.2-0.4,13.3-3.3
								c7.6-3.8,14.8-8,21.8-12.8C814.8,1098.6,817.9,1095.3,819.6,1090.8z"/>
							<path opacity="0.1091" fill="#131313" d="M775.2,1083.4c3.4-7,6.4-14.2,8.9-21.5c2.1-5,4.6-9.8,7.4-14.4
								c2.6-4.7,5.5-9.1,8.7-13.4c4.2-5.6,8.7-11,13.7-16c3.4-3.7,0.2-10.6-4.1-11.8c-3.9,0-7.6,0.7-11.2,2.2
								c-3.7,1.1-7.1,2.9-10.1,5.3c-5.3,5.9-10.3,12.1-14.8,18.6c-3.4,4.8-6.6,9.9-9.3,15.1c-3,5.1-5.6,10.4-7.9,15.9
								c-2.5,7.3-5.3,14.5-8.5,21.6c-1.2,2.4-1,4.7,0.4,7c1.3,2.4,3.3,3.6,5.9,3.8c4-0.9,7.8-2.4,11.3-4.7
								C769.3,1089,772.5,1086.5,775.2,1083.4z"/>
							<path opacity="0.1091" fill="#131313" d="M795.6,1077.3c1.7-7,4-13.9,7.1-20.4c-0.6,1.3-1.1,2.6-1.7,3.9
								c1.5-3.3,3.1-6.5,4.6-9.8c2.4-4.7,5.1-9.3,8.2-13.6c1.8-3,3.9-5.8,6.1-8.5c3.1-4.2,6.5-8.2,10.2-11.9
								c2.2-2.1,2.8-4.5,1.9-7.3c-1.1-2.8-3.1-4.3-6.1-4.5c-5.4,0.5-10.6,1.8-15.7,3.8c-5.2,1.6-10.1,3.9-14.6,6.9
								c-5,6.3-9.9,12.7-14.3,19.4c-4.7,7.3-9,14.9-12.5,22.8c-1.6,3.6-3.2,7.2-4.7,10.9c-1.1,2.4-2,4.8-2.8,7.3
								c-2,5-3.6,10.1-4.8,15.3c-0.1,2.9,1.2,5,3.7,6.4c3.9,1.1,7.5,0.5,10.9-2c6.1-2.9,11.8-6.3,17.3-10.2
								C792.3,1084.1,794.7,1081.2,795.6,1077.3z"/>
							<path opacity="0.1091" fill="#131313" d="M815.8,1106.4c1.8-7.1,4.2-13.9,7.3-20.6c-0.6,1.3-1.3,2.7-1.9,4
								c2.2-4.6,4.5-9.2,6.7-13.8c-0.7,1.3-1.3,2.7-2,4c3.3-6.3,7-12.3,11.2-18c4.1-5.6,8.5-11,13.4-16c0.9-1.2,1.8-2.5,2.8-3.7
								c0-1.3,0-2.6,0-3.9c-0.8-1-1.6-2-2.4-3.1c-2.8-1.4-5.6-1.5-8.4-0.4c-6.9,1.3-13.5,3.6-20.1,6.2c-3.1,1-6,2.3-8.8,4
								c-2.9,0.9-5,2.8-6.3,5.6c-4.3,6.2-8.3,12.7-11.9,19.3c-2.8,5-5.3,10.1-7.5,15.3c-2.4,5.2-4.6,10.5-6.4,15.9
								c-2.6,6.7-4.7,13.5-6.4,20.5c-0.1,2.9,1.1,5,3.7,6.4c4,1,7.8,0.3,11.4-2.2c6.4-3.1,12.5-6.6,18.3-10.6
								C812.2,1113.4,814.7,1110.5,815.8,1106.4z"/>
							<path opacity="0.1091" fill="#131313" d="M769.6,1106.9c3.5-7.3,6.7-14.7,9.5-22.3c2.2-4.9,4.7-9.7,7.6-14.3
								c2.6-4.6,5.5-9,8.7-13.2c4.1-5.5,8.6-10.8,13.5-15.7c0.9-2.2,0.8-4.4-0.1-6.6c-0.6-2.3-1.9-4.1-4-5.3
								c-2.7-0.4-5.3-0.1-7.9,0.9c-2.7,0.7-4.9,2.1-6.8,4.1c-5.1,5.5-9.8,11.3-14.1,17.4c-3.3,4.6-6.3,9.3-9,14.3
								c-2.9,4.9-5.5,9.9-7.8,15.1c-2.7,7.5-5.6,14.8-8.6,22.2c-0.3,2.3,0.2,4.4,1.5,6.4c1.1,2.1,2.7,3.6,4.8,4.4
								c2.6,0,5-0.8,7.1-2.3C766.4,1110.8,768.3,1109.1,769.6,1106.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M917.7,1076c1.7-3.8,2.9-7.7,3.8-11.8c1.2-4.4,2-8.9,2.5-13.4
								c0.3-3.2,0.5-6.4,0.8-9.7c0.3-6.7,0-13.4-0.9-20.1c-0.5-2.7-1.9-4.7-4.2-6c-3.1-1.2-5.9-0.7-8.6,1.4
								c-4.5,2.5-8.6,5.4-12.4,8.7c-2.9,1.8-4.3,4.4-4.2,7.7c-0.5,8.9-1.3,17.6-3.5,26.3c-0.8,2.8-1.6,5.5-2.3,8.3
								c-1.5,4.8-3.2,9.6-5.3,14.2c-0.3,2.7,0.6,5,2.7,6.9c3,1.8,6.2,2,9.5,0.4c5.4-1.5,10.6-3.6,15.6-6.1
								C914.5,1081.7,916.7,1079.4,917.7,1076z"/>
							<path opacity="0.1091" fill="#131313" d="M892.5,1062.9c3.6-3.4,6-7.5,7.1-12.2c0.8-4.2,1.6-8.4,2.2-12.7
								c0.9-4.7,1.4-9.5,1.4-14.3c0.2-6.7-0.2-13.4-1.1-20.1c-0.6-2.6-2.1-4.6-4.3-6c-2.6-1.3-5.2-1.1-7.6,0.7
								c-3.8,2-7.3,4.5-10.4,7.4c-2.5,1.7-3.6,4.1-3.2,7c0.2,2.9,0.3,5.8,0.5,8.7c0.1,4.3-0.1,8.6-0.7,12.9
								c-0.8,6.6-2.2,13-3.5,19.5c0.3-1.1,0.7-2.1,1-3.2c-1.8,2.7-3.5,5.4-5.3,8.2c-2.6,3.9,0.1,10.5,4.6,11.6c3.6,0,7-0.8,10.3-2.3
								C886.9,1067.2,889.9,1065.4,892.5,1062.9z"/>
							<path opacity="0.1091" fill="#131313" d="M898.4,1024.9c1.8-3.8,3.1-7.8,3.9-11.9c1.2-4.4,2-8.9,2.5-13.5
								c0.2-3.2,0.4-6.4,0.7-9.7c0.2-6.7-0.2-13.4-1.1-20.1c-0.3-2.8-1.7-4.8-4.3-6c-3.7-0.9-7.1-0.1-10.1,2.5
								c-5.4,3.1-10.5,6.7-15.2,10.8c-3.4,2-5.3,4.9-5.7,8.7c1,7.7,1.8,15.4,0.9,23.1c-0.4,2.6-0.8,5.2-1.2,7.8
								c-0.9,4.8-2.4,9.4-4.4,13.8c-0.2,2.7,0.7,5,2.7,6.8c3,1.8,6.1,1.9,9.4,0.4c5.3-1.5,10.5-3.6,15.4-6.1
								C895.3,1030.5,897.5,1028.3,898.4,1024.9z"/>
							<path opacity="0.1091" fill="#131313" d="M916.1,1051.7c2.9-8.3,5.5-16.5,6.3-25.3c0.2-3.2,0.4-6.4,0.7-9.6
								c0.2-6.7-0.2-13.3-1.1-19.9c-0.2-2.9-1.6-4.9-4.2-6c-3.8-0.8-7.4,0.1-10.5,2.7c-5.7,3.3-11,7.1-16,11.3
								c-3.6,2.1-5.6,5.1-6.1,9c-0.1,8.2-0.4,16.2-2.2,24.2c-1.7,7.4-4.2,14.5-6.9,21.6c-0.5,2.8,0.4,5.1,2.7,6.9
								c3.6,1.7,7.2,1.6,10.9-0.2c6.3-1.9,12.4-4.3,18.4-7.3C912,1057.9,914.7,1055.4,916.1,1051.7z"/>
							<path opacity="0.1091" fill="#131313" d="M918.2,1022.3c3-8.5,5.7-16.7,6.7-25.7c0.3-3.3,0.5-6.5,0.8-9.8
								c0.2-6.8,0-13.6-0.9-20.4c0.2-3.1-1.2-5.1-4.2-6c-4.9-0.3-9.3,1.2-13.2,4.5c-7.4,4.5-14.4,9.4-21.1,14.8
								c-4.4,2.5-7.4,6.1-8.8,10.8c0.4,7.4,0.6,14.7-0.7,22c-0.5,2.5-1,4.9-1.5,7.4c-1.4,4.5-2.9,9-4.6,13.4
								c-0.7,2.9,0.2,5.2,2.7,6.8c4.3,1.5,8.6,1.2,12.8-1c7.5-2.4,14.8-5.3,21.9-8.7C912.8,1029.1,916.1,1026.4,918.2,1022.3z"/>
							<path opacity="0.1091" fill="#131313" d="M879.2,1050.2c4.6-5.1,7.2-11,7.7-17.8c0.7-3.6,1.4-7.3,1.9-10.9
								c0.6-3.8,0.9-7.6,1-11.4c0.3-6.8,0-13.5-0.9-20.3c-0.7-2.5-2.1-4.5-4.1-6.1c-2.2-1.5-4.5-1.5-6.8,0c-3.3,1.6-6.3,3.6-9,6
								c-2.3,1.6-3.1,3.7-2.5,6.3c0.8,4.3,1.2,8.6,1.2,12.9c0.3,4.4,0.1,8.8-0.5,13.2c0.2-1,0.3-2,0.5-3.1c-1,6.5-2,13.1-3.1,19.6
								c0.2-1,0.4-2,0.5-3c-1.7,2.9-3.3,5.8-5,8.7c-0.8,2.2-0.6,4.4,0.4,6.6c0.7,2.3,2.2,4,4.2,5.1c2.7,0.3,5.4-0.2,7.9-1.3
								C875.2,1053.8,877.4,1052.3,879.2,1050.2z"/>
							<path opacity="0.1091" fill="#131313" d="M898.9,1082.8c5.3-4.9,8.4-10.9,9.4-17.9c0.7-2.6,1.1-5.2,1.4-7.9
								c0.8-4.9,1.3-9.8,1.4-14.8c0.2-6.9-0.2-13.8-1.2-20.6c-0.7-2.5-2-4.5-4-6.2c-2.2-1.5-4.4-1.6-6.7-0.2
								c-3.3,1.5-6.3,3.4-8.9,5.8c-2.2,1.6-3.1,3.6-2.5,6.2c0.5,4.7,0.5,9.5,0.1,14.2c-0.1,4.8-0.6,9.6-1.5,14.3
								c0.2-1.1,0.4-2.1,0.6-3.2c-0.8,4-1.6,8-2.4,12c0.2-1.1,0.5-2.1,0.7-3.2c-0.8,6.3-3.6,11.8-8.2,16.4
								c-2.5,3.7,0.6,10.4,4.6,11.7c3.2,0.1,6.3-0.5,9.3-1.9C893.9,1086.7,896.6,1085.1,898.9,1082.8z"/>
							<path opacity="0.1091" fill="#131313" d="M931.6,1047.6c2.8-8.4,5.4-16.6,6.2-25.5c0.2-3.2,0.4-6.4,0.6-9.7
								c0.1-6.7-0.3-13.4-1.3-20c0.2-3.1-1.3-5.1-4.2-6c-4.8-0.4-9.1,1.1-12.9,4.3c-7.2,4.3-14.1,9.1-20.7,14.4
								c-4.4,2.5-7.2,6-8.5,10.6c-0.6,7.8-1.3,15.6-3.3,23.2c-0.7,2.5-1.5,5.1-2.2,7.6c-1.8,4.4-3.5,8.9-5,13.5
								c-0.9,3,0,5.3,2.7,6.9c4.7,1.4,9.3,1,13.8-1.3c8.2-2.6,16.1-5.8,23.9-9.4C925.4,1054.6,929.1,1051.8,931.6,1047.6z"/>
							<path opacity="0.1091" fill="#131313" d="M885.3,1040c4-3.4,6.7-7.6,8-12.5c0.9-4.2,1.6-8.4,2.2-12.7
								c0.9-4.7,1.3-9.4,1.3-14.2c0.1-6.7-0.3-13.3-1.2-19.9c-0.5-2.7-1.9-4.7-4.3-6c-3-1.2-5.9-0.7-8.6,1.4
								c-4.4,2.4-8.5,5.3-12.2,8.7c-2.9,1.8-4.3,4.4-4.1,7.6c0.4,2.7,0.7,5.3,1.1,8c0.4,4.1,0.4,8.1,0.1,12.2
								c-0.4,6.4-1.6,12.7-2.7,19c0.4-1.1,0.7-2.2,1.1-3.3c-2,2.8-3.9,5.5-5.9,8.3c-2.9,4,0,10.5,4.6,11.6c3.8-0.1,7.4-1,10.9-2.5
								C879.2,1044.4,882.4,1042.5,885.3,1040z"/>
							<path opacity="0.1091" fill="#131313" d="M907.2,1034c2.9-8.3,5.5-16.5,6.4-25.3c0.2-3.2,0.5-6.4,0.7-9.6
								c0.2-6.7-0.1-13.4-1-20c-0.2-2.9-1.6-4.9-4.2-6c-4-0.8-7.6,0.2-10.9,3c-5.9,3.5-11.5,7.4-16.7,11.8c-3.7,2.1-5.9,5.2-6.5,9.2
								c0.5,7.8,0.8,15.4-0.5,23.2c-0.5,2.6-1,5.1-1.6,7.7c-1.3,4.6-2.8,9.2-4.6,13.7c-0.4,2.8,0.5,5.1,2.7,6.8
								c3.5,1.7,7,1.7,10.6-0.1c6.1-1.8,12-4.2,17.7-7C903.3,1040,905.9,1037.6,907.2,1034z"/>
							<path opacity="0.1091" fill="#131313" d="M927.8,1063c1.6-3.9,2.9-7.9,3.8-11.9c1.3-4.4,2.1-8.9,2.5-13.4
								c0.2-3.2,0.4-6.4,0.6-9.6c0.1-6.7-0.3-13.3-1.2-19.9c-0.1-2.9-1.5-4.9-4.2-6c-4.1-0.7-7.7,0.3-11.1,3.1
								c-6,3.5-11.7,7.5-17.1,11.9c-3.8,2.2-6,5.3-6.7,9.3c-0.7,8.3-1.6,16.5-3.8,24.6c-2,7.4-4.9,14.5-7.7,21.6
								c-0.7,2.9,0.2,5.2,2.7,6.9c4,1.6,8.1,1.4,12.1-0.7c7.1-2.2,14-4.9,20.7-8.1C922.8,1069.5,925.9,1066.9,927.8,1063z"/>
							<path opacity="0.1091" fill="#131313" d="M880.5,1063.3c4.7-5.1,7.3-11,7.7-17.8c0.9-4.1,1.6-8.2,1.9-12.4
								c0.3-3.3,0.6-6.5,0.7-9.8c0.2-6.8-0.2-13.6-1-20.3c-0.8-2.4-2.1-4.5-4.1-6.2c-1.9-1.6-4-1.7-6.2-0.5c-2.9,1.3-5.6,3-7.9,5.2
								c-2,1.5-2.7,3.5-2,5.9c0.9,6,1.2,12,1,18.1c0.1-1,0.1-2,0.2-3c-0.3,4-0.6,7.9-0.9,11.9c0.1-1,0.2-2,0.3-3
								c-0.6,4.2-1.3,8.5-2,12.7c-0.3,4.8-2.2,9.1-5.7,12.7c-0.6,2.2-0.5,4.4,0.6,6.5c0.7,2.2,2.1,3.9,4.1,5.1
								c2.5,0.4,4.9,0,7.2-1.1C877,1066.7,879,1065.3,880.5,1063.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M865.2,1056.3c1.7,5.5,3.4,11,5.1,16.5c1.4,2.7,3.5,3.9,6.6,3.4
								c4.3-1.8,7.3-4.9,9.1-9.4c4.2-6.9,7.8-14,11-21.4c2.6-4.1,3.4-8.4,2.3-12.9c-1.6-5.2-3.2-10.3-4.8-15.5
								c-1.3-2.7-3.5-3.9-6.5-3.5c-4.3,1.7-7.3,4.7-9.2,9.1c-4.2,6.7-7.9,13.7-11.2,21C865,1047.6,864.2,1051.8,865.2,1056.3z"/>
							<path opacity="0.1091" fill="#131313" d="M843.2,1038.6c1.7,5.5,3.4,11,5,16.5c1.4,2.6,3.6,3.8,6.5,3.4
								c4.1-1.5,7-4.4,8.8-8.6c3.9-6.4,7.4-13.1,10.3-20c2.5-3.9,3.1-7.9,2-12.1c-1.6-5.2-3.2-10.4-4.7-15.5
								c-1.4-2.6-3.5-3.8-6.5-3.5c-4.1,1.4-7,4.2-8.8,8.4c-4,6.3-7.5,12.8-10.5,19.6C842.8,1030.4,842.1,1034.4,843.2,1038.6z"/>
							<path opacity="0.1091" fill="#131313" d="M845.9,999.4c1.1,6.9,2.1,13.8,3.2,20.6c0.7,4.4,7,7.7,11,5.9
								c2.6-2.1,4.6-4.7,6-7.7c1.8-2.9,2.8-6,3.1-9.3c-1.2-6.6-2.5-13.2-3.7-19.7c-0.8-4.3-7-7.6-11-5.9c-2.5,2-4.4,4.4-5.8,7.3
								C847.1,993.3,846.2,996.2,845.9,999.4z"/>
							<path opacity="0.1091" fill="#131313" d="M864.6,1030.7c1.6,5.5,3.2,11.1,4.9,16.6c1.6,2.2,3.8,3.3,6.6,3.4
								c3.4-0.6,5.8-2.5,7.3-5.8c3-4.6,5.5-9.5,7.5-14.6c1.9-3.1,2-6.2,0.5-9.3c-1.5-5.3-3.1-10.6-4.6-16c-1.6-2.2-3.8-3.4-6.5-3.5
								c-3.4,0.5-5.8,2.4-7.3,5.6c-3,4.5-5.6,9.3-7.6,14.3C863.3,1024.6,863.1,1027.6,864.6,1030.7z"/>
							<path opacity="0.1091" fill="#131313" d="M864.6,997.8c1.3,6.4,2.6,12.8,4,19.2c0.9,2.1,2.5,3.7,4.6,4.7
								c2,1.2,4.1,1.7,6.4,1.3c2.2-1.4,3.8-3.2,4.9-5.6c1.4-2.2,2-4.6,2-7.2c-1.5-6.1-3-12.2-4.4-18.4c-1-2.1-2.6-3.6-4.7-4.5
								c-2-1.2-4.1-1.7-6.4-1.4c-2.1,1.2-3.6,3-4.7,5.2C865,993.1,864.5,995.3,864.6,997.8z"/>
							<path opacity="0.1091" fill="#131313" d="M828.5,1026.7c1.7,5.5,3.4,10.9,5.1,16.4c1.5,2.5,3.7,3.6,6.5,3.4
								c3.9-1.1,6.6-3.6,8.2-7.5c3.6-5.7,6.6-11.6,9.2-17.8c2.2-3.6,2.7-7.2,1.4-11c-1.6-5.2-3.2-10.4-4.8-15.5
								c-1.5-2.5-3.6-3.6-6.5-3.5c-3.8,1-6.6,3.4-8.2,7.3c-3.6,5.6-6.7,11.4-9.4,17.5C827.7,1019.3,827.2,1022.9,828.5,1026.7z"/>
							<path opacity="0.1091" fill="#131313" d="M848.1,1061.4c1.7,5.5,3.4,11,5.2,16.5c1.2,3,3.4,4.1,6.5,3.4
								c4.7-2.4,8-6.1,10.1-11.2c4.8-8.1,9.1-16.4,12.8-25c3-4.6,4-9.5,3.3-14.7c-1.6-5.1-3.2-10.2-4.8-15.3c-1.2-3-3.4-4.1-6.5-3.5
								c-4.7,2.3-8.1,5.9-10.1,10.9c-4.8,7.9-9.2,16-13,24.4C848.5,1051.5,847.4,1056.3,848.1,1061.4z"/>
							<path opacity="0.1091" fill="#131313" d="M878.6,1021.5c1,6.7,1.9,13.5,2.9,20.2c0.7,4.9,6.7,8.1,11.2,5.7
								c2.8-2.7,5.1-5.8,6.7-9.4c2-3.4,3.2-7.1,3.7-10.9c-1.1-6.3-2.1-12.6-3.2-19c-0.8-4.7-6.6-8.1-11.1-5.9
								c-2.8,2.5-5,5.4-6.6,8.9C880.3,1014.3,879.1,1017.7,878.6,1021.5z"/>
							<path opacity="0.1091" fill="#131313" d="M836.6,1011.1c1.6,5.6,3.1,11.2,4.7,16.9c0.1,2.8,1.4,4.8,3.9,6.1
								c2.5,1.3,4.9,1.2,7.2-0.3c3.2-3.3,5.7-7.1,7.7-11.3c2.3-4,3.9-8.3,4.7-12.8c-1.5-5.3-3.1-10.7-4.6-16
								c-0.1-2.8-1.4-4.8-3.9-6.1c-2.4-1.3-4.8-1.3-7.2,0.1c-3.2,3.2-5.8,6.8-7.7,10.9C839.1,1002.5,837.4,1006.6,836.6,1011.1z"/>
							<path opacity="0.1091" fill="#131313" d="M856,1006.8c0.9,7,1.7,14.1,2.6,21.1c0.6,4.8,6.8,8,11.1,5.8c2.8-2.5,5-5.5,6.5-9
								c1.9-3.3,3.1-6.8,3.6-10.5c-1-6.6-2-13.2-3-19.8c-0.7-4.6-6.8-7.9-11-5.9c-2.7,2.3-4.8,5.1-6.4,8.4
								C857.6,999.9,856.5,1003.3,856,1006.8z"/>
							<path opacity="0.1091" fill="#131313" d="M875.8,1042.4c1.7,5.4,3.4,10.8,5.1,16.2c1.6,2.3,3.8,3.4,6.6,3.3
								c3.6-0.8,6.2-3,7.7-6.6c3.2-5.1,5.9-10.4,8.2-16c2-3.3,2.3-6.7,0.8-10.1c-1.6-5.2-3.2-10.3-4.8-15.5
								c-1.5-2.3-3.7-3.5-6.5-3.5c-3.6,0.7-6.2,2.9-7.7,6.4c-3.3,5-6.1,10.2-8.4,15.8C874.7,1035.7,874.3,1039,875.8,1042.4z"/>
							<path opacity="0.1091" fill="#131313" d="M829.9,1040.5c1.7,5.5,3.4,11,5.1,16.5c1.3,2.8,3.5,3.9,6.5,3.4
								c4.3-1.8,7.4-5,9.2-9.5c4.2-7,8-14.2,11.2-21.7c2.6-4.1,3.5-8.5,2.4-13c-1.6-5.2-3.2-10.3-4.8-15.5c-1.3-2.8-3.5-3.9-6.5-3.5
								c-4.3,1.7-7.4,4.8-9.3,9.2c-4.3,6.8-8.1,13.9-11.4,21.3C829.8,1031.7,829,1036,829.9,1040.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M889.1,1060.5c2.1,3.3,3.7,6.8,4.9,10.5c1.4,3.6,2.6,7.2,3.5,11
								c-0.4-1.6-0.8-3.2-1.2-4.9c0.9,4.1,1.8,8.1,2.7,12.2c-0.3-1.6-0.7-3.2-1-4.9c0.4,2.4,0.7,4.9,1.1,7.3
								c0.3,1.6,0.6,3.2,0.8,4.8c1,0.9,1.9,1.8,2.9,2.7c1.3,0,2.6,0,3.9,0.1c3-1.4,5-3.6,6.1-6.7c3.8-6.6,6.7-13.7,9.3-20.9
								c1.4-3.2,2.5-6.6,3.1-10.1c1.3-3,1.4-6,0.2-9.1c-2-7.3-4.1-14.5-6.8-21.6c-1-2.7-2.1-5.3-3.2-7.9c-1.3-3.5-2.9-6.9-5-10
								c-1.8-2.5-4.2-3.2-7.1-2c-3.9,2.8-6.4,6.6-7.3,11.6c-2.9,7.9-5.2,16-7,24.3C887.3,1051.5,887.3,1056,889.1,1060.5z"/>
							<path opacity="0.1091" fill="#131313" d="M867.2,1041.9c2.6,4.5,4.6,9.3,6.2,14.3c-0.5-1.4-1-2.8-1.4-4.3
								c1.2,3.9,2.4,7.8,3.6,11.7c-0.4-1.4-0.9-2.9-1.3-4.3c1.3,4.9,2.2,9.8,2.7,14.8c0.3,1.5,0.7,2.9,1,4.4c1,0.8,2,1.7,3,2.5
								c1.3,0.1,2.6,0.2,3.9,0.2c2.8-1.1,4.8-3,5.8-5.8c3.5-5.8,6.1-12.2,8.4-18.6c1.3-2.9,2.2-5.9,2.7-9c1.2-2.7,1.2-5.5-0.1-8.2
								c-2.1-6.8-4.3-13.4-7-20c-1-2.5-2.1-5-3.2-7.5c-1.4-3.4-3.1-6.8-5-10c-1.9-2.2-4.3-2.9-7.1-2c-3.7,2.1-5.9,5.2-6.8,9.6
								c-2.5,6.7-4.5,13.5-5.9,20.5C865,1034.3,865.2,1038.2,867.2,1041.9z"/>
							<path opacity="0.1091" fill="#131313" d="M868.2,1005.1c0.9,3.4,1.9,6.8,2.8,10.1c1.8,3.4,3.1,7,4,10.8
								c1.6,6,2.5,12,3.3,18.2c0.9,2.3,2.5,3.9,4.8,4.7c2.3,1.1,4.5,1.1,6.7,0.1c2.3-2.3,4.1-5,5.2-8.1c1.4-3,2.1-6.1,2.1-9.4
								c-1.2-6.3-2.6-12.6-4.5-18.8c-0.6-2.6-1.6-5-2.9-7.3c-2.2-4.1-3.8-8.4-4.8-13c-1.2-2.1-3-3.4-5.4-3.9
								c-2.3-0.8-4.5-0.7-6.6,0.4c-2,2.2-3.3,4.7-3.9,7.6C867.9,999.4,867.7,1002.2,868.2,1005.1z"/>
							<path opacity="0.1091" fill="#131313" d="M886.6,1031c2.8,5.8,5.5,11.5,7.4,17.7c0.9,2.4,1.5,4.9,2,7.4
								c0.9,3.7,1.5,7.4,1.9,11.1c0.3,2.8,1.8,4.7,4.4,5.8c2.6,1.1,5,0.7,7.2-1.1c2.9-3.8,5.2-7.9,6.8-12.4c2-4.4,3.2-8.9,3.7-13.7
								c-1.5-7-3.2-13.9-5.7-20.7c-2.2-6.2-5-12-7.9-17.9c-0.6-2.8-2.3-4.6-5-5.3c-2.7-0.8-5.1-0.2-7.1,1.9
								c-2.5,4.1-4.4,8.5-5.5,13.1C887.4,1021.5,886.7,1026.2,886.6,1031z"/>
							<path opacity="0.1091" fill="#131313" d="M887.1,1003.2c1,3.3,1.9,6.6,2.9,9.9c2.2,2.8,3.7,6,4.4,9.5
								c1.8,6.1,2.7,12.2,3.5,18.6c1.1,2,2.8,3.4,5,4.2c2.1,1.1,4.3,1.3,6.5,0.6c2.1-1.6,3.5-3.6,4.4-6.1c1.2-2.4,1.6-4.8,1.3-7.4
								c-1-6.7-2.2-13.3-4.2-19.9c-0.4-2.5-1.4-4.8-3-6.8c-2.6-3.7-4.2-7.8-5-12.3c-1.4-1.8-3.2-3-5.5-3.5c-2.2-0.8-4.4-0.8-6.5,0
								c-1.8,1.6-3,3.7-3.5,6.1C886.6,998.5,886.5,1000.8,887.1,1003.2z"/>
							<path opacity="0.1091" fill="#131313" d="M852.5,1030.3c1.2,2.2,2.4,4.5,3.5,6.7c-0.6-1.2-1.1-2.4-1.7-3.6
								c2.1,4.7,3.8,9.5,5.1,14.5c1.3,4.8,2.2,9.7,2.7,14.7c0.1,3,1.5,5.1,4.2,6.3c2.8,1,5.3,0.5,7.4-1.6c3.2-4.4,5.8-9.2,7.6-14.3
								c2.3-5,3.8-10.2,4.6-15.6c-1.9-6.5-3.8-12.8-6.4-19.1c-2.9-7.1-6.2-13.9-9.9-20.6c-2-2-4.3-2.7-7.1-2.1
								c-3.4,1.4-5.4,4-6.2,7.8c-2.2,5.5-3.8,11.2-4.9,17C850,1024,850.4,1027.3,852.5,1030.3z"/>
							<path opacity="0.1091" fill="#131313" d="M872.6,1065.4c2.7,4.4,4.8,9.2,6.2,14.2c1.6,4.8,2.7,9.7,3.5,14.7
								c-0.3-1.8-0.6-3.6-0.8-5.4c0.5,4.2,1.1,8.5,1.6,12.7c1.5,2.9,3.8,3.8,6.8,2.7c4.4-2.8,7.4-6.9,9-12.1
								c4-8.5,7.5-17.2,10.4-26.1c2.5-4.9,3.1-9.9,1.9-15c-2.2-7.1-4.5-14.1-7.4-20.9c-1.1-2.6-2.2-5.2-3.3-7.8
								c-1.3-3.5-3-6.7-5.1-9.8c-1.8-2.7-4.1-3.4-7.1-2c-4.1,3.2-6.7,7.5-7.7,12.9c-3.1,8.8-5.7,17.8-7.8,26.9
								C871,1055.4,870.9,1060.4,872.6,1065.4z"/>
							<path opacity="0.1091" fill="#131313" d="M901,1026c0.9,3.2,1.8,6.5,2.6,9.7c1.8,3.3,3.1,6.7,4.1,10.3
								c0.9,2.4,1.5,4.9,1.9,7.5c0.9,3.7,1.5,7.4,1.8,11.2c0.7,5.1,7.6,7.4,11.6,4.7c2.6-3.1,4.6-6.5,6-10.4
								c1.7-3.7,2.7-7.6,2.9-11.6c-0.5-2.9-1-5.7-1.5-8.6c-0.9-4.3-2.1-8.4-3.5-12.5c-0.7-2.6-1.7-5.1-2.9-7.4
								c-2-3.9-3.5-8.1-4.3-12.4c-1.5-4.9-8.2-6.7-12-3.5c-2.3,3.4-4,7-4.9,11.1C901.3,1017.9,900.8,1021.9,901,1026z"/>
							<path opacity="0.1091" fill="#131313" d="M860.1,1013.4c0.9,3.3,1.7,6.7,2.6,10c1.8,3.2,3.2,6.6,4.1,10.1
								c0.9,2.4,1.5,4.8,2,7.3c0.9,3.6,1.6,7.2,1.9,10.9c0.8,5.4,7.3,7.8,11.6,4.7c2.8-3.4,4.9-7.2,6.4-11.3c1.9-4,3-8.2,3.3-12.6
								c-1.7-6.5-3.5-12.8-5.9-19.1c-0.8-2.4-1.8-4.7-3.1-6.9c-2.2-3.8-3.7-7.9-4.3-12.3c-1.4-4.9-8.2-6.7-12-3.5
								c-2.3,3.3-3.9,6.9-4.9,10.8C860.5,1005.4,859.9,1009.4,860.1,1013.4z"/>
							<path opacity="0.1091" fill="#131313" d="M878.1,1012.2c0.8,3.6,1.6,7.2,2.4,10.9c1.8,3.1,3.1,6.4,4,9.9
								c0.9,2.4,1.5,4.8,2,7.3c0.9,3.6,1.5,7.3,1.8,11c0.7,4.9,7.8,7.1,11.6,4.7c2.5-2.7,4.4-5.9,5.6-9.4c1.6-3.4,2.5-7,2.6-10.7
								c-1.4-6.7-2.9-13.3-5.3-19.7c-0.7-2.4-1.7-4.7-3-6.9c-2.3-4-3.7-8.3-4.2-13c-1.2-4.5-8.6-6-12-3.5c-2.1,2.7-3.6,5.8-4.4,9.3
								C878.1,1005.3,877.7,1008.7,878.1,1012.2z"/>
							<path opacity="0.1091" fill="#131313" d="M899.7,1045.7c1.2,2.3,2.3,4.5,3.5,6.8c-0.5-1.3-1.1-2.6-1.6-3.9
								c2,4.7,3.7,9.6,5.1,14.6c1.3,4.9,2.2,9.8,2.7,14.9c0.1,3,1.5,5.1,4.2,6.2c2.8,1,5.2,0.5,7.4-1.6c3.2-4.3,5.6-9,7.4-14.1
								c2.2-4.9,3.6-10,4.4-15.3c-1.8-8.8-3.9-17.3-7.2-25.7c-0.5-1.3-1-2.6-1.6-3.9c-1.7-4.8-3.9-9.5-6.5-13.9
								c-2-2.1-4.3-2.7-7.1-2c-3.5,1.7-5.6,4.6-6.4,8.6c-2.3,6-4.1,12.2-5.3,18.6C897.4,1038.9,897.7,1042.4,899.7,1045.7z"/>
							<path opacity="0.1091" fill="#131313" d="M854.4,1043.4c2.6,4.6,4.6,9.3,6.2,14.3c-0.5-1.5-0.9-2.9-1.4-4.4
								c1.2,4,2.3,7.9,3.5,11.9c-0.4-1.5-0.8-3-1.3-4.5c1.3,4.9,2.1,9.9,2.6,14.9c0.3,1.5,0.6,3.1,0.9,4.6c1,0.9,1.9,1.7,2.9,2.6
								c1.3,0.1,2.6,0.1,3.9,0.2c2.9-1.2,4.9-3.3,6-6.3c3.7-6.3,6.4-13,8.9-19.8c1.4-3.1,2.3-6.3,3-9.6c1.3-2.9,1.3-5.8,0.1-8.7
								c-2.2-6.6-4.5-13-7.3-19.4c-1.1-2.5-2.2-4.9-3.3-7.4c-1.4-3.4-3.1-6.7-5.1-9.8c-1.9-2.3-4.3-3-7.1-2.1c-3.7,2.2-6,5.5-6.9,10
								c-2.6,6.9-4.6,14-6.2,21.2C852.2,1035.5,852.4,1039.6,854.4,1043.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M942.8,1026.1c2-11.3,1.9-22.5-0.5-33.6c-1.1-10.3-2.3-20.7-3.4-31
								c0.1-2.6-1-4.7-3.1-6.2c-2-1.7-4.3-2.2-6.8-1.5c-3.3,2.1-6,4.7-8.2,7.9c-2.5,3-4.4,6.3-5.6,9.9c-3.6,9.4-6.7,19-9.6,28.7
								c-1.1,3.5,4.5,6,6.8,7c1.3,0.8,2.8,1.2,4.4,1.2c1.6-0.5,2.8-1.5,3.6-3.1c4.8-13.5,10.3-26.7,16.7-39.6
								c-7.3,1.9-14.6,3.8-21.9,5.7c1.1,10.4,2.3,20.7,3.4,31.1c2.3,10.6,2.7,21.3,1.2,32.1c-0.2,4.8,5.1,9.1,9.8,7.9
								c3.2-1.8,5.8-4.3,8-7.2C939.9,1032.7,941.7,1029.6,942.8,1026.1z"/>
							<path opacity="0.1091" fill="#131313" d="M920.2,1009.4c2.1-11.3,1.9-22.5-0.5-33.7c-1.1-10.3-2.3-20.7-3.4-31
								c0.1-2.6-1-4.7-3.1-6.2c-2-1.7-4.3-2.2-6.8-1.5c-3.3,2.1-6,4.7-8.2,7.9c-2.5,3-4.4,6.3-5.6,10c-3.6,9.7-7,19.5-9.9,29.4
								c-1.2,3.7,4.2,6.1,6.7,7.2c1.4,0.8,2.8,1.2,4.4,1.1c1.7-0.6,3-1.7,3.8-3.4c5-13.7,10.6-27,16.9-40.1
								c-7.3,1.9-14.6,3.9-21.9,5.8c1.1,10.4,2.3,20.7,3.4,31.1c2.3,10.6,2.7,21.4,1.2,32.2c-0.2,4.8,5.2,9.1,9.8,7.8
								c3.2-1.9,5.8-4.3,7.9-7.3C917.3,1016,919.1,1012.9,920.2,1009.4z"/>
							<path opacity="0.1091" fill="#131313" d="M925.1,968.7c-2.3-20.5-4.5-41-6.7-61.5c-0.9-2.8-2.9-4.3-5.8-4.5
								c-3,0.3-5.4,1.7-7.2,4.1c-3.8,3.3-7.1,7.1-10,11.3c-4.1,4.9-7.5,10.3-10.2,16c-1.4,7.9-2.4,15.9-2.9,23.9
								c0.9,2.1,2.4,3.7,4.5,4.7c1.5,1.1,3.1,1.6,4.9,1.5c1.6,0.4,2.9-0.2,3.9-1.8c1.8-2.1,3.1-4.4,4-7c2.7-7.3,5.4-14.7,8.1-22
								c-0.6,1.6-1.3,3.2-1.9,4.8c4.1-8.8,8.3-17.6,12.4-26.3c-11.1,9-22.2,18-33.3,27c2.2,20.1,4.2,40.2,6.5,60.3
								c0.9,2.8,2.8,4.4,5.8,4.6c4.3-0.6,7.6-2.8,10.1-6.5c5-5.4,9.6-11.1,13.7-17.1C924.1,976.7,925.5,973,925.1,968.7z"/>
							<path opacity="0.1091" fill="#131313" d="M942.8,996c-2.4-20.4-4.6-40.9-6.8-61.3c-1.1-2.7-3-4.2-5.8-4.5
								c-2.8,0-5,1.1-6.6,3.3c-3.4,2.8-6.3,6-8.8,9.7c-3.6,4.3-6.5,9-8.7,14c-1.5,7.5-3,15.1-4.3,22.6c-0.7,2-0.2,3.8,1.5,5.1
								c1.9,1.7,4,2.9,6.4,3.6c2,0.8,3.8,0.3,5.2-1.3c1.7-1.8,2.8-3.9,3.5-6.2c4.8-14.5,10.7-28.6,17.7-42.3c-10,7.5-20,15-30,22.6
								c2.2,20.1,4.3,40.2,6.6,60.3c1,2.7,2.9,4.2,5.7,4.6c3.9-0.2,6.9-2,9.2-5.3c4.4-4.6,8.4-9.6,12-14.9
								C942.4,1003.2,943.5,999.8,942.8,996z"/>
							<path opacity="0.1091" fill="#131313" d="M946,964.1c-2.5-20.5-4.6-41-6.8-61.5c-0.6-3.2-2.6-4.7-5.8-4.5
								c-5.1,1.6-9.2,4.8-12.1,9.5c-6.3,7.2-12.1,14.8-17.5,22.7c-3.8,4.1-5.8,8.8-6.1,14.1c0.4,2.7,0.7,5.5,1.1,8.2
								c-0.1-1.3-0.2-2.6-0.4-3.9c0.2,3.4,0.4,6.8,0.6,10.1c0.7,2.3,2.2,3.9,4.3,5c1.5,1.2,3.2,1.6,5,1.4c1.8,0.3,3.2-0.5,4.3-2.3
								c2.1-2.5,3.7-5.2,4.9-8.3c2.8-7.6,5.6-15.1,8.5-22.7c-0.9,1.9-1.7,3.8-2.6,5.7c5-9.9,10-19.8,15-29.8
								c-13.6,12.1-27.1,24.3-40.7,36.4c1.1,10.3,2.3,20.7,3.4,31c0.7,9.7,1.8,19.4,3.2,29.1c0.6,3.2,2.5,4.7,5.7,4.6
								c5.1-1.5,9.2-4.5,12.2-9.1c6.3-7,12.2-14.4,17.7-22.1C943.6,974,945.7,969.4,946,964.1z"/>
							<path opacity="0.1091" fill="#131313" d="M907.4,995.1c1-10.9,0.4-21.7-1.6-32.4c-1.1-10.3-2.3-20.7-3.4-31
								c0-2.6-1.1-4.6-3.2-6.1c-2-1.7-4.2-2.2-6.7-1.5c-3.2,2-5.8,4.5-8,7.6c-2.4,2.9-4.2,6.1-5.3,9.6c-4.1,9.8-7.6,19.9-10.6,30.1
								c-1.2,3.6,4.4,6,6.8,7c1.4,0.8,2.8,1.2,4.4,1.2c1.7-0.5,2.9-1.6,3.6-3.3c4.9-13.8,10.6-27.3,17.2-40.4
								c-7.2,1.8-14.3,3.5-21.5,5.3c1.1,10.4,2.3,20.7,3.4,31.1c2,10.3,2.7,20.6,2.1,31.1c0,4.7,5.1,9.1,9.8,7.8
								c3.1-1.8,5.7-4.1,7.8-7.1C904.6,1001.5,906.3,998.5,907.4,995.1z"/>
							<path opacity="0.1091" fill="#131313" d="M928.6,1026.8c0.4-11-0.2-22-1.8-33c-1-10.4-2.1-20.8-3.2-31.2
								c-0.4-2.3-1.6-4.2-3.5-5.6c-1.3-1.4-2.9-2.1-4.7-2.1c-1.8-0.5-3.3,0-4.6,1.7c-2.4,2.1-4.4,4.6-6,7.5
								c-4.3,11.8-8.7,23.5-13.3,35.2c-0.8,1.3-0.9,2.6-0.3,4c1.4,1.6,3.1,2.9,5,3.8c2.6,1.4,7.5,3.9,9.2-0.3
								c5.5-13.6,11.2-27.2,17.1-40.8c-6.3,0.5-12.5,1-18.8,1.4c1.1,10.4,2.2,20.8,3.3,31.2c1.6,10.6,2.5,21.3,2.6,32
								c0.5,2.3,1.7,4.1,3.6,5.5c1.7,1.6,3.8,2.4,6.1,2.4c2.5-1,4.5-2.6,6-4.8C927,1031.7,928.2,1029.4,928.6,1026.8z"/>
							<path opacity="0.1091" fill="#131313" d="M957.2,991.8c-2.4-20.5-4.5-41-6.7-61.5c-0.8-2.9-2.8-4.4-5.8-4.5
								c-3.2,0.5-5.7,2-7.6,4.6c-4,3.6-7.6,7.7-10.7,12.2c-4.4,5.3-8.1,11-11.2,17.1c-0.9,7.3-1.3,14.6-1.4,21.9
								c0.9,2.1,2.4,3.7,4.5,4.8c1.5,1.1,3.1,1.6,4.9,1.5c1.6,0.4,3-0.3,4-1.8c1.9-2.1,3.3-4.5,4.2-7.1c2.7-7.3,5.3-14.6,8-21.9
								c-0.7,1.7-1.4,3.3-2.1,5c4.3-9,8.6-17.9,12.8-26.9c-11.8,9.8-23.5,19.6-35.3,29.4c2.2,20.1,4.2,40.2,6.6,60.2
								c0.8,2.9,2.7,4.5,5.7,4.6c4.5-0.8,8-3.2,10.7-7.2c5.4-5.8,10.3-11.9,14.8-18.4C955.9,1000.4,957.4,996.4,957.2,991.8z"/>
							<path opacity="0.1091" fill="#131313" d="M916,979.8c-2.6-20.3-4.7-40.7-6.9-61.1c-1.1-2.6-3.1-4-5.8-4.5
								c-2.6-0.1-4.7,0.8-6.3,2.9c-3.1,2.5-5.8,5.5-8.1,8.9c-3.3,3.9-5.9,8.3-7.9,13c-2.1,8.3-4.1,16.6-6,24.9
								c-0.7,2.1-0.2,3.8,1.5,5.1c1.9,1.7,4,2.9,6.4,3.6c2,0.7,3.8,0.3,5.1-1.4c1.6-1.8,2.8-3.9,3.5-6.2c5-14.5,10.9-28.6,17.8-42.3
								c-9.4,6.7-18.7,13.5-28.1,20.2c2.2,20.1,4.3,40.2,6.7,60.2c1.1,2.6,3,4.1,5.8,4.6c3.6,0,6.5-1.6,8.7-4.7
								c4.1-4.2,7.7-8.8,11-13.7C915.9,986.5,916.8,983.3,916,979.8z"/>
							<path opacity="0.1091" fill="#131313" d="M934.1,978c-2.3-20.5-4.5-41-6.7-61.5c-0.9-2.8-2.9-4.3-5.8-4.5
								c-3.1,0.3-5.5,1.7-7.3,4.2c-3.8,3.4-7.2,7.2-10.1,11.5c-4.1,5-7.6,10.4-10.4,16.2c-1.3,7.9-2.2,15.8-2.7,23.7
								c0.9,2.1,2.4,3.7,4.5,4.8c1.5,1.1,3.1,1.6,4.9,1.5c1.6,0.3,3-0.3,4-1.9c1.9-2.1,3.3-4.6,4.2-7.2c2.7-7.4,5.4-14.7,8.1-22.1
								c-0.7,1.6-1.3,3.2-2,4.8c4.1-8.8,8.3-17.6,12.4-26.3c-11.2,9.1-22.4,18.3-33.7,27.4c2.2,20.1,4.2,40.2,6.5,60.3
								c0.9,2.8,2.8,4.4,5.7,4.6c4.3-0.6,7.7-2.8,10.2-6.6c5.1-5.4,9.7-11.2,13.9-17.4C933.1,986.1,934.5,982.3,934.1,978z"/>
							<path opacity="0.1091" fill="#131313" d="M954.4,1007.1c-2.7-20.3-4.8-40.7-7-61c-1.1-2.6-3-4.1-5.8-4.5
								c-2.7,0-4.9,1-6.5,3.1c-3.3,2.7-6.1,5.8-8.6,9.4c-3.5,4.1-6.3,8.7-8.4,13.6c-1.5,7.6-3,15.2-4.5,22.8c-0.6,2-0.1,3.6,1.6,4.9
								c1.9,1.7,4,2.9,6.4,3.7c2,0.8,3.6,0.4,5.1-1.1c1.6-1.7,2.7-3.6,3.3-5.8c4.8-14.4,10.6-28.4,17.5-41.9
								c-9.8,7.2-19.5,14.4-29.3,21.6c2.2,20.1,4.2,40.2,6.8,60.2c1,2.6,3,4.2,5.7,4.6c3.8-0.1,6.8-1.8,9-5.1
								c4.3-4.5,8.2-9.3,11.6-14.4C954.1,1014.1,955.1,1010.8,954.4,1007.1z"/>
							<path opacity="0.1091" fill="#131313" d="M909.9,1007.2c0.3-11-0.3-22-1.9-32.9c-1-10.4-2.1-20.8-3.2-31.2
								c-0.3-2.4-1.4-4.3-3.4-5.8c-1.4-1.4-3-2-4.8-2c-1.9-0.4-3.5,0.2-4.8,2c-2.6,2.4-4.8,5.1-6.6,8.2c-4.6,11.6-9,23.4-13.1,35.2
								c-0.8,1.4-1,2.8-0.5,4.4c1.4,1.7,3,3,5,3.9c1.9,1.1,3.9,1.8,6,2c1.6-0.4,2.7-1.3,3.4-2.8c5.4-14,11.2-27.8,17.5-41.5
								c-6.5,0.8-13.1,1.7-19.6,2.5c1.1,10.4,2.2,20.8,3.3,31.2c1.6,10.6,2.5,21.3,2.6,32.1c0.2,4.1,5.7,8.6,9.8,7.8
								c2.7-1.3,4.8-3.1,6.5-5.5C908,1012.6,909.3,1010.1,909.9,1007.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M903.5,1068.4c1.9,4.4,3.7,8.8,5.6,13.3c2.6,6.8,4.9,13.7,6.8,20.7
								c1.5,4.6,2.8,9.2,3.8,13.9c1.8,6.9,3.3,13.9,4.5,20.9c1.5,2.7,3.7,3.7,6.7,3.1c4.2-2,7.2-5.3,8.8-9.9
								c3.9-7.1,7.3-14.5,10.2-22.1c2.5-4.2,3.1-8.6,1.8-13.1c-1.2-5-2.5-10-3.7-15c-1.9-7.5-4.2-14.8-6.7-22.1
								c-1.5-4.8-3.2-9.6-4.9-14.3c-2.2-6.7-4.9-13.2-7.9-19.5c-1.6-2.6-3.9-3.5-6.9-2.7c-4.1,2.3-6.9,5.8-8.2,10.6
								c-3.5,7.5-6.4,15.1-8.9,23C902.3,1059.5,902,1064,903.5,1068.4z"/>
							<path opacity="0.1091" fill="#131313" d="M881.5,1050.3c1.8,4.4,3.7,8.9,5.5,13.3c2.6,6.8,4.9,13.7,6.8,20.8
								c1.5,4.6,2.8,9.3,3.8,14c1.8,7,3.3,14,4.5,21.1c1.5,2.6,3.7,3.6,6.7,3.1c4-1.6,6.8-4.6,8.3-8.9c3.6-6.5,6.8-13.3,9.4-20.2
								c2.3-3.9,2.8-8,1.4-12.1c-3.2-12.2-6.5-24.2-10.6-36.1c-1.6-4.7-3.2-9.4-5-14c-2.3-6.6-5-13.1-7.9-19.5
								c-1.7-2.4-4-3.3-6.9-2.7c-3.9,1.8-6.4,4.8-7.7,9.1c-3.1,6.5-5.8,13.3-7.9,20.2C880.1,1042.4,879.9,1046.3,881.5,1050.3z"/>
							<path opacity="0.1091" fill="#131313" d="M882.7,1013.5c1.6,5.3,3.2,10.6,4.8,15.8c2.6,6.8,4.9,13.7,6.8,20.8
								c1.4,4.5,2.7,9.1,3.7,13.7c1.8,6.8,3.3,13.7,4.5,20.7c0.9,4.4,7.4,7.3,11.3,5.3c2.5-2.3,4.4-5,5.6-8.1c1.6-3,2.5-6.2,2.6-9.5
								c-1.1-4.7-2.1-9.3-3.2-14c-1.7-7-3.7-13.9-5.9-20.7c-1.3-4.6-2.8-9.2-4.6-13.7c-2.8-7.3-5.3-14.7-7.7-22.1
								c-1.4-4-7.9-6.7-11.7-4.6c-2.2,2.1-3.8,4.7-4.8,7.6C883.1,1007.5,882.5,1010.5,882.7,1013.5z"/>
							<path opacity="0.1091" fill="#131313" d="M902.8,1042.6c1.8,4.5,3.6,8.9,5.4,13.4c-0.5-1.2-1-2.4-1.4-3.5
								c3.5,9,6.5,18.2,9.1,27.6c2.6,9.3,4.7,18.6,6.4,28c0.2,2.8,1.6,4.8,4.1,6c2.6,1.2,5,0.9,7.2-0.7c3.1-3.6,5.6-7.6,7.4-12
								c2.2-4.2,3.7-8.7,4.4-13.4c-3.4-14.8-6.9-29.4-11.9-43.7c-3.6-10.2-7.6-20.2-11.7-30.2c-1.8-2-4.1-2.9-6.9-2.7
								c-3.3,0.9-5.6,3.1-6.7,6.6c-2.5,4.9-4.5,10-5.9,15.3C900.8,1036.5,900.9,1039.6,902.8,1042.6z"/>
							<path opacity="0.1091" fill="#131313" d="M901.7,1011.3c1.7,5.1,3.4,10.2,5,15.3c3,6.5,5.3,13.3,7.2,20.2
								c1.5,4.6,2.7,9.3,3.8,14c1.8,6.9,3.3,14,4.5,21.1c1,2.1,2.6,3.6,4.8,4.5c2.1,1.2,4.2,1.5,6.5,1c2.1-1.5,3.7-3.5,4.7-5.9
								c1.3-2.3,1.9-4.8,1.7-7.4c-1-4.8-2-9.6-3-14.4c-1.6-7.2-3.6-14.4-5.8-21.4c-1.2-4.7-2.8-9.3-4.8-13.7c-2.9-7-5.5-14.1-8-21.3
								c-1.2-1.9-3-3.3-5.2-4c-2.1-1-4.3-1.2-6.5-0.6c-1.9,1.5-3.3,3.4-4,5.8C901.6,1006.6,901.3,1008.9,901.7,1011.3z"/>
							<path opacity="0.1091" fill="#131313" d="M866.7,1038.7c4.2,8.6,7.7,17.5,10.4,26.6c-0.4-1.3-0.8-2.6-1.2-3.9
								c1.8,6,3.6,11.9,5.4,17.9c-0.4-1.3-0.8-2.6-1.2-4c2.6,9.2,4.7,18.6,6.4,28c0.3,1.4,0.6,2.7,0.9,4.1c1,0.9,1.9,1.7,2.9,2.6
								c1.3,0.2,2.5,0.4,3.8,0.6c2.7-0.7,4.6-2.3,5.8-4.8c3.5-5,6.1-10.5,8.4-16.1c1.3-2.5,2.2-5.1,2.8-7.9c1.2-2.5,1.2-5,0-7.5
								c-3.2-11.9-6.5-23.7-10.6-35.3c-1.6-4.6-3.2-9.3-5-13.8c-2.3-6.5-5-12.9-8.1-19.1c-1.8-2.2-4.1-3.1-6.9-2.7
								c-3.6,1.3-5.9,3.8-7.1,7.6c-2.8,5.6-5,11.4-6.8,17.3C865,1031.9,865,1035.4,866.7,1038.7z"/>
							<path opacity="0.1091" fill="#131313" d="M886.7,1073.5c1.9,4.4,3.7,8.8,5.6,13.3c2.6,6.8,4.8,13.7,6.8,20.8
								c1.5,4.6,2.7,9.2,3.7,13.9c1.8,6.9,3.3,13.9,4.4,21c1.3,2.9,3.6,4,6.7,3.1c4.6-2.6,7.8-6.5,9.6-11.6
								c4.4-8.3,8.4-16.8,11.8-25.5c2.8-4.7,3.6-9.7,2.6-14.8c-1.3-5-2.6-9.9-3.9-14.9c-2-7.4-4.3-14.7-6.9-21.9
								c-1.6-4.8-3.3-9.5-5-14.2c-2.2-6.6-4.9-13.1-8-19.3c-1.5-2.8-3.8-3.7-6.9-2.7c-4.4,2.8-7.3,6.9-8.8,12.1
								c-3.9,8.5-7.2,17.1-10.1,26C886,1063.5,885.4,1068.4,886.7,1073.5z"/>
							<path opacity="0.1091" fill="#131313" d="M916,1033.7c1.4,5.5,2.8,10.9,4.2,16.4c2.8,6.7,5.1,13.4,7,20.4
								c1.5,4.6,2.8,9.3,3.9,14c1.9,7,3.4,14,4.6,21.1c1,4.9,6.9,7.9,11.4,5.3c2.8-2.9,4.9-6.2,6.4-9.9c1.9-3.6,3-7.3,3.4-11.3
								c-1.1-4.9-2.1-9.9-3.2-14.8c-1.7-7.4-3.7-14.7-6-21.9c-1.3-4.8-2.9-9.4-4.8-14c-2.9-7.2-5.2-14.6-6.9-22.2
								c-1.3-4.8-7.4-7.4-11.7-4.5c-2.6,3-4.5,6.4-5.7,10.1C916.9,1026.1,916.1,1029.8,916,1033.7z"/>
							<path opacity="0.1091" fill="#131313" d="M874.7,1022.1c1.6,5,3.2,9.9,4.8,14.9c2.7,6.7,5.1,13.5,7,20.4
								c1.5,4.6,2.8,9.2,3.9,13.9c1.9,6.9,3.4,13.9,4.7,20.9c0.3,2.7,1.7,4.7,4.2,5.8c2.5,1.2,4.9,1,7.2-0.5c3-3.4,5.4-7.1,7.1-11.3
								c2.1-4,3.5-8.3,4.1-12.7c-2.9-11.9-6.1-23.7-9.9-35.4c-1.4-4.7-3.1-9.2-4.9-13.7c-2.7-6.8-5.2-13.8-7.4-20.8
								c-0.5-2.6-2.1-4.4-4.6-5.3c-2.5-1-4.8-0.8-7,0.8c-2.7,3.3-4.7,6.9-6.1,10.9C875.9,1013.9,875,1017.9,874.7,1022.1z"/>
							<path opacity="0.1091" fill="#131313" d="M892.8,1020.5c1.4,5.5,2.8,10.9,4.3,16.4c2.8,6.6,5.1,13.3,7,20.1
								c1.5,4.6,2.8,9.2,3.9,13.9c1.9,6.9,3.4,13.9,4.6,20.9c1,4.7,7.1,7.7,11.4,5.3c2.7-2.7,4.7-5.8,6.2-9.3
								c1.8-3.4,2.8-6.9,3.1-10.7c-1.1-4.8-2.2-9.6-3.3-14.4c-1.7-7.2-3.8-14.4-6.2-21.4c-1.3-4.6-2.9-9.2-4.8-13.6
								c-3-7.1-5.4-14.4-7-21.9c-1.3-4.5-7.7-7-11.7-4.5c-2.4,2.6-4.2,5.6-5.3,9C893.5,1013.5,892.8,1016.9,892.8,1020.5z"/>
							<path opacity="0.1091" fill="#131313" d="M914,1054.3c4.2,8.6,7.7,17.4,10.5,26.5c-0.4-1.3-0.8-2.6-1.3-3.9
								c2.3,6.9,4.3,13.9,6,20.9c1.9,7,3.4,14,4.6,21.1c0,3,1.3,5.1,4,6.4c2.7,1.2,5.2,0.8,7.4-1.1c3.3-4.1,6-8.5,8-13.4
								c2.4-4.7,4.1-9.6,5-14.8c-2-10-4.4-20-7.3-29.8c-0.9-3.1-1.9-6.2-2.9-9.2c-1-3.1-2-6.2-3-9.2c-2.9-9-6.4-17.8-10.4-26.4
								c-1.8-2.2-4.1-3.1-6.9-2.7c-3.6,1.3-5.9,3.8-7.1,7.7c-2.8,5.6-5,11.4-6.7,17.4C912.2,1047.5,912.2,1051,914,1054.3z"/>
							<path opacity="0.1091" fill="#131313" d="M868.6,1051.9c1.8,4.4,3.7,8.9,5.5,13.3c2.6,6.8,4.8,13.8,6.8,20.8
								c1.5,4.6,2.7,9.3,3.8,14c1.8,7,3.3,14,4.4,21.2c1.5,2.7,3.7,3.7,6.7,3.1c4.2-1.9,7.1-5.2,8.7-9.7c3.9-7,7.3-14.3,10.1-21.8
								c2.4-4.2,3-8.5,1.8-12.9c-3.3-12-6.7-23.9-10.9-35.7c-1.6-4.7-3.3-9.3-5-13.9c-2.3-6.6-5-13-8-19.4c-1.7-2.5-3.9-3.4-6.9-2.7
								c-4,2-6.6,5.2-7.9,9.7c-3.3,6.9-6.1,14.1-8.4,21.4C867.3,1043.6,867,1047.8,868.6,1051.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M919.6,1138.3c1.7,10.4,3.3,20.7,4.9,31.1c1,2.8,3,4.2,6,4.2c4.4-1,7.7-3.6,10-7.7
								c4.8-6,9.2-12.3,13.2-19c3-3.6,4.3-7.6,3.7-12c-1.4-10-2.9-20-4.6-29.9c-1-2.8-3-4.3-6-4.3c-4.3,0.9-7.7,3.3-10,7.3
								c-4.9,5.8-9.3,12-13.3,18.4C920.3,1130,919.1,1133.9,919.6,1138.3z"/>
							<path opacity="0.1091" fill="#131313" d="M897.3,1121.2c1.7,10.4,3.4,20.7,4.9,31.1c1,2.8,3,4.2,6,4.2c4.4-1,7.7-3.6,10-7.7
								c4.9-6,9.3-12.4,13.3-19c3-3.6,4.3-7.6,3.8-12.1c-1.4-10-2.9-20-4.6-29.9c-1-2.8-3-4.3-6-4.3c-4.3,0.9-7.7,3.4-10.1,7.3
								c-4.9,5.8-9.4,12-13.4,18.5C898.1,1112.8,896.8,1116.8,897.3,1121.2z"/>
							<path opacity="0.1091" fill="#131313" d="M900.3,1082.6c-0.6,11.3,0.4,22.4,3.1,33.4c0.2,2.6,1.3,4.5,3.5,5.9
								c2.1,1.6,4.3,2,6.8,1.3c3-2.1,5.5-4.7,7.5-7.8c2.3-2.9,3.9-6.2,4.8-9.7c-2.6-10.5-3.7-21.2-3.5-32c-0.2-4.6-5.6-8.8-10.1-7.4
								c-3,1.9-5.4,4.3-7.3,7.2C902.7,1076.1,901.2,1079.2,900.3,1082.6z"/>
							<path opacity="0.1091" fill="#131313" d="M918.8,1113.1c1.4,10.4,3,20.8,4.8,31.2c1.3,2.4,3.3,3.8,6,4.2
								c3.4-0.1,6.1-1.7,8-4.8c3.6-4.1,6.7-8.6,9.3-13.4c2.3-2.8,2.8-5.9,1.7-9.1c-1.7-10.1-3.2-20.1-4.6-30.2
								c-1.3-2.4-3.3-3.9-6-4.3c-3.4,0-6.1,1.5-8,4.5c-3.6,4-6.7,8.3-9.4,13C918.4,1106.9,917.8,1109.9,918.8,1113.1z"/>
							<path opacity="0.1091" fill="#131313" d="M919.3,1080.4c0.1,11.4,1.3,22.6,3.5,33.8c0.5,2.3,1.8,4.1,3.8,5.4
								c1.8,1.5,3.9,2.2,6.3,2c2.5-1.2,4.4-3,5.9-5.3c1.8-2.1,2.9-4.6,3.2-7.3c-2.2-10.8-3.6-21.8-4.2-32.8c-0.6-2.2-2-4-3.9-5.2
								c-1.8-1.5-3.9-2.2-6.2-2.1c-2.3,1-4.2,2.6-5.6,4.8C920.5,1075.6,919.5,1077.9,919.3,1080.4z"/>
							<path opacity="0.1091" fill="#131313" d="M882.6,1109.1c1.7,10.4,3.3,20.8,4.9,31.1c1.1,2.7,3.1,4.2,6,4.2
								c4.2-0.8,7.4-3.2,9.6-7.1c4.6-5.6,8.8-11.6,12.5-17.8c2.9-3.4,4-7.2,3.3-11.4c-1.4-10-3-20-4.6-30c-1-2.7-3-4.2-5.9-4.3
								c-4.2,0.7-7.4,2.9-9.6,6.7c-4.7,5.4-8.9,11.2-12.6,17.3C883.1,1101.2,882,1105,882.6,1109.1z"/>
							<path opacity="0.1091" fill="#131313" d="M902,1143.4c1.8,10.4,3.5,20.7,5,31.2c0.8,3.1,2.8,4.5,6,4.2
								c4.9-1.7,8.7-4.9,11.4-9.6c5.7-7.3,11-14.9,15.9-22.8c3.5-4.1,5.2-8.8,5.1-14c-1.2-10-2.8-19.9-4.7-29.8
								c-0.8-3.1-2.8-4.5-6-4.3c-4.9,1.6-8.7,4.6-11.4,9.2c-5.8,7.1-11.1,14.4-16.1,22.1C903.7,1133.7,902,1138.3,902,1143.4z"/>
							<path opacity="0.1091" fill="#131313" d="M933.9,1103.6c-1.6,11.7-1,23.3,2,34.7c0.1,2.6,1.3,4.6,3.5,6
								c2.1,1.6,4.4,2,6.8,1.2c3.1-2.2,5.7-4.9,7.7-8.2c2.3-3,4-6.4,5-10.1c-2.9-10.9-3.8-21.9-2.7-33.1c0-4.7-5.7-8.8-10.1-7.3
								c-3,1.9-5.5,4.4-7.5,7.5C936.4,1097,934.8,1100.1,933.9,1103.6z"/>
							<path opacity="0.1091" fill="#131313" d="M891.5,1097.5c1.9,10.2,3.6,20.4,5.1,30.7c1.3,2.5,3.3,3.9,6,4.2
								c3.6-0.2,6.3-1.9,8.3-5.2c3.8-4.4,7.1-9.1,9.9-14.1c2.4-2.9,3-6.1,2-9.5c-1.5-10-3.1-20-4.8-29.9c-1.2-2.5-3.2-3.9-5.9-4.3
								c-3.6,0.1-6.3,1.7-8.3,4.9c-3.8,4.3-7.2,8.9-10.1,13.8C891.3,1090.9,890.5,1094.1,891.5,1097.5z"/>
							<path opacity="0.1091" fill="#131313" d="M910.8,1089.8c-1.2,11.5-0.3,22.9,2.6,34c0,2.6,1.2,4.7,3.4,6.1
								c2.1,1.6,4.4,1.9,6.9,1.1c3.2-2.3,5.9-5.2,8-8.5c2.4-3.1,4.2-6.6,5.3-10.4c-2.8-10.7-3.8-21.6-3-32.6
								c-0.1-4.8-5.4-8.9-10.1-7.4c-3.1,2.1-5.7,4.7-7.8,7.9C913.6,1082.9,911.9,1086.2,910.8,1089.8z"/>
							<path opacity="0.1091" fill="#131313" d="M930,1124.8c1.8,10.2,3.5,20.5,5.1,30.8c1.3,2.5,3.3,3.9,6.1,4.2
								c3.6-0.2,6.3-1.9,8.2-5.1c3.7-4.4,7-9.1,9.8-14.1c2.4-2.9,3-6.1,1.9-9.5c-1.5-10-3.1-20-4.8-30c-1.2-2.5-3.2-3.9-6-4.3
								c-3.5,0.1-6.3,1.7-8.3,4.9c-3.8,4.3-7.1,8.8-10,13.7C929.7,1118.3,929,1121.4,930,1124.8z"/>
							<path opacity="0.1091" fill="#131313" d="M883.6,1123.3c1.8,10.4,3.4,20.7,5,31.1c0.9,3,2.9,4.4,6,4.2
								c4.7-1.4,8.3-4.4,10.8-8.8c5.4-6.7,10.3-13.8,14.8-21.2c3.3-3.9,4.8-8.3,4.6-13.2c-1.3-10-2.8-19.9-4.7-29.8
								c-0.9-3-2.8-4.4-6-4.3c-4.7,1.3-8.3,4.1-10.9,8.4c-5.5,6.5-10.5,13.4-15,20.6C885,1114.1,883.4,1118.4,883.6,1123.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M974.9,1182.3c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C965.6,1179.1,969.8,1181.5,974.9,1182.3z"/>
							<path opacity="0.1091" fill="#131313" d="M950.5,1163.3c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C944.2,1162.7,947.1,1163.8,950.5,1163.3z"/>
							<path opacity="0.1091" fill="#131313" d="M949.8,1129.4c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C945,1127.1,947.2,1128.6,949.8,1129.4z"/>
							<path opacity="0.1091" fill="#131313" d="M971.7,1155.9c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C964.5,1154.6,967.8,1156.1,971.7,1155.9z"/>
							<path opacity="0.1091" fill="#131313" d="M966.9,1125.1c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C960.8,1121.7,963.7,1123.7,966.9,1125.1z"/>
							<path opacity="0.1091" fill="#131313" d="M932.9,1151.6c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2
								c-2-2.2-4.4-3.8-7.2-4.8c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C927.7,1149,930.1,1150.6,932.9,1151.6z"/>
							<path opacity="0.1091" fill="#131313" d="M957.9,1184c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C950,1182.1,953.6,1183.9,957.9,1184z"/>
							<path opacity="0.1091" fill="#131313" d="M985.9,1152.7c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C977.9,1150.6,981.5,1152.5,985.9,1152.7z"/>
							<path opacity="0.1091" fill="#131313" d="M941.1,1140c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C935.9,1137.4,938.3,1139,941.1,1140z"/>
							<path opacity="0.1091" fill="#131313" d="M957.4,1136.4c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C950.9,1132.6,953.9,1134.8,957.4,1136.4z"/>
							<path opacity="0.1091" fill="#131313" d="M984.5,1168.7c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C975.4,1165.8,979.6,1168.1,984.5,1168.7z"/>
							<path opacity="0.1091" fill="#131313" d="M932.8,1162.4c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C926.5,1158.8,929.4,1160.9,932.8,1162.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M943.2,1157.9c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C933.9,1154.7,938.1,1157.1,943.2,1157.9z"/>
							<path opacity="0.1091" fill="#131313" d="M918.8,1138.9c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C912.5,1138.3,915.4,1139.4,918.8,1138.9z"/>
							<path opacity="0.1091" fill="#131313" d="M918.1,1105c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C913.3,1102.7,915.5,1104.2,918.1,1105z"/>
							<path opacity="0.1091" fill="#131313" d="M940,1131.5c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C932.8,1130.2,936.1,1131.7,940,1131.5z"/>
							<path opacity="0.1091" fill="#131313" d="M935.2,1100.7c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C929.1,1097.3,932,1099.3,935.2,1100.7z"/>
							<path opacity="0.1091" fill="#131313" d="M901.2,1127.2c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2
								c-2-2.2-4.4-3.8-7.2-4.8c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C896,1124.6,898.4,1126.2,901.2,1127.2z"/>
							<path opacity="0.1091" fill="#131313" d="M926.2,1159.6c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C918.3,1157.7,921.9,1159.5,926.2,1159.6z"/>
							<path opacity="0.1091" fill="#131313" d="M954.2,1128.3c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C946.2,1126.2,949.8,1128.1,954.2,1128.3z"/>
							<path opacity="0.1091" fill="#131313" d="M909.4,1115.6c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2
								c-2-2.3-4.4-3.9-7.2-4.9c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C904.2,1113,906.6,1114.6,909.4,1115.6z"/>
							<path opacity="0.1091" fill="#131313" d="M925.7,1112.1c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C919.2,1108.2,922.2,1110.4,925.7,1112.1z"/>
							<path opacity="0.1091" fill="#131313" d="M952.8,1144.3c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C943.7,1141.4,947.8,1143.7,952.8,1144.3z"/>
							<path opacity="0.1091" fill="#131313" d="M901.1,1138.1c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C894.8,1134.4,897.7,1136.5,901.1,1138.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M858.4,1098.8c-1.6,6.4-3.5,12.6-5.9,18.8c-2.1,6.3-4.6,12.4-7.3,18.5
								c0.6-1.3,1.1-2.6,1.7-3.9c-1.8,4-3.7,8-5.5,11.9c-0.6,1.3-1.1,2.5-1.7,3.8c0.2,1.3,0.5,2.5,0.7,3.8c0.9,0.9,1.8,1.8,2.7,2.8
								c2.5,1.1,5,1,7.4-0.3c5.8-1.8,11.1-4.6,16.3-7.6c2.5-1.2,4.8-2.7,6.9-4.6c2.4-1.2,3.9-3.2,4.5-5.9c2.9-6.5,5.7-12.9,8.3-19.5
								c1.8-4.3,3.4-8.6,4.8-13c2.3-6,4.2-12.2,5.7-18.5c0.1-2.9-1.1-5-3.7-6.4c-3.8-1.2-7.4-0.6-10.8,1.8c-6,2.8-11.7,6.1-17.2,9.9
								C861.7,1092.1,859.3,1094.9,858.4,1098.8z"/>
							<path opacity="0.1091" fill="#131313" d="M835.2,1082.3c-2.2,8.6-4.9,16.9-8.2,25.1c-3.2,8.2-6.7,16.2-10.6,24.1
								c-0.5,3,0.6,5.2,3.4,6.5c4.7,1,9.1,0.1,13.2-2.6c7.6-3.3,15-7.2,22.1-11.5c4.6-1.9,7.8-5,9.7-9.4c3.7-8.6,7.1-17.2,10.1-26
								c3.1-8.1,5.7-16.3,7.7-24.8c0.4-3.1-0.8-5.2-3.6-6.4c-4.7-0.8-9,0.2-13,3c-7.4,3.6-14.6,7.7-21.4,12.2
								C839.9,1074.6,836.9,1077.9,835.2,1082.3z"/>
							<path opacity="0.1091" fill="#131313" d="M838.6,1047.9c-1.2,4.2-2.5,8.4-3.7,12.6c0.4-1.2,0.8-2.4,1.2-3.6
								c-2,6.3-4.3,12.5-6.9,18.6c-2.4,6.1-5,12.2-7.9,18.1c-1.7,2.3-1.8,4.8-0.6,7.3c1.3,2.5,3.4,3.8,6.3,3.8
								c4.8-1,9.4-2.8,13.7-5.2c4.5-2.1,8.6-4.8,12.2-8.2c4.3-10.4,8.4-20.8,12-31.5c2.4-7.4,4.6-14.6,6.8-22.1
								c-0.1-2.7-1.3-4.9-3.6-6.4c-3.2-1.4-6.3-1.1-9.4,0.9c-5.1,2.2-9.9,5-14.4,8.2C840.9,1041.9,839.1,1044.5,838.6,1047.9z"/>
							<path opacity="0.1091" fill="#131313" d="M858.6,1070.5c-2.5,8.5-5.3,16.8-8.3,25.1c-3.1,8.2-6.6,16.3-10.5,24.2
								c-1.6,2.3-1.7,4.8-0.4,7.3c1.3,2.5,3.3,3.8,6.2,3.9c4.6-0.9,9-2.6,13.1-4.9c4.3-2,8.1-4.6,11.6-7.9
								c3.9-8.4,7.4-16.9,10.6-25.5c3.4-9.4,6.4-19,9.2-28.6c-0.2-2.7-1.4-4.9-3.6-6.4c-2.2-1.2-4.3-1.2-6.5,0
								c-3.6,1-6.9,2.5-10.1,4.5C865.6,1064.4,861.9,1067.2,858.6,1070.5z"/>
							<path opacity="0.1091" fill="#131313" d="M860.5,1041c-5,8.7-9,17.8-12.2,27.3c-3.1,8.2-6.5,16.2-10.3,24
								c-1.9,4.2,1.1,10.3,5.8,11.1c3.5-0.3,6.7-1.4,9.8-3.2c3.2-1.4,6-3.5,8.2-6.1c3.7-8.1,7.1-16.3,10.1-24.7
								c2.8-9.2,6.4-18,10.8-26.6c1.8-4-2-10.2-6.2-10.9c-3.2,0.3-6.1,1.4-8.8,3.1C864.8,1036.5,862.4,1038.5,860.5,1041z"/>
							<path opacity="0.1091" fill="#131313" d="M819.1,1070.9c-2.2,8.6-4.9,17-8.3,25.2c-3.2,8.2-6.8,16.3-10.8,24.1
								c-0.7,3.1,0.4,5.3,3.4,6.5c5.1,0.8,9.9-0.3,14.4-3.2c8.4-3.7,16.5-8,24.4-12.7c5-2,8.6-5.3,10.9-10
								c3.5-8.5,6.7-17.2,9.7-25.9c3-8.1,5.5-16.3,7.5-24.7c0.5-3.1-0.7-5.3-3.6-6.4c-5-0.7-9.7,0.5-14,3.6
								c-8,3.9-15.8,8.3-23.3,13.2C824.5,1062.9,821.1,1066.3,819.1,1070.9z"/>
							<path opacity="0.1091" fill="#131313" d="M840.6,1102.7c-2.2,8.5-4.9,16.9-8.2,25.1c-3.1,8.2-6.6,16.1-10.5,24
								c-0.5,3.1,0.6,5.2,3.4,6.5c4.7,0.9,9.2,0.1,13.4-2.7c7.7-3.4,15.1-7.3,22.3-11.7c4.6-1.9,7.9-5,9.8-9.5
								c3.9-8.6,7.4-17.4,10.6-26.3c3.2-8.1,5.8-16.4,7.9-24.9c0.4-3.1-0.8-5.2-3.7-6.4c-4.8-0.7-9.3,0.3-13.4,3.3
								c-7.6,3.7-15,8-22.1,12.7C845.5,1094.9,842.3,1098.2,840.6,1102.7z"/>
							<path opacity="0.1091" fill="#131313" d="M873.5,1066.5c-4.8,8.4-8.5,17.3-11.1,26.6c-3.1,8.2-6.7,16.3-10.6,24.2
								c-2,4.3,1,10.4,5.8,11.1c3.6-0.4,6.9-1.5,10.1-3.4c3.3-1.5,6.2-3.6,8.5-6.3c4-8.3,7.6-16.7,10.8-25.3
								c2.4-9.1,5.8-17.7,10.1-26.1c1.9-4.2-1.7-10.3-6.2-10.9c-3.4,0.5-6.6,1.6-9.5,3.5C878.2,1061.6,875.6,1063.7,873.5,1066.5z"
								/>
							<path opacity="0.1091" fill="#131313" d="M830.1,1058.3c-2.3,8.6-5.1,16.9-8.3,25.2c-3.2,8.2-6.7,16.3-10.7,24.2
								c-0.4,3,0.8,5.1,3.4,6.5c4.2,1.1,8.3,0.5,12.2-2c6.9-3,13.6-6.4,20-10.4c4.3-1.7,7.1-4.7,8.6-8.8c3.5-8.4,6.7-17,9.6-25.6
								c2.9-8.1,5.5-16.3,7.6-24.7c0.2-2.9-1-5.1-3.6-6.4c-4.2-1-8.1-0.3-11.8,2.3c-6.6,3.1-12.9,6.7-19,10.8
								C834,1051.2,831.4,1054.2,830.1,1058.3z"/>
							<path opacity="0.1091" fill="#131313" d="M849.7,1052.8c-2.6,8.4-5.4,16.8-8.4,25.2c-3.1,8.2-6.6,16.3-10.4,24.2
								c-1.6,2.3-1.7,4.8-0.4,7.3c1.3,2.5,3.3,3.8,6.2,3.9c4.6-0.9,9-2.6,13.1-4.9c4.3-2,8.1-4.6,11.6-7.9
								c3.7-8.3,7.1-16.7,10.2-25.3c2.8-8.2,5.5-16.5,7.9-24.9c1.4-2.4,1.4-4.8,0-7.2c-1.4-2.4-3.4-3.7-6.2-3.7
								c-4.5,1-8.7,2.8-12.6,5.2C856.5,1046.8,852.9,1049.5,849.7,1052.8z"/>
							<path opacity="0.1091" fill="#131313" d="M870.6,1081.5c-3.4,8.5-6.6,17.1-9.5,25.8c-3.1,8.3-6.7,16.4-10.6,24.3
								c-1.3,2.3-1.4,4.7-0.1,7.1c1.2,2.4,3.2,3.8,5.9,4c4.2-0.7,8.2-2.2,11.9-4.3c3.9-1.8,7.4-4.2,10.4-7.3
								c4-8.4,7.7-16.9,10.9-25.6c2.8-8.6,5.8-17.1,9-25.5c1.3-2.4,1.2-4.8-0.2-7.1c-1.3-2.4-3.3-3.6-6-3.8
								c-4.2,0.9-8.1,2.5-11.7,4.8C876.9,1075.8,873.5,1078.4,870.6,1081.5z"/>
							<path opacity="0.1091" fill="#131313" d="M819.8,1084.5c-2.2,8.6-4.9,17-8.3,25.2c-3.2,8.2-6.7,16.2-10.7,24
								c-0.8,3.2,0.3,5.4,3.4,6.5c5.5,0.6,10.6-0.6,15.4-3.7c9-4.1,17.8-8.6,26.3-13.7c5.3-2.2,9.2-5.7,11.9-10.5
								c3.6-8.6,6.9-17.3,9.9-26.2c3.1-8.1,5.6-16.3,7.6-24.7c0.7-3.2-0.5-5.4-3.6-6.4c-5.4-0.5-10.5,0.9-15.1,4.2
								c-8.7,4.3-17.2,9.2-25.4,14.5C825.9,1076,822.2,1079.6,819.8,1084.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M874.3,1184.3c2.2-6.4,4.1-12.8,5.9-19.3c1.8-6.5,3.6-12.9,5.3-19.4
								c-0.3,1.2-0.7,2.4-1,3.6c1.7-6,3.3-11.9,5-17.9c1.4-2.4,1.3-4.8-0.1-7.2c-1.4-2.4-3.5-3.6-6.3-3.6c-4.4,1.1-8.6,2.9-12.5,5.4
								c-4.1,2.2-7.6,4.9-10.8,8.3c-3.6,13-7.2,25.9-10.7,38.9c0.3-1.2,0.7-2.4,1-3.6c-1.7,5.6-3.4,11.2-5,16.8
								c-1.4,2.4-1.4,4.8,0,7.2c1.3,2.4,3.4,3.7,6.1,3.8c4.4-1,8.5-2.6,12.3-5C867.6,1190.2,871.2,1187.5,874.3,1184.3z"/>
							<path opacity="0.1091" fill="#131313" d="M851.2,1167.8c4.4-8.8,7.7-17.9,9.9-27.5c2.3-8.9,4.8-17.8,7.3-26.6
								c1-2.4,0.7-4.7-0.7-6.9c-1.2-2.3-3.1-3.6-5.7-3.9c-3.7,0.7-7.1,2.1-10.3,4.2c-3.4,1.8-6.2,4.1-8.6,7
								c-2.5,8.8-4.9,17.6-7.2,26.5c-2,9.1-5,18-9,26.5c-1.6,4.4,1.3,10.3,6.2,10.9c3.5-0.5,6.8-1.7,9.9-3.7
								C846.2,1172.7,848.9,1170.5,851.2,1167.8z"/>
							<path opacity="0.1091" fill="#131313" d="M856.6,1129.3c4.7-17.6,9.6-35.1,14.5-52.7c-0.1-2.8-1.3-4.9-3.8-6.3
								c-3.5-1.2-6.9-0.7-10.1,1.6c-5.5,2.6-10.7,5.7-15.6,9.3c-3.5,1.7-5.6,4.4-6.1,8.1c-4.8,17.2-9.7,34.4-14.4,51.7
								c0,2.8,1.2,4.9,3.6,6.4c3.5,1.3,6.8,0.9,10-1.3c5.5-2.5,10.7-5.4,15.6-8.9C853.9,1135.6,856,1132.9,856.6,1129.3z"/>
							<path opacity="0.1091" fill="#131313" d="M874.2,1156.3c4.8-17.6,9.8-35.2,14.7-52.8c0-2.9-1.2-5-3.8-6.3
								c-3.8-1.1-7.4-0.4-10.8,2c-6,2.9-11.7,6.3-17.1,10.2c-3.8,1.8-6.1,4.7-6.9,8.6c-4.8,17.2-9.7,34.4-14.5,51.7
								c-0.1,2.9,1.1,5,3.6,6.4c3.7,1.2,7.3,0.7,10.8-1.7c6-2.7,11.7-5.9,17.1-9.6C871,1162.9,873.3,1160.1,874.2,1156.3z"/>
							<path opacity="0.1091" fill="#131313" d="M876.8,1126.4c4.8-17.7,9.9-35.3,14.8-53c0.4-3.1-0.9-5.2-3.8-6.3
								c-4.8-0.7-9.2,0.5-13.3,3.5c-7.6,3.9-14.9,8.2-21.9,13c-4.6,2.2-7.7,5.5-9.4,10c-4.8,17.2-9.8,34.3-14.5,51.5
								c-0.4,3.1,0.8,5.2,3.6,6.4c4.7,0.8,9.1-0.2,13.2-3.1c7.5-3.6,14.8-7.7,21.8-12.3C871.9,1134.1,875,1130.8,876.8,1126.4z"/>
							<path opacity="0.1091" fill="#131313" d="M838.1,1154.7c3.9-9.1,7-18.4,9.5-27.9c2.5-9,5.1-18,7.7-26.9
								c1.2-4.1-1.6-10.1-6.2-10.9c-3.1,0.3-6,1.3-8.6,3c-2.8,1.4-5.1,3.3-7,5.9c-2.6,8.9-5.1,17.8-7.5,26.8
								c-2.3,9.3-5.2,18.5-8.7,27.5c-1.3,3.9,2,10.1,6.2,10.9c2.9-0.2,5.7-1.1,8.1-2.7C834.3,1158.9,836.5,1157.1,838.1,1154.7z"/>
							<path opacity="0.1091" fill="#131313" d="M859.6,1186.4c3.8-9.1,6.9-18.4,9.4-27.9c2.5-8.9,5-17.7,7.5-26.6
								c1.2-4.2-1.5-10.2-6.2-10.9c-3.2,0.4-6.3,1.4-9,3.2c-2.9,1.5-5.4,3.5-7.4,6.1c-2.5,8.8-5,17.6-7.3,26.4
								c-2.3,9.3-5.2,18.5-8.7,27.5c-1.4,4,1.8,10.2,6.1,10.9c3.1-0.3,6-1.2,8.6-2.9C855.5,1190.9,857.8,1188.9,859.6,1186.4z"/>
							<path opacity="0.1091" fill="#131313" d="M889.4,1152c4.8-17.7,9.8-35.3,14.8-53c0.4-3.1-0.8-5.2-3.8-6.3
								c-4.8-0.7-9.3,0.5-13.4,3.5c-7.7,3.9-15,8.3-22.1,13.1c-4.6,2.2-7.8,5.5-9.5,10.1c-4.8,17.2-9.8,34.3-14.5,51.5
								c-0.5,3.1,0.8,5.2,3.6,6.4c4.8,0.8,9.2-0.2,13.3-3.1c7.6-3.7,15-7.8,22.1-12.4C884.4,1159.7,887.6,1156.5,889.4,1152z"/>
							<path opacity="0.1091" fill="#131313" d="M844.8,1144.6c4-8.8,7.2-17.8,9.4-27.2c2.4-8.9,4.9-17.8,7.4-26.6
								c1.2-2.4,1.1-4.8-0.4-7.1c-1.3-2.4-3.3-3.6-6-3.7c-4.1,0.9-8,2.6-11.5,4.9c-3.8,2-7,4.6-9.8,7.8c-2.5,8.8-4.9,17.7-7.3,26.5
								c-2.1,9-5.1,17.8-8.7,26.3c-1.2,2.4-1.1,4.7,0.3,7c1.3,2.4,3.2,3.7,5.9,3.9c4-0.8,7.8-2.3,11.3-4.5
								C838.9,1150.1,842.1,1147.6,844.8,1144.6z"/>
							<path opacity="0.1091" fill="#131313" d="M865.3,1138.5c4.8-17.6,9.8-35.2,14.7-52.8c0.1-2.9-1.2-5-3.8-6.3
								c-3.9-1.1-7.6-0.4-11,2.1c-6.1,3-11.9,6.4-17.4,10.4c-3.8,1.8-6.2,4.7-7.1,8.7c-4.8,17.2-9.7,34.4-14.5,51.6
								c-0.1,2.9,1.1,5,3.6,6.4c3.8,1.2,7.5,0.6,10.9-1.8c6.1-2.8,11.9-6.1,17.4-9.8C862,1145.2,864.4,1142.4,865.3,1138.5z"/>
							<path opacity="0.1091" fill="#131313" d="M885.6,1167.6c4.9-17.6,9.9-35.3,14.9-52.9c0.1-2.9-1.1-5.1-3.7-6.3
								c-4.1-1-8-0.2-11.6,2.4c-6.5,3.2-12.7,6.9-18.6,11c-4,1.9-6.6,4.9-7.7,9c-4.8,17.2-9.8,34.4-14.6,51.7
								c-0.2,2.9,1,5.1,3.6,6.4c4,1.1,7.9,0.4,11.5-2.1c6.4-3,12.6-6.5,18.5-10.4C881.9,1174.6,884.5,1171.6,885.6,1167.6z"/>
							<path opacity="0.1091" fill="#131313" d="M839.2,1168c4-9.1,7.2-18.4,9.6-28c2.5-9,5.1-18,7.7-26.9c0.5-2.3,0.1-4.5-1.3-6.5
								c-1-2.2-2.7-3.6-4.9-4.4c-2.8,0.1-5.3,0.9-7.7,2.4c-2.5,1.2-4.6,3-6.1,5.3c-2.6,8.9-5.1,17.8-7.5,26.8
								c-2.3,9.3-5.2,18.5-8.7,27.5c-0.4,2.3,0.1,4.4,1.4,6.4c1,2.1,2.6,3.6,4.8,4.5c2.6,0,5-0.7,7.1-2.1
								C836.1,1171.9,837.9,1170.2,839.2,1168z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M933.1,1005.9c1.8-5.4,3.5-10.8,4.9-16.3c1.4-5.4,2.8-10.8,4.1-16.2
								c-0.3-2.7-1.6-4.8-3.8-6.3c-3.1-1.4-6.1-1-9,1c-4.8,2.3-9.2,5-13.4,8.2c-3.1,1.7-4.8,4.2-5,7.5c-1.2,4.7-2.4,9.5-3.6,14.2
								c0.3-1.2,0.6-2.4,0.9-3.6c-1.3,4.6-2.7,9.2-4,13.8c-1.3,2.4-1.2,4.8,0.2,7.2c1.4,2.4,3.4,3.6,6.2,3.7c4.3-1,8.4-2.7,12.1-5.2
								C926.6,1011.8,930.1,1009.2,933.1,1005.9z"/>
							<path opacity="0.1091" fill="#131313" d="M910.1,989.3c4.7-9.5,8-19.4,9.8-29.8c0.9-2.4,0.7-4.7-0.8-6.9
								c-1.3-2.3-3.2-3.5-5.7-3.8c-3.7,0.7-7.1,2.2-10.2,4.3c-3.3,1.8-6.1,4.2-8.5,7.1c-1.7,10-4.7,19.6-8.9,28.8
								c-1.6,4.4,1.5,10.3,6.3,10.8c3.5-0.6,6.8-1.9,9.8-3.9C905.2,994.3,907.9,992.1,910.1,989.3z"/>
							<path opacity="0.1091" fill="#131313" d="M915.5,950.8c2.1-9.5,4.5-19,7.1-28.4c-0.1-2.8-1.4-4.9-3.9-6.3
								c-3.6-1.2-6.9-0.6-10.1,1.7c-5.5,2.7-10.7,5.9-15.6,9.6c-3.5,1.8-5.5,4.5-6.1,8.3c-2.5,9.1-4.9,18.3-7,27.5
								c0.1,2.8,1.3,4.9,3.7,6.3c3.5,1.2,6.9,0.7,10.1-1.5c5.5-2.6,10.7-5.7,15.6-9.2C912.9,957.1,914.9,954.4,915.5,950.8z"/>
							<path opacity="0.1091" fill="#131313" d="M933.1,977.8c2.3-9.5,4.8-19.1,7.3-28.5c0-2.9-1.3-5-3.8-6.3
								c-3.8-1.1-7.4-0.3-10.8,2.1c-5.9,3-11.6,6.4-16.9,10.4c-3.7,1.9-6,4.8-6.8,8.7c-2.5,9.1-4.9,18.3-7.1,27.5
								c0,2.9,1.2,5,3.7,6.4c3.8,1.1,7.3,0.5,10.7-1.9c5.9-2.8,11.5-6.1,16.9-9.9C930,984.4,932.2,981.6,933.1,977.8z"/>
							<path opacity="0.1091" fill="#131313" d="M935.7,947.8c2.3-9.6,4.8-19.2,7.4-28.7c0.4-3.1-0.9-5.2-3.9-6.3
								c-4.8-0.6-9.3,0.6-13.3,3.7c-7.6,4-14.8,8.5-21.8,13.4c-4.6,2.2-7.7,5.6-9.3,10.2c-2.6,9.1-5,18.2-7.1,27.4
								c-0.4,3.1,0.9,5.2,3.7,6.3c4.8,0.7,9.2-0.4,13.2-3.4c7.5-3.8,14.8-8.1,21.7-12.8C930.9,955.6,934,952.3,935.7,947.8z"/>
							<path opacity="0.1091" fill="#131313" d="M897.1,976.1c4.1-9.8,7.3-20,9.6-30.4c1.1-4.1-1.8-10.1-6.4-10.8
								c-3.1,0.3-6,1.4-8.6,3.2c-2.8,1.4-5.1,3.4-6.9,6c-2.2,10.2-5.1,20.1-8.7,29.9c-1.3,3.9,2.2,10.1,6.4,10.8
								c3-0.3,5.7-1.2,8.2-2.9C893.3,980.5,895.5,978.6,897.1,976.1z"/>
							<path opacity="0.1091" fill="#131313" d="M918.5,1008c4.1-9.8,7.2-20,9.5-30.3c1.1-4.2-1.6-10.1-6.3-10.8
								c-3.2,0.4-6.2,1.5-8.9,3.3c-2.9,1.5-5.3,3.5-7.2,6.1c-2.2,10.2-5.1,20.1-8.7,29.9c-1.3,4,2,10.1,6.3,10.8
								c3.1-0.3,5.9-1.3,8.5-3C914.5,1012.5,916.7,1010.5,918.5,1008z"/>
							<path opacity="0.1091" fill="#131313" d="M948.2,973.5c2.3-9.6,4.8-19.1,7.4-28.7c0.4-3.1-0.9-5.2-3.8-6.3
								c-4.8-0.6-9.3,0.6-13.4,3.7c-7.6,4-14.9,8.5-21.9,13.4c-4.6,2.2-7.7,5.6-9.4,10.2c-2.6,9.1-5,18.2-7.1,27.4
								c-0.4,3.1,0.8,5.2,3.7,6.4c4.8,0.7,9.2-0.4,13.3-3.3c7.5-3.8,14.8-8.1,21.8-12.8C943.4,981.3,946.5,978,948.2,973.5z"/>
							<path opacity="0.1091" fill="#131313" d="M903.9,966c4.1-9.5,7.2-19.4,9.2-29.5c1.2-2.4,1-4.8-0.5-7.1
								c-1.4-2.3-3.4-3.5-6.1-3.6c-4.1,1-8,2.7-11.5,5.2c-3.8,2.1-7,4.7-9.8,8c-1.9,9.8-4.8,19.3-8.6,28.6c-1.2,2.4-1,4.8,0.4,7
								c1.3,2.4,3.3,3.6,6,3.8c4.1-0.9,7.8-2.5,11.3-4.7C897.9,971.7,901.1,969.2,903.9,966z"/>
							<path opacity="0.1091" fill="#131313" d="M924.2,960c2.3-9.5,4.8-19.1,7.3-28.5c0-2.9-1.3-5-3.9-6.3c-3.9-1-7.6-0.3-11,2.3
								c-6.1,3.1-11.9,6.6-17.4,10.7c-3.8,1.9-6.1,4.8-7,8.8c-2.5,9.1-4.9,18.3-7.1,27.5c-0.1,2.9,1.2,5,3.7,6.3
								c3.9,1.1,7.5,0.4,10.9-2c6.1-2.9,11.8-6.3,17.3-10.2C921,966.8,923.3,963.9,924.2,960z"/>
							<path opacity="0.1091" fill="#131313" d="M944.5,989.1c2.4-9.6,4.9-19.1,7.5-28.6c0.1-2.9-1.2-5-3.8-6.3
								c-4.1-0.9-7.9-0.1-11.5,2.5c-6.4,3.3-12.5,7-18.3,11.2c-4,2-6.5,5-7.5,9.1c-2.5,9.2-4.9,18.3-7.2,27.6
								c-0.1,2.9,1.1,5,3.7,6.4c4,1,7.8,0.3,11.4-2.2c6.4-3.1,12.4-6.6,18.2-10.7C940.9,996.1,943.4,993.2,944.5,989.1z"/>
							<path opacity="0.1091" fill="#131313" d="M898.2,989.5c4.2-9.9,7.5-20,9.8-30.5c0.4-2.3,0-4.5-1.4-6.5
								c-1.1-2.1-2.7-3.6-5-4.3c-2.8,0.1-5.3,1-7.7,2.6c-2.5,1.3-4.5,3.1-6,5.4c-2.2,10.2-5.1,20.1-8.7,29.9
								c-0.3,2.3,0.2,4.4,1.5,6.4c1.1,2.1,2.7,3.6,4.8,4.4c2.6,0,5-0.8,7.1-2.3C895.1,993.5,896.9,991.8,898.2,989.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M645.2,1237.9c-1-6.1-2-12.3-3-18.4c-0.7-4.5-7.2-7.6-11.2-5.6
								c-2.6,2.2-4.5,4.9-5.9,8.1c-1.7,3-2.7,6.2-2.9,9.6c1.2,5.8,2.3,11.7,3.5,17.5c0.9,4.3,7.2,7.5,11.2,5.6
								c2.5-2.1,4.4-4.6,5.7-7.6C644.1,1244.2,645,1241.1,645.2,1237.9z"/>
							<path opacity="0.1091" fill="#131313" d="M622.3,1221.9c-0.9-6.1-1.8-12.2-2.7-18.3c-0.7-4.9-7-7.9-11.3-5.5
								c-2.8,2.7-4.9,5.9-6.4,9.5c-1.9,3.4-3,7.1-3.4,10.9c1,5.7,2,11.4,3,17.1c0.8,4.7,6.9,7.8,11.2,5.6c2.7-2.5,4.8-5.5,6.3-8.9
								C620.8,1229.1,622,1225.6,622.3,1221.9z"/>
							<path opacity="0.1091" fill="#131313" d="M626,1180.5c-1.6-4.7-3.2-9.4-4.8-14.2c-1.4-2.7-3.6-3.7-6.6-3.2
								c-4.2,1.8-7.2,5-8.8,9.5c-4,6.9-7.4,14.1-10.4,21.5c-2.5,4.1-3.1,8.4-1.9,12.9c1.5,4.4,3,8.8,4.6,13.2
								c1.4,2.7,3.6,3.8,6.6,3.3c4.2-1.7,7.2-4.8,8.9-9.3c4-6.8,7.5-13.8,10.5-21.1C626.4,1189.2,627.1,1185,626,1180.5z"/>
							<path opacity="0.1091" fill="#131313" d="M643.7,1208.2c-1.6-4.6-3.3-9.2-4.9-13.8c-1.6-2.3-3.8-3.4-6.6-3.2
								c-3.6,1-6.2,3.3-7.6,7c-3.2,5.3-5.8,10.8-8,16.6c-2,3.4-2.2,6.8-0.7,10.3c1.5,4.4,3.1,8.8,4.6,13.2c1.6,2.3,3.8,3.4,6.6,3.3
								c3.6-0.9,6.2-3.1,7.7-6.8c3.2-5.2,6-10.7,8.2-16.4C644.9,1215.1,645.2,1211.7,643.7,1208.2z"/>
							<path opacity="0.1091" fill="#131313" d="M646.2,1175.9c-1.6-4.7-3.3-9.4-4.9-14.1c-1.3-3-3.5-4.1-6.6-3.2
								c-4.7,2.7-8.1,6.7-10,11.9c-4.7,8.5-8.9,17.2-12.7,26.2c-2.9,4.8-4,9.9-3.1,15.3c1.5,4.3,3.1,8.7,4.6,13
								c1.2,3,3.4,4.1,6.6,3.3c4.7-2.6,8.1-6.4,10.1-11.6c4.8-8.3,9-16.9,12.8-25.7C645.9,1186.2,647,1181.2,646.2,1175.9z"/>
							<path opacity="0.1091" fill="#131313" d="M608.4,1208.4c-1.1-5.7-2.1-11.4-3.2-17.1c-0.9-5-6.8-8.1-11.3-5.4
								c-2.8,2.9-5,6.2-6.6,10c-1.9,3.6-3.1,7.4-3.6,11.4c1.1,5.3,2.2,10.7,3.3,16c0.4,2.6,1.9,4.4,4.3,5.5c2.3,1.3,4.6,1.3,7,0.1
								c2.8-2.7,5-5.9,6.6-9.5C606.8,1216,608,1212.3,608.4,1208.4z"/>
							<path opacity="0.1091" fill="#131313" d="M629.9,1239.6c-1.3-5.6-2.5-11.2-3.8-16.8c-1-2.1-2.6-3.6-4.7-4.5
								c-2-1.2-4.2-1.6-6.5-1.1c-2.1,1.4-3.7,3.4-4.7,5.8c-1.3,2.3-1.9,4.7-1.8,7.2c1.4,5.3,2.8,10.7,4.2,16c1.1,2,2.6,3.5,4.8,4.4
								c2,1.2,4.1,1.6,6.4,1.2c2.1-1.3,3.6-3.1,4.5-5.3C629.6,1244.3,630.1,1242.1,629.9,1239.6z"/>
							<path opacity="0.1091" fill="#131313" d="M657.6,1204.7c-1.6-4.7-3.2-9.5-4.8-14.2c-1.5-2.6-3.7-3.6-6.6-3.2
								c-4,1.5-6.8,4.4-8.4,8.6c-3.7,6.4-6.9,13-9.6,19.8c-2.3,3.9-2.8,7.8-1.5,12c1.5,4.4,3,8.9,4.5,13.3c1.5,2.6,3.7,3.7,6.6,3.3
								c4-1.4,6.9-4.2,8.5-8.4c3.7-6.2,7-12.7,9.7-19.4C658.3,1212.7,658.8,1208.8,657.6,1204.7z"/>
							<path opacity="0.1091" fill="#131313" d="M616.6,1192.4c-1.6-4.7-3.2-9.3-4.8-14c-1.6-2.4-3.8-3.4-6.6-3.2
								c-3.7,1.1-6.3,3.5-7.8,7.3c-3.3,5.5-6,11.3-8.3,17.3c-2.1,3.5-2.4,7.1-0.9,10.7c1.5,4.4,3,8.9,4.6,13.3
								c1.6,2.4,3.8,3.5,6.6,3.3c3.7-1,6.3-3.4,7.8-7.1c3.3-5.4,6.1-11.1,8.5-17C617.7,1199.5,618,1196,616.6,1192.4z"/>
							<path opacity="0.1091" fill="#131313" d="M634.9,1190.3c-1.6-4.7-3.2-9.4-4.8-14.2c-1.5-2.6-3.7-3.7-6.6-3.2
								c-4.1,1.7-7,4.7-8.7,9.1c-3.8,6.7-7.2,13.6-10,20.8c-2.4,4-3,8.2-1.8,12.5c1.5,4.4,3,8.8,4.5,13.3c1.4,2.6,3.6,3.7,6.6,3.3
								c4.1-1.6,7-4.6,8.7-8.9c3.9-6.5,7.3-13.3,10.2-20.4C635.5,1198.6,636.1,1194.5,634.9,1190.3z"/>
							<path opacity="0.1091" fill="#131313" d="M654.9,1219.8c-1.6-4.7-3.1-9.4-4.7-14.2c-1.7-2.2-3.9-3.3-6.6-3.2
								c-3.4,0.7-5.8,2.7-7.2,6.1c-2.9,4.8-5.3,9.8-7.3,15c-1.8,3.2-1.9,6.4-0.3,9.5c1.5,4.5,3,9.1,4.4,13.6
								c1.6,2.2,3.8,3.3,6.6,3.3c3.4-0.6,5.9-2.6,7.3-6c3-4.7,5.4-9.6,7.4-14.8C656.3,1226.1,656.5,1223,654.9,1219.8z"/>
							<path opacity="0.1091" fill="#131313" d="M610.2,1221.7c-1-6.1-2-12.3-3-18.4c-0.7-4.3-7.4-7.4-11.2-5.6
								c-2.5,2.1-4.3,4.6-5.6,7.6c-1.6,2.8-2.5,5.8-2.6,9c1.2,5.8,2.3,11.6,3.5,17.4c0.8,4.1,7.4,7.3,11.2,5.6
								c2.4-1.9,4.2-4.2,5.4-7.1C609.3,1227.6,610.1,1224.8,610.2,1221.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M657.7,1191.9c1.4-3.7,2.6-7.5,3.7-11.3c1.1-3.7,2.1-7.4,3-11.1
								c-0.2-2.7-1.4-4.9-3.7-6.4c-3.1-1.4-6.1-1.2-9.1,0.8c-4.9,2.2-9.4,4.8-13.8,7.9c-3.2,1.6-4.9,4.1-5.2,7.4
								c-0.8,3-1.6,6.1-2.4,9.1c0.3-1.2,0.7-2.4,1-3.6c-1,3-1.9,6-2.9,8.9c-1.4,2.4-1.4,4.8,0,7.2c1.3,2.4,3.3,3.7,6.1,3.8
								c4.4-0.9,8.5-2.6,12.4-4.9C651,1197.8,654.6,1195.2,657.7,1191.9z"/>
							<path opacity="0.1091" fill="#131313" d="M634.6,1175.4c4.1-6.1,6.7-12.7,7.7-19.9c1-2.4,0.8-4.7-0.6-6.9
								c-1.2-2.3-3.1-3.6-5.6-4c-3.7,0.6-7.1,2-10.3,4c-3.4,1.7-6.2,4-8.6,6.9c-0.9,6.8-3.2,13.1-6.8,19c-2,4.3,1.5,10.4,6.1,10.9
								c3.5-0.5,6.8-1.7,9.9-3.6C629.5,1180.3,632.3,1178.2,634.6,1175.4z"/>
							<path opacity="0.1091" fill="#131313" d="M640.1,1137c1.5-6.2,3.2-12.4,5-18.6c-0.1-2.8-1.3-4.9-3.7-6.4
								c-3.5-1.3-6.8-0.8-10,1.4c-5.5,2.6-10.7,5.6-15.6,9.1c-3.5,1.7-5.6,4.4-6.2,8c-1.8,5.9-3.5,11.8-5,17.8
								c0,2.8,1.2,4.9,3.6,6.4c3.4,1.3,6.8,0.9,10-1.2c5.5-2.4,10.7-5.3,15.6-8.7C637.3,1143.2,639.4,1140.6,640.1,1137z"/>
							<path opacity="0.1091" fill="#131313" d="M657.7,1163.9c1.6-6.3,3.4-12.5,5.3-18.7c0.1-2.9-1.2-5-3.7-6.4
								c-3.8-1.2-7.4-0.5-10.9,1.9c-6,2.8-11.7,6.2-17.2,10c-3.8,1.8-6.1,4.6-7,8.5c-1.8,5.9-3.5,11.8-5,17.8
								c-0.1,2.9,1.1,5,3.6,6.4c3.7,1.2,7.3,0.7,10.8-1.6c6-2.7,11.7-5.9,17.2-9.5C654.4,1170.5,656.7,1167.8,657.7,1163.9z"/>
							<path opacity="0.1091" fill="#131313" d="M660.2,1134c1.6-6.3,3.4-12.6,5.3-18.8c0.4-3.1-0.8-5.2-3.7-6.4
								c-4.8-0.7-9.2,0.4-13.3,3.3c-7.6,3.8-14.9,8-21.9,12.7c-4.6,2.1-7.7,5.4-9.5,9.9c-2,5.8-3.7,11.7-5,17.7
								c-0.4,3.1,0.8,5.2,3.6,6.4c4.7,0.8,9.1-0.2,13.2-3c7.5-3.6,14.8-7.6,21.8-12.2C655.3,1141.7,658.4,1138.5,660.2,1134z"/>
							<path opacity="0.1091" fill="#131313" d="M621.6,1162.3c3.6-6.4,6.2-13.3,7.6-20.5c1.1-4.1-1.5-10.1-6.1-10.9
								c-3.1,0.2-5.9,1.2-8.6,2.9c-2.8,1.4-5.1,3.3-7,5.8c-1.2,7-3.5,13.7-6.7,20c-0.6,2.3-0.2,4.5,1.1,6.6c1,2.2,2.7,3.7,5,4.4
								c2.9-0.2,5.6-1,8.1-2.6C617.7,1166.6,619.9,1164.7,621.6,1162.3z"/>
							<path opacity="0.1091" fill="#131313" d="M640.4,1195.6c4.7-6.7,8-13.9,10-21.8c1.4-4.1-1.5-10.2-6.1-11
								c-3.2,0.3-6.2,1.3-9,3.1c-2.9,1.4-5.4,3.4-7.4,6c-1.7,7.5-4.6,14.5-8.7,21.1c-0.6,2.3-0.3,4.5,1,6.6c1.1,2.2,2.7,3.7,5,4.4
								c3-0.2,5.8-1.1,8.3-2.7C636.4,1199.9,638.6,1198,640.4,1195.6z"/>
							<path opacity="0.1091" fill="#131313" d="M672.9,1159.7c1.6-6.3,3.4-12.6,5.3-18.8c0.4-3.1-0.8-5.2-3.7-6.4
								c-4.8-0.7-9.3,0.4-13.5,3.4c-7.7,3.8-15.1,8.1-22.3,12.8c-4.6,2.1-7.8,5.4-9.6,10c-2,5.8-3.7,11.7-5,17.6
								c-0.5,3.1,0.7,5.2,3.6,6.4c4.8,0.8,9.2-0.2,13.4-3c7.7-3.6,15-7.7,22.2-12.3C667.8,1167.4,671.1,1164.1,672.9,1159.7z"/>
							<path opacity="0.1091" fill="#131313" d="M628.2,1152.2c3.7-6.1,6.2-12.7,7.4-19.8c1.2-2.4,1.1-4.8-0.3-7.1
								c-1.3-2.4-3.3-3.6-6-3.8c-4.1,0.9-8,2.5-11.5,4.8c-3.8,2-7.1,4.5-9.9,7.7c-1.1,6.7-3.3,13-6.7,18.9c-1.2,2.4-1.1,4.7,0.3,7
								c1.2,2.4,3.2,3.7,5.8,3.9c4-0.7,7.8-2.2,11.3-4.4C622.2,1157.7,625.4,1155.3,628.2,1152.2z"/>
							<path opacity="0.1091" fill="#131313" d="M648.8,1146.2c1.6-6.3,3.4-12.5,5.3-18.7c0.1-2.9-1.2-5-3.7-6.4
								c-3.9-1.1-7.5-0.5-11,2c-6.1,2.9-11.9,6.3-17.5,10.1c-3.8,1.8-6.2,4.7-7.2,8.6c-1.9,5.9-3.5,11.8-5,17.7
								c-0.1,2.9,1.1,5,3.6,6.4c3.8,1.2,7.4,0.6,10.9-1.7c6.1-2.8,11.9-6,17.4-9.7C645.4,1152.8,647.8,1150,648.8,1146.2z"/>
							<path opacity="0.1091" fill="#131313" d="M669.1,1175.3c1.7-6.3,3.5-12.6,5.4-18.8c0.2-2.9-1-5.1-3.7-6.4
								c-4.1-1-8-0.3-11.6,2.3c-6.5,3.1-12.8,6.7-18.7,10.8c-4,1.9-6.6,4.8-7.8,8.9c-1.9,5.9-3.6,11.8-5.1,17.8
								c-0.2,2.9,1,5.1,3.6,6.4c4,1.1,7.9,0.5,11.5-2c6.5-3,12.7-6.4,18.6-10.3C665.3,1182.2,667.9,1179.3,669.1,1175.3z"/>
							<path opacity="0.1091" fill="#131313" d="M622.7,1175.7c3.8-6.4,6.4-13.3,7.8-20.6c0.5-2.3,0.1-4.5-1.2-6.5
								c-1-2.2-2.6-3.7-4.9-4.5c-2.8,0.1-5.3,0.9-7.7,2.4c-2.5,1.2-4.5,3-6.1,5.3c-1.2,7-3.5,13.7-6.7,20c-0.4,2.3,0.1,4.4,1.4,6.4
								c1,2.1,2.6,3.6,4.7,4.5c2.6,0,5-0.7,7.1-2.1C619.5,1179.5,621.3,1177.9,622.7,1175.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M50.2,579.5c2.3-9.3,3-18.7,2.1-28.2c0.5-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2.1c-3.6,1.9-6.8,4.4-9.5,7.4c-3,2.8-5.4,6.1-7.2,9.8c0.8,9.1,0.3,18.2-1.6,27.1
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7C46.2,586.1,48.5,583,50.2,579.5z"/>
							<path opacity="0.1091" fill="#131313" d="M27,563.3c3.2-9.5,4.3-19.3,3.1-29.2c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4-9,7c-2.8,2.7-5.1,5.7-6.7,9.3c1.1,9.4,0.4,18.7-2.3,27.9c-0.8,4.7,4.2,9.6,8.9,8.9
								c3.3-1.5,6.2-3.6,8.7-6.4C23.4,569.6,25.5,566.7,27,563.3z"/>
							<path opacity="0.1091" fill="#131313" d="M32.5,523c0-8.8,0-17.6,0-26.3c-0.7-2.9-2.4-4.6-5.3-5.1c-4.2,0.1-7.7,1.9-10.4,5.3
								c-5.3,4.7-10.3,9.9-14.8,15.4c-3.3,2.9-5,6.4-4.9,10.6c-0.1,8.4-0.2,16.9,0,25.3c0.6,2.9,2.4,4.6,5.2,5.2
								c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C30.8,530.7,32.5,527.2,32.5,523z"/>
							<path opacity="0.1091" fill="#131313" d="M50.3,550.1c-0.2-8.7-0.2-17.4-0.1-26.2c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.4-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.1,8.4-0.1,16.8,0.1,25.2
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C48.9,557.3,50.4,554,50.3,550.1z"/>
							<path opacity="0.1091" fill="#131313" d="M53.5,518.7c-0.2-8.8-0.2-17.6-0.1-26.3c-0.3-3.2-2.1-4.9-5.3-5.1
								c-5.2,1-9.5,3.6-13,7.9c-6.9,6.4-13.5,13.2-19.6,20.3c-4.2,3.6-6.7,8-7.5,13.2c-0.3,8.4-0.3,16.7,0.1,25.1
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C50.2,528.2,52.7,523.9,53.5,518.7z"/>
							<path opacity="0.1091" fill="#131313" d="M14,549.6c2.6-9.4,3.4-19,2.5-28.7c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.8-1.9-3.9-2.7-6.4-2.4c-3.3,1.5-6.1,3.6-8.4,6.3c-2.6,2.5-4.7,5.3-6,8.7c0.9,9.2,0.3,18.4-1.8,27.4
								c-0.6,4.5,4.4,9.4,8.9,8.8c3.1-1.3,5.8-3.2,8.1-5.8C10.8,555.4,12.7,552.7,14,549.6z"/>
							<path opacity="0.1091" fill="#131313" d="M35.9,580.7c1.5-9.5,2.1-19,1.7-28.5c0-4.2-4.4-9.3-8.8-8.9c-2.8,1-5.2,2.6-7.2,4.9
								c-2.3,2-3.9,4.4-4.9,7.2c0.5,9.3,0.2,18.5-0.9,27.8c0.1,2.4,1.1,4.3,2.9,5.9c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,5-2.4,6.8-4.5
								C33.6,585.6,35.1,583.4,35.9,580.7z"/>
							<path opacity="0.1091" fill="#131313" d="M65,545.7c-0.2-8.8-0.2-17.5,0-26.3c-0.5-3.1-2.2-4.8-5.3-5.1
								c-4.7,0.5-8.6,2.7-11.7,6.6c-6.2,5.6-11.9,11.5-17.3,17.9c-3.8,3.2-5.9,7.2-6.3,11.9c-0.3,8.4-0.2,16.8,0,25.1
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C62.4,554.3,64.5,550.4,65,545.7z"/>
							<path opacity="0.1091" fill="#131313" d="M23.3,534c-0.1-8.7-0.2-17.4-0.2-26.1c-0.9-2.6-2.7-4.3-5.3-5.1
								c-3.5-0.4-6.5,0.8-8.9,3.7c-4.3,3.7-8.3,7.8-11.8,12.2c-2.8,2.5-3.9,5.5-3.4,9c0,8.4,0,16.9,0.1,25.3
								c0.8,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.5-0.7,8.9-3.5c4.4-3.6,8.3-7.6,11.9-11.9C22.7,540.4,23.8,537.5,23.3,534z"/>
							<path opacity="0.1091" fill="#131313" d="M41.5,532.2c-0.1-8.8-0.1-17.5,0-26.3c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.3,0.2-7.9,2.1-10.8,5.7c-5.5,4.9-10.7,10.3-15.5,16c-3.4,3-5.2,6.6-5.3,11c-0.2,8.4-0.2,16.8,0,25.3
								c0.6,2.9,2.3,4.6,5.2,5.2c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C39.6,540.1,41.4,536.5,41.5,532.2z"/>
							<path opacity="0.1091" fill="#131313" d="M61.6,561.5c-0.2-8.7-0.2-17.4-0.1-26.2c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.1,8.4-0.1,16.8,0.1,25.2
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C60.3,568.7,61.8,565.4,61.6,561.5z"/>
							<path opacity="0.1091" fill="#131313" d="M16.8,561.5c1.5-9.5,2.1-19,1.7-28.5c0-4.2-4.4-9.3-8.9-8.8c-2.8,1-5.3,2.7-7.3,5
								c-2.3,2-3.9,4.5-5,7.3c0.5,9.3,0.2,18.5-1,27.8c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9c2.7-0.9,5-2.4,6.9-4.6
								C14.4,566.6,16,564.3,16.8,561.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M52.6,513.6c2.3-12.9,3.1-25.9,2.2-39c0-12.6,0-25.2,0-37.8
								c0.4-2.6-0.3-4.8-2.3-6.6c-1.9-1.9-4.1-2.6-6.7-2c-3.6,1.9-6.8,4.4-9.5,7.5c-3,2.8-5.4,6.1-7.1,9.8c0,12.6,0,25.2,0,37.8
								c0.8,12.6,0.3,25.2-1.7,37.7c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7
								C48.6,520.3,50.9,517.2,52.6,513.6z"/>
							<path opacity="0.1091" fill="#131313" d="M29.5,497.5c3.3-13.2,4.4-26.5,3.2-40c0-12.6,0-25.2,0-37.8
								c0.3-2.6-0.5-4.8-2.5-6.5c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4.1-9,7c-2.8,2.7-5,5.8-6.6,9.3c0,12.6,0,25.2,0,37.9
								c1.1,12.9,0.3,25.7-2.4,38.4c-0.6,4.7,4.1,9.6,8.9,8.9c3.3-1.5,6.2-3.6,8.7-6.4C25.8,503.7,28,500.8,29.5,497.5z"/>
							<path opacity="0.1091" fill="#131313" d="M34.9,457.2c0-25,0.1-50,0.1-75c-0.7-2.9-2.4-4.6-5.3-5.1c-4.2,0.1-7.7,2-10.4,5.4
								C13.9,387.3,9,392.5,4.5,398c-3.3,2.9-4.9,6.5-4.9,10.7c0,24.6-0.1,49.1-0.2,73.7c0.6,2.9,2.4,4.6,5.2,5.2
								c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C33.2,464.8,34.9,461.3,34.9,457.2z"/>
							<path opacity="0.1091" fill="#131313" d="M52.7,484.3c-0.1-24.9,0-49.9,0-74.8c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4,0-7.3,1.5-9.9,4.8c-5,4.4-9.5,9.2-13.7,14.3c-3.1,2.8-4.6,6.1-4.4,10.1c0,24.5-0.1,49.1-0.1,73.6
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C51.4,491.5,52.9,488.2,52.7,484.3z"/>
							<path opacity="0.1091" fill="#131313" d="M56,452.8c-0.2-25,0-50,0-75c-0.3-3.2-2.1-4.9-5.3-5.1c-5.2,1-9.5,3.7-12.9,8
								c-6.9,6.4-13.4,13.3-19.5,20.5c-4.2,3.6-6.6,8-7.4,13.3c0,12.6,0,25.2,0,37.8c-0.4,11.9-0.4,23.7-0.2,35.6
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C52.7,462.3,55.2,458,56,452.8z"/>
							<path opacity="0.1091" fill="#131313" d="M16.4,483.7c2.6-13,3.5-26.2,2.6-39.5c0-12.6,0-25.2,0-37.8
								c0.2-2.5-0.7-4.6-2.6-6.3c-1.8-1.9-3.9-2.6-6.4-2.3c-3.3,1.5-6.1,3.7-8.4,6.4c-2.6,2.5-4.6,5.4-6,8.7c0,12.6,0,25.2,0,37.8
								c0.9,12.7,0.2,25.4-1.9,38c-0.5,4.5,4.3,9.5,8.9,8.8c3.1-1.3,5.8-3.2,8.1-5.8C13.2,489.6,15.1,486.8,16.4,483.7z"/>
							<path opacity="0.1091" fill="#131313" d="M38.4,514.8c1.5-13.1,2.1-26.3,1.7-39.5c0.1-12.7,0.2-25.3,0.2-38
								c0-4.2-4.4-9.3-8.8-8.9c-2.8,1-5.2,2.6-7.2,4.9c-2.3,2-3.9,4.4-4.9,7.2c0,12.6,0,25.3-0.1,37.9c0.5,12.9,0.2,25.8-0.9,38.7
								c0.1,2.4,1.1,4.3,2.9,5.9c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,5-2.4,6.8-4.5C36,519.8,37.5,517.5,38.4,514.8z"/>
							<path opacity="0.1091" fill="#131313" d="M67.4,479.9c-0.1-25,0-50,0-75c-0.5-3.1-2.2-4.7-5.3-5.1c-4.7,0.6-8.6,2.8-11.7,6.7
								c-6.1,5.6-11.9,11.6-17.2,18c-3.8,3.2-5.8,7.2-6.2,12c0,12.6,0,25.2,0,37.8c-0.3,11.9-0.3,23.8-0.2,35.7
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C64.9,488.5,67,484.6,67.4,479.9z"/>
							<path opacity="0.1091" fill="#131313" d="M25.8,468.1c-0.1-24.9-0.1-49.8-0.1-74.7c-0.9-2.6-2.7-4.3-5.3-5.1
								c-3.5-0.4-6.5,0.9-8.9,3.8c-4.3,3.7-8.2,7.8-11.7,12.3c-2.8,2.5-3.9,5.5-3.3,9c0,24.6,0,49.1-0.1,73.7
								c0.8,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.5-0.7,8.9-3.5c4.4-3.6,8.3-7.6,11.9-11.9C25.1,474.6,26.2,471.6,25.8,468.1z"/>
							<path opacity="0.1091" fill="#131313" d="M44,466.3c0-25,0.1-50,0.1-75c-0.6-2.9-2.4-4.6-5.3-5.1c-4.3,0.3-7.9,2.2-10.8,5.7
								c-5.5,5-10.7,10.4-15.4,16.1c-3.4,3-5.2,6.7-5.2,11c0,24.5-0.2,49.1-0.2,73.6c0.6,2.9,2.3,4.6,5.2,5.2
								c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C42.1,474.2,43.9,470.6,44,466.3z"/>
							<path opacity="0.1091" fill="#131313" d="M64.1,495.6c-0.1-24.9,0-49.9,0-74.8c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.8c-5,4.4-9.5,9.2-13.7,14.3c-3.1,2.8-4.6,6.1-4.4,10.1c0,24.5-0.1,49.1-0.1,73.6
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C62.7,502.8,64.2,499.5,64.1,495.6z"/>
							<path opacity="0.1091" fill="#131313" d="M19.3,495.7c1.6-13.1,2.1-26.3,1.7-39.5c0.1-12.7,0.2-25.3,0.2-38
								c0-4.2-4.4-9.3-8.9-8.8c-2.9,1-5.3,2.7-7.3,5c-2.3,2-4,4.5-5,7.3c0,12.6,0,25.3-0.1,37.9c0.5,12.9,0.2,25.9-1,38.8
								c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9c2.7-0.9,5-2.4,6.9-4.6C16.9,500.7,18.4,498.4,19.3,495.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M60.8,386.5c0.1-7.4,0.3-14.8,0.4-22.2c-1.5-9-2.9-18-4.2-27
								c-2.3-15.4-4.6-30.8-6.9-46.2c-0.1-2.6-1.2-4.6-3.4-6c-2.1-1.6-4.3-2-6.8-1.2c-3.1,2.2-5.7,4.8-7.8,8.1
								c-2.4,3-4.1,6.3-5.1,10c2.3,15.4,4.6,30.8,6.9,46.2c0.9,6,1.8,12,2.8,17.9c2.1,9.7,2.6,19.6,1.6,29.6c0,4.7,5.5,8.8,10,7.5
								c3-1.9,5.5-4.3,7.5-7.3C58.3,393,59.9,390,60.8,386.5z"/>
							<path opacity="0.1091" fill="#131313" d="M38.5,369.5c0.1-7.3,0.1-14.6,0.2-21.9c-1.5-9-3-18-4.3-27
								c-2.3-15.4-4.6-30.8-6.9-46.2c-0.1-2.6-1.2-4.6-3.4-6.1c-2.1-1.6-4.4-2-6.8-1.2c-3.2,2.2-5.8,5-7.9,8.3
								c-2.4,3.1-4.1,6.5-5.2,10.2c2.3,15.4,4.6,30.8,6.9,46.2c0.8,6,1.8,11.9,2.8,17.9c2.1,9.7,2.7,19.4,1.8,29.4
								c0.1,4.7,5.4,8.9,10.1,7.5c3.1-2,5.6-4.5,7.7-7.6C35.8,376.2,37.5,373.1,38.5,369.5z"/>
							<path opacity="0.1091" fill="#131313" d="M43.2,328.7c-4.6-30.6-9-61.2-13.6-91.9c-1-2.8-3-4.2-6-4.2
								c-4.3,0.9-7.6,3.4-9.9,7.4c-4.8,5.8-9.1,12-13,18.5c-3,3.5-4.2,7.4-3.6,11.8c2.3,15.4,4.6,30.8,6.9,46.2
								c2.2,14.8,4.2,29.5,6.4,44.3c1,2.8,3,4.3,5.9,4.4c4.3-0.8,7.6-3.1,9.9-7c4.9-5.6,9.3-11.6,13.2-17.9
								C42.4,336.9,43.7,333,43.2,328.7z"/>
							<path opacity="0.1091" fill="#131313" d="M60.8,356.1c-4.7-30.5-9.1-61.1-13.6-91.6c-1.2-2.6-3.2-4-6-4.3
								c-3.9,0.4-6.8,2.4-8.9,5.9c-4.2,4.9-7.9,10.1-11.1,15.7c-2.6,3.1-3.5,6.6-2.6,10.4c4.5,30.2,8.8,60.4,13.4,90.5
								c1.1,2.6,3.1,4.1,5.9,4.4c3.8-0.3,6.8-2.2,8.9-5.6c4.2-4.7,8-9.8,11.3-15.2C60.7,363.2,61.6,359.9,60.8,356.1z"/>
							<path opacity="0.1091" fill="#131313" d="M63.9,324.1c-4.8-30.6-9.1-61.3-13.7-91.9c-0.8-3.2-2.8-4.6-6-4.3
								c-5.1,1.8-9,5.2-11.8,10c-6,7.5-11.6,15.4-16.7,23.6c-3.6,4.2-5.5,9-5.5,14.4c2.3,15.4,4.6,30.8,6.9,46.2
								c1,6.1,1.8,12.2,2.6,18.3c1,8.6,2.3,17.2,3.8,25.7c0.7,3.2,2.7,4.6,5.9,4.4c5.1-1.7,9-4.9,11.9-9.6
								c6.1-7.3,11.7-14.9,16.9-22.8C61.9,334.1,63.8,329.4,63.9,324.1z"/>
							<path opacity="0.1091" fill="#131313" d="M24.2,356.9c0.1-7.4,0.2-14.7,0.4-22.1c-1.5-9-2.9-18-4.2-27.1
								c-2.3-15.4-4.6-30.8-6.9-46.2c-0.1-2.6-1.2-4.6-3.4-6c-2.1-1.6-4.3-2-6.8-1.2c-3.1,2.2-5.7,4.9-7.7,8.1c-2.4,3-4,6.3-5,10
								c2.3,15.4,4.6,30.8,6.9,46.2c0.9,6,1.8,12,2.8,17.9c2.1,9.7,2.6,19.6,1.6,29.6c0,4.7,5.6,8.8,10.1,7.4c3-1.9,5.5-4.3,7.5-7.3
								C21.7,363.5,23.3,360.4,24.2,356.9z"/>
							<path opacity="0.1091" fill="#131313" d="M46.7,387c-0.4-6.8-0.9-13.6-1.3-20.4c-1.4-9.3-2.7-18.6-4-27.9
								c-2.2-15.5-4.5-30.9-6.7-46.4c-0.5-2.3-1.7-4.1-3.8-5.4c-1.8-1.6-3.9-2.2-6.3-2c-2.5,1.2-4.6,3-6.1,5.4
								c-1.8,2.2-3,4.6-3.4,7.4c2.3,15.4,4.6,30.9,6.8,46.3c0.8,6.2,1.8,12.3,2.8,18.5c1.7,9.6,2.8,19.2,3.3,28.9
								c0.6,2.3,1.9,4,3.9,5.3c1.8,1.5,3.9,2.3,6.2,2.2c2.4-1.1,4.3-2.7,5.7-4.9C45.4,391.9,46.4,389.6,46.7,387z"/>
							<path opacity="0.1091" fill="#131313" d="M75.2,352c-4.6-30.6-9-61.2-13.6-91.8c-1-2.9-3-4.3-6-4.3
								c-4.4,1.1-7.8,3.7-10.2,7.8c-5,6.1-9.6,12.5-13.7,19.3c-3.1,3.6-4.4,7.7-4,12.2c2.3,15.4,4.6,30.8,6.9,46.2
								c2.2,14.7,4.2,29.5,6.4,44.2c0.9,2.9,2.9,4.3,5.9,4.4c4.4-0.9,7.8-3.4,10.3-7.4c5.1-5.9,9.7-12.1,13.9-18.7
								C74.2,360.5,75.5,356.5,75.2,352z"/>
							<path opacity="0.1091" fill="#131313" d="M33.9,339.9c-4.9-30.4-9.2-61-13.8-91.5c-1.2-2.5-3.2-3.9-6-4.2
								c-3.7,0.3-6.5,2.1-8.5,5.5c-3.9,4.6-7.4,9.5-10.4,14.7c-2.5,3-3.2,6.3-2.3,9.9c4.5,30.2,8.8,60.4,13.4,90.5
								c1.2,2.5,3.2,4,5.9,4.4c3.7-0.2,6.5-1.9,8.6-5.2c4-4.4,7.5-9.2,10.6-14.3C34,346.7,34.8,343.5,33.9,339.9z"/>
							<path opacity="0.1091" fill="#131313" d="M52.2,338c-4.6-30.6-9-61.2-13.6-91.8c-1-2.8-3-4.2-6-4.2c-4.3,0.9-7.6,3.4-9.9,7.4
								c-4.8,5.9-9.2,12.1-13.1,18.6c-3,3.5-4.2,7.5-3.7,11.8c2.3,15.4,4.6,30.8,6.9,46.2c2.2,14.7,4.2,29.5,6.4,44.3
								c1,2.8,2.9,4.3,5.9,4.4c4.3-0.8,7.6-3.2,10-7.1c4.9-5.7,9.3-11.7,13.3-18C51.4,346.3,52.6,342.4,52.2,338z"/>
							<path opacity="0.1091" fill="#131313" d="M72.3,367.3c-5-30.4-9.3-60.9-13.8-91.4c-1.2-2.5-3.2-4-6-4.3
								c-3.7,0.3-6.6,2.2-8.6,5.6c-4,4.7-7.5,9.7-10.6,15c-2.5,3-3.3,6.4-2.4,10c4.5,30.1,8.8,60.3,13.5,90.4c1.2,2.6,3.1,4,5.9,4.4
								c3.7-0.2,6.6-2,8.7-5.3c4.1-4.5,7.7-9.4,10.8-14.6C72.4,374.2,73.2,370.9,72.3,367.3z"/>
							<path opacity="0.1091" fill="#131313" d="M28.1,367.2c-0.5-6.8-0.9-13.5-1.4-20.3c-1.4-9.3-2.7-18.6-4-27.9
								c-2.2-15.5-4.5-30.9-6.8-46.4c-0.6-4.2-5.6-8.6-10.1-7.4c-2.7,1.5-5,3.6-6.7,6.3c-2,2.4-3.4,5.2-4,8.2
								c2.3,15.4,4.6,30.9,6.8,46.3c0.8,6.2,1.8,12.3,2.8,18.5c1.7,9.6,2.8,19.2,3.3,28.9c0.4,4.1,5.9,8.4,10.1,7.4
								c2.6-1.4,4.7-3.3,6.4-5.8C26.3,372.7,27.5,370.1,28.1,367.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M7.2,103.2c0.8,27.2,1.5,54.5,2.3,81.7c0.7,22.1,1.2,44.3,4.1,66.3
								c1.1,8.7,2.6,17.2,4.7,25.7c1.6,6.9,3.7,13.5,5.7,20.3c1.4,4.5,2.7,9,3.8,13.6c0.8,3.2,1.4,6.4,1.7,9.6c0.5,3,0.6,6,0.4,9
								c0-1.6,0.1-3.1,0.1-4.7c-0.1,3.7-0.8,7.3-2.1,10.8c-1.2,3-0.7,5.7,1.6,7.9c2.6,1.8,5.3,1.9,8,0.1c5-4,9.5-8.5,13.5-13.6
								c4.3-4.8,7.9-10,10.9-15.7c0.7-6.5,1.4-12.7,0.9-19.2c-0.4-5.7-1.2-11.3-2.4-16.8c-1.1-5.2-2.5-10.4-3.9-15.6
								c-1.9-7.4-3.7-15.3-5-22.8c-3.6-20.4-4.6-41-5.5-61.7c-1.5-34.1-2-68.3-3-102.4c-0.7-2.9-2.5-4.6-5.4-5
								c-4.3,0.2-7.8,2.1-10.6,5.7c-5.4,4.9-10.4,10.2-15,15.9C8.9,95.3,7.2,98.9,7.2,103.2z"/>
							<path opacity="0.1091" fill="#131313" d="M-15.3,86.4c0.9,27.2,1.6,54.5,2.4,81.7c0.6,19.9,0.9,40,3.3,59.8
								c1.8,15.3,4.6,30.4,9.3,45.2c1.8,5.7,3.6,11.3,5.1,17c0.7,2.2,1.2,4.4,1.5,6.6c0.7,3.1,1.2,6.3,1.3,9.6c0-1.6,0-3.1,0-4.7
								c0,3.6,0,7.1-0.1,10.7c0-1.6,0.1-3.1,0.1-4.7c-0.1,3.8-0.9,7.4-2.2,11c-0.2,1.6-0.4,3.1-0.6,4.7c0.7,1.1,1.3,2.3,2,3.4
								c1.2,0.4,2.4,0.9,3.7,1.3c3.2-0.3,5.7-1.7,7.6-4.1c5.5-4.8,10.1-10.4,14.6-16.1c2.2-2.5,4.2-5.2,5.8-8.2
								c2.1-2.3,3-5.1,2.7-8.3c0-6.8-0.3-13.4-1.4-20.1c-0.7-4.4-1.6-8.7-2.5-13.1c-1.3-6.3-2.9-12.6-3.9-18.9
								c-1.2-8-2.7-15.9-3.7-24c-2.7-20.8-3.4-41.7-4.3-62.6c-1.3-32-1.7-63.9-2.8-95.9c-0.6-3-2.4-4.7-5.4-5.1
								c-4.5,0.4-8.2,2.5-11.1,6.2c-5.8,5.3-11.1,11-16.1,17.1C-13.3,78-15.2,81.9-15.3,86.4z"/>
							<path opacity="0.1091" fill="#131313" d="M-10.7,46.8c-2.1,9.8-2.8,19.6-1.9,29.5c0.4,9.1,0.7,18.1,0.9,27.2
								c0.5,16.4,0.9,32.9,1.5,49.3c0.7,17.6,1.8,35.1,4.2,52.6c1.3,9.3,3.2,18.5,5.5,27.5c1.5,5.9,3.5,11.6,5.2,17.5
								c1.8,6.4,3.6,12.9,4,19.6c0.1,2,0.1,3.9,0.2,5.9c-0.1,3.6-0.7,7.2-2,10.6c-0.2,2.6,0.8,4.7,2.8,6.3c1.9,1.8,4.2,2.4,6.7,1.8
								c3.4-1.9,6.2-4.4,8.6-7.5c2.7-2.8,4.7-6,6.1-9.7c0.2-1.9,0.4-3.9,0.6-5.8c0.1-3.1,0-6.1-0.3-9.2c-0.5-5.4-1.5-10.8-2.7-16.1
								c-1.1-4.5-2.3-9-3.4-13.5c-2.4-9.5-4-19.2-5.8-28.8c0.2,1.1,0.4,2.2,0.6,3.4c-0.8-5.4-1.5-10.7-2.3-16.1
								c0.2,1.1,0.3,2.3,0.5,3.4c-1.1-10.5-2.1-21-2.6-31.6c-0.6-10.6-1-21.2-1.3-31.8c-0.4-12.2-0.7-24.4-1.1-36.6
								c-0.2-9-0.5-18.1-1-27.1c-0.8-9.4-0.4-18.8,1.3-28.2c0.4-4.8-4.1-9.6-9.1-8.6c-3.4,1.7-6.3,4-8.8,6.9
								C-7.1,40.2-9.2,43.3-10.7,46.8z"/>
							<path opacity="0.1091" fill="#131313" d="M6.4,78.3c0.3,21.1,1,42.3,1.7,63.4c0.3,11.3,0.6,22.5,1,33.8
								c0.4,10.5,0.7,21.1,1.6,31.6c1.4,17.2,3.6,34.5,8.2,51.2c1.6,5.7,3.5,11.4,5.2,17c1.7,5.5,3,11,4.2,16.7
								c-0.2-1.2-0.4-2.5-0.7-3.7c0.7,4.1,1.1,8.2,0.9,12.3c-0.1,3.8-0.8,7.4-2.1,11c-0.5,2.8,0.3,5,2.4,6.8
								c2.1,1.8,4.5,2.2,7.1,1.2c3.9-2.6,7.3-5.8,10.2-9.5c3.2-3.5,5.7-7.3,7.7-11.6c0.7-3.3,1-6.7,0.9-10.1c0.2-3.3,0-6.7-0.5-10
								c-0.4-3.4-1-6.9-1.7-10.3c-1.2-6.6-3.1-13-4.8-19.4c-1.5-5.9-2.7-11.9-4-17.9c-4.2-20.5-5.6-41.3-6.5-62.2
								c-1.3-30.6-2-61.3-3-91.9c0,1.3,0,2.5,0.1,3.8c-0.1-7-0.2-13.9-0.3-20.9c-1-2.6-2.7-4.2-5.4-5c-3.4-0.5-6.2,0.7-8.5,3.4
								c-4.1,3.5-7.7,7.4-11,11.7C6.7,72,5.8,74.9,6.4,78.3z"/>
							<path opacity="0.1091" fill="#131313" d="M7,45.8c-1.1,9.7-1.4,19.5-0.9,29.2c0.2,6.1,0.3,12.3,0.4,18.4
								c0.3,15.3,0.8,30.6,1.2,45.9c0.6,21.1,1.2,42.4,4.6,63.3c1.4,8.6,3.1,17.3,5.4,25.7c1.6,5.9,3.7,11.6,5.5,17.4
								c1.7,5.4,3.2,11,4.1,16.6c0.2,2.1,0.5,4.2,0.7,6.3c0.3,3.9-0.1,7.8-1,11.7c0.4-1.1,0.7-2.2,1.1-3.3c-1,2.9-1.9,5.8-2.9,8.6
								c0.3,2.4,1.4,4.3,3.3,5.7c1.7,1.7,3.8,2.5,6.1,2.5c2.6-1.1,4.8-2.8,6.6-5c2-2.1,3.4-4.5,4.1-7.2c1-2.9,1.6-5.9,1.8-9
								c0.4-3.1,0.5-6.2,0.2-9.4c-0.4-4.3-1.1-8.6-2-12.9c-1.4-6.7-3.7-13.2-5.7-19.8c-2.5-8.3-4.4-16.8-5.8-25.4
								c-3.6-20.8-4.6-41.9-5.2-62.9c-0.4-14.3-0.9-28.6-1.2-42.9c-0.1-6.1-0.3-12.3-0.4-18.4C26,68.3,26,55.7,26.7,43.2
								c-0.3-2.3-1.3-4.3-3.1-5.8c-1.6-1.7-3.6-2.7-6-2.8c-2.6,0.9-4.7,2.4-6.5,4.5C9.1,41,7.7,43.2,7,45.8z"/>
							<path opacity="0.1091" fill="#131313" d="M-30.3,74.5c0.9,27.2,1.6,54.5,2.4,81.7c0.6,19.9,0.9,40,3.3,59.8
								c1,8.5,2.3,17.1,4.1,25.5c1.4,6.6,3.2,13.1,5.3,19.5c1.8,5.6,3.7,11.3,5.2,17c0.7,2.2,1.2,4.4,1.5,6.6
								c0.7,3.2,1.2,6.3,1.3,9.6c0-1.5-0.1-3-0.1-4.5c0,3.5,0,7,0,10.5c0-1.5,0-3,0-4.5c-0.2,3.8-0.9,7.5-2.3,11
								c-0.2,1.5-0.3,3.1-0.5,4.6c0.7,1.1,1.3,2.2,2,3.3c1.2,0.5,2.4,0.9,3.6,1.4c3.1-0.2,5.6-1.5,7.5-3.9c5.3-4.6,9.8-10,14.1-15.5
								c2.2-2.4,4-5.1,5.6-7.9c2-2.3,2.9-5,2.6-8c-0.3-6.4-0.8-12.6-1.9-19c-0.8-4.4-1.6-8.7-2.5-13c-1.3-6.5-2.8-13.1-3.6-19.7
								c-0.2-2-0.4-4-0.6-6c-1.1-6.2-2-12.4-2.7-18.7c-2.5-20.9-3.1-42-4-63c-1.3-32-1.7-64-2.8-95.9c-0.6-2.9-2.4-4.6-5.4-5.1
								c-4.5,0.4-8.1,2.4-11,6.1c-5.7,5.2-10.9,10.8-15.8,16.7C-28.3,66.3-30.1,70.1-30.3,74.5z"/>
							<path opacity="0.1091" fill="#131313" d="M-10.6,108.1c1,27.2,1.6,54.5,2.4,81.8c0.7,22.1,1.2,44.3,4.1,66.3
								c1.2,8.7,2.7,17.2,4.7,25.8c0.9,4.6,2.2,9,3.7,13.4c0.7,2.3,1.5,4.6,2.2,7c1.4,4.5,2.8,9,3.9,13.6c0.8,3.2,1.4,6.4,1.6,9.6
								c0.4,3,0.5,6,0.3,9c0.2-1.8,0.4-3.5,0.6-5.3c-0.5,3.6-1,7.2-1.5,10.8c0.2-1.8,0.5-3.5,0.7-5.3c-0.5,1.7-1,3.4-1.5,5.1
								c-0.3,1.7-0.6,3.5-1,5.2c0.6,1.2,1.2,2.4,1.8,3.5c1.3,0.4,2.5,0.8,3.8,1.2c3.5-0.7,6.3-2.4,8.5-5.2
								c6.2-5.8,11.6-12.3,16.8-18.9c2.6-2.9,4.8-6.1,6.8-9.5c2.4-2.6,3.5-5.7,3.5-9.3c0.2-7.2,0.1-14.2-1-21.3
								c-0.7-4.4-1.5-8.7-2.4-13.1c-1.3-6.1-2.9-12-3.9-18.2c-1.3-7.7-2.9-15.5-4-23.2c-2.9-20.6-3.6-41.4-4.5-62.1
								c-0.6-14.3-0.9-28.7-1.4-43c-0.5-17.6-0.8-35.2-1.4-52.8c-0.4-3.2-2.2-4.8-5.4-5c-5,0.9-9.1,3.4-12.4,7.6
								C8.1,81.8,1.9,88.4-3.8,95.3C-7.8,98.8-10,103-10.6,108.1z"/>
							<path opacity="0.1091" fill="#131313" d="M20.9,70.4c-0.6,6.7-1.2,13.4-1.7,20.1c0.2,5.9,0.5,11.8,0.7,17.7
								c0.4,12.2,0.7,24.4,1.1,36.6c0.6,20.9,1,41.8,2.9,62.7c0.6,6.9,1.4,13.9,2.5,20.8c0.7,4.3,1.5,8.6,2.3,12.8
								c2.2,11.9,6.1,23.4,9.7,34.9c-0.3-1.1-0.6-2.2-0.9-3.3c1.7,6.3,3.7,12.9,4,19.5c0,2,0.1,4,0.1,6c-0.1,3.8-0.9,7.5-2.2,11
								c-0.1,2.6,0.8,4.7,2.9,6.3c1.9,1.8,4.1,2.4,6.7,1.8c3.3-1.8,6.1-4.3,8.3-7.3c2.6-2.7,4.5-5.9,5.8-9.4
								c0.9-3.3,1.4-6.7,1.4-10.1c0.3-3.3,0.3-6.7-0.2-10c-0.6-5.6-1.8-11-3.2-16.4c-1.2-4.3-2.5-8.6-3.8-12.9
								c-2.3-7.9-4.2-15.9-6-24c0.2,1.1,0.4,2.2,0.6,3.3c-0.9-5.2-1.8-10.4-2.7-15.7c0.2,1.1,0.4,2.2,0.5,3.3
								c-1.3-10.4-2.4-20.7-3-31.2c-0.6-10.6-1-21.2-1.4-31.8c-0.4-12.2-0.7-24.4-1.1-36.6c-0.2-9-0.5-18-1-27
								c-0.8-9.3-0.5-18.5,0.8-27.8c0.3-4.6-4.4-9.4-9.1-8.6c-3.2,1.5-6,3.6-8.3,6.4C24.1,64.2,22.2,67.1,20.9,70.4z"/>
							<path opacity="0.1091" fill="#131313" d="M-20.9,62.6c1.1,24,1.7,48.1,2.4,72.1c0.6,20.9,1,41.8,2.9,62.6
								c1.5,16.2,3.9,32.5,8.6,48.1c1.4,4.6,2.8,9.1,4.2,13.7c1.7,5.5,3,11,4.1,16.7c-0.2-1.3-0.4-2.5-0.6-3.8
								c0.7,4.1,1,8.2,0.9,12.3c-0.1,3.7-0.8,7.4-2.1,10.9c-0.8,2.8,0,5.2,2.1,7.2c2.3,1.8,4.7,2.2,7.4,0.9c4.3-3,8-6.6,11.2-10.7
								c3.5-3.9,6.4-8.1,8.7-12.8c0.2-5.5,0.1-10.8-0.5-16.2c-0.5-4.3-1.2-8.5-2-12.8c-1.1-5.6-2.4-11.1-3.6-16.6
								c-1.6-7.4-2.5-14.8-3.7-22.2c-3.8-22.9-4.8-46.1-5.8-69.3c-1.4-34.1-1.9-68.2-3.2-102.3c-0.8-2.7-2.6-4.4-5.3-5.1
								c-3.8-0.2-6.9,1.2-9.4,4.3c-4.6,4.1-8.8,8.5-12.7,13.4C-20.1,55.7-21.3,58.9-20.9,62.6z"/>
							<path opacity="0.1091" fill="#131313" d="M-1.3,55.6c-1.9,12.6-2.2,25.2-1,37.8c0.4,12.2,0.7,24.4,1.1,36.6
								c0.6,18.8,0.9,37.6,2.3,56.3c1,14,2.6,28.1,5.4,41.9c2.3,11.4,5.8,22.4,9.2,33.5c-0.3-1.1-0.6-2.2-0.9-3.3
								c1.7,6.3,3.6,12.9,4,19.5c0.1,2,0.1,4,0.2,6c-0.1,3.7-0.8,7.3-2,10.8c-0.3,2.6,0.6,4.8,2.7,6.5c2,1.8,4.3,2.3,6.8,1.7
								c3.5-2.1,6.5-4.7,9-8c2.8-3,4.9-6.4,6.4-10.1c0.7-4.1,0.9-8.3,0.7-12.5c-0.1-3.2-0.5-6.5-1.1-9.7c-1.4-9-3.9-17.7-6.2-26.5
								c0.3,1.1,0.6,2.2,0.8,3.4c-0.8-3.3-1.6-6.6-2.3-9.8c0.2,1.1,0.5,2.3,0.7,3.4c-1-5.2-2.1-10.4-3.1-15.6
								c-1.3-6.2-2.3-12.5-3-18.8c0.1,1.2,0.3,2.3,0.4,3.5c-0.6-5.4-1.2-10.7-1.7-16.1c0.1,1.2,0.2,2.3,0.3,3.5
								c-1.6-19.9-2.3-39.9-2.9-59.8c-0.4-12.2-0.7-24.4-1.1-36.6c-0.1-6-0.4-12-0.7-18c-0.7-9.2-0.4-18.5,0.9-27.7
								c0.4-2.6-0.5-4.8-2.4-6.5c-1.9-1.9-4.1-2.6-6.7-2.1C11.1,40.5,8,42.9,5.5,46C2.6,48.7,0.3,51.9-1.3,55.6z"/>
							<path opacity="0.1091" fill="#131313" d="M17.8,89.6c0.5,24.2,1.3,48.4,2,72.5c0.6,20.9,1,41.8,2.9,62.6
								c1.5,16.2,4,32.5,8.6,48.2c1.4,4.6,2.8,9.1,4.2,13.6c1.7,5.5,3,11,4.2,16.6c-0.2-1.3-0.4-2.6-0.6-3.9
								c0.7,4.1,1.1,8.2,0.9,12.3c-0.1,3.8-0.8,7.5-2.2,11c-0.6,2.8,0.2,5.1,2.4,6.9c2.2,1.8,4.6,2.2,7.2,1.2
								c4-2.7,7.4-5.9,10.4-9.7c3.3-3.5,5.9-7.5,7.8-11.9c0.8-3.5,1.2-7.1,1.2-10.6c0.2-3.4,0.1-6.9-0.3-10.3
								c-0.6-4.6-1.3-9.2-2.3-13.8c-1.2-5.4-2.7-10.6-4.2-15.8c-2.2-7.6-4.2-15.5-5.6-23.3c-3.8-20.5-4.9-41.2-5.8-62
								c-0.7-16.5-1.1-33-1.6-49.5c-0.5-17.8-1.1-35.5-1.3-53.3c-1-2.6-2.7-4.3-5.4-5c-3.4-0.4-6.3,0.7-8.6,3.5
								c-4.1,3.6-7.8,7.5-11.1,11.8C18.1,83.4,17.1,86.3,17.8,89.6z"/>
							<path opacity="0.1091" fill="#131313" d="M-29.5,88.7c1,27.2,1.6,54.5,2.4,81.7c0.7,22.1,1.2,44.2,4.2,66.1
								c1.2,8.6,2.8,17.1,4.9,25.5c0.9,4.5,2.2,8.9,3.9,13.2c0.8,2.3,1.5,4.6,2.3,6.8c1.4,4.5,2.8,8.9,3.9,13.5
								c0.8,3.2,1.4,6.4,1.6,9.7c0.4,3.1,0.5,6.1,0.2,9.2c0.1-1.7,0.3-3.4,0.4-5.1c-0.2,3.8-1,7.5-2.5,11c-0.3,1.7-0.6,3.4-0.9,5.1
								c0.6,1.2,1.2,2.3,1.9,3.5c1.3,0.4,2.5,0.8,3.8,1.2c3.4-0.6,6.2-2.2,8.3-5c6.1-5.5,11.3-11.9,16.4-18.3
								c2.5-2.8,4.7-5.9,6.6-9.2c2.3-2.6,3.4-5.6,3.4-9.1c-0.3-6.7-0.9-13.2-2-19.8c-0.7-4.4-1.6-8.7-2.5-13
								c-1.3-6.3-2.7-12.7-3.4-19.1c-0.2-1.9-0.4-3.8-0.6-5.8c-1.1-6.1-2-12.2-2.7-18.4c-2.5-20.8-3.2-41.7-4-62.7
								c-0.6-14.3-0.9-28.7-1.4-43c-0.5-17.6-0.8-35.2-1.4-52.8c-0.4-3.1-2.2-4.8-5.4-5.1c-5,0.8-9.1,3.3-12.3,7.5
								C-10.9,62.6-17,69.1-22.7,76C-26.7,79.4-28.9,83.7-29.5,88.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M798.3,434.7c1.6-0.9,3.4-1.5,5.2-1.8c-11.8-3.2-23.6-6.4-35.5-9.6
								c11.2,6.3,22.5,12.7,33.7,19c1.6,0.4,3.3,0.7,4.9,1.1c2.5-0.2,3.9-1.4,4.3-3.5c1-1.4,1-2.8,0-4.3c-0.8-3.9-2.9-7-6.4-9.5
								c-3-3-6-6.1-9-9.1c-3.2-2.8-6.4-5.7-9.6-8.5c-2.7-3.3-5.9-5.3-9.9-5.8c-2.2-1-4.2-0.6-6,1.1c7.9,16.5,15.8,33,23.7,49.5
								c1.7-0.9,3.5-1.7,5.2-2.6c2.8-1.6,5.4-3.6,7.8-5.9c2.7-1.5,4.4-3.7,4.9-6.7c0.9-2.6,1.3-5.3,1.3-8.1
								c-0.9-3.2-2.9-5.6-5.8-7.1c-4.3-3.6-9-6.8-14-9.5c-3.6-2-7.3-4-10.9-6c-4-2.9-8.4-4-13.1-3.3c-4.4,2.3-9,4.1-13.4,6.5
								c-2.5,1.7-3.4,4-2.6,6.9c2.1,4,5.5,6.7,10.1,8c7.2,3.3,14.5,6.1,22.1,8.4C789.7,436,794,436.3,798.3,434.7z"/>
							<path opacity="0.1091" fill="#131313" d="M769.9,418.3c2.1-0.4,4.2-0.6,6.3-0.6c1.3,0,2.5-0.1,3.7-0.4
								c-8-4.3-16.1-8.6-24.1-12.9c0.4,8.6,0.8,17.2,1.1,25.8c0.2,5.5,8.3,8.6,11.8,4.1c2.7,1,5.3,0.7,7.7-0.9
								c2.3-1.7,4.4-3.7,6.3-5.8c1.6-1.6,2.9-3.3,4.1-5.1c1.9-2,2.8-4.4,2.6-7.2c0.4-2.4-0.3-4.3-2.1-5.8c-2-2.8-4.5-5.2-7.4-7.2
								c-2.1-1.5-4.1-2.9-6.2-4.4c-2.6-2.3-5.4-2.9-8.6-1.9c-4.4,0.9-8.7,1.5-13.1,2.1c-5.1,0.8-7.2,7.8-4.5,11.7
								c3.1,2.6,6.6,4.5,10.5,5.8C762,417.2,765.9,418.1,769.9,418.3z"/>
							<path opacity="0.1091" fill="#131313" d="M772.8,384.2c4.3-0.3,8.4-0.4,12.7-0.2c-1.3-0.1-2.7-0.2-4-0.4
								c0.8,0.2,1.6,0.5,2.3,0.7c-4.2-2.6-8.3-5.1-12.5-7.7c1.4,1.6,2.8,3.2,4.2,4.8c-0.8-1-1.7-2-2.5-2.9c0.6,0.8,1,1.7,1.2,2.7
								c0.2-1,0.5-2,0.7-3c-0.5,1.8-1.1,3.5-1.6,5.3c0.4-0.9,0.8-1.8,1.2-2.7c-0.9,1.6-1.8,3.3-2.7,4.9c0.5-0.8,1.1-1.6,1.6-2.5
								c-1.2,1.4-2.4,2.8-3.6,4.3c0.7-0.7,1.3-1.5,2-2.2c-1.4,1.1-2.8,2.3-4.3,3.4c0.8-0.6,1.6-1.2,2.3-1.8c-1.5,0.7-3,1.5-4.4,2.2
								c0.9-0.4,1.8-0.8,2.7-1.3c-0.7,0.1-1.5,0.1-2.2,0.2c-1,0.2-1.9,0.4-2.9,0.6c-0.7,0.9-1.4,1.7-2.2,2.6
								c-0.6,1.4-1.2,2.7-1.8,4.1c-0.9,2-1.2,4-0.9,6c-0.3,1.2,0.1,2.1,1.1,2.8c0.6,1,1.5,1.6,2.7,1.9c1.1,0.7,2.2,1.1,3.5,1.2
								c1.6,0.5,3.2,0.4,4.7-0.3c1.4-0.4,2.8-0.8,4.2-1.2c1.9-0.8,3.7-1.7,5.6-2.5c2.9-2,5.1-4.8,7.4-7.5c1.9-2.2,3.7-4.5,5.4-6.8
								c1-0.9,1.6-2.1,1.7-3.4c0.5-1.8,0.7-3.6,0.6-5.5c-0.1,1-0.2,2-0.2,3.1c0.1-1.1,0.2-2.2,0.3-3.3c0.5-1.9,0-3.5-1.5-4.6
								c-2.9-3.4-6.7-6.3-10.8-7.9c-1.7-0.6-3.5-1.2-5.2-1.8c-1.3-0.1-2.7-0.2-4-0.4c-1.9,0.1-3.8,0.3-5.7,0.4
								c-2.4,0.1-4.8,0.4-7.1,0.7c-2.2,1-3.7,2.7-4.4,5c-1,2.3-1,4.5,0,6.7c2.2,2.2,4.8,3.8,7.8,4.7
								C766.7,383.9,769.7,384.4,772.8,384.2z"/>
							<path opacity="0.1091" fill="#131313" d="M796.1,410c1.7-0.6,3.4-1.2,5.1-1.9c-1.4,0.4-2.7,0.8-4.1,1.2
								c1.4,0.2,2.8,0.5,4.2,0.7c-8.4-3.5-16.8-7.1-25.2-10.6c6.4,3.8,12.7,7.5,19.1,11.3c-5.1-3.9-10.2-7.8-15.3-11.7
								c2.4,2.4,4.8,4.7,7.3,7.1c-3.4-4.2-6.9-8.4-10.3-12.5c4.5,7.3,8.9,14.6,13.4,21.9c-3.1-6.8-6.2-13.5-9.3-20.3
								c0.8,4.9,1.7,9.7,2.5,14.6c-0.1-4.6-0.2-9.2-0.2-13.9c-1.3,7-2.6,14-3.8,21c-1.2,3.1-0.9,5.9,1.1,8.4c2,1.8,4.3,2.6,7,2.4
								c3.4-0.6,6.7-1.6,9.8-3.2c2.8-2.8,5.6-5.5,8.4-8.2c1.6-1.2,2.7-2.8,3.4-4.8c0.7-2.2,1.1-4.5,1.2-6.8c-0.5-1.3-1-2.6-1.5-3.9
								c-2.8-2.9-6-5.3-9.4-7.3c-2.7-1.9-5.5-3.6-8.5-5c-3.4-2.1-7.1-3.7-10.9-4.8c-4,0.3-7.6,0.5-11.5,1.8
								c-2.6,0.9-5.2,1.9-7.7,3.1c-2.3,1.8-3.1,4.1-2.6,6.9c1.5,3.7,4.3,6.1,8.3,7.3c6,2.9,12.3,5.2,18.7,7.1
								C788.8,411.7,792.5,411.7,796.1,410z"/>
							<path opacity="0.1091" fill="#131313" d="M793.7,382.6c2.7-0.9,5.1-1.6,7.9-1.8c-1.9-0.3-3.7-0.6-5.6-1
								c0.6,0.1,1.2,0.3,1.8,0.4c-4.6-2.2-9.3-4.4-13.9-6.7c3.1,1.8,6.3,3.6,9.4,5.5c-2-1.3-4-2.7-6-4c2.2,1.9,4.5,3.7,6.7,5.6
								c-1.7-1.5-3.4-2.9-5.1-4.4c1.8,2,3.6,4.1,5.4,6.1c-2.1-2.9-4.2-5.8-6.2-8.7c0.5,2.5,0.8,4.8,0.3,7.3
								c-0.1,0.8-0.3,1.6-0.7,2.3c-0.4,1.6-1.5,2.6-3.2,3.2c0.9-0.4,1.8-0.8,2.7-1.2c-2,0.8-3.9,1.6-5.9,2.5c-1.8,1.5-3,3.4-3.5,5.7
								c-0.9,2.2-1,4.5-0.4,6.7c1.4,1.8,3.2,3,5.5,3.5c2.2,0.8,4.3,0.9,6.5,0.2c3.4-1.4,6.7-3,10-4.7c1.8-1,3.1-2.3,4-4.1
								c1.9-2.7,3.7-5.4,5.6-8c1.7-4.3,2-8.7,1-13.4c-0.4-1.2-0.8-2.3-1.2-3.5c-2.3-2.2-4.9-4-7.7-5.3c-2.2-1.4-4.5-2.5-7-3.3
								c-2.8-1.4-5.8-2.3-9-2.7c-2.2-0.1-4.4,0.1-6.5,0.7c-2,0.4-3.9,1-5.8,1.7c-2.8,0.4-4.6,2-5.6,4.6c-1,2.6-0.6,5,1.2,7.1
								c3.7,2.8,7.7,4.9,12.1,6.4C784.7,381.2,789.1,382.3,793.7,382.6z"/>
							<path opacity="0.1091" fill="#131313" d="M756.6,406.4c3.7-0.4,7.4-0.7,11.2-0.8c-1.1,0-2.3-0.1-3.4-0.1
								c0.6,0.2,1.2,0.3,1.8,0.5c-3.5-2.3-7.1-4.5-10.6-6.8c0.4,0.5,0.8,1,1.1,1.5c0-1,0-2-0.1-3c0,1.3,0,2.6,0,3.9
								c0.1-1,0.2-2,0.3-3c-0.2,1.3-0.4,2.6-0.7,4c2-2.7,3.9-5.5,5.9-8.2c-1.8,0.5-3.5,1-5.3,1.5c-1.1,0.4-2.2,0.8-3.3,1.2
								c-1.1,1.4-2.1,2.8-3.2,4.2c-1.1,2.2-2.2,4.4-3.2,6.5c-1.6,3-2.5,6.1-2.9,9.4c-0.7,1.5-0.6,2.9,0.3,4.2c0.2,1.3,1,2.2,2.2,2.7
								c0.9,1,2,1.5,3.4,1.4c1.8,0.7,3.5,0.4,5.2-0.9c1.1-0.1,2.3-0.1,3.4-0.2c2.8-0.9,5.2-2.3,7.2-4.2c3.1-2.5,6.4-5.3,8.8-8.6
								c0.6-0.9,1.3-1.9,1.9-2.8c0.2-1,0.4-2,0.6-3c0.2-2.1,0.2-4.1-0.1-6.2c0-1.8-0.6-3.3-1.8-4.7c-1.6-2.3-3.7-3.9-6.3-5
								c-2.4-1.4-5-2.1-7.8-2.1c-4.5,0.5-8.9,0.9-13.4,1.7c-2,1.2-3.3,2.9-4,5.2c-1,2.1-1.1,4.3-0.5,6.5c1.6,2,3.6,3.3,6,4.1
								C751.7,406.5,754.1,406.9,756.6,406.4z"/>
							<path opacity="0.1091" fill="#131313" d="M782.3,437.5c0.8-0.4,1.7-0.8,2.5-1.2c-1.2,0.5-2.5,0.9-3.7,1.4
								c0.8-0.3,1.7-0.5,2.5-0.8c-9.5-3.7-18.9-7.3-28.4-11c5.4,9,10.9,18,16.3,27c1.1,2.5,2.8,3.7,5.1,3.7c1.4,0.6,2.6,0.4,3.6-0.6
								c1.6-0.4,2.4-1.5,2.6-3.1c1.7-3.2,2-6.6,0.7-10.4c-0.5-3.8-1.1-7.7-1.6-11.5c-0.8-3.8-1.6-7.6-2.4-11.3
								c-0.9-2.6-1.8-5.2-2.6-7.8c-0.7,7.6-1.5,15.2-2.2,22.8c-9.2,16.8-8.9,24.3,0.8,22.5c1.8,0.3,3.3-0.1,4.7-1.2
								c2.4-1.3,4.8-3,6.8-4.8c1.3-1.2,2.6-2.6,3.8-3.9c1.8-1.5,3.1-3.3,4-5.4c1.2-1.9,1.7-3.9,1.6-6.2c-0.1-2.8-1.3-5.1-3.5-6.7
								c-2.9-3.5-6.3-6.5-10-9.1c-2.7-1.9-5.4-3.8-8.2-5.8c-3.1-2.8-6.6-3.9-10.6-3.3c-4.1,2.7-8.4,5-12.9,6.9
								c-2.1,1.8-3,4.1-2.6,6.9c1.1,3.5,3.5,5.7,7.2,6.8c5.3,2.6,10.8,4.7,16.5,6.3C775.9,439.5,779.2,439.4,782.3,437.5z"/>
							<path opacity="0.1091" fill="#131313" d="M811.1,406.8c1.6-0.9,3.3-1.5,5.1-1.9c-1.6,0.3-3.3,0.6-4.9,0.9c0.8,0,1.6,0,2.3,0
								c-10.5-3.9-21.1-7.8-31.6-11.7c11,5.5,22,11,33,16.5c-10.4-7.7-20.7-15.3-31.1-23c7,7.6,13.9,15.2,20.9,22.8
								c-5.1-7.1-10.2-14.3-15.3-21.4c2.9,8.5,5.8,16.9,8.8,25.4c-0.7-7.2-1.5-14.3-2.2-21.5c-1.2,7.3-2.3,14.6-3.5,21.9
								c-1,2.9-0.5,5.4,1.6,7.5c2,1.7,4.4,2.4,7,2.3c3.3-1,6.4-2.3,9.5-3.9c5.1-4.4,9.6-9.5,13.5-15.1c1.3-3,0.9-5.7-1.5-8.1
								c-3.5-3.3-7.4-6.1-11.6-8.4c-5-3.3-10.1-6.2-15.5-8.8c-3.9-2.8-8.1-3.8-12.6-2.9c-4.7,0.1-9,1-13.1,2.9
								c-1.2,0.5-2.3,1.1-3.5,1.7c-2.6,1.6-3.5,3.9-2.6,6.9c2.4,4.1,5.9,6.8,10.7,8.1c7.5,3.4,15.3,6.4,23.2,8.8
								C802.2,408,806.6,408.3,811.1,406.8z"/>
							<path opacity="0.1091" fill="#131313" d="M765.8,395.5c3.7-0.4,7.3-0.7,11-0.9c-1.3-0.1-2.6-0.2-3.9-0.3
								c0.6,0.2,1.2,0.3,1.8,0.5c-4.1-2.5-8.2-5-12.3-7.6c1.3,1.8,2.6,3.5,3.8,5.3c-0.7-1.1-1.4-2.1-2.1-3.2c0,0.3,0.1,0.6,0.1,0.9
								c0.2-1,0.4-2,0.6-3.1c-0.3,1.2-0.6,2.5-0.9,3.7c0.4-1,0.8-1.9,1.2-2.9c-0.7,1.1-1.3,2.3-2,3.4c1.6-1.6,3.1-3.1,4.7-4.7
								c-2.3,1.4-4.6,2.6-7.1,3.5c-2.5,2.5-4.4,5.5-5.6,8.9c-1.6,3.2-2.4,6.6-2.6,10.2c0.7,2.4,2.3,4.1,4.7,5
								c2.3,1.1,4.6,1.1,6.8-0.1c2-0.2,3.9-0.8,5.7-1.9c1.9-0.7,3.5-1.7,4.8-3.2c2.5-2.2,4.8-4.6,6.9-7.2c0.9-1,1.7-2.1,2.4-3.2
								c1.1-1.3,1.7-2.8,1.7-4.6c0.1-1.3,0.1-2.7,0.2-4c0.3-2.2-0.2-4.2-1.5-6c-2-2.4-4.4-4.3-7.3-5.6c-2.7-1.6-5.7-2.5-8.8-2.6
								c-1.8-0.2-3.5,0-5.2,0.5c-2.9,0.3-5.9,0.6-8.8,1.1c-2.2,1-3.6,2.7-4.4,5c-1,2.2-1,4.5-0.1,6.7c2.1,2.2,4.6,3.7,7.5,4.6
								C760,395.2,762.8,395.7,765.8,395.5z"/>
							<path opacity="0.1091" fill="#131313" d="M782.8,393.3c3-0.6,6-1,9-1.4c-1.8-0.3-3.6-0.6-5.3-0.9c2.3,0.5,4.6,1,6.9,1.5
								c-4-1.3-8.1-2.7-12.1-4c2.8,1.4,5.6,2.9,8.4,4.3c-5.3-3.7-10.6-7.3-15.9-11c3.3,3.3,6.6,6.7,9.9,10c-2.2-3.1-4.4-6.2-6.6-9.3
								c0.8,2.1,1.5,4.1,2.3,6.2c-0.5-1.4-1-2.8-1.5-4.2c0.3,1.1,0.3,2.2,0,3.4c-1,2.1-2,4.2-2.8,6.3c0.5-1.2,1.1-2.4,1.6-3.6
								c-0.3,0.3-0.7,0.6-1,0.8c-2.2,2.3-3.8,5-4.7,8c-1.3,2.9-1.8,6-1.6,9.2c1,2.2,2.7,3.7,5,4.5c2.3,1,4.5,1,6.7-0.1
								c3-1,5.9-2.4,8.6-4.1c1.2-0.9,2.2-2,3.1-3.1c1.3-1.6,2.6-3.2,3.8-4.9c2.8-4.9,3.9-10.3,3.4-16.2c-0.4-1.1-0.8-2.3-1.1-3.4
								c-2.2-2.2-4.6-3.9-7.4-5.2c-2.1-1.3-4.3-2.4-6.7-3.2c-2.7-1.4-5.6-2.2-8.7-2.6c-2,0-3.9,0.3-5.7,1c-2.3,0.3-4.5,0.8-6.7,1.4
								c-5.2,1.2-7.6,7.4-4.4,11.7c3.4,2.7,7.1,4.7,11.2,6C774.4,392.1,778.5,393.1,782.8,393.3z"/>
							<path opacity="0.1091" fill="#131313" d="M809.2,422c1.6-0.9,3.3-1.5,5.1-1.9c-4.7-0.5-9.5-0.9-14.2-1.4
								c4.5,1.1,9,2.2,13.5,3.2c-13.7-6.4-27.4-12.8-41.2-19.2c12.9,8.7,25.8,17.4,38.8,26.1c-10.5-9.8-20.9-19.6-31.4-29.4
								c1.5,14.5,6.6,27,15.3,37.4c2.4,1.4,4.9,1.5,7.4,0.2c2.9-2.3,4.3-5.4,4.1-9.3c-2.2-11.8,3.2-14,16.2-6.4
								c1.3-3.2,0.6-6-1.9-8.4c-4-3.5-8.3-6.6-12.8-9.2c-5.5-3.6-11.2-6.8-17.1-9.7c-4.2-3-8.8-4.2-13.7-3.6c-3.1,1-6.2,1.7-9.2,2.9
								c-2.1,0.8-4,1.8-5.9,2.9c-2.7,1.6-3.6,3.9-2.6,6.9c2.6,4.2,6.4,7,11.3,8.4c7.9,3.6,16.1,6.7,24.4,9.3
								C799.7,423,804.4,423.4,809.2,422z"/>
							<path opacity="0.1091" fill="#131313" d="M759.8,419.8c1.5-0.2,3-0.4,4.4-0.6c-5.7-3.1-11.4-6.2-17.2-9.3
								c-0.9,7.4-1.7,14.7-2.6,22.1c0.5,1.2,0.9,2.5,1.4,3.7c1.3,1.7,3,2.5,5,2.4c1.4,0.5,2.6,0.2,3.6-0.8c2.7-1.1,4.3-3.2,5-6.4
								c0,2.1,0,4.2,0,6.3c3-0.6,5.6-2,7.6-4.2c3.2-2.8,6.6-5.9,8.9-9.5c0.6-1,1.2-2.1,1.8-3.1c0.3-1.3,0.6-2.6,0.8-3.9
								c0.3-1.6,0.4-3.1,0.1-4.7c0.1-1.3-0.3-2.5-1.2-3.5c-2.1-2.5-4.7-4.5-7.8-5.8c-2.9-1.7-6-2.6-9.3-2.8c-2,0.7-4,1.1-6.1,1.3
								c-2.1,0.2-4.3,0.6-6.4,0.9c-2.2,1-3.6,2.7-4.4,5c-1,2.2-1,4.5-0.1,6.7c2.1,2.2,4.6,3.7,7.5,4.6
								C753.9,419.5,756.8,420,759.8,419.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M815.1,435.9c2.3,1.3,4.7,2.6,7,3.9c-1.5-1-3-1.9-4.5-2.9c0.5,0.6,0.9,1.1,1.4,1.7
								c-6.1-11.1-12.2-22.3-18.3-33.4c6,13.4,11.9,26.8,17.9,40.2c-3.5-11.9-7-23.7-10.4-35.6c2.1,9.6,4.2,19.1,6.3,28.7
								c-1.4-9-2.8-17.9-4.2-26.9c0.9,12.6,2.5,25.2,4.9,37.6c0.9,3.5,3.1,5.4,6.5,5.7c1.2-0.5,2.4-1.1,3.6-1.6
								c2.9-4.1,4-8.7,3.3-13.9c0.2-6.6,0-13.3-0.6-19.9c-0.2-5.3-0.9-10.6-2.1-15.8c-5-6.4-11-11.4-18-14.9c-3-1.5-6.2-2.6-9.5-3
								c-3.2-0.6-5.3,0.6-6.4,3.6c-0.6,3.6,0,6.9,1.9,9.9c2.2,5.9,5,11.5,8.3,16.9C805.8,423.1,810.1,429.7,815.1,435.9z"/>
							<path opacity="0.1091" fill="#131313" d="M792.2,417.9c1.7,0.3,3.1,1.2,4,2.6c-1.3-1.7-2.6-3.4-3.9-5.1c0.3,0.5,0.7,1,1,1.5
								c-1.3-2.7-2.6-5.5-3.9-8.2c1.4,3.4,2.8,6.7,4.3,10.1c-1.1-2.9-2.2-5.8-3.3-8.7c1.1,3.5,2.2,7,3.3,10.5
								c-1.4-6.1-2.8-12.2-4.2-18.3c1.2,6.8,2.4,13.6,3.6,20.4c-0.8-6.4-1.6-12.8-2.5-19.1c-0.1,8.1,0.6,16.1,2.2,24
								c0,2.7,1.1,5,3.3,6.7c1.9,1.3,3.7,1.4,5.5,0.5c2.2-0.3,3.6-1.6,4.4-3.9c0.4-2.3,0.8-4.6,1.2-6.9c0.5-6.5,0.4-13-0.2-19.5
								c-5.3-6-10.5-12.1-15.8-18.1c-2.1-0.9-4.2-1.6-6.4-2.1c-2.8,0.1-4.9,1.3-6.4,3.6c-1.1,2.3-1.1,4.6,0.2,6.8
								c1.1,3.8,2.7,7.4,4.9,10.8C785.7,410.2,788.6,414.3,792.2,417.9z"/>
							<path opacity="0.1091" fill="#131313" d="M792.4,381.6c2,1.6,4,3.2,6.1,4.8c-0.8-0.7-1.7-1.4-2.5-2c0.9,1,1.8,2,2.8,3
								c-1.3-2.6-2.5-5.3-3.8-7.9c0.9,2.6,1.7,5.1,2.6,7.7c-0.3-2.6-0.5-5.2-0.8-7.8c-0.4,2.7-0.7,5.3-1.1,8
								c0.3-1.1,0.5-2.3,0.8-3.4c-0.6,1.9-1.2,3.8-1.8,5.6c-0.5,3.1-0.4,6.2,0.3,9.3c0.1,2,1,3.4,2.6,4c1.5,1.1,3.1,1.4,5,1.2
								c2.4,0.1,4.5-0.7,6.2-2.3c2.1-3.7,4.1-7.4,4.6-11.7c0.3-3,0.1-5.8-0.8-8.7c-0.9-2.9-2.1-6.1-4.1-8.4c-2.7-3.2-6.2-5.6-9.6-8
								c-2.3-0.3-4.4,0.1-6.4,1.4c-1.7,0.7-3,1.9-3.8,3.5c-1.1,1.2-1.3,2.7-0.4,4.4C789.1,377,790.5,379.4,792.4,381.6z"/>
							<path opacity="0.1091" fill="#131313" d="M813.1,410c1.7,0.4,3.1,1.2,4,2.7c-1.4-1.8-2.7-3.6-4.1-5.3
								c0.4,0.6,0.7,1.2,1.1,1.8c-2.4-5.7-4.8-11.4-7.3-17.1c2.6,7.1,5.2,14.2,7.8,21.3c-1.4-5.8-2.7-11.7-4.1-17.5
								c1.2,6.6,2.5,13.2,3.7,19.8c-0.8-5.9-1.5-11.8-2.3-17.6c-0.2,8.6,0.8,17.1,2.8,25.4c1.3,2.6,3.4,3.9,6.3,3.9
								c1.2-0.3,2.5-0.7,3.7-1c2.8-2.2,4.1-5.2,3.8-9c0.5-4.3,0.5-8.7,0.1-13c0.2-9.2-3.7-17.1-11.7-23.7c-3.8-3.1-8-4.8-12.6-6.2
								c-2.8,0-5,1.2-6.4,3.6c-1.1,2.5-0.9,4.9,0.4,7.2c1.2,4.1,3,8,5.4,11.6C806.2,401.7,809.3,406.1,813.1,410z"/>
							<path opacity="0.1091" fill="#131313" d="M810.3,377.4c2.2,1.9,4.5,3.9,6.7,5.8c-0.9-0.9-1.9-1.7-2.8-2.6c1,1.2,2,2.3,3,3.5
								c-0.9-1.5-1.8-3-2.6-4.5c0.4,0.8,0.8,1.6,1.2,2.3c-0.5-1.6-1-3.2-1.6-4.8c0.8,2.4,1.5,4.9,2.3,7.3c-0.4-1.6-0.8-3.2-1.1-4.8
								c0,0.9,0,1.8-0.1,2.7c0.1-1.2,0.3-2.4,0.4-3.5c-0.3,2.1-0.6,4.2-0.9,6.2c0.3-1.1,0.5-2.3,0.8-3.4c-0.6,2-1.2,4-1.8,6
								c-0.5,3-0.4,6,0.3,9c0.1,2,1,3.3,2.6,3.8c1.5,1,3.1,1.4,5,1.1c2.4,0.1,4.4-0.6,6.2-2.2c1.4-1.7,2.4-3.5,3-5.6
								c0.8-1.7,1.2-3.6,1.2-5.5c0-1.2,0.1-2.5,0.1-3.7c0.2-2.2-0.1-4.2-1-6.2c-1.1-3.4-2.8-7.4-5-10.1c-3-3.7-6.9-6.7-10.6-9.6
								c-2.3-0.6-4.5-0.2-6.6,1.1c-1.8,0.8-3,2-3.6,3.7c-1.1,1.5-1.1,3.2,0,5.1C806.5,371.8,808.1,374.7,810.3,377.4z"/>
							<path opacity="0.1091" fill="#131313" d="M777.1,404.6c1.3,1.1,2.6,2.3,3.8,3.4c-1-1-2-1.9-3-2.9c1,1.2,2,2.4,3,3.5
								c-1-1.7-1.9-3.4-2.9-5.1c1.1,2.1,2.3,4.1,3.4,6.2c-1.5-3.8-3-7.5-4.5-11.3c0.7,2.2,1.4,4.5,2.2,6.7c-0.5-2.1-1-4.1-1.5-6.2
								c0.3,5.5,0.7,11,1,16.5c0.4,1.7,0.8,3.4,1.2,5.1c1,1.8,2.4,2.8,4.4,3c1.2,0.6,2.4,0.6,3.7,0.1c1.9-0.1,3.4-1,4.5-2.8
								c0.5-1.1,1.1-2.2,1.6-3.2c0.9-1.5,1.4-3.1,1.6-4.9c0.8-4.1-0.1-8.4-1.4-12.3c-0.8-2.6-2-5.3-3.4-7.6
								c-1.4-2.3-3.2-4.2-5.4-5.6c-2.1-1.8-4.3-3.4-6.5-5c-2.3-0.7-4.6-0.4-6.7,0.9c-2.2,1.1-3.7,2.8-4.2,5.2c0.3,3.2,1.4,6.2,3.1,9
								C772.5,400.2,774.5,402.6,777.1,404.6z"/>
							<path opacity="0.1091" fill="#131313" d="M803.3,440.6c-0.1,2.6-0.3,5.3-0.4,7.9c-3.3-11.3-6.5-22.6-9.8-34
								c1.9,9.2,3.8,18.4,5.7,27.6c-1.3-8.8-2.6-17.6-3.9-26.3c0.5,10.9,1.8,21.8,3.7,32.5c0,3.5,1.2,6.5,3.5,9
								c1.9,1.7,3.8,1.9,5.5,0.5c2.5-0.7,3.9-2.5,4.2-5.4c0.3-3.1,0.6-6.2,0.9-9.4c0.2-8.9-0.1-17.8-1-26.6
								c-0.5-3.1-1.1-6.2-1.6-9.3c-7.1-7.9-15.4-12.5-24.9-13.8c-3-0.4-5.2,0.8-6.4,3.6c-0.9,4.6,0.1,8.9,2.9,13
								c3.5,7.4,7.5,14.5,12,21.4C795.7,435.9,798.9,438.9,803.3,440.6z"/>
							<path opacity="0.1091" fill="#131313" d="M826.8,406.2c1.7,0.4,3.1,1.3,4.1,2.8c-3-4.8-6.1-9.6-9.1-14.4
								c2.2,4.2,4.4,8.5,6.6,12.7c-1.1-2.9-2.3-5.8-3.4-8.8c1.2,3.7,2.4,7.4,3.5,11.1c-0.8-2.9-1.7-5.7-2.5-8.6
								c0.7,3.7,1.5,7.4,2.2,11.1c-0.5-2.8-1-5.7-1.5-8.5c0,5.5,0.7,11,2.1,16.3c0.9,0.9,1.7,1.8,2.6,2.8c2.5,1.2,4.9,1.2,7.4,0
								c2.1-1.3,3.2-3.2,3.2-5.6c0.9-3.6,1.3-7.3,1-11.1c0.9-8-1.9-14.9-8.3-20.5c-1.7-6.8-5.3-11.5-10.9-14.2
								c-2.2-1.1-4.6-2.1-6.9-2.9c-2.9-0.1-5,1.1-6.4,3.6c-1,2.7-0.8,5.2,0.6,7.6c1.4,4.4,3.3,8.5,5.8,12.4
								C819.6,397.2,822.9,401.9,826.8,406.2z"/>
							<path opacity="0.1091" fill="#131313" d="M786.5,394.8c1.2,0.9,2.3,1.9,3.5,2.8c-1-0.9-1.9-1.8-2.9-2.7
								c1.1,1.4,2.1,2.8,3.2,4.2c-1.4-3.4-2.7-6.8-4.1-10.2c0.7,2.1,1.3,4.2,2,6.3c-0.4-1.9-0.9-3.7-1.3-5.6c0.4,2.3,0.8,4.6,1.2,7
								c-0.3-1.9-0.5-3.9-0.8-5.8c0,2.8,0,5.6,0,8.4c-0.3,2.9,0,5.7,0.8,8.4c0.1,2,1.1,3.5,3.1,4.4c1.2,0.3,2.4,0.5,3.7,0.8
								c2.6,0.2,4.7-0.7,6.3-2.7c0.9-2.3,1.9-4.5,2.8-6.8c0.6-3.4,0.5-6.7-0.4-10c-0.5-3.4-1.7-6.5-3.7-9.3
								c-1.8-2.6-3.8-4.9-6.2-6.9c-1.5-1.5-3.2-2.8-5.1-3.9c-2.3-0.6-4.5-0.3-6.6,1c-2.2,1.1-3.6,2.8-4.3,5.1
								c0.2,3.1,1.2,5.9,2.9,8.5C782.1,390.7,784,393,786.5,394.8z"/>
							<path opacity="0.1091" fill="#131313" d="M804,392c1.2,0.9,2.3,1.9,3.5,2.8c-1.1-1.1-2.1-2.2-3.2-3.2c0.9,1,1.8,2,2.7,3
								c-1-1.8-2.1-3.6-3.1-5.5c1.3,2.3,2.5,4.6,3.8,6.8c-0.9-1.9-1.8-3.7-2.8-5.6c1,2.4,1.9,4.8,2.9,7.2c-1.3-4-2.5-7.9-3.8-11.9
								c0.6,2.7,1.2,5.4,1.8,8.1c-0.3-2-0.6-4.1-0.9-6.1c0.2,2.7,0.5,5.5,0.7,8.2c-0.1-2.1-0.2-4.1-0.2-6.2
								c-0.3,4.8-0.2,9.5,0.2,14.2c-0.1,3,1.2,5.1,3.7,6.4c2.4,1,4.9,1,7.4,0.1c2.6-1.2,3.9-3.3,3.9-6.3c1.6-4.7,1.9-9.3,1-14
								c-1.1-4.1-2.6-7.9-4.7-11.5c-3-4.6-7.7-8-12.2-11.1c-2.4-0.9-4.6-0.7-6.8,0.7c-2.3,1.2-3.7,3-4.1,5.5
								c0.5,3.6,1.7,6.9,3.7,9.9C799,386.9,801.2,389.7,804,392z"/>
							<path opacity="0.1091" fill="#131313" d="M829,424.1c0.8,0.4,1.6,0.7,2.4,1.1c-1.4-0.8-2.9-1.7-4.3-2.5
								c0.5,0.5,0.9,1,1.4,1.5c-1.7-2.4-3.4-4.8-5.1-7.3c1.9,3.1,3.8,6.2,5.7,9.2c-4.8-10-9.5-19.9-14.3-29.9
								c3.5,8.9,6.9,17.8,10.4,26.7c-1.1-3.7-2.1-7.4-3.2-11.1c1,4.5,1.9,8.9,2.9,13.4c-1.1-7.4-2.2-14.9-3.2-22.3
								c0.5,10.6,1.8,21.1,4,31.5c1.1,3,3.3,4.5,6.4,4.7c1.2-0.4,2.4-0.9,3.6-1.3c2.9-3.1,4-6.9,3.5-11.3c0.3-5.4,0.3-10.8-0.2-16.1
								c-0.1-4.4-0.7-8.7-1.8-12.9c-5.6-8.3-12.6-14.8-20.9-19.5c-1.9-1-3.9-1.7-6-2.1c-3.1-0.4-5.2,0.8-6.4,3.6
								c-0.8,4.7,0.2,9.1,3.1,13.2c3.6,7.6,7.8,14.9,12.4,21.9C821.2,419.2,824.5,422.4,829,424.1z"/>
							<path opacity="0.1091" fill="#131313" d="M779.5,418.3c1.8,2.7,3.6,5.3,5.4,8c-2.7-7-5.4-14-8.1-20.9
								c1.4,6.5,2.7,13,4.1,19.4c-0.7-6-1.5-12-2.2-18c-0.4,7.6,0.2,15.2,1.8,22.6c0,2.7,1,4.8,3.2,6.5c1.2,0.3,2.5,0.7,3.7,1
								c2.3,0.1,4-0.8,5-2.7c1.4-1.3,2.1-2.9,1.9-4.9c0.9-4.2,1.3-8.4,1-12.7c-0.1-3.1-0.3-6.3-0.4-9.4c-3.6-8.3-9.1-14-16.7-17.1
								c-2.4-1.3-4.7-1.2-7.1,0.2c-2.4,1.3-3.7,3.3-3.8,6c0.9,4.2,2.4,8.1,4.7,11.8C773.8,412,776.3,415.4,779.5,418.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M824.8,431.8c8.1,8.7,16.1,17.5,24,26.3c2.4,2.1,4.9,2.2,7.4,0.2
								c3.2-4.1,4.6-8.8,4.2-14.1c0.8-9.1,1-18.2,0.7-27.3c0.6-5.3-0.5-10.1-3.5-14.3c-7.4-8.6-15-17.1-22.9-25.3
								c-2.4-2.1-4.8-2.2-7.4-0.3c-3.2,3.9-4.7,8.5-4.5,13.8c-1,8.9-1.4,17.9-1.2,26.9C820.9,422.9,821.9,427.6,824.8,431.8z"/>
							<path opacity="0.1091" fill="#131313" d="M802.5,412.1c8,8.8,15.9,17.6,23.9,26.4c2.4,1.6,4.9,1.7,7.4,0.2
								c3-2.8,4.4-6.3,4.1-10.6c0.7-6.9,0.9-13.8,0.5-20.7c0.5-4.3-0.7-7.9-3.5-10.8c-7.6-8.6-15.3-17.1-23-25.6
								c-2.4-1.6-4.8-1.8-7.4-0.4c-3.1,2.7-4.5,6.1-4.3,10.4c-0.9,6.8-1.2,13.5-1,20.3C798.6,405.6,799.7,409.2,802.5,412.1z"/>
							<path opacity="0.1091" fill="#131313" d="M802.9,375.4c7.3,10.4,15.4,20.2,24.3,29.3c2.7,3,9.6,3.7,12.5,0.6
								c1.4-2.5,2.1-5.2,2.1-8.1c0.3-2.9-0.1-5.7-1.3-8.3c-8.7-8.9-16.8-18.3-24.3-28.2c-1.7-1.6-3.8-2.4-6.2-2.3
								c-2.4-0.3-4.5,0.3-6.4,1.7c-1.4,2.3-2.1,4.8-2,7.5C801.4,370.4,801.8,373,802.9,375.4z"/>
							<path opacity="0.1091" fill="#131313" d="M823.6,404.9c8.1,8.6,16,17.3,23.9,26c2.4,1.4,4.9,1.4,7.4,0.2c2.9-2.2,4.2-5.2,4-9
								c0.7-5.9,0.8-11.7,0.4-17.6c0.4-3.8-0.8-6.9-3.6-9.2c-7.6-8.5-15.3-17-23.1-25.3c-2.4-1.4-4.8-1.5-7.4-0.3
								c-2.9,2.1-4.3,5-4.2,8.8c-0.8,5.8-1.1,11.5-0.8,17.3C819.8,399.5,820.9,402.6,823.6,404.9z"/>
							<path opacity="0.1091" fill="#131313" d="M822,372.5c7.3,10.5,15.4,20.3,24.3,29.5c1.7,1.6,3.8,2.3,6.2,2.3
								c2.4,0.3,4.5-0.3,6.3-1.7c1.4-2.3,2.1-4.7,2-7.4c0.3-2.7-0.1-5.2-1.3-7.6c-8.7-8.9-16.8-18.3-24.3-28.2
								c-1.8-1.5-3.8-2.2-6.2-2.1c-2.3-0.3-4.4,0.2-6.3,1.5c-1.4,2-2,4.3-2,6.8C820.4,368,820.8,370.3,822,372.5z"/>
							<path opacity="0.1091" fill="#131313" d="M785.5,397.5c4.2,5.1,8.5,10.1,13,15c4.4,4.9,8.9,9.7,13.3,14.5
								c2.4,1.3,4.9,1.3,7.4,0.2c2.8-1.9,4.2-4.6,4-8.1c0.7-5.3,0.8-10.5,0.4-15.8c0.3-3.5-0.9-6.3-3.6-8.3c-4-4.3-7.9-8.7-11.9-13
								c0.8,0.9,1.7,1.8,2.5,2.7c-3.8-4.3-7.5-8.6-11.3-12.9c-1.3-2.4-3.3-3.7-6.1-3.8c-2.8-0.2-4.9,0.9-6.4,3.2
								c-1.5,4.2-2.3,8.6-2.3,13.1C784.1,388.7,784.4,393.1,785.5,397.5z"/>
							<path opacity="0.1091" fill="#131313" d="M808.8,435.9c8.1,8.7,16.2,17.5,24.1,26.4c2.4,2.2,4.9,2.3,7.4,0.2
								c3.2-4.2,4.6-9.1,4.2-14.6c0.8-9.4,1-18.8,0.7-28.3c0.7-5.5-0.5-10.4-3.4-14.8c-7.4-8.6-15-17.1-22.9-25.3
								c-2.4-2.2-4.8-2.3-7.4-0.3c-3.3,4.1-4.8,8.9-4.5,14.3c-1,9.3-1.4,18.5-1.3,27.9C804.9,426.6,806,431.5,808.8,435.9z"/>
							<path opacity="0.1091" fill="#131313" d="M837.6,401c7.9,9,15.7,17.6,23.8,26.5c2.4,1.3,4.9,1.3,7.4,0.2
								c2.8-1.9,4.2-4.7,4-8.3c0.7-5.4,0.8-10.8,0.4-16.2c0.3-3.6-0.9-6.4-3.6-8.5c-4-4.3-7.9-8.7-11.9-13c0.8,0.9,1.7,1.9,2.5,2.8
								c-4.6-5.2-9.2-10.3-13.7-15.5c-2.4-1.3-4.9-1.4-7.4-0.3c-2.9,1.8-4.2,4.5-4.2,8.1c-0.8,5.3-1,10.6-0.7,15.9
								C833.7,396.2,834.9,399,837.6,401z"/>
							<path opacity="0.1091" fill="#131313" d="M794.3,382.9c6,11.8,13.8,22.1,23.5,30.9c1.5,2.1,3.6,3.1,6.2,3.1
								c2.6,0.2,4.7-0.8,6.3-2.8c1.4-3.6,2-7.3,1.9-11.1c0.3-3.8-0.2-7.6-1.4-11.2c-9.3-8.5-17-18.2-23.1-29.2
								c-2.6-3.9-9.5-4.3-12.5-0.6c-1.5,3.3-2.2,6.7-2.2,10.3C792.8,376,793.2,379.5,794.3,382.9z"/>
							<path opacity="0.1091" fill="#131313" d="M812.4,382.9c6.5,11,14.3,20.9,23.6,29.7c1.5,2,3.6,3,6.2,3
								c2.6,0.2,4.7-0.7,6.3-2.7c1.4-3.4,2-6.9,1.9-10.6c0.3-3.7-0.2-7.2-1.4-10.6c-8.9-8.4-16.6-17.8-23.2-28.1
								c-2.7-3.7-9.5-4.2-12.5-0.6c-1.4,3.1-2.2,6.4-2.1,9.9C810.8,376.3,811.2,379.7,812.4,382.9z"/>
							<path opacity="0.1091" fill="#131313" d="M835,416.2c8,8.8,16,17.6,23.9,26.4c2.4,1.7,4.9,1.7,7.4,0.2c3-3,4.4-6.7,4.1-11.1
								c0.7-7.2,0.9-14.4,0.5-21.6c0.5-4.4-0.7-8.2-3.6-11.3c-7.6-8.6-15.2-17.1-23-25.5c-2.4-1.7-4.8-1.8-7.4-0.3
								c-3.1,2.9-4.5,6.5-4.3,10.9c-0.9,7.1-1.2,14.1-1,21.3C831.1,409.4,832.2,413.1,835,416.2z"/>
							<path opacity="0.1091" fill="#131313" d="M789.9,413.6c8.1,8.7,16,17.4,23.9,26.2c2.4,1.6,4.9,1.7,7.4,0.2
								c3-2.7,4.4-6.2,4.1-10.5c0.7-6.8,0.9-13.5,0.5-20.3c0.5-4.2-0.7-7.8-3.5-10.7c-7.6-8.6-15.3-17-23-25.4
								c-2.4-1.6-4.8-1.7-7.4-0.4c-3,2.6-4.5,6-4.3,10.2c-0.9,6.6-1.2,13.3-1,20C786,407.2,787.1,410.7,789.9,413.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M874.2,426.8c1,0.3,1.9,0.6,2.9,0.9c-1.6-0.8-3.2-1.6-4.8-2.5
								C873,425.8,873.6,426.3,874.2,426.8c-7.1-10.4-14.2-20.8-21.3-31.2c7.7,13.7,15.4,27.4,23.1,41.2
								c-3.9-11.8-7.8-23.5-11.7-35.3c3.2,13.5,6.4,26.9,9.6,40.4c-1.6-12-3.2-23.9-4.9-35.9c0.6,9.6,1.2,19.2,1.9,28.8
								c0-8.6,0-17.2,0-25.8c-0.4,9.3-0.7,18.6-1.1,27.9c0.9-8.3,1.8-16.6,2.7-25c-1.3,9-2.6,18-4,26.9c-0.2,2.9-0.5,5.7-0.7,8.6
								c0.5,1.5,1.1,3.1,1.6,4.6c1.1,0.7,2.2,1.4,3.3,2.2c1.3-0.2,2.6-0.3,3.9-0.5c1.1-1.1,2.3-2.3,3.4-3.4c1-2.7,2.1-5.4,3.1-8
								c1.8-5.8,3.2-11.7,4.3-17.7c0.9-3.4,1.4-6.8,1.5-10.3c1.5-6.9-1.5-12.6-8.8-17c-2-6.5-5.7-11.2-11.2-13.8
								c-1.4-0.8-2.8-1.6-4.2-2.2c-2.6-1.2-5.3-2.4-8.1-3.3c-2.8-0.9-5.4-1.4-8.4-1.7c-3.2-0.3-5.2,1.1-6.1,4.2
								c-0.1,5.2,1.6,9.9,5,14c4.8,7.9,10,15.5,15.8,22.8C865.5,421.9,869.3,425.1,874.2,426.8z"/>
							<path opacity="0.1091" fill="#131313" d="M846.5,406.7c1,0.3,1.9,0.5,2.9,0.8c-1.5-1.4-2.9-2.9-4.4-4.3
								c2.9,3.3,5.8,6.6,8.6,9.9c-2.6-4.4-5.3-8.8-7.9-13.1c1.6,3.4,3.3,6.7,4.9,10.1c-1.2-2.8-2.4-5.6-3.5-8.4
								c1.3,3.6,2.5,7.2,3.8,10.7c-0.9-3-1.9-5.9-2.8-8.9c0.9,3.7,1.8,7.5,2.7,11.2c-1.1-6.2-2.2-12.4-3.3-18.6c0.9,7,1.8,14,2.6,21
								c-0.5-6.4-1-12.9-1.5-19.3c0.2,7.1,0.4,14.3,0.6,21.4c0.2-6.5,0.3-12.9,0.5-19.4c-0.6,7.2-1.2,14.4-1.8,21.6
								c0.8-6.5,1.7-13.1,2.5-19.6c-2.3,8.2-3.7,16.5-4.1,25c-0.7,2.7-0.3,5.2,1.4,7.5c1.5,1.7,3.2,2.4,5.2,1.9
								c2.2,0.3,4-0.7,5.3-2.8c1-2.2,1.9-4.3,2.9-6.5c2.2-6.4,3.8-12.8,4.9-19.5c-7.5-10.4-15.1-20.8-22.6-31.3
								c-2.3-0.7-4.5-1.2-6.9-1.5c-2.8,0.3-4.8,1.7-6.1,4.2c-0.9,2.4-0.7,4.6,0.7,6.7c1.4,3.6,3.3,7,5.7,10.1
								C839.5,399.8,842.7,403.5,846.5,406.7z"/>
							<path opacity="0.1091" fill="#131313" d="M849.2,372.4c1.4,0.8,2.7,1.7,4.1,2.5c-0.9-0.6-1.8-1.2-2.8-1.8
								c1.5,1.5,3,3.1,4.6,4.6c-0.8-1.2-1.7-2.5-2.5-3.7c0.3,0.7,0.7,1.3,1,2c-0.5-1.4-1-2.8-1.5-4.3c0.6,2.3,1.3,4.6,1.9,6.8
								c-0.4-1.4-0.8-2.9-1.2-4.3c0.4,2.9,0.4,5-0.1,7.9c0.3-1.1,0.6-2.2,1-3.3c-1,2.7-2,5.3-3.1,8c0.5-1.1,1.1-2.1,1.6-3.2
								c-1.1,1.8-2.1,3.5-3.2,5.3c-1.3,3.1-2,6.2-2.2,9.5c-0.5,2.1,0,3.6,1.5,4.7c1.1,1.4,2.6,2.3,4.5,2.5c2.3,0.7,4.5,0.5,6.6-0.7
								c4.2-4.8,7.9-9.5,9.4-15.8c0.9-3.8,1.4-7.8,1.1-11.7c-0.4-5.4-2.7-10.4-6-14.7c-3.3-4.2-8.2-6.8-12.9-9.3
								c-2.3-0.1-4.4,0.5-6.2,2c-2,1.2-3.4,2.9-4,5.2c0.2,2.6,1.2,4.9,2.8,6.9C845,369.6,846.8,371.3,849.2,372.4z"/>
							<path opacity="0.1091" fill="#131313" d="M867.2,398.7c2.3,0.7,4.6,1.4,6.9,2.1c-1.3-0.4-2.6-0.8-4-1.2
								c0.7,0.5,1.4,1,2.1,1.5c-1.5-1.7-3.1-3.3-4.6-5c2.3,3.1,4.6,6.2,6.9,9.3c-2.8-4.6-5.5-9.1-8.3-13.7c1.7,3.5,3.5,7.1,5.2,10.6
								c-2.1-5.7-4.3-11.4-6.4-17.2c2.3,7.5,4.5,14.9,6.8,22.4c-0.6-2.9-1.3-5.8-1.9-8.7c0.5,3.8,1,7.5,1.5,11.3
								c-0.4-5.8-0.9-11.6-1.3-17.4c0.1,3.8,0.1,7.7,0.2,11.5c0.2-2.8,0.4-5.6,0.6-8.4c-0.4,3.7-0.7,7.4-1.1,11.1
								c0.5-2.7,1-5.4,1.5-8.1c-2.5,8-3.8,16.1-3.8,24.3c0.7,2.7,2.4,4.4,5.1,5.1c2.7,0.7,5,0.1,7-1.9c1.8-2.8,3.1-5.8,3.9-9
								c1.4-3.8,2.3-7.7,2.9-11.8c0.2-17-5.6-29.2-17.4-36.8c-4.3-2.7-9-4.1-14-5.2c-2.8,0.2-4.9,1.6-6.1,4.2
								c-0.8,2.6-0.5,5,1.1,7.2c1.6,4,3.8,7.8,6.5,11.2C859.6,391,863.1,395.1,867.2,398.7z"/>
							<path opacity="0.1091" fill="#131313" d="M867.5,369.1c1.5,1,2.9,2,4.4,3c-1.1-0.8-2.1-1.7-3.2-2.5c1.2,1.1,2.4,2.1,3.6,3.2
								c-1.1-1.5-2.1-3-3.2-4.5c1.6,2.3,3.2,4.5,4.8,6.8c-1-1.5-1.9-3-2.9-4.5c1.3,2.4,2.7,4.7,4,7.1c-1.4-3.2-2.9-6.4-4.3-9.5
								c0.7,2.6,1.5,5.1,2.2,7.7c-0.5-1.6-0.9-3.2-1.4-4.9c0.5,2.6,1,5.2,1.5,7.7c-0.3-1.6-0.6-3.2-0.9-4.8c0.1,2,0,4-0.4,5.9
								c0.3-1.1,0.6-2.2,1-3.3c-0.6,2-1.2,4-1.9,6c0.4-1,0.9-2,1.3-3.1c-0.8,1.9-1.7,3.8-2.5,5.7c0.5-0.9,1.1-1.9,1.6-2.8
								c-1.1,1.7-2.2,3.5-3.3,5.2c-1.2,2.6-1.8,5.3-1.9,8.2c-0.3,1.9,0.2,3.2,1.6,4.1c1.2,1.3,2.7,2.1,4.6,2.3
								c2.2,0.7,4.4,0.5,6.5-0.4c4-4.4,7.6-9,9.2-14.9c1-3.8,1.6-7.7,1.1-11.7c-0.6-3.4-1.6-6.6-3.1-9.7c-1-2.5-2.3-4.8-4-7
								c-3.9-5-9.3-8.4-14.7-11.6c-2.4-0.5-4.6,0-6.5,1.6c-2.1,1.3-3.4,3.2-3.7,5.6c0.6,3.1,1.9,6,3.9,8.5
								C862.6,365.2,864.8,367.4,867.5,369.1z"/>
							<path opacity="0.1091" fill="#131313" d="M831,393c1.5,1,3,2.1,4.5,3.1c-1-0.8-2.1-1.6-3.1-2.4c1.2,1.1,2.5,2.2,3.7,3.3
								c-1.1-1.5-2.2-3-3.3-4.6c1.8,2.7,3.5,5.4,5.3,8c-1.6-3.7-3.3-7.4-4.9-11c0.9,2.9,1.9,5.7,2.8,8.6c-0.4-2.1-0.8-4.3-1.2-6.4
								c0.4,2.7,0.8,5.4,1.3,8c-0.2-2.3-0.4-4.5-0.7-6.8c-0.4,3.9-0.8,7.8-1.1,11.8c0.5-2.5,1-5.1,1.5-7.6c-1,4.3-1.9,8.5-2.9,12.8
								c-0.7,2.3-1.1,4.6-1,6.9c-0.6,2.3-0.1,4.4,1.6,6.3c1.1,0.6,2.2,1.2,3.3,1.9c1.3,0,2.5,0.1,3.8,0.1c2.4-0.7,3.9-2.2,4.6-4.6
								c1.2-2,2.1-4.2,2.6-6.5c1.5-5.9,1.9-5.1,1.1,2.2c0.6-1.2,1.1-2.3,1.7-3.5c0.7-2.4,1.3-4.9,2-7.3c0.7-4.7-0.7-9.8-2.2-14.3
								c-0.6-2.6-1.7-5-3.4-7.2c-1.3-1.4-2.6-2.7-3.9-4.1c-3.5-3.3-7.6-5.8-11.8-8.3c-2.4-0.4-4.5,0.1-6.5,1.6
								c-2.1,1.3-3.4,3.1-3.8,5.5c0.5,3,1.7,5.8,3.7,8.2C826.2,389.3,828.3,391.4,831,393z"/>
							<path opacity="0.1091" fill="#131313" d="M857.6,428.6c1,0.3,2,0.6,2.9,1c-7.9-9.4-15.7-18.7-23.6-28.1
								c7.8,12.8,15.6,25.6,23.3,38.3c-2.6-6.9-5.2-13.8-7.7-20.7c1.3,4.9,2.6,9.9,4,14.8c-1.7-8.6-3.3-17.1-5-25.7
								c1.5,9.4,2.9,18.8,4.4,28.2c-0.9-8.8-1.7-17.6-2.6-26.5c0.6,9.5,1.1,19.1,1.7,28.6c0-8.8,0.1-17.5,0.1-26.3
								c-0.5,9.5-1,19-1.4,28.4c0.9-8.7,1.9-17.5,2.8-26.2c-2.5,12.3-4.2,24.8-5.1,37.3c0,3.6,1.6,5.9,4.8,7.1
								c3.4,0.3,5.9-1.2,7.4-4.4c2.9-6.9,5.1-14,6.5-21.4c1.9-7.3,3-14.6,3.3-22.1c-5.2-12.2-13.3-20.1-24.4-23.5
								c-4.3-1.5-8.5-2.5-13-3c-3-0.1-5.1,1.3-6.1,4.2c-0.5,4.6,0.8,8.7,3.9,12.3c4,6.8,8.5,13.3,13.5,19.5
								C849.8,424.8,853.1,427.5,857.6,428.6z"/>
							<path opacity="0.1091" fill="#131313" d="M885.1,396.4c1,0.3,2,0.7,3,1c-1.4-0.5-2.8-1-4.1-1.5c0.7,0.5,1.4,1,2,1.5
								c-1.6-1.8-3.3-3.7-4.9-5.5c2.4,3.2,4.8,6.5,7.2,9.7c-2.9-4.8-5.8-9.6-8.6-14.4c1.8,3.7,3.7,7.5,5.5,11.2
								c-1.2-2.9-2.5-5.8-3.7-8.7c1.4,3.8,2.9,7.6,4.3,11.3c-0.9-2.9-1.9-5.8-2.8-8.7c0.9,3.9,1.9,7.7,2.8,11.6
								c-0.6-2.8-1.3-5.7-1.9-8.5c0.5,3.8,1.1,7.6,1.6,11.4c-0.4-5.6-0.8-11.1-1.3-16.7c0,3.9,0,7.8,0.1,11.8
								c0.2-2.6,0.4-5.2,0.6-7.8c-0.4,3.7-0.7,7.5-1.1,11.2c0.5-2.5,1-4.9,1.4-7.4c-2.5,7.3-3.7,14.8-3.6,22.4
								c0.9,2.5,2.6,4.1,5.2,4.8c1.3,0.1,2.5,0.1,3.8,0.2c3-0.9,4.9-2.9,5.6-6.2c1.4-3.3,2.4-6.8,3-10.3
								c4.1-16.2-1.7-31.4-17.6-45.6c-4.5-3.1-9.5-4.9-14.8-6.2c-2.9,0.1-4.9,1.5-6,4.2c-0.7,4.1,0.3,7.8,3.1,11.1
								c3.5,6.1,7.5,11.8,12,17.2C877.9,393.4,881.1,395.6,885.1,396.4z"/>
							<path opacity="0.1091" fill="#131313" d="M840.7,383.4c1.4,0.8,2.7,1.6,4.1,2.5c-1-0.8-2-1.6-3.1-2.3
								c2.1,1.8,4.2,3.5,6.3,5.3c-1-0.8-2-1.7-3-2.5c0.4,0.5,0.8,1,1.2,1.5c-1-1.5-1.9-3-2.9-4.6c1.2,2.1,2.4,4.3,3.6,6.4
								c-1.5-3.4-3-6.8-4.5-10.1c0.9,3,1.8,5.9,2.7,8.9c-0.3-1.9-0.7-3.8-1-5.7c0.3,2.6,0.6,5.1,1,7.7c-0.2-2-0.3-3.9-0.5-5.9
								c0,2.6,0,5.3,0,7.9c0-2,0-4.1,0.1-6.1c-0.3,2.6-0.5,5.3-0.8,7.9c0.2-2.1,0.5-4.1,0.7-6.2c-0.8,3.1-1.6,6.3-2.5,9.4
								c-1.1,2.9-1.6,5.9-1.5,9c-0.5,2.1,0.1,3.9,1.8,5.3c1.1,0.6,2.2,1.2,3.3,1.7c2.5,0.8,4.8,0.4,6.8-1.2c3-3,5.1-6.6,6.5-10.5
								c1.4-2.5,2.1-5.1,2.1-7.9c0.5-4.4,0.3-8.3-1.4-12.6c-0.8-1.7-1.5-3.4-2.3-5.2c-1.6-2.3-3.4-4.5-5.2-6.6
								c-3.3-3.1-7.2-5.3-11.2-7.4c-2.4-0.4-4.5,0.2-6.5,1.7c-2.1,1.3-3.4,3.1-3.8,5.5c0.5,3,1.7,5.7,3.6,8.1
								C836,379.8,838.1,381.9,840.7,383.4z"/>
							<path opacity="0.1091" fill="#131313" d="M856.9,379c1.5,1.1,3.1,2.2,4.6,3.3c-1.2-1-2.3-2-3.5-3c2.4,2.2,4.8,4.4,7.2,6.6
								c-1.1-1-2.3-2.1-3.4-3.1c0.4,0.5,0.9,1.1,1.3,1.6c-1.1-1.8-2.3-3.6-3.4-5.4c1.6,3.2,3.2,6.4,4.8,9.7
								c-1.4-3.9-2.8-7.8-4.2-11.7c0.8,2.8,1.6,5.6,2.4,8.4c-0.4-2-0.8-4.1-1.1-6.1c0.4,2.9,0.9,5.8,1.3,8.7c-0.2-2-0.4-4.1-0.6-6.1
								c0.1,2.9,0.2,5.8,0.3,8.7c0-2,0-4.1,0-6.1c-0.3,2.9-0.5,5.8-0.8,8.6c0.2-2,0.4-4,0.7-6c-0.6,2.8-1.2,5.6-1.8,8.4
								c0.4-1.9,0.9-3.9,1.3-5.8c-0.8,2.6-1.5,5.3-2.3,7.9c-1.2,3.4-1.5,6.8-1.2,10.3c0.7,1.8,1.9,3,3.6,3.5c1.6,1,3.4,1.4,5.4,1.2
								c2.8-0.5,4.6-2.1,5.4-5c4.7-11.3,5.3-22.2,1.6-32.8c-1.3-3-2.9-5.7-4.9-8.3c-1.4-2.3-3.1-4.2-5.3-5.8
								c-2.9-2.4-5.9-4.5-9.2-6.3c-2.4-0.7-4.7-0.3-6.7,1.3c-2.2,1.4-3.4,3.3-3.6,5.9c0.9,3.5,2.4,6.7,4.6,9.6
								C851.5,374.4,854,376.9,856.9,379z"/>
							<path opacity="0.1091" fill="#131313" d="M883.5,412.6c1,0.3,1.9,0.6,2.9,0.9c-1.5-0.7-3-1.4-4.6-2.1c0.6,0.5,1.3,1,1.9,1.4
								c-2-2.3-3.9-4.6-5.9-6.9c2.6,3.7,5.2,7.4,7.9,11.2c-5.2-9.8-10.4-19.7-15.6-29.5c5,12.4,10,24.7,14.9,37.1
								c-1.7-6.2-3.5-12.4-5.2-18.6c0.8,4.5,1.5,9.1,2.3,13.6c-0.7-7.3-1.3-14.6-2-21.9c0.4,8.1,0.8,16.2,1.2,24.3
								c0.1-6.9,0.2-13.8,0.2-20.7c-0.4,7.7-0.9,15.5-1.3,23.2c0.8-6.6,1.6-13.1,2.4-19.7c-2.4,9.5-3.7,19.1-4,28.8
								c0.5,3,2.2,4.8,5,5.7c7.7,1.9,9.7-8.1,11.3-13.3c1.5-4.5,2.6-9.1,3.2-13.9c-0.5-17.4-6.8-30.1-19.1-38.2
								c-2.7-1.9-5.5-3.6-8.4-5.1c-3.4-1.9-7-3-10.9-3.4c-3.1-0.2-5.1,1.2-6.1,4.2c-0.3,4.9,1.2,9.2,4.4,13.1
								c4.4,7.3,9.3,14.3,14.6,21C875.3,408.3,878.8,411.2,883.5,412.6z"/>
							<path opacity="0.1091" fill="#131313" d="M833.3,406.5c1.2,0.7,2.5,1.4,3.7,2.1c-1.3-1.2-2.6-2.3-3.8-3.5
								c2.6,2.6,5.3,5.2,7.9,7.8c-2.5-3.5-5.1-7-7.6-10.5c1.8,3.3,3.5,6.7,5.3,10c-1-2.5-2.1-5-3.1-7.5c1.1,3.2,2.1,6.5,3.2,9.7
								c-1.4-5.4-2.9-10.9-4.3-16.3c1.1,6.9,2.2,13.8,3.2,20.7c-0.4-6.1-0.9-12.2-1.3-18.4c0.1,6.8,0.2,13.5,0.3,20.3
								c0.2-6.3,0.4-12.6,0.5-18.9c-0.4,3.7-0.8,7.4-1.2,11.1c0.6-3.2,1.1-6.5,1.7-9.7c-2.4,8.1-3.8,16.4-4.3,24.8
								c-0.8,2.7-0.3,5.3,1.4,7.6c1.1,0.7,2.2,1.3,3.3,2c2.3,0.6,4.1,0.1,5.5-1.6c1.8-1,2.8-2.6,3.2-4.7c2-4.2,3.5-8.5,4.4-13.1
								c0.7-3.3,1.4-6.7,2.1-10c-3.6-14.7-11.3-24.3-23.2-28.7c-4.7-2.1-10.4,2.2-10.3,7.1c1.1,3.9,2.9,7.5,5.4,10.8
								C827.3,401.1,830.1,404.1,833.3,406.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M845.5,408.1c0,2.6,0,5.2,0.1,7.8c1.2-4,2.3-8.1,3.5-12.1
								c-2.2,3.6-4.5,7.3-6.7,10.9c6.2-7.7,12.3-15.5,18.5-23.2c-8,7.2-15.1,15.1-21.3,23.8c-0.3,1.4-0.6,2.9-0.8,4.3
								c0.5,2.9,2.2,4.7,5.1,5.4c2.9,0.3,5.4-0.6,7.5-2.7c4.1-2.5,7.8-5.6,11.1-9.1c5.5-4,9.2-9.2,11.3-15.6c3-3.6,4.4-7.7,4.2-12.1
								c0.3-2.8,0.1-5.6-0.5-8.3c-1.1-2.8-3.2-4.1-6.2-4c-3.1,0.6-5.4,2.3-7,5c-3.6,3.8-6.7,8-9.4,12.6
								C851,396.2,847.9,402,845.5,408.1z"/>
							<path opacity="0.1091" fill="#131313" d="M823.3,390.9c-1.1,2.3-2.2,4.7-3.4,7c6.5-8.1,13.1-16.2,19.6-24.4
								c-7.5,6.6-14.2,13.9-20.2,21.8c-2.3,2.1-3.6,4.7-3.7,7.9c0.1,2.4,1.3,3.9,3.3,4.5c1.9,1.4,4.1,1.4,6.4,0
								c2.3-1.6,4.5-3.1,6.8-4.7c6-5,11.7-10.3,17-16.1c2.1-6,4.2-12.1,6.2-18.1c0.4-2.7,0.1-5.4-0.8-7.9c-1.1-2.8-3.2-4.1-6.2-4
								c-3,0.6-5.3,2.2-6.9,4.8c-3.5,3.7-6.6,7.8-9.2,12.2C828.7,379.2,825.7,384.8,823.3,390.9z"/>
							<path opacity="0.1091" fill="#131313" d="M826.3,356.6c-0.4,1.6-0.7,3.3-1.1,4.9c0.4-1.4,0.9-2.8,1.3-4.1
								c-0.7,1.4-1.3,2.8-2,4.1c2.5-3.3,5-6.6,7.6-9.9c-3.5,3.5-7,7.1-10.5,10.6c-0.9,1.4-1.9,2.8-2.8,4.2c0,1.2,0,2.3,0,3.5
								c0.5,2,1.7,3.4,3.4,4.4c0.9,1,1.9,1.5,3.3,1.3c1.5,0.5,2.8,0.2,4-1c1.9-0.8,3.5-1.9,4.9-3.3c4.9-4,3.7-2.8-3.7,3.5
								c3.4-1.8,6.2-4.1,8.7-7.1c2.7-2.7,4.8-5.8,6.2-9.3c1-3.7,1.7-7.5,2-11.4c-0.4-2.5-1.7-4.3-3.8-5.6c-2-1.5-4.3-1.9-6.7-1.2
								c-2.8,1.9-5,4.3-6.7,7.3C828.3,350.3,827,353.3,826.3,356.6z"/>
							<path opacity="0.1091" fill="#131313" d="M844.2,383.8c0,1.1,0,2.2-0.1,3.3c2.1-4.1,4.2-8.2,6.3-12.3
								c-2.3,3.8-4.5,7.5-6.8,11.3c3.5-4,7.1-8,10.6-11.9c-3.4,3.3-6.8,6.7-10.1,10.1c-2.4,2.2-4.5,4.7-6.1,7.5
								c-0.8,2-0.6,3.8,0.7,5.4c0.7,1.9,2,3.1,4.1,3.8c1.2,0,2.5,0.1,3.7,0.1c4.1-2,7.8-4.6,11.1-7.9c3.9-5.9,7.8-11.8,11.7-17.7
								c0.6-2.5,0.6-5.1,0.2-7.7c0.2-2.8-0.9-4.9-3.3-6.5c-2.3-1.5-4.8-1.7-7.2-0.4c-3.5,3-6.5,6.6-8.8,10.6
								C847.5,375.2,845.5,379.4,844.2,383.8z"/>
							<path opacity="0.1091" fill="#131313" d="M845.7,351.6c-0.5,2.6-1,5.2-1.5,7.9c0.2-1.1,0.4-2.1,0.6-3.2
								c-0.6,1.3-1.2,2.7-1.8,4c1.8-2.4,3.6-4.7,5.5-7.1c-1.6,1.6-3.3,3.1-4.9,4.7c2.2-1.6,4.3-3.2,6.5-4.8c-1.7,0.8-3.3,1.7-5,2.5
								c-2.8,1.8-5.2,4-7.3,6.7c-1.6,1.4-2.1,3-1.5,4.8c0.1,1.8,0.9,3.3,2.3,4.6c1.5,1.9,3.5,2.9,5.9,3.1c2.6-0.7,5.1-1.8,7.5-3.2
								c2.3-1.5,4.3-3.3,6-5.5c3.8-4.9,4.6-10.5,5.4-16.4c-0.8-2.2-2.2-3.8-4.2-5c-1.4-1.2-3-1.8-4.8-1.7c-1.6-0.4-3,0.1-4.1,1.6
								C848.2,346.7,846.7,349,845.7,351.6z"/>
							<path opacity="0.1091" fill="#131313" d="M808.5,379c-1.2,2.4-2.4,4.8-3.6,7.2c6.4-7.8,12.7-15.7,19.1-23.5
								c-7.4,6.4-14,13.4-19.9,21.1c-2.4,2.1-3.6,4.7-3.7,7.9c0.5,1.2,1,2.4,1.6,3.6c1.7,1.8,3.7,2.2,5.8,1.4c2.3-0.1,4.2-1,5.7-2.8
								c4.5-2.9,8.5-6.2,12.2-10.1c2.8-2.8,5.5-5.5,8.3-8.3c5.5-7.9,6.8-15.8,4-23.5c-1.2-2.6-3.3-4-6.2-4c-2.9,0.4-5.1,1.8-6.6,4.2
								c-3.3,3.3-6.2,7.1-8.5,11.2C813.3,368.2,810.6,373.5,808.5,379z"/>
							<path opacity="0.1091" fill="#131313" d="M828.2,418c0,1,0,2,0,3c6.7-12.3,13.5-24.6,20.2-36.9
								c-8.7,11.7-17.4,23.5-26.2,35.2c-0.5,1.6-0.9,3.3-1.4,4.9c0.5,1.2,1,2.5,1.5,3.7c1.2,0.5,2.4,1.1,3.6,1.6
								c3.4-0.2,6.3-1.5,8.7-3.9c6.5-4.8,12.4-10.4,18-16.2c2.8-2.5,5.3-5.3,7.5-8.3c2.6-2.3,4-5.1,4.3-8.6c2-6,2.1-11.8,0.4-17.5
								c-0.9-3.1-3-4.4-6.2-4c-4.8,1.8-8.5,5.1-11,9.9c-5.4,7.4-10.4,15.1-14.9,23.1C829.4,408.1,827.9,412.8,828.2,418z"/>
							<path opacity="0.1091" fill="#131313" d="M859.6,378c-0.5,1.8-1,3.7-1.5,5.5c0.5-1.5,1-2.9,1.4-4.4c-0.8,1.5-1.5,3.1-2.3,4.6
								c1.2-1.8,2.4-3.6,3.5-5.3c-1.7,2.2-3.5,4.5-5.2,6.7c2.8-3.1,5.6-6.2,8.4-9.3c-4.2,3.7-8.9,7.2-11.9,12
								c-1.1,1-1.5,2.2-1.1,3.6c-0.2,1.9,0.5,3.5,2,4.9c1,0.8,2,1.5,3,2.3c1.1,0.2,2.2,0.3,3.3,0.5c6.3-2.3,10.6-7,12.7-14.3
								c5.9-4.8,9.1-10.7,9.7-17.8c-0.3-2.5-1.5-4.5-3.7-5.8c-2.1-1.5-4.4-1.8-6.8-1c-2.9,2.1-5.3,4.8-7.1,8
								C861.8,371.2,860.3,374.4,859.6,378z"/>
							<path opacity="0.1091" fill="#131313" d="M817.1,367.9c-1,2.3-2.1,4.7-3.1,7c5.1-6.3,10.1-12.6,15.2-18.9
								c-3.6,3.6-7.2,7.2-10.8,10.8c-2.9,2.6-5.3,5.5-7.3,8.7c-1.1,2.1-1,4,0.3,5.8c0.6,2,2,3.2,4.1,3.7c1.4-0.1,2.7-0.2,4.1-0.3
								c4.8-2.6,9.1-5.8,12.9-9.6c3.7-6.9,7.4-13.8,11.2-20.8c-0.2-2.3-0.7-4.7-1.2-7c-1.4-2.4-3.4-3.7-6.2-4c-2.5,0-4.4,1-5.8,3
								c-2.8,2.5-5.1,5.5-7,8.8C820.6,359.1,818.5,363.3,817.1,367.9z"/>
							<path opacity="0.1091" fill="#131313" d="M836.7,363.9c-0.4,1.7-0.9,3.5-1.3,5.2c1.6-3.4,3.2-6.7,4.8-10.1
								c-2.2,3.7-4.4,7.4-6.6,11c3.1-3.4,6.1-6.8,9.2-10.2c-2.1,1.8-4.1,3.7-6.2,5.5c-2.6,2-4.8,4.4-6.4,7.2
								c-1.6,1.5-2.1,3.4-1.5,5.7c0.6,1.1,1.2,2.2,1.9,3.3c1.7,2,3.9,2.9,6.6,2.5c1.2-0.4,2.3-0.8,3.5-1.2c3.5-1.9,6.5-4.4,9-7.5
								c2.8-2.8,5-6.1,6.6-9.8c0.3-1.8,0.7-3.5,1.2-5.2c0.8-2.5,1.3-5,1.4-7.6c-0.2-2.5-1.5-4.5-3.7-5.8c-2.1-1.5-4.4-1.8-6.8-1
								c-3,2.2-5.4,4.9-7.2,8.1C839,357,837.5,360.3,836.7,363.9z"/>
							<path opacity="0.1091" fill="#131313" d="M855.3,395.5c0,1,0.1,2,0.1,3c2.3-4.4,4.5-8.7,6.8-13.1c-2.3,3.9-4.7,7.8-7,11.6
								c3.6-4.1,7.2-8.1,10.8-12.2c-3.5,3.5-7,7.1-10.5,10.7c-2.4,2.1-4.4,4.6-6,7.5c-0.8,2-0.5,3.8,0.7,5.4
								c0.7,1.9,2.1,3.1,4.1,3.8c1.2,0,2.5,0.1,3.7,0.1c4.1-2,7.8-4.6,11-7.8c4.2-6.4,8.5-12.8,12.7-19.2c0.6-2.5,0.6-5.1,0.1-7.6
								c0.3-2.9-0.7-5.1-3.1-6.7c-2.4-1.5-4.9-1.6-7.4-0.1c-3.7,3.3-6.8,7.1-9.4,11.5C859.1,386.3,856.9,390.7,855.3,395.5z"/>
							<path opacity="0.1091" fill="#131313" d="M809.9,397.5c-1.2,0.7-2.4,1.4-3.6,2.1c7.4-9.2,14.8-18.3,22.2-27.5
								c-8.3,7.6-15.9,15.8-22.7,24.5c-2.6,2.3-4.1,5.2-4.5,8.7c0.5,1.2,1,2.5,1.5,3.7c1.9,1.8,4,2.1,6.2,1c2.5-0.3,4.6-1.4,6.3-3.4
								c5-3.4,9.6-7.4,13.8-11.7c3.1-3.2,6.3-6.4,9.4-9.5c6.9-8.9,8.7-17.8,5.3-26.5c-1-2.9-3.1-4.3-6.2-4c-4.5,1.4-8,4.4-10.3,8.8
								c-5,6.7-9.5,13.7-13.6,20.9C810.7,388.4,809.4,392.8,809.9,397.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M775.3,392.1c-4.5,13.7-9.1,27.4-13.7,41.1c-0.2,2.9,1,5,3.4,6.5
								c3.7,1.3,7.3,0.9,10.7-1.3c6-2.5,11.7-5.5,17.2-9c3.8-1.6,6.2-4.3,7.2-8.1c4.5-13.4,9-26.7,13.4-40.1c0.2-2.8-0.9-5-3.3-6.6
								c-3.6-1.4-7.2-1.1-10.6,1c-6,2.4-11.7,5.2-17.2,8.6C778.7,385.7,776.3,388.3,775.3,392.1z"/>
							<path opacity="0.1091" fill="#131313" d="M752.1,375.5c-4.5,13.8-9.2,27.4-13.8,41.2c-0.5,3,0.6,5.2,3.4,6.5
								c4.7,1,9.1,0.1,13.2-2.6c7.6-3.3,15-7.2,22.1-11.5c4.6-1.9,7.8-5,9.7-9.4c4.5-13.3,9.1-26.6,13.4-40c0.6-3-0.5-5.2-3.3-6.6
								c-4.6-1.1-9-0.3-13.1,2.3c-7.6,3.2-14.9,6.8-22,11C757.2,368.2,754,371.2,752.1,375.5z"/>
							<path opacity="0.1091" fill="#131313" d="M755.3,341.3c-4.3,13.7-8.9,27.3-13.4,40.9c0,2.8,1.1,5,3.4,6.5
								c3.3,1.5,6.5,1.3,9.7-0.7c5.3-2.1,10.4-4.8,15.3-7.9c3.5-1.5,5.5-4,6.2-7.5c4.5-13.4,9-26.7,13.3-40.2c0.1-2.8-1-5-3.3-6.6
								c-3.2-1.6-6.4-1.4-9.6,0.5c-5.3,2-10.4,4.5-15.3,7.6C758,335.4,756,337.8,755.3,341.3z"/>
							<path opacity="0.1091" fill="#131313" d="M774.4,367.4c-4.8,13.7-9.3,27.4-13.9,41.2c0,2.7,1.2,4.9,3.4,6.5
								c3.1,1.5,6.2,1.4,9.3-0.5c5.1-2,9.9-4.5,14.5-7.6c3.3-1.5,5.2-3.9,5.8-7.3c4.5-13.5,9-27,13.7-40.4c0-2.7-1.1-4.9-3.3-6.6
								c-3-1.6-6.1-1.5-9.2,0.3c-5.1,1.9-9.9,4.3-14.4,7.2C776.9,361.7,775,364.1,774.4,367.4z"/>
							<path opacity="0.1091" fill="#131313" d="M777.4,334.6c-4.7,6.9-8.2,14.4-10.6,22.3c-2.4,6.8-4.7,13.7-6.9,20.5
								c-1,2.3-0.8,4.6,0.5,6.8c1.1,2.3,2.8,3.8,5.3,4.3c3.5-0.3,6.8-1.4,9.8-3.2c3.2-1.5,6-3.5,8.3-6.2c2.2-6.7,4.5-13.5,6.6-20.2
								c2.2-7.6,5.3-14.8,9.5-21.6c1.9-4-1.3-10.3-5.6-11.2c-3.3,0.2-6.3,1.1-9.2,2.7C782.1,330.2,779.5,332.1,777.4,334.6z"/>
							<path opacity="0.1091" fill="#131313" d="M735.7,364.2c-4.4,13.8-9.1,27.5-13.7,41.2c-0.7,3.1,0.4,5.3,3.4,6.5
								c5.1,0.8,9.9-0.3,14.5-3.2c8.4-3.7,16.6-8,24.5-12.7c5-2,8.6-5.3,10.9-10c4.5-13.3,9.2-26.5,13.4-39.9
								c0.7-3.1-0.4-5.3-3.3-6.6c-5-0.9-9.8,0-14.3,2.9c-8.4,3.5-16.5,7.6-24.3,12.1C741.6,356.4,738,359.6,735.7,364.2z"/>
							<path opacity="0.1091" fill="#131313" d="M757.6,395.8c-4.5,13.8-9.2,27.5-13.8,41.2c-0.5,3.1,0.6,5.2,3.4,6.5
								c4.7,1,9.2,0.1,13.4-2.7c7.7-3.4,15.1-7.3,22.3-11.7c4.6-1.9,7.9-5,9.8-9.4c4.5-13.4,9.1-26.6,13.4-40c0.6-3-0.5-5.2-3.3-6.6
								c-4.6-1.1-9-0.3-13.2,2.3c-7.6,3.2-15,6.9-22.2,11.1C762.8,388.4,759.6,391.4,757.6,395.8z"/>
							<path opacity="0.1091" fill="#131313" d="M790.7,359.8c-4.7,6.8-8.1,14.1-10.2,22c-2.2,6.9-4.4,13.8-6.8,20.6
								c-1,2.3-0.9,4.6,0.4,6.8c1.1,2.3,2.9,3.8,5.4,4.3c3.6-0.4,6.9-1.5,10.1-3.4c3.3-1.5,6.2-3.6,8.5-6.3
								c2.4-6.8,4.6-13.6,6.6-20.5c1.9-7.5,5-14.5,9.2-21.1c2-4.1-1.1-10.4-5.6-11.2c-3.4,0.2-6.6,1.2-9.6,2.9
								C795.6,355.3,792.9,357.3,790.7,359.8z"/>
							<path opacity="0.1091" fill="#131313" d="M746.9,351.7c-4.6,13.7-9.2,27.4-13.8,41.2c-0.4,3,0.7,5.1,3.4,6.5
								c4.3,1.1,8.3,0.5,12.2-2c6.9-3,13.6-6.4,20.1-10.4c4.3-1.7,7.2-4.7,8.7-8.8c4.5-13.4,9.1-26.7,13.5-40.1
								c0.4-2.9-0.7-5.1-3.3-6.6c-4.2-1.2-8.2-0.7-12.1,1.7c-6.9,2.8-13.6,6.1-20,9.9C751.3,344.7,748.4,347.6,746.9,351.7z"/>
							<path opacity="0.1091" fill="#131313" d="M765.5,349.7c-4.8,13.7-9.3,27.4-13.9,41.2c0,2.7,1.2,4.9,3.4,6.5
								c3.1,1.5,6.2,1.4,9.3-0.5c5.1-2,9.9-4.5,14.5-7.5c3.3-1.5,5.3-3.9,5.8-7.3c4.5-13.5,9-27,13.7-40.4c0-2.7-1.1-4.9-3.3-6.6
								c-3-1.6-6.1-1.5-9.2,0.3c-5.1,1.9-9.9,4.3-14.5,7.2C768,343.9,766.1,346.3,765.5,349.7z"/>
							<path opacity="0.1091" fill="#131313" d="M788.1,374.8c-3.7,6.8-6.5,13.8-8.7,21.2c-2.2,6.9-4.5,13.8-6.9,20.7
								c-1.3,2.3-1.4,4.7-0.1,7.1c1.2,2.4,3.2,3.8,5.9,4c4.2-0.7,8.2-2.2,11.9-4.3c3.9-1.8,7.4-4.2,10.4-7.3
								c2.4-6.9,4.6-13.7,6.8-20.7c2-7,4.7-13.8,8-20.4c1.3-2.3,1.4-4.7,0.1-7c-1.1-2.4-3-3.8-5.7-4.2c-4.1,0.6-8,1.9-11.6,4
								C794.4,369.5,791,371.8,788.1,374.8z"/>
							<path opacity="0.1091" fill="#131313" d="M736.5,377.6c-4.5,13.8-9.2,27.5-13.8,41.3c-0.8,3.2,0.3,5.4,3.4,6.5
								c5.5,0.6,10.6-0.6,15.4-3.7c9-4.1,17.8-8.6,26.3-13.7c5.3-2.1,9.3-5.6,11.9-10.5c2.2-6.9,4.6-13.8,7.1-20.6
								c2.4-6.3,4.5-12.8,6.3-19.3c0.9-3.2-0.2-5.4-3.3-6.6c-5.4-0.8-10.5,0.4-15.3,3.3c-9,3.8-17.7,8.2-26.2,13
								C743.1,369.5,739.2,372.9,736.5,377.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M743,429.4c0.8,2.4,1.9,4.7,3.4,6.7c1.5,2.6,3.3,4.9,5.4,7
								c3.1,3.3,6.5,6.2,10.2,8.7c2,2.4,4.4,3,7.2,1.7c3.8-3,6-6.9,6.7-11.9c2.5-8.1,4.4-16.2,5.7-24.6c1.6-4.7,1.4-9.3-0.7-13.6
								c-1.6-1.7-3.2-3.4-4.5-5.4c-0.6-0.9-1.2-1.9-1.8-2.9c-1-1.5-1.7-3.2-2.2-4.9c-1.3-2.7-3.5-3.9-6.5-3.5
								c-4.3,1.6-7.3,4.6-9.2,9c-4.2,6.7-8,13.7-11.2,20.9C742.8,420.7,742,424.9,743,429.4z"/>
							<path opacity="0.1091" fill="#131313" d="M720.9,411.7c0.7,2.4,1.8,4.6,3.4,6.6c1.5,2.4,3.4,4.7,5.5,6.7
								c3.1,3,6.5,5.5,10,8.1c2,2.1,4.4,2.7,7.2,1.6c3.5-2.2,5.6-5.5,6.2-9.9c2.1-6.8,3.7-13.7,4.7-20.7c1.4-4.2,0.9-8-1.3-11.6
								c-1.3-2.7-2.9-5.3-4.1-8.1c-1.3-3-2.5-5.9-3.6-9.1c-1.4-2.6-3.5-3.8-6.5-3.5c-4.1,1.4-7.1,4.2-8.9,8.3
								c-4,6.3-7.5,12.8-10.6,19.6C720.6,403.6,719.8,407.5,720.9,411.7z"/>
							<path opacity="0.1091" fill="#131313" d="M723.7,372.6c0.1,3.4,0.8,6.6,1.9,9.8c0.4,2.2,1.2,4.2,2.5,6.1
								c1.4,1.6,2.8,3.2,4.1,4.8c3.1,3.4,6.7,6.4,10.6,8.9c2.2,1.4,4.5,2.1,7.1,2c2.6-0.2,4.3-1.6,5.1-4.2c1.4-3.2,2.4-6.5,2.7-10
								c0.6-2.6-0.2-4.7-2.3-6.2c-2.2-1.7-4.3-3.7-6-5.9c-1.9-2-3.5-4.2-5-6.5c0.5,0.9,1,1.9,1.5,2.8c-1.2-3.9-2-7.8-2.5-11.8
								c-0.7-4.3-7-7.6-11-6c-2.5,1.9-4.5,4.4-5.8,7.3C724.9,366.4,723.9,369.4,723.7,372.6z"/>
							<path opacity="0.1091" fill="#131313" d="M742.3,403.8c0.6,1.5,1.2,3,1.8,4.5c1.2,2.6,2.7,4.9,4.7,6.8
								c3.5,4,7.5,7.2,11.7,10.4c2.1,1.7,4.5,2.3,7.2,1.6c3.2-1.5,5-4,5.6-7.6c1.7-5.3,2.9-10.7,3.5-16.3c1-3.5,0.4-6.6-1.9-9.2
								c-2.1-2.2-4.3-4.2-6.1-6.7c-2.1-2.8-3.1-5.8-4.2-9.1c-1.6-2.2-3.7-3.4-6.5-3.5c-3.4,0.5-5.9,2.3-7.4,5.6
								c-3.1,4.5-5.6,9.3-7.7,14.3C741,397.7,740.8,400.8,742.3,403.8z"/>
							<path opacity="0.1091" fill="#131313" d="M742.4,370.9c1,4.8,1.8,10.4,5.1,14.4c4.2,5.1,9.5,9.2,14.7,13.2
								c2.2,1.3,4.6,1.9,7.1,1.9c2.3,0.1,4-1,4.9-3.2c1.3-2.6,2-5.3,2.2-8.2c0.4-2.4-0.5-4.1-2.5-5.3c-2.3-1.6-4.5-3.3-6.6-5.2
								c-2.1-1.7-4-3.5-5.7-5.6c0.6,0.8,1.1,1.6,1.7,2.4c-1.5-3.3-2.7-6.8-3.4-10.4c-1-2.1-2.5-3.6-4.7-4.6c-2-1.2-4.1-1.7-6.4-1.4
								c-2.1,1.2-3.7,2.9-4.7,5.2C742.8,366.2,742.2,368.5,742.4,370.9z"/>
							<path opacity="0.1091" fill="#131313" d="M706.2,399.8c0.6,1.5,1.3,2.9,1.9,4.4c1.5,2.3,3.1,4.5,5,6.5
								c3.5,4,7.6,7.1,11.8,10.3c2.1,1.8,4.5,2.4,7.2,1.6c2.4-1,3.9-2.7,4.4-5.2c1.8-3.6,3-7.4,3.6-11.3c1.3-4.9,1.8-9.9,1.6-14.9
								c-2.1-4.5-4.5-8.9-6.2-13.5c-1.2-3.3-2.5-6.6-3.5-10c-1.4-2.5-3.6-3.7-6.5-3.5c-3.9,1-6.6,3.4-8.3,7.3
								c-3.6,5.6-6.8,11.4-9.5,17.5C705.5,392.4,705,396,706.2,399.8z"/>
							<path opacity="0.1091" fill="#131313" d="M725.8,434.6c0.8,2.4,2,4.7,3.6,6.6c1.6,2.5,3.5,4.8,5.7,6.9
								c0.9,0.9,1.9,1.9,2.8,2.8c3.3,3.1,7,5.8,11.1,7.9c3.5,0.2,6-1.3,7.6-4.6c2.3-5.1,4.1-10.4,5.3-15.9c2-6.7,3.5-13.4,4.7-20.3
								c1.8-5.1,1.6-10.1-0.4-14.9c-1.1-2.3-2.5-4.3-3.5-6.6c-0.4-1.1-0.9-2.1-1.5-3.2c-0.9-1.6-1.6-3.2-2.1-5
								c-1.2-3-3.3-4.2-6.5-3.5c-4.7,2.2-8.1,5.9-10.2,10.8c-4.9,7.9-9.2,16-13.1,24.4C726.3,424.6,725.1,429.4,725.8,434.6z"/>
							<path opacity="0.1091" fill="#131313" d="M756.4,394.6c0.1,3.3,0.6,6.5,1.5,9.6c0.4,2.2,1.1,4.4,2.2,6.4
								c1.4,1.4,2.7,2.7,4.1,4.1c3.2,2.8,6.5,5.4,9.8,7.9c2.2,1.6,4.6,2.1,7.2,1.5c3-1.1,4.7-3.3,5.3-6.6c1.5-4.6,2.5-9.3,3-14.1
								c0.8-3.2,0.1-5.9-2.2-8.1c-1.5-0.9-2.9-1.9-4.3-2.9c-1.7-1-3.3-2.3-4.6-3.9c0.5,1.1,0.9,2.1,1.4,3.2c-1.1-3.4-1.8-6.8-2-10.4
								c-0.7-4.8-6.7-8.1-11-5.9c-2.8,2.5-5,5.4-6.6,8.8C758.1,387.4,756.9,390.9,756.4,394.6z"/>
							<path opacity="0.1091" fill="#131313" d="M715.3,387.8c0.6,1.6,1.1,3.1,1.7,4.7c1.2,2.6,2.7,4.9,4.7,6.9
								c3.4,4,7.4,7.3,11.5,10.6c2.2,1.6,4.6,2.1,7.2,1.5c2.2-0.6,3.6-2,4.2-4.1c1.6-2.8,2.6-5.9,3-9.1c1.1-4,1.4-8,0.9-12.1
								c-2.2-3.4-4.4-6.9-6.5-10.4c-1.5-2.6-2.7-5.4-3.6-8.3c0.4,1.2,0.7,2.4,1.1,3.5c-0.8-2.6-1.5-5.2-2.3-7.8
								c-1.6-2.2-3.8-3.4-6.5-3.5c-3.3,0.4-5.7,2.1-7.2,5.3c-3,4.3-5.5,8.9-7.5,13.8C714,381.8,713.8,384.8,715.3,387.8z"/>
							<path opacity="0.1091" fill="#131313" d="M733.8,380c-0.1,3.5,0.3,7,1.3,10.3c0.3,2.3,1,4.5,2.2,6.5c1.3,1.4,2.6,2.9,3.9,4.3
								c3.1,2.9,6.4,5.6,9.8,8.1c2.2,1.4,4.6,1.9,7.2,1.6c2.8-0.7,4.5-2.5,5.1-5.5c1.4-3.9,2.3-8,2.6-12.1c0.7-2.9-0.1-5.3-2.4-7.1
								c-1.5-1.5-3-3-4.5-4.6c-1.6-1.5-3-3.2-4.1-5c0.5,1,1,2,1.4,3c-0.9-3.9-1.4-7.8-1.6-11.8c-0.5-4.7-6.9-7.9-11-6
								c-2.7,2.3-4.9,5.1-6.4,8.4C735.4,373.1,734.3,376.4,733.8,380z"/>
							<path opacity="0.1091" fill="#131313" d="M753.5,415.5c0.6,1.4,1.3,2.8,1.9,4.2c1.3,2.5,2.9,4.7,4.8,6.7
								c1.3,1.6,2.7,3.1,4.3,4.5c2.3,2.3,4.8,4.4,7.5,6.2c2.1,1.9,4.5,2.5,7.2,1.6c3.4-1.9,5.4-4.9,5.9-9c1.9-6.2,3.3-12.5,4.2-18.9
								c1.2-3.9,0.7-7.5-1.6-10.6c-2.2-1.6-4.3-3.1-6-5.2c-2.1-2.5-3.3-4.9-4.6-8c-1.5-2.3-3.7-3.5-6.5-3.5
								c-3.6,0.7-6.2,2.8-7.7,6.3c-3.3,5-6.1,10.2-8.4,15.7C752.5,408.8,752.1,412.1,753.5,415.5z"/>
							<path opacity="0.1091" fill="#131313" d="M707.7,413.6c0.7,2.4,1.9,4.6,3.5,6.5c1.6,2.4,3.5,4.6,5.7,6.5
								c1,0.9,1.9,1.7,2.9,2.6c3.3,2.8,6.8,5.2,10.6,7.2c2.4,0.5,4.2-0.1,5.5-1.9c2.8-2.1,4.3-5,4.6-8.7c1-3.5,1.9-7.1,2.9-10.6
								c1.6-6.1,2.5-12.3,2.6-18.6c-2.8-7.6-6-15.1-8.4-22.9c-1.3-2.8-3.5-3.9-6.5-3.5c-4.3,1.7-7.4,4.8-9.3,9.2
								c-4.3,6.8-8.2,13.9-11.5,21.3C707.6,404.8,706.7,409.1,707.7,413.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M887.1,411.5c4.7-0.1,9.4-0.1,14.2-0.2c3.2-0.3,4.9-2.1,5.2-5.3
								c-0.8-5.1-3.3-9.3-7.5-12.7c-6.2-6.8-12.7-13.2-19.6-19.2c-3.5-4.1-7.7-6.5-12.9-7.2c-4.4,0.1-8.7,0.2-13.1,0.3
								c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7c6,6.8,12.4,13.2,19.1,19.2C877.8,408.4,882,410.8,887.1,411.5z"/>
							<path opacity="0.1091" fill="#131313" d="M862.7,392.6c4.7,0,9.5,0.1,14.2,0.1c2.6-0.9,4.3-2.7,5.1-5.3
								c0.5-3.4-0.7-6.3-3.5-8.7c-3.5-4.2-7.5-8.1-11.8-11.5c-2.4-2.7-5.4-3.8-8.8-3.2c-4.5-0.1-9.1-0.1-13.6-0.2
								c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7c3.5,4.2,7.3,8.1,11.5,11.6C856.4,392,859.3,393.1,862.7,392.6z"/>
							<path opacity="0.1091" fill="#131313" d="M862,358.6c5.4,0.6,10.9,1.1,16.3,1.7c2.4-0.1,4.4-1.1,6-2.9c1.8-1.6,2.8-3.6,2.9-6
								c-0.9-2.7-2.4-5-4.6-7c-1.9-2.2-4.2-3.7-7-4.6c-5.2-0.4-10.5-0.7-15.7-1.1c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9
								c0.8,2.6,2.2,4.8,4.3,6.7C857.2,356.4,859.4,357.8,862,358.6z"/>
							<path opacity="0.1091" fill="#131313" d="M883.9,385.2c4.7,0,9.4-0.1,14.1-0.1c2.8-0.7,4.5-2.5,5.1-5.3
								c0.1-4-1.5-7.3-4.7-9.9c-4.4-5-9.1-9.6-14.3-13.9c-2.7-3.2-6.1-4.6-10.1-4.5c-4.4,0-8.9,0.1-13.3,0.1
								c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9c4.2,5,8.9,9.7,13.9,13.9C876.7,383.9,880,385.4,883.9,385.2z"/>
							<path opacity="0.1091" fill="#131313" d="M879.1,354.4c6,1.2,11.9,2.4,17.9,3.6c4.8,1,9.6-4.4,8.7-9
								c-1.6-3.4-3.8-6.3-6.6-8.8c-2.6-2.8-5.5-4.9-8.9-6.4c-5.6-1-11.2-1.9-16.8-2.9c-4.6-0.8-9.5,4.3-8.8,8.9
								c1.4,3.3,3.5,6.1,6.1,8.5C873,351,875.9,353,879.1,354.4z"/>
							<path opacity="0.1091" fill="#131313" d="M845.1,380.8c5.4,0.5,10.8,1.1,16.2,1.6c4.2,0.4,9.3-4.6,8.9-8.8
								c-1.1-2.9-2.7-5.4-5.1-7.4c-2.1-2.3-4.5-4-7.4-5.1c-5.2-0.4-10.5-0.7-15.7-1.1c-4.1-0.3-9.2,4.7-8.9,8.8
								c1,2.8,2.6,5.2,4.8,7.2C839.9,378.3,842.3,379.9,845.1,380.8z"/>
							<path opacity="0.1091" fill="#131313" d="M870.1,413.3c4.7,0,9.5,0,14.2,0c2.9-0.6,4.6-2.3,5.2-5.3c-0.2-4.4-2.1-8-5.7-10.9
								c-5-5.6-10.3-10.9-16-15.7c-3-3.5-6.6-5.3-11-5.4c-4.4,0-8.9,0.1-13.3,0.1c-2.9,0.6-4.7,2.3-5.2,5.2
								c0.1,4.3,1.9,7.9,5.4,10.9c4.8,5.6,10,10.9,15.6,15.7C862.2,411.3,865.8,413.2,870.1,413.3z"/>
							<path opacity="0.1091" fill="#131313" d="M898.1,381.9c4.7,0,9.5,0,14.2-0.1c2.9-0.6,4.7-2.3,5.1-5.3
								c-0.3-4.4-2.2-8.1-5.8-11c-5.1-5.7-10.5-11-16.4-15.9c-3-3.5-6.7-5.4-11.2-5.5c-4.4,0-8.9,0.1-13.3,0.1
								c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9C890.1,379.9,893.7,381.8,898.1,381.9z"/>
							<path opacity="0.1091" fill="#131313" d="M853.3,369.3c5.4,0.6,10.9,1.1,16.3,1.7c4.3,0.4,9.3-4.6,8.9-8.9
								c-1.1-2.9-2.8-5.4-5.2-7.5c-2.1-2.3-4.6-4.1-7.5-5.2c-5.3-0.4-10.5-0.8-15.8-1.2c-4.2-0.3-9.2,4.6-8.9,8.9
								c1,2.8,2.6,5.2,4.9,7.2C848.1,366.6,850.5,368.3,853.3,369.3z"/>
							<path opacity="0.1091" fill="#131313" d="M869.6,365.7c5.6,0.9,11.2,1.9,16.9,2.8c5,0.8,9.7-4,8.7-9
								c-1.8-3.6-4.2-6.6-7.2-9.3c-2.7-2.9-5.9-5.2-9.5-6.9c-5.3-0.7-10.6-1.5-15.9-2.2c-4.8-0.7-9.7,3.9-8.8,8.9
								c1.6,3.5,3.9,6.5,6.7,9.1C863.1,361.9,866.1,364.1,869.6,365.7z"/>
							<path opacity="0.1091" fill="#131313" d="M896.7,398c4.7-0.1,9.4-0.1,14.1-0.2c3.2-0.4,4.9-2.1,5.1-5.3
								c-0.8-5-3.2-9.2-7.3-12.4c-6-6.6-12.4-12.9-19.2-18.7c-3.4-4-7.6-6.3-12.6-7c-4.4,0.1-8.7,0.2-13.1,0.2
								c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4c5.8,6.6,12.1,12.8,18.7,18.7C887.6,395,891.7,397.4,896.7,398z"/>
							<path opacity="0.1091" fill="#131313" d="M845,391.7c5.8,1.1,11.6,2.1,17.4,3.2c4.9,0.9,9.7-4.2,8.8-8.9
								c-1.7-3.5-3.9-6.5-6.8-9.1c-2.6-2.8-5.7-5.1-9.2-6.7c-5.5-0.9-11-1.7-16.5-2.6c-4.7-0.7-9.6,4.1-8.9,8.8
								c1.5,3.4,3.6,6.3,6.4,8.8C838.7,388,841.6,390.2,845,391.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M911.5,535.9c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C902.2,532.8,906.4,535.2,911.5,535.9z"/>
							<path opacity="0.1091" fill="#131313" d="M887.1,517c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C880.7,516.4,883.6,517.5,887.1,517z"/>
							<path opacity="0.1091" fill="#131313" d="M886.4,483c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C881.6,480.8,883.8,482.2,886.4,483z"/>
							<path opacity="0.1091" fill="#131313" d="M908.3,509.6c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C901,508.2,904.4,509.8,908.3,509.6z"/>
							<path opacity="0.1091" fill="#131313" d="M903.5,478.8c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C897.4,475.3,900.3,477.4,903.5,478.8z"/>
							<path opacity="0.1091" fill="#131313" d="M869.5,505.2c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C864.3,502.6,866.7,504.3,869.5,505.2z"/>
							<path opacity="0.1091" fill="#131313" d="M894.5,537.7c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C886.6,535.7,890.2,537.5,894.5,537.7z"/>
							<path opacity="0.1091" fill="#131313" d="M922.5,506.3c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C914.5,504.3,918.1,506.1,922.5,506.3z"/>
							<path opacity="0.1091" fill="#131313" d="M877.7,493.7c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C872.5,491,874.9,492.7,877.7,493.7z"/>
							<path opacity="0.1091" fill="#131313" d="M894,490.1c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C887.5,486.3,890.5,488.5,894,490.1z"/>
							<path opacity="0.1091" fill="#131313" d="M921.1,522.4c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C912,519.4,916.1,521.7,921.1,522.4z"/>
							<path opacity="0.1091" fill="#131313" d="M869.4,516.1c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C863.1,512.4,866,514.6,869.4,516.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M921.1,478.7c1.2-3.5,2.3-7.1,3.1-10.7c0.9-3.5,1.3-7,1.3-10.7
								c0.2-3.2,0.4-6.4,0.6-9.6c0.1-6.6-0.1-13.3-0.8-19.9c-0.7-2.6-2.2-4.5-4.7-5.7c-3.1-1-5.8-0.4-8.4,1.9
								c-4.2,2.7-8,5.8-11.4,9.3c-2.7,2-3.9,4.6-3.5,7.8c0.1,6.2-0.2,12.3-0.8,18.5c-0.7,5.7-1.5,11.5-2.4,17.2
								c0.3-1.2,0.5-2.4,0.8-3.6c-0.8,2.7-1.6,5.4-2.4,8.2c-1.3,2.4-1.1,4.8,0.3,7.1c1.4,2.4,3.4,3.6,6.2,3.6
								c4.3-1.1,8.3-2.8,11.9-5.3C914.8,484.7,918.2,482,921.1,478.7z"/>
							<path opacity="0.1091" fill="#131313" d="M898.2,462.1c2.8-3.6,4.4-7.5,4.8-11.8c0.5-3.4,0.7-6.8,0.8-10.2
								c0.2-3.2,0.3-6.4,0.5-9.6c0.1-6.6-0.2-13.2-1-19.8c-0.8-2.5-2.4-4.4-4.7-5.7c-2.7-1.1-5.3-0.7-7.6,1.3c-3.7,2.3-7,5.1-10,8.2
								c-2.4,1.9-3.4,4.3-2.8,7.2c0.5,6,0.6,12,0.4,18c-0.4,5.5-0.8,11-1.2,16.6c0.1-1.1,0.3-2.2,0.4-3.3c-1.3,3.1-2.6,6.2-3.9,9.3
								c-1.8,4.3,1.9,10.3,6.5,10.7c3.5-0.6,6.8-2,9.8-4C893.3,467.2,896,464.9,898.2,462.1z"/>
							<path opacity="0.1091" fill="#131313" d="M903.6,423.5c1.2-7.3,2.3-14.6,2.7-22.1c0.3-3.2,0.3-6.5,0.2-9.7
								c0-5-0.2-10-0.8-14.9c-0.4-2.8-2-4.7-4.7-5.7c-3.9-0.5-7.4,0.6-10.3,3.5c-5.4,3.7-10.5,7.9-15.2,12.5
								c-3.4,2.4-5.2,5.5-5.5,9.5c0.6,6.9,1,13.8,0.6,20.7c-0.4,6.6-1.5,13-2.6,19.4c0.1,2.8,1.4,4.9,3.8,6.3
								c3.6,1.2,6.9,0.6,10.1-1.7c5.5-2.7,10.7-5.9,15.6-9.6C900.9,429.9,903,427.2,903.6,423.5z"/>
							<path opacity="0.1091" fill="#131313" d="M921.1,450.5c1.3-7.4,2.5-14.7,2.9-22.2c0.3-3.2,0.3-6.5,0.2-9.7
								c0-5-0.3-9.9-0.9-14.9c-0.4-2.8-2-4.7-4.7-5.7c-3.9-0.5-7.4,0.6-10.3,3.5c-5.4,3.7-10.5,7.9-15.3,12.5
								c-3.4,2.3-5.3,5.5-5.5,9.4c0,7.3-0.1,14.5-1,21.7c-0.8,6.7-2.1,13.2-3.4,19.8c0,2.9,1.3,5,3.8,6.3c3.8,1.1,7.3,0.4,10.7-2
								c5.9-2.9,11.4-6.3,16.7-10.2C918.1,457.2,920.3,454.4,921.1,450.5z"/>
							<path opacity="0.1091" fill="#131313" d="M923.8,420.5c1.4-7.5,2.7-14.9,3.1-22.6c0.3-3.3,0.4-6.6,0.2-9.8
								c0.1-5.1-0.2-10.1-0.8-15.1c0-3.2-1.5-5.1-4.7-5.7c-5.1,0.2-9.5,2.1-13.2,5.8c-7.3,5.2-14.2,10.9-20.8,16.9
								c-4.4,2.9-7.2,6.8-8.4,11.8c0.2,6.8,0.2,13.5-0.4,20.3c-0.6,6.4-2,12.8-3.1,19.2c-0.3,3.1,0.9,5.2,3.8,6.3
								c4.8,0.6,9.2-0.6,13.3-3.6c7.5-3.9,14.7-8.4,21.7-13.3C919,428.4,922.1,425,923.8,420.5z"/>
							<path opacity="0.1091" fill="#131313" d="M885.3,448.8c3-4.6,4.5-9.6,4.6-14.9c0.5-4.7,0.9-9.5,1.1-14.3
								c0.2-7.5-0.4-14.9-1.1-22.4c-0.9-2.4-2.4-4.3-4.7-5.7c-2.4-1.3-4.8-1-7,0.8c-3.3,2-6.2,4.4-8.8,7.2c-2.2,1.8-2.9,4.1-2.2,6.7
								c0.8,5.6,1.2,11.1,1.1,16.8c0-1.1,0.1-2.1,0.1-3.2c-0.2,4.4-0.4,8.8-0.6,13.2c0.2,5-1.1,9.7-3.8,14.1
								c-1.4,3.9,2.5,10.1,6.5,10.7c3-0.3,5.7-1.3,8.2-3.1C881.5,453.3,883.6,451.4,885.3,448.8z"/>
							<path opacity="0.1091" fill="#131313" d="M906.5,480.7c2.9-4.6,4.4-9.5,4.4-14.8c0.5-4.7,0.8-9.5,1-14.3
								c0.2-7.8-0.4-15.3-1.1-23.1c-0.9-2.4-2.4-4.4-4.5-5.8c-1.6-1.2-3.2-1.3-4.8-0.3c-2.3,0.6-4.4,1.8-6.2,3.5
								c-2.6,1.8-4.6,4-6,6.7c0.2,4.2,0.4,8.4,0.7,12.5c0-1,0-2.1-0.1-3.1c0,4.2-0.1,8.3-0.1,12.5c0-1.1,0-2.1,0.1-3.2
								c-0.3,4.5-0.7,9-0.9,13.6c0.1,5-1.2,9.7-3.9,14.1c-1.5,4,2.3,10.1,6.5,10.7c3-0.3,5.8-1.4,8.4-3.1
								C902.6,485.3,904.8,483.3,906.5,480.7z"/>
							<path opacity="0.1091" fill="#131313" d="M936.2,446.2c1.3-7.4,2.5-14.8,2.9-22.4c0.3-3.2,0.3-6.5,0.1-9.7
								c0-5-0.3-9.9-1-14.9c-0.1-3.1-1.6-5-4.6-5.7c-4.8,0-9,1.8-12.5,5.3c-6.9,4.9-13.4,10.1-19.5,15.9c-4.2,2.8-6.7,6.5-7.7,11.2
								c-0.3,7.1-0.7,14.2-1.7,21.3c-0.9,6.5-2.5,13-3.7,19.5c-0.3,3.1,0.9,5.2,3.8,6.3c4.8,0.6,9.2-0.5,13.2-3.5
								c7.5-3.9,14.7-8.3,21.6-13.1C931.5,454.1,934.6,450.7,936.2,446.2z"/>
							<path opacity="0.1091" fill="#131313" d="M892,438.8c2-3.6,3.3-7.3,4-11.3c0.8-3.4,1.1-6.9,1.1-10.5c0.2-3.2,0.3-6.4,0.5-9.6
								c0.1-6.6-0.3-13.2-1-19.7c-0.6-2.6-2.2-4.5-4.7-5.7c-3.2-0.9-6.1-0.2-8.7,2.2c-4.4,2.9-8.4,6.2-12.1,10
								c-2.9,2.1-4.1,4.8-3.9,8.1c0.7,5.7,1,11.3,1,17c-0.2,5.5-0.6,10.9-1.1,16.4c0.2-1.2,0.5-2.3,0.7-3.5c-1,2.9-2.1,5.7-3.1,8.6
								c-1.1,2.4-1,4.8,0.5,7c1.4,2.3,3.4,3.5,6,3.6c4.1-1,7.8-2.6,11.3-5C886.1,444.6,889.3,442,892,438.8z"/>
							<path opacity="0.1091" fill="#131313" d="M912.1,432.8c0.6-2.8,1.1-5.7,1.7-8.5c0.8-4.6,1.3-9.2,1.4-13.9
								c0.3-3.2,0.4-6.5,0.2-9.7c0-5-0.2-10-0.8-14.9c-0.3-2.9-1.9-4.8-4.7-5.7c-4.2-0.4-7.8,0.9-10.9,4c-5.8,4-11.3,8.5-16.4,13.4
								c-3.6,2.5-5.7,5.8-6.1,9.9c0.3,7,0.4,13.9-0.2,20.9c-0.6,6.6-1.9,13.1-3.2,19.6c0,2.9,1.3,5,3.8,6.3c3.9,1,7.5,0.3,10.9-2.2
								c6-3,11.8-6.5,17.2-10.5C908.9,439.7,911.2,436.8,912.1,432.8z"/>
							<path opacity="0.1091" fill="#131313" d="M932.5,461.9c0.6-2.8,1.1-5.6,1.7-8.5c0.8-4.6,1.3-9.2,1.4-13.8
								c0.3-3.2,0.3-6.4,0.1-9.7c0-5-0.3-9.9-0.9-14.8c-0.4-2.9-1.9-4.8-4.7-5.7c-4-0.5-7.6,0.8-10.7,3.8
								c-5.6,3.9-10.9,8.2-15.9,12.9c-3.5,2.4-5.5,5.6-5.8,9.7c-0.3,7.4-0.7,14.7-1.8,22.1c-1,6.7-2.5,13.3-3.9,20
								c-0.1,2.9,1.2,5,3.8,6.3c4,1,7.8,0.2,11.3-2.4c6.3-3.2,12.3-6.8,18-10.9C929,468.9,931.5,465.9,932.5,461.9z"/>
							<path opacity="0.1091" fill="#131313" d="M886.3,462.3c3.1-4.6,4.7-9.6,4.7-15c0.5-4.8,0.9-9.5,1.1-14.3
								c0.2-6.7-0.2-13.4-0.7-20.1c0.2-2.4-0.5-4.5-2.1-6.3c-1.4-2-3.2-3.2-5.6-3.6c-2.8,0.6-5.3,1.8-7.5,3.8
								c-2.4,1.6-4.2,3.8-5.5,6.4c0.3,4.1,0.7,8.2,1,12.3c-0.1-1-0.1-2-0.2-3c0.3,6.1,0.2,12.3-0.3,18.4c0.5,6.5-0.8,12.6-4,18.4
								c-0.3,2.3,0.2,4.4,1.6,6.4c1.1,2.1,2.7,3.5,4.9,4.3c2.6-0.1,5-0.9,7.1-2.4C883.2,466.3,885,464.6,886.3,462.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M944.4,456.8c1.1-4.6,2.6-9.2,2.5-13.9c-0.3-2.9-0.7-5.7-1-8.6
								c-0.4-4.9-1.2-9.7-2.4-14.4c-1.5-6.6-3.4-12.9-5.8-19.3c-1.1-3.3-2.4-6.4-4-9.5c-2.1-4.8-4.5-9.5-7.2-14
								c-1.6-2.1-3.7-3.3-6.3-3.8c-2.9-0.2-5.1,0.9-6.6,3.5c-2.6,3.3-4.7,6.8-6.4,10.6c-1.6,2.6-1.6,5.1,0,7.5
								c2.6,5.8,4.8,11.8,6.6,17.9c1.4,4.5,2.4,9.1,3.2,13.7c0.9,4.5,1.6,9.1,2.1,13.7c0.4,5.6,0.8,11.2,1.2,16.8
								c0-1.2,0-2.4,0.1-3.5c-0.7,3.2-1.4,6.3-2.1,9.5c-1.1,4.9,3,10.1,8.2,9.4c3.8-1.5,7.1-3.7,10-6.6
								C939.7,463.3,942.3,460.3,944.4,456.8z"/>
							<path opacity="0.1091" fill="#131313" d="M920.8,440.9c1.4-4,2.8-8.1,4.1-12.1c0-1.1-0.1-2.3-0.1-3.4
								c-0.4-2.8-0.7-5.6-1.1-8.3c-0.5-4.8-1.4-9.6-2.6-14.3c-1.5-6.5-3.5-12.8-6-19.1c-1.1-3.2-2.5-6.4-4.1-9.4
								c-2.1-4.8-4.6-9.3-7.3-13.8c-1.6-2.1-3.7-3.4-6.3-3.8c-3-0.1-5.3,1.2-6.8,3.9c-2.7,3.5-5,7.2-6.8,11.3
								c-1.7,2.6-1.8,5.3-0.2,7.8c2.9,5.3,5.5,10.7,7.6,16.3c1.6,4.2,3,8.4,4,12.8c1.2,4.3,2.2,8.6,2.9,13
								c0.7,5.3,1.4,10.6,2.1,15.8c0-1.1-0.1-2.3-0.1-3.4c-1.1,3.7-2.3,7.3-3.4,11c-1.4,4.6,3.9,9.8,8.3,9.4c3.4-1.2,6.4-3.1,9-5.7
								C916.8,446.8,919.1,444.1,920.8,440.9z"/>
							<path opacity="0.1091" fill="#131313" d="M926.6,400.8c0.4-5.9,0.2-11.8-0.4-17.7c-0.7-6.5-1.9-12.9-3.5-19.2
								c-1.8-6.5-4.1-12.9-6.7-19.1c-2.8-6.4-5.9-12.6-9.5-18.6c-1.2-2.7-3.3-4-6.3-3.8c-4.2,1.3-7.4,4-9.4,8.1
								c-4.4,6.2-8.3,12.7-11.8,19.4c-2.7,3.7-3.7,7.7-2.8,12.1c2.6,4.6,4.8,9.3,6.8,14.2c2,5,3.6,10.1,4.8,15.3
								c1.4,5.2,2.3,10.5,2.8,15.9c0.4,5,0.4,10.1,0.2,15.2c0.5,2.8,2.1,4.7,4.9,5.5c4,0.3,7.5-1,10.4-4.1c5.4-4,10.5-8.5,15.2-13.4
								C924.7,408.1,926.4,404.8,926.6,400.8z"/>
							<path opacity="0.1091" fill="#131313" d="M944.5,427.7c0.2-5.8,0-11.6-0.6-17.4c-0.7-6.4-1.9-12.8-3.6-19
								c-1.8-6.4-4.1-12.7-6.8-18.9c-2.8-6.3-5.9-12.4-9.5-18.3c-1.4-2.5-3.5-3.7-6.3-3.8c-3.7,0.6-6.5,2.7-8.3,6.3
								c-3.7,5-6.9,10.3-9.6,16c-2.3,3.2-2.9,6.7-1.7,10.3c2.3,5.3,4.3,10.8,6,16.4c1.6,5.6,2.9,11.2,3.8,16.9
								c1,5.6,1.6,11.3,1.9,17c0.1,5.1,0.1,10.3-0.1,15.4c0.5,2.8,2.1,4.7,4.9,5.5c3.9,0.4,7.3-0.8,10.1-3.8
								c5.3-3.9,10.2-8.2,14.7-12.9C942.7,434.8,944.4,431.7,944.5,427.7z"/>
							<path opacity="0.1091" fill="#131313" d="M947.7,396.7c0.1-6-0.1-11.9-0.5-17.8c-0.7-6.6-1.9-13.1-3.6-19.4
								c-1.9-6.5-4.2-12.9-7-19.2c-2.9-6.4-6.1-12.6-9.8-18.6c-1-3.1-3.1-4.4-6.3-3.8c-4.9,2.1-8.5,5.6-11,10.6
								c-5.4,7.8-10.4,15.9-14.8,24.3c-3.3,4.4-4.8,9.3-4.4,14.6c2.1,4.7,3.9,9.6,5.4,14.5c1.5,5,2.7,10.1,3.6,15.3
								c1,5.2,1.7,10.5,1.9,15.8c-0.1,5-0.1,9.9,0,14.9c0.1,3.2,1.7,5,4.9,5.5c5.1-0.4,9.5-2.6,13.1-6.5c7.2-5.6,14-11.6,20.4-18
								C943.9,405.8,946.6,401.7,947.7,396.7z"/>
							<path opacity="0.1091" fill="#131313" d="M907.6,427.4c1.2-3.9,2.3-7.9,3.5-11.8c-0.2-2.8-0.4-5.7-0.6-8.5
								c-0.6-5.9-1.7-11.6-3.1-17.3c-1.6-6.5-3.7-12.8-6.2-19c-1.2-3.2-2.6-6.3-4.2-9.3c-2.2-4.7-4.7-9.3-7.5-13.7
								c-1.6-2.1-3.7-3.4-6.3-3.8c-3-0.1-5.3,1.2-6.8,3.9c-2.7,3.5-5,7.3-6.8,11.3c-1.7,2.6-1.8,5.3-0.2,7.8c3.2,4.9,6,10,8.4,15.3
								c1.9,4,3.4,8.1,4.7,12.3c1.4,4.1,2.6,8.3,3.5,12.6c1.1,5,1.9,10.1,2.3,15.3c-0.1-1.1-0.2-2.1-0.3-3.2
								c-0.9,3.7-1.9,7.4-2.8,11c-1.1,4.3,4.1,9.6,8.3,9.4c3.1-1,5.8-2.6,8.1-4.9C904.3,432.8,906.2,430.4,907.6,427.4z"/>
							<path opacity="0.1091" fill="#131313" d="M930.1,458.2c0.8-3.6,1.5-7.2,2.3-10.8c-0.3-3-0.6-6-0.9-9.1
								c-0.7-5.9-1.7-11.7-3.1-17.5c-1.5-6.5-3.7-12.8-6.3-19c-1.2-3.2-2.6-6.3-4.3-9.3c-2.2-4.7-4.7-9.3-7.6-13.6
								c-1.7-1.9-3.8-3.2-6.2-4c-2.3-0.7-4.3-0.1-5.8,1.9c-2.1,2.2-3.8,4.8-4.9,7.6c-1.2,2.2-0.9,4.2,0.6,6
								c2.4,4.1,4.5,8.4,6.3,12.9c2,4.3,3.7,8.7,5,13.3c-0.3-1-0.6-2-0.8-2.9c1.1,4,2.2,8,3.3,12c-0.2-1-0.5-2-0.7-3
								c0.8,4,1.7,8.1,2.5,12.1c-0.2-1-0.4-2-0.6-3c0.6,4.4,1.2,8.9,1.8,13.3c1.1,4.8,0.7,9.6-1.3,14.4c0,2.4,0.8,4.4,2.5,6.1
								c1.5,1.9,3.4,3,5.8,3.3c2.8-0.7,5.2-2.1,7.2-4.1C927.3,463.1,929,460.9,930.1,458.2z"/>
							<path opacity="0.1091" fill="#131313" d="M959.3,423.4c0.1-5.9-0.1-11.7-0.6-17.6c-0.7-6.4-2-12.8-3.8-19
								c-1.9-6.4-4.3-12.6-7-18.7c-2.8-6.2-6.1-12.3-9.7-18.1c-1.2-2.7-3.3-4-6.3-3.8c-4.2,1.2-7.4,3.9-9.4,8.1
								c-4.4,6.2-8.3,12.6-11.8,19.4c-2.8,3.7-3.7,7.7-2.9,12.1c2,5.4,3.6,10.9,4.9,16.5c1.3,5.6,2.2,11.2,2.9,16.8
								c0.7,5.6,1.2,11.2,1.3,16.8c-0.2,5.1-0.4,10.2-0.3,15.3c0.2,3.1,1.8,4.9,4.9,5.5c4.7-0.1,8.8-2,12.2-5.6
								c6.6-5,12.8-10.4,18.6-16.3C956.2,431.8,958.6,428,959.3,423.4z"/>
							<path opacity="0.1091" fill="#131313" d="M917.3,411.8c0.7-5.9,0.6-11.7-0.3-17.6c-0.7-6.4-1.9-12.8-3.6-19.1
								c-1.8-6.5-4.1-12.8-6.8-18.9c-2.8-6.3-5.9-12.5-9.5-18.4c-1.4-2.4-3.5-3.7-6.3-3.8c-3.7,0.6-6.4,2.6-8.1,6.1
								c-3.6,4.9-6.7,10.1-9.4,15.6c-2.3,3.2-2.8,6.5-1.6,10.1c2.8,4.7,5.3,9.6,7.5,14.6c2.1,5.1,3.9,10.3,5.3,15.7
								c1.5,5.3,2.5,10.8,3.1,16.3c0.8,5.2,0.8,10.3,0.2,15.5c0.7,2.6,2.3,4.5,4.9,5.5c3.3,0.8,6.3-0.1,8.8-2.7
								c4.4-3.1,8.4-6.7,12.1-10.7C916.4,418,917.7,415.2,917.3,411.8z"/>
							<path opacity="0.1091" fill="#131313" d="M935.7,409.9c0.3-5.9,0.2-11.8-0.4-17.6c-0.7-6.5-1.9-12.9-3.6-19.2
								c-1.8-6.5-4.1-12.8-6.8-19c-2.8-6.3-5.9-12.5-9.5-18.4c-1.2-2.7-3.3-4-6.3-3.8c-4.2,1.2-7.3,3.9-9.3,8
								c-4.4,6.1-8.2,12.5-11.7,19.2c-2.7,3.7-3.7,7.7-2.8,12c2.4,4.8,4.4,9.8,6.2,14.9c1.7,5.2,3.1,10.4,4.2,15.8
								c1.2,5.3,1.9,10.7,2.3,16.2c0.2,5.1,0.2,10.1,0,15.2c0.4,2.9,2,4.8,4.9,5.5c4.2,0.2,7.8-1.3,10.9-4.5
								c5.7-4.3,11.1-9,16.1-14.2C933.4,417.5,935.4,414.1,935.7,409.9z"/>
							<path opacity="0.1091" fill="#131313" d="M955.9,439.1c0.2-5.8,0-11.6-0.6-17.4c-0.7-6.4-1.9-12.8-3.6-19
								c-1.9-6.4-4.1-12.7-6.8-18.8c-2.7-6.3-5.9-12.4-9.4-18.3c-1.4-2.4-3.5-3.7-6.3-3.8c-3.6,0.5-6.2,2.4-8,5.8
								c-3.5,4.7-6.5,9.7-9,14.9c-2.2,3.1-2.7,6.3-1.4,9.7c2.2,5.7,4,11.5,5.4,17.4c1.4,5.8,2.5,11.7,3.2,17.7
								c0.8,5.8,1.2,11.6,1.4,17.5c-0.1,5.2-0.2,10.4-0.3,15.6c0.5,2.8,2.1,4.7,4.8,5.5c4,0.4,7.4-0.9,10.3-3.9
								c5.4-4,10.4-8.3,15-13.1C954,446.3,955.7,443.1,955.9,439.1z"/>
							<path opacity="0.1091" fill="#131313" d="M910.7,439.3c0.8-3.6,1.6-7.2,2.4-10.8c-0.3-3-0.6-6.1-0.9-9.1
								c-0.7-6-1.6-11.8-2.9-17.7c-1.5-6.6-3.5-13-6-19.3c-1.2-3.3-2.6-6.4-4.1-9.5c-2.2-4.8-4.6-9.5-7.4-13.9c-1.6-2-3.7-3.3-6.2-4
								c-2.6-0.5-4.7,0.4-6.2,2.6c-2.4,2.7-4.3,5.7-5.8,9c-1.4,2.4-1.3,4.6,0.2,6.7c1.5,2.7,3,5.3,4.6,8c2.2,4.1,4.1,8.3,5.7,12.6
								c1.2,2.8,2.2,5.7,3,8.7c1.5,4.3,2.6,8.8,3.4,13.3c-0.2-1-0.4-2-0.6-3c0.7,4.4,1.4,8.7,2,13.1c1.2,4.8,0.8,9.6-1.2,14.4
								c0,2.4,0.9,4.4,2.6,6.1c1.5,1.9,3.4,3,5.7,3.3c2.7-0.7,5-2,7-4C908.1,444.1,909.7,441.9,910.7,439.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M951.3,441.5c-0.1-4.1-1.5-7.4-4.2-9.9c-1.9-2.1-3.9-4-6.1-5.7
								c-5-4.2-10.2-8.1-15.8-11.4c-6-3.5-12.5-6.7-19.1-8.8c-2.8-0.8-5.6-1.6-8.4-2.4c-5.8-1.4-11.6-2.3-17.5-2.7
								c-2.6-0.1-4.9,0.6-7.1,1.9c-2.1,1.4-2.9,3.3-2.4,5.8c0.3,3.2,1.1,6.4,2.5,9.3c0.8,2.4,2.5,3.7,5,3.8
								c4.9,0.7,9.8,1.4,14.7,2.8c5.9,1.7,11.6,4,17,6.9c4.3,2.3,8.2,4.9,12,7.9c4.3,3.4,8.4,7,12.5,10.5c-0.7-0.8-1.4-1.5-2.1-2.3
								c1,2.7,2,5.5,3.1,8.2c1.3,2,3.2,3.2,5.6,3.6c2.3,0.7,4.5,0.4,6.6-0.8c1.9-2.4,3-5,3.5-8C951.9,447.4,952,444.5,951.3,441.5z"
								/>
							<path opacity="0.1091" fill="#131313" d="M928.4,427.1c-0.1-4-1.3-7.5-3.7-10.5c-1.5-2.2-3.3-4-5.6-5.4
								c-1.8-1.6-3.7-3.2-5.5-4.9c-3-2.5-6.3-4.7-9.7-6.7c-5.9-3.5-12.2-6.7-18.7-8.9c-2.7-0.8-5.5-1.6-8.2-2.4
								c-5.7-1.4-11.4-2.4-17.2-3c-2.7-0.4-5,0.4-7,2.3c-2,2.7-2.4,5.6-1.1,8.9c1.1,5.1,2.7,10.1,4.9,14.9c0.9,3.4,3,5.6,6.2,6.5
								c4.2,0.6,8.3,1.1,12.4,2.2c5.1,1.3,10,3.2,14.7,5.5c3.8,1.9,7.3,4.1,10.6,6.6c3.7,2.8,7,6,10.4,9.1c-0.7-0.9-1.4-1.9-2.1-2.8
								c0.8,2.6,1.6,5.2,2.4,7.8c1.5,4.8,8.8,5.9,12.2,2.8c2.1-3.3,3.5-6.9,4.1-10.7C928.5,434.8,928.8,431,928.4,427.1z"/>
							<path opacity="0.1091" fill="#131313" d="M930.3,385.6c-4.3-5.6-8.6-10.8-14.1-15.3c-6.2-5.1-13.7-9.6-21-12.9
								c-5.4-2.4-10.8-4.3-16.5-5.8c-1.3-0.3-2.6-0.6-3.9-0.9c-5.8-1.2-11.7-2-17.7-2.3c-3.1,1.5-4.5,4.1-4.2,7.8
								c0.6,5.5,1.8,10.9,3.5,16.2c1.7,6.7,3.8,13.2,6.3,19.6c1.1,5.3,3.7,9.4,7.9,12.5c8.2,2.8,16.4,6.2,23.4,11.3
								c2.9,2.1,5.7,4.4,8.2,6.9c3.6,3.6,6.3,8,9.4,12c2,2.4,4.4,3,7.2,1.6c3.8-3,6-7,6.7-12.1c2.4-8.2,4.3-16.4,5.6-24.9
								C932.7,394.5,932.4,390,930.3,385.6z"/>
							<path opacity="0.1091" fill="#131313" d="M948.1,414.2c-1.2-1.6-2.5-3.2-3.7-4.8c-2.2-2.6-4.7-5-7.3-7.2
								c-4.8-4.3-10.1-8.2-15.8-11.3c-4.9-2.6-10-5-15.2-6.9c-8.1-3.1-16.7-4.4-25.3-5.6c-2.7-0.4-5,0.4-7,2.3c-2,2.8-2.4,5.8-1,9.2
								c1.1,5.3,2.8,10.5,5,15.4c0.9,3.5,3,5.8,6.2,6.8c5.3,1.6,10.4,3.3,15.4,5.7c4.8,2.3,9.6,5,13.8,8.2c3.4,2.6,6.6,5.4,9.5,8.4
								c3.8,3.9,7,8.5,10.1,13.1c2.1,1.8,4.5,2.4,7.2,1.6c3.3-1.6,5.2-4.3,5.8-8c1.8-5.6,3.1-11.3,3.9-17.1
								C950.9,420.3,950.3,417,948.1,414.2z"/>
							<path opacity="0.1091" fill="#131313" d="M949.8,381.5c-2.7-3.5-5.6-6.9-8.7-10.1c-1.9-1.6-3.8-3.3-5.7-4.9
								c-5.8-4.7-12.5-8.1-19.2-11.2c-3.9-1.8-7.9-3.2-11.9-4.5c-9-2.9-18.4-3.5-27.7-4c-3.1,1.5-4.5,4-4.2,7.5
								c0.6,5.3,1.6,10.4,3.3,15.5c1.6,6.4,3.6,12.6,6,18.7c1,5.1,3.6,9,7.7,11.9c8.4,4.2,16.7,9.2,23.6,15.6
								c2.8,2.6,5.4,5.3,7.8,8.2c1.8,2.1,3.4,4.3,4.7,6.7c1.1,2.2,2.6,4.2,4.3,6c1.9,2.7,4.3,3.2,7.2,1.6c4-3.6,6.3-8.2,7.1-13.7
								c2.7-9.2,4.8-18.6,6.4-28C951.9,391.6,951.8,386.5,949.8,381.5z"/>
							<path opacity="0.1091" fill="#131313" d="M914.4,412.4c-1.3-2.6-2.8-5-4.4-7.5c-1.4-2.4-3.2-4.4-5.6-6
								c-1.8-1.7-3.7-3.3-5.5-5c-3.1-2.5-6.3-4.8-9.8-6.7c-5.9-3.6-12.3-6.8-18.8-9c-2.8-0.8-5.5-1.7-8.3-2.5
								c-5.7-1.5-11.5-2.5-17.4-3.1c-2.8-0.7-5.1,0.1-7,2.3c-1.9,3.5-2.1,7.3-0.4,11.2c1.5,6.6,3.6,13,6.2,19.2
								c0.9,4.1,3.2,7.1,6.9,8.8c3.7,0.3,7.4,0.5,11.1,1.2c4.7,0.9,9.3,2.2,13.7,4.1c3.6,1.5,7,3.3,10.2,5.5
								c3.7,2.6,6.9,5.7,10.2,8.7c-0.7-1-1.3-2.1-2-3.1c1,1.7,1.9,3.3,2.9,5c0.8,2.6,2.6,4.2,5.3,4.8c2.7,0.6,5,0,6.9-2.1
								c2.2-3.9,3.7-8.1,4.5-12.6C914.3,421.3,914.7,416.9,914.4,412.4z"/>
							<path opacity="0.1091" fill="#131313" d="M935.6,443.6c0.1-4.3-1.3-7.6-4.2-9.8c-1.9-1.8-4-3.1-6.5-4.2
								c-4.2-2.8-8-6.4-12.2-9.2c-6.9-4.7-14.6-8.7-22.5-11.4c-2.8-0.9-5.6-1.7-8.4-2.6c-5.8-1.5-11.6-2.6-17.6-3.2
								c-2.6,0-4.9,0.6-7.1,1.9c-2.1,1.3-2.9,3.2-2.4,5.7c0.3,3.2,1.1,6.2,2.5,9.1c0.8,2.4,2.5,3.6,5,3.7c7.4,0.7,14.6,1.6,21.8,4
								c2.3,0.8,4.6,1.7,6.9,2.5c4.5,1.8,8.7,4.1,12.7,6.8c-0.8-0.6-1.6-1.2-2.4-1.7c3.6,2.7,7.1,5.4,10.7,8.1
								c2.3,2,4.8,3.5,7.7,4.5c-0.7-0.6-1.5-1.3-2.2-1.9c1,2.7,2.1,5.4,3.1,8.1c1.5,1.7,3.4,2.8,5.7,3.2c2.2,0.7,4.4,0.5,6.5-0.4
								c1.7-1.7,2.7-3.8,3.1-6.3C936.4,448.2,936.4,445.9,935.6,443.6z"/>
							<path opacity="0.1091" fill="#131313" d="M962,410.8c-1.2-1.6-2.5-3.3-3.7-4.9c-2.2-2.7-4.6-5.1-7.3-7.3
								c-4.8-4.3-10.2-8.1-15.8-11.2c-4.9-2.6-9.9-4.9-15.1-6.9c-8.1-3-16.7-4.4-25.2-5.7c-2.7-0.4-5,0.4-7,2.3
								c-2,2.8-2.4,5.8-1.1,9.2c1.1,5.3,2.8,10.4,5,15.4c0.9,3.5,3,5.8,6.2,6.8c2.2,0.9,4.3,1.7,6.5,2.6c3.2,1.4,6.2,3,9.1,4.9
								c4.7,2.9,9.4,6,13.5,9.7c3.2,2.9,6.3,6,9.1,9.3c3.6,4.2,6.6,9.1,9.7,13.7c2.1,2,4.5,2.6,7.2,1.7c3.4-2,5.5-5.1,6-9.2
								c2-6.3,3.5-12.8,4.5-19.4C964.7,417.8,964.2,414.1,962,410.8z"/>
							<path opacity="0.1091" fill="#131313" d="M921,398.3c-1.2-1.7-2.5-3.4-3.7-5.1c-2.2-2.8-4.6-5.3-7.3-7.6
								c-4.8-4.4-10.2-8.4-16-11.5c-4.9-2.7-10.1-5.1-15.4-7.2c-8.2-3.2-17-4.5-25.7-5.7c-2.9-1-5.2-0.3-7,2.2
								c-1.8,4.4-1.7,8.8,0.2,13.3c1.9,8,4.4,15.8,7.4,23.4c1,4.8,3.5,8.5,7.5,11c8.5,1.8,17.2,4.3,24.7,8.9
								c3.1,1.9,6.1,4.1,8.9,6.5c3.9,3.4,6.8,7.7,9.8,12c2,2.1,4.4,2.6,7.2,1.6c3.5-2.2,5.6-5.5,6.1-9.8c2.1-6.7,3.6-13.5,4.6-20.4
								C923.7,405.7,923.2,401.9,921,398.3z"/>
							<path opacity="0.1091" fill="#131313" d="M939.3,396c-3.4-4.5-6.9-8.8-11.1-12.6c-4.9-4.4-10.3-8.2-16-11.3
								c-4.9-2.6-10.1-5-15.3-6.9c-8.2-3-16.9-4.1-25.4-5.2c-2.9-0.9-5.2-0.2-7,2.2c-1.8,4.1-1.9,8.4,0,12.7c1.8,7.6,4.1,15,7,22.2
								c1,4.6,3.4,8.1,7.3,10.4c8.8,3.2,17.6,7.1,25.1,13c3,2.3,5.8,4.9,8.5,7.6c3.6,3.8,6.4,8.4,9.4,12.6c2,2.3,4.4,2.8,7.2,1.6
								c3.7-2.6,5.8-6.3,6.4-11c2.3-7.5,4-15.1,5.2-22.8C941.8,404.2,941.5,400,939.3,396z"/>
							<path opacity="0.1091" fill="#131313" d="M961.1,430.1c-0.7-3.3-2.1-6.3-4-8.9c-1.4-2.3-3.3-4.2-5.6-5.7
								c-1.8-1.7-3.6-3.4-5.5-5.1c-3-2.6-6.3-4.9-9.7-6.9c-5.9-3.7-12.1-7-18.7-9.3c-2.7-0.9-5.5-1.8-8.2-2.7
								c-5.7-1.6-11.5-2.8-17.4-3.5c-2.6-0.1-5,0.6-7,2.3c-2.1,2-2.6,4.4-1.6,7.3c0.8,4.1,2,8,3.8,11.7c0.9,2.9,2.8,4.5,5.6,4.9
								c4.7,1.4,9.4,2.8,13.9,4.8c5.4,2.4,10.6,5.4,15.4,8.8c3.8,2.7,7.3,5.6,10.5,8.9c3.5,3.5,6.7,7.4,10,11.1c-0.7-1-1.4-2-2-3
								c0.9,2.2,1.7,4.3,2.6,6.5c1.9,4.9,8.4,6.5,12.2,2.8c2.2-3.6,3.6-7.5,4.4-11.7C961,438.4,961.4,434.3,961.1,430.1z"/>
							<path opacity="0.1091" fill="#131313" d="M916.1,426.3c0-4.2-1.3-7.7-3.9-10.5c-1.6-2-3.4-3.8-5.5-5.2
								c-4.9-4.2-9.8-8-15.4-11.2c-5.9-3.5-12.3-6.5-18.9-8.6c-2.8-0.8-5.5-1.6-8.3-2.4c-5.7-1.4-11.5-2.3-17.3-2.9
								c-2.7-0.4-5,0.4-7,2.3c-2,2.7-2.4,5.7-1.1,9c1.1,5.2,2.8,10.2,4.9,15c0.9,3.4,3,5.6,6.2,6.6c4.1,0.2,8.2,0.4,12.3,1.1
								c5.1,0.9,10.2,2.3,15,4.2c3.9,1.6,7.5,3.5,11,5.8c3.7,2.5,7.1,5.4,10.7,8.1c-0.7-0.9-1.4-1.7-2.1-2.6
								c0.9,2.7,1.8,5.4,2.7,8.1c1.2,2.2,3,3.5,5.5,4c2.4,0.7,4.7,0.3,6.7-1.2c2-2.8,3.2-6,3.8-9.4
								C916.5,433.1,916.7,429.7,916.1,426.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M835.3,373.1c-6.7-3.1-13.6-5.5-20.8-7.2c-6.5-1.7-13.1-3.5-19.6-5.2
								c-4-1.1-10.1,2.1-10.8,6.4c0.2,2.9,1.2,5.7,2.9,8.1c1.4,2.7,3.3,4.8,5.7,6.4c6.5,1.8,13,3.5,19.5,5.1
								c6.9,1.5,13.7,3.6,20.3,6.3c2.3,0.4,4.5,0,6.4-1.4c2.1-1.1,3.6-2.7,4.3-5c-0.1-2.8-1-5.3-2.6-7.6
								C839.5,376.6,837.7,374.6,835.3,373.1z"/>
							<path opacity="0.1091" fill="#131313" d="M809.2,355c-12.8-3.1-25.5-6.4-38.3-9.6c-2.8,0.2-4.9,1.4-6.3,3.9
								c-1.2,3.4-0.7,6.7,1.5,9.8c2.6,5.3,5.7,10.3,9.2,14.9c1.8,3.4,4.4,5.3,8,5.8c12.5,3.2,25,6.4,37.5,9.5
								c2.8-0.1,4.9-1.4,6.3-3.8c1.3-3.4,0.9-6.6-1.3-9.7c-2.5-5.3-5.4-10.2-8.8-14.9C815.4,357.4,812.8,355.5,809.2,355z"/>
							<path opacity="0.1091" fill="#131313" d="M810.2,317.8c-12.9-3.1-25.7-6.5-38.5-9.8c-3.2-0.5-5.3,0.7-6.3,3.8
								c-0.3,5.3,1.1,10.2,4.5,14.6c4.5,8.4,9.5,16.5,14.9,24.3c2.4,5,6.1,8.5,11,10.6c6.5,1.6,12.9,3.3,19.3,5.1
								c5.9,1.7,11.9,3.2,18,4.4c3.2,0.6,5.3-0.7,6.3-3.7c0.5-5.3-0.9-10.1-4.1-14.5c-4.3-8.4-9.1-16.4-14.3-24.2
								C818.6,323.5,815.1,319.9,810.2,317.8z"/>
							<path opacity="0.1091" fill="#131313" d="M834.1,348.8c-14-3.9-28-7.5-42.1-11.1c-2.7,0.2-4.8,1.5-6.3,3.8
								c-1.3,3.1-1,6.2,1.1,9.1c2.3,4.9,5.1,9.4,8.4,13.7c1.7,3.2,4.2,4.9,7.6,5.1c12.6,3.2,25.2,6.4,37.7,9.8
								c2.7-0.2,4.8-1.5,6.3-3.7c1.2-2.2,1.2-4.3,0-6.3c-1-3.5-2.5-6.7-4.5-9.7C840.2,355.5,837.4,351.9,834.1,348.8z"/>
							<path opacity="0.1091" fill="#131313" d="M830.3,317.2c-12.9-3.2-25.7-6.6-38.5-9.9c-3.1-0.3-5.2,1-6.3,3.8
								c-0.7,4.7,0.5,9,3.5,13c3.8,7.4,8.2,14.4,12.9,21.2c2.2,4.5,5.5,7.5,10,9c12.5,3.2,24.9,6.6,37.4,9.6c3,0.3,5.2-0.9,6.3-3.7
								c0.8-4.6-0.3-8.9-3.2-12.9c-3.7-7.3-7.8-14.4-12.4-21.1C838,321.8,834.7,318.8,830.3,317.2z"/>
							<path opacity="0.1091" fill="#131313" d="M794,341.5c-12.8-3.2-25.7-6.6-38.5-9.9c-3-0.2-5.1,1.1-6.3,3.9
								c-0.7,4.5,0.3,8.7,3.2,12.5c3.7,7,7.8,13.7,12.4,20.2c2.1,4.3,5.3,7.1,9.7,8.5c12.5,3.2,24.9,6.6,37.4,9.6
								c3,0.3,5.1-1,6.3-3.8c0.8-4.4-0.1-8.6-2.9-12.4c-3.5-7-7.5-13.7-11.9-20.1C801.5,345.8,798.3,342.9,794,341.5z"/>
							<path opacity="0.1091" fill="#131313" d="M818.8,374.8c-6.7-3-13.5-5.3-20.6-6.9c-6.5-1.7-13.1-3.5-19.6-5.2
								c-4.2-1.1-10.1,1.7-10.8,6.4c0.4,3.2,1.5,6.1,3.3,8.8c1.5,2.9,3.5,5.2,6.2,7.1c6.5,1.8,13,3.5,19.5,5.1
								c6.9,1.5,13.6,3.6,20.2,6.2c4,1.3,10.1-2.1,10.8-6.4c-0.3-3-1.3-5.8-3-8.4C823.3,378.8,821.3,376.6,818.8,374.8z"/>
							<path opacity="0.1091" fill="#131313" d="M848.5,346.2c-6.4-2.9-13-5.1-19.9-6.5c-6.5-1.6-12.9-3.2-19.4-4.9
								c-2.4-1.2-4.8-1.1-7.1,0.4c-2.4,1.4-3.6,3.4-3.6,6.1c1,4.2,2.7,8.1,5.2,11.7c2.1,3.8,4.8,7.1,8,9.9
								c6.4,1.7,12.9,3.3,19.3,4.9c6.5,1.4,12.9,3.4,19.1,6c2.4,1.2,4.8,1.1,7-0.4c2.4-1.3,3.6-3.3,3.7-6c-0.9-4.1-2.5-7.9-4.8-11.4
								C854.2,352.2,851.7,348.9,848.5,346.2z"/>
							<path opacity="0.1091" fill="#131313" d="M802.2,329.7c-12.9-3.1-25.7-6.5-38.5-9.8c-3.2-0.4-5.2,0.8-6.3,3.9
								c-0.5,5.1,0.9,9.7,4.1,14c4.2,8,9,15.6,14.1,23c2.3,4.8,5.9,8.1,10.6,10c6.5,1.6,12.9,3.3,19.3,5.1c5.9,1.7,11.9,3.2,18,4.4
								c3.1,0.5,5.2-0.8,6.3-3.8c0.6-5-0.7-9.6-3.8-13.9c-4-7.9-8.6-15.6-13.6-22.9C810.4,334.9,806.9,331.6,802.2,329.7z"/>
							<path opacity="0.1091" fill="#131313" d="M821.2,329.3c-12.8-3.2-25.6-6.6-38.5-9.9c-3-0.2-5.1,1.1-6.3,3.8
								c-0.8,4.5,0.3,8.6,3.1,12.4c3.6,7,7.7,13.7,12.3,20c2.1,4.3,5.3,7.1,9.6,8.4c12.5,3.2,24.9,6.6,37.4,9.6c3,0.3,5.1-1,6.3-3.7
								c0.9-4.4-0.1-8.5-2.8-12.3c-3.5-6.9-7.4-13.6-11.8-20C828.6,333.5,825.5,330.7,821.2,329.3z"/>
							<path opacity="0.1091" fill="#131313" d="M846.4,361.7c-6.7-4.1-13.9-7-21.4-8.8c-6.4-1.8-12.8-3.5-19.2-5
								c-2.4-0.8-4.6-0.4-6.7,1c-2.2,1.2-3.5,3.1-4,5.5c0.6,3.4,1.8,6.6,3.8,9.4c1.7,3.1,3.9,5.6,6.7,7.7c6.3,1.6,12.6,3.2,18.8,4.8
								c7.2,1.6,14.1,4.1,20.6,7.7c4,1.7,10.2-2.1,10.8-6.4c-0.4-3.2-1.5-6.1-3.3-8.8C851,366,849,363.6,846.4,361.7z"/>
							<path opacity="0.1091" fill="#131313" d="M796.2,354.8c-12.8-2.9-25.5-6.2-38.2-9.4c-2.8,0.1-4.9,1.4-6.3,3.9
								c-1.1,3.7-0.5,7.1,1.9,10.3c2.8,5.6,6.1,11,9.9,16.1c1.8,3.6,4.6,5.7,8.4,6.3c12.5,3.2,24.9,6.5,37.4,9.4
								c2.8,0,4.9-1.3,6.3-3.8c1.2-3.6,0.6-7-1.7-10.3c-2.7-5.6-5.9-11-9.5-16.1C802.7,357.7,799.9,355.5,796.2,354.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M720.7,410.7c0-0.9-0.1-1.8-0.1-2.7c-0.1,3.3-0.2,6.5-0.4,9.8
								c0.7-2.7,1.5-5.3,2.2-8c-1.2,2.7-2.4,5.4-3.7,8.1c0.2-0.2,0.5-0.4,0.7-0.5c2.2-2.2,3.8-4.9,4.8-7.9c1.3-2.9,1.9-6,1.7-9.1
								c-1.3-4.1-8-6.7-11.7-4.5c-2.3,1.1-4.4,2.6-6.2,4.4c-1.2,1.7-2,3.7-2.2,5.8c-0.5,2.1-0.9,4.2-0.9,6.3
								c-0.1,2.8,0.2,5.6,0.9,8.3c0.2,2.5,1.5,4.2,3.9,4.9c2.5,0.7,4.9,0.6,7.4-0.2c2.4-0.8,3.6-2.5,3.7-5.1
								C721.4,417,721.4,413.8,720.7,410.7z"/>
							<path opacity="0.1091" fill="#131313" d="M698.1,394.3c-0.4-0.8-0.8-1.6-1.1-2.5c0.4,1.7,0.9,3.4,1.3,5.1
								c-0.1-0.8-0.1-1.5-0.2-2.3c0,2.4,0.1,4.8,0.1,7.2c0.2-3.1,0.3-6.2,0.5-9.3c-0.6,4.7-1.2,9.4-1.8,14.1
								c0.6-2.7,1.3-5.5,1.9-8.2c-0.7,2.2-1.3,4.3-2,6.5c1-2.5,2-5,3-7.5c0.7-2.1,1.3-4.2,2-6.3c0.2-1.6,0.3-3.2,0.5-4.8
								c-0.4-2-1.5-3.3-3.2-4c-1-0.9-2.1-1.4-3.4-1.3c-1.2-0.5-2.4-0.4-3.5,0.3c-1.5,0.2-2.5,1-3,2.5c-7.6,9.7-8.6,21.7-2.8,36.1
								c1.5,2.2,3.7,3.2,6.4,3.1c2.7,0,4.8-1.2,6.1-3.5c1.2-4.1,1.6-8.3,1.2-12.6C700.3,402.5,699.5,398.4,698.1,394.3z"/>
							<path opacity="0.1091" fill="#131313" d="M698.1,353.4c-0.3-0.5-0.7-1.1-1-1.6c1.5,13.8,3,27.6,4.5,41.4
								c0.7-13.1,1.5-26.2,2.2-39.4c-1.8,11.1-3.7,22.3-5.5,33.4c2.3-8.4,4.5-16.7,6.8-25.1c0.5-2.8,0.9-5.6,1.4-8.4
								c-0.4-1.6-0.8-3.1-1.2-4.7c-1.3-2.1-2.9-2.9-5-2.4c-1.6-0.3-2.9,0.2-3.8,1.6c-2,1.2-3.2,3-3.7,5.3c-5,8.6-5.6,18.2-2,28.8
								c-6.3,12.1-5.6,21.1,2.1,27c2.5,2,5,1.9,7.4-0.3c2.9-4.3,4-9.1,3.3-14.4c0.2-9.2-0.1-18.3-1-27.4
								C702.8,362.1,701.3,357.4,698.1,353.4z"/>
							<path opacity="0.1091" fill="#131313" d="M710.2,409.7c2.6-8.5,5.2-16.9,7.8-25.4c0.4,2,0.9,4,1.3,6c-0.1-0.5-0.1-1-0.2-1.4
								c0,2.9,0.1,5.7,0.1,8.6c0.2-3.3,0.4-6.6,0.6-9.9c-0.7,5.7-1.4,11.4-2.1,17.1c1.7-6.1,3.4-12.2,5.1-18.3
								c1.5-2.8,1.5-5.5,0.2-7.9c-0.4-2-1.7-3.4-3.8-4c-1.3-0.1-2.5-0.2-3.8-0.4c-3.4,1.1-5.7,3.5-6.7,7.1c4.4-7,4.4-7,0,0
								c-2.5,5.2-4.5,10.7-6,16.3c-1.6,3.4-1.4,6.7,0.5,9.8C705.1,409.2,707.5,410.1,710.2,409.7z"/>
							<path opacity="0.1091" fill="#131313" d="M717.2,350.6c0.7,0.5,1.3,1,2,1.6c0,12.8,0,25.6,0,38.4c0.6-9.9,1.3-19.8,1.9-29.7
								c-0.9,9.4-1.9,18.9-2.8,28.3c1.4-9.7,2.8-19.5,4.2-29.2c-1.8,9.1-3.7,18.3-5.5,27.4c2.5-9.3,5-18.6,7.6-27.9
								c0.6-3.2,1.2-6.4,1.8-9.6c-0.3-1.7-0.7-3.5-1-5.2c-1.2-2.3-2.9-3.1-5-2.4c-1.7-0.3-3,0.4-3.9,2c-2.1,1.5-3.4,3.6-4,6.3
								c-5,9.2-5.8,19.3-2.4,30.4c-7.3,13.2-6.6,22.4,1.9,27.5c2.5,2,5,1.9,7.4-0.3c2.9-4.4,4-9.4,3.3-14.8c0.2-9.4-0.1-18.9-1-28.3
								C721.8,359.6,720.4,354.8,717.2,350.6z"/>
							<path opacity="0.1091" fill="#131313" d="M683.5,380.5c-0.4-0.6-0.7-1.2-1.1-1.7c0.7,5.2,1.3,10.3,2,15.5c0-4,0-7.9-0.1-11.9
								c-0.6,5.5-1.3,11.1-1.9,16.6c0.7-3.5,1.3-6.9,2-10.4c-0.7,2.5-1.4,5-2.1,7.5c1.1-3.1,2.1-6.3,3.2-9.4
								c1.4-3.1,2.2-6.3,2.5-9.6c0.7-2.1,0.3-4.1-1.3-5.8c-1.1-0.7-2.1-1.4-3.2-2.1c-2.5-1-4.9-0.7-7,0.9
								c-12,16.7-13.4,30.7-4.2,42.2c1.3,2.6,3.5,4,6.4,3.9c2.9-0.2,5-1.7,6.1-4.4c1.1-5.2,1.5-10.4,1.1-15.8
								C685.8,390.8,685,385.6,683.5,380.5z"/>
							<path opacity="0.1091" fill="#131313" d="M705.9,416.2c-0.3-0.9-0.7-1.9-1-2.8c0.2,1.4,0.3,2.8,0.5,4.1
								c-0.2-2.3-0.4-4.5-0.6-6.8c0.1,1.4,0.2,2.8,0.3,4.2c0.1-0.8,0.2-1.6,0.3-2.4c-0.9,2.3-1.7,4.5-2.6,6.8c0.7-1,1.6-1.8,2.7-2.3
								c1.9-1.6,3.3-3.6,4-6.1c1-2.3,1.3-4.7,0.8-7.2c-1.2-1.9-3-3.2-5.3-3.9c-2.1-0.9-4.3-1.1-6.5-0.4c-2.3,1.1-4.4,2.6-6.3,4.3
								c-1.2,1.4-1.9,3-2.1,4.8c-0.5,2-0.8,3.9-0.9,6c0,3.7,0.6,7.2,2,10.6c0.4,2.3,1.7,3.7,4,4.1c2.5,0.5,4.9,0.4,7.4-0.3
								c2.2-0.6,3.5-2.1,3.7-4.4C706.7,421.7,706.6,418.9,705.9,416.2z"/>
							<path opacity="0.1091" fill="#131313" d="M733.1,382.5c-0.4-1-0.7-1.9-1.1-2.9c0.3,5.3,0.7,10.5,1,15.8
								c0.2-3.5,0.5-7.1,0.7-10.6c-0.7,6.3-1.5,12.6-2.2,18.8c3-8.8,5.2-17.8,6.5-27c-0.1-3.1-1.6-5.2-4.4-6.4
								c-2.9-0.9-5.4-0.2-7.4,2.1c-4.7,8.4-4.7,8.2,0-0.6c-2.5,1.2-4,3.1-4.6,5.8c-1.9,4-3.2,8.1-3.9,12.5c-0.6,3.2-1.2,6.3-1.7,9.5
								c-1.2,3.9-0.7,7.4,1.5,10.6c2.3,2.2,4.6,4.3,6.9,6.5c2.5,1.3,5,1.2,7.4-0.3c2.1-1.6,3.1-3.8,3-6.4c0.8-4,1-8.1,0.6-12.3
								C735.3,392.6,734.6,387.5,733.1,382.5z"/>
							<path opacity="0.1091" fill="#131313" d="M692.2,370.4c-0.4-0.6-0.8-1.1-1.2-1.7c0.5,2.7,0.9,5.4,1.4,8.1
								c-0.1-0.5-0.1-1-0.2-1.5c-0.2,7.6-0.5,15.1-0.7,22.7c0.9-8.3,1.8-16.7,2.7-25c-1.4,6.8-2.9,13.5-4.3,20.3
								c3.2-8,5.4-16.1,6.5-24.5c0.9-2.6,0.7-5.1-0.8-7.4c-1.3-1.8-3-2.6-5-2.3c-2.1-0.5-4,0.3-5.4,2.2c-1.1,2-2.3,4.1-3.4,6.1
								c-5.9,11.8-7.3,24.6-4.2,38.2c1.8,3.3,3.9,6.2,6.4,9c2.5,1.7,5,1.6,7.4-0.3c2.2-2.3,3.1-5.1,2.9-8.2
								c0.8-5.2,0.9-10.5,0.4-15.8C694.5,383.5,693.7,376.9,692.2,370.4z"/>
							<path opacity="0.1091" fill="#131313" d="M710.3,368.6c-1.3-1.9-2.5-3.7-3.8-5.6c1.3,4.1,2.7,8.2,4,12.4
								c-0.1-0.5-0.1-1-0.2-1.5c-0.3,7.9-0.5,15.8-0.8,23.8c0.9-8.7,1.9-17.3,2.8-26c-1.6,7.5-3.1,14.9-4.7,22.4
								c2.2-7.7,4.4-15.4,6.5-23c0.4-2.6,0.9-5.2,1.3-7.9c-0.4-1.5-0.8-2.9-1.2-4.4c-1.3-2-3-2.8-5-2.4c-1.5-0.4-2.8,0.1-3.8,1.4
								c-1.9,1.1-3.1,2.7-3.7,4.9c-4.7,7.8-5.4,16.5-1.9,26.3c-5.7,11.1-5,19.3,2.1,24.7c2.5,1.7,5,1.6,7.4-0.3
								c2.2-2.4,3.1-5.2,2.9-8.3c0.8-5.4,0.9-10.8,0.5-16.2C712.7,382,711.9,375.2,710.3,368.6z"/>
							<path opacity="0.1091" fill="#131313" d="M730.6,399.7c-0.4-1-0.8-2-1.2-2.9c0.4,1.5,0.9,3.1,1.3,4.6
								c-0.1-0.9-0.2-1.9-0.2-2.8c-0.2,4.5-0.4,9-0.5,13.6c1.4-5.5,2.9-11,4.3-16.4c0.2-1.8,0.5-3.6,0.7-5.4c-0.5-1.1-1-2.2-1.5-3.3
								c-1.4-1.6-3-2.4-5-2.4c-1.3-0.5-2.4-0.4-3.5,0.5c-1.6,0.3-2.7,1.3-3.2,3c-1.4,1.7-2.4,3.6-3.1,5.7c-0.5,1.1-1,2.3-1.6,3.4
								c2-3.8,4.1-7.6,6.1-11.3c-1,0.6-2.1,1.2-3.1,1.7c-1.7,2.5-2.8,5.1-3.5,8c-0.8,2.3-1.4,4.6-1.7,7c-0.8,2.9-1,5.8-0.7,8.7
								c0.9,4,2.4,7.6,4.5,10.9c1.6,2,3.8,2.9,6.4,2.7c2.6,0,4.7-1,6.2-3.1c1.2-3.6,1.6-7.3,1.3-11.1
								C732.7,406.9,732,403.2,730.6,399.7z"/>
							<path opacity="0.1091" fill="#131313" d="M685.5,395c-0.4-1-0.7-2-1.1-3c0.6,3.9,1.3,7.8,1.9,11.7c-0.2-3.2-0.5-6.5-0.7-9.7
								c0.1,2.3,0.1,4.5,0.2,6.8c0.2-3.1,0.3-6.2,0.5-9.3c-0.5,4.3-1.1,8.6-1.6,12.8c0.4-1.6,1-3,1.8-4.3c1.8-3.4,3.2-6.9,4-10.7
								c0.9-2.2,0.7-4-0.6-5.5c-0.8-1.7-2.2-2.8-4.1-3.2c-2.3-1-4.6-0.9-6.8,0.2c-1.7,12.8-3.4,25.6-5.1,38.4
								c1.6,2.1,3.7,3.1,6.4,2.9c2.7,0,4.7-1.1,6.1-3.4c1.2-3.9,1.6-7.8,1.2-11.9C687.7,402.6,687,398.7,685.5,395z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M703.5,419.5c-0.1-4.3-0.2-8.6-0.3-12.9c-0.1-4.7-6.7-8.1-10.8-6.4
								c-2.8,2.2-5,4.9-6.7,8c-2,3-3.3,6.2-3.8,9.7c0.2,4,0.4,7.9,0.7,11.9c0.3,4.6,6.7,8.1,10.7,6.5c2.7-2,4.9-4.6,6.5-7.6
								C701.8,426,703,422.9,703.5,419.5z"/>
							<path opacity="0.1091" fill="#131313" d="M680.3,404c0-4.6,0.1-9.1,0.1-13.7c0.1-5-6.6-8.2-10.8-6.3c-3,2.5-5.3,5.4-7.1,8.8
								c-2.1,3.2-3.5,6.7-4.2,10.5c0.1,4.2,0.2,8.4,0.3,12.6c0.1,4.8,6.6,8.2,10.7,6.4c2.9-2.3,5.2-5,6.9-8.3
								C678.3,410.9,679.6,407.6,680.3,404z"/>
							<path opacity="0.1091" fill="#131313" d="M684.4,362.8c-0.8-3.1-1.6-6.2-2.4-9.3c-1.2-2.7-3.4-4-6.4-3.7
								c-4.3,1.4-7.4,4.3-9.4,8.5c-4.4,6.4-8.3,13.1-11.7,20.1c-2.7,3.8-3.7,8-2.8,12.4c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.3-1.3,7.4-4.1,9.4-8.3c4.4-6.3,8.3-12.9,11.8-19.7C684.3,371.3,685.3,367.2,684.4,362.8z"/>
							<path opacity="0.1091" fill="#131313" d="M702.2,390.2c-0.9-3-1.7-5.9-2.6-8.9c-1.4-2.5-3.5-3.7-6.4-3.7
								c-3.7,0.7-6.5,2.9-8.2,6.5c-3.6,5.1-6.8,10.5-9.4,16.2c-2.3,3.3-2.8,6.7-1.6,10.3c0.8,2.8,1.6,5.6,2.4,8.4
								c1.4,2.5,3.5,3.7,6.3,3.8c3.7-0.6,6.5-2.7,8.3-6.3c3.7-5,6.9-10.3,9.6-15.9C702.8,397.2,703.4,393.8,702.2,390.2z"/>
							<path opacity="0.1091" fill="#131313" d="M704.9,358.1c-0.8-3.1-1.7-6.2-2.5-9.3c-1-3.1-3.1-4.4-6.4-3.7
								c-4.9,2.3-8.5,6-10.9,11.1c-5.4,8.1-10.2,16.4-14.6,25c-3.3,4.5-4.7,9.5-4.3,14.9c0.8,2.7,1.6,5.5,2.4,8.2
								c1,3.1,3.1,4.4,6.3,3.8c4.9-2.2,8.5-5.8,10.9-10.8c5.4-7.9,10.3-16.1,14.7-24.5C703.8,368.4,705.3,363.5,704.9,358.1z"/>
							<path opacity="0.1091" fill="#131313" d="M666.3,390.9c0-4.3-0.1-8.7-0.1-13c0-5-6.6-8.3-10.8-6.3c-3,2.5-5.4,5.5-7.2,9
								c-2.1,3.3-3.6,6.8-4.3,10.7c0.1,4,0.3,8,0.4,12c0.2,4.9,6.5,8.3,10.8,6.4c2.9-2.4,5.3-5.2,7-8.5
								C664.2,398,665.6,394.6,666.3,390.9z"/>
							<path opacity="0.1091" fill="#131313" d="M688.1,421.6c-0.3-4-0.7-8.1-1-12.1c-0.8-2.2-2.2-3.8-4.4-4.9
								c-1.9-1.4-4.1-1.9-6.4-1.5c-2.3,1.3-4,3.2-5.3,5.5c-1.5,2.2-2.3,4.6-2.4,7.2c0.5,3.8,1,7.5,1.5,11.3c0.9,2.1,2.3,3.7,4.4,4.8
								c1.9,1.3,4,1.9,6.3,1.6c2.2-1.2,3.9-2.9,5-5.1C687.4,426.4,688.1,424.1,688.1,421.6z"/>
							<path opacity="0.1091" fill="#131313" d="M716.1,386.6c-0.8-3.1-1.6-6.2-2.4-9.3c-1.3-2.7-3.4-4-6.4-3.7
								c-4.2,1.3-7.3,4-9.3,8.2c-4.3,6.2-8.1,12.8-11.5,19.6c-2.7,3.7-3.6,7.8-2.7,12.1c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.2-1.2,7.3-3.9,9.3-8c4.3-6.1,8.2-12.5,11.6-19.2C716.1,394.9,717,390.9,716.1,386.6z"/>
							<path opacity="0.1091" fill="#131313" d="M675.2,374.2c-0.9-3-1.7-6-2.6-8.9c-1.4-2.4-3.5-3.7-6.4-3.7
								c-3.7,0.7-6.4,2.8-8.2,6.4c-3.6,5.1-6.7,10.5-9.4,16.1c-2.3,3.3-2.8,6.7-1.6,10.3c0.8,2.8,1.6,5.6,2.4,8.4
								c1.4,2.5,3.5,3.7,6.3,3.8c3.7-0.6,6.5-2.7,8.2-6.3c3.6-5,6.8-10.3,9.5-15.9C675.8,381.2,676.4,377.8,675.2,374.2z"/>
							<path opacity="0.1091" fill="#131313" d="M693.3,372.4c-0.8-3.1-1.6-6.2-2.4-9.3c-1.3-2.7-3.4-4-6.4-3.7
								c-4.2,1.3-7.3,4.1-9.3,8.3c-4.3,6.3-8.2,12.9-11.5,19.7c-2.7,3.8-3.6,7.8-2.7,12.2c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.2-1.3,7.3-4,9.3-8.1c4.3-6.2,8.2-12.6,11.6-19.4C693.3,380.7,694.2,376.7,693.3,372.4z"/>
							<path opacity="0.1091" fill="#131313" d="M713.6,401.5c-0.9-3-1.7-6-2.6-9c-1.5-2.4-3.6-3.6-6.4-3.7
								c-3.6,0.5-6.2,2.4-7.9,5.8c-3.4,4.7-6.3,9.7-8.8,15c-2.1,3.1-2.6,6.4-1.3,9.7c0.8,2.8,1.6,5.7,2.3,8.5
								c1.4,2.4,3.5,3.6,6.3,3.8c3.6-0.4,6.2-2.3,7.9-5.7c3.4-4.6,6.4-9.6,8.9-14.8C714.4,408.1,714.8,404.9,713.6,401.5z"/>
							<path opacity="0.1091" fill="#131313" d="M669.7,401.3c-0.4-4-0.8-7.9-1.2-11.9c-0.4-4.3-6.8-7.9-10.7-6.4
								c-2.6,1.8-4.6,4.1-6.1,6.9c-1.8,2.6-2.9,5.5-3.2,8.6c0.5,3.8,1,7.6,1.5,11.4c0.5,4.2,6.8,7.8,10.7,6.4
								c2.5-1.7,4.5-3.9,5.9-6.7C668.4,407.1,669.4,404.3,669.7,401.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M740.6,466c0.8-2.5,1.6-5,2.4-7.5c0.6-2.2,0.5-4.4-0.3-6.6
								c-0.5-2.3-0.9-4.5-1.4-6.8c-0.7-3.9-1.9-7.8-3.5-11.4c-1.2-2.4-3-4-5.5-4.8c-3.1-0.6-5.6,0.4-7.6,2.9
								c-3.5,3.1-6.5,6.6-9.2,10.5c-2.2,2.4-2.9,5-1.9,8c0.8,3.2,1.2,6.4,1.3,9.7c0.1,2.6,0.3,5.3,0.5,7.9c0.1-1.2,0.2-2.4,0.3-3.6
								c-0.7,2.2-1.5,4.3-2.2,6.5c-1.7,4.9,2.3,10.3,7.5,10c4-1.4,7.6-3.4,10.8-6.1C735.3,472.3,738.2,469.4,740.6,466z"/>
							<path opacity="0.1091" fill="#131313" d="M716.5,450.4c1.6-3.2,3.2-6.5,4.8-9.7c0.3-2.1,0.1-4.3-0.4-6.4
								c-1.1-6.2-3.1-11.8-5.3-17.7c-1.2-2.4-3.1-4-5.6-4.8c-2.9-0.6-5.4,0.3-7.4,2.7c-3.3,3-6.2,6.3-8.7,10
								c-2.1,2.3-2.7,4.9-1.6,7.7c1.8,5.2,3.2,10,4.1,15.4c0-1.1,0.1-2.2,0.1-3.4c-1.4,2.9-2.7,5.7-4.1,8.6c-0.6,2.5,0,4.7,1.6,6.6
								c1.4,2.1,3.4,3.2,6,3.3c3.4-1,6.5-2.6,9.2-4.9C712.2,455.9,714.6,453.4,716.5,450.4z"/>
							<path opacity="0.1091" fill="#131313" d="M723,410.4c0.4-4.3,0.3-8.6-0.4-12.8c-0.8-5.3-2.3-10.4-4.5-15.3
								c-0.8-2.8-2.6-4.5-5.5-4.9c-4.2,0.3-7.6,2.2-10.2,5.8c-5.1,5-9.8,10.3-14.1,16c-3.2,3-4.7,6.6-4.5,10.8
								c1.6,2.9,2.6,6,3.1,9.3c0.4,3,0.5,6.1,0.1,9.1c0.3,2.8,1.8,4.8,4.5,5.9c3.8,0.7,7.3-0.3,10.3-3.1c5.5-3.5,10.6-7.4,15.4-11.7
								C720.7,417.3,722.6,414.2,723,410.4z"/>
							<path opacity="0.1091" fill="#131313" d="M740.8,437.3c0.4-4.2,0.2-8.5-0.5-12.7c-0.9-5.2-2.4-10.2-4.6-14.9
								c-0.9-2.7-2.7-4.4-5.5-4.9c-3.9,0.1-7.1,1.8-9.6,5.1c-4.7,4.5-9.1,9.4-13,14.7c-3,2.9-4.3,6.2-3.9,10.2c1,3.6,1.4,7.3,1.4,11
								c0,3.2-0.2,6.4-0.6,9.6c0.3,2.8,1.8,4.8,4.4,5.9c3.9,0.7,7.3-0.3,10.4-3.1c5.5-3.5,10.7-7.4,15.6-11.8
								C738.5,444.3,740.4,441.2,740.8,437.3z"/>
							<path opacity="0.1091" fill="#131313" d="M743.7,406.7c0.2-4.4,0.1-8.9-0.4-13.3c-0.9-5.4-2.4-10.6-4.6-15.7
								c-0.4-3.2-2.2-4.8-5.5-4.9c-5.1,1.1-9.3,3.9-12.6,8.3c-6.7,6.6-12.9,13.6-18.7,20.9c-4,3.7-6.3,8.2-6.9,13.4
								c1,2.9,1.6,6,1.7,9.1c-0.3,3-0.4,5.9-0.3,8.9c-0.1,3.2,1.4,5.1,4.5,5.9c5,0,9.4-1.7,13.3-5.3c7.4-4.9,14.4-10.2,21.1-16
								C739.5,415.4,742.4,411.6,743.7,406.7z"/>
							<path opacity="0.1091" fill="#131313" d="M703.2,437.2c1.4-3.1,2.8-6.3,4.2-9.4c0.1-2.9,0-5.9-0.2-8.8c0-1.7-0.4-3.3-1.2-4.8
								c-1.1-3.6-2.5-7.2-4.1-10.7c-1.3-2.3-3.1-3.9-5.6-4.8c-2.8-0.7-5.1,0.1-7.1,2.3c-3.1,2.7-5.8,5.8-8,9.3
								c-2,2.2-2.4,4.7-1.3,7.3c1.3,2.4,2.5,4.8,3.6,7.3c1.3,2.3,1.8,4.8,1.5,7.5c0-1.1-0.1-2.1-0.1-3.2c-1.1,2.8-2.3,5.6-3.4,8.5
								c-0.3,2.4,0.3,4.5,1.9,6.4c1.4,2,3.3,3.2,5.6,3.6c3-0.7,5.7-2,8-4C699.7,442.1,701.7,439.9,703.2,437.2z"/>
							<path opacity="0.1091" fill="#131313" d="M726.2,467.7c1-2.8,2-5.7,3-8.5c0-2.2-0.3-4.3-0.9-6.5c-1.3-6.5-3.6-12.4-6-18.6
								c-1.2-2.2-3-4-5.3-5.1c-2.3-1.1-4.4-0.7-6.4,1.1c-2.7,2-5,4.4-7,7.2c-1.7,2-2,4.1-0.9,6.4c2.1,6.4,3.9,12.7,5.4,19.2
								c-0.1-1-0.2-2.1-0.2-3.1c-0.9,2.7-1.8,5.3-2.7,8c-0.3,2.4,0.4,4.5,2,6.3c1.3,2,3.2,3.2,5.5,3.7c2.9-0.6,5.4-1.8,7.7-3.7
								C723,472.4,724.9,470.3,726.2,467.7z"/>
							<path opacity="0.1091" fill="#131313" d="M755.7,433c0.2-4.3,0-8.6-0.7-12.9c-1-5.2-2.6-10.1-4.9-14.9
								c-0.6-3-2.4-4.7-5.5-4.9c-4.7,0.7-8.5,3-11.4,7c-5.9,5.7-11.3,11.8-16.4,18.3c-3.6,3.3-5.5,7.4-5.7,12.1
								c0.5,3.6,0.6,7.1,0.4,10.7c-0.6,3.1-0.9,6.3-0.9,9.5c0,3.1,1.5,5,4.4,5.9c4.8,0.2,9-1.4,12.6-4.8c7-4.6,13.6-9.6,19.9-15
								C751.9,441.2,754.6,437.6,755.7,433z"/>
							<path opacity="0.1091" fill="#131313" d="M712.8,425.3c0.5-2.3,1-4.5,1.4-6.8c0.6-2.3,0.6-4.5-0.1-6.8
								c-0.4-2.3-0.8-4.6-1.4-6.8c-0.8-3.9-2-7.7-3.7-11.3c-1-2.6-2.9-4.2-5.5-4.8c-3.5-0.2-6.4,1.2-8.7,4.1
								c-4.1,3.9-7.9,8.2-11.2,12.8c-2.6,2.6-3.6,5.7-2.9,9.2c1.4,2.3,2.4,4.8,2.9,7.5c0.7,2.2,1.2,4.5,1.6,6.8
								c0.1-1.2,0.3-2.4,0.4-3.7c-0.4,1.9-0.8,3.7-1.2,5.6c-1,2.6-0.7,4.9,1,7.1c1.6,2.2,3.8,3.2,6.5,2.9c4.2-1.5,8-3.7,11.4-6.6
								C707,431.9,710.2,428.9,712.8,425.3z"/>
							<path opacity="0.1091" fill="#131313" d="M731.8,419.6c0.5-4.3,0.4-8.6-0.3-12.9c-0.9-5.2-2.4-10.3-4.5-15.2
								c-0.7-2.9-2.6-4.5-5.5-4.9c-4.3,0.4-7.8,2.4-10.5,6.1c-5.3,5.2-10.2,10.7-14.7,16.6c-3.3,3.1-4.9,6.8-4.8,11.1
								c1.2,3.1,2,6.4,2.2,9.8c0.1,3.1,0,6.2-0.3,9.3c0.2,2.9,1.7,4.9,4.5,5.9c4.1,0.6,7.7-0.6,11-3.5c5.9-3.8,11.4-8,16.6-12.6
								C729.2,426.9,731.3,423.7,731.8,419.6z"/>
							<path opacity="0.1091" fill="#131313" d="M752,448.8c0.6-4.3,0.4-8.5-0.4-12.7c-0.9-5.1-2.5-10.1-4.6-14.8
								c-0.9-2.7-2.7-4.4-5.5-4.9c-3.9,0.1-7.1,1.7-9.6,5.1c-4.7,4.5-9,9.4-12.9,14.6c-3,2.8-4.3,6.2-3.9,10.1
								c0.6,3.9,0.8,7.8,0.5,11.8c-0.3,3.3-0.7,6.7-1.1,10c0.3,2.9,1.7,4.8,4.4,5.9c4,0.6,7.6-0.5,10.7-3.3
								c5.7-3.6,11.2-7.7,16.2-12.2C749.5,455.9,751.5,452.8,752,448.8z"/>
							<path opacity="0.1091" fill="#131313" d="M706.5,449c1.1-2.9,2.2-5.8,3.2-8.7c-0.2-2.2-0.5-4.3-1-6.4
								c-1.2-6.6-3.3-12.7-5.5-19c-1.2-2.3-3-4-5.3-5.1c-2.4-1-4.5-0.7-6.5,1.2c-2.8,2.1-5.1,4.5-7.1,7.4c-1.8,2-2.1,4.2-0.9,6.5
								c2.5,6.1,4.6,12,6.4,18.4c-0.1-1-0.2-2-0.3-3c-0.9,2.7-1.8,5.3-2.7,8c-0.1,2.3,0.6,4.4,2.2,6.2c1.3,2,3.1,3.2,5.4,3.8
								c2.7-0.4,5-1.5,7.1-3.3C703.7,453.5,705.4,451.5,706.5,449z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M777.6,497.3c4.8-14.7,9.2-29.6,13.7-44.4c-0.1-2.7-1.3-4.9-3.6-6.4
								c-3.1-1.5-6.1-1.2-9.1,0.7c-4.9,2.1-9.6,4.7-14,7.8c-3.2,1.5-5,4-5.4,7.4c-2.8,9.1-5.5,18.1-8.1,27.2
								c0.4-1.2,0.7-2.4,1.1-3.6c-1.7,5.5-3.4,10.9-5.2,16.4c0.1,2.7,1.3,4.9,3.5,6.5c2.1,1.3,4.2,1.4,6.3,0.3
								c3.5-0.8,6.8-2.1,9.8-4.1C770.7,503,774.4,500.5,777.6,497.3z"/>
							<path opacity="0.1091" fill="#131313" d="M754.3,480.8c4-6.7,6.9-13.9,8.6-21.5c1.9-6.9,3.9-13.7,6.1-20.5
								c1-2.4,0.8-4.7-0.5-6.9c-1.2-2.3-3-3.7-5.6-4.1c-3.7,0.6-7.2,1.9-10.4,3.9c-3.4,1.7-6.3,3.9-8.7,6.8
								c-2.2,6.8-4.1,13.6-6,20.4c-1.7,7.2-4.2,14.1-7.8,20.6c-1.8,4.3,1.1,10.3,5.9,11c3.6-0.4,6.9-1.6,9.9-3.5
								C749.2,485.6,752,483.5,754.3,480.8z"/>
							<path opacity="0.1091" fill="#131313" d="M759.9,442.4c3.8-13.6,7.9-27.1,12-40.7c0-2.8-1.2-4.9-3.6-6.4
								c-3.4-1.3-6.8-0.9-10,1.2c-5.5,2.4-10.7,5.4-15.6,8.8c-3.5,1.6-5.6,4.3-6.2,7.9c-4,13.3-8.1,26.5-11.9,39.9
								c0,2.8,1.2,5,3.5,6.5c3.4,1.4,6.7,1.1,9.9-1c5.5-2.3,10.7-5.1,15.6-8.4C757.1,448.6,759.2,446,759.9,442.4z"/>
							<path opacity="0.1091" fill="#131313" d="M777.5,469.3c4-13.6,8.1-27.2,12.2-40.8c0.1-2.9-1.1-5-3.6-6.4
								c-3.8-1.2-7.4-0.6-10.9,1.7c-6.1,2.8-11.8,6-17.4,9.7c-3.8,1.7-6.2,4.5-7.2,8.4c-4,13.3-8.1,26.5-11.9,39.8
								c-0.1,2.9,1,5,3.5,6.5c3.7,1.3,7.3,0.8,10.8-1.4c6-2.6,11.8-5.7,17.3-9.3C774.1,475.9,776.5,473.1,777.5,469.3z"/>
							<path opacity="0.1091" fill="#131313" d="M779.9,439.5c4-13.7,8.2-27.3,12.3-40.9c0.4-3.1-0.8-5.2-3.6-6.4
								c-4.7-0.8-9.2,0.2-13.3,3.1c-7.6,3.6-14.9,7.7-22,12.3c-4.6,2-7.8,5.3-9.5,9.7c-4,13.2-8.2,26.4-12,39.7
								c-0.5,3,0.7,5.2,3.5,6.5c4.7,0.9,9.1,0,13.2-2.8c7.5-3.4,14.8-7.4,21.9-11.8C775,447.1,778.2,443.9,779.9,439.5z"/>
							<path opacity="0.1091" fill="#131313" d="M741.3,467.7c3.5-7,6.2-14.3,8.3-21.8c2.1-6.9,4.3-13.9,6.5-20.8
								c1.3-4-1.5-10.2-5.9-11c-3.1,0.2-5.9,1.1-8.6,2.7c-2.8,1.3-5.2,3.2-7,5.7c-2.2,6.9-4.3,13.8-6.3,20.7
								c-1.9,7.3-4.4,14.4-7.5,21.4c-0.6,2.3-0.3,4.5,1,6.6c1,2.2,2.7,3.7,4.9,4.5c2.9-0.1,5.6-0.9,8.1-2.5
								C737.4,471.9,739.6,470.1,741.3,467.7z"/>
							<path opacity="0.1091" fill="#131313" d="M760.2,500.9c4.4-7.2,7.9-14.8,10.3-22.9c2.4-6.8,4.7-13.7,6.7-20.7
								c1.3-4.2-1.3-10.2-6-11c-3.3,0.3-6.3,1.3-9.1,3.1c-3,1.4-5.5,3.4-7.6,6c-2,6.8-4.2,13.6-6.4,20.3c-2.2,7.7-5.3,15.2-9.2,22.3
								c-1.7,3.8,1.9,10.2,5.9,11c3-0.2,5.8-1,8.4-2.6C756.1,505.2,758.4,503.3,760.2,500.9z"/>
							<path opacity="0.1091" fill="#131313" d="M792.7,465.1c4-13.7,8.2-27.2,12.3-40.9c0.5-3.1-0.7-5.2-3.6-6.4
								c-4.8-0.8-9.3,0.3-13.5,3.2c-7.8,3.7-15.3,7.9-22.5,12.6c-4.7,2-7.9,5.3-9.8,9.9c-4,13.2-8.2,26.4-11.9,39.7
								c-0.5,3.1,0.6,5.2,3.5,6.5c4.8,0.9,9.2-0.1,13.4-2.9c7.7-3.5,15.2-7.5,22.4-12C787.5,472.7,790.8,469.5,792.7,465.1z"/>
							<path opacity="0.1091" fill="#131313" d="M747.8,457.6c3.7-6.8,6.5-13.9,8.4-21.3c2-6.9,4-13.7,6.1-20.6
								c1.2-2.4,1.2-4.7-0.2-7.1c-1.3-2.4-3.2-3.7-5.9-3.9c-4.1,0.8-8,2.3-11.5,4.6c-3.8,1.9-7.1,4.4-9.9,7.5
								c-2.1,6.8-4.1,13.6-6,20.5c-1.7,7.1-4.3,14-7.7,20.5c-1.2,2.4-1.1,4.7,0.2,7c1.2,2.4,3.1,3.7,5.7,4c4-0.7,7.7-2.1,11.2-4.2
								C741.9,463,745.1,460.6,747.8,457.6z"/>
							<path opacity="0.1091" fill="#131313" d="M768.6,451.6c4-13.6,8.1-27.2,12.2-40.8c0.1-2.9-1.1-5-3.6-6.4
								c-3.8-1.2-7.5-0.6-11,1.8c-6.1,2.8-12,6.1-17.5,9.8c-3.8,1.7-6.2,4.6-7.2,8.4c-4,13.3-8.1,26.5-12,39.8c-0.1,2.9,1,5,3.5,6.5
								c3.8,1.3,7.4,0.8,10.9-1.5c6.1-2.6,11.9-5.8,17.5-9.4C765.2,458.2,767.6,455.4,768.6,451.6z"/>
							<path opacity="0.1091" fill="#131313" d="M788.9,480.7c4.1-13.6,8.3-27.2,12.4-40.9c0.2-2.9-1-5.1-3.6-6.4
								c-4.1-1.1-8-0.4-11.7,2.1c-6.6,3-12.9,6.6-19,10.6c-4.1,1.8-6.7,4.8-8,8.8c-4,13.3-8.1,26.5-12,39.8
								c-0.3,2.9,0.9,5.1,3.5,6.5c4,1.2,7.9,0.6,11.6-1.9c6.5-2.9,12.8-6.2,18.9-10.1C785,487.5,787.6,484.7,788.9,480.7z"/>
							<path opacity="0.1091" fill="#131313" d="M742.4,481.1c3.6-7,6.4-14.3,8.4-21.9c2.1-6.9,4.3-13.9,6.5-20.8
								c0.5-2.3,0.1-4.5-1.1-6.5c-1-2.2-2.6-3.7-4.8-4.5c-2.8,0-5.3,0.8-7.7,2.3c-2.5,1.2-4.6,2.9-6.1,5.2
								c-2.2,6.9-4.3,13.8-6.3,20.7c-1.9,7.3-4.4,14.4-7.5,21.4c-0.4,2.3,0,4.4,1.3,6.4c1,2.1,2.5,3.7,4.7,4.6c2.6,0.1,5-0.6,7.2-2
								C739.2,484.9,741,483.3,742.4,481.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M847.9,500.1c1.7-4.7,3.4-9.5,5.1-14.2c0-2.7-1.1-4.9-3.3-6.6
								c-3.1-1.6-6.1-1.5-9.3,0.4c-5.1,1.9-9.9,4.4-14.5,7.3c-3.3,1.4-5.3,3.8-5.8,7.2c-1.6,4.5-3.3,9-4.9,13.5c0,2.7,1,4.9,3.2,6.6
								c3,1.6,6.1,1.6,9.2-0.2c5.1-1.8,9.9-4.2,14.5-7C845.4,505.8,847.3,503.5,847.9,500.1z"/>
							<path opacity="0.1091" fill="#131313" d="M823.3,487.1c2.6-5.1,5.1-10.3,7.7-15.4c2.1-4.3-0.8-10.4-5.6-11.2
								c-3.7,0.4-7.2,1.5-10.4,3.4c-3.4,1.5-6.4,3.6-8.9,6.3c-2.3,4.9-4.6,9.8-6.9,14.7c-2,4.2,0.7,10.4,5.5,11.3
								c3.6-0.3,6.9-1.3,10.1-3C818,491.7,820.9,489.7,823.3,487.1z"/>
							<path opacity="0.1091" fill="#131313" d="M829,448.8c1.6-4.7,3.1-9.3,4.7-14c0.1-2.8-1-5-3.3-6.6c-3.3-1.5-6.6-1.3-9.8,0.7
								c-5.4,2.1-10.6,4.8-15.6,7.9c-3.5,1.5-5.6,4-6.4,7.5c-1.5,4.4-3,8.9-4.5,13.3c-0.1,2.8,1,5,3.2,6.6c3.2,1.6,6.5,1.4,9.7-0.5
								c5.4-2,10.6-4.6,15.5-7.6C826.1,454.8,828.2,452.3,829,448.8z"/>
							<path opacity="0.1091" fill="#131313" d="M846.6,475.7c1.6-4.7,3.2-9.4,4.7-14.1c0.2-2.9-0.9-5.1-3.3-6.6
								c-3.7-1.4-7.4-1-10.9,1.2c-6.2,2.5-12.1,5.5-17.8,8.9c-3.9,1.5-6.4,4.2-7.5,8.1c-1.5,4.4-3,8.9-4.5,13.3
								c-0.3,2.9,0.8,5.1,3.2,6.6c3.7,1.4,7.3,1.1,10.9-1c6.1-2.4,12-5.2,17.7-8.6C842.9,482.1,845.4,479.5,846.6,475.7z"/>
							<path opacity="0.1091" fill="#131313" d="M848.9,446.1c1.6-4.7,3.2-9.4,4.8-14.2c0.6-3-0.6-5.2-3.3-6.6
								c-4.6-1.1-9-0.3-13.2,2.4c-7.6,3.2-15,6.9-22.1,11.1c-4.6,1.8-7.8,4.9-9.7,9.2c-1.5,4.4-3,8.8-4.4,13.3
								c-0.6,3,0.5,5.2,3.2,6.6c4.5,1.1,8.9,0.4,13.1-2.1c7.6-3.1,14.9-6.6,21.9-10.7C843.8,453.3,847,450.3,848.9,446.1z"/>
							<path opacity="0.1091" fill="#131313" d="M810.1,474.2c2.4-5.2,4.7-10.4,7.1-15.6c1.7-3.8-1.5-10.3-5.5-11.3
								c-3,0-5.8,0.8-8.4,2.2c-2.8,1.1-5.1,2.9-6.9,5.2c-2.1,5.1-4.3,10.2-6.4,15.2c-0.7,2.3-0.4,4.5,0.8,6.6
								c0.9,2.2,2.5,3.8,4.7,4.7c2.9,0,5.5-0.6,8-2.1C806.2,478.1,808.4,476.4,810.1,474.2z"/>
							<path opacity="0.1091" fill="#131313" d="M829.3,507.1c2.9-5.5,5.9-10.9,8.8-16.4c2.1-3.9-1.3-10.4-5.4-11.3
								c-3.3,0.1-6.3,1-9.2,2.6c-3,1.3-5.6,3.1-7.8,5.6c-2.6,5.3-5.2,10.6-7.8,15.9c-1.9,3.8,1.4,10.3,5.4,11.3
								c3.1-0.1,6-0.9,8.7-2.4C824.9,511.3,827.3,509.5,829.3,507.1z"/>
							<path opacity="0.1091" fill="#131313" d="M861.9,471.5c1.6-4.7,3.2-9.4,4.8-14.1c0.6-3.1-0.5-5.3-3.3-6.6
								c-4.8-1-9.4-0.1-13.7,2.6c-7.9,3.4-15.6,7.2-23.1,11.6c-4.8,1.8-8.2,5-10.3,9.4c-1.5,4.4-2.9,8.8-4.4,13.2
								c-0.7,3,0.4,5.3,3.2,6.6c4.7,1.1,9.3,0.3,13.6-2.3c7.9-3.2,15.5-6.9,22.9-11.1C856.4,478.9,859.8,475.9,861.9,471.5z"/>
							<path opacity="0.1091" fill="#131313" d="M816.5,464.1c2.6-5.1,5.2-10.3,7.8-15.4c1.3-2.3,1.3-4.7,0-7
								c-1.1-2.4-3-3.8-5.7-4.2c-4,0.6-7.9,1.9-11.4,3.9c-3.8,1.7-7.1,4-9.9,6.9c-2.3,4.9-4.7,9.8-7,14.7c-1.2,2.3-1.3,4.6,0,7
								c1.1,2.4,2.9,3.8,5.5,4.3c3.9-0.5,7.6-1.7,11.1-3.6C810.5,469.1,813.7,466.9,816.5,464.1z"/>
							<path opacity="0.1091" fill="#131313" d="M837.7,458c1.6-4.7,3.2-9.4,4.7-14.1c0.2-2.9-0.9-5.1-3.3-6.6
								c-3.7-1.4-7.4-1-10.9,1.2c-6.1,2.5-12,5.4-17.7,8.9c-3.9,1.6-6.3,4.2-7.5,8c-1.5,4.4-3,8.9-4.5,13.3
								c-0.2,2.9,0.8,5.1,3.2,6.6c3.7,1.4,7.3,1.1,10.8-1c6.1-2.4,12-5.2,17.6-8.6C834.1,464.4,836.6,461.7,837.7,458z"/>
							<path opacity="0.1091" fill="#131313" d="M858.1,487c1.6-4.7,3.3-9.4,4.9-14.2c0.4-2.9-0.7-5.1-3.3-6.6
								c-4.1-1.3-8.1-0.7-11.9,1.7c-6.8,2.8-13.3,6.1-19.6,9.8c-4.2,1.6-7,4.5-8.5,8.5c-1.5,4.4-3,8.9-4.5,13.3
								c-0.4,2.9,0.7,5.1,3.2,6.6c4,1.3,8,0.8,11.8-1.5c6.7-2.6,13.3-5.8,19.5-9.4C853.8,493.8,856.6,491,858.1,487z"/>
							<path opacity="0.1091" fill="#131313" d="M811.3,487.4c2.4-5.2,4.8-10.4,7.3-15.6c0.6-2.3,0.3-4.4-0.9-6.5
								c-0.9-2.2-2.4-3.8-4.6-4.7c-2.7-0.1-5.2,0.5-7.6,1.8c-2.5,1-4.6,2.6-6.1,4.8c-2.1,5.1-4.3,10.2-6.4,15.2
								c-0.5,2.3-0.1,4.4,1,6.5c0.9,2.2,2.4,3.8,4.4,4.8c2.5,0.2,4.9-0.4,7.2-1.6C808,491.1,809.9,489.5,811.3,487.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M879.7,502.7c4.2-11.1,8.1-22.3,12-33.6c0-2.7-1.1-4.9-3.3-6.6
								c-3.1-1.6-6.2-1.4-9.3,0.4c-5.1,2-10,4.4-14.6,7.4c-3.3,1.4-5.3,3.8-5.9,7.3c-3.7,10.9-7.6,21.8-11.7,32.6c0,2.7,1,5,3.2,6.6
								c3,1.6,6.1,1.6,9.2-0.2c5.1-1.8,9.9-4.1,14.5-7C877.1,508.4,879.1,506.1,879.7,502.7z"/>
							<path opacity="0.1091" fill="#131313" d="M855.1,489.7c6.2-10.9,11-22.4,14.3-34.6c1.1-2.3,1-4.6-0.3-6.9
								c-1.1-2.4-2.9-3.8-5.4-4.3c-3.7,0.4-7.2,1.6-10.5,3.5c-3.5,1.5-6.5,3.7-9,6.4c-3.2,11.7-7.6,22.8-13.2,33.5
								c-1.9,4.2,0.6,10.4,5.4,11.3c3.6-0.3,6.9-1.3,10.1-3C849.8,494.3,852.6,492.3,855.1,489.7z"/>
							<path opacity="0.1091" fill="#131313" d="M860.7,451.4c3.8-11.2,7.6-22.3,11.6-33.4c0.1-2.8-1.1-5-3.4-6.6
								c-3.3-1.5-6.6-1.2-9.8,0.7c-5.4,2.2-10.6,4.8-15.6,8c-3.5,1.5-5.6,4-6.3,7.5c-3.9,10.8-7.6,21.6-11.4,32.4
								c-0.1,2.8,1,5,3.2,6.6c3.2,1.6,6.5,1.4,9.7-0.5c5.4-2,10.6-4.5,15.5-7.5C857.8,457.4,860,454.9,860.7,451.4z"/>
							<path opacity="0.1091" fill="#131313" d="M878.4,478.3c3.8-11.2,7.7-22.4,11.7-33.5c0.2-2.9-0.9-5.1-3.3-6.6
								c-3.8-1.4-7.4-0.9-11,1.3c-6.2,2.5-12.1,5.5-17.8,9c-3.9,1.6-6.4,4.3-7.5,8.1c-3.9,10.7-7.7,21.5-11.4,32.3
								c-0.3,2.9,0.8,5.1,3.2,6.6c3.7,1.5,7.3,1.1,10.9-1c6.1-2.3,12.1-5.2,17.7-8.5C874.7,484.7,877.2,482.1,878.4,478.3z"/>
							<path opacity="0.1091" fill="#131313" d="M880.7,448.6c3.8-11.2,7.8-22.5,11.8-33.6c0.5-3-0.6-5.2-3.3-6.6
								c-4.6-1-9-0.2-13.2,2.4c-7.6,3.3-14.9,7-22,11.2c-4.6,1.8-7.8,4.9-9.7,9.2c-4,10.7-7.8,21.4-11.5,32.3
								c-0.6,3,0.5,5.2,3.2,6.6c4.5,1.2,8.9,0.5,13.1-2.1c7.6-3,14.9-6.6,21.9-10.6C875.6,455.9,878.8,452.9,880.7,448.6z"/>
							<path opacity="0.1091" fill="#131313" d="M841.9,476.8c5.6-11.4,10.4-23.1,14.2-35.3c1.4-3.9-1.1-10.2-5.4-11.3
								c-3,0-5.9,0.8-8.5,2.3c-2.8,1.2-5.2,2.9-7.1,5.3c-3.7,11.9-8.1,23.5-13.2,34.8c-0.7,2.3-0.4,4.5,0.8,6.6
								c0.9,2.2,2.5,3.8,4.7,4.7c2.8,0.1,5.5-0.6,8-2C838,480.7,840.2,479,841.9,476.8z"/>
							<path opacity="0.1091" fill="#131313" d="M861.1,509.7c6.8-11.5,12.2-23.5,16.4-36.1c1.6-4.1-0.8-10.3-5.5-11.3
								c-3.3,0.2-6.5,1.1-9.4,2.7c-3.1,1.3-5.8,3.2-8,5.7c-3.9,12.2-8.9,24-15,35.4c-1.8,3.9,1.3,10.3,5.4,11.3
								c3.1-0.1,6-0.8,8.8-2.4C856.7,513.8,859.1,512,861.1,509.7z"/>
							<path opacity="0.1091" fill="#131313" d="M893.7,474.1c3.8-11.3,7.7-22.5,11.8-33.6c0.6-3.1-0.5-5.3-3.3-6.6
								c-4.8-1-9.4-0.1-13.7,2.7c-7.9,3.4-15.6,7.3-23.1,11.7c-4.8,1.9-8.2,5-10.3,9.5c-4,10.6-7.9,21.4-11.4,32.2
								c-0.7,3,0.4,5.3,3.2,6.6c4.7,1.1,9.3,0.3,13.6-2.3c7.9-3.2,15.6-6.9,23-11C888.2,481.5,891.6,478.4,893.7,474.1z"/>
							<path opacity="0.1091" fill="#131313" d="M848.3,466.6c6.3-10.9,11.1-22.5,14.4-34.7c1.3-2.3,1.3-4.7,0-7
								c-1.2-2.4-3.1-3.8-5.7-4.1c-4.1,0.6-7.9,1.9-11.5,4c-3.8,1.7-7.1,4-10,7c-3.2,11.7-7.7,22.9-13.5,33.6c-1.3,2.3-1.3,4.6,0,7
								c1.1,2.4,2.9,3.9,5.5,4.3c3.9-0.4,7.6-1.6,11.1-3.5C842.3,471.6,845.5,469.4,848.3,466.6z"/>
							<path opacity="0.1091" fill="#131313" d="M869.5,460.6c3.8-11.2,7.7-22.4,11.7-33.5c0.2-2.9-0.9-5.1-3.3-6.6
								c-3.7-1.4-7.4-0.9-10.9,1.3c-6.1,2.5-12,5.5-17.7,9c-3.9,1.6-6.3,4.3-7.4,8.1c-3.9,10.7-7.7,21.5-11.4,32.3
								c-0.3,2.8,0.8,5.1,3.2,6.6c3.7,1.5,7.3,1.1,10.8-1c6.1-2.3,12-5.2,17.6-8.5C865.9,466.9,868.4,464.3,869.5,460.6z"/>
							<path opacity="0.1091" fill="#131313" d="M889.9,489.6c3.9-11.2,7.9-22.4,11.9-33.6c0.4-2.9-0.7-5.1-3.3-6.6
								c-4.1-1.2-8.1-0.6-11.9,1.8c-6.8,2.8-13.4,6.1-19.7,10c-4.2,1.7-7,4.5-8.5,8.6c-3.9,10.7-7.8,21.5-11.5,32.3
								c-0.4,2.9,0.7,5.1,3.2,6.6c4,1.3,8,0.9,11.8-1.4c6.8-2.6,13.3-5.8,19.6-9.4C885.6,496.4,888.4,493.6,889.9,489.6z"/>
							<path opacity="0.1091" fill="#131313" d="M843.1,490c5.7-11.4,10.5-23.2,14.3-35.3c0.6-2.3,0.3-4.5-0.9-6.5
								c-0.9-2.2-2.4-3.8-4.6-4.7c-2.7-0.1-5.3,0.5-7.7,1.9c-2.6,1-4.7,2.7-6.3,4.9c-3.7,11.9-8.1,23.5-13.2,34.8
								c-0.5,2.3-0.2,4.4,1,6.5c0.9,2.2,2.4,3.8,4.4,4.8c2.5,0.2,4.9-0.3,7.2-1.6C839.8,493.6,841.7,492.1,843.1,490z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M922.6,511.1c0.8-7.6,0.7-15.2-0.3-22.8c-0.1-6.8,0.3-13.6,1.3-20.3
								c0.5-5-5-9.3-9.7-8c-3.3,2-6.2,4.6-8.5,7.7c-2.6,2.9-4.6,6.2-6,9.9c-0.5,7.1-0.6,14.1-0.3,21.2c1.2,7.3,1.6,14.6,1.2,22
								c0,4.7,5.8,8.7,10.2,7.3c3-2,5.4-4.5,7.4-7.5C920.2,517.7,921.7,514.6,922.6,511.1z"/>
							<path opacity="0.1091" fill="#131313" d="M898.9,495.9c1.7-8.1,1.9-16.1,0.7-24.2c-0.1-6.9,0.4-13.7,1.5-20.5
								c0.6-4.9-5.1-9.2-9.7-7.9c-3.3,2-6.1,4.5-8.4,7.7c-2.6,2.9-4.6,6.1-5.9,9.8c-0.8,7.3-1.1,14.6-0.8,22
								c1.3,7.8,1.4,15.6,0.1,23.4c-0.3,4.8,6,8.7,10.2,7.2c3-2,5.5-4.6,7.5-7.7C896.4,502.7,898,499.4,898.9,495.9z"/>
							<path opacity="0.1091" fill="#131313" d="M903.5,455c-1.3-6.9-1.8-13.8-1.8-20.8c0,1.5-0.1,3.1-0.1,4.6
								c0.2-5,0.4-9.9,0.5-14.9c-0.1,1.5-0.2,3-0.2,4.6c0.4-3.4,0.7-6.8,1.1-10.1c0.1-1.5,0.2-3,0.4-4.5c-0.7-1.1-1.4-2.2-2.1-3.3
								c-1.2-0.5-2.4-0.9-3.6-1.4c-3,0.2-5.4,1.5-7.3,3.9c-5.1,4.5-9.4,9.8-13.5,15.3c-2.1,2.4-3.8,5-5.3,7.8
								c-2,2.3-2.7,4.9-2.3,7.9c-0.6,7.7-0.7,15.5-0.3,23.2c0.1,7,0.8,14,2.2,20.9c1,2.8,3,4.2,6,4.2c4.3-0.9,7.6-3.3,9.8-7.3
								c4.8-5.8,9.1-11.9,12.9-18.3C902.9,463.2,904.1,459.3,903.5,455z"/>
							<path opacity="0.1091" fill="#131313" d="M921.2,482.4c-0.5-3.4-1-6.8-1.4-10.2c0.1,1.4,0.2,2.7,0.3,4.1
								c-0.5-5.2-0.6-10.4-0.3-15.7c0-5.2,0.4-10.3,1.2-15.4c0.9-2.9,0.2-5.4-2.1-7.4c-2.4-1.8-4.9-2-7.6-0.6
								c-4.4,3.4-8.4,7.2-11.7,11.7c-3.7,4.2-6.8,8.8-9.2,13.8c-0.2,7.6-0.4,14.6,0,22.1c0.1,1.4,0.1,2.8,0.2,4.1
								c0.3,6.9,1.1,13.7,2.5,20.4c1.2,2.6,3.2,4,6,4.3c3.8-0.4,6.7-2.3,8.8-5.8c4.1-4.8,7.7-9.9,10.9-15.4
								C921.2,489.6,922.1,486.2,921.2,482.4z"/>
							<path opacity="0.1091" fill="#131313" d="M924.2,450.3c-1.4-6.9-2-13.8-1.8-20.8c0-6.8,0.6-13.6,1.8-20.3
								c-0.6-3.2-2.5-4.8-5.7-4.6c-5.1,1.4-9.3,4.5-12.3,9.1c-6.4,7-12.4,14.4-18,22.1c-3.9,3.9-6,8.6-6.4,13.9
								c-0.3,7.7-0.1,15.4,0.4,23.1c0.1,6.9,0.9,13.8,2.5,20.6c0.8,3.2,2.8,4.6,6,4.3c5-1.8,8.9-5.1,11.7-9.9
								c5.9-7.4,11.4-15.2,16.4-23.2C922.4,460.4,924.2,455.7,924.2,450.3z"/>
							<path opacity="0.1091" fill="#131313" d="M884.8,483.2c1.7-8.1,2-16.3,0.9-24.4c0-6.9,0.5-13.8,1.6-20.6
								c0.6-4.8-5.3-9.1-9.7-7.9c-3.2,1.9-5.9,4.3-8.1,7.3c-2.5,2.8-4.3,5.9-5.5,9.4c-1,7.5-1.5,14.9-1.3,22.5
								c1.2,7.9,1.1,15.8-0.1,23.8c-0.3,4.8,6.1,8.6,10.2,7.2c3-2,5.5-4.5,7.4-7.6C882.3,489.9,883.9,486.7,884.8,483.2z"/>
							<path opacity="0.1091" fill="#131313" d="M907.1,513.4c0.3-7.8,0.1-15.6-0.3-23.4c0.1-7,0.8-13.9,1.9-20.8
								c-0.3-2.4-1.5-4.3-3.4-5.7c-1.7-1.7-3.8-2.5-6.2-2.4c-2.6,1.1-4.8,2.9-6.5,5.2c-2,2.1-3.3,4.6-4,7.3
								c-0.9,7.2-1.4,14.3-1.3,21.5c0.7,7.6,1.1,15.2,1.2,22.8c0.7,2.2,2,4,4,5.2c1.8,1.5,3.9,2.2,6.2,2.1c2.3-1.1,4.2-2.7,5.6-5
								C905.9,518.3,906.8,516,907.1,513.4z"/>
							<path opacity="0.1091" fill="#131313" d="M935.4,478.4c-1-5.2-1.6-10.4-1.5-15.6c-0.3-5.2-0.2-10.5,0.3-15.7
								c-0.1,1.6-0.3,3.2-0.4,4.8c0.6-5,1.2-10,1.7-15c-0.8-3-2.7-4.5-5.7-4.7c-4.6,0.8-8.2,3.2-10.9,7.3
								c-5.5,5.9-10.6,12.1-15.3,18.6c-3.4,3.4-5.1,7.5-5,12.1c-0.1,7.4,0.2,14.7,0.8,22c0.4,6.8,1.2,13.6,2.6,20.3
								c1,2.8,3,4.3,6,4.3c4.4-1,7.7-3.5,10.1-7.6c4.9-6,9.4-12.2,13.4-18.8C934.7,486.8,935.9,482.8,935.4,478.4z"/>
							<path opacity="0.1091" fill="#131313" d="M894.3,466.2c-0.5-3.3-1-6.7-1.5-10c0.1,1.3,0.2,2.7,0.4,4
								c-0.5-5.2-0.6-10.4-0.3-15.6c0-5.2,0.4-10.3,1.3-15.4c0.6-2.8-0.1-5.2-2.4-7c-2.3-1.8-4.7-2.1-7.4-0.9
								c-4.1,2.9-7.7,6.5-10.7,10.5c-3.4,3.8-6.1,8-8.1,12.6c-0.3,3.8-0.6,7.7-0.8,11.5c-0.3,5.6-0.2,11.3,0.3,16.9
								c0.1,2.5,0.3,5,0.5,7.5c0.3,4,0.9,7.9,1.7,11.8c1.2,2.5,3.2,3.9,6,4.2c3.7-0.3,6.5-2.1,8.5-5.4c3.9-4.6,7.4-9.5,10.4-14.7
								C894.5,473.1,895.2,469.8,894.3,466.2z"/>
							<path opacity="0.1091" fill="#131313" d="M912.5,464.4c-1.3-6.9-1.8-13.8-1.7-20.8c0,1.5-0.1,3.1-0.1,4.6
								c0.2-5,0.4-10,0.6-15c-0.1,1.5-0.2,3.1-0.3,4.6c0.4-3.4,0.7-6.8,1.1-10.2c0.1-1.5,0.3-3,0.4-4.6c-0.7-1.1-1.4-2.2-2.1-3.3
								c-1.2-0.4-2.4-0.9-3.7-1.3c-3.1,0.2-5.5,1.6-7.4,4c-5.2,4.6-9.6,10-13.8,15.6c-2.1,2.4-3.9,5.1-5.4,7.9c-2,2.3-2.8,5-2.4,8
								c-0.4,7.6-0.4,15.2,0,22.8c0.2,7,1,13.9,2.3,20.7c1,2.8,3,4.2,6,4.3c4.3-0.9,7.6-3.3,9.8-7.3c4.8-5.8,9.1-11.9,12.9-18.3
								C911.9,472.6,913.1,468.7,912.5,464.4z"/>
							<path opacity="0.1091" fill="#131313" d="M932.7,493.6c-0.5-3.4-1-6.7-1.5-10.1c0.1,1.3,0.2,2.7,0.3,4
								c-0.5-5.2-0.6-10.4-0.3-15.6c0-5.1,0.4-10.3,1.2-15.3c0.8-2.9,0.1-5.3-2.1-7.3c-2.4-1.8-4.9-2-7.5-0.7
								c-4.4,3.2-8.2,7-11.5,11.3c-3.6,4.1-6.6,8.6-8.9,13.5c-0.1,7.3-0.2,14.3,0.3,21.6c0.1,1.3,0.2,2.7,0.3,4
								c0.4,6.8,1.3,13.5,2.6,20.1c1.2,2.5,3.2,3.9,6,4.3c3.7-0.3,6.5-2.1,8.5-5.4c3.9-4.6,7.4-9.4,10.4-14.6
								C932.8,500.5,933.6,497.2,932.7,493.6z"/>
							<path opacity="0.1091" fill="#131313" d="M888.5,493.5c0.2-7.7,0-15.5-0.5-23.2c0.1-6.9,0.7-13.8,1.8-20.6
								c-0.2-2.4-1.3-4.4-3.3-5.8c-1.8-1.7-3.9-2.4-6.3-2.2c-2.8,1.3-5.1,3.2-7,5.8c-2.1,2.3-3.6,4.9-4.4,7.9
								c-1,7.3-1.5,14.6-1.6,21.9c0.6,7.6,1,15.3,1.1,23c0.5,2.4,1.7,4.2,3.8,5.5c1.9,1.5,4,2.1,6.4,1.8c2.6-1.5,4.7-3.5,6.3-6
								C886.8,499.1,888,496.5,888.5,493.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M859.6,556.4c0-9.6-1.3-19-4-28.1c-2.1-8.4-4.2-16.8-6.1-25.3
								c-0.4-2.5-1.7-4.4-4-5.6c-2.2-1.4-4.4-1.7-6.8-0.8c-2.8,2.3-5.1,5-6.8,8.3c-2,3-3.3,6.4-3.9,9.9c1.9,8.4,4,16.8,6.2,25.1
								c2.6,8.7,4.1,17.6,4.4,26.8c0.6,4.5,6.3,8.3,10.7,6.6c2.8-2,5-4.5,6.6-7.6C857.8,562.9,859,559.8,859.6,556.4z"/>
							<path opacity="0.1091" fill="#131313" d="M836.3,540.9c0.5-9.9-0.6-19.5-3.5-28.9c-2.2-8.4-4.2-16.8-6.2-25.2
								c-0.3-2.6-1.6-4.5-3.9-5.8c-2.2-1.4-4.5-1.6-6.9-0.6c-3,2.5-5.4,5.5-7.2,9c-2.1,3.3-3.6,6.8-4.3,10.6c1.9,8.4,4,16.7,6.2,25
								c2.8,8.9,4.1,18,3.9,27.5c0.5,4.7,6.2,8.4,10.7,6.6c2.9-2.2,5.2-5,7-8.2C834.2,547.8,835.6,544.5,836.3,540.9z"/>
							<path opacity="0.1091" fill="#131313" d="M840.5,499.8c-4.1-16.9-8-33.8-12-50.7c-1.2-2.7-3.4-4-6.4-3.7
								c-4.3,1.4-7.4,4.2-9.4,8.5c-4.4,6.4-8.3,13.1-11.7,20.1c-2.7,3.8-3.7,7.9-2.8,12.4c3.9,16.5,7.7,33,11.6,49.4
								c1.2,2.8,3.3,4,6.3,3.8c4.3-1.3,7.4-4,9.5-8.1c4.5-6.2,8.5-12.7,12-19.5C840.3,508.2,841.3,504.1,840.5,499.8z"/>
							<path opacity="0.1091" fill="#131313" d="M858.3,527.1c-4.3-16.7-8.3-33.4-12.2-50.1c-1.4-2.5-3.5-3.7-6.4-3.7
								c-3.8,0.7-6.5,2.9-8.3,6.5c-3.7,5.1-6.8,10.5-9.5,16.2c-2.3,3.3-2.9,6.7-1.7,10.4c3.9,16.4,7.7,32.9,11.8,49.2
								c1.4,2.5,3.4,3.8,6.3,3.9c3.7-0.6,6.5-2.7,8.3-6.2c3.7-5,7-10.3,9.8-15.9C858.8,534.1,859.4,530.7,858.3,527.1z"/>
							<path opacity="0.1091" fill="#131313" d="M861,495.1c-4.2-16.9-8.1-33.8-12.1-50.7c-1-3.1-3.1-4.4-6.4-3.7
								c-4.9,2.3-8.6,6-10.9,11.1c-5.4,8.1-10.3,16.4-14.7,25c-3.3,4.6-4.7,9.5-4.3,14.9c2.1,8.4,4.1,16.9,5.9,25.4
								c1.7,8,3.6,15.9,5.7,23.7c1,3.1,3.1,4.4,6.3,3.9c4.9-2.1,8.6-5.7,11-10.6c5.5-7.8,10.4-15.9,15-24.4
								C859.8,505.3,861.3,500.4,861,495.1z"/>
							<path opacity="0.1091" fill="#131313" d="M822.3,527.8c0.3-9.7-0.9-19.2-3.7-28.4c-2.2-8.4-4.2-16.9-6.2-25.3
								c-0.3-2.6-1.5-4.5-3.9-5.8c-2.2-1.4-4.5-1.6-6.9-0.5c-3,2.5-5.4,5.6-7.2,9.1c-2.2,3.3-3.6,6.9-4.4,10.7
								c1.9,8.4,4,16.8,6.2,25.2c2.7,8.8,4.1,17.8,4.1,27.1c0.5,4.8,6.1,8.5,10.7,6.5c2.9-2.3,5.3-5.1,7.1-8.4
								C820.1,534.9,821.6,531.5,822.3,527.8z"/>
							<path opacity="0.1091" fill="#131313" d="M844.2,558.5c-0.8-9.4-2.4-18.6-4.8-27.7c-2-8.6-4-17.2-6-25.8
								c-0.7-2.2-2.2-3.9-4.3-5c-1.9-1.4-4.1-1.9-6.4-1.6c-2.3,1.4-4.2,3.2-5.4,5.7c-1.6,2.2-2.5,4.7-2.6,7.4
								c1.9,8.6,3.9,17.1,6.1,25.7c2.4,8.8,4.1,17.8,5.4,26.8c0.8,2.2,2.3,3.8,4.4,4.9c1.9,1.4,4,1.9,6.3,1.7
								c2.2-1.2,3.9-2.9,5.1-5.1C843.4,563.3,844.1,561,844.2,558.5z"/>
							<path opacity="0.1091" fill="#131313" d="M872.3,523.5c-4-16.9-8-33.8-12-50.6c-1.2-2.7-3.4-4-6.3-3.7
								c-4.2,1.3-7.3,4.1-9.3,8.3c-4.3,6.3-8.2,12.9-11.6,19.7c-2.7,3.8-3.6,7.8-2.7,12.2c3.9,16.4,7.7,32.9,11.6,49.4
								c1.2,2.7,3.3,4,6.3,3.9c4.2-1.2,7.4-3.8,9.4-7.9c4.4-6.1,8.4-12.5,11.8-19.2C872.1,531.8,873.1,527.8,872.3,523.5z"/>
							<path opacity="0.1091" fill="#131313" d="M831.3,511.1c-4.3-16.7-8.2-33.4-12.2-50.2c-1.4-2.4-3.5-3.7-6.4-3.7
								c-3.7,0.7-6.5,2.8-8.2,6.4c-3.6,5.1-6.7,10.4-9.4,16.1c-2.3,3.3-2.8,6.7-1.6,10.3c3.9,16.4,7.7,32.9,11.8,49.3
								c1.4,2.5,3.5,3.7,6.3,3.8c3.7-0.6,6.5-2.6,8.3-6.2c3.7-4.9,6.9-10.2,9.6-15.7C831.8,518.1,832.4,514.7,831.3,511.1z"/>
							<path opacity="0.1091" fill="#131313" d="M849.5,509.3c-4-16.9-8-33.8-12-50.6c-1.2-2.7-3.4-4-6.4-3.7
								c-4.2,1.3-7.3,4.1-9.3,8.3c-4.3,6.3-8.2,12.9-11.6,19.8c-2.7,3.8-3.6,7.8-2.7,12.2c3.9,16.4,7.7,32.9,11.6,49.4
								c1.2,2.7,3.3,4,6.3,3.9c4.2-1.2,7.4-3.9,9.4-8c4.4-6.1,8.3-12.5,11.8-19.2C849.3,517.6,850.3,513.6,849.5,509.3z"/>
							<path opacity="0.1091" fill="#131313" d="M869.5,538.7c-4.1-16.8-8.1-33.6-12.1-50.3c-1.4-2.4-3.6-3.6-6.3-3.7
								c-3.6,0.5-6.2,2.5-7.9,5.9c-3.4,4.8-6.4,9.8-8.9,15.1c-2.2,3.1-2.6,6.4-1.3,9.8c3.9,16.5,7.8,32.9,11.8,49.4
								c1.4,2.4,3.5,3.7,6.3,3.9c3.6-0.4,6.2-2.3,8-5.6c3.5-4.6,6.5-9.5,9.1-14.7C870.3,545.3,870.8,542.1,869.5,538.7z"/>
							<path opacity="0.1091" fill="#131313" d="M825.8,538.3c-0.9-9.3-2.6-18.5-4.9-27.5c-2-8.6-4-17.2-6-25.8
								c-1-4.2-6.3-8.2-10.7-6.5c-2.6,1.8-4.7,4.2-6.2,7c-1.8,2.7-3,5.6-3.4,8.7c1.9,8.6,3.9,17.1,6.1,25.7
								c2.4,8.8,4.1,17.8,5.3,26.8c0.7,4.1,6.6,8,10.7,6.5c2.5-1.7,4.5-3.9,6-6.6C824.4,544,825.4,541.3,825.8,538.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M891.6,582.2c1.1-1.8,1.8-3.7,2-5.7c0.5-2.4,0.8-4.9,0.9-7.4
								c0.1-6.2-0.9-12.3-2-18.4c-0.4-2.7-1.8-4.7-4.1-6.1c-2.2-1.1-4.2-1-6.2,0.3c-3.3,1.1-6.4,2.7-9.1,4.9
								c-3.8,2.3-7.1,5.2-9.9,8.6c-0.6,2.6-1.1,5.1-1.7,7.7c0.4-1.2,0.8-2.4,1.2-3.6c-0.9,2.6-1.8,5.2-2.7,7.8
								c0.5-1.2,1.1-2.3,1.6-3.5c-1,2.2-2.1,4.5-3.1,6.7c0.7-1.1,1.3-2.3,2-3.4c-2.4,4-4.6,8.2-7.2,12.1c-0.9,2.7-0.4,5.1,1.4,7.2
								c3,2.3,6.3,3,10.1,1.9c6.1-0.6,12.2-1.8,18.1-3.6C886.9,587.4,889.8,585.5,891.6,582.2z"/>
							<path opacity="0.1091" fill="#131313" d="M863.5,569c3.4-2.6,6.2-5.6,8.6-9.1c1.2-1.7,1.7-3.5,1.6-5.6
								c0.4-7.1-0.9-13.9-2.5-20.8c-0.6-2.6-2-4.6-4.2-6.1c-2.6-1.4-5.1-1.2-7.6,0.5c-3.8,1.9-7.2,4.3-10.4,7.1
								c-2.5,1.7-3.6,4-3.2,6.8c0.8,2.7,1.1,5.4,1.1,8.2c0.2,2.1,0.1,4.3-0.4,6.4c0.5-1,0.9-2,1.4-3.1c-2.2,2.7-4.6,5.1-7.3,7.3
								c-3.5,3.2-1.5,10.4,2.4,12.3c3.5,0.6,7,0.5,10.5-0.4C857.2,572.2,860.5,571,863.5,569z"/>
							<path opacity="0.1091" fill="#131313" d="M869.1,534.5c1.6-2.1,3.1-4.4,4.5-6.7c1.7-2.2,2.5-4.6,2.3-7.2
								c0.2-2.8,0.2-5.6-0.1-8.4c-0.5-4.4-1.3-8.7-2.5-12.9c-0.4-1.2-0.8-2.4-1.2-3.6c-1-0.8-1.9-1.7-2.9-2.5
								c-1.3-0.2-2.6-0.3-3.9-0.5c-3.5,1.1-6.7,2.7-9.7,4.8c-4.7,2.9-9.3,6-13.2,9.9c-1.7,1-2.6,2.3-2.8,4.2
								c-0.9,1.9-0.7,3.7,0.7,5.5c1,1.3,1.6,2.7,2,4.2c0.5,1.4,0.9,2.8,1.2,4.2c-0.1-1.3-0.3-2.5-0.4-3.8c-0.3,1.9-0.7,3.8-1,5.6
								c0.6-1,1.3-2.1,1.9-3.1c-0.7,1-1.4,1.9-2,2.9c-1.9,1.9-2.6,4.1-2,6.8c0.5,2.7,2,4.5,4.5,5.5c4.3,0.5,8.5,0.2,12.7-0.9
								C861.4,537.9,865.4,536.5,869.1,534.5z"/>
							<path opacity="0.1091" fill="#131313" d="M889.9,557.9c1.5-2.5,2.4-5.2,2.8-8c0.6-3.6,0.7-7.3,0.4-10.9
								c-0.3-4.3-1.3-8.5-2.4-12.6c-0.1-2.8-1.5-4.9-4.1-6.1c-3.8-1-7.3-0.2-10.5,2.3c-5.7,3.1-11,6.6-16.1,10.6
								c-3.6,2-5.7,4.9-6.2,8.7c0,3-0.2,6.1-1.3,8.9c-1,2.8-2.4,5.4-4,7.9c-1,2.7-0.5,5.1,1.4,7.2c3.3,2.3,6.9,2.9,10.9,1.7
								c6.6-0.8,13.2-2.1,19.6-3.9C884.6,563.2,887.7,561.3,889.9,557.9z"/>
							<path opacity="0.1091" fill="#131313" d="M891.4,528.8c1.5-2.6,2.5-5.3,3.1-8.2c0.5-2.8,0.8-5.6,0.8-8.4
								c0.1-2.3,0.1-4.6-0.1-6.9c-0.6-4.5-1.5-8.9-2.9-13.3c-2.1-2.6-4.8-3.3-8.1-2.2c-6.5,2.7-12.7,6-18.5,10
								c-6,3.7-11.6,7.9-16.7,12.8c-2.3,2.5-2.7,5.3-1.3,8.3c0.3,0.7,0.5,1.3,0.8,2c-0.2-1.3-0.3-2.6-0.5-3.9c0.2,2,0.5,4,0.7,6
								c-0.1-1.3-0.1-2.6-0.2-3.9c0.1,2,0.2,4,0.2,6c0-1.3,0-2.6,0-3.9c-0.1,1.2-0.3,2.3-0.7,3.4c-0.3,1.4-0.8,2.6-1.6,3.7
								c-1.1,2.7-0.6,5.1,1.5,7.2c3.6,2.3,7.6,2.8,11.8,1.5c7.2-0.9,14.3-2.3,21.4-4.3C885.5,534.3,889,532.4,891.4,528.8z"/>
							<path opacity="0.1091" fill="#131313" d="M851.9,556.2c2.6-2.7,4.8-5.7,6.6-9c0.8-1.8,1.1-3.7,1-5.7c0.3-3,0.3-5.9,0.1-8.9
								c-0.4-4.6-1.1-9.2-2.2-13.7c-0.6-2.5-1.8-4.6-3.8-6.3c-2-1.6-4.2-1.8-6.6-0.5c-3.3,1.3-6.2,3.1-8.9,5.3
								c-2.3,1.5-3.1,3.5-2.7,6.1c1,2.8,2,5.5,2.9,8.3c-0.4-1.2-0.7-2.4-1.1-3.6c0.7,2.8,1.3,5.7,2,8.5c-0.3-1.2-0.6-2.4-0.8-3.6
								c0.5,2.7,0.9,5.4,1.4,8.1c-0.2-1.2-0.4-2.4-0.6-3.7c0.1,1.5,0.1,3,0.2,4.4c0.3-1,0.6-1.9,0.9-2.9c-1.3,2.9-3.1,5.5-5.2,7.8
								c-1.1,2.1-1.3,4.2-0.7,6.5c0.3,2.3,1.4,4.3,3.1,5.8c2.5,0.8,5,0.9,7.5,0.2C847.7,558.9,850,557.9,851.9,556.2z"/>
							<path opacity="0.1091" fill="#131313" d="M870.8,588.7c3.4-2.6,6.3-5.8,8.6-9.4c1.2-1.7,1.7-3.6,1.6-5.6
								c0.3-2.7,0.4-5.5,0.1-8.2c-0.6-4.8-1.5-9.6-2.8-14.3c-0.6-2.5-1.8-4.6-3.8-6.3c-2.1-1.6-4.3-1.8-6.7-0.4
								c-3.3,1.3-6.4,3.2-9.2,5.5c-2.3,1.5-3.2,3.5-2.8,6.1c0.6,4,0.7,8,0.5,12c-0.3,2.6-0.8,5-1.7,7.5c0.5-1,0.9-2,1.4-3
								c-2.4,2.9-5.1,5.6-8,8.1c-3.5,3.2-1.6,10.4,2.4,12.3c3.5,0.6,7,0.5,10.5-0.4C864.5,591.8,867.8,590.6,870.8,588.7z"/>
							<path opacity="0.1091" fill="#131313" d="M905.5,554c1.5-2.6,2.3-5.3,2.6-8.3c0.4-2.2,0.6-4.4,0.6-6.6
								c0.2-2.9,0.1-5.7-0.5-8.6c-0.8-4.3-2.1-8.5-3.7-12.6c-2.1-2.6-4.8-3.3-8.1-2.3c-4.7,1.8-9.1,4-13.3,6.8
								c-5.4,3-10.6,6.4-15.5,10.2c-4.4,2.3-7.3,5.6-8.8,10.2c-0.5,2.5-1.4,5-2.6,7.2c-0.5,0.8-1,1.5-1.5,2.3
								c-0.6,1.7-1.5,3.4-2.6,4.9c-1.4,2.8-1,5.2,1.4,7.2c4.4,2.2,9.1,2.6,14,1.1c8.6-1.2,17.1-2.8,25.5-5.1
								C898.1,559.9,902.3,557.7,905.5,554z"/>
							<path opacity="0.1091" fill="#131313" d="M859.7,545.2c2.7-2.5,5-5.4,6.7-8.6c1.3-1.7,1.7-3.5,1.4-5.6
								c0-7.1-1.5-13.8-3.2-20.7c-0.5-1.2-0.9-2.3-1.4-3.5c-0.9-0.9-1.8-1.7-2.7-2.6c-1.2-0.3-2.3-0.5-3.5-0.8
								c-2.9,0.7-5.5,2-7.9,3.7c-3.8,2.3-7.5,4.8-10.5,8.2c-1.4,0.9-2.1,2.1-2,3.7c-0.6,1.9-0.2,3.6,1.1,5.2c0.5,1.1,1,2.2,1.5,3.4
								c-0.4-1.2-0.7-2.4-1.1-3.6c1,3.3,1.9,6.7,2.8,10.1c-0.2-1.2-0.4-2.5-0.5-3.7c-0.6,2.1-1.2,4.2-1.9,6.3c0.5-1,1-2,1.5-3
								c-1,1.1-2,2.2-2.9,3.3c-3.1,3.5-1.8,10.3,2.5,12.3c3.5,0.6,7,0.5,10.4-0.4C853.4,548.3,856.7,547.1,859.7,545.2z"/>
							<path opacity="0.1091" fill="#131313" d="M881.2,540.1c1.4-2.5,2.2-5.1,2.7-8c0.6-3.6,0.7-7.3,0.5-11
								c-0.3-4.3-1.3-8.6-2.3-12.8c-0.1-2.9-1.5-4.9-4.1-6.1c-3.9-0.9-7.4-0.1-10.7,2.5c-5.8,3.2-11.3,6.8-16.5,10.9
								c-3.7,2-5.8,5-6.4,8.9c0.6,2.1,1.2,4.2,1.8,6.4c-0.3-1.3-0.5-2.5-0.8-3.8c0.3,2.2,0.6,4.4,0.9,6.6c-0.2-1.3-0.3-2.5-0.5-3.8
								c0.1,2.1,0.3,4.2,0.4,6.4c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1,1.3-0.3,2.6-0.8,3.9c-0.3,1.4-0.9,2.7-1.8,3.9
								c-0.9,2.7-0.4,5.1,1.5,7.2c2.9,2.3,6.2,3,10,1.9c6-0.6,12-1.8,17.8-3.6C876.6,545.3,879.4,543.4,881.2,540.1z"/>
							<path opacity="0.1091" fill="#131313" d="M901.9,569.1c1.5-2.5,2.4-5.2,2.6-8.1c0.2-1.8,0.3-3.5,0.4-5.3
								c0.2-3.2,0-6.4-0.6-9.6c-0.8-4.2-2-8.3-3.5-12.3c-1.8-2.5-4.3-3.4-7.3-2.7c-4,1.4-7.7,3.2-11.2,5.6
								c-4.5,2.5-8.9,5.4-12.9,8.7c-3.8,2-6.1,5.1-7,9.1c-1,3.1-2.1,6.2-3.9,9c-0.6,0.9-1.2,1.7-1.9,2.6c-0.9,1.8-1.9,3.5-3.1,5.1
								c-1.3,2.8-0.8,5.2,1.4,7.2c3.9,2.3,8.2,2.7,12.7,1.4c7.8-1,15.5-2.5,23.1-4.6C895.4,574.8,899.2,572.7,901.9,569.1z"/>
							<path opacity="0.1091" fill="#131313" d="M853.7,569.1c2.6-2.7,4.8-5.7,6.5-9c0.7-1.8,1-3.7,0.9-5.7c0.4-7.7-1-14.8-2.6-22.3
								c-0.7-2.5-1.9-4.6-3.8-6.3c-1.8-1.7-3.8-1.9-6.1-0.8c-2.9,1.1-5.6,2.7-7.9,4.7c-2,1.5-2.7,3.4-2.1,5.7
								c0.9,2.8,1.8,5.7,2.7,8.5c-0.4-1.2-0.8-2.4-1.1-3.5c0.5,2.4,1,4.9,1.2,7.4c0.4,2.3,0.5,4.5,0.2,6.8c0.3-1,0.5-1.9,0.8-2.9
								c-1.4,2.9-3.2,5.5-5.4,7.9c-1,2.1-1.3,4.2-0.6,6.5c0.3,2.3,1.3,4.3,3.1,5.8c2.4,0.8,4.9,0.9,7.4,0.2
								C849.6,571.8,851.8,570.8,853.7,569.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M787.1,548.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C777.8,544.9,782,547.4,787.1,548.1z"/>
							<path opacity="0.1091" fill="#131313" d="M762.7,529.1c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C756.4,528.6,759.3,529.7,762.7,529.1z"/>
							<path opacity="0.1091" fill="#131313" d="M762,495.2c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C757.2,493,759.4,494.4,762,495.2z"/>
							<path opacity="0.1091" fill="#131313" d="M783.9,521.8c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C776.7,520.4,780,521.9,783.9,521.8z"/>
							<path opacity="0.1091" fill="#131313" d="M779.1,491c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C773,487.5,775.9,489.6,779.1,491z"/>
							<path opacity="0.1091" fill="#131313" d="M745.1,517.4c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C739.9,514.8,742.3,516.5,745.1,517.4z"/>
							<path opacity="0.1091" fill="#131313" d="M770.1,549.9c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C762.2,547.9,765.8,549.7,770.1,549.9z"/>
							<path opacity="0.1091" fill="#131313" d="M798.1,518.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C790.1,516.5,793.7,518.3,798.1,518.5z"/>
							<path opacity="0.1091" fill="#131313" d="M753.3,505.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C748.1,503.2,750.5,504.9,753.3,505.9z"/>
							<path opacity="0.1091" fill="#131313" d="M769.6,502.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C763.1,498.4,766.1,500.7,769.6,502.3z"/>
							<path opacity="0.1091" fill="#131313" d="M796.7,534.6c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C787.6,531.6,791.7,533.9,796.7,534.6z"/>
							<path opacity="0.1091" fill="#131313" d="M745,528.3c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C738.7,524.6,741.6,526.8,745,528.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M785.5,549.7c2.9-13,4.4-26.2,4.7-39.6c0.7-2.6,0.1-4.9-1.7-6.9
								c-1.8-2.1-4-2.9-6.6-2.5c-3.9,1.7-7.3,4.1-10.3,7c-3.3,2.7-6,5.9-8.1,9.5c-0.3,12.9-1.7,25.7-4.2,38.4
								c-0.7,2.6-0.1,4.8,1.7,6.8c1.7,2.1,3.8,3,6.5,2.7c3.8-1.5,7.2-3.7,10.1-6.5C780.7,556.2,783.4,553.2,785.5,549.7z"/>
							<path opacity="0.1091" fill="#131313" d="M761.8,533.8c4.5-13.4,6.7-27,6.5-41.1c0.5-2.5-0.2-4.8-2-6.6
								c-1.7-2-3.8-2.9-6.4-2.7c-3.5,1.4-6.7,3.5-9.4,6.3c-3,2.4-5.4,5.3-7.2,8.7c0.1,13.4-1.7,26.6-5.6,39.5
								c-1,4.6,3.4,9.9,8.2,9.5c3.4-1.2,6.4-3.1,9-5.6C757.8,539.7,760.1,537,761.8,533.8z"/>
							<path opacity="0.1091" fill="#131313" d="M767.7,493.8c1-12.8,2-25.6,3-38.3c-0.5-2.9-2.2-4.7-4.9-5.5
								c-4.1-0.3-7.5,1.2-10.4,4.3c-5.4,4.2-10.5,8.7-15.2,13.7c-3.4,2.6-5.2,5.9-5.3,10c-1,12.3-2.1,24.7-3,37
								c0.5,2.8,2.1,4.7,4.8,5.6c4,0.4,7.5-0.9,10.4-3.9c5.4-4,10.5-8.3,15.2-13.1C765.7,501,767.5,497.8,767.7,493.8z"/>
							<path opacity="0.1091" fill="#131313" d="M785.6,520.7c0.8-12.7,1.8-25.4,2.9-38.1c-0.5-2.8-2.2-4.6-4.9-5.5
								c-4-0.3-7.4,1-10.2,4.1c-5.3,4-10.2,8.4-14.7,13.3c-3.3,2.5-5,5.8-5.1,9.7c-1.1,12.3-2,24.6-2.9,36.9
								c0.5,2.8,2.1,4.7,4.8,5.6c3.9,0.5,7.3-0.8,10.2-3.7c5.3-3.8,10.2-8.1,14.8-12.7C783.7,527.8,785.4,524.6,785.6,520.7z"/>
							<path opacity="0.1091" fill="#131313" d="M788.7,489.7c0.8-12.8,1.8-25.6,2.9-38.4c-0.1-3.2-1.8-5-4.9-5.5
								c-5.2,0.5-9.5,2.8-13.2,6.7c-7.2,5.7-14,11.9-20.4,18.5c-4.3,3.2-7,7.3-8,12.4c-1.3,12.2-2.2,24.5-3,36.7
								c0.1,3.2,1.7,5,4.8,5.6c5.1-0.4,9.5-2.5,13.2-6.3c7.2-5.5,14.1-11.4,20.5-17.7C784.9,498.8,787.6,494.7,788.7,489.7z"/>
							<path opacity="0.1091" fill="#131313" d="M748.6,520.4c3.6-13.5,5.7-27.2,6.4-41.1c0.4-4.5-3.5-9.7-8.4-9.3
								c-3.2,1.2-6.1,3-8.5,5.5c-2.7,2.2-4.8,4.8-6.3,7.9c-0.6,13.4-2.3,26.6-5.3,39.7c-0.8,4.3,3.8,9.7,8.2,9.5
								c3.1-0.9,5.8-2.5,8.1-4.8C745.2,525.7,747.2,523.3,748.6,520.4z"/>
							<path opacity="0.1091" fill="#131313" d="M771.1,551.2c2.5-13.4,4.1-26.9,4.7-40.5c0.3-4.2-3.6-9.6-8.2-9.5
								c-2.9,0.8-5.5,2.3-7.7,4.4c-2.4,1.9-4.3,4.2-5.5,6.9c-0.5,13.4-1.8,26.6-3.9,39.8c-0.5,4,4,9.5,8.2,9.5
								c2.8-0.7,5.2-2.1,7.3-4.1C768.3,556,770,553.8,771.1,551.2z"/>
							<path opacity="0.1091" fill="#131313" d="M800.4,516.3c0.8-12.8,1.8-25.6,2.9-38.3c-0.2-3.1-1.9-4.9-4.9-5.5
								c-4.8,0.2-8.9,2.2-12.3,5.9c-6.6,5.2-12.8,10.8-18.7,16.8c-4,3-6.4,6.8-7.2,11.6c-1.2,12.2-2.2,24.5-3,36.8
								c0.2,3.1,1.8,4.9,4.8,5.6c4.7-0.1,8.8-1.9,12.3-5.5c6.6-5,12.9-10.3,18.8-16.1C797.2,524.7,799.6,521,800.4,516.3z"/>
							<path opacity="0.1091" fill="#131313" d="M758.4,504.8c1.2-12.9,2.2-25.4,3.1-38.3c-0.7-2.6-2.4-4.5-4.9-5.5
								c-3.4-0.7-6.3,0.2-8.8,2.8c-4.4,3.2-8.4,6.9-12.1,10.9c-2.8,2.3-4.1,5.1-3.7,8.5c-0.5,6.3-0.9,12.7-1.4,19
								c0.1-1.3,0.2-2.5,0.3-3.8c-0.7,7.3-1.3,14.6-2,22c0.7,2.6,2.3,4.5,4.8,5.6c3.3,0.8,6.2,0,8.8-2.5c4.4-3.1,8.4-6.6,12.1-10.5
								C757.5,510.9,758.7,508.2,758.4,504.8z"/>
							<path opacity="0.1091" fill="#131313" d="M776.7,502.9c1-12.8,2-25.5,3-38.3c-0.4-2.9-2.1-4.7-4.9-5.5
								c-4.3-0.1-7.9,1.4-10.9,4.7c-5.7,4.4-11.1,9.3-16.1,14.6c-3.6,2.7-5.5,6.1-5.8,10.4c-1.1,12.3-2.1,24.6-3,36.9
								c0.4,2.9,2,4.8,4.8,5.6c4.2,0.3,7.8-1.2,10.9-4.3c5.8-4.2,11.1-8.9,16.2-13.9C774.4,510.4,776.4,507.1,776.7,502.9z"/>
							<path opacity="0.1091" fill="#131313" d="M797,532c0.9-12.7,1.8-25.5,2.9-38.2c-0.5-2.8-2.1-4.7-4.9-5.5
								c-4-0.3-7.5,1.1-10.4,4.2c-5.4,4.1-10.4,8.6-15.1,13.6c-3.4,2.6-5.1,5.8-5.3,9.9c-1.1,12.3-2.1,24.6-2.9,36.9
								c0.5,2.8,2.1,4.7,4.8,5.6c4,0.4,7.4-0.9,10.4-3.8c5.4-3.9,10.5-8.2,15.2-13C795,539.2,796.8,536,797,532z"/>
							<path opacity="0.1091" fill="#131313" d="M751.7,532.3c2.6-13.4,4.2-26.9,4.8-40.6c0.3-4.1-3.8-9.5-8.2-9.5
								c-2.8,0.7-5.3,2.2-7.4,4.2c-2.4,1.8-4.1,4-5.3,6.7c-0.5,13.4-1.8,26.7-4,39.9c0,2.4,0.8,4.4,2.5,6.1c1.4,1.9,3.3,3,5.7,3.4
								c2.7-0.6,5-1.9,7-3.9C749.1,537,750.7,534.9,751.7,532.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M787.1,609.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C777.8,605.9,782,608.3,787.1,609.1z"/>
							<path opacity="0.1091" fill="#131313" d="M762.7,590.1c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C756.4,589.5,759.3,590.6,762.7,590.1z"/>
							<path opacity="0.1091" fill="#131313" d="M762,556.2c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C757.2,554,759.4,555.4,762,556.2z"/>
							<path opacity="0.1091" fill="#131313" d="M783.9,582.8c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C776.7,581.4,780,582.9,783.9,582.8z"/>
							<path opacity="0.1091" fill="#131313" d="M779.1,552c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C773,548.5,775.9,550.6,779.1,552z"/>
							<path opacity="0.1091" fill="#131313" d="M745.1,578.4c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C739.9,575.8,742.3,577.4,745.1,578.4z"/>
							<path opacity="0.1091" fill="#131313" d="M770.1,610.9c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C762.2,608.9,765.8,610.7,770.1,610.9z"/>
							<path opacity="0.1091" fill="#131313" d="M798.1,579.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C790.1,577.5,793.7,579.3,798.1,579.5z"/>
							<path opacity="0.1091" fill="#131313" d="M753.3,566.8c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C748.1,564.2,750.5,565.8,753.3,566.8z"/>
							<path opacity="0.1091" fill="#131313" d="M769.6,563.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C763.1,559.4,766.1,561.7,769.6,563.3z"/>
							<path opacity="0.1091" fill="#131313" d="M796.7,595.6c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C787.6,592.6,791.7,594.9,796.7,595.6z"/>
							<path opacity="0.1091" fill="#131313" d="M745,589.3c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C738.7,585.6,741.6,587.7,745,589.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M723.1,539.5c-0.6-6.7-1.2-13.3-1.9-20c-0.4-4.7-6.3-8.3-10.7-6.5
								c-2.9,2.2-5.2,4.9-6.9,8.1c-2.1,3-3.4,6.2-4,9.8c0.7,6.3,1.5,12.6,2.2,18.9c0.5,4.5,6.2,8.3,10.6,6.7c2.8-2,5-4.5,6.7-7.6
								C721.2,546,722.5,542.9,723.1,539.5z"/>
							<path opacity="0.1091" fill="#131313" d="M699.8,524c-0.4-7-0.9-13.9-1.3-20.9c-0.3-4.9-6.2-8.5-10.7-6.5
								c-3,2.4-5.4,5.3-7.3,8.7c-2.2,3.2-3.6,6.7-4.4,10.4c0.6,6.6,1.2,13.1,1.7,19.7c0.4,4.7,6.1,8.5,10.6,6.6
								c2.9-2.2,5.3-4.9,7.1-8.2C697.6,530.9,699,527.6,699.8,524z"/>
							<path opacity="0.1091" fill="#131313" d="M704,482.9c-1.3-5.6-2.6-11.2-3.9-16.8c-1.2-2.8-3.3-4-6.3-3.8
								c-4.3,1.3-7.5,4.1-9.5,8.3c-4.5,6.3-8.4,12.9-12,19.8c-2.8,3.8-3.8,7.9-2.9,12.3c1.2,5.3,2.4,10.5,3.7,15.8
								c1.2,2.8,3.3,4.1,6.2,3.9c4.3-1.2,7.4-3.9,9.5-8c4.5-6.2,8.5-12.6,12.1-19.4C703.8,491.3,704.8,487.3,704,482.9z"/>
							<path opacity="0.1091" fill="#131313" d="M721.8,510.2c-1.4-5.4-2.7-10.9-4.1-16.3c-1.4-2.5-3.5-3.8-6.3-3.8
								c-3.8,0.7-6.5,2.8-8.3,6.4c-3.7,5.1-7,10.5-9.7,16.1c-2.3,3.3-2.9,6.7-1.8,10.3c1.3,5.2,2.5,10.4,3.8,15.6
								c1.3,2.5,3.4,3.8,6.2,3.9c3.8-0.6,6.6-2.6,8.4-6.2c3.8-5,7.1-10.3,9.9-15.8C722.3,517.3,722.9,513.9,721.8,510.2z"/>
							<path opacity="0.1091" fill="#131313" d="M724.5,478.2c-1.3-5.6-2.7-11.2-4-16.8c-1-3.1-3.1-4.4-6.3-3.8
								c-4.9,2.2-8.6,5.8-11.1,10.9c-5.5,8-10.5,16.2-15,24.8c-3.3,4.5-4.9,9.4-4.5,14.8c1.3,5.2,2.5,10.4,3.8,15.5
								c0.9,3.1,3,4.4,6.2,3.9c4.9-2.1,8.6-5.6,11.1-10.6c5.5-7.8,10.6-15.9,15.2-24.2C723.3,488.4,724.8,483.6,724.5,478.2z"/>
							<path opacity="0.1091" fill="#131313" d="M685.7,511c-0.5-6.8-1-13.7-1.5-20.5c-0.4-4.9-6.2-8.5-10.7-6.5
								c-3,2.4-5.4,5.4-7.3,8.8c-2.2,3.2-3.7,6.7-4.4,10.5c0.6,6.4,1.2,12.9,1.9,19.3c0.5,4.8,6.1,8.5,10.6,6.6
								c2.9-2.3,5.3-5,7.1-8.3C683.5,518,685,514.7,685.7,511z"/>
							<path opacity="0.1091" fill="#131313" d="M707.7,541.6c-0.9-6.4-1.8-12.9-2.7-19.3c-0.7-2.2-2.2-3.9-4.2-5
								c-1.9-1.4-4-1.9-6.4-1.6c-2.3,1.3-4.1,3.1-5.4,5.5c-1.6,2.2-2.4,4.6-2.6,7.2c1.1,6.2,2.1,12.4,3.2,18.6
								c0.8,2.2,2.2,3.8,4.3,4.9c1.9,1.4,4,2,6.3,1.7c2.2-1.2,3.9-2.9,5.2-5.1C706.8,546.4,707.6,544.1,707.7,541.6z"/>
							<path opacity="0.1091" fill="#131313" d="M735.8,506.6c-1.3-5.6-2.6-11.2-3.9-16.8c-1.2-2.7-3.3-4-6.3-3.8
								c-4.3,1.3-7.4,4-9.4,8.2c-4.4,6.2-8.4,12.8-11.9,19.6c-2.8,3.7-3.7,7.8-2.9,12.1c1.2,5.3,2.4,10.5,3.7,15.8
								c1.2,2.7,3.3,4,6.2,3.9c4.2-1.2,7.4-3.8,9.5-7.9c4.5-6.1,8.5-12.5,12-19.1C735.6,514.9,736.6,510.9,735.8,506.6z"/>
							<path opacity="0.1091" fill="#131313" d="M694.8,494.2c-1.4-5.5-2.7-10.9-4.1-16.4c-1.4-2.5-3.5-3.7-6.3-3.8
								c-3.7,0.6-6.5,2.7-8.2,6.3c-3.7,5-6.8,10.3-9.5,15.9c-2.3,3.2-2.9,6.6-1.7,10.2c1.3,5.2,2.5,10.5,3.8,15.7
								c1.4,2.5,3.4,3.8,6.3,3.9c3.7-0.5,6.5-2.6,8.3-6.1c3.7-4.9,6.9-10.1,9.7-15.6C695.3,501.2,695.9,497.8,694.8,494.2z"/>
							<path opacity="0.1091" fill="#131313" d="M713,492.4c-1.3-5.6-2.6-11.2-3.9-16.8c-1.2-2.7-3.3-4-6.3-3.8
								c-4.3,1.3-7.4,4-9.4,8.2c-4.4,6.2-8.4,12.8-11.8,19.6c-2.8,3.7-3.7,7.8-2.9,12.1c1.2,5.3,2.4,10.5,3.7,15.8
								c1.2,2.7,3.3,4,6.2,3.9c4.2-1.2,7.4-3.8,9.5-7.9c4.5-6.1,8.4-12.5,12-19.1C712.8,500.7,713.8,496.7,713,492.4z"/>
							<path opacity="0.1091" fill="#131313" d="M733.1,521.8c-1.3-5.5-2.6-11-4-16.5c-1.4-2.4-3.5-3.7-6.3-3.8
								c-3.6,0.5-6.3,2.4-8,5.8c-3.5,4.7-6.5,9.7-9.1,15c-2.2,3.1-2.7,6.4-1.5,9.8c1.2,5.3,2.5,10.5,3.7,15.8
								c1.4,2.4,3.5,3.7,6.2,3.9c3.6-0.4,6.3-2.3,8-5.6c3.5-4.6,6.6-9.5,9.2-14.7C733.7,528.4,734.3,525.2,733.1,521.8z"/>
							<path opacity="0.1091" fill="#131313" d="M689.2,521.4c-0.9-6.4-1.9-12.8-2.8-19.2c-0.6-4.3-6.5-8.1-10.6-6.6
								c-2.6,1.7-4.7,4-6.2,6.8c-1.8,2.6-3,5.4-3.4,8.6c1.1,6.2,2.1,12.4,3.2,18.6c0.7,4.1,6.5,8,10.6,6.6c2.5-1.6,4.6-3.8,6-6.5
								C687.8,527.2,688.9,524.4,689.2,521.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M677.9,445.5c-1.2-4.7-2.4-9.3-3.6-14c-1.1-2.2-2.8-3.6-5.2-4.2
								c-2.3-0.9-4.5-0.8-6.7,0.4c-2.1,2.4-3.6,5.1-4.4,8.2c-1.2,3-1.6,6.1-1.3,9.2c1.3,4.3,2.6,8.6,3.9,12.8
								c1.1,2.1,2.9,3.5,5.3,4.1c2.3,0.9,4.5,0.8,6.7-0.2c2.1-2.2,3.5-4.7,4.2-7.6C677.9,451.3,678.3,448.4,677.9,445.5z"/>
							<path opacity="0.1091" fill="#131313" d="M655.5,428.6c-1.2-4.4-2.3-8.8-3.5-13.2c-1.3-4.9-8.1-6.7-12-3.7
								c-2.4,3.2-4,6.7-5,10.6c-1.4,3.7-2,7.6-1.8,11.5c1.2,4,2.4,8,3.6,12.1c1.4,4.7,8,6.8,11.9,3.9c2.3-3,4-6.4,5-10.1
								C655,436.1,655.7,432.4,655.5,428.6z"/>
							<path opacity="0.1091" fill="#131313" d="M658.1,387c-1.6-3.1-3.3-6.2-4.9-9.3c-1.8-2.5-4.1-3.3-7-2.2
								c-4,2.7-6.5,6.4-7.5,11.3c-3,7.8-5.5,15.8-7.5,24c-1.9,4.6-2,9.1-0.2,13.6c1.5,2.8,3.1,5.6,4.6,8.4c1.8,2.5,4.1,3.3,7,2.3
								c4-2.6,6.5-6.2,7.6-11.1c3.1-7.7,5.6-15.6,7.6-23.6C659.7,395.9,659.9,391.4,658.1,387z"/>
							<path opacity="0.1091" fill="#131313" d="M675.8,415.3c-1.6-3-3.3-6-4.9-9c-1.9-2-4.3-2.7-7-2.2c-3.4,1.4-5.6,4-6.4,7.7
								c-2.3,5.5-4.1,11.2-5.3,17.1c-1.4,3.6-1.1,6.9,0.9,10c1.5,2.9,3,5.7,4.6,8.6c1.9,2,4.3,2.8,7,2.3c3.4-1.3,5.6-3.9,6.5-7.6
								c2.4-5.5,4.2-11.1,5.5-16.9C678.1,421.7,677.8,418.4,675.8,415.3z"/>
							<path opacity="0.1091" fill="#131313" d="M677.8,382.6c-1.6-3.1-3.3-6.1-4.9-9.2c-1.7-2.8-4-3.6-7-2.2
								c-4.3,3.4-7,7.8-8.2,13.3c-3.4,9.1-6.3,18.4-8.7,27.8c-2.2,5.2-2.5,10.4-0.9,15.6c1.5,2.8,3.1,5.5,4.6,8.3
								c1.7,2.8,4,3.6,7,2.3c4.3-3.2,7-7.6,8.3-13c3.5-9,6.4-18.1,8.9-27.4C679.1,392.9,679.4,387.8,677.8,382.6z"/>
							<path opacity="0.1091" fill="#131313" d="M641.7,414.3c-1.4-3.6-2.8-7.2-4.3-10.7c-0.7-2.6-2.4-4.3-5-5.1
								c-2.6-0.9-4.9-0.4-7,1.4c-2.5,3.6-4.3,7.5-5.4,11.7c-1.5,4.1-2.2,8.4-2.2,12.7c1.4,3.3,2.8,6.7,4.2,10
								c0.7,2.6,2.3,4.3,4.9,5.1c2.6,0.9,4.9,0.5,7-1.2c2.5-3.5,4.3-7.3,5.4-11.4C640.9,422.7,641.7,418.5,641.7,414.3z"/>
							<path opacity="0.1091" fill="#131313" d="M663.2,444.8c-1.5-3.8-2.9-7.6-4.4-11.5c-1.3-1.9-3.1-3.2-5.4-3.7
								c-2.2-0.9-4.4-0.9-6.5-0.1c-1.9,1.7-3.2,3.8-3.8,6.3c-1,2.4-1.2,4.9-0.6,7.4c1.5,3.6,3.1,7.2,4.6,10.7
								c1.3,1.9,3.1,3.1,5.4,3.7c2.2,0.9,4.3,0.9,6.5,0.2c1.9-1.6,3.1-3.6,3.7-6C663.6,449.5,663.7,447.2,663.2,444.8z"/>
							<path opacity="0.1091" fill="#131313" d="M689.7,412c-1.6-3.1-3.2-6.3-4.8-9.4c-1.9-2.2-4.2-3-7-2.2c-3.7,1.9-6,4.9-6.9,9.1
								c-2.6,6.4-4.7,13-6.2,19.8c-1.6,4-1.5,7.8,0.4,11.4c1.5,2.9,3,5.8,4.5,8.7c1.9,2.2,4.2,3,7,2.3c3.7-1.8,6-4.8,6.9-8.9
								c2.6-6.3,4.8-12.8,6.4-19.5C691.7,419.4,691.6,415.6,689.7,412z"/>
							<path opacity="0.1091" fill="#131313" d="M648.7,399.5c-1.6-3.1-3.2-6.3-4.8-9.4c-1.9-2.2-4.2-2.9-7-2.2
								c-3.6,1.8-5.9,4.8-6.8,9c-2.5,6.3-4.6,12.8-6,19.5c-1.6,3.9-1.4,7.7,0.5,11.3c1.5,2.9,3,5.8,4.5,8.7c1.9,2.2,4.2,3,7,2.3
								c3.6-1.8,5.9-4.7,6.9-8.8c2.6-6.2,4.7-12.6,6.2-19.2C650.8,406.8,650.6,403.1,648.7,399.5z"/>
							<path opacity="0.1091" fill="#131313" d="M667.1,397.1c-1.6-3.1-3.2-6.2-4.8-9.3c-1.8-2.4-4.2-3.1-7-2.2
								c-3.9,2.4-6.3,5.8-7.3,10.5c-2.9,7.3-5.2,14.7-7,22.4c-1.8,4.4-1.8,8.6,0,12.7c1.5,2.8,3,5.7,4.6,8.5c1.8,2.4,4.1,3.2,7,2.3
								c3.9-2.3,6.3-5.7,7.3-10.3c2.9-7.2,5.3-14.5,7.1-22C668.8,405.4,668.9,401.2,667.1,397.1z"/>
							<path opacity="0.1091" fill="#131313" d="M688.6,430.8c-1.5-3.4-2.9-6.8-4.4-10.2c-0.6-2.7-2.3-4.4-4.9-5.2
								c-2.6-0.9-5-0.4-7,1.5c-2.5,3.7-4.4,7.7-5.5,12.1c-1.5,4.2-2.3,8.6-2.3,13.1c1.4,3.2,2.9,6.4,4.3,9.6
								c0.6,2.7,2.3,4.4,4.9,5.2c2.6,0.9,4.9,0.5,7-1.3c2.5-3.6,4.4-7.5,5.6-11.8C687.7,439.4,688.5,435.2,688.6,430.8z"/>
							<path opacity="0.1091" fill="#131313" d="M643.4,428c-1.2-4.5-2.5-9.1-3.7-13.6c-1.2-4.4-8.4-6.3-11.9-3.8
								c-2.2,2.6-3.7,5.6-4.6,8.9c-1.2,3.2-1.7,6.5-1.5,10c1.3,4.3,2.6,8.5,4,12.8c1.3,4.3,8.4,6.2,11.9,3.8
								c2.2-2.5,3.7-5.3,4.5-8.5C643.2,434.4,643.7,431.2,643.4,428z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M641.2,376.3c-3.3-4.1-6.5-8.1-9.8-12.2c-1.7-1.6-3.8-2.4-6.3-2.2
								c-2.4-0.2-4.5,0.6-6.3,2.2c-1.3,2.7-1.8,5.5-1.7,8.5c-0.2,3,0.4,5.8,1.7,8.5c3.3,3.8,6.5,7.6,9.8,11.4
								c1.8,1.6,3.9,2.3,6.3,2.1c2.4,0.2,4.5-0.6,6.3-2.1c1.3-2.5,1.8-5.2,1.7-8.1C643,381.5,642.5,378.8,641.2,376.3z"/>
							<path opacity="0.1091" fill="#131313" d="M618.8,360.6c-3.1-3.8-6.2-7.5-9.4-11.3c-1.5-2.3-3.6-3.4-6.3-3.3
								c-2.7,0-4.8,1.1-6.2,3.4c-1.2,4.1-1.7,8.3-1.5,12.7c-0.1,4.3,0.5,8.5,1.8,12.6c3,3.5,6,7,9,10.5c1.5,2.2,3.6,3.3,6.3,3.3
								c2.7,0.1,4.8-1,6.3-3.3c1.3-4,1.9-8.1,1.6-12.3C620.7,368.7,620.1,364.6,618.8,360.6z"/>
							<path opacity="0.1091" fill="#131313" d="M619.1,319.5c-3.2-3.1-6.4-6.2-9.6-9.3c-2.5-2-4.9-2-7.4,0.1c-3,4.2-4.2,9-3.7,14.3
								c-0.4,9.2-0.3,18.3,0.3,27.5c-0.4,5.4,0.9,10.1,4,14.2c3,2.8,5.9,5.7,8.9,8.5c2.5,2,4.9,2,7.4,0c3.1-4.1,4.3-8.8,3.8-14.1
								c0.5-9.1,0.5-18.1,0-27.2C623.4,328.3,622.2,323.6,619.1,319.5z"/>
							<path opacity="0.1091" fill="#131313" d="M637.4,349c-3.1-3-6.3-6-9.4-9c-2.5-1.3-4.9-1.2-7.4,0.1c-2.8,2.3-4,5.3-3.7,9
								c-0.5,5.8-0.4,11.5,0.2,17.3c-0.2,3.8,1.1,6.7,3.9,8.9c3,2.9,6,5.8,9,8.7c2.5,1.3,4.9,1.3,7.4,0c2.8-2.2,4.1-5.2,3.8-8.9
								c0.6-5.7,0.6-11.4,0-17.2C641.4,354.1,640.2,351.2,637.4,349z"/>
							<path opacity="0.1091" fill="#131313" d="M638.2,316.5c-3.2-3.1-6.4-6.2-9.6-9.3c-2.5-2.1-4.9-2.1-7.4,0.1
								c-3,4.4-4.3,9.4-3.7,15c-0.5,9.6-0.4,19.2,0.3,28.7c-0.5,5.5,0.9,10.5,4,14.9c3,2.8,5.9,5.6,8.9,8.5c2.5,2.1,4.9,2.1,7.4,0
								c3.1-4.3,4.4-9.2,3.8-14.7c0.6-9.5,0.6-18.9,0-28.4C642.5,325.8,641.2,320.9,638.2,316.5z"/>
							<path opacity="0.1091" fill="#131313" d="M601.7,343.5c-3.1-3-6.3-6-9.4-9c-2.5-1.3-4.9-1.3-7.4,0.1c-2.8,2.4-4,5.6-3.7,9.5
								c-0.5,6.1-0.4,12.1,0.3,18.2c-0.2,3.9,1.1,7,4,9.4c3,2.9,6,5.8,9,8.7c2.5,1.4,4.9,1.3,7.4,0c2.8-2.4,4.1-5.5,3.8-9.4
								c0.5-6,0.5-12,0-18.1C605.8,349,604.6,345.9,601.7,343.5z"/>
							<path opacity="0.1091" fill="#131313" d="M625.3,379.6c-3.3-4.1-6.5-8.1-9.8-12.2c-1.8-1.5-3.9-2.1-6.3-1.9
								c-2.4-0.2-4.4,0.5-6.3,2c-1.3,2.3-1.8,4.7-1.7,7.3c-0.2,2.6,0.4,5.1,1.7,7.3c3.3,3.8,6.5,7.5,9.8,11.3c1.8,1.4,3.9,2,6.3,1.8
								c2.3,0.2,4.4-0.5,6.3-1.8c1.3-2.1,1.8-4.4,1.7-6.9C627.1,383.9,626.6,381.7,625.3,379.6z"/>
							<path opacity="0.1091" fill="#131313" d="M651.3,345.4c-3.1-3-6.3-6-9.4-9c-2.5-1.4-4.9-1.3-7.4,0.1
								c-2.8,2.5-4.1,5.7-3.7,9.7c-0.5,6.2-0.4,12.3,0.2,18.5c-0.3,4,1,7.1,3.9,9.6c3,2.9,6,5.7,9,8.6c2.5,1.4,4.9,1.4,7.4,0
								c2.9-2.4,4.1-5.6,3.8-9.5c0.6-6.1,0.6-12.3,0-18.4C655.4,351.1,654.1,347.9,651.3,345.4z"/>
							<path opacity="0.1091" fill="#131313" d="M610.3,332.6c-3.2-3.1-6.3-6.3-9.5-9.4c-2.5-1.7-4.9-1.7-7.4,0.1
								c-2.9,3.4-4.2,7.5-3.7,12.2c-0.4,7.8-0.3,15.6,0.3,23.4c-0.4,4.7,1,8.7,4,12.1c3,2.9,6,5.8,8.9,8.7c2.5,1.7,4.9,1.7,7.4,0
								c3-3.3,4.2-7.3,3.8-12c0.5-7.7,0.5-15.4,0-23.1C614.6,339.9,613.3,336,610.3,332.6z"/>
							<path opacity="0.1091" fill="#131313" d="M628.5,330.6c-3.2-3.1-6.3-6.2-9.5-9.4c-2.5-1.8-4.9-1.8-7.4,0.1
								c-3,3.6-4.2,7.8-3.7,12.6c-0.5,8.1-0.4,16.1,0.3,24.1c-0.4,4.8,0.9,9,4,12.5c3,2.9,6,5.8,8.9,8.7c2.5,1.8,4.9,1.8,7.4,0
								c3-3.5,4.3-7.6,3.8-12.4c0.6-8,0.6-15.9,0-23.9C632.7,338.3,631.5,334.1,628.5,330.6z"/>
							<path opacity="0.1091" fill="#131313" d="M651.3,365.6c-3.1-4.4-6.3-8.8-9.4-13.2c-2.9-4.1-9.7-4.1-12.5,0.1
								c-1.3,3.8-1.8,7.7-1.5,11.7c-0.2,4,0.4,7.9,1.8,11.6c3,4.1,6.1,8.1,9.1,12.2c2.9,3.9,9.6,4.1,12.5,0
								c1.3-3.6,1.9-7.3,1.7-11.2C653.1,373,652.6,369.3,651.3,365.6z"/>
							<path opacity="0.1091" fill="#131313" d="M606.2,361.3c-3.1-4.2-6.3-8.4-9.4-12.6c-3-4.1-9.7-4.2-12.5,0.2
								c-1.2,3.9-1.7,7.8-1.5,11.9c-0.1,4.1,0.5,8,1.8,11.9c3,3.9,6.1,7.8,9.1,11.7c1.5,2.1,3.6,3.1,6.3,3c2.6,0.1,4.7-0.9,6.3-3.1
								c1.3-3.7,1.8-7.6,1.6-11.5C608.1,368.9,607.6,365.1,606.2,361.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M891.1,773.6c6.8-16.2,13.8-32.3,20.7-48.4c0.2-2.8-0.7-5-2.9-6.8
								c-3.1-1.7-6.3-1.8-9.6-0.1c-5.4,1.7-10.6,3.9-15.6,6.6c-3.5,1.2-5.7,3.5-6.6,6.9c-6.8,15.9-13.6,31.7-20.4,47.5
								c-0.3,2.7,0.6,5,2.7,6.8c3,1.8,6.2,1.9,9.5,0.3c5.4-1.5,10.6-3.6,15.5-6.2C887.9,779.2,890.1,776.9,891.1,773.6z"/>
							<path opacity="0.1091" fill="#131313" d="M865.9,760.5c5.4-7.8,9.7-16.1,13-25c3.3-8.2,6.8-16.3,10.3-24.4
								c1.3-2.2,1.3-4.5,0.2-6.9c-0.9-2.4-2.6-4-5.1-4.6c-3.8,0.2-7.3,1.1-10.7,2.8c-3.6,1.3-6.7,3.2-9.4,5.8
								c-3.6,8-7,16.2-10.2,24.3c-3.1,8.5-7.1,16.5-12,24.1c-2.2,4.1-0.1,10.4,4.7,11.6c3.6,0,7-0.8,10.3-2.3
								C860.3,764.7,863.3,762.9,865.9,760.5z"/>
							<path opacity="0.1091" fill="#131313" d="M871.8,722.4c6.9-16.2,13.8-32.3,20.7-48.4c0.2-2.8-0.8-5-2.9-6.8
								c-3.1-1.7-6.2-1.8-9.5-0.1c-5.4,1.7-10.5,3.9-15.5,6.6c-3.5,1.2-5.7,3.5-6.5,7c-6.8,15.9-13.6,31.7-20.5,47.5
								c-0.2,2.7,0.7,5,2.8,6.8c3,1.8,6.1,1.9,9.4,0.3c5.3-1.6,10.5-3.6,15.4-6.2C868.7,728.1,870.9,725.8,871.8,722.4z"/>
							<path opacity="0.1091" fill="#131313" d="M889.5,749.3c6.8-16.2,13.8-32.3,20.7-48.5c0.4-2.8-0.5-5.1-2.9-6.8
								c-3.7-1.6-7.4-1.4-11.1,0.5c-6.4,2.1-12.5,4.7-18.4,7.8c-4,1.3-6.7,3.8-8.1,7.6c-6.8,15.8-13.6,31.5-20.4,47.4
								c-0.5,2.8,0.4,5.1,2.7,6.8c3.6,1.7,7.2,1.6,10.9-0.3c6.3-1.9,12.4-4.4,18.3-7.3C885.4,755.4,888.1,753,889.5,749.3z"/>
							<path opacity="0.1091" fill="#131313" d="M891.6,719.8c6.9-16.2,13.9-32.4,20.9-48.6c0.7-3-0.3-5.2-2.9-6.8
								c-4.4-1.4-8.7-0.9-13,1.4c-7.6,2.6-14.9,5.8-22.1,9.4c-4.6,1.5-7.9,4.3-10,8.4c-6.8,15.8-13.7,31.5-20.4,47.3
								c-0.7,2.9,0.2,5.2,2.7,6.8c4.3,1.5,8.6,1.1,12.8-1c7.5-2.4,14.8-5.4,21.9-8.8C886.2,726.6,889.5,723.9,891.6,719.8z"/>
							<path opacity="0.1091" fill="#131313" d="M852.6,747.7c4.9-8.2,9.2-16.7,12.8-25.5c3.6-8.2,7.2-16.5,10.8-24.7
								c1.7-3.8-0.5-10.3-4.7-11.6c-2.9-0.2-5.8,0.3-8.4,1.6c-2.8,0.9-5.2,2.5-7.2,4.7c-3.6,8.2-7.2,16.4-10.6,24.6
								c-3.4,8.6-7.4,17-11.9,25.1c-0.8,2.2-0.6,4.4,0.4,6.6c0.7,2.3,2.2,4,4.3,5c2.7,0.3,5.4-0.2,7.9-1.4
								C848.6,751.3,850.8,749.8,852.6,747.7z"/>
							<path opacity="0.1091" fill="#131313" d="M872.3,780.3c5.8-8,10.6-16.6,14.3-25.8c3.7-8,7.2-16.1,10.5-24.2
								c1.1-2.2,1.1-4.5,0-6.8c-0.9-2.4-2.5-4-4.9-4.7c-3.5,0.1-6.8,0.9-9.9,2.4c-3.3,1.2-6.2,3-8.6,5.4c-3.3,8-6.7,15.9-10.2,23.8
								c-3.5,8.8-7.9,17.2-13.2,25.1c-2.2,3.8,0.4,10.4,4.6,11.6c3.2,0.1,6.3-0.5,9.3-1.9C867.3,784.3,870,782.6,872.3,780.3z"/>
							<path opacity="0.1091" fill="#131313" d="M904.9,745.1c6.8-16.2,13.9-32.4,20.8-48.6c0.8-3-0.1-5.3-2.9-6.8
								c-4.8-1.3-9.4-0.7-13.9,1.8c-8.2,2.9-16.2,6.3-24,10.2c-4.9,1.5-8.6,4.5-11,8.8c-6.7,15.8-13.7,31.4-20.3,47.2
								c-0.9,3,0,5.3,2.7,6.8c4.7,1.4,9.3,0.9,13.8-1.4c8.1-2.7,16.1-5.8,23.8-9.5C898.9,752.2,902.5,749.3,904.9,745.1z"/>
							<path opacity="0.1091" fill="#131313" d="M858.7,737.5c6-7.8,10.6-16.2,13.9-25.3c3.3-8.2,6.8-16.3,10.3-24.4
								c1.4-2.2,1.5-4.6,0.4-7c-1-2.5-2.7-4-5.3-4.5c-4,0.3-7.8,1.3-11.4,3.1c-3.8,1.4-7.1,3.4-10.1,6.1c-3.6,8.1-7,16.2-10.2,24.3
								c-3.1,8.7-7.4,16.8-12.8,24.4c-2.4,4.2-0.2,10.5,4.7,11.6c3.8-0.1,7.4-1,10.9-2.6C852.7,742,855.9,740.1,858.7,737.5z"/>
							<path opacity="0.1091" fill="#131313" d="M880.6,731.5c6.8-16.2,13.8-32.3,20.7-48.5c0.4-2.8-0.6-5.1-2.9-6.8
								c-3.6-1.6-7.1-1.5-10.8,0.4c-6.2,2-12.1,4.6-17.8,7.6c-3.9,1.3-6.5,3.8-7.8,7.5c-6.8,15.8-13.6,31.6-20.3,47.4
								c-0.4,2.8,0.5,5.1,2.7,6.8c3.5,1.7,7,1.7,10.6-0.2c6.1-1.9,12-4.2,17.7-7.1C876.7,737.6,879.3,735.2,880.6,731.5z"/>
							<path opacity="0.1091" fill="#131313" d="M901.1,760.5c6.9-16.2,13.9-32.4,20.9-48.6c0.6-2.9-0.4-5.2-2.9-6.8
								c-4.1-1.5-8.2-1.1-12.3,1c-7.1,2.4-14,5.3-20.8,8.8c-4.4,1.4-7.5,4.1-9.3,8.1c-6.8,15.8-13.7,31.5-20.4,47.3
								c-0.6,2.9,0.3,5.2,2.7,6.8c4,1.6,8.1,1.3,12.1-0.7c7.1-2.2,13.9-5,20.6-8.2C896.2,767.1,899.3,764.4,901.1,760.5z"/>
							<path opacity="0.1091" fill="#131313" d="M853.9,760.9c5-8.2,9.3-16.7,12.9-25.5c3.6-8.2,7.2-16.5,10.8-24.7
								c0.8-2.2,0.6-4.4-0.4-6.6c-0.7-2.3-2.2-4-4.2-5c-2.7-0.3-5.3,0.2-7.8,1.3c-2.6,0.9-4.8,2.3-6.6,4.4
								c-3.6,8.2-7.2,16.4-10.6,24.6c-3.4,8.6-7.4,17-11.9,25.1c-0.6,2.2-0.4,4.4,0.6,6.5c0.7,2.2,2.1,3.9,4.1,5.1
								c2.5,0.4,4.9,0,7.2-1.1C850.4,764.3,852.4,762.9,853.9,760.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M881.6,725.9c0.2-7.6,0.5-15.3,0.7-22.9c0.2-4.9-5.3-9.1-10-7.6
								c-3.2,2-5.9,4.7-8,7.8c-2.5,2.9-4.3,6.2-5.4,9.8c-0.1,7.2-0.1,14.5-0.2,21.7c0,4.7,5.2,9,9.9,7.7c3.1-1.9,5.7-4.3,7.8-7.3
								C878.8,732.4,880.5,729.3,881.6,725.9z"/>
							<path opacity="0.1091" fill="#131313" d="M859.1,709c0.2-7.6,0.4-15.2,0.6-22.9c0.1-4.9-5.3-9.1-10-7.6
								c-3.2,2.1-5.9,4.7-8.1,7.9c-2.5,3-4.3,6.3-5.4,9.9c0,7.2-0.1,14.4-0.1,21.7c0,4.7,5.2,9,9.9,7.7c3.1-1.9,5.7-4.4,7.8-7.4
								C856.3,715.7,858.1,712.5,859.1,709z"/>
							<path opacity="0.1091" fill="#131313" d="M864,668.3c-0.8-6.3-1.6-12.7-2.3-19c-1-2.8-2.9-4.3-5.9-4.4c-4.3,0.8-7.6,3.1-10,7
								c-4.9,5.6-9.4,11.6-13.4,17.9c-3.1,3.4-4.4,7.2-3.9,11.6c0.7,6,1.5,12,2.2,17.9c0.9,2.8,2.9,4.3,5.8,4.5
								c4.3-0.7,7.6-2.9,10-6.7c4.9-5.5,9.5-11.3,13.5-17.4C863,676.4,864.4,672.6,864,668.3z"/>
							<path opacity="0.1091" fill="#131313" d="M881.6,695.6c-0.8-6.3-1.6-12.5-2.4-18.8c-1.1-2.6-3.1-4.1-5.9-4.4
								c-3.9,0.3-6.9,2.2-9.1,5.7c-4.3,4.8-8.2,9.9-11.6,15.4c-2.7,3-3.7,6.5-3,10.3c0.7,6,1.5,11.9,2.2,17.9c1.1,2.6,3,4.1,5.8,4.5
								c3.8-0.2,6.9-2.1,9.1-5.4c4.3-4.7,8.2-9.7,11.7-15C881.3,702.8,882.3,699.4,881.6,695.6z"/>
							<path opacity="0.1091" fill="#131313" d="M884.8,663.7c-0.8-6.3-1.7-12.7-2.5-19c-0.7-3.2-2.6-4.7-5.9-4.4
								c-5.1,1.7-9.1,4.9-12,9.6c-6.2,7.3-12,15-17.3,23c-3.8,4.1-5.7,8.9-5.9,14.2c0.8,5.9,1.6,11.8,2.3,17.7
								c0.6,3.2,2.6,4.7,5.8,4.5c5.1-1.5,9.1-4.6,12.1-9.3c6.2-7.1,12-14.6,17.4-22.4C882.5,673.6,884.6,669,884.8,663.7z"/>
							<path opacity="0.1091" fill="#131313" d="M846.3,694.7c-0.2-7.1-0.3-14.1-0.5-21.2c-0.1-4.8-5.3-9-10-7.5
								c-3.1,2-5.7,4.6-7.8,7.7c-2.4,2.9-4.1,6.1-5.2,9.7c0.3,6.7,0.6,13.4,0.8,20.1c0.2,4.6,5.1,9,9.9,7.7c3.1-1.8,5.7-4.3,7.7-7.2
								C843.5,701.2,845.2,698.1,846.3,694.7z"/>
							<path opacity="0.1091" fill="#131313" d="M867.5,726.5c-0.3-7.1-0.6-14.3-1-21.4c-0.5-2.3-1.7-4.2-3.6-5.5
								c-1.8-1.6-3.9-2.3-6.2-2.2c-2.5,1.1-4.6,2.9-6.2,5.2c-1.9,2.1-3,4.5-3.5,7.2c0.5,6.9,1,13.8,1.5,20.7
								c0.5,2.3,1.8,4.1,3.7,5.4c1.8,1.6,3.8,2.3,6.2,2.3c2.4-1,4.4-2.6,5.9-4.8C865.9,731.4,867,729.1,867.5,726.5z"/>
							<path opacity="0.1091" fill="#131313" d="M896,691.5c-0.8-6.3-1.6-12.6-2.4-19c-0.9-2.9-2.8-4.4-5.9-4.4
								c-4.5,1-8,3.5-10.5,7.6c-5.2,6-10,12.3-14.4,19c-3.2,3.5-4.7,7.6-4.4,12.2c0.7,5.9,1.5,11.9,2.2,17.8
								c0.8,2.9,2.8,4.4,5.8,4.5c4.5-0.8,8-3.3,10.5-7.3c5.3-5.8,10.1-12,14.5-18.5C894.8,700,896.3,696,896,691.5z"/>
							<path opacity="0.1091" fill="#131313" d="M854.7,679.4c-0.8-6.2-1.7-12.4-2.5-18.6c-1.2-2.5-3.1-4-5.9-4.4
								c-3.7,0.2-6.5,1.9-8.6,5.1c-4,4.4-7.6,9.2-10.7,14.3c-2.5,2.9-3.4,6.1-2.5,9.7c0.8,6,1.5,11.9,2.3,17.9
								c1.1,2.6,3.1,4.1,5.8,4.5c3.6-0.1,6.5-1.7,8.6-4.9c4-4.3,7.7-8.9,10.8-13.9C854.7,686.2,855.6,683,854.7,679.4z"/>
							<path opacity="0.1091" fill="#131313" d="M873,677.6c-0.8-6.3-1.6-12.7-2.3-19c-0.9-2.8-2.9-4.3-5.9-4.4
								c-4.3,0.8-7.7,3.2-10.1,7.1c-5,5.7-9.5,11.7-13.6,18.1c-3.1,3.4-4.4,7.3-4,11.7c0.7,6,1.5,11.9,2.2,17.9
								c0.9,2.8,2.8,4.3,5.8,4.5c4.3-0.7,7.7-3,10.1-6.8c5-5.5,9.6-11.4,13.7-17.6C872,685.8,873.3,681.9,873,677.6z"/>
							<path opacity="0.1091" fill="#131313" d="M893.2,706.8c-0.9-6.2-1.7-12.4-2.6-18.6c-1.1-2.6-3.1-4.1-5.9-4.4
								c-3.8,0.2-6.7,2-8.9,5.4c-4.2,4.6-7.9,9.5-11.2,14.8c-2.6,3-3.6,6.3-2.8,10c0.8,5.9,1.6,11.9,2.3,17.8c1.1,2.6,3,4.1,5.8,4.5
								c3.8-0.2,6.7-1.9,8.9-5.2c4.2-4.5,8-9.3,11.3-14.5C893,713.7,893.9,710.5,893.2,706.8z"/>
							<path opacity="0.1091" fill="#131313" d="M848.8,706.8c-0.3-7.1-0.7-14.2-1-21.3c-0.2-4.2-5.7-8.6-9.9-7.7
								c-2.7,1.4-5,3.4-6.7,5.9c-2,2.3-3.4,5-4.1,8c0.5,6.9,1,13.8,1.5,20.7c0.3,4.1,5.7,8.6,9.9,7.7c2.6-1.3,4.8-3.2,6.5-5.6
								C846.9,712.3,848.2,709.7,848.8,706.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M847.1,743.1c2.6-9.8,4.2-19.7,4.6-29.8c0.8-2.6,0.3-4.9-1.5-6.9
								c-1.7-2.1-3.9-3-6.6-2.7c-3.9,1.6-7.5,3.9-10.6,6.8c-3.4,2.6-6.2,5.7-8.5,9.3c-0.4,9.7-1.7,19.3-4.1,28.8
								c-0.8,2.5-0.3,4.8,1.5,6.9c1.6,2.1,3.8,3.1,6.4,2.8c3.9-1.5,7.3-3.6,10.4-6.3C842.1,749.5,844.9,746.5,847.1,743.1z"/>
							<path opacity="0.1091" fill="#131313" d="M823.3,727.3c4.5-10,6.7-20.5,6.6-31.5c0.6-2.5,0-4.8-1.8-6.7
								c-1.6-2.1-3.7-3-6.3-2.9c-3.6,1.3-6.8,3.3-9.6,5.9c-3,2.3-5.5,5.1-7.4,8.4c0.1,10.4-1.8,20.4-5.6,30.1
								c-1.3,4.5,3.3,9.9,7.9,9.7c3.4-1.1,6.4-2.9,9.1-5.3C819.2,733.1,821.5,730.4,823.3,727.3z"/>
							<path opacity="0.1091" fill="#131313" d="M829.5,687.2c0.8-9.5,1.8-19,2.8-28.6c-0.4-2.8-2-4.7-4.8-5.6
								c-4-0.4-7.5,0.8-10.4,3.8c-5.5,3.9-10.6,8.2-15.3,13c-3.4,2.4-5.3,5.7-5.5,9.7c-1.1,9.2-2,18.4-2.8,27.6
								c0.4,2.8,2,4.7,4.7,5.7c3.9,0.5,7.4-0.7,10.4-3.6c5.5-3.8,10.6-7.9,15.3-12.6C827.4,694.3,829.2,691.2,829.5,687.2z"/>
							<path opacity="0.1091" fill="#131313" d="M847.3,714.2c0.8-9.5,1.8-19,2.8-28.5c-0.4-2.8-2-4.7-4.8-5.6
								c-3.9-0.5-7.4,0.8-10.3,3.7c-5.4,3.8-10.4,8.1-15.1,12.8c-3.4,2.4-5.2,5.6-5.4,9.6c-1.1,9.2-2,18.3-2.8,27.5
								c0.4,2.8,2,4.7,4.6,5.7c3.9,0.6,7.3-0.6,10.3-3.4c5.4-3.7,10.5-7.8,15.2-12.3C845.2,721.3,847,718.1,847.3,714.2z"/>
							<path opacity="0.1091" fill="#131313" d="M850.3,683.4c0.8-9.6,1.7-19.1,2.9-28.7c0-3.2-1.6-5.1-4.8-5.6
								c-5.1,0.3-9.5,2.4-13.3,6.2c-7.3,5.4-14.2,11.3-20.8,17.6c-4.4,3-7.1,7.1-8.3,12.1c-1.2,9.1-2.2,18.2-2.8,27.4
								c0,3.2,1.5,5.1,4.7,5.7c5.1-0.2,9.5-2.1,13.2-5.9c7.3-5.2,14.2-10.9,20.8-17C846.3,692.3,849.1,688.4,850.3,683.4z"/>
							<path opacity="0.1091" fill="#131313" d="M810,714c3.6-10.3,5.9-20.9,6.7-31.8c0.6-4.4-3.2-9.8-8-9.6c-3.2,1-6.1,2.7-8.6,5
								c-2.7,2-4.9,4.6-6.4,7.6c-0.7,10.4-2.5,20.6-5.5,30.5c-1,4.2,3.7,9.7,7.9,9.7c3-0.8,5.7-2.3,8.1-4.4
								C806.6,719.2,808.6,716.8,810,714z"/>
							<path opacity="0.1091" fill="#131313" d="M832.8,744.7c2.5-10.1,4.2-20.3,4.8-30.7c0.5-4.2-3.4-9.7-7.9-9.7
								c-3,0.7-5.6,2.2-7.9,4.3c-2.5,1.8-4.4,4.1-5.8,6.8c-0.6,10.1-1.9,20.1-4,30c-0.7,4,3.8,9.6,7.9,9.7c2.8-0.6,5.3-1.9,7.5-3.9
								C829.8,749.5,831.6,747.3,832.8,744.7z"/>
							<path opacity="0.1091" fill="#131313" d="M862.2,709.8c0.8-9.6,1.8-19.1,2.9-28.7c-0.1-3.1-1.7-5-4.7-5.6
								c-4.8,0.1-9,1.9-12.5,5.5c-6.8,5-13.2,10.4-19.3,16.2c-4.1,2.9-6.6,6.6-7.5,11.4c-1.2,9.1-2.1,18.3-2.8,27.4
								c0.1,3.1,1.6,5,4.6,5.7c4.8,0,8.9-1.7,12.4-5.2c6.8-4.8,13.2-10,19.3-15.7C858.7,718.2,861.2,714.5,862.2,709.8z"/>
							<path opacity="0.1091" fill="#131313" d="M819.6,702c1.4-11,2.5-21.4,3.6-32.4c-0.7-2.7-2.3-4.5-4.8-5.6
								c-3.3-0.8-6.2,0-8.8,2.5c-4.4,3-8.5,6.5-12.2,10.4c-2.9,2.1-4.2,4.9-3.9,8.3c-0.5,4.7-0.9,9.4-1.4,14.2
								c0.1-1.2,0.3-2.5,0.4-3.7c-0.7,5.8-1.3,11.6-2,17.3c0.6,2.6,2.2,4.5,4.7,5.7c2.3,0.8,4.4,0.5,6.2-0.9
								c3.3-1.5,6.2-3.4,8.9-5.9C814,709,817.1,705.8,819.6,702z"/>
							<path opacity="0.1091" fill="#131313" d="M838.4,696.4c0.9-9.6,1.9-19.1,3-28.7c-0.4-2.9-1.9-4.8-4.8-5.6
								c-4.2-0.3-7.9,1.1-11,4.3c-5.8,4.2-11.3,8.8-16.4,13.9c-3.6,2.5-5.6,5.9-6,10.1c-1.1,9.2-2.1,18.4-2.9,27.6
								c0.3,2.9,1.9,4.8,4.7,5.7c4.2,0.4,7.8-0.9,10.9-4c5.8-4,11.3-8.5,16.4-13.4C835.9,703.9,838,700.6,838.4,696.4z"/>
							<path opacity="0.1091" fill="#131313" d="M858.7,725.5c0.8-9.5,1.8-19,2.9-28.5c-0.4-2.9-2-4.7-4.7-5.6
								c-4-0.4-7.6,0.9-10.6,3.9c-5.6,4-10.8,8.3-15.6,13.2c-3.5,2.4-5.4,5.7-5.6,9.8c-1.1,9.2-2,18.3-2.8,27.5
								c0.4,2.9,1.9,4.8,4.6,5.7c4,0.5,7.5-0.7,10.5-3.6c5.6-3.8,10.8-8,15.6-12.7C856.4,732.7,858.3,729.6,858.7,725.5z"/>
							<path opacity="0.1091" fill="#131313" d="M813.3,725.9c2.6-10.1,4.3-20.4,4.9-30.8c0.4-4.1-3.6-9.6-7.9-9.7
								c-2.8,0.7-5.3,2-7.5,4c-2.4,1.7-4.2,3.9-5.4,6.5c-0.6,10.1-1.9,20.1-4,30.1c0,2.4,0.7,4.4,2.4,6.1c1.4,1.9,3.2,3.1,5.5,3.6
								c2.7-0.5,5-1.7,7-3.6C810.5,730.5,812.2,728.4,813.3,725.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M804,743.6c0.4-1,0.8-2,1.1-3c-2,3.1-4,6.3-6.1,9.4c2-2.5,3.9-5,5.9-7.4
								c-4.6,4.1-9.2,8.1-13.9,12.2c6.2-3.6,12.4-7.2,18.6-10.8c1.4-1.1,2.9-2.3,4.3-3.4c1.3-1.7,1.6-3.5,0.9-5.3
								c0-1.4-0.5-2.5-1.6-3.3c-0.9-1.7-2.5-2.6-4.6-2.7c-0.2-0.8-0.8-1.1-1.9-0.8c-1.5-0.4-3-0.7-4.5-0.8
								c-3.8,1.5-7.3,3.6-10.3,6.4c-3.3,2.5-6,5.5-8.2,9c-1.8,2.8-2.9,5.8-3.3,9c-0.1,2.6,0.9,4.6,2.9,6.2c1.9,1.8,4.1,2.4,6.6,1.8
								c3.2-1.8,6-4.2,8.2-7.2C800.8,750.2,802.7,747.1,804,743.6z"/>
							<path opacity="0.1091" fill="#131313" d="M779.5,731.2c1.3-2,2.5-4,3.8-6c-1.4,1.6-2.8,3.3-4.2,4.9c0.7-0.7,1.4-1.3,2.1-2
								c-3.1,1.9-6.3,3.8-9.4,5.7c3.8-1.7,7.6-3.3,11.4-5c2.7-1,5.1-2.4,7.1-4.4c1.8-1,2.6-2.5,2.5-4.7c-0.3-1.2-0.6-2.4-0.9-3.6
								c-1-2.4-2.7-3.9-5.1-4.5c-2.1-0.4-4.3-1.5-6.5-1.3c-1.4,0.6-2.8,1.3-4.2,1.9c-2.5,1.5-4.7,3.3-6.8,5.3
								c-2.6,2.4-5.1,5.2-7,8.2c-1.9,3.1-3.2,6.2-4.3,9.6c-0.1,2.6,0.9,4.6,3,6.2c1.4,1.5,3.1,2.1,4.9,1.8c2.2,0.3,4-0.5,5.4-2.5
								C774.5,737.9,777.2,734.7,779.5,731.2z"/>
							<path opacity="0.1091" fill="#131313" d="M785.9,690.9c0.2-0.7,0.3-1.4,0.5-2.1c-6.1,7.6-12.2,15.2-18.3,22.8
								c5.6-5.9,11.1-11.8,16.7-17.7c-5,4.4-10.1,8.8-15.1,13.2c5.7-4.3,11.3-8.6,17-12.9c-5.1,3.5-10.2,7-15.2,10.5
								c4.5-2.5,9-5.1,13.4-7.6c3.1-1.5,6-3.5,8.5-6c0.5-1.2,0.9-2.4,1.4-3.6c0.2-2.2-0.5-3.9-2.1-5.1c-0.7-1.3-1.7-1.9-3.1-1.9
								c-2.6-1-5.2-0.7-7.8,1.1c-9.7,8.9-19.4,17.8-29.1,26.7c-0.3,2.5-0.5,5-0.6,7.6c0.8,2.8,2.7,4.4,5.7,4.7
								c3-0.1,5.4-1.4,7.3-3.7c3.8-3.1,7.2-6.6,10.2-10.6C779.5,701.5,783,696.4,785.9,690.9z"/>
							<path opacity="0.1091" fill="#131313" d="M803.9,717.8c0.1-0.7,0.2-1.3,0.3-2c-3.2,4.5-6.4,8.9-9.6,13.4
								c2.4-2.9,4.7-5.9,7.1-8.8c-4.6,4.6-9.1,9.3-13.7,13.9c2.7-2.5,5.3-5,8-7.5c-2.5,2-5.1,4-7.6,6c6.9-4.1,13.1-8.9,18.6-14.6
								c2.3-1.5,3.5-3.6,3.6-6.3c-0.4-1.2-0.8-2.4-1.2-3.6c-1.2-2-2.9-2.8-5-2.6c-1.9-0.5-3.6-0.1-5.2,1.2c-4,1.6-7.8,3.7-11.2,6.4
								c-5,6.3-9.9,12.6-14.9,18.9c-0.3,2.2-0.3,4.5,0.1,6.7c1,2.7,2.8,4.3,5.6,4.7c2.8,0.1,5-0.9,6.7-3c3.5-2.6,6.5-5.7,9.1-9.3
								C798.4,727.2,801.5,722.7,803.9,717.8z"/>
							<path opacity="0.1091" fill="#131313" d="M807.3,681.8c0.2,0.9,0.5,1.7,0.7,2.6c-8.5,9.3-16.9,18.6-25.4,27.9
								c6.9-6.6,13.8-13.2,20.7-19.8c-6.7,6-13.4,12-20.2,18c7.2-5.8,14.4-11.5,21.6-17.3c-6.9,4.9-13.7,9.8-20.6,14.8
								c7.4-4.5,14.8-9,22.2-13.5c2.3-1.7,4.5-3.4,6.8-5.1c0.8-1.4,1.5-2.8,2.3-4.2c0.5-2.4-0.2-4.1-2-5.2c-0.9-1.3-2.2-1.8-3.9-1.5
								c-2.2-0.5-4.4-0.1-6.4,1.3c-9.6,2.8-16.8,9.5-21.4,20c-13.3,4.6-19,11.8-17.1,21.5c0.5,3.2,2.4,4.8,5.6,4.7
								c5.1-1.3,9.2-4.3,12.4-8.8c6.5-6.9,12.5-14.1,18.1-21.7C804.7,691.7,806.8,687.1,807.3,681.8z"/>
							<path opacity="0.1091" fill="#131313" d="M760.2,722.9c2.6-3,5.3-6.1,7.9-9.1c-1.2,1.6-2.4,3.2-3.6,4.8
								c1.9-2.4,3.9-4.7,5.8-7.1c-2.8,2.8-5.5,5.7-8.3,8.5c2.2-1.9,4.4-3.9,6.6-5.8c-2.8,1.6-5.6,3.3-8.4,4.9c2.1-0.7,4.2-1.4,6.3-2
								c-1.3,0.3-2.5,0.7-3.8,1c2.1-0.3,4.2-0.7,6.3-1c3.2-1,6.2-2.5,8.9-4.4c1.9-0.9,2.8-2.3,2.7-4.2c0.4-1.8,0.1-3.5-1-5.1
								c-0.9-2.2-2.5-3.8-4.8-4.6c-2.1-1.3-4.1-1.5-6.2-0.6c-2.9,0.7-5.6,1.8-8.2,3.3c-1.2,0.8-2.5,1.6-3.7,2.4
								c-1.4,1.4-2.8,2.9-4.3,4.3c-1.2,1.6-2.4,3.2-3.6,4.8c-1.7,1.7-2.2,3.6-1.5,5.6c0,1.3,0.5,2.3,1.5,3.1
								c0.6,1.1,1.6,1.9,2.8,2.4c0.9,0.9,2,1.2,3.3,1C757,725.6,758.7,724.8,760.2,722.9z"/>
							<path opacity="0.1091" fill="#131313" d="M788.7,747.6c0.4-0.9,0.8-1.9,1.2-2.8c-0.9,1.2-1.8,2.5-2.7,3.7
								c1.6-1.9,3.1-3.9,4.7-5.8c-2.2,2.3-4.5,4.6-6.7,6.8c1.8-1.5,3.7-2.9,5.5-4.4c-2.5,1.4-5,2.7-7.5,4.1c0.3,0,0.6,0,0.9-0.1
								c-0.9-0.4-1.9-0.8-2.8-1.2c1.2,0.6,2.5,1.1,3.7,1.7c3.2-0.1,6.1-0.9,8.9-2.4c2.9-1.2,5.4-3,7.5-5.4c2-4.1-1.3-10.1-5.4-11.3
								c-2.5-1-5.2-1.6-7.9-1.6c-2.1,0.4-3.9,1.3-5.5,2.7c-2.8,1.8-5.2,4-7.4,6.6c-1.9,1.9-3.1,4.1-3.8,6.7c-1.4,2-1.4,4-0.1,6
								c1.4,2.1,3.3,3.7,5.6,4.7c2.2,1,4.2,0.6,5.9-1.1C785.3,752.5,787.2,750.2,788.7,747.6z"/>
							<path opacity="0.1091" fill="#131313" d="M817.9,714.2c-0.6,1.2-1.1,2.4-1.7,3.6c-6.2,5.4-12.3,10.8-18.5,16.2
								c7.2-4.8,14.5-9.6,21.7-14.5c2.3-1.7,4.6-3.4,6.9-5.1c0.8-1.4,1.6-2.8,2.4-4.2c0.5-2.4-0.1-4.1-2-5.2
								c-0.9-1.4-2.2-1.8-3.9-1.5c-2.3-0.5-4.4-0.1-6.5,1.3c-3.3,1-6.5,2.4-9.5,4.1c-13.3,5.4-21.8,16.3-25.3,32.9
								c0.7,3,2.6,4.5,5.6,4.7c3.2-0.3,5.8-1.7,7.7-4.2c4.1-3.4,7.9-7.3,11.1-11.6C810.7,725.7,814.6,720.1,817.9,714.2z"/>
							<path opacity="0.1091" fill="#131313" d="M777.2,701.4c0.2-0.7,0.3-1.4,0.5-2.1c-2.8,4-5.6,8.1-8.4,12.1
								c2.2-2.7,4.4-5.4,6.5-8.1c-1.9,2.1-3.7,4.2-5.6,6.3c2.3-2.2,4.7-4.4,7-6.6c-4.1,3.5-8.1,6.9-12.2,10.4
								c2.7-1.9,5.5-3.8,8.2-5.7c-2.1,1.3-4.3,2.5-6.4,3.8c2.8-1.4,5.6-2.8,8.3-4.2c2-1.1,4.1-2.3,6.1-3.4c1.3-1.1,2.6-2.2,3.9-3.2
								c1.1-1.7,1.3-3.4,0.6-5.2c-0.1-1.3-0.6-2.4-1.6-3.3c-0.5-1.2-1.5-1.9-2.8-2.1c-1.3-0.9-2.7-0.9-4.1-0.2
								c-13,2.2-22.6,10.9-28.6,26.2c-0.7,2.8,0.1,5.2,2.3,7c2.2,1.8,4.7,2.2,7.3,1c4.1-2.9,7.7-6.3,10.8-10.3
								C772.4,710.1,775.1,705.9,777.2,701.4z"/>
							<path opacity="0.1091" fill="#131313" d="M794.9,700.2c0.1-0.7,0.3-1.4,0.4-2.1c-3.7,5.1-7.4,10.2-11.2,15.3
								c3.2-3.4,6.3-6.7,9.5-10.1c-5.4,4.7-10.7,9.3-16.1,14c5.9-4.5,11.8-9.1,17.6-13.6c-5.5,3.8-11,7.6-16.5,11.5
								c6.1-3.5,12.2-7,18.3-10.5c3.2-1.1,5.3-3.2,6.3-6.1c1-1.2,1.1-2.4,0.5-3.8c0-2-1-3.6-3-4.7c-1.4-0.3-2.7-0.5-4.1-0.8
								c-2.2,0.7-4.4,1.3-6.6,2c-18.4,5.4-28.1,15.1-29.3,29.3c-0.3,1.7-0.4,3.4-0.2,5.2c0.8,2.9,2.7,4.4,5.6,4.7
								c3-0.2,5.5-1.4,7.4-3.8c3.9-3.2,7.4-6.8,10.4-10.8C788.3,711,791.9,705.8,794.9,700.2z"/>
							<path opacity="0.1091" fill="#131313" d="M800.2,749c5.2-8.7,10.4-17.4,15.6-26.1c-3.2,5.8-6.4,11.5-9.6,17.3
								c2.3-2.9,4.6-5.7,6.9-8.6c-4.6,4.6-9.1,9.2-13.7,13.8c2.6-2.4,5.2-4.8,7.8-7.2c-2.6,2-5.2,4.1-7.8,6.1
								c8.5-4.4,16.4-9.6,23.9-15.4c2.1-2.3,2.5-4.9,1.3-7.7c-1.5-2.7-3.9-3.8-7-3.5c-9.5,3.2-9.4,2.9,0.4-0.9
								c-2.7-0.8-5.2-0.4-7.5,1.2c-4.2,1.7-8.1,4-11.8,6.7c-2.7,2-5.4,4-8.1,6c-3.7,2.1-5.8,5.1-6.5,9c0.3,3.1,0.7,6.2,1,9.4
								c1,2.7,2.8,4.2,5.6,4.7C794.8,753.9,797.9,752.3,800.2,749z"/>
							<path opacity="0.1091" fill="#131313" d="M769.8,728.3c0.4-0.9,0.8-1.8,1.2-2.8c-1,1.3-1.9,2.7-2.9,4c1.6-2,3.2-4,4.8-6
								c-2.3,2.4-4.6,4.7-6.9,7.1c1.9-1.6,3.8-3.1,5.7-4.7c-2.4,1.3-4.9,2.6-7.3,3.9c1.3-0.3,2.7-0.3,4,0.1c2.7,0.1,5.2-0.6,7.6-1.9
								c2.5-1.1,4.5-2.7,6.1-4.9c0.5-2.3,0.2-4.5-1-6.5c-0.9-2.2-2.5-3.8-4.6-4.7c-2.4-1.6-4.9-1.8-7.5-0.6c-2.7,0.9-5.3,2-7.7,3.5
								c-2.1,1-3.8,2.4-5.1,4.4c-2,2-3.3,4.3-4.1,7c-1.5,2.1-1.7,4.2-0.3,6.4c1.4,2.1,3.3,3.7,5.6,4.7c2.4,0.9,4.4,0.4,6.2-1.5
								C766,733.7,768.1,731.2,769.8,728.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M752.4,792.7c10.4-5.6,20.8-11.1,31.3-16.6c2.4-1.9,3-4.2,2-7.1
								c-2.4-3.8-6-6.1-10.6-7c-7.3-2.7-14.8-4.8-22.4-6.4c-4.4-1.7-8.6-1.6-12.7,0.4c-10.1,5.1-20.2,10.4-30.1,15.8
								c-2.4,1.8-3.1,4.2-2.2,7c2.3,3.8,5.7,6.2,10.3,7.2c7.1,2.8,14.5,5,21.9,6.7C744.1,794.6,748.3,794.6,752.4,792.7z"/>
							<path opacity="0.1091" fill="#131313" d="M724,776.6c11.9-3.8,23.2-9,33.8-15.6c2.5-0.8,4.1-2.6,4.8-5.2
								c0.8-2.6,0.3-4.9-1.4-6.9c-3.4-2.3-7.1-3.9-11.2-4.9c-3.9-1.3-8-1.9-12.1-1.7c-10.2,6.4-21,11.5-32.5,15.5
								c-2.5,0.8-4.1,2.5-4.8,5.1c-0.9,2.5-0.5,4.8,1.1,6.9c3.2,2.3,6.7,4,10.6,5C716.2,776.2,720,776.8,724,776.6z"/>
							<path opacity="0.1091" fill="#131313" d="M727.4,742.7c12.4-3.7,24.3-8.7,35.5-15.2c2.3-1.1,3.7-2.8,4.3-5.3
								c0.8-2.4,0.5-4.7-0.9-6.7c-2.7-2.1-5.8-3.6-9.2-4.3c-3.3-1.1-6.6-1.5-10.1-1.1c-10.7,6.2-21.9,11.3-33.7,15.3
								c-4.1,1.6-6.1,8.4-3.6,12c2.4,2.1,5.2,3.5,8.4,4.3C721.1,742.8,724.2,743.1,727.4,742.7z"/>
							<path opacity="0.1091" fill="#131313" d="M750.3,768c10.3-5.6,20.7-11.1,31.1-16.6c2.1-2,2.8-4.3,2-7.1
								c-1.9-3.6-4.9-5.7-9.1-6.5c-6.3-2.4-12.8-4.2-19.5-5.5c-4-1.5-7.7-1.2-11.2,0.8c-10.1,5.2-20.1,10.5-30.1,15.9
								c-2.2,1.9-2.9,4.3-2.1,7c1.8,3.6,4.7,5.8,8.8,6.7c6.2,2.5,12.6,4.4,19.1,5.8C743.2,770.2,746.9,770,750.3,768z"/>
							<path opacity="0.1091" fill="#131313" d="M751.7,739.3c10.3-5.5,20.7-11.1,31-16.6c1.9-2,2.5-4.4,2-7.1
								c-1.3-3.3-3.8-5.3-7.4-6c-5.3-2-10.7-3.5-16.2-4.5c-3.5-1.2-6.6-0.8-9.5,1.3c-10.1,5.4-20.2,10.7-30.3,16
								c-1.9,2-2.6,4.3-2.1,7c1.2,3.3,3.6,5.3,7.2,6.2c5.1,2.1,10.5,3.7,15.9,4.8C745.7,741.8,748.9,741.4,751.7,739.3z"/>
							<path opacity="0.1091" fill="#131313" d="M710.9,764.8c11.9-4.4,23.3-9.8,34.3-16.2c2-1.3,3.2-3.1,3.8-5.4
								c0.8-2.2,0.8-4.4-0.1-6.6c-1.9-1.9-4.1-3.2-6.8-3.8c-2.5-1-5.1-1.2-7.7-0.6c-10.7,6.2-21.7,11.7-33.1,16.5
								c-1.9,1.4-3.1,3.2-3.6,5.5c-0.8,2.2-0.9,4.4-0.1,6.5c1.7,1.8,3.7,3,6.2,3.6C706.1,765.2,708.5,765.4,710.9,764.8z"/>
							<path opacity="0.1091" fill="#131313" d="M736.6,795.6c10.2-5.7,20.5-11.2,30.9-16.6c1.9-2,2.6-4.4,2-7.1
								c-1.3-3.3-3.8-5.3-7.5-6.1c-5.3-2.1-10.8-3.6-16.5-4.7c-3.5-1.3-6.7-0.9-9.6,1.3c-10.1,5.3-20.1,10.6-30.1,16
								c-1.9,2-2.7,4.3-2.2,7c1.2,3.3,3.7,5.4,7.3,6.2c5.2,2.2,10.6,3.8,16.2,5C730.6,798,733.8,797.6,736.6,795.6z"/>
							<path opacity="0.1091" fill="#131313" d="M765.5,764.9c10.3-5.7,20.8-11.2,31.2-16.6c2.5-1.8,3.2-4.2,2-7.1
								c-2.8-3.9-6.7-6.4-11.7-7.2c-8-2.8-16.1-5.1-24.4-6.9c-4.7-1.8-9.3-1.8-13.7,0.1c-10.1,5.1-20.1,10.3-30,15.8
								c-2.5,1.8-3.2,4.2-2.1,7c2.7,4,6.4,6.5,11.3,7.4c7.8,3,15.8,5.4,23.9,7.3C756.6,766.6,761.1,766.7,765.5,764.9z"/>
							<path opacity="0.1091" fill="#131313" d="M720.3,753.8c11.8-4.5,23.2-9.9,34.2-16.2c3.8-2,6.3-8.1,3.6-12
								c-2.4-2.1-5.2-3.5-8.4-4.3c-3-1.1-6.1-1.5-9.3-1.1c-10.7,6.3-21.8,11.8-33.2,16.5c-3.8,1.7-6,8.4-3.6,12
								c2.3,2,4.9,3.4,7.9,4.1C714.4,753.9,717.3,754.3,720.3,753.8z"/>
							<path opacity="0.1091" fill="#131313" d="M737.4,751.8c10.9-4.9,21.6-10.3,32.1-16.2c2.7-0.7,4.3-2.4,5.1-5.1
								c0.8-2.7,0.2-5-1.7-7c-3.8-2.4-7.9-4.1-12.3-5.2c-4.3-1.4-8.7-2.1-13.2-2c-10.1,5.7-20.5,11-31,15.9c-2.6,0.7-4.3,2.4-5.1,5
								c-0.8,2.6-0.4,4.9,1.4,7c3.6,2.5,7.6,4.2,11.8,5.3C728.8,751.1,733.1,751.8,737.4,751.8z"/>
							<path opacity="0.1091" fill="#131313" d="M763.4,779.9c10.3-5.7,20.7-11.2,31.2-16.6c2.6-1.8,3.2-4.2,2-7.1
								c-3-4-7-6.5-12.1-7.4c-8.3-2.9-16.7-5.3-25.3-7.1c-4.8-1.9-9.6-1.9-14.2,0c-10.1,5-20.1,10.3-30,15.8c-2.6,1.8-3.3,4.1-2.2,7
								c2.8,4,6.7,6.6,11.7,7.6c8.1,3.1,16.3,5.6,24.8,7.6C754.1,781.6,758.8,781.7,763.4,779.9z"/>
							<path opacity="0.1091" fill="#131313" d="M713.9,778c11.8-4.5,23.1-9.9,34.1-16.2c3.7-2,6.2-8.2,3.7-12c-2.3-2.1-5-3.5-8-4.2
								c-2.9-1.1-5.9-1.4-9-1c-10.7,6.2-21.7,11.7-33.1,16.5c-3.7,1.7-5.9,8.4-3.7,12c2.1,2,4.6,3.3,7.5,4
								C708.2,778.2,711,778.5,713.9,778z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M691.2,785.3c3.4,2.5,6.7,5.1,10.1,7.6c0.7-1.1,1.3-2.2,2-3.3
								c0.5-3-0.5-5.4-3.1-7.2c-5.3-2.6-10.9-4.4-16.7-5.6c-5.7-1.5-11.5-2.3-17.4-2.4c11.6,2.1,23.3,4.2,34.9,6.4
								c-0.7-1.8-1.8-3.3-3.1-4.7c-2-2.3-4.7-3.3-7.5-4.2c-2.1,0.1-4.2,0.2-6.3,0.3c-4.4,0.6-8.7,1.6-13,3.1
								c-2.5,0.5-4.9,1.4-7.1,2.7c-2.5,0.6-4.2,2.1-5.1,4.6c-0.5,1.3-0.9,2.6-1.2,3.9c-0.4,2.7,0.4,5.1,2.4,7
								c2.1,1.5,4.3,1.9,6.6,1.1c3.9-0.4,7.6-1.3,11.3-2.8C682.7,790.2,687.1,788.1,691.2,785.3z"/>
							<path opacity="0.1091" fill="#131313" d="M667.6,768.7c0.2,0.1,0.5,0.2,0.7,0.3c-4.1,1-8.1,2.1-12.2,3.1
								c2.8-0.5,5.5-0.9,8.3-1.4c-3.7,0-7.3-0.1-11-0.1c1.8,0.8,3.5,1.6,5.3,2.5c4,1.7,8.2,2.8,12.6,3.4c2.4,0.9,4.5,0.6,6.1-0.9
								c1.7-0.8,2.7-2.3,3.1-4.3c0.8-2.5,0.4-4.8-1.2-6.8c-0.3-2.7-1.5-5.1-3.7-7.1c-0.8-0.7-1.7-1.5-2.5-2.2
								c-2.6-0.7-5.2-0.8-7.8-0.3c-2.1,0-4.3,0.3-6.3,0.9c-2.6,0.3-5.1,1.2-7.4,2.5c-1.4,0.5-2.7,1.3-3.9,2.2c-1.5,0.9-2.9,2-4,3.4
								c-1.3,2.2-1.5,4.4-0.5,6.8c0.7,2.5,2.3,4.1,4.7,5c3.6,0.1,7.1-0.5,10.4-1.9C661.8,772.7,664.9,771.1,667.6,768.7z"/>
							<path opacity="0.1091" fill="#131313" d="M671.7,734.3c0.5-0.5,1-0.9,1.6-1.4c-4.1,1.9-8.3,3.9-12.4,5.8
								c3-1.1,6.1-2.2,9.1-3.2c-5,1.3-10,2.6-14.9,3.9c3.1-0.6,6.3-1.2,9.4-1.8c-4.2,0.1-8.4,0.2-12.6,0.3c2.7,0.3,5.4,0.6,8.1,1
								c-1.6-0.3-3.2-0.7-4.7-1c2.4,0.7,4.9,1.3,7.3,2c-1.4-0.5-2.8-1.1-4.2-1.6c2.2,1,4.3,1.9,6.5,2.9c3.6,1.4,7.3,2.3,11.1,2.7
								c2.2,0.7,4,0.2,5.4-1.3c1.6-1,2.5-2.5,2.7-4.5c0.7-2.4,0.4-4.6-1-6.7c-1-1.3-1.9-2.6-2.9-3.9c0.6,0.9,1.2,1.8,1.9,2.7
								c-0.9-1.8-2-3.4-3.5-4.7c-1-1.2-2.1-2.1-3.5-2.8c-1.1-0.6-2.1-1.2-3.2-1.8c-3.2-0.2-6.3,0.1-9.3,0.9c-2.6,0.4-5,1-7.5,1.9
								c-3.1,0.7-5.9,2-8.6,3.7c-1.9,1.1-3.5,2.6-4.8,4.3c-1.8,2.1-2.3,4.5-1.3,7.1c0.9,2.6,2.8,4.2,5.5,4.7c4.7-0.3,9.2-1.3,13.5-3
								C663.8,739,667.9,737,671.7,734.3z"/>
							<path opacity="0.1091" fill="#131313" d="M691.3,757.6c0.1,0.8,0.1,1.6,0.2,2.3c-8.5,2.6-17.1,5.3-25.6,7.9
								c3.6-0.7,7.2-1.5,10.9-2.2c-3.3,0.4-6.6,0.8-9.9,1.2c3.6-0.3,7.3-0.7,10.9-1c-5.5-0.3-10.9-0.6-16.4-0.8
								c9,1.5,17.9,3.1,26.9,4.6c3.8,1.2,7.3,0.7,10.3-1.5c1.9-2.1,2.5-4.5,1.7-7.2c-1.8-3.4-3.7-6.7-5.5-10.1
								c-2.1-1.9-4.5-2.5-7.3-2c-4.5-0.2-9,0.2-13.4,1.2c-3.2,0.7-6.5,1.4-9.7,2c-4.2,0.3-7.3,2.2-9.5,5.5c-1.4,2.2-2.7,4.4-4.1,6.5
								c-0.6,2.8,0.2,5.1,2.4,6.9c3.5,1.8,7.2,1.9,11,0.2c6.4-1.7,12.6-3.9,18.7-6.6C686.9,763.6,689.7,761.3,691.3,757.6z"/>
							<path opacity="0.1091" fill="#131313" d="M690.4,732c0.4-0.4,0.8-0.9,1.2-1.3c-9.7,3.6-19.4,7.2-29.1,10.8
								c8.1-2.2,16.2-4.4,24.3-6.5c-7,1.2-14,2.5-21.1,3.7c4.2-0.4,8.5-0.7,12.7-1.1c-3.2,0.1-6.5,0.1-9.7,0.2
								c3.9,0.2,7.7,0.4,11.6,0.6c-3-0.3-5.9-0.7-8.9-1c4.8,1.1,9.7,2,14.6,2.8c3.3,0.8,6.6,1.1,10,0.8c1.1-0.6,2.2-1.3,3.3-1.9
								c1.5-1.6,2-3.3,1.7-5.3c0.3-1.4-0.1-2.5-1.1-3.5c-1.3-2.4-3.4-3.8-6.4-4.1c-14.3,1.6-28.6,3.1-42.9,4.7
								c-1.7,1.9-3.2,3.9-4.4,6.2c-0.8,2.9,0,5.2,2.5,6.9c2.8,1.4,5.7,1.5,8.6,0.3c5.2-0.8,10.2-2.2,15.1-4.3
								C678.7,738.2,684.8,735.5,690.4,732z"/>
							<path opacity="0.1091" fill="#131313" d="M651.6,758.3c0.8-0.5,1.6-1,2.3-1.6c-1.4,0.6-2.8,1.2-4.2,1.8
								c0.8-0.3,1.6-0.5,2.4-0.8c-1.5,0.2-3,0.5-4.5,0.7c2.3-0.3,4.6-0.6,6.8-0.8c-2.6-0.2-5.2-0.4-7.8-0.5c1.2,0.8,2.1,1.9,2.7,3.2
								c-0.4-0.9-0.8-1.8-1.2-2.7c0.7,1.6,1.3,3.3,2,4.9c1.9,1.7,4.1,2.8,6.7,3.2c2.5,0.7,5,0.7,7.4,0c1.8-1.5,2.9-3.3,3.3-5.7
								c0.7-2.3,0.5-4.4-0.5-6.5c-1.4-2.9-3-5.7-4.9-8.3c-2.9-2.4-6.1-3.6-9.7-3.7c-2.7,0.2-5.3,0.8-7.9,1.8c-2.5,0.8-4.7,2-6.7,3.6
								c-2.3,1-3.3,2.8-3.2,5.2c0.2,2.5,1,4.9,2.5,6.9c1.4,2,3.3,2.7,5.7,2C646,760.8,648.9,759.8,651.6,758.3z"/>
							<path opacity="0.1091" fill="#131313" d="M665.7,793.2c3.6-2.2,7.2-4.3,10.8-6.5c-6.2,2-12.4,4.1-18.6,6.1
								c6.6,1.2,13.2,2.5,19.8,3.7c1.8,0.1,3.6,0.1,5.4,0.2c2.1-0.5,3.4-1.6,4-3.5c0.9-1,1.2-2.2,1-3.6c0.4-1.9-0.2-3.6-1.7-5
								c0.3-1.3,0.1-2.5-0.8-3.5c-0.6-1.5-1.4-2.9-2.5-4.1c-0.8-0.7-1.7-1.5-2.5-2.2c-2.6-0.7-5.2-0.8-7.8-0.3
								c-2.1,0-4.3,0.3-6.3,0.9c-2.6,0.3-5.1,1.2-7.4,2.5c-1.3,0.9-2.5,1.8-3.8,2.7c-2.7,1-4.1,2.8-4.3,5.5c-0.1,2,0.4,3.8,1.5,5.3
								c0.6,1.7,1.8,2.8,3.6,3.4C659.4,795,662.6,794.5,665.7,793.2z"/>
							<path opacity="0.1091" fill="#131313" d="M706.8,753.5c-2.6,2.4-5.1,4.8-7.7,7.2c-8.5,1.5-16.9,3.1-25.4,4.6
								c8.7-1.1,17.5-2.1,26.2-3.2c2.9-0.6,5.7-1.2,8.6-1.8c1.3-1,2.7-1.9,4-2.9c1.5-1.9,1.6-3.8,0.3-5.6c-0.3-1.6-1.3-2.6-3-2.9
								c-1.9-1.4-4.1-1.9-6.5-1.5c-3.5-0.4-7-0.5-10.6-0.1c-2.1,0.1-4.1,0.1-6.2,0.2c7.1-0.7,14.2-1.3,21.3-2
								c-3-1.5-6.2-1.8-9.4-0.8c-5.8,0.5-11.5,1.7-17.1,3.4c-4.1,1.2-8.3,2.5-12.4,3.7c-5,0.8-9,3.2-12,7.1c-0.9,1.6-1.6,3.2-2.1,5
								c-1,3-0.2,5.3,2.4,6.9c4.6,1.6,9.3,1.3,13.9-0.8c8.3-2.3,16.4-5.2,24.3-8.5C700.4,760.3,704.2,757.7,706.8,753.5z"/>
							<path opacity="0.1091" fill="#131313" d="M642.6,750.8c5.6-1.4,11.2-2.8,16.7-4.3c-2,0.8-3.9,1.6-5.9,2.4c3-1.1,6-2.1,9-3.2
								c-4,1.1-8.1,2.1-12.1,3.2c3.1-0.6,6.2-1.1,9.2-1.7c-3.5,0-7-0.1-10.5-0.1c2.1,0.5,4.1,1,6.2,1.5c-1.3-0.4-2.7-0.7-4-1.1
								c1.9,0.8,3.7,1.6,5.6,2.4c-1.2-0.5-2.4-1.1-3.6-1.6c0.5,0.4,1.1,0.8,1.6,1.2c2.4,1.9,5.1,3.2,8.2,3.8c3,0.9,6,1.1,9,0.5
								c4.1-1.9,5.2-8.5,3-12.2c-0.5-2.7-2-4.3-4.3-5c-3.8-1.7-7.6-3.2-11.5-4.7c-3.7-0.1-7.3,0.5-10.8,1.8c-3.6,1-6.9,2.7-9.8,5
								c-1.4,2.1-1.7,4.4-0.8,6.9C638.6,748.3,640.2,749.9,642.6,750.8z"/>
							<path opacity="0.1091" fill="#131313" d="M680.2,743.4c0.4-0.4,0.8-0.9,1.2-1.3c-7.9,3-15.7,6-23.6,9
								c6.8-1.8,13.6-3.6,20.5-5.5c-5.8,1-11.7,1.9-17.5,2.9c3.5-0.2,7.1-0.4,10.6-0.5c-4.7-0.3-9.4-0.6-14.1-1
								c5.6,1.1,11.3,2.2,16.9,3.3c2.4,0.5,4.9,0.9,7.3,1.4c2.7,1,5,0.7,6.9-0.9c1.3-0.4,2.1-1.3,2.4-2.6c1.1-1.6,1.4-3.4,0.9-5.5
								c-22.2-14.1-37.5-14.3-45.8-0.4c-2.2,2.1-2.8,4.5-1.9,7.3c1.1,2.8,3.1,4.3,6.1,4.5c5.4-0.5,10.6-1.8,15.7-3.8
								C670.9,748.7,675.7,746.4,680.2,743.4z"/>
							<path opacity="0.1091" fill="#131313" d="M703,768.9c2.8,3.8,5.6,7.7,8.4,11.5c0.7-1.1,1.4-2.2,2.1-3.3c0.3-3.2-1-5.6-4-7.2
								c-6.2-2.6-12.7-4.6-19.4-5.7c-6.6-1.6-13.3-2.4-20.1-2.5c13,1.3,26.1,2.7,39.1,4c-0.9-3.1-3-4.9-6.3-5.3
								c-4.8-0.2-9.5,0.1-14.2,1c-5.9,0.7-11.6,1.7-17.4,3.2c-4.7,0.4-8.5,2.3-11.4,5.9c-0.9,2.2-1.7,4.4-2.6,6.6
								c-0.8,2.9,0,5.2,2.4,7c4,1.7,8.1,1.7,12.3-0.3c7.3-2,14.3-4.4,21.2-7.4C697.7,775.2,701,772.7,703,768.9z"/>
							<path opacity="0.1091" fill="#131313" d="M653.2,771.3c0.8-0.5,1.6-1.1,2.4-1.6c-1.3,0.6-2.6,1.2-3.9,1.7
								c0.8-0.3,1.6-0.5,2.4-0.8c-1.4,0.2-2.9,0.5-4.3,0.7c2.2-0.2,4.4-0.5,6.6-0.7c-2.6-0.2-5.1-0.4-7.7-0.5
								c0.4,0.4,0.8,0.9,1.2,1.3c-0.5-0.8-1.1-1.7-1.6-2.5c0.8,1.4,1.6,2.9,2.4,4.3c-0.4-0.9-0.9-1.8-1.3-2.7
								c0.6,1.6,1.1,3.2,1.7,4.9c2.2,1.8,4.7,3,7.5,3.4c2.7,0.8,5.5,0.9,8.2,0.2c3.9-2.1,4.7-8.7,2.8-12.2c-1.6-3-3.1-6-5.3-8.6
								c-0.8-0.9-1.7-1.8-2.5-2.7c-1.8-0.8-3.6-1-5.5-0.7c-2.6,0.1-5,0.6-7.5,1.1c-1.4,0.2-2.8,0.6-4,1.3c-1.6,0.6-3.1,1.5-4.4,2.7
								c-2.1,1-3.1,2.7-2.8,5c0.2,2.5,1,4.8,2.4,6.9c1.3,2,3.1,2.7,5.4,2.2C648.2,773.7,650.8,772.7,653.2,771.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M645,825.8c9.6,0.8,19.1,1.7,28.7,2.8c3.2,0,5.1-1.6,5.6-4.8
								c-0.4-5.2-2.5-9.7-6.4-13.5c-5.6-7.5-11.6-14.6-18-21.3c-3.1-4.5-7.2-7.3-12.3-8.6c-9.1-1.3-18.2-2.2-27.3-2.7
								c-3.2,0-5.1,1.5-5.7,4.7c0.3,5.2,2.3,9.7,6.1,13.5c5.4,7.5,11.2,14.6,17.4,21.3C636,821.6,640,824.5,645,825.8z"/>
							<path opacity="0.1091" fill="#131313" d="M620.8,806.7c9.5,0.8,19,1.8,28.4,2.7c2.7-0.6,4.6-2.2,5.6-4.8
								c0.8-3.5-0.2-6.5-2.8-9.2c-3.2-4.7-6.9-9-11-13c-2.2-3-5.1-4.4-8.6-4.3c-9.2-1-18.4-1.9-27.7-2.8c-2.7,0.6-4.6,2.1-5.7,4.7
								c-0.8,3.4,0,6.5,2.5,9.2c3.1,4.7,6.6,9,10.6,13C614.5,805.3,617.3,806.8,620.8,806.7z"/>
							<path opacity="0.1091" fill="#131313" d="M619.9,772c10.1,2.7,20.4,4.3,30.8,4.9c4,0.4,9.6-3.7,9.7-7.9
								c-0.6-2.8-1.9-5.2-3.8-7.3c-1.7-2.3-3.8-4.1-6.4-5.2c-10.1-0.5-20.2-1.9-30.1-4c-2.3,0-4.4,0.8-6.1,2.4
								c-1.9,1.4-3.1,3.2-3.6,5.5c0.5,2.6,1.6,4.9,3.4,6.8C615.4,769.5,617.4,771,619.9,772z"/>
							<path opacity="0.1091" fill="#131313" d="M641.9,799.1c9.5,0.8,19,1.7,28.5,2.8c2.8-0.5,4.7-2,5.6-4.8
								c0.5-3.9-0.7-7.3-3.7-10.3c-3.8-5.4-8.1-10.4-12.7-15c-2.4-3.4-5.6-5.1-9.5-5.3c-9.2-1.1-18.3-2-27.5-2.8
								c-2.8,0.4-4.7,2-5.7,4.7c-0.6,3.9,0.6,7.3,3.4,10.2c3.7,5.4,7.8,10.4,12.3,15C634.9,797.1,638,798.9,641.9,799.1z"/>
							<path opacity="0.1091" fill="#131313" d="M638.4,769.1c10,3.6,20.2,5.6,30.8,5.7c2.5,0.4,4.7-0.2,6.6-2c2-1.6,3-3.6,3-6.2
								c-1.2-3.4-3-6.4-5.5-9c-2.2-2.9-4.9-5.2-8-6.9c-10.1-0.1-19.9-1.7-29.6-4.8c-4.4-1-9.8,3.4-9.7,7.9c1,3.2,2.6,6.1,4.9,8.6
								C632.9,765.3,635.4,767.5,638.4,769.1z"/>
							<path opacity="0.1091" fill="#131313" d="M603.2,794.7c10.1,2.5,20.3,4.1,30.7,4.7c4.3,0.5,9.8-3.1,9.7-7.9
								c-0.9-3.1-2.4-5.9-4.6-8.3c-1.9-2.6-4.3-4.7-7.2-6.2c-10.1-0.5-20.1-1.9-30.1-4c-4.2-0.7-9.7,3.5-9.7,7.9
								c0.8,3,2.2,5.6,4.3,7.9C598.2,791.4,600.5,793.3,603.2,794.7z"/>
							<path opacity="0.1091" fill="#131313" d="M628.2,827.5c9.6,0.9,19.1,1.8,28.7,2.9c3-0.2,4.9-1.8,5.6-4.7
								c0.1-4.5-1.5-8.4-4.9-11.8c-4.6-6.3-9.6-12.3-15.1-17.9c-2.7-3.9-6.3-6.2-10.8-6.8c-9.2-1.1-18.3-2.1-27.5-2.8
								c-3,0.2-4.9,1.8-5.7,4.6c-0.2,4.5,1.3,8.4,4.6,11.7c4.4,6.3,9.3,12.3,14.6,17.9C620.3,824.5,623.8,826.8,628.2,827.5z"/>
							<path opacity="0.1091" fill="#131313" d="M655.9,795.7c9.6,0.9,19.1,1.9,28.7,2.9c2.9-0.3,4.8-1.9,5.6-4.8
								c0.2-4.3-1.3-8-4.5-11.2c-4.3-6-9.1-11.5-14.3-16.8c-2.6-3.7-6-5.8-10.3-6.2c-9.2-1.1-18.4-2-27.6-2.8
								c-2.9,0.3-4.8,1.9-5.7,4.7c-0.3,4.2,1.1,8,4.2,11.1c4.2,5.9,8.7,11.5,13.7,16.8C648.3,793.1,651.7,795.2,655.9,795.7z"/>
							<path opacity="0.1091" fill="#131313" d="M611.3,782.9c10.1,2.6,20.4,4.3,30.8,4.9c4.3,0.5,9.7-3.2,9.7-7.9
								c-0.8-3.1-2.3-5.8-4.5-8.1c-1.9-2.6-4.2-4.6-7.1-6c-10.2-0.5-20.2-1.9-30.1-4.1c-4.1-0.7-9.7,3.7-9.7,7.9
								c0.7,2.9,2.1,5.5,4.2,7.7C606.4,779.7,608.6,781.6,611.3,782.9z"/>
							<path opacity="0.1091" fill="#131313" d="M627.6,778.8c10,4.2,20.3,6.2,31.1,6.1c2.5,0.6,4.8,0,6.7-1.7
								c2.1-1.6,3-3.8,2.8-6.4c-1.4-3.7-3.4-6.9-6.1-9.8c-2.4-3.1-5.3-5.7-8.6-7.6c-10.2,0.1-20.2-1.7-29.8-5.2
								c-4.6-1.2-10,3.1-9.7,7.9c1.2,3.5,3,6.6,5.5,9.4C621.7,774.5,624.4,777,627.6,778.8z"/>
							<path opacity="0.1091" fill="#131313" d="M654.4,811.9c9.6,0.8,19.1,1.7,28.7,2.8c3.2-0.1,5-1.7,5.6-4.8
								c-0.2-5-2.2-9.3-5.9-12.9c-5.3-7.1-11-13.8-17.1-20.1c-3-4.3-6.9-6.9-11.8-8c-9.1-1.2-18.2-2.1-27.4-2.8
								c-3.1,0-5,1.6-5.7,4.7c0.1,4.9,2,9.2,5.6,12.9c5.1,7.1,10.6,13.8,16.5,20.2C645.8,808.1,649.6,810.8,654.4,811.9z"/>
							<path opacity="0.1091" fill="#131313" d="M603.6,805.6c9.9,3.4,20,5.1,30.4,5.3c2.6,0.7,4.8,0.2,6.8-1.6
								c2.1-1.7,3-3.8,2.8-6.4c-1.5-3.8-3.6-7.2-6.4-10.2c-2.5-3.2-5.5-5.9-8.9-8c-10-0.1-19.8-1.7-29.3-4.6
								c-2.5-0.7-4.8-0.1-6.8,1.6c-2.1,1.6-3.1,3.7-3,6.3c1.3,3.7,3.3,7,5.9,9.9C597.4,801,600.2,803.6,603.6,805.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M592,857.9c5.5,0,11-0.1,16.6-0.1c3.2-0.3,4.9-2.1,5.1-5.3
								c-0.8-5.1-3.4-9.3-7.5-12.7c-6.2-6.8-12.7-13.2-19.7-19.2c-3.5-4.1-7.8-6.5-12.9-7.2c-5.1,0.1-10.3,0.1-15.4,0.2
								c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7c6,6.8,12.4,13.2,19.1,19.2C582.7,854.7,586.9,857.1,592,857.9z"/>
							<path opacity="0.1091" fill="#131313" d="M567.5,838.9c5.5,0,11.1,0.1,16.6,0.1c2.6-0.9,4.3-2.7,5.1-5.3
								c0.5-3.4-0.7-6.3-3.5-8.7c-3.6-4.2-7.5-8.1-11.8-11.5c-2.4-2.7-5.4-3.8-8.8-3.2c-5.3-0.1-10.6-0.1-15.9-0.2
								c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7c3.5,4.2,7.3,8.1,11.5,11.6C561.2,838.3,564.1,839.4,567.5,838.9z"/>
							<path opacity="0.1091" fill="#131313" d="M566.9,805c6.3,0.6,12.5,1.1,18.8,1.7c2.4-0.1,4.4-1.1,6-2.9c1.8-1.6,2.8-3.6,2.9-6
								c-0.9-2.7-2.4-5-4.6-7c-1.9-2.2-4.2-3.7-7-4.6c-6.1-0.4-12.1-0.7-18.2-1.1c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9
								c0.8,2.6,2.2,4.8,4.3,6.7C562.1,802.7,564.3,804.2,566.9,805z"/>
							<path opacity="0.1091" fill="#131313" d="M588.8,831.5c5.5,0,11-0.1,16.4-0.1c2.8-0.7,4.5-2.5,5.1-5.3c0.1-4-1.5-7.3-4.8-9.9
								c-4.4-5-9.1-9.6-14.3-13.9c-2.7-3.2-6.1-4.6-10.1-4.4c-5.2,0-10.4,0.1-15.6,0.1c-2.8,0.7-4.5,2.4-5.2,5.2
								c-0.2,3.9,1.4,7.3,4.5,9.9c4.2,5,8.9,9.7,13.9,13.9C581.5,830.2,584.8,831.7,588.8,831.5z"/>
							<path opacity="0.1091" fill="#131313" d="M584,800.7c6.8,1.2,13.5,2.4,20.3,3.6c4.8,0.9,9.6-4.3,8.7-9
								c-1.6-3.4-3.8-6.3-6.6-8.8c-2.6-2.8-5.5-4.9-9-6.4c-6.4-1-12.8-1.9-19.2-2.9c-4.6-0.7-9.5,4.3-8.8,8.9
								c1.4,3.3,3.5,6.1,6.1,8.5C577.9,797.3,580.8,799.3,584,800.7z"/>
							<path opacity="0.1091" fill="#131313" d="M550,827.2c6.2,0.5,12.4,1.1,18.7,1.6c4.2,0.4,9.3-4.6,8.9-8.8
								c-1.1-2.9-2.7-5.4-5.1-7.4c-2.1-2.3-4.5-4-7.4-5.1c-6.1-0.4-12.1-0.7-18.2-1.1c-4.1-0.2-9.2,4.6-8.9,8.8
								c1,2.8,2.6,5.2,4.8,7.2C544.8,824.6,547.2,826.2,550,827.2z"/>
							<path opacity="0.1091" fill="#131313" d="M575,859.6c5.5,0,11.1,0,16.6,0c2.9-0.6,4.6-2.3,5.1-5.3c-0.2-4.4-2.1-8-5.7-10.9
								c-5-5.6-10.3-10.8-16.1-15.7c-3-3.5-6.6-5.3-11-5.4c-5.2,0-10.4,0-15.6,0c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C567.1,857.7,570.7,859.5,575,859.6z"/>
							<path opacity="0.1091" fill="#131313" d="M603,828.3c5.5,0,11,0,16.6,0c2.9-0.6,4.6-2.3,5.1-5.3c-0.3-4.4-2.2-8.1-5.9-11
								c-5.1-5.7-10.5-11-16.4-15.9c-3-3.5-6.7-5.4-11.2-5.5c-5.2,0-10.4,0-15.6,0.1c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11
								c4.9,5.7,10.2,11,16,15.9C594.9,826.2,598.6,828.1,603,828.3z"/>
							<path opacity="0.1091" fill="#131313" d="M558.2,815.6c6.3,0.6,12.5,1.1,18.8,1.7c4.3,0.4,9.3-4.6,8.9-8.9
								c-1.1-2.9-2.8-5.4-5.2-7.5c-2.1-2.3-4.6-4.1-7.5-5.2c-6.1-0.4-12.2-0.8-18.3-1.2c-4.2-0.3-9.2,4.6-8.9,8.9
								c1,2.8,2.6,5.2,4.9,7.2C553,813,555.4,814.6,558.2,815.6z"/>
							<path opacity="0.1091" fill="#131313" d="M574.5,812.1c6.4,0.9,12.8,1.9,19.2,2.8c5,0.7,9.7-4,8.7-9
								c-1.8-3.6-4.2-6.6-7.2-9.3c-2.7-2.9-5.9-5.2-9.5-6.9c-6.1-0.8-12.1-1.5-18.2-2.3c-4.8-0.6-9.7,3.9-8.8,8.9
								c1.6,3.5,3.9,6.5,6.7,9.1C568,808.2,571,810.4,574.5,812.1z"/>
							<path opacity="0.1091" fill="#131313" d="M601.6,844.3c5.5,0,11-0.1,16.5-0.1c3.2-0.4,4.9-2.1,5.1-5.3
								c-0.8-5-3.2-9.2-7.3-12.4c-6-6.6-12.4-12.8-19.2-18.7c-3.4-4-7.6-6.3-12.6-7c-5.1,0.1-10.3,0.1-15.4,0.2
								c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4c5.8,6.6,12.1,12.8,18.7,18.7C592.5,841.4,596.6,843.7,601.6,844.3z"/>
							<path opacity="0.1091" fill="#131313" d="M549.9,838.1c6.6,1.1,13.2,2.2,19.8,3.2c4.9,0.8,9.7-4.1,8.8-8.9
								c-1.7-3.5-4-6.5-6.9-9c-2.6-2.8-5.7-5.1-9.2-6.7c-6.3-0.9-12.5-1.7-18.8-2.6c-4.7-0.7-9.6,4-8.9,8.8c1.5,3.4,3.6,6.3,6.4,8.8
								C543.6,834.4,546.5,836.5,549.9,838.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M626,829.9c11.2-4,22.4-7.8,33.7-11.5c2.7-1.4,3.8-3.7,3.1-6.7
								c-2.1-4.3-5.5-7.3-10.1-8.9c-7.3-4-14.9-7.5-22.6-10.5c-4.3-2.5-8.7-3.2-13.4-2c-10.9,3.4-21.7,7.1-32.4,10.9
								c-2.7,1.4-3.8,3.6-3.2,6.6c1.9,4.3,5.2,7.3,9.8,9.1c7.1,4.1,14.5,7.7,22.1,10.8C617,830.3,621.4,831,626,829.9z"/>
							<path opacity="0.1091" fill="#131313" d="M597.6,812.9c12-2.6,23.7-6.1,35.1-10.6c2.7-0.3,4.6-1.8,5.7-4.3
								c1.2-2.5,1.1-4.8-0.4-7.1c-3.2-2.9-6.8-5.2-10.8-6.9c-3.9-2-7.9-3.3-12.2-3.8c-11.1,4.3-22.4,7.8-33.9,10.6
								c-2.7,0.3-4.6,1.7-5.7,4.2c-1.2,2.4-1.2,4.8,0.2,7.1c3,2.9,6.5,5.2,10.3,6.9C589.5,811,593.5,812.3,597.6,812.9z"/>
							<path opacity="0.1091" fill="#131313" d="M602.6,779.9c12.4-2.7,24.5-6.3,36.4-10.7c4.1-1.4,7.6-7,5.5-11.3
								c-2.1-2.5-4.7-4.3-7.7-5.6c-2.9-1.6-5.9-2.5-9.2-2.6c-11.6,4.5-23.4,8.2-35.5,11.2c-4.1,1.1-7.3,7.3-5.5,11.3
								c2,2.4,4.4,4.2,7.3,5.4C596.6,779,599.5,779.8,602.6,779.9z"/>
							<path opacity="0.1091" fill="#131313" d="M623.4,804.9c11.1-3.9,22.3-7.8,33.6-11.5c2.4-1.6,3.4-3.8,3.1-6.7
								c-1.3-3.8-4-6.5-8-7.9c-5.9-3.3-12.1-6.2-18.4-8.5c-3.7-2.1-7.4-2.4-11.2-1c-10.9,3.6-21.7,7.2-32.5,11
								c-2.4,1.5-3.5,3.7-3.2,6.6c1.2,3.8,3.7,6.5,7.7,8c5.8,3.4,11.7,6.4,18,8.8C616,805.9,619.7,806.3,623.4,804.9z"/>
							<path opacity="0.1091" fill="#131313" d="M620.6,777.1c11.6-3.2,23.1-6.8,34.4-11c2.7-0.3,4.7-1.7,5.8-4.2
								c1.2-2.5,1-4.9-0.6-7.2c-3.4-3-7.1-5.3-11.3-7c-4-2.1-8.2-3.4-12.7-4c-11,4-22.1,7.7-33.3,10.9c-2.7,0.3-4.7,1.7-5.8,4.1
								c-1.2,2.4-1.1,4.8,0.3,7.1c3.2,3,6.8,5.4,10.8,7.1C612.1,775,616.2,776.4,620.6,777.1z"/>
							<path opacity="0.1091" fill="#131313" d="M584.1,801.1c12.5-2.5,24.7-6.1,36.6-10.6c2.2-0.9,3.7-2.5,4.6-4.7
								c1.2-2.1,1.5-4.3,0.9-6.5c-1.7-2.2-3.8-3.9-6.4-5c-2.4-1.4-5.1-2.1-7.9-2c-11.6,4.5-23.4,8.2-35.5,11.2
								c-2.1,1-3.6,2.6-4.5,4.8c-1.2,2-1.5,4.2-1,6.5c1.5,2.1,3.4,3.7,5.9,4.7C579,800.7,581.5,801.3,584.1,801.1z"/>
							<path opacity="0.1091" fill="#131313" d="M609.8,832.6c11-4.2,22-8.1,33.2-11.7c2.3-1.6,3.4-3.9,3.1-6.7
								c-1.1-3.7-3.5-6.2-7.3-7.6c-5.5-3.2-11.2-5.8-17.1-8c-3.5-2-7-2.2-10.5-0.7c-10.8,3.5-21.6,7.2-32.3,11.2
								c-2.3,1.6-3.4,3.8-3.3,6.6c1,3.7,3.3,6.3,7,7.7c5.3,3.3,10.9,6,16.8,8.3C602.8,833.8,606.3,834.1,609.8,832.6z"/>
							<path opacity="0.1091" fill="#131313" d="M638.3,801.8c11.2-4,22.4-7.8,33.7-11.5c2.7-1.4,3.8-3.7,3.1-6.7
								c-2.1-4.3-5.4-7.2-10.1-8.9c-7.3-4-14.8-7.5-22.6-10.4c-4.3-2.5-8.7-3.1-13.3-1.9c-10.9,3.4-21.7,7.1-32.4,10.9
								c-2.7,1.4-3.8,3.6-3.2,6.6c1.9,4.3,5.2,7.3,9.8,9c7.1,4.1,14.4,7.7,22,10.7C629.4,802.2,633.8,802.9,638.3,801.8z"/>
							<path opacity="0.1091" fill="#131313" d="M593,790.1c12.5-2.6,24.7-6.1,36.6-10.6c4.1-1.4,7.5-7,5.5-11.3
								c-2-2.4-4.5-4.3-7.5-5.5c-2.8-1.6-5.8-2.4-8.9-2.5c-11.6,4.5-23.5,8.2-35.6,11.1c-4,1.1-7.3,7.4-5.5,11.3
								c1.9,2.3,4.2,4.1,7,5.3C587.2,789.3,590,790.1,593,790.1z"/>
							<path opacity="0.1091" fill="#131313" d="M609.9,787.7c12-2.6,23.7-6.1,35.1-10.6c2.7-0.4,4.6-1.8,5.7-4.3
								c1.2-2.5,1-4.8-0.4-7.1c-3.2-2.9-6.8-5.2-10.8-6.8c-3.9-2-7.9-3.3-12.2-3.8c-11.1,4.3-22.4,7.8-33.9,10.6
								c-2.7,0.3-4.6,1.7-5.7,4.2c-1.2,2.4-1.2,4.8,0.2,7.1c3,2.9,6.5,5.2,10.3,6.9C601.8,785.9,605.7,787.2,609.9,787.7z"/>
							<path opacity="0.1091" fill="#131313" d="M636.6,817.1c11.2-4,22.4-7.9,33.7-11.6c2.9-1.4,3.9-3.6,3.1-6.7
								c-2.4-4.5-6.1-7.6-11.1-9.3c-7.9-4.3-16-8-24.4-11.3c-4.6-2.7-9.3-3.5-14.3-2.4c-10.9,3.4-21.6,7-32.3,10.9
								c-2.9,1.3-3.9,3.5-3.2,6.6c2.2,4.5,5.8,7.6,10.7,9.5c7.7,4.4,15.6,8.3,23.8,11.6C627,817.3,631.7,818.1,636.6,817.1z"/>
							<path opacity="0.1091" fill="#131313" d="M584.6,813.3c13.3-1.8,26.2-4.9,38.7-9.5c4.2-1.3,7.6-7,5.5-11.3
								c-2.2-2.5-4.8-4.4-7.9-5.7c-2.9-1.6-6-2.5-9.4-2.7c-12.1,4.5-24.6,7.9-37.3,10.1c-4.2,0.9-7.3,7.4-5.5,11.2
								c1.9,2.4,4.3,4.2,7.2,5.4C578.7,812.5,581.5,813.3,584.6,813.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M603.7,834.2c9.2-0.7,18.1-1.5,27-4.4c3.1-1.1,6.3-2.2,9.4-3.4
								c6.4-2.6,12.5-5.7,18.4-9.3c3-1,4.3-3.1,4-6.2c-1.6-4.6-4.7-8.1-9.2-10.5c-6.9-5.1-14.2-9.8-21.7-14c-4-3.2-8.5-4.5-13.3-4.1
								c-8,2.4-16,4.6-24.3,5.6c-2.8,0.3-5.5,0.5-8.3,0.8c-5.1-0.1-10.1,0.1-15.2,0.5c-3.2,0.2-5,1.9-5.3,5.1
								c0.7,5.1,3,9.4,7.1,12.8c5.9,6.9,12.2,13.4,18.9,19.5C594.5,830.9,598.6,833.4,603.7,834.2z"/>
							<path opacity="0.1091" fill="#131313" d="M579.3,815.2c4.4,0.3,8.7,0,13-0.9c4.7-0.6,9.3-1.6,13.9-2.9c3.2-1,6.3-2.1,9.5-3.1
								c6.5-2.4,12.7-5.3,18.7-8.8c2.3-1.4,3.6-3.5,4-6.2c-0.1-3.3-1.7-5.8-4.7-7.5c-4-3.2-8.4-6-13-8.3c-2.8-2-5.8-2.4-8.8-1.1
								c-8.5,3.3-16.9,6.2-25.9,7.7c-2.9,0.4-5.8,0.8-8.8,1.2c-5.3,0.7-10.6,0.9-15.9,0.6c-2.6,0.8-4.4,2.5-5.3,5.1
								c-0.6,3.4,0.5,6.3,3.2,8.8c3.4,4.3,7.2,8.3,11.4,11.8C573,814.6,575.9,815.7,579.3,815.2z"/>
							<path opacity="0.1091" fill="#131313" d="M578.6,781.2c6.7,2.3,13.4,2.4,20.1,0.2c3.8-0.7,7.5-1.5,11.2-2.5
								c3.8-1,7.5-2.1,11.2-3.5c6.6-2.5,13-5.5,19.1-9c2-1.6,3.4-3.6,4.1-6.1c0.6-2.5-0.2-4.6-2.3-6.2c-2.6-2.4-5.4-4.4-8.6-5.8
								c-2.3-1.4-4.5-1.4-6.6,0.1c-4,2.5-8.1,4.5-12.4,6.3c-4.2,2-8.5,3.5-13,4.7c1-0.3,2-0.5,3-0.8c-3.9,0.9-7.9,1.9-11.8,2.8
								c1-0.2,2-0.5,3-0.7c-6.1,2.3-12.3,2.5-18.6,0.6c-2.3,0.2-4.3,1.1-5.9,2.9c-1.8,1.5-2.8,3.5-3.1,5.8c0.8,2.6,2.2,4.9,4.2,6.7
								C573.8,778.9,576,780.4,578.6,781.2z"/>
							<path opacity="0.1091" fill="#131313" d="M600.5,807.9c9.1-0.5,18-1.3,26.9-4c3.2-1.1,6.3-2.1,9.5-3.2
								c6.5-2.5,12.7-5.4,18.6-8.9c2.6-1.3,3.9-3.3,4-6.2c-0.7-3.9-3-6.9-6.7-8.8c-5.3-4-10.9-7.6-16.8-10.7
								c-3.3-2.5-6.9-3.3-10.8-2.4c-7.7,3.3-15.4,6.3-23.7,7.9c-7.8,1.5-15.6,1.9-23.6,2.2c-2.8,0.6-4.6,2.4-5.3,5.1
								c-0.2,3.9,1.2,7.3,4.4,10c4.2,5.1,8.7,9.8,13.7,14.1C593.3,806.4,596.6,808,600.5,807.9z"/>
							<path opacity="0.1091" fill="#131313" d="M597.4,778.6c4.6,1.9,9.3,2.4,14.1,1.7c4.4-0.8,8.8-1.8,13.1-2.9
								c4.8-1,9.5-2.5,14.1-4.4c6.4-2.5,12.6-5.6,18.5-9.1c2.2-1.5,3.5-3.6,3.9-6.2c0.1-3.1-1.3-5.5-4.2-7.2c-3.7-3-7.7-5.5-12-7.6
								c-2.7-1.9-5.5-2.1-8.3-0.7c-2.4,1.4-4.9,2.9-7.3,4.3c-3.8,2.1-7.7,3.8-11.7,5.1c-6.4,2.3-12.9,3.7-19.5,5.2
								c1.1-0.1,2.2-0.2,3.3-0.3c-3.2-0.5-6.4-1-9.6-1.5c-4.6-0.7-9.6,4.1-9,8.7c1.4,3.3,3.4,6.2,6.1,8.6
								C591.3,775,594.1,777.1,597.4,778.6z"/>
							<path opacity="0.1091" fill="#131313" d="M561.8,803.5c6.7,2.2,13.3,2.2,19.9,0c2.6-0.5,5.2-1.1,7.8-1.8
								c4.9-1,9.7-2.5,14.3-4.5c6.5-2.6,12.7-5.7,18.6-9.3c2-1.6,3.4-3.6,4.1-6.1c0.7-2.4,0-4.4-2-6c-2.4-2.3-5.1-4.1-8.1-5.5
								c-2.2-1.3-4.3-1.2-6.4,0.3c-5.6,3-11.4,5.5-17.4,7.6c1-0.3,2-0.6,3-0.9c-4,1.2-8,2.3-12,3.5c1-0.2,2-0.5,3-0.7
								c-4,0.9-8.1,1.7-12.1,2.6c1-0.2,2-0.4,3.1-0.6c-3.8,0.8-7.7,1.5-11.5,2.3c1-0.2,2-0.4,3.1-0.6c-3.4-0.4-6.9-0.9-10.3-1.3
								c-4.1-0.5-9.3,4.6-9,8.7c0.9,2.8,2.5,5.3,4.7,7.3C556.6,800.8,559,802.5,561.8,803.5z"/>
							<path opacity="0.1091" fill="#131313" d="M586.8,836c4.4,0,8.7-0.3,13-1.1c4.7-0.5,9.3-1.5,13.9-3c3.1-1.1,6.3-2.2,9.4-3.3
								c6.4-2.5,12.6-5.6,18.5-9.1c2.6-1.2,3.9-3.3,4-6.2c-0.8-4-3-6.9-6.8-8.9c-5.4-4.1-11-7.7-17-10.9c-3.3-2.6-7-3.4-10.9-2.4
								c-8.6,2.5-17.1,4.8-26,5.9c-8.1,0.9-16.2,1-24.4,1.2c-2.9,0.5-4.7,2.2-5.3,5.1c0.1,4.4,1.8,8,5.3,11
								c4.8,5.7,9.9,11.1,15.5,16.1C578.8,833.9,582.4,835.8,586.8,836z"/>
							<path opacity="0.1091" fill="#131313" d="M614.7,804.6c4.4,0,8.8-0.3,13.1-1c4.8-0.5,9.5-1.4,14-2.8c3.2-1,6.4-2.1,9.5-3.1
								c6.5-2.5,12.8-5.4,18.8-8.9c2.8-1.1,4.2-3.2,4-6.2c-1.3-4.4-4.1-7.7-8.3-9.9c-6.4-4.7-13.1-9-20-12.8
								c-3.8-2.9-7.9-4.1-12.5-3.4c-7.2,3.3-14.5,6.3-22.3,7.9c-2.7,0.5-5.3,0.9-8,1.4c-5,0.5-10,0.7-15,0.9
								c-2.9,0.5-4.7,2.2-5.3,5.1c0.1,4.4,1.9,8,5.4,11c4.8,5.7,10,11.1,15.6,16.1C606.7,802.4,610.3,804.3,614.7,804.6z"/>
							<path opacity="0.1091" fill="#131313" d="M569.9,791.9c6.7,2.3,13.4,2.4,20,0.2c2.7-0.5,5.3-1,7.9-1.7
								c4.9-1,9.7-2.5,14.4-4.4c6.5-2.5,12.8-5.6,18.9-9.1c2-1.6,3.4-3.6,4.1-6.1c0.6-2.6-0.2-4.7-2.4-6.3c-2.6-2.4-5.5-4.4-8.8-6
								c-2.3-1.5-4.5-1.4-6.7,0.1c-4.1,2.4-8.3,4.4-12.7,6c-4.2,1.9-8.6,3.4-13.2,4.5c1-0.2,2-0.5,3.1-0.7c-6.8,1.5-13.5,3-20.3,4.3
								c1-0.2,2.1-0.4,3.1-0.6c-3.5-0.5-6.9-0.9-10.4-1.4c-4.2-0.5-9.3,4.6-9,8.7c1,2.8,2.6,5.3,4.8,7.3
								C564.7,789.2,567.1,790.8,569.9,791.9z"/>
							<path opacity="0.1091" fill="#131313" d="M586.2,788.3c4.8,2.3,9.8,3.2,14.9,2.5c4.4-0.8,8.8-1.7,13.2-2.8
								c4.8-1,9.6-2.5,14.2-4.3c6.4-2.5,12.6-5.5,18.6-9c2.2-1.5,3.5-3.6,3.9-6.2c0.1-3.1-1.3-5.5-4.2-7.2c-3.7-3-7.7-5.5-12-7.6
								c-2.7-1.9-5.5-2.1-8.3-0.7c-2.6,1.3-5.2,2.7-7.7,4c-3.9,1.9-8,3.4-12.2,4.6c-6.6,2-13.3,3.2-20,4.6c1.2-0.1,2.3-0.1,3.5-0.2
								c-3.4-0.7-6.8-1.5-10.2-2.2c-4.8-1.1-9.7,4-9,8.7c1.6,3.5,3.7,6.5,6.5,9.1C579.8,784.3,582.8,786.6,586.2,788.3z"/>
							<path opacity="0.1091" fill="#131313" d="M613.3,820.7c9.2-0.6,18.2-1.4,27.1-4.1c3.2-1.1,6.3-2.2,9.5-3.3
								c6.5-2.5,12.7-5.6,18.7-9.1c3-1,4.3-3,4-6.2c-1.7-4.7-4.9-8.3-9.6-10.7c-7.2-5.3-14.6-10.1-22.4-14.4
								c-4.1-3.2-8.7-4.7-13.7-4.3c-7.5,2.7-15,5.3-22.9,6.5c-2.7,0.4-5.4,0.7-8,1.1c-5,0.1-10,0.3-14.9,0.7c-3.1,0.3-4.9,2-5.3,5.1
								c0.6,5,2.8,9.1,6.8,12.5c5.7,6.7,11.9,13,18.4,19C604.3,817.6,608.4,819.9,613.3,820.7z"/>
							<path opacity="0.1091" fill="#131313" d="M561.7,814.4c4.9,2.6,10,3.5,15.3,2.8c4.4-0.8,8.8-1.8,13.1-2.8
								c4.9-1,9.7-2.4,14.4-4.2c6.6-2.4,12.9-5.3,19-8.8c2.1-1.6,3.4-3.7,4-6.2c0.3-2.8-0.7-5-3.2-6.6c-3.1-2.6-6.5-4.8-10.2-6.5
								c-2.5-1.6-4.9-1.6-7.3-0.1c-4.4,2-8.8,3.6-13.4,4.9c-4.5,1.5-9,2.6-13.7,3.4c1.1-0.2,2.2-0.3,3.3-0.5c-6.6,1-13.3,2-19.9,3.1
								c1.1-0.1,2.3-0.2,3.4-0.2c-3.6-0.9-7.2-1.7-10.8-2.6c-4.7-1.1-9.7,4.1-9,8.7c1.5,3.4,3.6,6.4,6.3,9
								C555.4,810.5,558.4,812.8,561.7,814.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M635.9,806.6c8.8-0.2,17.5-0.2,26.3-0.1c3.2-0.3,4.9-2.1,5.1-5.3
								c-0.9-5.1-3.4-9.3-7.6-12.7c-6.2-6.8-12.8-13.1-19.7-19.1c-3.5-4.1-7.8-6.5-12.9-7.2c-8.4-0.3-16.7-0.3-25.1,0.1
								c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7c6,6.8,12.4,13.2,19.1,19.2C626.6,803.5,630.8,805.9,635.9,806.6z"/>
							<path opacity="0.1091" fill="#131313" d="M611.4,787.7c8.8,0.1,17.5,0.1,26.3,0.1c2.6-0.9,4.3-2.7,5.1-5.3
								c0.5-3.4-0.7-6.4-3.5-8.7c-3.6-4.2-7.5-8.1-11.9-11.5c-2.5-2.7-5.4-3.8-8.8-3.2c-8.5,0.1-17,0-25.5-0.1
								c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7c3.5,4.2,7.3,8.1,11.5,11.6C605.1,787.1,608,788.2,611.4,787.7z"/>
							<path opacity="0.1091" fill="#131313" d="M610.8,753.8c9.5,1.6,19,2.2,28.6,1.8c4.1,0,9.2-4.5,8.8-8.9
								c-0.9-2.8-2.5-5.1-4.7-7.1c-2-2.2-4.3-3.8-7.1-4.7c-9.3,0.5-18.6,0.2-27.8-1c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9
								c0.8,2.6,2.2,4.8,4.3,6.7C606,751.5,608.2,753,610.8,753.8z"/>
							<path opacity="0.1091" fill="#131313" d="M632.7,780.3c8.7-0.2,17.4-0.2,26.2-0.1c2.8-0.7,4.5-2.5,5.1-5.3
								c0.1-4-1.5-7.3-4.8-9.9c-4.4-5-9.2-9.6-14.3-13.8c-2.7-3.2-6.1-4.6-10.1-4.4c-8.4-0.1-16.8-0.1-25.2,0.1
								c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9c4.2,5,8.9,9.7,13.9,13.9C625.4,779,628.7,780.5,632.7,780.3z"/>
							<path opacity="0.1091" fill="#131313" d="M627.9,749.5c9.6,3.4,19.5,4.5,29.5,3.4c2.6,0.3,4.7-0.5,6.4-2.5
								c1.9-1.8,2.6-4,2.2-6.5c-1.6-3.4-3.9-6.3-6.8-8.8c-2.6-2.8-5.6-4.9-9.1-6.4c-9.5,1.1-18.9,0.3-28.1-2.5
								c-4.6-0.8-9.5,4.4-8.8,8.9c1.4,3.3,3.5,6.1,6.1,8.5C621.8,746.1,624.7,748.1,627.9,749.5z"/>
							<path opacity="0.1091" fill="#131313" d="M593.9,776c9.4,1.5,18.9,2.1,28.5,1.7c4.3,0,9.3-4.3,8.9-8.8
								c-1.1-2.9-2.8-5.4-5.2-7.5c-2.1-2.4-4.6-4.1-7.5-5.2c-9.3,0.5-18.5,0.2-27.8-1c-4.1-0.3-9.2,4.7-8.9,8.8
								c1,2.8,2.6,5.2,4.8,7.2C588.7,773.4,591.1,775,593.9,776z"/>
							<path opacity="0.1091" fill="#131313" d="M618.9,808.4c8.8-0.1,17.6-0.1,26.3,0c2.9-0.6,4.6-2.3,5.1-5.3
								c-0.2-4.4-2.1-8-5.7-10.9c-5-5.6-10.4-10.8-16.1-15.7c-3-3.5-6.7-5.3-11-5.4c-8.4-0.2-16.9-0.2-25.3,0
								c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9c4.8,5.6,10,10.9,15.6,15.7C611,806.5,614.6,808.3,618.9,808.4z"/>
							<path opacity="0.1091" fill="#131313" d="M646.9,777c8.8-0.1,17.5-0.1,26.3,0c2.9-0.6,4.6-2.3,5.1-5.3
								c-0.3-4.4-2.3-8.1-5.9-11c-5.1-5.7-10.6-11-16.4-15.9c-3-3.5-6.8-5.3-11.2-5.5c-8.4-0.2-16.8-0.2-25.2,0
								c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9C638.8,775,642.5,776.9,646.9,777z"/>
							<path opacity="0.1091" fill="#131313" d="M602.1,764.4c9.5,1.6,19,2.2,28.6,1.8c4.3,0,9.4-4.2,8.9-8.9
								c-1.1-3-2.9-5.5-5.3-7.6c-2.1-2.4-4.7-4.1-7.6-5.3c-9.3,0.5-18.6,0.2-27.9-1c-4.2-0.3-9.2,4.7-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2
								C596.9,761.8,599.3,763.4,602.1,764.4z"/>
							<path opacity="0.1091" fill="#131313" d="M618.4,760.8c9.4,2.7,19,3.5,28.7,2.5c2.6,0.4,4.8-0.4,6.6-2.3
								c1.9-1.9,2.6-4.1,2.1-6.7c-1.8-3.6-4.2-6.7-7.3-9.3c-2.8-2.9-5.9-5.2-9.6-6.9c-9.3,1-18.4,0.3-27.5-1.9
								c-4.8-0.7-9.7,4-8.8,8.9c1.6,3.5,3.9,6.5,6.7,9.1C611.9,757,614.9,759.2,618.4,760.8z"/>
							<path opacity="0.1091" fill="#131313" d="M645.5,793.1c8.8-0.2,17.5-0.2,26.3-0.1c3.2-0.4,4.9-2.1,5.1-5.3
								c-0.8-5-3.2-9.1-7.3-12.4c-6-6.6-12.5-12.8-19.3-18.6c-3.4-4-7.6-6.3-12.7-6.9c-8.4-0.3-16.7-0.3-25.1,0.1
								c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4c5.8,6.6,12.1,12.8,18.7,18.7C636.4,790.1,640.5,792.5,645.5,793.1z"/>
							<path opacity="0.1091" fill="#131313" d="M593.8,786.8c9.5,3.1,19.2,4.1,29,2.9c2.6,0.4,4.8-0.4,6.5-2.4
								c1.9-1.8,2.6-4,2.2-6.6c-1.7-3.5-4-6.5-7-9.1c-2.7-2.9-5.8-5.1-9.3-6.7c-9.4,1.1-18.6,0.4-27.8-2.2c-4.7-0.8-9.6,4.1-8.9,8.8
								c1.5,3.4,3.6,6.3,6.4,8.8C587.5,783.2,590.4,785.3,593.8,786.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M707.8,813.1c5.4-4.4,10.4-9.2,15.1-14.3c5-5.5,9.6-11.4,13.8-17.5
								c1.6-2.4,1.6-4.9,0.1-7.4c-2.8-3-6.4-4.3-10.7-3.9c-6.9-0.6-13.7-0.7-20.6-0.2c-4.3-0.4-7.8,0.8-10.7,3.7
								c-4.6,5.2-9.4,10.3-14.5,15c-4.8,4.3-9.6,8.5-14.5,12.6c-1.8,2.3-2.1,4.7-0.8,7.3c2.6,3.2,6.1,4.9,10.5,5
								c6.9,1.3,13.9,2.1,21,2.3C700.8,816.6,704.5,815.7,707.8,813.1z"/>
							<path opacity="0.1091" fill="#131313" d="M679.6,798.1c4-2,8-4.1,11.9-6.1c2.1-1.9,4.1-4,6-6.2c2.5-2.6,4.8-5.3,7.1-8
								c3.8-4.2,7.3-8.6,10.4-13.3c1-2.4,1-4.9,0.1-7.4c-1.4-2.7-3.7-3.9-6.9-3.9c-4.5-0.6-8.9-0.6-13.4-0.1c-3.2-0.1-5.5,1.1-7,3.8
								c-3.2,4.2-6.6,8.3-10.3,12.1c-3.3,3.7-6.7,7.3-10.2,10.8c0.8-0.8,1.7-1.6,2.5-2.4c-3.6,1.9-7.1,3.9-10.7,5.8
								c-4.3,2.3-4.9,9.3-1.4,12.5c3.4,1.7,6.9,2.6,10.7,2.8C672.3,799.2,675.9,799.1,679.6,798.1z"/>
							<path opacity="0.1091" fill="#131313" d="M684.7,764.4c3.6-2.1,7.1-4.2,10.7-6.3c2-2,4-4,5.8-6.2c2.4-2.6,4.8-5.3,7-8
								c3.7-4.2,7.1-8.7,10.2-13.4c1-2.4,1-4.9,0-7.4c-1.4-2.6-3.7-3.9-6.9-3.9c-4.4-0.6-8.9-0.6-13.3-0.1c-3.2-0.1-5.5,1.2-6.9,3.8
								c-2.8,4.3-5.9,8.3-9.3,12.2c-3.1,3.7-6.3,7.4-9.5,10.9c0.8-0.8,1.6-1.6,2.4-2.4c-3.2,2-6.4,4-9.6,6c-4,2.5-4.9,9.2-1.3,12.5
								c3.2,1.6,6.6,2.6,10.2,2.8C677.7,765.5,681.2,765.3,684.7,764.4z"/>
							<path opacity="0.1091" fill="#131313" d="M706.3,788.7c5.3-4.5,10.2-9.2,15-14.3c5-5.5,9.7-11.4,14-17.5
								c1.5-2.4,1.6-4.9,0.1-7.4c-2.7-2.9-6.2-4.2-10.4-3.9c-6.7-0.6-13.4-0.7-20.1-0.2c-4.2-0.4-7.7,0.8-10.5,3.7
								c-4.2,5.3-8.7,10.4-13.5,15.1c-4.6,4.3-9.2,8.6-14,12.7c-1.8,2.3-2,4.7-0.8,7.3c2.4,3.2,5.7,4.8,9.9,4.9
								c6.5,1.3,13.2,2,19.8,2.1C699.7,792.2,703.2,791.3,706.3,788.7z"/>
							<path opacity="0.1091" fill="#131313" d="M707.9,759.9c5-4.6,9.9-9.4,14.7-14.3c5.1-5.5,9.8-11.4,14-17.5
								c1.5-2.4,1.5-4.9,0-7.4c-2.6-2.9-6-4.2-10.1-3.9c-6.5-0.6-13-0.6-19.5-0.1c-4.1-0.4-7.5,0.9-10.1,3.8
								c-3.8,5.4-7.9,10.5-12.4,15.3c-4.3,4.4-8.8,8.7-13.4,12.8c-1.6,2.3-1.9,4.7-0.8,7.3c2.1,3.1,5.2,4.7,9.1,4.8
								c6.1,1.2,12.2,1.9,18.4,2C701.8,763.4,705.1,762.5,707.9,759.9z"/>
							<path opacity="0.1091" fill="#131313" d="M667.1,786.1c7.3-3.3,13.3-8.1,18.2-14.4c5.2-5.5,10-11.3,14.4-17.5
								c1.5-1.8,2.3-3.8,2.2-6.2c0.2-2.4-0.4-4.5-1.8-6.3c-2.3-1.3-4.8-2-7.5-1.9c-2.7-0.3-5.2,0.2-7.6,1.4
								c-4.1,5.9-8.6,11.5-13.5,16.7c-4.5,6-10,10.7-16.6,14.2c-1.6,1.7-2.4,3.7-2.5,6c-0.4,2.3,0,4.4,1.1,6.4
								c1.9,1.5,4.1,2.3,6.6,2.4C662.6,787.4,664.9,787.1,667.1,786.1z"/>
							<path opacity="0.1091" fill="#131313" d="M688.6,818.2c3-2.1,6-4.3,8.9-6.4c2.4-1.8,4.5-3.9,6.4-6.1c2.6-2.5,5-5.2,7.3-8
								c3.8-4.2,7.3-8.6,10.5-13.3c1.1-2.4,1.1-4.9,0.1-7.4c-1.7-2.7-4.2-4-7.5-3.9c-4.9-0.6-9.7-0.6-14.6-0.1
								c-3.3-0.2-5.9,1.1-7.6,3.7c-3.4,4.2-7,8.2-10.9,11.9c-3.5,3.7-7.2,7.3-11,10.7c0.9-0.8,1.9-1.6,2.8-2.3c-2.8,2-5.5,4-8.3,6
								c-2.4,1.2-3.8,3.1-4,5.9c-0.4,2.7,0.5,4.9,2.6,6.6c3.9,1.8,8.1,2.8,12.4,3C680.1,819.1,684.4,819,688.6,818.2z"/>
							<path opacity="0.1091" fill="#131313" d="M721.7,785.3c5.3-4.5,10.3-9.3,15.1-14.4c5.1-5.6,9.7-11.4,14-17.6
								c2-2.4,2-4.9,0.1-7.4c-3.9-3.1-8.3-4.4-13.5-4c-8.7-0.6-17.3-0.7-26-0.3c-5.1-0.5-9.6,0.7-13.5,3.7
								c-4.2,5.1-8.6,10-13.3,14.6c-4.6,4.1-9.3,8.2-13.8,12.4c-2.2,2.2-2.4,4.7-0.8,7.3c3.5,3.4,7.7,5.2,12.8,5.2
								c8.4,1.5,16.9,2.4,25.4,2.7C713.1,788.7,717.6,787.9,721.7,785.3z"/>
							<path opacity="0.1091" fill="#131313" d="M673.9,774.7c6.5-2,12-5.4,16.5-10.1c2.3-1.8,4.4-3.9,6.2-6.1
								c1.6-1.9,3.2-3.8,4.8-5.7c3.1-3.7,6-7.6,8.8-11.7c0.9-2.4,0.9-4.9,0-7.4c-1.1-2.5-3.1-3.8-6-3.9c-3.9-0.6-7.8-0.6-11.7-0.1
								c-2.9,0-4.9,1.3-6.1,3.8c-2.1,2.8-4.2,5.6-6.2,8.4c0.7-0.8,1.4-1.7,2.1-2.5c-2.8,3.3-5.5,6.6-8.3,9.8
								c0.7-0.8,1.4-1.6,2.1-2.4c-5,6-11.1,10.5-18.4,13.4c-3.7,2.1-4.2,9.6-1.3,12.5c2.6,1.6,5.5,2.4,8.6,2.6
								C668,775.9,671,775.7,673.9,774.7z"/>
							<path opacity="0.1091" fill="#131313" d="M697.1,770.9c5.5-4.2,10.6-8.9,15.2-14.2c5.1-5.5,9.7-11.4,14-17.5
								c1.3-2.4,1.3-4.9,0-7.4c-2.1-2.8-5-4.1-8.7-3.9c-5.6-0.6-11.2-0.6-16.8-0.1c-3.7-0.3-6.6,1-8.8,3.8
								c-3.9,5.5-8.2,10.7-12.9,15.6c-4.3,4.7-9,9-14.1,12.9c-1.5,2.3-1.7,4.8-0.8,7.3c1.7,3,4.4,4.5,8,4.7
								c5.3,1.1,10.7,1.7,16.1,1.7C691.9,774.4,694.9,773.5,697.1,770.9z"/>
							<path opacity="0.1091" fill="#131313" d="M719,800.2c5.3-4.5,10.3-9.3,15-14.4c5-5.5,9.6-11.4,13.8-17.6
								c1.9-2.4,1.9-4.9,0.1-7.4c-3.7-3.1-8-4.4-12.9-4c-8.3-0.6-16.7-0.7-25-0.3c-5-0.5-9.3,0.7-13,3.7c-4.4,5.1-9,10-13.9,14.6
								c-4.7,4.1-9.4,8.2-14.1,12.4c-2.1,2.2-2.4,4.7-0.8,7.3c3.4,3.4,7.6,5.2,12.5,5.2c8.3,1.5,16.6,2.4,25,2.7
								C710.5,803.6,714.9,802.8,719,800.2z"/>
							<path opacity="0.1091" fill="#131313" d="M669.7,799.1c7.2-3.4,13.2-8.2,18-14.4c5.2-5.5,10-11.3,14.3-17.6
								c1.6-1.7,2.4-3.8,2.3-6.2c0.2-2.4-0.4-4.5-2-6.3c-2.5-1.4-5.2-2-8-1.9c-2.9-0.3-5.6,0.2-8.1,1.4c-4.2,5.9-8.7,11.5-13.7,16.7
								c-4.5,5.9-10.1,10.7-16.7,14.2c-1.7,1.6-2.6,3.6-2.7,6c-0.4,2.3,0,4.5,1.3,6.4c2.2,1.5,4.7,2.4,7.4,2.5
								C664.6,800.3,667.2,800.1,669.7,799.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M748.1,832.8c3.7-7.2,7.3-14.4,10.1-22.1c1.3-3.3,2.4-6.6,3.2-10.1
								c1.6-5.2,2.8-10.5,3.6-15.9c0.3-2.8-0.6-5-2.7-6.8c-3.1-1.8-6.3-1.9-9.6-0.3c-5.5,1.6-10.7,3.7-15.8,6.3
								c-3.6,1.1-5.8,3.4-6.8,6.8c-1.1,3.2-2.1,6.3-3.2,9.5c-1.7,4.7-3.6,9.2-5.8,13.7c-3.2,6.8-7,13.3-10.7,19.9
								c-0.8,2.7-0.2,5.1,1.6,7.2c3,2.3,6.3,2.8,10.1,1.6c6-0.8,11.9-2.1,17.7-4C743.7,838.1,746.4,836.1,748.1,832.8z"/>
							<path opacity="0.1091" fill="#131313" d="M720.2,819.8c5-2.6,8.5-6.3,10.7-11c1.7-3.3,3.1-6.7,4.3-10.3
								c1.2-3.3,2.4-6.6,3.6-9.9c2.2-6.9,3.9-14,5-21.2c0-2.6-0.9-4.9-2.7-6.8c-2.2-1.9-4.8-2.3-7.6-1.1c-4.2,1.1-8.1,2.7-11.9,4.8
								c-2.9,1.1-4.5,3.1-4.7,6c-1.3,6.5-3.2,12.8-5.4,19.1c-2.2,5.7-4.5,11.3-6.9,16.9c0.5-1,1-2,1.5-3c-2.9,2.9-5.8,5.9-8.7,8.8
								c-3.3,3.4-1.3,10.4,2.8,12.2c3.5,0.5,7,0.3,10.5-0.7C714,823.1,717.2,821.8,720.2,819.8z"/>
							<path opacity="0.1091" fill="#131313" d="M726,785.1c2.4-3.2,4.7-6.5,6.7-10c2.1-3.3,3.7-6.8,4.8-10.6
								c1.2-3.3,2.4-6.6,3.6-9.9c2.2-6.9,3.8-14,4.9-21.1c0.2-2.7-0.7-5-2.8-6.8c-3-1.8-6.1-1.9-9.4-0.3c-5.3,1.5-10.4,3.6-15.3,6.2
								c-3.5,1.1-5.6,3.4-6.5,6.7c-0.9,6.1-2.2,12.1-4.1,18c-1.9,5.7-4.1,11.4-6.6,16.8c0.6-1,1.3-2.1,1.9-3.1
								c-1.7,2.6-3.4,5.1-5.1,7.7c-1.9,1.9-2.6,4.2-1.9,6.9c0.6,2.7,2.1,4.5,4.7,5.3c4.3,0.3,8.6-0.1,12.9-1.3
								C718.2,788.8,722.2,787.3,726,785.1z"/>
							<path opacity="0.1091" fill="#131313" d="M746.4,808.5c1.5-2.7,3-5.5,4.4-8.2c2.4-4.5,4.4-9.2,5.9-14
								c1.3-3.3,2.4-6.7,3.2-10.1c1.6-5.2,2.7-10.5,3.5-15.9c0.5-2.8-0.4-5.1-2.7-6.8c-3.6-1.7-7.3-1.6-11,0.3
								c-6.4,1.9-12.5,4.4-18.5,7.4c-4,1.2-6.7,3.7-8.2,7.4c-0.9,3-1.8,6-2.6,9.1c-1.4,4.5-3.1,8.9-5.1,13.2
								c-3,6.8-6.6,13.2-10.2,19.6c-0.9,2.7-0.4,5.1,1.6,7.2c3.3,2.2,7,2.7,10.9,1.4c6.6-0.9,13.1-2.4,19.4-4.4
								C741.3,814,744.4,811.9,746.4,808.5z"/>
							<path opacity="0.1091" fill="#131313" d="M748.1,779.4c1.5-2.8,3.1-5.5,4.6-8.3c2.4-4.5,4.5-9.2,6.1-14.1
								c1.4-3.3,2.4-6.7,3.3-10.2c1.6-5.3,2.8-10.6,3.7-16.1c0.7-2.9-0.2-5.2-2.7-6.8c-4.3-1.5-8.6-1.2-12.8,1
								c-7.5,2.4-14.8,5.3-21.9,8.7c-4.6,1.3-7.9,4-9.9,8.1c-0.7,2.9-1.3,5.7-2,8.6c-1.1,4.4-2.5,8.6-4.3,12.7
								c-2.6,6.7-6.1,13-9.6,19.3c-1.1,2.8-0.5,5.2,1.7,7.2c3.7,2.2,7.7,2.6,12,1.1c7.3-1.1,14.5-2.8,21.5-5
								C742.3,785.1,745.7,783,748.1,779.4z"/>
							<path opacity="0.1091" fill="#131313" d="M708.6,806.8c4.5-3.8,7.7-8.4,9.6-13.8c2.2-4.8,4.1-9.7,5.9-14.6
								c2.6-8,4.3-16,6-24.3c-0.2-2.6-1-4.9-2.6-6.9c-1.6-2-3.6-2.6-6.1-1.8c-3.3,0.6-6.3,1.8-9.2,3.4c-2.4,1.1-3.5,2.8-3.4,5.4
								c-0.6,3.3-1.3,6.6-1.9,9.9c0.3-1,0.5-2,0.8-3c-1.2,4.2-2.3,8.4-3.5,12.6c0.3-1,0.6-2,0.8-2.9c-1.6,4.7-3.3,9.3-5.2,13.8
								c-1.6,5.1-4.4,9.5-8.4,13.3c-1,2.1-1.2,4.3-0.5,6.5c0.4,2.3,1.5,4.2,3.3,5.7c2.5,0.7,5,0.7,7.6,0
								C704.4,809.7,706.7,808.6,708.6,806.8z"/>
							<path opacity="0.1091" fill="#131313" d="M729.6,838.9c3.9-2.8,6.7-6.3,8.5-10.5c1.7-3.4,3.1-6.8,4.3-10.4
								c1.2-3.3,2.4-6.6,3.7-10c2.3-7,4-14.1,5.2-21.3c-0.1-2.6-1-4.9-2.7-6.8c-2-1.9-4.4-2.3-7.1-1.3c-3.9,1-7.6,2.4-11,4.4
								c-2.7,1.1-4.2,3-4.3,5.8c-1,3.3-1.9,6.6-2.9,10c0.4-1,0.7-2.1,1.1-3.1c-1.5,4.3-3,8.5-4.6,12.8c0.4-1,0.8-2.1,1.2-3.1
								c-2.9,6.8-5.8,13.6-8.8,20.4c0.5-1,1-2,1.4-3c-2.3,2.8-4.7,5.6-7,8.4c-3,3.6-1.6,10.3,2.8,12.2c3.5,0.5,7,0.3,10.5-0.7
								C723.4,842.2,726.6,840.9,729.6,838.9z"/>
							<path opacity="0.1091" fill="#131313" d="M762.1,804.5c1.5-2.8,2.9-5.5,4.4-8.3c2.4-4.5,4.4-9.2,6-14.1
								c1.3-3.3,2.3-6.7,3.1-10.1c1.5-5.2,2.7-10.6,3.4-16c0.9-3,0-5.3-2.7-6.8c-4.7-1.4-9.3-0.9-13.9,1.4
								c-8.2,2.7-16.2,5.8-24.1,9.5c-4.9,1.4-8.6,4.3-11.1,8.5c-2.4,7.3-4.9,14.5-8.1,21.4c-3.1,6.6-6.8,12.8-10.3,19.1
								c-1.4,2.8-0.8,5.2,1.6,7.2c4.5,2.1,9.1,2.3,14,0.7c8.6-1.4,17-3.3,25.3-5.8C754.9,810.6,759,808.4,762.1,804.5z"/>
							<path opacity="0.1091" fill="#131313" d="M716.5,795.8c3.8-2.8,6.7-6.3,8.5-10.4c1.7-3.4,3.1-6.8,4.3-10.4
								c1.2-3.3,2.3-6.6,3.5-9.9c2.1-6.9,3.7-13.9,4.8-21.1c0-2.7-0.9-4.9-2.8-6.8c-2.4-1.9-5-2.2-7.9-0.9
								c-4.4,1.2-8.5,2.9-12.5,5.1c-3,1.1-4.7,3.2-5.1,6.2c-0.9,6.3-2.4,12.5-4.3,18.6c-2,5.7-4.1,11.4-6.3,17c0.5-1,1-2,1.5-3
								c-2.3,2.7-4.5,5.5-6.8,8.2c-3,3.6-1.6,10.3,2.8,12.2c3.6,0.5,7.1,0.3,10.5-0.7C710.2,799.1,713.5,797.8,716.5,795.8z"/>
							<path opacity="0.1091" fill="#131313" d="M737.8,790.7c3.7-7.2,7.4-14.4,10.1-22.1c1.3-3.3,2.4-6.7,3.2-10.1
								c1.6-5.2,2.8-10.5,3.6-15.9c0.4-2.8-0.5-5.1-2.7-6.8c-3.5-1.7-7-1.7-10.6,0.1c-6.1,1.9-12,4.2-17.7,7.1
								c-3.9,1.2-6.5,3.6-7.8,7.2c-0.7,3-1.4,6-2.1,8.9c-1.1,4.5-2.6,8.9-4.4,13.1c-2.7,6.8-6.1,13.1-9.6,19.6
								c-0.8,2.7-0.2,5.1,1.7,7.2c3,2.2,6.4,2.8,10.1,1.6c6.1-0.8,12-2.2,17.8-4.1C733.3,796,736.1,794,737.8,790.7z"/>
							<path opacity="0.1091" fill="#131313" d="M758.4,819.7c1.5-2.8,3-5.5,4.5-8.3c2.4-4.5,4.4-9.2,5.9-14
								c1.3-3.3,2.3-6.6,3.1-10.1c1.5-5.2,2.6-10.5,3.4-15.9c0.7-2.9-0.2-5.2-2.7-6.8c-4.1-1.6-8.2-1.3-12.3,0.8
								c-7.2,2.3-14.2,5-20.9,8.3c-4.4,1.3-7.6,3.9-9.5,7.9c-2.6,7.5-5.4,14.9-8.8,22.1c-3.2,6.7-7,13-10.7,19.4
								c-1.2,2.8-0.6,5.2,1.6,7.2c4,2.2,8.2,2.5,12.7,1c7.7-1.2,15.3-2.9,22.8-5.2C752.1,825.5,755.8,823.4,758.4,819.7z"/>
							<path opacity="0.1091" fill="#131313" d="M710.4,819.8c4.5-3.8,7.8-8.4,9.6-13.8c2.2-4.8,4.1-9.6,5.8-14.6
								c2.2-7,3.8-14,5.2-21.2c0.8-2.2,0.7-4.4-0.3-6.6c-0.7-2.3-2.1-4-4.1-5.2c-2.7-0.4-5.3,0-7.8,1.1c-2.6,0.8-4.9,2.2-6.7,4.3
								c-0.9,4.3-1.8,8.6-2.6,13c0.2-1,0.4-2,0.7-2.9c-1.5,6.6-3.6,13-6.3,19.2c-1.8,6.8-5.2,12.7-10.2,17.7c-1,2.1-1.1,4.3-0.5,6.5
								c0.4,2.3,1.5,4.2,3.2,5.7c2.4,0.8,4.9,0.8,7.3,0C706.3,822.6,708.5,821.5,710.4,819.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M808.3,828c4.6-5.6,9.3-11.2,14.1-16.7c1.3-2.6,1-5-0.7-7.3
								c-2.9-2.6-6.5-3.6-10.5-2.9c-6.6,0-13.1,0.6-19.6,1.8c-4.2,0-7.4,1.6-9.9,4.7c-4.7,5.2-9.2,10.5-13.6,16
								c-1.3,2.6-1.1,5,0.6,7.3c2.9,2.7,6.3,3.7,10.4,3.1c6.5,0.1,13-0.4,19.4-1.5C802.6,832.6,805.9,831.1,808.3,828z"/>
							<path opacity="0.1091" fill="#131313" d="M780.3,814.1c7.8-4,14-9.6,18.6-16.9c1.9-1.7,2.7-3.9,2.4-6.5
								c-0.1-2.6-1.3-4.6-3.6-6c-3.7-1-7.5-1.2-11.4-0.6c-3.9,0.2-7.6,1.1-11,2.7c-4.3,6.9-10,12.3-17.1,16.4
								c-3.8,2.9-2.9,10.1,1,12.5c3.5,1,7.1,1.3,10.7,0.8C773.5,816.4,777,815.6,780.3,814.1z"/>
							<path opacity="0.1091" fill="#131313" d="M784.8,779.9c6.7-4.6,12.3-10.2,16.9-16.8c2.1-1.7,2.9-3.9,2.6-6.5
								c-0.2-2.7-1.4-4.7-3.8-5.9c-4-0.9-8.1-1.1-12.2-0.5c-4.1,0.2-8.1,1.1-11.9,2.8c-4.4,6.3-9.7,11.8-15.9,16.3
								c-3.8,3.3-3.5,9.9,1,12.5c3.8,1,7.8,1.2,11.7,0.7C777.4,782.4,781.2,781.5,784.8,779.9z"/>
							<path opacity="0.1091" fill="#131313" d="M806.9,803.6c4.6-5.6,9.3-11.2,14.1-16.7c1.3-2.6,1.1-5-0.7-7.3
								c-3.1-2.6-6.7-3.6-10.8-2.8c-6.8,0.1-13.5,0.7-20.2,1.9c-4.2,0-7.6,1.6-10.1,4.8c-4.7,5.2-9.2,10.5-13.6,16
								c-1.3,2.6-1.1,5,0.6,7.3c3,2.7,6.5,3.7,10.7,3c6.7,0,13.3-0.5,19.9-1.5C801,808.3,804.3,806.7,806.9,803.6z"/>
							<path opacity="0.1091" fill="#131313" d="M808.1,774.7c4.8-5.6,9.5-11.2,14.3-16.7c1.3-2.6,1.1-5-0.7-7.3
								c-3.2-2.6-6.9-3.6-11.1-2.8c-6.9,0.1-13.8,0.8-20.7,2c-4.3,0-7.8,1.6-10.4,4.8c-4.7,5.2-9.2,10.6-13.7,16
								c-1.3,2.6-1.1,5,0.6,7.3c3.1,2.7,6.7,3.7,10.9,3c6.8,0,13.6-0.5,20.4-1.6C802.1,779.3,805.5,777.8,808.1,774.7z"/>
							<path opacity="0.1091" fill="#131313" d="M768.3,801.6c6.5-5,12.3-10.7,17.2-17.3c1.4-1.9,1.9-4,1.5-6.4
								c0-2.4-0.9-4.4-2.5-6.1c-2.5-1.1-5.1-1.4-7.8-1.1c-2.7,0-5.3,0.8-7.5,2.3c-4.7,6.3-10,12.1-16,17.2c-1.3,1.9-1.7,4.1-1.4,6.4
								c0,2.3,0.8,4.4,2.4,6.1c2.3,1.1,4.7,1.5,7.2,1.1C764,803.8,766.3,803,768.3,801.6z"/>
							<path opacity="0.1091" fill="#131313" d="M788.3,833.7c7-4.4,12.9-10,17.5-16.8c2-1.7,2.9-3.9,2.5-6.5
								c-0.2-2.7-1.4-4.6-3.7-6c-3.9-1-7.8-1.2-11.8-0.6c-4,0.2-7.9,1.1-11.5,2.7c-4.3,6.5-9.8,12-16.3,16.4
								c-3.8,3.2-3.3,9.9,0.9,12.5c3.7,1,7.5,1.3,11.3,0.8C781.1,836.1,784.8,835.3,788.3,833.7z"/>
							<path opacity="0.1091" fill="#131313" d="M822.5,799.9c4.6-5.7,9.4-11.3,14.2-16.7c1.7-2.6,1.5-5.1-0.7-7.3
								c-4.2-2.7-8.9-3.5-14-2.5c-8.8,0.3-17.5,1.1-26.2,2.4c-5.2,0-9.6,1.7-13.2,5.1c-4.8,5.1-9.2,10.3-13.5,15.9
								c-1.8,2.6-1.6,5,0.6,7.3c4.1,2.7,8.7,3.7,13.7,2.8c8.7-0.1,17.3-0.8,25.9-2C814.5,804.9,818.9,803.2,822.5,799.9z"/>
							<path opacity="0.1091" fill="#131313" d="M775.7,790.5c7.3-4.3,13.3-9.9,18.1-16.9c1.8-1.8,2.5-4,2.1-6.5
								c-0.1-2.6-1.2-4.6-3.3-6c-3.4-1-6.9-1.2-10.5-0.7c-3.6,0.1-7,1-10.1,2.6c-4.4,6.6-9.9,12.1-16.6,16.4
								c-3.5,2.9-2.7,10.1,1,12.5c3.2,1,6.4,1.3,9.8,0.9C769.6,792.8,772.8,792,775.7,790.5z"/>
							<path opacity="0.1091" fill="#131313" d="M798,785.8c4.6-5.6,9.2-11.2,14-16.6c1.1-2.6,0.8-5-0.7-7.3
								c-2.5-2.6-5.7-3.6-9.4-2.9c-5.9,0-11.7,0.6-17.5,1.7c-3.8,0.1-6.7,1.6-8.7,4.7c-4.6,5.2-9.1,10.6-13.5,16
								c-1.1,2.6-0.9,5,0.6,7.3c2.4,2.6,5.5,3.7,9.3,3.1c5.8,0.1,11.6-0.4,17.3-1.4C793.1,790.3,796,788.8,798,785.8z"/>
							<path opacity="0.1091" fill="#131313" d="M819.2,814.9c4.6-5.7,9.4-11.2,14.3-16.7c1.6-2.6,1.4-5.1-0.7-7.3
								c-3.9-2.7-8.2-3.6-13.1-2.6c-8.2,0.2-16.4,0.9-24.5,2.2c-4.9,0-9,1.6-12.4,5c-4.7,5.1-9.2,10.4-13.5,15.9
								c-1.6,2.6-1.4,5,0.6,7.3c3.8,2.7,8.1,3.7,12.9,2.9c8.1-0.1,16.2-0.7,24.2-1.8C811.8,819.8,815.9,818.2,819.2,814.9z"/>
							<path opacity="0.1091" fill="#131313" d="M770.5,814.5c6.5-5,12.2-10.7,17.2-17.3c1.4-1.9,1.9-4,1.6-6.4
								c0-2.4-0.9-4.4-2.6-6.1c-2.6-1.1-5.2-1.4-8-1c-2.8,0-5.4,0.8-7.8,2.3c-4.7,6.3-10,12.1-16,17.2c-1.3,1.9-1.8,4.1-1.5,6.4
								c0,2.4,0.8,4.4,2.4,6.1c2.3,1.1,4.8,1.5,7.4,1.1C765.9,816.7,768.3,815.9,770.5,814.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M887.1,865.2c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C877.8,862,882,864.4,887.1,865.2z"/>
							<path opacity="0.1091" fill="#131313" d="M862.7,846.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C856.4,845.6,859.3,846.7,862.7,846.2z"/>
							<path opacity="0.1091" fill="#131313" d="M862,812.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C857.2,810.1,859.4,811.5,862,812.3z"/>
							<path opacity="0.1091" fill="#131313" d="M883.9,838.9c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C876.7,837.5,880,839,883.9,838.9z"/>
							<path opacity="0.1091" fill="#131313" d="M879.1,808.1c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C873,804.6,875.9,806.7,879.1,808.1z"/>
							<path opacity="0.1091" fill="#131313" d="M845.1,834.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C839.9,831.9,842.3,833.5,845.1,834.5z"/>
							<path opacity="0.1091" fill="#131313" d="M870.1,867c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C862.2,865,865.8,866.8,870.1,867z"/>
							<path opacity="0.1091" fill="#131313" d="M898.1,835.6c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C890.1,833.6,893.7,835.4,898.1,835.6z"/>
							<path opacity="0.1091" fill="#131313" d="M853.3,822.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C848.1,820.3,850.5,821.9,853.3,822.9z"/>
							<path opacity="0.1091" fill="#131313" d="M869.6,819.4c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C863.1,815.5,866.1,817.8,869.6,819.4z"/>
							<path opacity="0.1091" fill="#131313" d="M896.7,851.7c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C887.6,848.7,891.7,851,896.7,851.7z"/>
							<path opacity="0.1091" fill="#131313" d="M845,845.4c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C838.7,841.7,841.6,843.8,845,845.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M889.2,813.6c2.3-11.7,3-23.5,2.2-35.4c0.4-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2c-3.6,1.9-6.8,4.4-9.5,7.5c-3,2.8-5.4,6.1-7.1,9.8c0.8,11.5,0.3,22.8-1.6,34.2
								c-0.4,2.6,0.3,4.8,2.3,6.6c1.8,1.9,4,2.7,6.6,2.3c3.6-1.7,6.7-4,9.3-7C885.2,820.3,887.5,817.2,889.2,813.6z"/>
							<path opacity="0.1091" fill="#131313" d="M866,797.5c3.3-12,4.4-24.1,3.2-36.4c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4.1-9,7c-2.8,2.7-5,5.8-6.6,9.3c1.1,11.8,0.3,23.4-2.4,34.9c-0.7,4.7,4.1,9.6,8.9,8.9
								c3.3-1.5,6.2-3.6,8.7-6.4C862.4,803.7,864.5,800.8,866,797.5z"/>
							<path opacity="0.1091" fill="#131313" d="M871.5,757.2c0-11.2,0-22.4,0.1-33.6c-0.7-2.9-2.4-4.6-5.3-5.1
								c-4.2,0.1-7.7,1.9-10.4,5.4c-5.3,4.8-10.2,9.9-14.8,15.5c-3.3,2.9-5,6.5-4.9,10.7c-0.1,10.8-0.2,21.6-0.2,32.3
								c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C869.8,764.8,871.5,761.3,871.5,757.2z"/>
							<path opacity="0.1091" fill="#131313" d="M889.3,784.3c-0.1-11.1-0.1-22.3,0-33.4c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.8c-5,4.4-9.5,9.1-13.7,14.3c-3.1,2.7-4.6,6.1-4.4,10.1c-0.1,10.8-0.2,21.5,0,32.3
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C888,791.5,889.4,788.2,889.3,784.3z"/>
							<path opacity="0.1091" fill="#131313" d="M892.6,752.8c-0.2-11.2-0.2-22.4-0.1-33.6c-0.3-3.2-2.1-4.9-5.3-5.1
								c-5.2,1-9.5,3.6-12.9,8c-6.9,6.4-13.5,13.2-19.6,20.4c-4.2,3.6-6.6,8-7.4,13.3c-0.4,10.7-0.4,21.4-0.1,32.1
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C889.2,762.3,891.7,758,892.6,752.8z"/>
							<path opacity="0.1091" fill="#131313" d="M853,783.7c2.6-11.8,3.5-23.8,2.6-35.9c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.8-1.9-3.9-2.6-6.4-2.3c-3.3,1.5-6.1,3.7-8.4,6.4c-2.6,2.5-4.6,5.4-6,8.7c0.9,11.6,0.3,23.1-1.8,34.5
								c-0.5,4.5,4.3,9.4,8.9,8.8c3.1-1.3,5.8-3.2,8.1-5.8C849.8,789.6,851.7,786.8,853,783.7z"/>
							<path opacity="0.1091" fill="#131313" d="M875,814.8c1.5-11.9,2.1-23.8,1.7-35.8c0-4.2-4.4-9.3-8.8-8.9
								c-2.8,1-5.2,2.6-7.2,4.9c-2.3,2-3.9,4.4-4.9,7.2c0.5,11.7,0.2,23.4-0.9,35.1c0.1,2.4,1.1,4.3,2.9,5.9
								c1.6,1.8,3.6,2.8,5.9,2.9c2.7-0.9,5-2.4,6.8-4.5C872.6,819.8,874.1,817.5,875,814.8z"/>
							<path opacity="0.1091" fill="#131313" d="M904,779.9c-0.1-11.2-0.1-22.4,0-33.5c-0.5-3.1-2.2-4.8-5.3-5.1
								c-4.7,0.6-8.6,2.8-11.7,6.7c-6.2,5.6-11.9,11.6-17.3,18c-3.8,3.2-5.8,7.2-6.2,12c-0.3,10.7-0.3,21.4-0.1,32.2
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C901.5,788.5,903.6,784.6,904,779.9z"/>
							<path opacity="0.1091" fill="#131313" d="M862.3,768.1c-0.1-11.1-0.1-22.2-0.1-33.3c-0.9-2.6-2.7-4.3-5.3-5.1
								c-3.5-0.4-6.5,0.9-8.9,3.7c-4.3,3.7-8.2,7.8-11.8,12.3c-2.8,2.5-3.9,5.5-3.3,9c0,10.8,0,21.6,0,32.4c0.8,2.6,2.6,4.4,5.2,5.2
								c3.5,0.5,6.5-0.7,8.9-3.5c4.4-3.6,8.3-7.6,11.9-11.9C861.7,774.6,862.8,771.6,862.3,768.1z"/>
							<path opacity="0.1091" fill="#131313" d="M880.5,766.3c0-11.2,0-22.4,0.1-33.5c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.3,0.2-7.9,2.1-10.8,5.7c-5.5,5-10.7,10.3-15.4,16.1c-3.4,3-5.2,6.7-5.2,11c-0.2,10.8-0.2,21.5-0.1,32.3
								c0.6,2.9,2.3,4.6,5.2,5.2c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C878.7,774.2,880.4,770.6,880.5,766.3z"/>
							<path opacity="0.1091" fill="#131313" d="M900.6,795.6c-0.1-11.1-0.1-22.3,0-33.4c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.8c-5,4.4-9.6,9.1-13.7,14.3c-3.1,2.7-4.6,6.1-4.4,10.1c-0.2,10.8-0.2,21.5,0,32.3
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C899.3,802.8,900.8,799.5,900.6,795.6z"/>
							<path opacity="0.1091" fill="#131313" d="M855.9,795.7c1.6-11.9,2.1-23.9,1.7-35.9c0-4.2-4.4-9.3-8.9-8.8
								c-2.8,1-5.3,2.7-7.3,5c-2.3,2-3.9,4.5-5,7.3c0.5,11.7,0.2,23.4-1,35.1c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9
								c2.7-0.9,5-2.4,6.9-4.6C853.4,800.7,855,798.4,855.9,795.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M886.3,878.7c1.4-3.8,2.5-7.6,3.3-11.6c0.3-4.1,0.4-8.1,0.4-12.2
								c0.2-4.5-0.1-9-0.7-13.5c-0.8-6.3-2.1-12.5-3.8-18.5c-1-2.5-2.7-4.2-5.2-5.2c-3-0.8-5.7,0.1-7.9,2.5
								c-3.8,2.9-7.1,6.3-10.1,10c-2.4,2.2-3.3,4.9-2.6,7.9c0.3,2.8,0.7,5.6,1,8.3c0.4,4.1,0.4,8.3,0.2,12.4
								c-0.2,6.4-1.1,12.7-1.8,19c0.3-1.2,0.6-2.4,0.8-3.6c-0.8,2.4-1.5,4.8-2.3,7.1c-1.3,2.4-1.2,4.8,0.3,7.1
								c1.4,2.4,3.4,3.6,6.2,3.6c4.3-1.1,8.3-2.8,12-5.3C879.9,884.7,883.4,881.9,886.3,878.7z"/>
							<path opacity="0.1091" fill="#131313" d="M863.4,862c3-3.8,4.6-8,4.9-12.6c0-3.9,0-7.9,0-11.8c0.1-4.5-0.2-9-0.9-13.4
								c-0.9-6.2-2.2-12.4-4.1-18.4c-1-2.4-2.8-4.2-5.2-5.2c-2.9-0.9-5.4-0.2-7.5,2.1c-3.5,2.7-6.6,5.8-9.3,9.2
								c-2.3,2.1-3,4.6-2.1,7.5c0.6,2.6,1.2,5.2,1.8,7.8c0.8,3.9,1.3,7.9,1.4,11.8c0.3,6,0,12,0,18c0.1-1.1,0.3-2.2,0.4-3.3
								c-1.3,2.8-2.6,5.6-3.8,8.4c-2,4.3,1.9,10.3,6.5,10.7c3.5-0.6,6.8-1.9,9.8-4C858.5,867.1,861.2,864.9,863.4,862z"/>
							<path opacity="0.1091" fill="#131313" d="M868.8,823.5c1.2-8,2.3-16,1.8-24.2c-0.3-3-0.5-6.1-0.8-9.1
								c-0.8-6.3-2.1-12.5-3.9-18.6c-0.6-2.8-2.4-4.6-5.2-5.2c-4.1-0.1-7.5,1.5-10.3,4.8c-5.3,4.4-10.2,9.3-14.6,14.5
								c-3.3,2.7-4.9,6.1-4.9,10.2c2,6.7,3.8,13.5,4,20.6c0.2,6.8-0.6,13.3-1.7,20c0.1,2.8,1.4,4.9,3.8,6.3
								c3.5,1.2,6.9,0.6,10.1-1.7c5.5-2.7,10.7-5.9,15.6-9.5C866.1,829.9,868.2,827.2,868.8,823.5z"/>
							<path opacity="0.1091" fill="#131313" d="M886.3,850.5c1.4-8.1,2.5-16,1.9-24.2c-0.3-3-0.6-6-0.8-9c-0.8-6.2-2.2-12.3-4-18.4
								c-0.7-2.8-2.4-4.5-5.2-5.2c-3.9-0.2-7.2,1.3-9.9,4.4c-5,4.2-9.7,8.8-13.9,13.7c-3.2,2.6-4.7,5.9-4.6,9.9
								c1.1,7.5,2,14.9,1.5,22.5c-0.4,7-1.7,13.8-3.1,20.6c0,2.9,1.3,5,3.8,6.3c3.8,1.1,7.3,0.4,10.7-2c5.9-2.9,11.5-6.3,16.8-10.1
								C883.3,857.2,885.5,854.3,886.3,850.5z"/>
							<path opacity="0.1091" fill="#131313" d="M888.9,820.4c1.4-8.3,2.6-16.4,2.2-24.8c-0.3-3.1-0.5-6.2-0.8-9.2
								c-0.8-6.4-2.1-12.7-3.9-18.9c-0.2-3.2-2-5-5.1-5.3c-5.1,0.7-9.4,3.1-12.9,7.2c-6.9,6-13.5,12.4-19.6,19.2
								c-4.2,3.4-6.7,7.6-7.5,12.7c1.3,6.6,2.4,13.2,2.2,20c-0.1,2.3-0.3,4.6-0.4,6.9c-0.8,4.2-1.5,8.4-2.2,12.7
								c-0.4,3.1,0.9,5.2,3.8,6.3c4.8,0.6,9.2-0.5,13.2-3.6c7.5-3.9,14.7-8.3,21.7-13.1C884.2,828.4,887.3,825,888.9,820.4z"/>
							<path opacity="0.1091" fill="#131313" d="M850.4,848.8c3-3.8,4.4-8,4.2-12.5c0.3-4.2,0.4-8.4,0.3-12.6
								c0.1-4.3-0.2-8.7-0.9-12.9c-0.9-6.1-2.3-12.2-4.3-18.1c-1.1-2.4-2.8-4.1-5.2-5.2c-2.6-1-5-0.4-7.1,1.7
								c-3.2,2.4-6,5.2-8.4,8.4c-2.1,2.1-2.6,4.4-1.7,7.1c0.8,2.5,1.6,5,2.4,7.4c1,3.5,1.7,7.1,2,10.7c0.8,6.1,0.7,12.3,0.8,18.5
								c0-1,0.1-2.1,0.1-3.1c-1.2,3-2.4,5.9-3.5,8.9c-0.5,2.3-0.1,4.5,1.3,6.5c1.1,2.2,2.9,3.6,5.2,4.2c3-0.3,5.7-1.3,8.2-3
								C846.6,853.3,848.8,851.3,850.4,848.8z"/>
							<path opacity="0.1091" fill="#131313" d="M871.7,880.7c2.8-3.8,4.2-8,4.1-12.5c0.2-4.2,0.3-8.4,0.1-12.6
								c0-4.5-0.3-9.1-1.1-13.6c-1-6.3-2.4-12.5-4.4-18.5c-1.1-2.3-2.8-4.1-5-5.4c-2.3-1.2-4.4-0.9-6.5,0.8
								c-2.9,1.9-5.3,4.2-7.5,6.9c-1.9,1.9-2.3,4-1.3,6.4c1.2,4.2,2,8.4,2.5,12.7c0.7,4.2,1.1,8.4,1,12.7c0-1,0-2.1,0-3.1
								c-0.2,6.3-0.2,12.6-0.2,18.9c0.1-1,0.1-2.1,0.2-3.1c-1.2,3-2.4,6-3.6,8.9c-1.6,3.9,2.4,10.1,6.5,10.7c3-0.3,5.8-1.4,8.4-3.1
								C867.8,885.2,870,883.2,871.7,880.7z"/>
							<path opacity="0.1091" fill="#131313" d="M901.4,846.2c1.3-8.2,2.4-16.1,1.8-24.4c-0.3-3-0.6-6-1-9
								c-0.9-6.2-2.3-12.3-4.2-18.3c-0.4-3.1-2.1-4.8-5.1-5.3c-4.7,0.4-8.7,2.5-11.9,6.2c-6.3,5.4-12.2,11.1-17.7,17.3
								c-3.8,3.1-6,7-6.5,11.7c0.6,7.3,1,14.6,0.2,21.9c-0.7,6.8-2.3,13.4-3.5,20.2c-0.4,3.1,0.9,5.2,3.8,6.3
								c4.8,0.7,9.2-0.5,13.2-3.5c7.5-3.9,14.7-8.2,21.6-13C896.7,854.1,899.8,850.7,901.4,846.2z"/>
							<path opacity="0.1091" fill="#131313" d="M857.2,838.7c2.2-3.8,3.6-7.9,4.3-12.2c0.2-4,0.2-8,0.2-12.1
								c0.1-4.5-0.2-8.9-0.9-13.4c-0.8-6.2-2.2-12.4-4-18.4c-0.9-2.6-2.6-4.4-5.2-5.2c-3.4-0.5-6.3,0.6-8.7,3.3
								c-4.3,3.5-8.2,7.3-11.7,11.6c-2.7,2.4-3.9,5.3-3.4,8.7c0.7,2.3,1.4,4.7,2.2,7c1,3.6,1.6,7.2,1.9,10.9
								c0.6,5.8,0.5,11.6,0.4,17.5c0.2-1.2,0.5-2.3,0.7-3.5c-1,2.5-2,5.1-3,7.6c-1.1,2.4-1,4.8,0.5,7c1.3,2.3,3.3,3.6,6,3.7
								c4.1-0.9,7.8-2.6,11.3-4.9C851.3,844.5,854.5,841.9,857.2,838.7z"/>
							<path opacity="0.1091" fill="#131313" d="M877.3,832.8c1-3.8,1.6-7.7,1.8-11.6c0.5-4.3,0.7-8.5,0.4-12.8
								c-0.3-3-0.5-6-0.8-9.1c-0.8-6.3-2.1-12.4-3.9-18.5c-0.6-2.9-2.3-4.6-5.2-5.2c-4.3,0-7.8,1.8-10.7,5.2
								c-5.5,4.7-10.7,9.7-15.4,15.2c-3.4,2.8-5.2,6.4-5.3,10.6c1.5,6.9,2.9,13.9,2.8,21c-0.1,6.9-1.2,13.4-2.6,20.2
								c0,2.9,1.2,5,3.8,6.3c3.9,1.1,7.5,0.3,10.9-2.1c6-3,11.8-6.5,17.2-10.4C874.1,839.6,876.4,836.7,877.3,832.8z"/>
							<path opacity="0.1091" fill="#131313" d="M897.7,861.9c0.9-3.8,1.5-7.6,1.7-11.5c0.5-4.2,0.6-8.5,0.3-12.7
								c-0.3-3-0.6-6-0.9-9c-0.9-6.2-2.2-12.3-4-18.3c-0.7-2.8-2.4-4.5-5.2-5.3c-4-0.2-7.3,1.3-10,4.5c-5.1,4.2-9.8,8.9-14.1,13.9
								c-3.2,2.7-4.8,6-4.7,9.9c0.6,7.8,1,15.6,0.2,23.4c-0.8,7.1-2.3,13.9-3.8,20.9c-0.1,2.9,1.2,5,3.8,6.3c4,1,7.8,0.2,11.3-2.4
								c6.3-3.1,12.3-6.8,18-10.8C894.2,868.9,896.7,865.9,897.7,861.9z"/>
							<path opacity="0.1091" fill="#131313" d="M851.5,862.3c3.8-5.4,5.3-11.3,4.6-17.7c0.2-4,0.1-8-0.1-12
								c-0.2-3.1-0.5-6.2-0.8-9.2c-0.8-6.4-2.2-12.6-4-18.8c-1.1-2.3-2.8-4.1-5-5.4c-2.3-1.2-4.4-1-6.4,0.7
								c-2.8,1.9-5.3,4.1-7.3,6.8c-1.8,1.9-2.2,4-1.2,6.3c1.4,4,2.4,8.1,3,12.2c0.9,4.1,1.4,8.2,1.5,12.4c0-1-0.1-2-0.1-3
								c0.1,3.7,0.2,7.5,0.2,11.2c0-1,0-2-0.1-3c0.1,3.5,0.1,7,0.2,10.5c0-1,0-2,0-3c-1.2,3-2.4,6-3.6,8.9c-0.3,2.3,0.2,4.4,1.6,6.4
								c1.1,2.1,2.7,3.5,4.9,4.4c2.6-0.1,5-0.9,7.1-2.4C848.4,866.3,850.2,864.5,851.5,862.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M853.2,887.7c-4.9-6.5-10.3-12.5-16.3-18c-5.4-5.2-10.8-10.5-16.2-15.7
								c-3-2.9-9.8-3.2-12.5,0.3c-1.2,2.7-1.7,5.6-1.5,8.7c-0.1,3,0.5,5.9,1.9,8.6c5.3,5.3,10.7,10.4,16.2,15.5
								c5.9,5.2,11.3,10.9,16.3,17c1.8,1.5,3.9,2.2,6.3,2c2.4,0.1,4.5-0.7,6.2-2.3c1.2-2.5,1.7-5.2,1.5-8.1
								C855.2,892.9,854.6,890.2,853.2,887.7z"/>
							<path opacity="0.1091" fill="#131313" d="M830.8,871.9c-4.8-6-10-11.6-15.8-16.7c-5.4-5.1-10.8-10.2-16.2-15.4
								c-1.5-2.2-3.7-3.3-6.4-3.1c-2.7,0.1-4.8,1.3-6.1,3.7c-1.1,4.2-1.5,8.5-1.1,12.9c0,4.4,0.7,8.6,2.2,12.7
								c5.3,5.1,10.7,10.2,16.1,15.3c5.5,4.9,10.6,10.2,15.3,15.9c1.5,2.2,3.6,3.3,6.3,3.1c2.7,0,4.8-1.2,6.2-3.5
								c1.2-4.1,1.7-8.2,1.4-12.5C832.9,880,832.2,875.9,830.8,871.9z"/>
							<path opacity="0.1091" fill="#131313" d="M831,830.9c-10.8-10.1-21.4-20.3-32.1-30.5c-2.5-2-5-1.9-7.4,0.3
								c-2.9,4.3-4,9.2-3.2,14.5c-0.2,9.3,0.2,18.5,1.2,27.7c-0.3,5.4,1.2,10.1,4.4,14.2c10.4,9.9,20.5,19.9,31.1,29.6
								c2.5,2,5,1.9,7.4-0.2c3-4.2,4.1-9,3.5-14.3c0.3-9.1,0.1-18.2-0.7-27.3C835.5,839.6,834.1,834.9,831,830.9z"/>
							<path opacity="0.1091" fill="#131313" d="M849.3,860.4c-10.8-9.9-21.4-20-31.9-30.1c-2.5-1.2-5-1.1-7.4,0.3
								c-2.7,2.3-3.9,5.4-3.5,9.1c-0.3,5.8-0.1,11.5,0.7,17.3c-0.1,3.8,1.3,6.7,4.2,8.8c10.4,9.9,20.8,19.9,31.3,29.7
								c2.5,1.2,4.9,1.2,7.4-0.2c2.8-2.3,4-5.2,3.6-9c0.4-5.7,0.3-11.4-0.4-17.1C853.5,865.5,852.2,862.6,849.3,860.4z"/>
							<path opacity="0.1091" fill="#131313" d="M850,828c-10.8-10.1-21.4-20.3-32.1-30.5c-2.5-2.1-5-2-7.4,0.3
								c-2.9,4.5-4,9.5-3.2,15.1c-0.2,9.6,0.2,19.2,1.2,28.7c-0.3,5.6,1.2,10.5,4.4,14.7c5.4,5.1,10.8,10.2,16,15.4
								c4.8,4.9,9.9,9.6,15.1,14.2c2.5,2.1,5,2,7.4-0.2c3-4.4,4.1-9.3,3.5-14.8c0.3-9.4,0.1-18.9-0.6-28.3
								C854.6,837.1,853.2,832.3,850,828z"/>
							<path opacity="0.1091" fill="#131313" d="M813.6,855c-10.8-9.9-21.4-20-32-30.1c-2.5-1.3-5-1.2-7.4,0.3
								c-2.8,2.6-3.9,5.8-3.4,9.8c-0.3,6.2,0,12.3,0.8,18.5c-0.1,4,1.3,7.1,4.2,9.4c10.4,9.9,20.7,19.9,31.3,29.6
								c2.5,1.3,4.9,1.2,7.4-0.2c2.8-2.5,4-5.7,3.6-9.6c0.4-6.1,0.2-12.2-0.5-18.3C817.9,860.4,816.5,857.3,813.6,855z"/>
							<path opacity="0.1091" fill="#131313" d="M837.3,890.9c-4.8-6.5-10.3-12.5-16.3-18c-5.4-5.2-10.8-10.5-16.2-15.8
								c-1.8-1.5-3.9-2.1-6.3-1.9c-2.4-0.1-4.4,0.6-6.2,2.2c-1.2,2.4-1.7,4.9-1.5,7.6c-0.1,2.7,0.5,5.2,1.8,7.5
								c5.3,5.3,10.7,10.4,16.2,15.5c5.8,5.2,11.3,10.8,16.2,16.9c1.9,1.3,4,1.9,6.3,1.7c2.3,0.1,4.4-0.6,6.2-2
								c1.2-2.2,1.7-4.5,1.5-7C839.3,895.3,838.7,893,837.3,890.9z"/>
							<path opacity="0.1091" fill="#131313" d="M863.2,856.9c-10.8-9.9-21.4-20-32-30.1c-2.5-1.3-5-1.2-7.4,0.3
								c-2.8,2.5-3.9,5.8-3.4,9.7c-0.3,6.2-0.1,12.3,0.7,18.4c-0.2,3.9,1.2,7.1,4.2,9.4c10.4,9.9,20.7,19.9,31.3,29.6
								c2.5,1.3,4.9,1.3,7.4-0.2c2.8-2.5,4-5.6,3.6-9.5c0.4-6.1,0.3-12.2-0.4-18.2C867.5,862.4,866.1,859.3,863.2,856.9z"/>
							<path opacity="0.1091" fill="#131313" d="M822.2,844c-10.7-10.1-21.3-20.4-32-30.6c-2.5-1.7-5-1.6-7.4,0.3
								c-2.8,3.6-3.9,7.7-3.3,12.4c-0.2,7.9,0.1,15.8,1,23.6c-0.2,4.8,1.2,8.8,4.3,12.1c10.4,9.9,20.7,20,31.1,29.8
								c2.5,1.7,5,1.6,7.4-0.2c2.9-3.5,4.1-7.5,3.5-12.2c0.4-7.8,0.2-15.5-0.6-23.3C826.7,851.3,825.3,847.3,822.2,844z"/>
							<path opacity="0.1091" fill="#131313" d="M840.4,842.1c-10.7-10.1-21.4-20.4-32-30.6c-2.5-1.7-5-1.6-7.4,0.3
								c-2.9,3.7-4,7.9-3.3,12.7c-0.2,8.1,0.1,16.2,1,24.2c-0.3,4.9,1.2,9,4.3,12.4c10.4,9.9,20.6,20,31.1,29.7
								c2.5,1.7,5,1.7,7.4-0.2c2.9-3.6,4.1-7.7,3.5-12.5c0.4-8,0.2-15.9-0.6-23.9C844.8,849.6,843.5,845.5,840.4,842.1z"/>
							<path opacity="0.1091" fill="#131313" d="M863.4,877.1c-4.2-7.3-9.4-13.6-15.8-18.9c-5.5-5-10.9-10.1-16.2-15.2
								c-1.6-2.1-3.7-3-6.4-2.8c-2.7,0-4.7,1.1-6.1,3.3c-1.2,3.8-1.6,7.7-1.2,11.7c0,4,0.7,7.9,2.1,11.6
								c5.3,5.2,10.6,10.2,16.1,15.1c6.1,5,11.2,10.9,15.4,17.6c3,3.8,9.7,3.9,12.5-0.3c1.2-3.6,1.7-7.3,1.4-11.1
								C865.4,884.4,864.7,880.7,863.4,877.1z"/>
							<path opacity="0.1091" fill="#131313" d="M818.3,872.5c-4.5-6.6-9.8-12.6-15.8-17.8c-5.4-5-10.8-10.1-16.2-15.3
								c-1.6-2.2-3.7-3.1-6.4-2.9c-2.7,0-4.7,1.2-6.1,3.5c-1.1,4-1.5,8.1-1.1,12.2c0,4.2,0.7,8.2,2.2,12.1
								c5.3,5.1,10.7,10.2,16.1,15.2c5.8,5,10.9,10.6,15.4,16.8c1.6,2.1,3.7,3.1,6.3,2.9c2.7,0,4.7-1.1,6.2-3.3
								c1.2-3.8,1.7-7.7,1.4-11.8C820.3,880.2,819.7,876.3,818.3,872.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M894.1,920.9c2.2-8.5,2.9-17.1,2.1-25.8c0.5-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2.1c-3.6,1.9-6.8,4.3-9.6,7.4c-3,2.8-5.4,6.1-7.2,9.7c0.8,8.3,0.3,16.6-1.5,24.8
								c-0.7,4.9,3.7,9.8,8.8,8.9c3.6-1.7,6.7-4,9.3-7C890.1,927.6,892.4,924.5,894.1,920.9z"/>
							<path opacity="0.1091" fill="#131313" d="M870.9,904.8c3.2-8.7,4.3-17.7,3.1-26.8c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4-9,6.9c-2.8,2.7-5.1,5.7-6.7,9.3c1.1,8.7,0.4,17.2-2.3,25.5c-0.9,4.7,4.3,9.6,8.9,8.9
								c3.3-1.5,6.2-3.6,8.7-6.4C867.3,911,869.4,908.1,870.9,904.8z"/>
							<path opacity="0.1091" fill="#131313" d="M876.4,864.5c0-8,0-16,0-23.9c-0.7-2.9-2.4-4.6-5.3-5.1c-4.2,0.1-7.7,1.9-10.5,5.3
								c-5.3,4.7-10.3,9.8-14.8,15.3c-3.3,2.9-5,6.4-5,10.6c-0.1,7.7-0.1,15.3,0,23c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5
								c5.3-4.6,10.3-9.6,14.9-14.9C874.7,872.1,876.4,868.7,876.4,864.5z"/>
							<path opacity="0.1091" fill="#131313" d="M894.2,891.6c-0.2-7.9-0.2-15.8-0.1-23.8c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.1,7.6-0.1,15.3,0.1,22.9
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C892.8,898.8,894.3,895.5,894.2,891.6z"/>
							<path opacity="0.1091" fill="#131313" d="M897.5,860.1c-0.2-8-0.3-15.9-0.1-23.9c-0.3-3.2-2-4.9-5.3-5.1
								c-5.2,1-9.5,3.6-13,7.9c-7,6.4-13.5,13.1-19.7,20.3c-4.2,3.6-6.7,8-7.5,13.2c-0.3,7.6-0.3,15.1,0.1,22.7c0.3,3.2,2,5,5.2,5.2
								c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C894.1,869.6,896.6,865.3,897.5,860.1z"/>
							<path opacity="0.1091" fill="#131313" d="M857.9,891c2.6-8.6,3.4-17.4,2.5-26.3c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.8-1.9-3.9-2.7-6.4-2.4c-3.3,1.5-6.1,3.6-8.4,6.3c-2.6,2.5-4.7,5.3-6,8.6c0.9,8.4,0.3,16.8-1.8,25.1
								c-0.7,4.5,4.5,9.4,8.9,8.8c3.1-1.3,5.8-3.2,8.1-5.8C854.7,896.9,856.6,894.2,857.9,891z"/>
							<path opacity="0.1091" fill="#131313" d="M879.8,922.1c1.5-8.6,2.1-17.3,1.7-26.1c0-4.2-4.4-9.3-8.8-8.9
								c-2.8,1-5.2,2.6-7.2,4.9c-2.3,2-3.9,4.4-4.9,7.2c0.5,8.5,0.1,16.9-0.9,25.3c0.1,2.4,1.1,4.3,2.9,5.9c1.6,1.8,3.6,2.8,5.9,2.9
								c2.7-0.9,5-2.4,6.8-4.5C877.5,927.1,879,924.8,879.8,922.1z"/>
							<path opacity="0.1091" fill="#131313" d="M908.9,887.2c-0.2-8-0.2-15.9-0.1-23.9c-0.5-3.1-2.2-4.8-5.3-5.1
								c-4.7,0.5-8.6,2.7-11.7,6.6c-6.2,5.6-12,11.5-17.3,17.8c-3.8,3.2-5.9,7.2-6.3,11.9c-0.3,7.6-0.2,15.2,0.1,22.8
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C906.3,895.8,908.4,891.9,908.9,887.2z"/>
							<path opacity="0.1091" fill="#131313" d="M867.2,875.4c-0.2-7.9-0.2-15.8-0.2-23.7c-0.9-2.6-2.6-4.3-5.3-5.1
								c-3.5-0.4-6.5,0.8-8.9,3.7c-4.3,3.7-8.3,7.8-11.8,12.2c-2.8,2.5-3.9,5.5-3.4,9c0,7.7,0,15.3,0.1,23c0.8,2.6,2.6,4.4,5.2,5.2
								c3.5,0.5,6.5-0.7,8.9-3.5c4.4-3.6,8.3-7.6,11.9-11.9C866.6,881.9,867.7,878.9,867.2,875.4z"/>
							<path opacity="0.1091" fill="#131313" d="M885.4,873.7c-0.1-8-0.1-15.9,0-23.9c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.3,0.2-7.9,2.1-10.8,5.6c-5.6,4.9-10.7,10.3-15.5,16c-3.4,3-5.2,6.6-5.3,11c-0.2,7.6-0.2,15.3,0,22.9
								c0.6,2.9,2.3,4.6,5.2,5.2c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C883.5,881.5,885.3,877.9,885.4,873.7z"/>
							<path opacity="0.1091" fill="#131313" d="M905.5,902.9c-0.2-7.9-0.2-15.8-0.1-23.8c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.1,7.6-0.1,15.3,0.1,22.9
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C904.2,910.2,905.7,906.9,905.5,902.9z"/>
							<path opacity="0.1091" fill="#131313" d="M860.7,903c1.5-8.6,2.1-17.3,1.7-26.1c0-4.2-4.4-9.3-8.9-8.8c-2.8,1-5.3,2.7-7.3,5
								c-2.3,2-3.9,4.5-5,7.3c0.5,8.5,0.1,16.9-1,25.3c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9c2.7-0.9,5-2.4,6.9-4.6
								C858.3,908,859.9,905.7,860.7,903z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M881.9,941.4c-3.1-5.6-6.2-11.3-9.3-16.9c-2-3.7-8.9-5.2-12.3-2.5
								c-1.8,2.5-2.9,5.3-3.4,8.4c-0.8,3-0.8,6-0.1,9c3.1,5.4,6.3,10.8,9.4,16.2c2.1,3.6,9,5.1,12.3,2.5c1.8-2.4,2.9-5,3.3-8
								C882.6,947.2,882.6,944.3,881.9,941.4z"/>
							<path opacity="0.1091" fill="#131313" d="M859,926.9c-2.9-5.8-5.8-11.6-8.7-17.4c-1.1-2.4-3-3.8-5.6-4.2
								c-2.6-0.6-4.9,0.1-6.7,2c-2,3.6-3.2,7.4-3.8,11.4c-0.9,4-1.1,8-0.5,12c2.8,5.4,5.7,10.9,8.5,16.3c1.1,2.4,2.9,3.8,5.5,4.2
								c2.6,0.6,4.8,0,6.7-1.8c2-3.4,3.3-7,3.9-10.9C859.4,934.6,859.6,930.7,859,926.9z"/>
							<path opacity="0.1091" fill="#131313" d="M860.8,885.3c-3.2-4.7-6.4-9.4-9.6-14.2c-2.1-2.4-4.5-2.8-7.2-1.3
								c-3.7,3.3-5.8,7.5-6.2,12.6c-2.1,8.4-3.7,17-4.7,25.6c-1.4,4.9-1,9.6,1.3,14c3,4.4,6,8.8,9,13.2c2,2.4,4.4,2.9,7.2,1.4
								c3.7-3.2,5.8-7.3,6.4-12.3c2.2-8.3,3.9-16.7,5-25.2C863.4,894.3,863,889.7,860.8,885.3z"/>
							<path opacity="0.1091" fill="#131313" d="M878.6,914.1c-3.2-4.6-6.3-9.2-9.5-13.8c-2.2-1.7-4.6-2.2-7.2-1.3
								c-3.2,1.7-5,4.5-5.4,8.3c-1.6,5.7-2.7,11.5-3.2,17.4c-1,3.7-0.3,6.9,2.1,9.7c3,4.4,6,8.8,9,13.3c2.1,1.8,4.6,2.3,7.2,1.5
								c3.2-1.7,5.1-4.4,5.5-8.1c1.7-5.6,2.8-11.3,3.4-17.1C881.6,920,880.9,916.8,878.6,914.1z"/>
							<path opacity="0.1091" fill="#131313" d="M880.2,881.4c-3.2-4.7-6.4-9.4-9.6-14.1c-2-2.6-4.4-3-7.2-1.3
								c-3.9,3.8-6,8.6-6.5,14.2c-2.3,9.4-4.1,19-5.3,28.6c-1.5,5.4-1.2,10.6,1,15.6c3,4.4,6,8.7,9,13.1c2,2.6,4.4,3.1,7.2,1.4
								c3.9-3.7,6.1-8.3,6.7-13.9c2.4-9.3,4.3-18.7,5.6-28.2C882.6,891.4,882.3,886.3,880.2,881.4z"/>
							<path opacity="0.1091" fill="#131313" d="M843,909c-2.4-3.8-4.9-7.6-7.3-11.3c-0.9-2.6-2.8-4.2-5.5-4.7
								c-2.8-0.5-5,0.3-6.8,2.5c-2.1,4.2-3.4,8.6-4.1,13.2c-1,4.6-1.3,9.2-0.8,13.8c3.7,5.6,7.3,11.3,11,16.9
								c2.2,1.6,4.6,2.1,7.2,1.4c3.1-1.4,4.9-3.8,5.4-7.4c1.6-5.1,2.6-10.3,3.1-15.6C846.1,914.4,845.4,911.4,843,909z"/>
							<path opacity="0.1091" fill="#131313" d="M866.2,943.3c-3.1-5.4-6.3-10.8-9.4-16.2c-1.5-1.8-3.4-2.8-5.8-3.1
								c-2.3-0.6-4.4-0.4-6.5,0.6c-1.7,1.9-2.7,4.2-3,6.7c-0.7,2.5-0.6,5,0.2,7.4c3.2,5.1,6.4,10.3,9.6,15.4c1.5,1.7,3.5,2.7,5.8,3
								c2.2,0.6,4.4,0.4,6.5-0.5c1.7-1.8,2.6-3.9,3-6.4C867.1,948,867,945.6,866.2,943.3z"/>
							<path opacity="0.1091" fill="#131313" d="M892.5,910.7c-3.2-4.7-6.3-9.4-9.5-14.1c-2.1-1.9-4.6-2.4-7.2-1.3
								c-3.4,2.1-5.2,5.3-5.6,9.5c-1.8,6.4-3,12.9-3.6,19.6c-1.1,4-0.5,7.6,1.9,10.9c3,4.4,6,8.9,9,13.3c2.1,1.9,4.5,2.4,7.2,1.5
								c3.4-2.1,5.3-5.1,5.8-9.2c1.8-6.3,3.1-12.7,3.9-19.3C895.4,917.5,894.8,913.9,892.5,910.7z"/>
							<path opacity="0.1091" fill="#131313" d="M851.5,898.1c-3.2-4.7-6.3-9.5-9.5-14.2c-2.1-2-4.5-2.5-7.2-1.3
								c-3.4,2.5-5.4,5.9-5.8,10.3c-1.8,6.9-3.1,14-3.9,21.1c-1.2,4.3-0.6,8.2,1.8,11.7c3,4.5,6,9,8.9,13.4c2.1,2,4.5,2.5,7.2,1.4
								c3.5-2.3,5.4-5.7,5.9-10.1c1.9-6.8,3.3-13.8,4.1-20.8C854.4,905.5,853.9,901.6,851.5,898.1z"/>
							<path opacity="0.1091" fill="#131313" d="M869.8,895.8c-3.2-4.7-6.3-9.5-9.5-14.2c-2.1-2.2-4.5-2.6-7.2-1.3
								c-3.6,2.9-5.6,6.7-6,11.4c-2,7.7-3.4,15.5-4.3,23.3c-1.3,4.6-0.8,8.9,1.5,12.8c3,4.4,6,8.9,8.9,13.3c2.1,2.2,4.5,2.7,7.2,1.4
								c3.6-2.7,5.6-6.5,6.1-11.2c2.1-7.5,3.6-15.2,4.6-23C872.5,904,872.1,899.7,869.8,895.8z"/>
							<path opacity="0.1091" fill="#131313" d="M891.7,930.1c-3-5.4-6-10.8-8.9-16.2c-1-2.5-2.9-4-5.6-4.4
								c-2.7-0.5-4.9,0.2-6.8,2.1c-2,3.8-3.3,7.8-3.9,12c-1,4.2-1.2,8.4-0.6,12.6c2.9,5.1,5.8,10.2,8.7,15.3c1,2.5,2.8,3.9,5.5,4.4
								c2.6,0.6,4.9,0,6.8-1.9c2.1-3.6,3.4-7.5,4-11.6C891.9,938.3,892.2,934.2,891.7,930.1z"/>
							<path opacity="0.1091" fill="#131313" d="M846.8,925.9c-2.9-5.9-5.9-11.7-8.8-17.6c-2.1-4.2-8.8-5.6-12.3-2.3
								c-1.9,3.1-3.1,6.5-3.6,10.1c-0.9,3.6-1,7.2-0.3,10.7c2.9,5.5,5.9,11,8.8,16.5c2.2,4.1,8.7,5.6,12.3,2.4
								c1.9-2.9,3.1-6.2,3.6-9.7C847.3,932.8,847.4,929.4,846.8,925.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M836.7,952c-2.8-6.1-6.6-11.3-11.5-15.7c-4.2-4.5-8.8-8.7-13.6-12.7
								c-1.6-1.8-3.6-2.8-6.1-2.8c-2.4-0.3-4.6,0.3-6.4,1.8c-1.5,2.7-2.3,5.5-2.3,8.6c-0.4,3.1-0.1,6,1,8.9c4.5,3.8,8.8,7.9,13,12.1
								c4.7,4.1,8.5,9,11.4,14.7c1.5,1.8,3.5,2.8,5.9,3c2.4,0.5,4.5,0,6.5-1.4c1.6-2.4,2.5-5.1,2.7-8
								C837.9,957.6,837.7,954.8,836.7,952z"/>
							<path opacity="0.1091" fill="#131313" d="M814.1,937.1c-2.3-5.3-5.3-10-9.2-14.2c-5.6-5.7-11.5-11.1-17.7-16.2
								c-2.4-1.2-4.8-1.4-7.4-0.4c-2.8,1.5-4.2,4-4.2,7.3c-0.8,4.8-1.1,9.6-0.8,14.5c-0.3,3.3,0.8,5.9,3.4,7.7
								c5.7,4.8,11.1,9.9,16.3,15.2c-0.8-0.9-1.5-1.8-2.3-2.7c2.2,3.5,4.4,7,6.6,10.5c1.2,2.3,3.2,3.6,5.8,3.9
								c2.6,0.4,4.8-0.4,6.6-2.3c1.8-3.6,2.8-7.4,3.1-11.5C815,945,814.9,941,814.1,937.1z"/>
							<path opacity="0.1091" fill="#131313" d="M815.3,895.6c-3.7-4.5-7.5-8.8-11.7-12.9c-4.2-4.5-8.7-8.7-13.6-12.5
								c-2.3-2.2-4.8-2.3-7.4-0.4c-3.3,3.9-4.8,8.5-4.6,13.7c-1.1,8.9-1.6,17.9-1.6,26.9c-0.8,5.2,0.2,9.9,3,14.2
								c4.2,3.6,8,7.5,11.6,11.6c3.5,3.8,6.9,7.7,10.3,11.6c2.2,2.3,4.6,2.6,7.3,0.9c3.5-3.5,5.3-7.9,5.5-13
								c1.6-8.6,2.7-17.3,3.3-26C818.5,904.6,817.8,899.9,815.3,895.6z"/>
							<path opacity="0.1091" fill="#131313" d="M833.2,924.8c-3.6-4.4-7.4-8.6-11.5-12.6c-4.2-4.4-8.7-8.6-13.5-12.4
								c-2.4-1.4-4.8-1.6-7.4-0.4c-2.9,2.1-4.4,5-4.3,8.8c-0.9,5.7-1.2,11.5-1,17.3c-0.5,3.8,0.6,6.8,3.3,9.2
								c4.3,3.8,8.3,7.8,12.1,12.1c3.7,3.9,7.2,7.9,10.5,12c2.3,1.6,4.7,1.9,7.3,0.9c3.1-1.9,4.7-4.7,4.9-8.4
								c1.3-5.7,2-11.4,2.2-17.2C836.7,930.5,835.8,927.4,833.2,924.8z"/>
							<path opacity="0.1091" fill="#131313" d="M834.5,892c-3.7-4.5-7.6-8.7-11.7-12.8c-4.2-4.5-8.8-8.6-13.7-12.3
								c-2.3-2.3-4.8-2.5-7.4-0.4c-3.3,4.2-4.9,9.1-4.7,14.7c-1.1,9.6-1.7,19.2-1.7,28.8c-0.9,5.5,0.1,10.6,2.9,15.2
								c4.1,3.8,7.9,7.8,11.4,12.1c3.4,3.9,6.8,7.9,10.2,11.7c2.2,2.4,4.6,2.8,7.3,0.9c3.6-4,5.5-8.7,5.7-14.3
								c1.7-9.4,2.9-18.9,3.6-28.4C837.7,901.8,837,896.7,834.5,892z"/>
							<path opacity="0.1091" fill="#131313" d="M797.7,919.3c-3.6-4.5-7.4-8.7-11.5-12.7c-4.2-4.4-8.7-8.6-13.5-12.4
								c-2.4-1.4-4.8-1.6-7.4-0.4c-2.9,2.1-4.4,5-4.3,8.8c-0.9,5.7-1.2,11.5-0.9,17.3c-0.5,3.8,0.6,6.8,3.3,9.2
								c4.4,3.4,8.4,7.2,12.2,11.2c3.8,3.7,7.3,7.6,10.6,11.8c2.3,1.5,4.7,1.8,7.3,0.9c3-1.7,4.7-4.5,4.9-8.1
								c1.2-5.5,1.9-11,2.1-16.6C801.1,924.8,800.2,921.7,797.7,919.3z"/>
							<path opacity="0.1091" fill="#131313" d="M821,953.8c-2.8-5.8-6.7-10.6-11.7-14.6c-4.5-4.1-9-8.2-13.6-12.2
								c-1.7-1.6-3.7-2.4-6.1-2.4c-2.3-0.3-4.5,0.2-6.4,1.5c-1.4,2.2-2.2,4.6-2.2,7.2c-0.4,2.6,0,5.1,1.1,7.5
								c4.4,3.8,8.8,7.7,13.1,11.6c4.7,3.7,8.6,8.2,11.6,13.5c1.7,1.6,3.7,2.5,6,2.6c2.3,0.4,4.4,0.1,6.4-1c1.5-1.9,2.4-4.1,2.5-6.6
								C822.3,958.3,822,956,821,953.8z"/>
							<path opacity="0.1091" fill="#131313" d="M847.1,921.3c-3.6-4.5-7.4-8.7-11.5-12.7c-4.2-4.5-8.7-8.6-13.5-12.3
								c-2.4-1.6-4.8-1.7-7.4-0.4c-3,2.4-4.5,5.6-4.4,9.6c-0.9,6.3-1.3,12.6-1.1,19c-0.6,4,0.5,7.4,3.2,10.1
								c4.2,3.9,8.2,8.1,12,12.4c3.6,4,7.1,8,10.4,12.1c2.3,1.7,4.7,2.1,7.3,0.9c3.2-2.2,4.9-5.3,5.1-9.4c1.4-6.3,2.2-12.6,2.4-19
								C850.5,927.7,849.7,924.3,847.1,921.3z"/>
							<path opacity="0.1091" fill="#131313" d="M806.2,908.6c-3.6-4.6-7.4-8.9-11.6-12.9c-4.2-4.5-8.7-8.7-13.5-12.5
								c-2.4-1.8-4.8-2-7.4-0.4c-3.2,3.1-4.6,6.9-4.5,11.5c-1,7.5-1.4,15-1.3,22.6c-0.7,4.6,0.4,8.5,3.2,11.9
								c4.2,3.5,8.2,7.3,11.8,11.4c3.6,3.8,7.1,7.7,10.4,11.8c2.2,1.9,4.7,2.2,7.3,0.9c3.3-2.7,5.1-6.3,5.2-10.8
								c1.5-7.2,2.4-14.4,2.7-21.7C809.6,916,808.8,912.1,806.2,908.6z"/>
							<path opacity="0.1091" fill="#131313" d="M824.4,906.4c-3.6-4.5-7.5-8.8-11.6-12.9c-4.2-4.5-8.7-8.6-13.5-12.4
								c-2.3-1.9-4.8-2.1-7.4-0.4c-3.2,3.3-4.7,7.4-4.5,12.2c-1,7.9-1.5,15.9-1.4,23.9c-0.7,4.8,0.3,9,3.1,12.6
								c4.2,3.7,8.1,7.6,11.7,11.9c3.5,3.9,7,7.8,10.3,11.8c2.2,2.1,4.7,2.4,7.3,0.9c3.4-3,5.2-6.9,5.3-11.7
								c1.5-7.7,2.5-15.5,2.9-23.4C827.7,914.4,827,910.1,824.4,906.4z"/>
							<path opacity="0.1091" fill="#131313" d="M846.6,940.9c-2.3-5.3-5.4-10-9.2-14.1c-5.6-5.8-11.5-11.2-17.7-16.4
								c-2.4-1.2-4.8-1.3-7.4-0.4c-2.8,1.5-4.2,3.8-4.2,7.1c-0.8,4.7-1.1,9.4-0.8,14.1c-0.3,3.3,0.8,5.8,3.4,7.5
								c5.6,5.2,11,10.6,16.2,16.2c-0.8-0.9-1.5-1.8-2.3-2.7c2.2,3.5,4.4,7,6.6,10.5c1.2,2.3,3.2,3.6,5.8,3.9
								c2.6,0.4,4.8-0.4,6.6-2.3c1.8-3.6,2.8-7.5,3.1-11.5C847.4,948.9,847.4,944.9,846.6,940.9z"/>
							<path opacity="0.1091" fill="#131313" d="M801.5,938.3c-1.5-6.4-4.6-11.7-9.1-16c-5.6-5.6-11.5-10.9-17.7-15.9
								c-2.4-1.1-4.8-1.3-7.4-0.4c-2.7,1.3-4.1,3.6-4.2,6.8c-0.8,4.4-1,8.9-0.7,13.4c-0.3,3.2,0.9,5.6,3.4,7.2
								c5.6,4.6,11.1,9.5,16.4,14.5c-0.8-0.8-1.6-1.6-2.3-2.5c2.2,4.1,4.4,8.2,6.6,12.2c2.2,4.2,9.2,4.9,12.4,1.5
								c1.7-3.2,2.7-6.6,2.9-10.2C802.5,945.3,802.3,941.8,801.5,938.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M807.7,945.9c0.4-1.7,0.7-3.4,1.1-5.1c0.7-2.1,0.8-4.3,0.4-6.5
								c0.2-2.1-0.3-4.1-1.4-5.9c-1.1-3.3-2.6-6.3-4.5-9.2c-1.2-2.4-3-4-5.5-4.9c-3-0.6-5.5,0.3-7.6,2.8c-3.4,3.1-6.5,6.5-9.1,10.3
								c-2.2,2.3-2.8,5-1.8,7.9c1,2.9,1.5,5.8,1.5,8.9c0.1-1.2,0.2-2.4,0.4-3.6c-0.4,2.6-0.7,5.2-1.1,7.8c0.2-1.2,0.4-2.4,0.6-3.6
								c-0.4,1.4-0.8,2.9-1.1,4.3c-1.1,2.5-0.9,4.8,0.6,7.1c1.5,2.3,3.5,3.4,6.3,3.4c4.2-1.2,8-3,11.6-5.6
								C801.7,952,805,949.3,807.7,945.9z"/>
							<path opacity="0.1091" fill="#131313" d="M785.1,929.1c0.6-1.8,1.1-3.7,1.7-5.5c0.4-2.6,0.6-5.3,0.7-8
								c0.3-1.7-0.1-3.2-1.3-4.6c-1.3-3.2-3-6.1-5-8.9c-1.2-2.4-3.1-4-5.6-4.8c-2.9-0.7-5.4,0.2-7.3,2.6c-3.3,2.9-6.1,6.2-8.6,9.9
								c-2.1,2.3-2.6,4.8-1.6,7.6c1.5,2.1,3,4.3,4.5,6.4c-0.7-1.1-1.3-2.1-2-3.2c0.6,1.2,1.2,2.5,1.8,3.7c0-1.1,0.1-2.2,0.1-3.4
								c-0.1,3.1-0.7,6.2-1.8,9.1c-1.3,4.5,1.9,10.2,6.9,10.5c3.5-0.8,6.7-2.2,9.7-4.4C780.3,934.4,783,932.1,785.1,929.1z"/>
							<path opacity="0.1091" fill="#131313" d="M790.3,890.5c0-1.5,0-3,0-4.5c-0.2-3-0.9-5.9-2.1-8.6c-1.3-3.4-2.8-6.5-4.5-9.7
								c-0.7-1.1-1.3-2.2-2-3.3c-1.2-0.5-2.4-1-3.5-1.6c-1.4,0.3-2.9,0.6-4.3,0.9c-3.5,2.4-6.7,5.2-9.5,8.5
								c-4.5,4.7-8.9,9.6-12.3,15.1c-1.6,1.5-2.3,3.3-2.2,5.4c-0.6,2.1-0.1,4,1.7,5.5c1.6,1.2,2.8,2.7,3.4,4.6
								c-0.5-1.2-0.9-2.4-1.4-3.6c0.9,1.9,1.2,4,0.9,6.1c0.2,2.8,1.5,4.9,4.1,6.1c3.7,1,7.1,0.3,10.2-2.2c5.5-3,10.7-6.4,15.6-10.3
								C787.8,897.1,789.8,894.2,790.3,890.5z"/>
							<path opacity="0.1091" fill="#131313" d="M807.8,917.6c0.4-2.5,0.3-4.9-0.4-7.3c-0.3-2.2-0.9-4.3-1.9-6.3
								c-0.3-0.8-0.6-1.6-0.9-2.4c-1.5-3.4-3.3-6.5-5.5-9.5c-2.3-1.9-4.8-2.2-7.6-0.9c-3.3,2.2-6.2,4.7-8.8,7.7
								c-3.5,3.4-6.6,7-9.5,10.9c-3,2.8-4.2,6.2-3.8,10c1,2.1,1.4,4.3,1.4,6.6c0.3,2.3,0.2,4.6-0.4,6.9c0.1,2.9,1.5,4.9,4,6.2
								c3.8,1,7.3,0.2,10.6-2.4c5.8-3.1,11.2-6.7,16.4-10.7C805,924.4,807.1,921.4,807.8,917.6z"/>
							<path opacity="0.1091" fill="#131313" d="M810.5,887.3c0-1.6,0.1-3.1,0.1-4.7c-0.2-2.7-0.7-5.3-1.5-7.8
								c-0.4-1.8-1-3.5-1.8-5.1c-1.2-3.5-3-6.8-5.1-9.9c-2.9-1.9-5.9-1.8-8.8,0.4c-6.2,4.8-11.9,10.2-17.1,16.2
								c-5.5,5.7-10.4,11.8-14.6,18.4c-1.9,3.1-1.8,6.1,0.4,8.8c0.3,0.7,0.7,1.3,1,2c-0.4-1.2-0.9-2.5-1.3-3.7
								c0.3,1.2,0.6,2.5,0.8,3.7c0.4,1.5,0.4,3,0,4.5c-0.3,3.1,1.1,5.2,4,6.2c4.9,0.4,9.3-1,13.3-4.2c7.5-4.3,14.6-9,21.5-14.2
								C806,895.5,809,892,810.5,887.3z"/>
							<path opacity="0.1091" fill="#131313" d="M772.2,915.8c1.1-2.9,1.6-6,1.7-9.1c0.3-2.1,0.3-4.3,0.1-6.5
								c-0.9-1.8-1.8-3.6-2.8-5.4c-1.2-2-2.5-3.9-3.8-5.8c-0.8-1-1.6-2-2.3-2.9c-1.1-0.6-2.2-1.3-3.2-1.9c-1.1,0-2.2-0.1-3.3-0.1
								c-2.3,1.1-4.3,2.6-5.9,4.5c-2.7,2.7-5.1,5.6-6.7,9c-1,1.1-1.2,2.3-0.6,3.6c0.1,1.9,1,3.4,2.6,4.5c2.3,1.4,4.1,3.3,5.5,5.5
								c-0.6-2.2-1.2-4.3-1.8-6.5c0,2.7,0,5.4,0,8.1c0-1,0-2.1,0-3.1c-0.3,1.7-0.7,3.5-1,5.2c-0.8,4.1,2.4,10,6.9,10.5
								c3-0.4,5.8-1.6,8.2-3.4C768.4,920.5,770.6,918.4,772.2,915.8z"/>
							<path opacity="0.1091" fill="#131313" d="M793.2,947.8c0.4-1.9,0.9-3.8,1.3-5.7c0.3-2.6,0.4-5.3,0.2-7.9
								c0.1-1.7-0.4-3.3-1.5-4.7c-1.4-3.4-3.2-6.6-5.3-9.6c-1.3-2.2-3-3.9-5.3-5.1c-2.3-1.1-4.4-0.7-6.3,1.1c-2.7,2-4.9,4.4-6.8,7.1
								c-1.7,2-2,4.1-0.8,6.4c2.1,3.1,3.8,6.4,4.9,10c-0.1-1-0.1-2.1-0.2-3.1c0,3.6-0.5,7.1-1.5,10.5c-0.9,4.1,2.4,10,6.9,10.5
								c3-0.4,5.7-1.5,8.1-3.3C789.5,952.5,791.6,950.4,793.2,947.8z"/>
							<path opacity="0.1091" fill="#131313" d="M822.9,913.2c0-1.6,0.1-3.1,0.1-4.7c-0.5-2.5-1.1-5-1.9-7.4c-0.4-1.7-1.1-3.3-2-4.9
								c-1.4-3.3-3.2-6.2-5.5-9c-2.7-1.9-5.4-2-8.3-0.2c-4,2.8-7.6,6.1-10.9,9.7c-4.3,4.2-8.4,8.7-12.1,13.5
								c-3.6,3.3-5.5,7.2-5.6,11.9c0.4,2.1,0.4,4.2-0.1,6.3c0.2,2.2-0.1,4.4-0.6,6.6c-0.2,3.1,1.1,5.1,4,6.2c4.8,0.5,9.1-0.8,13-4
								c7.3-4.1,14.3-8.7,21-13.8C818.5,921.3,821.4,917.8,822.9,913.2z"/>
							<path opacity="0.1091" fill="#131313" d="M779.1,905.7c0.8-4.1,2.7-10.5,1.5-14.6c-0.8-1.9-1.7-3.9-2.5-5.8
								c-1.2-2.1-2.4-4.2-3.6-6.3c-0.7-1-1.4-2.1-2.1-3.1c-1.1-0.6-2.3-1.1-3.4-1.7c-1.3,0.1-2.5,0.3-3.8,0.4
								c-2.9,1.8-5.5,4-7.8,6.6c-3.6,3.8-7.1,7.7-9.6,12.3c-1.3,1.3-1.8,2.8-1.4,4.5c-0.3,2,0.4,3.7,2.2,5.1
								c1.9,1.4,3.4,3.2,4.3,5.3c-0.5-1.1-1.1-2.3-1.6-3.4c0,1.2,0.1,2.4,0.1,3.6c0.2-1.2,0.4-2.4,0.6-3.6c-0.5,2.5-0.9,5-1.4,7.5
								c0.4,2.7,1.8,4.7,4.1,6.2c2.1,1.1,4.1,1.1,6-0.1c3.2-1,6.2-2.5,8.9-4.6C773.2,911.8,776.4,909,779.1,905.7z"/>
							<path opacity="0.1091" fill="#131313" d="M798.8,899.9c0.4-2.5,0.4-5-0.2-7.5c-0.2-2.3-0.7-4.4-1.8-6.5
								c-0.3-0.8-0.6-1.6-0.9-2.4c-1.4-3.5-3.2-6.8-5.3-9.9c-2.5-1.9-5.1-2.1-7.9-0.5c-5.1,3.7-9.6,7.9-13.6,12.7
								c-4.3,4.5-8.1,9.5-11.2,14.9c-1.3,3-0.8,5.6,1.4,7.8c0.5,0.8,0.9,1.6,1.4,2.4c-0.5-1.2-1.1-2.3-1.6-3.5
								c0.6,1.4,1,2.8,1.2,4.3c0.4,1.6,0.3,3.3-0.1,4.9c0.1,2.9,1.4,4.9,4,6.2c4,0.9,7.6,0,11-2.6c6-3.3,11.7-7,17.1-11.2
								C795.8,906.9,798,903.9,798.8,899.9z"/>
							<path opacity="0.1091" fill="#131313" d="M819.1,929c0.6-2.5,0.4-5-0.3-7.4c-0.3-2.2-1-4.3-1.9-6.3c-0.4-0.8-0.7-1.6-1.1-2.4
								c-1.4-3.4-3.3-6.5-5.5-9.3c-2.3-1.9-4.8-2.2-7.6-1c-3.3,2.1-6.2,4.7-8.8,7.6c-3.5,3.3-6.6,7-9.5,10.9c-3,2.8-4.2,6.1-3.8,10
								c0.4,2.5,0.5,5.1,0,7.6c0,2.5-0.4,4.9-1,7.3c0,2.9,1.4,5,4,6.2c4,0.9,7.7,0,11.1-2.7c6.1-3.3,11.9-7.1,17.4-11.4
								C816,936,818.3,933,819.1,929z"/>
							<path opacity="0.1091" fill="#131313" d="M773.2,929.3c1.2-4.6,2.4-9,1.9-13.9c0-1.7-0.5-3.3-1.4-4.7
								c-1.2-3.5-2.8-6.8-4.8-10c-1.3-2.2-3-3.9-5.3-5.1c-2.3-1-4.5-0.7-6.4,1.2c-2.7,2.1-5.1,4.5-7,7.3c-1.7,2-2,4.2-0.9,6.5
								c1.8,2.5,3.6,5,5.4,7.4c-0.7-1-1.3-2.1-2-3.1c0.9,1.6,1.7,3.2,2.6,4.8c-0.1-1-0.2-2-0.3-3c0.3,3.5-0.1,6.9-1.1,10.3
								c-0.2,2.3,0.4,4.4,1.8,6.3c1.2,2.1,2.9,3.4,5.1,4.2c2.6-0.2,5-1.1,7.1-2.7C770.2,933.5,771.9,931.7,773.2,929.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M755.4,887.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C746.1,884,750.3,886.4,755.4,887.1z"/>
							<path opacity="0.1091" fill="#131313" d="M731,868.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C724.6,867.6,727.5,868.7,731,868.2z"/>
							<path opacity="0.1091" fill="#131313" d="M730.3,834.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C725.5,832,727.7,833.5,730.3,834.3z"/>
							<path opacity="0.1091" fill="#131313" d="M752.2,860.8c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C744.9,859.5,748.3,861,752.2,860.8z"/>
							<path opacity="0.1091" fill="#131313" d="M747.4,830c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C741.3,826.6,744.2,828.6,747.4,830z"/>
							<path opacity="0.1091" fill="#131313" d="M713.4,856.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C708.2,853.9,710.6,855.5,713.4,856.5z"/>
							<path opacity="0.1091" fill="#131313" d="M738.4,888.9c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C730.5,886.9,734.1,888.8,738.4,888.9z"/>
							<path opacity="0.1091" fill="#131313" d="M766.4,857.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C758.4,855.5,762,857.4,766.4,857.5z"/>
							<path opacity="0.1091" fill="#131313" d="M721.6,844.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C716.4,842.2,718.8,843.9,721.6,844.9z"/>
							<path opacity="0.1091" fill="#131313" d="M737.9,841.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C731.4,837.5,734.4,839.7,737.9,841.3z"/>
							<path opacity="0.1091" fill="#131313" d="M765,873.6c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C755.9,870.6,760,873,765,873.6z"/>
							<path opacity="0.1091" fill="#131313" d="M713.3,867.3c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C707,863.7,709.9,865.8,713.3,867.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M745.3,913.6c2.3-7.7,3-15.4,2.1-23.4c0.5-2.6-0.3-4.8-2.3-6.6
								c-1.9-1.9-4.1-2.6-6.7-2.1c-3.6,1.9-6.8,4.4-9.5,7.4c-3,2.8-5.4,6.1-7.2,9.8c0.9,7.5,0.4,14.9-1.6,22.3
								c-0.8,4.9,3.8,9.8,8.8,8.9c3.6-1.7,6.7-4,9.3-7C741.3,920.3,743.6,917.2,745.3,913.6z"/>
							<path opacity="0.1091" fill="#131313" d="M722.1,897.5c3.3-7.9,4.3-16,3.1-24.3c0.3-2.6-0.5-4.7-2.4-6.5
								c-1.8-1.9-4-2.6-6.6-2.2c-3.5,1.7-6.5,4-9,7c-2.8,2.7-5.1,5.7-6.7,9.3c1.2,7.8,0.4,15.5-2.3,23c-1,4.7,4.3,9.6,8.9,8.9
								c3.3-1.5,6.2-3.6,8.7-6.4C718.5,903.7,720.6,900.8,722.1,897.5z"/>
							<path opacity="0.1091" fill="#131313" d="M727.6,857.2c0-7.2,0-14.3,0-21.5c-0.7-2.9-2.4-4.6-5.3-5.1
								c-4.2,0.1-7.7,1.9-10.4,5.3c-5.3,4.7-10.3,9.9-14.8,15.4c-3.3,2.9-5,6.4-4.9,10.6c-0.2,6.8-0.2,13.6,0,20.5
								c0.6,2.9,2.4,4.6,5.2,5.2c4.2,0,7.6-1.7,10.4-5c5.3-4.6,10.3-9.6,14.9-14.9C725.9,864.8,727.6,861.3,727.6,857.2z"/>
							<path opacity="0.1091" fill="#131313" d="M745.4,884.3c-0.2-7.1-0.2-14.2-0.1-21.3c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.2,6.8-0.1,13.6,0.1,20.4
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.5c5-4.2,9.6-8.8,13.8-13.8C744.1,891.5,745.5,888.2,745.4,884.3z"/>
							<path opacity="0.1091" fill="#131313" d="M748.7,852.8c-0.2-7.2-0.3-14.3-0.1-21.5c-0.3-3.2-2.1-4.9-5.3-5.1
								c-5.2,1-9.5,3.6-13,7.9c-6.9,6.4-13.5,13.2-19.6,20.3c-4.2,3.6-6.7,8-7.5,13.2c-0.4,6.7-0.3,13.5,0.1,20.2
								c0.3,3.2,2,5,5.2,5.2c5.2-0.8,9.5-3.4,12.9-7.5c7-6.2,13.5-12.8,19.7-19.7C745.3,862.3,747.8,858,748.7,852.8z"/>
							<path opacity="0.1091" fill="#131313" d="M709.1,883.7c2.6-7.8,3.5-15.7,2.5-23.8c0.2-2.5-0.7-4.6-2.6-6.3
								c-1.8-1.9-3.9-2.7-6.4-2.4c-3.3,1.5-6.1,3.6-8.4,6.3c-2.6,2.5-4.7,5.3-6,8.6c1,7.6,0.4,15.1-1.8,22.6
								c-0.8,4.5,4.5,9.4,8.9,8.8c3.1-1.3,5.8-3.2,8.1-5.8C705.9,889.6,707.8,886.8,709.1,883.7z"/>
							<path opacity="0.1091" fill="#131313" d="M731.1,914.8c1.6-7.8,2.2-15.7,1.7-23.6c0-4.2-4.4-9.3-8.8-8.9
								c-2.8,1-5.2,2.6-7.2,4.9c-2.3,2-3.9,4.4-4.9,7.2c0.6,7.7,0.3,15.3-0.9,22.9c0.1,2.4,1.1,4.3,2.9,5.9c1.6,1.8,3.6,2.8,5.9,2.9
								c2.7-0.9,5-2.4,6.8-4.5C728.7,919.8,730.2,917.5,731.1,914.8z"/>
							<path opacity="0.1091" fill="#131313" d="M760.1,879.9c-0.2-7.1-0.2-14.3,0-21.4c-0.5-3.1-2.2-4.8-5.3-5.1
								c-4.7,0.5-8.6,2.7-11.7,6.6c-6.2,5.6-11.9,11.5-17.3,17.8c-3.8,3.2-5.9,7.2-6.3,11.9c-0.3,6.8-0.3,13.5,0,20.3
								c0.4,3,2.2,4.8,5.2,5.2c4.7-0.4,8.6-2.5,11.7-6.3c6.2-5.4,12-11.2,17.4-17.3C757.6,888.5,759.7,884.6,760.1,879.9z"/>
							<path opacity="0.1091" fill="#131313" d="M718.4,868.1c-0.1-7.1-0.2-14.1-0.2-21.2c-0.9-2.6-2.6-4.3-5.3-5.1
								c-3.5-0.4-6.5,0.8-8.9,3.7c-4.3,3.7-8.3,7.8-11.8,12.2c-2.8,2.5-3.9,5.5-3.4,9c0,6.8,0,13.7,0.1,20.5
								c0.8,2.6,2.6,4.4,5.2,5.2c3.5,0.5,6.5-0.7,8.9-3.5c4.4-3.6,8.3-7.6,11.9-11.9C717.8,874.6,718.9,871.6,718.4,868.1z"/>
							<path opacity="0.1091" fill="#131313" d="M736.6,866.3c-0.1-7.1-0.1-14.3,0-21.4c-0.6-2.9-2.4-4.6-5.3-5.1
								c-4.3,0.2-7.9,2.1-10.8,5.6c-5.5,4.9-10.7,10.3-15.5,16c-3.4,3-5.2,6.6-5.3,11c-0.2,6.8-0.2,13.6,0,20.4
								c0.6,2.9,2.3,4.6,5.2,5.2c4.3-0.1,7.9-1.9,10.8-5.4c5.6-4.8,10.7-10,15.5-15.5C734.8,874.2,736.5,870.6,736.6,866.3z"/>
							<path opacity="0.1091" fill="#131313" d="M756.7,895.6c-0.2-7.1-0.2-14.2-0.1-21.3c-0.7-2.8-2.5-4.5-5.3-5.1
								c-4-0.1-7.3,1.5-9.9,4.7c-5,4.3-9.6,9.1-13.8,14.2c-3.1,2.7-4.6,6.1-4.4,10c-0.2,6.8-0.1,13.6,0.1,20.4
								c0.7,2.8,2.4,4.5,5.2,5.2c3.9,0.2,7.2-1.3,9.9-4.4c5-4.2,9.6-8.8,13.8-13.8C755.4,902.8,756.9,899.5,756.7,895.6z"/>
							<path opacity="0.1091" fill="#131313" d="M712,895.7c1.6-7.8,2.2-15.7,1.7-23.7c0-4.2-4.4-9.3-8.9-8.8c-2.8,1-5.3,2.7-7.3,5
								c-2.3,2-3.9,4.5-5,7.3c0.6,7.7,0.3,15.3-1,22.9c0.1,2.4,1.1,4.4,2.9,6c1.6,1.8,3.6,2.8,6,2.9c2.7-0.9,5-2.4,6.9-4.6
								C709.5,900.7,711.1,898.4,712,895.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M795,997.3c-0.1-2-0.1-4.1-0.2-6.1c0,1.3-0.1,2.6-0.1,3.9c0.6-2.6,1.2-5.2,1.8-7.8
								c-1.8,3.4-3.6,6.8-5.3,10.2c2.2-3,4.1-6.2,5.9-9.5c1.4-2.1,2.3-4.4,2.8-6.9c-0.1-1.9-0.9-3.4-2.4-4.4
								c-1.2-1.5-2.8-2.4-4.8-2.7c-1.1,0.1-2.2,0.2-3.3,0.3c-2.9,2.1-5.2,4.7-7,7.8c-1.8,5.9-3.5,11.8-5.3,17.7
								c-0.3,3.2-0.1,6.4,0.5,9.6c1.1,2.2,2.8,3.6,5.1,4.3c2.3,1,4.5,0.9,6.7-0.1c2.1-2.2,3.6-4.7,4.5-7.6
								C794.8,1003.3,795.3,1000.3,795,997.3z"/>
							<path opacity="0.1091" fill="#131313" d="M772.5,980.6c-0.1-1.9-0.2-3.8-0.3-5.8c-0.1,1.6-0.3,3.2-0.4,4.8
								c0.5-3.3,0.9-6.7,1.4-10c-1,3.7-1.9,7.4-2.9,11.1c1.1-2.9,2.2-5.9,3.3-8.8c-0.9,2-1.8,4-2.6,6c1.5-2.7,3-5.4,4.5-8.2
								c1.7-2.6,1.9-5.2,0.4-7.8c-1.1-1.7-2.6-2.8-4.5-3.3c-1.6-1-3.3-1-5.1-0.2c-3,2.3-5.5,5.1-7.4,8.5c-3.5,5.8-5.5,12-6.1,18.6
								c-0.3,3.9-0.1,7.8,0.6,11.7c0.8,2.5,2.4,4.1,4.9,4.8c2.4,1,4.7,0.7,6.9-0.7c2.4-2.9,4.1-6.2,5.2-9.9
								C771.9,988,772.6,984.4,772.5,980.6z"/>
							<path opacity="0.1091" fill="#131313" d="M775.3,939c-0.3-1.2-0.7-2.4-1-3.5c0.3,1.6,0.7,3.2,1,4.8c0.1-1,0.2-1.9,0.3-2.9
								c-2.1,6.6-4.2,13.2-6.3,19.7c2.3-5.6,4.7-11.2,7-16.8c1-2.5,2-4.9,3.1-7.4c-0.1-1.5-0.1-3.1-0.2-4.6c-0.8-1-1.7-2-2.5-3
								c-2.1-1.3-4.1-1.2-5.9,0.1c-2.1,0.6-3.7,2-4.7,4c-2.2,2.4-4.1,5-5.7,7.8c-2.2,3.1-4.2,6.3-5.9,9.7c-2.4,3.9-4.4,8-5.8,12.4
								c-0.7,6.3-0.3,12.4,1.1,18.5c0.3,2.2,1,4.3,2.2,6.3c1.7,2.6,4,3.4,7,2.4c4-2.4,6.7-6,7.8-10.8c3.2-7.6,5.9-15.3,8.1-23.2
								C776.7,947.9,776.9,943.5,775.3,939z"/>
							<path opacity="0.1091" fill="#131313" d="M794.4,970.9c-1.2-2.1-1.5-4.2-0.9-6.5c-1.5,5-3,9.9-4.4,14.9
								c1.9-4.5,3.9-9.1,5.8-13.6c0.8-2,1.6-4.1,2.4-6.1c-0.2-1.3-0.4-2.7-0.5-4c-0.9-0.9-1.7-1.9-2.6-2.8c-1.9-1.3-3.8-1.5-5.6-0.4
								c-1.9,0.3-3.3,1.3-4.2,3c-1.9,1.8-3.5,3.9-4.9,6.2c-1.9,2.5-3.5,5.1-4.9,7.8c-2,3.2-3.6,6.5-4.7,10.2
								c-0.1,1.4-0.2,2.8-0.3,4.3c0,2.1,0.3,4.2,0.8,6.2c0.8,3.2,2,6.2,3.4,9.2c1.9,2.1,4.2,2.9,6.9,2.4c2.5-0.7,4.2-2.3,5.1-4.7
								c2.2-3.4,3.9-7.1,5-11C792.8,981,794,976.1,794.4,970.9z"/>
							<path opacity="0.1091" fill="#131313" d="M795.9,940.1c-0.6-3-1.2-6-1.8-8.9c0.2,1.8,0.5,3.7,0.7,5.5c0.2-1,0.3-2,0.5-3
								c-0.9,3.3-1.8,6.6-2.7,9.9c1.4-4.1,2.9-8.3,4.3-12.4c-3.1,7.5-6.2,15-9.3,22.5c4.4-7.9,8.4-16.1,11.9-24.5
								c0.1-1.8,0.2-3.6,0.3-5.4c-1-3.1-3.1-4.4-6.3-3.8c-3.4,1.3-6,3.5-7.8,6.7c-4.1,4.9-7.6,10.2-10.7,15.8
								c-3.5,5.3-6.5,11-8.9,16.8c-2,3-2.8,6.4-2.3,10c0.2,6.6,1.8,12.7,5,18.4c1.6,2.9,3.9,3.7,6.9,2.4c3.1-2,5.2-4.8,6.3-8.3
								c3-5.8,5.5-11.7,7.4-18C792.3,956,794.5,948.1,795.9,940.1z"/>
							<path opacity="0.1091" fill="#131313" d="M758.7,966.4c-0.2-1.6-0.4-3.1-0.6-4.7c-0.2,1.7-0.4,3.5-0.6,5.2
								c0.4-3.1,0.8-6.2,1.2-9.4c-1.1,4-2.1,7.9-3.2,11.9c1.9-4.1,3.8-8.2,5.7-12.3c0.5-1.5,0.9-3,1.4-4.6c-0.3-1.1-0.7-2.2-1-3.3
								c-0.9-0.9-1.8-1.7-2.7-2.6c-1.6-1.3-3.4-1.7-5.2-1.1c-1.5-0.1-2.6,0.5-3.4,1.9c-1.5,1.2-2.8,2.6-3.7,4.3
								c-1.4,1.7-2.6,3.6-3.5,5.6c-1.5,2.3-2.5,4.8-3,7.5c-1.9,4.3-2.6,8.6-2.3,13.1c-0.1,2.6,0.2,5.2,1,7.7c1,5.2,7.9,7.2,11.8,4.1
								c2.6-3.4,4.4-7.1,5.6-11.1C757.8,974.7,758.6,970.6,758.7,966.4z"/>
							<path opacity="0.1091" fill="#131313" d="M780.3,996.8c-0.2-1.6-0.4-3.3-0.5-4.9c0.1,1.1,0.1,2.1,0.2,3.2
								c0.1-1.5,0.3-3,0.4-4.5c-0.4,1.4-0.9,2.7-1.3,4.1c0.4-0.7,0.7-1.4,1.1-2.1c-0.8,1.2-1.6,2.4-2.4,3.7c0.5-0.6,1-1.2,1.4-1.8
								c2.1-2.2,3.7-4.8,4.8-7.6c1.1-2.2,0.9-4.2-0.7-6c-1.7-1.9-3.8-3.2-6.2-3.9c-2.3-0.7-4.2,0-5.7,1.9c-2.3,1.8-4,4-5.3,6.6
								c-1,1.6-1.7,3.3-2.2,5.1c-1.6,5-0.7,10.2,0.1,15.3c1.3,1.9,3.1,3.2,5.3,3.8c2.1,0.9,4.3,1,6.5,0.4c1.9-1.6,3.2-3.5,3.8-5.9
								C780.6,1001.5,780.8,999.2,780.3,996.8z"/>
							<path opacity="0.1091" fill="#131313" d="M808.1,968.1c-0.8-2.6-1.5-5.2-2.3-7.8c0.4,1.4,0.8,2.8,1.2,4.2
								c0.1-1.1,0.3-2.3,0.4-3.4c-0.6,2.5-1.2,4.9-1.7,7.4c1-3.4,2.1-6.8,3.1-10.1c-2.3,5.8-4.6,11.5-6.9,17.3
								c3.7-6.3,6.8-12.8,9.5-19.6c-0.1-1.5-0.1-3-0.2-4.6c-1.2-2.7-3.3-4-6.3-3.9c-3,0.6-5.3,2.2-6.8,4.9
								c-6.5,7.6-12,16.5-15.9,25.7c-2.9,5-2.7,10.4,0.6,16.1c0.7,2.5,1.7,4.8,3,7.1c1.8,2.3,4.1,3.1,6.9,2.5
								c2.7-1.1,4.5-2.9,5.4-5.7c2.4-4,4.3-8.3,5.6-12.8C806,979.7,807.4,974,808.1,968.1z"/>
							<path opacity="0.1091" fill="#131313" d="M767.2,955.4c-1.1-2.1-1.4-4.3-0.9-6.6c-0.5,2.3-1,4.5-1.5,6.8c0.3-1,0.7-2,1-3.1
								c0.6-2.2,1.2-4.3,1.9-6.5c-0.3-1.3-0.6-2.7-0.9-4c-0.9-0.9-1.9-1.7-2.8-2.6c-2-1.1-3.9-1.1-5.6,0.1c-1.9,0.5-3.2,1.7-3.9,3.5
								c-1.8,2.1-3.2,4.3-4.3,6.8c-1.7,2.7-3.1,5.5-4.2,8.4c-1.8,3.4-3,7-3.8,10.8c-0.5,3.4-0.3,6.9,0.6,10.2c0.8,2.7,1.7,5.4,2.8,8
								c1.8,2.2,4.1,3,7,2.4c2.7-1,4.4-2.8,5.3-5.4c2.4-3.9,4.2-8,5.4-12.4C765.3,966.6,766.6,961.1,767.2,955.4z"/>
							<path opacity="0.1091" fill="#131313" d="M784.2,949.1c-0.3-1.2-0.7-2.4-1-3.6c0.4,1.5,0.7,3,1.1,4.6c0.1-1,0.2-2.1,0.3-3.1
								c-0.6,2.7-1.3,5.3-1.9,8c1.1-3.5,2.2-7.1,3.4-10.6c-2.4,6-4.8,12-7.1,18c3.7-6.5,6.9-13.2,9.5-20.2c-0.1-1.5-0.1-3-0.2-4.6
								c-1.2-2.7-3.3-4-6.3-3.9c-3,0.6-5.2,2.3-6.8,4.9c-6.5,7.6-12,16.5-15.8,25.7c-3,5.7-3.1,11.8-0.4,18.4c0.4,3,1.3,5.7,2.8,8.3
								c1.7,2.4,4.1,3.3,7,2.4c3.9-2.2,6.4-5.5,7.6-10c3.1-7.1,5.6-14.3,7.6-21.8C785.8,957.4,785.9,953.2,784.2,949.1z"/>
							<path opacity="0.1091" fill="#131313" d="M805.7,982.6c-1.1-2.5-1.3-5-0.5-7.6c-0.4,1.9-0.7,3.8-1.1,5.7
								c0.2-0.8,0.5-1.6,0.7-2.4c-1.1,2.5-2.3,5-3.4,7.5c2.6-3.9,4.7-8.1,6.2-12.5c1.3-2.1,1.5-4.4,0.5-6.7
								c-0.9-1.9-2.4-3.1-4.4-3.4c-1.8-1-3.7-0.8-5.6,0.5c-1.4,1.5-2.9,3-4.3,4.4c-3.5,4.6-6.6,9.4-9.2,14.6
								c-0.6,4.2-1.2,8.3-1.7,12.5c0.3,2.7,1,5.3,2.2,7.8c0.6,2.7,2.1,4.5,4.7,5.4c2.6,1,4.9,0.6,7.1-1.2c2.7-3.6,4.6-7.5,5.9-11.8
								C804.6,991.3,805.5,987,805.7,982.6z"/>
							<path opacity="0.1091" fill="#131313" d="M760.4,980.1c-0.1-1.9-0.2-3.9-0.3-5.8c-0.1,1.4-0.1,2.7-0.2,4.1
								c0.2-1.8,0.5-3.5,0.7-5.3c-0.6,1.8-1.2,3.5-1.8,5.3c0.5-1.1,0.9-2.2,1.4-3.2c-0.9,1.5-1.9,3.1-2.8,4.6
								c2.9-3.8,5.4-7.9,7.4-12.2c1.4-2.3,1.3-4.6-0.3-6.7c-0.9-0.8-1.9-1.6-2.8-2.5c-2-1.4-4.2-1.9-6.6-1.3
								c-1.9,1.2-3.5,2.7-4.6,4.6c-2.5,3.2-4.6,6.7-6.3,10.5c-1.5,3-2.2,6.1-2.2,9.3c-0.4,4.1-0.3,8.1,0.4,12.2
								c1,2.2,2.7,3.7,5.1,4.4c2.3,1,4.5,0.9,6.7-0.3c2.2-2.4,3.7-5.1,4.6-8.3C760.1,986.4,760.6,983.3,760.4,980.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M772.4,975.5c-0.1-4.3-0.2-8.6-0.3-12.9c-0.1-4.7-6.7-8.1-10.8-6.4
								c-2.8,2.2-5,4.9-6.7,8c-2,3-3.3,6.2-3.8,9.7c0.2,4,0.4,7.9,0.6,11.9c0.2,4.6,6.7,8.1,10.7,6.5c2.7-2,4.9-4.5,6.5-7.6
								C770.7,982,771.9,978.9,772.4,975.5z"/>
							<path opacity="0.1091" fill="#131313" d="M749.2,960c0.1-4.6,0.1-9.1,0.2-13.7c0.1-5-6.6-8.3-10.8-6.4
								c-3,2.4-5.3,5.4-7.1,8.8c-2.1,3.2-3.6,6.7-4.3,10.5c0.1,4.2,0.2,8.4,0.2,12.6c0.1,4.8,6.6,8.2,10.7,6.5
								c2.9-2.3,5.2-5,6.9-8.3C747.2,966.9,748.5,963.6,749.2,960z"/>
							<path opacity="0.1091" fill="#131313" d="M753.3,918.8c-0.8-3.1-1.6-6.2-2.4-9.3c-1.2-2.7-3.4-4-6.4-3.7
								c-4.3,1.4-7.4,4.2-9.4,8.5c-4.4,6.4-8.3,13.1-11.8,20.1c-2.7,3.8-3.7,7.9-2.8,12.4c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.3-1.3,7.4-4.1,9.4-8.3c4.4-6.3,8.4-12.9,11.8-19.7C753.2,927.3,754.2,923.2,753.3,918.8z"/>
							<path opacity="0.1091" fill="#131313" d="M771.1,946.2c-0.9-3-1.7-5.9-2.6-8.9c-1.4-2.5-3.5-3.7-6.4-3.7
								c-3.7,0.7-6.5,2.8-8.2,6.4c-3.6,5.1-6.8,10.5-9.5,16.1c-2.3,3.3-2.8,6.7-1.6,10.3c0.8,2.8,1.6,5.6,2.4,8.4
								c1.4,2.5,3.5,3.7,6.3,3.8c3.7-0.6,6.5-2.7,8.3-6.3c3.7-5,6.9-10.3,9.6-15.9C771.7,953.2,772.3,949.8,771.1,946.2z"/>
							<path opacity="0.1091" fill="#131313" d="M773.8,914.2c-0.8-3.1-1.7-6.2-2.5-9.3c-1-3.1-3.1-4.4-6.3-3.7
								c-4.9,2.3-8.6,5.9-10.9,11c-5.4,8-10.3,16.4-14.7,25c-3.3,4.5-4.7,9.5-4.3,14.9c0.8,2.7,1.6,5.5,2.4,8.2
								c1,3.1,3.1,4.4,6.3,3.8c4.9-2.2,8.5-5.7,10.9-10.8c5.4-7.9,10.3-16,14.8-24.5C772.7,924.4,774.2,919.5,773.8,914.2z"/>
							<path opacity="0.1091" fill="#131313" d="M735.2,946.9c0-4.4-0.1-8.7-0.1-13.1c0-5-6.5-8.3-10.8-6.3c-3,2.5-5.4,5.5-7.2,9
								c-2.1,3.3-3.6,6.8-4.3,10.7c0.1,4,0.3,8,0.4,12c0.2,4.9,6.5,8.3,10.7,6.4c2.9-2.3,5.3-5.2,7-8.5
								C733.1,954,734.5,950.6,735.2,946.9z"/>
							<path opacity="0.1091" fill="#131313" d="M757,977.6c-0.3-4-0.7-8.1-1-12.1c-0.8-2.2-2.2-3.8-4.3-4.9
								c-1.9-1.4-4.1-1.9-6.4-1.5c-2.3,1.3-4,3.2-5.3,5.5c-1.5,2.2-2.3,4.6-2.4,7.2c0.5,3.8,1,7.6,1.5,11.3c0.9,2.1,2.3,3.8,4.4,4.8
								c1.9,1.3,4,1.9,6.3,1.6c2.2-1.2,3.9-2.9,5-5.1C756.2,982.4,757,980.1,757,977.6z"/>
							<path opacity="0.1091" fill="#131313" d="M785.1,942.6c-0.8-3.1-1.6-6.2-2.4-9.3c-1.2-2.7-3.4-4-6.3-3.7
								c-4.2,1.3-7.3,4-9.3,8.2c-4.3,6.2-8.2,12.8-11.5,19.6c-2.7,3.7-3.6,7.8-2.7,12.1c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.2-1.2,7.3-3.9,9.3-8c4.3-6.1,8.2-12.5,11.6-19.2C785,950.9,785.9,946.9,785.1,942.6z"/>
							<path opacity="0.1091" fill="#131313" d="M744.1,930.2c-0.9-3-1.7-6-2.6-8.9c-1.4-2.4-3.5-3.7-6.4-3.7
								c-3.7,0.7-6.5,2.8-8.2,6.4c-3.6,5.1-6.7,10.4-9.4,16.1c-2.3,3.3-2.8,6.7-1.6,10.3c0.8,2.8,1.6,5.6,2.4,8.4
								c1.4,2.5,3.5,3.7,6.3,3.8c3.7-0.6,6.5-2.7,8.2-6.3c3.6-5,6.8-10.3,9.5-15.9C744.7,937.2,745.3,933.8,744.1,930.2z"/>
							<path opacity="0.1091" fill="#131313" d="M762.3,928.4c-0.8-3.1-1.6-6.2-2.4-9.3c-1.2-2.7-3.4-4-6.4-3.7
								c-4.2,1.3-7.3,4.1-9.3,8.3c-4.3,6.3-8.2,12.9-11.6,19.7c-2.7,3.8-3.6,7.8-2.7,12.2c0.8,2.8,1.5,5.7,2.3,8.5
								c1.2,2.7,3.3,4,6.3,3.8c4.2-1.2,7.3-3.9,9.3-8.1c4.4-6.2,8.2-12.6,11.6-19.4C762.2,936.7,763.1,932.7,762.3,928.4z"/>
							<path opacity="0.1091" fill="#131313" d="M782.5,957.5c-0.9-3-1.7-6-2.6-8.9c-1.5-2.4-3.6-3.6-6.3-3.7
								c-3.6,0.5-6.2,2.4-7.9,5.8c-3.4,4.7-6.3,9.7-8.8,15c-2.2,3.1-2.6,6.4-1.3,9.7c0.8,2.8,1.5,5.7,2.3,8.5
								c1.4,2.4,3.5,3.7,6.3,3.8c3.6-0.4,6.2-2.3,7.9-5.7c3.5-4.6,6.4-9.6,8.9-14.8C783.3,964.1,783.8,960.9,782.5,957.5z"/>
							<path opacity="0.1091" fill="#131313" d="M738.6,957.3c-0.4-4-0.8-7.9-1.1-11.9c-0.4-4.3-6.8-7.9-10.7-6.5
								c-2.6,1.8-4.6,4.1-6.1,6.9c-1.8,2.6-2.9,5.5-3.2,8.6c0.5,3.8,1,7.6,1.5,11.4c0.5,4.2,6.8,7.8,10.7,6.5
								c2.5-1.7,4.5-3.9,5.9-6.7C737.2,963.2,738.3,960.4,738.6,957.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M750.5,1004.2c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C741.2,1001,745.4,1003.5,750.5,1004.2z"/>
							<path opacity="0.1091" fill="#131313" d="M726.1,985.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C719.8,984.7,722.7,985.8,726.1,985.2z"/>
							<path opacity="0.1091" fill="#131313" d="M725.4,951.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C720.6,949.1,722.8,950.5,725.4,951.3z"/>
							<path opacity="0.1091" fill="#131313" d="M747.3,977.9c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C740.1,976.5,743.4,978,747.3,977.9z"/>
							<path opacity="0.1091" fill="#131313" d="M742.5,947.1c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C736.5,943.6,739.3,945.7,742.5,947.1z"/>
							<path opacity="0.1091" fill="#131313" d="M708.5,973.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C703.3,970.9,705.7,972.6,708.5,973.5z"/>
							<path opacity="0.1091" fill="#131313" d="M733.5,1006c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C725.6,1004,729.2,1005.8,733.5,1006z"/>
							<path opacity="0.1091" fill="#131313" d="M761.5,974.6c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C753.5,972.6,757.1,974.4,761.5,974.6z"/>
							<path opacity="0.1091" fill="#131313" d="M716.7,962c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C711.5,959.3,713.9,961,716.7,962z"/>
							<path opacity="0.1091" fill="#131313" d="M733,958.4c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C726.5,954.5,729.6,956.8,733,958.4z"/>
							<path opacity="0.1091" fill="#131313" d="M760.1,990.7c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C751,987.7,755.2,990,760.1,990.7z"/>
							<path opacity="0.1091" fill="#131313" d="M708.4,984.4c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C702.1,980.7,705.1,982.9,708.4,984.4z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M770,1114c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C760.7,1110.8,764.9,1113.2,770,1114z"/>
							<path opacity="0.1091" fill="#131313" d="M745.6,1095c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C739.3,1094.4,742.2,1095.5,745.6,1095z"/>
							<path opacity="0.1091" fill="#131313" d="M745,1061.1c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9c-0.8-2.6-2.2-4.8-4.3-6.7
								c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C740.1,1058.8,742.3,1060.3,745,1061.1z"/>
							<path opacity="0.1091" fill="#131313" d="M766.8,1087.6c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C759.6,1086.3,762.9,1087.8,766.8,1087.6z"/>
							<path opacity="0.1091" fill="#131313" d="M762.1,1056.8c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C756,1053.4,758.8,1055.4,762.1,1056.8z"/>
							<path opacity="0.1091" fill="#131313" d="M728,1083.3c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C722.8,1080.7,725.2,1082.3,728,1083.3z"/>
							<path opacity="0.1091" fill="#131313" d="M753,1115.7c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C745.1,1113.8,748.7,1115.6,753,1115.7z"/>
							<path opacity="0.1091" fill="#131313" d="M781,1084.4c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C773,1082.3,776.6,1084.2,781,1084.4z"/>
							<path opacity="0.1091" fill="#131313" d="M736.2,1071.7c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2
								c-2-2.3-4.4-3.9-7.2-4.9c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C731,1069.1,733.4,1070.7,736.2,1071.7z"/>
							<path opacity="0.1091" fill="#131313" d="M752.5,1068.2c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C746,1064.3,749.1,1066.5,752.5,1068.2z"/>
							<path opacity="0.1091" fill="#131313" d="M779.6,1100.4c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C770.5,1097.5,774.7,1099.8,779.6,1100.4z"/>
							<path opacity="0.1091" fill="#131313" d="M727.9,1094.2c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C721.6,1090.5,724.6,1092.6,727.9,1094.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M874.9,1026.2c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C865.6,1023,869.8,1025.4,874.9,1026.2z"/>
							<path opacity="0.1091" fill="#131313" d="M850.5,1007.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C844.2,1006.6,847.1,1007.7,850.5,1007.2z"/>
							<path opacity="0.1091" fill="#131313" d="M849.8,973.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C845,971,847.2,972.5,849.8,973.3z"/>
							<path opacity="0.1091" fill="#131313" d="M871.7,999.8c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C864.5,998.5,867.8,1000,871.7,999.8z"/>
							<path opacity="0.1091" fill="#131313" d="M866.9,969c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C860.8,965.6,863.7,967.6,866.9,969z"/>
							<path opacity="0.1091" fill="#131313" d="M832.9,995.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C827.7,992.9,830.1,994.5,832.9,995.5z"/>
							<path opacity="0.1091" fill="#131313" d="M857.9,1027.9c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C850,1026,853.6,1027.8,857.9,1027.9z"/>
							<path opacity="0.1091" fill="#131313" d="M885.9,996.6c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C877.9,994.5,881.5,996.4,885.9,996.6z"/>
							<path opacity="0.1091" fill="#131313" d="M841.1,983.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C835.9,981.3,838.3,982.9,841.1,983.9z"/>
							<path opacity="0.1091" fill="#131313" d="M857.4,980.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C850.9,976.5,853.9,978.7,857.4,980.3z"/>
							<path opacity="0.1091" fill="#131313" d="M884.5,1012.6c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C875.4,1009.7,879.6,1012,884.5,1012.6z"/>
							<path opacity="0.1091" fill="#131313" d="M832.8,1006.3c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C826.5,1002.7,829.4,1004.8,832.8,1006.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M662.7,887.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C653.4,884,657.6,886.4,662.7,887.1z"/>
							<path opacity="0.1091" fill="#131313" d="M638.3,868.2c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C632,867.6,634.9,868.7,638.3,868.2z"/>
							<path opacity="0.1091" fill="#131313" d="M637.6,834.3c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C632.8,832,635,833.5,637.6,834.3z"/>
							<path opacity="0.1091" fill="#131313" d="M659.5,860.8c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C652.3,859.5,655.6,861,659.5,860.8z"/>
							<path opacity="0.1091" fill="#131313" d="M654.7,830c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C648.7,826.6,651.5,828.6,654.7,830z"/>
							<path opacity="0.1091" fill="#131313" d="M620.7,856.5c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C615.5,853.9,617.9,855.5,620.7,856.5z"/>
							<path opacity="0.1091" fill="#131313" d="M645.7,888.9c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C637.8,886.9,641.4,888.8,645.7,888.9z"/>
							<path opacity="0.1091" fill="#131313" d="M673.7,857.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C665.7,855.5,669.3,857.4,673.7,857.5z"/>
							<path opacity="0.1091" fill="#131313" d="M628.9,844.9c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C623.7,842.2,626.1,843.9,628.9,844.9z"/>
							<path opacity="0.1091" fill="#131313" d="M645.2,841.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C638.7,837.5,641.8,839.7,645.2,841.3z"/>
							<path opacity="0.1091" fill="#131313" d="M672.3,873.6c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C663.2,870.6,667.4,873,672.3,873.6z"/>
							<path opacity="0.1091" fill="#131313" d="M620.6,867.3c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C614.3,863.7,617.2,865.8,620.6,867.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M696.8,928.6c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C687.6,925.4,691.8,927.9,696.8,928.6z"/>
							<path opacity="0.1091" fill="#131313" d="M672.4,909.6c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C666.1,909.1,669,910.2,672.4,909.6z"/>
							<path opacity="0.1091" fill="#131313" d="M671.8,875.7c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C666.9,873.5,669.2,874.9,671.8,875.7z"/>
							<path opacity="0.1091" fill="#131313" d="M693.7,902.3c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C686.4,900.9,689.7,902.4,693.7,902.3z"/>
							<path opacity="0.1091" fill="#131313" d="M688.9,871.5c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C682.8,868,685.6,870.1,688.9,871.5z"/>
							<path opacity="0.1091" fill="#131313" d="M654.9,897.9c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C649.7,895.3,652.1,896.9,654.9,897.9z"/>
							<path opacity="0.1091" fill="#131313" d="M679.9,930.4c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C671.9,928.4,675.5,930.2,679.9,930.4z"/>
							<path opacity="0.1091" fill="#131313" d="M707.9,899c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C699.8,897,703.5,898.8,707.9,899z"/>
							<path opacity="0.1091" fill="#131313" d="M663.1,886.3c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C657.8,883.7,660.2,885.3,663.1,886.3z"/>
							<path opacity="0.1091" fill="#131313" d="M679.4,882.8c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C672.9,878.9,675.9,881.2,679.4,882.8z"/>
							<path opacity="0.1091" fill="#131313" d="M706.5,915.1c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C697.4,912.1,701.5,914.4,706.5,915.1z"/>
							<path opacity="0.1091" fill="#131313" d="M654.7,908.8c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C648.5,905.1,651.4,907.3,654.7,908.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M772.4,1167.6c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C763.2,1164.5,767.4,1166.9,772.4,1167.6z"/>
							<path opacity="0.1091" fill="#131313" d="M748,1148.7c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C741.7,1148.1,744.6,1149.2,748,1148.7z"/>
							<path opacity="0.1091" fill="#131313" d="M747.4,1114.7c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C742.5,1112.5,744.8,1113.9,747.4,1114.7z"/>
							<path opacity="0.1091" fill="#131313" d="M769.3,1141.3c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C762,1140,765.3,1141.5,769.3,1141.3z"/>
							<path opacity="0.1091" fill="#131313" d="M764.5,1110.5c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C758.4,1107.1,761.2,1109.1,764.5,1110.5z"/>
							<path opacity="0.1091" fill="#131313" d="M730.5,1136.9c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2
								c-2-2.2-4.4-3.8-7.2-4.8c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C725.3,1134.4,727.7,1136,730.5,1136.9z"/>
							<path opacity="0.1091" fill="#131313" d="M755.5,1169.4c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C747.5,1167.4,751.2,1169.2,755.5,1169.4z"/>
							<path opacity="0.1091" fill="#131313" d="M783.5,1138c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C775.4,1136,779.1,1137.8,783.5,1138z"/>
							<path opacity="0.1091" fill="#131313" d="M738.7,1125.4c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2
								c-2-2.3-4.4-3.9-7.2-4.9c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C733.4,1122.7,735.9,1124.4,738.7,1125.4z"/>
							<path opacity="0.1091" fill="#131313" d="M755,1121.8c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C748.5,1118,751.5,1120.2,755,1121.8z"/>
							<path opacity="0.1091" fill="#131313" d="M782.1,1154.1c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C773,1151.1,777.1,1153.4,782.1,1154.1z"/>
							<path opacity="0.1091" fill="#131313" d="M730.4,1147.8c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C724.1,1144.1,727,1146.3,730.4,1147.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M772.4,1167.6c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C763.2,1164.5,767.4,1166.9,772.4,1167.6z"/>
							<path opacity="0.1091" fill="#131313" d="M748,1148.7c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C741.7,1148.1,744.6,1149.2,748,1148.7z"/>
							<path opacity="0.1091" fill="#131313" d="M747.4,1114.7c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C742.5,1112.5,744.8,1113.9,747.4,1114.7z"/>
							<path opacity="0.1091" fill="#131313" d="M769.3,1141.3c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C762,1140,765.3,1141.5,769.3,1141.3z"/>
							<path opacity="0.1091" fill="#131313" d="M764.5,1110.5c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C758.4,1107.1,761.2,1109.1,764.5,1110.5z"/>
							<path opacity="0.1091" fill="#131313" d="M730.5,1136.9c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2
								c-2-2.2-4.4-3.8-7.2-4.8c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C725.3,1134.4,727.7,1136,730.5,1136.9z"/>
							<path opacity="0.1091" fill="#131313" d="M755.5,1169.4c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C747.5,1167.4,751.2,1169.2,755.5,1169.4z"/>
							<path opacity="0.1091" fill="#131313" d="M783.5,1138c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C775.4,1136,779.1,1137.8,783.5,1138z"/>
							<path opacity="0.1091" fill="#131313" d="M738.7,1125.4c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2
								c-2-2.3-4.4-3.9-7.2-4.9c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C733.4,1122.7,735.9,1124.4,738.7,1125.4z"/>
							<path opacity="0.1091" fill="#131313" d="M755,1121.8c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C748.5,1118,751.5,1120.2,755,1121.8z"/>
							<path opacity="0.1091" fill="#131313" d="M782.1,1154.1c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C773,1151.1,777.1,1153.4,782.1,1154.1z"/>
							<path opacity="0.1091" fill="#131313" d="M730.4,1147.8c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C724.1,1144.1,727,1146.3,730.4,1147.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M699.3,689.6c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C690,686.4,694.2,688.8,699.3,689.6z"/>
							<path opacity="0.1091" fill="#131313" d="M674.9,670.6c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C668.5,670,671.4,671.1,674.9,670.6z"/>
							<path opacity="0.1091" fill="#131313" d="M674.2,636.7c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C669.4,634.5,671.6,635.9,674.2,636.7z"/>
							<path opacity="0.1091" fill="#131313" d="M696.1,663.3c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C688.9,661.9,692.2,663.4,696.1,663.3z"/>
							<path opacity="0.1091" fill="#131313" d="M691.3,632.5c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C685.2,629,688.1,631,691.3,632.5z"/>
							<path opacity="0.1091" fill="#131313" d="M657.3,658.9c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C652.1,656.3,654.5,657.9,657.3,658.9z"/>
							<path opacity="0.1091" fill="#131313" d="M682.3,691.3c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C674.4,689.4,678,691.2,682.3,691.3z"/>
							<path opacity="0.1091" fill="#131313" d="M710.3,660c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11c-4.9-5.7-10.2-11-16-15.9
								c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C702.3,657.9,705.9,659.8,710.3,660z"/>
							<path opacity="0.1091" fill="#131313" d="M665.5,647.3c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C660.3,644.7,662.7,646.3,665.5,647.3z"/>
							<path opacity="0.1091" fill="#131313" d="M681.8,643.8c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C675.3,639.9,678.3,642.1,681.8,643.8z"/>
							<path opacity="0.1091" fill="#131313" d="M708.9,676.1c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C699.8,673.1,703.9,675.4,708.9,676.1z"/>
							<path opacity="0.1091" fill="#131313" d="M657.2,669.8c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C650.9,666.1,653.8,668.2,657.2,669.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M728.5,633.5c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C719.3,630.3,723.5,632.7,728.5,633.5z"/>
							<path opacity="0.1091" fill="#131313" d="M704.1,614.5c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C697.8,613.9,700.7,615,704.1,614.5z"/>
							<path opacity="0.1091" fill="#131313" d="M703.5,580.6c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C698.6,578.4,700.9,579.8,703.5,580.6z"/>
							<path opacity="0.1091" fill="#131313" d="M725.4,607.2c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C718.1,605.8,721.4,607.3,725.4,607.2z"/>
							<path opacity="0.1091" fill="#131313" d="M720.6,576.4c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C714.5,572.9,717.3,574.9,720.6,576.4z"/>
							<path opacity="0.1091" fill="#131313" d="M686.6,602.8c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C681.4,600.2,683.8,601.8,686.6,602.8z"/>
							<path opacity="0.1091" fill="#131313" d="M711.6,635.2c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C703.6,633.3,707.2,635.1,711.6,635.2z"/>
							<path opacity="0.1091" fill="#131313" d="M739.6,603.9c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C731.5,601.9,735.2,603.7,739.6,603.9z"/>
							<path opacity="0.1091" fill="#131313" d="M694.8,591.2c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C689.5,588.6,692,590.2,694.8,591.2z"/>
							<path opacity="0.1091" fill="#131313" d="M711.1,587.7c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C704.6,583.8,707.6,586,711.1,587.7z"/>
							<path opacity="0.1091" fill="#131313" d="M738.2,620c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C729.1,617,733.2,619.3,738.2,620z"/>
							<path opacity="0.1091" fill="#131313" d="M686.5,613.7c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C680.2,610,683.1,612.1,686.5,613.7z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M801.7,662.7c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C792.4,659.6,796.7,662,801.7,662.7z"/>
							<path opacity="0.1091" fill="#131313" d="M777.3,643.8c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C771,643.2,773.9,644.3,777.3,643.8z"/>
							<path opacity="0.1091" fill="#131313" d="M776.7,609.9c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C771.8,607.6,774,609.1,776.7,609.9z"/>
							<path opacity="0.1091" fill="#131313" d="M798.5,636.4c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C791.3,635.1,794.6,636.6,798.5,636.4z"/>
							<path opacity="0.1091" fill="#131313" d="M793.8,605.6c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C787.7,602.2,790.5,604.2,793.8,605.6z"/>
							<path opacity="0.1091" fill="#131313" d="M759.7,632.1c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C754.5,629.5,756.9,631.1,759.7,632.1z"/>
							<path opacity="0.1091" fill="#131313" d="M784.7,664.5c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C776.8,662.6,780.4,664.4,784.7,664.5z"/>
							<path opacity="0.1091" fill="#131313" d="M812.7,633.1c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C804.7,631.1,808.4,633,812.7,633.1z"/>
							<path opacity="0.1091" fill="#131313" d="M767.9,620.5c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C762.7,617.9,765.1,619.5,767.9,620.5z"/>
							<path opacity="0.1091" fill="#131313" d="M784.2,616.9c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C777.8,613.1,780.8,615.3,784.2,616.9z"/>
							<path opacity="0.1091" fill="#131313" d="M811.3,649.2c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C802.2,646.2,806.4,648.6,811.3,649.2z"/>
							<path opacity="0.1091" fill="#131313" d="M759.6,642.9c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C753.4,639.3,756.3,641.4,759.6,642.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M884.6,674.9c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C875.4,671.8,879.6,674.2,884.6,674.9z"/>
							<path opacity="0.1091" fill="#131313" d="M860.2,656c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C853.9,655.4,856.8,656.5,860.2,656z"/>
							<path opacity="0.1091" fill="#131313" d="M859.6,622.1c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C854.7,619.8,857,621.3,859.6,622.1z"/>
							<path opacity="0.1091" fill="#131313" d="M881.5,648.6c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C874.2,647.3,877.5,648.8,881.5,648.6z"/>
							<path opacity="0.1091" fill="#131313" d="M876.7,617.8c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C870.6,614.4,873.4,616.4,876.7,617.8z"/>
							<path opacity="0.1091" fill="#131313" d="M842.7,644.3c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C837.5,641.7,839.9,643.3,842.7,644.3z"/>
							<path opacity="0.1091" fill="#131313" d="M867.7,676.7c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C859.7,674.7,863.3,676.6,867.7,676.7z"/>
							<path opacity="0.1091" fill="#131313" d="M895.7,645.3c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C887.6,643.3,891.3,645.2,895.7,645.3z"/>
							<path opacity="0.1091" fill="#131313" d="M850.9,632.7c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C845.6,630.1,848.1,631.7,850.9,632.7z"/>
							<path opacity="0.1091" fill="#131313" d="M867.2,629.1c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C860.7,625.3,863.7,627.5,867.2,629.1z"/>
							<path opacity="0.1091" fill="#131313" d="M894.3,661.4c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C885.2,658.4,889.3,660.8,894.3,661.4z"/>
							<path opacity="0.1091" fill="#131313" d="M842.6,655.1c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C836.3,651.5,839.2,653.6,842.6,655.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M855.4,570.1c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C846.1,566.9,850.3,569.3,855.4,570.1z"/>
							<path opacity="0.1091" fill="#131313" d="M831,551.1c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C824.6,550.5,827.5,551.6,831,551.1z"/>
							<path opacity="0.1091" fill="#131313" d="M830.3,517.2c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C825.5,514.9,827.7,516.4,830.3,517.2z"/>
							<path opacity="0.1091" fill="#131313" d="M852.2,543.7c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C844.9,542.4,848.3,543.9,852.2,543.7z"/>
							<path opacity="0.1091" fill="#131313" d="M847.4,512.9c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C841.3,509.5,844.2,511.5,847.4,512.9z"/>
							<path opacity="0.1091" fill="#131313" d="M813.4,539.4c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C808.2,536.8,810.6,538.4,813.4,539.4z"/>
							<path opacity="0.1091" fill="#131313" d="M838.4,571.8c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C830.5,569.9,834.1,571.7,838.4,571.8z"/>
							<path opacity="0.1091" fill="#131313" d="M866.4,540.5c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C858.4,538.4,862,540.3,866.4,540.5z"/>
							<path opacity="0.1091" fill="#131313" d="M821.6,527.8c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C816.4,525.2,818.8,526.8,821.6,527.8z"/>
							<path opacity="0.1091" fill="#131313" d="M837.9,524.3c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C831.4,520.4,834.4,522.6,837.9,524.3z"/>
							<path opacity="0.1091" fill="#131313" d="M865,556.5c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C855.9,553.6,860,555.9,865,556.5z"/>
							<path opacity="0.1091" fill="#131313" d="M813.3,550.2c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C807,546.6,809.9,548.7,813.3,550.2z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M660.2,521.3c3.2-0.3,4.9-2,5.2-5.2c-0.7-5.1-3.1-9.3-7.2-12.7
								c-6-6.8-12.4-13.2-19.1-19.2c-3.4-4.1-7.6-6.5-12.6-7.3c-3.2,0.3-4.9,2-5.2,5.2c0.7,5.1,3.1,9.3,7.2,12.7
								c6,6.8,12.4,13.2,19.1,19.2C651,518.1,655.2,520.5,660.2,521.3z"/>
							<path opacity="0.1091" fill="#131313" d="M635.8,502.3c2.6-0.9,4.3-2.6,5.2-5.2c0.5-3.4-0.6-6.3-3.3-8.7
								c-3.5-4.2-7.3-8.1-11.5-11.6c-2.4-2.7-5.3-3.8-8.7-3.3c-2.6,0.9-4.3,2.6-5.2,5.2c-0.5,3.4,0.6,6.3,3.3,8.7
								c3.5,4.2,7.3,8.1,11.5,11.6C629.5,501.7,632.4,502.8,635.8,502.3z"/>
							<path opacity="0.1091" fill="#131313" d="M635.2,468.4c2.3-0.2,4.3-1.2,5.9-3c1.8-1.6,2.8-3.5,3-5.9
								c-0.8-2.6-2.2-4.8-4.3-6.7c-1.8-2.1-4.1-3.5-6.7-4.3c-2.3,0.2-4.3,1.2-5.9,3c-1.8,1.6-2.8,3.5-3,5.9c0.8,2.6,2.2,4.8,4.3,6.7
								C630.4,466.2,632.6,467.6,635.2,468.4z"/>
							<path opacity="0.1091" fill="#131313" d="M657.1,495c2.8-0.7,4.5-2.4,5.2-5.2c0.2-3.9-1.4-7.3-4.5-9.9
								c-4.2-5-8.9-9.7-13.9-13.9c-2.7-3.2-6-4.7-9.9-4.5c-2.8,0.7-4.5,2.4-5.2,5.2c-0.2,3.9,1.4,7.3,4.5,9.9
								c4.2,5,8.9,9.7,13.9,13.9C649.8,493.6,653.1,495.1,657.1,495z"/>
							<path opacity="0.1091" fill="#131313" d="M652.3,464.2c2.5,0.2,4.6-0.6,6.4-2.5c1.9-1.7,2.7-3.9,2.5-6.4
								c-1.4-3.3-3.5-6.1-6.1-8.5c-2.4-2.7-5.2-4.7-8.5-6.1c-2.5-0.2-4.6,0.6-6.4,2.5c-1.9,1.7-2.7,3.9-2.5,6.4
								c1.4,3.3,3.5,6.1,6.1,8.5C646.2,460.7,649,462.7,652.3,464.2z"/>
							<path opacity="0.1091" fill="#131313" d="M618.3,490.6c4.1,0,9.2-4.4,8.9-8.8c-1-2.8-2.6-5.2-4.8-7.2c-2-2.2-4.4-3.8-7.2-4.8
								c-4.1,0-9.2,4.4-8.9,8.8c1,2.8,2.6,5.2,4.8,7.2C613.1,488,615.5,489.6,618.3,490.6z"/>
							<path opacity="0.1091" fill="#131313" d="M643.3,523.1c2.9-0.6,4.7-2.3,5.2-5.2c-0.1-4.3-1.9-7.9-5.4-10.9
								c-4.8-5.6-10-10.9-15.6-15.7c-2.9-3.5-6.5-5.3-10.8-5.5c-2.9,0.6-4.7,2.3-5.2,5.2c0.1,4.3,1.9,7.9,5.4,10.9
								c4.8,5.6,10,10.9,15.6,15.7C635.3,521.1,639,522.9,643.3,523.1z"/>
							<path opacity="0.1091" fill="#131313" d="M671.3,491.7c2.9-0.5,4.7-2.3,5.2-5.2c-0.2-4.4-2-8-5.6-11
								c-4.9-5.7-10.2-11-16-15.9c-2.9-3.5-6.6-5.4-11-5.6c-2.9,0.5-4.7,2.3-5.2,5.2c0.2,4.4,2,8,5.6,11c4.9,5.7,10.2,11,16,15.9
								C663.2,489.7,666.9,491.5,671.3,491.7z"/>
							<path opacity="0.1091" fill="#131313" d="M626.5,479c4.2,0,9.3-4.4,8.9-8.9c-1-2.8-2.6-5.2-4.9-7.2c-2-2.3-4.4-3.9-7.2-4.9
								c-4.2,0-9.3,4.4-8.9,8.9c1,2.8,2.6,5.2,4.9,7.2C621.2,476.4,623.7,478,626.5,479z"/>
							<path opacity="0.1091" fill="#131313" d="M642.8,475.5c2.6,0.4,4.7-0.4,6.5-2.3c1.9-1.8,2.7-4,2.3-6.5
								c-1.6-3.5-3.9-6.5-6.7-9.1c-2.6-2.9-5.6-5.1-9.1-6.7c-2.6-0.4-4.7,0.4-6.5,2.3c-1.9,1.8-2.7,4-2.3,6.5
								c1.6,3.5,3.9,6.5,6.7,9.1C636.3,471.6,639.3,473.9,642.8,475.5z"/>
							<path opacity="0.1091" fill="#131313" d="M669.9,507.8c3.1-0.3,4.9-2.1,5.2-5.2c-0.7-5-3-9.1-7-12.4
								c-5.8-6.6-12.1-12.8-18.7-18.7c-3.3-4-7.5-6.3-12.4-7c-3.1,0.3-4.9,2.1-5.2,5.2c0.7,5,3,9.1,7,12.4
								c5.8,6.6,12.1,12.8,18.7,18.7C660.8,504.8,664.9,507.1,669.9,507.8z"/>
							<path opacity="0.1091" fill="#131313" d="M618.2,501.5c2.5,0.3,4.7-0.5,6.4-2.4c1.9-1.7,2.7-3.9,2.4-6.4
								c-1.5-3.4-3.6-6.3-6.4-8.8c-2.5-2.8-5.4-4.9-8.7-6.4c-2.5-0.3-4.7,0.5-6.4,2.4c-1.9,1.7-2.7,3.9-2.4,6.4
								c1.5,3.4,3.6,6.3,6.4,8.8C611.9,497.8,614.8,499.9,618.2,501.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M182.8,1028.4c4.5-8.5,7.8-17.5,9.9-26.9c2.6-9.8,4.7-19.8,6.1-29.9
								c1.5-10.5,2.5-21,2.9-31.5c0.4-10.8,0.2-21.6-0.6-32.4c-0.8-4.2-3.1-7.3-6.8-9.4c-4.8-1.8-9.3-1-13.5,2.3
								c-6.9,3.9-13.3,8.5-19.2,13.9c-4.5,2.9-6.7,7-6.4,12.1c-0.3,9.9-1.1,19.7-2.5,29.6c-0.9,7.3-2.3,14.5-3.9,21.7
								c-1.4,7.1-3.2,14.2-5.3,21.2c-2.6,8.9-5.6,17.6-8.7,26.3c0.8-1.8,1.7-3.6,2.5-5.5c-2.8,5.9-5.6,11.7-8.3,17.6
								c-0.4,4.3,1,7.9,4.3,10.7c4.7,2.9,9.6,3,14.9,0.5c8.4-2.4,16.5-5.7,24.3-9.7C177.9,1037.3,181.3,1033.7,182.8,1028.4z"/>
							<path opacity="0.1091" fill="#131313" d="M141.1,1008.7c4.3-5.4,8.6-10.8,13-16.2c0.5-1.7,1-3.4,1.5-5
								c3.4-11.7,6.1-23.6,8.1-35.5c1.8-10.3,2.9-20.7,3.5-31.2c0.4-5.3,0.6-10.7,0.4-16c0.1-8.1-0.2-16.2-0.9-24.3
								c-1-4-3.3-7.1-6.8-9.4c-4.1-2.1-8.2-1.6-12,1.3c-6,3.3-11.4,7.2-16.3,11.9c-4,2.8-5.6,6.4-4.9,11c0.5,9.6,0.5,19.3-0.1,28.9
								c-0.4,7.2-1.1,14.4-2.3,21.5c-0.9,7.1-2.2,14.2-3.9,21.1c-2.2,8.5-4.4,17.1-6.6,25.6c0.5-1.7,1-3.4,1.5-5
								c-3.8,5-7.5,10-11.3,15c-1.9,3.5-2,7.1-0.3,10.7c1.3,3.8,3.9,6.3,7.6,7.5c5.5,0,10.8-1.2,15.9-3.6
								C132.5,1015.4,137.1,1012.6,141.1,1008.7z"/>
							<path opacity="0.1091" fill="#131313" d="M152.6,948.1c4.6-8.5,7.9-17.5,10-26.9c2.6-9.9,4.7-19.8,6.1-30
								c1.5-10.4,2.3-20.9,2.6-31.5c0.3-10.8,0-21.6-0.8-32.3c-0.5-4.4-2.7-7.6-6.8-9.4c-5.9-1.3-11.2,0.1-16,4.2
								c-8.5,5.1-16.6,11-24.1,17.5c-5.4,3.3-8.4,7.9-9,14c0.9,8.8,1.2,17.5,1.1,26.3c0,6.7-0.5,13.3-1.3,19.9
								c-0.6,6.7-1.6,13.3-3,19.9c-1.8,8.6-4.1,17.1-6.8,25.4c0.8-1.8,1.7-3.7,2.5-5.5c-2.7,5.8-5.4,11.7-8.1,17.5
								c-0.4,4.3,1.1,7.9,4.3,10.7c4.7,2.8,9.6,3,14.8,0.5c8.4-2.5,16.4-5.7,24.1-9.8C147.8,956.9,151.2,953.4,152.6,948.1z"/>
							<path opacity="0.1091" fill="#131313" d="M181.2,989.9c3.6-8.7,6.6-17.6,9.1-26.6c2.6-9.8,4.6-19.8,6-29.9
								c1.5-10.4,2.3-20.9,2.6-31.4c0.3-10.8,0-21.5-0.8-32.2c-0.4-4.5-2.6-7.6-6.8-9.4c-6.1-1.2-11.6,0.3-16.5,4.5
								c-8.8,5.3-17.2,11.3-25,18.1c-5.6,3.4-8.7,8.1-9.5,14.3c0,9.2-0.6,18.4-1.6,27.6c-1,9.2-2.5,18.3-4.4,27.3
								c-1.9,9-4.3,17.9-7.2,26.7c-2.7,8.1-5.7,16-9,23.9c-0.7,4.4,0.7,8,4.3,10.7c5.6,2.7,11.4,2.5,17.2-0.4
								c9.9-3,19.5-6.9,28.8-11.6C174.8,999.6,179,995.7,181.2,989.9z"/>
							<path opacity="0.1091" fill="#131313" d="M185,943.4c3.2-8.8,6.2-17.8,8.9-26.8c2.7-9.9,4.8-20,6.3-30.2
								c1.5-10.6,2.5-21.2,2.9-31.8c0.4-10.9,0.2-21.9-0.5-32.8c0.2-4.9-2-8.1-6.8-9.4c-7.8-0.3-14.7,2.2-20.8,7.6
								c-11.6,7.3-22.6,15.3-33.2,23.9c-7,4.1-11.6,9.8-13.7,17.3c0.3,8.5,0.2,16.9-0.4,25.4c-0.6,8.6-1.6,17.1-3.1,25.6
								c-1.5,8.7-3.6,17.2-6.1,25.6c-2.8,7.7-5.5,15.4-7.9,23.2c-1.1,4.6,0.3,8.2,4.3,10.7c6.8,2.3,13.5,1.8,20.1-1.7
								c11.8-3.8,23.3-8.5,34.4-13.9C176.6,954.1,181.8,949.8,185,943.4z"/>
							<path opacity="0.1091" fill="#131313" d="M118.3,989.5c4.3-5.5,8.7-11,13-16.5c2.9-7.1,5.3-14.5,7-22
								c2.4-9.1,4.4-18.4,5.6-27.8c0.7-5.3,1.3-10.5,1.9-15.8c0.8-8.1,1.2-16.2,1.1-24.3c0.3-8,0-15.9-0.9-23.8
								c-1.2-3.9-3.4-7-6.7-9.4c-3.6-2.3-7.2-2.2-10.8,0.3c-5.2,2.6-9.9,6-14.1,10c-3.5,2.6-4.8,6-3.8,10.1c1,9.3,1.4,18.6,1.1,27.9
								c-0.2,9.7-1,19.4-2.3,29.1c0.2-1.6,0.4-3.2,0.7-4.8c-1.3,9.6-3.2,19.1-5.8,28.5c-1.8,11.3-6.8,21-15,29.2
								c-1.1,3.5-0.8,6.9,0.8,10.3c1.2,3.5,3.3,6.2,6.6,8c4.1,0.5,8-0.1,11.8-2C112.4,995,115.7,992.7,118.3,989.5z"/>
							<path opacity="0.1091" fill="#131313" d="M151,1040c4.6-5.6,9.2-11.2,13.8-16.8c2.8-5.3,4.8-10.9,6.1-16.7
								c1.3-5.6,2.7-11.1,3.8-16.7c2-10.5,3.4-21.1,4.4-31.7c0.8-8.2,1-16.3,0.9-24.5c0.1-8.2-0.2-16.4-1.1-24.5
								c-1.1-3.9-3.3-7.1-6.5-9.5c-3.4-2.3-6.9-2.4-10.6,0c-5.1,2.4-9.7,5.6-13.8,9.4c-3.5,2.5-4.7,5.8-3.7,9.9
								c0.4,10.2,0.2,20.4-0.6,30.5c0.2-1.7,0.3-3.3,0.5-5c-0.7,6.8-1.4,13.6-2.1,20.4c0.2-1.7,0.5-3.3,0.7-5
								c-1.1,6.7-2.1,13.5-3.2,20.2c0.3-1.7,0.6-3.3,0.9-5c-1.4,6.7-2.8,13.4-4.1,20.2c0.3-1.7,0.7-3.3,1-5
								c-1.2,6.8-3.2,13.3-5.8,19.7c-2.5,8.7-7.3,16.2-14.4,22.5c-1.5,3.5-1.5,7,0.2,10.5c1.2,3.7,3.6,6.3,7.2,7.7
								c4.9,0.2,9.7-0.7,14.2-2.9C143.5,1046.1,147.6,1043.5,151,1040z"/>
							<path opacity="0.1091" fill="#131313" d="M206.1,983.1c2.9-8.8,5.8-17.7,8.5-26.6c2.6-9.9,4.5-19.9,5.9-30
								c1.4-10.4,2.2-20.9,2.4-31.5c0.2-10.8-0.2-21.5-1.1-32.3c0.2-4.9-2.1-8-6.7-9.4c-7.5-0.4-14.3,1.9-20.2,7.1
								c-11.2,7-21.9,14.7-32.1,23.1c-6.8,4-11.2,9.6-13.2,16.9c-0.4,9-1.3,17.9-2.6,26.8c-1.3,8.9-3,17.7-5.2,26.5
								c-2.1,8.8-4.7,17.5-7.7,26.1c-3.3,7.5-6,15.2-8.1,23.1c-1.4,4.7,0.1,8.3,4.3,10.7c7.3,2.2,14.5,1.5,21.6-2.2
								c12.7-4.2,25.2-9.2,37.3-15C196.6,994.2,202.3,989.7,206.1,983.1z"/>
							<path opacity="0.1091" fill="#131313" d="M131.1,972.2c4.4-5.4,8.8-10.8,13.2-16.3c0.6-1.7,1.2-3.4,1.8-5.1
								c1-4.4,2.1-8.7,3.1-13.1c2-7.3,3.6-14.8,4.6-22.3c1.7-10.3,2.8-20.6,3.3-31c0.4-5.3,0.5-10.6,0.3-15.9
								c0.1-8.1-0.2-16.1-1-24.1c-0.8-4.2-3.1-7.3-6.8-9.4c-4.8-1.8-9.3-1-13.5,2.4c-6.9,4-13.4,8.7-19.3,14c-4.5,3-6.7,7-6.5,12.1
								c0.9,9.1,1.4,18.2,1.2,27.3c0,6.9-0.5,13.7-1.3,20.6c-0.6,6.9-1.6,13.7-3,20.5c-2,8.3-3.9,16.7-5.8,25
								c0.6-1.7,1.2-3.4,1.8-5.1c-3.8,5-7.6,10-11.4,15c-4.7,6.2,0.4,16.6,7.4,18.2c5.9-0.2,11.6-1.5,17-4
								C121.7,979.1,126.7,976.2,131.1,972.2z"/>
							<path opacity="0.1091" fill="#131313" d="M167.1,962c3.7-8.7,6.8-17.6,9.2-26.7c2.7-9.8,4.7-19.8,6.1-29.9
								c1.5-10.4,2.4-20.9,2.7-31.5c0.3-10.8,0.1-21.6-0.7-32.3c-0.3-4.6-2.6-7.7-6.8-9.4c-6.4-1.1-12.1,0.6-17.2,5
								c-9.3,5.6-18,12-26.3,19.1c-5.8,3.5-9.2,8.4-10.1,14.8c0.4,8.8,0.4,17.6-0.1,26.5c-0.5,8.9-1.5,17.8-3.1,26.5
								c-1.5,8.9-3.6,17.7-6.2,26.3c-2.5,8.1-5.4,16-8.7,23.8c-0.7,4.4,0.8,8,4.3,10.7c5.5,2.7,11,2.6,16.7-0.3
								c9.6-2.9,18.9-6.7,27.9-11.3C161,971.6,165.1,967.8,167.1,962z"/>
							<path opacity="0.1091" fill="#131313" d="M199.8,1007.5c3.4-8.7,6.4-17.6,8.9-26.6c2.6-9.8,4.5-19.8,5.9-29.8
								c1.4-10.4,2.2-20.8,2.4-31.3c0.2-10.7-0.1-21.4-1-32.1c-0.3-4.6-2.5-7.7-6.8-9.4c-6.4-1.1-12.1,0.6-17.2,5
								c-9.3,5.6-18.1,12-26.5,19c-5.8,3.5-9.2,8.4-10.2,14.8c-0.5,9.4-1.5,18.8-3,28.1c-1.5,9.3-3.3,18.4-5.6,27.5
								c-2.3,9-5,18-8.1,26.8c-3.1,7.9-6.1,15.8-9,23.7c-1,4.6,0.4,8.1,4.3,10.7c6.3,2.5,12.7,2.1,19-1.1
								c11.1-3.5,21.8-7.8,32.3-12.9C192.1,1017.7,196.9,1013.6,199.8,1007.5z"/>
							<path opacity="0.1091" fill="#131313" d="M122.4,1009.3c4.3-5.5,8.5-11,12.8-16.4c2-7.1,3.9-14.2,5.6-21.4
								c3.2-12.6,5.5-25.5,6.6-38.5c0.6-6.9,1-13.8,1.2-20.7c0.3-11.2-0.2-21.4-0.8-32.6c-1.3-3.8-3.5-7-6.6-9.5
								c-3.1-2.4-6.3-2.6-9.8-0.5c-4.6,2.1-8.7,4.9-12.3,8.5c-3.1,2.5-4.1,5.6-3,9.4c0.3,5,0.5,9.9,0.8,14.9c0-1.6,0-3.2,0-4.8
								c0,6.5,0,13.1,0,19.6c0-1.6,0.1-3.2,0.1-4.7c-0.3,6.5-0.7,13-1,19.5c0.1-1.6,0.2-3.2,0.3-4.7c-0.5,4.9-1.1,9.8-1.6,14.6
								c-1,7.3-2.4,14.4-4.2,21.5c-1.1,4.5-2.2,9.1-3.2,13.7c-1.1,8.7-5.2,16.1-12.4,22.1c-0.9,3.5-0.6,6.9,1,10.2
								c1.1,3.5,3.2,6.2,6.3,8.1c3.8,0.6,7.5,0.1,11-1.6C117,1014.6,120,1012.4,122.4,1009.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M152.3,1030.9c5.5-12.3,8.5-25.1,9-38.5c1.4-9.1,2.9-18.2,4.6-27.3
								c3.7-21,8.1-41.8,12.7-62.7c1.6-4,1.1-7.7-1.5-11.1c-2.5-3.5-5.9-5.1-10.2-4.7c-6.5,2.2-12.4,5.5-17.8,9.9
								c-5.7,3.8-10.6,8.5-14.7,14.1c-4.4,21.2-8.5,42.4-12.1,63.7c-1.6,9.2-3,18.4-4.4,27.6c-0.4,12.7-3,25-7.9,36.7
								c-1.3,4-0.6,7.6,2,10.9c2.5,3.4,5.8,5,10,4.7c6.1-2.2,11.7-5.4,16.7-9.6C144.2,1040.8,148.6,1036.3,152.3,1030.9z"/>
							<path opacity="0.1091" fill="#131313" d="M114.3,1006.4c8.1-12.8,12.5-26.6,13-41.5c1.3-9.1,2.9-18.1,4.5-27.2
								c3.8-21,8.2-41.9,12.9-62.7c1.1-3.9,0.4-7.4-2.2-10.6c-2.3-3.4-5.5-5.1-9.6-5.2c-5.7,1.7-10.9,4.4-15.5,8.2
								c-5,3.3-9.1,7.5-12.4,12.4c-4.7,21.3-9.1,42.6-12.8,64c-1.7,9.1-3.1,18.3-4.4,27.5c-0.3,13.9-4,27-10.9,39.2
								c-0.8,3.9,0.1,7.3,2.7,10.4c2.3,3.3,5.4,5,9.4,5.2c5.3-1.5,10-4.1,14.2-7.8C107.8,1015.1,111.5,1011.2,114.3,1006.4z"/>
							<path opacity="0.1091" fill="#131313" d="M125.9,942.2c2.8-21.1,6-42,9.8-62.9c3.8-21,8.3-41.9,13-62.7
								c-0.5-4.5-2.8-7.6-6.9-9.3c-6-1.2-11.4,0.4-16.2,4.6c-8.6,5.4-16.8,11.4-24.4,18.2c-5.5,3.4-8.5,8.2-9.1,14.3
								c-4.7,21.6-9.2,43.3-13,65.1c-3.6,20.7-6.9,41.4-9.7,62.2c0.6,4.5,2.9,7.5,7.1,9.1c6.1,1,11.5-0.7,16.3-5.1
								c8.6-5.6,16.7-11.9,24.2-18.9C122.5,953.3,125.4,948.4,125.9,942.2z"/>
							<path opacity="0.1091" fill="#131313" d="M153.8,984.7c2.9-21.1,6.1-42,9.9-63c3.8-21,8.3-41.9,13-62.7
								c-0.4-4.5-2.7-7.6-6.9-9.3c-6.1-1.1-11.7,0.5-16.6,4.8c-8.9,5.5-17.2,11.7-25,18.6c-5.6,3.4-8.7,8.3-9.4,14.5
								c-4.4,21.4-8.5,42.9-12.1,64.5c-3.4,20.6-6.5,41.2-9.4,61.9c0.5,4.5,2.9,7.5,7.1,9.1c6.1,1,11.5-0.6,16.3-5
								c8.6-5.6,16.7-11.9,24.3-18.8C150.3,995.7,153.3,990.8,153.8,984.7z"/>
							<path opacity="0.1091" fill="#131313" d="M159.2,936c0.8-11.9,2.3-23.8,4.6-35.5c1.4-9.1,3-18.1,4.6-27.1
								c3.8-20.9,8.2-41.7,12.9-62.5c0.2-5-2.1-8.1-6.9-9.3c-7.9-0.1-14.9,2.6-21,8.1c-11.7,7.6-22.8,15.9-33.5,24.9
								c-7,4.3-11.6,10.2-13.8,17.8c-4.5,21.7-8.8,43.5-12.4,65.3c-1.6,9.3-3.1,18.6-4.5,27.9c-2.5,11-3.9,22.1-4.2,33.3
								c-0.1,5,2.3,8,7.1,9.1c7.9-0.1,14.8-2.9,20.7-8.6c11.5-7.8,22.4-16.4,32.8-25.6C152.7,949.6,157.2,943.6,159.2,936z"/>
							<path opacity="0.1091" fill="#131313" d="M94.9,984.5c6.5-13.2,10.4-27,11.7-41.5c1.4-9,3-17.9,4.6-26.8
								c3.8-21.1,8.3-42.1,13.1-63c0.8-3.8-0.1-7.2-2.6-10.3c-2.1-3.3-5.1-5.2-9-5.7c-5,1.1-9.6,3.3-13.6,6.6
								c-4.4,2.8-7.9,6.4-10.6,10.8c-4.9,21.5-9.6,42.9-13.4,64.6c-1.6,9-3.2,17.9-4.6,26.9c-0.9,14-4.3,27.4-10.1,40.2
								c-0.5,3.8,0.5,7.1,3.1,10.1c2.2,3.2,5.2,5,9,5.5c4.8-1.1,9-3.3,12.7-6.6C89.4,992.4,92.6,988.8,94.9,984.5z"/>
							<path opacity="0.1091" fill="#131313" d="M126.9,1035.6c6.7-13.3,10.8-27.4,12.2-42.2c1.6-9.2,3.3-18.4,5.1-27.5
								c4-21,8.5-41.9,13.2-62.8c0.7-3.8-0.2-7.2-2.6-10.2c-2.1-3.3-5-5.2-8.8-5.9c-4.8,0.9-9.2,3-13.1,6.1
								c-4.2,2.6-7.6,6.1-10.1,10.3c-4.7,21.1-9,42.3-12.9,63.6c-1.7,9.2-3.3,18.4-4.8,27.6c-1,14.2-4.3,27.7-10.1,40.8
								c-0.2,3.7,0.9,7,3.4,9.8c2.1,3.1,5,5,8.7,5.7c4.3-0.8,8.2-2.7,11.5-5.6C122.3,1042.8,125.1,1039.6,126.9,1035.6z"/>
							<path opacity="0.1091" fill="#131313" d="M177.8,977.4c0.9-11.9,2.5-23.8,4.8-35.5c1.4-9.1,3-18.2,4.7-27.2
								c3.8-21,8.4-41.9,13.1-62.7c0.1-4.9-2.2-8-6.9-9.3c-7.6-0.3-14.4,2.2-20.2,7.5c-11.2,7.2-21.9,15.2-32.1,23.8
								c-6.8,4.1-11.2,9.8-13.1,17.2c-4.2,21.5-8.2,43.1-11.7,64.7c-1.5,9.2-2.9,18.5-4.3,27.8c-2.3,11-3.7,22.2-4.3,33.4
								c0,4.8,2.4,7.9,7.1,9.1c7.5,0.2,14-2.3,19.7-7.7c10.8-7.3,21.1-15.3,30.8-23.9C172.1,990.4,176.2,984.7,177.8,977.4z"/>
							<path opacity="0.1091" fill="#131313" d="M108.8,966.6c3.9-12.1,6.4-24.5,7.4-37.2c1.4-9.1,3-18.2,4.7-27.3
								c3.8-21.1,8.3-42,13-62.9c1.6-4,1.2-7.7-1.5-11.2c-2.5-3.5-6-5.1-10.3-4.6c-6.6,2.4-12.7,5.8-18.1,10.3
								c-5.8,4-10.9,8.8-15,14.4c-4.8,21.5-9.3,43-13.2,64.6c-1.7,9.3-3.3,18.5-4.7,27.9c-1,12.2-3.2,24.1-6.7,35.8
								c-1.5,4-1,7.7,1.8,11.1c2.6,3.5,6.1,4.9,10.4,4.4c6.5-2.5,12.5-6,17.8-10.6C99.9,977.3,104.8,972.4,108.8,966.6z"/>
							<path opacity="0.1091" fill="#131313" d="M140,956.6c2.9-21.1,6.1-42.1,9.9-63c3.8-21,8.3-41.9,13-62.7
								c-0.3-4.6-2.6-7.7-6.9-9.3c-6.5-0.9-12.2,0.9-17.3,5.4c-9.3,5.9-18.2,12.4-26.5,19.7c-5.8,3.6-9.2,8.6-10.2,15.1
								c-8.9,42-16.5,84.3-22.1,126.9c0.4,4.6,2.8,7.6,7.1,9.1c6.5,0.8,12.2-1.1,17.2-5.8c9.2-6.1,17.9-12.8,26-20.3
								C136,968.2,139.2,963.1,140,956.6z"/>
							<path opacity="0.1091" fill="#131313" d="M171.6,1002.6c3-21.1,6.3-42.1,10.1-63.1c3.8-21,8.3-41.9,13.1-62.8
								c-0.3-4.6-2.6-7.7-6.9-9.3c-6.4-1-12.2,0.8-17.3,5.3c-9.3,5.8-18.1,12.3-26.4,19.5c-5.8,3.5-9.2,8.5-10.1,15
								c-4.2,21.4-8.2,42.7-11.6,64.2c-3.3,20.6-6.4,41.1-9.2,61.8c0.5,4.5,2.8,7.6,7.1,9.1c6.3,0.9,11.9-0.9,16.7-5.4
								c8.9-5.8,17.3-12.3,25.1-19.5C167.8,1013.8,170.9,1008.9,171.6,1002.6z"/>
							<path opacity="0.1091" fill="#131313" d="M97.7,1005c6.9-13,10.6-26.7,11-41.3c1.5-9.1,3.1-18.3,4.9-27.4
								c3.9-21,8.3-41.8,13-62.7c0.4-3.7-0.5-7-2.9-10c-2-3.2-4.8-5.2-8.5-6c-4.4,0.7-8.4,2.5-12,5.3c-3.9,2.4-6.8,5.6-8.9,9.6
								c-4.8,21.2-9.3,42.4-13.2,63.7c-1.7,9.2-3.4,18.4-4.8,27.6c-0.1,14-3.1,27.3-9.1,40c-0.1,3.7,1.1,6.9,3.6,9.7
								c2.1,3.1,4.9,5,8.5,5.9c4.1-0.6,7.7-2.4,10.8-5.1C93.5,1011.8,96.1,1008.8,97.7,1005z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M233.8,1117c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C219.3,1112,225.9,1115.8,233.8,1117z"/>
							<path opacity="0.1091" fill="#131313" d="M194.2,1086c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C184.4,1085.1,188.9,1086.8,194.2,1086z"/>
							<path opacity="0.1091" fill="#131313" d="M190,1029.5c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C182.8,1026.3,186.1,1028.4,190,1029.5z"/>
							<path opacity="0.1091" fill="#131313" d="M228.2,1075c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C216.8,1072.9,222,1075.3,228.2,1075z"/>
							<path opacity="0.1091" fill="#131313" d="M219.4,1025.3c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C209.9,1020,214.3,1023.1,219.4,1025.3z"/>
							<path opacity="0.1091" fill="#131313" d="M163.6,1064.5c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C155.7,1060.7,159.4,1063.1,163.6,1064.5z"/>
							<path opacity="0.1091" fill="#131313" d="M206.8,1119.4c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C194.4,1116.4,200,1119.2,206.8,1119.4z"/>
							<path opacity="0.1091" fill="#131313" d="M250.8,1070.2c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C238.2,1067,243.9,1069.9,250.8,1070.2z"/>
							<path opacity="0.1091" fill="#131313" d="M176.4,1046.3c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C168.5,1042.4,172.1,1044.8,176.4,1046.3z"/>
							<path opacity="0.1091" fill="#131313" d="M204.4,1043.1c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C194.3,1037.1,199,1040.6,204.4,1043.1z"/>
							<path opacity="0.1091" fill="#131313" d="M248.9,1095.7c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C234.6,1091,241.1,1094.7,248.9,1095.7z"/>
							<path opacity="0.1091" fill="#131313" d="M165.8,1083.9c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C156,1078.3,160.5,1081.6,165.8,1083.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M170.4,738.9c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C155.9,734,162.5,737.8,170.4,738.9z"/>
							<path opacity="0.1091" fill="#131313" d="M130.8,707.9c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C120.9,707,125.5,708.8,130.8,707.9z"/>
							<path opacity="0.1091" fill="#131313" d="M126.6,651.4c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C119.3,648.2,122.7,650.3,126.6,651.4z"/>
							<path opacity="0.1091" fill="#131313" d="M164.8,697c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C153.4,694.9,158.6,697.2,164.8,697z"/>
							<path opacity="0.1091" fill="#131313" d="M156,647.2c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C146.5,641.9,150.9,645.1,156,647.2z"/>
							<path opacity="0.1091" fill="#131313" d="M100.2,686.5c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C92.3,682.7,95.9,685.1,100.2,686.5z"/>
							<path opacity="0.1091" fill="#131313" d="M143.4,741.4c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C130.9,738.3,136.6,741.2,143.4,741.4z"/>
							<path opacity="0.1091" fill="#131313" d="M187.4,692.1c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C174.8,688.9,180.5,691.8,187.4,692.1z"/>
							<path opacity="0.1091" fill="#131313" d="M113,668.2c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C105.1,664.4,108.7,666.8,113,668.2z"/>
							<path opacity="0.1091" fill="#131313" d="M141,665c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2c-2.5-5.4-6-10.1-10.4-14.1
								c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2c2.5,5.4,6,10.1,10.4,14.1
								C130.9,659.1,135.6,662.5,141,665z"/>
							<path opacity="0.1091" fill="#131313" d="M185.5,717.6c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C171.2,713,177.7,716.6,185.5,717.6z"/>
							<path opacity="0.1091" fill="#131313" d="M102.4,705.9c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C92.6,700.2,97.1,703.5,102.4,705.9z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M187.4,712.1c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C172.9,707.2,179.5,710.9,187.4,712.1z"/>
							<path opacity="0.1091" fill="#131313" d="M147.9,681.1c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C138,680.2,142.5,681.9,147.9,681.1z"/>
							<path opacity="0.1091" fill="#131313" d="M143.7,624.6c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C136.4,621.4,139.8,623.5,143.7,624.6z"/>
							<path opacity="0.1091" fill="#131313" d="M181.9,670.1c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C170.5,668,175.7,670.4,181.9,670.1z"/>
							<path opacity="0.1091" fill="#131313" d="M173,620.4c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C163.6,615.1,168,618.2,173,620.4z"/>
							<path opacity="0.1091" fill="#131313" d="M117.3,659.7c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C109.4,655.9,113,658.3,117.3,659.7z"/>
							<path opacity="0.1091" fill="#131313" d="M160.5,714.6c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C148,711.5,153.7,714.3,160.5,714.6z"/>
							<path opacity="0.1091" fill="#131313" d="M204.5,665.3c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C191.9,662.1,197.6,665,204.5,665.3z"/>
							<path opacity="0.1091" fill="#131313" d="M130.1,641.4c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C122.2,637.5,125.8,640,130.1,641.4z"/>
							<path opacity="0.1091" fill="#131313" d="M158.1,638.2c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C148,632.2,152.7,635.7,158.1,638.2z"/>
							<path opacity="0.1091" fill="#131313" d="M202.6,690.8c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C188.3,686.2,194.8,689.8,202.6,690.8z"/>
							<path opacity="0.1091" fill="#131313" d="M119.4,679.1c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C109.7,673.4,114.2,676.7,119.4,679.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M177.7,682.8c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C163.2,677.9,169.8,681.7,177.7,682.8z"/>
							<path opacity="0.1091" fill="#131313" d="M138.1,651.8c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C128.3,650.9,132.8,652.7,138.1,651.8z"/>
							<path opacity="0.1091" fill="#131313" d="M133.9,595.4c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C126.7,592.1,130,594.2,133.9,595.4z"/>
							<path opacity="0.1091" fill="#131313" d="M172.1,640.9c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C160.7,638.8,165.9,641.1,172.1,640.9z"/>
							<path opacity="0.1091" fill="#131313" d="M163.3,591.1c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C153.8,585.8,158.2,589,163.3,591.1z"/>
							<path opacity="0.1091" fill="#131313" d="M107.5,630.4c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C99.6,626.6,103.3,629,107.5,630.4z"/>
							<path opacity="0.1091" fill="#131313" d="M150.7,685.3c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C138.3,682.2,143.9,685.1,150.7,685.3z"/>
							<path opacity="0.1091" fill="#131313" d="M194.7,636c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C182.1,632.8,187.9,635.7,194.7,636z"/>
							<path opacity="0.1091" fill="#131313" d="M120.3,612.1c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C112.4,608.3,116,610.7,120.3,612.1z"/>
							<path opacity="0.1091" fill="#131313" d="M148.3,608.9c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C138.2,603,142.9,606.4,148.3,608.9z"/>
							<path opacity="0.1091" fill="#131313" d="M192.8,661.5c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C178.5,656.9,185,660.5,192.8,661.5z"/>
							<path opacity="0.1091" fill="#131313" d="M109.7,649.8c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C99.9,644.1,104.4,647.4,109.7,649.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M172.8,621.8c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C158.3,616.9,164.9,620.7,172.8,621.8z"/>
							<path opacity="0.1091" fill="#131313" d="M133.2,590.8c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C123.4,590,127.9,591.7,133.2,590.8z"/>
							<path opacity="0.1091" fill="#131313" d="M129.1,534.4c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C121.8,531.2,125.1,533.3,129.1,534.4z"/>
							<path opacity="0.1091" fill="#131313" d="M167.3,579.9c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C155.9,577.8,161.1,580.2,167.3,579.9z"/>
							<path opacity="0.1091" fill="#131313" d="M158.4,530.2c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C148.9,524.9,153.3,528,158.4,530.2z"/>
							<path opacity="0.1091" fill="#131313" d="M102.6,569.4c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C94.8,565.6,98.4,568,102.6,569.4z"/>
							<path opacity="0.1091" fill="#131313" d="M145.8,624.3c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C133.4,621.2,139,624.1,145.8,624.3z"/>
							<path opacity="0.1091" fill="#131313" d="M189.9,575c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C177.2,571.9,183,574.8,189.9,575z"/>
							<path opacity="0.1091" fill="#131313" d="M115.5,551.1c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C107.5,547.3,111.2,549.7,115.5,551.1z"/>
							<path opacity="0.1091" fill="#131313" d="M143.5,547.9c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C133.4,542,138.1,545.5,143.5,547.9z"/>
							<path opacity="0.1091" fill="#131313" d="M187.9,600.6c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C173.7,595.9,180.2,599.6,187.9,600.6z"/>
							<path opacity="0.1091" fill="#131313" d="M104.8,588.8c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C95,583.2,99.6,586.5,104.8,588.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M192.3,863.3c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C177.8,858.4,184.4,862.2,192.3,863.3z"/>
							<path opacity="0.1091" fill="#131313" d="M152.7,832.3c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C142.9,831.4,147.4,833.1,152.7,832.3z"/>
							<path opacity="0.1091" fill="#131313" d="M148.6,775.8c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C141.3,772.6,144.6,774.7,148.6,775.8z"/>
							<path opacity="0.1091" fill="#131313" d="M186.8,821.4c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C175.4,819.3,180.6,821.6,186.8,821.4z"/>
							<path opacity="0.1091" fill="#131313" d="M177.9,771.6c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C168.5,766.3,172.8,769.5,177.9,771.6z"/>
							<path opacity="0.1091" fill="#131313" d="M122.1,810.9c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C114.3,807.1,117.9,809.5,122.1,810.9z"/>
							<path opacity="0.1091" fill="#131313" d="M165.4,865.8c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C152.9,862.7,158.6,865.6,165.4,865.8z"/>
							<path opacity="0.1091" fill="#131313" d="M209.4,816.5c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C196.7,813.3,202.5,816.2,209.4,816.5z"/>
							<path opacity="0.1091" fill="#131313" d="M135,792.6c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C127,788.8,130.7,791.2,135,792.6z"/>
							<path opacity="0.1091" fill="#131313" d="M163,789.4c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C152.9,783.5,157.6,786.9,163,789.4z"/>
							<path opacity="0.1091" fill="#131313" d="M207.4,842c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C193.2,837.4,199.7,841,207.4,842z"/>
							<path opacity="0.1091" fill="#131313" d="M124.3,830.3c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C114.5,824.6,119.1,827.9,124.3,830.3z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M297.2,860.9c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C282.7,855.9,289.3,859.7,297.2,860.9z"/>
							<path opacity="0.1091" fill="#131313" d="M257.6,829.9c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C247.8,829,252.3,830.7,257.6,829.9z"/>
							<path opacity="0.1091" fill="#131313" d="M253.5,773.4c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C246.2,770.2,249.5,772.3,253.5,773.4z"/>
							<path opacity="0.1091" fill="#131313" d="M291.6,818.9c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C280.2,816.8,285.4,819.2,291.6,818.9z"/>
							<path opacity="0.1091" fill="#131313" d="M282.8,769.2c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C273.3,763.9,277.7,767,282.8,769.2z"/>
							<path opacity="0.1091" fill="#131313" d="M227,808.4c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C219.1,804.6,222.8,807,227,808.4z"/>
							<path opacity="0.1091" fill="#131313" d="M270.2,863.3c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C257.8,860.3,263.4,863.1,270.2,863.3z"/>
							<path opacity="0.1091" fill="#131313" d="M314.2,814.1c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C301.6,810.9,307.4,813.8,314.2,814.1z"/>
							<path opacity="0.1091" fill="#131313" d="M239.8,790.2c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C231.9,786.3,235.6,788.7,239.8,790.2z"/>
							<path opacity="0.1091" fill="#131313" d="M267.8,787c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C257.7,781,262.4,784.5,267.8,787z"/>
							<path opacity="0.1091" fill="#131313" d="M312.3,839.6c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C298.1,834.9,304.5,838.6,312.3,839.6z"/>
							<path opacity="0.1091" fill="#131313" d="M229.2,827.8c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C219.4,822.2,224,825.5,229.2,827.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M160.6,848.7c5-0.5,7.7-3.2,8.2-8.2c-1.1-7.9-4.9-14.5-11.3-19.8
								c-9.4-10.6-19.3-20.6-29.9-30c-5.3-6.4-11.9-10.2-19.8-11.3c-5,0.5-7.7,3.2-8.2,8.2c1.1,7.9,4.9,14.5,11.3,19.8
								c9.4,10.6,19.3,20.6,29.9,30C146.1,843.8,152.7,847.5,160.6,848.7z"/>
							<path opacity="0.1091" fill="#131313" d="M121,817.7c4.1-1.4,6.8-4.1,8.2-8.2c0.9-5.3-0.8-9.9-5.1-13.6
								c-5.4-6.6-11.4-12.6-18-18c-3.7-4.3-8.3-6-13.6-5.1c-4.1,1.4-6.8,4.1-8.2,8.2c-0.9,5.3,0.8,9.9,5.1,13.6
								c5.4,6.6,11.4,12.6,18,18C111.2,816.8,115.7,818.5,121,817.7z"/>
							<path opacity="0.1091" fill="#131313" d="M116.9,761.2c3.6-0.4,6.7-2,9.1-4.8c2.8-2.4,4.4-5.5,4.8-9.1
								c-1.1-3.9-3.2-7.3-6.3-10c-2.7-3.1-6.1-5.2-10-6.3c-3.6,0.4-6.7,2-9.1,4.8c-2.8,2.4-4.4,5.5-4.8,9.1c1.1,3.9,3.2,7.3,6.3,10
								C109.6,758,112.9,760.1,116.9,761.2z"/>
							<path opacity="0.1091" fill="#131313" d="M155.1,806.7c4.4-1.1,7.1-3.8,8.2-8.2c0.2-6.2-2.1-11.4-7.1-15.6
								c-6.7-7.9-14-15.2-21.9-21.8c-4.2-5-9.4-7.3-15.6-7.1c-4.4,1.1-7.1,3.8-8.2,8.2c-0.2,6.2,2.1,11.4,7.1,15.6
								c6.7,7.9,14,15.2,21.9,21.8C143.7,804.6,148.9,807,155.1,806.7z"/>
							<path opacity="0.1091" fill="#131313" d="M146.2,757c3.9,0.3,7.3-1,9.9-4c3-2.7,4.3-6,3.9-10c-2.2-5.1-5.3-9.4-9.5-13.1
								c-3.7-4.1-8.1-7.3-13.2-9.4c-3.9-0.3-7.3,1-9.9,4c-3,2.7-4.3,6-3.9,10c2.2,5.1,5.3,9.4,9.5,13.1
								C136.7,751.7,141.1,754.8,146.2,757z"/>
							<path opacity="0.1091" fill="#131313" d="M90.4,796.2c3.7-0.2,6.8-1.7,9.4-4.6c2.8-2.5,4.4-5.6,4.6-9.3
								c-1.4-4.3-3.8-7.9-7.2-10.9c-3-3.4-6.6-5.8-10.9-7.2c-3.7,0.2-6.8,1.7-9.4,4.6c-2.8,2.5-4.4,5.6-4.6,9.3
								c1.4,4.3,3.8,7.9,7.2,10.9C82.6,792.4,86.2,794.8,90.4,796.2z"/>
							<path opacity="0.1091" fill="#131313" d="M133.6,851.1c4.6-0.9,7.3-3.6,8.2-8.2c-0.2-6.8-3-12.5-8.5-17
								c-7.6-8.8-15.7-17-24.5-24.6c-4.5-5.5-10.2-8.3-17-8.5c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17
								c7.6,8.8,15.7,17,24.5,24.6C121.2,848.1,126.9,850.9,133.6,851.1z"/>
							<path opacity="0.1091" fill="#131313" d="M177.7,801.9c4.6-0.9,7.3-3.6,8.2-8.2c-0.3-6.9-3.2-12.6-8.7-17.2
								c-7.7-8.9-16.1-17.2-25-24.9c-4.6-5.5-10.4-8.4-17.2-8.7c-4.6,0.9-7.3,3.6-8.2,8.2c0.3,6.9,3.2,12.6,8.7,17.2
								c7.7,8.9,16.1,17.2,25,24.9C165,798.7,170.8,801.6,177.7,801.9z"/>
							<path opacity="0.1091" fill="#131313" d="M103.3,778c3.7-0.2,6.9-1.7,9.4-4.5c2.9-2.5,4.4-5.6,4.5-9.4
								c-1.4-4.3-3.9-7.9-7.3-11c-3-3.4-6.7-5.8-11-7.3c-3.7,0.2-6.9,1.7-9.4,4.5c-2.9,2.5-4.4,5.6-4.5,9.4c1.4,4.3,3.9,7.9,7.3,11
								C95.3,774.1,99,776.5,103.3,778z"/>
							<path opacity="0.1091" fill="#131313" d="M131.3,774.8c4,0.5,7.4-0.7,10.2-3.7c3-2.8,4.2-6.2,3.7-10.2
								c-2.5-5.4-6-10.1-10.4-14.1c-4-4.4-8.7-7.9-14.1-10.4c-4-0.5-7.4,0.7-10.2,3.7c-3,2.8-4.2,6.2-3.7,10.2
								c2.5,5.4,6,10.1,10.4,14.1C121.2,768.8,125.9,772.3,131.3,774.8z"/>
							<path opacity="0.1091" fill="#131313" d="M175.7,827.4c4.9-0.5,7.7-3.3,8.2-8.2c-1-7.8-4.7-14.3-10.9-19.4
								c-9.1-10.4-18.9-20.1-29.3-29.2c-5.2-6.3-11.7-9.9-19.4-10.9c-4.9,0.5-7.7,3.3-8.2,8.2c1,7.8,4.7,14.3,10.9,19.4
								c9.1,10.4,18.9,20.1,29.3,29.2C161.5,822.8,168,826.4,175.7,827.4z"/>
							<path opacity="0.1091" fill="#131313" d="M92.6,815.6c4,0.4,7.3-0.8,10.1-3.8c3-2.7,4.3-6.1,3.9-10.1
								c-2.3-5.2-5.6-9.8-9.9-13.6c-3.8-4.3-8.4-7.6-13.6-10c-4-0.4-7.3,0.8-10.1,3.8c-3,2.7-4.3,6.1-3.9,10.1
								c2.3,5.2,5.6,9.8,9.9,13.6C82.8,810,87.4,813.3,92.6,815.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M179.9,851c5,0,10,0.1,15,0.1c8,0.6,16,1.2,24,1.6c13.4,0.8,26.8,1.6,40.2,2.3
								c5.1-0.2,7.9-2.8,8.5-7.9c-1-8.1-4.6-15.1-11-20.7c-9.2-11.3-19.1-22-29.6-32.1c-5.2-6.8-11.7-11-19.8-12.6
								c-13.4-0.8-26.8-1.5-40.2-2.4c-5.2-0.2-10.5-0.6-15.7-1.2c-6.9-0.9-13.9-1-20.8-0.3c-5,0.2-7.9,2.7-8.7,7.7
								c0.7,8,4.1,14.9,10.3,20.6c8.8,11.2,18.3,21.9,28.4,32C165.5,845.1,172,849.3,179.9,851z"/>
							<path opacity="0.1091" fill="#131313" d="M134.5,819.1c6.4,0.9,12.8,1.7,19.3,2.3c6.1,0.6,12.3,0.9,18.5,1
								c16.1,0.9,32.2,1.9,48.3,2.8c4.2-1.2,7-3.8,8.5-7.9c1-5.4-0.7-10.2-4.9-14.2c-5.3-7.1-11.3-13.5-17.9-19.4
								c-3.6-4.5-8.2-6.5-13.7-6c-21.1-1.2-42.2-2.5-63.3-3.5c2,0.2,4,0.4,6,0.6c-5.1-0.6-10.2-1.2-15.3-1.8
								c-4.2-1.5-7.9-0.8-11.3,2.1c-3.3,3-4.5,6.6-3.5,10.9c3.2,6.8,7.5,12.9,12.7,18.3C122.7,810.1,128.2,815.1,134.5,819.1z"/>
							<path opacity="0.1091" fill="#131313" d="M137.7,764.1c7.2,2.3,14.3,4.6,21.5,7c8,0.1,15.9,0.4,23.8,1
								c13.5,0.9,26.9,1.7,40.4,2.5c3.7,0,7-1.3,9.6-4c3-2.3,4.7-5.4,5.1-9.1c-1.2-4.4-3.4-8.2-6.7-11.4c-2.8-3.6-6.4-6.2-10.6-7.9
								c-13.5-0.8-26.9-1.6-40.4-2.5c-5.2-0.5-10.5-0.7-15.7-0.5c-9.6,0.7-18.9-1.2-27.9-5.6c-3.7,0.2-6.8,1.6-9.4,4.2
								c-2.9,2.3-4.7,5.2-5.3,8.8c0.9,4,2.8,7.5,5.8,10.4C130.6,760.4,133.8,762.7,137.7,764.1z"/>
							<path opacity="0.1091" fill="#131313" d="M174.3,808.6c26.5,1.6,53,3.2,79.5,4.7c4.4-0.9,7.3-3.5,8.5-7.9
								c0.5-6.2-1.7-11.5-6.6-15.9c-6.4-8.2-13.5-15.8-21.2-22.8c-4-5.2-9.2-7.8-15.4-7.8c-26-1.5-51.9-3.2-77.9-4.7
								c-4.4,0.8-7.3,3.4-8.6,7.7c-0.6,6.1,1.4,11.4,6.1,15.9c6.2,8.2,13,15.9,20.4,22.9C163.1,805.9,168.1,808.6,174.3,808.6z"/>
							<path opacity="0.1091" fill="#131313" d="M166.9,759.6c7.3,2.5,14.6,5.1,21.9,7.6c7.6,0.2,15.2,0.6,22.8,1
								c13.4,0.7,26.8,1.5,40.2,2.3c4,0.6,7.4-0.5,10.2-3.4c3.1-2.6,4.5-6,4.2-10c-2.2-5.3-5.3-10-9.5-14.1
								c-3.7-4.5-8.2-8-13.3-10.5c-13.4-0.8-26.8-1.6-40.2-2.3c-4.9-0.3-9.9-0.5-14.8-0.5c-9.6,0.5-18.8-1.7-27.6-6.5
								c-3.9-0.5-7.3,0.7-10.1,3.5c-3.1,2.5-4.6,5.7-4.6,9.6c1.8,5.1,4.6,9.5,8.4,13.4C157.9,754,162.1,757.3,166.9,759.6z"/>
							<path opacity="0.1091" fill="#131313" d="M110.1,798c7.1,2.3,14.3,4.6,21.4,6.9c8.2,0.8,16.5,1.5,24.7,2.1
								c13.5,1,27,1.9,40.6,2.7c3.8,0.3,7.1-0.9,9.9-3.7c3.1-2.4,4.7-5.6,4.8-9.4c-1.5-4.8-4.1-9-7.7-12.6c-3.1-4-7-7-11.6-9.1
								c-13.5-0.8-27-1.6-40.4-2.5c-5.4-0.4-10.8-0.7-16.2-1c-9.8,0-19.3-2-28.4-6.2c-3.8-0.1-7,1.2-9.7,3.9c-3,2.4-4.7,5.4-5,9.1
								c1.2,4.4,3.5,8.3,6.8,11.6C102.3,793.5,105.9,796.2,110.1,798z"/>
							<path opacity="0.1091" fill="#131313" d="M153.1,853.4c26.5,1.1,53,3,79.5,4.5c4.7-0.6,7.5-3.2,8.5-7.8
								c-0.1-7-2.9-13-8.3-17.9c-7.5-9.5-15.7-18.4-24.6-26.7c-4.5-5.8-10.2-9.1-17.2-9.8c-13.4-0.8-26.8-1.5-40.2-2.3
								c-5.2-0.3-10.5-0.6-15.7-1.1c-7.2-0.7-14.4-1-21.6-1c-4.7,0.6-7.6,3.1-8.7,7.7c-0.1,6.9,2.5,12.9,7.8,17.8
								c7.2,9.5,15.1,18.4,23.6,26.7C140.6,849.4,146.2,852.7,153.1,853.4z"/>
							<path opacity="0.1091" fill="#131313" d="M196.7,803.6c26.5,1.3,53,3.1,79.5,4.6c4.6-0.7,7.5-3.3,8.5-7.9
								c0-6.9-2.7-12.7-8-17.5c-7.3-9.2-15.3-17.8-24-25.8c-4.4-5.7-10-8.8-16.8-9.3c-13.4-0.8-26.8-1.5-40.2-2.3
								c-5.2-0.3-10.4-0.6-15.6-1.1c-7.2-0.7-14.4-1-21.7-1.1c-4.6,0.6-7.5,3.2-8.6,7.7c-0.2,6.8,2.3,12.6,7.5,17.4
								c7,9.2,14.7,17.8,23.1,25.8C184.5,799.9,190,803.1,196.7,803.6z"/>
							<path opacity="0.1091" fill="#131313" d="M122.8,779.4c7.2,2.4,14.5,4.8,21.7,7.2c8.3,0.8,16.5,1.5,24.8,2.1
								c13.5,1,27,1.9,40.6,2.7c3.8,0.3,7.1-0.9,9.9-3.7c3.1-2.4,4.7-5.6,4.8-9.4c-1.5-4.8-4.1-8.9-7.6-12.5c-3.1-4-7-7-11.5-9
								c-13.5-0.8-27-1.6-40.4-2.6c-5.4-0.4-10.8-0.7-16.2-1c-9.9,0-19.4-2.1-28.5-6.3c-3.7-0.1-7,1.3-9.6,4c-3,2.3-4.7,5.4-5.1,9.1
								c1.2,4.4,3.4,8.2,6.7,11.4C115.1,775.1,118.6,777.7,122.8,779.4z"/>
							<path opacity="0.1091" fill="#131313" d="M150.6,775.9c7.2,2.6,14.5,5.2,21.7,7.8c7.6,0,15.2,0.2,22.8,0.7
								c13.4,0.8,26.8,1.5,40.3,2.3c4,0.8,7.5-0.2,10.5-3.2c3.1-2.7,4.5-6.1,3.9-10.2c-2.5-5.7-6-10.7-10.4-15.1
								c-4-4.8-8.8-8.6-14.3-11.6c-13.4-0.8-26.8-1.6-40.2-2.3c-5-0.4-10-0.5-15-0.5c-9.5,0.6-18.6-1.5-27.2-6.5
								c-4-0.7-7.4,0.3-10.3,3.2c-3.2,2.6-4.6,5.9-4.3,9.9c2.1,5.4,5.2,10.3,9.4,14.4C141,769.5,145.4,773.2,150.6,775.9z"/>
							<path opacity="0.1091" fill="#131313" d="M194.7,829.4c5,0.1,10,0.1,15,0.2c8,0.6,16,1.2,24,1.6c13.4,0.8,26.8,1.6,40.2,2.3
								c5-0.3,7.8-2.9,8.5-7.9c-0.8-7.9-4.2-14.6-10.4-20c-8.8-10.8-18.3-21.1-28.4-30.7c-5-6.5-11.4-10.5-19.2-11.9
								c-13.4-0.8-26.8-1.5-40.2-2.4c-5.2-0.3-10.4-0.6-15.7-1.2c-7-0.9-14-1-21-0.4c-4.9,0.3-7.8,2.8-8.6,7.7
								c0.5,7.8,3.8,14.4,9.7,19.9c8.5,10.8,17.6,21,27.3,30.7C180.8,824,187.1,828,194.7,829.4z"/>
							<path opacity="0.1091" fill="#131313" d="M112.5,817.5c7.1,2.4,14.2,4.9,21.2,7.3c7.6-0.1,15.2,0.2,22.8,0.7
								c13.4,0.8,26.8,1.5,40.3,2.3c4,0.9,7.6-0.2,10.5-3.1c3.2-2.7,4.5-6.1,3.9-10.2c-2.5-5.8-6-10.8-10.5-15.3
								c-4-4.8-8.8-8.8-14.4-11.7c-13.4-0.8-26.8-1.6-40.2-2.3c-5-0.4-10-0.6-15.1-0.5c-9.4,0.6-18.3-1.4-26.8-6.2
								c-7.4-2.3-15.5,5.9-14.7,13.1c2.2,5.5,5.3,10.4,9.5,14.7C102.8,811,107.3,814.7,112.5,817.5z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M378.6,868.2c0.5-0.3,1-0.6,1.5-0.9c3.2-4.4,3.2-9,0.1-13.7c-7-9.9-15-19-23.9-27.3
								c-8.4-8.8-17.6-16.6-27.5-23.6c-4.8-3.1-9.4-3-13.7,0.3c-0.3,0.2-0.6,0.4-0.9,0.7c-3.2,4.4-3.3,8.9-0.2,13.7
								c6.9,9.9,14.8,19,23.6,27.4c8.3,8.8,17.4,16.7,27.3,23.7C369.6,871.5,374.2,871.4,378.6,868.2z"/>
							<path opacity="0.1091" fill="#131313" d="M327.3,839.4c1.4,0.7,2.9,1.4,4.3,2.1c4.3,1.3,8,0.3,11.1-2.9
								c3.1-3.2,4-6.9,2.7-11.2c-3.7-6.6-8.4-12.4-14-17.5c-5.2-5.6-11.1-10.1-17.7-13.8c-1-0.5-2-0.9-3-1.4
								c-4.2-1.2-7.9-0.2-11,2.9c-3.1,3.1-4.1,6.8-2.9,11c3.5,6.5,8,12.2,13.4,17.2C315.2,831.4,320.9,835.9,327.3,839.4z"/>
							<path opacity="0.1091" fill="#131313" d="M324.3,781.2c2.3,1.5,4.7,3,7,4.6c3,2.5,6.2,2.9,9.5,1c3.5-2,6.2-4.7,8.2-8.2
								c1.8-3.4,1.5-6.6-1.1-9.5c-2.8-4-6.2-7.4-10.2-10.2c-2.1-1.3-4.3-2.6-6.4-4c-2.9-2.4-6-2.6-9.2-0.7c-3.4,2-6.2,4.7-8.2,8.2
								c-1.9,3.2-1.6,6.3,0.7,9.2C317.3,775.4,320.5,778.6,324.3,781.2z"/>
							<path opacity="0.1091" fill="#131313" d="M360.7,827.9c0.9,0.2,1.8,0.3,2.7,0.5c4.5,1.8,8.4,1.2,11.9-2.1
								c3.2-3.5,3.8-7.5,1.9-11.9c-4.8-7.6-10.5-14.4-17.1-20.5c-6.2-6.5-13.1-12.1-20.7-16.7c-0.6-0.1-1.3-0.2-1.9-0.3
								c-4.4-1.8-8.4-1.2-11.9,2c-3.2,3.5-3.9,7.5-2,11.9c4.6,7.6,10.2,14.4,16.7,20.4C346.3,817.6,353.1,823.2,360.7,827.9z"/>
							<path opacity="0.1091" fill="#131313" d="M352.3,775.7c2.2,1.4,4.4,2.7,6.5,4.1c3.3,3.4,7.1,4.3,11.4,2.9
								c3.8-1.7,6.5-4.4,8.2-8.2c1.4-4.3,0.5-8.1-2.9-11.4c-4-5.2-8.6-9.8-13.8-13.8c-2-1.2-4-2.4-6-3.6c-3.3-3.3-7-4.1-11.2-2.6
								c-3.7,1.7-6.4,4.5-8.2,8.2c-1.5,4.2-0.6,7.9,2.7,11.1C342.9,767.5,347.3,771.9,352.3,775.7z"/>
							<path opacity="0.1091" fill="#131313" d="M297.5,815.8c2.3,1.5,4.6,2.9,6.8,4.4c3.1,2.8,6.4,3.3,10,1.5
								c3.6-1.9,6.3-4.6,8.2-8.2c1.8-3.6,1.3-6.9-1.5-10c-3.1-4.3-6.8-8-11.1-11.1c-2.1-1.3-4.2-2.6-6.4-3.9c-3-2.6-6.3-3.1-9.8-1.3
								c-3.5,1.9-6.3,4.7-8.2,8.2c-1.8,3.5-1.4,6.7,1.2,9.8C289.8,809.4,293.4,812.9,297.5,815.8z"/>
							<path opacity="0.1091" fill="#131313" d="M345.8,873.1c0.8,0,1.6,0,2.4,0c4.6-0.9,7.3-3.6,8.1-8.2
								c-0.3-6.8-3.2-12.5-8.7-17.1c-7.7-8.8-16-17-24.9-24.6c-4.6-5.5-10.3-8.3-17.2-8.5c-0.5,0-1,0-1.5,0
								c-4.6,0.9-7.3,3.6-8.2,8.2c0.2,6.8,3,12.5,8.5,17c7.6,8.8,15.7,17,24.5,24.6C333.4,870,339,872.9,345.8,873.1z"/>
							<path opacity="0.1091" fill="#131313" d="M389.9,823.8c0.8,0,1.5,0,2.3,0c4.6-0.9,7.3-3.6,8.1-8.3c-0.4-6.9-3.4-12.7-9-17.2
								c-7.9-8.9-16.3-17.2-25.4-24.9c-4.7-5.5-10.5-8.4-17.4-8.7c-0.5,0-1,0-1.5,0.1c-4.6,0.9-7.3,3.6-8.2,8.2
								c0.3,6.9,3.2,12.6,8.7,17.2c7.7,8.9,16.1,17.2,25,24.9C377.2,820.7,383,823.6,389.9,823.8z"/>
							<path opacity="0.1091" fill="#131313" d="M310.3,797.6c2.3,1.5,4.6,3,6.9,4.5c3.1,2.8,6.4,3.3,10.1,1.6
								c3.6-1.9,6.3-4.6,8.2-8.2c1.7-3.6,1.2-7-1.6-10.1c-3.1-4.4-6.9-8.1-11.2-11.2c-2.1-1.3-4.3-2.7-6.4-4c-3-2.7-6.3-3.1-9.8-1.3
								c-3.5,1.9-6.3,4.6-8.2,8.2c-1.8,3.5-1.3,6.8,1.3,9.8C302.6,791,306.1,794.6,310.3,797.6z"/>
							<path opacity="0.1091" fill="#131313" d="M337,793.1c2.1,1.3,4.3,2.7,6.4,4c3.4,3.6,7.4,4.7,11.9,3.4
								c3.8-1.6,6.6-4.3,8.2-8.2c1.3-4.6,0.2-8.5-3.5-11.9c-4.3-5.6-9.3-10.5-14.8-14.8c-2-1.2-4-2.4-6-3.6
								c-3.4-3.5-7.3-4.6-11.7-3.2c-3.8,1.6-6.5,4.4-8.2,8.2c-1.4,4.5-0.3,8.4,3.2,11.7C326.8,784.1,331.6,788.9,337,793.1z"/>
							<path opacity="0.1091" fill="#131313" d="M393.7,846.9c0.5-0.3,1.1-0.5,1.6-0.8c3.2-4.3,3.3-8.9,0.2-13.6
								c-6.9-9.7-14.6-18.5-23.3-26.6c-8.2-8.6-17.2-16.2-27-22.9c-4.8-3-9.3-2.8-13.6,0.4c-0.3,0.2-0.6,0.4-1,0.6
								c-3.2,4.3-3.3,8.8-0.4,13.6c6.8,9.7,14.4,18.6,23,26.7c8.1,8.6,17,16.3,26.7,23C384.9,850.3,389.4,850.1,393.7,846.9z"/>
							<path opacity="0.1091" fill="#131313" d="M298.6,834.1c2.1,1.3,4.3,2.7,6.4,4c3.4,3.5,7.2,4.6,11.7,3.2
								c3.8-1.6,6.5-4.4,8.2-8.2c1.4-4.4,0.3-8.3-3.1-11.7c-4.1-5.4-8.9-10.2-14.3-14.3c-2-1.2-4-2.4-6-3.6c-3.3-3.4-7.1-4.4-11.4-3
								c-3.8,1.7-6.5,4.4-8.2,8.2c-1.4,4.3-0.5,8.1,2.9,11.4C288.7,825.5,293.3,830.1,298.6,834.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M191.4,233.5c5-8.9,6.5-18.3,4.7-28.1c-0.1-1.8-0.2-3.7-0.3-5.5
								c-1.1-1.6-2.2-3.3-3.3-4.9c-1.7-1-3.3-2.1-5-3.1c-3.4-1.1-6.4-0.4-8.9,1.9c-2.9,1.5-5.4,3.4-7.5,5.8c-2.9,2.3-5.5,5-7.7,7.9
								c-2.4,2.2-4.2,4.8-5.6,7.6c-2.3,2.6-2.9,5.6-1.7,9c0.2,3.6,0.4,7.2,0.7,10.8c-0.1-1.8-0.2-3.7-0.3-5.5
								c-1.5,5.1-2.9,10.3-4.4,15.4c-0.6,4.1,0.5,7.5,3.6,10.3c2.8,3,6.3,4.2,10.3,3.6c5.5-2.6,10.4-6.2,14.5-10.8
								C185.1,243.9,188.7,239,191.4,233.5z"/>
							<path opacity="0.1091" fill="#131313" d="M154.9,208.2c6.1-9.3,8.3-19.3,6.4-29.8c0.5-4.1-0.7-7.5-3.8-10.2
								c-2.9-3-6.3-4.1-10.3-3.5c-5.4,2.7-10.2,6.3-14.2,10.9c-4.5,4.2-7.9,9-10.5,14.5c1.9,9.6,0.3,18.8-4.9,27.5
								c-0.4,4,0.9,7.3,3.9,10c2.7,3,6.1,4.3,10.1,3.9c5.2-2.3,9.7-5.6,13.5-9.8C149.3,217.9,152.6,213.4,154.9,208.2z"/>
							<path opacity="0.1091" fill="#131313" d="M165,143.4c-0.1-7.9-0.1-15.8,0-23.7c-1-4.5-3.8-7.2-8.3-8
								c-6.6,0.2-12.1,2.9-16.4,8.3c-8.4,7.4-16.2,15.5-23.3,24.1c-5.2,4.5-7.8,10.1-7.8,16.7c-0.2,7.4-0.2,14.8,0.1,22.2
								c1,4.5,3.7,7.2,8.2,8.2c6.5,0,12-2.7,16.4-7.9c8.4-7.2,16.2-15,23.3-23.4C162.4,155.4,165,149.9,165,143.4z"/>
							<path opacity="0.1091" fill="#131313" d="M192.7,186.1c0-7.9,0-15.7,0-23.6c-1.1-4.4-3.9-7.1-8.3-8.1
								c-6.2-0.1-11.4,2.3-15.6,7.4c-7.8,6.8-15.1,14.2-21.7,22.3c-4.9,4.3-7.2,9.5-6.9,15.8c-0.1,7.4-0.1,14.9,0,22.3
								c1.1,4.4,3.8,7.1,8.2,8.2c6.2,0.3,11.3-2.1,15.5-7c7.9-6.6,15.1-13.8,21.7-21.7C190.7,197.4,193,192.3,192.7,186.1z"/>
							<path opacity="0.1091" fill="#131313" d="M198.6,136.1c-0.9-7.7-1.1-15.5-0.6-23.2c-0.4-5.1-3.2-7.8-8.3-8.1
								c-8.2,1.5-15,5.6-20.4,12.4c-10.9,10-21.2,20.6-30.9,31.9c-6.6,5.6-10.5,12.5-11.7,20.7c-0.9,7.1-0.6,14.1,1,21.1
								c0.4,5,3.1,7.8,8.2,8.2c8.1-1.3,14.8-5.2,20.2-11.8c10.9-9.7,21.1-20,30.8-30.9C193.4,150.9,197.3,144.2,198.6,136.1z"/>
							<path opacity="0.1091" fill="#131313" d="M133.4,187.6c5.5-9.5,7.7-19.5,6.6-30.1c0.3-4-1-7.3-4.1-10
								c-2.8-2.9-6.1-4.2-10.1-3.7c-5.1,2.3-9.5,5.6-13.2,9.9c-4.1,3.8-7.3,8.4-9.5,13.5c1.4,9.7-0.3,18.9-5,27.8
								c-0.2,3.9,1.2,7.1,4.1,9.8c2.6,2.9,5.9,4.3,9.8,4.1c4.8-2,9-4.9,12.5-8.8C128.6,196.6,131.5,192.4,133.4,187.6z"/>
							<path opacity="0.1091" fill="#131313" d="M166.5,237.8c5-9.7,7.1-20,6.4-30.7c-0.1-3.8-1.6-6.9-4.4-9.5
								c-2.5-2.9-5.7-4.4-9.5-4.5c-4.4,1.5-8.2,4.1-11.3,7.6c-3.5,3.1-6.1,6.9-7.6,11.3c1.1,9.9-0.4,19.5-4.4,28.8
								c0.3,3.7,1.9,6.7,4.7,9.2c2.5,2.8,5.5,4.4,9.2,4.7c4.1-1.2,7.5-3.4,10.3-6.6C163.1,245.3,165.3,241.9,166.5,237.8z"/>
							<path opacity="0.1091" fill="#131313" d="M216.3,178.8c-0.6-7.8-0.7-15.6-0.4-23.4c-0.7-4.8-3.5-7.5-8.3-8.1
								c-7.4,0.8-13.6,4.3-18.5,10.3c-9.7,8.7-18.8,18.1-27.3,28c-5.9,5-9.2,11.3-9.9,18.7c-0.6,7.2-0.4,14.4,0.6,21.5
								c0.7,4.8,3.4,7.5,8.2,8.2c7.3-0.6,13.5-3.9,18.4-9.8c9.7-8.4,18.8-17.5,27.2-27.2C212.4,192.3,215.7,186.1,216.3,178.8z"/>
							<path opacity="0.1091" fill="#131313" d="M149.5,167.4c0.4-5.1,0.7-10.2,0.9-15.4c0.2-4.9,0.2-9.8,0-14.8
								c-1.4-4.1-4.2-6.8-8.3-8c-5.5-0.6-10.2,1.3-14,5.8c-6.8,5.8-13,12.2-18.5,19.2c-4.4,3.9-6.1,8.6-5.3,14.1
								c0,3.8,0.1,7.6,0.1,11.4c0.1-2,0.2-4,0.2-6c-0.2,3.9-0.5,7.8-0.7,11.7c-1.3,4.3-0.4,8,2.8,11.2c3.2,3.1,6.9,4,11.2,2.7
								c6.6-3.7,12.5-8.4,17.7-14.1C141.1,180,145.8,174.1,149.5,167.4z"/>
							<path opacity="0.1091" fill="#131313" d="M179.3,157.7c-0.2-7.9-0.2-15.7-0.1-23.6c-0.9-4.6-3.7-7.3-8.3-8.1
								c-6.8,0.3-12.5,3.3-16.9,8.8c-8.7,7.8-16.8,16.1-24.3,25.1c-5.4,4.6-8.2,10.4-8.3,17.2c-0.3,7.3-0.3,14.7,0.2,22
								c0.9,4.6,3.6,7.3,8.2,8.2c6.7-0.2,12.4-3,16.9-8.4c8.7-7.5,16.8-15.6,24.3-24.4C176.3,170.1,179.1,164.4,179.3,157.7z"/>
							<path opacity="0.1091" fill="#131313" d="M210.6,204c0-7.9,0-15.7,0-23.6c-1.1-4.4-3.9-7.1-8.3-8.1
								c-6.2-0.1-11.4,2.3-15.6,7.4c-7.8,6.8-15.1,14.2-21.7,22.2c-4.9,4.3-7.3,9.5-7,15.7c-0.1,7.4-0.1,14.9,0,22.3
								c1.1,4.4,3.8,7.1,8.2,8.2c6.2,0.3,11.3-2,15.5-7c7.9-6.6,15.1-13.8,21.7-21.7C208.5,215.3,210.9,210.1,210.6,204z"/>
							<path opacity="0.1091" fill="#131313" d="M136.6,207.8c5-9.7,7.1-20,6.4-30.8c-0.1-3.8-1.5-7-4.4-9.5
								c-2.6-2.9-5.7-4.3-9.5-4.4c-4.5,1.6-8.3,4.2-11.4,7.8c-3.6,3.2-6.1,7-7.7,11.4c1.1,10-0.4,19.6-4.5,28.9
								c0.3,3.7,1.9,6.8,4.7,9.2c2.5,2.8,5.6,4.4,9.3,4.7c4.1-1.3,7.6-3.5,10.5-6.8C133,215.4,135.3,211.9,136.6,207.8z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M177.1,178.5c2.8-2.8,5-5.9,6.5-9.5c0.8-2.3,2.2-4.2,4.2-5.7
								c-2.4,1.9-4.9,3.8-7.3,5.7c3.8-2.9,7.5-5.8,11.3-8.7c2.9-3.4,3.4-7.2,1.8-11.4c-1.7-4.1-4.8-6.4-9.2-6.9
								c-7.5,0.9-14.8,2.9-21.7,6.1c-7.2,2.6-13.8,6.2-19.7,10.9c-2.2,3.5-4,7.1-5.4,10.9c-1.3,3.3-2.3,6.7-2.8,10.3
								c0.5-1.9,1.1-3.7,1.6-5.6c-1.5,2.4-3.1,4.7-4.6,7.1c-2.1,3.8-2.1,7.5,0.1,11.2c2.1,3.8,5.3,5.7,9.6,5.8
								c6.8-1.5,13.2-4.2,19.1-7.9C166.8,187.7,172.4,183.6,177.1,178.5z"/>
							<path opacity="0.1091" fill="#131313" d="M134.7,158.3c7.5-5.1,13.1-11.6,17-19.4c-1.8,1.7-3.5,3.3-5.3,5
								c4.1-3.8,8.1-7.6,12.2-11.3c2-3.5,2.2-7.1,0.5-10.8c-1.4-3.8-4-6.3-7.9-7.4c-5.9,0.2-11.5,1.5-16.9,4
								c-5.6,1.9-10.6,4.9-14.9,8.9c-3,3-5.3,6.4-7,10.3c-1.7,6-5.3,10.9-10.6,14.7c-1.4,3.7-1,7.3,1.2,10.7
								c1.3,2.9,3.3,4.7,6.2,5.5c2.9,1.7,5.9,1.5,9.1-0.6C124.2,165.3,129.7,162.2,134.7,158.3z"/>
							<path opacity="0.1091" fill="#131313" d="M148.4,97.2c0.9-2.8,2.1-5.4,3.6-7.8c1.2-2.3,2.8-4.3,4.8-6c-2.6,2-5.2,4-7.7,5.9
								c4-3,8-6.1,11.9-9.1c2.8-3.5,3.4-7.2,1.7-11.4c-1.7-4.1-4.8-6.4-9.3-6.8c-7.6,0.9-14.8,3-21.7,6.2c-7.2,2.6-13.7,6.3-19.7,11
								c-3.8,4.1-7.1,8.6-10,13.4c-2,3.7-3.8,7.6-5.3,11.6c-2.6,3.8-2.8,7.7-0.6,11.7c2.4,3.9,5.9,5.7,10.5,5.3
								c7.9-2.2,15.3-5.4,22.2-9.8C136.1,107.7,142.6,103,148.4,97.2z"/>
							<path opacity="0.1091" fill="#131313" d="M178.5,133.6c0.8-1.9,1.6-3.8,2.3-5.7c-0.9,2-1.7,4.1-2.6,6.1
								c1.1-2.4,2.6-4.5,4.5-6.3c1.6-2,3.6-3.7,5.9-5c3.4-3.4,4.3-7.3,2.7-11.8c-2-4.3-5.5-6.4-10.3-6.4c-8.9,1.5-17.4,4.1-25.5,7.8
								c-8.4,3.2-16.3,7.4-23.5,12.6c-1.3,2.1-2.6,4.2-3.9,6.3c-1.7,3-3.2,6.2-4.6,9.3c-2.1,4.6-3.7,9.3-5.3,14.1
								c-0.1,4.5,1.8,7.8,5.8,10c5.9,1.9,11.5,1,16.9-2.7c9.3-4.4,18.3-9.5,26.7-15.3C173.4,144,177.1,139.6,178.5,133.6z"/>
							<path opacity="0.1091" fill="#131313" d="M183.1,86.2c0.5-1.7,0.9-3.5,1.4-5.2c-1.3,2.3-2.5,4.6-3.8,6.9
								c1.2-2.3,2.8-4.4,4.7-6.2c1.6-1.9,3.4-3.4,5.6-4.7c1.4-2.1,2.8-4.1,4.3-6.2c-0.1-2.1-0.2-4.1-0.3-6.2c-1.4-1.5-2.8-3-4.2-4.5
								c-4.7-1.8-9.3-1.7-13.9,0.4c-5.3,1.1-10.3,2.8-15.2,5.1c-5.9,2.1-11.7,4.5-17.3,7.4c-5,2-9.8,4.4-14.2,7.5
								c-4.7,1.8-8,5-9.9,9.7c-3.1,4.5-5.8,9.3-8.1,14.3c-2.7,3.5-4.1,7.4-4.2,11.7c-0.6,4.8,1.3,8.2,5.8,10
								c7.4,1.2,14.3-0.4,20.7-5c11.8-5.8,23.2-12.3,34.1-19.6C175.5,98.3,180.4,93.2,183.1,86.2z"/>
							<path opacity="0.1091" fill="#131313" d="M113.8,137.9c4-4.1,7.9-8.4,11.7-12.7c2.1-2.3,4-4.8,5.7-7.4
								c-1.4,1.4-2.7,2.9-4.1,4.3c2.5-2.5,4.9-5.1,7.4-7.6c-1.4,1.4-2.8,2.8-4.2,4.1c1.4-0.9,2.7-1.8,4.1-2.7
								c3.7-1.8,5.5-4.6,5.2-8.6c-0.4-4-1.8-7.6-4.3-10.7c-2.6-3-5.9-3.9-9.8-2.5c-5.1,1.1-10,3.1-14.4,5.9
								c-3.4,2.4-6.6,5.2-9.4,8.2c-2.9,3.3-5.3,6.9-7.2,10.8c1.3-1.5,2.6-2.9,3.9-4.4c-3.1,3.5-6.2,6.9-9.2,10.4
								c-3.3,2.2-4.6,5.3-3.7,9.1c0.9,3.9,2.9,7.2,5.8,10c2.9,2.6,6.1,3.1,9.7,1.3C105.6,143.6,109.9,141.1,113.8,137.9z"/>
							<path opacity="0.1091" fill="#131313" d="M147.4,187.9c4.6-4.9,9.2-9.9,13.8-14.9c1.8-2.9,3.9-5.5,6.2-7.9
								c-1.6,1.5-3.2,3-4.9,4.4c2.8-2.3,5.7-4.6,8.5-6.9c1.8-3.5,1.9-7.1,0.2-10.7c-1.3-3.8-3.8-6.3-7.5-7.6
								c-5.4,0-10.6,1.1-15.5,3.4c-5.2,1.7-9.7,4.5-13.6,8.3c-2.2,2.3-3.9,4.9-4.9,7.9c-2.6,6.2-6.5,11.4-11.7,15.9
								c-0.9,3.7-0.3,7.1,1.8,10.3c1.2,2.8,3.1,4.7,5.8,5.7c2.4,1.8,5.1,1.8,8,0C138.6,193.9,143.2,191.3,147.4,187.9z"/>
							<path opacity="0.1091" fill="#131313" d="M202.9,126.5c0.5-1.7,1-3.5,1.5-5.2c-1.3,2.3-2.6,4.6-3.9,6.9c1.3-2.4,3-4.6,5-6.4
								c1.7-2,3.7-3.6,6-4.8c1.6-2.1,3.2-4.3,4.7-6.4c-0.1-2.1-0.1-4.1-0.2-6.2c-1.5-1.5-2.9-3-4.4-4.4c-5.1-1.7-10-1.4-14.8,0.8
								c-5.7,1.3-11.1,3.1-16.4,5.5c-6.4,2.2-12.6,4.9-18.7,7.9c-5.4,2.1-10.5,4.8-15.4,7.9c-5,1.9-8.6,5.3-10.9,10.1
								c-2.1,4.4-3.9,8.8-5.6,13.3c-2,3.5-3,7.2-2.9,11.1c-0.7,4.8,1.2,8.2,5.7,10c7.5,1.2,14.4-0.5,20.9-5
								c11.9-5.8,23.4-12.4,34.4-19.7C195.2,138.7,200.2,133.5,202.9,126.5z"/>
							<path opacity="0.1091" fill="#131313" d="M130.8,116c4.2-2.9,7.2-6.5,8.9-11c0.8-2.3,2.2-4.2,4.3-5.7c2.1-3.6,2.2-7.3,0.2-11
								c-1.7-3.8-4.6-6.1-8.8-6.7c-6.4,0.8-12.4,2.7-18.1,5.8c-6,2.5-11.2,6-15.8,10.5c-2.2,2.6-3.9,5.4-5,8.6
								c-1.5,4.4-4.3,8.2-8.1,11.2c-1.8,3.8-1.6,7.4,0.6,11c2,3.7,5.1,5.7,9.2,6c6.3-1.3,12.2-3.6,17.6-7.1
								C121.6,124.7,126.6,120.9,130.8,116z"/>
							<path opacity="0.1091" fill="#131313" d="M164.6,105.6c0.7-1.9,1.5-3.8,2.2-5.6c-0.9,2.1-1.8,4.1-2.6,6.2
								c1.1-2.4,2.6-4.5,4.4-6.3c1.6-2,3.6-3.7,5.8-5c3.3-3.4,4.2-7.3,2.5-11.7c-2-4.3-5.4-6.4-10.1-6.4c-8.6,1.4-16.9,3.9-24.8,7.6
								c-8.2,3.1-15.8,7.2-22.8,12.4c-1.6,2.3-3.2,4.5-4.8,6.8c-2,3-3.8,6.2-5.3,9.5c-2.3,4.8-4.2,9.6-5.9,14.6
								c-0.1,4.5,1.8,7.9,5.8,10c6,1.8,11.7,0.8,17.2-2.9c9.5-4.5,18.6-9.7,27.3-15.7C159.4,116.2,163.1,111.7,164.6,105.6z"/>
							<path opacity="0.1091" fill="#131313" d="M193.7,157.6c1.7-4,3.4-8,5.2-12c-1,2.1-2,4.2-3,6.3c1.2-2.4,2.8-4.5,4.7-6.4
								c1.7-2,3.7-3.7,6-5c1.3-2,2.6-4,3.9-6c-0.1-2-0.3-4.1-0.4-6.1c-1.4-1.5-2.7-3-4.1-4.6c-4.4-2-8.8-1.9-13.1,0
								c-4.9,0.9-9.7,2.5-14.3,4.6c-5.6,1.9-10.9,4.1-16.2,6.8c-4.7,1.8-9.1,4.1-13.2,7c-4.4,1.7-7.5,4.8-9.2,9.3
								c-1.4,3.1-2.7,6.3-3.8,9.5c-1.7,4.5-3.2,9.2-4.4,13.9c-0.3,4.6,1.7,7.9,5.7,10.1c4.4,1.5,8.5,1.1,12.4-1.3
								c7.2-2.4,13.9-5.6,20.3-9.7C178.9,169.4,186.7,164,193.7,157.6z"/>
							<path opacity="0.1091" fill="#131313" d="M117.8,157.6c4.5-4,8.5-8.3,12.2-13c1.8-1.9,3.1-4,4-6.5c-1.2,1.3-2.4,2.7-3.6,4
								c2.1-2.3,4.2-4.7,6.2-7c-1.3,1.3-2.5,2.6-3.8,3.9c1.4-0.9,2.8-1.8,4.1-2.8c3.5-1.8,5-4.6,4.7-8.4c-0.4-4-1.9-7.6-4.3-10.7
								c-2.4-3-5.4-3.9-9.2-2.8c-4.8,1-9.2,2.8-13.3,5.4c-3.3,2.2-6.2,4.9-8.8,7.9c-2.3,2.7-3.9,5.8-4.8,9.3
								c1.1-1.4,2.2-2.7,3.3-4.1c-3.3,3.6-6.6,7.2-9.9,10.8c-3,2.2-3.9,5.1-2.9,8.6c1.1,3.8,3,7.2,5.8,10c2.5,2.7,5.5,3.3,8.9,1.8
								C110.7,162.7,114.5,160.5,117.8,157.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M179,165c2.2-1.4,4.4-2.8,6.6-4.2c-2.2,1-4.3,2-6.5,3c4.5-2,9-4,13.5-6.1
								c-2.2,1-4.4,1.9-6.6,2.9c4.6-2,9.4-3.5,14.3-4.6c4.7-1,9.5-1.6,14.4-1.8c2.4-0.6,4.8-1.2,7.2-1.8c1.3-1.6,2.6-3.2,3.8-4.8
								c0-2.1-0.1-4.1-0.1-6.2c-2.2-4.5-5.7-7.4-10.4-8.9c-4.6-2.6-9.4-4.7-14.5-6.3c-5.7-2.4-11.5-4.4-17.5-5.9
								c-5-1.9-10.1-3.1-15.3-3.8c-4.6-1.7-9.2-1.5-13.7,0.7c-7.7,3.5-15.2,7.2-22.5,11.5c-2.4,1.4-4.8,2.9-7.2,4.3
								c-4.2,2.4-8.2,5-12,8.1c-3.2,3.3-3.9,7.1-2.1,11.4c3.9,5.4,9.4,8.5,16.4,9.1c11,2.9,22.3,4.9,33.6,6.1
								C167.2,169.7,173.4,168.7,179,165z"/>
							<path opacity="0.1091" fill="#131313" d="M132.4,140.5c7.1,1,13.2-0.4,18.4-4.3c2.8-2.1,5.9-3.5,9.3-4.4c2.3-0.7,4.7-1.3,7-2
								c4.6-1.1,9.2-1.7,13.9-1.9c4.1-1.1,6.7-3.6,8-7.6c1.5-4,1-7.6-1.6-11c-5.2-4-10.9-6.9-17.2-8.7c-6.1-2.4-12.4-3.7-18.9-3.8
								c-8,2.1-16,4.2-23.4,7.8c-2.3,1.2-4.7,2.4-7,3.6c-5.1,5-11.5,7.2-19.1,6.7c-3.6,1.8-5.7,4.8-6.3,8.9c-0.9,4,0,7.5,2.8,10.5
								c5.1,3,10.7,4.9,16.6,5.7C120.7,141.3,126.6,141.5,132.4,140.5z"/>
							<path opacity="0.1091" fill="#131313" d="M138,87.2c7,0.3,13.5-1.1,19.4-4.3c3.2-1.6,6.4-2.9,9.8-4.1
								c2.4-0.6,4.9-1.2,7.3-1.9c4.8-1,9.7-1.5,14.7-1.5c3.6-1.5,6-4.2,7.1-8c1.5-3.7,1.3-7.2-0.6-10.6c-4-3.5-8.6-6-13.7-7.5
								c-5-2-10.1-2.9-15.4-2.6c-9,1.2-18.2,2.5-26.7,5.9c-2.4,1.1-4.9,2.2-7.3,3.2c-6.3,4.1-13.3,6.3-21,6.5c-3.3,2.1-5.2,5-5.7,9
								c-0.9,3.9-0.1,7.3,2.3,10.4c4.4,2.9,9.3,4.6,14.6,5.3C127.7,88.3,132.9,88.3,138,87.2z"/>
							<path opacity="0.1091" fill="#131313" d="M176.1,126.5c2.2-1.4,4.5-2.7,6.7-4.1c-2,1-4,2-6,3.1c2.2-1.1,4.4-2.2,6.7-3.2
								c3.4-1.5,7-2.8,10.7-3.7c2.3-0.8,4.7-1.3,7.1-1.7c3.5-0.8,7.1-1.2,10.7-1.3c4.8-0.2,8.2-2.6,9.9-7c1.3-4.6,0.1-8.5-3.6-11.6
								c-7.7-4.8-15.9-8.5-24.6-11.1c-8.5-3.2-17.3-5.3-26.3-6.2c-5.8,1.5-11.5,3.4-17.1,5.7c-3.5,1.4-6.9,2.9-10.3,4.5
								c-8.6,4-16.7,8.7-24.6,13.9c-3,3.4-3.7,7.2-2.1,11.4c3.4,5.3,8.4,8.2,14.9,8.8c10.1,2.7,20.4,4.6,30.8,5.6
								C165.4,131.3,171.1,130.2,176.1,126.5z"/>
							<path opacity="0.1091" fill="#131313" d="M172.7,84.4c6.6-3.9,13.1-7.7,20.5-10c2.4-0.7,4.8-1.3,7.2-2
								c4.8-1.1,9.6-1.7,14.4-1.8c4.4-0.8,7.2-3.3,8.7-7.5c1.4-4.2,0.7-7.9-2.3-11.2c-6.1-4.2-12.7-7.4-19.9-9.5
								c-7-2.7-14.1-4.2-21.6-4.6c-4.9,0.7-9.6,1.7-14.3,3.1c-4.7,1.1-9.3,2.6-13.7,4.4c-2.8,1.2-5.7,2.4-8.5,3.7
								c-4.6,2.4-9.2,4.9-13.7,7.6c-4.4,1.3-7,4.1-7.9,8.6c-0.7,4.5,0.8,8.1,4.5,10.8c7.3,3.4,14.9,5.6,22.9,6.7
								C156.8,84.5,164.7,85.1,172.7,84.4z"/>
							<path opacity="0.1091" fill="#131313" d="M106.5,122.6c6.2-0.9,12.5-2,18.6-3.4c3.2-0.4,6-1.8,8.4-4.2
								c6.8-3.1,13.9-4.3,21.2-5.3c-1.8,0.1-3.5,0.3-5.3,0.4c4.1-0.2,8.3-0.5,12.4-0.7c3.2-1.9,5.3-4.6,6.3-8.2
								c1.5-3.4,1.6-6.9,0.3-10.3c-2.9-3.1-6.4-5.3-10.5-6.5c-4-1.7-8-2.2-12.2-1.5c-2.7,0.4-5.4,0.7-8.1,1.1
								c-4.1,0.6-8.1,1.6-12.1,2.8c-2.6,0.6-5.1,1.5-7.5,2.7c-3.4,1.2-6.5,3.1-9.1,5.7c1.7-0.4,3.3-0.7,5-1.1
								c-5,1-10.1,2.1-15.1,3.1c-3.5-0.1-6,1.5-7.3,4.8c-1.6,3.6-2.3,7.4-2.1,11.4c0.1,3.5,1.8,5.9,5.1,7
								C98.4,122,102.4,122.7,106.5,122.6z"/>
							<path opacity="0.1091" fill="#131313" d="M147.5,172.4c4.5-1,8.7-2.8,12.6-5.2c3.1-2,6.4-3.4,9.9-4.4c2.3-0.7,4.7-1.3,7-2
								c4.6-1.1,9.2-1.7,13.9-1.9c4.5-0.6,7.5-3,9-7.3c1.4-4.3,0.5-8.1-2.6-11.3c-6.5-4.4-13.6-7.8-21.2-10
								c-7.4-2.9-15-4.6-22.9-5.1c-7.5,2.6-14.8,5.3-21.8,9c-2.3,1.3-4.7,2.6-7,3.9c-3.6,2.2-7.1,4.6-10.4,7.2
								c1.6-1.1,3.2-2.2,4.9-3.2c-2.9,1.2-5.8,2.4-8.7,3.6c-4.1,1.5-6.5,4.4-7.2,8.7c-0.8,4.3,0.4,7.8,3.6,10.7
								c6.3,3.2,12.9,5.3,20,6.3C133.5,172.8,140.4,173.2,147.5,172.4z"/>
							<path opacity="0.1091" fill="#131313" d="M200.9,121.4c3.8-3.4,8.1-5.9,12.9-7.4c-2.5,0.9-5,1.8-7.4,2.7
								c4.8-1.7,9.7-3.5,14.5-5.2c-2.5,0.9-5,1.7-7.5,2.6c3.4-1.2,7-2.1,10.6-2.6c3.5-0.7,7-1.2,10.5-1.3c2.5-0.6,5.1-1.2,7.6-1.7
								c1.3-1.6,2.6-3.2,3.9-4.8c-0.1-2.1-0.2-4.1-0.3-6.2c-2.5-4.6-6.3-7.6-11.3-9.1c-4.9-2.7-10.1-4.9-15.5-6.6
								c-6.1-2.5-12.3-4.6-18.7-6.3c-5.3-1.9-10.7-3.3-16.3-4.1c-4.9-1.8-9.7-1.7-14.5,0.5c-9.1,3.3-17.8,6.7-26.3,11.2
								c-2.7,1.4-5.3,2.9-8,4.3c-4.6,1.8-8.6,4.5-11.9,8.2c-3.6,3.3-4.3,7.1-2,11.4c5,5.7,11.4,8.9,19.2,9.6
								c12.9,3.2,25.9,5.5,39.1,7C187.1,125.8,194.2,125.1,200.9,121.4z"/>
							<path opacity="0.1091" fill="#131313" d="M124.5,103.8c7,0,13.6-1.5,19.6-4.5c3.4-1.6,6.9-3,10.5-4.1
								c2.4-0.6,4.8-1.1,7.1-1.7c4.8-0.9,9.6-1.4,14.4-1.3c3.5-1.6,5.8-4.3,6.9-8c1.5-3.6,1.4-7.1-0.4-10.6
								c-3.7-3.4-7.9-5.9-12.8-7.3c-4.7-1.9-9.5-2.7-14.5-2.3c-8.4,1.1-17.2,2.3-25.2,5.4c-2.4,1-4.8,2-7.1,3
								c-6.6,4.3-14,6.7-22,7.2c-3.1,2.2-4.8,5.2-5.3,9c-0.9,3.7-0.3,7.2,1.8,10.3c3.9,2.8,8.2,4.4,12.9,5
								C115.3,105.1,119.9,105.1,124.5,103.8z"/>
							<path opacity="0.1091" fill="#131313" d="M155.2,101.5c4.5-1,8.8-2.8,12.6-5.2c3.1-2,6.5-3.5,10.1-4.4c2.4-0.7,4.8-1.3,7.2-2
								c4.7-1.1,9.5-1.7,14.4-1.8c4.2-0.9,7-3.5,8.3-7.6c1.5-4.1,0.8-7.8-1.9-11.1c-5.6-4.1-11.8-7.1-18.5-9.1
								c-6.5-2.6-13.2-3.9-20.1-4.1c-9.1,1.9-17.9,3.9-26.5,7.5c-2.7,1.2-5.4,2.4-8.1,3.7c-4.6,3.3-9.6,5.8-15.1,7.3
								c-4.1,1.5-6.5,4.4-7.2,8.7c-0.8,4.3,0.4,7.8,3.7,10.7c6.3,3.2,13,5.3,20.1,6.3C141.2,102,148.2,102.3,155.2,101.5z"/>
							<path opacity="0.1091" fill="#131313" d="M196.9,144.9c3.8-3.4,8.2-5.9,13.1-7.4c-2.5,0.9-5,1.8-7.5,2.7
								c4.9-1.7,9.8-3.4,14.7-5.1c-2.5,0.8-5.1,1.7-7.6,2.5c3.5-1.2,7.1-2,10.7-2.5c3.5-0.7,7.1-1.1,10.7-1.2
								c2.6-0.5,5.3-1.1,7.9-1.6c1.3-1.6,2.7-3.2,4-4.7c-0.1-2.1-0.2-4.2-0.4-6.2c-2.7-4.7-6.7-7.8-11.9-9.3
								c-5.2-2.8-10.6-5.1-16.2-6.8c-6.4-2.6-12.9-4.8-19.5-6.6c-5.5-2-11.2-3.5-17-4.3c-5-1.9-10.1-1.8-15.1,0.3
								c-8.5,3.6-16.6,7.3-24.6,11.8c-2.5,1.5-5.1,2.9-7.6,4.4c-4.5,1.9-8.3,4.6-11.5,8.2c-3.6,3.3-4.3,7.1-2.1,11.4
								c5,5.7,11.4,8.9,19.3,9.6c12.9,3.2,25.9,5.6,39.1,7.1C183.1,149.3,190.2,148.5,196.9,144.9z"/>
							<path opacity="0.1091" fill="#131313" d="M108.3,142.6c7.1-1.3,14.1-2.6,21.2-3.9c3.6-1.8,7.4-3.3,11.3-4.6
								c2.4-1,4.8-1.6,7.3-2c3.6-0.9,7.3-1.5,11-1.7c-2,0.1-4,0.1-6.1,0.2c4.4-0.1,8.9-0.3,13.3-0.4c3.5-1.6,5.7-4.3,6.8-8
								c1.5-3.6,1.4-7.1-0.3-10.5c-3.6-3.4-7.7-5.8-12.5-7.2c-4.6-1.9-9.3-2.7-14.3-2.3c-7.9,1.2-16.1,2.5-23.6,5.5
								c-2.4,1-4.8,2.1-7.2,3.1c-6.4,4.4-13.6,6.7-21.5,7.2c-2.8,2.4-4.4,5.4-4.8,9.1c-0.9,2.8-0.7,5.5,0.5,8c0.4,2.7,2.2,4.4,5.2,5
								C99.1,141.8,103.6,142.7,108.3,142.6z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M218.3,226.9c5.8-1.1,11.5-2.3,17.3-3.4c9.2-1.3,18.4-2.7,27.6-4.2
								c15.4-2.5,30.9-5,46.3-7.5c4.8-1.4,6.9-4.6,6.6-9.5c-2.4-7.5-7.2-13.2-14.3-17.2c-10.9-8.6-22.3-16.4-34.2-23.6
								c-6.3-5.3-13.3-7.7-21.2-7.3c-15.4,2.5-30.9,5-46.3,7.5c-6,1-12,1.9-18.1,2.6c-8.2,0.8-16.3,2.4-24.3,4.6
								c-4.7,1.3-7,4.5-6.8,9.4c2.1,7.4,6.7,13.2,13.6,17.3c10.5,8.7,21.5,16.6,33,23.9C203.7,224.7,210.6,227.2,218.3,226.9z"/>
							<path opacity="0.1091" fill="#131313" d="M172,197.2c7.9,0.6,15.7,0.2,23.4-1.2c6.9-1.1,13.7-2.3,20.5-3.7
								c-1.9,0.3-3.8,0.6-5.7,1c18.5-3,37.1-6,55.6-9c4.3,0.3,7.7-1.3,10.2-4.9c2.5-3.6,2.8-7.3,1-11.3c-4.5-5.6-9.8-10.3-15.9-14.1
								c-5.7-4.3-12-7.6-18.8-9.8c-26.2,4.2-52.4,8.4-78.5,13.1c1.9-0.3,3.8-0.6,5.7-0.9c-6.4,0.3-12.8,0.6-19.1,0.9
								c-4.3-0.3-7.7,1.3-10.2,4.8c-2.6,3.5-3,7.2-1.4,11.2c4.2,5.6,9.2,10.3,15.1,14.1C159.4,191.7,165.4,195,172,197.2z"/>
							<path opacity="0.1091" fill="#131313" d="M175,142.6c8.4,0.6,16.8,1.2,25.3,1.8c9.5-1.2,19-2.5,28.5-4
								c15.6-2.3,31.1-4.7,46.7-7.2c3.8-0.6,6.7-2.6,8.7-5.8c2.4-3,3.4-6.4,2.8-10.1c-2.4-4.2-5.6-7.6-9.7-10.2
								c-3.8-3.1-8-5.1-12.8-6c-15.5,2.5-31,5-46.5,7.3c-6.2,0.9-12.4,1.9-18.6,3c-10.9,2.4-21.8,2.7-33,0.8
								c-3.6,0.9-6.4,2.9-8.4,6.1c-2.4,2.9-3.4,6.2-3.1,9.9c2,3.9,4.8,7,8.5,9.4C166.8,140.3,170.6,142,175,142.6z"/>
							<path opacity="0.1091" fill="#131313" d="M213.1,186.2c30.5-4.9,61.1-9.8,91.6-14.7c4.2-1.8,6.3-5,6.6-9.5
								c-1-6.3-4.4-11-10.2-14.3c-8.2-6.7-16.9-12.7-26.2-18c-5.1-4.2-10.8-5.7-17-4.4c-15.4,2.5-30.9,5-46.3,7.5
								c-14.5,2.3-28.9,4.4-43.4,6.7c-4.2,1.8-6.4,4.9-6.8,9.4c0.8,6.2,4,11,9.6,14.4c7.9,6.8,16.4,12.9,25.3,18.3
								C201.4,185.8,206.9,187.4,213.1,186.2z"/>
							<path opacity="0.1091" fill="#131313" d="M205.2,139.2c7.9,0.5,15.8,1,23.7,1.5c8.8-1.8,17.7-3.4,26.6-4.8
								c15.4-2.5,30.9-5,46.3-7.5c4.2,0,7.4-1.7,9.7-5.3c2.5-3.4,2.9-7.1,1.4-11c-3.9-5.2-8.6-9.4-14.1-12.8
								c-5.2-3.9-10.9-6.8-17.1-8.5c-15.4,2.5-30.9,5-46.3,7.5c-5.9,0.9-11.8,1.9-17.7,3.2c-9.9,2.6-20,2.9-30.3,0.8
								c-7.9-0.3-14.1,8.7-11.5,16c3.5,5,7.9,9.2,13.1,12.6C193.9,134.7,199.3,137.5,205.2,139.2z"/>
							<path opacity="0.1091" fill="#131313" d="M147.2,176.5c8.5,0.7,16.9,1.3,25.4,2c9.5-1.2,19.1-2.5,28.6-3.9
								c15.6-2.3,31.1-4.7,46.7-7.2c3.7-0.7,6.6-2.7,8.6-5.9c2.4-3,3.4-6.3,2.9-10c-2.2-4.1-5.3-7.4-9.2-9.9
								c-3.6-3-7.7-4.9-12.3-5.7c-15.5,2.5-31,5-46.5,7.3c-6.2,0.9-12.4,1.9-18.6,3c-10.8,2.4-21.8,2.7-32.9,0.8
								c-3.5,1-6.3,3-8.3,6.2c-2.4,2.9-3.5,6.1-3.3,9.8c1.8,3.7,4.4,6.7,7.9,9C139.5,174.4,143.1,176,147.2,176.5z"/>
							<path opacity="0.1091" fill="#131313" d="M191.1,229.7c30.6-4.7,61.2-9.7,91.8-14.6c4.2-1.8,6.4-5,6.6-9.5
								c-1.1-6.4-4.6-11.2-10.5-14.6c-8.5-6.9-17.4-13.1-26.9-18.6c-5.2-4.3-11-5.9-17.4-4.7c-15.4,2.5-30.9,5-46.3,7.5
								c-14.5,2.3-29,4.4-43.5,6.7c-4.2,1.7-6.5,4.8-6.8,9.4c0.9,6.3,4.2,11.2,10,14.6c8.2,7,16.8,13.3,26,18.8
								C179.1,229.2,184.8,230.8,191.1,229.7z"/>
							<path opacity="0.1091" fill="#131313" d="M236.3,181.5c5.8-1.1,11.7-2.1,17.5-3.2c9.2-1.3,18.4-2.8,27.6-4.3
								c15.4-2.5,30.9-5,46.3-7.5c4.5-1.6,6.7-4.8,6.5-9.5c-1.9-7-6.1-12.3-12.7-16c-9.9-7.8-20.2-14.9-31-21.3
								c-5.8-4.8-12.3-6.9-19.5-6.1c-15.4,2.5-30.9,5-46.3,7.5c-6,1-12,1.9-18.1,2.7c-8.3,1-16.6,2.4-24.7,4.3
								c-4.5,1.5-6.8,4.6-6.8,9.4c1.6,7,5.6,12.3,12.1,16.1c9.5,7.9,19.5,15.1,30,21.6C222.8,180.1,229.2,182.2,236.3,181.5z"/>
							<path opacity="0.1091" fill="#131313" d="M160.7,158.8c8.5,0.7,16.9,1.3,25.4,2c9.5-1.2,19.1-2.5,28.6-3.9
								c15.6-2.3,31.1-4.7,46.7-7.2c3.8-0.6,6.7-2.5,8.8-5.8c2.5-3,3.3-6.4,2.7-10.2c-2.5-4.3-5.8-7.8-10.1-10.5
								c-3.9-3.2-8.2-5.2-13.1-6.2c-15.5,2.5-31,5-46.5,7.3c-6.2,0.9-12.4,1.9-18.6,2.9c-10.9,2.4-22,2.6-33.1,0.6
								c-3.7,0.8-6.5,2.8-8.5,6c-2.4,2.9-3.4,6.3-3,10c2.1,4,5,7.2,8.8,9.6C152.3,156.4,156.2,158.1,160.7,158.8z"/>
							<path opacity="0.1091" fill="#131313" d="M189.4,156.3c7.8,0.5,15.7,0.9,23.5,1.4c8.8-1.8,17.7-3.4,26.6-4.8
								c15.4-2.5,30.9-5,46.3-7.5c4.2,0.1,7.4-1.7,9.8-5.2c2.5-3.4,2.9-7.1,1.4-11c-3.9-5.2-8.7-9.5-14.3-13c-5.2-4-11-6.9-17.3-8.7
								c-15.4,2.5-30.9,5-46.3,7.5c-5.9,0.9-11.8,1.9-17.6,3.2c-9.9,2.6-19.9,2.9-30.1,0.9c-7.9-0.3-14.1,8.6-11.5,16
								c3.6,5.1,8,9.3,13.2,12.7C178,151.7,183.4,154.5,189.4,156.3z"/>
							<path opacity="0.1091" fill="#131313" d="M234.2,206.4c5.7-1.2,11.5-2.3,17.2-3.5c9.2-1.3,18.5-2.7,27.7-4.2
								c15.4-2.5,30.9-5,46.3-7.5c4.8-1.4,7-4.5,6.6-9.5c-2.6-7.6-7.5-13.4-14.8-17.5c-11.2-8.7-22.8-16.8-35-24.1
								c-6.4-5.4-13.6-7.9-21.6-7.6c-15.4,2.5-30.9,5-46.3,7.5c-6,1-12,1.9-18.1,2.6c-8.2,0.8-16.2,2.4-24.2,4.6
								c-4.8,1.3-7.1,4.4-6.8,9.4c2.3,7.5,7,13.4,14,17.5c10.8,8.8,22,16.9,33.8,24.4C219.3,204,226.3,206.6,234.2,206.4z"/>
							<path opacity="0.1091" fill="#131313" d="M150.6,197c8.6,0.8,17.3,1.6,25.9,2.4c8.8-1.3,17.7-2.7,26.5-4.2
								c15.4-2.6,30.8-5.1,46.2-7.5c4-0.3,7-2.2,9.2-5.6c2.4-3.2,3.1-6.7,2-10.6c-3.2-4.7-7.2-8.5-12.1-11.5
								c-4.5-3.5-9.5-5.9-15.1-7.2c-15.4,2.5-30.8,5-46.2,7.6c-5.7,1-11.4,1.9-17,3c-10.7,2.5-21.5,2.5-32.5,0
								c-3.9,0.4-6.9,2.3-9,5.6c-2.5,3.1-3.3,6.5-2.5,10.3c2.7,4.5,6.3,8.2,10.8,11C140.9,193.5,145.5,195.8,150.6,197z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M287.3,169.6c2-0.1,4-0.3,5.9-0.4c-2.7-0.6-5.4-1.2-8.1-1.8c3.2,0.6,6.2,1.5,9,2.9
								c2.8,1,5.3,2.4,7.7,4.2c-3.9-3.3-7.8-6.6-11.6-10c5.3,4.5,10.5,9,15.8,13.6c2.6,1.1,5.2,2.1,7.8,3.2c2-0.6,4-1.1,6-1.7
								c1-1.8,2-3.7,3-5.5c0.1-5.6-1.7-10.6-5.4-14.8c-3-5.4-6.5-10.5-10.5-15.2c-4.2-5.9-8.7-11.6-13.6-16.9
								c-3.7-4.9-7.9-9.5-12.6-13.6c-3.3-4.5-7.8-7.3-13.3-8.5c-8-1.4-16.1-2.1-24.2-2.1c-4.1-0.9-8-0.5-11.8,1.1
								c-4.9,0.8-7.5,3.6-7.7,8.6c1.5,7.8,5.5,14.1,12.1,19c9.8,10,20.2,19.3,31.1,28C272.6,165.6,279.4,168.9,287.3,169.6z"/>
							<path opacity="0.1091" fill="#131313" d="M241.5,138.9c4.5,1.5,9.1,2.4,13.8,2.7c3.8,1,7.3,2.6,10.6,4.7
								c-2.5-1.9-5.1-3.8-7.6-5.6c4,3,7.9,6,11.9,9c4.1,1.8,7.9,1.4,11.4-1.3c3.5-2.8,4.9-6.4,4.1-10.8c-2.9-7.1-6.9-13.6-11.9-19.4
								c-4.5-6.3-9.8-11.7-16-16.2c-2.3-0.8-4.6-1.5-6.9-2.3c-3.5-1-7-1.7-10.6-2c-5.1-0.6-9.9-0.4-15-0.1c2,0,3.9-0.1,5.9-0.1
								c-2.7-0.5-5.4-1-8.1-1.5c-4.3-0.9-7.9,0.3-10.8,3.6c-3,3.2-3.7,6.9-2.4,11.1c3.8,6.2,8.4,11.5,14,16.1
								C229.1,131.7,234.9,135.8,241.5,138.9z"/>
							<path opacity="0.1091" fill="#131313" d="M238.6,81.4c6.2,2.9,12.6,5.6,19,8.2c4.2,0.8,8.2,2.1,12.1,3.9
								c-1.7-0.8-3.4-1.7-5.1-2.5c3.4,1.7,6.5,4,9.2,6.7c2.7,3,5.8,3.8,9.5,2.4c3.7-1.5,6.8-3.9,9.1-7.1c2.2-3.2,2.2-6.4,0-9.8
								c-2.3-4.4-5.4-8.3-9.1-11.7c-2-1.5-4-2.9-6-4.4c-2.9-1.7-5.8-3.3-8.9-4.7c-1.8-1.1-3.7-1.8-5.8-2.2
								c-3.4-1.2-6.9-1.9-10.4-2.2c1.7,0.7,3.4,1.4,5.2,2.1c-4.5-1.8-8.9-3.6-13.4-5.4c-3.1-2.3-6.2-2.4-9.4-0.3
								c-3.3,2.2-5.9,5.1-7.7,8.6c-1.7,3.4-1.2,6.5,1.4,9.3C231.1,75.9,234.6,79,238.6,81.4z"/>
							<path opacity="0.1091" fill="#131313" d="M275.2,127.6c4.4,0.3,8.9,0.5,13.3,0.8c-2.2-0.2-4.4-0.3-6.6-0.5
								c3,0.3,5.9,1,8.6,2.2c2.8,0.9,5.4,2.2,7.8,3.8c-2.9-2.3-5.8-4.5-8.7-6.8c4.3,3.4,8.7,6.8,13,10.2c4.2,2.3,8.2,2.1,12-0.7
								c3.6-3.1,4.7-6.9,3.5-11.5c-3.7-8-8.3-15.3-14-22c-5.2-7.1-11.2-13.3-18.1-18.7c-2.7-1-5.3-1.9-8-2.9
								c-3.9-1.2-7.9-2.1-12-2.6c-2.4-0.5-4.8-0.8-7.2-1c-5.4-0.6-10.9-0.8-16.4-0.7c-4.3,1.3-6.9,4.2-7.7,8.6
								c-0.2,4.4,1.3,8,4.6,10.9c4.1,5.7,9,10.8,14.5,15.2C260.2,118.1,267.3,123.3,275.2,127.6z"/>
							<path opacity="0.1091" fill="#131313" d="M273.2,79.3c5.1,3.9,10.7,6.2,16.8,7.1c2.7,0.3,5.4,1.1,7.9,2.4
								c-2.1-1.2-4.2-2.4-6.3-3.7c3.8,2.3,7.7,4.5,11.5,6.8c-2-1.3-4-2.6-6-3.9c1.6,1.3,3.3,2.5,4.9,3.8c1.9,1.3,3.7,2.7,5.6,4
								c1.7,0,3.4,0,5.2,0c1.7-0.9,3.5-1.8,5.2-2.7c2.7-1.7,4.2-4,4.5-6.9c1-2,0.8-3.9-0.6-5.7c-0.8-2.7-2.1-5-3.9-7.2
								c-3.9-5.8-8.4-11.4-14.6-15c-2.5-1.5-5.1-3-7.6-4.5c-3.6-2-7.4-3.4-11.4-4.2c-2.1-0.7-4.3-1.1-6.5-1.2
								c-5.8-0.4-11.3-2.4-16.3-5.9c-4-0.2-7.3,1.3-9.8,4.4c-2.8,2.9-3.9,6.3-3.3,10.3c2.6,5.1,6.2,9.4,10.7,13
								C263.1,74.2,267.8,77.3,273.2,79.3z"/>
							<path opacity="0.1091" fill="#131313" d="M211.6,115.8c6.3,2.8,12.7,5.6,19,8.2c4.3,0.8,8.4,2.2,12.3,4.3
								c-1.8-1-3.6-1.9-5.5-2.9c3.1,1.7,6,3.7,8.7,5.9c2.8,3.4,6.2,4.6,10.3,3.5c3.9-1.3,6.9-3.7,9.2-7.1c1.8-2.7,1.9-5.3,0.3-7.8
								c-1.1-3.9-3.1-7.3-5.9-10.3c-2.9-4.3-6.6-7.6-11.1-10.1c-5.4-3.4-11.3-5.7-17.5-6.9c-5.3-0.2-10.3-1.5-14.9-4.1
								c-3.1-2.4-6.3-2.6-9.6-0.6c-3.4,2.1-6,5-7.7,8.6c-1.6,3.5-1.1,6.7,1.6,9.5C203.8,110,207.4,113.2,211.6,115.8z"/>
							<path opacity="0.1091" fill="#131313" d="M253.2,171.3c4.6,0.4,9.1,0.7,13.7,1.1c-2.3-0.3-4.7-0.6-7-0.9
								c3.1,0.4,6,1.3,8.8,2.6c2.8,1,5.4,2.3,7.8,4.1c-3.3-2.7-6.6-5.4-9.9-8.1c4.7,3.9,9.5,7.8,14.2,11.8c2.3,0.8,4.7,1.6,7,2.3
								c1.9-0.7,3.9-1.3,5.8-2c1.1-1.7,2.2-3.5,3.3-5.2c0.6-4.9-0.7-9.3-3.9-13c-2.4-4.7-5.3-9-8.7-13c-3.5-5.1-7.3-9.8-11.4-14.4
								c-3.1-4.2-6.7-8-10.7-11.4c-2.9-3.9-6.9-6.2-11.8-6.8c-4.8-0.8-9.7-1.3-14.5-1.4c-6.4-0.2-12.8-0.1-19.2,0.4
								c-4.5,1.2-7.1,4-7.7,8.6c0,4.7,1.8,8.5,5.3,11.5c4.6,6.1,9.9,11.6,15.9,16.5C237.1,160.8,244.8,166.5,253.2,171.3z"/>
							<path opacity="0.1091" fill="#131313" d="M304.6,123.3c2.1,0,4.2,0,6.4,0c-2.4-0.4-4.8-0.7-7.3-1.1c4,0.6,7.8,1.7,11.5,3.4
								c3.4,1.5,6.4,3.5,9.2,6.1c4.3,2.7,8.4,2.6,12.5-0.1c3.6-3.3,4.6-7.4,2.9-12.1c-4.3-8.7-9.6-16.8-16-24.2
								c-5.8-7.8-12.5-14.8-20.1-21c-4.2-1.9-8.6-3.3-13.1-4.3c-3.8-1.1-7.6-1.9-11.5-2.5c-2.8-0.4-5.6-0.8-8.4-1.2
								c-4.3-0.9-8.7-0.9-13-0.2c-4.6,1.1-7.2,4-7.7,8.6c0.7,6.9,4,12.6,9.9,16.9c8.3,8.6,17.3,16.6,26.7,23.9
								C291.6,120.7,297.6,123.3,304.6,123.3z"/>
							<path opacity="0.1091" fill="#131313" d="M224.5,97.7c6.4,2.9,12.9,5.7,19.3,8.5c4.3,0.8,8.4,2.2,12.2,4.1
								c-1.8-1-3.7-1.9-5.5-2.9c3.4,1.8,6.5,4,9.2,6.7c2.7,3.3,6.1,4.4,10.1,3.2c3.8-1.4,6.9-3.7,9.2-7.1c2.1-3.6,1.9-7.1-0.6-10.6
								c-2.8-5-6.2-9.4-10.3-13.3c-5.3-3.7-11.3-8.2-17.6-10.3c-2.1-0.6-4.2-1.1-6.3-1.7c-5.4-0.3-10.5-1.7-15.2-4.3
								c-3.2-2.5-6.5-2.7-9.8-0.8c-3.4,2.1-6,5-7.7,8.6c-1.6,3.6-1,6.8,1.8,9.7C216.4,91.6,220.2,95,224.5,97.7z"/>
							<path opacity="0.1091" fill="#131313" d="M257.9,96.8c5.5,4.4,11.5,6.5,18.1,6.4c3.8,1,7.3,2.6,10.5,4.9
								c-2.1-1.4-4.3-2.9-6.4-4.3c3.6,2.7,7.2,5.3,10.8,8c3.9,1.1,7.5,0.3,10.7-2.3c3.4-2.4,5-5.7,4.9-9.8c-1.9-5.8-4.8-11-8.8-15.6
								c-3.5-5-7.8-9.1-13-12.4c-2.4-1.2-4.9-2.5-7.3-3.7c-3.6-1.7-7.3-2.9-11.1-3.5c-2-0.6-4.1-0.9-6.2-0.7
								c-5.5,0.3-10.6-1.4-15.3-5.1c-4.1-0.4-7.4,1.1-10,4.2c-2.9,3-3.9,6.4-3.1,10.4c2.9,5.3,6.6,9.9,11.4,13.7
								C247.4,91.3,252.4,94.6,257.9,96.8z"/>
							<path opacity="0.1091" fill="#131313" d="M302.6,148.6c2-0.2,3.9-0.3,5.9-0.5c-2.7-0.6-5.3-1.2-8-1.7c4,0.7,7.9,2,11.6,3.8
								c3.3,1.6,6.2,3.6,8.9,6.1c2.5,1,5,1.9,7.5,2.9c2-0.6,4-1.2,6-1.8c1-1.8,2-3.6,3.1-5.4c0.2-5.4-1.4-10.1-4.9-14.1
								c-2.8-5.1-6.1-9.9-9.9-14.4c-3.9-5.6-8.2-10.9-12.8-16c-3.5-4.7-7.5-8.9-11.9-12.7c-3.2-4.3-7.4-6.9-12.7-7.8
								c-8.4-2.3-17-3.6-25.8-4c-4.2-1.1-8.2-0.8-12.2,0.7c-4.9,0.8-7.5,3.7-7.7,8.6c1.4,7.7,5.5,14,12,18.9
								c9.7,9.9,20,19.1,30.9,27.7C288.1,144.7,294.8,148,302.6,148.6z"/>
							<path opacity="0.1091" fill="#131313" d="M212.4,134.1c7.2,5.7,15,9.2,23.6,10.5c2.5,0.4,5,1,7.4,2c-2.2-1.3-4.4-2.6-6.6-3.9
								c5.1,3.3,10.3,6.7,15.4,10c4,1.3,7.6,0.6,10.8-2.1c3.4-2.5,5-5.8,4.7-10c-2.1-6-5.2-11.5-9.4-16.4
								c-3.7-5.3-8.2-9.7-13.5-13.2c-2-0.7-4-1.5-6.1-2.2c-2.9-1.1-6-1.7-9.1-1.8c-1.8-0.2-3.6-0.3-5.4-0.2
								c-5.9,0.3-11.3-1.7-16.3-5.7c-4-0.1-7.2,1.4-9.7,4.5c-2.4,2.1-3.5,4.6-3.3,7.6c-0.7,3.3,0.4,6.1,3.4,8.4
								C202.5,126.3,207.2,130.5,212.4,134.1z"/>
						</g>
					</g>
				</g>
				<g clip-path="url(#SVGID_2_)">
					<g>
						<g>
							<path opacity="0.1091" fill="#131313" d="M265.4,940.7c-0.9-5.5-1.9-11-2.8-16.4c-1.9-3.9-3.3-7.9-4.2-12.1
								c-0.8-2.5-1.4-5.1-1.6-7.7c-0.6-3.5-1-7-1-10.6c0,2.2-0.1,4.3-0.1,6.5c0.1-4.5,0.3-9,0.4-13.6c-0.2,2.2-0.3,4.4-0.5,6.6
								c0.5-4.5,1-9,1.4-13.6c-0.3,2.2-0.6,4.4-0.9,6.6c0.8-4.5,1.7-9,2.5-13.5c-0.8-3.9-3.1-6.7-6.7-8.4c-3.4-2.1-7-2.4-10.7-0.9
								c-4.2,3.5-7.5,7.8-9.8,12.8c-2.9,4.7-4.6,9.8-5.2,15.3c-0.3,5.4-0.2,10.8,0.3,16.1c0.4,5.2,1.3,10.4,2.6,15.4
								c1.2,5.5,2.8,10.9,5,16.1c4.2,6.9,6.6,14.5,7.2,22.7c2.2,3,5.3,4.7,9,5.2c3.7,1,7.1,0.5,10.3-1.4c2.7-3.5,4.4-7.5,5-12
								C266.7,949.3,266.7,945,265.4,940.7z"/>
							<path opacity="0.1091" fill="#131313" d="M230.2,914.4c0.5-6.7-1-12.8-4.4-18.2c-2.1-3.2-3.4-6.8-4.1-10.6
								c-0.5-2.6-1-5.1-1.5-7.7c-0.7-5.1-0.8-10.1-0.4-15.2c-0.3,2.5-0.6,5.1-0.9,7.6c0.6-5,1.3-10.1,1.9-15.1
								c-0.4,2.5-0.9,5-1.3,7.5c1-4.9,2-9.9,3-14.8c-0.6-4-2.8-7-6.5-8.8c-3.6-2.1-7.2-2.2-10.9-0.5c-4.5,4.1-8,8.9-10.6,14.4
								c-3.1,5.2-5.1,10.9-6,16.9c-0.6,6.3-0.7,12.7-0.2,19c0.4,6.4,1.4,12.7,2.9,18.9c1.5,6.1,3.5,12.1,6.1,17.8
								c4.4,5.8,6.7,12.5,6.9,20.1c1.7,3.7,4.7,5.9,8.8,6.5c4,0.9,7.6,0,10.6-2.8c3.1-5.3,5.1-11,6-17.1
								C230.8,926.5,231.1,920.5,230.2,914.4z"/>
							<path opacity="0.1091" fill="#131313" d="M233.3,846.7c-3.5-4.1-6-8.7-7.4-13.9c0.9,2.5,1.7,5,2.6,7.6c-1.6-5-3.1-10-4.7-15
								c0.8,2.5,1.6,5,2.4,7.6c-0.5-2.5-1-4.9-1.5-7.4c-0.6-3.7-0.8-7.3-0.6-11c-0.1-2.4,0.1-4.8,0.5-7.2c0.3-3.6,0.9-7.2,1.9-10.7
								c1-5.1-0.7-9.1-4.9-11.9c-4.7-1.9-8.9-1-12.5,2.8c-6.6,8.2-12.1,17-16.7,26.5c-5.1,9.2-9.2,18.8-12.1,28.9
								c0.4,7.3,0.9,14.5,2.2,21.7c1.3,7.8,3.3,15.5,5.7,23.1c2.2,6.6,4.8,13.1,7.7,19.4c1.3,2.9,2.7,5.8,4.1,8.6
								c1.4,3.7,3.6,6.8,6.5,9.4c3.2,3.7,7,4.4,11.4,2.2c5.8-5,9.1-11.5,9.9-19.4c3.4-13,5.9-26.2,7.6-39.6
								C237.5,860.7,236.9,853.5,233.3,846.7z"/>
							<path opacity="0.1091" fill="#131313" d="M264.3,898.2c-2.8-4.7-5.3-9.5-7.4-14.4c-1.9-4.9-3.2-10-4-15.2
								c-0.7-5-0.9-10-0.6-15c0.3-4.9,1.1-9.8,2.3-14.6c0.2-4.6-1.7-8.1-5.7-10.3c-4.1-2.1-8-1.7-11.7,1.1
								c-5.6,6.1-10.1,12.9-13.7,20.4c-4.2,7.2-7.2,14.8-9.1,22.9c0.2,6.1,0.9,12.2,2,18.2c1.1,6,2.6,12,4.6,17.8
								c2,5.8,4.3,11.5,7.1,17c2.3,4.2,4.6,8.3,7,12.4c-1-1.8-2.1-3.6-3.1-5.4c1.4,2.4,2.8,4.9,4.1,7.3c1.2,4.4,4,7,8.5,8
								c4.5,0.8,8.1-0.6,10.8-4.2c3.5-7.1,5.8-14.6,7-22.5C264.2,914,264.8,906.1,264.3,898.2z"/>
							<path opacity="0.1091" fill="#131313" d="M263.8,840.3c-3.8-3.9-6.2-8.6-7.4-13.9c0.8,2.8,1.6,5.6,2.4,8.4
								c-1.5-5.3-2.9-10.7-4.4-16c0.7,2.8,1.4,5.6,2.2,8.5c-0.5-2.5-0.9-5-1.4-7.5c-0.5-3.7-0.6-7.4-0.4-11.1c0-2.4,0.2-4.9,0.6-7.3
								c0.4-3.6,1.1-7.2,2.2-10.7c0-2.9-0.1-5.7-0.1-8.6c-1.3-1.6-2.7-3.3-4-4.9c-2.1-0.1-4.2-0.3-6.3-0.4
								c-5.3,2.4-9.1,6.2-11.6,11.4c-3.8,5.1-7.1,10.6-9.9,16.4c-3.8,6.4-7.2,13.1-10.3,19.9c-3.1,5.6-5.6,11.5-7.6,17.6
								c-2.8,5-3.7,10.4-2.6,16c1.1,6.9,2.7,13.7,4.8,20.4c2,6.6,4.4,13,7.2,19.2c2.7,6,5.7,11.9,8.9,17.7c1.6,5.1,4.6,9.3,8.8,12.5
								c3.1,4,6.9,4.8,11.4,2.2c6.1-5.8,9.5-13.1,10.4-21.7c3.7-14.5,6.6-29.2,8.6-44.1C267.7,855.9,267.3,847.9,263.8,840.3z"/>
							<path opacity="0.1091" fill="#131313" d="M208.3,890.6c-1.4-4.4-3.2-8.7-5.4-12.8c-2-3.4-3.3-7.1-4-11.1
								c-0.5-2.6-1-5.2-1.5-7.8c-0.7-5.2-0.7-10.3-0.3-15.5c-0.4,2.7-0.7,5.4-1.1,8.2c0.7-5.3,1.4-10.5,2.2-15.8
								c-0.5,2.6-1,5.3-1.4,7.9c1.1-5.1,2.2-10.3,3.3-15.4c-0.5-4.1-2.7-7.1-6.4-8.9c-3.6-2.1-7.3-2.2-11-0.3
								c-4.6,4.3-8.2,9.3-10.9,15c-3.2,5.4-5.3,11.2-6.3,17.4c-0.9,7-1.2,14-0.9,21c0.3,6.9,1.2,13.8,2.7,20.6
								c1.5,6.6,3.5,13,6.1,19.3c3,5.2,5.6,10.7,7.6,16.3c1.4,4.1,4.3,6.6,8.6,7.4c4.3,0.9,7.9-0.4,10.7-3.7
								c3.3-6.4,5.5-13.2,6.6-20.4C208.5,904.9,209,897.8,208.3,890.6z"/>
							<path opacity="0.1091" fill="#131313" d="M241.7,949.1c-1-5.5-1.9-11-2.9-16.4c-0.1-3-1.1-5.7-2.8-8
								c-1.4-2.1-2.4-4.4-2.9-6.9c-1.3-3.7-2.1-7.5-2.3-11.5c0.2,1.7,0.4,3.4,0.5,5.1c-0.3-4.2-0.6-8.5-0.9-12.7
								c0.1,1.7,0.2,3.4,0.3,5.1c-0.1-3.8,0.1-7.5,0.7-11.2c0.3-3.7,1-7.4,2.1-11c1.5-3.5,0.8-6.6-1.9-9.2
								c-2.9-2.7-6.3-4.6-10.2-5.5c-3.7-0.8-6.6,0.3-8.7,3.5c-2.9,3.7-5.1,7.7-6.5,12.2c-1.2,5.7-1.9,11.5-2.2,17.4
								c-0.1,4.6,0,9.2,0.4,13.8c0.5,5.9,1.4,11.7,2.9,17.5c1.2,5.3,3.5,10,6.9,14.2c-0.3-1.6-0.7-3.2-1-4.8
								c1.1,5.1,2.1,10.3,3.2,15.4c-0.1,3.5,1.5,5.8,4.7,7.1c3.6,1.6,7.4,2.3,11.4,2.2c3.5,0,5.8-1.6,7.1-4.8
								C241.1,956.9,241.8,953.1,241.7,949.1z"/>
							<path opacity="0.1091" fill="#131313" d="M283,887.3c-1.4-2.4-2.8-4.9-4.2-7.3c1,2,2,4,3,5.9c-2.4-4.8-4.3-9.7-5.6-14.9
								c-1.1-3.7-1.7-7.5-1.9-11.4c-0.5-3.8-0.6-7.5-0.3-11.3c0.3-5,1.1-9.9,2.4-14.7c-0.3-2.3-0.6-4.7-0.9-7
								c-1.4-1.5-2.8-2.9-4.2-4.4c-2-0.3-4-0.6-6-1c-4.6,1.2-7.9,4-10,8.2c-3.2,3.8-5.8,7.9-8,12.4c-3.1,4.9-5.7,10-8,15.3
								c-2.4,4.3-4.3,8.8-5.7,13.6c-2.3,4.1-2.6,8.4-1.1,12.8c1.1,5.8,2.5,11.5,4.2,17.1c1.8,5.7,3.8,11.2,6.2,16.6
								c4.6,10.4,10.4,20.1,16.5,29.6c3.3,3,7.1,3.8,11.4,2.2c5.3-3.2,8.3-8.1,9-14.6c2.8-9.9,4.8-20,5.9-30.2
								C287.6,897.9,286.7,892.3,283,887.3z"/>
							<path opacity="0.1091" fill="#131313" d="M218.8,867.3c-1.4-2.4-2.8-4.8-4.3-7.2c1,2.1,1.9,4.2,2.9,6.3
								c-2.4-4.8-4.2-9.7-5.6-14.9c-1.1-3.7-1.7-7.5-1.9-11.4c-0.5-3.8-0.6-7.5-0.3-11.3c0.3-5,1.1-9.9,2.3-14.8
								c0.3-4.7-1.6-8.2-5.6-10.5c-4.2-2-8.1-1.6-11.8,1.3c-5.6,6.3-10.3,13.4-14,21.1c-4.2,7.4-7.3,15.2-9.3,23.5
								c0,7.2,0.1,14.4,1,21.6c1.1,8.8,3.1,17.3,5.9,25.7c2,5.9,4.4,11.7,6.9,17.4c1,2.4,2.2,4.8,3.4,7.2c1.7,3.8,3.8,7.3,6.2,10.6
								c3.3,3.2,7.1,3.9,11.4,2.2c5.4-3.7,8.5-9,9.2-15.9c3-10.8,5.1-21.7,6.3-32.8C223.3,878.8,222.4,872.8,218.8,867.3z"/>
							<path opacity="0.1091" fill="#131313" d="M247.5,863.4c-3.4-4.3-5.8-9-7.4-14.3c0.9,2.3,1.8,4.7,2.7,7
								c-1.5-3.7-2.5-7.5-3.2-11.4c-0.9-3.7-1.4-7.5-1.5-11.3c0-2.5,0-5,0.1-7.4c0.3-4.9,1.1-9.8,2.3-14.6c0.9-5.1-0.8-9-5-11.7
								c-4.6-1.9-8.8-1.1-12.4,2.6c-6.5,7.9-11.9,16.5-16.3,25.7c-5,8.9-8.9,18.3-11.7,28.1c0.6,6.8,1.2,13.6,2.6,20.4
								c1.4,7.4,3.4,14.7,5.9,21.8c2.2,6.3,4.8,12.4,7.7,18.4c1.3,2.8,2.7,5.5,4.2,8.2c1.6,3.6,3.8,6.8,6.5,9.7
								c3.3,3.4,7.1,4.2,11.4,2.2c5.6-4.3,8.8-10.2,9.5-17.6c3.2-11.8,5.5-23.9,7-36C251.9,876.1,251.1,869.5,247.5,863.4z"/>
							<path opacity="0.1091" fill="#131313" d="M281.4,919.5c-0.3-7.2-2.4-13.6-6.4-19.3c-1.9-4.8-3.3-9.8-4.1-14.9
								c-0.8-4.9-1-9.8-0.6-14.8c0.3-4.8,1.1-9.6,2.3-14.3c0-4.5-2-7.7-5.9-9.9c-3.9-2.1-7.8-1.9-11.5,0.6
								c-5.2,5.5-9.5,11.7-12.8,18.5c-3.9,6.6-6.6,13.6-8.2,21c0.3,5.5,1,11,2.1,16.4c1,5.6,2.5,11,4.3,16.4
								c1.9,5.5,4.2,10.8,6.8,15.9c2.3,3.6,4.7,7,7.3,10.4c-1.1-1.5-2.2-3.1-3.2-4.6c1.1,4,2.2,8,3.3,12.1c1.6,3.9,4.5,6.1,8.7,6.8
								c4.1,0.9,7.7-0.1,10.6-3.1c3.2-5.6,5.3-11.7,6.2-18.1C281.9,932.3,282.3,925.9,281.4,919.5z"/>
							<path opacity="0.1091" fill="#131313" d="M210.7,914.3c-0.9-5.4-1.9-10.9-2.8-16.3c-1.9-3.2-3.3-6.5-4.2-10
								c-0.9-2.5-1.6-5-1.8-7.6c-0.8-3.8-1.2-7.7-1.1-11.7c-0.1,2.3-0.2,4.5-0.2,6.8c0.2-4.9,0.4-9.7,0.7-14.6
								c-0.2,2.2-0.3,4.4-0.5,6.6c0.6-4.8,1.2-9.6,1.8-14.4c-0.3,2.1-0.5,4.3-0.8,6.4c0.6-2.6,1.2-5.2,1.8-7.8
								c0.4-2.1,0.7-4.2,1.1-6.3c-0.7-1.5-1.5-3-2.2-4.5c-1.5-1.2-3.1-2.4-4.6-3.6c-2.6-1.8-5.2-2.3-8-1.5c-2.1-0.2-3.7,0.6-4.8,2.5
								c-2,1.6-3.5,3.5-4.6,5.9c-1.7,2.3-3.1,4.9-4.1,7.6c-1.8,3.2-2.7,6.6-2.9,10.2c-0.5,3.3-1.1,6.7-1.4,10
								c-0.8,8.3-0.5,16.7,0.7,25c0.9,6.4,2.3,12.6,4.6,18.6c0.7,2.6,1.8,5.1,3.3,7.4c3.4,5.5,5,11.6,4.9,18.4c2,3.4,4.9,5.4,8.9,6
								c3.9,0.9,7.4,0.2,10.5-2.3c3-4.6,4.8-9.6,5.5-15C211.6,925,211.7,919.6,210.7,914.3z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g id="Слой_2">
			<g>
				<path fill={color2} style={{transition: "1s"}} d="M890.6,622.8c-0.1-0.1-0.9-0.6-1.5-1.6c-1.4-2-1-3.2,1.3-3.4c0.6,0,1.1-0.1,1.7-0.1
					c-0.8-0.7-1.3-1.7-1.4-2.7c-0.1-1.4,0.7-2.6,0.9-2.9c1.7-2.4,6.6-13.2,6.3-20.1c-0.4-9.8-11.7-18-19.4-21.1
					c-0.6-0.3-1.2-0.5-1.6-0.6c-1.2,0.2-2.3,0.5-3.5,0.7c-0.1,0.5-0.2,1-0.4,1.5c-0.9,3-1.9,5.9-3.1,8.7c-0.3,0.6-0.4,1.3-1.1,1.4
					c-4.4,0.6-8.1,3.3-12.1,4.9c-0.6,0.3-1.4,0.4-2,0.2c-2.1-0.5-3.7,0.2-5.7,0.9c-3.2,1.1-6.1,3.4-9.9,3.3
					c-3.1-0.1-6.3,1.1-9.3,2.3c-3.6,1.4-7.3,1.9-11.1,2.2c-3.7,0.3-7.2,1.6-10.8,2.2c-3.2,0.5-6.5,1.3-9.8-0.6
					c-2.3-1.3-3.6,1.5-5.5,2c-1.8,0.5-3.2,1.8-4.8-0.5c-0.5-0.7-1.5-0.8-2.2-0.3c-1.9,1.3-4.9,0.9-5.7,4.1c-0.1,0.3-0.7,0.6-1.1,0.5
					c-2.8-0.7-5.7-1.4-7.1-4.5c-1.1-2.6-3.1-3.6-5.2-4.1c-2.2-0.5-4.4-1.6-6.8-0.5c-1.7,0.7-3.3-1.1-4.9-1.8
					c-3.8-1.7-7.7-1.7-11.7-1.9c-3.6-0.1-7.3,0.5-10.8-1.3c-0.8-0.4-1.6-0.4-2.3,0c-5.8,3.3-11.9,2.9-18.2,1.1
					c-1.7-0.5-3.3-0.1-5.1-0.5c-3.3-0.7-6.5-2.8-10.2-1.2c-1.9,0.8-4.9-0.3-7.1-2.3c-2.3-2-5.3-1-7.9-1.6c-0.2,0-0.5,0.4-0.6,0.7
					c-0.5,1.5-1.7,1.6-2.8,1.6c-1.3,0.1-3.3,0.3-3.6-1.4c-0.2-1.1,1.8-0.9,2.7-1.3c1.2-0.5,2.6-0.3,3.3-1.8c0.2-0.5,0.9-0.6,1.4-0.5
					c3.2,1,5.9-0.8,8.9-1.4c0.6-0.1,1.5-0.2,1.4-1.3c-0.1-1.1-0.8-1.1-1.5-1.2c-4.5-0.9-9-1.9-13.6-2.6c-2.6-0.4-4.9-1.1-7.4-0.2
					c0.1,0.6,0,1.3-0.6,1.3c-2.7,0.2-5,1.6-7.9,1.2c-2.1-0.3-4.4,0.8-6.5,1.4c-4.7,1.3-9.5,1.2-14.3,1.6c-4,0.3-7.9,1.8-11.8,3
					c-1.2,0.4-1.8,2.1-0.4,3.2c0.8,0.7,0.5,1.6,1.1,2.2c1.6-2.5,4.5-1.4,6.6-2.7c2.8-1.7,6.2-0.9,9.3-0.8c1,0,1.5,0.3,1.4-1.1
					c-0.1-1.4,1.1-1.2,1.8-1.5c1.4-0.4,2.8-0.8,4.3-1.1c0.8-0.2,1.6-0.5,1.9,0.8c0.2,1.1-0.6,1.2-1.2,1.4c-0.5,0.2-1.2,0.1-1.6,0.9
					c0.2,0.1,0.5,0.5,0.6,0.4c3.4-1.5,7.2-0.5,10.7-1.3c2.7-0.7,5.7,0.8,8.6,0.2c0.4-0.1,0.8,0.5,0.7,1c0,0.8-0.1,1.6-0.9,1.8
					c-0.8,0.3-1.7,0.5-2.5,0.7c1.8,0,2.8,0.9,2.8,3.1c0,1,0.2,2.1,0.5,3.1c0.5,1.7,0,2.6-1.4,2.9c-11.3,2.6-22.2,6.8-33.9,7.4
					c-3.7,0.2-7.5,0.1-10.8,2.4c-3.6,2.6-3.3,8.2,0.7,10.6c1.8,1.1,3.8,1.7,5.8,1.6c7.5-0.4,14.8-1.3,22.2-2.6
					c-0.2-0.5-0.4-1.1-0.9-0.9c-2.5,0.9-5.4-0.6-7.9,0.7c-2.5,1.3-5.4-0.4-8.1,0.5c1.3-1.5,2.7-2.2,4.6-2.4c3.3-0.3,6.4-1.1,9.8-1.1
					c0.4,0,1.1-0.3,1.1-0.6c0-2.2,1.8-2.5,3-3.1c-0.5-1.1-1.1-0.6-1.6-0.5c-1.8,0.6-3.5,1-4.9-1.2c-0.5-0.7-1.4-0.8-2.2-0.6
					c-0.6,0.2-1.4,0.5-1.8,0.2c-3-2.1-5.8,0.1-8.7,0.1c-0.6,0-1.1,0.5-1.7,0c0-0.2,0-0.5,0-0.5c5-1.8,10.1-3.1,15.3-4
					c1.7-0.3,3.3-0.9,4.9-1.4c5.4-1.7,10.9-3,16.3-4.5c1.1-0.3,1.7,0.5,2.3,1.1c3,2.8,6.3,4.5,10.2,5c5.6,0.7,11.1,2.2,16.7,2.9
					c7,0.8,13.8,2.7,20.8,3.7c2.5,0.4,5.2-0.8,7.5,1.2c2.1,1.7,4.9,1.8,6.8,4c0.1,0.1,0.1,0.2,0.2,0.2c2.6,0.3,4.2,1.8,5.4,4.3
					c0.4,0.9,1.4,0.4,2.2,0.3c2.3-0.4,4.4-0.8,7-0.5c3,0.4,6.5,0.2,9.4-0.5c10.9-2.9,21.9-2.6,33.1-2.1c3.5,0.1,6.9-0.3,10.4,0.4
					c0.6,0.1,1.4-0.9,2.1-0.8c4.8,0.2,9.3-2.2,14.1-0.8c0.5,0.2,1,0.4,1.5,0.2c3.1-1.5,6.7,0.2,9.9-1.3c0.6-0.3,1.2,0.2,1.8,0.2
					c1.8-0.2,3.6,1.1,5.5-0.2c1-0.7,2.7-0.7,4,0.3c1.8,1.3,3.8,1.3,5.5,1.1c4.6-0.6,9.3,0.4,13.9-0.1c5.2-0.5,10.6,0.3,15.8-1.4
					c2.9-1,6,0.5,8.9,1.5c1.3,0.4,0.5,1.6,0.7,2.5c0.2,0.9-0.3,1.1-1,1.1c-0.9-0.1-1.8,0.1-2.5,0.7c-1.4,1.1-2,2.3-0.2,3.9
					c0.5,0.4,1.7,1.1,0.7,1.5c-1.1,0.5-1.4,2.9-3.1,1.6c-2.1-1.6-6.8-2.1-8.4-0.4c-1.5,1.5-2.9,2.1-4.9,1.2c-0.4-0.2-1,0-1.5,0.1
					c-1.4,0.4-2.9,0.8-1.6-1.8c0.2-0.4,0.1-1.3-0.3-1.1c-2.6,1.2-5-1.7-7.6-0.7c-1.6,0.6-3.2,1.1-4.3,2.7c-1.4,2-3.2,1.6-5.2,0.6
					c-0.6-0.3-0.7-2.1-1.7-1.1c-0.7,0.6-1.6,1.3-1.5,2.5c0.4,4.1-2.5,7-3,10.8c-0.1,0.7-0.8,0.9-1.4,1c-6.4,1.7-12.6,4.3-19.2,5.6
					c-2.4,0.5-4.8-0.2-7.2,0.6c-2.2,0.7-4.4,1.6-6.5,2.6c-2.3,1-4.4,2.8-6.6-0.5c-0.5-0.7-1.4-0.5-2.2-0.3c-1.9,0.4-3.8,1.4-5.8,1.1
					c-0.8-0.1-0.9,0.6-1.1,1.2c-0.5,1.4-1.8,1.5-2.9,1.1c-1.1-0.4-0.6-1.5-0.5-2.5c0.3-2.5-0.6-3.5-2.8-2.9
					c-2.8,0.7-5.4,1.2-7.4-2.2c-0.5-0.9-1.9-1.2-2.9-0.8c-1.1,0.4-0.4,1.6-0.3,2.5c0.2,1.9-1,2.3-2.2,2.2c-2.4-0.1-4.7-0.5-5.8,2.9
					c-0.5,1.6-2.6,2.5-3.9,3.7c-0.8,0.7-1.8,1.3-2.2,2.3c-0.7,1.5-1.2,3-3.2,2.1c-1.9-0.8-4-1.4-3.6-4.5c0.1-1.1-0.7-1.9-1.4-2.7
					c-1.2-1.5-0.7-2.9,1-3c1.8-0.1,3.5-1,5.4,0.5c0.9,0.7,2.7,0.4,2.4-2c-0.2-1.8,0.7-2.6,2.3-3.1c3.6-1.1,6.8-3.1,10.7-3.6
					c1.8-0.2,3.6-1.7,5.7-1.9c0.8-0.1,1.1-0.8,1.1-1.7c0.1-3.2,1-4.2,4-4.5c2.6-0.2,4.6-1.9,7.1-2.4c0.3-0.1,0.7-0.2,0.7-0.7
					c-0.1-0.7-0.6-1-1.1-1.2c-2.8-0.9-5.6-1.6-8.5-1.8c-2-0.1-4.1,0.8-5.7-1.7c-0.4-0.6-1.3-0.1-2,0.1c-2.4,0.7-4.6,0.7-7.1-0.2
					c-3-1.1-6.1,0.5-9.2,0.9c-2.6,0.3-4.9,1.7-7.7,1.1c-0.8-0.2-1.5,0.3-1.7,0.9c-1,2.5-3.2,3.5-4.9,5.2c-1.8,1.8-4.5,1.2-6.9,1.4
					c-0.6,0-1-0.5-1.1-1.1c-0.4-2.8-1.9-2.4-3.6-1.9c-3.7,1.1-7.6,1.8-11.1,3.5c-0.7,0.4-1.4,0.6-1.7,1.5c-0.2,0.9-1,1.3-1.5,1.9
					c-0.9,1.1-2,0.5-2.9-0.1c-1.4-0.9,0.3-1.5,0.1-2.4c-3-1.7-5.2,0.1-7.4,1.8c-1.2,0.9-0.6,1.7,0.4,2.5c0.6,0.5,1.6,1,0.9,2
					c-0.4,0.6-1.7,0.6-1,1.8c0.5,0.9,1.5,1.7,2.5,1.5c1.6-0.3,2.7,0,3.2,2c0.3,1,1.2,0.7,1.8,0.4c4.1-1.8,8.6-0.9,12.8-1.3
					c0.9-0.1,1.7-0.1,2.6-0.3c3.8-1.1,7,0.1,9.9,3c0.7,0.7,1.4,0.7,2.2,0.4c1-0.3,2-0.5,3-0.7c0.5-0.1,1.1,0.1,1.3,0.7
					c0.2,0.7-0.1,1.2-0.6,1.3c-2.4,0.5-2.5,2.5-2.4,4.6c0,0.4,0,0.9-0.2,1.1c-2.1,1.5-2.3,3.6-1.3,6c0.1,0.2-0.1,0.6-0.3,0.7
					c-2.8,1.4-4.1,4.7-6.8,6.2c-0.5,0.3-1.2,0.5-1.5,1c-2,4.2-6.2,5.3-9.2,8c-1,0.9-2.3,1.8-3.5-0.3c-0.6-1.1-1.6-0.4-2.4-0.1
					c-2.4,0.7-4.3,0.3-6.8-0.8c-3.2-1.4-6.3-3.6-9.8-4.3c-0.8-0.2-1.7,0.1-2,0.9c-0.9,2.1-2.3,1.8-3.9,0.8c-0.5-0.3-1.1-0.8-1.5-0.3
					c-1.8,2.4-3.6,0.4-5.4-0.4c-1.5-0.6-3-1.2-3.4,1.5c-0.3,2-2.2,2.8-3.4,3.9c-1,0.9-2,3.1-4.1,1.2c-0.9-0.8-1.5,0.7-1.7,1.5
					c-0.5,1.6-1,2.7-3,2.6c-1.2,0-2.5,1.1-3.6,2c-4.4,3.7-8.7,7.5-13.1,11.1c-3.5,2.9-7.3,5.4-10.7,8.6c-0.2,0.2-0.6,0.4-0.9,0.5
					c-2.8,0.6-5.4,1.5-7.7,3.5c-1.2,1-2.4,0.8-3.1-1.1c-0.3-0.8-0.7-1.4-1.5-1.5c-2.9-0.4-2.9-3-3.4-5.2c-0.1-0.3,0-0.9,0.2-1
					c1.5-1.1,1.8-3.2,3.3-4.5c1.1,3.6,2.4,2.9,4.7,1.2c2.6-1.9,6.2-2.4,8.3-5c2.4-3.1,6-4.3,8.4-7.5c2.4-3.1,5.4-6.5,9.6-7.4
					c0.4-0.1,0.8-0.4,1-0.8c3.2-4.8,7.7-5.5,12.8-4.4c1.3,0.3,5.4-2.6,5.7-3.9c0.3-1-0.2-1.4-0.9-1.1c-4.7,2.4-5.9-2-7.8-5.1
					c-0.5-0.8-1-1.8-1.7-2.3c-1.3-1-2.6-1.7-4.1-0.4c-6.8,6-13.7,11.8-20.5,17.7c-2.3,2-4.1,4.6-6.7,6.3c-0.9,0.6-1.8,1.3-2.8,0.8
					c-0.5-0.3-1.2-1.3-1.1-1.9c0.2-1.2,0.7-2.4,1.9-2.7c1.9-0.5,2.5-2.1,3.5-3.7c1.4-2.3,3.1-5.1,5.9-6c2.2-0.7,3.4-2.9,4.9-4.5
					c2.6-2.7,5-5.8,8.4-7.5c2.2-1.1,2.9-3.8,4.2-5.8c0.6-0.9,1-1.5,1.9-1.8c0.8-0.2,1.8-0.2,1.8-1.6c0-0.9,1.7-1.7,0.3-2.6
					c-0.9-0.6-2.3-0.7-3.1,0.2c-0.8,0.9-1.6,1.1-3.3,1c3.2-2.5,4.8-6,8.9-7c2.1-0.5,5.5-1.4,5.8-4.9c0.3-3.9,2.3-3.3,4.7-2.6
					c0.3,0.1,0.7,0.1,1,0c6-2,12.5-1.5,18.6-3.6c1.7-0.6,3.5-1.5,5.5-0.5c0.4,0.2,1.1,0,1.5-0.3c6.5-4.2,14.2-4.3,21.1-6.7
					c0.1,0,0.2,0,0.3-0.1c1.5-0.3,3.4-1.9,3-2.7c-0.7-1.4-2.2-2.9-4.2-3c-3.8-0.3-7.4,0-11.2,0.1c-3.8,0.1-8.3-0.7-11.8-3.7
					c-2.4-2-5.6-2.4-7.6-5.3c-0.4-0.6-1.2-0.2-1.7,0.2c-1.7,1.4-2.3,3.2-1.4,5.5c1.9,4.6,0.9,6.9-2.8,9.3c-4.1,2.7-8.6,3.1-13.3,3.5
					c-8,0.7-15.9,2.4-23.8,2.9c-4,0.3-7.8,1.2-11.7,1.9c-0.8,0.1-1.8,0-2.6-0.3c-2-0.8-3-0.3-2.9,3c-1.1-2.6-2.3-3.7-4.5-1.7
					c0.8-2.5,2.4-3,3.6-3.3c2.9-0.7,5.7-2.6,9-1.5c0.5,0.2,1.2-0.2,1.8-0.3c11.4-3,22.8-6,34.2-9c1.2-0.3,2.4-0.5,3.1-1.7
					c-1.4-0.1-2.5,0.6-3.7,1c-9.5,2.8-18.9,5.6-28.7,7.5c-6,1.2-12.1,1.5-17.9,3.2c-3.2,1-4.6,3.8-3.7,7.4c0.9,3.7,4.1,5.9,8.6,5.5
					c10.5-1,20.8-2.5,31.2-4.1c0.9-0.1,1.8-0.6,2.7,0.1c-0.2,1.2-1,1.4-1.7,1.7c-5.3,2.9-10.2,6.4-14.6,10.7
					c-6.7,6.4-13.2,13.1-19.6,19.9c-4,4.3-5.5,10.1-2.9,15.8c1,2.2,0.4,3.3-0.9,4.6c-2.8,2.6-5.3,5.5-7.2,9
					c-2.2,3.9-0.5,9.7,3.2,11.9c2.7,1.7,5.3,1.5,8.5,1.1c-3,3.4-5.9,6.1-8.2,9.4c-1.8,2.5-2.8,5-1.5,8.4c0.9,2.1,1.5,4.5,1.8,6.7
					c0.5,3.4,2.4,4.5,5.1,5c2.2,0.4,4.1-0.2,6-1.4c3-1.9,5.3-4.7,8.2-6.6c2.7-1.8,4.6-4.8,7.7-6c-1.4,1.9-3,3.7-4.5,5.5
					c-3.9,4.7-7.8,9.4-10,15.5c-2.5,6.7,0.2,14.4,6,16.3c3.7,1.2,6.4-0.6,8.9-3.1c5.6-5.7,11-11.7,16.7-17.2
					c8.5-8.1,17.5-15.4,26.9-22.2c4.6-3.3,9.3-6.5,14.3-9c0.9-0.4,1.7-1,2.5,0.3c2.9-4.3,7.2-3.5,11.4-2.8c0.8,0.1,1.5,0.5,2.3,0.6
					c0.9,0,2.1,0.4,2.1,1.7c0,1.2,0,2.6-1.5,2.8c-1.5,0.2-2.5,1-3.1,2.5c-0.3,0.9-1.2,0.9-1.9,1.2c-3.3,1.6-6.1,3.9-8,7.4
					c-1.2,2.1-2.5,4.3-5.1,4.5c-1.8,0.1-2.3,1.8-3,3.1c-2,3.8-5.7,5.5-8.5,8.3c-0.6,0.6-1.5,0.9-2.1,1.4c-0.9,0.7-2.2,1.7-3,0.5
					c-0.7-1-1.1-2.3-0.8-4.1c0.9-5.6,5.1-8.1,7.9-11.9c0.1-0.2,0-0.6,0-0.8c-2.6,0.9-4.3,3.1-6.3,5c-2.4,2.3-3.5,5.3-3.6,8.8
					c-0.3,5.7,4.1,13.3,11.5,9.9c1.7-0.8,3.2-1.8,4.6-3.1c8.9-7.7,18.1-14.9,27.8-21.3c7-4.6,13.2-10.4,18.9-16.8
					c3-3.4,5.5-7.3,8.5-10.8c4.8-5.5,11.3-7.7,17.7-10.2c8.9-3.5,17.8-7.2,26.5-11.4c12.3-5.9,24.5-11.9,36.9-17.7
					c9.3-4.4,18.7-8.3,27.8-13.2c6-3.2,12.2-5.9,18.4-8.6c1-0.4,2.2-1.3,2.7-2.7C901.5,632.5,892.7,624.3,890.6,622.8z"/>
				<path fill={color2} style={{transition: "1s"}} d="M683.7,693.3c-2.5,2.4-5.8,2.9-8.9,3.9c-0.4,0.1-0.8,0.7-1,1.2c-0.7,2.4-2.8,3.6-3.6,6
					c-0.3,0.8-0.9,1.3-1.7,1.6c-2.9,1-5,3-6,6.2c-0.2,0.6-0.3,1.4-1,1.5c-2.8,0.7-4.8,3.1-7.8,3.3c-3.2,0.2-4.9,3.3-6.1,5.7
					c-1.1,2.2-0.5,5.5-0.9,8.3c-0.2,1.3,1.3,1,2,1.3c0.7,0.3,1.5,0.3,1.5-0.9c-0.1-2.1,0.8-1.1,1.5-0.7c2,1.2,3.4,0.6,4.7-1.2
					c2.1-2.7,6-2.6,7.7-5.8c0-0.1,0.2-0.1,0.2-0.1c1.9,0.5,2.6-1,3.8-2.1c5.3-4.9,10.7-9.7,16.3-14.3c1.8-1.5,4-2.7,5.2-5
					c0.7-1.4,1.1-2.8,2.6-3.6c1.4-0.7,0.5-2.2,0.2-3.3c-0.4-1.4-1.3-1.1-2.1-0.5c-1.2,0.9-2.1-0.1-2.8-0.8
					C686.4,693.1,685.2,692.9,683.7,693.3z"/>
				<path fill={color2} style={{transition: "1s"}} d="M741.7,703.1c2,0.7,4,1.8,5,0c1.3-2.4,3.2-2.8,5.3-3.3c0.7-0.2,1.8-0.2,1.5-1.4
					c-0.4-1.7,0.6-2.4,1.4-3.3c1.8-1.9,2.9-4.3,3.9-6.8c0.5-1.4,0.7-3.5-0.8-4c-1.4-0.5-3.4-0.3-3.8,2.1c-0.1,0.9-0.4,1.6-1.3,1.9
					c-3.4,1.2-4.8,5.2-8,6.6c-0.6,0.3-0.2,1.2-0.3,1.8C743.9,698.7,744.2,701.3,741.7,703.1z"/>
				<path fill={color2} style={{transition: "1s"}} d="M677,635.7c-8.2,0.2-14.5,1.5-16.1,3.3C666.4,639.1,674.1,637.7,677,635.7z"/>
				<path fill={color2a} style={{transition: "1s"}} d="M822.8,578.8c-0.5,0.2-1.2,0.3-1.8,0.3c-22-0.2-42.9-6.6-64.4-10c-8.3-1.3-16.6-1.8-24.9-1.9
					c-0.4,0.2-0.8,0.5-1.1,0.8c-2.3,1.7-4.6,0.9-6-0.8c-3.1,0-6.3,0-9.4,0c-5.3,0.8-10.6,1.1-16,0.5c-0.9-0.1-1.8-0.2-2.7-0.4
					c-1,0.7-2.3,1-3.9,0.6c-1.1-0.2-2.1-0.4-3.2-0.5c-0.3,0.8-1.3,1.1-2.2,1.3c-4.6,1.2-9.2,2.4-13.8,3.6c-1,1.5-2.5,2.3-4.1,2.4
					c-2.5,0-3.8,3.2-6.4,2.7c-0.2-0.7,0.7-0.3,0.8-1.1c-5,0-9.8,1.1-14.7,1.1c-5,0-9.3,2.6-14.4,1.9c-3.4-0.5-6.2,1.8-8.3,4.6
					c-0.5,0.6-0.2,1.3,0,1.9c0.3,1.4,0.7,2.8,1.1,4.2c0.8,0.9,1.7,1.5,2.9,1.2c0.3-0.1,0.6,0,0.7,0.3c0,0,0,0,0,0
					c0.2,0.1,0.4,0.1,0.6,0.2c1.6,0.1,3.1,0.3,4.7,0.2c11-0.3,21.9-2.1,32.8-3.3c0.8-0.2,1.7-0.4,2.5-0.7c0.8-0.3,0.9-1.1,0.9-1.8
					c0-0.5-0.3-1.1-0.7-1c-2.9,0.6-5.9-0.8-8.6-0.2c-3.5,0.9-7.3-0.1-10.7,1.3c-0.1,0.1-0.4-0.3-0.6-0.4c0.3-0.8,1-0.7,1.6-0.9
					c0.6-0.2,1.5-0.3,1.2-1.4c-0.3-1.3-1.1-1-1.9-0.8c-1.4,0.4-2.9,0.7-4.3,1.1c-0.8,0.2-1.9,0.1-1.8,1.5c0.1,1.3-0.4,1.1-1.4,1.1
					c-3.1,0-6.5-0.8-9.3,0.8c-2.1,1.2-5,0.2-6.6,2.7c-0.6-0.6-0.3-1.6-1.1-2.2c-1.3-1.1-0.7-2.8,0.4-3.2c3.9-1.3,7.8-2.7,11.8-3
					c4.8-0.4,9.7-0.3,14.3-1.6c2.2-0.6,4.4-1.7,6.5-1.4c2.9,0.4,5.2-1.1,7.9-1.2c0.6,0,0.7-0.7,0.6-1.3c2.5-0.9,4.8-0.2,7.4,0.2
					c4.6,0.7,9,1.6,13.6,2.6c0.7,0.1,1.4,0.2,1.5,1.2c0.1,1.1-0.7,1.2-1.4,1.3c-2.9,0.5-5.7,2.3-8.9,1.4c-0.5-0.2-1.2,0-1.4,0.5
					c-0.7,1.5-2.2,1.3-3.3,1.8c-1,0.4-3,0.2-2.7,1.3c0.4,1.6,2.3,1.4,3.6,1.4c1.1-0.1,2.3-0.2,2.8-1.6c0.1-0.3,0.4-0.8,0.6-0.7
					c2.6,0.6,5.6-0.4,7.9,1.6c2.3,2,5.2,3.1,7.1,2.3c3.8-1.6,6.9,0.5,10.2,1.2c1.8,0.4,3.4,0,5.1,0.5c6.3,1.8,12.4,2.3,18.2-1.1
					c0.7-0.4,1.4-0.4,2.3,0c3.5,1.7,7.2,1.2,10.8,1.3c3.9,0.1,7.8,0.2,11.7,1.9c1.6,0.7,3.2,2.6,4.9,1.8c2.4-1.1,4.6,0,6.8,0.5
					c2.1,0.5,4,1.5,5.2,4.1c1.4,3.1,4.4,3.8,7.1,4.5c0.3,0.1,1-0.2,1.1-0.5c0.8-3.1,3.8-2.7,5.7-4.1c0.7-0.5,1.7-0.4,2.2,0.3
					c1.6,2.3,3,1.1,4.8,0.5c1.9-0.6,3.2-3.3,5.5-2c3.3,1.9,6.6,1.1,9.8,0.6c3.6-0.6,7.1-1.8,10.8-2.2c3.8-0.3,7.5-0.8,11.1-2.2
					c3-1.2,6.2-2.3,9.3-2.3c3.8,0.1,6.6-2.2,9.9-3.3c2-0.6,3.6-1.4,5.7-0.9c0.6,0.2,1.4,0.1,2-0.2c4-1.7,7.7-4.4,12.1-5
					c0.7-0.1,0.9-0.8,1.1-1.4c1.2-2.8,2.1-5.7,3.1-8.7c0.2-0.5,0.3-1,0.4-1.5C856.7,574.4,839.7,578.7,822.8,578.8z M669.1,575.7
					c0.2-0.6,0.5-0.1,0.7,0C669.5,575.7,669.3,575.7,669.1,575.7z M720.9,579.1c-0.6,0.2-1.2,0.3-1.8,0.5c0,0.1,0,0.2,0,0.3
					c-3.9,0-7.7,0-11.6-0.1c-2,0-2.9-1.3-3.3-3.4c-0.5-2.4,0.9-3.2,2.1-4c2.6-1.8,7.8-0.9,10.3,1.5c1,1,1.9,2.4,3.6,2
					c0.8-0.2,1.3,0.6,1.4,1.4C722,578.2,721.7,578.8,720.9,579.1z"/>
				<path fill={color2a} style={{transition: "1s"}} d="M654.7,617.1c-0.2-0.5-0.4-1.1-0.9-0.9c-2.5,0.9-5.4-0.6-7.9,0.7c-2.5,1.3-5.4-0.4-8.1,0.5
					c1.3-1.5,2.7-2.2,4.6-2.4c3.3-0.3,6.4-1.1,9.8-1.1c0.4,0,1.1-0.3,1.1-0.6c0-2.2,1.8-2.5,3-3.1c-0.5-1.1-1.1-0.6-1.6-0.5
					c-1.8,0.6-3.5,1-4.9-1.2c-0.5-0.7-1.4-0.8-2.2-0.6c-0.6,0.2-1.4,0.5-1.8,0.2c-3-2.1-5.8,0.1-8.7,0.1c-0.6,0-1.1,0.5-1.7,0
					c0-0.2,0-0.5,0-0.5c5-1.8,10.1-3.1,15.3-4c1.7-0.3,3.3-0.9,4.9-1.4c5.4-1.7,10.9-3,16.3-4.5c1.1-0.3,1.7,0.5,2.3,1.1
					c3,2.8,6.3,4.5,10.2,5c5.6,0.7,11.1,2.2,16.7,2.9c7,0.8,13.8,2.7,20.8,3.7c2.5,0.4,5.2-0.8,7.5,1.2c2.1,1.7,4.9,1.8,6.8,4
					c0.1,0.1,0.1,0.2,0.2,0.2c2.6,0.3,4.2,1.8,5.4,4.3c0.4,0.9,1.4,0.4,2.2,0.3c2.3-0.4,4.4-0.8,7-0.5c3,0.4,6.5,0.2,9.4-0.5
					c10.9-2.9,21.9-2.6,33.1-2.1c3.5,0.1,6.9-0.3,10.4,0.4c0.6,0.1,1.4-0.9,2.1-0.8c4.8,0.2,9.3-2.2,14.1-0.8c0.5,0.2,1,0.4,1.5,0.2
					c3.1-1.5,6.7,0.2,9.9-1.3c0.6-0.3,1.2,0.2,1.8,0.2c1.8-0.2,3.6,1.1,5.5-0.2c1-0.7,2.7-0.7,4,0.3c1.8,1.3,3.8,1.3,5.5,1.1
					c4.6-0.6,9.3,0.4,13.9-0.1c5.2-0.5,10.6,0.3,15.8-1.4c2.9-1,6,0.5,8.9,1.5c1.3,0.4,0.5,1.6,0.7,2.5c0.2,0.9-0.3,1.1-1,1.1
					c-0.9-0.1-1.8,0.1-2.5,0.7c-1.4,1.1-2,2.3-0.2,3.9c0.5,0.4,1.7,1.1,0.7,1.5c-1.1,0.5-1.4,2.9-3.1,1.6c-2.1-1.6-6.8-2.1-8.4-0.4
					c-1.5,1.5-2.9,2.1-4.9,1.2c-0.4-0.2-1,0-1.5,0.1c-1.4,0.4-2.9,0.8-1.6-1.8c0.2-0.4,0.1-1.3-0.3-1.1c-2.6,1.2-5-1.7-7.6-0.7
					c-1.6,0.6-3.2,1.1-4.3,2.7c-1.4,2-3.2,1.6-5.2,0.6c-0.6-0.3-0.7-2.1-1.7-1.1c-0.7,0.6-1.6,1.3-1.5,2.5c0.4,4.1-2.5,7-3,10.8
					c-0.1,0.7-0.8,0.9-1.4,1c-6.4,1.7-12.6,4.3-19.2,5.6c-2.4,0.5-4.8-0.2-7.2,0.6c-2.2,0.7-4.4,1.6-6.5,2.6c-2.3,1-4.4,2.8-6.6-0.5
					c-0.5-0.7-1.4-0.5-2.2-0.3c-1.9,0.4-3.8,1.4-5.8,1.1c-0.8-0.1-0.9,0.6-1.1,1.2c-0.5,1.4-1.8,1.5-2.9,1.1
					c-1.1-0.4-0.6-1.5-0.5-2.5c0.3-2.5-0.6-3.5-2.8-2.9c-2.8,0.7-5.4,1.2-7.4-2.2c-0.5-0.9-1.9-1.2-2.9-0.8
					c-1.1,0.4-0.4,1.6-0.3,2.5c0.2,1.9-1,2.3-2.2,2.2c-2.4-0.1-4.7-0.5-5.8,2.9c-0.5,1.6-2.6,2.5-3.9,3.7c-0.8,0.7-1.8,1.3-2.2,2.3
					c-0.7,1.5-1.2,3-3.2,2.1c-1.9-0.8-4-1.4-3.6-4.5c0.1-1.1-0.7-1.9-1.4-2.7c-1.2-1.5-0.7-2.9,1-3c1.8-0.1,3.5-1,5.4,0.5
					c0.9,0.7,2.7,0.4,2.4-2c-0.2-1.8,0.7-2.6,2.3-3.1c3.6-1.1,6.8-3.1,10.7-3.6c1.8-0.2,3.6-1.7,5.7-1.9c0.8-0.1,1.1-0.8,1.1-1.7
					c0.1-3.2,1-4.2,4-4.5c2.6-0.2,4.6-1.9,7.1-2.4c0.3-0.1,0.7-0.2,0.7-0.7c-0.1-0.7-0.6-1-1.1-1.2c-2.8-0.9-5.6-1.6-8.5-1.8
					c-2-0.1-4.1,0.8-5.7-1.7c-0.4-0.6-1.3-0.1-2,0.1c-2.4,0.7-4.6,0.7-7.1-0.2c-3-1.1-6.1,0.5-9.2,0.9c-2.6,0.3-4.9,1.7-7.7,1.1
					c-0.8-0.2-1.5,0.3-1.7,0.9c-1,2.5-3.2,3.5-4.9,5.2c-1.8,1.8-4.5,1.2-6.9,1.4c-0.6,0-1-0.5-1.1-1.1c-0.4-2.8-1.9-2.4-3.6-1.9
					c-3.7,1.1-7.6,1.8-11.1,3.5c-0.7,0.4-1.4,0.6-1.7,1.5c-0.2,0.9-1,1.3-1.5,1.9c-0.9,1.1-2,0.5-2.9-0.1c-1.4-0.9,0.3-1.5,0.1-2.4
					c-3-1.7-5.2,0.1-7.4,1.8c-1.2,0.9-0.6,1.7,0.4,2.5c0.6,0.5,1.6,1,0.9,2c-0.4,0.6-1.7,0.6-1,1.8c0.5,0.9,1.5,1.7,2.5,1.5
					c1.6-0.3,2.7,0,3.2,2c0.3,1,1.2,0.7,1.8,0.4c4.1-1.8,8.6-0.9,12.8-1.3c0.9-0.1,1.7-0.1,2.6-0.3c3.8-1.1,7,0.1,9.9,3
					c0.7,0.7,1.4,0.7,2.2,0.4c1-0.3,2-0.5,3-0.7c0.5-0.1,1.1,0.1,1.3,0.7c0.2,0.7-0.1,1.2-0.6,1.3c-2.4,0.5-2.5,2.5-2.4,4.6
					c0,0.4,0,0.9-0.2,1.1c-2.1,1.5-2.3,3.6-1.3,6c0.1,0.2-0.1,0.6-0.3,0.7c-2.8,1.4-4.1,4.7-6.8,6.2c-0.5,0.3-1.2,0.5-1.5,1
					c-2,4.2-6.2,5.3-9.2,8c-1,0.9-2.3,1.8-3.5-0.3c-0.6-1.1-1.6-0.4-2.4-0.1c-2.4,0.7-4.3,0.3-6.8-0.8c-3.2-1.4-6.3-3.6-9.8-4.3
					c-0.8-0.2-1.7,0.1-2,0.9c-0.9,2.1-2.3,1.8-3.9,0.8c-0.5-0.3-1.1-0.8-1.5-0.3c-1.8,2.4-3.6,0.4-5.4-0.4c-1.5-0.6-3-1.2-3.4,1.5
					c-0.3,2-2.2,2.8-3.4,3.9c-1,0.9-2,3.1-4.1,1.2c-0.9-0.8-1.5,0.7-1.7,1.5c-0.5,1.6-1,2.7-3,2.6c-1.2,0-2.5,1.1-3.6,2
					c-4.4,3.7-8.7,7.5-13.1,11.1c-3.5,2.9-7.3,5.4-10.7,8.6c-0.2,0.2-0.6,0.4-0.9,0.5c-2.8,0.6-5.4,1.5-7.7,3.5
					c-1.2,1-2.4,0.8-3.1-1.1c-0.3-0.8-0.7-1.4-1.5-1.5c-2.9-0.4-2.9-3-3.4-5.2c-0.1-0.3,0-0.9,0.2-1c1.5-1.1,1.8-3.2,3.3-4.5
					c1.1,3.6,2.4,2.9,4.7,1.2c2.6-1.9,6.2-2.4,8.3-5c2.4-3.1,6-4.3,8.4-7.5c2.4-3.1,5.4-6.5,9.6-7.4c0.4-0.1,0.8-0.4,1-0.8
					c3.2-4.8,7.7-5.5,12.8-4.4c1.3,0.3,5.4-2.6,5.7-3.9c0.3-1-0.2-1.4-0.9-1.1c-4.7,2.4-5.9-2-7.8-5.1c-0.5-0.8-1-1.8-1.7-2.3
					c-1.3-1-2.6-1.7-4.1-0.4c-6.8,6-13.7,11.8-20.5,17.7c-2.3,2-4.1,4.6-6.7,6.3c-0.9,0.6-1.8,1.3-2.8,0.8c-0.5-0.3-1.2-1.3-1.1-1.9
					c0.2-1.2,0.7-2.4,1.9-2.7c1.9-0.5,2.5-2.1,3.5-3.7c1.4-2.3,3.1-5.1,5.9-6c2.2-0.7,3.4-2.9,4.9-4.5c2.6-2.7,5-5.8,8.4-7.5
					c2.2-1.1,2.9-3.8,4.2-5.8c0.6-0.9,1-1.5,1.9-1.8c0.8-0.2,1.8-0.2,1.8-1.6c0-0.9,1.7-1.7,0.3-2.6c-0.9-0.6-2.3-0.7-3.1,0.2
					c-0.8,0.9-1.6,1.1-3.3,1c3.2-2.5,4.8-6,8.9-7c2.1-0.5,5.5-1.4,5.8-4.9c0.3-3.9,2.3-3.3,4.7-2.6c0.3,0.1,0.7,0.1,1,0
					c6-2,12.5-1.5,18.6-3.6c1.7-0.6,3.5-1.5,5.5-0.5c0.4,0.2,1.1,0,1.5-0.3c6.5-4.2,14.2-4.3,21.1-6.7c0.1,0,0.2,0,0.3-0.1
					c1.5-0.3,3.4-1.9,3-2.7c-0.7-1.4-2.2-2.9-4.2-3c-3.8-0.3-7.4,0-11.2,0.1c-3.8,0.1-8.3-0.7-11.8-3.7c-2.4-2-5.6-2.4-7.6-5.3
					c-0.4-0.6-1.2-0.2-1.7,0.2c-1.7,1.4-2.3,3.2-1.4,5.5c1.9,4.6,0.9,6.9-2.8,9.3c-4.1,2.7-8.6,3.1-13.3,3.5
					c-8,0.7-15.9,2.4-23.8,2.9c-4,0.3-7.8,1.2-11.7,1.9c-0.8,0.1-1.8,0-2.6-0.3c-2-0.8-3-0.3-2.9,3c-1.1-2.6-2.3-3.7-4.5-1.7
					c0.8-2.5,2.4-3,3.6-3.3c2.9-0.7,5.7-2.6,9-1.5c0.5,0.2,1.2-0.2,1.8-0.3c11.4-3,22.8-6,34.2-9c1.2-0.3,2.4-0.5,3.1-1.7
					c0.9-0.5,1.8-0.9,2.7-1.4c0.7-0.4,1.7-0.8,1.4-2.1c-0.4-1.4-1.4-1.6-2.5-1.5c-3.5,0.4-7.1,0.6-10.6,1.1
					c-8.1,1-16.3,2.2-24.4,3.3C655.4,617.3,655.1,617.1,654.7,617.1z M739,653.8c-2.3,1-5.1,2-7.7,3.3c-1.5,0.8-1,2.5-0.3,3.8
					c0.8,1.5,1.9,1.2,2.8,0.2c0.5-0.6,1.2-1.1,1.5-1.9c0.7-1.7,1.8-2,3.3-1.6c0.9,0.2,1.9,0.4,2.8,0.6c0.7,0.1,1.2,0.1,1.3-0.8
					C743.2,655,741.5,653.2,739,653.8z M810.9,628.9c-1.1,0.5-2.8,0.3-2.2,2.4c0.7,2.1,2.1,2.4,4,2.1c2.2-0.4,2.1-2.2,1.7-3.5
					C813.8,627.9,812.2,628.9,810.9,628.9z"/>
				<path fill={color2a} style={{transition: "1s"}} d="M722.3,714.7c2.9-4.3,7.2-3.5,11.4-2.8c0.8,0.1,1.5,0.5,2.3,0.6c0.9,0,2.1,0.4,2.1,1.7
					c0,1.2,0,2.6-1.5,2.8c-1.5,0.2-2.5,1-3.1,2.5c-0.3,0.9-1.2,0.9-1.9,1.2c-3.3,1.6-6.1,3.9-8,7.4c-1.2,2.1-2.5,4.3-5.1,4.5
					c-1.8,0.1-2.3,1.8-3,3.1c-2,3.8-5.7,5.5-8.5,8.3c-0.6,0.6-1.5,0.9-2.1,1.4c-0.9,0.7-2.2,1.7-3,0.5c-0.7-1-1.1-2.3-0.8-4.1
					c0.9-5.6,5.1-8.1,7.9-11.9c0.1-0.2,0-0.6,0-0.8c0.3-0.4,0.6-1,1.2-0.9c2.2-0.2,3.7-1.4,3.9-4C717,721.2,720.1,718.4,722.3,714.7
					z"/>
				<path fill={color2} style={{transition: "1s"}} d="M673.3,572.2c-1,1.5-2.5,2.3-4.1,2.4c-2.5,0-3.8,3.2-6.4,2.7c-0.2-0.7,0.7-0.3,0.8-1.1
					c-5,0-9.8,1.1-14.7,1.1c-5,0-9.3,2.6-14.4,1.9c-3.4-0.5-6.2,1.8-8.3,4.6c-0.5,0.6-0.2,1.3,0,1.9c0.3,1.4,0.7,2.8,1.1,4.2
					c-2.2-1.4-3-3.6-2.6-6.2c0.4-2.7,2.1-4.2,4.3-5.1c3.8-1.5,8-1.5,12-1.9c10.5-1,20.9-1.9,31.1-4.5
					C672.4,572.1,672.9,572.2,673.3,572.2z"/>
				<path fill={color2} style={{transition: "1s"}} d="M714.1,724.1c-0.2,2.6-1.7,3.7-3.9,4C710.9,726.1,712.5,725.2,714.1,724.1z"/>
				<path fill={color2} style={{transition: "1s"}} d="M630,591.1c0.3-0.1,0.6,0,0.7,0.3C630.5,591.3,630.3,591.2,630,591.1z"/>
				<path fill={color2} style={{transition: "1s"}} d="M630.8,591.4c0.2,0.1,0.4,0.1,0.6,0.2C631.2,591.8,631,591.8,630.8,591.4z"/>
				<path fill={color2a} style={{transition: "1s"}} d="M683.7,693.3c1.5-0.4,2.7-0.3,4,0.9c0.7,0.7,1.6,1.7,2.8,0.8c0.8-0.6,1.7-0.9,2.1,0.5
					c0.3,1.1,1.2,2.6-0.2,3.3c-1.5,0.8-1.9,2.2-2.6,3.6c-1.2,2.3-3.4,3.5-5.2,5c-5.5,4.6-10.9,9.4-16.3,14.3
					c-1.2,1.1-1.9,2.6-3.8,2.1c-0.1,0-0.2,0-0.2,0.1c-1.8,3.2-5.7,3.1-7.7,5.8c-1.3,1.7-2.7,2.4-4.7,1.2c-0.7-0.4-1.7-1.4-1.5,0.7
					c0.1,1.2-0.8,1.1-1.5,0.9c-0.8-0.3-2.2,0-2-1.3c0.4-2.8-0.2-6.1,0.9-8.3c1.2-2.4,2.8-5.5,6.1-5.7c3-0.2,5-2.7,7.8-3.3
					c0.7-0.2,0.8-0.9,1-1.5c1-3.2,3.2-5.2,6-6.2c0.8-0.3,1.4-0.7,1.7-1.6c0.7-2.3,2.9-3.6,3.6-6c0.1-0.5,0.6-1.1,1-1.2
					C677.9,696.3,681.2,695.8,683.7,693.3z"/>
				<path fill={color2} style={{transition: "1s"}} d="M741.7,703.1c2.5-1.8,2.2-4.3,2.8-6.5c0.1-0.6-0.3-1.6,0.3-1.8c3.2-1.4,4.6-5.4,8-6.6
					c0.9-0.3,1.1-1,1.3-1.9c0.4-2.5,2.5-2.6,3.8-2.1c1.5,0.5,1.3,2.6,0.8,4c-0.9,2.5-2.1,4.9-3.9,6.8c-0.8,0.9-1.8,1.6-1.4,3.3
					c0.3,1.2-0.8,1.2-1.5,1.4c-2.1,0.6-4,0.9-5.3,3.3C745.7,704.9,743.7,703.7,741.7,703.1z"/>
				<path fill={color2} style={{transition: "1s"}} d="M677,635.7c-2.9,2-10.6,3.5-16.1,3.3C662.6,637.2,668.9,635.9,677,635.7z"/>
				<path fill={color2} style={{transition: "1s"}} d="M719.2,579.8c-3.9,0-7.7,0-11.6-0.1c-2,0-2.9-1.3-3.3-3.4c-0.5-2.4,0.9-3.2,2.1-4
					c2.6-1.8,7.8-0.9,10.3,1.5c1,1,1.9,2.4,3.6,2c0.8-0.2,1.3,0.6,1.4,1.4c0.2,0.9-0.1,1.5-0.9,1.8c-0.6,0.2-1.2,0.3-1.8,0.5
					C719.2,579.6,719.2,579.7,719.2,579.8z"/>
				<path fill={color2} style={{transition: "1s"}} d="M669.8,575.7c-0.2,0-0.5,0-0.7,0C669.3,575.1,669.5,575.5,669.8,575.7
					C669.8,575.7,669.8,575.7,669.8,575.7z"/>
				<path fill={color2} style={{transition: "1s"}} d="M739,653.8c2.5-0.7,4.2,1.2,3.8,3.6c-0.1,0.9-0.7,0.9-1.3,0.8c-0.9-0.2-1.9-0.4-2.8-0.6
					c-1.5-0.3-2.7-0.1-3.3,1.6c-0.3,0.7-1,1.3-1.5,1.9c-0.9,1-2,1.3-2.8-0.2c-0.6-1.3-1.2-3,0.3-3.8
					C733.9,655.8,736.6,654.8,739,653.8z"/>
				<path fill={color2} style={{transition: "1s"}} d="M810.9,628.9c1.2,0,2.9-1,3.5,0.9c0.5,1.4,0.5,3.1-1.7,3.5c-1.9,0.3-3.4,0-4-2.1
					C808.1,629.2,809.8,629.4,810.9,628.9z"/>
			</g>
		</g>
		<g id="Слой_3">
			<path fill={color3} style={{transition: "1s"}} d="M149,558.7c4.4-9.9,18.3-11.6,28.3-7.5c10,4.1,17.8,12.3,27.2,17.6c17.6,10.1,39.2,9.9,59.3,6.7
				c16.5-2.6,33.1-7.1,49.6-4.5c35.5,5.7,57.9,41.5,90.4,56.7c14.9,7,31.7,9.4,46.3,17c14.6,7.5,27.5,22.9,24.1,39.1
				c-1.5,7.4-6.6,14-13.3,17.5c-5.1,2.6-10.9,3.5-15.8,6.5c-11.4,7.1-3.9,28.3-5.1,39.5c-1.8,18.1-9.6,36.1-21,50.2
				c-2.9,3.6-6.2,7.2-7.8,11.5c-1.3,3.7-1.3,7.8-1.5,11.7c-0.5,18.1-3.8,36-7,53.8c-0.6,3.2-1.2,6.5-1.8,9.7
				c-2.1,11.4-4.1,22.8-6.2,34.2c-20.1-4.4-40.7-5.8-61.2-4.3c1.4-11.2,2.5-23.3-3.2-33c-2.3-3.9-5.5-7.1-7.9-10.9
				c-8.8-14.1-4.1-32.2-4.6-48.8c-0.7-25.6-14-48.9-19.9-73.8c-2.2-9.3,0.1-38.1-5.7-44.5c-4.8-5.1-22.9-2.6-29.9-3.9
				c-25-4.7-47.4-18.7-73-20.8c-21.5-1.8-53.8,11.8-72.2-3.9c-25.5-21.8,5.1-102.6,35.4-76.1C155,585.5,143.5,571.2,149,558.7z"/>
			<path fill={color3b} style={{transition: "1s"}} d="M123.4,781.1c2.7,17.2,5.5,21,5.4,33.7c-0.1,9.9-2.5,19.7-4.1,29.5c-6.5,39.6-1.2,80,4,119.7
				c0.5,4,1.1,8.2,3.5,11.6c3.9,5.5,10.2,7.2,12.6,7.9c11.5,3.3,47.7,14.9,97.4,30.7c9.6-0.2,22.1-2.1,28-10.7
				c4.9-7.2-1.2-14.7,2-26.5c4.6-16.9,13.4-32.6,15.7-50c2.6-18.9-2.7-37.8-3.7-56.9c-1.4-24.6,2.1-34.7,3-54.2
				c0.1-1.7,0.2-11.1-0.2-23.5c-0.7-22.9-1.1-34.3-4.5-39.4c-6.6-9.7-20.5-11.4-48.4-14.8c-25-3-23.7,0.4-46.7-3
				c-9.6-1.5-21.1-3.6-38.8-4.8c-17.4-1.1-20.7,0-23.2,2C116.8,739.6,119.5,756.7,123.4,781.1z"/>
			<path fill={color3a} style={{transition: "1s"}} d="M326.1,784.8c-3.3-12.4-9.8-23.7-16.3-34.7c7.8-3.1,16.8,3,20,10.7s2.3,16.5,1.5,24.8
				c-0.7,8.3-1.1,17.3,3,24.6c2,3.6,5.1,6.7,6.1,10.7c1.2,4.6-0.5,9.4-0.8,14.1c-0.5,11,7.2,20.8,15.9,27.5
				c8.5,6.5,18.1,11.3,26,18.2C319.1,891.5,335.1,818.9,326.1,784.8z"/>
			<path fill={color3b} style={{transition: "1s"}} d="M203,624.1c7.1,4.4,12.1,12,19.9,15.1c4.7,1.8,10.3,1.9,14.1,5.2c4.3,3.6,5.1,9.7,7.8,14.5
				c2.7,4.8,10.3,8.1,13.4,3.5c0.7-5.9,1.5-11.8,2.2-17.7c5.7,3.6,11.3,7.3,17,10.9c1.3,0.9,2.7,1.7,4.3,2.1
				c7.1,1.4,10.7-10,5.9-15.5c-4.7-5.5-12.8-6.3-20-7.3C236.9,630.6,192,587.9,167,605C173.6,619.7,190.6,616.4,203,624.1z"/>
			<path fill={color3b} style={{transition: "1s"}} d="M130.9,659.2c-3-2.8-6.2-6-7-10s2-9.1,6.1-8.9c4.3,0.3,6.3,5.2,9.1,8.4c2.8,3.2,10.2,2.7,9.2-1.4
				c0.9,2.1,1.8,4.2,2.8,6.4c3.3-0.7,6.9,0.3,9.3,2.6c-3.7,6.1-14.1,6.1-17.9,0.1c-3.4,1.7-7.3,2.5-11.1,2.2"/>
			<path fill={color3b} style={{transition: "1s"}} d="M333.3,688.9c2,3.1,3.5,7,7,8.2c1.7,0.6,3.6,0.5,5.4,0.4c10.8-0.5,21.8,1.9,31.4,6.8
				c4.4,2.2,8.5,5,13.3,6.1c5.6,1.3,11.5,0.3,17.1-0.8c11.2-2.1,22.4-4.2,33.6-6.2c2-0.4,4.1-0.8,5.5-2.2c3.3-3.3-0.1-9.7-4.8-10.5
				c-4.7-0.8-9.1,2.1-12.7,5.2c-3.5,3.1-6.9,6.8-11.4,8.2c-6.3,2-13-0.9-19.2-3.1c-12.6-4.5-26.2-6.5-39.6-5.8
				c-3.7,0.2-7.5,0.6-10.8-0.9c-6.7-2.9-8.6-11.4-13.6-16.6c-6.7-7-17.3-5.7-23.8,0.1C319,678.1,328.6,681.6,333.3,688.9z"/>
			<path fill={color3a} style={{transition: "1s"}} d="M227.5,982.7c11.2,5.8,27-1,30.5-13.1c-29.1,2.3-58.4,1.1-87.2-3.6c15.8,10.1,34,16.3,52.7,17.9
				c5.2-6.9,0.6-17.4-6.6-22.1c-7.2-4.8-16.1-6-24.2-9c-12.2-4.6-22.6-13.7-28.7-25.1c-1.3-2.4-2.4-5.6-0.6-7.8
				c2.1-2.6,6.2-1.4,9.1,0.1c9.2,4.9,17.2,11.8,26.2,17c9,5.2,19.9,8.6,29.9,5.6c2.3,3,0.8,7.8-2.3,9.9c-3.1,2.2-7.2,2.3-11,1.8
				c-7.7-1-15-4.4-20.8-9.6c1-5.4,6.1-9.7,11.6-9.8c0.1-6.8-2.7-13.6-7.6-18.3c-1.8-1.7-4-3.5-3.9-6c0.1-3.7,4.9-5,8.5-5.3
				c22.9-1.7,47.9-2.8,66.4,10.8c-21.3,6.9-43.5,11.1-65.8,12.4"/>
			<path fill={color3a} style={{transition: "1s"}} d="M143.2,821.2c-1.5,6.7-2.9,15.8,3.4,18.6c5.2,2.3,11.2-2.5,16.8-1.3c5.8,1.2,8.4,7.9,12.5,12.2
				c5.6,6,14.3,7.8,22.5,9.2c23.3,4,46.9,8.1,70.4,6.2c-4.9-8.7-16.4-10.4-26.2-12.1c-13.3-2.3-26.1-6.4-38.9-10.5
				c-10.2-3.2-20.3-6.5-30.5-9.7c-4.3-1.4-8.9-2.9-11.6-6.5c-4.7-6.2-1.6-15-1.8-22.8c-0.3-13.3-10.4-26.5-5.5-38.9
				c1.4-3.5,3.9-6.6,4.6-10.2c1.8-9.1-7.8-17.5-15.2-20.2C133.2,765.3,150.1,791.3,143.2,821.2z"/>
			<path fill="#1A2226" d="M343,898.1c8.8,3.8,17.1,9,24.3,15.3c-4.6,4.8-12.6,5.7-18.2,2.1s-8.1-11.2-5.7-17.4"/>
			<path fill="#1A2226" d="M363.3,849.3c-9,1.4-13.1-10.6-14.8-19.5c-2.5-13-6.9-25.6-13.2-37.2c-6.1,4.6-6.3,14.2-2.4,20.7
				s11,10.5,17.9,13.6c3.4,1.6,7.6,4.9,5.7,8.1c-2.1,5.4-14-0.1-14.9,5.6c-0.2,1.5,0.7,2.8,1.5,4c9.4,13.3,24.4,29.5,41.1,31.9
				C383.3,873.9,363.4,849.2,363.3,849.3z"/>
			<path fill="#0D1113" d="M170.1,617.6c1.8,2.3,3.6,4.5,5.4,6.8c7.3,9.1,15,19.9,12.4,31.3c3.2-3.2,6.4-6.4,9.6-9.6
				c0.6,4,2.8,7.8,6.1,10.3c2.9-1.7,5-4.7,5.8-7.9c8.6,8.8,17.3,17.7,25.9,26.5c-3.3-6.5-6.3-13.2-9.2-19.9c4.1-0.1,8.1,2.7,9.6,6.5
				c0-6.1,0-12.2,0.1-18.3c-11.5-1.1-22.4-7.8-28.5-17.6c-2.4,3.5-4.8,6.9-7.2,10.4c-3.9-9.2-14.9-14.7-24.6-12.4"/>
			<g>
				<g>
					<path fill="#1A2226" d="M300.8,698.3c-15.6-8.2-32.8-12.1-50.4-11.9c-5,0.1-6.6,6.7-0.7,6.6c16.2-0.2,31.8,3.8,46.1,11.3
						C299.5,706.3,305.7,700.9,300.8,698.3L300.8,698.3z"/>
				</g>
			</g>
			<path opacity="0.47" fill="#1A2226" stroke="#1A2226" stroke-width="3" stroke-miterlimit="10" d="M113.4,619.5
				c9.8-8.9,25.1-2.5,38,0.3c16,3.4,32.7,0.3,49,1.6c18.9,1.5,37.3,9.1,51.7,21.4c10.3,8.8,19.9,20.6,33.5,22
				c6.9,0.7,13.6-1.4,20.4-2.8c6.8-1.4,14.2-1.8,20.1,1.8s8.4,12.8,3.4,17.6c-2.4,2.3-5.9,3-9.1,3.3c-8.3,0.7-16.8-0.8-24.4-4.3
				c-4.2-1.9-8.2-4.5-12.7-5.1c-3.7-0.5-7.4,0.4-11.1,1.1c-40.3,8.7-88.6,13-129,2.5C123.6,674,89.4,641.1,113.4,619.5z"/>
			<path opacity="0.47" fill="#1A2226" stroke="#1A2226" stroke-width="3" stroke-miterlimit="10" d="M403.2,800.5
				c4.7-7.7,12.4-13.2,16.9-21c4.4-7.4,5.4-16.3,6.4-24.8c1-8.5,1.5-18.5-4.8-24.3c-7.1-6.6-18.6-4-27.9-6.6
				c-13.5-3.8-24-18.7-37.8-16.5c-10.5,1.7-17,15.1-11.9,24.4c2.8,5.1,8,8.3,12.1,12.3s7.4,10.2,5,15.5c-1.2,2.7-3.8,4.6-5.2,7.2
				c-3.7,6.8,1.5,15.2,7.6,20.1c6.1,4.8,13.6,8.7,16.9,15.7c7.6,16.6,0.1,32.6,20.7,41.1C399.2,829.3,395.1,814,403.2,800.5z"/>
			<g>
				<g>
					<path fill="#1A2226" d="M340.3,886.4c1.5-0.8,3.1-1.6,4.6-2.4c0.8-0.4,1.8-1,2.3-1.8c0.3-0.6,0.5-1.5-0.1-2
						c-1.4-1.4-4.2-1.1-5.8-0.3c-1.5,0.8-3.1,1.6-4.6,2.4c-0.8,0.4-1.8,1-2.3,1.8c-0.3,0.6-0.5,1.5,0.1,2
						C335.9,887.5,338.7,887.2,340.3,886.4L340.3,886.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M355.4,889.2c1.8,0,4.9-0.5,5.7-2.5c0.7-1.8-0.6-3.2-2.5-3.2c-1.8,0-4.9,0.5-5.7,2.5
						C352.2,887.8,353.5,889.2,355.4,889.2L355.4,889.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M371.6,888.7c0.8,0,1.6,0,2.3,0c1.1,0,2.4-0.3,3.4-0.7c0.8-0.3,1.9-0.9,2.3-1.6c0.3-0.5,0.6-1.2,0-1.7
						c-0.6-0.6-1.5-0.7-2.3-0.7c-0.8,0-1.6,0-2.3,0c-1.1,0-2.4,0.3-3.4,0.7c-0.8,0.3-1.9,0.9-2.3,1.6c-0.3,0.5-0.6,1.2,0,1.7
						C369.8,888.6,370.8,888.7,371.6,888.7L371.6,888.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M387.8,890.8c0.8,0,1.6,0,2.3,0c1.1,0,2.4-0.3,3.4-0.7c0.7-0.3,1.9-0.8,2.4-1.5c0.3-0.4,0.6-1,0.1-1.5
						c-0.6-0.5-1.5-0.5-2.3-0.5c-0.8,0-1.6,0-2.3,0c-1.1,0-2.4,0.3-3.4,0.7c-0.7,0.3-1.9,0.8-2.4,1.5c-0.3,0.4-0.6,1-0.1,1.5
						C386.1,890.8,387,890.8,387.8,890.8L387.8,890.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M142.6,966.2c4.9,0,6.7-6.2,0.8-6.2C138.6,960,136.7,966.2,142.6,966.2L142.6,966.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M140.3,967.3c1.9,0,4.7-0.3,6.1-1.6c1-0.9,1.5-2-0.5-2c-1.9,0-4.7,0.3-6.1,1.6
						C138.7,966.2,138.2,967.3,140.3,967.3L140.3,967.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M157.7,962.1c1.5,0.8,3.1,1.6,4.6,2.4c1.7,0.9,4,0.4,5.6-0.3c0.8-0.3,1.8-0.7,2.4-1.4
						c0.3-0.3,0.7-1.1,0.1-1.4c-1.5-0.8-3.1-1.6-4.6-2.4c-1.7-0.9-4-0.4-5.6,0.3c-0.8,0.3-1.8,0.7-2.4,1.4
						C157.5,961,157.1,961.8,157.7,962.1L157.7,962.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#1A2226" d="M135.1,967.5c1.5,0.8,3.1,1.6,4.6,2.4c1.9,1,4.5,0.6,6.2-0.7c1.2-0.9,2.6-2.9,0.6-3.9
						c-1.5-0.8-3.1-1.6-4.6-2.4c-1.9-1-4.5-0.6-6.2,0.7C134.5,964.4,133.2,966.5,135.1,967.5L135.1,967.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M142.3,961c3.1,0.8,6.2,1.5,9.3,2.3c0.8,0.2,3.4-1,1.8-1.4c-3.1-0.8-6.2-1.5-9.3-2.3
						C143.3,959.3,140.7,960.6,142.3,961L142.3,961z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M158.1,968c1.5,0.8,3.1,1.6,4.6,2.4c0.6,0.3,1.3,0.1,1.9-0.1c0.1-0.1,1.3-0.6,0.8-0.9
						c-1.5-0.8-3.1-1.6-4.6-2.4c-0.6-0.3-1.3-0.1-1.9,0.1C158.8,967.2,157.6,967.7,158.1,968L158.1,968z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M177,982.2c1.6,0,3.1,0,4.7,0c0.7,0,1.5-0.2,2-0.5c0.4-0.3,0.4-0.5-0.1-0.5c-1.6,0-3.1,0-4.7,0
						c-0.7,0-1.5,0.2-2,0.5C176.4,982,176.4,982.2,177,982.2L177,982.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M209.7,988.6c16.7,1.9,32.7,6.4,47.9,13.5c0.8,0.4,3.4-0.8,2.3-1.3c-15.3-7.2-31.6-11.8-48.4-13.7
						C210.6,987.1,208.1,988.5,209.7,988.6L209.7,988.6z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M263.4,1003.5c1.2,0,3.1-1.7,1.1-1.7C263.4,1001.8,261.5,1003.5,263.4,1003.5L263.4,1003.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M146.8,939.4c0-3.9,0-7.8,0-11.6c0-0.7-2.9-0.2-2.9,0.8c0,3.9,0,7.8,0,11.6
						C143.9,940.9,146.8,940.4,146.8,939.4L146.8,939.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M146.8,907.1c0-7,0-14,0-20.9c0-1.3-2.9-0.7-2.9,0.3c0,7,0,14,0,20.9
						C143.9,908.7,146.8,908.2,146.8,907.1L146.8,907.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M143.2,862.2c11.4-7.3,15.3-22.9,8-34.5c-0.5-0.8-3.3,0.1-2.7,1.1c6.7,10.6,3.6,25.7-7.1,32.5
						C139.8,862.5,142.3,862.8,143.2,862.2L143.2,862.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M147.6,809.4c-4.3-9.1-4.1-19.1-3.7-29c0.5-11.2,1-22.4,1.5-33.6c0.1-1.3-2.9-0.8-2.9,0.3
						c-0.5,11.8-1.1,23.5-1.6,35.3c-0.4,9.5-0.3,19,3.8,27.8C145.2,811.2,148.1,810.4,147.6,809.4L147.6,809.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#030405" d="M146.8,739.7c0-0.8,0-1.6,0-2.3c0-0.7-0.7-1-1.3-1c-0.6,0.1-1.6,0.4-1.6,1.2c0,0.8,0,1.6,0,2.3
						c0,0.7,0.7,1,1.3,1C145.8,740.8,146.8,740.5,146.8,739.7L146.8,739.7z"/>
				</g>
			</g>
			<g>
				<path fill="#EEEEEE" stroke="#546474" stroke-miterlimit="10" d="M257,278.2c-0.6-4.5-1.1-9.1-1.7-13.6c3.3-0.4,6.6-0.8,9.9-1.2
					c0,0,0,0,0,0c0.6,4.5,1.1,9.1,1.7,13.6C263.6,277.4,260.3,277.8,257,278.2L257,278.2z"/>
				<path fill="#333333" stroke="#546474" stroke-miterlimit="10" d="M387.7,252.3c3.3,1,4.7,3.6,5.1,8.3c0.8,9.5,2.1,19,3.4,28.5
					c0.5,3.4,0,5.8-2.2,7.2c-0.7,0-1.5,0-2.2,0.1c-39,4.7-78.1,9.5-117.1,14.2c-62.9,7.6-125.8,15.3-188.7,23
					c-2.1,0.3-4,0.1-5.5-2.4c-0.1-0.3-0.2-0.7-0.3-1c-1.3-10.7-2.4-21.4-3.9-32.1c-0.6-4.5,0.2-7.4,2.9-9.4c1.1,1.6,2.5,1.2,3.8,1.1
					c99.8-12.1,199.6-24.2,299.4-36.4C384.1,253.3,385.9,252.7,387.7,252.3z M208.4,284.3C208.4,284.3,208.4,284.3,208.4,284.3
					c-13.8,1.7-27.7,3.4-41.5,5c-2.2,0.2-2.7,1.2-2.2,4.2c0.8,5.4,1.5,10.9,2,16.3c0.3,3.1,1.1,3.7,3.3,3.4
					c13.7-1.8,27.5-3.4,41.2-5.1c13.7-1.7,27.3-3.3,41-5c3.2-0.4,3.2-0.4,2.7-5c-0.6-5.2-1.4-10.4-1.9-15.7c-0.3-2.8-1-3.6-3.1-3.3
					C236,281,222.2,282.6,208.4,284.3z M316.4,295.3C316.4,295.3,316.4,295.3,316.4,295.3c13.9-1.7,27.8-3.4,41.7-5
					c2-0.2,2.4-1.1,2.1-3.8c-0.8-5.9-1.5-11.8-2.1-17.7c-0.2-2.1-0.8-2.7-2.3-2.5c-28.1,3.5-56.1,6.9-84.2,10.2
					c-1.6,0.2-2.1,1-1.8,3.2c0.8,5.7,1.5,11.3,2,17c0.3,3.2,1.1,3.9,3.4,3.6C288.9,298.6,302.7,297,316.4,295.3z M93.6,314.8
					C93.6,314.8,93.6,314.8,93.6,314.8c-0.5,0-1,0.2-1.5,0.1c-1.4,0-1.8,0.7-1.6,2.7c0.3,2.1,1.1,2.4,2.3,2.1c1-0.2,2.1-0.3,3.2-0.4
					c1.4-0.1,1.7-1.3,1.6-3c-0.2-1.7-1-2-2-1.8C94.9,314.6,94.3,314.7,93.6,314.8z M125.2,303.9c0.7-0.8,3.1,1.1,2.7-2.5
					c-0.2-2.4-1.5-2.3-2.7-1.9c-1.4,0.4-3.7-0.9-3.8,2.6c0,1.3,0.6,2.2,1.6,2.1C123.7,304.1,124.3,304,125.2,303.9z M134.9,314
					c0.6-0.1,1.1-0.2,1.7-0.2c1-0.1,1.7-0.5,1.5-2.2c-0.2-1.7-1-1.9-2-1.8c-1.2,0.2-2.5,0.3-3.7,0.5c-0.9,0.1-1.2,0.9-1.1,2.1
					c0.1,1.1,0.3,2.1,1.4,2C133.4,314.2,134.2,314.1,134.9,314z M118.1,316c0.7-0.1,1.3-0.2,2-0.2c1-0.1,1.4-0.9,1.2-2.3
					c-0.2-1.5-0.8-1.9-1.8-1.8c-1.4,0.2-2.8,0.3-4.2,0.6c-1,0.2-0.9,1.3-0.8,2.3c0.1,1,0.5,1.7,1.3,1.6
					C116.6,316.2,117.4,316.1,118.1,316z M115.9,300.9c-0.3,0-0.7,0-1.1,0c-1.2-0.2-1.9,0.3-1.7,2.2c0.2,1.8,0.8,2.4,2.1,2.1
					c0.7-0.2,1.5-0.2,2.2-0.3c1.3-0.1,2.7-0.2,2.3-2.7c-0.4-2.5-1.8-1.3-2.9-1.4C116.6,300.7,116.4,300.8,115.9,300.9z M152.2,311.9
					c0.6-0.1,1.1-0.1,1.7-0.2c1.1-0.1,1.6-0.8,1.4-2.4c-0.2-1.5-1-1.7-1.9-1.6c-1.1,0.1-2.3,0.3-3.4,0.4c-1,0.1-1.5,0.7-1.3,2.2
					c0.2,1.4,0.9,1.9,1.8,1.8C151.1,312,151.7,312,152.2,311.9z M143.1,313c0.5-0.1,1-0.1,1.5-0.2c1.1-0.1,1.9-0.4,1.6-2.4
					c-0.2-1.8-1-1.8-2-1.7c-1.1,0.2-2.1,0.3-3.2,0.4c-1,0.1-1.4,0.7-1.3,2.2c0.1,1.5,0.7,2,1.7,1.9
					C141.9,313.2,142.5,313.1,143.1,313z M126.2,315.1C126.2,315.1,126.2,315.1,126.2,315.1c0.6,0,1.1-0.1,1.7-0.2
					c1.1-0.1,1.7-0.6,1.5-2.3c-0.2-1.8-1-2-2-1.8c-1.1,0.2-2.3,0.3-3.4,0.5c-0.9,0.1-1.2,0.9-1.1,2.1c0.2,1.2,0.6,1.9,1.6,1.8
					C125.1,315.2,125.6,315.1,126.2,315.1z M141.4,297.8C141.4,297.8,141.4,297.8,141.4,297.8c-0.5,0-1,0.1-1.5,0.1
					c-1,0.1-1.9,0.4-1.6,2.1c0.2,1.6,1.2,1.8,2.1,1.7c1.1-0.2,2.3-0.3,3.4-0.5c0.8-0.2,1-1,0.9-2c-0.1-1-0.5-1.7-1.3-1.7
					C142.7,297.6,142,297.8,141.4,297.8z M133.4,302.6C133.4,302.7,133.4,302.7,133.4,302.6c0.4,0,0.8-0.1,1.2-0.1
					c1.1-0.1,2-0.5,1.7-2.4c-0.3-1.7-1.3-1.6-2.2-1.5c-1.1,0.1-2.1,0.2-3.2,0.4c-0.8,0.1-1.1,0.8-1,1.9c0.1,1.2,0.5,2,1.5,1.9
					C132.1,302.8,132.7,302.7,133.4,302.6z"/>
				<path fill="#353D44" d="M387.7,252.3c-1.8,0.4-3.6,1-5.4,1.2c-99.8,12.1-199.6,24.3-299.4,36.4c-1.3,0.2-2.6,0.6-3.8-1.1
					c1.4-2,2.8-4,4.3-6c0.5-0.2,0.8-0.7,1-1.4c0,0,0,0,0,0c3-2.8,5.4-6.4,7.8-9.9l0,0c0.3-0.4,0.7-0.8,1-1.2l0,0
					c3.3-4.1,6.9-7.9,9.8-12.5c2.6-4,5.9-4.3,9.4-4.9c2.8,0,5.7-0.4,8.4-1c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2
					c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2
					c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2
					c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2
					c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2c3.3-0.4,6.6-0.8,9.8-1.2c3.3-0.4,6.6-0.8,9.9-1.2
					c1.1-0.1,2.3-0.1,3.4-0.3c2.2-0.4,4.4-0.5,6.5,1.1c3.7,3.2,7.6,5.6,11.6,7.7c3.4,1.8,6.7,4.1,10.1,6.2c0,0,0,0,0,0
					c3.6,1.9,6.8,5.1,10.5,6.5C382.7,249.4,385.6,249.7,387.7,252.3z"/>
				<path fill="#949596" stroke="#546474" stroke-miterlimit="10" d="M208.4,284.3c13.8-1.7,27.7-3.3,41.5-5.1
					c2.1-0.3,2.8,0.5,3.1,3.3c0.5,5.2,1.2,10.4,1.9,15.7c0.6,4.6,0.6,4.6-2.7,5c-13.7,1.7-27.3,3.3-41,5
					c-13.7,1.7-27.5,3.3-41.2,5.1c-2.1,0.3-3-0.3-3.3-3.4c-0.5-5.5-1.1-10.9-2-16.3c-0.5-3,0-3.9,2.2-4.2
					C180.7,287.7,194.5,286,208.4,284.3C208.4,284.3,208.4,284.3,208.4,284.3z M199.9,307.6c2.5-0.3,4.9-0.7,7.4-0.9
					c1.2-0.1,1.5-0.7,1.3-2.3c-0.5-3.4-0.9-6.8-1.2-10.2c-0.2-2-0.6-3-2.2-2.5c-1.1,0.3-1.8-0.3-1.9-1.9c-0.1-0.6-0.4-0.9-0.7-1.3
					c-1.9-2.2-3.9-1.7-6-1.5c-2.4,0.1-3.3,2.1-3.8,4.8c-0.1,0.9-0.4,1.6-1,1.5c-2.9-0.3-2.3,2.2-2,4.6c0.4,2.8,0.8,5.6,1,8.5
					c0.2,1.8,0.7,2.3,2,2.1C195.1,308.1,197.5,307.9,199.9,307.6z M242.9,302.4C242.9,302.4,242.9,302.4,242.9,302.4
					c2.4-0.3,4.8-0.6,7.1-0.9c1.2-0.1,1.8-0.5,1.5-2.4c-0.5-3.5-0.9-7-1.3-10.5c-0.2-1.6-0.6-2.3-1.8-2c-1.2,0.3-1.8-0.1-2.5-2
					c-0.4-1.3-1.9-2.7-3-3c-3.8-1-6.4,1.1-7.5,5.7c-0.1,0.6-0.4,1.1-0.8,1c-3.2-0.4-2.2,2.5-1.9,4.9c0.3,2.5,0.7,5,0.9,7.5
					c0.2,2.2,0.7,3,2.4,2.7C238.3,302.9,240.6,302.7,242.9,302.4z M178.3,310.3C178.3,310.3,178.3,310.3,178.3,310.3
					c2.4-0.3,4.8-0.7,7.2-0.8c1.5-0.1,2-0.7,1.7-2.9c-0.5-3.3-0.8-6.6-1.2-9.8c-0.1-0.7-0.1-2.2-0.6-2.1c-2.3,0.6-3.3-1.5-4.8-3.3
					c-0.6-0.7-0.6-1.7-1.4-2.1c-2.6-1.3-7.2,1.7-8,5.3c-0.1,0.7-0.3,1.6-1,1.6c-2.6-0.3-2.1,1.9-1.8,4.2c0.4,2.6,0.8,5.2,0.9,7.8
					c0.2,2.4,0.7,3.5,2.7,3C174,310.6,176.2,310.5,178.3,310.3z M221.4,305.1c2.2-0.3,4.4-0.7,6.7-0.8c1.7-0.1,2.3-0.8,1.9-3.3
					c-0.5-3-0.9-6.1-1.1-9.2c-0.2-2.3-0.8-3.1-2.4-2.6c-0.5,0.2-1.5,0.3-1.5-0.5c-0.1-2.1-1.3-2.5-2.2-3.5c-1-1-6.4-1.2-7.1,0
					c-1.3,2.2-1.4,6-4.4,6c-0.5,0-0.2,1.4-0.1,2.2c0.4,3.4,0.9,6.8,1.2,10.2c0.2,2,0.8,2.5,2.2,2.3
					C216.8,305.5,219.1,305.3,221.4,305.1z"/>
				<path fill="#949596" stroke="#546474" stroke-miterlimit="10" d="M316.4,295.3c-13.7,1.7-27.5,3.3-41.2,5.1
					c-2.3,0.3-3.1-0.5-3.4-3.6c-0.5-5.7-1.3-11.3-2-17c-0.3-2.2,0.2-3,1.8-3.2c28.1-3.4,56.1-6.8,84.2-10.2c1.5-0.2,2.1,0.5,2.3,2.5
					c0.6,5.9,1.3,11.8,2.1,17.7c0.4,2.7-0.1,3.5-2.1,3.8C344.2,291.9,330.3,293.6,316.4,295.3C316.4,295.3,316.4,295.3,316.4,295.3z
					 M348.5,289.8c2.3-0.3,4.6-0.7,6.9-0.8c1.8-0.1,2.1-1.1,1.8-3.4c-0.5-3-0.9-6.1-1.1-9.2c-0.2-2-0.6-3.3-2.3-2.7
					c-0.8,0.3-1.3-0.3-1.8-1.4c-1.1-2.3-2.5-3.9-4.7-3.6c-1.1,0.2-2.3,0.3-3.5,0.4c-0.8,0.1-1.1,0.5-1.6,1.5
					c-0.7,1.5-0.6,4.3-2.1,4.4c-2.5,0.3-2,2.2-1.7,4.4c0.4,2.7,0.8,5.4,1,8.2c0.1,2.7,1,3.2,2.7,2.8
					C344.2,290.2,346.4,290.1,348.5,289.8z M305.7,295C305.7,295,305.7,295,305.7,295c2.4-0.3,4.8-0.6,7.1-0.8
					c1.2-0.1,1.8-0.6,1.5-2.4c-0.5-3.5-0.9-7-1.3-10.5c-0.2-1.8-0.7-2.4-2-2.1c-0.7,0.1-1.9,0.9-2-1c-0.1-2.9-2.8-3.9-3.1-3.9
					c-2.4,0.3-5.5-1.1-7.1,2.4c-0.9,2-1.1,4.9-3.6,4.7c-0.4,0-0.2,1.2-0.1,1.8c0.4,3.6,0.9,7.2,1.3,10.9c0.2,1.6,0.6,2.2,1.8,2
					C300.8,295.5,303.3,295.3,305.7,295z M284.2,297.6C284.2,297.6,284.2,297.6,284.2,297.6c2.4-0.3,4.8-0.6,7.2-0.8
					c1.3-0.1,1.9-0.6,1.6-2.6c-0.5-3.5-0.9-7-1.3-10.5c-0.2-1.6-0.7-2.2-1.8-2c-1.2,0.2-2,0.2-2.7-2c-0.4-1.4-1.9-3.4-3.8-2.9
					c-1.1,0.3-2.3,0.2-3.4,0.5c-1.7,0.4-2.5,2.2-3,4.1c-0.4,1.5-0.6,2.5-1.9,2.4c-1.1-0.1-1.4,0.6-1.2,2.1c0.5,3.6,1,7.3,1.3,10.9
					c0.2,1.6,0.6,2.2,1.8,2C279.4,298.1,281.8,297.9,284.2,297.6z M327.1,292.4c2.5-0.3,4.9-0.6,7.4-0.9c1-0.1,1.6-0.4,1.4-2
					c-0.5-3.8-0.9-7.7-1.4-11.6c-0.1-0.9-0.4-1.7-1.1-1.5c-1.7,0.4-2.3-0.4-3.5-2.7c-2-4-8.3-2.8-9.8,0.4c-0.2,0.4-0.3,1.1-0.2,1.6
					c0.5,2.2-0.5,2.5-1.7,2.5c-1.3,0-1.6,0.8-1.4,2.6c0.5,3.4,0.9,6.8,1.2,10.2c0.2,2,0.8,2.5,2.2,2.2
					C322.5,292.9,324.8,292.7,327.1,292.4z"/>
				{/* индикаторы коммутатора */}
				<path fill={ledOn} stroke="#546474" stroke-miterlimit="10" d="M92.4,314.9c0.7-0.1,1.3-0.1,2-0.2c1-0.1,1.8,0.2,2,1.8
					c0.2,1.7-0.1,2.9-1.6,3c-1.1,0.1-2.1,0.2-3.2,0.4c-1.2,0.2-2,0-2.3-2.1c-0.2-2,0.2-2.7,1.6-2.7C91.4,315.1,91.9,315,92.4,314.9
					C92.4,314.9,92.4,314.9,92.4,314.9z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M123.9,304.1c-0.9,0.1-1.5,0.2-2.1,0.2
					c-1.1,0.1-1.6-0.8-1.6-2.1c0.1-3.5,2.4-2.1,3.8-2.6c1.1-0.4,2.4-0.5,2.7,1.9C127,305.2,124.6,303.3,123.9,304.1z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M133.7,314.2c-0.7,0.1-1.5,0.2-2.2,0.3c-1,0.1-1.3-0.8-1.4-2
					c-0.1-1.2,0.1-2,1.1-2.1c1.2-0.1,2.5-0.3,3.7-0.5c1-0.2,1.8,0.1,2,1.8c0.3,1.8-0.4,2.2-1.5,2.2
					C134.8,314,134.2,314.1,133.7,314.2z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M116.8,316.2c-0.7,0.1-1.5,0.2-2.2,0.3
					c-0.8,0.1-1.2-0.6-1.3-1.6c-0.1-1.1-0.3-2.2,0.8-2.3c1.4-0.2,2.8-0.4,4.2-0.6c1-0.1,1.6,0.3,1.8,1.8c0.2,1.4-0.2,2.2-1.2,2.3
					C118.1,316,117.5,316.1,116.8,316.2z"/>
				<path fill={led1} stroke="#546474" stroke-miterlimit="10" d="M114.7,301c0.4,0,0.7-0.1,0.9-0.1c1.1,0.1,2.5-1,2.9,1.4
					c0.4,2.5-1.1,2.5-2.3,2.7c-0.7,0.1-1.5,0.1-2.2,0.3c-1.2,0.3-1.9-0.4-2.1-2.1c-0.2-1.9,0.5-2.4,1.7-2.2
					C114,301,114.4,301,114.7,301z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M151,312.1c-0.6,0.1-1.1,0.1-1.7,0.2c-1,0.1-1.6-0.4-1.8-1.8
					c-0.2-1.5,0.3-2.1,1.3-2.2c1.1-0.1,2.3-0.3,3.4-0.4c0.9-0.1,1.7,0,1.9,1.6c0.2,1.6-0.3,2.3-1.4,2.4
					C152.1,311.9,151.6,312,151,312.1z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M141.8,313.2c-0.6,0.1-1.1,0.1-1.7,0.2c-1,0.1-1.6-0.4-1.7-1.9
					c-0.1-1.4,0.3-2.1,1.3-2.2c1.1-0.1,2.1-0.2,3.2-0.4c1-0.1,1.8-0.1,2,1.7c0.3,1.9-0.5,2.3-1.6,2.4
					C142.8,313,142.3,313.1,141.8,313.2z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M125,315.2c-0.6,0.1-1.1,0.2-1.7,0.2c-0.9,0-1.4-0.6-1.6-1.8
					c-0.2-1.2,0.1-2,1.1-2.1c1.1-0.2,2.3-0.3,3.4-0.5c1-0.2,1.8,0,2,1.8c0.2,1.7-0.4,2.2-1.5,2.3C126.1,315.1,125.5,315.2,125,315.2
					C125,315.2,125,315.2,125,315.2z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M140.1,298c0.7-0.1,1.3-0.2,2-0.2c0.8,0,1.2,0.7,1.3,1.7
					c0.1,1-0.1,1.8-0.9,2c-1.1,0.2-2.3,0.4-3.4,0.5c-1,0.1-1.9-0.1-2.1-1.7c-0.2-1.7,0.7-2,1.6-2.1C139.1,298.1,139.6,298,140.1,298
					C140.1,298,140.1,298,140.1,298z"/>
				<path fill={led2} stroke="#546474" stroke-miterlimit="10" d="M132.1,302.8c-0.7,0.1-1.3,0.2-2,0.2c-0.9,0-1.4-0.7-1.5-1.9
					c-0.1-1.1,0.2-1.7,1-1.9c1.1-0.2,2.1-0.3,3.2-0.4c0.9-0.1,1.9-0.2,2.2,1.5c0.3,1.9-0.7,2.2-1.7,2.4
					C132.9,302.7,132.5,302.8,132.1,302.8C132.1,302.8,132.1,302.8,132.1,302.8z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M198.6,307.8c-2.4,0.3-4.8,0.5-7.1,0.9c-1.2,0.2-1.8-0.3-2-2.1
					c-0.2-2.8-0.6-5.7-1-8.5c-0.3-2.5-0.9-4.9,2-4.6c0.6,0.1,0.9-0.6,1-1.5c0.4-2.7,1.4-4.6,3.8-4.8c2.1-0.1,4.1-0.7,6,1.5
					c0.3,0.4,0.6,0.7,0.7,1.3c0.2,1.6,0.9,2.2,1.9,1.9c1.6-0.5,2,0.6,2.2,2.5c0.3,3.4,0.8,6.8,1.2,10.2c0.2,1.6-0.1,2.2-1.3,2.3
					C203.6,307.1,201.1,307.5,198.6,307.8z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M242.9,302.4c-2.3,0.3-4.6,0.4-6.9,0.9
					c-1.7,0.3-2.2-0.5-2.4-2.7c-0.2-2.5-0.6-5-0.9-7.5c-0.3-2.3-1.3-5.3,1.9-4.9c0.5,0.1,0.7-0.4,0.8-1c1.2-4.6,3.7-6.7,7.5-5.7
					c1.1,0.3,2.6,1.7,3,3c0.6,1.9,1.3,2.3,2.5,2c1.2-0.3,1.6,0.5,1.8,2c0.4,3.5,0.8,7,1.3,10.5c0.3,1.9-0.3,2.3-1.5,2.4
					C247.6,301.8,245.3,302.1,242.9,302.4C242.9,302.4,242.9,302.4,242.9,302.4z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M177.1,310.4c-2.1,0.3-4.3,0.3-6.4,0.8c-1.9,0.5-2.5-0.6-2.7-3
					c-0.2-2.6-0.6-5.2-0.9-7.8c-0.3-2.2-0.8-4.4,1.8-4.2c0.8,0.1,0.9-0.9,1-1.6c0.8-3.6,5.4-6.6,8-5.3c0.8,0.4,0.8,1.4,1.4,2.1
					c1.5,1.7,2.6,3.9,4.8,3.3c0.5-0.1,0.5,1.3,0.6,2.1c0.4,3.3,0.7,6.6,1.2,9.8c0.3,2.2-0.2,2.8-1.7,2.9
					C181.8,309.8,179.5,310.2,177.1,310.4C177.1,310.4,177.1,310.4,177.1,310.4z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M220.1,305.2c-2.3,0.3-4.6,0.5-6.9,0.9c-1.3,0.2-2-0.3-2.2-2.3
					c-0.3-3.4-0.8-6.8-1.2-10.2c-0.1-0.8-0.4-2.2,0.1-2.2c3-0.1,3.1-3.8,4.4-6c0.7-1.2,6.1-1,7.1,0c0.9,1,2.1,1.3,2.2,3.5
					c0,0.8,1,0.7,1.5,0.5c1.6-0.5,2.3,0.3,2.4,2.6c0.2,3.1,0.6,6.1,1.1,9.2c0.4,2.5-0.2,3.2-1.9,3.3
					C224.6,304.6,222.4,305,220.1,305.2z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M348.5,289.8c-2.1,0.3-4.3,0.4-6.4,0.8
					c-1.7,0.4-2.6-0.1-2.7-2.8c-0.1-2.7-0.6-5.4-1-8.2c-0.3-2.2-0.8-4.2,1.7-4.4c1.5-0.2,1.4-3,2.1-4.4c0.5-1,0.9-1.4,1.6-1.5
					c1.1-0.2,2.3-0.2,3.5-0.4c2.1-0.4,3.6,1.3,4.7,3.6c0.5,1.1,1,1.6,1.8,1.4c1.7-0.6,2.1,0.7,2.3,2.7c0.3,3.1,0.6,6.1,1.1,9.2
					c0.4,2.3,0.1,3.3-1.8,3.4C353.1,289.1,350.8,289.5,348.5,289.8z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M305.7,295c-2.5,0.3-4.9,0.5-7.4,0.9c-1.2,0.2-1.6-0.4-1.8-2
					c-0.4-3.6-0.9-7.2-1.3-10.9c-0.1-0.6-0.3-1.9,0.1-1.8c2.5,0.2,2.7-2.7,3.6-4.7c1.6-3.5,4.6-2.1,7.1-2.4c0.3,0,3,1,3.1,3.9
					c0.1,1.9,1.3,1.1,2,1c1.3-0.2,1.8,0.3,2,2.1c0.3,3.5,0.8,7,1.3,10.5c0.3,1.9-0.3,2.3-1.5,2.4C310.5,294.3,308.1,294.7,305.7,295
					C305.7,295,305.7,295,305.7,295z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M284.2,297.6c-2.4,0.3-4.8,0.5-7.2,0.9c-1.2,0.2-1.6-0.4-1.8-2
					c-0.4-3.6-0.8-7.3-1.3-10.9c-0.2-1.5,0.1-2.2,1.2-2.1c1.3,0.1,1.6-0.9,1.9-2.4c0.4-1.9,1.3-3.7,3-4.1c1.1-0.2,2.3-0.2,3.4-0.5
					c1.9-0.5,3.4,1.5,3.8,2.9c0.7,2.2,1.5,2.2,2.7,2c1.1-0.2,1.7,0.4,1.8,2c0.4,3.5,0.8,7,1.3,10.5c0.3,2.1-0.3,2.5-1.6,2.6
					C288.9,297,286.5,297.3,284.2,297.6C284.2,297.6,284.2,297.6,284.2,297.6z"/>
				<path fill="#070505" stroke="#546474" stroke-miterlimit="10" d="M327.1,292.4c-2.3,0.3-4.6,0.5-6.9,0.9c-1.3,0.2-2-0.2-2.2-2.2
					c-0.3-3.4-0.7-6.8-1.2-10.2c-0.3-1.7,0.1-2.6,1.4-2.6c1.2,0,2.2-0.3,1.7-2.5c-0.1-0.5,0-1.1,0.2-1.6c1.5-3.2,7.8-4.5,9.8-0.4
					c1.1,2.3,1.8,3.1,3.5,2.7c0.7-0.2,1,0.6,1.1,1.5c0.5,3.9,0.9,7.7,1.4,11.6c0.2,1.6-0.4,1.9-1.4,2
					C332.1,291.8,329.6,292.1,327.1,292.4z"/>
			</g>
			{/* 
			<g>
				<g>
					<path fill="#808080" d="M632.9,1105.9c23.8-5.8,47.6-11.5,71.4-17.2c11-2.6,22.1-5.2,33.5-3.8c10.9,1.4,21,6.1,30.1,12.1
						c17.8,11.8,32.5,28.4,44.8,45.8c11.6,16.4,21.3,35.1,24,55.3c2.2,17-2,35.2-14.3,47.7c-12.8,12.9-31.4,16.7-49,15.3
						c-37.8-2.9-72.4-27.2-83-64.4c-4.5-15.7-4.8-32.3-3.3-48.5c0.9-9.2,1.4-20.1,7.1-27.8c5.8-7.8,16.2-10.1,25.4-10.6
						c20.5-1.2,41.5,5.7,59.5,15.1c17.8,9.3,34.1,22,47.3,37.1c6.2,7.1,11.9,14.8,16.4,23.1c4.9,9,10.2,21.3,3.8,30.9
						c-5.1,7.8-15.3,11.8-23.8,14.6c-9.5,3.1-19.3,4.9-29.2,5.4c-22.1,1.2-44.4-3.8-63.7-14.5c-17.9-9.9-33.1-23.9-46.6-39.2
						c-6.3-7.1-12.2-14.5-18-22c-6.8-8.7-13.9-17.7-18.3-28c-4-9.2-5.2-19.3,0.5-28.1c5.3-8.2,14.1-13.8,22.3-18.7
						c19.4-11.6,39.7-22.9,60.6-31.6c21.1-8.8,40.5,1.9,56.4,15.8c16.7,14.6,30.5,32.7,45,49.5c15.7,18.2,31.4,36.4,47.1,54.6
						c7.9,9.1,15.8,18.1,23.6,27.3c6.4,7.6,13.6,16.2,16.1,26c1,4,1.1,8.2-1.3,11.7c-3,4.4-8.8,6-13.7,7.2
						c-10.8,2.7-21.9,4.6-32.9,6.2c-44.5,6.5-92.1,8.9-135.5-4.7c-20-6.3-38.8-16.1-54.8-29.7c-13.8-11.8-26.9-27.5-29.6-46.1
						c-3-20.7,8.9-38.8,20.8-54.6c10-13.4,21.7-26.4,37.3-33.3c41.4-18.1,78.4,22.4,104.6,48.7c15.1,15.1,30.1,31,40.9,49.6
						c9.1,15.6,16.4,36.7,8.8,54.4c-7.5,17.4-27.4,21.7-44.5,21.8c-20.6,0.2-41.1-4.7-60.7-10.5c-20.8-6.1-41.5-13.8-59.6-26
						c-16.8-11.3-30.7-26-42.5-42.3c-11.2-15.5-21.5-32.3-30.2-49.4c-5-9.8-9-21.3-0.8-30.5c6.3-7.1,15.7-11.5,24-15.9
						c17.3-9.1,35.9-16.1,55.5-18.1c21.3-2.1,42.6,2.1,62,11c34.6,16,61.7,45.1,84.1,75.3c12.8,17.2,28.5,36.5,29.1,58.9
						c0.4,16.1-5.9,31.5-17,43.1c-23.8,24.8-62.7,30.1-95.2,25.5c-37.3-5.3-72.1-24.3-98.3-51.1c-21.3-21.8-40.6-51.9-39.7-83.6
						c1.1-36.2,31.6-60.8,62.4-74c19.9-8.5,41.6-13.5,63.3-12.5c22.8,1.2,44.1,9.4,63.1,21.7c18.3,11.8,34.9,26.5,49.4,42.7
						c13.1,14.6,25.6,31.6,30.3,51c9.2,38.2-21.6,71.6-54,86.1c-36.5,16.3-78.6,14.9-116.7,5.9c-41.5-9.8-81.8-29.2-114.1-57.1
						c-29.9-25.7-56.2-63.2-42.2-104.3c11.5-33.6,41.8-57,76.2-63.5c41.5-7.8,84.3,7.7,120.3,27.3c18.7,10.2,36.5,22.1,53.4,35.1
						c17.1,13.1,33.7,27.5,47,44.6c13.6,17.5,22.8,38.3,23.1,60.7c0.3,20.7-6.6,40.7-17.3,58.2c-4.6,7.5-10.6,18.5-19.5,21.5
						c-10.2,3.5-22.8-2.3-31.8-6.8c-17.6-8.8-34.4-23.3-42.6-41.5c-1.7-3.7-3.3-7.9-3-12c0.6-6.9,6.9-6.7,12.2-5
						c8.8,2.8,16.6,8.9,21.9,16.4c9.1,12.6,12.4,30.2,7.6,45.1c-6.8,20.9-29.7,15.9-44.6,7.3c-16.9-9.7-28-25.5-30.7-44.9
						c-1.2-9.1-1.1-19.3,0.6-28.4c0.7-3.4,2.5-8.2,6.2-9.2c5.3-1.3,9.5,4.6,11.9,8.4c5.8,9.3,17.6,34.6,0.5,39.3
						c1.1,0.3,2.1,0.5,3.2,0.8c-16.3-18-29-44.7-19.4-68.9c-1.9,0.1-3.8,0.3-5.7,0.4c2.9,14.4,5.4,29.2,5.5,43.9
						c0,7.3-0.2,15.7-3.9,22.2c-2.1,3.8-5.4,5.7-9.7,5.5c-12.3-0.7-25.7-10.6-33.3-19.7c-6.5-7.8-7.4-16.1,0.8-22.9
						c8.1-6.7,18.8-10.2,28.7-13.2c22.8-7,46.7-12.7,70.7-11.8c28.5,1.1,60.2,12.9,72.7,40.7c5.1,11.4,6.3,24.9,0.2,36.1
						c-6.7,12.4-20.2,19.6-33.3,23.4c-28.4,8.3-63.7,5.2-85.9-16c-22.3-21.2-33.4-63.1-14-89.7c5.6-7.7,14.4-9,22-3.1
						c2.9,2.2,6.6,5.6,7,9.6c0.7,7.3-6.9,14.2-11.7,18.6c-16.3,14.8-36.3,25.8-55.6,36.1c-3.8,2-7.7,4-11.6,6
						c-3.5,1.8-0.4,5.1,2.6,3.6c22.1-11.3,44.7-23.3,64-39.1c6.9-5.6,15.4-12.8,17.7-21.8c2.6-10-8.4-18.8-17.1-20.8
						c-16.2-3.8-26.2,12.4-30.2,25.6c-5.3,17.6-2.6,36.6,4.8,53.1c6.9,15.6,18.1,28.5,33.3,36.5c14.9,7.8,32,10.6,48.7,10
						c27.2-1,65.5-12.9,69-44.8c3.6-32.9-27.1-57.3-55.8-64.7c-28.8-7.4-58.2-2.4-86.4,5c-11.9,3.1-24.2,6.6-35.1,12.4
						c-7.8,4.2-17.3,10.9-16.8,20.8c0.6,12.5,15,22.8,24.8,28.2c7.7,4.3,18.1,8.8,26.9,4.9c16.5-7.2,12.8-34.6,11.3-48.4
						c-1-9.4-2.6-18.6-4.5-27.9c-0.5-2.5-5-1.4-5.7,0.4c-10,25.4,2.5,53.2,19.7,72.3c0.8,0.9,2.1,1.1,3.2,0.8
						c18-4.9,14.3-24.6,8.2-37.8c-3.5-7.4-8.3-16.9-17.2-18.5c-7.5-1.3-13.4,3.8-15.7,10.6c-2.9,8.5-2.6,19.3-1.9,28.2
						c0.9,11.4,4.4,22.6,10.9,32.1c9.9,14.6,28.1,26,45.7,27.9c7.5,0.8,15.3-0.4,21.4-5c7.3-5.5,10.3-14.6,11.3-23.3
						c1.9-16.5-4-34-16-45.5c-6.6-6.3-15.7-11.8-25-12.3c-7.7-0.4-14.3,3.9-14.3,12.1c0,8.9,5.6,18,10.6,24.9
						c6.3,8.6,14.2,16.2,22.9,22.3c14.9,10.5,40.5,24.6,57.9,12c7.6-5.5,13-14.7,17.8-22.7c5.7-9.5,10.2-19.7,13.1-30.3
						c6.2-22.7,4.3-46.5-5.8-67.8c-10-21-26.5-38.3-43.9-53.4c-35.7-31-78-58.4-123.5-72.1c-39.3-11.9-83.3-11.6-117.9,12.9
						c-33,23.5-51.1,66.6-34,105.2c18,40.6,58.9,69.6,97.7,88.4c40.8,19.7,87.8,31.5,133.3,26.9c37.3-3.8,76.2-20.2,96-53.6
						c11.2-18.8,13.6-40.3,5.4-60.7c-8.5-21.2-24.1-39-40.2-54.8c-17-16.5-35.9-31.4-57.5-41.4c-22.1-10.2-46.4-13.7-70.5-10.4
						c-37.8,5.2-81.8,24.7-100.4,60c-16.6,31.4-4.8,67.2,14.3,94.5c23,32.8,58,58.2,96.5,69.4c36,10.4,78.5,9.6,110.7-11.3
						c13-8.5,23.9-20.7,28.9-35.5c3.4-10,4.3-20.7,2.4-31.1c-2.3-12.3-8.9-23.2-15.8-33.4c-23.4-34.7-51.7-69.2-88.9-89.7
						c-19.1-10.6-40.3-16.9-62.3-17.1c-21.9-0.2-43.4,6-63.1,15.4c-9.2,4.4-18.6,9.2-26.9,15.1c-7.6,5.4-13.9,12.8-13.4,22.6
						c0.6,10.9,7.3,21.1,12.6,30.3c5.2,9.1,10.5,18.2,16.3,27c12.1,18.5,26.3,35.8,44,49.2c18.8,14.3,40.9,23.3,63.4,30.2
						c21.4,6.6,43.7,12.1,66.2,12.2c17.3,0.1,37.5-3.7,48.2-18.7c11.4-16,7.6-37.8,0-54.5c-9.9-21.9-26.8-40.1-43.5-57
						c-28.2-28.5-65.6-69.9-110.5-54.1c-16.8,5.9-30.1,18.7-40.9,32.5c-12.7,16.2-26.4,35.1-25.4,56.7c0.9,19.8,13.3,36.8,27.3,49.8
						c16.8,15.6,37.4,26.8,59.2,33.8c47.4,15.2,100,12,148.5,3.9c11-1.8,22.9-3.2,33.2-7.6c7.7-3.3,13.2-9.4,12.4-18.2
						c-0.9-10.1-8-19.3-14.1-27c-8.2-10.2-17-19.9-25.5-29.8c-18.1-20.9-36.2-41.9-54.2-62.8c-15.4-17.9-30.2-37.5-49.6-51.2
						c-8.1-5.7-17.1-10.4-27-12.1c-10.8-1.9-20.8,0.9-30.6,5.4c-22.7,10.4-46,21.8-66.6,36.1c-7.3,5.1-14.6,11.5-18,20
						c-3.7,9.3-1.5,19.1,2.6,27.9c5.3,11.2,13.1,21,20.8,30.6c6.9,8.7,14,17.3,21.7,25.4c15,15.8,32.2,29.8,52.3,38.5
						c21.4,9.2,45.4,12.2,68.4,8.6c10-1.5,20-4.2,29.2-8.5c7.9-3.6,16.4-9,19.6-17.6c3.6-9.5-0.3-19.8-4.7-28.3
						c-5-9.7-11.5-18.6-18.7-26.6c-14.7-16.5-32.8-30-52.6-39.5c-19-9.2-41.4-15.6-62.7-12.8c-8.8,1.1-17.9,4-24.1,10.6
						c-7.1,7.4-8.3,18.1-9.5,27.9c-2,17.5-2.1,35.6,2.6,52.7c10.8,40.1,48.6,66.4,89.3,68.7c17.8,1,36.5-2.7,50.2-14.8
						c13.8-12,20.2-30,19.2-48c-1.1-21.6-11.1-42.1-23-59.7c-13.2-19.4-29.8-38.3-49.8-50.9c-8.7-5.5-18.3-9.7-28.5-11.4
						c-11.2-1.8-22.3,0-33.2,2.5c-25.6,5.9-51.1,12.2-76.6,18.4C628.2,1102.7,629.3,1106.8,632.9,1105.9L632.9,1105.9z"/>
				</g>
			</g>
			 */}
			 <g>
				<g>
					<path fill="#333333" d="M447.5,467.9c-0.4-6.6,0.5-12.9,2.8-19.2c0.6-1.6-2.3-1.6-2.8-0.4c-2.4,6.4-3.4,12.9-3,19.7
						C444.6,469.7,447.5,469.2,447.5,467.9L447.5,467.9z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M450.6,438.5c0-2.2,0.4-4.4,1.3-6.4c0.6-1.3-2.3-1.1-2.7-0.1c-1,2.2-1.5,4.5-1.5,6.9
						C447.7,440.1,450.6,439.5,450.6,438.5L450.6,438.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M582,1186.8c7.8-2.5,15.7-4.7,23.7-6.6c1.3-0.3,1-1.7-0.4-1.4c-8,1.9-15.9,4.1-23.7,6.6
						C580.3,1185.8,580.6,1187.2,582,1186.8L582,1186.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M605.2,1179c0.8,0,2.3-1.2,0.9-1.2C605.3,1177.8,603.8,1179,605.2,1179L605.2,1179z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M560.5,1225.7c3.2,4.1,6.2,8.7,9.9,12.4c3.2,3.2,7.2,5.3,11.4,6.9c1,0.4,2.5-1,1.2-1.5
						c-9.7-3.8-14.4-10.8-20.6-18.7C561.8,1223.9,559.8,1224.7,560.5,1225.7L560.5,1225.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M620,1218.6c-3.4-2.3-7.1-5.2-8.6-9.2c-0.3-0.7-2.4,0-2.2,0.6c1.6,4.1,5.3,7,8.8,9.4
						C618.6,1219.8,620.5,1218.9,620,1218.6L620,1218.6z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M610.4,1208.5c1.3,0,1.6-1.7,0.2-1.7C609.3,1206.8,608.9,1208.5,610.4,1208.5L610.4,1208.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M628.7,1238c-1.8-2.5-3.6-5-5.4-7.4c-0.4-0.5-2.5,0.2-2,0.8c1.8,2.5,3.6,5,5.4,7.4
						C627.1,1239.4,629.2,1238.7,628.7,1238L628.7,1238z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M637.5,1234.7c-5.2-3-11.7-6.9-13.7-13c-0.3-0.8-2.4-0.2-2.2,0.6c2.1,6.3,8.7,10.4,14.1,13.4
						C636.4,1236.1,638.4,1235.2,637.5,1234.7L637.5,1234.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M654.3,1226.7c-3-1.3-12.9-5.9-8.9-10.6c0.6-0.7-1.6-0.6-2-0.1c-4.5,5.2,5.8,10.2,9.2,11.7
						C653.3,1228.1,655.2,1227.1,654.3,1226.7L654.3,1226.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M645.6,1101.3c8.6-4.7,18.6-5.4,28.2-4.9c0.4,0,2.5-0.8,1.4-0.8c-10.7-0.6-21.7,0.5-31.3,5.7
						C643.1,1101.8,645.2,1101.5,645.6,1101.3L645.6,1101.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M651.1,1089.1c13.7-10.2,28.3-19.1,43.5-26.9c1.3-0.7,0.1-1.8-1-1.3c-15.4,7.9-30.1,16.9-44,27.2
						C648.5,1089,650.2,1089.7,651.1,1089.1L651.1,1089.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M741,1254.5c-9.4-9.7-12.7-21-13.7-34.1c-0.9-11.5-2.7-25.7,9.2-32.2c1.3-0.7-0.6-1.1-1.4-0.7
						c-11.2,6.1-11,18.3-10.3,29.6c1,14.6,3.6,27.4,14.1,38.3C739.6,1256,741.6,1255.2,741,1254.5L741,1254.5z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M747.7,1251.3c-0.7-17.9,0.7-35.7,4.5-53.3c0.2-1-2-0.7-2.2,0.2c-3.8,17.5-5.2,35.4-4.5,53.3
						C745.5,1252.6,747.7,1252.1,747.7,1251.3L747.7,1251.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M798,1276.7c-9.2-9.5-17.8-21.9-16.4-35.7c0.1-0.9-2.1-0.6-2.2,0.2c-1.4,14.1,7.2,26.7,16.5,36.4
						C796.6,1278.2,798.7,1277.4,798,1276.7L798,1276.7z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M826.1,1231.2c7.8-0.1,15.6-1.1,22.8-4.1c1.4-0.6,0.1-0.9-0.8-0.5c-6.6,2.7-13.6,3.7-20.7,3.7
						C827,1230.3,825,1231.2,826.1,1231.2L826.1,1231.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M867.4,1255.2c8.6-6.2,16-13.6,21.7-22.5c0.2-0.3-1.8-0.2-2.1,0.4c-5.6,8.7-12.8,16-21.2,22
						C864.9,1255.8,867,1255.5,867.4,1255.2L867.4,1255.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M859.1,1156.2c-8.8-9.6-16.9-19.7-24.2-30.4c-0.4-0.6-2.5,0.1-2,0.8c7.3,10.8,15.4,20.9,24.2,30.4
						C857.5,1157.6,859.6,1156.8,859.1,1156.2L859.1,1156.2z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M873.7,1159.4c-1.5-3.3-3.8-6-6.5-8.5c-0.7-0.6-2.6,0.4-1.7,1.2c2.4,2.2,4.6,4.8,6,7.8
						C871.9,1161,874.1,1160.3,873.7,1159.4L873.7,1159.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M872.7,1094.8c-2.9-8.1-6.8-15.7-11.6-22.9c-0.5-0.8-2.6,0-2,0.9c4.7,7,8.6,14.5,11.5,22.5
						C870.9,1096.4,873,1095.8,872.7,1094.8L872.7,1094.8z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M676.6,1036c13.5-1.6,27.5,2.6,39.2,9.1c0.6,0.3,2.4-0.6,2-0.8c-12.3-6.9-26.8-10.9-40.9-9.2
						C676.1,1035.2,675.1,1036.2,676.6,1036L676.6,1036z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M810.4,1124.4c-5.4-7.5-12.3-13.7-19.8-19.1c-0.8-0.6-2.7,0.6-1.7,1.3c7.4,5.3,14.2,11.4,19.5,18.8
						C809,1126.2,811.1,1125.3,810.4,1124.4L810.4,1124.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path fill="#333333" d="M846.4,1193.6c-8.6-11.7-17.3-23.3-25.9-35c-0.4-0.5-2.4,0.3-2.2,0.6c8.6,11.7,17.3,23.3,25.9,35
						C844.7,1194.7,846.6,1193.8,846.4,1193.6L846.4,1193.6z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M764.4,1136.4c-1.1-0.3-2.3-0.7-3.4-1c-0.5-0.1-1.2,0-1.5,0.4c-0.3,0.4-0.2,0.8,0.3,1c1.1,0.3,2.3,0.7,3.4,1
						c0.5,0.1,1.2,0,1.5-0.4C765,1137,764.9,1136.6,764.4,1136.4L764.4,1136.4z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M761.7,1137.3c1,0,2.2-1.5,0.8-1.5C761.5,1135.9,760.2,1137.3,761.7,1137.3L761.7,1137.3z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M758.2,1136.2c0.8,0,2.3-1.2,0.9-1.2C758.3,1135,756.7,1136.2,758.2,1136.2L758.2,1136.2z"/>
				</g>
			</g>
		</g>
		<g id="Слой_4">
			<g>
				<path fill="#B0022F" d="M707.1,114.5c1.9-0.1,2.7,1.7,4,2.5c0.5,0.2,1,0.5,1.4,0.7l-0.3,0.2c0,0,0.3-0.2,0.3-0.2
					c0.1,1.5,0.8,2.3,2.4,2.1c0,0,0,0.1,0,0.1c-0.1,0.8,0.2,1.2,1,1.1l-0.3,0.4c0,0,0.3-0.4,0.3-0.4c-0.2,1.9,1,2.5,2.6,2.8
					c0,0,0.3,0.2,0.3,0.2c0.3,4.6,2.3,8.5,5.5,11.8c-0.1,4.8,2.5,8.5,4.8,12.4c-0.8,0.2-2.1,0.1-1.8,1.4c0.4,1.3,1.8,0.8,2.8,0.9
					l0,0c-2,5.5,0.6,10.6,1.1,15.9c0.2,2,1.8,2,3.2,2.1c0.1,1.5,0.1,2.9,0.2,4.4c-4.3,2.5-1.7,6.7-2.4,10.1
					c-10.9,4.8-21.1,11-31.4,16.9c-5.5,3.2-8.1,2.2-8.7-4.2c-0.3-3.6-1.8-6.3-4.8-8.1c-0.6-0.4-1.6-0.4-1.5-1.3
					c0.3-10.9-7-17.7-13.4-24.9c-0.5-0.5-1.7-0.4-2.6-0.6c-0.2-0.2-0.3-0.5-0.2-0.7c0.2-1.5-0.4-2.5-2-2.6c0,0,0-0.1-0.1-0.1
					c0-0.3,0-0.7,0-1c4-2.4,7.9-5.6,12.6-5.3c8.4,0.5,15.4-3,22.7-6.2c9.2-4,10.9-6.7,8.9-18.2c0-0.6,0.5,0.5,1,1.7
					c0.3-0.2,0.8-0.2,1-0.5c0.8-1.7-0.2-2.6-2-1.3c-3.3,1.1-5.5-2-8.5-3.8c-6.3-4.9-12.7-9.1-21.2-5.6c-15.3-1-29.3,3.6-42.8,10.3
					c-2.2-0.8-4.5-1.5-5.7-3.7c0.5-0.4,1-0.7,1.5-1.1c1.1-0.2,2.2-0.2,3.2-0.6c17.6-7.4,35.6-12.9,54.9-12.8c2.1-1.7,3.8,4.1,6,0.3
					c0.4-0.1,0.7,0.1,0.8,0.5C701.1,113.3,704.2,113.7,707.1,114.5z"/>
				<path opacity="0.79" fill="#8C8988" d="M425.1,217.4c0.5-3.2,3.3-4.7,5.4-6.3c1.3-1,2.8-1.1,4-2.9c2.2-3.4,1.9,3.1,4.1,1.4
					c1.3-0.8,1.1-2.1,1.1-3.3c1.2-0.1,2.4,0.1,3.3-0.9c7.9-0.4,15.7-1.1,23.6-0.1c2.6,1.5,4.4,4.5,7.9,4.4c3.3-0.5,6.2-0.6,9.8,0.8
					c3.2,1.3,6.4,3.7,10.2,3.7c0.2,5.5,8.7,4.6,8.1,10.6c0.5,0,1.2,0.2,1.6,0c3.6-2.4,4.2,1.3,5.9,2.8c-0.7,0.1-1.5,0.1-1.7,1.1
					c0,0.2,0.4,0.6,0.6,0.6c1-0.1,0.9-0.9,1.1-1.7c1.2,0.7,4.9-1.4,2.9,2.9c-0.9,1.9,2,3.7,2.4,3.2c3.9-4.5,5.3,1.7,7.9,1.6
					c0,0.3,0,0.7,0.1,1c-8.6,3-15.9,7.6-19,16.8c-3.3,2.5-4.4,5.9-4.1,9.9c-0.6,2.5,0.2,4.8,1.1,7.1c-1.8,1.1-1.1,2.7-0.9,4.2
					c-12.3,1.3-23.8-2.2-35.1-6.5c4.2-2.8,8.2-6.6,13.5-6.6c5,0.1,8.5-2.2,11.9-5.1c1.7-1.4,3.3-3.1,5.4-3.8c3.9-1.2,2-2.9,0.2-4
					c-2.9-1.8-6.3-1.7-9.7-1.9c-8-0.7-15.7-2-22.9-6.9c-4.5-3.1-8.9-6.9-15-8c-4.2-0.7-10.4-3.4-11.5-6.6c-2.9-8.2-5.2-3.9-8.3-0.9
					c-0.5,0.5-1.2,0.7-1.9,1C425.7,222.5,430,218.7,425.1,217.4z"/>
				<path fill="#8C8988" d="M425.1,217.4c4.9,1.3,0.6,5.1,2.1,7.6c0.7-0.3,1.4-0.5,1.9-1c3.1-3,5.4-7.3,8.3,0.9
					c1.1,3.2,7.3,5.9,11.5,6.6c6.1,1.1,10.5,4.9,15,8c7.1,4.9,14.8,6.2,22.9,6.9c3.3,0.3,6.8,0.1,9.7,1.9c1.8,1.1,3.7,2.8-0.2,4
					c-2.2,0.7-3.7,2.3-5.4,3.8c-3.4,2.8-7,5.1-11.9,5.1c-5.3-0.1-9.3,3.7-13.5,6.6c-15.7-4.2-28.3-13.9-38.8-25.5
					c-5.6-6.2-5.8-15.6-3-24.2C423.7,217.7,424.6,217.6,425.1,217.4z M431.7,229.5c1-0.2,1.8-0.7,1.9-1.7c0.1-1.2-1-1.4-1.9-1.3
					c-1,0-1.8,0.5-1.8,1.6C429.9,229.2,430.7,229.6,431.7,229.5z"/>
				<path fill="#A29EA1" d="M445.1,202.7c4.5-5.8,11.2-8.8,16.9-13.1c19.5-14.6,39.2-28.9,59.2-42.8c9.4-6.5,17.2-15.2,27.7-20
					c0.1,0.6,0.1,1.3,0.2,1.9c-1.7,1.2-2.5,2.9-2.2,5c0,0,0-0.1,0-0.1c-0.7,0.4-1.4,0.8-2,1.2c-2.9,4.6-7,7.9-11,11.5
					c-3,2.7-6.1,2.2-8.8,3.9c-12.6,7.8-23.2,18.3-35.7,26.4c-8,5.2-14.9,12.2-22.9,17.7c-3.3,2.2-7.8,3.9-8.8,8.6
					C453.4,202.8,449.3,202.8,445.1,202.7z"/>
				<path fill="#AA1836" d="M732.3,182.8c0.7-3.4-1.9-7.5,2.4-10.1c1.8,3.1,2.2,6.6,2.2,10.1c-0.2,11.1,0.7,22.2-1.2,33.9
					c3.2-4,2.5-9.4,7.3-11.4c1,0.3,1.9,0.7,2.9,1c0.3,0.3,0.7,0.6,1,0.9c0,0.4,0.1,0.8,0.1,1.2c0,0.4-0.1,0.8-0.1,1.1
					c-0.2,3.8-0.4,7.6-0.6,11.4c-0.1,2.8-2.8,5.9,0.6,8.5c-0.2,2.1,0,4.3-2.5,5.7c-1.3-1.2-1.2-3.5-3-4.2c0-3.3,0-6.6,0-9.9
					c-2.2,4.5-3.6,9-2.3,13.8c-4,5.8-10.5,6.9-16.7,8.5c-0.3-1.6,0.5-3.4-1.2-4.6c-1.4-2-0.3-3,1.4-4.3c2.5-1.9,5.8-2.9,7.6-5.8
					c5.9-1.3,6.7-2.8,3.4-7.5c-2.5-3.5-2.5-7-2-11C732.6,201,734.8,191.9,732.3,182.8z"/>
				<path fill="#C92E43" d="M693.2,109c-19.3-0.1-37.3,5.5-54.9,12.8c-1,0.4-2.1,0.4-3.2,0.6c0.1-1.6,0.8-2.8,2.5-3.2
					c1.5-0.4,3.2-0.9,2.7-2.8c-0.4-1.6-1.9-2.7-3.7-2.8c-6.1-0.2-12.1-0.1-17.3,3.7c-2,0-3.3-0.7-3.5-2.8c7-2,12.7-7.6,20.5-7.7
					c1.6,1.8,3.3,0.9,5.1,0.4c5.4-1.6,11-2.7,16.4-3.4C669.8,102.2,681.7,104.7,693.2,109z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M549.1,128.6c-0.1-0.6-0.1-1.3-0.2-1.9c2.8-2.1,5.5-4.4,8.8-5.8c0.5,1,0.9,2.7,2.1,2
					c2-1.2,5.8,1.2,6.5-3.6c0.2-1.3,4.7-2.6,5.3-5.9c3.3-0.8,6.6-0.8,9.9-0.3c2,0.2,4.1,0.4,6.1,0.6c-3.6,3.4-6.9,7.3-11.7,9
					c-3.2,1.1-5,2.9-5.7,6c-0.3,0-0.5,0.2-0.8,0.3c-1.8,0.4-2.8,1.6-3.4,3.3l0,0c-2.2-0.2-3.2,1.1-3.7,3c-0.7,0.4-1.4,0.8-2.1,1.2
					c-2.5,0-3.6,1.1-3.1,3.6l0,0c-1.6-0.2-2.3,0.5-2.4,2c-3.9,2.3-7.8,4.8-11,8.1c-0.1-2.3-0.2-4.5-0.4-6.8c0.2-1.2,0.4-2.3,0.6-3.5
					c0.3-0.4,0.5-0.8,0.8-1.3l-0.1,0.1c4-0.5,2-3.5,2.4-5.5c0,0,0,0.1,0,0.1C549.3,132.7,549.2,130.7,549.1,128.6z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M553.6,305.5c-3,0.4-4.9-3.5-8.2-2.3c-0.4-1.6-1.7-1.4-2.9-1.6c-0.4-0.2-0.7-0.5-1.1-0.7
					c-0.5-1.2-1.5-0.7-2.3-0.6c-0.7-0.3-1.3-0.5-2-0.8c-2.6-1.1-5.3-2.1-7.9-3.2c-3.8-1.8-7.6-3.5-11.4-5.3c0-0.7-0.2-1.2-1-0.8
					c-0.4-0.1-0.8-0.2-1.2-0.3c-2.5-1.5-4.9-2.9-7.4-4.4c3-2.8-2.1-6,0.5-8.8c0.4-0.1,0.8-0.1,1.3-0.2c1.1,0.8,2.2,3.4,3.3,0.1
					c1.1,0.8,2.3,1.5,3.4,2.3c-0.4,0.3-1.6-0.5-1.2,0.8c0.4-0.3,0.8-0.6,1.2-0.9c0.7,0,1.5,0,2.2,0c0.1,2.3,1.6,4,3.5,4.6
					c1.2,0.4,2.3-0.1,3.9,0.7c8.8,4,16.8,12,27.8,6.1c0.7-0.4,1.8-0.1,2.7-0.2c1.1,0.8,2.2,1.5,3.4,2.3c-1.7,0-3.3-0.2-5,0.1
					c-2.3,0.4-5.2,5.5-3.8,6.3C554.9,300.4,553.1,303.2,553.6,305.5z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M756.9,231.9c0,4.8,0.2,9.6-0.1,14.3c-0.5,7.7-5.7,11.5-12.4,13.8c-2.6-0.9-0.8-2.5-0.7-4
					c0.2-3.9,5.9-6.1,3.3-10.8c0.8-4.5,1.5-8.9,2.3-13.4C751.8,236.2,754.3,230.8,756.9,231.9z"/>
				<path fill="#D36760" d="M743,205.3c-4.7,2.1-4.1,7.5-7.3,11.4c1.9-11.7,1-22.8,1.2-33.9c0.1-3.5-0.4-7-2.2-10.1
					c-0.1-1.5-0.1-2.9-0.2-4.4c0.3-1,0.7-2,1.8-2.3c1.9,5.1,2.9,10.3,3,15.7C739.6,189.7,742.3,197.3,743,205.3z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M615.7,114.5c0.2,2.1,1.5,2.9,3.5,2.8c4.8,2.1,9.6,4.1,14.4,6.2c1.3,2.2,3.5,2.9,5.7,3.7
					c1,1,1.5,2.3,3,3.1c4.9,2.6,9.1,6.1,11.8,11.1c0,1.5,0.1,3,0.1,4.5c-5.4-5.6-10.6-11.5-17.4-15.5c-1.4-0.8-3.1-1.7-3.9-3.1
					c-1.6-2.8-4-2.9-6.6-2.7c-2.1-0.8-2.2-4.6-5.5-3.5c-0.3-1.1-1.2-1.1-2.1-1.3c-1.5-2.2-3.5-2.6-6-1.8c0.4-1.1-0.7-1.5-1-2.3
					C613,115.4,614.4,114.9,615.7,114.5z"/>
				<path fill="#AA1836" d="M693.2,109c-11.5-4.3-23.4-6.8-35.5-5.2c-5.4,0.7-11,1.8-16.4,3.4c-1.8,0.5-3.5,1.4-5.1-0.4
					c0.4,0,0.7-0.1,1.1-0.1c0.4-0.2,0.8-0.4,1.3-0.6c20.7-7.8,40.8-5.9,60.6,3.2C697,113.1,695.3,107.3,693.2,109z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M743.6,261.1c-1.6,0.6-3.2,1.1-3.5,3.1c-2.2,0.7-4.4,1.5-6.6,2.2c0.1-3.1-2.6-7.2,1.6-8.8
					c1.5-0.6,2.6-1,2.5-2.1c-0.6-6.9,3.8-9,9.3-10.2c2.6,4.7-3,6.9-3.3,10.8c-0.1,1.5-1.9,3,0.7,4
					C744.1,260.4,743.8,260.8,743.6,261.1z"/>
				<path fill="#A29EA1" d="M508.8,276.7c-2.6,2.8,2.5,6.1-0.5,8.8c-4.8-2.3-6.5-6.7-7.8-11.4c-0.2-1.5-0.9-3.2,0.9-4.2
					C503.8,272.2,506.3,274.4,508.8,276.7z"/>
				<path fill="#AA1836" d="M736.3,166c-1.2,0.3-1.6,1.3-1.8,2.3c-1.5-0.1-3,0-3.2-2.1c-0.5-5.3-3.1-10.4-1.1-15.9
					c0.7,0.7,1.5,1.5,2.2,2.2c0.2,0.3,0.3,0.5,0.3,0.9c-0.4,1-0.1,1.9,0.8,2.5C734.4,159.3,735.4,162.6,736.3,166z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M571.5,113.5c-0.6,3.3-5.1,4.6-5.3,5.9c-0.7,4.8-4.4,2.4-6.5,3.6c-1.2,0.7-1.6-1.1-2.1-2
					c0,0,0.1,0,0.1,0c3.6,1,4.1-3.1,6.7-4c1.9-0.7-0.7-1.4-0.7-2.3c1.6-1.6,3.5-2.7,5.6-3.3C570,112.1,570.8,112.8,571.5,113.5z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M587.5,113.9c-2-0.2-4.1-0.4-6.1-0.6c0.2-2.4-2.8-1.6-3.3-3.3c5.7-0.8,11.3,0,16.8,1.5
					c-0.7,1.1-2.4,1.4-2.2,3.1c-0.7,0.1-1.5,0.2-2.2,0.3C589.5,114.5,588.5,114.2,587.5,113.9z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M592.7,114.6c-0.2-1.7,1.5-2.1,2.2-3.1c3.2,0.9,6.5,1.9,9.7,2.8c0.9,0.8,1.8,1.6,2.7,2.4
					c0,0.5,0,0.9-0.1,1.4C602.4,116.9,597.6,115.7,592.7,114.6z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M445.1,202.7c4.2,0,8.3,0.1,12.5,0.1c2.2,0.3,4.4,0.5,6.6,0.8c0.9,0.4,2.1,0.2,2.3,1.6
					c-7.9-1-15.7-0.3-23.6,0.1C443.1,203.9,444.1,203.3,445.1,202.7z"/>
				<path fill="#D36760" d="M732.4,152.6c-0.7-0.7-1.5-1.5-2.2-2.2c0,0,0,0,0,0c0.1-0.9-0.2-1.7-1-2.2c-2.2-3.9-4.9-7.6-4.8-12.4
					c0.5-0.3,1-0.7,1.5-1C728.5,140.5,730.9,146.4,732.4,152.6z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M756.9,231.9c-2.6-1.1-5.1,4.3-7.7,0c-1.3-0.7-1.1-2-1.2-3.2c0.8-0.2,1.9,0,2.2-1.2
					C753.8,227,755.7,228.8,756.9,231.9z"/>
				<path fill="#AA1836" d="M725.9,134.7c-0.5,0.3-1,0.7-1.5,1c-3.3-3.3-5.2-7.1-5.5-11.8C722.1,126.9,724.1,130.7,725.9,134.7z"/>
				<path fill={color4} style={{transition: "1s"}} d="M578.1,109.9c0.5,1.7,3.5,0.9,3.3,3.3c-3.3-0.5-6.6-0.5-9.9,0.3c-0.7-0.7-1.5-1.5-2.2-2.2
					C572.1,109.9,575,109.6,578.1,109.9z"/>
				<path fill="#A29EA1" d="M750.2,227.5c-0.3,1.2-1.4,1-2.2,1.2c-0.4,0.2-0.7,0.4-1.1,0.7c-3.4-2.6-0.7-5.6-0.6-8.5
					C747.6,223.1,748.9,225.3,750.2,227.5z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M563.7,114.7c0,0.9,2.6,1.6,0.7,2.3c-2.6,0.9-3.1,5-6.7,4C558.8,118,560.2,115.4,563.7,114.7z"/>
				<path fill="#A29EA1" d="M607.3,116.6c-0.9-0.8-1.8-1.6-2.7-2.4c2.7-0.9,4.9,0.5,7.1,1.6c0.3,0.8,1.4,1.2,1,2.3c0,0,0,0,0,0
					C610.9,117.6,609.1,117.1,607.3,116.6z"/>
				<path fill="#AA1836" d="M707.1,114.5c-2.9-0.8-6-1.1-7.1-4.6C703,110.3,705.1,112.3,707.1,114.5z"/>
				<path fill="#D8D8D9" d="M439.7,206.3c0,1.2,0.2,2.5-1.1,3.3C439,208.5,436.5,206.5,439.7,206.3z"/>
				<path fill="#AA1836" d="M718.6,123.7c-1.5-0.3-2.8-0.9-2.6-2.8C717.5,121.3,718,122.5,718.6,123.7z"/>
				<path fill="#AA1836" d="M714.9,119.7c-1.6,0.2-2.3-0.6-2.4-2.1C713.8,117.8,714.5,118.5,714.9,119.7z"/>
				<path fill="#D36760" d="M733.4,155.9c-0.9-0.6-1.2-1.5-0.8-2.5C733.6,154.1,733.7,154.9,733.4,155.9z"/>
				<path fill="#AA1836" d="M712.5,117.7c-0.5-0.2-1-0.5-1.4-0.7C711.7,116.8,712.3,117,712.5,117.7z"/>
				<path fill="#AA1836" d="M716,120.9c-0.8,0.1-1.2-0.3-1-1.1C715.3,120.2,715.7,120.5,716,120.9z"/>
				<path fill="#D36760" d="M746.9,207.2c-0.3-0.3-0.7-0.6-1-0.9C746.6,206.2,746.9,206.5,746.9,207.2z"/>
				<path fill="#D36760" d="M746.9,209.5c0-0.4,0.1-0.8,0.1-1.1c0.1,0.3,0.2,0.5,0.2,0.8C747.2,209.3,747,209.4,746.9,209.5z"/>
				<path fill="#C92E43" d="M638.6,106.1c-0.4,0.2-0.8,0.4-1.3,0.6C637.7,106.5,638.1,106.3,638.6,106.1z"/>
				<path fill="#A7062E" d="M732.3,182.8c2.5,9.2,0.3,18.2-0.8,27.3c-0.5,4-0.5,7.5,2,11c3.3,4.7,2.5,6.2-3.4,7.5
					c-1.8-0.8-2.9-2.6-5.5-1.8c-7.5,2.3-7.8,1.9-7.9-6.1c0-3.6-0.6-7.4-4.2-8.3c-3.9-1-8.2,0.2-11.2,3.2c-2.7,2.7-3.7,6.3-1.7,9.6
					c1.5,2.5,1.5,4.9,1.3,7.5c-0.6,7.8-0.3,8.1,7.7,6.1c1.6-0.4,3.2-0.9,4.8-1.2c1.7-0.3,3,0.1,3.2,2.1c-5.2,1.5-10.4,3-15.6,4.5
					c-1.3,0.2-3.7-0.4-2.8,2c1,2.5-0.8,5.6,1.7,7.7c0.2,0.2,0.7,0.2,1,0.1c4.4-1.7,9.6-0.7,13.3-4.2c2.2-0.4,4.5-0.7,6.7-1.1
					c0.3,3.2-0.4,5-4.1,5.9c-4.9,1.2-9,2.8-14.7,1.5c-5-1.2-6.2-2.3-5.6-6.7c0.3-1.9-0.5-3.6-1-5.4c-1.7-5.7-3.2-11.4-4.8-17.1
					c-6.2-22.4-9.3-45.8-21.2-66.4c0.9,0.2,2.1,0.1,2.6,0.6c6.4,7.2,13.7,14,13.4,24.9c0,0.9,0.9,1,1.5,1.3c3,1.9,4.5,4.5,4.8,8.1
					c0.6,6.4,3.1,7.4,8.7,4.2C711.2,193.8,721.4,187.5,732.3,182.8z"/>
				<path fill={color4} style={{transition: "1s"}} d="M669.9,160.4c11.9,20.6,14.9,44,21.2,66.4c1.6,5.7,3.2,11.4,4.8,17.1c0.5,1.8,1.3,3.5,1,5.4
					c-0.6,4.4,0.6,5.5,5.6,6.7c5.7,1.3,9.8-0.3,14.7-1.5c3.7-0.9,4.5-2.7,4.1-5.9c0.1-1,0.1-2,0.2-2.9c0.3-0.5,0.5-1,0.8-1.5
					c0.1-0.3,0.2-0.6,0.2-1c6.2-1.6,12.7-2.6,16.7-8.5c1.1-1.1,2.3-2.1,2.3-3.9c1.9,0.7,1.7,2.9,3,4.2c2.5-1.4,2.2-3.7,2.5-5.7
					c0.4-0.2,0.7-0.4,1.1-0.7c0.1,1.2-0.1,2.5,1.2,3.2c-0.8,4.5-1.5,8.9-2.3,13.4c-5.5,1.2-9.9,3.3-9.3,10.2c0.1,1.1-1,1.6-2.5,2.1
					c-4.3,1.5-1.5,5.7-1.6,8.8c-2.8,1.8-6.4,2.2-8.9,4.6c-7.2,1.7-14.8,2.7-18.9,10.1c-3.8,0.3-7.2-0.6-10.4-2.8
					c-2.7-1.8-5.4-2.9-6.6,1.7c-0.3,0.9-1.4,1.6-2.1,2.4c-1.1,0-2.2,0.1-3.3,0.1c0-5.3-0.1-10.6-0.1-15.8c0.9-0.5,2.5-0.8,1.2-2.2
					c-1.5-1.7-0.2-3.1-0.1-4.7c5.3-1.5,4.3,3.1,5.5,5.9c0.7-4.5,3.7-8.1,3.3-12.8c-2.1,0-3.4,1.7-5.4,1.3c-2.7-0.5-4.8-1.9-4.3-4.7
					c0.5-2.7,2.3-3.5,4.8-1.4c1.3,1.1,3.1,0.2,2.4-1.4c-1.8-4.4,0.5-9.4-1.9-13.6c0-0.4,0-0.8,0-1.2c-0.2-8.6-1.8-16.8-5.6-24.6l0,0
					c-0.1-1.9-0.1-3.8-1.2-5.5c-4.5-14.7-9.9-28.9-19-41.4c-1.3-3-4.8-5.1-4.1-9c1-0.4,2-0.7,3.1-1.1c1.6,1.8,3.3,3.7,4.9,5.5
					c0.3,0.4,0.5,0.7,0.8,1.1c0,0,0,0.1,0,0.1c0,1.4,0.8,2.2,2,2.6C669.6,160,669.7,160.2,669.9,160.4z M696.6,262.1
					c-1.8,0-3.4,0.2-3.6,2.4c0,0.2,0.4,0.7,0.6,0.7C695.7,265.3,696.4,263.9,696.6,262.1c0.1-0.1,0.3-0.1,0.4-0.2
					c0.1-0.1,0.1-0.2,0.1-0.3C696.9,261.8,696.8,261.9,696.6,262.1z M701.2,259c-0.2,0-0.3,0.1-0.5,0.2c0,0,0.1,0.3,0.1,0.4
					c0.2,0,0.3-0.1,0.5-0.2C701.4,259.4,701.3,259.2,701.2,259z"/>
				<path fill="#C92E43" d="M654,141.4c-2.7-5-6.8-8.5-11.8-11.1c-1.4-0.8-2-2.1-3-3.1c13.5-6.8,27.5-11.3,42.8-10.3
					c-0.2,1.4-0.9,3-0.6,4.3c1.6,5.6-1.5,8.4-5.6,11.3c-5,3.5-9.5,7.6-15.5,9.4c-1.4,0.4-1.8,1.8-1.9,3.2
					C657.1,143.7,655.6,142.6,654,141.4z"/>
				<path fill="#C8143E" d="M658.6,144.9c0.1-1.4,0.5-2.8,1.9-3.2c6-1.8,10.5-5.9,15.5-9.4c4.1-2.9,7.2-5.7,5.6-11.3
					c-0.4-1.3,0.4-2.8,0.6-4.3c8.5-3.5,14.9,0.7,21.2,5.6c-1,2.5,3.7,6.2-0.8,7.6c-6.7,2-12,6.1-17.4,10.1c-2.7,2-5.5,3.7-9.1,3.6
					c-1.7-0.1-3.1,0.3-4.5,1.6c-3.2,2.9-6.7,4.6-10.9,1.9C659.9,146.4,659.2,145.7,658.6,144.9z"/>
				<path fill="#BD0334" d="M660.5,147.2c4.2,2.7,7.8,1,10.9-1.9c1.4-1.3,2.8-1.7,4.5-1.6c3.6,0.2,6.4-1.6,9.1-3.6
					c5.5-3.9,10.7-8.1,17.4-10.1c4.5-1.4-0.1-5.1,0.8-7.6c3,1.8,5.2,4.9,8.9,5.6c1.6,9.7-0.2,12.4-9.4,16.4
					c-7.3,3.2-14.3,6.6-22.7,6.2c-4.7-0.3-8.7,2.9-12.6,5.3c-0.3,0-0.5-0.1-0.8-0.1c-1.6-1.8-3.3-3.7-4.9-5.5
					C661.4,149.3,661,148.3,660.5,147.2z"/>
				<path fill="#D36760" d="M633.6,123.5c-4.8-2.1-9.6-4.1-14.4-6.2c5.2-3.8,11.2-3.9,17.3-3.7c1.8,0.1,3.3,1.2,3.7,2.8
					c0.5,1.9-1.2,2.4-2.7,2.8c-1.6,0.4-2.4,1.6-2.5,3.2C634.6,122.8,634.1,123.1,633.6,123.5z"/>
				<path fill="#BD0334" d="M711.3,124.4c2.2,0.4,3.2,1.4,2.4,3.1c-0.1,0.2-0.6,0.3-1,0.5C712.3,126.8,711.8,125.6,711.3,124.4z"/>
				<path fill="#AA1836" d="M729.2,148.1c0.8,0.5,1,1.3,1,2.2c-1-0.1-2.4,0.4-2.8-0.9C727,148.2,728.4,148.3,729.2,148.1z"/>
				<path fill="#A7062E" d="M669.6,159.7c-1.2-0.5-2-1.3-2-2.6C669.3,157.2,669.8,158.2,669.6,159.7z"/>
				<path fill="#A7062E" d="M666.8,155.9c0.3,0,0.5,0,0.8,0.1c0,0.3,0,0.7,0,1C667.3,156.6,667.1,156.3,666.8,155.9z"/>
				<path fill="#FEFEFE" d="M494.4,214c-3.8,0-7-2.4-10.2-3.7c-3.6-1.4-6.4-1.3-9.8-0.8c0.2-1,1.6-2.3,0.2-2.9
					c-3.1-1.5-2.3-2.3-0.1-4c13.6-10.1,27.4-19.9,40.5-30.6c4.4-3.6,10.8-5.3,13-11.6c0.3,0,0.7,0,1-0.1c5.8-1.1,8.7-7,14.2-8.5
					c0.1,1,0.2,2,0.3,3c0.7,3.7,1.4,7.5,2.1,11.2c0.1,1.5,0.2,3.1,0.3,4.6c0,4,1.4,8,0.7,12.1c-2.9-4-5.8-2.5-8.8,0
					c-4.6,4-9.1,8.2-14.5,11.1c-2.7,1.4-4.6,3.7-6.9,5.4C509.5,204.5,503.1,211.1,494.4,214z"/>
				<path fill="#F0F2F3" d="M494.4,214c8.7-2.9,15.1-9.5,22.2-14.8c2.3-1.7,4.2-4,6.9-5.4c5.4-2.9,9.9-7.1,14.5-11.1
					c2.9-2.5,5.8-4.1,8.8,0c0.6,4.1-0.2,8.5,2.2,12.3c-0.2,0.7-0.3,1.3-0.5,2c-1.3,0.9-1.5,2.1-1.1,3.5c-1.7,5.6-3.3,11.2-5,16.8
					c-2,0.8-3.4,3.2-2.9,4.3c3.4,7.1-3.4,7.8-6.6,9.6c-2.9,1.6-6.1,3.2-9.5,4c-2.7,0-4.1-6.2-7.9-1.6c-0.4,0.4-3.3-1.4-2.4-3.2
					c2-4.3-1.6-2.2-2.9-2.9l0,0c-1.7-1.5-2.3-5.2-5.9-2.8c-0.4,0.2-1,0-1.6,0C503.2,218.6,494.6,219.5,494.4,214z"/>
				<path fill="#D8D8D9" d="M528,160.5c-2.2,6.2-8.6,8-13,11.6c-13.1,10.7-26.9,20.5-40.5,30.6c-2.2,1.6-3.1,2.5,0.1,4
					c1.4,0.7,0,1.9-0.2,2.9c-3.5,0.1-5.3-2.9-7.9-4.4c-0.2-1.4-1.4-1.2-2.3-1.6c1.5-2.5,3.3-4.9,5.7-6.7c6.7-5.2,15-7.7,21.4-13.4
					c1.9-1.7,3.4-4,5.7-5c9.9-4.4,19.6-9.2,27.3-17.1C525.4,160.3,526.7,160.4,528,160.5z"/>
				<path fill={color4} style={{transition: "1s"}} d="M508.8,276.7c-2.5-2.3-4.9-4.5-7.4-6.8c-0.9-2.3-1.7-4.6-1.1-7.1c1.5,0.7,3,1.3,4.5,2
					c2.6-5.8,6.9-9.4,13.2-10.4c-3.3,10.6-2.9,11.9,5.9,17.4c2.6,1.6,5.6,2.6,8.4,3.9c-2.7,0.4-5.7,4.1-7.8-1
					c-0.3-0.8-1.7-0.2-2.4,0.5c-1.1,1.2-2.2,2.4-3.2,3.6c-0.7,0-1.5,0-2.2,0l0,0c-1.1-0.8-2.3-1.5-3.4-2.3c-1.1,0.4-2.2-3.4-3.3-0.1
					C509.6,276.6,509.2,276.7,508.8,276.7z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M504.4,253c3.2-9.2,10.4-13.8,19-16.8c1.3,3.2,5.3,3.7,6.8,6.8c-1.5,0.8-2.9,1.7-3.4,3.5l0,0
					c-2-0.2-3.5,0.2-3.3,2.7c-0.6,0.3-1.3,0.5-1.9,0.8c-2-3-4.1-3.2-7.1-1.1C511.4,250.8,508.6,253.7,504.4,253z"/>
				<path fill="#A29EA1" d="M530.2,243c-1.5-3.1-5.5-3.6-6.8-6.8c-0.1-0.3-0.2-0.6-0.1-1c3.4-0.8,6.6-2.4,9.5-4
					c3.2-1.7,10-2.5,6.6-9.6c-0.5-1.1,1-3.5,2.9-4.3c0.2,0.1,0.4,0.2,0.4,0.3c1.1,3.7-0.3,7-1.4,10.4c-0.9,3.1,2.5,7.7-4,9.1
					c-1.2,0.2-3.4,4.6-6.2,6C530.9,243.2,530.6,243.1,530.2,243z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M504.4,253c4.2,0.8,7-2.1,10-4.1c3-2.1,5.2-1.9,7.1,1.1c-0.4,0.7-0.8,1.4-1.2,2.1
					c-1.6-0.1-2.5,0.6-2.4,2.3l0.1,0.1c-6.3,1.1-10.6,4.7-13.2,10.4c-1.6-0.7-3.1-1.4-4.5-2C500,258.9,501.1,255.5,504.4,253z"/>
				<path fill="#F0F2F3" d="M510.1,227.5c-0.1,0.7-0.1,1.5-1.1,1.7c-0.2,0-0.6-0.4-0.6-0.6C508.5,227.6,509.3,227.6,510.1,227.5
					C510,227.4,510.1,227.5,510.1,227.5z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M646.3,292.2c-0.6,0-1.2,0.1-1.8,0.1c-0.5,0.3-1,0.6-1.5,0.9c-0.8,0-1.5,0-2.3,0.1
					c-3.7-1.8-6.8-1.5-8.9,2.5c-9.1,3.1-18.5,4.7-27.9,6.5c-0.5-3.1-0.6-5.5-4.6-7.6c-4.5-2.3-9.6-0.2-13.3-3.6c0,0,0,0,0,0
					c0.9,0,1.7-0.1,2.3-0.9c9.9-0.7,19.6-2.7,29-5.9c0.7-0.1,1.5-0.2,2.2-0.3c7.6-0.8,14.9-2.2,21.7-6c0.6-0.1,1.2-0.1,1.9-0.2
					c2-0.3,4-0.4,6-0.9c10.3-2.7,20.6-5.4,30.9-8.1c0.7,1.5,1.2,2.8,0.5,4.6c-1.5,3.5-2.6,7.2-3.8,10.9c-1.5,0.5-2.9,1-4.4,1.5
					c-7.8,1.9-15.5,3.8-23.3,5.7C647.2,289.5,646.6,290.4,646.3,292.2z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M553.6,305.5c-0.5-2.3,1.3-5.1-2-6.8c-1.4-0.8,1.4-5.9,3.8-6.3c1.6-0.3,3.3-0.1,5-0.1
					c4.1,0,8.1,0,12.2-0.1c2.6-0.7,5.3,1.7,7.9-0.2c0.4,0,0.8,0,1.1,0c5.1,3.2,7.4,8.6,10,13.6C578.9,307.8,566.3,308.8,553.6,305.5
					z M576.8,300c-1.4,0.3-3.7-0.7-3.9,1.7c-0.1,2.3,1.6,3.6,3.9,3.8c2.3,0.2,4.2-1,4.3-3.2C581.1,299.8,578.6,300.2,576.8,300z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M591.6,305.6c-2.7-5-4.9-10.4-10-13.6c1.5-0.3,2.9-0.6,4.4-0.9c3.7,3.5,8.8,1.3,13.3,3.6
					c4,2.1,4.1,4.5,4.6,7.6C599.7,303,596,305.6,591.6,305.6z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M676.5,284.2c1.3-3.6,2.3-7.3,3.8-10.9c0.8-1.8,0.2-3.1-0.5-4.6c0.7-1.4,1.6-2.4,3.3-1.9
					c0,5.3,0.1,10.6,0.1,15.8c-1.6,0.6-3.2,1.1-4.7,1.7c-0.2,0-0.4,0.1-0.6,0.2C677.4,284.4,676.9,284.3,676.5,284.2z"/>
				<path fill={color4} style={{transition: "1s"}} d="M631.8,295.7c2.1-4,5.2-4.2,8.9-2.5C637.7,294,635.2,296.5,631.8,295.7z"/>
				<path fill="#D8D8D9" d="M676.5,284.2c0.5,0.1,1,0.2,1.4,0.3c-1.6,2.2-3.2,4.3-5.9,1.2C673.5,285.2,675,284.7,676.5,284.2z"/>
				<path fill="#D8D8D9" d="M539.1,300.4c0.8-0.1,1.8-0.7,2.3,0.6C540.2,302.8,539.5,302.1,539.1,300.4z"/>
				<path fill={color4} style={{transition: "1s"}} d="M646.3,292.2c0.3-1.8,0.8-2.8,2.4-0.8C648.1,292.2,647.2,292.3,646.3,292.2z"/>
				<path fill="#D8D8D9" d="M542.5,301.6c1.2,0.2,2.5,0,2.9,1.6C544.1,303.4,542.9,303.2,542.5,301.6z"/>
				<path fill={color4} style={{transition: "1s"}} d="M643,293.2c0.5-0.3,1-0.6,1.5-0.9C644,292.7,643.5,292.9,643,293.2z"/>
				<path fill="#EBEDF0" d="M431.7,229.5c-1.1,0.1-1.8-0.3-1.9-1.4c0-1.1,0.8-1.6,1.8-1.6c1,0,2,0.1,1.9,1.3
					C433.5,228.7,432.7,229.3,431.7,229.5z"/>
				<path fill="#CECECF" d="M528,160.5c-1.3-0.1-2.7-0.2-3.7,0.8c-7.7,7.9-17.4,12.7-27.3,17.1c-2.3,1-3.9,3.3-5.7,5
					c-6.4,5.8-14.7,8.3-21.4,13.4c-2.4,1.8-4.1,4.2-5.7,6.7c-2.2-0.3-4.4-0.5-6.6-0.8c1-4.8,5.5-6.4,8.8-8.6
					c8.1-5.4,14.9-12.4,22.9-17.7c12.4-8.1,23.1-18.6,35.7-26.4c2.7-1.7,5.8-1.2,8.8-3.9c4-3.6,8.1-6.9,11-11.5
					c-0.1,1.4-0.2,2.9-0.3,4.3c0,0,0.1-0.1,0.1-0.1c-0.6,0.2-1,0.5-0.8,1.2c-0.2,1.2-0.4,2.3-0.6,3.5c-1.7,6.5-8.2,8.5-12.1,13
					c-1,1.1-2.1,2.1-2,3.7C528.7,160.5,528.4,160.5,528,160.5z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M544.4,139.1c0.1-1.4,0.2-2.9,0.3-4.3c0.7-0.4,1.4-0.8,2-1.2C546.4,135.6,548.5,138.6,544.4,139.1z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M549.1,128.6c0.2,2.1,0.2,4.1-2.2,5C546.6,131.5,547.4,129.9,549.1,128.6z"/>
				<path fill="#B0022F" d="M716.7,239.7c-0.2-2-1.5-2.4-3.2-2.1c-1.6,0.3-3.2,0.8-4.8,1.2c-8.1,2-8.3,1.7-7.7-6.1
					c0.2-2.5,0.2-5-1.3-7.5c-2-3.4-1.1-7,1.7-9.6c3.1-3,7.3-4.2,11.2-3.2c3.5,0.9,4.1,4.7,4.2,8.3c0.1,8,0.5,8.4,7.9,6.1
					c2.6-0.8,3.7,1.1,5.5,1.8c-1.9,2.8-5.1,3.9-7.6,5.8c-1.7,1.2-2.7,2.3-1.4,4.3C719.7,239,718.2,239.4,716.7,239.7z"/>
				<path fill="#BD0334" d="M716.7,239.7c1.5-0.4,3-0.7,4.4-1.1c1.7,1.2,0.9,3.1,1.2,4.6c0,0.3-0.1,0.7-0.2,1
					c-0.3,0.5-0.5,1-0.8,1.5c-0.1,1-0.1,2-0.2,2.9c-2.2,0.4-4.5,0.7-6.7,1.1c-1.6-3.1-3.3-3.3-6.5-1.5c-4.6,2.6-6.4,1.3-6.9-4
					C706.3,242.8,711.5,241.3,716.7,239.7z"/>
				<path fill="#D36760" d="M741.4,230.9c0,1.7-1.2,2.8-2.3,3.9c-1.3-4.8,0.1-9.3,2.3-13.8C741.4,224.3,741.4,227.6,741.4,230.9z"/>
				<path fill="#CECECF" d="M543.6,154.9c-0.1-1-0.2-2-0.3-3c0.1-0.5,0.2-0.9,0.3-1.4c3.2-3.3,7.1-5.8,11-8.1c1.6,0.3,2.4-0.4,2.4-2
					c0,0,0,0,0,0c2.7,0.3,3.8-0.9,3.1-3.6c0.7-0.4,1.4-0.8,2.1-1.2c2.5,0.5,3.7-0.5,3.7-3c0,0,0,0,0,0c2.6,0.5,3.7-0.7,3.4-3.3
					c0.2-0.2,0.5-0.3,0.8-0.3c8.3-2.6,14.3-8.5,20.5-14.1c0.7-0.1,1.5-0.2,2.2-0.3c4.8,1.2,9.7,2.3,14.5,3.5
					c4.3,2.2,8.7,4.3,13.4,5.5c0.4,2.1,2.2,3.1,3.4,4.6c-1.8,0.9-2.9,2.1-1.8,4.2c-1.3,0.3-1.5,1.3-1.7,2.4
					c-0.6-0.1-1.3-0.2-1.9-0.3c-0.5-0.3-1-0.6-1.6-0.9c-1.4-0.4-2.8-0.8-4.2-1.1c-0.5-1.8-2.5-1.9-3.5-3.1c0.2-3.7,4.1-2.9,7.3-5.5
					c-4.6,0.3-6.8-2.4-9.7-3.1c-4.6-1.2-9.2-4.9-14.2-0.8c-0.8,0.6-2.2,0.6-2.1,2.1c0.1,1.2,1.4,1.1,2,1.6c2.5,2.4,7.2,0.3,8.9,4.3
					c-4.8,4.3-9.8,8.4-13.5,13.2c2.1-0.5,4.9,0.6,7.7,1.1c0.4,0.4,0.8,0.8,1.1,1.2c0.8,1.2-0.2,1-0.9,1.2c-3.7,0.7-5.8,3.6-8.7,5.4
					c-6.1,3.9-10.6,11.2-19.4,10.4c-2.9-0.3-4.9,2.2-7,4.1c-1.8,1.7-1.5,4.7-4,5.8c-0.6-1.7,1.5-3.4,0.1-5.4
					c-3.7,1.9-6.5,5.6-11,5.6c-0.1-1.5-0.2-3.1-0.3-4.6c4.6-0.8,7.8-4.3,10.6-7.3c3.7-3.9,10.3-4.3,11.8-10.5
					c0.9-0.5,1.2-1.3,1.1-2.3l0,0c8.6-5,13.8-13.7,21.7-19.5c-5.4,0.5-9.9,2.6-13.9,5.9c-0.2-0.7-0.3-1.6-0.7-2
					c-1.3-1.2-1.2,0.6-1.7,0.9c-5.4,2.6-7.3,8.7-12.8,11.8C555.6,146.6,549,149.9,543.6,154.9z"/>
				<path fill="#D8D8D9" d="M543.5,150.5c-0.1,0.5-0.2,0.9-0.3,1.4c-5.5,1.5-8.4,7.5-14.2,8.5c0-1.6,1.1-2.6,2-3.7
					c3.9-4.5,10.4-6.5,12.1-13C543.3,146,543.4,148.3,543.5,150.5z"/>
				<path fill="#A29EA1" d="M590.5,114.8c-6.2,5.6-12.2,11.4-20.5,14.1c0.7-3.2,2.5-4.9,5.7-6.1c4.8-1.7,8.1-5.6,11.7-9
					C588.5,114.2,589.5,114.5,590.5,114.8z"/>
				<path fill="#A29EA1" d="M560.1,136.7c0.7,2.7-0.4,3.9-3.1,3.6C556.5,137.8,557.6,136.7,560.1,136.7z"/>
				<path fill="#A29EA1" d="M565.9,132.5c0,2.5-1.2,3.6-3.7,3C562.6,133.6,563.7,132.3,565.9,132.5z"/>
				<path fill="#A29EA1" d="M569.3,129.2c0.3,2.5-0.8,3.7-3.4,3.3C566.5,130.8,567.5,129.6,569.3,129.2z"/>
				<path fill="#A29EA1" d="M557,140.4c0,1.6-0.8,2.4-2.4,2C554.7,140.9,555.4,140.2,557,140.4z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M543.8,140.3c-0.2-0.7,0.2-1.1,0.8-1.2C544.3,139.4,544.1,139.8,543.8,140.3z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M519,278.8c1.1-1.2,2.1-2.4,3.2-3.6c0.7-0.7,2-1.3,2.4-0.5c2.1,5,5.1,1.4,7.8,1c1,0,1.9,0,2.9,0
					c1.4,3.1,4.8,2,7.1,3.2c5.2,2.1,10.5,4.2,15.7,6.3c-0.4,1.6-0.7,3.2-1.1,4.8c-0.9,0-2-0.2-2.7,0.2c-11,5.9-19.1-2.1-27.8-6.1
					c-1.6-0.7-2.8-0.3-3.9-0.7C520.6,282.8,519.1,281.2,519,278.8z M541.7,288.4c-0.3-0.2-0.6-0.5-0.8-0.5c-0.3,0-0.6,0.2-0.9,0.4
					c0.3,0.2,0.5,0.5,0.8,0.5C541,288.8,541.3,288.5,541.7,288.4z"/>
				<path fill={color4} style={{transition: "1s"}} d="M557,290c0.4-1.6,0.7-3.2,1.1-4.8c1.8,0.2,3.6,0.4,5.4,0.6c4.4,1.5,8.9,1.9,13.5,2l0,0
					c-0.2,1.1,0,1.9,1.2,2.1c-0.1,0.4-0.1,0.8-0.2,1.2c-1.9,0-3.9-0.6-5.5,1.1c-4.1,0-8.1,0-12.2,0.1
					C559.2,291.5,558.1,290.7,557,290z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M510,276.5c1.2-3.4,2.2,0.4,3.3,0.1C512.2,280,511.1,277.3,510,276.5z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M516.8,278.8c-0.4,0.3-0.8,0.6-1.2,0.9C515.2,278.3,516.4,279.1,516.8,278.8
					C516.8,278.8,516.8,278.8,516.8,278.8z"/>
				<path fill="#FEFEFE" d="M517.9,291.1c-0.3-0.3-0.7-0.6-1-0.8C517.7,290,517.8,290.5,517.9,291.1z"/>
				<path fill={color4} style={{transition: "1s"}} d="M626.2,124.7c2.6-0.1,5-0.1,6.6,2.7c0.8,1.3,2.4,2.2,3.9,3.1c6.9,4,12,9.8,17.4,15.5c0,0,0,0,0,0
					c0.8,0.7,1.5,1.5,2.3,2.2c0.4,1.1,0.7,2.2,1.1,3.2c-2.8,0.7-2.8-2.9-5-3.3c-7.8-7.3-14.7-15.8-24.7-20.4
					C626.7,126.9,625.7,126.2,626.2,124.7z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M657.4,151.4c-0.4-1.1-0.7-2.2-1.1-3.2c0-1.5-0.8-2.2-2.3-2.2c0,0,0,0,0,0c0-1.5-0.1-3-0.1-4.5
					c1.5,1.2,3,2.3,4.5,3.5c0.7,0.8,1.3,1.5,2,2.3c0.4,1.1,0.9,2.2,1.3,3.2c-1,0.4-2,0.7-3.1,1.1
					C658.4,151.5,657.9,151.4,657.4,151.4z"/>
				<path fill="#A29EA1" d="M626.2,124.7c-0.5,1.5,0.5,2.2,1.4,3c-0.1,0.2-0.3,0.3-0.4,0.5c-1.1,0-2.1,0-3.2,0
					c-1.2-1.5-3-2.5-3.4-4.6c-0.1-0.7-0.1-1.4-0.2-2l0.3-0.3C624.1,120.1,624.2,123.9,626.2,124.7z"/>
				<path fill="#A29EA1" d="M612.7,118.1c2.4-0.9,4.4-0.4,6,1.8c-0.2,0.2-0.3,0.4-0.5,0.6C616.2,120.2,614.2,119.6,612.7,118.1
					L612.7,118.1z"/>
				<path fill="#A29EA1" d="M618.7,119.9c0.9,0.2,1.8,0.3,2.1,1.3c0,0-0.3,0.3-0.3,0.3c-0.8-0.3-1.5-0.7-2.3-1
					C618.4,120.3,618.5,120.1,618.7,119.9z"/>
				<path fill={color4} style={{transition: "1s"}} d="M618.2,120.5c0.8,0.3,1.5,0.7,2.3,1c0.1,0.7,0.1,1.4,0.2,2c-4.7-1.3-9.1-3.3-13.4-5.5
					c0-0.5,0-0.9,0.1-1.4c1.8,0.5,3.6,1,5.4,1.5C614.2,119.6,616.2,120.2,618.2,120.5z"/>
				<path fill="#AA1836" d="M701.1,244.3c0.5,5.3,2.3,6.6,6.9,4c3.1-1.8,4.9-1.6,6.5,1.5c-3.8,3.5-9,2.5-13.3,4.2
					c-0.3,0.1-0.8,0.1-1-0.1c-2.6-2-0.7-5.1-1.7-7.7C697.4,243.9,699.8,244.4,701.1,244.3z"/>
				<path fill="#AA1836" d="M683.1,266.8c-1.7-0.4-2.6,0.6-3.3,1.9c-10.3,2.7-20.6,5.5-30.9,8.1c-1.9,0.5-4,0.6-6,0.9
					c-1.6-3.8,0.1-4.6,3.7-4.6c3,0,6.3,1.2,9.5-1.4c-3.6-1.3-7-1.3-9.8-0.6c-4.8,1.3-7.1-0.8-9-4.6c0.4-3.4,0.7-6.7,1.1-10.1
					c0.8,0,1.5,0,2.3,0c-0.4,4.6,0.5,6.8,5.7,4.1c2.7-1.4,5.9-2.1,9.1-1.8c3.7,0.4,6.4-2.3,9.9-3c0.8-0.1,0.7-4.6,1-7
					c1-4.7-2.5-6-5.7-7.3c-4.3-1.8-7-4.4-6.5-9.5c0-6.9,0.1-13.7,4.1-19.8c0.3-0.5-0.4-1.7-0.7-2.5c0-2.1,1.6-3.2,2.9-4.5
					c2.6-2.5,4.1-16.8,1.2-18.6c-4.6-2.9-3-6-1.8-9.4c4.2,5.8,6,12.6,7.8,19.4c4.4,16.4,7,33.3,13.2,49.2c0.7,4.9,1.3,9.8,3.5,14.3
					c-0.1,1.6-1.4,3,0.1,4.7C685.6,266,684.1,266.3,683.1,266.8z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M680.7,245.7c-6.2-15.9-8.8-32.8-13.2-49.2c-1.8-6.7-3.6-13.5-7.8-19.3c-0.5-2.6-2-4.6-3.8-6.5
					c-0.3-0.7-0.5-1.5-0.8-2.2c1.4-3.8,6.9-3.5,7.7-7.8c9.2,12.5,14.6,26.7,19,41.4c-2.2,2.4,0,3.8,1.2,5.5c0,0,0,0,0,0
					c0.6,8.5,3.4,16.4,5.6,24.6c0,0.4,0,0.8,0,1.2c-4.2,0.7-4.7,4.4-5.4,7.4C682.9,242.6,682,244.1,680.7,245.7z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M663,160.5c-0.8,4.3-6.3,4-7.7,7.8c-2.3-2.1-5-3.8-6.6-6.6c-0.8-0.9-1.5-1.9-2.3-2.8
					c-0.1-0.4-0.3-0.6-0.6-0.7c-0.5-0.4-1.1-0.8-1.6-1.1l0.1,0c-0.8-1.2-1.6-2.3-2.4-3.5c1.5-0.5,4,1.8,4.3,0c0.7-3.9,4-3.9,6.3-5.5
					c2.2,0.3,2.2,3.9,5,3.3c0.5,0.1,0.9,0.1,1.4,0.2C658.2,155.5,661.7,157.5,663,160.5z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M680.7,245.7c1.3-1.5,2.2-3,2.7-5.2c0.6-3,1.1-6.7,5.4-7.4c2.4,4.3,0.1,9.2,1.9,13.6
					c0.6,1.5-1.1,2.4-2.4,1.4c-2.5-2-4.4-1.2-4.8,1.4c-0.5,2.8,1.6,4.2,4.3,4.7c2,0.4,3.3-1.3,5.4-1.3c0.4,4.7-2.6,8.3-3.3,12.8
					c-1.2-2.8-0.3-7.4-5.5-5.9C682.1,255.4,681.4,250.5,680.7,245.7z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M688.7,231.9c-2.2-8.1-5-16.1-5.6-24.6C686.9,215.1,688.6,223.3,688.7,231.9z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M696.6,262.1c-0.2,1.8-0.9,3.2-3,3.1c-0.2,0-0.6-0.5-0.6-0.7C693.2,262.3,694.8,262,696.6,262.1
					L696.6,262.1z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M683.2,207.4c-1.3-1.6-3.4-3.1-1.2-5.5C683.1,203.6,683.1,205.5,683.2,207.4z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M701.2,259c0,0.1,0.1,0.4,0.1,0.4c-0.1,0.1-0.3,0.1-0.5,0.2c0-0.1-0.1-0.4-0.1-0.4
					C700.9,259.1,701.1,259.1,701.2,259z"/>
				<path fill={color4a} style={{transition: "1s"}} d="M696.6,262.1c0.2-0.2,0.3-0.3,0.5-0.5c0,0.1,0,0.2-0.1,0.3C696.9,262,696.7,262,696.6,262.1
					C696.6,262.1,696.6,262.1,696.6,262.1z"/>
				<path fill="#AA1836" d="M721.4,245.8c0.3-0.5,0.5-1,0.8-1.5C721.9,244.8,721.6,245.3,721.4,245.8z"/>
				<path fill="#D8D8D9" d="M568.1,148.2c-1.5,6.2-8.2,6.6-11.8,10.5c-2.8,3-6,6.5-10.6,7.3c-0.7-3.7-1.4-7.5-2.1-11.2
					c5.4-4.9,12-8.2,18.2-11.7c5.5-3.1,7.4-9.2,12.8-11.8c0.6-0.3,0.5-2.1,1.7-0.9c0.5,0.4,0.5,1.3,0.7,2c-3.6,3.9-6.9,8-7.8,13.5
					c0,0,0,0,0,0C568.3,146.4,568.2,147.3,568.1,148.2z"/>
				<path fill="#A29EA1" d="M546,170.7c4.5,0,7.3-3.6,11-5.6c1.4,2-0.6,3.7-0.1,5.4c0,0,0,0,0,0c-1.4,0.6-2,1.8-2.2,3.2
					c-0.8,7.4-2.1,14.7-5.8,21.3c-2.4-3.8-1.6-8.1-2.2-12.3C547.4,178.7,546.1,174.7,546,170.7z"/>
				<path fill="#D36760" d="M549,195c3.7-6.6,5-13.9,5.8-21.3c2.3,0,2-1.8,2.2-3.2c0,0,0,0,0,0c2.6-1.1,2.2-4.1,4-5.8
					c2.1-1.9,4.1-4.4,7-4.1c8.9,0.8,13.3-6.4,19.4-10.4c2.9-1.8,5.1-4.8,8.7-5.4c0.7-0.1,1.7,0,0.9-1.2c-0.3-0.5-0.7-0.8-1.1-1.2
					c1.6-1.7,3.1-3.3,4.6-5c2,1.4,0,4.7,3.1,4.9c2.8,0.2,3.4-2.5,4.8-4c1.6-1.8,1.7-4.9,4.5-5.8c1.4,0.4,2.8,0.8,4.2,1.1
					c0.5,0.3,1,0.6,1.6,0.9c0.6,0.1,1.3,0.2,1.9,0.3c0.4,5.6,4.8,7,9,9c4.8,2.2,9.2,5.3,12.3,9.8c0.8,1.2,1.6,2.3,2.4,3.5
					c-5.1,1.1-7.7,6.3-12.6,7.8c-0.4,0.1-0.7,1.3-0.8,2c-0.4,3.7-3.1,5.5-6.1,6.6c-2.8,1-4.7,2.7-6.4,5c-3.4,4.8-7.8,8.9-9.7,14.8
					c-0.4,1.1-1.3,2.1-2.5,1.6c-2.8-1.2-4.6,5-7.6,0.9c-0.4,0.3-1,0.6-1.3,1c-3.4,6.1-12.4,7.1-13.9,15c-0.1,0.3-0.9,0.8-1.3,0.7
					c-5.9-1.1-9.5,4.1-11.9,6.9c-4,4.7-8.2,8.7-13.2,12.1c-2.8-3.2-8.6-0.6-10.5-6.3c-2,4.3-2.1,7.9-1.1,11.5
					c0.5,1.9,1.5,3.8,3.9,2.8c3.7-1.5,3.9,1,4.5,3.4c-2.9,4.4-0.4,7.6,3.7,11.4c-4.8-1.1-8.2-1.8-11.5-2.8c-1.9-0.6-3.3-0.7-3.5,1.8
					c-2.9-1-3.4-6-7.7-4.7l0,0c0.1-2.1-1.3-2.9-3-3.4c-0.2-0.7-0.4-1.3-0.5-2c2.8-1.4,5-5.8,6.2-6c6.5-1.3,3-6,4-9.1
					c1-3.4,2.5-6.7,1.4-10.4c0-0.1-0.3-0.2-0.4-0.3c1.7-5.6,3.3-11.2,5-16.8c1.2-0.9,2-1.9,1.1-3.5C548.6,196.4,548.8,195.7,549,195
					z M608.1,186.1c-0.5-1-1-2-2.7-1.9c-1.8,0.1-2.8,0.8-2.5,2.7c0.2,1.3,0.9,2.6,2.4,2.4C607,189,608,188,608.1,186.1z"/>
				<path fill="#A29EA1" d="M548.5,197c0.9,1.6,0.1,2.6-1.1,3.5C546.9,199.1,547.2,197.9,548.5,197z"/>
				<path fill="#BD0334" d="M535.2,275.7c-1,0-1.9,0-2.9,0c-2.8-1.3-5.8-2.3-8.4-3.9c-8.9-5.4-9.3-6.8-5.9-17.4c0,0-0.1-0.1-0.1-0.1
					c0.7-0.1,1.4-0.3,2-0.4c2.2,2.7,4.1,1.2,6-0.5c2.6-2.2,5.2-4.4,8.8-4.9c0,0,0,0,0,0c2.1,7.2,8.2,9.9,14.4,12.4
					c0.7,0.4,1.4,0.8,2.1,1.2c0,1.3,0.3,3.1-1.5,3c-3.9-0.1-5.6,3-8.2,4.9C539.2,271.7,538.4,275,535.2,275.7z"/>
				<path fill="#A7062E" d="M534.7,248.6c-3.6,0.5-6.2,2.7-8.8,4.9c-1.9,1.6-3.7,3.2-6,0.5c0.1-0.6,0.2-1.2,0.4-1.8
					c0.4-0.7,0.8-1.4,1.2-2.1c0.6-0.3,1.3-0.5,1.9-0.8c1.4-0.6,2.8-1.1,3.3-2.7l0,0c1.8,0.3,3.3-0.9,4.9-1.3
					C533.5,245.7,534.9,246.4,534.7,248.6z"/>
				<path fill={color4} style={{transition: "1s"}} d="M531.7,245.2c-1.7,0.3-3.1,1.6-4.9,1.3c0.6-1.7,1.9-2.7,3.4-3.5c0.3,0.1,0.6,0.2,0.9,0.2
					C531.4,243.9,531.6,244.5,531.7,245.2z"/>
				<path fill={color4} style={{transition: "1s"}} d="M526.8,246.5c-0.5,1.6-2,2.1-3.3,2.7C523.3,246.7,524.8,246.3,526.8,246.5z"/>
				<path fill={color4} style={{transition: "1s"}} d="M520.3,252.1c-0.1,0.6-0.2,1.2-0.4,1.8c-0.7,0.1-1.4,0.3-2,0.4C517.9,252.7,518.8,252,520.3,252.1z"/>
				<path fill="#BD0334" d="M654.1,231.9c-0.5,5,2.2,7.7,6.5,9.5c3.2,1.3,6.7,2.6,5.7,7.3c-6.4,0.4-12.9,0.7-19.3,1.3
					c-3.7,0.4-7.6,1.1-6.4,6.5c-0.8,0-1.5,0-2.3,0c0-8.9,0-8.8-8.7-9c-1.5,0-3.1,0.6-4.8-0.8c9.1-1.4,18.1,2.1,26.9-1.5
					c-14.7,0-29.4,0.4-44.3-0.2c0.2,0.7,0.3,1.2,0.4,1.7c0.3,2.1,4.5,2.5,3.1,4.6c-1.8,2.8-2.5-2.4-4.6-1.5c-0.3,0.1-0.7,0-1.1,0
					c-8,0.1-14.5,4.4-21.2,7.9c-1.3,0.7-3.8,1.2-3.1,3.3c0.5,1.3,2.4,2.2,3.8,3c3.9,2.3,8.4,0.9,12.6,1.9c9.5,2.3,10.7,1.3,12.1-8.3
					c0.1-0.7,0-1.6,2-1.7c-0.3,4.1-0.7,8.1-1,12.5c8.7-3.4,17.9-1.5,26.9-2c1.8,3.8,4.1,5.9,9,4.6c2.8-0.7,6.2-0.7,9.8,0.6
					c-3.2,2.6-6.5,1.4-9.5,1.4c-3.6,0-5.3,0.8-3.7,4.6c-0.6,0.1-1.2,0.1-1.9,0.2c-7.5,0.9-14.8,2.6-21.7,6c-0.7,0.1-1.5,0.2-2.2,0.3
					c-0.9-1.1-2.2-0.9-3.3-0.9c0.4-2.2,0.8-4.4,1.2-6.6c5.9,0,11.8,0,17.7,0c-8.1-1.3-16.1-2.9-24.6-2.2c0.7-1.7,3.1-0.7,2.8-2.8
					c-4.9-2.3-11.1-3.6-15.4-2.3c-7.4,2.3-14.3,0.7-21.3,0.5c-3.1-0.1-8-0.1-8.6-2.2c-1.2-3.8-4.2-4-6.5-5.6
					c0.7-1.4,1.3-2.9,1.1-4.6c2.2,0,4.5,0,6.7,0c2.5,2.7,8.5,3.7,8.7,1.5c0.3-3,2.6-4.8,3.3-6.9c1.5-4.6,5.1-9.3,2.5-14.7
					c-1.4-2,1.5-2.8,1.1-4.5c2.7-0.4,5.8-0.3,5.6-4.5c-0.3-2.5,0.8-3.6,3.3-3.4c6.6-2.8,9.2-9.4,13.4-14.5c0-0.4,0-0.7,0-1.1
					c2.6-0.3,4.1-1.8,4.5-4.4c1.5-1.1,3-2.2,4.5-3.4c3.3-0.5,5.4-3,6.5-5.6c1.7-4,4.8-6.5,8-8.9c3,0,5.3-5.8,8.9-1.1
					c-2.1,3.9-6.7,6-7.9,10.4c-1.3,4.8-3.3,8.9-7.2,12c-0.8,0.7-1.7,1.6-1,2.8c0.7,1.3,1.9,1.5,3.3,1.2c4.6-1,9.4-0.5,14-0.6
					c6.8-0.1,11,1.8,7.8,9.8c-0.2-3-0.5-5.9-4.7-5.4c-0.2,0-0.4,0-0.6,0c-1.8-0.2-3.5-0.3-3.5,2.3c0,0.3,0,0.6-0.1,0.9
					c-1.4,1.2-1.5,2.3,0.1,3.3c1.6,1.1,2.9,2.7,5.2,2.2c1,0.1,1.9,0,2.5-1c1.5,0,2.7,0.2,3.5,1.9C649.9,228.5,651.2,231,654.1,231.9
					z M603.5,245.1c-0.3-0.1-0.7-0.4-0.9-0.4c-0.2,0.1-0.3,0.6-0.4,0.9c0.3,0.1,0.7,0.4,0.9,0.4
					C603.3,245.9,603.3,245.4,603.5,245.1z"/>
				<path fill="#AA1836" d="M613.9,283.3c1.2,0.1,2.5-0.2,3.3,0.9c-9.4,3.2-19.1,5.3-29,5.9c-1-0.2-1.8,0-2.3,0.9c0,0,0,0,0,0
					c-1.5,0.3-2.9,0.6-4.4,0.9c-0.4,0-0.8,0-1.1,0c-0.6-0.9-1.5-1-2.4-1c0.1-0.4,0.1-0.8,0.2-1.2c-0.1-0.9-0.3-1.7-1.2-2.1
					c0,0,0,0,0,0c7-2.2,14.3-0.7,21.3-2.3c3-0.4,5.9-0.7,8.9-1.1c1.2,0,2.5,0.2,3.4-1.1C611.7,283.3,612.8,283.3,613.9,283.3z"/>
				<path fill="#AA1836" d="M619.4,283.9c6.8-3.4,14.2-5.1,21.7-6C634.4,281.7,627,283.2,619.4,283.9z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M586,291.1c0.5-1,1.3-1.2,2.3-0.9C587.7,291,586.9,291.1,586,291.1z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M576.8,300c1.8,0.1,4.3-0.2,4.2,2.3c0,2.2-2,3.4-4.3,3.2c-2.2-0.2-4-1.5-3.9-3.8
					C573,299.3,575.4,300.3,576.8,300z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M578,291.1c1-0.1,1.8,0.1,2.4,1c-2.6,1.9-5.3-0.6-7.9,0.2C574.1,290.5,576.1,291.1,578,291.1z"/>
				<path fill="#A29EA1" d="M641.8,153.6c-3.1-4.5-7.5-7.5-12.3-9.8c-4.2-2-8.6-3.4-9-9c0.2-1.1,0.4-2.1,1.7-2.4
					c5.2,2,5.4,1.9,5-4.3c0.1-0.2,0.3-0.3,0.4-0.5c10.1,4.6,16.9,13.1,24.7,20.4c-2.3,1.6-5.6,1.6-6.3,5.5
					C645.8,155.4,643.3,153.1,641.8,153.6z"/>
				<path fill="#A29EA1" d="M612.9,132.5c-2.8,1-2.9,4-4.5,5.8c-1.3,1.5-2,4.2-4.8,4c-3-0.2-1.1-3.5-3.1-4.9c-1.5,1.6-3.1,3.3-4.6,5
					c-2.9-0.5-5.6-1.6-7.7-1.1c3.7-4.8,8.7-9,13.5-13.2c2.6,0.5,5.2,0.9,7.7,1.4C610.4,130.6,612.4,130.7,612.9,132.5z"/>
				<path fill="#D8D8D9" d="M609.4,129.4c-2.6-0.5-5.2-0.9-7.7-1.4c-1.7-4-6.4-1.9-8.9-4.3c-0.6-0.6-1.8-0.5-2-1.6
					c-0.2-1.5,1.3-1.5,2.1-2.1c5-4.1,9.6-0.4,14.2,0.8c2.9,0.7,5.1,3.4,9.7,3.1C613.4,126.6,609.6,125.7,609.4,129.4z"/>
				<path fill="#F0F2F3" d="M569.3,145.9c0.9-5.5,4.2-9.6,7.8-13.5c4-3.3,8.5-5.5,13.9-5.9C583,132.2,577.8,140.9,569.3,145.9z"/>
				<path fill={color4} style={{transition: "1s"}} d="M627.2,128.1c0.4,6.2,0.2,6.3-5,4.3c-1-2.1,0-3.4,1.8-4.2C625.1,128.2,626.2,128.1,627.2,128.1z"/>
				<path fill="#F0F2F3" d="M568.1,148.2c0-0.9,0.2-1.8,1.1-2.3C569.3,146.9,569.1,147.7,568.1,148.2z"/>
				<path fill="#A29EA1" d="M618.6,134.6c-0.5-0.3-1-0.6-1.6-0.9C617.6,134,618.1,134.3,618.6,134.6z"/>
				<path fill="#C92E43" d="M598.3,285.5c-7,1.6-14.4,0.1-21.3,2.3c-4.6-0.1-9.1-0.5-13.5-2c0.5-1.7,0.2-3.1,2.9-4.3
					c2.9-1.3,6.4-0.3,8.8-2.8c1-1,2.7-1.6,2.6-3.3c-0.2-1.9-2-1.7-3.3-2.2c-3.1-1.3-6.5-0.8-9.7-2.1c-10.6-4.4-10.6-4.2-18.2,4.2
					c-1.2,1.4-2.8,2.4-4.1,3.6c-2.3-1.2-5.7,0-7.1-3.2c3.1-0.7,4-4,6.3-5.6c2.6-1.8,4.3-5,8.2-4.9c1.8,0.1,1.4-1.8,1.5-3
					c0,0,0.2-0.2,0.2-0.2c1.8,0.1,3.4,0.2,4.9,1.6c1.5,1.4,2.6,0.4,2.8-1.5c2.3,1.7,5.3,1.8,6.5,5.6c0.6,2,5.5,2.1,8.6,2.2
					c7.1,0.2,13.9,1.8,21.3-0.5c4.3-1.3,10.5,0,15.4,2.3c0.3,2.1-2.1,1.1-2.8,2.8c8.5-0.6,16.5,0.9,24.6,2.2c-5.9,0-11.8,0-17.7,0
					c-10.9,0.9-21.7-2.4-32.7-0.3c4.5,1.5,7,7.4,13.1,5.2C597.1,281,598.6,283.3,598.3,285.5z"/>
				<path fill="#D36760" d="M542.4,278.9c1.4-1.2,2.9-2.3,4.1-3.6c7.6-8.4,7.6-8.5,18.2-4.2c3.2,1.3,6.6,0.8,9.7,2.1
					c1.3,0.6,3.2,0.3,3.3,2.2c0.2,1.7-1.6,2.3-2.6,3.3c-2.4,2.4-5.9,1.5-8.8,2.8c-2.7,1.2-2.4,2.6-2.9,4.3c-1.8-0.2-3.6-0.4-5.4-0.6
					C552.9,283.1,547.6,281,542.4,278.9z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M541.7,288.4c-0.3,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.5-0.3-0.8-0.5c0.3-0.1,0.6-0.4,0.9-0.4
					C541.1,287.9,541.4,288.2,541.7,288.4z"/>
				<path fill="#C92E43" d="M577,287.8c0.9,0.4,1.1,1.2,1.2,2.1C577,289.7,576.8,288.9,577,287.8z"/>
				<path fill={color4b} style={{transition: "1s"}} d="M654.1,145.9c1.5,0,2.2,0.7,2.3,2.2C655.6,147.4,654.9,146.7,654.1,145.9z"/>
				<path fill="#C8143E" d="M637.4,266.6c-9,0.5-18.2-1.5-26.9,2c0.4-4.4,0.7-8.4,1-12.5c-2,0.1-1.9,1-2,1.7
					c-1.4,9.6-2.6,10.6-12.1,8.3c-4.2-1-8.7,0.4-12.6-1.9c-1.4-0.8-3.3-1.7-3.8-3c-0.7-2.1,1.8-2.6,3.1-3.3
					c6.7-3.5,13.2-7.8,21.2-7.9c0.4,0,0.8,0.1,1.1,0c2.1-0.9,2.7,4.3,4.6,1.5c1.4-2.1-2.7-2.5-3.1-4.6c-0.1-0.5-0.2-1-0.4-1.7
					c14.9,0.6,29.6,0.3,44.3,0.2c-8.8,3.5-17.8,0-26.9,1.5c1.6,1.4,3.3,0.8,4.8,0.8c8.7,0.1,8.7,0.1,8.7,9
					C638.1,259.9,637.8,263.2,637.4,266.6z"/>
				<path fill="#A7062E" d="M656,170.5c1.8,1.9,3.3,3.9,3.8,6.5c-1.1,3.5-2.8,6.6,1.8,9.4c2.9,1.8,1.4,16.1-1.2,18.6
					c-1.3,1.3-2.9,2.4-2.9,4.5c-9.2,0-12.1-9.1-18.7-12.9c-0.6-0.3-1.1-1.1-1.2-1.8c-0.1-1.1,0.2-2.2,0.4-3.3
					c0.2-1.8,0.4-3.7,0.5-5.5c2.3-1.2,2.9-3.8,4.4-5.5C646.7,176.4,649.7,171.4,656,170.5z"/>
				<path fill="#B0022F" d="M638.5,186.1c-0.2,1.8-0.3,3.7-0.5,5.5c-0.1,1.1-0.4,2.2-0.4,3.3c0.1,0.6,0.6,1.4,1.2,1.8
					c6.6,3.8,9.5,12.9,18.7,12.9c0.3,0.9,1,2.1,0.7,2.5c-4,6.1-4,12.9-4.1,19.8c-2.9-0.9-4.3-3.4-5.4-5.9c-0.8-1.8-2-2-3.5-1.9
					c-0.1-0.4-0.1-0.8-0.2-1.2c0,0,0,0,0,0c0.4-0.3,0.8-0.7,1.3-1c3.2-8-1-9.9-7.8-9.8c-4.6,0.1-9.4-0.4-14,0.6
					c-1.4,0.3-2.6,0.2-3.3-1.2c-0.7-1.3,0.2-2.2,1-2.8c3.9-3.1,6-7.2,7.2-12c1.2-4.4,5.8-6.5,7.9-10.4
					C637.8,186.1,638.1,186.1,638.5,186.1z"/>
				<path fill="#C92E43" d="M640.7,256.5c-1.1-5.4,2.7-6.2,6.4-6.5c6.4-0.6,12.8-0.9,19.3-1.3c-0.3,2.5-0.2,6.9-1,7
					c-3.4,0.7-6.2,3.4-9.9,3c-3.2-0.3-6.4,0.4-9.1,1.8C641.2,263.2,640.3,261.1,640.7,256.5z"/>
				<path fill="#AA1836" d="M638.5,186.1c-0.4,0-0.7,0-1.1,0c-3.6-4.7-5.9,1.1-8.9,1.1c0-1.1,0-2.2,0-3.4c3.9-9.1,13-14.1,17.9-22.4
					c0.8,0.1,1.6,0.1,2.3,0.2c1.6,2.8,4.2,4.6,6.6,6.6c0.3,0.7,0.5,1.5,0.8,2.2c-6.2,0.9-9.3,5.9-13,10.1
					C641.4,182.4,640.8,184.9,638.5,186.1z"/>
				<path fill="#C92E43" d="M646.3,161.5c-4.9,8.3-14,13.3-17.9,22.4c-3.1-0.2-3.3,3.2-5.4,4.3c-5.3,2.9-8.1,7.7-9.1,13.5
					c-1.5,1.1-3,2.2-4.5,3.4c-3.2-0.3-4.7,1.2-4.5,4.4c0,0.4,0,0.7,0,1.1c-5.7,1.1-13,9.1-13.4,14.5c-2.5-0.2-3.6,0.9-3.3,3.4
					c-3.2-0.1-5.1,1.2-5.6,4.5c0.3,1.7-2.6,2.5-1.1,4.5c-1.4-0.2-2.8-0.4-4.3-0.6c-2.6,0.1-5.2,0.3-9.1-2c2.8,4.7,3.8,7.9,0,10.4
					c-0.4-4.2-4.5-0.9-6-2.9c0.9-5.7,4.3,1.3,5.8-1.4c1.4-3.9-1-6.2-4.2-8.1c-0.9-3-3.1-1.9-5-1.6c-0.6,0.1-1.2,0.1-1.8,0.2
					c5-3.4,9.2-7.4,13.2-12.1c2.4-2.8,6.1-8,11.9-6.9c0.4,0.1,1.2-0.4,1.3-0.7c1.5-7.9,10.5-8.9,13.9-15c0.2-0.4,0.9-0.7,1.3-1
					c3,4.1,4.8-2.1,7.6-0.9c1.2,0.5,2.2-0.5,2.5-1.6c1.8-5.8,6.2-10,9.7-14.8c1.7-2.3,3.6-4.1,6.4-5c3-1.1,5.6-2.9,6.1-6.6
					c0.1-0.7,0.4-1.8,0.8-2c4.9-1.5,7.5-6.6,12.6-7.8c0,0-0.1,0-0.1,0c0.5,0.4,1.1,0.8,1.6,1.1c0.4,0.1,0.6,0.4,0.6,0.7
					C646.4,159.8,646.3,160.7,646.3,161.5z"/>
				<path fill="#D36760" d="M646.3,161.5c0-0.9,0-1.7,0.1-2.6c0.8,0.9,1.5,1.9,2.3,2.8C647.9,161.6,647.1,161.6,646.3,161.5z"/>
				<path fill="#D36760" d="M645.7,158.2c-0.5-0.4-1.1-0.8-1.6-1.1C644.7,157.4,645.2,157.8,645.7,158.2z"/>
				<path fill="#CECECF" d="M557,170.5c-0.3,1.4,0.1,3.2-2.2,3.2C554.9,172.2,555.6,171.1,557,170.5z"/>
				<path fill="#C92E43" d="M567,257.6c-2.2,0-4.5,0-6.7,0c-6.8-2.6-6.9-2.5-8.8,4.4c0,0-0.2,0.2-0.2,0.2c-0.7-0.4-1.4-0.8-2.1-1.2
					c-1.1-1.5-2.2-2.9-3.4-4.4c0.9-1.9-0.8-0.9-1.3-1.2c-0.3-0.3-0.6-0.5-0.9-0.8c-0.4-0.5-0.8-1-1.1-1.5l0,0.1
					c0.2-2.4,1.6-2.3,3.5-1.8c3.3,1,6.7,1.6,11.5,2.8c-4.2-3.8-6.6-7-3.7-11.4c1.4,0.6,2.3-0.4,3.2-1.1c0.8,0.8,1.6,1.6,2.5,2.4
					c-1.4,0.3-2.9,0.5-3.5,1.6c3.7,3.2,7.3,6.2,10.7,9.4C567.1,255.4,566.9,256.7,567,257.6z"/>
				<path fill="#D8D8D9" d="M556.9,241.7c-1,0.7-1.8,1.7-3.2,1.1c-0.6-2.4-0.8-4.9-4.5-3.4c-2.4,1-3.3-0.9-3.9-2.8
					c-1-3.7-0.8-7.2,1.1-11.5c1.8,5.6,7.6,3,10.5,6.3c0.6-0.1,1.2-0.1,1.8-0.2C559.6,235,557.5,238.2,556.9,241.7z"/>
				<path fill="#A7062E" d="M545.7,256.6c1.1,1.5,2.2,2.9,3.4,4.4c-6.2-2.6-12.3-5.2-14.4-12.4c4.3-1.3,4.8,3.7,7.7,4.7
					c0,0,0-0.1,0-0.1c0.4,0.5,0.8,1,1.2,1.5c0.3,0.3,0.6,0.5,0.9,0.8C544.9,255.8,545.3,256.2,545.7,256.6z"/>
				<path fill="#D8D8D9" d="M608.1,186.1c-0.2,1.9-1.1,3-2.7,3.1c-1.5,0.2-2.2-1.1-2.4-2.4c-0.3-1.8,0.7-2.6,2.5-2.7
					C607.1,184,607.6,185.1,608.1,186.1z"/>
				<path fill="#C8143E" d="M567,257.6c-0.1-0.9,0.1-2.2-0.4-2.6c-3.5-3.2-7.1-6.2-10.7-9.4c0.6-1.1,2.1-1.2,3.5-1.6
					c0.3,0,0.7,0.1,1,0.2c0.2,0.6,0.4,1.1,1.1,0.7c1.5,1,3.1,1.8,5,1.2c0.5,0.1,1.1,0.3,1.6,0.4c3.1,0.5,6.1,1.1,9.1,3
					c-1.3-3.7-3.2-7.2,0.8-10.1c0.2-0.2-0.5-1.6-0.7-2.5c1.4,0.2,2.8,0.4,4.3,0.6c2.6,5.4-1,10.1-2.5,14.7c-0.7,2.1-3,3.9-3.3,6.9
					C575.5,261.3,569.6,260.4,567,257.6z"/>
				<path fill="#C8143E" d="M598.3,285.5c0.3-2.2-1.2-4.5-2.8-3.9c-6.1,2.2-8.6-3.8-13.1-5.2c10.9-2.1,21.8,1.2,32.7,0.3
					c-0.4,2.2-0.8,4.4-1.2,6.6c-1.1,0-2.2,0-3.3,0c-0.3-2-1.1-3.9-3.3-3c-2.3,0.9-0.5,2.7,0,4.1
					C604.3,284.8,601.3,285.2,598.3,285.5z"/>
				<path fill="#C8143E" d="M614,201.8c1.1-5.8,3.8-10.6,9.1-13.5c2.1-1.2,2.3-4.6,5.4-4.3c0,1.1,0,2.2,0,3.4c-3.2,2.5-6.3,5-8,8.9
					C619.3,198.8,617.3,201.3,614,201.8z"/>
				<path fill="#C8143E" d="M591.6,225.2c0.4-5.4,7.7-13.4,13.4-14.5C600.8,215.8,598.2,222.4,591.6,225.2z"/>
				<path fill="#C8143E" d="M551.4,262c1.9-7,2.1-7,8.8-4.4c0.2,1.7-0.4,3.1-1.1,4.6c-0.3,1.8-1.3,2.9-2.8,1.5
					C554.8,262.2,553.2,262.1,551.4,262z"/>
				<path fill="#D36760" d="M637.4,219.6c0-0.3,0.1-0.6,0.1-0.9c1.4-0.4,3.2-0.2,3.5-2.3c0.2,0,0.4,0,0.6,0c-0.8,3.2,2.8,4,3.4,6.5
					c0,0,0,0,0,0c-0.8,0.7-1.6,1.5-2.3,2.2c-2.3,0.6-3.6-1-5.2-2.2C637.5,221.8,637.5,220.7,637.4,219.6z"/>
				<path fill="#AA1836" d="M645.1,222.9c-0.6-2.4-4.2-3.3-3.4-6.5c4.2-0.5,4.5,2.4,4.7,5.4C645.9,222.2,645.5,222.5,645.1,222.9z"
					/>
				<path fill="#C8143E" d="M582.6,233c0.5-3.2,2.5-4.6,5.6-4.5C588.5,232.7,585.4,232.6,582.6,233z"/>
				<path fill="#C8143E" d="M605,209.6c-0.3-3.2,1.2-4.7,4.5-4.4C609.1,207.8,607.7,209.3,605,209.6z"/>
				<path fill="#AA1836" d="M641,216.4c-0.3,2.1-2.1,1.9-3.5,2.3C637.5,216.1,639.2,216.2,641,216.4z"/>
				<path fill="#C8143E" d="M603.5,245.1c-0.1,0.3-0.2,0.8-0.4,0.9c-0.2,0.1-0.6-0.2-0.9-0.4c0.1-0.3,0.2-0.8,0.4-0.9
					C602.8,244.6,603.2,244.9,603.5,245.1z"/>
				<path fill="#AA1836" d="M637.4,219.6c0,1.1,0.1,2.2,0.1,3.3C635.9,221.9,636,220.8,637.4,219.6z"/>
				<path fill="#AA1836" d="M642.7,225.1c0.8-0.7,1.6-1.5,2.3-2.2c0.1,0.4,0.1,0.8,0.2,1.2C644.7,225.1,643.7,225.2,642.7,225.1z"/>
				<path fill="#BD0334" d="M607.2,284.4c-0.4-1.4-2.2-3.2,0-4.1c2.2-0.9,3.1,1,3.3,3C609.8,284.6,608.5,284.4,607.2,284.4z"/>
				<path fill="#D8D8D9" d="M577.2,236.9c0.3,0.8,1,2.3,0.7,2.5c-4,2.9-2.1,6.4-0.8,10.1c-3-1.9-6.1-2.5-9.1-3
					c0-0.4,0.1-0.8,0.1-1.2l-0.1,0.1c3.8-2.5,2.8-5.8,0-10.4C572,237.2,574.6,237,577.2,236.9z"/>
				<path fill="#D8D8D9" d="M563.7,232.9c3.1,2,5.6,4.2,4.2,8.1c-1.6,2.7-4.9-4.2-5.8,1.4c-0.3,0.2-0.5,0.5-0.7,0.7
					c-0.3,0-0.5-0.1-0.8-0.1C559.1,238.9,558,234.8,563.7,232.9z"/>
				<path fill="#D36760" d="M563.7,232.9c-5.8,2-4.6,6-3.2,10.2c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3-0.1-0.7-0.1-1-0.2
					c-0.8-0.8-1.6-1.6-2.5-2.4c0.6-3.5,2.7-6.7,1.8-10.5C560.6,231,562.9,229.8,563.7,232.9z"/>
				<path fill="#D8D8D9" d="M566.4,246.1c-1.9,0.6-3.4-0.2-5-1.2c0-0.6-0.1-1.1-0.1-1.7c0.2-0.3,0.5-0.5,0.7-0.7
					c1.5,2.1,5.6-1.3,6,2.9c0,0,0.1-0.1,0.1-0.1C567.3,245,566.8,245.4,566.4,246.1z"/>
				<path fill="#D36760" d="M545.7,256.6c-0.4-0.4-0.8-0.8-1.3-1.2C545,255.7,546.7,254.7,545.7,256.6z"/>
				<path fill="#D36760" d="M543.5,254.6c-0.4-0.5-0.8-1-1.2-1.5C542.8,253.7,543.2,254.1,543.5,254.6z"/>
				<path fill="#CECECF" d="M561.4,243.2c0,0.6,0.1,1.1,0.1,1.7c-0.7,0.4-1-0.1-1.1-0.7c0.1-0.4,0.2-0.7,0.3-1.1
					C560.9,243.1,561.1,243.1,561.4,243.2z"/>
				<path fill="#C92E43" d="M566.4,246.1c0.4-0.6,0.9-1.1,1.7-0.8c0,0.4-0.1,0.8-0.1,1.2C567.5,246.3,567,246.2,566.4,246.1z"/>
			</g>
		</g>
	</g>
</switch>
</svg>
		)}}
