import React from "react";

export default class Lan1SVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		//let color4 = this.props.selectedDresses.hat ? '#0d0' :'#2A2A2A'
		return ( 
			<div style={{
				position: 'absolute',
				height: this.props.onIPsCorrect ? '7%' : 0 ,
				width: '5%',
				left: '37.5%',
				bottom: '2%',
				cursor: 'pointer',
				zIndex: 222,
				transition: 'all 1s cubic-bezier(0.99, 0.01, 1, 0.54) 0s',
				bottom: this.props.onIPsCorrect ? '2%' : '-10%',
			}}>
<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 216.3 339.3" enable-background="new 0 0 216.3 339.3">
<g>
	<path fill="#83817D" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M99.4,293.8c-11.2,11.3-18.2,25.2-24.6,39.4
		c-0.8,1.9-2.7,3.8-0.7,6c-4.5,0-9,0-13.4,0c2.4-17.5,8-33.9,16.8-49.2c1.7-3,4-6.3,4.1-9.5c0.7-17.9,11.2-30.3,22.2-42.9
		c4.4-5,8.3-11.3,4.4-19.1c-2-3.9-2.1-8.2,1.8-11.3c3.9-3.1,6.5,0.9,9.2,2.6c5.4,3.4,12,5.5,16.9,2.6c4.2-2.5,0.3-8.8-0.2-13.4
		c-0.1-0.6-0.2-1.3-0.3-1.9c1.8,3,6.7-0.2,7.8,4c8.2,12.8,8.3,25.7-2.7,38.6c1,0.1,2,0.1,3,0.2c2.4-1.5,4.7-3,7.1-4.5
		c1.4,5.8,4.6,11.1,4.3,17.3c-6.5,0.9-12.8,3.5-19.6,2.1c-3.8-0.8-5.8,2.1-8,4.2C116.4,269.4,107.5,281.3,99.4,293.8z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M135.3,197.1c0.1,0.6,0.3,1.2,0.3,1.9
		c0.6,4.6,4.5,10.8,0.2,13.4c-4.9,2.9-11.5,0.9-16.9-2.6c-2.7-1.7-5.2-5.7-9.2-2.6c-3.9,3.1-3.8,7.4-1.8,11.3c3.9,7.7,0,14-4.4,19.1
		c-11,12.6-21.4,25-22.2,42.9c-0.1,3.2-2.4,6.5-4.1,9.5c-8.8,15.3-14.4,31.7-16.8,49.2c-1.9,0-3.8,0-5.8,0c2.2-10,1.7-19.5-1.9-29.5
		c-2.5-6.8-3.1-14.6,0.9-21.9c5-8.8,9.5-17.9,14.2-26.9c1.2-2.3,2.2-4.7,0-7.1c-1.4-1.5-3-2.6-5-1.3c-4.8,3.1-11.4,5.5-12.8,10.9
		c-2.8,10.7-10.7,18.3-15.2,28c-7.2,15.5-12.4,31-14.8,47.7c-3.8,0-7.7,0-11.5,0c3.1-15.1,10.5-28.5,16.3-42.5
		c10.9-20.7,23.3-40.6,37.7-59.1c0.6-0.6,1.1-1.3,1.7-1.9c0,0,0,0,0,0c9.8-4.5,14.2-13,16.9-22.9c5-1.6,6.5-6.8,10.4-9.6
		c5.1-3.7,10-8.4,17-6.9C117.6,198.4,126.4,200.1,135.3,197.1z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M20.3,339.3c2.4-16.7,7.5-32.2,14.8-47.7
		c4.5-9.7,12.4-17.3,15.2-28c1.4-5.4,8-7.8,12.8-10.9c2-1.3,3.6-0.2,5,1.3c2.1,2.3,1.2,4.7,0,7.1c-4.7,9-9.3,18-14.2,26.9
		c-4.1,7.2-3.4,15.1-0.9,21.9c3.7,9.9,4.1,19.5,1.9,29.5C43.3,339.3,31.8,339.3,20.3,339.3z"/>
	<path fill="#83817D" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M186.9,47.5c0-1.2,0.1-2.3,0.1-3.5
		c-0.1-1.3-0.1-2.7-0.2-4c-1.3-2.7-2.5-5.4-3.9-8.3c0.1-0.1,0.2-0.1,0.3-0.1c-0.3-0.4-0.5-1-0.6-1.6c-0.3-1.6-0.7-3.2-1.1-4.7
		c-3,0-5.9-1.3-8.6-4.3c-1.9-2.1-3.8-5.2-7.7-4.2c-0.6,0.7-1.8,1.9-1.7,2.1c7.3,9.4,4.5,22.2,11.4,32.2c3.8,5.6-2.3,13.5-2.1,20.6
		c2.2-0.5,4.4-1,6.5-1.6c3.8-5.1,10.1-8.1,11.6-15C187.8,53.6,185,51.9,186.9,47.5z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M100.8,30.6c0.1-1.7,0.2-3.4,0.4-5.1
		c2.8,0.3,4.7-1.2,6.5-3.1c8.6-8.9,19.1-13.3,31.5-13c2.5,0,5.1,0.1,7.6,0.1c6.4,1.9,13.7,1.6,18.4,7.5c-0.6,0.7-1.8,1.9-1.7,2.1
		c7.3,9.4,4.5,22.2,11.4,32.2c3.8,5.6-2.3,13.5-2.1,20.6c-5.2,4.3-12.4,8.1-16.7,2.4c-3.4-4.5-8.6-12.2-0.4-19.4
		c4.5-3.9,7.4-11.3,2.8-16.4c-5.4-6-11.2-12.3-18.9-15.7c-5.1-2.2-10.5-3-15.7-0.3c-15,7.9-19.6,17.3-16.9,34.4
		c1.8,11.4,1.7,11.6-9.2,8c-3.1-1-4.9-0.4-6.3,2.2c-1.4,2.6-3,5.9,0.5,7.5c6.2,3,10.3,9.9,18.2,9.8c2.2,0,5.2-0.1,5,3
		c-0.2,2.9-3.2,2.5-5.4,2.6c-1.9,0.1-3.8-0.1-5.7,0.1c-2.1,0.2-5.1-0.6-5.5,2.3c-0.4,3,2.4,4.4,4.9,4.9c2.8,0.5,5.7,0.4,8.6,0.5
		c13.4,0.5,26.7-0.6,40.1,1.7c7.8,1.3,15.9,0.3,23.9,0.3c2.5,0,5.8,0,6-3.3c0.2-3.2-3.2-2.3-5.1-2.8c-5-1.4-10.1-1.2-15.1-1.8
		c-3.9-0.4-10.9,2.8-11-3.1c-0.1-4.3,6.9-2.2,10.8-2.5c13.6-1.1,18-4.8,18.1-16c3.8-5.1,10.1-8.1,11.6-15c3.7,0.2,7.4,0.4,11.1,0.6
		c2,2.5,6.1,3.2,6.2,7.3c-3.7,2.7-2.7,7-3.1,10.6c-2.7,23.3-5.1,46.6-7.7,70.6c-2.6,0.1-4.3-6-7.9-2.3c-7.9-4.5-16.4-6.2-25.6-6
		c-11.1,0.2-22.2,0.1-33.3-1.5c-11.6-1.7-23.4-2.2-35.1-2.2c-12,0-18.5,8.6-16.3,20.3c1.4-1.7,2.8-3.4,4.1-5.1l0.2,0.1
		c1.2,3.7-5.3,8.7,1.8,11.4c-0.1,4.4,1.4,6.3,5.6,3.3c4.7-3.4,10.2-3.7,15.6-3.2c23.3,2.1,46.8,2.6,69.8,7.7
		c2.8,4.2,5.5,8.4,8.3,12.5c0,1.6-0.1,3.3-0.1,4.9c-14.9,1.9-16.5,3.3-15.7,13.3c-7.9-7.6-15.7-15.3-27.8-15.5
		c-5.3-0.1-10.9-0.6-15.9-2.4c-16.1-5.9-30.6-6.9-41.3,9.7c-0.1,0.2-0.6,0.3-0.8,0.4c-1.9,1.2-6,1.6-4.9-0.4
		c5.2-9.2-1.7-16.3-3.2-24.1c-3-15.4,0.4-31.1-3.5-46.3c8.6,1.3,4.4,9,6.4,14c8.2-4.8,16.4-8.3,25.6-5.2c6.8,2.3,13.7,2.2,20.8,2.4
		c23.5,0.8,47.3-2.7,70.4,3.8c0.4,0.4-0.6,1.8,1,1.4c-0.3-0.5-0.7-0.9-1-1.4c-2.7-6.9-8.4-8-14.8-7.8c-26.4,1.2-52.6-2.3-78.9-2
		c-7.1,0.1-13.8-0.8-17-9.1c-2-5.1-6.6-6.4-11.9-4.1c-0.9-4.7-1.9-9.5-2.8-14.2c1-10.6,2-21.2,3-31.9c3.7-1.1,3.6-4.1,3.5-7.1
		c2.5-2.8,5.8-4.4,9.4-5.3c13.2-0.6,13.2-0.6,13.6-13.7c0-1.6,0-3.2,0-4.7C100.3,33.4,100.8,32.1,100.8,30.6z M86.9,174.8
		c6.6-2.5,12.9-7.1,19.7-5.7c19,3.8,38.8,0.6,57.3,7.9c2.8,1.1,5.7,2.2,9.4-1.1c-4.9-2.2-8.7-5-12.8-5.6
		c-19.8-2.8-39.6-5.8-59.7-5.6C94.4,164.8,89.6,168.5,86.9,174.8z"/>
	<path fill="#83817D" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M64.5,235.8c-0.6,0.6-1.1,1.3-1.7,1.9
		C62.5,236.3,63.2,235.8,64.5,235.8z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M81.4,212.9c-4.3-8.1,3.4-15.2,1.8-23.1
		c0.3-0.1,0.7-0.2,0.8-0.4c10.6-16.6,25.2-15.6,41.3-9.7c4.9,1.8,10.6,2.3,15.9,2.4c12.2,0.2,19.9,7.9,27.8,15.5
		c1.2,3.3,2.3,6.7,3.5,10c0.4,3.7,0.8,7.3,1.3,11c-0.7,3.8-1.5,7.6-2.2,11.4c-5.9-4.1-8.7-12.2-17-13.3c-0.8-7.4-3.5-13.4-11.4-15.4
		c-1.2-4.2-6.1-1-7.8-4c-8.9,2.9-17.7,1.2-26.6-0.7c-6.9-1.5-11.8,3.2-17,6.9C88,206.1,86.4,211.3,81.4,212.9z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M193.5,151.7c-0.2,1.8-0.4,3.6-0.5,5.3
		c-2.6-0.6-5.2-1.3-7.7-1.9c-5.9-5.3-12.9-7.5-20.8-7.9c-12.9-0.7-25.8-2.6-38.7-3c-14-0.5-28.4-3.7-41.9,3.3c0,0-0.2-0.1-0.2-0.1
		c2-7.5,7.3-9.5,14.4-9.6c21.2-0.1,42.3,2.6,63.6,2.6c10,0,21.2,0.2,29.6,8C191.9,149.5,192.7,150.6,193.5,151.7z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M191.2,148.4c-8.5-7.8-19.6-8-29.6-8
		c-21.3,0-42.4-2.7-63.6-2.6c-7.1,0-12.4,2.1-14.4,9.6c-1.4,1.7-2.8,3.4-4.1,5.1c-2.1-11.8,4.3-20.4,16.3-20.3
		c11.7,0,23.5,0.6,35.1,2.2c11.1,1.6,22.2,1.7,33.3,1.5c9.2-0.1,17.7,1.5,25.6,6C190.9,143.9,191.9,146,191.2,148.4z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M154.6,216.5c8.3,1.1,11.1,9.2,17,13.3
		c1,5.1-1.3,9.4-3.5,13.7c-1.7,7-7.1,8.6-13.3,9.2c0.3-6.2-2.9-11.5-4.3-17.3C155.1,229.8,158.4,223.9,154.6,216.5z"/>
	<path fill="#25292F" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M154.6,216.5c3.8,7.4,0.5,13.3-4.1,18.9
		c-2.4,1.5-4.7,3-7.1,4.5c-1-0.1-2-0.1-3-0.2c11-12.9,10.9-25.8,2.7-38.6C151.1,203.1,153.8,209.1,154.6,216.5z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M179.4,70.1c-0.1,11.2-4.4,14.9-18.1,16
		c-3.8,0.3-10.9-1.8-10.8,2.5c0.1,5.8,7.1,2.7,11,3.1c5.1,0.5,10.2,0.3,15.1,1.8c2,0.6,5.3-0.3,5.1,2.8c-0.2,3.3-3.5,3.3-6,3.3
		c-8,0.1-16.1,1-23.9-0.3c-13.4-2.2-26.7-1.2-40.1-1.7c-2.9-0.1-5.8,0-8.6-0.5c-2.5-0.5-5.3-1.9-4.9-4.9c0.4-2.9,3.4-2.1,5.5-2.3
		c1.9-0.2,3.8,0,5.7-0.1c2.2-0.1,5.2,0.3,5.4-2.6c0.2-3-2.8-3-5-3c-7.9,0.1-12-6.8-18.2-9.8c-3.4-1.7-1.9-4.9-0.5-7.5
		c1.4-2.6,3.2-3.2,6.3-2.2c10.9,3.6,11,3.4,9.2-8c-2.7-17.1,1.9-26.5,16.9-34.4c5.2-2.7,10.7-1.9,15.7,0.3
		c7.8,3.3,13.5,9.7,18.9,15.7c4.6,5.1,1.7,12.5-2.8,16.4c-8.1,7.2-3,15,0.4,19.4c4.3,5.6,11.5,1.9,16.7-2.4
		C175.1,71.2,177.2,70.6,179.4,70.1z"/>
	<path fill="#060403" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M186.8,40.1c1.6,1.3,1.8,2.6,0.2,4
		C187,42.7,186.9,41.4,186.8,40.1z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M71.7,119c0.2-2.6,0.4-5.3,0.6-7.9
		c5.2-2.3,9.9-1,11.9,4.1c3.2,8.3,9.9,9.1,17,9.1c26.3-0.3,52.6,3.2,78.9,2c6.4-0.3,12.1,0.9,14.8,7.8l0,0
		c-23.2-6.5-46.9-3-70.4-3.8c-7-0.3-13.9-0.1-20.8-2.4c-9.1-3.1-17.3,0.4-25.6,5.2C76,128,80.2,120.3,71.7,119z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M83.7,147.5c13.5-7.1,27.9-3.8,41.9-3.3
		c12.9,0.5,25.8,2.4,38.7,3c7.9,0.4,14.9,2.6,20.8,7.9c0.8,2.9,3.2,5.7,0.3,9.3c-3.8-2.6-7-6.3-11.8-7.1c-3.5-7.7-10.7-3.8-16-4.5
		c-18.2-2.4-36.5-3.9-54.8-5.2c-6.9-0.5-13.5,1.2-15.5,9.4l0.1-0.1c-0.7,0.7-1.3,1.3-2,2l0.1-0.1C78.4,156.2,85,151.2,83.7,147.5z"
		/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M176.6,166.7c-23-5.1-46.5-5.6-69.8-7.7
		c-5.4-0.5-10.8-0.2-15.6,3.2c-4.2,3-5.7,1.1-5.6-3.3c0,0-0.1,0.1-0.1,0.1c0.7-0.7,1.3-1.3,2-2c0,0-0.1,0.1-0.1,0.1
		c11.2-4.3,22.6-4,34.5-2.9c16.6,1.5,33.1,5.2,49.9,4.8C173.4,161.5,175,164.1,176.6,166.7z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M86.9,174.8c2.8-6.3,7.5-10,13.9-10.1
		c20.1-0.2,39.9,2.8,59.7,5.6c4.1,0.6,7.9,3.4,12.8,5.6c-3.6,3.3-6.5,2.2-9.4,1.1c-18.4-7.3-38.3-4.1-57.3-7.9
		C99.8,167.7,93.5,172.3,86.9,174.8z"/>
	<path fill="#83817D" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M176.6,166.7c-1.6-2.6-3.2-5.1-4.8-7.7
		c0.6-0.5,1.2-1.1,1.9-1.6c4.8,0.8,8,4.5,11.8,7.1c2.9-3.7,0.5-6.4-0.3-9.3c2.6,0.6,5.2,1.3,7.7,1.9c5.6,14.1,3.4,20.1-8.1,22.2
		C182.1,175,179.4,170.9,176.6,166.7z"/>
	<path fill="#83817D" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M184.8,184.1c0.6,5,0.6,4.8-4.1,7.9
		c-0.7,0.4-0.3,2.6-0.4,3.9c-0.4,5.3-0.1,11-7.8,11.5c-1.2-3.3-2.3-6.6-3.5-10C168.3,187.5,169.9,186.1,184.8,184.1z"/>
	<path fill="#D0D5D2" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M194.9,134c0.3,0.5,0.7,0.9,1,1.4
		C194.3,135.8,195.3,134.4,194.9,134C194.9,134,194.9,134,194.9,134z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M173.7,157.4c-0.6,0.5-1.2,1.1-1.9,1.6
		c-16.8,0.4-33.3-3.2-49.9-4.8c-11.9-1.1-23.2-1.5-34.5,2.9c2-8.3,8.6-9.9,15.5-9.4c18.3,1.3,36.6,2.8,54.8,5.2
		C163,153.5,170.2,149.6,173.7,157.4z"/>
	<path fill="#B8BCBA" stroke="#333333" stroke-width="2" stroke-miterlimit="10" d="M87.5,157c-0.7,0.7-1.3,1.3-2,2
		C86.2,158.3,86.8,157.7,87.5,157z"/>
</g>
</svg></div>
)}}
