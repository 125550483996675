import React from "react";

export default class OveralSceneSVG extends React.Component {
	constructor(props) {
		super(props)
		this.state = { 
			allWindows: true,
			allWindows2: true
		}
	}

	componentDidMount(){
		setInterval(()=>{
			setTimeout(() => {this.setState({allWindows: !this.state.allWindows})},500)
			setTimeout(() => { this.setState({ allWindows: !this.state.allWindows }) }, 800)
			setTimeout(() => { this.setState({ allWindows: !this.state.allWindows }) }, 2000)
			setTimeout(() => { this.setState({ allWindows: !this.state.allWindows }) }, 2300)
			setTimeout(() => { this.setState({ allWindows: !this.state.allWindows }) }, 2400)
			setTimeout(() => { this.setState({ allWindows: !this.state.allWindows }) }, 2700)

			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 2500)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 2800)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 4000)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 4300)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 4400)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 4700)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 4900)
			setTimeout(() => { this.setState({ allWindows2: !this.state.allWindows2 }) }, 5000)
		},
		9000)
	}

	render() {
		let noteBookXY = (this.props.selectedDresses.hat && this.props.selectedDresses.shoes && this.props.selectedDresses.pants) ? 'M764.7,2694.8' :'M864.7,2994.8'
		
		//окна
		let color1 = this.props.quizStepComplete > 0 ?'#FC8521':(this.state.allWindows ? '#001' : '#FCEE21')
		let color2 = this.props.quizStepComplete > 1 ? '#FC9521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color3 = this.props.quizStepComplete > 2 ? '#FCa521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color4 = this.props.quizStepComplete > 3 ? '#FCa521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color5 = this.props.quizStepComplete > 4 ? '#FCb521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color6 = this.props.quizStepComplete > 5 ? '#FCEE21' : '#001'
		let color7 = this.props.quizStepComplete > 6 ? '#FCb521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color8 = this.props.quizStepComplete > 7 ? '#FCc521' : (this.state.allWindows ? '#001' : '#FCEE21')
		let color9 = this.props.quizStepComplete > 8 ? '#FCEE21' : '#001'
		let color10 = this.props.quizStepComplete > 9 ? '#FCEE21' : '#001'
		let color11 = this.props.quizStepComplete > 10 ? '#FCc521' : (this.state.allWindows2 ? '#001' : '#FCEE21')
		let color12 = this.props.quizStepComplete > 11 ? '#FCd521' : (this.state.allWindows2 ? '#001' : '#FCEE21')
		let color13 = this.props.quizStepComplete > 12 ? '#FCd521' : (this.state.allWindows2 ? '#001' : '#FCEE21')
		let color14 = this.props.quizStepComplete > 13 ? '#FCe521' : (this.state.allWindows2 ? '#001' : '#FCEE21')
		let color15 = this.props.quizStepComplete > 14 ? '#FCe521' : (this.state.allWindows2 ? '#001' : '#FCEE21')
		let color16 = this.props.quizStepComplete > 15 ? '#FCf521' : (this.state.allWindows2 ? '#001' : '#FCEE21')

		//цвет огня
		let colorF1 = (this.state.allWindows2 || this.state.allWindows) && this.props.kz ? '#B33600' : 'transparent'
		let colorF2 = (this.state.allWindows2 && this.state.allWindows) && this.props.kz ? '#F7931E' : 'transparent'
		let colorF3 = this.state.allWindows2 && this.props.kz ? '#FFFF00' : 'transparent'

		let colorKz = this.props.kz ? '#FFFF00' : '#000'

		return (    
        <div style={{padding: 0, marginTop: 0, marginLeft: 0, height: "100%", weight: "100%"}}>	

<svg version="1.1" id="Слой_8"  x="0px" y="0px" viewBox="0 0 2834.6 2834.6" enable-background="new 0 0 2834.6 2834.6">
<switch>

	<g>
		<g>
			<defs>
				<rect id="SVGID_1_" x="3.1" y="0" width="2834.6" height="2834.6"/>
			</defs>
			<clipPath id="SVGID_2_">
				<use overflow="visible" href="#SVGID_1_"/>
			</clipPath>
			<rect x="3.1" clip-path="url(#SVGID_2_)" fill="#1B1464" width="2834.6" height="2834.6"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M349,712.6c0.9,106.8,3.3,213.4,24.4,317.9c-135.9,1.3-271.9-7.8-406.4-27.5
				c17-184.9,16.8-370.7,24.9-556.2c115.5-4.7,230.5-21.1,342.7-48.8c27.2,199.9,21.6,406.7,20.7,607.9c0,8.2,0.1,16.9,4.5,23.8
				c7.2,11.3,22.4,13.5,35.8,14.4c86.2,5.7,173.1,1.3,258.2-13.2c-17.4-92.1-24.5-186.1-21.1-279.7c-99.1-7.4-198.2-16.1-297.1-26
				c-10.2-1-20.5-2.1-30.8-1.7"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M706.7,181.7c94.1,32.1,196.8,38.6,294.2,18.4
				c8.3,274.7,16.5,549.4,24.8,824.2c0.1,3.8,0.1,8-2.1,11.1c-3.3,4.4-9.6,4.8-15.1,4.7c-75.3-1.1-160.7,8.9-234.8-3.7
				c-53.9-9.2-54.2-53.4-58-100.2c-13-161.2-18.1-323.2-17-484.8C698.7,426.7,715.2,184.6,706.7,181.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M414.7,486.5c-0.3-7.7,2.1-18.3,9.8-17.8c7,0.4,8.9,9.9,8.8,16.9
				C431.7,572,438,658.5,452,743.7c-34.8-15.5-25.4-78-28-110.1C420,584.7,416.9,535.6,414.7,486.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M539.6,738c-1.2-103.4,0-206.8,3.6-310.2c73.4,3.2,146.8,6.4,220.2,9.6
				c2.5,0.1,5.2,0.3,7.3,1.6c4.4,2.8,4.7,9,4.5,14.2c-7.7,194.5,14.4,390.2,65.2,578.1c-89.1,7.7-178.2,15.3-267.3,23
				c-4.4,0.4-9.3,0.7-12.9-1.9c-4.7-3.3-5.3-9.9-5.4-15.7c-4.8-151.7-9.5-303.3-14.3-455"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M1005.3,1015.7c-6.3-150.8-4.5-302,5.4-452.6c0.4-6.7,1.6-14.6,7.5-17.9
				c5.6-3.2,12.6-0.4,18.6,2.1c94.6,39.1,200.8-0.4,301-21.3c9.2-1.9,20.5-3,26.5,4.4c4,4.9,3.8,11.9,3.6,18.2
				c-7.2,161,4.7,322.8,35.2,481c-86.3,5.8-172.5,11.6-258.8,17.3c-36.3,2.4-74,4.7-107.9-8.7c-33.9-13.3-62.7-47-58-83.1"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M1221.5,709c-6.9-162.1-13.7-324.7,1.3-486.2c0.6-6.5,1.7-13.9,7-17.7
				c3.7-2.6,8.5-2.9,13-3.1c102.2-3.8,204.3-7.6,306.5-11.4c5.6-0.2,11.8-0.2,15.9,3.6c4.7,4.3,4.6,11.7,4.2,18
				c-19.2,290.4-23.7,581.8-13.3,872.6c0-0.1-159.8-0.6-174.5-0.7c-36.2-0.1-128.1,16.5-144.8-17.1c-9.4-19-2.9-61.8-3.8-83.3
				c-1.3-29.7-2.5-59.3-3.8-89C1226.8,832.8,1224.1,770.9,1221.5,709z"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M1724,1022.2c-73.6-306.2-63.5-632,29-933c23.4,54.2,29.2,114.1,34.7,172.9
				c20.7,221.6,41.4,443.2,62.1,664.8c4,42.5,7.8,86.2-4,127.2c-1.1,3.8-8.3,5.8-6.7,2.2"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M1971.5,1044.1c-15.1-168.1-19.7-337.2-13.9-505.8c0.1-2.6,0.3-5.6,2.4-7.2
				c1.7-1.4,4.1-1.4,6.2-1.3c53.1,1.1,106.3,2.2,159.4,3.3c7.3,175,14.5,350,21.8,525.1c-10.2-6.5-20.5-13.1-30.7-19.6"/>
			<path clip-path="url(#SVGID_2_)" fill="#2E3192" d="M1827,212.3c13.9,9.3,20.6,26,26.6,41.6c27.9,73.4,56,147.1,71.8,224
				c36.7,179-12.3,355.2,26.2,535.2c1,4.8,2.2,9.8,5.6,13.4c4.6,4.8,11.9,5.6,18.5,6.1c78.6,5.7,157.2,11.4,235.8,17.1
				c8.3,0.6,18.7,0.1,22.3-7.5c2.2-4.7,0.6-10.3-1-15.3c-52-166.1-17.3-345.8-31.6-519.2c-39.6,37.4-81.1,72.9-124.2,106.2
				c-20.6,15.9-41.6,31.4-63,46.4c-15,10.5-50.7,25.1-60.1,40.1c-18.9,30.5,1.1,112.6,3.2,149.2c3.1,53.4,6,106.8,9.3,160.1
				c0.3,5.1,0.4,10.7-3.1,14.4c-3.3,3.5-8.6,3.9-13.5,4.1c-43.4,1.8-143.8,26.4-177.6-8.2c-27.5-28.1-20.1-102.1-23.2-137.8
				c-12.4-145.1-5.9-291.7,19.1-435.2C1772.2,424.6,1831,215.1,1827,212.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M2332.7,259.8c59.6,23.6,125.8,22.1,189.9,19.9c7.3-0.3,15.8,0,20.2,5.8
				c3.6,4.7,3.1,11.2,2.4,17.1c-29.9,258.6-26.8,521.1,9.2,778.9c-59.3-1.3-109.1-38.4-165.2-52.2c-21.8-5.3-42.4-1.9-54.4-22.9
				c-12-20.9-3.3-65.8-3.4-88.9c-0.7-141.9-0.8-283.7-0.2-425.6C2331.3,469.2,2344.6,264.5,2332.7,259.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M1989.7,775.7c159.1-0.9,317.5-32.6,476.3-23.4
				c-9.9,106.5-19.7,212.9-29.6,319.4c-66.1,15.5-135,3.3-202.4-4.3c-252.9-28.5-508.3,9.5-762.8,7.1c13.4,0.1,24.9-197.1,26.8-216
				c4.5-44.9,17.7-73,63.6-84.7C1696.2,739.6,1852.7,776.4,1989.7,775.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M2547,1022.2c-14.5-162-21.7-324.6-21.7-487.3
				c58.9,22.4,122.6,32.1,185.5,28.2c6-0.4,12.5-0.7,17.4,2.8c6.8,4.9,7.2,14.7,6.9,23.1c-5.1,150.1-5.4,302.4,32.7,447.6
				c-75.2,14.1-152.3,17.6-228.4,10.3c1.4-34.9-4.2-70-16.3-102.7"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M2924.8,1076c-22.4-238.8-44.7-477.7-67.1-716.5c-0.9-9.5-2-19.7-8.1-27.1
				c-7.8-9.6-21.4-11.7-33.7-13.2c-40.8-4.9-81.5-9.8-122.3-14.7c-7,37.8-8.7,76.4-9.5,114.8c-3,145.6,7.5,291.4,31,435
				c11.5,70.1,20.6,148.7,45.6,215.4c13.4,35.9,22.9,26.1,57.9,21.8C2854.5,1087.1,2889.5,1085.2,2924.8,1076z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1095.8,375.5c79.6,9.2,159.8,12.4,239.9,9.6c4.9,113.4-5.4,226.6-11.4,339.8
				c-6.1,114.4,4.3,222.8,11.9,336c-86.2,29.4-205.2,38.7-226.6-69.4c-24-121.5-8.6-259.2-10.4-382.7
				C1098.9,589.1,1100.2,376,1095.8,375.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1475.6,1081.8c-40-331.2-46-666.4-18.1-998.8
				c77.9,19.7,159.2,25.3,239.1,16.4c-9.9,127.9,6.5,256.2,10.1,384.4c5.4,194.4-18.6,390.7,13.8,582.5
				c-83.9,12.2-169.8,10.3-253.1-5.6c-5.7-11.6-11.4-23.2-17-34.9"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M144.4,635.3c-0.1-6.4,0-13.4,4.1-18.3c3.6-4.3,9.3-6,14.7-7.4
				c79.1-20.2,162.5-23.7,243.1-10.2c2.4,158.9,18.4,317.6,47.9,473.7c-3-16.1-158.7-32.2-177.5-34.6
				c-41.9-5.4-101.9,5.7-117.9-41.9c-16.2-48.2-7.3-115.8-9.2-166.2C147.2,765.4,145.5,700.4,144.4,635.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1271,601.2c79.8-0.1,159.5-0.3,239.3-0.4c6.4,157.2,19.3,314.2,38.6,470.4
				c-68.4,2-236.7,21.4-258.3-68.6C1282.5,968.9,1272.7,601.2,1271,601.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M2085.8,676.6c58-2.3,116-4.6,174-6.9c11.4,125.3,26.3,250.3,44.5,374.9
				c-2.4-16.4-203.7,36.3-219.5-43.4C2065,900.7,2080.7,778.4,2085.8,676.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1667.2,890.7c140.9-19.9,283.7-25.8,425.7-17.7c10.7,0.6,22.9,2,29.4,10.6
				c3.8,5.1,4.8,11.7,5.6,18c7,53.2,11.8,106.8,14.3,160.4c-149.4,12.2-299.9,11.4-449.2-2.5c-5-0.5-10.3-1.1-14.1-4.3
				c-4.4-3.7-5.8-9.9-6.7-15.6c-11.5-69-8.3-140.4,9.2-208.1"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M2244.2,576.4c96.7,10.9,193.5,21.7,290.2,32.6c5.8,0.7,12.2,1.6,16,6
				c3.4,3.9,3.8,9.5,4.1,14.6c7.8,142.6,15.5,285.3,23.3,427.9c0.2,3.4-184-4.8-200.6-6.8c-50.1-6.1-102.1-4.1-114.7-58.4
				C2231.8,860.4,2234.8,710.7,2244.2,576.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M2844.1,550.8c-33,1.3-66,2.7-99,4c-5.1,0.2-10.7,0.6-14.4,4.2
				c-4,3.9-4.2,10.1-4.1,15.7c1,102.7,2,205.3,2.9,308c-61.2,2.1-122.3,4.2-183.5,6.3c8.2,72,6.7,145.1-4.4,216.7
				c78.2,3.5,161.7,7.6,239.5-3.3c50.2-7,71.5-30.9,76.5-81.2C2861.9,980,2850.9,550.5,2844.1,550.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M807.8,818.9c107.2,6.4,214.4,19.7,320.3,36.9
				c9.3,55.9,18.6,111.7,27.8,167.6c0.9,5.4,1.8,11.2-0.5,16.2c-3.8,8.3-14.2,10.9-23.2,12.4c-74.5,12.3-149.6,21.4-224.9,27.4
				c-34.7,2.7-69.8,4.8-104-1.4c-8.3-1.5-16.9-3.7-23-9.5c-5.2-4.9-7.9-11.9-10.3-18.6c-16.9-47.5-26.4-97.6-28.3-147.9
				c-1-27.4-8.5-63.8,19.1-79.9c15.2-8.8,34.6,1.4,49.3-5.2c20.5-9.1,41.7-45.2,54.1-63.1c25.5,31.7,47.8,66,66.3,102.3
				c22-25.6,51.5-63.6,73.5-89.2c1.6-1.9,3.7-3.9,6.1-3.6c2.3,0.3,3.7,2.6,4.8,4.6c17.7,33.1,35.4,66.1,53.1,99.2"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M-69.6,1084.6c7.4-78.5,14.8-157.1,22.2-235.6c0.2-2.4,0.5-5,2-6.9
				c2.5-3.1,7.1-3.2,11.1-3.1c57.9,2.5,115.8,5,173.6,7.5c6.3,0.3,13.1,0.7,18.3,4.3c4.4,3.1,7,8.1,9.3,12.9
				c20.5,41.7,36.3,85.7,47.2,130.9c1.7,7.1,2.5,16.4-3.8,20.2c-3.7,2.3-8.4,1.5-12.7,0.7c-91.2-16.7-185.5-16.3-276.6,1
				c-11.4,2.2-24.1,4.4-34-1.7"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M404.8,1062.9c-14.4-46.9-24.2-95.1-29.1-143.9c59.9-2.8,119.8-5.6,179.8-8.3
				c2.7-0.1,5.7-0.2,8,1.3c2.3,1.5,3.4,4.4,4.3,7c9.5,27.3,18.4,54.8,26.8,82.4c1.7,5.6,3.2,12.3-0.4,16.9c-2.1,2.7-5.5,4.1-8.8,5.3
				c-24.9,8.9-51.2,13.8-77.6,14.4c-48,1.2-97.2-11.3-143.4,1.7"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M2382.5,1882.3c1.4,0,1.4,2.7,0,2.7"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M148.4,448.4c-2.1,0.4,0.1,4.1,2,3.2s2.2-3.5,2.2-5.7
				c-0.4-35.9-0.9-71.7-1.5-107.6c8-0.2,16-0.4,24-0.6c2.5-0.1,5.2,0,7.1,1.6c2.1,1.9,2.2,5.1,2.1,7.9c-0.9,35.3-1.7,70.6-2.6,105.9
				c-12.2,0.3-24.5,0.5-36.7,0.8"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M223.4,305.7c11.7,3.6,23.3,7.2,35,10.7c2.1,24.6,26.8,131.6-8.1,121.3
				C200.9,423.1,240.8,311,223.4,305.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M771.3,53.3c-5,61.1-8.6,122.3-10.8,183.6c42.8,4.7,86-1,128.7-6.8
				c-4.3-26.2-6.6-52.7-6.8-79.3c10.6-0.2,21.3-0.4,31.9-0.6c-0.6,0-0.2,63.9-0.6,70.1c-2.3,31.3,2.7,26.7-26.3,34
				c-28.4,7.1-86.1,14.8-112.3-3.6C730.9,219.5,743.8,98.8,749.4,55C757,53,763.3,53.5,771.3,53.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M2349.5,616.3c3.9-22.2,7.7-44.3,11.6-66.5c0.5-2.6,1-5.5,2.9-7.4
				c2.7-2.7,7-2.6,10.8-2.3c18.1,1.4,36.2,2.9,54.3,4.3c0.2,24.3,0.3,48.6,0.5,72.9c12.3-0.6,24.7-1.1,37-1.7c3-0.1,6.3-0.5,8.1-2.8
				c1.5-2,1.5-4.7,1.3-7.2c-1-17.4-2.1-34.8-3.1-52.2c21.1,2.2,42.6,0.6,63.1-4.8c10.9-0.5,15.2,13.7,16,24.6
				c1.9,25.5,3.8,51.3,0.2,76.6c-65.9-6.1-131.8-12.1-197.8-18.2c-2.1-0.2-4.3-0.5-5.8-2c-1.7-1.8-1.6-4.6-1.3-7.1
				c4.3-42.8,8.5-85.7,12.8-128.5"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M2407.8,309.6c24.9-3.8,49.7-8.3,74.4-13.5c0.2-21.2,0.4-42.3,0.6-63.5
				c7.3,12.3,29.6-9.6,39.6,0.7c-1.9,29.4-3.9,58.9-5.8,88.3c-0.3,4.7-0.8,9.7-3.9,13.2c-3.6,3.9-9.4,4.6-14.8,4.9
				c-33.6,1.9-133.8,17-144.6-26.2c-4-15.8,10.2-39,14.8-53.6c8.2-26.3,9.4-50.3,12.9-76.9c11.6-0.8,23.7,0.8,34.8,4.1
				C2416.5,197.7,2412.2,309,2407.8,309.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M2738.6,347.4c3.4-39.4,4.4-79,3.1-118.5c10.5,0,21,0,31.4,0
				c-2.3,43-6,85.9-11.2,128.6c-7.1-6.6-16.7-10.3-26.4-10.1"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" d="M1291.9,228.6c0-15.6,0-31.3,0-46.9c30.9-3.9,65.3-13.7,96.4-12
				c4.1,22.4,5,45.5,2.8,68.2C1354.8,235.2,1328.1,231.3,1291.9,228.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1687.6,146c-40-5.9-79.5-14.8-119.1-23.2c-24.5-5.2-62.2-2-41.7-29.8
				c13.6-18.4,60.1-25.5,78.7-18.4C1634.8,85.9,1657.2,140.3,1687.6,146z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M1299,349.9c3.5-1.8,7.5-3.6,11.3-2.6c5,1.3,7.6,6.7,9.5,11.5
				c8,20.2,16.1,40.3,24.1,60.5c-36.8-0.6-196.5,17.6-201.8-22.2c-1.9-13.9,16.1-42.3,28.7-47.8c30.3-13.2,46.8,23.9,69.2,40.5
				C1259.7,376.8,1277.8,361.5,1299,349.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M584.9,457c1.7-32.7,1.2-65.6-1.7-98.2c19.5-0.7,39.1,2.1,57.7,8.2
				c2,27.6,4,55.1,6,82.7c0.2,2.9,0.3,6.1-1.6,8.2c-2,2.3-5.4,2.5-8.5,2.5c-20.2-0.1-40.3-1.5-60.3-4.4"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" d="M219.7,534.4c-0.2-2.6-0.2-5.5,1.5-7.5c1.5-1.7,3.8-2.2,6-2.6
				c18.8-3.6,37.6-7.3,56.4-10.9c0.7-0.1,1.4-0.3,2.1-0.4c-0.9,26.6,18.3,103.7-17.5,100.9C235,611.3,221.2,559.2,219.7,534.4z"/>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M351.8,122.3c-2.9-5.4,2.3-11,7.7-11.4c3.9-0.3,13.6,1.9,13,7.1c-0.4,3.9-3.7,9.4-7.1,11.1
						c-4,2.1-9.6,0.5-10.5-4.2c-0.3-1.4-3.2-0.6-2.9,0.8c1.1,5.8,7.8,7.6,12.8,6c7.3-2.2,9.7-10.5,11.5-17c0.1-0.2-0.1-0.6-0.4-0.8
						c-6.3-3.4-14.3-7.4-21.4-3.8c-5,2.5-8.2,8.3-5.4,13.6C349.8,124.8,352.6,123.7,351.8,122.3L351.8,122.3z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M973.6,78.2c0.5-1.8,1.2-3.5,2.4-5c0.8-1,2.3-2.5,3.7-1.6c0.8,0.6,1.1,1.8,1.2,2.7c0,0.5-0.1,1-0.2,1.5
						c0,0.1-0.3,0.4-0.2,0.5c0-0.3,0.9,0,0.1-0.3c-1-0.4-3.4,1.1-1.7,1.7c4.5,1.6,6.6-4.6,3.5-7.3c-1.9-1.6-5-0.8-6.8,0.4
						c-2.6,1.7-4,4.8-4.8,7.7C970.3,79.7,973.2,79.3,973.6,78.2L973.6,78.2z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M515.5,222.8c2.5-4.4,6.5-7.6,11.4-9c-0.5-0.1-1.1-0.3-1.6-0.4c0.9,1.1,0.9,4.2-1.3,4
						c-1.5-0.2-2.9,2-1,2.2c3.8,0.4,7.8-4.1,4.9-7.6c-0.4-0.5-1-0.6-1.6-0.4c-5.8,1.7-10.6,5.4-13.6,10.7
						C511.9,223.8,514.8,223.9,515.5,222.8L515.5,222.8z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M564.4,123.2c2.2-1,4.6,0.6,6,2.3c1.5,1.8,2,4.1,1,6.3c-0.4,0.9,2.5,0.6,2.9-0.4c1.3-3,0-6.3-2.5-8.3
						c-2.5-2-5.7-2.5-8.6-1.2C561.4,122.8,563.1,123.8,564.4,123.2L564.4,123.2z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2098,92.2c4.2,0,8.3,0,12.5,0c1.6,0,2.2-2.1,0.3-2.1c-4.2,0-8.3,0-12.5,0
						C2096.6,90.1,2096,92.2,2098,92.2L2098,92.2z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M1943.9,247.4c2.1-4.3,5.9-7.7,10.7-8.6c1.8-0.3,4.9-0.6,6.5,0.4c2.8,1.6,0.2,7-2.8,5.3
						c-0.8-0.5-3.2,0.7-2.7,1c2.5,1.5,5.7,0.8,7.8-1.2c1-0.9,1.8-2.1,1.9-3.4c0.2-2.6-1.3-2.9-3.4-3.3c-8.2-1.7-17.3,2.7-20.9,10.2
						C1940.7,248.5,1943.5,248.2,1943.9,247.4L1943.9,247.4z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2169.3,290.2c-2.1-3.9,0.2-11,5.7-9c2,0.7,4.4,2.7,3.3,4.9c-1.3,2.6-4.6,4.3-7.4,4.2
						c-0.6,0-1.6,0.1-2,0.5c-0.3,0.3-0.5,0.8,0.2,0.8c3.6,0.1,7.3-1,10-3.5c1.1-1,3-2.9,2.5-4.4c-0.5-1.6-2.3-2.9-3.7-3.5
						c-6.6-3-15.4,3.5-11.6,10.7C2166.8,291.8,2169.6,290.8,2169.3,290.2L2169.3,290.2z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2318.6,122.8c0,1,0,2.1,0,3.1c0,0.8,2.9,0.3,2.9-0.8c0-1,0-2.1,0-3.1
						C2321.4,121.2,2318.6,121.7,2318.6,122.8L2318.6,122.8z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2626.1,202c-1-3.3-0.3-7.2,2.3-9.7c3.2-3.1,7.3-0.9,8.9,2.7c1.3,2.9,0.2,7.8-3.9,5.9
						c-2.4-1.1-4.4-4-2.7-6.5c0.8-1.1-2.1-1-2.7-0.1c-2.8,3.9,1.4,8.2,5.3,8.8c4.6,0.7,8.4-3.2,7.2-7.8c-1.3-5-6.6-7.5-11.3-5.7
						c-5.1,2-7.6,8-6,13.1C2623.6,203.8,2626.5,203,2626.1,202L2626.1,202z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2474.4,63.3c3.3-2,6.7-4,10-6.1c-0.8,0.1-1.7,0.3-2.5,0.4c1.6,2,2.7,4.4,2.7,7
						c0.1,3.1-1.6,7.1-5.4,6.6c-2.1-0.3-4.1-1.9-4.2-4.1c0-2.5,2.9-5.3,4.9-2.7c0.5,0.6,3-0.6,2.9-0.8c-4.6-5.8-16.2,5-7.4,8.4
						c3,1.2,7.3,0.3,9.7-1.9c3.8-3.5,2.5-9.5-0.4-13.1c-0.4-0.5-2.1,0.1-2.5,0.4c-3.3,2-6.7,4-10,6.1
						C2471.1,64,2473.9,63.6,2474.4,63.3L2474.4,63.3z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2619.5,63.9c1.2,0,3-1.8,1.1-1.8C2619.4,62.1,2617.5,63.9,2619.5,63.9L2619.5,63.9z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2714.8,103.4c0.2-2.2,0.4-4.3,0.6-6.5c0-0.5,0.1-2.4,0.3-2.5c0.4-0.2,1.3,0.5,1.6,0.6
						c1.7,1.2,3.1,3.7,1.3,5.4c-0.5,0.5,0.4,0.6,0.7,0.7c0.7,0,1.4-0.3,1.9-0.7c2.7-2.6-0.2-6.5-3.2-7.2c-1.6-0.4-3.6,0.1-4.8,1.2
						c-1,0.9-0.7,2.8-0.9,4c-0.2,1.8-0.4,3.7-0.5,5.5C2711.9,104.8,2714.8,104.2,2714.8,103.4L2714.8,103.4z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M2610.1,73.2c1,0,2.1,0,3.1,0c0.6,0,1.6-0.2,1.9-0.8c0.3-0.6-0.3-0.9-0.8-0.9c-1,0-2.1,0-3.1,0
						c-0.6,0-1.6,0.2-1.9,0.8C2608.9,72.9,2609.6,73.2,2610.1,73.2L2610.1,73.2z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M1300.6,91.8c3.1,0,6.3,0,9.4,0c0.9,0,3.1-1.3,1.2-1.3c-3.1,0-6.3,0-9.4,0
						C1301,90.5,1298.7,91.8,1300.6,91.8L1300.6,91.8z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#FFFFFF" d="M37.3,259.7c0-0.4,0.2-0.7,0.4-1c0.2-0.2,0.1,0-0.1,0c0.2,0,0.3,0,0.5,0c0.9,0.1,1.6,0.8,1.6,1.7
						c0.1,0.8,3,0.2,2.9-0.8c-0.3-3.8-7.9-2.8-8.2,0.6C34.3,261.1,37.2,260.5,37.3,259.7L37.3,259.7z"/>
				</g>
			</g>
			<rect x="1119.6" y="419.4" clip-path="url(#SVGID_2_)" fill="#FBB03B" width="40.5" height="40.5"/>
			<g clip-path="url(#SVGID_2_)">
				
					<image overflow="visible" width="456" height="456" href="data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/7AARRHVja3kAAQAEAAAAHgAA/+4AIUFkb2JlAGTAAAAAAQMA
EAMCAwYAAAcfAAAMGwAAEvr/2wCEABALCwsMCxAMDBAXDw0PFxsUEBAUGx8XFxcXFx8eFxoaGhoX
Hh4jJSclIx4vLzMzLy9AQEBAQEBAQEBAQEBAQEABEQ8PERMRFRISFRQRFBEUGhQWFhQaJhoaHBoa
JjAjHh4eHiMwKy4nJycuKzU1MDA1NUBAP0BAQEBAQEBAQEBAQP/CABEIAcgByAMBIgACEQEDEQH/
xAC2AAEAAwEBAQEAAAAAAAAAAAAAAQMEAgUGBwEBAQEBAQAAAAAAAAAAAAAAAAECAwQQAAECAwcD
BAICAQUAAAAAAAECAwAEBRAgUBExMjRAEjMwIRMGIjVBFCRgcJBCIxEAAQIDBAkCAgkEAwEAAAAA
AQACIBEDIXGxclAxQVFhgZESIhDBggQwQPChMmKywhNCUiMzcJKi0hIAAgICAgMBAAAAAAAAAAAA
AAEREkAiIQIgkDJh/9oADAMBAAIRAxEAAAD9AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
QJRQaHHRIAAACBIAAAAAAAAAAAAAAAAAAAETAPHHi3Z9JsyrPSt8iV92/wCa7j6i75MfZdfGXR9a
+X0H0DxdB6aJgAAAAAAAAAAAAAAAAABz155k82J0ii+iwdHMhEdQVOxwtFcWirRz0fYzE4oAAAAA
AAAAAAAAAAAAEef6Hn14Q1FF9BNF+JbLsumKdOXRREp33nwr6c1Upq7puX7CYnAAAAAAAAAAAAAA
AAAACPP9DBXgjUUX0EU3DNZaMOyZOZgMm2sYvQpls059FfXzE4AAAAAAAAAAAAAAAAAARg34K8KD
UnPfQAQkQkcEkygEi2q5frpicAAAAAAAAAAAAAAAAAAIw7sFeCNRRfQAAAcTEnQAJtqtX66YnAAA
AAAAAAAAAAAAAAACMO7DXgDUUX0CYkARMHEh0ASLqb1+tGAAAAAAAAAAAAAAAAAAEYd2E8Abii+g
TEgCJg4kOpiQCNGfQfWjFAAAAAAAAAAAAAAAAAAjDuwngDcUX0CYkARI4B1MSARpzaY+sGaAAAAA
AAAAAAAAAAAABGLbir58aii+gTEgCJHAOpiQCNWbVH1QzQAAAAAAAAAAAAAAAAETAx7MZ88NxRfQ
JiQADgHUxIA1ZdcfUjNAAAAAAAAAAAAAAAAAAjHsyHzo3FF9AmJAAOAdTEgDZj1y/UjIAAAAAAAA
AAAAAAAABEwMmvIfOjcUX0CYkAFpnmsXO6yUBtxbj6cYoAAAAAAAAAAAAAAAACJgZNeQ+dG4ovoE
xIBFVoqTJzYEgbsO8+mGKAAAAAAAAAAAAAAAAAiYGXVlPnBuKL6BMSImADiYkmQAndh3S/TDIAAA
AAAAAAAAAAAAABEwMurKfODcUX0CYExIRMHMxJIEg34N8v0oyAAAAAAAAAAAAAAAAARMDLqzHzY3
FF9AmJAETBzMSTMSAR6Hn+iv0gwAAAAAAAAAAAAAAAAARMDNpznzQ3FF9AmJAESOJiSZiQCPR870
l+jGAAAAAAAAAAAAAAAAACJgZtOc+aG4ovoExIABwDqQAj0/M9RfoRgAAAAAAAAAAAAAAAAABGfT
nPmRuKL6BMSARIcA7ABHq+V6q/QDAAAAAAAAAAAAAAAAAABn0Zz5kbii+gTEgAHAOwAR6vleqv0A
wAAAAAAAAAAAAAAAAAAUX0HzA3FF9AmJAAOAdTzWXOKi/wBbyfWX3xgAAAAAAAAAAAAAAAAAAovo
PmBuKL6Bz1Qdd0WHGjHrIBxXbUW03cF3r+R7C+8MAAAAABBKJAAAAAAAAAAAHHY+az/S/O6V0aM9
kVWiizsU3ByclhBKJHs+N7cvtjIAAQSgTAChL3k+rrPSGdygSAAAAAAAAABi2wfKZvoPC0qdLIju
4zx6WhfHj6DTHy931VkfM6PeHlbdCJAAAiYOfP0ZOnHtZRrGrNR6i+V7HkbLOFEWewzuPo1jOgAA
AAAAAAAI8n1x5ejYOOpAAAAEJAAACJgp8v2cHTjRbxbvnnu31Z6eXr1dp5d+vksdufboSgAAAAAA
AAAAAAAAAAAAAAICMprDUEjOwAAP/9oACAECAAEFAP8AQgGcAZWZCMhHbHbHbGR6XLrBr1g16wa9
YNesGvoHpBr6B6Qa+gekHonpB6J6QeiekHonTox6J06MeiekHonpB6J06MeidOjHonTrDp1h06w6
dYdOkB9A6XgMypBT6wOduYjujujMxndQkdoCCkhKA4O49gjL8unbJyV7I+RJCnAYK0HqU90K7rv/
2gAIAQMAAQUA/wCO4nIIWFdU4tXcS4FArWWz2jvVHd+HTupGacy58agUtEQG3BH8dO52wjtu/wD/
2gAIAQEAAQUA/wBwjOygWlxtWL1Gqfk6fyBIhMzMIKKrPphNdnEwj7AqE16WMJrEgoJnpNYStCsO
qNT7oy95nOA2+Cf7Yj5ZgQXymBNsZh5lUZhQ/wCwUUwmcm0QirVBMIr82IT9hMNVyVcODEgCoVT5
bZjUXNYU2hUNtoQtTfc78TogJmkkrmBHzLEGYbESriFP4NV1rRKAZWzGsBCiCki4nyDyXZfl4NWu
JbMaw9LziyhFRQpbrqE/35sFKu5KfIPIkZn4yYy9yhQsleZg1a4lsxrEyxIOOMSzbalJmFJIqySn
PJPkT5HwyWyxSVFA/wDMNVBJb+UJlOZg1Z4lsxrDzDbhak2W1vNfIgSjqSkEBPkT5HVhKDMSiiOw
t/HSVFgIS3Ke85g1Z4lgJ7piwiMoIjKAIT5E+QjMfGM8vZTCFFCAgSXNwas8QEGzL3mL6fInyRlZ
lGUSXNwas8QDK2Yvp8id92Q52DVjhwBkYmL6fInfdp/OwascS2Zvo3p33af7z+DVjh2zN9O9Pku0
33qGDVjh2zN9G9Pku0z9jg1X4dszfTvTvu0v3qODVfhWzN9G9O+7Sf2ODVfhWzOl5G9O8XaT71HB
qvwrZnS8jenfdo/7HBqtwrZrS8ncnfdo/wCxwarcK2a0vJ3J33aN71HBqrwbZrS8ncnddon7HBqr
wrZrS8jcncLtF/Y4NVeFbNaXk7k7rtD/AGGDVThWzW28ncnddof7DBqpwrZrbdyhG5G7K7Qufg1T
4Vs1pcETtQp4kWne8uOlAmajLTiwc7lA5+DVPhWzW24dC2CptOSssylsJgXKBzsGqfCtm9LhjKEb
kj3yu0DnYNU+FbN7byNU7rv17m4NUuFbNbbyNyd12gc3BqlwrZvbeRuTuu/XuZg1S4Vs3tvI1Tuu
/XuXg1R4Vs3tvI1Trd+vD/Lwao8O2b23kap1u/XeVg1Q4ds3tvI1Rrd+vcrBp/h2ze28jVOt367y
cGqHDtm9l5GqNbv13k4NUOHbN7BeRqjW79dH+Rg09w7ZvYLyNUa3frnmwae4YOZsm9gvNwjW79c8
2DT3Etm9l5uEa3frnlwae4lmZzm9l5uEXvrfkwad4ls3sF5uEXf4+t78GnOLbN7BebhEKWlIEy2S
tztSHnlHP2+t64NN8W2b2CFuoQUzCVKWspHyTCiCcm9EQ+QAgqzc7u1IOY0+tYPN8b3zsm9kPKyL
ZcKncykIGadG9EQ73ZJQ4VLT3JSykH+PrWDrQlaJyVXKu2TXjh4OEobWFLR3BLCQQMob0b0IgCMo
Ag5AfWtuDzcsiZaeaWy5mM5rxwRAEZRlY3ohYzJVH5x2qJCBAQkH67602tbbAnZkFJzT1FQkhMtq
T2mb8fyJzKjmCuMlk9nt8aIAACfcfyiUmlhujz64T9feIboMumJWRl5QegpSUJcqDpKai6AKhMRM
znxH+/MQo9x70oacqLqi3UHQUqCk9NVZDvD2gsSkqhuSm3IRRZ9Qb+vuGE/X5QFulSDcIZZbHq1A
kS8k2hx6eZbS3IoQt1aipaZVgIWntXPKIZadLSnHC4qQJLHTzNCaeeboUkiG6ZINwlptA6OYa+Vo
KcZW7MvPCn+eYaLTqag4EEklXfNS7D62FrqDigw58rWHqnWVLfmEOIp7ZzcabdSmSlwVyrC1NtIa
Dkqy4UScukjDyMwqnskop7IISEi//9oACAECAgY/APXdBzlWZaBSjqiKkZERY+amyOPolrJ1NvH/
2gAIAQMCBj8A9d0nGUuvUrYcM7smSfzITtXsLaxqxpvUhPXJ2NfH/9oACAEBAQY/AP8AkIsNZgcD
IgmS8XB1xnpd3y3y5tA8njBq6KbSQeC8KrhcSrKpOYTXkGu5SXnSHwleTHD71+MtvC8azOsl4uDr
jPRxoUDZqc8beDVNWEi0WhGVUEbnNCs/jd1C8qM8rgV5U3jlNWktzAhWPb1VhBHAorxJFxXjWePi
K/2lw/MAV5MY/kQvOj/1KDS1zSTK0aHmbAnUPlz4AyqPG3g31F4htXk0G8J4YA0GVgTj3ObID8Jk
vGu4cHAOX46bxxaQf1L/AFtdlfL9TV5UHi6Tl5Bzb2lU+0z8mn79DjsJHc4Ay3QC8ekwDJWgiB9w
T7hEy9uOhxmEAvHp30a7qY2NBEkP56xfT3EBTpMD3bnKT/lWm4yQJEidifyT7grSAN5Ml4kOuKkr
QfRmZuOhxmEAvHpOrVayrumQUHU/mDUH9v8AIXD/AKoig7sdvlNWva4cWId2vbJP5J3JEV/9e2ya
m2sG9WoNY4iyTXA6vzdy/wAfzReOJDkBVPc/aZSTMzcdDtzCAXj0m5oN4QexgDhtAXbMt4tMl4/M
VW/EUASTxKfyTuSLiw1Jf0gymvP5Wo03ArysZK2Y1BeNYMPNqaGO727DOc1TzNx0OMw9SJWS1oXi
N/JP5empSVoB5INaJAKlnbjocZgjI6vSaF4jfyT+UVLO3Q/xBHiZ+ovEb+SfywipZ26H+IehO/0F
4jfyT+WEVLO3Q/xCAXiN/JP5YRUh+duhzmEAvEb7wn3jCKlnboc5hALxjG+8J94wip5xhod2YQC8
YxvvCfeMIqeYYaHdmGMAvGMb7wn3jCKnm9tDuzDGAXjGN94T7xhEzN7aHdmGMAvGMb7wn3jCJmY/
o0O+8YwC8YxvvCfeMImZjhod94xgHLGN94T7xhE3McNDvvGMHTGN94T7/aJmZ36NDvvGMHMYxvvG
Cdf7RNzOw0PU5YwDljG/MME6/wBom3uw0PU5YwdMY35vZOv9om3uw0PU5YwdMY35vZOviHxYaHq3
DGAcsYhQoTHzYc0HxO/yd3bk473IubrDweSY75dhYGtk6Y7bYeTtD1OWMHTGLuItTsydPerBDydo
epcMYOmMb8ydfFydoerdB0xjfmTr4jldjoerdB0xjdmTr4vhdoerlg6YxuzJ18Ryux0PVug6Yxuz
J18XwOx0PVug6YxuzJ2aI5XY6Hq5YOmMbsydmidkdi3Q9XLB0xjdmTs0TsjsW6HrZTB0xjdmT8xi
dkOLdD1csHTGN2ZOzGJ+Q4t0PVywdMY3Zk7MYn5PfQ9bKYOmMbsydmMVTJ76HrZSiN3r0xjdmKfm
MVTJ76HrZTB0xjdmKfmMVXKMdD1sp9ZSskumMbsxTsxiq5RjoerlMH23xuzFOzGKrlGOh6uUwfbf
G7MU7MVNxkEGiZJUwJncgA2V/pVyt/doerlMH23+knFdrQb1NomVsarU7MU7MVaO7kh2sA4yXjIF
DueB6VcrP3aHq5SuHqftt9LG9xuVoAFysMuK832cAhJOzFOzFeGtAuMrlKZFymZmW8+lXK392h3M
d+FwkUWG1hta7ePU/bb6eMpKbjyCkSeSnrv9HZinZjCVWuZ+7RBpu1/0ncUabxJzVKdqP22xuzFO
bIkhxsAKsaeZAVsh96/EeQVpJvKnITVf4f3fTOcwycJSPNT758JBA77frPc0AVW6uPBEOBDhZcjd
7qQMzwtVjSeS1Ac1rAHAK0kq0TvM0AAABsC32qW3cpspPcLiFawMzEId1VrbgSgaj3PukE4UQR36
yTOf0Jc4yAEyV/jAa3ZMTKIc0OMrCLELGdD/APS7KYm/aTqC8u1w3EIulKZnIbEHuMgGia/xgNbx
tKH8gDm7ZCRQc20G0H6ufmKQ8h+MDbxQ9fEF1wmvCi48pIEta3MUP5KrRwaJol73unsBAQlRaZf3
zd+pSpsazKAPppDaQCpPAIDZyO1d7WgOBlYJTRDgHANnbeE5x1kkldpYDZaZW9U5k59pIVJmw2n4
Qu4NDjs7tiLiA0nWApHYSB9Y721TTZr7AJ/egXl9Q/mdIf8AjtU20Gk73Du/UpMaGjgPqjmjXrF6
mJte3egHkSGwBHKcQiCLCZi5BpbNwEu6fsiTrOtTAk+mbANoki4AEGwg7VJjQ075zTXykSLRpAip
S7wDIOsNiFOkzsZOZ4p1Q6pdo4rtqAOCn2zvXc5kyeJCIpjtBMztXc5vlvFin2zPG1WaPIOoqYm3
gCpmbuBNiAAkBqA+g//Z" transform="matrix(1 0 0 1 1189 1189)">
				</image>
			</g>
			<rect x="1482.3" y="166.8" clip-path="url(#SVGID_2_)" fill={color1} style={{transition: "1s"}} width="40.5" height="40.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color13} style={{transition: "1s"}} d="M1191.6,456.7c0.2-10.6,0.4-21.3,0.6-31.9c9.8-3.3,21.3-4.3,29.7,1.6
				s10.3,20.7,1.5,26c-8.5,0.6-17.1,1.2-25.6,1.8"/>
			<path clip-path="url(#SVGID_2_)" fill="#FBB03B" d="M1263.7,453.7c0.1-11.9,0.3-23.7,0.4-35.6c3.7-0.9,7.5-0.9,11.2,0
				c1.1,9.8,0.8,19.7-0.7,29.4c-2.6,0.1-5.2,0.1-7.8,0.2"/>
			<path clip-path="url(#SVGID_2_)" fill={color2} style={{transition: "1s"}} d="M1488.8,294.7c-0.1-15.6-0.3-31.2-0.4-46.7c8.3,4.7,17.7,7.4,27.2,7.9
				c0,11.9-0.1,23.8-0.1,35.6c-7,0.4-14-0.7-20.5-3.2"/>
			<path clip-path="url(#SVGID_2_)" fill={color3} style={{transition: "1s"}} d="M1569.8,198.7c0.2-10.7,0.3-21.5,0.5-32.2c10.4-2.6,24.5-3.6,28.8,6.3
				c1.3,3.1,1.2,6.6,1.1,9.9c-0.3,10.1-0.6,20.2-1,30.2c-6.2-9.7-18-15.5-29.5-14.6"/>
			<path clip-path="url(#SVGID_2_)" fill={color4} style={{transition: "1s"}} d="M1557.8,276.7c-0.1-9.6-0.2-19.2-0.2-28.8c5.6,1.9,11.6,2.7,17.5,2.2
				c2.9,13.8,5.8,27.6,8.7,41.4c-8.6,0-17.1-0.1-25.7-0.1c-0.2-7.9-0.3-15.8-0.5-23.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color5} style={{transition: "1s"}} d="M1480.7,414.5c-3.3-14.3-4.7-29.1-4.2-43.7c8.5,3.7,17.9,5.6,27.2,5.4
				c0,15.9,0,31.8,0,47.7c-7.8-1.2-15.6-2.3-23.4-3.5c-0.1-4.9-0.3-9.8-0.4-14.6"/>
			<path clip-path="url(#SVGID_2_)" fill={color6} style={{transition: "1s"}} d="M1581.8,483.7c0.1-11.6,0.2-23.2,0.3-34.8c6.8,4.7,15.4,6.6,23.5,5.1
				c0.1,11.7,0.2,23.5,0.2,35.2c-6.5-2.5-13.6-3.5-20.6-2.8c-0.2-2.9-0.4-5.8-0.6-8.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color7} style={{transition: "1s"}} d="M1540,492.7c-0.2-15-0.4-30-0.6-44.9c-8.1,2.5-16.2,4.9-24.3,7.4
				c4.9,14.8,7.4,30.4,7.4,46.1c6.7-1.1,13.3-2.2,20-3.3c0.2-3.7,0.4-7.4,0.6-11.2"/>
			<path clip-path="url(#SVGID_2_)" fill={color8} style={{transition: "1s"}} d="M1131.7,549.7c0.2-11.9,0.3-23.8,0.5-35.7c10.8,0,21.6,0,32.4,0.1
				c0,11.8,0,23.5-0.1,35.3c-8.8-0.5-17.8,0.7-26.2,3.3c-0.2-7-0.4-13.9-0.6-20.9"/>
			<path clip-path="url(#SVGID_2_)" fill={color9} style={{transition: "1s"}} d="M1207.5,549.7c-1.5-12.6-3-25.2-4.5-37.8c5.7,5.5,13.6,8.8,21.5,8.8
				c0,9.5-0.1,18.9-0.1,28.4c-5.8,1.1-11.5,2.2-17.3,3.2c-0.2-3.9-0.4-7.7-0.5-11.6"/>
			<path clip-path="url(#SVGID_2_)" fill={color1} style={{transition: "1s"}} d="M1269.8,555.7c-0.1-12.6-0.2-25.2-0.2-37.8c5.8,2,11.9,2.8,18,2.2
				c0,11.7-0.1,23.4-0.1,35.1c-5,1.7-10.7,1-15.1-1.9"/>
			<path clip-path="url(#SVGID_2_)" fill={color2} style={{transition: "1s"}} d="M1483.5,591.7c-1.2-9.9-2.3-19.8-3.5-29.7c7.8,0,15.7,0,23.5,0.1
				c0,10.8,0,21.5,0,32.3c-5.9-0.4-11.9,0.5-17.4,2.8c-0.2-2.8-0.4-5.7-0.5-8.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color3} style={{transition: "1s"}} d="M1569.8,588.7c-0.1-14.6-0.2-29.2-0.3-43.8c7.8,1.9,15.9,2.7,24,2.1
				c-0.5,13.8,0.4,27.7,2.8,41.3c-8.8,0.1-17.6,0.3-26.4,0.4"/>
			<path clip-path="url(#SVGID_2_)" fill={color4} style={{transition: "1s"}} d="M1644.7,579.7c0.2-15.9,0.3-31.7,0.5-47.6c6.5,0.1,13.1,0.1,19.6,0.2
				c4.6,15.3,8.1,31,10.3,46.8c-6.9,0-13.8,0-20.7,0c-1.1-2.8-2.3-5.7-3.4-8.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color5} style={{transition: "1s"}} d="M1608.8,360.7c0.1-8.9,0.2-17.8,0.3-26.7c6.8,0,13.6,0,20.4,0
				c0,10.8,0,21.6-0.1,32.4c-6.3-1.4-13.3,0.6-18,5c0.1-3.6,0.2-7.2,0.3-10.8"/>
			<path clip-path="url(#SVGID_2_)" fill={color6} style={{transition: "1s"}} d="M1482.8,660.7c0.1-7.9,0.3-15.7,0.4-23.6c9.9-1.7,19.8-3.4,29.6-5
				c0,11.8,0.1,23.6,0.1,35.3c0,1.8,0,3.6-1.1,5.1c-1.1,1.6-3.2,2.1-5.1,2.6c-6.9,1.7-13.9,3.4-20.8,5.1c-0.1-6.5-0.2-13-0.3-19.5"
				/>
			<path clip-path="url(#SVGID_2_)" fill={color7} style={{transition: "1s"}} d="M1582.7,729.7c-1.1-12.9-2.1-25.7-3.2-38.6c7.5,0,15.1,0.1,22.6,0.1
				c1.1,16.5,2.2,32.9,3.3,49.4c-5.7,3.8-13.9,3.4-19.1-1s-7-12.5-4.1-18.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color8} style={{transition: "1s"}} d="M1644.6,729.7c1.3-12.9,0.6-26.1-2.1-38.8c6.6,0,13.2,0.1,19.9,0.1
				c2.4,13.8,4.7,27.5,7.1,41.3c-8,0-16.1,0-24.1,0.1c-0.2-4.9-0.3-9.8-0.5-14.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color9} style={{transition: "1s"}} d="M1375.4,663.5c0.1-7.8,0.2-15.7,0.3-23.5c5.8,0,11.6,0,17.4,0
				c0,12.8,0,25.5,0,38.3c-10.1,3.3-20.1-11.4-13.3-19.6"/>
			<path clip-path="url(#SVGID_2_)" fill={color10} style={{transition: "1s"}} d="M567.7,498.7c0.1-12.8,0.2-25.7,0.3-38.5c6.9-1.2,13.9-1,20.7,0.5
				c-0.1,13.6-0.1,27.1-0.2,40.7c-6.8,0-13.6,0-20.3-0.1c-0.2-2.8-0.4-5.7-0.6-8.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color1} style={{transition: "1s"}} d="M627.6,507.7c0.2-12.8,0.3-25.7,0.5-38.5c6.8,0,13.6,0,20.4,0
				c0,11.7-0.1,23.4-0.1,35.1c-6.7,0-13.5,0-20.2,0c-0.2-3.8-0.4-7.7-0.6-11.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color11} style={{transition: "1s"}} d="M622.4,609.7c-1.2-13.8-2.4-27.7-3.6-41.5c5.9,0,11.8,0,17.7,0
				c0,14.7,0.1,29.5,0.1,44.2c-4.8,0-9.7,0-14.5,0.1c-0.2-2.9-0.4-5.8-0.6-8.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color12} style={{transition: "1s"}} d="M687.6,597.7c0.2-16.9,0.3-33.8,0.5-50.7c6.7,2.1,13.4,4.1,20.1,6.2
				c0.7,14.7,0.7,29.4,0,44c-6.7,0-13.4,0-20.2,0c-0.2-2.8-0.4-5.7-0.6-8.5"/>
			<path clip-path="url(#SVGID_2_)" fill={color13} style={{transition: "1s"}} d="M561.6,666.7c0.2-11.9,0.3-23.7,0.5-35.6c6.8,0,13.6,0,20.4,0.1
				c0,14.7,0.1,29.4,0.1,44.2c-6.4-2.6-13.5-3.7-20.4-3c-0.2-5.9-0.3-11.8-0.5-17.6"/>
			<path clip-path="url(#SVGID_2_)" fill={color14} style={{transition: "1s"}} d="M654.8,675.7c-1.8-11.9-3.6-23.9-5.4-35.8c8.7,0.1,17.4,0.1,26.1,0.2
				c0,11.7-0.1,23.4-0.1,35.1c-6.5,2.7-14.5,1-19.3-4"/>
			<path clip-path="url(#SVGID_2_)" fill={color15} style={{transition: "1s"}} d="M348.6,663.7c0.2-8.9,0.3-17.7,0.5-26.6c7.8,0,15.6,0,23.4,0
				c0,14.8,0,29.5,0.1,44.3c-7.8,0-15.6,0-23.4,0c-0.2-7.9-0.3-15.8-0.5-23.7"/>
			<path clip-path="url(#SVGID_2_)" fill={color16} style={{transition: "1s"}} d="M285.6,690.7c0.2-14.9,0.3-29.8,0.5-44.7c7.8,0,15.6,0,23.4,0
				c0,11.7-0.1,23.4-0.1,35.1c-7.8,2-15.5,3.9-23.3,5.9c-0.2-3.8-0.4-7.5-0.6-11.3"/>
			<path clip-path="url(#SVGID_2_)" fill={color11} style={{transition: "1s"}} d="M180.7,699.7c-0.1-13.6-0.2-27.2-0.3-40.8c6.8,2,14,2.8,21.1,2.2
				c0,14.9,0,29.7,0,44.6c-6.4-1.8-13.6,0.1-18.3,4.8c0.1-4.6,0.3-9.2,0.4-13.8"/>
			<path clip-path="url(#SVGID_2_)" fill={color12} style={{transition: "1s"}} d="M326.9,837.9c-2.6-13.8-3.2-27.9-1.6-41.9c7.7,0,15.4,0,23.1,0
				c-0.7,13.8,0.1,27.6,2.4,41.2c-6.9,1.4-14.1,1-20.8-1.4"/>
			<path clip-path="url(#SVGID_2_)" fill="#4F4E5E" d="M2837.8,2834.6c0,0-2834.6,0-2834.6,0c0,0,0-414.3,0-438.6
				c0-285.6,0-571.3,0-856.9c0-121.7,0-243.4,0-365c0-42.7-0.1-85.4,0-128.1c0-25.6-5.4-60.2,11.8-81.5
				c25.7-31.8,106.4-40.8,144-41.3c37.6-0.5,74.2,11.5,110.7,20.4c61.1,14.9,124.3,21.7,187.2,20.1c70.8-1.8,140.9-14.1,211.4-20.8
				c84.3-8,169.1-8.1,253.7-8.1c98.9,0,197.7-0.1,296.6-0.1c519.6-0.2,1041.6-15,1561-0.6c83.6,2.3,58.2,62.6,58.2,125.7
				c0,43.9,0,87.8,0,131.8c0,121.9,0,243.9,0,365.8c0,281.6,0,563.3,0,844.9C2837.8,2546.5,2837.8,2690.6,2837.8,2834.6z"/>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1798.2,1312.8c-4.7,1.6,5.9-0.6,7.5-0.6c3,0.1,3.7,0.1,2.1,0c-2.3-0.2-0.9,0,4,0.9
						c-3.7-1.2,2.5,1.1,2.4,1.3c-0.1,0.2-3.2-2.3-3.3-2.4c1.5,1.4,3,2.7,4.5,4.1c-1.3-1.3-2.3-2.6-3.3-4.2c0.9,1.7,1.7,3.5,2.6,5.2
						c-1-2.1-1.5-4.1-1.7-6.5c0.1,1.9,0.2,3.9,0.3,5.8c0-2.3,0.2-4.4,0.9-6.7c-0.6,1.9-1.3,3.9-1.9,5.8c0.1-0.1,1.5-3.8,1.7-3.7
						c-0.8,1.1-1.5,2.3-2.3,3.4c1.5-1.5,1.2-1.3-0.8,0.7c-3.5,3.3-7.6,5-12.1,6.4c2.3-0.6,1.7-0.5-1.8,0.2c-2.8,0.7-5.7,0.9-8.6,0.5
						c0.7,0,1.3,0.1,2,0.2c2.2,0.3,0.9,0-3.9-0.9c1,0.5,2.4,0.7,3.5,1c1.2,0.4,2.3,0.9,3.5,1.3c0.7,0.3,1.4,0.6,2.1,0.9
						c1.8,0.8,0.9,0.4-2.6-1.2c1.8,1.5,4.9,2.5,7,3.6c4.3,2.2,8.7,4.3,13.1,6.3c1.1,0.5,2.2,1,3.3,1.4c4.7,1.9-1.6-1.1-1.8-1
						c0.9-0.6,6,3.7,6.7,4.3c-1.5-1.4-3-2.7-4.5-4.1c0.9,0.9,1.5,1.7,2.2,2.7c-0.9-1.7-1.7-3.5-2.6-5.2c0.5,1.1,0.7,1.9,0.9,3.1
						c-0.1-1.9-0.2-3.9-0.3-5.8c0,0.8-0.1,1.6-0.4,2.3c-1.2,4.9,1.8,10.1,5.2,13.2c4.2,3.8,10,6,15.7,6.5c13,1,29.2-3.1,37.8-13.6
						c1.9-2.2,1-1.5-2.9,2.3c-3.3,2.3-6.8,4-10.7,5.1c-3.8,1.1-7.7,1.4-11.6,1c2.1,0.2,1.8,0.1-1-0.4c-2.8-0.7-3.1-0.7-1.1-0.1
						c2.4,0.9,1.7,0.6-2.3-1.1c0.2,0.7,4.3,3.1,5.1,3.8c-1.5-1.4-3-2.7-4.5-4.1c4.7,4.5,8.3,9.7,12.1,14.9
						c-0.4-5.6-0.7-11.3-1.1-16.9c1-1.4,1.9-2.9,2.9-4.3c4.2-3,8.4-6.1,12.6-9.1c-0.2,0.1-0.4,0.2-0.6,0.3c5.6-1.5,11.3-3,16.9-4.5
						c17.9,6.2,23.9,7.4,17.9,3.4c1.5,1.4,3,2.7,4.5,4.1c-0.7-0.6-1.1-1.1-1.5-1.9c0.9,1.7,1.7,3.5,2.6,5.2
						c-0.1-0.2-0.1-0.5-0.2-0.7c0.1,1.9,0.2,3.9,0.3,5.8c0-0.2,0-0.5,0-0.7c-0.6,1.9-1.3,3.9-1.9,5.8c-1,1.4-2,2.8-3,4.2
						c5.5-6.4,8.3-15.1,2.8-22.5c-5.6-7.4-17.5-9.8-26.2-8.6c-2.3,0.3-4.6,0.6-7,0.8c-2.5,0.2-10.5-0.4-0.6,0.3
						c-4.1-0.3-8.1-0.4-12.2-1c-1.2-0.2-2.3-0.4-3.5-0.6c4.5,1,5.2,1.1,2.2,0.4c-1.7-0.5-3.4-0.9-5.1-1.4c-2.2-0.7-4.4-1.5-6.6-2.3
						c-1.5-0.6-8.8-4.1-1.9-0.7c1.9,7,3.8,14,5.6,21.1c0.9-2.2,1.8-4.4,2.9-6.5c0.5-0.8,1-1.6,1.4-2.5c2.2-3.8-3.1,3.9-1,1.5
						c1.4-1.6,2.8-3.3,4.2-4.8c3.2-3.5-4.6,3.7-1.9,1.6c0.9-0.7,1.8-1.5,2.8-2.2c0.8-0.6,1.6-1.1,2.3-1.6c4.4-3.2-5.7,3-2.8,1.6
						c1.9-0.9,3.9-1.8,5.8-2.7c-9.2,4.2-6.3,2.2-3.5,1.4c1.1-0.3,2.4-0.4,3.5-0.9c-0.3,0.1-8.8,1.2-4.5,0.8c1.4-0.1,2.8-0.3,4.3-0.3
						c-3.6-13.4-7.2-26.8-10.8-40.2c-1.7,0.8-3.4,1.5-5.1,2.3c-2.5,1-1,0.5,4.5-1.6c-1,0.4-2,0.7-3,1c-3.1,0.9-6.2,1.5-9.3,2.3
						c10.6-2.9,3.9-0.7,0.8-0.4c-1.6,0.2-3.2,0.3-4.8,0.4c-2.6,0.1-7.5-0.7,1.8,0.2c-1.9-0.2-3.7-0.1-5.6-0.3
						c-3.4-0.3-6.9-1.2-10.3-1.4c7.3,0.4,3.8,0.8,1.1,0.1c-1.8-0.5-3.6-1-5.4-1.6c-1.8-0.6-3.5-1.2-5.2-1.9c-0.5-0.2-1-0.4-1.5-0.6
						c-2.5-1.1-2-0.8,1.8,0.9c-0.8-1-3.1-1.6-4.2-2.3c-1.7-1-3.3-1.9-4.8-3.2c1.5,1.4,3,2.7,4.5,4.1c-0.2-0.3-0.5-0.7-0.7-1
						c0.9,1.7,1.7,3.5,2.6,5.2c-0.1-0.4-0.3-0.8-0.4-1.2c0.1,1.9,0.2,3.9,0.3,5.8c0.1-0.5,0.1-1,0.2-1.4c-0.6,1.9-1.3,3.9-1.9,5.8
						c-0.4,0.9-3.7,4.6-1.4,1.7c0.7-0.8,1.3-1.7,2-2.5c1.4-1.6,2.8-3.2,4.2-4.8c0.7-0.8,1.5-1.5,2.2-2.3c2.8-3-1.8,1.2-2.1,1.8
						c0.6-1.4,3.7-3,4.9-3.9c0.5-0.4,2.4-1.2,2.6-1.7c-0.3,0.6-6.9,3.6-3,1.9c0.8-0.3,2.3-0.7,2.8-1.3c-0.3,0.3-8.2,2.9-3.8,1.5
						c4.9-1.5-3.1,0.3-3.2,0.4c0.3-0.4,3.2-0.3,3.7-0.4c2.8-0.2,5.6-0.2,8.4-0.2c11.2,0,22.3-0.4,33.1-3.8
						c16.3-5.1,29.2-13.5,38.7-27.8c3.2-4.8,2.2-12.5-1.1-16.9c-3.9-5.2-11.8-8.1-18-8.5c-16.6-1.3-32.4,5.3-41.6,19.2
						c-0.2,0.3,3.6-4.8,1.7-2.7c-1.9,2.2,3.2-3.1,3.1-2.6c-0.1,0.4-2.1,1.4-2.3,1.6c0,0,6.5-3.3,4-2.5c-0.6,0.2-1.5,0.5-2,0.9
						c-0.3,0.2,7.8-2.4,4.8-1.9c-0.7,0.1-1.4,0.4-2.1,0.5c-4.3,1.1,4-0.8,4.4-0.8c-3.3-0.5-7.5,0.5-10.9,0.6
						c-4.4,0-8.8-0.1-13.2,0.1c-23.9,1.4-44.6,12-61.5,28.4c-6.3,6.1-13.6,14-16.3,22.4c-4.3,13.3,5.9,21.8,16.6,27.2
						c15.7,7.9,32.8,13,50.4,13.7c23.2,0.8,45.9-3.1,67-13.1c5.8-2.7,10.9-6.7,14.6-11.9c2.8-4,5.2-9.5,3.5-14.5
						c-4.1-11.9-17.8-14.3-28.8-13.8c-32.5,1.4-68.4,21.7-80.5,53.1c-2.8,7.4-2.7,16.9,5.6,21.1c24.2,11.9,50.9,17.3,77.8,13.5
						c-7.8-10.4-15.6-20.7-23.4-31.1c-4.9,5.7-7.9,12.4-6.1,20c1.2,5.2,5.8,9.5,10.1,12.1c18.7,11.6,48.5,2.8,58.9-16
						c2.9-5.3,2.4-12.1-1.1-16.9c-8.5-11.6-17.5-22.7-31.7-27.5c-20.7-7-48.9-1-62.9,16.3c19.6,2,39.1,4,58.7,6
						c2.6-10.7-4.8-20.2-13.3-25.6c-3.8-2.4-8.1-4.2-12.2-6c-1.5-0.6-3-1.3-4.5-1.9c-1.8-0.8-0.9-0.4,2.7,1.3
						c-0.9-0.5-1.9-0.9-2.8-1.4c-7.5-3.8-15-8-23-10.8c-12.2-4.4-25.8-5.1-38.4-1.9c-22.1,5.6-45.7,24.8-40,50.1
						c5.6,25,39.1,30.8,59.7,24c7.7-2.5,15.4-5.9,20.9-12.1c3.7-4.2,7.4-11.4,5.4-17.3C1830.8,1310.8,1810.6,1308.7,1798.2,1312.8
						L1798.2,1312.8z"/>
				</g>
			</g>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1914.3,1304.1c23.3-32.1,32.8-72.4,27.9-111.6c-0.7-5.6-8.4-9.4-12.9-10.7c-7.3-2.1-16.8-1.5-24,0.6
						c-12.2,3.6-28.7,12.1-26.9,27.2c0.5,4.4,0.9,8.9,1.1,13.3c-0.1-1.8-0.1-3.5-0.2-5.3c0.4,18.5-2.6,36.7-9,54
						c0.7-1.8,1.3-3.5,2-5.3c-4.6,12-10.6,23.3-18.2,33.7c-3.2,4.4-2.7,11.2,0.6,15.2c3.8,4.8,11.9,7,17.6,7.2
						C1888.4,1323.2,1904.7,1317.3,1914.3,1304.1L1914.3,1304.1z"/>
				</g>
			</g>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1821,1283.9c19.7-9.9,35.5-24.8,46.3-44c2.4-4.3-3-8.1-5.9-9.3c-5.9-2.4-14.6-2-20.7-1
						c-13.5,2.2-29.6,7.1-36.7,19.7c2-3.5-1.4,2.1-1.8,2.7c-0.9,1.3-1.8,2.6-2.8,3.8c-1,1.2-2,2.4-3,3.6c-0.4,0.5-4.7,5.3-2.3,2.5
						c2.4-2.7-2,1.7-2.6,2.2c-1.2,1-2.5,1.9-3.8,2.9c-4.3,3.3,5-2.8,0.6-0.3c-1.8,1-3.7,2.1-5.6,3c-4,2-11.2,6.9-12.5,11.8
						c-1.2,4.5,1.8,7.9,5.9,9.3C1790.4,1295.9,1808.3,1290.3,1821,1283.9L1821,1283.9z"/>
				</g>
			</g>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1810.7,1289.3c5.3-1.3,10.6-2.5,16-3.6c2.7-0.6,5.8-1.5,8.5-1.6c-1.1,0-7.6,1.2-3,0.5
						c1.6-0.2,3.2-0.5,4.8-0.7c11.1-1.7,22.3-2.8,33.6-3.4c8.1-0.5,17.5-3.8,24-8.8c4.8-3.7,10.8-10.5,11.1-17.1
						c0.8-16.1-16.1-21.8-29.5-21.1c-26,1.5-52,5.6-77.3,11.9c-7.5,1.9-15.8,6.5-20.8,12.5c-3.8,4.5-7.2,12-5.2,18
						C1778.1,1291.1,1797.4,1292.6,1810.7,1289.3L1810.7,1289.3z"/>
				</g>
			</g>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1880.7,1322.3c20.6,3.8,43.4-3,60.4-14.5c9.9-6.7,17.5-22.5,4.2-30.2c-13.8-7.9-33-3.9-45.5,4.6
						c-2.7,1.8-1.8,1.3,2.9-1.5c-1,0.5-2,1-3,1.4c1.7-0.6,3.5-1.3,5.2-1.9c-1.1,0.3-2.1,0.6-3.2,0.8c5.7-0.9,7.2-1.2,4.6-1
						c-2.4,0.2-1.1,0.2,4,0.1c-2.1-0.5-4.4-0.3-6.5-0.7c-7.5-1.4-18.4,1.5-25.1,4.6c-5.9,2.7-13.8,8-16.6,14.2
						c-2.4,5.2-3.3,11.4,0.9,16.1C1867.6,1319.7,1873.9,1321,1880.7,1322.3L1880.7,1322.3z"/>
				</g>
			</g>
			<g opacity="0.65" clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1905,1311c49.6-14,93.9-46.6,113.4-95.4c1.8-4.6-2.5-8.3-6.1-9.9c-5.9-2.6-14.7-2.3-20.8-1.3
						c-8.3,1.3-16.9,3.6-24.2,8c-5.1,3.1-10.1,6.6-12.4,12.3c-1.8,4.4-3.8,8.6-5.9,12.8c-1.3,2.5,2.3-3.7-0.2,0.3
						c-0.5,0.8-1,1.7-1.5,2.5c-1.2,1.9-2.4,3.8-3.7,5.6c-2.7,3.9-5.6,7.6-8.7,11.2c-1.5,1.7-3,3.3-4.5,4.9c-3.5,3.6,0.6-0.4,0.5-0.5
						c0.1,0.1-2.5,2.4-2.7,2.5c-3.3,3-6.8,5.8-10.3,8.5c-1,0.7-2,1.4-3,2.2c-3.1,2.2,1.7-0.9,2.2-1.4c-1.2,1.6-4.6,2.9-6.4,3.9
						c-1.9,1.1-3.9,2.1-5.9,3.2c-3.3,1.7,7.2-3.2,2.5-1.2c-0.9,0.4-1.8,0.8-2.7,1.2c-2.3,1-4.6,1.8-6.9,2.7
						c-4.2,1.6,3.8-1.3,4.1-1.3c-1.4,0.3-2.9,0.9-4.3,1.3c-7.3,2.1-15.5,5.2-21.4,10.1c-2.8,2.3-8.4,7.9-6.7,12.4
						C1873.9,1317.4,1896.5,1313.4,1905,1311L1905,1311z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1869.7,1273.6c-0.3,0-3.3,0.5-3.4,0.3c0.3,0.6,8.6,0.1,2.3-0.2c-3.1-0.2,5.2,2,2.4,0.6
						c-1.2-0.6-2.7-1.1-3.9-1.7c-9.7-4.6-23.4-2.1-32.6,2.4c-7.4,3.7-18.4,12.3-18,21.9c0.1,2.4-0.1,4.6-0.7,6.9
						c0.6-2.1,1.2-4.2,1.8-6.3c-0.3,0.9-0.6,1.8-1,2.6c-1.3,2.5-0.7,1.7,1.7-2.3c-0.5,0.8-1.1,1.5-1.7,2.2c-2,2.2-1.2,1.5,2.4-2.2
						c-0.7,0.6-1.5,1.2-2.3,1.8c5-2.9,6.1-3.6,3.3-2.1c-7.7,3.4,7.6-2.3,1.2-0.5c-3.5,0.7-2.3,0.6,3.4-0.5c-1.2,0.1-2.4,0.2-3.6,0.3
						c-1.2,0.1-2.4,0.1-3.6,0c5.2,0.2,6.2,0.2,2.8,0c-4.8-0.6-9.6-1.2-14.4-2c-1.6,15.6-3.1,31.2-4.7,46.8c1.2-0.3,2.3-0.6,3.5-0.9
						c3.5-0.7,2.4-0.6-3.4,0.5c1.7,0.1,3.7-0.4,5.4-0.5c0.9-0.1,1.8-0.1,2.7-0.1c2.4,0,1.2-0.1-3.7-0.1c1.9,0.8,5.1,0.5,7.2,0.9
						c-7-5.2-14-10.3-21.1-15.5c0,0.4,0.1,0.8,0.1,1.2c-0.2-2.1-0.3-4.2-0.5-6.4c0,0.4-0.1,0.8-0.1,1.1c0.6-2.1,1.2-4.2,1.8-6.3
						c-0.6,1.8-0.3,1.5,1-0.9c1.3-2.2,2.9-4.1,5.1-5.6c3.3-2.8,7.2-4.4,11.5-4.9c6-2.6,12.1-2.6,18.4-0.2c-4.4-1.6,4.4,4,0.8,0.4
						c-1.1-1.1-2.4-2-3.5-3.1c1.6,1.5,3.1,3.1,4.7,4.6c-1.1-1.1-2.2-2.3-3.2-3.5c-2.6,9.9-5.2,19.8-7.7,29.7c2-1.6,4-3.1,6.1-4.5
						c5.1-3.5-2,0.5-2.1,1.2c0.1-0.4,2.5-1.5,2.9-1.7c2.4-1.4,4.9-2.6,7.5-3.8c1-0.5,2.1-0.9,3.1-1.3c5.1-2.2-6.7,2.2-2.8,1
						c2.4-0.8,4.8-1.6,7.2-2.2c1.1-0.3,2.2-0.6,3.3-0.8c1.4-0.3,6.6-1.2,0.6-0.3c-6.3,1,2.3-0.2,4-0.4c2.8-0.2,5.6-0.4,8.4-0.4
						c-9.8-5.2-19.5-10.5-29.3-15.7c0,0.4,0.1,0.7,0.1,1.1c-0.2-2.1-0.3-4.2-0.5-6.4c0,1.1-0.1,2-0.4,3.1c0.6-2.1,1.2-4.2,1.8-6.3
						c-1,2.5-0.2,1.4,2.4-3.3c-1.8,2.2-0.7,1.2,3.3-3.1c-3.7,3.1,8.3-4.9,2-1.5c6.4-2.2,8-2.9,4.7-1.9c-0.8,0.2-1.6,0.4-2.5,0.6
						c9.7-3,6.7-1,3.2-0.7c-9.6,0.7-19.3,0.5-28.9,0.8c8.1,11.5,16.2,22.9,24.3,34.4c-0.7,0.7-5.1,4.4-1.9,2
						c1.1-0.8,2.1-1.7,3.2-2.5c1-0.7,4.4-2.9-0.1-0.1c-4.6,2.8-1.1,0.7,0,0.2c1.2-0.6,2.4-1.1,3.6-1.8c4-2.1-3.1,1.4-3.7,1.3
						c1.6,0.2,5.5-1.5,7-1.9c3.7-1-7.7,0.8-3.9,0.7c1,0,2-0.2,3-0.3c3.3-0.2,3.3,1.6-3.7-0.2c0.8,0.2,1.8,0.3,2.6,0.3
						c4.1,0.4-3.8-0.6-3.6-0.9c0-0.1,2.2,0.8,2.4,0.9c1.8,0.9,1.5,0.7-0.9-0.5c-2.2-1.2-2.7-1.4-1.5-0.7c0.3,0.5,1.9,1.5,2.3,1.9
						c-1.6-1.5-3.1-3.1-4.7-4.6c1.1,1.2,1.9,2.4,2.6,3.9c-0.9-1.9-1.8-3.8-2.8-5.8c0.5,1.3,0.8,2.5,1,3.9c-0.2-2.1-0.3-4.2-0.5-6.4
						c0.1,1.7-0.1,3-0.4,4.6c0.6-2.1,1.2-4.2,1.8-6.3c-0.3,1-0.6,2-1,3c-1.3,2.5-0.9,2,1.3-1.7c-0.6,0.1-2.6,3.2-3.2,3.9
						c-2.2,2.2-1.5,1.6,2-1.8c-0.8,0.7-1.6,1.5-2.5,2.2c-1.3,1.1-2.6,2.1-3.9,3.1c-1.7,1.3-3.8,2.4-5.4,3.8c6.8-5.8,3.8-2.3,1.3-0.9
						c-3.6,2-7.4,3.7-11.2,5.3c-2.5,1-1,0.4,4.4-1.6c-1,0.4-2.1,0.7-3.1,1c-1.8,0.6-3.7,1.1-5.6,1.6c-1.9,0.5-3.8,0.7-5.6,1.2
						c10.1-2.7,4.4-0.7,1.3-0.4c-1.6,0.2-3.3,0.2-4.9,0.3c6.2,0.3,7.7,0.3,4.5,0c-0.6-0.1-1.2-0.2-1.9-0.3c-2.8-0.2,8.5,1.5,2.1,0.2
						c4.4,2.5,5.5,3,3.2,1.6c-0.6-0.4-1.1-0.9-1.7-1.4c1.6,1.5,3.1,3.1,4.7,4.6c-0.7-0.8-1.3-1.6-1.8-2.5c0.9,1.9,1.8,3.8,2.8,5.8
						c-0.4-1.1-0.6-1.9-0.8-3.1c0.2,2.1,0.3,4.2,0.5,6.4c-0.1-0.8,0-1.7,0.2-2.5c-0.6,2.1-1.2,4.2-1.8,6.3c0.8-2.6,2.8-4.7-1.3,1.1
						c2.7-3.8-4.6,3.7-1.9,1.8c1.4-0.9,5.6-5.3,7.3-5.1c-0.9-0.1-7.1,4.2-1.9,1.3c1.2-0.7,2.5-1.4,3.7-2c2.7-1.4,5.5-2.7,8.4-3.9
						c2.5-1,6.6-1.6-2.8,0.9c1.3-0.4,2.7-0.9,4-1.3c2.9-0.9,5.9-1.7,8.9-2.4c1.4-0.3,2.8-0.6,4.1-0.9c4-0.9-8.4,1.2-2.9,0.5
						c3.4-0.4,6.7-0.7,10.1-0.9c-8.8-3.3-17.7-6.6-26.5-10c0.2,0.3,0.3,0.7,0.5,1c-0.9-1.9-1.8-3.8-2.8-5.8c0.1,0.3,0.2,0.6,0.3,1
						c-0.2-2.1-0.3-4.2-0.5-6.4c0.1,0.6,0.1,1.2-0.1,1.8c0.6-2.1,1.2-4.2,1.8-6.3c-0.9,2.4-0.2,1.3,2.3-3.5c2.4-3,5.4-5.3,8.8-6.9
						c-4.7,2.5,9.6-3.4,2.2-1c6.6-0.9,8.3-1.3,5-1.1c-0.8,0-1.6,0-2.4,0c11.1-0.2,4.7,0.2,1.9-0.1c-1.7-0.2-3.4-0.5-5.1-0.8
						c-4.8-0.8,8,2.6,1.4,0.2c-0.8-0.3-1.5-0.6-2.2-0.9c-2.5-1.2-1.4-0.6,3.1,1.7c-1-1-2.4-1.7-3.5-2.7c1.6,1.5,3.1,3.1,4.7,4.6
						c-1-1.1-1.8-2.2-2.5-3.4c0.9,1.9,1.8,3.8,2.8,5.8c-0.8-1.7-1.3-3.4-1.5-5.4c0.2,2.1,0.3,4.2,0.5,6.4c-0.1-1.7,0.1-3.2,0.4-4.9
						c-0.6,2.1-1.2,4.2-1.8,6.3c0.2-0.8,0.6-1.5,0.8-2.2c1.2-4-2.9,4.2-1.5,2.4c1.8-2.2-5.4,4.1-2.8,2.6c0.2-0.1,2.6-1.7,2.6-2
						c0,0.6-7.4,3.9-3.5,2.3c1.4-0.6,5.6-2.3-0.4-0.1c-7.8,2.9,2.2-0.1,2.4-0.3c-0.4,0.5-8.9,1.1-4,0.8c1.1-0.1,2.3-0.2,3.4-0.3
						c5.9-0.5-5.6-0.7-2.3-0.2c1.1,0.2,2.3,0.3,3.3,0.4c3.4,0.2-6.3-2.3-1.7-0.4c0.7,0.3,2.7,0.7,3.1,1.3c-0.6-0.8-5.9-3.6-2-0.7
						c1,0.7,1.8,1.5,2.7,2.3c-1.6-1.5-3.1-3.1-4.7-4.6c1.6,1.8,3,3.6,4.2,5.7c-0.9-1.9-1.8-3.8-2.8-5.8c1,2.1,1.7,4.2,2.1,6.6
						c-0.2-2.1-0.3-4.2-0.5-6.4c0.2,2.7-0.1,5-0.7,7.6c0.6-2.1,1.2-4.2,1.8-6.3c-2.5,7.8-6.7,14.6-8.7,22.7
						c-1.5,6.1-1.3,13,0.9,18.9c10.2,27.5,45.7,23.8,66.8,12.5c27.4-14.6,49.5-36.3,64.4-63.5c5.2-9.4-0.7-20.2-9.3-24.7
						c-11.1-5.9-24.6-4.2-35.7,0.8c-3,1.4-6,2.7-9.1,3.9c-5.8,2.3,1.7,0.1,2.4-0.8c-0.4,0.6-4.4,1.5-5.1,1.8
						c-2.8,0.9-5.7,1.8-8.6,2.6c-3.8,1.1-5.7,0,2.3,0c3.7,0.5,7.4,1.1,11.1,1.6c4.5,2.7,5.6,3.3,3.6,1.7c1.6,1.5,3.1,3.1,4.7,4.6
						c-0.7-0.7-1.2-1.4-1.7-2.3c0.9,1.9,1.8,3.8,2.8,5.8c-0.4-1-0.6-1.8-0.8-2.8c0.2,2.1,0.3,4.2,0.5,6.4c-0.1-1.9,0.1-3.6,0.5-5.4
						c-0.6,2.1-1.2,4.2-1.8,6.3c0.4-1.6,1.1-3,1.7-4.5c1.4-3.6-1.6,3.2-1.9,2.8c-0.1-0.1,1.3-1.7,1.5-1.9c2.2-3.1-2.5,3-3,2.8
						c0.2,0.1,2.3-1.8,2.6-2c3.4-2.7-2.3,1.2-2.9,1.6c1-0.9,2.5-1.4,3.6-2c1-0.5,2-0.9,3-1.4c3.4-1.8-7.4,2.4-3.7,1.3
						c2.1-0.6,4.2-1.1,6.3-1.8c3.7-1.1-7.7,0.9-3.8,0.6c1.1-0.1,2.2-0.3,3.3-0.3c1.1-0.1,2.2-0.1,3.3-0.1c3.5-0.3-8.5-0.5-2.5-0.1
						c-5.4-11.4-10.7-22.7-16.1-34.1c-1.2,1.4-2.5,2.7-3.7,4c-2.1,2.2,5.8-5.2,0.9-1c-2.6,2.3-5.2,4.4-8,6.4
						c-1.3,0.9-2.6,1.7-3.9,2.6c8.1-5.6,3.7-2.3,1.6-1.1c-3,1.7-6.1,3.1-9.2,4.5c-6.5,2.8,5.8-1.9,2.4-0.8c-1.7,0.6-3.5,1.2-5.3,1.7
						c-3,0.9-6.1,1.4-9.2,2.2c9.8-2.6,5.4-0.9,2.5-0.5c-1.6,0.2-3.1,0.3-4.7,0.4c-1.6,0.1-3.1,0.2-4.7,0.2c-8.3,0.2,5.8,0.4,0.4,0.1
						c5.4,11.4,10.7,22.7,16.1,34.1c1.2-1.7,2.4-3.3,3.7-4.9c2-2.4,4.7-3.2-1.2,0.9c1.4-1,2.7-2.4,4.1-3.4c0.6-0.5,1.2-0.9,1.9-1.4
						c2.7-1.8,1.7-1.2-3.1,1.9c1.4-0.6,2.7-1.5,4.1-2.1c0.9-0.4,1.9-0.8,2.8-1.2c-5.9,2.1-7.2,2.6-3.9,1.6c1.5-0.4,3-0.8,4.5-1.2
						c3.4-0.7,2-0.4-4.2,0.7c1-0.1,2-0.2,3.1-0.3c-1.2-15.7-2.4-31.4-3.5-47.1c-14.7,5.4-29.6,10.1-44.7,13.9
						c-7.3,1.8-14.6,3.4-22,4.8c-1.8,0.4-3.7,0.7-5.5,1c-6.6,1.2,7.5-1.1,1.3-0.2c-4.3,0.7-8.7,1.3-13,1.8
						c6.3,13.2,12.5,26.4,18.8,39.7c0.9-0.6,1.9-1.5,2.9-1.9c-0.8,0.3-6.3,3.8-2.5,1.6c2.3-1.3,4.5-2.6,6.8-3.9
						c4.1-2.2,8.3-4.3,12.5-6.2c4.5-2.1,9.1-4,13.7-5.8c4.6-1.8-3.1,1.4-3.3,1.2c0.2,0.2,2.9-1.1,3.3-1.2c2.5-0.9,5-1.7,7.5-2.6
						c18.7-6.2,37.9-10.8,57.1-15.2c12.6-2.9,27.1-14,26-28.2c-1.1-14.6-17.1-19.2-29.5-18.9c-5.5,0.1-11,0.3-16.5,0.4
						c8.1,11.5,16.2,22.9,24.3,34.4c-6.2,6-1.9,1.9-0.4,0.5c1.3-1.2,2.6-2.3,4-3.4c2.9-2.4,5.9-4.7,9-6.9c1.4-1,2.9-2,4.3-2.9
						c5.9-3.9-4,2.3-1.1,0.7c3.3-1.8,6.6-3.7,10-5.3c11.2-5.5,22.3-18.2,16.1-31.2c-6.6-13.8-23.8-13.7-36.6-11.4
						c-19,3.4-37,9.1-54.9,15.9c15,8,30,15.9,45,23.9c2.9-4.9-3.8,5.2-0.4,0.7c0.9-1.2,1.8-2.5,2.8-3.7c0.8-1,1.7-2,2.5-2.9
						c4.4-4.7-3.4,2.9-1.1,1c2.2-1.8,4.4-3.6,6.7-5.2c4.5-3.1-7,3.6-1.7,1.1c1.4-0.7,2.7-1.4,4.2-2.1c6.4-3,13.3-9,16.2-15.6
						c2.5-5.6,2.6-13.4-1.5-18.4c-10.4-12.9-29.9-11.7-43.5-5.4c-19.6,9.1-34.7,23.7-45.5,42.1c-5.4,9.3,1,20.3,9.3,24.7
						c11.6,6.1,24,3.6,35.7-0.8c-10.7,4.1,1.3-0.4,4.1-1.3c3.8-1.2,7.7-2.4,11.6-3.5c7.5-2,15.1-3.7,22.8-5
						c-6.8-14.2-13.7-28.4-20.5-42.6c-19.1,9.5-35.4,21.8-50.7,36.6c-6.4,6.2-7.3,17.3-2.2,24.4c6,8.4,17,10.2,26.5,10
						c5.5-0.1,11-0.3,16.5-0.4c-1.2-15.7-2.4-31.4-3.5-47.1c-20.3,4.7-40.7,9.6-60.4,16.3c-24.2,8.2-46.7,18.5-68.1,32.5
						c-8.5,5.5-13.6,17.5-9.1,26.8c4.7,9.7,17.6,14.2,27.9,12.9c34.6-4.4,68.6-12.1,101.3-24.3c12.3-4.6,27.2-12.8,26-28.2
						c-1.1-14.2-17.1-19.9-29.5-18.9c-26.9,2.1-54.9,15.1-70.5,37.8c-4.7,6.8-7.4,13.6-3.6,21.5c3.5,7.3,11.6,12.1,19.7,12.6
						c43.9,2.6,86.9-13.3,116.4-46.6c12.6-14.2,1.4-32.8-16.1-34.1c-19.6-1.4-39.4,1.5-56.9,10.7c-16.6,8.7-31.1,21.2-35.1,40.2
						c-1.5,7-0.3,15.3,4,21.1c7.9,10.9,22.2,14.3,35,13.4c8.6-0.6,17.2-3.3,25.4-6.1c9-3.1,17.9-6.5,26.6-10.4
						c-15-8-30-15.9-45-23.9c-0.8,1.4-1.5,2.7-2.3,4.1c-2.6,4.8,3.3-4.8,0.4-0.7c-1.8,2.6-3.6,5.1-5.5,7.6c-1.9,2.5-4,4.8-6.1,7.1
						c-1,1.1-2.3,2.2-3.3,3.4c5.4-6.8,2.3-2.2,0.4-0.5c-2.3,2.1-4.8,4.1-7.3,5.9c-1.3,0.9-2.5,1.9-3.8,2.7c-1,0.7-6.2,4-1.1,0.8
						c5.1-3.1-0.1,0-1.2,0.6c-1.4,0.8-2.7,1.5-4.1,2.2c-6.3,3.2,5.5-1.4,2.1-0.5c-6.6,1.7-0.9,2,17.1,0.6c4.3,2.6,5.5,3.1,3.3,1.5
						c1.6,1.5,3.1,3.1,4.7,4.6c-0.8-0.9-1.4-1.6-2-2.7c0.9,1.9,1.8,3.8,2.8,5.8c-0.8-1.9-1.3-3.7-1.5-5.7c0.2,2.1,0.3,4.2,0.5,6.4
						c-0.1-2.5,0.2-4.9,0.8-7.4c-0.6,2.1-1.2,4.2-1.8,6.3c3-9.3,9.1-17.4,9.5-27.5c0.6-12.9-5.1-23.2-14.2-31.8
						c-17.2-16.1-47.5-16.5-68.8-9.8c-22.2,7-49.5,26-42.7,53c5,19.7,27.9,28.1,46,29.8c14.6,1.3,29,0.2,42.4-6.5
						c8.4-4.2,16.2-10.6,20.5-19.1c1.4-2.8,2.5-6.3,2.7-9.4c0.3-5.7-1.6-9.6-4-14.6c-4.2-8.6-18.4-10.4-26.5-10
						c-29.1,1.5-56.7,11.4-81,27.4c-13.3,8.8-27.2,23.8-22.3,41.1c4.3,15.3,21.4,22.2,35.7,24.1c14.3,1.8,29.9-0.9,43.5-5.1
						c14.9-4.6,28.3-11.2,41.1-20c11.4-7.9,23.7-18.7,28.7-32c3.2-8.5,3.7-17.3-0.3-25.6c-4.1-8.5-10.4-13.4-18.7-17.5
						c-15.1-7.5-33.4-6.3-49.3-2.6c-18.7,4.4-34.3,14.6-47.9,27.8c-6.4,6.2-7.3,17.2-2.2,24.4c6,8.4,17,10.2,26.5,10
						c15.3-0.4,31.3,0.5,46.2-3.7c11.1-3.2,21.6-8.6,29.5-17.3c7.2-7.9,9.2-16.7,8-27c-0.9-1.9-1.8-3.8-2.8-5.8
						c-2-2.9-4.6-4.9-7.8-6.2c-6-3.2-12-3.8-18.7-3.7c-30.8,0.3-62,11.8-86.1,30.9c-4.8,3.8-8.5,8.5-10.1,14.4
						c-1.3,4.8-1.2,11.2,2.4,15.3c4.2,4.6,8.6,9.7,14,12.9c9.1,5.4,19.2,8,29.8,6.9c12-1.2,23.4-5.9,32.1-14.3
						c3.5-3.4,6.4-6.9,8.4-11.4c2.6-5.7,2.2-10.2,1.6-16.1c-1-9.5-13.4-14.2-21.1-15.5c-15.1-2.5-30.9-1.4-45.6,2.7
						c-6.8,1.9-12.9,5.2-18,10.1c-3.9,3.8-8,9.2-8.2,14.9c-0.5,12.7,10,19.9,21.6,21.8c21.4,3.6,43.1,4.1,63.2-5.2
						c16.6-7.7,34.7-25.2,33.9-45c-16.9,8.1-33.7,16.2-50.6,24.3c10.2,4.9,22.7,6.4,33.9,5.2c8.5-0.9,17.1-3.5,23.9-8.9
						c4.9-3.9,10.7-10.8,11-17.6C1900.1,1280,1883.7,1272.1,1869.7,1273.6L1869.7,1273.6z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1889.5,1269c7.9,0,17.8-4.1,23.9-8.9c4.9-3.8,10.8-10.8,11-17.5c0.6-16.9-15.9-22-29.8-22
						c-7.9,0-17.8,4.1-23.9,8.9c-4.9,3.8-10.8,10.8-11,17.5C1859.1,1263.8,1875.7,1269,1889.5,1269L1889.5,1269z"/>
				</g>
			</g>
			<g clip-path="url(#SVGID_2_)">
				<g>
					<path fill="#DC8400" d="M1989.6,1278.3c4.2-4.2,8.3-8.3,12.5-12.5c3.5-3.5,7.7-8.5,6.3-14c-1.4-5.2-7.7-8.1-12.4-9
						c-7.8-1.5-16.2-0.9-23.8,1.3c-7.4,2.2-15.7,5.2-21.3,10.8c-4.2,4.2-8.3,8.3-12.5,12.5c-3.5,3.5-7.7,8.5-6.3,14
						c1.4,5.2,7.7,8.1,12.4,9c7.8,1.5,16.2,0.9,23.8-1.3C1975.8,1286.9,1984,1283.9,1989.6,1278.3L1989.6,1278.3z"/>
				</g>
			</g>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1834.7,1112.7
				c-43.9-28.7-79.8-69.7-102.5-117c-5.7-11.8-10.6-24.4-10.7-37.5c-0.1-27.2,21.3-50.6,46.2-61.4c24.9-10.8,52.9-11.5,80-12.1
				c-29.3-10.5-55.1-30.3-72.8-55.9c-11.3-16.4-19.5-36.2-16.4-55.9c3.2-20,17.7-36.6,34.9-47.4c36.4-22.8,86.5-21.1,121.3,4
				c-28-40.3-12.9-100.8,25.8-130.9c38.8-30.1,94.1-32.4,140.1-15.1c-6-17.3,1.5-37.3,15-49.6c13.6-12.3,32.2-17.7,50.4-19.2
				c63.9-5,128,40.9,143.8,102.9c15.9,62-18.3,133.1-76.7,159.4c18.3,7.5,37,15.2,51.4,28.8c14.3,13.6,23.4,34.5,17.6,53.4
				c-3.6,11.6-12.4,21-22.3,28.2c-23,16.9-52,23.6-76.3,38.5c-24.3,14.9-44.6,44.5-33.8,70.9c-30.5-0.8-61.1,7.6-86.8,23.9
				c-4.8,3-9.6,6.6-11.8,11.8c-7,16.9,16.4,28.9,33.5,35.4c31.5,12,58.6,37.4,68,69.7c9.4,32.3-1.3,70.7-28.8,90.1
				c-19.1,13.4-43.3,16.8-66.6,19.1c-64.4,6.3-130.2,7.3-193.2-7.6c-63-14.9-123.5-46.7-164.2-97.1c-7.5-9.4-14.6-20-15.5-31.9
				c-1-13.4,5.8-26.1,12.5-37.8c21.8-38.6,43.7-77.1,65.5-115.7"/>
			<path clip-path="url(#SVGID_2_)" fill="#999999" stroke="#808080" stroke-width="2" stroke-miterlimit="10" d="M1974,881.6
				c8.5-4.8,16.9-12.1,17.5-21.8c0.8-12.7-12.1-22-24.2-26s-25.8-5.9-34.7-15c-10.2-10.5-9.9-29.5,0.8-39.6s29.6-9.5,39.5,1.3
				c0.4-9.9,12.1-17.6,21.4-14.1c9.3,3.5,13,17,6.7,24.7c-8.7-32.1,12.7-69.1,44.9-77.7c16.5-4.4,35.3-1.4,48,9.9
				s17.7,31.4,9.6,46.3c-14.8,27.1-60.8,18.5-74.9-8.9c-14.1-27.5-4.6-61.3,10.6-88.2c9.9-17.5,23.9-34.9,43.6-38.9
				c19.7-4,43.4,13.7,38,33c35.6-7.2,60.6-47.5,51.2-82.6c-4.1-15.4-13.6-32.6-4.8-46c3.5-5.3,9.4-8.7,15.4-10.8
				c17.8-6.3,40-1.6,51.1,13.6c11.1,15.3,6.8,40.6-10.3,48.6c23.2-2.5,49.3,8.3,57.9,30c10.4,26.3-9.4,56.8-35.3,68.2
				c-25.9,11.4-55.5,9-83.7,6.3c17,1.5,34.5,6.1,46.8,17.8c12.3,11.8,17.4,32.3,7.6,46.2c-7.2,10.2-20,14.5-32.1,17.8
				c-36.9,9.9-74.9,15.7-113,17.1c13.7-4,28.6,9.4,28.3,23.7c-0.4,14.3-13.3,26.4-27.5,28.3c-18.7,2.6-44-6.5-52.5,10.3
				c-10.2,20.1,23.5,35.4,28.8,57.3c-21.2,15.2-61.7,0.8-69.9,25.5c-5.2,15.7,10.9,30.3,26.3,36.3c15.4,6,33.6,9.7,42.4,23.7
				c11.1,17.7-0.8,42.6-19.2,52.6c-53.4,29.1-159.5,3.6-187.1-52.8C1799.7,913.2,1929.7,906.5,1974,881.6z"/>
			<g clip-path="url(#SVGID_2_)">
					<path fill={colorF1} style={{ transition: "all 1s cubic-bezier(0.26, 1.39, 0.86, -0.49) 0s"}} d="M1787.4,1154.1c20-48.4,40-96.7,60-145.1c5.9,18.1,11.8,36.2,17.7,54.3
					c27.1-55.6,71.4-102.7,125.2-133.2c-7.3,61.3-11,123-10.9,184.7c21.2-2.4,42.3,16.3,42.5,37.6c9.7-5.9,18.3-13.5,25.5-22.2
					c-9.6,39.9,11.7,81.5,7.1,122.3c-6.4,56.6-60.2,95.9-113.5,115.8c-45.5,17-94.2,25.3-142.8,24.4c-59.6-1.1-114.6-42.6-106-108.5
					c8.1-61.4,71.6-102.7,80-165.8C1777.8,1130,1782.5,1142.2,1787.4,1154.1z"/>
			</g>
					<path clip-path="url(#SVGID_2_)" fill={colorF3} stroke={colorF2} style={{ transition: "all 1s cubic-bezier(0.26, 1.39, 0.86, -0.49) 0s" }} stroke-width="20" stroke-miterlimit="10" d="M1797.8,1229
				c0,7.6,0.1,15.1,0.1,22.7c26.6-19.5,46.3-48,55.1-79.8c-0.3,19-0.6,38.1-0.8,57.1c14.4-3.1,16-22.3,18.1-36.9
				c3.5-24.1,18-46.5,38.7-59.5c-9.6,17.7-19.4,38.6-11.7,57.2c3.1,7.6,9,13.9,12.2,21.4s2.7,18-4.3,22.2c11.3-4.2,21-12.4,27-22.8
				c11.4,16.9,23.3,35.5,21.7,55.8c-1.7,20.6-17,37.5-33.6,49.7c-33.8,24.9-106.5,54-145.9,25.6c-14.5-10.4-16.4-25-14.1-42.1
				C1763.5,1275.3,1797.9,1250.3,1797.8,1229z"/>
			<path clip-path="url(#SVGID_2_)" fill="#1A1A1A" d="M0.5,409.3c10.5-0.9,21.1-0.3,31.5-0.5c38.7-0.9,77.5,1.1,116.1-4.2
				c8.5-1.2,17.2-0.2,25.7,1.2c5.5,0.9,8.5-1.8,7.6-8.5c-3.5-26.1,6.6-50.6,3.9-76.5c-0.6-5.5,1.9-12.4-4.5-15.1
				c-2.4-1-2.4-5.9-0.7-7.7c10.8-11.1,0.5-10.9-3.7-13.9c-4.7-3.4-9.4-6.7-13.9-11.3c5.5,2.5,11,4.8,16.4,7.6
				c4.8,2.5,6.9,0.8,6.5-6.5c-2.3-41,1.8-81.9-0.8-123.1c-1.1-17.2,5.7-34.7,4.3-52.8c-2.5-32.9,2.9-66-1.2-98.9
				c5.7,0.2,11.6-2.7,17,2.3c-1.9,1.1-5.4,1.8-3,5.6c2.6,4.1,3.5,8.7,4.8,13.5c0.5,2.1-0.1,5.8,2.7,5.4c1.8-0.3,3.6-1.3,4.2-4.2
				c1.5-6.7,2.9-13.4,4.4-20.1c5.1,0,10.2-0.1,15.3-0.1c0.4,7,2.4,14.5-0.3,20.9c-2.1,4.9-0.1,7.9,2,8.2c8.2,1.1,12,11.2,18.3,16.2
				c6.5,5.1,8.6,2.7,8.8-6.7c0.2-12.8,0.5-25.6,0.7-38.4c13.6,0,27.2-0.1,40.7-0.1c2.9,5.6,8.3,11.4,3.6,18.5
				c-3.6,5.5-3.1,11.2-3.2,17.1c-0.3,17.9,1.7,35.9,1.4,53.5c-0.3,24.2-6.7,48.2-0.2,72.6c0.5,1.7,0.4,5.2-0.2,5.6
				c-7.6,5.4-4.2,15.2-4.7,23.1c-1.5,23.3,2.4,46.7-0.2,70.2c-2.5,22.6,2.1,45.5-0.5,68.3c-1.4,11.6,0.5,21.1,10.9,22.2
				c1.3,0.1,2.5,2,3.7,3l0-0.1c-1.8-0.4-3.7-0.6-5.4-1.3c-8.3-3.6-13.2-3.2-12,12.7c0.7,8.5,4.3,20,3,23.9
				c-6.7,21.2,6.1,11.1,9.1,11c41.6-1.3,83.3-2.1,125-1.9c49.9,0.2,99.8-4,149.9-3.1c47.9,0.9,95.9-3.9,143.8-5
				c24.8-0.6,49.7,1.3,74.5,2.1c1.1,0,2.1,0.5,2,2.4c-1.5,3.7-6,0.9-7,6.7c15.7,0.7,31.1-2.3,46.6,0.1c-9.4-6.8-19.2-6.2-29-5.9
				c-2.8,0.1-6.2-0.7-6.1-4.5c0.1-5.4,3.9-5.1,6.9-4.6c30.5,5.6,60.8-1.3,91.1-2.6c10.4-0.4,21.4-4.8,31.7,6
				c-7.9,4.7-14.2,7.2-22.1,2.6c-7.3-4.2-15.5-0.6-22.9,4.8c8,1.7,16.4-2.9,26.4,2.7c-13.3,8.1-26.1,1.4-39,7.7
				c6.7,8.2,11.9,16.3,18.2,21.7c8,6.9,10.8-12.3,19-10.2c-1,6.9-6.1,6.9-7.6,11.4c-1.1,3.3-7.1,3.5-4.1,8.5
				c1.8,3.1,2.8,8.2,6.7,9.2c7.3,2,14.7-4.6,16-15c0.5-4-0.8-8.3,1.4-11.9c1.7-2.8,0.8-9,5.2-8.2c3.7,0.7,2.3,5.6,3.4,8.4
				c3.1,7.5-0.3,17.3,4,24.2c3.2-1.3,1.5-2.8,1.2-4.6c-0.9-5.8,2-8.6,4.1-3.8c4.9,11.5,4.4-3.1,6.9-2.1c0.5-7.9,0.8-14.9-7.1-16.6
				c-8.7-1.9-5.6-12.7-6.1-20.1c-0.4-5.5,4.1-6.6,6.4-5.4c7.2,3.8,14.8,7.5,20.8,13.9c3.4,3.6,1.4,12.6,1.4,19.2
				c0,2.6,0,5.1-0.2,7.7c-1.2,16.8,2.2,29.5,15,34.8c2.8,1.2,5.7,3,3.8,10.1c-3.7,13.6-2.4,28.1-0.7,42.3c0.5,3.9,1.4,7.2-0.4,11.5
				c-6.5,15-4.2,24.7,6.8,31.4c-7.6,12.4-7.4,15.4,3.3,23.1c38.5,27.9,77,55.4,115.7,82.9c3,2.2,5.6,7.3,9.9,4.5
				c3.9,7.2,9.2,10,15.5,8.9c18.6-3.2,37.2,1,55.8,0.9c29.6-0.2,59.1-3.4,88.7-2.9c11.4,0.2,22.9,13.7,23,29.6
				c0.1,26.8,6.2,37.1,24.4,41c17.2,3.7,34.5,6.8,51.7,10.4c24.9,5.2,50,7.8,74.9,13.5c4.4,1,7.5,10.4,11.5,4.7
				c3.1-4.5,1.2-13,4.3-18.6c0.8-1.5,0.8-3.8,1.2-5.7c6-13,3.1-30.7,10.8-42.4c14.5,17.6,11.6,42.1,14.2,64.3
				c1.2,10.1,3.2,20.3,2.9,30.3c-0.6,19.2,8.5,20.5,18.4,23c4.7,1.2,6.1-1.6,7-6.1c1.2-5.8-0.3-7.6-5.1-7.6
				c-10.2,0.1-10.8-13.1-11.1-21.9c-0.2-6.7,6.8-7.9,11.7-10c9.3-4,16.5,5.6,25.4,5.1c14.3-0.8,28.6,0.5,43,2.3
				c18.2,2.3,36.6,4.5,54.7,5.1c17.8,0.6,35.7,3.3,53.6,1.7c5.5-0.5,11.3,1.9,15.8,7.7c-15.3-1.2-30.5-3.1-45.8-3.3
				c-24.8-0.4-49.6-3.8-74.3-5.4c-19.6-1.2-40.8-10.8-59.1,9.2c-0.2-5.1,4.9-7.9,3.1-13.5c-1.6-2-2.7,0.5-3.9,0.6
				c-2.4,0.1-4.9-1.1-7.3-1c-6.1,0.3-9.2,8.6-5.4,14.7c4.7,7.7,9.1-2.1,13.8-1.1c-6.7,8.3,3.5,13.7,1.9,21.7
				c-2.2,11.3,3.6,11.5,9.6,12.6c11.2,2.1,21.7-4.9,32.7-3.2c1.5,6.2-3.7,4.8-4.8,8.2c-0.9,2.6-4.2,3.6-3.3,7.2
				c1.3,5.3,3.9,1.3,6,1.5c4.5,0.4,8.5,5.3,7.5,11.5c-1.7,10.2,2.9,11.6,8.3,14c-14.6-4.6-12.9,13.5-18.3,27.4
				c1-13.3,0-19.5-7.7-14.9c3.9-5,0.3-7.7-2.1-8.1c-5.4-1-11-4-16.4-0.1c-14-10.3-29.6-7.5-44.5-10.5c-4.1-0.8-4.4,4.4-4,8.5
				c1,9,2.3,17.8,3.4,26.8c-1.5,6.2-1.3,12.3,1.2,18c-2.7,18.2,0.3,27.8,9.2,29.9c16.7,3.1,33.5-2.7,50.3,1.1
				c0.8,4.3,2.6,4.1,4.7,2.1c20.4,0.6,40.7,1.3,61.3-3.7c-2-12.4-4-24.2-5.9-36.1c0-2.6-0.6-5.6,2-6.6c3.6,5.1,3.5,12,4.6,18.3
				c5,27.4,4.9,27.4,25.1,27.1c17.2-0.2,34.5-1.6,51.7-2.7c7.8-0.5,9.2-4.3,11.4-12.9c4-15.5-2.5-31.1,1.8-46.8
				c2.1-7.6-5.4-7.3-8.6-7.6c-23-2-46.1-3.9-69.1-4.2c-7.4-0.1-14.6,5.1-17.2,16.9c-2.9-0.4-5.8-0.8-8.7-1c-8.9-0.8-2.7-16.3-9.6-19
				c5.4-3.1,9.7-11,16.8-7.4c4.1,2.1,8.1,5.5,12.3,5.7c26,1.8,52,3,78,4.2c11.9,0.5,3.5-12.9,6.1-19.1c2-4.8,0.3-10.8-5.7-10.9
				c-12.1-0.1-24.1-3.1-36.3-1.4c-1.4,0-2.8,0.1-4.2,0.1c-3.7-3.2-9.3,1.1-12.1-5.7c6.8-2.7,13.2-2.3,20.4-0.5
				c10.7,2.7,22,1.2,33,2.5c8.9,1,11.2-4,8.8-15.1c-1.3-6.1-1.1-12.3,3.9-13.2c4.6-0.9,10.4-0.5,9.7,10.2c-0.2,2.5,0.7,5.1,0.5,7.6
				c-0.9,10.4,2.9,13,9.6,13.1c10.4,0.1,20.8,1.1,31.2,1.8c-10.4,5.1-20.9,4.4-31.4,2.9c-2.4-0.4-5.8-2.4-7,1.5
				c-2.5,8.3,1.1,15.9,2.2,23.9c1,6.6,4.2,3.8,6.4,4.1c21.9,2.3,43.9,4.3,65.8,6.3c-0.3,1.5-0.6,3-0.8,4.4c-2.7,0-5.4,0-8.2,0
				c-5-9.9-11.2-5.2-17.2-2.9c-12.1,0.4-24.2,0.7-36.3-2.4c-3.9-1-10.5-3.8-10.1,7.8c0.3,8.2,2.2,16,1.9,24.8
				c-0.3,7.6,3,16.5,3.5,25.2c0.5,9.1,3.7,11.9,10.1,11.2c19.4-2,38.8-2.6,58.2-1.4c5.9,0.4,12.5,1.6,17.4-5.9
				c12.2,3.9,24.5,9,36.8,0.6c0.7-4.6-4.3-6.1-2.7-12.1c3.5-12.6-0.1-18.4-9.4-20c-8.3-1.5-15.4,1.3-22.4,6.9
				c0.6-10.6-4.7-9.4-9.6-9.4c-22.3,0.1-44.5,0.2-66.8,0.3c0-1.3,0-2.6,0-3.9c27.3-7.5,55.4,11.9,82.4-4.9
				c-0.2,6.4,0.7,10.9,6.7,9.4c3.1-0.8,6.4,0.6,9.6,0.2c3.8-0.5,9.6,4.4,10.5-5.1c0.8-9.5-4.2-12.8-10-13.7
				c-2.5-0.4-5.1-0.2-7.6-0.3c-0.5-0.4-1-0.5-1.6-0.2c1-3.5,5.9-0.8,5.4-5.4c-0.6-5.5-3.7-1.6-5.8-2c-3.9-0.7-8,2-11.7-1.4
				c4.9-4.6,0.3-15.9,6.9-19.3c1.2,17.8,1.5,18,18.9,14.7c-1.1-9.4,0.1-20.4-10.6-24c5-2.9,9.7-4.1,6.7-11.7c-1.4-3.5-2.5-8-6.3-6.8
				c-4.9,1.6-4.7,7.8-3.9,12.4c1.3,7.1,0.5,10.8-5,11.1c-10.2-6.3-21-2.4-31.5-4.1c-7-1.1-14.4,0.8-22.4-5.6
				c16.1-2.6,30.3,2.2,44.6,2c5-0.1,9.4,0.9,9.2-9.7c-0.2-10.1-4.7-8.8-9-8.6c-21.3,1-42.5-5.2-63.8-3.2c-4.2,0.4-8-1.1-10.4-3.2
				c24.5,1.3,50.1,2.5,75.8,4.1c5.9,0.4,8.6-2,9-10.9c0.5-9.7-2.3-12.6-8.7-12.9c-27.2-1.4-54.3-2.9-81.4-4.8
				c-12.7-0.9-25.5-0.1-37.9-5.1c15.6-5.6,31.2-4.3,46.5,1.5c5.2,1.9,10.4,3.8,15,1.5c8.9-4.6,17-2,25.6,1.1c8,2.9,16.4,6,24.2-1.7
				c27.1,1.7,54.2,3.7,81.3,4.8c6.2,0.2,11.7,6.7,18.1,3.4c5.4-1.9,15.2,9.7,15.3-8.3c0.2-33-0.5-66-0.9-99
				c0.5-10.2-2-20.3-0.1-30.5c0.1-9.1,0.1-18.1,0.3-27.2c0.2-13.4-4.2-17.2-12.9-12.5c-1.2-1.1-2.4-2.2-3.6-3.3
				c4.4,0.4,4.8-2.9,4.2-7.9c-0.1-1-3.7-2.3-0.3-3.4c11.4-3.8,3.3-18.3,7.4-26.1c1.4,23,7.6,24.5,27.3,17c-6.2-1.1-8.5-4.1-6.5-11.2
				c0.7-2.7,0.5-6.7-2.6-6.8c-2.1,0-4.5,0.8-5.6,3.9c-1.2,3.1,0.6,7.2-1.7,10.1c-2.1-3.7-0.4-8.6-3-12.6c-5.7-8.9-3.2-16.9,6.1-21.9
				c2,3.9,4.6,6.1,8.1,6c6.8,2.8,15.4,7.7,20.4,1.9c6.9-7.9,5,4.5,8.4,4.9c4.5,0.6,0.8,5.5-0.4,8.6c-4.5,11.6-3,16.6,5.8,19.2
				c4.3,17.4-7.2,14.3-12.7,18.9c-5.9-0.3-11.8-0.2-17.7-0.8c-8.2-0.9-11.5,4.9-11.5,15.5c0,35.2-0.5,70.4,1.1,105.7
				c0.7,16.7,1.3,34.1-1.6,50c-2,11.1,1.1,11.6,5.9,12c8.1,0.8,16.2-0.2,24.2,0.7c53.1,5.6,106.4,5,159.6,9.8
				c59.5,5.3,119.2,5.2,178.8,7.7c3.5,0.1,8.2-2.6,10,5.8c-66.1-2.6-132-5.2-197.8-7.9c-1-2.7-2.8-2.8-4.7-2.8
				c-29.4-1.1-58.9-2.2-88.3-3.2c-2.5-0.1-5.2-0.6-7.1,2.8c-1.7,2-1.7,4.5-1.2,7.2c0.2,4,0.3,7.9,0.5,11.9c-0.3,1.4-0.5,2.9-0.8,4.3
				c-2.3-0.1-4.6-0.3-6.9-0.4c3.9-6.6,6.4-14.6,3.3-22.2c-2.8-6.9-9.1-4.9-14.1-5.3c-3.7-0.3-7.3,0-11-0.2
				c-17.8-0.6-17.7-0.6-15.7,23.3c0.3,3.5,0.4,7,0.5,10.6c-2,12.2,8.7,5.6,10.7,14.1c-13.9-2-11.1,8.8-8.4,19.3
				c-0.9,6.6,1.3,11.6,4.5,15.9l-0.3,0.2c-9.2,3.8-18.1,0.8-26.1-4.7c-6.7-4.6-10.7,1.3-14.9,4.5c-3.3,2.5-2.6,9.9-0.5,13.7
				c1.9,3.4,8.4,3.6,0.3,8.1c-3.3,1.8-1.8,8.5-0.7,12.5c1.1,4,4.4,5.6,7.3,5.4c4.3-0.2,3.9-5.7,3.8-9.3c-0.4-10.3,4.8-17.6,5.5-28.8
				c4.4,6.1,2.1,12.7,4.7,17.7c7.5-3.3,14.9-3.8,21.1,4.9c0.1,2.1,0.1,4.3,0.2,6.4c-4.8,4.3,0.1,4.4,1.3,6.2c1,12.3,2.1,24.7,3.1,37
				c-2.1,0.9-7.4-12.1-6.3,2.9c0.3,3.6,0.6,7.5-2.6,10.9c-0.7-6-1.2-11.1-1.8-16.1c-2.7,12,1.1,24.1-1,36.1c-4.3,0-8.6,0-14.9,0.1
				c12.2-6.7,12.8-10.8,7.9-38.1c-4.5,6.7-11.4,10.2-13.4,20.8c-0.8,4.2-4.8,7.2-8.9,1.8c-4.2-5.7-5.6-2.4-6.7,4.5
				c-2.4,14.7-0.2,29.6-2,44.1c-1,8.7-4.9,18.6-1.4,25.7c4.3,8.7,5,15,0.4,22.9c-2.6,4.5,2.2,5.8,2.4,9.5c0,0.8,2.6,0.4,3.3-1.9
				c3-10.7,4.9-27.6,1.4-31.7c-7.4-8.8-1.9-15.3-2.1-23.4c5.4,10.4,13.1,11.9,20.9,12.8c6.7,0.8,9.6-2.1,11.5-12.8
				c2.1-11.9-2.7-23.4,1.3-34.5c5.9,4.4-1.1,15.5,6.6,21.3c-4-19.2,6.2-32.4,6.6-48.8c-0.3,17.2,2.5,33.5,7.6,49.1
				c0.1,3.1,0.2,6.2,0.4,9.3c-4.7,14.1,6,24.7,4.5,38.1l0.1,1.2c-0.6,17.2,2,33.8,5.2,50.2c-5.9,8.8,3.8,11.9,3.5,18.6
				c-0.5,3.4-1.4,6.9,1.6,9.2c0.1,2,0.2,4,0.4,6.1c2.8,8-1.7,16.9,1.8,24.8c3.8,4-13.7,14.6,2.4,14.8l1,0.4l1.1-0.2
				c1.5,0,3,0.1,4.4,0.1c18.4,1.8,36.9,3.9,55.4,3.1c-1.6,3.8-4.7,2.5-6.9,3.9c-15.7-1.4-31.3-0.3-47,0c-8.3,0.2-10,8-7.7,14.8
				c4.5,13.2,4.1,27.7,7.4,41c-0.5,30.4,5.7,59.3,10.9,88.4c-1.2,14.4,2.8,27.9,4.1,41.9c-6.6-0.3-13.4-0.2-11.6-14
				c4.1-6.6,2.4-14.3,2.1-21.7c-2.9-14.2-5.7-28.2-4.6-43.5c0.6-8.7-1.9-17.9-3.4-29.6c-4.8,8.5-1.1,15.9-3.8,21.8
				c-2.8-0.1-3.1-10-6.8-4.9c-2.6,3.5-1.8,8.1,2.2,11.1c5.3,4,2.5,10.8,2.1,16.5c-2,26.5,1,52.5,3.9,78.6c1.3,11.7,7,6.6,11.3,7.7
				c2.1,6.2,0.2,15.9,8,17.4c1.6-5.2,0.3-9.9-0.7-14.6c1.3,0,2.6,0,3.9,0l0.3,1c-0.1,1,0,2,0.2,3c-1.7,11.8,2.7,15.8,10.3,15.8
				c17.6,0,35.2,0.1,52.8,0c2.5,0,6.8-0.3,7.1-1.7c2.8-14.1,10.6-8.5,16.9-9.3c14.2-1.6,28.6,1.3,42.7-2.2l0.9,0.4l1-0.2
				c1.5,1.7,3,2.2,4.4-0.2c7.1,0.7,14.9,5,21-0.8c8.5-8,5.5-46-3.8-51.9c-4.2-2.7-9.2-2.8-13.8-4.1l-0.6-0.5c-1.3-1-2.6-2-3.9-3
				c-1.6-1.4-3.7-2.5-4.5,0.1c-0.6,2,1.4,2.5,2.8,2.5c-0.1,0.9,0.1,1.7,0.7,2.2l-0.1,0.5c-2.8-1.3-6.7,3.5-8.6-3
				c0.1-1.3,0.1-2.7,0.2-4c-0.6-6.2,0.9-11.9,1.7-17.8c1.2-8.8,2-13,7-2.4c2.3,4.9,7.4,5.3,9.5-1.6c1.7-5.8,4.8-5.1,8-5.5
				c-1.7,4.2-3.8,8.6,2.2,9.3l-0.1-0.1c1.8,8.5,7.5,8.4,9.9,4.4c5.9-9.7-2.6-15-6-19.7c-5.1-7-2.2,7.4-6.2,6.3
				c0.3-7.1-1.8-10.1-7.1-9.9c-10.3,0.4-20.6,0.2-30.8,0.2c16,1.1,30.7-13.8,47.2-7.9c2.4,0.8,3.3-3.5,3.4-6.6
				c0.2-19.1,5.3-37.4,4.7-56.9c-0.3-8.4-1.9-12.3-7.1-14.4c0-2.2,0-4.3,0-6.5c0.2-1,0.2-2,0-3.1c0.1-1.1,0.2-2.2,0.3-3.2
				c0.9-6.5-1-9.1-5.7-9.4c-10.9-0.7-21.8-2.2-32.6-3.1c-4.7-0.4-9.6-1.1-12.3,6.4c0.2-3.6-0.9-6-3.7-5.8
				c-10.2,0.5-20.6-3.2-30.7,0.3c-4.1,1.4-7.2,1.9-8.1-6.1c27.8,1.8,55.3,3.5,82.9,5.3c11.7,0.8,16.4-5,17.1-21.5
				c0.2-4.1-0.3-8.2-0.4-12.3c3.4-16.5,2.4-18.6-7.6-15.9c-26.3,7.1-52.8,11.5-79.6,11.3c-8.8-0.1-12.7,3.3-9.1,16
				c-1.9-0.1-3.9-0.1-5.8-0.2c0-1.6,0.1-3.3,0.1-4.9c2.6-11-2.8-9.9-7.7-10.6c-13.1-1.9-26.5-0.6-41.3-6.5c3.2-0.3,4.3-0.4,5.4-0.5
				c15.4-0.3,31.5,12,46-5c0.1-0.2,1.3,0.7,1.4,1.3c2.4,10.4,8.6,5.9,13.1,6c27.2,0.7,54.1-5.2,81-9.7c4.4-0.7,11-1.5,10.1-12.2
				c0.2-3.1,0.3-6.2,0.5-9.3c0.7-3.6,2.1-7.8-2.8-7.2c0.3-0.2,0.7-0.3,1-0.5c2.5-1.1,3.4-3.6,3.4-7c0.1-8.7-0.1-17.4,0.4-26
				c0.4-9.1-4.9-5.2-9.4-7.9c15.7,1.4,9.7-14.4,9.8-21.8c0-8.5-7.1-4.1-10.8-3.2c-18.7,5.1-37.5,7.6-56.4,10.2
				c0.3-1.4,0.5-2.7,0.8-4.1c20.7-0.4,40.8-6.8,61.2-10.2c3-0.5,7.8-0.8,8.4-7.6c0.5-5.7-3.2-5.9-5.7-7.6c-0.2-1-0.5-2-0.7-3
				c0.3-1.9,0.6-3.9,0.9-5.8c6.3-5.7,8.5-18.4,3.1-24.2c-3.7-4.1-4-7.9-3.6-13.1c4.4-0.4,5.1-4.3,6.5-9.6
				c3.8-13.9-0.6-29.1,4.4-42.5c2.8,1.1,4.1,2.6,3.3,7.6c-1.7,10.2-2.4,20.7-3.7,31c-0.4,3.5-2.1,7.1,2.1,7.9c4,0.7,3.5-3.8,4.1-6.9
				c0.4-1.9,0.8-3.8,1-5.8c2.1-21.7,2.1-21.8,17.3-16.7c3.6,1.2,8.5,1.1,6.3-4.7c-3.7-9.9,0.2-28.3-14.2-26.5
				c1.8-7.2,10.3,3.6,9.3-8.7c-0.8-9.6-2.8-16.8-11.5-14.7c-3.9,0.9-8,0.3-12,0.3c-0.6-1-1.2-2.1-1.8-3.1c3.4-1.8,4.1-5.4,3.5-9.9
				c3.8-22.1,3.8-22.1-12.1-23.1c-7.6-0.5-15.2-0.9-22.7-1.4c10.4-1.6,20.7-1.5,31-1.8c3.7-0.1,8.7,2.1,8.8-7.1
				c3.4-0.6,5.5,7.9,9.4,2.9c0.9-1.1,1.4-3.7,1.1-5.3c-1.1-6.7-5.5-4.4-8.6-5.5c-0.7-0.2-1.4-0.6-2-0.9c-1.4-0.2-2.8-0.4-4.1-0.6
				c6.8-5.9,14.4-3.1,21.5-2.9c34.7,0.8,69.4,2.2,104.1,3.3c-2.1,2.1-4.3,2.4-6.5,2.5c-30.6,2.1-61.1-3.8-91.6-2.6
				c-3.3,0.1-9.6-5.3-9.8,6.2c-0.1,10.6,4.2,12,10.7,12.1c21.7,0.3,43.4,2.1,65.2,2c31.6,0,63.1,1.9,94.6,5.8c7.5,0.9,7.2,0,7.6-4.9
				c0.7-9.3-0.3-14.6-8.6-13.4c-8.4,1.2-16.9,0.3-25.4,0.4c8.1-2.6,16.2-3.7,24.2-2.5c10.6,1.6,10.2-6.2,10.8-17.2
				c0.8-14.3-6.3-8.3-9.8-8.5c-27.6-1.4-55.2-1.9-82.9-2.9c-2.6-0.1-5.6,1.4-8.5-4c4.6-0.3,8.2-0.9,11.7-0.8
				c27.6,0.6,55.2,1.1,82.8,2c6.1,0.2,9.2-2,7.9-11.1c-1.5-10.3-5-20.7,8.2-21.9c3.3-0.3,8.2-10.8,7.6-20.2
				c-0.4-5.6-0.3-11.3,0-16.9c0.6-10.3,5.1-19.7,11.8-19.2c23.9,1.8,47.7-3.8,71.5-0.7c5.9,0.8,10.6-0.3,9.3-11
				c-2.8-21.9,1.1-43.8-0.2-65.6c-0.3-5,1.1-11-2.5-14.3c-4-3.7-6.7-6-0.8-11.2c3.2-2.8,1.5-8.6,1.5-13c-0.1-8.2-2.9-18.1-0.5-24.2
				c7.2-18.5,1-34.2-2.9-50.6c-1.2-5.1-0.6-8.2,2.3-9.9c2.5-1.5,6.7-2.9,7.3,2.7c1.4,13.7,8.4,8.4,13.6,8.3c4.1-0.1,3.8-6.3,1.5-6.8
				c-8.1-1.7-5.4-11.8-8.2-17.4c-6.8-18.3-14.1-36.3-20.1-55.2c-4-12.7,3.5-24.9,3.1-37.9c-1-29.9,10.3-58,6.4-88.4
				c-1.7-13.1,5.4-24.1,5.7-37.1c0.4-12.5,1-24.6,0.4-37.4c-0.8-15.4,3.6-32.2,9.5-46.8c1.6,1.4,3.1,2.8,4.7,4.3
				c-1,1.2-2.9,2.4-2.9,3.6c-0.5,25.3-5.1,50.3-2.4,75.8c-3.3,5,0.2,8.8,1.2,13.1c-3.1-0.6-5.9-0.8-6.3,5c-0.3,4.8,0.7,8,4.7,7.2
				c-0.5,0.6-0.8,1.5-0.8,2.4c-5.2,0.3-4.2,5.5-4,10c-2.4,7.7-1.5,15.9-2.2,23.9c-1.7,21.5,1.9,27.3,17.7,28.1c0,2.2,0,4.4,0.1,6.5
				c-3.1-1.2-6.2-0.8-9.1,1.2c-2.3-4.2-4.8-10.1-8.6-8.9c-3.8,1.2-1.7,8.7-3.3,13c-1,2.8-0.5,6.8-0.7,10.2
				c-0.5,6.1-1.3,12.2-1.5,18.3c-0.3,9.4,2.3,14.9,9.9,12.5c0.5,2.6,1.1,4.8,3.5,4.6c7.6-0.7,15.2-1.3,22.8-2
				c18.4-1.7,18.4-1.7,18-27.7c3-1.8,6-1.7,9,0c1.1,27.5,1.8,28.2,21.1,27.7c33.1-0.9,66.2-2.8,99.3-2.2
				c36.8,0.6,73.7,1.9,110.4-0.8c9.8-0.7,7,7.9,7.6,14.2c1.2,12.2-5.1,3.5-7.8,5c-7.2,4-16.2-4.5-22.6,6c-1.7-0.3-3.4-0.6-5.2-0.9
				c-10.6-7.5-21.7-1.7-32.6-2.6c-5.7-1.2-4.1,3.9-3.6,8c-4.4-7.8-10.2-7.8-16.3-5.6c0.5-3,1.2-6-2.4-5.8
				c-5.7,0.2-11.5,0.1-19.1,0.1c3.1,4.7,6.3,7.1,5.9,12c-2.1-0.8-5-0.6-6.1-2.5c-8.3-13.7-18.9-8.9-29-7.6c-12-2.3-24-0.3-35.9-0.1
				c-4,0-5.8,2.4-6.4,7.6c-3.7,1.6-5.4-4.8-8.8-4.3c-8.8-4.4-17.8-1.9-28.2-1.4c5.1,9.5,9.9,15.4,17.6,14.7
				c-1.2,13.9,11.2,14,13.1,24.8c-10.2-0.2-13.7-16.2-22.9-18.8c0.3-2.1-0.3-3.3-1.9-3.3l-0.7,0.3c-4.3-9-10.7-15-17.3-19.6
				c-8.4-5.8-12.9-1-12.8,12c0,5.9,0.2,11.9,0.3,17.8c-5.6-7.5-5.6-17-5.4-26.9c0.1-3.8,1.3-8.6-1.9-11.1c-3-2.3-16.1,8.2-16.7,13.4
				c-1.1,10,0.5,20.2-1.5,30.4c-1.1,5.6,3,8.1,6.8,9.8c9.8,4.3,14.7-3.7,18.8-14c2.3,15.5,9.3,15.2,17.6,10c0.6,1.7,1.1,4.8,1.8,4.9
				c10.1,1.5,20.2,2.4,30.4,3.7c5,0.6,4.6-4.7,5.3-8.9c8.4,8.9,13.6-2.6,20.1-6.1c-2,3.9-3.9,7.8-6.4,12.7c8.3,1,15.8,2.1,23.3-2.3
				c-6-3-7.6-19.5-17.1-10.1c1.9-7.7-1-12.8-5.2-16.5c-3.5-3-6.6-6.1-8.2-11.5c5.5,0,8.8,5.5,12.2,10.2c2.9,4,5.5,8.3,10.1,4.9
				c0.1,3-0.5,6,1.4,8.5c11.1,14.6,23.2,22.6,38.4,12.9c13.7,7.5,27.4,2.7,40.9,2.4c0.3-6.3-7-5.6-3.1-12.1c6-2.3,8.3,14.1,15.4,6
				c5.4,8.7,18.1,8.8,26.5,0.3c8.9,5.7,18.4,6.5,27.7,4.5c4.4-0.9,7.3,2.4,10.6,3.9c2.7,1.2,1,7.5,0.9,11.3c0,3.8-3.4,2.7-5.4,2.8
				c-18,0.8-36.1,1.8-54.1,2.1c-43.6,0.7-87.2,1-130.8,1.3c-11.4,0.1-22.8-0.8-34.1-0.7c-12.5,0.1-19.2,8.3-17.1,25.8
				c4.7,39.2-0.3,78.4,2.4,117.3c1.2,17.3,2.3,34.5,1.5,51.7c-0.4,8.8,2.7,10.4,7.9,10.3c12.5-0.3,25.1-0.4,37.6-0.6
				c34.9-0.5,69.9-1.1,104.8-1.6c7.5-0.1,15,3.6,22.4-0.4c10.9,4.8,22.1,3.1,33.2,2c9.8,0.7,19.6,1.1,29.3-0.9
				c0.5,0.9,1.1,1.7,1.6,2.6c-0.9,3.9,0.6,8.5-2,11.8c-4.5-4-7.2,4.8-12.2,2.7c-12.9-5.4-26-4.3-38.9,1.5c-2,0.9-3.6,2.2-6.1,0.7
				c-19.2-12-39.6-5.3-59.4-7.1c-14.5-1.3-29.1,0.8-43.5-3.8c-2.4-0.8-5.6-1.8-6.3,3.3c-0.6,4.4-1.4,9.5,2.9,11.8
				c3.7,2,7.3,3.8,11.1,5.8c0-4.7-0.9-10.3,2.4-12.8c4.3-3.3,4.5,5.1,7.6,6.5c5.1,2.4,6,9.7,8.2,15.6c2.9,7.8,6.8,14.2,14.2,9.4
				c1.7-1.1,3.3-1.2,5.2-0.9c23.7,3.7,47.4-0.2,71.1,0.9c15.3,0.7,30.7-1.9,45.7,4.4c0,1.8,0,3.6,0,5.4
				c-38.7,0.9-77.4,3.4-116.1,1.9c-4-0.2-8.1-0.8-11.6,3c3.8,2.5,3.9,10.6,8.9,11.4c1,0.2,2.1,0.9,3,1.8c2.1,2.1,4.3,4.5,3.4,8.7
				c-0.9,4.1-4.1,6-6.1,4.8c-9.6-5.5-21,0.8-29.8-9c-2-2.2-3.9-6-6.3-1.9c-2.8,4.8-4.6,10.9-3.7,17.1c0.6,4.8,4.2,2.8,6.5,3.3
				c8,1.7,15.9-3.1,24.7-0.1c0.6-0.1,0.4-0.7,0.2-0.7c-1.1-0.1-1.3,0.6-0.6,1.8c0.2,0.3,0.6,0.4,0.9,0.6c0.1-0.4,0.2-0.9-0.4-1.6
				c0.8-4.7,4.1-12.5,5.8-3.4c1.7,8.7,4.9,5.6,7.6,5.9c3.3,0.3,6.6-4.4,10.2-0.5c30.4,2.4,60.8,2.5,91.2,1.7
				c3.9,0.1,7.8,0.3,11.7,0.4c0.3,3.6-1.3,4.3-3.3,4.2c-11.9-1-23.8,2-35.7-1.9c-9.9-3.2-14.9,10.2-10.4,26.7
				c-0.4,1.8-0.8,3.6-1.3,5.5c-0.9,0-1.8,0-2.7,0.1c-0.6-1.9-1.2-3.7-1.8-5.6c2.5-22.1,1.1-24.5-14.1-24.7
				c-18.6-0.4-37.2,1.3-55.8-2.1c-0.7-0.2-1.4-0.3-2.1-0.5c-8.6-0.4-17.1-0.7-25.7-1.3c-8.7-0.6-13.5,3.7-11.7,16.9
				c-1.6,2-3.5,3.8-4.8,6.2c-4.6,8-9.3,15.3-17.8,11c-10.4-5.4-7.9-15.4-4.9-25.8c1.9-6.5,0-9.5-5.1-9.4c-9.6,0.2-19.1,0.1-28.7-0.1
				c-6.5-0.1-10.8,0.4-7.6,12.9c2.6,9.9-3.8,15.9-8.4,22.2c-4.6,6.1-5.9-2.1-8.9-3.2c-5.7-2.1-11.2-1.7-16.8,6.7
				c9.9,3.8,18.4,7.8,27.5,8.3c2.1,0.1,5.4-1.8,5.7,1.7c1.9,21.8,14.7,17.9,23.8,18.4c24.1,1.2,48.4,0,72.5-0.4
				c3.8-0.1,8.8,3.8,8.6-7.6c-0.2-10.9-5-6.3-8.3-6.5c-26.4-1.7-52.9,1.9-79.4-2.6c12.8,2.9,24.8-5,38.2-2c17.5,3.9,35.9,0.3,53.9,0
				c2.9,0.1,5.8,0.1,8.6,0.2c0.8,0.1,1.7,0.1,2.5,0.2c5.8,1,11.6,1.7,17.4-0.3c2.3,0.1,4.5,0.3,6.8,0.4c5.9,0.2,11.7,0.3,17.6,0.5
				c-12.4,6.5-25.2,1.6-37.8,2.9c-4.5,0.5-10.8-1.1-10.3,9.1c0.4,10,6.8,5.9,10.3,5.7c36.1-2.4,72.3-1.5,108.4,1.2
				c0.1,14.1,0.2,28.2,0.3,42.3c-4.1,3-9.1-0.7-13,3.3c-3-0.6-5.9-0.3-8.6,1.6c-7.4-0.8-15.6-5.5-19.5,9.3c-0.6,2.3-4.1,3.3-6.2,4.8
				c-4.2-14.9-13.6,0.2-19.6-6.5c-2.1-2.4-8.5-4.6-8.7-0.9c-0.5,9.8-8,16.8-5,27.2c0.9,3.1,3,4.4,5.5,4.2c13.5-1.5,27.2,0,40.3-6.1
				c10.1,1.2,20.3,2.4,30.4,3.5c2.2,0.2,4.4,0,4.4,4.5c0.3,35.9,1.3,71.8-1.2,107.6c0,0.5-0.3,0.9-0.5,1.3c-4.8-0.1-6.9,2.8-6.7,9.9
				c0.2,6.4,1.3,10.6,6.6,10.3c0.1,10.6,0.1,21.3,0.2,31.9c-7.3,3.2-10.2-1.2-9.9-11.3c0.1-4-0.5-8.1-0.6-12.2
				c-0.1-5.1-1.3-9.1-5.4-9.7c-3.8-0.6-4.7,2.2-5.3,7.2c-0.2,1.9-2.3,7.3-5.7,6.7c-3.3-5.2,3.4-7.4,0.9-13.6
				c-10.6,6.9-21.1,1.3-31.7-2.3c-11.7-4-22.8-10.6-34.1-16.5c-4.1-2.1-8.9-8.5-12.8-4c-4,4.7,3.8,8.3,3,14c-0.3,1.9,0.3,4,0.5,6
				c-1.5-1.2-3.1-2.3-4.6-3.5c-1.4-5.9-4.6-8.6-7.7-4c-2.3,3.5-2.2,9.4,2.9,11.1c-0.1,0.9,0.1,1.6,0.4,2.3c0.1,14.2-8,8.5-12.8,9.6
				c-6.1,1.4-4.4-6-4.9-10.4c-0.6-5.5,0-15.3-1.5-15.9c-12.4-5.2,0-10.3-0.4-15.1c10.3,0.2-5.6-8.6,1.7-9.4c-2.2-2-4.5-2.1-5.7,1.3
				c-1.1,3.1-1.7,6.5-5.1,7.5c-0.3-3.6-0.5-7.1-0.7-10.5c0.1-2.1,0.2-4.2,0.4-6.3c4.5-3.3,9.3,1.3,13.8-1.1
				c-2.1-4.1-6.7,1.9-8.6-5.1c6.9,1.7,14.5-9.1,19.4-0.8c5.9,10.1,9.1-4.1,13.3-0.2c0.4,5.8-1.1,14.5,4.1,15.4
				c10.2,1.7,18.6,10.6,28.7,12.2c1.5,0.5,2.9,1,4.4,1.5c0.7,0.6,1.5,1.2,2.2,1.7c1.4,0.5,2.8,1,4.2,1.5c14.1,6.5,15.4,5,18.4-21.3
				c7.8,1.6,2.5,9.1,2.8,13.7c0.2,2.9-3.1,4.6-2.6,9.2c0.9,7.7,1.5,16.1-7.9,12c-3.3-1.5-6.9-4.9-11.7-1.8
				c16.6,8.5,26.5,5.9,30.9-10.3c4.3-16.1,5.1-33.8,5.6-51.1c0.1-3.3-0.6-6.9-3.4-6.3c-13,2.8-25.9-5.5-38.8-0.7
				c-6.4-1.9-12.9-1.6-19.3,0c-1.6,0.1-3.2,0.3-4.7,0.4c-4.4-0.7-8.8-0.7-13.1,1.3c-11.9,0.1-23.8,0.2-35.7,0.3
				c-1.4-0.2-2.8-0.4-4.2-0.5c-9.6-3.5-18.3,5.2-27.9,3.8c-3.2-0.5-4,3.6-3.8,7.6c1.2,23.9-0.7,48,2.4,71.8c0.1,5,0.3,10,0.4,15
				c-2.3,0.9,0.8,10-4.7,6.1c0-4-0.1-8.1-0.1-12.1c0-2.1,0-4.1,0-6.2c-0.1-6.2-0.1-12.3-0.2-18.5c-0.2-1.6-0.4-3.1-0.6-4.7
				c-0.2-17.4-0.4-34.8-0.6-52.2c-0.1-8.3-1.9-12.1-8.8-9.7c-9,3.1-18.1,0.7-26.9-3.3c-1.8-1-2.6-2.7-2.4-5.4
				c5.9-0.1,11.7-0.2,17.6-0.2c4.8-0.9,10,1.3,14.6-3.3c-4.9-9.8-10.8-6.4-16.7-3.2c-4.8-2.3-9.5-5.1-13.8,1.4
				c-3.4-9.1-1.9-14.7,4.4-13.9c7.5,1,11.7-3.8,16.6-14.4c-8,4.8-13.9,0.6-19.9,1.1c-2.7,0.3-7.4,4-7-3.4c0.1-2.1-0.2-10.6,6.8-10.5
				c23.9,0.3,47.7-1.7,71.6-2c5.6-0.1,8.3-2.5,8.7-9.8c0.5-8.1,2.4-17.4,0.4-24.2c-2.3-7.6-9.3-0.7-14.1-2.7c-1-0.4-2.2-0.3-3.3-0.6
				c-24-7.1-48.3,0.3-72.5-2.8c-2.2-0.3-4.6-0.1-5.4,3.7c-0.7,3.4,0.5,6.4,2.6,7.8c3.9,2.7,5.7,5.8,2.8,11.8c-3-2.1-4.2-9.6-8.8-5.6
				c3.4,13.7,3.8,28.3,3.9,42.1c0.1,13.2,6.9,23.9,3.7,39.1c-2.6,12,2.6,27,3.3,40.8c0.5,10.4,3,18.5,9.2,24.4
				c0.7,0.9,1.4,1.8,2.1,2.7c-4.7,0.3-6.3,4.2-6,10.2c0.9,16.2,1.7,32.4,2.8,48.6c0.4,6.5,2.9,10.4,8.2,8.6
				c8.2,0.9,16.5,3.4,24.1-3.5c3.3,0.5,4.3,5.1,6.7,7.2c-0.5,9.7,4.6,8.9,9,9.3c-0.4,5.2,2.6,14.9-5.4,11.7
				c-6.1-2.4-11.5-0.2-17.3-0.2c-5.7-0.1-13.2,0.6-15.2-10.2c-1.1-5.8-3.6-4-5.7-4c-3.8,0-2.4,4.6-1.9,6.7
				c3.6,14.4,1.5,29.2,1.7,43.8c0.2,12.4,5.2,18,13.8,20c-4.3-14.1-0.9-21.1,9.5-21.6c7-0.3,13,2.1,17.2-12.9
				c6.5-22.9,10.3-21.7,21.4-1.2c0.5,1,0.8,2.3,1.1,3.2c-6.8,5.4-21.4-7.4-19,17c-12.6-4-13-3.6-10.8,13.2c-1.1-13.7,10-3,10.6-13.4
				c5.4-4.3,6.7-22.5,17.1-9.4c-3.7,14.2-6.8,16,13.6,23.9c-9.4,1.7-16,8.1-27.8,5.8c6.4,3.8,3.6,10,6.8,12.4c4.3,3.1,3.8,5.3,0,8
				c-4.4,3.1-4,8.9-2.3,13.2c3.9,9.8,11.1,8,17.6,7.5c2.9-0.2,7.1,2.4,8-4.5c2.4-19.1,4.6-38.2,4.2-57.9c-0.1-5.7-5.7-8.4-3.2-12.5
				c5.2-8.6,1.9-19.3,5.1-30c6.1,27.9,9.2,0.1,17.7-1.7c-9.8,8.7-3.5,15.2-2.1,19.9c3.6,12.4-1.2,30.8,13.5,36.3
				c6.7,2.5-1.9,4.5-1.5,7.5l0.2-0.2c-5-0.3-2.6,12.8-9.1,9.7c0-4.6,5.2-8.4,1.3-13.5c-1.5-2.1-3.7-1.7-4.5,1.2
				c-1.4,5.1-1.5,10.1,3.3,12.2c-6.8,15.1-4.8,20.9,7.6,21.7c3.7,0.2,7.4-0.2,11,0.6c8.9,2.1,11.7-4,11.6-15.2
				c-0.2-18.7-3.2-37.3-0.3-56.1c-1.4,3.7,0.8,11.2-4.8,9.3c-7.4-2.4-1.6-13.7-6.1-19.2c7.1,0.6,15.4-0.8,19.3,4.3
				c9.9,13,20.7,5.9,31.1,6.9c4.2,0.4,5.6-4.3,5.6-9.3c0-3.8,2.6-9.8-3.3-10.1c-2.6-0.1-4.3-1.6-3.6-5.6c0.6-3.2,2.8-2.4,4.5-3
				c13.9-4.6,16.2,12.5,22.1,22.9c0,0,0.1-0.2,0.1-0.2c-0.8,1.1-1.6,2.2-2.4,3.3l0.1-0.2c-9.2,9.7-4.3,23.5-4.7,35.4
				c-0.4,12.4,7.3,1.6,10.6,5c1.6,1.6,4.2,1.1,6.4,1.6c0.3,25,1.4,50,0.7,74.9c-1.6,58.3-1.8,116.6-1.7,175
				c-7.3-0.7-17.3-8.3-15,13.4c1.7,15.6,10.1,7,15.2,9.9c0,8.8,0,17.6,0.1,26.4c-13,4.6-25.4,1.3-40.8-2.2c9.6-7.9,21.1,0,23.6-15.4
				c1.9-11.7-1.2-15.4-12.5-20.9c-0.4,4.1-1.3,8.6,3.5,8.4l-0.1-0.2c-0.2,1.5-0.5,3-0.7,4.5c-1.3,11.8-7.3,14.7-13.7,10.6
				c-8.5-5.6,1.9-8.3,2.1-13c0.2-6.4,3.1-14-1.8-18.3c-3.2-2.8-4.9-5.6-5.6-10.3c-0.5-3.5-1.9-5.2-4.5-5.5c-3.7-0.4-3.8,3.7-4.7,6.9
				c-1.4,5,3,36.9,6.1,44.1c5.9,13.6,1.7,25.4-9.4,25.7c-4.8,0.1-9.7-0.7-14.3,0.3c-8.3,1.8-12-5.5-10.2-13.9
				c3.8-18.3-5.1-19.6-13.3-22.5c-3.4-1.2-7-1.7-11-2.6c1.9-7.4,5.9-14.9-2.9-18.4c-1.2-0.5-1.9-6.5-1.8-9.8
				c0.2-9.3-2.4-13.1-9.2-12.2c-2.8,0.4-6,0-8.6-1.4c-13.9-8-15.2-4.4-18.8,12.4c-2.6,12.3-4,12.2-10.1,5.4c-2-2.2-4.4-7.6-7.5-3.6
				c-3.5,4.5,1.6,6.6,2.5,9.9c0.8,2.8,4.5,4.7,1.4,8.7c-5.7-4.3-12.5-31-11.9-41.3c1.3-21.2,0.1-42.1-6.3-61.9
				c-1.7-5.3-4.4-7.4-7.8-5c-3.6,2.5-0.1,5.5,0.4,8.5c3.3,19.8,8.1,39.8,8.3,59.8c0.1,13.8,5.4,28.7-1.3,42.4
				c-0.8,1.7-1.3,7.8,1.2,7.8c11.5-0.1,16.2,20,30.3,15.7c-6.9,11.4-13.3,16.2-21.8,15.4c-3.9-0.4-6.9,2-10.5,5.7
				c-7.2,7.4-16.1,1.3-24.2-0.1c-0.5-0.1-1.5-3.1-1.2-3.6c4.1-7.8,10.2-2.5,15.2-4.2c1-0.3,1.3-0.7,0.6-2.8
				c-41.2-0.8-82.6-2.7-124-0.9c-4,0.2-8,1.8-12,2c-3.1,0.1-4.5-6.5-3.1-6.4c10.3,0.8,8.2-17.8,16.9-18.1c-2.6-5.7-9.3,2.4-9.8-9.2
				c-1-23.9-0.6-47.5,1.7-71.1c1.2-12.1,0.8-24.4,3.3-36.4c0.7-3.3,0.1-8.6-3.9-9.4c-3.6-0.7-3.1,3.1-3.3,5.9
				c-2,27.4-4.5,54.8-5.6,82.3c-0.7,15.9-5.5,31.9-3.5,46.7c2,14.7-2.7,14.3-8.7,13.1c-7.1-1.5-14.1-3.7-23.1-2.1
				c4.5-5.3,12.5-1.7,10.5-9.2c-2.2-8.1-2.7-21.4-10.8-22.5c-14.5-2-28.9-5.2-43.6-3.5c-15,1.8-31.1,8-40.8-16.4
				c7.1-2.7,9.1-4.3,7.9-6.9c-2.7-6.3-6.6-2.2-9.7-1c-17.2,6.7-17.2,6.9-24.4,4.7c3.5-6.3,11.2-3.4,15.5-14
				c-7.8,1.7-15.6-3.6-19.3,3.5c-4.9,9.6,0.4,19.7,6.1,28c-6.8,4-12.7,2.1-18.4,1.7c-22.4-1.2-41.1-22.3-63.6-23.7
				c-3.5-0.2-10.7-0.6-7.7-12.8c1-4.2-4.3-7-8.4-7c-4.5,0-7.1,2.3-6.8,8.9c0.5,11.9-2.1,25.3,7.4,33.3c2.9,2.4,2.6,6,1.8,9.4
				c-0.9,4.1-3.5,2.8-5.6,2.7c-7-0.2-14-0.3-21-0.9c-2.9-0.2-6.3-0.6-7.2-5.5c-0.7-3.8,0.9-6.8,3.2-8.9c7.9-7.3,6.1-18.6,6.2-29.1
				c0-3.9-0.6-7.6-3.9-7.9c-5.5-0.6-11.4-2.6-16.2,1.2c-3.1,2.4,2.4,6.7,2,14.1c-7.5-8.8-14.3-0.1-20.9-3.9c0-0.8-0.2-1.5-0.6-2.1
				c1.3-4,5.2-5.4,5.6-10.4c-3.1-2.1-6.4-3.4-8.9-6.1c-1.8-1.9-1.6-5.8-0.4-8.5c1.6-3.5,2.9-1.4,3.8,1.2c0.8,2.4,1.7,6.9,3.5,2.7
				c1.7-3.8,5.3-8,2.8-13.8c-2.2-5.1-3.2,1.1-4.9,1.3c-8.7,1.1-17.3,3.3-26,4.3c-3.9,0.5-6.1,2.7-7,7.7c-3.4,0.3-6.9,0.6-10.3,0.9
				c-21.3-8.8-42.9-2.5-64.4-3.5c-6.6-1.8-14.5,2-18.7-10.2c-1.4-4.1-6.3-4.3-9.9-1.6c-12.2,4.2-24.1,12-37.1,6.1
				c-2.6-1.2-5-5.7-7.6-0.9c-2.4,4.6-2.1,9.9,0.7,14c1.7,2.5,4.4,3.7,4.3,8c-1.4,0.5-2.9,1-4.3,1.5c-1.8-0.5-3.4-0.1-4.4,2.4
				c-2.2,0.2-4.4,0.5-6.6,0.7c-3-1.7-10-0.7-3.4-9.3c2-2.6,3-5.9,2.8-9.7c-0.2-3.8,0.9-8.9-3.3-9.9c-4.1-1-5.1,3-4.9,7.6
				c0.6,9.8-4.4,11-9.2,11.1c-15.8,0.3-31.5-0.2-46.8,7.1c-4,1.9-9.5,2.1-11.1-5.2c-1.3-5.9-2.4-9.4-7.2-8.1
				c-4.3,1.1-3.2,5.7-3.3,9.7c-0.2,6.9,2.6,8.9,6.9,8.9c5.1-0.1,10.2,0,15.3-0.1c-4.6,3-9.3,2.7-14,3.1c-2.4,0.2-5.6-1.3-6.9,2.7
				c-1.5,4.4,1.5,6.5,3.4,8.4c7.7,7.4,4,11.6-1.8,16.2c-6.2,4.8-12.9,3.6-19.4,3.4c-4.3-0.1-10.2,4.4-12.5-4.4
				c-1.9-7.2,1.3-12.9,5.3-17.1c1.9-2,3.6-3.8,3.1-7.4c-2.3-18.7,1.1-37.6-0.9-56.5c-1.2-11.3-7.5-13.4-12-14.8
				c-5.9-1.9-4.3,7.7-4.5,12.5c-0.4,11.8,1.6,24.2-0.5,35.2c-1.8,9.5,11.2,13.7,3.5,20.8c-4.6,4.2-12.1,2.4-18.3,2.7
				c-18.4,1-36.6,10.4-55.6,3.8c4.2-8.8,12.6-19.4-3.8-20.8c-1.4-0.1-4-3.3-3.9-4.8c0.8-15.2-8.5-8.7-10.7-7.2
				c-12,8.5-9,0.7-6.5-9.6c1.1-4.5,2-12-3.5-13.9c-4.4-1.6-7.2,1.3-9.6,7.1c-2.4,5.7-1.1,11-1.1,16.4c-0.1,7.1,0,13.1-7.4,12.6
				c-5-0.3-7.5,5.3-8.3,12c0,0,0.1-0.2,0.1-0.2c-15.1-6.9-30.7-1.2-46-3.4c-8.2-1.2-16.3-2.2-24.7,2.8c-7.4,4.4-15.3-0.2-21.1-8.2
				c-4.1-5.7-3.7-5.8-9.1-5.6c-3.8,0.1-9.8,4.7-9.9-6.6c0.7-0.3,1.3-0.8,2-0.8c3.8,0,6.3-1.9,5.7-7.9c-0.2-2.5-0.9-5.6-3-5
				c-3.8,1.1-2,6.6-3.2,9.9c-0.5,1.3-0.9,2.7-1.4,4c-7,5.1-8-0.8-6.4-7.9c1.1-5,1.7-9.8,1.5-15c-0.1-2.5-0.1-5.5-2.5-5.9
				c-3.1-0.5-3.6,2.6-3.7,5.8c0,7.2-0.4,14.4,0.3,21.5c1,11-2.9,15.1-10,15.2c-7.1,0.1-14.3,0.1-24.6,0.1c7.1,5.6,7.2,11.4,6.2,17.7
				c-0.8,5.1,0.3,13.8-5.9,10.8c-5.6-2.8-10.9-2-16.2-1.3c-5.9,0.8-8.7-4.9-11.1-10c-2.6-5.7,1.6-8.7,4.2-12.1
				c1.4-1.9,3.5-4,2.4-8.7c-8.2,2.6-16.5,5.3-25,2.3c0.3-0.2,0.6-0.3,0.8-0.6c0.1-0.1,0-0.5,0-0.8c-0.2,0.5-0.4,1-0.6,1.6
				c-2.1-1.2-7,4.2-5.4-5.8c1.4-8.8,0.4-18.3,0.6-27.5c0.1-4.7,0.3-11-3.6-11.3c-4.9-0.3-2.7,6.6-3.1,10.5
				c-1.2,11.2,3.4,26.6-2.6,32.8c-5.8,5.9-16.2,3.2-24.6,4.1c-3.7,0.4-7.4-0.4-11,0.3c-3.4,0.7-3.6-8.4-8.1-4.2
				c-10.9,10.4-20.8-2.3-31.2-3.1c-21.1-1.5-6.9-22.8-10.9-33.6c-0.4-1.2-0.1-3-0.1-4.6c0-4.1-0.8-6.9-4.2-7
				c-3.9-0.1-5.2,2.5-5.2,7.5c0,7.7-0.1,15.4-0.1,23c0,6.9-1.8,14.8,6,16.7c2.6,0.6,2.5,4.6,2.2,7.7c-0.3,3.1-2.1,4-4,4.1
				c-4.6,0.2-9.4,2.6-13.6-2.9c-3.8-4.9-6.5-3.9-7.4,3.7c-21.2-1.6-42.4-3.4-63.5-7.2c-9.8-1.7-20.5-3.7-30.1,3.8
				c-2-9.2-7.8-7.4-12.5-8.2c-18.3-3.2-36.4,0.5-54.7,5.2c15.3,0.9,30.7,1.9,46,2.8c0,2.6,0.2,5.2-0.2,7.6
				c-0.8,4.2,1.6,10.5-2.5,12.2c-4.2,1.7-5.5-4.4-6.5-8.4c-2.2-8.5-7.1-7.4-11.8-7.8c-20-1.9-39.9,1.5-60.1-3.1
				c-8.4-1.9-20.1-3.8-28.4,9.4c-5.3,8.3-13.2,3.7-15.7-7.6c-0.9-4-1.9-5.5-5-6.4c-12.5-3.5-24.6,3.6-37.1,1.3
				c-4.4-0.8-4.9,5.4-5.3,11c-1.2,18.3-4.5,22.4-17.5,23.7c-1.5,0.1-2.9-0.1-4.4,0.1c-11.5,1-14.3,7.6-9.3,21.7
				c1.8,5,5.9,7.4,6.4,13.8c1,12.6-0.4,27.7,13.1,29.9c0.1,0,0.2,0.9,0.3,1.4c-1.6,9.8-5.2,15.6-13.3,14.7c-2.5-0.3-6.8-2.3-7.7,4.6
				c0.2,5.4,1.9,11.8-3.1,14.8c-7.2,4.3-7.1,12-6.1,20.9c0.6,5.5,0.5,11.3,0.4,16.9c-0.1,5.1-2.2,10.4-6,8.4
				c-7.8-4.1-15.2,0.6-22.9-0.7c-21-3.6-41.9-8.8-63.2-8.2c-4.4,0.1-8.3-3.1-10.4-7.6c-4.8-9.9-11.1-8.5-17.5-6
				c-3.3,1.3-6.2,1.7-9.6,1.1c-18.6-3.3-36.7-10-55.3-18.2c12-11.5,26.2-9.5,38.9-14.4c1.9-0.7,5.2,0.7,5.3-3.4
				c0.2-5.4-3.5-4-5.8-4.4c-1.8-0.3-3.9-0.8-5.5,0.1c-10.8,6.2-5.6-19.9-15.6-14.9c-0.4-1.9-1.1-3.7-1.2-5.6
				c-0.3-4.9-0.1-9.5,4.7-9.6c16.8,0.6,33.6,1.4,50.3,1.8c4.2,0.1,8.1-1,8-9c-0.2-29-0.3-58-0.5-87c2.3-1.1,3.7-3.3,3.7-6.8
				c0.4-27.1-0.4-54.3,1.8-81.3c0.1-1.6-1.3-3.4-2-5.1c0.5-4.4,1.1-8.8-1.5-12.4c2.8-5.8,9-3.6,11.7-10.3c2.1-5.1,4-13.2-0.4-12.7
				c-6,0.7-15-11.4-18.8,5.5c-0.8,3.6-3.9,0.7-5.9,0.6c-2.6-0.3-2.4-2.9-3.1-6c-2.3-9.9-3.9-19.7-3.7-30.2c0.1-3.9-0.3-9-4.7-7.9
				c-3.6,0.9-3.4,5.6-2.4,8.6c3.3,10.1-1,20.2,0.6,30.3c1.1,7.3-1.3,10-6.6,9.8c-8.1-0.4-16.2,0.1-24.2,0.2c-3.7,0-8.1,1.8-5.8,7.3
				c4.9,11.6,2.1,23.9,3.6,35.7c-0.1,16.7-0.1,33.5-0.2,50.2c-0.7,0.1-1.4,0.3-2.1,0.1c-3.4-0.8-6.6-5.4-10-1.3
				c-0.7,0.8-0.7,4.2,0,5.6c2.5,5.6,6.6,1.6,10,2.6c0.7,0.2,1.4,0.4,2.1,0.6c-0.1,3.4-0.2,6.8-0.2,10.1c-4.1,2-9.5-3.2-12.7,4.2
				c-4.6-0.3-9.1-0.7-13.7-1c-3.1-4.4-7.9-4.2-9.1-0.1c-1.5,5,2.5,8.8,6.3,10.5c1.7,3.8,4.3,3.1,6.9,2.8c-0.3,6.8,2.4,9.3,6.7,7.5
				c2.6-1.1,4.8-4.2,6.7,1.2c3.3,9.4,6.1,1.2,9.2-0.4c0.2,2,0.3,3.9,0.5,5.9c-0.2,3-0.3,6.1-0.6,9.1c-2.1,19.1-11,30.4-24.5,26.9
				c-12.4-3.2-24.6-8-37.2-11.6c7.9,0,15.7-0.1,23.6-0.1c-13.5-3.6-27.1-3.3-40.6-6.2c-6.2-1.3-10.2,8.5-16.4,10.6
				c-8.5,2.9-8.9-11.7-15.4-11.9c-20.1-0.5-40.1-1.7-60,5.4c18.5-0.1,37-0.1,55.5-0.2c-10.6,1.5-19.8,10.6-30.8,9.2
				c-15.3-2-30.8-2.9-46.2-0.5c-2.6,0.4-5.2,1.2-6.8-2.8c-1.8-4.3,0.5-7.2,2.3-9.1c7.1-7.7,10.4-19.4,15.4-29.2
				c4.1-8,7.7-22.8-1.5-26.7c-8.8-3.7-19.6-5.6-29.4-0.5c-1.9-1.5-3.9-1.5-5.8-0.2c1.5-5.7,4.5-7.2,8.6-7.1c5.5,0.1,11-0.7,16.5-1
				c9.4-0.6,9.4-0.6,6.1-17c-2,24-13.8,13.6-21.1,13.5c-10.2-0.2-1-10.5-2.2-16.1c-1.3-6.2,0.5-13.5-0.7-19.8
				c-2-10.7-7.9-20-15.3-19.4c-8.8,0.6-18.6-4-26.8,7.2c-3.8,5.1-11.5,3.3-14.2-4.5c-4.4-12.4-16.3-9.8-17.3-7.6
				c-7.5,15.8-21-0.2-28.4,11.8c-0.2,0.4-2.2-0.5-2.9-1.5c-5.3-7.4-14.7-0.7-19.4-11.8c-1.2-2.9-6.6-1.4-9.8,2.8
				c-8.5,11.3-16.6,10.1-23.8-3.8c0.1,11.3-5.2,11.9-10.4,11.2c-11.4-1.5-22.7-0.9-34,0.5c-4.6,0.6-7.5,2.4-9,8.7
				c-0.6,2.6,3.8,4.6,0.5,6.4c-1.7,0.9-4.6-0.1-6.2-1.7c-2-1.9-1.7-7.6-0.4-8.1c5.1-2.1-1.3-12.3,5-12.7c-0.1-1.9,0.2-4.1-0.3-5.7
				c-0.8-2-3.1-2.4-3.8-0.9c-2.3,5.2-7.2,3.3-9.3,6.4c-4.8,6.8-15.1,8.1-12.5,23c0.6,3.7,0.4,9.3-4,8.7c-4.3-0.6-3.3-6.3-3-9.9
				c0.8-12.1-2.2-16.3-10.7-12c-2,1-4.4,0.8-6.5,1.2c-0.3-0.2-0.6-0.3-0.9-0.5c0.3,0.2,0.6,0.4,0.8,0.6c0.1,20.3,0,21.2-14.7,17.8
				c-9.4-2.2-18.6-0.4-24.1,8.4c-9.2,14.5-18,4.7-27,3.9c5.2-2.4,10.6-3.3,15.9-4.6c4.5-1.1,5.8-5.6,5.7-11.3
				c-0.1-5.9-2.7-7.9-6.5-7.7c-2.2,0.1-4.4-0.1-6.6-0.2l0.1,0.1c3.5-5.6,8.4-3.8,12.4-3.1c9.1,1.5,18,4.3,26.8-3.1
				c-21.5,4.1-42.2,0-62.9-8.5c-4-1.7-8.8-11-11-5.7c-3.4,8.3-8.6,6.2-11.4,5.1c-6.4-2.5-6.2,2.2-6.9,7.8
				c-1.7,14.6,0.3,28.9,1.2,43.4c1.3,22.3,4.3,41.7,20.1,54.6c-16.7,2.9-16.7,2.9-23.3,15.3c5.8,0,11.2,0,17.1-0.1
				c-9.4,14.6-35.7,21.5-44.3,9.8c-7.2-9.8-15.1-11.6-23.9-12.6c-5.3-0.6-10.2-2.5-14.5-6.5c-4.2-3.9-8.3-12.3-10.1,2.2
				c-6.5-2.2-9.9,9.3-14.4,8.4c-15.7-3.2-31.5-0.1-47.2-2.6c-16.4-2.6-34.3-6.8-49.2,0.6c-15.1,7.5-29.4,1.4-43.6,5.1
				c-14.4,3.7-25.7-14-41.3-7.3c-9,3.8-21.1,0.4-31.6,0.9c-8.8,0.4-17.2-10.1-26.4-1.2c-1.9,1.8-5.6-1.6-6.3-6.5
				c-0.6-4.2,0.4-7.2,3.6-7.7c5.5-0.9,6.5-7.7,8.6-12.8c2.6-6.3,6.7-12.2,11.3-9.9c12.6,6.3,19.5-5.3,27.7-14
				c4.3-4.6,12.2-10.3,10.9-15.2c-2.4-8.8-10.3-3.9-15.9-3.5c-8.4,0.7-11.5-4-10.1-15.3c0.4-3.5,0-7.6,1-10.6
				c4.6-12.9-1.3-16.7-8.3-19.9c-1.7-0.8-4.6-0.6-5.2-2c-3.8-11-5.2,1.4-8.8,1.8c2.7-19.3-10.3-16.9-16.6-24
				c-0.9-1.1-3.8-0.4-3.5,1.5c1.4,12.6-3.5,6-7.2,5.4c-7.1-1.2-14.4-2-17.5,10.7c-1.3,5.2-4.6,6-8.3,4.9c-0.2-24.5-0.2-49-0.6-73.5
				c-0.1-7.1,3.5-12,4.1-18.5c2-3.3,7.9-2.3,7-7.6c-3.2-18.8,2.3-37.2,0.3-56.3c-0.1,6.6-1.8,11.2-7.1,11.9c0.2-2.5,0.1-4.6,0.4-6.5
				c5.5-28.6,8.4-57.3,2.8-86.8c-1.1-5.7,0-10.2-5.6-6.5c-0.3-6.1,0.3-12,1.9-17.6c5.1-1.4,10.2-2.7,15.3-4.1
				c-6.6-1.9-9.6-14.2-17.4-12c-1.4-4.5,3-9.2-0.3-13.6c2.9,0.6,5.8,1.2,8.6,1.8c2.2,2,4.4,2.3,5.5,1.1c-1.2,0.2-3.4,1.8-5.6-1
				c0.5-14.4,0.5-14.4-8.6-15.7c1.1-10.8,9.7-13.4,12.7-23.6c1.7-5.8,5.1-10.4,4.2-17.8c-1.3-10.5,1.3-16,9.8-15.3
				c12.4,1,24.8,0.9,37.2,1.3c-0.4,2.5-0.9,4.9-1.3,7.4c-1.1,6-1.2,6.4-5.9,5.2c-2.8-0.7-2.3,2.3-2.2,3.2c0.7,5.5-3.3,7.1-4.3,10.1
				c8.5,10.4,16.2-1.3,24.5-1.4c3.4-0.1,6.1-4.4,0.3-9.8c-2.6-2.4-12.5-0.8-7.8-13c8.2,1.3,16.5,2.8,24.8,3.9
				c4.7,0.6,9.7,1.3,11-7.9c0.4-2.6-0.7-7,2.7-6.7c3.8,0.4,1.8,5.7,3.1,8.4c0.8,6.9,3.5,10.9,8.7,11.3c2.4,0.2,3.3-1.8,3.3-4.7
				c-0.3-11.5,3.6-21.6,5.4-32c4-22.7,7.5-47.4,0.2-71c-1.9-6,0.9-4.9,3.5-5.9c5.1-2,9-11.5,7.9-14.4c-3.4-8.4-2-13.4,2.5-18.3
				c4.2-4.6,6.5-10.1,3.7-17.1c-3.1-7.9-2.1-15.3-0.5-23.4c3.9-20.5,1-30.6-9.4-37.4c-6.7-7.7-13.3-15.8-23-12.7
				c-0.2-3.6,3.8-11.2-2.1-9.4c-7.7,2.3-14.1-3.2-21.2-3.1c-3.6-5.6-6.3-13.5-13.3-11.5c-3.2-9.5-10.1-12.9-15.9-18.2
				c-7.7-14.3-20.4-21.5-27.4-36.9c-0.8-1.7-4-3.3-4.9-2.5C14,768.6,10,750.5,2,754.2c-2,0.9-2.3-3.3-2.3-5.8
				c-0.2-13.8-0.2-27.7-0.3-41.5c0-4.2,1.3-7.1,4.5-7.1c17.1-0.2,34.2-0.2,51.4,1.4c-4,0.5-8,1-12,1.4c12.2,2.6,24,0.9,35.4-5
				c1,3.3,2.8,3.4,4.9,2.7c1.3-0.4,2.5-0.7,3.8-1.1c6.3,3.2,12.6,2.8,18.9-0.3c1.8-0.5,3.7-1,5.5-1.5c1.7,0.2,3.3,0.3,5,0.5
				c8.7,1.5,17.4,2.1,26-0.6c0.9,0.1,1.8,0.2,2.8,0.2c1.4,0.2,2.8,0.4,4.2,0.6c1.4,8.2,0.8,12.2-6.9,13.5c-8.3,1.4-16.4-2-24.5,0.3
				c-10.2,0-20.4-0.1-30.5-0.1c-5,0-10,0.3-15-0.2c-2.5-0.3-2.9-11.6-4.9-6c-3.2,9.1-7.7,3-11.1,5.3c-5.5-0.2-11-0.8-16.5-0.5
				c-4.2,0.3-9.9-2.8-10.1,8.6c-0.2,9.8,1.2,16.1,9.2,16.2c2.6,6.4,7.5,3.7,11.2,5.7c3.6,0.3,7.1,1.9,10.6-0.3c14.9,0,29.8,0,44.7,0
				c3.8,0.3,7.3,0.2,11.2,2.2c10.5,5.5,21.1,0.7,24.5-9.9c9.5,7.8,3.1,18.8,2.2,27.8c-0.8,7.3-6.4,7-10.9,4.7
				c-3.4-1.7-6.5-1.4-9.4,1.3c-2.2,0-4.3,0-6.5,0c-5-6.8-9.6-14.7-17.9-9.6c-9.4-3-18.8-8.4-28.6-2.4c-4.9-1.7-9.8-3.5-14.7-5.1
				c-3.6-1.2-7.2,2.6-4.9,5.7c5.4,7,7.2,20.6,17.1,20.3c0.8,7.5,3.3,11.9,9.3,10.2l0.4,0.8c2.5,14.1,10,19.8,19.4,22.4
				c9.4,17.4,19.1,22.8,28.6,16c0.3,3.8,2.3,8.5,4.1,7.2c4.4-3.3,5.5-9.9,4.6-16.8c-3.5-6.6-0.1-13.1-0.7-19.7c4.8,0.2,2,6.5,4.7,8
				c1.8-3.2,0.6-9.6,5.1-9.9l-0.1-0.2c-0.2,5,0.9,12.9-0.9,14.7c-9.2,8.9-4.3,22.5-6.8,33.4c-0.7,3.2-1.9,8.5,3,9.2
				c3.7,0.5,6.4-4,6.3-6.7c-0.5-12.6,4.6-23.2,5.3-35.1l0,0.1c4.4,0.3,2.9-7.2,6-8.7c8.1-4,8.6-5,5.6-14.8
				c-1.6-5.2-3.2-10.7-5.7-15.1c-4.4-7.9-0.4-16.9-1.9-25.3c-0.5-2.4,7.5-3,11.4-3.4c6.3-0.8,10.7-5.9,15.9-9.4
				c3.4-2.3,4-6.3,2.5-10.1c-2.3-5.7-4,0.6-6.1,1.3c-4.9,1.7-9.9,3.4-14.8,5.2l0.2,0.2c4.2-6.8,10.7-9.9,14.4-18.5
				c-6.8-3.8-11.9,0.1-16.9,8.9c0.2-11.9,1.5-19.2,7.2-25.4c4.6-5,6.3-13.4,4-22.2c-1.5-5.7-3.1-11.6-3.8-17.6
				c-0.7-6-0.4-13.8,3.3-16.6c4.1-3.1,6.9,3.6,9.3,8c-2.5,0.3-4.9,0.7-7.4,1c2.3,7,2,15.7,6.7,20.3c13.7-6.8-0.2-14.3,0.6-21.6
				c3.9,1.7,6.3,9.5,12.2,2.5c5.4-6.4,6-12.5,3.2-19.9c-4-10.8-7.2-22.1-9.5-33c-7.3,0.2-7.2,12.4-13.5,12.8
				c-1.9-14.3,15.3-22.2,7-36.8c-6.9-12.3,7.2-28.6-6.5-37.5c5.4-4.3,7.7-0.5,10.1,3.6c2.6,4.2,4.9,8.6,8.9,11.2
				c3.1,2.1,5.6,4.7,8.9,0.8c3.5-4.2,3-9,1-13.8c-3-7.3-6.5-14.4-9.5-21.8c-2.6-6.5-5.4,1.3-7.8,0c-3.3-11.4,14.5-27.8-5.7-34.8
				c-11.3-3.9-20.8-6.7-33.5,0c11.7,0,20.2-0.1,28.7-0.1c0.1,1.4,0.1,2.8,0.2,4.1c-12,5.2-24.9,0.4-37.1,3.2
				c-25.1-7.2-50.3-0.6-75.3,0.5c-17.5,0.7-35-0.7-52.5,0.3c-7.2,0.4-9.5-2.2-8.9-12.3c0.7-10.1,0.1-20.4,0-30.6
				c3.9-2.9,8-4.9,12.5-5c3.9-0.1,7.5-1.6,7.2-8.6c-0.4-8.7-6.5-6.6-9.3-6.7c-3.1-0.2-6.6,3.4-10.4,2.4C3.8,420.3-0.7,415,0.5,409.3
				z M474.6,1273.4c0,1.1,0,2.3,0,3.4c-1,0.4-2.9,0.9-2.9,1.2c0.7,15.6,1.3,31.2,2.6,46.7c0.3,4.1,3.2,6.4,6.7,6.4
				c3.9,0.1,3.6-4,4.2-7.3c0.4-2.4,0.2-5.7,1.3-7c6.4-7.9,6.5-7.7,7-15.4c1-16.6-3.9-23.5-16.1-23.1c-0.9,0-1.9-1.2-2.9-1.9
				c0.5-0.5,1-1,1.5-1.5c-0.5-0.5-1-0.9-1.5-1.4c-0.2-0.2-0.4-0.4-0.6-0.7C474.1,1273.1,474.4,1273.2,474.6,1273.4z M1953.4,1135.7
				c-1.4,0.3-2.9,0.5-4.3,0.8c0.2,0.4,0.4,1.1,0.6,1.1c1.5,0.3,2.9,0.4,3.6-2.1l0.7-0.5L1953.4,1135.7z M2275.5,960.5l-0.4,0.1
				c-4.7,8.8,0,17.2,0.8,25.7c-0.7,7.8,0.4,15.2,1.8,22.7c0.8,4.5,1.4,6.4,5.5,6.7c13.2,0.8,26.1-1.3,39.2-2.6
				c4.4,0.4,8.8,0.9,13.2,1.3c4.2,3.5,8.8,2.6,13.3,2c1.4-1.5,2.7-2.9,4.1-4.4c1.8-2.8,6.6,3.6,6.9-4.2c2.9-0.1,5.9-0.9,8.8-0.3
				c10.7,2.4,13.9-4.7,12.6-18.2c-0.3-3.5-0.1-7.3-0.9-10.6c-1.2-5-0.8-7.9,3.5-8c2.3-0.1,4.4-0.8,4.2-5.1c-0.2-5.6-3.3-4-5.6-4.8
				c-14.8-5.4-29.8-1.2-44.7-2.5c3.8-5.9,9.1-1.2,13.4-3.7c12.4,0.4,24.8,0.6,37.2,1.1c4.3,0.2,7.1-0.8,6.7-8.1
				c-0.4-6,0.8-12.7-6.2-12.4c-13.5,0.5-26.9-4-40.4-2c-18.5-4.7-37.6,5.6-56.1-5c-4.2-2.4-10.1,0.9-15.2,1.5
				c-4.1,0.5-7,2.2-6.4,9.4c0.5,6.3,1.3,11,7,10.6c5.1-0.4,10.3-0.1,15.4-0.2c0.1,0.8,0.1,1.7,0,2.5c-3,0.3-5.9,0.6-8.9,0.9
				c-1.4,0-2.7,0-4.1,0c-1.6,0-3.2,0-4.7,0.1C2271.2,955.6,2272.1,958.1,2275.5,960.5z M1213.5,792.9c2.1-3.1,1.8-6.7,0.8-10.2
				c0-0.2-3.1,0.4-3.8,1.7c-3.1,5.5,3.2,5,3.2,8.8c-1.2,0.6-3.2-0.8-3.3,2.5c0,0.3,0.5,0.9,0.7,1
				C1212.9,796.7,1213.9,795.6,1213.5,792.9z M449.2,1054.4c1.5,0,3.1,0,4.6,0.1c-0.1,6.5-2.1,14.9,6.5,12.8
				c-4.7,3.3-14.2,4-4.1,15.1c0.3,10.1,1,20.5,0.8,30.3c-0.3,14,8.3,16.7,12.8,18.4c6.6,2.4,6-10.5,7.5-16.7
				c1.9-8,1.2-17.1,1.6-25.7c3-3.4,0.4-10.4,4.1-13.2c3.5-0.5,3,4,4.4,6c1.5,2,3,6.4,5.1,3.7c2.8-3.6,5.5-8.5,4.8-14.5
				c-0.4-3.2,0.2-7.4-2.7-9.2l-11.3-6.8c3.2-8.8,3.8-18.2,3.3-27.8c0-2,0.1-4.1,0.1-6.1c-0.1-17.4-2.9-21.1-15.5-20.7
				c-2.2-2.5-6.3-1.7-6.9-7.3c5.8,0.9,10.8-1.2,15.2-8.1c-5.9-2.1-11.2-1-16.4-0.5c-4.6,0.5-9.6,1.6-12-6.5
				c11.6-1,23.5,4.4,34.7-3.5c-0.5-2-1-4.1-1.5-6.1c4.8-1.1,7.2-6.8,5.6-11.5c-2-6-6.5-2.9-10-0.2c-2.1-0.9-4.5-1.7-6,1.1
				c-1,1.8-0.1,3.8,1.4,4.8c-5.9,1.1-9.9-7.3-15.8-6c-5.7-1.1-13.7-8.1-12.7,9.1c1.6,4.1,3.1,8.3,4.7,12.4
				c-7.2,11.3-3.4,25.1-4.4,37.9c-3.2,1.8-6.4,3.6-10.8,6.2c1-8.4,0.8-20.5,2.7-21.1c9.5-3.3,4.9-15.9,3.9-17.1
				c-6-6.5-3.6,8.6-9,7.6c-0.2-5.4-0.5-10.9-0.7-16.4c-0.2-4.5-1.9-6.5-5-6c-5.3,0.7-1.4,4.2-1.4,7.1c0,3.8-1.3,7.5-2,11.3
				c-2.4-0.9-4.7-0.6-6.9,1.1c-0.6-2,1.5-8.5-1.9-5.2c-7.3,7.1-7.5,36.9-0.8,45.1c3.6,4.4,3.5,7.8,0,10.8c-7,6-7.4,15.3-7.3,25.1
				c-0.7,6.1-1.2,12.2-0.2,18.3c0,1.1-0.1,2.1-0.1,3.2c-1.4,4.4-0.7,5.5,4.1,6.3c-5.6,7.2-6.2,14.4,0.2,21.5
				c-5.7-2.4-9.9,3.8-14.7,4.6c3.6,1.4,7.3,3.8,11.2,4.3c5.3,0.7,6.4-3,3.4-9.1c2.2,0.3,4.4,0.7,6.5,1c16.5,2.3,18.9-0.6,17.8-22.9
				c-0.3-6.5-0.7-12.7,1.7-19.1c-2.6,4.9,10.8-0.7,2.4,9c-3.7,4.3,4.2,8.9,4.5,15.1c0.1,1.4,3.5-0.9,3.7-3.1
				c0.6-8.6,2.6-21.1-2-24.7c-8.5-6.5-5-16.4-9-27.4c6.5,7,9.5,8.1,9.3-2.3c-0.1-7.8-2.4-15.7,0.4-23.4c2.2,8,4.5,16.1,6.7,24.1
				c-6.9,1.9-1.4,11-4.4,15.1c-0.5,0.7-1,1.3-1.3,2.1c-0.1,0.2,0.2,0.7,0.3,1.1C448.6,1056.7,448.9,1055.6,449.2,1054.4z M946,849.2
				c-2.7,1.8-6.4-4.1-8.5,1.8c-0.3,0.8,0.6,3.4,0.8,3.4c3-0.5,7.7,4,8-4.8c1.4,0.1,2.9,0.7,3.4-1.9c0.1-0.5-0.7-1.8-1.2-1.9
				C946.9,845.6,945.8,846.5,946,849.2z M556.5,1288.4c-0.4,4.4-1.4,8.9,1.4,14.7c0.7-6.1,2-10.6-1.5-14.5c-0.6-3.4,0.7-10.2-3.9-8
				c-4.5,2.1-1.7,8.6-2,13.6C552.9,1292.9,553.5,1288,556.5,1288.4z M676.1,792c-0.3,6,1.6,8.7,7.7,8.5c-3.4-3.8-5.5-6.2-7.6-8.6
				c-2.1-5.1-2-13.2-8.4-12.9c-1.1-2.7-0.2-7.6-3.9-7.2c-0.8,0.1-2,1.6-2.3,2.8c-1.3,5.2,2.9,4.4,4.2,6.8
				C667.9,787.4,670.3,792.9,676.1,792z M904,809.7c0.4,0.4,0.9,0.8,1.3,1.2c-0.2-0.7-0.4-1.4-0.7-2.2c-1.9-8.1-5-14.1-11.8-14.4
				c-0.2-0.6-0.4-1.2-0.6-1.7c0,0.3-0.2,0.7-0.1,0.8c0.2,0.3,0.5,0.5,0.8,0.7C895.2,801.3,898.6,806.9,904,809.7z M994,1213.2
				c-0.1-0.4-0.2-0.8-0.4-1.1c-0.1-0.2-0.4-0.1-0.6-0.2c0.4,0.4,0.8,0.8,1.2,1.1c1.4,4-0.1,11.7,5.3,10.5c5.9-1.4-0.1-7.1,1.1-10.6
				c10-9.9-0.4-18.4-0.1-28.5c-9.9,10.9-6.7,20,0.3,28.7C998.4,1213.1,996.2,1213.1,994,1213.2z M721.3,995.6
				c0.6,0.4,1.2,0.5,1.8,0.4c-1,1.8-2.5,4.1-0.9,6c1.6,1.8,3.8,1.2,5.7-0.3c10.7,0.7,21.6,3.2,30.8-7.3c2.9-3.3,9.9-3.9,8.1-10.1
				c-2-6.9-8.4-2.9-12.9-3.2c-1-0.1-2.1,0.9-3,1.7c-7.5,6.7-15.6,1.9-23.4,2.4c-1.4-0.2-2.8-0.4-4.2-0.6c-4.6-3.2-8.9-2.8-12.9,1.8
				c-3.7,4.2-2.7,9.4,0.1,12.9c2.6,3.2,3.5-0.2,3.7-3.6C716.7,998.2,719,998.2,721.3,995.6z M29.1,1080.6c0.7-3.9,0-6.8-3.2-7.3
				c-1.8-0.2-3.9-0.1-4.2,2.8c-0.3,3.7,1.1,6.5,3.8,7.6c-0.2,1.2-0.4,2.4-0.5,3.6c-2.2,5.6-4.3,11.2-7.7,20.1
				c6.1-4.9,7.9-11.5,12.2-13.3c1.5,0.3,3,0.5,4.5,0.8c3.8,3.2,7.8,2.4,7.5-3.8c-0.3-6.9-1-17-9.9-12.8
				C30.7,1079.1,29.9,1079.9,29.1,1080.6z M694.4,1046.9c-1.4,0.6-2.7,1.1-4.1,1.7c-1.4-0.1-2.9-0.2-4.3-0.3
				c-1.7-3.7-6.9,2.1-7.3-5.2c2.4-1,4.7-2,7.1-3c6.3,3.7,13.4,5.2,18.8-0.1c3.3-3.3,0.3-11.4-1.3-17.1c-2.8-9.3-26.7-17.5-31.5-10.7
				c-0.7,1-0.8,2.8-1,4.2c-1.2,8.4-2.4,16.8-3.6,25.3c-0.7,12.5-4.4,24.8-1.4,37.5c-0.1,0.9-0.1,1.7,0.2,2.5
				c-4,2.8,0.4,8.6-2.2,11.9c-9.3,12.8-1.3,26.5-0.2,39.4c0.4,4.2,3.3,6.8-1,9.9c-2,1.5-3.2,4.1-3,7.5c0.3,5.7,14.4,22.3,18.8,21.8
				c10.1-1.2,20.3-2.7,30.4-4.1c2.7-0.4,6.7,0.7,5.9-5.8c-0.7-6.3-5.1,0.2-6.9-3c8.6-4.2,7.7-10.3,1.7-17.4c3.2-0.4,4.2-3,3.5-7.1
				c-0.5-2.7-1.6-4.5-3.7-3.3c-2.5,1.4-2.3,4.5-1.8,7.5c-3.3-7.6-9.8-3.6-14.1-7.7c0.7,0.1,1.3-0.1,1.8-0.7c2-0.9,4.1-1.9,6.1-2.6
				c4.3-1.5,10.3-0.1,11.3-8c1-7.5-6.4-4.9-8.1-10c8.2-5.3,2.9-14.9,3.6-22.5c4.9-1.6,0.3-13,6.8-12.9c0.4,10.6,0.6,21.3,3,31.5
				c1,5.1-3.5,12.8,3.7,14.9c3.8,1.9,7.4,0.9,10.9-1.6c3.8,6.4-0.6,4.6-2.6,4.8c-3.2,0.4-8.2-0.9-8.1,4.7c0.2,8.1-3,16.7,0.9,24.3
				c3.1,6-1.2,16.3,6,19.8c-8.8,5.7-1.8,10.4,1.1,13.2c3.7,3.6,10.4,4.3,12.2-0.2c3.7-9.3,8.9-8.2,14.5-8.6c2.6-0.2,5.6-0.3,4.7-5.8
				c-0.8-5.2-3.6-3.3-6.1-3.2c-3.5,0.1-6.9,6.4-10.9,0.9c2.9-4.5,6.5-2.9,9.5-4.2c3.4-1.4,7.9-1.8,7.8-8.6c-0.1-8.6-6-2.9-8.7-6
				c3-2.4,8.5,1.6,8.8-7.5c0.3-8.3-2.7-9.8-7.8-11.4c-5.1-1.6-9.9-5.9-16-9.9c4.3-0.6,7.4-1,10.9-1.5c-1.1-4.1-2.1-7.6-3.2-11.2
				c0.7-6.5,9.6-9.4,4.1-18.6c0.1-1.1,0.3-2.2,0.4-3.3c1.6-0.2,3.2-0.4,4.7-0.5c0.5,1.6,1,4.5,1.6,4.5c2.4,0.3,1.8-3,2.3-4.9
				c3.7,0.4,7.4,0.9,11.1,1.3c-4,4.5-3.9,6.6,1.6,8.6c6.5,2.4,2.6,9.8,2.3,15.2c-0.1,2.3-0.5,6.1-2.6,5.7c-4.7-0.9-4.1-6.6-3.5-10.9
				c1.1-7.7-3.9-3.7-4.2-3.5c-3.7,3.8-0.6,9.5-1.8,14.3c-1.2,5,1.7,4.2,3.8,4.4c11.4,1.1,13.5-3.1,13.4-19.3
				c-0.1-8-5.6-8.8-6.6-14.6c9.2-4.5,4.7-13.8,3.9-21.8c4.8-16.1,0.7-32.8,1.6-49.2c0.4-6.9-3.9-9.7-8.4-8.3
				c-9.4,2.9-19,1.2-28.4,2.5c-8.4-0.3-16.8-0.6-25.1-1c-4.7-0.2-6.8,2.6-5.6,9.2c1.5,8.3,0,17.4,3.5,25.2c-1.9,4-4.1,7.4-8.1,6.7
				C703.3,1042.7,698.2,1048.6,694.4,1046.9z M2430.9,1035.1c-0.1,12.9,6.5,4.1,9.8,5.6c1.9,0.9,3.6-2.1,2.3-4.8
				c-2-4.1-4.8-5.9-8.2-2.1c-1,1.1-2.7,1-4.1,1.5c-1.4-4.5,5-4.4,2.7-9.9c-1.6-0.1-3.5-0.7-5.2-0.2c-3.5,0.9-9.7-3.3-9.6,4.2
				c0,7.1,5.6,7.4,10.2,5.7C2429.4,1034.9,2430.1,1035.1,2430.9,1035.1z M2380.1,1131.2c-0.4-1.9-0.9-3.8-2.6-3.7
				c-0.4,0-0.7,1.2-1,1.8c1.3,0.6,2.6,1.1,3.8,1.7c-0.1,1,0,2.3-0.4,2.9c-2.6,4.3-7.3,7.4-4.4,14.8c0.8,2,2.5,2.5,3.8,1.2
				c4.4-4.5,2.7-10.9,2.3-16.8C2381.5,1132.5,2380.6,1131.9,2380.1,1131.2z M1767.9,1080.9c-2.4,2.3-5,4.3-4.9,9.1
				c-2.5-0.8-6.2-4.8-5.7,3.4c0.3,5,2,9.9,1.8,14.8c-0.3,11.5,4.6,6.1,7.4,4.8c3.6,7-3,19.4,7.6,21.2c2.6,0.4,0,2.3-0.5,3.3
				c-6.2,11.9-3.9,23,6.6,27.2c9.5,3.8,19.3,6.3,29.1,9c9.1,2.5,16.7,7.4,19.5-14.5c3.2-25.4,3.4-50.1,2.8-75.3l-0.1-0.1
				c0.1-1,0.2-2,0.2-3c2.7-18.4,2-37,1.5-55.6c0.8-9.7-5.5-5.3-8.2-8c1.9-2,6.1,0.1,5.8-5c-0.2-4.3-3.2-2.6-5.2-2.4
				c-4.7,0.3-9.4,0.5-14.2,0.8c-2.1-2-4.3-4.5-7-3.4c-12.3,5.1-24.6,0.3-36.9,0.7c-4.5,0.2-9.4-2.4-13.3,3c-4.8,2.3-10-2.4-15.3,2
				c5.8,12.4,11.4,24.5,16.8,35.9c0.4-4.2-0.8-10.5,4.4-12.6c-4.1,12.8,4.5,23,3.7,35.1C1763.3,1076,1763.6,1080,1767.9,1080.9z
				 M238.6,88.3c8-6.5,15.4-13.7,20.7-24.6c-8.6,5.3-16.7,11.3-22.5,22c-0.9,1.8-4.9,1-3.1,4.8C234.9,92.7,237.4,91.2,238.6,88.3z
				 M836.5,1115.3l-0.1-0.6l0.2,0.4c0.8,6.9-4.8,10.4-4.4,17.8c0.3,5.7,1.2,6.9,4.6,6.6c1.3,3.9,0.3,7-2.1,9.2
				c-2.1,2-3.5,5.5-1.8,7.4c3,3.2,7.1,2.7,10.5,0.3c2.4-1.7,2.8-4.8,0.2-7c-2.3-1.9-2.5-3.1,0-4.8c5.5-3.6,3.1-9.7,3.3-15
				c-6.6-1.6-5.4,9.8-10.3,10c-1.2-6.1,1.7-10.6,3.2-15.5C841.5,1118.9,841.5,1115.5,836.5,1115.3z M2430.9,1153L2430.9,1153
				c3.8,6.1,6.3,4.6,6.3-2.9c0.1-8.5,1.2-17.1-0.7-25.6c5.6-18,0.6-37.4,3.5-55.8c0.4-2.7-0.6-4.4-2.7-5.4
				c-10.7-5.1-18.7,2.1-19.1,17.7c-0.3,12.9-2.3,26.7,0.2,38.5c1.9,9.1-2.8,20.4,4.5,27.4c-3.6,2.5-5.1,7.6-2.3,10.9
				C2425,1162.8,2427.6,1155,2430.9,1153z M490.3,929c0.8,1.3,2.2,3.7,2.3,3.6c2-1.7,0.9-3.8,0-5.7c-3-11.1-8.9-18.5-14.7-26.2
				C482.9,909.5,482.2,923.5,490.3,929z M107.6,1321c0.4,0.6,0.8,1.3,1.3,1.9c0.1,0.1,0.5-0.2,0.8-0.3c-0.7-0.5-1.5-0.9-2.2-1.4
				c0-1.5,0.1-3,0.1-4.5c0-4.7-0.2-9.7-4.6-9.9c-3.4-0.1-7.4,2.1-7.8,6.6c-0.5,5.8,4.3,5.4,7.1,6.9
				C103.9,1321.1,105.8,1320.7,107.6,1321z M156.9,828.7c3,1.5,5.8,1.1,8.6-1c0.6,1.9,1.2,3.9,1.8,5.8c-2.2,2-5.5,2.8-4.5,8.2
				c-6,0.8-12.5,5.2-12,12.7c0.9,12.2,7.5,2.3,11.7,2.8c1,0.1,2,1.2,3,1.9c13.1,2.5,16.1-2,11.6-17.5c-0.8-2.7-2.2-5-3.3-7.5
				c1.4-4.1-1.6-12.3,3.6-12.3c7.6,0,3.9,13.3,8.9,17.7c2.5-10-0.8-21,3.7-30.4c1.6-3.3-2.3-4.2-3.4-6.5c1.3-4.1,2.8-8.1,3.8-12.3
				c1.5-6.4-0.9-10.1-5.2-10.4c-7.5-0.6-12.8,11.6-10.3,22.9c-0.6,5.9,6.7,14.6-3.2,17.1c-3.4-1.6-3.3-10.6-9-7.6
				c-5.4,2.9-6.3,8.4-6.2,15.3c-0.4,0.6-0.7,1.2-1.1,1.8C156,829.1,156.4,828.9,156.9,828.7z M520.1,431.7c-0.4,3.3,0.8,5.4,2.1,8
				c2,4,4.7,5.5,7.4,5.5c2.7,0.1,2.3-4.6,2.8-7.2c1.1-5.3-2.3-7-4.8-8.5c-1.6-0.9-3.5-0.7-5.2-1c0.9-4.3,2.8-9.2-2-11.3
				c-6.2-2.8-7.6,6.3-11.3,9.6c-1.7,1.5-3.1,3.8-1.2,6c2.8,3.2,6,3.1,9.2,0.4C517.9,432.4,519.1,432.2,520.1,431.7z M2243.3,1162.1
				c1.1,2.7-5.9,8.5,1.6,8.8c2.5,0.1,6.2,3.3,6.8-3.2c0.8-8.8-4.1-3.5-6.5-4.6c-0.7-0.3-1.4-0.5-2.1-0.7c-0.5-4.6,1.8-6,4.2-7.5
				c2-1.2,4.4-2.8,3.3-6.6c-1-3.5-3.2-5.2-5.9-5.9c-3-0.7-5.4,0.6-6.1,4.4C2237.5,1153.1,2236.7,1160,2243.3,1162.1z M121.6,1282
				c4.2,3.5,8.6,3.8,11.6-1.9c2.4-4.5-0.5-8-3.2-9.3c-3.2-1.5-4.6-8.6-10.2-3.5c-6.4,5.8,1.8,8.5,0.1,12.5c-1.6,1.3-4.8,2.3-2.4,5.7
				C119.7,1288.3,121,1285.1,121.6,1282z M1661.9,991.5c-4.4,0.9-9.8-0.8-11.4,7.9c12,0.5,12,0.5,13.7-7.8c2.6,4.2,5.6,7.4,10,5.6
				c4.6,2.5,6.1-4.5,9.4-6c9-4.1,4-10.1,0.4-14.5c-4.6-5.6-22.2-1.4-25.6,5.6c-0.2,0.4,1,2.2,1.6,3.4
				C1659.7,988.1,1660.3,990.2,1661.9,991.5z M164.1,1046.5c-4.6,6.1,0.7,9.8,1.8,14.5c0.8,3.3,2,8.6,4.9,5.9
				c5.1-4.6,2.6-13.1,2.4-19.6c-0.2-6.4-3.5-2.2-5.6-1C166.4,1044.8,165.2,1044.2,164.1,1046.5z M442.9,1116.5
				c0.9-7.3-0.3-13.8-4.1-20.4c-0.3,6.4-0.7,11.7-0.8,17.1c-0.1,6.2,2.7,4.3,5,3c-2.3,3.7,10.2,9.5-1,12.5c-2.4,0.6-0.9,5.1,0.3,7.7
				c1.5,3.4,4.2,5.7,6.4,4c6.1-4.9,0.9-12.4,1.3-18.7C450.7,1113.5,446.7,1114.5,442.9,1116.5z M2409.5,1081.7
				c0.3-1,0.7-1.9,0.8-2.9c0.8-6.4,2.6-12.7-4.8-14.8c-8.3-2.3-4.5,5.5-5.1,9.9c-0.3,1.8-1.5,3.4-2.2,5.1
				c-7.3,8.7-4.3,19.9-3.7,30.5c-1.4,3.5-1.7,6.8,0.6,9.9c-7.6,2.6-4.6,7-1.7,12.9c6.6,13.2,4.2,18-5.4,23.5c-6,3.4-11.2,2-16.7,2
				c-8.4-0.1-6.1,7.5-5.9,13.7c0.1,3.1-1.5,8.9,2.5,8.3c7.9-1.2,0-8.2,1.7-11.8c1-2.2-2.6-6.7,2-7c3-0.2,4,2.5,4.1,5.5
				c0.3,8.3,0.1,16.6,0.2,24.9c2.5-13.7,3.7-10.8,12.5-18.1c6.6-5.5,4.9,1.3,7.3,3.5c6.9,6.2,10.2,18.9,21.7,21.4
				c-5.5-10.3,2.3-22.6-3.6-29.9c-9.1-11.3-12.9-23.1-9.5-39.2c8.3,3.3,8.3,3.3,5.2-14c2.1-8.2,2.3-16.4-0.4-24.4
				C2410.9,1087.8,2411.1,1084.8,2409.5,1081.7z M653.7,727.3c4.2,13.4,11.2,8,17.8,5.2c2.9,1.6,5.5,4.4,8.9,3.1
				c8.2,7,16.4-0.7,24.6-0.1c14.1,3.7,27.1-6.1,40.6-6.6c6.7-0.2,8.2-12.5,16.6-7.6c8.3,4.8,17.1-2.1,24.2-10.2
				c6,7.9,12.4,0.4,18.7,1c3.1,0.3,2.2-4.5,2.7-7c0.6-3.5-1.3-4.1-3.2-4.2c-6.2-0.2-12.3,0.1-18.2,2.5c-10.8-0.3-21.8,0.4-32.5-1.5
				c-4.9-0.9-7.1,8.1-12.4,3.2c-5-4.6-11-3.5-16.5,0.3c-2.8-2.3-5.5-4.3-6.9,2c-11-7.5-22.5-3.4-33.8-3.3
				c-3.1-13.1-7.1-0.9-10.7-0.8c-5.7,3-13-2.1-17.5,6.8c-0.5-5.5-3.1-4.7-5.6-3.5c-1,0.5-2.2,1.5-1.3,3.2c2.1,3.9,4.7-0.5,7,0.6
				c-0.6,1.5-1.3,3.1-1.9,4.6c-2.3,1.7-6.5-0.1-6.5,5.8C647.7,725.9,650.5,727.4,653.7,727.3z M2462.5,1153.5
				c-0.5,17,0.3,17.7,13.6,9.8c-6-1.3-7.1-9.4-12-10.9c-0.8-12.7-9.6-10.6-14.2-11.5c-4.6-0.9-3.7,8.8-3.2,14.6
				c-0.5,1.9-1.5,3.8-1.4,5.7c0,5.1,6.2,11.2-1.3,15c2,6.5,6.3,10.7,10.4,12.2c5.1,1.9,4.2-5.3,3.6-9.4c-0.9-7.2,0.9-15.7-4.9-20.6
				C2456.8,1158.7,2459.5,1155.6,2462.5,1153.5z M1869.5,1030.8c-0.1-19.3-0.1-19.3-17.6-16.2c4.2,1.5,5.4,6,6.7,10.6
				c-2.3,3.4-3.9,7.1-2.4,12.1c-0.4,13.4,1,27.1-2.6,40.1c-1.5,5.3-6.4-1-7.9,5.2c4.5,1.3,7.9,6.8,13.5,5.2c2.9-0.9,1.4,3,1.1,5.1
				c-5.5,7.9-9.4,34.1-4.8,41.4c3.9,6.2,3.5,8.5,0,14.5c-3.8,6.5-1,15.4-0.5,23.3c0.1,2,2.5,4.8,4.3,3c3.3-3.4,9.2-8.5,8.8-11.3
				c-3-18.8,8-40.6-6-56.9c-3.4-3.9-2.3-9.1-1.9-14.1c6.1,0.6-1-12.9,6.9-10.4c-8.4-0.3-8.4-0.3-13.5-5c6.2-2.5,17.8,4.5,14.5-14.8
				c-1.2-6.9,0.3-11,4.3-10.8c6.3,0.3,12.5,3,18.8,4.7c0.1,0,0.3,1,0.2,1.2c-4.2,6.2,2.7,21.5-9.3,21.7c-2,0-1.4,5.3-1.6,8.3
				c-1.6,24.6,2.1,48.9,2.8,73.4c0.1,2.9,0.7,5.3,3.1,4.4c1.8-0.6,0.8-2.7,0.9-4.6c1.2-22.6,1.2-45.3,0.9-68
				c-0.1-4.4-1.1-10.1,4.1-10.2c5.2,0,5,4.9,5,10.6c0.1,21-2.8,42,0.6,63.1c1.6,9.9,0.2,20.6,1.5,30.6c1.3,9.7,4.5,17.8,14,13.9
				c3.7-1.5,7.3-5.3,11.6-3.8c6.9,2.3,7.1-4.2,7-10.7c-0.2-11.4-2.3-22.7-1-34.2c0.6-5.6-0.9-8.1-5.5-7.6c-5,0.5-10.7,1.5-14.6-7.4
				c9.9,4.4,13.5-11.5,22-11.3c2.6,0.1,3-3.7,3.1-7c0.1-10.5,1.1-21.2,1.6-31.2c1.2-24.3,1.8-48.6,3.7-73
				c1.8-23.5,6.1-48.6-0.8-72.5c-1.1-3.8,1.6-3.7,2.1-5.5c4.3-15.7,0.4-24-11.3-27.5c-9.5-2.8-19.3,0.2-28.7-4.6
				c13.3,0,26.5-0.1,42-0.2c-3.2-2.2-4-3.1-5-3.5c-20.2-7.3-41.1,0.5-61.4-5.8c-1.9-0.6-5,1-4.6,2.6c3.3,13.8-1.4,30.4,7.6,42.1
				c-0.1,0.7,0,1.4,0.5,1.8c-0.5,4.4-0.5,8.8,1.1,12.8c0.7,7.8,5.4,14.7,3,23.3c-7.8,0.9-10,6.7-7.3,16.6c-2,7.6,5.9,8.5,5.4,15
				c-6,1.5-5.8,4-0.5,7.1c2.2,1.3,6.8-0.3,3.6,6.6c-5.1,0.4-10.5,0.2-9.1,11c-0.2,5.9,4.2,14.1-3.1,16.6c-6.8,2.4-6.4-7.3-8.7-12.2
				c-0.2-0.4-0.1-1-0.1-1.5C1868.7,1036.3,1872.4,1036.9,1869.5,1030.8z M2595.3,643.3c-1.8-0.4-3.7-0.3-5.2-1.3
				c-4.3-2.8-8.7-2.5-9.3,4.4c-0.5,5.4,2.8,9.6,7.9,8.8c3.5-0.5,7.1-0.1,10.8-0.1c-0.9-5-4.9-6.9-4.3-12.1c3.1,0.2,6.9,2.6,7.2-4.8
				c0.1-3.4,0.9-7.9-2-9.3c-3.3-1.5-6.7,1.1-8.6,4.5C2588.6,639,2596.3,638.4,2595.3,643.3z M165.8,880c-3.8-0.3-3.8,5.3-6.1,8.1
				c-0.3-1.8-0.6-3-0.7-4.3c-0.5-3.7,0.7-9.2-3.9-8.8c-1.6,0.1-4.8-1-4.8,3.8c0.1,6.6,2.3,7.3,5.8,3.5c0.3,0.8,0.9,1.6,1,2.6
				c0.7,5,1.1,11.2,6.2,9.2c6.1-2.4,1.6-9.5,2.4-14.3c6.1,0.3,11.7-0.3,11.7-11.5c-5.3,0-10.6,0-15.5,0.1c-1,3.6-0.1,5,1.3,6.1
				C164.8,875.7,166.7,876.6,165.8,880z M283.1,303.3c9-2.3,8.9-4.1-1.8-22.6c-5.1,7.3-7.6,14.2,1.4,19.7c0.3,0.2,0.2,1.8,0.2,2.8
				c-2.7,2.8-9.8,0.3-6.3,10.7c1.3,3.9,2.4,8.6,5.8,6.2c5.9-4.2,1.4-9-0.5-13.4C281.2,304.7,282.3,304.1,283.1,303.3z M174.2,762.9
				c-4,1.4-11.5-6.4-10.7,7c0.3,5.3,1.2,14.4,7.1,13c6.1-1.4,7-9.3,5.7-17.2c3.9,1.6,7.2,1.1,8.6-5.2c0.4-1.9,0.3-4.9-1.3-4.5
				C180.1,757.1,175.1,754.9,174.2,762.9z M48.2,1299.2c0.6,0,1.2,0.2,1.8,0.5c0.2,5.3-0.9,11.9,5.4,11.3c7.1-0.6,14.3,2.1,21.3-1.8
				c12.6-0.8,14.6-2.4,18.2-14.9c-0.6-0.8-1.2-1.6-1.9-2.4c2.3-4.1-0.6-8.7,0.7-14.2c4.7,5.9,8.6,4.3,11.3-2.5
				c4.4-11.1-3.7-11.6-8.2-15.2c4.4-5.1,14.7-7,3.8-16.1c4,1.1,8.7,5.4,10.9-3.1c1.1-4.5-1.7-5.7-4.5-6.1c-5.1-0.7-7.5,2-6.2,9.5
				c-8.4-6-4.5-12.3-0.3-18.8c4.3-6.7-0.8-12.2-1.7-18.1c-0.4-2.7-3.7-3.3-5.9-2.2c-5.1,2.6-2,8.8-2.6,13.3
				c-0.6,4.5,0.7,8.8,1.6,13.3c2.4,11.9,1.7,22.6-9.5,25c-0.1-10.5-0.4-21.1-0.3-31.6c0.1-6.1-0.7-10-5.9-8.6
				c5.1-3.6,7.6-8.5,5.9-17c-0.8-4-2.2-5-4.6-4.2c-9.2,3.3-17.9-2.8-26.9-1.9c7.3-0.4,14.6-0.1,21.9-0.6c8.3-0.6,13.7-7,10.7-18.7
				c-1.6-6.2,1.4-6.9,2.6-9.8c1-2.5,3.2-2.5,4.9-1.4c3.6,2.2,2.8,6.6,2.5,10.6c-0.3,4.1-0.7,8.1-1,12.2c-0.3,4.3-0.4,8.3,4,8.8
				c4.1,0.4,5.7-1.5,6.3-7.5c1-10.5,2.6-20.9,10.6-26.4c4.2-2.9,2.5-7.7-0.1-9.5c-5.2-3.7-2.3-8.3-2.7-13.4
				c-1-12.4,7.9-5.9,8.9-10.9c1.8-8.9-10.8-0.1-8.4-11.9c0.8-3.8,0.3-8.2,0.4-12.3c0.1-3.1,0.3-6.2,0.2-9.2c-0.1-3.8-1-7.7-4.2-7.7
				c-3.1,0-2.6,4.4-2.5,7.2c0.5,12.9-3.2,14.2-10.3,7.8c-1.6-1.4-3.7-2.1-5.6-0.3c1.4,4.9,5.1,3.9,7.5,6.2c2,2,5.2,1.8,4.2,6.9
				c-1.1,6.2-4.2,1.8-6.2,1.7c-9.8-0.5-19.7,0.9-29-6.1c-3.2-2.4-8.1-1.6-12-0.5c-5.3,1.4-7.2,9.2-7.2,14.7c0,5,5.2,3.4,8.3,3.5
				c1.8,0.1,3.6-1.1,5.4-1.2c13.2-1.4,26.3-2.7,39.5-4.1c-3.3,6.1-3.9,13.8-10.6,12.6c-12.5-2.3-24.6,3.1-36.7,5.9
				c-5.4,1.3-6.9,5.7-6.9,12.9c-0.1,21.2-2.1,42.4,0.4,63.6c-0.9,17.7-0.6,35.3,1.2,52.9c0.6,5.7,3.9,15.6-5.7,14.1
				c-0.2-0.4-0.3-1-0.6-1.2c-0.2-0.2-0.7,0-1,0.2c-7.9,7.9,0.4,14.8,0.9,22.1c0.2,3.5,2.7,5,5,3.8c2.7-1.4,1-5.3,1.7-7.9
				C47.5,1300.1,47.9,1299.6,48.2,1299.2z M395.7,931.5c0.9-7.7-2.8-8-6.6-8c-0.9-1.3-1.9-2.7-2.8-4c0.4-1.9,0.8-3.9,1.2-5.8
				c3.9,0,8.7,1.6,8.7-7.1c0-8.9-5.2-5.3-8.2-5.4c-16-0.3-32.3,2.6-47.9-1c-12.1-2.8-11.8,2.6-9.7,14.4c0.3,1.6,0.5,3.1,0.8,4.7
				c3.4,8.3,7.4,16.3,13,21.7c3.3,3.3,6.6,6.1,5.1,11.8c-1.9,7.5-5.7,2.8-9.1,1.4c-5.1-2.1-9.6-2.7-8.3,8.4
				c2.9,24,1.6,48.7,4.8,72.4c2.6,19.1,2,39.6,9.4,57.3c-6.7,17.8,0,36-0.2,53.9c0,5.7,7.4,9.5,10,5c7-12.2,11,5.8,16.7,1.8
				c2.4,6.9,1.9,14.8-1.4,19.3c-5.3,7.2-0.1,10.5,1.2,15.4c-1.7,0.8-3.9,1.4-3.9,4.5c0,16-4.5,32-0.4,48.1
				c-11.8,2.6-3.2,18.1-8.2,25.9c-1.2-3.2-2.2-5.8-3.3-8.3c-3.2-7.2-7.8-13.6,0.7-21.2c2.2-2,6.9-14.4-2.7-18
				c-0.9-8.7,3.8-13.5,6.9-19.3c2.1-3.9,0.9-5.4-0.8-8.9c-2.5-5-5.8-5.6-9.2-6.3c-0.7-0.1-1.6,2.9-2.5,4.5
				c8.2,7.9,5.4,17.2,2.1,26.8c0,0.1,2.3,1.9,3.6,2.9c-3.3,3.8-4,8.5-4.1,14.6c-0.2,19,4.7,36.3,8.4,54c1,4.7-3.5,12.9,2.9,14.1
				c7.6,1.4,9.8,3.5,4.3,13c-0.9,1.6-3.4,6.8-1.4,11.1c16.8,3.1,25.3-7.4,19.4-25.5c-2.7-8.2-3.1-16.4-4.6-24.6
				c-0.8-4.3-2.4-6.9,3.3-6.2c3.9,0.5,0.4-8.5,2.6-12.5c0.7-1.4-0.7-3.9-2.5-3.7c-3.6,0.4-3.7,4.4-3.7,7.9c-0.1,6.1-3.9,4.8-6.3,6.3
				c-0.4,0.2-2.1-2.1-2-2.3c4.2-9.5-6-15.6-3-24.7c3.2-2.2,6.5-1.4,9.1,1.4c2.6,2.7,4.8,5.4,7-0.1c1.8-4.6,1.3-9.3-1.9-12
				c-2.6-2.2-6.6-2.7-0.6-6.2c1.9-1.1,2.4-4.4,0.4-5.9c-8.7-6.6-6.6-19.8-9.7-29.8c6.2,2.5,12.2,7.5,17.9-3c1.9-3.4,7.8-5.5,12-0.3
				c9,10.8,19,10.3,27.6-1.3c1.3-1.7,2.4-2.3,3.8-1.2c4.9,3.9,4.9-1.4,4.8-5.4c-0.2-6.3,2-13.3-1.9-18.7c-0.6-0.8-3.8,0.3-3.8,0.8
				c-1.2,13.9-7,5.2-11.5,5.4c-6.1,0.3-14.2-4.9-17.8,6.1c-2.3,7.1-5.2,9.8-10.4,10.6c-7.3,1.1-10.7-11.4-18.4-9.4
				c-0.9,0.2,5.5-27.2,8-26.3c5.2,1.7,8.5-3.2,8-7.3c-0.9-7.1-7.7-5.6-11.6-9.3c-3.1-2.9-6.3,1.8-6.5,8.6c-0.1,4.5,1.6,12.3-3.9,12
				c-6.6-0.4-2-9.1-3.5-13.6c-0.5-1.6,0.1-4,0.2-6l0.4-0.2l-0.3,0.3c-6.2-4.8-9.8-11.4-5.7-21.1c1.9-4.4-0.7-6.5-1-9.7
				c4.3-4,2.3-10.3,2.4-15.4c0.6-27.6,0.8-55.1,1.3-82.7c0.1-4.1-2.4-11.5,4.5-10.1c2.3,4.8,0.7,10,0.5,15
				c-0.7,14.1,5.1,24,10.6,34.2c-3.1-0.1-5.1,2.4-5.1,6.4c-0.3,19.3-0.3,38.5-0.3,57.8c0,2.9,0.5,6.1,4,4.7
				c4-1.7,16.7-27.1,14.4-31.2c-8.2-14.6,3.9-12.7,7.3-18.8c-3.5,0.1-6.1-1.8-7.3-5.9c-2.4-8.5-3.2-17.8,2.1-23.8
				c4.8-5.4,5.1-3.9,3.1-13.1c-0.7-3.1-3.1-11.5,4.2-9.8c0.6,2.2,0.4,5.4,2.9,5.7c1.2,0.1,2.4-0.6,1.7-2.6c-0.9-2.8-2.9-2.6-4.7-2.8
				c-1.8-8.1,10.5-15.4,0.3-24.3c-5.7-5-1.9-17.6-8.7-23.3c3.4,11.6,0.8,23.1-0.4,33.9c-1.3,11.8-3.8,23.3-4.1,35.5
				c-0.3,11.2,2.8,23.7-5.3,33.7c-1.1-7.7,1.1-14.8-2.9-20.3c5.7-9,2.1-20.2,3.2-30.2c1-8.9-0.4-17.3-9.5-16.6
				c-1.9,0.1-3.9-2.6-5.8-4c2.7-14.9,2.9-15.2,15.5-28.4c1.4-1.5,1.3-3,0.4-5.1c-1.7-4-1.3-7.7,1.6-10.6c3.7,0,7.7,2.8,10.8-2.2
				C396.5,939.7,396.7,935.6,395.7,931.5z M2428.3,952c2.3,13.1,8.9,3.8,13.7,6.2c-2.3-16.2-8.7-7.8-13.8-6
				c4.6-6.4,3.3-11.5-1.6-16.2c-3-2.9-7.4-4-8.4,0.4c-1.4,5.9-2.1,14.6,0.1,19.2C2421.3,962.1,2424.7,952.9,2428.3,952z
				 M615.1,963.4c6.6,4.4,13.6,1.1,20.4,1.5c2.2,0.1,5.6-0.3,5.2-5.5c-0.3-4.2-3.1-3.7-5.1-3.7c-6.9,0.1-13.7,0.5-20.6,0.7
				c-4-0.7-10.5-4.1-10.8,2.9c-0.3,8.6,6.5,1.8,9.8,3.9C614.3,963.4,614.7,963.4,615.1,963.4z M1529.8,1381.6
				c-2.8-1.9-5.6-4.4-9-2.7c-7.6-3.6-14.1-2.2-15.9,10.2c-2,13.9,6.8,10.1,11.7,12.4c3,6.1,7.2,2,10.9,2.4c1.7-1.3,7.3,5.1,4.8-4.7
				c-1.1-4.3,1-7.6,1.7-11.3C1534.9,1384.3,1533.4,1381.9,1529.8,1381.6z M2462.2,1137.3c4.1,4.8,10.1,5.1,13.4,11.8
				c1.2,2.5,3.5,4.9,5.4,1.6c1-1.8,2.9-5.3,0.3-7.9c-3-2.9-3.1-7.9-4.6-11.9c-3.5-9.6-6.8-9.1-10.4-0.2c0-4.2,0.1-8.4,0.1-12.6
				c6.2-12.1-3.5-14.1-6.7-19.9c-2.7-4.9-8.4-12.5-10.8-5.5c-2,5.7-9,17.6,1.1,24.7c-3.4,3.5-2.4,8.2,0.4,9.7
				C2454.8,1129.7,2457.2,1136.6,2462.2,1137.3z M2470.4,982.6c-4.3,9.8,3.3,8.2,5.9,11.9c-3.5-5.7,10.3-13.9-1.6-17.8
				c-1.1-3,7.7-7.8-0.4-8.8c-6.8-0.8-14.1-1.9-21.5,1.8C2457.3,979.7,2462.9,983.9,2470.4,982.6z M2511.1,1210.5
				c-0.7,7.2,1.7,9,6.4,7.7c-0.9-5.6-4.2-5.8-6.6-7.5c0-5,0-10-0.1-15c-7.3,2.7-12.7,12-21,9.3c-1.6-0.5-1.7-0.1-0.9,6.1
				c-5.7,18.8-3.6,23.9,10.6,27c4.5,1,10.7-1.6,10.5,10.4c-0.3,23.5,0.2,47,0.6,70.4c0.1,6.5-2.3,16.4,5.3,16.8
				c7.8,0.3,5.1-10.4,6-16.6c0.7-4.3-0.2-9.2-0.6-13.7c-0.5-6.7-1.4-14.2,1.1-19.5c4.8-10.1,2.7-19.1,0.1-28.8
				c-1-3.8-2-7.8-2.3-11.7c-0.8-14.4-3.8-17.6-14.3-14.4c-14.7,4.5-17.2,1.6-17.4-20c15.5-17.4,6.6,14.1,14.9,13.7
				c-4.6-4.1,1.4-6.2-0.4-11.5C2502.1,1209.3,2507.1,1204.5,2511.1,1210.5z M1295.5,1390.3c8.1,1,14.8-2.8,12.4-15.3
				c-1.5-8.2-9.2-7.3-14.8-7.6c-8.3,1.2-3.3,9.8-3.8,14.9c-0.3,3.4-1.9,10,3.5,10.5C1293.6,1392.8,1294.6,1391.1,1295.5,1390.3z
				 M1294.7,1218.2c-0.2-0.3-0.3-0.7-0.5-1c-0.1-0.1-0.4,0-0.5,0c0.4,0.2,0.8,0.5,1.2,0.7c-0.4,4.8-0.9,9.2-4.3,12.5
				c-2.8,2.7-2.2,8.3,0.6,9.9c2.3,1.3,4.7,8.1,8.1,2c2.9-5.3,6.4-10.4,5.2-17.8C1303.2,1217,1298.4,1219.1,1294.7,1218.2z
				 M2245.5,1199.4c-0.7-5,8.5-5.1,3.4-11.1c-3.3-3.8-7.9-12-11.6-8.5c-5.3,5-2.1,15.6-4,24.3c4.1-0.7,7.2-1.3,10.2-1.8
				c0.4,5.8-10.4,4.6-4.5,12.6c1.8,2.4,7.7,7.7,10.1,1.7C2251.3,1211.4,2254.7,1201.8,2245.5,1199.4z M2289.7,1149.7
				c1.3,3.9-4.7,8.3,0.2,11.6c1.8,1.2,4.4,0.9,5.7-2.4c0.7-1.7,1.8-4,1.4-5.4c-1.3-5.2-5.3-1.3-7.5-3.6c2.4-5.6,12.8-0.2,9.8-11.6
				c-2.1-7.9-9.9-6.9-15.3-8.9c-2.3-0.8-3.5,3.1-2.6,5.1C2283.8,1139.9,2280.8,1151.2,2289.7,1149.7z M1177.8,694.4
				c-1.5,0.8-4,1.2-4.4,2.6c-0.9,3.9,1.4,5.9,3.6,7.4c6,10.6,16.6,6.5,23.4,14.3c0.6-1.2,1.2-2.5,1.8-3.7c0.8-0.3,1.6-0.6,2.5-0.9
				c6.5,7.3,13.5,12,22,7.1c9.8,11.4,21.9,6.4,32.9,9.2c10.6,2.6,12.3-5.5,10.6-17.4c-0.5-3.4-1.7-6.6-2.5-9.8
				c1.3-2.4,3.2-6.5-0.6-6c-17.2,2.1-35.5-6.1-51.6,8.7c-5.2-7-11.5-6.9-18-5.9c-5.6-3.2-12.6,2.6-17.4-5.5
				c10.6,0,21.2-0.1,31.9-0.1C1200.6,693.2,1189,687.5,1177.8,694.4z M135.7,1170c4-6.4,3.1-15.2-1.3-16.8
				c-3.7-1.4-8.9,5.5-11.2,12.1c-2.9,8.5,1.4,10,6.1,10.7c2.4,8.1-9.3,4.3-5.5,11.1c3.4,6.2,10.2,6.4,15.4,4.7
				c5.9-1.9,4-11.6,4.7-17.1C144.9,1166.3,138,1173.7,135.7,1170z M1469.9,1375.2c-7.2,2.4-11.6,6.9-7.1,18.2
				c3.6,9.7,10.2,8.1,14.7,4.3c4-3.5,4.7-12.6,3.2-19.2C1479,1370.6,1474.1,1372.6,1469.9,1375.2z M237,395.7
				c0.1,14.4,8.1,7.7,12.8,9.1c2.9,0.8,5-1.4,4.8-6c-0.1-4.9,1.6-11.1-1.3-14.5c-3-3.6-5,2-6.9,4.6
				C243.7,392.4,240.4,394.4,237,395.7c2.4-9.3,10-11.4,13.5-19.3c1-2.3,4.8-3.1,2.7-6.7c-1.4-2.4-4.3-4.1-7-1.4
				c-4.4,4.6-8.6,9.3-13.1,13.7c-2.8,2.8-8.1,3.6-6.6,10C228.2,398.8,233,397,237,395.7z M2569.1,1432.7c-0.9-9.8-0.2-16.8,9.1-11.7
				c4.7,2.6,4.8-6.4,8.2-8c1.8-0.9,1.2-3-0.1-4.5c-2.6-3.1-5.2-1.6-8.1-0.7c-10.6,3.3-15.4,12.1-13.8,27.2c-2,6.9-1.9,13,4.3,15.3
				c3,1.2,6.2,0.8,6.8-4.5C2576.1,1439.4,2576.6,1432.1,2569.1,1432.7z M941.2,711.2c-0.3-0.4-0.5-0.8-0.8-1
				c-0.2-0.1-0.8,0.1-0.8,0.2c-0.2,7.7-6.1,17.5,4,21.4c6.1,2.5,12.2,2.2,18.4,0.9c2.6-0.5,3.8-2.5,3.7-6.1
				c-0.1-2.5-0.1-6.2-2.3-5.8C954.9,722.2,949.2,711.7,941.2,711.2z M545.5,733.9c0.8,3.2,2.6,3.2,4.6,2.7
				c9.5-0.1,18.9-0.3,28.4-0.4c0.9-0.3,1.8-0.5,2.7-0.8c12.7,6.9,19.2-7.1,26.5-16.4c2.9-3.7,4.5-14.9-2.3-15.4
				c-13.4-0.9-27.2-2.9-40,5.9c-0.7-0.2-1.4-0.5-2.2-0.7c-0.3-1.8-1-2.7-2.3-2.1c-3.2-0.9-6.2,0-8.9,2.5c-0.6-8.1-5.1-4.7-8.3-5.6
				c-11.8-3.3-23.3,5.2-35.5,1.1c-5.9-2-8.9,5-7,13.8c-4.9-2.8-8.8,2.6-13.2,3.2l-0.6-0.8c-0.7-5.4,7-4.7,4.4-10.2
				c-2.7-5.7-5.6,0.9-8.4,1.8c-0.2-4.7-1.5-7.2-5.3-7c-6.6,0.4-13.1,0.3-19.7,0.4c-8.5,0.2-5.9,5.4-2.4,10.8
				c-9.2,0.4-4.4,11.7-6.5,17.5c-0.8,2.1,3.4,1.6,5.4,1.8c3.8,1.3,5.9-2.8,8.4-5.4c6.5-6.8,12.7-14.3,20.4-18.5
				c-2.2,9.5-9.1,11.7-13.6,17.4c5,6.8,11.5,6.9,18,7.5c-8.1,8.6-17.8,0.2-26.3,5.4c10.1-1.7,20.7,4.5,30.4-3.6
				c6.6-2.1,14.6,5.7,20.1-5.2c4.4,4.1,8.9,8.9,14.5,4.7c3.9-3,5.7-7.6,3.2-13.8l-0.2,0.3c8.5,5.7,10.8-10.3,17.9-9.7
				c1,5.9-4.5,4.7-5,9c-2,2.7-4.7,5.2-2.7,9.8C541.7,737.8,543.7,734.4,545.5,733.9z M736.4,875.2l-0.2-0.2c4.2,5.6,6.1,1.8,7.5-3.6
				c1.2-4.6-1.2-7.9-3.7-7.2c-12.4,3.4-25.2-3.5-39.1,3.4C713,876.7,725.8,866.7,736.4,875.2z M351.7,891c11,2.6,22,1.1,33,0.2
				c7.6,5.1,11.1-2.3,11.4-10c0.3-7.6-0.6-17.8-10.3-14.6c-4.1,1.4-8.6,0.7-13,0.4c-3.5-0.2-3.1,5.2-5.4,6.4c-0.2,0.1-0.2,1.9,0,2
				c1.4,0.5,3.1,0.3,4.2,1.3c1.6,1.5-0.2,3.4-0.3,5.2c-4.2-3.7-8.5-4.6-13-1.1c-3.5-3.6-7.4-1-11.1-1.6c1.8-4.9,5-2.8,7.7-3.2
				c2-0.3,4.7,1.1,4.9-3.5c0.2-4.4-2.5-4.7-4.5-4.8c-7.3-0.3-14.6-0.3-21.9-0.3c-1.9,0-3.7,0.6-4.1,3.7c-0.4,3.1,0.9,4.7,3,4.9
				c2.3,0.2,4.4,0.9,5,5c-3.3,1.5-8.3-3.1-9.9,4.2c-0.4,1.7,0.8,5.4,2.1,6.4c4.3,3.2,8.3,2.8,11.5-3.3c0.7-1.3,2.6-1.5,3.9-2.2
				C345.2,893.2,348.8,891.3,351.7,891z M1277.4,1184.5c-6.2-2.1-5.6,5.6-6.5,9.8c-0.7,3.3-1.3,8.3,2.6,10.2c3.2,1.5,6.1-0.4,8-3.1
				c2.7-3.9,1.8-9.2,0.9-14.1c9.4,8.9,15,7.1,18.3-6c3.4-18.2-5-19.3-14.5-17.5c0.7-7,11.8-6.6,4.5-14.6c-1.5-1.6-6-4.6-9.6-5.8
				c-5.6-1.9-8.5-0.9-8.1,7.9c0.3,7.9,0.8,15.9,1.1,23.8C1271.6,1180.9,1275.8,1181.8,1277.4,1184.5z M627.4,751.6
				c-1.3-4.2-3.5-3.3-5.7-2.1c-58.9-3.1-117.8,1.6-177.2-2.4c9.1,8.5,3.7,20.1,4,29.8c0.3,10.4,3.2,13.3,9.4,9.7
				c10.3-6,19.7-2.6,29.2,3.2c6.5,4,13-0.4,19.1-1.7c4.8-1,1.8-9.6,2.4-14.8c0.9-8.1,2.3-11.8,9.9-9.1c6.7,2.5,11.8,8.7,18.4,10.8
				c11.7,3.8,22.9-1,34.2-3c18-3.1,35.8-8.7,54-9.8c0.8,1.5-0.5,4.8,2.8,4c6.8-1.6,11.8-10.8,19.4-8.9c2.4,0.6,5.1,2.6,6.6-1.9
				c0.8-2.5,0.5-5.2-1.1-6.8c-2.2-2.3-4.9-2.8-7,0c-5.6,7.4-11.9,5.6-18.3,3.2L627.4,751.6z M952.5,793.9c4-1.6,6.7,5.2,13,3.5
				c-6.8-5.7,8.6-29.5-12.2-21.2c-1.9,0.7-4.9-2-6.5,2.4c-1.8,5.2,4.1,4.9,3.7,9.3c-3.1,1.1-5.6-1.6-8.4-2.4
				c-2.2-0.6-7.2-0.2-5.5,3.1c2.6,5.1-3.7,13.6,2.3,14.1c7.2,0.6,10,4.2,8.4,16.9C952.9,809.8,953.1,802,952.5,793.9z M596.8,864.7
				c-5.1,0.1-10.1,0.6-17,6.1c8,0.8,12.9,9.3,18.6,0.9c2.2,6.3,6.7,13.7-3.6,14.5c-0.6,0.1-6.1-0.3-5.2,9.2
				c4-10.9,18.3,8.1,16.2-15.4c0-0.5,3.1-2,4.7-2.1c2.8-0.2,3.8-2.3,3.9-5.7c2.5-3.4,5.8,7.1,7.9-0.9c0.5-2.1,1.3-5.2-1.4-5.9
				c-4.1-1.2-6.1,1.5-6.3,7.1C608.4,871.1,603.3,864.6,596.8,864.7z M784,1222.9c0.2,3.7,0.7,7.2,4.3,6.2c0.7-0.2,1.9-2.1,1.7-2.8
				c-0.9-4.8-4.1-2.2-6.2-3.2c4-4.3,7.6-6.6,13.1-2.4c6.1,4.7,10.3-1.2,13.6-8.7c-5.2-2.4-9.7-4.3-14.1-6.5c-5-2.6-9.3,0.1-12.8,4.3
				C780.6,1213.6,780.4,1218.7,784,1222.9z M2569.2,782.1c-4.2,0.3-7,2.3-6.1,9.1c0.6,4.2-1.3,10.2,2.6,12.2
				c5.6,2.9,7.4-6.3,11.9-7.6c-1.4-5.5-6.2-5.4-6.3-10.8c8.4,2.8,16.2,3.2,20.5-11.5c-9.6,0.8-18.7-7.8-28-2.1
				C2563.7,777.9,2570.8,774.8,2569.2,782.1z M313.5,716.3c-1,20.9,1.4,25.4,10.9,20.9c7.1-2.2,13.4,3.4,20.5,3.7
				c11.1,0.5,22.2-5.9,33.3-0.5c4,1.9,5.4-4.1,8.1-6.2c0.7-1,1.5-2,2.2-3c1.5-0.2,3-0.5,4.5-0.7c1.5,4.5,3.9,7.3,7.6,6
				c3.2-1.1,7.5,0.9,8.7-6.1c0.3-2,1.1-4-0.6-4.9c-3.2-1.6-6.5-5.2-9.7,0.2c-1.5,2.5-3.7,2.7-6,2.5c-3-8.3,8-9.5,4.6-17.9
				c2.2-2.4,6.4,1.4,7.9-4.4c-2.9-2.5-5.7-0.5-8.4-0.7c-8.3-1.5-16.5-0.8-24.8,0.6c-0.6,1.8-1.2,3.7-1.8,5.5
				c-4.1-7.1-9.5-6.8-14.6-3.6c-3.9,2.5-3,7.2-0.5,11.2l0.2-0.3c-5.2-3.6-8.9,4.3-13.7,3.3l0.2,0.2c0-5.3,6.7-6,4.8-11.6
				c-1.1-3.2-4.7-0.8-7.1-0.6c-7.8-7.2-16.3-2.2-24.5-2.2C311.9,707.7,313.3,713,313.5,716.3z M2697.9,954.2
				c-6.6-4.8-10.4-2.6-11.4,8.3c8.7,2.1,16.9,1.4,24.9-2.3c13.9,5.7,28.1,1.1,42.1,2.4c4.4,0.4,4.6-4.2,3.2-8.4
				c-1.3-3.7-2.7-7-2.9-11.3c-0.2-5.2-2.4-5.6-5.1-2.4c-9.5,11.2-23,4.5-33,13.8c-3.2-10-9.7-10-15.8-9.3c-4.9,0.5-1,6.4-2,9.5
				L2697.9,954.2z M1223,950.3c1.8-10.1,1.6-12-2.4-18.7c3.2-2.2,5.2,2,7.8,2.6c8.9,2.2,13.6-8.5,17.8-15.5
				c4.5-7.4-4.4-9.1-7.1-13.4c-3.7-5.9-7.6,2.9-11.7,1.7c-1.7-2.7,4.2-8.3-1.8-9.1c-2.3-0.3-5.9-2.2-6.9,1.8c-1,4.3-2.1,10,2,13.5
				c-0.9,6.2-0.9,12.3,0.1,18.5c-7.5,6.3-7.5,13,0.1,21.5c3.4,3.2-8.2,9.2,0.4,9.6c6.8,0.3,13.7,0.3,20.6,0.1c1.9,0,3.9-0.2,4.3-3.2
				c0.5-3.9-1.6-4.5-3.9-5.6C1235.9,951.4,1229.8,948.1,1223,950.3z M2324.7,1168.2c0.2-2,0.1-4.2,0.6-6.1
				c4.2-18-7.5-19.3-13.5-19.5c-8.8-0.3-2.2,11.9-3.9,18.1c-0.1,0.4,0,1,0.1,1.5c-0.9,5.5,1.6,8.9,4,12.4c-1,0.7-2.6,1.1-2.8,2.1
				c-3,15.5-3.3,31.1-0.2,46.7c1.1,5.4,4.6,4.1,7.3,4.3c3.2,0.3,6.5,0.7,7.5-5.7c2.6-16,3.2-31.8-1.4-47.4
				C2323.2,1172.4,2324,1170.3,2324.7,1168.2z M2405,1223.4c0,9.8,5.5,7.9,9.2,7.2c5-1,3.1-6.2,1.8-10.3c-0.3-3.1-0.6-6.2-0.9-9.3
				c0.2-10.7-7.6-10.7-11.5-15.8c-5.7-7.4-8.1-2.4-7.3,6.8c-2-0.3-4.2,0.3-5.2-3.2c2-5.9,2.2-11.8-0.6-17.2c-0.6-1-3.3-1.4-3.9-0.5
				c-3.8,5.3-0.8,11.2-0.4,17c0,0.4,0.4,0.8,0.7,1.2C2391.4,1209.3,2393.5,1223.2,2405,1223.4z M217.8,673.2c3-4.5,3.2-8.9-0.5-13
				c-1.5-1.7-3.8-3.2-4.9-1.1c-2.3,4.3-2.3,10-0.7,14.6c1.8,5.3,4.2,0.5,6.2-0.8c-3.1,18.2,13.9,25.8,29.8,23.9
				C239,680.2,229.3,672.5,217.8,673.2z M2588.4,603.1c-9.2,6-5.1,18-3.1,24.7c1.5,5,8.6,0,12.6-4.6c6.9-8-6.5-7.5-2.9-13.9
				c12.7-0.2,4.8-13.1,6-20c1.6-9-4.1-7.7-7.9-7.4C2583.6,582.5,2582.4,588.1,2588.4,603.1z M559.4,953.6c-1.1-0.1-2.3-0.8-3.2-0.3
				c-5.3,2.5-15.5-5.1-15.2,4.4c0.3,10.9,10.2,6,16.3,5.8c13.4-5.6,27.5,4.9,40.8-3.1c-0.5-8.7-5.3-7.6-9.7-7.5
				C578.8,953,569.1,953.3,559.4,953.6z M1604,1434.2c2-11.9-0.2-18.9-10.2-17.4c-4,0.6-8,0.1-12,0.5c-4.2,0.4-10.6-1.4-10.8,6.2
				c-0.2,8.2,6.3,5.7,10.3,6.5c7.1,1.4,14.5-1.9,21.4,2.4c-10.2,0-20.5-0.9-31.5,1.8C1580.3,1445.8,1598.5,1445.1,1604,1434.2z
				 M2274.3,1205.3c14-0.1,4.1-12.5,4.8-19c1.5-15.5-11.3,0.9-12.5-10.2c-0.1-1.2-4.1-2.9-5.9-0.4c-1.9,2.7-1.3,5.4,1.4,6.8
				c1.7,0.9,6.1,0.2,4.2,3.6c-6.7,12.1,3.7,11.1,5.8,16.3c-4.2,3.9-7.1-0.2-11.2-5.9c1.2,12.1-3.7,22.9,4.6,30.5
				c0.7,4.4,1.4,8.4-0.6,13.1c-5.5,12.2-3.8,27.7,3.9,34.9c3.5,3.3,7.9,2,11.8,0.6c4-1.4,3.6-8.4,2.8-10.1c-5-12-0.8-24-0.9-35.9
				c0-6.7-0.9-10.9-6.1-11.7C2275.8,1213.7,2275,1209.5,2274.3,1205.3z M243,219.9c-1.2,7.9-8,8.4-10.3,14.9
				c-0.9,2.6-3.3,4.6-0.9,7.8c1.9,2.5,3.2-0.1,4.7-1.3c7.9-6.6,14.8-14.6,21.1-24c-6.4-6.8-9.6,4.9-14.7,2.8
				c3.4-16.9,0.2-24.3-12.3-28.9c0,11.7,0.1,23.6,0.1,35.6C235.6,225.2,238.3,219,243,219.9z M1220.4,1486.8
				c0.7-5.1,5.6-3.8,6.6-8.5c-2.7-1.6-5.8-7.2-7.8-0.2c-1,3.4-3.4,4.8-3.9,8.3c1.8,0.1,3.6,0.1,5.4,0.2
				c10.3,23.4,26.8,12.1,40.9,14.3c1.6,0.2,2.9-1.3,2.5-3.7c-0.4-3.1-2.6-4-4.2-3.5C1246.1,1497.7,1233.6,1487.9,1220.4,1486.8z
				 M243.4,935.1c-1.2,5.7,4.1,9.8,2.2,15.7c-4.9-0.6-9.3-6.5-14.5-2.1c-12.7,10.7-25.5,21.3-38.2,31.9c4.4,10.1,15.4,6.9,17.7,19.4
				c-4.3-2.6-8.6-5.1-12.9-7.8c-2.1-1.3-4.9-2.5-5.7,0.8c-1.8,7.2-2.9,15.5-0.8,22.3c2.4,7.7,6.9,0.4,10.4,0
				c-1.1,5.8-0.1,11,2.4,15.7c-4.4,3.9-11.5-12-13.2,1.5c-1.6,13.4-3.5,27-4.6,40.6c-0.8,9.9,1.3,11.1,8,6.5
				c8.8-6.1,17.5-13,27.5-15.1c-7,14-21.2,11.5-29.9,24.5c12.9,2.6,25.2,0,36.5,3c11.3,2.9,21.1,4,31.2-2.9
				c6.9,14.4,9.3,30.5,9.4,47.5c0,12.3,0.6,24.6,0.5,36.9c0,9.4,0.1,18.4,6.1,24.7c-2.9,5-9.4,4.5-8.3,13.4
				c1.3,10.1,1.9,20.1-1.1,30.1c-1.2,4,0.1,8,3,10.6c2.7,2.4,3.1,8,1.9,9.5c-7.7,9.6-0.8,22.2-3.3,30.7c-3.2,11.5,1.3,11,5.4,14.6
				c6.2,5.5,7.7,11.6-0.8,15.9c-5.9,3-8.7,7.6-5.1,16.2c1.4,3.3,0.2,6-0.9,8.5c-2.8,6.3-2.4,11.5,1.8,16.6
				c4.6,5.6,6.6,11.3,0.6,18.6c-0.6,0.8,4.7,11.3,2.6,18.6c-0.8,3-3,2.3-4.7,2.6c-2.2,0.3-3.9,4.1-2.9,4.7c5.1,2.9,6.8,8.5,9,14.7
				c0.9,2.4,6.3,2,6.2,8.2c-0.1,7-4.5,7.6-7.8,8.9c-12.7,5-23.5,16.4-36.8,19.2c9.4,3.3,16.7,11.4,24.5,17.1
				c5.2,3.8,13.2-0.2,15.9,10.4c-1.7,1.2-3.3,2.3-5,3.5c-17.6-1.3-32.4,11.4-49.6,17.8c8.2,8.4,17.6,8.7,24,15.8
				c7.8,8.6,14.9,3.2,21.4,0.3c5.8-2.5,9.7-10.7,16.7-12.8c5.2-1.5,1.9-6.6-1.3-9c3.8-0.2,8,0.6,8.7-7.1c4.5-0.9,8.9,0,12.7,3.7
				c10.1,9.9,16.4,1.6,22.5-8.5c2.3-3.7,1.7-6.8-0.9-8.9c-8.9-7.5-16.2-23.2-29.1-11.1c-1.7,1.6-5.4,1.8-5.8-3.8
				c-0.2-4,2-4.8,4.3-5.4c2.7-0.7,6.6,0,6.7-5.5c0-2.5-1.9-5.4-3.4-7.4c-3.9-5-6.2,0.9-9.9,4.5c1.9-39.5-2.4-77.4-2.3-115.5
				c0-14.8-0.5-29.7-1-44.5c-1.4-48.1-4.7-96.3-3.4-144.2c0.4-15.4-8.7-35.3,4.9-48.9c0.3-0.3-0.1-3.6-0.7-4.1
				c-11.7-8.8-3.7-25.3-7.6-37.3c-1.5-4.6,1.1-8.7,4.7-10.5c1.6,0.9,3.4,3,4.8,2.6c3.5-0.9,16.3-26.2,15.6-30.7
				c-1-6.9-4.2-10.2-9.4-8.5c-3.6,1.2-6.8,3.4-9.3,7.4c0-2.4,0-4.9,0-7.3c2.5-4.6,11.3-4.8,8-11.7c-3-6.2-5.1-14.4-10-18.9
				c-1.2-4.7-8.4-0.7-6.9-10c1.7-10.4-0.5-24.7,5.2-30.7c6-6.4,10.6-12.4,12.8-22.1c1.1-4.9,6.5-9,3.5-14.1
				c-3.3-5.6-8.5-3.9-13.2-1.5c-6.6,3.3-8.7-5.5-7.3-9.7c3.7-11-1.6-19.7-1.8-29.2c-0.2-14.8-10.5-32.7,4.9-44.3
				c-0.8-0.5-1.5-1.3-2.4-1.5c-3.8-0.9-4.3-5-4.4-9.2c-0.2-9.7-0.3-19.4-0.5-29.1c-0.1-4.7,1-8,4.9-8.1c2.2,4,3.8,12.5,8.3,7.5
				c3.7-4-0.8-8.4-3.2-12.2c-2.1-3.3-1.8-8.8-5-11.3c-9.6-13.7-9.5-30.4-8.1-47.8c0.6-7.6,2.3-11.1,8.1-10.3c9.8,1.2,9-10.8,9.6-18
				c1-11.6-6.6-4.1-9.7-5.6c-1.6-7.7,5.8-9.4,5.2-9.7c-7.3-4.2-1.6-20.1-10.2-22c-4.3-0.9-3.7-5.1-4.4-8.9
				c-3.3-17.9,2-31.9,10.4-44.3c2.9-4.2,6.3-9.4,2.9-13.7c-3.5-4.3-5.7,4.3-8.9,5.9c-0.5,0.2-1.2-0.4-1.8-0.7
				c-0.9-2.7,0.1-4.4,1.1-6.7c3.9-8.5,5.9-19.1-0.4-25.3c-5-4.9-5.8-9.6-5.5-16.3c0.5-11.7,0.1-23.2,1.5-35
				c1.6-13.7,0.3-28.1,0.2-42.1c-5.5,9,1.3,24.6-12.6,25.6c-3.6,0.2-8.9-2.3-10.7,4.4c-2,7.4,4.9,7.6,6.4,12.5
				c0.2,0.7-0.3,1.8-0.4,2.8c-5.9,0.5-11.8-8.8-17.7,0.1c-3.8-0.4-8.5-3.4-8.7,6.1c-0.2,10.5-0.6,21.1-1,34.7
				c6.4-7.3,10.9-13,15.9-17.7c3.3-3.1,4.9-5.9,2.6-11c5.3,0.6,10.3,0.5,11.1-9.3c8,5.3,3,18.7,8.3,25.6c1.5,2,4.1,4.1,1.5,7.2
				c-2,2.2-4.4,1-5.6-2.1c-6.3-16.7-13.4-8.4-20.3-1.4c-2.9,3-6.3,5.7-8.3,9.7c-1.9,3.9-9,5.2-4.8,12.7c3.2,5.6,8.1,6.8,12.8,2.5
				c5.2-4.7,10.2-9.8,15.2-14.8c5.1-5.2,3,1.4,3.4,4C245,550,235,557.6,234.3,565c-0.8,9.4,10.3,14.3,16.7,20.4
				c0.6,0.6,1.3,0.9,1.9,1.4c6,10.2-3.2,20.7,0.5,30.9c-8.2-5.6-15-3.8-21.1,6.2c-3,4.9-7.4,7.9-11,12.1c-3.5,4.1-8.8,7.6-4.4,15.8
				c4.2,7.9,9.3,11.1,15.5,3.9c5.6-6.5,10.6-14.4,17-19c7.3-5.2,6.8-11.2,3.9-19.2c12.1,5.3,12.1,5.7,4.2,16.3
				c-1.8,2.4-3,5.5-4.5,8.2c-9.2,5-15,16.4-23.2,24c9,9.1,15.9,21.2,26.4,26.7c3,4.4,1.8,9.9,2,15c-4.1,0.2-10.1-3.3-11.1,4.9
				c-0.9,7.2,3.8,11.3,8.9,12.9c12.3,11.8,12.3,11.8-0.8,22c-2.2,1.7-4.4,3.8-6.8,4.8c-6.3,2.7-11.6,9.3-19.5,0.1
				c-5.3-6.2-15-5.8-21.2,3.6c-5.3,8.1,6.5,6.4,4.1,13.1c-2.4,0.6-6.4-3.2-6.6,3.8c-0.2,4.8-2.3,10.3,1,14.2c2.1,2.5,3.9-1,5.6-2.7
				c-1.6,8.3-2.7,16.6,5.5,19.2c7.1,2.3,16.6,5.9,20.4-2.4c3.1-6.8-6.5-10-10.7-14.6c-0.3-0.3-0.7-0.4-1-0.7c-1.3-1.5-3.7-3-2.2-5.8
				c1.9-3.8,3.7,0.8,5.7,0.9c9.7,8.4,17.1,24,31,18.8c0.1,5.7,0.2,11.3,0.3,17c-8.9-3.9-12.3,4.3-15.2,13.5
				c-0.9,0.9-1.8,1.7-2.7,2.6c-4.6-7.6-8.7,4.2-13.2,0.6c0.3-1.8,0.7-3.5,1-5.3c6-0.7-0.3-5.1,1.1-7.2c2.1,0.9,4.1,2.3,6.2,2.5
				c5,0.4,14.4-10.4,12.2-12.9c-3.4-3.8-8.3-4.3-12.3-3c-4.3,1.4-11.8-5.3-12.5,7.6c-2.6-1.5-5.4-4.6-7.8-4.1
				c-6.5,1.4-7.2-17.3-13.1-8.7c-3.2,4.7-1,16.5-1.3,25c-0.2,2-0.7,3.9-0.7,5.9c-0.1,8-4.8,18.4-0.7,23.1c4.5,5.2,9.2-6.8,14.8-7.6
				c-1.6,9.9,3.6,7.9,7.3,7.7c12.4-0.6,24.7-1.6,37-2.5c0.1,4.9,2.4,10.4-2.3,13.7c-3.9-5-6.3-0.9-8.6,3c-5.3,1.8-9.4,4.9-8.2,14
				c-3.7-2-8.2-2.7-8.5,4.1c-0.3,6.2,4.4,3,6.8,3.4c5.4,0.9,10.9,1.8,16.2-1c3.4-0.1,6.2-0.3,7.2,6.4c1.4,10.1-4.1,13.5-9.6,20.1
				c8.9-2,10.7,3.5,9.6,12.2c-6.5-7.9-11.6,7.3-17.9,2.1c0-4.7,4-5,5.8-10.2c-9.4,0.7-17.8,1.3-28.5,2.1
				C229.6,934.2,236,936.5,243.4,935.1z M1517.7,1146.5c0.5,0.3,0.9,0.7,1.4,1c-0.5-0.3-1-0.6-1.5-0.9c-0.3-8.2,0-16.5-1.2-24.5
				c-0.8-5.5-2.3-13.2-8.1-11.7c-6.3,1.7-2.8,6.9-1.2,11.3c0.3,0.8-0.7,2.4-0.9,3.7C1504,1140.5,1507.4,1146.6,1517.7,1146.5z
				 M2571.6,822.1c-5.3-0.5-8.3,2.5-7.1,10.4c0.7,4.8-2.3,12.7,3.6,13.6c4.7,0.8,11,4.1,14.1-3.2c3-7-4.2-7.7-5.9-12
				c-0.3-0.8-0.2-1.9-0.3-2.9c11.2,9.9,20.1,6.2,27.7-8.6c-10.5-7.8-21.8-1.3-32.4-5.4C2568.1,812.8,2571.6,819.2,2571.6,822.1z
				 M2595.2,677.2c-1.9-6.3,5.8-7.9,3.4-15.2c-2.6,0-5.5,0.2-8.4,0c-3.6-0.3-7,1.5-8,5.9c-0.8,3.2-0.2,8.3,1.2,10.9
				c2.4,4.3,5.1-1.1,7.7-1.6c1.4-0.3,2.8-0.1,4.2-0.2c-2.1,7.9-13.9,8.2-8.8,21.8c-5.4-0.5-10.5,1-15.6,3.2
				c-0.8-3.1,1.5-4.1,2.2-6.1c3-8.7-4.9-7.1-6.3-12.1c-0.6-2-2.8-2.9-4.3,0.2c-2.8,5.9,0.9,18.1,6.5,20.9c1.7,4.7-8.1,4.7-2.5,10.1
				c9.3,9,17.1,26.2,31.4,14.3c-0.5,5.9-10.9,8.1-2.7,17.1c-10.1-1.9-11.9-1-19.6,9.9c3.4,0.2,6.8,0.1,10,0.8
				c11,2.3,17.8-5.6,18.5-21.3c0.3-7.5-3-6.9-6.4-6.3c3.9-8.7,2.3-15-4.3-19.2c-2.5-1.6-5.7-3.1-4.6-8.6c3.8,0.2,6.9,2.5,9.7,6.1
				c1.4,1.8,4.2,3.4,4.7,1.2c2-8.3,1-17,0.2-25.4C2602.7,677.6,2599.6,675,2595.2,677.2z M802.6,1518.9c5.4-3.1,6.2,10.2,10,6.7
				c4-3.6,6.6-10.6,6.9-18c0.1-3.3,1.2-7.6-1.9-9.3c-4.6-2.7-8.2,2-12.2,4c-3.9,1.9-2.4,8-5,10.6c-3.3,1.4-4.5-1.8-5.8-5.1
				c-1.6-3.9-4.9-5.1-6.1-1.6c-2.1,6.1-4.5,13.7-0.3,19.9c3.4,5.1,5.9,13.9,13.1,9.7C809.1,1531.3,800.4,1524.3,802.6,1518.9z
				 M521.6,1066.9c0.1-1,0.2-2,0.4-3c2-8.4-2.6-17.2,0.9-25.6c0.4-0.9-16.9-2.5-17.2-0.5c-0.9,6.2-2.2,12.9,1.4,18.4
				c1.4,2.1,3.6,3.2,5.5,4.7c-2,4.2-9.4,2.8-6.6,12.2c-0.6,12-3.2,23.6-1.5,36c0.9,6.6,3.2,6.3,6.6,7.1c4.4,1.1,7.9,0.2,9.1-5.9
				c0.9-4.8,4.7-13.4,2.1-13.8c-11-2-4.7-14.4-7.7-20.8C516.4,1071.9,520.5,1071.8,521.6,1066.9z M279.8,620.7
				c-3.6,0.3-9-1.6-4.9,8.1c2.4,5.6,5.5,10.4,7.1,16.9c1,4.3,0.8,11.3,5.8,11.4c4.3,0.2,5.9-5.6,7.9-9.6c4.3-8.4-4.7-8.9-5-14.8
				c9.5-5.4,4.1-15.2,2.8-22.9c-1.1-6.5-1.9-16-7.5-17.3c-6.8-1.6-5.1,10.5-9.1,14.9C271.6,613.6,282.1,614.9,279.8,620.7z
				 M289.5,842.7c-4.4-1.9-9.1-5.6-10.8,4.8c-0.9,5.1,0.9,9.6,3.8,8.4c4.5-1.8,12.9,8.2,13.5-7.3c1.3,0.4,3,0.3,4,1.4
				c3.7,3.9,8.4,8.3,11.1,1.4c2.3-6.1,0.4-13.9-5.2-17.6c-1.6-1.1-3.7-1.2-5-2.7c-4.1-5.1-7.5-11.1-13.7-3.5
				C280.7,835.4,291.9,836.8,289.5,842.7z M803.6,1161.6c-12.8-10.4-17.1-8.3-19.4,9.2c-0.3,5.5-2.3,13.6-0.6,15.8
				c4.1,5,9.7,0.8,14.5-1.4c1.7-0.8,3.2-2.5,4.9-3.2c1.4-0.5,2.9-0.1,4.4-0.2c1.9-0.1,3.7-0.2,2.6-4.3
				C808.4,1171.8,811.8,1162.2,803.6,1161.6z M1858.5,979.3c1,4.6,2,9.3,3,14.3c-3.3-1-7.1-3-9.8,1.9c-0.6,1.1-0.3,4.7,0.4,5.3
				c4.5,3.9,9.7,5.1,14.7,2.8c5.4-2.6,7.5-8.4,6.5-16.7c-0.8-6.9,1.8-16.2-7-16.4c-6.7-2.4-13.4-1.1-21.8-0.1
				C1849.2,977.7,1851.4,985.8,1858.5,979.3z M1614.2,1371.5c-0.8,6.7,4.7,11,3.5,18.3c-0.6,3.8-1.2,10.7,3.1,11.3
				c5.6,0.9,11.6,2.9,16.9-2.3c1.1-1.1,2.5-3.3,0.9-4.9c-4.2-4.2-1.2-11.9-4.5-16.3c5.8-0.7,6.1-6.4,5.2-12.2
				c-1.1-6.7-0.4-13.2-0.2-19.8c0.6-15.4,0.9-30.8,1.1-46.1c0.1-8.3-0.5-15.1-5.5-22.7c-4.2-6.5-2-9.3-8-11.8
				c-1.3-0.5-2.7-1.5-2.2-4.8c5.7-0.8,12.4,2.6,15.1-10.4c1.4-6.8,14.5-1.6,15.1,6.9c0.5,6.1,2.3,10.3,5.2,14.1
				c-2.4,4.1-8.6-1.1-8.3,9.1c0.7,26.6,0.4,53.3,0.5,79.9c1.4-3.2,3.4-7.2,3.7-9.8c1.7-13,13-26.2,0.3-39.8
				c-1.5-1.6-0.9-7.9-0.4-11.8c1-7,4-0.9,5.8-0.7c6,0.8,9.4-1.9,11.9-9.8c2.1-6.5-0.6-15,0.6-17.7c6.3-14.8,7.4-30.4,7.5-46.7
				c0-4.1,0.1-8.4-3.7-9.8c0-3.1,0-6.1,0-9.2c2.6-21,1.3-22.6-12.8-16c-1.3,0.6-2.5,1.9-3.8,2.8c-1.3,0.3-2.5,0.6-3.8,0.9
				c-13,2.9-25.9,6.7-39,8.5c-8.6,1.2-10.8,5.9-8.2,16.6c-0.2,3-0.4,6.1-0.6,9.1c-2.7,21.6-7.5,42.5-10.2,63.9
				c-2.9,22.3-7.5,44.8-4.3,68.1c0.8,5.6,0.8,12.6,5.8,13.1c6.4,0.6,4.2-7.5,4.9-12.5c3.2-23,3.2-46.2,2.7-69.5
				c-0.1-2.8-0.9-7.1,1.9-7.7c4-0.8,3.3,4.3,3.4,7.5c0.5,26.1,0.8,52.3,1.1,78.4C1614.4,1369,1614.3,1370.2,1614.2,1371.5z
				 M1289.7,1107.3c1.5-2,2.4-4,0.4-6.4c4.5,1.1,8.4-1.8,12.5-3.8c-3.1,5.4,0.1,8,2.5,10.9c-7.2,5.1-3.8,14-3.6,21.4
				c0.2,9.7,3.8,17.7,7.4,25.7c0.5,1-3.5,3.2-0.7,5.8c2.1,2,4.4,0.6,6-1.5c5.9-7.6,8.2-16.2,3.9-27c-1.4-3.5-8.1-8.7,1.1-11.2
				c2.3-0.6,2.2-11.8,0.3-12.7c-5.9-2.8-7.1-4.2-4.3-9.5c3.7-7.2,1.7-11.4-1.2-17.3c-1.6-3.4-4.3-9.2-1.4-12.7
				c4.5-5.4,0.6-8.4-1.1-11.1c-2.5-3.9-4.5-5.9-0.1-10.1c4.5-4.3-0.6-8.8-1.3-13c-0.7-4.5-4.2-5.3-7.3-4.9c-3.5,0.5-4.4,4.8-3.6,7.9
				c6,22.9-3.3,21-14,17.2c-0.7-0.2-1.5,0-2.2,0c1.9-4.1,5.4-4.6,8.1-6.9c2.4-2,5.8-24.4,3.9-25.5c-4.1-2.4-8.9-0.8-12.2,1.8
				c-2.9,2.3-2.9,10.1,0,12.9c7.2,7-0.4,4.2-1.6,6.1c-2.9,4.7-3,9.1,2,11.5c-4.3,5.1-5.1,11.4-3.3,18.7c-0.8,2.4-1,4.7,0.4,6.8
				c-5.7,6.6-15,11.6-5.6,25.1c0.7,1-1.1,5.5-2,8.2c-0.4,1.1-1.4,1.9-2.1,2.8c1.4-0.3,2.8-0.5,4.2-0.8c0.1,1.2,0.3,2.5,0.4,3.7
				c0.5,1.9,0.9,3.8,1.4,5.7c-2.3,4.8,5.8,4.8,2.4,10.1c5.1,2.8,9.4,11.2,15.9,4.7c1.8-1.8,3.4-30,1.8-31.4c-2.1-1.8-4.2-1.1-5.6,2
				C1290.6,1109.4,1290.1,1108.3,1289.7,1107.3z M2383.5,1424c-4.1-1.2-8.5-0.6-11.8-5.2c-2.7,8.7,0.2,17-2.9,24.9
				c-1.2,3.1,0,10.9,5.8,5.9c2.7-2.4,5.9-3.7,8.7-6c10.2-8.4,10.1-11.6,0.1-19.7c1.2-1.1,2.8-1.8,3.4-3.3c1.1-2.8,4.2-4.5,2.6-8.9
				c-0.9-2.5-1.9-2.7-3.2-1c-1.8,2.4-5.1,4.2-2.3,9.1C2384.3,1420.5,2383.7,1422.5,2383.5,1424z M2304.6,1048
				c3.7-1.4,6.2,3.9,8.8,3.9c6.3,0,14.8,8.2,18.4-1.6c1.7-4.7-7.2-8.9-12.4-10.8c-2.7-1-5.3-2.2-1.7-6.4c1.1-1.3,4.3,0.2,3.1-4
				c-0.7-2.5-2.9-2.6-4.8-2.6c-8.9,0.3-13.4,8.9-11.2,21.6c-4.6-3.2-10.1-2.7-13.8-10.1c-1.6-3.2-8.3-10.4-11.7-2.2
				c-1.9,4.7-3.8,15.6,2.5,18.2c7.8,3.2,16.4,7,24.3-0.7C2306.3,1053.1,2305.1,1049.8,2304.6,1048z M1359,1306.9
				c5.8-1.7,3.1,7.9,7.4,10.2c-0.1-13.9,0-27.1-0.2-40.3c-0.1-9.7-0.6-19.3-0.8-29c-0.1-5.6-1.1-11.2-5.7-10.8
				c-5.2,0.4-2.8,6.7-2.7,10.8c0.2,8.1-0.6,16.6,2,24c4.3,11.9,3.4,23.5,0.1,35.3c-3.1,12.9-0.4,25.9,0,38.9
				C1359,1333,1359,1320,1359,1306.9z M1362.5,980.8c-7.3-2.9-14.5-9.3-22.1-0.4c-1.8,2.1-6.8-0.7-6.6,4.7c0.1,3,3.3,6.6,5.7,8.5
				c2.7,2.2,5.6,3.3,9.2,2.8c5.8-0.7,11.7-4.2,17.6-1.1c1.8-6.6-5.3-8.4-3.9-14.8c2.3-2,6-6.4,7-1.6c3.1,14.7,13.3,11.4,19.9,14.8
				c5.4,2.8,2.4-10.8,9.3-16.7c-8.1,2.1-12.8-4.5-17.1,0.6c-5.1,6.1-7.6-5.2-12.3-2.6C1366.5,976.5,1363.4,975.6,1362.5,980.8z
				 M2461.5,1189.5c1.2,10.6,6.2,10.3,11.9,8.3c-0.6,3.7-1,7.2,2.7,7.8c2.4,0.4,5.1-0.7,5.2-3.9c0.4-9,1.4-18.5-0.2-27
				c-1.6-8.5-6.7,1.1-9.5-0.9c-3.7-2.7-7.1-7.1-9.5,0.5C2460.9,1178.7,2457.2,1184.1,2461.5,1189.5z M2592.3,1090.3
				c-1.9-12.5-0.3-29.4-16-24.3c-2.2,0.7-5.5-1.6-5.9,4.1c-0.2,3.9,1.9,4.6,3.9,6.2c5,3.9,10.2,7.6,13.9,14.1
				c-2-0.4-4.1-0.8-6.1-1.2c-2.4-5.8-6.2-7.3-11-8.3c1.2,8.2,5.5,9,9,11.3c0.8,2.8,5.7,7.3-1,7.1c-4.9-0.1-7.9,4.4-11.1,7.4
				c-3.2,3.1-0.9,5.6,2.5,5.1l-0.1-0.2c-1.9,4.5-7.2,7.5-4.4,14.1c2.4,5.4,6.8,2.3,10.1,1.9c3.8-0.5,7.4-3.1,6.1,6
				c-0.1,0.7,2.1,2.6,3.3,3c1.6,0.5,2.8-1,3-3.3c0.5-5.9,2.5-13,0.8-17.5c-4.2-11.8,1.7-11.6,7.5-12.6c-2.4-6.3-9.6-4.7-8.8-13.1
				c1.5,0,3-0.1,4.5-0.1c3.5,1,8.3,11.1,10-2.7c0.2-1.8,2.7-3.9,4.4-1.9c0.7,0.9,0.9,4.1,0.3,5.6c-2,5.3-5.3,9.8-3.1,17.1
				c4-2.3,5-6.6,6.6-11.7c3.8-11.6-4.6-17-5.2-26c-0.2-3.7,0-7.5-3.8-7.3c-2.9,0.1-6.3-0.9-8.3,2.4c-2.1,3.4,1,6.3,1.4,9.4
				C2595.5,1080.1,2602.8,1088.6,2592.3,1090.3z M2410.9,1430.1c0.7,7.9,3.4,7,5.7,1.9c3.3-7.1,2.1-15.4,0-22.2
				c-2.3-7.3-8-10.5-13.6-9.9c-3.6,0.4-10.3,0.7-9.6,6.7c0.8,6.7-2.7,17.1,5.3,20.6C2402.2,1432.7,2406.2,1434.1,2410.9,1430.1z
				 M324.2,1265c-3.8,0-7.5,0-11.3-0.1c0.9-5.2,2.6-10.4-0.1-15.5c7.5,2.9,8.8-7.8,13.3-11.7c2.8-2.4,2.9-6,2.1-9.3
				c-1.4-6.2-4.2-0.8-6.2-0.7c-7,0.2-12.7,2.4-9,15.3c0.6,2,2.2,4.5-0.1,6.6c-6.4-11.8-13.5-12.5-22.3-4.3
				c-7.8,7.1-5.1,17.6-3.1,24.3c2.5,8.3,9.5,12,16.8,7.5c0.2,4.3,0.6,9,4.5,8.8c6.6-0.4,11.8,2.4,16.2,9.5c1,1.7,4.7,2.1,6.2,0.8
				c2.4-2.1,0.7-6.2,0.7-9.6c0.1-9.4-9.2-11.9-7.8-21.8c15.6,0.5,1.6-14.2,6.3-19.3C320.6,1248.1,321.8,1256.3,324.2,1265z
				 M1731.5,948.3l-0.1-0.2c7.5,8.3,10.1-1.1,10.6-7.7c0.6-8.4,3.7-20.5-6.5-23.2c-3.9-1-10-1.8-10.1,6.9
				C1725.3,932.8,1720.9,944.7,1731.5,948.3z M2574.2,907.4c-6.1-1.8-13.7-6.8-17.3,3c-3,8.2,5.5,6.6,8.1,10.6
				c13,7.3,26.1-0.5,39.2,1.2C2595.6,910.5,2586.9,900.4,2574.2,907.4z M781.7,1247.7c-6.3,0.2-4.1,8.3-2.4,8.7
				c7.7,1.8,16.1,4,23.4,1.4c4.9-1.8,0.8-21.7-5.1-24.3c-9.5-4.2-14,2.2-16,14.3L781.7,1247.7z M2607.3,1564.9
				c-5.2,2.7-13.5-8.1-14.7-0.2c-1.7,11.6-5.6,5.5-9,6c-8.4,1.2-17.2-1.9-27.8,6.4c16.5,1.1,30.3,2.2,44,2.9
				c8.1,0.5,8.8-6.5,7.3-15.3c0.6-0.1,1.3-0.1,1.9-0.2c-0.1-0.4-0.2-0.9-0.3-1.3C2608.2,1563.8,2607.8,1564.3,2607.3,1564.9z
				 M2819.1,1418c19.2,2.6,20.1,1.3,16.3-22.7c-1.7,23.2-1.7,23.2-15.8,20.3c0-4.1-0.3-8.2,0-12.3c1.3-16.1-7.2-15.6-14.4-16
				c-10-0.6-5.2,10.4-5.9,16.7c-0.3,2.8,0.8,6,1.2,8.9c-18,10-23.3,6-24.4-18.9c-0.4-8.6-0.3-17.3-0.2-25.9
				c0.1-19.9-3.4-25.5-16.7-26.5c-2.2-0.2-4.6,0.5-6.6-0.4c-11.8-5.1-8.5,6.3-9.1,14.9c-0.8,11.7,1.7,16.4,10.4,14.2
				c5.3-1.3,10.8-3,16.1,2.5c-8.2,9-21.3-9.8-25,8.2c-1.8,8.8-5.2,24.5,7.9,31.7c-6.3,0.6-8.8,1.8-12.2-6.1
				c-4.2-9.9,2.7-15.9,0.5-24.1c-1.7-6.1-3.4-10.5-8.4-8.9c-4,1.3-7.4,8.5-5.8,10.8c8.4,12.1-2.5,18.8-2.7,28.3
				c-0.8-9.6-0.7-19.1-0.9-28.5c-0.1-4.5-0.3-10.6-4.7-10.3c-7.2,0.5-14.8,0.9-21.1,4.8c-2.3,1.4-1.6,12.8-2,19.6
				c-0.2,3-0.1,6.1,0,9.2c0.1,2.6,0.2,4.8-2,6.2c-6.3,4.1-22.1-3.2-24.5-12.2c-2.7-10.3-5-20.7-6-31.9c-2.2-25.2-5.7-50.2-8.9-75.2
				c-0.1-0.8-1.6-1.3-2.6-2c-1.2,23.5,2.5,50.1,9.5,67c1.3,3.2,0.6,5.2-1.5,5.5c-3.8,0.5-3.3,2.7-2.9,7c1.1,13.2,1.2,26.6,2.4,39.8
				c0.8,9.2-0.5,20.5,9.3,26.3c-1.8-15.1,3.7-16.2,11.9-15.9c23.1,0.8,46.3,0.1,69.5,0c0-0.7,0-1.5,0-2.2
				c-26.6,0.1-53.2,0.2-79.9,0.3c29.7-2.7,59.4-4.6,89-2.2c16.6,1.3,32.8,1.8,49.2-0.8C2811.6,1420,2815.4,1418.4,2819.1,1418z
				 M2602.5,1451.4c4.8,3,9.9,1.3,15.2,1.5c-3.4-3.7-8.5,1.5-10.9-5c4.8,0.5,6.6-2.4,6-9.2c-0.6-6.2-3.1-7.1-6.9-6.8
				c-4,0.3-8.6,1.5-12-0.6c-10.1-6.5-9.2,6-10.3,11.9c-2,11,5.1,8.7,9.2,9.3C2595.9,1453.1,2599.3,1451.8,2602.5,1451.4z
				 M590.7,989.5c-4.4,1.9-4.3-10.8-10-5.7c-11.9,10.7-19.8,11-29.1,1.7c-1.5-1.5-2.7-1.4-3.9,0.2c-2.7,3.7-4.9-1.4-7.4-1.2
				c-0.8,9.8-2.7,18,9,16.8c10.9-1.1,21.1-8.8,32.2-6.1c0.3,0.4,0.6,0.9,0.8,1.3c2.4,10.3,6.9,2.1,9.5,1.1
				C594.4,996.6,587.7,993.4,590.7,989.5c4-2.1,5.9,2.4,8.1,5.5c2.3,3.3,6.3,8.4,7.9,4.9c3-6.6,11.5-6.6,10.8-14.1
				c-0.7-8-5.8,3.6-8.8-0.7c-0.5-0.7-1.1-1.4-1.7-1.9C600,977.4,594.9,981.3,590.7,989.5z M2577.4,1256.8c3.3,8.8-5.7,5.6-5.6,12.4
				c4.3,1.8,9.8,7.2,12.9-1.1c2.6-7-3.6-8.9-7.2-11.4c-2.5-9.2-3-16.4,7.6-12.9c-2.7-3.9-7-3.4-7.6-8.9l-0.1,0.2
				c3.3,0.2,7,1.1,7.5-5.6c0.3-4.1-1-7.7-4.1-7.3c-5,0.7-11.6-4.9-14.6,4.6c-3.1,9.6,0.6,19.9,0,29.9
				C2570,1256.8,2573.7,1256.8,2577.4,1256.8z M2268.2,1362.6c-6.8,4.3-3.3,13.5-6.1,19.8c5.4,3.1,9.7-5.4,14.2,0.4
				c-1.6,4-4.9,4.5-8,6.9c4.6-0.2,7.1,4.7,10.7,5.6c2.9,0.7,7.1,4.2,8.5-2.2c0.9-4,2.4-7.7-0.1-13.6c-2.8-6.7-8.4-7.4-10.4-13.9
				c0.9-0.9,1.8-2.3,2.7-2.5c5.1-1,9.9-6.6,7.1-11.7c-5.8-10.2-10.2,8.5-17.8,4.1C2264.5,1353,2269.8,1360.3,2268.2,1362.6z
				 M2579.8,1312.1c12.7-2.7,4.4-15.7,5.8-23.8c1.6-9.4-5.5-9.3-8.8-11.7c-3.6-2.6-7.2,1.8-9.2,7.8c-2.6,7.8-0.4,14.6,1.1,21.6
				c-4.6,3.4,5.1,6.3,0,10.6c-4.1,3.5-1.7,11.7,0.1,12.4c5.9,2.3,9.5-6.2,14.1-9.7C2586.7,1316.5,2577.8,1317.5,2579.8,1312.1z
				 M1350.9,886.3c0.8,0.1,1.5,0.2,2.3,0.4c5.5,3.6,11.7,4.5,17.1,1.7c9.7-5.1,19.8-0.2,29.4-3.6c1.3,4.9,3.4,3.2,5.7,1.5
				c-1.8-2.3-3.8-1.5-5.8-1.3c4.7-9.6,12.7-4.2,19.6-9.7c-2.6,7.4-5.7,12.2,2.8,12.8c11,0.8,22.2-5.9,33,1.6
				c0.8,0.5,2.7-1.3,3.6-2.6c1.3-1.8,0.2-3.3-0.8-4.9c-6.9-10.9-15-15.1-24.9-9.1c-3.3,2-5.8,2.3-9.1-0.5c-11-9.5-23-11.3-35.6-7.4
				c-5-2.1-10.1-1.4-15.2-0.9c-3.6,1.2-7.5-1.9-11,1.5c-1.7-2.2-3.3-2-4.9,0.1c-4.5-9.6-11.2-3-16.8-4.3c-6.9-1.6-8.7,3.9-8.4,12.5
				C1332.5,884,1343.2,891.1,1350.9,886.3z M827.5,415.2c-3.2,9-8.6,14.7-14.8,19.1c-3.1,2.2-6.7,4-3.4,9.7c2.3,3.9,5.1,7.3,9.2,2.7
				c5-5.5,10.4-10.2,15.3-16c2.6-3.1,5.7-6.5,3.5-12.9c-2.6-7.7-6.3-4.6-10-2.5c-0.5-2.3-0.4-5.4-2.7-5.7c-12.7-1.7-25,2.7-37.5,3.9
				c-1.7,0.2,0.3,3.2,0.6,4.9c-7.9-4.1-14.9-13.4-24.2-9.1c-6.2,2.9-15,3.5-11.2,18.5c-8.3-5.7-11.3,8.2-17.8,9.3
				c0.3-1.9,0.1-4.3,0.9-5.5c8.4-12.9,8.8-14.3-3.1-19c-13.9-5.5-28.2-1.2-42.2,1.7c-1.8,0.4-6.1,3.8-3.3,7.4
				c5.8,7.7,2.2,15.3,0.9,22.6c-1.7,9.3,0.7,11.7,7,11.5c12.6-0.3,25.4,0.9,34.4-15.2c1.2-2.2,2.9-4.8,5.6-3.7
				c-1.1,6.7-5.8,9.8-9,15.9c14.3-0.1,27.5-0.1,41.7-0.1c-3.3-6.6-7.6-9.5-8.6-15.8c5.2,1.3,8.4,6.7,12.2,11.2
				c9.4,11.1,25.7,4.7,29.8-11.3C812.7,436.5,817.4,419.4,827.5,415.2z M1130.5,1085.9c0.5-2.9,1.4-5.9,1.4-8.8
				c-0.2-6.6-1.5-12.4-7.4-12.4c-7.1,0-4.4,7.3-4.5,12.2c0,5.1,0.3,10.2,0.5,15.4c-0.1,7.6-0.2,15.2-0.4,22.8
				c-0.1,3.9,0.3,7.6,3.6,7.8c8,0.5,15.9-0.1,23.8,2.6c2.7,1,4.5-2.7,4.7-6.8c0.1-3,0.7-6.6-2.4-7.1c-1.8-0.3-4.6,0.4-4.6,2.9
				c0.4,13.6-6.6,5-9.5,5.4c-4.9,0.8-3.3-6.2-3.7-10.3C1131.4,1101.7,1133.6,1093.5,1130.5,1085.9z M1355.2,1375.4
				c-10.5,0.2-11.1,2.1-6.6,18.5c-5.4,6.4-13.5-1.3-18.7,5.5c26.1-0.1,52.1-0.2,78.1-0.3c2.7,0,6.5,1.8,7.1-4.2
				c0.4-4.2-1.1-8.4-4.5-9.3c-3.4-0.9-7.4,0.2-7.1,6.1c0.4,6.6-2.5,5-4.7,5c-8.8,0-17.7-0.2-26.5-0.5c-6-0.2-10.4-5.2-9.9-13
				C1363.2,1373,1359.2,1375,1355.2,1375.4z M1240.9,1039.7c-2.4-13.4-11-1.3-15.4-8.2c6.2-1.7,12.4-3.4,19.6-5.5
				c-4.9-7.4-7.6-15.1-15.7-7.6c-7.8,7.2-12.1,13.9-4.8,25.6c-3,3.9-7.6-6.5-9,2.7c-0.6,4.2-1.8,11,1.2,12.7c4,2.2,7.2-2.1,8.8-8.1
				c1.9-7.2,5.7-2.9,8.9-2.6c-0.2,5.2,1.7,8.1,5,10c2.6,1.5,5.5,3.2,3.8,8.6c-1.7-0.8-3.5-1.2-5-2.3c-11.9-8.1-20-0.7-22.6,17.5
				c-1.9,13.1,4.6,19.1,8,28c-2.4,3.9-7-6.4-7.6,1.1c-0.3,4.3-1,12.5,4.4,14.8c6.5,2.7,7-3.8,7.4-10c18.9-5.6,26.1-21.9,15.3-49.3
				c11.9-0.5,5.3-13.1,6.4-20.1C1251.5,1035.1,1247.8,1033.1,1240.9,1039.7z M2572.7,1151.8c3.8,1.9,8.1,2.8,11.2,6
				c8.6,8.8,16.1,2.6,23.7-2.1c4.9-3,5.5-9.5,4-15.8c-1-4,0.3-11.1-3.8-11.3c-4.2-0.3-7.6,5.7-12.3,6.2c-1,0.1-0.6,3,0.6,4.9
				c4.4,6.5,4.3,6.7-1.1,14.8c-0.3-4.8,0.1-8.4-4.8-10.4c-8-3.4-12.6,1.1-17.5,7.9c-7.2,4-10.8-6.7-16.7-7.8
				c-2.9-2.2-5.8-4.3-8.6-6.6c-3.1-2.4-5.2-1.2-5,3.7c0.7,15.4-2.6,30.2-3,45.4c-0.1,2.9-0.3,6.4,2.5,7.5c2.9,1.1,3.2-2.3,4.5-4.8
				c5.1-9.8,11.6-13.2,19.4-6c2.7,2.5,5,4.7,7.6,0.6c2.1-3.3,7.3-6,5.6-9.9c-2-4.6-6.7-8.1-10.7-9.6c-5.8-2.2-11.5-3.3-13.7-12.6
				c3.8-1.8,6.9,0.9,9.9,3.3C2568.5,1158.3,2571,1157.1,2572.7,1151.8z M1293.9,947l0.1,0.2c-4.1-9.8,0.5-13.5,5.9-17
				c2.3-1.5,4.9-3.7,3.8-8.1c-1.2-4.7-6.3-2.7-6.4-2.3c-1.5,8.6-17.5-9.2-11.5,12.2c-11.6,7.6-1.8,17.2-0.6,25.3
				c1.3,9.8,7,4.1,10.6,2.7c2.9-1.2,8.5,0.2,8-5.2C1303.3,949.5,1299.4,944,1293.9,947z M575.1,881.7c3.4-3.5,2.5-6.8-0.5-9.4
				c-2.7-2.5-6.4-5.6-8.6-1c-2.1,4.2-4.2,2.6-6.5,2.9c-6.4-10.7-15-7.3-23-6.9c-2.7,0.2-7.2-0.1-6.9,6.4c0.3,5.9,3.9,8.9,7.7,9.5
				c5.9,0.9,12-3.8,15.4,9.8C554.6,900.5,572.9,891.4,575.1,881.7z M238.7,315.5c-4.4,0.8-12.8-7.5-11.3,7c0.8,8.2,3.7,18,10.7,21.9
				c4.8,2.6,7.6,13.7,13.7,8.6c5.6-4.7,1.5-14.1,2.6-21.3c1.5-9.7-7.4-6.5-8.9-12.9c3.1-0.5,7.1,6.8,9.1-1.3
				c1.5-6.2-1.9-8.5-5.7-10.6c-5-2.7-9.7-6.5-14.5-9.8c4.7-0.6,8.5,2.8,12.4,5.6c6.9,5,9,1.3,8.8-8.5c-0.3-15-10.2-32.9-20.8-36.7
				c-3.9-1.4-7.1,0.6-7.2,6c-0.2,12-3.4,25.5,6.8,33.6c-3,0.2-6.9-2.1-7.2,5.3c-0.2,5,1.8,7,4.8,8.5
				C234.4,311.9,236.5,313.9,238.7,315.5z M672.1,866.6c-6.8-2.3-13.7-5.2-20.3,3.7c6.1,5.3,11.3,7,16.8,1.5c1.3,9.3-8.4,9-8,17.7
				c7.3,0,14.4-0.6,21.5,0.1c7.1,0.7,16-2,17.5,13.4c-0.3,2.6-0.2,4.8,2,5.7c0.8,5.7-3.2,14,4.5,16c-1.2,4-5.9,4.8-3.8,12.2
				c1.7,6.1,2.1,11.8,7.8,11.8c15.4,0,30.8-0.1,46.2-0.1c0.1-1.1,0.1-2.2,0.2-3.4c-9.2-3.2-18.4-6.4-28.3-9.8
				c2.5-2.3,4.2-3.9,5.9-5.5c14.5,4.7,29.1,9.4,44.9,14.6c-3.9-12.1-0.4-22.7-0.8-33.5c-0.3-7.9-2.3-12.3-7.7-11.2
				c-13.2,2.7-26.5-0.7-39.9,1.7c-7.7,1.4-17.5,2.3-25.4-4.7c-0.1-5.1,6.8-5.7,4.2-12.3c-2.1-5.4-5.9-2.2-8.8-3.4
				c-10-4.2-19.6,1.6-29.9,3.1c2.1-8,7.6-3.5,10.1-7.9C677.5,873.2,678.9,862.7,672.1,866.6z M1090.3,987.5l0.2,0.2
				c0.4-7-3.1-8.1-6.9-9.4c-11.8-4.2-18.6,11.2-28.5,14.3c-2.7,0.9-2.2,5.3,2,7.9c10.7,6.8,21.2-3.2,31.9,0.3
				c2,0.7,8.1-4.3,7.7-10.9C1096.4,984.9,1092.5,988.7,1090.3,987.5z M1174.9,1082.9c-1.8-0.2-4.5,0.6-5.3-0.7
				c-2.5-4.6-4.5-7.3-8.8-3.2c-4.2,4-2.8,8.5-1.9,13.5c0.5,2.9,0.4,6,0.6,9c-4.6,8.5,0.1,16.7,0.4,25c-2.6,0.7-2.8,6.5-7.1,5
				c-5.5-1.9-6.6,7.6-10.2,11c-0.2,0.2,0.5,3.7,0.8,3.7c5.3,0.1,8.4,4.3,11.3,10c0.7,1.5,3.4,1.2,4.5-1.8c2.8-7.4,2.8-7.4,8.4-5
				c-7.1,2-8.8,13.3-13.4,19.6c-3.9,5.3,3.2,13.5,6.9,18.1c2.2,2.7,7.8,4.3,10.2-2.4c2.2-6.3,2.4-12.7-3.2-16.4
				c-6.7-4.5-1.2-8.7,0.8-10.4c3.3-2.9,7.5-3.8,11.7-2.9c0.7,1.6,1.5,3.1,2.2,4.7c0.9-2.3,1.5-4.6-0.7-6.4
				c-0.3-7.2,5.9-15.8-2.5-21.2c-0.9-1.4-1.7-2.8-2.6-4.3c2.9-0.8,9,3.8,7.6-5.1c-2.8-16.6-4.7-33.2-3-51.5
				C1176.9,1074.5,1176.3,1079.1,1174.9,1082.9z M2241.8,1421.1c17.7-3.6-2.2-21.6,5.8-29.4c3-2.9-0.3-3.4-1.8-4.2
				c-3.8-1.9-7.5-4.5-11.4-5.6c-3.6-1-8.4-6.9-10.8,0.3c-1.9,5.9-1.5,12.5,1.2,20c3.9,10.8,12.3,11.4,17,19.2
				c-4.9,1.6-9.2-1.9-13.7-3.2c-1.6-0.4-4,0.1-4.9,1.6c-0.9,1.5-0.7,6.7-0.5,6.7c6.5,1.7,9.3,19.4,18.8,8.8c2.1-2.3,4.6-3.7,8-6.4
				C2245.7,1427.2,2241.3,1428,2241.8,1421.1z M2389.6,1300.4c2.1,0.3,4.3,0,6.2,1c10,5.1,10,3.9,10.4-10.2
				c0.3-11.2-6.1-16.1-7.9-24.8c6.6,5.4,16,3.9,19.9,16.1c0.6,1.8,3.1,2.3,3.7,0.3c1.7-6.2,3.1-14.4,0.3-19
				c-4.2-6.9-3.9-23.1-11.7-20.2c-8.2,3.1-16.9,1.7-24.6,5.5c0.9,7.9,7.8,7.3,8.1,14.6c-4.9,1.6-8.9-10.1-13.7-2.9
				c-3.4,5.2-2,12.3,0.5,18.3c-1,1.3-1.9,2.6-2.9,3.9c0.3,0.3,0.6,0.6,0.9,0.8c0.6-1.7,1.3-3.3,1.9-5c6.7,4.3,4.8,15.5,9,21.7
				c-1.7,1.5-5.7-1.1-5.4,2.4c0.5,5.1,1.5,12.3,5.9,13.2c4.7,0.9,3.3,0.8,1,4.6c-4.1,6.7-1,10.5,4,10.8c7.5,0.5,14.9,0.1,22.4,0.1
				C2410,1315.8,2396.5,1314.4,2389.6,1300.4z M608.4,1009c-2.9,0.8-5.9,1.6-8.8,2.4c-6.5-4-13.3-1.9-20-2.2c-4-0.2-8-0.6-12-0.6
				c-31.4,0.2-28.9-5.3-27.1,42.2c0.3,8.3,3.8,17-1.4,24.6c-5.8-6-3.7-14.9-3.8-22.6c-0.1-6.5-4.2-6.7-6.3-9.8
				c-0.6-0.9-3.3,2.2-2.5,3.6c5.4,9.6,0.3,19.4,0.6,29c0.1,2.2,0.7,4,2.6,4.1c1.8,0.1,4.7,1.6,1.8,3.3c-11.3,6.3-1.3,12.2-0.3,18.2
				l0.1-0.2c-3.8,4.2-6.5,27.4-3.7,32c1.7,2.8,4.8,2.5,5.6,0.1c2.5-7.7,9.5-9.1,11.7-17l-0.1,0.2c0.4,0.7,0.9,1.1,1.5,1.1
				c0.3,2.7,0.6,5.4,0.9,8.1c1.9,5.5-4.1,14.9,4.3,17.3c10,3.8,20,7.5,30,11.3c-0.5,1.1-1.1,2.2-1.6,3.3c-8.6-2.7-17.3-1.9-25.9-1.3
				c-2,0.2-4.8-0.2-4,4.6c0.4,2.5,2.3,1.7,3.6,1.8c-0.7,2.8-5.1,4.3-3.4,6.7c5.4,7.8,3.5,17.3,3.9,25.9c0.4,8.2,3.8,10.2,9.8,12.1
				c-4,2.3-8.6,0.2-7.7,6.8c0.7,5.1,4.1,3.9,6.3,3.3c5.7-1.5,11.3-3.7,16.9-5.7c5.8-2.1,9.8,0.9,12.8,7.9c3,6.9,0.2,8.3-3.6,12
				c-2.8,2.7-4.1,10.6-1.8,16.9c0.4,1,0.2,3.4,1.6,2.2c9.6-8.6,4.1,4.3,5.4,7.3c-2.4,1.6-5.8-2.3-7.6,2.3c-0.2,0.5,0.8,2.7,1.6,3.1
				c3.7,1.9,5.2-1.2,5.9-5.6c3.7-0.3,7.5-0.5,11.2-0.8c10.4-0.7,20.3,6.9,30.9,3.7c-0.1,2.2,0.5,5,2,4.3c4-1.9,2.6-7.8,3.3-11.8
				c0.9-5.4-2.9-4.2-5.3-4.6c-1.3-11.3-7.4-14.1-14.3-15.2c-3.3-0.5-6.6-0.7-8.3-5.7c3.6-2.2,10.6,4,10.5-5.1
				c-0.1-6.9-6.6-3-10.2-3.5c-2.1-0.3-4.3,0.4-6.5,0.6c2.4-8,7.7-4.4,11.5-4.8c19.4-2.2,19.4-2,22.2-26.4c0.7-6.3-0.5-9.7-5.4-9.9
				c-7.5-0.3-14.9-1.1-23.2-1.7c5.8-7.3,14.5,2.1,18.3-8.8c2.2-0.3,3.2-2.1,2.2-4.8c-1.7-4.8-5.1-4.3-8.3-4.3
				c5.1-4.7,10.4-3.1,15.8-1.4c2.5,0.8,6,2.4,7-1.1c1-3.2,0.3-8.3-0.9-11.6c-1.6-4.4-4.1-1-6.6,0c-6.4,2.6-12.9,3.4-19.3-0.6
				c12-2.1,13.4-4.8,8.8-16.7c0.1-0.6,0.2-1.2,0.2-1.8c6.3-15.5,0.4-29.8-2.4-44.4c1.6-3.4,6.2-4.9,3.5-11c-0.8-1.8-0.7-6.1,0.3-6.8
				c0.2-11.2,0.5-22.5,0.7-33.7c4.1-3.3,0.5-10,5.1-18.1c0.2,43.2,0.3,82.3,0.4,121.3c0,6,2.6,8,6.3,8.3c3.1,0.3,4.6-1.9,5.1-6.2
				c0.8-7-2.2-10-5.2-11.4c11.6-7.1,10-24,11.8-36.7c2-14.6,0.4-30.1,0.3-46.2c-11.5,7.2-14.2-7.9-14.8-16.8
				c-1.2-16.8-7.4-17.5-16.3-15.4c-1.6-0.1-3.2-0.2-4.8-0.3C615.7,1008.5,612.1,1008.7,608.4,1009z M2552.5,1065
				c-4.5-9.3,8.8-11.3,4.3-20.6c2.7-1.1,6.7-1.2,2.5-7.2c-1.9-2.7-3.7-5-6.6-4c-3.7,1.3-3.5,5.6-3.8,9.7c-0.6,8.5-1,16.9-2.1,25.4
				c-2.3,18.4-1.8,37.5-2.6,56.3c-0.2,5.1,2.7,6.1,4.9,6.7c4.2,1.2,2.9-4.3,3.7-7.2c6.4,0.4,10.6-4.7,9.7-12.7
				c-1-9.4,8-16.6,2.7-24.9c-2.7-4.3-2.6-3.6,0.1-7.6c3.5-5.2,0.1-10-4-12C2558.4,1065.7,2555.4,1065.6,2552.5,1065z M539.2,850.8
				c13.7,6.6,27.3,13.6,42,6.6c5.7,6.8,11.6,1.9,16-1.8c4.3-3.6-0.4-4.2-2.5-4.9c-1.2-3-3.8-7.6,1.7-5.3c4.8,2,8.9,0.4,14.9-1
				c-8.4-10.1-15.9-3.6-23-3.3c-15.7,0.5-31.3,1.8-47,2.7c-4.8,0.3-7.7,1.4-2,7.3c-2.1-0.5-4.3-1-6.4-1.7c-2.4-0.8-5.2-3.2-6.7,1.3
				c-1,2.9-0.2,6.7,2.2,7.7c4.6,2,10.1,3.3,13.8-0.1C545.5,855.3,539.6,854,539.2,850.8z M1781.6,1258c1,1.5,1.9,3,2.9,4.6
				c0.2,16.9,10,6.4,17,12c-9.5-0.8-13,4.9-13,15.2c0,3.6,1.4,9.7-3,9.5c-3.2-0.2-3.6-5.9-4.2-9.8c-0.5-3.2,1.1-7-1.4-10.3
				c-5.5,5.3-6.1,14.4-8.6,21.9c-1.2,3.6,0.3,8,3.6,7.4c14.1-2.6,7.5,8.1,7.1,17.6c-0.4,11.4-4.9,27.8,10.7,30.3
				c2.7,0.4,7.1,7.7,5.8,16.2c-1.1,7.3-0.8,15.3-0.1,22.8c0.7,7.8,5.5,8.8,10.1,8.2c4.6-0.6,2.8-5.7,1.6-7.9
				c-3.5-6.1-4.7-10.4,1.5-14.8c1.7-1.2,2.6-6.4,2.6-9.7c0.1-38.5,7.7-76.1,6-114.7c0.3-4.2,0.7-8.3,1-12.5
				c6.9-5.2,2.8-14.1,3.6-21.4c0.1-2.1,0.2-4.1,0.3-6.2c-0.2-2.1-0.4-4.1-0.5-6.2c0.1-2,0.2-4,0.3-6c1.8-5,1.2-10.1-2.8-10.9
				c-14-2.7-27-12.8-41.7-10.7c-8.4,1.2-8.7,6.8-5,15c2.1,4.6,4.1,9.1-0.2,12.8c-2,1.7-6,1.3-4.9,6.1c1.2,5.4,4.8,7.2,8.9,5.8
				c-0.9,5,1.2,8.8,2.6,12.8c0.1,1,0.2,2,0.2,3c-0.2,1-0.5,2-0.7,3.1c-3.9-0.3-10.8-3.3-11.1,0.8c-0.5,8,3.4,16.1,3,26.9
				C1776.8,1264,1779.2,1261,1781.6,1258z M338.1,1474.5c4.8,3.6-3.9,9.8,1,12.5c6.9,3.7,9.9,13.5,16.3,18c2.8,2,5.1,4.8,7.8,1.2
				c2.6-3.5-0.1-6.3-1.2-9.3c-1.7-4.8-1.8-10.3-0.5-15c1.8-6.7,0.7-13.2,0.7-19.8c0-3.7,0-8.1-2.9-9c-1.4-0.5-3.5,2.8-5.2,4.4
				c-3,2.7-3.9,10.4-9.2,7.6c-2.3-6.5,4.4-11.6,1.6-18.3c-9.3,2.1-17.9,5.9-27.7,15C327.6,1463.3,328.2,1480.2,338.1,1474.5z
				 M227.1,1354.6c-2.5,3.1-7.7-3.7-7.2-0.1c2.4,17.9-7.2,10-12,12c-2.6,1.1-6.2-0.4-6.9,5.3c-0.6,5,0.5,8.6,3.7,11.5
				c10,9,23,7.8,31.2-3.1c2.3-3.1,2.3-6.7,0.8-9.6C233.8,1365,230.3,1359.9,227.1,1354.6c8.5-1.6,8.5-1.6,15.1-13.5
				c0.2-0.3,0.7-0.3,1-0.4c0.3,0,0.6,0.2,0.8,0.3c-0.3-0.2-0.5-0.4-0.9-0.6c-4.3,0.8-7.1,7.6-12,5.8
				C223.8,1343.4,229.2,1352.5,227.1,1354.6z M962.8,1103.8c-3.8,13.2-2.8,22.9,8.9,26.3c6.9,2,14.4-8.7,18.3-25.3
				c1.4-6.2,0.8-11.5-3.7-13.8c-6.3-3.2-2.3,7.6-5.4,9c-2-5.8,1.9-14.1-4.5-17.5c-5.6-7.7-10.7,1.8-16.1,1.1c3.3-8,11.1-5,15.2-12.6
				c-1.7-3.3-5.4-4.6-6.1-9.2l-0.1,0.2c6.7-1.1,6.7-1.1,3.8-7c-0.7-1.4-3.1-2.8-1.8-4.1c7.6-7-2.7-21.5,6.1-27.7
				c0.3-0.2-0.1-1.9-0.1-3c-0.3-8.2-6.2-9-8.9-8.6c-6.4,1-12.9-4.1-18.9,0.9c-0.6,0.5,0.1,3.8-0.2,5.7c-1.8,15.2,7.7,11.9,13.5,14.5
				c-3.6,14.6-20.7,15.2-16.7,35.3c0,0.1-2.5,19.2-2.5,19.3c2.3,7.2,8.2,6.5,8.8,2.1c1.2-8.8,5.5-4.2,8.1-6.1
				C956.2,1096.1,956.6,1100.1,962.8,1103.8z M1003.2,1281c-9.2-1.3-8,6-5.9,14.1c-0.3,0.6-0.7,1.1-1.1,1.6
				c-3.2-0.9-5.6-8.9-8.3-5.4c-4.6,6.1-1,15.2-1.6,23c-0.4,5.5,1.1,8.2,5.5,8.6c5.7,0.6,11.1,0.7,16.6-2.5c1.3-0.8,4.4,4.8,6.4,0.8
				c1.8-3.4,1.7-9-0.3-11.4c-2.4-2.9-3.3-5.3-1.9-8.8c3.3-8.8-0.9-13-5.1-17.3c8.1-1.3,4.3-8.5,2.8-13.1c-1.6-4.9-4.6-9-7.1-13.8
				C999.2,1265.6,996,1273.4,1003.2,1281z M292,953.8c-6.5-4.9-8.7,1.7-9.8,8.2c-1.5,8.6,16.4,36.8,23.3,37.6
				c0.2,3.8-4.6,8.9,0.4,11.1c5.6,2.4,7.1-3.8,7.8-10.5c-2.9-0.2-5.7-0.3-8.4-0.5c3.8-9,11.7-10.2,16.6-16.8
				c-1.3-6.6-6.7-5.7-9.2-9.7c-2.8-4.5-8.9-2.6-9.7-10.5c5-3,7.8,3.1,11.6,5.2c2.1,1.2,4.5,5.2,6.8,1.9c2.1-3,0.5-7.1-0.7-10
				c-5.9-13-11.3-26.6-19.1-37.8C295.4,931.5,290.2,940.6,292,953.8z M300.3,1419c-7.1,3.7-3.8,9-0.9,13.8
				c10.3,17.5,21.2,19.7,35,7.1c1.5-1.4,3.4-2.3,2.6-5.6c-0.6-2.3-1.3-6.4-3.1-5.2c-9.8,6.6-9.3-6.9-12.7-12.3
				c-3-4.7-8.9-11.3-10-7.5c-2.4,8.6-8.4,4.7-11,9.9L300.3,1419z M395.7,823.4L395.7,823.4c0.1-1.8,0.1-3.7,0.2-5.6
				c6.4-12.8,5.4-17.5-4.2-19.3c-0.2-1.9-0.5-3.8-0.7-5.6c9.2-0.3,8.7-9.8,8.8-18.5c0.3-24.4,0.3-24.5-16.9-24.6
				c-15.7-0.1-31.3,1.4-47,2.9c-19.6,1.9-19.7,0.4-18.4,28.3c0.1,2,0.4,4,0.5,6.1c-1.9,2.9-1.5,5.1,0.9,6.8c0.5,1.8,0.9,3.6,1.4,5.4
				c-0.1,1.1-0.1,2.1-0.2,3.2c-1.2,5.2-0.9,10.3,0.3,15.4c-3.4,9.1,5,17,1.5,26.1c3.8,0.3,1.9,9,6.3,8.4c12.1-1.6,24.6,2,36.5-3.3
				c10.3,5.5,20.3-2.3,30.4-1.4C403.2,839.8,401.5,831.6,395.7,823.4z M652.8,938.8l0.6,0.1c-6-28-14.5-36.8-32.9-33.3
				c-6.2,1.2-11,7.3-16.6,10.8c-2.8,5.3-13.4-0.5-10.3,12.5c1.8,7.8,0.2,20.7,9.7,21c16.9,0.6,33.8,0,50.7-0.3c2,0,4.6,0.1,4.8-4.4
				c0.1-3.9-1.6-5.3-4-5.8C654.1,939.2,653.4,939,652.8,938.8z M2533.9,1435.6c1.5,7.9,6.2,6.6,10.3,6.9c0.1,0,0.7-3,0.3-4
				c-2.4-5.3-6.2-3.6-9.6-3.3C2534.5,1435.3,2534.2,1435.6,2533.9,1435.6c-0.5-6.5-1.3-11.1,5.8-14.2c9.5-4.1,14.7-16.1,14.2-30.9
				c-0.5-12.1-8.1-7.7-12.8-11.5c0,12.1,0,22.7,0.1,33.3c0,4.1-0.9,7.8-3.9,7.7c-3-0.1-4-3.9-3.9-7.9c0.3-11.7-1.1-23.6,1.1-35.2
				c0.6-3.2,0.7-6.8-2-7.7c-3.2-1.1-3.3,3.4-3.6,6c-1.9,17.5-4.1,35.1-5.3,52.8C2522.7,1440,2530.2,1433.8,2533.9,1435.6z
				 M1529.2,1094.3c-1,7-2.6,13.9-1.8,21.2c0,3.1,0.9,6.8-0.1,9c-7.8,18,3.7,32.4,4.4,48.8c0.1,2.9,1.8,5.5,4.5,5.8
				c10.1,1.2,19.4-3.5,28.4-8.5c2.2-1.3,2.4-10.2,3.2-15.8c0.5-4,1.4-6.9,4.6-6.8c3.6,0.1,3.6,4.2,4.1,7.6c2.2,7.4-0.7,12.4-6,17.5
				c18.2,3,33.9,0.6,49.8-4.8c17-5.7,34.5-8.1,51.5-13.9c7.2-2.4,8.8-7.4,7.9-17c-1.1-12-6.4-4.6-9.7-4.5c-6,0.2-12,1.5-19.3,2.5
				c8.4-7.2,17.2-2.6,24.5-7.8c4.6,2,4.3-7.8,8.8-6.7c8.8,2.1,8-7.4,8.1-14.7c0.1-10.4-3.1-20.1-1.1-31.3c3.1-17.4-2.2-36,2.5-53.7
				c0.9-3.3,1.7-9.9-3.6-10.2c-6.7-0.5-13.7-3.9-20.1,2c-4,0-7.4-3.1-11.7-2.8c-14.9,0.8-29.7,1.7-44.8-1.3
				c-12.5-2.4-25.5-3.2-38.2,1.5c-2.4-0.2-4.7-0.3-7.1-0.5c-6-2.9-11.8,0.2-17.3,1.9c-18.4,5.5-19.2,8.6-10.9,32.6
				c0.4,3.9-0.4,9.2,4.5,8.3c0.2,3.5,2.4,4.8,4,6.9c-2.2,4.2-5.9,3.5-8.8,5.2c-2.3-1.8-4.6-6.9-7.1-4.4c-3.4,3.5-1.3,9.9-1.6,15
				C1527.8,1081.4,1529.6,1088,1529.2,1094.3z M1008.7,855.2c-1.4,12.3-9.1,8.5-13.8,8.6c-5.6,0.1-5.7,2.9-5.9,8.6
				c-0.4,11.5,5.3,18.8,13.5,15.9c2.6-0.9,4.6-3.9,5.6,2.1c0.8,5.1-0.4,7.6-4.3,8.1c-4.8,0.7-9.9-2-15.5,1.4
				c8.2,15.3,22.8,24.3,18,47.1c-4.1-3.9-7.8-8.7-12.8-10.3c-2,26.3-1.7,26.9,12.6,25.9c4.8,0,3.8-4.9,3.8-8.8
				c-0.1-32.8-0.4-65.7-0.6-98.5c1.1-7.3-0.3-12.1-6.3-12.2c-4.7-0.1-9.5,0.1-14.2,0.1c-2.8,0-4.9,0.8-4.8,5.9
				c0.1,4.9,2.2,5.9,5,5.9C995.6,855.1,1002.2,855.1,1008.7,855.2z M207.9,917c-0.8,1.7-1.6,3.4-2.6,4.9c-1.1,1.6-1.3,0.1-2-1.1
				c-4.6-7.4-5.7-5.6-7,3.6c-1.8,13-0.4,26.5-3.9,39.2c-1.4,5.1,1.1,10.4,5.3,5.9c10.4-11.5,23.2-17.4,34.6-28.2
				c-5-4.2-9.7-6.7-13-11.1c-3.7-4.9-12-2-11.5-13.4c3.2-0.7,6.5-1.2,9.7-2.1c5.1-1.5,14.2-1.1,13.5-7.7c-1-8.2-10.1-4.9-15.5-5.4
				C209.2,901.2,203.1,904.9,207.9,917z M1474.8,1156c-0.9,13.3,7.6,16.3,13.1,17.3c3.1,0.6,8.5-9.2,9-18c3.1,0,4.4-2.2,4.9-6.4
				c1.7-13-6.6-24.3-2.9-37.7c0.7-2.4-2.3-2.8-4.1-3.3c-3.7-0.9-7.7,1.8-11.3-3.3c-0.9-1.2-6.7,0.3-7.2,6.8c-0.6,7.9-2,16,4.9,20.8
				c1.6,1.1,5.2,7.4,2.1,7.3C1473.6,1139.4,1477.6,1150.7,1474.8,1156z M1756,1335.5c-0.9-0.4-2.3-1.3-2.5-1
				c-0.8,1.8,0.3,2.3,1.3,2.7c-2.4,4.5-9.3-5.8-9.8,6.2c-0.7,15.8-1.2,31.7-4.6,47.2c-1.8,8.2,3.3,10.4,7.1,9.5
				c5.5-1.3,11.3-3.3,15.8-8.9c6.6-8.3,3.6-26.3-4.9-31.7c-1.9-1.2-4.2-3.4-4.8-5.8c-0.8-3.6,3-3.6,4-6.1c1.4-3.2,7-0.3,5.7-6.9
				C1762.5,1336,1759,1335.9,1756,1335.5z M563.9,903.2c-8.3-2.2-16.6-2.3-25-2.4c-6.1-0.1-3.4,5.2-3.7,8.9
				c-3.4,0.3-7.6-2.5-9.5,3.8c-0.8,2.6,0,7.1-3.3,5.4c-2-1.1-6.8-2.4-2.9-8.4c-9.3-6.6,2.4-6.6,2.2-11c-8-3.5-13.2-1.8-14,12.5
				c-4-8.1-8.4-10.9-14-5.6c1.3,4.6,6.6,0.3,6.1,6c-1,10.4,1.4,18.1,9.2,26.2c1.4,1.6,2.9,3.2,4.3,4.8c0.5,1.8,1.3,5.2,1.5,5.1
				c7.5-2.3,15.5,3.3,22.8-1.9c13.8,4.5,27.7-1.3,41.6,0.5c4.2,0.5,8.5,3,12.5-1c-4.4-12.4-5.7-29.6-21-22.9
				c-2.6,1.1-4.8-4.3-4.7-8.6c0.1-4.8,1.3-9.2,5.8-8.6c3.5,0.5,7,1.7,11-2.9C575.9,899.3,569.9,898.5,563.9,903.2z M1736.3,1077.8
				c6.6-23.7-2.2-41-12.3-57.4c-3.3,30.4-1.9,61.3-2.3,92.2c-0.1,9.5,1.6,16.6,10.5,19.2c-0.7-13.7,1.7-26.1,2.6-38.9
				C1737.6,1088.4,1736.6,1083,1736.3,1077.8z M319.4,1187.8c-5.8,4.7-9.3-3.3-13.9-4.8c-4.3-1.4-8.8-8.4-12.9-1
				c-4.1,7.2-4.9,15.4-1.5,24.2c7.8,19.6,17.3,17.6,34.5,8.8c2.8-1.5,5.2-4.1,5.3-8.8c0.1-5.3-2.1-8.4-5.5-10.4
				C322.7,1194.2,318.5,1194.5,319.4,1187.8c2.1,0.4,4.2,0.9,6.3,1.2c2.8,0.4,6.1,8.7,8.4-0.1c2-8.1-2.6-11.4-6.9-12.1
				c-4.6-0.7-9.3,1.2-14.2,2C314.3,1183.6,319.6,1181.8,319.4,1187.8z M106.7,464.1c8.7,8.1,17.4,6.9,27.2-0.7
				c-5.8-0.9-6.3-6.6-7.3-11.8c1,0.5,2.2,0.7,3.1,1.5c13.6,10.6,28.1,15.9,43.7,13.4c2-0.3,4.1,0.7,5.4-3.3
				c4.4-13.9-4.7-36.8-15.9-38.2c-9.5-1.2-19.1-0.3-28.6-0.9c-7-0.4-12.6,2.3-17.4,11.5c6.5,2,9,8.3,9.9,16.1
				c-8.7-12-20-15.5-31.3-19.4c-14.6-5-17.9-1.9-17.5,17.9c0.1,3.6-0.3,7.1-0.3,10.7c0,3.5,1.2,6.2,3.8,5.9c8.5-0.9,17.3,2.8,25.5-3
				L106.7,464.1z M1046.2,1043.5c0.5-3.8,5.8-3.1,4.1-9c-1.2-4.3-3.7-5.9-6.6-6.9c-9.8-3.2-13.6,2.3-15.2,21.7
				c-5,2.8-2.7,8.6-2.6,13.2c1,33.5,2.1,34.6,27.5,30.8c3.5,2.3,8.1,0.6,11.2,6.8c-13,3.1-5.8,13.4-2.4,18c5.5,7.4,5.4,11.6-2,16.8
				c4.3,1,7.5,1.8,10.7,2.6c2.4,0.6,6.5-4.6,6.7,2.9c0.1,5.5-3.7,7.1-7.1,7.6c-3.2,0.4-6.8-3.4-9.8,1.8c5,9.5,14.8,9.4,18.4,21.1
				c0.6,1.9,6.6,3.2,7.2,9.9c1.1,12.2-1.6,28.1,15.4,25.4c-5.9,7.7-11.3,12.5-11.1,21.1c0.1,5-2.3,13.9,5.1,13.9
				c5.7,0,8.4-4.3,7.9-12.9c-0.5-9.7-0.2-19.5,0.1-29.2c0.3-11.6-2.1-26.6,2.1-34c4.7-8.3,15.8-1.3,24.1-2.5
				c2.3-0.3,4.6-0.4,6.1-0.5c-7.9-5-17.2,1.4-25.7-1.4c-9-2.9-14.9-13.6-23.9-24.6c12.5,1.8,19.3-4.3,23.8-15.8
				c-0.1-13.2-0.2-26.5-0.3-39.7c-0.3-1.2-0.7-2.4-1-3.7c0.3-5,0.6-10,0.9-15.1c0.8-12-3.6-23.9,0.1-36.3c2.1-7.1-2.1-9.5-6.8-9.5
				c-8.8-0.1-17.6-0.4-26.4-0.5c-14.6-0.1-14.6,0-10.7,18.8c-0.3,6.5,3.3,5.3,5.9,5.4c9.9,0.3,19.7,0.3,29.6,0.4
				c-6.8,8-14.7,0.8-21.7,2.9C1068.7,1046.4,1057.6,1050.6,1046.2,1043.5L1046.2,1043.5z M851.9,430.6c-10.4-4-23.9,6.7-29.7,23.9
				c14.1,2.2,27.7-1.4,41.5-2.6c-0.6-8-7.7-7.9-7.5-15.3c4.1-0.2,6.6,3.4,8.9,7.4c6.8,11.6,15.9,9.5,24.3,6.3
				c4.5-1.7,10.3-8.3,5.5-16c-8.7-13.9-17.6-28.9-33.5-26C851.2,410.2,847.1,418.9,851.9,430.6z M307,1067.8c6,8.1,16,8.6,22.1,22.5
				c-12.8-5.2-21.6-14.1-31.1-21.1c-4.7-3.5-8.1-3-11.4,3c-3.5,6.3-0.5,10.1,2.1,14.2c2.6,4.1,6,7.6,7.9,12.3
				c7,16.9,6.8,17,18.7,7.3c2.5-2,5.1-3.8,7.8-5.5c9.9-6.6,11.1-12.6,5.4-25.7c-2.1-4.8-4-9.8-6-14.7c-3.4-7-6.5-14.4-10.4-20.7
				c-1.5-2.4-3.5-9.5-7.2-4c-4.1,6.2-10,12.2-9.5,21.1C295.7,1064.4,302.6,1065,307,1067.8z M1609.4,1494.2c5.4-1.3,4-7.3,4.3-12.1
				c0.6-8.1,1.1-16.3,1.8-24.4c0.6-6.4,5.3-10.7,7.4-7.3c4.4,6.8,9.5,0.9,13.7,4.4c2.4,2,14.6-17.6,12.8-20.4
				c-2.8-4.3-6.7-7.3-10.3-10.5c-2-1.9-6-1.7-5.8-5.9c0.6-15.7-8.2-7.8-12.3-8.9c-6.8-1.8-2,7.3-3.6,10.9c-2.6,6,0.2,13.1-1.4,19.4
				c-3.1,0.2-3.5-8.3-7.1-4.3c-2.5,2.8-1.8,7.6-1.6,11.6c0.6,15.9,1.4,31.8,2.1,47.8c-1.2,3-2.5,6.1,0,9c0.3,0.3,1.5-0.2,1.9-0.8
				C1614.4,1498.2,1609.3,1497.4,1609.4,1494.2z M2627.3,794.6c12.2,11.1,26.1,10.5,39.7,11.4c8,0.5,17.1-17.4,13.9-27.5
				c-2.5-7.8-8.3-8.4-13.9-8.3c-4.3,0-8.5-0.2-7.3,8.6l0.1-0.2c-12-14.2-26.1-5.7-39.2-6.1c-4.9-0.1-3.7,5.9-1,9.8
				c2.7,3.8,6.3,1.8,9.4,2.8l-0.1-0.2c-0.9,2.8-1.8,5.6-2.6,8.4c-3.9-0.7-8-0.4-8.1,6.3c0,6.1,4.3,4,6.8,3.9
				C2630.6,803.4,2629.3,799.1,2627.3,794.6z M489.2,450.4c6.6,8.3,15.2,6.9,23.1,9.1c3.1,0.9,4.7-1.6,5.2-5.8
				c0.3-2.9-0.5-5.8-2.4-5.9c-8.7-0.1-18.2-13.5-26,2.8c0.7-18,14-21,21.6-33.9c-22.3-0.5-42.8,2.6-63.3,3
				c-10.9,0.2-11.4,4.4-6.9,15.5c-7.5-0.5-10-5.2-5.8-15.7c-16.4,3.7-31.9-2.5-47.4,0.4c-3.4,0.6-9.5-0.9-7.9,6.2
				c2.8,12-3.3,24.2,1.9,35.3c0.4,0.8,2.1,0.6,3.2,0.6c21.4-0.8,42.8-1.6,64.2-2.6c3-0.1,7.1,1.3,7.2-6c10.4,4.8,20.9,13.8,30.1-3.3
				C486.4,449.5,488.1,450.3,489.2,450.4z M1448.8,1189.8c2.2-7.2-0.3-9.3-5-9c-5.5,0.4-11,0.7-16.4,1.1
				c-25.7-2.2-51.3-1.6-77.3,3.6c0.5,7.4,5.3,15.1,3.7,24.5c-0.7,4.4-0.1,9.1,3.4,9.6c5,0.6,2.7-5.5,3.2-9
				c0.8-5.6-2.4-13.7,1.9-16.5c3.4-2.2,8.2,0.5,10.8,7c0.8,1.9,4.7,1.7,7.2,1.9c20.9,1.8,41.7-3.2,62.5-2.8
				C1448.6,1200.4,1449.7,1196.5,1448.8,1189.8z M1769.1,998.9c11.9,3.8,23.9-0.6,35.8,2.1c7.4,1.7,14.8,2.9,22.5,0.9
				c9.2-2.4,4.9-14.1,7.9-20.8c1.7-3.7-0.4-7.3-4.1-8.5c-11.1-3.5-22.2-6.2-33.1,0.5c-3.4,2.1-6,0.6-9.3-0.8
				c-6.7-2.9-13.9-7.1-19.3,3.7c-7.3-6.3-13.5-1.6-15.7,7.3c-2.4,9.9,4,13.4,10.1,14.7C1765.7,998.3,1767.4,998.6,1769.1,998.9z
				 M2507.3,779.2c-1.3-0.6-2.7-1-4-1.8c-4.6-3.2-8.5-11.3-14.6-5c-5.2,5.4-0.8,12.3-1,18.5c-0.4,11.6,2.3,16.1,11.3,15.4
				c15-1.3,30.1-0.3,45.1-0.7c2.7-0.1,7,2.1,7.9-2.6c1.1-5.6-2.4-9-6.2-10.6c-1.5-0.6-2.8-1.4-2.8-4.7c0.2-2.9,2.5-2,4-2.9
				c3.3-2,5.6-5.7,5.2-10.6c-0.5-7.4-5.2-4-7.9-4.3c-7.3-0.7-14.7-0.9-21.7,3c-3.9-5.8-8.8-2.3-13.2-2.7
				C2500.8,769.4,2507.9,776.2,2507.3,779.2z M1343.4,1174.9c8.1-0.2,16.2-0.4,24.2-0.5c12.1,0.1,24.1,0.4,36.2,0.3
				c2.4,0,5.6,1.8,7-2.1c1.8-4.8-2.3-6.8-3.4-10.3c4.6-1.5,8-4.4,6.6-12.2c3.3-35,1.8-70.1,1.6-105.1c5.8-0.2,1.9-9.9,6.6-12.4
				c0.1,20,0.1,39.4,0.2,58.8c-0.2,4.6-0.4,9.3-0.7,13.9c-4.8,3-5,6.1-0.1,9.4l0-0.2c-6.5,6.4-3.1,15.2-3,23.2
				c0,6.1,0.1,12.2,0.1,18.3c-1.3,20,8,20.5,18.3,19.2c2.2-0.3,4.4,0.3,6.6,0c5.7-0.7,9.8-3.9,9.6-13.1c3.5-24.4,8.1-48.6,7.5-73.8
				c4.9-20.8,2.2-43.3,7.6-64.2c1.7-6.5,0-13.6-8.2-12.6c-8.6,1-17.9,0.5-26.1-2.8c-9.9-3.9-13.5-0.1-12.5,17.8
				c-4.1-18.4-13.1-22.5-25.6-14.9c-7.4,4.5-14.8,9.7-23,8.7c-3.1-0.4-13.5,8.5-9-8.9c0.8-3.1-1.8-2.2-3-2.3
				c-11.4-0.6-22.8-0.5-34.1,1.8c-2.2,0.4-6,0-5.6,5.2c0.4,5.5,4.2,4.4,7,4.9c6.2,1.1,11.8-6.7,18.1-2.2
				c-3.7,21.5-1.9,23.7,14.4,18.7c2.1-0.6,6.1-7.2,7.9,1.3c-2.9,3.2-2.3,8.2-3.3,12.3c-2.4,6.3-0.5,6.9,3.4,5.1c0,1.3,0.1,2.7,0.1,4
				c-1.4,0.2-2.8,0.5-4.2,0.7c-2.9,0-5.9-0.1-8.8-0.1c1.9-5.7,8.5-12.1,3.8-17.2c-5.6-6.2-13.7-0.9-19.4,2.6c-6.5,4,0.8,5.5,2.4,8
				c1.7,2.8-0.7,2.7-1.3,4c-3.6,7.9-6.9,7.3-10.1-0.4c-2.4-5.9-7.1-7.3-10.4-5c-4,2.7-0.3,8.4,0.7,11.9c2,7-0.9,14.3,1.5,21
				c-2.7,14.7,3.2,27.1,5.9,40.2c-6.2,7.4-5.8,14.5,0.6,21.3c0.4,1,0.7,1.9,1.1,2.9c-1,3.2-1.8,6.4-0.2,9.6
				C1329.2,1174,1333.8,1178.4,1343.4,1174.9z M1055.5,1416.3c-0.2,10.2-0.2,20.5-0.8,30.7c-0.7,12.6,6,14.4,12,15.2
				c6.2,0.8,12.1-2.2,11.9-13.3c-0.1-8.2,1.6-13,8.1-11.9c5.3,0.9,8.3-2.8,7-9.6c-1.6-8.1,6-20.8-5.7-24c-5.2-1.4-6.4-7.2-6.3-12.6
				c0.1-6.1,4.3-5.5,7.6-5.7c3.2-0.2,6.6,3,10.3-0.8c-11.6-6.6-22.9-4-34.2-4.6c-8.4-0.4-11.5,3.8-9.8,15.1
				C1054.4,1402,1054.9,1409.1,1055.5,1416.3z M506.8,835.3c0.1-0.8,0-1.5-0.4-2c7.3-4.4,15.4-15.4,19.4,5.5
				c0.8,4.4,4.4,2.7,6.8,2.3c21.1-3.8,42.5-3.1,63.6-8.4c9.4-2.4,19.7-3.3,27.3,8.8c0.2,5.2-8.6,12.6-0.9,14.6
				c11.7,3,24.1,0.7,36.2,0.7c-5.4-11.5-12.7-13.3-19.9-15.2c1.2-5.9,6.9-7.6,7-14.9c0.1-3.1-4.2-7.8,0.7-9.5
				c3.9-1.3,9.4-1.8,11.2,3.2c3.1,8.5,8.6,6.5,13,7.8c2.9,0.8,7.7-1.2,9.4-4.3c2.3-4.1-1.2-8.2-3.5-11.6
				c-7.3-10.5-16.2-18.8-22.7-30.7c-1.9-3.5-6.1-5.5-11.2-3.4c-10,4.2-20.8,4.9-31.1,8c-24.5,7.4-49.8,5.8-74.4,11.7
				c-8.5,0.8-16.8-0.1-24.8-4.6c-4.9-3.3-9.2,2.8-13.8,1.9c-10.5-2-13.7,5.7-13.2,18.6c-3-1.4-6.7,1.4-9.3-3c-0.4-2-0.9-3.9-1.3-5.9
				c1.8-7.9,0.2-10.2-6.3-8.9c-4-3.1-6.8,3.1-9.5,3c-10.6-0.7-10.6-1.4-9.9,15.2c0.3,1.9,0.6,3.8,1,5.8c-1.9,7.8-0.8,16-1.5,23.9
				c-1,11,2.3,12.5,9.7,14.1c12.3,2.7,24.4-2.7,36.4,0.7c6.1,1.7,12.5,2.5,12.6-7.1c0.1-8.2-7.2-1.4-10.6-4.4c2.4-4.4,6.2-3.7,9.6-5
				C509.4,840.8,508.6,837.9,506.8,835.3z M249.2,115.1c11,3.2,11.1-6.3,10.5-17.1c-0.3-6.4-0.1-12.9-0.1-20.2
				C241,91.9,241,92,244.8,109.1c-1.1,0.1-2.4,0.7-3.2,0.1c-10.4-7.1-10.4-7.2-10.6,9.5c-0.2,10.8,0.8,21.9-0.9,32.2
				c-4.1,25.1,6.5,37.1,19.6,48.1c4.2,3.5,8.7,2,8.3-3.5C255.9,168.5,267.4,138.6,249.2,115.1z M1991.1,1255.8
				c0.3-2.1-0.6-3-1.9-3.3c1.2-3.3,4.1-2.2,5.6-3.9c5.3-6.3,3.8-11.7-0.5-18.8c-3.3-5.5-9.5-10-10.1-6.4c-2.2,13-6.2,5.1-9.8,3.4
				c-10.7-5.1-14.6-1.1-13.4,14.3c1.5,19.5,3.7,38.8,3,58.6c-0.3,8.1,6.7,16,8.6,13.7c7.6-9,17.9-4.5,25.1-12.6
				c-0.4-4.5-5.3-2.2-5.8-6.5c7.9-4.1,8.3-11.8,4.8-20.4c-0.9-2.2-3.8-1.8-1-5.8C2002.4,1258.4,1994.7,1258.3,1991.1,1255.8z
				 M1921.4,864.5c6.2,11.3,14.8,3.4,22.2,5.4c0.8-6.2,4-12.3,0.2-18.6c-1.1-1.9,0.4-2.5,0.7-3.7c3.7-11.7-0.5-15.8-8.1-19.1
				c-17.8-7.7-36.2-4.1-54.2-6.9c-22.3-3.5-22.8-2.7-17.5,27.9c1.4,5.2-0.9,14.5,4.6,15.1C1886.6,866.2,1904.1,872.3,1921.4,864.5z
				 M980.1,1214c-8.7-3.7-8.8,7-9.4,12.9c-0.9,10.4,8.6,5.5,12.6,13.7c-13.1,0.8-7.7,13-9.9,22c-5.3,22.2,8.1,16.2,13,18.7
				c8.3,4.2,3.8-8.1,5.5-12.7c2.8-7.4,5-15.2,7.3-22.3c-2.9-4.4-9.1,1.2-9.3-6.5c-0.6-21.8-5-44.5,4.8-64.7c0.7-1.5,1.3-2.3,0.2-3.3
				c-7.4-6.4-7-16.5-6.9-26.7c0-2.4-1.7-6-3.4-7.2c-3.3-2.4-2.8,3.1-4,5c-2.8,4.4-3.4,11.2-8.4,13.3c-3.6,1.5-3.3,9.7-2.8,10.2
				c8.9,10.2-0.4,12.4-3.7,17.2c2.3,4,7.6,3.8,8,9.4c-2,7.7-6.5-5.7-7.7,3c-0.8,6,3.8,4.7,6.1,6.6c3.3,2.8,5.6,8,9.9,8.4
				C981.4,1212.1,980.7,1213.1,980.1,1214z M1021.4,901.3l0.1,0.2c-3.6-6.7-6.4-3.6-7.9,2c-4,15.3,0.5,31.3,10.6,39.7
				c3.8,3.2,10.5,5.7,7.8,13.6c-2.9,8.5-6.4-1.6-9.8-2.1c-0.7-0.1-1.5-0.1-2.2-0.1c-2.1,0.1-4.6-2.6-6.1,0.2c-0.9,1.6-0.7,5-0.2,7.2
				c0.9,3.8,3.5,3,5.6,2.5c13.5-3,27.1-0.6,40.6-0.8c13.5-0.2,12.8,0.6,10.6-18c-1.8-15.6-9.1-28.4-9.9-44.3
				c-0.3-6.5-4.6-5.1-7.8-5.4C1042.1,894.9,1031.9,899.1,1021.4,901.3z M1010.1,753.5c-0.9-1.4-2-4-2.7-3.8
				c-2.9,0.6-1.5,4.5-2.1,6.9c-1.8-0.2-3.6-0.5-5.5-0.7c-1.3-1.6-2.3-1.9-2,1.3c-4.9,1.2-11.3-0.1-11.6,10.4
				c-0.1,4.4-2.5,6.9-3.2,9.9c-2.9,12.3-1.1,25.2,1.6,37.3c0.5,2.1,4.3,4.8,8.3,3.1c24.6-10.7,49.4-20.3,74.6-27.7
				c4.6,6.1,7.8-1.7,12.7-1c-4.4-6.5-8.9-5.1-13.1-5.6c-4.3-13-16.1-4.8-21.4-14.9c-9.9-7.6-19.9-14.5-31.4-15.7
				C1012.9,753.1,1011.5,753.3,1010.1,753.5z M624.1,428.3c-14-12.8-29.5-15.9-45.8-14.6c-5.5,0.4-11,1.2-16.5,0.8
				c-3.1-0.2-8.3,2.2-8.2-6c0-3.5,3.2-3.7,5.3-4c1.5-0.2,3,0.4,4.5,0.6c-26.1-0.6-52.2-5.1-78.1,2.8c13.6,0.9,27.2,0.7,40.8,0.9
				c2.8,0,8.6-1.6,6.2,5.8c-4.4,13.1,2,17,7.6,23c5.6,6,9.7,15.7,17.6,15.9c23.9,0.5,46.3-7.3,66.6-25.4c2.1-0.6,4.2-1,6.3-1.9
				c2.8-1.2,7.2,0.7,7.4-6.3c0-1.3-0.8-3.5-1.4-3.7c-3.6-1-7.3-1.6-10.4,2.2C623.7,421.3,623.3,424.6,624.1,428.3z M1105.7,962.2
				c11.8,2.4,23.4-1,35-2.2c2.7-0.3,5-0.9,5.8-5.3c1.9-9.8,3.8-19.5,6.1-29.1c3.9-16.4-8.1-17.6-12.8-25.2
				c-3.5-5.6-8.5,2.4-10.9,6.2c-12.9,20.8-29.2,18.5-46,14.9c6.8-5.6,14-9.6,21-14.2c1.4-0.9,2.8-3.5,3.2-5.6c0.4-2.3-1.5-3-3-3.1
				c-11.3-0.8-22.5-1.7-33.8-2.4c-4.5-0.3-4.7,4.2-3.6,8.3c3.9,14.1,2.3,30.5,10,43c2.3,3.7,1.5,9.4,0.5,14.6
				c7.5,0,14.8-0.1,22.1-0.1C1101.5,962.1,1103.6,962.1,1105.7,962.2z M696.5,841.6c-4.9-0.1-10.3-1.4-10.8,8.8
				c-0.2,3.4,0.5,7.1,3.2,6.2c8.9-2.8,17.5,2.1,26.5,0.2c14.2-3,28.9-3,43-3.3c18.6-0.4,37.6,1.6,56.4,1.7
				c22.7,0.2,24.4-2.6,22.7-33.5c-0.4-7.9-1-12.3-8.5-9.2c-16.6,7-33.8,10.9-51,12.5C750.5,827.9,723.9,838.4,696.5,841.6z
				 M1962.7,1098.7c-0.8,6.1-5.4,12.5,0.6,18.2c-3.1,4.4-4.9,8.7-4.7,16c0.6,17.9,6.6,34.4,6,52.6c-0.2,4.3,4.7,8.7-1.3,12.4
				c-1.4,0.9,8.2,19.1,9.8,17.4c6.6-6.6,14.1-3.8,21.5-6.2c-3.9-2.2-7.7-4.4-13-7.4c5.8-1.7,12.3,3.1,13.4-5.9
				c1.4-11.4,0.2-23.9-3.4-33.8c-3-8.4,3.3-13.5,0.9-20.5c-0.3-0.8,2.5-1.2,2.9,1.3c0.4,3.1,5,1.2,3.6,4.7c-6,14.3,0.9,28.9-1,43.3
				c-0.5,3.4-1.5,10.3,3.9,10.2c5.4-0.1,5.2-5.9,4.8-11.2c-2-28-4.3-56-5.9-84.1c-0.8-13.5,0.3-27.9-14.8-20.8l0-0.9l-0.6-0.4
				c2.8-3.9,4.1-7.6-1.2-9.1c-3.1-0.9-6.7,0.4-9.6-1.1c-9.7-5-12.7,0.4-11.8,13.4C1963.1,1090.7,1962.8,1094.7,1962.7,1098.7z
				 M691.4,1202.8c-4.1-2-8.1-2.9-11.3,2.6c-3.2,0.2-8.6-4.1-8.6,4.1c0,12.2,5.8,18.2,14.9,19.1c-12,6.9-24.5-2-36.2,4
				c-0.2,5.6,3.7,11.7-0.8,16.9c-6.6,7.6-2.4,9.7,3,10.6c3.3,0.5,6.8-0.8,9.8,0.5c4.1,1.8,10,2.9,5.4,13.3
				c-2.1,4.6-1.6,11.7-1.7,17.7c-0.2,20.5-0.1,40.9-0.1,61.4c0,3.1,0.1,6.2,0.1,9.3c-1,6-0.3,13.5,3.3,15.1
				c11.5,5.1,23.5,10.9,35.3,10.8c4.3,0,10.5-2.9,16-3.5c3.9-0.4,9,2,10.5-4.9c1.5-7-6.2-4.2-6.9-10.5c7.3-1.4,8.5-8.7,6.9-16.9
				c-3.3-17.2-2.5-35-4.1-52.4c-0.7-8.2-0.7-14.9-8.8-15.3c-8.4-0.4-8.9,5.8-8.2,14.5c0.3,4.3,0,8.5-4.1,8.8c-4,0.4-4.2-4.4-3.8-7.4
				c1.6-14.8-4.1-18.3-14.6-16.1c5.7-4.8,14.4,2.7,14.3-6.8c-0.1-12.1,6.3-25.3-1.3-36.8c2.6-0.4,5.6-2.2,7.9-1
				c7.5,3.7,9.5,2,10-10.2c0.4-12.1,3.4-26.3-3.5-33.5C707.7,1189,698.7,1196.9,691.4,1202.8z M826.1,654.8
				c9.6-1.7,7.3-21.6,17.6-22.3c0.1,0,0.1-1.9,0.1-2.9c-0.2-23.1,0.5-46.3-1.1-69.2c-0.8-11.2,2.2-13.2,8-12.4
				c20.2,2.9,40.3-0.4,60.5-0.3c4.8,0,7.3-1.5,7.5-9.3c0.2-12.1,0.9-24.2,1.6-36.2c1.6-28.8,3.8-34.4-23.3-32.2
				c-22.9,1.8-45.9,0-68.7,0.6c-37.7,0.9-75.5,4.2-113.3,3.8c-41.1-0.4-82.3,0.5-123.4,1.9c-21.1,0.7-42.5,5-63.3,3.6
				c-40-2.5-79.8-2.1-119.7-1.1c-30.9,0.8-61.8,5.3-92.6,2.6c-18.4-1.6-20.9,0.8-18.6,27c0.4,4.6,1.2,9,1.8,13.6
				c5,19.2,1,39.2,2.9,58.7c1.7,17.6,1.2,35.8,7.4,52.3c1,2.6,1.7,6.1,1.3,8.8c-2.8,15.9,0.4,31.4,1.5,47.1c-3,0.7-4.7,2.9-4,7.4
				c0.4,2.8,2.1,4.8,4,4.1c9.1-3.5,18.6,1.9,28-1.8c-2-1-4.1-0.7-4.6-4.1c24.9-0.5,49.9-1.4,74.8-1.5c9.1,0,18.3,2.8,27.4,2.2
				c34.9-2.5,69.8-2.1,104.7-2.8c27.3-0.5,54.8-1.4,82.3,0.7c35.7,2.7,71.6-0.1,107.4-2.4c19.6-1.2,38.8,2.9,58.3,2.9
				c15.6,0,31.1-1.6,46.7-2.4c0.8-1.3,1.7-2.5,2.5-3.8C823.9,683.3,826,668.4,826.1,654.8z M837.3,766.5c-2.3-5.9,4.5-19.7-5-20.3
				c-26.4-1.8-53.1-2.3-79.1,3.9c-19.8,4.8-39.7,10.1-59.5,14.9c-5.7,1.4-13.5-1-16.1,7.1c-2.4,7.4,5.3,11.2,8.3,15.9
				c13,20,26.9,37.5,49.2,26.3c9.9-5,20.9-8.6,31.2-7.9c18.5,1.3,35.6-6.6,53.1-11.4C841.2,789.2,837.5,788.4,837.3,766.5z
				 M1151.4,854.9c2.4-0.5,5,1.8,7.2-1.6c4.8-7.4,0.7-22.5-5.9-26.9c-5.7-3.8-9.2-5.4-11.8,4.6c-0.9,3.4-3.9,7.8-6.7,5.3
				c-7.2-6.4-16.4-8.1-21.2-20.1c-5.2-13-10.5-21.3-24.6-16.1c-23.1,8.5-45.4,20.7-69,26.8c-5,1.3-6.6,9.4-6.2,16.9
				c0.2,4.1-0.4,8.7,3.9,9.2c12,1.5,23.5,6.6,35.9,4.8C1085.6,853.3,1118.4,850.1,1151.4,854.9z M1428.6,964.6
				c11-1.1,21.9-2.5,32.9-3.3c4.1-0.3,5.8-1,6.3-8.4c0.7-11.7-2-23-1.1-34.1c1.7-20.4-0.4-25-15.3-24.1c-14.6,0.9-29.3,1-43.9,2.1
				c-20.1,1.6-20.1,0.3-16.1,27.6C1397.4,965.5,1398,966.2,1428.6,964.6z M1917,1368.7c1.5,4.8,2.1,6.1,2.3,7.4
				c0.5,3.1-1.1,8.4,2.9,8.1c3.8-0.3,2.5-5.4,2.4-8.6c-0.2-7.3,2.9-16-5.1-20.2c-1.6-0.9-2.7-8.4-0.2-11c4.7-5,4.2-11.9,4-18.1
				c-0.6-17.8,4.7-34.2,5.2-51.9c0.7-20.8,0.4-41.9,1.9-62.7c0.2-2.9,0.9-7.5-3.5-7.4c-3.8,0.1-3.6,3-3.9,6.8
				c-0.5,5.9-0.5,12-7.7,8.8c-17-7.7-17.6-7.2-17.6,18.1c0,5.8-4.5,12.7-1,16.4c7.1,7.5,4,16.8,3.3,24.9
				c-2.7,30.6,0.4,61.1-0.2,91.7c-0.1,7.3,3.3,14.1,9.3,14.9C1915.7,1386.5,1917.4,1379.7,1917,1368.7z M2484.2,817
				c4.7,2.1,5.2,3.4,3.9,6.9c-4.5,12.3,0.6,24.8,10.4,25c10,0.2,20.1,0,30.1-0.1c0,1.3,0,2.6-0.1,3.9c-9.7,2.5-19.6,1.5-29.4,0.6
				c-8.1-0.7-9.8,3.8-9.8,14c0,6.9,4.2,13.1,0.9,20.4c-4.5,10,0.6,12.4,7.8,13.1c-4.7,3-10.6,0.5-9.8,9.6c0.4,4.7-0.5,14.6,1.7,13.2
				c6.8-4.2,17.9,2.9,20.3-9.1c3-14.8,2-31.2,3.3-46.8c0.2-3.2-6.1-1.4-1.9-5.1c3.3-2.9,6.9-1.2,6.4,5.5c-0.9,11.8,4.4,12.1,10.3,12
				c4.8,0,9.6,0.2,14.4,0c6.5-0.2,8.9-6,9.2-14.1c0.4-9.4-4.5-8.8-9-8.9c-2.8-0.1-5.7,0.4-8.3-3.6c1.8-0.4,3.2-1.3,4.5-1
				c10.5,2.5,13.8-4.2,12-17.8c-0.3-2.5-0.4-5.2-0.2-7.7c0.7-8.1-1-12-7.6-10.8C2524.4,819.6,2505.3,815.4,2484.2,817z
				 M2382.8,921.5c18.4-0.7,36.7,3.4,55.1,0.8c5.5-0.8,5.7-3.5,5.5-9c-0.1-5.1,0.4-10.6-5.3-10.3c-22,1.1-43.5-7.6-65.6-6.3
				c-13.1,0.8-26.1,6.5-39.3,2.6c-21-6.2-42.4-3.7-63.6-5.4c-5.6-0.4-6.6,4.5-5.1,9.6c2.2,7.6,4,16.4,12.5,15.7
				C2312.2,916.4,2347.4,925.1,2382.8,921.5z M1427,1429.9c-5-7.1-8.6-10.4-13.9-9.7c-6.1,0.7-14.1,13.9-18-4.4c0,0-1.3,0.6-2.1,0.7
				c-23.9,2.4-47.9,5-71.9,0.9c-2.3-0.4-4.4-1.1-5,3.3c-0.6,4.3,0.5,6.5,3.8,7.3c3.5,0.8,9,1.5,10,4.5c6.5,20.6,11.7,10.2,17-0.9
				c3.5,9.4,6.6,20.7,13.1,3.3c1.2-3.3,3-2.2,4.2,0.4c4.9,10.4,11.2,10.7,15.4,0c2.1-5.3,5.4-6.2,6-1.7c1.7,13.1,8.8,7.2,13.6,9.7
				c11.2,5.8,23.5-16.9,34.1,2.8c0.3,0.5,1.4,0.2,2.2,0.2c5.2,0,10.3-0.5,15.5,0.1c7.8,0.9,9.8-4,10.2-14.4
				c0.6-13.6-2.6-19.1-12.3-17.3c-3.2,0.6-7.1,1.3-9.8-0.5C1428.7,1407.5,1426.8,1415.3,1427,1429.9z M1470.6,815.2
				c-1.1-10.5,1.1-15.2-8-16.3c-15.8-1.9-31.8-3.3-47.6-6.8c-28.5-6.3-57.2-10.9-85.8-16.7c-3.9-0.8-8.1-7.8-9.7,2.6
				c-1.3,8.3,2.4,10.8,8,12.6c44,13.7,88.6,22.6,133,32.6C1466.8,824.6,1471.9,822.4,1470.6,815.2z M810,1112.1
				c-0.7,2.8-1.6,4.7-1.5,6.4c0.3,4.7-0.7,14.4,1.1,13.1c5.6-3.7,10.3,6.9,14.2,0.9c2.4-3.7,2.7-10.3-1-14.9c-2.6-3.2-0.4-5.5,1-7.5
				c4.3-5.8,5.5-13.8,5.4-21.1c-0.2-20.2,5.4-39.1,4.9-59.5c-0.5-19.4,0.4-19.8-13.5-17.6c-1.4,0.2-2.9,1.6-4.2,1.3
				c-4.2-0.9-6.3-1.8-4.8,6.9c2.1,12.8,4.4,25.8,4.2,39.2c-0.2,11.2,1.4,22.5-0.9,35.4c-1.6-2.7-2.8-3.8-2.8-4.7
				c0.6-15-6.6-15.2-14.2-14.7c-8.9,0.6-10.6,8.5-10,19.1c0.3,6.2,1.4,12.9-6,17.9C792.8,1112.1,801.2,1112.1,810,1112.1z
				 M2308.4,1419.4c5.1,4.9-1.8,15.3,6.2,18.4c-2.2,7.8,1.4,12.2,5.6,11.8c6-0.6,5-8.4,4.9-14.5c-0.6-49.5-1.3-99-1.7-148.6
				c-0.1-7.3-1-14.3,2.9-21.8c3.8-7.2-4.1-13.7-4.8-21.7c-0.2-2.6-6.4-2.9-9.1-0.6c-4.5,3.8-3,10.8-2.6,16.7
				c3.7,51.3,1.1,103.3,7.9,154.3c0.5,3.4,1.5,7-1.3,9.8C2312.7,1426.8,2312.8,1418.1,2308.4,1419.4z M1097.5,1548.7
				c0.7-0.1,1.5-0.1,2.2-0.2c12.7-1.4,14-4.2,8.9-18.3c-0.9-2.5-0.5-5.8,0.9-7.1c6-5.1,3.7-13.4,4.6-20.6c1.7-13.8-4.6-27.2-0.9-41
				c0.5-2-0.3-4.3-2.1-4.3c-0.8,0-2.5,1.5-2.4,2.2c0.4,13-7.6,8.6-11.4,8.9c-9.6,0.8-13.4,8.6-12.9,19.7c0.6,13.1-2.2,25.3-3,37.9
				C1079.9,1548.4,1081.1,1551.1,1097.5,1548.7z M1064.4,1198.5c-4.6,5.8-3.1,13.4-3.7,20.3c-0.3,3.8,1.2,8.9-2.9,10.2
				c-2.8,0.8-4.8-1.2-7.4-3.4c-4.8-4.1-12.7-8.1-16.1,2.2c-3.6,10.7-3.9,22.7-3.1,35.3c0.9,13.3,12.6,19.6,9.3,35.1
				c-1.1,5.3-1.6,11.9-1.8,18c-0.2,4.5,0.8,8.3,3.4,10.7c3.2,3,5.4-1.1,7.8-3.1c2.9-2.4,3.7-6.2,2.8-10.6c-4.6-23.4-1-47.3-1.7-70.9
				c-0.2-6.1,1.4-12.8,8-7.8c4.2,3.2,2.5-2.5,2.9-4.6c1.3-6.1-2.8-15.6,4.5-17c1.2-0.2,3.8,12.3,1.9,19.7c-0.2,0.9-0.4,2-0.3,3
				c0.3,5.3,2,8.6,6.3,8.1c4.1-0.5,9.4,3.1,11.7-4.1c3-9.5-4-17.7-3.1-27.8c0.2-2.3-7.9-2.9-12-1.6
				C1062.2,1213.1,1061.5,1208.8,1064.4,1198.5z M858.6,1509.7c-1.4,5.5-4.6,4.7-6.6,6.7c-4,4.1-12.9,1.6-11.5,12.6
				c1.1,9.1,8.1,3.3,12.3,5.2c0.7,0.3,1.4,0.6,2.1,0.8c21.4,7.3,42.9,1.1,64.4,2.2c4.2,0.2,5.8-4.1,6.4-8.7c0.6-5.1-2.8-5-5.1-6.1
				c-8-4.1-16.1-6.4-24.7-6.4c-9.1,0-12.1-7.9-7.8-19.8c-12.5,13-25.5,12.8-39.2,5.4c-2.5-1.4-6.5,4.9-9.4-1.6
				c-1.3-2.9-3.5-3.7-5.7-1.8c-1.8,1.5-1.8,4.3-1.7,6.8c0.2,5.4,3.3,4.7,5.7,4.7C844.6,1509.8,851.4,1509.7,858.6,1509.7z
				 M1027,878.8c-7,9.4-3.3,11.2,3.1,10.7c5.7-0.5,12.9-3.4,16.8-2c11.9,4.6,11.4-3.5,9.9-16.5c3.6,4.7,6,7.8,8.4,11
				c-5.4,6.5-1.1,5.9,2.3,6.4c5.4,0.9,10.8,2.6,16.2,3.3c14.8,1.8,28.7-1.1,38.9-18.4c1.2-2,2.4-4,1.7-6.8c-0.8-3.2-3-2.6-4.7-2.6
				c-7.7,0.1-15.6-0.9-23.1,0.9c-21,5-43.3-9.8-63.4,8.2c-0.9,0.8-3.2-1.6-4.9-2.4c-8.8-4.1-11.7-0.3-10.7,14.5
				C1021.8,886.7,1022.2,878.9,1027,878.8z M584.9,1223.9c-10.6,7.1-20.8-13.9-30.3,5.1c-1.1,2.3-4.1,0.8-6.1-0.7
				c-3.9-2.7-7.8-5.7-11.9-7.7c-3.1-1.5-6.8-7.2-9.7-1.8c-1.9,3.5-0.3,8.9,1.5,13.3c2.2,5.3,4.5,9.6,9.4,8c2.1-0.7,4-2.5,6.1-3.4
				c10.2-4.1,13.9-0.2,14.8,14.8c1,17.3,2.2,34.6,3.6,51.8c0.4,5.3,0.9,7.7-4.4,7.8c-5.5,0.1-6.9,6.6-7.1,14.4
				c-0.3,11.1,5.4,3.3,7.6,4.6c3.8,2.3,12,2.1,12.4-5.4c1.9-31.2,9.2-62.1,3.2-93.1C577.3,1227.3,582.1,1235.5,584.9,1223.9z
				 M960.5,870.1c9.7,17.7,7.3,31.5-6.7,39.4c-6.4,3.6-14.6,6.7-13.4,17.8c1,9.8,4.1,20.1,8.3,29.1c2.2,4.8,6.7,5.8,8.2,4.6
				c4.2-3.4,6.5-2.4,10.1,1.7c3.6,4,8.5,4.3,11.8-1.7c2.7-4.9,3.2-9.3,0.4-16c-2.3-5.6-11-15.8,0.9-21.9c0.3-0.2,0.4-3.5-0.1-3.9
				c-11-9.9-5.2-26-6.5-38.8C972,865.9,968,866.7,960.5,870.1z M1082.4,1322.8c9.3,0.9,14.8,0.6,16.4-16c1.7-18-0.1-35.8,2-53.3
				c0.4-3.3,2.8-5.9-0.9-8.2c-3.5-2.2-7.2-0.8-9.2,2.2c-3.9,5.9,0.9,5.4,3.3,6.7c0.6,0.4,1.2,2.7,1,3.9c-1.4,7,4.1,18.7-2.1,20.2
				c-5.5,1.4-1.6-11.4-3.9-15.8c-2.7-5.3,1-18.3-8-15.7c-6.6,1.9-13.2,4-13.7,18.4c-0.4,14.2,4.5,27.1,2.8,41
				C1068.4,1321,1073.6,1325.5,1082.4,1322.8z M193.6,1319c-1.7,9.6,2.8,14,8.7,17.2c7.3,4,15.2,3.9,22.7,3.8
				c14-0.2,21.9-11.9,21.3-31.8c-0.5-15.4-12.1-15.3-19-21.3c-2.1-1.9-5.2-0.7-7.3,1.7c-5.8,6.8-12.7,10.1-19.6,13.3
				C194.4,1304.8,192.5,1310.2,193.6,1319z M2532.6,1361.2c6.2-6.4,6-15,5.5-23.7c-0.4-6.6,0-12.5,6.6-12.1c4.1,0.3,6.8-2.1,7.4-8.7
				c0.6-7-0.8-11.5-5.3-15.1c-10.8-8.3-10.7-20.2-0.2-30.1c2.4-2.2,7.3-1.3,6.4-8.4c-1.3-10.2,0.7-21.7-3.5-30.5
				c-3.5-7.4-4.7-14.9-4.2-22.8c0.3-5.5-0.2-7.3-4.2-7.7c-4.8-0.5-2.4,4-3.1,6.7c-4.2,18.1,1.4,37.5-2.5,55c-6.4,28-2,56.8-5.1,84.9
				C2529.9,1352.4,2529.2,1356.9,2532.6,1361.2z M2311.5,1064c-7.4-1.4-14.2,0.1-21,0.7c-7.5,0.7-11.3,5.6-11,16.2
				c0.3,8.6,1.1,17.2,1.1,25.8c0.1,5.6,3.1,6.9,5.6,7.9c9.1,3.5,18.4,6.8,27.7,9.3c8.7,2.3,9-7.3,10-15.3c1.2-9.2-2.1-11.5-8.1-11.2
				c-5.9,0.3-11.9-0.8-17.9-1.3c6.7-6.1,13.2-1.5,19.7-1.2c11.5,0.4,7.3-13.4,8.4-19.6c1.4-7.9-5.1-3.1-8.2-3.2
				c-5.9-0.3-11.9-0.1-19.1,0C2302.9,1064.3,2307.8,1067.9,2311.5,1064z M186.5,1423.1c0.6,5.6-5,12.4,2.1,15.8
				c9.7,4.7,19.1,10.9,28.9,14.7c9.3,3.6,17.4-4.2,24.4-10.7c5.1-4.8-1.5-15,1.1-22.4c-8.9-2.3-12.8-17.6-22.7-17.2
				c-8.4,0.3-15.8,0.8-20.3,14.4c-1.8,5.6,0.4,12.6-1,13.1C194.3,1432.1,187.4,1435.6,186.5,1423.1z M87,1360
				c-0.6-11.6,0.3-23.3-1.6-35c-1.2-7.3-3.7-10.6-8-8.6c-5.8,2.6-11.6,1-17.4,1.9c-2.5,0.3-5.5-0.8-6.2,4.2c-0.3,2.3-0.9,5.8,0,7
				c2,2.9,5.1,2.3,7,0.1c4.7-5.6,9-1.5,11.8,2.1c3.5,4.4,2.8,11.3,0.1,16.8c-3.9,7.9-7.1,19.4-7.4,25.7c-0.7,12.5-2.2,25.8-1.1,38.8
				c0.4,4.5-0.7,9.8,4.4,9.8c4.1,0,7.2-2.3,7.8-8.5c0.4-4.5-0.6-10.8,2.1-13.1C90.5,1390.8,86.5,1374.6,87,1360z M2646.7,815.4
				c0,0.1,0,0.2,0,0.2c-2.9,0-5.9,0-8.8,0c-18.8,0.3-18.6,0.3-18.5,26.9c0,8.6,2.3,12.1,8.3,12.1c10.6,0.1,21.3,0.4,31.9,0.8
				c4.8,0.2,7.3-2.6,8.1-9.4c3.9-34.5,11.4-30.2-16.6-30.6C2649.6,815.3,2648.2,815.4,2646.7,815.4z M2820.8,1260.3
				c-7.4-2.9-13.5-6.3-19.8-7.5c-13.4-2.5-18.3,7.1-12.3,24.4c1,2.7,0.3,2-0.2,3.8c-7.7,27.6-2.1,39.1,18.9,39.7c1.4,0,2.8,0,4.3,0
				c-0.6-4.4-6.2-1.5-4.7-7c1.1-4.3,4.4-2.1,6.8-2.6c2.6-0.6,6.6,2.4,7-3.9c0.3-5-3.3-6.6-6.1-7.5c-5.8-2-11.7-3.3-17.6-4.9
				c6.4-1.3,12.5-1,18.6,1.4c2.1,0.8,4.5,0.2,5.1-3.8c0.4-2.8-0.2-5.3-2.4-5.9c-9.5-2.4-0.9-2.1,0.2-2.6c2.8-1.3,2.5-4.6,2.7-7.6
				c1.2-17.8-8.9-9.8-14.5-12.4C2810.7,1260.4,2815.5,1265.6,2820.8,1260.3z M2022.3,1378.6c-0.2-7.6-2.3-14.6-2.5-22.4
				c-0.5-23.2-2.1-46.9-5.6-69.2c-2.3-15.2-2.7-28.8-2-43.6c0.3-6.4-1.3-16.6-7.5-17.1c-7.2-0.6-4.1,8.8-4.2,14.1
				c-0.3,16.6,0.4,34.5,3.3,49.4c5.9,29.9-0.5,60.7,6.6,89.9c1.3,5.4,1,11.4,6.1,11.3C2022,1390.9,2022.2,1384.5,2022.3,1378.6z
				 M773.4,1213c-1,2.2-2,3.5-2,4.6c0.2,8.4-3.3,11.2-8.4,9.9c-6-1.6-3.1-7.3-2.2-12c0.5-2.4,0.3-5.1,0.6-7.6c0.6-5.6-1-9.3-5.1-9.4
				c-4.7-0.1-3,5.7-3.2,8.9c-0.3,5,0.4,10.2,0.4,15.2c0,6.6,0.8,15.1-6.6,13.6c-6-1.2-4.9-8.2-3.8-14.4c0.7-3.9,1.2-8.1,1.2-12.1
				c0-8,1-16.2-8.4-16.2c-9.4,0-8,8.2-7.3,15.4c3.3,36.3,7,72.5,10.6,108.8c0.3,3.3,1.2,6.6,1.8,9.9c1.1-17.8-0.1-35.5,1.3-52.9
				c1.3-16-3-37.6,17.5-39.8C763.6,1234.5,779.3,1234.6,773.4,1213z M604.8,1358.5c-0.3,5.5-0.5,11.7,4.7,11.1
				c5.3-0.7,10.8-2.2,11.2-12.9c0.6-19.6,4.4-39.5,8.9-56.9c4.6-17.9-5.2-18.9-8.7-26.6c-2-4.4-15.8,7.2-15.8,13.4
				c-0.1,14.8-0.3,29.6-0.2,44.4c0.1,4.7-0.7,11,5,11.2c0.1,0,0.2,0.7,0.3,1C602.2,1344.3,607.1,1353.8,604.8,1358.5z M1374.6,961.7
				c0.3-3.7-2.3-4.9-3.3-5.1c-12.7-2.8-21.4-13.8-29.2-26.9c-5.9-9.9-17.9-11.9-19.1-28.3c-0.2-2.2-2.5-3.4-4.6-2.8
				c-1.8,0.5-2.6,2.6-2.3,4.5c3.2,16.8-3,34,1.3,50.9c1,4,1.2,7.9,5.2,7.9C1340,961.8,1357.3,961.8,1374.6,961.7z M2602.1,1170.1
				c7.4,3.2,15.2,5.5,15.8,19.4c-4.9,0.5-9.6,1-14.3,1.3c-3.5,0.2-8.2,5.5-7.8,6c5.8,6.5-1.6,15.9,2.9,22.1c0.5,0.6,1.4,0.8,2.1,0.8
				c7.7,0.3,15.4,2.3,22.9-1.1c1.9-0.9,5.7-1.4,5.3-6.1c-1.1-12.7,3.4-24.9,0.9-37.8c-1.6-8.1,0.8-19.5-4.8-23.6
				c-4.3-3.1-8.7,5.4-12.7,10.2C2610,1164.5,2605.1,1164,2602.1,1170.1z M428.2,1236.3c-0.8-8.5-3.7-17.4-1.8-23
				c4.4-12.8-3.2-13.2-6.3-11c-5.6,3.9-9.2,2.2-13.3-2.5c-4.9-5.6-4.8,2.5-6.7,4.9c6.2,5.2-1,10-0.3,15c1.8,12.8,3.5,25.6,5.7,38.3
				c1.3,7.5,5.6,10.5,10.9,10.2c4.9-0.3,7.6-4.3,9.5-10.6C428,1250.2,425.3,1242,428.2,1236.3z M1900.6,872.6c0-0.5,0-1.1,0-1.6
				c-9.6,0-19.1,0.2-28.7,0.1c-5.5,0-6,3.4-4,9.6c4.3,13.5,12.7,15.8,21.8,15.6c16.2-0.2,32.4-0.5,48.5,2.1c2.8,0.5,6.5,1.5,7.3-3.9
				c0.6-4.1,1-8.5-2.2-12.5c-3.9-4.7-7.9-7.2-12.9-7.5C1920.5,873.8,1910.6,873.2,1900.6,872.6z M467.8,949.1c5.9,0,11.7,0,17.6-0.1
				c1.7,0,3.9,0.7,4.8-2.2c0.9-2.9-0.9-4.6-2-6.5c-5.6-9.9-12.3-18.9-16.5-29.8c-5.5-14.2-14.4-9.5-21.6-9
				c-7.5,0.6-3.5,11.8-3.7,17.6C445.5,949.5,445.8,949.6,467.8,949.1z M1965.4,1358c1.3,5.2-2.1,15.8,4.7,14.1
				c10.2-2.6,19.9-8.9,29.6-14.3c1.4-0.8,2.2-5.2,2.4-8c1.2-14.8-9.5-34.1-19.7-31.7c-5.3,1.3-17.6-6.5-16.6,10.6
				C1966.6,1338.7,1966,1348.3,1965.4,1358z M660,1296.1c0.6-8.5,1.2-14.3-5.2-14.1c-5.5,0.2-10.9-1-11.1,10.9
				c-0.5,24.7,1.5,49.5-2.3,74.1c-1.2,7.7,4.6,12.2,7.6,15.2c4,4.1,4.4-5.8,7-8.7c3.1-3.4,2-8.7,2-12.9
				C657.9,1338.2,657.7,1315.8,660,1296.1z M231,1207.9c-11.5-4-20.1,4.5-29.3,2.4c-1.4-0.3-3.1,0.1-3.2,2.9
				c-0.2,3.1,1.3,4.3,3.2,5.3c17,8.7,32.7,2,47.3-9.4c5.6-4.3,5.4-17.5,1.8-21.5c-7.2-7.9-16-14.9-26.4-11.8
				c-9.5,2.9-12.7,12.8-14.6,25.6c3.8,0,7-0.1,10.3,0C223.4,1201.5,226.5,1202.7,231,1207.9z M250,1484.6
				c-24.5-22.3-47-13.3-69.3,1.7C204.2,1512,226.7,1500.9,250,1484.6z M1429.4,1325.7c0.2,0,0.5,0,0.7,0c0-13.3,0-26.6-0.2-39.9
				c-0.1-6.6-0.5-13.2-1.1-19.8c-0.6-5.8-4.7-9.9-7.6-9.6c-4.4,0.4-2.4,7.3-2.6,11.5c-0.7,15.8,0.3,31.5-0.8,47.4
				c-1.3,18.9-0.2,38-0.9,57c-0.4,10.3,5,10.6,9.1,11.2c5.5,0.7,3.3-6.6,3.4-10.3C1429.7,1357.4,1429.4,1341.5,1429.4,1325.7z
				 M1771.2,1477.2c0-0.3-0.3-5,0-9.5c0.8-11.2-2-16.6-10.6-14.7c-3.2,0.7-6.6,0-9.9,0.4c-4.6,0.6-10.3,2.9-8.8,10.3
				c2.6,13.3-6.7,31.4,4.3,39.6C1763.5,1516.1,1771.7,1507.6,1771.2,1477.2z M493.9,1211.1c-6.4,9.6-16.5,1.2-22.3,14
				c-5,11-7.2,20.6-3.8,32.8c3.8,13.7,12.7,10.9,20.1,11.8c3.7,0.5,6.4-3.5,6.2-10.1C493.9,1244.2,494,1228.7,493.9,1211.1z
				 M1477.8,1049.3c0.2,0,0.5,0,0.7,0c0,9.7,0.6,19.4-0.1,28.9c-0.6,7.9,5.3,10.8,6.8,11.4c5.4,2.1,4.6-9.1,9.2-11.5
				c1.9-1,1.9-5.4-0.6-7.5c-6.8-5.9-7.1-11.1,0.7-15.7c2.9-1.7,2.2-5.2,1.4-7.8c-1.7-6-1-11.3-0.2-17.6c1.7-12.4-6.9-15.7-12.5-17.2
				c-6.6-1.7-5.4,8.1-5.5,14.2C1477.6,1034,1477.8,1041.7,1477.8,1049.3z M1733.8,1471c-0.2-21.6-0.2-21.5-16.2-20.7
				c-3.3,0.2-6.5,0-9.6,2.2c-2.9,2.1-4.9,4.8-4.9,9.3c0.1,8.3,0.6,16.7,0.8,25c0.2,4.5,1.5,7.4,4.7,9.3c14.8,9.1,25.5-0.2,25.2-22.1
				C1733.8,1473,1733.8,1472,1733.8,1471z M2405.2,978.1c13.7-2.5,11.4,10.5,10.7,20.2c-0.8,9.9,1.4,13.2,8.2,12.2
				c3.3-0.5,6.6-0.4,9.9-0.4c7.5,0.1,10.7-4.9,10.1-15.3c-0.3-5.1-0.3-10.3-0.1-15.4c0.3-7.7-1.9-11.3-7.7-11
				c-9.6,0.4-19.2,0.2-29.2,0.2c2.6,3.7,6.7-1.2,8.2,4.5C2412.6,976,2408.7,972.3,2405.2,978.1z M876,796.1c-1.9-0.6-2.2,2-3.2,3.1
				c-11.4,11.7-2.8,27-2.9,40.2c-0.1,10.7,11.2,4,15.1,11.3c1.7-5.3,5.7-3.3,8.4-5.1c4.3-2.9,11-0.1,12.7-9
				c1.7-8.9-3.9-11.7-7.9-16.2c-3.6-4.1-7.4-9.2-9.6-14.2C885.3,798.6,879.1,801.8,876,796.1z M1395.7,849
				c-19.9-6.3-39.8-12.6-59.6-18.8c-6.4-2-12.8-3.9-19.2-5.8c-2.8,26-0.2,30.5,17.3,29.9c6.5-0.2,13.2-3.1,19.6-1.8
				C1367.7,855.3,1381.4,851.4,1395.7,849z M1382.6,960.9c0.3,0,1.2-2.8,1.3-4.7c1-18.3-2.4-36.2-2.9-54.4c-0.1-4-2.4-4.4-4.8-4.3
				c-9.7,0.5-19.5-1.4-28.2,4.7C1353.9,927.2,1374.5,936,1382.6,960.9z M208.1,1257c-0.6,9.4,2.6,14.3,8.6,17.3
				c5.9,3,27.3-5.1,30.7-12.5c1.2-2.6,0.7-4.2-0.8-6.5c-9.5-13.9-34.7-18.7-46.5-8.9c-3,2.5-4,5.7-3.4,10.1c0.5,4,2.4,6.2,5.1,7.1
				C205.5,1265,207.2,1262.4,208.1,1257z M2591.2,1310.1c20.9,0.5,35.8-6.1,39-33.4c-10.1-4.7-20.5-7.6-29.8-9.5
				C2596,1281.3,2602.9,1298.3,2591.2,1310.1z M236.3,1168.8c5.3,2,11.1,12.8,15.8,2c3.7-8.4,0.3-23.5-4.4-25.4
				c-15.3-6.2-31.5-8.2-47.4-11.4c-1.4-0.3-3.1,2-4.5,3.6c-0.3,0.3,0.4,3.4,1,3.7c13.8,7.6,25.2,26.5,42.4,17.3
				c1.3-0.7,2-0.1,2.6,1.7C241.9,1165.2,237.4,1164.5,236.3,1168.8z M302.5,1294.6c-5,0-10.1,3.3-9.7,14.8
				c0.3,8.5,13.4,28.8,18.6,28.8c6.7-0.1,18.3-16.3,18.7-26.2C330.2,1307.2,311.6,1294.4,302.5,1294.6z M2672.7,959.1
				c3.1,1.7,6.2,1.6,7.3-3.4c0.7-3.3,0.3-9.9-1.4-9.3c-7.7,2.7-10.9-7.7-17-9.9c-6.6-2.4-6.9,9.3-9.7,7.8c-9.9-5.4-18.7,13-28.7,1.4
				c-1.6-1.9-7-1.5-6.6,6c0.2,4.5,0.8,12.4,3.8,11c6.6-3.3,12.8,2.2,18.6-0.1C2650.3,958.2,2661.6,963,2672.7,959.1z M1410,1332.1
				c-0.1-25.9-0.7-51.8,0-77.7c0.3-10.6-4.7-10.1-8.8-10.8c-5.2-0.9-6,4.1-6.5,10.7C1392.5,1282.4,1399.3,1307.4,1410,1332.1z
				 M1756,1122.1c-0.5-10.4-1.4-25.1-2-39.9c-0.2-6.3-3-9.5-6.7-10.4c-4.1-1-3.7,4.7-5.1,8c-5.5,12.9-2.3,27.8-5.4,41.5
				c-2.2,9.7,7.1,23.1,13.7,22.5C1759.9,1143,1755.4,1133.1,1756,1122.1z M1878.6,1267.9c-3.6,5.3-3.1,10.9-2,16.3
				c1.5,7,0.4,13.8-2.1,19.3c-8,17.7-2.7,36.8-3.6,55.3c-0.2,3.1,1.4,7.3,5.1,5.5c7.8-3.8,10.1-13.3,4.7-22.2
				c-3.9-6.5-3.9-12.1,0.2-17.3c2.5-3.1,3.7-6.2,3.2-10.6C1882.3,1299.1,1883.3,1283.2,1878.6,1267.9z M209.9,1460.9
				c-8.4-4.4-15.9-8.2-23.4-12.3c-21.6-11.9-21.4-11.9-22.8,20c-0.5,10.4,3.3,14.5,9,10.6C184.3,1471.1,197.3,1469.2,209.9,1460.9z
				 M115.9,1318.6c-0.5,10,10,20.9,16.9,17.8c2.1-0.9,3.5-2.9,3.1-5.4c-2.1-12.2,6.5-19.7,6.8-30.8c0.1-3.2,3.9-5.9,1.2-9.5
				c-2.1-2.8-4.5-4.2-7.9-3.5C120.8,1290.7,116.9,1296.5,115.9,1318.6z M960.5,529.6c0-5.7,0.3-10.9-0.1-15.9
				c-0.6-8.1-11.1-18.6-14.8-14.5c-10.9,11.9-8.4,26.5-5,42.7c1.5,7.2,4.5,9.8,7.8,13.1c1.2,1.2,3-0.3,4.6-0.9
				C963.4,549.9,960,538.4,960.5,529.6z M2592.3,980.7c-10.5-0.8-12.2,1.7-13.1,17.6c-0.5,9,11.9,21.1,22.6,18.4
				c6.3-1.6,12-12.8,11.5-21C2612,976.4,2598,987.2,2592.3,980.7z M860.1,1222.3c-7.4,8.3-12.5,31.7-9,40.5c0.9,2.4,3.2,4.5,5.1,5.1
				c2.5,0.9,2.5-2.6,2.7-5c0.5-6.9,2.3-12.1,8.2-10.8c6.4,1.4,4.9,8.7,4.9,14.5c0,6.5-1.3,13.9,7,14.2c-3-18.6,7.5-38.8-4.5-56.7
				c-4.8,7.1,1.7,23.5-8.5,22.1C856.9,1245,862.5,1231.2,860.1,1222.3z M124.5,1504.2c7.2,0.7,23.1-12.2,22.3-18.7
				c-2-16.3-13.8-14.6-21.3-18.1c-5.9-2.8-11.9,7.4-15,16C109.1,1487.5,120.5,1503.9,124.5,1504.2z M2533.7,970.6
				c-13.7-5.9-15.7,3.7-14,18.6c0.4,3.5,0.1,7.2,0.2,10.8c0.1,5,0.9,9.7,5.4,9.2c5.6-0.6,14.5,4.5,16-3.6c1.7-8.8,5.2-17,5.9-25.6
				C2548.4,962.6,2537.8,973.4,2533.7,970.6z M1447.8,1362.3c3.2-11.6,14.6-20.4,7-36.4c-0.7-1.5-1.2-4-0.8-5.7
				c4.7-23.4,2.5-47.8,5-71.5c0.3-3,1-8.3-2.9-8c-4.2,0.3-3.5,5.5-3.4,9.5c0.1,5.2,4,8.1,2.2,15.4
				C1447.4,1296.5,1449.3,1329.4,1447.8,1362.3z M857.5,1426.3c7.3-16.5,8.3-34.2,9.7-51.8c0.8-9.9,4.2-6.3,8.7-1.6
				c-10-15.8-2.3-19.4,6-23.3c3.7-1.7,7.1-5,10.7-7.6c-7.6,0.3-14.6,7.7-22.1-0.2c-1.3-1.3-4.8-0.6-4.8,1.7
				c0,17.1-11.6,17.2-19.5,25.6c4.5-0.9,7.5-1.8,10.6-1.9c1.1,0,3.3-1.6,3.1,3.3c-0.2,8.3,1.2,16.1-0.3,25.1
				C858.1,1405.1,853.4,1415.2,857.5,1426.3z M824.2,916.3c-3.1-2.8-0.9-12.6-6.5-10.1c-3.7,1.6-2.2,7.9-2.5,12.2
				c-0.4,7.1,1.1,14.1-0.2,21.3c-0.7,3.7-0.7,11,4.2,9.6c5.5-1.7,13.6,4.7,16.2-6.7c1.9-8.5,1.8-18,2.3-27
				c0.6-9.2-2.2-17.5-8.8-17.4c-8,0.1-1.7,10.8-4,15.7C824.8,914.3,824.7,914.8,824.2,916.3z M1811,1423.3c-1.4-6,1.1-15.1-6.4-15.2
				c-2.9,0-9.8-0.5-2.9,7.8c-7.8,5.8-7.1,21.6-15.9,26.1c-1.1,0.6-4-0.2-3,1.8c2.4,5,1.2,14.1,7.9,14.6c5.1,0.4,11.8,5.9,14.6-3.1
				C1808.4,1445.4,1809.2,1433.9,1811,1423.3z M2183.1,1464.4C2183.1,1464.4,2183.1,1464.4,2183.1,1464.4c8.4-0.1,16.8-0.2,25.2-0.1
				c4.1,0,6-3.7,6.5-8.4c0.4-4.4-1.9-6.3-4.9-6.3c-16.4,0-32.8-0.2-49.2,0.1c-3.5,0.1-7.6,0.5-6.8,8.5c0.6,6.7,4,6.3,7.2,6.3
				C2168.5,1464.5,2175.8,1464.5,2183.1,1464.4z M220.6,1169.6c-3.6-9.5-9.9-10.1-15.3-12.1c-15.7-6-26.3,4.8-25.6,24.8
				c0.2,4.6-0.1,9.5,4.3,10.8c3.6,1.1,6.9-0.3,8.6-4.5c1.7-4.3,3-8,7.2-7.3C208.5,1182.6,214.6,1175.1,220.6,1169.6z M1493.3,789.5
				c1.9-9.9-1.5-16.9-1.6-24.6c-0.1-3.8-0.5-8.6-4.8-8.3c-4.4,0.2-5.3,4.5-5.4,9.4c-0.1,5.6-0.2,11,1.8,16.3
				c1.3,3.4,3.6,9.3,0.9,11.2c-7.1,5.2-3.1,17.1-8.3,23.2c-1.8,2.1-1.4,6,0.8,7.9c2.5,2.3,3.6-0.7,5.1-3.1c3.8-6,8.2-8.9,12.8-1.3
				c1.2,2.1,2,4.7,3.9,1.7c1.3-1.9,2.3-5.2,0.8-6.9C1493.3,807.4,1496.2,796.4,1493.3,789.5z M2622.9,1557c-2.5-18-10-5.7-15.3-6.2
				c0.9,1,2.3,1.1,2.8,2c5.8,9.8,12,14.4,20.9,6.4c3.6-3.2,4.8,2,6.6,6.4c2.7,6.5,6.9,13.1,13.2,11.2c5.2-1.6,7.3-9.9,7.5-15.4
				c0.3-10.4-7-7.5-11.5-8.9c1.1-6.1,4.6-13.7-2.4-16.6C2636.4,1532.2,2625.1,1543.8,2622.9,1557z M2469.6,1244.5
				c4.6-1.5,11.5,3.1,12.2-9.3c0.7-11,0.6-19.6-10.3-14.4c-2.9,1.4-2-2.9-2.2-5c-1.1-11.6-5.9-10.5-10.3-4.6
				c-4,5.3-7.8,10.1-13.2,12.5c-2.3,1.1-5,6.2-1.3,7.6C2453.2,1234.6,2459,1248.3,2469.6,1244.5z M1555.6,871.1
				c1.2-1.8,3.8-1.9,6.4-1.8c3,0.1,6.1-0.3,6.4-6c0.3-5.5-0.7-11.8-4.7-12.5c-8.9-1.6-17.8-7.6-26.8-2.1c-6.2,3.8-12.1,6.3-18.9,2.4
				c-3.4-2-8.4-2.7-9,3.4c-0.5,4.6,3.6,9.3,7.8,8.3c9.6-2.4,18.2,3.4,27.2,5.2C1547.5,868.8,1551,869.9,1555.6,871.1z
				 M2372.6,1098.8c-4-4.5-1.5-9.3-1.9-13.6c-0.4-4.4,0.1-10.3-4.7-10.1c-4.9,0.2-2.3,5.9-2.9,9.4c-2.9,17.6,5,34.4,1.7,52.1
				c-0.5,2.5,0.2,6.6,3.4,6.4c2.6-0.1,2.9-3.6,2.3-5.6c-5.7-20.5,7.8-34.6,9.2-53c0.5-6.8,3.7-15.3,0-19.4c-4-4.5-10.5-2.4-17.6,0.4
				C2375.8,1070.5,2376.1,1071.5,2372.6,1098.8z M1166.5,897.4c0.7,3.4,0.6,6.3,1.5,7c9.1,8.1,11.5,25.8,23.1,30.7
				c1.6,0.7,1.6,8.8,5.5,6.9c4.2-2.1,2.1-7.8,2-12.1c-0.2-6.6-2.7-12.3,0.6-20.1c3.5-8.4-3.6-17.1-9.6-11.2
				C1181.3,906.8,1175.1,900.3,1166.5,897.4z M2286.9,1334.3c0-10.4,1.6-25.4-0.4-26.4c-7.8-4-9.8-15.7-16.6-19.7
				c-2.3-1.4-6.1-5.6-7.3,0.7c-1.4,7.6-3.3,14.3,0.7,23.9C2268.9,1325.9,2277.5,1327.6,2286.9,1334.3z M442.8,1242.3
				c-2.2-8.5-3.8-16.1,1.9-23.5c1.5-2,6.7-5.6,3.8-10.7c-3.4-5.9-7,0-10.9,2.7c-0.5-6.3-0.9-11.6-1.3-17c-0.2,0.1-0.4,0.1-0.6,0.2
				c0.1,25.9,0.2,51.9,0.3,77.8c0.9,0.7,1.8,1.4,2.7,2.1c0.9-17.4,7.5-25.7,17.9-33.5c11.1-8.3-3.7-13.9-5.5-22.6
				C455.3,1231.2,438.3,1229.3,442.8,1242.3z M2560.1,623.5c0.8,9.2,1.5,16.8,2.2,24.4c0.3,2.8-0.4,6.6,2.8,7
				c2.5,0.3,4.4-1.5,5.2-4.5c2.7-9.7,3.8-19.6,2.6-30c-0.9-7.9-4-17.4-2-23.5c3.6-11.2-3.7-10.7-5.9-11.9c-4.4-2.4-4.5,4.4-4.9,8.6
				C2559.1,604,2565,614.5,2560.1,623.5z M2787.7,1361.5c2.1-5.8,2.6-12.3,7.5-10.8c4.3,1.3,3.7,6.8,4,11.2
				c0.4,4.8,2.1,11.9,5.1,6.3c3.6-7,8.3-9.2,12.9-12.7c4.5-3.3,6.1-9,4.9-15.5c-1.3-7-5.7-7.1-9.9-6.4c-4.7,0.8-9.4,2.6-14.2,3.1
				C2787.6,1337.7,2785.2,1346,2787.7,1361.5z M868.1,898.7c9.4,11.7,21.1,15.4,27.1,29.3c1.2,2.9,4.2,4.5,5.5,8.5
				c0.8,2.7,5.3,3.2,4.8,0.1c-1.7-12.2,2.2-24,0.6-35.9c-0.2-1.3-0.5-2.7-2.1-2.5C892.8,899.4,881.5,893.6,868.1,898.7z
				 M1553.3,1427.4c-0.1,0.6-0.2,1.3-0.3,1.9c-8.1,0-16.1,0-24.2,0.1c-2.1,0-4.6-0.1-5.3,3.8c-0.5,3,0.9,4.6,2.6,5.9
				c5.4,4.1,33.8-0.3,38.1-6.5c3-4.3,2.8-8.4-1.4-12.4c-11.5-11-23.9-6-36-5.2c-2.5,0.2-3.8,2.9-3.9,6.4c-0.1,4.9,2.4,5.9,5.1,6
				C1536.4,1427.6,1544.9,1427.5,1553.3,1427.4z M1974.3,840.8c0.1-11.8-6.4-11.4-12.1-12.6c-5.8-1.3-7.1,2.4-7.5,10.2
				c-0.2,5.7,1.6,12-1.7,17.5c-3,5-4.8,11.7,1.8,14c6.1,2.1,14.2,5.3,16.3-6.6C1972.5,856.2,1974.2,848.6,1974.3,840.8z
				 M2521.9,1087.8c0,8-0.3,13.7,0.1,19.3c0.6,8.8,6.1,9.7,10.6,10.4c6.4,1.1,4.6-6.9,4.3-10.8c-0.9-10.9,2.3-21.1,2.1-31.8
				c-0.2-11.1-3.8-12.9-10.8-9.3C2518.7,1070.3,2522.9,1081.5,2521.9,1087.8z M413.7,1159.2c7.9-8.3,17.1-8.1,26.5-10.9
				c-3.4-5.4-18.7-6.5-3.1-15.8c0.7-0.4,0.8-3.7,0.6-5.6c-0.4-3.6-2.3-3.2-4.2-2.5c-7.6,2.9-15.6,2.6-23,0.8c-9.4-2.2-1.5,9-5.3,11
				c-2,1-1.2,5.5,0.6,5.9C412.8,1143.3,412.1,1151.5,413.7,1159.2z M2559.5,476c-10.1,0-10,11.1-8.9,17.3
				c2.6,14.6,7.9,28.1,13.5,41.2c1,2.4,2,4.5,4.1,3.7c2.3-0.8,2.8-4.2,1.9-6c-2.8-5.6-0.2-10.3,0.2-15.6c0.7-8.5,1.1-17.8-0.7-25.7
				c-1.5-6.8-7.7-2.3-11.7-3.4c-1.9-0.6-3.6-1.2-3.4-4.5C2554.6,478.8,2558.6,481.4,2559.5,476z M760.6,964.1
				c4.9-1.5,12.1,3.5,12-6.7c-0.1-9-7.1-5.7-11-5.7c-17.9,0-35.7,0.9-53.6,1.5c-1.8,0.1-4,0-4.2,3.4c-0.1,3.1,1.8,4.3,3.6,4.8
				c3.9,1,8,2.7,11.9,2.5C733.3,963.2,747.3,962.1,760.6,964.1z M1231.5,1300.9c-0.7-11.2-1.2-21.7-2.1-32.2c-0.3-4,0.6-10.6-4.8-9
				c-4.4,1.3-12.6,2.3-11,8.8c2.8,11.4-1.8,21.8,0.8,32.4c2.4,9.5,8.7,11.6,13.9,11.9C1233,1313.1,1230.7,1304.8,1231.5,1300.9z
				 M201.6,1509.8c-9.5-5.6-17.3-9.9-24.9-14.6c-5.4-3.4-11.3-4.5-15.1,1.7c-5.4,8.9,4.7,7,5.6,12.4c3.3,20.1,12,13.6,20.6,8
				C191.8,1514.7,195.9,1512.9,201.6,1509.8z M2512.2,993.7c-0.1-10.1,3.1-22.3-8.9-22.3c-8.2,0-11.2,7.4-11.4,17.4
				c-0.4,15.6,3.1,20.1,14.1,21.1C2517.7,1011.1,2510.5,999.1,2512.2,993.7z M1529.1,974.7c-3.5,3-9.9-3.7-9.3,7.2
				c0.4,7.1-1.1,15.4,7.8,15.5c9.4,0.1,17.4-4.6,25.1-11c2-1.7,3-4.7,2.4-8c-0.8-4.1-4-3.4-5.5-3
				C1542.4,977.4,1535.4,977.7,1529.1,974.7z M2234.8,1304.7c5,0,10.2-2.2,12.8,0.5c4,4.2,4.5,1.8,4.3-2.4
				c-0.3-5.4,2.2-12.3-1.3-15.9c-3-3.1-5.8,2.5-8.6,4.7c-1.9,1.5-4.2,2.1-6.1,3.6c-7.6,5.7-13,23.6-8.5,32.3
				c5.6,10.6,10.6-2.3,16-3.1c2.6-0.4,5.1-3.7,7.7-5.8C2246,1313.6,2239.7,1312,2234.8,1304.7z M878.2,1094.3c3.4-1,6.7-1.4,9.7-3
				c5-2.7,5.1-10,2.8-13.8c-2.4-3.9-8.8-11.4-10.1-7c-1.8,5.9-3,6.5-6.8,6.8c-10.1,0.9-12.4,6.7-10.7,24.9
				c1.5,15.5,7.7,0.7,11.1,3.4c0.8,0.6,2.1-0.2,3.2-0.4c2.9-0.5,7.4,3.1,8-3.8C886.2,1094.7,880.6,1097.6,878.2,1094.3z
				 M155.4,977.7c0.6,5.3-1.5,11.3,3.8,14.6c3.7,2.3,6-0.7,7.7-3.1c3.6-5,9.6-15.2,8.9-16.3c-5.1-8.1-5.6-18.6-8.9-27.4
				c-1.1-3-2.5-5-5.1-3.9c-2.3,1-4.2,3.7-3.8,6.6C159.4,958.7,155.1,967.9,155.4,977.7z M115.4,1428.4c0-0.2,0-0.4,0-0.5
				c-6.5,0.7-13.1,1.3-19.6,2.2c-2.1,0.3-4.7,0.4-4.8,4.6c-0.1,4,2.5,4.2,4.5,5.4c5.1,2.8,10.6,4.7,15,9c6.1,6.1,11.8,4.7,15.5-2
				c3.1-5.7,12-8.3,9.3-16c-2-5.7-10-2.5-15.4-2.8C118.3,1428,116.9,1428.3,115.4,1428.4z M1557,1360c12.5-24-2.5-47.5-2.2-72.1
				C1554.3,1312.5,1540.9,1338,1557,1360z M1408,1209.3c0,1.3,0.1,2.5,0.1,3.8c-7,0.6-14.1,1.8-21.1,1.6c-5.6-0.2-7.3,2.9-6.2,10
				c0.6,3.8,0.3,9.3,3.6,10.3c4.1,1.2,10.2,3.4,12.4-1.6c3.9-8.8,8.8-9.5,14.9-8.8c3.3,0.4,7.9,0,6.9-6.2
				c-0.8-4.9-0.1-13.5-6.3-13.7c-12.1-0.4-24.2,0-36.3,0c0,1.6,0,3.2,0,4.8C1386.7,1209.3,1397.4,1209.3,1408,1209.3z M544.4,1252.7
				c-6.4,0.7-10.5,5.9-16.2,2.7c-3.3-1.9-5.9,4.8-3.8,7.6c6.5,8.8,0.9,23.3,8.5,31.4c2.7,2.9-0.8,13.5,5.4,12.2
				c6.2-1.3,2.3-10.2,3.6-15.4c0.5-2.2-0.5-5-0.3-7.4C542.5,1274,539.1,1263.5,544.4,1252.7z M1166.5,1236.6
				c-2.9-6.9-3.6-12.3,1.8-14.3c5.1-1.9,9.7-4.2,6.6-12.8c-3.5-9.5-9.8-13.5-15.9-5.8c-7.1,9.1-10.4,8-12.2-5.2
				c-3.5,11.8-3.5,11.8,9.3,14.7C1151.6,1234.2,1151.6,1234.2,1166.5,1236.6z M117.8,1506.4c-7.6-2.5-8.4-18.5-15.5-13
				c-10.6,8.2-19.5,20.5-31.5,33.6C88.9,1524.5,102.6,1517.3,117.8,1506.4z M1619.3,990c-2.7,4.4-8.5,2.1-8.9,9.8
				c6.6,3.7,13.7-6.1,16.9-2.9c9.4,9.4,13.4-2.4,18.8-6.7c3.9-3.2,3.4-9.2-2.3-10c-11.4-1.6-22.8-2.7-35.9-4.2
				C1612.1,982.6,1617.1,983.5,1619.3,990z M2434.7,1169.5c-0.7-4.5,0.6-9.7-3.2-11.1c-3.5-1.3-6.3,1.9-8.4,5.6
				c-1.6,2.9-1.5,6.6-2.2,9.9c-3.2,16.5,2.7,31.8,2.9,47.8c0,3.4,1.2,7.5,4.4,7.6c4.4,0.1,3.7-5.4,4.1-9
				C2434.4,1203.2,2431.5,1185.7,2434.7,1169.5z M2839.8,959.7c5.2,1.3,10-0.3,9.7-8.1c-0.4-8-6-1.6-8.8-3.6c-6-4.3-10,6-16,3.5
				c-7.3-3-14.9-4.4-22.6-3.7c-4.1,0.4-7.3,2.4-6.9,9.1c0.4,6.9,4.2,5.9,7.4,5.7C2815,961.7,2827.5,960.7,2839.8,959.7z
				 M2412.6,1364.8c-8.3-11-16.4-10.2-21.2-0.9c-4.5,8.6,0.1,18,5.5,25.2c4.8,6.4,8.8,1.1,12.8-1.1c3.4-1.9,7.6-2.8,7.6-10.9
				c0-15.7-8.7-7-14-10.8C2406.3,1365.9,2408.7,1365.5,2412.6,1364.8z M2535,905.2c-4.6,0-8.2-0.1-11.9,0.1c-5,0.3-4.4,5.7-4.3,10.1
				c0.1,4.2-1.4,10.3,3.8,10.4c7.3,0.2,14.7,0,22-0.6c4-0.3,5.2-4.4,5.1-9.8C2549.4,897.2,2539.2,909,2535,905.2z M1569.8,1292.2
				c0.9-28.8-1.7-53.4,0.1-78c0.1-1.3-1.6-4-2.5-4.1c-2.4-0.3-2.6,2.9-2.8,5.3c-1.3,13.1-3.7,26.3-3.5,39.3
				C1561.3,1266.3,1563.9,1278.1,1569.8,1292.2z M2530.5,961.6c3.9,0.8,7.2,1.7,10.7-2c3.7-3.9,7.3-7.1,6-14.5
				c-1.5-8.3-6.4-4.9-10-5.3c-4-0.4-8,0.4-12.1,0.3c-3.4-0.1-5.3,1-5.7,6.7C2518.5,959.2,2520.4,966.4,2530.5,961.6z M2683.4,1301
				c0.6-8.1,1.7-15.8-6.1-16.5c-7.4-0.7-12,9-12.2,27.2c-0.1,7.7,4.1,9.4,8.9,9.8C2686.7,1322.4,2681.9,1308.5,2683.4,1301z
				 M1295.6,854c1.7-1.2,7.2,4.2,8.8-2.9c1.3-5.8-2-12.1-4.7-16.3c-2.2-3.4-6.3-5.4-3.6-10.3c5-9-1.3-8.2-3.7-8
				c-9.9,0.6-5.7,12.5-8,19.6C1279.8,850.8,1282.8,856.1,1295.6,854z M1989.3,1399.6c-0.4-6,6.5-4,4.9-10.8
				c-6.6-1.3-12.5-5.1-18.5-8.9c-3.1-2-7-7.1-9.4-0.6c-1.7,4.6,0,9.3,3.1,14.4c7.5,12.1,18.4,10.3,27.3,16.2c1.5,1,3.1,9,6.3,1.7
				c3-6.9-1.6-9.1-3.8-12C1996.4,1395.9,1993,1400.9,1989.3,1399.6z M2789,1039.5c7.9-0.9,15.9,5,23.6-0.3
				c3.6-2.5,10.2-3.5,8.1-11.4c-1.5-5.6-7.1-1.9-10.8-1.3c-8.7,1.2-17.3,9.5-26.3-0.2c-1.6-1.8-5-1.6-7,1.8c4.1,1.7,1,10.6,5.7,11.4
				C2784.6,1039.9,2786.8,1039.6,2789,1039.5z M198.4,73.7c1.7,7.9,3.3,16.3,5.2,24.4c0.7,2.9,2.6,5,5.1,3.7
				c5.8-3.1,9.9-17.1,6.2-23.7c-2.8-5-4.2-10.4-5.7-16.1c-0.9-3.3-1.2-7.6-4.6-7c-2.9,0.5-3.9,4.3-4.5,7.7
				C199.3,66.2,199,69.7,198.4,73.7z M759.2,1242.2c-4,5.7-3.1,8.9-3.2,11.8c-0.5,17.6,1.6,35.4-3.7,52.6c-2.3,7.7,0,16.5,1.4,24.6
				c0.6,3.2,1.8,5.7,4.6,4.7c2.5-0.9,1.8-3.7,1.6-6.2C757.8,1301.7,758,1273.5,759.2,1242.2z M415.2,831.3c-1.7,8.7,1,12.1,4.8,13.3
				c5.9,1.8,6.4-7.8,10.2-11c1.5-1.3,1.6-11.4-2.3-12.8c-7.2-2.5-6.2-4.8-1-8.9c3.9-3,4.9-7.7,3.5-12.9c-1.3-4.8-5-3-7.7-3.6
				c-4.7-1-4.4,4.8-5.8,8.7C413.3,813.6,417.8,824.1,415.2,831.3z M203.5,206.5c-9,10.5-10,36.7-2.5,45.4c1,1.1,2,2.6,3.8,1.6
				c5.5-3.2,10-17.3,6.5-23.8C207.7,222.9,206.2,215.2,203.5,206.5z M281.1,727.6c-4.4-4.6-6.3,0.7-6.8,4.9
				c-0.6,4.4,2.7,7.2,5.3,8.2c3.5,1.4,7.3,1.6,10.3-2.7c1.9-2.8,3.5-3,6.6-1.6c7.6,3.4,9.4-1.9,6.4-11.2c-1.2-3.6-3.5-6.5-4.7-10.1
				c-1.4-4.6-2.7-8.8-7-7.3c-3.7,1.4-4.6,5.2-4.9,10.4C286,721.7,287.8,729.8,281.1,727.6z M173.1,1288.7c0,3.1-0.1,6.1,0.1,9.2
				c0.3,3.9-2.7,10.2,2.8,10.8c4.2,0.4,9.7-4.3,9.6-7.9c-0.2-10.9,3.2-21,2.5-31.8c-0.2-3.9,0.4-8.9-3.5-9.8
				c-3.1-0.7-5.5,1.6-7.6,4.9C172.4,1271.2,173.7,1280.2,173.1,1288.7z M2617.5,1250.3c1.9,0,4.8,0,7.8,0c4.6,0,8-3,9-9.1
				c0.7-4.3,1.2-10-1.3-13.3c-3.5-4.6-7.4-1.8-11,1.3c-2.7,2.4-6,3.4-8.4,7c-3,4.5-11.9,2-10.6,10.2
				C2604.5,1256,2611.8,1248.7,2617.5,1250.3z M778.6,1360.3c-0.1-4.1-13.8-18.7-13.9-18.2c-0.6,11.5-13.2,3.7-13,15.8
				c0,2.4,0.3,5.2,1.3,7c1.8,3.1,21,3.2,23,0.4C777.2,1363.6,778,1361.4,778.6,1360.3z M586.9,1305.3c0,7.2,0.1,14.3,0.1,21.5
				c0,4.5,0.4,8.2,4.5,8.5c3.9,0.3,5.2-3,5.3-7.6c0.4-14.8,0.5-29.7,1.1-44.4c0.2-5.6-1.3-7.3-4.9-7.3c-3.3-0.1-6,1-6,6.4
				C586.8,1290,586.9,1297.7,586.9,1305.3z M17.2,1303.5c-0.1,0-0.2,0-0.3,0c0,7.2-0.1,14.3,0.1,21.5c0.1,2.6,1.3,4.9,3.4,5
				c1.8,0,2-2.1,2.4-4.2c4.2-21.5-0.1-43.6,2.2-65.2c0.3-2.9,0-6.6-3.1-6.6c-3.2,0.1-2.8,3.8-2.9,6.8
				C18.4,1275,17.8,1289.2,17.2,1303.5z M2532.9,1155.4C2533,1155.4,2533,1155.4,2532.9,1155.4c0.1-5.6-0.5-11.4,0.2-16.8
				c0.5-3.9,1.6-7.1-2.2-7.9c-2.7-0.6-5.3-1.8-7.4,3.5c-4,10.2-4.7,20.4-1.2,30.7c2.1,6,1.5,18,7.4,16.5c6.9-1.8,2.3-12.9,3.3-19.8
				C2533.2,1159.6,2532.9,1157.4,2532.9,1155.4z M2027.2,1229.6c-9.6,3-13.3,8.7-9.6,21.4c1.4,4.8,0.4,11.1,0.4,16.7
				c0.2,12,5.6,20.2,9.8,29.5C2037.4,1273.3,2022.6,1252.2,2027.2,1229.6z M2742.3,1450.3c3.3,0,6.6,0,9.9,0
				c4.2-0.1,8.1,0.1,7.9-8.5c-0.2-7.3-2.3-9.8-7.4-9.7c-6.2,0.2-12.4-0.5-18.6-0.5c-6.1,0-4.1,8.8-6.5,13c-1.5,2.6,2.8,6.2,5.9,6.3
				C2736.5,1450.9,2739.4,1450.5,2742.3,1450.3z M214.8,633.4c26-19.2,27-24.4,10.1-43.4c-0.3-0.3-0.5-0.8-0.8-1.1
				c-4.8-5.7-7.3-3-7.7,4.6C215.7,606.2,215.4,618.8,214.8,633.4z M1425.9,1492.8c-0.3,1.6-0.6,3.3-0.8,4.9
				c7.4,2.3,15.1,3.5,21.8,8.7c3.7,2.8,4.7-5.6,5.2-9.4c0.9-7.5,0.9-15.3,0.8-23c-0.1-5,2.7-12.6-3.8-13.4c-7.6-1-5.3,7.9-5.2,13.1
				c0.2,11.6-2.1,18.9-11.7,17.6C1430.2,1491,1428,1492.3,1425.9,1492.8z M174.9,1023.4c0.2-10.3-2.7-16.8-7.2-17.4
				c-4.4-0.6-13.9,16.1-14.3,25.2c-0.4,8.9,5.3,10.7,8.7,10.3C169.6,1040.5,176.2,1035.8,174.9,1023.4z M1503.2,1018.3
				c3.1,14-3.4,29.1,3,42.5c1.5,3.2-0.1,5.7-1.9,7.7c-2.5,2.8-2.5,7.4-1,9.6c3.8,5.3,4,10.1,1.5,16.3c-1.2,3.2-1.4,7.5,2.2,8.2
				c4.4,0.9,4.5-3.8,4.4-8c-0.5-13.2,0.2-26.8-2-39.5C1507.3,1042.8,1511.1,1028.9,1503.2,1018.3z M311.4,421.1
				c-2.7,0-4.3-0.5-5.6,0.1c-3.7,1.9-9.6-4-10.9,5.2c-0.7,5.3-1.3,13,0.8,16.1c4.3,6.4,8.1-2.2,12.3-4c1.7-0.7,4-1.2,4.9-2.9
				c3.3-6,13.9,1.2,12.2-10.1C323.5,414.6,315.1,423.3,311.4,421.1z M191.6,377.1c0.2,7.5,1.3,13.2,4.9,17.6c1.9,2.3,1.7,9.4,5.9,6
				c3.7-2.9,8.1-6.5,6.5-14c-1.4-6.7-2.1-14.1-6.2-19.2c-1.7-2.2-1-8.6-4.5-6.8c-2.4,1.2-5.3,3.8-5.8,8.4
				C192,372.1,191.7,375.2,191.6,377.1z M1961.9,877c-3.9,0.5-7.8-1.8-9,4.7c-0.9,5-2.2,11.4,0.8,14.5c4.2,4.3,10.1,5,14.3,0.2
				c3.6-4.1,5.4-4.1,7.8,0.9c1.1,2.2,3.4,4.6,4.3,1.9c1.5-4.4,1.5-9.7,2.1-14.6c0.3-2.7-1.6-4.6-2.5-3.6
				C1972.5,888.8,1967.6,875.3,1961.9,877z M2456.4,1064.2c-5.1-0.9-8.3-2.9-8.8,3.7c-0.5,6.7,11.9,26.2,17.9,25.7
				c5.1-0.4,12.7-6.9,11.9-11.6C2474.6,1066.2,2464.3,1064,2456.4,1064.2z M1307.2,1263.1c1.6-5.6,0.8-11.6-0.8-9.4
				c-4.3,6.3-13.9,3.7-14.1,15.7c-0.2,9.7,3.9,21.6,9.5,20.6C1317.2,1287.3,1308.9,1272,1307.2,1263.1z M1011.7,1094.5
				c2.3,3.5-3.4,11.8,4.2,13.6c8.4,2,7.5-7.4,7.9-13.3c0.9-13.9-4.3-27.1-3.1-41.3c0.2-2.3-2.3-5.7-4.6-5.5c-3,0.3-2.7,4.4-2.5,7.1
				C1014.6,1068.2,1012.4,1080.8,1011.7,1094.5z M2235.3,1350.7c-2.6,3.1-7.1-7.7-8.6,1.1c-2.1,12.4,10.3,29.7,19.7,29.2
				c2.8-0.2,3.8-1.2,4-5.1C2251.1,1364.4,2243.5,1351.3,2235.3,1350.7z M2515.5,1143.8c-0.3-5.1-0.5-9.1-0.7-13.1
				c-0.1-3.4,0.6-8.2-3.2-7.8c-3.3,0.4-6.3,4.3-6.8,8.3c-0.7,6.3,2.5,3.8,5,2.8c2.5-1.1,3.1,1.5,3,4.3c-0.2,2.9-0.5,5.9-0.8,8.8
				c-5-7.7-8.9,6.4-13.7,1.2c-2-2.1-0.6-10.4-5.1-7.1c-3.6,2.6-1.4,9.3-1.5,14.1c-0.1,3.7,2.3,6.9,4.8,7.6c3.4,1,2.2-3.9,3-6.2
				c3.6-9.7,5.8,1.5,8.8,1.8c1.6,0.1-3.8,10.1,3.1,8.9c6-1.1,1.9-8.3,3-12.5C2515.2,1151,2515.2,1146.7,2515.5,1143.8z
				 M2472.1,922.6c3.1,2.3,6,5.2,7-0.3c1-5.3,1.5-11.8-1.1-16.2c-2-3.4-5.9-3.1-9.2-1.5c-4,1.9-6.2,8.8-11.3,7.4
				c-2.4-0.6-5.8-4.8-6.2,3.1c-0.3,5.7,0.2,9.7,5.5,10.3C2462.5,926.2,2467.9,927.7,2472.1,922.6z M158.8,1265.4
				c-0.2-8.7-7-7.3-7.2-14.2c-0.2-9.4-6.8-10.1-9.2-7.5c-4.2,4.8-12.2,9.5-8.4,20c2,5.6,6.4,12.2,12.7,6.6
				C150.5,1267.1,154.5,1264.9,158.8,1265.4z M2566.7,1545.2c5.2-21.7-4.9-19.9-13.9-20c-5-0.1-6.7,6-9.9,9.4
				c-2.1,2.2-5.2,3.2-3.8,7.4c1.4,4,4.4,4.6,7.6,3C2553.4,1541.7,2560.2,1541.9,2566.7,1545.2z M2502.3,940.1
				c-1.7-0.6-4,2.4-6.3-1.8c-1.5-2.7,9.2-2,0.4-4.2c-2.8-0.7-5.2,0-4.4,5.7c0.3,1.7-1.2,4-0.9,5.7c1.6,9.5,5.1,15.9,13,15.2
				c5.3-0.5,11.6-0.8,10.9-10.2C2514.5,942.5,2509.6,937.4,2502.3,940.1z M430.5,930.5c0.2-12-0.7-14-5.5-14.2
				c-8.6-0.4-12.8,9.3-12.7,28.5c0,4.7,1.9,5.2,4,4.5C424,946.9,431.6,944,430.5,930.5z M2577.9,1361.1c-5.3-1.4-10.1-0.4-11.5,7.6
				c-0.9,5-0.3,10.5,3.4,14.2c4.9,4.8,10.1,12.7,16.2,7.9c8-6.4-2.7-15.7,0.2-25.2C2588,1359.6,2581,1361.9,2577.9,1361.1z
				 M1183,893.6c5.1,3.2,7.3-3.9,10.8-5.5c3.8-1.8,5.6-4.6,4.9-9.9c-0.7-5.7-4.7-8-7.6-9.9c-1.4-0.9-6.9,1.5-7.4,6.8
				c-1.4,14.6-5.9,14.5-12.8,6.2c-1.5-1.8-3.8-1.8-4.7,0.9c-0.9,2.7,0.4,5.4,2.4,5.6C1174,888,1178.4,891.7,1183,893.6z
				 M1726.7,1251.9c1.1,4.9-3,16.1,4.3,16.2c5,0,8.6-9.6,9.5-18c0.3-3,0.9-6.3,0.4-9.1c-1-5-1-13.4-4.9-13.4
				c-4.9,0.1-9.3,5.7-10,13.8C1725.7,1244.4,1726.4,1247.5,1726.7,1251.9z M1007,1088.6c0.1,0,0.3,0,0.4,0c0-11.2,0-22.5-0.2-33.7
				c-0.1-3.7,1-9.3-3.7-8.8c-4.2,0.4-4.5,6.8-3.3,9.3c4.9,10.1,4.4,21.3,3.4,31.9c-1.3,13.2,6.5,26,0.6,39.2c0,0,1.2,1.1,1.9,1.7
				c1.9,1.7,1-0.2,1-1.4C1007.1,1114.1,1007.1,1101.4,1007,1088.6z M497.6,890.1c3.2,0,6.8,1.4,8.1-0.4c1.8-2.4,8.2-5,2.9-10.9
				c-7.1-7.8-15.3-11.7-24.6-9c-1.9,0.6-3.9,1.2-3.4,4.4c0.5,2.8-0.4,8.2,1.7,8C488.7,881.8,491.6,893.3,497.6,890.1z M2737.2,986.5
				c0.6,5.5,1.1,11,1.7,16.5c0.3,3.2,1.1,5.9,4,5.9c2.5,0,4.4-1.2,5.3-4.3c2.5-8.8,4.8-17.8,7-26.8c0.8-3.5-0.4-5.4-3.1-6.5
				C2745,968.6,2737.6,976.2,2737.2,986.5z M2424,1495.5c0.9-25.9,5.8-51.6,2.6-78.3c-0.4-3.7-0.6-5.5,2.1-6.8
				c4.3-2.1,2.8-6,1.2-9.7c-1-2.3-2.4-4.4-4.4-2.8c-2.8,2.2-1.8,6.3-1.8,9.7C2423.8,1436.9,2423.9,1466.2,2424,1495.5z M789,1032
				c-0.2,5.3-0.5,7-0.2,8.3c1.2,5.3-2.4,14.9,4.5,15.1c6.9,0.2,7.9-7.8,8-16.4c0.1-6.3-2.2-12.5,0.2-19.4c1.4-4.1-2.4-7.7-5.5-8.5
				c-3.4-0.9-5.7,1.8-6.1,6.5C789.3,1023.7,789.1,1029.8,789,1032z M442.7,1383.3c3.8-9.3,9-18.1,1.3-27.4c-0.1-0.1,0.5-1.9,1-2.4
				c4.4-4.9-0.2-6.6-1.8-9c-2-2.9-5-2.8-6.8-0.5c-2,2.4-4.8,6.2-2.5,10.4c2.4,4.3,4.2,10.3,3.1,13.7
				C433.4,1378.8,439.3,1379.7,442.7,1383.3z M1178.3,1263.7c0.8-15.2-9.8-12.7-15.1-19.5c-2.5-3.3-6.9-2.5-8.8,1.2
				c-1.4,2.8-3,6.6-1.2,12c4,11.6,9.9,14.3,17.4,9.4C1173.8,1264.8,1176.6,1263.7,1178.3,1263.7z M1433.9,840.8
				c-21.1-10-42.9-16.2-65.4-16.1C1390.2,830.4,1411.2,842.3,1433.9,840.8z M2394.8,925.2c-41.6,1.3-83.1-5.8-124.7-1.7
				C2311.7,923.2,2353.2,937.2,2394.8,925.2z M2615.1,1401c1.1,4.5-0.8,11.1,3.7,11.8c3.5,0.6,5-5.3,7-8.7c0.8-1.3,0.5-3.9,0.5-5.9
				c-0.3-10.6-4.3-21-0.3-32c1.1-3.1-0.4-7.2-3.6-8c-3.3-0.8-5.9,0.8-6.8,5.6c-0.9,4.9-2.4,11.6,0.6,14.5c5.8,5.6,3.5,9.1,0.2,14.4
				C2615.2,1394.7,2615.5,1398.5,2615.1,1401z M515.5,1417.4c8.5-21.8,4.4-45,4.9-67.6c0.1-2.3-1-4.2-2.6-3.9
				c-3.1,0.6-2.5,4.3-2.5,7.2C515.3,1374.6,515.4,1396,515.5,1417.4z M353.9,1526.4c0.6-1.4,1.1-2.7,1.7-4.1
				c-7.1-2.3-10.2-11.1-14.4-17.3c-8.5-12.4-13.3-8.7-19.1,4.2C332.9,1515,343.4,1520.7,353.9,1526.4z M233.5,36.9
				c2.3,15.5-3.1,27.7-1,42.4c8-7.6,14.8-14.5,20.4-23.3c-2.8-3.4-5.2-6.6-7.9-9.2C242.1,43.8,238.9,41.4,233.5,36.9z M863.7,946.2
				c12.8,3.5,23.7,1.3,35.1,1C888.5,924.3,881.7,923.8,863.7,946.2z M293.1,79.5C293.1,79.5,293.1,79.5,293.1,79.5
				c0.1-4.1,0.7-8.4-0.1-12.1c-1-4.6-1.1-10.8-4.9-12.7c-4.6-2.2-5.8,4.9-8.4,7.9c-3.4,4-1.4,7.8,1,11.6c3,4.8,3.2,11.2,4.5,17
				c0.6,2.6,0.8,7,3.4,6.3c2.2-0.5,4.8-2.7,4.6-7.3C293,86.7,293.1,83.1,293.1,79.5z M1228,743.9c11.8,11.9,23.7,20.4,37.1,24
				c2.5,0.7,4.7,3.2,5.8-2.2c0.8-4,0.3-8.1-2.3-9C1255.1,752,1241.5,748.1,1228,743.9z M886.7,622.2c-6.3-13.7-6.3-13.7-18.9-12.8
				c5.6,15.2,18.2,15.4,26.3,24.6c0.6,0.7,2.8,0.2,3.1-0.6c2.6-6.7,4.4-14.2,3.7-21.7c-0.5-5.2-4.8-2.4-7.4-3.1
				C886.5,606.8,888.1,615.1,886.7,622.2z M1208.2,848.9c0,1.4,0,2.8,0,4.2c16.3,4,32.9,1.9,49.3,3.1c2.2,0.2,4.6-0.1,4.5-4.5
				c-0.1-2.7-1.8-2.9-3.2-2.9C1241.9,848.7,1225,848.8,1208.2,848.9z M965.2,1151c-5.5-5.3-11.5-7.3-7.6-18.1
				c2.6-7.3-6.6-4.1-7.4-10.2c-2.7,1.4-6.1,2.5-5.8,7.8c0.5,8.3-0.4,18.1,2.6,24.3c3.7,7.8,8.2-1,12.3-3.6
				C960.4,1150.4,961.9,1151,965.2,1151z M289.3,232.7c0.7-9.5,0.5-16.5-1.5-23.3c-0.8-2.6-0.5-6.5-3.5-6.7c-2-0.1-2.8,2.7-4.1,4.4
				c-6.3,8.3-4.8,16.9-0.9,25.8c2.5,5.7,1,18,6.2,17.1C292.1,248.7,288.4,237.2,289.3,232.7z M323.6,1004.8
				c-14.5,15.1-14.5,15.4,3.4,45.5C330.3,1034.5,330.3,1019.2,323.6,1004.8z M2513.8,1080.7c1.6-8.7,0-14.6-6.4-14.3
				c-6.7,0.3-3.3,8.3-3.9,13c-0.5,3.5,0.5,7.9-0.8,10.4c-4.7,9.5,3.9,9.7,5.2,15.1c0.6,2.6,4.3,4.1,5.8,2.3
				c2.7-3.2,1.2-8.5,1.2-12.9C2514.8,1089.6,2514.1,1084.8,2513.8,1080.7z M1343.6,1285.4c-0.1-15.2,0-30.3-0.2-45.5
				c-0.1-4.8-1.2-9.3-5.9-8.4c-2.5,0.5-4.3,1.9-3.5,5.9C1337.2,1253.3,1335.7,1270.6,1343.6,1285.4z M254.8,267.6
				c4.7-11,3.5-21.7,3.5-32.2c0-4.8-0.8-7.9-5.2-4.7c-15.6,11.3-16.2,19.3-2.2,32.8C252.1,264.6,253.1,265.9,254.8,267.6z
				 M804.8,931.6c2.5-13.5-3.1-16.2-9.2-16.1c-7,0.1-4,8.7-4.4,13.7c-0.4,4.8,0.5,9.9,0.7,14.9c0.1,3.1,0.8,4.4,3.2,4.5
				c5.1,0.2,10.4-7.4,9.7-14.1C804.7,932.9,804.8,931.3,804.8,931.6z M1442,1225.8c-0.2,0.1-0.8,0.5-1.4,0.7
				c-4.9,1.3-5.4,5.9-4.6,11.6c0.4,3,2,3.9,4,4.2c5.3,0.9,4.1-5.3,5.3-9c1.7-5.3,1.8-10.9,1.6-16.5c-0.2-4.2,1.2-10-3.5-11.2
				c-4.8-1.2-9.9,0.1-13.6,4.1c-3.9,4.2,2,6.7,2.4,11C1432.9,1228.2,1438.6,1222,1442,1225.8z M339.6,1225.3
				c4,22.1-6.9,47.4,7.7,67.4C343.6,1270.4,349.4,1246.2,339.6,1225.3z M1839.2,1178.4c1.1,0,2.1,0.1,3.2,0.1
				c-0.1-29.7-0.2-59.4-0.3-78C1844.5,1119.1,1831.4,1148.5,1839.2,1178.4z M1725.4,1437.4c3.5,5.5,7.1,8,11.9,7.7
				c7.2-0.4,14.4-0.3,21.6-0.1c3.2,0.1,7.3,1.8,7.8-4.8c0.5-7.7-5.1-8.4-7.7-7.1c-7.6,3.8-14.7-0.4-21.9-1
				C1732.8,1431.8,1729.1,1432.8,1725.4,1437.4z M2561.3,729.3c4.5,7.8-3.2,22.1,4.2,24.9c4.1,1.5,13-4.7,16.6-13.8
				c0.9-2.2-15-20.1-17.3-19.5C2561.6,721.8,2562,726,2561.3,729.3z M853.8,946.2c0-12.2,0.1-24.4-0.2-36.6
				c-0.1-4.1,2.2-11.3-2-11.8c-4.3-0.5-7.4,4.8-8.6,11.9C840.4,924.9,847.6,935,853.8,946.2z M285.7,382.3c0-5.5,0.2-9.6-0.1-13.5
				c-0.4-4.5-1-9.6-5.1-10.4c-4.9-0.9-5.4,5.4-7.1,9.6c-2.4,6,1,10.7,2.3,15.6c1.6,6.1,0.6,17.9,6.3,16.8
				C289.1,399.1,284.6,387.7,285.7,382.3z M2710.9,1449.4c-5.7-17.7-16.8-11.2-26.4-10.7c-3.2,0.2-5,3.8-4.5,8.7
				c0.4,4.8,3,5.5,5.6,5.4C2694.1,1452.6,2703,1455.4,2710.9,1449.4z M208.9,312.8c1.6-9.2-3.7-13.7-3.6-20.4
				c0-4.2-2.2-9.7,2.6-11.3c2.2-0.7,4.2-1.6,5.6-4.1c1.3-2.4,2.4-4.8,4.3-8.6c-9.3,6.7-20.1,9.9-21.8,25
				C194.8,304.5,205.4,303.8,208.9,312.8z M704.3,1002c-4.9-4.8-3.5-14.7-4.3-14.1c-7.6,5.4-14.7-3.8-22-0.9
				c-1.6,0.6-2.7,1.3-2.7,3.7c0,2.2,1.3,3.1,2.4,4.4C684.6,1004,693,1003.8,704.3,1002z M2485,1055.6c1.2,16.3,2.3,32.5,3.5,48.8
				c0.2,2.2,0.9,4.1,2.5,4.2c0.9,0.1,2.6-0.8,2.7-1.5c1.3-12.3,2.5-24.5,3.5-36.9c0.1-1-1.3-3.3-1.9-3.2
				C2489.4,1067.6,2488.7,1059.8,2485,1055.6z M2717.4,1038.6c-5.9-16-16.9-11.5-25.8-14c-2-0.6-5.7-2.7-6,3.1
				c-0.3,4.2,3.2,7.9,4.7,7.5C2699.4,1032.9,2707.3,1045.2,2717.4,1038.6z M1517.5,1033.8c-1.3,13.7-1.1,26.9,0.9,39.7
				c0.8,5-0.5,15,5.6,13.9c4.6-0.8,1.9-9.5,2.4-14.6c0.7-6.5,0.8-13.2,0.3-19.8C1526.2,1045,1524.4,1037.4,1517.5,1033.8z
				 M237.3,865.6c-10.5-0.5-18.7-1.5-26.9,1.7c-2.6,1-5.1,3.5-5.1,7.1c0,3.9,2.9,5,5.4,6.2C221.7,885.9,229.6,880.6,237.3,865.6z
				 M1232.4,870.9c-4.5,0.9-8-0.4-10.7,3c-1.4,1.8-0.5,4.1,0.5,5.1c5.8,5.5,11.1,13.1,19.4,9.4c3.7-1.7,5.1-4.6,5.6-9.6
				c0.9-7.9-3.5-4.3-5.6-5.1C1238.3,872.2,1234.7,871.5,1232.4,870.9z M226.2,379.4c7.6-5.8,14.7-10.6,17.2-19.7
				c1.2-4.3-6.1-8.8-9.7-12.8c-4-4.5-8.1-5.7-7.7,5.2C226.4,360.2,226.2,368.4,226.2,379.4z M856.8,786.3
				c1.2-32.8,1.2-32.8-8.5-40.2C844,772.4,845.9,781.5,856.8,786.3z M2252.3,1250.8c-3.6-7.2-5.7-16.6-13.3-17.8
				c-4.9-0.8-6.4,4.7-6.1,10c0.6,8.5,1.8,17.6,9.8,18.5C2247.7,1262.1,2250.5,1257.6,2252.3,1250.8z M410.7,1350.9
				c4,2.9-4.1,16.1,4.5,16.2c7.9,0.1,5.1-12.1,6.3-19.1c0.6-3.3-0.2-7.1-0.4-10.6c-0.3-5.5-3-6.3-6.2-6.2c-3.3,0.1-4.4,2.6-4.3,6.9
				C410.8,1341.6,410.7,1345.2,410.7,1350.9z M1574.4,984.5c7.5-2.6,9.1,2.5,11.5,7c3.3,6.3,8.6,5.5,13,3.6c2.7-1.1,4.8-5.4,7.2-8.2
				c-2.1-2.8-3.9-7.4-6.3-8.1C1591.9,976.4,1583.8,974.9,1574.4,984.5z M1831.6,1275.6c2.1,12.1-1.8,22.4-1.4,33.4
				c0.1,2.8-0.9,6.6,1.5,8.1c3,1.9,4.7-2.2,6.2-4.6C1842.2,1305.2,1838.7,1282.9,1831.6,1275.6z M1644.6,1481.8
				c0.4-5.4-0.3-10.4-4-13c-1.9-1.3-3.9,0.8-3.4,3.8c2.4,15-4.5,18.8-12.7,21.2c-1,0.3-2,1.2-3,1.8c0.6,0.3,1.1,0.5,1.7,0.9
				C1634.6,1505.6,1644.7,1498.7,1644.6,1481.8z M2008.4,1142.4c9.4,16.1-2.5,37.5,7,53c5.3-8.6,7.9-17,2.3-28.3
				C2014,1159.7,2017.9,1146.1,2008.4,1142.4z M416.4,1274.5c-4.9,2-10.6,2.4-10.4,12.5c0.1,6.6,1.4,14.1,6.8,13.3
				c5.6-0.9,12.9-1.6,13.2-14C426.3,1276.3,420.6,1276.9,416.4,1274.5z M1218.5,988.4c4,5,8.6,7.6,13.4,9.6c3.7,1.6,6.5-0.3,8.3-4.6
				c2.6-6.1-1.9-7.9-4.1-10.2C1229.6,976.5,1224,980.5,1218.5,988.4z M2454.3,1276.8c7.9,0,15.2-0.1,22.4-0.1c2.8,0,5.9-1.6,8,2.6
				c4.3,8.5,4.3,8.5,11.7,5.5c-10.7-13.8-25.2-4.3-37.6-10c1.3-4.4,3.4-3.3,5-3.3c2.3,0,4.3-0.3,5.1-4.1c0.4-2.2,0.3-4.5-1-5.3
				c-2.4-1.4-3.9,1.7-5.7,3.2C2459,1267.8,2455.9,1270.3,2454.3,1276.8z M667.7,1413.5c8.9,3.6,15.5-1,21.5-8.2
				c1.3-1.5,2.6-4.2,1.5-6.7c-0.6-1.3-2.3-2.3-3.6-2.3C677.9,1395.8,667,1391.7,667.7,1413.5z M225.9,1231.9c2.3,5.8,6.2,5,9,6.9
				c13.2,9,13.2,8.8,14.7-9.3c0.3-3.2,2-9.4-1.6-7.2C240.9,1226.6,232.9,1226.9,225.9,1231.9z M882.9,1309.5
				c5.1-4.8,10.4-13.2,17.6-4c2.3,3,5,4.5,8,2.2c0.6-0.5,1-2.8,0.7-3.9c-1-4.7-3.8-5.6-6.9-5.9c-6.4-0.6-13.6,0.7-14.7-12.4
				c-0.1-0.7-4.7-0.2-4.4,0.4C886.5,1294.1,875.1,1301.2,882.9,1309.5z M2713,1485.7c-0.1,1.6-0.3,3.1-0.4,4.7
				c11.8,5.6,24.2,2.3,36.3,2.7c1.6,0,2.8-1.6,2.3-4c-0.6-2.9-2.5-3.5-4.4-3.5C2735.6,1485.6,2724.3,1485.7,2713,1485.7z M941,647.5
				c-5.3,1-4.4,7.3-3.8,10.5c1.2,6.2-2.9,17.4,6.4,17.3c8.3-0.1,5.4-10.9,6.2-16.5C951,650.8,944,651.3,941,647.5z M2696.6,826.7
				c-10.5-10.6-14.2-8.7-16.9,8.2c-0.6,3.4-1.2,6.9-1.4,10.4c-0.2,3.6-2.2,8.7,2.5,9.5c3.8,0.6,4.6-3.6,5.5-7.7
				C2688,838.7,2687.5,828.2,2696.6,826.7z M335.2,1130.9c-3.3-4.6,3.2-16.4-4.1-15.1c-7,1.2-10.3,14.3-11.6,22.9
				c-1.1,6.9,8,8.6,11.9,8.2C339.1,1146,333.3,1136.4,335.2,1130.9z M2552.6,971.1c0.9,1.9-2.8,10.3,2.6,10.3
				c6.6-0.1,2.6-8,2.9-12.9c0.4-6.3,1.8-12.3,5.7-16.8c1.4-1.6,2.5-5.6,2.1-7.9c-0.5-3.2-3.4-3.2-5.6-3.2c-2.9,0-5.1,1.5-5.3,5.9
				C2554.7,954.2,2551.3,961,2552.6,971.1z M618,1393.4c-2.8,2.4-9-3.9-8.4,6.2c0.4,6.3-0.7,13.9,7.5,14.8c9.8,1.1,7.9-9.7,8.7-15.6
				C627,1390.3,620.9,1395.4,618,1393.4z M1157.8,1033.3c5.7,0,11.3,1.3,11.6-8.2c0.2-6.1-2.6-11.5-7.5-9.4
				c-4.3,1.8-11.5-3.1-12.4,7.3C1148.8,1031.4,1153.3,1034.2,1157.8,1033.3z M2420.8,1503.8c-3.8,1.5-2.8,5.1-3,7.8
				c-0.3,3.5-0.1,7.2-0.5,10.6c-2,15.6,2.4,20.4,13.2,15.3C2421.8,1529.9,2424.4,1514.7,2420.8,1503.8z M2462.9,938.1
				c-0.5,6,9.3,5.1,4.9,14.5c-0.6,1.4,4.3,5.1,8,4c3.6-1.1,4.9-3.3,4.7-8.2C2480.1,936.6,2475,933.6,2462.9,938.1z M2463.9,1014
				c5.8,0.4,12,0.1,12.3-7.4c0.3-8-5.6-13.7-11.2-14.1c-5-0.4-9.4,4.5-12.6,10.4C2456.3,1006.8,2457.4,1017.2,2463.9,1014z
				 M248.2,426.5c-7.2-5.5-13.5-2.5-19.3-3.9c-5-1.2-3.6,9.2-5.5,14c-0.9,2.3-0.2,6.3,2.5,7.2c3.8,1.2,9.1,0.2,9.2-4.8
				C235.4,427.3,241.7,428.5,248.2,426.5z M1309.2,1330.6c-6.9,3.1-13.9,6.2-21.7,9.7c4.9,0.3,9.1-0.4,10.4,7c0.7,3.7,1.7,7,5.3,5.2
				c3-1.6,7-2.2,7-8C1310.2,1340.1,1311.9,1335.3,1309.2,1330.6z M883,734.7c9.4,2.2,16.7-0.1,22.8-5.3c4.9-4.2,3.2-13,2-19.6
				c-0.7-4.2-4.2-1.9-5,0.6C899,721.7,890.2,724.6,883,734.7z M240.5,601.2c4.9,1.1,7.5-2.7,8.9-5.2c2.7-4.7-2.3-4.8-3.8-6.7
				c-4.5-6-9.4-11.3-14.3-16.7c-2.2-2.5-4.7-3.2-7-0.6c-1.8,2-3.5,4.8-1.8,7.8C227.9,589,234.9,595.3,240.5,601.2z M305.5,756.8
				c-2.1-10-8.7-6.5-12.9-9.2c-3.2-2-8.5,0.1-7.3,6.9c1.1,6.2,1.5,16.4,8,16C298.5,770.1,301.9,762.3,305.5,756.8z M197.2,676.4
				c-0.3-4.7,1.1-10.1-3.3-11.9c-3.8-1.5-4.8,3.2-6.4,6.5c-1.2,2.4-3.4,4.2-4.2,6.8c-2.1,7.3-1.7,14.7,3.9,18.3
				c6.1,3.8,6.8-5,8.8-9.5C197.4,683.8,196.9,679.6,197.2,676.4z M990.2,1040.1c0.3,10.2-0.8,19.1-6,25.7c-2.6,3.4-0.6,6,1.1,7.1
				c2.8,1.9,3.4,9,7.8,6.4c3.6-2.1-0.7-5.4,0.2-7.4C997.9,1060.7,993.8,1051,990.2,1040.1z M171.7,920.9c5.9,0.1,9.8-0.6,9.9-9.4
				c0.1-7.8-2.5-9.4-7.3-9.8c-5.9-0.4-11.3,4.1-11.9,10.1C161.7,919.9,168.3,920.3,171.7,920.9z M1119.4,974.4
				c-3.7,3-10.7,3.5-12.1,12.8c-0.7,4.9,3.3,9.1,6.4,8.3c5.5-1.4,9.2-7.7,11.1-14.7C1125.6,977.7,1124.5,973.5,1119.4,974.4z
				 M2021.8,1403.2c-6,6.2-14.6,8.7-10.1,23.9c2.3,8,5.5,6.4,9.2,6.6c1.7,0.1,3.9,0.6,3.6-3.5c-0.4-7.8-6.1-1-9-7
				C2026.6,1421.4,2025.2,1412.8,2021.8,1403.2z M1476.2,1261.7c-1,1.7-1.7,3.5-2.8,4.5c-4.7,4.5-3.1,10.4-2,16.2
				c0.4,2,2,4.2,3.2,2.7c2.9-3.6,3-8.8,2.8-14.1c-0.2-4.5,0.5-9,0.1-13.4c-0.6-6.5-4.7-4.4-7.6-5.4c-1.9-0.7-2.9-0.6-2.8,2.4
				c0.2,5.8,3.8,3.3,5.8,4.4C1473.9,1259.5,1474.8,1260.5,1476.2,1261.7z M1754.5,1218.3c4.3,1,8.2,0.7,8.1-6.5c0-8-4.4-8.8-9.1-9.6
				c-6.7-1.2-6.6,5.2-7.1,11C1746.1,1217.6,1746.4,1217.7,1754.5,1218.3z M869.4,642c9-0.4,11.3-1.4,11.4-5.2
				c0.1-2.8-13.4-18.5-15.4-17.6c-5.1,2.3-3.8,9.1-4.4,14.2C860,643,864.8,642.6,869.4,642z M419.4,865.9c3.9,10.4-5.8,8.7-4.7,16.4
				c0.6,4.4,0.8,10.5,5.6,11.3c6.1,1.1,5.8-7.7,8.9-11.3C425.9,878.4,427.8,869.4,419.4,865.9z M2606.9,1317.3
				c0.2,1.6,0.4,3.2,0.6,4.8c6.7,0,13.4,0.3,20.1-0.2c5.2-0.3,4.8-6.2,4.9-11.3c0-3.6-0.8-6.4-3.8-4.9
				C2621.3,1309.4,2614.1,1313.4,2606.9,1317.3z M325,1171.2c0.5-12-8-10.6-12-16.5c-4.3-6.5-6.3,6.8-10.5,8.8
				c-1.9,0.9-3.7,4.7-2,7.5c2.3,3.9,5.8,2.8,9.1,2.2C314.9,1172.2,320.3,1171.7,325,1171.2z M155.2,1084.4
				c-10.7-13.6-13.7-14.4-24-10.4c-1.2,8.3,13.7,4.8,6.1,18.8c-0.6,1.1,1,4.9,3.2,5c2.6,0.1,3-2.7,2.7-5.3
				C142.4,1082.6,146.3,1080.7,155.2,1084.4z M2426.7,1386.3c3.6-5.6,2.5-12,7.3-17.9c4.2-5.1,2.3-16.5-0.6-24.5
				c-1.2-3.4-4.3-3.4-4.6,0.7C2427.7,1358.4,2427.3,1372.4,2426.7,1386.3z M710.9,1266.3c1.2,5.9-4.1,15.5,5.5,15.9
				c5.2,0.2,6.5-3.6,6-9.5c-0.7-8,1.7-19.5-6.7-20.9C706.8,1250.4,713.2,1261.8,710.9,1266.3z M2835.4,1068.7
				c-0.3,15.8-2.6,30.7-6.3,45.4c-0.7,2.8-1.5,7.6,2.3,8.3c3.2,0.6,6.6-2,6.3-6.4C2836.5,1100.3,2840,1084.4,2835.4,1068.7z
				 M130.8,1459.5c6.1,1.2,11.9,11.7,15.7,6c3.3-4.9,1.3-16.7,1.8-26.5C139.7,1443.4,136,1451.6,130.8,1459.5z M288.5,535.7
				c0.6-7.5,0.4-14.9-1.4-22.3c-1-4-2.1-8.2-5.3-8.7c-5.7-0.8-3.5,8.5-6.9,11.4c-2.3,2-3.5,6.5-0.6,8.7
				C278.9,528.4,281.6,536.8,288.5,535.7z M1503.7,1481.7c0-4,0.2-6.6-0.1-9c-0.4-4,1.6-10.4-3.7-10.3c-5,0.1-4.8,6.2-5,11.3
				c-0.1,2-0.1,4.1,0.3,6c1.5,6.8-1,19.5,4.6,19.4C1506.8,1498.9,1501.9,1486.7,1503.7,1481.7z M512.7,1004.4
				c1.8-7.3,10.7,3.7,10.2-7.7c-0.1-2.1,2.7-13.3-5-12.3c-6,0.8-11.6,3.1-12.8,12.2C504.4,1002,508.9,1003,512.7,1004.4z
				 M2512.1,1382.9c1.3,0.3,2.5,0.5,3.8,0.8c-4.6-13.3,5.1-20.8,5.5-32.1c0.1-3.3,1.6-7.4-1.9-9.1c-3.5-1.8-3.5,7.6-7.5,4.8
				C2512,1359.1,2512.1,1371,2512.1,1382.9z M2584.8,901.9c-6.8-13.2-14.8-12.3-22.6-12.1c-3.3,0.1-5.7,1.7-5.4,7.1
				c0.3,5.2,3.1,5,5.6,5C2569.3,902,2576.1,901.9,2584.8,901.9z M2740.4,476.8c18.8,0.9,37.4,7.7,58,2.9
				C2778,473.8,2759.1,477.8,2740.4,476.8z M2558.4,1026.9c-2-6.4-1.7-12.8-1.5-19.3c0.3-6.9,5.2-18.7-2-19.7
				c-7.6-1.1-4,12-4.4,18.5C2550.1,1014,2546.4,1025.9,2558.4,1026.9z M772.1,1335.5c7.4,1.1,14.6,2.5,20.9-5.1
				c2.6-3.1,4.5-6.5,1.7-10c-3.2-3.9-7.7-2.9-11.7-2.4c-3.4,0.5-4.9,4.4-4.4,9C779.4,1334.5,776.1,1335.5,772.1,1335.5z
				 M907.1,1262.9c3.5-18.4,3.5-18.3-5.4-27c-1-1-0.2-5.2-3.8-3.9c-1,6.8-0.8,13.5,0.2,21.5c2.7-2,4.8-3.5,7.4-5.5
				C906,1252.5,906.4,1256.6,907.1,1262.9z M1551.4,1487.2C1551.4,1487.2,1551.5,1487.2,1551.4,1487.2c0.1-4,0.1-8.1,0-12.1
				c-0.1-3-1.5-4.3-3.6-4.3c-1.9,0-3.9,0.5-4.1,3.6c-0.6,8.4-1.3,16.9-1.3,25.4c0,2.7,2.5,2.9,4.4,3c2.6,0.1,4.3-1.6,4.6-5
				C1551.7,1494.3,1551.4,1490.8,1551.4,1487.2z M2738.1,541.6c20.7,9.1,38.6,4.4,56.5-0.2C2776.8,541.5,2758.9,541.5,2738.1,541.6z
				 M783.8,459c20.1,8.7,38.4,2.9,56.8-0.2C822.3,458.8,804,458.9,783.8,459z M1506.6,1280.1c-1.7,11.6-1.2,22.3,2.6,32.7
				c1.2,3.2,2.9,5,5.2,3.5c2-1.3,1.7-4.2,0.8-6.6C1511.8,1300.5,1514.3,1288,1506.6,1280.1z M2738.1,1342.1c-6.2,3.1-10.9,15-9.5,23
				c0.8,4.5,2.9,5.2,5.7,5.4c3.7,0.2,5.5-2.3,5.3-7.2C2739.2,1356.3,2738.6,1349.2,2738.1,1342.1z M1120.7,1031.1
				c1.1,3.2-1.9,11.1,3.3,9.8c4.9-1.2,8.5-6.8,8.2-15.7c-0.1-4.1,0.3-7.4-3.3-7C1123.7,1018.8,1119.8,1021.9,1120.7,1031.1z
				 M1206.3,903.1c-2.5,14.3-3.2,28.4,0.4,42.4C1209.6,931.3,1209.1,917.1,1206.3,903.1z M307.5,1372.1c2-3.1,8.3,1.1,7.9-5.4
				c-0.5-9.5-6.8-12.2-13-13.1c-3.6-0.5-7.1,0.6-6.9,6.8C296,1369.9,301.6,1370.8,307.5,1372.1z M807.1,731.3
				c8.7,4.3,17.7,5.7,26.6,2.2c4.7-1.9,3.8-8.6,2.3-16.1C828.7,733.9,816.5,724.4,807.1,731.3z M228,1527.1
				c-13.1-14.3-23.9-12.7-36.4,0.1C204.3,1527.2,215.2,1527.2,228,1527.1z M350.9,855.8c13.4,5.8,27.1,3.3,40.6,3.9
				c1.8,0.1,3.9-0.7,3.8-3.9c-0.1-3.5-2.3-3.2-4.1-3.1C377.8,853.7,364.4,854.7,350.9,855.8z M309,1516.4c-3.8-5.2-8.9-7-14.1-8.3
				c-3.6-0.9-3.3,3.6-2.4,5.8c2.3,5.4-0.3,16.5,7.5,15.1C304,1528.3,307.8,1524,309,1516.4z M696.6,462.5c0,1.7,0,3.4,0,5
				c15.7-0.1,31.5,1.2,47-5.2C727.9,462.3,712.2,462.4,696.6,462.5z M1225.8,1182.4c0.9,5.6-2.4,14.9,6,15.4
				c3.8,0.2,6.2-2.2,5.7-8.3c-0.7-8.5-0.6-19.7-7.1-21.8C1225.4,1166.2,1226.3,1176.2,1225.8,1182.4z M2314.6,1509.8
				c3.1,5.5,1.8,17.8,8.6,16c5.7-1.5,2-8.4-0.4-12.8c-0.3-0.6,0.1-2.9,0.7-3.6c2.8-3.2,1.9-6.4-0.2-8.9c-0.8-0.9-3.5-0.9-3.9,0
				C2317.9,1503.7,2314.2,1504.8,2314.6,1509.8z M595.5,1356.8c0.6-5.8-1.2-9.5-5.3-10.3c-3.4-0.7-7.2,0.3-7.3,6.3
				c-0.1,8.1-2.9,10.7-8.1,10.5c-1.5,0-4.2-0.4-3.2,3.7c0.4,1.4,2.7,3.5,3.2,3.1c5.8-4.7,12.2-5.1,18.5-6.3
				C595.7,1363.3,596.8,1360.5,595.5,1356.8z M630.9,405.4c-18.3-4.8-36.6-1.2-57.6-1C594.3,411.3,612.6,408.5,630.9,405.4z
				 M2755.1,1012.6c10.9-9.4,11-21.6,13-32.4c0.4-2.2,3.7-5.1,0.5-7.1c-2.4-1.5-4.9-0.6-5.8,4.1
				C2760.7,987.6,2758.3,998.1,2755.1,1012.6z M2430.3,1331.9c6.4-11,4.3-23.8,5.1-36c0.1-2.2,0.7-5.6-1.8-6.2
				c-3.2-0.7-3.4,2.9-3.4,5.9C2430.1,1307.7,2430.2,1319.8,2430.3,1331.9z M511.3,1025.8c7.2,1.3,12.3-0.5,12-8.8
				c-0.4-10.2-6.8-2-10.4-4.6c-3.8-2.7-8.2,0.4-8,7.9C505.1,1028.4,510,1025.3,511.3,1025.8z M859.9,1290.1
				c-0.1-7.4,0.8-15.6-5.2-15.7c-7.3,0-3.5,9.3-3.4,13.8c0.1,6.8-2.9,19,2.5,19.6C863.2,1309,855.9,1294.2,859.9,1290.1z
				 M1526.1,1478c0.9-6.2,3.6-13.3-3.1-13.4c-6.4-0.1-3.6,8.2-4.2,13.1c-0.2,1.5-0.1,3.1,0.1,4.6c0.8,6.1-2.5,16.1,2.8,17.2
				c3,0.6,4.9-11.1,5.2-18.3C1526.9,1479.8,1526.2,1478.4,1526.1,1478z M102.8,1527.7c0.1,1.2,0.2,2.4,0.2,3.6
				c6.2,0,12.5,0.4,18.6-0.3c2.7-0.3,8,2.8,7.4-4.4c-0.4-5.5-4.5-9.8-9.1-8C114.2,1520.9,108.5,1524.6,102.8,1527.7z M2269,1431.7
				c5.9,0.7,8.9,11.9,13.3,9.8c4.1-1.9,4.8-12.3,9.2-15.6C2282.5,1414,2276.4,1425.7,2269,1431.7z M2251.4,895.6
				c-0.8,5.7-0.7,11.5-3.7,16.7c-1.7,2.9,9.3,13.6,10.1,10.5c2.3-8.2,8-16,3.2-26.2C2257.3,915.4,2255.1,895,2251.4,895.6z
				 M1751.8,1257.5c1.4,4.5-4.2,12.4,3.9,12.5c5.4,0.1,7.9-4.8,7-10.6c-1-6.3,0.3-17.2-5.3-17.9
				C1749.3,1240.5,1753.9,1252.2,1751.8,1257.5z M1449.7,993c6-1.4,12.3,10.4,17.7-0.1c3.6-6.9-1.6-11.4-4.1-21.4
				C1461.9,986.8,1453.5,985.2,1449.7,993z M1635.4,1174.5c12.6-4.8,26.6,3.4,38.4-8.2C1660.7,1166.8,1647.6,1166.7,1635.4,1174.5z
				 M2712.5,1050.5c-6.4-2.5-12.8-3.2-19.4-1.5c-1.8,0.5-4.7-2.9-5.2,1.6c-0.6,4.6,2.8,5.8,5.1,6.2
				C2699.7,1057.9,2706.4,1057.2,2712.5,1050.5z M1218.2,1468.8c7.4,2.3,11.2,2.8,13.1-5.4c1.9-8.1,4.4-15.7,0.3-23.7
				C1227.6,1449.4,1229.9,1462.7,1218.2,1468.8z M1277.9,1453.7c8.2,13.1,1.8,28.2,6.1,41.8c4.3-9.7,0.8-20.7,3.9-29
				C1293.6,1451.6,1287.3,1452.9,1277.9,1453.7z M1012.3,1012.9c4.5,7.2,8.5-0.5,11.4,5.2c3.6,7.1,10.1,4.2,15.1,6.7
				c2.2,1.1,6.5,2,6.3-4.7c-0.2-4.3-2-7.4-5.7-7.3C1030.9,1013,1022.5,1012.9,1012.3,1012.9z M1856.4,1287.6
				c2.5,4.8-2.9,14.4,4.1,14.9c7.3,0.5,4.8-9.2,5-15.1c0.3-5.9,1.1-14.2-5.1-13.9C1853.4,1273.8,1858.8,1283.1,1856.4,1287.6z
				 M1218.3,1388.1c2.2,4.8,3.6,10.8,5.3,11c12.1,1.4,11.9,11.6,8.9,24.2C1239.7,1394.4,1239.5,1393.9,1218.3,1388.1z M22.8,1164.4
				c2,5.1-2.7,15.2,4.6,14.3c7.5-0.9,3.5-11,4.1-17.1c0.5-5.1,1.1-13.5-4.5-12.8C19.9,1149.8,24.7,1159.5,22.8,1164.4z
				 M2619.9,1079.5c-2.6,10.3-4.3,19.7-3.8,29.5c0.3,5.2,3,8.8,6.5,8.8c4.6,0.1,3-5.6,3.2-9.3
				C2626.5,1098.5,2623.8,1089.7,2619.9,1079.5z M424,790.2c2.2-0.5,7.5,1.4,6.5-6.6c-0.8-5.8,0.6-15-7.1-15.6
				c-5.3-0.5-6.3,3.7-5.6,9.5C418.3,782.5,415,790.9,424,790.2z M482.8,1164.6c3.4-5.1,12.5,2.2,13.8-6.8c1.4-9.3-6.1-7.2-10.5-7.8
				c-5.5-0.8-9.5,2.2-9.8,10.5C476.1,1165.9,479.1,1165.5,482.8,1164.6z M2677.1,1405.6c7.6-10.6,9.2-21.1,5.7-38.7
				C2674.3,1377.4,2669.4,1389.3,2677.1,1405.6z M2766.6,1039.7c-7.8-5.3-11-14-18.3-12.7c-4,0.7-6.2,5.2-6.6,8.8
				c-0.4,3.5,3.4,4,6,4C2753,1039.8,2758.4,1039.8,2766.6,1039.7z M216.3,1280.1c-7.9,0.2-12-15.5-19-10.2c-3.9,3-1.1,14.4,0.1,25.1
				C202.1,1282.9,209.6,1285,216.3,1280.1z M155.6,1059c-0.9,28.7-0.9,28.7,11.9,25.5C164.2,1075.8,163.6,1065,155.6,1059z
				 M1476.6,1193c-0.6,12.9,4.5,16.1,11.6,15.7c5-0.3,6.3-5.4,4.8-10.8C1489.2,1184.4,1482,1195.9,1476.6,1193z M441.8,466.3
				c-15.3,1-30.7,2-46,3.1C411.3,473.1,426.8,475.7,441.8,466.3z M2441,1205.9c3.2,2.9-3.3,13,4,13.1c6.4,0.1,8.7-7.3,8.8-16
				c0.1-7.6-2.9-11.8-7.7-11.8C2438.8,1191.2,2441.9,1199.6,2441,1205.9z M188.4,1236.3c-1.3-3.4,2.8-11.2-4.4-11.6
				c-8-0.5-5.7,8.1-6.4,13.7c-0.6,5.2-0.2,11.1,5,10.4C187.2,1248.3,189.5,1243.9,188.4,1236.3z M957.5,997.2c4.2,1.4,10,1,9-5.7
				c-1.6-10.6-10.4-7.9-16-11.2c-3.4-2-3.3,2.2-3.2,5C947.6,994.3,950.4,999.5,957.5,997.2z M177.5,1344c1.1-8.2,2.5-15.6,7.3-20.9
				c1.8-2,2.5-5.7,0.9-8.2c-2.3-3.6-6.5,0.1-6.6,1.4C179,1325.4,173.9,1334.2,177.5,1344z M486.9,1197.2c3.5,0.3,7.2-0.4,7.2-7.2
				c0-7.1-4-9.6-8.2-9.8c-4.8-0.2-11.8,3.1-11.6,8.1C474.6,1196.2,481.8,1195.4,486.9,1197.2z M1658.6,1181.5
				c-16.9,1.1-33,6.9-49.1,13.1C1626.2,1192.5,1642.7,1189.1,1658.6,1181.5z M748.6,402.9c-27.6-6.5-39-6.1-46.9,0.2
				C717.3,403,733,402.9,748.6,402.9z M2258.8,1069.6c1.5,3.6-2,12.1,4.6,10.4c3.6-0.9,10,0,10-6.1c-0.1-8.3-8.3-3.6-11.7-9.1
				C2260.4,1062.7,2257.6,1065.2,2258.8,1069.6z M2367.5,1317.3c1.2,0,2.5,0,3.7,0c-3.9-15.8,5.3-32.6-3.9-51.7
				C2367.4,1285.3,2367.4,1301.3,2367.5,1317.3z M733.6,1407.2c0.4-6.8-1.3-8.4-8.7-9.5c-2.1-0.3-4.7,1.6-4,2.4
				c4.8,5.1,1.6,16.9,6.8,19.1C735.1,1422.4,732.4,1412.1,733.6,1407.2z M885.2,870.2c-6-5.1-11.2-6.9-15.7-2.2
				c-3.6,3.8-2.4,9.8-1.1,15.3C875,882,878.9,875.1,885.2,870.2z M2382.5,1387c-6.3,0.2-10.3,3.3-10.3,11.5c0,7,4.4,7.6,8.1,7.2
				c4.9-0.5,8.2-3.4,7.7-11.7C2387.7,1387.9,2385.9,1385.8,2382.5,1387z M1048.1,1101c-4.9,0.1-9.5-0.7-8.6,8.2
				c0.6,5.5-0.6,12.7,6.6,12.3c5.6-0.3,7.9-2.7,7.9-10.6C1054,1104.1,1053.2,1099.5,1048.1,1101z M213.4,363.8
				c0.2-7.1,0.7-12.6,0.5-18.1c-0.2-4.7,2-10.2-1.6-14c-0.5-0.5-2.5,0.3-2.8,1.2c-1.6,5-4.8,9.7-2.8,15.6
				C208.3,353.4,207.4,360.1,213.4,363.8z M2577.3,1356c2.5-0.9,8.4,3.4,9.1-5.9c0.7-9.8-5.4-7.8-9.3-7.7c-3.8,0.1-9.6-3.1-9.8,6.6
				C2567.2,1357.5,2572.1,1356.1,2577.3,1356z M863.1,1466.5c0.3-5.4-0.8-7.8-3.6-6.2c-4.6,2.5-11,3-12.3,11.1
				c-0.8,5.1,4.3,8.1,6.7,7.7C859.2,1478.2,861.5,1471.6,863.1,1466.5z M1857.8,1249c8.8-1.6,7.1-13.6,9.7-20.8
				c1.1-3.1-1.9-6-4.9-5.6c-1.1,0.2-2.6-1.5-3.1,1.8C1858.2,1232.4,1854.8,1239.9,1857.8,1249z M1116.5,1180.2
				c1.6,2.5-3.4,10.8,3.3,10.4c8.8-0.5,2.8-12.4,6-18.1c2-3.5-1.4-5.2-4.4-5.3C1114.5,1167.2,1116.4,1173.7,1116.5,1180.2z
				 M785.8,1283.8c-6.5-3.8-2.2,9.5-6.4,10.6c1.9,7.7,8.2,10,11.6,10.6c6,1.2-1.1-12.5,5.3-14.5
				C794.8,1284.2,790.8,1284.3,785.8,1283.8z M2616.1,1258.1c2,4.7,5.2,3.1,7.2,5.3c3.3,3.8,6.7,9.1,11.2,4.3
				c1.2-1.3,1.2-7.2,0.2-10c-2.5-6.9-7.5-4.5-11.6-4.3C2620.5,1253.5,2617.8,1254.1,2616.1,1258.1z M669.2,944.3
				c-3.1-14.7-13.8-34.5-18.9-36.5C656.1,921.2,660.4,935,669.2,944.3z M1697.2,1438.8c7,8.6,14,5.6,20.5,5.4
				c1.5-0.1,2.6-1.8,2.6-4.1c-0.1-3.6-1.6-5.9-4.1-5.8C1710.2,1434.4,1704.1,1433.8,1697.2,1438.8z M324.3,1380.9
				c-5.6,2.2-7.3,7.6-9.4,12.2c-1.4,3.1-1.7,6.9,1.1,9.3c1.5,1.2,3.3,2.5,4.9,2.4c3.5-0.3,3.4-4.3,3.4-8
				C324.3,1391.8,324.3,1386.7,324.3,1380.9z M2263.5,987.1c-2.7,8,1.5,14.7,1.2,22c-0.2,4.9,3.2,4.7,5.7,4.6
				c2.9-0.1,3.9-2.8,3.3-6.2C2272.4,999.2,2273.7,988.2,2263.5,987.1z M1021.1,1183c-2-6.2,2.9-17.7-3.7-18.1
				c-6.8-0.4-3.3,11-3.9,17.2c-0.7,6.9-0.1,18.8,3.7,18.5C1023.6,1200.1,1018.8,1188.9,1021.1,1183z M1863.7,1354.3
				c-6.6,3-1.3,17.8-10.3,15.6c-3.2-0.8-5.2-1.1-4,4.7c1,4.9,3.4,4.1,6,2.8C1863.5,1373.5,1862.8,1363.7,1863.7,1354.3z
				 M2008,1431.7c-4.5-16.1-12.3-6.5-18.6-5.9c-1.9,0.2-5.2,3.2-3.5,5.8c3.9,6,9.6,2.3,14.5,2.4
				C2002.8,1434.1,2005.2,1432.6,2008,1431.7z M286,128.4c-3.8,2-7.1,4.8-7,9.5c0.2,8.6,6.8,8.9,10.5,13c1.7,1.8,4.1-0.9,3.3-3.4
				C290.9,140.8,288.3,134.6,286,128.4z M303.5,699.5c-0.6-8.3-1.1-15.5-1.8-22.6c-0.3-2.6,0.9-6.8-2.7-6.7
				c-2.7,0.1-3.9,3.2-4.1,6.2C294.1,686.2,295.5,694.8,303.5,699.5z M2477.7,775.6c-1,8.3,4.3,14.5-2.4,19.8
				c-2.8,2.2,0.2,6.8,3.2,8.8c2.2,1.5,5.7,1.9,5.4-1C2483,794.4,2485.3,783.7,2477.7,775.6z M274.9,126.7
				c8.7-13.6,6.1-24.9-0.1-38.3C274.8,101.9,274.8,113.2,274.9,126.7z M892.7,985.8c-8.7-9.2-14,1.3-20.5,4.9
				c-0.8,0.4-1.7,2.3-1.5,3.3c0.2,2.1,1.5,2.3,3.1,3C882.5,1000.5,885,985.2,892.7,985.8z M2276.7,1145.8c-0.5-4.3,0.9-11.1-3-9.6
				c-4,1.5-9.3,6.6-10.3,11.5c-1.2,5.9,5.5,10.1,8.6,10.3C2277.2,1158.5,2274,1149,2276.7,1145.8z M1075.2,1190.9
				c-4.6-1.4-3.1,3.9-3.6,6.6c-1.1,7,1.8,8.8,6.2,8.5c4.4-0.3,6.2-3.1,6.3-9.5C1084.3,1183.7,1076.1,1199.1,1075.2,1190.9z
				 M654.3,1208.5c6.9-1.8,12.6-5,21.1-10.6c-6,0-9.2,1.2-11.5-0.2c-11.6-6.9-12.1,5.6-14.3,15.2c-0.7,3.1,0.9,8.1,1.9,7.9
				c3.1-0.7,3.7-7.3,7.4-7.8C657.5,1211.5,655.9,1210,654.3,1208.5z M1246.1,1276.1c-6.7,7.4-3.3,17.4-3.6,26.3
				c0,0.9,1.5,2.7,1.8,2.5c1.2-0.8,3-2,3.2-3.3C1248.4,1293.2,1251.4,1284.5,1246.1,1276.1z M99.8,1465.8c1.3,1.9-1.8,7.7,2.5,7.3
				c4-0.4,7.6-4.3,9-9.8c1.4-5.7-2.5-6.6-5-7.5C100.7,1454,100.8,1460.3,99.8,1465.8z M1605.3,1388.2c0.2-3.8,0.2-8.4-5.5-7.8
				c-4,0.4-6.2,1.7-5.7,7.9c0.5,5.5,0.2,12.4,5.3,13.1C1604.7,1402.1,1605.4,1395.8,1605.3,1388.2z M771.6,1169.2
				c7.2-0.5,8.4-5.5,6-12.3c-1.9-5.2-5.5-9.8,2.4-10.6c0.8-0.1,1.8-5.2,0.3-7.4C767.9,1143.4,773.5,1157.6,771.6,1169.2z
				 M791.5,1139.5c7.7,1.9,8.8-7.4,10.2-13.8c1-4.6-2.5-11.9-5.9-10.1c-5.9,3.1-4.6,15.9-10.8,20.1
				C786.6,1139,788.5,1140.8,791.5,1139.5z M2681.8,860.7c-6.9-2.6-8.2,5.3-9.2,9c-1.2,4.3-5.7,11.9-1.5,14.6
				c5,3.1,5.1-6.1,6.6-10.8C2679,869.5,2680.3,865.4,2681.8,860.7z M483,1362.5c3.7,2,0.9-9.5,5.3-5.2c1.9,1.9,2.9,2.6,4.2-0.3
				c1.4-3.2-0.2-4.1-2.1-5.3c-2.6-1.6-4.9-4.2-7.5-5.7c-1.6-0.9-3.8-1.4-4.9,1.2c-1.7,4-0.1,7.7,1.1,11.4
				C479.9,1361.4,480.8,1362.9,483,1362.5z M1039.2,1164.4c0.7-9,10-6.4,10-16.1c-0.1-7.6-4.2-7-7.6-7.6c-4.4-0.8-6.6,1.9-6.8,8.4
				C1034.5,1155.2,1037.1,1159.2,1039.2,1164.4z M1230.5,807.5c2.8-0.8,7,1.1,6.6-6.6c-0.4-7-3.5-9.3-8-10c-6.6-1-4.4,6.9-5,10.6
				C1222.8,808.5,1227.4,806.9,1230.5,807.5z M2576.1,976.3c0-4.4-0.6-7.9-3.5-6.8c-5.1,2-5.3,10-8.3,14.6
				c4.4,0.8,1.7,11.7,6.3,10.1C2577.7,991.9,2571.6,980.2,2576.1,976.3z M352.6,1171c2.3-7.4,10.6,5.3,11.2-4.1
				c0.5-8.4-7.2-6-11.4-7.4c-3.1-1-5.8,0.5-5.7,6.3C346.8,1170.8,348.8,1171.7,352.6,1171z M881.3,1019.7c-2-5.7-7.7-3.3-9.3-8.7
				c-2.8,1.7-6.7,1.6-6.7,8c0,5.9,1.5,10.6,6.1,10C875.4,1028.4,878.3,1024.2,881.3,1019.7z M213.2,156.9c-1.4-6-2.7-11.2-3.9-16.3
				c-1-3.9-3.6-5.2-5.9-5.4c-1.3-0.1-3.1,2.7-3.8,4.7c-1.2,3.2,0.2,5.8,2.1,7.9C204.9,151.6,207.8,156,213.2,156.9z M55.1,783.6
				c1-1.4,2-2.9,3-4.3c-6.4-7.6-12.7-15.3-19.2-22.7c-0.9-1-2.9-1.4-3.9-0.8c-1.9,1.1-1.2,3.9-0.2,5
				C41.7,768.1,46.9,778.7,55.1,783.6z M1520.3,1277.7c-4.8,11.7-0.3,21.9-0.2,32.1C1524.1,1299.5,1524.5,1289.3,1520.3,1277.7z
				 M1162.1,1311c4,0.6,9.5,7.1,10.8-2.4c1-7.7-4.8-5.4-8-5.6c-4.2-0.2-10.3-0.6-9.8,7.3C1155.5,1319,1159.8,1309.3,1162.1,1311z
				 M930.3,1426.3c-2.7,1.4-7.7-2.1-7.8,4.6c-0.1,8.1,5.5,6.5,9.2,6.7c3.1,0.2,7.5,1,7.3-5.8
				C938.7,1423.8,933.5,1427.7,930.3,1426.3z M457.4,1181.7c-5.9-1.9-3.3-16-9.9-13.8c-2.7,0.9-5.3,12-2.9,16.8
				C448.4,1192.3,452.6,1182.7,457.4,1181.7z M677.2,906.2c-2.2-5.2-7.3-3.9-10.8-6.5c-1.7-1.2-5.2-2.6-4.1,2.6
				c1.1,5,4,9.3,6.1,13.9C670.4,911.9,675.6,913.9,677.2,906.2z M1740.5,907.4c-0.8-5.4,0.4-12.1-5.7-12.5c-5.8-0.4-6.2,5-6.6,10.8
				c-0.5,6.8,2.2,8.9,6.5,9C1738.5,914.9,1741.1,913.2,1740.5,907.4z M181.1,926.6c-13,8.4-13,10.7-2.3,25.6
				C179.5,944.2,180.3,936.2,181.1,926.6z M1340.8,1035.8c-0.5-6.3-0.8-13.1-7.2-12.1c-2.4,0.4-7.1-2-6.3,4.7
				c0.8,6.2,2.3,13.4,8.3,14.4C1339.1,1043.4,1341.4,1041.4,1340.8,1035.8z M1574.8,1398.3c0.3-5.2-0.2-10.5-4.8-9.5
				c-6.3,1.3-5.9,10.7-8.2,16.5c-1.5,3.8,2.6,5.7,5,5.8C1572.9,1411.3,1575,1405.9,1574.8,1398.3z M803.6,1474
				c5.3,2.8,4.3,17.9,13.2,12.2c4.4-2.9,4.9-8.7,3.3-12.6C815.3,1462.3,809.4,1477.1,803.6,1474z M1992.9,1450.5
				c-9.4-8.7-16.7-5.4-23.9-0.1C1976.4,1451.8,1983.7,1457.5,1992.9,1450.5z M214.9,212c1-10.2-0.7-17.8,2.1-24.6
				c0.9-2.1,1-5.2-1.5-6.1c-2.5-0.9-2.7,2-3.6,4.2C208.1,194.3,211.4,201.5,214.9,212z M542.5,1383.9c5-0.1,8.2-2,7.2-9.9
				c-0.4-3.2,0.2-9.6-3-7.4c-3.8,2.7-7.8,7.9-10,12.6C534.8,1383.5,540.3,1383.2,542.5,1383.9z M1745.6,999.7
				c-4.7-6.1-7.3-11.1-6.4-12.6c2.3-3.6,2.5-6.3-1.2-7.5c-2.9-1-4.8,1.2-5.8,5C1729.8,993.5,1735,1000,1745.6,999.7z M874,1039.3
				c5.6,3,9.7,0.1,14-0.5c2.2-0.3,5.1,3,5.9-3c0.5-4-0.5-6.9-2.9-9.1c-2.5-2.3-4.7-1.7-6.9,1.1C881.2,1031.5,878,1034.7,874,1039.3z
				 M1141,1089.7c4-12.6,5.4-26.9,2.5-38.8C1142.7,1063.4,1141.9,1075.9,1141,1089.7z M2612.8,950.8c-0.3-4.3-1.3-7.5-4.6-7.4
				c-5.3,0.1-6.5,7.4-8.9,11.5c-1.3,2.2,4.3,5.8,6.4,4.5C2608.4,957.8,2613.9,958.4,2612.8,950.8z M199,154.7c1,5,1.6,9.8,2.4,14.4
				c2.2,12.9,7.4,8.9,8.8,0.6C212,159.6,203.2,159.8,199,154.7z M2548.3,898.9c-9.3-2-18.6-3.2-27.6,1.3
				C2530,902.7,2539.2,903.6,2548.3,898.9z M547,1169.3c-0.8-4.7-3.7-9.5-7.1-8.7c-4,1-11.1,0.3-11.6,5.8c-0.6,6.8,4.9,3.3,8.1,3.3
				c1.9,0,3.9,2,5.7,3.3C544.6,1174.9,546.2,1174.3,547,1169.3z M218.8,32.3c-14.1,9.5-1.3,17.4,0.1,25.8
				C218.9,49.8,218.8,41.5,218.8,32.3z M233.6,1282.7c4.5,1.8,7.3,13.3,13.5,4.8c2.7-3.8,3.4-12,0.9-13.6
				C242.8,1270.6,238.2,1276.3,233.6,1282.7z M2469,860.6c2.3,3.2,3.4,8.8,2.2,14.4c-0.5,2.4,0.6,2.3,1.2,3c1.7,1.8,4.1,1.2,5.1-0.3
				c3-4.9,1.5-11.2,0.4-15.9C2476.2,855,2472.3,857.1,2469,860.6z M1437.7,984c0.4-5.8-2.7-6.2-5-6.3c-3.3-0.2-7.8-2.5-8.3,5.6
				c-0.2,3.3-1.2,8.6,2.4,8.3C1430.9,991.2,1435.5,989.9,1437.7,984z M962.3,842.5c-0.8,1-1.5,2-2.3,3c6.6,2.7,2.4,17.7,9.5,15.7
				c6.5-1.8,1.2-13,2.7-19.9C968.5,841.7,965.4,842.1,962.3,842.5z M2501.9,1382.5c0-11,0-18.3-0.2-25.6c0-1-1.3-2.6-2.2-2.8
				c-1.6-0.4-3,1.3-2.7,3.5C2498,1364.7,2496.2,1372.7,2501.9,1382.5z M142.7,1507.8c-4.2,2.5-9.9,0.1-11.1,7.7
				c-0.5,3.5,1.9,7,4.7,7.2C143.8,1523.3,138.9,1511.7,142.7,1507.8z M2250.2,1073.6c0-4.2,2.6-10.1-3.4-9.4
				c-2.8,0.3-6.6,1.2-5.9,6.5c0.7,5.1,7.1,3.9,7.4,10.5C2253.1,1080.5,2249.8,1076.1,2250.2,1073.6z M921.3,458.2
				c-12.1,0-22.7-1.4-33,3.1C898.9,462.3,909.6,466,921.3,458.2z M138.8,1237.5c-6.9-7.4-11.8-9-16.6-0.6c-2.3,4-1.2,5.5,1.5,8.1
				C132.5,1253.6,131.9,1236.9,138.8,1237.5z M2006.9,1490.3c-4.8,0-9.5,0-14.1,0c-2.9,0-4.4,2-2.3,5.3c2.9,4.6,7.8,2.4,11.7,4.8
				C2005.2,1502.3,2006.9,1496.1,2006.9,1490.3z M274.9,536.2c2.2,7.7,3.5,12.7,5.1,17.6c0.8,2.5,1.4,6,4.5,5.1
				c2.6-0.8,4.9-2.4,5.6-6.2c0.6-3.4-1-5.8-2.9-7.4C283.9,542.6,280.4,540.3,274.9,536.2z M203.4,574.8c-0.1-4.2,0.5-10.5-3.5-9
				c-4.8,1.8-6.5,9-6.8,15.7c-0.2,4.3,0.3,12.2,3.3,11.2C202.2,590.7,202.6,581.8,203.4,574.8z M2374.2,1253
				c-2.7-3.1,2.6-11.8-4.3-12.7c-3.3-0.4-4.6,2.2-4.3,6.6c0.4,5.8,1.6,12.1,5.3,14.1C2373.9,1262.6,2374.2,1257,2374.2,1253z
				 M1237,1232.5c-0.2-5.1,0.5-9.3-0.7-11.8c-1.7-3.5-5.6-5-7.4-0.7c-3.2,7.8,8,12.1,3.5,21c1,0.3,2.1,1.2,2.9,0.8
				c0.7-0.4,1.2-2.2,1.3-3.5C1236.9,1235.9,1236.9,1233.3,1237,1232.5z M906.3,748c-5.3,5.4-7.3,11.2-12.1,12.3
				c-3.8,0.8-2.9,3.3-1.3,7.3c3.2,8.2,7-2.8,11.1,1.7c2.5,2.8,2.1-4.3,2.3-7.1C906.5,758.2,906.3,754.2,906.3,748z M1277.1,863.8
				c-0.2-1.8,0.2-5.9-3.4-6.1c-5.3-0.4-4.9,6.2-5.8,10.5c-0.7,3.3,0.8,5.8,3.5,5.8C1275.4,873.9,1277.3,871,1277.1,863.8z
				 M2607.4,1372.5c-1.4-4,1.5-13.3-3.7-13.1c-6.5,0.2-3,9.5-3.2,14.8c-0.1,4.1-2.7,12,3.5,11.5
				C2609.5,1385.2,2606.8,1377.9,2607.4,1372.5z M890.3,576.6c7.1,3.3,13.6-0.3,20.8,1.2C902,564.5,900.6,564.5,890.3,576.6z
				 M144.4,1368.9c-7.5-3.5-13.5-4.7-18.8,1.3c-1.7,1.9,0.1,6.6,1.9,7.8C134.9,1382.7,138.4,1373.8,144.4,1368.9z M2584.4,793
				c5.3,5.6,9.1,14.1,17.3,9.3C2598.2,791.6,2594.3,783.2,2584.4,793z M213.3,889.2c-5.3-2.5-10.6-5.4-15.4-0.8
				c-2.1,1.9-0.2,6.2,1.6,7.7C205.4,901.1,209.4,895.5,213.3,889.2z M881.1,1165.9c-1.4-1.2,2.4-8.2-3.6-6.7
				c-5.2,1.3-7.6,6.6-8.2,12.4c-0.3,3.2,3.6,5.2,6.5,5.4C880.9,1177.3,881.7,1172.8,881.1,1165.9z M696,908
				c-5.2-0.4-9.5-11.5-15.1-2.6c-1.9,3-2.2,6.8,0.5,9.3C687.6,920.3,691.7,914,696,908z M2617.4,1222.6c-5.9,0-10.9-0.1-15.9,0.1
				c-4,0.2-4.8,3.9-3.9,8.5c1,5.2,3.9,4,6.2,2.4C2608.2,1230.5,2612.4,1226.8,2617.4,1222.6z M328.6,1525.7
				c-4.8-2.5-8.8-8.5-14.4-4.2c-1.5,1.1-2.8,5.1-1.2,6.3c4.6,3.6,9.7,1.8,14.5,0.1C327.8,1527.7,327.9,1527,328.6,1525.7z
				 M2250.7,1042.6c-4.7-1.7-4.4-13.1-9.4-10.6c-3.8,1.9-3.1,11.6-1,14.7C2242.2,1049.6,2246.1,1041.8,2250.7,1042.6z
				 M1839.2,1350.5c-0.2-8.1-3-9.3-6.1-9.8c-4.6-0.7-4.4,4.1-4.3,8.1c0,3.2-1,8,3.6,6.7C1835.6,1354.6,1839.2,1354.2,1839.2,1350.5z
				 M1486.3,1236c4.5-15.9,4.5-15.9-5.8-15.1C1480.4,1226.9,1483.6,1230.4,1486.3,1236z M673.3,856.6c3.9,0.3,7.6,0.2,7.3-6.4
				c-0.2-4.6-5.5-7.7-5.9-7.1c-3,4.3-10.2,2.2-10.1,8.9C664.7,858.3,670.4,855.7,673.3,856.6z M860.7,1131.2c-3.3,0.3-2.5,5-3.2,8.7
				c-1.3,7.1,3.7,6.2,5.8,8.2c1.7,1.6,4.4-1.6,3.1-5.1C864.9,1139,867.3,1131.8,860.7,1131.2z M2377.9,1349.8
				c-3.7,2.6-6.3,6.4-5.9,12.9c0.2,4,0.5,8.7,4.8,7.1c4.2-1.5,7.2-4.7,6.6-11.8C2383,1353.5,2381.8,1350.2,2377.9,1349.8z
				 M554.6,1398.9c1.6,3.8-2.3,11.5,4.2,12.8c2.5,0.5,5-1.5,3.7-5c-2-5.6,0.1-14.9-5.6-16.6C553.6,1389,555.1,1395.2,554.6,1398.9z
				 M821.9,1455.3c0.2-6.1-0.7-12.5-5.8-12.3c-4.4,0.2-2.8,7.1-3.2,11.3c-0.4,4.6-0.8,9.6,4.1,10.1
				C822,1464.8,822,1460.1,821.9,1455.3z M1244.1,1242.4c-4.6,6.8-4.6,12.2-2,17.6c0.5,1.1,3.5,1.7,3.6,1.3
				C1247.9,1255.7,1245.9,1250.5,1244.1,1242.4z M1464.8,846.8c-11.7-8.4-15.8-8.5-27.4-2.4C1446,851.6,1454.3,849,1464.8,846.8z
				 M751.8,886.1c-3.7-1.7-6.3-3.3-9.1-4.2c-2.8-0.9-6-1.4-7.9,2.6c-0.7,1.5-1.2,4.1-0.7,5.6c1.6,5.1,5,2.3,7.7,2.8
				C745.5,893.7,748.6,891.6,751.8,886.1z M840,1494.5c2.7-0.5,6.9,0.7,6.4-5.3c-0.6-6.3-5.2-8.4-8.9-9.7c-4.7-1.6-3.9,4.6-4.1,8.2
				C832.8,1494.8,836.6,1494.4,840,1494.5z M423.8,1313.1c-4.9-1.6-8.3-11.9-14.4-5.4c-2.4,2.6-2.1,6.9,0,9.3
				C414.6,1322.9,419.2,1318.6,423.8,1313.1z M1690.5,1209.1c2.4-8.6,3.6-15.9,12.5-15c-7.3-6.2-11.2-1.4-14.6,2.3
				C1685.3,1199.8,1688.9,1203.6,1690.5,1209.1z M281.6,1152.1c3.2,2.6,5.8,3.8,7.3,6.3c1.6,2.6,1.8,6.5,5.3,2.7
				c2.2-2.3,4.5-4.8,4.1-8.1c-0.5-4.4-4.5-4.8-6.2-3.6C289.1,1151.7,285.8,1148.5,281.6,1152.1z M647,1278.2
				c5.4-1.1,10.4,6.2,13.1-3.4c0.9-3.2,1.2-9-2.6-10.4C650,1261.7,650.2,1271,647,1278.2z M1134.7,1491c0.5,3.1-0.9,9,4.4,8.7
				c4.5-0.3,3.6-5.3,3.7-9.1c0.1-4.3,1.3-9.9-4.2-9.5C1134.3,1481.4,1134.8,1485.9,1134.7,1491z M1723.6,1301
				c-0.1-2.5-0.7-5.6-3.5-5.5c-5.7,0.2-2.9,7.8-4.6,11.6c-1,2.2,1,4.2,3.1,4.2C1723.3,1311.3,1723.1,1306.2,1723.6,1301z
				 M1977.5,612.1c2.8-0.5,7.4,1.1,6.8-4.3c-0.7-5.7-4.4-10.2-9.4-10.2c-3.7,0-6.2,1.4-6,7.7
				C1969.2,614.4,1974.3,611.1,1977.5,612.1z M888.8,1258.3c-6.4,6-6,12.8-6.1,19.1c0,1.9,2.4,2.4,3.6,1.4
				C891.9,1273.7,889,1266.7,888.8,1258.3z M1485.5,928.7c1.2,3-2.1,9.9,4.1,8.9c3.7-0.6,6.1-2.6,5.9-8.4c-0.2-4.9-1.2-8.9-5.3-9.6
				C1484.8,918.7,1485.9,924.3,1485.5,928.7z M1107.1,677.3c-6.9-7-11.9-13.2-20.1-8.8C1091.8,679.2,1098.6,677.6,1107.1,677.3z
				 M1837.2,1249.4c-1.4-12.2,4.9-25-1.7-40.7C1834,1224.9,1833.8,1237.2,1837.2,1249.4z M479.7,1392.8c5.6,6.7,1.4-8.5,7-1.2
				c1.7,2.3,4.5,2.2,5-1.8c0.5-4.5-2.5-4.5-4.5-3.8c-3.3,1.1-6-0.3-8.9-2.1C478.9,1387.9,479.6,1391.8,479.7,1392.8z M2548.7,1356.1
				c-3.5-0.3-4.3,4.6-6.6,6.6c2.7,2.2,3.1,7.9,7.2,8.2c3.9,0.2,4.4-2.5,3.6-6.5C2552.3,1361,2553.5,1355.4,2548.7,1356.1z
				 M2786.9,1384.5c-0.7,7.1-1.5,14.3,0.8,21.3c0.2,0.5,2.8,0.3,2.8,0c1.3-7.3,2.3-14.8-1.8-21.2
				C2788.6,1384.3,2788.1,1384.5,2786.9,1384.5z M445.9,707c-3.7,6.2-8.2,11.2-5.7,19.5c0.9,3.1,4.6,3.3,5.7,1.3
				C449.4,721.5,449.1,714.5,445.9,707z M135.7,1413.3c-8.6-6.5-8.6-6.5-15.3-0.4C127.8,1419.1,127.8,1419.1,135.7,1413.3z
				 M1745.1,1421.9c9.2,6.2,20.4,5.8,27-0.7C1762.5,1417.4,1753.9,1423,1745.1,1421.9z M272,275.9c9.4-10.2,9.4-14.9-0.1-28.8
				C271.3,256.9,270.7,266.2,272,275.9z M2693.5,1514.9c-1.5,5.4-9,0.8-7.7,9.7c0.6,4.4,4.3,7.2,6.8,5.2
				C2696.8,1526.6,2694.2,1520.6,2693.5,1514.9z M2366.1,1218.9c0.7,4.7-1.9,11.2,4.3,11.3c3.3,0,4-4.9,3.3-5.6
				c-3.3-3.2-1.3-11.2-5.3-12.3C2363.8,1211.1,2366.4,1216.7,2366.1,1218.9z M486.3,1204.7c-5-6.8-10.9-2.6-15.7-5
				c0,3.5-1.1,8.8,0,10.1C476.9,1216.6,480.5,1206.1,486.3,1204.7z M471.9,1153.8c0.2-4.1-1.6-6.9-5.9-6.7c-3,0.2-6.2-0.4-5.9,5.9
				c0.3,5.2,0.4,10.5,5.6,10.8C470,1164.1,471.6,1160.9,471.9,1153.8z M863.9,956.6c3.4,4.3,4.7,13.8,11.6,8.7
				c1.8-1.4,5.6-4.3,3.1-7.8C874.5,951.7,869.2,950.9,863.9,956.6z M246,1385.5c-6.3,3.5-7.9,8.8-6.5,15.4c0.6,3,4,5.4,5.2,4
				C248.8,1399.8,246.6,1393.1,246,1385.5z M910.2,1123.8c-2.7-9-3.5-18.5-3.8-28.2C901.6,1106.5,907,1114.9,910.2,1123.8z
				 M152,950.6c-7.4,2.4-8.1,9.5-7.4,17.5c0.2,2.3,1.7,3.6,3.2,2.3C153.3,965.7,153.3,958.6,152,950.6z M307.1,779.5
				c-2.7,5.7-10.1,8.3-6.3,17.7c1,2.4,4,3.6,5.9,1.8C312.3,793.6,308.6,786.5,307.1,779.5z M1232.8,1350.2c0.4-5.2-2.1-6-4.8-5.7
				c-3.1,0.4-7.1,0-7.3,6.5c-0.1,4.4,2.4,6.3,5.1,6.4C1229.3,1357.5,1232.4,1356.2,1232.8,1350.2z M418.7,1422
				c6.6,0.4,13.5,15.6,20-0.1C431.5,1422,425.1,1422,418.7,1422z M2422.2,1310.7c-0.5-8.9-7.6-0.1-10-5.4c-0.6-1.3-4.4,2-3.8,3.4
				c2.2,5.4,6.7,7.1,10.5,9.3C2422.2,1319.8,2421.5,1314.6,2422.2,1310.7z M663.1,1008.5c-7.1,6-6.8,12.8-3.9,20.9
				C665.5,1024.2,663.5,1016.9,663.1,1008.5z M160,1141.8c0.7,2.7-1.8,9.3,3.3,9.4c4.1,0.1,4.6-5.7,4.9-10.1
				c0.2-3.8,0.8-8.6-3.9-8.4C160.4,1132.7,159.5,1135.9,160,1141.8z M1109.7,1011.5c0-0.5,0-0.9,0-1.4c-14.5,0.1-29,0.1-43.5,0.2
				c0,0.5,0,0.9,0,1.4C1080.7,1011.6,1095.2,1011.6,1109.7,1011.5z M1079.6,658.1c-5.6-6.5-9.2-9.7-13.7-9.4c-2.5,0.2-6.5-3-7,3.3
				c-0.5,5.6,2.9,6.2,6,6.2C1068.8,658.1,1072.8,658.1,1079.6,658.1z M2765.2,1535.8c-4.5,4.6-2.9,11.8-7.5,13.3
				c-2.4,0.8-2.8,4.7-1.3,7.5c1.6,2.9,4.2,2.2,6,0.7C2768.5,1552.4,2766.8,1545.1,2765.2,1535.8z M128.1,411.7
				c-8.7,1.2-15.9-0.9-22.3,4.4C112.7,413.9,120.2,419.5,128.1,411.7z M665.5,985.8c-3.6,2.7-6.2,5.7-8.9,3.3
				c-2.4-2.1-3.2-0.9-3.3,2.3c-0.1,2,0,4.1,0.4,5.9c0.4,1.7,1.9,2.3,2.4,0.6c4.1-12.9,4.9,2.6,7.9,1.6
				C662.4,995.4,663.9,991.7,665.5,985.8z M2359.9,1420.1c2.4-7,2.1-14.8,1.8-22.5c0-0.2-3.3-1.2-3.8-0.3
				c-4.1,7.3-1.4,14.9,0.2,22.3C2358.1,1419.8,2358.7,1419.8,2359.9,1420.1z M189.8,1155.4c3.1,0.2,6.9-0.5,6.7-5.3
				c-0.3-5.8-4.6-7.7-8.3-8c-3.1-0.3-6.2,1.4-5.8,6.8C182.8,1154.8,186.3,1155.5,189.8,1155.4z M1533.3,1205.3
				c-0.5,7.1-0.3,13.6,6,15.5c-0.1-4.7,2-9.7-1.3-13.9c-0.2-0.3,0.3-1.7,0.6-2.6c1.1-4,2.4-8.6-2.6-8.4
				C1531,1196.2,1533.6,1201.8,1533.3,1205.3z M166.1,1182.8c0.7-8.3,2-14.6-0.3-21.3C158.8,1167.9,158.5,1174.3,166.1,1182.8z
				 M1732.5,859.7c-3.8,1.7-3.2,6.1-2.4,9.9c0.8,3.9-1.2,11.3,3.9,10.6c4.4-0.6,3.5-7.6,3.9-12.1
				C1738.3,863,1735.8,860.9,1732.5,859.7z M2286.2,1412.7c-5.1-4.3-10.4-4.2-15.6-2.3c-1.8,0.6-2.7,3.4-2,6.1
				c0.8,2.8,2.8,3.5,4.5,2.8C2277.6,1417.4,2281.9,1414.9,2286.2,1412.7z M200.1,328.3c3.7,0,5.1-2.4,6.2-6c1.5-5.2-0.7-7.7-3.5-9.4
				c-2.1-1.3-4.9-1.2-4.9,2.9C198,320,199.3,324.3,200.1,328.3z M1710,1033c0.2-5-0.6-9.5-4.6-10.5c-4.2-1-4.7,3.6-4.8,7.8
				c-0.2,6,2.2,10.4,6.2,10.8C1710,1041.6,1709.6,1036.4,1710,1033z M1349.4,816.3c-7.4-5.4-14.3-10.5-24.2-6.4
				C1334.3,811.6,1341.2,816.8,1349.4,816.3z M185.2,549.1c2.1,5.8-3.5,14.1,2.9,17.3c2,1,5.2-1.5,5.8-5.7
				C195,551.9,188.7,552.6,185.2,549.1z M395.9,408.7c8.6,2.1,17,7.3,26.9,2.8C413.5,405.2,404.6,408.7,395.9,408.7z M2573.1,1047.3
				c4.8,9.3,9.8,10.7,16.1,4.3C2584.2,1049.6,2579.9,1043.3,2573.1,1047.3z M895.2,594.6c-4.2-3.6-7.5-9.2-12.8-8.8
				c-0.3,0-1.2,5.4-0.4,6.8C885.5,599,890.4,596.3,895.2,594.6z M156.3,1151.8c-0.4-7.5-7.3-4.8-8.7-10.7c-0.6,1.2-1.9,2.8-1.7,3.5
				c1.7,4.7,2.7,10.6,7.4,11.5C155.5,1156.4,157,1155.3,156.3,1151.8z M1338.7,1192.9c1.6-2.5,8.3,4.3,8.6-4
				c0.2-6.9-5.8-6.1-9.6-6.4c-3.1-0.3-5.9,0.5-6,6.3C1331.6,1195,1334,1195.3,1338.7,1192.9z M1029.7,985.2
				c-3.8-1.5-0.9-8.9-3.5-9.5c-3.3-0.9-5.1,4.4-4.8,8.1c0.3,2.6,2.1,6.8,5.3,6.5C1028.9,990.2,1030.1,988.5,1029.7,985.2z
				 M2731,1264.3c-1.1,0.5-2.8,2.3-2.4,6.5c0.3,3.8-0.7,8.6,3.4,9.6c2.9,0.7,5-0.3,4.4-5.5C2735.9,1270.8,2737.7,1265.4,2731,1264.3
				z M847.3,827c0,7.2,0.1,14.2,0.1,22.9C855.5,831.8,855.5,831.8,847.3,827z M2679.9,478.9c0-0.6,0.1-1.1,0.1-1.7
				c-13.7,0-27.5,0.1-41.2,0.1c0,0.6,0,1.1,0,1.7C2652.5,479,2666.2,479,2679.9,478.9z M2397,1000.7c-0.3-3.3-0.2-8.5-4-9.8
				c-2.4-0.9-3.8,2.3-3.8,5.7c0,4.4-0.4,9.5,4,10.6C2396.2,1007.9,2397,1005.4,2397,1000.7z M1224.1,1235.1c0.4-5.5-2.3-7.2-5.3-7.3
				c-2.9-0.1-6.1,1-5.5,6.7c0.5,5.1,3.6,5.2,6.4,6C1223.2,1241.4,1224.2,1239.2,1224.1,1235.1z M496.4,1111.1
				c-0.3-4.5-0.2-9.7-4.9-9.5c-3.3,0.1-5.6,2.7-5.2,7.6c0.4,4.5,1.7,9,5.7,9.7C496.3,1119.6,496.4,1115.3,496.4,1111.1z
				 M1540.8,1236.1c-13.7,4.9-7.2,17.6-8,22.6C1532.5,1253.4,1537.7,1246.8,1540.8,1236.1z M892.1,710.9c2.5-2.9,7.3-2.3,7.2-9
				c0-2-1.4-6.7-2.8-4.2c-2.7,4.9-8.7-0.2-9.7,6.7C886.2,708.5,890.1,708.6,892.1,710.9z M1384.5,1243.5c0.8,5.9,1.7,11.8,2.5,17.7
				C1388.3,1255,1391.5,1248.4,1384.5,1243.5z M123.4,1201.2c6.6,5.9,13,5.7,21,5.1C137.6,1196,131.4,1194.1,123.4,1201.2z
				 M536.2,1314.8c-3.1,6.3-5.4,10.9-7.8,15.8C541.2,1331.8,541.2,1331.8,536.2,1314.8z M1247.5,1107.9c-3.1,8.7-10.8,6.4-9.4,16.2
				c0.5,3.2,3.2,5.1,5.3,4.1C1249.9,1125.2,1247.4,1118,1247.5,1107.9z M1191,846.7c-1.2-4.6-4.3-5.1-7-5.9c-3.3-1-3.7,2-3.3,5.3
				c0.5,4.7,1.5,9.2,6,8.4C1189.7,853.9,1189.2,849.1,1191,846.7z M952.7,595.4c0.1-3.6,0.4-7.6-3.5-6.8c-2.6,0.5-3,3.9-3.1,6.9
				c-0.1,4.1,1.1,6.8,4.4,6.2C953.4,601.2,954,598.9,952.7,595.4z M1266.6,1044c0,11.5,0,19.6,0.2,27.8c0,0.3,2.8,1,2.9,0.7
				c2.3-7.3-1.5-14.6-0.3-22.1C1269.6,1049.5,1268.5,1048.3,1266.6,1044z M422.9,850.9c-1.6,2.9-7-2.9-7.2,4.3c-0.1,5.6,4.3,4,7,4.1
				c3.1,0.1,7.5,1.7,7.3-5.2C429.7,849.5,425.9,851.5,422.9,850.9z M1120.4,1592.1c-3.8,0.9-3,5.4-3,9.2c0,4,1.2,6.7,4.5,6.3
				c3.5-0.4,3.5-4,3.6-7.6C1125.6,1595.4,1124.4,1592.3,1120.4,1592.1z M208.2,1233.2c-2.8-5.1-6.5-7.4-10.2-5.6
				c-1.7,0.8-2,7.8-0.3,9.3C201.4,1240.2,204.7,1236.2,208.2,1233.2z M526.1,470.7c-12.3-4.6-24.7-2.5-37.1-0.6
				C501.2,474.4,513.6,471.7,526.1,470.7z M2786.2,1057.4c-15.4-14.6-15.4-14.6-23.1-2.3C2769.9,1058.3,2776.8,1055.5,2786.2,1057.4
				z M2556.6,1448c4.2,0.1,4.7-3.5,4.4-8.2c-0.2-3.8-0.5-7.4-4-7.7c-3.7-0.2-5.3,2.5-5.1,7.6
				C2552.2,1444.2,2552.4,1448.3,2556.6,1448z M1769.2,1409.4c-7-1.8-12.3-3.1-18.8,3C1757.3,1416.4,1762.6,1418.4,1769.2,1409.4z
				 M2569.9,677.9c-0.3-5.5,3.3-12.2-2.4-14.9c-2.6-1.2-4.6,0.4-5.4,5.2C2560.1,679.8,2568.8,672,2569.9,677.9z M1712.7,1408.1
				c-4,1.2-3.9,6.7-4.1,11.5c-0.1,3,0.5,7.1,3.3,5.7c3.7-2,6.3-6.4,6.1-12.7C1717.9,1408.4,1715.6,1408.2,1712.7,1408.1z
				 M1038.3,1109.7c0.5-4.7,0.4-8.7-3.8-9c-3.2-0.2-6.8,1.4-5.3,6.3c1.3,4.3,0.4,12,5.3,12.5C1040,1120,1036.7,1112.5,1038.3,1109.7
				z M869.2,929.4c1.6-5.1,6.8-4.1,7.7-10.3c-2.6-1.9-6.3-6.1-7.2-5.1C866.2,917.6,867.5,923.5,869.2,929.4z M2538.3,1549.3
				c-2.2,1.2-5.8-1.7-5.9,4.3c0,4.9,2.7,6.1,5.3,5.5c2.6-0.6,6.9,0.1,7.1-5C2545,1548.1,2540.7,1550.2,2538.3,1549.3z
				 M1762.6,1296.5c-5.2,2.1-10.7-5.4-16.5,0.6C1752.2,1303.4,1757.5,1304.7,1762.6,1296.5z M869.7,1478.2c2.4,3.5-7.7,5.9-1.5,11.7
				c1.3,1.3,3.3,4.2,4.6,1.8C876.8,1485,866.9,1483.2,869.7,1478.2z M2248.6,1475.7c-4.7-0.8-9.7-3.9-14,1c-0.4,0.5-0.2,3.5,0.2,3.8
				C2240.1,1484.4,2244.1,1478.6,2248.6,1475.7z M2013.9,1467.4c1.6-5.9,9.8-1.3,8.3-11.1c-0.7-4.7-4.2-4-6.5-2.9
				C2010,1456.1,2012.9,1461.8,2013.9,1467.4z M1661.8,1412.2c0-2.8-1.7-4-4.3-3.8c-4.8,0.5-4.6,5.7-4.6,10.2c0,2.8,0.3,7.3,3.1,6.6
				C1660.5,1424.3,1661.1,1418.6,1661.8,1412.2z M1860.1,1312.1c-3.6,0.9-2.9,4.9-3,8.3c-0.1,4.8,1.2,7.9,5,7.4
				c3.5-0.4,3.7-4.1,3.4-7.8C1865.3,1315.4,1863.5,1312.6,1860.1,1312.1z M306.9,1137.6c-7.9-13.4-7.9-13.4-16.2-3.9
				C295.8,1134.9,300.2,1135.9,306.9,1137.6z M454.8,1287.5c0-4.4-0.8-8.2-4.6-8.5c-3.8-0.3-5.4,3-4.9,7.6c0.5,4.7,2.1,9.1,6.2,8.9
				C455.1,1295.3,454.6,1291,454.8,1287.5z M2460.1,1052c1.3,5.8,5.1,3.6,8,4.1c1.6,0.3,4.4,0.1,3.9-3c-0.8-5.3-4.5-5.6-7.6-5.9
				C2462.5,1047,2460,1047.2,2460.1,1052z M97.3,1089.7c-1.9-8.1-7.4-4.1-11.1-6.3c-1.7-1-4.5,2.1-3.1,3.6c2.9,3.2,6.9,4.7,10.5,6.3
				C95.3,1094,97.4,1093.3,97.3,1089.7z M319.8,1114.9c-4.4,1.3-10.1-1.5-11.8,6.4c-0.4,1.8,1.9,6.6,3.4,6.9
				C318.2,1129.7,316.1,1118.5,319.8,1114.9z M1465.2,1150.9c1.3-5.8,1-12.5,4.6-17.7c1.2-1.7,0.8-5.3-1.3-6.3
				c-2.4-1.1-3.3,1.6-3.4,4.2C1465.1,1137.7,1465.2,1144.3,1465.2,1150.9z M1687.8,1408.6c-4.1-0.3-4.7,4.1-5.4,8
				c-0.7,4.2,0.7,7,4,7.2c3.9,0.2,4.8-3.7,5.2-8C1691.9,1411.9,1691.6,1408.4,1687.8,1408.6z M1840.8,995.8c7.3-0.4,7.4-6.1,6-13.4
				c-0.7-3.7-2.5-8.5-4.5-5.9C1838.2,981.6,1840.8,989.2,1840.8,995.8z M1138.7,1152.3c-16.5-4.9-16.5-4.9-22.3,0.6
				C1124.1,1154.9,1131.4,1153.9,1138.7,1152.3z M1955.7,977.8c3.5-2.8,6.4-6.5,6.6-12.7c0.1-2.4-0.6-5.1-3.2-4.4
				c-4.2,1.2-7.9,3-7,10.7C1952.5,974.4,1952.4,977.6,1955.7,977.8z M1793.3,1379.1c-0.6-3.6,0.7-9.4-4.5-9.4
				c-3.3,0-4.7,2.6-4.3,6.8c0.4,4.5,0.6,9.6,5.1,10.1C1793.2,1387.1,1793.7,1383.7,1793.3,1379.1z M2669.1,1366.8
				c0.7,3.2-1.3,9.1,3.3,8.8c3.1-0.2,3-4.9,3.1-8.4c0-3.5,0.2-7.9-3.1-8.2C2668.5,1358.6,2669.5,1363.4,2669.1,1366.8z
				 M1116.3,1491.3c0.5,3.1-0.7,8.5,3.7,8.6c5.6,0,3.1-6.4,3.5-10.2c0.4-3.9,1.1-8.8-3.6-8.2
				C1115.6,1482.1,1116.6,1487,1116.3,1491.3z M750.9,1180.2c3.4,2.2,0.1,13.9,7.3,11.2c3-1.1,2.9-4.4,2.5-7.1
				C759.5,1175.7,754.7,1180.8,750.9,1180.2z M2636.8,933.2c-6.3,0.5-12.6,0.9-18.8,1.4C2624.3,934.5,2630.7,938.8,2636.8,933.2z
				 M97.5,475.9c5.4,3.2,10.7,3,15.9,0C108.2,473.6,102.9,472.5,97.5,475.9z M545.6,1193.6c-5.9-5-10.5-10.2-16.7-5.9
				c-1.5,1.1-1,4.2-0.1,5.3C533.5,1198.3,538.6,1201.1,545.6,1193.6z M1018.1,1252.1c-2.4,7.5-1.9,14.6,0.6,21.4
				C1020,1266.2,1021.4,1259,1018.1,1252.1z M119.4,1353.3c2.3-3.5,5.8-6.6,2.8-11c-1.1-1.6-4.9-2.3-5.9-1
				C111.9,1347.1,120.2,1348.1,119.4,1353.3z M1741.8,1193.5c5.9,0,11.2,0,16.5-0.1C1752.9,1190.9,1747.5,1188.8,1741.8,1193.5z
				 M452.9,1312.9c-0.2-3.7,0.4-8.6-3.8-8.9c-4-0.3-4.8,4.1-4.6,8.3c0.2,4.1-0.6,10.2,4.4,10
				C452.9,1322.1,452.6,1317.1,452.9,1312.9z M977.9,1310.8c1.7-1.9,5.1-1.7,4.8-6.8c-0.2-3.3,1.1-7.9-3.2-7.7
				c-3.1,0.1-4.6,2.9-4.5,7.1C975.1,1306.3,974.5,1309.8,977.9,1310.8z M1267.4,841c8-10.4,3.5-19.9,1.5-29.3
				C1266.4,820.9,1266.6,830.2,1267.4,841z M1488.6,878c3.3,3.9,2.3,10.9,5.9,15.5C1497.6,884.8,1498.3,878.3,1488.6,878z
				 M2603.8,1530.1c-1.5-3.3-4.5-2.5-7.3-2.4c-2.3,0.1-4.7,1-4,5.1c1,5.7,4.5,3.7,7.2,3.7C2602,1536.6,2604.2,1535.6,2603.8,1530.1z
				 M80,413.9c0,0.7-0.1,1.5-0.1,2.2c5.3,0,10.6,0,16-0.1c0-0.7-0.1-1.5-0.1-2.2C90.5,413.9,85.2,413.9,80,413.9z M986.7,1027.5
				c-0.2-4.1-0.6-9.1-3.8-9.9c-3.9-1-3.2,4.3-3.1,7.5c0,3.9-0.4,8.9,3.8,9.1C986.4,1034.3,986.5,1030.5,986.7,1027.5z M847.7,1420.2
				c-1-5.1,3.1-11.4-1.8-14.9c-1.5-1.1-4.1,0-4.5,2.5C840.5,1414,844.1,1417,847.7,1420.2z M24.9,1230.9c0-9-0.1-15.6-0.1-22.1
				C24.1,1215.5,15.3,1221.7,24.9,1230.9z M154,413.6c8.3,4.6,16.4,2.5,24.5-0.4C170.3,409.9,162.2,410.8,154,413.6z M984.8,972.6
				c-1.1,8.9-1.8,14.7-2.5,20.5c1.1,0,2.5,0.7,3.1,0C989.8,987.5,987.6,981.7,984.8,972.6z M2436.5,1053.9
				c-5.6-4.4-8.3-6.6-11.1-8.7c-2.1-1.5-4.3-1.9-5.9,0.8c-0.8,1.3-1.4,3.9-1.1,5.4c0.8,3.5,3.4,3.3,5.5,3.4
				C2427,1054.9,2430.3,1054.4,2436.5,1053.9z M2412.1,1340.3c-7.1,0-12.4,0-19,0.1C2399.4,1348.6,2404.5,1353.4,2412.1,1340.3z
				 M1270.3,1022.2c0.4-5.1,7.4-7.3,3.2-14c-1-1.6-2.8-3-3.8-1.4C1266.7,1011.7,1267.6,1016.9,1270.3,1022.2z M435.4,753.7
				c-4.1,2.2-9.5-2.5-14.1,4.1C426.8,758.4,431.4,759,435.4,753.7z M1284.2,704.6c-0.9,0-1.8,0-2.8,0c-1,7.1-2,14.1,2,21.3
				C1283.7,718.8,1284,711.7,1284.2,704.6z M1347.1,1218.6c-3.6,3.2-8.9-4.7-11.4,3.6c-0.3,1,0.4,3.4,1.1,3.9
				C1342,1229.7,1344.4,1223.8,1347.1,1218.6z M829.6,1379c4.3,0,7-1,9.1,0.1c6.7,3.5,5.1-5.8,4.1-6.5
				C838,1369.4,835.2,1376.3,829.6,1379z M1282.3,890.4c4.7,0,6.9-0.1,9.1,0c3.2,0.1,5.6-1.6,6.8-5.9c0.6-2.1,0.6-4.3-0.8-5.4
				c-3.1-2.2-5.6,1.1-8,2.9C1287.3,883.5,1285.7,886.2,1282.3,890.4z M1118.9,1306.1c3.9-3.7,6.5-8.1,5-15c-0.5-2.2-1.9-3.5-3.2-1.8
				C1116.7,1294.1,1119.2,1300.4,1118.9,1306.1z M849,1086.9c1.1-7.8,2.7-14.6-2.2-20c-0.5-0.6-2.4-0.2-2.7,0.5
				c-2.5,6.7,0.2,12.3,2.3,18C846.7,1085.7,847.1,1085.7,849,1086.9z M631.7,1307.4c-3.1,11.3-3.5,18.6-0.2,28.7
				C635.2,1326.1,634.9,1318.8,631.7,1307.4z M698.4,697.2c0,0.3,0,0.7,0,1c10.1,0,20.3-0.1,30.4-0.1c0-0.3,0-0.7,0-1
				C718.7,697.1,708.5,697.1,698.4,697.2z M1020.2,633.8c0-3.8-1.5-5.3-4-5.7c-2.6-0.4-4.2,0.8-4.4,4.7c-0.3,4.2,1.8,5,4.1,5.4
				C1018,638.5,1020.2,638.3,1020.2,633.8z M901.2,638.6c5.4,3.3,9.9-0.1,14-4.7c1.5-1.7,1.6-5.2-0.3-6.5c-5.2-3.6-6.9,6.1-11.3,6.2
				C902,633.6,901.1,635.2,901.2,638.6z M1752.4,1227.1c3,6.2,5.6,10,10.1,9c0.3-0.1,0.7-4,0.2-5.6
				C1760.8,1224.2,1757.2,1225.4,1752.4,1227.1z M2325.3,1025.1c5.5,5.9,10.6,5.1,17.6,2.3
				C2336.7,1020.8,2331.8,1020.6,2325.3,1025.1z M467.3,1371c5.3-6.7,5.5-12.1,3.6-19.6C465.6,1356.6,466,1362.4,467.3,1371z
				 M1142.2,1341.2c-1.5,5.7-0.8,10.1,0.2,14.4c0.1,0.2,2.6,0,2.8-0.7C1146.9,1350.1,1146.7,1345.7,1142.2,1341.2z M271.2,164.1
				c1.6,8.5-1.5,13.6,2.5,17.4c1.3,1.2,3.1-0.1,3.5-2C278.4,174.2,277.2,170,271.2,164.1z M852.7,872.3c-2.4,2.9-5.1,6.1-5.7,11.4
				c-0.3,2.8,0.6,5.2,2.8,5.4c5.4,0.3,4.1-7.1,5.9-10.9C856.6,876.4,855.9,873.2,852.7,872.3z M371.3,1360c0.6,3.8,1.9,6.7,5.1,6.3
				c2.7-0.4,5.1-1.9,5.2-6.3c0.1-4.7-2.4-5.7-5.2-6C373.3,1353.6,371.7,1355.8,371.3,1360z M945.1,868.6c-0.2-3.4-1.7-4-3.7-3.8
				c-4,0.4-4.5,4-4.1,8.4c0.2,2.4,0.2,5.9,2.5,5.5C943.8,878,943.7,872.3,945.1,868.6z M369.1,427.4c-1.9-1.7-2.7-7.4-6.6-6.6
				c-2.8,0.6-2.2,4.7-2.2,7.5c0.1,4.6,1.3,8,5.3,6.8C367.9,434.3,369.7,432.7,369.1,427.4z M550.1,1247.7c0.5,6.6-1.8,13,2.8,17.2
				c0.5,0.4,2.3-0.6,2.5-1.5C557.2,1256.8,555.5,1251.9,550.1,1247.7z M648.7,1392.5c-4.7,8.1-2.5,15.1-1.6,22
				C650.1,1407.7,649,1400.8,648.7,1392.5z M1048.5,657.4c2.3,0.2,4-1.9,3.8-6.2c-0.2-4.7-2.8-5.2-5.5-5c-2.8,0.1-5.1,1.6-4.7,6.2
				C1042.5,656.8,1045,657.5,1048.5,657.4z M1476.5,1188.1c6.9,1.6,11.3,4.9,15.5-0.4c0.3-0.4-0.2-3.1-0.8-3.8
				C1486.6,1178.9,1482.6,1182,1476.5,1188.1z M631.4,463.3c-7.7-2.1-13.9,0.4-21.6,4.2C618.3,469.5,624.9,468.9,631.4,463.3z
				 M95.8,1323.8c3.6,11.1,7.3,14.4,13.2,11.9C107.2,1327.7,100.9,1330.5,95.8,1323.8z M1006.4,984.2c0.3-4.8-2.2-5-4.3-5
				c-2.7,0-6.4-1.4-6.3,5.1c0,3.1,0.6,6.6,3.2,7.1C1002.8,992.2,1004.5,987.8,1006.4,984.2z M922.3,1161c3.9,8.7,6.9,11.4,12.1,10.2
				C932.7,1162.5,927.8,1165,922.3,1161z M1348.4,1297.8c0.4,5.5,0.7,11,1.1,16.5C1352,1308.5,1352,1303.1,1348.4,1297.8z
				 M444.3,1272.6c2.6-7.6,8.5-8.6,6.4-16.3c-0.5-2-3.4-5.3-5.3-3.3C441,1257.4,443.3,1263.4,444.3,1272.6z M2455.2,846.9
				c1.9-0.4,3.6-1.3,3.2-4.6c-0.5-3.9-2.3-6.2-5.2-5.7c-2.3,0.4-2.3,3.2-2.3,5.8C2450.9,846.5,2452.9,847,2455.2,846.9z M742.2,976
				c-7.6-8.1-12.1-1.5-17,0.1C730.1,976,734.9,976,742.2,976z M1752.9,1317.7c4.4,0.3,9.1,2.3,9.7-5.9c0.1-1.8,1.2-4.5-0.9-4.9
				C1756.1,1305.9,1757.9,1317.5,1752.9,1317.7z M1979.4,858.3c-2.8,0.2-2.7,3.7-2.6,6.6c0.1,3,0.5,6.7,3.3,6.7c3,0,2.7-3.9,2.9-6.9
				C1983.3,861.4,1982.8,858.7,1979.4,858.3z M931.9,1123.1c-0.3-2.8,0.5-7.2-3.5-7c-2,0.1-3.4,1.7-3,4.9c0.4,3.1,1.4,5.9,3.7,6.9
				C931.8,1129,931.8,1126.1,931.9,1123.1z M1474.7,1223.8c0.3-2.9-0.1-5.1-2.6-5.1c-2,0-3.9,1-3.9,4c0,2.8,0.1,6.6,3.4,6.4
				C1473.8,1229,1474.3,1226.4,1474.7,1223.8z M959.7,415.8c-0.4-0.4-0.8-0.9-1.1-1.3c0,13.5,0.1,27.1,0.1,40.6c0.4,0,0.8,0,1.1,0
				C959.8,442,959.7,428.9,959.7,415.8z M1225.3,815.3c3.1,7,6.9,8.9,11.5,7.5c0.6-0.2,1.4-4.4,0.8-5.4
				C1234.4,811.3,1230.3,814.1,1225.3,815.3z M274.2,414.4c-0.2-3.4-2-2.7-3.3-2.6c-4.7,0.3-1.6,5.4-2.2,8.1
				c-0.5,2.1-0.7,6.3,1.9,5.2C274,423.6,268.6,415.1,274.2,414.4z M2603.8,839.5c-1.7-1.5,1.1-8-3.2-6.9c-2.7,0.7-7.1,2.1-7.1,7.7
				c-0.1,5.5,3.6,7.4,6.9,7.8C2604.5,848.5,2603,843.4,2603.8,839.5z M1860.6,1384.4c-2.3,0.5-3,2.4-2.8,5.4
				c0.2,2.5,0.2,5.3,2.7,5.4c2.4,0,3-2.5,2.7-5.1C1862.9,1387.8,1863,1384.7,1860.6,1384.4z M2405.9,1237.9c-1.4-4-3.9-5.6-6.9-5.4
				c-2,0.1-4.9-0.5-4.9,3.7c0,4.9,2.9,6.4,6,6.7C2402.8,1243.3,2405,1242.1,2405.9,1237.9z M833.4,1304.3c0.3,0,0.7,0,1,0
				c0-11.4-0.1-22.7-0.1-34.1c-0.3,0-0.7,0-1,0C833.3,1281.6,833.3,1293,833.4,1304.3z M287.9,1125.3c3.4-4.9,9.2-1.7,8-10.1
				c-0.6-3.9-3.1-7-5.4-6C285.4,1111.3,288.8,1117.3,287.9,1125.3z M110.6,1279.5c-2.8,6.6-6.8,8-6.6,14.8
				C110,1290.9,110,1290.9,110.6,1279.5z M897.5,963c-6.2-12.2-6.2-12.2-13.2-2.6C888.2,965.7,892.7,965.5,897.5,963z
				 M2377.6,1296.5c0.7,8.4-3.2,18.2,2.7,26.8C2377.3,1314.8,2378.9,1305.8,2377.6,1296.5z M23.8,1127.7c3.2-1.2,7-0.2,6.9-6.7
				c0-0.9-1.4-2.4-2.2-2.5C23.5,1117.9,24.2,1123.1,23.8,1127.7z M103.4,1354.9c-0.4-3.8-2.6-5.4-4.6-5c-3,0.6-1.8,4.6-1.8,7.2
				c0,2.2-0.2,5.4,2.8,4C101.9,1360.1,103.7,1358.8,103.4,1354.9z M552.1,745.1c0-0.4,0-0.7,0-1.1c-7.8,0-15.6,0.1-23.3,0.1
				c0,0.4,0,0.7,0,1.1C536.6,745.1,544.4,745.1,552.1,745.1z M426.3,711.7c-5,1.2-10.7,0.9-7.5,12.2c0.2,0.6,2,0.6,3,0.4
				c1.1-0.2,1-0.5,0.4-1.4C413.6,710.3,427.5,719.2,426.3,711.7z M2622,927.2c0.4-2.5-0.1-4.7-1.6-5.3c-2-0.8-4.5-1.4-5.8,2
				c-0.9,2.4,0.4,4.1,1.7,4.7C2618.4,929.7,2620.6,930.4,2622,927.2z M1017.5,1246c1.6-0.4,2.7-1.8,2.8-4c0.1-3.4-0.9-6.1-3.7-6.1
				c-2,0-2,2.6-2,4.5C1014.6,1243.2,1015.2,1245.6,1017.5,1246z M2261.6,1096.7c2.4,0,4.5-1.6,5-4.8c0.5-3.5-1.7-3.8-3.6-3.8
				c-1.8,0-3.8,0.1-4.1,3.5C2258.6,1094.4,2259.1,1096.6,2261.6,1096.7z M692,1176.7c-8.1-2.3-8.1-2.3-13,2.9
				C683,1180.1,686.9,1181.7,692,1176.7z M1018.1,1139.9c-3.2,6.8-3.9,11.6-1.1,16.7c0.3,0.5,2.3,0.2,2.6-0.4
				C1021.6,1151.5,1021.6,1146.7,1018.1,1139.9z M2682.7,939c-4.6-2.7-7.7-3.4-11.2,0.7C2675.5,943.2,2678.5,943.3,2682.7,939z
				 M1290.2,1220c-3.5-0.7-5.3-6.8-8.2-1.3c-0.6,1.2-1.4,3.7-1.1,4C1283.8,1226.2,1286.1,1222.9,1290.2,1220z M1004,605.2
				c-2.5-5.9-6.1-7.1-10.3-6.3c-0.4,0.1-1,3.2-0.6,3.9C996,608.2,999.8,607.1,1004,605.2z M415.2,728.7c-0.9,2.1-2,3.4-1.8,4.3
				c0.2,1.1,1.2,2.3,2.1,2.8c0.8,0.4,2.4,0.7,2.1-1.5C417.4,732.5,416.3,731.1,415.2,728.7z M2743.5,940.4
				c-4.4-3.6-8.4-1.8-14.1,0.2C2735,941.4,2739.1,944.8,2743.5,940.4z M2042.2,1221.1c-4.9-3.1-8.5-3.5-12.5-0.4
				C2033.3,1225.2,2036.9,1224,2042.2,1221.1z M1887.4,1190.9c-0.2,0.7-0.5,1.5-0.7,2.2c1.7,0.2,3.4,0.3,5,0.5
				C1890.3,1192.7,1888.9,1191.8,1887.4,1190.9z M138.9,478.2c0.7-0.3,1.3-0.6,1.9-0.9c-0.3-0.7-0.7-2.1-1-2.1
				c-0.8,0.2-1.6,0.9-2.4,1.4C137.9,477.1,138.4,477.6,138.9,478.2z M1864.2,1216.8c0.7-1.3,1.5-2.4,1.9-3.7c0.6-2.3,1-6-1.8-4.5
				C1862.4,1209.6,1862.7,1213.7,1864.2,1216.8z M702.9,1175.3c-3.2-1.5-5.6-1.9-7.6,1c-0.3,0.5,0.3,3.2,0.7,3.3
				C698.7,1180.5,700.7,1178.6,702.9,1175.3z M1010.9,1177.9c0.7-4.7,1.6-8.4-0.5-12.9C1009.5,1169.4,1009.3,1173.1,1010.9,1177.9z
				 M980,1347.5c-2.7-2.3-4.7-1.4-7.9,0C975.3,1347.5,977.4,1347.5,980,1347.5z M1307.1,1170.8c0.6-0.4,1.6-0.6,1.7-1.2
				c0.4-1.9-0.5-2.6-1.7-2.2c-0.5,0.2-0.9,1.2-1.4,1.8C1306.2,1169.7,1306.6,1170.2,1307.1,1170.8z M157.6,1240.8
				c-0.4-0.7-0.8-1.9-1.3-2c-0.6-0.2-1.7,0.4-1.8,1c-0.3,1.7,0.4,3,1.7,2.8C156.7,1242.5,157.1,1241.4,157.6,1240.8z M1010.5,1146.4
				c-1.9,4.3-1.9,7.4,0.1,10.4C1012.4,1153.7,1012.7,1150.6,1010.5,1146.4z M2386.9,1232.6c-0.7,1.6-1.7,2.8-1.6,3.7
				c0.1,0.8,1.5,2.1,2.1,1.9c0.7-0.2,1.7-1.7,1.7-2.8C2389.2,1234.7,2387.9,1233.9,2386.9,1232.6z M1750.4,1309.4
				c-0.4-1.3-0.7-2.8-0.9-2.7c-0.9,0.1-1.7,0.7-2.5,1.1c0.3,0.8,0.5,2.3,0.8,2.3C1748.6,1310.2,1749.4,1309.7,1750.4,1309.4z
				 M1153.6,1040c-0.2-0.7-0.4-1.4-0.7-2.1c-0.1-0.2-0.8,0-0.9,0.2c-0.1,0.7-0.1,1.4-0.2,2.2C1152.5,1040.2,1153,1040.1,1153.6,1040
				z M415.5,1330.6c0.7-0.6,1.8-1.6,2.8-2.5c-0.8-0.8-1.6-2.3-2.4-2.3c-1.3,0.1-3.3,0.7-3.6,1.8
				C411.7,1330.2,413.6,1329.9,415.5,1330.6z M571.2,1336.7c-0.6-0.4-1.5-1.2-1.8-0.9c-0.5,0.4-0.6,1.6-0.8,2.5
				c0.6,0.3,1.4,1.1,1.6,0.9C570.7,1338.7,570.9,1337.5,571.2,1336.7z M2818.3,1365.8c-1-0.3-1.8-0.8-2.7-0.7
				c-0.3,0-0.5,1.6-0.8,2.4c0.8,0.5,1.7,1.1,2.5,1.3C2817.5,1368.9,2817.9,1367.2,2818.3,1365.8z M2522.3,1222
				c0.5-0.2,1-0.3,1.4-0.5c-0.3-0.2-0.6-0.5-0.9-0.4c-0.5,0-1,0.3-1.4,0.5C2521.7,1221.7,2522,1221.8,2522.3,1222z M1222.8,743.1
				c0.3-0.3,0.5-0.5,0.8-0.8c-0.6-0.2-1.2-0.5-1.8-0.6c-0.3,0-0.7,0.3-1,0.4C1221.5,742.4,1222.1,742.8,1222.8,743.1z M2221,1458
				c-0.2,0.4-0.6,0.7-0.6,1.1c0,0.9,0.3,1.8,0.4,2.8c0.2-0.4,0.6-0.7,0.6-1.1C2221.3,1459.9,2221.1,1458.9,2221,1458z M627.5,903.8
				c0.7-1,1.2-1.8,1.8-2.7c-1-0.4-1.9-1.2-2.9-1.1c-0.7,0.1-1.3,1.3-1.9,2C625.4,902.6,626.4,903.1,627.5,903.8z M2002.6,1032.8
				c-0.5-1.7-0.5-3.6-2-2.8c-0.4,0.2-0.8,1.7-0.7,2.5c0.2,1.3,0.8,2.4,1.3,3.6C2001.7,1034.9,2002.3,1033.7,2002.6,1032.8z
				 M1125.7,1537.8c0.3-0.2,0.5-0.4,0.8-0.6c-0.3-0.4-0.6-0.7-0.9-1.1c-0.2,0.3-0.3,0.7-0.5,1
				C1125.2,1537.4,1125.5,1537.6,1125.7,1537.8z M419.7,973.2c-0.7-0.4-1.3-0.8-1.9-1c-0.2-0.1-0.5,0.5-0.7,0.8
				c0.4,0.5,0.7,1.5,1.1,1.5C418.6,974.5,419.1,973.7,419.7,973.2z M2240,1136.2c-0.3,0.3-0.8,0.7-0.8,0.9c0.1,0.8,0.4,1.6,0.7,2.4
				c0.3-0.3,0.8-0.6,0.8-0.8C2240.5,1137.9,2240.2,1137.1,2240,1136.2z M962.5,1132.8c-0.2-0.8-0.5-1.6-0.7-2.4
				c-0.2,0.2-0.7,0.4-0.7,0.7c0,0.8,0.1,1.7,0.1,2.6C961.6,1133.3,962,1133.1,962.5,1132.8z M13,1124.4l-0.5-0.1l0.3-0.9L13,1124.4z
				 M436.7,1283.6c-0.2-0.9-0.5-1.8-0.7-2.6c-0.2,0.3-0.7,0.7-0.7,1c0.1,0.9,0.4,1.7,0.6,2.6
				C436.2,1284.2,436.4,1283.9,436.7,1283.6z M1881.2,1069.2c0.2-0.2,0.6-0.3,0.7-0.6c0.1-0.4-0.1-0.8-0.1-1.2
				c-0.3,0.3-0.7,0.5-1,0.8C1880.9,1068.5,1881,1068.9,1881.2,1069.2z M423.4,1320.5c-0.3,0.6-0.6,1.2-0.9,1.8
				c0.6,0.3,1.1,1,1.7,0.9c0.4,0,0.6-1.1,0.9-1.7C424.5,1321.2,424,1320.9,423.4,1320.5z M646.1,1266.1l0.3-0.8l-0.6,0L646.1,1266.1
				z M2845.9,1150.2c0.2-0.3,0.6-0.5,0.6-0.8c0-0.3-0.3-0.6-0.5-0.9c-0.2,0.3-0.5,0.6-0.6,0.9
				C2845.4,1149.6,2845.7,1149.9,2845.9,1150.2z M590.4,1369.9l0.7,0.7l0.1-1.1L590.4,1369.9z M229.1,887.3l-0.4,0.7l0.6,0.2
				L229.1,887.3z M471.8,1219.4c-0.2-0.4-0.4-0.7-0.6-1.1c-0.3,0.3-0.7,0.6-0.7,0.9c0,0.3,0.4,0.7,0.6,1.1
				C471.2,1220.1,471.5,1219.7,471.8,1219.4z M1911.3,1452.5l0.4-0.9l-0.6-0.1L1911.3,1452.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#A7BBDA" d="M1.1,1241c-0.5,0.2-1,0.3-1.5,0.5c0.4-0.5,0.7-0.9,1.1-1.4L1.1,1241z"/>
			<path clip-path="url(#SVGID_2_)" fill="#48505B" d="M0.7,1039.7c-0.6,1.3-1.2,2.5-1.8,3.8C-0.5,1042.3,0.1,1041,0.7,1039.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2624.3,1569.2c-0.1-0.9,0.2-2-0.3-2.7c-0.4-0.8-1.8-8.1-1.2-9
				c2.6-4.3,4.5-9.2,8.9-12.2c0.8,2.4-1.6,5.6,1.9,7.2c6.9-1.9,3.9,5.9,7.4,7.8c7.5,3.9,1.3,5.5-1.6,7.5c5.2,5.9,4.7,11.2-1.4,16
				c4.9,2.2,9.8,5.5,15.1,3.1c2-0.9,5.3-1.5,6.3-4.9c-1.5-3.1-5.1,0.2-7.2-2.8c8.7-2.3,5.4-12.5,11.1-17.2
				c6.3,2.8,10.8,4.5,11.6-6.2c0.4-6.1,7.8-9.4,14.3-10.5c3.8-0.6,7.8-0.9,11.6-1.3c11.1-1,22.3-7.2,33.5-0.7
				c-0.2,1.1-0.3,2.2-0.5,3.3c-8.9-0.6-12.1,3.9-11.7,12.7c4.3-0.4,7.2-4.7,11.4-2.6c5.1,1.4,3.7-4.6,6.4-5.9
				c2.8-0.3,3.7-2.3,4.1-4.7c0-0.1-1.9-0.6-2.9-0.9c0.2-1.9,0.3-3.7,0.5-5.6c0.8-0.3,2.4-0.6,2.4-0.9c0.6-7,3.6-12,9.9-15.4
				c1.4-0.8,4.9-3.9,2.8-6.7c-1.9-2.7-5,0.1-7.5,0c-0.4,0-0.8-0.6-2-1.5c5-2,9.4-3.3,14.2-3.2c-0.4,1.5-0.9,2.9-1.1,4.4
				c-1.8,14.8-0.5,16.1,13.6,13.7c3.8-0.6,7.7-1.3,11.6-1.9c1.6,0.4,3.1,0.5,3.7-1.5c11,1.8,20.9-2.9,31.1-5.5
				c3.5-0.9,0.4-9.3,7-6.1c0,0,0.5,0.6,0.5,0.6l0.8-0.1c1.3,1.5,1.2,4.7,4.5,3.5c1.2,0.1,2.5,0.1,3.7,0.2c1.8-0.1,3.6-0.2,5.4-0.3
				c1.8-0.1,3.7-0.1,5.5-0.2c3.1,13-3.3,25.1-3.1,37.9c0.1,3.4,1.1,8.4-5.7,10.1c9.6,1.6,18.2-1.5,25.3,4.4
				c-4.5-0.2-9.1,0.5-13.5-1.1c-3.8-1.4-6.6-0.6-6.7,4.3c0,2.3,0.7,5.6-2.9,5.6c-2.7,0-5.6-1-6.1-4.3c-0.9-6-1.5-0.6-2.7-0.1
				c-11.2,4.1-8.3,14.3-9.1,22.5c-0.3,3.2,3.5,6.5,7.1,6.7c6,0.3,12.6,1.7,17.3-4c4.6,3-1.4,6.2,0.3,9.3c-1.8,0.9-5.2-2.7-5.7,2
				c-12.4-1-24.5,1-37,2.4c6.7,10.6,6.8,10.7,15.7,3c1.9-1.7,3.9-1.5,5.9-1.6c5.2-0.1,10.4,0,15.5,0c5.9,7.6,12.4,14.5,20,20.4
				c-1.4,6.3,2.8,11,4.8,16.3c1.8,4.7,4.7,1.6,7.2,0.6c1.4-0.6,4.8-1.7,3.2-2.4c-5.4-2.4-1.5-6.2-2.2-9.1c0.6,0.6,1.1,1.2,1.7,1.8
				c2.3,1.7,3.9,5.7,7.6,1.9c2.7-0.1,3.3-1.9,3.4-4.2c1.1,0.3,2.3,0.6,3.4,0.9c0.1,9.2-2.3,18.6,2.4,27.4c-4.1,9.1-4.1,9.1,6.8,9.9
				c-1.2,3.3-2.4,6.6-3.7,9.9c-3.9-1.7-5.4-0.4-5.3,3.8c0.1,4.8-0.1,9.6-0.2,14.4c-9-1.5-9.1-10.5-6.5-14.1c6.4-8.7,1-15.4-0.5-23
				c-0.4-1.7-0.2-4.1-2.9-3.6c-1.7,0.3-3.8,1.2-4.1,3.3c-0.4,2.7-0.5,5.5-0.4,8.3c1.2,39.2-0.2,78.4,1.4,117.6
				c2.7,64.5-1.2,129.1-1.4,193.7c0,1,0.4,2.3-2,2.9c0-42.1,0-84.1,0-126c-0.6,0-1.2,0-1.7,0c0,45.7,0,91.4,0,137
				c-0.5,0-1,0.1-1.5,0.1c-0.3-2.4-0.6-4.8-1-7.9c-2,3,0,6.6-3.4,8c-0.5-0.5-0.9-1-1.4-1.5c1.7-9.8,2-19.6,1.9-29.5
				c-0.3-36-0.1-71.9-1.5-107.8c-1.1-25.3,0.1-50.7-0.3-76c-0.4-24.7,1-49.3-1.2-74c-1.4-15.5-0.3-31.2-0.6-46.9
				c-0.1-6.7-5.2-12-10.2-12.2c-5.3-0.3-4.4,6.8-4.4,11.3c-0.1,24.7,1.4,49.3,1.5,74c0.3,56.1,1.4,112.1-1.2,168.2
				c-1.4,29.9-0.3,60-0.4,90c0,3,1,6.5-3.4,11.3c0-36,0-69.5,0-103c-1.8,1.2-2.1,2.4-2,3.5c1.2,34.3-2.8,68.5-1.9,102.8
				c0.1,2.4,0.5,5.4-0.6,7.3c-5.7,9.9,1.2,8.9,7.1,8.8c2.1,0,4.3,0,6.4,0c-3.6,9.9-12.8,4.1-18.9,7.1c-2.2-8.5-3-17.6-0.3-25.6
				c2.8-8.3,4.6-16.3,4.5-24.9c-1.2-76.6,3.3-153.3-3.6-229.7c-1-11.1,1.6-22.1-0.2-33c-2-12-1.4-23.8-0.2-35.8
				c1.2-11.5,1.6-23.4,0.1-34.8c-0.9-7.2-6.8-13.5-3.7-22c1-2.7-3.3-4-5.2-5.9c-0.8-0.8-1.8,0-1.8,0.9c0,4.6-3.3,4.3-6.3,4.7
				c-2.8,0.4-3.5,2.9-2.5,4.9c1.4,2.8,2.9-0.3,4.3-0.6c1.2-0.3,2.3-0.6,3.9,1.1c-4.7,5.4-5.1,12.1-5,19.1
				c0.9,38.3,1.1,76.6,1.2,114.9c0.2,61.8,4.5,123.8-2.5,185.7c-2.3,20.7-0.4,41.9-0.4,62.8c-1.1-103.9-5.3-207.7-4.5-311.6
				c-1,3.3-1,6.7-1,10c0,95.3-0.2,190.6,0,285.9c0,9.3-0.9,18.6-2.6,27.5c-1.2,6.4,0.9,7.1,5.7,6.7c5.7-0.5,8.9,1.3,7.7,7.7
				c-0.6,0-1.2-0.2-1.8-0.4c-2.4-2.1-4.8-2.2-7.2,0.1c-3.7,0-7.3,0.1-11,0.1c-3.5-4.3-0.3-10.3-3.6-14.7c-3.2-6.2,3.8-9.5,3.9-15.3
				c0.8-43.4,0.8-86.7,2.3-130.1c2.9-82.7,0.9-165.6,1-248.3c3.2-4.8,1.8-8.7-1.9-12.5c-3.1,3.5,0.8,6,0.1,8.8
				c-4.6,0.6-5.7-1.9-5.6-6c0.1-5.4,0-10.7,0-16.1c-3.1,3.6-6.6,3.9-10.3,3.5c-4.1-0.4-6.5,1-6,5.5c-3.2,4.1-1.2,8.3,0.1,12.6
				c0-4.2,0-8.3,0-12.5c1.5-0.4,2.9-0.8,4.4-1.3c2.8-0.8,6-1.4,7.2,1.7c1.5,4-2.8,2.7-4.4,3.8c-2.2,1.5-2.8,3.9-3.4,6.3
				c-1.8,6.4-0.3,12.7-0.3,19.1c0.2,40.4,0.5,80.9,0,121.3c-0.5,53.4,1.4,106.9-1.4,160.3c-1.4,28.1-0.3,56.3-0.4,84.4
				c0,5,0.4,9.8,2,14.6l-0.1,0.1c-2.1-0.2-3.2-1.3-3.5-3.5c-3.4-21.8-2.7-43.8-1.2-65.6c1.5-22.2,0.2-44.3,0.2-66.4
				c-0.9,14.8,0.4,29.7-1.2,44.3c-3,28.9,0,58-3.1,86.8c-0.7,6.7,0.6,14.7,10.3,15.7c1.9,0.2,3.5,1.5,3.2,3.7
				c-0.1,1-1.1,2.2-2.1,2.7c-4.7,2.9-12.4,0.1-13.3-5.4c-1.8-11.7-2.4-24.2-1.7-35.4c0.9-13.8,1.6-28,2.6-41.9
				c1.8-26.7,0.3-53.5,0.7-80.3c1-65.6-2.3-131.1-1.8-196.7c0.1-14.7,0-29.4,0-44.2c-0.7,0-1.5,0-2.2-0.1c0,29.9,0,59.7,0,89.6
				c-1,0-2,0-3,0c-4.3-40.7-1-81.5-2.4-123.1c-5.4,5.4-1.4,11.4-1.5,16.4c-0.3,130.2,0,260.5-0.5,390.7c0,12.8,0.8,26.5-7.1,38.4
				c-1.6,2.4,2.3,1.3,3.6,1.9c0.2,1.1,0.4,2.2,0.6,3.3c-1.3,4.5,1.1,7.8,3.2,11.3c-8.7,3.8-9.9,2.9-12-10.8
				c-0.6,2.7-0.6,3.9-1.2,4.8c-1.9,3.1-3.7,6.5-8.1,5.9c-3.9-0.6-4.2-3.7-4.4-6.9c-0.1-2.1-1-3.7-3.2-3.7c-7.4,0.2-6-5.1-6-9.5
				c3.4,1.6,6.8,3.8,11.1-0.8c-6.8-1.2-11.2-3.7-8-11.9c3.6,3.2,7.9,8.7,8.5-1.6c0.2-3.2,3.8-2.4,6.1-2.4c5.7,0.1,8.6-1.6,7.4-8.3
				c-0.6-3.2,0.6-6.7,0.6-10.1c1-43.9-0.8-87.7,1.4-131.6c2.2-43.5,0.6-87.1,0.9-130.7c0.2-33.3,0.1-66.7-0.6-100.1
				c-0.1-2.5,0-5,0.3-7.4c1.5-9.9-0.8-19.6-1.3-29.4c-0.3-6-4.4-9.2-10.4-9.6c-3-0.2-7,1.3-5.9-5c0.5-3.1-2.7-2.7-4.3-0.7
				c-2,2.5-2.1,5.7-2.1,8.8c0.1,14.5,0,29.1,0,43.6c-0.1,93.4-0.2,186.9-0.2,280.3c-2.7,2.8-1.5,6.4-1.5,9.7
				c-0.2,27.7-0.3,55.4-0.4,83.1c-4,0.5-3.4,3.7-3.2,6.2c0.4,5.9,1.1,11.9,1.7,17.8c-5.9-2-7.5-6.5-7.4-12.3
				c0.1-16.4-0.5-32.8,0.2-49.2c1.6-35.6-4.2-71.4,3.2-106.8c0.2-1-0.5-2.4-1.1-3.4c-2.3-3.9-2.1-8.3-2.1-12.6
				c0-35.9,0-71.8,0-107.6c0-20.2,0-19.9-20.4-20.1c-7.4-0.1-9.2,2.3-9.1,9.5c0.3,38.7,0.2,77.3,0.1,116c0,2.5-0.1,4.9-0.3,7.4
				c-0.1,1.4-1,2.3-2.3,2.4c-1.3,0.1-2.4-0.8-2.5-2.1c-0.2-2.5-0.3-4.9-0.3-7.4c0-33.7,0-67.4,0-101.1c0-23.9,0-23.9-23.3-22.6
				c-0.6,0-1.2,0-1.8,0c-3.7,0.1-4.7,1.5-4.7,5.8c0.4,40.8,0.3,81.6,0.4,122.5c0,1.2,0,2.5-0.1,3.7c-0.1,1.3-0.5,2.7-1.9,2.5
				c-1-0.1-2.4-1.1-2.6-2c-0.5-2-0.7-4.2-0.7-6.4c0.1-40.2,0.1-80.4,0.4-120.6c0-4.6-0.7-5-4.9-4.5c-11.4,1.5-12.3,0.6-12-10.7
				c1.1-39.2-2.2-78.4-2-117.6c0-0.9-0.4-1.7-0.6-2.6c-0.5,0-1,0-1.6,0c0,41.6,0,83.2,0,124.9c-1.2,0-2.4,0-3.5,0
				c-2.8-45.3-1-90.7-1.5-136.1c1.4-0.8,1.9-2,1.4-3.6c-0.1-0.2-0.5-0.3-0.7-0.5c-0.2,1.4-0.4,2.7-0.7,4.1
				c-2.8,5.9-3.7,12.1-3.7,18.6c0,36.2,0,72.3-0.2,108.5c0,3,1,6.5-2.5,10.4c-5-44.8-1.5-88.4-3.9-131.9
				c-1.7,48.7-0.3,97.5-5.7,146.1c-3.6,31.7-1.8,64.1-2.5,96.2c0,2.1-0.1,4.6-1.2,6.2c-7.7,10.5-18.3,17.8-29.8,27.5
				c1-4.6,1.4-6.6,1.9-8.7c4.7-0.8,7.4-3.8,8.8-8.2c0.2-0.5-0.9-2.1-1.2-2.1c-4.8,1.2-5.8,5.6-7.6,9.3c-0.1,0.3-0.1,0.6-0.1,0.9
				c-4.6,2.6-11.2-5.1-14.6,2.7c-2.5,5.8-2.5,11.9-0.8,18.1c0.9,3.4,2.7,7.8-3,8.7c-5.7,0.9-5.4-3.5-5.3-7.3
				c0.3-14.1-10.4-24.6-11.9-38.1c-0.1-0.9-1.5-2.2-2.6-2.4c-10.6-2.4-8.6-8.1-3.7-14.5c1.4-1.8,2.2-4.1,1.5-6.1
				c-3.7-10.7,0.2-21.4,0.1-32c-0.1-12.6-1.3-24.9-3.1-37.5c-3-20.5,1.3-41.6-1.7-62.4c-0.3-2.4,1.3-4.6,3.9-5.6
				c4.8-1.8,9.4-3.9,14.9-3.8c10.1,0.1,20.2,0.9,30.3-1.1c2.2-0.4,3.3-0.4,3.3-2.5c0.1-17.9,0-35.8,0-53.7
				c-3.4,6.6,0.2,14.1-4.6,20.8c-3.9-16-2.3-31.8-2.9-47.4c-12.7,1.7-23.9,14.7-19.4,23.5c2.8,5.5,4,11.4,6.8,16.7
				c1.5,2.9,0.8,4.2-1.3,6.2c-5.4,5.3-10-1.1-15.2-0.8c-3.1,0.2-1.7-4.6-1.8-7.2c-0.6-11.7-1.5-23.3-4.2-34.7
				c-1.4-5.8-10.6-11-14.2-8.7c-10.8,7-16.9,15.7-14.9,30c1.5,11.1,1.7,23-7.4,33.2c0-12.9,0-25.1,0-37.3c-0.7,0-1.4,0-2.1,0
				c0,15.7,0,31.3,0,48.9c-4.9-6-1.3-11.4-4.9-16.8c0,14.9-0.1,28.5,0,42.1c0,5.8-2,5.2-5.1,1.8c-1.3,3.4,0.9,7.4-2,10.5
				c-3.6-2.4-0.8-6.2-3.2-9c-0.5,2.3-0.9,4.3-1.3,6.4c-2.3-1.9,0.2-5.9-4.3-7c0,14.2,0,28,0,41.7c3.2-1.8-1-7,3.6-8.3
				c2.7,3.2,0,6.7,0.7,9.7c1.4,5.9-1.7,6.2-5.9,6c-3.3-0.2-6.7,0.5-9.9-1c-9.2-4.4-19.7-2.1-29.1-6.1c-2.8-1.2-6.6-0.2-10,0
				c-1.8,0.1-2.7,1.3-2.7,3.1c0,1.2,0,3.2,1.4,2.8c10.2-3.1,4.7,5.3,5.5,8.3c1,4.1,0.3,8.7,0.3,13.1c1.6-3.7,1.5-7.3,1.7-11
				c0.2-3.8-1-8.1,3.6-11.8c1.4,46.2-3.1,91.5-1.6,137c-1.2,0-2.4,0-3.7,0c0-24.8,0-49.6,0-74.4c-1.8,1.8-1.8,3.7-1.8,5.5
				c0,21.7,0,43.3-0.1,65c0,2.2,1.2,5.9-2.3,5.9c-2.9,0-3.1-3.3-3.1-5.8c0-4.5,0-8.9,0-13.4c-3.2,5.5-0.1,11.9-5.2,17.3
				c0-8.8,0-17.7,0-26.5c-3.5,8.1-0.3,16.7-5.6,24.6c0-13.1,0-26.3,0-39.4c-0.8,0-1.5,0-2.3,0.1c0,26.9,0,53.8,0,80.8
				c1.9-4.9,1.7-9.8,2-14.7c0.3-4.5-1.8-9.5,3.1-13.3c-1.4,52.3-1,104.5-1.5,155.8c1.4-2.2,2.2-5.7,2.1-9.5
				c-1-41.7,3-83.4,1.8-125.1c-0.2-5.3-0.1-10.5,0.1-15.8c0.1-2.5-0.2-5.5,4-6.9c-1,52.5-1.9,104.5-2.9,156.6
				c2.3-0.4,2.5-2.4,2.4-4.1c-0.5-12.7,2.2-25.1,2-37.9c-0.4-34.9-0.1-69.7,0-104.6c0-2.9-1.4-6.6,3.5-9.5c0,45.2,0,89.3,0,135
				c2.8-4.4,1.8-7.5,1.8-10.4c0.1-34,0.1-68.1,0.1-102.1c0-7.9,1.2-15.6,4.8-23.7c2.6,14.1-1.1,26.8-1.3,39.8
				c-0.6,37.7,2.4,75.4-1.9,113c-0.2,2.1-2.9,5,1.4,5.8c3.1,0.6,5.3-1.1,6-4.3c0.7-2.7,1.8-5.4,1.8-8.1c-0.4-17.3,2.4-34.4,2-51.8
				c-0.6-28.1-0.2-56.3,0-84.5c0-3.2-1.6-7.2,3.8-10.1c0,36.8,0,72.6,0,108.4c0.8,0,1.6,0,2.4,0c0-36,0-72,0-107.9
				c0.9,0,1.8,0,2.8,0c0,29.1,0,58.3,0,87.4c1.9-10.2,1.9-20.3,1.9-30.5c0-9.9-0.3-19.8,0.1-29.7c0.3-9.5-1.6-19.3,2.3-28.4
				c3.6,48.9-2.1,97.6,0.7,146.5c-0.6-13.6,2.8-26.8,2.6-40.4c-0.5-32.2-0.1-64.4,0-96.5c0-2.8-0.1-5.7,0.7-8.2
				c0.6-2.1,3.3-4.1,4.3-0.5c2.1,7.4,1.9,1.7,2.6-0.3c0.6-1.7,2-2.1,3.7-2c2.2,0.2,3.2,1.6,3.2,3.7c0.1,2.5,0.1,4.9,0.1,7.4
				c0.2,45.1,0.2,90.2-1.8,135.3c-1.4,0.8-2,2-1.4,3.6c0.1,0.2,0.5,0.3,0.7,0.5c0.2-1.4,0.5-2.7,0.7-4.1c2.9-8.8,3.4-18,3.5-27
				c0.4-35.9,0.2-71.8,0.3-107.6c0-3-1.1-6.5,3.2-10.9c1.6,50.5-0.6,98.9-2.8,150.1c1.6-5,3.1-7.5,3-10c-0.4-10.8,2.3-21.4,2.2-32.3
				c-0.4-33.1-0.2-66.2-0.1-99.3c0-2.9-1.5-6.6,3-9.1c2.9,46.6-2.9,92.4-0.1,138.2c0.4-21.9,1.5-43.7,2.3-65.5
				c0.9-22.5,0.2-45.2,0.2-67.7c0-2.1-0.4-4.7,2.6-5.1c2.4-0.4,3.9,1.4,5.2,3.2c1.4,2,1.7,4.2-0.8,5.4c-2.3,1-3.3,2.4-3.3,4.9
				c0,10.8,0,21.6,0,33.4c2.3-2,1.1-4.2,3.2-4.5c0.7,5.5,6.6,8,6.3,15.1c-1,22.8-0.4,45.6-0.4,68.4c0.8,0,1.6,0,2.3,0
				c0-14.5,0-28.9,0-43.4c1.4,0.1,2.9,0.2,4.3,0.3c-7,22.2,2,45.6-8.2,69.3c4.9-4.3,9.9-4.8,13.6-9.7c3.5-4.7,9.7-8.4,17-6.4
				c6.5,1.9,12.7,2.6,17-4.4c1.1-1.8,3-2.3,4.8-1.4c1.9,0.9,0.4,2.7,0.5,4.1c0.6,10.1-2.7,20-0.8,30.1c1.4,7.2-3.5,8.7-9,9.7
				c-9.5,1.8-18.3-3-27.8-2c-16.7,1.8-33.3-1.8-50-1.9c-2.2,0-5.8,0.6-4.9-3.3c0.7-3,3.4-1,5.3-0.9c10.4,0.9,20.7,2.4,31,3.9
				c13.7,2,28,0.5,42,0.4c2,0,5.1,1.4,5.7-2.5c-3.2-2.1-6.6-1.2-9.8-1c-19.5,0.7-39-0.3-58.1-3.2c-12.4-1.9-22.9,3.6-34.4,4.3
				c-6,0.4-10,8.8-17.8,4.9c-2.3-1.2-5.2,1.6-4.5,5.2c0.4,2.2,0.5,3.6-2.2,3.7l-0.1-0.1c0.3-4.6-2.1-6.1-6.2-5.3
				c-5.3,1-10.6,2.2-15.9,3.3c-2.9,0.6-6.7,0.2-7.7,3.6c-1,3.4,5.6,0.6,4.6,5.5c-4.3-1.9-6.3,6.6-10.8,2.6c-3.5-3.1-5.4-0.3-7.9,0.8
				c-1.4,0.6-1.2,3.4-4,2.3c-17.9-6.8-36.3-2.7-54.5-1.7c-3.7,0.2-5.7,0.7-3.9,5.4c1.8,4.8,2.6,10,3.9,15c-2.7,3-5,6-1.6,11.7
				c1.3-4.3,3.2-6.8,6.5-7.9c3.9,1.8,7.8,3.6,13.7,6.3c-4.8-7.8-3.2-13.1,3.5-12.9c3.6,0.1,5,0.6,7.5-2.5c1.3-1.6,6.2-3.1,8.3,0.2
				c2.3,3.6-2,4.2-3.8,5.6c-2.3,1.8-5.5,2.5-4.8,6.7c0.7,4.4,3.9,4.9,7.2,5.7c4.1,1.1,8,1.8,12.2-0.3c4.3-2.1,8.6-3.4,13.4,0
				c2.5,1.7,5.7-0.6,7.9-3.2c1.1-1.3,1.8-4.9,4.6-2.1c1.7,1.7,2.9,4.2,1.3,6.7c-1.3,2.1-2.8,3.7-5.7,3.9c-7.6,0.5,5.9,6.9-1.9,7.7
				c0.3,0.8,0.5,1.9,1.1,2.3c0.4,0.3,1.5-0.2,2.2-0.6c4.9-2.9,9.8-5.7,15.9-5.5c2.1,0.1,4-1.7,3.3-4.5c-1.7-7.1,4.6-1.5,6.4-4
				c1-1.5,5.2-0.4,5,3c-0.2,4.5,3.6,4.9,5.5,7.3c3.6,4.5,12.1,3.6,12.2,12c0,1.2,3.2,2.1,3.2-1.2c0-2.7,1.7-2.9,3.5-3.3
				c6.8-1.4,13.6-2.8,22.8-4.6c-4.5,3.6-10.1,3.5-8.4,9c0,0-0.1-0.4-0.1-0.4c-0.6,0.7-1.2,1.3-1.8,2c0.6,1.1,1.2,2.3,1.8,3.4
				c-0.6,0.6,0.5,2.7-1.7,2.1c-0.9-2.6-3-4.7-5.4-3.5c-7.6,3.9-16.4,0.4-23.9,4.2c-2.6-7.6-7.6-4.1-12.1-2.7
				c-7.5-0.1-9.5,4.9-10.1,11.3c0.4,2.5-1,6.8,2.6,6.6c10-0.3,6.9,4.9,4.7,10c-4,1.2-3.7-0.5-16.5-2.8c-9.3-1.6-19.7-3.5-29.3,0.9
				l0.1-0.1c-6.5-1-13.3-0.8-19.5-3.5c4.8,0,9.7,0,14.5,0c-8.3-3.8-7-12-10-17.6c-0.9-1.8,0-5.8-4-4.4c-7.1,2.6-14.4,0.3-21.5,1.6
				c-16.3,3.2-33.1,0.2-49.4,3.8c-0.5-0.1-1-0.3-1.5-0.5c1-5.9,8,0.3,9.5-5.2c-6.4,0-12.6,0-18.9,0c-9.1,0-18-3.5-27.2-2.4
				c1.1-4.8,7-0.8,9-5.9c-4.1-1.2-7.4-1.9-11.5,0.8c-3.1,2.1-8.2,4.8-6.1-3.6c0.4-1.5-0.1-2.5-1.2-3c-10.8-4.8-2.9-8,1-11.5
				c2.9-0.7,7.4,0.9,6.8-4.9c2.4-0.1,4.7-0.3,7.1-0.4c0.6,2.9,1.8,3.9,4.1,1.3c2.7-0.5,5.3-1.1,8-1.6c2.7,0.1,4.9-0.4,4.6-3.9
				c1.4-0.7,4.1-1.4,4.1-2c-0.1-2.7-3-0.9-4.1-1.9c-2.2-2.1,0.6-1.7,1.4-2.4c2.7,1.2,5.4,0,6.2-2c0.9-2.3-2.9-2.6-3.8-4.5
				c1.7-7.2,9.8-0.7,12.4-6c10,3.3,4.6-11,12.5-10.6c-12.2-4.9-22.1-13.9-37.2-14.6c3.6-2.7,8.1-2.7,6.1-7.2c-1.7-3.6-4.4-4.9-8-4.3
				c-3.8,0.6-7.6,1.6-11.4,2.4c-4.7-10.3-1.1-21.2-2.1-31.8c4.4-2.7,8.3-5.9,10.1,2.9c0.9,4.2,4.8,2.2,7.2,0.2
				c3.5-2.9,4.4-7.2,5.2-11.4c1.6,0.1,3.2,0.1,4.7,0.2c-0.1,14.8-9.1,21.7-24.4,24.6c19,3.8,22.6,2.4,27.8-9.4
				c2.1-1.3,2.3-3.5,2.3-5.7c0-16,1-32,1.4-48c1-36.4,0.4-72.8,0.3-109.3c0-3.3,1.1-6.8-1.5-9.7c-0.9-3,0.9-6.5-2.2-10.6
				c1.4,13.6-1.9,26,2,38.1c-0.6,15.5,2.1,31.1-1.4,46.6c-0.8,0-1.6,0-2.4-0.1c-2.5-2.2-1.6-5.2-1.6-7.8c-0.1-20.6,0-41.2-0.1-61.8
				c0-3,0.7-6.3-1.9-8.7c-0.1-3.7-1.3-7.5,0.6-11.1c5.2-1.1,2.9-5.4,3-8.3c0.9-18.1-0.4-36.2,1.6-54.4c1.5-13.9,0.3-28.2,0.3-42.3
				c-1.3,14.7-1.4,29.5-3.4,44.1c-8.7,4.3-16.2,9.4-14.8,21.1c0.2,1.9,0.9,5.6-1.5,5.6c-10.4,0.2-5.9,8.1-5.2,11.9
				c2.6,13.8,0.4,27.5,0.8,41.2c-2.9-4.6,0.9-10.5-3.2-14.8l0,0c-0.3-0.8-0.5-2.2-1-2.4c-3-0.9-2.5,1.7-2.6,3.3
				c-0.5,5.1,2.2,11.9-2,15.1c-6.2,4.7-6.4,10.7-7.2,17.1c-0.2,1.4,0.1,3-2.3,3c-4.9,0-10.3,3.8-10.3,7.9
				c-0.1,24.9-0.1,49.8-0.1,74.7c1.5-1.5,1.7-2.9,1.7-4.4c0-19.8,0-39.6,0.2-59.4c0-4-2.1-9.5,5.9-9.6c8.2-0.1,4.8,5.7,4.9,9
				c0.4,14,0.2,28.1,0.2,42.1c2.3-7.3,0.8-15.2,5.4-23.4c0,29.1,0,56.9,0,84.6c0,1.2,0.2,3.1-0.5,3.6c-8.2,5.4-1.7,10.2,0.5,15.2
				c-8.6,1.2-1.9,7.8-3.7,10.5c-4.6,7.4-3.1,15.2-3.5,22.9c-30,6.7-60.6,7.5-91,10.7c-3.9,0.4-8.1,1-9.2-4.6
				c13.4,0.9,25.6-5.4,38.8-6.4c17.1-1.3,34.2-3.7,51.3-5.6c3.1-0.4,7.3,0.4,6.3-5.2c2.5-3.1,3.2-6.1-1.6-7.2
				c-4.8-1.1-9.8-1.5-14.8-2.2c-0.9-0.8-1.8-1.6-2.7-2.4c-5-12.5-2.4-25.5-2.9-38.4c0-2.5,0-5.1,0.1-7.6c0-12.4-0.5-24.8,1.1-37.1
				c1.6-3.1,1.1-6.4,0.5-9.6c0.1-1,0.1-2.1,0.2-3.1c-0.1-2.7-0.2-5.3-0.3-8c0-1.6,0-3.2,0.1-4.8c0.1-0.7,0.3-1.4,0.4-2.2
				c0-3.2,0.1-6.4,0.1-9.6c-0.1-2.4-0.1-4.8-0.2-7.2c0,0-0.1,0.1-0.1,0.1c3.6-6.7,1.5-13.8,1.6-20.8c0.1-12.9,0.2-25.8,0.3-38.7
				c-0.3-8.7,3.1-17.2,1.7-26.1c0.4-19.8,0-39.5,2.1-59.2c5,1.2,3.2,5.3,3.3,8.4c0.2,13.8,0.1,27.6,0.1,41.3
				c2.1-5.8,1.3-11.8,2.2-17.5c2.8-17-2.2-36,14.2-49.7c5.1-4.3,4-13.7,3.8-21.1c-0.2-6.8-0.5-13.2,5.4-18.7
				c3.2-2.9,3.6-7.9-1.2-11.9c-4-3.3-3.4-18.2,0.1-22.1c4-4.5,6.2-21.1,3.4-26.1c-8.7-15.5-3.3-37,11.7-46.3c2.7-1.7,7-1.8,5-7.7
				c-1.1-3.2,0.9-5.5,5.5-2.9c6.8,3.9,8.5,9.7,8.7,16.9c0.6,27.5-2.8,54.8-2,82.3c0.3,10.7,1.3,21.6-1.4,32.3
				c-0.4,1.5-0.6,3.7,1.9,4.2c2.1-1.3,1.5-3.4,1.4-5.1c-1.1-29.3,2.5-58.6,2-87.9c-0.1-3.7,1.1-7.5,1-11
				c-0.6-13.6,6.8-28.9-7.6-39.9c8-1.9,12.8,0.6,13.3,8.3c0.7,10.1,1.3,20.3-0.3,30.5c-2.2,13.7-0.5,27.4-0.3,41.1
				c1.5-17.1,2.8-34.3,2.8-51.5c0-8.5,6.7-14.1,7.2-22.1c0-0.7,1.5-3.1,4.1-2.1c6.9,2.4,13.4-0.3,19.9-2.2c0.6,0.2,1.1,0.2,1.7,0.2
				c0.9,4.1,2.7,5,5.6,1.5c4.5,2,6.3-5.4,10.8-3.2c3.9,19.7,1.5,39.5,1.7,59.3c-5.1,1-3,5.2-3.4,8.2c-0.6,4.2,1,8.8-3.5,12.7
				c0-6.3,0-12.6,0-20.9c-3.5,5.6-0.1,10.6-3.7,13.8c-3.4-1.5-0.3-6.4-4.8-7.5c-0.8,4.8-2.1,9.3,0.8,13.4c4.6,6.3,5.6,23.8,0.2,33
				c0-10.2,0-18.3,0-26.4c-3.2,6.8,0.2,14.2-3.8,21.6c-3-4.7,0-9.9-4.2-13.4c-1.1,11-0.9,22-1.1,32.9c-0.1,2.8,1.8,6.9-2.3,8
				c-5.1,1.3,0.5-5.7-3.5-5.8c-3,1.9,0.4,7.8-3.9,7.3c-4.6-0.6-1.4-4.9-1.4-7.7c0.1-12.5,0-25,0-37.5c-0.1,17.3-4.2,34.3-3.3,51.5
				c0.4,7.2,1.5,13.6-9,11.6c-1-0.2-3,0.8-3.1,1.4c-1,7.5-5.6,15.1,0.2,23.3c2-7.7-1-15.2,2-22.2c5.1,2.5,8,4.9,8.1,11.1
				c0.7,31.5-2.1,63-2.2,94.5c0,1.6,0.5,4-3,3.7c-2.7-33.3,3.5-66.8,0.1-100.1c1,17-2.9,33.7-2.4,50.7c0.8,27.5-3.1,54.9-1.9,82.4
				c0.1,1.6,0.7,3.9-3.3,4.6c0-12.2,0-24,0-35.9c-5.3,57.9-3.4,115.9-3.1,173.8c1.7-41.5-0.8-83.2,5.1-124.6
				c-0.9,43.2-0.3,86.3-0.6,130c3.3-3.2,2-6.8,1.9-9.9c-1.1-38.3,3-76.5,2-114.9c-0.1-2.4-1.2-5.7,3.4-6.8
				c0.2,46.3,0.8,92.3-1.8,138.4c2.2-8,3.6-16.1,3.6-24.2c0-35.8,0.2-71.6,2-107.4c0.1-1.8-1.1-4.5,3.3-5.4
				c0.9,46.3-2.1,92.3-2.7,141.1c3.3-11.4,4.7-20.4,4.7-29.5c0.1-33.4,0.1-66.8,0.2-100.2c0-3.1-0.9-6.5,1.1-9.3
				c2.3,0.6,2.3,2,2.3,3.1c-0.5,41.4,2.1,82.9-2.2,124.1c-1,9.3,3.5,10.6,9.3,12.3c4.3,1.2,9.5,0.9,9.6,7.5
				c-7.4,1.3-15.2,1-21.3,6.5c-1.5,1.3-3.1,2.2-4.9,1.6c-6.6-2.1-13.1,0.9-19.7,0.1c-1.5-0.2-4.2-0.9-4.4,1.7
				c-0.1,1.6,0.9,3.8,2.2,4.8c1.6,1.2,3.9,1.5,5.9,2.2c1.8,6.6,9.9,2.4,12.6,7.5c0.7,0.7,1.5,1.3,2.2,2l0,0
				c4.4,6.9,6.4,7.3,10.9,1.9c11.2-3.7,22.4-1,33.3,1.2c9.7,1.9,17,0.7,19.8-5.5c0.4-1.5,0.9-3,1.3-4.5c-1-1.9,3.2-7.1-3.8-5.5
				c-1.1,0.3-6.3-1.8-7.1-5.8c2.9-1.1,5.6,2.7,9.6-0.5c-10.6-5.1-17.1-13.3-20-24.3c0.4-0.5,1.1-0.9,1.1-1.4
				c0.7-26.1,0.9-52.3-0.1-78.4c0-0.5-0.7-0.9-1.1-1.4c-0.1-8.2,0.2-16.4-0.3-24.6c-0.3-5.5,2.2-11.5-2-16.5
				c-4.4-3.8,2.1-11.4-4.7-14.4c-1.9-14.7,0.7-29.3,1.1-43.9c1.2-46.9-0.8-93.8,1.9-140.7c1.1-18.5,0.2-37.1,0.3-55.7
				c0-3-1-6.2,1.5-8.8c3.9-3.5,1.8-8.1,2-12.1c1-19.1-2.3-38.4,7-56.9c4.4-8.7,10.2-11.5,18.1-13.3c1.5-0.3,3-1.2,3.7,0.9
				c0.6,1.9-0.9,2.4-2.1,3.4c-5.3,4.3-12.1,7.1-13.9,14.8c-1.6,0.8-1.8,2.2-1.8,3.7c-7.3,23.1-1.8,46.6-2.9,69.9
				c1.1-23.3,0.5-46.7,2.8-69.9c1.6-0.8,1.8-2.2,1.8-3.7c6.9-2.3,13.4-5.5,18-11.3c1.1-1.4,1.4-3.7,3.7-2.4c1.8,1,3.9,2.6,3,5.1
				c-0.7,2.1-3.9,4.3,1.1,4.8c3.9,0.4,5.4-1.5,5.4-5.4c-0.1-4.9-1.8-11.7,0.7-14.4c7.9-8.8-2.9-18.3,2.3-26.5
				c-6.6-4-1.6-7.2,0.7-10.5c6.1-0.8,12.2-1.6,18.3-2.4c3.1,5.5,9.3,4.5,13.8,5.3c8.5,1.5,10,7.1,8.9,13.3c-1.1,6.7-1,13.2-0.5,19.8
				c3.9-0.5,7.2-3.4,10.7-0.3c0.1,1.5-0.1,3.9,1.5,4c6.9,0.1,9.4,5.3,12,11.9c1.6-5.6,1-10.2,4.7-13.2l0,0c0.9,3.7,1.7,7.4,2.6,11.2
				c2.8-4,10.2-7,2.8-13.2c0-1.8,0-3.7,0-5.5c4.5,0.1,6.5,11.8,12.9,2.8c1.7-2.4,4.7,4.9,6-0.8c4.6,0,9.2-0.1,13.8-0.1
				c10.1,3.5,19.7,8.7,30.9,7.1c4.5-0.1,6.5-0.4,8.3-6.8c2.4-8,1.8-8.8-5.4-7.9c0-1.8,0-3.6,0.1-5.3c5-1.4,10-5.7,9.7-9.4
				c-0.4-6.5-6.1,1-9-1.5c-0.2-0.2-0.5-0.4-0.7-0.6c0-1.2,0.1-2.4,0.1-3.6c3,0.5,4.7-0.8,4.7-3.9c0.1-5.7-3.8-12.4,4.3-16.1
				c0.7-0.3,0.8-3.8-2-5.1c-3.2-1.5-6-3.9-9-5.8c-7.5-4.8-7.3-8.1,0.7-12.7c0.5-0.4,1.1-0.5,1.6-0.3c3.3,7,10.1,9.1,16.4,11.3
				c3,1.1,7.6,1,7.8-4.7c0.3-0.4,0.7-0.8,1-1.3c2.1-0.1,4.2-0.3,6.3-0.4c-1.2,2.3-1.9,4.8-1.6,7.4c-6.8,1.8-5.5,6.2-3.3,10.8
				c0.1,1-1.6,3,1.3,2.7c0.9,0,1.7,0,2.6-0.1l0.4,0l0.5,0.4c0.3,2.7,3.7,5.9,1.5,7.7c-5.3,4.1-5.9,7.2-0.2,11
				c-9.6,5.1,0.3,13-2.3,18.3c-6.1,12.6-4.1,17.2,9.9,18c6.3,0.3,11.6-5.4,8.4-10.2c-3.3-5-1-5.7,2.5-6.8c3.7-1.2,4.2-4,4.2-7.3
				c0-5.6-3.8-0.9-5.2-2c-1.1-0.8-1.5-2.6-2.3-3.9c6.9-1.8,7.4-9.4,10.3-13.9c4.5-7.1,11.1-5,16.2-8.2c-2.8,4.1-5.5,8-12.1,9
				c3.6,2.8,11.1,1.5,6.9,8.4c-0.9,1.5,0.9,2.4,2.4,3.1c5.2,2.2,5.6,3.9-0.2,6.5c-5,2.2-3.8,5.6,0.1,8.2c1.9,1.2,5.9,3.1,2.4,4.6
				c-11.5,5-2.4,6.9,2,10.4c-10,2.7-6.1,8.9-3.8,13.8c3.4,7.1,11,5,16.8,6.1c3.1,0.6,5.4-3,4.7-6.9l-0.2,0.2c1.8,0.5,1.6-1.1,2.1-2
				c4.6,1.1,10.9-0.9,11-3.8c0.1-5.1-5.7-5.7-10.3-5.4C2624.9,1569.3,2624.6,1569.3,2624.3,1569.2z M2390.3,2081.7
				c0.1-0.2,0.3-0.5,0.4-0.7C2390.6,2081.2,2390.5,2081.5,2390.3,2081.7c-0.1,0.3-0.2,0.5-0.4,0.8
				C2390,2082.2,2390.2,2082,2390.3,2081.7z M2794.5,1554.4c1.5,2.4,3,4.7,6.4,2.6C2799.4,1554.3,2796.9,1554.4,2794.5,1554.4
				c-2.7-3.1-3.8-8.9-10.8-6.1C2786.9,1552.3,2790,1554.7,2794.5,1554.4z M2276.9,1844.1c0.2,0.5,0.5,1.1,0.8,1.6
				c0,0.1,0.6-0.1,0.6-0.1c-0.3-15.3,2.3-30.7-1.3-46c-0.3-0.1-0.6-0.3-0.9-0.3c-0.2,0-0.7,0.3-0.7,0.4
				C2275.7,1814.5,2273.1,1829.4,2276.9,1844.1z M2416,1747.6c0,0.6-0.1,1.2-0.1,1.9c-3.7,3.2-3.4,7.6-3.5,11.9
				c-0.9,33.6-0.1,67.3-1.4,100.9c-2.2,54.6-1.7,109.3-2.4,164c0,2.5,0.2,5-0.1,7.4c-1.7,16.8-7,29.7-24.4,29.4
				c-2.3,0-4.3,0.5-4.5,3.4c-0.1,3.5,2.2,3.7,4.8,4c15.3,2,21.7-2.3,27.5-18.5c2.9-1.9,2.1-4.9,2.1-7.6c0-29.4,0-58.7,0-88.1
				c0-2.1,0.5-4.4-1.4-6c0.3-10.7-2.2-21.4,1.3-32c3-2.3,1.9-5.6,1.9-8.6c0.1-21,0.1-42,0-63c0-2.7,1-5.6-1.5-7.9
				c-0.8-16.9-0.4-33.7,1.6-50.6c3.1-17.8,2.6-35.6,0.2-53.5c0.4-7.6-2.2-15.3,1.6-22.6c2.2-1.9,1.8-4.4,1.8-6.9
				c0-20.1,0.1-40.2,0-60.3c0-2.4,0.6-5-1.6-6.9c0-5.5,0-11,0-16.6c-2.5,18.1-2.1,36.3-3.4,54.4c-1.5,20.9-0.4,42-0.4,63
				C2414.1,1741.9,2412.9,1745.3,2416,1747.6z M2341,1814.6c0.1-3.9,0.1-7.8,0.2-11.7c2.2-0.1,3.2-1.5,3.2-3.6c0-22.1,0-44.1,0-66.2
				c-0.6,0-1.2,0-1.7,0c0,6.7,0,13.4,0,20.1c-2.9,8.4-3.1,17.3-3.4,26c-1.3,35,1.7,70-1.9,105c-0.3,2.6-0.7,5.8,3.3,6.3
				c-3.2,7.5,1.2,14.5,2.4,21.8c0-7.9,0-15.8,0-23.7c4.8,1.3,3.2,5.4,3.2,8.5c-0.6,45.3,1.4,90.7-1,136c-0.3,5.9,2.3,11.5,6.5,15.7
				c1.7,1.7,3.8,4,7.1,1.7c5.3-3.7,17.3-2.7,21.8,1.5c6.3,5.8,13.6,4.7,17.4-2.8c2.1-4.1,3.3-8.5,3.3-13.3c0-12.6-0.6-25.3,1.9-37.8
				c2-1.6,1.6-3.8,1.6-5.9c0-22.8,0-45.5,0-68.3c0-1.8,0.2-3.7-1.5-5c-0.5-2.6-0.3-5.1,1.4-7.3c2-1.9,1.9-4.4,1.9-6.9
				c-1.2-49.1,2.4-98,1.9-147.1c0-2.7,0.9-5.6-1.6-7.8c0.1-13.8,0.2-27.7,0.3-41.5c4.9-0.8,3-4.7,3-7.4c0.2-11.4,0.4-22.8,0-34.2
				c-0.2-6.9,1.9-14.1-1.4-20.8c0-12.3-1.2-24.6,1.1-36.9c0.9-4.8,2.8-11.5-1.6-14.9c-4.6-3.5-10.9-5.9-17-2.8
				c-8,4-18,7.6-18.9,17.5c-1.9,19.3-1.8,38.8-2.2,58.2c-0.5,24.7-2.9,49.3-2,74c0.1,1.4,0.3,3.2-3.4,3.8
				c-0.9-33.3,3.7-66.2,0.6-99.2c-4.4,28.1-1.5,56.8-5.7,86.5c-2.4-33,1.3-64.3,0.5-95.8c-1.5,7.5-2.8,15-2.8,22.6
				c0.1,27.2-0.5,54.3-2,81.4c-0.1,2.4,0.3,4.8-1.6,7c-10.6,12.3-3.9,26.5-4.3,39.9c3.8-9.2,0.6-19.2,3.6-31
				c4.2,11.4,0.7,21.1,5,30.1c0-9.4,0-18.8,0-28.2c0.9,0.1,1.9,0.2,2.8,0.3c-0.5,43.5-2.6,87-1.9,130.6c-1.1,0-2.3,0-3.4,0
				c0-19.8,0-39.6,0-59.4c-0.8,0-1.6,0-2.4,0c0,20,0,40,0,59.9c-0.9,0-1.7,0-2.6,0c0-31.9,0-63.9,0-95.8c-2.2,2.9-2.4,5.8-2.4,8.8
				c-0.1,24.1,0,48.2-0.1,72.4c0,3.8,1.4,8.6-5,8.7c-0.1-19.7-0.2-39.4-0.5-59.1C2342.6,1821,2343.9,1817.4,2341,1814.6z
				 M2695.8,1734.6c5.7-1.2,3.4-5.8,3.5-9c1.2-41.9-1.5-83.8-1.7-125.7c0-0.8-0.5-1.6-0.7-2.5c-0.4,0.1-0.8,0.2-1.1,0.3
				C2695.7,1643.2,2695.7,1688.9,2695.8,1734.6c-0.3,0.2-0.5,0.5-0.8,0.8C2695.3,1735.1,2695.5,1734.8,2695.8,1734.6z
				 M2595.1,1614.1c0,3.3,0,6.7,0,10c-0.2,21.5,0.4,43-0.9,64.4c-0.5,7.9,4.3,7.6,7.9,8.2c5.6,1,4.2-4.3,3.9-7.1
				c-2.1-24.3-1.6-48.7-1.8-73.1c0-0.8,0.6-2.3-2.7-2.2c0,9.3,0,18.7,0,28.1C2595.6,1633.4,2597.7,1623.7,2595.1,1614.1z
				 M2575.6,1578.2c-12.7-6.6-25.3-8.5-39.1-6.4c4,3.2,10.8-2.1,12.1,4.1c1,4.3-6.1,0.8-7.6,5.5c8.9,0.8,16.4,6,24.7,7
				c7.5,0.8,13,6.3,20.1,6.9c3,0.3,7.6,2,8.7-2.3c1-3.8-3.8-3.9-6-5.7c-6.2-4.9-14.4-4.2-21.2-8.3
				C2569.9,1578.8,2572.5,1578.5,2575.6,1578.2z M2653.1,1737.1c0.5,0,1,0,1.6,0c0-45.9,0-91.8,0-137.7c-0.5,0-1,0-1.6,0
				C2653.1,1645.3,2653.1,1691.2,2653.1,1737.1z M2725.2,1732.4c0-49.6,0-99.3,0-148.9C2718,1633.1,2724.5,1682.7,2725.2,1732.4z
				 M2687,1596.5c0,46.2,0,92.5,0,138.7c0.9,0,1.8,0,2.8-0.1C2692.1,1688.9,2687.8,1642.8,2687,1596.5z M2662.3,1614.2
				c0,28.2,0,75.5,0,122.8C2667.1,1689.7,2659.7,1642.4,2662.3,1614.2z M2473.9,1595.8c-4.4,50.7-1.9,101.5-3,152.2
				C2471,1697.3,2475.1,1646.6,2473.9,1595.8z M2612.6,1604.7c-0.5,0.1-1.1,0.3-1.6,0.4c0,30.4,0,60.7,0,91.1c0.5,0,1.1,0,1.6,0
				C2612.6,1665.7,2612.6,1635.2,2612.6,1604.7z M2715.6,1591.9c-0.3,0-0.7,0-1,0c0,46.8,0,93.7,0,140.5c0.3,0,0.7,0,1,0
				C2715.6,1685.6,2715.6,1638.7,2715.6,1591.9z M2645.6,1603.1c-0.4,0-0.8,0-1.3,0c0,45.3,0,90.6,0,135.9c0.4,0,0.8,0,1.3,0
				C2645.6,1693.7,2645.6,1648.4,2645.6,1603.1z M2705.2,1732.4c0.3,0,0.6-0.1,0.9-0.1c0-45.6,0-91.1,0-136.7c-0.3,0-0.6,0-0.9,0
				C2705.2,1641.3,2705.2,1686.9,2705.2,1732.4z M2670.4,1736.1c0.3,0,0.7,0,1-0.1c0-45.6,0-91.1,0-136.7c-0.3,0-0.7,0-1,0
				C2670.4,1645,2670.4,1690.6,2670.4,1736.1z M2213.9,1924.1c-0.7,32.9-0.6,65.8-2.9,98.7c1,0,1.9,0,2.9,0
				C2213.9,1989.9,2213.9,1957,2213.9,1924.1z M2420.6,2049.9c0.3-0.1,0.6-0.1,1-0.2c0-32.2,0-64.5,0-96.7c-0.3,0-0.6,0-1,0
				C2420.6,1985.2,2420.6,2017.6,2420.6,2049.9z M2502.5,2042.4c3.3-15.6,4.1-81.1,1.6-91
				C2502.6,1982.4,2505.6,2012.5,2502.5,2042.4z M2680.7,1645.5c-0.5,0-1,0-1.5,0c0,29.7,0,59.4,0,89c0.5,0,1,0,1.5,0
				C2680.7,1704.9,2680.7,1675.2,2680.7,1645.5z M2472.9,2036.6c0.3,0.1,0.6,0.2,0.9,0.3c0-30.6,0-61.3,0-91.9c-0.3,0-0.6,0-0.9,0
				C2472.9,1975.5,2472.9,2006,2472.9,2036.6z M2285.4,1786.8c-0.8,18.2-1.1,36.1-2.8,54c0.9,0,1.8,0,2.8,0
				C2285.4,1822.8,2285.4,1804.8,2285.4,1786.8z M2300.6,2119.2c-0.5-3.3-1.1-7.1-4.1-6.8c-6.7,0.5-4.7,7.5-6.6,11.4
				c-0.7,1.5,0.5,4.5,2.5,3.7C2296.1,2125.9,2298.9,2122.7,2300.6,2119.2z M2478.6,2018.1c0.3,0.1,0.6,0.1,0.9,0.2
				c0-20.3,0-40.6,0-60.8c-0.3,0-0.6,0-0.9,0C2478.6,1977.6,2478.6,1997.9,2478.6,2018.1z M2309.1,1670.4c-3.7,23.3-3.8,34.2,0,42
				C2309.1,1697.9,2309.1,1684.9,2309.1,1670.4z M2499.7,1976.3c-0.3,0-0.6,0-0.9,0c0,18.9,0,37.9,0,56.8c0.3,0,0.6,0,0.9,0
				C2499.7,2014.1,2499.7,1995.2,2499.7,1976.3z M2872.7,1764.8c-0.3,0-0.6,0-0.9-0.1c0,18.4,0,36.7,0,55.1c0.3,0,0.6,0,0.9,0
				C2872.7,1801.5,2872.7,1783.2,2872.7,1764.8z M2280.1,1742.4c-0.3,0-0.6-0.1-0.9-0.1c0,15.9,0,31.8,0,47.8c0.3,0,0.6,0,0.9,0
				C2280.1,1774.2,2280.1,1758.3,2280.1,1742.4z M2345.2,1711.6c0.3,0.1,0.6,0.2,0.9,0.3c0-13.4,0-26.9,0-40.3c-0.3,0-0.6,0-0.9,0
				C2345.2,1684.9,2345.2,1698.3,2345.2,1711.6z M2460.2,2044.5c0.3,0,0.6,0,0.9,0c0-12.2,0-24.5,0-36.7c-0.3,0-0.6,0-0.9,0
				C2460.2,2020.1,2460.2,2032.3,2460.2,2044.5z M2814.7,1667.1c-1.5,6.6-3.1,13.3,0.3,22
				C2818.2,1680.4,2813.2,1673.7,2814.7,1667.1z M2732.6,1598.5c1.5-2.3,1.8-5.2,4.6-6.5c1.5-0.7,4.1-0.4,3.4-3.2
				c-0.4-1.6-1.9-2.3-3.6-2.4c-2.5,0-4.1,1.5-4.4,3.8C2732.3,1592.9,2732.6,1595.7,2732.6,1598.5z M2600.2,1746
				c-3.3,12.7-3.3,12.7-0.4,17.6C2601.5,1757.4,2600.4,1751.7,2600.2,1746z M2600.7,1854c-4.6-0.5-7.8-2-8.9,2.4
				c-0.3,1,0.4,3.2,0.8,3.2C2595.9,1859.8,2597.1,1856.7,2600.7,1854z M2421.2,1938.8c-1.9,3.7-1.9,6,0.4,9.3
				C2423.4,1944.4,2423.6,1942.1,2421.2,1938.8z M2430.3,1749.1c-2.6-1.8-4.6-2-6.3-0.3c-0.4,0.4-0.4,2.2-0.2,2.3
				C2426.2,1752.3,2428.1,1751.4,2430.3,1749.1z M2504.1,1656.4c0.3-0.1,0.5-0.3,0.8-0.4c0-3.4,0-6.7,0-10.1c-0.3,0-0.5,0-0.8,0
				C2504.1,1649.4,2504.1,1652.9,2504.1,1656.4z M2360.7,1624c1.3,0.1,1.9-0.6,1.8-1.6c-0.2-1.1-0.5-2.7-1.3-3.1
				c-1.7-0.9-1.8,0.7-1.8,1.9C2359.3,1622.4,2359.5,1623.6,2360.7,1624z M2364.5,1612.9c-2-1.1-2.8-1.8-3.5-1.7
				c-0.6,0.1-1.4,1.1-1.4,1.8c0,0.6,0.8,1.7,1.4,1.8C2361.7,1614.8,2362.5,1614,2364.5,1612.9z M2506.7,2053.4
				c-0.5-0.2-1.1-0.7-1.5-0.6c-0.4,0.1-0.5,0.9-0.7,1.4c0.5,0.2,1.1,0.7,1.5,0.6C2506.4,2054.7,2506.5,2053.9,2506.7,2053.4z
				 M2272.4,1735.8c-0.3,0.5-0.7,1-0.8,1.5c0,0.5,0.4,1,0.6,1.5c0.3-0.5,0.7-0.9,0.8-1.4C2273.1,1736.9,2272.7,1736.4,2272.4,1735.8
				z M2354.7,1821.1c0,0.5-0.1,0.9-0.1,1.4c0.2-0.2,0.5-0.4,0.5-0.7C2355.1,1821.6,2354.8,1821.3,2354.7,1821.1z M2472.1,1588.2
				c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2,0.1-0.6,0.1-0.6,0.2c0,0.2,0.1,0.5,0.2,0.7C2471.7,1588.3,2471.9,1588.2,2472.1,1588.2z
				 M2728.2,1579.7c0.2,0.1,0.5,0.2,0.7,0.2c0.1,0,0.2-0.4,0.2-0.6c-0.2-0.1-0.5-0.2-0.7-0.2
				C2728.3,1579.1,2728.3,1579.5,2728.2,1579.7z M2810.9,1560.4c-0.2,0.1-0.5,0.2-0.6,0.4c-0.1,0.1,0.2,0.4,0.3,0.6
				c0.3-0.2,0.6-0.5,0.9-0.7C2811.3,1560.6,2811.1,1560.5,2810.9,1560.4z M2804.9,1558.9c0,0.2,0,0.5,0.2,0.6
				c0.2,0.1,0.5,0.1,0.8,0.2c-0.1-0.4-0.1-0.7-0.2-1.1C2805.3,1558.7,2805.1,1558.8,2804.9,1558.9z M2699,1591.4
				c-0.2-0.2-0.3-0.4-0.5-0.7c-0.2,0.2-0.6,0.5-0.5,0.6c0.2,0.3,0.5,0.6,0.8,0.9C2698.9,1591.9,2698.9,1591.7,2699,1591.4z
				 M2689.2,1592.2c0.2-0.1,0.5-0.2,0.5-0.4c0.1-0.2,0-0.5-0.1-0.7c-0.3,0.2-0.6,0.3-0.8,0.5C2689,1591.8,2689.1,1592,2689.2,1592.2
				z M2672.8,1594.1c0.2-0.1,0.5-0.2,0.6-0.4c0.1-0.2,0-0.5-0.1-0.7c-0.3,0.2-0.6,0.3-0.9,0.5
				C2672.5,1593.7,2672.6,1593.9,2672.8,1594.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1541.8,1698.1c15.7,5,40.5,5,50.3,0.4
				C1574.9,1699.2,1558.4,1694.7,1541.8,1698.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2337.3,1314.1c-2.1,19.7-1.7,39.5-2.1,59.2c-2.7-2.5-1.6-5.8-1.6-8.7
				c-0.1-11.1,0-22.2,0-33.3c0-11.1-0.9-11.7-11.5-7.9c-4.5,0-3.4,5.5-6.4,6.8c-3.9,1.7-4,1.5-4,4.7c0,8.3,0,16.6,0,25.4
				c2.7-0.9,0.6-5.7,4.1-4.7c2.3,0.6,1.4,3.1,1.4,4.8c0.1,3.7-0.1,7.4-0.1,11.1c-2.9,0.2-2.5,1.9-3,4.2c-0.9,3.5-3.9,7.5-7.5,7.7
				c-4.2,0.2-4-4.5-4.2-7.9c1.9-0.4,3.4-0.9,3.6-3.3c1.1-10,2.5-20,3.3-30c0.9-11.4-11.5-19.5-22.3-15c-2.6,1.1-0.9,2.8-1.4,4.1
				c-4.3,12.1,1.4,24.8-2,36.9c-4.7-0.3-9.5-0.9-14.2,0.5c1.7,5.4-1.2,18.8-4.6,22.3c-1.1,1.1-3.1,0.9-3.1,0.3
				c-0.6-8.9-9.3-5.1-13.6-8.4c1.8-2.2,3.8-1.8,5.5-2c7,0.8,6.6-4.1,6.7-8.8c-6.2-3.3-12.4-5.2-18.7-0.1c-1,1-2.3,3.1-2.9,2.9
				c-9.6-2.6-4.3,3.4-3.9,6.6c-1.5,2.4-2.2,5.8-6.4,4.8c-3.8-0.9-2.6,2.3-2.7,4c-0.1,2,0.8,3.6,3.1,3.3c2-0.3,4-0.9,6-1.4
				c-1.6,3.1-3.1,6.2-4.7,9.3c-5.7,2.4-9.9,13.9-9.2,19.6c1,8.1,2.1,15.6,7.9,21.8c0.9,0.9,0.1,3.4,0.1,5.9
				c-1.8-0.8-3.7-1.1-3.8-1.7c-1.3-7.5-7.1-0.8-9.9-3.4c-0.8,3.9-1.9,7.2,2.8,10.2c6,3.9-2.2,10.2,1.3,15.7c1.3,2-4.8,1.1-5.5,2.4
				c-1.3,2.7-10.2,0.7-4.6,7.4c-1.7,9.9-3.6,19.8-5.2,29.8c-0.9,5.4-0.4,10.2,7.1,9c1.6,0.8,1.5,2.2,1.5,3.7c-7,3.6-15.3,1-22.6,4.7
				c3,2.7,8.5-1.2,10.2,4.1c-3,0.2-6.5,1.4-9,0.3c-3.9-1.8-6.1,2.3-9.4,1.6c0.6,5.7,5.5,4.6,8.9,5.7c0.5,0.2,1.2,0,1.8-0.2
				c6.3-2,12.5-0.7,18.9,0.1c13.5,1.5,27.1,2.2,40.9,3.2c-1.8-7.6-10.9-3.6-12.4-10.1c3.6-3.7,7.3-1.1,11-0.5c2.1,0.4,6.6,0.4,4-3
				c-6-7.8,2.8-2.5,3.2-4.2c3.8,6.9,9.3,2.9,14.3,2.4c0.4-0.3,0.7-0.6,1.1-0.9c1.2,0.1,2.3,0.3,3.5,0.4l0.7-0.1l0.7,0.2
				c1.9,2.8,4.8,1.1,7.2,1.8c5.9,2.9,12,2,18.1,1.1c-2.6-0.9-5.3-1-7.9-1.3c-1.2-0.1-2.4-0.8-1.9-2.4c0.2-0.7,1.2-1.1,1.8-1.7
				c4.1,4.9,12.5-1.2,15.7,6.2c-0.1,0.2-0.3,0.4-0.4,0.6c0.1-0.2,0.2-0.5,0.4-0.7c1.8,0,3.7,0,5.5,0.1l0,0c-0.7,4.2,5,1.6,4.4,3.6
				c-2.7,9.1,4.3,6.4,8.3,7.5c0,2.5,0,5.1-0.1,7.6c-6.7,3.8-14.5,2.7-21.2,2.3c-19.3-1.1-38.8,0.7-58.1-1.8
				c-5.3-0.7-11.1-2.7-16.2-1.7c-13,2.5-26-0.8-38.9,0.8c-1.9,0.2-3.9,0.5-5.6-1.7c-3.9-5-9.4-6.5-15.6-6.2
				c-9.7,0.6-18.7,5.7-28.6,4.2c0-8,0-15.9,0.1-23.9c10.1,4.3,18.8,1.4,28.8-2.8c7.7-3.2,5-7.5,5.7-11.9c0.9-5.3-1.7-6.8-6.8-8.3
				c-7.7-2.3-15.9,1.1-23.1-3.5c5.7-5,3.9-12.4,5.5-18.7c0.7-2.9-1.6-5.1-5.1-4.8c-2.1-0.4-6.2,0.2-5-3.1c2-5.5-1.7-10.5,0.1-15.7
				c3.5-1,9.3,2.6,10.2-2.7c1.6-9.5,7.9-8.2,14.1-8.1c11.9,0.2,23.7-0.2,35.3-3.5c-1.4-0.3-2.9-0.7-4.3-1c-0.3-0.2-0.5-0.4-0.7-0.6
				c2.2-9.3,1.8-18.6,0.4-28.3c1.6,11.8-4,14.3-13.8,13c-3.5-0.5-7.5-0.4-10.7,0.9c-3.9,1.6-0.4,7-3.1,10.2
				c-0.9-13.2-0.9-13.2,4.9-15.9c-7.9-7.3-8.1-7.3-10,2c-0.4,1.9-0.8,1.9-2.1,1.5c-6.8-2.1-13.7,2.7-20.5-0.4
				c0-10.5-0.1-20.9-0.1-31.4c3.9,0.5,7.8,1.4,11.7,1.4c2,0,5.2,1.1,5.9-2.6c0.6-3.2-0.3-5.5-3.7-6.3c-1.2-0.3-2.3-0.7-2.1-3.1
				c3.6-1.5,7.4-1.2,12.1,0.3c-4.5,6.1-5.1,11.9,1.6,15c6.3,2.9,4.5-4.1,6.3-7.1c1.4,3.9,2.2,9.2,7.4,3.4c0.3,0.8,0.5,1.6,0.8,2.3
				c-0.1,2.3,2.1,5-2.8,5.9c-3.9,0.7-7.6,4.6-4.1,9.1c3.4,4.4,8.1,5.2,13.9,3.5c5.4-1.7,6.1-4.9,5.9-9.6c-0.1-2.4-0.6-5,1.3-7
				c1.5,0,3,0,4.5,0.1c18.6,1.4,18.6,1.4,18.1-17c0-0.3,0.2-0.6,0.3-0.9c1.7-4.6-1.5-4.4-4.3-4.4c-2.8,0.1-5.7-0.1-4,4.5
				c1.7,4.8-1.3,9.8-4.9,9.7c-8.2-0.2-16,5-24.3,1.9c-0.9-8,5.2-6.7,9.6-7.6c3.8,0.2,7.7,0.6,11.5,0.4c3.4-0.2,4.9-1.9,2.6-5.3
				c1.2-3.2-2.4-3.6-3.2-5.6c1.1-0.2,2.3-0.5,3.4-0.7c4.1,0,8.2-0.7,8.7-5.6c0.6-5.9,1.9-12.3-4.9-16c0.7-4.6-1.8-9.5,1.7-13.7
				c5.6,1.1,6.9-2.6,7.2-6.9c0.4-4.8-0.2-9.6,0.4-14.4c1-8-3.6-1.7-5.4-2.4c0.4-5.6-4.5-5.3-7.6-7.2c0.9-2.4-2.9-7.7,3.7-6.5
				c2.8,0.5,5.2,0.7,5-2.8c-0.2-2.8-2.6-3.7-5.3-3.9c-5.9-0.6-11.8-1.4-17.9-2.2c2.1-2.5,7.6,0.8,7.5-4.8c3.4-1,4.9,5.1,7.9,2.9
				c4.1-3.1,9.3-3.5,13.2-6.2c2.6-1.8-1-5.5-1.5-8.5c-0.5-3.1-2.2-5.6-6.2-5.3c-3.9,0.3-7.9,0.3-11.8,0c-2.8-0.2-6.4,1-7.3-3.5
				c2.7-0.2,6.3,1.9,7.5-2.5c1.5-5.9-3.6-6.5-7-8.4c11.2,1.9,20.9-5.1,31.8-5.3c4.3-0.1,4.4-3.3,4.4-6.6c0.2-25.3,4.8-50.3,3.9-75.7
				c-0.1-4.2,1.1-8.4,1.7-12.7c0.7,0.5,1.4,1.1,2.1,1.6c1.8,9.5,2.3,19.5,10.5,26.3c-3.1,5,0,8,4.1,12.6c-1.1-6.1,1.4-10.8-2-14.8
				c3.5-10.8,3.5-22,3.5-33.2c0-6.2-3.1-3.4-5.4-1.2c-0.5-0.1-1.1-0.1-1.6-0.2c6.6-7.7-0.7-12-4.2-17.3c-0.9-2.6-3.3-1.6-5.1-2.3
				c0.3-2.5,0.5-5,0.8-7.6c0.9,0.2,1.7,0.4,2.5,0.6c3,4.5,6,9.1,9.1,13.5c1.9,2.7,3.8,2.9,5.2-0.5c2.1-5.1-3.5-20.1-8.8-23.5
				c-0.3-6.3,2.7-14-6.9-16.1c3-38.2,6-76.3,9.1-116.1c3.7,7.3-0.9,14.3,3.2,19.8l0.7,0.6c-3.8,6.4-1.8,11.6,3.2,16.2
				c0,1.3,0,2.5,0,3.8c1.7,5.6,2.3,11.7,6.8,17.5c0.3-5.8,0.5-10.5,0.7-15.2c4.4-1.9,4.7-4.5,1.3-7.7c-0.5-7.1-0.9-14.1,1.5-21.2
				c0.9-2.6,3-7.6-3.1-8.6l0,0c0.3-7.8-3-17.4-7.7-19.4c-9.2-4-4.3-9.8-4.5-14.5c-0.3-7.9,1-15.8,1.6-23.8c4.1-1,6.7,2.3,10,3.6
				c-1.8,10.4,0.2,19.3,6,26.5c0.6-5.2,1.1-10.4,1.9-15.5c1-6.2-1.3-10-7.5-11.3c4.8-3.8,11.7-6.5,10.2-14.5
				c-1.4-7.4,1.2-14.4,1.1-21.6c0-4.8-0.8-8.9-3.9-12.6c7.2-2.7,11.6,3.8,17.6,5.4c9.4,2.4,6.2,10,6.1,15.9
				c-1.2,47.1-2.6,94.1-4,141.1c-0.1,4.6-2.1,7.4-5.5,11.1c-11,12.2-17.6,26.8-14.3,44.2c0.4,2.1,0.3,4.8,2.9,5.1
				c2.3,0.2,2.1-2.5,3-3.9c5.2-7.5,10-15.2,10.8-24.9c0,17.2,0,33.8,0,50.4c-1.6,1.2-1.7,2.5-0.3,3.9c-6.3-0.6-1.9-7.3-5.2-9.5
				c0,0-0.1,0.1-0.1,0.1c2.2-0.5,3.8-1.4,3.7-4.1c-0.1-6.8,0-13.6,0-20.4c-4.7,5.2-8.8,10.2-9.2,17.1l0.1-0.1
				c-6.5,3.7-9.4,23-2.7,26.4c7,3.5,2.5,5.4,0.8,7.7c-6,8-8.9,31.5-4.7,41.4c7-5.7,6.9-15.9,13.6-21.5c0.7,0.7,1.3,1.3,2,1.9
				c-0.2,23-0.1,46.1-0.8,69.1c-0.2,7.7,2.6,9.9,9.5,8.8c6.2-1,12.3-2.3,18.8-0.3c3.6,1.1,3.3,2.8,2.6,5.3c-2.9-0.2-6.6-4.6-8.2,2.3
				c-2.2,9.4,4.6,6.7,8.9,7.7c-3.9,3.6-9.2,0.8-13.4,3.1c-0.5-3.7-4.8-2.5-6.1-5.1c-0.5-1.1-1.2-2.2-1.3-3.4c-0.4-3.3-1-6.1-5.3-5.7
				c-2.4,0.2-3.9,0.7-4.3,3.6c-0.6,4.8,2.4,5.7,5.9,6.1c0.5,5.5,6.4,1.5,8.2,4.7c-3.8,0.4-8.4-1.3-10.6,3.8
				c-0.6,0.3-1.3,0.6-1.9,0.9c-2.6-0.7-4.3,0.1-4.1,3.1c0.7,12.6,0.4,25.1-1.1,37.7c-1.3,10.4,2.5,13.8,12.8,11.7
				c5.8-1.2,11.3,2.4,17-0.5c2.7-1.4,5.8-1.8,5.4-6c-0.7-8.3,0.9-16.9-3-24.6c-2.2-4.4-0.4-8.9-0.8-13.4c-0.2-1.7,2.6-3.7,5-4.4
				c0.7,4.5,8.2,2.8,7.5,7.9c-2,14.3,4.7,27.2,5.7,40.9c0,0.3,0.2,0.7,0.3,0.8c0.5,0.1,1,0,1.4,0
				C2336,1314.1,2336.7,1314.1,2337.3,1314.1z M2243.9,1291.8c-2,0-4.6-0.9-5.5,1.4c-1.3,3.5,0.9,6,3.6,8c0.1,2.2,0.1,4.4,0.2,6.5
				c-9.3,6.4-12.3,15.5-8.4,24.9c0.9,2.2,0.7,4.8,1,7.2c-2.5,1-3.6,2.6-2,5.2c0.1,5.3-1,10.3-3.3,15.1c-1.2,2.5-0.9,4.1,2.1,4.1
				c1.7,0,5.3,1.1,3.5-2.6c-1.7-3.6,3.1-4.1,2.2-6.9c4.6,3.3,3.7,13,12.6,11.2c8.3,1.6,11.3-1.9,10.5-10.4c-0.6-6.3-1-13,0.3-19.1
				c1.3-6.1,0.4-9.7-7.9-11.1c11.6-3.6,5.6-11.4,7.3-16.8c2.2-6.2,4.9-12.4-1.2-18c-2.1-4.8-5-8.4-10.9-8c-2.8,1.2-6.5-3.1-8.8,2.5
				C2237.1,1290.7,2242.5,1289.6,2243.9,1291.8L2243.9,1291.8z M2285.6,893c2.7-0.9,7.8,1.2,7.1-4.2c-0.7-5.3-4.9-7.3-10-7.9
				C2283.7,885.4,2284.6,889.2,2285.6,893c-9.1,0.7-3.8,6.7-4.5,10.4C2284.2,900.6,2286.4,897.4,2285.6,893z M2275.2,1250.8
				c8.5,0.4,9.3-4.3,7-11c-0.6-2.6-0.9-5.4-1.9-7.9c-1.4-3.8-3.4-3.8-5.3-0.2c-2.5,4.6-4.6,9,3.7,8.7c-1.1,5.6-7.2,1.1-9.3,4.8
				c0.3,3-3.9,6.4,0.1,8.8C2272.1,1255.7,2274.7,1254.4,2275.2,1250.8z M2249.2,1214.5c0.8-2.5,5.6-4.6,1.8-7.4
				c-2.4-1.8-6.6-4-9.2-1.6c-2.1,2,0.6,5.4,1.7,8.1c0.6,1.4,1.4,2.6,2.2,4c2.8,4.1,0,10.1,5.1,14
				C2250.7,1225.5,2257.3,1219.5,2249.2,1214.5z M2240.3,1191.5c-1.3,7.5,4.1,6.1,7.8,5.4c5.1-1,1.8-4.5,1.2-7
				c-0.6-2.4-1.2-4.7-3.7-5.8c0.2-5.8-2.6-10.5-5.4-17.2C2237.9,1176.4,2236.2,1184,2240.3,1191.5z M2242,1230.4
				c2.5-7.5-0.1-13.7-5-21.2c-1,8.1-1.9,14.1-2.4,20.2c-0.2,2.8-1.2,6.6,3.7,6.7c-0.9,2.2-1.9,4.4-2.8,6.7c3.3,1.4,6.2,0.9,9-0.1
				c2.1-0.8,5.9-1,4.2-4.3C2247.2,1235.4,2249,1228.7,2242,1230.4z M2245.3,1276c5,1.5,3.7-1.8,3.3-4.5c0.1-3.1,0.2-6.2,0.3-9.3
				c-0.8-2.7-2.6-5-5.4-4.7c-1.9,0.2-1.1,3-1.5,4.6c-1.4,0.2-3.1,0.1-3.6,1.9c-6.9,3.4-2.7,10.3-4.4,15.4c-0.4,1.3,2.9,0.9,4.5,0.6
				C2241.9,1280.5,2245.5,1281.6,2245.3,1276z M2275,1186c-4,2.2-6.8,5.8-4.6,10.1c1.9,3.6,7.3,4.4,10.4,3.3
				c3.2-1.2,0.3-5.2-0.4-7.9c2.2-0.1,5.4,1.1,5.4-2.8c0.1-7.3,1.1-14.8-1.1-24.6C2281.6,1173.3,2275.7,1178.4,2275,1186z
				 M2287.1,941.4c-10,3.9-14,16.7-8.1,25.5c1.6,2.4,3.5,1.2,4.2-0.4c5.6-11.7,13.4-22.9,8.9-37.4c-4.4,3-5.1,6.9-4,11.4
				C2287.8,940.9,2287.4,941.2,2287.1,941.4z M2289.5,992.9c4.6-10.4,1-21.1,1.2-31.8c-2,6.1-8.1,10.4-6.6,17.6
				c-7.6,4.2-11.7,20.2-7.5,27.8c2.8,4.9,5.5,6.3,9.3,1.1c-1,6.3-2.7,11.8-7.2,17.2c-7.3,8.8-3.3,20.6-3.9,31.1
				c-1.1-0.1-1.5,0.2-0.8,1.3c0.3-0.4,0.6-0.8,0.9-1.2c11.1-13.4,17.5-28.5,16.3-46.4c0-1.1-0.5-1.9-1.7-1.8c-1.2,0-2.5,0-3.7,0
				C2287.1,1002.8,2292.9,999,2289.5,992.9z M2273.1,1303c2.5-0.1,4.2-1.2,3.9-3.9c-1.2-9.3,2.6-18.3,1.8-27.5c-0.2-3,2.5-7.9-3.3-9
				c-0.2-0.2-0.4-0.4-0.7-0.6c-0.9-2.8-3.1-4.7-5.4-2.9c-3.7,2.8-1.5,7.2-1.7,10.7c2.8,1.6,2.8-2.9,6.4-1.5
				c-8.1,10.8-5,23.4-6.6,35.3c-0.5,3.7-1.3,8.3,3.4,10.5c0.8,0.4,2.7,0,3.2-0.7C2276.5,1309.7,2273.8,1306.4,2273.1,1303
				L2273.1,1303z M2258.2,1085.7c7.8-13.8,4.9-31.8-8.7-48.8c-3,10.2-2.5,20.5-2.3,30.8c3,0.5,2.5-3.6,7.4-2.3
				c-10.9,8.5-8.4,17.3-1.9,26.1c1.5,5.8,1.4,12.3,7.3,18.4C2259.5,1100.7,2262.7,1093,2258.2,1085.7z M2263.7,1223.2
				c0.1,0,0.2,0,0.3,0c0-3.7,0.1-7.4,0-11.1c-0.1-1.9,0.9-4.6-2.2-5.2c-3.5-0.7-4.1,2.2-4.3,4.6c-0.6,8.6-0.9,17.2-1.2,25.8
				c-0.1,2.4,0.5,4.3,3.5,4c2.6-0.2,3.8-1.8,3.9-4.2C2263.8,1232.4,2263.7,1227.8,2263.7,1223.2z M2263.9,1048.1
				c1-10.7-3.9-19.5-8.1-29.3C2248.9,1028.6,2253.1,1042.1,2263.9,1048.1z M2282.7,1205.3c-13.9-1-17.1,7.1-11.9,28.2
				C2274.4,1223.6,2276.8,1214.3,2282.7,1205.3z M2269.1,969.1c2.1-12.5-5.5-22.2-8.8-33.2c-1.9,2.7-4,5.4-3.6,7.7
				C2258.3,953.1,2266.4,959.7,2269.1,969.1z M2264.2,1143.4c3.1-8.9,1.7-18.1,3-27.2c1.3-8.6,0.3-17.5,0.3-26.2
				C2264.1,1107.8,2263,1125.5,2264.2,1143.4z M2294.4,1317c-2.8-0.4-4.1-0.9-5.1-0.6c-2.2,0.7-5.1,1.5-4.5,4.7c0.7,3.3,3,1.5,5,1.3
				c5-0.5,10-0.5,14.9-0.7c2.1-0.1,4.6,0.3,4.8-2.7c0.2-3.3-2.5-2.9-4.5-2.8C2301,1316.3,2296.9,1316.8,2294.4,1317z M2260.5,1196.4
				c7.4-9.2,7.4-18.5,0.2-24.6C2259.7,1179.8,2256.7,1187.4,2260.5,1196.4z M2293.2,911.6c-0.9-2.3-1.7-8.2-3.8-8.7
				c-3.8-0.9-5.6,4.3-6.1,7.5c-0.7,4.6,4.2,4.2,7,5.4C2292.9,917,2293.1,914.9,2293.2,911.6z M2226.3,1250.5
				c-3.3,0.1-7.9-1.2-7.9,4.4c0,4.8,4.1,3.3,6.8,3.5c2.6,0.1,6.1,0.5,5.3-3.8C2230.2,1252.8,2231.4,1247.8,2226.3,1250.5z
				 M2280.7,1307.8c1.1-7.4,3.8-15-0.8-22.1C2278.2,1293.2,2277.7,1300.5,2280.7,1307.8z M2163,1403.9c-0.4-7.6-1.6-8.8-6.8-7.9
				c-5.9,1.1-2.9,4.9-2.9,8.2c4.1-0.7,1-4.7,4.3-6C2157.8,1401.2,2160.6,1401.4,2163,1403.9z M2225.4,1331.5
				c2-5.4,2.8-10.8-0.5-18.7C2224,1320.8,2223.5,1326.2,2225.4,1331.5z M2279.2,928c-1.6,5.2-3.6,9.2-1.3,14.2
				C2283.3,938.1,2283.7,933.9,2279.2,928z M2283.7,1213.4c-3.5,4.4-5.9,7.6-4.7,12.2c0.3,1.2,1.8,1.5,3,0.5
				C2285.8,1222.8,2284.5,1218.8,2283.7,1213.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2933.4,1543.7c0,6.3,0,12.5,0,19.5c11.1-5.2,11.2-16.2,15.5-24.5
				c0.5,0.4,1,0.8,1.5,1.2c-0.2,9.6-4.1,18.1-8.4,26.4c-5.2,10.1-4.1,18.1,3.8,21.9c3.4,1.6,7.6,1.5,11.5,2.2c1.9,0.3,3.7,0.6,5.6,1
				c4.3,0.4,8.6,3.2,13,0.4c0,0,0.7,0,0.7,0c4.2,2.8,8.4,5.1,13.2,1.3c2,0,4,0.1,6,0.1c6.5,5.7,14.3,3.5,21.7,3.9
				c1.6,0.9,1.7,2.4,1.6,3.9c-2,0.2-3.4,1-3.4,3.4c-2.4,0.2-4.8,0.3-7.2,0.5c-4.3,1.7-14.9-8.2-11.9,6.9c0.8,0,1.7-0.1,2.6,0
				c2.5,0.5,6.5-1.1,7.2,2.2c1,4.6-3.5,2.5-5.6,3.4c-4.6,2.1-3.6,6.4-3.6,10.3c0,3.6,0.3,6.9,4.6,7.6c5.1,0.9,10.3,1.6,15.4,2.4
				c8,1.2,15.9,3.7,24.1,1.7c0.6,0.1,1.2,0.2,1.8,0.3c1.9,6.7,7.4,2.3,11,3.7c0.6,0.1,1.2,0.2,1.8,0.3c1.6,1.8,3.7,1.7,5.8,1.3
				c18.7,2.5,37.4,4.3,56.2,4.3c1,5.4-0.4,7.9-6.5,7.4c-16-1.5-32-2.5-48-3.7c-2.1-0.8-2.7-4.6-5.9-3.2c-2.6,1.1-1.7,3.7-1.7,5.7
				c0,4.8-2.1,9.6,0.2,14.3l0,0c-4.6,0.1-6.2-1.2-4.9-6.6c1.2-5.4,4.6-13-5-15.4c-4-0.7-7.9-1.4-11.9-2.1c-6.3-0.4-6.6,3.7-6.8,8.6
				c-0.2,5.8,2.6,11.3,1,17.1c-2,1.1-4.8,7.8-5.4-0.8c-0.1-1.8,0-3.8-1.9-4.8c-0.4-0.7-0.8-1.3-1.2-2c0-1.1,0-2.1,0-3.2
				c6.5-3.5,5.4-9.6,4-14.9c-1.1-3.8-4.4-0.6-6.7,0c-3.7-1-6.4-3.8-10.5-4.5c-8.7-1.3-10.6-0.3-9.1,8.2c0.8,4.8,1.8,9.6,1.6,14.5
				c-0.1,2.8,1.1,3.9,3.7,3.7c-5.6,4.9-2.5,10-0.2,15l0.1-0.1c-4.6,5.6-0.8,11.2-0.1,16.8l0.1-0.1c-3.9,9.7-1.1,19.5,0.1,29
				c1.8,13.9,0.1,28,3.4,41.7c0.6,4.3,1.3,8.6,1.9,12.9c-0.4,4-2.7,7.9-1,12.2c1.2-3.2,3.1-4.8,6.5-3.4c-0.6,6.3,5.4,9.2,7.2,14.3
				c0.2,0.6,0.4,2.4,1.5,0.9c4-5.6,10.8,0.7,15-3.6c0.1,0.5,0.2,1,0.4,1.5c-1.1,5.5-7.6,4.2-10,8.2c-2,3.5-1.5,4.4,2.4,3.4
				c1.3,1.7,1.6,3.5,0.2,5.3c-2.4-1.4-6.9,1.8-6.7-5.6c0.2-7.3-5.3,0.8-8.8-0.5c-8.7-3.1-7.3,2.2-4.5,7.4c-1.9,0.2-3.9,0.5-5.8,0.7
				c-1.2-1.6-2.6-2.3-4.4-1c-0.3,0.2-0.5,1.2-0.3,1.5c1.7,1.9,3.1-0.4,4.6-0.4c0,1.9,0,3.8,0,5.6c-5.2,1-7,3.6-3.3,8.1
				c2.2,2.7,1.6,5.7,1.3,8.7c-2,0.3-4.9,0-3.8,3.3c0.6,1.7,1.9,2.2,4.8,1.6c4.1-0.9,5.2-6.9,10.1-5.2c6.1,4.1,9.3,9.5,7.6,17.1
				c-0.5,2.3,1.7,6.5-2.6,6.3c-2.7-0.1-5.5-2.8-5.8-6.1c-0.2-1.8,1.7-3.9-1.6-5.5c-1,3.8-2,7.3-2.9,10.7
				c-3.8,11.7,3.1,23.1,0.2,35.1c4.4-4.3,3.2-13.7,12.6-10.9c2.3,4.3-5.8,6.5-2.5,10.5c4.3,5.2,10.2,1.1,15.4,1.7
				c4.9,0.6,2.8-4.2,3.5-6.8c6.3-4,8.9,0.4,12.3,5.3c-3.5,0.3-6.1,0.5-8.6,0.8c3.9,4.2,11.1-2.9,13.5,5.1c-4.2,0.1-8.4,0.1-12.7,0.2
				c-1.8,0.1-3.8-0.1-4,2.5c-0.2,2.2,1.9,3,3,4.3c1.7,2.2,2.9,1.1,3.9-0.9c2-4,5.8-2.1,8.8-2.5c3.8,3.8,1.6,8.4,1.7,12.7
				c-6.4-1.1-7.9,2.2-6.9,7.8c-3.6-2.7-7.7-1.1-9.6,1.3c-2.3,3.1-2.1,9.9-7.1,8.8c-4.8-1.1,0.1-7.8-2.8-11.4
				c-0.9,1.6-11.4,11.6-13.1,12.6c-0.4,0.3-0.6,1-0.9,1.6c-1.9,4.3-0.2,8.1,4.8,9.2c7.1,1.5,9.1,4.8,5.2,11.3
				c-0.6,0.9-0.3,2.4-0.4,3.6c-1,0.7-2.1,1.3-3.1,2c-1.4,0.6-2.8,1.2-4.2,1.8c-3.3-8.2-8-5.8-12.9-1.8l0,0c1-4.4-5.2-4.5-5-7.9
				c0.5-8.2-4.6-7.7-9.9-7.2c1.6-1.2,3.6-2.2,4.8-3.8c3.1-3.9,9-5.2,9.9-11.1c7.4-1.4,1.7-7.8,4.2-11.1c5.6,0.9,1.9,5.8,3.8,9.3
				c1.3-2.3,2.3-4,3.4-5.7c4.2-1.2,3.1-5.3,4.5-8.1c0.9-1.7,3.8-3-0.2-4.3c-2.6-0.9-5.5-3.8-6.7,1.6c-1.4-1.5-2.5-3.9-5.2-1.9
				c-7.4-5.2-12.3,1.3-17.9,4.1c-5.2,0-4.2-7.4-9-7.8c-1.4,0-2.8,0-4.2,0c0-6,0-12,0-18c-3,5.4,0.4,11.5-3.6,16.7
				c-0.7,0.9,2.3,0.9,3.6,1.2c-0.1,2-0.7,3.2-3.2,3.2c-12.6,0.1-25.1,0.4-37.7,0.6c-2.3,0-4.2,0.4-4.7,3.2
				c-0.6,6.6-1.4,13.1-1.5,19.7c0,3-1.2,7,3.2,8.6c0,4.2,6.4,6.2,3.6,11.3c-5,9.1-3.5,19.2-4.7,28.9c-2.2,3.6-4.4,1.2-6.6,0
				c-0.3-4.5,2.3-8.9,0.2-13.4c-2.9-6.6-9.1,3.9-12.3-3.1c4.3,5.5,0.9,10.9,1.6,16c0.1,0.5,0,0.9-0.2,1.4c-8.6,1.3,0.2,8-3.2,10.9
				c-3.5,2.9-3.1,8-6.1,11.3c-3.2-4.7,1.1-12.3-2.3-14.6c-6.9-4.6,0-11.6-4.4-15.9c-0.2-0.2-0.2-2.1,1.3-1.4c5.9,2.8,3.5-2.3,4-4.4
				c0.6-2.8-1-6.2,2.1-8.4c4.9-5.8,4.1-17.2-1.7-23.5c-0.1-4.3-0.5-8.7-0.3-13c0.1-3-1.6-7.8,3.8-7.6c5.3,0.2,3,5.1,3.4,8
				c0.8,5.8-1.1,11.8,1.4,17.5c-1.1-14.4,3.4-29.4-3.1-43.4c-1.7,3.2,0.6,7.5-5.1,9.2c-0.2-2.9-0.5-5.6-0.5-8.2
				c-0.1-16.6,1-33.4-0.4-49.9c-1.2-14.5-1.6-28.9-1.1-43.4c1.9,0.2,3.9,0.5,5.1-1.6c0.7-0.1,1.4-0.3,2.1-0.4c0.5,0.2,1,0.3,1.5,0.2
				c-0.5,3.8,0.8,7,3.8,9.4c-7.3,5.3-2.5,11.3-2.7,17c0.9,0,1.8,0.1,2.7,0.1c0-5.7,0-11.4,0-17.2l0.9-0.1l0.9,0c0,8.8,0,17.7,0,26.5
				c2.4-5,3-10.1,3.3-15.2c0.9-16.2,0.8-32.6,2.8-48.7c0.9-7.4,0.3-13-4.4-18.3c4-4.1,5.6-9.1,5.5-14.9c-0.2-7.5-0.1-15-0.2-22.5
				c1.6-0.1,3.2-0.3,4.8-0.4c2.2-0.4,4.3-0.8,6.5-1.2c11.6,5.7,23.2,1.5,34.8,0.1c5.6-2.5,8,2.6,7.5,5.4c-0.8,4.3,2.4,5.1,3.5,7.5
				l0.1-0.1c-3.7-0.2-4.8,2-4.2,5.2c2,11.3-1.2,22.7,0.4,34c3,5.4-6.9,17.1,7.9,16.7c0.4,0,0.8,1.5,1.2,2.3c4.8,9-1.8,8.9-7.5,8.9
				c0.6,3.9,5.5,1.4,5.7,5.1c0.6,8.5,4.6,6.5,9.6,3.4c7.8-4.9,4.1-10.5,1.4-16.1c-0.1-0.6-0.1-1.2-0.2-1.8c2.2-0.5,4.3,2.6,7,0
				c-2.3-2.1-3.2-3.7-2.5-7.7c1.4-8.5,4.3-17.9-1.8-26.1c0.4-6.6,0.9-13.3,1-19.9c0.1-2.5,0.8-6-3.6-5.9l0.1,0.1
				c0.6-3.1,4-5.2,3-8.9c0.6-2.3,1.1-4.5-1.4-6.1c1.3-14.5-2.3-28.7-1.9-43.1c0.1-2.3-1.5-3.1-3.5-3.2c1.2-3.1,3.2-6,2.1-9.6
				c1.5-5.8-3-10.8-2-16.5c2.7-4.2,2.2-7.8-0.9-11.7c-7.9-10-19.2-11.2-30.5-12.4c-1.7,0-3.3,0-5,0.1c-2.6-4.9-7.2-3.3-11.2-3.7
				c0.6-4.7-1.8-7.9-6-8.6c-3.9-0.6-6.8-3.2-10.4-4.1c-6.7-2.6-13.4-5.2-20.1-7.8c0.1-1.2,0.1-2.5,0.2-3.7c0.6,0,1.2,0,1.7-0.1
				c6.4,2.3,12.7,4.6,19.4,7c0.2-3.7-3.9-4.1-2.9-7c2.7-1.1,5.8-4,7.4,1.4c-3.9,3.6-1.1,6.1,1.8,7.6c4.7,2.5,9.7,6.3,14.6,0.3
				c-0.2,5.2,3.3,6.7,7.4,7.5c3.2-0.6,6.5-1.3,9.7-1.9c-3.8,5.7,1.1,8.2,4.6,8.9c4.3,0.8,7.9,3,12,3.8c2.7,0.6,7.3,4.6,8.1-0.4
				c0.8-5,3.8-13.1-3.6-15.3c-6.1-1.8-13.5-4.7-19.5,1.5c-3.8-1.8-5.3-7.8-11-6.2c-3.9-4.1-5.4,0.6-7.7,2.1
				c-0.4-2.4-1.4-4.4-3.6-5.7c1.2-1.2,2.4-2.3,3.6-3.5c3.9-3.8,3.7-6.8-1.9-8.1c-5.5-1.3-10.9-3.8-16.7-1.3c-0.3,0.2-0.6,0.2-1,0.1
				c-0.4-0.8-0.7-2.2-1.2-2.3c-10.9-2.9-21.8-6.4-32.9-8.2c-5.2-0.9-3.7,2.8-1.1,5.3c0,1-0.1,2.1-0.1,3.1l0.2,0.7
				c-4-0.9-5.8,0.8-5.4,4.9c-4.7-0.8-9.5-1.1-14-2.4c-4.4-1.2-7.4-0.1-8,4.3c-0.6,4.3,2.6,5.7,6.3,5.8c2.1,0,4.2,0,6.3-0.1
				c2.1,3.9,2.9,8.8-1.1,10.8c-3.1,1.5-9.2,2.8-8.7-5.4c0.2-3.3-3.4-3.6-6.3-3.3c-0.2-3.9-7.2-7.4-0.6-12c0.3-0.2-0.7-6.3-2.1-8.7
				c-1.4-2.4-5.3-4.8-8.3-3.5c-2,0.9-4.6,0.9-6.3,2.7c8.8,5-1.4,10.5,0.3,15.6c-4.8,5.7-11.3,4.3-17.3,4c-3.5-0.2-7.4-3.5-7.1-6.7
				c0.8-8.2-2.1-18.4,9.1-22.5c1.2-0.4,1.7-5.8,2.7,0.1c0.5,3.3,3.4,4.3,6.1,4.3c3.6,0,2.9-3.3,2.9-5.6c0.1-5,2.8-5.7,6.7-4.3
				c4.4,1.6,9,0.9,13.5,1.1l-0.1,0c2.4,3.5,6.9,1.6,10.2,4.4c3.8,3.1,4.5-1,4.4-4.3c0.7-0.6,1.3-1.2,2-1.8c3.4,0,4.7,4.7,8.4,3.9
				c5.9,7,14.8,5.2,22.3,7.8c1.9,0.4,3.8,0.8,5.7,1.2c12.9,4.8,12.9,4.8,13.2-3.5c0.3-4.6,0.6-9.1,0.9-13.7c1.3-6,0.6-12,0.3-18
				C2931.9,1545.8,2931.6,1544.1,2933.4,1543.7z M2993.8,1847.8L2993.8,1847.8c-0.6-0.6-1.2-1.2-1.8-1.9l0.1,0.1
				c-0.6-0.6-1.3-1.3-1.9-1.9l0.1,0.1c-1.8-2.4-3.7-4.7-5.5-7.1c-1-4,2.3-7.1,2.6-11c-3,0.5-5.5,0.8-8.1,1.2
				c-0.2-0.1-0.5-0.3-0.7-0.4c0.2,0.1,0.5,0.3,0.7,0.4c0,2.2,0,4.4,0,6.6c0.6,0.9,1.2,1.8,1.9,2.6l0,0c0,2.7-1,5.7,1.9,7.7
				c-2.4,2.9-6.7,5.2-4.6,10c2,4.6,1.2,9.3,0.9,14c-0.1,2.7-1.3,6.6,1,7.8c3.4,1.9,4.6-2.9,7.1-4.3c2.6-1.4,3.3-8,7.1-4.4
				c2.2,2.2-3.1,4.9-4.6,7.7c-0.1,0.3-0.5,0.4-0.6,0.6c-1.4,1.9-5.4,2.1-2.8,6.3c2.7,4.4,3.8,1.1,5.6-0.5c0.5-0.4,1.8-0.1,2.6,0.1
				c10.2,3.1,10.2,3.1,10.1-7.5c0-2.5-0.1-4.9-0.1-7.4c0-3.5-2.1-4-4.9-3.6c-0.3-0.3-0.5-0.6-0.8-0.8c2.3-3.5,3.7-6.5-2.5-7.3
				c-2-0.3-8-0.4-2.7-5.3C2995.2,1849.1,2995.5,1848.5,2993.8,1847.8z M2938.2,1586.9c0.8,0.1,1.6,0.2,2.4,0.3
				c-0.3-0.7-0.5-1.3-0.8-2c-1-1.4-2.3-2.5-3.5-0.6C2935.6,1585.8,2937,1586.6,2938.2,1586.9z M2957.5,1779.2
				c1.6,1.1,3.4,4.6,4.9,0.1c1.3-4-0.1-7-4.5-7.9c-1.6-2.9-2.8,0.7-4.3,0.3c0-3.3,0.4-6.5-3.2-8.6c-2.1-1.2-5.4-2.3-4.3-6.1
				c1.5,0.1,3,0.1,4.4,0.2c1.5,3.1,3.4,5.6,6.8,6.8c1.5,2.2,4.2,3.1,4.9,0.5c1.3-5.5,3.1-12,1.3-16.7c-0.9-2.3-2.5-14-11.6-7.3
				c-4.1-0.1-8.3-0.1-12.4-0.2c-1.6,0-3.1,0-4.7,0c-4.7,8.4-8.1,17.2-6.7,27.1c0.3,2.6-1.5,6.5,1.6,7.5c3.3,1.1,4.8-2.7,6.4-5.2
				c1,0.1,2.1,0.1,3.1,0.2c-2.2,5-3.1,10.3,2.9,12.5c6.8,2.5,5.8-5.2,8.5-8.2c0.9-1,1.1-3,3.2-2.6c-0.5,2.4-1,4.7-1.4,7.1
				c-0.2,1.5-0.2,3.2,1.8,3.6C2956.5,1782.9,2957.1,1781.2,2957.5,1779.2z M2916.7,1890.1c-2-8.8,5.6-17.7-0.8-26.6
				c-0.4,5.3-0.9,10.6-1.3,16c-1.3,1.7-4.5-6.9-4.7,0.8c-0.2,6.3-0.7,12.4-2.6,18.6c-1.1,3.3,0.3,8.6,6.6,6
				C2919.2,1900.9,2916.6,1895.2,2916.7,1890.1z M2969.9,1737.8c-1.3-1.6-2.5-1.6-3.4,0.4c0,7.7-1.1,15.5,0.3,22.9
				c1.2,6.4-2.7,15.2,6.9,19c0-8,0-15.8,0-23.6c0-4-0.2-8-0.3-11.9C2973.3,1741.8,2973.2,1738.9,2969.9,1737.8z M2951.7,1907.2
				c-3.5-0.8-10,2.2-10.1-2.3c-0.3-7.8-3.3-16.2,1.3-23.8c1.6-5.9,5.1,3.5,6.4-0.7c1.2-3.7,2.4-8.9-0.8-11.1
				c-3.5-2.4-8.2-1.4-9.3,4.5c-4.4,7.1,0,15.1-2.3,22.7c-2.5,0.2-1.9-4.2-4.9-3.8c0.1,2,0.8,3.9,0.3,5.6c-1.7,6.2,6.1,8.7,5.2,14.5
				c-1.1,6.6,2.5,8.1,8,7.2c2.7-0.4,5.3-1.1,7.9-1.7c5.5-0.1,8.3-4.7,7.7-8.6C2960.4,1905.8,2955.9,1904.1,2951.7,1907.2z
				 M2931.7,1827.4c-0.2-0.1-0.4-0.3-0.6-0.4C2931.3,1827.1,2931.5,1827.2,2931.7,1827.4c2.3,10.3-7.2,21.8,4.3,31.4
				c-4.3-7.9-5.2-15.7-0.9-23.4c0.9,0,1.7-0.1,2.6-0.1c0,8.2,0,16.3,0,24.5c1.1-0.1,2.2-0.2,3.3-0.3c-2.6-7.1-4.8-16.5-1.3-21.2
				c8.5-11.2-3.5-17.4-1.6-26.9C2930.8,1816.1,2936.4,1823.3,2931.7,1827.4z M2962.8,1844.1c3.4,1.5,0.4,6.5,5.3,9.3
				c0-9,0.6-16.6-0.2-24.2c-0.8-8.6,2.3-16.1,4-24.1c1.8-1.9,2.5-3.7,0-5.6c0-0.9,0-1.9,0-2.8c-0.2-2,2.7-4.2,0.2-5.9
				c-2.5-1.6-3.8,1.1-5,2.6c-2.5,3.2-5.1,3-6.7-0.3c-0.9-2-1.3-3.2-3.6-2.4c-3.2-1-3.5,1.6-3.9,3.5c-2.5,11.1-1.4,22,2.4,32.6
				c-0.5,2.7-0.9,5.5-1.4,8.2c-1.5,4.9,2.7,10.9-3,15c2.3,3.3-2.4,8.3,2.6,11c3.9,2.2,6.8,2.4,7.3-3.4
				C2961.2,1853.1,2962.1,1848.6,2962.8,1844.1L2962.8,1844.1z M2965.5,1597.3c-2.4,0-4.1,0.4-4.3,2.8c-0.2,2.4,0.7,3.4,3.2,4.4
				c7.3,2.9,15.3,2.3,22.6,4.9c2.1,0.8,3.5-0.3,3.5-2.7c0-1.9-0.3-3.5-2.6-4.2C2980.4,1600.5,2972,1602.4,2965.5,1597.3z
				 M2925.8,1928.6c-5.6,5.8-5.7,12.9-8.3,18.9c-0.7,1.6-3.1,4.8,0.9,5.8c2.9,0.8,4.7-1.4,5.1-3.9
				C2924.7,1942.9,2927,1936.4,2925.8,1928.6z M2935.4,1883.3c-1.5-3.7-1.7-8.3-1.7-13c0-2.7-3.8-4.5-7.2-3.6
				c-1.8,0.5-2.5,3-1.2,3.3c9.1,2.2,3.1,9.5,4.8,14.1c0.6,1.6,0.2,4,2.7,4C2935.1,1888,2935.3,1885.9,2935.4,1883.3z M2926.1,1956.5
				c-4.3-0.9-8.4-2.7-10.1,0.5c-1.4,2.7,0.1,6.6,3.8,10.4C2920.5,1962.5,2923.2,1960.2,2926.1,1956.5z M2917.8,1916.2
				c0,4.9,0,9.2,0,14.5C2924.6,1919.9,2924.6,1919.9,2917.8,1916.2z M2966,1859c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2,0.2-0.4,0.4-0.4,0.6
				c0,0.2,0.2,0.5,0.3,0.7C2965.6,1859.4,2965.8,1859.2,2966,1859z M2973.1,1815.4l-0.4-0.3l-0.1,0.4L2973.1,1815.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2548.4,1485.2c-4.9,0.6-9.8,1.3-14.6,1.9c0-0.6-0.1-1.1-0.1-1.7
				c3.8-3.5,2.6-8.6-0.3-9.9c-7.3-3.2-0.3-10.3-4.7-14.7c-1.1-1.1-1.2-9.8,0.4-14c1.7-4.6-2.7-10,0.8-12.5c5-3.4-1.1-7.8,2.3-10.6
				c-0.9-0.2-1.8-0.5-2.7-0.7c-1.8-2.8,5.1-7.3-1.7-9.2c0.1-1.9,0.2-3.9,0.4-5.8c1-0.6,4.1,1.1,2.5-2.2c-2.4-5.2-1.2-10.2,0.1-15.3
				c0.7-8.9,5.8-16.4,7.7-25c1.8-7.8,3-15.7,4.1-23.6c0.4-2.6,2.2-2.2,3.8-2.2c2.2,0.1,2.1,1.4,1.5,3c-0.5,1.4,0.9,3.1-0.9,4.6
				c-2.2,1.8-2,5.2-0.9,6.8c5.4,7.7,4.6,17.3,8,25.6c1.9,4.6,2.6,10.6,1.7,15.5c-1.2,6.4,1.6,9.7,5.8,12.9c-1,2.7-6.1,4.6-4.1,7.1
				c4,4.7,9,10.4,14.4,11.5c4.4,0.9,8.4-0.4,10.4,5c0.8,2.2,2.8,1.7,4.4,1.3c2.3-0.6,2-2.1,1.4-4c-0.9-3-2.2-6.1-1.4-9.4
				c1.9,1.2,7.4-2.7,5.5,4.7c-0.5,1.7,6.3,5,10.2,5.4c3.1,0.3,1.9-3.8,2.2-5.9c0.5-3.6,1.1-6.5,3.6-9.8c3.3-4.2,1-10.8,3.2-16.1
				c0.6-1.6,0.5-3.4,1.9-3.9c8.2,8,8.8,18.8,10.6,29.1c-3.6-2.5-7.4-4.4-13.6-1.2c5.8,1,9.8,1.8,13.8,2.5c0.7,2.8,1.4,5.7,2.1,8.5
				c-1.2,0.7-2.4,1.3-3.6,2c3.4,5-1.6,2.1-2.6,2.9c-2.7-2.6-5.6-1.2-8.5,0c2.8,0,5.7,0,8.5,0c0.4,1.4,1.1,2.8-1.4,3
				c-2.2,0.2-4.7-0.9-7.2,1.9c4.1,1.4,7.7,2,10.8,0.1c2.1-1.3,2.4,0.2,3.5,0.8c1,0.6,1.3,1.5,0.5,2.3c-1,1-2.2,0.4-3.4,0
				c-4.4-1.3-8.7-0.8-13.2-0.5c1.3,6,6.9,2.6,9.1,5.7l0,0.1c-3.2,0.8-8.5-2.1-9.3,2.9c-0.6,4,4.2,2.6,6.8,3.2c0.4,0.1,0.7,0.4,1,0.7
				c-1.9,1.9-11-1.7-4.7,6.3c0.9,1.2,0.3,1.4-0.8,1.8c-5,1.9,2.4,7.3-2.5,9.2c1.8,2.4,4.6,0.3,6.7,1.6c-0.8,2-3.6-0.3-4.5,2.3
				c2.6,0.1,4.9,0.3,7.3,0.4c0,0.3,0,0.6,0,0.9c-3.5,0.2-6.9,0.4-10.3,0.6c-1.5,9.7,6.3,6.2,10.3,7.6c-2.7,3-9.1-2.2-9.4,3.6
				c-0.4,7,6.9,1.1,9.5,4.5c-5,1.2-11.2-1.9-14.6,5.1c-1,2.1-3.7,0.7-3.5-2.6c0.2-4.6-0.2-9.2-0.2-13.8c-0.1-6-3-6.7-7.7-4.5
				c1.8-9.5,5.2-18.6,9.1-27.8c-3.3,0.1-6.4-0.2-5.2-5.1c0.2-0.8-1.8-3-3.1-1.3c-4.8,6.4-7.7-0.3-11.3-1.5c4.9-8.8,4.6-9.4-5-11.7
				c-7.2-1.3-13.1-5.4-19.5-8.5c-4.1-2-3.2,0.5-3.4,2.5c-5.3-1.8-10.6-1.9-16.1-0.8c5.6,0.8,11.4,0.8,16.4,4c-0.3,1-0.6,2-0.9,2.9
				c-5.6,0.9-11.3-3-18.6,0.7c7.1,1.1,13.2-0.8,17.9,3.5c-1.1,0.2-2.1,0.3-3.2,0.5c0.4,0.5,0.8,1.1,1.3,1.3c1.7,0.6,1.7-0.7,1.8-1.9
				c1,0.1,2.1,0.1,3.1,0.2c-1.5,3.9,2.5,8-0.5,11.9c-0.9,0-1.7-0.1-2.6-0.1c0.3-3-2.9-2.6-4.8-3.9c2.2-0.5,3.5-0.8,4.7-1
				c-4.1-4.9-8.8-4.9-14.3-2.6c3.6,3.7,7.7,5.5,11.6,7.6c-4.5,0.9-9.4-1.7-14.5,2.5c2.9,0.2,5.3-0.4,6.6,0.6
				c3.4,2.5,9.8-0.9,10.9,5.7l-0.1,0.1c-5.9,0-11.7,0-17.6,0c2,2.6,0.6,6.9,4.7,7.3c4.7,0.4,9.5,1,14.2,1.5c0.2,1,0.3,2,0.5,3
				c-4.7-0.9-9.3-2-14-2.5c-2-0.2-4.7,0.7-4.7,3.5c0,2.4,2.3,2.3,3.9,2.1c4.5-0.7,8.1,1.8,12.1,3.3
				C2547.6,1478.4,2549.5,1482.3,2548.4,1485.2z M2611.4,1429.4c3.4,2,5.5,3.2,8.5,0.8C2617.1,1428.5,2615,1428.9,2611.4,1429.4z
				 M2537.3,1438.5c3.1,0.8,5-0.3,7.2-1.7C2541.8,1436.1,2539.6,1435.6,2537.3,1438.5z M2619.3,1433.1c-2.3,0.6-3.8-0.6-6,1.8
				C2615.6,1434.2,2617.1,1435.5,2619.3,1433.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M955.4,960.4c-0.6,0-1.2-0.1-1.9-0.1c0.1-3,0.3-6.1,0.4-9.1
				c2.1-0.5,2.8-2,3-4c0.1-1.7-0.6-4.1,1.9-4.4c2.8-0.3,0.8,4,3.6,4.3c0-11.1,0.5-22.2-0.2-33.2c-0.4-6.5-2.1-13.2-6.5-18.6
				c1.1-32.1,2.2-64.2,3.3-96.3c2.5-0.2,4-2,4.3-4c1.8-14.4,14.8-17.9,24.4-20.5c7.8-2.1,10.4-7.1,15-11.2c2.3,0.4,4.8-0.1,6.7,1.8
				c3.3,3.1,6.1,4.5,8.4-1.2c1.5-3.7,5.9-1.7,8.7-3.1c0.6,2.1,1.1,4.3,1.7,6.4c-5.5,1.1-4.7,1.8-0.2,4.2c9.5,5.1,18.6,1.7,27.9-0.1
				l0.5,0.2c0,0,0.6-0.1,0.6-0.1c0.2,1.2,0.4,2.4,0.6,3.6c-0.6,2.3,1,3.7,1.9,5.5c-6.5,1.9-13,4.7-19.9,2.2
				c-2.6-0.9-4.1,1.4-4.1,2.1c-0.5,8-7.2,13.7-7.2,22.1c-0.1,17.2-1.3,34.3-2.8,51.5c-0.1-13.7-1.8-27.4,0.3-41.1
				c1.6-10.1,1-20.4,0.3-30.5c-0.6-7.7-5.4-10.3-13.3-8.3c14.3,11,6.9,26.3,7.6,39.9c0.2,3.4-1,7.3-1,11c0.5,29.3-3.2,58.5-2,87.9
				c0.1,1.7,0.7,3.7-1.4,5.1c-2.5-0.5-2.2-2.7-1.9-4.2c2.7-10.7,1.7-21.5,1.4-32.3c-0.7-27.5,2.6-54.9,2-82.3
				c-0.2-7.2-1.9-13-8.7-16.9c-4.5-2.6-6.6-0.4-5.5,2.9c2,5.8-2.3,6-5,7.7c-15,9.3-20.4,30.7-11.7,46.3c2.8,4.9,0.6,21.6-3.4,26.1
				c-3.5,3.9-4,18.9-0.1,22.1c4.8,4,4.3,9,1.2,11.9c-5.9,5.5-5.6,11.9-5.4,18.7c0.2,7.4,1.3,16.8-3.8,21.1
				c-16.4,13.7-11.4,32.7-14.2,49.7c-0.9,5.7-0.1,11.6-2.2,17.5c0-13.8,0.1-27.6-0.1-41.3C958.7,965.7,960.4,961.6,955.4,960.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2144.7,1640.8c23.8-3.5,47.9-5.1,71.6-9.6c3.5-0.7,7.3-0.8,10.9-0.5
				c9.3,0.7,18.3-0.9,27.3-2.8c1.1,5.6,5.3,5,9.2,4.6c30.4-3.2,61-4,91-10.7c4.1-2.4,9.2,5.6,12.9-1.3c3.8-0.8,7.6-1.8,11.4-2.4
				c3.6-0.6,6.3,0.7,8,4.3c2,4.5-2.4,4.4-6.1,7.2c15.1,0.8,25,9.7,37.2,14.6c-7.9-0.4-2.4,13.9-12.5,10.6c-2.3-3-6.1-1.6-9.1-3.2
				c-3-1.6-7.7,1.6-10-2.3c-3.4-6.1-6-2.1-8.9,0.2c-0.7,0.5-1.5,1-2.2,1.6c-2.4-4.8-8.7-5.6-11-10.8c-0.3-0.8-3.4-0.3-5.2-0.4
				c-2.7-0.1-5.7,0.5-8.1-0.4c-16.8-6.5-33.5-1.1-50.2,0c-12.1,0.8-24.4-0.9-36.4,3.9c-4.5,1.8-10.7-1.6-16-1.2
				c-19.4,1.3-37.3,9.8-56.3,13c-1.7,0.3-2.3,1.8-2.5,3.4c-1.1,0-2.3,0-3.4,0c-2-2.8-5-2.1-7.6-1.8c-11.4,1-22.8,2.2-34.2,3.4
				C2144.6,1653.7,2144.6,1647.3,2144.7,1640.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2620,1437.8c1-0.9,6.1,2.1,2.6-2.9c1.2-0.7,2.4-1.3,3.6-2
				c5.3,0.4,7.8,2.3,5.7,8.3c-0.7,2-2.3,5.3,1.1,7.2c0.9,2.7-1.8,6.8,2.2,8.3c2.9,1.1,5-0.6,5.2-4.1c2.3,0.6,4.8,1,5.6,3.8
				c-6.4,3.7-6.6,6.3-0.6,10.1c2.4,1.6,4.6,3.5,7.2,4.6c2.2,1,2.1,3.8,1.6,4c-6.5,3-3.3,8.4-3.4,12.9c-0.1,2.5-1.4,3.5-3.8,3.1
				c-0.2-1.9-0.5-3.9-0.6-5.8c-0.1-8.7-5.6-14.3-13.9-15.2c-5.5-0.6-5.9,1-6.4,6.5c-1,11.6,3.1,24.4-6,34.6
				c-0.4,0.5-0.2,1.4-0.3,2.2c-3.7,0-7.4,0-11.1,0.1c-0.2-3.1,0.3-6.7-4.3-7.2c-1.5-0.2-3.1-0.5-3.3,1.6c-0.2,2.5-1.4,5.7,2.8,6.3
				c-5.1,7.2-6.7-2.2-10.3-2.3c-3.3-0.6-3.3-2.5-2.7-5.5c0.8-4.6,3.8-9.7-1.5-13.7c0.2-1.5,3-1.8,2.1-3.4c-1.3-2.4-3.9-1-6-1.2
				c-1.9-0.2-3.5,0.4-3.4,2.8c0.4,7.6,0.9,15.2,1.3,22.8c0.1,1.4,0.9,2.4,2.3,2.4c2.1,0.1,2.3-1.6,2.1-3.1c-0.3-3.5,0.7-7-1.5-10.7
				c-2-3.4-3.3-8.2,3.1-9.7c-0.1,8.4-0.1,16.8-0.2,25.2c-3,2.3-2.5,6.1-3.8,10.5c-2.1-5.3-4.1-9.4-9.6-9.5c-1.3,0-1.1-2-1.2-3.2
				c0.1-3.7,0.2-7.3,0.1-11c-0.1-9.7-0.4-19.3,2.7-28.6c5.1,1.3,11.4-1.4,15,4.8c4.7-2.3,7.7-1.5,7.7,4.5c0.1,4.6,0.4,9.2,0.2,13.8
				c-0.1,3.3,2.5,4.7,3.5,2.6c3.4-7,9.6-3.8,14.6-5.1c-2.6-3.4-9.9,2.5-9.5-4.5c0.4-5.7,6.7-0.5,9.4-3.6c-4-1.4-11.8,2.1-10.3-7.6
				c3.4-0.2,6.8-0.4,10.3-0.6c0-0.3,0-0.6,0-0.9c-2.3-0.1-4.7-0.3-7.3-0.4c0.8-2.5,3.7-0.3,4.5-2.3c-2-1.4-4.9,0.7-6.7-1.6
				c5-1.9-2.4-7.4,2.5-9.2c1.1-0.4,1.7-0.6,0.8-1.8c-6.3-8.1,2.8-4.4,4.7-6.3c-0.4-0.2-0.7-0.6-1-0.7c-2.6-0.6-7.4,0.8-6.8-3.2
				c0.8-4.9,6-2.1,9.3-2.9c0.7,1.1,1.7,1.5,2.9,1.1c0.2-0.1,0.3-0.4,0.4-0.6c-1.1-0.2-2.2-0.4-3.2-0.6c-2.2-3.1-7.8,0.3-9.1-5.7
				c4.4-0.3,8.8-0.8,13.2,0.5c1.2,0.3,2.4,0.9,3.4,0c0.9-0.8,0.5-1.7-0.5-2.3c-1-0.6-1.4-2.1-3.5-0.8c-3.1,1.9-6.7,1.3-10.8-0.1
				c2.5-2.7,5-1.7,7.2-1.9C2621.1,1440.6,2620.4,1439.2,2620,1437.8L2620,1437.8z M2630,1452.1c0.4-0.2,1-0.3,1.1-0.6
				c0.1-0.3-0.2-0.8-0.3-1.2c-0.5,0.3-1,0.5-1.4,0.8C2629.5,1451.4,2629.8,1451.8,2630,1452.1z M2622.2,1467.3
				c0.4-0.2,0.8-0.4,1.2-0.6c-0.4-0.2-0.7-0.7-1.1-0.7c-0.4,0-0.8,0.4-1.2,0.6C2621.4,1466.8,2621.8,1467,2622.2,1467.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1702,996.9c2.4,3.2,2.5,3.2,1.1,3.7c-7,2.7-14.2,5.1-21.1,8.1
				c-4.5,1.9-7.7,5.5-8.2,10.6c-0.7,6.8-2.7,13.5-0.9,20.4c-3.9-0.3-4-2.8-3.1-5.8c1.5-5.1,0.9-10.3,0.6-15.3
				c-0.3-5.3-3.5-7-7.2-4.3c-1.6,1.9-3.2,3.9-4.7,5.8c-3.8-5.3-3.7-14.1-12.7-14.9c-0.1,0-0.1-1.8-0.1-2.8c0.2-0.1,0.4-0.1,0.6-0.2
				c0.1-0.1,0.1-0.3,0.1-0.5c-0.2,0.3-0.4,0.5-0.7,0.8c-1.4,0.5-2.8,1.1-4.2,1.6c-5.3-7.8-5.7-8-9.1-3c-3,2.4-6.3,3.2-9.8,1.3
				c-1-5.1-3.3-8-8.9-5.8c-2.1-1-3-2.2-1.2-4.4c3.3-4.3,2.1-8.1-1.7-11.4c2.3,0.1,6,2.4,6.3-1.6c0.3-4.2-3.6-3.4-6.5-3.2
				c0.3-1.6,2.1-3,0.8-4.7c-0.3-0.3-2.3,0.8-3.5,1.3c1-4.5-0.3-9.8,4.1-13.2c4.5,4,8.9-0.1,13.4-0.1c-0.1,4.6,2.1,5.7,6.5,6.1
				c5.2,0.5,6.6-3.8,9.7-5.9c0.4-0.1,0.8,0,1.3,0.2c-0.5,2.9-1,5.8-1.5,8.8c0.4,3.3-3.4,2.4-4.3,4.3c-4.2,0.5-4.7,5.1-7.5,7.2
				c-1,0.8-1.2,2.1-0.1,3.1c1.1,1,1.7,0.4,2.9-0.2c4.3-2.1,6.1-5.4,6-10.1c5.3,0.9,2.4,4.6,2.5,7.4c0.1,4.3-7.4,8.4-0.5,13.2
				c0.5,0.3-2.1,4.7,2.2,4.3c3.1-0.3,5.8-2.6,5.5-5.6c-0.3-3.4,1.3-4.1,3.5-3.7c3,0.5,4.6-1.9,6.1-3.1c6.3-4.5,12.7-3.5,19.4-2.6
				c-2.9,1.2-5.9,2.7-5,5.9c1,3.8,4,1.1,6.1,0.6c3.8-0.8,7.5-1.7,11.2-2.8c5-1.5,2.6-3.4,0.1-5.1c1-1.2,2.5-1.3,3.9-1.3
				c1.2,3.4,4,6.8,0.5,10.5c-1.1,0.1-2.2,0.3-3.3,0.4c-6-0.3-11.7,0.2-18.2,4.5c6.9,1.6,11-2.2,15.8-2.1c0,1-0.1,2.1-0.1,3.1
				c-2.9,1.5-5.7,3.1-8.6,4.6c5.6,0.7,10.2-0.8,14.1-4.3C1696.5,996.1,1699.3,1001.3,1702,996.9z M1618.6,966.7
				c-1.3,0.3-3.6-0.4-3.3,2.4c0.3,2.2,1.7,3.5,4,3.2c1.7-0.2,3.1-1.1,3.1-3.4C1622.3,966.1,1620.3,966.9,1618.6,966.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1686.9,1666.9l-0.7,0.1c0,0-0.8-0.1-0.8-0.1c-0.9-0.9-1.8-1.8-2.6-2.8
				c0.3-1.5,0.9-3.7-0.7-3.9c-2.8-0.3-1.8,2.2-1.7,3.9c-2.7,0.8-5.3,0.2-8,0c-12.2-1-24.4-2.3-36.6-0.2c-4.6-0.7-10.1,1.7-13.1-4.2
				c3.8-2.4,7-5.1,12.3-4.3c5.8,0.8,5.9,0,5.7-8.2c-6.2,8-14.4,3.6-22.8,3.8c4,4.2,0.7,6.1-2.1,8.2c-4.2,1.7-7.1,7.8-12.4,5.4
				c-4.2-2-7.5,0.4-11.3,0.5c-0.6-0.7-1.2-2.1-1.6-2c-5.6,0.7-10.5-1.1-15.2-3.7l0.2,0.1c-0.8-0.7-1.5-1.3-2.3-2
				c-4.8-0.1-9.6-0.1-14.3-0.2c-0.4-1.6,1.8-4-0.1-4.6c-3.5-1.2-3.8,2.6-5.1,4.6c-2,0-3.9,0-5.9-0.1c-0.1-3.8,3.2-10.3-3.9-9.4
				c-2.5,0.3-2.8,2.6-3.8-1.2c-0.9-3.1-4-1.5-6.2-1.8c-0.2-1.3-0.4-2.5-0.6-3.8c0.2-0.4,0.3-0.9,0.2-1.4c3.5,0.9,7,1.1,10.5,0.5
				c2.2,1.2,4.4,3.6,6.6,0c10.9,1.5,22-0.8,32.7,2.5c1.5,0,3.1-0.1,4.6-0.1c5.3,2.2,10.2,0.9,15-1.8c5,0,10,0,15.1,0.1
				c4.4-0.4,8.8-0.8,13.5-1.2c-0.8-3.2-6-2.7-4.9-6.8c6.2,0.8,13.8-5.2,18.1,4.2c7,1.9,13.9,4,20.8-0.2c1.2,3,2.4,6.1,3.6,9.1
				c-6.3,1.7-13.2-1.3-21.1,2.7c8,2.6,14.4,1.5,20.6,1.6c3.8,0.1,2.4-2.4,2-4.4c7.7-3.8,17.2,0.9,24.4-5.3
				c13.7,1.5,27.6-0.7,41.2,2.6c0,2.2,0,4.3,0.1,6.5c-11.1-3-22.4-0.9-33.5-1.2c-8.8-0.3-17.6-0.1-27.8-0.1
				c6.2,5.5,17.5,6.7,12.4,18.2L1686.9,1666.9z M1601.9,1652.3c1.9,2.3,3.1-0.4,4.6-0.7c2.2-0.4,5,1.5,6.4-1.6
				c0.3-0.6-0.1-2.1-0.7-2.5c-2.9-1.8-5,1-7.4,1.8C1603.7,1649.7,1601.8,1650.3,1601.9,1652.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2522.2,1385.8c1.3-0.1,2.1,0.5,2.7,1.6c0.5,0.5,1.1,1,1.6,1.4
				c0,0-0.1-0.2-0.1-0.2c-0.9,6-3.7,11.6-2.9,17.9c-0.6,0.6-1.2,1.2-1.8,1.8c-1.9,0.4-4.2,1.7-5.5,1c-6.4-3.6-5.2,1.7-5.8,4.8
				c0,3.9,0,7.8,0,11.8c-1.2,3.3,3.6,4.5,2.5,7.7c-2.7,3.3-3.8,18.2-1.5,21.1c0.2,0.2,1.3-0.4,2-0.7c0.8,2.8-3.4,4.3-1.8,7.2
				l0.2,0.1c-1.9,1-1.8,2,0.1,2.8c0,0.6-0.1,1.1-0.1,1.7c-2.3,11.4-2.2,22.9-0.2,34.3c0.7,1.8,0.9,4.1,2.1,5.3
				c3.6,3.5,1.3,4.8-1.7,6.2c-1.9,1-3.8,2.1-6,1.6c0.1-2.9-0.4-6,0.3-8.8c0.9-3.5-3-5.9-1.3-9.3c2.4-4.8,0.9-10.1,1.1-15.1
				c0.1-2.7-1.8-1.1-3-0.8c-0.2-2.2,1.7-3.4,2.4-5.1c1-2.5,1.4-4.9-2.4-5.3l-0.1-0.1c-0.2-0.5-0.4-1-0.6-1.5
				c1.5-1.8,4.1-2.9,3.6-5.9c0-8.7-0.1-17.4-0.1-26c0-3.2,0-6.3,0.1-9.5c0-2.4,0.2-4.7,0.1-7.1c-0.2-4.4-2.3-3.9-4.9-1.8
				c-0.8,0.4-1.7,0.9-2.5,1.3c0.2-4.3,4.6-6.7,5.2-11.5c-6.3,1.3-12.3,2.5-18.3,3.7c-0.6,8.8-2.2,17.5-1,26.4
				c0.2,1.9-0.9,3.9-3.5,3.7c1.5-19.3,3-38.7,4.5-58c0.2-2,0.2-3.8,2.6-4.7c10.9-4,21.8-4.9,32.3,1c1.3,0.7,1.3,2.5,1,4.1
				c-2.3,0.2-4.7-0.8-6.7,0.2C2515.9,1388.6,2518.2,1390.3,2522.2,1385.8z M2488.4,1397.7c-2,2.6-2.3,5.9-0.3,7.9
				c2.3,2.3,5.2,0.3,7.5-1.6c4.1-0.6,3.6-2.5,1-4.5C2494.2,1397.7,2491.2,1397.7,2488.4,1397.7z M2504.4,1383.3
				c0.8-2.5,1.1-6-1.8-6.1c-3.6-0.2-8.2-0.6-10.1,4.3c-1.4,1.2-0.9,2.9-1.3,4.4c-3.8-0.8-4.7,1.4-4.4,4.6c0.1,1.7,0.7,2.9,2.7,2.3
				c3.6-1.1,3.6-3.4,1.8-6.2c-0.1-0.2-0.1-0.5-0.1-0.7c4-1.5,4.6,2.5,6.6,4.2c1.4,1.2,2.4,3.5,4.7,2.8c2.6-0.8,2.1-3.6,2.7-5.5
				C2505.7,1386.3,2504.7,1384.7,2504.4,1383.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1567.8,1666.5c0.2,4.1,1.5,8.7-6,8.6c26.7,0.7,52.1,6.3,78,6.4
				c26.8,0.1,53.5,2.7,80.3,2.1c5.4-0.1,10.8,1.2,16.2,1.8c0,2.5,0,5-0.1,7.4c-3.5-0.2-6.9-0.4-10.4-0.5c-3.7-2.1-7.5-1.4-11.3-0.7
				c-1.8-0.1-3.6-0.2-5.4-0.3c-21.2-2.5-42.5-2.5-63.8-5c-32.4-3.8-65.2-3.5-97.9-4.9c-3.3-0.1-6.8,0.7-8.2-3.7
				c-0.4-1.5-1-2.7-3.2-2c-8,2.7-15.4-1.6-23.2-2.1c3.8-3.4,1.2-7.1,0.6-10.7c0.7-0.3,1.4-0.7,2-1c1.3-0.1,2.6-0.1,3.9-0.2l0.3,0.1
				c1,1.8,1.5,5,4.2,1.4c2.9-3.3,2.6-8.3,6.1-11.3c0.8,2.7-1.7,8.4,5,5.9c0.7,0,1.5,0,2.2,0
				C1547.1,1661.9,1556.4,1667.8,1567.8,1666.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2453.3,1526.5c-0.4-1.7-0.9-3.4-1.3-5.2c4-0.7,8.1-1.5,12.1-2.1
				c12-1.8,12.3-1.8,12.7-14.3c0.4-13,2.3-25.9,1.7-38.9c-0.4-7.5,3.3-14.8,2.6-22.5c5.7,2.1,1.7,7.2,3.1,10.6
				c-3.1,14.7-5.9,29.4-3.1,44.5l0.1-0.1c-5.1,4-3.6,8.7-1.5,13.5l-0.1-0.1c0.6,1.5-2,3.8,0.3,4.4c2.2,0.6,4.8,0.1,7.1-0.5
				c1.7-0.5,1.2-2.3,0.7-3.8c-1.2-3.1-1-6.2,0.1-9.3c3.3-9.5,0.7-19.5,2.1-29.3l0.1-0.1c2.7-5.2,1.7-10.9,2.6-16.4
				c0.5-2.8-2.2-3.5-4-4.5c4.4-3.8,6-8.8,5.5-14.4c-0.3-3.4-0.6-6.6,1.4-9.5c3.2,0.4,3.3,2.7,3.3,5.2c-2.6,15.9-1.2,32.2-4,48.2
				c-0.5,2.6,1,4.2,4,3.3c-0.1,1.5,0.4,3.8-0.3,4.3c-6.6,4.6-4.7,11.6-5.7,17.9c-1,6.6,1.9,8.2,7.5,7.6c0,1.1,0,2.2,0,3.2
				c-5.9,0.9-10.2,3.2-9.1,10.6c0.2,1.5-1.2,3.2-2.2,5.7c-1.5-2.9-2.6-4.8-3.5-6.8c-1.9-4.3-4-5.4-6.7-0.6c-0.6,1-1.6,1.9-2.4,2.8
				l-0.5,0.1l-0.4-0.2c0.7-6.9-1.9-8.4-8.4-6.5C2462.5,1524.6,2457.6,1524.3,2453.3,1526.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2671.2,1466c-0.2-2.1,0.4-4.1,1.3-5.9c4.1-1.1,7.9,1.6,11.9,1.3
				c2.1,6.1,0,12.2,0.3,18.3c0.2,5.7,0.6,11.5,0.9,17.2c0.6,1.1,0.9,2.5,1.8,3.1c1.2,0.8,4.2-2.9,4.2,1.6c0,2.6-0.4,4.9-3.4,5.8
				c-2.8,0.9-4.7,1.4-2,5.4c2.6,3.9-1.6,8.4-6.7,8.2c-11.2-0.6-12.8-4.3-7.9-14.4c2-4.2-5.9-10.5,1.8-14.7c-4.6-3-4.1-5.6,0.1-8.8
				c1.8-1.4-1-4-1.2-6.1c2.4-1.3,6,0.1,7.7-3.1c-5.5-0.5-5.4-0.6-6.4-6.4C2673.5,1466.9,2672,1466.5,2671.2,1466z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M984.1,954.8c5.4,2.4,11-1.4,16.4,0.3c1,0.3,1.4,0.3,1.7-1.2
				c1.5-7.4,1.6-7.4,8-1.6c-4.7,2.2-4.7,2.2-3.9,12.7c2.2-2.6-0.6-6.8,2.5-8.1c2.5-1.1,5.8-1.1,8.6-0.7c7.8,1.1,12.3-1,11-10.4
				c1.1,7.8,1.5,15.2-0.3,22.6c-7.2-0.3-14.4-0.9-23.8,0.6c9.5,0.7,16.9,0.7,24.3-0.1c1.2,0.3,2.3,0.5,3.5,0.8
				c-9.3,2.7-18.7,2.9-28.2,2.8c-5-0.1-10-1.1-11.3,6.5c-0.7,4.3-5.4,1.4-8.1,2.2C984.2,972.4,984.2,963.6,984.1,954.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1689.6,981.4c2.6,1.8,4.9,3.6-0.1,5.1c-3.7,1.1-7.4,2-11.2,2.8
				c-2.1,0.4-5,3.1-6.1-0.6c-0.9-3.3,2.1-4.7,5-5.9c-6.7-0.8-13.1-1.9-19.4,2.6c-1.6,1.1-3.2,3.6-6.1,3.1c-2.3-0.4-3.8,0.2-3.5,3.7
				c0.2,3-2.4,5.3-5.5,5.6c-4.3,0.4-1.7-4-2.2-4.3c-6.9-4.8,0.6-8.9,0.5-13.2c-0.1-2.8,2.8-6.5-2.5-7.4c-0.4,0.1-0.9,0.1-1.3,0.1
				c0.9-2,4.7-1,4.3-4.3c2.2,0.8,0.8,4.2,3.2,4.8c0.4,0.8,0.6,2,1.3,2.3c2,1.1-3.8,4.1,1,4.2c2.9,0,3.3-2.3,3.2-4.7
				c-0.2-4.2,1.2-8.6-1.2-12.6c-0.1-0.9-0.3-1.8-0.4-2.7c3.1-0.1,6.1-0.1,9.2-0.2c0,0.8,0,1.7,0,2.5c-2,1.4-1.4,3.5-1.4,5.3
				c0,1.6-0.9,3.6,1.3,4.4c0.6,0.2,1.7-0.7,2.3-1.3c1.4-1.4,1.5-2.8-0.3-4.2c-0.5-0.4-0.3-1.8-0.5-2.7c3.1,0.4,5.4,2.4,8,4
				c5.3,3.1,10,8,16.7,7.8l0.2,0.1C1681.5,981.9,1685.6,981.6,1689.6,981.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2833,1474.9c-3.8,0-7.3,1-11.3,2.6c0.9,0.7,1.2,1.2,1.6,1.2
				c2,0.1,4.5-2.1,6,0c1.6,2.3-1.1,4.8-2.3,5.4c-5,2.7-7.4,6.7-7.9,12.3c0,0.3-1.2,0.5-1.9,0.8c-0.7-4,0.5-7.7,1.1-11.6
				c0.5-3,1-6.9-4.3-6.1c-2.1-1.7-6.2-0.3-6.7-4.5c0.9-0.2,1.8-0.3,2.6-0.5c0.4,0.2,0.9,0.3,1.4,0.4c3.2,3.7,6.8,3.8,8-1
				c2-8.1,3.4-16.5,2.6-25.1c3.2-1.5,6-4,9.8-4c0.9,0.3,1.7,0.6,2.6,0.9c1.8,0.8,2.1,3.5,4.4,3.6c0.2,2.1,0.8,4.5-0.9,6.2
				c-4.2,4.3-3.9,10-4.6,15.3C2833.2,1472.2,2834.9,1473.7,2833,1474.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M987.8,1015c5.7,3.6,12.3,1,18.4,2.8c4,1.2,6.1,2.3,5.4,6.6
				c-0.6,3.5,1.6,6.9-4.6,9.5c-8,3.3-14.9,5.7-23,2.2c-0.1-6.9-0.2-13.8-0.3-20.8C985.1,1015.3,986.4,1015.2,987.8,1015z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2586.6,1419.7c-0.8,3.3,0.5,6.3,1.4,9.4c0.6,1.9,0.8,3.4-1.4,4
				c-1.6,0.4-3.5,0.9-4.4-1.3c-2-5.4-6-4.1-10.4-5c-5.4-1.1-10.4-6.8-14.4-11.5c-2.1-2.5,3.1-4.4,4.1-7.1c7.6,3.7,14.7,8.7,23.5,9.2
				C2585.5,1417.5,2586,1418.9,2586.6,1419.7z M2584.9,1430.3c0.1-0.2,0.2-0.4,0.3-0.6C2585.1,1429.9,2585,1430.1,2584.9,1430.3
				c-0.1,0.2-0.2,0.4-0.3,0.6C2584.7,1430.7,2584.8,1430.5,2584.9,1430.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2811.5,1500.1c-9-5.2-17.9-0.3-26.8,0.5c-3.1,0.3-6.2,0.5-9.3,1
				c-5.2,0.8-11.1,3.5-11.4,8.4c-0.6,8.5-4.2,7.2-9.2,5c2.5-12.7,4.9-14.9,16.8-16.7c7.5-1.2,15.2-1.2,22.5-3.8
				c2.4-0.7,4.7-2.1,7.3-0.8c4,0.9,7.8-0.1,11.7-0.7C2812.3,1495.3,2813.9,1498.2,2811.5,1500.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2432.9,1523.4c-3.7,3.3-5.8,7.3-12,9c-7.7,2.1-18,4.8-19.5,16.4
				c-0.2,1.7-1.5,3.1-3.4,3.2c0.7-11.9,4.2-17,15-20.6c2.3-0.8,4.2-1.2,4.1-4.1c-0.3-5.2,1.6-7,6.9-5.5
				C2426.7,1522.8,2430.2,1521.1,2432.9,1523.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M955.8,895.2c4.4,5.4,6.1,12.1,6.5,18.6c0.7,11,0.2,22.1,0.2,33.2
				c-2.8-0.3-0.8-4.6-3.6-4.3c-2.5,0.3-1.8,2.8-1.9,4.4c-0.2,2.1-0.9,3.5-3,4C953,932.5,955.6,913.9,955.8,895.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2684.4,1461.4c-4.1,0.3-7.8-2.4-11.9-1.3c-1.7,0.1-3.4,0.2-5,0.3
				c-1.1-4.7-1.7-9.3,3.2-12.4c3.5,0,7.1,0,10.6,0c0.2,0.8,0.4,1.5,0.5,2.3c-3.7,3.7-0.8,5.1,2.2,6.5
				C2684.1,1458.4,2684.3,1459.9,2684.4,1461.4z M2677.4,1450.4c-0.4-0.2-0.8-0.5-1.2-0.5c-0.4,0-0.7,0.4-1.1,0.6
				c0.4,0.2,0.8,0.5,1.1,0.5C2676.6,1450.9,2677,1450.6,2677.4,1450.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2833.4,1470.9c0.8-5.3,0.4-11,4.6-15.3c1.6-1.7,1-4.1,0.9-6.2c0,0,0,0,0,0
				c0.8-0.1,1.6-0.2,2.3-0.2C2848.7,1457,2845.6,1465.8,2833.4,1470.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2365.9,1428.8c7.4-0.1,14.9-1.8,21.3,3.4c-6.3,4.8-13.9,3.9-21.4,3.5
				C2365.8,1433.5,2365.9,1431.1,2365.9,1428.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2811.5,1500.1c2.4-2,0.8-4.8,1.5-7.1c-0.3-0.2-0.3-0.4-0.2-0.7
				c2.4-1.2,2.1-2.7,0.3-4.2c1.5-2.7,2-5.5,1.1-8.6c5.3-0.9,4.8,3,4.3,6.1c-0.6,3.9-1.8,7.6-1.1,11.6c-0.1,1.5-0.3,3-0.4,4.5
				c-1.6,1.2-1.1,2.9-1,4.5c-2.2,1.1-1,5.8-5.1,4.7c0.8-2.7,0.6-5.4,0.2-8.1C2811.2,1501.9,2811.4,1501,2811.5,1500.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1023.4,921c-4.5-0.8-3.7-4.8-4.5-7.8c1.2-8.2-0.3-16.7,3.1-27
				c2.4,10.3,0.4,18.7,1.5,26.9c-0.1,2.3-0.2,4.5,1.6,6.2C1024.5,919.9,1024,920.4,1023.4,921z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2822,1448.8c0.8,8.5-0.6,16.9-2.6,25.1c-1.2,4.8-4.8,4.7-8,1
				c6-5,6.8-12.1,7.5-19.3C2819.1,1452.9,2819.3,1450.3,2822,1448.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1018.9,825.6c-3,3.3-18.4,6.1-21.5,3.9c-0.8-0.6-1.6-1.3-1.1-2.5
				c0.7-1.8,2.3-2.6,4.1-2.6c6.1-0.1,12.1-0.1,18.2-0.1C1018.6,824.9,1018.7,825.3,1018.9,825.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2671,1446.6c-0.1-1.9-0.3-3.9-0.2-5.8c0.1-3-0.7-7.2,4.1-6.7
				c3.5,0.3,7.1,1.8,6.5,6.9c-0.2,1.9-0.2,3.9-0.3,5.8C2677.8,1446.7,2674.4,1446.6,2671,1446.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1041,867c1.4,0.6,5.1-4.5,4.3,1.9c-0.5,3.8,0,7.7-0.3,11.5
				c-0.3,3.4-1.3,6.4-5.8,5.5C1039.9,879.6,1037.8,873,1041,867z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M990.7,842.2c-2.8,1.5-7,2.3-5.3,6.9c1.6,4.1,5.7,2.4,8.2,1.6
				c4.6-1.4,8.3-0.7,11.9,2.1c0.1,0.1,0.1,0.6,0,0.7c-0.4,0.3-0.8,0.5-1.3,0.7c-4.6-0.9-9.1,0.2-13.6,0.1c-2.9-0.1-7.5,1.4-6.9-5.4
				C984.1,843.3,985.4,841.2,990.7,842.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2523.6,1406.5c-0.8-6.2,2.1-11.9,2.9-17.8c1.8-0.2,3,0.9,4.2,1.9
				c-1.3,5.1-2.5,10.1-0.1,15.3c1.5,3.3-1.5,1.7-2.5,2.2C2526.6,1407.5,2525.1,1407,2523.6,1406.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1018.9,825.6c-0.2-0.4-0.3-0.8-0.4-1.2c2.8-0.6,3-5.1,6.6-4.6
				c2.7,1.5,6.8,2,5.6,6.7c-0.9,3.5-3.8,1.9-6,2C1022.2,828.8,1021,826.4,1018.9,825.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2723.6,1520.8c0.2,0,0.5,0.1,0.7,0c3.7-0.2,8.3,0.2,8.3,4.3
				c0,2.3-5.1,4-8.8,3.1c-0.5-0.1-1.1-0.2-1.6-0.3c0.3-2.1,0-3.8-2.3-4.4C2721.1,1522.6,2722.4,1521.7,2723.6,1520.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2666.7,1461.4c-0.1,4.5-3.8,4.6-6.2,3.7c-5-1.8-10.5-3.5-13.1-9.1
				c5.3,0.5,9.3,5.1,14.7,5.2c0.4,0.6,0.8,1.1,1.5,0.4C2664.6,1461.6,2665.7,1461.5,2666.7,1461.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1025.1,919.3c-1.8-1.8-1.7-4-1.6-6.2c2.9,0.5,5.8,1.1,8.6,1.6
				c0.6,1.7,3.4,2,2.5,4.5C1031.4,919.2,1028.3,919.3,1025.1,919.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2640.4,1452.5c-0.1,3.5-2.3,5.1-5.2,4.1c-4-1.4-1.3-5.5-2.2-8.3
				C2635.7,1449.3,2638.5,1450.2,2640.4,1452.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2731.1,1507.5c-2.8-1.3-0.9-3.9-1.6-5.8c4,0.1,6.6-5.2,11.4-2.6
				C2738.9,1503.4,2731.8,1501.8,2731.1,1507.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1099.1,829.2c0.5-0.2,1-0.5,1.5-0.7c0,8.2,0,16.4,0,24.6c-0.5,0-1,0-1.5,0
				C1099.1,845,1099.1,837.1,1099.1,829.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2624.2,1424.3c-4-0.7-8.1-1.5-13.8-2.5c6.2-3.2,9.9-1.3,13.6,1.2
				C2624.2,1423.4,2624.3,1423.9,2624.2,1424.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1140.4,1032.1c-4.1,4.6-6.4,2.8-7.3-2.6c1.9-1,4.3,0,6.7-0.2
				C1139.9,1030.2,1140.2,1031.2,1140.4,1032.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2801.3,1493.7c-2.6-1.3-4.9,0-7.3,0.8c1.1-2.2,0.3-5.9,3.8-6
				C2800.9,1488.4,2801.3,1491.1,2801.3,1493.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2719.9,1523.5c2.3,0.6,2.6,2.4,2.3,4.4c0,0.6,0,1.3,0,1.9l0.2-0.1
				C2719,1528.7,2715,1527.9,2719.9,1523.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2791,1485.3c0.9-4.8,4.5-2.6,7.2-2.9c0,0.9,0,1.9,0,2.8
				C2795.8,1485.2,2793.3,1485.2,2791,1485.3L2791,1485.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2684,1456.9c-3-1.4-5.9-2.8-2.2-6.5
				C2683.5,1452.2,2684.5,1454.3,2684,1456.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2671,1446.6c3.4,0,6.8,0.1,10.1,0.1c0.1,0.5,0.2,0.9,0.1,1.4
				c-3.5,0-7.1,0-10.6,0C2670.8,1447.6,2670.9,1447.1,2671,1446.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2798.2,1485.2c0-0.9,0-1.9,0-2.8c1.5,0,3,0,4.5-0.1l0,0
				C2802.5,1485.3,2800.4,1485.3,2798.2,1485.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2810,1474.5c-0.9,0.2-1.8,0.3-2.6,0.5c-1-0.7-2.1-1.7-0.6-2.6
				C2808.7,1471.3,2809.8,1472.4,2810,1474.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2834.5,1445.8c-0.9-0.3-1.7-0.6-2.6-0.9c0.2-1.3-0.3-3.4,1.6-3.1
				C2835.6,1442.1,2834.4,1444.3,2834.5,1445.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2783.1,1487.5c-1.5,1-2.9,1.2-4.4-0.1
				C2780.3,1486.5,2781.7,1486,2783.1,1487.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2802.7,1482.3c0.2-1.1,0.2-2.5,1.9-2.3c0.2,0,0.5,0.3,0.5,0.5
				C2805.1,1482.2,2803.7,1482.1,2802.7,1482.3L2802.7,1482.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2790.9,1485.2c-1.1,2.5-2.9,1.7-4.8,1.1
				C2787.5,1484.3,2789.3,1485.3,2790.9,1485.2C2791,1485.3,2790.9,1485.2,2790.9,1485.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2813,1488c1.8,1.6,2.1,3-0.3,4.2C2812.8,1490.8,2812.9,1489.4,2813,1488z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1071.4,613.3c0.3-0.8,0.7-1.5,1-2.3c0.2,0.2,0.4,0.3,0.5,0.5
				c0.3,1-0.1,1.8-0.9,2.3C1071.9,613.8,1071.4,613.3,1071.4,613.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1080.3,601.6c-0.2,0.4-0.5,0.7-0.7,1.1
				C1078.8,601.7,1079.5,601.6,1080.3,601.6L1080.3,601.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1144.7,1035.1c-0.5-0.5-1.1-1-1.6-1.4
				C1144,1033.7,1144.6,1034.1,1144.7,1035.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2663.6,1461.7c-0.7,0.8-1.1,0.2-1.5-0.4
				C2662.6,1461.4,2663.1,1461.6,2663.6,1461.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2835,2085.3c0.5,0.7,1,1.3,1.4,2c-1.4,5.3-4.9,8.3-9.8,10.7
				c-12.7,6.1-25.6,12.1-36.4,21.6c-1.8,1.6-4.6,1.8-6,0.7c-2.8-2.2-4.7-0.2-7.1,0.1c-6.6,0.8-11.1,6-17.1,8
				c-1.5-0.3-2.8-0.3-3.9,1.1c-0.7,0.1-1.4,0.2-2,0.4c-0.5,0-1,0-1.5,0c-0.7,0-1.3,0-2-0.1c-4.1-1.6-8.4-4.5-11,2
				c-1.8,0.1-3.6,0.1-5.4,0.2c-1.4-2.3-3.5-2-5.6-1.8c-28.7,3-57.5,2.2-86.3,2.3c-8.4,0.1-22.3-5.3-25.2,10.9c-0.1,0.5-0.6,1-1,1.5
				c-5-4-11.2-0.8-16.6-2.7c-2-0.7-2.2-5.8-4.7-2.9c-1.8,2.2-5.2,5.8-1.5,9.6c-12.3,0-24.6,0.1-36.9-0.2c-3.6-0.1-6.4,0.5-8.7,3.3
				c-0.9-3-3.6-3-5.9-3.1c-20.7-1-41.3-5-62-0.2c-1.8,0-3.6,0-5.4,0c-3.6-0.6-7.2-1.1-10.8-1.7c-1.7-5.6,3.9-5.4,8.4-9
				c-9.2,1.9-16.1,3.2-22.8,4.6c-1.7,0.4-3.5,0.6-3.5,3.3c0,3.2-3.2,2.4-3.2,1.2c-0.1-8.3-8.6-7.5-12.2-12c-1.9-2.4-5.7-2.8-5.5-7.3
				c0.2-3.4-4-4.5-5-3c-1.8,2.6-8.1-3.1-6.4,4c0.7,2.7-1.2,4.6-3.3,4.5c-6.1-0.2-11,2.6-15.9,5.5c-0.7,0.4-1.8,0.9-2.2,0.6
				c-0.6-0.4-0.7-1.5-1.1-2.3c7.9-0.8-5.7-7.2,1.9-7.7c2.9-0.2,4.4-1.8,5.7-3.9c1.6-2.6,0.3-5-1.3-6.7c-2.7-2.8-3.4,0.8-4.6,2.1
				c-2.2,2.5-5.4,4.9-7.9,3.2c-4.9-3.4-9.1-2.1-13.4,0c-4.2,2-8.1,1.4-12.2,0.3c-3.2-0.9-6.5-1.3-7.2-5.7c-0.7-4.2,2.5-4.9,4.8-6.7
				c1.8-1.4,6.1-2,3.8-5.6c-2.1-3.3-7-1.9-8.3-0.2c-2.4,3.1-3.9,2.6-7.5,2.5c-6.7-0.2-8.4,5.1-3.5,12.9c-5.9-2.7-9.8-4.5-13.7-6.3
				c-0.4-1.2-0.9-2.5-1.3-3.7c6.5-0.4,6.5-0.4,9.3-5.1c-10.1-0.3-10.1-0.3-9.3,5.1c-1.2,0-2.4-0.1-3.6-0.1c-1.3-5-2-10.2-3.9-15
				c-1.8-4.7,0.2-5.2,3.9-5.4c18.2-1,36.5-5.1,54.5,1.7c2.8,1.1,2.6-1.7,4-2.3c2.5-1.1,4.3-3.9,7.9-0.8c4.6,4,6.5-4.5,10.8-2.6
				c0.9-4.9-5.7-2.1-4.6-5.5c1-3.4,4.8-3,7.7-3.6c5.3-1.1,10.6-2.3,15.9-3.3c4.1-0.8,6.5,0.7,6.2,5.3c-0.2,0.3-0.5,0.6-0.7,0.8
				c0.3-0.2,0.5-0.5,0.8-0.7c2.7,0,2.6-1.5,2.2-3.7c-0.7-3.6,2.2-6.4,4.5-5.2c7.8,4,11.8-4.5,17.8-4.9c11.5-0.7,22-6.2,34.4-4.3
				c19.1,2.9,38.7,3.9,58.1,3.2c3.2-0.1,6.7-1,9.8,1c-0.6,3.9-3.7,2.5-5.7,2.5c-14,0.1-28.2,1.6-42-0.4c-10.3-1.5-20.6-3-31-3.9
				c-2-0.2-4.7-2.1-5.3,0.9c-0.9,3.9,2.7,3.3,4.9,3.3c16.7,0.1,33.3,3.7,50,1.9c9.5-1,18.4,3.8,27.8,2c5.5-1,10.3-2.5,9-9.7
				c-1.9-10.1,1.4-20.1,0.8-30.1c-0.1-1.4,1.4-3.2-0.5-4.1c-1.8-0.9-3.7-0.4-4.8,1.4c-4.3,7-10.5,6.2-17,4.4
				c-7.2-2.1-13.4,1.6-17,6.4c-3.7,4.9-8.7,5.4-13.6,9.7c10.2-23.7,1.2-47,8.2-69.3c-1.4-0.1-2.9-0.2-4.3-0.3c0,14.5,0,28.9,0,43.4
				c-0.8,0-1.6,0-2.3,0c0-22.8-0.6-45.6,0.4-68.4c0.3-7-5.6-9.5-6.3-15.1c-2.1,0.3-0.9,2.5-3.2,4.5c0-11.8,0-22.6,0-33.4
				c0-2.5,1.1-3.8,3.3-4.9c2.6-1.2,2.3-3.4,0.8-5.4c-1.3-1.8-2.8-3.5-5.2-3.2c-3,0.5-2.6,3-2.6,5.1c0,22.6,0.6,45.2-0.2,67.7
				c-0.9,21.8-2,43.7-2.3,65.5c-2.8-45.8,3-91.6,0.1-138.2c-4.5,2.5-3,6.2-3,9.1c-0.1,33.1-0.4,66.2,0.1,99.3
				c0.1,10.9-2.6,21.4-2.2,32.3c0.1,2.4-1.4,4.9-3,10c2.1-51.2,4.4-99.6,2.8-150.1c-4.2,4.4-3.2,7.9-3.2,10.9
				c-0.1,35.9,0.1,71.8-0.3,107.6c-0.1,9-0.5,18.3-3.5,27l0,0c2-45.1,2-90.2,1.8-135.3c0-2.5,0-5-0.1-7.4c-0.1-2.1-1-3.5-3.2-3.7
				c-1.7-0.2-3.1,0.3-3.7,2c-0.7,2-0.5,7.7-2.6,0.3c-1-3.6-3.7-1.5-4.3,0.5c-0.8,2.6-0.7,5.5-0.7,8.2c-0.1,32.2-0.4,64.4,0,96.5
				c0.2,13.6-3.2,26.9-2.6,40.4c-2.8-48.8,2.9-97.6-0.7-146.5c-3.9,9.1-1.9,18.9-2.3,28.4c-0.4,9.9-0.1,19.8-0.1,29.7
				c0,10.2,0,20.3-1.9,30.5c0-29.1,0-58.3,0-87.4c-0.9,0-1.8,0-2.8,0c0,36,0,72,0,107.9c-0.8,0-1.6,0-2.4,0c0-35.8,0-71.6,0-108.4
				c-5.4,2.9-3.8,7-3.8,10.1c-0.2,28.2-0.6,56.3,0,84.5c0.3,17.3-2.5,34.5-2,51.8c0.1,2.7-1.1,5.4-1.8,8.1c-0.8,3.2-2.9,4.8-6,4.3
				c-4.3-0.8-1.6-3.7-1.4-5.8c4.3-37.6,1.3-75.3,1.9-113c0.2-12.9,3.9-25.7,1.3-39.8c-3.6,8.1-4.8,15.8-4.8,23.7
				c0,34,0,68.1-0.1,102.1c0,2.9,0.9,6-1.8,10.4c0-45.8,0-89.8,0-135c-4.9,2.9-3.5,6.6-3.5,9.5c-0.1,34.9-0.5,69.7,0,104.6
				c0.2,12.7-2.5,25.2-2,37.9c0.1,1.8-0.1,3.7-2.4,4.1c0.9-52,1.9-104,2.9-156.6c-4.1,1.5-3.9,4.4-4,6.9c-0.2,5.3-0.2,10.5-0.1,15.8
				c1.2,41.7-2.8,83.4-1.8,125.1c0.1,3.8-0.7,7.3-2.1,9.5c0.5-51.3,0.2-103.5,1.5-155.8c-4.9,3.8-2.7,8.8-3.1,13.3
				c-0.4,4.9-0.1,9.8-2,14.7c0-26.9,0-53.8,0-80.8c0.8,0,1.5,0,2.3-0.1c0,13.1,0,26.3,0,39.4c5.3-7.9,2-16.6,5.6-24.6
				c0,8.8,0,17.7,0,26.5c5.1-5.4,2-11.8,5.2-17.3c0,4.5,0,8.9,0,13.4c0,2.5,0.2,5.9,3.1,5.8c3.6,0,2.3-3.7,2.3-5.9
				c0.1-21.7,0.1-43.3,0.1-65c0-1.8,0-3.6,1.8-5.5c0,24.8,0,49.6,0,74.4c1.2,0,2.4,0,3.7,0c-1.5-45.4,3-90.7,1.6-137
				c-4.5,3.7-3.4,7.9-3.6,11.8c-0.2,3.7,0,7.3-1.7,11c0-4.4,0.8-9-0.3-13.1c-0.7-2.9,4.8-11.3-5.5-8.3c-1.4,0.4-1.5-1.7-1.4-2.8
				c0-1.7,0.9-3,2.7-3.1c3.3-0.1,7.1-1.2,10,0c9.4,4,19.9,1.8,29.1,6.1c3.2,1.5,6.6,0.8,9.9,1c4.2,0.2,7.4-0.1,5.9-6
				c-0.7-3,2-6.5-0.7-9.7c-4.5,1.3-0.4,6.5-3.6,8.3c0-13.8,0-27.5,0-41.7c4.4,1.1,1.9,5.1,4.3,7c0.4-2.1,0.8-4.1,1.3-6.4
				c2.4,2.8-0.3,6.6,3.2,9c2.8-3,0.7-7.1,2-10.5c3.1,3.4,5.1,4,5.1-1.8c-0.1-13.6,0-27.1,0-42.1c3.6,5.4-0.1,10.8,4.9,16.8
				c0-17.6,0-33.3,0-48.9c0.7,0,1.4,0,2.1,0c0,12.2,0,24.4,0,37.3c9.1-10.2,9-22.1,7.4-33.2c-2-14.3,4.1-23,14.9-30
				c3.6-2.3,12.8,2.8,14.2,8.7c2.7,11.4,3.5,23,4.2,34.7c0.1,2.6-1.3,7.3,1.8,7.2c5.1-0.3,9.8,6.1,15.2,0.8c2.1-2,2.8-3.4,1.3-6.2
				c-2.8-5.3-4-11.2-6.8-16.7c-4.5-8.9,6.7-21.8,19.4-23.5c0.6,15.6-1,31.4,2.9,47.4c4.7-6.7,1.2-14.3,4.6-20.8
				c0,17.9,0,35.8,0,53.7c0,2.1-1.2,2.1-3.3,2.5c-10.1,2-20.2,1.2-30.3,1.1c-5.5-0.1-10,2-14.9,3.8c-2.6,1-4.3,3.2-3.9,5.6
				c3,20.8-1.3,41.9,1.7,62.4c1.8,12.5,3,24.9,3.1,37.5c0.1,10.7-3.8,21.3-0.1,32c0.7,2-0.1,4.2-1.5,6.1c-4.8,6.4-6.9,12.1,3.7,14.5
				c1,0.2,2.5,1.5,2.6,2.4c1.5,13.5,12.2,24,11.9,38.1c-0.1,3.8-0.4,8.2,5.3,7.3c5.7-0.9,3.9-5.3,3-8.7c-1.7-6.2-1.7-12.3,0.8-18.1
				c3.4-7.9,10-0.2,14.6-2.7l0.1,0.1c-0.4,2-0.9,4.1-1.9,8.7c11.5-9.7,22-16.9,29.8-27.5c1.1-1.5,1.2-4.1,1.2-6.2
				c0.7-32.1-1.1-64.4,2.5-96.2c5.5-48.7,4.1-97.4,5.7-146.1c2.4,43.4-1.1,87,3.9,131.9c3.5-3.9,2.5-7.4,2.5-10.4
				c0.1-36.2,0.1-72.3,0.2-108.5c0-6.5,0.8-12.7,3.7-18.6l0,0c0.6,45.4-1.3,90.8,1.5,136.1c1.2,0,2.4,0,3.5,0c0-41.6,0-83.2,0-124.9
				c0.5,0,1,0,1.6,0c0.2,0.9,0.6,1.7,0.6,2.6c-0.3,39.2,3.1,78.4,2,117.6c-0.3,11.2,0.6,12.2,12,10.7c4.2-0.6,5-0.1,4.9,4.5
				c-0.4,40.2-0.4,80.4-0.4,120.6c0,2.1,0.1,4.3,0.7,6.4c0.2,0.9,1.6,1.9,2.6,2c1.5,0.2,1.8-1.3,1.9-2.5c0.1-1.2,0.1-2.5,0.1-3.7
				c-0.1-40.8,0-81.6-0.4-122.5c0-4.3,0.9-5.7,4.7-5.8c0.6,0,1.2,0,1.8,0c23.3-1.3,23.3-1.3,23.3,22.6c0,33.7,0,67.4,0,101.1
				c0,2.5,0.1,4.9,0.3,7.4c0.1,1.3,1.2,2.2,2.5,2.1c1.3-0.1,2.2-1,2.3-2.4c0.2-2.5,0.3-4.9,0.3-7.4c0-38.7,0.2-77.3-0.1-116
				c-0.1-7.3,1.7-9.6,9.1-9.5c20.4,0.3,20.4-0.1,20.4,20.1c0,35.9,0,71.8,0,107.6c0,4.3-0.2,8.7,2.1,12.6c0.6,1,1.3,2.4,1.1,3.4
				c-7.4,35.4-1.5,71.2-3.2,106.8c-0.7,16.4-0.1,32.8-0.2,49.2c0,5.8,1.6,10.3,7.4,12.3l0-0.1c0,4.5-1.4,9.7,6,9.5
				c2.1-0.1,3.1,1.6,3.2,3.7c0.1,3.2,0.4,6.3,4.4,6.9c4.4,0.6,6.2-2.9,8.1-5.9c0.6-0.9,0.6-2.1,1.2-4.8c2.2,13.6,3.4,14.6,12,10.8
				c9.8,2.3,19.5,3.8,29.3,0.1c0.6,0.1,1.1,0.2,1.6,0.3c0.1,0.5,0.2,1.1,0.1,1.6c-3.4,3.4,1,3.5,2,5.1c1.1,1.7,5.2,2.4,3.8,4.3
				c-4.5,6.2,3.3,10.3,1.7,15.9c2.2-2.7,1.2-7.8,5.2-8C2819.4,2091.1,2826.5,2085.5,2835,2085.3z M2772.3,2107.4
				c-4.4,0.5-8.6,1.5-12.7,5.4c5.7,0.6,9.8-1.2,13.4-4.5c1.3-0.4,2.6-0.8,3.9-1.3c-0.3-0.4-0.5-0.9-0.9-1
				C2774.5,2105.6,2773.3,2106.2,2772.3,2107.4z M2659.1,2052.1c0.5,1,1,2,1.5,3c0.4-0.3,0.8-0.5,1.1-0.8c-0.8-0.8-1.6-1.6-2.4-2.4
				c0-23.1-0.4-46.3,0-69.4c0.5-27.7-1.8-55.5,1.5-83.1c0.5-3.9,1.1-8.4-3.1-11.4c-3.2,3.2-2,6.8-2,10c-0.1,29-0.1,58-0.2,87
				c0,8-0.7,16-1.2,23.9C2653.5,2023.4,2651.6,2038.4,2659.1,2052.1z M2715.6,2052.3c-7-15.7-3.9-32.4-2.9-48.3
				c2.2-36.5,0.3-72.9,1.2-109.3c0.1-2.2,0.8-5.7-3-5.3c-2.9,0.3-2.3,3.2-2.3,5.3c0.6,49.4-3.1,98.8-1.7,148.2
				c0.2,5.7,2.2,10.9,5,15.7c2.6,4.5,7.1,2.9,10.7,2.4c3-0.4,2.7-3.3,1.4-5.1c-4.5-6.1-2.8-12.8-2.8-19.5
				c0.1-46.1,0.2-92.1,0.1-138.2c0-2.8,1.3-6.1-2.9-9.4c-2.7,55.1-3,109.1-2.4,163.1L2715.6,2052.3z M2612.4,2048.5
				c-4.8-4.8-4.5-10-4.5-15.1c0-31.2,0-62.5,0-93.7c0-3,1-6.5-3.2-11.3c0,21.2-2.8,40-2.1,59c0.7,19-1.2,38,1.4,57
				c-0.9,0-1.7,0-2.6,0.1c0-51.3,0-102.6,0-153.9c-1,0-2.1,0-3.1-0.1c-2.9,25.2-0.6,50.7-2.5,76c-1.9,25.3-0.4,51-0.4,76.4
				c-0.7,0-1.4,0-2,0.1c0-44.2,0-88.5,0-135.1c-4.6,4.9-3.4,8.2-3.4,11c-0.2,9.3,0.6,18.6-0.4,27.8c-3.1,29.3-1,58.7-1.2,88
				c-0.1,6.4,1.5,14.4,9.2,16.7c11.5,3.4,23.5,2.3,35.3,2.3c3.2,0,4.7-1.3,2.9-4.5c-3.8-6.9-2.4-14.2-2.3-21.5
				c0.3-43.9,0.2-87.9,0.2-131.8c0-2.2,0.9-4.9-4-7.5c3.1,55.1-5,108.7,0.6,164.5c-5-8.5-5.8-15.9-5.8-23.5c0-43.6,0.1-87.2,0-130.9
				c0-3,1.1-6.5-3-10.4c-0.9,52.5-3.4,103.4-2.1,154.3c-1,0-2,0-3,0c0-42.6,0-85.2,0-127.9C2611,1959,2610.9,2003.2,2612.4,2048.5z
				 M2681.9,1886.9c0,56.7,0,112.2,0,168.9c-7.7-8.9-4.7-18-4.6-27c0.2-43.6,0.2-87.2,0.2-130.8c0-2.8,0.9-6-1.9-9
				c-2.8,3.6-1.8,7.1-1.8,10.2c-0.1,23.2,0.3,46.4-0.3,69.6c-0.8,29.5-5.5,59,3.7,88c1.4,4.2-0.8,14,7.1,3.3
				c0.6-0.9,2.7-0.9,3.2,0.4c4.2,9.9,4.6-3.3,8.7-1.6c3.3,1.3,1.5-3.8,1-5.5c-2.6-8.2-1.6-16.6-1.6-24.8c-0.1-44.2,0-88.5,0-132.7
				c0-2.9,0.9-7.7-2.7-7.9c-4.6-0.2-2.5,4.7-2.6,7.3c-0.7,38.3,0.8,76.6-1.6,114.9c-0.9,14.7-2,29.6,4.2,46.3
				c-5.8-4.7-5.9-8.6-6.6-12.6c-2.1-12.6-0.5-25.1-0.2-37.7c0.9-36.2,0.4-72.4,0.4-108.6C2686.5,1894,2686.9,1890.2,2681.9,1886.9z
				 M2458.6,1880c0.5,1.4,0.8,2.3,1,3.1c0.7,2.4-1.8,6.4,3.9,6.2c5.8-0.2,3.3-4.2,3.4-6.5c0.7-16.9,0.3-33.8,1.5-50.8
				c1.5-21.5,0.5-43.2,0.4-64.9c0-2.8,1.5-7-2.6-7.9c-3.8-0.9-8.6-2.4-10.8,3.5c-2.1-2.3-0.6-6.4-6.4-8.8
				c-0.8,46.4,0.7,91.9-0.7,137.3c2.6-17.1,3.7-34.3,4.1-51.5c0.4-17.5,0.1-34.9,0.1-52.4c0.6,0,1.2,0,1.9,0c0,33.8,0,67.6,0,102
				C2457.8,1886.5,2454.6,1882.4,2458.6,1880z M2481.9,1862.1c3.7-1.5,3.4-3.2,3.4-4.6c1-29-1.6-58,1.4-86.9
				c0.5-4.7-2.1-10.3-7.7-10.9c-5.3-0.6-6.8,4.1-7.4,8.6c-0.3,2.4-0.7,4.9-0.7,7.3c-0.1,35.5-0.1,71-0.1,106.6
				c0,2.4-1.1,5.4,2.5,7.9c2.6-8.1,0-16,2.2-25.3c1.8,9.3-0.9,17.3,4.2,24.5c0-29.2,0-58.4,0-87.6c0.7,0,1.5,0,2.2,0
				C2481.9,1821.5,2481.9,1841.5,2481.9,1862.1z M2699.7,1887.7c-1.2,23.1-0.2,45.5-1.6,67.9c-1.6,26.7-0.3,53.7-0.4,80.5
				c0,6.9,2.4,13.3,3.7,19.9c0.3,1.6,0.8,3.2,2.7,2.9c2.9-0.4,1.5-2.4,1.4-4.2c-0.8-11.3-1.5-22.4-1.2-33.8
				c1.1-41,0.5-82.1,0.5-123.1C2704.8,1894.2,2705.3,1890.3,2699.7,1887.7z M2666.8,1887.7c-2.9,5.5-2,8.9-2.1,12.2
				c-0.2,20.1,0.3,40.2-0.4,60.2c-1,30.7-4.1,61.4,2,92c1.4,7,3.9,5.1,9.7,5.6c-7.3-6.2-7.8-12.4-7.8-19.2
				c0.2-30.6,0.1-61.2,0.1-91.8c0-3.7,0.8-7.4,1-11C2670,1920.5,2672.1,1904.9,2666.8,1887.7z M2641.1,1889.9
				c-5.3,49.3-3.4,98.5-2.3,147.8C2644.6,1988.5,2641.8,1939.2,2641.1,1889.9z M2714,1809.6c0-20.4,0-40.7,0-61.1
				c0-2.3,0.8-5.8-2.6-5.9c-3.5-0.1-2.8,3.4-2.8,5.6c0,40.4,0,80.8,0,121.3c0,2.7-0.4,6.8,2.9,6.8c4.1,0.1,2.4-4.2,2.4-6.6
				C2714,1849.7,2714,1829.7,2714,1809.6z M2701.6,1877.3c2.9-4.8,3.3-9.2,3.3-13.9c-0.1-37.3,0-74.6,0-111.9c0-1.8,0-3.7-0.1-5.5
				c-0.1-1.6-0.7-3.2-2.5-3.3c-1.8-0.1-2.6,1.3-2.7,3c-0.1,2.1-0.2,4.3-0.2,6.5c0,38.5,0,77.1,0.1,115.6
				C2699.5,1870.5,2698.4,1873.8,2701.6,1877.3z M2686.6,1811.6C2686.6,1811.6,2686.6,1811.6,2686.6,1811.6c0-20.4,0.1-40.8,0-61.3
				c0-2.3,0.7-5.8-2.7-5.8c-3.4,0-2.6,3.6-2.6,5.9c-0.1,12.7,0,25.4,0,38.1c0,26.6,0,53.2,0.1,79.8c0,3-1.4,8.2,3.4,7.9
				c3.6-0.3,1.8-5.2,1.8-8C2686.6,1849.3,2686.6,1830.4,2686.6,1811.6z M2718.1,1742.5c-0.2,2.3-0.5,3.5-0.5,4.7
				c0,40.7,0,81.5,0,122.2c0,1.2,0,2.5,0.1,3.7c0.1,1.5,0.2,3.3,2.2,3.4c1.7,0,2.6-1.5,2.9-3c0.3-1.8,0.3-3.7,0.3-5.5
				c0-37.7,0-75.3,0.1-113C2723.2,1750.7,2723,1746.5,2718.1,1742.5z M2651.8,1812.5C2651.8,1812.5,2651.8,1812.5,2651.8,1812.5
				c0-19.5,0.1-38.9,0-58.4c0-2.6,0.8-6.9-2.6-6.9c-3.4,0-2.6,4.2-2.6,6.9c-0.1,38.6-0.1,77.3,0,115.9c0,2.5-1.3,6.7,2.6,6.7
				c3.9,0,2.6-4.2,2.6-6.7C2651.8,1850.8,2651.8,1831.7,2651.8,1812.5z M2667,1877.1c1.7-5.7,3-10.9,3-16.3c0-35.6,0-71.1-0.1-106.7
				c0-2.8,1.6-7.6-2.6-7.6c-4.3-0.1-2.7,4.8-2.7,7.5c-0.1,38.3-0.1,76.7,0,115C2664.8,1871.5,2663.8,1874.4,2667,1877.1z
				 M2676.8,1878.6c1.5-12.4,1.7-22.9,2-32.9c1.1-31.2,0.4-62.4,0.4-93.6c0-2.9,0.7-7.3-3.5-7c-3.3,0.3-1.9,4.6-1.9,7.1
				c-0.1,38.9,0,77.8,0,116.7C2673.8,1871.5,2673.2,1874,2676.8,1878.6z M2445.2,2053.1c4.9-9.4,8-144.5,2-153.4
				C2446.8,1952,2444.2,2002.9,2445.2,2053.1z M2649.2,1889.9c-4.8,17.2-3.2,145.6,0,152.3
				C2649.2,1991.7,2649.2,1940.8,2649.2,1889.9z M2588.6,2050.1c-5.5-15.3-4.4-29.4-4.4-43.5c0-30.3,0-60.6-0.1-91
				c0-3,1-6.5-1.9-9.8c-0.7,1.5-1.4,2.3-1.4,3c-0.1,43-0.1,86-0.1,129C2580.7,2042.5,2582.4,2046.6,2588.6,2050.1z M2641.9,1747.1
				c-0.6,0-1.2,0-1.8,0c0,43,0,86,0,129.1c0.6,0,1.2,0,1.8,0C2641.9,1833.1,2641.9,1790.1,2641.9,1747.1z M2557,2087.3
				c0.1,0.6,0.1,1.1,0.2,1.7c12.6,0,25.1,0,37.7,0c0-0.6,0-1.1,0-1.7C2582.3,2087.3,2569.7,2087.3,2557,2087.3z M2542,2090.2
				c-10.2-1.4-20-0.7-29.9-2.4c0,0.8,0,1.6-0.1,2.4C2522.1,2090.2,2532,2090.2,2542,2090.2z M2508.4,1918.5c-0.3,0-0.6,0-0.9,0
				c0,7.6,0,15.3,0,22.9c0.3,0,0.6,0,0.9,0C2508.4,1933.7,2508.4,1926.1,2508.4,1918.5z M2367.5,2120.4c1.5-0.1,2.3-1.1,1.8-2.3
				c-0.4-0.9-1.5-2-2.5-2.1c-1.6-0.3-1.8,1.2-1.8,2.5C2365.1,2119.8,2366.2,2120.3,2367.5,2120.4z M2757.1,2113.9
				c-0.6-0.1-1.1-0.4-1.5-0.3c-0.8,0.2-0.9,0.7-0.1,1.1c0.2,0.1,0.6,0.2,0.8,0.1C2756.5,2114.6,2756.8,2114.2,2757.1,2113.9z
				 M2378.2,2116.1c-0.5,0-0.9-0.1-1.4-0.1c0.2,0.2,0.4,0.5,0.6,0.5C2377.7,2116.5,2377.9,2116.3,2378.2,2116.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2403.2,1998c-2.5,12.5-1.9,25.2-1.9,37.8c0,4.8-1.2,9.2-3.3,13.3
				c-3.8,7.5-11.1,8.6-17.4,2.8c-4.5-4.2-16.5-5.2-21.8-1.5c-3.3,2.3-5.4,0-7.1-1.7c-4.2-4.2-6.8-9.8-6.5-15.7
				c2.4-45.3,0.5-90.7,1-136c0-3.1,1.6-7.1-3.2-8.5c0-1.7,0-3.4,0-5.2c6.4-0.1,5-4.9,5-8.7c0.1-24.1,0.1-48.2,0.1-72.4
				c0-3,0.3-5.9,2.4-8.8c0,31.9,0,63.9,0,95.8c0.9,0,1.7,0,2.6,0c0-20,0-40,0-59.9c0.8,0,1.6,0,2.4,0c0,19.8,0,39.6,0,59.4
				c1.1,0,2.3,0,3.4,0c-0.7-43.6,1.4-87,1.9-130.6c-0.9-0.1-1.9-0.2-2.8-0.3c0,9.4,0,18.8,0,28.2c-4.4-9-0.9-18.7-5-30.1
				c-3,11.8,0.2,21.9-3.6,31c0.4-13.4-6.3-27.6,4.3-39.9c1.9-2.2,1.4-4.6,1.6-7c1.5-27.1,2.1-54.3,2-81.4c0-7.5,1.3-15.1,2.8-22.6
				c0.8,31.4-2.9,62.8-0.5,95.8c4.2-29.7,1.3-58.4,5.7-86.5c3.1,33-1.5,65.9-0.6,99.2c3.7-0.6,3.4-2.5,3.4-3.8
				c-0.9-24.7,1.5-49.3,2-74c0.4-19.4,0.3-38.9,2.2-58.2c0.9-9.9,11-13.5,18.9-17.5c6.2-3.1,12.5-0.7,17,2.8
				c4.4,3.4,2.5,10.1,1.6,14.9c-2.3,12.3-1.1,24.6-1.1,36.9c-0.4,20.8,0.6,41.6-1.6,62.3c-0.1,13.8-0.2,27.7-0.3,41.5
				c0.5,38.9-3.1,77.8-2,116.7c-1.8-2.4-3.7-4.7-5.5-7.2c-2-2.8-4.9-2.6-7.3-1.6c-5.9,2.5-8.2,11.5-6.1,19.1
				c2.4,8.4,7.8,6.7,13.6,5.2c1.7-0.4,3.5-0.5,5.3-0.8c0.8,10.1-2.4,20.2-0.2,30.3c-1.8,2.2-1.9,4.7-1.4,7.3
				C2403.3,1945.2,2403.3,1971.6,2403.2,1998z M2348.2,2033.9c1.3,0,2.6,0,3.9,0c0-44.3,0-88.7,0-133
				C2346,1945.2,2349.3,1989.6,2348.2,2033.9z M2355.5,2019.1c0.7-39.9,5.8-79.8,1.6-119.8
				C2355.3,1939.2,2356.2,1979.2,2355.5,2019.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2461.2,1195.3c-2.5,2.6-1.5,5.8-1.5,8.8c-0.1,18.6,0.8,37.2-0.3,55.7
				c-2.7,46.9-0.7,93.8-1.9,140.7c-0.4,14.6-3,29.2-1.1,43.9c-0.1,16.5-0.1,33-0.2,49.5c0,3.3,0,6.5,0,9.8c0,30.1,0,60.2,0,90.3
				c-0.1,0.7-0.2,1.5-0.3,2.2c-3,0.5-6,1.1-9,1.6c-0.1-6.7-5.3-6.3-9.6-7.5c-5.9-1.7-10.3-3-9.3-12.3c4.3-41.2,1.7-82.7,2.2-124.1
				c0-1.1,0-2.5-2.3-3.1c-2,2.8-1.1,6.2-1.1,9.3c-0.1,33.4,0,66.8-0.2,100.2c0,9.2-1.4,18.2-4.7,29.5c0.6-48.8,3.5-94.7,2.7-141.1
				c-4.4,0.9-3.2,3.5-3.3,5.4c-1.9,35.8-2,71.6-2,107.4c0,8.1-1.5,16.2-3.6,24.2c2.6-46.1,2-92.1,1.8-138.4
				c-4.6,1.1-3.5,4.4-3.4,6.8c1,38.3-3.1,76.5-2,114.9c0.1,3.1,1.4,6.7-1.9,9.9c0.3-43.7-0.4-86.8,0.6-130
				c-5.9,41.4-3.4,83.1-5.1,124.6c-0.3-57.9-2.2-115.9,3.1-173.8c0,11.9,0,23.8,0,35.9c4-0.8,3.4-3.1,3.3-4.6
				c-1.2-27.5,2.7-54.9,1.9-82.4c-0.5-17,3.4-33.7,2.4-50.7c3.4,33.4-2.8,66.8-0.1,100.1c3.5,0.3,3-2,3-3.7
				c0.1-31.5,2.9-62.9,2.2-94.5c-0.1-6.2-3-8.6-8.1-11.1c-3.1,7-0.1,14.5-2,22.2c-5.8-8.1-1.2-15.8-0.2-23.3
				c0.1-0.6,2.1-1.6,3.1-1.4c10.5,2,9.4-4.4,9-11.6c-0.9-17.2,3.3-34.2,3.3-51.5c0,12.5,0,25,0,37.5c0,2.8-3.2,7,1.4,7.7
				c4.3,0.6,0.9-5.4,3.9-7.3c4,0.1-1.6,7.2,3.5,5.8c4.1-1.1,2.3-5.2,2.3-8c0.2-10.9,0.1-21.9,1.1-32.9c4.2,3.4,1.2,8.7,4.2,13.4
				c4-7.3,0.6-14.8,3.8-21.6c0,8.1,0,16.2,0,26.4c5.4-9.2,4.4-26.7-0.2-33c-3-4.1-1.7-8.6-0.8-13.4c4.5,1.2,1.4,6,4.8,7.5
				c3.6-3.2,0.2-8.2,3.7-13.8c0,8.3,0,14.6,0,20.9c4.5-3.8,2.9-8.4,3.5-12.7c0.4-3-1.7-7.2,3.4-8.2
				C2461.2,1192.9,2461.2,1194.1,2461.2,1195.3z M2416.1,1415.7c2.1-3.8,1.9-6.3,0.7-9.1C2415.1,1408.9,2415.9,1411.4,2416.1,1415.7
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2763.3,2057.6c-1.3-0.6-5.2,0.5-3.6-1.9c7.9-11.9,7-25.6,7.1-38.4
				c0.5-130.2,0.2-260.5,0.5-390.7c0-5-3.9-11,1.5-16.4c1.4,41.6-1.9,82.5,2.4,123.1c1,0,2,0,3,0c0-29.9,0-59.7,0-89.6
				c0.7,0,1.5,0,2.2,0.1c0,14.7,0.1,29.4,0,44.2c-0.6,65.6,2.8,131.1,1.8,196.7c-0.4,26.8,1.1,53.7-0.7,80.3
				c-1,13.9-1.7,28.1-2.6,41.9c-0.7,11.2-0.1,23.7,1.7,35.4c0.8,5.5,8.5,8.4,13.3,5.4c0.9-0.6,1.9-1.7,2.1-2.7
				c0.3-2.2-1.3-3.5-3.2-3.7c-9.7-1-11-9-10.3-15.7c3.1-28.9,0.1-57.9,3.1-86.8c1.5-14.6,0.3-29.5,1.2-44.3
				c0,22.1,1.3,44.2-0.2,66.4c-1.5,21.8-2.1,43.8,1.2,65.6c0.3,2.2,1.4,3.3,3.5,3.5c0.6,0.5,1.3,1,2,1.4c0,0,0.3-0.4,0.5-0.7
				c-0.8-0.3-1.6-0.6-2.4-0.9c-1.6-4.7-2-9.6-2-14.6c0.1-28.1-1-56.3,0.4-84.4c2.7-53.4,0.8-106.9,1.4-160.3
				c0.4-40.4,0.1-80.9,0-121.3c0-6.4-1.6-12.7,0.3-19.1c0.7-2.4,1.3-4.8,3.4-6.3c1.7-1.2,6,0.2,4.4-3.8c-1.2-3.1-4.5-2.5-7.2-1.7
				c-1.5,0.4-2.9,0.8-4.4,1.3l-0.1-0.1c-0.4-4.6,2-6,6-5.5c3.7,0.4,7.3,0,10.3-3.5c0,5.4,0.1,10.7,0,16.1c-0.1,4.1,1,6.6,5.6,6l0,0
				c-0.1,1.6,0.2,3,1.8,3.7l0,0c0,82.8,2,165.7-1,248.3c-1.5,43.4-1.6,86.7-2.3,130.1c-0.1,5.8-7.1,9.1-3.9,15.3
				c-4,7.3-1.1,15.1-1.5,22.6c-0.1,0.5-0.3,0.9-0.4,1.4c-0.6,0.6-1.3,1.1-1.9,1.7C2785.2,2055.1,2773.9,2051.1,2763.3,2057.6z
				 M2771.3,1739.1c-0.4,3.5-1.1,5.9,0.8,9.5C2772.4,1745,2773.1,1742.6,2771.3,1739.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2234.8,1392.1c-2,0.5-4,1.2-6,1.4c-2.3,0.3-3.2-1.3-3.1-3.3
				c0.1-1.7-1.2-4.9,2.7-4c4.2,1,4.8-2.4,6.4-4.8c0.5,0.2,1,0.3,1.5,0.4c0.5,0.6,1,1.3,1.4,1.9c0.3-0.8,0.6-1.6,0.9-2.4
				c3.5-2.4,3.8-5.8,2.9-9.5c6.3-5.1,12.5-3.1,18.7,0.1c-3.2,0.6-6.4,1.2-10.2,1.9c2.9,1.8,3.3,4.4,3.5,6.9c-1.7,0.3-3.7-0.1-5.5,2
				c4.3,3.3,13-0.5,13.6,8.4c0,0.6,2.1,0.8,3.1-0.3c3.3-3.5,6.2-16.9,4.6-22.3c4.7-1.4,9.4-0.7,14.2-0.5c0.1,1.9,0.2,3.7,0.4,5.6
				c0.1,1.8,0.2,3.6,0.3,5.5c-0.2,10.9,1.5,11.9,10.8,6.4c1.7-1,4.5,0.2,5.3-2.6c0.1-2.6,0.2-5.2,0.3-7.8c0.6,0,1.2,0.2,1.7,0.4
				c0.2,3.4,0,8.1,4.2,7.9c3.5-0.1,6.6-4.1,7.5-7.7c0.6-2.3,0.2-4.1,3-4.2c0.4,0.6,0.9,1.3,1.3,1.9c0.2-0.2,0.4-0.4,0.5-0.6
				c2.6-8.5,3.1-16.9-1.8-24.7c-3.4-5.4-3.6-10.4,1.6-14.2c3.9-2.9,4.3-6.3,3.4-10.4c10.6-3.9,11.5-3.3,11.5,7.9
				c0,11.1-0.1,22.2,0,33.3c0,2.9-1,6.2,1.6,8.7c1.4,8.8-2,17.3-1.7,26.1c-3.4,12.9-2.4,25.8-0.3,38.7c-0.1,7,2,14.1-1.6,20.8
				c0.5-10.9-9-4.2-12.8-7.5c0-14.1,0.2-28.2-0.1-42.3c-0.1-4.1,2.5-11.7-2.2-11.8c-5.4-0.2-6,7.5-6.5,12.7
				c-0.4,5.2-0.1,10.5-0.2,15.8c-0.1,7.6,0.6,15.3-3.5,23.9c-0.3-5.3-0.6-9.2-0.8-13c-3.5,0.9-1.5,6.2-5.3,6.6c-2.2-1.2-2-3.5-1.5-5
				c2.3-6.9-1.4-12.2-3.9-17.9c-0.8-1.8-2-2.4-3.5-2c-1.7,0.4-4.6,2.4-4.1,2.7c4.1,3.1-1.6,10.1,2.7,10.6c4,0.5,2.7,2.4,3.6,4.2
				c5.1,10.3-11.9,16.8-4.4,27.4c0,0-0.4,0.4-0.7,0.5c-3.9,1.9-1.7,6.8-5.1,9.1c1.3,0.5,1.9,0.8,2.4,1c4.5,1.5,12.3-1,12.5,5.4
				c0.2,5.7-6.9,5.1-11.7,5.4c-1.5,0.1-3.1,0.3-3.6,2.1c-0.4,1.4,0.1,2.9,1.2,3.1c4.1,0.9,8.8,2.8,12.4,1.7c5.7-1.8-1.5-2.6-1.2-4.3
				c3.1-1.7,11.1,1.3,8.1-5.8c-2.7-6.4,1.8-13.5-3.6-19c-1.6-1.7,0.9-2.6,1.9-4c4.7-6,10.2-0.7,15.2-1.6c4.3,5.8,9.4,4.4,14.7,1.9
				c0.1,2.4,0.1,4.8,0.2,7.2c-5,0.8-11.1-2.3-14.5,4.1c-1.6-1.8-3-3.8-4.8-5.2c-1.2-0.9-3.3-2.4-4.4,0.1c-0.4,1,0.6,3.9,1,3.9
				c7.2-0.1,5,7,7.8,10.2c2.1,7.4,6.2,8.4,14.4,3.4c0.1,2.7,0.2,5.3,0.3,8c-9.9,0.2-19.8,0.3-29.7-1.5c0,0.9,0,1.8,0,2.8
				c9.9,0.6,19.7,1.3,29.6,1.9c0.6,3.3,1.1,6.5-0.5,9.6c-14.3-0.6-28.6,1.8-42.7-1.9c-4.5-1.2-4.7,1.1-5,4.9
				c-0.6,7.3,1.1,14.2,2.7,21.2c-2.4-0.7-5.3,0.9-7.2-1.8c3.4-6.1,0.9-12.6,1.8-19.7c-5.8,6.6-6.6,12.8-3.1,19.7
				c-1.2-0.1-2.3-0.3-3.5-0.4c-0.5-1.3-0.5-4-1.6-3.5c-2,1-0.9,3,0.5,4.4c-5,0.5-10.5,4.5-14.3-2.4c0.1-5.2,0.3-10.3,0.3-15.5
				c0-3.3,0-9.4-0.8-9.5c-9.4-1.7-9.3-7.5-7.3-14.8c0.2-0.9-0.8-2.9-2.7-3.1c-1.4-2.7,2.2-5.5-1-9.6c-1.9,6.6-5.4,11.6-4.2,17.9
				c-0.4,7.1-0.4,14.2-1.2,21.2c-0.6,5.2,1.5,7.8,7.6,8.9c-7.2,2-12.9,4.3-18.5,3c-6.1-1.4-11.9,2.1-17.8-0.2c0-1.4,0.2-2.9-1.5-3.7
				c2.1-2.4,5.9-3,5.4-8c-0.9-9.1,0.1-18.3,1.5-27.4c1.4,2.6,2.7,5.2,4.1,7.8c-0.7,5.5,2.4,9.1,6.1,12.5c-0.4,0.3-0.9,0.5-1.3,0.8
				c0.3,0.3,0.6,0.6,0.8,0.8c0.4-0.6,0.8-1.2,1.2-1.8c0.5-0.2,1-0.3,1.5-0.2c0.9,0.2,1.9,0.5,2.8,0.7c0.3,4.5-6.1,9.4,1,14.3
				c1-5.7,3.7-10.5,0.5-15.7c-0.2-1-0.4-1.9-0.6-2.9c2.1-3.2,2.4-6.9,2.5-10.5c0.2-0.2,0.6-0.4,0.6-0.5c-0.1-0.4-0.3-0.9-0.5-1.3
				c-0.1-2.5-0.2-4.9-0.3-7.4c3.5-4.1,1.9-8.3-2-9.5c-3-0.9-8.6-3.7-10.5,3.5c-0.7,2.6-0.9,7.7-6.3,6.5c-2.8-0.1-5.5-0.3-8.3-0.4
				c-5.6-6.7,3.3-4.7,4.6-7.4c0.7-1.4,6.8-0.4,5.5-2.4c-3.6-5.5,4.6-11.9-1.3-15.7c-4.7-3.1-3.6-6.3-2.8-10.2
				c2.8,2.6,8.6-4.1,9.9,3.4c0.1,0.6,2,0.9,3.8,1.7c0-2.4,0.8-4.9-0.1-5.9c-5.8-6.2-7-13.7-7.9-21.8c-0.7-5.7,3.5-17.2,9.2-19.6
				c0,1.3,0.1,2.5,0.1,3.8c-1.2,8.3,2.7,14.2,10.2,20.8c-1.4-7.8,3.3-15.1-5.6-18.9C2237.1,1402.1,2238.5,1397.1,2234.8,1392.1z
				 M2299.3,1407.1c6.9,0.7,6.9-3.6,6.5-8.8c-0.6-6.6-4.4-3.2-7.1-2.7c-5.8,1-11.4,1.5-16.2-3.5c1.6,5.2-3.8,11.5,3.4,14.9
				C2290.2,1417,2294.8,1409.9,2299.3,1407.1z M2252.3,1411.5c1-5.4,1.9-9.4,2.5-13.5c0.3-2.3-0.1-4.3-3.1-4.3
				c-2.3,0-4.3,0.6-4.2,3.4C2247.5,1401.7,2246.6,1406.7,2252.3,1411.5z M2279.6,1456.8c-2-6.3-5.8-7.8-11.1-6.7
				c-2.3,0.5-3.2,2.6-2.3,4.1C2269.5,1459.6,2274.6,1456.1,2279.6,1456.8z M2277.3,1498.9c-5,1.9-7.8-2.1-11.8,0.5
				c2.8,3.8,2.3,9.2,6.4,12.5C2274.9,1508.6,2274.6,1504.3,2277.3,1498.9z M2219.9,1503.3c0,3.9-0.1,7.8,0,11.7
				c0.1,1.8,1.4,2.8,3,2.8c2.5,0,1.5-2.4,1.7-3.4C2225.3,1510.4,2223,1507,2219.9,1503.3z M2270.9,1490.6c1.7-0.5,4.9,1.2,4.6-2.3
				c-0.3-3.5-3.1-4-6.1-3.7c-1.9,0.2-5-0.5-4.8,2.5C2264.9,1490.8,2268.3,1490.3,2270.9,1490.6z M2280.3,1471.8c0-3-1.4-3.8-3.5-3.6
				c-2.5,0.1-5.1,0.5-5.2,3.8c0,2,1.4,3.6,3.5,3.3C2277.4,1474.8,2280,1474.4,2280.3,1471.8z M2222.7,1466.9
				c0.2-0.1,0.5-0.2,0.5-0.3c0.1-0.2,0-0.5-0.1-0.7c-0.3,0.2-0.5,0.3-0.8,0.4C2222.5,1466.5,2222.6,1466.7,2222.7,1466.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2835,2039c-3.3,0.5-2.5,5.3-5.9,5.9c-3.2,2.9-1.3,6.6-1.5,9.9
				c-0.2,2.6,0.3,5.3-3.9,5.3c0-5.1,0-10.2,0-15.3c1.1-6.4-2-8.2-7.7-7.7c-4.7,0.4-6.9-0.3-5.7-6.7c1.7-9,2.6-18.2,2.6-27.5
				c-0.1-95.3,0-190.6,0-285.9c0-3.3,0-6.7,1-10c-0.7,103.9,3.4,207.7,4.5,311.6c0-21-2-42.1,0.4-62.8c6.9-61.9,2.7-123.8,2.5-185.7
				c-0.1-38.3-0.3-76.6-1.2-114.9c-0.2-7.1,0.3-13.8,5-19.1c-1.6-1.7-2.7-1.4-3.9-1.1c-1.5,0.4-2.9,3.4-4.3,0.6
				c-1-2-0.3-4.5,2.5-4.9c3.1-0.4,6.3-0.1,6.3-4.7c0-0.9,1-1.8,1.8-0.9c1.9,1.9,6.1,3.2,5.2,5.9c-3.1,8.4,2.8,14.7,3.7,22
				c1.5,11.4,1.1,23.3-0.1,34.8c-1.3,12.1-1.8,23.9,0.2,35.8c1.8,10.9-0.8,21.9,0.2,33c6.9,76.5,2.4,153.1,3.6,229.7
				c0.1,8.6-1.7,16.7-4.5,24.9c-2.7,8-1.9,17.1,0.3,25.6C2835.6,2037.6,2835.3,2038.3,2835,2039z M2821.5,1999.5
				c1,7.6-1.6,16.5,6.1,21.9C2822.9,2014.9,2824.5,2006.5,2821.5,1999.5z M2829.3,2028.8c-0.9-0.6-1.9-1.7-3.2-2.1
				c-1.9-0.6-4.4-1.4-5.5,1.3c-0.7,1.7,0.2,3.1,2,3.4C2824.7,2031.6,2826.9,2032.3,2829.3,2028.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2732.3,2052c-0.6-5.9-1.3-11.8-1.7-17.8c-0.2-2.5-0.8-5.7,3.2-6.2
				c0.1,1.8,0.3,3.6,0.4,5.4c-0.4,0.7-0.8,1.5-1.2,2.2c0.5,0.2,1.1,0.8,1.4,0.6c1.4-0.6,1.3-1.9,1.1-3.1c0.1-32.6,0.1-65.2,0.2-97.8
				c0.1-93.4,0.2-186.9,0.2-280.3c0-14.5,0-29.1,0-43.6c0-3.2,0.1-6.3,2.1-8.8c1.6-2,4.8-2.4,4.3,0.7c-1,6.3,2.9,4.8,5.9,5
				c6,0.5,10.1,3.6,10.4,9.6c0.5,9.8,2.8,19.5,1.3,29.4c-0.4,2.4-0.4,4.9-0.3,7.4c0.7,33.4,0.8,66.8,0.6,100.1
				c-0.3,43.6,1.3,87.2-0.9,130.7c-2.2,43.9-0.4,87.7-1.4,131.6c-0.1,3.4-1.3,6.9-0.6,10.1c1.3,6.6-1.7,8.4-7.4,8.3
				c-2.3,0-5.9-0.8-6.1,2.4c-0.6,10.3-4.9,4.8-8.5,1.6c-3.2,8.2,1.2,10.7,8,11.9C2739.1,2055.8,2735.7,2053.6,2732.3,2052
				C2732.3,2052,2732.3,2052,2732.3,2052z M2753.5,1760.3c-0.6,0-1.2,0.1-1.8,0.1c0,41.6,0,83.2,0,124.9c0.6,0,1.2,0,1.8,0
				C2753.5,1843.7,2753.5,1802,2753.5,1760.3z M2754.2,1893.7c-1,0-2,0-3,0c0,43.9,0,87.8,0,131.7
				C2755.9,1981.5,2753.5,1937.6,2754.2,1893.7z M2742.7,1745.8c0.5,0,0.9,0,1.4,0c0-37.9,0-75.9,0-113.8c-0.5,0-0.9,0-1.4,0
				C2742.7,1669.9,2742.7,1707.9,2742.7,1745.8z M2751.4,1746.2c4.6-15,3.2-99,0-106C2751.4,1675.4,2751.4,1710.8,2751.4,1746.2z
				 M2743.6,1875c0.3,0.1,0.6,0.2,0.9,0.3c0-37.4,0-74.9,0-112.3c-0.3,0-0.6,0-0.9,0C2743.6,1800.4,2743.6,1837.7,2743.6,1875z
				 M2751.9,1623.4c-1,3.2-2.6,5.5,0.8,7.8C2753.9,1628.7,2755.3,1626.5,2751.9,1623.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2392.4,1395.3c-1.9,3.6-0.7,7.4-0.6,11.1c0,0-0.2,0.2-0.2,0.2
				c-5.7,0-10.3,1.5-10.6,8.3c-0.4,8.1,2.4,14.1,10.8,16.1c0.4,22.8,0.5,45.7-1.6,68.5c0,1.4,0,2.7,0,4.1c-0.8,21.4,1.6,43-1.4,64.4
				c-0.8,4.2-1.7,8.5-5.2,11.4c-2.4,2-6.3,4.1-7.2-0.2c-1.8-8.9-5.8-5.6-10.1-2.9c-1.2-0.1-2.4-0.2-3.6-0.4c-2.2-5-8.7-9.8-0.5-15.2
				c0.6-0.4,0.5-2.3,0.5-3.6c0-27.8,0-55.5,0-84.6c-4.7,8.1-3.1,16-5.4,23.4c0-14,0.2-28.1-0.2-42.1c-0.1-3.3,3.3-9-4.9-9
				c-8,0.1-5.8,5.5-5.9,9.6c-0.2,19.8-0.1,39.6-0.2,59.4c0,1.5-0.2,3-1.7,4.4c0-24.9-0.1-49.8,0.1-74.7c0-4,5.4-7.9,10.3-7.9
				c2.4,0,2.1-1.6,2.3-3c0.8-6.3,1-12.4,7.2-17.1c4.2-3.2,1.5-10,2-15.1c0.2-1.6-0.4-4.2,2.6-3.3c0.5,0.2,0.7,1.6,1,2.4
				c-3.2,3.4-1.4,7.1,0,10.7c0-3.6,0-7.2,0-10.7c4.1,4.3,0.3,10.2,3.2,14.8c-0.4-13.7,1.8-27.4-0.8-41.2c-0.7-3.8-5.2-11.6,5.2-11.9
				c2.4,0,1.7-3.7,1.5-5.6c-1.4-11.7,6.1-16.8,14.8-21.1C2393.4,1354.7,2395,1375.1,2392.4,1395.3z M2361.2,1464.2
				c1.6-2.3,2-4.7,0-8.6C2361.2,1459.6,2361.2,1461.9,2361.2,1464.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2282.2,1666.8c13.4-3.2,26.8,1.5,40.1,0c2.1-0.2,4.6-0.2,6.1-1.4
				c7-5.6,14.2-8.4,23.6-7.5c4.1,0.4,5.9,2.9,9,3.8c-0.5,3.1-1,6.2-1.5,9.3c-3.9,3.5-11.8,6.7-1,11.5c1.1,0.5,1.6,1.6,1.2,3
				c-2.1,8.3,3,5.7,6.1,3.6c4-2.7,7.4-2,11.5-0.8c-2,5.1-8,1.1-9,5.9c-4.5,4.4-9.8,1.1-14.7,1.5c-5.1,0.4-11.4-1.4-15.2,0.9
				c-6.6,4.1-10.5,3.8-14-3.4c5.7-0.2,11.9,1.6,18-1.5c-8.9-13-22.7-15.2-36.4-17.7c-1.2-0.1-2.4-0.2-3.6-0.3
				c-1.6-4.1-5.7-3.8-8.9-4.9c-4.1-1.4-8.9,1.1-12.8-2l0.7,0L2282.2,1666.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1079.3,841.6c0-1.2,0.1-2.4,0.1-3.6c-0.1-19.8,2.2-39.7-1.7-59.3
				c8.3-4.8,6.3-16.3,15.6-22.1c-3.4,0-4.9,0-6.4,0c0-1.4,0-2.7,0-4.1c2.4-0.6,4.9-1.3,7.3-1.9c2.8,0.6,5.2-0.8,7.5-2
				c3.2,0,8.2,2,9-1.4c1.3-5.5,4.6-11,2.7-16.9c-0.7-2.2-3-1.3-4.7-0.7c0.9-3.1,4.7-5,3.6-8.9c0.2-0.5,0.2-1,0.1-1.5
				c0.7,0,1.4-0.1,2.1-0.1c4.8-1.1,9.6-2.2,14.4-3.3c0,0.7,0.1,1.4,0.1,2.1c-2.3,3.4-7.3,6.6-0.7,10.5c-5.2,8.3,5.6,17.7-2.3,26.5
				c-2.5,2.8-0.8,9.5-0.7,14.4c0.1,3.9-1.5,5.9-5.4,5.4c-5-0.5-1.8-2.7-1.1-4.8c0.9-2.5-1.2-4-3-5.1c-2.4-1.3-2.6,1-3.7,2.4
				c-4.6,5.9-11.1,9.1-18,11.3c0,0,0.1,0.1,0.1,0.1c1.7-7.7,8.6-10.6,13.9-14.8c1.2-1,2.7-1.6,2.1-3.4c-0.7-2.1-2.2-1.3-3.7-0.9
				c-7.9,1.8-13.7,4.6-18.1,13.3c-9.3,18.5-6,37.8-7,56.9C1081.1,833.5,1083.2,838.1,1079.3,841.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1034,1434.7c-2,16.8-2.4,33.7-1.6,50.5c-0.2,26.5-0.3,53-0.5,79.5
				c-3.5,10.5-1,21.3-1.3,32c-0.2,33.9-0.4,67.8-0.6,101.7c-5.8,16.2-12.2,20.5-27.5,18.5c-2.6-0.3-5-0.6-4.8-4
				c0.1-2.9,2.2-3.5,4.5-3.4c17.5,0.2,22.7-12.7,24.4-29.4c0.3-2.4,0-4.9,0.1-7.4c0.6-54.7,0.2-109.4,2.4-164
				c1.3-33.7,0.5-67.3,1.4-100.9c0.1-4.4-0.2-8.7,3.5-11.9C1034,1408.7,1034,1421.7,1034,1434.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2446.9,1597.8c3-0.5,6-1.1,9-1.6c4.6,1.3,3.2,7.6,7.6,9.1
				c0.5,1.1,1,2.3,1.5,3.4c-1.6,6.4-7.1,5.3-11.5,5.9c-5.2,0.7-10.8-1.8-15.7,1.8c-11.1-0.7-22.1-1.3-33.2-2c-2-0.7-4.3-0.9-5.9-2.2
				c-1.3-1-2.3-3.2-2.2-4.8c0.2-2.6,2.8-1.8,4.4-1.7c6.6,0.7,13.1-2.2,19.7-0.1c1.9,0.6,3.5-0.3,4.9-1.6
				C2431.8,1598.9,2439.6,1599.1,2446.9,1597.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2595.1,1614.1c2.6,9.6,0.4,19.3,6.4,28.4c0-9.4,0-18.7,0-28.1
				c3.3-0.1,2.7,1.4,2.7,2.2c0.2,24.4-0.2,48.7,1.8,73.1c0.2,2.8,1.7,8.1-3.9,7.1c-3.6-0.7-8.4-0.4-7.9-8.2
				c1.3-21.4,0.7-42.9,0.9-64.4C2595.1,1620.8,2595.1,1617.5,2595.1,1614.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1124.6,1650.8c-3.8-1.5-7-2-11.4,0.3c-6.2,3.2-5.6-4.1-6.5-7.3
				c-0.7-2.8,0.7-6.1,1.1-9.2c5.7-1.1,11.5,0.8,17.2-1.3c3.2-1.2,2.5,2.1,3.2,3.5c2.3,4.5,1.3,11,8,14
				C1132.3,1650.8,1128.5,1650.8,1124.6,1650.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2684.7,1528c-2.5,0.2-4.6,0.4-6.6,0.6c5.4,3.3,12,2.7,16.9,6.6
				c1.8,1.4,5.6,1.5,4.8,4.5c-0.9,3.4-4.6,2-7,1.8c-5.7-0.5-10-4.8-16-5.5c-6.7-0.7-12.7-5-19.8-5.6c1.3-3.7,6.9-0.9,6.1-4.4
				c-1.1-4.9-6.5-0.7-9.7-3.3C2664.5,1521.2,2674.6,1522.7,2684.7,1528z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2404.6,1614.6c11.1,0.7,22.1,1.3,33.2,2c15.2,1.7,30.5,3.4,45.7,5.1
				c-2.8,6.1-10.1,7.4-19.8,5.5c-10.9-2.1-22.1-4.8-33.3-1.2c-3.4-2.2-7.1-2.6-10.9-1.9l0,0c0-1.5-1-1.9-2.2-2
				C2414.5,1617,2406.4,1621.2,2404.6,1614.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2653.1,1737.1c0-45.9,0-91.8,0-137.7c0.5,0,1,0,1.6,0c0,45.9,0,91.8,0,137.7
				C2654.1,1737.1,2653.6,1737.1,2653.1,1737.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2725.2,1732.4c-0.7-49.7-7.2-99.4,0-148.9
				C2725.2,1633.1,2725.2,1682.8,2725.2,1732.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2687,1596.5c0.8,46.2,5.1,92.4,2.8,138.6c-0.9,0-1.8,0-2.8,0.1
				C2687,1689,2687,1642.7,2687,1596.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2695.7,1734.5c0-45.6,0-91.3,0-136.9c0.4-0.1,0.8-0.2,1.1-0.3
				c0.3,0.8,0.7,1.6,0.7,2.5c0.2,41.9,2.9,83.8,1.7,125.7C2699.2,1728.8,2701.4,1733.4,2695.7,1734.5L2695.7,1734.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2399.5,1410.8c2.6,2.9,1.5,6.5,1.5,9.7c0.1,36.4,0.7,72.9-0.3,109.3
				c-0.4,16-1.4,32-1.4,48c0,2.2-0.2,4.3-2.3,5.7c0.3-32.8,0.5-65.7,0.8-98.5c3.5-15.4,0.8-31,1.4-46.6
				C2399.3,1429.2,2399.4,1420,2399.5,1410.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1382,1707.2c4.3-2.1,8.7-5.6,12.2,0.9c1.1,2,2,2.4,2.9,0.6
				c2.5-4.4,5-1.8,7.4-0.2c1.4,1,2.4,3.2,4.5,2.5c10.2-3.6,7.4,2.4,5.5,7.6c-9.8,3.6-19.6,2.1-29.3-0.1
				C1383.1,1715,1380.7,1711.7,1382,1707.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2461.2,1458.8c4.2,5.1,1.7,11,2,16.5c0.5,8.2,0.2,16.4,0.3,24.6
				c0,27,0.1,54.1,0.1,81.1c2.9,11,9.4,19.3,20,24.3c-4,3.3-6.7-0.5-9.6,0.5c-1.7-0.2-3.4-0.3-5.1-0.5c-6.3-8.8-7.6-18.8-7.5-29.4
				c0.2-20-0.2-40-0.4-60c0-4.1,0.1-8.1,0.1-12.2c0-3.3,0-6.7,0-10C2461.1,1482.1,2461.1,1470.5,2461.2,1458.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2662.3,1614.2c-2.7,28.2,4.8,75.5,0,122.8
				C2662.3,1689.7,2662.3,1642.4,2662.3,1614.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2397.8,1484.9c-0.3,32.8-0.5,65.7-0.8,98.5c-5.2,11.8-8.7,13.2-27.8,9.4
				c15.3-2.9,24.2-9.8,24.4-24.6c0-1.2,0-2.3,0-3.5c2.1-1.4,2-3.6,2-5.7c-0.1-19.9-0.2-39.7-0.3-59.6c0-4.9,0-9.7,0.1-14.6
				C2396.2,1484.9,2397,1484.9,2397.8,1484.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2473.9,1595.8c1.2,50.8-3,101.5-3,152.2
				C2472,1697.3,2469.5,1646.5,2473.9,1595.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2529,1455.7c-2.2-10.1,1-20.2,0.2-30.3c0-4.9,0-9.9-0.1-14.8
				c-1.1-38.9,2.4-77.8,2-116.7c2.6,2.2,1.6,5.1,1.6,7.8c0.5,49-3,98-1.9,147.1C2530.9,1451.3,2531,1453.8,2529,1455.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2362.7,1575.9c1.2,0.1,2.4,0.2,3.6,0.4c1,10.6-2.6,21.5,2.1,31.8
				c-3.7,6.8-8.8-1.2-12.9,1.3c0.4-7.7-1.1-15.5,3.5-22.9C2360.7,1583.7,2354.1,1577.1,2362.7,1575.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2612.6,1604.7c0,30.5,0,61,0,91.5c-0.5,0-1.1,0-1.6,0c0-30.4,0-60.7,0-91.1
				C2611.5,1605,2612,1604.9,2612.6,1604.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2465.5,1347.1c-0.1,3.9-0.1,7.8-0.2,11.7c-0.1,25.3-0.2,50.5-0.3,75.8
				c-4-0.5-3.6-3.7-3.3-6.3c3.6-34.9,0.5-70,1.9-105c0.3-8.7,0.5-17.5,3.4-26C2466.9,1314,2467.9,1330.6,2465.5,1347.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2417.7,1712.3c-3.8,7.3-1.1,15.1-1.6,22.6c-0.1,4.2-0.1,8.5-0.2,12.7
				c-3-2.3-1.9-5.7-1.9-8.6c-0.1-21-1.1-42.1,0.4-63c1.3-18.1,0.9-36.2,3.4-54.4c0,5.5,0,11,0,16.6
				C2417.8,1662.9,2417.8,1687.6,2417.7,1712.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2715.6,1591.9c0,46.9,0,93.7,0,140.6c-0.3,0-0.7,0-1,0
				c0-46.8,0-93.7,0-140.5C2714.9,1591.9,2715.3,1591.9,2715.6,1591.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2645.6,1603.1c0,45.3,0,90.7,0,136c-0.4,0-0.8,0-1.3,0
				c0-45.3,0-90.6,0-135.9C2644.7,1603.1,2645.1,1603.1,2645.6,1603.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2705.2,1732.4c0-45.6,0-91.2,0-136.8c0.3,0,0.6,0,0.9,0
				c0,45.6,0,91.1,0,136.7C2705.8,1732.4,2705.5,1732.4,2705.2,1732.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2670.4,1736.1c0-45.6,0-91.1,0-136.7c0.3,0,0.7,0,1,0c0,45.6,0,91.1,0,136.7
				C2671.1,1736.1,2670.8,1736.1,2670.4,1736.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2461.2,1458.8c0,11.7,0,23.3,0,35c-1.6,0-3.2,0.1-4.9,0.1
				c0.1-16.5,0.1-33,0.2-49.5C2463.2,1447.4,2456.7,1455,2461.2,1458.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2483.6,1621.7c-15.2-1.7-30.5-3.4-45.7-5.1c4.9-3.6,10.5-1.2,15.7-1.8
				c4.5-0.6,9.9,0.5,11.5-5.9c3.8,6.6,10.5,6.4,16.6,7.7l0.8,0.2l0.9-0.1c0.4,0.3,0.9,0.5,1.5,0.6
				C2484.4,1618.7,2484,1620.2,2483.6,1621.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2338.1,1468.3c0,32.9,0,65.8,0,98.7c-1,0-1.9,0-2.9,0
				C2337.5,1534.1,2337.4,1501.2,2338.1,1468.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1010.5,1041.7c2.6-20.3,1-40.6,1.5-60.9c2-14.6,2.1-29.3,3.4-44.1
				c0,14.1,1.2,28.4-0.3,42.3c-2,18.2-0.6,36.3-1.6,54.4C1013.4,1036.2,1015.8,1040.5,1010.5,1041.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2395.4,1484.8c0,4.9,0,9.7,0,14.6c-1.7,0-3.4,0.1-5.1,0.1
				c2.1-22.8,2-45.6,1.6-68.5c-0.1-8.1-0.1-16.3-0.2-24.4c0,0,0.2-0.2,0.2-0.2c2.6,2.4,1.9,5.7,1.9,8.7c0,20.6,0,41.2,0.1,61.8
				C2393.8,1479.7,2392.9,1482.6,2395.4,1484.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1192.6,1643.2c0.9,5.2-1.7,7.7-6.3,9c-3.2,0.9-6.4,2-9.7,2.9
				c1.7-4.1,4.2-8.2-3.8-8c-2.8,0.1-1.7-3.3-2.1-5.3c1.5,0.4,2.9,0.8,4.4,1.2C1181,1645.3,1186.8,1645.4,1192.6,1643.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1210.5,1643.3c-5.4-1.9-8,6.9-13.9,3.8c2.8-7.1,9.3-9.6,15.4-12.5
				c1.8,0.5,0.8-1.2,1.3-1.6c3,2,5.8-1.8,8.8-0.2c0.2,0.2,0.4,0.3,0.5,0.6c0.1,0.2,0,0.5-0.1,0.6
				C1218.2,1636.7,1211.9,1636.8,1210.5,1643.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2465,1434.6c0.1-25.3,0.2-50.5,0.3-75.8c2.9,2.8,1.6,6.4,1.6,9.6
				c0.3,19.7,0.3,39.4,0.5,59.1c0,1.7,0,3.4,0,5.2c0,7.9,0,15.8,0,23.7C2466.2,1449.1,2461.7,1442.2,2465,1434.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1038.7,1696.2c0-32.3,0-64.6,0-96.9c0.3,0,0.6,0,1,0c0,32.2,0,64.5,0,96.7
				C1039.4,1696,1039.1,1696.1,1038.7,1696.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1030.1,1698.4c0.2-33.9,0.4-67.8,0.6-101.7c2,1.6,1.4,3.9,1.4,6
				c0,29.4,0,58.7,0,88.1C1032.1,1693.4,1032.9,1696.5,1030.1,1698.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1352.4,1679.7c-0.1-1.8-0.3-3.6-0.4-5.4c0.1-27.7,0.2-55.4,0.4-83.1
				c0-3.2-1.2-6.8,1.5-9.7c-0.1,32.6-0.1,65.2-0.2,97.8C1353.3,1679.5,1352.9,1679.6,1352.4,1679.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1120.6,1688.7c3.2-29.9,0.2-60,1.6-91
				C1124.7,1607.6,1123.9,1673.1,1120.6,1688.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2680.7,1645.5c0,29.7,0,59.4,0,89c-0.5,0-1,0-1.5,0c0-29.7,0-59.4,0-89
				C2679.6,1645.5,2680.1,1645.5,2680.7,1645.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1091,1682.9c0-30.5,0-61.1,0-91.6c0.3,0,0.6,0,0.9,0c0,30.6,0,61.3,0,91.9
				C1091.6,1683.1,1091.3,1683,1091,1682.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2317.3,1470.2c3.4-6.5,9.5-3.3,14.5-4.1c0,3.2-0.1,6.4-0.1,9.6
				c-0.5,0.4-1,0.8-1.5,1.2c-4.3,0.3-8.6,0.5-12.9,0.8C2317.3,1475.2,2317.3,1472.7,2317.3,1470.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1032,1564.7c0.2-26.5,0.3-53,0.5-79.5c2.4,2.2,1.4,5.2,1.5,7.9
				c0.1,21,0.1,42,0,63C1033.9,1559,1035,1562.4,1032,1564.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2463.5,1581.1c0-27-0.1-54.1-0.1-81.1c0.4,0.5,1.1,0.9,1.1,1.4
				c1,26.1,0.8,52.3,0.1,78.4C2464.6,1580.1,2463.9,1580.6,2463.5,1581.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2527.4,1542.2c0.1-26.4,0.1-52.8,0.2-79.2c1.7,1.3,1.5,3.2,1.5,5
				c0,22.8,0,45.5,0,68.3C2529.1,1538.4,2529.4,1540.7,2527.4,1542.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1083.6,993.4c2.4-16.5,1.4-33.2,1.5-49.8c0-6.7,0-13.4,0-20.1
				c0.6,0,1.2,0,1.7,0c0,22.1,0,44.1,0,66.2C1086.8,992,1085.8,993.3,1083.6,993.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1092.4,782.1c-2.3,23.3-1.7,46.6-2.8,69.9
				C1090.7,828.8,1085.2,805.2,1092.4,782.1L1092.4,782.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2417.7,1712.3c0.1-24.7,0.1-49.4,0.2-74c2.3,1.9,1.6,4.5,1.6,6.9
				c0,20.1,0,40.2,0,60.3C2419.5,1707.8,2419.9,1710.4,2417.7,1712.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2407.2,1708.1c2.2-20.7,1.2-41.5,1.6-62.3c3.4,6.7,1.2,13.8,1.4,20.8
				c0.4,11.4,0.2,22.8,0,34.2C2410.2,1703.4,2412.1,1707.3,2407.2,1708.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2393.6,1564.7c0,1.2,0,2.3,0,3.5c-1.6-0.1-3.2-0.1-4.7-0.2
				c3-21.4,0.6-42.9,1.4-64.4c2.9,2.4,1.7,5.6,1.7,8.5c0.1,13.4-0.2,26.8,0,40.2C2392.1,1556.5,2390.4,1561,2393.6,1564.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1027.8,977.4c0,18,0,36,0,54c-0.9,0-1.8,0-2.8,0
				C1026.7,1013.5,1027,995.6,1027.8,977.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2484.9,1617.2c-0.5,0-1-0.2-1.5-0.6c-0.5-1.5-1-1.8-1.7-0.1
				c-6.1-1.3-12.9-1.2-16.6-7.7c-0.5-1.2-1-2.3-1.5-3.4c1.8,0,3.5,0.1,5.3,0.1c1.7,0.2,3.4,0.3,5.1,0.5c0.8,4,6,6.1,7.1,5.8
				C2488.1,1610.1,2483.9,1615.3,2484.9,1617.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2424.8,1663.5c-1.7,3.5-4.5,6.7-8.2,8.3c-2,0.9-3.3-2.2-2.5-3.7
				c2-4-0.1-10.9,6.6-11.4C2423.7,1656.4,2424.4,1660.1,2424.8,1663.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2383.2,1642.4c5.9-2.1,7.2,4.1,10.9,6.1c0.9,1.9,4.7,2.2,3.8,4.5
				c-0.8,2-3.5,3.3-6.2,2c0.7-5.2-2.3-5.8-6-4.6c-4,1.4-6.9,0.7-8.6-3.5C2379.6,1646.2,2383.3,1646.9,2383.2,1642.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2811.1,1502.8c0.4,2.7,0.6,5.4-0.2,8.1c-3.4-1.7-5.7,1.7-9.1,2.1
				C2801.4,1505.9,2804,1502.3,2811.1,1502.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2644.5,1519.1c1.8-3.7-0.9-8.3,2.4-11.7c5.7-0.8,6.2-0.1,4.3,6.3
				C2649.7,1518.8,2648.1,1519.1,2644.5,1519.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2589.3,1517.9c0.1-8.4,0.1-16.8,0.2-25.2l-0.1,0c5.3,4,2.3,9.1,1.5,13.7
				c-0.5,3-0.6,4.9,2.7,5.5c0,1.5,0,2.9,0,4.4C2592.2,1516.8,2590.7,1517.3,2589.3,1517.9L2589.3,1517.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1096.7,1664.4c0-20.2,0-40.5,0-60.7c0.3,0,0.6,0,0.9,0c0,20.3,0,40.6,0,60.8
				C1097.3,1664.5,1097,1664.4,1096.7,1664.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1051.5,861c0,14.5,0,27.5,0,42C1047.6,895.2,1047.8,884.3,1051.5,861z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1117.9,1622.6c0,18.9,0,37.9,0,56.8c-0.3,0-0.6,0-0.9,0
				c0-18.9,0-37.9,0-56.8C1117.2,1622.6,1117.6,1622.6,1117.9,1622.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1175.3,1643.1c-1.5-0.4-2.9-0.8-4.4-1.2c0.4-5.1,2-9.1,8-9
				c-0.7,1.3-0.8,2.4,0.7,3.1C1184.5,1642.4,1175.1,1639.7,1175.3,1643.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1490.8,1411.1c0,18.4,0,36.7,0,55.1c-0.3,0-0.6,0-0.9,0
				c0-18.4,0-36.7,0-55.1C1490.2,1411.1,1490.5,1411.1,1490.8,1411.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2317.3,1477.7c4.3-0.3,8.6-0.5,12.9-0.8c0.2,0.5,0.6,0.8,1.1,1
				c0,1.6,0,3.2-0.1,4.8c-8.2,5-12.3,4-14.4-3.4C2317.1,1478.7,2317.2,1478.2,2317.3,1477.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2351.6,1598.2c-6-1.2-12.1-2.4-16.5-7.3c0.1-0.7,0.1-1.4,0.1-2
				c4.9,0.7,9.9,1.1,14.8,2.2C2354.8,1592.1,2354.1,1595.1,2351.6,1598.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2671.2,1466c0.8,0.5,2.3,0.8,2.4,1.4c1,5.8,0.9,5.8,6.4,6.4
				c-1.7,3.3-5.2,1.9-7.7,3.1c0,0-0.4-0.3-0.4-0.3c0-0.4-0.1-0.7-0.1-1.1c-0.1,0.4-0.1,0.7-0.2,1.1c-0.7,0-1.4,0-2.1,0.1
				c-0.3-0.7-0.7-1.4-1-2.1C2666.8,1471,2665.7,1467.5,2671.2,1466z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2416,1747.6c0.1-4.2,0.1-8.5,0.2-12.7c2.4,17.8,2.9,35.7-0.2,53.5
				c0-13-0.1-25.9-0.1-38.9C2415.9,1748.8,2415.9,1748.2,2416,1747.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1022.4,933c0,15.9,0,31.7,0,47.6c-0.3,0-0.6,0-0.9,0c0-15.9,0-31.8,0-47.8
				C1021.9,932.9,1022.2,932.9,1022.4,933z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2603.9,1514.2c-4.2-0.6-3-3.7-2.8-6.3c0.2-2.1,1.8-1.7,3.3-1.6
				c4.6,0.5,4.1,4.1,4.3,7.2C2607.7,1518.1,2605.3,1512.2,2603.9,1514.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2331.3,1493.8c-9.9-0.6-19.7-1.3-29.6-1.9c0-0.9,0-1.8,0-2.8
				c9.9,1.8,19.8,1.7,29.7,1.5C2331.5,1491.7,2331.4,1492.7,2331.3,1493.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1019.4,990.1c3.6,15.2,1,30.6,1.3,46c0,0-0.6,0.2-0.6,0.1
				c-0.3-0.5-0.5-1.1-0.8-1.6C1019.3,1019.8,1019.3,1005,1019.4,990.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1421.8,1690.9c-1.7,2.6,1.1,8.3-5.1,7.9c0.4-7.5-2.5-15.3,1.5-22.6
				C1421.6,1680.6,1418.3,1686.6,1421.8,1690.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1019.4,990.1c0,14.8-0.1,29.7-0.1,44.5c-3.8-14.7-1.2-29.6-1.4-44.4
				c0-0.1,0.4-0.4,0.7-0.4C1018.8,989.8,1019.1,990,1019.4,990.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2646.9,1503.2c-1.2-3-1-6.1,0-9.1c0.2,0.2,0.4,0.3,0.6,0.5
				c2.3,2,6.9-4,7.2,1.2C2654.9,1498.6,2650.9,1502.1,2646.9,1503.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1087.5,902.2c0-13.3,0-26.7,0-40c0.3,0,0.6,0,0.9,0c0,13.4,0,26.9,0,40.3
				C1088.1,902.4,1087.8,902.3,1087.5,902.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1067.2,1637.2c-3.2,0-7.5,1.9-7.5-4.2c5,0,10,0,15.1,0
				C1073.6,1637.5,1068,1632.5,1067.2,1637.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1078.4,1690.8c0-12.2,0-24.5,0-36.7c0.3,0,0.6,0,0.9,0c0,12.2,0,24.5,0,36.7
				C1078.9,1690.8,1078.7,1690.8,1078.4,1690.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2589.3,1517.9c1.4-0.5,2.9-1,4.3-1.6c5.9,4.9,0,7.3-2.2,10.5
				C2590.6,1523.8,2590,1520.8,2589.3,1517.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2333.2,1438.1c-2.1-12.9-3.1-25.8,0.3-38.7
				C2333.4,1412.3,2333.3,1425.2,2333.2,1438.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2399.5,1410.8c-0.1,9.2-0.2,18.4-0.3,27.6c-3.9-12.2-0.6-24.6-2-38.1
				C2400.4,1404.3,2398.6,1407.8,2399.5,1410.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2814.7,1667.1c-1.5,6.7,3.5,13.3,0.3,22
				C2811.6,1680.4,2813.2,1673.7,2814.7,1667.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1179.6,1636c-1.5-0.7-1.5-1.8-0.7-3.1c3.6-1.1,7.6-3.9,9.6,2.2
				c-0.4,0.8-0.8,1.6-1.2,2.4C1184.5,1638.5,1183,1632.3,1179.6,1636z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1487.6,1657.4c-0.1,2.6-0.3,5.2-0.4,7.7c-1.3-0.1-2.6-0.2-3.9-0.4
				c-1.7-5.5-2.3-11,0.7-16.3c0.5,0.5,0.9,1,1.4,1.6c1,2.4-4.7,7,2.3,7.5L1487.6,1657.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2810.1,1544.1c0-2.2-0.2-4.4,0.1-6.6c0.2-1.9,1.5-3.1,3.5-3.1
				c1.3,0,2.5,0.6,2.9,1.9c0.6,2.2-1.5,2-2.7,2.6C2811.6,1540,2811.4,1542.3,2810.1,1544.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1218.3,1392.3c0.2,5.8,1.3,11.5-0.4,17.6C1215,1405,1215,1405,1218.3,1392.3
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2450.5,1655.7c0.5,1.2,0.9,2.5,1.3,3.7c-3.2,1.1-5.2,3.6-6.5,7.9
				c-3.4-5.7-1.2-8.7,1.6-11.7C2448.1,1655.6,2449.3,1655.7,2450.5,1655.7L2450.5,1655.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1031,1612.5c0.4-2.5,0.8-4.9,1.2-7.4c2.7-0.5,3.8,1.1,4.2,3.5
				C1036.9,1613.2,1033.3,1611.9,1031,1612.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1200.4,1520c0-0.3,0-0.7,0.1-0.9c1.8-3.7,2.8-8.1,7.6-9.3
				c0.2-0.1,1.4,1.5,1.2,2.1C1208,1516.3,1205.2,1519.3,1200.4,1520C1200.5,1520.1,1200.4,1520,1200.4,1520z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1222.1,1632.7c-3-1.6-5.8,2.2-8.8,0.2c-0.5-0.9-0.9-1.8-1.4-2.7
				c0.8-0.3,1.9-0.3,1.4-1.6c0,0,0.1,0.3,0.1,0.3c2.9,0.5,5.8,0.9,8.7,1.3C1222.1,1631,1222.1,1631.9,1222.1,1632.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2419.5,1624.1c3.9-0.7,7.5-0.3,10.9,1.9
				C2425.8,1631.3,2423.9,1631,2419.5,1624.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1218.8,1500.3c-3.5,2.7-4.7,5.8-8.1,5.6c-0.4,0-1-2.2-0.8-3.2
				C1211,1498.3,1214.3,1499.8,1218.8,1500.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1039.3,1585.1c2.4,3.3,2.2,5.6,0.4,9.3
				C1037.5,1591.2,1037.4,1588.9,1039.3,1585.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1048.5,1395.4c-2.2,2.2-4.1,3.2-6.4,2c-0.3-0.1-0.3-1.9,0.2-2.3
				C1043.9,1393.4,1045.8,1393.6,1048.5,1395.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2504.1,1656.4c0-3.5,0-7,0-10.4c0.3,0,0.5,0,0.8,0c0,3.4,0,6.7,0,10.1
				C2504.6,1656.1,2504.4,1656.3,2504.1,1656.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2815.9,1506.1c-0.2-1.6-0.6-3.3,1-4.5c0.8,0.3,2.4,0.6,2.3,0.7
				C2818.9,1504.2,2818.2,1505.9,2815.9,1506.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2513,1564.6c-1-0.3-1.1-1.3-1.1-2.2c0.1-0.9,0.1-2.2,1.5-1.5
				c0.6,0.3,0.9,1.6,1,2.5C2514.5,1564.1,2514,1564.6,2513,1564.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2476.7,1532.7c3-0.3,3.4,2.5,4.7,4.2c-0.6,0.1-1.2,0.2-1.8,0.3
				c-0.5,0-0.9,0-1.4-0.1C2477.5,1535.7,2476.2,1534.5,2476.7,1532.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2479.6,1537.2c0.6-0.1,1.2-0.2,1.8-0.3c1,0.3,2.2,0.2,2.7,1.5
				C2481.8,1541.2,2480.4,1540.5,2479.6,1537.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2516.1,1555.7c-1.6,0.9-2.3,1.5-2.8,1.4c-0.5-0.1-1.1-0.9-1.1-1.4
				c0-0.5,0.7-1.3,1.1-1.4C2513.8,1554.2,2514.5,1554.8,2516.1,1555.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1051.8,1608c-0.6-3.3,1.8-2.9,3.7-3.2
				C1055.7,1607.7,1053.9,1608.1,1051.8,1608z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1440,1690.7c-2.4,0-4.8,0.1-7.2,0.1
				C1435.2,1688.5,1437.6,1688.6,1440,1690.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1124.8,1699.7c-0.2,0.5-0.3,1.3-0.7,1.4c-0.4,0.1-1-0.4-1.5-0.6
				c0.2-0.5,0.3-1.3,0.7-1.4C1123.7,1699,1124.3,1699.5,1124.8,1699.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1042.1,1608.3c1.3-0.5,2.4-0.2,3.3,1
				C1043.5,1611.4,1042.6,1610.6,1042.1,1608.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2335.3,1588.9c0,0.7-0.1,1.4-0.1,2c-1.2-0.1-2.3-0.2-3.5-0.2l0.1,0.1
				c0.3-1.4,0.6-2.9,0.9-4.3C2333.5,1587.3,2334.4,1588.1,2335.3,1588.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1099.9,1690.9c-0.2,1.4-0.5,2.7-0.7,4.1c-0.2-0.2-0.6-0.3-0.7-0.5
				C1097.9,1692.9,1098.5,1691.7,1099.9,1690.9C1099.9,1690.9,1099.9,1690.9,1099.9,1690.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1014.8,926.4c0.3,0.6,0.7,1.1,0.6,1.5c0,0.5-0.5,0.9-0.8,1.4
				c-0.2-0.5-0.6-1-0.6-1.5C1014.1,927.4,1014.5,926.9,1014.8,926.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2728.1,1546.1c0.2-1.1,0.4-2.2,0.5-3.3c0.2,0.1,0.5,0.2,0.6,0.4
				C2729.6,1544.4,2729.2,1545.4,2728.1,1546.1L2728.1,1546.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2504.5,1538.7c0-1.2,0.2-2.4,1.5-3c0,0-0.1,0-0.1,0
				C2506,1536.9,2505.7,1538,2504.5,1538.7L2504.5,1538.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1097.1,1011.6c0.1,0.3,0.4,0.5,0.4,0.7c0,0.2-0.3,0.4-0.5,0.7
				C1097,1012.6,1097,1012.1,1097.1,1011.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2602,1535.9c-0.2,0-0.3,0.1-0.5,0.1c-0.1-0.2-0.1-0.4-0.1-0.6
				c0-0.1,0.3-0.1,0.5-0.1C2601.9,1535.5,2601.9,1535.7,2602,1535.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2806.6,1529.1c0.1-0.2,0.1-0.5,0.2-0.5c0.2,0,0.4,0.1,0.6,0.2
				c-0.1,0.2-0.1,0.5-0.2,0.5C2807,1529.3,2806.8,1529.2,2806.6,1529.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2668.6,1474.6c0.3,0.7,0.7,1.4,1,2.1
				C2667.3,1477,2668.7,1475.5,2668.6,1474.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2783.3,1538.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2-0.2-0.4-0.4-0.6-0.7
				c0,0,0.3-0.3,0.4-0.4C2783.1,1538.1,2783.2,1538.3,2783.3,1538.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2775.5,1539.2c-0.1-0.2-0.2-0.3-0.3-0.5c0.2-0.1,0.5-0.3,0.7-0.4
				c0,0.2,0.1,0.4,0.1,0.6C2775.9,1539,2775.7,1539.1,2775.5,1539.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2762.4,1540.7c-0.1-0.2-0.2-0.3-0.3-0.5c0.2-0.1,0.5-0.3,0.7-0.4
				c0,0.2,0.1,0.4,0.1,0.6C2762.8,1540.5,2762.5,1540.6,2762.4,1540.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2331.3,1477.9c-0.5-0.1-0.9-0.5-1.1-1c0.5-0.4,1-0.8,1.5-1.2
				C2331.6,1476.4,2331.5,1477.1,2331.3,1477.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M2722.2,1529.8c0-0.6,0-1.3,0-1.9c0.5,0.1,1.1,0.2,1.6,0.3
				C2723.5,1529,2723.6,1530.2,2722.2,1529.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2417.2,1622.1c1.2,0.1,2.3,0.4,2.2,2
				C2418.7,1623.4,2418,1622.7,2417.2,1622.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2514.5,1626c0.1-0.2,0.3-0.5,0.4-0.7
				C2514.8,1625.5,2514.7,1625.7,2514.5,1626L2514.5,1626z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2695.8,1734.6c-0.3,0.3-0.5,0.5-0.8,0.8
				C2695.2,1735.1,2695.5,1734.8,2695.8,1734.6C2695.7,1734.5,2695.8,1734.6,2695.8,1734.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2671.7,1476.7c0.1-0.4,0.1-0.7,0.2-1.1c0,0.4,0.1,0.7,0.1,1.1
				C2671.9,1476.7,2671.7,1476.7,2671.7,1476.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1213.4,1628.5c0.4,1.4-0.7,1.4-1.4,1.6
				C1212.4,1629.6,1212.9,1629.1,1213.4,1628.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M2514.5,1626c-0.1,0.3-0.3,0.5-0.4,0.8
				C2514.3,1626.5,2514.4,1626.2,2514.5,1626L2514.5,1626z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1372.3,1776.2c0.7-0.1,1.4-0.2,2-0.4c2.4,3.7,3.1,1,3.9-1.1
				c6-1.9,10.5-7.2,17.1-8c2.4-0.3,4.4-2.3,7.1-0.1c1.4,1.1,4.2,0.8,6-0.7c10.8-9.5,23.7-15.5,36.4-21.6c4.9-2.4,8.4-5.4,9.8-10.7
				c6.5,1.1,12.7,4.2,19.5,3.7c0.3,0,0.8-0.1,0.9,0.1c5.2,7.5,11.3,13.2,20.9,14.5c1.7,0.2-0.3,4.1-2.7,5.4
				c-10.9,5.8-21.6,12.4-34.8,9.9c-1.8-0.3-3.7-0.3-5.4,0.1c-3.5,0.8-8.1,2.8-8.1,5.8c-0.1,4.4,5.2,2.7,9.1,3.9
				c-6.3,6-12.1,11.6-17.9,17.2c-4.8,4.7-9.2,8.5-16.7,3.7c-4.3-2.8-10.2-2.6-14,2.4c-7.8,10.3-19.6,9.3-30.3,11.2
				c-4.7,0.8-9,0.3-13.1,4.4c-2.9,2.9-8.3,3.2-12.9,3.2c-7.3,0.1-14.5-0.7-21.7,1.6c-2.8,0.9-6.1,0.8-8.7-1.5
				c-2.8-2.5-5.5-2.2-8,0.5c-3.9,4-9.2,2.8-13.8,3.9c-15,3.6-28.1-4.9-43.1-3.6c-17.8,1.5-36-1.5-54,2.6c-8.8,2-15.8-5.6-24.4-5.4
				c-2.9-5.6-9-3-13.2-5.2c-0.6-2.1-2.1-2.2-3.8-2c-4.8-1-9.6-2-14.4-2.9c0.3-2.2,2.2-2.5,4.9-4c-9-2.7-17-4.5-25.5-4
				c-3.6-0.9-7.8,1.3-10.9-2.1c9-0.3,18-0.6,27,1.1c8.7,1.6,17.6,4.8,26.5,0.4c2.3-2.8,5.1-3.4,8.7-3.3c12.3,0.3,24.6,0.2,36.9,0.2
				c11.8,4.8,23.9,6.2,36.6,4.4c10-1.4,20.2-3.3,30.1-2.5c8.9,0.7,17.4,0.8,25.6-2.7c13.5-5.7,27.1-10.7,42-10.9
				c2.9,0,6.1-1.3,6.6-5.2c1.8-0.1,3.6-0.1,5.4-0.2c3.7-0.5,7.9,1.9,11-2c0.7,0,1.3,0,2,0.1
				C1371.2,1777.7,1371.8,1777.7,1372.3,1776.2z M1209.3,1802.1c-8.9,0.3-18.2-7.2-28.9,0
				C1191.3,1802.1,1200.3,1802.1,1209.3,1802.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1709.1,1691.4c0,1-0.1,2-0.1,3.1c-28.2,0-56.5,1.3-84.6-0.3
				c-24.6-1.4-49.1-2.3-73.6-2.8c-5-0.1-8-1.9-9.6-6.5c-0.4-0.3-1-1-1.2-0.9c-1.1,0.7-0.4,1.3,0.3,1.8c-4.8,10.8-6.1,11.3-13.7,3
				c-2-2.2-2.2-1.2-3.7-0.3c-2.1,1.2-4.4,2.1-6.7,2.4c-10.4,1.6-14.7-3.1-12.6-13.4c0.3-1.5,0.4-3,0.6-4.5c1.3-0.1,2.6-0.1,4-0.2
				l0.4,0.3l0.5-0.2c1.3,0.2,2.6,0.5,3.9,0.7c7.7,0.5,15.2,4.8,23.2,2.1c2.1-0.7,2.7,0.5,3.2,2c1.3,4.5,4.9,3.6,8.2,3.7
				c32.6,1.4,65.5,1.1,97.9,4.9C1666.6,1688.8,1687.9,1688.8,1709.1,1691.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1438.3,1718.8c5-1.8,10.1-2.9,15.2-0.3c-2,4.3-0.1,8.8-0.4,13.1
				c-8.5,0.2-15.6,5.9-24.2,6.3c-4,0.2-3,5.3-5.2,8c1.7-5.6-6.1-9.7-1.7-15.9c1.4-1.9-2.7-2.6-3.8-4.3c-1.1-1.6-5.4-1.7-2-5.1
				C1424.1,1725.4,1431.6,1727.7,1438.3,1718.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1179.6,1636c3.3-3.7,4.9,2.5,7.7,1.5c-0.4,4,5.5,2,5.3,5.7
				c-5.8,2.2-11.6,2-17.4-0.1C1175.1,1639.7,1184.5,1642.4,1179.6,1636z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1512.9,1673.6c-1.3-0.2-2.6-0.5-3.9-0.7c-0.1-0.5-0.2-0.9-0.3-1.4
				c-0.2,0.4-0.4,0.8-0.7,1.2c-1.3,0.1-2.6,0.1-4,0.2c0.7-1.9,1.4-3.7,2-5.6c3.8,0.9,3.6-5.2,7.3-4.4
				C1514.1,1666.5,1516.8,1670.2,1512.9,1673.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1485.7,1673.1c-1.1-0.1-2.2-0.3-3.3-0.4c0.4-1.4,0.7-2.7,1.1-4.1
				C1485.8,1669.3,1485.9,1671.1,1485.7,1673.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1140.1,1653.7c0.1,0.1,0.3,0.2,0.4,0.3
				C1140.5,1653.9,1140.3,1653.8,1140.1,1653.7L1140.1,1653.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1528.5,1042.1c0-1.2-2.3-3.5,1.1-3.2c4,0.3,6.5-5.3,11-2.5
				c0.2-5.2,6.6-4.5,7.7-7.4c2-5.4,6.4-4.3,9.9-5.7l0,0c0,6.1-5,9.3-8.8,11.8c-4.8,3.2-9.2,7.9-15.7,8.2c-2.4,0.1-0.5,4.4-3.2,5.1
				l0.1,0.1c0.1-1.7-0.4-3.1-1.7-4.2C1528.7,1043.5,1528.6,1042.8,1528.5,1042.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1574.9,1048.4c-1.9-3.6-1.6-8.4-0.9-11.2c1-3.4-0.4-4.1-1.7-5.9
				c-0.1-0.1,0.2-0.5,0.4-0.6c0.2-0.1,0.5-0.1,0.7-0.2c3.1,0,3.7,2.8,5.3,4.5c3.3,4.6,5.7,9.3,1.5,14.7
				C1578.3,1050.1,1576.4,1050,1574.9,1048.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1576.7,1023.9c-1.1-3.2-1.5-5.1-3-6.5c-3.8-3.4-1.2-6.4,1.7-7.1
				c4.5-1.1,9.4,0,13.8-5.4c0.3,5.2-1.8,7-3.7,7.4c-2.8,0.5-3.6,3-5.6,4.1C1577.1,1017.8,1577.1,1020.2,1576.7,1023.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1596.1,1014.4c2,2.5,3.9,5.1,7.9,5.9c-8,0.3-4.9,10.7-11.8,11
				c-3.3-5.5,0.3-10.4,2.5-15.5l-0.1,0.1c1.3,0.2,1.4-0.7,1.6-1.6L1596.1,1014.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2698.9,486.4c-3.7-7.8,3-5.7,6.2-6.4c1.8-0.4,4.9-3.4,5.3,0.9
				c0.2,2.6,0.1,7.1-5.1,5.6C2703.2,486.4,2701.1,486.4,2698.9,486.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1594.5,983.4c2.1,0,4.3,0.1,6.4,0.1c0,1.2-0.2,2.3,0.8,3.4
				c1.4,1.5,5.5,2.2,2.4,5.2c-2.4,2.2-3.6-1.2-4.9-2.5C1597.2,987.8,1594.7,986.4,1594.5,983.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1593.5,1004.4c0.5,2.2-1.7,5.2,2.6,6.2c3,0.7,0.2,2.5,0,3.8l0.1-0.1
				c-0.8,0.2-1.6,0.5-1.6,1.6l0.1-0.1c-5.8-3.3-2.3-8.3-2.2-12.7C1592.9,1003.5,1593.2,1004,1593.5,1004.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1578.7,1035.1c-1.6-1.7-2.2-4.6-5.3-4.5c1.3-1.6,2.9-3,2.5-5.4
				C1580.4,1027.8,1581.5,1031,1578.7,1035.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1577.7,988.3c-0.7,2.8-0.2,6-2.3,8.4c-1.7-7-1.7-7,1.6-9.2
				C1577.2,987.7,1577.5,988,1577.7,988.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2681.9,484.8c0.5,0,1.1-0.1,1.6-0.1c-1.5,2,2.5,5.1-1.4,6.6
				c-0.2-0.3-0.5-0.6-0.7-0.9C2681.6,488.6,2681.7,486.7,2681.9,484.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1592.6,1003.1c1.4-1.1,2.6-1,3.1,0.8c0.1,0.5-0.3,1.1-0.5,1.7
				c-0.5-0.4-1.1-0.8-1.6-1.2C1593.2,1004,1592.9,1003.5,1592.6,1003.1z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#76726E" points="1558.2,1023.2 1558.3,1023.1 1558.1,1023.2 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2409.9,1398.5c10.6,0.2,21.2,0.3,31.8,0.5c1.2,5.2,8.5,2,9.9,8.1
				c-11.1-0.8-21.9-1.4-32.7-2.6c-5.1-0.6-10.1-1.6-15.1,0c-0.5,0.1-1,0.3-1.4,0.2c-2.8-0.9-6.6,0-7.1-4.6c2.6,0.6,4.4-2.7,7.5-1.3
				C2404.7,1399.6,2407.4,1398.6,2409.9,1398.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1015.3,930.6c0.1-0.5,0.2-1,0.4-1.5c6.6,2.5,12.8-1.7,19.4-1.5
				c2.9,0.1,5.3-3.9,3.9-7.8c-1.3-3.7,1-3.6,3.2-3.6c2.3,0,4.8-0.2,3.4,3.5c-0.1,0.2-0.2,0.5-0.2,0.7c0.4,14.7,0.4,14.7-14.5,13.6
				c-1.2-0.1-2.4,0-3.6-0.1c-3.5-3-7.5-1.2-11.3-1.5C1015.8,931.8,1015.5,931.2,1015.3,930.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1065.6,869.7c-1-1.6-2.2-2.8-4.1-3.1c0-1.7-0.1-3.5-0.1-5.2
				c0,0,0.1-0.1,0.1-0.1c2.1-0.3,3.4-0.9,1.3-3.1c0.1-1.5,1.3-2.9,0.1-4.4l0,0c5.1-0.6,2.1-5.1,3.4-7.5c4.7-0.4,7,2.5,8.7,6.4
				l-0.1-0.1c-7.1,1.2-4.1,7.1-4.9,11.2c0,0-0.2,0.1-0.2,0.1c-1.7,0.4-3.2,0.9-1.5,3.1c0,1-0.1,1.9-0.1,2.9
				C1067.2,869.8,1066.4,869.8,1065.6,869.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1055.5,892.3c1.2,2.1,2.5,4.1,3.6,6.2c1.1,2.1,0.4,5,3.8,7.1
				c1.5-9.1,1.6-17.8,0.8-26.6c3.4,1.5,0.5,5.9,3.4,7.5c-0.1,4.4-0.1,8.9-0.2,13.3c-2.1,2.5-1.9,5-0.1,7.5c0.3,1.9,0.5,3.9,0.8,5.8
				c-7.2,1.4-6.4-6.3-10.1-8.9c-0.4-3,0-6.3-3.6-7.7C1052.6,894.3,1053.5,893.1,1055.5,892.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1048.8,820.8c4.1-2.1,3.1,1.9,3.4,3.2c0.7,3.5-2.1,3.2-4.2,3.1
				c-2.2-0.1-5.5,1-5.5-2.8C1042.5,819.8,1046.1,820.9,1048.8,820.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M1064.2,794.4c-0.6-1.1-1.3-2.1-1.7-3.2c-0.9-2.1-3-4.9-1.4-6.5
				c2-1.9,5.4-0.1,7.3,1.3c3.1,2.3-0.7,4-1.4,5.9C1066.1,792.8,1065.2,793.6,1064.2,794.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1093.4,812.3c1.8,5.3,1.2,9.1-5.6,8.8c-1.4-1.7-1.5-4.6-4.6-4.4
				c1.7-2.9,6.6,0.7,7.5-3.8C1091.6,812.7,1092.5,812.5,1093.4,812.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1092.2,866.6c-2.3-5.8-2-11.7-0.7-17.7
				C1095.2,854.6,1093,860.7,1092.2,866.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1053.9,896.4c3.6,1.4,3.2,4.8,3.6,7.7c0.6,2.2-3.2,2.6-1.8,5.5
				c1.4,3-1.4,2.1-2.8,2.1c-2.4,0-2.7-1.3-1.7-3.3C1053.1,904.7,1054.1,900.7,1053.9,896.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1089.4,1040.6c-2.8-5.4-2.1-10.5,2.5-15.7c-0.7,5.7,1.3,10.9-1.4,15.8
				l-0.5-0.1L1089.4,1040.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1093.4,812.3c-0.9,0.2-1.9,0.3-2.8,0.5c-6.7,0.2-5-3.3-3-7
				c1.5-2.9,3.1-2.9,4.3,0.2C1092.7,808,1092.9,810.2,1093.4,812.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1066.2,846.4c-1.3,2.4,1.7,6.9-3.4,7.5c-1.1-1.8-5.4-1-3.6-5.5
				C1061,843.9,1064,847.3,1066.2,846.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M998.2,943.4c-1.9-2-4.2-2.2-4.3-4.6c-2.6,1-0.2,4.3-3.5,4.8
				c0-2.6-2.4-5.7,2.3-6.5C996.9,936.4,997.8,937.4,998.2,943.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1070.5,924.9c-0.2-2-0.4-4.1-2.8-5.5c3.1-0.6,5.6-1,8.1-1.5
				C1075.8,921.6,1076.1,925.6,1070.5,924.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1048,885.5c-1.5-4.3-1.1-8.6-0.4-15C1050.2,876.9,1049.6,881.2,1048,885.5z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1055.5,934c3,4,1.9,8,0.1,12c-1.2-0.5-2.5-1-3.7-1.5c0-1-0.1-2-0.1-3
				C1053,938.9,1054.2,936.5,1055.5,934z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M1074.9,852.7c4.8,4.5,2.7,9.5,0.9,14.4l0.1,0c-1.7-4.7-2.4-9.5-1.2-14.5
				L1074.9,852.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1028.5,968.9c-7.4,0.8-14.8,0.8-24.3,0.1c9.3-1.4,16.6-0.9,23.8-0.6
				C1028.1,968.6,1028.3,968.8,1028.5,968.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1056.6,925.8c-0.4-0.1-0.8-0.2-1.2-0.3c-0.3-2.5-4.6-7.4,3.1-5.3
				c0.5,0.1,1.6-1.5,2.4-2.3c0.7,3,0.5,5.7-2.4,7.6C1057.9,925.5,1057.3,925.6,1056.6,925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1094.6,791.2c0.6,4.4,1.7,7.5-1.4,10.2c-0.9,0.8-2.1,0.5-2.4-0.4
				C1089.9,797.3,1091.8,794.6,1094.6,791.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1083.2,816.7c3.1-0.2,3.3,2.8,4.6,4.4c-0.4,2.8-2.5,3.9-4.6,2.6
				C1080.1,821.8,1083.4,819,1083.2,816.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1062.8,853.8c1.2,1.5,0,3-0.1,4.4c-1.4,0.6-1.3,1.8-1.3,3.1
				c0,0-0.1,0.1-0.1,0.1c-2.1-1.6-3.9-3.6-2.9-6.4C1059.1,853.1,1061.2,853.8,1062.8,853.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1066.8,830.2c-1.8,0-3.7-0.1-5.5-0.1c0.3-1.3-0.3-3.5,1.2-3.7
				C1064.7,826.2,1066.1,828,1066.8,830.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1044.1,1639.4c-0.8,0.7-1,1-1.2,1.1c-3.2,1.7-7.2,2.2-9.7,0.2
				c-2.2-1.7,0-3.1,2.4-2.7c1.9,0.3,3.8,0.7,5.7,1C1042.1,1639.1,1042.8,1639.2,1044.1,1639.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1516.7,1417.3c2,6.6-5.1,11.7-2.8,18.5c0.5,1.5-0.8,3.6,1.8,4.1
				c4.6,0.9,4.3,0.9,4.8,4.8c0.5,3.7-2.8,9.1,4,10.4c3.1,1.3,3.5,4,3.5,7c-0.7,0.1-1.4,0.3-2.1,0.4c-1.7,0.6-4.7-3.3-5.1,1.6
				c-0.5,14.5-0.1,28.9,1.1,43.4c1.4,16.5,0.3,33.2,0.4,49.9c0,2.6,0.3,5.3,0.5,8.2c5.8-1.6,3.5-6,5.1-9.2c6.4,14,2,29,3.1,43.4
				c-2.5-5.7-0.7-11.7-1.4-17.5c-0.4-2.9,1.9-7.8-3.4-8c-5.4-0.2-3.7,4.6-3.8,7.6c-0.2,4.3,0.2,8.6,0.3,13c-1.2,8,2.6,15.6,1.7,23.5
				c-3.1,2.2-1.5,5.6-2.1,8.4c-0.5,2.1,1.9,7.2-4,4.4c-1.5-0.7-1.5,1.2-1.3,1.4c4.5,4.3-2.5,11.3,4.4,15.9c3.5,2.3-0.8,10,2.3,14.6
				c-2.8,3.6-3.2,0.3-4.2-1.4c0-0.4,0-0.9,0-1.3c-0.1,0.4-0.2,0.8-0.3,1.3c-1.3,0.1-2.6,0.1-3.9,0.2c-0.1-2.1-0.3-4.2-0.4-6.3
				c1.7-1,5,2.8,5-0.7c-0.1-3.7-3.2-5.6-7.1-2.9c-0.4,1.1-0.9,2.2-1.3,3.4l-0.6,0.6c-6.2-4.5-4.6-10.4-1.1-14.9
				c2.6-3.3,3.2-6.4,3.2-10.1c0-24.4,1.2-48.9-0.4-73.1c-1.8-25.9,0.2-51.8-1.8-77.6c-1.5-19.1-0.9-38.2-1.5-57.2
				c0-1.8,0.1-4.4-0.9-5.1c-8-5.8,1.4-3.8,2.3-5.5C1513.1,1413.8,1511.9,1419.1,1516.7,1417.3z M1515.6,1444.5
				c-4.2,8.7-0.6,15.4-1.6,21.9C1518.6,1460.1,1517.2,1453.5,1515.6,1444.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1626.7,1514.5c0.9-3.4,1.9-6.9,2.9-10.7c3.3,1.6,1.4,3.7,1.6,5.5
				c0.3,3.4,3.2,6,5.8,6.1c4.3,0.2,2-4,2.6-6.3c1.7-7.6-1.5-13-7.6-17.1c-0.4-3.4,2.9-5.1,4.5-6.1c6-4,3.8-9.5,2.4-13.8
				c-1.4-4.2-6.4-2.8-10.1-2.9c-2.8-5.2-4.2-10.5,4.5-7.4c3.5,1.3,9-6.8,8.8,0.5c-0.2,7.4,4.3,4.2,6.7,5.6c0,0,0,0.1,0,0.1
				c-2.6,4-9.8,8,0.2,11.9c0.3,0.1,0.4,1.6,0.1,2.3c-3.8,9.7-6.2,19.7-6.1,30.3c0.1,4.6-0.8,9.4,4.1,12.2c5.5,3.2,6.8-3.6,10.6-4.6
				c0,0,0.1,0.1,0.1,0.1c2.2,5.6-7,8.4-3.4,14.3l-0.4,0.4c-2.8-0.5-5.9-0.4-6.3,3.1c-0.3,2.8-0.1,6.6,1.5,8.5
				c2.5,2.9,4.1-2.1,6.6-2.3l0,0c-0.7,2.6,1.4,7.3-3.5,6.8c-5.1-0.6-11.1,3.5-15.4-1.7c-3.3-4,4.8-6.2,2.5-10.5l0,0
				c1.3-0.3,2.7-0.6,4-0.9c-1.3-0.4-2.6-0.7-4-1.1l0,0c-0.2-12.4-3.9-18.4-12.6-20.6C1626.9,1515.5,1626.8,1515,1626.7,1514.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1572.1,1481.4c0.5-2.7,0.9-5.5,1.4-8.2c0.3-12.1-0.3-24.2,1.5-36.1
				c2.3-0.7,2.6,0.5,3.6,2.4c1.6,3.3,4.2,3.5,6.7,0.3c1.2-1.6,2.5-4.3,5-2.6c2.5,1.6-0.4,3.9-0.2,5.9c0.1,0.9,0,1.8,0,2.8l0,0
				c-2.5,0-5,0.8-4.8,3.6c0.2,3,3.1,1.6,4.8,2l0,0c-1.7,8-4.9,15.5-4,24.1c0.7,7.5,0.2,15.2,0.2,24.2c-4.8-2.8-1.8-7.8-5.3-9.3
				c-0.1-0.2-0.1-0.5-0.3-0.6c-0.1-0.1-0.3-0.1-0.5-0.1c0.2,0.3,0.5,0.5,0.7,0.8c-0.7,4.5-1.6,9.1-1.9,13.6
				c-0.5,5.9-3.3,5.6-7.3,3.4C1574.7,1498.8,1574.5,1490.1,1572.1,1481.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1595.6,1429.2c2.4-0.1,5.8,2.7,7-1.4c2.1-7.4,9.4-9.1,13.9-13.8
				c6,8.3,3.2,17.6,1.8,26.1c-0.7,4,0.2,5.6,2.5,7.7c-2.7,2.6-4.8-0.5-7,0c0,0,0.1,0,0.1,0c-0.6-0.7-1.2-1.3-1.8-2
				c-2.3-2.4-4.2-2.8-5.5,0.9c2.1,2,4.3,3.5,7.4,2.8c2.6,5.6,6.3,11.2-1.4,16.1c-4.9,3.1-9,5.2-9.6-3.4c-0.3-3.8-5.1-1.2-5.7-5.1
				c5.7,0,12.3,0.1,7.5-8.9c-0.4-0.8-0.8-2.3-1.2-2.3C1588.6,1446.2,1598.6,1434.5,1595.6,1429.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1550,1571.6c0.5-2.7,2.4-3.1,4.7-3.2c12.6-0.2,25.1-0.5,37.7-0.6
				c2.5,0,3.1-1.2,3.2-3.2l0,0c1.4,0,2.8,0,4.2,0c2.2,4.3-4.1,7.1-1.9,11.4c-1.7,7.9,0.1,15.9-0.3,23.9c-2.1,3.2-4.1,6.4-10.1,4.7
				c2.8-2.7,8.2-3.5,6.3-8.5c-1.6-3.5,4-9.5-3.6-11c0.2-1.5,0.4-3,0.6-4.5c0.5-4.2-3.5-5-5.5-7.2c-0.5-0.6-2.2,1.5-2.6,3.1
				c-1.7,0-3.3-0.1-5-0.1c-3.4-0.5-7-1.7-10,1.2c-1.1,0.1-2.1,0.1-3.2,0.2l0,0c-0.1-1.4-0.4-2.2-1.8-0.9c-3.3,1-3.2,3.7-3.2,6.4
				c0,5.4-0.1,10.7-0.1,16.1c-0.1,2.1-1,4.3,1,6c-1.7,4.1,3.5,7.1,1.6,11.2c-2.9-0.6-3.2,1.4-3.4,3.5c-0.9-0.6-1.9-1.1-2.8-1.7
				c-0.1-2.4-0.3-4.8-0.4-7.2c2.8-5.1-3.6-7.2-3.6-11.3c3.4-7.2,1.4-15.3,3.5-22.8C1556.2,1573.3,1553.5,1571.6,1550,1571.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1618,1509.5c2.7-0.3,4.9,0.1,4.9,3.6c0,2.5,0.1,4.9,0.1,7.4
				c0,10.6,0,10.6-10.1,7.5c-0.8-0.3-2.1-0.6-2.6-0.1c-1.8,1.6-2.9,4.9-5.6,0.5c-2.6-4.2,1.5-4.4,2.8-6.3c0.2-0.2,0.5-0.4,0.6-0.6
				c1.5-2.8,6.8-5.5,4.6-7.7c-3.8-3.7-4.5,3-7.1,4.4c-2.5,1.4-3.7,6.1-7.1,4.3c-2.3-1.3-1.1-5.1-1-7.8c0.2-4.7,1.1-9.4-0.9-14
				c-2.1-4.8,2.2-7.1,4.6-10c2.4,1.2,4.8,0.4,7.2-0.1c0,0-0.1-0.1-0.1-0.1c0,1.3,0.6,2,1.9,1.9l-0.1-0.1c0,1.3,0.6,2,1.9,1.9
				c0,0-0.1,0-0.1,0c0,0.6,0,1.3,0,1.9c-5.3,4.9,0.7,5,2.7,5.3c6.2,0.8,4.8,3.7,2.5,7.3c-0.3,0.4-1,1-0.9,1.2
				C1617,1510.9,1617.5,1510,1618,1509.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1628.8,1469.2c3.7,0.1,8.7-1.4,10.1,2.9c1.4,4.3,3.6,9.8-2.4,13.8
				c-1.6,1.1-4.9,2.7-4.5,6.1c-4.9-1.6-5.9,4.4-10.1,5.2c-2.8,0.6-4.2,0.1-4.8-1.6c-1.1-3.3,1.8-3.1,3.8-3.3
				c0.2,0.2,0.5,0.4,0.8,0.5c0.2,0.1,0.6,0,0.7-0.1c1.6-5.7,0-11.4,0.5-17.2c0-1.9,0-3.8,0-5.6l0,0
				C1624.9,1469.7,1626.9,1469.5,1628.8,1469.2z M1628.6,1488.7c-0.5-4.3,1.2-6.9-1.9-10.1C1626.9,1482,1625.7,1484.6,1628.6,1488.7
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1549.8,1473.7c4.8-4-0.8-11.3,6.3-16c-1.8,9.5,10.1,15.8,1.6,26.9
				c-3.5,4.6-1.2,14,1.3,21.2c-1.1,0.1-2.2,0.2-3.3,0.3c0-8.2,0-16.3,0-24.5c-0.9,0-1.7,0.1-2.6,0.1c-4.2,7.6-3.4,15.4,0.9,23.4
				C1542.6,1495.5,1552.1,1484,1549.8,1473.7L1549.8,1473.7z M1555.2,1467.2c-0.9,0.8-1.6,1.2-1.6,1.5c0,1.1,0.3,2.3,0.5,3.4
				c0.4-0.3,1-0.5,1-0.9C1555.3,1470.1,1555.2,1469,1555.2,1467.2z M1553,1476.5l-0.4-0.3l-0.1,0.5L1553,1476.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1571.4,1564.6c-2.6,0.6-5.3,1.2-7.9,1.7c-5.5,0.9-9.1-0.5-8-7.2
				c1-5.8-6.8-8.4-5.2-14.5c0.4-1.7-0.2-3.6-0.3-5.6c3-0.4,2.4,4,4.9,3.8c2.3-7.6-2.1-15.6,2.3-22.7c1.2,2.5,2.5,4.9,3.8,7.4
				c-4.6,7.6-1.6,16-1.3,23.8c0.2,4.6,6.6,1.5,10.1,2.3c0.2,0.6,0.2,1.1,0,1.7c-1.8,0-3.6,0.1-5.4,0.1c-3.6-0.2-5.9,1-5.5,5.2
				c0.4,4.6,2.7,4.6,6.3,3.1c1.8-0.8,4.1-0.4,6.2-0.5C1571.5,1563.6,1571.5,1564.1,1571.4,1564.6z M1555,1548.3
				c-0.2-0.5-0.4-0.9-0.6-1.4c-0.2,0.2-0.6,0.4-0.6,0.5c0.1,0.5,0.4,0.9,0.6,1.4C1554.6,1548.7,1554.8,1548.5,1555,1548.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1644.6,1637c-4.2-9.4-11.9-3.4-18.1-4.2c-2.5-2.9-5.8-0.9-8.7-1.5
				c-0.2-4.3,3-8.4,0.6-12.8c0.8,0.1,1.6,0.2,2.3,0.3c0.7-0.1,1.4-0.2,2.1-0.2c0,0,0,0,0,0c4.3,0.7,7.5,9.8,12.9,1.8
				c1.4-0.6,2.8-1.2,4.2-1.8c0,0.9,0,1.9,0,2.8c-0.8,4.2,2.3,5.1,5,6.5C1644.9,1630.9,1644.8,1633.9,1644.6,1637z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1555.3,1611.2c0.2,2.4,0.3,4.8,0.4,7.2c-1.4,19.4-0.1,20.7,17.7,19
				c3.1,0.3,6.2,0.5,9.2,0.8c0.2,1.5,0.4,2.9,0.7,4.4c-10.7-3.3-21.9-1-32.7-2.5C1551.8,1630.4,1550.3,1620.3,1555.3,1611.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1536.9,1434.7c-0.4,9.9,1.7,20-1.4,29.8c-3.3-4.4-0.3-10.4-3.7-14.8
				c1.3,5.5-4.3,12.6,3.4,16.7c0,1.8-0.1,3.6-0.1,5.3c0,0-0.9,0-0.9,0l-0.9,0.1l0,0c-3-2.4-4.2-5.6-3.8-9.4c0-5.5,0.1-11.1,0.1-16.6
				C1529.6,1440.4,1530.3,1435.5,1536.9,1434.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1543.9,1640.1c-3.5,0.6-7,0.4-10.5-0.5c-0.7-5.1,2.8-10.5-1.6-16
				c3.3,6.9,9.4-3.5,12.3,3.1C1546.2,1631.2,1543.6,1635.6,1543.9,1640.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1640.1,1621.4c0-0.9,0-1.9,0-2.8c1-0.7,2.1-1.3,3.1-2c7.9,1,16.4-1.9,23.5,4
				c1.6,2.2,1,3.2-1.6,2.9C1656.7,1622.8,1648.4,1622.1,1640.1,1621.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1531.9,1551.2c-6.3,2.6-7.6-2.7-6.6-6c2-6.2,2.4-12.3,2.6-18.6
				c0.2-7.7,3.4,0.8,4.7-0.8c0.1,3.7,0.2,7.4,0.3,11.1C1530.5,1541.5,1532.3,1546.4,1531.9,1551.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1543.9,1574.9c1.2,7.9-1.1,14.3-2.3,20.9c-0.5,2.6-2.2,4.7-5.1,3.9
				c-3.9-1.1-1.6-4.3-0.9-5.8C1538.3,1587.8,1538.4,1580.6,1543.9,1574.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1626.7,1514.5c0.1,0.5,0.2,1,0.3,1.5c0.5,3.3,1.7,6.8,1.2,10
				c-1.2,9.4,2.7,12.4,11.3,10.6c0,0,0,0,0,0c0,0.7-0.1,1.3-0.1,2l0,0c-9.4-2.8-8.3,6.6-12.6,10.9
				C1629.7,1537.6,1622.8,1526.2,1626.7,1514.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1553.5,1529.6c-0.1,2.7-0.3,4.8-2.7,4.8c-2.5,0-2-2.3-2.7-4
				c-1.8-4.6,4.3-11.9-4.8-14.1c-1.3-0.3-0.5-2.8,1.2-3.3c3.4-0.9,7.3,0.9,7.2,3.6C1551.8,1521.3,1552.1,1525.8,1553.5,1529.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1575,1437.1c-1.8,12-1.3,24.1-1.5,36.1c-3.8-10.7-4.9-21.5-2.4-32.6
				C1571.5,1438.7,1571.8,1436.1,1575,1437.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1561,1527.4c-1.3-2.5-2.5-4.9-3.8-7.4c1.1-5.9,5.9-6.9,9.3-4.5
				c3.2,2.2,2.1,7.4,0.8,11.1C1566,1530.9,1562.6,1521.5,1561,1527.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1622.9,1618.6c-0.7,0.1-1.4,0.2-2.1,0.2c-0.6-1.7-1.9-2-3.4-2.1l0.1,0.1
				c-1.6-3.1-4.3-3.9-7.4-3.8l0,0.2c-0.6-3.2-1.2-6.5-1.8-9.7c0,0-0.2,0-0.2,0c5.3-0.5,10.4-1,9.9,7.2
				C1617.8,1614.1,1624,1614.1,1622.9,1618.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1569.9,1555.3c0.2-0.6,0.2-1.2,0-1.7c4.2-3.2,8.7-1.4,9.3,2.5
				c0.6,3.9-2.2,8.4-7.7,8.6c0.1-0.5,0.1-1,0.1-1.5C1570.9,1560.5,1570.4,1557.9,1569.9,1555.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1524.5,1618.4c0.9-8-2.9-15.6-1.7-23.5
				C1528.6,1601.2,1529.4,1612.6,1524.5,1618.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1673.2,1555.4c-0.3,1.2-0.7,2.4-1,3.6c-3.1,0.4-6.8-1.5-8.8,2.5
				c-1,2-2.1,3.1-3.9,0.9c-1.1-1.4-3.2-2.1-3-4.3c0.2-2.6,2.3-2.5,4-2.5C1664.7,1555.5,1668.9,1555.5,1673.2,1555.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1798.1,1116c-0.8,4.4,2.2,9.3-1.5,13.3c-0.7-3-1.4-6-5.5-5.6
				c0,0,0.1,0.1,0.1,0.1C1790.2,1118.2,1791.7,1114.9,1798.1,1116z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1635.9,1620.4c-5.5,8-8.6-1.1-12.9-1.8
				C1627.9,1614.6,1632.6,1612.1,1635.9,1620.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1544.3,1602.8c-2.9,3.7-5.7,6-6.3,11c-3.6-3.8-5.2-7.7-3.8-10.4
				C1535.9,1600.2,1539.9,1602,1544.3,1602.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1533,1536.9c-0.1-3.7-0.2-7.4-0.3-11.1c0.4-5.3,0.9-10.6,1.3-16
				c6.4,8.9-1.2,17.7,0.8,26.6C1534.2,1536.6,1533.6,1536.7,1533,1536.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1572.1,1481.4c2.4,8.7,2.6,17.4-0.4,26c-5-2.7-0.2-7.7-2.6-11
				C1574.8,1492.4,1570.6,1486.3,1572.1,1481.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1533.4,1471.8c0,5.7,0,11.4,0,17.2c-0.9,0-1.8-0.1-2.7-0.1
				C1530.9,1483.1,1526.1,1477.1,1533.4,1471.8L1533.4,1471.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1535.9,1562.5c6.8,3.7,6.8,3.7,0,14.5
				C1535.9,1571.6,1535.9,1567.4,1535.9,1562.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1622.9,1475.6c-0.5,5.7,1.1,11.5-0.5,17.2c0,0.1-0.5,0.2-0.7,0.1
				c-0.3-0.1-0.5-0.3-0.8-0.5c0.3-3,0.9-5.9-1.3-8.7C1616,1479.2,1617.7,1476.6,1622.9,1475.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1535,1657.8c-6.7,2.5-4.2-3.1-5-5.9c3.5-2.9-5.4-9.6,3.2-10.9
				c0.2,1.3,0.4,2.5,0.6,3.8C1535,1649,1531.4,1653.7,1535,1657.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1529.8,1445.8c0,5.5-0.1,11.1-0.1,16.6c-0.5,0.1-1,0-1.5-0.2
				c0-2.9-0.4-5.7-3.5-7C1524.7,1451.1,1524.3,1446.8,1529.8,1445.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1597.4,1473.7c2.5-0.4,5-0.8,8.1-1.2c-0.2,3.9-3.6,7-2.6,11
				c-1.2-0.2-2.4-0.4-3.6-0.5l0,0c-0.6-0.9-1.2-1.8-1.9-2.6C1597.4,1478.1,1597.4,1475.9,1597.4,1473.7L1597.4,1473.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1570,1399.4c2.1,0.8,3-1.4,5.4-2.1c0,4.5,0,8.8,0,13
				c-3.4-1.1-5.3-3.7-6.8-6.8C1570.1,1402.5,1570.2,1401,1570,1399.4L1570,1399.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1599.3,1482.9c1.2,0.2,2.4,0.4,3.6,0.5c1.8,2.4,3.7,4.7,5.5,7.1
				c-2.4,0.5-4.8,1.3-7.2,0.1C1598.3,1488.5,1599.3,1485.6,1599.3,1482.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1648.7,1462.6c4.5-0.8,6.9,2.1,9,5.5c-3,3-5.9,3.2-8.8-0.1c0,0,0-0.1,0-0.1
				C1650.3,1466.1,1650,1464.3,1648.7,1462.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1533,1536.9c0.6-0.1,1.2-0.3,1.8-0.4c-0.1,5.1,2.5,10.8-2.9,14.8
				C1532.3,1546.4,1530.5,1541.5,1533,1536.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1575.7,1425.6c0.1-2.6,0.2-5.2,0.4-7.8c4.4,0.9,5.8,3.9,4.5,7.9
				C1579.1,1430.1,1577.3,1426.7,1575.7,1425.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1595.6,1564.6c-1.3-0.3-4.3-0.3-3.6-1.2c4-5.3,0.6-11.3,3.6-16.7
				C1595.5,1552.7,1595.5,1558.7,1595.6,1564.6C1595.5,1564.7,1595.6,1564.6,1595.6,1564.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1569.9,1399.4c0.3,1.6,0.2,3.1-1.3,4.1c-1.5-0.1-3-0.1-4.5-0.2
				c0,0,0.2-0.1,0.2-0.1c0-1.3,0.1-2.6,0.1-3.9C1566.3,1399.4,1568.1,1399.4,1569.9,1399.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1623,1470c-1.6,0-3,2.4-4.6,0.4c-0.2-0.2,0-1.3,0.3-1.5
				C1620.4,1467.6,1621.8,1468.3,1623,1470L1623,1470z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1613.9,1449.5c-3.1,0.7-5.3-0.8-7.4-2.8c1.1-0.1,2.2-0.3,3.3-0.4
				c0.8,0.4,1.5,0.7,2.3,1.1c0.5,0.3,1.1,0.4,1.7,0.4c0,0-0.1,0-0.1,0C1613.8,1448.3,1613.9,1448.9,1613.9,1449.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1520.9,1464.1c0.4-4.9,3.4-1,5.1-1.6
				C1524.8,1464.6,1522.8,1464.3,1520.9,1464.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1590.1,1445.8c2.5,1.8,1.8,3.7,0,5.6l0,0
				C1590.1,1449.5,1590.1,1447.7,1590.1,1445.8L1590.1,1445.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1584.1,1505.3c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1-0.2-0.3-0.5-0.3-0.7
				c0-0.2,0.3-0.4,0.4-0.6C1583.9,1504.8,1584,1505,1584.1,1505.3z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#9C999E" points="1591.3,1461.7 1590.8,1461.8 1590.9,1461.4 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1549.9,1473.7c-0.2-0.1-0.4-0.2-0.6-0.4
				C1549.4,1473.4,1549.6,1473.6,1549.9,1473.7L1549.9,1473.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1597.4,1473.7c-0.2-0.1-0.5-0.3-0.7-0.4
				C1596.9,1473.4,1597.1,1473.5,1597.4,1473.7L1597.4,1473.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1597.4,1480.3c0.6,0.9,1.2,1.8,1.9,2.6
				C1598.7,1482,1598.1,1481.2,1597.4,1480.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1610.2,1492.3c-1.3,0-1.9-0.6-1.9-1.9
				C1608.9,1491,1609.5,1491.7,1610.2,1492.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1612,1494.2c-1.3,0-1.9-0.6-1.9-1.9
				C1610.7,1492.9,1611.4,1493.5,1612,1494.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1611.9,1496.1c0-0.6,0-1.3,0-1.9C1613.6,1494.8,1613.3,1495.4,1611.9,1496.1
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2331.6,1590.7c-1,3-5.6,0.3-5.9,3.4c-0.2,1.9,2.3,2.1,4.1,2.2l-0.1-0.1
				c-8.3,5-17.1,3.7-26.2,1.9c10.4,1.2,17.5-9.5,28.2-7.4C2331.7,1590.8,2331.6,1590.7,2331.6,1590.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2329.8,1596.3c1.6-1.8,4.1-2.8,4.8-0.1c1.1,4.3-1.3,1.1-2.4,0.7
				c-0.8-0.3-1.7-0.5-2.5-0.7L2329.8,1596.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1648.8,1468c2.9,3.2,5.9,3.1,8.8,0.1c1.3,1.9,2.5,3.7,3.8,5.6
				c-1.3,1.7-2.6,3.4-3.9,5.1c-1.5,1.9-1.1,4.2,1.1,3.9c2.6-0.3,2.2,7.5,6,2c1.8,10.8,2.6,21.4-5.3,30.5c-1,1.2-1.1,3.3-1.6,5
				c-3.8,1-5.1,7.8-10.6,4.6c-4.9-2.8-4.1-7.7-4.1-12.2c-0.1-10.6,2.3-20.5,6.1-30.3c0.3-0.7,0.2-2.2-0.1-2.3
				C1639,1476,1646.3,1472,1648.8,1468z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1637.7,1594.2c7.5-2.9,15,1.4,22.5,0c4-0.8,8.4,1.2,12.1-1.9
				c2.3,1.2,4.4,2.6,3.5,5.6c-0.8,2.8-3.4,2.1-5.4,2.1c-4.8,0.1-9.5,0-14.9,0c3.5,4.6,7.7,3.1,11.5,3.5c3.7,0.4,8.3-1.6,10.5,3.2
				c1.2,2.6-0.4,4.8-1.9,6.8c-3.7,4.9-6.9-0.8-10.5-0.7l0,0c-0.1-2.6,0-5.4-3.7-5.6c-0.2-0.3-0.5-0.5-0.7-0.8c0,0.2,0,0.4,0.1,0.5
				c0.2,0.1,0.4,0.2,0.7,0.3c-0.3,3-2.9,5.6-4.4,2.8C1652.7,1601.8,1643.4,1600.3,1637.7,1594.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1654.5,1534.5c-3.6-5.9,5.6-8.7,3.4-14.3c6.1-4.4,10-2.7,10.6,5
				c0.3,3,0.3,6.1,0.2,9.2c0,1.8,3.1,4.1-0.3,5.3c-1.4,0.5-3.5-0.4-5.1-1c-2.8-1.1-1.9-3.3-1.2-5.4c0.3-0.8,0.4-1.6,0.8-3.3
				C1659.2,1530.4,1658.9,1537.2,1654.5,1534.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1670.5,1626.2c0.1,2.2-1,3.3-3,3.6c-7.2-3.6-14.6-5.3-22.4-1.9
				c-2.7-1.3-5.9-2.3-5-6.5c8.3,0.7,16.6,1.4,24.9,2.2c2.7,0.2,3.2-0.7,1.6-2.9c2.9-3.8,4.1,0.2,5.5,1.5
				C1674.3,1624.1,1670.4,1624.5,1670.5,1626.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1655.9,1544.2c-2.5,0.2-4.1,5.2-6.6,2.3c-1.6-1.9-1.8-5.7-1.5-8.5
				c0.4-3.5,3.4-3.5,6.3-3.1C1656.1,1537.7,1656.1,1541,1655.9,1544.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1664.6,1484.6c-3.7,5.5-3.3-2.3-6-2c-2.3,0.3-2.6-2-1.1-3.9
				c1.3-1.7,2.6-3.4,3.9-5.1C1664.7,1476.6,1665.5,1480.4,1664.6,1484.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1637.4,1434.5c0-0.6,0-1.3,0.1-1.9c6.9,2,0.3,5.3,0.6,8
				C1637.8,1438.6,1637.6,1436.5,1637.4,1434.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1635.8,1592.6c1.2-0.1,1.7,0.6,1.9,1.7
				C1637.1,1593.7,1636.5,1593.2,1635.8,1592.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1789.3,1157.2c-0.2,0.4-0.5,0.8-0.7,1.2
				C1787.5,1157.1,1789,1157.5,1789.3,1157.2L1789.3,1157.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2582,1445.1c-2.8,1-3.2,2.7-2.5,5.6c0.4,1.8,1.3,4.4-0.6,6.2
				c-0.4,0-0.8,0-1.2,0c-3.5-6.8-3.7-7.1-9.4-3.9c-3.7,2.1-7.1,4.3-11,6.1c-1.9,0.9-2.3,5.3-3.3,8.1c-4.7-0.5-9.5-1-14.2-1.5
				c-4.1-0.3-2.7-4.6-4.7-7.3c5.9,0,11.7,0,17.6,0c0.3,0.2,0.5,0.4,0.8,0.7c-0.2-0.3-0.4-0.5-0.7-0.8c-1.1-6.6-7.5-3.2-10.9-5.7
				c-1.3-1-3.7-0.4-6.6-0.6c5.1-4.2,10-1.7,14.5-2.5l0,0c1,1.6,2,1.7,3,0l0,0c0.9,0,1.7,0.1,2.6,0.1c0,1.7,0.7,3.5,2.4,2.6
				c5.4-3.2,12.2-2.6,17-7.1c4.6-1.1,2.1-4.9,2.8-7.5c-0.2-1.4-0.3-2.8-0.5-4.3C2586.6,1435.6,2586.9,1436.3,2582,1445.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2554.4,1470.3c2.8,3.8-1.6,4.3-2.8,6.3c-3.9-1.5-7.5-4-12.1-3.3
				c-1.6,0.2-3.9,0.3-3.9-2.1c0-2.8,2.7-3.7,4.7-3.5C2545,1468.2,2549.7,1469.4,2554.4,1470.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2549.8,1449.6c-3.9-2.1-8-3.9-11.6-7.6c5.5-2.2,10.2-2.3,14.3,2.6
				c-1.1,0.3-2.5,0.6-4.7,1c2,1.3,5.2,0.9,4.8,3.9l0,0C2551.8,1449.6,2550.8,1449.6,2549.8,1449.6L2549.8,1449.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2529.4,1423c-1.1,3.6-5.6,3.3-7.3,6.2c0.1-4.9-1.5-10.6,5.9-12.2
				c1.2-0.3,0-2.1-0.3-3.2l0,0C2534.6,1415.7,2527.7,1420.2,2529.4,1423z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2553.5,1433.6c0.7,1.5,4.3,1.3,2.3,4.3c-1-0.1-2.1-0.1-3.1-0.2l0.1,0.1
				c-4.7-4.2-10.7-2.4-17.9-3.5C2542.2,1430.6,2547.9,1434.5,2553.5,1433.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M2523.6,1406.5c1.5,0.5,3,1,4.5,1.5c-0.1,1.9-0.2,3.9-0.4,5.8l0,0
				c-1.8-0.4-3.7-0.9-5.5-1.3c-0.1-1.4-0.3-2.9-0.4-4.3C2522.3,1407.6,2522.9,1407,2523.6,1406.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2554.1,1427.4c1.7,0.9,1.9,2,0.3,3.2c-5-3.2-10.8-3.2-16.4-4
				C2543.5,1425.5,2548.9,1425.6,2554.1,1427.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2611.4,1429.4c3.6-0.6,5.7-0.9,8.5,0.8
				C2616.9,1432.6,2614.8,1431.4,2611.4,1429.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2537.3,1438.5c2.3-2.8,4.5-2.4,7.2-1.7
				C2542.3,1438.2,2540.4,1439.3,2537.3,1438.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2620,1437.7c-2.8,0-5.7,0-8.5,0C2614.4,1436.6,2617.3,1435.1,2620,1437.7
				C2620,1437.8,2620,1437.7,2620,1437.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2619.3,1433.1c-2.2,2.4-3.7,1.1-6,1.8
				C2615.5,1432.6,2617,1433.8,2619.3,1433.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2552.7,1437.7c-0.1,1.1-0.1,2.5-1.8,1.9c-0.5-0.2-0.9-0.9-1.3-1.3
				C2550.6,1438.1,2551.7,1437.9,2552.7,1437.7L2552.7,1437.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2383.2,1642.4c0.1,4.5-3.6,3.8-6.2,4.6c-1.5-0.4-2.9-0.8-4.4-1.2
				c0.3-1.1,0.6-2.2,1-3.3c0.9-1.2,1.9-2.4,2.8-3.7c0.7-0.5,1.5-1,2.2-1.6C2380.1,1639,2381.6,1640.7,2383.2,1642.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2589.4,1492.7c-6.3,1.5-5,6.4-3.1,9.7c2.1,3.7,1.1,7.1,1.5,10.7
				c0.1,1.5-0.1,3.1-2.1,3.1c-1.4-0.1-2.2-1.1-2.3-2.4c-0.5-7.6-1-15.2-1.3-22.8c-0.1-2.3,1.5-2.9,3.4-2.8c2,0.2,4.7-1.2,6,1.2
				C2592.3,1490.9,2589.6,1491.2,2589.4,1492.7C2589.3,1492.7,2589.4,1492.7,2589.4,1492.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2630,1452.1c-0.2-0.3-0.4-0.7-0.7-1c0.5-0.3,1-0.5,1.4-0.8
				c0.1,0.4,0.5,0.9,0.3,1.2C2631,1451.8,2630.4,1451.9,2630,1452.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2618.5,1451.1c1.1,0.2,2.2,0.4,3.2,0.6c-0.1,0.2-0.2,0.5-0.4,0.6
				C2620.1,1452.6,2619.2,1452.3,2618.5,1451.1C2618.5,1451.1,2618.5,1451.1,2618.5,1451.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2622.2,1467.3c-0.4-0.3-0.8-0.5-1.2-0.7c0.4-0.2,0.8-0.6,1.2-0.6
				c0.4,0,0.8,0.4,1.1,0.7C2623,1466.9,2622.6,1467.1,2622.2,1467.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1635.8,1664c12.2-2.2,24.4-0.8,36.6,0.2c2.7,0.2,5.3,0.8,8,0
				c0.8,0,1.6,0,2.5,0c0.9,0.9,1.8,1.8,2.6,2.8c-5.6,3.5-11.8,1.8-17.8,1.8c-11.2,0-22.5-0.2-33.7-0.4
				C1634.5,1666.9,1635.2,1665.4,1635.8,1664z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1567.8,1666.5c-11.4,1.3-20.7-4.7-30.5-8.8c3.5-0.2,7.1-0.4,10.6-0.5
				c2,0,3.9,0,5.9,0.1c1.7,0,3.4,0,5.1,0.1c4.8,0.1,9.6,0.1,14.3,0.2c0.8,0.7,1.5,1.3,2.3,2l-0.2-0.1
				C1576.1,1665.3,1570.1,1663.9,1567.8,1666.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1635.8,1664c-0.6,1.5-1.3,2.9-1.9,4.4c-13.8-2.6-28-0.1-41.8-3.3
				c3.7-0.2,7-2.5,11.3-0.5c5.3,2.5,8.2-3.7,12.4-5.4c2.3,0.2,4.6,0.4,6.9,0.6C1625.7,1665.6,1631.2,1663.3,1635.8,1664z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1641.6,1004.1c-3.3-0.2-7.1,1.1-9.1-3
				C1635.9,996.1,1636.3,996.2,1641.6,1004.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1645.9,1002.5c0.2-0.3,0.4-0.5,0.7-0.8c0,0.2,0,0.4-0.1,0.5
				C1646.3,1002.3,1646,1002.4,1645.9,1002.5C1645.8,1002.4,1645.9,1002.5,1645.9,1002.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1608.1,1602.4c0.7,3.1,1.3,6.2,2,9.4c-10.4-2.5-13.6-0.5-13.5,9.1
				c0,4.6,0.7,9.2,1,13.8c-1.1,1.1-2.3,2.3-3.4,3.4c-7.2-4.4-0.5-13.5-8.3-19c0,7.6,0,14.2,0,20.9c-1.7,0-3.3,0.1-5,0.1
				c-0.2-1.4-0.5-2.8-0.7-4.2c0-3.3,0.1-6.5,0.1-9.8c-0.8-3.5,3.1-8.8-2.7-10.2c-3.3-0.8-5.9,2.7-5.5,6.5c-5.4,0.5-7.4,2.8-3.7,6.9
				c1.7,1.9,1.9,3.7,1.7,5.8c-19.3,1.7-20.7,0.3-19.2-18.3c1,0.5,2.1,1.1,3.1,1.6c0.3,2.6,0.6,5.2,0.8,7.6
				c10.9-3.3,14.6-12.1,19.5-19.9l0,0c0.4,1.3,0.9,3.9,2.6,2.5c2.3-1.8,4.7-6.5,2.5-7.7c-6.7-3.9-3.5-9.7-5.1-14.5
				c10.2,0.5,3.6-6.8,5.8-10.1c0.4-1.5,2.2-3.6,2.8-3c2.1,2.2,6.5,2.9,5.9,7c-0.2,1.4-0.4,2.9-0.6,4.3c-2.4,4.6-3.8,9,3.9,10.6
				c2,4.8-3.8,5.6-6.9,8.2c6.5,1.7,8.7-1.5,11-4.5C1600,1600.9,1603.5,1603.2,1608.1,1602.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1587.9,1642.5c0-6.9,0-13.8,0-21.7c7.2,5.7,1,15.1,7.6,19.7
				c2.5,0.1,5,0.2,7.4,0.3l-0.1-0.1C1598.1,1643.4,1593.2,1644.7,1587.9,1642.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1617.9,1640.8c0-3.1,0-6.3-0.1-9.4c2.9,0.6,6.2-1.4,8.7,1.5
				c-1.1,4.1,4,3.6,4.9,6.8C1626.8,1640,1622.4,1640.4,1617.9,1640.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1669,1645.9c0.5,0.2,1,0.1,1.5-0.1c0.4,2,1.8,4.5-2,4.4
				c-6.2-0.1-12.5,1-20.6-1.6C1655.8,1644.6,1662.8,1647.6,1669,1645.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1601.9,1652.3c-0.1-2,1.8-2.6,2.9-3c2.4-0.8,4.5-3.6,7.4-1.8
				c0.5,0.3,0.9,1.8,0.7,2.5c-1.4,3.1-4.2,1.2-6.4,1.6C1605,1651.9,1603.8,1654.6,1601.9,1652.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1558.8,1657.3c-1.7,0-3.4,0-5.1-0.1c1.3-2,1.6-5.8,5.1-4.6
				C1560.7,1653.3,1558.4,1655.7,1558.8,1657.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1575.4,1659.4c-0.8-0.7-1.5-1.3-2.3-2
				C1573.9,1658.1,1574.7,1658.8,1575.4,1659.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2505.9,1435.5c-2-2.4-5.8,3.2-7.2-1.7c0-2.4-0.2-4.8-3.3-5.2
				c-2.5-2.1-1.4-3.8,0.4-5.6c3-0.2,2.9-2.4,2.8-4.5l0,0c0.8-0.4,1.7-0.9,2.5-1.3c-0.2,3.9,1.5,6.9,4.8,8.8
				C2505.9,1429.1,2505.9,1432.3,2505.9,1435.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2495.7,1421.5c-0.8-0.5-2.3-1.3-2.2-1.6c2-7.2-4.4-7-7.9-9.3
				c6-1.2,12-2.4,18.3-3.7c-0.6,4.9-5,7.2-5.2,11.5l0,0C2496.6,1418.4,2495.9,1419.6,2495.7,1421.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1109.4,1032.3c0.4-1.5-0.1-3.2,1.3-4.4c3.5,3.8,7.6,3.4,11.9,1.8
				c0.4,1.4,1.3,3,0.9,4.2c-0.6,1.9-0.1,4.8-2.7,5.5c-2.3,0.7-3.3-1.6-4.7-2.8C1114,1034.8,1113.4,1030.7,1109.4,1032.3
				L1109.4,1032.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2510.4,1425.9c2.4-0.9,4.8-1.8,7.2-2.6c-0.1,2.4,3.3,4.6,0.2,7
				c-4.5-3.2-1.7,4.5-4.9,3.3C2513.9,1430.4,2509.1,1429.1,2510.4,1425.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1106.5,1044c2.9,0,5.8,0,8.2,1.8c2.5,2,3,3.9-1,4.5
				C1110.3,1049.3,1107.7,1047.5,1106.5,1044z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1106.5,1044c1.2,3.5,3.8,5.3,7.1,6.3c-2.3,1.8-5.1,3.9-7.5,1.6
				C1104.2,1049.9,1104.5,1046.6,1106.5,1044z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1109.4,1032.2c0,0.2,0,0.5,0.1,0.7c1.7,2.8,1.7,5-1.8,6.2
				c-2,0.6-2.6-0.6-2.7-2.3C1104.7,1033.6,1105.5,1031.5,1109.4,1032.2C1109.4,1032.3,1109.4,1032.2,1109.4,1032.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2502.4,1467.4c-1.3-1.3-3.9-2.6-3-4.5c1.2-2.7,4.3-0.9,6.6-1.4
				C2506.5,1464.5,2503.9,1465.6,2502.4,1467.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M2511.5,1461.3c1.1,0.3,2.6,0.1,2.7,1.7c0.2,1.7-1.2,2.4-2.5,3
				c0-0.6,0.1-1.1,0.1-1.7c0-0.9-0.1-1.9-0.1-2.8L2511.5,1461.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M2511.7,1461.4c0,0.9,0.1,1.9,0.1,2.8
				C2509.9,1463.3,2509.9,1462.4,2511.7,1461.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1519.4,1661.7c0.1-0.4,0.2-0.8,0.3-1.3c0,0.4,0,0.9,0,1.3
				C1519.7,1661.7,1519.4,1661.7,1519.4,1661.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#FEFEFE" d="M994,916c-0.1,1.5-0.4,2.9-2.3,2.8c-1.2,0-1.7-0.9-1.4-1.9
				c0.4-1.3,0.6-3,2.4-3C993.8,913.9,993.8,915,994,916z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2495.7,1421.5c0.1-1.8,0.9-3.1,2.9-3c0.1,2.1,0.2,4.3-2.8,4.5
				C2495.8,1422.5,2495.8,1422,2495.7,1421.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2584.9,1430.4c0.1-0.2,0.2-0.4,0.3-0.6
				C2585.1,1429.9,2585,1430.1,2584.9,1430.4L2584.9,1430.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2584.9,1430.3c-0.1,0.2-0.2,0.4-0.3,0.6
				C2584.7,1430.7,2584.8,1430.5,2584.9,1430.3L2584.9,1430.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2677.4,1450.4c-0.4,0.2-0.8,0.6-1.2,0.6c-0.4,0-0.8-0.3-1.1-0.5
				c0.4-0.2,0.7-0.6,1.1-0.6C2676.6,1449.8,2677,1450.2,2677.4,1450.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1230.5,1694.8c-1.4-45.4-1.4-89.6,3.8-133.7c0,42.6,0,85.2,0,127.9
				c1,0,2,0,3,0c-1.3-50.9,1.2-101.8,2.1-154.3c4.2,3.9,3,7.4,3,10.4c0.1,43.6,0,87.2,0,130.9c0,7.6,0.8,15,5.8,23.5
				c-5.6-55.8,2.4-109.4-0.6-164.5c4.9,2.6,4,5.3,4,7.5c0,43.9,0.1,87.9-0.2,131.8c0,7.3-1.5,14.6,2.3,21.5c1.8,3.2,0.3,4.5-2.9,4.5
				c-11.8,0-23.8,1.1-35.3-2.3c-7.7-2.3-9.3-10.3-9.2-16.7c0.3-29.3-1.9-58.7,1.2-88c1-9.2,0.2-18.5,0.4-27.8
				c0.1-2.9-1.1-6.1,3.4-11c0,46.6,0,90.8,0,135.1c0.7,0,1.4,0,2-0.1c0-25.5-1.5-51.1,0.4-76.4c1.9-25.3-0.5-50.8,2.5-76
				c1,0,2.1,0,3.1,0.1c0,51.3,0,102.6,0,153.9c0.9,0,1.7,0,2.6-0.1c-2.6-19-0.7-38-1.4-57c-0.7-19,2.2-37.8,2.1-59
				c4.2,4.8,3.2,8.2,3.2,11.3c0.1,31.2,0,62.5,0,93.7C1226,1684.8,1225.8,1690,1230.5,1694.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1300.1,1533.2c5,3.2,4.6,7,4.6,10.7c0,36.2,0.5,72.4-0.4,108.6
				c-0.3,12.6-1.9,25.1,0.2,37.7c0.7,4,0.8,7.9,6.6,12.6c-6.2-16.7-5.1-31.6-4.2-46.3c2.4-38.3,0.9-76.6,1.6-114.9
				c0-2.6-2.1-7.5,2.6-7.3c3.6,0.2,2.7,4.9,2.7,7.9c0,44.2-0.1,88.5,0,132.7c0,8.3-1,16.6,1.6,24.8c0.5,1.6,2.3,6.8-1,5.5
				c-4.1-1.7-4.5,11.5-8.7,1.6c-0.5-1.3-2.6-1.3-3.2-0.4c-7.9,10.7-5.7,0.9-7.1-3.3c-9.3-29-4.5-58.6-3.7-88
				c0.6-23.2,0.2-46.4,0.3-69.6c0-3.1-1-6.6,1.8-10.2c2.7,3.1,1.9,6.2,1.9,9c0,43.6,0.1,87.2-0.2,130.8c0,8.9-3,18.1,4.6,27
				C1300.1,1645.4,1300.1,1589.9,1300.1,1533.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1076.8,1526.3c-4.1,2.4-0.9,6.5-4.2,9.2c0-34.4,0-68.2,0-102
				c-0.6,0-1.2,0-1.9,0c0,17.5,0.3,35-0.1,52.4c-0.4,17.2-1.5,34.4-4.1,51.5c1.4-45.5-0.1-90.9,0.7-137.3c5.8,2.4,4.3,6.5,6.4,8.8
				c2.2-5.9,7-4.3,10.8-3.5c4.1,0.9,2.6,5.1,2.6,7.9c0.1,21.6,1.2,43.3-0.4,64.9c-1.2,17-0.8,33.9-1.5,50.8
				c-0.1,2.3,2.4,6.3-3.4,6.5c-5.7,0.2-3.2-3.7-3.9-6.2C1077.5,1528.5,1077.2,1527.7,1076.8,1526.3z M1077.7,1440.9
				c-0.3-0.1-0.6-0.3-0.9-0.4c0,26.3,0,52.6,0,78.9c0.3,0,0.6,0,0.9,0C1077.7,1493.3,1077.7,1467.1,1077.7,1440.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1429.6,1616.8c-0.4,3.2-2.9,4.1-5.3,4.2c-11.9,0.2-22.7,4.2-33.5,8.7
				c-6.6,2.7-13.3,2.7-20.5,2.1c-7.9-0.6-16.1,0.9-24.1,2c-10.1,1.4-19.8,0.3-29.2-3.5c-2.9-3-0.3-5.9,1.2-7.6
				c1.9-2.3,2.1,1.8,3.7,2.3c4.3,1.5,9.3-1.1,13.3,2.2c0.3-0.4,0.7-0.7,0.8-1.2c2.3-13,13.4-8.7,20.1-8.7c23-0.2,46,0.5,68.9-1.9
				C1426.8,1615.2,1428.5,1614.9,1429.6,1616.8z M1327.5,1628.9c-0.8-0.1-1.5-0.3-2.1-0.3c-0.4,0-0.8,0.3-1.2,0.5
				c0.8,0.2,1.6,0.5,2.4,0.6C1326.8,1629.8,1327.1,1629.3,1327.5,1628.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1334.2,1698.1c-0.6-54-0.3-108.1,2.4-163.1c4.2,3.3,2.9,6.6,2.9,9.4
				c0,46.1,0,92.1-0.1,138.2c0,6.7-1.7,13.4,2.8,19.5c1.3,1.8,1.6,4.7-1.4,5.1c-3.7,0.5-8.1,2.1-10.7-2.4c-2.8-4.8-4.9-10-5-15.7
				c-1.4-49.4,2.3-98.8,1.7-148.2c0-2-0.6-5,2.3-5.3c3.8-0.3,3.1,3.1,3,5.3c-0.9,36.4,1,72.8-1.2,109.3c-1,15.9-4.1,32.6,2.9,48.3
				c0.2,1.5,0.4,2.9,1.9,3.6c0.3,0.8,0.6,1.6,0.9,2.4c0.2-0.2,0.7-0.5,0.6-0.5c-0.4-0.7-0.8-1.4-1.2-2.1
				C1335.4,1700.7,1334.8,1699.4,1334.2,1698.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1100.1,1508.4c0-20.7,0-40.6,0-60.5c-0.7,0-1.5,0-2.2,0
				c0,29.2,0,58.4,0,87.6c-5.1-7.2-2.4-15.2-4.2-24.5c-2.2,9.2,0.3,17.2-2.2,25.3c-3.5-2.4-2.4-5.4-2.5-7.9c0-35.5,0-71,0.1-106.6
				c0-2.4,0.4-4.9,0.7-7.3c0.6-4.5,2-9.2,7.4-8.6c5.6,0.7,8.2,6.2,7.7,10.9c-3,29-0.4,58-1.4,86.9
				C1103.4,1505.2,1103.8,1506.9,1100.1,1508.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1317.8,1534c5.6,2.6,5.1,6.5,5.1,10.1c0,41.1,0.6,82.1-0.5,123.1
				c-0.3,11.4,0.4,22.6,1.2,33.8c0.1,1.8,1.4,3.8-1.4,4.2c-1.9,0.3-2.4-1.3-2.7-2.9c-1.3-6.6-3.7-13-3.7-19.9
				c0.1-26.9-1.2-53.8,0.4-80.5C1317.6,1579.5,1316.6,1557.1,1317.8,1534z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1284.9,1534c5.3,17.2,3.2,32.8,2.6,48.2c-0.2,3.6-1,7.3-1,11
				c0,30.6,0.1,61.2-0.1,91.8c0,6.7,0.5,12.9,7.8,19.2c-5.8-0.5-8.3,1.4-9.7-5.6c-6.1-30.7-3-61.3-2-92c0.7-20.1,0.2-40.2,0.4-60.2
				C1282.9,1542.9,1282,1539.5,1284.9,1534z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1259.3,1536.2c0.7,49.3,3.5,98.6-2.3,147.8
				C1255.9,1634.8,1254,1585.5,1259.3,1536.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2714,1809.6c0,20.1,0.1,40.1-0.1,60.2c0,2.4,1.7,6.7-2.4,6.6
				c-3.3-0.1-2.9-4.1-2.9-6.8c0-40.4-0.1-80.8,0-121.3c0-2.3-0.7-5.8,2.8-5.6c3.4,0.1,2.6,3.6,2.6,5.9
				C2714,1768.9,2714,1789.3,2714,1809.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2701.6,1877.3c-3.2-3.5-2.1-6.7-2.1-9.6c-0.1-38.5-0.1-77.1-0.1-115.6
				c0-2.2,0-4.3,0.2-6.5c0.1-1.7,0.9-3.1,2.7-3c1.8,0.1,2.4,1.6,2.5,3.3c0.1,1.8,0.1,3.7,0.1,5.5c0,37.3-0.1,74.6,0,111.9
				C2704.9,1868,2704.5,1872.5,2701.6,1877.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2686.6,1811.6c0,18.9,0.1,37.7-0.1,56.6c0,2.8,1.7,7.7-1.8,8
				c-4.7,0.3-3.4-4.9-3.4-7.9c-0.1-26.6-0.1-53.2-0.1-79.8c0-12.7,0-25.4,0-38.1c0-2.3-0.8-5.8,2.6-5.9c3.4,0,2.7,3.5,2.7,5.8
				C2686.6,1770.7,2686.6,1791.1,2686.6,1811.6C2686.6,1811.6,2686.6,1811.6,2686.6,1811.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2718.1,1742.5c4.9,4,5.1,8.2,5,12.5c0,37.7,0,75.3-0.1,113
				c0,1.8,0,3.7-0.3,5.5c-0.3,1.5-1.1,3.1-2.9,3c-2,0-2.1-1.9-2.2-3.4c-0.1-1.2-0.1-2.5-0.1-3.7c0-40.7,0-81.5,0-122.2
				C2717.6,1746,2717.9,1744.8,2718.1,1742.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1269.9,1458.8c0,19.2,0,38.3-0.1,57.5c0,2.5,1.3,6.7-2.6,6.7
				c-3.9,0-2.6-4.2-2.6-6.7c-0.1-38.6-0.1-77.3,0-115.9c0-2.6-0.8-6.9,2.6-6.9c3.4,0,2.6,4.2,2.6,6.9
				C1270,1419.9,1269.9,1439.4,1269.9,1458.8C1269.9,1458.8,1269.9,1458.8,1269.9,1458.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1285.2,1523.4c-3.3-2.7-2.3-5.6-2.3-8.1c-0.1-38.3-0.1-76.7,0-115
				c0-2.7-1.6-7.6,2.7-7.5c4.2,0.1,2.6,4.9,2.6,7.6c0.1,35.6,0,71.1,0.1,106.7C1288.2,1512.5,1286.9,1517.7,1285.2,1523.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1294.9,1524.9c-3.5-4.6-2.9-7.2-2.9-9.6c-0.1-38.9-0.1-77.8,0-116.7
				c0-2.5-1.4-6.8,1.9-7.1c4.2-0.4,3.5,4.1,3.5,7c0.1,31.2,0.7,62.4-0.4,93.6C1296.6,1502.1,1296.4,1512.5,1294.9,1524.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1277.2,1698.4c-7.5-13.7-5.6-28.7-4.8-43.2c0.4-7.9,1.1-15.9,1.2-23.9
				c0.1-29,0-58,0.2-87c0-3.2-1.2-6.8,2-10c4.2,3,3.6,7.5,3.1,11.4c-3.3,27.7-1,55.4-1.5,83.1c-0.4,23.1,0,46.3,0,69.4
				L1277.2,1698.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1063.4,1699.4c-1.1-50.2,1.6-101.2,2-153.4
				C1071.4,1554.9,1068.3,1690.1,1063.4,1699.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1267.3,1536.2c0,50.9,0,101.8,0,152.3
				C1264.1,1681.8,1262.5,1553.4,1267.3,1536.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1206.7,1696.4c-6.2-3.5-7.9-7.6-7.9-12.2c-0.1-43,0-86,0.1-129
				c0-0.7,0.7-1.5,1.4-3c2.9,3.3,1.9,6.7,1.9,9.8c0.1,30.3,0.1,60.6,0.1,91C1202.3,1666.9,1201.2,1681.1,1206.7,1696.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1260,1393.4c0,43,0,86,0,129.1c-0.6,0-1.2,0-1.8,0c0-43,0-86,0-129.1
				C1258.8,1393.4,1259.4,1393.4,1260,1393.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1237.9,1631.6c-2.7-0.7-4.2,2-6.4,2.5c-2.9,0.6-6.6,1.5-5.1-3.9
				c16.5-3.9,33-0.7,49.5,0.2c1.9,0.1,4,0.1,4.7,2.5c-7.1,3.5-14.2,0.9-21.2-0.3C1252.2,1631.2,1245.1,1631.4,1237.9,1631.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1438.3,1718.8c-6.7,8.9-14.2,6.6-22,1.8c0-0.6,0-1.1-0.1-1.6
				C1423.6,1718.5,1430.9,1717.4,1438.3,1718.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1175.2,1733.6c12.6,0,25.3,0,37.9,0c0,0.6,0,1.1,0,1.7
				c-12.6,0-25.1,0-37.7,0C1175.3,1734.7,1175.2,1734.1,1175.2,1733.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1160.1,1736.5c-10,0-19.9,0-29.9,0c0-0.8,0-1.6,0.1-2.4
				C1140.1,1735.9,1150,1735.2,1160.1,1736.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2450.5,1655.7c-0.9-5.4-0.9-5.4,9.3-5.1
				C2456.9,1655.3,2456.9,1655.3,2450.5,1655.7L2450.5,1655.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1126.6,1564.8c0,7.6,0,15.3,0,22.9c-0.3,0-0.6,0-0.9,0c0-7.6,0-15.3,0-22.9
				C1126,1564.8,1126.3,1564.8,1126.6,1564.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1442.7,1615c-2.5,3.1-5.8,1.2-8.8,1.6
				C1436,1611.4,1439.4,1613.7,1442.7,1615z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1391.1,1754.6c-3.6,3.3-7.6,5.1-13.4,4.5c4-3.9,8.2-4.9,12.7-5.4
				C1390.7,1754,1390.9,1754.3,1391.1,1754.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1136.6,1607.4c-1-0.1-1.9-0.4-2-1.6c-0.1-1,0.2-2.2,1.4-2
				c0.8,0.1,1.7,1,2,1.7C1138.4,1606.6,1137.8,1607.4,1136.6,1607.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1450.2,1613.9c-0.6,1.7-1.2,3.9-3.1,0.9
				C1448,1613.7,1449.1,1613.7,1450.2,1613.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1277.4,1698.2c0.8,0.8,1.6,1.6,2.4,2.4c-0.4,0.3-0.8,0.5-1.1,0.8
				c-0.5-1-1-2-1.5-3C1277.2,1698.4,1277.4,1698.2,1277.4,1698.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1390.4,1753.7c1-1.1,2.2-1.8,3.7-1.4c0.4,0.1,0.6,0.7,0.9,1
				c-1.3,0.4-2.6,0.8-3.9,1.3C1390.9,1754.3,1390.7,1754,1390.4,1753.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1375.2,1760.2c-0.3,0.4-0.5,0.7-0.9,1c-0.2,0.1-0.5,0-0.8-0.1
				c-0.8-0.4-0.8-0.9,0.1-1.1C1374.1,1759.8,1374.6,1760.1,1375.2,1760.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1145.1,1604.1c-0.2,0.1-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.3-0.5-0.4
				C1144.4,1604,1144.8,1604,1145.1,1604.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1043.2,1726.2c-0.3,0.2-0.5,0.5-0.8,0.7
				C1042.7,1726.7,1042.9,1726.4,1043.2,1726.2C1043.1,1726.1,1043.2,1726.2,1043.2,1726.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1445.5,1615c-0.4,1.2-0.8,1.2-1.2,0C1444.7,1615,1445.1,1615,1445.5,1615z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2472.4,1578.2c1.1-44.3-2.2-88.8,3.9-133c0,44.3,0,88.7,0,133
				C2475,1578.2,2473.7,1578.2,2472.4,1578.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2529.2,1410.6c0,4.9,0,9.9,0.1,14.8c-1.8,0.3-3.6,0.4-5.3,0.8
				c-5.8,1.5-11.2,3.1-13.6-5.2c-2.2-7.7,0.2-16.7,6.1-19.1c2.4-1,5.3-1.2,7.3,1.6C2525.4,1405.9,2527.3,1408.2,2529.2,1410.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2479.7,1563.4c0.8-39.9-0.2-79.9,1.6-119.8
				C2485.5,1483.5,2480.4,1523.4,2479.7,1563.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2468.9,1605.5c-1.8,0-3.5-0.1-5.3-0.1c-4.3-1.5-3-7.8-7.6-9.1
				c0.1-0.7,0.2-1.5,0.3-2.2c2.7-2.5,1.5-5.8,1.5-8.7c0.1-20.2,0-40.5,0.1-60.7c0-3.1-1.3-7,3.1-8.6c0.2,20,0.6,40,0.4,60
				C2461.2,1586.7,2462.5,1596.7,2468.9,1605.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M2461,1516.1c-4.4,1.6-3.1,5.5-3.1,8.6c-0.1,20.2,0,40.5-0.1,60.7
				c0,2.9,1.2,6.2-1.5,8.7c0-30.1,0-60.2,0-90.3c1.6,0,3.2,0.1,4.8,0.1C2461,1507.9,2461,1512,2461,1516.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2461.1,1503.8c-1.6,0-3.2-0.1-4.8-0.1c0-3.3,0-6.5,0-9.8
				c1.6,0,3.3-0.1,4.9-0.1C2461.1,1497.1,2461.1,1500.5,2461.1,1503.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2416.1,1415.7c-0.2-4.3-1-6.8,0.7-9.1
				C2417.9,1409.4,2418.1,1411.9,2416.1,1415.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2771.3,1739.1c1.9,3.5,1.1,6,0.8,9.5
				C2770.2,1745,2770.9,1742.6,2771.3,1739.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1405.4,1676c0.8,0.3,1.6,0.6,2.4,0.9c-0.2,0.2-0.5,0.7-0.5,0.7
				C1406.6,1677.1,1405.9,1676.6,1405.4,1676C1405.3,1676.1,1405.4,1676,1405.4,1676z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1416.3,1700.2c0.1,1.5-0.4,2.1-1.9,1.7
				C1415,1701.3,1415.7,1700.7,1416.3,1700.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1051.8,944.5c1.2,0.5,2.5,1,3.7,1.5c7,3,3.3,8.8,4.4,15.1
				C1054,955.8,1050.9,951.1,1051.8,944.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2316.9,1457c0.6-1.8,1.2-3.7,1.9-5.5c3.8,3.2,13.3-3.5,12.8,7.5
				c0,0,0.1-0.1,0.1-0.1C2326.4,1461.4,2321.2,1462.7,2316.9,1457z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1069.5,949.4c-4.5-3.8-3.8-7.8-3.9-11.5c0-2.2,1.5-2.8,3.4-2.8
				c2.4,0,2.8,1.6,2.5,3.4C1071,941.9,1070.3,945.1,1069.5,949.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1091.3,985.6c-4-0.5-8,2.2-10.7-2.1c-0.7-1.2,0-2.9,1.8-3.3
				C1086.7,979.4,1089.7,980.6,1091.3,985.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1043.6,1022.8c2.5,2.9,4.4,5.6,3.8,8.9c-0.1,0.8,0.6,2.7-1.3,2.7
				c-1.3,0-2.4-0.8-2.4-2.2C1043.5,1029.1,1043.6,1026,1043.6,1022.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1055.4,1021.8c2.6,4.1,0.4,8-0.4,12.5c-5.6-3.8-0.6-7.8-0.8-11.4
				C1054.6,1022.5,1055,1022.2,1055.4,1021.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1084.4,1012.6c-2.1-0.2-4.8,0.2-5-2.8c-0.1-2.4,2.3-1.8,3.8-2
				c2.3-0.3,4.7,0.2,4.9,3C1088.3,1013.6,1085.8,1012.3,1084.4,1012.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1065.9,1004.9c-0.6,2.7-2.3,4.7-4.1,6.6c-1-5.1,1.8-9,3.3-14.3
				C1067.7,1000.5,1064.8,1002.8,1065.9,1004.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1091.9,997.6c-0.2,2.1-2.3,2.5-4.1,2.7c-1.6,0.3-2.8-1-2.8-2.6
				c0-2.6,2.1-2.9,4.1-3C1090.8,994.6,1091.9,995.2,1091.9,997.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1056.6,925.8c0.6-0.1,1.3-0.2,1.9-0.4c-0.2,0.6-0.5,1.3-0.7,1.9
				C1057.4,926.8,1057,926.3,1056.6,925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1050.3,1022.7c0,0,0.6-0.1,0.6-0.1c-0.3,0.5-0.7,1-1,1.4
				c-0.2-0.2-0.4-0.4-0.7-0.7C1049.5,1023.1,1049.9,1022.9,1050.3,1022.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1045.9,993.7c-0.1-0.2-0.2-0.3-0.3-0.5c0.2-0.1,0.4-0.2,0.7-0.4
				c0,0.2,0.1,0.4,0.1,0.6C1046.3,993.6,1046,993.7,1045.9,993.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1056.9,1011c-0.2-0.5-0.2-1,0-1.4c0.2,0.3,0.3,0.7,0.4,1
				C1057.4,1010.7,1057.1,1010.9,1056.9,1011z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1439.6,1645.8c3,7,1.4,15.4,6.1,21.9
				C1438.1,1662.2,1440.6,1653.4,1439.6,1645.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1447.5,1675.1c-2.4,3.5-4.6,2.8-6.6,2.5c-1.8-0.3-2.7-1.7-2-3.4
				c1.1-2.7,3.5-1.9,5.5-1.3C1445.6,1673.4,1446.6,1674.5,1447.5,1675.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1371.7,1406.7c0,41.7,0,83.3,0,125c-0.6,0-1.2,0-1.8,0
				c0-41.6,0-83.2,0-124.9C1370.5,1406.8,1371.1,1406.7,1371.7,1406.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1372.4,1540c-0.7,43.9,1.6,87.8-3,131.7c0-43.9,0-87.8,0-131.7
				C1370.4,1540,1371.4,1540,1372.4,1540z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2742.7,1745.8c0-37.9,0-75.9,0-113.8c0.5,0,0.9,0,1.4,0
				c0,37.9,0,75.9,0,113.8C2743.6,1745.8,2743.2,1745.8,2742.7,1745.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2751.4,1746.2c0-35.4,0-70.8,0-106
				C2754.6,1647.3,2756.1,1731.2,2751.4,1746.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1361.7,1521.3c0-37.3,0-74.7,0-112c0.3,0,0.6,0,0.9,0c0,37.4,0,74.9,0,112.3
				C1362.3,1521.6,1362,1521.4,1361.7,1521.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2825.6,1564c2.8,2.5,1.6,4.2,0.7,6.2
				C2823.5,1568.4,2824.8,1566.6,2825.6,1564z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1352.4,1679.7c0.5-0.1,0.9-0.2,1.4-0.3c0.1,1.2,0.3,2.5-1.1,3.1
				c-0.3,0.1-0.9-0.4-1.4-0.6C1351.6,1681.2,1352,1680.4,1352.4,1679.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2393.6,1564.7c-3.1-3.7-1.5-8.2-1.6-12.3c-0.2-13.4,0.1-26.8,0-40.2
				c0-2.9,1.1-6.2-1.7-8.5c0-1.4,0-2.7,0-4.1c1.7,0,3.4-0.1,5.1-0.1c0.1,19.9,0.2,39.7,0.3,59.6
				C2395.6,1561.2,2395.7,1563.4,2393.6,1564.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2391.7,1406.7c0.1,8.1,0.1,16.3,0.2,24.4c-8.5-2-11.3-8-10.8-16.1
				C2381.4,1408.2,2386,1406.7,2391.7,1406.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2370,1399.4c0,3.6,0,7.2,0,10.7C2368.6,1406.6,2366.9,1402.9,2370,1399.4
				L2370,1399.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2361.2,1464.2c0-2.3,0-4.6,0-8.6C2363.2,1459.5,2362.8,1461.9,2361.2,1464.2
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1471.2,1650c-0.6-1-1.7-2.1-1.7-3.1c-0.1-24.3-0.1-48.5,1.6-72.8
				C1471.2,1599.4,1471.2,1624.7,1471.2,1650L1471.2,1650z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1471.2,1650.1c1.8,1.9,4,3.3,4.9,6.8
				C1471.2,1656.4,1471.1,1653.3,1471.2,1650.1C1471.2,1650,1471.2,1650.1,1471.2,1650.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1513,1652c3.8-2.7,7-0.9,7.1,2.9c0.1,3.6-3.3-0.3-5,0.7
				C1514.4,1654.4,1513.7,1653.2,1513,1652z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1487.7,1657.5c0.2-0.3,0.5-0.5,0.7-0.8c0,0.2,0,0.4-0.2,0.5
				c-0.2,0.1-0.4,0.2-0.6,0.2L1487.7,1657.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1511.7,1655.4c0.2,0.4,0.4,0.8,0.6,1.2c-0.4-0.2-0.8-0.4-1.2-0.6
				C1511.1,1656,1511.7,1655.4,1511.7,1655.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2449.7,1484.1c5.2-4.6,10.6,1.7,15.6-1.4c0.2,0.9,0.8,1.9,0.6,2.7
				c-0.5,1.9-2.2,2.3-4,2.5C2457.4,1488.1,2454.5,1483.2,2449.7,1484.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2373.5,1642.5c-0.3,1.1-0.6,2.2-1,3.3c-4.1,1.6-7.2-1.2-10.9-2.3
				C2365.7,1640.9,2369.5,1639.7,2373.5,1642.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1485,1712c9.6,0,19.3,0,28.9,0c0,0.7,0,1.3,0.1,2c-9.7,0-19.3,0-29,0
				C1485,1713.3,1485,1712.7,1485,1712z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1540,1712c5.9-0.6,11.7-1.2,17.6-1.8c1.3-0.1,2.4,0.1,2.7,1.5
				c0.4,1.8-0.5,3.2-2.2,3.2C1552,1714.7,1545.9,1716.1,1540,1712z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1557.2,1717.8c-6,4.4-11.8-0.3-17.9,0
				C1545.2,1717.8,1551.2,1717.8,1557.2,1717.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1535.2,1711.3c0.2,0.1,0.5,0.2,0.7,0.4
				C1535.6,1711.6,1535.4,1711.4,1535.2,1711.3C1535.2,1711.3,1535.2,1711.3,1535.2,1711.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2577.7,1457c0.4,0,0.8,0,1.2,0c4.5,4.7,1.1,11.7,4.4,17
				c0.4,0.6-3.6,0.7-5.6,0.8c1.5-3.2,0.9-6.2,0-9.6C2577,1462.7,2575.5,1459.7,2577.7,1457z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2517.5,1496.5c-0.7,0.2-1.4,0.4-2,0.6c0.7-3.2,0.3-7.1,4.9-7.7
				C2521.3,1492.6,2518.3,1494.1,2517.5,1496.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1476.7,1685.3c-0.9,0.2-1.7,0.3-2.6,0.5c0.1-0.5,0.1-1,0.2-1.4
				C1475.1,1684.7,1475.9,1685,1476.7,1685.3L1476.7,1685.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M2481.7,1616.5c0.7-1.6,1.2-1.4,1.7,0.1l-0.9,0.1L2481.7,1616.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1315.3,1635.7c-7.2,0-14.4,0-23.1,0C1300.8,1630,1308.2,1636,1315.3,1635.7z
				"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1277.6,1643.5c-1.6,1.3-3.1,2.7-4.6,4c-2.8-3.3,0.9-3.9,1.6-5.6
				C1275.9,1641.8,1277.1,1641.9,1277.6,1643.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1508.1,1672.7c0.2-0.4,0.4-0.8,0.7-1.2c0.1,0.5,0.2,0.9,0.3,1.4
				c0,0-0.5,0.2-0.5,0.2L1508.1,1672.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1540.3,1685.7c-0.7-0.5-1.3-1.1-0.3-1.8c0.1-0.1,0.8,0.6,1.2,0.9
				C1540.9,1685.1,1540.6,1685.4,1540.3,1685.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1270,1646.9c-0.2,0.1-0.8,0.4-1.4,0.6c-1,0.3-1.9,0-2.1-1.1
				c-0.1-0.4,0.6-1.2,1.1-1.4C1268.5,1644.6,1269.3,1645,1270,1646.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1616.7,1014.3c0-0.6,0-1.3,0-1.9c4,0.4,6.1-1.8,7.1-5.4
				c3.1,1.3,6.2,2.4,9.8,1.5c1.7-0.4,3.5,0.1,3.6,2.4c0.1,1.2,0,3.1-1.3,2.9c-6.2-1-6,4.9-8.7,7.8c-0.2,0.2-0.4,0.4-0.6,0.6
				c-3.6,0.5-3.7,3.5-3.8,6.1c-0.2,3.3-1.8,4.3-4.7,3.8c1.4-3.7,5-8-2.6-9c0-0.3,0-0.7,0-1C1622,1020.6,1622.3,1018,1616.7,1014.3z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1615.3,1023c7.6,1,4,5.3,2.6,9c0,0,0.1,0,0.1,0c-2,2-4,3.9-6,5.9
				c-2.1-3.6-0.6-7.5-1-11.3C1610.7,1023.3,1613.2,1023.3,1615.3,1023z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1612,1037.9c2-2,4-3.9,6-5.9c0.3,1.6-0.2,3.4,1.5,4.5l0.1,0.1c0,1,0,2,0,3
				c-0.4,0.5-0.9,1-1.3,1.5C1614.3,1043.8,1614.2,1038.9,1612,1037.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1659,1030.6c-0.3-0.2-0.5-0.4-0.8-0.6c0.1,0,0.4,0,0.4,0.1
				C1658.8,1030.2,1658.9,1030.5,1659,1030.6L1659,1030.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1627.1,1021.6c0.2,0.4,0.3,0.7,0.5,1.1c-0.4-0.2-0.7-0.3-1.1-0.5
				C1626.7,1021.9,1626.9,1021.8,1627.1,1021.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1530.5,1048.4c-3.3-0.3-3.2-1.9-1.7-4.2
				C1530.1,1045.3,1530.5,1046.8,1530.5,1048.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1594.6,1015.9c0-1.1,0.7-1.4,1.6-1.6
				C1596,1015.2,1595.9,1016.1,1594.6,1015.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1062.5,1041.7c0.5-0.4,0.8-0.8,1-0.8c0.8,0.1,1.6,0.4,2.3,0.7
				c-0.4,0.2-0.8,0.5-1.2,0.5C1064,1042.1,1063.3,1041.9,1062.5,1041.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1074.8,852.7c-1.2,5-0.5,9.8,1.2,14.5c-3.1,1-2.4-5.4-6.1-3.3
				C1070.7,859.8,1067.6,853.9,1074.8,852.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1066.9,907.2c-1.9-2.5-2-5,0.1-7.5C1067,902.3,1066.9,904.8,1066.9,907.2z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1068.1,867c-1.7-2.2-0.2-2.7,1.5-3.1C1069.9,865.4,1070,866.7,1068.1,867z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1073.5,872.5c-0.5,0.2-1,0.4-1.4,0.4c-0.4,0-0.8-0.3-1.3-0.5
				c0.4-0.2,0.9-0.5,1.3-0.4C1072.6,871.9,1073,872.2,1073.5,872.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1086.2,862.6c-0.9,0.3-1.7,0.6-2.6,1c0.5-1.6-1.4-4.3,1.4-4.6
				C1086.3,858.9,1085.8,861.4,1086.2,862.6L1086.2,862.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1088,830.5c-0.4,0.5-0.6,1.4-1.3,0.4c0-0.1,0.5-0.6,0.8-0.9
				C1087.6,830.1,1087.8,830.3,1088,830.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1098.1,573.2c1,0.7,1.9,1.5,0.7,2.7c-0.5-0.7-1-1.4-1.4-2.1
				C1097.6,573.6,1097.8,573.4,1098.1,573.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1061.4,861.3c0-1.2-0.2-2.5,1.3-3.1C1064.8,860.4,1063.5,861,1061.4,861.3z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1059.7,806.4c0.1,1.3-0.3,2.3-1.4,3C1058.8,808.4,1059.3,807.4,1059.7,806.4
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1515.6,1444.5c1.6,9,3,15.6-1.6,21.9
				C1515,1459.9,1511.3,1453.2,1515.6,1444.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1606.4,1393.7c0.1,1.5,0.4,3.1-1.7,3.4c-0.4,0.1-1.1-0.6-1.2-0.9
				c-0.3-2.3,1.9-1.6,2.9-2.3C1606.5,1393.8,1606.4,1393.7,1606.4,1393.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1610.2,1392c-1,1.3-2.1,2.1-3.8,1.7c0,0,0.1,0.1,0.1,0.1
				c0.9-1.5,2.2-1.9,3.8-1.7C1610.3,1392.1,1610.2,1392,1610.2,1392z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1639.6,1536.6c-8.7,1.8-12.5-1.2-11.3-10.6c0.4-3.2-0.8-6.7-1.2-10
				C1635.7,1518.2,1639.3,1524.3,1639.6,1536.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1639.5,1538.7c0-0.7,0.1-1.3,0.1-2c1.3,0.4,2.6,0.7,4,1.1
				C1642.2,1538.1,1640.9,1538.4,1639.5,1538.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1590.1,1445.8c0,1.9,0,3.7,0,5.6c-1.7-0.4-4.6,1-4.8-2
				C1585.1,1446.6,1587.6,1445.8,1590.1,1445.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1580.9,1490.4c-0.2-0.3-0.5-0.5-0.7-0.8c0.2,0,0.4,0,0.5,0.1
				C1580.8,1489.9,1580.9,1490.1,1580.9,1490.4L1580.9,1490.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1609.8,1446.3c-1.1,0.1-2.2,0.3-3.3,0.4c1.3-3.8,3.2-3.3,5.5-0.9
				c0,0,0,0,0,0C1611.3,1446,1610.6,1446.1,1609.8,1446.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1613.8,1447.8c-0.6,0-1.2-0.2-1.7-0.4c-0.1-0.5-0.1-1-0.1-1.5c0,0,0,0,0,0
				C1612.6,1446.5,1613.2,1447.1,1613.8,1447.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1577.3,1607.4c-4.6,8.1-8,17.2-18,20.6c-0.2-2.5-0.5-5.2-0.7-7.9
				c0.1-2.1,0.4-4.1,3.4-3.5c9.8,0.2,7-7.6,8-12.9c3.5-2.8-1-5.5-0.1-8.3c-0.1-0.8-0.1-1.7-0.2-2.5c1.4-1,0.9-2.1,0.1-3.2l0,0.1
				c-0.3-4.2,4.6-9.4-2.3-12.3c3-2.9,6.6-1.7,10-1.2c-0.1,3.5-0.2,7.1-0.3,10.6c1.5,5-1.5,11,4.7,15c1.9,1.3-0.2,6.1-2.3,8
				C1578,1611.4,1577.7,1608.7,1577.3,1607.4c1.7-1.2,2.6-2.8,2.3-5.2C1576.1,1603.1,1577.6,1605.6,1577.3,1607.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1570,1603.6c-1,5.4,1.7,13.2-8,12.9c1.9-4.1-3.3-7.1-1.6-11.2
				C1563.5,1604.8,1566.7,1604.2,1570,1603.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1570,1603.6c-3.2,0.6-6.5,1.1-9.7,1.7c-1.9-1.7-1.1-3.9-1-6
				c3.5-1.3,7.1-2.7,10.6-4C1568.9,1598.1,1573.5,1600.9,1570,1603.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1577.3,1586.9c0.1-3.5,0.2-7.1,0.3-10.6c1.7,0,3.3,0.1,5,0.1
				C1580.6,1579.8,1586.6,1587.4,1577.3,1586.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#E6E6E6" d="M1593.7,1596.1c-7.1-1.7-5.8-6.2-3.6-11
				C1597.7,1586.6,1592,1592.6,1593.7,1596.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1569.9,1589.8c-4.5-2.8-3.9-7.9-5.5-12.1c1.1-0.1,2.1-0.1,3.2-0.2
				C1574.5,1580.4,1569.5,1585.6,1569.9,1589.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1564.4,1577.7c-0.6-0.3-1.2-0.6-1.8-0.9
				C1563.9,1575.6,1564.3,1576.3,1564.4,1577.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1618,1509.5c-0.5,0.5-0.9,1.5-1.7,0.4c-0.1-0.1,0.6-0.8,0.9-1.2
				C1617.4,1508.9,1617.7,1509.2,1618,1509.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1656,1416.2c-0.3,0.4-0.6,0.8-0.9,1.2c-0.9-0.9-0.3-1.3,0.5-1.7
				C1655.6,1415.8,1656,1416.2,1656,1416.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1628.6,1488.7c-2.9-4.2-1.7-6.8-1.9-10.1
				C1629.9,1481.8,1628.1,1484.4,1628.6,1488.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1535.3,1466.4c-7.7-4.2-2.1-11.2-3.4-16.7c3.4,4.4,0.4,10.4,3.7,14.8
				C1535.5,1465.1,1535.4,1465.8,1535.3,1466.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1564.5,1399.4c0,1.3-0.1,2.6-0.1,3.9c-4.3,0.7-3.3-4.2-5.7-5.5
				c1-0.1,2-0.3,2.9-0.4C1562.6,1398,1563.6,1398.7,1564.5,1399.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1555.2,1467.2c0,1.7,0.1,2.9-0.1,4c0,0.3-0.7,0.6-1,0.9
				c-0.2-1.1-0.4-2.3-0.5-3.4C1553.6,1468.4,1554.3,1468.1,1555.2,1467.2z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#76726E" points="1553,1476.5 1552.5,1476.7 1552.5,1476.2 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1569.9,1555.3c0.5,2.6,1.1,5.2,1.6,7.8c-2.1,0.1-4.4-0.2-6.2,0.6
				c-3.6,1.5-5.8,1.5-6.3-3.1c-0.4-4.2,1.8-5.4,5.5-5.2C1566.2,1555.4,1568.1,1555.3,1569.9,1555.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1555,1548.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2-0.5-0.5-0.9-0.6-1.4
				c0-0.1,0.4-0.4,0.6-0.5C1554.6,1547.4,1554.8,1547.9,1555,1548.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1618.5,1618.6c-1-0.2-1.9-0.6-1.1-1.9c1.5,0.1,2.9,0.5,3.4,2.1
				C1620,1618.7,1619.3,1618.6,1618.5,1618.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#484849" d="M1573.4,1637.4c0.1-2.2,0-4-1.6-6c-3.4-4.3-1.5-6.6,3.4-7.2
				c2,2.2,4.6,3.3,7.5,3.9c0,3.4-0.1,6.8-0.1,10.1C1579.6,1638,1576.5,1637.7,1573.4,1637.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1617.5,1616.8c-3.5,0.8-5.6-1.3-7.4-3.8
				C1613.2,1612.9,1615.9,1613.7,1617.5,1616.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1612,1445.8c0,0.5,0.1,1,0.1,1.5c-0.8-0.4-1.5-0.7-2.3-1.1
				C1610.6,1446.1,1611.3,1446,1612,1445.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1785.7,1151.6c-0.2-0.1-0.5-0.1-0.7-0.3c-0.1-0.1-0.1-0.3-0.1-0.5
				C1785.1,1151.1,1785.4,1151.4,1785.7,1151.6C1785.6,1151.6,1785.7,1151.6,1785.7,1151.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2574.7,1445.2c-0.3-3-0.7-6,2.8-7.5
				C2576.8,1440.3,2579.3,1444.1,2574.7,1445.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2549.8,1449.6c1,0,2,0,3,0C2551.8,1451.3,2550.8,1451.2,2549.8,1449.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2552.8,1458.5c0.2,0.3,0.4,0.5,0.7,0.8c-0.3-0.2-0.5-0.4-0.8-0.7
				C2552.7,1458.6,2552.8,1458.5,2552.8,1458.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1582.7,1628.1c-2.9-0.6-5.5-1.6-7.5-3.9c-0.4-4,2-7.6,5-6.8
				C1585.6,1619,1582,1624.4,1582.7,1628.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1603,1640.8c-2.5-0.1-5-0.2-7.4-0.3c1-1.2,2.1-2.4,3.1-3.6
				C1600.1,1638.3,1601.5,1639.5,1603,1640.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2501.6,1480.7c-0.4,1.9-0.1,4.4-3,4.6
				C2498.8,1483.2,2499.6,1481.6,2501.6,1480.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2503.1,1468.9c-0.2,0.4-0.5,0.8-0.7,1.2
				C2501.4,1469.1,2502.2,1469,2503.1,1468.9C2503,1468.9,2503.1,1468.9,2503.1,1468.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2503.1,1479.2c-0.5,0.5-1,1.1-1.6,1.6
				C2502,1480.3,2502.6,1479.8,2503.1,1479.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2513.3,1454.1c0.1-0.9,0.6-1.5,1.5-1.6
				C2514.8,1453.6,2514.4,1454.3,2513.3,1454.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2514.5,1436.6c-0.2-0.5-0.5-1-0.7-1.4c0.5,0.2,1,0.4,1.5,0.6
				C2515,1436,2514.7,1436.3,2514.5,1436.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2479.7,1512c0.2-0.2,0.5-0.4,0.7-0.7c-0.1,0.1-0.1,0.4-0.2,0.4
				C2480,1511.8,2479.8,1511.8,2479.7,1512L2479.7,1512z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2481.1,1498.6c0.3,0.1,0.7,0.2,1,0.3c-0.1,0.2-0.3,0.5-0.4,0.7
				c-0.2-0.4-0.3-0.7-0.5-1.1C2481.2,1498.5,2481.1,1498.6,2481.1,1498.6z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#9C999E" points="2482.9,1488.9 2483.4,1488.7 2483.4,1489.1 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M2489.8,1473.5c-0.3-0.2-0.6-0.3-1-0.5c0.2-0.2,0.3-0.5,0.5-0.7
				c0.2,0.3,0.4,0.7,0.6,1L2489.8,1473.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1077.7,1440.9c0,26.2,0,52.3,0,78.5c-0.3,0-0.6,0-0.9,0
				c0-26.3,0-52.6,0-78.9C1077.1,1440.7,1077.4,1440.8,1077.7,1440.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1327.5,1628.9c-0.4,0.4-0.7,0.9-0.9,0.9c-0.8-0.1-1.6-0.4-2.4-0.6
				c0.4-0.2,0.8-0.5,1.2-0.5C1326,1628.6,1326.7,1628.8,1327.5,1628.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1335.7,1702.2c-1.5-0.8-1.7-2.2-1.9-3.6c0,0,0.5-0.4,0.5-0.4
				c0.6,1.3,1.2,2.5,1.7,3.8L1335.7,1702.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2A2B2B" d="M1335.9,1701.9c0.4,0.7,0.8,1.4,1.2,2.1c0,0-0.4,0.3-0.6,0.5
				c-0.3-0.8-0.6-1.6-0.9-2.4C1335.7,1702.2,1335.9,1701.9,1335.9,1701.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1641.3,1042.7c-1.2-0.1-2.1-0.6-2.4-2c0-0.2,0.2-0.4,0.3-0.6
				c0.8,0.8,1.5,1.5,2.3,2.3C1641.6,1042.4,1641.3,1042.7,1641.3,1042.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#9C999E" d="M1642.9,1044.1c-0.5-0.5-1.1-0.9-1.6-1.3c0,0,0.3-0.3,0.3-0.3
				C1642,1042.9,1642.5,1043.5,1642.9,1044.1L1642.9,1044.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#76726E" d="M1577.3,1607.4c0.3-1.8-1.2-4.3,2.3-5.2
				C1579.9,1604.6,1579,1606.1,1577.3,1607.4L1577.3,1607.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#4D4D4D" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M823.9,2214.3
				c-49.1,0-98.2,0-147.3,0c-0.2-0.7-0.5-1.5-0.5-2.2c-0.7-12.8-1.5-25.6-2.1-38.4c-0.5-11.1-0.7-22.1-1.2-33.2
				c-0.9-18.5-2.1-36.9-2.7-55.4c-0.4-10.8-1.7-21.5-1.6-32.3c0-2.8,0.8-3.7,3.4-3.7c15.7-0.5,31.5-1.1,47.2-1.7
				c2.3-0.1,3,0.4,2.2,2.7c-1.7,4.7-3.1,9.5-4.7,14.2c-0.7,2.1-0.2,3.2,2.3,2.8c3.4-0.5,6.7-0.9,10.1-1.3c2.2-0.3,2.9-1.1,1.9-3.5
				c-1.9-4.3-4.4-8.3-6.6-12.4c-1-1.8-0.9-2.8,1.6-2.9c8.7-0.3,17.5-0.8,26.2-1.2c6.3-0.3,12.7,0.1,18.8-1.3c-2.1-3.4-5.5-2-8.3-2
				c-10.1,0-20.3,0.4-30.4,0.9c-12.8,0.6-25.7,0.6-38.4,1.6c-6.6,0.5-13.1,0.5-19.7,0.6c-5.8,0.1-7.3,1.9-6.5,7.6c0,0.3,0,0.7,0,1
				c0.9,18.9,1.7,37.8,2.6,56.7c0.7,15,1.3,29.9,2,44.9c0.4,8.2,0.6,16.3,1,24.5c0.5,11.3,1.1,22.6,1.6,33.9c-23.4,0-46.9,0-70.3,0
				c-0.2-5.3-0.5-10.6-0.6-16c-0.2-8.9-0.3-17.8-0.3-26.8c0-2.6-1-3.6-3.5-3.2c-0.9,0.1-1.7,0.1-2.6,0.3c-3.5,0.5-4.1,1.8-4,5.3
				c0.3,9,1.5,18.1,0.9,27.2c-0.3,4.4-0.5,8.8,0.3,13.2c-5.9,0-11.8,0-17.8,0c-0.9-1.9,0.4-2.5,1.8-3c2.2-0.9,4.1-2.2,5.8-3.9
				c-1.1-0.8-2.1-0.7-3-0.3c-2.3,0.8-2.9-0.1-3-2.3c-0.1-6.7-0.2-13.5-0.5-20.2c-0.1-3.4,0.4-6.8-1-10.1
				c-1.5,10.9-0.2,21.9-1.4,32.8c-0.4-6-0.9-12-1.3-18.3c-1.2,1.3-1.8,3-3.3,2.5c-1.8-0.6-3.3,0.1-4.3,0.9c-1.9,1.6-4.2,1.6-6.3,1.7
				c-4.1,0-8.3-0.1-10.4-4.9c-0.8-2-2.1-3.8-4.2-4.8c0,0-0.1-0.1-0.1-0.1l0.1,0c0.8,1.6,1.9,3.2,2.4,4.9c2,7.9,3.7,15.8-1.5,23.3
				c-0.4,0.6-0.4,1.2-0.3,1.9c-11,0-21.9,0-32.9,0c0.4-3.5-0.8-6.8-1.6-10.2c-0.4-1.7-0.7-3.4-0.4-5.1c0.7-3.6-0.4-4.4-4.1-3.6
				c-4.6,0.9-9.2,1.5-13.9,1.9c-8.1,0.6-16.3,1-24.4,1.4c-1.3,0.1-2,0.8-2.3,1.9c-0.3,1.2,0,2.8-2.2,2.1c-1.3-0.4-2.8-0.3-4.2-0.5
				c-0.5-0.1-0.3-0.7-0.2-1.1c0.6-1.9-0.3-1.6-1.6-1.3c-2,0.4-4,2.6-5.8,1.2c-2.8-2.2-4.1-0.6-5.8,1.3c-1,1.1-1.2,2.5-1.7,3.8
				c-1.2,2.9-0.7,4.1,2.4,4.1c3.3,0,3.7,1.5,2.9,4.1c-22.8,0-45.6,0-68.4,0c-0.2-6.6-0.3-13.3-0.6-19.9c-0.2-5.5-0.3-5.3-5.7-5
				c-2.6,0.1-6.2,1.4-7.6-0.4c-2.4-3.1-5.7-3-8.2-2.9c-9.5,0.3-19,1.4-28.5,2.3c2.8,0.3,5.7,0.5,8.5,0.2c4.4-0.5,8.7-0.5,13.1-0.3
				c2.8,0.1,3.1,1.5,3.2,3.5c0.4,7.5,0.2,15,0.1,22.5c-21,0-42.1,0-63.1,0c0-5.5,0-11.1,0-16.6c0-0.9,0.6-2.1-0.9-2.3
				c-1.2-0.2-2.2,0.4-2.7,1.4c-0.4,0.7-0.5,1.7-0.5,2.5c0,5,0.1,10,0.2,15c-15.1,0-30.2,0-45.4,0c0.9-2.2,1.4-4.7,4.9-5.1
				c-4.9-3.6-10.2-4.8-14.7-2.3c-6.3,3.5-12.9,3.4-19.5,3.5c-18.1,0.3-36.1,1.7-54.1,2.9c-4,0.3-8.1-0.3-12,1c-16.4,0-32.9,0-49.3,0
				c1-3.9,0.5-5.1-2.5-4.9c-6.7,0.5-13.3-0.4-19.8-1.2c-2.7-0.3-3.4,0.7-3.2,3c0.1,1.1,0.4,2.1,0.6,3.2c-12.7,0-25.5-0.1-38.2,0.1
				c-2.4,0-2.7-0.7-2.6-2.8c0.2-2.8,0.1-5.6,0.1-8.5c3.9,0.4,7.8,0.4,11.6,0.3c4.4-0.1,8.8-0.9,13.1-2.3c3.6-1.1,7.5-1,10.7-3.3
				c4.8-3.5,5.1-9.5,7.8-14.2c0.1-0.2-0.3-0.8-0.4-1.2c-1.4-3.8-3-4.2-7-5c-5.1-1.1-9.9,5.2-14.9,0.3c-3.7,1.3-7.6,0.3-11.4,0.9
				c-3.2,0.5-5.9,4.1-9.5,1.6c0-4.6,0-9.2,0-13.7c2.6-1,2.5-3.8,3.8-5.7c1.4-0.3,2.8-0.7,4.2-0.8c12.5-0.7,24.3,1.5,35.4,7.3
				c4.5,2.3,8.1,6.1,12.9,7.9c1.9,0.8,2.3,0.2,2.4-1.5c0.3-3.8,1.7-4.4,5.5-2.8c1.5,0.6,4.1,2.2,3.8-1.7c-0.1-0.8,0.4-1.3,1.2-1.1
				c0.7,0.2,1.2,0.7,1,1.4c-0.2,0.6-0.6,1.1-0.9,1.7c-0.4,0.9,0.1,1.7,0.8,2.3c0.5,0.5,1.8,0.3,1.8,0c-0.1-2.7,3.6-2.9,3.5-5.5
				c0-0.9,0.9-0.9,1.5-1c0.9-0.2,0.7,0.6,0.6,1.1c-0.5,2,1.2,3.4,1.5,5.2c0.3,1.8,1.6,2.1,2.8,0.7c1.3-1.5,3.8-2.1,3.9-4.6
				c0-0.3,0.9-0.7,0.9-0.7c1.9,2.3,3.5,0.2,5.1-0.5c0.5,1.3-0.6,1.8-0.8,2.6c-0.3,1.2-0.6,2.8,0.9,3.1c4.1,0.9,7.7,5.1,12.4,1.6
				c0.8-0.6,2.2-0.8,2.8,0.5c0.9,2,1.4,5.1,4.2,1.4c0.1-0.1,0.6-0.1,0.6,0c1,2.3,4.2,1.3,5.3,3.2c1.1,1.9,1.7,1.4,2.9,0.2
				c0.9-0.9,1.9-0.9,3.1,0.1c1.8,1.6,3.9,2.8,6.6,1.9c0.3-0.1,1.1,0,1.2,0.2c0.8,2.6,2.2,1.4,3.7,0.8c0.8-0.3,1.7,0,2.5,0.5
				c1.6,1.1,3.4,1.2,5.3,1.5c3.5,0.5,7.2-0.2,10.4,0.8c4.6,1.4,9.1,0.8,13.6,1c5.3,0.2,10.7-0.3,15.9,0.4c5.6,0.9,11.3,1.2,16.9,1.5
				c7.7,0.4,15.5-0.1,23.3,0.1c0.4,0,5.9,2.7,4.1-2.7c-0.2-0.5,0.8-0.6,1.3-0.6c2.5-0.3,3.2-1.5,2.7-4.1c-0.4-1.9-0.2-3.8,2.7-4.6
				c2.5-0.7,5.8-0.2,7.2-3.1c1-2.1,2.6-3.3,4.4-4.6c0.9-0.7,2.6-1.5,2-2.6c-0.9-1.6-1.3-4.1-3.4-4.4c-2.9-0.4-5.3-2.5-8.3-2.3
				c0.5-2.4-2.7-3.3-1.9-5.9c-1.5,1.3,0.4,3-1.2,3.9c-2,1.1-0.8,1.9,0.2,2.8c-4.8,0.5-9.7,0.5-14-1.9c-4.4-2.4-8.9-2.4-13.6-1.3
				c-3.8,0.9-4,0.5-2.8-3.6c-0.7-1.3-0.2-2.7-0.6-4c-0.1-6.5-0.1-13.1-0.2-19.6c0-1.4,0.8-3.2-1.4-3.8c0.2-1.9,0.3-3.8,0.5-5.7
				c1.7-3.3,1.4-4.2-1.4-4.5c-0.8-1.5-3.3,1.5-3.6-1.3c-0.4-5.1-0.6-10.1-1-15.2c-0.5-6-0.1-12.1-0.2-18.1c-0.1-4,0-8,2.6-11.3
				c1.7,1.7-1.2,4.1,1.1,5.6c0.1,0.5,0.3,1.1,0.2,1.6c-0.7,3.4,0.7,5.2,3.7,6.8c4.1,2.3,8.5,4.2,11.9,7.6c1.1,4.1-0.2,8.2,0.1,12.3
				c0.1,2-1.2,3.9-3.2,4.9c-0.9,0.5-1.5,0.8-0.8,1.9c1.6,2.5-0.8,3.2-2.2,4.2c-0.7,0.5-1.2,0-1.2-0.9c0-0.8,1.3-2.2-0.2-2.4
				c-1.6-0.1-0.5,1.8-1,2.6c-0.7,1.1,0,2,0.6,3c1.1,1.7,3.2,3.7-0.6,4.8c-0.3,0.1-0.6,0.7-0.6,1.1c-0.3,2.8-0.6,5.7,0.2,8.4
				c0.7,2.3,3,2.9,5,2.7c2-0.2,2.4-1.9,2.2-4c-0.3-2.8-0.3-5.6,0.3-8.4c0.4-1.8,2-4.3-0.9-5.8c-0.1,0,0-0.7,0.1-0.9
				c0.5-0.7,0.8-0.1,1.3,0.1c1.6,0.6,5.1-1.4,4.6-2.7c-1-2.7,0-4.2,2.1-5.7c0.8-0.5,1.5-2.1,1.2-2.9c-1.2-3.7,0-7.7-1.7-11.2
				c0.4-7.2,1.5-14.4,2.9-21.5c2.6-0.4,3.5,0.7,2.8,3.2c-0.3,1.1-0.9,2.8,0.7,2.9c1.3,0.1,1-1.8,1-2.7c0.1-7.8,0.2-15.6,0-23.4
				c-0.1-4.1,0-8.2,0.9-12.2c0.5-2.3,0.1-4.3-1.1-6.2c-0.7-1.1-1.9-1.3-2.8-0.5c-1,0.9-0.2,1.6,0.6,2.3c1.7,1.5,1.6,1.7,0.3,3.4
				c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.7-0.4-1.7-1.4-2c-1.1,1.3-0.6,3.5-2.8,4.1c1.5,0.7,3.8-0.2,3.5,1.6c-0.5,2.9,1,5.4,1.1,8.2
				c0.1,5.7-0.8,11.5,0.3,17.2c0.4,1.9-1.1,2-2.5,1.7c-0.5-2.6,1.1-6-2.5-7.4c-4.9-2-8.8-5.6-13.4-7.9c-2.5-1.2-3-2.7-2.7-5.2
				c1.7-13.9,3.5-27.8,4.9-41.7c0.5-4.9,1.5-9.9,0.6-14.9c-3.8,23.9-4.9,48.2-10,71.9c-2-2.5-4-4.9-4.6-8.1c-0.3-1.8-1-3.4-2.9-5.3
				c0,1.9,0,3,0,4.2c0.2,7.4,0.7,14.7,0.6,22.1c-0.1,2.9,0.9,6.3-1.6,8.9c-0.3-0.6-0.3-1.7-1.4-1.4c-3.6,1.3-7.2,1.4-10.9,0.7
				c-1.2-0.2-2.2,0.7-3.4,1.1c-4.5,1.5-9,1.3-13.4-0.6c-1.7-0.7-1.7-2.7-2-4.3c-0.2-1.1,1-1.2,1.8-1.3c7.9-0.8,15.8-2.5,23.8-2.6
				c1.5,0,2.4-1,3.6-1.3c0.6-0.2,1-0.4,1-1.1c0-0.8-0.6-1.1-1.3-1.1c-1.7-0.1-2.9-0.8-3.3-2.6l0,0c1.1,0,2.2,0.1,3.3-0.1
				c1.8-0.4,1.9-1.9,1.9-3.3c0-1-0.2-2.5-1.3-2.2c-3.1,0.9-5.9-0.5-8.8-0.8c-4.4-0.4-8.9-0.1-13.4,0c-2.7,0-2.7,0.1-1.2,2.5
				c-6.5,0.3-12.3-3.1-18.7-2.7c3.6,1,6-3.5,9.8-2.3c0.4,0.1,1.7-0.9,1.2-2.2c-0.3-0.7-0.5-1.5,0.5-1.9c4.4-1.3-0.1-6.4,3.4-8.2
				c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.2-0.3-0.3-0.5-0.4c0.3-1.6,2.9-2.7,0.8-4.8c-1.5-1.6-0.9-5.5,1-6.7c2-1.3,2.7-3.4,3.8-5.3
				c0.4-0.6-0.1-1.9-0.9-1.5c-2.9,1.2-3.2-1-4.1-2.6c1.4-1.4,4.8,0.3,4.9-3.1c1-3,3.5-1.9,5.6-2c0.1,1.7,0.7,2.2,2.7,2
				c2.8-0.3,4.3-2.3,6.2-3.8c0.4,0,0.8,0,1.2,0c2.5,2.1,4.6-0.4,6.9-0.7c1.6-0.3,1.6,1.1,2,2c1.1,2.8,2.2,5.7,3.3,8.5
				c0.4,1,1,1.8,2.2,1.5c1.4-0.4,1.1-1.5,0.8-2.4c-0.7-3.1-1.7-6.1-3.7-8.6c-1.2-1.5-1.5-2.9-1.5-4.7c0.1-2.5,0.6-3,3-1.8
				c5.1,2.6,5.1,2.5,4.8-3.2c0-0.3-0.1-0.6-0.1-1c0.8-5,0.2-6.3-4-8.5c-1.6-0.8-3-1.9-4.5-2.8c-0.5-1.2-0.8-4.2-0.8-6.1
				c0-1.9,1.3-5.1-3-3.6c-0.8,0.3-2-0.9-1.1-1.6c1.8-1.3,3.5-3.8,6.2-2.7c2.1,0.8,4.5,0.8,6.4,2.7c0.4-5,0-9.5,0.8-14.1
				c0.6-3.2,1.1-6.3,0.1-9.8c-1.3-4.4-2.1-9.1,0.2-13.9c0.8-1.7,1.4-4.6-0.7-6c-1.3-1-1.8-1.3-0.4-2.4c0.4-0.4,1-1,0.4-1.7
				c-0.6-0.6-1.2-0.1-1.8,0.2c-0.6,0.3-1.1,0.7-1.7,1c-4.8,3-9.5,6.1-14.3,9.1c-0.6-1.6-1.6-1.7-3.1-1.2c-2.1,0.7-4,2-6.6,1.9
				c0.8-1.2,2-1.6,3-2c4.1-1.8,8.1-3.7,12.3-5.3c1.7-0.7,2.4-1.6,2.3-3.4c1.6-0.3,1.3-1.6,1.1-2.6c-0.6-2.3,1.3-5.1-1.2-7.1
				c-0.3-0.6-0.5-1.2-0.8-1.8c3.6-0.7,7.3-0.7,11-1.1c6.4-0.7,12.7-1.9,19.2-1.8c0.8,0,1.7-0.2,1.8-1.1c0.1-1.1-1-1-1.7-1
				c-3.3,0-6.5,0.1-9.8,0c-8.3-0.5-16.4,1.3-24.6,2c-6.8,0.6-13.7,0.8-20.5,1.9c1.4,0.1,2.8,0.1,4.1,0.1c5.3,0.3,10.7-0.1,16,0.9
				c-0.4,1.5-2.4,0.6-2.9,1.9c-0.8,0.2-1.7,0.3-2.5,0c-9.9,0.2-19.7,1.1-29.7,1c-5.8-0.1-11.7,1-17.6,1.5c0.1-1,1.7-1,1.6-2.4
				c-1.8-0.5-3.3-0.3-4.5,1.4c-0.4,0.6-0.6,1.4,0,1.8c1.1,0.7,2-0.4,2.9-0.7c0.2,0.6,0.4,1.3,0.6,1.9c-1.6,1.2-3.1,0-4.7,0.1
				c-0.9,0-1.9,0.4-2.5-0.7c-0.1-0.2-0.1-0.3,0-0.5c1.3-0.3,2.7-0.3,2.5-2.2c-0.1-0.7,0.1-1.6-1-1.7c-1.4-0.1-2.6,0.1-2.9,1.9
				c-4.9,0.3-9.9,0.3-14.8,1.1c-2.4,0.4-5.2-0.7-7.3,1.3c-1.5,0.8-3.1,0.7-4.8,0.4c-2.8-0.4-5,0.1-7.1,2.7c-1.7,2.2-1.9,4.4-2.5,6.7
				c-1.8,6.5-3.7,12.9-5.5,19.4c-0.3,1.2-0.8,1.8-2,1.6c-3-0.4-3.5,1.3-3.3,3.7c0.2,3.6,0.7,7.2,0.5,10.7c-0.2,2.3,0.9,4.1,1,6.2
				c0.1,1.3,1.7,1.4,2.3,0c0.5-1.2,1-1.6,2.2-1.1c1,0.4,2.1,0.4,2.5-0.8c0.3-1-0.7-1.4-1.5-1.9c-0.5-0.3-1.4-0.3-1.1-1.4
				c2.1-0.6,3.3-2.1,3-5.7c2.2,3.1,3.9,3.6,5.9,1.4c0.3-0.4,1.1-0.6,1.4,0c0.5,0.8-0.2,1.1-0.7,1.5c-0.9,0.7-2.4,1.8-1.4,2.8
				c1.3,1.3,2.2,3.7,4.3,3.4c1.7-0.2,3.2-0.2,4.7,0.4c0.7,0.3,1.3,0.3,1.7-0.4c0.3-0.5,0.3-1.2-0.1-1.5c-2.1-1.7-0.3-3.6,0.1-5
				c0.4-1.4,2.1,0.5,3.4,0.5c0.6,0,1.3,0.6,1.9,0.2c3.3-2.2,4.4-1.3,4.1,2.5c-0.1,1.3,0.6,2.5,2.1,2.2c1.6-0.3,1.6-1.5,1.4-2.8
				c-0.4-1.9-0.9-3.8-1.3-5.7l0,0c0.3,0.1,0.7,0.1,0.9,0.3c0.9,0.6,1.3,2.3,2.8,1.7c1.7-0.6,0.9-2.3,0.9-3.4c0.1-2.6,2.9-3,3.8-4.9
				c0.2-0.4,2.9-0.2,2.4,1.8c-0.7,2.7,1.1,1.9,2.3,2c-0.2,4.3-0.5,8.6-0.7,12.9c-1,1.1-1.5,2.5-1.4,4c-0.4,0-0.7,0-1.1,0
				c-0.2-2-1.8-1.6-3-1.6c-4.2,0-8.3,0.1-12.5,0.1c-2.1,0-3.1-0.6-1.4-2.6c0.8-1,1.1-2.1,1-3.3c-0.1-1.1-0.5-1.9-1.8-1.9
				c-1,0-1.6,0.7-1.7,1.7c-0.1,1.3-0.1,2.6,0,3.9c0.1,1.7-0.1,2.5-2.3,2.5c-2.1,0-4.3-0.6-6.1-0.3c-5.1,0.8-10.2,1.1-15.3,0.5
				c-2-0.2-1.9,0.8-1.8,2.1c-0.8,6.1-0.2,12.2-0.4,18.4c0,0.4,0.3,0.9,0.6,1.1c2.6,1.5,4.1,4.1,6.4,6c0.9,3.7,3,5.1,6.5,4.3
				c1.2-0.3,2.3-0.6,3.5-0.6c2.2,0.1,2.3-1,1.7-2.6c4.6-0.8,8.9-2.9,13.7-2.1c0.1,1.6,0.3,3.2-0.4,4.8c-0.4,0.9-0.6,2,0.8,2.4
				c1.1,0.3,2.2,0.4,2.5-1.2c0.2-1,0.9-1.4,2-1c0.4,1.1-2.3,1.2-0.8,2.5c0.8,0.7,2.2,0.8,2.6-0.1c0.9-2,2.9-2.6,4.2-4
				c0.5,0.2,1.1,0.2,1.5-0.2c0.7-0.6,1.3-1.8,2.4-0.8c0.9,0.8-0.1,1.8-0.3,2.7c-0.1,0.8-0.7,1.3-1.5,1.7c-2.8,1.2-5.3,3-8.3,3.8
				c-0.4-1.6-1.3-1.3-2.4-0.7c-2.4,1.3-5,2.1-7.2,3.7c-1.5,1.1-3.4,1.7-2.9,4.1c-0.1,2.6,1.9,4.1,4,2.7c3.7-2.6,8.1-3.4,12-5.3
				c2.3-1.1,2.1-0.3,2.2,1.6c0.1,2-0.5,3.8-0.6,5.7c-0.1,1.5-0.7,1.7-2.1,2.1c-2.4,0.6-4.2-1.6-6.4-0.9c-0.9,0.3-2.5,0.2-1.9,1.5
				c1,2,3.8,2.9,3.8,5.6c3.6-0.5,4.2,1.7,4.1,4.5c0,0.2,0.4,0.6,0.6,0.7c2.7,1,2.6,2.7,0.6,5c3.3-0.4,5.9-0.6,8.4-1
				c5.5-0.8,5.6-0.7,5.2,4.9c-0.1,1.1-0.1,2.2-0.2,3.3c-0.1,0.9-0.4,1.7-1.5,1.9c-3,0.6-6.2,0.8-9.1,1.9c-0.1-1.5-1.3-1.5-2.4-1.4
				c-1.4-0.3-1.3-1.5-1-2.4c1-3-0.6-4.8-2.7-6.3c-5.4-3.7-9.4-8.8-13.7-13.5c1-0.1,2,1.3,2.9-0.1c0.6-0.9,0-1.7-0.6-2.3
				c-2.9-3.2-6.9-5.3-8.5-9.7c-0.2-0.7-1.2-1.1-1.9-0.7c-0.7,0.4-0.6,1.1,0,1.6c1.3,1.1-0.3,2.2,0.1,3.3c-2.6-1.6-4.1-4.1-6-6.3
				c0.2,0,0.5-0.1,0.6-0.1c2,0.8,3.6,0.4,3.9-1.9c0.3-2.1-0.3-3.7-3.1-3.1c-0.8,0.2-1.4-0.5-2-1c-1.9-1.6-3.8-3.2-5.7-4.7
				c-0.8-0.7-1.7-1.6-2.9-1.1c-1.3,0.6-0.4,2-0.6,2.9c-0.7,3.4-1.1,6.6,2.3,9.2c3.8,3,6.3,7.4,11,9.4c6,2.5,6,2.7,3.7,8.8
				c-0.8,0.5-1.4,1.3-1.7,2.2c-1.6-0.1-1.7-5.6-4.5-1.3c0,0.1-0.5,0-0.5-0.1c-1.4-2.7-2.9,0.1-4.4,0c-1.3-0.1-1.9,1.2-1.3,2.3
				c0.5,1,0.6,3,2.7,2.1c1.5-0.7,2,0.1,2.2,1.4c0.1,1.2-0.3,2.1-1.7,2.2c-1,0.1-2.1-0.5-2.9,0.5c0.6,0.8,1.5,0.6,2.2,0.8
				c0.1,0.9-0.6,2.4,0.7,2.4c1.6,0,3.6-0.1,4-2.4c0.6,0.2,1.2,0.4,1.8,0.6c1.5,1.5,7.4,1.6,8.7,0.1c1-1.1-0.2-1.4-0.9-1.8
				c-0.1-0.9-0.2-1.7-0.3-2.6c-0.2-1.1-0.9-2.6,0.6-3.1c1.2-0.4,2.6,0.1,3.2,1.4c0.6,1.3,1.8,1.5,2.9,1.8c3,0.7,4,2.3,3.1,5.3
				c-0.5,1.6-1,3.3,2,3.4c1.3,0.1,3.1,1.4,3.8,3.1c-0.8,0.2-1.7,0.7-2.5,0.7c-10.3,0.4-20.6,2.2-30.9,2.1c-2.9,0-2.9,0-2.5,2.8
				c0.1,0.6-0.3,0.3-0.5,0.4c-2,0.9-1.4,2.5-1,3.9c0.2,0.8,0.4,1.4,0.2,2.2c-0.9,4.4-0.9,4.4,3.9,4.2c-5.2,0.3-5.6,3-3.8,6.7
				c0.3,0.5,0.1,1.3,0.1,1.9l-0.1,0.2l-0.2-0.1c-3.3,1-1.1,3.4-1.8,5.6c1.3-1.2,0-3.1,1.7-3.6c0.7,1.2,0.6,2.4,0.3,3.6
				c-0.1,2.1,0.9,4.4-1.2,6.3c-0.7,0.6,0.4,1.5,1.1,2c-0.2,0.9-0.7,1.9-0.5,2.8c0.8,4,0.2,8.1,0.7,12.2c0.1,1.1,0,2.1-0.8,2.9
				c0.1,1-0.4,2.3,1.3,2.4c1.6,0.1,5.6-3,6-4.8c2.5-2.3,4.2-1.7,5.8,2.1c0.6,2.6,6.8,6.4,9.6,5.5c4.4-1.5,8.6,0,13-0.1
				c1.3,0,3,0,4.2,1.5c-2.7,1.5-5.5,0.8-8.1,1c-1.2,0.1-2.2,0.1-2.2,1.7c0,1.4,0.1,2.7,1.9,2.8c1.5,0.1,3,0,4.5-0.4
				c1.9-0.4,3.9-0.8,5.9,0.4c-1.2,1-2.1,0-3,0.1c-1,0.1-2.3,0.1-2.4,1.2c-0.1,1.3,1.2,1.2,2.2,1.3c1.6,0.1,3.3,0.1,4.9,0.2
				c0.5,0.8,0.2,1.6-0.2,2.4c-1.4-0.2-2.8-0.4-4.2-0.5c-1.4-0.1-3.3-0.6-3.9,0.9c-0.6,1.8,1.5,2,2.6,2.8c0.2,0.1,0.2,0.3,0.6,0.8
				c-4.4-0.1-8.1,2.5-12.5,1.2c-4-1.2-8.2-2.1-12.5-2.2c-1.3,0-2.5-0.2-3.6,1.2c3.5,1.8,7.1,0.5,10.6,1.3c-2.3,1.3-4.5,1.1-6.8,0.5
				c-1-0.3-2.1-0.2-2.4,1.1c-0.3,1.2,0.9,1.2,1.6,1.6c2.6,1.9,5.9,0.8,8.7,2.3c4.6,2.5,9.4,4.4,14.2,6.5c0.9,0.4,2.1,1.3,3,0.2
				c1.1-1.2-1.7-1.2-0.8-2.7c2.9,0.9,5.9,1,9,0.2c0.8-0.2,1.8-0.5,2.2,0.8c0.5,1.7,2.1,0.7,3.2,1.3c-0.8,0.5-2,0.7-1.6,1.8
				c0.3,1,1.4,1.1,2.2,0.9c5-1.2,9.9-0.5,14.9-0.4c0.9,0,1.8,0.1,2.6-0.2c1-0.3,1.8-1,1.7-2.1c-0.1-1.2-1.2-1-2-1
				c-0.5,0-0.8-0.1-0.9-0.8c0.5-0.2,1.1-0.5,1.7-0.8c1.2-0.6,2.1-1.6,1.7-2.8c-0.6-1.5-1.9-0.4-2.8-0.1c-1.4,0.4-2.7,0.8-4.2,0.6
				c-4.7-0.5-9-2.6-13.6-3.8c-0.7-0.2-1.1-0.6-1-1.4c0-1.8,1-4-0.4-5.2c-1.6-1.3-1.4-1.9-0.3-3.2c1.1-1.3,1.9-3.3,4.3-2.1
				c0.8,0.4,1.6,0.4,2.5,0c2.5,7.1,5.7,8.4,12.5,5c1.5-0.8,3.1-1.4,4.8-1.7c2.8-0.6,5.6-1.6,4.3-5.5c2-0.6,3.9-1.7,6.1-1.3
				c-0.7,1.3-2.2,1.6-3.3,2.4c-0.5,0.3-1.4,0.5-1.1,1.3c0.3,1,1.4,0.8,1.9,0.5c3.9-2.4,5.9,1.9,8.9,2.5c0,1-1.9,1.1-1.3,2.5
				c0.2,0.6,0,1.4,0.9,1.5c2.8,0.3,5.6,0.5,8.4,0.7c0.9,0.1,1.6-0.2,2.3-0.9c1.5-1.7,7.1-2.4,9.6-1c-4.6-1.2-7.5,3-11.6,3.4
				c-0.6,0.1-1,0.4-1,1c0,0.6,0.3,1.1,0.8,1.3c1,0.4,2.2,0.4,3.2,0.1c5-1.4,10.1,0.2,15.1-0.4c0.5-0.1,1.4-0.2,1.5,0.8
				c-1,0.7-1.7,2-3.3,1.2c-2.7-1.3-5.1-0.2-7.7,0.9c-5.6,2.4-11.1,5.3-17.5,2.4c-0.6-0.3-1.5,0.2-2.2,0.4c-0.9,0.2-2.3-0.4-2.4,1.1
				c0,1.6,1.7,0.8,2.9,1.5c-1.1,0.2-1.4,1.4-2.4,0.9c-1.6-0.8-3.4,0.3-5.1-0.6c-1-0.5-2.6,0.1-2.7,1.6c0,1.5,1.4,0.9,2.3,1
				c0.9,0.1,1.7,0.2,2.6,0.4c6.6,1.4,13.2,1,19.8,0.4c4.4-0.4,8.6,0.3,13.2,2.1c-2.3,1.1-4.2,1.8-6.1,2.3c-4.8,1.2-9.5,0-14.3-0.1
				c-14.9-0.3-29.8,0.5-44.6-1.3c-2-0.2-4.4-0.1-5.7-1.9c-1.5-1.9-3.4-2.2-5.5-2.2c-3.1,0-6.1,0.8-9,1.7c-9,2.8-18.2,1.6-27.3,1.2
				c-9.1-0.3-18.1-0.5-27.2,0.1c-5.5,0.3-11.1,2.2-17.1-0.2c2.1-1,1.8-3.7,4-4.4c0.6-0.2,1.2-0.7,0.9-1.5c-0.3-0.9-1.2-1-1.9-0.8
				c-1.9,0.5-3.9,0.8-5.6,1.8c-8.2,4.5-15.2-0.4-22.7-2.5c3.2-0.4,5.4-2.1,7.2-4.6c0.8-1.1,2.5-2.4,1.1-3.5
				c-1.1-0.8-3.5-0.4-4.3,1.1c-1.6,3-4.6,3.5-7.3,4.5c-2,0.8-4.2,1.1-5.3,3.2c-0.4,2.3,0.9,5.4-3.1,5.9c0-94,0-188.1,0-282.1
				c0.4-0.3,0.9-0.6,1.3-1c2.8-2.6,4.4-2.4,6.8,0.9c0.7,1,1.8,1.1,2.8,1.2c1.3,0.2,2.6,0.2,3.8,0.3c-0.3,1.5,0.7,1.6,1.7,1.5
				c0.6-0.1,1.7,0,1.7-0.6c-0.1-1.2-0.1-2.6-1.7-3c1.6-1.9,4.6-2.1,5.8-0.1c1.5,2.5,3.4,3.1,6.1,2.3c1-0.3,2.2-0.1,3.2-0.1
				c-0.1,0.8-1,1.9,0.9,1.9c1.4,0,2.8,0,4.2,0c-1.8-0.4-2.7-3.3-5.1-1.9c-0.4-1.7,0.9-2.3,2.2-2.6c1.6-0.4,3.2-0.2,4,1.3
				c1.2,2.2,3,2.4,5.2,2.6c2.2,0.2,4.2-0.7,6.4-0.7c0.5,2,2.2,2.1,3.8,2.5c0.5,0.1,0.9-0.4,0.7-0.8c-0.6-1.3-1.5-2.3-3.1-2.4
				c1.6-2.1,3.3-4.1,5.9-1.2c0.8,0.9,1.4,0.5,1.8-0.3c0.5-1.1,1-2.1,0.7-3.4c-1-4.3,0-8.6,0.4-12.8c0.4-3.8,0.3-7.6,0.5-11.3
				c0.1-2-0.9-2.5-2.7-1.7c-1.4-1-3-0.5-4.5-0.6c-0.1-0.3-0.1-0.8-0.3-0.9c-2.4-1.5-14.5,0-16.4,2c-1,1-0.7,2,0.5,2.7
				c0.7,0.4,1.6,0.6,3.3,1.2c-2.6,0.2-4,0.7-3.8,2.9c0.1,1.2-0.9,1.4-1.8,1c-4.6-2.1-9.8-1.9-14.5-3.3c0-0.3,0.1-0.6,0.1-0.9
				c1.4-0.1,3.1,0.2,3-1.9c-0.1-2-1.8-1.8-3.2-2c-0.9-2.1-2.6-2.9-4.8-2.6c-2.1,0.3-4.3-0.6-6.2,0.7c-2.1-1.8-4.4-1.3-6.7-0.7
				c-0.7,0-1.3,0-2,0c0-3.1,0-6.1,0-9.2c2.8,0.1,5.6,0.3,8.4,0.2c1.1,0,1.6-0.1,1.1,1.3c-1.3,4.3-1.3,4.3,3.2,4.3c0.5,0,1.1,0,1.6,0
				c1.4,0.2,2.8,0.4,4.1-0.7c1.4,0.8,2.8,1.1,4.2,0c0.3-0.2,0.4-1.1,0.3-1.5c-0.8-1.6-2.4-1.1-3.7-1.1c-0.1,0-0.2,0-0.3,0
				c-0.1-0.3-0.2-0.5-0.3-0.8c1.7-0.5,4.4,0,4.3-2.4c0-2.8-2.8-0.9-4.2-1.6c-0.1,0-0.2,0-0.3-0.1c0-0.1,0-0.3,0-0.4
				c0.8-0.3,1.6-0.6,2.5-0.8c1.1-0.3,2.2-0.6,2.1-2.1c-0.1-1.5-1.3-1.1-2.2-1.3c-0.5-0.1-0.8-0.2-0.7-0.8c1-0.8,2.9-0.7,2.9-2.6
				c0-2.2-2.2-1.2-3.4-2c1.4-1,3.5-0.7,3.4-2.9c-0.1-2.2-2.3-0.8-3.4-1.7c0.8-1.5,3.8-0.6,3.4-3.2c-0.4-1.9-2.5-0.4-3.4-1.6
				c0.9-1.4,3.5-0.4,3.5-2.9c0-1.9-2.3-1.1-3-2.3c1.6-0.8,4.7-1.6,2.2-3.5c-1.5-1.1-4.4-3-7.1-1.3c-1.2-0.5-2.5-0.6-3.7,0
				c-1-0.7-1.9-0.9-2.7,0.2c-2-0.1-4.1-0.2-6.1-0.2c-0.8-0.8-1.7-0.8-2.7-0.7c0-0.9,0-1.7,0-2.6c1.4,0,2.9,0,4.3,0
				c0.5-0.2,1.4-0.3,1.5-0.5c0.2-3.3,2.9-6.2,2.2-9.6c-1.1-5.8-1.1-11.7-1.8-17.6c-0.4-3.7,0.8-7.6-1-11.1c-0.3-1.1-0.3-2.2,0.2-3.3
				c1.2,0.6,1,1.9,1.1,2.9c1.3,11.9,2.7,23.9,3.7,35.8c0.3,3.4,1.6,4.1,4.5,3.3c2.3,1.4,3.8-0.2,5.5-1.5c1.1-0.9,3.5-0.6,3.1-2.7
				c-0.3-1.9-2.5-0.6-3.6-1.3c-0.1-0.1-0.2-0.4-0.3-0.6c1.3-0.9,3.9-0.4,3.9-2.7c0-2.3-2.6-0.9-3.7-1.9c1.2-1,3.5-0.6,3.4-2.8
				c-0.1-2.2-2.3-1-3.3-1.8c0.7-1.5,3.5-0.6,3.4-3c-0.1-2.5-3-1.1-3.9-2.6c0.5-1.1,1.4-0.6,2-0.8c1.7-0.4,2.6-2.7,1.3-3.7
				c-2.7-2-2.7-5.7-5.2-7.8c-1.4-1.2-0.7-2.4,0.9-2.3c2.1,0.2,3.3-0.8,4.5-2.1c2-2.1,4.2-2.4,6.9-1.4c3.3,1.2,6.6,1.3,10,0.5
				c2-0.5,3.7,1.1,3.2,3c-0.3,1.2-1.3,3.2-1.6,3.3c-3.5,0.6-1.2,4.2-3,5.3c-2.8,1.6-2.7,3.2-0.5,5.2c0,0-0.2,0.5-0.4,0.8
				c-2.1,2.6-1.3,4.1,1.8,4.8c-1,0.8-2.8,0.5-3,2.2c-0.2,1.8,1.8,1.8,2.6,2.7c-0.9,1.2-3.1-0.1-3.1,2c-0.1,2.3,2.5,1.8,3.4,3
				c-1.1,0.7-3.2-0.4-3.4,1.7c-0.2,2.1,2.2,1.8,3.3,3c-1.5,0.4-4.2,0-1.7,2.7c0,0.7,0,1.3,0,2c-4.4,3.3,0.9,3.5,1.9,5
				c-1.3,0.5-3.1-0.3-3.5,1.5c-0.5,2.5,2.6,1.7,3.3,3.2c-1.1,0.6-3-0.3-3.3,1.5c-0.3,2.2,1.9,2.3,3.3,2.9c-1.2,1-3.3,0.2-3.4,2.2
				c-0.1,2.2,2.1,1.8,3.5,2.4c-1,1.4-3.3,0-3.5,2.2c-0.3,2.5,2.7,1.5,3.5,3c-2.2,0.5-5.3,0.3-2.5,3.8c0.2,0.2,0.2,0.7,0,0.9
				c-2.8,3.4,0.6,3.5,2.5,4.5c-1.2,0.8-3.5,0-3.5,2.2c0,2.4,2.4,2.3,3.7,2.3c4.8,0.2,9.5,1.5,14.3,0.3c1.2,0,2.4,0,3.6,0
				c1.4,1.6,2.6,1.5,2.6-0.7c0-7,0.3-14-0.1-21c-0.1-1.1-0.6-1.2-1.3-1.4c0.3-3.3-0.9-6.8,0.7-10c-0.5-1.2,2-3.1-0.8-3.8
				c-0.2-1.2-0.2-2.3,0.2-3.4c0.7-2.1,0.9-4.3,0.2-6.6c-0.2-1.5-0.1-3,0.2-4.5c0.7-3.8,1.4-7.7,1.5-11.6c0.4-10.6,0.4-21.3,0.6-31.9
				c0-2-0.6-3.1-2.9-3.5c-5.3-1-10.7,0-16-0.9c-2-0.3-3.9,1.2-6,1.4c-1.2,0.1-1,1.6-0.2,2.4c0.6,0.7,1.6,1,2.5,1.6
				c-3.3,0.9-4.3,4.6-2,7.3c0.8,1,2.2,2.1,1.3,3.3c-1,1.4-2.6,0.9-4.2,0.4c-3.6-1-7-3.4-10.9-0.9c-0.7,0.5-1.5-0.1-1.9-0.8
				c-0.6-1.2,0.4-1.2,1.1-1.4c2.8-0.8,2.6-3.1,2.6-5.3c0-2.5-2.1-2-3.8-2.7c1.2-0.5,2.2-0.7,3-1.2c1.2-0.7,1.2-2,0.1-2.7
				c-4.2-2.7-8.8-3.9-13.8-2.4c-1.6-0.2-3.3-0.4-4.9-0.7c-1.1,0.2-2.9-1.8-3.1,0.6c-0.1,1.1-0.9,2.2-0.5,3.3
				c-0.4-0.2-0.9-0.4-1.3-0.7c0-2.8,0-5.7,0-8.5c1.4-0.9,2-2.7,3.6-3.3c0,0.5,0.1,1.1,0.1,1.6c0,2.5,1.6,3.2,3.7,2.8
				c2.2-0.4,1.5-2.2,1.4-3.7c-0.1-3.3-2.1-4.2-4.9-4c-1.2-0.8-2.4-1.4-3.9-1.3c0-4.8,0-9.6,0-14.4c5.1,0.4,10.2,1,15.3,1.3
				c13.9,0.8,27.7,1.4,41.6,2.1c1.7,0.1,2.3,0.6,2.3,2.3c-0.1,3.6-0.1,7.1-0.1,10.7c0,1.3-0.1,1.9-1.8,1.8
				c-6.2-0.5-12.4-0.8-18.6-1.1c-1.2,0-3.4-0.9-3.6,0.9c-0.3,2.2,2,2.1,3.5,2.5c0.1,0,0.1,0.3,0.2,0.5c-0.5,0.1-0.9,0.2-1.3,0.4
				c-0.9,0.5-2.7-0.3-2.6,1.7c0.1,1.5,1.5,1.4,2.4,1.7c2.7,0.9,5.4,1.4,8.3,1.5c4.4,0.1,8.8,0.3,13.1,0.9c2.2,0.3,2.5-0.4,2.5-2.1
				c0.1-6.2,0.3-12.4,0.5-18.6c0.6-15,0.6-30,0.9-44.9c0.1-6.7,0.4-13.4,0.5-20.2c0-1.9-0.1-3.9-2.8-4c-5.2-0.3-10.5-0.5-15.7-0.8
				c-2.3-0.2-2.9,0.9-2.7,2.9c-2.6,0.8-5.1,0.5-7.7-0.1c1.5-2.2-0.8-2.3-1.7-2.6c-1.8-0.5-3.6-0.6-5.5-0.7c-9-0.7-18-1.3-27-2
				c0-5.5,0-10.9,0-16.4c6.9,0.5,13.8,0.9,20.7,1.4c5.3,0.4,10.6,0.7,15.9,1c12,0.6,23.9,1.9,35.9,2.8c7.4,0.6,14.8,0.9,22.2,1.5
				c12.6,1,25.1,2.7,37.7,3.1c4.5,0.2,9,0.2,13.4,0.8c12.1,1.6,24.4,2,36.5,3.1c7.5,0.7,15.1,0.9,22.6,1.6c12.5,1.1,25,1.9,37.5,3
				c6.3,0.5,12.6,1,18.9,1.5c10.3,0.8,20.7,1.6,31,2.4c4.9,0.4,9.8,0.4,14.7,0.8c11.5,0.9,23,2.1,34.5,3c5.5,0.4,11.2,0.1,16.7,1
				c11.9,1.8,23.9,2,35.8,3.1c5.9,0.6,11.8,0.3,17.7,0.7c1.5,0.1,3.3-0.1,3.2,2.3c-0.8,0.5-2.4-0.3-2.4,1.4
				c-0.1,1.7,1.5,1.4,2.5,1.8c0.2,0.1,0.4,0.1,0.6,0.1c1.1,2.3,3.1,1.8,5,1.4c2.7-0.4,5.2-1.5,8-1.2c0.5,0.2,1.1,0.5,1.5,0.4
				c6.1-1,12.2-2.1,18.3-3.1c6.7-1.2,13.5-2.3,20.2-3.6c1.7-0.3,2,0.8,2.9,1.6c0.8,0.7,0.6,1.2,0.1,2c-3.3,5.1-8.7,6.2-14,7.1
				c-0.9,0.2-1.8-0.1-2.1,0.9c-0.3,1-0.5,2.2,0.3,3.1c0.6,0.8,1.5,0.6,2.4,0.5c5-1,10-2.2,15.1-2.8c8.8-1,17.4-3.6,26.3-4.2
				c1.5-0.1,3-0.8,3.3-2.2c1-4.2-4.6-9.3-8.6-7.6c-3.4,1.5-6.9,0.2-10.2,1.4c-0.8,0.3-1.8-0.3-1.9-1.3c-0.2-1.3,0.9-1.3,1.7-1.4
				c4.8-0.1,9.3-2.5,14.3-1.6c0.8,0.1,1.6-0.6,1.8-1.6c0.6-1.5,0.8-2.7-1.6-2.5c-5.7,0.6-11.3,1.6-16.8,3c-1,0.3-2.2,0.5-2.4,1.8
				c-1,0.5-2,0.8-2.8-0.4c0-1,0-2,0-3c3.5-2.2,7.8-0.8,11.7-2.5c-1.6-1.1-3.2-1.2-4.8-0.7c-2.1,0.6-4.1,0.8-6.1-0.3
				c-0.9-0.8-1.7-1.5-3-2.6c3.8-0.2,6.5-1.4,6.9-5.3c0.2,0.6,0.2,1.5,1,1.4c4.5-0.6,8.9,2.9,13.5-0.1c1.1-0.7,2.7-0.7,4.1-1.1
				c6.2-1.6,12.6-2.2,18.7-4.4c-0.3-1-0.9-1-1.6-1.1c-1.5-0.2-3,0.1-4.5,0.4c-1.6,0.4-7-2.2-6.8-3.2c0.5-2.8,1.7-5.5,4.1-7.1
				c1.4-1,3-1.6,3.7-3.4c0.6-1.7,0.1-3.2-0.7-4.9c0.9,0,1.5-0.1,2,0c2.8,0.5,3.7-0.7,3.4-3.4c-0.2-2.4-0.1-4.8-0.1-7.2
				c0-1.2-0.1-2.1-1.7-2.2c-3-0.1-6.4,2-8.9-1.4c-0.1-0.2-0.6,0-1,0c-5.8,0.6-11.4,2.1-17,3.5c-0.7,0.2-2.1,0.5-1.7,1.5
				c0.9,2.1-0.8,2.1-1.9,2.6c-1.8,0.9-3.6,1.4-5.6,1.7c-2.3-1.1-4.8-1.2-7.3-1.3c-0.7,0-1.4,0-1.6-0.8c-0.2-1.1,0.6-1.2,1.4-1.4
				c0.5-0.1,1-0.3,1.6-0.5c0.6-0.2,1.5,0.1,1.4-0.9c-0.1-0.8-0.8-0.9-1.5-1c-3-0.3-6.1-0.6-9.5-1c2.7-0.8,5.3,0.2,7.6-1.3
				c-3.1,0.1-6-1.8-9-0.8c-4.9,1.6-4.8,1.8-9.1,0.4c1.7,0,3,0,4.3,0c1.1,0,2.3,0.4,2.3-1.4l0,0c1.8,0,3.6-0.1,5.4,0
				c2.2,0,3.5-0.8,3.1-3.2c0.7,0,1.3,0,2,0.1c-1.1,1.4-2.7,3.3,0.4,3.7c2.5,0.3,5.1,1.5,7.8,0.5c0.7-0.3,1.6-0.2,1.7,0.9
				c-2,0.6-4.2-0.6-6.1,0.8c2-0.5,4.5,3.4,6.2-0.7c1.2,0,2.3-0.1,3.5-0.1c1.5,0,3.1,0.2,3.7-1.7c0.1-0.4,0.2-0.8,0.7-0.9
				c3.8-1.1,7.8-2,10.8-4.8c0.7-0.6,1.2-1.6,2.3-1.6c0.2,0.6,0.4,1.3,1.3,1.1c1.9-0.5,3.8-0.9,5.5-1.9c2.1-1.2,1.6-2.1-0.3-2.9
				c4.4-3,9-5.3,14.3-5.9c3.2-0.4,4.6-1.6,3.3-4.8c-0.2-0.5-0.3-1.1,0.2-1.5c2.1-1.7,2.1-3.4,0.1-5.2c-0.7-0.7-0.6-1.6-0.6-2.5
				c0-2.2,0.2-4.4,0-6.5c-1.2-9.7-0.2-19.5,0.3-29.2c0.4-7.6,0.4-15.2,0.2-22.8c-0.1-2.7-0.1-4.3,2.8-4.7c1-0.1,2-0.2,2-1.6
				c0-1.1-0.2-1.8-1.4-2.6c-2.2-1.4-3.7-3.5-3.4-6.4c0.2-2.4,0-4.8,0-7.2c0.1-5-2.6-7.8-7.5-7.1c-4.6,0.6-9-0.5-13.2-1.5
				c-6.1-1.3-12.4-1-18.5-1.8c-1.9-0.2-2.9,0.9-2.8,2.8c0.1,4.5,0.3,9.1,0.4,13.6c0.1,4,0.1,8.1,0.1,12.1c0,2.1-0.6,2.9-3.3,2.8
				c-4.5-0.2-8.8-0.9-13.3-1.5c-9.4-1.3-18.8-2.5-28.2-3.8c-1.7-0.2-2.1-1.1-2.5-2.8c-0.9-3.7,0.8-7.2,0.1-10.9
				c-1.2-6.4-0.7-12.9-0.4-19.2c0.2-4.4-0.6-8.6-0.4-13c0.1-4.5-0.7-9.3-0.3-13.7c0.5-5-0.6-9.9,0.3-14.7c1.1-6-1.9-11.9-0.8-18
				c0.5-2.6-0.5-5.4-0.2-8.1c0.5-5.4,0.3-10.8,0-16.3c-0.1-2.6-1.2-5.4,0.6-7.9c-3.3-0.5-6.3-1.8-9.4,0.1c-0.7,0.4-2,1.4-3,0.3
				c-0.8-0.9-0.3-2.1-0.1-3.2c0.1-0.4,0.3-0.8,0.4-1.2c0.7-1.9,0.4-2.9-2-2.5c-6.5,1-13,1.2-19.5,1.3c-2.3,0-4.6,0.8-6.7,2.7
				c-2,1.8-2.1,4.7-4.7,6.2c-1.6,1-3.4,1.3-4.8,2.3c-3.8,2.7-7.1,2-9.3-1.9c-1-1.8-2.3-3.9-5.2-2.7c-2.6,1.1-1.6,3.1-1.1,4.7
				c1.7,6.3,4.8,11.9,9.7,16.3c1.7,1.5,1.4,2.2-0.6,2.8c-4.7,1.4-9.2,3.2-13.8,4.7c-6.8,2.2-13.9,3.8-20.7,5.8c-0.2-1.3,2.7-2.6,0-4
				c0.1-1.3,1.1-1.4,2.1-1.6c1.3-0.2,1.3-0.7,0.6-1.7c-2.1-3-3.3-6.2-2.7-9.9c0.3-2.1-0.6-3.8-2-5.3c-0.7-0.8-1.5-0.7-2.4-0.6
				c-1,0.2-1,1-1,1.8c0.1,2.4,0.5,4.8,0.2,7.2c-0.5,3.7,0.7,7.5-1.2,11.1c-2,3.8-4.1,7.5-9.2,7.7c-1.2,0-2.3,0.8-2.7,2.1
				c-0.6,1.8-1.7,2.5-3.7,2.1c-1.9-0.4-4.5,2.5-4.1,4.3c0.8,3.8,0.1,4.9-3.8,6.4c-1.3,0.5-2.8,1.4-2.5,2.6c0.8,3.1-1,4.5-3,6
				c-1.3,0.9-2.2,1.8,0.1,2.6c1.7,0.6,2.5,1.9,1.4,3.2c-2.8,3.4-3.3,6.8-0.6,10.5c0.6,0.8-0.3,1-0.7,1.4c-1.5,2-0.9,4.3-0.3,6.5
				c0.5,1.6,1.8,1.8,3.4,1c-0.2,1.8-1.7,2-2.4,3c-0.5,0.8-1.1,1.4,0.3,2c4.1,1.7,3.6,5.8,4.7,9c0.2,0.6,0.1,1.5-0.8,1.9
				c-1.6,0.7-1.2,1.5-0.1,2.3c1.9,1.4,4,4.8,3.7,5.3c-1.4,2.4-0.6,3.5,1.5,4.5c0,0-0.1,0.2-0.1,0.3c-1.4,2.2-1.5,3.5,1.8,3
				c0.8-0.1,1.8,0.8,1.5,1.7c-0.4,1-1.3,0.3-1.9-0.1c-0.7-0.5-1.2-0.3-1.5,0.5c-0.2,0.6-0.1,1.3,0.5,1.4c3,0.4,2.6,3.1,3.4,5
				c1.2,2.5,1.5,5.6,5,6.4c3,0.7,5.1,3.5,8.3,3.9c0.3,0,0.7,0.2,0.8,0.4c1.4,3.5,3.3,6.8,3.3,10.7c0,10,0.1,20,0.1,30
				c-0.5,0.9-0.4,1.7,0.1,2.5c0.1,6.8-0.1,13.6,0.3,20.4c0.2,3.5-1.2,7.1,0.4,10.5c0,0.8,0.2,1.4,0.7,2c-0.1,7-1,8.7-6.1,9.8
				c-2.3,0.5-4.1,1.8-6,2.7c-0.7-1.4-1.8-1.6-3.3-1.2c-2.5,0.7-1.3,2.9-1.7,4.4l0-0.1c-1.6,0.1-4.2,2.6-4.2-1.6
				c0-0.8-0.8-0.9-1.5-0.9c-0.6,0-1.3,0.2-1.3,0.8c0,2.1-1.3,4.7,1.8,5.9c-3.7,3.5-7.8,6.4-11.7,9.6c-2.1,1.7-5.3,2.4-6,5.7
				c-2.1,0.4-1.4-1.2-1.4-2.1c-0.1-4.1-0.1-8.3-0.1-12.4c0-0.9,0.4-2.1-1.2-2.2c-1.6-0.1-1,1.2-1.4,2c-1.1,2.4,1.8,4.8-0.2,7.1
				c-2.3-1.3-3.6-3.6-5.2-5.6c-0.6-0.8-0.9-2.6-2.6-1.1c-4.1-0.3-8,0-10.9,3.3c-2.4-2.4-3.3-5.4-3.8-8.7c-0.1-0.8-0.1-1.7,1-1.7
				c2,0.1,4-1.4,6.1-0.4c0.9,0.4,2.1,0.6,2.4-0.7c0.3-1.2-0.9-1.6-1.7-2.1c-1.1-0.6-2.2,0.5-3.4-0.5c-2.3-1.8-5.5-2.3-8.1-2.2
				c-2.8,0-5.4,0.6-8.3-0.1c-3.9-1-7.9,0.4-11.9,0.3c-2.2-0.1-4.4,0.2-5.5,3c-0.7,1.9-3,1.4-4.4,1c-2.3-0.7-4.5-0.9-6.8-0.9
				c-1.1,0-2.4,0.1-2.8,1.1c-0.4,1.3,1.7,1,2.1,2.1c-2.5,0.9-3.5,2.2-0.7,3.9c1.1,0.7,1.3,1.6,1.4,2.7c0.1,1.8,0.3,3.7,0.4,5.5
				c-5.5,0.1-11-0.6-16.5-1.1c0-2.4-1.5-4.3-2.6-6.2c-1.3-2.4-1.8-4.6-0.4-7.1c1.1-2,0.3-2.8-1.8-2.8c-1.2,0-2.4,0-3.6,0.1
				c-2.4,0.1-2.5,2.3-2.8,3.7c-0.2,0.8,0.2,3.5,2,3.1c3.8-0.9,3.2,1,2.6,3.2c-0.2,0.7-0.1,1.3,0.6,1.6c1.1,0.5,1,1,0,1.5
				c-1,0.5-0.9,1.4-1,2.2c-4.6,0.7-9.1-0.4-13.6-0.9c-13.3-1.4-26.7-2-40.1-3.2c-7.4-0.7-14.8-0.9-22.2-1.5
				c-6.2-0.6-12.3-1.5-18.6-1.6c-7.5-0.2-15-1.6-22.6-1.3c-1.6,0.1-2.1-2.1-3.6-1.2c-1.6,0.9-3.1,0.3-4.7,0.2
				c-5.1-0.4-10.3-1.1-15.4-0.7c-6.1,0.5-12,0.3-17.9-0.9c-2-0.4-2.7-1.2-2.7-3.3c0.1-6.3-0.2-12.6,0.1-18.9
				c0.5-10.9,0.3-21.9,0.4-32.8c0.1-11.8,0.1-23.7,0-35.5c-0.1-31.9,0.2-63.8,0.7-95.8c0.1-3.5-0.6-7.2,1.6-10.4
				c0.2-0.3-0.3-1.2-0.5-1.8c-0.2-1.5,0.7-1.9,1.7-1.1c1.8,1.4,3.4,0.9,5.2,0.2c10.1-3.8,20.3-7.4,30.5-11.2
				c12.3-4.6,24.6-9.1,36.9-13.7c2.2-0.8,3.3-0.5,3.3,2c0,4.4,0.4,8.9,0.3,13.3c-0.2,17.2-0.9,34.4-0.8,51.6
				c0.1,8.3-0.6,16.5-0.2,24.8c0.1,1.3-0.3,2.6-0.4,3.9c-0.2,1.9-1,3-3.1,2.8c-1.5-0.2-2.8,0.4-3.5,1.9c-0.4,0.7-0.7,1.5-1.6,1.3
				c-4.4-0.9-4.1,2.2-4.2,4.8c-0.2,5.7-0.1,11.3-0.2,17c-0.1,2.9,1.3,5,4.2,5.4c1.7,0.2,3,0.7,2.9,2.7c0,0.7,0.3,1.3,1.1,1.4
				c1,0.1,1.4-0.7,1.4-1.4c0.1-1.2,1.1-2.9,0.1-3.4c-1.8-0.8-3.1-2.7-5-3c-2.3-0.4-2.6-1.5-2.5-3.3c0.2-4.2,0.3-8.5,0.5-12.7
				c0-1-0.5-2.4,1.2-2.6c1.4-0.1,1.7,0.9,1.8,2.2c0.1,1.4,0.8,2.4,2.3,2c2.8-0.7,2.8,0.9,2.8,2.9c-0.1,2-0.1,3.9,0,5.9
				c0.1,1.2-0.6,2.6,0.5,3.4c2.3,1.5,2.2,4,2.3,6.1c0.3,7.2,0.3,14.5,0.3,21.7c0,15.3,0,30.7-0.1,46c0,9.5-0.2,19-0.1,28.5
				c0,1.1-1.1,2.5,0.7,3.8c0.1-1.5,0.2-2.7,0.2-3.8c0.1-21.2,0-42.3,0.2-63.5c0.1-11.2-0.5-22.5,0.9-33.6c0.1-0.9-0.5-2.3,1-2.3
				c1.4-0.1,1.2,1.4,1.3,2.2c0.3,1.8,1.3,1.5,2.3,0.8c1.1-0.9,0.5-3.7-0.5-4.8c-1.5-1.6-4.1-2.6-4.3-5.1c-0.2-3.3,0-6.5,0.1-9.8
				c0-1.4,0-2.6,2.2-1.9c2.1,0.7,3.4-0.5,4.2-2.6c1-2.5,0.2-4.9-0.1-7.3c-0.3-2.5-1.6-3.1-3.7-1.8c-0.8,0.5-0.9,1.8-2.1,1.7
				c-0.6-0.6-0.4-1.3-0.4-1.9c0.3-31.7,0.5-63.4,0.8-95.2c0-2.2,0.9-3,2.8-3.7c24.4-9,48.7-18.2,73.1-27.2c3.6-1.3,7-3.1,10.9-3.6
				c2.9-0.4,3.9,0.5,3.9,3.5c-0.1,9.6-0.1,19.2-0.1,28.8c-0.1,43.2-0.2,86.4-0.2,129.5c0,26.1,0,52.1,0.1,78.2c0,1-0.5,2.5,1.2,2.5
				c1.9,0,0.9-1.7,0.9-2.5c0-29.1,0-58.2,0-87.3c0-33.8,0-67.6-0.1-101.4c0-16.7,0.1-33.4-0.1-50.1c0-2.8,0.2-3.5,3.2-2.8
				c8.5,2.2,17.3,3.4,25.9,5.3c7.5,1.7,15.3,2.5,22.8,4.2c8.8,2,17.5,4.4,26.4,5.6c5.1,0.7,8.9,5.3,14.5,4c1.3-0.3,2.9,0,4.2,0.4
				c3.7,0.9,7.7,0.8,10.6,3.8c-0.4,1-1.4,1.2-2.1,1.6c-0.8,0.5-1,1.8-0.6,1.9c2.7,0.6,1,2.9,1.8,4.3c1.2,2.2-0.3,2.5-1.9,2.1
				c-1.8-0.3-3.5-0.9-5.4,0.5c1.7,1.2,3.3,0.7,4.9,0.8c5,0.4,6.1-0.5,6.2-5.6c0.1-2.1,0.8-3.5,3-4c0.7-0.2,1.4-0.4,2-1
				c4.8-5.4,11.6-6.6,17.9-8.8c1.7-0.6,3.6-0.3,4.1,1.5c0.6,1.9-1.5,2.1-2.7,2.6c-4.7,1.8-9.5,3.3-14.1,5.2
				c-6.7,2.8-6.7,2.9-6.5,10.1c0.1,3.4,0.4,3.6,3.6,2.5c5.7-1.9,11.5-3.7,17.6-3.6c2.7,0,4.5-1.1,6.1-2.9c3.1-3.3,6.1-6.7,11.3-7.8
				c-1.8-1.4-1.9-3.8-4.1-4.1c-3.9-0.5-3.2-1.6-0.7-3.3c1.4-1,1.5-2.7,0.8-4.2c-0.8-1.8-2.1-0.5-3.1-0.3c-6.3,1.3-12.6,2.9-18.5,5.4
				c-5.9,2.5-11.5,3.6-17.7,1.6c-2.7-0.9-5.6-0.9-8.1-2.4c-0.6-0.4-1.5-0.6-2.2-0.6c-8.9,0.3-17.3-2.8-26.1-3.5
				c-2.6-0.2-5.2-1-7.8-1.3c-8.2-1.1-16-4.2-24.2-5.1c-5.1-0.5-10.1-1.7-15.1-2.9c-9-2.2-18.3-3-27.1-5.7c-3-0.9-6.1,0-9,1.1
				c-8.4,3.3-16.9,6.6-25.3,9.9c-13.6,5.2-27.3,10.4-41,15.6c-12.7,4.9-25.4,9.9-38.2,14.8c-11.7,4.5-23.5,8.9-35.3,13.3
				c-5.9,2.2-11.6,5-17.7,6.6c0.6-1.3,1.4-2.9,2.7-3c3.2-0.3,5.4-1.9,8-3.6c3.5-2.3,8-3,11.5-5.5c0.5-0.3,1.5-0.5,1.8-0.2
				c1.3,1.2,2.2,0.4,3.4-0.2c2.6-1.2,5.6-1.6,7.1-4.5c0.4-0.8,1.6-0.8,2.4-0.7c5,0.7,7.9-3.5,12.1-4.8c3.2-1,5.5-4.4,9-4
				c4.6,0.5,7.6-2.7,11.6-3.8c4.6-1.2,8.7-3.5,13.7-4.2c4.4-0.7,9.2-1.8,13.3-4.5c1.2-0.8,2.2-1.9,3.9-2.4c6.1-1.7,12.1-4,18.1-6.1
				c0.5-0.2,1-0.6,1.3-1c1.2-2.2,3.3-2.7,5.5-2.9c2-0.2,3.8-1,5.7-1.6c4.2-1.4,8.5-2.2,12.5-4.5c2.8-1.6,6.4-2.5,9.6-3.8
				c5.1-2,10.3-3.5,16.1-1.6c2,0.6,4.4,0.9,6.7,0.9c3.3,0,6.6,0.8,9.8,2c2.9,1.1,6,2.6,9,1.5c3.6-1.4,5.4-1.5,8.1,1.2
				c1.5,1.5,3.1,2.2,5.5,2c3.1-0.2,6.6-1.2,9.5,0.2c4.6,2.3,8.8,1.2,13-0.6c0.5-0.2,1.2-0.8,1.4-0.2c1.4,3.4,5.2,3.5,7.5,5.6
				c1.7,1.6,3.8,1.4,5.8,1.6c3.8,0.3,7.9-1.5,11.5,1.1c0.4,0.3,1.1,0.2,1.6,0.1c2.8-1.1,3.7,0.3,3.5,3.4c1.7-1.7,3.2-2.2,4.9-2.7
				c1.4-0.4,3.2-1.8,2.1-3.3c-1.4-2-2.7-4.6-5.4-5.4c-3-1-6-1.8-9-2.7c-1.9-0.6-3.7-0.6-5.8-0.2c-2.6,0.4-4.6-2.2-7.3-2.1
				c-2.7,0.2-5.3-2.2-7.8-1.3c-1.6,0.6-2.1,0.3-2.7-0.8c-2-3.2-5.3-4.1-8.6-4.7c-5.8-1.1-11.6-1.8-17.4-2.8
				c-1.9-0.3-4.3-0.1-4.2-3.1c0.1-0.7,0.7-0.7,1.3-0.8c-0.3,2,0.7,2.8,2.6,2.6c2.9-0.2,5.6,0.7,8.4,1.1c3,0.5,6.1,1.6,9.3,0.3
				c5.6-2.2,9.9-6.5,14.9-9.7c4.2-2.6,8.6-4.3,13-6.1c3.5-1.4,7.4-2.1,10.9-3.4c3.5-1.3,7-2,10.6-2.8c2.1-0.5,4.5-0.3,5.6-2.7
				c0.4-0.9,1.2-0.6,2-0.5c4.1,0.5,8.2,1.5,12-1.1c0.6-0.4,1.5-0.6,2.2-0.5c2.9,0.6,5.6-0.4,8.3-1.1c1.9-0.5,3.4,0.5,5.1,0.6
				c0.6,0,1.2,1.1,0.4,1.4c-2.9,1.2-4.7,4-7.9,4.7c0-0.9,1.6-1.2,1.1-2.1c-0.5-1-1.7-0.8-2.6-0.7c-1.3,0.2-2.5,0.5-3.8,0.3
				c-0.6-0.1-1,0.4-1.1,1c-0.1,0.7,0.5,0.9,1,0.9c1.8,0.2,3.6,0.3,5.4,0.5c0.2,1.2-0.9,1.6-1.6,2.3c-4.6,4.3-9.4,8.3-13,13.4
				c-2.1,3-5.6,4.8-7.3,8.2c-0.3,0.6-1.3,1.1-2,0.7c-0.9-0.5-0.4-1.3,0.1-2c1.5-2.2,0.7-4.3-0.6-6.1c-1.6-2.2-2.5-4.8-3.9-7.1
				c-1.3-2.2-3.6-3.5-5.6-2.3c-2.1,1.3-4,1.3-6.1,1.5c-2.2,0.2-3.3,1.2-1.8,3.4c0.8,1.2,1.4,2.4,1.9,3.8c1.1,3.2,1.7,6.6,4.3,9.1
				c0.7,0.7,1.3,1.6,1.5,2.5c0.9,3.5,3.2,4,6.4,4.1c6.2,0.1,11.3,2.5,14.3,8.3c0.5,1,1.2,1.3,2.3,1.5c5.6,0.7,11.2-1.5,16.8-0.1
				c0.3,0.1,0.7,0.1,1-0.1c2.7-1.8,5.6-1.3,8.5-0.8c0.8,0.1,1.8,0.5,1.8-0.6c0-1.9,1.5-2.8,2.3-4.2c1.9-3.3,2.4-7.4,2.1-10.7
				c-0.7-8-1.4-16.1-3.3-24c-0.6-2.7-1.6-5.5-0.7-8.4c0.4-1.1,0.3-2.6,1.8-2.8c1.3-0.1,2.5,0.6,3.2,2.4c-4-0.4-3.5,2.1-3.1,4.5
				c0.5,3,0.9,6,1.5,9c1.4,7.6,2.8,15.1,4.2,22.7c1.3,6.8,2.7,13.6,4.1,20.4c0.2,0.8,0.4,2,1.6,1.6c1.1-0.3,0.3-1.3,0.2-2
				c-0.5-5.7-1.7-11.4-1.3-17.2c0.1-1.9,0-4,1.3-5.2c1.3-1.3,1.2-2.6,1.4-4.1c0.3-2.5-1.3-5.2,0.2-7.3c3-4.2,2.9-8.6,1.8-13.3
				c-0.2-0.8,0.5-1.4,0.6-2.1c0.6-2.7,3.1-4.1,4.4-6.3c1.2-1.9,1.7-5-0.1-5.3c-2.9-0.4-5.9-2-9-0.8c-1.8,0.7-3.1-0.3-2.8-1.9
				c0.4-1.9-0.5-2.4-1.6-3.4c-2.4-2.3-4.6-5.1-5.8-8.1c-1.5-3.6-1.2-3.8-4.1-1c-0.3-5.7,3.9-9,7.2-11.9c5.6-5.1,12.4-8.7,19.6-11.4
				c2.7-1,5.6-2.2,5.9-5.8c0.9,2.2,4,1.2,5.1,3.5c-0.8,0.5-1.6,1-2.4,1.4c-3.8,1.5-6.7,4.5-8.8,7.4c-4.7,6.4-2.4,13.3,0.8,19.7
				c0.7,1.5,2,3.1,3.4,3.7c3.1,1.3,6.6,1.6,10,1c10-1.6,19.5-4.5,28.9-8.3c9.3-3.8,18.3-8.2,25.8-15.2c4.1-3.8,7.9-8.2,13.1-10.8
				c1.8-0.9,3.7-1.9,5.4-3c2.4-1.6,2.8-5.2,0.8-7.8c-1.5-2.1-3.1-1.1-4.6,0.2c-0.4,0.4-1,0.7-1.5,0.3c-0.7-0.5-0.3-1.2,0-1.8
				c1-2.2,2-4.3,2.9-6.5c1-2.2,0.4-4-1.7-5.3c-1.3-0.7-2.3,1.3-4,0.1c2.2-2.2,1.6-4.9,1.6-7.6c0-1.4,0.6-2.9,2-2.9
				c1.3,0,0.7,1.5,0.8,2.5c0.2,1.8,1.2,2.9,2.7,1.7c3.7-2.9,7.9-5,12.2-6.5c5.3-1.9,10.8-4.2,16.6-1.8c0.6,0.2,1.3,0.2,1.9,0.1
				c7.3-0.4,13.7,1.4,19.1,6.5c0.2,2.2,3,3.9,1.4,6.5c-3.1-3.1-7-4.9-11-5.9c-13.6-3.6-27-2-40.1,2.8c-0.9,0.3-1,0.8-1.2,1.7
				c-0.7,2.4,1,3.3,2.2,4.5c1.3,1.4,2.7,0.6,3.8-0.2c1.8-1.2,3.9-1.8,5.9-2.7c2-1,4-2.3,6.4-2.3c4.5-0.1,9-0.6,13.4-0.1
				c6.6,0.7,13.8-1.7,19.7,3.3c0.8,0.7,1.1,0,1.3-0.7c1.4,3.1,0.8,6.6,1.4,9.8c-3.8-3.1-7.9-5.2-12.8-6.3
				c-11.5-2.4-22.7-1.1-33.8,1.7c-0.8,0.2-2,0.1-2,1.3c0,1.5,1.3,1.1,2.2,1.4c-1.2,2,0.6,1.8,1.4,1.7c4.3-0.6,8.7-0.4,13-0.6
				c0.7,0,1-0.4,1-1c4-0.8,7.9-0.1,11.9,0.1c3.5,0.2,6.9,0.4,10.3,0.9c3.8,0.5,6.7,2.7,9.9,4.3c0.5,1.3,0.1,2.7,0.7,4.1
				c2.1,4.5,1.7,9.4,1.7,14.2c-1.1-0.8-2.2-1.7-2.9-3c0.3-0.4,1.8,0.6,1.1-0.8c-0.6-1.1-0.4-2.2-0.3-3.3c1.7-0.3,0.9-1.5,0.7-2.2
				c-1-2.9-6.7-6.9-9.8-6.7c-1.4,0.1,0,0.9-0.2,1.3c-0.7,0.4-0.9,1.1-0.7,1.7c1.7,3.5-1.2,3.9-3.3,4.8c-0.6,0.2-1.5,0-1.8,0.6
				c-1.3,3.3-5.1,3.4-7.2,3.5c-5.4,0.1-10.6,2.1-16,1.2c-0.4-0.1-0.9,0.1-1.2,0.3c-3.3,2.1-7.5,1.8-10.8,4.1
				c-0.5,0.4-1.3,0.6-1.9,0.5c-2.9-0.7-4.5,1-6.4,2.7c-1.1,1-2.1,3.1-3.9,2.7c-6.3-1.4-8.9,4.4-13.2,6.7c-2.6,1.4-3.7,5.4-7.8,4
				c-0.8-0.3-1.8,0.6-2.4,1.4c-0.6,0.7-1.4,2.3-2-0.1c-0.2-0.9-0.9-0.5-1,0.1c-0.3,1.1-1.2,1.3-2,1.9c-3.6,2.6-8.5,3-11.7,6.5
				c-0.7,0.7-1.6,0.6-2.5,0.6c-1,0-2,0-3,0c-0.8,0-1.7,0.2-1.7,1.2c0,1,1.1,1,1.7,1.1c1,0.1,2.1-0.1,3.2-0.3
				c2.8-0.6,5.5-1.4,8.2,0.3c0.4,0.2,1,0,1.6-0.1c6.8-0.8,13.3-4.1,20.4-3c0.5,0.1,1-0.3,1.6-0.4c4-1.2,7.7-3.2,12.2-2.8
				c2.4,0.2,4.6-1,6.9-1.7c3.6-1.1,7.5-2.4,11.1-0.8c6.4,2.7,12.4,2.5,18.1-1.7c2-1.4,4-2.5,6.6-2.4c2.3,3.4,1.8,7.5,3.4,11.1
				c1.3,2.9,1.1,6.3,3.1,9c0.8-0.3,0.7-0.7,0.8-1.1c0.8-8.2-2.7-15.6-4.6-23.4c0.1-0.8,0.6-1,1.2-1.2c2.2-1,4.7-1.2,6.6-2.8
				c0.1,0.2,0.2,0.4,0.3,0.7c-0.6,2.4,2.9,2.7,2.6,5c0,0.6,0.1,1.1,0.1,1.7c-0.5-0.2-1-0.4-1.4-0.6c-0.1,0-0.2-0.1-0.2-0.1
				c0.1,0,0.2,0.1,0.3,0.1c0,0.8,0.3,1.4,0.7,2.1c1.5,2.7,2.5,5.5,2.1,8.6c-0.6,4.2-1.6,8.5-0.7,12.7c0.5,2.4,0.8,4.3-1.1,6.1
				c-0.1,0.1-0.2,0.4-0.2,0.6c0.4,4.5-2,7.4-4.7,10.9c-2.9,3.7-6.5,6.7-9.4,10.3c-2.7,3.5-6.6,6-8.4,10.7c2.8-0.8,5.3-0.8,7.7-0.4
				c1,0.2,1.9,0.7,2.9,0.3c7.9-3.5,16.4-5.8,23.8-10.4c1-0.6,1.4-0.6,1.8,0.6c1.1,3,0.7,5.4-2.7,6.7c0.9,0.8,1.5,0.8,2.1,0.5
				c1.7-0.8,3-5,5.4-0.7c2.5-0.8,4.9,0.8,7.6,0.6c3.8-0.3,8,0.3,11.7,2c5.3,2.4,10.6,5,15.7,7.9c3.3,1.9,5.3,4.1,3.2,7.6
				c-0.8,1.4,0,2.5,0.2,3.8c-0.6,0-1.3-0.2-1.9,0c-0.9,0.2-2.3-1.5-2.5,0.5c-0.1,1.3,1.2,2.5,2.4,2.6c2.4,0.3,2-1.6,2-3.1
				c5.4,1,2.3-3.5,3.3-5.7c1,2.5,1.3,4.9,2.7,6.5c1.4,1.5,3.8,2,5.7,2.9c0.1,0.3,0.2,0.6,0.3,0.9c-4.3-0.6-6.4,1-6,5
				c-1,0.2-2,0.3-3,0.5c-0.6-1,0.3-1.9,0.1-2.9c-0.3-1-0.6-2.2-1.8-2.6c-0.8-0.2-1.8-0.3-2.5,0.3c-1.2,0.9-1.1,5.8,0.1,6.6
				c1.6,1,2.9,2.4,2.9,4.1c-0.1,3,0.6,4.9,4,5.2c-0.9,1.2-1.9,1.5-2.9,1.3c-6.5-0.8-11,2.4-14.9,7.1c-3.3,4-3.7,6.2-1,10.7
				c0.4,0.6,0.7,1.5,1.7,1.2c1.2-0.4,0.5-1.2,0.3-1.8c-0.1-0.2-0.2-0.4-0.2-0.6c0-1-0.5-2.6,0.6-2.9c0.9-0.2,2.8,0.7,3,1.4
				c0.6,2.9,3.3,5.2,2.4,8.5c-1-0.2-1.9-0.4-2.9-0.5c-0.8-0.1-1.8-1.1-2.2,0.2c-0.4,1.2,0.3,2.7,1.5,2.9c2.3,0.2,4.2,1.7,6.6,1.6
				c5.2-0.4,10.3,0.5,15.3,2.1c2.2,0.7,4.6,1.7,6.9,1.5c6.8-0.7,13.4,0.7,19.7,2.7c5.4,1.7,11,1.1,16.4,2.4
				c3.6,0.9,7.6,0.5,10.9,2.8c0.2,0.2,0.7,0.3,0.8,0.2c1.9-2.7,3.9-0.7,5.7-0.1c2.5,0.8,5.4-0.1,7.7,1.1c3.1,1.6,6.5,1,9.6,2.4
				c2.2,1,2.6,0.5,1.6,2.8c-1.2,2.9,1.6,6.7,4.7,5.8c2.6-0.8,2.3,0.6,2.7,2c0.8,3.2,0.3,6.4,0.4,9.6c0,0.1-0.3,0.2-0.2,0.2
				c-1.6,0.5-1-1.4-2.1-1.3c-1.2,0.7-0.1,3.7-2,2.8c-2-0.9-3.7,0.1-5.1-0.2c-3.5-0.9-1.8,2.8-3.5,3.4c-0.8-1.3,0.4-2.9-0.9-4
				c-1.4,0.7-2.8,2.7-4.3,0.2c-0.1-0.1-0.5-0.3-0.5-0.2c-2.9,2.2-4.7-0.3-6.9-1.4c-0.7-0.4-1.5-0.1-1.7,0.5
				c-1.2,3.3-3.8,1.2-5.7,1.2c-0.7,0-1.7-0.6-2.7-0.3c-1.9,0.6-3.6,0.7-5.1-1c-0.6-0.7-1.4-0.9-2-0.1c-1,1.4-3.6,1.1-3.8,3.6
				c-0.1,0.8-1.4,1.3-2.5,1c-0.5-0.2-1.3-0.7-1.3-0.8c0.9-3.1-0.7-5.5-2.1-7.9c-0.4-0.7-0.1-2.2-1.6-2.1c-1.3,0-1,1.5-1.8,2
				c-2.7-3.3-2.7-3.5-6.5-2.7c-2.4,0.5-4.7,0.7-7.1,0.1c-1.6-0.4-4.1,0.3-3.6,2.2c0.8,3-1.3,3.8-2.9,5c-0.9,0.7-2,1.6-3.2,0.7
				c-1.1-0.8-2.5-1.6-1.8-3.4c0.5-1.3-0.3-2-1.4-2c-2.2,0.1-4.4,1-6.1-1.2c-0.1-0.1-0.6,0.1-0.9,0.2c1,3.3-2.3,1.2-3,2.4
				c-1.3,1.8-1.7,0.8-2.3-0.6c-0.6-1.6-1-3.3-3.6-1.8c-1.2,0.7-3.7,1.3-5.2-0.7c-1.7-2.3-5.1-1.4-7-3.4c0,0-0.7,0.2-0.7,0.5
				c-0.3,0.9-0.8,1.6-1.9,1.3c-1.2-0.4-1.2-1.7-1.5-2.7c-0.1-0.5,0.3-1,0.3-1.6c0.2-1.7-0.6-2.9-1.8-4c-0.7-0.6-1.6-0.5-2,0.2
				c-0.8,1.2-1.4,2.4-0.1,3.8c0.7,0.8-0.2,1.6-0.5,2.4c-1,2.9-2,5.7-1.6,9.1c1.6-0.7,2.3-2.8,4-1.4c1.7,1.4,3.2,1.2,5.1,0.4
				c1.1-0.5,2.8-0.7,3.8-0.2c3.7,1.7,7.9,1.2,11.6,2.9c1.6,0.8,3.8,0.8,5.4,0c2.8-1.4,3.9-1.3,5.2,1.6c0.4,0.9,0.6,1.2,1.6,0.8
				c1.2-0.5,2.3,0.1,3.5,0.4c1.8,0.5,3.9,1.5,5.4,1c3.5-1.2,5.7,0.3,8,2.4c0.6,0.5,1.3,0.7,1.8,0.2c1.4-1.3,2.2-0.4,3.4,0.5
				c0.8,0.6,2.3,2,3,0.5c1.9-3.8,3-0.8,4.1,0.4c1.2,1.4,2.2,2.9,4.2,1.3c0.6-0.4,1.2-0.1,1.9-0.1c3.2,0.3,6.3,1.4,9.5,1.2
				c1.9,4.5,5.4,0.8,8.1,1.6c1.5,0.5,3.1,0.5,4.7,1.2c1.4,0.6,3.2,0.8,4.7-0.2c2-1.4,3.3-0.6,5.1,0.8c2,1.5,4.4,3.9,7.6,1.4
				c1.1-0.9,2.8-0.8,3.6,0.9c0.4,0.9,0.7,1.8,1.8,0.9c1.6-1.3,2.8-1.2,4.1,0.5c0.6,0.8,1.4,0.6,2.2-0.2c0.9-0.8,0-1.1-0.1-1.7
				c-0.5-2.2-2.7-2-3.9-3.1c-0.6-0.5-2.1-0.2-1.6-1.5c0.4-1,1.3-2.1,2.7-2c1.4,0.1,5.4,4.4,5.5,5.9c0.1,1.3-0.2,2.5,1.6,3.2
				c2.7,1,4.8,2.9,6.6,5.1c5.2,6.2,11.4,11,19.5,13c2,0.5,2.7,1.5,1.4,3.2c-1.8,2.4-1.2,5-0.8,7.6c0.2,1.5,0.5,3,0.4,4.5
				c-0.2,2.1-1.2,3-3.3,2.4c-1.8-0.5-3.6-1.6-5.3-1.5c-2,0.2-2.5-0.7-2.5-2c0.3-4-0.8-8.2,1.7-12c1-1.5,1.2-3.5-0.5-4.7
				c-1.4-1-3.3-2.4-5-0.7c-1.4,1.5-2,4-0.7,5.5c2.7,3,1.2,6.4,1.5,9.6c0.3,3-1.1,4.6-4.4,3.7c-0.5-0.1-1.1,0.2-1.6,0.3
				c-3.3,0.7-6.5,0.9-8.9-2.4c-2.1,5.2,0.3,9.1,3.2,13c-2,0.2-3.4,0-4.9-0.3c-4.6-0.8-9.2-2.5-14,0c-6.3,3.3-12.9,6.2-19.4,9.2
				c-2.2,1-3.5,3.1-3.6,5.1c-0.2,3.5-0.9,6.9-0.9,10.4c-0.2-3.3-0.5-6.7-0.8-10c-0.1-1.7-0.9-2-2.4-1.4c-3.2,1.4-6.4,2.7-9.6,4.2
				c-8.7,4.1-17.4,8.1-25.9,12.6c-6,3.1-12.4,5.2-18.2,8.8c-3.4,2.1-7,3.9-10.5,5.8c-3.2,1.7-7,2.8-10.4,4.4
				c-10.6,5-21.2,10.1-31.8,15.2c-3.6,1.7-6.9,1.1-10.2-0.7c-0.8-0.4-1.5-1.2-0.9-2.2c0.5-0.8,1.3-0.4,2,0c2.9,1.5,3.7,1.1,3.9-2.3
				c0.1-1.1,0.3-2.1,0.2-3.2c-0.2-4,1.4-6.5,4.9-7.9c6-2.4,11.6-5.7,17.7-8c5-1.9,9.8-4.5,14.6-7c5.5-2.9,11.9-3.9,16.8-8
				c4.5-3.7,10.5-4.5,15.5-7.3c6.5-3.5,13.4-6,19.8-9.7c8.4-5,17.6-8.5,26.4-12.8c1.3-0.6,2.6-1.1,3.7-2c1.1-0.8,1.9-2.1-0.4-2.6
				c-1.8-0.4-3.5-1.1-5.2-1.4c-6.8-1.2-13.6-2-20.4-3.2c-3.6-0.6-7.4-1.3-11.2-0.4c6.3,1.2,12.3,3,18.1,6.4
				c-3.4,0.8-6.4-0.4-9.4-0.6c-11.1-0.8-22.2-2.6-33.2-3.9c-10.4-1.3-21.1-2.4-31.5-5c-1.2-0.3-2.4-0.3-3.7,0.6
				c-7.5,5.2-15.3,10-23.4,14.4c-1.8,1-3.5,2.7-3.8,4.3c-0.6,3.1,0.3,6.3-0.6,9.5c-0.3,0.9,1.2,2.3-0.3,3.2
				c-1.2,0.7-1.7-1.1-2.8-1.2c-0.4-0.7,0-2-1.4-2.2c-0.1,1.1,0.7,1.6,1.4,2.1c-0.7,1.2-3,1.6-1.9,3.8c0.9,1.8,2.4,1.4,3.9,1.4
				c0.2,0,0.4,0,0.6,0l0,0c0.1,2.3,0.5,4.6,0.4,7c0,2.4-1.3,5.8,2.5,7.4c0.8,0.3,0.5,1.4-0.2,2.2c-1,1.1-2.1,2.6-1,4
				c1.2,1.4,0.9,2.4,0.2,3.8c-0.9,1.8-1.8,3.7-2.5,5.6c-0.5,1.2-1.3,1.3-2,0.6c-2.9-3-2.7-0.2-3.1,1.5c-0.9,3.7-0.8,7.5,0.5,11
				c1.3,3.8,0.9,6.3-2.8,8.6c3,1.1,6,0.5,8.9,0.7c2,0.2,4.1,0.5,4-2.7c2.7,2.3,5.5,3.6,8.7,3.9c3.6,0.3,7.2,0.8,10.7,1.4
				c1.9,0.3,4.1,0.4,4.7,2.9c0,1.8-0.2,2.8-2.7,2.4c-6.5-1.1-12.9-3.1-19.5-3.7c-2.3-0.2-5.2-1.4-6.7-1c-4.4,1.3-8.8,0.7-13.2,1.2
				c-3.8,0.4-7.4,2.7-8.7,6.3c-0.4,1-0.6,1.5,0.9,1.7c4.9,0.6,9.8,0.8,14.7,1.7c6.9,1.3,14,1.5,21.1,2.3c7.8,0.9,15.6,0.9,23.2,3.1
				c0.8,0.8,1.5,1.7,0.8,2.9c-0.6,1.1-1.7,0.7-2.6,0.7c-1.3,0-2.6,0.1-3.9-0.4c-0.8-0.3-1.5,0-1.8,0.9c-0.2,0.8,0.3,1.6,0.8,1.6
				c2.1,0.1,4,1.3,6.1,0.6c2.7-0.9,7.6,0,8.8,1.7c-0.8,0.5-2,0.9-0.4,2c1.4,1,1.9,2.5,2.2,4.9c-3-3-5.6-4.9-9.4-5.2
				c-2.6-0.2-5.4,1.4-7.8-0.8c-0.3-0.2-0.9,0-1,0.5c-0.2,0.5,0,0.8,0.4,1.1c0.5,0.3,2.1,0.4,0.1,0.9c-1.1,0.3-1,1.1-1,2.1
				c0.2,3.9-0.1,7.8,0.3,11.7c0.2,2.5-0.7,3-2.9,3.1c-1.1,0-1.8-0.4-2.6-1c-2.1-1.4-4.7-1.2-6.1,0.3c-2.5,2.6-5.4,1.4-7.7,1
				c-3.1-0.5-6.1-1.3-9.2-1.4c-1.1,0-2.1-0.2-2.9-1.1c-2.6-2.8-4.4-2.9-7.5-0.6c-0.4,0.3-1.2,0.5-1.5,0.3c-4.3-2.4-4.1,1.4-5.3,3.6
				c3.6,0.8,7,2.2,10.3,2.1c6.3-0.2,12.3,1.5,18.5,1.8c8.3,0.3,16.6,2.2,25,2.4c2.2,0,4,1,5.7,2.1c1.6,1,1.5,2.4-0.5,3
				c-0.7,0.2-1.6,0.1-2.3,0.3c-3.3,1.1-7.2,0.4-10.1,2.8c-1.6,0.3-3.2,0.5-4.8-0.2c-0.7-0.3-1.7-1.3-1.9,0.4
				c-0.2,1.2,0.7,1.3,1.7,1.4c3.3,0.3,6.5,0.7,9.8,0.9c7.2,0.4,14.3,2,21.5,1.8c1,0,2.3,0.7,2.5-1.1c0.5-0.1,1.1-0.3,1.6-0.2
				c11.4,1.1,22.8,2.2,34.1,3.3c8.2,0.8,16.5,1.9,24.7,2.4c7.2,0.4,14.3,1.5,21.5,1.7c4.5,0.1,9.3,0.4,13.6,2.6c0,1,1.4,0.9,1.5,1.9
				c-4.6-0.5-9.2-0.9-13.7-1.3c-1.4-0.1-3.6-0.2-3.2,1.7c0.9,4.5-3.5,9.4,1.2,13.5c0.1,0.1,0.1,0.4,0.2,0.6c1.1,8.5,2.8,17,2.3,25.7
				c-0.1,1.3,0,2.8-1.8,3c-1.4,0.2-3.2,0.8-4.1-0.9c-1.4-2.5-2-0.7-2.8,0.3c-2.8,0.7-5.6,1.2-8.4,1.3c-1.2-1.2-2.7-0.6-4.1-0.6
				c-5.7-0.2-0.5-5.2-3.1-7c-0.8-0.5,0.5-2.1,1.6-2.8c0.1-0.1,0.2-0.1,0.3-0.1c1.3-0.5,2.8-0.7,4-1.4c1.4-0.7,3.8,0.2,4.3-1.6
				c0.6-2.4-2.8-1.3-3.7-3.3c1.5-0.1,2.8-0.2,4.2-0.2c4.6,0,4.5,0,5-4.6c0.3-2.4-0.4-3.6-3-3.3c-1.4,0.2-2.7-0.1-3.7-1.1
				c-1.1-1-2.1-0.9-3.3-0.5c-1.6,0.5-3.2,0.6-4.8,1c-2.1,0.6-4.3-0.6-6.2,0.7c-3.5,2.3-7,4.5-11.2,5.2c-1.5-4-5.3-0.9-7.6-2.8
				c3,0,5.1-3.7,8.6-1.6c0.6,0.4,1.6,0.4,1.7-0.3c0.5-2.9,3.3-2.5,4.6-2.3c2.7,0.5,5-0.4,7.4-0.7c3.1-0.4,6.2-1,9.4-1.6
				c0.6-0.1,1.3-0.4,1.2-1.2c-0.1-0.5-0.6-0.6-1-0.6c-4.9,0.1-9.7-1.3-14.6-1.6c-14.2-1-28.4-2.5-42.7-3.7
				c-9.6-0.8-19.1-1.7-28.7-2.5c-6.7-0.5-13.5-1-20.3-1.4c-1.3-0.1-2.3,0.1-2.6,1.7c-1,4.3-2.6,8.6-2.9,13
				c-0.5,6.7-0.2,13.5,0.7,20.2c0.2,1.5,0.9,2.1,2.5,2.3c7.3,1,14.7,1.2,22.1,1.8c18,1.3,36,2.3,54,3.6c3.3,0.2,6.8-0.1,10,1.3
				c2.9,1.7,6.2,2.1,9.5,2.6c3,0.4,6.1-0.9,9-0.4c2.9,0.4,4.9,3.3,7.9,4.1c0.3,0.1,0.6,0.9,0.7,1.4c0.2,0.8,0.2,1.6,1.4,1
				c0.9-0.4,2-0.9,1.4-2.1c-0.4-0.8-0.6-1.2-0.4-2.2c0.9-4.3,4.4-2.5,6.8-3.1c1.9-0.5,2.8,0.5,2.6,2.6c-0.4,3.6-0.6,7.2-0.8,10.7
				c-0.9,0.3-1.2,1.1-1.6,1.9c-2.9,6.2-4.5,12.8-7.6,18.9c-0.5,1-0.1,2.1,0.2,3.1c-0.3,1.3-2.1,3.4-1.6,3.4c2.4-0.2,2.1,2.3,3.6,2.8
				l-0.1-0.1c0,1.9,2,4.6-1.9,4.9c-3.7,0.3-6.4,1.7-7.3,5.6c-0.1,0.3-0.5,0.4-0.7,0.6c-0.9-0.6-0.7-1.5-0.7-2.4
				c-0.2-7.2-0.5-14.3-0.7-21.5c0-1.3,0.1-2.6-1.4-2.9c-2.4-0.4-2.6-2.2-3.1-4c-0.6-1.9-1.1-3.8-1.3-5.7c-0.2-1.8-0.9-3.3-3.2-3.4
				c-12.4-0.8-24.8-1.8-37.2-2.6c-7.4-0.5-14.8-0.7-22.2-1.4c-12.2-1.2-24.4-1.2-36.5-2.4c-2.2-0.2-2.9,0.6-2.8,2.8
				c0.2,3.7-0.1,7.4,0.6,11c-0.7,0.7-0.6,1.6-0.5,2.5c0.6,10.7,0.5,21.3,0.7,32c0,0.5-0.1,1.2,0.2,1.6c3,5.7,4.5,12,7.5,17.7
				c0.7,1.4,1.5,2.6,1.6,4.4c0.2,3.6,3,5.8,5.6,5.1c0.4,1.4-4,2.7-0.1,4.3c0.5,0.2-0.4,0.3-0.7,0.4c-0.6,0.2-1.2,0.9-0.7,1.1
				c2.1,0.7,0.9,2.5,1.4,3.6c4.4,9,6.8,19,13,27.2c0.7,0.9,0.8,2.1,1,3.3c1,5.5,4.2,10.1,6.3,15.2c0.1,0.2,0.2,1.1,0.8,0.6
				c1.9-2,2.6,0.3,4.4,0.8c-1.6,0.7-3-0.3-3.7,1.1c-0.7,1.4,0.3,2,1.2,2.7c0.2,0.2,0.4,0.5,0.5,0.8c2.7,8.7,6.3,17,11,24.8
				c0.5,0.9,0.9,1.9,1.4,2.8c0.5,3.4,2.4,6.2,3.9,9.2c0.4,0.8,1.2,1.4,1.7,0.5c1.9-3.3,3.1-6.9,4.2-10.5c0.7-0.9,1.2-1.9,1.3-3.1
				c1.9-3.5,4-7.1,4-11.3c2-0.7,1.1-2.6,1.4-3.9c1.4,0.2,2-0.6,2.1-1.9c0.1-1.5,1.3-2.5,1.9-3.8c0.3-0.7,0.8-1.2,1.8-0.8
				c5.1,2,6.3,1.2,9.9-2.6c2.1-2.3,2.8-4.9,4.3-7.3c5.1-8.2,8.1-17.2,11.8-26c1.4-3.4,0.3-4.9-3.3-4.9c-1.9,0-3.7-0.1-5.6,0
				c-2.7,0.2-2.2-1-1.1-2.4c1.2-0.1,2.2-0.5,2.5-1.7c0.5-1.9,1.3-2.2,3.4-1.8c5.7,1,6.4,0.3,8.7-5.5c4.3-10.6,8.5-21.2,12.8-31.8
				c0.3-0.9,0.4-2.2,1.7-2c1.1,0.2,0.8,1.4,0.8,2.2c0.1,1.7,1.2,1.7,2.5,1.8c5.1,0.2,10.2,0.6,15.3,0.9c-1.1,1.5-3.2,1.7-4.1,3.6
				c-2.8,5.8-3.4,12.3-6.5,18c-1.7,3.1,0.3,6.3,0.2,9.5c-0.2,6.1-2.5,12-1.1,18.3c0.6,2.9,1,6.3-1.1,9.1c-0.4,0.6-0.3,1.4-0.3,2.2
				c0.2,5.2,0.3,10.3,0.3,15.5c0,1.9-0.9,4-0.5,5.8c1.9,7,1.1,14.2,1.7,21.2c0.6,7.4,0.1,15,0.2,22.5c0,1.8,0.5,3.6,0.5,5.5
				c0.1,5.3,0.3,10.7,0.1,16c-0.3,6.7,1.7,13.4-0.2,20c-0.1,0.5,0,1,0,1.6c0.3,4.8,0.7,9.6,0.8,14.4c0.2,6.3,0.1,12.7,0.3,19
				c0.3,9.9,0.7,19.7,1.1,29.5c0.1,1.6-1,2.9-0.5,4.5c0.8,3.1,0.6,6.2,0.1,9.4c-0.3,2.2-0.2,4.4,3.2,4.3c4.3-0.1,8.6-1.3,12.9-0.7
				c2.2,0.3,2.9-0.9,2.7-2.9c-0.3-3.2-0.5-6.4-0.6-9.6c-0.3-6.1-0.2-12.3-0.8-18.4c-0.5-4.9-0.3-9.8-0.8-14.7
				c-0.7-7.4-0.4-15-0.9-22.5c-0.5-7.6-0.3-15.2-0.8-22.8c-0.1-2.1-0.5-4.2-0.4-6.4c0.3-5.1-0.4-10.3,0.1-15.4
				c0.4-3.4-1.6-6.2-1.2-9.5c0.3-2.1,0.9-4.5,0.5-6.4c-1.3-7.2-0.7-14.5-1.1-21.8c-0.4-8.6-0.3-17.2-0.8-25.9
				c-0.1-1.7,0.5-3.2,0.3-4.9c-0.7-5.3-0.2-10.7-1.1-16.2c-0.6-4-1.2-8.4-0.9-12.7c0.3-3.8-0.5-7.7-0.4-11.7
				c0.1-5.4,0.1-10.8-0.6-16.3c-0.4-3.4-2.6-8.3,2.7-10.7c0,0,0-0.2,0-0.3c0.7-0.1,1.5-0.2,2.2-0.3c-0.2,6.5,0.2,13,0.3,19.5
				c0.4,15.6,0.9,31.3,1.5,46.9c0.1,3.8-1.3,8.1,4,10.1c-0.7,0.2-1,0.2-1.3,0.1c-0.9-0.4-2.1-0.8-2.5,0.4c-0.4,1.1-0.6,2.5,0.2,3.4
				c0.9,1,2,0.4,2.8-0.5c1.6-1.7,3.4-0.9,5.2-0.3c1,0.3,1.9,0.8,3,0c2.4-1.7,4.9-3.2,7.3-4.7c0.3,0,0.7,0.7,1,0l-0.1,0
				c15.1,0.4,30.1-1,45.2-1.3c4.1-0.1,8.3,0,12.4,0c3.7,1.6,5.2-2.8,8.2-3.1c-0.6,5.5-1.3,6.3-6.3,7.3c-0.4,0.1-0.8,0.1-1.3,0.2
				c0.1-1.3-0.5-1.6-1.8-1.6c-5.7,0-11.4-0.4-17-0.2c-11.3,0.4-22.7,1-34,1.6c-2.4,0.1-4.6,0.9-6.8,1.8c-1.4,0-2.8-0.1-4.2-0.1
				c-1.5,0-1.9,0.9-1.7,2.1c0.2,1-0.5,2.5,0.8,2.8c1,0.2,2.5,0,2.6-1.7c0.1-1.2,1-1.3,1.9-1.2c0.7,0.5,1.4,0.3,2.2,0.2
				c5.2-0.2,10.5-1.2,15.7,0.1c-1.9,2.2-4.8,1.4-7.1,2.4c-0.2-0.3-0.5-1-0.7-1c-3.2,0.2-6.4,0.5-9.6,0.9c-0.6,0.1-0.6,0.8-0.6,1.3
				c-1.7-0.1-3.4-0.3-4.9,1.2c-0.9,1-2.1,0.3-2.9-0.4c-0.9-0.8-0.8-1.7,0.3-2.4c0.6-0.4,1-0.9,0.4-1.6c-1.7-1.7-7.6-1.7-9.4-0.1
				c-0.3,0.3-0.6,0.7-0.4,1.1c0.2,0.5,0.7,0.6,1.1,0.4c1.4-0.6,2.7-0.2,4.1,0.2c1,0.3,2.2,0.5,1.9,1.7c-0.3,0.9-1.5,0.3-2.3,0.2
				c-4.3-0.7-6.4,1.5-4.9,5.5c0.7,1.8-0.9,4.6,2.3,5.4c0.1,0,0.1,1,0.1,1.6c-0.1,1.1-0.3,2.1-0.4,3.2c-1.4,1.5-1.5,3.1-0.6,4.9
				c0,3.1,0,6.1,0,9.2c0.1,1.4,0,2.9,0.2,4.2c0.3,1.9-0.9,4.2,1.2,5.8c-1.3,1.6-0.7,3.4-0.8,5.2c-0.1,1.7,1.1,1.8,2.4,1.8
				c4.3,0,8.5,0,12.8,0.1c6.3,0,6.2,0.1,8,4.4c0.6,1.4,0.2,2.9-0.8,3c-1.8,0.3-2.6,3.8-5,1.5c-1.8-1.7-3.5-1.4-5.7-0.6
				c-3.1,1.2-6.3,2.4-9.8,1.5c-0.6-0.1-1.1-0.3-1.3,0.4c-0.1,0.5-0.2,1.2,0.1,1.6c0.5,0.9,1.1,0.3,1.5-0.1c0.6-0.6,1.3-0.6,1.7,0
				c0.8,1.1-0.5,1.2-1,1.5c-1.7,1-2.4,2.5-2.3,4.3c0.1,2.2,0.2,4.5,0.3,6.7c0.1,3.8,0,7.6,0.1,11.4c0,2,0.8,3.1,3,3.4
				c5.1,0.7,10.1,1.9,15.2,2.8c-0.1,1.5,0.8,1.7,2,1.8c2.7,0.1,5.4-0.2,8.1-0.7c1.2-0.2,1.6-0.9,1.7-2c2.8-0.7,4.6,2.7,4.4,3.1
				c-1.3,2.9,1.3,4.5,1.6,6.8c-1.3,1.2-2.4,0.5-3.5-0.3c-2-1.5-4.1-1.2-5.5,0.5c-1.7,2.2-3.8,2.3-6.2,2.1
				c-6.1-0.6-12.3-1.2-18.5-1.1c-2.3,0-2.5,0.9-1.9,2.7c-0.5,1.5,0,2.6,1.6,2.1c3.3-0.9,6.5,0.1,9.8-0.5c2.5-0.5,3.7,0.4,2.6,3
				c-0.7,1.8,0,3,1.3,3.9c2.1,1.4,3.7-0.2,4.7-1.6c1.3-1.8-0.4-1.5-1.6-1.7c-0.9-0.2-1.2-1.7-1.1-2.8c0.1-1.1,1-0.9,1.8-0.9
				c1.4,0,2.8,0,4.3,0c0.9,0,0.9,0,1,1.1c1.3,10,0,20,0.7,30c0.4,6.2,0.4,12.4,0,18.6c-0.2,2.7,0.3,5.4,0.5,8.1
				c0.1,0.8,0.4,1.5,1.4,1.5c0.8-0.1,1-0.7,1-1.4c0-0.7,0-1.3,0-2c-0.3-10.1-0.6-20.2-0.4-30.4c0-1.5,0-2.7,1.9-3.1
				c1.4-0.4,1.2-2.1,1.3-3.4c0.1-1.6-1-0.8-1.8-0.9c-1.1-0.1-1.4-1-1.4-1.9c0-1.6,0-3.2,0-4.8c0.2,0,0.4-0.1,0.6-0.1
				c0.5,1.7,0.9,3.4,1.4,5.1c2-1.5,0.7-3.9,1.8-5.4c1.5,0.1,1,1.9,2.2,2.1c1.1-1.2,0-3,1-3.8c1.3-0.2,1.1,2.5,2.2,0.9
				c0.9-1.3,1.4-1.1,2.5-0.7c1.5,0.5,2.3-1.1,3.1-1.9c1.1-1.1-0.1-1.8-0.8-2.6c-1.2-1.2-0.6-2.2,0.9-2.6c0.9-0.2,2-0.1,2.9-0.1l0,0
				c-0.9,4.1-0.9,4.1,3.2,3.1c0.3-0.1,0.6-0.2,1-0.3c0.9-0.1,1.6,0.3,1.6,1.2c0,1.1-0.6,1-1.5,1c-1.3,0-2.7,0.5-4,0.8
				c-1,0.2-1.1,0.9-0.8,1.8c0.8,2.2,3.3,2.9,5.5,2.6c2.9-0.4,6.1-1.2,8.8,1.1c0.8,0.7,1.6,0.7,2.3,0c0.7-0.7,0.4-1.4-0.3-1.9
				c-1.6-1.5-2.5-3.3-2.9-5.3c-0.3-1.6-0.4-2.8-2.6-2.1c-0.9,0.3-1.9-0.1-1.8-1.2c0.1-1.2,0.2-2.8,1.8-2.5c2,0.4,4.8-1.6,6,1.6
				c0.3,0.7-0.3,0.9-0.8,1.2c-1.7,0.7-2.2,0.7-0.5,2.9c1.9,2.5,3.6,4.5,6.2,6.1c0.8,0.5,0.2,1.4,0.3,2.1c0.5,3.5,1.4,6.7,4.6,8.8
				c1.7,1.1,1.5,2.5-0.1,3.5c-1.2,0.7-2.5,1.4-3.4,2.5c-0.3,0.4-0.5,1.1,0.1,1.5c0.6,0.5,1.1,0,1.3-0.5c0.8-1.5,2.1-1.9,3.7-2.3
				c1.5-0.4,2.7-1.8,2.3-3.4c-0.4-1.3,0.2-2.3,0.3-3.5c0-0.7,0-1.7,1-1.7c1.1-0.1,1.4,0.7,1.5,1.7c0.2,2.4,1.1,3.6,3.5,1.7
				c0.4-0.3,1-0.4,1.5,0c0.5,0.4,0.2,1,0.1,1.5c-0.2,1.1-0.9,1.7-1.9,2.1c2.4,1.7,4.6,2.3,7.2,2.2c4.1-0.2,4.2,0,4.1,4.4
				c-0.1,4,1,5.3,5.1,6.1c2.2,0.5,4.5,0.9,6.7,1.5c2.4,0.7,2.8,2.4,2.3,4.6c-0.5,2.1-1.7,2.8-3.9,2.3c-2.4-0.6-4.9-1.2-7.3-1.7
				c-0.9-0.2-2-0.5-2.7,0.5c-2.1,3.6,1.5,6.6,1.2,10c0,0.4,0.1,1,0.4,1.1c0.4,0.2,1,0.3,1.5,0.3c3.3-0.2,6.5,0.3,9.8-0.2
				c2.9-0.5,6-1.4,8.7-1.2c5.2,0.3,10.2-0.6,15.3-1.2c0.5-0.1,1.3-0.1,1.3-0.7c0-3.2,1.5-6.3,1.1-9.3c-0.4-3.4,0.3-6.6,0-9.9
				c-0.3-4.7,0.6-9.3-1.3-14c-1.1-2.7-3.1-5.6-1.2-8.9c0.3-0.5-0.4-1.9-0.9-2.3c-2.4-1.3-2-3.9-1.3-5.1c1.8-3,0.8-6.2,1.5-9.2
				c0.7-2.7-0.5-3.4-3-2.3c-0.1-1.4,0.1-2.5,1.4-2.8c1.3-0.3,1.6-1.4,1.5-2.2c-0.4-3.9-0.6-7.9-0.6-11.9c0.1-6-3.2-11.1-5.7-16.3
				c-2-4.1-3.8-8.3-5.3-12.5c-1.3-3.7-3.4-7-4.4-10.8c-0.9-3.7-5.1-5.7-4.9-9.9c0-0.1-0.3-0.3-0.4-0.5c1-4-2.9-9.8-6.9-10.2
				c-1-0.1-1.9-0.3-2.6-1.2c1.1-0.9-0.3-3.1,1.7-3.3c2.8-0.2,0-1.4,0.4-2c-0.1-5.2-3.4-11.6-7.4-11.3c-2.1,0.2-2.7-1.4-3.8-2.6
				c-1.5-1.5,1.9-1.4,0.8-2.7c-2.3-0.7-1.5,2.2-3.2,2.5c-2.7,0.5-0.9,1.6,0.1,2.4c1.4,1.1,3.4,0,4.8,1.7c-4.7-0.2-9.2,0.7-13.6,2.2
				c-0.3,0.1-0.8-0.2-1.3-0.2c-3.4-0.5-6.7-0.6-10.1,0.1c-1.5,0.3-2.7,0.4-3.8-1c-0.8-1.1-1.9-1.8-3.4-0.9
				c-3.3,2.2-6.4,2.6-10.6,1.7c-6-1.3-12.4-1.2-18.6-1c-3.9,0.1-8.2,0.5-12.2,1.1c-3,0.5-5.6-0.4-8.3-1.8c4.2-1.6,8.7-2.4,12.9-1.9
				c4.7,0.6,8.7-1.1,13-2c1.3-0.3,2.2-1.3,2.6-2.6c-1.9-1.2-4.1,0.4-5.7-0.9c0.4-0.9,1.2-0.8,1.9-0.8c11.7-0.3,23.3-2.3,35-1.3
				c1.6,0.1,3.2,0.6,4.8,0c3.5,0.4,7.1,3.6,10.3-0.5c0-0.1,0.2,0,0.3,0c0.7-0.1,1.4-0.5,1.4-1.2c0-1.2-1-0.9-1.7-0.9
				c-0.7,0-1.4,0.2-1.4-0.8c0-1,0.6-1.3,1.5-1.2c0.7,0,1.3,0.2,2,0c1.3-0.3,3.2-0.1,3.1-2.1c-0.1-2-0.8-3.9-3.2-4.3
				c-0.6-0.1-1.3,0-2,0c-0.7,0-1.3-0.3-1.2-1.1c0.1-0.7,0.6-0.9,1.2-0.8c3.6,0.7,2.7-1.1,1.8-3.1c-0.6-1.4-1.3-2.8-1.7-4.2
				c-0.5-2-1.6-3.1-4.1-2.7c1.2-0.6,2.6-1,1.7-2.4c-2-3.2-2.6-6.8-4.2-10.1c-2.1-4.3-1.9-4.4-6.8-3.1c-16-0.3-32.1-0.3-48.1,0.2
				c0.1-1.1,1.5-0.3,1.6-1.3c-1.1-0.2-2.4-0.5-3.2,0.3c-2.2,2.1-5.1,2-7.7,2.9c-1.5-1.1-3.7-0.3-5.5-2.1c2.3-0.1,2.8-2.6,4.9-2.5
				c3.9-0.3,7.8-0.7,11.7-0.9c7.8-0.4,15.7-1,23.5-1.1c7.7-0.1,15.5-0.8,23.2,0.6c0.9-0.3,2.4,0.6,2.8-0.6c0.6-1.5-0.8-2.1-1.8-2.9
				c-1.9-0.6-3.9-2-5.8,0.2l0,0.1c-1.4,0.2-3,0.9-4.1-0.4c-0.3-0.3,0.9-0.3,1.2-0.7c-3.7-0.6-7,1.4-10.7,1.5
				c-4.3,0.1-8.2-1.9-12.4-1.6c-4.4,0.3-8.7-0.1-13.1,0.4c-4.2,0.5-8.4,0.7-12.6,1c1.9,1.8,1.9,1.8-1.5,3c-0.7-0.3-1.2-0.8-1.4-1.6
				c0.8-0.6,0.3-0.9-0.3-1c-0.2,0-0.4,0.1-0.6,0c-0.1-0.1-0.3-0.5-0.2-0.5c3.4-3.6,0.7-4.1-2.3-4.5c-0.1,0-0.2-0.3-0.3-0.6
				c2.3-0.4,4.2-1.8,4.1-3.8c-0.1-1.8-2.7-1-4.1-1.6c-0.2-0.1-0.6,0-0.5-0.6c2-0.2,4-1,4.2-3.2c0.2-2.6-2.9-1.1-4.1-2.5
				c1.5-0.9,3.9-0.7,3.9-3c0-2.6-2.6-2.4-4.2-3c0.2-1,0.9-0.7,1.3-0.9c1.7-1.4,4.8-2,3.7-5c-0.5-1.3-0.3-2.5-0.7-3.7
				c21.5,1.6,43,1,64.5,1.6c-0.9,1-0.5,1.9-0.1,3c4.5,10.1,9.3,20.2,13.3,30.5c1.6,4.2,6,7,5.6,12.3c-0.1,1.1,1.9,2.4,3.1,3.6
				c0.4,0.4,1.2,0.5,1.4,0c1.2-2.9,3.6-2.2,5.8-1.9c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.2,0.5c-3,0.3-3.7,2-2.2,4.6
				c-1.7,0.1-3.5-1.3-4.4,0.5c-0.7,1.3,0.3,2.8,1.3,3.7c1.2,1.2,1,2.8,1.7,4.1c0.7,1.3,1.3,2.4,2.8,2.8c-0.1,0.3-0.1,0.7-0.2,1
				c-1,2.3-0.6,4,2.1,4.6c0.9,2.8,1.7,5.6,4.1,7.6c0.1-2.5-0.2-4.8-0.1-7.2c0-1.2,1.9-1.9,0.8-3.2c-1.3-1.5-0.8-2.5,0.6-3.5
				c2.4-1.6,0.6-2.1-0.7-2.6c0.7-0.4,1.4-0.7,1.9-1.3c1.1-1.1,4.1-1.6,2.4-3.7c-1.6-2.1-2.7,0.9-4.3,1.6c-0.1-2.3-0.3-4.3,2.2-5.2
				c1.1-0.3,1.8-0.8,1.6-2c-0.3-1.2-1.2-1.5-2.3-1.2c-1.3,0.3-1.9-0.2-1.7-1.5c0.3-1.5,0-3.1,0.3-4.4c1.1-4.5-0.2-8.4-2.3-12.2
				c-0.8-1.5-1.6-3.8-0.6-4.8c1.5-1.5,1.3-1.5-0.2-2.7c-2-1.5-1.2-4-1.1-6.1c0.1-1.7,1.5-1.2,2.5-1.3c1.3-0.2,1.6-1,0.5-1.7
				c-3.1-2-3.7-4.8-3.9-8.2c-0.3-4.9-0.3-9.8-0.3-14.6c1.5-0.2,2.5,2,3.5,0.4c0.7-1,0-2.7-0.9-3.2c-4.3-2.3-2.8-5.9-2.1-9.1
				c0.4-1.9,0.7-3.9,0.4-5.7c-0.5-3.4-0.2-6.6,1.5-9.6c0.6-1,0.9-2,0-3c-1.1-1.4,0.9-2,0.9-1.9c1.6,3.4,2.9,1,4.3-0.1
				c0.2-0.2,1.3,0.2,1.4,0.4c0.9,2.8,3.3,4.8,4,7.7c0.2,0.6-0.1,1-0.5,1.5c-2.2,2.5-3.2,5.1-0.4,8c0.4,0.4,1,1.4,0.8,1.6
				c-0.7,1-1.5,2,0.5,2.2c1.4,0.1,1.5-1,1.7-2.2c0.8-5.8-1.7-11.4-0.8-17.2c0.9-5.5-0.8-11-0.2-16.6c0.1-1,0.6-2,0.1-3.2
				c-1.2-3.1-0.6-6.4-0.8-10.3c1.1,1.8,1.3,3,1.3,4.3c0,0.7-0.2,1.8,0.8,1.8c1-0.1,1.3-1.1,1-2c-0.8-2.2-0.4-4.5-0.4-6.8
				c0-1.1,0.2-2.1,1.7-1.2c0.5,0.4,1.1,0.4,1.3-0.3c0.2-0.8-0.3-1.1-1.1-1.2c-2.8-0.3-4.5,0.6-4.3,2.4c0.5-2.2-1.7-2-4.2-1.6
				c-4.7,0.8-9.4,1.6-14.1,2.4c-1.6-1.1-3.3-0.6-5-0.3c-3.5,0.6-7.1,1-10.6,1.6c0.2-4.1-1.4-7.9-1.6-11.9c-0.1-1.7,0.1-2.6,2.1-2.9
				c7.9-1.1,15.7-2.4,23.5-3.6c4.5-0.7,9-1.4,13.4-2.1c-2.7-0.6-5.2-1.1-7.9-0.1c-2.4,0.9-5.2,1.4-7.8,0c1.2-1.7,3-0.1,4.8-0.9
				c-3.4-1.7-6.9-1.9-10.3-2.3c-6.6-0.8-13.2-1.4-19.8-2c-0.6-3.5-1.4-7-1.8-10.6c-0.3-2.8-1.8-3.4-4.2-3c-1.1,0.2-2.5,1.4-3.4-0.3
				c1-0.5,2.8,0.2,2.9-1.4c0.2-2.4,1.9-2.1,3.3-2c5.5,0.4,11.1,0.7,16.6,1.3c13.1,1.6,26.4,0.4,39.4,2.9c2.7,0.5,5.9-0.6,8.2,1.7
				c-0.4,0.1-0.8,0.3-1.3,0.3c-5-0.2-10-0.4-14.9-1.4c-1.3-0.3-2.7-0.4-4.5,0.9c7.9,0.7,15.1,1.2,22.3,2.2c8,1.1,16.1,0.8,24,2.5
				c1.3,0.3,3,0.6,4.1-0.6c1.4-1.6,2.8-1.1,4.5-0.7c2.2,0.5,4.5,1.9,6.7,1.1c3-1,6.1-1.4,9.2-1.2c3.7,0.3,8.4-1.8,10.5,3.4
				c0.1,0.2,0.4,0.3,0.5,0.3c4.3-1.2,2.8,2.2,2.9,3.9c0.6,6.7,1.3,13.3,1.2,20.1c0,1.3,0.6,1.7,1.8,1.7c1.1,0,1.6-0.5,1.5-1.6
				c-0.1-8.5,2.7-16.7,3.1-25.2c0.1-2.5-0.3-3.9-3.1-4.1c-5.4-0.3-10.9-0.7-16.3-1.1c-1.1-0.1-2.6,0.3-3-0.8c-1.1-3.3-3.4-2.3-5.6-2
				c-3.2,0.5-6.5,0.7-9.7,1.5c-1.8,0.4-4-0.2-5.2,1.4c-2.2,2.8-4.6,2.1-7.2,1.1c-1.8-7.8-4.6-9.7-11.8-10.5c0.3-1,0.9-1.7,2.1-1.6
				c4.7,1.8,9.4,3.5,14.9,3c-0.8-1.5-2.9-0.7-2.8-2.3c0.1-1.6,1.8-1.6,3-2.1c1.6-0.7,3.4-0.9,5-1.4c2.4-0.7,4.9-2.1,5.8-4.6
				c0.8-2.1-2.3-1.6-3-3c3.9-0.7,7.6,0.5,11.3,0.4c1.8-0.1,3.8,2.1,5.2,0.4c1.2-1.5-0.2-3.8,0.2-5.6c0.4-2.4-0.9-4.8-0.2-7
				c1.1-3.3,1.4-3.3-2.4-3.1c-1.5,0.1-3,0.5-4.5,0.7c-4.1,0.4-8,1.7-12.2,1.5c0-0.7,0.6-0.7,1-1c3.2-2.4,6.8-4.5,7.6-9
				c4.3-1.7,8.8-2.3,13.4-2.6c-0.6,2.3-2.1,4.3-2.4,6.8c-0.2,1.4,0.2,1.8,1.3,2c2.5,0.5,4.9,1,7.4,1.3c9.2,1.2,18.3,2.2,27.5,3.5
				c3.3,0.4,6.8,0,10.1,1.1c0.1,1.8-0.2,3.4-2.6,3.3c-3.5-1.1-4.5-0.4-4.5,3c0.1,7.2-0.1,14.4,0.5,21.5c0.2,2.4,2,3.9,4.4,3.5
				c1.1-0.2,2.1-0.6,3.2-0.8c5.7-1,11.4-2.2,16.8,1l0,0c-5.4-0.4-10.8-1-16.2,0.1c-0.8,0.2-2.2-0.1-2,1c0.4,3.5-2.3,3-4.3,3.4
				c-2.1,0.5-4.2,0.8-6.3,1.3c-2.1,0.5-1,1.3-0.1,2c0,0.4,0.1,0.8,0.1,1.2c-0.9,0.2-2.1,0-2.1-1c0-3.4-2.2-2.4-4-2.2
				c-1.6,0.2-3.2,0.4-4.8,0.7c-1.4,0.2-2.6,0.8-2.3,2.5c0.9,5.3-0.7,10.3-1.8,15.4c-0.3,1.4-0.8,3,1.6,3c0.8,0,2-0.2,2,1
				c0.1,1.3-1.2,0.8-2,1.1c-0.9,0.3-2.1,0.1-2.4,1.3c-0.3,1.2,0.4,1.8,1.4,2.2c-0.4,1.2-2.1,1.8-1.5,3.5c0.1,1.6,0.2,3.2,0.2,4.9
				c0,1.3,0.5,1.6,1.8,1.7c4.4,0.2,8.7,0.2,12.6-2c0.7-0.4,1.6-0.6,2.5-0.7c5-0.5,5-0.5,5.5-5.1c0.9-0.4,1.9-0.6,2.8-0.1
				c0.5,2.1,0.2,4.9,2.7,5.5c2.6,0.6,5.4,1.6,8.3,0.7c4.1-1.3,8.4-2.1,12.8-1.7c2.1,0.2,4.2,0.1,6.2,0.1c2.1,0.1,3.5-0.8,4.6-2.6
				c0.4-0.7,0.4-1.8,1.6-1.9c0.4-0.6,0.9-0.6,1.4-0.1c0.3,0.4,0.3,0.8,0.1,1.2c-1.8,3.6-1.8,3.6,2.1,4.6c0.6,0.2,1.3,0.2,1.9,0.5
				c1.4,0.8,2.4,2.4,1.3,3.4c-2.3,2-1.5,4.3-1.7,6.6c-0.1,1.4,0.2,3-2.1,2.8c-1.9-1-3.9-1.1-6-1.4c-6.4-0.7-12.9-0.9-19.3-0.3
				c-6.2,0.6-12.4-0.7-18.7,0.8c-4.3,1-8.8,1.1-13.2,2c-0.9-1.2-1.7-2.2-1-4.1c0.5-1.3,0.4-3,0.2-4.5c-0.5-2.9-3.2-2.2-5-2.7
				c-1.1-0.3-1.7,0.6-1.6,1.5c0.1,2.8,0.1,5.7,0.5,8.5c0.1,1.1,0.3,2.7,2.2,2.7c0.1,0.2,0.2,0.4,0.3,0.6c-1.4,0.4-2.2,1.1-2.1,2.8
				c0.3,4.9-1.4,9.7-0.3,14.7c0.3,1.4,0.3,2.8,1.6,3.4c1.4,0.6,1.6,0.9,0.3,1.8c-1.2,0.8-1,2.2-0.5,3.3c0.6,1.3,1.8,0.6,2.9,0.5
				c2.9-0.5,5.2-1.9,7.9-4.1c-3.6,0.2-4.7-1-2.9-3.6c1.5-2.1,0.1-2.2-1.3-2.7c-1.8-0.6-4,0.4-6-1.2c1.8-0.3,3.3-1.1,4.5-0.7
				c2.3,0.8,2.5-0.5,2.8-2.1c0.3-2.3-1.4-2-2.7-2.1c-1.3-0.2-2.8,0.6-4.2-0.8c2.9-0.8,5.4-2.1,8.1-3.7c-3-1.2-1.7-3.7-2.2-5.6
				c1.7-0.2,3.5-0.3,5.2-0.5c16-1.6,32-2.9,48.1-1.1c1,0.1,1.7-0.1,2.4-0.8c-0.5,1.4,2.4,3.7-1.1,4.4c-1.5,0.6-3,0.7-4.5,0.5
				c-12.5-1.5-25-0.9-37.6,0c-2.2,0.1-4.7-0.1-7,1.4c2,0.6,3.8,0.5,5.5,0.4c12.2-0.6,24.4-1.3,36.6-1c2.6,0.1,5.1,1.3,7.7,0.3
				c0.9,0.9,1.8,1.9,2.7,2.8c-0.6,0.4-1.3,0.9-1.1,1.8c-1.4-1-2.9,0-4.3-0.1c-15.3-1.2-30.5-0.7-45.7,1.5
				c15.1,0.3,30.3-1.1,45.4-0.2c1.1,0.6,2.2,1.1,3.3,1.6c1.3,0.6,0.1,0.9-0.6,1.1c-2.2-0.4-3.5,2-5.8,1.8
				c-12.4-1.2-24.9-0.6-37.3,0.3c-2,0.1-4.4-0.1-6.8,1.4c3.8,0.9,7,0.5,10.3,0.2c12.4-0.9,24.7-1.1,37.1-0.4
				c1.5,1.3,4.6,0.4,4.8,3.5c-0.6,0.7-2.2,0.2-2.1,1.7c-0.1,0.9-0.1,1.7,1,2c0.7,0.2,1.7,0.4,1.4,1.4c-0.2,0.8-1.1,0.9-1.8,1.1
				c-0.8,0.3-1.9,0-1.9,1.3c0,1.1,1,1.2,1.8,1.5c0.9,0.3,2.2,0.4,2.1,1.6c-0.1,1.2-1.4,1.1-2.3,1.5c-0.6,0.2-1.6-0.4-1.7,0.9
				c0,0.9,0.7,1.3,1.4,1.6c0.5,0.2,1.1,0.3,1.5,0.5c0.6,0.3,1.6,0.4,1.5,1.4c-0.1,0.7-0.9,0.8-1.5,1c-1.1,0.5-2.7,0.1-3.1,1.9
				c-1.1-1.3-2.7-1.3-4.2-1.3c-11.5,0.1-23-1-34.5,0.5c-1,0.1-2.1,0.3-2.7-1.1c-0.4-0.9-2.1-0.1-1.9,0.1c1.8,1.5-0.2,1-0.6,1.3
				c-0.6,0.4-1.7,0.4-1.5,1.4c0.3,1,0.8,2,1.5,2.7c0.6,0.5,1.4-0.2,2-0.7c1.1-1,2.1-2.2,3.8-2.2c12.7-0.3,25.5-1.6,38.2,0
				c0.4,0.1,0.9,0,1.3,0.2c1.1,0.6,3,0.2,3.2,1.8c0.1,1.5-1.7,1.3-2.7,1.8c-0.5,0.3-1,0.5-0.9,1.1c0.1,0.8,0.9,0.8,1.4,1.2
				c0.8,0.4,2.2,0.2,2.3,1.5c0.1,1.5-1.3,1.5-2.2,2c-0.5,0.2-0.8,0.7-1.2,1.1c-2.9-0.8-5.8-0.9-8.8-0.9c-9.4,0-18.8-0.1-28.2,0.2
				c-2.8,0.1-6,0.1-8.6,2c3.4,0.4,6.9,0.7,10.3,0.3c12.3-1.4,24.6-1.6,36.9-0.4c1.9,1,3.2,3.1,5.8,2.7c-0.3,1.5,0.7,1.6,1.8,1.7
				c0,1.6,1.1,2.2,2.2,2.9c4,2.6,8,5.2,12.4,7.1c0.9,0.4,1.4,1.8,2.7,1.1c1.3-0.7,0.6-2,0.6-3c0.3-5.7-1.5-11.4-0.3-17.1
				c0.3-1.2-0.5-2.1-1.8-2.2c1.5-0.8,3.2-1.4,4.5-2.5c2.5-2.2,2.8-0.7,2.9,1.4c0.4,7.8,0.6,15.6,1.1,23.3c0.1,2.1-0.8,5,2.3,5.7
				c2.4,0.6,3.8,3.4,6.3,3.1c2.7-0.3,2.9,0.9,3,2.9c0.2,2.3-0.9,2.9-2.9,2.7c-0.1-1.2,1.4-2.3,0.1-3.5c-1-1-1.9-0.1-2.8,0.3
				c-0.6-0.3-1.2-0.4-1.8-0.8c-9.5-5.7-19-11.4-28.5-17.1c-1.9-1.1-4.1-3-5.2,0.8c-0.6,0.1-1.6-0.2-1.7,0.5
				c-0.4,2.2,2.5,0.9,2.8,2.6c-1.1,1.3-3.3,0.9-4.4,2.2c-15.2,0.1-30.4-1.4-45.4,1.9c15.2-0.2,30.4-1.5,45.7-0.8
				c1.6,0.6,4.1,0.9,4.1,2.5c0.1,1.6-2.6,1.3-3.9,2.1c-0.6-0.4-0.1-2.1-1-1.6c-3,1.8-6.1,0.8-9.1,0.8c-7.5,0.1-15,0-22.4,0.5
				c-5.3,0.3-10.6,1.1-16.4,1.6c2.9,1.7,5.4,1,7.9,0.9c13.7-0.8,27.4-2.6,41.2-0.9c1.4,0.7,4.1,0.4,4,2.1c-0.2,2.2-2.9,0.9-4.4,1.5
				c-0.8,0.3-1.6,0.5-2.5,0.5c-13.2-0.7-26.3-0.4-39.4,0.8c-1.7,0.2-4-0.4-5.9,2c9.2-0.5,17.8-1,26.5-1.4c7.3-0.3,14.6-0.9,21.9,0.4
				c1-0.2,1.9,0.4,2.8,0.7c0.7,0.2,1.7,0.3,1.5,1.3c-0.1,0.8-1,0.6-1.6,0.8c-1.1,0.3-2.4,0.3-2.7,1.8c-0.6-0.5-1.3-0.6-2.1-0.6
				c-10.4,0-20.8,0-31.1,0c-2.1,0-4.4,1.7-6.1-0.9c-1.6,2.5-4.7,1.6-6.9,3.1c15.6,0,31.1-1.4,46.6-0.9c0.4,0.1,0.8,0.2,1.2,0.4
				c1.1,0.6,3,0.5,3.1,1.8c0,1.8-2.1,0.7-3.1,1.4c-0.4,0.3-0.9,0.4-1.4,0.6c-1.1,0.1-2.2,0.3-3.3,0.2c-8.8-0.9-17.7-0.3-26.5-0.2
				c-3.8,0.1-8,2.5-11.7-0.7c-0.2-0.1-0.8,0-0.9,0.1c-0.9,1.9-3,1.8-4.8,2.6c2.6,1.1,5.1,0.9,7.7,0.7c12.2-0.8,24.4-1.5,36.7-1.2
				c1.1,0,2.2,0.4,3.1-0.5c1.2,0.4,2.1,1.4,3.4,1.6c0.7,0.1,1.7,0.4,1.6,1.3c0,0.9-1,0.9-1.7,1.1c-1.1,0.2-2.3,0.3-3.1,1.3
				c-5-0.3-10-1-15-0.7c-2.7,0.2-5.4,0.5-8.1,0.3c-8.4-0.4-16.6,0.3-24.9,1.5c-0.8,0.1-1.1,0.6-1.2,1.3c-1.6,0.3-3.8,0.2-4.1-1
				c-0.3-1.3,1.5-2.5,3.1-3.1c0.4-0.2,0.8-0.4,0.6-1.1c-0.9-0.9-3.1-0.3-3-2.6c0.1-2.1,2.5-1.5,3-3c-1.2-1.3-3.9-0.1-4.1-2.5
				c-0.2-2.3,2.6-1.7,3.7-3.2c-4.6-1-2.5-7.5-7.7-7.9c2.5-2.1,5.5-1.9,8.5-4.1c-3.6,0-6.2-0.6-8.4-2.2c1-0.7,2.4-0.5,3.4-1.6
				c1.3-1.4,1-4.5-0.9-5.4c0.9-1.1,2.9-0.5,3.3-2.3c0.2-0.2,0.5-0.6,0.7-0.5c7.9,0.6,15.7-0.6,23.5-0.7c8.4-0.1,16.8-0.5,25.2,0.1
				c1.6,0.1,2.2-0.4,2.4-1.9c0.1-1.8-1.4-1-2.1-1.1c-2.3-0.3-4.5-0.7-6.8-0.6c-6.5,0.1-13.1-0.2-19.6,0.1
				c-7.2,0.3-14.4,0.9-21.5,1.4c-1.9,0.1-3.7,0.6-3,3.3c-1,0.4-1.9,0.2-2.8-0.1c-1.7-0.6-6.2,1.4-6.2,2.9c-0.1,2.7,2.2,1,3.4,1.5
				c0.4,0.2,0.8,0.4,0.8,0.8c0,0.5-0.3,0.8-0.8,0.8c-1.8,0.2-3.6,1.2-3.2,2.9c0.5,1.6,2.7,0.3,4.1,0.6c-1.6,0.9-1.5,3.1-3.1,4
				c-1.7,1-1.6,2,0.3,2.5c2.6,0.8,1.5,1.7,0.1,2.6c-2.1,1.4-1.3,2.7,0.4,3.7c0.6,0.4,1.4,0.4,1.7,1.2c-0.8,0.5-1.5,0.8-2.2,1.3
				c-1.8,1.4-0.8,2.6,0.6,3.2c0.6,0.3,1.9-0.7,2,0.8c0.1,1.4-1.3,1.4-2.1,1.6c-2.1,0.7-2,1.7-0.7,3c0.1,0.9,0.2,1.7-0.3,2.6
				c0.1,1.2-0.6,2.7,1.6,2.9c1,0.1,2.5-0.3,2.7,1.2c0.1,1.2-1.6,0.8-2.2,1.5c-0.6,0.7-2,0.7-1.8,2c0.2,1,1.2,1.2,2,1.5
				c0.6,0.2,1.4,0.1,1.5,1c-0.1,0.2-0.2,0.4-0.3,0.5c-3.2,2.3-3.2,2.3,0.2,4.2c0.7,0.4,1.4,1,0.2,1.7c-1.2,0.7-3.3,0.9-3,2.8
				c0.3,1.8,2.6,0.6,3.6,1.5c0.1,0.1,0.2,0.3,0.4,0.7c-0.7,0.4-1.5,0.8-2.2,1.3c-1.7,1.1-3.5,2.1-0.3,3.7c1.2,0.6,0.9,1.5,0,2.3
				c-1.8,1.6-1.3,2.5,0.9,2.9c1.3,0.2,2.7,0.5,4.1,0.8c-0.8,1.3-2.3,0.9-3.3,1.6c-1,0.7-2.4,1.1-1.9,2.6c0.5,1.3,1.8,0.7,2.8,0.6
				c3.2-0.5,5.7-2.2,9.2-3.9c-1.5-0.3-2.3-0.4-3.1-0.5c-0.9-0.1-1.9-0.2-2.2-1.1c-0.4-1,1-1,1.3-1.6c0.6-1.7,3.2-1.1,3.6-3
				c1.9-0.4,3.8,0.9,5.7,0.4c0.6-0.1,1.8-0.4,2,0.5c0.2,1-0.6,1.8-1.5,2.2c-1.4,0.7-3.2,0.5-4.5,1.5c3.7,2.8,7.3,1.7,8.3-2.6
				c0.2-0.7,0-1.3,1.2-1.3c3.5-0.1,7,0.4,10.6-0.2c0.8-0.1,2.1-0.1,2.5,0.3c2,2.8,3.8,1.2,6,0.2c1.2-0.6,4.3-3.4,4.4,1.3
				c0,1,1.1,1.1,1.9,1.4c4.9,1.7,9.1-0.2,12.8-2.7c2.6-1.7,5-1.5,7.4-1c2.2,0.5,3.6,0.1,4.8-1.7c0.2-0.3,0.4-0.4,0.6,0
				c1.1,1,2.2,1.4,3-0.3c2.2-1,4.5,0,6.7-0.3c2.1-0.2,2.8-0.8,2.7-3.3c0-5.4-1.7-10.7-0.2-16.1c0.6-1.2,1.4-2.1,2.5-2.8
				c0.7-0.5,1.4-1.2,2.4-0.7c0.9,0.4,0.5,1.3,0.5,2c0.2,4.6,0.3,9.1,0.5,13.7c0.2,4.8,0.3,4.9,5.3,4.5c1.8-0.2,4-1.2,5.4,0.1
				c2.4,2.3,5.2,1.1,7.8,1.5c1.2,0.2,1.4-0.7,1.5-1.6c1.1,1,2.2-1.7,3.3,0c1.3,2.1,2.9,2.2,4.8,0.5c0.1,1.5,0.2,2.7,0.3,3.9
				c-6.6,0.3-13.3-0.1-19.8-1c-4.9-0.7-9.7-0.8-14.5,0.6c0.5,0.3,1,0.5,1.5,0.5c5,0.5,9.9,1.4,14.9,1.2c4.3-0.1,8.6,0.5,12.8,0.6
				c5.5,0.1,11.4-0.8,16.5,2.7c0.4,0.3,1.2,0.2,1.6,0c2.4-1.8,5.5,1.4,7.8-1c0,0,0.8,0.6,1.2,0.9c0.6,0.5,1.2,1.4,1.7,1.4
				c3.2-0.2,5.9,1.8,9.2,1.6c4.8-0.4,9.6-0.1,14.4-0.1c1.2,0,2.4,0.1,3.6-0.2c1.2-0.2,2.4-0.9,2.3-2.3c-0.1-1.6-1.5-1.2-2.5-1.4
				c-3.3-0.5-6.7-0.9-10.1-0.7c-0.8,0.1-1.6-0.1-1.8-1c-0.1-0.6,0-1.4,0.5-1.4c3.9,0,2.6-2.2,2.1-4.3c-0.6-2.6,1.3-4.1,3.4-4.3
				c2.2-0.3,1.6,2.1,2,3.4c0.2,0.8,0.3,1.9-0.1,2.6c-0.6,1.2-0.7,2.3,0.7,2.7c2,0.7,1.3-1.4,2-2.2c2.2,0.5,4.3-0.7,6.4-0.6
				c0.8,0,1.9,0.2,2.1-1c0.2-1.2-0.2-2.1-1.5-2.5c-0.2-0.1-1.4,0.2-0.5-0.4c1.4-1,0.5-2.2,0.8-3.2c0.2-0.7,1-1.4,1.1-1.1
				c0.6,1.8,4.3,1.5,2.8,4.7c-0.6,1.1-0.8,3.4,1.7,3.2c2.3-0.2,4.1,0.1,6.3,1c1.5,0.6,4.3,1,5.7-1.4c1.2,0.4,2.1-0.4,3.2-0.7
				c0,1.9,0,3.7-0.1,5.6c-4.1-0.1-8.1-0.2-12.2-0.3c-0.7,0-2-0.2-1.8,0.7c0.4,2.6-0.8,5.3,1.1,7.9c-2.7-0.7-4.9,0.5-7.3,0.6
				c-0.2-0.6-0.7-0.7-1.2-0.6c-0.1,0-0.2,0.3-0.2,0.4c0.5,0,1,0.1,1.5,0.1c0,1,0.7,1.2,1.5,1.2c1.8,0,3.5,0.1,5.3,0
				c2.2-0.1,3.4,0.6,3.9,3c0.6,2.9,0.4,5.8,0.5,8.8c0.1,5-0.7,10-0.4,14.9c0.2,4.1,0.4,8.2,0.6,12.3c0.1,4.6,0.2,9.2,0.2,13.8
				c0,5.6,0.1,11.3,0,16.9c-0.1,5-0.9,10.1-0.4,15.1c0.8,7.4,0.4,14.8,0.7,22.2c0.1,2.2,0.3,4.5-2.7,5.7c3.4-0.1,2.8,1.7,2.5,3.4
				c-3.5-0.1-5-3.7-8.2-4c-0.3,0-0.8-0.8-0.7-0.9c1-1.9-0.2-4.2,1.6-6.1c1.4-1.4,0.2-4.1,1.7-5.7c-5.1-2.4,0.8-1.9,0.8-3.2
				c-0.7-0.8-1.5-1.7-2.1-2.6c-0.7-1-1.5-1.8-2.6-1.6c-3,0.6-4.7,4.5-3.3,7.4c0.8,1.6,0.6,3.1-0.8,3.6c-2.3,0.7-2.7,1.8-2.1,3.9
				c0.3,0.9-0.5,1.7-1.5,1.8c-3.4,0.4-6.6,2.1-10.2,0c-2-1.2-4.8-1.3-7.3-1.7c-1.9-0.3-3.4,0.9-4.7,1.9c-1.2,0.9,0.9,1.8,0.7,2.8
				c-0.4,1.9,2.5,3.4,0,5.9c-0.9,0.9,0,4-2.9,4.6c0.1,0.4,0.1,0.8,0.3,1.2c0.4,0.7,0.9,1.5-0.4,1.6c-1,0.1-1.8-0.7-1.5-1.7
				c0.8-3.7-0.9-6.5-2.8-9.3c-1-1.4-1.2-2.7-0.5-4.2c1.5-3.1,0.2-6.1-0.3-9.1c-0.2-1.1-1.2-1.8-2.5-1.8c-1.8,0-3.3,0.5-4.8,1.5
				c-2.1,1.3-3.3,3.9-6.8,3.4c-2.7-0.4-4.1,4-2.3,6.2c2.7,3.3,2.7,3.2-1.1,4.1c-0.2,0-0.5,0.1-0.6,0.2c-2.8,2.8-3.7,0.5-4.9-1.6
				c-1-1.9-3.8-2.5-5-1c-0.7,0.9-1.4,2-2.6,1c-1-0.8-0.8-1.9,0-2.9c0.4-0.5,1.6-1.1,0.2-1.6c-2.7-0.9-2.7-5-6.3-4.9
				c-0.9,0-0.1-1.3,0.1-1.9c0.8-3,1.7-6.2,1.6-9.1c-0.1-3.1,0-6.4-0.6-9.6c-1.2-6.3-0.8-6.3-6.9-7.1c-1.9-0.2-2.1,0.4-1.9,1.9
				c0.4,2.8-0.6,3.7-3.4,3.4c-0.6-0.1-1.3-0.4-1.9-0.4c-1.7,0.1-3.8-1-5,0.5c-1.4,1.7,0.7,3.1,1.3,4.7c0.1,0.3,0.1,0.6,0.1,0.9
				c-3.8-0.5-3.7-0.5-3-3.9c0.2-0.9,0.2-2-0.6-2.8c-0.4-0.4-0.9-1.2-0.5-1.4c3.2-1.9,0.2-2.4-0.7-3.4c-0.4-0.5-0.8-1.1-0.8-1.7
				c-0.3-4.7-0.8-9.5-1-14.2c-0.3-6.2,0.1-12.4-0.1-18.6c-0.1-3.6-0.9-7.3-0.5-10.7c0.5-5.1,0.1-10.2-0.1-15.2
				c-0.3-5.7-0.5-11.4-1-17.1c-0.2-2.2,0.6-3.1,2.9-3.1c9.8,0.2,19.6,0.7,29.2,2.4c4.8,0.8,9.5-0.9,14.3-0.2c-3-1.2-6.1-2.6-9.1-2.6
				c-6.3,0-12.5-1.1-18.9-1.3c-6.7-0.1-13.5,0.5-20.1-0.3c-3.7-0.4-4.4,1.8-4.9,4.5c-0.2,0.8,0.3,1.8-0.5,2.5c-3.6,2.9-3,6.9-3,10.9
				c-0.1,5.2,4.1,10.2,0.5,15.6c-0.5,0.7-0.5,2.4,0,3.2c1.5,2.4,0.6,4.9,0.4,7.3c-0.2,2.3-1.9,4.6,0.6,6.7c0.2,0.1,0,0.9-0.2,1.2
				c-0.8,1.7-0.9,3.3-0.1,5c0.6,1.4-0.1,2.3-1.5,2c-1.9-0.4-3.8,0.4-5.7-0.8c-0.8-0.6-2.5-1.3-3.3-0.4c-1.1,1.4-2.2,0.9-3.5,0.7
				c-1.7-0.3-3.7-1-3.6,2.1c0,0.4-0.7,0.7-1.3,0.3c-0.3-1.3-0.7-2.6-2.3-2.6c-4,0.2-7.8,1.3-11.8,3.2c2.2,1.3,4.2,0.7,6,0.8
				c1.8,1.2,2.4,2.8,2.1,4.9c-0.2,1.3-0.1,2.6-0.1,3.9c0,1.9-0.4,3.3-2.6,3.9c-1.7,0.5-2,2-1.5,3.7c1.4,5.1,1.3,10.3,1.3,15.6
				c0,0.5,0.4,1.1-0.2,1.4c-2.1,1.2-2.1,2.5-0.4,4.1c0.4,0.4,0.4,1.2,0.4,1.8c0.2,2.8-1.6,5.5,0,8.4c0.3,0.5-0.1,1.4-1,1.4
				c-0.6,0-1.1-0.5-0.9-1.1c0.6-3-2.2-2.8-3.6-3.7c-1.4-0.9-3.3-2.4-4.8,0.3c-0.4,0.7-1.3,0.6-2.1,0.3c-2.4-0.8-3.2,0.6-3.4,2.6
				c-0.1,1.8,0,3.7-0.1,5.6c0,0.8,0.5,2-0.7,2.2c-0.8,0.1-1.1-1-1.4-1.6c-2.1-3.6,0.7-8-2.1-11.6c-0.4-0.5,0.7-1.5,1.1-2.2
				c0.8-1.5,1.9-3.1,0.8-4.8c-3.2-5.1-0.3-9.3,2-13.6c-1.5-1.1-3.8-2.3-2.4-4.6c0.7-1.3,0.7-2.3,1-3.6c0.7-3.7-2.3-6.4-2-10.1
				c0.7-9.9-0.5-19.7-0.5-29.6c0-2-1.5-3.7-0.5-6c0.6-1.4-1.1,0-1.7-0.2c-0.6,0-1.3,0-1.9-0.1c0.1-1.5,0.2-3.1,0.3-4.6
				c0.5,0,1,0,1.5,0c0.7,0.7,0.1,2.7,1.6,2.3c1.2-0.3-0.1-1.9,0.2-2.5c1.3-2.4-1.5-4.7,0.2-7.2c0.2-0.3-0.2-3.5-1.7-4.8
				c-0.8-0.7-0.7,0.5-1.1,0.5c-3.8,0.3-7.2,2.8-11.6,1.5c1.8-2.4,4.9-0.8,6.6-2.8c-3.8,0-7.5,0-11.5,0c1.4-1.7,2.9-2.6,4.7-3.1
				c1.2-0.3,2.7-0.4,2.9-2.1c2,0,4.1,0.1,6.1,0c1.1,0,1.9-0.6,1.9-1.8c0-0.8-0.3-1.7-1.3-1.5c-3.5,0.6-7.4-0.3-10.4,2.5
				c-0.2,0.5-0.7,0.4-1,0.6c-2.8,1.3-5.9,1.3-7.1,5.6c-0.9,3.2,1.2,5,1.1,7.5c-0.1,3.2,0.9,4.9,4.2,5.2c-2,1-5.9,0.8-3.2,4.9
				c-0.2,1.3-0.6,2.5-0.6,3.8c0,1.4-1.7,3.1,0.5,4.2c0.1,3.2,0.1,6.5,0.2,9.7c-0.7,3.1-0.7,6.1,1.1,8.9c0.2,0.2,0.3,0.3,0.5,0.5
				c0.1-0.1,0.2-0.3,0.2-0.4c0.3,0,0.6,0,1,0c1.5,1,2.3,2.4,2.4,4.7c0.8-1.4,1-2.6,1.8-3.2c2.1-1.4,1-2-0.5-2.7
				c-0.6-0.3-1-0.7-1.6-1.1c-1.2-1.9-2.4-3.9-2.6-6.3c1.1-1.6,2.5-1,4-0.7c3.6,0.5,7.2-0.2,10.8-0.3c0.1-2.9,0.2-5.8,0.3-8.7
				c0.6,0,1.1,0,1.7,0c0.2,0.4,0.4,0.8,0.5,1.2c0.2,7.5,0.4,15,0.5,22.4c0,2.7-0.5,5.5-0.1,8.1c0.7,3.9-0.1,7.7-0.3,11.5
				c-1.9-0.6-3.3,0.6-4.4,1.6c-2.3,2.5-5,2.8-8,2c-0.6-1.9-0.7-3.8-0.7-5.8c-0.2-4.1-0.8-8.2-0.9-12.3c-0.1-2.5-1.7-2.1-3.1-2.2
				c-2-0.2-2,1.1-2,2.5c0.5,11,0.2,21.9,1.7,32.8c-1.3,1.6-0.6,3.6-0.7,5.4c0,1.6,0.9,1.4,1.9,0.6c0.9-0.7,1.1-2.6,3-1.8
				c2.4,0.8,4.3,2.4,6.3,3.8c1.8,1.2,3.5,2.9,5.8,3c0.1,2.2,0.2,4.5,0.4,6.7c0.1,1.1,0.1,2.2,0.4,3.2c2.1,5.7,1.2,11.5,1,17.3
				c-0.6,0.1-1.4-0.1-1.9,0.2c-4.7,2.7-9.4,5.4-14.1,8.1c-1,0.5-1.1,1.3-0.8,2.2c0.4,1.3,0.8,2.7,1.2,4c0,3,0,6,0,8.9
				c0,1.3,1,1.2,1.8,1.2c0.3,1.2,0.1,2.3-0.2,3.5c-0.4,0.9-1.6,1.1-1.5,2.7c0.8,10.3,1.2,20.7,1.8,31.1c-0.1,0.8-1.2,1.5-0.1,2.3
				c0.9,0.7,6.2-0.3,7.3-1.4c0.6-0.1,1.3-0.1,1.9-0.2c-0.2,1.7,1.8,1.8,2.6,2.9c-1.4,0.8-2.6,0.4-3.7,0.5c-2.2,0.1-4.4,0-6.6,0
				c-0.9,0-1.9,0.4-1.9,1.5c0.1,1.4,0.8,2.7,1.8,3.6c0.8,0.6,1.2-0.5,1.7-1.1c1.5-1.7,3.6-1.7,5.7-1.7c-0.9,1-2.2,1.8-1.8,3.1
				c0.5,2-0.7,1.8-1.9,1.9c-4.9,0.2-5,0.2-4.8,5.2c0,1.3-0.7,3.4,1.1,3.7c3.1,0.5,5.3,3.6,8.9,2.4c1.2-0.4,2.4,0.5,2.2,1.7
				c-0.3,2.2-0.3,4.3-0.4,6.5c0,1.3-0.9,1.4-2,1.5c-2.6,0.3-5.2,0.7-7.7,1.8c1.7,4,4.4,2.6,6.9,1.6c3.3-1.4,3.6-1.2,3.5,2.4
				c0,0.7,0.1,1.6-0.7,2.1c-1.1,0.1-1.9-1.7-4,0c2.1-0.1,2.2,2.4,4.3,1.2c0.5,0,1,0,1.4,0c1.2,0.5,1.9,1.3,1,2.4
				c-0.9,1.2-0.6,2.5-0.9,3.7c-0.5,2.5,0.4,5.2-0.7,7.7c-1.9,1.7-4.2,2.5-6.6,3.4c-2.1,0.8-3.8,2.6-5.8,3.5c-2.3,1.1-3.4,1.8-0.9,4
				c0.8,0.8,3,2.1,1.8,3.6c-1,1.2-1.9,2.7-3.3,3.5c-2.1,1.1-3.6,2.3-2.9,5c0.7,2.7,2.7,3.9,5,4.3c1.8,0.3,4.4,1.7,5.4-1.2
				c1.1-3.1,3.1-1.5,4.8-1.3c0.8,0.1,1.2,1.3,2.2,0.9c0.6-0.9-0.2-2,0.7-2.8c1.7,0.5,0.9,1.8,0.8,2.7c-0.4,3.6-0.6,7.1-0.6,10.7
				c0,2.1,0.6,3.1,2.9,3.2c4,0.2,8,0.7,12.1,1c1.4,0.1,2.8,0.2,2.9,1.9c0.3,3.6,0.7,7.2,0.5,10.7c-0.1,2.3-1.6,4.6,0,6.9
				c0.1,0.2-0.2,0.7-0.4,1.1c-3.1-1.9-2.3,1.5-3.2,2.7c-1.4-2.2-1.4-2.2-3.6-1.6c-1.4,0.4-6.1-0.7-7-1.8c-1.1-1.4-1.7-1.1-2.1,0.4
				c-0.8,2.4-1.8,1.8-3.2,0.4c-1.2-1.2-1.1-3-2.4-4.5c-0.8,2.5,3,7.3-3.7,5.7c0.9,2.2-0.3,4.7,0.5,5.9c2,3.2-0.7,2.8-2,3.1
				c-4.9,1.3-6.8,0.2-8.2-4.5c-0.6-2.1-1.9-2.5-4-3.2c-4.2-1.3-9.7-0.1-12.4-5.1c-0.3-0.6-1.2-1-2-1.1c-2.8-0.4-5-1.8-7.4-4.1
				c0.4,3.6-1.8,6.2-2,9.2c-0.2,4.1-2.9,7-5,10.1c-0.5,0.8-1.8,1.2-3,0.8c-2.3-0.8-3.8-0.2-4.3,2.4c-0.9,4.5,0,6.9,2.6,7.6
				c7,1.9,13.7,4.3,20.1,7.9c5,2.8,10.4,4.5,16.1,5.4c1.1,0.2,2.5,0,3.2,1.2c-46.2,0-92.5,0-138.7,0c0.1-1,0.3-1.9,0.2-2.9
				c-0.7-11.3-1.3-22.6-2-33.9c-0.9-14-1.8-28-2.6-42c-0.5-9.1-0.9-18.3-1.5-27.4c-0.9-14.6-1.9-29.2-2.6-43.9
				c-0.2-5.3-0.3-10.7-0.8-16c-0.5-5.6-0.2-6.1,5.2-6.3c13.4-0.7,26.9-1.2,40.3-2c2.7-0.2,2.9,0.9,2.4,2.9
				c-1.2,4.3-2.3,8.6-3.4,12.9c-0.6,2.3,0.2,3.1,2.5,2.6c3.1-0.6,6.2-1.1,9.4-1.7c1.4-0.3,2.2-0.8,1.3-2.4
				c-2.2-3.9-3.9-8.1-6.5-11.7c-0.5-0.7-1.3-1.3-1-2.2c0.4-1.2,1.6-0.9,2.5-0.9c8.2-0.4,16.4-0.6,24.6-1c4.4-0.2,8.7-0.7,13.1-1
				c-2-1.6-4.2-1.4-6.3-1.4c-12.1,0.5-24.3,1.1-36.4,1.7c-15.8,0.8-31.6,1.5-47.5,2.3c-4.5,0.2-8.9-3.7-9.5-8.6
				c0.7-0.2,1.7,0.4,2.2-0.3c2-3.4,4.9-3.6,8.4-3.3c1.7,0.2,4.9,0.5,5.2-2.9c1.8-0.8,3-1.9,1.2-3.9c-0.6,1.1-0.3,3.7-2.5,2.3
				c-1.6-1-4.3-0.4-4.8-3.3c-0.2-1.1-1.4-0.5-2.4,0c-2,1.2-3.1,0.3-3.1-2c0-0.6,0.8-1.2-0.3-1.9c-0.4,1-0.8,2-1,3
				c-0.2,0.8,0.1,2-1.1,2c-1.2-0.1-0.9-1.3-0.9-2.1c0-0.4,0-0.8,0-1.6c-2.6,5.7-2.6,5.7-5.4,2.6c-1-1.1-1.9-2.1,0.7-2.3
				c1.6-0.1,2.3-0.5,1.3-2.7c-0.8-1.9-2.1-3.4-2.9-5.3c-0.9-2.3-2.1-2.3-3.4-0.4c-0.6,0.9-1.2,1.9-2.5,1.7c-2.5-0.3-4.9-0.6-7.4-1.1
				c-0.6-0.1-1.2-0.8-1.6,0.1c-0.3,0.6-0.3,1.3,0.3,1.8c0.9,0.9,2.1,1.5,3.4,1.9c2.1,0.5,4.2,1.1,6.3,1.6c0.9,0.2,1.5,0.4,1.2,1.4
				c-0.3,0.8-1,1.2-1.8,0.9c-1.3-0.5-2.7-0.7-3.7-1.9c-0.8-0.9-2.3-0.6-1.9,0.7c0.7,1.9,0.8,4.3,3,6.3c-0.4-4.2,1.3-3.4,3.7-2.5
				c2.1,0.8,2.3,3.5,3.5,3.7c2.5,0.5,2.1,1.9,2,3.4c-1,1.5-1.7,3,0.7,3.9c0,0.3-0.9,0.8-0.3,0.8c2.1-0.1,2.4,1.1,2.3,2.7
				c-0.1,1.5,0.7,2.6,1.4,3.9c1.6,2.9,4.3,5.1,4.6,8.7c0.3,4.1,0.8,8.4,0.9,12.4c0.2,7.1,1.2,14,1.8,21c0.3,3.3,0.2,6.6,0.7,9.9
				c0.3,2.4-0.5,5.5-0.2,8.2c1.2,12.8,1.4,25.7,2.2,38.6c0.3,5.3,0.5,10.6,1,15.8c0.4,5.2,0.7,10.5,1,15.7c0.1,2-0.7,4.2-0.2,6.1
				c1.6,5.7,1.4,11.5,1.5,17.3C822.3,2199.7,823.1,2207,823.9,2214.3z M721.4,2119.4c0.2-0.2,0.4-0.5,0.7-0.7c0.2-2.8,3.7-3.7,4-6.5
				c0-0.5,0.7-0.8,1.3-0.7c0.8,0.1,1.1,0.7,1.2,1.3c0.5,2.8,0.5,5.4-1.9,7.5c-0.7,0.6-1.9,1.3-1.3,2.4c0.6,1.1,1.8,0.3,2.7,0.3
				c7.2-0.6,7.5-1.1,7.2-8.7c0-0.2,0-0.4,0-0.7c-0.1-1.7,0.1-3.8-2.3-4.1c-2.3-0.3-1.9,1.9-2.5,3.1c-0.1,0.1-0.3,0.1-0.7,0.2
				c-1.1-0.8-0.7-3.5-3.2-2.8c-1.7,0.5-2,2.2-2.9,3.5c-1.1-1.5-1.3-3.8-3.6-2.9c-1.5,0.6-2.1,1.9-1.7,3.7c1.3-0.5,1.1-2.5,2.6-1.9
				c1.3,0.5,1.4,1.8,1.4,3c-0.1,1.1-0.2,2.1-0.3,3.2C722,2119.1,721.8,2119.3,721.4,2119.4z M409.8,1841.9L409.8,1841.9
				c0.3,0,0.7,0.1,1.1,0.1c1.1-0.1,1.7,0.8,2.7,1.1c0.8,0.2,1.8,0.3,1.9-0.6c0.7-4.2,1.8-8.3,1.4-12.6c-0.1-0.9,0.5-1.7,0-2.6
				c1.7-1.7,2.1-3.9,2.3-6.2c0.1-0.9,0.9-2-0.2-2.6c-0.9-0.6-1.8,0.1-2.7,0.5c-1.8,0.7-3.2,2.2-5.2,2.5c-0.2-0.2-0.5-0.5-0.7-0.7
				c2-1.8,4.5-2.8,6.8-3.7c2.6-1,3.4-2.6,3.7-5.1c0.7-5.9,2.5-11.7,0.6-17.7c-0.1-0.1-0.2-0.1-0.2-0.1c0.1-0.2,0.1-0.3,0.2-0.5
				c2.1-1.9,2.1-4.2,1.7-6.8c-0.2-1.4-0.3-2.9-0.3-4.4c0.1-4.4,0.5-8.8-1.5-13c-0.2-0.6-0.4-1.1-0.6-1.7c1.1-0.2,2.2,0.3,2.5,1.2
				c0.4,1.6,1.3,1.6,2.4,1c1.8-1-0.7-2,0-3.4c0.4-0.9-1.2-0.4-2-0.3c-5.3,0.9-10.7,1.5-15.8,2.9c-4.6,1.2-9.9,0.1-13.8,3.7
				c-0.5,0.5-1.1-0.4-1.7-0.6c-2.8-1-5.3,0.2-7.9,1c0,0,0.2,0.8,0.3,1.2c0.2,1.6-1.1,1.6-2,2c-1,0.4-2.1,0.8-1.6,2.3
				c0.3,1.1,0.4,2.4,2,2.6c0.7,0.1,1.6-0.3,2.1,0.9c-1.2,1.2-5,0.5-3.6,3.9c0.7,1.6,2.2,2,3.8,1.1c0.2,0.1,0.5,0.1,0.7,0.2
				c-0.2,2.6-4.6,5-0.2,8c-2.1,0.6-4.2,0.7-4.2,3.5c-0.1,3.1,3.3,1.2,4.2,2.9c-0.2,0.3-0.4,0.6-0.7,0.7c-3.7,1.5-4.2,2.7-2.1,6.2
				c0.7,1.2-0.1,1.8-0.3,2.6c-2,0-4,0.2-6,0c-2.9-0.3-4.2,0.7-4,3.6c0.1,0.7,0.1,1.5,0.1,2.2c0.1,0.7,0.1,1.4,0.2,2.1
				c0,4.1,0,8.3-0.2,12.4c-0.1,2.1,0.6,3,2.7,2.9c0.6,1.4,1.8,0.9,2.8,0.9c2.2,0,4.3,0.1,6.3,0.8c-0.3,1-0.7,2.1,0.6,2.7
				c0.1,0.2,0.1,0.3,0,0.5c-1.6-0.2-2.3,0.5-2.2,2.1c-0.6,2.7-1.2,5.3,3,5.4c0.3,0,0.7,0.3,0.8,0.5c1.5,2.7,3.9,2.8,6.5,2.5
				c1.2-0.2,2.3-0.4,3.5-0.7c2.3,0.2,4.6,0.4,6.9,0.6c0.7-0.2,1.6,0.2,2.2-0.6c1.8,1,3.8,0.5,5.7,0.6c2.8,0.1,3.5-1.1,2-3.6
				c-0.8-1.7-2.3-2.2-4-2.2l0,0c-0.1-0.9-0.7-0.9-1.4-0.7l0,0.1c0.5-0.9-1.2-1-0.7-2c0.3,0,0.5-0.1,0.8-0.1
				C408.9,1841.9,409.4,1841.9,409.8,1841.9z M113.9,2061.1c1.8-2.8-2.5-2.3-2.3-4.2c-0.5,0.2-1.2,0.3-1.3,0.7
				c-0.7,1.6,0.7,2.2,1.7,3c0.2,0.2,0.4,0.5,0.7,0.7c-0.2,1.2-0.4,2.3-0.6,3.5c-0.1,0.7,0.1,1.3,0.8,1.6c1.1,0.4,1.2-0.6,1.5-1.2
				C115.1,2063.7,114.3,2062.5,113.9,2061.1z M50.8,2062.4c0.7-0.2,1.3-0.5,2-0.7c0.9-0.2,1.8-0.4,2.7-0.7c0.8,2.6-1.4,3.2-3,3.8
				c-1.3,0.5-1.9,0.6-1.3,2.1c0.7,1.5,1.4,1,2.2,0.3c2.7-2.5,5.5-1.9,8.4-0.5c0.1,2.1,0.2,4.1,0.3,6.2c0.2,3.1-0.7,3.9-4.5,3.8
				c-1.1,0-2.1-0.5-3.2-0.5c-5.4-0.3-5.4-0.2-6,4.9c-0.1,1.2-0.1,2.4-0.1,3.6c0,3.1,0.7,3.8,3.7,2.8c3.5-1.1,6.5-1.7,8.4,2.8
				c-0.3-3.3,1.7-5.5,2-8.2c0.1,3.5,0.7,7,0,10.4c-0.3,0-0.6-0.1-1-0.1c-0.4-0.6-0.9-1.8-1.6-1.2c-3.2,2.6-5.6-0.2-8.2-1.1
				c-1.6-0.5-2.4-0.3-2.8,1.5c-0.6,2.7,0.5,5.4,0,8.1c-0.2,1.1,1,1.1,1.8,1.4c3.3,1.3,6.3,0.2,9.4-0.7c0.1,2.6-0.3,5.1-0.8,7.7
				c-1-0.9-2-1.6-3.5-0.7c-0.8,0.5-1.8,0.7-2.8,0.6c-4.3-0.4-5.6,1.3-4.2,5.6c-0.1,2.2-0.2,4.3-0.2,6.5c0,0.7-0.2,1.4,1.1,1.4
				c3.1,0.2,4.8,2.4,6.3,4.8c-1.6,1.2-3.5,1.6-5.3,2.1c-1.1,0.3-1.7,0.9-1.8,2c-0.2,1.2,0.2,2.1,1.2,2.8c1.1,0.8,2.3,1,4.1,0.6
				c-1.5,2-2.8,3.4-4.5,4.4c-1.8,1.1-0.9,2.9-0.8,4.5c0.1,1,1,1.3,1.9,1.4c2.1,0.2,1.7-1.6,2.2-2.6c1.3-2.4,1.9-5.3,3.9-7.3
				c2.8,1.6,1.7,4.4,2,6.7c-0.7,0.9-3,0.3-2.6,1.9c0.3,1.5,2.1,0.7,3.1,0.6c1.8-0.2,2.6,0.4,2.6,2.3c0,1.3,0.9,2.4,2.3,2.2
				c1.3-0.2,0.8-1.6,0.8-2.5c0.1-3.1,0.1-6.1,0-9.2c-0.1-1.7,1.4-4,0.9-4.5c-2.2-2.3-0.8-4.8-0.8-7.1c-0.1-33.2,0-66.5,0-99.7
				c0-18.5-0.1-37.1-0.1-55.6c0-1.4,0.1-3.4-1.9-3.1c-4,0.7-4.8-1.6-5.4-4.7c-0.2-1.3-0.8-2.5-1.3-3.7c-0.8-1.9-0.7-4.1-2-5.8
				c-0.9-1.2-2.9-2.2-0.8-3.9c0,0-0.1-0.2-0.1-0.3c-1.6-3.2-2-6.6-1.7-10.1c0.1-0.8,0-1.6-1-1.8c-1-0.2-1.7,0.2-2.2,1
				c-0.8,1.3-1.1,2.7-0.7,4.1c0.9,2.6,0.7,5.2,0.6,7.8c-0.7,11.5,0.1,23.1,0.2,34.6c0,3.3,3.1,5,5.9,3.4c2.8-1.7,4.7-7,2.9-9.7
				c-1-1.3-0.2-1.6,0.3-2c2.3-2.3,2,1.8,3.7,1.4c-1.1,0.8-2.3,1.3-2.3,2.7c0.1,2,0,4.1,1.7,5.7c1,0.9,1.6,2.2,1.2,3.5
				c-0.5,1.4-1.9,0.7-2.9,0.9c-2.9,0.4-5.9,0.6-8.8,0c-1.8-0.4-1.4,0.7-1.2,1.7c-0.5,0.7-1.2,1.5-0.7,2.3c1.9,2.9,0.2,5.8,0.2,8.7
				c0,5-1.9,10.3,2.9,14.6c1.7,1.5,0.1,1.8-1.3,1.4c-1.2-0.3-1.6,0.5-1.7,1.4c-0.1,1.1,0.8,1,1.6,1.2c1.2,0.2,2.5-1.2,3.6,0.3
				c-1.2,1.1-1.3,3.3-3.5,3.5c-0.9,0.1-1.8,0.2-1.6,1.5c0.2,1.2,1.5,1.3,2,0.9c1.6-1.4,4.4,2.2,5.4-1.2c1.3,2.3,3.5,1.1,5.3,1.2
				c0.3,1.3-1.7,1.2-1,2.6c1.9,3.9,1.3,4.6-3.2,4.8c-2.1,0.1-4.2,0.3-5.4,2.6c2.1,0.8,3.9-1.6,5.7,0.1c0.5,2.9-2.8,2.1-3.7,3.7
				c-1.3,0.5-2.5,1.3-3.8,1.4c-1.8,0.2-1.9,0.8-1.4,2.2c0.2,0.7,0,1.5-0.1,2.2c0,0.7,0.1,1.4,0.1,2.1c0.8,2.3,1.7,4.5,1.9,7
				C50.8,2057.8,50.8,2060.1,50.8,2062.4c-0.4-0.1-0.6,0.1-0.3,0.4C50.6,2062.7,50.7,2062.6,50.8,2062.4z M220.5,2060.5
				c0,0.7-1.9,0.9-0.7,1.6c1,0.6,2.9,0.8,3.6-0.3c1-1.6-1.1-1-1.6-1.5c-0.4-0.9-0.8-1.8-1.3-2.6c-0.7-1.2-1.8-1.4-3.1-1.3
				c-0.6,0-1.1,0.3-1.1,0.9c-0.1,1.2,1.2,1.4,1.7,2.2C218.5,2060.6,219.6,2060.3,220.5,2060.5z M568.2,1483.2
				c0.3,0.6,0.7,0.8,1.3,0.6c0.1,0,0.1-0.2,0.2-0.3C569.2,1483.4,568.7,1483.3,568.2,1483.2c-0.1-1.3-0.8-2.6-0.3-4.1
				c0.7-1.8-0.3-1.8-1.7-1.3c-1.3,0.5-2.4,1.8-2.1,2.5C564.9,1481.8,566.2,1483.1,568.2,1483.2z M417.7,1372.6l0.2,0.2L417.7,1372.6
				c-1-1.5,2.1-1.9,1-2.9c-2.1-1.9-2.2-4.6-3.5-6.9c0,1.1-0.4,2.2-0.1,2.9C416.3,1367.9,414.1,1371.3,417.7,1372.6z M310,1781.5
				c2.8-0.3,5.5-0.5,8.3-0.8c0.3,0,0.8-0.4,0.9-0.7c0.1-0.5-0.5-0.8-0.9-0.7c-2.8,1.2-5.9-0.3-8.6,1c-1.2-1.2,0.3-3.5-2-4.2
				c-0.3-1.6-1.8-3-1.3-4.8c0.9-0.7,2.2,1,2.7-0.1c0.8-2.4,3.2-4.3,2.1-7.3c-0.4-1.1-0.1-2.6,0-3.9c0.1-3.5-1-4.7-4.5-5.2
				c-0.9-0.1-1.7,0-2.6-0.1c-2.7,0-5.5,0-8.2,0c0,0.4-0.1,0.6-0.1,0.6c1.3,1,2.5,2.7,1.3,3.8c-1.5,1.3-1.9-1.2-2.9-1.9
				c-0.9-0.6-1.6-1.9-2,0.3c-1.8,3-0.5,6.1-0.2,9.1c0.2,2,2.3,0.6,3.1,1.5c0.2,0.2,0.2,0.6,0.2,0.9l0,0c-0.9,0.1-1.9-0.4-2.5,0.8
				c-0.6,1.2,0.5,1.6,1.1,2.3c0.3,0.4,0.6,0.8,0.9,1.2c-0.6,1.4-2.6,2.3-1.5,4.3c-1.9,2.1-2.1,5.2-1.4,7.2c0.6,1.6,3.7,0.4,5.7,0.1
				c0.9-0.2,1.7-0.3,2.6-0.4c3-1.7,0.5-4.4,1.2-6.5c0.3-1-0.9-2.7,1.1-2.7c1.7,0,1.9,1.2,2,2.7c0.1,1.8-0.1,3.9,2.2,4.8
				C307.9,1782.7,309.7,1783.7,310,1781.5z M896.3,2108.3L896.3,2108.3c0.1,1.6,0.5,2.7,2.2,1c1.9-1.8,1.7-5.5-0.6-6.7
				c-0.6-0.4-2-0.7-2,0C895.9,2104.4,893.4,2106.6,896.3,2108.3z M961.9,1785c0.1,0.1,0.3,0.2,0.4,0.2c-0.1-0.1-0.2-0.3-0.3-0.4
				c0-1.5,2.3-1.3,2.2-2.6c-3.3-1.8-6.2-2-8.6,1.2c-0.6,0.7-1.3,1.2-0.8,2.2c0.5,1,1.4,0.7,2.3,0.5
				C958.8,1785.6,960.2,1784.8,961.9,1785z M518.9,2113.6c0.6-1.4,1.9-1.2,3-1.2c3.7,0,7.3-0.1,11-0.2c1,0,2.3,0.4,2.4-1.3
				c1,0,2.1,0,3.1-0.1c1.4,0.8,2.8,1.8,3.5-0.7c0.2-0.8,0.7-1.5,0.4-2.5c-0.9-3.8-1.7-7.6-2.5-11.4c-0.4-1.8-1.6-2-3-1.5
				c-1.5,0.5-3,0.5-4.5,0.3c-4.2-0.4-8.4-1-12.1,1.9c-0.2,0.1-0.8-0.2-1.2-0.3c-1.4-2.4-2.9-4.9-4.4-7.3c-0.4-0.7-0.8-1.7-1.9-0.9
				c-0.5,0.4-0.6,1.8-1.5,0.8c-0.5-0.5-0.5-1.6-0.6-2.5c-0.1-1,0.7-1.2,1.5-1.3c2.7-0.4,5.4-0.4,8.2-0.3c3.9,0.2,7.7,3.3,11.8,0.5
				c0.3-0.2,0.9,0,1.3,0c1,0,2.4,0.8,2.4-1.1c0-1.5,0.3-3.4-1.4-4c-1.4-0.5-3.1-1.6-4.7-0.6c-2.6,1.6-5.6,0.6-8.3,1
				c-0.8,0.1-2-0.4-1.4-1.1c2.3-2.4,0.9-5.6,2.5-8.1c2.7-0.3,5.4-0.7,8.1-0.9c1.1-0.1,2.5-0.5,1.9-1.4c-1.3-1.9-0.2-3.4-0.2-5.2
				c0-4.1-1.7-7.9-2.6-12.1c-0.9,0.9-1,2.4-2.3,2.5c-0.5-1.8,2-3.4,0.8-5.1c-1.3-2-2-5.3-5.8-4.1c-0.2,0.1-0.6-0.3-0.9-0.4
				c-2.1-1.2-3.8-1.8-3.9,1.8c0,0.8-0.7,1.6-0.8,2.7c-0.2,3-2.9,1.5-4.4,2.1c-1.8,0.7-1.5-1-1.6-1.9c0-2.5-1.1-3.2-3.6-3.1
				c-2.1,0.1-4.4,0.9-6.3-0.5c-0.5-0.3-1.4-0.5-1.2-1.3c0.2-0.8,1-0.7,1.6-0.7c4.8-0.1,9.7-2.2,14.7,1.2c0-2-0.9-2.9-1.4-3.8
				c-0.4-0.6-0.5-1.2,0.2-1.7c0.5-0.3,1.1-0.2,1.5,0.3c0.5,0.6,0.9,1.2,1.4,1.8c1.2,1.6,2.3,1.6,3.1-0.4c1.2-3,2.1-6-0.2-8.9
				c-0.5-0.6-0.2-1.1-0.1-1.7c0.4-4.2-0.4-8.6,1.3-12.7c0.2-0.6,0-1.6-0.3-2.2c-1.5-2.8-1.8-5.9-2.4-9c-1.1-6.6-2.3-13.1-4-19.5
				c-0.9-3.6-0.5-7.6-3-10.8c0.1-2-0.6-3.1-2.8-2.8c-4,0.5-8,0.1-12-0.3c0-0.9-0.6-1.5-1-2.3c-0.8-1.4-0.7-2.7,1.4-2.8
				c0.1,0.6,0.1,1.7,0.8,1.5c2.7-0.8,5.5,0.6,8.2-0.3c1.2-0.4,2.3-0.8,2.2-2.4c0.6-0.4,1.7-0.4,1.5-1.4c-0.4-2.3-0.5-5-1.7-6.8
				c-0.9-1.5-0.8-2.4-0.4-3.7c0.2-4-1.5-7.6-2.2-11.4c-0.1-0.6-0.5-0.8-1.1-1c-4.3-1.6-8.7-2.3-13.1-0.4c-0.4,0.2-1,0.4-1.2-0.1
				c-0.8-2.9-2.8-1.8-4.7-1.7c-1.3,0.1-2.4-1-3.3-1.9c-1.2-1.3-0.8-1.8,0.8-2.1c1.2-0.2,3,1,3.7-1c1.8,0.3,1.9-0.6,1.5-2
				c1.7,0,1.6,1.5,1.9,2.5c0.5,1.3,1.5,1.5,2.6,1.4c1.2-0.1,1.2-1,1.2-1.9c0-1,0.1-1.9,0.8-2.7c2.1,0,3.7-0.4,2.7-3.1
				c-0.5-1.4-0.2-2.2,1.6-2.3c0.9-0.1,2.8-0.3,2-2.1c-1.3-2.9-0.7-6.4-3-9.1c-0.2,3.4-2.6,2.9-4.8,2.8c-9.3-0.2-18.6-0.3-27.9-0.5
				c-1.5,0-3.7,0.8-3.7-1.9c0-2.6-1.1-5.6,2.2-7.2c3.1-1.5,6.4-2,9.8-1.3c0.4,0.3,1.1,0.2,1.2,0.9c0.3,0.7,0.9,0.8,1.6,0.9
				c4.9,1.1,9.9,0.5,14.9,0.4c1.8,0,3.7,1.3,5.5-0.1c-0.4-0.7-1.4-0.4-1.5-1.2c0.5-1,2.5-0.3,2.4-2c-0.1-0.9-1-0.9-1.7-1.2
				c-0.1-7.5-2.2-14.7-4.1-21.9c0-9.8-2.9-19-4.9-28.5c-0.2-0.8-0.5-1.5-1.5-1.4c-1.1,0.1-1.2,1.1-1.5,1.9
				c-3.5,8.1-7,16.2-10.8,24.1c-1.2,2.5-1.1,4.3,1.2,5.8l0.1-0.1c-3.5,0-5.8,1.4-5.5,5.3c0.1,0,0.2,0.1,0.2,0.1c0,0.2,0,0.3-0.1,0.5
				c-2.6,1.9-3.5,4.5-2.2,6.3c-0.4,0.7-0.8,1.5-1.2,2.2c-3.4,6.4-5.6,13.4-9.3,19.6c0,1.2-2,2.7,0.4,3.5c1.6,0.6,2.3-0.6,2.6-2
				c1.1-0.1,0.7,1.6,1.7,1.6c2.8,0,3,2.1,3.7,3.9c-1,0.8-2.3-1-3,0.5c-0.1,0.2,0,0.7,0.2,0.9c1,0.7,2.1,0.4,3.1,0c1-0.4,2-1.2,3-0.3
				c1.6,1.5,3.3,1,4.9,0.2c1,2.5-0.5,4.9,0.7,7.1c0.2,2.7,0.3,5.5,0.5,8.2c-0.5,1.2-0.9,2.4-0.4,3.7c0.5,1.3-0.5,3.6,2.4,3.1l0,0
				c-0.1,0.3-0.1,0.7-0.3,0.9c-2.5,2.1-1.5,5-1.6,7.6c-0.1,2.9,0.3,5.6,3.9,6.5c0.6,0.1,1,0.5,0.8,1.2c-0.3,0.8-1,0.8-1.6,0.5
				c-2.6-1.1-2.8,0.4-2.5,2.3c0.9,5.1-1,10.4,1.8,15.6c0.8,1.5,0.7,4.3,1.8,6.2c1,1.7,1.2,3.4,1.3,5.3c0.1,2.2-1.6,2.9-3.7,4
				c2.2,0,3.6-0.2,5,0c2.8,0.4,3.4-1,3.2-3.4c-0.1-1.6,0-3.3,0-5c1.2,0.2,1.2,0.8,1.3,1.3c0.6,2.2,0,4.5,0,6.6
				c0.1,5.1,1.8,10.1,1,15.3c-0.2,1.1,0.9,3-0.6,3.3c-1.6,0.4-0.7-1.9-1.6-2.6c-0.2-0.2-0.2-0.6-0.4-0.8c-0.6-0.7-1.3-0.9-1.9-0.1
				c-0.5,0.6-0.8,1.1,0.2,1.8c1.4,1.1,1.3,1.5-0.3,2.2c-3.5,1.4-2.5-1.6-3.1-3.9c-0.9,3.3-1.8,5.6-1.9,8.2
				c-0.3,7.5,1.6,14.9,1.2,22.4c0,0.8,0.1,1.8,1,1.7c1.7-0.1,3.7,0.5,5-0.7c1.1-1.1,1.6-2.8,0.7-4.5c-0.4-0.7-0.3-1.7-0.4-2.6
				c-0.2-2.1,0.2-3.3,2.8-3.1c3.7,0.3,5.3-1.6,4.6-5.2c-0.6-2.9-0.8-5.8-0.8-8.7c0-0.8,0.2-1.8-0.3-2.2c-3.3-3.6-1.5-7.8-1.7-11.7
				c-0.1-3.6-0.3-7.2,0.8-10.7c1-3.3,1-3.3,3.5-0.7c0.2-1.3,1.3-2.1,1.8-3.2c0.4-0.9,0.8-2.6-1-2.2c-2.1,0.4-1.3-0.5-1.5-1.6
				c-0.4-2.8,2.1-2,3.6-2.9c-0.1,7,4.9,13.5,1,20.8c3-0.2,3.6,1.6,3.6,4.1c0,1.9,0.6,3.8,1,5.8c0.2,1.2,0.7,2.5-0.7,3.7
				c-0.7-2.2-1-4.2-1-6.3c0-1.2,0.6-3.3-0.9-3.4c-1.7-0.2-2.1,1.9-2.4,3.4c-0.2,0.8,0,1.7,0,2.6c0,4.6,0,9.2,0,14.5
				c-0.8-1.2-1.3-3.1-2.6-1.7c-1.2,1.3-1.5,3.8-0.3,4.4c2.7,1.4,1,2.6,0.3,3.8c-1,1.6-1.4,2.6,0.6,4c1.9,1.3,3.1,3.3,1.1,5.6
				c-5.2-0.3-10.3,1.1-15.5,1.3c-1.5,0.1-1.8,1-1.9,2.1c-0.7,2.1-0.6,4.1,0.8,5.8c1.6,2,2,4.5,3.4,6.6c0.6,0.9,0.8,2.1,0.2,3.1
				c-0.8,1.3-1.9,0.2-2.9,0.2c-1.5,0-4.1-1.6-3.5,2.1c0,0.3-0.5,0.8-0.8,0.9c-3.9,1-7.9,0.8-11.5-0.6c-2.2-0.9-0.8-3.6-0.9-5.6
				c0-0.4-0.4-0.7-0.6-1.1c1.2-2.6-0.8-5.3,0.1-7.9c0-1.3,0-2.6,0-3.9c-0.5-1.5-0.9-3-1.4-4.5c1.2-2,0-4.4,0.7-6.4
				c1.2-3.2-2-6.1-0.3-9.2c0,0-0.3-0.4-0.4-0.4c-3-0.1-1.5-2.6-1.3-3.3c1.4-5,0.8-10.2,1.4-15.2c0.8-6.5,0.5-12.8-1.2-19
				c-0.3-0.9,0-1.8,0.4-2.8c1.7-3.9,1.3-8,0-11.9c1.6-3.2,1.2-3.7-2.5-3.7c-3,0-6,0-9,0c-0.4-1.5,2.7-0.3,1.6-2.3
				c2.7,0.5,5.1-1.3,7.8-1c1.9,0.2,2.2-0.7,2.4-2.5c0.7-6.6-0.6-13.2-0.2-19.8c0-0.7,0.3-1.5-0.8-2.2c-1.7,3.6-2.6,1-3.6-1
				c-1.1,2,0.2,5-2.4,5.1c-1.3,0-1-2.4-0.9-3.9c-1.9,1.2-3.7,2.7-6.1,2.7c-1.1-1,0.3-1.9,0-2.9c-0.2-0.8,0.4-2.2-1.1-2.1
				c-1.2,0.1-1.5,0.9-1.1,2.1c0.3,0.8,0.3,1.7-0.1,2.5c-1.4,0.1-1.6,1.2-2,2.2c-6.6,14.7-13.3,29.4-19.9,44
				c-2.5,0.4-2.3,2.8-2.4,4.3c-0.1,1.8,2.2,0.4,3.1,1.3c0.7,3.9-0.9,7.7-0.6,11.6c0.4,5.4,0.6,10.9,0.5,16.3
				c-0.1,4,0.1,8.1,0.1,12.1c0,5.3,0.4,10.7-0.2,16c-0.3,2.5,2.5,5.9-1.2,7.5c-2.7,1.2-5.8,0.4-8.8,0.5c-0.1,0-0.2,0-0.3,0
				c-1.6,0-2.3-0.8-2.4-2.5c-0.4-8-0.9-16.1-1.2-24.1c-0.2-3.8,0-7.7,0-11.8c-1.7,1-2.2,1.8-2.2,3.3c0.4,9.7,0.5,19.3,1,29
				c0.1,3-1,6.9,4,8c-4.4,0.8-8.7,0.9-13,0.5c-0.8-0.1-1-1-0.8-1.9c-0.2-1.1-0.5-2.3-0.7-3.4c-1.2-2.1,0.1-5.1-2.4-6.8
				c-0.3-1.9-1.2-4-0.8-5.7c1.8-7.5,2.2-15.2,5.9-22.5c8.1-16,15-32.6,22.4-49c3.3-7.4,5.7-15.3,9.3-22.6c4.7-9.7,9.7-19.3,14.2-29
				c2.3-5,4.3-10.2,6.4-15.4c0.5-1.2,1.5-3.6-1.4-3c-3,0.6-5.9,0.1-8.8,0.3c-3.3,0.8-6.5-0.4-9.7-0.4c-14.9-0.3-29.7,0.2-44.6,0.4
				c-4.7,0-9.3,1.5-14,1.5c-0.7,0-1.8,0.1-2,1c-0.3,1.6-0.3,1.9-1.7,1.2c-0.8-0.4-1.3-0.7-2,0c-0.4,0.4-0.5,1-0.3,1.5
				c0.4,1.1,1.4,1,2.2,0.8c4.6-1,9.4-0.5,14-1.2c9.8-1.4,19.7-1.5,29.6-2.3c0.2,1.2-0.1,2.3-0.1,3.5c-0.1,2.9-2,5.4-1.7,8.4
				c0.2,1.9,1.4,3.5,1.4,5.5c0,3.7-1.7,7.2-1.3,11c0.1,1.2-0.7,2.7,1.3,3.1c0.4,0,0.6,0.2,0.6,0.6c-0.4,0.9-0.8,1.7-0.7,2.7
				c0.1,0.8-0.1,2,1.1,1.7c0.9-0.2,2.6,0.1,2.4-1.7c-0.1-0.8-0.2-1.4,0.1-2.2c5-1.3,5.2-1.1,4.6,4c-0.2,1.8,0.9,1.9,2.2,1.9
				c-0.2,1-0.1,2.2-1.5,2.6c-3.5,0.5-5,4.2-8.2,5.2c-0.5,0.2-0.6,0.9-0.4,1.4c0.2,0.5,0.7,1.2,1.2,0.9c2.9-1.8,1.5,1.5,2.5,2
				c0.1,0.7,0.1,1.4-0.7,1.7c-1.2,0.2-1.6-1.1-2.4-1.4c-1.3-0.4-1.4-3.1-2.6-2.1c-1.3,1.1-1,3.5-0.3,5.1c0.7,1.7,2.7,0.7,4.1,1.1
				c0,0.6-0.1,1.2-0.6,1.6c-3.7,0.5-4.3,1.7-2.7,6c-1.5,3.7-2.8,7.5-4.6,11.1c-0.8,1.6-1.2,3.8-3.3,4.6c1.7-5.6,3.7-11.2,3.1-17.5
				c-2.5,1.5-4.2,2.7-4.6,5.5c-0.2,1.6,0.1,3.3-0.2,4.6c-0.9,3.4,1.1,7.2-2,10.5c-1.6,1.7-1.7,4.7-1.6,7.3c0,0.6-1.7,1.1-0.3,1.7
				c1,0.5,1.9-0.2,2.2-1.3c0.4-1.2,0.7-2.5,0.7-3.8c0.1-2.6,1-5,2.7-7.1c0.1,3.6-0.6,7.2-1.6,10.6c-2.9,9.6-1.7,19.9-7.2,29.2
				c-3.4,5.7-4.6,12.9-4.5,20c0,0.5-0.4,1-0.7,1.4c-0.9,1.1-0.9,2.3-1.1,3.6c-0.8,5.4-0.2,10.9-2.4,16.1c-0.4,0.9-0.6,2.1,0.4,2.6
				c1.2,0.6,2.2,0.1,3.3-0.8c1.4-1.2,0.3-2.9,1.2-4.2c1.5,0.4,4.3-0.9,4.3,1.6c0,3.4,2.2,2.9,4.1,3.2c0.9,0.2,2-0.3,2.9,0.3
				c0.7,2.9,3.6,0.3,5.1,2.3c-5,1-8.7,5-14.2,4.2c-1.4-0.2-2.9-0.1-4.2,0.6c-0.6,0.3-1.1,0.8-1,1.6c0.1,0.8,0.7,1.2,1.4,1.1
				c3.9-0.5,7.9,0.2,11.6-1.5c4.2-2,8.2-4.5,13.1-3.1c0.6,0.2,1.8,0.1,1.9-0.2c1.3-3.3,6.1-2.2,7.2-5.7c0.4,4.1-0.1,8.2-0.1,12.3
				c0,1.4-1.2,1.6-2.4,1.8c-3.6,0.4-7.1,0.7-10.7,1.2c-5.9,0.7-12,0.7-17.7,2.6c-1.5,0.5-3.4-1.6-4.6,0c-0.9,1.3-0.9,3.3-1.3,5
				c-0.2,0.8-0.4,1.7-0.6,2.6c-0.2,1.3-0.4,2.6-0.7,3.8c-1,3.2,0.2,7-2.9,9.5c-4.5-0.6-3.2,3-3.7,5.3c-0.3,1.6-0.3,3.4,1.3,4.4
				c2.1,1.3,5,0.3,7.1,2c1.4-2.3,3.7-1.2,5.6-1.2c5.1,0,10.3-0.4,15.3,0.5c4,0.8,7.9-0.4,11.9,0.5c5,1.1,10.1,1.5,15.2,2
				c1.4,0.2,3,0.8,4.5-1.1c-6.1-0.9-11.9-1.9-18.5-2.9c4.7-1.2,8.6,0.6,12.8-0.1c-0.8-1.8-1.9-2.5-3.4-3.1
				c-4.6-1.9-9.2-3.9-13.7-5.9c0-0.6,0.2-1.2,0.8-1.2c3.5,0,7.1-0.5,10.5,0.4c-0.9,2.1,0.7,1.7,1.8,1.7c9.7-0.1,19.3-0.2,29-0.4
				c1.9,0,3.6,0.8,5.4,0.7c4.7-0.2,9.5-0.3,14.2-0.4c0,0.4,0.1,0.8,0.1,1.2c-9.7,1.2-19.5-0.5-29.8,0.3c2.6,4.4,7.3,4.9,11.1,7.3
				c-4.4,1.9-4.5,5.4-4.3,8.9c0.1,1.9-0.8,2.9-2.5,3.2c-3.1,0.5-6.3,1.4-9.4,1.2c-5.3-0.3-8,2.5-9.9,7.1c2.5-1.4,5.9,0.1,7.8-3.1
				c0.4-0.8,1-0.6,1.5,0.1c1.1,1.7,2.5,0.8,3.3,0c1.4-1.5-0.6-1.8-1.4-2.5c3.1-0.1,4.2,1.5,3.1,4.1c-0.2,0.4-0.5,0.9-0.2,1.1
				c0.4,0.3,1,0.4,1.5,0.3c1.3-0.3,2.1-1.3,2.5-2.4c0.7-1.8,1.6-2.1,3.5-1.4c1.9,0.6,4.8,3.5,5.7-1.3c0.1-0.4,0.7-0.3,1.1-0.2
				c4.5,0.5,8.6-1.1,12.5-2.9c1.8-0.8,3.4-0.6,5.1-0.6c1.7,0,1.9,1.4,0.8,2.1c-1.5,1-2.7,2.7-4.7,2.8c-2.2,0.1-3.1,1.3-3.6,3.2
				c-0.4,1.6-0.9,2.9-3,2.8c-0.8-0.1-1.7,0.3-1.8,1.1c-0.1,1.2,1.2,1.2,1.9,1.4c2.8,0.6,5.7,1,8.5,1.5c-4.9,0.7-9.8,0.6-14.7,1.2
				c-5,0.6-9.9,0.8-15,0.2c-5.3-0.5-10.9-1-15.6,2.9c-0.6,0.5-1.5,0.3-2.1-0.2c-0.6-0.5-0.3-1.1-0.2-1.8c0.8-3.4,3.4-4.9,7.7-3.9
				c3.2,0.7,6.1,0,9-1c0.6-0.2,1.2-0.6,1-1.4c-0.2-0.7-0.8-0.6-1.4-0.6c-4,0-8.2-0.3-12.1,0.3c-3,0.4-5.9,0.5-8.7-0.3
				c-2.7-0.8-5.4-0.3-8.2-0.6c-0.5-0.1-1.2-0.2-1.4,0.5c-0.2,0.8,0.4,1.2,1.1,1.4c1.9,0.5,3.8,0.9,6.6,0.7c-3.3,1.2-5.7,2.1-8.2,3.1
				c-3.3,1.3-6.7,2.3-10.2,1.5c-8.7-2.1-17.7-0.7-26.3-2.5c-2.2-0.5-2.8,0.7-2.7,2.8c0.2,3.3,0.5,6.5,1,9.7c0.3,2.2,1.1,2.9,3,3.2
				c7.1,0.9,14.2,1,21.2,0.2c2.4-0.2,4.1,1.1,4.7,2.5c0.5,1.1-1.9,1.9-3.2,2.5c-2.1,0.9-4.2,1.5-6.6,1.4c-1.5,0-3,0.5-4.4,1.2
				c-4.2,2-8.8,2.1-13.2,3.3c-0.1,0-0.4-0.4-0.5-0.6c-1.5-2-3.8-3.6-3.5-6.6c0.1-1.1-1-1.9-1.8-2.6c-0.7-0.7-1.6-0.8-1.7,0.2
				c-0.3,1.9-1.6,1.8-2.9,1.8c-1,0-2,0.1-2.4,1.3c-0.6,2.4-2.5,4.6-0.3,7.3c0.6,0.8,0.6,2.3,0.4,3.7c-0.6,3.6,1.7,5.8,5.3,5.8
				c2.4,0,4.8-0.2,7.2,0c2.2,0.1,4.2,0.7,3.4,3.8c-0.6,2.5,0.6,3.7,3.1,3.6c1.4,0,2.8,0,4.2,0c-1.9,0.8-3.5,2-5.6,1.7
				c-1.9-0.3-2.2,1.3-2.2,2.8c0,1.7,1,1.9,2.4,1.5c2.7-0.7,5.5-1.3,8.3-2c9.4-2.2,18.7-5.2,28.6-4.1c0.7,0.1,1.5-0.5,2.2-0.6
				c0.8-0.1,1.9-0.4,2.4,0.5c1.2,2,3.6,2.4,5.3,3.7c4.2,3.3,11.4,1.4,14.4-3.1c1.2-1.7,2.5-4.1,1.4-6.1c-0.9-1.6-1.2-2.5,0-4
				c1.3-1.6,1.2-3.5,0-5.2c-1.1-1.6-2.7-1-4.2-0.6c-0.9,0.3-2.1,0.7-2.7-0.4c-1.4-2.5-1.2-5.5-2.6-8c-0.2-0.4,0.2-1.4,0.6-1.8
				c1-1.1,0.8-2.4,1.3-3.5c0.9-2,1.5-4.7,3.7-5.6c2.8-1,5.9-1.8,8.4-3.1c4.1-2.1,7.9-1.7,12-1.1c1.5,0.2,3.1,0.5,4.5-0.1
				c3.2-1.4,6.6-1.5,9.9-1.6c6.8-0.2,12.9,2.8,19.1,5c9.6,3.4,19,7,28.1,11.5c1.7,0.8,3.5,1,5.4,1c6.1,0.1,12.2-0.3,18.3-0.5
				c2.7-0.1,5.9-0.4,7.4-1.9c2.7-2.6,5.4-2.5,8.5-2.3c0.7,0.1,1.5-0.3,2.2-0.4c2.6-0.4,6.4,2,6.6,4.6c0.2,1.6,1.4,1.3,1.6,0.8
				c1.1-1.9,2.8-0.9,4.1-1.4c2.1-0.7,5.8-0.2,5.7-4.3c0-1.7,1.5-2.2,2.8-1.6c1.6,0.7,3-0.1,4.5-0.2c2.5-0.3,1.6-3.7,4-4.2
				c0.8,2.6,0.8,2.5,3.8,2.3c1.4-0.1,2.7-0.1,3.9,1c1.4,1.4,3.4,2.2,4.7-0.3c1.6-3,3.5-6,4.2-9.2c0.6-3.2-0.2-6.6-0.6-9.9
				c-0.1-0.8-0.5-2.2-1.9-1.3c-1.6,1-3.1,0.4-4.7,0.4c-3,0.1-6.3-0.4-8.8,1.6c-3.2,2.6-6.3,3.5-10,1.1c-1.6-1-2.9,0.1-4.2,0.8
				c-1.1,0.6-2,1.7-3.2,2.3c-2.3,1.1-2,2.5-0.8,4.2c0.5,0.7,1,1.5,1.5,2.1c0.7,0.8,1.2,1.7,0.2,2.6c-0.7,0.7-1.7,1.5-2.6,0.8
				c-1.2-0.9-2.2-0.4-3.4-0.3c-5.5,0.3-10-1.8-13.6-5.9c-0.7-0.8-1.3-1.6-0.5-2.6c0.9-1.1,1.5-2.4,0.2-3.5c-1.2-1-2.5-1.8-4.4-1.6
				c-2.9,0.3-5.1,2.4-8.1,2.4c-0.2-1.2-0.2-2.8-1.5-2.8c-0.9,0-1.9,1.4-2.9,2.3c1,0.4,1.8,0.8,2.5,1.1c-0.4,1.4-1.6,1.3-2.6,1.6
				c-6.8,1.8-13.6,1.6-20.4,0.3c-1.6-0.3-2.9-0.5-4.3,0.6c-1.3,1-2.5,1.2-2.1-1.2c0.4-2.4-1-3.2-3.1-3.2c-6.2-0.1-12.4-0.9-18.3,2.2
				c-2.2,1.1-5,1-7.3-0.8c2.7-0.4,5.5-0.5,8-1.1c4.5-1.2,9-2.4,13.7-2c1.8,0.1,3.5-0.5,5.1-1.2c1-0.4,2.3-1,2-2.2
				c-0.3-1.2-1.7-0.8-2.6-0.7c-3.6,0.5-7.4-0.4-10-2c-2.8-1.7-4.8-3.8-8.5-3.9c-2-0.1-4-0.9-5.9,0.2c-1.1,0.6-2.1,0.4-3.1-0.3
				c-0.6-0.5-0.7-1.1-0.2-1.7c0.7-0.9,1.6-1.7,2.8-1.2c1.6,0.6,2.3,0.1,2.2-1.6c-0.2-2.5,1.2-2.7,3.1-2.1c0.8,0.2,1.6,0.8,2.4-0.1
				c-2.4-2.3-5.5-2.8-8-1.4c-1.2,0.7-0.7,2.8-2,2.7c-3.4-0.1-5.6,2.6-8.8,3c-2.5,0.3-5,0.7-7.2,2c-0.7,0.4-1.9,1-2.4,0.1
				c-1.5-2.8-2.8-1.1-4.4-0.1c-1.5,1-3.6,2.2-5.1,0.8c-1.5-1.5-2.1-4-0.8-6c1.2-1.9,2.9-3.4,5.7-2.2c2,0.9,4.8-0.1,5.9-1.4
				c1.9-2.2,4.1-3.2,6.5-2.4c4.6,1.5,9,0.6,13.6,0.5c2,0,4.3,0.1,5.7,1.1c2.9,2.1,5.6,0.8,8.4,0.4c0.6-0.1,1.5,0,1.5-0.9
				c0-1-0.8-1.2-1.6-1.2c-1.6,0-3.2,0-4.9-0.2c-5.5-0.8-10.3-4.2-15.9-4.6c-0.2,0-0.3-0.5-0.4-0.8c0.9,0.1,1.9,0.2,2.8,0.2
				c1.5,0,2.5-0.5,1.7-2.3c4.8-0.3,9.6-0.9,14.3-0.8c2.2,0,4.3-1.7,6.5-0.1c0.1,0.6,0.2,1.1,0.3,1.7c-0.8,1.4,0,1.5,1.2,1.6
				c4.1,0.5,8.3,0.5,12.3,2.3c0.9,0.4,2.1,0.2,3.2,0.3c0.3,0.5,0.6,1,0.8,1.5c-1.4,0.8-2.8,0.1-4.2,0c-1-0.1-2.5-0.7-2.7,0.9
				c-0.2,1.2,1.1,1.4,2.1,1.6c2.9,0.7,6,0.7,8.5,2.1c3.3,1.9,6.7,2.8,10.2,3.9c7,2.1,7.7,1.4,6-5.6c-0.3-0.8-0.4-1.8-1.6-1.7
				c-3.3,0.4-3.8-1.5-3.6-4.2c0.1-0.8,0.1-1.6-1.4-1.6c-5.2,0.1-10.5-0.2-15.6,0.6C522.7,2115.6,520.2,2116.5,518.9,2113.6z
				 M276.2,1787.7c1.5-0.2,2.9-0.4,4.7-0.6c-1.3-2.2-3.2-3.4-4.8-4.7c-1.8-1.5-3.3-4.6-5.5-3.8c-1.6,0.6,0,3.9-0.4,6
				c-0.2,2.7-1.2,4.8-4.4,5.4c2,0.5,3.7-0.3,5.4-0.6C273.1,1789.2,275.1,1789.7,276.2,1787.7z M453.7,1933.9c1-1.6,2.2-3.2,3.6-4.5
				c0.4-0.3,0.4-1.2,0.3-1.8c-0.1-0.5-0.7-0.5-1.1-0.2c-1.2,0.8-3.1,1.5-3.5,2.6c-2,5.5-7.7,10.3-3.8,17.2c0.9-1,0.1-2,0-3
				c-0.1-1.5-1-3.2,1-4.2c1.6,0.7,3,1.6,4,3.2c0.3-2.6-1.2-4.3-3-5.8c0.2-0.9,0.8-1.3,1.6-1.3c2.7,1.4,2.1,4.8,3.9,7.2
				C457.1,1939.3,457.8,1935.8,453.7,1933.9z M887.9,2022.1c0,1.1,0.1,2.2,0.2,3.3c0.1,1-0.2,2.8,1.2,2.4c0.9-0.3,3-0.1,3-2.1
				c0-2.3,1.4-2.7,3.5-2.7c2.7-0.1,1.9,1.7,2,3.1c0.1,0.9,0.3,1.9,1.5,1.5c0.7-0.2,1.2-0.7,0.9-1.8c-0.4-1.1-0.4-2.3-0.6-3.5
				c0.3-2.7-0.6-4.1-3.5-3.1c-1.7,0.6-3.4,0.6-5-0.6c1.6-0.5,2.1-2.1,3-3.3c0.5-0.7,2.3-1.5,1.3-2.5c-1.4-1.4-1.6,1.2-2.7,1.3
				c-0.2,0-0.4-0.1-1-0.1c0.9-1.6-1.1-3.6,1.2-5c0.2,0.1,0.6,0.5,0.7,0.4c0.5-0.3,0.2-0.6,0-0.9c-1.4-3.5-2.8-7.1-4.4-10.5
				c-0.7-1.5-0.6-4.1-3.8-1.6c-1.8,1.5-1.4,1.8-0.1,2.5c1.6,0.8,1.7,2,1.8,3.5c0.2,5.7,0.4,11.3,0.7,17
				C887.1,2020.2,886.6,2021.2,887.9,2022.1z M279.8,1790.8c-3.1-1-6,0.1-8.8,0.9c-1.6,0.5-1.5,7.5,0,9.5c-0.2,1.2-0.4,2.4-0.6,3.6
				c0.1,1.1,0.1,2.1,0.2,3.1c1.3,0.4,2.2-1.6,3.6-0.4c-3.7,2.9-3.6,2.9-3.8,8c0,1.6,0.7,2.9,0.7,4.4c-0.7,0.3-0.7,1-0.7,1.6
				c-1,7.5-0.9,15-0.1,22.5c0.1,1.4,0.3,2.3,1.9,2.4c0,0.4,0.1,0.9,0.1,1.3c-3.7,0.6-7.4,0.2-11.1,0.2c-11-0.1-22.1,0.1-33.1-0.4
				c-2.3-0.1-5.1-0.8-7.2,0.4c-1.7,1-3.2,0.9-4.9,1.2c-4,0.6-7.9-1-11.9-0.4c-7.4,1.2-15-1-22.4,0.4c-1.6-0.9-2.8,1.6-4.3,0.5
				c-2.2-1.6-4.5-0.8-6.9-0.5c-1.9,0.3-2.7,1.2-2.6,3.3c0.2,2.8-0.1,5.6-0.2,8.4c-4,0-8.1-0.2-12.1,0c-3.2,0.1-5-0.5-4.4-4.2
				c0.3-1.8,0-3.7,0.1-5.5c0.1-2-0.7-2.7-2.7-2.7c-5.6,0-11.1,0-16.7-0.1c-1.5,0-1.9,0.8-1.8,2l0,0c-1.6-1.4-3.2-2-4.9-0.4
				c-2.2-1.3-4.3-3.1-6.9-0.9c-1.1-0.7-2.2-1.3-3.6-1.3c-5.4,0-10.7,0-16.1-0.1c-2.6-0.1-3.4,0.9-3.4,3.4c-0.1,8.3,1.3,16.6,0.7,25
				c-0.1,1.9,0.5,3.1,2.7,1.7c1.4-0.9,2.8-0.3,3.8,0.8c1.1,1.2,0.1,2.2-0.8,3c-1,0-1.7-0.4-2.1-1.3c-0.4-0.8-0.9-0.9-1.5-0.4
				c-0.5,0.4-1.1,0.9-1.1,1.5c-0.1,0.8,0.8,1,1.4,1c1.2-0.1,2.5,1,3.7-0.2c1.1-0.1,2.2-0.3,3.3-0.3c4.3-0.1,9,1.2,12.2-3.3
				c0.9-1.3,3-0.4,3,0.9c0.1,2.8,1.8,2.2,3.4,2.6c2.6,0.6,4.6-0.2,6.7-1.6c1.3-0.9,3.9-3.3,3.6,1.3c0,0.4,0.4,0.8,0.9,0.8
				c3.8-0.6,8.1,1.5,11.6-1.8c1.2-1.2,2.7-1.3,4.1-0.1c3.3,2.8,8.1,2.8,11.5-0.1c1.5-1.2,3.4-2.2,4.4-0.1c1.1,2.4,2.8,2,4.5,2.1
				c1.8,0.1,3.7,0,5.5,0.1c0.9,1.7,2.1,1.1,3.5,0.7c-1-2.4-2.3-0.5-3.4-0.7c0.1-2.1,1.9-2.3,3.2-3c1-0.5,2.2-0.3,2.3,0.9
				c0.2,2.3,1.6,2.1,3.2,2.1c1.8,0,3.7-0.1,5.5-0.1c1.6,2.3,4.1,1.7,6.4,1.7c0.8,0,0.5-1,0.8-1.6c0.6-0.1,1.3-0.4,1.9-0.2
				c3.8,1.1,7,0.5,9.8-2.5c1.3-1.4,3.1-1,3.3,1.5c0,0.5,0.2,1.1,0.5,1.4c1.1,1,7.6,0.2,8.4-1c0.6-1,1.2-1.4,2.4-1.3
				c1.5,0.1,1.2,1.3,1.3,2.2c0.1,0.9,0.5,1.6,1.3,1.6c2.4,0.1,5,0.8,7.1-0.3c2.5-1.3,4.8-1,7.3-0.5c5.4,1.1,10.8-0.3,16.2,0.6
				c3.3,0.5,6.5-1.1,9.2-3.4c1.6-1.4,3.5-1.5,3.7,1.4c0.1,1.2,0.9,1,1.7,1c1.3,0,2.6,0.1,3.9,0c2.1-0.1,4.1,0.4,5.4-2.5
				c0.7-1.8,3.4-2.2,5.2-1c2.2,1.4,2,0,2.1-1.4c0.6-5.9,1.6-11.9,1.6-17.8c0-4.2,1.8-9.5-4.5-11.5c0,0,0.1-0.4,0.1-0.6
				c2.1,0.1,3.9-0.1,3.9-2.9c0-5.1,0-10.3,0.1-15.4c0-1.3-0.4-2.1-1.9-1.9c-0.1-0.2-0.1-0.4-0.2-0.6c1.3-2.2,1.5-4.6,1.4-7.1
				c-0.1-6-0.1-12-0.1-18c0-1.6-0.3-3-1.3-4.2c0-1.1-0.1-2.2-0.1-3.3c0.9,0,1.4-0.5,1.5-1.4c0.1-2.4,0.5-4.8,0.3-7
				c-0.6-6.4,0.2-12.7-0.5-19.1c-0.5-4.5,0.3-9.2-1.1-13.6c-0.7-0.8-0.1-1.5,0.1-2.3c0.9-1,0.6-2.3,0.6-3.4
				c-0.2-5.2-0.5-10.3-0.5-15.5c0-12.9-0.2-25.8-0.7-38.7c-0.3-6.6,0.4-13.3-0.6-19.8c-0.4-2.3,1.3-5.4-1.3-6.6
				c-2.1-1-4.9-0.3-7.4-0.4c-2.4,0-4.7,0.1-7.1,0.1c-1.5-2.9-4-0.1-5.8-1c-2.2,0.7-4.4,0.5-6.5,0.2c-2.4-0.4-3.4,0.9-3,2.5
				c1.1,4.2-1.8,8.1-0.3,12.3c-1.8-0.3-2.5-0.7-3-2.6c-0.9-3.8-0.1-7.6-0.9-11.3c-0.7-3.3-3.2-1.7-5-2.1c-1.9-0.4-2,1-2,2.4
				c0.1,3.2,0.3,6.5,0.4,9.7c0.1,8.2,0.4,16.4,0.6,24.6c0,1.8,0.4,2.5,2,2.4c1.7,0,3.5-0.2,3.7-2.3c0.2-3.1,0.7-6.2,1.3-9.3
				c0.3-1.3-0.8-3.7,1.4-3.6c1.9,0.1,0.5,2.4,1.1,3.6c0.5,1.1,1.4,3.3,1.4,3.3c-2.7,1.3-0.4,3.3-0.7,4.9c-0.3,1.7,0.9,2.3,2.8,1.9
				c3-0.6,5.9-2,9.1-1.7c5.4,0.1,10.5-1.8,15.7-2.8c1.4-0.3,2.1-0.2,2,1.4c0,3.9,0.1,7.8,0,11.8c0,1.7-0.5,3.2-3,2.2
				c-1.2-0.5-1.8,0.4-1.6,1.6c-2.1-0.9-4.1-0.5-6,0.3c-3.6-1.2-5.7-0.5-6.9,2.2c-3.8-0.2-7.5,0.7-11.2,1.4c-0.9,0.2-1.7,0.9-1.2,2.4
				c1,3.1-0.3,6.3-0.1,9.4c0.1,1.9,0,4.1-0.4,5.8c-0.5,2.1-1.4,3.9-0.6,6.2c0.6,1.6-2.5,4.5,1.7,4.9c-0.1,3.2,2.2,2.2,3.9,2.3
				c8.2,0.6,16.3,1.1,24.5,1.7c0.9,0.1,1.8,0.1,1.8,1.4c-3.2,0.6-6.5,0.1-9.7,0.3c-0.7,0-1.4,0-2.2,0c-1.9,0-3.8,0-5.7,0
				c-0.4-0.4-1.3-0.7-1.3-0.3c-0.3,2-1.4,4.2,1,5.7c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.4-0.2,0.5c-1.2,1.3-1.7,2.6,0,3.8
				c0.1,0.1,0.2,0.2,0.2,0.3c0,0.2-0.1,0.4-0.2,0.6c-0.8,0.6-0.8,1.2,0,1.8c0.1,0.1,0.2,0.2,0.2,0.2c0,0.2-0.1,0.3-0.2,0.5
				c-1,0.9-0.9,1.8,0,2.7l0-0.1c0,0.4,0,0.8,0,1.2c1.3,1.8,2.4,1.1,3.5-0.2c0.4-0.1,0.9-0.2,1.3-0.3c0.2,0.5,0.3,0.9,0.5,1.4
				c-0.5,0.3-1.4,0-1.5,0.9c-0.1,1,0.9,1,1.5,1.4c-0.6,1.6,0.1,2.9,1.4,3.6c1.9,1.1,3.2,2.8,4.8,4.2c2.2,2,4.4,3.8,7.6-0.2
				c-0.5,4,0,7-0.9,9.9C284.9,1790.6,282.3,1790.7,279.8,1790.8z M922.2,2092.8c2.5-1.1,2.1-4.3,4.1-5.9c0.6-0.5,0.2-2-0.8-2.1
				c-2.7-0.4-5.5-1.1-8-0.4c-2.2,0.6,0.4,3.3-0.6,4.9c-0.1,0.1,0.1,0.4,0.1,0.6c0.1,1.9,1.1,2.9,3.1,2.7c-0.6,1.1-1.2,2.2-1.7,3.3
				c-0.1,0.3,0,0.9,0.3,1.2c0.3,0.4,1,0.2,1.1-0.2C920.1,2095.4,922.6,2094.9,922.2,2092.8z M203.5,2054.8c-0.1,1.2-1.7,1.2-1.7,2.2
				c-0.2,3.3-2.7,2.5-4.5,2.4c-1.1-0.1-2.2-0.8-2.5,0.9c-0.3,1.3,0.3,2.2,1.7,2.7c2.5,1,4.7,1,7-0.9c1.7-1.4,3.7-3.7,6.5-1.9
				c0.7,0.5,1.2,1.2,1.8,0.2c0.5-0.8,0.3-1.7-0.7-2.2c-2.2-1-2.7-2.9-2.9-5c0.9-1.7,2.3-0.4,3.4-0.6c0.9-0.2,2.4,0.8,2.2-1.1
				c-0.1-1.3-0.9-2.2-2.4-2c-1.2,0.2-2.4,0.5-3.7,0.3c-2.6-0.3-5.2,0.1-7.8,0.3c-0.7,0.1-1.6,0.3-1.6,1.2c0,0.3,0.5,1,0.7,1
				C200.9,2052.2,201.6,2054.6,203.5,2054.8z M387.6,1762.3c0.2,0.7,0.4,1.4,0.6,2.1c-1.2,0.1-2.4,0.1-3.6,0.2
				c-1.1,0.1-2.5,0.1-2,1.7c0.4,1.5,1.9,1.2,2.8,0.7c3.6-2,7.3-0.3,10.9-0.2c2.4,0.1,4.9,0.6,7.3,0.2c3.7-0.5,7.5-1.1,11.1-2.1
				c4.3-1.2,9-0.3,13-3.3c-6.9-0.3-13.9-0.6-20.8-0.9c-1-0.2-2-0.8-3.1-0.6c-1,0-2,0-2.9,0c-2.5-0.2-4.9-0.5-7.4-0.7
				c-1-0.7-2-0.4-3.1-0.3c-3.9,0.4-6.6,3.6-10.6,3.9c-2.3,0.2-4.9,0.3-7,1.8c0.7,1,1.4,0.7,2.2,0.6
				C379.2,1765,383.1,1762.6,387.6,1762.3z M311.8,1924c0.8,3.9-0.7,7.7-1,11.6c-0.3,4.1-2.1,8.1-1.3,12.6c2.3-3.1,2.5-6.5,3.2-9.7
				c1.5-7.8,2.7-15.6,2.2-23.6c-0.1-1.4,0.2-3-2-2.9c-0.9,0-1.7,0.2-2.6,0.1c-2.5-0.1-5-0.4-7.5-0.5c-0.8,0-1.9,0.2-2,1.3
				c-0.1,0.9,0.5,1.4,1.3,1.6c2.5,0.5,3.1,2.1,3.3,4.4c0.3,2.9,1.8,3.2,4,1.3c0.5-0.4,0.2-1.4,1.4-1.3
				C311.5,1920.5,310.7,1922.5,311.8,1924z M305.2,1683.5c0.9-1.6,4.5-1.5,2.8-4.7c-1-1.8,0.7-4.4-1.5-5.9c-0.1-0.9,0.4-2-0.7-2.6
				c-0.1-0.7-0.2-1.6-0.8-1.6c-3.5,0-6.9-1.5-10.4-0.3c-0.9,0.8-3.3-0.8-3.1,1.5c0.6,6.3-0.1,12.5-0.4,18.8c-0.3,6.6-0.2,6.6,6.3,6
				c2.5,1.4,4.2-0.8,6.2-1.5c4.9-1.6,4.7-1.6,4.9-6.7C308.7,1683.8,307.6,1683.2,305.2,1683.5z M400.5,1921c0.7-4.3,0.7-4.6-3.6-4.2
				c-6.5,0.7-13,0.3-19.5,0.3c-2.8,0-3.5,0.9-3.1,3.4c-0.7,1.4-0.3,1.9,1.3,2c7.6,0.1,15.3-0.3,22.9,0.6
				C399.9,1923.1,400.7,1922.6,400.5,1921z M450.6,1736.8c-0.6,2.4-0.3,4.9,0.2,7.3c-1.3,0.8-0.9,2.1-0.8,3.3
				c0.3,11.3-0.2,22.6,0.5,34c0.7,12.7-0.1,25.5,0.6,38.2c0.6,11.8-0.1,23.7,0.8,35.6c0.4,6.4-0.7,12.7,0.2,19.1
				c0.3,2.2,1.3,4.3,2,6.5c0.3,1-0.3,2.6,1.1,2.8c1.6,0.3,1.9-1.4,2.5-2.5c4.7-10.3,9.5-20.6,14.2-30.9c0.9-1.9,1.9-3.8,3.4-5.2
				c1.1,1.1-0.4,3.2,1.6,3.4c1.5,0.1,2.4-1.4,3.2-2.5c0.7-0.9,0.6-2.4,1.7-3.1c2.2-1.3,2.3-3.3,1.6-5.4c-1.9-5.9-2.2-12.1-3.5-18.1
				c-5.3-23.5-10.2-47.1-15.3-70.6c-0.2-1.1-0.7-1.8-1.5-2.4c0.8-2.3-1.9-3.8-1.4-6.1c0.8-3.8-0.7-7.4-1.5-11
				c-0.2-0.7-0.7-1.1-1.5-1.1c-2.5,0-5.2,0-7.3,1.3c-1.7,1.1-0.6,3.4-0.7,5.2C450.6,1735.3,450.6,1736,450.6,1736.8z M295.1,1835.5
				c-1.9,0.5-1.4,1.7-1,3c-1.2,0.2-1.2,0.8-1.1,1.9c0.4,3,1.4,6.4,1,8.9c-1.3,6.6-0.8,13.1,0.3,19.4c0.6,4.1-0.7,10.2,6,11.5
				c0.2,0,0.4,0.2,0.5,0.4c2.3,2.9,5,2.7,8.2,1.5c2.6-0.9,5.5-3.2,8.2,0.4c0.8,1.1,3,1,4.1-0.3c1-1.1,1.9-1.1,3.2-0.8
				c4.7,1.3,9.4,2.7,14.4,1.1c0.5-0.2,1.2,0.3,1.9,0.4c4,1,8,2.6,11.4-1.4c0.6-0.7,1.7-1.1,2.7-0.7c1.2,0.5,0.7,1.4,0.5,2.2
				c-0.2,0.7,0.1,1.5,0.9,1.4c3.7-0.4,7.9,1.6,11-2.2c1.3-1.5,3.1-1.9,4.1,0.6c0.5,1.3,1.6,1.6,2.9,1.7c2.6,0.3,4.8-0.5,6.5-2.3
				c1.8-1.8,3.1-1.9,4.8,0.1c1.8,2,3.8,2.6,5.7-0.3c1.6-2.4,5-1.9,6.3,0.7c0.5,0.9,0.8,1.9,2,1.7c2.6-0.4,5.6,1.5,7.7-1.4
				c0.5-0.7,2-0.8,2.4-0.3c2.3,3,5.4,1.5,8.2,1.7c1.3,0.1,2.3-0.2,2.4-1.9c0.1-1.3,1.1-2,2.5-2c1.3,0,2.4,0.3,2.6,1.9
				c0.2,1.3,1.1,1.4,2.1,1.5c1.1,0,2.4-0.2,3.2,0.3c1.9,1.3,2.7,0.2,3.1-1.3c0.6-2.2,2.4-2.3,3.6-1.7c3.9,2.1,3.6-1.1,3.8-2.9
				c0.4-5.1,0.4-10.1,0.5-15.2c0.3-8.7-0.2-9.1-8.6-6.5c-1,0.3-2.3,0.1-2.9,1.2c-0.6,1.1,1.7,5.5,3.2,6c-1.3,0.4-1.9,1-2.3,2.4
				c-0.3,1.3-0.2,2.6-0.5,3.8c-0.4,1.6-1.4,2-2.9,1.9c-2-0.2-1-1.4-0.9-2.4c0.1-3.2,0.9-6.2,1.5-9.3c0.2-1,1.4-2.4-0.8-2.7
				c-1.7-0.2-3,0.1-3.2,2.3c-0.3,3.1-0.9,6.2-1.3,9.3c-0.3,1.9-1.1,2.8-3.3,2.3c-2-0.4-4-1.1-6-1.5c-2.7-0.5-3.4-1.6-2.3-4.3
				c2.4-5.9,1.8-6.6-4.5-6.7c-0.5,0-1.1-0.1-1.6-0.1c-0.8,0-1.5,0-2.3,0c-1.7,0.2-3.4,0.6-5.1,0.7c-4.3,0.1-8.4,1-12.4,2.4
				c-1,0.4-2,0.8-1.6,2c0.6,1.7-0.5,2.2-1.7,2.4c-2.9,0.7-5.8,1.5-8.7,0.9c-3.8-0.8-7.6-0.6-11.4-0.7c-1,0-2.1,0.2-2.9-0.2
				c-2.6-1.3-5.2-1-8-0.5c-2.8,0.5-5.4-0.5-8-1.5c11.5-1.4,23-2.8,34.6-4.2c-5.2-1.1-10.3,0-15.3,0.5c-7.7,0.9-15.3,1.9-23,2.7
				c-3.4,0.3-6.9,1.7-10.3-0.1c0.6-2.8-0.4-5.6,0-8.4c0.3-2.2-0.5-3-2.9-3c-2.6-0.1-2.8,1.1-2.7,3.1c0.1,2.3,0,4.6-0.1,6.9
				c-2.6-0.2-5.1-0.3-7.7-0.5c-2.9-0.2-4.2-1.3-3.8-4.4c0.2-1.8,0.9-4.2-1.8-5.2c-0.7-0.4-1.4-0.7-2-1.1c-3.1-0.5-6.3-0.4-9.5-0.3
				c-1.1,0-2.2,0.2-2.2-1.4c-0.1-2.1,1.4-1.1,2.3-1.2c2-0.2,4.1,0.3,6.1-0.6c1.8,0.8,3.5,1.1,5.3,0c1.2-0.1,2.5-0.1,3.7-0.2
				c-1.2-0.4-1.9-0.9-1.9-2.3c0-4.1-0.3-8.3-0.5-12.4c-0.3-7,0.2-14-0.1-21.1c-0.3-7.3-0.7-14.5-0.7-21.8c0-2.6-0.9-3.3-3.2-1.8
				c-2.7-1.2-5.2,0.5-7.8,0.8c-1.3-1.6-3.1-0.7-4.5-0.6c-1.1,0.1-2.8-0.1-2.9,1.9c-0.1,1.6,0.6,2.4,2.2,2.6c1.4,0.2,1.5,1.2,0.5,1.6
				c-2.4,1.1-0.9,2.5-0.6,3.8c-1.8,1.6-0.7,3.6-0.7,5.4c-1.4,0.3-1.6,1.3-1.4,2.5c0.7,4,0.7,8,0.2,12c-0.2,1.7,0.7,2.1,1.9,2.5
				c-1.1,3.3,0.1,6.8-0.8,10.1c0,1.3,0.1,2.4,1.8,2.5c0.1,0.8,0.1,1.5,0.2,2.3C295.3,1835.1,295.2,1835.3,295.1,1835.5z
				 M596.1,2123.1c1.2-2.3,2.8-1.5,4.6-0.8c2.6,1.1,4.9,3.2,8.1,2.5c0.6-0.1,1,0.4,1,1c0.1,1,0.3,2.1,1.6,1.8c0.9-0.2,1.9-1,1.8-1.9
				c-0.1-0.8-0.7-1.8-1.7-2.2c-2.5-1-2.6-1.8-0.9-4.2c1.6-0.1,1.6-1.2,1.5-2.3c-0.5-15.2-1-30.4-1.5-45.6c-0.1-1.5-0.5-3-0.8-4.7
				c-2.3,3.1-1.3,6.2-1.4,9.2c0,1.4,0.3,2.9-2,3c-2.8,0.1-5.6,0.6-8.5,0.7c-1.9,0.1-2.8,0.6-2.7,2.6c0.2,5.9,0.4,11.7,0.2,17.6
				c-0.1,5.3,0.5,10.6-0.4,15.9c-1.4,1.5,1.4,3.6-0.8,5c-0.8,5.8-4.8,9.2-9.9,12.7c2.8-0.2,4.3-1.5,6-2.1
				C594.4,2129.9,596,2127,596.1,2123.1z M226.7,1663.2c-2.8,0.6-5.5-0.9-8.3,0c-1.1,0.4-2.8-2.2-3.4,0.7c-2.8-2-6.1-1.7-9.3-2
				c-5.6-2.4-11.6-1-17.4-1.9c-2.4-0.4-4.9-1-7.4-0.1c-3.6-0.1-7.2-0.3-10.8-0.4c-0.6-0.9-1.6-1-2.6-1.1c-4.1-0.4-8.2-0.9-12.3-1.2
				c-11.4-0.6-22.8-1.7-34.1-2.4c-1.9-0.1-4.8-0.8-5.8,2.3c-2.3-2.8-5.7-1.9-8.6-2.5c-0.6-0.2-1.2-0.4-1.8-0.6
				c-4.9-1.8-6.1-1-6.1,4.1c0,6.1,0.3,12.2-0.1,18.2c-0.8,10.6,0,21.3-1,31.9c-0.3,2.9,2,5.4,1.1,8.3c-0.7,2.3,0.3,2.9,2.3,3.1
				c9.4,0.8,18.8,1.7,28.3,2.3c6.4,0.4,12.9,0.5,19.3,0.8c0.8,0,1.8-0.1,2.1,1c-0.9,2,0.1,3.1,1.9,3.4c2.4,0.5,4.9,0.7,7.4,1
				c1,0.1,2.1,0.2,2.5,1.3c1.3,3.4,2.2,0.1,2.7-0.4c0.7-0.6,0.8-1.9,2-1.8c1.4,0.1,1,1.7,1.7,2.5c0.1-5-0.5-5.3-4.9-3.8
				c-2.1,0.7-4.4,1.7-6.6,0.1c0.8-1.2,2.6-0.7,2.8-2.3c-0.8-0.2-1.5-0.4-2.2-0.6c1.2-1.8,3.2-1.3,4.8-1.8c1.6-0.4,3.5,0.1,4.4-1.9
				l0,0c1.9,0.1,3.8,0.4,5.7,0.9c1.7,0.5,4.6,1.2,5.5-2c0.3-0.1,0.5-0.2,0.8-0.2c0.8,1.7,2.3,1.4,3.6,1.2
				c10.9-2.1,21.7-4.3,32.6-6.5c1.3-0.3,2.2-0.8,2.1-2.4c-0.8-9.9-0.2-19.8-0.6-29.7c-0.1-2.7-1-5.4-0.1-8.1c3.1,1,6.2,2.9,6.4,6.1
				c0.3,3.8,0.6,7.7,0.7,11.5c0.1,2.9-2,5.5,0.1,8.7c1.1,1.7,0.4,4.7,0.2,7c-0.2,2.5,0.7,3.4,3.1,2.9c2.3,0.7,4.7,1.5,6.3-1.3
				c1.1-0.6,1.4-1.5,1.4-2.7c-0.4-12.8,0.5-25.5,0.8-38.3c0.1-2.2-0.6-3-2.7-2.8C231,1662.6,229,1661.4,226.7,1663.2z M901,1705.8
				c-1.1-2.2-3-1.2-4.4-0.7c-1.6,0.5,0,1.5-0.1,2.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.9-0.3-1.9,0.5-2.6-1.1c-0.9-2.1-2-1.1-2.7,0.4
				c-1.4,0-2.9-0.1-4.3-0.1c-0.9-0.1-2.1-1.3-2.7,0.5c-0.3,0.8-0.6,1-1.4,0.6c-1.8-0.9-3.6-0.4-5.3,0.3c-0.6,0.2-1.1,0.4-1.7,0.6
				c-2.3-0.1-5.1-1.6-6.1,2c-2.2-0.7-2.1,0.9-2,2.2c0.6,9.8,1.4,19.7,1.5,29.6c0,1.4-0.2,3.3,1.7,4.1c0.7,1.9,2,3.7,0.4,5.7
				c-0.1,0.1,0,0.4,0,0.6c0.4,11.7,0.9,23.4,1.3,35c0.6,14.3,1.1,28.7,1.7,43c0.4,9.2,1,18.5,1.1,27.7c0.1,6.3,1.3,12.6,0.6,18.9
				c-1.8-0.1-0.9,2.6-2.9,2.6c-1.5,0-2.7,0.9-3.7,2.3c-1.4,1.9-3.2,1.7-4.8-0.1c0.5-1.2,3-0.7,2.2-1.9c-1.9-2.7-1.3-5.9-2.7-8.6
				c-0.8-1.5-1.9-2.4-3.3-0.1c-1.1,1.9-1.9,5-3.2,5.6c-3.9,1.8-2.7,4.4-1.6,6.6c2,3.8,3.6,7.7,5.3,11.5c0.6,1.3,1.1,2.5,2.8,2.8
				c1,0.2,2,0.7,0.8,1.9c-0.7,0.7-0.7,1.3,0.1,1.9c0.9,0.6,1.4,0.1,1.9-0.6c0.5-0.7,0.9-1.5,1.9-1.6c3.9-0.4,7.1,2.8,6.3,6.5
				c-0.4,1.8,0,2.7,1.7,3.3c1.1,2.2,0.6,4.5,0.6,6.8c0.2,2.1,0.5,2.6,2.9,2.3c2.3-0.3,4.6-0.7,6.8-0.4c7.1,0.9,14.1,0.3,21.2,0.2
				c1.8,0,2.6,0.6,3,2.2c1,3.9-0.3,5.4-4.3,4.9c-0.5-0.1-1.1-0.1-1.6-0.2c-0.7,0.4-1.5,0.7-2.2,1.1c-4.4-2.8-9.3-0.3-13.9-1.1
				c-3-0.5-6.2-0.1-9.3-0.2c-2-0.1-2.6,0.7-2.7,2.7c-0.3,4.5,2.2,8.1,3.8,11.9c0.6,1.5,0.6,3.8,3.2,3.9c0.8,0.1,0.6,1.2,0.5,1.9
				c-0.7,4.5,2.1,7.6,3.9,11.5c3.1-4.2-0.4-8.1,0.6-12.4c1.8,2.6,3.3,5,5.5,6.9c0.3,0.3,0.3,1,0.3,1.6c0.2,2,0.8,4.2,0.6,6.1
				c-0.2,2.3,1.8,4.9-0.6,7c-2,1.7-1.4,3.7-0.4,5.6c2.1,4.3,4.3,8.5,5.8,13.1c1.1,3.4,2.7,6.6,4.7,9.7c1.1-1.2,0.9-3.3,2.2-3.7
				c1.8-0.5,2.6-0.9,2-2.9c-0.2-0.6,0.4-1.2,1.1-1.3c0.2,0,0.7,0.3,0.7,0.4c-0.7,2.6,1.8,4.2,2.4,6.8c-0.9-0.7-0.5-2-1.7-1.7
				c-0.8,0.2-0.5,1.1-0.4,1.7c0.1,1.7,0.6,3.4,0.1,5.2c-1.2,5.1-1.1,10.2,1.9,14.8c0.9,1.3,0.4,5,3.9,2.4c0.6-0.4,1.3,0.6,1.6,1.3
				c0.4,1,0.1,2.7,2.1,2.1c1.5-0.5,1.8-1.7,1.6-3.2c-0.2-1.1,0.3-2.4-0.2-3.2c-1.1-1.8,1.6-6-3.4-5.4c-0.3,0-0.6-0.6-0.9-0.9
				c-2.6-2.1-1.9-5.3-2.1-7.9c-0.6-8.7-0.6-17.4-1.2-26.1c-0.2-2.9,0.1-4.6,3.5-4c0.8,0.1,2.1,0.3,2-0.9c0-1.1-1.2-1-2.1-1
				c-0.8,0-1.5,0.1-2.3,0.1c-1.4,0.1-2-0.6-1.9-2c0.5-7.7-1-15.4-0.5-23.1c2.2-0.2,4.3,0.2,6.5-0.5c1.9-0.6,3.5-3.2,3-3.6
				c-1.6-1.5-0.3-3-0.6-4.3c-1.6-7.4,1.8-14.9-1-22.2c-0.1-0.3-0.1-0.7,0-1c1.5-4-0.5-7.9,0.1-11.8c0.8-5.1-1.9-9.4-2.3-14.3
				c-0.1-1.5,0-3,0.1-4.4c0-4.7-1.4-9.4,0.1-14.1c0.6-1.8,0.9-4,0.5-5.8c-1.4-6.3-0.7-12.8-1.5-19.2c-0.2-1.5-1.3-2.7-1-4.2
				c0.7-3.2,0.7-3.5-0.9-5.8c-0.2-0.2-0.1-0.6-0.2-1c-0.2-1.6-0.5-3.2-0.5-4.9c-0.5-13.9-1-27.8-1.4-41.7
				c-0.2-5.8,0.6-11.7-0.3-17.3c-1.8-11.7-1.5-23.4-2.4-35.2c-0.2-2.6-0.7-3.8-3.4-3.5c0.6-1.6,3.5-1.2,3.1-4
				c-0.8-5.9-0.2-11.9-2.3-17.6c-0.4-1.1-0.2-2.9-2.1-2.7c-1.8,0.2-4.1-0.7-4.8,2.1C901.7,1705.8,901.4,1705.8,901,1705.8z
				 M475.7,1688.7c1.7,0.3,3.7,0.2,3.6,2.9c0,0.3,0.9,0.7,1.5,1c0.1,1.3-0.2,2.6,1.7,3c5.7,1.1,11.3,2.6,16.9,3.7
				c6.3,1.2,12.8,1,19.2,1.8c6.5,0.9,13,1.5,19.6,1.5c0.9,0,2.3,0.3,2.3-1.1c0-1.4-1.5-1-2.3-1c-4.7,0-9.3-0.4-14-0.6
				c-6.5-0.2-12.8-0.9-19.3-1.4c-2.8-0.2-5.6-1-8.3-1.6c-0.8-0.2-1.9-0.4-1.8-1.5c0.1-1,1.2-0.7,1.8-0.9c4.2-1.6,8.6-1.9,13-1.8
				c3,1.1,6,1.4,8.8-0.7c6.2-0.4,12.4-1,18.6-1.2c2.9-0.1,4-0.9,3.5-3.7c-0.2-1-0.4-1.9-0.6-2.9c1.2-3.8,1-4.1-2.9-4.1
				c-3.2,0-6.4-0.1-9.6-0.1c3.2-0.8,6.5-0.4,9.7-0.6c0.5,0,1.1,0,1.3-0.7c0.1-0.5-0.2-1-0.6-0.9c-2.2,0.5-3.6-1.6-5.9-1.4
				c-10.9,1.1-21.6,3.4-32.5,4.8c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.3c-1.1,0.6-2.3,0.7-3.4,0.8c-4,0.3-7.2,2.3-10.3,4.4
				c-1.6,1.1-0.4,1.2,0.6,1.3c0.1,0.6-0.1,1.4,0.7,1.6c1.4,0.4,1.6,1.3,1.1,2.4c-0.6,1.4-1.7,1.1-2.7,0.7c-1.6-0.7-3.3-0.8-5-0.4
				c-0.6-3.3,1.2-5,4.1-6.1c1.3-0.5,2.5-1,2.9-2.7c-1.1-0.2-2.1-0.3-3-0.5c-0.3-1.4-1.4-1.1-2.3-1c-10.3,1-20.5,2.3-30.6,4.5
				c-1.6,0.3-1.8,1.4-1.8,2.6c0,1.1-0.6,2.9,0.9,3.1c3.1,0.5,6.3-1.1,9.5,0.4c0,0,0.2-0.1,0.3-0.2c2.2-2.1,5.2-2,7.9-2.6
				c0.7-0.2,1.6-0.1,2.1-0.8C472.1,1687.1,473.8,1687.3,475.7,1688.7z M96.4,1826.6c-1.6,5.4-0.6,10.9-0.8,16.4
				c0,1.5,1.4,1.4,2.5,1.4c5,0.2,10-0.2,15,0.6c1.1-0.1,2.3-0.1,3.4-0.2c1-0.1,2.4,0.7,2.8-1.1c3-0.3,5.8,1,8.6,1.2
				c6.9,0.4,13.8,0.2,20.7,0.2c1.8,0,2.8-0.5,2.8-2.6c0-10.9,0-21.8,0.1-32.7c0-1.9-0.7-2.8-2.7-2.7c-13.5,0.3-26.9-0.9-40.3-1.3
				c-2-0.1-3.9,0-5.8,0.6c-5.1,0.5-5.1,0.5-5.6,5.4c-0.1,1.4-0.3,2.8-0.4,4.2c0.8,1,2.2,1.4,2.2,3l0,0c-1.7,0.1-1.1,1.4-1.3,2.3
				C95.7,1822.7,97.7,1825.1,96.4,1826.6z M210.9,1800.7c-4.5-0.2-8.9-0.1-12.9,2.5c-2.1-0.2-4.2,0.7-6.2,0c-1.4,0-2.8-0.1-4.2,0.1
				c-3.4,0.3-6.3,1.2-6.9,5.2c-2.7-2.8-5.9-2.7-9.3-1.9c-2.3,0.5-3.3,1.2-3.3,3.8c0.1,9.8-0.1,19.6-0.1,29.4c0,6.6,0.4,6.9,6.9,5.7
				c0.3-0.1,0.6-0.2,0.9-0.3c1.3,0,2.6-0.2,3.8-0.9c4.5-2.4,9-4.7,13.5-7.1c1.2-0.6,2.1-2,3.7-1.5c-4.1,4.3-10.5,5.2-15,9.7
				c2.9,0.7,4.2-1.4,5.9-2.2c1.4-0.7,2.4-0.9,2.9,0.8c0.1,0.9-0.1,1.9,1.3,1.8c4.8-0.2,9.5,0.5,14.3-0.1c0.7,0.9,1.4,0.6,2.1,0.1
				c1.4,0.6,2.8,0.5,4.2,0.5c2.4-0.1,5.1,1,7-0.4c1.8-1.3,0.1-4.1,1-6.1c2.8,1.4,5.5,3,7.7,5.2c1.4,1.4,2.9,2.2,4.1,1.2
				c1.3-1.2,1.8-3.3-0.1-4.9c0.5-0.3,1.7-0.2,1.5-0.9c-0.9-3.3,0.8-7-1.3-10.2c-0.1-2.4-0.2-4.7-0.4-7.1c2.5-0.2,1.2-2.2,1.5-3.4
				c0.4-1.5-1.2-0.9-2-1.3c1.3-1.6-0.8-2.6-0.8-4.2c0.1-4.5,0.5-8.9-0.1-13.4c-0.1-1,0.8-2.8-1.3-2.4c-1.4,0.3-3.5-0.1-3.2,2.8
				c0.4,5.3,1.4,10.6,0.2,15.9c-0.2,0.8,0.3,2.6-1,2.2c-1.4-0.4-3.5,0.5-4.2-1.7c-0.5-1.6-0.8-3.2-0.4-4.8c0.7-3.7,0.1-7.4,0.2-11.1
				c0-0.9,0-1.8-1.3-1.7c-2.5-1.2-4.8-0.9-7.1,0.7C212,1800.6,211.5,1800.6,210.9,1800.7z M579.4,1655.9c2.4,0.1,3.8-1.2,4.6-3.4
				c0.2-0.5,0.3-1.2,0.7-1.4c1.3-0.8,2.2-1.5,1.4-3.4c-0.7-1.7-1.9-2.1-3.5-2.3c-3.5-0.3-7.1-0.3-10.4-1.1c-3.3-0.8-6.7,2.5-9.8-0.6
				c-0.1-0.1-0.5,0.1-0.5,0.2c-0.7,2-1.4,1-2.2,0c-0.4-0.6-1.1-0.7-1.8-0.6c-5.3,0.4-10.6,0.8-15.8,1.4c-1.2,0.1-2.4,0.8-3.9,2.4
				c3.7,0,5.4,3.1,8.4,3.7c1,0.2,1.7,1.4,3.1,0.6c1.8-1.2-0.8-2,0-3.2c4.3-0.5,8.7-1.5,13-1.4c4.6,0.1,10.3-2.3,13.1,4.1
				c0,0.1,0.2,0.2,0.3,0.2C579,1651.5,578.8,1654,579.4,1655.9c0,0.1-0.1,0.1-0.2,0.1c0,0,0,0.1,0,0.2
				C579.2,1656.2,579.3,1656.1,579.4,1655.9z M580.7,1496.3c-3.3-0.2-3.7-0.9-2.6-4.3c-0.3,0-0.7,0-1-0.1c-1.9-1-3.4,0-4.5,1.3
				c-1,1.1-1.1,2.3-0.2,3.8c0.6,1.1,0.5,2.7,0.3,4.3c-0.6,3.6-0.3,7.3-0.2,11c0,0.7,0.8,1.5,1.4,2.1c0.4,0.5,1,0.9,1.7,0.6
				c1.1-0.4,0.3-1.1,0.2-1.6c-0.4-2.2,0.4-3.5,2.6-4.5c4.1-1.8,7.9-4.1,11.9-6.2c1-0.5,1.8-1.2,2.4-2.1c0.3-0.5,0.5-1.3-0.2-1.3
				c-3.3,0.2-5.7-3.5-9.3-1.9C582.2,1497.8,580.8,1498.3,580.7,1496.3c0-0.1,0.1-0.2,0.1-0.2
				C580.8,1496.1,580.7,1496.2,580.7,1496.3z M70.5,1929.4c0.7-1.4,2-1.2,3.2-1.2c12.5,0,24.9-1,37.4-0.7c8.1,0.2,16.2,0,24.3,0
				c1,0,2.1,0.2,2.1-1.3c0-1.2-0.7-1.8-1.9-1.8c-0.4,0-0.9,0-1.3,0c-22.4,0-44.9,0-67.3,0c-1.3,0-2.5,0.1-3.2,1.2
				c-0.9,1.4,1.1,1.3,1.2,2.2c0.1,0.5,0.1,1,0.2,1.6c-1.1,0.6-1.6-0.2-2-0.9c-0.5-0.8-1.2-1.1-1.9-0.7c-1.4,0.7-0.8,2.5-1.9,3.5
				c-0.5,0.4,0.2,1.2,0.8,1.5c0.7,0.4,9.5-1.2,9.9-1.8C70.4,1930.5,70.4,1929.9,70.5,1929.4z M263.2,1789.7c0.8-1.3,0.1-3.1,1.2-4.3
				c0.1-0.1-0.3-0.8-0.6-1c-0.6-0.3-1.7-0.3-1.8,0.2c-0.4,2.6-3.3,3.3-4.1,5.5c-5.2,2.1-10.9,1.1-16.2,2.5
				c-3.2,0.9-6.7,1.8-10.1,0.7c-0.3-0.5-0.7-0.9-0.7-1.4c-0.1-4.4-0.1-8.8-0.7-13.2c-0.1-0.9-0.1-3.2,1.1-3.8
				c3.9-1.9,2.5-4.6,1.6-7.4c-0.1-1.5-0.4-2.8-2.3-2.8c-1.6-4.3,1.2-8,2.3-11.8c0.1-0.5,3.7,0,5.7-0.1c0.1,0,0.2-0.3,0.9-1
				c-10-1.9-19.7-0.8-29.4-1c-5-1-10.1,0.4-15.2-0.5c-5.4-0.9-10.9-0.6-16.4-0.8c-3.1-1.4-6.4-0.2-9.6-0.6c-1.1-0.1-0.6,0.8-0.6,1.3
				c0,6.3-0.1,12.6,0,18.9c0.2,10.6-0.7,21.2-0.1,31.9c0.1,2,0.2,3.3,2.4,1.4c3.4,0.6,6.5-0.9,9.8-1.1c3.9,0.1,7.6-1.5,11.6-1.3
				c0.3,0,0.6-0.5,1-0.8c1,0.3,2.1,0.5,3.2,0.6c4.2,0.3,8.2-1.1,12.3-1.4c6.2-0.4,12.4-1.1,18.6-2c10.1-1.5,20.2-3.2,30.4-4.5
				C259.5,1791.7,262.2,1792.5,263.2,1789.7z M228.1,1725.5c-3.4,0.1-6.6,1.9-10.1,1.3c-1.4,0-2.9-0.1-4.2,0.1
				c-10.1,1.9-20.3,3.8-30.4,5.9c-1.6,0.3-3.7-0.1-4.6,1.8c-1.5,0.3-3,0.6-4.5,0.9c-5.6,1.3-5.7,1.3-5.9,7.2
				c-0.1,1.8-0.5,2.6-2.5,2.5c-4.2-0.2-8.5-0.4-12.7-0.4c-2.8,0-3.7-1.1-3.5-3.7c0.1-1.1,1.2-2.6-0.7-3.3c-1.6-0.2-3.2-0.6-4.8-0.7
				c-8.3-0.6-16.7-1.4-25-1.7c-4-0.1-8.6-2-11.7,2.5c-1.3,0.8-1.7-0.3-2.2-1.1c-1.6-2.5-4.2-1.9-6.2-1.4c-1.8,0.4-0.7,2.4-0.9,3.7
				c-0.1,0.7,0,1.5-0.1,2.3c-0.1,1.5,0.3,2.1,2,2.1c4.8-0.1,9.6,0.3,14.4,0.7c22.6,1.7,45.2,1.9,67.8,2.7c0.9,0,2.2,0.7,2.8-0.8
				c0.7-0.2,1.5-0.5,2.2-0.4c10,0.4,20,0.8,30,1.3c0.4,0.3,0.8,0.9,1.3,0.9c3.6,0.1,7.1,0.9,10.7,0.6c1.1-0.1,2.4,0.3,2.8-1.1
				c0.4-1.2,1-2.5-0.3-3.6c-1.4-1.1-0.9-2.5-0.4-3.8c1.8-4.4,0.9-8.9,0.8-13.4c0.1-1,1.4-2.4-0.3-2.9
				C230.4,1723.1,228.7,1723.5,228.1,1725.5z M390,1714.8c-0.9,0-1.5-0.4-1.9-1.2c0.5-1.1,1-2.2,1.6-3.6c-1.9,0.4-3.4,0.6-5.1,0.1
				c-1.4-0.4-3-1.1-4.5,0.9c1.5-0.1,2.5-0.2,3.5-0.3c0.9,0,1.4,0.5,1.7,1.3c0.4,1.1-0.5,1.2-1.2,1.5c-0.2-0.2-0.4-0.3-0.7-0.5
				c0,0-0.1,0.1-0.2,0.2c0.3,0.1,0.6,0.2,0.8,0.3c0.2,1.3-0.1,2.4,1.6,3.5c2.2,1.4-1.6,2.1-1.2,3.8c0,0.2-0.8,2.1-2.3,1.6
				c-0.6-0.2-0.6-0.8-0.4-1.4c0.7-1.9,0.7-3.8,0-5.7c-0.3-0.8,1.3-2-0.5-2.4c-1.5-0.3-2.5,0.3-3,1.7c-2.5,1.8-2.5,4.7-3.3,7.2
				c0.1,1.2-1.7,1.2-1.6,2.5c3.1,0.2,6.1,0,9.1,0.8c0.8,0.2,1.8,0.1,1.7,1.4c-2.9,0.9-6,1.1-9,1.2c-0.7,0-1.5-0.9-2.3-0.2
				c-0.6,0.6-0.5,1.4-0.2,2.1c0.5,1,1.4,0.9,2.3,0.7c1.8-0.4,3.2,0,4.1,1.7c0.3,1.9,0.6,3.8,3.2,3.7c0.7,0,1.4,0.2,1.3,1
				c0,0.7-0.7,0.7-1.1,1c-0.9,0.7-3,0-2.7,2.1c0.2,1.7,1.5,2.3,3.1,2.4c0.7,0,1.4,0.1,1.3,1c0,0.7-0.7,0.8-1.2,0.9
				c-3.3,0.4-3.7,2-1.5,4.4c-1.8,0.8-3.2-0.2-4.7-0.4c-4.4-0.7-4.4-0.8-3.5,3.1c0,0.2,0,0.4,0,0.6c-1.2,2.6-4.2,3.9-5.7,2.1
				c-1.4-1.7-3.1-1.6-4.4-0.9c-3.6,1.8-7.3,0.4-10.8,0.6c-2.4,0.2-4.8-0.7-4.5-4.1c0.1-0.5-0.4-1-1.4-0.8c0.4,2.5-0.1,4.6-3.5,4
				c0-5.5,0-11-0.2-16.6c-0.1-1.6,1.2-4.6-1.3-4.5c-1.9,0.1-0.9,3.1-0.8,4.8c0.2,5.2,1.2,10.5,0,15.7c-2.9,0.4-5.9,0.1-8.8,0
				c-2.6,0-3.7-1-3.8-3.6c-0.6-13.7-1.3-27.4-1.9-41.1c-0.1-1.2,0.5-3-1.7-2.6c-1.5,0.3-3.6-0.1-3.4,2.8c0.4,5.4,0.3,10.9,0.4,16.3
				c0.2,8.2,0.4,16.3,0.6,24.5c0,1.3,0.4,3.1-1.9,3c-3.2-0.2-6.5,0.5-9.7-0.5c-0.1-4.5-0.3-9-0.4-13.6c0-1.2-0.5-1.8-1.6-2.2
				c0.2-0.4,0.3-0.7,0.5-1.1c0.3-0.3,0.9-0.6,0.9-0.8c0-7,0.5-14-0.4-21.1c-0.3-2.1-1.4-1.5-2.4-0.7c-1.7,1.4-2.8,3.3-4.7,4.4
				c0-0.5-0.1-1-0.1-1.4c0.6-1.8,2.5-2.6,3.6-4c0.6-0.7,1.5-1.5,0-2.3c-1.2-0.6-5.8,1.3-6.4,2.6c-2.1-0.6-4.1,0.7-6.2,0.6
				c-1.7-0.2-2.2,0.5-2.2,2.2c-0.1,3.1,0.4,6.2,0.7,9.2c0.2,0.9,0.4,1.9,0.6,2.8c0.4,1.4-0.7,3.6,2.2,3.5c0.1,0.8-1.5,0.7-1,1.7
				c3-0.3,5.7,1,6.3,3.5c0.4,1.8,1.4,4.6-0.6,5.2c-1.9,0.6-2.7-1.9-2.9-3.9c-0.1-0.8-0.5-1.5-1.4-1.5c-0.7-0.1-1.6,0.1-1.6,0.7
				c-0.2,1.7-2,3.2-0.8,5.2c-1.2,3.6-0.7,7.2,0,10.8c0.5,0.9,1.3,1.1,2.2,1c6.3-0.5,12.6,0.9,19,0.8c10.7-0.2,21.3,1.6,32,1.8
				c11.7,0.3,23.3,1.2,35,1.8c6.2,0.3,12.4,1.4,18.6,1.3c12.8-0.1,25.4,2.5,38.2,2.1c0.7,0,1.8,1.4,2-0.2c0.2-1.2-1-1.4-2.1-1.5
				c-2.3-0.1-4.6,0.3-6.8-0.3c1.2-6.7,2.5-13.4,3.7-20.1c0.4-2.4-0.9-5.4-2.4-5.1c-2.5,0.4-2.7-0.9-2.6-2.8c0.1-4.5,0-8.9,0.1-13.4
				c0-1.7-0.4-3.2-2.3-3.3c-3.4-0.1-6.7-0.7-10.1,0.1c-0.5,0.1-0.9,0.2-1.4,0.3c-0.8-1.2-2-1.3-3.4-1.4c-5.1-0.3-10.1-0.6-15.2-1.2
				C393.2,1710.6,390.5,1710.8,390,1714.8z M591.9,1881.2c-0.6,1.4-0.6,3-0.3,4.4c0.4,2.1,1.1,0.9,1.6,0c0.9,2.7,3.2,2.2,5.1,1.9
				c2.1-0.3,1.4-2.2,1.4-3.5c-0.1-4.5-0.5-9.1-0.5-13.6c0-3.7-0.3-7.5-0.4-11.2c-0.3-6.7-4.6-12.3-6.6-18.5
				c-0.2-0.5-0.8-0.9-1.3-0.7c-1.2,0.3-0.9,1.3-0.9,2.2c0,6.7-0.3,13.5,0.3,20.2c0.3,3.2-1.9,7.1,2.1,9.6c0.3,0.2,0.2,0.4,0,0.7
				C590.3,1875.4,591.3,1878.3,591.9,1881.2z M675.7,2033.6c0.9-0.3,3.1,1.4,2.3-1.4c-0.7-2.5-1.7-4.9-2.8-7.3
				c-2-4.4-4.4-8.8-6.3-13.3c-2.6-6-4.9-12-7.5-17.9c-3.3-7.3-6.2-14.9-10.4-21.8c-0.6-1-0.5-3-2.2-2.7c-1.6,0.3-1.8,2-1.7,3.5
				c1.3,2.8,0.9,5.7,0.7,8.5c-0.2,3.5-0.8,7.1-1.2,10.6c-0.7-3.1-0.7-6.1-0.6-9.1c0-0.9,0.1-1.9-0.9-2.2c-1.1-0.3-1.4,0.8-1.7,1.5
				c-1.2,3-2.2,6.1-3.8,9c-0.9,1.6-0.2,2.2,1.7,2.2c1.7,0,3.6-0.9,5.3,0.5c2.3,1.9,3.6,4.1,3.2,7.2c-0.1,1.1-0.2,2.2,0.4,3.2
				c0.3,0.4,0.4,1.1,1.1,0.9c0.5-0.1,1.2-0.7,1-1.1c-1.2-2.1,1.7-4.5-0.5-6.6c-0.3-0.3,0-1.3,0.1-1.9c0.1-0.6,0.2-1.1,0.9-1.1
				c0.5,0,0.8,0.4,0.8,0.9c0.1,2.6,2.2,4.4,2.8,6.9c0.3,1.3,0.7,3.9,2.9,1.2c0.5-0.6,1.2-0.3,1.2,0.2c0.1,1.8,2.2,2.9,2,4.7
				c-0.7,6.2,0.6,12.4,0.2,18.6c-0.2,2.7-0.3,5.1,3.9,5.3C669.6,2032.4,672.9,2032.1,675.7,2033.6z M149.3,1749
				c-2.7-1.3-5.7-0.1-8.5-0.6c-5.5-1.1-11-1.1-16.5-1.2c-0.9,0-2,0.3-2.6,1.2c-2.8-0.7-5.6-1.4-8.5-0.7c-1.7-1.6-3.9-1.1-5.9-1.3
				c-2.6-0.3-3.3,0.6-3.4,3.2c-0.4,7.4-1.2,14.7-2,22.1c-0.3,2.7,0.5,5.9-0.2,8c-1,3-0.8,6.1-1.6,9c-1,3.7,0.4,8.1-3.3,11.2
				c-1.3,1.1-0.1,2.3,0.7,3.3c0.4,1.2,1.4,1,2.4,0.8c2-0.5,4-0.3,6-0.1c14.3,0.5,28.6,0.9,42.9,1.4c1.8,0.1,2.7-0.7,2.6-2.5
				c-0.6-9.5,1.6-19.2-1.4-28.7c0.8-1.7,0.4-3.5-0.1-5.2c1.3-0.3,1.5-1.4,1.5-2.5c0.2-5.3-0.2-10.6,0.3-15.9
				C151.9,1749.1,151.1,1747.8,149.3,1749z M601.2,1380.9c0.6,0.2,1.1,0.9,1.8,0.4c0.6-0.5,0.5-1,0.2-1.7c-1.7-3.6-3.7-7-5.5-10.4
				c-3.1-5.9-15.6-9.8-22.5-5.7c-3.7,2.2-6,9.3-4.1,13.2c0.8,1.7,3.9,2.3,2.6,5.1c0,0,0.7,0.5,1.1,0.5c2.2,0,3.2,1.6,4.2,3.2
				c1.8,2.7,1.6,2.5,3.7,0.7c2.7-2.3,2.7-4.6,0.9-7.3c-0.4-0.7-0.6-1.5,0.2-2.1c0.8-0.6,1.4,0,2,0.5c0.6,0.5,0.6,1.4,1,2
				c2.5,3.6,2.4,8.4,5.8,11.6c-5.5-1.6-3.5,2.9-4.2,5.5c1.8,0,3.5,0.2,5.2-0.1c2.4-0.4,3.5,0.5,4.1,2.6c0.8,2.9,2.2,5.7,3.3,8.5
				c1.1,2.8,1.6,6,1.2,9c-1,7.2-1.4,14.5-4.7,21.4c-3.3,6.7-6.6,12.6-14.3,14.8c-1.5,0.4-3.3,1.2-2.9,3.3c0.6,3-1.5,2-2.4,1.4
				c-2-1.2-4.3-1.7-6.3-3.1c-2-1.4-4.3-0.6-5.6,1.6c-0.7,1.2-1.1,2.3-2.7,2.5c-0.1-1.9-3-2.8-2.1-4.8c1.7-3.5-0.7-4.6-3-5.7
				c-4-1.9-7.5-4.3-9.1-8.7c-0.4-1.1-1.3-1.8-2.5-1.8c-1.5,0.1-2.5-0.8-3.6-1.4c-1.7-0.9-3.6-5.4-2.9-6.6c2-3.3,2-6-0.9-8.7
				c-2.1-1.9-2.2-3.8-1.5-6.1c0.5-1.7,1.5-3.5,1.3-5c-1.2-6.5,2.8-8.7,7.8-10.9c3.1-1.4,7.4-1.7,8.9-5.9c0.2-0.6,0.9-0.7,1.4-0.2
				c0.9,1,1.5,0.1,2.2-0.2c2-0.8,3.9-2,6,0c0.6,0.6,1.7,0.6,2.4-0.2c1-1.1-0.4-1.4-0.8-2c-0.6-0.9-1.3-1.8-2-2.5
				c-1.1-1.1-0.8-2.2-0.1-3.2c0.7-1,1.5-1.7,2.7-0.7c1.2,1,2.1,0.3,2.7-0.7c0.7-1.3-0.2-2.5-0.7-3.7c-0.5-1.1-1.5-1.2-2.5-0.8
				c-2.6,1.1-2.9-0.4-2.4-2.3c0.6-2.4,0-5,1.6-7.2c0.9-1.3-0.3-1.9-1.3-1.6c-2.6,0.6-3.4-0.7-3.9-3c-1.7-6.7-1.8-6.7-8.4-4.3
				c-1.1-3.8-1.2-4-5.4-3c-8.7,2-17.3,4.2-26,6.5c-2.5,0.7-5.1,1.2-7.5,2.9c2.5-0.6,2.8,1.7,4.1,2.6c-0.3,0.2-0.5,0.4-0.8,0.5
				c-10.3,2.1-20.7,4.3-31.1,5.7c-1.2,0.2-2.9-0.3-2.4,1.9c-0.5-0.2-1-0.3-1.5-0.6c-6.5-4.6-10.1-4.3-15.2,1.3
				c-0.2,0.2-0.5,0.5-0.6,0.7c-1.9,3-1,4.9,2.5,5.6c2.2,0.4,4.6,1.2,6.8,0.5c5.1-1.6,10.1-0.4,15.1,0.6c1.6,0.3,3.4,0.9,5-0.4
				c5.7,2.4,11.3,4.9,17.7,5.2c2.2,0.1,2.7,1.7,2.8,3.7c0.2,3.9-0.8,7.8,0,11.6c1,5,0.6,10,0.6,15c0,1,0.2,3.1-2.1,2.8
				c-1.9-0.3-2.3-1.6-1.8-3.1c0.5-1.5-0.9-3.5,1.4-4.4c-0.4-1,0.3-2.6-0.8-3c-2-0.7-2.4-2.4-3.2-3.9c-0.4-0.8-1-1.8-1.8-1.7
				c-3,0.5-5-2.7-8.2-1.7c-1,0.3-2,1.6-2.9,1c-4.2-2.6-2.8,0.6-3.3,2.6c-0.3,1.2,1.3,3.7-1.7,3.7c-0.6,0-1.3-0.5-1.6,0.4
				c-0.3,0.9,0.3,1.6,1,1.9c3.3,1.2,4.4,2.3,0.1,3.8c-0.8,0.3-1.4,1.1-0.9,2.1c0.4,0.9,0,1.7-0.3,2.5c-1.3,3.2,0.6,8.8,3.8,9.5
				c3.7,0.8,5.9,3.3,8.8,5.1c2.2,1.4,3.8,2.5,2.9,5.4c1.5-2.3,1.5-2.3,4.3-1.8c0.4,0.1,0.9,0,1.3-0.1c4.7-1.4,5.1-0.9,5.4,4.1
				c0.2,2.9,1,5.8,1.5,8.8c0.6-3.6,0.9-7.2,0.9-10.7c0.1-10.3-0.6-20.5,0.1-30.8c0.1-1.7,0-2.5,1.8-1.4c2.5,1.5,4.2,0.5,5.4-1.7
				c1.2-2.3,0.2-4.6-2.4-5.4c-1.8-0.5-1.9-1.6-1.5-2.6c1.2-2.5,2.5-5.2,6.2-6.4c-2.3,11.1-1.4,21.6,1.5,31.9l0,0
				c-0.2-0.1-0.3-0.3-0.5-0.4c-1.9-0.9-3.8-2.4-5.8-0.7c-1.6,1.3-1.5,3.2-0.8,5.1c0.9,2.4,2.9,2.2,4.8,2.2c2.4,0,1.9-2.5,3-3.5
				c1.7,3.2,3.5,6.4,5.1,9.6c1.4,2.8,3.8,5,4.6,8.1c-0.3-0.3-0.7-0.5-1-0.8c-1.5-1.5-3.2-2.4-5.2-1.1c-1.8,1.1-2.1,3.1-1.6,4.9
				c0.6,1.8,1.9,3,4.1,3c2.5,0.1,2.8-1.8,3.5-3.5c0.4-0.9,0.1-1.7,0.2-2.5c0.6-0.1,0.9,0.3,1.3,0.7c5.4,5.8,11.2,11.1,18.9,13.7
				c10.7,3.7,20.9,3.7,30.6-3c2.1-1.5,3.3-4.2,6.6-4.9c-0.5,1.2-1,2-1.1,2.8c-0.6,3.2,1.1,4.5,4.1,5.3c1.7,0.5,2.7,0,3.1-1.2
				c0.8-2.3,0.4-4.6-2.1-5.9c-0.6-0.3-1.2-0.5-1.8-0.7c-2.3-0.8-2.6-2-0.8-3.6c0.7-0.6,2.1-0.8,2.2-1.8c0.4-5.2,5.7-8.5,5.4-14
				c0-0.3,0.2-0.8,0.4-0.9c3.2-0.4,1.8-3,1.8-4.5c0.1-3.5,0.6-4.2,4.1-3.8c2.1,0.3,2.8-1.3,3.7-2.6c1-1.4,0.2-2.7-0.8-3.4
				c-1.3-0.9-2.7-1.8-4-2.8c-0.8-0.6-1.7-1-2.9,0.2c-1.4,1.4-2.8,0.2-4-0.7c-1.4-1-0.9-2.4-0.2-3.2c1.5-1.7,1.1-3.3,0.4-5.1
				c-0.3-0.8-0.8-1.9-0.5-2.5c2.1-4.2-3.5-5.9-3.1-9.5c0.1-0.7-1.1,0-1.6-0.1c-1.8-0.5-4-0.7-4.6-3.1c0.7-0.1,1.8,0.1,1.6-1.1
				c-0.5-2-1-4.1-1.9-6c-0.4-0.9-1.8-1.7-0.2-2.5c1.5-0.7,2.1,0.3,2.3,1.8c0.1,0.7,0.4,2,1.4,1.9c1.1-0.1,1.9-1.1,2.1-2.2
				c0.2-1.3,0-2.6,0-3.9C601,1381.1,601.1,1381,601.2,1380.9z M439.4,1823.5c0.9-2.8-0.6-5.7,0.2-8.6c0.8-2.3,1.1-4.6,1.1-7
				c-0.3-14.1-0.4-28.3-0.6-42.4c0-1.1,0.1-2.2-1-2.9c-1.1,0.8-1.4,2.2-1.3,3.2c1.2,8.2-0.4,16.1-1.7,24.1c-0.4,2.7-0.6,5.4-1.3,8
				c-2,6.4-2.4,13.1-3.7,19.7c-1.9,10.1-3.3,20.3-5,30.4c-0.3,1.7,0,2.1,1.6,2.2c3.3,0.2,6.8,0.1,10,0.9c3.6,0.9,3.8-0.6,3.8-3.4
				C441.3,1839.6,441.7,1831.5,439.4,1823.5z M693.8,1924.6c-0.8-0.4-1.7-0.9-2.5-1.1c-1.8-0.4-3.7-0.8-4,2
				c-1.8-0.1-3.6-0.4-5.6-1.7c0.5,1.7,0.8,2.7,1.1,3.7c0.3,0.8,0.9,1.8,0,2.3c-0.6,0.3-1.6-0.3-2.1-1c-2.7-3.7-6.2-2.2-9.6-1.7
				c-4-1.2-5.6-0.4-6.7,3.2c-0.5,1.6-1.1,3.1-1.8,4.5c-1,2-1,3.8,0.3,5.5c2,2.8,3,6,4.2,9.1c1.1,2.8,2.5,5.5,4.1,8.9
				c0.7-4.3,1.4-5.8,2.9-6.6c-0.8-1.1-2.3-1.6-2.5-3.3c-0.2-2.6,2.4-0.4,3-1.8c0.1-0.3,0.8-0.5,1-0.8c1.2-2,3.1-2.1,5.1-1.7
				c-1.6,1.4-2.9,2.8-0.3,4.9c0.3-1.8,1.2-2.8,3.1-2.3c4.3,1.1,8.8,1.3,13,3.1c1.2,5.5,0.5,11,0.8,16.5c0,0.7,0.3,1.6-0.9,1.7
				c0-0.7-0.1-1.2-0.8-1.8c-2.2-1.7-4.6-3.6-3.8-7c0,0-0.3-0.2-0.5-0.3c-1.1,1.3-0.9,3.1-1.4,4.6c-0.2,0.7-0.8,1.5-1.6,1.4
				c-1.2-0.2-0.8-1.1-0.6-1.8c0.2-0.8,0.4-1.5-0.3-2.1c-2.6-2.7-3.1-6-2.9-9.6c0-0.6,0.4-1.6-0.9-1.6c-2.1,5.7-0.9,11.5-0.8,17.2
				c-2.2,1.3-0.8,3.1-0.7,4.7c-0.3,5-0.8,10.1,0.9,14.9c0.4,0,0.8,0,1.1,0c0.1-1.9,0.2-3.9,0.3-5.8c1.2,0.1,1.4,2.4,2.7,0.9
				c0.6-0.7,0.6-1.9,0.7-2.5c2.5-0.7,0.1,6.7,4.9,2.4c0.1-0.1,0.4,0,0.6,0.1c-0.4,2.1,3.4,3,1.6,5.5c-0.5,0.8-0.2,1.4,0.1,2.2
				c2,5.5,1.4,11.4,2.1,17.1c0.6,4.9-1.4,10,1.3,14.7c0,3.8,2,6.9,3.6,10c2,4.1,2.2,4.1,6.6,2.1c-1.1-1.5-4-0.6-4.1-3.5
				c-0.2-6.2-1.1-12.4-1.1-18.5c-0.1-17.9-0.8-35.7-1.2-53.5c-0.5-19.7-0.7-39.4-1.4-59.1c-0.3-9.5,0-19.1-0.4-28.7
				c-0.4-7.7-0.2-15.4-0.5-23.1c0-0.9,0.1-1.8-0.9-2.2c-0.9-0.3-1.4,0.4-1.7,1.2c-1.7,3.5-3.6,6.8-3.3,11c0.5,7.2,0.5,14.4,0.8,21.7
				c0.2,3.1-0.2,5-4,3.6c-0.9-0.3-1.7,0.2-2.5,0.6c-1.9,0.9-2.5,0.3-1.9-1.7c0.3-1,0.4-1.8-0.8-2c-1-0.2-1.7,0.1-2.2,1.3
				c-1,2.3-0.2,3.8,1.5,5.2c-1,0.4-2.9-0.7-2.9,1.1c0,1.8,1.8,1.4,3.1,1.4c1.4-0.1,2.8-0.3,4.2-0.3c1.5,0,2.8,0.5,3,2.2
				c-3.9,0.4-8-0.5-12.6,1.1c4,1.4,7.8-0.4,10.9,1.7c-2.9-0.5-5.7,0.8-8.6,0.2c-1.9-0.4-3,1.1-3.8,2.5c-0.7,1.4-1.2,3-1.8,4.5
				c-0.1,0.4-0.3,0.8-0.4,1.2c-0.5,3.4-3.3,6-2.9,9.7c-1.2,0.3-2.2,0.8-2.2,2.3c-0.3,2.3,1.1,2.3,2.8,2.3c7.4,0,14.7-0.4,22.1,0.4
				c0.3,1.5,0.3,3,0,4.5c-0.3,0.2-0.9,0.3-1,0.6c-0.2,0.7,0.4,0.8,1,0.9c0,0.6,0,1.1,0.1,1.7
				C695.1,1923.9,694.4,1924.2,693.8,1924.6z M396.5,1927.8c-1.1-1.2-1.8-0.5-2.5,0.5c-1.3-0.5-2.7-0.7-4.1-0.7
				c-4.2,0-8.4,0.2-12.6,0.6c-3.8,0.3-4,0.5-3,4.3c-0.8,2.7,0.7,4.8,1.8,7c0.8,1.6,2.4,0.5,3.5,0.8c0.5,0.1,1.2,0.1,1.2,0.8
				c0,0.7-0.5,0.9-1.1,1c-1.2,0.2-2.4,0.3-3.6,0.3c-0.8,0-1.6,0.1-1.7,1.1c-0.1,0.8-0.4,1.9,0.9,2.1c1.6,0.3,1.8,1.3,1.5,2.6
				c-0.3,1.2,0.3,1.9,1.1,2.6c1.2-0.9-0.4-3.3,1.9-3.1c2,0.2,1.5,2,1.6,3.4c0.8,8,0.2,15.9,0,23.9c-0.2,10.4-1,20.8-1.1,31.2
				c0,1.4-0.9,3.7,2.3,2.6c0.8-0.3,1,0.6,1.1,1.3c0.1,0.9-0.4,1.8,0.2,2.7c1.2-0.9,0.2-1.9,0.3-2.8c0.8-6.6,2.2-13.1,2.8-19.7
				c0.5-4.8,0.9-9.3,0.6-14.2c-0.5-7.4,1-14.9,0.7-22.4c-0.1-1.4,0.3-2.5-1.4-3.5c-1.2-0.8-2.7-3.4-0.7-4.5c1.8-1,4.6-1,6.6-0.3
				c1.6,0.5-0.4,2.7-0.1,4.1c0,0.2,0.2,0.3,0.5,0.6c1.7-1,2.8-2.3,3.1-4.5c0.7-4.5,0.8-9.2,2.8-13.5
				C400.5,1927.7,400.3,1927.4,396.5,1927.8z M543.3,1914.2c0.1,0.1,0.2,0.1,0.2,0.2c-0.1,0.2-0.2,0.3-0.2,0.4
				c-0.9,1.5-1.6,3,0.3,4.3c1.1,3.6,0.8,4.1-2.8,3.8c-3-0.2-5.8,0.4-8.7,0.8c2.8,0.9,5.7,0.8,8.5,0.6c2.9-0.1,4.1,0.7,3.4,3.7
				c-1.4,0.2-1.5,1.1-1.2,2.1c0.9,3.1-0.2,6-0.7,9c-0.1,0.6-0.5,1.2-0.2,1.9c1.1,2.4,0.4,3.9-2.3,4.4c1.1,2.3,3,1.6,4.7,1.6
				c0.7,5.3,0.7,5.3-4.3,5.6c-1.2,0.1-1.3,0.8-1.4,1.7c-0.2,2.5,0.6,4.8,1,7.2c0.5,1,0.1,2.2,1,3.1c0.2,1.4-0.4,3.4,1.9,3.4
				c0.9,0,1.9-0.9,2.2,0.8c0.2,1.3,0.9,2.5-1.1,3.1c-1.5,0.5-1.3,2.4-0.7,2.6c3.2,1.2,1.8,3.7,1.8,5.7c0.2,10.3,0.6,20.7,1,31
				c0.5,11.9,0.2,23.8,0.5,35.7c0,1.9-0.9,4.2,1.2,5.9c-0.4-4.9,2-9.4,1.7-14.4c-0.1-1.4-1.5-2.2-1-3.6c1.6-3.7-0.6-7.5,0.5-11.5
				c0.9-3.3,0.4-7.4-0.9-11c-0.4-1.1-0.9-2.6-0.4-3.5c1.8-3.6,0.3-7.3,0.4-10.9c0.2-3.2-0.2-6.5,0.2-9.7c1.1-8.5,0.7-17.1,0.7-25.6
				c0-6.7-1-13.5-1-20.3c0-4.3-0.9-8.6-0.3-12.8c0.8-5.6-0.2-11.2,0.2-16.8c0.4-5.7-0.2-11.5-0.5-17.3c-0.1-2.1,1.1-4,0.5-6.1
				c-0.8-2.7-0.6-5.6-0.3-8.3c0.7-6.2-0.2-12.3-0.3-18.4c-0.1-6.8,0.1-13.6-0.4-20.5c-0.5-6.6,0.2-13.2-1.4-19.7
				c-0.2-0.8,0.2-2.1-1.1-2c-1.2,0.1-0.9,1.3-0.9,2.1c0,2.7,0,5.5,0,8.2c0,0.9,0.4,2-0.8,2.6c-8,0.2-15.9,0.4-23.9,0.7
				c-1.5,0-3.1,0.1-4.6,0.3c-0.7,0.1-1.6,0.5-1.5,1.5c0.2,1,1.1,0.9,1.9,0.9c9.4-0.4,18.8-0.9,28.2-1.4c1.1,1.7,1,3.5,0.2,5.2
				c-3.4-1-6.9-0.1-10.4-0.1c-5.6,0-11.3-0.4-16.9,0.7c-0.7,0.1-1.7,0.1-1.5,1.2c0.1,0.8,0.9,0.9,1.6,0.8c9-0.4,18.1-0.9,27.1-1.3
				c1.2,3.9,1.1,7.8,0.2,11.8c-7.9-0.4-15.7,0.5-23.6,0.7c-0.9,0-2.2-0.3-2.2,1.2c0.1,1.3,1.3,1.2,2.3,1.3c3.4,0.1,6.8,0.1,10.1-0.7
				c4.4-1,9,0.3,13.3-1.2c0.9,1.5,0.9,3,0.2,4.6c-6.3,0.2-12.6,0.3-18.9,0.5c-1.1,0-2.2,0-3.3,0.1c-1.1,0.1-2.5,0.3-2.4,1.7
				c0.1,1.3,1.5,1,2.4,1c4.5-0.2,8.9-0.4,13.4-0.5c2.9-0.1,5.9,0.3,8.7-0.7c0,1.8-0.6,3.4-0.3,5.3c0.7,3.1-0.5,4.4-3.9,4.6
				c-2.8,0.1-5.7-0.1-8.5,0.7c-2.8,0.7-5.7,1.9-8.2-0.9c-0.5-0.5-1.8-0.5-1.6,0.6c0.3,3.2-0.2,6.8,2.8,9.2c-0.6,1.2-0.8,2.5-0.5,3.8
				c2,8.3,4.1,16.7,6.1,25c0.5,0.4,0.4,0.8,0,1.2c-0.1,1.4,0.8,1.8,2.1,1.8C535.1,1912.4,539.2,1913.2,543.3,1914.2z M344,1919.1
				c-3.6,0-7.2,0-10.8,0c5.7-2.3,11.8-1.5,17.7-2.7c-7.6-0.4-15.1-0.5-22.6-0.4c-1.4,0-1.4,1.2-1.3,2.2c0.1,2.6-0.5,5-1.1,7.4
				c-0.6,2.6-1.6,5.3-1.4,8c1.4,15.1,0.6,30.2,1.1,45.3c0.9,26.9,1,53.8,1.4,80.7c0.4,25.5,0.7,51,1.3,76.5c0.1,4.1-1.4,8.7,2,12.4
				c1.1,2.9,3.5,1.3,5.4,1.6c1.4,0.2,3.3-0.5,2.7,2.3c-0.3,1.3,0.9,1.7,1.9,1.7c1.8,0,3.8,0.2,5.5-0.4c3.9-1.4,7.4-0.7,11.1,0.6
				c4.7,1.6,9.3,2,13.5-1.2c0.9-0.7,1.5,0.3,2.4,0.3c-0.2-1.5,0.8-3.3-1.5-4.2c-0.7-0.3-0.3-1,0.1-1.7c3.1-5,0.2-10.6,2.5-15.9
				c-2.6,0.5-0.6,3.5-2.9,3.1c-1.9-0.4-3.7-1.2-3.6-3.9c0.1-2.2,0.8-4.1,3.1-4.2c2.2-0.1,2.4-1.1,2.4-2.9
				c-0.3-11.1-0.4-22.1-0.7-33.2c0-0.7-0.2-1.5-0.4-2.2c-0.8-3.8-1.2-7.9-0.8-11.5c0.5-4,0.6-7.3-1.7-10.6c-0.7-0.9-1.2-1.9-0.5-3.3
				c1.1-2.5,0.5-4.8-1-7.1c-2-3.1-4.8-5.9-5.7-9.7c-0.4-1.6,0.3-3.3-0.9-4.9c-0.9-1.2-1.6-2.6-0.3-4.4c0.7-1,0.8-3-0.6-4.2
				c-2-1.8-2.8-3.3,0.2-5c1.4-0.8,0.2-1.9-0.2-2.8c-0.2-0.5-1.3-0.8-1.3-0.6c0,3.1-3.9,4-3.8,7.4c0.4,13.7,0,27.4,0.6,41.1
				c0.7,14.9-0.3,29.8,0.6,44.8c0.6,9.6,0.1,19.2,0.2,28.8c0,1.6,0.8,3.8,0.1,4.7c-1.4,1.7-3.1-0.5-4.6-0.9
				c-6.2-1.6-12.5-1.7-18.8-2.2c-0.2-4.8-0.4-9.6-0.4-14.4c-0.2-14.3-0.3-28.5-0.5-42.8c-0.2-16.9-0.6-33.7-0.8-50.6
				c-0.2-12.2-0.1-24.4-0.2-36.6c-0.2-22.4-0.5-44.9-0.7-67.3c0-3.6,0.3-7.2,0.5-11c1.1,0.5,1,1.1,1.1,1.6c0.2,1.4,0.1,3.4,1.8,3.3
				c2-0.1,5.3-0.8,5.7-1.9C340.7,1924.8,342.9,1922.2,344,1919.1c0-0.1,0.1-0.2,0.2-0.3C344.1,1918.9,344.1,1919,344,1919.1z
				 M598.2,2044.7c2,2.9,4.4,0.6,6.6,0.8c-2.1-0.9-4.4-0.1-6.5-0.9c-0.2-2.1,0-4.3,1.2-6c3.7-5.3,4.4-11.7,6.9-17.4
				c1-2.3,2.5-4.6,2.5-7.3c0-1.6,0.3-2.7,2.4-1.9c1.5,0.6,1.9-0.8,2.4-2c9-22.6,18.1-45.3,27.1-67.9c1.4-3.6,3.1-7.3,3.1-11.7
				c-1.9,0.4-3.2,2.1-4.9,1c-1.6-1-3.4-0.6-5.1-1.2c-3-0.9-1.6,3-3.2,3.9c-0.1,0.1-0.2,0.1-0.3,0.1c-1.6-1.6-1.9,0.5-2.8,0.9
				c-1,0.4-1.9,3-3.1,0.1c-0.1-0.3-0.8-0.5-1.1-0.4c-3.6,1.5-2.5-2.2-2.7-3.1c-0.4-1.7,1.5-0.5,2.4-0.9c0.9-0.4,1.8-0.8,2.7-1.1
				c0.7-0.3,1.5-0.6,1.3-1.6c-0.2-1-1.1-0.8-1.9-0.7c-4.6,0.1-9.2,0-13.8,0.4c-3.3,0.3-4.4-1-4-4c6.4,0.8,12.9,0.7,19.2-0.4
				c-3.3-0.9-6.7-0.9-10-1c-3.1-0.1-6,1.1-9,0.5c0-1.7,0.2-3.5,0.1-5.2c-0.2-2.3,0.4-3.3,3-3.2c3.9,0.1,7.8-0.7,11.8-0.3
				c-0.2-1.3-1-1-1.6-1c-3.5,0-7,0-10.5-0.1c-0.8,0-2.1-0.3-2-1.2c0.1-1.3,1.3-0.7,2.1-0.7c1.4-0.1,2.8-0.1,4.3,0
				c6.9,0,6.9,0,4.1-6.3c-1-2.2-1.5-4.9-4.8-4.9c0.2-1.3,2.6-0.9,2.1-2.8c-0.6-2.2-1.3-4.4-2.6-6.2c-0.6-0.9-1.6-0.9-2.8-0.5
				c-4.8,1.5-9.7,2.8-14.8,3.1c-2.8,0.2-4,1.9-4.2,4.4c-0.1,2.4,0,4.8,0,7.1c-0.6,2.2,0,4.3,0,6.5c0.2,13.5,0.5,27,1.2,40.5
				c0.4,7.2,0.2,14.4,0.4,21.6c0.2,10.4-0.5,20.9,0.5,31.3c0.3,3,1.5,5.9,0.9,9.1c-0.1,0.9-1.3,3.1,0.9,3.9c-0.3,2.5-2.5,5-2.2,7.1
				c0.7,5.7,0,11.5,0.9,17.2C594.9,2045.2,596,2045.4,598.2,2044.7z M566.2,2135.6c0.5,3.5,2.6,1.4,4.1,1.2c0.9-0.1,2-0.5,1.6-1.5
				c-0.6-1.4-1-3.1-2.6-3.7c-1-0.4-1.5,0.6-1.8,1.4c-0.2,0.5-0.5,1-0.7,1.4c-1.2,0.1-1.6-0.6-1.3-1.6c0.5-1.4,1.1-2.7,2.7-3.1
				c1.3-0.3,2.5,0,3.2,1.1c1.3,2.1,3.6,2.2,5.5,2.7c1.6,0.4,4.1-0.8,4.1-1.6c-0.1-3.3,2.6-4.9,4.4-5.7c3.5-1.5,2.8-4,2.9-6.4
				c0-0.9-0.8-2-0.3-2.8c2.1-4.1-1.8-8.3,0.5-12.4c0.1-0.2-0.2-0.6-0.2-0.9c-1-3.9-1.5-7.9-0.6-11.8c1-4.2-0.2-8.4,0-12.6
				c0.2-5.7-0.9-11.4-1-17.2c-0.1-5,0.6-10.1,0.5-15.2c-0.1-5-0.1-9.9,0.7-14.8c0.2-1.6,0.5-3.5-0.1-4.8c-1.5-3.2-1.1-6.2-0.6-9.5
				c0.3-2,0.6-4.4-2.3-5.3c-1.2-0.4-0.6-1.3-0.2-1.9c3.8-4.8,1.2-10.3,1.6-15.5c0.2-2.4,0.7-5.3-0.9-7.8c-0.5-0.8,0-1.9,0.6-2.7
				c0.6-0.8,1.4-1.7,1-2.7c-1-2.9-0.6-5.9-0.5-8.8c0-1.9-1.5-3.5-0.8-5.1c0.7-1.5,0.9-2.9,0.8-4.4c-0.5-7-0.8-13.9-0.7-20.9
				c0.1-5.1-0.8-10.1-0.8-15.3c0.1-9.5-0.8-19.1-1.1-28.6c-0.1-3.2,0.9-6.5,0.7-9.8c-0.5-6.9-1-13.8,0.4-20.7c0.3-1.3,0.3-2.6,0-3.9
				c-1-5.5-0.8-11.1-1-16.6c-0.1-2.4-1.3-4.2-1.4-6.3c-0.3-5.9-0.1-11.7-0.5-17.6c-0.3-5.3,0.1-10.6-0.7-16c-0.3-2-2-4.4-0.6-6.3
				c1.5-2,1.2-3.8,0.9-5.8c-0.2-1.3-0.8-2.7-0.5-3.8c2-7.1-0.1-14.3,0.1-21.4c0.2-6.4,0-13.1-0.4-19.6c-0.4-7.5,0.2-14.9-0.6-22.3
				c-0.4-4.1,1-8.2,0.8-12.1c-0.2-4.6,1.1-9.6-1.8-13.9c-0.3-0.5-0.2-1.3-0.1-1.9c0.7-4.6-0.8-9.1-0.3-14c-3.2,1.4-2.5,3.8-2.5,5.8
				c0,1.9-0.5,3.5-2.2,4.3c-1.5,0.7-2.6-0.2-3.5-1.6c-0.6-1-1.7-0.8-2,0.3c-0.6,2.9-2.4,5.6-2.5,8.3c-0.2,4.7-2.4,8.9-2.2,13.6
				c0.1,1.9-1.2,3.7-0.8,5.2c0.3,1.5,0.5,2.9,0.6,4.4c0,6.4,0,12.8,0,19.1c0,1.1,0,2.2,0.2,3.3c0.7,4.2,1.5,8.3,0.1,12.5
				c-0.3,0.8-0.2,1.5,0,2.2c1.5,6.4,1.7,12.9,0.9,19.4c-0.1,0.9-0.1,1.7,0.2,2.5c1.5,3.3,0.9,7.1,2.1,10.6c0.5,1.4-0.9,3.5,0.4,5.2
				c0.1,0.2,0.1,0.8-0.1,0.9c-2.2,1.7-1.8,3.9-1.7,6.3c0,4.4-1.7,8.5-1.7,13c-0.1,3.9,0.3,7.8,0.2,11.7c-0.1,4.2,0.5,8.4,0.6,12.6
				c0.1,4.2-1.4,8.4,0.2,12.6c0.5,1.3,1.3,2.9,0.3,4.4c-1.1,1.6-0.5,3,0.2,4.6c1.4,3.4,1.4,6.8,0.3,10.6c-0.6,2.1-2.7,4.2-1.8,6.4
				c1.2,2.9,0.4,5.7,0.5,8.6c0.1,1.4-0.6,2.7-0.5,4.2c0.5,5,1.2,10,1,14.9c-0.3,5.4,0.9,10.8-0.4,16.3c-1.1,4.6,0.6,9.5-0.2,14.2
				c-0.2,1.4,1.6,2.2,0.9,3.6c-2.1,4-0.1,8.2-0.1,12.2c0,3.6-0.7,7.3-0.3,11c0.5,4.6-0.6,9.3,0.4,14c0.4,2.1,0.5,4.7,0.1,6.9
				c-0.8,4.8-1.6,9.5,0.5,14.1c0.1,0.3,0.3,0.6,0.3,0.9c-0.3,5.1-0.3,10.1-0.3,15.1c0,4,2.7,8.5-0.8,12.4c-0.1,0.1,0,0.7,0.2,0.9
				c2.2,1.7,0.7,3.6,0.1,5.2c-0.7,2.3-0.3,3.9,1.5,5.4c2.7,2.1,3.5,7.6,1.3,10.1c-2.1,2.4-1.5,6-4,8.3c-0.2,0.2-0.1,1.1,0.2,1.6
				c1.3,2.8,0.2,5.3-0.6,8c-1,3.3-3.3,6.4-1.2,10.4c1,1.8,1.2,4.8-2.3,5.7c-0.7,0.2-1.7,0.9-1,1.7c1.3,1.4-0.2,2.3-0.3,3.4
				c-0.2,1.4-1.3,2.4-1.5,3.9c-0.6,4.8,0.7,9.9-1.8,14.5c-0.9,1.6-1.1,3.8-1.1,5.7c0,6.5,0.2,13.1,0.4,19.6c0,1.3-0.3,3.1,1,3.5
				c1.3,0.5,2.8-0.1,4.2-1C563,2139.3,564.3,2137.2,566.2,2135.6z M542.7,1341.2c-0.3-2.3,0.4-4.9-0.7-7.6c-1.2-2.9-1.7-6.1-2.6-9.1
				c-0.6-1.8,0.2-3.7-0.3-5.4c-1.7-5.4-1.8-11.1-2.9-16.6c-0.4-2.3-1.7-3.6-3.5-3.7c-3.7-0.3-7.4-0.5-11.1,0.4
				c-11.8,3.1-23.5,6.8-35.6,8.9c-8.5,1.5-17,2.9-25.5,4.6c-1.7,0.3-4.6,0-4.6,3.1c0,4.1-1.3,8.1,1,12.3c1,1.9,2.4,4.3,2.5,7.1
				c0.1,4.6,1.1,9.2,2.2,13.7c1.1,4.4,2.5,8.8,3.2,13.4c0.3,2.4,1.6,4.6,4.3,5c2.9,0.4,2.2-2.4,3-3.8c0.3-0.6,0.5-1.3,0.4-1.9
				c-0.4-2.3,1.7-5.2-0.9-6.9c-1.7-1.2-0.9-2-0.3-2.8c0.9-1.3,2.6-2.7,3.8-1.9c1.9,1.2,4.7,0.2,5.9,3.2c1.8,4.3,4.8,7.3,9.4,9.4
				c3.6,1.7,7.8-1.2,10.7,2.1c0.1,0.1,0.5,0,0.6-0.2c3.5-3.9,7.6-4.4,12.2-2.3c0.1,0,0.2,0,0.3,0c9.3-2.7,20-1.8,26.5-11.4
				c1.2-1.7,2.2-3.1,2-5.1C542.6,1344.2,542.7,1342.9,542.7,1341.2z M946.2,1737.5c0-2.8,0-4.7,0-6.7c0-1.5,0-2.9-1.4-3.7
				c-1.9-1.1-3.4-2.7-4.6-4.5c-0.8-1.2-2.4-1.6-3-0.5c-1.4,2.7-3.3,5.1-3.6,8.5c-0.3,2.6,0.5,5.1-0.2,7.6c-0.9,3.1-2.1,6.1-0.3,9.4
				c0.7,1.3,0.8,3.2,0.8,4.8c-0.1,5.9-0.4,11.7,0.8,17.6c0.5,2.4,1.9,5.3,0.7,7.8c-0.9,1.8-0.7,3.5-0.7,5.4
				c0.1,5.6,2.5,11.1,0.9,16.7c-0.8,2.8,0.6,5.4,0.2,8c-0.9,6.6,1.4,13.1,0.3,19.8c-0.7,4.4,2.8,8.9,1.1,13.7
				c-0.1,0.3,0.1,0.9,0.3,1.2c1,1.1,1.1,2.3,0.5,3.6c-0.6,1.6,0,3.2,0.9,4.3c1.8,2,1.5,4.1,1,6.4c-0.8,3.4-2.2,7.1,1.8,9.8
				c0.7,0.5,0.6,1.3,0.6,2.1c0.4,4.2-1.2,8.6,1.1,12.7c0.2,0.3-0.3,0.9-0.2,1.2c0.9,3.6-1.5,7.1-0.3,10.8c0.7,2.1,0.4,4.2,0.3,6.4
				c-0.2,2.5,0,5.3,0.5,7.6c0.6,2.9,1.2,5.9,1.4,8.9c0.1,2.7,1.8,5,1,7.9c-1.2,4.3,1.4,8.3,1.2,12.6c-0.2,3.3,0.5,6.7,0.1,9.9
				c-0.6,4.7-1,9.3,1.6,13.6c0.2,0.3,0.4,1,0.3,1.2c-2.2,2.4,0.8,5.6-1.5,8c-0.4,0.4,0.1,1.2,0.4,1.8c1,1.8,1,3.7-0.1,5.5
				c-0.6,1-1.6,2.8-0.9,3.3c3,2.2,0.9,5.4,1.2,7.9c0.6,5.2-0.3,10.6,0.4,16c0.6,4.5-0.5,9.1-0.8,13.6c-0.2,2.9,0.6,5.6,0.4,8.2
				c-0.2,3.3,0.5,6.7-0.2,10c-0.1,0.7-0.1,1.8,0.4,2.2c2.6,2.3,1.5,4.9,0.9,7.5c-0.9,4.2-0.8,8.6-0.4,12.9c0.1,1.2,0.7,2.4,0.6,3.9
				c-0.2,6.5,0.4,13.1,0.1,19.6c-0.1,3.5,1.5,6.6,0.8,10.2c-0.3,1.5-0.4,4,2,5.7c1.3,0.9,3.3,2.9,3.6,5.3c0.3,2.2,2.4,2.3,4,2.7
				c1.9,0.5,1.8-1.3,2.5-2.4c2.2-3.3,1.3-7.2,1.2-10.6c-0.1-4.6,0.1-9.2-0.1-13.8c-0.3-6.6-0.8-13.1-1.4-19.7
				c-0.3-3.4-1.4-7.1-1.1-10.2c0.3-3.5-3.3-6.9-0.2-10.3c-0.6-6.1-1.4-12.2-1.1-18.4c0.1-2.5,0-5.2-0.5-7.6
				c-1.7-8.1-1.9-16.4-2.9-24.6c-0.7-5.7-0.4-11.9-0.4-17.8c0-2.9,0.5-6.2,0-8.7c-1.9-9.2-1.1-18.5-2.3-27.7c-0.3-2.4-1-4.7-1-7.2
				c-0.3-11,1.5-22.1-0.1-33.1c-0.3-1.9-1.5-3.8-0.9-5.9c1.6-5.7,1.2-11.6,0.2-17.1c-1.5-8.5-1.8-17-1.7-25.5
				c0.1-9.1-1.5-18.2-0.7-27.4c0.1-0.7-0.2-1.5-0.4-2.2c-0.6-1.7-0.4-3.3-0.4-5c-0.1-3.3,1.6-6.3,1.2-9.6c-0.6-4.8-1.1-9.7-1.1-14.6
				c0-4.3,0.1-8.6-0.9-12.9c-0.3-1.1-0.2-2.1-0.1-3.2c0.2-3.7,1.2-7.8,0-11c-1.2-3.3-0.2-6.5-0.9-9.8
				C946,1745.4,945.4,1740.9,946.2,1737.5z M307.3,1957.4c-0.8-1.2,0-2.4-0.1-3.6c-0.1-1-0.1-2-1.2-2.4c-1-0.3-1.5,0.2-2.2,0.9
				c-2.1,1.9-2.6,4.4-3,6.9c-0.4,2.6-1.6,4.3-3.6,6.1c-2.3,2.1-6,2.4-6.9,6.3c-0.4,1.6,0.7,2.7,0.3,3.8c-0.8,2.5-3.7,4.4-2.2,7.6
				c-2.6,1.6-1.7,4.5-1.8,6.6c-0.4,6.2,0.1,12.5,0.2,18.7c0,1,0,2,0.3,2.9c1.4,5.6,0.3,11.3,0.6,16.9c0.2,2.8-2.4,5.7,0.1,8.5
				c0.5,0.6-0.1,1.2-0.7,1.6c-0.9,0.5-0.7,1.1-0.3,1.9c1,2.3,1,4.5-0.2,6.8c-0.9,1.9-1.1,4.2,0.2,5.9c1,1.3,0.9,2.4,0.7,3.9
				c-0.4,2.5-1.1,4.9-0.9,7.6c0.7,6.4-1.2,13,1.3,19.3c0.2,0.4,0.1,1.1-0.1,1.6c-0.9,1.7-0.9,3.2-0.7,5.2c0.4,3.9,0.5,7.8,1.7,11.4
				c1.2,3.6,0.2,7,0.4,10.5c0.4,6.9,1.9,13.8,0.7,20.7c-0.4,2.2,0.6,2.1,2,2.1c2.8,0.1,5.7-0.1,8.5,0.3c3.1,0.5,5.8-0.3,8.6-1.3
				c1.1-0.4,2-1.2,1.6-3c-0.5-2.7-0.3-5.6,0.4-8.4c1-3.7,0.9-7.5,0.2-11.3c-0.4-2-1.7-4.2,0.7-6c0.5-0.3,0.3-0.9-0.1-1.4
				c-2.9-3.6-2.7-8.3-1.9-11.9c1.7-7.2,1-14.3,0.8-21.3c-0.1-3.6,0.3-7.1,0.4-10.7c0-0.6,0.3-1.3,0.2-1.9c-0.8-4.2-0.6-8.6-0.5-12.7
				c0.2-4.3-1.5-8.4-0.7-12.8c0.3-1.7-0.4-4,0.5-5.9c0.3-0.8,0.1-1.4-0.1-2.2c-0.8-3.4-1-6.8-0.6-10.2c0.7-5.8,0.1-11.5,0.2-17.2
				c0-5.1-0.8-10.2-0.1-15.2c0.7-5.2-2-10.2-0.5-15.4c0-0.1-0.2-0.4-0.3-0.6C307.7,1963.3,306.6,1960.5,307.3,1957.4z M195.4,1440.9
				c-0.1,9.7-0.9,19.4-0.3,29.1c0.1,1.6,0.4,1.8,1.7,1.8c1.6,0.1,3,0,4.5-0.6c3.1-1.1,6.3-1.9,9.3-3.1c2.6-1,3.2-0.1,3.2,2.5
				c-0.1,5.6-0.7,11.3-0.1,17c0.2,2,0.8,2.1,2.3,1.9c1.1-0.2,2.2-0.2,3.2-0.5c5.6-1.6,11.1-3.2,16.7-4.8c4.4-1.3,4.6-1.8,4.6-6.5
				c0-25.8-0.1-51.6,0.6-77.5c0.1-3.9-1.4-5.1-5.2-3.8c-5.8,2-11.5,4.1-17.3,6.2c-1.8,0.7-3.9,0.6-4.6,3.5c-0.2,0.8-3.4-1.1-5.6,0
				c-2.7,1.4-5.7,2.4-8.6,3.1c-3.6,0.8-4.6,3.1-4.6,6.4C195.5,1423.9,195.4,1432.4,195.4,1440.9z M83.9,2060.2
				c-0.8,1.6,0.1,3.8-0.2,5.9c-0.5,3.2-0.9,6.5-0.6,9.7c0.4,4.7,1.2,9.4,0.4,14.2c-0.4,2.3-0.1,4.4,0.6,6.6c0.4,1.2,1.2,2.5,0.6,3.8
				c-2.1,4.9,0.1,9.7-0.1,14.6c-0.1,3.4,0.4,7,1.5,10.5c1,3.4,3,5.3,6.5,4.7c4.6-0.8,7.2-3.5,7.5-8.6c0.2-3.9,1.7-7.5,0.7-11.8
				c-0.6-2.5,0.7-6.3,1.5-9.4c0.9-3.1,2.2-6.8,1.2-9.5c-1.1-3.3-1.2-7-0.7-9.5c1-5.6-1.2-10.9,0.1-16.5c1.4-6.2-0.8-13,0.2-19.6
				c0.9-6.2-0.5-12.6-0.9-18.9c-0.3-4.1,0.8-8.6-1.6-12.5c-0.6-0.9,0-2.1,0-3c0-3.3,1-6.7-0.2-10c-0.5-1.4-0.1-2.3,0.9-3.1
				c0.4-0.3,1.4-0.6,1-1c-2.5-2.4-0.1-5.6-1.6-8.2c-0.9-1.4-0.4-3.1-0.2-4.7c0.3-3.9,0-7.8-0.8-11.7c-0.7-3.8-4.3-6-4.6-10
				c-0.1-1.6-0.3-3.4-0.6-5.1c-1.1-5.2-1.6-10.5-1.8-15.8c0-1.2,0.2-2.6-1.3-3.1c-0.9-0.3-2.6-0.5-2.7,0.2c-0.4,2.1-2.8,3.9-1.2,6.3
				c1.7,2.6,0.2,5.1-1.3,6.8c-1.4,1.6-1.7,3-1.1,4.7c1.4,3.5-0.1,6.8-0.9,10c-0.7,2.9-1.6,5.6-0.6,8.5c0.5,1.6,0.8,3.2,0.4,4.8
				c-0.8,3.2,0,6.4-0.1,9.6c-0.1,3.9-0.6,7.8-0.7,11.7c0,3.2-0.1,6.8-0.4,10.1c-0.2,2.6-1.1,5.5-0.3,8.1c1.8,5.7,1.2,11.5,0.4,17.2
				c-0.4,3-0.3,6.2-0.2,9.1C82.9,2050,83.6,2054.9,83.9,2060.2z M508.7,1787.7c2.5-3.3,3.8-7.1,5.5-10.7c3.8-8.2,7.5-16.4,11.2-24.6
				c4.6-10.3,9.1-20.6,13.6-31c0.5-1.2,0.5-2.3-1.1-2.2c-2.8,0.2-5.4-1-8.1-1.2c-13.6-0.7-27.3-1.3-40.9-2.1c-3.2-0.2-3.6,1.2-3,3.6
				c1.4,5.6,2.5,11.2,3.7,16.8c1.5,6.8,3.3,13.7,4.8,20.5c1.9,8.6,3.8,17.2,5.8,25.8c0.2,1,0.1,2.5,1.6,2.5c1.3,0,1.8-0.9,1.9-2.2
				c0.1-0.8,0.6-1.5,1.4-1.6c1-0.2,1.1,0.9,1.4,1.5C507.4,1784.4,508,1786,508.7,1787.7z M542.1,1783c-0.2,0-0.4,0-0.6,0
				c0-6.4,0.1-12.8,0-19.3c-0.1-6.6-0.1-6.3-6.7-7.1c-3.6-0.4-4.9,1-6.2,4c-6.7,15.3-13.5,30.6-20.3,45.8c-2.2,4.9-2.2,4.9,3.2,4.7
				c0.3,0,0.7,0,1,0c8.6-1.9,17.3-2,26-2.4c2.9-0.1,3.8-1.1,3.7-3.9C542.1,1797.6,542.1,1790.3,542.1,1783z M527.9,2189
				c4.4,0.3,6.9-2.7,5.8-6.9c-0.1-0.3-0.2-0.6-0.2-0.9c-0.6-3.8-3.4-5.9-7.2-5.5c-0.6,0.1-1.4,0.2-1.9-0.1c-3.2-1.4-6,0.5-8.9,0.9
				c-5.1,0.7-10.1,1.6-14.4-2.5c-1-1-2.3-1.6-3.5-2.4c-1.5-1-2.9-1.3-4.2,0.4c-1,1.4-2.3,0.9-3.8,0.5c-4.2-1-8.8-4.8-12.5,1.1
				c-0.2,0.4-1,0.5-1.5,0.7c-5.4,1.5-11.3,8.4-11.7,13.9c-0.1,0.6,0.1,1.3,0.3,1.9c1.9,5.3,5.1,6.6,10.4,4.2c1.9-0.9,3.9-1.2,6-1.6
				c3.8-0.6,7.7,0.3,11.6-0.9c1.9-0.6,4.1-2.1,6.3-1.2c3,1.2,5.9,0.3,8.7-0.5c3.5-1.1,6.9-1.9,10.2,0.4c1.5,1,2.9,1,4.6,0.2
				C523.7,2189.8,525.7,2188.9,527.9,2189z M285.4,2179.1c0.3,2.5,0.3,4.7,0.9,6.8c0.5,1.8,1.6,3.3,4.1,2.5c1.8-0.6,3.8-0.8,5.8-1
				c3.6-0.3,4.7-3,2.7-6c-0.5-0.7-1.3-1.5-0.3-2.2c0.7-0.5,1.8,0,2,0.5c1,2.2,2.3,0.7,3.7,0.8c3.4,0.1,7.8-2.4,10.2,2.4
				c0.4,0.8,1.3,0.5,2,0.1c3.2-1.8,6.4-3.3,10.1-1.7c0.3,0.1,0.6,0,0.9-0.1c5.1-1.4,10.3-0.7,15.4-0.6c7.7,0.1,12.8-4.9,10.7-12.3
				c-0.6-2-6.7-5.4-8.6-4.5c-1.4,0.7-2.4,0.5-3.7,0.2c-2.2-0.5-4.3-0.9-6.5,0.5c-1.4,0.9-2.9,0.7-4.6,0c-4.2-1.8-8.9-1.8-13.1-0.9
				c-4,0.9-7.5,1.5-11.3-0.2c-1.1-0.5-2.3-0.3-3.5,0.3c-5.8,2.7-6.3,3.9-3.6,10c0.3,0.7,0.7,1.6,0.1,2.1c-0.8,0.7-1.5-0.2-1.8-0.8
				c-1.5-2.6-3.9-3.5-6.4-2.2C288.2,2174.2,285.3,2175.7,285.4,2179.1z M937.8,2134c0.9,1.4,1.4,3.3,3.6,3c2.8-0.4,4.6-2.5,4.1-5.3
				c-0.6-3.3-0.2-6.1,3.1-7.8c0.6-0.3,0.9-0.9,0.4-1.2c-1.6-0.9-2.4-3-4.3-3.3c-3.6-0.5-7.2-1.6-10.9-1.3c-4.2,0.4-8.5,0.9-12.8,0.6
				c-2-0.1-2.7,0.7-1.8,2.5c1.3,2.9,1.4,5.8,1.3,8.9c-0.1,3.7,0.1,7.4,0.1,11.1c0,1-0.1,2,0.9,2.6c3.8,2.3,9.1,0.9,11.5-3.1
				c0.3-0.5,0.5-1,1.1-1c2.5-0.1,2.9-1.4,2.3-3.5C936.3,2135.4,936.8,2134.5,937.8,2134z M323,1839.2c1,2.9-0.7,6.9,3.9,8.5
				c1.5,0.5,1.9,0.7,1.9-1c-0.1-9.2-0.2-18.5-0.3-27.7c-0.2-10.8-0.4-21.5-0.6-32.3c0-1.2,1.4-3.3-1.5-2.7c-1.9,0.4-5.2-0.8-4.5,3.3
				c0.2,1.5,0.7,2.9,0.6,4.5c-0.2,5.8-0.3,11.7,0,17.5C323,1819.2,322,1829.2,323,1839.2z M590.6,1400.6c-0.9,2.4-2.6,4.7-4.9,4.5
				c-2.9-0.2-4,0.9-4.8,3.2c-0.7,2-1.9,3.2-4.2,2c-0.5-0.2-1.2-0.5-1.6-0.3c-3.3,1.5-7.3,0.9-10.4,3.3c-1.8,1.4-2.6,4.6-0.6,5.6
				c2.8,1.4,4.4,2.9,3.4,6.3c-0.5,1.8,1.6,1.7,2.6,2.2c0.6,0.4,1.7,0.8,1.8-0.4c0.1-2.1,2.1-2.6,3-4c0.7-1.1,2.3-3,4.9-1.4
				c1.6,1,4.1,0.5,5.4-1.8c1.1-2,1.1-5.1,2.3-6.2C592,1409.9,591.5,1405.4,590.6,1400.6z M98.1,1590.1c0,0,0.1,0,0.1,0
				c0,4.5-0.1,8.9,0,13.4c0.1,3.2,0.9,6.3,2.8,8.9c2.1,2.8,4.6,3,7.2,0.6c2.4-2.1,3.4-5,4.2-8c2-7.6,1-15.4,1.1-23.1
				c0.1-5.4,0.4-10.9-1.8-16.1c-2.2-5.1-6.5-5.6-9.7-1.1c-2.5,3.5-3.8,7.6-4,11.9C98,1581.1,98.1,1585.6,98.1,1590.1z M133,1562.3
				c0-4.6-0.3-8.4-2.6-11.7c-2.3-3.3-5.2-3.6-7.9-0.8c-6,5.9-7.6,19.6-3.1,26.6c2.5,3.9,6.1,4.2,9,0.5
				C131.9,1572.4,132.8,1567.1,133,1562.3z M271.6,1927.3c2.1-2.3,3.1-4.3,3.8-6.1c0.9-2.2,4.6-5,0.3-7.7c-1-0.7-0.1-0.9,0.4-1.2
				c1.2-0.9,0.9-2.2,0.6-3.3c-0.3-1.2-1.6-1-2.5-0.9c-1.4,0.1-2.8,0.6-4.2,0.6c-12.1,0-24.1,1.5-36,3.4c-1.1,0.2-2.5,0.1-2.3-1
				c0.4-3-1.7-2.1-3.1-2.2c-2-0.1-2.1,1-1.6,2.5c0.2,0.8-0.6,1.3-0.9,2c-0.8,1.8,0,2.5,1.7,2.3c6.5-0.9,13.1-1.7,19.6-2.8
				c4.4-0.8,8.5-0.1,12.8,0.4c2.5,0.3,4.9,0.9,7.3,0.7c2-0.2,2.2,0.5,2.4,2C270.4,1919.5,271,1923.1,271.6,1927.3z M160.6,1810.6
				c-2.4,3.1-3.3,3.1-5.1,0.5c-0.3-0.4-0.5-1.1-1.1-0.9c-0.6,0.1-0.9,0.6-1,1.2c-0.2,1.5-0.7,3-0.7,4.5c-0.1,8.8,0,17.5,0,26.3
				c0,1.5,0.6,2.2,2.2,2.2c1.2,0,2.4-0.1,3.6,0c2.2,0.3,2.8-0.6,2.8-2.8c-0.1-8.2-0.1-16.3-0.1-24.5
				C161.2,1815.1,161.5,1813.1,160.6,1810.6z M257.6,1774.3c0.2,1.5-1.2,1.1-1.9,1.6c-0.9,0.6-1.7,1.9-0.4,2.2
				c2.2,0.6,3.3,2.4,4.9,3.5c1.3,0.9,5.4,0.1,5.5-1.2c0.3-3.9,0.2-7.8,0.5-11.6c0.3-3.7-1-7.3-0.1-11.1c1-3.8-0.9-6.1-4.2-5.2
				c-2.1,0.6-4,0.5-6.3,0.2c0.5,1.1,0.7,2.4,1.3,2.7c6.7,2.8-1.4,3.1-0.8,4.8c0,0-0.2,0.1-0.3,0.2c-0.8,0.4-1.6,0.9-1.4,2
				c0.2,1.2,1.2,1.1,2.1,1.1c1.2,0,2.2,0.3,3.1,1.5c-3.2-0.3-2.7,3.2-4.7,4c1.6,1.5,0.1,2.5-0.6,3.6c-0.5,0.8-0.7,1.7,0,2.4
				c0.7,0.7,1.4,0,2-0.4C256.7,1774.1,257.1,1773.8,257.6,1774.3z M99.9,2203.5c-1-1.2-1-2.3-0.8-3.6c0.4-3.3,0.8-6.6,0.3-9.9
				c-0.4-2.6-2.3-4.4-3.3-6.6c-0.8-1.8-4-0.1-6,0.3c-1.9,0.4-0.1,1.4,0,2.1c0.1,0.6-0.2,1-0.7,1.4c-3.9,3.1-4.7,7.2-4.4,11.9
				c0.3,3.4,3.5,5.9,6.7,4.7C94.4,2202.6,96.9,2202.6,99.9,2203.5z M436.2,2134.1c-3.9,0.7-6.9-1.7-10.3-2.3
				c-3.4-0.5-6.8-1.3-9.4-3.3c-2.8-2.2-4-1.3-5.1,1.4c-0.7,1.6-1.5,2.1-3.5,1.8c-2.5-0.4-4.6-2.6-7.4-1.7c-0.6,0.2-1.4-0.2-1.7,0.5
				c-0.4,0.8,0.4,1.2,0.9,1.6c2.8,2.1,5.7,4,8.4,6.2c3.4,2.8,6.9,2.7,10.6,0.8c2-1,3.9-1.8,6.2-0.5c2.2,1.2,4.3,0.3,6.1-1
				C432.5,2136.3,433.8,2134.6,436.2,2134.1z M304,2152.7c1.7,0.6,2.7-0.5,3.9-2.2c1.8-2.5,1.9-5.4,3-8.1c1.2-3.1,0-4.3-2.4-4.6
				c-4.4-0.5-8.9-1.3-13.3,0.5c-2,0.8-4.2,4.3-3.5,6.2C293,2147.9,299.8,2152.6,304,2152.7z M85,2140.2c0.2,0.9,0.6,1.9,0.6,3
				c0.1,3.8,1.2,6.9,5.1,8.4c0.3,0.1,0.7,0.6,0.7,0.9c0.1,1.3,0.4,2.4,2.1,2.1c1.7-0.3,3.7-1.3,3.5-2.9c-0.2-1.9,0.8-3.2,1-4.9
				c0.2-1.5-0.3-3.3-0.2-4.6c0.1-2.1,0.5-4.6,1.8-6.6c0.9-1.4,1.4-2.1-1.1-2.7c-3.1-0.7-5.8,0.3-8.6,0.9
				C85.7,2134.7,85,2135.7,85,2140.2z M103.8,1916.8c-2.1-0.9-4.3-0.9-6.6-0.9c-6.6,0-13.1,0-19.7,0c-7.2,0-14.4-0.1-21.6-0.1
				c-3.2,0-2,2.8-2.3,4.4c-0.4,2.1,1.2,1.7,2.6,1.6c6.6-0.7,13.2-1.3,19.9-2C85.3,1918.9,94.6,1917.8,103.8,1916.8z M176.7,1909
				c0-0.2,0-0.4,0-0.5c-15.5,0-31-0.1-46.5,0.2c-2.2,0-6.3-1.6-6.8,3.2c-0.1,0.9-1.5,1.1-2.5,1.1c-0.9,0.1-2.3-0.4-2.3,1
				c0,1.6,1.6,1.3,2.5,1.2c3.9-0.4,7.8-1.1,11.7-1.5C147.4,1912.1,162,1910.5,176.7,1909z M345.4,1800.4c-0.4,0-0.9,0-1.3,0
				c0-5.2,0.1-10.4,0-15.6c0-1.3,0.7-3.6-1.1-3.6c-2.8,0-0.9,2.3-0.9,3.5c-0.8,20.4,1.2,40.7,1.3,61c0,0.9-0.6,2.6,1.3,2.4
				c1.3-0.1,1.5-1.5,1.3-2.6c-1-4.8-0.2-9.7-0.4-14.5C345.1,1820.9,345.4,1810.6,345.4,1800.4z M549.6,2184.3c0.9-1.5-0.6-3.1,1-3.9
				c1.8-0.8,2.3-2.5,1.5-3.8c-1-1.6-2.7-4-4.1-4c-5.2-0.1-10.4-1.5-15.8-0.6c1.3,2.8,3.3,4.9,3.8,7.8c0.1,0.8,0.8,0.9,1.5,1
				C541.9,2181,546.2,2181.1,549.6,2184.3z M802.9,1801c-0.8-1.8-2-1.9-3-2c-7.2-0.8-14.4-0.3-21.7-1.2c-7.4-1-15.1-0.1-22.7,0.8
				c-2.3,0.3-1.9,1.9-2,2.6c-0.2,1,1.3,0.5,2.1,0.7c1.1,0.2,2.2,0.2,3.3,0.1c9.5-0.5,19-1,28.5-1C792.4,1801,797.5,1801,802.9,1801z
				 M294.4,1896.8c1.8,1.1,3.8,0.6,5.7,0.6c2.8,0.1,4.2-1.9,2.9-4.3c-0.9-1.7-2.8-1.9-4.6-1.8c-4.5,0.1-9,0.3-13.6,0.3
				c-6.4,0-12.8-0.2-19.2-0.5c-3.1-0.2-2.5,2.1-2.9,3.6c-0.4,1.6,0.1,2.4,2.1,2.7c3.1,0.6,6.1-0.4,9.1-0.2c0.7,0.1,2,0.5,1.9-0.8
				c-0.1-1-1.1-0.4-1.9-0.4c-2.6,0.2-5.2,0.2-7.9,0.2c-1.2,0-1.8-0.6-1.9-1.8c0-1,0.4-1.9,1.5-1.8c3.1,0.3,6.5-1.3,9.4,1.3
				c0.5,0.4,1.3,1.2,1.7-0.1c0.2-0.8,0-2,1.4-1.6c1.2,0.3,1.3,1.9,1.3,2.4c-0.6,3.9,2.2,2.6,4,2.7c0.9,0.1,1.8-0.1,2.6,0.1
				c1.7,0.5,2.2-0.1,2-1.7c-0.3-1.9,0.4-2.7,2.4-3.3c2.8-0.8,1.9,2,2.9,2.6c1.1-0.6-0.8-2.8,1.3-2.3c1.6,0.4,2.9,0.3,4.4-0.1
				c1-0.2,1.7,0.4,1.7,1.5c0,0.9,0,1.8-1.3,1.8C297.8,1895.9,296.2,1897.2,294.4,1896.8z M379,1675.9c-0.4-0.8-1-0.8-1.5-0.8
				c-5.2-0.4-10.4-1.1-15.6-1.2c-4.8-0.2-10.1-1.1-12.5,4.9c-0.3,0.8-1,0.5-1.7,0.5c-1.3-0.1-0.9,1-0.9,1.6c0,0.8,0.6,1.5,1.2,1.2
				c2.1-1.4,4.4-0.2,6.5-0.8C362.4,1678.7,370.7,1677.6,379,1675.9z M322.2,1755.9c0.1,7.9,0.3,15.5-1.4,22.9
				c-0.1,0.6-0.4,1.2,0.2,1.4c1.8,0.5,3.7,0.5,5.5,0c1.6-0.5,1.1-1.9,1.1-3.1c0.1-6.2,0.1-12.3-0.6-18.5c-0.3-2.6-0.6-3.6-3.3-2.8
				C323.2,1756,322.6,1755.9,322.2,1755.9z M205.9,1925.2c-11.2-0.3-22.3-1.5-33.4-0.2c-0.9,0.1-1.8-0.1-2.6,0.9
				c-1.9,2.4-4.1,2.4-6.1,0c-1.1-1.4-2-0.4-2.8,0c-0.7,0.3-1.9,0.6-1.5,1.8c0.3,1.2,1.4,1.1,2.4,1.2c4.1,0.3,8.2,0.5,12.3,0
				C184.7,1927.2,195.3,1926.3,205.9,1925.2z M751.5,1813.7c0,0.3,0.1,0.5,0.1,0.8c18.7-0.1,37.4-1.9,56.2-0.7c0-1.3-0.5-1.7-1-1.8
				c-15-1.8-30,0.1-45.1-0.8C758.4,1811.1,754.9,1812.8,751.5,1813.7z M55.6,1736.5c0-0.6,0.5-0.6,0.9-0.7c1.1-0.2,2.2-0.5,2.1-2
				c-0.1-1.7-1.4-1.2-2.4-1.4c-0.9-0.2-1.9-0.6-2.8-0.9c-7.2-2.3-10.3-0.5-9.7,5.8c0,0.1,0,0.2,0,0.3c0,1.3,0.7,1.9,2,2
				c2.9,0.2,5.9,0.5,8.8,0.6c1.7,0.1,3.9,0.3,4.2-2C59,1736.1,56.3,1737.9,55.6,1736.5z M753.7,1807.7c1.5,0.2,2.9,0.7,4.2,0.6
				c14-0.6,28-2,42-0.8c1.6,0.1,4.4,1.2,4.7-1c0.1-1.3-3.1-1.3-4.8-1.3c-11.8,0-23.6-0.6-35.3,0.4c-1.5,0.1-3.1,0.5-5.3-0.8
				c2.8-0.2,4.6-0.3,6.5-0.4c-3.4-0.7-6.7-0.5-10,0.4c0.4,0.9,2-0.3,2.2,1.2C756.6,1806.8,754.8,1805.9,753.7,1807.7z M702.9,2143.3
				c-1.1-3-0.8-5.3-0.9-7.4c0-0.9-0.1-1.8-1.3-1.8c-1.2,0-1.5,0.8-1.4,1.7c0,2.8,0.1,5.6,0.2,8.5c0.1,1.6,0.8,1.8,2.4,1.6
				c2.4-0.3,4.8-1.2,7.4-0.8c1.2,0.2,2.1-0.4,2-1.9c-0.1-2.6-0.2-5.2-0.2-7.8c0-1-0.4-1.7-1.5-1.8c-1.3-0.1-1.2,1-1.2,1.8
				c0,1.6,0.1,3.3,0.2,4.9c0,0.8,0.1,2-1.1,2c-1,0-0.7-1.1-0.8-1.8c-0.1-1.5-0.1-3-0.1-4.6c0-1.1-0.4-2.1-1.5-2.1
				c-1.4,0-1.2,1.3-1.2,2.2C703.6,2138.1,704.4,2140.3,702.9,2143.3z M364.4,1366.3c-1.4,0.1-1.9-1.4-2.8-0.6
				c-0.7,0.6-0.4,1.4-0.2,2.1c0.4,1.1,1.2,1,2.3,1c2.5-0.1,4.8-0.8,7.4-0.4c1.8,0.2,3.4-1.3,2.8-3.4c-0.9-2.8-0.9-6-4.5-7.7
				c-2.3-1.1-3.7-0.7-5.3,0.3c-1.8,1.2-2.1,2.9-1.1,4.9C363.5,1363.6,363.9,1364.9,364.4,1366.3z M454,1906.5
				c1.7-0.6,1.8-1.8,1.4-3.1c-0.8-2.8-0.3-5.6-0.5-8.4c-0.1-3.1,1.1-6.5-2.7-8.5c-0.7-0.4-1.2-1.4-2.2-1.3c-1.2,0.1-1,1.4-1.1,2.1
				c-0.9,5.2,0.3,10.4-0.1,15.6c-0.1,1.1,0.7,1,1.6,0.7C454.2,1902.4,454.3,1902.6,454,1906.5z M376.7,1710
				c-3.3-1.5-4.3-3.8-3.7-6.8c0.3-1.5,0.6-2.2,2.5-2.1c3.2,0.3,5.1-2,5-5.3c-0.1-1.6-0.6-2.1-2.2-1.9c-3.2,0.5-6.4,1-9.6,1.4
				c-0.7,0.1-1.2,0.2-1.3,1.1c0,1.9-0.3,3.9-0.1,5.8C368.1,1709.4,369.2,1710.3,376.7,1710z M115.5,2127.8c-1.3,0-2.5-0.2-3.5,0
				c-2.3,0.5-1.2,2.4-1.4,3.7c-0.1,0.9-0.2,2.2,1.4,2c1.4-0.2,2.8-0.3,4.1-0.8c3.8-1.4,6.2-0.8,6.7,2.9c0.5,3.5,2.6,2.9,4.6,2.6
				c1.7-0.2,3.2-1.3,5-1.4c0.7-0.1,1.7-1.2,0.7-1.4c-2.3-0.6-3.6-3.3-6.3-3c-0.8,0.1-1.5-0.2-2.3-0.7
				C121.6,2130,119.2,2126.9,115.5,2127.8z M1013.8,1796.8c-10.3-1.2-20.8-0.5-31.2-1.3c2.6-1.5,5.4-0.4,8.6-1.3
				c-7.2-0.1-13.8,0.4-20.4,1.2c-1.8,0.2-1.8,1.6-1.9,2.2c-0.2,1.1,1.2,0.8,1.9,0.8c3.4-0.1,6.8-0.4,10.1-0.5
				C991.9,1797.4,1002.9,1796.8,1013.8,1796.8z M967.7,1757.3c1.2,1,2.5,0.8,3.7,0.7c10.4-0.4,20.7-1,31.1-1.3
				c3.6-0.1,7.2,0.3,10.8,0.5c0.6,0,1.5,0.2,1.4-0.8c-0.1-0.7-0.4-1.5-0.8-1.9c-0.4-0.4-1-0.1-1.2,0.6c-0.4,1.3-1.3,0.7-2.2,0.6
				c-11.4-1.1-23,1.3-34.3-1.4C972.7,1753.4,971.1,1757.8,967.7,1757.3z M968.6,1781c15.3,0.7,30.5-2,45.7-0.8
				c-11.4-2.4-23-1.3-34.9-1.2c2.7-2.1,5.6-0.9,9-1.7c-4,0-7.4-0.8-10.2,0.2C975.1,1778.7,971.9,1779.8,968.6,1781z M688.8,2063.9
				c0,0,0.1,0,0.1,0c0-1.5,0.1-3.1,0-4.6c-0.2-2-3.3-4.4-4.3-3.3c-1.5,1.6-3.8,3.7-3.3,5.6c0.7,2.6-1.1,5.5,1.3,7.9
				c0.8,0.8,0.7,2.6,2.5,2.1c1.8-0.4,3.4-0.9,3.4-3.3C688.5,2066.9,688.7,2065.4,688.8,2063.9z M857.4,2131.1c-1.2-3-0.7-5.3-1-7.6
				c-0.2-0.9-0.5-1.8-1.6-1.6c-1,0.2-0.9,1-0.9,1.8c-0.2,9.2-0.2,9.2,8.8,8.6c0.3,0,0.7,0,1-0.1c0.8-0.2,1.3-0.7,1-1.4
				c-1-2.4-0.3-4.9-0.5-7.4c-0.1-1-0.3-2-1.3-2c-1.3-0.1-0.9,1.2-1,2c-0.2,2.1,0.5,4.4-0.7,7c-1.1-2.6-0.6-4.8-1-6.8
				c-0.1-0.9-0.2-2-1.4-1.9c-1.2,0.1-0.8,1.3-0.9,2.1C858,2125.9,858.8,2128.2,857.4,2131.1z M161.2,1855c0.1-5.3,0.1-5.2-5-5.4
				c-2.8-0.1-4.2,0.4-3.6,3.6c0.3,1.8-0.4,3.9,0.3,5.4c0.9,2,3.4,0.4,5,1.1c2.9,1.2,3.4-0.3,3.2-2.7
				C161.2,1856.3,161.2,1855.7,161.2,1855z M754.9,1789c14.1,0.2,28.1-0.8,42.2-1C783,1787.3,768.9,1786.1,754.9,1789z
				 M1101.7,1891.7c3.4,3.4,3.4,3.4,11.1,3.2c3.4-0.1,3.7-0.5,2.8-3.9c-1-3.8-2.9-7.2-4.8-10.6c-0.9-1.7-1.7-1.7-2.8-0.1
				c-1.7,2.6-2,5.6-3.2,8.4C1104.3,1890.1,1104.1,1891.8,1101.7,1891.7z M241,1920.9c-1.3,0.4-2.5,0.4-3.7,0
				c-0.9-0.3-1.9-0.7-2.1,0.6c-0.3,1.5,1.1,1.3,2,1.4c4,0.4,7.9-0.8,12-0.9c1.1,0,2.1-1,3.2-1.6c1.2-0.7,1-1.9,0.8-2.9
				c-0.2-1.4-1.3-0.5-2.1-0.6c-4-0.7-7.9,0.8-11.9,0.8C239.2,1919,240.8,1919.3,241,1920.9z M916.2,2077c0.3,1.1-0.8,2.7,1,3.5
				c2.1,0.9,4,2.1,6.2,2.8c1.4,0.5,2.4,0.1,2.5-1.9c0.3-5-0.8-6.5-5.8-7.4C916.1,2073.3,916.1,2073.3,916.2,2077z M877.2,2130.9
				c3.4,0,4.9-1.7,4.3-5c-0.2-1.3-0.4-2.5-0.5-3.8c-0.1-1.1-0.5-3-2.3-2.3c-1.5,0.6-4.3-0.7-4.2,2.7c0.1,2.3,0.6,4.5,0.7,6.7
				C875.2,2130.6,875.8,2131.1,877.2,2130.9z M1012.7,1762.7c0-0.2,0-0.3,0-0.5c-0.5-0.1-1-0.2-1.5-0.3c-9.4-1.2-18.8-0.8-28.1-0.4
				c-4.6,0.2-9.4-0.3-14,1.1c-0.5,0.2-1.5-0.2-1.4,0.8c0.1,0.8,0.7,1,1.4,1c1.5,0,3.1,0,4.6-0.1c7.2-0.4,14.2-1.8,21.4-1.7
				C1001,1762.8,1006.9,1762.7,1012.7,1762.7z M508,1816.9c1.6,0,2.3,0,3,0c9.3-0.4,18.6-0.9,27.9-1.4c0.8,0,2.2,0.4,2.2-0.7
				c0-1.4-1.5-0.9-2.3-0.9c-8.3,0.2-16.5,0.3-24.8,0.6C512.1,1814.5,510.1,1814.7,508,1816.9z M320.3,1680.4c0,2-0.1,3.9,0,5.9
				c0.1,1.3-1,3.3,1.8,3.2c2.2-0.1,3.2-1.1,2.9-3.3c-0.6-4.5-0.3-9.1-1.3-13.6c-0.3-1.2-0.8-1.7-2-1.7c-1.4,0-1.5,0.9-1.5,2
				C320.3,1675.4,320.3,1677.9,320.3,1680.4z M541.7,1821c-0.5-0.8-1.1-0.7-1.6-0.7c-9.4,0-18.8,0.3-28.1,1.1
				c-0.9,0.1-2.5,0-2.4,1.1c0.1,1.6,1.7,0.9,2.6,0.9c9-0.4,17.9-0.9,26.9-1.4C540,1822,541,1822.2,541.7,1821z M46.2,2050.6
				c-0.3-2.1-0.5-4.1-3.5-4.2c-2.4,0-3.7,0.9-3.7,3.4c0,0.7,0,1.4,0.8,1.6c0.6,0.2,1-0.2,1.4-0.6c0.6-0.8,0.9-2.5,2.3-1.5
				c1.4,1.1,0.3,2.5-0.6,3.4c-1.7,1.8-2.9,3.8-3.8,6c-1.2,2.7,0.2,4.8,3.1,4.6c1.3-0.1,2.6-0.4,3.8-0.8c1.3-0.4,1.2-1.4,1-2.4
				c-0.2-0.9-0.9-1.2-1.7-0.8c-0.9,0.4-1.9,1.8-2.8,0.6c-1-1.3,0.1-2.6,1-3.8C44.6,2054.4,45.8,2052.7,46.2,2050.6z M1012.7,1768
				c-14.5-1.3-28.9-1.3-43.6,0.2C970.9,1769.7,1002.7,1769.6,1012.7,1768z M332.6,1779.7c2.3,1,4.1-0.1,5.8-0.1c2,0,4-0.4,5.6-1.5
				c1.3-0.9-0.5-2.1-0.5-3.5c0.2-5-0.1-10-0.3-14.9c0-0.9-0.1-2-1.2-1.9c-1.1,0.1-0.7,1.2-0.7,1.9c0,4.9-0.1,9.8,0,14.7
				c0.1,2.2-0.7,3-2.8,3.5C336.8,1778.3,334.6,1777.9,332.6,1779.7z M911.1,1696.8c-0.1,1.1,0.4,1.4,0.9,1.5
				c4.4,0.6,6.8,2.6,6.4,7.4c-0.1,1.6,0.3,3.5,0.4,5.5c3.9-4.1,0.4-9.7,4.5-13.8C918.9,1697.2,915.1,1697,911.1,1696.8z
				 M392.1,1857.6c3-0.4,6.2-0.9,9.5-1.2c1.3-0.1,1.7-0.7,1.7-1.9c0-1.4-0.6-2-2-2c-2.5,0-5-0.1-7.5-0.1c-1.6,0-2.8,1-3.5,2
				c-0.5,0.7-1.9,1.6-1.1,2.8C389.7,1858.1,390.9,1857.4,392.1,1857.6z M773.2,2009c-2.2,3.2,0,6.2-0.7,9.2c-0.9,3.8-0.1,7.9-1,11.8
				c-0.2,0.9-0.2,2.1,1.2,2.2c1.5,0.1,2-0.9,2-2.1c0-3.5,0-7-0.4-10.4c-0.2-2.4-0.3-4,2.7-2.1c0.6,0.4,1.3,0.4,1.6-0.4
				c0.3-0.8-0.2-1.4-0.9-1.4c-2.2,0-2.6-1.5-2.9-3.2C774.4,2011.5,773.8,2010.5,773.2,2009z M716.2,2135.9c0.6,1.3-2.9,2.1-0.5,3.6
				c0.8,0.5,1.7,0.9,1.1,2c-0.6,1.2-1.5,0.4-2.2,0.1c-0.6-0.2-1.3-0.5-1.7,0.2c-0.4,0.7,0,1.3,0.4,1.8c1,1.3,2.5,1.4,3.9,0.9
				c1.6-0.6,3-2,2.5-3.7c-0.6-1.9-0.6-3.7-0.6-5.6c0-2.1-1.8-2.6-3.6-2.4c-1.5,0.1-3.1,1.1-2.7,2.7
				C713.2,2137.8,715,2135,716.2,2135.9z M98.2,1776.1c-0.1,0-0.1,0-0.2-0.1c0.2-1,0.6-2.1,0.7-3.1c0.9-8.1,1.6-16.2,2.5-24.3
				c0.1-1.2-0.1-2-1.3-2.1c-1.6-0.1-1.7,1-1.7,2.2C98.2,1757.8,98.2,1767,98.2,1776.1z M525.9,1826.9c-4.3,0-8.5,1.3-12.7,1.1
				c-0.8,0-2-0.1-2,1.1c0,1.2,1.2,1,2,1c8.9,0.3,17.8-1.8,26.7-1.4c0.7,0,1.8,0.2,1.8-0.9c0-1-1.1-0.9-1.8-0.9
				C535.3,1826.9,530.6,1826.9,525.9,1826.9z M745.4,2131.2c-0.3,0-0.9,0.1-1.5,0.3c-1.2,0.5-2.7,1.1-2.3,2.6c0.4,1.3,1.6,1,2.7,0.3
				c0.4-0.2,1.1-0.3,1.4,0.3c0.3,0.6-0.2,1-0.5,1.5c-0.8,1.7-2.8,2.5-2.9,4.6c-0.1,1-0.7,2.6,1.1,2.2c1.9-0.4,4.3,1,5.6-1.4
				c0.1-0.2-0.2-1.1-0.4-1.1c-4.2-0.4-1.7-2.2-0.8-3.8C749.2,2134.1,748.2,2131.2,745.4,2131.2z M873.3,2126.6
				c0.1-4.4-0.8-5.9-3.3-5.9c-2.9,0-4.1,1.4-4.1,5c-0.1,4.1,1,5.7,3.5,5.8C872.6,2131.6,873.3,2130.7,873.3,2126.6z M756.8,2136.8
				c0-0.4,0.1-0.9,0-1.3c-0.6-1.8,0.1-4.2-3.2-4.4c-3.4-0.2-3.4,1.9-3.6,4.1c-0.2,1.8,0.1,3.5,0.3,5.2c0.2,1.3,0.9,1.8,2.2,1.9
				C755.8,2142.5,756.7,2141.4,756.8,2136.8z M845.8,2102.7c1.4-0.2,2.1-2.2,3.4-0.7c1.5,1.8,1.3,5.3-0.4,7.6c1,0.6,2,1.1,3.2,1.8
				c0.1-0.9,0.1-1.6,0.2-2.2c0.4-1.6-1.6-4.2,2-4.4c0.1,0,0.3-0.4,0.3-0.4c-1.3-1.4,0.1-3.6-1.3-4.9c-1.4-1.3-2.1,0.2-3,0.8
				c-0.2,0.1-0.6-0.1-0.9-0.2C846.9,2098.8,846.2,2100.2,845.8,2102.7z M569.3,1474.7c3,0.1,3.5-2.3,3.9-4.4
				c0.3-1.6-2.8-4.1-4.8-4.2c-2.6,0-2.9,2.2-3.2,3.8C564.8,1471.9,567.5,1474.7,569.3,1474.7z M197.1,1909.4
				c1.1,1.2,1.9,2.1,1.6,3.5c-0.4,2.2,0.8,2.9,2.8,2.6c1.2-0.1,2.4,0,3.6,0.1c1.5,0.1,2.5-0.5,3-1.9c0.2-0.5,0.6-1.1,0-1.4
				c-1.3-0.7-2.5-0.4-3.5,0.6c-0.4,0.4-0.9,1.1-1.6,0.6c-0.6-0.5-0.3-1.2,0-1.8c1.2-2.6-0.5-2.9-2.3-3
				C199.5,1908.8,198.3,1909.2,197.1,1909.4z M257.6,1832.8c0.3,0,0.7,0,1,0c2.7,0,4.8-1,5.3-3.9c0.4-2.8-1-4.7-3.7-5.7
				c-0.7-0.2-1.2-0.5-1.8,0c-0.6,0.5-0.7,1.1-0.4,1.8c0.4,1,1.5,0.8,2.2,1.1c1.3,0.5,1.7,1.5,1.2,2.6c-0.8,1.8-1.5,0.1-2.1-0.3
				c-1.6-0.9-3-0.3-4.3,0.5c-0.9,0.6-1.5,1.7-0.9,2.8C254.9,1833.4,256.3,1832.8,257.6,1832.8z M592.9,1353.9
				c-9.8-0.8-19.2-3.7-28.3,2.9C573.9,1353.4,583.3,1354.9,592.9,1353.9z M606.4,1878.3c0,2.6-0.2,4.9,0.1,7.3
				c0.3,2.8,2.5,2.2,4.3,1.9c2.3-0.4,1.1-2,0.7-3c-0.8-2.1-1.9-4.1-2.9-6.1c-0.5-1-0.7-2.7-2.2-2.3
				C605,1876.4,607.2,1877.7,606.4,1878.3z M978.5,1925.4c0-1.8,0-3.6,0-5.4c0-1.4,0.5-3-1.9-2.9c-1.8,0.1-3.9-0.2-3.7,2.8
				c0.4,4.4,0.6,8.9,0.9,13.3c0,0.5,0.4,0.7,1.1,0.7c1.1-4.6-0.2-9.6,0.8-14.3C976.4,1921.6,976,1923.8,978.5,1925.4z M892.5,2122.1
				c-0.1-1.8-0.5-3.2-2.2-3.5c-1.5-0.3-2.8,0.1-3.6,1.7c-0.2,0.5-0.3,1.1,0,1.5c0.4,0.5,0.9,0.6,1.4,0.2c0.6-0.4,0.9-1.6,1.8-0.7
				c0.9,0.9,0,1.9-0.5,2.6c-1,1.2-1.9,2.4-2.4,3.9c-0.2,0.6-0.4,1.5,0.3,1.8c1.6,0.6,3.2-0.1,4.8-0.3c0.6,0,0.9-0.6,0.8-1.2
				c0-0.3-0.3-0.8-0.5-0.8c-3.7-0.4-1.1-2-0.7-3.3C892.1,2123.3,892.3,2122.6,892.5,2122.1z M518,2127.1c-0.3,0.2-0.8,0.3-1,0.6
				c-0.3,0.5-0.1,1.2,0.4,1.4c1.6,0.4,2.5,1.7,2.9,2.9c1.5,3.5,4.3,4.1,7.6,4.3c1.3,0.1,2.7-0.3,3.8,0.7c0.6,0.5,0.9,0.1,1.2-0.4
				c0.3-0.5,0.5-1.1-0.1-1.4c-0.9-0.4-1.8-0.8-2.7-1c-1.2-0.4-3.3-0.2-3.7-1c-1.6-3-6-2.2-7.1-5.6
				C519.2,2127.3,518.6,2127.3,518,2127.1z M260.9,1612.2c1.6-0.1,2.8-0.1,3.9-0.3c1.3-0.3,1.7-1.2,1.2-2.4c-0.9-2-7.6-3-8.8-1.2
				c-0.8,1.3-1,2.9,0.9,3.8C259.1,1612.4,260.2,1612.4,260.9,1612.2z M590.1,1469.2c0-2.2-2.3-4.7-4.2-4.4c-2,0.2-3,1.6-3.2,3.6
				c-0.2,2.4,1.5,4.3,3.8,4.3C589,1472.7,589.7,1470.9,590.1,1469.2z M541.7,1848.2c0-0.3,0-0.5,0-0.8c-0.3-0.1-0.6-0.4-0.8-0.4
				c-7.8,0.2-15.7,0.4-23.5,0.7c-0.8,0-1.7,0.1-1.6,1.1c0.1,1,1.1,1,1.8,0.9c5.1-0.3,10.3-0.5,15.4-0.9
				C535.8,1848.7,538.8,1848.4,541.7,1848.2z M1093.2,1891.8c-2.2-1.8,0.1-4.4-1.2-6.1c-1.6-2-2.4-3.7-0.8-6.1
				c0.7-1.1,0-1.9-1.1-2.1c-1.1-0.2-1.5,0.6-1.6,1.6c-0.1,1.4-0.2,2.8-0.4,4.2C1087.5,1887.2,1089.7,1890.9,1093.2,1891.8z
				 M47,2035.1C47,2035.1,47,2035.1,47,2035.1c0-1.1,0.1-2.2,0-3.3c0-0.8,0.2-2-1-2.1c-1.1-0.1-1.1,1-1.3,1.9
				c-0.3,1.3,0.6,3.6-1.2,3.6c-1.8,0-0.7-2.3-1-3.5c-0.2-0.6-0.2-1.4-1.1-1.3c-0.8,0-1,0.7-1.2,1.4c-1,3,0.2,5.4,3.1,6.7
				c0.4,0.2,0.7,0.5,0.9,0.8c0.4,0.7,0.4,2,1.5,1.7c1-0.3,1.1-1.6,1.1-2.5C47,2037.3,47,2036.2,47,2035.1z M577.4,1485.4
				c0.8,0.1,1.7,0.5,2-0.6c0.2-1-0.7-1.2-1.5-1.3c-1.6-0.1-2-1-2-2.4c0-1.3-0.2-2.6-0.3-3.9c-0.1-0.9,0.5-2.4-0.9-2.3
				c-1.2,0.1-0.8,1.6-0.7,2.4c0.3,3.9-0.2,7.6-2.3,11.1c-0.2,0.3-0.2,0.9,0,1.2c0.3,0.6,0.9,0.3,1.4,0.2c1.3-0.4,2.5-0.7,2.1-2.6
				C574.9,1485.5,575.8,1484.8,577.4,1485.4z M935.4,1674.5c-3.2-4.5-7.3-3.6-11.4-2c-0.9,0.3-2.4,0.5-2.2,1.6
				c0.2,1.4,1.8,1,2.8,0.8c2.8-0.6,5.6-0.6,8.4,0.1C933.7,1675.1,934.7,1675.3,935.4,1674.5z M536.2,1932.4c0-1,0-2,0-2.9
				c0-0.8,0-1.7-1.1-1.6c-0.9,0.1-0.9,1-1,1.8c-0.3,4.5,0.8,8.9,1.7,13.2c0.2,1.1,0,2.9,1.6,2.7c1.5-0.2,0.8-2.1,0.7-3.1
				C538,1939,535.8,1935.9,536.2,1932.4z M739.5,2137.5c-0.1,0-0.2,0-0.3,0c0-1.5,0-3,0-4.6c0-0.8-0.4-1.8-1.3-1.3
				c-1.5,0.8-2.7,2.3-3.3,3.8c-0.6,1.5,1.1,1,1.9,1.4c0.7,0.4,0.6,1.1,0.6,1.7c0,1.1,0,2.2,0,3.3c0,0.7,0.2,1.4,1,1.4
				c0.9,0.1,1.5-0.4,1.5-1.3C739.6,2140.5,739.5,2139,739.5,2137.5z M723.9,2142.5c-0.1-2.8-0.3-5.1-0.3-7.3c0-1.1,0-2.4-1.4-2.4
				c-1.6,0.1-1.1,1.5-1.1,2.5c0,1.5,0.2,3,0.3,4.5c0.2,4.7,0.2,4.7,4.8,4.1c0.1,0,0.2,0,0.3,0c1-0.2,1.9-0.7,2.1-1.8
				c0.1-0.4-0.1-1-0.3-1.1c-0.9-0.5-1.6,0.1-2.4,0.5C725.3,2141.7,724.8,2142,723.9,2142.5z M923.2,2069.5c-5.3-1.4-5.3-1.4-4.9-6.9
				c0-0.3,0.1-0.7,0-1c-0.1-0.3-0.3-0.6-0.6-0.7c-0.5-0.3-0.9,0-1,0.4c-1,2.8-1.8,5.6-1.1,8.5c0.3,1,1.3,2,2.5,1.6
				C919.8,2071,921.6,2071.2,923.2,2069.5z M923.9,2028c0.7-2.9-0.8-3.7-3.3-3.6c-1.3,0.1-3.2-0.4-2.9,1.9c0.3,2.7,2.4,4.2,4.7,4.7
				C924.7,2031.3,923.5,2029,923.9,2028z M959.9,1786.7c-1.5,0-5.1,3.1-4.8,4c0.7,2.5,2.5,0.5,3.8,0.7c0.8,0.2,1.7,0,2.6-0.1
				c1.9-0.1,2.6-1.9,2.9-3.1c0.2-1.1-1.6-0.6-2.4-0.9C961.2,1787.1,960.3,1786.9,959.9,1786.7z M378.8,2038.9
				c-0.9,0.8-3.4,0.2-3.3,2.8c0.1,2.1-0.2,5.2,1.8,5.8c1.7,0.5,2-2.5,2.4-4.2c0.3-0.9,0.6-1.9,0.9-2.8
				C380.9,2039.3,380.3,2038.9,378.8,2038.9z M964.2,1765c-3.8-1.4-3.8-1.3-8.3,0.3c-1.3,0.5-1.9,1.1-1.7,2.3c0.2,1.4,1.5,1.1,2.4,1
				C959.5,1768.3,962.4,1767.7,964.2,1765z M195,2152.2c-0.1-0.2-0.2-0.6-0.3-0.7c-2.5-0.2-4.9-2.2-7.6-0.9c-1.3,0.6-2.9,1.1-2,3
				c0.8,1.8,2.2,1.7,3.8,0.8c1.3-0.7,2.4-2.4,4.3-0.8C194,2154.4,194.6,2153.2,195,2152.2z M582.5,1475.8c-1.2-0.2-2.1,0.1-2.4,1.2
				c-0.5,2.2-0.6,4.6,0.1,6.7c0.5,1.6,2.1,2.1,3.7,2c1.1-0.1,2.1-0.6,2-1.7c-0.1-1.4-1.2-0.8-2-0.5c-1.1,0.5-1.7-0.2-2-1.1
				c-0.6-1.6-0.8-3.3,0.2-4.7c0.8-1.2,1.5,0.1,2.1,0.5c0.5,0.4,1,0.6,1.4,0c0.4-0.5,0-1-0.3-1.5
				C584.6,1476,583.5,1475.8,582.5,1475.8z M963.7,1771c-2.9-2.5-5.5-0.3-8.1-0.2c-0.9,0-1.1,1.5-1.2,2.4c-0.1,0.7,0.5,1.2,1.2,1.3
				c2.1,0.2,4-1.4,6.3-0.9C962.8,1773.8,962.8,1771.7,963.7,1771z M964,1776.6c-2.8-2.7-5.2,0-7.8,0.3c-0.9,0.1-1.9,1.3-1.4,2.6
				c0.5,1.4,2,1.2,2.8,0.6C959.7,1778.7,963.2,1780,964,1776.6z M337.5,1772c0.9-2.8,1.4-7.5,1.2-9.3c-0.1-0.7-0.2-1.3-0.9-1.6
				c-1.1-0.5-7.3,1.3-8.7,2.6c0.6-0.1,1.1-0.2,1.5-0.2c6.1-1.5,6.8-1,6.9,5.2C337.5,1769.7,337.5,1770.7,337.5,1772z M219.7,2049.7
				c-1.1,0.1-2.1,0.2-3.1,0.3c-0.7,0-1.4,0.1-1.5,0.9c-0.1,0.9,0.6,1.4,1.4,1.6c0.9,0.2,1.7,0.1,2.5-0.3c0.9-0.4,1.9-0.6,2.6,0.5
				c0.3,0.6,0.6,1.3,1.4,0.9c1-0.5,1-1.6,1.1-2.6c0.1-0.8-0.7-1-1.4-1C221.6,2049.9,220.6,2049.8,219.7,2049.7z M233.9,1747.7
				c0,0.3,0,0.6,0,0.9c6.9,0.9,13.8,2.3,21.8,0.4C247.7,1748.5,240.8,1748.1,233.9,1747.7z M366,1909.7c0-0.3-0.1-0.6-0.1-0.9
				c-5.1-0.2-10.3-0.9-15,2.5C355.9,1910.8,361,1910.2,366,1909.7z M699.1,1736.6c2-1.4,3.5-3.4,2.4-5.4c-0.9-1.6-3.5-0.7-5.1-0.2
				c-1.8,0.6-1.4,2.2-0.6,3.8c0.8-0.8,0.3-2.4,1.8-2.2C699.8,1733,698.3,1735.1,699.1,1736.6z M527.1,2194.1
				c1.1,1.3,2.1,1.7,2.9,2.6c1,1.1,2.8,0.8,3.9,0.2c1.4-0.8-0.1-1.9-0.3-2.8c-0.5-1.7-1.6-1.7-3.1-1.1
				C529.6,2193.5,528.6,2193.7,527.1,2194.1z M438.1,1679.9c1.1,2.4,3,1.3,4.4,1.4c2.5,0.2,1.5,2.8,3,4c0.5-1.4,1.1-2.6,1.1-4
				c0-0.6,0-1.4-0.9-1.4C443.2,1679.9,440.8,1679.9,438.1,1679.9z M644.4,2034.8c1.5-0.6,3.3-0.8,3.6-2.8c0.1-0.8-0.5-2-1.6-1.6
				c-1.8,0.6-4.6,0-4.8,2.8C641.5,2034.7,643.6,2033.9,644.4,2034.8z M248.8,1739c1.4-0.2,1.8-0.9,1.9-1.5c0.3-1.6-0.1-3,2.3-4.1
				c1.8-0.9-2.2-2-1.8-3.9c0.1-0.4-0.8-0.9-1.6-0.6c-0.5,0.3-0.9,0.8-0.8,1.3C249.4,1733.1,249,1736,248.8,1739z M230.3,1933.8
				c0.2,0.8-0.6,2.6,0.7,2.5c2.1-0.3,2.8-2.4,3.1-4.4c0.1-1-0.1-2-1.5-2C229.9,1930,230.4,1932,230.3,1933.8z M359,1769.9
				c0.8-3.2-0.1-6.3,1.2-9.3c0.4-0.9,0.2-1.8-1.1-1.7c-1.1,0-2,0.4-1.8,1.7C357.8,1763.7,358.4,1766.8,359,1769.9z M483.4,2011.3
				c0.9-2.4,1.5-4.7-0.2-7.5c-0.7,3.2-1.8,1.7-2.8,0.5C479.5,2007.7,482.9,2008.8,483.4,2011.3z M58.6,1767.8
				c-1,0.1-2.1,0.1-1.5,1.2c0.8,1.7,2.2,3.1,4.3,2.7c1.3-0.3,2.2-1.5,2.4-2.8c0.2-0.9-0.8-1.1-1.3-0.9
				C61,1768.4,59.7,1769.5,58.6,1767.8z M342.1,1690.9c0.1,1.1-0.4,2.7,1.4,2.7c1.8,0,2.6-1.2,2.6-3.1c0-1.6-0.6-2.5-2.3-2.5
				C341.5,1688,342.4,1689.7,342.1,1690.9z M763.5,2119.4c1.4-3.1,0.9-6.2,1.1-9.2c0-0.7,0-1.4-0.8-1.6c-0.6-0.2-1,0.2-1.4,0.7
				c-0.4,0.6-0.5,1.3,0.1,1.7c1.4,0.8,0.9,2.1,0.9,3.2C763.6,2115.9,763.5,2117.6,763.5,2119.4z M138.7,2021
				c1.8,0.7,3.1,0.2,3.5-1.6c0.4-1.4-0.9-1.9-1.7-1.5c-1.6,0.7-4.5-0.6-4.8,2C135.5,2021.9,137.6,2020.8,138.7,2021z M409.2,1852.9
				c-0.9,0.6-3.4-1.1-3.2,1.4c0.1,2.3,2.4,0.9,3.7,1.2c0.3,0.1,0.7,0,1-0.1c1-0.3,2.5-0.1,2.3-1.6c-0.2-1.3-1.6-0.7-2.5-0.8
				C410.1,1852.9,409.8,1852.9,409.2,1852.9z M478.4,2012.6c0-3,0-5.4,0-7.8c0-0.5-0.3-1.1-1-1c-0.5,0.1-0.6,0.6-0.6,1
				C476.8,2007.3,475.7,2009.9,478.4,2012.6z M345,1860.7c1.5-2.7,0.9-5.2,0.9-7.6c0-0.7-0.3-1.3-1.1-1.3c-0.9,0.1-0.9,0.8-0.9,1.4
				C343.9,1855.6,344,1857.9,345,1860.7z M111.9,2046.3c1.7-1.1,3.4-2.2,5-3.3c0.3-0.2,0.4-0.9,0-1.1c-1.9-0.9-2.7,1.3-4.3,1.5
				c-0.6,0.1-1.2,0.5-1.7,0.9c-0.2,0.2-0.4,0.8-0.2,1.1C110.8,2045.8,111.3,2046,111.9,2046.3z M442.6,2130.4
				c-3.9-1.9-7.2-0.9-9,2.7C436.8,2132.4,439.1,2129.3,442.6,2130.4z M249.5,1711c-0.3-1.3-0.2-3-2.3-2.6c-0.6,0.1-1.6,0.1-1.2,1
				c0.6,1.2,0.6,3.2,2.6,3.3C249.4,1712.7,249.7,1711.8,249.5,1711z M649.9,2009.3c0.7,1.4,0.3,3.2,1.3,4.6c0.3,0.4,0.6,0.6,1.1,0.6
				c0.6,0,0.8-0.5,0.6-0.9c-0.6-1.5-0.3-3-0.4-4.5c0-0.8-0.5-1.5-1.4-1.5C650,2007.6,650,2008.5,649.9,2009.3z M133.4,2141
				c2.5,1.9,5.3,0.8,7.5,1.8c0.2,0.1,0.7-0.4,0.9-0.8c0.3-0.7-0.2-0.9-0.8-1C138.7,2140.5,136.3,2139.6,133.4,2141z M57.6,1924.4
				c-2.8-0.3-4.1,0.4-4.2,2.5c-0.1,1,0.4,1.7,1.3,1.3c1.6-0.7,3.2-1.6,3.8-3.2C58.7,1924.1,57.3,1924.4,57.6,1924.4z M381.5,2167.6
				c-1.3,0.3-3.6-0.4-3.4,1.3c0.2,1.8,2.8,0.7,4.2,1.3c0.8,0.4,2.3-0.2,2.3-1.2C384.4,2167.2,382.6,2167.9,381.5,2167.6z
				 M580.9,1393.7c1.1,0.1,1.5-0.4,1.4-1.3c-0.1-1.7-1.1-2.5-2.7-2.7c-1.2-0.1-2.4,0.5-2.2,1.8
				C577.7,1393.3,579.8,1392.9,580.9,1393.7z M243.4,1982.3c0.6,1-0.9,3,0.9,3.1c1.5,0.1,1.2-2,1-3.2c-0.2-1.1,0.7-3.3-0.9-3.2
				C242.7,1979,244,1981.1,243.4,1982.3z M518.6,1865.7c4.4,0.9,6.8,0.5,9.3-1.4C524.5,1864.1,521.1,1864.6,518.6,1865.7z
				 M602,1392.5c0.1-1.8,0.9-3.5-0.2-5.3C598.3,1389.1,599.9,1390.8,602,1392.5z M467.6,2002.8c0.1-1.1-0.4-1.8-1.4-1.9
				c-1-0.1-2.3-0.4-2.5,1.2c-0.1,0.9-0.5,2.3,0.8,2.4C465.6,2004.7,466.8,2004,467.6,2002.8z M471.3,1730.9c1.5-1.9,2.2-3.5,0.9-4.6
				c-1.1-1-2.7-0.3-4.1,0.9C470.7,1726.8,470.9,1728.7,471.3,1730.9z M609.4,1401.2c-0.3-1-0.7-2.1-1.9-2.2c-1.1-0.1-0.8,1-0.9,1.7
				c-0.2,1.3,0.8,1.8,1.6,2C609.2,1403.1,609.4,1402.2,609.4,1401.2z M217.9,2054.5c0-0.2,0-0.4-0.1-0.6c-2.1,0-4.2,0-6.3,0
				c-0.7,0-1.3,0.4-1.3,1.2c0.1,0.9,0.9,0.9,1.5,0.7C213.8,2055.4,215.8,2054.9,217.9,2054.5z M781.6,2009.2c1.6,1,2.2,3.6,4.6,2.6
				c0.7-0.3,0.6-1.2,0.3-1.7C785.2,2008.2,783.4,2008.7,781.6,2009.2z M547.4,2055c-0.3,0-0.5,0-0.8,0c0,1.5,0,3.1,0,4.6
				c0,0.6,0.1,1.4,1,1.3c0.9-0.1,0.9-0.9,0.8-1.5C548.2,2057.9,547.8,2056.4,547.4,2055z M249.6,1752.5c0,2.2,1.2,2.5,2.8,2.4
				c1-0.1,1.9-0.5,1.8-1.5c-0.1-1.2-1.1-1.1-2-0.6C251.4,1753.3,250.6,1752.4,249.6,1752.5z M718.4,2122.7c0.5,0.7,1.2,0.4,1.7,0.3
				c0.9-0.2,1.9,0.8,2.7-0.2c0.1-0.2,0.1-0.7,0-0.8c-1.1-0.5-2.2-0.8-3.4-0.3C719.1,2121.9,718.4,2122,718.4,2122.7z M217.5,2033.2
				c0-1-0.1-2.2-0.8-2c-1.8,0.6-0.7,2.6-1.5,3.9c-0.3,0.5,0.2,1.2,0.7,1.1C217.7,2035.8,217.4,2034.3,217.5,2033.2z M220.8,2037.9
				c0-0.9-0.5-1-1.1-0.9c-1.5,0.2-2.7,1.1-3.7,2.3c-0.3,0.3,0.4,1,1,0.9C218.7,2040,219.8,2039,220.8,2037.9z M694.9,1450.1
				c0.4,1.3-0.5,3.2,1.7,3.5c0.7,0.1,1.5-0.3,1.5-0.9C697.8,1450.9,696.2,1450.7,694.9,1450.1z M374.8,1809.9
				c0.9-0.1,1.7-0.6,1.8-1.6c0.1-1.2-0.8-1-1.5-1c-1,0-1.8,0.4-1.8,1.5C373.2,1809.7,374,1809.9,374.8,1809.9z M524.9,2195
				c-1.5-1.2-2.9-1.2-4.3-0.5c-0.1,0.1-0.1,0.8,0,0.9C522.1,2196.4,523.4,2195.8,524.9,2195z M834.3,2055.4c0.2-1,0.4-2.1-0.8-2.1
				c-1.5,0-1.3,1.5-1.5,2.5c-0.3,0.9-0.1,2,1,2C834.6,2057.8,833.9,2056.1,834.3,2055.4z M533.8,1480.5c-0.4-0.7-0.2-1.7-1.3-1.9
				c-0.9-0.2-1.5,0.4-1.6,1.2c-0.2,1.1,0.6,1.8,1.6,2C533.3,1481.8,533.7,1481.3,533.8,1480.5z M533.5,1467.3
				c-0.1-0.8-0.4-1.5-1.3-1.6c-1.1-0.1-1.4,0.8-1.4,1.7c0,0.9,0.4,1.4,1.4,1.3C533,1468.7,533.5,1468.3,533.5,1467.3z M728,2138.4
				c-0.5-0.5-0.1-2-1.5-1.9c-1,0.1-2,0.7-1.8,1.8c0.1,1.4,1.4,1.4,2.5,1.5C727.9,2139.8,728.3,2139.4,728,2138.4z M384.9,1855
				c-1.1-0.1-1.6,0.6-1.8,1.5c-0.2,0.7,0.4,0.9,0.9,1c0.9,0.1,1.8,0,2-1.2C386.1,1855.5,385.8,1855.1,384.9,1855z M384.1,1853.1
				c2,0.6,2.8,2,4.3,1.4c0.6-0.2,0.7-1.3,0.2-1.7C387.5,1851.8,386.2,1852.1,384.1,1853.1z M434.2,1374.1c-0.1-1-0.7-1.6-1.7-1.6
				c-0.9-0.1-1.7,0.3-1.9,1.3c-0.2,0.9,0.4,1.4,1.2,1.5C432.9,1375.3,433.8,1375.2,434.2,1374.1z M726.1,2132.5
				c-0.8-0.1-1.6,0.1-1.6,1.1c0,1.2,0.8,1.7,1.9,1.7c0.8,0,1.8,0,1.6-1C727.7,2133.5,727.5,2132.2,726.1,2132.5z M816.1,2001.6
				c-0.8-1.5,0.2-2.7-0.8-3.6c-0.1-0.1-1.2,0.6-1.3,1.1C813.4,2000.5,814.5,2000.9,816.1,2001.6z M233,1995.1
				c0.5-1.4,0.8-2.9-0.1-4.6C231.9,1992.3,232.8,1993.7,233,1995.1z M187.4,2147.2c-0.1-0.8-0.6-1-1.3-1.1c-0.8-0.1-1.6,0-1.7,1
				c-0.1,0.8,0.6,1.1,1.3,1.1C186.4,2148.2,187.2,2148.2,187.4,2147.2z M976.5,1772.1c2.2,1,4.1-0.4,6.1-0.4
				C980.5,1770.9,978.5,1772.2,976.5,1772.1z M141.2,2138.3c-1.8-1.5-3.2-1.5-5.1-1.6C137.5,2138.3,138.8,2138.5,141.2,2138.3z
				 M864.9,2111.1c-1.7-0.2-2.8-1-4,0c-0.1,0.1-0.1,0.7,0.1,0.8C862.2,2112.4,863.3,2112.2,864.9,2111.1z M271.9,1928.6
				c-0.5,0.2-0.7,0.6-0.5,1c0.2,0.3,0.6,0.7,0.9,0.7c0.5,0,0.5-0.6,0.4-1C272.7,1928.8,272.4,1928.6,271.9,1928.6z M208.6,1924.7
				c0,0.1,0.1,0.2,0.1,0.3c1.4-0.1,2.7-0.3,3.1-0.3C211.4,1924.7,210,1924.7,208.6,1924.7z M473.9,1909.6c-1-0.2-1.8-0.5-2.4,0.3
				C472.2,1910.6,472.9,1910.6,473.9,1909.6z M1128.2,1973.9c0.1-0.1,0.5-0.4,0.5-0.5c-0.1-0.4-0.4-0.8-0.7-0.9
				c-0.5-0.2-0.8,0.2-0.8,0.7C1127.2,1973.6,1127.5,1973.9,1128.2,1973.9z M290.6,1897.3c0.5-0.2,0.7-0.5,0.6-1
				c0-0.2-0.4-0.4-0.4-0.3c-0.3,0.3-0.6,0.6-0.7,0.9C289.9,1897.3,290.3,1897.3,290.6,1897.3z M602.9,1395.2c0.5,0,0.7-0.3,0.6-0.6
				c0-0.3-0.2-0.6-0.4-0.7c-0.4-0.1-0.6,0.2-0.5,0.6C602.6,1394.7,602.8,1394.9,602.9,1395.2z M157.6,1925.1
				c-0.2,0.1-0.6,0.2-0.7,0.4c-0.2,0.4,0.2,0.6,0.5,0.6c0.3,0,0.7-0.1,0.8-0.3C158.5,1925.4,158.1,1925.2,157.6,1925.1z
				 M738.3,1977.6c0.4-1.5,1.2-2.5-0.1-3.5C737.3,1975,737.3,1976,738.3,1977.6z M519.6,1612.2c0-0.4-0.3-0.8-0.7-0.7
				c-0.4,0.1-0.7,0.4-1,0.7c-0.1,0.1,0.2,0.6,0.4,0.7C518.8,1613,519.3,1612.9,519.6,1612.2z M261.6,2158.9c0.2-0.1,0.4-0.1,0.5-0.2
				c-0.1-0.2-0.2-0.4-0.3-0.4c-0.2,0-0.4,0.1-0.7,0.2C261.3,2158.6,261.5,2158.8,261.6,2158.9z M150.7,1979.2c0.1-0.5,0-0.9-0.4-1.1
				c-0.2-0.1-0.6,0.2-0.7,0.4c-0.2,0.5,0.1,0.8,0.5,1.1C150.4,1979.8,150.6,1979.5,150.7,1979.2z M793.5,1872.3
				c-0.1,0.2-0.2,0.3-0.2,0.5c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0,0.2-0.3,0.2-0.5C793.9,1872.4,793.7,1872.3,793.5,1872.3z
				 M347.1,1489.9c-0.1-0.2-0.1-0.5-0.2-0.5c-0.1-0.1-0.4,0.1-0.5,0.1c0.1,0.2,0.2,0.5,0.3,0.7
				C346.8,1490.1,346.9,1490,347.1,1489.9z M920.3,2006.2c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.4,0.2,0.6,0.5,0.6c0.4,0,0.9,0.2,0.8-0.5
				C920.9,2006.7,920.5,2006.5,920.3,2006.2z M854.1,2112.5c-0.2-0.1-0.4-0.1-0.5-0.1c-0.3,0-0.5,0.1-0.8,0.1c0.1,0,0.2,0.1,0.3,0.1
				C853.4,2112.6,853.7,2112.6,854.1,2112.5z M393.7,2072.3c-0.1-0.2-0.1-0.5-0.2-0.5c-0.1-0.1-0.5,0-0.5,0.1
				c-0.2,0.3-0.1,0.6,0.3,0.7C393.4,2072.6,393.6,2072.4,393.7,2072.3z M768.2,1804.1c-0.1,0-0.2-0.1-0.2-0.1
				c-0.3,0.1-0.3,0.2,0,0.3c0.1,0,0.2,0,0.2-0.1C768.2,1804.3,768.2,1804.2,768.2,1804.1z M582.8,2134.1l-0.2,0l0-0.1L582.8,2134.1z
				 M586.3,1652.2c0-0.1,0.1-0.2,0-0.3c-0.1-0.3-0.3-0.2-0.3,0.1c0,0.1,0,0.2,0.1,0.3C586.1,1652.2,586.3,1652.2,586.3,1652.2z
				 M699.4,1738l-0.1,0.2l0.2,0L699.4,1738z M731.1,1509c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C731,1509.2,731.1,1509.1,731.1,1509z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M69.2,1525.1
				l-0.1-0.2L69.2,1525.1c0.7,0.6,1.4,1.1,2.4,0C70.6,1525.1,69.9,1525.1,69.2,1525.1z M74.5,1445.8c-1.9-1.1-4.8-0.4-5.7-3.4
				c-0.2-0.6-1.5-0.2-2.3,0.3c-1.9,1.2-3.9,1-5.1-1c-0.7-1.2-1.2-2.3-2.6-1.2c-0.8,0.6-2.3,0.9-1.6,2.7c0.7,1.7,1.6,2.3,3.2,1.8
				c1-0.3,2.1-0.6,2.8,0.1c3,3,7.4,1.4,10.7,3.5c2,0.9,2.1,1.6,0.8,3.8c-1.6,2.7-5.2,4.5-4.6,8.5c0.1,0.5-0.6,0.5-1,0.4
				c-0.9-0.1-1.9,0.7-1.6,1c1,1,0.5,2.5,1.5,3.2c0.3,0.2,1.7-0.3,2-0.8c1.3-2.6,3.1-4.8,4.9-7.1c1.9-2.4,2.5-6,6.3-6.7
				c0.7-0.1,1.2-0.6,1.1-1.3c0-0.7-0.2-1.4-1-1.5C79.5,1447.6,77.4,1445.4,74.5,1445.8z M402.4,1647c0,0.3-0.1,0.5-0.1,0.8
				c5.6,1.3,11.5,1.7,17.1,3.1c8,1.9,16.6-0.9,25,3.8c-2.2-2.9-4.3-3.7-7-3.9c-6.9-0.5-13.9-0.2-20.8-1.6
				C411.8,1648.2,407.1,1647.7,402.4,1647z M442,1616.6c4.8-2.9,8.7-6.5,12.1-10.9c-1.9,0.7-3.3-0.4-4.9-0.8
				c-3.4-0.8-3.3-0.9-4.6,0.4c1,0.7,2.1,0.3,3.2,0.5c0.9,0.2,2.1,0.4,2.5,1.4c0.5,1.1-0.8,1.3-1.4,1.9
				C446.7,1611.6,444.3,1614.1,442,1616.6z M82.3,1528.4c-3-1.7-6.2-2.5-9.6-2.1C75.9,1527,79.1,1527.7,82.3,1528.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M141.5,1377.7
				c-1.3,0.4-2.4,1.5-4.3,1.4c0.7-5.9,1.3-11.8,2-17.6c0.8-6.8,1.6-13.6,2.4-20.4c0.2-1.8,0.1-3.2-2.3-3.4c-1.9-0.1-1.3-1.3-0.8-2.3
				c1.8-3.7,3.4-7.5,5.5-11.1c0.6-1.1,0.7-2.1,0.9-3.2c2,0.8,1.1,2,0.6,3.4c-0.9,2.4-2.8,4.4-3.2,7.2c2.1-1,4.4-1.6,3.4-4.6
				c-0.1-0.3,0.5-1,1-1.2c2.4-1.2,2.9-3.6,3.8-5.8c2.2-5,4.4-10,6.6-15c5.1-11.6,10.2-23.3,15.3-34.9c0.9-2.1,1.8-4.2,2.5-6.4
				c0.3-0.9,0.5-2.1-0.9-2.3c-1.2-0.2-1.3,0.7-1.6,1.6c-0.6,1.7-0.4,3.7-1.5,5.3c-3,4.1-2.1,10-6.4,13.5c-0.1,0.1,0,0.2-0.1,0.3
				c-1.4,5.1-3.3,10-6.8,14.2c-0.4,0.4-0.3,1.2-0.4,1.9l0,0c-3.3,2.8-1,8.6-5.7,10.7c-0.3,0.1,0,1.1-0.2,1.6c-0.9,2.2-1.3,4.9-3.9,6
				c0.8-2,1.6-3.9,2.5-5.9c0.3-0.7,0.7-1.5-0.3-1.9c-0.7-0.3-1.4,0.1-1.8,0.9c-0.7,1.7-1.6,3.2-2.2,4.9c-0.3,0.8-0.3,2,1,2.4
				c0.4,1.9-0.1,3-2.3,2.6c0.2-8.6,1.9-17,2.7-25.6c0.9-10,2.6-19.9,3-29.9c0-0.8,1.1-2-0.3-2.6c-1-0.5-1.9,0.2-2.7,0.7
				c-8.2,5.2-16.8,9.9-24.5,15.7c-22.7,17.3-48.2,28.9-75.3,37.5c-4.6,1.5-9,3.3-13.5,5c0-0.9,0-1.7,0-2.6c5-0.7,9.5-2.9,14.2-4.6
				c14.9-5.3,29.8-10.7,43.9-17.9c9.8-5.1,18.7-11.7,27.7-17.9c8.5-5.9,17.6-11,26.2-16.7c2-1.3,4.2-2.2,5.8-4.1
				c2.9-1,5.6-2.4,7.7-4.7c2.5-0.5,4-2.6,6.1-3.8c1.7-0.5,3.2-1.3,4.7-2.3c5.2-3.3,10.5-6.4,15.4-10c3.4-2.4,7.5-3.9,10-7.5
				c4.5-1.2,7.6-4.6,11.3-7.1c3.8-2.7,7.7-5.3,11.5-8c0.8-0.6,2.6-1.1,1.7-2.2c-1-1.3-1.7,0.7-2.5,1.1c-1.1,0.6-2.1,1.5-3.1,2.3
				c-2.6,1.9-6,2.7-7.9,5.6c-2.1-1.6-1.5-4.1-2.2-6.2c-0.7-1.9-1.3-2.8-3.1-1c-3.9,3.8-7.1,7.9-5.9,13.9c-1,0.9-1.9,2-3.1,2.8
				c-7.6,5-15.3,9.9-22.9,14.8c-0.6,0.4-1.2,0.4-1.9,0.3c2-2.3,3.8-4.8,6.2-6.7c4.4-3.6,5-7.2,2.1-12.3c-3.3-5.8-5.7-12-8.5-18
				c-0.4-0.8-0.5-1.9-1.6-1.7c-1.2,0.2-0.8,1.3-0.8,2.1c-0.1,1,0.1,2.7-0.2,2.8c-3,1-2,3.5-2.4,5.4c-2.6-0.8,0-3.1-1.3-4.2
				c-0.7,0.5-0.6,1.3-0.8,2c-2,11.7-3,23.5-4,35.3c-0.3,3.3-2,4.7-4.7,5.6c1.2-11.1,2.4-22.2,3.5-33.4c1-9.6,2-19.3,2.8-28.9
				c0.5-5.2-0.2-5.9-5.2-6.6c1-3.1,4.1-3.3,6.3-4.6c4.9-2.9,10.1-5.4,15.2-8.1c3.6-1.9,7.1-4.6,11.6-2.4c0.6,0.3,1.4,0.4,1.8-0.3
				c0.4-0.7,0.2-1.5-0.2-2.2c-3.6-6.3-6.5-13-10.6-19.1c-0.5-0.8-0.8-2-1.9-2c-1.5,0.1-1.3,1.5-1.6,2.5c-2.5,7.4-5,14.8-7.5,22.3
				c-0.7,2-2.5,2.7-4,3.6c-1.1,0.7-0.9,3.4-2.8,2.3c-1.4-0.8-0.7-2.8-0.6-4.3c0.8-12.7,0.4-25.4,0.4-38.1c0-17,0.3-34,0.5-51
				c0-0.8,0.1-1.8,0.5-2.5c1.3-2.3,2.7-4.4-0.2-6.8c-0.8-0.7,0.7-2.1,0.8-3.3c0-0.4-0.1-0.9-0.4-1.2c-0.3-0.3-0.9-0.2-1,0.3
				c-0.3,2.1-1.4,1.5-2.5,0.8c-1.2-0.8-2.4-1.8-1.3-3.4c2.2-3.1,0.1-5.9-0.3-8.9c-0.1-0.8-1.3-0.9-1.3-0.1c-0.3,2.6-1.6,1.4-2.6,0.8
				c-1.3-0.8-2.1-1.8-1.1-3.5c1.3-2.2,1.3-4.6-0.3-6.8c0.5-1.1,1.1-2.6-0.6-2.8c-1.8-0.3-0.8,1.5-1.1,2.4c-2.6-0.9-5-1.4-3.1-5.3
				c1.1-2.3,0.3-5.2-0.5-7.7c-0.1-0.3-0.6-0.6-0.9-0.8c-0.1-0.1-0.7,0.4-0.6,0.6c0.2,1.6-0.4,2-1.8,1.2c-1.5-0.8-2.6-1.7-2.3-3.8
				c0.4-2.2,2.3-4.3,0.5-6.7c-0.1-1.2,0.9-3-1-3.1c-1.7-0.1-0.7,1.6-0.9,2.5c-3.7-1.3-4.4-0.7-2.9-7.9c0.3-1.3,0.5-2.3-0.5-3.2
				c0.1-1.2,0.9-2.9-0.8-3.3c-1.8-0.5-0.7,1.8-1.6,2.3c-0.2,0.1-0.4,0.1-0.6,0.2c-2.9-2-3.2-6.9-0.4-10.2c1.1-1.3,2.4-2.5,3.7-3.7
				c1.3-1.2,0.5-2.2-0.2-3.1c-0.9-1.1-1.6-0.5-2.1,0.6c-0.5,1.1-1.5,0.6-2.3,0.5c-5.2-0.5-9.5-2.3-11.4-7.7
				c-0.4-1.1-1.1-2.1-1.6-3.2c4.3,0.7,1.8-2.4,2.1-3.8c2.9,0.8,6.1,0,7.2-1.8c1.6-2.4,3.4-2.6,5.5-2.8c1.6-0.2,3.1-0.3,4.7-0.8
				c3.8-1.1,7-3.7,10.5-4.8c4.9-1.5,9.5-3.4,13.8-6.2c2-1.3,4.2-1,6.5-0.5c-0.6,1.8-2.8,2.3-2.6,4.2c-2.8,2.5-3.9,6.1-6,9
				c-0.9,0.2-1.6,0.5-1.8,1.5c-0.4,0.5-0.7,1.1-1.1,1.6c-1.1,1.3-2.9,2.1-3.6,4.8c7.4-3.3,14.5-5.5,22.5-5.6
				c-2.9,6.4-8,10.5-12.6,15c3.7-0.9,7.3-1.9,10.9-3c1.5-0.4,2.9-0.8,2.9,1.5c-3,2.9-5.3,6.2-7.3,9.8c-1.3,1.2-3,2.2-3.6,4.1
				c1.4,0.7,2.3-1,3.5-0.4c3.3,1.8,5.3-0.8,7.5-2.4c0.1,0,0.2-0.1,0.3-0.2c2.3-0.6,4.6-1.2,7-1.8c0.9-0.2,2.7,0.2,1.9,1
				c-2.3,2.2-2.4,5.3-4.3,7.6c-2.4,2.9-6,4.2-8.9,7.5c1.8-0.5,3.1-0.7,4.2-1.2c4.3-2,8.9-2.9,13.6-3.4c1.2-0.2,3-1,3.6,0.2
				c0.8,1.5-1.4,1.5-2.1,2.3c-0.1,0.2-0.2,0.4-0.3,0.6c-2,4.3-5.5,7.2-9.4,9.7c-1.2,0.7-2.2,1.7-3.3,2.5c4-0.7,7.7-1.8,11.4-2.8
				c3.4-0.9,6.8-1.7,10.4-1.3c0.6,0.1,1.4,0.2,1.4,1c0,0.9-0.8,0.8-1.4,0.9c-0.9,0.1-2-0.3-2.6,0.9c-2.2,4.6-6.2,7.1-10.3,9.6
				c-2,1.2-3.8,2.5-5.6,4.5c0.8-0.2,1.3-0.2,1.8-0.4c5.6-1.9,11.1-4,17-4.9c1.7-0.3,3.5-0.4,5.2-0.4c0.7,0,1.7,0,1.7,1
				c0,0.8-0.8,0.9-1.5,0.9c-0.9,0-1.8,0.1-2.3,1c-2.7,5-7.6,7.5-11.9,10.8c-0.8,0.6-1.9,1-2.3,2.5c2.6-0.3,4.9-1.5,7.2-2.2
				c4.2-1.3,8.5-2.3,12.8-2.8c0.9-0.1,2.8,0.4,1.7,1.2c-1.6,1.2-1.7,3.1-3.2,4.3c-3.9,3.1-7.2,7-12,9c-1,0.4-1.8,1.6-2.7,2.4
				c0.1,0.2,0.3,0.5,0.4,0.7c5.8-2.8,11.8-4.5,18-5.7c1.8-0.3,3.7-0.5,5.5-0.7c0.5,0,1.2,0.1,1.2,0.8c0,0.3-0.2,0.8-0.3,0.8
				c-2.6-0.3-2.6,2.5-4.2,3.4c-5.1,2.8-8.2,8-13.3,10.9c-0.2,0.1-0.1,0.5-0.3,1.3c1.7-0.6,3.1-1.1,4.6-1.6
				c5.1-1.8,10.2-3.2,15.6-3.9c0.7-0.1,1.6-0.5,2.1,0.4c0.4,0.8-0.6,1-0.8,1.6c-2.3,4.4-6.8,6.5-10.2,9.8c-1.2,1.2-2.7,2-3.5,4.3
				c5.2-2.1,10-3.4,14.9-4.4c2.1-0.5,4.3-0.6,6.5-0.9c0.4,0,0.9,0.1,1,0.6c0,0.3-0.2,0.8-0.3,0.8c-3.2,0-3.7,3.3-5.8,4.8
				c-4,3-7.6,6.5-11.3,9.9c1.3,0.3,2.1,0.1,2.9-0.2c5.2-2.1,10.6-3.5,16.1-4.2c0.7-0.1,1.6-0.5,2.1,0.4c0.4,0.7-0.2,1.2-0.6,1.7
				c-2,2.3-3.7,4.7-6.2,6.7c-2.7,2.2-5.8,4-7.9,7.3c20.8-5.9,20.8-5.9,24-3.3c-2.6-0.4-3.5,1.1-4.9,2.2c-3.9,3.3-6.9,7.5-11.6,10
				c-1.1,0.6-2.3,1.2-2.8,2.6c1.2,0.2,2.2-0.5,3.2-0.8c5-1.8,9.9-3.4,15.2-4.3c1.1-0.2,3.1-0.4,2.1,1.5c-0.8,1.7-0.9,3.6-2,5
				c-1.3,0.5-2.2,1.5-3,2.5c-0.9,0.1-1.4,0.7-1.5,1.5l0,0c-0.4-0.1-0.7,0.1-0.7,0.5l-0.1,0c-2.5,1.1-5.1,2.2-6.5,4.9
				c5.7-2.2,11.6-3.3,17.3-5.1c1.5,0.3,2.9-0.1,4.2-0.7c0.9,0.7,2.9-0.3,2.9,1.3c0,1.7-1.8,0.9-2.9,1.1c-1.2,0.2-1.5,0.8-0.9,1.8
				c0.4,0.8,0.8,1.5,0.4,2.4c-2.6,0-4.4,1.4-5.8,3.4c-0.7-0.1-1.3,0.1-1.5,0.9c-1,0.7-2.1,1.5-3.1,2.2c-2.1,0.9-4.1,2-5.6,3.7
				c0.2,0.3,0.4,0.5,0.5,0.8c4.7-1.7,9.4-3.4,14.2-5c0.6-0.1,1.2-0.2,1.8-0.3c0.6,0.2,1.1,0.2,1.5-0.5c0.4-0.1,0.8-0.1,1.1-0.2
				c2,0.3,3.3,1,1.7,3.3c-0.3,0.4-0.3,1-0.5,1.5l-0.1,0.1c-1.7,1.9-3.4,3.9-5,5.8l-0.2,0.2c-0.8-0.1-1.3,0.2-1.4,1
				c-0.2,0.1-0.4,0.1-0.6,0.3c-0.8-0.2-1.4,0-1.6,0.8c-0.4,0.2-0.8,0.5-1.2,0.7c-2.1,0.4-2.6,2.7-4.3,3.8c4.3-1.6,8.6-3.1,12.9-4.7
				c0.4-0.1,0.8-0.2,1.1-0.3c1.6,0.3,3.1-0.5,4.7-0.7c1.2-0.1,2.7-1.1,3.6,0.2c0.8,1-0.8,1.8-1.1,2.8c-0.2,0.9-0.7,1.8-1,2.7
				c-2.4-0.1-3.3,1.8-4.6,3.3c-0.8-0.2-1.3,0.1-1.4,0.9c-1.1,0.5-1.8,1.4-2.5,2.2c-2.9,1-5,3.1-7.2,5.1c6.6-2.6,13.5-4.3,20.2-6.3
				c0.6-0.1,1.2-0.2,1.8-0.3c0.8,0.1,1.7,0.1,2.5,0.4c0.6,0.2,1.1,1.3,0.3,1.4c-3.7,0.8-3.8,5.1-6.6,6.6c-0.4,0.3-0.8,0.6-1.2,0.9
				c-0.7,0-1.2,0.4-1.3,1.1c-0.4,0.5-0.9,0.9-1.3,1.4c-3.2,1.4-5.8,3.7-9.1,6.3c1.9-0.7,3.2-1.1,4.4-1.5c6.1-2.1,12.1-4.3,18.5-5.2
				c1.4-0.2,2.8-0.5,3.9,0.5c-3.6,3-7.3,5.3-9.8,8.9c-0.2,0.2-0.4,0.3-0.5,0.5c-1,0-1.3,0.7-1.5,1.5c-0.2,0.1-0.4,0.2-0.5,0.3
				c-0.7-0.1-1.3,0.2-1.5,1c-0.4,0.3-0.8,0.5-1.2,0.8c-2.4,0-3,2.3-4.3,3.6c0.1,0,0.1,0.1,0.4,0.2c6.3-2.7,13-4.3,19.6-6.3
				c1.3-0.1,2.5-0.2,3.8-0.3c1.1,0.4,2.5,0.7,0.8,2.1c-0.8,0.2-2-0.6-2.1,1c-0.6,1.2-1.5,2-3,2c-0.9,0-2,0.1-1.9,1.5
				c-1,1.9-1.8,4-4.2,4.7c-0.4-0.1-0.6,0.1-0.5,0.5l-0.1,0.1c-2.3,2-5.5,2.9-8,6.3c4.6-1.8,8.3-3.2,12.1-4.7
				c0.3-0.1,0.6-0.2,0.8-0.3c0.9,0.4,1.6,0.2,2.1-0.5c0.4-0.1,0.8-0.1,1.2-0.2c0.6,0.3,1.1,0.1,1.5-0.5c0.4-0.1,0.8-0.1,1.2-0.2
				c0.6,0.4,1.1,0.1,1.5-0.3c0.6-0.1,1.2-0.2,1.8-0.3c1.8,1,2,3,0.3,4.2c-3.6,2.4-6.6,5.6-9.8,8.4c-1.6,1.4-3.8,2.2-5.1,5
				c4-1.7,7.7-2.3,10.9-4.1c0.4-0.1,0.8-0.2,1.1-0.4c2.2,0,4.2-0.4,6.1-1.5c0.6-0.1,1.2-0.2,1.8-0.3c1,0.1,1.9,0.1,2.9,0.3
				c0.4,0.1,0.7,0.4,0.9,0.7c0.3,0.5,0,0.7-0.5,1c-2.3,1.1-3,3.7-4.8,5.3c0,0-0.1,0.2-0.1,0.2c-0.9,0.3-1.2,0.9-1.2,1.8l-0.1,0.1
				c-0.7,0-1.2,0.3-1.4,1.1l0,0c-3.2,1.3-5.1,4.1-7.7,6.1c4.7-1.9,9.7-2.3,14.3-4.5c0.6-0.1,1.2-0.3,1.8-0.4
				c0.6,0.4,1.1,0.2,1.5-0.2c0.6-0.1,1.2-0.3,1.8-0.4c1,0.6,2.5-0.2,3.1,1.3c-3.5,1.8-3.7,2-4.6,4.7c-0.3,0.4-0.7,0.7-1,1.1
				c-0.9,0.2-1,0.8-0.8,1.6c-0.3,0.3-0.6,0.5-0.9,0.8c-3.3,2.5-6.7,4.9-10,7.4c0.1,0.1,0.2,0.3,0.3,0.4c3.4-1.3,6.8-2.6,10.2-3.9
				c0.4-0.1,0.8-0.2,1.1-0.3c0.6,0.4,1.1,0.2,1.5-0.4c0.4-0.1,0.8-0.2,1.1-0.3c0.6,0.3,1.1,0.2,1.5-0.3c0.4-0.1,0.7-0.2,1.1-0.3
				c1.3,0.7,3.3-0.8,4.1,0.9c0.9,1.9-1,3-2.1,4.2c-0.2,0.2-0.1,0.6-0.2,0.9c-2,2.7-5.3,4-7.4,6.6c-2.3,1-4.1,2.8-6.8,4.8
				c5.1-2.3,9.8-3.1,14-5.2c0.4-0.1,0.7-0.2,1.1-0.3c1.9,0.3,3.7-0.3,5.4-1.2c0.4,0,0.8-0.1,1.2-0.1c0.8,0.7,2.2,1.4,0.8,2.4
				c-1.5,1.1-1.3,2.6-1.8,3.9c-0.5,0.1-0.9,0.2-1.4,0.3c-0.4-0.1-0.7,0.1-0.7,0.5c-0.8,0.8-1.6,1.6-2.4,2.4
				c-1.5,0.8-2.7,1.9-3.4,3.5l0,0c-1.4,0.8-2.8,1.6-3.9,3.7c3.4-1.1,6.2-2,8.9-2.9c5.1-1.6,5.1-1.5,6.4,3c-0.3,3.2-3.2,4.5-4.9,6.6
				c-0.4,0-0.7,0.2-0.7,0.6c-0.3,0.2-0.6,0.5-0.9,0.7c-2.1,1.1-4.2,2.2-5.6,4.1c4-1.3,8.1-2.2,11.8-4.3c0.4-0.1,0.8-0.2,1.1-0.4
				c3,0.4,6,0.6,8.8-1.1c1.4-0.8,3.4-1.4,3.1,1.6c-2,0-3.3,0.4-1.6,2.7c0.2,0.3-0.1,1-0.1,1.5c-0.7,0.1-1.1,0.5-1.1,1.2
				c-1.6,1.4-3.2,2.7-4.8,4.1c-3.9,1.5-8.2,2.2-11.3,6.3c3.9-2,7.9-2.4,11.2-4.4c0.4-0.1,0.7-0.2,1.1-0.3c1.5,0.3,2.8-0.2,4.1-1
				c0.4-0.1,0.8-0.2,1.2-0.4c2.7,1,5.4-1.5,8.1,0l0,0c0,0.6,0.3,0.7,0.8,0.4c0.8-0.2,1.5-0.4,2.3-0.6c1.2,0.9,2.9,1.2,3.4,2.8
				c-1.1,1.6-2.6,2.5-4.6,2.2c-0.7-2-4.8-3.9-4.9-2.7c-0.2,2.6-2.7,2.9-3.6,4.7c-1.6,0.2-2.1,1.7-3.2,2.6c-1.2,0.3-2.2,1.1-2.9,2.1
				l-0.1,0.1c-2.3,1.4-4.6,2.8-7.4,4.6c2,0.4,2.9-0.2,3.9-0.6c5.3-1.8,10.5-3.6,16.1-4.4c1.1-0.2,2.5-0.6,2.8,0.8
				c0.3,1.1,0.5,2.6-0.8,3.6c-0.4,0.3-0.8,0.7-1.3,1c-3.7,2.6-6.5,6.4-10.7,8.4c-0.6,0.3-1.4,0.9-1.2,2.2c3.1-1,6-2,9.1-2.8
				c2-0.6,2.8,0.3,2.1,2.2c-1.5,4.4-3.8,8.1-7.6,11.1c-4.2,3.3-9.1,5.6-12.9,9.3c-0.2,0-0.3,0.1-0.4,0.3c-1.5,0.3-2.6,1.2-3.3,2.5
				c-3.5,2-7.1,3.8-9.2,7.4c-1.4,2.3-2,5-4,7c-0.5,0.1-1.1,0-1.3,0.8c-2.5,0.4-4.7-1.6-7.2-0.9c-0.3,0-0.5-0.1-0.8-0.1
				c-0.8-2.4-1.5-4.8-4.8-4.8c-1.1,0-2.2-0.3-2.8-1.4c-0.7-1.3,0.4-1.7,1.2-2.3c1.5-1.1,1.8-2.9,1-4c-1-1.3-1.8-3.2-3.8-3.6
				c-4.8-1-4.9-1-2.2-4.6c1.6-2.2-0.6-5.5-3.6-6.4c-1.4-0.4-3-0.2-3.6-1.8c-0.6-1.6,0.6-2.5,1.9-3.3c1-0.6,0.9-1.9,0.4-2.7
				c-1-1.4-2-3-3.8-3.5c-0.7-0.2-1.6,0-2.3-0.2c-2-0.6-2.4-2.3-0.8-3.5c1.6-1.2,2.2-3,1.1-4.2c-1.2-1.3-1.6-3.8-4.5-3.4
				c-1.5,0.2-2.1,0.3-1.9,2c1.1,10.2,1.9,20.4,3.2,30.5c0.3,2.7,1.3,5.5,1.6,8.3c0.1,1.5,1,2.3,2.4,2.6c-0.2,0.9-1.1,0.5-1.3,0.9
				c-2.1,3.7-5.7,2.5-8.8,2.8c-1.5,0.1-2.7-0.3-1.3-2.3c1.8-2.7,1.6-5.1-0.3-8c-8.3-12.4-16.3-24.9-24.5-37.4
				c-0.5-0.7-1.2-1.2-1.8-1.9c1.1-2.3,1.8-5,5-5.5c2.2,3.4,4.5,6.7,6.7,10.1c4.2,6.5,8.4,13,12.5,19.5c0.4,0.6,0.8,1.4,1.7,1.3
				c0.9-0.2,0.7-0.9,0.6-1.6c-1-9.6-2.1-19.2-3.1-28.9c-0.3-3.1-0.7-6.3,2.2-8.7c0.9-0.7,0.6-1.8,0.3-2.7c1.2-0.4,1.6-1.5,2-2.6
				c0.3-0.2,0.7-0.5,1-0.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2,0.4-0.4,0.7-0.6,1.1c-1,0-1.9,1.8-2.9,0.4c-1.2-1.7,1.4-2,1.7-3.4
				c-0.5,0-1,0-1.3-0.1c-2.9-0.7-2.9-0.8-2.9,2.1c0,0.4-0.4,0.8-0.6,1.1c-1.5-0.7-2.7-1.8-3.7-3.1l0-0.2c0.4-2,3.7-2.6,2.4-5.3
				c0.8-1.8,2.5-2.8,4.3-4.2c-2.8-0.5-3.7,1.7-5.1,2.6c-1.2-1.1,0.4-3.3-1.7-3.6c-1.2-0.2-1.1,1.1-1.5,1.8c-2.1-0.4-4.6-1.9-3.1-3.6
				c2.7-3,1.6-5-0.3-7.4c0-2,3.4-1.2,3.2-3.7c-3,1.5-3,1.5-3-1c0.1-0.2,0.1-0.3,0.2-0.5c0,0.2-0.1,0.4-0.1,0.6
				c-3.7,0.4-3.7,0.4-2.1,3.7c0.1,0.2,0,0.4-0.1,0.6c-0.3,0-0.6,0-0.9,0c-0.3-0.1-0.6-0.2-0.9-0.4c-1.3-0.8-2.8-2.3-1.8-3.3
				c3-2.8,2.3-3.7-1-5.6c-3-1.6-2.6-2.2-3.6,1.1c-2.6,8.4-5.3,16.8-7.8,25.2c-0.8,2.6-3.3,5.8,1.5,7.5c0.4,0.1,0.6,0.9,0.9,1.3
				c1.3,1.8,2.7,3.7,4,5.5c-1.5,1.6-3.1,3.2-4.6,4.7c-0.4-0.4-0.8-0.7-1.1-1.1c-0.5-0.7-0.7-1.8-1.8-1.4c-1.1,0.4-0.5,1.5-0.5,2.3
				c0,1.4,0.1,2.8,0.1,4.2c-1.4,0.5-2.1,2.2-3.9,2.5c0.3-2.6-1.9-4.1-2.5-6.3c-0.2-0.9-0.8-1.6-1.9-1.3c-1,0.3-0.8,1.2-0.8,1.9
				c-0.1,4.7-0.1,9.3-0.2,14c0,0.6-0.3,1.5,0.7,1.5c0.8,0,0.7-0.9,0.8-1.5c0.3-2.8,1.8-4.3,5.1-4c-1.2,1.6-2.3,2.9-3.3,4.3
				c-0.9,1.3-0.7,2,1,2c0.7,0,1.4-0.8,2.4,0.3c-1.7,1.9-3.3,3.8-5.1,5.7c-1,1.1-1.8,2.1-1.6,3.7c1.3,9.9,0.2,19.9,1.2,29.9
				c0.2,2.1,0.6,4.5-0.8,6.7c-1.7-1.2-3.1-2.7-4.3-4.4c-3.5-4.8-8.2-8.4-11.5-13.4c-0.4-0.6-0.9-1.1-1.6-0.7c-0.9,0.6-0.5,1.3,0,2
				c0.4,0.5,0.8,1,1.3,1.5c6.2,6.8,12.5,13.7,18.7,20.5c-1,1.1-2.5,1.1-3.8,1.7c-0.7,0.3-1.4,0.4-1.9-0.5
				c-3.2-5.9-8.5-10.1-12.7-15.2c-0.4-0.5-1-1-1.7-0.8c-0.9,0.3-0.7,1.2-0.7,2c-0.2,6.3-0.6,12.6-0.6,18.9c0,2-1,3.1-2.4,3.7
				c-2.9,1.2-5.8,2.2-9,3.4c0.3-6.8,0.6-13.5,0.8-20.1c0.4-11.3,0.7-22.6,1.1-33.9c0.1-1.8-1-2.8-1.8-4c-3.3-5.3-6.6-10.5-10-15.7
				c-0.6-0.9-1.2-2-2.4-2c-1.3,0-1.2,1.4-1.6,2.2c-4.6,10.4-9,20.8-13.8,31.1c-2,4.2-2.8,8.6-3.4,13.2c-2.7,1.3-1.7,3.4-0.8,5.2
				c1.2,2.5,2.6,5.1,4.1,7.5c4.5,7.2,8,15,13.1,21.8c-3,2.8-3.3,2.7-5-0.4c-3.2-5.5-6.4-11-9.6-16.6c-0.7-1.1-1.3-2.2-2.6-2.8
				c-0.8-0.9-0.9-2.4-2.6-3.3C143.7,1355.6,142.5,1366.7,141.5,1377.7z M206.6,1179.4c-1.7,1.3-1.7,1.6,0,5.3c0.3-2,3-2.8,1.8-5.2
				c1.4-2.1-1.4-3.5-1-5.4c0,0-0.6-0.3-0.8-0.2c-0.7,0.2-0.6,0.8-0.7,1.3C205.6,1176.6,207.6,1177.8,206.6,1179.4z M197.6,1156.7
				c1.9-1.5-0.3-2.8-0.2-4.2c0-0.7,0.1-1.8-1-1.6c-1.1,0.2-0.9,1.3-0.8,2.2c0.1,1.3,1.9,2,1.2,3.5c-2.8,0.6-2.9,1-0.3,5.8
				C196,1159.8,199.4,1159.1,197.6,1156.7z M192.2,1144.9c3.3-1.4-0.3-2.7-0.1-4.1c0.1-0.7-0.1-1.7-1.2-1.4c-0.9,0.3-0.6,1.2-0.6,2
				c0,1.3,1.9,1.9,1.4,3.5c-4.5,0.3-1.5,2.9-1.3,4.6c1.3,4.3,1.8,8.8,1.9,13.3c1.6-4.8,0.5-9.3-0.6-13.8
				C193.4,1147.8,193.5,1146.4,192.2,1144.9z M218.7,1202.5c3.1-1.8-0.8-2.9-0.4-4.5c0.1-0.7-0.2-1.8-1.1-1.6
				c-1.1,0.2-0.7,1.4-0.7,2.2c-0.1,1.4,2.3,2,1.3,3.7c-4.2,1.1-0.6,3.6-0.9,5.4C217,1205.7,220.2,1205,218.7,1202.5z M228.3,1224.5
				c-0.5,0.1-1.2,0-1.6,0.2c-1.9,1.3-0.1,2.9-0.3,4.3c0,0.3,0.7,1.2,1.1,0.7c1.1-1.3,3.8-1.8,2.6-4.4c1-1.9-1.5-2.9-1.3-4.7
				c0.2-1-0.6-1.6-1.7-1.2c-1.1,0.4-0.8,1.4-0.6,2.2C226.7,1222.8,228.6,1223,228.3,1224.5z M255.3,1280.9c-2.4,1.5-1.3,3.7-1.4,6.3
				c3.1-4.7,3.1-4.7,3.1-6.2c0.6-0.6,1.2-1.3,1.7-2c0.5-0.8,2.9,0,1.9-1.7c-0.9-1.3-1.9,0.6-2.9,0.9c-0.5,0.2-0.5,1.3-1.4,1.2
				c-0.6-0.5-0.4-1.2-0.5-1.8c-0.1-0.7,0.1-1.6-0.7-2c-0.6-0.3-1,0.1-1.3,0.6c-0.6,0.9-0.1,1.6,0.2,2.4
				C254.1,1279.5,255.6,1279.6,255.3,1280.9z M161.9,1253.5c-4.6,1.4-5.9,3.7-4.2,7.3c-0.6,1.2,0.2,2.1,0.8,3c1.3,2,2.5,4.1,3.9,6
				c1.7,2.3,2.1,4.3-0.3,6.4c-0.7-1.1-1.4-2.2-2.2-3.3c-0.8-1.3-1-3.2-3.3-3.1c-0.1-1.2-0.2-2.4-0.4-4c-1.7,1.2-2.1,2.6-2.3,4.1
				c-0.8,6.2-1.8,12.4-2.4,18.6c-0.4,4.5-1.8,8.9-1.1,13.5c-0.3,1-0.7,2.1-1,3.1c-0.1,0.7-0.5,1.6,0.5,2c1.1,0.4,1.5-0.5,1.9-1.3
				c3.2-7.1,6.3-14.2,9.5-21.4c3.1-7.1,6.3-14.2,9.4-21.3c2.5-5.7,5-11.3,7.5-17c0.3-0.8,0.8-1.7,0-2.4c-0.7-0.6-1.4,0-2.1,0.3
				c-5.8,2.8-10.2,7.1-14.2,12.1C161.9,1255.2,161.9,1254.3,161.9,1253.5L161.9,1253.5L161.9,1253.5z M213.7,1190.6
				c0.1-1.5-1-2.8-0.7-4.4c0.1-0.6-0.4-1-1-0.9c-0.3,0.1-0.9,0.6-0.8,0.8c0.6,3.7-2,2.5-3.8,2.5c-0.6,0-1.4-0.2-1.7,0.5
				c-0.3,0.7,0.3,1.2,0.6,1.7c1,1.7,2.8,1.4,4.3,1.7c0.1,1.1,0.2,2.2,0.5,4.2C212.4,1194.3,214.8,1193.3,213.7,1190.6z
				 M177.8,1187.9c0.2,0.5,0.2,1.1,0.5,1.4c1.2,1.3,2,3.6,4.1,3c2-0.6,0.7-2.8,1.2-4.3c0.5-0.3,0.7-0.9,0.6-1.4
				c-0.2-1.9,1-4.4-0.5-5.5c-1.7-1.3-3.6,0.9-5.2,1.7C174.8,1184.8,174.9,1185,177.8,1187.9z M201.7,1174.3
				c0.8-3.8,3.3-7.5-0.1-11.1c-0.7,2.3-2.1,1.7-3.8,0.6c-0.7-0.4-1.1-2.1-2.3-0.8c-1.2,1.2-2.7,2.4-3.1,4.1c-0.4,1.5-0.9,3.1,2,2.3
				c4.4-1.1,5.7-0.1,5.9,3.6c-2-0.1-5-1.9-4,2.6c0.3,1.2-0.9,1.6-2,1.5c-1.7-0.1-1.1,1.2-1.3,2.1c-0.2,1.1,0.5,1.3,1.3,1.3
				c1.3,0,2.6,0.1,2.6,1.7c0,1.5-1.1,2.3-2.5,2.5c-0.5,0.1-1.2,0-1.3,0.7c-0.1,0.6,0.3,1.2,0.8,1.3c3,0.3,3.7,2.7,4.5,4.9
				c0.3,0.8,0.5,1.4,1.5,1.2c0.7-0.1,1.4-0.2,1.7-0.9c0.4-1-0.3-1.7-1-2.2c-0.6-0.4-1.5-0.6-1.9-1.2c-0.7-1.1,1.7-5.9,3-5.9
				c2.3,0,2.3-1.8,1.8-2.9C202.8,1178.1,202.3,1176.2,201.7,1174.3z M183.7,1225.4c0.5,0,0.6-0.3,0.6-0.8c0.3-7.1,0.5-14.1,0.9-21.2
				c0.1-2-0.4-3-2.5-3.3c-3.9-0.6-7.7-1.5-11.6-2.2c-1.3-2.4-2.2-5-4-7.2c-1.9,2.9-0.8,5,0.5,7.6c4.8,10,9.1,20.2,13.5,30.3
				c0.5,1.1,0.7,2.4,2.6,3.3C183.7,1229.5,183.7,1227.4,183.7,1225.4L183.7,1225.4z M175.7,1110.7c-0.5,0-1.4-0.1-1.6,0.2
				c-2.2,4.1-5.8,7.1-8.5,10.8c-0.7,1-0.7,2,0,3c1.5,2.6,3.1,5.3,4.6,7.9c1.1,1.8,2,1.4,2.7-0.3c0.8-2.3,1.7-4.7,2.3-7.1
				c0.7-3,3.4-6,0.6-9.3c-0.5-0.6,0-1.4,0.6-2c0.9-1,0.5-2-0.1-3c1.7-1.7-0.2-3.4,0-5.2c0-0.2-0.6-0.7-0.9-0.7
				c-0.7,0-0.8,0.6-0.9,1.1C174.1,1107.8,175.7,1109,175.7,1110.7z M196.1,1319.6c-0.7,1.4,0.2,2.5,0.9,3.6
				c2.9,4.6,5.7,9.3,9.4,13.3c0.6,0.7,0.9,2,2.1,1.8c1.3-0.2,0.9-1.6,1-2.4c0.1-1.3,0.1-2.6,0.1-3.9c-0.1-6.8-0.3-13.7-0.5-20.5
				c-0.1-1.5,1.1-3.6-0.8-4.5c-1.7-0.8-2.2,1.8-3.6,2.3c-0.8-1.4,1.1-2.3,0.8-4c-1.4,1.3-2.5,2.4-3.7,3.5c-0.7,0.6-1.5,1.1-1.8-0.3
				c3.4-3.2,6.7-6.6,9-10.8c0.6-0.4,0.6-0.8-0.1-1.2c-0.5-23.2-1.1-46.4-1.5-69.6c0-2.2-0.9-2.6-2.6-1.5c-3,1.9-5.8,3.9-8.7,5.8
				c-0.9,0.6-1.1,1.4-1.1,2.5c0,23.3-0.9,46.6-1.9,69.9c-0.1,1.9,1.8,5.4-2.8,5.1c-0.1,0-0.3,0.3-0.4,0.5c-0.9,2.1-2,4.1-2.7,6.3
				c-0.5,1.7-0.8,4,1.3,5c1.8,0.9,2.3-1.6,3.5-2.2c0.1,0,0.1-0.2,0.2-0.2C193.7,1317.7,194.7,1319,196.1,1319.6z M182.4,1275.3
				c0.5,0,1.1,0,1.6,0c0-7.2,0-14.4,0-21.6c0-0.7,0.3-1.6-0.7-1.9c-1-0.3-1.2,0.6-1.6,1.3c-0.1,0.3-0.3,0.6-0.4,0.9
				c-3.9,8.2-6.8,16.8-11.3,24.7c-0.6,1-0.4,2.3,0.4,3.5c3.2,4.7,6.2,9.5,9.3,14.3c0.5,0.8,1,1.6,1.9,1.5c1.3-0.2,0.7-1.4,0.8-2.1
				C182.4,1289.1,182.4,1282.2,182.4,1275.3z M186.2,1128.2c-1.2,3.3-3,1.6-4.4,0.8c-1.7-1.1-1.7-2.6-0.2-4c0.9-0.8,1.1-1.7,0.6-2.6
				c-0.5-1-1.5-0.7-2.3-0.5c-1.5,0.3-1.1,1.6-0.8,2.4c1.2,3.8-0.4,7.1-1.5,10.5c-1.3,4-2.6,7.9,0.8,11.7c1.5,1.7,2.4,3.9,3.6,5.8
				c0.6,1,0.8,2.6,2.4,2.1c1.2-0.4,1.5-1.7,1.5-3c0.1-4.1-0.8-8.6,0.5-12.3C187.8,1135.2,186.2,1132,186.2,1128.2z M217.5,1231.2
				c0.1,0,0.2,0,0.3,0c0,3.4,0,6.7,0,10.1c0,0.7-0.5,2,0.8,2.1c1.1,0.1,1.3-0.9,1.6-1.7c1.2-4,2.1-8,3.6-11.9
				c1.5-3.8,0.3-6.8-1.9-9.8c-0.8-1-1.6-1.8-3-1.5c-1.6,0.4-1.5,1.5-1.5,2.8C217.5,1224.7,217.5,1227.9,217.5,1231.2z M169.8,1092.7
				c-0.1,3.4-1.4,3-3,2.3c-1-0.4-1.8-0.2-1.7,1.1c0.3,5.5-0.7,11,0.4,16.5c0.2,1,0.5,1.9,1.5,1.4c2.4-1.2,3.6-3.7,5.1-5.8
				c1.1-1.4-0.8-1.9-1.3-2.9c-0.3-0.6-0.7-1.5-0.5-1.8C173.1,1100.3,171.6,1097.1,169.8,1092.7z M164.9,1141.2
				C164.9,1141.2,164.9,1141.2,164.9,1141.2c0,1.1-0.2,2.2,0,3.3c0.4,2-1,5.7,0.8,5.8c2.6,0.2,1.5-3.7,2.7-5.4
				c2.8-4.3,1-7.9-1.4-11.6c-0.4-0.6-0.8-1.2-1.5-1.1c-1.2,0.2-0.7,1.1-0.7,1.8C164.9,1136.4,164.9,1138.8,164.9,1141.2z
				 M195.4,1206.6c0.3,4.6,1,5.3,3.3,3.6c1.9-1.4,2.3-3.8,3.8-5.6c0.1-0.1-0.1-0.8-0.2-0.8c-2-0.3-4.2-0.9-6.1-0.5
				C194.5,1203.6,195.8,1205.6,195.4,1206.6z M222,1218.7c0.6-1.3,1.8-1.7,2.6-2.5c1.1-1.2,0.3-2.5,0-3.7c-0.2-0.6-0.9-0.7-1.4-0.4
				c-1.1,0.7-2.6,1.1-2.4,2.7C220.9,1216.1,221.5,1217.4,222,1218.7z M164.4,1092.9c0.6-1.5,2.8-2.8,0.9-4.7
				c-0.6-0.7-1.9-0.5-2.3,0.3C162.1,1090.2,162.4,1091.8,164.4,1092.9z M166.5,1085.8c-1.8-0.8,0.3-3.5-2-4.1
				c-0.8-0.2-0.9,0.8-0.8,1.4c0.2,1.6,0.7,3.1,2.3,3.9C166.3,1087.2,166.5,1086.5,166.5,1085.8z M215.6,1191.5c1.4-0.8,2.8-1,3-2.7
				c0-0.4-0.6-0.7-1-0.5C216.1,1188.7,215.6,1189.8,215.6,1191.5z M182.8,1120.7c-0.5-1.2-0.8-1.9-1.1-2.7c-0.7-1.6-1.9-1.9-2-0.4
				C179.6,1119.3,180.5,1120.3,182.8,1120.7z M172.9,1188.9c-0.2-0.7-0.6-1.4-1.6-1.2c-0.6,0.1-1.5,0.4-1.3,1.1
				c0.2,0.9,1.2,0.8,1.9,0.9C172.5,1189.9,172.9,1189.7,172.9,1188.9z M207.1,1304.1c-0.1,0.2-0.2,0.4-0.2,0.6
				c0.2,0.1,0.4,0.3,0.5,0.2c0.1-0.1,0.2-0.3,0.3-0.5C207.5,1304.2,207.3,1304.2,207.1,1304.1z M210.6,1298.9
				c-0.1,0.1-0.4,0.2-0.4,0.3c0,0.1,0.1,0.3,0.2,0.5c0.2-0.2,0.4-0.3,0.6-0.5C210.8,1299.1,210.7,1299,210.6,1298.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1133.6,1697.1
				c3,19,6,38.1,9.1,57.1c0.4,2.7,2.1,5.2,1.3,8.2c-0.4,1.5,0.9,2.3,2.5,2.3c2.6,0,5.3,0.5,7.9,0.3c2.4-0.2,2.8,0.9,2.3,2.6
				c-0.4,1.5,1.5,3.8-1.5,4.6c-1.8,0.4-2.8,0.2-2.7-1.8c0.1-3.3-1.4-4.6-4.8-4.5c-2.3,0.1-3.1,0.5-2.7,2.9
				c1.3,7.8,2.4,15.7,3.6,23.6c1.3,8.4,2.7,16.7,4.1,25.1c-0.3-0.3-0.7-0.5-1-0.8c-0.5-0.5-0.7-1.5-1.6-1.2c-0.9,0.3-0.6,1.3-0.7,2
				c-0.4,2-1.2,3.7-3.1,4.8c-6.4-4.9-12.8-10-19.3-14.7c-4.1-2.9-7.6-6.6-12.2-8.8c0.2-1.3-1-2.9,0.8-3.9
				c9.2,7.1,18.3,14.1,27.5,21.1c1.3,1,2.6,3.3,4.3,2.2c1.3-0.9,0.5-3.2,0.2-5c-2.3-14.7-4.6-29.4-7-44.1c-0.2-1.2,0.4-2.9-1.3-3.4
				c-1.5-0.5-2.3,1-3.3,1.8c-7.3,6.3-14.6,12.7-21.9,19c-1.9-1.9,0.6-4.3-0.7-6.2c1.5,0.2,2.4-0.9,3.3-1.7
				c6.9-5.8,13.7-11.7,20.5-17.6c0.7-0.6,1.9-1.2,1-2.3c-0.7-0.9-1.8-0.5-2.6,0.2c-1.9,1.4-4.1,2.4-5.6,4.4
				c-1.1,1.3-2.4,0.6-3.6,0.1c-1.2-0.5-1.3-1.8-1.2-2.8c0.1-1.7,1.6-0.9,2.5-1.2c0.4-0.1,0.9,0,1.3-0.2c0.8-0.3,2,0.2,2.2-0.9
				c0.2-1.1-0.9-1.4-1.6-1.8c-4.6-2.7-9.2-5.5-13.8-8.1c-1.3-0.8-2.6-1.9-4.4-1.7c0-1.4-1.1-3.1,0.2-3.9c1.1-0.7,2.3,0.8,3.4,1.5
				c6.6,3.8,13.2,7.7,19.8,11.5c0.9,0.5,1.9,1.6,2.9,0.9c1.1-0.8,0.1-2-0.1-3c0.4-1.8-0.1-3.5-0.3-5.2c-2-14.3-5-28.4-6.2-42.9
				c-0.2-2.5-1.9-3.5-3.5-2.1c-6.6,5.6-13,11.2-19.6,16.9c-0.9-0.4-0.8-1.2-0.9-1.9c-0.4-2.6-0.8-5.1-1.3-7.7
				c-0.2-1.1-0.8-1.8-1.8-2.1c-0.7-0.2-1.7,0.2-1.6,0.7c0.3,2.2-1.9,4.1-1.5,5.6c0.8,2.4-0.1,5,1.3,7.3c0.5,0.9,0.3,2.6-0.5,3.1
				c-0.8,0.5-1.7,1.8-2.9,1.1c-1.2-0.8-1-2.1-0.7-3.3c0.6-1.9,0.1-3.4-1.3-5.1c-0.2,1.3-0.3,2.2-0.4,3.2c-0.3,5.7-1.5,11.4,3.2,16.2
				c0.9,0.9,1.9,2.8-0.9,2.7c-1.2,0-1.3,0.5-1.3,1.3c0,1.4-0.1,2.8-0.1,4.2c0.1,2.7,1.4,3.6,4,2.6c4.6-1.7,7.1,0,6.8,4.7
				c-0.1,1,0.4,2.1,0.7,3.1c0.8,2.5-1.4,3.2-2.7,4.3c-1,0.8-2.5,1.8-3.7,0.1c-0.8-1-1.8-1.4-2.9-0.8c-1.3,0.7-1.2,2.1-0.9,3.2
				c0.6,2.4,0.5,4.6-0.3,6.9c-0.1,0.4-0.4,0.9,0,1.2c2.7,1.9,1.2,4,0.6,6.3c-0.8,2.9-0.9,6,0.8,9c1-1,0.6-1.8,0.8-2.5
				c0.2-0.8,0.7-1.4,1.6-1.4c1,0,1.4,0.7,1.3,1.5c-0.1,0.8-0.5,1.6,0.8,1.7c1,0.1,1.4-0.5,1.4-1.4c0.1-1,0.7-1.4,1.5-1
				c0.9,0.5,2.7,1.2,2.1,2.1c-1.2,1.9-0.5,4.1-1.6,5.9c-0.4,0.7-1.1,1.8-1.5,1.3c-1.4-1.4-4.5,0-4.8-3.1c-0.1-0.9-1-1.5-2-0.8
				c-1,0.7-0.4,4.4,0.8,4.8c1.3,0.5,2.6,0.7,4.4,1.2c-1.4,1.1-1.4,2.9-3.5,2.8c-1.7-0.1-1.4,1.4-1.4,2.4c0,1.4-0.4,3.9,0.6,3.7
				c2.6-0.5,2.7,1.5,3.6,2.7c0.6,0.7,1.5,1.6,0.6,2.6c-0.8,0.8-1.5-0.1-2.3-0.5c-1.6-0.9-2.7,0.4-3.9,1c-0.6,0.3-0.4,0.9-0.2,1.4
				c1.8,3.5,1.2,7.2,0.9,10.8c-0.3,3.7-1.4,7.4,1.6,10.5c1.2,1.3,2.9,2.3-0.4,3c-0.8,0.2-0.6,1.2-0.3,2c1.9,4.5,2.7,9.2,2.9,14
				c0.1,1.1,0.2,2.2,0.7,3.1c0.7,1.2,1.9,1.4,2.4,0c1.2-3.4,2.8-2.7,4.6-0.7c0.9,1,1.9,1,3,0.7c1.5-0.4,0.4-1.4,0.4-2.1
				c0-0.9,0.5-1.5,1.2-1.8c1.4-0.6,1.4-1.8,1.5-3c4-1.1,6.5-4.3,9.5-6.7c6.6-5,13.5-9.7,19.9-14.9c1.8-1.5,4.1-2.8,6.7-4.1
				c1.2,10.4,4.1,20.3,3.2,30.6c-1,0.3-2,1.1-3.2,0.7c-0.2-0.4-0.6-0.7-0.6-1.1c-0.2-7-1.7-13.9-2.7-20.9c-0.1-0.9,0.1-2-1.1-2.5
				c-1-0.4-1.5,0.5-2.2,1c-5.7,4.3-11.1,9.1-17.1,12.9c-2.9,1.9-3.5,4.5-2.2,7.6c0,0.5,0.1,1,0.1,1.5c-0.4,1.1,0.1,2,0.8,2.8
				c-0.7,0.7,0,2.8-2,2.2c-1.4-0.5-1.3-1.5-0.7-2.7c0.4-0.7,0.3-1.8,0.1-2.6c-0.3-1.3,0.2-3.7-2-3.4c-2.1,0.3-4,1.8-3.4,4.3
				c0.5,2.1,1.8,4.3-2.1,4.3c-0.5,0-0.7,0.8-0.5,1.4c0.2,1,1,1.1,1.8,1c3.4-0.2,6.7,0.2,10.1,0.7c1,0.2,2.4-0.2,2.5,1.4
				c0.1,1.4-1.1,2.1-2.3,2.3c-1.2,0.2-2.4,0.2-3.6,0.2c-4.8,0-9.6-0.3-14.4,0.1c-3.3,0.2-6-1.7-9.2-1.6c-0.5,0-1.1-0.9-1.7-1.4
				c-0.4-0.3-1.1-1-1.2-0.9c-2.3,2.4-5.4-0.8-7.8,1c-0.3,0.3-1.2,0.3-1.6,0c-5-3.6-11-2.6-16.5-2.7c-4.3-0.1-8.5-0.7-12.8-0.6
				c-4.9,0.2-9.9-0.8-14.9-1.2c-0.5,0-1-0.2-1.5-0.5c4.8-1.4,9.6-1.3,14.5-0.6c6.6,0.9,13.2,1.3,19.8,1c6.7,1,13.5,1.2,20.3,1
				c4.1,1.3,8.3,1.3,12.5,1.4c0.5,0,1.1-0.1,1.1-0.8c0-0.7-0.6-0.8-1.1-0.9c-4.1-1-8.3-1-12.5-1.2c-0.9-0.7-1.9-0.8-3-0.8
				c-2.5,0.1-5,0-7.5,0c0.5-2.2-0.7-3.2-2.6-3.8c-2.9-3.1-7.2-3.7-10.5-6.1c-1.7-1.2-3.8-3.4-5.7-2.3c-1.4,0.8-0.3,3.6-0.2,5.5
				c0.1,1.2-0.1,2.4,0.7,3.4c-0.1,0.9-0.3,1.8-1.5,1.6c-2.6-0.4-5.3,0.8-7.8-1.5c-1.4-1.3-3.6-0.2-5.4-0.1c-5,0.4-5.2,0.3-5.3-4.5
				c-0.2-4.6-0.3-9.1-0.5-13.7c0-0.7,0.3-1.6-0.5-2c-0.9-0.5-1.7,0.2-2.4,0.7c-1,0.7-1.9,1.6-2.5,2.8c-1.1-0.1-2.3,0-3.1,0.7
				c-5.9,4.8-12.3,8.9-17.9,14.1c-17.8-0.8-35.5,0.4-53.2,1.4c-0.2,0-0.5-0.1-0.7-0.1c0-1.6-0.1-3.1,2-3.5c5-0.9,10.1-0.3,15.1-0.6
				c2.5-0.2,5.4,0.7,7.9,0.6c7.4-0.2,14.7,0.1,22.1,0.5c2.3,0.1,4.3-0.1,6.2-1c0.7-0.3,1.9-0.4,1.8-1.6c-0.1-1-1.1-1.2-1.9-1.6
				c4.6-3.6,9.2-7.2,13.8-10.7c7.1-5.4,14.5-10.3,21.1-16.3c2,0.2,3.1-0.3,2.9-2.7c-0.2-2-0.4-3.2-3-2.9c-2.5,0.3-3.9-2.5-6.3-3.1
				c-3.1-0.7-2.2-3.6-2.3-5.7c-0.4-7.8-0.7-15.6-1.1-23.3c-0.1-2.1-0.5-3.6-2.9-1.4c-1.2,1.1-3,1.7-4.5,2.5
				c-0.3,0.4-0.5,1.1-0.9,1.3c-5.7,2.3-10,6.7-15,9.9c-1-0.1-2.1-0.2-1.8-1.7c8.6-6,17.2-12,25.8-18c0.6-0.4,1.5-0.8,0.4-1.6
				c-1.6-1-5.8,0.3-6.8,2c1-3.7-2.8-9.1-6.7-9c-3.5,0.1-7-0.3-10.4-0.1c-1.9,0.1-2.7-0.5-2.3-2.3c0.6-3.2-0.9-4.3-3.9-3.9
				c-1.1,0.1-2.2,0-3.3,0.1c-0.1-1.6,1.6-1,2.1-1.7c2.6,1,5.4,0.5,8,1c1,0.2,1.8,0,2.1-1c0.3-0.9-0.5-1.3-1.1-1.8
				c-3.2-3-6.3-5.9-9.5-8.9c-0.2-0.9,0.4-1.4,1.1-1.8c0.8,0.7,1.7,1.3,2.5,2c4.2,3.6,8.5,7.2,12.7,10.8c3,3.6,6.5,6.7,10.1,9.6
				c1.1,0.8,1.5,3.1,3.1,2.4c1.7-0.8,0.2-2.9,0.8-4.5c0.4-0.9-0.4-1.5-1.6-1.3c-3.8,0.4-7.9-2.1-9.5-5.8c2.9,0.1,5.8,0.2,8.7,0.4
				c0.8,0,1.6,0,1.8-0.9c0.2-0.6,0.4-1.5-0.2-1.8c-2.8-1.4-2.4-2.5-0.1-4c0.4-0.2,0.3-1.4,0.2-2.2c-0.6-14.2-1.2-28.5-1.9-42.7
				c0-0.7,0.3-1.6-0.6-2c-1-0.4-1.6,0.3-2.2,0.9c-5.4,4.9-10.8,9.8-16.2,14.7c-0.9,0.8-1.6,2.1-3.1,2.2c-0.3-1.8-0.2-3.4,1.6-4.6
				c1-0.7,1.1-1.5,0-2.3c-1.4-0.9-8.3-0.6-9.9,0.5c-0.5-0.5-1-0.4-1.4,0.1c-0.5-2-2.1-1.5-3.4-1.6c-10.1-0.3-20.3,0.2-30.4,0.6
				c-0.8,0-1.8-0.1-2.2,0.9c-1-0.5-1.9-0.3-2.8,0.1c-7.6-0.8-15,0.6-22.6,1.2c-0.6-1.6,1.1-2.3,1.5-3.5c10.7-0.9,21.4-2.5,32.2-2.6
				c13.4-0.1,26.9-1.6,40.1,1.5c1.2,0.3,1.8-0.5,2.5-1.2c5-4.5,9.9-9.2,15.1-13.5c3.3-2.7,5.4-6.9,10.1-7.8c0.2,0,0.4-0.7,0.4-1.1
				c-0.1-0.9-0.2-2.2-1.3-2.4c-1.3-0.2-2.6,0.2-3.4,1.4c-1.2,1.7-2.4,3.5-4.3,4.7c-0.6-1.1,0.3-3.2-2.1-2.7l0.1-0.2l-0.2-0.1
				c0.9-1.8-0.6-2.4-1.7-3.1c-6.3-4.3-12.6-8.6-18.8-13c-1.7-1.2-3-1.2-4.7,0.2c-4.1,3.5-8.3,6.8-12.5,10.1
				c-0.7,0.5-1.3,1.9-2.4,0.8c-1.1-1.2-0.3-2.1,0.8-3c3.2-2.6,6.4-5.2,9.7-7.8c4.4-3.5,4.4-3.5-0.5-6.8c-9.5-6.5-19-13.1-28.5-19.7
				c-1-0.7-1.8-1.9-3.1-1.4c-1.4,0.6-0.9,2.1-0.9,3.2c-0.5,8.6-1,17.2-1.3,25.8c-0.1,2-0.9,2.8-2.8,2.7c-2.1-0.1-1.2-1.7-1.1-2.6
				c0.4-7.6,0.8-15.2,1.2-22.8c2.4,0.1,2.7-1.5,2.6-3.3c0.7-2.1-0.3-3.9-1.3-5.6c-1.2-2.2-0.6-3.4,0.9-5.1c9-9.9,17.9-20,26.9-29.9
				c11-12.2,22-24.4,33.3-36.3c2.3-2.4,3.6-5.9,7.3-7.1c1.2-0.4,0.5-1.7,0.2-2.6c-0.3-0.9-1-0.9-1.9-0.6c-1.9,0.9-3,2.5-3.9,4.2
				c-2,3.6-4.7,6-8.9,6.5c-3.7,0.4-3.8,0.7-2.1,3.9c0,0.1,0.2,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1c-0.7-0.8-1.4-1.6-2.3-2.3
				c-1.7-1.3-3.8-1-5,0.2c-1.2,1.2,0.8,2.1,1.5,3.1c0.8,1.1,2.3,1.9,2.4,3.5c-1.8-1.4-3.3-3.1-4.8-4.8c-2.3-2.5-3.7-2.4-5.5,0.5
				c-1.3,2-2.4,4.1-3.6,6.2c-0.4,0.7-1.4,1.4-0.8,2.3c0.6,0.9,1.6,0.3,2.4,0.2c3.3-0.6,6.5-1.2,9.8-1.9c-1.7,2.3-3.1,4.8-5.9,5.7
				c-0.4,0.1-0.8,0.1-1.2,0.2c-2.2-0.4-4.3,0.4-6.5,0.8c-3.8,0.7-7.7,1.4-11.5,2.1c-2.9,0.5-4.4-1-5.1-3.6c0.5-0.2,1-0.4,1.5-0.6
				c0.7-0.2,1.4-0.4,2.1-0.6c1.3,0.4,3,1.3,3.8,0.1c0.9-1.3-1-2.1-1.7-3.1c-0.4-0.5-0.8-1-1.1-1.5l0.2-0.2c1.5,0.6,2.2,2,3.4,3
				c1.8,1.4,4.8,1.4,5.9-0.2c1.6-2.4,3.1-5,4.7-7.5c0.6-1,0.4-2.4-0.7-2.1c-5.1,1.7-11.8-0.9-15.1,5.5c0,0.1-0.4,0-0.6,0
				c-0.5,0-0.9,0-1.4,0c0.6-0.7,1.1-1.4,0.2-2.2c-0.9-0.7-1.9-0.5-2.7,0c-1.5,0.9-0.1,1.9,0,2.8c-0.9,0.1-2-0.1-2.8,0.3
				c-0.8,0.3-1.7,1.1-1.4,2.2c0.3,1.2,1.2,0.9,2.1,0.7c0.1,0.3,0.3,0.5,0.4,0.8c-0.8,1.2-0.1,1.9,0.8,2.6c2.6,3.9,6,7,9.3,10.3
				c1,1.3,2,2.6,3,3.8c0.8,0.9,2.5,1.6,0.9,3c-1.7,1.5-2.1-0.7-2.9-1.4c-2.7-2.3-4.2-5.8-7.6-7.3c-0.1-0.7-0.9-0.9-1.2-1.4
				c-1.9-3.3-4.8-2-7.3-1.5c-2.7,0.6,1,2.5-0.4,3.6c-0.2,0.1-0.3,0.3-0.4,0.5c-1.7,0.2-2,1.2-1.9,2.7c0.6,9.2-0.9,18.3-0.9,27.4
				c0,3.1-1.6,4.6-4.1,5.8c0.5-10.6,1.1-20.9,1.6-31.3c0.1-1.3,1-3.2-0.9-3.8c-1.5-0.5-3.3-0.1-4.6,1c-0.7,0.6-1.5,1.3-2.2,1.9
				l0.2-0.2l-0.1-0.2c0.5-1.1,0.4-1.9-1-2c-0.9-0.1-1.9,0.6-2.8-0.2c1.3-0.1,2.6-0.3,3.9-0.2c2.6,0.1,4.1-1.2,5.3-3.4
				c-1.3-0.5-2.3-0.1-3.3,0.2c-2.7,0.6-5.5,1-8.2,1.6c-1.6,0.3-1.1-0.5-0.7-1.3c0.5-1,1.7-0.9,2.5-1.3c2.7-1.3,5.5-2.6,6.6-5.7
				c0.4-1,0.8-2.2-0.7-2.7c-1.2-0.5-2.1-0.3-2.3,1.3c-0.1,1.3-1.1,1.6-2.2,2.1c-4,1.8-7.9,3.9-12,5.5c-3,1.2-5.8,3.3-9.3,3.2
				c0-0.4,0-0.8,0.1-1.2c4.2-1.9,8.5-3.8,12.6-5.8c4.2-2,8.3-4.2,12.4-6.2c1.4-0.7,2-2.4,0.8-2.5c-3-0.3-6.1-3.3-9.2-0.4
				c-2.7,2.5-7.3,1.2-9.6,4.6c-0.1,0.2-0.8,0.2-0.9,0.1c-2.2-2.8-4.6-0.2-6.9-0.4c-1.6-2.2-1.3-4.5-0.7-6.9c0.9,0,1.8,0.2,2.6,0
				c8.8-2.5,18-3.1,27-5c4.9-1,4.9-0.9,5.6-5.9c0-0.2,0-0.4,0-0.7c0.3-0.9,0.4-1.8,0.1-2.7c0-0.6,0-1.2-0.1-1.8
				c0.1-0.3,0.1-0.5,0.2-0.8c0.7-10.2,1.4-20.4,2.1-30.6c0.2-3.8,0.7-7.6,0.4-11.4c-0.1-1.7-0.6-3,0.6-4.6
				c6.3-8.3,12.5-16.7,18.8-25.1c0.5-0.6,1.2-1.1,0.8-2c-0.4-0.8-1.2-0.8-2-0.8c-3.2,0-6.3,0-9.5-0.1c-1.8-0.1-2.3,0.7-2.4,2.3
				c-0.2,4.1-0.9,8.3-0.8,12.4c0.1,3.5-2.1,5.3-4.5,7.5c0.5-6.8,0.9-13.2,1.4-19.6c0.2-1.9-0.4-2.6-2.3-2.6c-3.3,0.1-6.6,0-9.8-0.1
				c-0.5-0.5-0.9-1.1,0.1-1.5c3.4,0.1,6.8,0.1,10.2,0.3c1.8,0.1,2.2-0.6,2.3-2.2c1-16.7,2.1-33.5,3.2-50.2c0.7-10.7,1.4-21.3,2-32
				c0.1-1.2,0.7-2.6-1.1-3.3c-1.1-0.4-0.5-1.3,0-1.9c1.6-2,1.8-4.3,1.9-6.8c1.5-26,3.1-52,4.6-78c0-0.7,0.4-1.7-0.3-2.1
				c-1.1-0.6-1.3,0.9-1.9,1.2c-2.7,1.4-3.7,3.2-3,6.4c0.5,2.3-1,4.2-3.2,5.2c-1.5,0.6-2.1,1.3-1.5,2.9c1.2,3.5-0.1,6-3.4,7.4
				c-1.5,0.7-1.5,1.5-1.2,2.8c0.9,4.2,0.4,5.4-3.3,7.4c-1.3,0.7-1.7,1.3-1.3,2.8c1.1,4.4,0.5,5.6-3.4,7.6c-1.2,0.6-1.7,1.1-1.2,2.5
				c0.4,1,0.5,2.1,0.5,3.2c0.1,1-0.1,2.1-1.2,2.5c-1.1,0.4-1.5-0.8-2-1.3c-4.3-4.9-10-8-15-11.9c-7.7-6-16.5-10.1-25.4-14
				c-0.2-0.3-0.3-0.6-0.5-0.9c-1.2-0.9-2.5-1.7-3.7-2.6c-0.8-0.6-1.5-1.4-1-2.5c0.5-0.9,1.5-0.7,2.3-0.5c4.9,1.1,9.8,2.1,14.2,4.7
				c1.2,0.7,2.6,1.5,4.7,1.3c-4.8-4.8-8.1-10.5-14.8-12.4c-1-0.3-1.6-1.3-1.5-2.3c0.1-1.2,1.3-1,2.1-1.1c1.7-0.3,3.2,0.5,4.8,0.8
				c5.6,1.4,10.9,3.8,16.4,5.7c-2.1-2.5-4.6-4.5-7.5-6c-2.3-1.2-4.1-3-6-4.8c-2-1.9-4.1-4.9-8-3.3c-1.5,0.6-2.9-1.1-3.2-2.2
				c-0.4-1.4,1.4-0.8,2.2-1c4.2-1,7.9,0.9,11.9,1.6c4.6,0.9,8.7,3.6,14.1,4.9c-4.8-3.8-9.3-6.8-13-10.5c-4.1-4.1-10.3-2.6-15.2-5.9
				c12.6-2.3,23,2.5,33.7,6.7c-0.1-1.5-1.2-1.8-2-2.2c-4.1-2-7.4-5-10.8-7.9c-3.6-3-6.7-6.4-12-4.5c-0.3,0.1-0.9,0-1.2-0.3
				c-0.7-0.6-1.9-1.1-1.6-2.3c0.4-1.4,1.6-0.7,2.5-0.6c1,0.1,2,0.6,2.9,0.5c7.2-0.9,13.5,2,20,4.2c2.3,0.8,4.3,2.3,6.9,2.2
				c-2.7-1.9-5.1-4.1-7.3-6.4c-4.3-4.5-9.1-7.8-15.7-7.4c-1.5,0.1-1.9-0.7-2-1.9c-0.1-1.6,1-1.3,2-1.3c6.3-0.2,12.3,1.2,18.1,3.7
				c1.6,0.7,3.2,1.2,4.8,1.8c1.6,0.6,3.2,1.2,4.8,1.9c-7.3-5.9-14.1-12.2-23.2-15c-0.7-0.2-1.8-0.1-1.7-1.1c0.1-1.2,1.2-1,2-1.2
				c5.6-1.1,10.8,0.9,16,2.5c3.5,1.1,6.9,2.5,10.3,3.7c-2.3-2.4-5.1-4-7.3-6.3c-4.8-4.9-10.7-7.3-17.1-9.2c-0.7-0.2-1.3-0.4-1.2-1.2
				c0.1-0.7,0.8-0.7,1.3-0.7c1.6-0.1,3,0.8,4.5,0.7c6-0.3,11.3,1.5,16.8,3.6c2.2,0.9,4.3,2.3,7.5,2c-1.5-1-2.7-1.7-3.7-2.6
				c-5.4-4.9-11.3-9.1-18.2-11.6c-1.6-0.6-3.2-0.5-4.8-0.5c-0.8,0-1.6-0.3-1.6-1.2c0-1.1,1-1.1,1.7-1c1.3,0.1,2.6,0.7,3.9,0.6
				c8.8-0.6,16.8,2.7,24.8,5.6c1.6,0.6,3,1.9,5.4,1.9c-4-4.4-8.9-7.1-12.9-10.7c-4.3-3.9-10.2-3.7-14.7-6.8
				c11.5-0.9,21.7,3.4,32.2,7.4c-3.5-2.3-6.7-4.9-9.4-7.9c-3.4-3.8-6.8-7.4-12.3-7.8c-0.5,0-1-0.4-1.5-0.6c-0.5-0.2-1.2-0.4-1-1.1
				c0.2-0.9,0.9-0.6,1.6-0.6c5.5,0.5,10.8,1.6,15.9,3.6c4.4,1.7,8.7,3.6,13.7,5.7c-2.4-3-6-3.4-7.7-5.9c-2.8-4.2-8.4-5.1-11.1-9.5
				c-0.2-0.3-0.8-0.5-1.2-0.4c-1.9,0.5-3.6-0.4-3.4-1.9c0.3-1.8,2.4-1.2,3.8-0.9c0.2,0,0.4,0.1,0.6,0.1c6.9,1.4,13.4,3.8,19.6,6.7
				c-3.3-3.5-6.4-7.3-10.2-10.4c-1.8-1.4-3.3-4-6.2-3.6c-1.3,0.2-2.4,0.1-3.3-1c-0.4-0.5-0.4-1.1-0.1-1.5c1-1.3,2.4-0.8,3.7-0.5
				c5.4,1.1,10.7,2.5,15.7,4.8c1.9,0.9,3.8,1.6,5.7,2.4c-0.7-1.6-1.9-2.4-2.7-3.5c-1.9-2.3-4.9-3.2-6.8-5.5c-3-3.6-6.5-6.3-11.5-6.6
				c-0.7,0-1.8,0.1-1.7-1c0-1,1-1,1.8-1c3.1,0,6.1,0.7,9.1,1.4c5.6,1.3,10.7,3.8,16.3,5.6c-4-2.7-6.6-6.7-10.1-9.7
				c-1.3-1.2-2.8-2.4-4.7-2.9c-1.1-1.6-2.9-2.1-4.5-2.8c-0.3-2.5,1.5-1.3,2.4-1.2c5.8,1.1,11.6,2.3,16.8,5.4
				c0.8,0.5,1.6,0.5,2.5,0.3c0.2-0.9-0.4-1.5-0.9-2c-4.5-4.7-9.4-8.9-15.2-12.1c-0.6-0.3-1.4-0.3-1.1-1.2c0.2-0.6,0.8-0.6,1.4-0.6
				c0.9,0.1,1.7,0.3,2.6,0.5c7.8,1.3,14.8,4.7,22.1,8.1c-0.1-1.8-1.4-2.2-2.5-2.5c-1.4-0.3-2.4-1.1-3.3-2
				c-4.4-4.6-9.2-8.5-14.6-11.9c-0.6-0.3-1.2-0.8-0.8-1.5c0.3-0.7,1.1-0.6,1.7-0.5c2.1,0.4,4.3,0.9,6.4,1.4
				c5.5,1.4,10.8,3.5,16.1,5.9c-1.9-1.9-4.2-3.3-5.9-5.1c-5.2-5.6-10.9-10.3-18.6-12.1c10.2-0.2,19.6,2.8,29.3,7.5
				c-1.2-2.2-2.6-3.3-4-4.2c-5.1-3.2-8.7-8.3-13.9-11.3c-0.6-0.3-1.2-0.8-0.8-1.6c0.3-0.7,1.1-0.7,1.7-0.5c0.6,0.1,1.3,0.3,1.9,0.5
				c7,1.4,13.4,4.2,20,6.9c-1-1.1-2-2.1-3.1-2.8c-4.2-2.8-8.3-5.9-11.7-9.6c-1.9-2-4.3-2.6-6.6-3.4c-1.2-0.4-2.6,0.2-3.9,0.1
				c-0.8-0.1-1.8-0.6-1.7-1.6c0.1-1,1.2-1.1,1.8-0.8c3.9,1.3,8,0.5,11.9,1.6c5.8,1.5,11.3,3.7,16.9,6c-3.4-4.3-7.9-7.4-11.8-11.1
				c-4-3.9-10.1-3.5-16-5c5.9-2.6,15.1-0.6,32.8,6.7c-0.4-1.3-1.3-2.3-2.4-2.8c-2.4-1-4-2.8-5.9-4.4c-3.5-2.9-6.6-6.4-11.2-7.7
				c-0.7-0.2-1.2-0.6-1.2-1.4c0.1-1,0.8-1.3,1.6-1.2c1.8,0.2,3.7,0.5,5.5,0.9c6.2,1.3,12,3.7,18.4,6.9c-1.4-2.1-2.6-3.2-4-4
				c-2.5-1.3-4.5-3.1-6.4-5c-2.3-2.3-4.5-4.7-8.2-4.7c-0.6,0-1.3-0.8-1.8-1.3c-0.5-0.6-1.8-1-1.2-2c0.4-0.8,1.5-0.8,2.4-0.6
				c4.2,0.8,8.4,1.5,12.4,2.9c3,1.1,6,2.4,9.2,3.4c-3.1-3.2-6-6.8-9.9-8.5c-4.3-1.8-7.5-4.7-11.2-7.3c9.5-0.5,18,2.6,26.4,6.4
				c-5.7-6.7-14-10-19.9-16.9c9.1,0.7,17.2,3.4,25.5,7.9c-5.7-6.4-12.6-10-18.4-15c-0.8-0.7-1.9-0.5-2.8-0.6
				c-0.9-0.1-2.3,0.3-2.1-1.2c0.1-1.3,1.3-1.1,2.3-1c4,0.5,8,1.1,11.9,2.1c4.3,1.1,8.1,3.3,12.3,4.6c-5.7-6.2-13.3-9.7-20-14.3
				c-0.8-0.5-2.6,0.1-2.2-1.6c0.3-1.3,1.9-0.8,2.9-0.8c6.2,0.3,12.1,1.7,17.7,4.3c1.2,0.5,2.1,1.2,4,0.4c-5.3-4.9-11-8.6-15.8-14.6
				c7.4,0.7,13.6,2.7,19.9,5c-1.7-1.8-3.1-3.7-4.9-5.2c-3.1-2.5-6.4-4.9-9.7-7.2c-0.7-0.5-1.6-1.2-1.2-2.1c0.4-1.1,1.5-0.5,2.3-0.4
				c2.3,0.4,4.5,0.8,6.8,1.3c5,1.1,9.6,3.2,14.6,6c-0.5-1-0.5-1.4-0.7-1.6c-7-6.8-13-14.7-21.8-19.5c-1.3-0.7-0.9-1.4-0.5-2.4
				c0.9-2.8,2-5.5,1.5-8.6c0.1-0.1,0.2-0.2,0.3-0.3c11.6-2.5,29.1,1.3,34.1,8.1c-5.1-2.6-9.4-3.4-14.3-2.2c-2.4,0.6-3.6,1.7-4.7,3.3
				c-1.6,2.5-0.6,4.2,1.7,6c5,3.7,11,5.5,16.5,8.2c1.6,0.8,1.4,3.1,3.7,3.1c3.5,0.1,5.2,3.9,8.6,4.6c0.1,0,0,0.8,0.1,1.2
				c-0.2,0.4-0.4,0.7-0.7,1.1c-0.7,0.4-1.7,0.6-0.5,1.6c0.2,0.3,0.4,0.9,0.7,1c13.7,4.1,27.3,8.3,41,12.4c2.6,0.8,3.4-0.6,3.2-3
				c-0.3-3.5-0.7-6.9-0.6-10.4c0.1-3.2-1.1-4.7-4.2-4.7c-0.3-0.4-0.6-0.4-0.9,0.1c-1,0-2.1,0.1-3.1,0.1c-0.5-0.4,0.5-2.1-1.3-1.3
				c-0.6-1.9-2.5-1.4-3.8-1.8c-4.6-1.4-9.2-2.4-13.8-3.9c-4.6-1.5-9.1-3.6-14-3.9c-0.7-0.9,1-1.9-0.1-2.9c0.5-1.8,0.1-3.4-1.2-4.9
				l0.1-0.1c1.3-0.6,2.3,0,3.4,0.5c0.6,0.3,1.2,0.8,1.8,0.3c0.8-0.7,0-1.4-0.3-2c-1.7-2.9-3.5-5.7-5.1-8.6c-0.6-1.2-1.5-1.5-2.6-1.6
				c-3.8-0.5-4.7-1.6-4.3-5.8c1.8-2.3,3.2-4.6,3.2-7.7c0-6.3,0.1-6.3,4.3-6.6c2.1,1.2,2.7,2.8,1.5,5.1c-0.5,0.8-1.2,2,0.4,2.6
				c1.4,0.6,2.7,1.4,4.3,1.2c5.7-0.6,11.3-1.8,17.3-0.8c5,0.9,10.2,0.6,15.3,1.2c2.3,0.3,4,0.3,3.4-2.8c-0.2-1.1,0.9-0.6,1.3-0.9
				c3.4-0.1,6.4,1.4,9.4,2.6c2.4,1,0.6,3.7,1.1,5.6c0.3,1.5-0.6,1.5-1.8,1.3c-0.6-0.1-1.1,0.2-1.1,0.9c0,0.8,0.5,1.3,1.2,1.3
				c1.9,0,1.6,1.1,1.6,2.4c-0.1,6,0,12,0,17.8c-0.8,0.7-1.4,0.2-2,0c-0.5-0.1-1.1-0.3-1.5,0.2c-0.5,0.7-0.1,1.3,0.3,1.7
				c4.1,4.4,3.2,9.8,3.1,15.4c-1.9-0.5-3.6-0.9-5.4-1.4c-0.9-0.3-1.9-0.5-2.2,0.6c-0.3,0.9-0.7,2.1,0.7,2.8c0.9,0.4,1.6,1.1,2.5,1.5
				c4.2,1.9,5.1,5.2,4.5,9.3c-0.2,1.5-0.1,3-0.2,4.5c-0.2,3.6-0.4,3.8-4.1,2.6c-8-2.5-15.9-5.1-23.9-7.5c-9.6-2.9-19.3-5.7-28.9-8.6
				c-1.2-0.4-2.1-0.4-2.9,0.5c-1.4,1.5-3.1,2.4-5,2.9c-2,0.5-2.4,1.5-1.2,3.3c2.4,3.8,1.9,7.3-1.6,10.1c-1.4,1.1-1.5,2-1.2,3.5
				c0.5,2.5-0.3,4.9-2.4,6.4c-1.5,1.1-2.1,2-1.7,4c0.6,2.5-0.4,4.8-2.5,6.3c-1.4,1-2.1,1.9-1.6,3.7c0.7,2.5-0.3,4.8-2.4,6.4
				c-1.4,1-1.9,2.1-1.6,3.8c0.4,2.8-0.6,5.2-3,6.8c-1.4,0.9-1.5,1.7-1.2,3.2c0.6,2.9-0.3,5.4-2.9,7.1c-1.3,0.8-1.8,1.4-1.3,3.1
				c0.9,2.7-0.3,5.2-2.4,6.8c-1.7,1.3-2.2,2.5-1.5,4.4c0.2,0.5,0.4,1.2,0.2,1.6c-2.9,6-0.8,12.2-0.7,18.2c0.1,5.6,1,11.3,1,16.8
				c-0.1,9.9,0.5,19.7,0.7,29.5c0.4,18.2,1.1,36.4,1.9,54.5c0.1,2.9,0.6,5.8,0.7,8.7c0.1,1.9-0.6,2.4-2.3,1.5
				c-0.6-0.3-1.2-0.7-1.8-0.7c-6.4,0.2-9.9-3.7-13.2-8.5c-3-4.4-3.3-4.3-5.9,0.8c-0.7,0.2-0.4,1.6-1.6,1.1c0-1.2,0.9-2.4,0.3-3.6
				c0.2-1-0.2-2,1-2.8c0.9-0.5,1.4-1.8,0.6-2.9c-1.6-2.3-3.4-4.5-5.1-6.8c-0.3-0.5-0.9-0.8-1.4-0.4c-0.3,0.2-0.4,0.7-0.3,1.1
				c0.6,4-0.9,8.4,2.5,11.8c1,1.3,0.4,2.3-0.7,3.2c-1.2,0.9-2.2,2.1-2.7,3.5c-0.3,0.8-1,1.7-0.2,2.4c0.8,0.8,1.8,0.3,2.5-0.4
				c0.8,0,1.6,0,2.5,0c0.4,0.5,0.8,0.8,1.5,0.6c1.3,0.2,2.6,0.5,3.8,0.7c0.6,0.7,1.3,0.8,2.1,0.6c7.2,1.7,14.4,3.4,21.7,5.1
				c0.3,1.2-4.2,2.2,0.6,3.6c-2.6,0.5-3.3,1.5-3,3.6c2.4,14,4.7,28,7,42c0.2,1.1,0.4,2.1,0.6,3.2c-1,0.8-2.6-1-2.9-0.1
				c-0.5,1.6-1.2,3.8,0,5.3c1.6,1.9,1.6-1.7,3-1.3c0.3,1,0.6,2,0.9,3c2.8,16.6,5.5,33.3,8.3,49.9c0.5,2.8,0.4,5.9,2.6,8.2
				c0.1,0.1,0.3,0.4,0.3,0.5c-1.9,7.8,1.6,15.2,2.3,22.7c0.8,7.8,2.3,15.5,3.5,23.2c0.3,1.9,0.7,3.9,1,5.8c-0.4,0.4,0.4,2.6-1.3,1.1
				c-2-1.8-2.1-0.7-1.9,1c0.2,1.5-0.4,2.6-1.5,3.6c-6.4-8.7-12.8-17.3-19.2-26c-1-1.3-1.8-3.2-3.4-0.4c-5.5,9.6-11,19.3-16.6,28.8
				c-2.4,4-2.1,8-0.8,12.2c0,1.1,0,2.2,0.9,3c4.6,3.9,9,8.1,14,11.5c1,2.3,3.4,2.6,5,0.1c2.3-3.8,5.7-6.5,8.3-10
				c4.4-6,9.2-11.8,14.2-17.3c1.3-0.3,1.2-2.5,2.9-2.2c1.5,9,3,17.9,4.4,26.9c0.3,2.3,1.6,2,3.3,2c17.1-0.9,34.1-1.9,51.1-4
				c1-0.1,2.1-0.7,3.1,0.2c-0.2,1.4-1.3,1-2,1.1c-16.8,2.2-33.8,3.1-50.7,4c-4.1,0.2-4.1,0.2-3.4,4.4c0.8,4.9,1.7,9.9,2.2,14.9
				c0.3,3,1.3,4,4.4,4c15.9,0.1,31.7,1.2,47.6,0.6c0.6,0,1.3-0.3,1.9,0.6c-0.9,0.8-2,0.4-3,0.5c-15.7,0.5-31.3-0.2-47-0.7
				c-2-0.1-3.4,0.2-2.6,2.6c1.9,5.4,1.3,11.2,2.4,16.7c1.1,5.6,2.3,11.2,2.3,17.1c0,1.9,1.4,3.8,2,5.8c0.4,1.4,1.9,0.5,2.9,0.4
				c12.8-2.2,25.7-4.5,38.5-6.7c0.5-0.1,1.1-0.2,1.6-0.3c2.6-0.9,3.5,0.3,2.9,2.6c-0.4,1.8,1.3,4.2-1.6,5.3c-0.6,0.2,0,1.2,0.4,1.6
				c0.8,0.8,1.6,1.5,1,2.8c-1.2-0.2-1.7-1.2-2.4-1.9c-2.7-2.8-3-2.8-4.9,0.4c-1.4,2.2-2.8,4.4-4.2,6.6c-0.3,0.5-1,1.1-0.5,1.8
				c0.4,0.6,1.2,0.4,1.8,0.3c3.3-0.6,6.6-1.2,10.1-1.9c0.4,1.9-0.3,3.8-0.1,5.5c0.4,3.6-3.4,1.2-3.8,3.2l-0.2,0l-0.1,0.2
				c-0.8-0.6-1.7-0.8-2.7-0.6c-3.2,0.7-6.4,1.2-9.7,1.8c-1.6,0.3-3.6,0.4-4,2.1c-0.5,1.7,0.1,3.7,1.7,5c0.6,0.5,1.1,1.1,1.6,1.7
				c-0.1,0.8-0.7,1.2-1.4,1.4c-0.9-1.5-1.8-3.1-3.8-3.6c1-1.6,1.2-3.2,0-4.7c-0.5-0.7-1.3-0.7-2-0.2c-1.4,1,0.3,2.1,0,3.2
				c-2.1-1.6-3.8-3.2-7.1-1.6c-2.2,1.1-5.1,1.4-7.8,1.7c-1.7,0.2-2.4,1-2.1,2.8c2.7,17.1,4.9,34.2,8.1,51.2c0.9,4.5,1,9.1,2.2,13.6
				c0.3,0.4,0.6,0.7,0.9,1.1c-1.1,0.9-0.6,1.9-0.4,3c1.9,12,3.7,24,5.9,35.9c0.7,3.6,1.1,7.2,1.6,10.8c-1.6,0.5-1.6-1.7-3.1-2.1
				c-0.2,2.3,0,4.7-2.1,6.1c-4.2-3.9-8.4-7.8-12.8-11.6c-1.8-1.5-3.3-3.4-5.6-4.3c-0.1-1.4-1.4-2.1-2.1-3c-1.3-1.6-3.7-2.1-4.7-4.1
				c0.3-0.9,1.3-1.6,0.8-2.7c7.3,6.7,14.7,13.3,22,19.9c0.6,0.5,1.1,1.7,2.1,1.1c0.9-0.5,0.3-1.5,0.3-2.3c0.1-7.7-3-15-3.3-22.7
				c-0.2-4.2-1.5-8.4-1.9-12.6c-0.3-3.5-2.1-6.7-1.7-10.3c0.1-0.7-0.3-2.7-1.9-1.6c-1.5,1-3.4,1.4-4.4,3.1c-1.9,3.4-6.1,4.9-6.9,9.5
				c-0.2,1.3-3.2,3-5.6,3.6c-3.6-1-7.4,0.1-11.1-0.8c3.2-0.2,6.2-2,9.5-1c0.6,0.2,1.4,0.1,1.7-0.6c0.4-0.8-0.2-1.2-0.8-1.5
				c-1.3-0.7-0.2-1.4-0.1-2.2c0.5-0.2,1.1-0.4,1.4-0.7c5.1-5.3,10.3-10.6,15.4-15.9c0.3-0.3,0.5-0.8,0.4-1.1
				c-0.2-0.7-0.9-0.6-1.3-0.3c-1.5,0.9-2.4-0.1-3.5-0.8c-4.4-2.7-8.4-6.1-13.1-8.5c-0.8-0.4-0.9-1.2-0.9-1.9c2.5-0.7-0.4-1.7,0-2.9
				c5.2,3.4,10.2,6.8,15.2,10c0.9,0.6,1.7,2.2,3,1.3c1-0.7,0.1-2,0.1-3.1c0.1-0.5,0.3-1.1,0.2-1.6c-2.5-14.8-4.9-29.7-7.5-44.5
				c-0.1-0.8,0.7-2.1-0.9-2.4c-1.2-0.2-1.7,0.8-2.4,1.5c-2.5,2.3-4.8,4.8-7.5,6.8c-1.7,1.3-2.3,2.1-0.3,3.6c1.9,1.4,1.5,3.3-0.3,4.2
				c-1.6,0.8-1.7,1.8-1.6,3.1c0.1,1.1,0.1,2.2-1,2.8c-0.6,0.3-1.2,0.6-1.7,1c-3.7,2.6-9.8,1.2-11.9-2.9c-0.1-0.6-0.2-1.3-0.2-1.9
				c0.5-0.9,0.2-1.1-0.7-0.8c-2-0.3-4.5-1.6-2.8-3.3c2.5-2.4,2.9-5.8,5.1-8.1c3.5-0.8,7-0.9,10.6-0.7c1.2,1.1,2.3,1.7,4,0.4
				c4.3-3.5,8.5-7.2,12.5-11.1c3.5-3.4,3.5-3.4-0.4-6.5c-0.1-0.1-0.4,0-0.7,0.1c-0.2,2.1-0.2,4.3-2.2,5.6c-1.4-1.3-2.9-1.2-4.6-0.8
				c-2,0.5-4.7,2.3-5.9,0.5c-0.9-1.4,1.5-3.4,3.3-4.6c0.9-0.6,1.2-1.9,0.9-2.9c-0.4-1-1.5-0.5-2.3-0.4c-1.3,0.2-2.5,0.3-2.6-1.5
				c0.7,0,1.6,0.3,1.8-0.6c0.3-1.2-0.8-1.8-1.5-2.5c0-0.4-0.1-0.9-0.1-1.3c0-1.1-2.7-1.5-1.3-2.6c1.5-1.2,2.2,1,3.2,1.8
				c0.2,0.1,0.3,0.3,0.5,0.5c0.9,0.6,1.5,2.6,2.7,1.4c1.1-1.1-0.5-2-1.3-2.8c-0.6-0.7-1.4-1.4-2.1-2c-1.6-1.3-2.3-2.8-2-4.8
				c1.8-0.4,3.6-0.9,5.4-1.1c2.4-0.2,3.5-1.1,3.1-3.7c-1.3-8.6-2.5-17.1-3.8-25.7c-0.1-0.7-0.3-1.3-1.1-1.4
				c-0.8-0.1-1.3,0.5-1.7,1.2c-1.2,1.8-2.5,3.5-3.8,5.3c-0.4-0.2-0.7-0.4-1.1-0.5c2.1-2-0.2-4.6,1-6.7c1.4-0.8,2-2.3,3-3.4
				c0.6-0.7,0.6-1.6-0.2-2.3c-1.4-1.1-2.7-2.3-4.1-3.2c-1.1-0.7-2.3-0.7-2.1,1.1c0.4,3.5-1.8,3.7-4.3,3.4c-1.7-0.2-3.5-0.4-5.2-0.1
				c-1.2,0.2-1.9-0.1-1.8-1.5c0.3-3.6-2.3-3.2-4.4-2.6c-3.7,1.1-7.1,2.8-10.3,4.9c-3.8,2.4-3.8,2.3-0.6,5.8
				c4.3,4.7,8.4,9.4,12.6,14.1c-0.1,0.9-0.2,1.9-0.3,2.8c-4.3-4.7-8.5-9.3-12.8-14c-4.4-4.9-4.4-4.9-10-1.2
				c-1.6,1.1-2.4,2.1-2.2,4.2c0.4,5.3,0.4,10.7,0.8,16c0.3,5,0,10,1.1,14.9c0.2,1.1,0.5,1.8,1.8,1.7c5.7-0.7,11.6-1.1,14.5-7.4
				c0.4-0.8,0.9-1.5,1.5-2.1c2.1-2,2.5-5.3,5.3-6.8c0,1.2-0.1,2.4,1.6,2.7c1.2,0.2,1.3,1,0.4,1.8c-0.3,0-0.9-0.1-0.9,0
				c-1.1,3-3.1,5.5-4.6,8.5c2.7-1.2,4.2,0.8,6.1,1.6c0.4,0.1,0.8,0.2,1.3,0.3l0.1,0c0,0.7-0.1,1.3-1,1.2c-1,0-2.1,0-2.1,1.4
				c0,1.1,0.9,1.3,1.8,1.5c0.5,0.1,1.1,0.2,0.9,0.8c-1.8,1.4-4.5-0.8-5.7,2c-2,0-3.9,0.1-5.9,0.1c-3.8,0.1-5.6,1.8-5.7,5.6
				c0,0.4,0,1.1-0.4,0.9c-2.1-1.1-2.6,0.6-2.9,1.9c-0.4,1.7,1.3,2.2,3.3,2.8c-3.1,1.3-5.6,2.4-8,3.4c-0.8,0.3-0.7,0.9-0.3,1.5
				c0.7,1.4-0.1,3.1,1,4.4c0.5,1.7-1.5,3.7,0.5,5.3l0,0c0.3,1.1,1.2,1.5,2.2,1.6c4.8,3.3,9.7,6.7,14.5,10.1c0.9,0.6,2.5,0.8,1.3,2.6
				c-1.1,1.7-2.1,1.2-3.5,0.2c-4.3-3-8.7-5.9-13-8.7c-1.8-1.2-2.6-0.8-2.4,1.4c0.3,4,0.4,8,0.7,12c0.2,2.6,0.3,5.3,0.5,7.9
				c-0.6,0.8-2.3,0.5-2.1,1.7c0.6,2.6-1.6,6.1,1,7.5c2.7,1.4,4.2-2,6.1-3.7c4.7-4.8,9.7-9.4,14.2-14.4c2.4-2.7,4.1-2.8,6.2,0
				c-1.4,2.1-4.2,1-5.9,2.7c-3.4,3.5-7,6.9-10.5,10.3c-1.5,1.5-2.3,3.6-4.6,4.6c-2.4,1.1-2.7,3.1-0.4,5.1c3.1,2.6,6,5.4,8.9,8.1
				c5.4,4.9,10.8,9.8,15.5,15.5c-1.6,0.7-2.6,0-3.7-1.1c-6.9-6.5-13.9-12.9-20.8-19.4c-0.6-0.6-1.1-1.5-2.1-1.1
				c-1.1,0.5-0.5,1.6-0.5,2.3c0.5,8.1,1,16.2,1.5,24.2c0.3,5.5,0.7,11.1,1,16.6c0.1,1.4,0.1,2.8,2.1,2.1c-0.1,0.2-0.2,0.4-0.1,0.6
				c-1.1-0.1-1.7,0.6-1.8,1.5c-0.1,3.8-0.4,7.6,0.7,11.3c0.5,1.7,1.4,1.9,2.7,0.5c6-6.6,12.2-13.1,18.3-19.6
				c0.4,0.2,0.8,0.4,1.2,0.6c0.8,1.2,2.5,0.9,3.4,2.1c-0.7,0.7-1.3,1.3-2,2c-3,0.2-8.7,4.5-9.6,7.3c-0.2,0.7-0.5,1.5,0.1,2.1
				c0.7,0.6,1.4,0.2,2.1,0c4-1.1,5.9-4.3,7.3-7.8c0.8-0.1,2.1,2.1,2.3-0.1c0.1-1.6,1.6-2.2,1.6-3.6c0-0.2,0.1-0.4,0.1-0.6
				c0.8-0.1,1.7-0.1,2.5-0.2c2.1-0.3,2.3,0.8,1.8,2.4c-4.6,2.2-5.1,3.6-4.2,11.3c2-0.1,3.6-0.6,4.1-2.8c1.3-2.1,3.5-2,5.6-2.3
				c1.3-0.2,3.3,1.1,3.8-0.7c0.4-1.7-1.1-3.1-2.7-4.1c-0.5-0.3-1-0.6-1.3-1c-0.5-0.7-1.3-1.5-0.9-2.3c0.5-0.8,1.6-0.4,2.4-0.3
				c1.3,1.3,2.6,2.7,3.9,4c0.1,4.9,0.9,5.5,6.1,5.1c1.9,0.9,3.6,6,2.7,7.8c-0.3,0.7-0.8,0.6-1.3,0.4c-1.1-0.3-2.1-0.7-2.7-1.7
				c-1.6-2.2-3.9-2.1-6.2-1.8c-1.8,0.2-2.4,1.6-1.4,2.8c1.1,1.3,2.2,3,4.4,2.9c1.5,0,3-0.1,4.5-0.6c0.8-0.3,2.3-0.8,2.6,0.2
				c0.3,1.3-1.2,1.2-2.1,1.3c-1.2,0.1-2.4,0.4-2.2,1.8c0.5,4-1.9,7.9-0.2,12c0.5,1.1,0.7,2.6,0,3.8c-0.5,1-0.3,1.7,0.3,2.3
				c1,0.8,1.9,0,2.6-0.6c5.4-4.7,10.8-9.5,16.3-14.2C1129.6,1700,1130.9,1697.2,1133.6,1697.1z M1040.4,1280.6
				c-1.3,0.1-2.3,0.7-2.3,2.1c0,0.2,0.4,0.5,0.7,0.5c1.6,0,0.2-1.7,1.2-2.2C1040.2,1281,1040.3,1280.7,1040.4,1280.6
				c0.9,0.4,0.9,1.9,2.2,1.9c0.4,1,1.2,2.5,1.9,2.3c2.8-0.7,6.4,1,8.2-2.6c0.4-0.8,1-1.2,1.9-0.9c3.5,0.9,7.3,0.3,10.9,3.4
				c-0.6-1.2-0.7-1.7-1-2.1c-1.1-1.5,0.2-4.2-2.8-4.9c-2.3-0.5-4.2-0.2-6.3,0.4c-2.9,0.8-6.3-0.2-7.6-2.3c-1.5-2.5-3.5-2.1-5.3-2.9
				c-2.4-1-2.6,0.9-3.9,2.5c-3.1,3.7-0.4,3.1,1.7,3.5c0.3,0,0.6,0.3,0.9,0.4C1040.9,1279.6,1040.6,1280.1,1040.4,1280.6z
				 M1150,1066.2c5.5-2.5,5.5-2.4,1.4-5.5c-2.2-1.7-2.8-0.8-2.7,1.5c-0.5,0.3-0.5,0.6-0.1,1c0,0.3-0.1,0.6-0.1,0.9
				c-0.6,0.7-0.5,1.6-0.6,2.4c-0.1,1.1,0,2.1,1.1,2.8c0.5,0.3,1.1,0.5,1.4-0.1C1151,1068.1,1150.4,1067.2,1150,1066.2z
				 M1048.9,1376.5c0.6-0.1,0.9-0.6,0.9-1.2c0.2-0.4,0.5-0.8,0.6-1.2c0.3-2.7,0.4-5.5,3.9-6.5c0.7-0.2,1.7-2.2,1.4-2.9
				c-0.6-1.5-1.5,0.2-2.3,0.3c-1,0.1-1.3-0.7-1.8-1.2c-2.1-2.2-4.1-4.4-6.3-6.6c-0.6-0.6-1.1-1.6-2.1-1.2c-0.9,0.4-0.3,1.4-0.3,2.1
				c0.2,3.9,0.5,7.8,0.7,11.7c0,0.5,0.1,1,0.1,1.5c0,1.5,0.1,3,0.1,4.4c-2.8,1.9-2.3,5-2.6,7.7c-0.2,1.5,1.3,1.8,2.5,0.8
				C1046.3,1382.2,1047.9,1379.6,1048.9,1376.5L1048.9,1376.5z M1036.2,1537.5c0.3-0.3,0.5-0.7,0.9-0.9c1.8-1.3,1.9-3.3,0.8-4.6
				c-1.4-1.6-2.4,0.5-3.3,1.3c-3.1,2.8-5.9,5.8-9,8.4c-2.4,2-2.3,3.3,0.3,4.8c0.3,0.9,0.9,0.7,1.5,0.3c0.4-0.1,0.8-0.3,1.2-0.4
				c-0.2,0.8-0.7,1.8,0.1,2.4c2.5,2,5.1,4.1,7.7,5.9c1.5,1.1,2.7,0.6,2.6-1.6c-0.2-4.5-0.4-9.1-0.5-13.6c0-1.1-0.5-1.6-1.5-1.7
				C1036.9,1537.7,1036.6,1537.5,1036.2,1537.5z M1054.9,1498.4c3.6-4.3,7.8-8.2,10.9-13.2c-5-1.1-9.5,0.1-14.1,0.1
				c-1.6,0-1.9,1-1.8,2.4c0.2,2.6,0.2,5.2,0.3,7.8c-3,1.6-1,4.4-1.7,6.6c-0.2,0.6,0.3,1.3,1,1.4c2.3,0.3,3.1-1.6,4.3-3
				C1054.5,1500,1055.2,1499.4,1054.9,1498.4z M1025.1,1419.6c-0.7,0.1-1.2,1.2-1.9,0.3c-0.8-1.1,0.4-2,0.5-2.9
				c3.3-3.9,7.1-7.4,9.9-12.2c-3.4,0-3.7-2.8-4.8-4.8c-5.1-9.6-10.1-19.3-15.2-28.9c-0.3-0.6-0.4-1.6-1.4-1.6
				c-0.9,0-1.2,0.8-1.6,1.4c-4.2,7.5-8.3,15-12.6,22.4c-0.5,0.9-1,1.7-0.4,2.7c0.2,1.2,1,2.2,1.8,3c12,12.7,23.9,25.5,35.9,38.2
				c0.7,0.7,1.5,2.1,2.6,1c0.8-0.8,0.1-2-0.6-2.9c-1.7-2-3.2-4.1-4.2-6.6c0-4.8-0.1-9.5-0.1-14.3c0-0.9,0.4-2.1-0.8-2.5
				c-1-0.3-1.4,0.7-2,1.4C1028.5,1415.4,1026.8,1417.5,1025.1,1419.6z M1014.7,1561.6c-0.3,1.2-0.8,2.6,1.3,2.3
				c6.6-0.8,13.4,0.5,19.9-1c0.5-0.1,1.2,0.2,1.4-0.5c0.3-0.8-0.3-1.2-0.8-1.6c-3.3-2.8-6.6-5.6-9.8-8.4c-1-0.9-2.2-1.4-3.1-0.1
				c-1.9,2.9-4.9,4.9-6.6,8C1016.5,1561.4,1015.7,1561.8,1014.7,1561.6c2.1-3.6,5.5-6.4,8.1-9.7c0.8-1,1.4-1.8,0.1-2.6
				c-1.3-0.8-2.5-2.8-4.3-1c-6.8,6.6-13.5,13.2-20.3,19.9c-0.4,0.4-0.8,0.7-0.2,1.7c3.4-1.9,8.6-0.2,11-4
				C1010.7,1563.5,1012.5,1562.6,1014.7,1561.6z M1133.3,1560c5.1,3.1,6.4,3,8.7-0.8c1.1-1.9,2.3-3.7,3.4-5.6c0.4-0.6,1.1-1.3,0.7-2
				c-0.5-1.1-1.5-0.5-2.3-0.4c-4.5,0.6-9,1.9-13.6,2.1c-0.6,0-2.3-0.1-1.6,1.6l0.1,0c-0.9,0.7-1.5-0.5-2.4-0.4
				c-1.3,0.1-2.9-0.4-3.6,0.9c-0.9,1.5,0.3,2.6,1.3,3.6c0.8,0.9,2.1,1.6,2.2,3.4c-2.2-0.2-3-2-4.3-3.2c-0.6-0.6-1.4-1.6-2.1-1
				c-1.2,1.2-0.3,2.8-0.3,4.3c0.1,1.1,0.5,2.1,2,2.1c3.2-0.1,6.5,0,9.7-0.3c2.9-0.2,3.3-1.5,1.6-3.8
				C1133,1560.4,1133.2,1560.2,1133.3,1560z M1025.8,1665.8c-1.1-0.2-2.1-0.6-2-2c6.8-5.1,13.6-10.1,20.4-15.2
				c0.6-0.4,1.4-0.9,1.2-1.8c-0.3-0.9-1.4-0.8-2-0.5c-2.4,1.1-3.6-0.6-4.9-2c-9.4-10.5-18.9-20.9-28.2-31.4c-1.7-1.9-3-1.9-4.5,0
				c-6.5,8.1-13.8,15.4-20.6,23.2c-1,1.1-1.5,2.1-1.2,3.5c-1.2,1.5,0.2,2.1,1.1,2.8c6,4.2,11.9,8.3,17.9,12.4
				c14.6,10,29.2,20,43.8,30.1c1.2,0.8,1.8,0.1,2.6-0.2c0.7-0.2,1.2-0.8,1-1.5c-0.4-0.9-1.3-0.9-2.2-1.2c-1.3-0.5-3.2,0.9-4.1-1.1
				c0.1-8.9-0.3-17.7-1-26.6c-0.2-2-1.1-1.7-2.2-0.9C1035.8,1657.5,1030.8,1661.7,1025.8,1665.8z M1069.6,1834.5
				c-0.5,1.7,0.1,2.8,1.7,3.7c4,2.1,8,4.4,12,6.5c1.7,0.9,3.5,3.1,5.1,2.1c1.6-1-0.3-3.4-0.1-5.2c0.4-3.2-1.1-4.4-4.2-3.5
				c-0.9-0.9-0.2-3.3-2.6-2.9c-0.2,0-0.7-0.5-0.8-0.8c-0.2-0.6,0.3-1,0.9-1.1c0.8-0.2,1.7-0.4,2.5-0.4c1.8,0,3.7-0.8,3.4-2.5
				c-0.5-2.1-2.3,0.3-3.7-0.2c0.4-0.7,0.2-1.6,1.2-2.1c1.5,0.2,2.9,0.4,2.8-1.8c-0.3-4.3-0.5-8.6-0.8-12.9c-0.1-2-1-1.9-2.1-0.6
				c-5.3,2.3-9.1,6.7-13.3,10.5C1068,1826.6,1067.6,1829.6,1069.6,1834.5z M1072.3,1484.3c-0.9,0.1-2.2-0.4-2.6,0.8
				c-0.4,1.2,0.8,1.6,1.5,2.2c4.1,3.4,8.2,6.7,12.4,10.1c0.7,0.6,2,1,0.6,2.1c-2.1,0.7-3.7-0.2-5.3-1.6c-3.3-3-6.9-5.7-10.3-8.6
				c-1.4-1.2-2.4-1.1-3.5,0.4c-4,4.9-8,9.8-12.1,14.6c-1.3,1.5-1.2,2.5,0.2,3.9c2.1,2.1,4.1,4.2,6,6.4c1.2,1.4,2.3,1.7,3.9,0.5
				c5-3.8,11.4-5,16-9.6c0.3-0.1,0.5-0.2,0.8-0.2c1.6-0.5,3.2-0.9,4.8-1.4c3.4-0.7,6.7,1.3,10.1,0.4c4.3,1.2,4.4,1.1,3.7-3.1
				c-0.9-5.4-1.7-10.7-2.5-16.1c-0.2-1.3-0.4-2.7-2.2-2.5C1086.6,1483.3,1079.4,1483.2,1072.3,1484.3z M1062.8,1710.9
				c0.1,1.4,0.2,2.8,1.6,3.7c5.9,3.5,11.8,7,18,10.6c0.1-1.6,0.1-2.7-0.4-3.9c-0.2-4.1-0.4-8.3-0.5-12.4c0-1.7-1.2-3.7,1.2-4.9
				c0.4-0.2,0.4-0.9-0.1-1c-2.2-0.5-1.6-2.3-1.8-3.7c-0.2-1.2-0.8-2.1-2.1-2c-2.5,0.3-2.6-1.2-2.6-3c0-1.2-0.1-2.4-0.1-3.6
				c0-0.6,0.1-1.4-0.6-1.7c-0.9-0.4-1.4,0.3-2,0.8c-2.7,2.8-5.2,6-8.2,8.4C1060.8,1701.9,1061.7,1706.3,1062.8,1710.9z
				 M1067.9,1750.5c3.5-2.9,6.9-6,10.6-8.5c3.9-2.5,5.2-5.6,4.4-10c-0.3-2-0.4-3.5-2.7-4.5c-1.8-0.8-3.7-1.7-5.4-2.9
				c-3.1-2.2-6.5-4-9.8-5.9c-1.5-0.9-2.7-0.7-2.3,1.4c1.2,7.1-0.6,14.5,2.3,21.3c0.1,0.3-0.5,0.9-0.8,1.3c-1.6,0.1-2.3,0.9-2.4,2.5
				c-0.1,1.8-0.4,3.6-0.6,5.5c-0.1,1.1-0.6,2.6,0.6,3.2c1.2,0.6,2.6,0,3.7-0.8C1066.4,1752.3,1067.1,1751.3,1067.9,1750.5z
				 M1041.1,1611.4c0.6-5.9-0.2-11.9-0.5-17.8c-0.1-2.2-1.9-2.6-3.9-2.3c-2.1,0.3-4.1,1-5.8,0.3c1.9-1.5,4.6-1.4,7.3-1.5
				c2.4-0.1,2.8-1.2,1.6-3.1c-1.2-0.9,1.3-2.6-0.8-3.2c-2-0.6-7.8,2.5-8.8,4.6c-3.1-0.3-3.4,2.9-5,4.3c-3.6,3.1-7,6.5-8.9,11
				c-0.7,0.1-1,0.5-1,1.1c-2.5,0.1-4.1,1.8-5,3.7c-0.9,1.8,1.6,2.2,2.5,3.3c9.1,10.3,18.3,20.5,27.5,30.7c0.5,0.5,0.7,1.5,1.7,1.2
				c1.1-0.3,0.6-1.3,0.6-1.9c0.2-7.9,0-15.8-1.3-23.7c0-0.7,0-1.3,0-2c0.3-1.1,0.1-2.1-0.2-3.2
				C1041.1,1612.4,1041.1,1611.9,1041.1,1611.4z M1034.5,1442.8c-0.7-1.1-1.2-2.2-2.2-3.2c-3.5-3.6-7-7.2-10.3-10.9
				c-1.9-2.2-3-2-4.8,0.2c-7.3,9.2-14.9,18.2-22.3,27.3c-2.9,3.5-2.9,3.8-0.1,7.5c3.6,4.9,7.2,9.9,10.7,14.9c0.6,0.9,2.2,2,0.6,3
				c-1.4,0.9-1.6-1-2.1-1.8c-3.3-4.6-6.5-9.3-9.7-13.9c-0.5-0.7-1-1.6-2-1.3c-0.8,0.2-0.7,1-0.7,1.6c-0.3,5.8-1.5,11.5-1.2,17.4
				c0.1,1.9,0.7,2.5,2.5,2.5c3.7,0,7.4,0.1,11.2,0.2c1.5,0.1,2.4-0.6,3.2-1.7c6.4-8.8,12.8-17.6,19.5-26.2c4.2-5.5,7.6-11.6,13-16.2
				c0.7-0.6,0.6-1.4,0.1-2.1c-0.6-0.8-1.4-0.7-2-0.1C1036.6,1440.7,1035.7,1441.6,1034.5,1442.8z M1040.5,1525.1
				c-4.2,0.7-5.6-1.6-7.1-3.9c-7-10-14.2-20-21.2-30.1c-2-2.9-2.5-2.9-4.6,0c-5.5,7.3-10.8,14.7-16.4,22c-1.9,2.5-1.6,4.2,0.7,6
				c9,7.3,18,14.5,26.9,21.9c1.7,1.4,2.9,1.4,4.5-0.1C1028.7,1535.8,1034.2,1530.9,1040.5,1525.1z M985.1,1569.5c0.1,0,0.2,0,0.3,0
				c-0.1,1.1-0.1,2.2-0.2,3.2c-0.2,1,0,1.9,0.8,2.3c0.9,0.5,1.6-0.3,2.3-0.9c2.1-2,4.2-4.1,6.4-6.1c7.1-6.8,14.2-13.5,21.3-20.2
				c1.4-1.3,2.1-2.5,0.2-4c-8.6-7-17.2-14.1-25.8-21.1c-0.7-0.5-1.4-1.2-2.3-0.7c-0.8,0.4-0.5,1.3-0.5,2.1c-0.3,5-0.6,10-0.9,15
				C986.2,1549.2,985.7,1559.4,985.1,1569.5z M1056.8,1363.2c4.8-8.7,8.6-17.6,13.7-25.7c0.9-1.5,0.5-2.9-0.4-4.3
				c-4.7-7.5-9.3-15-13.9-22.5c-0.4-0.6-0.7-1.5-1.6-1.3c-0.9,0.2-0.8,1.1-1.1,1.8c-3.4,8-6.7,16.1-10.4,24
				c-1.7,3.7-1.1,7.2-0.5,10.8c0.5,3,2.5,5.7,4.7,7.2C1051.1,1355.7,1053.9,1359.1,1056.8,1363.2z M1065.9,1415.4
				c1.3,0,1.6-0.8,2-1.6c3.4-6,6.8-12.1,10.3-18.1c1-1.7,1-2.8-0.5-4.2c-6.7-6.9-13.3-13.9-19.8-20.9c-1.7-1.8-2.7-1.8-3.9,0.4
				c-2.6,4.7-5,9.6-8.3,14c-1,1.4-0.6,2.8,0.4,4.2c6.2,8.3,12.3,16.6,18.4,24.9C1065.1,1414.6,1065.3,1415.4,1065.9,1415.4z
				 M1029.1,1326.3c-0.2-1.9,0-3.1-0.8-4.2c-2.5-3.4-5.1-6.8-7.7-10.2c-1.2-1.5-2-1.1-3,0.4c-5.5,8.5-11.1,17-16.7,25.4
				c-1,1.6-0.9,2.9,0,4.5c2.8,4.9,5.4,10,8,15c0.4,0.7,0.6,1.8,1.6,1.8c1.1,0,1.2-1.1,1.7-1.8c5-8.9,10.1-17.8,15.1-26.8
				C1028.2,1329,1029.3,1327.5,1029.1,1326.3z M1067.6,1813.7c-0.1,0-0.2,0-0.3,0c0,0.4,0.1,0.9,0,1.3c-0.4,1.6,0.9,2.5,1.7,3.2
				c1.1,0.8,1.8-0.7,2.5-1.4c4.5-3.8,8.9-7.7,13.5-11.4c1.1-0.9,1.5-1.7,1.3-3c-0.6-5.2-1-10.4-1.7-15.5
				c-0.9-6.3-4.4-11.8-12.1-10.6c-1.2,0.2-2.6,0.4-3.6,0c-3.2-1.4-3.1,0.3-3,2.6C1066.5,1790.5,1067,1802.1,1067.6,1813.7z
				 M993.6,1436.2C993.6,1436.2,993.6,1436.2,993.6,1436.2c-0.4,4.7-0.7,9.3-1,14c-0.1,1.1-0.3,2.4,0.9,2.8c1.2,0.4,2-0.6,2.7-1.4
				c1.7-2.2,3.5-4.4,5-6.7c4.4-6.7,10-12.4,14.8-18.7c1-1.2,1.6-2.2,0.2-3.6c-6.1-6.4-12.1-12.8-18.2-19.3c-0.5-0.5-1-1.3-1.9-0.9
				c-0.7,0.3-0.5,1.1-0.6,1.8C995,1414.8,994.3,1425.5,993.6,1436.2z M1048.6,1204.4c-3.5,7.5-6.4,14.3-9.7,20.8
				c-2.9,5.9-4,12.1,0.9,17.8c2,2.3,3.9,4.6,5.8,7.1c1.1,1.5,1.7,1.7,2.6-0.2c2.4-4.5,4.5-9.3,7.6-13.3c4.7-6.1,4.4-11.5,0.4-17.7
				C1053.3,1214.5,1051.3,1209.6,1048.6,1204.4z M1030.9,1368.5C1030.9,1368.5,1030.9,1368.5,1030.9,1368.5
				c-0.4-8.9-0.8-17.9-1.3-26.8c0-0.7,0.3-1.7-0.6-2c-1-0.4-1.2,0.6-1.6,1.2c-4.3,7.7-8.6,15.4-12.9,23.1c-0.6,1-0.9,1.9-0.3,3.1
				c5.1,9.5,10.1,19.1,15.3,28.6c0.4,0.8,0.9,2.2,2,1.9c1.2-0.3,0.8-1.6,0.7-2.6C1031.8,1386.1,1031.3,1377.3,1030.9,1368.5z
				 M1045.7,1393.5c-1.3,0.3-0.7,1.3-0.7,2.1c1,16.5,2.1,33.1,2.6,49.6c0,0.6-0.6,1.7,0.7,1.8c0.9,0.1,1.2-0.6,1.6-1.3
				c4.4-8,8.8-16,13.5-23.8c1.3-2.2,1.1-3.7-0.3-5.5c-5.4-7.1-10.6-14.4-15.9-21.6C1046.9,1394.2,1046.6,1393.5,1045.7,1393.5z
				 M1036.8,1503.1c-0.1,0-0.3,0-0.4,0c0-4.5-0.1-8.9,0.1-13.4c0.1-2.6-0.6-3.5-3.3-3.3c-5.9,0.4-11.8,0.5-17.7,0.7
				c-0.8,0-1.9-0.5-2.3,0.5c-0.4,0.9,0.3,1.6,0.9,2.3c7.4,9.1,12.4,19.8,20.4,28.4c0.7,0.7,1.1,2.4,2.4,1.7c0.9-0.5,0.8-1.9,0.8-3
				C1037.3,1512.5,1037.1,1507.8,1036.8,1503.1z M1071.7,1342.3c-1.1,0.1-1.3,1-1.7,1.8c-2.5,4.3-5,8.7-7.5,13
				c-3.9,6.8-3.9,6.8,1.5,12.6c4.4,4.7,8.8,9.4,13.2,14.1c0.5,0.5,1,1.3,1.9,0.9c0.7-0.3,0.4-0.9,0.3-1.4
				c-2.3-13.2-4.5-26.4-6.7-39.5C1072.6,1343.2,1072.6,1342.5,1071.7,1342.3z M1089.1,1446.3c0.1-0.2,0.3-0.4,0.3-0.6
				c-2.2-14.3-4.4-28.6-6.7-42.9c-0.1-0.7,0.1-1.7-1-1.8c-0.9-0.1-1.1,0.8-1.5,1.5c-2.9,5.4-6.1,10.7-9.5,15.8
				c-1.1,1.6-0.7,3.1,0.5,4.7c5.3,7.1,10.4,14.2,15.6,21.4C1087.4,1445.3,1087.7,1446.5,1089.1,1446.3z M1035.6,1471
				c-0.2,0-0.3,0-0.5,0c0-4.1,0-8.2,0-12.4c0-0.7,0.2-1.7-0.7-2c-1-0.3-1.5,0.6-2,1.3c-6.3,8.5-12.6,16.9-18.9,25.4
				c-0.5,0.6-1,1.2-0.6,2c0.5,0.9,1.3,0.5,2,0.5c6.3-0.2,12.7-0.5,19-0.6c1.8,0,2.5-0.7,2.4-2.5
				C1035.9,1478.8,1035.8,1474.9,1035.6,1471z M1001.2,1314.6c-0.4,6.2-0.6,10.5-0.8,14.9c0,0.8-0.6,1.9,0.6,2.2
				c0.9,0.2,1.2-0.7,1.6-1.4c4.5-6.9,9-13.9,13.7-20.8c1.1-1.6,0.7-2.7-0.4-4c-3.2-4.1-6.3-8.3-9.5-12.5c-0.5-0.7-0.8-1.8-1.8-1.6
				c-1.3,0.2-1.1,1.5-1.2,2.4C1002.2,1301.3,1001.2,1308.9,1001.2,1314.6z M1014.2,1265.8c-0.8,2.7-1.9,4.3-3.9,5.3
				c-1.6,0.7-0.7,2-0.4,3.1c0.4,1.6,1.5,1.3,2.6,0.9c3.8-1.2,7.7-2.5,11.5-3.7c2-0.6,3.9-1.5,6-0.7c1.6,0.7,1.6-0.5,1.4-1.5
				c-0.2-1.2-1.3-1.9-2.4-1.8c-2.5,0.1-2.7-1.3-2.8-3.3c-0.2-7.5-0.6-15-1-22.5c0-0.8,0.3-2-0.9-2.1c-0.9-0.1-2,0.1-2.7,0.6
				c-0.7,0.5-0.5,1.4-0.2,2.3c1.3,3.7,0.7,5.7-2.5,7.8c-1.4,0.9-1.5,1.7-1.1,3.2c0.5,1.9,0.6,4.1-1.1,5.5c-2,1.7-1.7,3.2-0.5,5.1
				c0.6,0.9,1,1.9,1.5,2.9c0.4,0.7,1.2,1.6,0,2.2c-1.1,0.6-1.8,0-2.2-1.2C1015.5,1267.3,1015.1,1267,1014.2,1265.8z M1075.3,1482.6
				c6.5-0.5,12.1-0.9,17.8-1.2c1.5-0.1,2.2-0.5,2-2.1c-1-6.2-2-12.4-3-18.7c-0.1-0.7-0.3-1.3-1.1-1.5c-0.9-0.3-1.4,0.2-1.9,0.9
				c-4.9,6.9-10.6,13.3-16,19.8c-0.5,0.7-1.4,1.2-1,2.2c0.5,1.1,1.5,0.5,2.3,0.6C1075,1482.6,1075.5,1482.6,1075.3,1482.6z
				 M1084.5,1757.8c-0.3-4.1-0.7-9-0.9-13.9c-0.1-1.6-0.7-2.5-2.2-1.4c-4.7,3.8-9.4,7.8-14,11.8c-0.9,0.8-1.2,1.7,0.1,2.7
				c5.1,3.9,10.1,8,15.1,11.9c1.2,1,1.9,0.7,2.1-0.9C1085.1,1764.8,1084.3,1761.7,1084.5,1757.8z M1043.1,1292.6
				c-0.9,1.3-4.4-0.5-4.1,3.3c0.6,8.9,1.1,17.8,1.7,26.8c0,0.6-0.1,1.4,0.7,1.6c0.9,0.2,1-0.6,1.3-1.2c1.7-4,2.8-8.3,5.1-11.8
				c4.2-6.4,2.7-11.7-1.7-17c-0.2-0.2-0.3-0.6-0.5-0.8C1045.1,1292.7,1044.4,1292.3,1043.1,1292.6z M1059.7,1235.8
				c-0.3,0.2-0.8,0.3-0.9,0.7c-3.2,5.6-6.3,11.2-9.5,16.8c-0.5,1-0.4,1.8,0.4,2.7c2.8,3.5,5.4,7.1,8.2,10.6c0.7,0.9,1.2,2.5,2.6,2
				c1.5-0.5,0.9-2.1,0.9-3.1c-0.3-9.4-0.6-18.7-0.9-28.1C1060.5,1236.7,1060.4,1236.2,1059.7,1235.8z M1000.2,1347
				c-1.2,0.3-0.8,1.3-0.9,2.1c-0.7,10.3-1.3,20.7-2,31c-0.1,0.8-0.4,1.7,0.6,1.9c0.9,0.2,1.1-0.8,1.5-1.5c2.8-5.2,5.6-10.3,8.6-15.4
				c0.8-1.5,1-2.7,0.2-4.3c-2.3-4.1-4.4-8.3-6.6-12.4C1001.2,1347.8,1001,1347.2,1000.2,1347z M1068.9,1325.6c0.2-1.5,0.3-2,0.2-2.5
				c-1.9-9.5-2.1-19.4-5.8-28.6c-0.3-0.8-0.3-2-1.2-2.1c-1.3-0.1-1.2,1.3-1.6,2.1c-0.2,0.4-0.2,0.9-0.5,1.2
				c-4.5,6.3-3.5,12,1.2,17.8C1064,1317,1066.1,1321.1,1068.9,1325.6z M1058.6,1185.3c-1.2,0-1.5,0.5-1.7,1c-1.8,4.1-3.5,8.4-6,12.2
				c-1.2,1.8-1,3.5,0.1,5.4c2.4,4.2,4.5,8.6,6.7,13c0.4,0.7,0.5,1.7,2,1.5C1059.4,1207.4,1059,1196.4,1058.6,1185.3z M1066.1,1759.8
				c-0.9,0-1.2,0.5-1.2,1c0.5,3.9-0.2,7.9,1,11.7c0.3,0.9,0.9,1.5,1.8,1.6c5.1,0.5,10.3,0.4,15.3,1.4c0.3,0.1,0.9-0.2,1.1-0.5
				c0.3-0.4-0.1-0.8-0.5-1c-5.5-4.5-10.9-9-16.4-13.4C1067,1760.1,1066.4,1760,1066.1,1759.8z M1050.4,1168.5
				c0.3,2.8,1.3,5.5-0.4,6.9c-3.3,2.7-3.8,6.3-4.3,10c0,0.1,0,0.2-0.1,0.3c-2.4,2.6-0.8,4.9,0.4,7.3c0.3,0.6,0.5,1.3,1.3,1.4
				c1,0.1,1.1-0.9,1.4-1.5c2.3-5.2,4.6-10.3,7-15.5c0.4-0.9,0.9-1.9,0.2-2.8C1054.2,1172.8,1053.1,1170.5,1050.4,1168.5z
				 M1106.1,1022.8c2.5,4.1,4.3,7.9,6.8,11.2c1.3,1.7,4.4,2.9,6.2,1.7c3-2,4.8-5.2,6.7-8.4C1118.7,1028,1113.2,1023.7,1106.1,1022.8
				z M1138.8,1034.3c0-0.6,0-0.8-0.1-1c-0.5-1.3-11-4.2-12.1-3.4c-0.2,0.1-0.3,0.3-0.4,0.5c-1.3,1.6-2.6,3.2-3.8,4.9
				c-1,1.2-1,2,0.8,2.5c2.5,0.6,5,1.5,7.5,2.1c1,0.2,2.2,1.4,3.3,0.1C1135.7,1038,1137.3,1036,1138.8,1034.3z M1149.8,1026.7
				c1.8-0.8,4.2-0.5,5.2-2.1c0.9-1.5-1.2-2.5-1.8-3.8c-0.5-1.1-1.6-0.9-2.6-0.9c-3.2,0.1-6.3,0.3-9.5,0.4c-2,0.1-3.9,0.1-5.9,0.2
				c-1.5,0.1-2.9,1.2-2.9,2.2c0,1.3,1.5,1.8,3,1.5c0.7-0.1,1.6,0,2.2,0.3C1141.3,1027.2,1145.9,1024.4,1149.8,1026.7z
				 M1125.9,1026.1c4.2-2.3,6.1-6.6,9.1-9.9c2.1-2.4,0.8-2.5-1.5-2.8c-5-0.6-9.6,0.6-14.3,2c-1.8,0.5-5-0.6-5,1.5
				c0,1.8,3.4,1.7,5.4,1.7c3.4,0,6.8-0.1,10.2-0.1c0.5,0,1.1-0.2,1.4,0.5c0.2,0.7-0.3,1.2-0.8,1.2
				C1126.5,1020.3,1127.9,1024.5,1125.9,1026.1z M1036.3,1217.8c1.4,0,1.4-0.9,1.6-1.4c2.3-4.9,4.3-10,6.8-14.8
				c1.3-2.4,0.7-4.3-0.5-6.4c-0.8-1.4-1.2-1.2-2.1,0.2c-2,3-4.7,5.6-3.8,9.8c0.1,0.5-0.3,1.3-0.8,1.7
				C1034.3,1210.4,1036.6,1214.1,1036.3,1217.8z M1020.6,1302.4c0.2-0.1,0.5-0.3,0.7-0.5c1.2-1.8,2.5-3.6,3.6-5.4
				c0.7-1.3,0-2-1.3-2.3c-3.3-0.8-6.5-1.6-9.8-2.4c-0.5-0.1-1.1-0.3-1.4,0.3c-0.3,0.6,0.2,1,0.5,1.4c2.1,2.7,4.1,5.3,6.2,8
				C1019.3,1301.9,1019.7,1302.5,1020.6,1302.4z M1053.7,1483.7c2.3,0,3.6,0.1,4.9,0c1.2-0.1,2.1-0.8,0.9-1.8
				c-2.6-2.3-5.3-4.5-8.1-6.6c-0.6-0.4-1.8-0.9-1.8,0.2c-0.2,2.7-0.6,5.6,0.5,8C1050.9,1485.1,1053.1,1483.6,1053.7,1483.7z
				 M1052.7,1298.5c0.8-0.1,0.9-0.9,1.1-1.4c0.7-1.7,1.4-3.4,2.1-5.1c0.3-0.8,1.1-1.6,0.6-2.4c-0.8-1-1.8-0.1-2.7,0.1
				c-8.1,2.3-6.4,0.8-2.6,7.7C1051.5,1297.9,1051.9,1298.5,1052.7,1298.5z M1143.1,1017.9c0,0,0-0.1,0-0.1c1.9,0,3.7,0,5.6,0
				c0.8,0,1.7,0.1,1.9-0.8c0.2-1-0.5-1.8-1.4-1.9c-3.1-0.5-6.2-1-9.3-0.9c-1.7,0-3.3,1.8-3.1,3.5c0.2,1.4,2,0.3,3.1,0.4
				C1140.9,1018.2,1142,1018,1143.1,1017.9z M1051.5,1515.5c0.1,1.8,0.3,3.5,0.4,5.1c0.1,1.5,0.9,1.5,2,1.1c4.5-1.8,5.1-3.9,1.9-7.4
				c-0.1-0.1-0.1-0.2-0.2-0.2c-1.2-0.9-1.8-3.5-3.6-2.7C1050.4,1512,1051.9,1514.2,1051.5,1515.5z M1143.9,1040.6
				c0.5-2.3-0.7-3.9-3.4-3.9c-2.5,0-4.5,2.9-4.2,3.8c0.7,2.4,3.8,2.5,6.1,3.1C1144.6,1044.1,1143.8,1042.1,1143.9,1040.6z
				 M1028.1,1312.5c1-3.4-0.6-5.9-1.3-8.6c-0.3-0.9,0.2-3-1.6-2.3c-1.7,0.7-3.6,3.4-2.9,4.3
				C1024.1,1308,1025.2,1310.8,1028.1,1312.5z M1013.5,1285.4c4.4-2,9-3.1,13.7-4.1c0.5-0.1,1.1-1.8,0.9-2.6c-0.2-1-1.2-0.4-1.9-0.2
				c-4.4,1.5-8.8,3-13.2,4.5c-0.7,0.2-1.2,0.6-1,1.4C1012.1,1285.4,1012.8,1285.4,1013.5,1285.4z M1057.4,1168.6c0-3.2,0-6.2,0-9.2
				c0-0.8,0.3-2-1-1.9c-1.1,0.1-2.2,0.8-1.9,2.2c0.2,1.2,0.6,2.4,0.3,3.5C1054,1165.9,1055.6,1167.1,1057.4,1168.6z M1129.8,1568.2
				c0,0,0-0.1,0-0.1c-3,0.6-6,1.1-9,1.7c-0.6,0.1-1.1,0.3-1.1,1c0,0.6,0.6,0.9,1.2,0.9c3.8-0.2,7.6-0.6,11.3-1.4
				c0.5-0.1,0.9-0.6,0.8-1.2c-0.1-0.7-0.6-0.8-1.2-0.9C1131.1,1568.1,1130.4,1568.1,1129.8,1568.2z M1153.3,1564.9
				c-4.9-0.1-9.7,0.6-14.2,2.7C1143.8,1566.5,1149.1,1568.7,1153.3,1564.9z M1153.6,1045.2c-1.1-1.2-1.2-3.9-3.2-4.2
				c-1.3-0.2-2.9,2.5-2.8,3c0.8,2.1,3.4,1.7,5.2,2.5C1153.4,1046.7,1153.8,1046.2,1153.6,1045.2z M1019.2,1285.4
				c0,0.1,0.1,0.3,0.1,0.4c2.3,0,4.5,0,6.8,0c1.2,0,2.3-0.4,2.1-1.8c-0.2-1.5-1.5-1-2.3-0.7
				C1023.6,1283.9,1021.4,1284.7,1019.2,1285.4z M1010.7,1567.1c3.7-0.7,7.1-0.9,10.1-2.3
				C1012.8,1563.7,1012.8,1563.7,1010.7,1567.1z M1091.1,1590.9c1,0.3,2,0.3,1.9-0.7c-0.1-1.7-1.3-2.8-2.9-3
				c-1.8-0.2-3.3,1.3-3.2,2.6C1087.2,1593.8,1090.4,1588.4,1091.1,1590.9z M976.9,1583.3c1.3,0,2.9,0.1,3-1.9c0-1.1-0.8-1.5-1.8-1.2
				c-1.2,0.3-3-0.1-3,2C975,1583.5,975.8,1583.6,976.9,1583.3z M1058.6,1566.7c-1.7-0.4-3.2-0.7-4.1,0.7c-0.5,0.8,0.3,1.8,1.1,2
				C1057.8,1570,1057.9,1568.2,1058.6,1566.7z M1147.6,1057.8c0-0.9,0.6-2.2-0.7-2.2c-1.2,0-1.6,1.3-1.5,2.4c0.1,1-0.5,2.7,0.9,2.7
				C1148.1,1060.7,1147.2,1058.7,1147.6,1057.8z M974.5,1586.4c0.1,1.1,1,1.5,2,1.6c0.9,0.2,1.9,0,1.9-1.1c0-1.2-1.4-1.2-2.2-1.5
				C975.4,1585,974.7,1585.4,974.5,1586.4z M1095.4,1694.9c0.5-0.3,1.5-0.2,1.6-1.1c0.1-0.5-0.4-0.8-0.8-0.7
				c-0.7,0.1-1.6,0.2-1.7,1.1C1094.4,1694.6,1094.8,1694.8,1095.4,1694.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#1A1A1A" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M570,1232.1
				c0,1,0.1,1.9,0.1,2.9c-5.4-5.1-11.8-6.9-19.1-6.5c-0.6,0-1.4,0.1-1.9-0.1c-5.9-2.4-11.3-0.1-16.6,1.8c-4.3,1.5-8.5,3.6-12.2,6.5
				c-1.6,1.2-2.6,0.1-2.7-1.7c-0.1-0.9,0.5-2.5-0.8-2.5c-1.4,0-2,1.5-2,2.9c0,2.7,0.6,5.4-1.6,7.6c1.7,1.2,2.8-0.8,4-0.1
				c2.1,1.3,2.7,3.1,1.7,5.3c-0.9,2.2-2,4.4-2.9,6.5c-0.3,0.6-0.7,1.3,0,1.8c0.5,0.4,1.1,0.1,1.5-0.3c1.5-1.3,3.1-2.3,4.6-0.2
				c1.9,2.6,1.6,6.2-0.8,7.8c-1.7,1.2-3.6,2.1-5.4,3c-5.2,2.6-8.9,7-13.1,10.8c-7.5,6.9-16.4,11.3-25.8,15.2
				c-9.3,3.8-18.9,6.7-28.9,8.3c-3.4,0.5-6.8,0.3-10-1c-1.4-0.6-2.7-2.2-3.4-3.7c-3.2-6.4-5.5-13.3-0.8-19.7c2.1-2.9,5-5.9,8.8-7.4
				c0.9-0.3,1.6-0.9,2.4-1.4c-1-2.4-4.2-1.3-5.1-3.5c-1.4-1.2,0.8-1.7,0.5-2.9c-1.6-0.3-3.3-0.7-5-1c0-0.9-0.7-2.6,0.5-2.3
				c2.9,0.8,2.4-1.7,3.3-2.8l0,0c0.8,0.2,1.5,0.2,2-0.7c0.2-0.1,0.3-0.2,0.3-0.4c0.5-0.7,0.5-1.2-0.3-1.6c-0.8-0.8-1.8-0.6-2.8-0.9
				c-0.8-0.2-1.6-0.9-1.2-1.6c2-3.5-1-2.6-2.6-3c-1.1-0.3-2.3-0.3-2.5-1.6c-0.2-1.2,1.1-1.5,1.9-2c1.2-0.8,2.4-1.4,3.7-2.1
				c0.7-0.4,1.9-0.3,1.7-1.6c-0.1-1.1-1-1.2-1.9-1.5c-3.8-1-3.8-1-1.7-4.3c-1.3-0.5-2.6-0.4-3.8-0.4c-1.2,0-2.2-0.5-2.6-1.6
				c-0.5-1.4,0.8-1.6,1.6-2c1.6-0.9,3.2-1.8,4.9-2.7c0.6-0.3,1.3-0.6,1.2-1.4c-0.1-0.8-0.8-0.9-1.5-1c-1-0.2-2-0.3-2.8-0.7
				c-0.9-0.4-1.8-1.1-0.6-2.2c1.6-1.5,0.6-1.9-0.9-2.1c-0.5-0.1-1.1,0-1.6-0.1c-1.2-0.1-2.6-0.2-2.9-1.6c-0.3-1.4,1.2-1.6,2.1-2.2
				c1.6-1,3.2-1.9,4.8-2.7c0.6-0.3,1.2-0.6,1-1.4c-0.2-0.7-0.8-0.7-1.3-0.7c-0.6-0.1-1.3-0.1-2-0.2c-1.8-0.4-3.5-0.9-1.2-3
				c0.3-0.3,1.1-0.4,0.7-1.1c-0.3-0.5-0.8-0.5-1.4-0.6c-1.9-0.3-4.9,0.8-5.3-1.9c-0.3-1.7,2.8-1.9,4.1-3.1c0.1-0.1,0.2-0.1,0.3-0.2
				c1.1-1.1,3.8-1.2,3.6-2.8c-0.2-1.8-2.6-0.7-4-1.2c-1.6-0.5-2.6-1.1-0.9-2.6c1.6-1.5,0.8-1.9-0.8-2.1c-1.8-0.2-4.7,0.8-5-1.7
				c-0.3-1.9,2.7-2,4.1-3.1c1.4-1.1,3-2,4.8-3.1c-1.5-1.4-3.3-0.4-4.8-1.1c-0.7-0.3-1.5-0.5-1.8-1.3c-0.4-1.1,0.5-1.3,1.2-1.6
				c0.6-0.3,1.3-0.6,1-1.4c-0.3-0.8-1.1-0.5-1.7-0.6c-1.9-0.2-4.7,0.9-5.3-1.3c-0.5-1.9,2.6-2.3,4.1-3.4c0.3-0.2,0.6-0.3,0.8-0.5
				c1.1-1.2,4-1.1,3.7-2.7c-0.4-1.8-2.9-0.4-4.4-0.9c-0.8-0.3-1.9-0.3-2.1-1.3c-0.3-1.2,0.9-1.3,1.6-1.7c0.5-0.3,1.1-0.5,1-1.2
				c-0.1-0.9-0.9-0.8-1.6-0.8c-1.5-0.1-3.1,0-4.6-0.2c-0.7-0.1-1.2-0.6-1.4-1.2c-0.2-0.8,0.5-1,1.1-1.4c2.3-1.3,4.5-2.6,6.8-4
				c0.5-0.3,1.3-0.6,1.1-1.4c-0.2-0.9-1-0.7-1.6-0.7c-0.8,0-1.5,0-2.3-0.1c-1-0.2-2.4,0.2-2.7-1.3c-0.3-1.3,1.3-0.8,1.8-1.4
				c0.3-0.5,1.3-0.7,0.9-1.5c-0.3-0.6-1-0.8-1.7-0.8c-1.6,0-3.3-0.1-4.9-0.3c-0.6-0.1-1.3-0.5-1.3-1.2c-0.1-0.7,0.7-0.7,1.1-1
				c2.9-1.7,5.8-3.4,8.6-5.8c-2-0.3-3.7-0.5-5.3-0.7c-0.8-0.1-1.6-0.3-1.7-1.3c-0.1-0.8,0.7-0.9,1.2-1.3c0.7-0.5,1.9-0.7,1.5-1.7
				c-0.4-1-1.5-0.4-2.3-0.5c-1.9-0.2-4.7,0.8-5.2-1.3c-0.4-1.5,2.5-1.9,3.9-2.9c1.8-1.4,3.7-2.6,6.2-4.3c-2.4-0.2-4.1-0.3-5.7-0.5
				c-0.7-0.1-1.6-0.4-1.7-1.3c-0.2-0.8,0.6-1,1.2-1.3c0.7-0.4,1.6-0.8,1.3-1.6c-0.3-0.8-1.3-0.7-2-0.8c-1.3-0.1-2.6-0.1-3.9-0.2
				c-0.7,0-1.3-0.4-1.5-1.1c-0.2-0.9,0.6-1,1.1-1.3c2.3-1.4,4.6-2.9,7-4.2c0.8-0.4,1.8-0.8,1.6-1.8c-0.3-1.2-1.4-0.6-2.2-0.6
				c-0.4,0-0.9,0.2-1.3,0.2c-1.3,0-3,0.4-3.4-1.2c-0.4-1.4,1.4-1.4,2.2-1.9c0.6-0.4,1.5-0.7,1.3-1.6c-0.2-1.1-1.2-0.6-1.9-0.6
				c-1.6,0-3.3,0.1-4.9,0.1c-0.8,0-1.6-0.2-1.8-1.1c-0.1-0.9,0.8-0.9,1.3-1.2c2.5-1.6,4.9-3.2,7.4-4.8c0.5-0.3,1.3-0.6,1-1.4
				c-0.3-0.7-1.1-0.6-1.7-0.6c-1.8,0-4.4,1.2-5.1-0.6c-0.8-2.2,3-1.9,3.7-4.1c-1.9-0.7-3.8,0.1-5.6-0.1c-1.2-0.1-2.8,0.4-3.2-1
				c-0.5-1.6,1.4-1.6,2.3-2.1c1.9-1.2,4-2.2,5.9-3.4c0.8-0.5,1.8-1.3,1.2-2.3c-0.4-0.8-1.5-0.8-2.4-0.6c-0.3,0.1-0.6,0.2-1,0.2
				c-1.2,0-3,0.8-3.4-0.9c-0.4-1.4,1.4-1.3,2.3-1.8c0.8-0.5,2-0.9,1.6-2.1c-0.4-1-1.5-0.6-2.3-0.6c-1.5,0.1-3,0.3-4.6,0.3
				c-0.7,0-1.7,0.1-2-0.8c-0.3-1,0.8-1.1,1.4-1.5c2.3-1.4,4.6-2.9,7-4.3c0.8-0.5,1.6-1,1.4-2c-0.2-1.3-1.4-0.6-2.1-0.7
				c-0.5,0-1.1,0.2-1.6,0.1c-1.2,0-2.8,0.6-3.2-0.8c-0.6-1.7,1.4-1.5,2.2-2.2c0.8-0.6,2.6-1.1,1.6-2.2c-0.9-1-3.1-0.9-4.3,0.1
				c-11.9,10.3-24.5,19.7-36.6,29.7c-10.7,8.8-21.4,17.6-32.1,26.6c-11.9,10-23.7,20-35.4,30c-4.3,3.7-8.9,7.1-13.6,10.3
				c-0.7,0.5-1.4,1.8-2.4,0.6c-0.8-1.1,0-1.9,0.9-2.7c11.3-9,22.5-18.3,33.5-27.8c24.8-21.5,50.2-42.3,76-62.6
				c1.5-1.1,2.9-2.3,4.3-3.5c3.1-1.4,5.2-4,7.4-6.4c0.2-0.1,0.4-0.2,0.6-0.3c0.9-0.9,2.9-1.4,2.4-2.7c-0.6-1.8-2.4-0.5-3.8-0.5
				c-0.9,0-1.8,0.1-2.6-0.2c-1.1-0.4-1.3-1.1-0.1-1.8c0.1-0.1,0.2-0.1,0.3-0.1c1.5-0.6,3.9-1.7,3.5-2.8c-0.6-1.8-3.1-0.6-4.7-0.6
				c-1.6-0.1-4.1,1.4-4.7-0.5c-0.4-1.4,2.4-2.1,3.8-2.9c1.8-0.9,3.3-2.1,4.9-3.3c0.6-0.5,1.3-1,1-1.9c-0.4-0.9-1.3-0.6-2-0.6
				c-1.7,0-4.1,1.6-4.8-0.2c-0.8-2,2.6-1.5,3.4-3.1c0.3-0.5,1.3-0.7,1-1.5c-0.3-1-1.3-0.6-2-0.6c-1.7,0.1-3.5,0.4-5.2,0.4
				c-0.9,0-2.3,0.5-2.6-0.8c-0.3-1.1,1.1-1.1,1.8-1.6c2.3-1.5,4.6-2.8,6.9-4.4c0.7-0.5,1.8-1.3,1.3-2.4c-0.5-1-1.7-0.5-2.7-0.4
				c-0.4,0.1-0.9,0.2-1.3,0.2c-1.1,0-2.8,0.7-3.2-0.7c-0.3-1.3,1.6-1.1,2.4-1.8c0.3-0.3,0.8-0.4,1.1-0.7c0.6-0.6,1.7-0.9,1.3-2
				c-0.4-0.9-1.3-0.6-2-0.6c-2.1,0.2-4.1,0.4-6.2,0.6c-0.7,0.1-1.3,0-2-1c2.9-1.6,5.9-3.1,8.5-5.1c1-0.7,2.3-1.6,1.9-2.8
				c-0.5-1.5-2-0.6-3.2-0.4c-1.4,0.2-2.7,0.5-4.8-0.2c1.9-1.4,3.3-2.5,4.7-3.5c0.5-0.4,1.3-0.6,1.1-1.4c-0.3-0.9-1.1-0.8-1.9-0.7
				c-2.8,0.3-5.5,0.9-8.6,0.4c1.2-2,3.2-2.6,4.7-3.2c2.3-0.9,3.3-3.2,5.5-4c0.5-0.2,0.7-0.9,0.5-1.4c-0.3-1-1.1-0.8-1.9-0.9
				c-1.9-0.1-3.7,1.3-5.7,0.2c0.7-1.6,2-2.2,3.2-2.9c0.9-0.6,2.2-1,1.6-2.3c-0.6-1.3-1.7-0.4-2.7-0.3c-1.9,0.3-3.9,0.3-5.9,0.3
				c-0.4,0-0.9-0.2-1.1-0.5c-0.4-0.6,0.2-1,0.7-1.2c3-1.4,5.5-3.5,8.3-5.1c1-0.5,1.8-1.1,1.4-2.1c-0.5-1.3-1.6-1.1-2.8-0.8
				c-1.2,0.3-2.6,0.5-3.9,0.6c-0.5,0.1-1.1,0-1.4-0.6c-0.2-0.7,0.3-1,0.8-1.3c1.5-0.8,3.1-1.7,4.7-2.4c0.8-0.4,1.8-0.4,1.9-1.5
				c0-1-0.6-1.6-1.3-2.1c-1-0.7-1.5,0.2-2.2,0.7c-2.7,2.1-5.9,1.6-8.9,1.9c-0.1-0.3-0.3-0.6-0.4-0.9c3.2-1.8,6.3-3.8,9.6-5.4
				c1.3-0.7,2.9-1,2.1-2.8c-0.7-1.7-2.2-2.1-3.8-1c-1.6,1.1-3.2,0.9-5.5,0.8c1.7-2.2,3.7-3.2,5.6-3.8c2.2-0.7,3-1.5,1.8-3.7
				c-0.5-1,0.1-2.3-0.9-3.6c-1.7,1.7-0.9,5.1-4.2,5.2c-2.3,0.1-4.7,1-8,0.2c3.9-2.2,7.1-4.2,10.4-5.9c1.3-0.7,2.6-1.3,1.8-2.8
				c-0.7-1.4-2.1-1.8-3.6-0.7c-0.2,0.1-0.4,0.3-0.6,0.3c-1.4,0.2-3.3,1.1-3.9-0.2c-0.7-1.7,1.6-1.5,2.6-2.1c1.1-0.7,2.4-1.1,3.6-1.7
				c1.5-0.8,1-2.2,0.4-3.1c-0.5-0.9-1.6-1.3-2.8-0.4c-2.4,2-5.4,2.6-9.9,1.8c3.9-2.3,7-4.4,10.4-6c1.5-0.7,1.8-1.4,1.4-2.7
				c-0.5-1.5-1.8-1.6-2.9-1.2c-1.5,0.5-3.7,2.1-4.2,0.5c-0.8-2.1,2.5-1.7,3.6-2.9c0.4-0.4,1.3-0.3,1.8-0.7c1.3-1,1.2-2.3,0.2-3.4
				c-0.9-1-2.1-1.4-3.1-0.2c-2.5,2.9-5.7,1.6-8.9,1.5c3.2-1.8,6.2-3.8,9.5-5.4c1.6-0.8,2.4-1.7,1.7-3.2c-0.7-1.4-2.2-1.7-3.7-1
				c-0.1,0-0.2,0.1-0.3,0.2c-1.3,0.5-3.2,2-3.9,0.3c-0.7-1.6,1.9-1.5,3-2.2c0.7-0.5,1.5-0.9,2.2-1.4c1.1-0.7,1.5-1.7,0.8-2.8
				c-0.8-1.1-1.6-0.7-2.8-0.2c-2.8,1.4-5.8,1.6-8.8,1.7c-0.1-0.3-0.2-0.6-0.3-0.8c3.2-1.8,6.3-3.8,9.6-5.2c1.7-0.7,2-1.4,1.7-2.8
				c-0.4-1.7-1.8-2-3.1-1.5c-1.5,0.5-3.5,2.2-4.3,0.6c-0.8-1.7,2.3-1.6,3.3-2.8c0.3-0.4,0.8-0.7,1.3-1c1.2-0.7,1.4-1.8,0.6-2.8
				c-0.7-0.9-2-1-2.6-0.2c-1.8,2-4.3,1-6.3,1.8c-0.6,0.3-1.4,0.1-1.6-0.6c-0.2-0.8,0.5-1.1,1.1-1.4c1.6-0.7,3.1-1.4,4.5-2.5
				c1.4-1.1,3.1-3.3,4.7,0.9c0.2-2.4,0.1-3.9,0.1-5.3c-0.1-2.1-1.7-2.6-3.6-1.9c-0.6,0.2-1.1,0.8-1.6,1c-0.8,0.2-1.8,0.8-2.3-0.2
				c-0.6-1.3,0.7-1.3,1.4-1.7c1.2-0.7,2.4-1.5,3.6-2.2c1.2-0.7,1.3-1.7,0.5-2.7c-0.7-0.9-1.9-1.4-2.8-0.4c-1.9,2-4.5,1.3-6.7,2
				c-0.6,0.2-1.1-0.1-1.3-0.7c-0.2-0.7,0.3-1,0.9-1.1c3-0.6,4.7-3.8,8.1-3.8c1.4,0,1.6-1.7,1.7-2.8c0.2-1.2-0.6-2.2-1.7-2.8
				c-1-0.6-1.8-0.4-2.6,0.4c-0.4,0.4-0.8,0.9-1.2,1c-0.9,0.3-2.3,0.7-2.7-0.2c-0.7-1.5,1-1.1,1.7-1.5c1.1-0.6,2.1-1.4,3.2-2.1
				c0.9-0.6,2.1-1.2,1-2.5c-0.8-0.9-1.8-1.8-3-0.6c-1.8,1.8-4.4,1-6.5,1.8c-0.5,0.2-1.4,0.4-1.7-0.5c-0.3-0.9,0.5-1.7,1.1-1.7
				c3.3-0.1,4.9-3.3,7.9-4.1c2.1-0.5,1.8-2.6,1.1-4c-0.7-1.4-2.3-1.5-3.7-0.5c-0.5,0.4-0.7,1.1-1.5,1.1c-0.7-0.1-1.6,0.6-2-0.5
				c-0.3-0.8,0.5-1.2,1.1-1.4c1.6-0.5,2.9-1.6,4.1-2.6c0.8-0.6,1.3-1.6,0.5-2.6c-0.8-1.1-2.1-1.2-2.9-0.4c-2,1.8-4.7,1.1-6.9,2
				c-0.4,0.2-0.9,0.1-1-0.5c-0.1-0.5,0.1-0.8,0.5-1.1c1.8-1,3.6-2,5.4-3.1c4.9-2.7,5.3-6.7,1-10.4c-0.8-0.7-0.5-1.6-0.8-2.4
				c1.3-1,1.2-1.6-0.4-2v0c-0.4-2.3-2-2.7-3.9-2.2c-1.5,0.4-2.8,0.1-3.3-1.2c-0.5-1.4,1.3-1,2-1.5c0.4-0.3,1.1-0.6,1.2-1.1
				c0.6-2,1.1-3.4,3.9-2.9c1.3,0.2,2.1-2.2,1.5-3.3c-1-1.8-0.8-3.6-0.9-5.4c-0.1-3.2-1.1-4.5-4.3-3.8c-2.8,0.6-4.5-1.4-6.8-1.6
				c-0.9-0.1-0.7-1.1-0.1-1.5c0.7-0.5,1.6-0.8,2.4-1c10.8-2.3,21.7-4.5,32.6-6c5.9-0.8,11.7-2,17.4-4.1c-17.5-0.1-34.2,5.1-51.5,7.5
				c1.6-0.4,0.2-5.3,4.1-2.3c0.7,0.5,2.1,0.1,3.2-0.1c3.6-0.7,7.3-1.6,10.9-2.4c1-0.2,1.9-0.5,2.2-1.6c0.6-2.4,2.8-1.8,3.7-1.1
				c2.2,1.7,4.3,1,6.5,0.6c3.3-0.6,6.7-1.1,10-1.8c1.5-0.3,3.4,0.1,3.7-2.4c0.1-1,1.2-1,1.8-0.5c2.2,1.8,4.8,1.3,7.2,1.2
				c2.5-0.1,3.9,1,4.5,3.4c0.2,1,0.5,2.1,0.8,3c-6.6,3.2-13.9,1.5-20.7,3.1c-5.4,1.2-10.8,2.1-16,3.8c-1.9,0.7-3,2-2.6,3.5
				c0.7,2.6-0.6,3.3-3.4,3.9c3.3,0,2.2,1.4,1.8,2.6c-0.2,0.7,0.1,1.4,0.8,1.6c1,0.4,1-0.5,1.1-1.1c0.1-0.7,0.4-1.3,1-1.6
				c0.9-0.5,7.3,1,8.2,1.7c2.1,1.7,4.5,3.5,7.3,1.9c3.6-2.1,7.2-2,10.8-0.6c0.7,0.3,1.5,0.8,2.2,0.4c2.6-1.6,5.6-0.7,8.3-1.4
				c1.7-0.4,2.4,0.5,2.7,1.6c0.4,1.4-0.2,2.5-1.9,2.8c-0.7,0.1-1.5,0.4-2.2,0.6c-2.2,0.6-1.7,2.2-1.4,3.7c0.3,1.7,1.4,1.6,2.7,1.1
				c0.8-0.3,1.7-0.3,2.6-0.5c0.9-0.2,2-0.7,2.7,0.4c0.5,0.9,1,1.9,0.3,3.1c-1.2,2-1.9,4.4-3.6,6.2c-0.4,0.4-1.1,1-0.8,1.6
				c0.4,0.8,1.3,0.6,2.1,0.5c1.4-0.1,2.8-0.3,4.2-0.4c0.6,0,1.3,0.2,1.4,1c0.1,0.8-0.5,1.2-1.2,1.3c-1.6,0.4-3.2,0.7-4.8,1
				c-0.8,0.1-2-0.5-2.1,0.8c-0.1,1.4,1,1.9,2.2,1.9c1.7,0.1,3.3,0.2,4.8,0.9c1.9,0.8,2.8,1.8,1.6,4.2c-0.8,1.6-2.5,2-3.1,3.4
				c1.5,1.1,2.9,0.1,4.3,0.1c0.8,0.1,2-0.3,2.1,0.8c0.1,1.1-0.9,1.7-1.9,1.9c-1.6,0.3-3.3,0.4-4.9,0.6c-0.9,0.1-2.2-0.3-2.2,1.3
				c0,1.2,1.2,1.1,1.9,1.4c1.9,0.8,3.9,0.3,5.8,0.7c1.4,0.3,2.9,0.9,3.4,2.3c0.6,1.5-1,2.2-1.8,3.2c-0.8,0.9-2.3,1.2-2.5,2.8
				c1.5,0.8,2.9-0.1,4.2,0.1c1.7,0.2,1.6,0.8-0.6,3c-1.6,0-3.2,0.1-4.9,0.1c-1,0-2.3,0-2.1,1.2c0.1,0.9,1,1.8,2.3,1.8
				c1.5,0,3,0,4.5,0c1.4,1.1,4.8,0.8,3.5,3.8c-0.8,1.8-2.9,3-4.4,4.5c1.4,0.7,2.7,0.6,4,0.4c0.8-0.1,1.9-0.5,2.2,0.7
				c0.3,1.4-1,1.4-1.9,1.7c-0.4,0.1-0.8,0.3-1.2,0.5c-2.1-0.2-5.6-0.5-5.5,1.2c0.1,2.5,3.4,1.5,5.4,1.6c0.5,0.3,0.9,0.7,1.4,0.8
				c3.5,1.3,3.9,3,1.2,5.6c-1,0.9-2.1,1.7-3.6,3c1.9-0.1,3.3-0.2,4.6-0.2c0.9,0,2.3-0.6,2.4,0.8c0.1,1.2-1.1,1.8-2.3,1.9
				c-1.4,0.1-2.8,0-4.2,0.1c-0.6,0-1.4,0.3-1.5,1c-0.2,0.9,0.5,1.4,1.3,1.5c1.9,0.4,3.9,0.7,5.8,0.9c1.4,0.2,2.8,0.5,3.1,1.9
				c0.5,1.6-0.6,2.9-1.8,3.9c-0.7,0.6-2.1,0.7-2,2.2c5.4,0.4,5.8,0.2,5.6-2.9c-0.2,1.1-0.9,2.3,1.1,2.6c-0.5,2.2-0.6,4.2,2.2,5.1
				c0.7,0.3,1,1.6,0.7,2.3c-0.7,2.5,0.6,4.9,0.4,7.3c-0.1,2.4,0.2,3.8,2.7,4.4c0.2,0.1,0.3,0.7,0.5,1.1c-0.9,3-2.5,5.5-4.9,7.6
				c-0.4,0.4-0.9,0.7-0.7,1.3c0.3,0.6,0.9,0.4,1.4,0.4c1.4,0.1,2.8-0.7,4.2-0.2c0,0.6,0,1.2,0,1.8c-2.6,0.8-5.2,1.7-8,1.7
				c-0.7,0-1.7-0.2-1.7,0.9c0,0.9,0.9,1.2,1.7,1.2c2.3,0.2,4.5,0.5,6.8,1c2.9,0.6,2.6,1.9,1.4,3.9c-1.1,1.9-3.4,2.5-4.8,4.4
				c1.6,0,2.8,0,4,0c0.9,0,2.3-0.7,2.5,0.5c0.3,1.6-1.3,1.8-2.5,1.9c-2,0.2-3.9,0.5-6,1.4c3.1,1.8,9.9,1.7,12.1,0.1
				c0.3,0.6,0.6,1.1,0.9,1.7c-1.7,2.9-3.4,5.9-6.4,7.9c1.6,1,2.8,0.7,3.9,0.3c2.2-0.9,2.8,0.3,3,2.1c0.4,1.8,2.1,2.4,3.1,3.6
				c-0.2,2.9-2.6,4.3-4.3,6.2c-0.6,0.6-1.5,1.1-1.1,2.1c0.4,1.1,1.5,0.8,2.2,0.5c1.3-0.4,2.3,0.2,3.4,0.8c-0.2,1.2-0.4,2.4,1,3
				c1.2,1.5,0.8,2.9,0,4.5c-1.5,2.8-4.2,4.4-6.5,6.6c1.5,0.6,2.7,0,3.9,0c0.9,0,2.2-0.5,2.4,0.9c0.1,1.2-1.2,1.1-2,1.3
				c-1.7,0.4-3.5,0.4-5.2,1.3c0.3,0.4,0.4,0.7,0.6,0.8c1.7,0.4,3.4,0.7,5.1,1.1c2.5,0.6,3.5,2.2,2.6,4.5c-1.4,3.8-1.4,3.8,2.6,3.4
				c0.8-0.1,1.5-0.1,2.3-0.2c0,0.2,0,0.5,0,0.7c0.1,0.2,0.2,0.5,0.3,0.7c0.3,0.3,0.7,0.6,1,0.9c0.2,0.1,0.4,0.3,0.6,0.4
				c0.1,1,0.3,2,0.4,3c-1.7,3.3-3.7,6.4-6.7,9.4c2.9,0.4,4.9-1.4,7.1-0.7c-0.2,1.9,1.1,2.7,2.5,3.4c0.5,2.5-1.3,4.2-1.9,6.3
				c-0.5,1.6-3.1,2.8-2.3,4.2c0.8,1.4,3.1-0.5,4.8,0c0.2,0.4,0.5,0.9,0.7,1.3c0,0.4,0.2,0.6,0.6,0.6c0.3,0.6,0.6,1.1,1,1.7
				c-1,3.1-0.9,6.6-3.9,8.8c-0.8,0.6-2.3,1.2-1.7,2.4c0.5,1.2,1.9,0.6,2.9,0.3c0.7-0.2,1.4-0.4,1.7,0.4c0.4,0.9-0.3,1.4-1.1,1.7
				c-1.7,0.6-3.4,1.1-5.5,1.9c2.3,1,4.2,0.3,6,0.6c1.3,0.2,3,0.1,3.6,1.3c0.7,1.4-0.2,2.9-1.2,4.2c-0.5,0.7-1,1.4-1.9,1.7
				c-1.1,0.5-1.9,1.2-2.2,2.8c1.4-0.2,2.6-0.3,3.7-0.5c0.8-0.2,1.8-0.9,2.1,0.5c0.2,1-0.6,1.6-1.5,1.8c-1.4,0.4-2.8,0.8-4.4,1.9
				c3.1,0.2,6.2,0.5,7,3.3c0.8,2.7-1.6,4.6-3.9,6.6c2.5,0.8,4.5,0,6.3-0.8c3.9-1.9,5.1-7.6,2.5-11c-0.6-0.8-1.8-1.5-1.2-2.8
				c1.5,0,1.5-1.1,1.3-1.9c-0.4-1.6-1.7-0.5-2.6-0.6c-0.9-1.3,0.2-2.4,0.4-3.6c0.2-1,1-2.5,0.6-2.9c-2.1-2.5,0.3-2.9,2-3.9
				c-6.5,1.5-6.5,1.5-7.7-0.8c3.2-1.4,2-3.7,1.3-6c1.2-1.9-1.1-3.3-0.9-5.1c1.4-1.2,0.1-3.1,1.4-4.4c1.1-1.1,0.8-2.2-0.5-3.2
				c-1.3-1-2.5-2.1-2-4c0.5-1.4,0.2-2.4-1.3-2.8c-0.4-1.7-0.2-3.4,0.2-5.1c0.2-2.4,2.4-1.6,3.6-2c2.8-0.9,4.8-4,7.5-4.1
				c6-0.3,11.6-2.5,17.5-3.5c3.8-0.6,7.5,0.1,11.2,1c3.6,0.9,6.5,3.2,10.1,4.1l0,0c0,0.9,0,1.8,0,2.8c-7.5-3.4-15.2-5.8-23.1-1.9
				c-3.6,1.7-6.7,4.4-10,6.6c-2.3,1.5-5.3,2.1-7.1,4.5c-0.4,0.6-1.4,1.1-0.9,1.9c0.7,1.1,1.6,0.2,2.2-0.3
				c11.3-7.6,23.5-8.1,36.3-4.5c0.3,0.1,0.5,0.3,0.8,0.4c0.8,0.4,1.6,0.7,2.3,1.1c0.3,0.7,0.8,1.1,1.6,1.1l0,0
				c0.4,1.1,1.3,1.7,2.4,2.1l0,0c0.4,1.8,0.2,2.6-1.8,1.3c-2.5-1.7-5-3.5-8.2-3.7c-2.6-0.1-5.2,0-7.8-0.8c-4.9-1.5-9-0.4-12,3.9
				c-0.2,0.3-0.7,0.6-1,0.7c-3.3,0.8-5.5,3.2-8.1,5.1c-1.9,0.9-4.1,1.1-5.9,2.3c-0.9,0.6-1.1,1.3-0.6,2.2c0.6,1,1.4,0.4,2,0
				c1.6-1.2,3.1-2.4,4.6-3.6c0.9-0.3,1.9-0.5,2.8-0.8c13.5-3.9,26.8-6,38.7,4.6c0,0.1,0.1,0.3,0.1,0.4c-0.4,0.6,0.4,1.5-0.9,2.3
				c-2.4-4.7-8-4.1-11.6-7.1c-0.7-0.6-1.7-0.3-2.5-0.1c-5.9,1.2-11.7,2.5-17.4,4.6c-3.6,1.3-7,3-9.7,6.2c1.8,0.4,3.1,0,4.3-0.4
				c3.1-1.1,6.2-2,9.5-2.5c8.7-1.3,17.3-1.3,25.3,3.1c1.8,1,3.4,2.2,4.4,4.2c-2.1-0.2-8.5-2.2-9.4-2.9c-4.2-3.2-11-3.7-15.3-1
				c-4.1,2.6-8.5,4.9-12.9,6.9c-1.7,0.7-3.3,1.6-4.2,3.3c0.6,0.6,1.1,0.4,1.6,0.2c8.9-3.8,18.2-5.3,27.9-3.7
				c5.3,0.8,10.6,2.2,14.1,6.8c0.5,0.7,1.6,1.7,0.5,2.5c-0.8,0.6-1.6-0.2-2.3-0.7c-4.5-2.7-8.9-5.5-14.6-4.3c-1.3,0.3-2.8,0-4.3,0
				c-2.4,0-5-0.4-7.1,0.9c-4.3,2.6-9.3,3.8-13.4,6.6c-2.1,1.4-4.9,2.1-5.1,6c5.2-4.4,10.9-6.3,17-7.3c9-1.5,17.9-1.5,26.4,2.5
				c2.8,1.3,5.4,3.2,6,7.4c-2.4-1.2-4.3-2.2-6.2-3.1c-3.5-1.6-7-4-11.1-3.5c-5.1,0.6-10.2,1.3-15.1,3.6c-5,2.4-9.9,5-14.7,7.7
				c-0.7,0.4-1.4,1.1-0.9,1.9c0.6,1.1,1.5,0.5,2.2,0.1c1.4-0.8,2.4-2.2,3.9-2.8c5.6-2.4,11.5-3.7,17.5-4c9.8-0.5,19.4-0.1,26.4,8.4
				c0,0.5,0,0.9,0,1.4c-1.2,0.5-1.8-0.4-2.5-1c-1.9-1.8-4.3-2.5-6.7-3.2c-3.8-1.1-7.4-1.4-11.4-1.1c-10.1,0.8-18.6,4.8-26.5,10.7
				c-0.6,0.4-1.5,1.1-1,1.9c0.7,1,1.7,0.3,2.3-0.2c3.2-3.2,7.4-4.1,11.6-5c10.2-2.3,20.4-2.8,30.2,1.8c2.2,1,3.9,2.5,5.1,4.9
				c-3.2,0.7-4.9-2.4-7.2-2.5c-7.2-0.4-14.2-3.4-21.7-0.1c-5.7,2.5-11.4,4.6-16.7,7.9c-1.4,0.8-1.8,2.1-2.3,3.4
				c-0.2,0.6-0.2,1.3,0.5,1.7c0.9,0.5,1.4-0.1,1.8-0.8c0.9-1.7,2.2-3,4-3.7c11.8-4.4,23.9-5.7,36-1.7c3.7,1.2,7.2,3.2,8.6,7.7
				c-1.7,0.1-2.5-0.7-3.1-1.5c-0.9-1.2-2.1-1.5-3.4-2.1c-3.4-1.7-7.3-1.1-10.7-2.5c-1.7-0.7-3.8-0.4-5.8,0.1
				c-8.4,2.2-16.1,5.7-23.6,10c-0.6,0.4-1.7,0.7-1.1,1.6c0.5,0.8,1.3,0.4,2,0c9.8-5,20.3-6.1,31-4.9c6.7,0.8,13.3,2.3,16.8,9.2
				c0,0.4-0.1,0.7-0.1,1.1c-1.8-0.3-2.7-1.9-4-2.9c-0.8-0.7-1.5-1.4-2.8-1.5c-4.6-0.5-9-1.5-13.4-2.7c-1.8-0.5-3.4-0.3-5.1,0.2
				c-3.3,1-6.2,3.1-9.5,4.1c-5.1,1.6-9.3,4.5-13.7,7.3c-0.6,0.4-1,1.4-0.8,2.4c0.4-0.3,0.8-0.7,1.2-0.9c9.4-5.1,19.5-6.8,30.1-6.1
				c7.8,0.5,15.2,2,20.1,9c0,0.2,0.1,0.5,0.1,0.7c-1.8,0-3.3-0.7-4.9-1.5c-5.9-3.1-12.4-5.4-18.8-3.7c-5,1.3-10.6,1.7-15.1,4.8
				c-3.5,2.4-7.7,3.5-11,6.5c1,0.7,1.6,0.4,2.4,0c10.7-5.1,22-6.5,33.6-4.9c5.5,0.7,10.7,2.3,14.4,6.9c0.5,0.6,1.2,1.2,0.9,2
				c-0.5,1.1-1.6,0-2.3,0.4c-2.6-1.7-5.2-3.5-7.8-5.2c-0.9-0.6-1.8-1.7-3.2-0.8c-0.5-0.1-0.7,0.1-0.7,0.6c-2.1,1.1-4.1,0.8-6.1-0.3
				c-1.1-0.6-2.2-1.4-3.6-1c-0.5-0.4-1-0.4-1.4,0c-1.3,0-2.6-0.2-3.8,0.6c-2.2,2-5.6,2-7.5,4.5c-0.2,0-0.4,0.1-0.5,0.2
				c-4.2,2.6-9.6,3.2-12.7,7.9c3.8-1.5,7.5-2.9,11.3-4.4c0.4-0.1,0.9-0.3,1.3-0.4c0.4,0.3,0.8,0.3,1-0.2c0.5-0.1,1-0.3,1.4-0.4
				c0.6,0.4,1.1,0.2,1.5-0.2c0.4-0.1,0.7-0.1,1.1-0.2c6.7-0.4,13.4-0.7,20.1,0.1c0.4,0.1,0.7,0.1,1.1,0.2c0.4,0.5,0.9,0.7,1.5,0.3
				c0.4,0.1,0.7,0.2,1.1,0.3c4,2,8.4,3.6,10.6,7.9c0,0.2,0.1,0.4,0.1,0.7c-2,0.2-3.5-1-4.9-2.2c-1.8-1.5-4.1-3.2-6.1-2.6
				c-3.5,1.1-6.5-0.5-9.8-0.5c-10.4,0-19.2,3.5-27.5,9.2c-0.7,0.5-1.8,1.6-2.2,0.9c-1.2-2-5.7-1.8-4.1-5.9c0.4-1.1-0.4-2.5-0.4-3.8
				c-0.1-3.7-3.3-6.6-2.2-10.6c0.8-3-0.4-5.6-2.5-7.7c-0.9-0.9-1.1-1.8-0.5-2.9c1.9-3.9,2.8-7.9-0.3-11.6
				c-3.5-4.2,0.9-10.2-3.3-14.3c-0.1-0.1,0.4-1.2-0.6-1.2c-0.6,0-1.4,0.1-1.8,0.4c-0.5,0.4-0.1,1,0.2,1.4c2.2,2.5,0.9,4.8-0.2,7.3
				c-0.7,1.7-2.5,2.6-2.9,4.3c1.7,1.8,4.6-2.5,6,1.8c0.8,2.5,1,4.4,0.4,6.8c-0.6,2.4-2.8,3.6-3.9,5.6c1.3,1.1,3.5-0.7,3.8,1.3
				c0.3,2-1.9,2-3.2,2.7c-0.1,0-0.2,0,0,0c2,1.4,3.5,2.9,4.3,5.3c0.9,2.8-2,3.4-3,5.4c2.2,0.2,3.9-1.2,6.2-0.8
				c-1.8,1.9-2.2,3.5,0.2,4.9c0.4,0.2,0.5,1.2,0.7,1.8c0.7,2.7-1.6,4.4-2.3,6.4c1.1,1.4,3-1,3.8,0.9c0.6,1.4-1.5,1.7-1.7,2.9
				c3.6,0.8,4.7,4,0.1,10.9c2.6-1,6.3,0.6,5.8-3.5c-0.5-4.8,2.2-6.5,5.8-7.9c9.2-3.6,18.8-5,28.7-4.1c5.7,0.5,11.3,1.5,15.9,5.4
				c1.6,1.3,3.1,2.7,2.8,5.4c-2.2-1.1-2.6-4-5.1-4c-2.3-0.1-4.5-0.7-6.7-1.5c-6.4-2.2-12.6-3.1-18.7,0.8c-0.6,0.4-1.5,0.7-2.2,0.7
				c-3-0.3-5.5,0.7-8,2.2c-3.6,2.2-7.1,4.5-10.6,6.8c0.7,0.6,1.2,0.7,1.5,0.5c6-3.2,12.4-4.8,19.1-5.6c9.1-1.1,18.2-1.1,26.7,3.1
				c2.9,1.4,5.8,3.2,6.4,7.3c-3-0.4-3.6-3.5-6.4-4.1c-5.6-1.1-11.1-3-16.9-2.7c-1.7,0.1-3.3-1.6-4.7-0.7c-2.8,1.6-6.3,1.6-8.7,3.5
				c-4.4,3.5-10.2,4.4-14.2,8.4c-1,1-2.1,1.1-2.3-1c-0.2-1.8-0.8-3.6-1.2-5.4c-0.2-0.7-0.5-1.3-1.3-1.1c-0.6,0.2-0.6,0.9-0.4,1.4
				c1.3,3,0.4,5.5-1.4,7.9c-0.2,0.3-0.4,0.9-0.3,1.2c0.3,0.6,0.9,0.5,1.4,0.2c2.5-1.1,5.2-1.8,7.7-2.9c12.5-5.5,25.5-6.7,38.9-3.8
				c3.8,0.8,7.2,2.5,10,5.3c1.6,1.6,1.6,1.9,1.2,4.2c-2.3-2.6-5.1-4.3-8.4-4.7c-4.8-0.5-9.6-0.3-13.8-3.1c-0.3-0.2-0.9-0.2-1.3-0.1
				c-3.5,1.6-7.9,1.1-10.9,3.2c-4.5,3.3-10.4,3.1-14.2,7.8c-1.2-5.3-1.9-5.7-6.5-3.7c3.4,0.9,2.2,2.9,1.6,4.9
				c-0.8,2.6-2.9,4.5-3.9,7c1.7,0.4,2.6-0.9,3.8-1.3c0.9-0.3,2-0.6,2,0.7c0.1,1.5,1.2,2,2.1,2.7c1.4,1,1.5,1.9,0.4,3.2
				c-1.5,1.8-2.8,3.6-4.1,5.5c-0.7,1.1-1,2.1,0.9,2.1c3.7,0,5.3,2.5,3.9,6c-0.5,1.3-0.9,2.7-1.4,4c-0.3,0.9-1.1,2-0.1,2.7
				c0.9,0.6,2.1,0.9,3.3,0.2c6-3.4,12.6-4.9,19.3-5.7C546.7,1222.7,559.7,1221.5,570,1232.1z M483.7,1107.4c2.2-0.1,4.7,0.3,5.6-1.2
				c1.7-2.8,1.6-6.2,1.1-9.5c-0.1-0.7-0.8-1.1-1.5-0.9c-0.7,0.2-0.7,0.8-0.5,1.3c0.7,1.6-0.2,2.8-0.9,4
				C486.4,1103.1,485.1,1105.1,483.7,1107.4z M448.2,940.1c-2.5-0.7-4.3-0.1-6.1,0c-0.6,0-1.1,0.1-1.3,0.7c-0.2,0.8,0.4,1.1,0.9,1.5
				C444.7,944.4,446,941.9,448.2,940.1z M456.8,999.7c1.5-0.1,4.1,0.4,4-1.4c-0.2-1.8-2.7-0.6-4.1-0.4c-0.9,0.1-2.6-0.5-2.4,1.2
				C454.4,1000.3,455.9,999.6,456.8,999.7z M441.4,925.2c-0.5,0-1.1,0-1.6,0.2c-1,0.2-2.6-0.4-2.6,1.1c0,1.4,1.6,1.5,2.8,1.4
				c0.5,0,1.1-0.1,1.6-0.1c1.2-0.1,2.4-0.5,2.2-1.8C443.6,924.6,442.2,925.5,441.4,925.2z M454.3,983.3c-1,0.6-3.2-0.4-3.3,1.5
				c-0.1,1.4,1.7,0.9,2.7,0.9c1.1,0,2.7,0.6,2.9-1.2C456.7,983.2,455.3,983.4,454.3,983.3z M472.2,1040.3c-3.6-0.5-4.3-0.3-6.5,1.7
				C468.3,1042.7,470.4,1042.6,472.2,1040.3z M447.6,941.8c1.4,0.7,2.2,1.4,3.5,1C450.4,941.2,449.5,940.8,447.6,941.8z M492,1123.9
				c-0.4-0.3-0.5-0.5-0.8-0.6c-0.1,0-0.5,0.2-0.5,0.3c0.1,0.3,0.2,0.6,0.3,0.7C491.3,1124.4,491.6,1124.1,492,1123.9z M449.8,935.1
				c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1,0.1-0.2,0.2-0.2,0.2c0,0.2,0.1,0.3,0.2,0.4C449.6,935.3,449.7,935.2,449.8,935.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M608,1379.6
				c2.5,1.4,5.1,0.9,7.7,0.5c1,0,2.1,0.4,2.9,0c2.4-1.3,4.3-1.3,6.3,0.8c0.5,0.6,2,1.2,3,0.3c0.5-0.5,0.5-1.6,1.7-1.6
				c4.5,0.1,9-0.3,13.4,0.3c8.1,1.2,16.2,1.5,24.3,2.8c8.3,1.3,16.8,1.4,25.1,2.3c3.9,0.4,7.8,1.2,11.7,0.9c0.6,0.1,1.2,0.2,1.8,0.3
				c0.4,0.4,0.9,0.6,1.5,0.2c1.5,0.2,2.9,0.3,4.4,0.5c0.5,0.4,1,0.5,1.5,0.2c1.3,0.1,2.5,0.3,3.8,0.4c0.6,0.6,1.4,0.5,2.1,0.3
				c0.4,0.1,0.8,0.3,1.2,0.4c0.2,0.8,0.8,0.6,1.4,0.5c1.5,0.1,2.9,0.2,4.4,0.3c1.6,0.6,3.4,0.8,5.1,1c5.5,0.8,11,1.5,16.5,2.5
				c6.2,1.2,12.4,2.6,18.6,3.9c-0.2,0.6-1.6,0.8-1,1.9c0.4,0.8,1.3,0.8,2,0.7c1.2-0.1,1-0.9,1-1.9c0.1-2,0.3-4.1,3.3-3.8l0,0
				c0.1,1.5,0.1,3,0.3,4.5c0.2,1.2-0.1,2-1.4,2c-2.8-0.1-5.4,1.1-8.1,1.3c-3.4,0.3-6.7,0.4-10.2-1.3c-5.5-2.7-11.2-1.9-16,2
				c-1.9,1.5-4.7,3.2-0.9,5.9c0.5,0.4,0.8,1.3,0.9,2c0.4,1.4,0.9,2.1,2.7,2.1c2.6,0,4.2,1.5,4.2,4.3c0,1.2,0.2,2.4,0.3,3.9
				c-0.8-0.5-1.4-0.7-1.9-1.1c-1-0.7-2-1.6-3.1-0.3c-0.9,1.1-0.3,2,0.4,2.9c0.9,1.1,1.7,2.3,2.3,3.6c0.9,1.7-0.2,3.2-1.1,4.4
				c-1,1.2-1.8-0.2-2.5-1c-2.4-2.4-4.8-2.4-7.6-0.5c-1.1,0.7-2.5,1.4-3.9,0.5c-0.8-0.5-1.1-0.3-1.8,0.3c-2.3,1.9-3.5,3.8-1.5,6.6
				c-4,0.6-5.8,3.4-4.7,6.9c0.5,1.5,0.7,2.6-1.4,2.6c-2.3,0-2.3,1.5-2.3,3.3c0,4.2,2.4,7.6,3.4,11.4c0.8,3.1,0.9,6.7,5.3,7.1
				c0.3,0,0.8,0.3,0.8,0.5c0.7,4.4,3.9,6.7,7.1,9.1c0.1,0.1,0.1,0.5,0.2,0.6c2.8,2.2,4.8,5.3,9.2,5.4c2.6,0.1,5.3,2.3,5.8,5.7
				c0.1,1,0,2.3,1.4,2.1c1.1-0.2,2.3-0.8,2.4-2.3c0-0.9,0.2-1.8,0-2.6c-0.7-3.2,2.1-3.8,3.9-4.3c2.6-0.8,4.1,1.8,5.3,3.6
				c1,1.5-0.2,3-1.7,4c-0.9,0.6-1.5,1.5-2.8,1.5c-0.9,0-1.8,0.5-1.7,1.6c0.2,1.2,1.3,1,2.1,0.9c1.9-0.2,2.8,0.9,3.4,2.4
				c0.6,1.6,0.9,3-0.5,4.5c-2.8,3-1.3,12.2,2.5,13.2c3,0.8,5.7,2.5,8.8,2.9c3.7,0.5,4.6,2.6,3.3,6.2c-0.6,1.6-2.4,3-1.7,4.8
				c0.8,2.2-0.7,3.5-1.9,3.8c-4,1.1-5.5,4.4-7.2,7.5c-0.9,1.7-2.3,2.7-4.1,2.6c-6.1-0.3-11.8,1.7-17.8,2.1
				c-3.2,0.2-6.4,1.8-10.1,0.5c3-2.5,7-2.3,9.6-4.9c-2.7-2.3-8.4-1-10.7,1c-1.4,1.2-2.2,2.5-0.9,3.9c-3.1,1.7-6.4,1.2-9.6,1.6
				c-13.5,1.8-27.2,1.9-40.7,3.6c-7.4,0.9-15,0.9-22.5,0.9c-6,0-12,0.8-18,1.2c-1.5,0.1-2.8-0.4-4.4,0c-4.3,1.1-7.4,3.7-10.7,6.4
				c-2.2,1.8-2.7,3.5-2.1,5.4c0.6,2.1,2.9,1.2,4.6,1.2c10.7,0,21.3-1.6,32-2.3c13.8-0.9,27.6-1.9,41.4-3.1c10-0.9,20-1.4,30-2.1
				c11.9-0.8,23.8-1.8,35.8-2.4c1.9-0.1,5.3-3,6.1,1.7c0,0,0.6,0,0.9-0.1c8.7-2.4,16.3-6.8,22.6-13.3c1.6-1.7,2.8-2.2,4.6-0.5
				c0.7,0.7,1.5,1.2,2.4,1.8c1.4,0.9,1.6,1.9,0.4,3.2c-1,1.1-2.4,2-2.5,3.7c0.4,0.1,0.7,0.2,1,0.2c5.8-0.7,11.5-0.8,16,4
				c0.4,0.4,1.2,0.7,1.3,0.1c0.2-2.2,2.2-2.5,3.4-3.6c0.7-0.7,0.9-1.5,0.2-2.3c-0.8-0.8-1.2-0.4-1.7,0.4c-0.8,1.4-1.7,0.6-2.3-0.1
				c-1.3-1.4-2.1-3-2.4-4.9c-0.6-3.1-2.5-5-5.5-6c-2.3-0.8-4.2-3.2-4.5-5.1c-0.3-1.9-0.9-2.6-2.2-3.5c-0.8-0.5-1.5-1.7-1.1-2.5
				c0.5-1.1,1.6-0.5,2.5-0.2c1,0.3,2.2,0.3,3.2,0.3c10,0.3,19.8,1.9,29.7,3.1c6.9,0.9,13.9,1.7,20.8,3.4c7.9,1.9,16,2.5,24,3.8
				c7.7,1.3,15.6,1.6,23.3,3.1c4.5,0.9,5.6,2,5.7,6.6c0.1,6.5,0.4,13,0.7,19.5c0.3,7.1,0.8,14.3,1.1,21.4c0.3,5.5,0,11.1,0.7,16.6
				c0.5,3.9,2.4,7.7,0.9,11.7c-0.1,0.2,0,0.5,0.1,0.6c2.7,2.9,2.1,6.6,2.6,10.1c0.1,0.9,0.5,3.3,1.7,2.3c1.2-0.9,4.9,0.2,3.5-3.7
				c-1.5-4.3,0-8.9-1.1-13.9c1.5,1.6,0.2,3.4,1.5,4.4c0,0.7,0,1.4,0.1,2.1c-0.5,0.4-0.6,0.8,0.2,1c0.4,0.1,0.8,0.2,1.2,0.4
				c0.2,0.1,0.4,0.2,0.6,0.3c-1.3,0.7-2,1.7-1.8,3.4c0.3,2.6,0.2,5.3,0.3,8c-2.1,0.4-4.4-0.5-6.2,1.1c-1.6,0.1-3.3,0.1-4.9,0.2
				c-4.6,0.3-9.1,0.8-13.4,2.6c-2-0.1-3.7,0.9-4.9,2.2c-2.1,2.3-4.4,2.9-7.3,1.8c-1.2-0.5-2.5-0.6-3.8-0.9c-6.3-1.4-6.3-1.4-6.3,5.2
				c0,0.5,0.1,1.1,0,1.6c-0.5,2.9,1,3.3,3.4,3c3.6-0.5,6.9-1.7,10.3-2.9c4.1,0.2,8.1-1.2,12.2-1.5c1.5-0.1,3-0.6,4.5-0.7
				c3.7-0.2,3.5-0.2,2.4,3.1c-0.7,2.2,0.6,4.6,0.2,7c-0.3,1.9,1,4.2-0.2,5.6c-1.4,1.7-3.4-0.4-5.2-0.4c-3.7,0.1-7.4-1.1-11.3-0.4
				c0.7,1.4,3.8,0.8,3,3c-0.9,2.5-3.4,3.9-5.8,4.6c-1.6,0.5-3.4,0.7-5,1.4c-1.2,0.5-2.8,0.5-3,2.1c-0.1,1.6,2,0.8,2.8,2.3
				c-5.5,0.5-10.2-1.2-14.9-3c-0.1-3.7-0.1-7.5-0.2-11.2c-0.1-2,0.9-2.9,2.8-3.2c1.4-0.2,2.7-0.6,4.1-1c0.4-0.1,0.8-0.5,0.9-0.8
				c0.1-0.7-0.6-0.9-1.1-0.8c-4.4,0.3-8.9,1-13.6,0.8c0.9,1.6,2,1.5,3,1.9c0,0.4,0,0.8,0,1.2c-1.5,0.5-2.6,2-4.3,2.1
				c-0.2-3.3-0.4-6.6-2.7-9.2c1.1,0.4,2.3,0.6,2.1-1.2c-0.3-2.5-0.5-5-0.3-7.5c0-0.5-0.6-1.1-0.9-1.6c0.6-2.8-1.8-3.5-3.4-4.8
				c-0.8-0.2-1.7-0.4-2.5-0.7c-1.4-0.4-1.9,0.6-2.3,1.6c-0.2,0.5-0.9,1-0.3,1.4c0.7,0.5,1.6,0.3,2.5,0c0.8-0.3,1.7-0.4,2.5-0.6
				c1,0.7,0.4,2.1,1.3,2.8c-1.8-0.1-3.7-0.3-5.5-0.4c-0.7,0-1.3,0.2-1.6,0.9c-0.3,0.6,0.5,0.8,0.8,1.3c0.6,1,2.4,1.3,1.8,3
				c-2.5-1.1-1.9,0.7-1.8,1.9c0.1,4.2,0.9,8.3,0.6,12.5c-0.1,1.9-0.1,4,2.4,4.7c1.1,0.3,0.5,1.7,0.2,1.6c-2.8-1.1-2.1,1.6-2.9,2.8
				c-1.1-1.4-1.8-3.1-3.8-2.1l-0.1,0c1.5-0.5,3.2-0.6,3-3c-0.4-4.6-0.7-9.3-0.7-14c0-3.9-1.1-7.8,0.3-11.8c0.3-0.9-0.6-3.5-2.4-4
				c-2.6-0.7-5.2-1.7-8-0.9c-1-0.9-2.4-0.9-3.6-1.3c-3.7-1-7.7-0.4-11.4-1.9c-7.8-3.2-16.3-3.8-24.4-5.9c-1.8-0.5-3.5,0.5-5,1.6
				c-1.2,0.9-2.6,1.6-3.8,2.5c-1.1,0.7-2.1,1.5-2.4-0.6c-0.1-0.8-0.9-0.9-1.5-0.5c-0.8,0.4-1.7,1-1.2,2c1.2,2.1-0.5,2.6-1.8,3.3
				c-15.4,9.1-30.8,18.2-46.2,27.2c-0.6,0.3-1.2,0.9-1.7,0.8c-4.8-0.2-9.6-0.5-14.4-0.8c2.1-2,4.4-3.3,6.6-4.6
				c11.7-6.8,23.3-13.9,34.8-21.2c0.7-0.5,1.6-0.7,2.4-1.1c0.8-0.4,1.1-1,0.9-1.9c-0.2-0.7-0.7-1.5-1.2-1.2
				c-1.7,0.8-3.9-0.2-5.2,1.2c-2.2,2.4-5.4,1.6-8,2.8c-3.3,1.5-7.5-1.6-11.5,1.3c-2,1.5-5.9,1-8.8,1.2c-9.1,0.6-17.9,2.7-26.8,4.3
				c-2.2,0.4-2.6,1.3-2.5,3.3c0.2,3.9,0.2,7.8,0.3,11.8c0,1.2,0.1,2.1-1.6,2c-5.1-0.3-10.3-0.9-15.2-2.3
				c-11.8-3.4-23.9-5.4-36.1-6.9c-8-1-14.7-3.3-21.4-8.1c-9.2-6.6-17.8-14.7-29.2-17.9c-0.2-0.1-0.5-0.1-0.6-0.3
				c-1.3-2.4-1.3-2.4-3.2-2.4c-1.1-1.7-6-2.6-8.8-1.7c-2.1,0.7-4.1-0.5-6.1-0.6c-0.5,0-1-0.8-0.8-1.6c0.3-0.9,1-1.2,1.8-0.9
				c1.3,0.5,2.6,0.4,3.9,0.4c0.9,0,2,0.4,2.6-0.7c0.7-1.2,0-2.1-0.8-2.9c-0.8-2-1.2-4.1-2.3-5.8c-1.9-3-2.6-6.9-6.6-8.2
				c-0.6-2.5-2.8-2.6-4.7-2.9c-3.5-0.6-7.1-1.1-10.7-1.4c-3.2-0.3-6-1.6-8.7-3.9c0,3.2-2,2.9-4,2.7c-2.9-0.3-5.8,0.4-8.9-0.7
				c3.7-2.4,4.1-4.9,2.8-8.6c-1.2-3.5-1.4-7.3-0.5-11c0.4-1.8,0.2-4.6,3.1-1.5c0.7,0.7,1.5,0.6,2-0.6c0.8-1.9,1.6-3.8,2.5-5.6
				c0.7-1.4,1-2.4-0.2-3.8c-1.1-1.3,0-2.9,1-4c0.7-0.8,1.1-1.8,0.2-2.2c-3.8-1.5-2.5-4.9-2.5-7.4c0-2.4-0.4-4.7-0.4-7
				c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0-0.2,0-0.2,0c-0.1,0.1-0.1,0.2-0.1,0.3c-0.2,0-0.4,0-0.6,0c-1.5-0.1-3,0.3-3.9-1.4
				c-1-2.2,1.2-2.6,1.9-3.8l0,0c1.1,0.1,1.6,1.9,2.8,1.2c1.5-0.8,0-2.2,0.3-3.2c0.9-3.2,0-6.4,0.6-9.5c0.3-1.7,1.9-3.3,3.8-4.3
				c8-4.4,15.9-9.2,23.4-14.4c1.3-0.9,2.5-0.9,3.7-0.6c10.3,2.5,21,3.7,31.5,5c11,1.4,22.1,3.1,33.2,3.9c3,0.2,5.9,1.4,9.4,0.6
				c-5.8-3.4-11.8-5.2-18.1-6.4c3.8-1,7.6-0.3,11.2,0.4c6.8,1.2,13.6,1.9,20.4,3.2c1.8,0.3,3.5,0.9,5.2,1.4c2.3,0.6,1.5,1.8,0.4,2.6
				c-1.1,0.9-2.5,1.3-3.7,2c-8.8,4.3-17.9,7.8-26.4,12.8c-6.4,3.8-13.3,6.2-19.8,9.7c-5,2.8-11.1,3.6-15.5,7.3
				c-5,4.1-11.3,5.1-16.8,8c-4.8,2.5-9.5,5.1-14.6,7c-6.1,2.3-11.7,5.6-17.7,8c-3.6,1.4-5.1,3.9-4.9,7.9c0,1.1-0.2,2.1-0.2,3.2
				c-0.2,3.4-0.9,3.8-3.9,2.3c-0.7-0.4-1.5-0.8-2,0c-0.6,0.9,0.1,1.7,0.9,2.2c3.3,1.8,6.6,2.4,10.2,0.7
				c10.6-5.1,21.2-10.2,31.8-15.2c3.4-1.6,7.2-2.7,10.4-4.4c3.5-1.9,7.1-3.7,10.5-5.8c5.7-3.6,12.2-5.7,18.2-8.8
				c8.5-4.4,17.2-8.4,25.9-12.6c3.1-1.5,6.4-2.8,9.6-4.2c1.5-0.6,2.3-0.3,2.4,1.4c0.3,3.3,0.6,6.7,0.8,10c-0.1-3.5,0.7-6.9,0.9-10.4
				c0.1-2.1,1.4-4.1,3.6-5.1c6.5-3,13.1-5.9,19.4-9.2c4.8-2.5,9.4-0.8,14,0c1.5,0.3,2.9,0.5,4.9,0.3c-2.9-3.9-5.3-7.8-3.2-13
				c2.4,3.3,5.6,3.1,8.9,2.4c0.5-0.1,1.1-0.4,1.6-0.3c3.3,0.9,4.7-0.7,4.4-3.7c-0.3-3.2,1.2-6.6-1.5-9.6c-1.3-1.4-0.7-4,0.7-5.5
				c1.7-1.8,3.6-0.3,5,0.7c1.7,1.1,1.5,3.1,0.5,4.7c-2.5,3.8-1.4,8-1.7,12c-0.1,1.3,0.5,2.2,2.5,2c1.7-0.2,3.5,1,5.3,1.5
				c2.1,0.5,3.1-0.4,3.3-2.4c0.1-1.5-0.1-3-0.4-4.5c-0.4-2.5-1-5.2,0.8-7.6c1.2-1.7,0.6-2.7-1.4-3.2c-8.1-2-14.3-6.8-19.5-13
				c-1.8-2.2-3.9-4.1-6.6-5.1c-1.8-0.6-1.5-1.9-1.6-3.2c-0.1-1.5-4.2-5.8-5.5-5.9c-1.4-0.1-2.3,1-2.7,2c-0.5,1.2,1,1,1.6,1.5
				c1.3,1.1,3.5,0.9,3.9,3.1c0.1,0.6,1,0.9,0.1,1.7c-0.8,0.8-1.6,1-2.2,0.2c-1.3-1.7-2.5-1.8-4.1-0.5c-1.1,0.9-1.4,0-1.8-0.9
				c-0.8-1.7-2.6-1.8-3.6-0.9c-3.2,2.6-5.6,0.2-7.6-1.4c-1.8-1.4-3.1-2.1-5.1-0.8c-1.5,1-3.4,0.8-4.7,0.2c-1.6-0.7-3.2-0.7-4.7-1.2
				c-2.6-0.9-6.2,2.9-8.1-1.6c-3.2,0.3-6.3-0.9-9.5-1.2c-0.7-0.1-1.3-0.4-1.9,0.1c-2.1,1.6-3,0.1-4.2-1.3c-1.1-1.2-2.2-4.1-4.1-0.4
				c-0.7,1.5-2.2,0.1-3-0.5c-1.1-0.8-2-1.8-3.4-0.5c-0.5,0.5-1.2,0.3-1.8-0.2c-2.3-2.1-4.5-3.6-8-2.4c-1.5,0.5-3.6-0.5-5.4-1
				c-1.2-0.3-2.3-0.8-3.5-0.4c-1,0.4-1.2,0.1-1.6-0.8c-1.3-2.8-2.4-2.9-5.2-1.6c-1.7,0.8-3.9,0.7-5.4,0c-3.7-1.8-7.9-1.2-11.6-2.9
				c-1-0.5-2.7-0.3-3.8,0.2c-1.9,0.8-3.4,1-5.1-0.4c-1.7-1.4-2.4,0.7-4,1.4c-0.4-3.4,0.7-6.3,1.6-9.1c0.3-0.8,1.2-1.6,0.5-2.4
				c-1.3-1.4-0.6-2.6,0.1-3.8c0.4-0.6,1.4-0.8,2-0.2c1.1,1.1,2,2.3,1.8,4c-0.1,0.5-0.4,1.1-0.3,1.6c0.3,1,0.3,2.3,1.5,2.7
				c1.1,0.4,1.6-0.3,1.9-1.3c0.1-0.2,0.7-0.5,0.7-0.5c1.9,2,5.3,1.1,7,3.4c1.5,2,4,1.4,5.2,0.7c2.7-1.5,3,0.2,3.6,1.8
				c0.5,1.4,1,2.4,2.3,0.6c0.8-1.1,4,0.9,3-2.4c0.3-0.1,0.8-0.3,0.9-0.2c1.7,2.3,4,1.4,6.1,1.2c1.1-0.1,1.9,0.7,1.4,2
				c-0.7,1.8,0.7,2.6,1.8,3.4c1.2,1,2.3,0,3.2-0.7c1.6-1.2,3.6-2,2.9-5c-0.5-1.8,2-2.6,3.6-2.2c2.4,0.6,4.7,0.4,7.1-0.1
				c3.7-0.8,3.8-0.6,6.5,2.7c0.7-0.5,0.5-2,1.8-2c1.5,0,1.2,1.4,1.6,2.1c1.4,2.4,2.9,4.8,2.1,7.9c0,0.2,0.8,0.7,1.3,0.8
				c1.1,0.3,2.4-0.2,2.5-1c0.3-2.4,2.8-2.1,3.8-3.6c0.5-0.8,1.4-0.6,2,0.1c1.4,1.7,3.2,1.7,5.1,1c1-0.3,1.9,0.3,2.7,0.3
				c1.9,0,4.4,2.1,5.7-1.2c0.2-0.6,1-0.8,1.7-0.5c2.2,1.1,4,3.6,6.9,1.4c0.1-0.1,0.5,0.1,0.5,0.2c1.6,2.5,2.9,0.5,4.3-0.2
				c1.3,1.1,0.1,2.7,0.9,4c1.7-0.6,0-4.3,3.5-3.4c1.5,0.4,3.2-0.7,5.1,0.2c2,0.9,0.8-2.1,2-2.8c1.1-0.1,0.5,1.9,2.1,1.3
				c-0.1,0.1,0.2-0.1,0.2-0.2c-0.1-3.2,0.5-6.4-0.4-9.6c-0.4-1.4-0.1-2.9-2.7-2c-3,1-5.9-2.9-4.7-5.8c1-2.4,0.6-1.8-1.6-2.8
				c-3.1-1.5-6.6-0.8-9.6-2.4c-2.2-1.2-5.1-0.3-7.7-1.1c-1.8-0.6-3.8-2.6-5.7,0.1c-0.1,0.1-0.6-0.1-0.8-0.2
				c-3.3-2.4-7.2-1.9-10.9-2.8c-5.4-1.4-11-0.7-16.4-2.4c-6.3-2-12.9-3.4-19.7-2.7c-2.3,0.2-4.7-0.8-6.9-1.5
				c-5-1.6-10.1-2.5-15.3-2.1c-2.4,0.2-4.3-1.3-6.6-1.6c-1.2-0.1-1.9-1.6-1.5-2.9c0.4-1.3,1.5-0.3,2.2-0.2c1,0.1,1.9,0.3,2.9,0.5
				c0.7,0.2,1.5,0.4,2.2,0.5c0.6,1,1.5,0.9,2.4,0.6c0.6,0.1,1.2,0.2,1.8,0.3c1.3,1,2.7,0.6,4.2,0.2c4.7,1.2,9.5,1.9,14.3,2.5
				c0.5,0.2,0.9,0.6,1.4,0.7c8.1,1.3,16.1,2.6,24.2,3.8c1.2,0.8,2.5,1,3.9,0.5c3.6,0.7,7.2,1.5,10.8,1.9c5.4,0.6,10.8,2.3,16.3,2.1
				c2.2,0.5,4.5,1,6.7,1.5c2.4,0.6,4-0.6,4.3-2.8c0.6-5.3,0.6-10.6-3.6-14.9c-0.1-1.3,0.9-1.6,1.9-2.2c3.4-1.9,7.4-2.1,10.7-4
				c0.8-0.5,2.4,0,2.4-1.2c0-1.2-1.5-1.5-2.5-1.7c-6.5-1.7-13.1-2.3-19.8-2.2c-2.6,0-4.7,1.6-5.4,4.4c-5.8-1-11.2-3.5-17.2-3.9
				c-1.4-0.1-0.8-1.3-0.5-2c0.7-1.8,1.5-3.6,2.2-5.4c0.3-0.7,0.5-1.4-0.6-1.7c-5.6-1.5-11.5-1.6-17-3.4c-0.7,1.5-0.4,2.8-0.1,4
				c0.4,1.8,0.3,3.7-0.2,5.5c-2.8,0.3-5.3-1-7.9-1.4c-1-1.8-0.3-3.6,0.2-5.4c1-2.9-0.2-4-3.2-4.4c-7-1.1-14.1-2-20.7-4.5
				c-1.4-0.5-3.1-0.9-2.9-3C606.1,1379.9,607.1,1379.7,608,1379.6z M711.3,1581.9c-0.5,0-0.8,0.2-0.8,0.8c-1,2.1-0.3,4.3-0.4,6.4
				c-0.1,2.1,0.7,3.1,2.9,3.4c3.8,0.6,7.4,1.6,11.2,2.3c3,0.6,5.8,0.6,7.4-2.9c0.7-1.7,2-3.1,3-4.7c2.9-4.9,5.8-9.7,7.5-15.2
				c0.4-1.3,1-3.3-1.2-3.1c-5.9,0.5-12,0.3-17.8,1.7c-0.9,0.3-1.7,0.4-2.6,0.1c-2.2,0-4.3,0-6.5,0.1c-1.4,0.1-3.2-0.4-4.1,1.3
				c-0.7,1.3,0.4,7.3,1.4,8.3c0.2,0.3,0.4,0.6,0.6,0.9C711.6,1581.7,711.5,1581.8,711.3,1581.9z M704.6,1616.6
				c1.2-0.3,2.4-0.7,3.6-1c-0.9-1.3-3.1,0-2.9-2.2c1.2,0.2,2.4,0.4,3.6,0.4c1.8,0.1,4.1,1.3,4.5-2c0-0.2,0.9-0.2,1.1-0.4
				c1.8-2,4.8-2.5,5.9-5.4c0.4-1.1,0.2-0.8-0.6-1.3c-3.3-1.7-6.9-1.9-10.3-2.7c-9.3-2.1-9.3-2.1-8.3,7.3c-0.9,0.5-1.8,1-2.6,1.5
				c-1,0.2-2,0.4-3,0.5c-0.4-0.8-1.5-0.7-1.5-0.3c0.5,2.2-1,1.6-2.1,1.7c-0.9,0.1-1.7,0.1-2.6,0.2c-0.5,0-0.9,0.3-0.8,0.8
				c0,0.2,0.4,0.6,0.6,0.6c3.6,0.2,6.7,2.4,10.3,2.6C701.2,1617.2,702.9,1617.4,704.6,1616.6z M869.1,1620c1.5,0.8,1.1,2.4,1.6,3.6
				c0.3,0.7,0.5,1.6,1.4,1.4c1-0.2,0.8-1.1,0.8-1.9c0.1-2-0.5-4,0.3-6c0.3-0.8-0.5-2.5-2-2.8c-1.8-0.3-1.7,1.3-2.2,2.2
				C868.6,1617.8,869.5,1618.9,869.1,1620c-1.4-1.1-2.9-2.3-4.3-3.4c-0.4-0.4-0.8-0.2-1.1,0.1c-0.3,0.3-0.6,0.9-0.2,1.1
				c1.6,1.1,1.1,2.6,1.1,4.1c0.1,2.1,1.8,2.8,3.5,2.6c2.5-0.3,0.6-1.9,0.7-3C868.8,1621.1,868,1620.4,869.1,1620z M720.3,1620.1
				c2.2,1.4,4.2,1.5,6-0.8c0.2-0.3,0.8-0.3,1.2-0.5c1.1,0.5,2.3,0.7,3.4-0.1c1-0.1,2.8,0.5,3-0.4c0.5-2.5,2.2-1.3,3.5-1.6
				c2.3-0.7,4.5,0.2,6.7,0.3c1.6,0.1,3-0.1,1.9-2.3c0.5,0.4,0.9,0.4,1.3-0.1c0.1-0.1-0.1-0.6-0.2-0.6c-0.7-0.2-0.8,0.3-1,0.8
				c-3.6,0.6-7.1,0.2-10.6-0.8c3.4-0.6,6.8,0.1,10.1-1.2c1.1-0.4,2.8-0.5,3.5,0.6c0.7,1.2,1.1,1.9,2.4,1.2c1-0.5,1.8-1.3,2.8-2
				c-1-1.3-2.3-1.2-3.4-1.5c-6.6-1.4-13.4-2.4-19.8-4.3c-4.7-1.4-8.4-0.5-10.3,3.5c-0.7,1.4-4.1,1.3-2.4,3.9
				c-1.3,0.8-3.1,0.9-3.6,2.6c-0.5,0-1.1-0.1-1.6,0c-1.1,0.1-2.3,0.4-2.3,1.8c0.1,1.5,1.5,1,2.3,1c1.6,0.1,3.2,0,4.8-0.1
				C718.4,1620.2,719.3,1620.5,720.3,1620.1z M838.1,1617.9c0.5,0.6,1.3,0.8,2,0.9c8,1.8,6.4,2.4,6.4-5.8c0-1.5-0.8-2-2.1-1.7
				c-3.4,0.7-6.9,0.5-9.9,2.5c-2.2,0.3-4.5,0.7-6.7,1c0,0.3,0,0.5,0,0.8C831.2,1616.4,834.7,1617.2,838.1,1617.9z M833.5,1586.8
				c0.4,0,0.9,0.2,1.3,0.1c4.5-1.5,7.9-4.9,12.1-6.9c1-0.5,0.6-1.6-0.2-2.1c-2.7-1.9-5-4.2-8-5.7c-0.7-0.3-1.6-1.3-2.3-0.4
				c-0.8,1,0.3,1.7,1,2.4c2.4,2.3,2.2,3.7-0.6,5.3c-0.4,0.2-0.9,0.4-0.8,0.8c0.8,2.8-1,4.5-2.5,6.3c-1,0.2-2.3,0.2-3.1,0.7
				c-3.2,2.1-5.5,0.2-8-1.3c-1.2-0.7-3.2-0.6-2.3-3.2c0.5-1.5-0.5-2.7-2.4-3.2c-2-0.5-2.7-2.7-1.5-3.7c2.6-2.4,3.2-5.8,5.4-8.3
				c1.4,0.8,2.9,0.6,4.4-0.2c0.1,2.4-4.4,3.4-1.8,5.6c1.3,1,4.9,0.8,6.3-0.3c1.8-1.5-1-3.1-2-5c5.3,0.1,6.2-4.1,8.1-7.7
				c-4-0.2-5.3-7.3-11-3.3c1.6-2.9-0.3-4-1.5-5.3c-0.4-0.4-0.9-0.8-1.4-0.4c-0.5,0.3-0.5,0.9-0.3,1.5c0.5,1.2,0.7,2.1-1.2,2.1
				c-0.7,0-1.5-2-2.2-0.1c-0.5,1.4-0.9,2.7,0.8,4c1.7,1.3,3.8,2.3,5.1,4.3c-1.4,0.9-2.7,0.6-4,0.7c-1.2,0-2.4-0.1-3.6,0
				c-11.3,0.8-22.7,1.7-34,2.6c-11.7,0.9-23.5,1.6-35,4.5c-2.2,0.6-3.5,1.4-4.3,3.6c-2.7,7.2-6.8,13.8-10.9,20.3
				c-1.2,1.8-0.7,2.4,1.3,2.8c15.3,3.1,30.6,6.4,45.9,9.5c6.4,1.3,13.2,1.5,19.1,0.1c7-1.6,13.7-5.3,19.6-9.9c1-0.5,2.2,0.3,3.1-1.2
				c1.7-2.8,4.2-4.8,7.3-6C831,1589.1,832.4,1588.1,833.5,1586.8z M602.1,1584.6c5.1-1.2,9.7-3.8,14.6-5.5c-3.1-0.3-6.3-0.6-8.9,0.8
				c-1.9,1-3.3,0.9-5.2,0.7c-5-0.5-10.1-1-15.1,0.7c-2.6-0.1-5.2-0.3-7.8-0.3c-1,0-2.4-0.5-2.8,0.9c-0.4,1.4,1.1,1.6,1.9,2.3
				c2.7,2,6.1,3.1,7.9,6.2c-2.7,0.1-4.6,2.1-7,2.8c2.6,0.4,5-0.2,7.1-1.5C592.3,1590.2,597.2,1587.5,602.1,1584.6z M876.9,1559.9
				c-8.3-0.6-16.6-0.2-24.9,0.7c-0.4-0.1-0.9-0.1-1.3-0.4c-5.5-4.3-12.2-6.4-18.1-10c-0.7-0.4-1.6-0.9-2.4,0.1
				c0.3,1.2,1.4,1.6,2.2,2.2c8.3,5.8,16.4,12,24.4,18.2c7.9,6.1,15.8,12.1,23.8,18c1.1,0.8,2.7,2.3,3.8,1.5c2.7-2,5.4-0.9,8.1-0.6
				c0.8,0.1,1.9,0.3,2.3-0.8c0.4-1-0.8-1.4-1.1-2.2c-0.6-1.3-2.9-0.7-2.9-2.7c0.4-0.1,0.8-0.1,1.2-0.2c0.3,0,0.5-0.1,0.8-0.1
				c2.3-0.4,5.2,0.1,4.9-3.9c-0.4-5.2-0.5-10.4-0.6-15.6c-0.1-2.1-0.9-2.5-2.7-1.5c-2.7,1.6-5.4,3.2-8.1,4.8
				c-4.8,2.9-9.7,5.8-14.5,8.6c-2.7-2.1-5.5-4.1-8.2-6.2c3.9-2.4,7.8-4.8,11.6-7.3c0.9-0.6,2.4-0.8,2.2-2.4c0.8-0.6,2.3,0.3,2.7-1.2
				c0-0.2-0.3-0.7-0.5-0.7C878.3,1558.2,877.1,1558.2,876.9,1559.9z M645.5,1605c-6.7-0.7-13.5,0.2-20.2-1.4
				c1.7,2.3,3.4,4.1-1.8,5.5c4.4,0.2,7.5,0.1,10.5,0.6c12.9,1.9,26,1.9,39,3.7c1.6,0.2,3.6-1.4,4.2-0.8c2.1,2.1,4.7,0.5,6.9,1.4
				c0.6,0.3,1.7,0.4,2-0.5c0.4-1.1-0.7-1.1-1.4-1.3c-0.9-0.3-1.9-0.6-2.8-0.7c-2.7-0.3-5.4-0.5-8.1-0.8c-1-0.1-2.1-0.3-2.3-1.4
				c-0.2-0.9,0.5-1.7,1.4-2c1.8-0.4,1.6-1.8,1.6-3.1c0.5-0.1,1-0.2,1.5-0.3c1.1,0.7,1.2-0.1,1.2-0.9c0.7-0.7,1.4-1.4,2.1-2.1
				c1.5-1.3,2.1-2.8,1.9-4.5c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6,1.1-1.2,2.3-1.8,3.4c-1.9,0.4-3.6,1.4-5.2,2.5c-2.6,1.6-3.1,1.1-3.5-1.9
				c-0.4-3,0.9-6-0.5-8.9c-0.1-1.5,0-2.9-2.3-3.2c-12.3-1.3-24.6-2.7-36.8-4.2c-3.1-0.4-5.7,0.3-8.2,2c-1.2,0.2-2.4,0.1-3.5,0.5
				c-6.6,2.6-13.1,5.3-19.7,8c-0.8,0.3-1.8,0.6-1.5,1.7c0.2,1,1.2,1.1,2,1.3c0.9,0.3,2.4-0.3,2.7,0.7c0.6,3,2.8,2.2,4.6,2.2
				c1.8,0,3.5,0.4,5.2,0.6c4.5,0.6,8.9,2,13.5,0.6c2,0.4,4,0.9,6,1.2c0.8,0.1,1.9,0.5,2.3-0.5c0.4-0.8-1-1-1.1-1.9
				c1.1,0,2.2,0,3.2,0c0.6,0.2,1.2,0.5,1.9,0.7c-0.8,0.2-1.9,0-1.8,1.3c0.1,0.9,1,1.1,1.7,1.1c1.2,0.1,2.4,0,3.5,0
				c2.9-1.4,5.8-1.1,8.8-0.7c1.4,0.2,2.8,0.1,4.2,0.2c-0.1,0.3-0.1,0.6-0.2,0.8c-0.8,0-1.5,0.1-2.3,0.1c-1.2-0.8-2.2-0.8-2.7,0.7
				C647.5,1604.8,646.5,1604.5,645.5,1605z M673,1630.3c3.7-0.8,7.3-1.5,11-2.3c0-0.4,0-0.7,0-1.1c-3.3,0-6.5,0-9.8,0
				c-1,0-1.1,0.9-1.1,1.7c-2.3,0.6-3.1-1-3.1-2.6c0-1.8-1.1-2.5-2.5-3.5c2.7,0.8,2.1-1.1,2.5-2.2c0.4-0.9,0.1-1.5-0.7-1.9
				c-1.3-0.7-3.2-0.5-3.7,0.3c-1.6,2.4-4.1,3.2-6.3,4.7c-3.2,2.1-6.7,3-9.8,5.3c-0.5-3.3,2.2-2.4,3.3-2.7c2.8-0.8,3.6-4.4,6.8-4.3
				c0.2,0,0.5-0.6,0.4-0.7c-0.2-0.3-0.5-0.7-0.9-0.8c-4.1-1.7-7.4,2.3-11.3,1.6c-0.2,0-0.5,1.3-0.9,1.9c-0.2,0.3-0.7,0.5-1.1,0.5
				c-0.6,0-0.6-0.4-0.5-0.9c0.4-2.4-1.3-2.3-2.8-2.5c-2.3-0.2-4.5-0.6-6.8-0.8c-0.9-0.1-2.2-0.8-2.6,0.4c-0.4,1.3,1.1,1.6,1.9,2.3
				c2.5,2.4,6,2.7,8.5,5.8c3,3.5,7.9,5.5,13.3,5.2C662.4,1633.3,667.6,1631.2,673,1630.3z M886.8,1609.4c0.1-0.4-0.1-0.7-0.5-0.8
				c-0.5-0.2-1-0.3-1.5-0.5c0.1-1.1-0.1-1.8-1.3-2.1c-1.9-0.6-3.6-0.2-5.3,0.7c-0.7,0.4-1.9,0.1-2,1.2c-0.1,1.2,1.4,0.8,1.9,1.5
				c-1.4-0.2-2.5,0.6-3.7,1c-0.7,0.2-1.6,0.7-1.2,1.6c1.8,4,0.6,8.1,1,12.2c0.1,1.2,0.4,2.1,1.8,2.3c6.9,1.4,13.9,2.7,20.8,4.1
				c1.2,0.2,1.5,0,1.9-1.3c1.4-3.9,0.4-7.7,0.5-11.6c0-0.5-0.5-1.4-1-1.6c-3.6-1.7-5.6-6.5-10.6-5.7
				C887.4,1610.5,887.1,1609.8,886.8,1609.4z M610.2,1539.8c3.7-1.7,7.5-3.3,11.2-5.2c4.2-2.2,8.7-3.9,12.7-6.5
				c4,0.8,3.2-4.5,6.6-4.6c-3.5-1-6.8-0.1-10-0.3c-8.4-0.5-17,0.8-25.3-2.1c-0.6-0.2-1.4-0.1-1.8,0.5c-0.6,0.8-0.1,1.4,0.3,2.1
				c1.3,2.4,3.8,3,6.1,3.3c6.5,0.9,13,1.5,19.5-0.3c2.1-0.6-0.6,1,0.3,1.1c-8.7,2.1-17.4,4.2-26,6.4c-9.1,2.3-17.8,6.1-27.2,7
				c-2.6,0.3-5.2,0.5-7.5,2.1c-3.1,0.9-5.5,3.4-4.6,5.2c1.1,2,3.3,2,5.4,1.4c1.3,0.2,2.6,0.5,3.9,0.7c2.8,0.1,4.8,2.8,7.8,2.1
				c4.6,0.2,7.8-3.3,11.9-4.6c0.9,0.2,1.4-0.2,1.6-1c0.2,0,0.3-0.1,0.4-0.2c1.4,0.2,2.3-0.6,3.2-1.5c0.4-0.2,0.8-0.4,1.2-0.6
				c0.8,0.1,1.4-0.1,1.7-1c0.3-0.1,0.6-0.2,0.9-0.4c0.8,0.2,1.4,0,1.7-0.8c0.4-0.1,0.9-0.2,1.3-0.3
				C607.3,1541.8,609,1541.2,610.2,1539.8z M707,1420c3.5-3.2,4.5-7.3,7.5-10.1c1.5-1.4,2-2,3.3-0.2c0.5,0.7,1.2,1.3,2.2,1.1
				c0.6-0.1,0.7-0.5,0.6-0.9c-0.3-0.9-0.6-1.9-1.7-2.3c-0.6-0.2-1.3-0.3-1.9-0.2c-5.4,1.7-10.9,2.9-16.5,3.9
				c-2.2,0.4-3.9,2.1-3.5,4.1c0.4,2.1-0.5,3.6-0.8,5.3c-0.4,3.1-3.7,6.1,0,9.2c0.1,0.1,0,0.7-0.2,0.9c-1.7,2.2,0.2,3.9,1.2,5.1
				c1.2,1.5,3.1,0.6,4.6-0.5c0.3-0.2,0.9-0.1,1.2-0.4c1.5-1.2,2.4-1.3,2.5,1c0.1,1.1,0.6,1.9,1.8,1.8c0.9,0,2,0.5,2.7-0.6
				c0.6-1,0.5-2.1-0.2-3.1c-1.1-1.5-2.3-3-4.4-2.8c-2.1,0.2-2.5-1-2.7-2.8c-0.3-4.9,2.7-9,2.8-13.8c0,0,0.4,0,0.7-0.1
				C706.6,1416.4,706.8,1417.8,707,1420z M889.4,1543.8c-0.6-0.9-1.6-0.9-2.5-1c-6.4-0.8-12.9-1.6-19.3-2.6c-2.9-0.4-5.8-1.3-9-0.7
				c-4.9,1-7.6,5.4-12.1,6.8c-0.4,0.1-0.7,0.5-0.5,1c0.1,0.5,0.6,0.6,1,0.6c0.8,0,1.5,0,2.3-0.1c12.7-1.1,25.5-2.1,38.2-3.2
				C888.2,1544.7,889.1,1544.9,889.4,1543.8z M871.5,1636.5c-6.6-0.2-8,1.2-7.7,7.9c0.1,1.9,0.3,3.7-2.3,4.2c-0.7,0.1-1.5,0.8-1,1.7
				c0.4,0.7,1.1,0.3,1.8,0.1c2.3-0.9,4.6-1.4,7-1.6c2-0.2,4.2-0.3,3.9-3.6c-0.1-1.1,0.6-2-0.9-2.7c-1.3-0.6-1-1.4,0.3-2
				c1.2-0.6,1.2-1.7,0.8-2.8C873.1,1636.8,872.3,1636.4,871.5,1636.5z M710.1,1530.1c1.1,4.9,3.1,9,5.8,13.5c0.4-2.3,0.7-4,2.9-1.8
				c0.4,0.4,1.4,0.1,1.3-0.2c-1-2.7,0.5-3.2,2.6-3.9c0.7-0.2,0.6-1.5,0-2.3c-0.8-1.3-1.8-2.5-2.5-3.8c-1.7-3.2-4.3-2.1-6.2-0.8
				C712.3,1531.8,711.4,1531.5,710.1,1530.1z M839.7,1598.9c3.5-0.9,7.6-2,11.8-3.1c1.2-0.3,1.2-1.1,1-2c-0.7-2.3,0.4-3.2,2.6-3.4
				c1.3-0.1,2.6-0.5,3.9-0.8c0.6-0.1,1.4-0.2,1.2-1.1c-0.2-1.2-3.8-2.7-4.9-2c-5.6,3.2-11.1,6.5-16.7,9.7c-0.9,0.5-1.6,0.9-1.3,1.9
				C837.4,1599,838.3,1599,839.7,1598.9z M743.9,1506.1c0-1.9,0-3.1,0-4.3c0-0.7,0.1-1.4,0.9-1.5c0.9,0,1,0.8,1.1,1.4
				c0.2,1.6,0.3,3.2,0.4,4.9c0.1,1.3,0.9,1.6,2,1.6c1.4-0.1,1-1,1.1-1.9c0.2-2.1-1.6-4.9,1.8-6.1c0.6-0.2,0.2-1,0-1.4
				c-0.7-1.2-0.6-2.5-0.5-3.8c0.2-2.1-0.5-2.7-2.7-2c-1.7,0.6-4.1-0.1-5.3,0.9c-1.5,1.3,0.6,3.3,0.5,5.2
				C743,1501,742.3,1503.2,743.9,1506.1z M564.3,1558c-4.1,0.3-7.9,1.1-11,4.1c-1.4,1.4-1.4,3.1-0.7,4.5c0.8,1.5,2.2,0.1,3.1-0.3
				c5.8-2.7,11.6-5.5,17.4-8.3c0.9-0.4,2.6-0.7,2.1-2c-0.5-1.3-1.9-0.2-2.9-0.3C569.4,1555.4,567.3,1558.1,564.3,1558z
				 M556.9,1532.1c-1.2-0.1-1.9,0-2,2.2c-0.2,4.4,3.5,4.6,5.9,5.8c2.3,1.1,5.1,0.5,7.3-1.2c0.8-0.6,0.7-1.8-0.2-2.3
				C564.4,1535,561.5,1532.1,556.9,1532.1z M654.6,1585.1c-0.5-2.6-2.8-3.7-4-5.5c-2.7-4-2.7-3.9-7-2c-1.6,0.7-2.2,1.7-2,3.2
				c0.2,1.7,1.1,2.8,2.9,3C647.9,1584.2,651.2,1584.6,654.6,1585.1z M700,1642.3c-0.6-0.9,1-3.2-0.9-2.9c-1.6,0.2-3.4,0.4-5,0.8
				c-1.5,0.4-2.3,1.4-0.8,2.8c1.9,1.6,1.8,3.3,0.6,5.2c-0.3,0.5-0.8,1-0.7,1.4c0.1,1.3,0.4,2.9,1.8,3.1c1.8,0.2,0.6-1.3,0.8-2
				c0.2-0.5,0.5-1.1,1-1.2C700.2,1648.2,699.9,1645.2,700,1642.3z M670.7,1640.4C670.6,1640.4,670.6,1640.4,670.7,1640.4
				c-0.1-1.3-0.1-2.6,0-3.9c0-1.3,0-2.6-1.8-2.4c-1.5,0.2-3.6-0.3-3.8,2.1c-0.1,2.2-0.2,4.4,0.2,6.5c0.4,2.2,2.4,3.4,4.3,3.3
				c2.3,0,0.9-2.2,1.1-3.3C670.8,1642,670.7,1641.2,670.7,1640.4z M627.3,1625.5c6.2,5.2,12.7,9.9,19.9,13.6
				C641.3,1633.6,634.7,1628.9,627.3,1625.5z M609.8,1607.6c0.6,0,1.4,0.3,1.5-0.6c0.1-0.9-0.7-1-1.3-1.1c-5-1-10.2-0.1-15.2-1.5
				c-0.3-0.1-0.9,0-0.9,0.6c0,0.5,0.4,0.8,0.8,0.8C599.7,1606.4,604.6,1607.8,609.8,1607.6z M730.9,1401.4c0-0.2,0-0.4,0-0.6
				c-3.2-0.3-6.4-0.5-9.7-0.8c-0.9-0.1-2.1-0.5-1.8,1.3c0.2,1.3,0.6,2.4,2.2,2.1C724.8,1402.8,727.8,1402.1,730.9,1401.4z
				 M906.2,1607c-1.2-3.1-3.9-4.7-6.1-6.7c-0.7-0.6-1.8-0.9-1.6-2.2c0.1-1-0.3-1.6-1.3-1.1c-0.9,0.5-2.2,1.3-1.2,2.5
				C898.7,1602.8,902,1605.4,906.2,1607z M852,1620c-0.3-0.8-0.5-1.4-0.7-1.8c-0.5-1,0-3.1-2-2.7c-2.1,0.5-0.9,2.4-0.9,3.6
				c-0.1,1.7,1.2,1.8,2.5,1.7c0.6-0.1,1.1-1.2,1.8,0.1c0.5,1,1.6,1.4,2.4,0.4c0.6-0.7,0.2-1.7-0.5-2.3
				C853.1,1617.7,852.7,1619.2,852,1620z M753.4,1511.4c-3.2-1.5-7.8,0.8-9.7-3.8c-1.1,2.3-0.3,3.6,2.4,4
				C748.5,1511.8,751,1512.1,753.4,1511.4z M706.5,1621.8c-0.8,0.2-2.3-0.6-2.2,1c0.1,1,1.3,1.8,2.2,1.3c1.2-0.6,3.6,0.5,3.6-1.1
				C710.1,1621.4,707.8,1622.2,706.5,1621.8z M857.6,1620.8c0.3-1.8,0.9-3.4-0.7-4.6c-0.4-0.3-1.3-0.3-1.4,0.3
				C855.2,1618.2,856.1,1619.5,857.6,1620.8z M621.6,1603.7c-2.5-0.8-4.9-1.6-7.5-0.8C616.5,1604.2,619,1604.6,621.6,1603.7z
				 M906.1,1647.8c-1.8-1.3-3.5-0.8-5.3-0.8c-0.4,0-0.8,0.3-0.8,0.8c0,0.5,0.5,0.8,0.9,0.7C902.7,1648.4,904.4,1648,906.1,1647.8z
				 M698.8,1580.5c0.3,0.7,0.6,1.5,1.7,1.5c0.9,0,1-0.7,1-1.4c0-1-0.5-1.7-1.6-1.6C699.1,1579.1,698.8,1579.6,698.8,1580.5z
				 M862.6,1638c0.9-0.8,1.7-1.6,2.5-2.4C862.5,1634.5,862.2,1635.9,862.6,1638z M753.6,1615.4c1,0.1,1.8,0,2-1.2
				c0.2-1.1-0.6-1-1.1-0.9c-0.9,0.2-2.1,0.1-2.3,1.3C752.1,1615.5,753.1,1615.3,753.6,1615.4z M609.6,1602.8c-0.1-1-0.8-1.1-1.4-1.1
				c-0.7-0.1-1.4,0.2-1.3,1c0,0.7,0.7,1,1.4,1C608.9,1603.7,609.5,1603.5,609.6,1602.8z M650.4,1640.7c-0.8-0.6-1-1.5-1.9-1.4
				c-0.1,0-0.4,0.6-0.3,0.8C648.6,1640.7,649.2,1641.2,650.4,1640.7z M654,1643.1c-0.3,0-0.6,0.2-0.5,0.6c0.2,0.5,0.5,1,1.1,0.9
				c0.2,0,0.5-0.4,0.5-0.6C655,1643.4,654.6,1643.1,654,1643.1z M652,1642.8c0.1-0.1,0.5-0.3,0.5-0.5c0-0.5-0.4-0.7-0.9-0.7
				c-0.2,0-0.6,0.3-0.6,0.5C651,1642.7,651.4,1642.8,652,1642.8z M590.4,1448.7c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.1-0.3,0.2,0,0.4
				c0.1,0,0.2,0,0.3-0.1C590.4,1448.9,590.4,1448.8,590.4,1448.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M420.7,1602.1
				c-1.8-0.5-3.5-1.5-5.1-1.7c-4.7-0.6-9.5-0.4-14,1.5c-1.4,0.2-2.9,0.1-3.9,1.3c-3.8,0.3-7,2.1-10.1,3.9c-0.2-0.5-0.2-1.4-0.8-1.2
				c-3.3,1-6.9,0.4-10,2.3c-2.3,1.4-4.6,2.8-6.8,4.4c-1.5,1.1-3.5,1.9-3.5,4.2c-2.7,1.4-5.5,2.7-8,4.3c-2.7,1.7-2.8,3.1-0.5,5.3
				c2.5,2.4,5,4.8,7.8,6.9c2.4,1.8,3.8,4.8,7,5.6l0-0.1c0.2,0.8,0.6,1.7-0.7,2.1c-5.1-4.4-10.1-8.9-15.2-13.3
				c-3.6-3.1-5.8-2.8-8.5,1.1c-0.4,0.6-0.8,1.3-1.1,2c-2.8,0.2-4.9,1.5-6.1,4.1c-3-0.4-4.1,2-5.7,3.8l0,0c-1.8-0.2-3.7-0.4-5.5-0.5
				c-0.6-0.8-1.4-0.5-2.2-0.3c-1.6,0.1-3-0.4-4.5-0.9c5.2-3.1,10.5-5.9,15.2-9.7c3.5-2.8,7.3-5.3,11.1-8.1c-4.8-2.2-4.8-2.2-8.7,0.5
				c-4.7,3.3-9.6,6.4-14.2,9.9c-2.5,1.8-4.8,4-5.5,7.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.2-0.5-0.5-0.7-0.9-0.5c-0.2,0.1-0.2,0.5-0.3,0.7
				c-2.3-1.3-4.7-0.5-7.1-0.6c0.6-3.3,3.8-4,6-5.7c3.9-3.2,8.1-6.1,11.7-9.6c2-1.1,4.3-1.7,5.2-4.2l0,0.1c2.7,0.5,4-1.1,5-3.2
				c2-0.9,3.7-2.2,6-2.7c5.1-1.1,6-2.8,6.1-9.8c2.1-1.6,4.5-1.8,7.1-1.6c-1.6-2.6-3.7-2.6-7.8-0.4c-1.6-3.4-0.2-7-0.4-10.5
				c-0.4-6.8-0.3-13.6-0.3-20.4c0.5,0.1,1,0.3,1.5,0.3c1-0.1,2.7-2.5,2.5,1c0,0.8,1.7,0.6,2.6,0.1c0.9-0.5,1.8-1.3,1.5-2.3
				c-0.4-1.4-1.5-0.6-2.4-0.3c-0.5,0.2-1.1,0.8-1.6-0.2c-1-2-2.6-1.8-4.3-1c0-10-0.1-20-0.1-30c0-4-1.9-7.3-3.3-10.7
				c-0.1-0.2-0.5-0.4-0.8-0.4c-3.2-0.4-5.2-3.2-8.3-3.9c-3.4-0.8-3.8-3.9-5-6.4c-0.9-1.8-0.4-4.5-3.4-5c-0.6-0.1-0.7-0.8-0.5-1.4
				c0.3-0.8,0.8-1,1.5-0.5c0.6,0.4,1.5,1.1,1.9,0.1c0.3-0.9-0.7-1.8-1.5-1.7c-3.3,0.5-3.2-0.8-1.8-3c0.1-0.1,0.1-0.3,0.1-0.3
				c-2.1-1-2.8-2-1.5-4.5c0.3-0.5-1.8-3.9-3.7-5.3c-1.2-0.9-1.5-1.6,0.1-2.3c0.9-0.4,1-1.3,0.8-1.9c-1.2-3.2-0.7-7.3-4.7-9
				c-1.4-0.6-0.8-1.2-0.3-2c0.6-1,2.2-1.2,2.4-3c-1.6,0.8-3,0.6-3.4-1c-0.6-2.2-1.2-4.4,0.3-6.5c0.3-0.5,1.3-0.6,0.7-1.4
				c-2.6-3.7-2.2-7.1,0.6-10.5c1.1-1.4,0.3-2.6-1.4-3.2c-2.3-0.8-1.4-1.7-0.1-2.6c2.1-1.4,3.8-2.9,3-6c-0.3-1.2,1.2-2.1,2.5-2.6
				c4-1.5,4.7-2.6,3.8-6.4c-0.4-1.8,2.2-4.7,4.1-4.3c2.1,0.4,3.1-0.3,3.7-2.1c0.4-1.3,1.5-2,2.7-2.1c5.1-0.2,7.2-3.9,9.2-7.7
				c1.9-3.5,0.7-7.4,1.2-11.1c0.3-2.3-0.1-4.8-0.2-7.2c0-0.8,0.1-1.6,1-1.8c0.9-0.2,1.7-0.2,2.4,0.6c1.4,1.5,2.4,3.1,2,5.3
				c-0.6,3.7,0.6,7,2.7,9.9c0.7,1,0.7,1.6-0.6,1.7c-1,0.1-2,0.2-2.1,1.6c-0.9,0.8-1.6,1.8-2.7,2.3c-2.9,1.3-0.5,2.7,0.1,3.2
				c1.4,1,1.4-1.3,2.5-1.5c6.9-2.1,13.9-3.6,20.7-5.8c4.6-1.5,9.1-3.3,13.8-4.7c1.9-0.6,2.3-1.2,0.6-2.8c-4.9-4.4-8-10-9.7-16.3
				c-0.4-1.6-1.5-3.6,1.1-4.7c2.9-1.2,4.1,0.9,5.2,2.7c2.2,3.8,5.5,4.6,9.3,1.9c1.4-1,3.2-1.3,4.8-2.3c2.6-1.5,2.7-4.4,4.7-6.2
				c2.1-1.9,4.4-2.7,6.7-2.7c6.5-0.1,13-0.2,19.5-1.3c2.4-0.4,2.7,0.6,2,2.5c-0.1,0.4-0.4,0.8-0.4,1.2c-0.2,1.1-0.7,2.3,0.1,3.2
				c1,1.1,2.3,0.1,3-0.3c3.1-1.9,6.1-0.6,9.4-0.1c-1.8,2.5-0.7,5.3-0.6,7.9c0.3,5.4,0.4,10.8,0,16.3c-0.2,2.7,0.7,5.5,0.2,8.1
				c-1.1,6.1,1.9,11.9,0.8,18c-0.9,4.9,0.2,9.8-0.3,14.7c-0.4,4.4,0.4,9.2,0.3,13.7c-0.1,4.3,0.7,8.6,0.4,13
				c-0.3,6.3-0.8,12.8,0.4,19.2c0.7,3.6-1,7.2-0.1,10.9c0.4,1.6,0.8,2.6,2.5,2.8c9.4,1.2,18.8,2.5,28.2,3.8
				c4.4,0.6,8.8,1.3,13.3,1.5c2.7,0.1,3.3-0.6,3.3-2.8c0-4,0-8.1-0.1-12.1c-0.1-4.5-0.3-9.1-0.4-13.6c0-1.8,0.9-3,2.8-2.8
				c6.1,0.8,12.4,0.5,18.5,1.8c4.3,0.9,8.6,2.1,13.2,1.5c4.9-0.7,7.6,2.2,7.5,7.1c0,2.4,0.2,4.8,0,7.2c-0.3,3,1.2,5,3.4,6.4
				c1.2,0.8,1.3,1.5,1.4,2.6c0,1.4-0.9,1.5-2,1.6c-3,0.4-2.9,2-2.8,4.7c0.3,7.6,0.2,15.2-0.2,22.8c-0.5,9.7-1.4,19.4-0.3,29.2
				c0.3,2.1,0,4.4,0,6.5c0,0.9-0.2,1.8,0.6,2.5c2,1.8,2,3.5-0.1,5.2c-0.5,0.4-0.4,0.9-0.2,1.5c1.3,3.2-0.1,4.5-3.3,4.8
				c-5.3,0.6-9.9,3-14.3,5.9c-0.6,0.1-1.3,0.3-1.9,0.4c-0.3-0.4-0.5-0.1-0.7,0.1c-1.4,1-3.4,1.2-4,3.3c-1.1,0-1.6,1-2.3,1.6
				c-3.1,2.8-7,3.7-10.8,4.8c-0.5,0.2-0.6,0.5-0.7,0.9c-2.1-1.4-4.1-2.3-6.9-2.2c-3.4,0.2-6.9,0.3-10.2-1.2c-0.7,0-1.3,0-2-0.1
				c-3.9,0.4-7.6-1-11.4-1.3c-1-0.1-1.9,0-2.7,0.6c-1,0.7-2.2,0.6-3-0.3c-0.8-1.1-1.8-1.3-2.9-1c-1.7,0.5-0.1,1.6-0.3,2.4
				c-0.2,0.6,0.1,1.4-0.9,1.4c-0.6,0-1.4,0.1-1.9-0.2c-0.9-0.6,0.1-1,0.3-1.5c0.3-0.8,0.6-1.8-0.1-2.4c-0.7-0.7-1.7-0.4-2.3,0.3
				c-1,1.1-2.3,0.4-3.4,0.7c-1.5,0.4-3.4-0.8-4.5,1.6c-0.4,0.9-2.6,0.1-4-0.3c-1.4-2-4.6-2.1-7.3-0.4c-1.5,0.4-3,0.4-4.4-0.4
				C427.2,1600.8,423.3,1600.4,420.7,1602.1z M352.2,1616.1c-1,0.1-1.9,0.3-2.4,1.2c-0.1,0.1,0.3,0.6,0.4,0.6
				C351.6,1618,352.2,1617.3,352.2,1616.1c0.4,0,0.8,0,1-0.5c0.1-0.4-0.1-0.7-0.5-0.6C352.2,1615.2,352.2,1615.6,352.2,1616.1z
				 M400.7,1561.7c0.8,2.6,3.3,2.2,5.2,2.6c1.4,0.3,2.3-0.3,2.4-1.8c1.2-0.3,2-1,2.3-2.5c-5.5-0.7-10.7-1.4-15.9-2
				c-1-0.1-2.6-0.9-2.8,1.1c-0.2,1.6,1.4,1.2,2.2,1.7C396.3,1561.9,398.7,1560.2,400.7,1561.7z M474.6,1557.9
				c0.9-1.6-0.4-1.8-1.4-2.5c-1.6-1.1-3.4-0.8-5.1-1.1c-6.5-1.1-13.1-1.6-19,2.1c-5.5,3.5-10.6,7.6-15.9,11.4c-0.4,0.3-1,0.5-1,1.2
				c0.1,0.8,0.8,0.9,1.5,1c4.2,0.8,8.4,1.4,12.5,2.5c3.3,0.9,6.2,0,9.2-1c1.4-0.5,2.8-1.1,4.3-1.6c5.4-2,10.3-4.7,14.2-9.1
				C474.6,1559.9,475.4,1559.2,474.6,1557.9z M360.8,1523c11.8-3.1,23-5.1,33.5-9.5c1.2-0.5,2.5-1.1,3.8-1c4.8,0.2,9.1-1.6,13.5-3
				c0.6-0.2,1.7-0.1,1.5-1.7c-5.1,0.9-10.2,1.7-15.2,2.8c-9.8,2.2-19.6,4.6-29.4,7C365.5,1518.2,362.5,1519.1,360.8,1523z
				 M442.3,1428.4c0-1.4,0-2.8,0-4.2c0-2.6-1.1-3.8-3.9-3.9c-8.2-0.3-7.9-0.4-7.9,7.9c0,4.2,1.5,5,5.4,5.6
				C443.5,1434.9,442.4,1435.6,442.3,1428.4z M498.5,1530.1c-2.8,0-4.1,1.8-5.3,3.9c-2.1,3.8-1.2,8-1.6,12.1c-0.1,1.5,1,2.6,2.5,2.4
				c2.8-0.4,5.5-1.2,6.7-4.2c0.3-0.7,0.4-1.4,1.4-1.9c2.4-1,3.3-4.8,1.8-6.8c-1.6-2.1-3.4-3.7-5.5-0.3c-0.6,1-1.9,1.1-2.9,0.3
				c-1.1-0.9-0.2-1.8,0.1-2.6C496.2,1531.7,497.7,1531.2,498.5,1530.1z M400.4,1550.8c-2,0-3.9,0-5.9,0c-1.4,0-2.6,0.1-2.6,2.1
				c0.1,1.8,0.8,2.4,2.6,2.6c4.4,0.4,8.9,1.1,13.3,1.7c5.8,0.8,6.7,0.2,7.8-5.3c0.3-1.6,0-1.9-1.8-1.8
				C409.4,1550.5,404.9,1551.1,400.4,1550.8z M431,1448c0,1.1,0,2.2,0,3.2c0.1,2,0.3,3.7,2.9,1.8c1.1-0.8,2-0.1,3,0.4
				c1.9,1.1,4.4,0.8,5.3-0.8c1.5-2.5,1.3-5.7,0.8-8.6c-0.5-2.6-3.2-1.8-4.7-1.9C431.4,1441.5,430.4,1439,431,1448z M360.4,1551.7
				c1.2,0,2.2,0.8,3.5,0.6c2-0.2,4.4-0.5,4,2.9c-0.1,0.7,0.6,0.8,1.1,0.8c5.5,0.7,10.9,1.8,16.3,3.1c0.8,0.2,1.9,0.6,2.1-0.7
				c0.2-1.2-0.8-1.4-1.7-1.5c-1.3-0.2-2.6-0.3-3.9-0.5c-3.9-0.4-7.8-0.9-11.7-1.3c-0.9-0.1-2.3-0.2-2.2-1.4c0.1-1.4,1.5-0.8,2.4-0.9
				c1-0.1,2,0.1,2.9,0.2c0.8,0.1,1.8,0.1,1.8-0.8c0.1-1.5,0.5-3.1-0.2-4.5c-0.5-1-1.3-0.3-2-0.1
				C368.6,1548.5,364.4,1549.7,360.4,1551.7z M422.6,1489.9c-0.2-1.1-1.6-1.6-2.4-2.6c-0.4-0.5-1.3-0.3-1.8,0.2
				c-1.5,1.5-3.1,3-4.4,4.7c-0.9,1.2-1.4,3,0.7,3.8C416.6,1496.7,422.6,1492.2,422.6,1489.9z M382.1,1573.6
				c-3.3-2.3-6.9-3.2-10.7-3.3c-0.9,0-2.2-0.1-2.2,1.3c0,1.1,1.1,1.5,2.2,1.5C375,1573.3,378.6,1573.5,382.1,1573.6z M377,1575.6
				c2.8,1.4,5.9,1,8.8,1.6c0.6,0.1,1.3-0.2,1.3-1.1c0-0.7-0.6-1.1-1.1-1C383.1,1576.2,380,1574.1,377,1575.6z M357.9,1572.2
				c0.6,2.3,2.5,2,3.9,2.5c0.7,0.3,1.5,0.1,1.6-1c0-0.9-0.2-1.5-1.2-1.5C360.8,1572.2,359.4,1572.2,357.9,1572.2z M514.9,1586.4
				c0-0.9-0.3-1.7-1.4-1.7c-0.9,0-1,0.6-1,1.3c0,0.9,0.1,1.8,1.3,1.8C514.9,1587.9,515,1587.2,514.9,1586.4z M362.4,1570.2
				c0.6-0.1,1.6,0,1.6-1.1c-0.1-1.2-1.1-1.4-2.2-1.4c-0.9,0-1.6,0.4-1.5,1.3C360.4,1570.1,361.3,1570.2,362.4,1570.2z M495.3,1561.8
				c-0.9-0.1-1.2,0.4-1.3,1.1c-0.2,1.1,0.4,1.8,1.5,2.1c0.7,0.2,1.3,0,1.4-0.8C497,1562.9,496.3,1562.2,495.3,1561.8z M396.2,1573.5
				c0.9,0,1.5-0.3,1.5-1.3c0-0.8-0.4-1.2-1.2-1.2c-0.8,0.1-1.4,0.4-1.5,1.4C394.9,1573.4,395.5,1573.5,396.2,1573.5z M354.6,1573.2
				c-1.2-0.8-1.9-1.5-2.9-0.5C352.4,1573.8,353.4,1573.4,354.6,1573.2z M415.6,1508.1c0.3-0.2,0.4-0.3,0.6-0.4
				c-0.2-0.2-0.4-0.4-0.7-0.4c-0.1,0-0.4,0.2-0.5,0.3C415.2,1507.8,415.4,1507.9,415.6,1508.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M830.7,1898.8
				c0.1,0.2,0.4,0.3,0.4,0.5c-0.2,4.2,3.9,6.2,4.9,9.9c1,3.8,3.1,7.1,4.4,10.8c1.5,4.3,3.3,8.4,5.3,12.5c2.5,5.2,5.8,10.3,5.7,16.3
				c0,4,0.2,7.9,0.6,11.9c0.1,0.8-0.2,1.9-1.5,2.2c-1.3,0.3-1.6,1.4-1.4,2.8c2.5-1.1,3.6-0.4,3,2.3c-0.8,3,0.3,6.2-1.5,9.2
				c-0.7,1.2-1.1,3.8,1.3,5.1c0.6,0.3,1.2,1.8,0.9,2.3c-1.9,3.3,0.1,6.2,1.2,8.9c1.9,4.7,1,9.3,1.3,14c0.2,3.3-0.4,6.6,0,9.9
				c0.4,3-1.1,6.2-1.1,9.3c0,0.6-0.8,0.6-1.3,0.7c-5.1,0.6-10,1.5-15.3,1.2c-2.6-0.1-5.8,0.7-8.7,1.2c-3.3,0.5-6.6,0.1-9.8,0.2
				c-0.5,0-1.1,0-1.5-0.3c-0.3-0.2-0.4-0.8-0.4-1.1c0.4-3.4-3.3-6.4-1.2-10c0.6-1.1,1.8-0.7,2.7-0.5c2.5,0.5,4.9,1,7.3,1.7
				c2.1,0.6,3.4-0.1,3.9-2.3c0.5-2.2,0.1-4-2.3-4.6c-2.2-0.6-4.5-1-6.7-1.5c-4.1-0.8-5.2-2.1-5.1-6.1c0.1-4.3,0-4.5-4.1-4.4
				c-2.6,0.1-4.9-0.4-7.2-2.2c1-0.4,1.8-1,1.9-2.1c0.1-0.5,0.4-1.1-0.1-1.5c-0.5-0.4-1.1-0.3-1.5,0c-2.4,1.9-3.3,0.7-3.5-1.7
				c-0.1-1-0.4-1.8-1.5-1.7c-1.1,0.1-1,1-1,1.7c-0.1,1.1-0.7,2.1-0.3,3.5c0.5,1.6-0.8,3-2.3,3.4c-1.6,0.4-2.9,0.9-3.7,2.3
				c-0.3,0.5-0.7,0.9-1.3,0.5c-0.5-0.4-0.4-1-0.1-1.5c0.9-1.2,2.1-1.8,3.4-2.5c1.6-1,1.8-2.4,0.1-3.5c-3.2-2.1-4.1-5.4-4.6-8.8
				c-0.1-0.7,0.5-1.6-0.3-2.1c-2.6-1.5-4.4-3.6-6.2-6.1c-1.6-2.2-1.2-2.2,0.5-2.9c0.5-0.2,1-0.5,0.8-1.2c-1.2-3.2-4-1.2-6-1.6
				c-1.6-0.3-1.7,1.4-1.8,2.5c-0.1,1.2,0.9,1.5,1.8,1.2c2.1-0.7,2.3,0.5,2.6,2.1c0.4,2,1.4,3.9,2.9,5.3c0.6,0.6,1,1.2,0.3,1.9
				c-0.7,0.7-1.5,0.7-2.3,0c-2.7-2.3-5.9-1.5-8.8-1.1c-2.3,0.3-4.7-0.4-5.5-2.6c-0.3-0.9-0.2-1.5,0.8-1.8c1.3-0.3,2.6-0.8,4-0.8
				c0.9,0,1.6,0.1,1.5-1c0-0.9-0.7-1.3-1.6-1.2c-0.3,0-0.6,0.2-1,0.3c-4.1,1-4.1,1-3.2-3.1c0.6,0,1.1,0.1,1.3,0.7
				c0.3,0.6,0.6,1.3,1.4,0.6c0.3-0.3,0.4-1,0.4-1.5c0-0.8-0.6-1.1-1.3-1.1c-0.9,0-1.3,0.7-1.8,1.3c-1,0-2-0.1-2.9,0.1
				c-1.5,0.3-2.1,1.3-0.9,2.6c0.8,0.8,2,1.5,0.8,2.6c-0.9,0.8-1.6,2.4-3.1,1.9c-1.2-0.3-1.6-0.6-2.5,0.7c-1.1,1.6-0.9-1.2-2.2-0.9
				c-0.9,0.8,0.1,2.6-1,3.8c-1.2-0.1-0.8-2-2.2-2.1c-1.1,1.5,0.2,3.9-1.8,5.4c-0.5-1.8-1-3.5-1.4-5.1c-0.2,0-0.4,0.1-0.6,0.1
				c0,1.6,0,3.2,0,4.8c0,0.9,0.3,1.8,1.4,1.9c0.7,0.1,1.9-0.7,1.8,0.9c-0.1,1.3,0.2,3-1.3,3.4c-2,0.5-1.9,1.7-1.9,3.1
				c-0.2,10.1,0.2,20.2,0.4,30.4c0,0.7,0.1,1.3,0,2c0,0.7-0.2,1.3-1,1.4c-1,0.1-1.3-0.6-1.4-1.5c-0.2-2.7-0.6-5.4-0.5-8.1
				c0.4-6.2,0.4-12.4,0-18.6c-0.7-10,0.6-20-0.7-30c-0.1-1.1-0.1-1.1-1-1.1c-1.4,0-2.8,0-4.3,0c-0.7,0-1.7-0.2-1.8,0.9
				c-0.1,1.1,0.3,2.6,1.1,2.8c1.2,0.3,2.9,0,1.6,1.7c-1,1.3-2.6,2.9-4.7,1.6c-1.3-0.9-2.1-2.1-1.3-3.9c1.1-2.6-0.1-3.5-2.6-3
				c-3.2,0.6-6.5-0.5-9.8,0.5c-1.6,0.4-2.1-0.6-1.6-2.1c11,0.1,21.9-1.5,32.9,0c0.2,1.4,0.6,2.8,0.4,4.1c-0.2,1.7,0.3,2.3,1.8,2.8
				c3.9,1.3,1.1-3.7,3.7-3.7c0.4,0,0.6-1.1,0.2-1.4c-0.4-0.4-1.2-0.3-1.8-0.5c-1.2-0.4-2.7-0.3-2.8-2.1c0.9-0.5,1.9-0.5,2.9-0.5
				c13.7,0,27.3,0,41,0.1c4.2,0,8.5-0.8,12.7,0.2c0.8,0.2,0.9-0.3,1.1-0.9c0.6-2.4-0.8-2.5-2.5-2.4c-7.7,0.2-15.5,0.3-23.2,0.4
				c-0.8-1.8,2-1.5,1.9-3.3c-0.2-2.6,0.4-5.3,0.6-7.9c0.1-1.6-1.1-2.1-2.3-2.2c-1.8-0.2-1,1.1-1,2c0.1,2.5-0.3,4.9,0.5,7.4
				c0.7,2.2-0.9,3-2.6,2c-2.6-1.5-3.5-0.3-4.1,1.9c-7.8,0.7-15.7,0.3-23.5,0c-0.4-2.2-2.9-3.9-1.6-6.8c0.2-0.5-1.6-3.8-4.4-3.1
				c-3.9,0.3-7.9,0.6-11.8,0.9c-5.1-1-10.1-2.1-15.2-2.8c-2.3-0.3-3-1.4-3-3.4c0-3.8,0-7.6-0.1-11.4c0-2.2-0.2-4.5-0.3-6.7
				c-0.1-1.9,0.6-3.4,2.3-4.3c0.5-0.3,1.8-0.3,1-1.5c-0.4-0.6-1.1-0.6-1.7,0c-0.5,0.5-1.1,1-1.5,0.1c-0.2-0.4-0.2-1.1-0.1-1.6
				c0.2-0.7,0.8-0.6,1.3-0.4c3.5,0.8,6.7-0.3,9.8-1.5c2.2-0.8,3.9-1.1,5.7,0.6c2.4,2.3,3.2-1.2,5-1.5c1-0.2,1.4-1.6,0.8-3
				c-1.8-4.4-1.7-4.4-8-4.4c-4.3,0-8.5-0.1-12.8-0.1c-1.3,0-2.4,0-2.4-1.8c1.4,0,2.9-0.2,4.3,0c5.7,0.6,11.3,0.6,16.9-0.3
				c0.4,0.2,0.6,0.1,0.5-0.4c0-0.5,0-1.1,0-1.6c0.3-1.4,0.9-2.8-1.3-3.1c-6.5,0.1-13.1,0.2-19.6,0.3c-2.1-1.6-0.9-3.9-1.2-5.8
				c-0.2-1.4-0.2-2.8-0.2-4.2c13.6-0.6,27.1-0.9,40.7-0.8c9.9,0.1,19.7,0,29.6,0c1.3,0,2.6,0,3.5-1.2c1-1.5-0.7-2-1.1-3
				c-0.8-2.3-3.2-3-5-4.7c2.1-1.2,4.3-0.6,6.2-1.2c1-0.3,2.8,0.5,2.7-1.4c-0.1-1.8-1.6-2.3-3.1-2.2c-2.9,0.4-5.8,1.1-8.7,1.3
				c-7.2,0.6-14,3.6-21.3,3.7c-14.4,0.1-28.9,0.3-43.3,0.5c-0.9-1.8-0.8-3.4,0.6-4.9c3.3,0.8,6.3,0.3,9.7-0.3
				c11.5-2.1,23.2-2.7,35-2.8c0.7,0.1,1.4,0.4,2-0.4c0.7,0,1.3,0,2,0c0.7,0.7,1.4,0.6,2-0.1c0.6,0,1.3,0.1,1.9,0.1
				c0.7,0.6,1.4,0.7,2-0.1c0.9,0,1.7,0,2.6,0c0.5,0.7,0.9,0.7,1.4,0c2.6,0,5.2-0.1,7.8-0.1c0.5,0.7,0.9,0.7,1.4,0c0.4,0,0.8,0,1.2,0
				c1.6,0.8,3.1,0.9,4.7,0c0.4,0,0.8,0,1.2,0c2.9,1,5.8,0.5,8.8,0.2c5,0.1,10,0.3,15,0.4c0.7,0.7,1.3,0.8,2,0
				c0.4,0.1,0.9,0.1,1.3,0.2c-0.4,0.6,2.4,1.8-0.4,2c-2,0.2-0.7,2.4-1.7,3.3c-5.7,2-11.5,2.3-17.5,2.4c-5.3,0.1-5.3,0.1-4.8,5.4
				c-0.6,0.3-0.6,0.6-0.1,1c0,0.3,0,0.7,0.1,1c-0.4-0.1-1-0.1-0.9,0.4c0.1,0.3,0.7,0.4,1.1,0.1c6.3,0.2,12.6,0.3,18.9,0
				c0.7,0.1,1.4,0.1,2.2,0.2l0,0c0.9,2.2,2.5,1.4,4,0.9C826.4,1898.9,828.5,1898.9,830.7,1898.8z M751.8,1914.3
				c8.9,1.1,17.8,1.5,26.7,1.6c10.6,0.1,21.1,0.7,31.7,1.2c1.9,0.1,2.9-0.3,2.9-2.4c0-2.2-0.8-2.9-3-2.6c-3.7,0.6-7.1-0.6-9.8-2.8
				c-1.6-1.3-2.9-1.6-4.7-1.6c-7.4,0-14.6,1.2-21.8,2.3c-7.3,1.2-14.5,2.5-21.8,3.8c-2.4-0.6-4.1,0.9-4.6,3.6
				c-0.4,2.5,1.3,5.1-0.7,7.4c-0.1,0.2,0.1,0.9,0.4,1.1c0.5,0.4,1.1,0.1,1.4-0.3c0.7-1,1.5-1.6,2.9-1.5c5.4,0.1,10.7-0.1,16.1,0
				c6.8,0,13.5,0.4,20.3,0.4c6.8,0,13.7,0.2,20.5,0.8c2.8,0.2,5-1,5.1-3.5c0.1-2.8-2.1-3.2-4.3-3.4c-0.9-0.1-1.7,0-2.6,0
				c-5.2,0-10.5,0.6-15.6-0.2c-7.8-1.2-15.6-0.1-23.3-0.7c-5.8-0.4-11.7,0-17.9-1.6C750.6,1915.2,751.2,1914.8,751.8,1914.3z
				 M834.3,1994.4c0.1-2.5-0.9-3.6-3.4-3.9c-1.2-0.1-2.4,0-3.5-0.3c-3.6-1.2-7-0.5-9.9,1.6c-1.5,1.1-2.6,1.1-4.1,0.4
				c-1.6-0.7-3.2-1.4-5.1-0.8c-1,0.3-2,0.7-1.9,1.9c0.2,1.2,1.3,0.7,2.1,0.9c1,0.2,2.3-0.7,2.8,0.8c0.7,2.2,2.4,2.2,4.2,2.2
				c4.1,0.1,8-0.6,11.8-1.8c1.5-0.5,3.4-1.5,4.6,0.6c0.3,0.5,0.9,0.6,1.4,0.3C834.3,1996,834.2,1995.1,834.3,1994.4z M808.9,1965.4
				c1.7-1.6,5.2-0.4,5.8-3.4c0.4-2.1,0.3-4.5-0.4-6.8c-0.8-2.4-1.8-2.8-4.2-2.4c-3.1,0.6-5.9,2.9-9.3,1.6c-1.7-0.6-2.4,0.3-2.6,1.9
				c-0.4,2.6,0.4,4.4,2.9,5.3c1.8,0.6,1.5-1.4,2.2-2.1c0.9-1-0.4-5.4,3.3-2.1c0,0,0.4-0.3,0.6-0.6c1.3-1.4,1.7-0.4,1.7,0.7
				c0.1,1.5-0.6,4,0,4.3C811.7,1963.3,808.8,1964,808.9,1965.4z M769.1,1959.9c-0.6-1.5-0.9-3.3-3.2-3.5c-1.9-0.1-3.8-0.5-5.8-0.7
				c-0.9-0.1-1.6-0.2-1-1.3c0.4-0.8,1.5-2.1,0.2-2.4c-1.2-0.3-1.2,1.4-1.3,2.4c-0.1,0.7-0.3,1.2-0.9,1.6c-0.7,0.5-0.2,1.2,0,1.7
				c0.3,0.7,0.8,0.4,1.3,0.2c0.8-0.3,1.7-0.2,2.5-0.4c2.3-0.8,4.6,0.3,5.2,2.5c0.3,1,0.8,1.6,1.8,1.6
				C768.9,1961.6,769,1960.8,769.1,1959.9z M803.7,1908.1c2.2,2.3,4.8,2,7.4,1.9c0.6,0,1.5,0.2,1.6-0.7c0-0.9-0.8-1.2-1.6-1.2
				C808.6,1908.1,806.2,1908.1,803.7,1908.1z M752,1992l0.2,0.1l0-0.2L752,1992z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M707.6,1759.4
				c-1.5-0.5-1.2-2.9-3.6-2.8c-0.6,0,1.2-2.1,1.6-3.4c0.4-0.1,0.8-0.2,1.2-0.3c0.9,0.7,1.9,1.5,2.6-0.2c1.1-1.8,0.9-4,1.7-5.8
				c2.3-5.7,2.6-11.7,3.5-17.6c0.3-3.6,0.5-7.2,0.8-10.7c0.2-2-0.6-3-2.6-2.6c-2.4,0.6-5.9-1.3-6.8,3.1c-0.2,1,0,1.4,0.4,2.2
				c0.6,1.2-0.5,1.7-1.4,2.1c-1.2,0.6-1.2-0.2-1.4-1c-0.1-0.5-0.4-1.3-0.7-1.4c-3-0.8-5-3.7-7.9-4.1c-2.9-0.4-6,0.9-9,0.4
				c-3.3-0.5-6.5-0.9-9.5-2.6c3.6,0.2,7.2,0.2,10.8,0.5c2.1,0.2,3.8-0.1,5-2c0.7,0.3,1.4,0.3,2.1-0.1c4.3,0.9,8.4-1.3,12.6-1.2
				c0.5,0,1.4-0.6,1.2-1.3c-0.1-0.5-0.9-0.8-1.4-1.2c0.8-1,1.4-2.8,2.8-0.3c0.9,1.7,2.7,1.1,4.1,0.9c1.7-0.2,1.7-1.7,1.8-3
				c0.5-8.7-1.1-17.1-2.3-25.7c0-0.2,0-0.5-0.2-0.6c-4.7-4.1-0.3-9-1.2-13.5c-0.4-1.9,1.8-1.8,3.2-1.7c4.5,0.4,9.1,0.9,13.7,1.3
				c-0.1-1.1-1.5-1-1.5-1.9c4.1-1.1,8.1,0.3,12,0.7c8.5,0.8,17.1,0.6,25.5,2.5c-0.4,1.7,0.6,1.9,2,2.1c6,0.7,12,1.3,17.9,2.3
				c8.5,1.5,17.2,1.4,25.6,3.2c0.1,0.1,0.3,0.2,0.5,0.2c1,1.7,2.3,0.5,3.4,0.3c2.5-0.4,3.9,0.2,4.2,3c0.4,3.5,1.2,7,1.8,10.6
				c-1.9,2.4,0,4.7,0.2,7.1c0.1,1.9,1.4,2.4,3,1.9c5-1.5,10.1-1.5,15.2-2.3c2.3-0.4,4.4-2.2,6.9-1.4c2.6,1.4,5.4,0.9,7.8,0
				c2.7-1,5.2-0.5,7.9,0.1c-4.5,0.7-9,1.4-13.4,2.1c-7.8,1.2-15.7,2.5-23.5,3.6c-2,0.3-2.1,1.2-2.1,2.9c0.1,4,1.8,7.8,1.6,11.9
				c-1,1-0.6,2.3-0.5,3.3c0.3,3.1,0.9,6.2,1.3,9.2c-2.6,1.2-2.9,3.9-4.2,6c-0.3,0.4-0.1,0.9,0.3,1.1c0.2,0.1,0.7,0,0.9-0.2
				c3.2-3.4,2.6,0.7,2.4,1.4c-1.7,5.9-1.5,12.3-4.1,18c-1,2.1-1.7,4.2-0.7,6.5c-0.3,1.5-1.9,2.8-0.6,4.5c-0.1,0.2-0.1,0.4-0.1,0.6
				c-3.4,3.1-4.9,7.2-4.9,11.7c0,2.7,0.8,5.3,0.3,8c-21.5-0.7-43,0-64.5-1.6c-6-1.3-12.1-1.5-18.2-1.3c-0.7,0.1-1.5,0.2-2.2,0.3
				c-1-0.1-2-0.2-3-0.3c-5.1-0.3-10.2-0.7-15.3-0.9c-1.3-0.1-2.4-0.1-2.5-1.8c0-0.8,0.2-2-0.8-2.2c-1.3-0.2-1.4,1.2-1.7,2
				c-4.3,10.6-8.6,21.2-12.8,31.8c-2.3,5.7-3,6.4-8.7,5.5c-2.1-0.4-2.9-0.1-3.4,1.8c-0.3,1.2-1.4,1.6-2.5,1.7
				c2.8-6.9,6.3-13.5,7.2-21.1c2.8-3.9,4.2-8.4,5.9-12.8c3.5-5,5.2-10.7,6.4-16.6c0.2-0.2,0.6-0.4,0.7-0.6c0.9-3.9,3.6-5.3,7.3-5.6
				C709.5,1764,707.5,1761.2,707.6,1759.4c0.3,0,0.5-0.1,0.2-0.4C707.7,1759.1,707.7,1759.3,707.6,1759.4z M750.2,1753.3
				c-0.3-3.3,2.8-4.3,4.6-6.1c2-2,5.4-3,6.2-5.7c1.6-5.8,1.8-11.8-0.3-17.6c-0.6-1.6-2-2.2-3.7-2.1c-1.6,0.1-3.2,0.5-3.8,2.1
				c-1.2,3-1.9,6.1-0.7,9.3c0.8-1.8,0.8-3.6,0.9-5.5c0.1-2.2,1.2-3.8,3.5-4c2.5-0.2,2.8,1.8,3.4,3.6c1.2,3.4,0.1,6.8,0.2,10.3
				c0,1.8-0.7,3-1.9,4.3c-2.7,2.8-6.2,4.5-8.5,7.6c-1.2,1.6-2.7,3.3-0.9,5.5c2.5-1.4,4.9,1.7,7.3,0.3
				C754.4,1754.3,752.8,1752.2,750.2,1753.3z M803.8,1756.2c-2.6,0.2-5.1,1-5.8-0.8c-0.6-1.7,1.4-3.2,2.8-4.4
				c0.2-0.1,0.3-0.3,0.5-0.4c1.5-0.9,2.5-3.6,4.3-2.7c1.2,0.7-0.5,2.8-0.9,4.4c-0.7,2.9,0.8,6.4,3.2,7.2c3.5,1.2,5.5,0.2,7.3-3.7
				c0.3-0.7,0.7-1.4,1.1-2c2.1-3,2-6.1,0.3-9.2c-0.4-0.8-1.1-1.5-0.3-2.4c3.8-4.3,2.5-8.3-0.4-12.3c-1.9-2.7-4.4-2.7-6,0.3
				c-0.3,0.6-0.3,1.5-1.3,1.8c-0.6-1-1.2-2-1.8-3c-0.8-1.3-2-2-3.6-1.8c-1.7,0.2-1.9,1.6-2.3,2.8c-0.7,2.4-0.1,4.8,0.4,7.2
				c0.7-1.9,0.7-3.8,0.7-5.7c0-1.2,0.4-2.2,1.7-2.4c1.2-0.2,2.1,0.3,2.6,1.5c0.8,1.8,0.9,3.8,0.9,5.8c0,1.5-0.9,3.2,0.4,4.8
				c0.4-0.7,0.5-2,1.4-1.5c1.1,0.6,1.8,2.2,1.7,3.2c-0.1,1.4-1.8,2.3-3,2.7c-1.5,0.4-0.2-1.6-0.9-1.9c-4.5,4.2-9.6,7.6-12.3,13.3
				c-0.4-3.2,0.9-5.9,1.6-8.7c0.5-2,3,0.3,4-1.8c-4.9,0.3-3.3-2.9-3-5.4c0.6-4.1,0.3-8.3,0.1-12.4c-0.1-1.2-0.1-2.2-1.6-2.4
				c-1.4-0.1-2.8,0-3.2,1.6c-0.4,1.3-0.7,2.8-0.8,4.1c-0.7,6.8-1.2,13.7-2.9,20.4c-0.5,2.2-0.7,4.5-4.7,3.4c3.2,2.4,4.7,1.7,5.6-1.9
				c1.7-6.8,2.8-13.6,2.8-20.6c0-1.3,0.4-2.6,0.7-3.8c0.2-0.8,0.8-1.3,1.7-1.3c1,0,1.2,0.8,1.2,1.6c0.1,2.1,0.2,4.1,0.2,6.2
				c0,6.6-1.8,12.9-2.8,19.4c-0.1,0.7-0.8,1.4,0.1,2.1C794.6,1758.1,801.4,1757.7,803.8,1756.2z M728.6,1750.5
				c0.5,4.3,0.5,4.2,4.6,4c1.2-0.1,2.4,0.2,3.6,0.2c1,0,2.2,0.1,2.6-0.9c2.1-6,4.1-12.3,3-18.6c-0.4-2.2-1.2-4.3-1-6.6
				c0.5-6.8-0.3-7.7-6.8-8.6c-1.9-0.3-2.8,0.2-2.6,2.1c0.5,4-0.1,8-0.2,12c-0.2,4.2-3.1,6.3-7.1,4.7c-1.2-0.5-2.1-0.7-3.1,1
				c1.3,0,2.1-0.1,2.8,0c4.6,0.5,5.2,1.6,3.7,6.2c-2.2,6.8-2.8,7-8.4,6.2c-3-0.5-3.3-2.6-3.8-5.1c-1.1,2.3-0.9,3.7,0.3,4.9
				C719,1754.1,724.4,1753.6,728.6,1750.5z M755.7,1690.6c0-1-0.1-2,0-3.1c0.2-2,0.4-3.9,3.1-4.3c2.7-0.3,3.8,1.2,4.4,3.4
				c1.1,4.2,1.2,8.6,1.1,12.9c0,2.7-1.2,4.3-3.8,4.9c-2.6,0.6-4.2,2.4-5.2,4.6c-0.6,1.2-0.5,2.8,1.4,2.2c3.1-1.1,6.2,2.3,9.1-0.5
				c0.1-0.1,0.6,0.2,0.8,0.4c2.2,2,5.2,1,7.7,1.9c0.8,0.3,1.8-0.5,1.9-1.8c-0.5-0.4-0.8,0-1.2,0.2c-2.4,1.2-4.5-0.5-5.8-1.7
				c-0.9-0.9,1.5-1.9,2.5-2.8c1.9-1.6,3.7-3.3,3.7-5.9c-0.1-4.9,0-9.8-1.5-14.5c-0.8-2.5-2.2-3.7-5.3-3.7c-3.2,0-2.5,3-4.1,4.2
				c-1.9-4.3-4.1-6.2-6.6-5.1C753.6,1683.7,755.3,1687.4,755.7,1690.6z M783.3,1735c-0.1-2.3-0.2-4.5-0.3-6.8
				c-0.2-3.8-2.8-2.4-4.7-2.6c-2.1-0.3-3,0.8-3.1,2.8c-0.1,2.2-0.2,4.3-0.2,6.5c-0.1,6.3-1.4,12.5-2,18.8c-0.1,1.4-0.3,2.9,1.5,3.5
				c3.2,1.2,6.5-0.4,6.9-3.7C782.1,1747.3,783.5,1741.2,783.3,1735z M790.4,1713.6c2.2-0.5,2.4-2.4,3.7-2.9c2.5-0.9,0.8,2.5,2.6,2.7
				c0.8-1.5-1.3-3.5,0.1-4.5c1.5-1.1,3.1,1,4.8,0.9c0.2,0,0.4-0.1,0.6-0.1c-0.1-1.1-0.2-2.3-0.2-3.7c-0.7,1.3-1.2,2.4-2.8,2
				c-1.4-0.4-1.7-1.5-1.9-2.8c-0.3-2-0.7-4.1-0.7-6.1c-0.1-2.2-1.8-2.5-3.2-3.1c-1.4-0.6-2.8-0.4-3.8,0.9c-1.7,2.1-0.5,9.5,2,10.9
				c1.6,0.9,2.1,1.7,0.7,3.2C791.6,1711.5,791.3,1712.4,790.4,1713.6z M778.7,1706.8c0.7-0.7,0.5-1.6,0.3-2.4
				c-1.2-5.3,0-10.9-1.2-16.2c-0.3-1.3-0.1-2.7,1.7-3c1.7-0.2,3,0.2,3.3,2.2c0.2,1.6,0.5,3.2,0.7,4.8c0.6,5.1,1.2,10.3,1.7,15.4
				c0.8-6.8-0.2-13.5-1.2-20.1c-0.4-2.5-1.8-4.2-4.7-3.9c-2.8,0.4-2.6,2.5-2.5,4.5c0.3,5.6,1.2,11.3,1,16.9
				C777.8,1705.8,777.7,1706.5,778.7,1706.8z M739.6,1702.4c2.1,2.1,4,1.8,5.8,2.1c2,0.3,1.9,1.7,1.9,3.2c-0.1,1.6-1.2,1.4-2.4,1.4
				c-2.2,0-3.9-1-5.5-2.5c0.3,3.7,3.2,3.3,5.7,3.6c2.5,0.3,2.9-1.4,3.1-3.2c0.1-1,0.2-2,0.2-2.9c-0.5-6.6-1.1-13.2-1.7-19.8
				c-0.1-1.1,0-2.4-1.7-3.2c0.6,5.7,1.2,11.1,1.8,16.4c0.7,6.1,0.7,6.1-5.2,5.1C741.2,1702.5,740.8,1702.5,739.6,1702.4z
				 M769.8,1744.1c1.5-0.5,2.5-1.2,2.8-2.6c0.9-4.8,1.3-9.7,0.4-14.6c-0.6-3.2-3.9-4.6-6.5-2.9c-2.3,1.5-3.5,8.2-1.9,10.8
				c0.8-1.1,0.3-2.2,0.2-3.2c-0.1-1.7,0.2-3.2,0.9-4.8c0.6-1.5,1.9-1.5,3.3-1.6c1.5-0.1,2.3,0.5,2.6,1.9
				C772.6,1732.9,773,1738.5,769.8,1744.1z M729.9,1736.1c0.9-4.2,1.8-8.3,0.6-12.6c-1-3.3-4.6-5.2-7.9-4c-1.1,0.4-1.9,1-1.6,2.4
				c6.1-2.7,8.7-1,8.9,5.7C730,1730.4,729.9,1733.2,729.9,1736.1z M763,1749.6c-2,0.8-2.3,2.9-3.6,4.1c-0.5,0.5-0.9,1.4,0,2
				c0.6,0.5,1.3,0.4,1.8-0.2c1.2-1.5,2-0.5,3.2,0.2c1.7,1,3.6,1.3,5.1-0.6c-2.9,1-5.2-0.4-7.2-1.9
				C761,1752.4,763.7,1751.4,763,1749.6z M719.1,1687.2c0.7,6.5-0.3,13.3,1.9,19.7C720.3,1700.3,720.2,1693.7,719.1,1687.2z
				 M737.1,1702c-3.1-0.1-6.3,0.6-6.1-3.7c0-0.7-1-0.7-1.6-0.5c-0.7,0.2-0.8,0.8-0.3,1.2c1.1,1.1,0.8,2.7,1.4,3.9
				c0.2,0.3,0.4,1.1,0.5,0.8C732.3,1701.3,734.8,1703.4,737.1,1702z M725.9,1679.2c-2.3-1.9-5.8-2.1-8,0.1
				C720.9,1678.4,723.3,1680.2,725.9,1679.2z M805.8,1691.6c1-0.9,0.8-2.4,2-2.7c1.1-0.3,2.2-0.2,3.6-0.4
				C807.6,1686,806.1,1686.8,805.8,1691.6z M746.6,1739.7c-0.7,0.2-2-0.5-2,0.8c0,0.9,1.1,0.9,1.8,0.9c0.7,0.1,1.7,0.4,1.9-0.6
				C748.4,1739.7,747.3,1739.9,746.6,1739.7z M785.8,1745.1c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.2,0.2,0.1,0.2
				C785.6,1745.1,785.7,1745.1,785.8,1745.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M497.8,1968.3
				c-2.1,0.1-2.2,1.4-1.4,2.8c0.4,0.7,1,1.4,1,2.3c-1.3,2.5-0.7,3.9,1.9,3.9c4.3,0,8.6,0.1,12.9-0.9c2.4,3.2,2,7.2,3,10.8
				c1.7,6.4,2.9,13,4,19.5c0.5,3.1,0.8,6.2,2.4,9c0.3,0.6,0.6,1.6,0.3,2.2c-1.7,4.1-0.9,8.5-1.3,12.7c-0.1,0.6-0.4,1.1,0.1,1.7
				c2.3,2.9,1.4,5.9,0.2,8.9c-0.8,1.9-1.8,1.9-3.1,0.4c-0.5-0.6-0.9-1.2-1.4-1.8c-0.4-0.4-0.9-0.6-1.5-0.3c-0.7,0.4-0.6,1.1-0.2,1.7
				c0.6,1,1.5,1.8,1.4,3.8c-4.9-3.5-9.8-1.4-14.7-1.2c-0.6,0-1.4-0.1-1.6,0.7c-0.2,0.8,0.7,1,1.2,1.3c2,1.4,4.2,0.7,6.3,0.5
				c2.5-0.2,3.6,0.6,3.6,3.1c0,1-0.2,2.7,1.6,1.9c1.5-0.6,4.2,0.9,4.4-2.1c0.1-1,0.8-1.9,0.8-2.7c0.1-3.6,1.8-3,3.9-1.8
				c0.3,0.2,0.6,0.5,0.9,0.4c3.8-1.2,4.4,2.1,5.8,4.1c1.1,1.7-1.3,3.3-0.8,5.1c0,0.4,0,0.7,0,1.1c0,0.5,0,1,0,1.5
				c0,0.2-0.1,0.4-0.2,0.5c-0.2,0.1-0.3,0.1-0.5,0.2c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.8,0.1,1.5,0.3,2.3c1.2,5.5,1.2,5.5-3.6,7.7
				c-0.2,0.1-0.4,0.3-0.6,0.3c-3.4,0.1-2.5,1.5-0.9,3c-1.5,2.5-0.2,5.8-2.5,8.1c-0.7,0.7,0.6,1.2,1.4,1.1c2.8-0.4,5.7,0.6,8.3-1
				c1.6-1,3.3,0.1,4.7,0.6c1.6,0.6,1.4,2.6,1.4,4c0,1.8-1.5,1-2.4,1.1c-0.4,0-1-0.2-1.3,0c-4.1,2.9-7.9-0.3-11.8-0.5
				c-2.7-0.1-5.5-0.2-8.2,0.3c-0.8,0.1-1.6,0.2-1.5,1.3c0.1,0.9,0.1,1.9,0.6,2.5c1,1,1-0.4,1.5-0.8c1.1-0.9,1.5,0.2,1.9,0.9
				c1.5,2.4,2.9,4.8,4.4,7.3c-2.2-0.8-3.9,0.2-5.5,1.6c0-0.4,0-0.9-0.2-1.2c-1.5-3.2-4.4-5.6-4.5-9.5c0-1-1.2-1.4-2-0.7
				c-0.8,0.7-2.1,0.5-2.7,1.6c-1.2,0-2.4,0.1-3.6-0.1c-3.2-0.6-6.3,0.3-9.4,0.8c-1.5,0.3-2.3,1-2.1,2.7c0.3,2.6,0.4,5.2,0.5,7.8
				c-0.1,0.5-0.2,1-0.3,1.6c-1.1,0.8-2.4-0.3-3.4,0.4c0.1-3.6-0.5-7.1-0.8-10.7c-0.1-1.5,0-3.6-1.7-3.4c-1.5,0.2-4.1,0.3-4.1,3
				c0,3.9-0.2,7.8,0,11.8c0.1,2.5,2.2,3.5,4.5,2.8c1.4-0.4,0.4-2.5,2-2.6c0.5,2.6,0.3,4.2-3.2,3.9c-5.5-0.5-11,0.6-16.5-0.6
				c-1.9-0.4-3.9,1.8-5.5,0.3c-1.5-1.3,0.2-3.8-1.1-5.5c0.2-1.3,0.8-2.8-1-3.7c-4.5-2.2-7.7-1.4-10.9,2.6c-6.4,0.3-12,3.5-18.1,5
				c-1.9-0.4-3.9,0.2-5.5-1.4c-2.8-2.9-5.6,0.5-8.4,0.3c-1.7-0.1-0.5,2.4-1,3.6c-0.5,0.7-1.6,1.1-1.3,2.1c0.4,1,1.5,0.6,2.3,0.6
				c10.4,0.3,20.9,0.1,31.3,1.3c0.6,0.1,1.3,0.3,1.7-1c-6.7,0.6-13.2,1.2-19.8,1.8c-3.5-0.8-7-0.4-10.5-0.4c-0.6,0-0.8,0.6-0.8,1.2
				c-0.5,0.4-1,1.2-1.5,1.1c-4.5-0.5-8.9,0.9-13.3,0.2c-3.6-0.6-7.2-0.5-10.8-0.6c-0.8,0-1.5,0-1.6,1.1c3.6,1.9,7.6,0.3,11.3,1.5
				c-4.4-0.7-8.6,1.2-12.9,0.9c-2.4-0.1-5-2-5.6-4.7c3-2.6,1.9-6.4,2.9-9.5c0.9-0.1,2.1-0.8,2.8,0.1c1.2,1.7,3,1.5,4.7,1.6
				c2.9,0.2,5.6-0.7,8.2-1.5c0.6,0.6,1.3,0.5,2,0.1c0.8,1.1,0.4,2.3,0.6,3.5c-0.3,0.9-0.4,1.9-0.8,2.7c-1,2.3-0.4,3.2,2.1,3
				c0.9-0.1,1.7-0.1,2.6-0.1c1.3,0,1.5-0.9,1.8-1.8c0.4-1.3-0.4-1.4-1.3-1.6c-0.5-0.1-0.8-0.6-1.2-0.9c0.5-0.8,0.7-2.1,2-1.7
				c1.5,0.4,3,0.4,2.6-1.4c-0.3-1.7,0.9-4.2-2.2-5c-3.1-0.7-6.4,0.1-9.4-1.2c-3-1.3-6.1-0.7-9,0c-1.7,0.4-3.2,0.5-4.8,0.3
				c0.2-0.9,0.4-1.7,0.6-2.6c0.4-1.7,0.4-3.7,1.3-5c1.1-1.6,3.1,0.5,4.6,0c5.7-2,11.8-1.9,17.7-2.6c3.6-0.4,7.1-0.8,10.7-1.2
				c1.2-0.1,2.3-0.4,2.4-1.8c0.1-4.1,0.6-8.2,0.1-12.3c0-2.1,0.9-4.6-2.4-5.2c-2.6-0.5-3.9,0.6-3.6,3.4c0.1,0.7-0.5,1.2-1.3,1.1
				c-1-0.1-0.7-0.8-0.6-1.5c0.1-0.8,0.1-1.7-1-1.6c-1.1,0-1,1-0.9,1.7c0.4,2.1-0.9,2.3-2.5,2.4c-2.9,0.1-5.8,0.2-8.8,0.4
				c-0.9-0.6-1.9-0.2-2.9-0.3c-1.8-0.3-4.1,0.2-4.1-3.2c0-2.5-2.8-1.2-4.3-1.6c-0.2-0.9,0.2-1.8,1.1-1.5c2.1,0.6,3.7-0.8,5.6-1.1
				c-0.3,0.8,0,1.8,0.8,1.9c4.3,0.4,8.5,0.3,13-0.5c-5-1.1-3.9-5.1-4-8c-0.5-9.7-0.6-19.3-1-29c-0.1-1.4,0.4-2.3,2.2-3.3
				c0,4.1-0.1,8,0,11.8c0.3,8,0.8,16.1,1.2,24.1c0.1,1.6,0.7,2.4,2.4,2.5c0.1,0,0.2,0,0.3,0c2.9-0.1,6.1,0.7,8.8-0.5
				c3.8-1.6,1-5,1.2-7.5c0.6-5.3,0.1-10.7,0.2-16c0-4-0.2-8-0.1-12.1c0.2-5.4,0-10.9-0.5-16.3c-0.3-3.9,1.3-7.7,0.6-11.6
				c0.1-0.3,0.2-0.6,0.4-0.9c0.7-0.5,0.5-0.9-0.1-1.3c0-1.2-0.2-2.4-0.9-3.4c6.6-14.7,13.3-29.3,19.9-44c0.4-1,0.7-2.1,2-2.2
				c0.2,0.8,0.3,1.7,0.5,2.5c-0.2,0.5-0.1,1,0.5,1.2c0.6,0.2,0.9-0.1,0.9-0.7c0.6-0.8,0.5-1.6,0.4-2.5c2.4,0,4.2-1.5,6.1-2.7
				c-0.1,1.5-0.4,4,0.9,3.9c2.6-0.1,1.3-3.1,2.4-5.1c1,1.9,1.9,4.5,3.6,1c1.1,0.6,0.8,1.5,0.8,2.2c-0.4,6.6,0.9,13.2,0.2,19.8
				c-0.2,1.8-0.4,2.6-2.4,2.5c-2.7-0.3-5.1,1.5-7.8,1c-1.3,0.1-2.6,0.3-3.9,0.4c-2.2,0.1-3.7,0.7-3.4,3.5c0.2,2.7,2.1,2,3.6,2
				c4.5,0,9.1-0.7,13.6,0.2c1.3,4,1.7,8,0,11.9c-0.4,1-0.7,1.9-0.4,2.8c1.7,6.3,2,12.5,1.2,19c-0.6,5,0,10.2-1.4,15.2
				c-0.2,0.8-1.7,3.2,1.3,3.3c0.1,0,0.4,0.3,0.4,0.4c-1.7,3.1,1.5,6,0.3,9.2c-0.7,2,0.5,4.4-0.7,6.4c-3.2,0.1-6.5,0.2-9.7,0.3
				c-0.6-0.7-0.9-1.5,0.4-1.5c2.1,0.1,2.3-1.3,2.5-2.7c0.2-1.8-1.5-4,1.3-5.1c-1.8-2.6,0.9-4.2,1.3-6.3c0.1-0.8,0.5-2,0.2-2.5
				c-1.3-1.9-0.3-3.2,0.7-4.8c0.9-1.5,2-4.3,0.2-5c-2.4-0.9-1.9-1.5-1-3c0.4-0.7-0.4-1.4-0.9-2.3c-0.6,2-1.5,2.4-3.1,1.2
				c-2.2-1.7-4.4-0.1-6.3,0.6c-1.1,0.4-0.5,2.3-0.5,3.6c0.1,1.5,0.8,0.6,1.4,0.3c1-0.5,1.3,0.4,1.6,1.1c0.4,0.9,0.4,1.8-0.3,2.4
				c-0.8,0.7-1.4,0.1-1.9-0.5c-1.8-1.9-2.1-0.3-2.2,1c-0.1,3.8-0.1,7.7-0.1,11.5c0,0.6,0.1,1,0.8,1.2c2.2,0.8,4.2,6,3.1,8.3
				c-0.5,1-0.5,2.5-2.2,2.3c-3.1-0.4-2.6,1.5-2.1,3.4c0,5,0,10,0,15c0.3,5.6,0.8,11.2,0.9,16.8c0.1,2.7,0.9,3.7,3.7,3.1
				c1.9-0.4,3.9-0.3,5.9-0.5c4.7-0.4,6-3,3.9-7.6c-1.5-0.2-3-0.5-4.5-0.7c-0.8-2.5-1.7-4.9-4.9-5.3c-1.1-0.1-1.2-1-0.5-1.9
				c0.9-1.3,2.2-1.4,3.7-1.4c2.3,0,4.7,0.8,6.9-0.5c1.3,0.1,2.2-0.3,1.9-1.8c-0.2-1.2,0.5-1.8,1.3-2.4c0.2,0.4,0.6,0.7,0.6,1.1
				c0.1,1.9-1.3,4.7,0.9,5.6c3.6,1.4,7.6,1.7,11.5,0.6c0.3-0.1,0.8-0.6,0.8-0.9c-0.6-3.6,2-2,3.5-2.1c1,0,2.1,1.1,2.9-0.2
				c0.6-1,0.4-2.2-0.2-3.1c-1.4-2-1.8-4.6-3.4-6.6c-1.3-1.7-1.5-3.7-0.8-5.8c4.6,0.3,9.1-0.1,13.6-0.8c2.1-0.3,3.5-0.5,3.9-2.7
				c2-2.3,0.8-4.4-1.1-5.6c-2-1.4-1.5-2.4-0.6-4c0.7-1.2,2.4-2.4-0.3-3.8c-1.2-0.6-0.9-3.1,0.3-4.4c1.3-1.4,1.8,0.5,2.6,1.7
				c0-5.3,0-9.9,0-14.5c0-0.9-0.1-1.8,0-2.6c0.3-1.5,0.7-3.5,2.4-3.4c1.5,0.1,0.9,2.2,0.9,3.4c0,2.1,0.3,4.1,1,6.3
				c1.4-1.2,0.9-2.5,0.7-3.7c-0.4-1.9-1-3.8-1-5.8c0-2.6-0.6-4.3-3.6-4.1c4-7.3-1.1-13.8-1-20.8c-1.5,0.9-3.9,0.1-3.6,2.9
				c0.1,1.1-0.6,2.1,1.5,1.6c1.8-0.4,1.3,1.3,1,2.2c-0.5,1.1-1.6,1.8-1.8,3.2c-2.4-2.6-2.4-2.6-3.5,0.7c-1.1,3.5-0.9,7.1-0.8,10.7
				c0.1,3.9-1.7,8.2,1.7,11.7c0.4,0.4,0.3,1.5,0.3,2.2c-0.1,2.9,0.2,5.8,0.8,8.7c0.7,3.6-0.9,5.4-4.6,5.2c-2.6-0.2-3,1.1-2.8,3.1
				c0.1,0.9,0,1.8,0.4,2.6c1,1.8,0.4,3.4-0.7,4.5c-1.2,1.2-3.3,0.6-5,0.7c-0.9,0.1-1.1-1-1-1.7c0.4-7.5-1.5-14.9-1.2-22.4
				c0.1-2.7,1-4.9,1.9-8.2c0.6,2.4-0.4,5.4,3.1,3.9c1.6-0.7,1.8-1.1,0.3-2.2c-1-0.7-0.7-1.1-0.2-1.8c0.7-0.8,1.3-0.6,1.9,0.1
				c0.2,0.2,0.2,0.7,0.4,0.8c0.9,0.7-0.1,3,1.6,2.6c1.6-0.3,0.4-2.2,0.6-3.3c0.8-5.2-0.8-10.2-1-15.3c-0.1-2.1,0.6-4.4,0-6.6
				c-0.1-0.5-0.2-1.1-1.3-1.3c0,1.7-0.1,3.4,0,5c0.2,2.4-0.4,3.8-3.2,3.4c-1.4-0.2-2.8,0-5,0c2-1.1,3.8-1.8,3.7-4
				c-0.1-1.9-0.2-3.6-1.3-5.3c-1.1-1.9-1-4.7-1.8-6.2c-2.8-5.2-0.9-10.5-1.8-15.6c-0.3-1.9-0.2-3.4,2.5-2.3c0.6,0.3,1.3,0.3,1.6-0.5
				c0.2-0.6-0.2-1-0.8-1.2c-3.5-0.8-4-3.5-3.9-6.5c0.1-2.6-0.9-5.5,1.6-7.6c0.2-0.2,0.2-0.6,0.3-0.9c0.2-0.2,0.4-0.4,0.7-0.7
				c0.6-0.3,1.2-0.6,1-1.4c0-0.1-0.2-0.2-0.3-0.2c-0.8,0.3-0.8,0.9-0.7,1.6c-0.4,0-0.7,0.2-0.7,0.7c-2.9,0.5-1.9-1.8-2.4-3.1
				c-0.5-1.3-0.1-2.5,0.4-3.7c4.5,3,9.4,5.1,14.6,7.1c6.2,2.4,12.6,3.3,19,4.5c-0.4,1.3-0.5,2.2,0.4,3.7c1.2,1.9,1.2,4.5,1.7,6.8
				c0.2,1.1-0.9,1.1-1.5,1.4C505.3,1967.6,501.6,1968,497.8,1968.3z M509.7,2069.6c-0.5,0-0.9,0-1.4,0.1c-2.3,0.1-4.7,0.2-7,0.3
				c-0.4-0.1-0.9-0.2-1.3-0.3c0.2-1.4,0.1-2.8-0.3-4.2c-1-3.1-0.8-6.4-0.9-9.6c-0.1-3.5-1-4.2-4.4-3.9c-3.3,0.3-4.6,3.6-8.6,3.7
				c3.7,0.9,3.4,1,3.6,3.9c0.3,3.5-0.6,7.1,0.6,10.6c-0.4,0.9-0.4,1.9-0.1,2.9c0,0.4,0,0.7,0,1.1c-0.5,3.1-0.3,6.3-0.2,9.4
				c0,1.4-0.6,3.6,1.9,3.2c2.2-0.4,5.7,1.7,6.3-2.6c0.1-0.9,0.2-1.7,1.4-1.7c4.6,0,9-1,13.5-1.6c7.3-0.9,9-2.7,7.5-9.9
				c0-1.4-1.1-1.4-2-1.4C515.3,2069.5,512.5,2069.5,509.7,2069.6z M486.7,1972.8c-0.4,0.3-0.8,0.8-1.3,1c-1.3,0.7-1.8,1.9-1.4,3.2
				c0.5,1.7,2,0.7,3,0.7c1.4,0.1,2.8-0.1,4.2-0.1c1,0,2.1,0.1,2.2-1.3c0.1-1.2,0.1-2.5-1.2-3.1c-0.9-0.4-1.9-0.5-2.8-0.7
				c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.9,0.3-2.3-1.2-2c-1.1,0.2-2.8-0.1-3.4,1.2C484.2,1972.4,486.2,1972,486.7,1972.8z
				 M508.1,2014.3c0.8,2.4-0.3,4.2-0.7,6.1c-0.2,1.3,0.6,2.8,1.4,1.9c1.2-1.2,3.4-1.6,3.4-4.2c0.2-9.2-0.6-18.5,0.1-27.7
				c0.1-0.8-0.3-1.7-1-1.8c-3.8-0.6-1.7-2.5-0.7-3.7c1.2-1.5,2.4-3.1,0.9-4.5c-1.7-1.6-2.5,0.8-3.6,1.5c-0.5,0.3-0.9,0.8-1.4,0.5
				c-0.8-0.5-0.3-1.1,0.1-1.6c0.3-0.5,0.6-0.9,0-1.3c-2.9,1.5-2.9,1.5-6.1,1.4c0.4,1.1,0.6,3.1,1.7,2.5c2.2-1.3,2.7,0.9,3.8,1.4
				c2.5,1.2,0.2,2.5-0.5,3.7c-0.2,0.3-0.3,1-0.1,1.2c1.6,1.3,0.2,2.1-0.4,3.2c-1.1,1.9-2.4,5.1-0.7,6c3.1,1.7,1.5,3.5,1.4,5.4
				c0,1.1-0.5,2.1-0.8,3.1c-0.9,3.5-1.3,7.1,1.1,10.9C506.8,2016.7,507.4,2015.7,508.1,2014.3z M514.2,1991.7
				c-0.1,1.1-0.2,1.7-0.2,2.4c0.2,6.6,0.8,13.2,0.4,19.8c-0.3,4.4,1,8.9-0.3,13.3c-0.5,1.8,0.5,3.4,1.2,5c0.7,1.5,1.9,1.2,3.1,0.8
				c1.7-0.6,0.5-1.4,0.1-2.2c-0.3-0.5-0.4-1.2-0.4-1.9c-0.9-11.2-1.7-22.5-2.6-33.7C515.5,1994.1,515.6,1992.9,514.2,1991.7z
				 M511.1,2030.4c-0.1-1.2,0.2-2.5-0.4-3.8c-0.6-1.2-1.4-2-2.8-1.6c-1.4,0.5-1,1.7-0.8,2.7c0.3,1.2,0.3,2.5,1,3.4
				c0.9,1.1,0,3.8,2,3.4C512,2034.2,510.9,2031.9,511.1,2030.4z M504.4,2039.3c-1.1-2-2.2-3.8-2.9-5.8c-0.3-0.9-1.3-0.3-1.9,0.2
				c-1.5,1.3-1.7,3.2-0.5,4.3c1.2,1.1,3.2,1.4,4.9,2C504,2040,504.3,2039.6,504.4,2039.3z M516.4,2057.2c1.5,0,2.5,0,3.5,0
				c0.9,0,2,0,2.1-1.1c0-1.2-1.2-1-2-1.1c-1-0.1-2-0.2-2.9,0c-1.6,0.3-4.5-0.8-4.5,1.1C512.5,2058.1,515.4,2056.8,516.4,2057.2z
				 M526.9,2051.6c-1.5-2.6-4.2-3.5-7.6-5C521.3,2050.1,524.3,2050.4,526.9,2051.6z M393.6,2109.2c1.1-0.3,3.1-0.4,3.1-1.9
				c0-1.9-2.5-1.7-3.9-2.3c-1.6-0.6-1.6,1-1.7,2C390.9,2108.5,391.9,2109.1,393.6,2109.2z M400.1,2112c0.6-0.5,1.5-0.9,1.6-1.8
				c0.1-0.6-0.5-0.7-0.9-0.6c-1,0.1-1.6,0.6-1.6,1.7C399.2,2111.8,399.6,2112,400.1,2112z M501.5,1987.9c1-1.8,1-1.8,0.2-3
				C500.9,1985.7,500.6,1986.5,501.5,1987.9z M411.5,2109.4c-0.2-0.2-0.3-0.5-0.4-0.5c-0.3,0-0.5,0.2-0.8,0.3
				c0.1,0.2,0.2,0.5,0.3,0.5C411,2109.6,411.2,2109.5,411.5,2109.4z M499.7,1980.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.2
				c0.1,0.1,0.1,0.2,0.2,0.2C499.5,1980.5,499.6,1980.4,499.7,1980.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M566.2,2135.6
				c-1.9,1.5-3.2,3.6-5.2,5c-1.4,0.9-2.9,1.5-4.2,1c-1.3-0.5-1-2.2-1-3.5c-0.1-6.5-0.4-13.1-0.4-19.6c0-1.9,0.3-4.1,1.1-5.7
				c2.5-4.7,1.2-9.7,1.8-14.5c0.2-1.5,1.3-2.5,1.5-3.9c0.1-1.1,1.6-2,0.3-3.4c-0.7-0.7,0.3-1.5,1-1.7c3.5-0.8,3.3-3.9,2.3-5.7
				c-2.1-4,0.2-7.1,1.2-10.4c0.8-2.7,1.9-5.2,0.6-8c-0.2-0.5-0.4-1.3-0.2-1.6c2.5-2.2,1.9-5.9,4-8.3c2.2-2.5,1.4-8-1.3-10.1
				c-1.9-1.4-2.3-3.1-1.5-5.4c0.5-1.6,2.1-3.5-0.1-5.2c-0.2-0.2-0.3-0.8-0.2-0.9c3.5-3.9,0.8-8.4,0.8-12.4c0-5,0-10.1,0.3-15.1
				c0-0.3-0.2-0.6-0.3-0.9c-2.1-4.6-1.3-9.3-0.5-14.1c0.4-2.3,0.3-4.8-0.1-6.9c-1-4.7,0.2-9.4-0.4-14c-0.4-3.7,0.3-7.4,0.3-11
				c0-4-2-8.2,0.1-12.2c0.7-1.4-1.2-2.2-0.9-3.6c0.8-4.7-0.9-9.6,0.2-14.2c1.3-5.5,0.1-10.9,0.4-16.3c0.3-5-0.4-10-1-14.9
				c-0.2-1.5,0.6-2.7,0.5-4.2c-0.2-2.9,0.6-5.7-0.5-8.6c-0.9-2.2,1.2-4.3,1.8-6.4c1.1-3.8,1.1-7.2-0.3-10.6c-0.6-1.6-1.3-3-0.2-4.6
				c1-1.5,0.2-3.1-0.3-4.4c-1.5-4.2,0-8.4-0.2-12.6c-0.1-4.2-0.8-8.4-0.6-12.6c0.1-3.9-0.2-7.8-0.2-11.7c0.1-4.5,1.8-8.7,1.7-13
				c0-2.4-0.4-4.6,1.7-6.3c0.2-0.1,0.2-0.7,0.1-0.9c-1.3-1.7,0.1-3.8-0.4-5.2c-1.2-3.5-0.7-7.2-2.1-10.6c-0.4-0.8-0.4-1.6-0.2-2.5
				c0.8-6.5,0.6-13-0.9-19.4c-0.2-0.8-0.3-1.5,0-2.2c1.5-4.2,0.6-8.3-0.1-12.5c-0.2-1.1-0.2-2.2-0.2-3.3c0-6.4,0-12.8,0-19.1
				c0-1.5-0.2-2.9-0.6-4.4c-0.3-1.6,0.9-3.4,0.8-5.2c-0.2-4.7,2-8.9,2.2-13.6c0.1-2.7,1.8-5.5,2.5-8.3c0.2-1.1,1.3-1.3,2-0.3
				c0.9,1.4,2,2.3,3.5,1.6c1.7-0.8,2.3-2.4,2.2-4.3c0-2-0.8-4.4,2.5-5.8c-0.5,4.9,1,9.4,0.3,14c-0.1,0.6-0.2,1.4,0.1,1.9
				c2.9,4.3,1.6,9.3,1.8,13.9c0.2,3.9-1.2,8-0.8,12.1c0.7,7.4,0.1,14.9,0.6,22.3c0.4,6.5,0.6,13.2,0.4,19.6
				c-0.3,7.1,1.8,14.3-0.1,21.4c-0.3,1.1,0.3,2.5,0.5,3.8c0.3,2,0.5,3.8-0.9,5.8c-1.4,1.9,0.3,4.3,0.6,6.3c0.8,5.4,0.3,10.7,0.7,16
				c0.4,5.8,0.2,11.7,0.5,17.6c0.1,2.1,1.3,4,1.4,6.3c0.2,5.5,0,11.1,1,16.6c0.2,1.3,0.2,2.7,0,3.9c-1.4,6.9-0.9,13.8-0.4,20.7
				c0.2,3.2-0.8,6.5-0.7,9.8c0.3,9.5,1.2,19.1,1.1,28.6c-0.1,5.2,0.8,10.2,0.8,15.3c-0.1,7,0.1,14,0.7,20.9c0.1,1.5-0.1,2.9-0.8,4.4
				c-0.7,1.5,0.8,3.2,0.8,5.1c-0.1,2.9-0.5,5.9,0.5,8.8c0.3,0.9-0.4,1.9-1,2.7c-0.7,0.8-1.1,2-0.6,2.7c1.6,2.5,1.1,5.5,0.9,7.8
				c-0.4,5.1,2.1,10.7-1.6,15.5c-0.4,0.5-1,1.5,0.2,1.9c2.9,0.9,2.6,3.3,2.3,5.3c-0.5,3.3-0.9,6.3,0.6,9.5c0.6,1.3,0.4,3.3,0.1,4.8
				c-0.8,4.9-0.8,9.8-0.7,14.8c0.1,5-0.6,10.1-0.5,15.2c0.1,5.7,1.2,11.5,1,17.2c-0.2,4.2,1,8.3,0,12.6c-0.9,3.9-0.4,7.9,0.6,11.8
				c0.1,0.3,0.4,0.7,0.2,0.9c-2.3,4,1.6,8.3-0.5,12.4c-0.4,0.9,0.4,1.9,0.3,2.8c-0.1,2.4,0.6,4.9-2.9,6.4c-1.8,0.8-4.5,2.4-4.4,5.7
				c0,0.8-2.5,2-4.1,1.6c-2-0.5-4.2-0.5-5.5-2.7c-0.7-1.1-1.9-1.4-3.2-1.1c-1.6,0.4-2.2,1.7-2.7,3.1c-0.3,1,0,1.7,1.3,1.6
				C566.6,2134.9,566.4,2135.3,566.2,2135.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M195.3,2028.1
				c0.9,1.6,1.2,3.9,4.1,2.6c0.9-0.4,1.3,0.9,0.9,1.5c-1.1,1.9-1.8,4-3.8,5.3c-1.9,1.2-2.5,5.1-1,6.7c2.1,2.1-0.5,3.3-0.8,4.8
				c-2.7,1.7-5.3,3.8-9,2.9c-1.7-0.4-1.2,0.9-0.6,1.8c-0.3,0.7-0.8,1-1.6,0.9c-0.6-0.1-1.2-0.1-1.3,0.6c-0.1,0.9,0.7,1,1.3,1
				c2-0.1,3.1,1.7,4.8,2.7c0.5-2,0.5-3.7,0-5.3c3.7-0.2,3.8-0.1,3.8,4.8c-1,0.4-0.8,1.2-0.5,1.9c0.5,1.3-0.8,2.4-1.2,2.2
				c-3.8-1.2-6.1,3.3-9.8,2.3c-0.3,0-0.6-0.1-0.9-0.1c-2.1-0.8-4.3-2.1-6.4-0.1c-1.3,0.3-3.3-0.5-3.6,1.6c-0.3,2.6-1.3,5.2-0.1,7.9
				c0,0.6-0.1,1.2-0.1,1.8c-0.9,2.6-0.5,5.4-0.3,8c0.3,4.7-1.6,9.2-0.4,13.9c-0.1,2-0.2,4.1-0.2,6.1c0,0.9,0.2,2,1.3,1.8
				c2.8-0.4,5.8,0.7,8.5-0.9c0.6,2.4-1.1,2.7-2.8,2.7c-1.8,0.1-3.6,0-5.5,0c-1.9,0-2.3,0.9-1.6,2.5c0,0.5,0,0.9-0.1,1.4
				c-1.1,5.7-1.1,5.9,3.7,8.2c7.9,3.8,15.6,8,23.7,11.4c0,0.5,0,0.9,0,1.4c-0.2,0.1-0.5,0.2-0.7,0.3c-2.2-0.4-4.1,0.8-6.1,1.3
				c-2.4-0.6-4.4-2.1-6.6-3.2c-3.3-1.7-6.3-4.3-10.3-4.2c-0.4-0.6-0.9-1.1-1.7-1.1c-0.7-0.7-1.2-1.7-2.3-1.5
				c-0.7,0.1-0.8,0.7-0.7,1.3c0.7,2.7-0.9,5.6,0.3,8.3c0,0.4,0,0.9,0,1.3c-0.1,0.4-0.1,0.9-0.2,1.3c-0.8,0.4-1.7,0.4-2.5,0
				c-2.4-1.2-3.1,0.8-4.3,2.1c-1.1,1.3-1.3,1.9,0.3,3.2c1.5,1.2,0.4,3.4,0.4,5.2c-3.7,0.4-7.4,0.9-10.5,3.3
				c-0.1-0.7-0.1-1.4-0.2-2.1c0.6-2.9,3-5.2,2.8-8.4c0.4-0.8,0.7-1.6,0.2-2.4c0.3-2.6,0.5-5.3,0.9-7.9c0.4-2.5-0.7-3.4-3-3.3
				c-1.5,0.1-2.8-0.5-3.8-1.6c2-3.8-1.4-3.3-3.3-4c-3-1.7-3.6-1.5-3.6,1.7c0,1.6-0.6,1.5-1.7,1.3c-5.3-0.8-10.8,0.5-16-1.4
				c-1.6-3.8-3.3-4.4-5.8-2.1c-3-0.8-4.7,2.2-7.3,2.4c0.8-0.8,1-1.8,0.8-2.9c-0.5-4,0.1-8.1-0.7-12.2c-0.2-0.9,0.3-1.8,0.5-2.8
				c1.7-0.2,3.1-1.3,4.2-2.4c7.3-7.6,15-14.8,22.8-21.9c2-1.5,4.3-2.5,5.5-4.9c4.5-2,6.9-6.5,11-8.9c2.9-1.1,6.1-1.3,9.1-1.9
				c1.1-0.2,1.4-0.9,1.5-1.9c0.1-1.1,0.1-2.2,0.2-3.3c0.4-5.6,0.3-5.6-5.2-4.9c-2.6,0.4-5.2,0.6-8.4,1c2-2.3,2.1-4-0.6-5
				c-0.3-0.1-0.6-0.5-0.6-0.7c0.1-2.8-0.5-5-4.1-4.5c-0.1-2.7-2.8-3.6-3.8-5.6c-0.6-1.3,1-1.2,1.9-1.5c2.3-0.7,4,1.6,6.4,0.9
				c1.4-0.4,2-0.5,2.1-2.1c0.2-1.9,0.7-3.7,0.6-5.7c-0.1-1.9,0.1-2.7-2.2-1.6c-3.9,1.9-8.3,2.7-12,5.3c-2.1,1.5-4.1-0.1-4-2.7
				c4.6-1.7,8.8-4,12.6-7.2c3-0.8,5.5-2.6,8.3-3.8c0.7-0.3,1.3-0.8,1.5-1.7c0.2-0.9,1.2-1.9,0.3-2.7c-1.1-1-1.7,0.2-2.4,0.8
				c-0.4,0.4-1,0.4-1.5,0.2c0.3-1.8,0.9-3.9-2.2-3.7c-2.9,0.1-5.8,0.3-8.7,0.4c-4.8-0.9-9.2,1.3-13.7,2.1c-0.4,0-0.9,0.1-1.3,0.1
				c-0.7-0.6-1.5-0.7-2.4-0.4c-2.5-2.1-5.4-0.2-8.1-0.8c-2.3-1.8-3.8-4.4-6.4-6c-0.3-0.2-0.6-0.7-0.6-1.1c0.2-6.1-0.5-12.3,0.4-18.4
				c14.9-0.3,29.8-0.7,44.8-1c0.4,0,0.7,0,1.1,0c2.8-0.7,3.1-1.6,1.4-4c0.2-4.3,0.5-8.6,0.7-12.9c1.1-7.1,1.5-14.2,1.7-21.4
				c0-1.7-0.7-2.5-2.1-3.3c-6.5-3.6-12.9-7.3-19.3-11c2.5-1.1,5.2-0.3,7.8-0.8c3.7,2,7.3,4.1,11,6.1c1,0.6,2.4,1.1,2.2-0.8
				c-0.2-2,1-3.8,0.5-5.7c1.6-0.1,3.2,1.1,4.7-0.1c1.8-0.1,3.7-0.2,5.5-0.3c1.1-0.1,1.9,0.3,1.6,1.4c-1,3.5,0,7,0.1,10.5
				c0.1,2.8,1,4.3,3.4,5.6c4.4,2.3,8.6,4.8,13.1,7.4c-4.1,2.9-5.6,2.9-9.1,0.6c-1.8-1.2-3.5-2.6-5.5-3.5c-1.6-0.7-2.5-0.6-2.5,1.4
				c0,1.7,0,3.5-0.1,5.2c-4.6-0.5-5-0.1-5.3,4.7c0,0.6,0.1,1.2,0.2,1.9c-0.1,0.4-0.2,0.8-0.3,1.2c-1.3,2-3,4-1.1,6.5
				c3.3,1.4,5.4-1.1,7.9-2.2c1.1,3.9-3.3,4.9-4.8,8.1c3-1,4.7-3.5,7.5-3.1c0.1,1.2,1,1.6,1.9,2c0.1,1,0.7,1.1,1.4,0.6
				c16.8,9.3,34.4,17.2,51,26.9c0.9,0.5,1.8,0.5,2.8,0.7c0,0.3,0.1,0.6,0.1,1c0.3,5.7,0.2,5.7-4.8,3.2c-2.3-1.2-2.9-0.7-3,1.8
				c-0.1,1.8,0.3,3.2,1.5,4.7c2,2.5,3,5.5,3.7,8.6c0.2,0.9,0.5,2.1-0.8,2.4c-1.2,0.3-1.8-0.5-2.2-1.5c-1.1-2.8-2.2-5.7-3.3-8.5
				c-0.4-0.9-0.4-2.3-2-2c0.7-0.9,0.3-1.4-0.7-1.4c-2.3-0.1-4.5,0.3-6.2,2.1c-0.4,0-0.8,0-1.2,0c-2.2-3.7-1.4-4.9,3.1-5.1
				c1.4-0.1,2.8-0.2,4.1,0.7c1,0.7,1.9,0.7,2.1-0.8c0.1-1.6,0.7-3.5-0.9-4.4c-2.1-1.1-4-3-6.7-2.7c-0.8,0.1-1.5,0.8-1.9,1.7
				c-0.3,0.6-0.6,1.2-1.3,1.3c-0.2-1.1-0.9-1.4-1.9-1.4c-2.8,0-4.9,2.8-3.9,5.5c0.3,0.8,0.5,1.4-0.1,2c-0.3,0.3-0.8,0.6-1.2,0.3
				c-0.8-0.5,0.1-1.2,0-1.7c-0.6-2.1,0.8-4.2-0.3-6.9c-1.4,3.7-4.5,1.2-6.6,2.5c-0.6,0.4-0.6-0.3-0.5-0.8c0.2-1.2-0.3-1.7-1.4-1.5
				c-1,0.1-2.1,0.2-1.9,1.6c0.1,0.8,0,2-0.8,1.8c-2.2-0.6-2.3,1.6-3.6,2.2c0.1-1,0.4-2.3-1.4-1.4c-1.6-1.2-3.1,0.3-4.7,0.1
				c-0.9-0.1-2.7-0.6-2.5,1.3c0.1,1.5-0.1,3.7,2.7,3c0,1.6,2,0.4,2.4,2.1c-2.6-0.5-4.7,1.4-7.2,0.7c-0.9-0.2-1.5,0.3-1.6,1.1
				c-0.1,1.1,0.7,1.1,1.5,1.1c1.1,0,2.3,0.4,3.2,0c3.7-1.4,5.7-0.7,5.2,3.7c-0.6,0.5-1.1,1.3-2.1,1.2c0.4-1.4,0.5-2.8-0.3-4.2
				c-1.5,0.6-3.1,1.1-4.5,1.7c-0.7,0.3-2,0.4-1.8,1.5c0.2,0.9,1.3,0.4,2,0.7c0.5,0.2,2-0.8,1.3,1c-1.1,0.5-2.8-0.5-3.3,1.3
				c-0.3,1.5,2.2,1.1,2,2.8c-0.9,0.5-2.5-1.4-2.8,0.7c-0.2,1.4,0.3,2.6,2.1,2.6c-0.1,0.4-0.2,0.8-0.4,1.2c-0.6,1.3-0.4,2.4,1.1,2.7
				c1.8,0.4,1.9-0.9,1.9-2.2c0-0.7,0.1-1.5,0.2-2.2c0.4-0.1,0.8-0.3,1.1-0.4c0.3,0.3,0.6,0.6,0.9,0.9c-0.5,0.9-1.4,1.8-0.4,2.9
				c0.7,0.8,1.4,0.1,2.1-0.2c0.7-0.3,1.5-0.8,1.4-1.6c-0.3-1.4-1.6-1.2-2.6-1.2c2.1-1.6,2.2-3.3,0.4-5.2c0.5-1.4,1.5-1.2,2.6-0.8
				c0.1,1.5-1.6,2.6,0.6,4.6c1.8,1.6,2.6,1.4,4.3,1.1c0.9-0.2,2-0.1,2-1.2c0-1.3-1.2-1.3-2.2-1.3c-1.9,0.1-2.6-0.6-2.3-2.5
				c0.1-0.8-0.1-1.7-0.1-2.6C192.8,2028.4,194,2028.2,195.3,2028.1z M137.5,1995.6c2.3,0.3,2.4-2.3,4.1-2.9
				C136.7,1991.6,136.7,1991.7,137.5,1995.6c-1,0-2.2,0-2.4,1.4c-0.1,0.6,0.5,0.7,0.9,0.7C137.5,1997.8,137.8,1996.8,137.5,1995.6z
				 M139.1,2110.1c-2.4-2.3-5.8-2.8-8.5-4.6c-1.6-1.1-4.1-2.2-5.6-1.7c-3.4,1.2-6.4,3.6-8.2,7c-0.9,0.1-1.3,0.7-1.8,1.3
				c-0.6,0.8-2.3,1.1-1.6,2.4c0.5,1,1.8,0.5,2.7,0.5c5.1,0,10.2-0.1,15.3-0.2c3.4,0,6.9,0,10.3-0.2c0.9,0,2.2,0,2.4-1.2
				c0.2-1-0.9-1.3-1.6-1.7C141.4,2111.2,140.2,2110.7,139.1,2110.1z M156.3,2079.4c0.2,0,0.4,0,0.6,0c-0.2-1-0.1-2.5-0.8-3
				c-1.2-1-1.6,0.9-2.5,1.3c-3.2,1.3-4.9,4.1-7.2,6.3c-4.7,4.3-9.4,8.4-14.2,12.6c-1.8,1.5-2,2.4,0.5,3.6c6.7,3,13.2,6.2,19.7,9.6
				c2.7,1.4,3,0.5,3.1-2C155.6,2098.3,156,2088.8,156.3,2079.4z M171.4,2010c-0.2,2.4,0.8,2.1,2.5,1.2c6.8-3.8,13.6-7.4,20.4-11.2
				c0.8-0.4,2.4-0.4,1.8-1.8c-0.6-1.4-2-1-3.1-0.4c-2.1,1-4,2.2-6.1,3.1c-4.8,2.2-9,5.4-14.2,6.6
				C171.3,2007.9,171.6,2009,171.4,2010z M134.5,2122.1c1.8,0.1,4.2-3,4.3-4.9c0.1-1.9-1.4-1.3-2.3-1.4c-2.5-0.3-4.8,0.5-5.1,3.1
				C131.2,2120.3,132.4,2122.2,134.5,2122.1z M134.3,2013.9c3-4.7,3-5,0-7.2C133.2,2011.2,133.2,2011.2,134.3,2013.9z M175.7,2036.9
				c0,0-0.1,0-0.1,0c0-0.5,0.1-1.1,0.1-1.6c0-1.2-0.4-2.3-1.7-2.2c-1.2,0.1-1.2,1.3-1.4,2.3c-0.2,1.4,0.9,2.4,0.9,3.7
				c0,0.5,0,1.1,0.8,1.1c0.8,0,1.3-0.5,1.4-1.1C175.8,2038.5,175.7,2037.7,175.7,2036.9z M187,2045.5c-0.1-2.6,3.2-0.2,3.2-2.2
				c0.1-2.6-2.7-0.3-3.9-1.7c-0.8-0.9-1.2,0.8-1.3,1.4C184.8,2044.1,185.7,2044.8,187,2045.5z M175.3,2030.6c-2.2-0.3,1.3-3.8-2-3.8
				c-1.5,0-1.8,1.3-1.8,2.6c-0.1,1.9,1.6,2.4,2.9,2.9C174.9,2032.6,175.3,2031.7,175.3,2030.6z M172.7,2024.5
				c1.2-0.9,3.5-0.7,3.8-2.9c0.1-1-0.8-1.6-1.5-1.2c-1.5,0.8-3,1.8-4.1,3.1C170.5,2024.1,171.8,2024.4,172.7,2024.5z M177.5,2018.5
				c-0.6-1.2-1.4-2.3-2.3-1.7c-1.2,0.8-3,0.7-3.9,2.1c-0.3,0.5,0,1,0.4,1.4c0.5,0.4,1,0.3,1.4-0.2
				C174.2,2018.8,175.8,2018.9,177.5,2018.5z M180.3,2053.6c0-1.1-0.4-1.7-1.4-1.7c-0.9,0-1.4,0.5-1.3,1.4c0.2,0.9,0.4,2.1,1.4,2.2
				C179.9,2055.5,180,2054.2,180.3,2053.6z M179.6,2014.6c-1.7,0.1-1.3,1.2-1.4,2.1c-0.1,1,0,2,1.3,2.1c1.1,0,1.7-0.8,1.4-1.7
				C180.5,2016.2,180.9,2014.8,179.6,2014.6z M175.7,2054.6c-0.1-0.8,0.3-2-0.9-2.4c-0.8-0.3-1.1,0.5-1,1.2c0.3,0.9-0.5,2.4,1,2.7
				C175.8,2056.2,175.7,2055.2,175.7,2054.6z M174.4,1987.5c-0.9-0.1-2-0.6-2,0.8c0,1.2,1.2,1.1,2,1c1,0,2.7,0.5,2.8-0.7
				C177.2,1986.9,175.3,1988,174.4,1987.5z M208.7,2007.4c-0.2-0.8-0.7-1.7-2-1.9c-1.1-0.1-1.1,0.8-1.1,1.6c0,1,0.1,2,1.6,1.8
				C208,2008.9,208.7,2008.8,208.7,2007.4z M212.8,2008.3c0.1-0.9-0.6-1.4-1.3-1.6c-1.1-0.3-1.5,0.5-1.7,1.4
				c-0.2,0.9,0.2,1.5,1.1,1.6C211.8,2009.7,212.5,2009.2,212.8,2008.3z M142.9,1996c-0.8,0.3-1.9-0.8-2.3,0.4
				c-0.1,0.2,0.3,0.9,0.7,1C142.4,1998,142.5,1996.7,142.9,1996z M133.5,1997.2c-0.1,0.2-0.1,0.3-0.2,0.5c0.2,0.1,0.4,0.2,0.5,0.2
				c0.1,0,0.2-0.3,0.2-0.5C133.8,1997.4,133.7,1997.3,133.5,1997.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M130.3,1850.4
				c-0.1-1.3,0.2-2.1,1.8-2c5.6,0.1,11.1,0.1,16.7,0.1c2,0,2.8,0.7,2.7,2.7c-0.1,1.8,0.2,3.7-0.1,5.5c-0.6,3.7,1.2,4.3,4.4,4.2
				c4-0.2,8.1,0,12.1,0c1.6,0.9,3.4,0.7,5.1,0.9c1.6,0.2,2.5-0.5,2.3-2.2c-0.6-4.6,0.7-8,5.6-9.5c0.4-0.1,0.6-0.6,0.9-0.9
				c7.5-1.4,15,0.7,22.4-0.4c4.1-0.6,7.9,1,11.9,0.4c1.7-0.3,3.2-0.2,4.9-1.2c2-1.2,4.9-0.5,7.2-0.4c11,0.5,22.1,0.3,33.1,0.4
				c3.7,0,7.4,0.5,11.1-0.2c1.3,0,2.7,0,4,0c0.6,0.4,1.3,0.4,1.9,0c0.9,0.1,1.8,0.1,2.7,0.2c1.8,1.4,3.9,0.8,5.9,0.5h0
				c0,0.2-0.1,0.6-0.1,0.6c6.3,1.9,4.5,7.3,4.5,11.5c-0.1,5.9-1.1,11.9-1.6,17.8c-0.1,1.4,0.1,2.8-2.1,1.4c-1.9-1.2-4.5-0.8-5.2,1
				c-1.2,2.9-3.3,2.4-5.4,2.5c-1.3,0.1-2.6,0-3.9,0c-0.7,0-1.5,0.2-1.7-1c-0.3-2.9-2.1-2.8-3.7-1.4c-2.7,2.3-5.9,4-9.2,3.4
				c-5.4-0.9-10.9,0.5-16.2-0.6c-2.4-0.5-4.8-0.7-7.3,0.5c-2.2,1.1-4.7,0.4-7.1,0.3c-0.8,0-1.2-0.8-1.3-1.6
				c-0.1-0.9,0.2-2.1-1.3-2.2c-1.1-0.1-1.8,0.4-2.4,1.3c-0.8,1.3-7.4,2-8.4,1c-0.3-0.3-0.5-0.9-0.5-1.4c-0.2-2.5-2-2.9-3.3-1.5
				c-2.9,3-6.1,3.5-9.8,2.5c-0.6-0.2-1.3,0.1-1.9,0.2l0,0c-0.8-3.1-1.9-3.5-4.5-1.8c-1.3-0.2-1.9,0.9-2.7,1.6
				c-1.8,0-3.7,0.1-5.5,0.1c-1.5,0-3,0.2-3.2-2.1c-0.1-1.2-1.3-1.4-2.3-0.9c-1.3,0.7-3.1,1-3.2,3l-0.1-0.1l0.1,0.1
				c-1.8,0-3.7,0-5.5-0.1c-1.8-0.1-3.4,0.3-4.5-2.1c-1-2.1-2.9-1.1-4.4,0.1c-3.4,2.8-8.3,2.9-11.5,0.1c-1.4-1.2-2.9-1.1-4.1,0.1
				c-3.5,3.3-7.7,1.2-11.6,1.8c-0.5,0.1-0.9-0.3-0.9-0.8c0.3-4.6-2.3-2.1-3.6-1.3c-2.1,1.4-4,2.2-6.7,1.6c-1.7-0.4-3.4,0.2-3.4-2.6
				c0-1.3-2.1-2.2-3-0.9c-3.3,4.5-7.9,3.2-12.2,3.3c-1.1,0-2.2,0.2-3.3,0.3c-0.1-0.2-0.3-0.4-0.4-0.6c0.9-0.9,1.9-1.8,0.8-3
				c-1-1.1-2.4-1.7-3.8-0.8c-2.2,1.4-2.9,0.2-2.7-1.7c0.6-8.3-0.8-16.6-0.7-25c0-2.5,0.8-3.5,3.4-3.4c5.3,0.2,10.7,0.2,16.1,0.1
				c1.4,0,2.5,0.6,3.6,1.3c2.8,2.9,5.9,5.6,8.4,8.7c2.2,2.8,4.7,2.8,7.9,2c-3.2-3.4-6.3-6.6-9.3-9.8
				C127.2,1848.4,128.8,1849,130.3,1850.4c0,1.1,0.5,1.7,1.9,1.8C131.9,1850.9,131.3,1850.5,130.3,1850.4z M133.6,1853.6
				c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1,0-0.2,0C133.4,1853.5,133.5,1853.5,133.6,1853.6c0,0.5,0,1,0.7,1.1c0.1,0,0.2-0.1,0.2-0.2
				C134.7,1853.8,134.1,1853.7,133.6,1853.6z M138.2,1857.6c-0.3-1.2-0.9-2.2-2.6-2.3C136.1,1856.8,136.9,1857.5,138.2,1857.6
				c0.2,1.7,0.9,3,2.9,2.7C140.4,1859.1,139.8,1857.8,138.2,1857.6z M271.8,1855.6L271.8,1855.6c2.2-0.7,3-2.8,4.8-4.8
				c-2.3,0-3.9,0-5.6,0c-6-1.3-12.2-0.2-18.2-0.6c-1.6-0.1-1.6,1.1-1.6,2.2c0,3.3,0.1,6.7,0,10c0,0.6,0.4,1.6-1.5,1.9
				c0-3.5-0.2-6.9,0-10.3c0.3-4.9,0.4-4.9-4.3-4.4c-0.6,0.1-1.3,0-2-0.1c-4-0.3-8-0.1-12,0.3c-1,0-2,0.1-3,0.1c-2-0.3-4,0.3-5.9-0.8
				c-1.1-0.6-1.8,0.4-1.8,1.6c0,2,0,3.9,0.1,5.9c0.1,6.5,0.1,6.5,6.7,6.2c1.6,0.6,3.2,1.2,4.9,1.3c1.2,1.3,2.8,1.4,4.5,1.4
				c7.6-0.1,15.1-0.2,22.7-0.4c0.7,0,1.5-0.3,2.2-0.6c2-1.1,4.1-1.6,6.3-1.6c2.5,0,4.3-0.4,2.7-3.5c-0.3-0.5,0.1-1.4,0.2-2.2
				C271.3,1856.7,272.3,1856.5,271.8,1855.6z M207.3,1854.7c0-1.3,0.1-1.8,0-2.1c-0.3-0.9,1.1-2.6-0.7-2.6c-1.1,0-1.4,1.6-1.4,2.8
				c0,2.3-0.1,4.5-0.2,6.8c0,0.6,0,1.1,0.7,1.3c0.9,0.2,1.1-0.4,1.1-1.2C207,1857.7,207.2,1855.8,207.3,1854.7z M289.6,1854.3
				c-2.4,0.4-4.1,0.9-5.2,2.4C286.2,1856.7,287.8,1855.9,289.6,1854.3z M276.3,1861.1c0.6-0.5,0.5-0.7,0.3-0.7
				c-0.4,0-0.7,0.3-1.1,0.5c-0.1,0,0,0.3,0.1,0.5C275.9,1861.2,276.3,1861.1,276.3,1861.1z M280.8,1858.6c-0.1-0.1-0.1-0.2-0.2-0.2
				c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.2C280.6,1858.7,280.7,1858.6,280.8,1858.6z M281.9,1858.1
				c0.1-0.1,0.2-0.1,0.2-0.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2C281.7,1857.9,281.8,1858,281.9,1858.1z M283.4,1857.3
				c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.2C283.3,1857.4,283.4,1857.3,283.4,1857.3z
				 M278.1,1859.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.1c0.1,0.1,0.1,0.2,0.2,0.2C278,1860,278.1,1859.9,278.1,1859.9z
				 M279.2,1859.3l0.1-0.2l-0.2,0L279.2,1859.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M527.6,1360.1
				c6.7-2.4,6.7-2.4,8.4,4.3c0.6,2.2,1.3,3.6,3.9,3c1-0.2,2.3,0.3,1.3,1.6c-1.6,2.2-0.9,4.9-1.6,7.2c-0.5,1.9-0.2,3.4,2.4,2.3
				c1-0.4,2-0.3,2.5,0.8c0.5,1.2,1.4,2.4,0.7,3.7c-0.6,1-1.4,1.7-2.7,0.7c-1.2-1-2-0.2-2.7,0.7c-0.8,1.1-1,2.1,0.1,3.2
				c0.8,0.8,1.4,1.6,2,2.5c0.4,0.6,1.8,0.9,0.8,2c-0.7,0.7-1.8,0.8-2.4,0.2c-2.1-2-4-0.8-6,0c-0.8,0.3-1.4,1.2-2.2,0.2
				c-0.4-0.5-1.2-0.4-1.4,0.2c-1.4,4.2-5.7,4.5-8.9,5.9c-5,2.3-9,4.4-7.8,10.9c0.3,1.6-0.8,3.4-1.3,5c-0.7,2.3-0.6,4.2,1.5,6.1
				c2.9,2.6,2.9,5.4,0.9,8.7c-0.7,1.1,1.1,5.7,2.9,6.6c1.1,0.6,2.1,1.5,3.6,1.4c1.2-0.1,2.2,0.7,2.5,1.8c1.6,4.4,5.1,6.8,9.1,8.7
				c2.3,1.1,4.6,2.2,3,5.7c-1,2,2,2.9,2.1,4.8c1.5-0.1,2-1.3,2.7-2.5c1.3-2.2,3.6-3,5.6-1.6c2,1.4,4.3,1.9,6.3,3.1
				c0.9,0.6,3,1.6,2.4-1.4c-0.4-2.1,1.4-2.9,2.9-3.3c7.7-2.2,11.1-8.1,14.3-14.8c3.4-6.9,3.7-14.2,4.7-21.4c0.4-3,0-6.2-1.2-9
				c-1.2-2.8-2.5-5.6-3.3-8.5c-0.6-2.1-1.7-3.1-4.1-2.6c-1.7,0.3-3.4,0.1-5.2,0.1c0.7-2.7-1.2-7.1,4.2-5.5c1.2,0.6,2.3,1.3,3.1,2.5
				c0.6,2.4,2.7,2.6,4.6,3.1c0.5,0.1,1.7-0.6,1.6,0.1c-0.4,3.7,5.2,5.3,3.1,9.5c-0.3,0.6,0.2,1.7,0.5,2.5c0.7,1.8,1.1,3.4-0.4,5.1
				c-0.6,0.7-1.2,2.2,0.2,3.2c1.3,0.9,2.7,2.1,4,0.7c1.1-1.2,2.1-0.8,2.9-0.2c1.3,1,2.7,1.8,4,2.8c1,0.7,1.8,2,0.8,3.4
				c-0.9,1.3-1.6,2.9-3.7,2.6c-3.5-0.4-4,0.3-4.1,3.8c-0.1,1.5,1.4,4.1-1.8,4.5c-0.2,0-0.4,0.6-0.4,0.9c0.3,5.5-5,8.8-5.4,14
				c-0.1,1.1-1.5,1.2-2.2,1.8c-1.8,1.6-1.5,2.8,0.8,3.6c0.6,0.2,1.3,0.4,1.8,0.7c2.5,1.3,2.9,3.5,2.1,5.9c-0.4,1.2-1.4,1.7-3.1,1.2
				c-2.9-0.8-4.6-2.1-4.1-5.3c0.1-0.8,0.6-1.6,1.1-2.8c-3.2,0.7-4.4,3.4-6.6,4.9c-9.8,6.7-19.9,6.7-30.6,3
				c-7.8-2.7-13.6-7.9-18.9-13.7c-0.4-0.4-0.7-0.8-1.3-0.7l0,0.1c-0.8-3.1-3.2-5.3-4.6-8.1c-1.6-3.2-3.4-6.4-5.1-9.6
				c-1.1,1-0.6,3.4-3,3.5c-2,0-3.9,0.3-4.8-2.2c-0.7-1.9-0.9-3.7,0.8-5.1c2-1.7,4-0.3,5.8,0.7c0.2,0.1,0.3,0.3,0.5,0.4c0,0,0,0,0,0
				c-2.9-10.4-3.8-20.8-1.5-31.9c-3.7,1.2-5,3.8-6.2,6.4c-0.5,1-0.3,2.1,1.5,2.6c2.6,0.7,3.6,3.1,2.4,5.4c-1.1,2.2-2.9,3.2-5.4,1.7
				c-1.8-1.1-1.7-0.3-1.8,1.4c-0.6,10.3,0.1,20.5-0.1,30.8c0,3.5-0.3,7-0.9,10.7c-0.5-2.9-1.3-5.8-1.5-8.8c-0.4-5-0.8-5.4-5.4-4.1
				c-0.4,0.1-0.9,0.2-1.3,0.1c-2.8-0.5-2.8-0.5-4.3,1.8c0.9-2.9-0.7-4-2.9-5.4c-2.9-1.8-5.1-4.3-8.8-5.1c-3.2-0.7-5.1-6.3-3.8-9.5
				c0.3-0.8,0.7-1.6,0.3-2.5c-0.5-1,0.1-1.9,0.9-2.1c4.2-1.5,3.1-2.6-0.1-3.8c-0.6-0.2-1.3-1-1-1.9c0.3-0.9,1-0.4,1.6-0.4
				c2.9,0,1.4-2.5,1.7-3.7c0.5-2-0.9-5.2,3.3-2.6c0.9,0.6,1.9-0.7,2.9-1c3.2-1.1,5.3,2.2,8.2,1.7c0.8-0.1,1.4,0.9,1.8,1.7
				c0.8,1.5,1.2,3.2,3.2,3.9c1.1,0.4,0.3,2,0.8,3c-2.3,0.9-0.9,2.9-1.4,4.4c-0.5,1.5-0.1,2.9,1.8,3.1c2.3,0.3,2.1-1.8,2.1-2.8
				c0-5,0.4-10-0.6-15c-0.8-3.9,0.2-7.8,0-11.6c-0.1-2-0.6-3.6-2.8-3.7c-6.3-0.3-12-2.9-17.7-5.2c-3.5-3.3-7.8-5.4-12-7.7
				c-0.5-2.3,1.2-1.8,2.4-1.9c10.5-1.4,20.8-3.5,31.1-5.7c0.3-0.1,0.6-0.3,0.8-0.5c3.2-0.7,6.5-1.5,9.7-2.2c0.6,0.2,1.1,0,1.5-0.5
				c0.4-0.1,0.8-0.1,1.2-0.2c0.6,0.3,1.1,0.1,1.5-0.4c1.7-0.3,3.4-0.7,5.1-1c4.7,2.9,9.2,6.8,15.4,3.6c1.6,0.5,2.3,0.1,1.9-1.7
				C528.6,1364.6,528.1,1362.4,527.6,1360.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.7,1341.2
				c0,1.7-0.1,3,0,4.3c0.1,2.1-0.9,3.4-2,5.1c-6.5,9.5-17.2,8.7-26.5,11.4c-0.1,0-0.2,0.1-0.3,0c-4.6-2.1-8.7-1.6-12.2,2.3
				c-0.1,0.1-0.5,0.3-0.6,0.2c-3-3.3-7.1-0.4-10.7-2.1c-4.6-2.1-7.6-5.2-9.4-9.4c-1.2-3-4-2-5.9-3.2c-1.2-0.7-2.9,0.6-3.8,1.9
				c-0.6,0.9-1.4,1.7,0.3,2.8c2.6,1.7,0.5,4.6,0.9,6.9c0.1,0.6-0.1,1.3-0.4,1.9c-0.7,1.5,0,4.2-3,3.8c-2.7-0.4-4-2.6-4.3-5
				c-0.7-4.6-2.1-8.9-3.2-13.4c-1.1-4.5-2.1-9.1-2.2-13.7c-0.1-2.8-1.5-5.2-2.5-7.1c-2.3-4.2-1-8.2-1-12.3c0-3,2.9-2.7,4.6-3.1
				c8.5-1.7,17-3,25.5-4.6c12.1-2.2,23.8-5.8,35.6-8.9c3.7-1,7.4-0.7,11.1-0.4c1.8,0.1,3.1,1.4,3.5,3.7c1.1,5.5,1.2,11.2,2.9,16.6
				c0.5,1.7-0.2,3.6,0.3,5.4c0.9,3,1.4,6.2,2.6,9.1C543,1336.3,542.4,1339,542.7,1341.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M946.2,1737.5
				c-0.8,3.4-0.2,7.9,0.7,11.7c0.8,3.3-0.2,6.4,0.9,9.8c1.2,3.3,0.2,7.3,0,11c-0.1,1.1-0.2,2.1,0.1,3.2c1,4.2,1,8.6,0.9,12.9
				c0,4.9,0.5,9.7,1.1,14.6c0.4,3.3-1.3,6.3-1.2,9.6c0,1.7-0.1,3.4,0.4,5c0.2,0.7,0.5,1.5,0.4,2.2c-0.8,9.2,0.8,18.3,0.7,27.4
				c-0.1,8.6,0.2,17,1.7,25.5c1,5.5,1.3,11.4-0.2,17.1c-0.6,2.1,0.7,4,0.9,5.9c1.6,11.1-0.2,22.1,0.1,33.1c0.1,2.5,0.7,4.8,1,7.2
				c1.3,9.2,0.4,18.5,2.3,27.7c0.5,2.6,0,5.8,0,8.7c0,6-0.3,12.1,0.4,17.8c1,8.2,1.2,16.4,2.9,24.6c0.5,2.4,0.6,5.1,0.5,7.6
				c-0.3,6.2,0.5,12.3,1.1,18.4c-3.1,3.4,0.5,6.8,0.2,10.3c-0.3,3.1,0.8,6.8,1.1,10.2c0.6,6.5,1.2,13.1,1.4,19.7
				c0.2,4.6,0,9.2,0.1,13.8c0.1,3.5,1,7.3-1.2,10.6c-0.7,1-0.6,2.8-2.5,2.4c-1.6-0.4-3.7-0.5-4-2.7c-0.3-2.4-2.3-4.4-3.6-5.3
				c-2.4-1.7-2.3-4.2-2-5.7c0.7-3.6-0.9-6.8-0.8-10.2c0.3-6.5-0.3-13-0.1-19.6c0-1.5-0.5-2.6-0.6-3.9c-0.3-4.3-0.5-8.6,0.4-12.9
				c0.6-2.6,1.7-5.2-0.9-7.5c-0.4-0.4-0.5-1.5-0.4-2.2c0.6-3.3-0.1-6.7,0.2-10c0.2-2.7-0.7-5.3-0.4-8.2c0.3-4.5,1.4-9.1,0.8-13.6
				c-0.7-5.4,0.2-10.8-0.4-16c-0.3-2.4,1.8-5.6-1.2-7.9c-0.7-0.5,0.2-2.3,0.9-3.3c1.2-1.9,1.2-3.7,0.1-5.5c-0.3-0.6-0.8-1.3-0.4-1.8
				c2.3-2.4-0.7-5.6,1.5-8c0.2-0.2-0.1-0.8-0.3-1.2c-2.6-4.3-2.2-8.9-1.6-13.6c0.4-3.3-0.3-6.6-0.1-9.9c0.3-4.3-2.3-8.3-1.2-12.6
				c0.8-2.9-0.9-5.2-1-7.9c-0.1-3-0.8-5.9-1.4-8.9c-0.5-2.3-0.7-5.1-0.5-7.6c0.2-2.2,0.4-4.3-0.3-6.4c-1.2-3.6,1.2-7.1,0.3-10.8
				c-0.1-0.4,0.4-1,0.2-1.2c-2.3-4.1-0.7-8.5-1.1-12.7c-0.1-0.8,0-1.7-0.6-2.1c-4-2.7-2.6-6.3-1.8-9.8c0.5-2.3,0.8-4.3-1-6.4
				c-0.9-1.1-1.5-2.7-0.9-4.3c0.5-1.4,0.4-2.5-0.5-3.6c-0.3-0.3-0.4-0.9-0.3-1.2c1.7-4.8-1.8-9.3-1.1-13.7
				c1.1-6.7-1.2-13.2-0.3-19.8c0.4-2.7-1-5.2-0.2-8c1.6-5.6-0.8-11.1-0.9-16.7c0-1.8-0.2-3.5,0.7-5.4c1.2-2.5-0.2-5.3-0.7-7.8
				c-1.3-5.9-0.9-11.7-0.8-17.6c0-1.6-0.1-3.4-0.8-4.8c-1.8-3.3-0.6-6.3,0.3-9.4c0.7-2.5,0-5,0.2-7.6c0.3-3.3,2.2-5.8,3.6-8.5
				c0.6-1.1,2.2-0.7,3,0.5c1.2,1.8,2.7,3.4,4.6,4.5c1.4,0.9,1.5,2.3,1.4,3.7C946.2,1732.7,946.2,1734.7,946.2,1737.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M307.3,1957.4
				c-0.6,3.1,0.4,5.9,1.9,8.6c0.1,0.2,0.4,0.4,0.3,0.6c-1.5,5.2,1.2,10.2,0.5,15.4c-0.7,5,0.1,10.2,0.1,15.2
				c0,5.7,0.6,11.5-0.2,17.2c-0.4,3.4-0.2,6.8,0.6,10.2c0.2,0.7,0.4,1.4,0.1,2.2c-0.9,2-0.2,4.2-0.5,5.9c-0.8,4.4,0.9,8.5,0.7,12.8
				c-0.2,4.1-0.3,8.5,0.5,12.7c0.1,0.6-0.1,1.2-0.2,1.9c-0.2,3.6-0.5,7-0.4,10.7c0.2,7,0.9,14.1-0.8,21.3c-0.8,3.6-1,8.3,1.9,11.9
				c0.4,0.5,0.5,1.1,0.1,1.4c-2.4,1.8-1.1,4-0.7,6c0.7,3.8,0.9,7.6-0.2,11.3c-0.8,2.8-1,5.7-0.4,8.4c0.4,1.8-0.5,2.6-1.6,3
				c-2.7,1-5.5,1.8-8.6,1.3c-2.8-0.4-5.7-0.3-8.5-0.3c-1.4,0-2.4,0.1-2-2.1c1.1-6.9-0.3-13.8-0.7-20.7c-0.2-3.5,0.8-7-0.4-10.5
				c-1.2-3.6-1.3-7.6-1.7-11.4c-0.2-2-0.2-3.6,0.7-5.2c0.2-0.4,0.3-1.1,0.1-1.6c-2.5-6.3-0.7-12.9-1.3-19.3
				c-0.3-2.7,0.5-5.1,0.9-7.6c0.2-1.4,0.3-2.5-0.7-3.9c-1.3-1.7-1.1-4-0.2-5.9c1.1-2.4,1.2-4.6,0.2-6.8c-0.4-0.8-0.5-1.4,0.3-1.9
				c0.6-0.4,1.2-1,0.7-1.6c-2.5-2.8,0-5.6-0.1-8.5c-0.3-5.6,0.8-11.3-0.6-16.9c-0.2-0.9-0.2-1.9-0.3-2.9c-0.2-6.2-0.6-12.5-0.2-18.7
				c0.1-2.1-0.8-4.9,1.8-6.6c-1.5-3.2,1.4-5.1,2.2-7.6c0.3-1.1-0.7-2.2-0.3-3.8c0.9-3.9,4.6-4.2,6.9-6.3c2-1.8,3.2-3.5,3.6-6.1
				c0.4-2.5,0.9-5,3-6.9c0.7-0.6,1.3-1.2,2.2-0.9c1.2,0.4,1.1,1.4,1.2,2.4C307.2,1955,306.5,1956.1,307.3,1957.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M877.2,1916.8
				c-0.1-2.3,0.5-4.6-0.6-6.8c1-1,2.2-0.8,3.4-0.7c9.5,1.1,19,1.3,28.6,0.6c3.3-0.2,7-1.4,9,3.2c0-4.5-0.1-8.3,0-12.1
				c0.1-2.2-0.9-2.6-2.8-2.3c-4.2,0.8-8.6,0.5-12.7,0.2c-7.6-0.6-15.1-0.1-22.6-0.5c-4.8-0.3-4.8,0-5.4-4.7
				c-0.3-2.2-0.5-4.3-0.8-6.5c-0.1-0.9-0.4-1.9,0.5-2.4c2.7-1.8,2.1-4.5,1.9-7c0.7-6.3-0.6-12.6-0.6-18.9
				c-0.1-9.2-0.7-18.5-1.1-27.7c-0.6-14.3-1.2-28.7-1.7-43c-0.5-11.7-0.9-23.4-1.3-35c0-0.2-0.1-0.5,0-0.6c1.6-2,0.3-3.9-0.4-5.7
				c-1-10.4-0.3-20.8-1.8-31.2c-0.2-1.5-0.4-3.2,0.6-4.7c1.1-3.6,3.8-2.1,6.1-2c-0.5,4.7,0.1,9.3,0.4,13.9c0.6,9.7,0.8,19.3,1.3,29
				c0,0.8-0.3,1.8,0.7,2.3c0.7,0.3,1-0.3,1.1-0.8c0.1-1,1.9-1.9,0.6-3c-1.6-1.3-1-2.6-0.7-4c0.9-0.4,1.7-0.7,2.6-1.1
				c0.4,0.1,0.7,0.2,1.1,0.4c-0.4,1.5-0.4,2.8,1.7,2.8c0.2,0.7,0.5,1.2,1.3,1.4c0.1,1,0.5,1.9,1.5,2.3c0.5,0.5,0.7,1.4,1.7,1.4
				c0.7,0,1.8-0.3,1.6,1c-0.1,0.9-1,0.6-1.6,0.6c-2.9,0.3-5.8,0.4-8.7,0.8c-1.9,0.3-2.6,0.9-2.5,2.8c0.4,8.7,0.7,17.3,1,26
				c0.9,24.1,1.9,48.3,2.9,72.4c0.2,5.7,0.5,11.3,0.4,17c0,1.1,0.6,2.9,1.5,2c1-1,2.5-3.8,1.6-4.6c-2.1-1.8-0.9-3.9-1.4-5.7
				c-0.6-2.2,0.6-2.7,2.5-2.4l-0.1-0.1c0.5,1.9,2.7,4.6,3.8,6.2c1.7,2.6,3.3,5.2,5.8,7.3c-1.3,1-2.5,0.6-3.7,0.7
				c-9.4,0.2-9.4,0.2-9.5,9.5c0,1-0.5,2.5,1.2,2.5c1.7,0,1.8-1.7,1.5-2.7c-1-3.4,0.4-3.3,2.9-1.8c0.1-2.5-3.4-3.1-2.6-4.8
				c1-2.2,3.4,0.1,5.2-0.3c2.3-0.6,3.4,0.5,2.2,2.8c-1.1,2.3,1.8,4.2,0.6,6.5c-0.2,0.3,1,0.5,1.7,0.6c3.6,0.6,7.2-0.1,10.8-0.3
				c0.9,0,1.7,0,2.6,0c2,1.1,3.1,1,2.7-1.7c-0.2-1.2,0-2.4,0-3.6c0.1-6.9,0.1-6.8-6.9-6.6c-2,0-4.5,0-5.8-1.2
				c-1-0.9,1.2-2.9,1.7-4.5c1.2-1.6,2.4-3.1,3.8-0.1c0.4,0.8,1.1,1.6,2.3,1.4c0.7,1.2,1.4,2.6,3.2,2c1.7-0.6,1-2,0.9-3.1
				c-1.2-17-1.6-34.1-2.7-51.2c-1.1-17.2-1.5-34.5-2.2-51.7c-0.2-4.7-0.1-4.7,4.1-3.5c0.4,0.1,0.9,0,1.1,0.6
				c-0.2,0.9-1.6,1.1-1.6,2.2c3.4,0,3.9-0.6,3.3-3.7c-0.5-2.7-2.8-2.1-4.1-1.7c-2.8,1-3.1,0.1-3.1-2.5c0.1-5.7,0-5.7-5.6-5.6
				c-0.9,0-1.8,0-2.6,0c-4.9,0-4.9-0.1-2.9-4.6c0.1-0.2,0.2-0.4,0.4-0.6c0.9-0.6,1.5-1.4,1.9-2.3c1.7-0.3,2.3-1.2,1.5-3
				c-1,0.7-1.7,1.4-2,2.4c-0.7,0.3-1.4,0.6-2.2,1c0-3.5,0.1-7.2-0.4-10.6c-0.5-3.5,0-6.2,1.6-9.1l-0.1,0.1c2.8-1.6,4.2-4.2,4.6-7.3
				l0,0.1c2-0.2,3.3,0,2.8,2.6c-0.2,1.2,0.2,2.6,0.4,3.9c0.1,0.7,0.7,1.7-0.4,2c-2.6,0.6-0.7,2.8-0.6,2.9c2.2,1.8,1.1,3.8,0.4,5.8
				c-0.2,0.7-1.2,1.3-0.5,2c2.9,3.1,1.5,6.7,1.4,10.2c0,0.6-0.6,1.3,0,1.9c0.3,0.4,0.7,0.4,1.2,0.3c1-0.4,0.7-1.3,0.6-2
				c-0.2-5.6-0.4-11.3-0.8-16.9c-0.1-1.8,0.3-3.2,1.9-4.1c0.7,0.1,1.3,0.1,1.5-0.8l0.1,0c0.5,0.1,0.8,0,0.6-0.6l0.1-0.1
				c0.5,0.1,0.9,0.1,0.6-0.6l0.1-0.1c2.7-0.3,3.2,0.9,3.4,3.5c0.8,11.7,0.5,23.5,2.4,35.2c0.9,5.7,0.1,11.5,0.3,17.3
				c0.4,13.9,0.9,27.8,1.4,41.7c0.1,1.6,0.3,3.2,0.5,4.9c0,0.3,0,0.7,0.2,1c1.6,2.3,1.6,2.6,0.9,5.8c-0.3,1.5,0.8,2.7,1,4.2
				c0.8,6.4,0.2,12.9,1.5,19.2c0.4,1.8,0.1,3.9-0.5,5.8c-1.4,4.7,0,9.4-0.1,14.1c0,1.4-0.2,2.9-0.1,4.4c0.4,4.8,3.1,9.2,2.3,14.3
				c-0.6,3.9,1.4,7.9-0.1,11.8c-0.1,0.3-0.1,0.7,0,1c2.8,7.3-0.6,14.8,1,22.2c0.3,1.3-1,2.8,0.6,4.3c0.4,0.4-1.1,3-3,3.6
				c-2.2,0.7-4.3,0.3-6.5,0.5c-0.3-0.7-1.8-0.8-1.2-2c0.4-0.8,1.4-0.5,2.1-0.5c0.6,0,1.3,0,2,0.1c2.4,0.5,3.3-0.2,3.1-2.8
				c-0.5-6.4-0.9-12.8-1.1-19.2c-0.1-2.1-0.9-2.4-2.6-2.2c-5.6,0.6-11.2,0.9-16.9,0.8C891.6,1916.8,884.4,1916.9,877.2,1916.8z
				 M914.9,1872.4c0-0.9,0-1.7-0.1-2.6c-0.1-0.8,0.3-1.9-0.8-2.2c-0.4-0.1-1.2,0-1.5,0.3c-0.7,0.8,0.1,1.6,0.3,2.4
				c0.8,2.8,1.6,6,0.6,8.4c-1.7,3.6,0.2,6.8,0,10.1c-0.1,1.1,1,1.3,2,1.2c0.7-0.1,1-0.2,0.8-1.2
				C915.2,1883.3,915,1877.8,914.9,1872.4z M909.7,1772.2c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.2,0.2,0.3
				C909.5,1772.3,909.6,1772.2,909.7,1772.2z M881.6,1749.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3,0.1-0.3,0.3,0,0.4c0,0,0.2,0,0.2-0.1
				C881.6,1749.3,881.6,1749.2,881.6,1749.1z M873.8,1749.2c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0.1-0.2,0.2
				c0.1,0.1,0.2,0.1,0.3,0.2C873.7,1749.3,873.8,1749.2,873.8,1749.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M726.1,1783.2
				c1,0.1,2,0.2,3,0.3c0,0.1,0.1,0.3,0,0.3c-5.3,2.4-3.1,7.2-2.7,10.7c0.7,5.5,0.7,10.9,0.6,16.3c-0.1,3.9,0.7,7.9,0.4,11.7
				c-0.3,4.3,0.2,8.7,0.9,12.7c0.9,5.4,0.4,10.8,1.1,16.2c0.2,1.7-0.4,3.2-0.3,4.9c0.5,8.6,0.4,17.2,0.8,25.9
				c0.4,7.2-0.2,14.5,1.1,21.8c0.4,2-0.3,4.3-0.5,6.4c-0.4,3.3,1.6,6.1,1.2,9.5c-0.5,5.1,0.2,10.2-0.1,15.4
				c-0.1,2.2,0.3,4.3,0.4,6.4c0.5,7.6,0.3,15.2,0.8,22.8c0.5,7.4,0.1,15.1,0.9,22.5c0.5,4.9,0.3,9.8,0.8,14.7
				c0.6,6.1,0.6,12.3,0.8,18.4c0.1,3.2,0.3,6.4,0.6,9.6c0.2,2-0.5,3.2-2.7,2.9c-4.4-0.5-8.6,0.6-12.9,0.7c-3.3,0.1-3.5-2-3.2-4.3
				c0.5-3.1,0.8-6.2-0.1-9.4c-0.4-1.6,0.6-2.9,0.5-4.5c-0.4-9.8-0.8-19.7-1.1-29.5c-0.2-6.3-0.1-12.7-0.3-19
				c-0.1-4.8-0.5-9.6-0.8-14.4c0-0.5-0.2-1.1,0-1.6c1.9-6.7-0.1-13.4,0.2-20c0.2-5.3,0-10.7-0.1-16c0-1.8-0.5-3.6-0.5-5.5
				c-0.1-7.5,0.5-15-0.2-22.5c-0.6-7.1,0.2-14.2-1.7-21.2c-0.5-1.8,0.5-3.8,0.5-5.8c0-5.2-0.1-10.3-0.3-15.5c0-0.8-0.1-1.6,0.3-2.2
				c2.1-2.8,1.7-6.2,1.1-9.1c-1.4-6.3,0.9-12.2,1.1-18.3c0.1-3.2-1.9-6.4-0.2-9.5c3.1-5.7,3.7-12.2,6.5-18
				C722.9,1784.9,725,1784.7,726.1,1783.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M195.4,1440.9
				c0-8.5,0-17,0-25.5c0-3.3,0.9-5.6,4.6-6.4c3-0.7,6-1.7,8.6-3.1c2.2-1.2,5.4,0.8,5.6,0c0.6-2.8,2.7-2.8,4.6-3.5
				c5.7-2.1,11.5-4.2,17.3-6.2c3.8-1.3,5.3-0.1,5.2,3.8c-0.7,25.8-0.6,51.6-0.6,77.5c0,4.7-0.3,5.2-4.6,6.5
				c-5.6,1.6-11.1,3.2-16.7,4.8c-1,0.3-2.2,0.3-3.2,0.5c-1.5,0.3-2.1,0.2-2.3-1.9c-0.5-5.7,0-11.3,0.1-17c0-2.6-0.5-3.4-3.2-2.5
				c-3.1,1.2-6.2,2-9.3,3.1c-1.5,0.5-2.9,0.6-4.5,0.6c-1.4-0.1-1.6-0.3-1.7-1.8C194.5,1460.3,195.3,1450.6,195.4,1440.9z
				 M201.2,1411.3c2.1,0.2,3.8-0.3,5.6-2.3C204.2,1409,202.6,1410,201.2,1411.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M87.2,1414.7
				c1.5-0.7,3.2-0.8,4.4-2.1c6.2-1.5,11.8-4.4,17.7-6.6c11.8-4.3,23.5-8.8,35.3-13.3c12.8-4.9,25.4-9.9,38.2-14.8
				c13.6-5.2,27.3-10.4,41-15.6c8.5-3.2,16.9-6.6,25.3-9.9c2.9-1.1,5.9-2.1,9-1.1c8.9,2.7,18.2,3.5,27.1,5.7c5,1.2,10,2.4,15.1,2.9
				c8.3,0.9,16,4,24.2,5.1c2.6,0.3,5.2,1.1,7.8,1.3c8.8,0.7,17.2,3.8,26.1,3.5c0.7,0,1.6,0.2,2.2,0.6c2.5,1.5,5.4,1.5,8.1,2.4
				c6.2,2,11.8,0.9,17.7-1.6c5.9-2.5,12.2-4.1,18.5-5.4c1-0.2,2.3-1.5,3.1,0.3c0.7,1.5,0.6,3.2-0.8,4.2c-2.5,1.8-3.3,2.8,0.7,3.3
				c2.2,0.3,2.2,2.7,4.1,4.1c-5.2,1.1-8.2,4.4-11.3,7.8c-1.6,1.8-3.4,3-6.1,2.9c-6.1-0.1-11.9,1.7-17.6,3.6
				c-3.2,1.1-3.6,0.9-3.6-2.5c-0.2-7.2-0.2-7.3,6.5-10.1c4.6-1.9,9.4-3.4,14.1-5.2c1.2-0.5,3.3-0.7,2.7-2.6
				c-0.6-1.8-2.4-2.1-4.1-1.5c-6.3,2.2-13.2,3.4-17.9,8.8c-0.5,0.6-1.2,0.9-2,1c-2.2,0.5-2.9,1.9-3,4c-0.1,5.1-1.2,6-6.2,5.6
				c-1.5-0.1-3.2,0.4-4.9-0.8c1.9-1.4,3.6-0.8,5.4-0.5c1.7,0.3,3.2,0,1.9-2.1c-0.8-1.4,0.9-3.6-1.8-4.3c-0.4-0.1-0.3-1.4,0.6-1.9
				c0.7-0.4,1.7-0.6,2.1-1.6c-2.9-3-7-2.9-10.6-3.8c-1.4-0.3-2.9-0.7-4.2-0.4c-5.5,1.3-9.3-3.3-14.5-4c-8.9-1.1-17.6-3.6-26.4-5.6
				c-7.6-1.7-15.3-2.6-22.8-4.2c-8.6-1.9-17.3-3.1-25.9-5.3c-2.9-0.7-3.2,0-3.2,2.8c0.2,16.7,0.1,33.4,0.1,50.1
				c0,33.8,0,67.6,0.1,101.4c0,29.1,0.1,58.2,0,87.3c0,0.8,1,2.5-0.9,2.5c-1.7,0-1.2-1.5-1.2-2.5c0-26.1-0.1-52.1-0.1-78.2
				c0-43.2,0.1-86.4,0.2-129.5c0-9.6-0.1-19.2,0.1-28.8c0-2.9-1-3.9-3.9-3.5c-3.9,0.6-7.3,2.3-10.9,3.6c-24.4,9-48.7,18.2-73.1,27.2
				c-1.9,0.7-2.8,1.5-2.8,3.7c-0.2,31.7-0.5,63.4-0.8,95.2c0,0.6-0.2,1.3,0.4,1.9c1.2,0.1,1.3-1.2,2.1-1.7c2.1-1.3,3.4-0.7,3.7,1.8
				c0.3,2.4,1.1,4.8,0.1,7.3c-0.8,2.1-2.1,3.3-4.2,2.6c-2.2-0.7-2.2,0.5-2.2,1.9c-0.1,3.3-0.4,6.6-0.1,9.8c0.2,2.5,2.8,3.5,4.3,5.1
				c1,1.1,1.7,3.9,0.5,4.8c-1,0.7-2,1-2.3-0.8c-0.1-0.9,0.1-2.3-1.3-2.2c-1.5,0.1-0.9,1.5-1,2.3c-1.4,11.2-0.8,22.4-0.9,33.6
				c-0.2,21.2-0.1,42.3-0.2,63.5c0,1.1-0.1,2.3-0.2,3.8c-1.8-1.4-0.7-2.7-0.7-3.8c-0.1-9.5,0.1-19,0.1-28.5c0-15.3,0.1-30.7,0.1-46
				c0-7.2,0-14.5-0.3-21.7c-0.1-2.1,0.1-4.6-2.3-6.1c-1.1-0.7-0.5-2.2-0.5-3.4c-0.1-2-0.1-3.9,0-5.9c0.1-1.9,0.1-3.6-2.8-2.9
				c-1.5,0.4-2.3-0.6-2.3-2c-0.1-1.2-0.4-2.3-1.8-2.2c-1.7,0.2-1.1,1.6-1.2,2.6c-0.2,4.2-0.3,8.5-0.5,12.7c-0.1,1.9,0.2,3,2.5,3.3
				c1.8,0.3,3.1,2.2,5,3c0.9,0.4,0,2.2-0.1,3.4c0,0.8-0.4,1.5-1.4,1.4c-0.8-0.1-1.1-0.7-1.1-1.4c0.1-2-1.2-2.5-2.9-2.7
				c-2.9-0.4-4.3-2.5-4.2-5.4c0.1-5.7,0.1-11.3,0.2-17c0.1-2.6-0.2-5.8,4.2-4.8c0.9,0.2,1.3-0.6,1.6-1.3c0.7-1.4,2-2,3.5-1.9
				c2.2,0.3,2.9-0.9,3.1-2.8c0.1-1.3,0.5-2.6,0.4-3.9c-0.4-8.3,0.3-16.5,0.2-24.8c-0.2-17.2,0.5-34.4,0.8-51.6
				c0.1-4.4-0.3-8.9-0.3-13.3c0-2.6-1.1-2.9-3.3-2c-12.3,4.6-24.6,9.2-36.9,13.7c-10.1,3.8-20.3,7.4-30.5,11.2
				c-1.8,0.7-3.4,1.2-5.2-0.2c-1-0.8-1.9-0.4-1.7,1.1c0,0-0.3,0-0.3,0c-0.1-0.8-0.6-1-1.4-0.8c0-0.2,0-0.4,0-0.7
				C86,1415.5,86.9,1415.8,87.2,1414.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M405.1,1858.9
				c0.5,0,1.1,0.1,1.6,0.1c6.3,0.1,6.9,0.8,4.5,6.7c-1.1,2.7-0.5,3.8,2.3,4.3c2,0.4,4,1.1,6,1.5c2.3,0.5,3.1-0.4,3.3-2.3
				c0.5-3.1,1-6.2,1.3-9.3c0.2-2.2,1.5-2.5,3.2-2.3c2.2,0.3,1,1.7,0.8,2.7c-0.5,3.1-1.3,6.2-1.5,9.3c0,1-1.1,2.2,0.9,2.4
				c1.5,0.1,2.5-0.3,2.9-1.9c0.3-1.3,0.2-2.6,0.5-3.8c0.4-1.4,1-2,2.3-2.4c-1.4-0.5-3.7-4.9-3.2-6c0.6-1.2,1.9-0.9,2.9-1.2
				c8.4-2.7,8.9-2.3,8.6,6.5c-0.2,5.1-0.1,10.2-0.5,15.2c-0.1,1.8,0.1,5-3.8,2.9c-1.1-0.6-3-0.5-3.6,1.7c-0.4,1.4-1.2,2.6-3.1,1.3
				c-0.8-0.5-2.1-0.3-3.2-0.3c-1,0-2-0.1-2.1-1.5c-0.2-1.5-1.3-1.8-2.6-1.9c-1.5,0-2.4,0.7-2.5,2c-0.1,1.7-1.2,2-2.4,1.9
				c-2.8-0.2-5.9,1.2-8.2-1.7c-0.4-0.6-1.9-0.4-2.4,0.3c-2.2,3-5.2,1-7.7,1.4c-1.2,0.2-1.6-0.8-2-1.7c-1.3-2.6-4.6-3.2-6.3-0.7
				c-2,3-3.9,2.3-5.7,0.3c-1.8-2-3.1-1.9-4.8-0.1c-1.7,1.8-3.9,2.6-6.5,2.3c-1.3-0.2-2.3-0.4-2.9-1.7c-1-2.4-2.8-2.1-4.1-0.6
				c-3.2,3.8-7.3,1.8-11,2.2c-0.8,0.1-1.1-0.6-0.9-1.4c0.2-0.9,0.7-1.7-0.5-2.2c-1-0.4-2.1,0-2.7,0.7c-3.4,3.9-7.4,2.4-11.4,1.4
				c-0.6-0.2-1.3-0.6-1.9-0.4c-5,1.7-9.7,0.2-14.4-1.1c-1.3-0.3-2.2-0.3-3.2,0.8c-1.2,1.3-3.3,1.4-4.1,0.3c-2.7-3.6-5.5-1.4-8.2-0.4
				c-3.2,1.1-5.9,1.4-8.2-1.5c-0.1-0.2-0.3-0.4-0.5-0.4c-6.7-1.3-5.4-7.5-6-11.5c-1-6.3-1.5-12.8-0.3-19.4c0.5-2.5-0.5-5.9-1-8.9
				c-0.2-1.1-0.1-1.7,1.1-1.9c3.7,1.7,6.5,4.6,9.7,6.8c0.1,0.4,0.3,0.6,0.7,0.5c0.3,0.4,0.3,0.9,0,1.3c-2,0.8-4.1,0.4-6.1,0.6
				c-0.8,0.1-2.4-0.8-2.3,1.2c0.1,1.6,1.1,1.4,2.2,1.4c3.2-0.1,6.3-0.2,9.5,0.3c-1.2,3.2,0.2,6.3,0.2,9.5c0,0.5,0.5,1,1.2,0.8
				c0.8-0.2,0.8-0.8,0.8-1.5c-0.1-2.6-0.1-5.2-0.2-7.7c2.7,1,2,3.4,1.8,5.2c-0.4,3.1,0.9,4.3,3.8,4.4c2.6,0.1,5.1,0.3,7.7,0.5
				c1.2,0.3,2.4,0.6,3.6,1c0.2,0.3,0.5,0.4,0.9,0.2c0.4,0.1,0.7,0.2,1.1,0.3c3.4,1.9,6.9,0.5,10.3,0.1c7.7-0.7,15.3-1.8,23-2.7
				c5.1-0.6,10.1-1.6,15.3-0.5c-11.5,1.4-23,2.8-34.6,4.2c2.7,0.9,5.3,2,8,1.5c2.7-0.5,5.3-0.8,8,0.5c0.8,0.4,1.9,0.2,2.9,0.2
				c3.8,0.1,7.6-0.1,11.4,0.7c2.9,0.6,5.9-0.3,8.7-0.9c1.2-0.3,2.3-0.7,1.7-2.4c-0.4-1.2,0.5-1.7,1.6-2c4-1.4,8.1-2.3,12.4-2.4
				c1.7,0,3.4-0.5,5.1-0.7c-0.2,2.8-0.4,5.5-0.6,8.3c0,0.8-0.4,2,0.8,2.1c1,0.1,0.8-1.1,0.9-1.7
				C404.4,1864.6,404.7,1861.7,405.1,1858.9z M434.3,1872.1c2.1,1.5,4.8-3,6.1,1.5c0.1-1.5,0-2.5-0.2-3.6c-0.1-0.8-0.6-1.6-1.5-0.9
				C437.4,1870.2,435.5,1870.4,434.3,1872.1z M433.3,1857.6c1.9,1.3,3.5,2.4,5.2,3.5c0.5,0.4,1.1,0.5,1.4,0c0.3-0.5,0-1.1-0.5-1.4
				C437.6,1858.6,435.9,1857.3,433.3,1857.6z M434.7,1863c1.3,0.6,2.2,0.6,3.4-0.4C436.8,1862.2,435.9,1861.9,434.7,1863z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M664.5,1393.4
				c-0.4-1.3-0.6-2.5,0.1-4c5.5,1.8,11.4,1.9,17,3.4c1.2,0.3,0.9,1,0.6,1.7c-0.7,1.8-1.5,3.6-2.2,5.4c-0.3,0.8-0.9,1.9,0.5,2
				c5.9,0.4,11.4,2.9,17.2,3.9c0.2,0.8,0.8,0.9,1.4,1.1c4.1,1.2,8,3.1,11.3,5.8c4.2,4.2,4.2,9.5,3.6,14.9c-0.3,2.2-1.8,3.3-4.3,2.8
				c-2.2-0.5-4.4-1-6.7-1.5c0.2-1.9-1.5-2-2.6-2.3c-7.4-2.3-15.2-1.9-22.7-3.2c-1.1-0.2-1.5,0.6-1.8,1.5c-1.4,0.5-2.7,0.4-3.9-0.5
				c-2.2-2.8-5-4.6-8.7-4.5c-1.4,0-2.9-0.4-4.2-0.4c-4.2,0.1-8.6-2.3-12.7,0.4c-4.8-0.6-9.6-1.3-14.3-2.5c-1.3-1.6-2.7-1.1-4.2-0.2
				c-0.6-0.1-1.2-0.2-1.8-0.3c-0.5-1.4-1.4-1.2-2.4-0.6c-0.7-0.2-1.5-0.4-2.2-0.5c0.9-3.3-1.8-5.6-2.4-8.5c-0.1-0.7-2.1-1.6-3-1.4
				c-1.1,0.3-0.6,1.8-0.6,2.9c0,0.2,0.1,0.4,0.2,0.6c0.2,0.7,1,1.5-0.3,1.8c-1,0.3-1.3-0.6-1.7-1.2c-2.7-4.4-2.3-6.7,1-10.7
				c3.9-4.7,8.4-7.9,14.9-7.1c1,0.1,1.9-0.2,2.9-1.3c-3.3-0.3-4.1-2.2-4-5.2c0.1-1.7-1.3-3.1-2.9-4.1c-1.3-0.8-1.3-5.7-0.1-6.6
				c0.8-0.6,1.7-0.5,2.5-0.3c1.2,0.4,1.5,1.5,1.8,2.6c0.3,0.9-0.6,1.9-0.1,2.9c-0.2,2.1,1.5,2.4,2.9,3c6.7,2.6,13.8,3.4,20.7,4.5
				c2.9,0.5,4.2,1.5,3.2,4.4c-3.2,1.6-6.8,2.3-10,4c-1.9,1-4.1,0.1-4.2-2.5c-0.1-2-1.4-3.4-1.6-5.2c0-0.5-0.4-1.1-1.1-0.8
				c-0.6,0.2-0.6,0.9-0.4,1.4c0.5,1.7,1.4,3.3,1.5,4.9c0.2,3.6,2.5,4.3,5.1,3.6c3.5-0.8,6.9-0.3,10.4,0.1c2.7,0.4,5.2,1.7,7.9,1.4
				c3.4,0.8,6.7,1.7,10.5,1.9C670.6,1395.5,668.2,1393.8,664.5,1393.4z M634.1,1391.8c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.2
				c0.1,0,0.2,0.1,0.3,0.1C634.1,1392,634.1,1391.9,634.1,1391.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M595.8,2018
				c-2.2-0.8-1-3-0.9-3.9c0.5-3.2-0.6-6.1-0.9-9.1c-1-10.4-0.3-20.8-0.5-31.3c-0.2-7.2,0-14.4-0.4-21.6c-0.7-13.5-1-27-1.2-40.5
				c0-2.2-0.6-4.3,0-6.5c4.2,0.3,8.3-0.6,12.5,0.3c0.3,0.8,0.9,1.4,1.8,1.3c0.8-0.1,1-0.6,0.7-1.3c0.5-1.7-1.6-3.5,0.1-5.3
				c0.9,0,1.9-0.1,2.8-0.1c1.5,0,3,0,4.5,0c3.3,0,3.8,2.6,4.8,4.9c2.8,6.3,2.8,6.3-4.1,6.3c-1.4,0-2.8,0-4.3,0
				c-0.8,0-2.1-0.5-2.1,0.7c0,0.9,1.2,1.2,2,1.2c3.5,0.1,7,0,10.5,0.1c0.6,0,1.4-0.3,1.6,1c-4-0.4-7.9,0.4-11.8,0.3
				c-2.6-0.1-3.2,0.9-3,3.2c0.1,1.7,0,3.5-0.1,5.2c-0.8,0.2-0.6,0.5-0.2,0.9c-0.3,3,0.7,4.3,4,4c4.6-0.4,9.2-0.3,13.8-0.4
				c0.7,0,1.7-0.3,1.9,0.7c0.2,0.9-0.6,1.3-1.3,1.6c-0.9,0.4-1.8,0.7-2.7,1.1c-0.8,0.4-2.8-0.8-2.4,0.9c0.2,0.9-0.9,4.6,2.7,3.1
				c0.3-0.1,1,0.1,1.1,0.4c1.2,2.9,2.1,0.3,3.1-0.1c0.9-0.4,1.2-2.5,2.8-0.9c-1.7,1.9-1.4,4.7-2.7,6.8c-2.4,3.7-4.1,8-5.6,11.9
				c-1.9,5.1-4.3,10.2-6,15.4c-1.3-1.5,0.1-2.9,0.1-4.3c0-1.3-0.4-2.2-1.7-2.3c-1.3-0.2-2.1,0.4-2.3,1.7c-0.1,0.8,0.4,2.1-1,2.3
				c-1.1-8.6,0.1-17.4-2.4-25.9c-0.5,12.6-0.6,25.1-0.4,37.7c0,2.3-1.1,5,1,7.1c-1.8,4.4-3.4,8.9-5.3,13.3
				C601.3,2004.6,599.2,2011.5,595.8,2018z M614.8,1934.7c1.5-0.3,3.9,0.5,3.8-1.5c-0.1-2.1-2.7-0.9-4.1-1.4
				c-1.1-0.3-2.7,0.4-2.7,1.7C611.9,1935.6,613.8,1934.3,614.8,1934.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M508.7,1787.7
				c-0.8-1.7-1.4-3.3-2.2-4.7c-0.3-0.6-0.5-1.7-1.4-1.5c-0.8,0.1-1.3,0.9-1.4,1.6c-0.1,1.3-0.6,2.2-1.9,2.2
				c-1.5-0.1-1.4-1.5-1.6-2.5c-2-8.6-3.9-17.2-5.8-25.8c-1.5-6.9-3.2-13.7-4.8-20.5c-1.3-5.6-2.3-11.3-3.7-16.8
				c-0.6-2.5-0.2-3.8,3-3.6c13.6,0.8,27.3,1.4,40.9,2.1c2.7,0.1,5.3,1.4,8.1,1.2c1.6-0.1,1.7,1,1.1,2.2c-4.6,10.3-9.1,20.7-13.6,31
				c-3.7,8.2-7.4,16.4-11.2,24.6C512.5,1780.6,511.2,1784.4,508.7,1787.7z M512.7,1749.4c0.2-4.5,2.3-8.3,4-12.3
				c1.8-4.2,4.1-8.3,5.2-12.9c0.8-3.1-0.3-4-3.2-4c-1.4,0-2.9,0.1-4.2-0.3c-2.6-0.9-3,0.3-2.9,2.5
				C511.8,1731.4,511.5,1740.4,512.7,1749.4z M517.4,1764.4c0-0.7,0.1-1.3-0.7-1.5c-1.1-0.2-1.7,0.6-2,1.3c-0.5,1.4-0.7,2.8-1.5,4.2
				c-1.2,2.1-2,4.6-2.9,6.9c-0.3,0.8-1,1.9-0.1,2.4c1.2,0.8,1.6-0.7,2.2-1.3C515.5,1773,515.7,1768.3,517.4,1764.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.5,1889.6
				c-3.6,0.2-7.2,0.9-10.8,0.3c-0.6-0.1-1.8-0.2-1.7-0.6c1.2-2.4-1.7-4.2-0.6-6.5c1.1-2.4,0.1-3.4-2.2-2.8c-1.8,0.5-4.2-1.9-5.2,0.3
				c-0.7,1.6,2.8,2.2,2.6,4.8c-2.4-1.5-3.8-1.6-2.9,1.8c0.3,1,0.1,2.7-1.5,2.7c-1.7,0-1.2-1.4-1.2-2.5c0.1-9.3,0.1-9.2,9.5-9.5
				c1.2,0,2.4,0.3,3.7-0.7c-2.5-2.1-4-4.7-5.8-7.3c-1.1-1.6-3.3-4.3-3.8-6.2c1.1,1.6,3.7,1.8,4.1,4.3c0.2,1,0.9,2.7,2.2,2.4
				c1.6-0.3,0.5-2,0.8-3.1c0.6-1.9-1.9-3.6-1.5-4.1c2.8-2.8,0.6-5.9,1.1-8.7c0.3-1.9-0.6-3.5-2.2-4.4c-2.3-1.2-3.2-3.5-4.5-5.5
				c1.9,0.1,2.6,1.7,3.6,3c0.6,0.7,1.3,1.8,2.3,1.3c1-0.5,0.9-2.1,0.5-2.6c-1.6-2.3-0.4-4.6-0.3-6.9c0.1-2.5-0.4-4.7-2.2-6.5
				c0-0.4,0.1-0.8,0.1-1.2c0.6,0.3,1.7,0.3,1.6-0.2c-0.7-2.3,0.5-4.9-1-7c-0.4-0.6-0.9-1-0.4-1.6c2.8-3.3,0.5-7.1,0.8-10.6
				c0.1-1.5-1.4-2.8-2.8-3.7c-2.5-1.5-4-3.7-4.8-6.5c2,1.2,3.4,3.1,5,4.9c0.6,0.6,1.1,1.6,2.1,1.1c0.9-0.5,0.3-1.6,0.5-2.4
				c0.3-1.2-1.4-1.6-0.9-2.9c1.6-4.7,0.5-8.8-2.7-12.5c1.9,1.1,3,1.4,2.8-1.6c-0.3-4-1.5-7.9-0.8-12.1c0.5-3.2,0.6-6.8-2.7-9.1
				c2.4,0,3.3-1.1,2.2-3.5c-0.4-0.9-0.8-1.4-0.3-2.5c0.8-2.2,0.1-3.5-2.6-3.3c-0.8,0.1-1.7-0.1-2.6-0.2c-1-0.1-2.3-0.7-3,0.4
				c-0.5,1,0.9,1.6,1.1,2.5c-1.8,0-2.9,0.4-2.7,2.7c0.4,4.9,1.8,9.8,1.2,14.8c-0.3,2.3,2.8,2.6,2.8,4.7c-2.1-0.8-2.8-0.1-2.7,2.2
				c0.1,2.6,1.5,5.4,1,7.5c-0.8,3.8-0.3,6.6,2.3,9.3c-2.1-0.6-2.9,0-2.6,2.3c0.4,3.2,1.1,6.5,0.8,9.5c-0.3,3.5,0,6.3,2.1,9
				c-0.1,0.3-0.3,0.5-0.4,0.8c-2-0.7-1.5,0.8-1.5,1.8c0,4.1,1.6,8.2,0.4,12.4c-0.7,2.4,2.3,3.3,2.4,5.5c-2.3-0.6-3.1,0.3-2.3,2.5
				c0.9,2.6,1.8,6.1,1.3,8c-1.2,4.2,0.3,6.8,2.4,9.8c-1.9-0.3-3.1,0.2-2.5,2.4c0.5,1.8-0.7,3.9,1.4,5.7c0.9,0.8-0.5,3.6-1.6,4.6
				c-0.9,0.9-1.5-0.8-1.5-2c0.1-5.7-0.1-11.3-0.4-17c-1-24.1-1.9-48.3-2.9-72.4c-0.3-8.7-0.6-17.3-1-26c-0.1-2,0.6-2.5,2.5-2.8
				c2.9-0.5,5.8-0.6,8.7-0.8c0.6-0.1,1.5,0.2,1.6-0.6c0.2-1.3-0.9-0.9-1.6-1c-1-0.1-1.2-0.9-1.7-1.4c-0.1-0.6-0.3-1.3,0.2-1.9
				c0.9-1.1,0.9-7.4-0.1-8c-0.3-0.2-0.8,0-1.3,0.1c-0.1-0.1-0.2-0.2-0.2-0.3c1.9-1.4,2.6-3.2,2.2-5.6c-0.3-2.1-0.3-4.3-0.3-6.5
				c0-2-1-3.7-2.6-4.6c-1.7-1-2-2.4-2.3-4l-0.1,0.1c1.3,0.5,2,1.5,2.5,2.7c0.3,0.6,0.8,1,1.6,0.8c0.7-0.2,0.8-0.9,0.6-1.3
				c-1.5-2.8,0.9-6-0.4-8.4c-1.6-3-0.4-5.5-0.2-8.2c1.4,0,2.9,0.1,4.3,0.1c0,1-0.2,2.2,1.5,2.1l0,0.3l0.3,0.1
				c-1.8,3.1-2.3,6.2-0.4,9.4c0.3,0.5,0.6,1,0.2,1.5c-1.5,1.7-0.3,3.7-0.6,5.6c-0.3,2,0.8,2.6,2.6,2.5c-1.5,2.9-2.1,5.5-1.6,9.1
				c0.5,3.3,0.3,7,0.4,10.6c0.3,0.7,0.5,1.3,0.8,2c-0.1,0.2-0.3,0.4-0.4,0.6c-2,4.5-2,4.6,2.9,4.6c0.9,0,1.8,0,2.6,0
				c5.5-0.1,5.7-0.1,5.6,5.6c-0.1,2.6,0.2,3.4,3.1,2.5c1.2-0.4,3.5-1,4.1,1.7c0.6,3,0.1,3.7-3.3,3.7c0-1.1,1.4-1.3,1.6-2.2
				c-0.2-0.7-0.7-0.5-1.1-0.6c-4.3-1.3-4.3-1.2-4.1,3.5c0.7,17.2,1.1,34.5,2.2,51.7c1.1,17,1.6,34.1,2.7,51.2
				c0.1,1.1,0.8,2.5-0.9,3.1c-1.9,0.7-2.5-0.8-3.2-2c0.1-0.5,0.2-0.9,0.3-1.4c0.6,0.4,1,0.3,1.2-0.4c0.3-1.8-0.5-7.9-1.1-8.7
				c-0.2-0.5-0.4-1-0.6-1.5c0.8-3.1,2.8-6.1,0-9.2c-0.2-0.2-0.3-0.9-0.3-0.9c3.8-1.3,0.5-4.3,1.5-6.3c0.2-0.4,0.2-1.1-0.5-1.4
				c-0.7-0.3-1.2,0-1.6,0.7c-0.8,1.4-1.5,2.9-2.6,4.1c-2.8,2.9-5.1,5.7-4.3,10.4c0.5,3,2.7,6.1,0.9,9.4c0,0.1,0.1,0.2,0.1,0.3
				c-0.4,0.7-0.7,1.4-1.1,2c0,0.1,0,0.2,0,0.3c0,0-0.1,0.1-0.2,0.1c0.1-0.1,0.1-0.2,0.2-0.4c1.5-0.6,1.6,0.7,2.2,1.5
				c-0.5,1.6-2.6,3.7-1.7,4.5c1.3,1.1,3.8,1.2,5.8,1.2c7-0.2,7-0.3,6.9,6.6c0,1.2-0.2,2.4,0,3.6c0.4,2.7-0.7,2.8-2.7,1.7
				c0-0.4,0-0.9-0.1-1.3c-0.3-1.4,0.9-3.9-0.7-3.9c-1.9,0-1.8,2.5-1.8,4.2C905.4,1889,905.5,1889.3,905.5,1889.6z M901.6,1804.5
				c-0.6,1.5-1.2,3-2.5,4.2c-2.7,2.6-4,5.8-3.5,9.9c0.4,2.9,2.7,5.6,0.7,8.8c-0.9,1.5,0.6,2.5,2.7,2.2c-3.2,3.7-3,7.3-1.4,11.1
				c0.2,0.4,0.5,1,0.3,1.1c-2,1.9-0.2,4.1-0.5,6.2c-0.1,0.7-0.3,1.7,0.7,2c1.1,0.3,1.5-0.8,1.8-1.5c0.8-1.9,2.2-3.3,3.6-4.7
				c2.9-3,4.6-6.3,1.3-10.2c-0.5-0.6-0.4-0.9,0.4-1c1.5-0.2,1.1-1.4,1.1-2.3c0-2.3-0.1-4.6-0.1-6.8c0-0.8,0.1-1.6-1-1.7
				c-1.7-0.2-0.8-1.1-0.5-1.7c1.6-2.8,1.8-5.5-0.3-8.1c-0.3-0.3-0.8-0.9-0.3-1c3-0.8,1.1-3.1,1.3-4.6c0.1-1.3,0.8-3.2-0.7-3.9
				C903,1801.6,902.9,1804.4,901.6,1804.5c-0.9-0.8,0.1-1.4,0.5-1.8c2.8-2.9,4.5-5.9,1.8-9.7c-0.4-0.6,0.4-0.9,0.5-1.3
				c0.7-3.3,0.5-6.8-0.4-9.9c-0.4-1.5-0.3-2.5-0.3-3.9c0.1-2.2,1.2-4.8-1-6.8c-0.4-0.3-0.7-0.8-0.4-1c2.6-2,0.6-4.6,1.1-6.9
				c0.1-0.6,0.1-1.4-0.7-1.6c-0.8-0.2-1.2,0.4-1.5,1c-1.8,3.6-4.6,6.4-6.9,9.6c-0.6,0.9-0.7,1.8-0.7,2.8c0.2,4.4,1.5,8.7,0.6,13.1
				c-0.2,0.8,0,1.8,1.1,2.1c0.2,0.3,0.4,0.5,0.6,0.8c-1.3,1.6-1.9,3.4-1.4,5.4c0.9,3.4,0.4,6.9,0.6,10.4c0.1,1-0.4,2.4,1,2.8
				c1.5,0.4,1.9-1.2,2.5-2.1C899.4,1806.1,899.3,1804.1,901.6,1804.5z M893.3,1762.7c0.1,1.9,0.1,3.3,0.2,4.7c0,0.6-0.1,1.4,0.7,1.6
				c0.9,0.3,1.5-0.4,1.8-1c1.7-3.2,3.9-6.1,6.4-8.8c0.8-0.9,1-2.1-0.4-2.2c-2.7-0.1-5.4,0.3-8.1,0.6c-0.2,0-0.5,0.6-0.5,0.7
				C894.8,1760,893.6,1761.6,893.3,1762.7z M903.4,1879.2c-1.4,0.5-4-1-3.7,1.6c0.2,1.6,2.2,4.1,3.6,4.2c1.5,0.1,3.2-2.2,3.3-4.3
				C906.7,1878.3,904.5,1879.6,903.4,1879.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M398.6,2066.3
				c-1.8,0.4-3.4,1.7-5.6,1.1c-0.8-0.2-1.3,0.6-1.1,1.5c-0.9,1.2,0.1,3-1.2,4.2c-1,0.9-2.1,1.4-3.3,0.8c-1.1-0.5-0.8-1.7-0.4-2.6
				c2.1-5.2,1.5-10.8,2.4-16.1c0.2-1.3,0.2-2.6,1.1-3.6c0.3-0.4,0.7-0.9,0.7-1.4c-0.1-7,1.1-14.3,4.5-20c5.5-9.3,4.3-19.6,7.2-29.2
				c1-3.3,1.6-7,1.6-10.6l0,0c2.1-0.8,2.5-3,3.3-4.6c1.7-3.6,3.1-7.4,4.6-11.1c0.9-2,1.8-4,2.7-6c0.5-0.4,0.7-1,0.6-1.6
				c0.9-0.7,1.2-1.7,1.2-2.8c0.8-0.3,0.9-0.9,0.7-1.7c2.4-2.8,3.1-6.4,5-9.5c1.4-0.4,1.3-1.6,1.5-2.6c2.1-5.2,4-10.6,6.5-15.7
				c1.7-3.4,2.7-7.2,4.6-10.5c1.3-1.6-0.2-1.2-0.9-1.2c-4.8-0.1-9.6,0-14.3-0.1c-2.3,0-2.8,1.1-2.4,3.1c0,0.2,0.1,0.6,0.1,0.6
				c4.3,0.3,4.9,3.3,5.1,6.7c0,0.4,0.4,0.8,0.7,1c0.8,0.7,0.3,1.5,0.2,2.3c-0.5,3.9-3.3,3.4-5.9,3.3c-2.4-0.1-4,0.4-4,3.2
				c-2-0.4-1.1-1.9-1.3-3.1c-0.4-3.7,1.3-7.2,1.3-11c0-2.1-1.2-3.6-1.4-5.5c-0.3-3,1.5-5.5,1.7-8.4c0.1-1.2,0.3-2.3,0.1-3.5
				c8.8-0.5,17.5-3,26.5-1.2c1.1,0.2,1.5-0.5,1.9-1.3c2.9-0.2,5.8,0.3,8.8-0.3c2.8-0.6,1.8,1.8,1.4,3c-2,5.2-4,10.4-6.4,15.4
				c-4.5,9.8-9.5,19.4-14.2,29c-3.5,7.2-6,15.1-9.3,22.6c-7.3,16.4-14.3,33-22.4,49c-3.7,7.3-4.1,14.9-5.9,22.5
				c-0.4,1.8,0.5,3.8,0.8,5.7c0,0.2-0.1,0.4-0.3,0.5c-0.5-0.1-0.9,0-1.1,0.4c-0.2,0.5,0.1,0.8,0.6,0.9c0.1,0.8,0.3,1.4,0.1,2.4
				c-0.7,2.9,0.1,3.5,3,2.7C398.1,2064,398.4,2065.2,398.6,2066.3z M419.7,1916.6c-1.3,0.8-1.6,2.2-1.2,3.3c0.4,1.1,1.9,0.8,2.9,0.9
				c4.6,0.2,9.3,0.2,14,0.3c2.3,0,2.6-1.5,2.3-3.3c1.1-1,2.6-2.2,1.9-3.7c-0.6-1.2-2.4-0.6-3.6-0.5c-4.4,0.1-8.6-0.8-13-0.4
				C420.4,1913.4,419.5,1914.1,419.7,1916.6z M420.2,1931.6c-1.5,0.3-3.4-1.4-4.4,0.8c-0.4,0.8,0.4,1.6,1.4,1.8
				C419,1934.5,420.2,1933.9,420.2,1931.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M574.9,1266.3
				c-0.1,1.1-0.2,2.2,0.3,3.3c0.7,1.4-0.8,0.4-1.1,0.8c-1.3-0.5-2.5-2.6-3.9-1.5c-0.9,0.8-0.5,2.8,0.6,4.1c-0.8,1.7-0.3,3.6-0.8,5.4
				c-0.9,1.4-1.7,2.9-2.6,4.3c-1.9,1.6-4.4,1.8-6.6,2.8c-0.6,0.3-1.2,0.5-1.2,1.2c-1.7,1-2.1,2.8-2.6,4.5c-2.5-0.1-4.6,0.9-6.6,2.4
				c-5.7,4.1-11.7,4.3-18.1,1.7c-3.7-1.5-7.5-0.2-11.1,0.8c-2.3,0.7-4.6,1.9-6.9,1.7c-4.4-0.4-8.2,1.6-12.2,2.8
				c-0.5,0.2-1.1,0.5-1.6,0.4c-7.1-1-13.6,2.3-20.4,3c-0.5,0.1-1.2,0.3-1.6,0.1c-2.7-1.7-5.5-0.9-8.2-0.3c-1.1,0.2-2.2,0.4-3.2,0.3
				c-0.6-0.1-1.7,0-1.7-1.1c0-1,0.9-1.1,1.7-1.2c1-0.1,2,0,3,0c0.9,0,1.8,0.1,2.5-0.6c3.2-3.4,8.1-3.8,11.7-6.5
				c0.8-0.6,1.7-0.8,2-1.9c0.2-0.6,0.8-1,1-0.1c0.6,2.4,1.4,0.8,2,0.1c0.7-0.8,1.6-1.7,2.4-1.4c4.2,1.4,5.3-2.6,7.8-4
				c4.3-2.3,6.9-8.1,13.2-6.7c1.8,0.4,2.7-1.6,3.9-2.7c1.9-1.7,3.5-3.4,6.4-2.7c0.6,0.1,1.4-0.1,1.9-0.5c3.2-2.3,7.5-2,10.8-4.1
				c0.3-0.2,0.9-0.4,1.2-0.3c5.4,0.9,10.6-1.2,16-1.2c2.1,0,5.9-0.1,7.2-3.5c0.3-0.7,1.2-0.4,1.8-0.6c2.1-0.8,4.9-1.2,3.3-4.8
				c-0.3-0.6,0-1.3,0.7-1.7c2,1.4,2.1,4.6,4.6,5.1C572.2,1264.2,573.6,1265.1,574.9,1266.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M139.6,1936.6
				c6.4,3.7,12.8,7.4,19.3,11c1.4,0.8,2.1,1.6,2.1,3.3c-0.2,7.2-0.5,14.3-1.7,21.4c-1.2-0.2-3.1,0.7-2.3-2c0.6-2-2.2-2.2-2.4-1.8
				c-1,1.9-3.8,2.3-3.8,4.9c0,1.2,0.8,2.8-0.9,3.4c-1.5,0.6-1.9-1.1-2.8-1.7c-0.3-0.2-0.6-0.2-0.9-0.3c-0.2-2-0.8-3-3-1.5
				c0.9,1.2,1.9,1.3,3,1.5c0.4,1.9,0.9,3.8,1.3,5.7c0.3,1.3,0.2,2.6-1.4,2.8c-1.6,0.3-2.2-0.9-2.1-2.2c0.3-3.7-0.8-4.7-4.1-2.5
				c-0.6,0.4-1.3-0.2-1.9-0.2c-1.2,0-2.9-1.9-3.4-0.5c-0.4,1.4-2.2,3.3-0.1,5c0.4,0.3,0.4,1,0.1,1.5c-0.4,0.6-1,0.6-1.7,0.4
				c-1.5-0.6-3-0.6-4.7-0.4c-2,0.3-3-2.1-4.3-3.4c-1-1,0.5-2.1,1.4-2.8c0.5-0.4,1.2-0.8,0.7-1.5c-0.4-0.6-1.1-0.4-1.4,0
				c-1.9,2.2-3.7,1.7-5.9-1.4c0.3,3.5-0.8,5.1-3,5.7c-0.2,1.1,0.7,1.1,1.1,1.4c0.8,0.5,1.8,0.9,1.5,1.9c-0.3,1.2-1.5,1.2-2.5,0.8
				c-1.2-0.4-1.7,0-2.2,1.1c-0.6,1.4-2.2,1.4-2.3,0c-0.2-2.1-1.2-3.9-1-6.2c0.3-3.5-0.2-7.1-0.5-10.7c-0.2-2.4,0.3-4.2,3.3-3.7
				c1.2,0.2,1.7-0.5,2-1.6c1.8-6.5,3.7-12.9,5.5-19.4c0.6-2.3,0.8-4.5,2.5-6.7c2.1-2.6,4.3-3.1,7.1-2.7c1.7,0.2,3.2,0.4,4.8-0.4
				c0.6,0,1.2,0,1.9,0C137.6,1935.5,138.5,1936.3,139.6,1936.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M97.5,1821.3
				c0.1-0.9-0.4-2.2,1.3-2.3c2.1,2.1,2.7,5.5,5.9,6.6c1.8,2.8,4.6,4.5,7.1,6.6c-1.6-2.9-3.6-5.6-7-6.7c-1.9-2.3-2.4-5.8-6-6.5
				c0-1.6-1.5-2-2.2-3c-0.1-1.2,0.4-2.2,1.2-3.2c1.7-2,3.2-4.3,4.8-6.4c1.9-0.6,3.9-0.6,5.8-0.6c13.4,0.5,26.9,1.6,40.3,1.3
				c2,0,2.7,0.9,2.7,2.7c0,10.9-0.1,21.8-0.1,32.7c0,2.1-1,2.6-2.8,2.6c-6.9-0.1-13.8,0.2-20.7-0.2c-2.8-0.2-5.6-1.5-8.6-1.2
				C112,1836.3,104.8,1828.8,97.5,1821.3z M119.2,1839.2c0,0.1,0.1,0.2,0.1,0.3c0,0,0.2,0,0.2,0
				C119.4,1839.4,119.3,1839.3,119.2,1839.2c0-0.9-0.5-1.3-1.3-1.3c0-0.8-0.3-1.5-1.2-1.7c-0.4-0.1-0.6,0.2-0.5,0.6
				c0.2,0.9,0.9,1.1,1.8,1.1C117.8,1838.8,118.3,1839.2,119.2,1839.2z M113.7,1833.4c-0.1-0.2-0.2-0.4-0.4-0.5
				c-0.4-0.1-0.6,0.1-0.5,0.5c0.1,0.1,0.3,0.3,0.5,0.3C113.4,1833.8,113.5,1833.6,113.7,1833.4z M122.9,1842.6
				c-0.1-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.4,0.1-0.5,0.1c0.1,0.2,0.2,0.4,0.3,0.5C122.5,1842.7,122.7,1842.6,122.9,1842.6z
				 M114.8,1835l0.1,0.1l0-0.2L114.8,1835z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M976.4,1650.7
				c-0.4,7.6-0.9,15.2-1.2,22.8c0,1-1,2.6,1.1,2.6c1.8,0.1,2.7-0.7,2.8-2.7c0.3-8.6,0.8-17.2,1.3-25.8c0.1-1.1-0.5-2.6,0.9-3.2
				c1.3-0.5,2.2,0.7,3.1,1.4c9.5,6.6,18.9,13.1,28.5,19.7c4.9,3.3,4.9,3.3,0.5,6.8c-3.2,2.6-6.4,5.2-9.7,7.8c-1.1,0.8-1.9,1.8-0.8,3
				c1,1.1,1.7-0.3,2.4-0.8c4.2-3.3,8.4-6.6,12.5-10.1c1.6-1.4,3-1.4,4.7-0.2c6.3,4.4,12.6,8.6,18.8,13c1,0.7,2.6,1.3,1.7,3.1
				c-0.4,0-0.8-0.1-0.7,0.6l0,0c-1.9-0.3-3.5,0.2-4.6,1.9l-0.1,0c-0.8-0.3-1.5-0.3-1.9,0.6l-0.1,0c-6.5,1.9-13.1,3.8-19.6,5.6
				c-2,0.2-3.9,0.5-5.9,0.1c0-0.8-0.6-1.1-1.2-1.3c-3.8-1.6-8-3-6.3-8.7c0-0.1-0.3-0.3-0.4-0.4c-1.2-1.3-2.9-1.3-4.5-1.3
				c-2.4-0.1-4.8,0-7.2-0.2c-7.6-0.8-15.1-0.2-22.6,1.6c-0.9-0.7-2-1.5,0.1-2c2.1-0.5,4.2-0.8,6.3-1.3c1.9-0.5,4.6,0.1,4.3-3.4
				c-0.1-1.2,1.2-0.9,2-1c5.4-1.1,10.8-0.5,16.2-0.1c0.1,0.1,0.2,0.2,0.3,0.4c0-0.1,0-0.2,0-0.2c-0.1-0.1-0.2-0.1-0.3-0.1
				c-5.4-3.2-11.1-2-16.8-1c-1.1,0.2-2.1,0.6-3.2,0.8c-2.4,0.4-4.2-1.1-4.4-3.5c-0.7-7.2-0.5-14.4-0.5-21.5
				C971.9,1650.3,972.9,1649.6,976.4,1650.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M416.4,1713.2
				c3.3-0.9,6.7-0.3,10.1-0.1c2,0.1,2.3,1.6,2.3,3.3c0,4.5,0,8.9-0.1,13.4c0,1.9,0.1,3.2,2.6,2.8c1.5-0.2,2.8,2.8,2.4,5.1
				c-1.2,6.7-2.5,13.4-3.7,20.1c-2.2,0.6-4.3-0.1-6.5-0.2c-13.6-0.7-27.2-1.5-40.8-2.2c-1.2-0.1-2.4-0.4-3.1-1.5
				c0.4-3.3,3-1.2,4.5-1.8c2.1,1.1,4.3,0.7,6.5,0c-2.2,0-4.3,0-6.5,0c-3-0.9-6.1-1.4-9.2-1.3c-0.1-0.1-0.2-0.1-0.2-0.2
				c0.1,0,0.2,0.1,0.3,0.1c0,0.4,0,0.8,0,1.2c-0.6,0-1.3-0.1-1.9-0.1c0-0.2,0.1-0.4,0-0.6c-0.8-3.8-0.9-3.7,3.5-3.1
				c1.5,0.2,2.9,1.2,4.7,0.4c-2.2-2.4-1.8-4,1.5-4.4c0.5-0.1,1.2-0.2,1.2-0.9c0.1-0.9-0.7-1-1.3-1c-1.6-0.1-2.8-0.7-3.1-2.4
				c-0.3-2.1,1.8-1.4,2.7-2.1c0.4-0.3,1.1-0.3,1.1-1c0-0.9-0.7-1.1-1.3-1c-2.6,0.1-2.9-1.8-3.2-3.7c1.2-0.1,3.6-0.2,3.4-0.8
				c-0.7-2.3,1.8-3,1.8-4.7c0.1-1.3-0.8-1.2-1.7-1.4c-2.9-0.8-6-0.6-9.1-0.8c-0.1-1.3,1.7-1.3,1.6-2.5c3,0.5,4-0.8,3.4-3.7
				c-0.2-1.1-0.1-2.3-0.2-3.5c0.5-1.5,1.4-2.1,3-1.7c1.8,0.4,0.2,1.6,0.5,2.4c0.7,1.8,0.8,3.8,0,5.7c-0.2,0.5-0.2,1.2,0.4,1.4
				c1.5,0.5,2.3-1.5,2.3-1.6c-0.4-1.6,3.3-2.4,1.2-3.8c-1.6-1.1-1.4-2.2-1.6-3.5l0,0c0.7-0.2,1.6-0.4,1.2-1.5
				c-0.3-0.8-0.8-1.3-1.7-1.3c-1,0-2,0.2-3.5,0.3c1.5-2,3.2-1.3,4.5-0.9c1.6,0.4,3.2,0.2,5.1-0.1c-0.6,1.3-1.1,2.5-1.6,3.6
				c-1,0-1.6-1.1-2.8-0.8c0.6,2,1,4.1,4,3.4c0.6-0.1,1.5,0.7,2.2,1.2c8.6,5.4,17.3,10.8,25.9,16.2c0.5,0.3,0.9,0.6,1.4,0.9
				c0.2,2-1.8,1-2.5,1.8c-4.8,5.1-11.3,8.2-16.2,13.2c1.5,0.4,2.3-0.6,3.2-1.3c2.6-2,5.1-4.1,7.6-6.2c0.8-0.6,1.7-1.5,2.6-0.9
				c1.1,0.8-0.2,1.7-0.6,2.5c-0.4,0.8-0.9,1.5-1.5,2.5c1.9-0.4,2.6-1.4,3.3-2.5c1.1-1.7,1.8-3.7,3.3-5.1c1.1-1.1,1.8-1.1,2.3,0.5
				c0.2,0.7,0,1.8,1.2,1.3c0.9-0.3,0.8-1.1,0.5-2c-0.4-1.4-0.8-2.9-1.1-4.3c1-1.2,0-1.8-0.6-2.6
				C418.9,1725.2,417.7,1719.2,416.4,1713.2z M411.1,1748.9c-0.3,0-0.7,0-0.9,0.1c-1.2,0.5-2.4,0.9-3.6,1.4c0.1,0,0.2-0.1,0.2-0.1
				c1.4,0.4-0.2,2.1,1.1,2.5c0.6,0.2,0.9-0.4,1.2-0.9C409.7,1751,410.5,1749.9,411.1,1748.9l0-0.1L411.1,1748.9z M427.5,1749.5
				c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0-0.1,0-0.2C427.7,1749.6,427.6,1749.6,427.5,1749.5c-0.9-3.4-3.2-1.3-4.3-0.7
				c-3.7,2.2-7.3,4.4-11.6,5c0,0-0.1,0.2-0.2,0.5c0.9,0.7,2,0.2,3,0.5c4.4,1.1,8.5-2.3,12.9-1c0.8,0.2,1.1-0.6,1.3-1.2
				c0.4-1.4-0.8-0.9-1.4-1c-0.8-0.1-1.7-0.1-1.7-1.1C425.5,1749.2,426.8,1749.7,427.5,1749.5z M422.8,1742.2
				c-0.7,0.7-2.9,0.5-1.8,1.9c0.6,0.8,0.2,3.2,1.9,2.3C424.6,1745.5,422.7,1743.7,422.8,1742.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M877.2,1916.8
				c7.2,0,14.3,0,21.5,0.1c5.7,0.1,11.3-0.2,16.9-0.8c1.6-0.2,2.5,0.1,2.6,2.2c0.2,6.4,0.6,12.8,1.1,19.2c0.2,2.6-0.7,3.3-3.1,2.8
				c-0.6-0.1-1.3-0.2-2-0.1c-0.7,0-1.6-0.3-2.1,0.5c-0.6,1.2,0.9,1.3,1.2,2c-0.5,7.7,1,15.4,0.5,23.1c-0.1,1.4,0.5,2,1.9,2
				c0.8,0,1.5-0.1,2.3-0.1c0.9,0,2.1-0.1,2.1,1c0,1.2-1.2,1-2,0.9c-3.4-0.5-3.7,1.1-3.5,4c0.6,8.7,0.6,17.4,1.2,26.1
				c0.2,2.7-0.5,5.8,2.1,7.9c0.3,0.3,0.6,0.9,0.9,0.9c5-0.6,2.3,3.6,3.4,5.4c0.5,0.8,0,2.2,0.2,3.2c0.2,1.5-0.1,2.6-1.6,3.2
				c-2,0.7-1.7-1-2.1-2.1c-0.3-0.8-1-1.7-1.6-1.3c-3.5,2.6-3-1.1-3.9-2.4c-3-4.6-3.1-9.7-1.9-14.8c0.4-1.8-0.1-3.4-0.1-5.2
				c0-0.6-0.4-1.5,0.4-1.7c1.2-0.3,0.8,1.1,1.7,1.7c-0.6-2.6-3.1-4.2-2.4-6.8c0-0.1-0.5-0.5-0.7-0.4c-0.7,0.1-1.3,0.8-1.1,1.3
				c0.7,2-0.1,2.4-2,2.9c-1.3,0.3-1.1,2.4-2.2,3.7c-2-3.1-3.6-6.3-4.7-9.7c-1.5-4.6-3.8-8.8-5.8-13.1c-1-2-1.5-3.9,0.4-5.6
				c2.4-2.1,0.4-4.7,0.6-7c0.2-1.9-0.4-4-0.6-6.1c-0.1-0.5,0-1.3-0.3-1.6c-2.2-1.9-3.7-4.3-5.5-6.9c-1,4.3,2.4,8.2-0.6,12.4
				c-1.7-3.9-4.6-7-3.9-11.5c0.1-0.8,0.4-1.9-0.5-1.9c-2.6-0.2-2.6-2.4-3.2-3.9c-1.6-3.9-4-7.4-3.8-11.9c0.1-2,0.8-2.7,2.7-2.7
				c3.1,0.1,6.3-0.3,9.3,0.2c4.6,0.8,9.5-1.7,13.9,1.1c-0.9,1.1-1.3,2.3-0.3,3.9c1.6-1.5,3.2-2.7,2.5-5c0.5,0.1,1.1,0.1,1.6,0.2
				c4,0.4,5.2-1,4.3-4.9c-0.4-1.6-1.3-2.2-3-2.2c-7.1,0.1-14.1,0.7-21.2-0.2c-2.3-0.3-4.5,0.1-6.8,0.4
				C877.7,1919.4,877.4,1919,877.2,1916.8z M903.4,1943.5c0.2,1.3,1,2.5,2.1,2.4c1.5-0.1,1.9-1.7,2.2-3.1c0.3-0.7,2.1-0.9,1.3-1.7
				c-1.3-1.3-3.3-0.7-4.8-0.3C902.8,1941.1,902.3,1942.3,903.4,1943.5z M904.6,1965.1c0.5,1.9-0.6,3.8-0.3,5.7
				c-1.6,3.2-0.4,11.9,2,14.4c0.4,0.5,1,1.2,1.6,0.5c1.7-2.3,4-4.5,4.7-7.1c1.8-6.3-0.1-8.3-6.6-8.3c1.2-2.1,2.8-4,2.5-6.6
				c1.5-4.7,0.1-9.6,0.8-14.4c0.1-0.6-0.1-1.4-0.9-1.5c-0.6,0-0.8,0.5-1.1,1c-2.3,3.4-4.5,6.7-2.4,11.1c0.2,0.4-0.3,1-0.5,1.5
				C904,1962.6,903.9,1963.9,904.6,1965.1z M888.8,1942c2.9,3.2,4.2,6.3,7.7,8.9c-0.5-2.8-3-4-2.7-5.8
				C894.6,1940.8,891.9,1942,888.8,1942z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M230.6,1764.7
				c1.9,0,2.2,1.4,2.3,2.8c-2.6,1.6-5.3,3-7.7,4.7c-6.9,4.8-13.9,9.5-21.1,14c-7.9,4.9-15.7,10.1-23.5,15.2
				c-3.3,0.1-6.5,1.7-9.8,1.1c0.2-1.5,0.1-3.1,1.1-4.5c3.7,0.2,4-3,5.2-5.3c1.7-0.1,3.4,0.4,5.7-0.8c-5.2-1.7-5.1-5.6-5.1-9.4
				c0-0.4,0-0.9,0-1.3c0.1-4,0.3-8,0.4-12c0.1-3.6,0.9-7.2,0.4-11c-0.4-2.9,0.1-5.8,0.2-8.8c5.5,0.2,11-0.1,16.4,0.8
				c5.1,0.8,10.2-0.5,15.2,0.5c0.6,1.2,0.4,2.6,0.4,3.9c-0.1,1.8-0.1,3.2,2.5,2.6c1.6-0.4,2.4,0.2,2.3,2.3c-0.2,4-1.5,7.9-1.5,11.9
				c0,0.8-0.8,2.3,0.6,2.4c1.6,0.2,1.3-1.3,1.4-2.4c0.2-3,0.4-6.1,0.5-9.1c0.1-0.9,0.2-2,1.2-2c1.3-0.1,0.9,1.2,1,2
				c0.1,2.5,0.2,5,0.2,7.5c0,0.8,0.2,1.9,1.2,1.2C223.4,1768.7,227.8,1768,230.6,1764.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M393.5,1850.4
				c-2.6,0.3-5,0.1-6.5-2.5c-0.1-0.2-0.5-0.5-0.8-0.5c-4.2-0.1-3.6-2.7-3-5.4c1.8,0.4,3.2,0.4,2.2-2.1c0.1-0.1,0.2-0.3,0-0.5
				c0.3-0.3,0.6-0.6,1-0.7c0.6-0.2,1.5-0.3,1.5-1c-0.1-1.1-1.1-0.7-1.8-0.8c-0.4-0.1-0.9-0.1-1.3-0.1c-2-0.8-4.2-0.8-6.3-0.8
				c-1,0-2.2,0.5-2.8-0.9c1.4-0.1,2.8-0.1,4.3-0.2c1.2,0,2.1-0.4,1.7-1.7c-0.5-1.8,0.7-2.3,2.3-2.6c-0.8-1.2-2.6-0.8-2.5-2.5
				c0.2-2.2,2.3,0.3,2.7-1.4c-0.8-1-3-1.1-2.6-3c0.4-1.8,2.6-0.7,3.5-1.9c0.5-0.6,1.7,1.4,1.9-0.3c0.2-1.8-1.3-1.3-2.3-1.5
				c-3.8-0.5-7.6-0.2-11.5-0.2c-0.1-0.7-0.1-1.4-0.2-2.1c1.9,0.1,4,0.3,5.6-0.7c0.7-0.5,0.3-2.3,2.2-1.3c0.9,0.4,0.3-2.2,2-1.9
				c0.2,0,0.4,0,0.6,0c0.4,0.2,1,0.3,1.1-0.2c0.1-0.6-0.5-0.9-1.1-0.9c-0.2-0.3-0.4-0.6-0.6-0.8c0.2-0.9,1-1.5,0.3-2.6
				c-2.1-3.5-1.6-4.8,2.1-6.2c0.3-0.1,0.4-0.4,0.7-0.7c-0.9-1.7-4.3,0.2-4.2-2.9c0-2.7,2.1-2.9,4.2-3.5c-4.5-2.9,0-5.3,0.2-8
				c2.6-2.8,4.4-6.2,6.3-9.5c1.3-2.4,2.4-2.2,4.3-0.8c3.9,3.1,7.9,6.2,12,9.1c1.5,1.1,1.8,2.3,1.6,3.9c-1.1,10.1-2.1,20.1-3.1,30.2
				c-0.1,1.2-0.4,2.1-1.4,2.7c-3.6,1.1-6.7,3-9.7,5c0.2,0.3,0.3,0.6,0.5,1c3-1.5,6.1-3,9.1-4.6c0.7,0.3,0.9,1,0.8,1.6
				c-0.7,3.7-0.6,7.5-1.5,11.3c-0.8,3.1-1.8,3-3.7,0.9c2.4-1,1.4-1.9-0.1-2.8c-2.3-1.5-5.2-2.3-6.9-4.7c0.3-1.1-0.3-1.4-1.2-1.1
				c-2.3,0.7-3.8,2.6-6,3.6c-1.4,0.6,0.4,1.1,0.8,1.7c0,0.3,0.2,0.7,0.5,0.5c0.2-0.2,0.2-0.7,0.3-1.1c1.1-0.8,2,0,2.9,0.5
				c1.9,0.9,3.8,1.8,5.7,2.7l-0.1,0c0.7,0.5,1.4,1.1,2.1,1.4c3,1.1,2.7,2.4,0.6,4.3C397.6,1845.7,395.6,1848.1,393.5,1850.4z
				 M387.5,1827.5c0.6-1.4,1-2.6,0.2-3.6c-0.2-0.3-0.8-0.1-1.1,0.3C385.7,1825.4,386.4,1826.4,387.5,1827.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M814,1786.2
				c0.5-2.7-0.3-5.3-0.3-8c0-4.5,1.5-8.6,4.9-11.7c7.4,0.3,14.6-1.3,21.7-3.2c1.3,1.4,1.4,3.2,1.4,5c-0.1,5.4-0.1,10.8,0.6,16.1
				c0.1,0.9,0.5,1.9-0.4,2.7c-8.4-0.3-16.7-0.7-25.1-1c8.4,0.3,16.7,2.2,25.1,1.2c1,1,0.6,2.2,0.6,3.4c0.2,7.7,0.8,15.5,0.5,23.2
				c-0.2,6.6,0.5,13.1,0.2,19.6c-2.2-0.3-4.6-1-5.8,1.9c-0.2,0.6-1,0.4-1.4,0c-1.1-1.2-3.1-2.5-3.1-3.6c0.4-5.2-4-8.1-5.6-12.3
				c-4-10.3-8.8-20.4-13.3-30.5C813.5,1788.1,813.1,1787.1,814,1786.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M595.8,2018
				c3.4-6.4,5.5-13.4,8.4-20.1c1.9-4.4,3.5-8.9,5.3-13.3c3.1-5.1,4.9-10.7,6.7-16.3c1.7-5.2,4-10.3,6-15.4c1.5-3.9,3.2-8.2,5.6-11.9
				c1.4-2.1,1-4.9,2.7-6.8c0.1,0,0.2,0,0.3-0.1c1.6-0.9,0.2-4.8,3.2-3.9c1.7,0.5,3.5,0.1,5.1,1.2c1.7,1.1,3-0.6,4.9-1
				c0,4.3-1.7,8-3.1,11.7c-9,22.7-18.1,45.3-27.1,67.9c-0.5,1.2-0.9,2.5-2.4,2c-2.2-0.8-2.4,0.3-2.4,1.9c0,2.7-1.5,5-2.5,7.3
				c-2.5,5.7-3.2,12.1-6.9,17.4c-1.2,1.7-1.4,3.9-1.2,6l-0.1,0.1c-2.3,0.8-3.3,0.5-3.8-2.4c-0.9-5.7-0.1-11.4-0.9-17.2
				C593.3,2023,595.4,2020.5,595.8,2018z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M333.2,2147.7
				c-1,0.3-2,0.5-3,0.8c-3.4-3.7-1.9-8.3-2-12.4c-0.6-25.5-0.9-51-1.3-76.5c-0.4-26.9-0.5-53.8-1.4-80.7
				c-0.5-15.1,0.2-30.3-1.1-45.3c-0.3-2.8,0.7-5.4,1.4-8c0.6-2.4,1.3-4.8,1.1-7.4c-0.1-1-0.1-2.2,1.3-2.2c7.5,0,15.1,0,22.6,0.4
				c-5.9,1.2-12,0.4-17.7,2.7c3.6,0,7.2,0,10.8,0l0,0c-1,3.2-3.3,5.8-4.3,9c-0.4,1.2-3.7,1.8-5.7,1.9c-1.8,0.1-1.6-2-1.8-3.3
				c-0.1-0.5,0-1.1-1.1-1.6c-0.2,3.8-0.6,7.4-0.5,11c0.2,22.4,0.5,44.9,0.7,67.3c0.1,12.2,0.1,24.4,0.2,36.6
				c0.2,16.9,0.6,33.7,0.8,50.6c0.2,14.3,0.3,28.5,0.5,42.8C332.8,2138.2,333,2143,333.2,2147.7z M331.3,1919.3c-1.1-1.2-1.9-1-3,0
				C329.4,1919.9,330.3,1919.9,331.3,1919.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M173.8,1975.6
				c-2.5,1.2-4.7,3.7-7.9,2.2c-0.3-2.3,1.6-4.2,1.1-6.5c0.1-0.4,0.2-0.8,0.3-1.2c3.8,0.4,7.5,0.8,11.6,1.3c-1.2-2.5-4.1-1.1-5-3.4
				c2.7-0.4,4.8-1.6,7.4-2.6c-1.6-0.8-2.6,0.2-3.7,0.2c-1.9,0-4.5,1.1-5.3-2c0-1.7,0.1-3.5,0.1-5.2c0-2,0.8-2.2,2.5-1.4
				c2,0.9,3.7,2.3,5.5,3.5c3.5,2.3,5.1,2.3,9.1-0.6c-4.5-2.5-8.7-5.1-13.1-7.4c-2.4-1.2-3.3-2.8-3.4-5.6c-0.1-3.5-1.1-7-0.1-10.5
				c0.3-1.1-0.5-1.5-1.6-1.4c-1.8,0.2-3.7,0.2-5.5,0.3c-0.2-0.6-0.4-1.3-0.6-1.9l0,0c5.9-0.6,11.7-1.6,17.6-1.5
				c9.9,0.2,19.8-0.7,29.7-1c-0.7,1.9-0.4,3.7,0.2,5.6c0.4,1.4,1.2,1.3,2.2,1.1c1.2-0.2,1.2-1,1-1.9c-0.4-1.5-0.6-3.1-1-4.7
				c0.5-1.3,2.5-0.4,2.9-1.9c1.5,0,3,0,4.5,0c0.3,0.6,0.5,1.2,0.8,1.8c0,3.2,0,6.4,0.1,9.7c0.1,1.7-0.6,2.7-2.3,3.4
				c-4.1,1.6-8.2,3.5-12.3,5.3c-1,0.5-2.2,0.8-3,2c2.5,0.1,4.5-1.2,6.6-1.9c1.5-0.5,2.5-0.4,3.1,1.2c-2.2,1.4-4.3,2.9-6.5,4.2
				c-8.5,5.1-17,10.1-25.5,15.2c-0.9-0.2-1.7-0.2-1.7,1.1c-1.1,0.2-2.1,0.6-2.8,1.6C176.8,1973.1,175,1973.9,173.8,1975.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M731.4,1783.2
				c6.1-0.2,12.2,0,18.2,1.3c0.4,1.2,0.3,2.5,0.7,3.7c1.1,3.1-2,3.7-3.7,5c-0.3,0.3-1.1-0.1-1.3,0.9c1.6,0.6,4.2,0.4,4.2,3
				c0,2.3-2.4,2-3.9,3c1.1,1.3,4.3-0.2,4.1,2.5c-0.2,2.2-2.2,3-4.2,3.2c-0.1,0.6,0.3,0.5,0.5,0.6c1.4,0.6,4-0.2,4.1,1.6
				c0.1,2-1.9,3.5-4.1,3.8c0.1,0.3,0.2,0.6,0.3,0.6c3,0.4,5.7,0.9,2.3,4.5c-0.1,0.1,0.1,0.4,0.2,0.5c0.1,0.1,0.4,0,0.6,0
				c0.1,0.3,0.2,0.7,0.3,1c0.1,0.8,0.7,1.2,1.4,1.6c-0.1,0.4-0.3,0.9-0.4,1.3c-2.1-0.1-2.5,2.4-4.9,2.5c1.8,1.8,4,1,5.5,2.1
				c-0.1,0.4-0.3,0.9-0.4,1.3c-1.5,1.1-2.9,2.2-4.4,3.3c1.4,1.3,4.2-0.4,4.3,1.8c0.1,2.2-1.9,3.7-4,4.5c1.2,1.7,4-0.5,4.2,1.7
				c0.2,2.2-1.3,4-3.6,4.6c1.3,1,3.7-0.5,4.1,1.1c0.4,1.9-1.5,3.3-3.1,4.5c-0.3,0.2-0.9,0.1-0.8,0.8c4.2,0.9,4.2,0.9,2.2,4.8
				c-0.2,0.4-0.5,0.9-0.7,1.3c1.3,0.7,3-0.4,4.1,0.9c-0.1,0.4-0.8,0.7-0.2,1.2l0.1,0c-0.3-0.3-0.7-0.3-1,0c-2.4,1.6-4.9,3-7.3,4.7
				c-1.1,0.8-2,0.3-3,0c-1.8-0.6-3.6-1.3-5.2,0.3c-0.9,0.9-1.9,1.4-2.8,0.5c-0.8-0.9-0.6-2.4-0.2-3.4c0.4-1.2,1.6-0.8,2.5-0.4
				c0.3,0.1,0.6,0.1,1.3-0.1c-5.3-2-3.9-6.3-4-10.1c-0.6-15.6-1.1-31.3-1.5-46.9C731.5,1796.3,731.2,1789.7,731.4,1783.2z
				 M747.2,1818.7c0.9-0.5,0-0.4-0.2-0.6c-0.5-0.6-1-0.5-1,0.2C746,1818.9,746.6,1818.9,747.2,1818.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M684.6,1885.6
				c-1.7-1.4-2.5-2.9-1.5-5.2c0.5-1.2,1.2-1.5,2.2-1.3c1.2,0.2,1.2,1,0.8,2c-0.7,2,0,2.5,1.9,1.7c0.8-0.4,1.6-0.9,2.5-0.6
				c3.8,1.4,4.1-0.6,4-3.6c-0.4-7.2-0.3-14.5-0.8-21.7c-0.3-4.2,1.6-7.5,3.3-11c0.3-0.7,0.8-1.5,1.7-1.2c1,0.3,0.9,1.2,0.9,2.2
				c0.3,7.7,0.2,15.4,0.5,23.1c0.4,9.5,0.1,19.1,0.4,28.7c0.6,19.7,0.9,39.4,1.4,59.1c0.5,17.8,1.2,35.7,1.2,53.5
				c0,6.2,0.9,12.4,1.1,18.5c0.1,2.9,3,2.1,4.1,3.5c-4.4,1.9-4.5,2-6.6-2.1c-1.6-3.2-3.5-6.3-3.6-10c0-2.3,0.3-4.6,0.1-6.9
				c-1-13.8-0.8-27.6-1.2-41.4c-0.1-1.8-0.9-1.9-2.2-1.8c-4.3,0.2-8.7,0.4-13,0.5c-0.1-1.6-1.5-3.4,0.7-4.7c1.2,0.3,1.5,2.4,2.7,1.9
				c2.1-0.9,4.3,0,6.4-0.7c0.3-0.1,0.7-0.1,1-0.2c1.2,0,2.3,0,3.5,0.1c1.2-0.1,0.9-1,0.9-1.7c-0.3-5.5,0.4-11.1-0.8-16.5
				c0.5-8.8-0.4-17.5-0.5-26.3c0-0.6,0-1.1-0.1-1.7c0-0.5,0-0.9,0-1.4c0.3-1.5,0.3-3,0-4.5c0.1-1.6,0-2.6-2.1-2.6
				c-7.6,0.1-15.2,0-22.8-0.1c0-1.4,1-2,2.2-2.3c4.9-0.3,9.7-0.8,14.6-1c7.9-0.2,7.8-0.1,7.7-8c0-2.2-1-2.5-2.7-2.5
				c-5.4,0.2-10.9,0.4-16.3,0.5c0.6-1.5,1.1-3.1,1.8-4.5c0.8-1.5,1.9-3,3.8-2.5c2.9,0.7,5.7-0.7,8.6-0.2c-3.1-2.1-6.9-0.3-10.9-1.7
				c4.7-1.6,8.7-0.7,12.6-1.1c0.9-0.6,2.3,1.8,2.7-0.3c0.3-1.6,1.2-3.8-2.2-4.2C689.9,1885.2,687.3,1884.9,684.6,1885.6z
				 M694.8,1895.3c0-1.2,0.5-2.4-0.5-3.9C693.6,1893.3,694.6,1894.3,694.8,1895.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.1,1783
				c0,7.3-0.1,14.6,0,21.9c0,2.8-0.9,3.7-3.7,3.9c-8.7,0.4-17.5,0.5-26,2.4c-0.3,0.1-0.7,0-1,0c-5.4,0.2-5.4,0.2-3.2-4.7
				c6.8-15.3,13.6-30.5,20.3-45.8c1.3-3,2.6-4.4,6.2-4c6.6,0.7,6.7,0.5,6.7,7.1c0.1,6.4,0,12.8,0,19.3
				C541.7,1783,541.9,1783,542.1,1783z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.8,1853.6
				c0.8-3.9,1-7.9-0.2-11.8c0.1-0.4,0.1-0.9,0.2-1.3c0.8-1.8,0.9-3.5-0.2-5.2c0-0.6-0.1-1.3-0.1-1.9c1.2-0.6,0.8-1.7,0.8-2.6
				c0-2.7,0-5.5,0-8.2c0-0.8-0.3-2.1,0.9-2.1c1.3-0.1,0.8,1.2,1.1,2c1.7,6.5,1,13.1,1.4,19.7c0.5,6.8,0.3,13.7,0.4,20.5
				c0.1,6.1,1,12.2,0.3,18.4c-0.3,2.7-0.5,5.6,0.3,8.3c0.6,2.1-0.6,4.1-0.5,6.1c0.2,5.8,0.9,11.6,0.5,17.3
				c-0.4,5.6,0.6,11.2-0.2,16.8c-0.6,4.2,0.4,8.5,0.3,12.8c-0.1,6.8,1,13.6,1,20.3c0,8.5,0.4,17.1-0.7,25.6c-0.4,3.2,0,6.5-0.2,9.7
				c-0.2,3.6,1.4,7.3-0.4,10.9c-0.4,0.9,0,2.4,0.4,3.5c1.3,3.6,1.8,7.7,0.9,11c-1.1,4,1.1,7.8-0.5,11.5c-0.6,1.4,0.9,2.2,1,3.6
				c0.3,4.9-2.1,9.5-1.7,14.4c-2.1-1.7-1.2-4-1.2-5.9c-0.3-11.9,0-23.8-0.5-35.7c-0.4-10.3-0.8-20.7-1-31c0-2,1.4-4.5-1.8-5.7
				c-0.6-0.2-0.8-2.1,0.7-2.6c1.9-0.6,1.3-1.9,1.1-3.1c-0.3-1.7-1.3-0.8-2.2-0.8c-2.4-0.1-1.7-2.1-1.9-3.4c1.3-0.6,3.7,0.2,3.8-1.4
				c0-1.4-2.5-1-3.8-1.6c-0.3-0.1-0.6,0-1,0c-0.4-2.4-1.2-4.8-1-7.2c0.1-0.9,0.1-1.6,1.4-1.7c5-0.3,5-0.3,4.3-5.6
				c-0.1-6.3-0.3-12.7-0.4-19c0.7-2.9-0.5-3.8-3.4-3.7c-2.8,0.1-5.7,0.2-8.5-0.6c2.9-0.4,5.8-1,8.7-0.8c3.6,0.3,3.9-0.2,2.8-3.8
				c-0.1-1.4-0.2-2.9-0.3-4.3c0.1-0.1,0.2-0.3,0.2-0.4c0,0-0.1-0.1-0.2-0.2c0.6-1.5,0.5-2.1-1.5-2.4c-4.3-0.7-8.7-0.3-13-0.9
				c0.4-0.4,0.5-0.8,0-1.2c4.2-0.5,8.5-1,12.7-1.3c1.7-0.1,2.2-0.7,2.2-2.4c-0.1-4.2-0.2-8.5-0.2-12.7c0-1.8-0.9-1.9-2.3-1.8
				c-3,0.3-6.1,0.5-9.1,0.8c-1.8,0.2-2.1-0.3-1.4-2c1.3-2.7,3-4.2,6.2-3.1c1.5,0.5,3,0.2,4.5,0.1c3.2-0.3,1.5-2.8,1.9-4.3
				c0.3-1.6-0.4-2.4-2.2-2.3c-6,0.1-12,0.1-18,0.2c-2.9-2.5-2.4-6-2.8-9.2c-0.1-1.1,1.2-1.1,1.6-0.6c2.5,2.9,5.4,1.7,8.2,0.9
				c2.8-0.7,5.6-0.6,8.5-0.7c3.3-0.1,4.6-1.4,3.9-4.6c-0.4-1.9,0.2-3.5,0.3-5.3c0-0.7,0.1-1.3,0.1-2c0.7-1.6,0.7-3.1-0.2-4.6
				C542.7,1854.5,542.7,1854.1,542.8,1853.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M841.9,1787.1
				c0.9-0.8,0.6-1.8,0.4-2.7c-0.7-5.4-0.7-10.7-0.6-16.1c0-1.8-0.1-3.5-1.4-5c0-0.2-0.1-0.5-0.1-0.7c0.9-0.8,0.9-1.9,0.8-3
				c-0.2-3.7-0.2-7.4-0.4-11.1c-0.6-11.2,0-22.5-1.1-33.7c4.7-0.8,9.4-1.6,14.1-2.4c2.5-0.4,4.6-0.6,4.2,1.6
				c-0.2-1.8,1.6-2.6,4.3-2.4c0.8,0.1,1.3,0.4,1.1,1.2c-0.2,0.7-0.7,0.6-1.3,0.3c-1.5-1-1.7,0.1-1.7,1.2c0,2.3-0.4,4.6,0.4,6.8
				c0.3,0.9,0,2-1,2c-1.1,0.1-0.8-1.1-0.8-1.8c0-1.3-0.2-2.6-1.3-4.3c0.1,3.9-0.5,7.2,0.8,10.3c0.5,1.2,0,2.1-0.1,3.2
				c-0.6,5.5,1,11.1,0.2,16.6c-0.9,5.8,1.6,11.4,0.8,17.2c-0.2,1.2-0.3,2.4-1.7,2.2c-2-0.2-1.2-1.2-0.5-2.2c0.2-0.3-0.4-1.2-0.8-1.6
				c-2.8-2.8-1.8-5.4,0.4-8c0.4-0.4,0.6-0.8,0.5-1.5c-0.7-2.9-3.1-4.9-4-7.7c-0.1-0.3-1.2-0.6-1.4-0.4c-1.4,1.1-2.7,3.5-4.3,0.1
				c0,0-2,0.6-0.9,1.9c0.8,1,0.5,2,0,3c-1.7,3-2,6.2-1.5,9.6c0.3,1.8,0,3.7-0.4,5.7c-0.7,3.3-2.2,6.8,2.1,9.1
				c0.9,0.5,1.6,2.2,0.9,3.2c-1,1.7-2-0.6-3.5-0.4c0.1,4.8,0,9.7,0.3,14.6c0.2,3.4,0.8,6.3,3.9,8.2c1.1,0.7,0.8,1.4-0.5,1.7
				c-1,0.2-2.4-0.4-2.5,1.3c-0.1,2.1-0.8,4.7,1.1,6.1c1.5,1.2,1.7,1.2,0.2,2.7c-1,1-0.2,3.2,0.6,4.8c2.1,3.8,3.4,7.7,2.3,12.2
				c-0.3,1.3-0.1,2.9-0.3,4.4c-0.2,1.3,0.4,1.8,1.7,1.5c1.1-0.3,2,0,2.3,1.2c0.3,1.2-0.5,1.7-1.6,2c-2.6,0.8-2.3,2.9-2.2,5.2
				c1.6-0.7,2.7-3.7,4.3-1.6c1.6,2.1-1.4,2.6-2.4,3.7c-0.5,0.5-1.2,0.8-1.9,1.3c1.2,0.5,3,1,0.7,2.6c-1.4,0.9-1.9,2-0.6,3.5
				c1.1,1.3-0.8,2-0.8,3.2c-0.1,2.4,0.2,4.7,0.1,7.2c-2.3-2-3.2-4.8-4.1-7.6c-0.1-1.8,0.7-4-2.1-4.6c0.1-0.3,0.1-0.7,0.2-1
				c1.7,0,2-1,1.5-2.4c-0.7-1.8-0.3-3.6-0.4-5.4c0-2.6,0.3-5.3-0.5-7.9c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.2
				c0.3-6.5-0.4-13.1-0.2-19.6c0.2-7.7-0.3-15.5-0.5-23.2c0-1.2,0.3-2.4-0.6-3.4L841.9,1787.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1014.9,1714.1
				c1.7-1.1,8.5-1.5,9.9-0.5c1.1,0.8,1.1,1.6,0,2.3c-1.8,1.1-1.9,2.7-1.6,4.6c1.5-0.1,2.1-1.4,3.1-2.2c5.4-4.9,10.8-9.8,16.2-14.7
				c0.7-0.6,1.2-1.3,2.2-0.9c0.9,0.4,0.6,1.3,0.6,2c0.6,14.2,1.3,28.5,1.9,42.7c0,0.7,0.1,1.9-0.2,2.2c-2.3,1.4-2.8,2.6,0.1,4
				c0.6,0.3,0.4,1.2,0.2,1.8c-0.3,0.9-1,1-1.8,0.9c-2.9-0.2-5.8-0.3-8.7-0.4c-1-0.1-2-0.2-2.9-0.3c-4.2-3.6-8.4-7.2-12.7-10.8
				c-0.8-0.7-1.7-1.4-2.5-2c-0.9-0.9-1.8-1.9-2.7-2.9c-0.2-0.5-0.5-1.1-0.7-1.6c3.5-0.7,0.6-3,1.1-4.4c0-0.2,0-0.3,0.1-0.5
				c2.2,0.2,2-1.4,2.1-2.8c0.1-2.3-0.7-4.6,1.7-6.6c1.1-1,0.1-2.7-1.3-3.4c-0.5-0.3-1.3-0.3-1.9-0.5c-3.9-1-3.9-1-2.1-4.6
				C1015.2,1714.9,1015.2,1714.5,1014.9,1714.1z M1045.2,1749.1c-3.3-1.3-4.2,0.9-4.8,3.3c-0.2,0.9-0.6,2.1,0.9,2.3
				c1.4,0.2,1.5-0.7,1.5-1.9C1042.7,1751,1042.5,1749.1,1045.2,1749.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M514.2,1644.8
				c-0.3-9.4-0.6-18.7-0.8-28.1c2-0.3,3.8-0.8,5.6-1.7c1-0.5,2.7-0.5,1.9-2.6c-0.4-1.1,1-1.4,1.7-1.5c5.6-1.4,11.2-2.9,17-3.5
				c0.3,0,0.8-0.1,1,0c2.5,3.3,5.9,1.3,8.9,1.4c1.6,0,1.7,1,1.7,2.2c0,2.4-0.2,4.8,0.1,7.2c0.2,2.7-0.6,3.9-3.4,3.4
				c-0.5-0.1-1.1,0-2,0c0.8,1.7,1.2,3.2,0.7,4.9c-0.6,1.8-2.2,2.4-3.7,3.4c-2.4,1.6-3.5,4.3-4.1,7.1c-0.2,1,5.1,3.6,6.8,3.2
				c1.5-0.4,3-0.6,4.5-0.4c0.6,0.1,1.3,0.1,1.6,1.1c-6.1,2.1-12.5,2.8-18.7,4.4c-1.4,0.3-3,0.3-4.1,1.1c-4.5,2.9-9-0.5-13.5,0.1
				C514.4,1646.3,514.4,1645.4,514.2,1644.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M810.7,1881.3
				c-0.4,0-0.8,0-1.2,0c-1.6-1.2-3.1-1.4-4.7,0c-0.4,0-0.8,0-1.2,0c-0.4-0.9-0.9-1-1.4,0c-2.6,0-5.2,0.1-7.8,0.1
				c-0.5-1.1-0.9-1.1-1.4,0c-0.9,0-1.7,0-2.6,0c-0.7-1.2-1.4-1.2-2,0.1c-0.6,0-1.3-0.1-1.9-0.1c-0.7-1.2-1.4-1.2-2,0.1
				c-0.7,0-1.3,0-2,0c-0.9-1.3-1.6-1.2-2,0.4c-11.7,0.1-23.5,0.6-35,2.8c-3.4,0.6-6.4,1.1-9.7,0.3c0.1-1.1,0.3-2.1,0.4-3.2
				c0.1-0.5,0.1-1.5-0.1-1.6c-3.2-0.7-1.7-3.6-2.3-5.4c-1.5-3.9,0.6-6.2,4.9-5.5c0.9,0.1,2.1,0.7,2.3-0.2c0.4-1.2-0.9-1.4-1.9-1.7
				c-1.4-0.4-2.7-0.8-4.1-0.2c-0.4,0.2-0.9,0.1-1.1-0.4c-0.2-0.5,0-0.8,0.4-1.1c1.8-1.6,7.6-1.6,9.4,0.1c0.7,0.6,0.2,1.2-0.4,1.6
				c-1.1,0.7-1.2,1.6-0.3,2.4c0.8,0.7,2,1.4,2.9,0.4c1.4-1.6,3.1-1.3,4.9-1.2c3.7,0.8,7.4,0.4,10.8-1.3c2.3-1,5.2-0.1,7.1-2.4
				c0.4,0,0.8-0.1,1.2-0.1c1.7,1.3,3.9-0.3,5.7,0.9c-0.5,1.3-1.3,2.3-2.6,2.6c-4.3,0.9-8.3,2.6-13,2c-4.2-0.5-8.7,0.3-12.9,1.9
				c2.7,1.4,5.3,2.3,8.3,1.8c4-0.6,8.3-1,12.2-1.1c6.1-0.1,12.6-0.2,18.6,1c4.2,0.9,7.3,0.4,10.6-1.7c1.5-1,2.6-0.2,3.4,0.9
				c1.1,1.4,2.3,1.4,3.8,1c3.3-0.8,6.7-0.6,10.1-0.1c0.4,0.1,0.9,0.4,1.3,0.2c4.4-1.5,8.9-2.4,13.6-2.2c-1.4-1.7-3.4-0.6-4.8-1.7
				c-1-0.8-2.8-1.9-0.1-2.4c1.7-0.3,1-3.2,3.2-2.5c1.1,1.2-2.3,1.1-0.8,2.7c1.1,1.1,1.7,2.7,3.8,2.6c3.9-0.3,7.3,6.1,7.4,11.3
				c-0.4-0.1-0.9-0.1-1.3-0.2c-0.6-0.9-1.3-1-2,0c-5-0.1-10-0.3-15-0.4C816.6,1879.7,813.7,1880,810.7,1881.3z M816.7,1878.1
				c0-0.7-0.1-1.1-0.6-1.2c-0.6-0.1-0.7,0.4-0.7,0.8c0,0.5,0,1.2,0.7,1.1C816.4,1878.7,816.6,1878.2,816.7,1878.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M474.4,1935.4
				c-1.2-2.2,0.3-4.6-0.7-7.1c-1.6,0.8-3.3,1.3-4.9-0.2c-1-0.9-2-0.1-3,0.3c-1,0.4-2.1,0.7-3.1,0c-0.2-0.1-0.3-0.6-0.2-0.9
				c0.7-1.5,2,0.3,3-0.5c-0.7-1.8-0.9-4-3.7-3.9c-1,0-0.6-1.7-1.7-1.6c-0.5-1.4-2-1-3-1.5c3.7-6.3,5.8-13.3,9.3-19.6
				c0.4-0.7,0.8-1.5,1.2-2.2c1.1-0.1,2.1-0.2,3.2-0.3c1.7,0.3,3.6,1.1,5-0.7c0.6-1.1,2.8-1.1,2.3-2.8c-0.5-1.8-2.4-1.6-3.8-2
				c-1.5-0.4-3-0.3-4.5-0.5c0-0.2,0.1-0.3,0.1-0.5c0,0-0.2-0.1-0.2-0.1c2.4-0.7,4.8-0.2,7.2-0.2c0.9,0,1.9,0.2,1.9-1.1
				c0-1.1,0.7-2.5-0.8-3.2c-0.9-0.4-1.9-0.5-2.8-0.8l-0.1,0.1c1.4-0.6,2.8-1.1,4.4-1.1c1.8,0,3.6-0.2,5.4,0.6c2.8,1.3,5-1.5,7.7-1.6
				c1.9,7.2,4,14.4,4.1,21.9c-7.4,0.9-14.9,0.8-22.3,2.2c-3.4-0.8-6.7-0.2-9.8,1.3c-3.3,1.6-2.2,4.6-2.2,7.2c0,2.7,2.2,1.8,3.7,1.9
				c9.3,0.2,18.6,0.4,27.9,0.5c2.2,0,4.5,0.5,4.8-2.8c2.4,2.8,1.7,6.2,3,9.1c0.8,1.8-1.1,2-2,2.1c-1.8,0.1-2.1,0.9-1.6,2.3
				c1,2.7-0.6,3.1-2.7,3.1c0-0.8-0.3-1.8-0.1-2.5c0.7-2.6-0.7-3.1-2.8-3.4c-2.7-0.3-4.4,0.2-3.8,3.5c0.2,1,0.1,2.1,0.2,3.2
				c0.4,1.4,0.2,2.3-1.5,2c-0.3-0.9-0.5-1.8-0.8-2.8c-0.3-0.8,0.7-2.3-0.9-2.3c-1.6,0-1.4,1.4-1.2,2.5c0.4,2.1-0.4,2.4-2.3,2
				C479.5,1934.4,476.9,1934.2,474.4,1935.4z M484.5,1924.3c0,0,0,0.1,0,0.1c2.3,0,4.6,0,6.9,0c1,0,2.5,0.2,2.5-1.2
				c0-1.1-1.5-1.5-2.5-1.5c-0.9,0-1.9,0.4-2.8,0.6c-2.6,0.7-5.6,1.6-7.9,0.2c-2-1.2-3.4-0.8-5.1-0.3c-0.9,0.2-1.8,0.6-1.3,1.8
				c0.3,0.8,0.9,0.8,1.8,0.7C478.8,1924.5,481.6,1924.4,484.5,1924.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M281.6,1715.3
				c-0.2-1.2,0.4-2.1,1.6-1.6c2.5,1.1,2.9-0.4,3-2.2c0.1-3.9,0-7.8,0-11.8c0-1.6-0.7-1.6-2-1.4c-5.2,1-10.3,2.9-15.7,2.8
				c0-4.5,0.1-8.9-0.2-13.4c-0.2-3.2,0.8-5.8,2.7-8.4c2.9-3.9,5.9-7.8,7.9-12.3c2.5,0.1,5.3-0.6,7.4,0.4c2.5,1.2,0.9,4.3,1.3,6.6
				c1,6.6,0.3,13.2,0.6,19.8c0.5,12.9,0.7,25.8,0.7,38.7c0,5.2,0.4,10.3,0.5,15.5c0,1.2,0.3,2.4-0.6,3.4c-0.9,0.1-2.2-0.1-2-1
				c0.8-3.2-1.4-4.6-3.1-6.6c-0.9-1-1.2-2.6,1-2.6c2.5,0,2.7-1.5,2.3-3.2c-0.3-1.2,0.3-2.6-0.6-3.8c-4.8,2.7-10,4.5-14.2,8
				c-1.1,0.9-1.8,0.8-2.5-0.5c-1.3-2.4-1.2-5.1-0.5-7.3c0.6-2.2,3.3-0.7,5-0.9c0.9-0.1,1.8-0.1,2.6,0.1c2.4,0.5,4.2-0.5,6-2.5
				c-3.7,0-7-0.2-10.2,0.1c-2.6,0.2-3.6-0.9-3.7-3.3c-0.1-3.4-0.2-6.7-0.3-10.1c1.2-2.7,3.3-3.4,6.9-2.2c0.4,0.4,0.7,0.8,1.1,1.1
				c3.3,2.2,6.9,4.3,4.4,9.3c-0.2,0.5-0.2,1.1,0.6,1.2c0.8,0.1,1.2-0.5,1.2-1.2c0.1-2.9,1.5-3.3,3.7-1.7
				C287,1719.4,287,1719.4,281.6,1715.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M121.7,1748.3
				c0.6-0.9,1.8-1.2,2.6-1.2c5.5,0.1,11.1,0.1,16.5,1.2c-0.1,0.4-0.2,0.9-0.4,1.2c-6.7,8.5-13.4,17-20.2,25.4
				c-7.5,9.4-15.1,18.7-22.7,28.1c-0.8-1-2-2.3-0.7-3.3c3.7-3.1,2.2-7.4,3.3-11.2c0.8-2.9,0.6-6,1.6-9c0.7-2.1-0.1-5.2,0.2-8
				c0.8-7.3,1.5-14.7,2-22.1c0.2-2.6,0.8-3.5,3.4-3.2c2,0.2,4.2-0.3,5.9,1.3c4.9,3.5,9.8,6.9,14.5,10.4c1.4,1.1,2.2,1.2,3.3-0.4
				c1.2-1.5,1-2.3-0.5-3.3C127.5,1752.4,124.6,1750.3,121.7,1748.3z M107.4,1780.3c-1.1-1.1-2.3-0.8-3.6,0.1c1.2,0,2.3,0,3.5,0
				c1.3,1.1,2.6,0.6,3.9-0.1C109.9,1780.3,108.6,1780.3,107.4,1780.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M183.3,1970
				c8.5-5,17-10.1,25.5-15.2c2.2-1.3,4.3-2.8,6.5-4.2c4.8-3,9.5-6.1,14.3-9.1c0.6-0.4,1.1-0.7,1.7-1c0.6-0.3,1.3-0.7,1.8-0.2
				c0.6,0.6,0,1.3-0.4,1.7c-1.3,1.1-0.9,1.4,0.4,2.4c2,1.5,1.5,4.4,0.7,6c-2.3,4.7-1.5,9.4-0.2,13.9c1,3.5,0.5,6.6-0.1,9.8
				c-0.8,4.5-0.5,9.1-0.8,14.1c-1.9-1.9-4.3-1.9-6.4-2.7c-2.7-1.1-4.4,1.4-6.2,2.7c-0.9,0.7,0.3,1.8,1.1,1.6c4.3-1.5,3,1.7,3,3.6
				c0,1.9,0.2,4.9,0.8,6.1c-2.7-1.1-5.4-2.2-8.1-3.4c-2.4-1.2-4.8-2.6-7.1-3.9c2-1.9,4.5-2.8,6.7-4.3c0.9,0.2,1.4-0.4,1.9-1
				c1.1-0.5,2.2-1.1,3.3-1.6c0.7,0.3,1.3,0,1.6-0.7c0.5-0.3,1-0.6,1.5-0.9c0.8,0,1.7-0.2,1.6-1c-0.2-1.1-1.3-0.6-2.1-0.7
				c-0.6-0.4-1.2-0.7-1.8-1.1c-0.4-0.5-0.8-0.7-1.4-0.4c-2.6,0.7-4.8,1.9-6.3,4.3c-0.6,1-1.5,2-2.8,2.1c-5.3,0.2-10.8,1.7-15.9-1.3
				c-2.5-1.5-5.3-2.5-8.2-3.2c0.7-1.2,1.7-1.5,3-1.5c1,0,2.3-0.3,2.2-1.4c-0.2-3.7,2.1-3.6,4.7-3.4l0.1,0.2c-0.2,2.3,2.1,2.5,3,2.4
				c1.7-0.3,0.8-2,0.6-3.2c-0.1-3.2-2.3-4.2-5-4.5c-0.6-0.4-1.2-0.9-1.9-1.3c-1.7-0.4-2.9,0.2-3.6,1.8c-2.3,0.7-4.6-0.6-7,0
				C183.6,1971.5,183.2,1970.8,183.3,1970z M223.7,1961c0-2.1,0-4.1,0-6.2c0-2.3-1.4-3.3-3.3-2.1c-6.3,3.9-12.6,7.9-18.8,12
				c-1.2,0.8-1.4,1.7,0.1,2.4c6.4,3.2,12,7.8,18.7,10.7c2.8,1.2,4,0.5,3.3-2.4C222.4,1970.5,224.4,1965.8,223.7,1961z M228.4,1954.8
				c-1-0.8-0.7-1.6-0.8-2.3c-0.1-0.8,0.2-2-1-2.1c-1.4,0-1.7,1.1-1.7,2.3c0,7.5,0.1,15,0.1,22.5c0,2.7,2.1,3.9,4.1,4.3
				c1.6,0.3,1.2-2.3,2.3-3.2c0.3-0.3,0.1-1.2,0.1-1.9c-0.2-7.5-0.5-15-0.7-22.5c0-1.2,0-2.5-1.3-3.6
				C228.1,1950.5,229.5,1952.8,228.4,1954.8z M222,1950.3c3.1-0.9,5.7-2.3,8.3-4.1c0.5-0.4,1-1,0.6-1.7c-0.3-0.6-0.9-0.5-1.4-0.2
				C226.8,1946.1,224,1947.6,222,1950.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1043,1688.5
				c2.4-0.5,1.4,1.6,2.1,2.7c1.9-1.2,3.1-2.9,4.3-4.7c0.8-1.2,2.1-1.7,3.4-1.4c1.1,0.2,1.2,1.4,1.3,2.4c0,0.4-0.2,1.1-0.4,1.1
				c-4.7,0.9-6.8,5.2-10.1,7.8c-5.2,4.2-10.1,9-15.1,13.5c-0.7,0.6-1.3,1.4-2.5,1.2c-13.3-3.1-26.7-1.7-40.1-1.5
				c-10.8,0.1-21.5,1.6-32.2,2.6c-1.1-0.4-1.7-1-1.4-2.2c0.3-1.2,1.5-1,2.4-1.3c0.7-0.3,2,0.2,2-1.1c-0.1-1.2-1.2-1-2-1
				c-2.4,0-1.9-1.6-1.6-3c1-5.1,2.7-10.1,1.8-15.4c-0.3-1.7,0.9-2.3,2.3-2.5c1.6-0.3,3.2-0.5,4.8-0.7c1.9-0.2,4-1.2,4,2.2
				c0,1,1.1,1.2,2.1,1l0,0c-0.3,0.6-0.6,1.3-0.9,1.9c-3.7,0-2.2,2.1-1.6,3.9c0,1,0.1,2,0.1,3c0.3,2.2-1.2,4.9,1.7,6.5
				c0,0.4,0.1,0.9,0.1,1.3c-0.3,2.8,0.4,3.5,3.1,2c2.5-1.3,4.9-2.8,7.9-2.7c1.4,0.1,3.4-0.6,2.4,2.2c-0.3,1,0.7,0.9,1.3,0.9
				c2.6-0.1,5.2,0.2,7.8-0.3c1.9-0.4,3.9-0.9,5.7,0.2c2,1.2,3.7,0.9,5.3-0.5c0.8,0.1,1.5,0.2,2.3,0.3c1.5,0.6,3,1.6,4.8,0.4
				c-0.8-0.9-1.6-1.5-2.7-1.2l0.1,0c-0.4-0.8-1.2-0.7-1.9-0.8c0.8-2.3,2.9-3.8,4.6-4.9c2.2-1.4,5.3-1.6,7.9-2.3
				c6.2-0.9,12-2.8,17.8-5c0.8-0.3,1.8-0.3,1.9-1.5l0.1,0c0.8,0.2,1.6,0.5,1.9-0.6l0.1,0c1.9,0.1,3.3-0.7,4.6-1.9l0,0
				C1042.5,1688.6,1042.7,1688.5,1043,1688.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M875.8,1877.9
				c0.1,2.6,0.8,5.2-1.9,7c-0.9,0.6-0.6,1.6-0.5,2.4c0.3,2.2,0.5,4.3,0.8,6.5c0.6,4.6,0.6,4.4,5.4,4.7c7.5,0.4,15.1-0.1,22.6,0.5
				c4.1,0.3,8.5,0.5,12.7-0.2c1.9-0.3,2.9,0,2.8,2.3c-0.2,3.8,0,7.6,0,12.1c-2-4.5-5.6-3.4-9-3.2c-9.6,0.7-19.1,0.5-28.6-0.6
				c-1.2-0.1-2.4-0.4-3.4,0.7c-1.6-0.5-2.1-1.5-1.7-3.3c0.8-3.8-2.4-7-6.3-6.5c-1,0.1-1.4,1-1.9,1.6c-0.5,0.7-1,1.2-1.9,0.6
				c-0.8-0.6-0.8-1.2-0.1-1.9c1.2-1.2,0.2-1.8-0.8-1.9c-1.7-0.3-2.2-1.5-2.8-2.8c-1.8-3.9-3.4-7.8-5.3-11.5
				c-1.1-2.1-2.3-4.8,1.6-6.6c1.3-0.6,2.1-3.7,3.2-5.6c1.4-2.3,2.4-1.4,3.3,0.1c1.4,2.6,0.9,5.9,2.7,8.6c0.8,1.2-1.7,0.7-2.2,1.9
				c1.6,1.8,3.4,2,4.8,0.1c1-1.4,2.2-2.3,3.7-2.3C874.9,1880.5,874,1877.8,875.8,1877.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M839.5,1714.8
				c1.1,11.2,0.5,22.5,1.1,33.7c0.2,3.7,0.3,7.4,0.4,11.1c0,1.1,0.1,2.2-0.8,3c-7.1,1.2-14.2,2.7-21.5,3.3c-1.3-1.7,0.2-3,0.6-4.5
				c1.2-3.1,2.6-6.2,3.5-9.4c2.2-7.7,4.5-15.4,1.9-23.5c-0.5-3.1-1-6.1-1.3-9.2c-0.1-1.1-0.6-2.4,0.5-3.3c3.5-0.5,7.1-1,10.6-1.6
				C836.2,1714.2,837.9,1713.8,839.5,1714.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1058,1816.9
				c-6.5,6.1-14,11-21.1,16.3c-4.6,3.5-9.2,7.1-13.8,10.7c-1.4,0-2.7,0-4.1,0c-0.2-0.7-0.4-1.3-0.6-2c1.4-0.8,3.3-0.5,4.8-2
				c-1.6-1-2.7-2.4-4.7-1.9c-0.1-0.6-0.2-1.3-0.3-1.9c1.3-1.1,4.2,0.3,4.4-1.8c0.1-1.4-2.6-1.3-3.8-2.4c-0.2-0.2-0.6-0.3-0.9-0.4
				c-0.2-0.2-0.2-0.3-0.2-0.6c0.8-1,2-1.1,3.1-1.3c0.7-0.1,1.7-0.2,1.7-1.1c0-0.9-1-1.2-1.6-1.3c-1.3-0.2-2.2-1.2-3.4-1.6
				c-0.1-0.3-0.2-0.6-0.3-1c0.5-0.2,1-0.3,1.4-0.6c1-0.7,3.1,0.5,3.1-1.4c0-1.4-2-1.3-3.1-1.8c-0.4-0.2-0.8-0.2-1.2-0.4
				c-0.1-0.2-0.2-0.5-0.3-0.7c0.3-1.5,1.6-1.5,2.7-1.8c0.6-0.1,1.5,0,1.6-0.8c0.1-1-0.9-1.1-1.5-1.3c-0.9-0.3-1.8-0.9-2.8-0.7
				c-0.6-0.7-0.8-1.4-0.6-2.3c1.5-0.6,4.2,0.7,4.4-1.5c0.1-1.7-2.6-1.4-4-2.1c0-0.4-0.1-0.9-0.1-1.3c1.3-0.8,4-0.5,3.9-2.1
				c-0.1-1.5-2.5-1.9-4.1-2.5c-0.1-0.4-0.2-0.8-0.2-1.2c1.2-1.3,3.3-0.9,4.4-2.2c-0.4-1.7-3.3-0.4-2.8-2.6c0.1-0.8,1.1-0.4,1.7-0.5
				c6.5,4,13,8,19.5,11.9c5.5,3.3,5.5,3.4,1.6,8.4c-1.1,1.4,0.5,6.6,2.1,7c0.3,0.1,0.7,0.1,0.9-0.1c4.2-2.9,8.8-5.5,11.4-10.2
				c0.9-0.5,1.8-1.3,2.8-0.3C1059.4,1814.5,1057.9,1815.7,1058,1816.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M247.4,2092.5
				c-3.4-3.4-7.8-5.3-11.9-7.6c-3-1.7-4.4-3.5-3.7-6.8c0.1-0.5-0.1-1-0.2-1.6c0.1-0.5,0.2-1,0.3-1.6c1.2-1.2,1.2-2.5,0.4-3.9
				c0.2-2.2,1-4.4,0.5-6.6c5.1-23.7,6.2-48,10-71.9c0.9,5,0,10-0.6,14.9c-1.5,13.9-3.2,27.8-4.9,41.7c-0.3,2.4,0.2,3.9,2.7,5.2
				c4.7,2.3,8.5,5.9,13.4,7.9c3.6,1.4,2,4.8,2.5,7.4c-0.1,0.9-0.3,1.8-0.4,2.6c-1.4,7.1-2.5,14.2-2.9,21.5
				C250.5,2094.9,249,2093.3,247.4,2092.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M373,1752
				c0.6,0,1.3,0.1,1.9,0.1c1.6,0.6,3.1,1.3,4.7,1.9c0.8,1,1.9,1.4,3.1,1.5c13.6,0.7,27.2,1.5,40.8,2.2c2.2,0.1,4.3,0.8,6.5,0.2
				c2.3,0.6,4.6,0.2,6.8,0.3c1.1,0.1,2.3,0.3,2.1,1.5c-0.2,1.6-1.3,0.2-2,0.2c-12.8,0.4-25.4-2.2-38.2-2.1c-6.2,0.1-12.4-1-18.6-1.3
				c-11.7-0.6-23.3-1.5-35-1.8c-10.7-0.3-21.3-2.1-32-1.8c-6.4,0.1-12.6-1.2-19-0.8c-0.9,0.1-1.7,0-2.2-1c1.8,0.3,4.3,1.6,4.5-1.4
				c0.2-3.7,0-7.7-4.5-9.4c-1.2-1.9,0.6-3.4,0.8-5.2c0.1-0.7,1-0.8,1.6-0.7c0.9,0.1,1.3,0.7,1.4,1.5c0.2,1.9,1,4.5,2.9,3.9
				c2-0.6,1-3.4,0.6-5.2c-0.6-2.5-3.2-3.8-6.3-3.5c-0.5-1,1.1-0.9,1-1.7c1.3-1.7,4.4-1.5,4.9-3.4c0.5-1.8-1.3-3.6-2.2-5.4
				c0-0.7-0.3-1.1-1-1c-0.7,0.1-1.2,0.5-1.1,1.3c-0.1,0.4-0.2,0.8-0.3,1.3c-0.4,0.2-0.4,0.5-0.2,0.9c-0.7,1-1.1,2.3-2.3,2.9
				c-0.2-0.9-0.4-1.9-0.6-2.9c1.9-0.5,2.8-1.2,2.2-3.6c-0.6-2.5-0.5-5.2-0.7-7.9c2.1,0.1,4.1-1.2,6.2-0.6c0.9,1.3-1.3,4.8,2.8,3.7
				c0,0.5,0.1,1,0.1,1.4c-2,1.7-3,3.5-1,5.8c0.7,0.8,0.3,1.3-0.2,2.2c-1.1,2.4,0.7,4.6,1.2,6.8c0.3,1.5,2.2,0.9,3.5,0.9
				c1.7,0,3,0.4,3.2,2.4c-0.2,0.4-0.3,0.7-0.5,1.1c-1,0.1-2.1,1.7-3.1-0.3c-0.4-0.8-1.5-0.8-2.4-0.5c-1,0.4-1,1.3-0.9,2.2
				c0.2,3.9,0.9,7.7,0.7,11.7c-0.1,1.8,0.9,3.1,3.2,3.3c1.6,0.1,3-0.2,4.4-0.6c3.2,1,6.5,0.3,9.7,0.5c2.3,0.1,1.9-1.7,1.9-3
				c-0.2-8.2-0.4-16.3-0.6-24.5c-0.1-5.4-0.1-10.9-0.4-16.3c-0.2-2.9,1.8-2.5,3.4-2.8c2.3-0.4,1.7,1.4,1.7,2.6
				c0.7,13.7,1.4,27.4,1.9,41.1c0.1,2.6,1.1,3.6,3.8,3.6c2.9,0,5.9,0.4,8.8,0c0.6,0.7,1.4,0.7,2.2,0.5c3.4,0.6,3.9-1.4,3.5-4
				c1-0.2,1.4,0.2,1.4,0.8c-0.3,3.4,2,4.2,4.5,4.1c3.6-0.2,7.3,1.2,10.8-0.6c1.3-0.6,2.9-0.8,4.4,0.9
				C368.8,1755.8,371.8,1754.6,373,1752z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M278.9,1667.1
				c-2,4.5-5,8.4-7.9,12.3c-1.9,2.6-2.9,5.2-2.7,8.4c0.2,4.4,0.1,8.9,0.2,13.4c-3.2-0.3-6.1,1.1-9.1,1.7c-1.8,0.4-3.1-0.2-2.8-1.9
				c0.3-1.6-2-3.6,0.7-4.9c0.1,0-0.9-2.2-1.4-3.3c-0.5-1.2,0.8-3.5-1.1-3.6c-2.3-0.1-1.1,2.3-1.4,3.6c-0.6,3.1-1.1,6.2-1.3,9.3
				c-0.2,2.2-2,2.3-3.7,2.3c-1.6,0-2-0.6-2-2.4c-0.2-8.2-0.5-16.4-0.6-24.6c0-3.2-0.3-6.5-0.4-9.7c0-1.5,0.1-2.8,2-2.4
				c1.8,0.4,4.3-1.2,5,2.1c0.8,3.7,0,7.5,0.9,11.3c0.4,1.8,1.1,2.3,3,2.6c-1.4-4.2,1.4-8.1,0.3-12.3c-0.4-1.7,0.6-2.9,3-2.5
				c2.1,0.3,4.4,0.5,6.5-0.2c0.6,0.9,2.1,1.1,1.8,2.7c-0.1,0.7,0.3,1.5,1.3,1.6c0.9,0.1,1.4-0.5,1.8-1.2c0.4-0.7,0.6-1.4,0.9-2.1
				C274.2,1667.1,276.6,1667.1,278.9,1667.1z M254.6,1683.3c0,1.2-0.4,2.1-0.8,3.1c-0.4,1,1.5,1.4,2.2,0.3
				C257.2,1684.9,255.1,1684.4,254.6,1683.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.5,1845.8
				c-0.7,0.5-1.4,0.8-2.1-0.1c0.2-2.1,0.5-4.3,0.7-6.4c0.1-1.1,0.7-2.4-0.2-3.1c-1.1-0.8-2.2,0.2-3.3,0.8c-4.2,2.4-8.5,4.7-12.8,7.1
				c-0.6-1.8-1.5-1.5-2.9-0.8c-1.7,0.8-3,2.9-5.9,2.2c4.5-4.5,10.9-5.5,15-9.7c0.4-0.2,0.7-0.4,1.1-0.5c1.4,0.2,2.3-0.2,2.8-1.6
				c1.2-0.5,2.4-1.1,3.6-1.6c1.8-0.9,1.9-2.1,0.3-3.1c-8.4-5.4-16.9-10.7-25.4-15.9c-1.4-0.9-2.6-0.6-2.7,1.4
				c-0.3,10.3-0.5,20.6-0.7,30.9c-0.3,0.1-0.6,0.3-0.9,0.3c-6.6,1.2-7,0.9-6.9-5.7c0-9.8,0.2-19.6,0.1-29.4c0-2.6,1-3.3,3.3-3.8
				c3.5-0.7,6.7-0.8,9.3,1.9c17.2,10.9,34.4,21.8,51.6,32.6c1.9,1.6,1.3,3.8,0.1,4.9c-1.1,1.1-2.7,0.2-4.1-1.2
				c-2.2-2.2-4.9-3.8-7.7-5.2c-2.1-3.4-5.8-4.7-9.1-6.3c-1-0.5-2.3-0.3-2.3,1.6C209.1,1838.6,208.8,1842.2,208.5,1845.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M315.8,1633.2
				c-6.2-0.9-12.4-1.7-18.6-2.6c-1-0.1-1.8-0.6-2.6-1.2c-1.1-0.7-2.1-1.3-3.4,0.2c-1.2,1.4-3.1,0.1-4.6,0.2
				c-1.2,0.1-2.4-0.1-3.6-0.2c0-4.7-3.3-8-5.4-11.9c0,0-0.2,0-0.4,0.1c-3.7,3.1-2.1,7.1-1.8,10.9c-2.1,0.4-4.2-0.1-6.2-0.5
				c-0.1-1.8-0.3-3.7-0.4-5.5c-0.1-1.1-0.3-2-1.4-2.7c-2.8-1.7-1.8-2.9,0.7-3.9c-0.4-1.1-2.5-0.7-2.1-2.1c0.3-1.1,1.6-1.1,2.8-1.1
				c2.3,0,4.5,0.2,6.8,0.9c1.4,0.4,3.7,0.9,4.4-1c1.1-2.8,3.3-3.1,5.5-3c4,0.1,8.1-1.3,11.9-0.3c2.9,0.8,5.6,0.1,8.3,0.1
				c2.7,0,5.8,0.4,8.1,2.2c1.2,1,2.3-0.1,3.4,0.5c0.8,0.5,2,0.9,1.7,2.1c-0.3,1.3-1.5,1.1-2.4,0.7c-2.1-1-4.1,0.4-6.1,0.4
				c-1.1,0-1.1,0.8-1,1.7c0.5,3.2,1.4,6.3,3.8,8.7c-1.6,3.1,1.2,4.5,2.4,6.6C315.8,1632.7,315.8,1632.9,315.8,1633.2z M303.2,1625.9
				c-0.3,2,0,3.7,2.2,4.4c0.7,0.2,1.7,0.5,2.1-0.3c0.7-1.7-0.1-3.1-1.6-4.1c-0.6-1.8-1.2-3.7-1.8-5.5c-0.7-2-2.8-2.7-4.1-2.3
				c-2,0.5-0.4,2.5-0.1,3.6C300.3,1623.3,301.1,1625.2,303.2,1625.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M399.2,1931.9
				c-1.9,4.3-2.1,9-2.8,13.5c-0.3,2.2-1.4,3.5-3.1,4.5c-0.3-0.3-0.5-0.4-0.5-0.6c-0.3-1.4,1.7-3.6,0.1-4.1c-2-0.7-4.8-0.7-6.6,0.3
				c-2,1.1-0.5,3.7,0.7,4.5c1.7,1.1,1.3,2.2,1.4,3.5c0.3,7.5-1.2,15-0.7,22.4c0.3,4.9-0.2,9.4-0.6,14.2c-0.6,6.7-2,13.1-2.8,19.7
				c-0.1,0.9,0.9,1.9-0.3,2.8c-0.6-0.9-0.2-1.8-0.2-2.7c0-0.7-0.3-1.5-1.1-1.3c-3.2,1.1-2.3-1.2-2.3-2.6c0.1-10.4,0.9-20.8,1.1-31.2
				c0.2-7.9,0.7-15.9,0-23.9c-0.1-1.4,0.4-3.2-1.6-3.4c-2.2-0.2-0.7,2.1-1.9,3.1c-0.8-0.6-1.4-1.4-1.1-2.6c0.3-1.4,0.2-2.3-1.5-2.6
				c-1.3-0.2-0.9-1.3-0.9-2.1c0.1-1,0.8-1.1,1.7-1.1c1.2,0,2.4-0.2,3.6-0.3c0.5-0.1,1.1-0.3,1.1-1c0-0.7-0.7-0.7-1.2-0.8
				c-1.2-0.3-2.8,0.8-3.5-0.8c-1.1-2.2-2.6-4.3-1.8-7c5.8-0.3,11.5-0.7,17.3-0.9c2.3-0.1,3.2-1,2.5-3.3c0.7-1,1.4-1.7,2.5-0.5
				C396,1930.1,395.2,1932.6,399.2,1931.9z M384,1941c-0.1-0.8-0.8-1-1.4-1.1c-0.7-0.1-1.3,0-1.3,0.9c0,0.7,0.6,0.8,1.1,0.8
				C383.1,1941.7,383.8,1941.8,384,1941z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M288.8,1798.2
				c1.1,1.2,1.3,2.6,1.3,4.2c0,6,0,12,0.1,18c0.1,2.5-0.1,4.9-1.4,7.1c-0.5,0.4-1,0.8-1.4,1.3c-5.1,5.9-10.1,11.7-15.2,17.6
				c-1.6-0.1-1.7-1-1.9-2.4c-0.8-7.5-1-15,0.1-22.5c3.9,1,6.5,4.1,9.8,6.2c2.6,1.7,2.5,1.8,4.6-1.1c-4.5,0.1-7.4-2.7-10.5-5
				c-1-0.7-1.9-1.4-3.1-1.7c0-1.5-0.7-2.8-0.7-4.4c0.1-5.1,0-5.1,3.8-8c-1.4-1.2-2.3,0.8-3.6,0.4c0-0.9-0.1-2-0.2-3.1
				c3.5-3.2,6.3-7.1,9.3-10.7c1.3-1.5,2.4-1.7,3.8-0.4c1.3,1.2,3.1,1.9,3.5,4.1C287.1,1799,288.2,1798,288.8,1798.2z M279.7,1803.3
				c0.6,0,1-0.1,1.3-0.7c0.2-0.2,0.5-0.4,0.7-0.6c0.9,0,1.7-0.4,2.1-1.2c1.3-0.2,2.3-0.7,2.7-2.2c-1.6,0-2.3,0.9-3.1,1.8
				c-0.8,0.3-1.5,0.6-1.9,1.4c-0.2,0.2-0.5,0.4-0.7,0.6c-0.5,0-1,0.2-1.3,0.7c-0.3,0.2-0.5,0.4-0.8,0.5c-0.7,0.1-1.2,0.6-1.4,1.2
				c-0.8,0.2-1.6,0.3-1.9,1.3c1.2,0.3,1.7-0.4,2.3-1.1c0.7-0.1,1.2-0.5,1.4-1.2C279.3,1803.8,279.5,1803.6,279.7,1803.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M735.3,1889.7
				c14.4-0.2,28.9-0.4,43.3-0.5c7.3,0,14.1-3.1,21.3-3.7c2.9-0.2,5.8-1,8.7-1.3c1.5-0.2,3,0.4,3.1,2.2c0.1,1.9-1.7,1.1-2.7,1.4
				c-1.9,0.6-4.1,0-6.2,1.2c1.7,1.7,4.1,2.4,5,4.7c0.4,1,2.1,1.5,1.1,3c-0.8,1.3-2.1,1.2-3.5,1.2c-9.9,0-19.7,0.1-29.6,0
				c-13.6-0.1-27.1,0.2-40.7,0.8C735.2,1895.8,735.2,1892.7,735.3,1889.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M179.3,1716.8
				c-0.1,0.5-0.1,0.9-0.2,1.4c-0.3,0.1-0.5,0.2-0.8,0.2c-1.6-0.5-3.1-1.2-4.7-1.5c-5.1-1-5.9-2.3-5.2-7.4c0.4-2.9,0.2-5.9,0.3-8.8
				c0.1-1.6-1-1.8-2.1-1.4c-1.1,0.4-2.2,1.1-3.1,1.9c-1.8,1.6-3.1,1.2-4.4-0.6c-1.6-2.4,1.2-2.2,2-3.1c0.9-1,2.3-1.7,3.6-2.2
				c3.6-1.3,4.3-4.2,4.4-7.4c0.4-9.5,0.6-18.9,0.9-28.4c3.6,0.1,7.2,0.3,10.8,0.4c-0.1,6.8-0.3,13.6-0.4,20.3
				c-0.2,0.2-0.2,0.4,0,0.5c0.3,1.1,0.5,2.2,0.8,3.3c-0.1,1.8-0.1,3.5-0.2,5.3c-0.8,1-1,2.1-1,3.3c-0.2,7.4-0.4,14.8-0.7,22.2
				C179,1715.5,179,1716.1,179.3,1716.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.7,1923.5
				c0.2,8.8,1,17.5,0.5,26.3c-4.1-1.8-8.7-1.9-13-3.1c-1.9-0.5-2.7,0.5-3.1,2.3c-2.6-2.1-1.4-3.5,0.3-4.9c-2-0.4-3.9-0.3-5.1,1.7
				c-0.2,0.3-0.9,0.5-1,0.8c-0.6,1.3-3.2-0.9-3,1.8c0.1,1.6,1.7,2.1,2.5,3.3c-1.5,0.8-2.2,2.3-2.9,6.6c-1.6-3.4-3-6.1-4.1-8.9
				c-1.2-3.1-2.3-6.3-4.2-9.1c-1.2-1.7-1.3-3.6-0.3-5.5c0.8-1.4,1.3-3,1.8-4.5c1.1-3.6,2.7-4.4,6.7-3.2c-0.2,0.5-0.3,1.3,0.5,1.2
				c3.1-0.4,6.1,1.3,9.2,0.5c0.5,0.7,1.6,1.3,2.1,1c0.9-0.5,0.2-1.5,0-2.3c-0.3-1-0.6-2-1.1-3.7c2,1.3,3.8,1.6,5.6,1.7
				c0.6,0.1,1.2,0.3,1.8,0.4c0.6,1.1-0.3,3.5,1.4,3.2c1.6-0.2-0.1-2.1,0.1-3.3c0.4,0,0.7,0,1.1,0c0.1,1.2,0.1,2.4,0.2,3.5
				c0.1,1.1-0.5,2.5,1.3,2.8c0.2,0.3,0.3,0.7,0.5,1c0.1-0.1,0.3-0.2,0.4-0.3c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-1.2,0-2.3,0.9-3.2
				c0.9-0.9,1.4-2,0.1-3c0.6-0.6,0.4-1-0.3-1.4C694.4,1924.2,695.1,1923.9,695.7,1923.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M439.4,1823.5
				c2.4,7.9,1.9,16.1,2.1,24.2c0.1,2.8-0.1,4.3-3.8,3.4c-3.2-0.8-6.7-0.7-10-0.9c-1.6-0.1-1.9-0.4-1.6-2.2c1.8-10.1,3.1-20.3,5-30.4
				c1.3-6.6,1.7-13.3,3.7-19.7c0.8-2.6,0.9-5.3,1.3-8c1.3-8,2.9-15.9,1.7-24.1c-0.1-1,0.2-2.4,1.3-3.2c1.1,0.7,1,1.8,1,2.9
				c0.2,14.1,0.3,28.3,0.6,42.4c0,2.4-0.3,4.7-1.1,7c-0.7-1.6-1.3-3.1-2.3-5.5c-0.3,2.2-0.9,3.8-0.5,4.9
				C437.9,1817.4,438.4,1820.5,439.4,1823.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M268.7,1717.8
				c0.1,3.4,0.2,6.7,0.3,10.1c0.1,2.4,1.1,3.5,3.7,3.3c3.3-0.2,6.5-0.1,10.2-0.1c-1.8,2-3.6,3-6,2.5c-0.9-0.2-1.8-0.2-2.6-0.1
				c-1.7,0.2-4.4-1.2-5,0.9c-0.7,2.2-0.8,4.9,0.5,7.3c0.7,1.4,1.5,1.4,2.5,0.5c4.3-3.5,9.4-5.3,14.2-8c0.9,1.3,0.3,2.6,0.6,3.8
				c0.4,1.7,0.2,3.2-2.3,3.2c-2.3,0-1.9,1.6-1,2.6c1.7,1.9,3.9,3.4,3.1,6.6c-0.2,0.9,1.1,1.1,2,1c-0.2,0.7-0.7,1.5-0.1,2.3
				c-0.6-0.2-1.2-0.4-1.7-0.6c0-1.3-0.9-1.3-1.8-1.4c-8.2-0.6-16.3-1.1-24.5-1.7c-1.7-0.1-4,0.9-3.9-2.3c-4.2-0.4-1.1-3.3-1.7-4.9
				c-0.8-2.3,0.1-4,0.6-6.2c0.4-1.7,0.5-4,0.4-5.8c-0.2-3.2,1.1-6.4,0.1-9.4c-0.5-1.5,0.3-2.3,1.2-2.4
				C261.2,1718.5,264.9,1717.6,268.7,1717.8z M283.2,1750.9c0.8-0.4,2.2,0.8,2.8-0.3c0.7-1.1-0.3-2.2-1.1-3.2
				c-0.2-0.2-0.4-0.5-0.6-0.8c-1.4-1.3-1.8-4.2-4.1-3.6c-2.1,0.5-1,3.2-1.6,4.8c-0.9,2.1,0.2,2.8,2.1,2.8
				C281.5,1750.7,282.3,1750.8,283.2,1750.9z M273.6,1750.3c2.8,0.9,2.6-1.4,3.1-2.9c0.4-1.3-0.7-1.2-1.3-1.1
				c-1.8,0.4-3.8,1.1-4.2,3C270.9,1750.8,272.7,1750.1,273.6,1750.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M178.8,1734.6
				c1-2,3.1-1.5,4.6-1.8c10.1-2.1,20.2-4,30.4-5.9c1.4-0.3,2.8-0.1,4.2-0.1c0,6.7,0.7,13.5-0.6,20.2c-10-0.4-20-0.9-30-1.3
				c-0.7,0-1.5,0.3-2.2,0.4c-0.8,0-1.5-0.1-2.3-0.1c-3.9-0.2-4.3-0.5-4.2-4.6C178.8,1739.1,178.8,1736.9,178.8,1734.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M595.7,1393.3
				c-0.7-1.2-1.8-1.9-3.1-2.5c-3.4-3.1-3.3-8-5.8-11.6c-0.4-0.6-0.4-1.4-1-2c-0.6-0.5-1.2-1.1-2-0.5c-0.7,0.6-0.6,1.4-0.2,2.1
				c1.8,2.7,1.8,5-0.9,7.3c-2.1,1.8-1.9,2-3.7-0.7c-1-1.6-1.9-3.2-4.2-3.2c-0.4,0-1.1-0.5-1.1-0.5c1.3-2.8-1.8-3.4-2.6-5.1
				c-1.9-3.9,0.3-10.9,4.1-13.2c6.9-4.1,19.4-0.3,22.5,5.7c1.8,3.5,3.8,6.9,5.5,10.4c0.3,0.7,0.4,1.2-0.2,1.7
				c-0.7,0.6-1.2-0.2-1.8-0.4c-0.6-1.6-0.9-3.4-3.6-3.6c0.7,2.1,1.4,3.5,3.3,4c0,1.3,0.2,2.6,0,3.9c-0.1,1.1-1,2.1-2.1,2.2
				c-1,0.1-1.3-1.1-1.4-1.9c-0.2-1.5-0.8-2.5-2.3-1.8c-1.6,0.8-0.2,1.6,0.2,2.5c0.8,1.9,1.4,3.9,1.9,6
				C597.5,1393.4,596.4,1393.2,595.7,1393.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M115.3,1657.2
				c11.3,13,22.6,26,33.9,39c4.9,5.6,10,11.2,15,16.7c0.6,0.7,1.8,1.4,1,2.4c-0.6,0.9-1.8,0.5-2.8,0.3c-2.4-0.5-3.4-2.5-4.8-4.2
				c-3.3-3.9-4.4-4.2-8.6-1.2c-3.9,2.8-8,4.8-13.5,3.4c4.4-2.6,8.6-4.8,12.5-7.4c2.5-1.6,2.3-3.2,0.7-5
				c-10.8-12.4-21.7-24.7-32.6-37c-0.7-0.8-1.5-1.6-2.8-1.3c-0.7-0.7-0.6-2.5-2-2.1c-1.1,0.3-0.8,1.7-0.9,2.6
				c-0.4,2.7-0.9,5.4-1.1,8.1c-1.2,13.3-2.9,26.6-4.6,39.9c-2.6,0.3-3.3-0.6-3-3.3c2-16.6,3.8-33.3,5.6-49.9c0.1-1.2,0-2.4-0.6-3.5
				C109.6,1655.3,113,1654.3,115.3,1657.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1015.9,1756.8
				c1.1,0,2.2,0.1,3.3-0.1c3.1-0.4,4.5,0.8,3.9,3.9c-0.3,1.8,0.4,2.5,2.3,2.3c3.5-0.2,6.9,0.2,10.4,0.1c3.9-0.1,7.7,5.3,6.7,9
				c1-1.8,5.3-3.1,6.8-2c1.2,0.8,0.2,1.2-0.4,1.6c-8.6,6-17.2,12-25.8,18c-2.5,0.4-3.9-1.7-5.8-2.7c-0.2-0.9-1.2-0.6-1.7-1.1
				c0.4-0.4,0.7-0.9,1.2-1.1c0.9-0.5,2.3-0.5,2.2-2c-0.1-1.3-1.5-1-2.3-1.5c-0.6-0.3-1.3-0.4-1.4-1.2c-0.1-0.7,0.4-0.9,0.9-1.1
				c1-0.5,2.8-0.3,2.7-1.8c-0.1-1.6-2.1-1.2-3.2-1.8c-0.4-0.2-0.8-0.1-1.3-0.2c0-0.2,0-0.4,0-0.6c0.3-1.8,2-1.4,3.1-1.9
				c0.6-0.3,1.4-0.3,1.5-1c0.2-1-0.8-1.1-1.5-1.4c-0.5-0.2-1-0.3-1.5-0.5c-0.7-0.3-1.4-0.6-1.4-1.6c0-1.2,1.1-0.6,1.7-0.9
				c0.9-0.4,2.2-0.3,2.3-1.5c0.1-1.2-1.3-1.3-2.1-1.6c-0.8-0.3-1.8-0.4-1.8-1.5c0-1.3,1.1-1,1.9-1.3c0.7-0.2,1.6-0.3,1.8-1.1
				c0.2-1-0.7-1.2-1.4-1.4C1015.8,1758.5,1015.8,1757.7,1015.9,1756.8z M1019.3,1785.8c-0.1,0-0.2,0-0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
				C1019.3,1785.9,1019.3,1785.8,1019.3,1785.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M601.8,1594.7
				c4,1.2,4.8,5.2,6.6,8.2c1.1,1.7,1.6,3.9,2.3,5.8c-7.6-2.1-15.4-2.2-23.2-3.1c-7-0.8-14.1-1-21.1-2.3c-4.9-0.9-9.8-1.2-14.7-1.7
				c-1.4-0.2-1.2-0.7-0.9-1.7c1.3-3.6,4.9-5.8,8.7-6.3c4.4-0.5,8.7,0.2,13.2-1.2c1.5-0.4,4.4,0.8,6.7,1c6.6,0.6,13,2.5,19.5,3.7
				C601.6,1597.6,601.8,1596.6,601.8,1594.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M462.2,1669.7
				c8.8-2.8,18.1-3.4,27.1-5.2c3.7-0.8,7.5-1.5,11.2-2.3c1.4-0.3,2.4-0.7,2.6-2.4c0.4-2.4,2.4-2.2,3.1-0.9c1.3,2.5,2.3,1,3.5,0.2
				c0.8,1.2,1.8,0.9,2.8,0.4c7.1,0.3,13.8-2.7,20.9-2.3c-0.2,1-1,1.7-1.8,1.6c-4.9-0.9-9.5,1.5-14.3,1.6c-0.9,0-1.9,0.1-1.7,1.4
				c0.2,1,1.1,1.6,1.9,1.3c3.3-1.2,6.9,0.1,10.2-1.4c3.9-1.7,9.6,3.4,8.6,7.6c-0.3,1.4-1.8,2.1-3.3,2.2c-8.9,0.6-17.5,3.2-26.3,4.2
				c-5.1,0.6-10.1,1.8-15.1,2.8c-0.9,0.2-1.8,0.4-2.4-0.5c-0.7-1-0.6-2.1-0.3-3.1c0.3-1,1.2-0.8,2.1-0.9c5.4-0.9,10.7-2,14-7.1
				c0.5-0.7,0.7-1.3-0.1-2c-0.8-0.7-1.2-1.9-2.9-1.6c-6.7,1.3-13.5,2.4-20.2,3.6c-6.1,1.1-12.2,2.1-18.3,3.1
				C463.3,1670.2,462.7,1669.8,462.2,1669.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M692.4,1711.7
				c-4.5-1.4-9-2.6-13.6-2.9c-2.4-0.2-3.5-0.9-3.5-3.4c0-4.6-0.2-9.3-0.3-13.9c4.2-0.7,7.7-2.9,11.2-5.2c1.8-1.2,4.1-0.1,6.2-0.7
				c1.5-0.4,3.2-0.5,4.8-1c1.2-0.4,2.3-0.5,3.3,0.5c1,0.9,2.3,1.2,3.7,1.1c2.6-0.3,3.3,0.9,3,3.3c-0.5,4.6-0.5,4.6-5,4.6
				c-1.3,0-2.7,0.2-4.2,0.2c0.8,2.1,4.2,1,3.7,3.3c-0.4,1.7-2.9,0.8-4.3,1.6c-1.2,0.7-2.6,0.9-4,1.4c-0.1,0-0.2,0.1-0.3,0.1
				c-1.1,0.6-2.4,2.2-1.6,2.8c2.6,1.8-2.6,6.8,3.1,7c1.4,0,2.9-0.5,4.1,0.6c-1.3,0.3-2.6,0.5-3.9,0.8
				C693.8,1711.4,693.1,1711.4,692.4,1711.7z M692.4,1693.8c1,1.2,1.9,0.9,3.1,0.7C694.5,1694.1,693.8,1693.5,692.4,1693.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M175.9,1845.1
				c0.2-10.3,0.4-20.6,0.7-30.9c0-1.9,1.2-2.2,2.7-1.4c8.5,5.3,17,10.6,25.4,15.9c1.6,1,1.5,2.3-0.3,3.1c-1.2,0.6-2.4,1.1-3.6,1.6
				c-1.3-0.1-2.3,0.3-2.8,1.6c-0.4,0.2-0.7,0.3-1.1,0.5c-1.7-0.5-2.5,0.9-3.7,1.5c-4.5,2.4-9,4.7-13.5,7.1
				C178.5,1844.9,177.2,1845.2,175.9,1845.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M304.6,1847.1
				c0.3-0.5,0.2-0.9,0-1.3c1.9,0.2,1.8-0.9,1.4-2.1c0.8-1.4-0.2-2.6-0.4-3.9c-0.1-0.5-1.6-0.8-0.9-1.4c2.8-2.7,0-5.7,0.4-8.5
				c0.3-1.8-1.1-2.3-2.5-1.3c-1.7,1.2-3.4,2.5-4.6,4.1c-0.8,1-1.2,2.1-2.6,2.2c-0.1-0.8-0.1-1.5-0.2-2.3c2.7-2.3,5.5-4.5,8.2-6.8
				c1.8-1.6,1.7-4,0.5-5.3c-1.6-1.6-2.4,0.8-3.3,1.8c-2.4,2.5-4.7,5.2-7.1,7.8c0.9-3.3-0.3-6.8,0.8-10.1c2.8-1.8,4.3-4.8,6.6-7
				c1-0.9,2-2.1,1.9-3.7c-0.1-2-2-0.4-2.8-1.3c-0.7-0.8-1.6-1.5-2.4-2.1c-1.3-1-2.2-2.4-3.9-2.8c0-1.8-1.2-3.8,0.7-5.4
				c2.3,1.9,4.2,4.3,7.6,5.9c-0.7-2.8-0.1-5.1-0.3-7.2c-0.2-2.2,1.3-5.2-2-6.5l0-0.2c0-0.5,0-0.9,0-1.4c2.6-0.3,5-1.9,7.8-0.8
				c0,0.6-0.1,1.3,0,1.9c0.9,6,2.1,12,2.1,18.1c0.1,13.2,0.4,26.3,0.6,39.5C308.2,1848.2,306.4,1848,304.6,1847.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M969.9,1850.9
				c0.2,0,0.5,0.1,0.7,0.1c-0.5,1-1.6,1-2.4,1.5c-1,0.6-1.8,1.8-1.5,2.9c0.3,1.1,1.6,0.8,2.5,0.7c0.4,0.1,0.9,0.2,1.3,0.2
				c-0.4,1.9-2.9,1.3-3.6,3c-0.3,0.7-1.7,0.6-1.3,1.6c0.3,0.9,1.3,1,2.2,1.1c0.8,0.1,1.6,0.3,3.1,0.5c-3.4,1.7-6,3.4-9.2,3.9
				c-1.1,0.1-2.4,0.8-2.8-0.6c-0.5-1.5,0.9-1.9,1.9-2.6c1-0.6,2.4-0.2,3.3-1.6c-1.4-0.3-2.8-0.5-4.1-0.8c-2.2-0.4-2.7-1.3-0.9-2.9
				c0.9-0.8,1.3-1.7,0-2.3c-3.2-1.6-1.3-2.6,0.3-3.7c0.7-0.5,1.5-0.9,2.2-1.3c-0.2-0.3-0.2-0.5-0.4-0.7c-1.1-0.9-3.3,0.3-3.6-1.5
				c-0.4-1.9,1.7-2.1,3-2.8c1.2-0.7,0.4-1.3-0.2-1.7c-3.4-1.9-3.4-1.9-0.2-4.2c0.2-0.1,0.2-0.3,0.3-0.5c-0.1-0.9-0.9-0.8-1.5-1
				c-0.8-0.3-1.8-0.5-2-1.5c-0.3-1.3,1.2-1.3,1.8-2c0.6-0.7,2.3-0.3,2.2-1.5c-0.1-1.5-1.6-1.1-2.7-1.2c-2.2-0.1-1.5-1.7-1.6-2.9
				c1.7,0,3-0.9,4.5-2c-1.4-0.2-2.8-0.3-4.2-0.5c-1.3-1.4-1.4-2.4,0.7-3c0.8-0.3,2.2-0.3,2.1-1.6c-0.1-1.5-1.4-0.5-2-0.8
				c-1.3-0.6-2.3-1.8-0.6-3.2c0.7-0.5,1.4-0.9,2.2-1.3c-0.3-0.7-1.1-0.8-1.7-1.2c-1.7-1-2.5-2.2-0.4-3.7c1.4-1,2.4-1.9-0.1-2.6
				c-1.9-0.6-2-1.5-0.3-2.5c1.6-0.9,1.5-3.1,3.1-4c0,0,0.2-0.1,0.2-0.1c2.2,1.6,4.8,2.1,8.4,2.2c-3,2.2-6,2-8.5,4.1
				c5.2,0.4,3.1,6.9,7.7,7.9c-1,1.5-3.9,0.9-3.7,3.2c0.2,2.4,2.9,1.3,4.1,2.5c-0.5,1.5-2.9,0.9-3,3c-0.1,2.3,2.1,1.8,3,2.6
				c0.1,0.7-0.2,0.9-0.6,1.1c-1.6,0.6-3.4,1.8-3.1,3.1c0.3,1.2,2.5,1.3,4.1,1c0.2,0.1,0.5,0.1,0.7,0.2c-0.2,0.4-0.3,0.9-0.6,1.1
				c-1.4,0.7-3.4,1.1-3.2,3.1c0.2,2.1,2.5,1.1,3.6,2c0.1,0.1,0.2,0.4,0.5,0.8c-2.5-0.3-3.8,1.6-3.9,3.2c-0.1,1.8,2.7,0.9,4,2.3
				c-2,0.6-3.9,1.7-3.5,3.9C966.5,1850.9,968.4,1851.4,969.9,1850.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M881.2,1678.8
				c4.8,0.4,9.6,0.8,14.4,1.2c0.6,0.6,1.2,0.5,1.9,0.1c1.8,0.1,3.5,0.2,5.3,0.3c2.6,1,5,1.7,7.2-1.1c1.2-1.6,3.5-0.9,5.2-1.4
				c3.2-0.8,6.5-1,9.7-1.5c2.2-0.3,4.5-1.3,5.6,2c0.4,1.1,1.9,0.7,3,0.8c5.4,0.4,10.9,0.8,16.3,1.1c2.8,0.1,3.2,1.6,3.1,4.1
				c-0.4,8.5-3.3,16.7-3.1,25.2c0,1.1-0.4,1.6-1.5,1.6c-1.2,0-1.9-0.4-1.8-1.7c0.1-6.7-0.6-13.4-1.2-20.1c-0.1-1.7,1.4-5.1-2.9-3.9
				c-0.1,0-0.5-0.1-0.5-0.3c-2.2-5.2-6.8-3.1-10.5-3.4c-3-0.2-6.1,0.2-9.2,1.2c-2.2,0.7-4.5-0.6-6.7-1.1c-1.7-0.4-3.1-0.9-4.5,0.7
				c-1,1.2-2.8,0.9-4.1,0.6c-7.9-1.7-16-1.4-24-2.5c-7.1-1-14.4-1.4-22.3-2.2c1.8-1.3,3.2-1.1,4.5-0.9c4.9,1,9.9,1.2,14.9,1.4
				C880.4,1679.1,880.8,1678.9,881.2,1678.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M178.6,1749.6
				c-0.1,2.9-0.6,5.9-0.2,8.8c0.5,3.7-0.3,7.3-0.4,11c-0.1,4-0.3,8-0.4,12c0,0.4,0,0.9,0,1.3c0,3.8,0,7.7,5.1,9.4
				c-2.3,1.2-4,0.7-5.7,0.8c-2-2-3.5-1.5-4.6,0.9c-0.6,1.4-0.8,2.8-0.6,4.3c-1,1.3-0.9,2.9-1.1,4.5c-2.2,1.9-2.3,0.5-2.4-1.4
				c-0.6-10.6,0.2-21.2,0.1-31.9c-0.1-6.3,0-12.6,0-18.9c0-0.6-0.5-1.5,0.6-1.3C172.2,1749.4,175.5,1748.2,178.6,1749.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M523.2,1880.9
				c6-0.1,12-0.1,18-0.2c1.8,0,2.5,0.7,2.2,2.3c-0.3,1.5,1.4,4-1.9,4.3c-1.5,0.1-3.1,0.5-4.5-0.1c-3.2-1.1-4.9,0.4-6.2,3.1
				c-0.8,1.7-0.4,2.2,1.4,2c3-0.3,6.1-0.5,9.1-0.8c1.4-0.1,2.3,0,2.3,1.8c0,4.2,0.1,8.5,0.2,12.7c0.1,1.6-0.4,2.2-2.2,2.4
				c-4.2,0.3-8.5,0.9-12.7,1.3c-2-8.3-4.1-16.7-6.1-25C522.3,1883.3,522.6,1882.1,523.2,1880.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M675.7,2033.6
				c-2.9-1.4-6.1-1.2-9.1-1.4c-4.2-0.3-4.1-2.7-3.9-5.3c0.5-6.2-0.8-12.4-0.2-18.6c0.2-1.8-1.9-2.8-2-4.7c0-0.5-0.7-0.8-1.2-0.2
				c-2.3,2.7-2.6,0.1-2.9-1.2c-0.6-2.5-2.7-4.3-2.8-6.9c0-0.4-0.3-0.9-0.8-0.9c-0.7,0-0.9,0.5-0.9,1.1c-0.1,0.6-0.4,1.6-0.1,1.9
				c2.2,2.1-0.7,4.5,0.5,6.6c0.2,0.4-0.4,1-1,1.1c-0.7,0.2-0.8-0.5-1.1-0.9c-0.6-1-0.5-2.1-0.4-3.2c0.3-3.1-1-5.3-3.2-7.2
				c-1.7-1.4-3.5-0.5-5.3-0.5c-1.9,0-2.6-0.6-1.7-2.2c1.5-2.9,2.6-6,3.8-9c0.3-0.8,0.6-1.9,1.7-1.5c1,0.3,0.9,1.3,0.9,2.2
				c0,3,0,6,0.6,9.1c0.4-3.5,1-7.1,1.2-10.6c0.2-2.9,0.6-5.8-0.7-8.5c2.2-0.5,3.4,0.3,3.4,2.6c0,1.3,0.1,2.6,1.5,3.3
				c-1,3.3-1.5,6.7-1.9,10.2c-0.3,2.1,1.3,2.2,2.3,3c0.5,0.5,1.5,1,2.1,0.4c0.9-1,0.4-2.2,0-3.4c-0.2-0.7-0.5-1.4-0.7-2.2
				c-0.3-1.1,0.4-1.3,1.2-1.4c4.3,9.7,8.7,19.4,12.9,29.1C670.7,2020.6,673.8,2026.9,675.7,2033.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M232.4,1841.2
				c-17.2-10.9-34.4-21.8-51.6-32.6c0.5-4,3.5-5,6.9-5.3c1.4-0.1,2.8,0,4.2-0.1c-0.2,1.1,0.7,1.5,1.4,2c3.7,2.7,7.4,5.3,11,8.1
				c2.5,1.9,6.3,3.3,4.7,7.8c-0.2,0.5,0.4,1,0.9,1.2c0.5,0.2,0.8-0.2,0.8-0.7c0.2-2.8,1.5-2.7,3.4-1.3c0.5,0.4,1.2,0.7,1.8,0.3
				c2.5-1.7,4.4,0.4,6.8,0.7c-1.1,2.1-4,1.8-4.3,4.3l0.1-0.1c-0.8-0.6-1.1,0-1.3,0.7c2.2,3.8,6.4,4.1,9.8,5.9
				c4.2,3.8,4.2,3.8,5.4-1.4c0-0.2,0.2-0.4,0.3-0.6c2.1,3.2,0.4,6.8,1.3,10.2C234.1,1841,232.9,1840.8,232.4,1841.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M405.7,1823.5
				c1-0.6,1.3-1.6,1.4-2.7c1-10.1,2.1-20.1,3.1-30.2c0.2-1.6-0.1-2.9-1.6-3.9c-4.1-2.9-8.1-6-12-9.1c-1.9-1.5-3-1.6-4.3,0.8
				c-1.8,3.3-3.6,6.7-6.3,9.5c-0.2-0.1-0.5-0.1-0.7-0.2c1.2-4,4.1-7,6.3-10.5c0.5-0.8,1.3-1.6,0.6-2.6c-0.7-1-1.7-0.8-2.7-0.6
				c-1.6,0.2-3.2,0.6-4.8,0.8c-0.1-0.4-0.3-1.2-0.3-1.2c2.6-0.7,5.1-1.9,7.9-1c0.6,0.2,1.2,1.1,1.7,0.6c4-3.6,9.2-2.5,13.8-3.7
				c5.2-1.4,10.5-2,15.8-2.9c0.7-0.1,2.4-0.6,2,0.3c-0.6,1.4,1.8,2.4,0,3.4c-1.1,0.6-2,0.6-2.4-1c-0.3-0.9-1.3-1.3-2.5-1.2
				c0.2,0.6,0.4,1.2,0.6,1.7c-7.4,0.4-7.4,0.4-8.2,7.5c-0.1,1.2-0.1,2.4-0.3,3.6c-0.3,1.8-0.6,3.7,0.6,5.3c-0.3,1-1.6,2.2-0.2,3.1
				c1.9,1.2,3.3,3.1,5.8,3.3c0.4,1.1,1.5,1.1,2.4,1.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0.2,0.1,0.2,0.1c-1.5,0.6-2.7,0.4-3.9-0.7
				c-1.1-1-2.3-2-3.6-2.8c-1-0.6-2.2-0.8-2.3,1c-0.9,9-1.8,18-2.7,27c-0.1,1-0.1,2.1,1.4,2c0.2,0.2,0.5,0.5,0.7,0.7
				c-2,0.6-2.9,1.9-2.9,4.1c0,2.3-0.4,4.5-0.6,6.8c-0.1,0.6-0.4,1.4,0.3,1.8c0.8,0.5,1.4-0.2,2-0.7c2.4-2.1,4.7-4.2,7-6.3
				c0.5,0.9-0.1,1.7,0,2.6c-3.4,3.7-10.3,4.8-9.2,12c-0.6,0.9,1.1,1.1,0.7,2c-1.9-0.2-2.8,0.6-2.6,2.6c0.1,1.1,0.1,2.1,0.1,3.2
				c-0.5,0.8-1.4,0.4-2.2,0.6c0.9-2.1,0.4-4.5,0.7-6.7c0.2-1.2-0.9-1.8-1.5-1.1c-2.1,2.3-5.5,3.6-6.1,7.1c-1.2,0.2-2.3,0.4-3.5,0.7
				c2.1-2.3,4.2-4.7,6.5-6.7c2.1-1.9,2.4-3.2-0.6-4.3c-0.8-0.3-1.4-0.9-2.1-1.4c1.5-0.7,2.6,1.2,4.1,0.8c1.8,2.1,2.8,2.2,3.7-0.9
				c1-3.7,0.8-7.5,1.5-11.3c0.1-0.7-0.1-1.3-0.8-1.6C405.7,1824.4,405.7,1824,405.7,1823.5z M396.6,1775.1c0.1-0.9-0.4-1.4-1.3-1.4
				c-0.3,0-0.6,0.1-0.5,0.5C395.1,1775.2,395.9,1775.2,396.6,1775.1c2.3,3.6,6.1,5.2,9.4,7.5c-1.3-1.9-2.9-3.6-4.4-5.2
				C400.2,1776,398.9,1774.6,396.6,1775.1z M410.4,1771c-3,0.4-5.4,0.7-7.8,1c-0.8,0.1-1.6,0.3-1.9,1.1c-0.4,1,0.3,1.6,0.9,2.2
				c2.2,2.1,4.5,4.1,6.6,6.3c1.6,1.7,2.5,1,2.8-0.8c0.4-2.3,0.6-4.8,1-7.1C412.5,1771.6,411.9,1770.7,410.4,1771z M407.5,1783.7
				c0.6,1.2,1,2,2.7,1.7C409.5,1784.3,409,1783.4,407.5,1783.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M180.5,1680.2
				c0.1-6.8,0.3-13.6,0.4-20.3c2.5-0.9,5-0.3,7.4,0.1c5.8,0.9,11.8-0.5,17.4,1.9c1.3,1.9,3.9,2.7,4.3,5.2c-9.5,4-17.8,10-26.3,15.7
				c-0.8,0.5-1.4,1.3-2.4,1.3c-0.3-1.1-0.5-2.2-0.8-3.3c0.1,0,0.2-0.1,0.2-0.1C180.6,1680.5,180.6,1680.4,180.5,1680.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M188.1,1982.2
				c2.9,0.8,5.6,1.7,8.2,3.2c5.1,3,10.5,1.6,15.9,1.3c1.3-0.1,2.1-1.1,2.8-2.1c1.5-2.4,3.7-3.5,6.3-4.3c0.3,0.8,0.8,0.8,1.4,0.4
				c0.6,0.4,1.2,0.7,1.8,1.1c0.2,0.6,0.3,1.2,0.5,1.7c-0.5,0.3-1,0.6-1.5,0.9c-0.9-0.7-1.5-0.4-1.6,0.7c-1.1,0.5-2.2,1.1-3.3,1.6
				c-1.4-1.1-1.8-0.3-1.9,1c-2.2,1.5-4.8,2.4-6.7,4.3c2.4,1.3,4.7,2.7,7.1,3.9c2.6,1.3,5.4,2.3,8.1,3.4c1.5,0.9,3,2,4.5,2.8
				c4.2,2.3,4.9,3.6,4,8.5c-0.9-0.2-1.8-0.1-2.8-0.7c-16.6-9.7-34.1-17.7-51-26.9c-0.3-0.7-0.8-0.9-1.4-0.6c-0.9-0.5-1.8-0.8-1.9-2
				c2.2-1.8,4.2-1.8,6.5,0C184.4,1981.7,186.2,1982.3,188.1,1982.2z M209.4,1989.2c-2.3-0.4-3.9-0.4-5.8,0.1
				C205.5,1991.8,207.2,1990.8,209.4,1989.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M180.6,1801.4
				c7.8-5.1,15.5-10.3,23.5-15.2c7.2-4.5,14.2-9.2,21.1-14c2.5-1.7,5.1-3.1,7.7-4.7c0.9,2.8,2.3,5.5-1.6,7.4
				c-1.2,0.6-1.2,2.8-1.1,3.8c0.6,4.4,0.5,8.8,0.7,13.2c0,0.5,0.5,0.9,0.7,1.4c-1.5,1.3-3.4,1.1-5.2,1.3c0-4.8-0.1-9.7,0-14.5
				c0-2-0.7-2-2.1-1.2c-2.6,1.6-4.5,3.5-3.9,7c0.5,3.1,0.5,6.2,0.7,9.4c-1.5,0.5-1.6-0.4-1.6-1.5c-0.1-3-0.3-6.1-0.3-9.1
				c0-1.9-0.9-2.3-2.4-1.6c-1.4,0.6-2.6,1.4-2.6,3.3c0,2.3-0.3,4.6-0.7,6.8c-0.2,1.2,0.5,3.6-1.2,3.4c-1.6-0.2-0.2-2.4-0.5-3.6
				c-0.4-1.9,1.3-4.5-0.4-5.6c-1.9-1.2-3.7,1.3-5.3,2.3c-4.5,2.9-9.7,4.9-13,9.5c-0.3,0.3-0.7,0.8-1,0.8
				C188.2,1799.9,184.5,1801.6,180.6,1801.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M232.6,1830.1
				c-0.1,0.2-0.2,0.4-0.3,0.6c-1.1,5.2-1.1,5.2-5.4,1.4c1.3-2.7,0.2-4.5-2.2-5.7c-2-1-4.1-1.5-6.3-0.8c0.2-2.4,3.2-2.1,4.3-4.3
				c-2.4-0.3-4.3-2.4-6.8-0.7c-0.6,0.4-1.3,0.1-1.8-0.3c-1.9-1.4-3.2-1.6-3.4,1.3c0,0.5-0.3,0.8-0.8,0.7c-0.6-0.2-1.1-0.7-0.9-1.2
				c1.6-4.5-2.1-5.8-4.7-7.8c-3.6-2.8-7.3-5.4-11-8.1c-0.7-0.5-1.5-0.9-1.4-2c2.1,0.6,4.2-0.2,6.2,0c5.8,4.3,11.6,8.6,17.5,12.7
				c2.9,2,3.3,1.7,3.9-1.6c0.9-4.8,0.3-9.6,0.3-14.3c1.3-0.1,1.4,0.8,1.3,1.7c-0.1,3.7,0.5,7.4-0.2,11.1c-0.3,1.6,0,3.3,0.4,4.8
				c0.7,2.2,2.8,1.3,4.2,1.7c1.4,0.4,0.9-1.3,1-2.2c1.2-5.3,0.3-10.6-0.2-15.9c-0.2-2.9,1.8-2.6,3.2-2.8c2.1-0.4,1.2,1.4,1.3,2.4
				c0.7,4.5,0.2,8.9,0.1,13.4c0,1.6,2.1,2.6,0.8,4.2c-0.6,1.6-1,3.2,0.5,4.7C232.4,1825.4,232.5,1827.7,232.6,1830.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M287,1753.2
				c0.6,0.2,1.2,0.4,1.7,0.6c1.4,4.4,0.6,9.1,1.1,13.6c0.7,6.3-0.1,12.7,0.5,19.1c0.2,2.3-0.1,4.7-0.3,7c0,0.9-0.6,1.4-1.5,1.4
				c-0.3-1.2-0.6-2.4-1.4-3.3c0.9-3,0.4-6,0.9-9.9c-3.2,3.9-5.4,2.1-7.6,0.2c-1.6-1.4-2.9-3.1-4.8-4.2c-1.3-0.7-1.9-2-1.4-3.6
				c3.8,0.1,7.6,0.2,11.5,0.4c3.1,0.1,1.4-2.4,1.8-3.5c0.5-1.7-1.1-1-1.9-0.9c-3.8,0.6-7.5,1.5-11.3,1.7c-0.2-0.5-0.3-0.9-0.5-1.4
				c1.2-5.6,2.3-11.2,3.5-16.7C280.5,1753.3,283.8,1753.8,287,1753.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M474.4,1935.4
				c2.5-1.2,5.1-1,7.7-0.5c1.9,0.4,2.7,0.1,2.3-2c-0.2-1-0.4-2.5,1.2-2.5c1.6,0,0.7,1.5,0.9,2.3c0.3,0.9,0.6,1.8,0.8,2.8
				c-0.7,2-2.4,0.8-3.7,1c-1.6,0.3-2,0.8-0.8,2.1c0.9,1,1.9,2,3.3,1.9c1.9-0.1,3.9-1.3,4.7,1.7c0.1,0.5,0.7,0.2,1.2,0.1
				c4.4-1.8,8.8-1.2,13.1,0.4c0.6,0.2,1,0.4,1.1,1c0.6,3.8,2.4,7.4,2.2,11.4c-6.4-1.2-12.8-2.1-19-4.5c-5.1-2-10-4-14.6-7.1
				C474.7,1940.9,474.5,1938.2,474.4,1935.4z M502.1,1944.2c-0.7-0.4-1.2-0.6-2,0.1C500.8,1944.8,501.4,1945,502.1,1944.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M323,1839.2
				c-1-10.1,0.1-20.1-0.6-30.1c-0.4-5.8-0.3-11.6,0-17.5c0.1-1.6-0.4-3-0.6-4.5c-0.6-4.1,2.7-2.9,4.5-3.3c2.8-0.5,1.4,1.6,1.5,2.7
				c0.2,10.8,0.5,21.5,0.6,32.3c0.1,9.2,0.2,18.5,0.3,27.7c0,1.8-0.3,1.5-1.9,1C322.2,1846.1,324,1842.2,323,1839.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M492.5,1884.1
				c-2.7,0.1-4.9,2.9-7.7,1.6c-1.8-0.9-3.6-0.6-5.4-0.6c-1.6,0-3,0.4-4.4,1.1c-2.4-1.5-2.4-3.3-1.2-5.8c3.8-8,7.2-16.1,10.8-24.1
				c0.4-0.8,0.5-1.7,1.5-1.9c1-0.1,1.3,0.6,1.5,1.4C489.5,1865.1,492.4,1874.4,492.5,1884.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M590.6,1400.6
				c1,4.9,1.5,9.4-2.9,13.2c-1.2,1.1-1.2,4.2-2.3,6.2c-1.3,2.3-3.8,2.8-5.4,1.8c-2.6-1.6-4.1,0.2-4.9,1.4c-0.9,1.4-2.9,2-3,4
				c-0.1,1.2-1.1,0.7-1.8,0.4c-1-0.6-3.1-0.4-2.6-2.2c1-3.4-0.6-5-3.4-6.3c-2-1-1.2-4.2,0.6-5.6c3.1-2.4,7.1-1.8,10.4-3.3
				c0.4-0.2,1.1,0,1.6,0.3c2.3,1.2,3.5,0,4.2-2c0.8-2.3,1.8-3.4,4.8-3.2C587.9,1405.3,589.7,1403,590.6,1400.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M595.5,1761.5
				c0.6,1.7-0.5,4,1.8,5c2,5.6,3.2,11.3,4.5,17.1c0.4,5.2,1.8,10.3,2.6,15.4c0.1,0.8,0.3,1.5-0.2,2.2c-2.6,0.7-5.4-1.5-5.6-5.1
				c-0.1-1.8-0.9-3-1.6-4.4c-3-5.7-4.4-12-7.5-17.7c-0.2-0.4-0.2-1.1-0.2-1.6c-0.2-10.7-0.1-21.3-0.7-32c-0.1-0.8-0.2-1.8,0.5-2.5
				c1.8,6.9,2.3,14.2,5.5,20.8C594.9,1759.6,595.2,1760.6,595.5,1761.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M811.2,1861.2
				c0.4,0,0.8-0.1,1.3-0.2c5-0.9,5.7-1.7,6.3-7.3c-3,0.3-4.5,4.8-8.2,3.1c-0.1-0.2-0.2-0.5-0.3-0.7c0.3-0.6,0.6-1.1,1.3-1.2
				c1.3-0.2,3.4-0.1,3.4-1.5c0.1-1.5-2.1-1.5-3.3-2.2c-0.5-0.3-1.2-0.6-1.8-0.2c-0.1-0.7-0.2-1.3-0.3-2l-0.1,0
				c0.3-0.2,0.6-0.4,0.9-0.7c1.3-0.2,3.5,0.7,3.6-1.2c0.1-1.6-2-1.8-3.4-2.2c-0.3-0.1-0.6,0.1-0.9,0.1c-0.1-0.6-0.2-1.3-0.3-1.9
				c0.7-0.4,1.4-1,2.2-1.3c0.8-0.3,2.3,0.6,2.3-0.9c0.1-1-1.2-1.5-2.1-1.9c-0.7-0.3-1.4-0.4-2.1-0.7c-0.2-0.4-0.4-0.7-0.5-1.1
				c0.4-0.8,0.9-1.4,1.9-1.4c0.8,0,1.9-0.1,2-1.1c0-1-0.9-1.7-1.9-2c-0.6-0.2-1.3-0.2-1.9-0.3c-0.2-0.7-0.3-1.3-0.5-2
				c0.4-0.2,0.9-0.3,1.2-0.5c1-0.8,3.2-0.1,3.2-1.5c0-1.6-2.1-1.9-3.4-2.5c-0.2-0.1-0.4,0-0.6,0c-0.5-0.6-0.7-1.2-0.5-2
				c4.9-1.3,4.7-1.2,6.8,3.1c1.6,3.3,2.2,7,4.2,10.1c0.9,1.4-0.5,1.8-1.7,2.4c2.4-0.3,3.5,0.7,4.1,2.7c0.4,1.5,1.1,2.8,1.7,4.2
				c0.9,1.9,1.8,3.8-1.8,3.1c-0.6-0.1-1.1,0.1-1.2,0.8c-0.1,0.9,0.5,1.1,1.2,1.1c0.7,0,1.3-0.1,2,0c2.5,0.4,3.1,2.3,3.2,4.3
				c0.1,2-1.8,1.8-3.1,2.1c-0.6,0.2-1.3,0-2,0c-0.9,0-1.5,0.3-1.5,1.2c0,1,0.7,0.8,1.4,0.8c0.7,0,1.7-0.3,1.7,0.9
				c0,0.7-0.7,1-1.4,1.2c-0.1,0-0.3,0-0.3,0c-3.2,4.1-6.8,0.9-10.3,0.5C811.2,1862.4,811.1,1861.8,811.2,1861.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M210,1667.1
				c-0.4-2.6-3-3.4-4.3-5.2c3.2,0.3,6.5,0,9.3,2c5.6,4.4,11.2,8.7,16.8,13.1c1.2-4.5,1-8.8,1.1-13.1c2.1-0.2,2.8,0.6,2.7,2.8
				c-0.3,12.8-1.2,25.5-0.8,38.3c0,1.2-0.2,2.1-1.4,2.7c-0.1-7-0.5-13.9-0.2-20.9c0.1-3.3-1.6-4.9-3.9-6.3c-1.3-0.9-2.6-0.7-2.3,1.5
				c0.2,1.7,0.2,3.5,0.2,5.2c0,7.3,0.6,14.5-0.1,21.8c-2.4,0.5-3.3-0.3-3.1-2.9c0.2-2.4,0.9-5.4-0.2-7c-2.1-3.2,0-5.9-0.1-8.7
				c-0.1-3.8-0.4-7.7-0.7-11.5c-0.2-3.2-3.3-5.1-6.4-6.1c-0.2-2.2-1.1-3.8-3.7-3.6C212.1,1668.5,211.1,1667.8,210,1667.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133,1562.3
				c-0.2,4.8-1.1,10.1-4.7,14.6c-2.9,3.6-6.5,3.4-9-0.5c-4.5-7-2.9-20.7,3.1-26.6c2.8-2.7,5.6-2.5,7.9,0.8
				C132.7,1553.9,133,1557.7,133,1562.3z M130.4,1560.7c0.1-2.2-0.5-4.3-1.4-6.3c-0.8-1.8-2.2-3.1-4.1-2.2c-2,1-1.4,3-0.5,4.6
				c0.9,1.7,2,3.2,2.2,5.3c0.1,1.1,1.2,2.7,2.5,2.6c1.4-0.1,1.3-1.8,1.4-2.9C130.4,1561.3,130.4,1561,130.4,1560.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M591.9,1881.2
				c-0.6-2.9-1.6-5.8,0.3-8.6c0.2-0.3,0.3-0.5,0-0.7c-4-2.4-1.8-6.4-2.1-9.6c-0.6-6.7-0.3-13.5-0.3-20.2c0-0.8-0.2-1.9,0.9-2.2
				c0.6-0.1,1.2,0.2,1.3,0.7c2,6.3,6.3,11.8,6.6,18.5c0.2,3.7,0.4,7.5,0.4,11.2c0,4.5,0.4,9.1,0.5,13.6c0,1.3,0.7,3.1-1.4,3.5
				c-1.9,0.3-4.2,0.8-5.1-1.9c-0.6-2.1,0.8-3.1,3.1-4.4C594.2,1881.2,593.1,1881.2,591.9,1881.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M178.8,1734.6
				c0,2.3-0.1,4.5-0.1,6.8c0,4.1,0.3,4.4,4.2,4.6c0.8,0,1.5,0.1,2.3,0.1c-0.6,1.5-1.9,0.8-2.8,0.8c-22.6-0.8-45.2-1.1-67.8-2.7
				c-4.8-0.4-9.6-0.8-14.4-0.7c-1.7,0-2.1-0.6-2-2.1c0.1-0.8,0-1.5,0.1-2.3c0.2-1.3-0.9-3.3,0.9-3.7c2.1-0.5,4.6-1,6.2,1.4
				c0.5,0.8,0.9,1.9,2.2,1.1c3.3,3.9,7.3,6.1,12.7,6c6.2-0.1,12.5,0,18.6,0.9c4.9,0.7,7.2-1.7,9.3-5.3c0.3-0.5,0.6-1.1,0.9-1.6
				c1.9,0.7,0.8,2.2,0.7,3.3c-0.2,2.6,0.7,3.8,3.5,3.7c4.2,0,8.5,0.2,12.7,0.4c1.9,0.1,2.4-0.7,2.5-2.5c0.2-5.9,0.3-5.9,5.9-7.2
				C175.8,1735.2,177.3,1734.9,178.8,1734.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M230.6,1764.7
				c-2.8,3.4-7.2,4.1-10.6,6.4c-1,0.7-1.2-0.4-1.2-1.2c-0.1-2.5-0.1-5-0.2-7.5c0-0.8,0.4-2-1-2c-1,0.1-1.2,1.1-1.2,2
				c-0.2,3-0.3,6.1-0.5,9.1c-0.1,1,0.1,2.6-1.4,2.4c-1.4-0.2-0.6-1.6-0.6-2.4c0-4,1.3-7.9,1.5-11.9c0.1-2.1-0.7-2.7-2.3-2.3
				c-2.6,0.6-2.6-0.8-2.5-2.6c0-1.3,0.2-2.6-0.4-3.9c9.6,0.1,19.3-0.9,29.4,1c-0.6,0.7-0.8,1-0.9,1c-2,0-5.6-0.5-5.7,0.1
				C231.8,1756.7,229,1760.3,230.6,1764.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M886.5,1707
				c-0.1,2.7-1.4,5.3,0.2,8.2c1.3,2.4-1.1,5.6,0.4,8.4c0.2,0.4,0.1,1.1-0.6,1.3c-0.7,0.2-1.3-0.2-1.6-0.8c-0.5-1.2-1.2-2.2-2.5-2.7
				c-0.1-1.1-0.9-1.6-1.6-2.2c-0.2-0.2-0.4-0.8-0.8-0.2c0,0,0.3,0.3,0.5,0.5c0.4,1,0.7,2,2,1.9c0.3,1.6,0.6,3.1,2.3,4
				c1.6,0.9,2.5,2.6,2.6,4.6c0,2.2,0,4.3,0.3,6.5c0.4,2.4-0.3,4.2-2.2,5.6c-1.5,0.2-2.6,2.1-4.4,1.2c0.4-1.5-0.8-2.1-1.6-2.9
				c-1.3-1.4-1.4-3-1.6-4.8c-0.2-1.8,0.8-3.4,0.2-5.3c-1.2-3.5-0.4-8.5,1-12.5c0.1-0.2,0-0.5-0.1-0.6c-2.7-2.5-1.7-5.8-1.9-8.8
				c1.7-0.7,3.5-1.2,5.3-0.3c0.8,0.4,1.2,0.2,1.4-0.6C884.4,1705.7,885.6,1706.9,886.5,1707z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1023.8,1849.7
				c5.6-5.1,12-9.3,17.9-14.1c0.9-0.7,2-0.8,3.1-0.7c-1.4,5.4,0.2,10.7,0.2,16.1c0,2.5-0.7,3-2.7,3.3c-2.2,0.3-4.5-0.7-6.7,0.3
				c-1-0.2-2.1-0.5-3,0.3c-0.2-0.3-0.4-0.3-0.6,0c-0.6-0.8-1.4-0.9-2.3-0.8c-7.4,1-14.7,0.3-22.1,0.3c4.3-2.4,9.1-1.9,13.7-2.1
				C1023.4,1852.3,1024.1,1851.6,1023.8,1849.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271.6,1927.3
				c-0.6-4.2-1.2-7.8-1.7-11.4c-0.2-1.5-0.4-2.2-2.4-2c-2.4,0.2-4.9-0.4-7.3-0.7c-4.3-0.5-8.4-1.2-12.8-0.4
				c-6.5,1.2-13.1,1.9-19.6,2.8c-1.8,0.2-2.6-0.5-1.7-2.3c0.3-0.7,1.2-1.2,0.9-2c-0.5-1.5-0.4-2.6,1.6-2.5c1.4,0.1,3.5-0.8,3.1,2.2
				c-0.1,1.1,1.3,1.1,2.3,1c11.9-1.9,23.9-3.4,36-3.4c1.4,0,2.8-0.4,4.2-0.6c1-0.1,2.2-0.3,2.5,0.9c0.3,1.1,0.6,2.4-0.6,3.3
				c-0.5,0.4-1.5,0.6-0.4,1.2c4.3,2.7,0.6,5.5-0.3,7.7C274.7,1922.9,273.7,1925,271.6,1927.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M979.8,1971.8
				c-2.4-0.1-4-1.8-5.8-3c-2-1.4-3.9-3-6.3-3.8c-0.7-2.1-3.5-2.1-4.2-4.1c-1.5-10.9-1.2-21.9-1.7-32.8c-0.1-1.4,0-2.7,2-2.5
				c1.5,0.1,3-0.3,3.1,2.2c0.1,4.1,0.7,8.2,0.9,12.3c0.1,2,0.2,3.9,0.7,5.8c-0.8,0.1-1.5,0.2-2.3,0.1c-1.2-0.1-2.3-0.7-3.2,0.6
				c-0.9,1.3,0.3,2.1,0.7,3.1c-0.1,0.7,0,1.3,0.8,1.6c0.1,1.6,0.8,1.8,2.1,1.1c2.2-1.1,4.3-2.3,6.4-3.6c2.2-1.4,4-3.5,6.9-3.6
				c-0.3,1.1-0.3,2.3-1,3.3c-3,4.2-2.6,9.1-2.4,13.9c-0.6-0.3-1.2-0.5-1.8-0.8c0.1-2.6,0-5.2-0.5-7.7c-0.2-0.8-0.5-1.8-1.3-1.3
				c-1.4,0.9-3.4,0.7-4.2,2.5c-1.6-0.4-2.7,0.5-3.2,1.7c-0.6,1.5,1,2.2,1.9,2.6c3.1,1.4,5.6,3.8,8.6,5.3
				C979,1965.9,980.7,1968.2,979.8,1971.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M390,1714.8
				c0.5-4,3.2-4.2,6.5-3.8c5,0.6,10.1,0.9,15.2,1.2c1.4,0.1,2.6,0.2,3.4,1.4c0.3,1.3-0.1,2.7,0.3,4.1c1.3,4,2.3,8,2.5,12.2
				c-0.3,0.7-0.5,1.5-0.8,2.2c-2.4-1.5-4.7-3-7.1-4.5C403.2,1723.3,396.6,1719,390,1714.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M149.1,1737.8
				c-0.3,0.5-0.6,1.1-0.9,1.6c-2.1,3.5-4.4,6-9.3,5.3c-6.1-0.9-12.4-0.9-18.6-0.9c-5.4,0.1-9.4-2.1-12.7-6c3.1-4.5,7.7-2.6,11.7-2.5
				c8.3,0.3,16.7,1.1,25,1.7C145.9,1737.2,147.5,1737.6,149.1,1737.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M217.4,1746.9
				c1.3-6.7,0.6-13.5,0.6-20.2c3.5,0.7,6.7-1.2,10.1-1.3c0.1,1.1,0.2,2.1,0.3,3.2c-1.3,1.9-2,3.9-2.1,6.2c1.4-1.6,3.2-3,3.4-5.3
				c0.8-1,1.7-2,2.5-3c0.1,4.5,1,9-0.8,13.4c-0.6,1.3-1.1,2.7,0.4,3.8c1.4,1.1,0.7,2.4,0.3,3.6c-0.4,1.3-1.7,1-2.8,1.1
				c-3.6,0.3-7.1-0.5-10.7-0.6C218.3,1747.8,217.8,1747.2,217.4,1746.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M813.3,1897.4
				c0-0.3,0-0.7-0.1-1c0-0.3,0-0.7,0.1-1c-0.5-5.3-0.5-5.3,4.8-5.4c5.9-0.1,11.8-0.5,17.5-2.4c0.6,0.9,1.5,1.1,2.6,1.2
				c3.9,0.3,7.9,6.2,6.9,10.2c-2.1,0.1-4.3,0.1-6.4,0.2c-1.1-1.5-2.6-0.8-4-0.9l0,0c0.1-0.7-0.2-1.2-1-1.2c-0.7,0-1.1,0.4-1.2,1.1
				c-6.3,0.3-12.6,0.2-18.9,0C813.6,1897.7,813.5,1897.5,813.3,1897.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M226.4,1794.7
				c1.8-0.2,3.7,0,5.2-1.3c3.5,1.1,6.9,0.1,10.1-0.8c5.4-1.4,11-0.4,16.2-2.5c1.7-0.1,3.5-0.2,5.2-0.3c-1,2.8-3.7,2-5.6,2.3
				c-10.2,1.3-20.2,3.1-30.4,4.5c-6.2,0.9-12.4,1.6-18.6,2c-4.1,0.3-8.1,1.7-12.3,1.4c-1.1-0.1-2.1-0.3-3.2-0.6
				c3.3-4.6,8.5-6.6,13-9.5c1.6-1,3.4-3.6,5.3-2.3c1.7,1.1,0,3.7,0.4,5.6c0.2,1.3-1.2,3.5,0.5,3.6c1.8,0.2,1-2.2,1.2-3.4
				c0.4-2.2,0.7-4.5,0.7-6.8c0-1.9,1.3-2.7,2.6-3.3c1.5-0.7,2.4-0.3,2.4,1.6c0,3,0.2,6.1,0.3,9.1c0,1.1,0.1,2,1.6,1.5
				C222.9,1795.7,224.7,1795.4,226.4,1794.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M480.8,1692.6
				c1.7-0.4,3.4-0.3,5,0.4c1,0.4,2.1,0.7,2.7-0.7c0.5-1.1,0.3-2.1-1.1-2.4c-0.8-0.2-0.6-1-0.7-1.6c1.8-0.6,3.7-0.1,5.5-0.5
				c5-1.1,10-2,15.2-2.4c2.6-0.2,3.2,1.5,3.7,3.3c0.4,1.5,1.2,3.3-1.3,3.9c-4.4-0.1-8.8,0.2-13,1.8c-0.7,0.2-1.7-0.1-1.8,0.9
				c-0.1,1.1,1.1,1.4,1.8,1.5c2.8,0.6,5.5,1.4,8.3,1.6c6.4,0.5,12.8,1.3,19.3,1.4c4.7,0.1,9.3,0.6,14,0.6c0.9,0,2.3-0.4,2.3,1
				c0,1.4-1.4,1.1-2.3,1.1c-6.6,0-13.1-0.6-19.6-1.5c-6.4-0.9-12.9-0.7-19.2-1.8c-5.7-1-11.3-2.5-16.9-3.7
				C480.5,1695.2,480.8,1693.9,480.8,1692.6L480.8,1692.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M410.5,1820.8
				c-1.5,0.1-1.5-1-1.4-2c0.9-9,1.8-18,2.7-27c0.2-1.8,1.4-1.6,2.3-1c1.3,0.8,2.5,1.7,3.6,2.8c1.2,1.1,2.4,1.3,3.9,0.7
				c1.9,6,0.1,11.8-0.6,17.7c-0.3,2.4-1.1,4.1-3.7,5.1C414.9,1818.1,412.4,1819,410.5,1820.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M160.6,1810.6
				c0.8,2.4,0.5,4.5,0.6,6.6c0,8.2,0,16.3,0.1,24.5c0,2.1-0.6,3-2.8,2.8c-1.2-0.2-2.4,0-3.6,0c-1.5,0-2.2-0.8-2.2-2.2
				c0-8.8,0-17.5,0-26.3c0-1.5,0.4-3,0.7-4.5c0.1-0.6,0.4-1.1,1-1.2c0.7-0.1,0.9,0.5,1.1,0.9
				C157.4,1813.7,158.2,1813.7,160.6,1810.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M257.6,1774.3
				c-0.5-0.4-0.9-0.2-1.2,0.1c-0.6,0.5-1.3,1.1-2,0.4c-0.7-0.7-0.5-1.6,0-2.4c0.7-1.1,2.3-2.1,0.6-3.6c2-0.8,1.5-4.3,4.7-4
				c-0.9-1.2-1.9-1.5-3.1-1.5c-0.9,0-1.8,0.1-2.1-1.1c-0.2-1.1,0.6-1.5,1.4-2c0.1-0.1,0.3-0.1,0.3-0.2c-0.6-1.7,7.5-2,0.8-4.8
				c-0.6-0.3-0.8-1.6-1.3-2.7c2.3,0.3,4.2,0.4,6.3-0.2c3.2-0.9,5.1,1.4,4.2,5.2c-0.9,3.8,0.3,7.4,0.1,11.1
				c-0.3,3.9-0.3,7.8-0.5,11.6c-0.1,1.3-4.2,2.1-5.5,1.2c-1.7-1.1-2.8-2.9-4.9-3.5c-1.2-0.4-0.5-1.6,0.4-2.2
				C256.4,1775.4,257.7,1775.7,257.6,1774.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964,1885.7
				c-3.2-0.3-4.3-2-4.2-5.2c0.1-2.4-2-4.2-1.1-7.5c1.2-4.3,4.3-4.4,7.1-5.6c0.4-0.2,0.9-0.1,1-0.6c1.2,0.2,2.5,0.5,3.7,0.7
				c-0.2,1.7-1.7,1.8-2.9,2.1c-1.8,0.5-3.3,1.3-4.7,3.1c4,0,7.7,0,11.5,0c-1.7,2-4.8,0.4-6.6,2.8c4.3,1.3,7.8-1.3,11.6-1.5
				c0.6,3.9,1,7.7,0.8,11.7c-0.5,0-1,0-1.5,0c-2.1-2.7-4.5-0.5-6.8-0.6c-1.1-1-2.3-1-3.3,0.2C967,1885.4,965.5,1885.5,964,1885.7z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M65.3,1929.4
				c-0.1-0.5-0.1-1.1-0.2-1.6c-0.2-0.9-2.1-0.8-1.2-2.2c0.7-1.1,1.9-1.2,3.2-1.2c22.4,0,44.9,0,67.3,0c0.4,0,0.9,0,1.3,0
				c1.2,0.1,1.9,0.6,1.9,1.8c0,1.5-1.1,1.3-2.1,1.3c-8.1,0-16.2,0.2-24.3,0c-12.5-0.3-24.9,0.6-37.4,0.7c-1.2,0-2.5-0.2-3.2,1.2
				C68.8,1929.4,67,1929.4,65.3,1929.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M606.9,1900
				c-1.6,1.7,0.5,3.5-0.1,5.3c-0.8,0-1.7,0.1-2.5,0.1c-4.1-0.9-8.3,0-12.5-0.3c0-2.4-0.1-4.8,0-7.1c0.1-2.6,1.4-4.3,4.2-4.4
				c5.1-0.3,10-1.6,14.8-3.1c1.3-0.4,2.2-0.4,2.8,0.5c1.3,1.9,2,4,2.6,6.2c0.5,1.9-1.9,1.5-2.1,2.8c-1.5,0-3,0-4.5,0
				C608.7,1898.4,607.8,1898.2,606.9,1900z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M509.6,1692.6
				c2.6-0.5,1.7-2.3,1.3-3.9c-0.5-1.8-1.1-3.5-3.7-3.3c-5.1,0.4-10.2,1.3-15.2,2.4c-1.9,0.4-3.7-0.1-5.5,0.5
				c-0.9-0.1-2.2-0.2-0.6-1.3c3.1-2.1,6.4-4.2,10.3-4.4c1.2-0.1,2.4-0.2,3.4-0.8c5.1-0.5,10.1-1,15.2-1.4c0.7,0.5,1.3,0.5,1.9-0.1
				c3.5-0.1,7-0.3,10.6-0.4c3.2,0,6.4,0.1,9.6,0.1c3.8,0,4,0.4,2.9,4.1c-8.1,0.3-16.1,0.5-24.2,0.8c-2.5,0.1-3.2,1-2.3,3.5
				c0.8,2.7,2.9,2.9,5,3.4C515.6,1694,512.6,1693.7,509.6,1692.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M580.7,1496.3
				c0.2,2,1.6,1.5,2.6,1.1c3.6-1.5,6,2.2,9.3,1.9c0.6,0,0.5,0.8,0.2,1.3c-0.6,0.9-1.4,1.6-2.4,2.1c-3.9,2.1-7.8,4.4-11.9,6.2
				c-2.2,1-3,2.2-2.6,4.5c0.1,0.5,0.9,1.2-0.2,1.6c-0.7,0.3-1.3-0.1-1.7-0.6c-0.5-0.7-1.4-1.4-1.4-2.1c-0.1-3.7-0.4-7.4,0.2-11
				c0.2-1.6,0.4-3.2-0.3-4.3c-0.9-1.6-0.8-2.7,0.2-3.8c1.2-1.3,2.6-2.3,4.5-1.3c0.3,0.1,0.6,0.1,1,0.1
				C577,1495.4,577.4,1496.1,580.7,1496.3L580.7,1496.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M770.1,1970.8
				c-11-1.5-21.9,0.1-32.9,0c-0.6-1.8-0.4-2.6,1.9-2.7c6.2-0.1,12.3,0.5,18.5,1.1c2.5,0.2,4.5,0.1,6.2-2.1c1.4-1.8,3.5-2,5.5-0.5
				c1.1,0.8,2.2,1.5,3.5,0.3c7.8,0.3,15.7,0.8,23.5,0c2.4,0,4.7,0.1,7.1,0.1c7.7-0.1,15.5-0.2,23.2-0.4c1.8,0,3.2,0.1,2.5,2.4
				c-0.1,0.5-0.3,1-1.1,0.9c-4.2-1-8.4-0.2-12.7-0.2c-13.7-0.1-27.3-0.1-41-0.1c-1,0-2-0.1-2.9,0.5
				C771.1,1970.3,770.6,1970.6,770.1,1970.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M805,2027.5
				c0.1-1.5,0.5-2.9-2-3.4c-1.2-0.2-1.5-2.9-3.5-3.7c-2.4-0.9-4.1-1.8-3.7,2.5c-2.2-2-2.3-4.4-3-6.3c-0.5-1.3,1-1.6,1.9-0.7
				c1.1,1.1,2.4,1.4,3.7,1.9c0.8,0.3,1.5-0.1,1.8-0.9c0.3-1-0.3-1.2-1.2-1.4c-2.1-0.5-4.2-1.1-6.3-1.6c-1.3-0.3-2.4-0.9-3.4-1.9
				c-0.5-0.5-0.5-1.2-0.3-1.8c0.4-0.9,1-0.2,1.6-0.1c2.5,0.4,4.9,0.7,7.4,1.1c1.3,0.2,1.9-0.8,2.5-1.7c1.4-1.9,2.5-1.9,3.4,0.4
				c0.8,1.8,2.1,3.4,2.9,5.3c0.9,2.2,0.2,2.6-1.3,2.7c-2.6,0.2-1.8,1.1-0.7,2.3c2.8,3.1,2.8,3.1,5.4-2.6c0,0.8,0,1.2,0,1.6
				c0.1,0.8-0.3,2,0.9,2.1c1.2,0.1,0.9-1.2,1.1-2c0.2-1,0.7-1.9,1-3c1.1,0.7,0.3,1.3,0.3,1.9c0,2.2,1,3.1,3.1,2c1-0.6,2.2-1.1,2.4,0
				c0.5,2.9,3.2,2.3,4.8,3.3c2.2,1.3,1.8-1.2,2.5-2.3c1.8,2,0.6,3.1-1.2,3.9c-1-0.3-2-0.4-3,0c-0.3-0.3-0.6-0.3-0.9,0.1
				c-1.9,0.2-3.8,0.3-5.6,1.2c-0.9,0.5-2.9,1.2-3.5,0.2c-1.5-2.3-2.5-0.9-3.7,0.1c-0.9-0.5-1.7-0.3-2.3,0.5
				C805.7,2027.1,805.3,2027.3,805,2027.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M292.3,1757.6
				c0.4-2.2,1.1-0.9,2-0.3c1,0.7,1.4,3.2,2.9,1.9c1.1-1,0-2.8-1.3-3.8c0,0,0-0.2,0.1-0.6c2.7,0,5.4,0,8.2,0c0.5,1.9-2.1,6.8,3.6,3.7
				c0.3-0.2,0.9,0,0.9,0.6c0.1,0.5-0.3,0.7-0.7,0.9c-2.5,1.5-1.5,3.2-0.7,5.4c0.6,1.7-3.5,3.5-0.7,6c-0.5,1.9,1,3.2,1.3,4.8
				c-1.2-0.2-1.7,0.1-1.5,1.5c0.3,1.7,0.3,3.5,0.5,5.3c-2.3-0.9-2.1-3-2.2-4.8c-0.1-1.5-0.3-2.8-2-2.7c-2,0.1-0.8,1.8-1.1,2.7
				c-0.6,2.1,1.8,4.8-1.2,6.5c-0.2-2.4-0.3-4.7-0.6-7.1c-0.1-1-0.1-2.4-1.5-2.5c-1.2-0.1-2.6-0.3-3.6,1c-0.4,0.6-0.9,1.2-1.3,1.8
				c-1.1-2,0.9-2.9,1.5-4.3C301.4,1767.2,298.8,1763.4,292.3,1757.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M442.6,1909.3
				c-0.4,0.8-0.8,1.5-1.9,1.3c-8.9-1.8-17.6,0.7-26.5,1.2c-9.9,0.8-19.8,0.9-29.6,2.3c-4.6,0.7-9.4,0.2-14,1.2
				c-0.9,0.2-1.8,0.2-2.2-0.8c-0.2-0.5-0.2-1.1,0.3-1.5c0.7-0.6,1.2-0.3,2,0c1.4,0.7,1.4,0.4,1.7-1.2c0.2-0.9,1.3-1,2-1
				c4.7,0,9.2-1.4,14-1.5c14.9-0.1,29.7-0.6,44.6-0.4C436.1,1908.9,439.3,1910,442.6,1909.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M676.2,1900
				c5.4-0.2,10.9-0.3,16.3-0.5c1.7-0.1,2.7,0.2,2.7,2.5c0.1,7.9,0.3,7.8-7.7,8c-4.9,0.1-9.7,0.6-14.6,1c-0.5-3.6,2.3-6.3,2.9-9.7
				C675.9,1900.8,676.1,1900.4,676.2,1900z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1007.6,1854.5
				c7.4,0,14.8,0.6,22.1-0.3c0.9-0.1,1.7-0.1,2.3,0.8c-1.2,1.7-2.6,2.1-4.8,1.7c-2.4-0.5-4.8-0.7-7.4,1c-3.7,2.5-8,4.4-12.8,2.7
				c-0.9-0.3-1.9-0.4-1.9-1.4c-0.1-4.8-3.1-1.9-4.4-1.3c-2.2,1-4,2.6-6-0.2c-0.3-0.5-1.7-0.5-2.5-0.3c-3.5,0.6-7,0-10.6,0.2
				c-1.3,0.1-1.1,0.6-1.2,1.3c-1,4.3-4.6,5.4-8.3,2.6c1.3-1.1,3.1-0.8,4.5-1.5c0.9-0.5,1.8-1.2,1.5-2.2c-0.2-0.9-1.3-0.6-2-0.5
				c-2,0.4-3.8-0.9-5.7-0.4c-0.4-0.1-0.9-0.2-1.3-0.2c2.4-2.3,6.1-2.6,8.6-2.5c5.7,0.1,11.6-1.3,17.4,1.6
				C998.8,1857,1003.5,1854.9,1007.6,1854.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M811.2,1861.2
				c-0.1,0.6,0.1,1.2,0.5,1.6c-1.6,0.6-3.2,0.1-4.8,0c-11.7-1-23.3,1-35,1.3c-0.7,0-1.6-0.1-1.9,0.8c-0.4,0-0.8,0.1-1.2,0.1
				c-5.2-1.2-10.5-0.2-15.7-0.1c-0.8,0-1.5,0.3-2.2-0.2c0.4-0.6,1-1.1,0.6-2c2.2-0.9,4.4-1.7,6.8-1.8c11.3-0.6,22.7-1.2,34-1.6
				c5.7-0.2,11.4,0.2,17,0.2C810.7,1859.6,811.3,1859.9,811.2,1861.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M681.9,1971.5
				c4.3-0.2,8.7-0.3,13-0.5c1.4-0.1,2.2,0,2.2,1.8c0.4,13.8,0.2,27.6,1.2,41.4c0.2,2.3-0.1,4.6-0.1,6.9c-2.7-4.7-0.7-9.8-1.3-14.7
				c-0.7-5.7,0-11.6-2.1-17.1c-0.3-0.7-0.6-1.4-0.1-2.2c1.8-2.5-2.1-3.4-1.6-5.5c-0.2,0-0.5-0.2-0.6-0.1c-4.8,4.4-2.4-3.1-4.9-2.4
				c-0.1,0.6-0.1,1.8-0.7,2.5c-1.3,1.5-1.5-0.8-2.7-0.9c-0.1,1.9-0.2,3.9-0.3,5.8c-0.4,0-0.8,0-1.1,0
				C681.2,1981.6,681.7,1976.5,681.9,1971.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1025,1791.3
				c5-3.2,9.3-7.6,15-9.9c0.4-0.2,0.6-0.8,0.9-1.3c1.3,0.2,2.1,1.1,1.8,2.2c-1.2,5.7,0.5,11.4,0.3,17.1c0,1,0.6,2.3-0.6,3
				c-1.3,0.7-1.8-0.7-2.7-1.1c-4.4-1.9-8.3-4.6-12.4-7.1C1026.1,1793.5,1024.9,1792.9,1025,1791.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M750.6,1821.6
				c0.1-0.4,0.2-0.9,0.4-1.3c3.4-1.3,3.4-1.3,1.5-3c4.2-0.3,8.4-0.5,12.6-1c4.4-0.5,8.7-0.1,13.1-0.4c4.2-0.3,8,1.7,12.4,1.6
				c3.7-0.1,7-2.1,10.7-1.5c-0.4,0.4-1.5,0.4-1.2,0.7c1.2,1.3,2.7,0.6,4.1,0.4c0.1,0.5,0.3,0.7,0.8,0.6c0.1,0,0.3-0.3,0.3-0.4
				c-0.2-0.4-0.6-0.4-1-0.3c1.9-2.1,3.8-0.7,5.8-0.2c-1.4,0.8-3.1,1.6-1,3.5c-7.7-1.5-15.4-0.8-23.2-0.6c-7.8,0.1-15.7,0.7-23.5,1.1
				C758.4,1820.9,754.5,1821.3,750.6,1821.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M483.2,1377.3
				c4.1,2.3,8.4,4.4,12,7.7c-1.6,1.2-3.3,0.7-5,0.4c-5-1-9.9-2.2-15.1-0.6c-2.2,0.7-4.5,0-6.8-0.5c-3.5-0.7-4.4-2.6-2.5-5.6
				c0.2-0.3,0.4-0.5,0.6-0.7c5.1-5.6,8.7-5.9,15.2-1.3C482.2,1377,482.7,1377.1,483.2,1377.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M579.4,1656
				c-0.6-1.9-0.4-4.4-3.3-4.8c-0.1,0-0.2-0.1-0.3-0.2c-2.8-6.4-8.5-4-13.1-4.1c-4.4-0.1-8.8,0.9-13,1.4c-0.8,1.2,1.8,2,0,3.2
				c-1.4,0.9-2.1-0.3-3.1-0.6c-3.1-0.6-4.7-3.7-8.4-3.7c1.5-1.6,2.7-2.2,3.9-2.4c5.3-0.6,10.5-1,15.8-1.4c0.7-0.1,1.3,0,1.8,0.6
				c0.7,1,1.4,2,2.2,0c0.1-0.1,0.5-0.3,0.5-0.2c3.1,3.1,6.5-0.2,9.8,0.6c3.4,0.8,6.9,0.8,10.4,1.1c1.6,0.1,2.8,0.5,3.5,2.3
				c0.8,1.9-0.1,2.6-1.4,3.4c-0.4,0.2-0.5,0.9-0.7,1.4C583.2,1654.8,581.8,1656,579.4,1656L579.4,1656z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M977.4,1714.1
				c0.4-1.1,1.4-0.9,2.2-0.9c10.1-0.4,20.3-0.9,30.4-0.6c1.3,0,3-0.4,3.4,1.6c-1.2,0.1-1.2,1.2-1.6,1.9c-1,1.8-2.5,2.7-4.6,2.6
				c-2.1-0.1-4.2,0.1-6.2-0.1c-4.4-0.4-8.6,0.4-12.8,1.7c-2.9,0.9-5.7-0.1-8.3-0.7C977.6,1719,977.9,1716.2,977.4,1714.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1055.3,1813.7
				c-2.6,4.8-7.1,7.3-11.4,10.2c-0.2,0.2-0.7,0.1-0.9,0.1c-1.6-0.4-3.2-5.6-2.1-7c3.9-4.9,3.9-5-1.6-8.4c-6.5-3.9-13-7.9-19.5-11.9
				c1.2-3.8,3.3-2,5.2-0.8c9.5,5.7,19,11.4,28.5,17.1C1054.1,1813.3,1054.7,1813.5,1055.3,1813.7z M1041.9,1818.1
				c2.9,0.2,4.8-1.4,7.1-1.1c0.7,0.1,0.8-1.1,0.3-1.5c-1-0.8-2.1-1.5-3.5-0.5c-0.9,0.7-2,1.6-3.3,1c-1-0.4-2.1-0.8-2.2,0.6
				C1040.1,1818.1,1041.6,1818,1041.9,1818.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M573.7,1252.4
				c0.3,0.9,0.6,1.8,0.8,2.7c-3.2-1.6-6.2-3.8-9.9-4.3c-3.4-0.4-6.8-0.7-10.3-0.9c-4-0.2-7.9-0.8-11.9-0.1c-0.6-0.1-1.2-0.2-1.7-0.3
				c-0.7,0-1.4,0-2.1,0c-3.9,0.1-7.8,0.1-11.7,0.2c-0.9-0.2-2.2,0.1-2.2-1.4c0-1.2,1.2-1.1,2-1.3c11.2-2.8,22.4-4.1,33.8-1.7
				c4.8,1,9,3.2,12.8,6.3l0,0C573.2,1252.1,573.3,1252.3,573.7,1252.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M219.7,1799.9
				c0,4.8,0.6,9.6-0.3,14.3c-0.6,3.3-1,3.7-3.9,1.6c-5.9-4.1-11.6-8.5-17.5-12.7c3.9-2.6,8.4-2.7,12.9-2.5c-0.2,2.1-0.4,4.1-0.5,6.2
				c-0.1,0.7-0.1,1.4,0.8,1.6c1,0.2,0.9-0.7,1-1.3c0.2-2.2,0.3-4.3,0.5-6.5C214.8,1799,217.2,1798.8,219.7,1799.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964.7,1793.2
				c-0.7-2.6,1.1-3.1,3-3.3c7.2-0.5,14.4-1.2,21.5-1.4c6.5-0.2,13.1,0.1,19.6-0.1c2.3,0,4.6,0.3,6.8,0.6c0.7,0.1,2.2-0.7,2.1,1.1
				c-0.1,1.4-0.8,2-2.4,1.9c-8.4-0.6-16.8-0.3-25.2-0.1c-7.8,0.1-15.7,1.4-23.5,0.7c-0.2,0-0.5,0.4-0.7,0.5
				C965.5,1793.2,965.1,1793.2,964.7,1793.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M435.2,1924.3
				c-1.9,3.3-2.9,7-4.6,10.5c-2.5,5.1-4.3,10.4-6.5,15.7c-1.3,0-2.4-0.1-2.2-1.9c0.6-5.1,0.4-5.3-4.6-4c-0.8-1.1-2-0.2-3-0.7
				c0-0.4-0.2-0.6-0.6-0.6c-0.1-2.8,1.6-3.3,4-3.2c2.6,0.1,5.4,0.5,5.9-3.3c0.1-0.9,0.6-1.6-0.2-2.3c-0.3-0.3-0.7-0.7-0.7-1
				c-0.2-3.4-0.8-6.4-5.1-6.7c0,0-0.1-0.4-0.1-0.6c3,0.8,6.1,0.6,9.2,1.3C429.7,1928.1,433,1927,435.2,1924.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.7,1736.1
				c0.5,1.9-0.8,4.4,2.2,5.6c-2.7,1.6-5.1,2.8-8.1,3.7c1.4,1.4,2.9,0.6,4.2,0.8c1.3,0.2,3.1-0.2,2.7,2.1c-0.2,1.6-0.4,2.9-2.8,2.1
				c-1.2-0.4-2.7,0.4-4.5,0.7c2,1.6,4.2,0.6,6,1.2c1.4,0.5,2.8,0.7,1.3,2.7c-1.9,2.6-0.7,3.8,2.9,3.6c-2.6,2.2-5,3.6-7.9,4.1
				c-1.1,0.2-2.2,0.9-2.9-0.5c-0.5-1.1-0.6-2.4,0.5-3.3c1.3-0.9,1.1-1.2-0.3-1.8c-1.3-0.6-1.3-2-1.6-3.4c-1.1-5,0.7-9.8,0.3-14.7
				c-0.1-1.7,0.7-2.4,2.1-2.8C956.8,1736.3,958.7,1736.2,960.7,1736.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M413.6,1786.1
				c-1.2-1.7-0.9-3.5-0.6-5.3c0.2-1.2,0.1-2.4,0.3-3.6c0.9-7.1,0.9-7.1,8.2-7.5c2,4.1,1.6,8.6,1.5,13c0,1.5,0,3,0.3,4.4
				c0.4,2.5,0.4,4.9-1.7,6.8c-0.9-0.2-2-0.3-2.4-1.4C418.5,1789.2,416.2,1787.6,413.6,1786.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M902.1,1705.8
				c0.6-2.9,3-1.9,4.8-2.1c2-0.2,1.7,1.5,2.1,2.7c2.1,5.7,1.5,11.7,2.3,17.6c0.4,2.7-2.5,2.3-3.1,4l-0.1,0.1
				c-0.5-0.1-0.9-0.1-0.6,0.6l-0.1,0.1c-0.6-0.2-0.9-0.1-0.6,0.6l-0.1,0c-0.8-0.5-1.5-0.1-2.2,0.4c-2.2,0.2-1.5-1.4-1.5-2.4
				C902.7,1720.1,902.4,1713,902.1,1705.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M518.4,1691.9
				c-2.1-0.5-4.1-0.8-5-3.4c-0.8-2.5-0.2-3.5,2.3-3.5c8.1-0.2,16.1-0.5,24.2-0.8c0.2,1,0.4,1.9,0.6,2.9c0.5,2.8-0.6,3.7-3.5,3.7
				C530.8,1690.9,524.6,1691.5,518.4,1691.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M536.5,1364.9
				c-1.7,0.3-3.4,0.7-5.1,1c-0.2-0.7,0.2-1.6-1-2.2c-0.2,1-0.4,1.8-0.5,2.7c-0.4,0.1-0.8,0.1-1.2,0.2c-0.8-0.8-1.2-0.3-1.5,0.5
				c-3.2,0.7-6.5,1.5-9.7,2.2c-1.3-0.9-1.7-3.2-4.1-2.6c2.3-1.7,5-2.2,7.5-2.9c8.6-2.3,17.3-4.5,26-6.5c4.2-1,4.3-0.8,5.4,3
				c0.5,2.2,1,4.4,1.5,6.7c0.4,1.8-0.3,2.2-1.9,1.7c-0.4-2.8-1.7-5.4-1.5-8.3c0-0.8-0.4-1.8-1.4-1.9c-0.8-0.1-1.2,0.6-1.4,1.2
				c-0.5,1.6-2,1.8-3.2,2.4C541.7,1363.1,538.8,1363.2,536.5,1364.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M176.7,1909
				c-14.6,1.6-29.3,3.1-43.9,4.8c-3.9,0.4-7.8,1.1-11.7,1.5c-0.9,0.1-2.5,0.4-2.5-1.2c0-1.4,1.4-1,2.3-1c1-0.1,2.4-0.3,2.5-1.1
				c0.5-4.9,4.5-3.2,6.8-3.2c15.5-0.3,31-0.2,46.5-0.2C176.7,1908.6,176.7,1908.8,176.7,1909z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M652,1978.5
				c-1.4-0.7-1.5-1.9-1.5-3.3c0-2.3-1.2-3.1-3.4-2.6c0-1.5,0.1-3.2,1.7-3.5c1.7-0.3,1.6,1.7,2.2,2.7c4.2,6.9,7.1,14.5,10.4,21.8
				c2.6,5.9,4.9,12,7.5,17.9c1.9,4.5,4.3,8.8,6.3,13.3c1.1,2.4,2.1,4.8,2.8,7.3c0.8,2.8-1.4,1.1-2.3,1.4c-2-6.7-5.1-12.9-7.8-19.3
				c-4.2-9.7-8.6-19.4-12.9-29.1C654.3,1982.8,653.2,1980.6,652,1978.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M475.7,1688.7
				c-1.9-1.4-3.6-1.6-5.1,0.6c-0.5,0.7-1.3,0.6-2.1,0.8c-2.7,0.7-5.7,0.5-7.9,2.6c-0.1,0.1-0.2,0.2-0.3,0.2
				c-3.1-1.5-6.4,0.1-9.5-0.4c-1.5-0.3-0.8-2-0.9-3.1c-0.1-1.3,0.2-2.3,1.8-2.6c10.1-2.1,20.3-3.5,30.6-4.5c1-0.1,2-0.4,2.3,1
				c-2.2-0.1-4.2,0.9-6.4,1.1C475.4,1684.6,474.5,1686,475.7,1688.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M345.4,1800.4
				c0,10.2-0.3,20.4,0.1,30.6c0.2,4.8-0.5,9.7,0.4,14.5c0.2,1.1,0,2.5-1.3,2.6c-1.8,0.2-1.3-1.4-1.3-2.4c-0.2-20.3-2.2-40.6-1.3-61
				c0-1.2-1.9-3.5,0.9-3.5c1.8,0,1.1,2.3,1.1,3.6c0.1,5.2,0,10.4,0,15.6C344.5,1800.4,345,1800.4,345.4,1800.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M980.3,1890.3
				c0,2.8-0.1,5.6-0.1,8.5c-0.6,0-1.1,0-1.7,0c-5.9-0.1-11.8-0.1-17.8-0.2c-2.1-1.2-0.4-2.8-0.5-4.2c0-1.3,0.4-2.5,0.6-3.8
				c5.9-0.1,11.7-0.2,17.6-0.3C979,1890.3,979.6,1890.3,980.3,1890.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M967.8,2023.7
				c-0.9,0-1.8,0.1-1.8-1.2c0-3,0-6,0-8.9c0.6-0.3,1.2-0.5,1.7-0.8c4-2.4,7.6-5.5,12.1-7.2c-0.7,2.5,0.6,5.2-0.8,7.6
				c-0.2,0-0.4,0.1-0.6,0.2c-1.2-0.3-1.9,0.4-2.2,1.5c-1.2,0.8-2.5,1.6-3.7,2.5c-0.9-0.2-1.5,0.1-1.4,1c0.1,0.7,0.8,0.7,1.5,0.5
				c2.3,0,4.7,0,7,0c0.2,6.1,0.2,6.1-4.5,5.7C972.9,2022.5,970.4,2022.9,967.8,2023.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M305.8,1670.3
				c1,0.7,0.6,1.7,0.7,2.7c-3.9,2.7-6.4,6.8-9.5,10.2c-1.6,1.7-0.3,4,2.1,4.6c-1.7,0-2.2,1.1-2.1,2.5c0.1,1.5,0.3,3,0.4,4.5
				c-6.4,0.6-6.5,0.6-6.3-6c0.3-6.3,1-12.5,0.4-18.8c-0.2-2.3,2.1-0.8,3.1-1.5c0.4,3,0.7,6,1.1,9c0.1,0.8,0.1,1.8,1.1,2.1
				c1,0.3,1.6-0.5,2.2-1.2C301.3,1675.7,303.6,1673,305.8,1670.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M894.3,1728.6
				c-1.8,0.1-2.9-0.5-2.6-2.5c0.3-1.9-0.9-3.9,0.6-5.6c0.4-0.5,0.1-1-0.2-1.5c-1.9-3.2-1.4-6.3,0.4-9.4c0.5,0,0.8-0.3,0.8-0.8
				c0-0.1-0.4-0.3-0.4-0.3c-0.2,0.2-0.4,0.4-0.6,0.6c-1.6,0.1-1.4-1.1-1.5-2.1c0.7-1.5,1.8-2.5,2.7-0.4c0.7,1.5,1.8,0.7,2.6,1.1
				c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0.7,0.6,1.1,1.3,1.4c0.2,0.2,0.4,0.6,0.7,0.1c0-0.1-0.2-0.3-0.3-0.5c-0.2-0.7-0.6-1.1-1.2-1.4
				c-0.2-0.2-0.5-0.4-0.7-0.6c0.2-0.8-1.5-1.8,0.1-2.3c1.5-0.4,3.3-1.5,4.4,0.7c0,2-1.4,4.9-0.9,5.7c2.6,3.9,0.9,6.9-1.2,9.9l0-0.1
				C896.6,1723.3,894.8,1725.6,894.3,1728.6L894.3,1728.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M308.8,1734.4
				c-0.2-2-1.5-2.4-3.2-2.4c-1.3,0-3.2,0.5-3.5-0.9c-0.5-2.2-2.3-4.4-1.2-6.8c0.4-0.9,0.8-1.4,0.2-2.2c-2-2.4-1-4.2,1-5.8
				c1.9-1.1,3.1-3,4.7-4.4c1-0.8,2.2-1.4,2.4,0.7c0.9,7,0.4,14,0.4,21.1C309.7,1733.8,309.1,1734.1,308.8,1734.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M227.3,1709
				c0.7-7.3,0-14.5,0.1-21.8c0-1.7-0.1-3.5-0.2-5.2c-0.2-2.2,1-2.3,2.3-1.5c2.2,1.4,4,3,3.9,6.3c-0.2,6.9,0.1,13.9,0.2,20.9
				C232,1710.5,229.7,1709.7,227.3,1709z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.7,1898.6
				c5.9,0.1,11.8,0.1,17.8,0.2c-0.1,2.9-0.2,5.8-0.3,8.7c-3.6,0.1-7.2,0.9-10.8,0.3c-1.5-0.2-2.9-0.9-4,0.7
				c-0.8-0.1-1.6-0.2-2.5-0.3C960.8,1905,960.7,1901.8,960.7,1898.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M802.9,1801
				c-5.5,0-10.6,0-15.6,0c-9.5,0-19,0.4-28.5,1c-1.1,0.1-2.2,0.1-3.3-0.1c-0.8-0.1-2.3,0.3-2.1-0.7c0.2-0.8-0.3-2.3,2-2.6
				c7.6-0.9,15.3-1.8,22.7-0.8c7.3,0.9,14.5,0.5,21.7,1.2C800.9,1799.1,802.1,1799.2,802.9,1801z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M845.6,1695.5
				c-2.5-0.8-4.6,1-6.9,1.4c-5.1,0.8-10.2,0.8-15.2,2.3c-1.7,0.5-2.9,0-3-1.9c-0.2-2.4-2.1-4.7-0.2-7.1c6.6,0.7,13.2,1.2,19.8,2
				c3.4,0.4,6.9,0.7,10.3,2.3C848.6,1695.3,846.8,1693.8,845.6,1695.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M272.2,1846.4
				c5.1-5.9,10.1-11.7,15.2-17.6c0.4-0.5,0.9-0.8,1.4-1.3c0.1,0.2,0.1,0.4,0.2,0.6c-0.6,2.8-0.3,5.7-0.1,8.5
				c0.2,2.9-0.9,4.6-3.2,6.2c-1.5,1.1-3.6,1.8-3.5,4.3c-0.5,0.2-1.2,0-1.3,0.8c-0.9-0.1-1.8-0.1-2.7-0.2c0.1-0.9-0.4-1.2-1.1-1.1
				c-0.6,0-0.9,0.5-0.8,1.1c-1.3,0-2.7,0-4,0C272.3,1847.3,272.2,1846.8,272.2,1846.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M373.1,1819.6
				c3.8,0,7.7-0.3,11.5,0.2c1,0.1,2.5-0.3,2.3,1.5c-0.2,1.7-1.4-0.3-1.9,0.3c-0.9,1.2-3.1,0.1-3.5,1.9c-0.4,1.9,1.9,2,2.6,3
				c-0.4,1.8-2.5-0.7-2.7,1.4c-0.2,1.7,1.6,1.4,2.5,2.5c-1.6,0.3-2.7,0.8-2.3,2.6c0.3,1.3-0.6,1.7-1.7,1.7c-1.4,0-2.8,0.1-4.3,0.2
				c-2.1,0.1-2.8-0.8-2.7-2.9C373.1,1827.9,373.1,1823.8,373.1,1819.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M913.3,1645.2
				c-3.4,1.2-6.7,2.4-10.3,2.9c-2.4,0.3-3.9-0.1-3.4-3c0.1-0.5,0-1.1,0-1.6c0-6.5,0-6.5,6.3-5.2c1.3,0.3,2.6,0.5,3.8,0.9
				c2.9,1.1,5.2,0.5,7.3-1.8c1.2-1.4,2.9-2.3,4.9-2.2c-0.8,4.4-4.3,6.6-7.6,9C913.9,1644.5,913.3,1644.6,913.3,1645.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M222.4,1929.1
				c-1.5,0-3,0-4.5,0c-5.3-1-10.6-0.6-16-0.9c-1.4-0.1-2.8,0-4.1-0.1c6.8-1.1,13.7-1.3,20.5-1.9c8.2-0.8,16.3-2.5,24.6-2
				c3.3,0.2,6.5,0,9.8,0c0.7,0,1.8-0.1,1.7,1c-0.1,0.9-0.9,1.1-1.8,1.1c-6.5-0.1-12.8,1.1-19.2,1.8
				C229.7,1928.5,226,1928.4,222.4,1929.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.7,1736.1
				c-1.9,0.1-3.9,0.2-5.8,0.3c-0.1-0.2-0.2-0.4-0.3-0.6c1.4-1,3-1.4,4.8-1.4c4.4-0.9,8.9-1,13.2-2c6.2-1.5,12.5-0.2,18.7-0.8
				c6.4-0.6,12.9-0.4,19.3,0.3c2.1,0.2,4.1,0.4,6,1.4c-0.1,0.1-0.1,0.3-0.1,0.5c-0.7,0.6-1.4,0.9-2.4,0.8
				c-16.1-1.8-32.1-0.5-48.1,1.1C964.2,1735.8,962.4,1735.9,960.7,1736.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M294.4,1896.8
				c1.8,0.4,3.4-0.9,5.1-0.8c1.4,0.1,1.3-0.9,1.3-1.8c0-1.1-0.7-1.7-1.7-1.5c-1.5,0.4-2.8,0.5-4.4,0.1c-2.1-0.5-0.2,1.7-1.3,2.3
				c-1-0.7-0.1-3.4-2.9-2.6c-2.1,0.6-2.8,1.4-2.4,3.3c0.3,1.6-0.3,2.2-2,1.7c-0.8-0.2-1.7-0.1-2.6-0.1c-1.8-0.2-4.6,1.1-4-2.7
				c0.1-0.4-0.1-2.1-1.3-2.4c-1.4-0.3-1.2,0.8-1.4,1.6c-0.4,1.3-1.3,0.5-1.7,0.1c-2.8-2.6-6.2-0.9-9.4-1.3c-1-0.1-1.5,0.8-1.5,1.8
				c0,1.2,0.7,1.8,1.9,1.8c2.6-0.1,5.3,0,7.9-0.2c0.7,0,1.8-0.6,1.9,0.4c0.2,1.4-1.2,0.9-1.9,0.8c-3-0.2-6,0.7-9.1,0.2
				c-2-0.4-2.5-1.1-2.1-2.7c0.4-1.6-0.2-3.8,2.9-3.6c6.4,0.3,12.8,0.4,19.2,0.5c4.5,0,9-0.2,13.6-0.3c1.8,0,3.7,0.1,4.6,1.8
				c1.3,2.4-0.1,4.4-2.9,4.3C298.2,1897.4,296.2,1897.9,294.4,1896.8z M282.6,1896.8c1-0.1,1.9-0.3,2.9-0.4c0.7-0.1,1.7-0.2,1.6-1.2
				c-0.1-0.7-0.7-1.3-1.1-1.9c-0.6-0.7-1.3-0.5-1.9,0.2c-0.3,0.4-0.6,1.5-1.2,0.2c-0.3-0.7-0.8-1.1-1.5-0.6
				c-0.8,0.7-0.7,1.8-0.5,2.7C281.1,1896.8,281.8,1896.9,282.6,1896.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M379,1675.9
				c-8.3,1.8-16.6,2.8-24.5,5.3c-2.2,0.7-4.5-0.6-6.5,0.8c-0.5,0.4-1.2-0.4-1.2-1.2c0-0.7-0.3-1.7,0.9-1.6c0.7,0,1.3,0.4,1.7-0.5
				c2.4-6,7.7-5.1,12.5-4.9c5.2,0.2,10.4,0.8,15.6,1.2C377.9,1675.1,378.5,1675.1,379,1675.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M952.1,1715.5
				c7.5-0.6,15-2,22.6-1.2c-0.4,4.7-0.4,4.6-5.5,5.1c-0.8,0.1-1.8,0.3-2.5,0.7c-4,2.2-8.3,2.1-12.6,2c-1.3,0-1.8-0.4-1.8-1.7
				C952.2,1718.7,952.1,1717.1,952.1,1715.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M322.2,1755.9
				c0.4,0,1,0.1,1.4,0c2.7-0.8,3,0.2,3.3,2.8c0.7,6.2,0.6,12.3,0.6,18.5c0,1.2,0.5,2.6-1.1,3.1c-1.8,0.5-3.7,0.5-5.5,0
				c-0.6-0.2-0.3-0.8-0.2-1.4C322.5,1771.4,322.3,1763.8,322.2,1755.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M205.9,1925.2
				c-10.6,1.1-21.2,2.1-31.8,3.5c-4.2,0.6-8.2,0.4-12.3,0c-1-0.1-2.1,0.1-2.4-1.2c-0.3-1.2,0.8-1.5,1.5-1.8c0.9-0.4,1.7-1.3,2.8,0
				c1.9,2.4,4.1,2.4,6.1,0c0.8-1,1.7-0.8,2.6-0.9C183.6,1923.7,194.7,1924.9,205.9,1925.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M150,1768.8
				c-2.4-1.8-4.8-3.9-7.4-5.4c-2.8-1.7-2.2-3.3-0.6-5.3c2.4-3.1,4.9-6.1,7.3-9.2c1.8-1.1,2.6,0.1,2.5,1.5
				c-0.5,5.3-0.1,10.6-0.3,15.9C151.4,1767.5,151.3,1768.5,150,1768.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M808.7,1824.2
				c-0.2,0.7,0,1.4,0.5,2c-3.6-0.1-7.2-0.1-10.8-0.2c-15.9-0.4-31.7,0.9-47.5,1.6c0.1-0.4,0.3-0.9,0.4-1.3c2.6-0.9,5.5-0.8,7.7-2.9
				c0.8-0.7,2.1-0.5,3.2-0.3c-0.1,1-1.5,0.2-1.6,1.3C776.7,1823.9,792.7,1823.9,808.7,1824.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.5,1845.8
				c0.3-3.6,0.7-7.1,0.8-10.7c0.1-1.9,1.3-2.1,2.3-1.6c3.4,1.6,7,2.8,9.1,6.3c-0.9,2,0.8,4.9-1,6.1c-1.9,1.3-4.6,0.3-7,0.4
				C211.3,1846.3,209.8,1846.3,208.5,1845.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M751.5,1813.7
				c3.4-0.9,6.9-2.6,10.2-2.4c15,0.9,30.1-1,45.1,0.8c0.5,0.1,1,0.4,1,1.8c-18.8-1.2-37.5,0.6-56.2,0.7
				C751.6,1814.2,751.5,1814,751.5,1813.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M113,1845
				c0.2-0.9,0.1-1.8-0.5-2.4c-5.5-5.2-10.1-11.3-16.1-16c1.4-1.6-0.7-3.9,1.2-5.3c7.2,7.5,14.5,15,21.7,22.5c-0.4,1.8-1.8,1-2.8,1.1
				C115.3,1845,114.2,1845,113,1845z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M309.9,1847.1
				c-0.2-13.2-0.5-26.3-0.6-39.5c0-6.1-1.2-12.1-2.1-18.1c-0.1-0.6,0-1.3,0-1.9c2.3-1.5,3.2-0.8,3.2,1.8c0,7.3,0.4,14.5,0.7,21.8
				c0.3,7-0.3,14.1,0.1,21.1c0.2,4.1,0.4,8.2,0.5,12.4c0,1.4,0.7,1.9,1.9,2.3C312.4,1846.9,311.2,1847,309.9,1847.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M753.7,1807.7
				c1.1-1.8,2.9-0.9,4.1-1.7c-0.1-1.4-1.8-0.3-2.2-1.2c3.3-0.9,6.6-1.1,10-0.4c-1.9,0.1-3.7,0.2-6.5,0.4c2.3,1.3,3.9,0.9,5.3,0.8
				c11.8-1,23.6-0.4,35.3-0.4c1.7,0,5,0,4.8,1.3c-0.2,2.2-3.1,1.1-4.7,1c-14-1.2-28,0.2-42,0.8
				C756.6,1808.4,755.2,1808,753.7,1807.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M310.3,1751.2
				c-1.5,0.4-2.8,0.7-4.4,0.6c-2.2-0.2-3.2-1.4-3.2-3.3c0.2-3.9-0.5-7.8-0.7-11.7c-0.1-0.9-0.1-1.8,0.9-2.2c0.9-0.3,2-0.3,2.4,0.5
				c1,2,2,0.4,3.1,0.3c1,0.3,1.5,1,1.6,2.2C310,1742.2,310.2,1746.7,310.3,1751.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M406.8,1760.7
				c6.9,0.3,13.9,0.6,20.8,0.9c-4,3-8.7,2.1-13,3.3c-3.6,1-7.4,1.6-11.1,2.1c-2.4,0.4-4.9-0.1-7.3-0.2c-3.5-0.1-7.3-1.9-10.9,0.2
				c-0.8,0.5-2.4,0.8-2.8-0.7c-0.4-1.6,1-1.6,2-1.7c1.2-0.1,2.4-0.1,3.6-0.2c4.7,0.3,9.5,0.6,14.2,1
				C407.2,1765.8,407.4,1765.7,406.8,1760.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M364.4,1366.3
				c-0.5-1.5-0.9-2.7-1.5-3.8c-0.9-2-0.7-3.7,1.1-4.9c1.6-1,3-1.4,5.3-0.3c3.6,1.7,3.7,4.8,4.5,7.7c0.6,2.1-1,3.7-2.8,3.4
				c-2.6-0.4-4.9,0.4-7.4,0.4c-1.1,0-1.9,0.1-2.3-1c-0.3-0.7-0.5-1.6,0.2-2.1C362.5,1364.9,363,1366.5,364.4,1366.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M454,1906.5
				c0.3-3.9,0.2-4-3.7-2.9c-0.9,0.3-1.7,0.4-1.6-0.7c0.5-5.2-0.8-10.4,0.1-15.6c0.1-0.7-0.1-2,1.1-2.1c1-0.1,1.5,0.9,2.2,1.3
				c3.9,2,2.6,5.4,2.7,8.5c0.1,2.8-0.3,5.6,0.5,8.4C455.8,1904.7,455.7,1905.9,454,1906.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M810.2,1856.2
				c0.1,0.2,0.2,0.5,0.3,0.7c-4.1,0-8.3,0-12.4,0c-15.1,0.2-30.1,1.7-45.2,1.3c-0.6-0.5,0.1-0.8,0.2-1.2c5.7-0.9,11.3-1.2,17.1-1.3
				c11.5-0.2,22.9-1.1,34.4-0.8C806.5,1854.9,808.4,1855.1,810.2,1856.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M691.7,1968.1
				c-2.1,0.6-4.2-0.2-6.4,0.7c-1.2,0.5-1.5-1.6-2.7-1.9c-0.2-5.7-1.3-11.5,0.8-17.2c1.3,0,0.9,1,0.9,1.6c-0.1,3.5,0.3,6.9,2.9,9.6
				c0.6,0.6,0.4,1.4,0.3,2.1c-0.2,0.7-0.5,1.7,0.6,1.8c0.8,0.1,1.4-0.6,1.6-1.4c0.5-1.5,0.3-3.2,1.4-4.6c0.2,0.1,0.5,0.2,0.5,0.3
				c-0.8,3.4,1.5,5.3,3.8,7c0.8,0.6,0.8,1.1,0.8,1.8c-1.2,0-2.3,0-3.5-0.1C692.2,1967.3,691.9,1967.6,691.7,1968.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M757,1913.8
				c-5.6,0.9-11.3,0.9-16.9,0.3c-1.4-0.1-2.8,0-4.3,0c0.1-1.8-0.5-3.6,0.8-5.2c6.5-0.1,13.1-0.2,19.6-0.3c0.3,1.1-1,2.9,1.3,3.1
				c0,0.5,0,1.1,0,1.6C757.1,1913.2,756.9,1913.3,757,1913.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M181.7,1849
				c-0.3,0.3-0.6,0.8-0.9,0.9c-4.8,1.5-6.1,4.9-5.6,9.5c0.2,1.7-0.6,2.4-2.3,2.2c-1.7-0.2-3.5,0-5.1-0.9c0.1-2.8,0.3-5.6,0.2-8.4
				c-0.1-2.1,0.7-3,2.6-3.3c2.3-0.4,4.6-1.1,6.9,0.5C178.9,1850.6,180.1,1848.1,181.7,1849z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M233,1663.9
				c-0.1,4.3,0.1,8.6-1.1,13.1c-5.7-4.4-11.3-8.8-16.8-13.1c0.6-2.9,2.3-0.3,3.4-0.7c2.4,2.4,6.1,3,7.9,6.1c0.2-0.1,0.5-0.3,0.7-0.4
				c-0.1-1.9-0.2-3.8-0.3-5.7C229,1661.4,231,1662.6,233,1663.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M376.7,1710
				c-7.5,0.3-8.6-0.7-9.5-7.7c-0.2-1.9,0.1-3.9,0.1-5.8c0-0.9,0.6-1,1.3-1.1c3.2-0.5,6.4-0.9,9.6-1.4c1.6-0.3,2.2,0.3,2.2,1.9
				c0.1,3.3-1.8,5.6-5,5.3c-1.9-0.2-2.1,0.6-2.5,2.1C372.4,1706.2,373.4,1708.5,376.7,1710z M368.8,1702.3c0,0.7,0,1.1,0,1.6
				c0,0.9,0,1.8,1.2,1.9c1.3,0.2,1.3-0.8,1.8-1.7c1.2-2.2,0-4.2,0-6.3c0-0.9-0.3-1.5-1.3-1.5c-0.9,0-1.4,0.6-1.5,1.4
				C369,1699.3,368.9,1700.9,368.8,1702.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M887.6,2019.2
				c-0.2-5.7-0.5-11.3-0.7-17c0-1.5-0.2-2.7-1.8-3.5c-1.3-0.7-1.7-1,0.1-2.5c3.2-2.5,3.1,0.1,3.8,1.6c1.5,3.5,2.9,7,4.4,10.5
				c-0.2,0.2-0.4,0.4-0.7,0.6c-2.3,1.4-0.3,3.4-1.2,5c0.6,0.1,0.8,0.2,1,0.1c1.1-0.1,1.3-2.7,2.7-1.3c1.1,1.1-0.7,1.8-1.3,2.5
				c-0.9,1.2-1.3,2.8-3,3.3C889.8,2018.4,888.7,2018.8,887.6,2019.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M417,1827.3
				c-2.3,2.1-4.7,4.2-7,6.3c-0.5,0.5-1.2,1.2-2,0.7c-0.6-0.4-0.3-1.2-0.3-1.8c0.2-2.3,0.6-4.6,0.6-6.8c0-2.2,0.9-3.5,2.9-4.1
				c2-0.4,3.4-1.8,5.2-2.5c0.9-0.4,1.8-1.1,2.7-0.5c1.1,0.7,0.3,1.7,0.2,2.6C419,1823.4,418.7,1825.6,417,1827.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1013.8,1796.8
				c-11,0-21.9,0.6-32.8,1c-3.4,0.1-6.8,0.4-10.1,0.5c-0.7,0-2.1,0.4-1.9-0.8c0.1-0.6,0.1-2,1.9-2.2c6.6-0.8,13.3-1.3,20.4-1.2
				c-3.1,0.9-6-0.3-8.6,1.3C993.1,1796.4,1003.5,1795.6,1013.8,1796.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M293.5,1803.1
				c1.7,0.4,2.6,1.8,3.9,2.8c0.9,0.7,1.7,1.4,2.4,2.1c0.8,0.9,2.7-0.7,2.8,1.3c0.1,1.5-0.9,2.7-1.9,3.7c-2.3,2.2-3.8,5.3-6.6,7
				c-1.2-0.4-2.1-0.8-1.9-2.5c0.5-4,0.5-8-0.2-12C292,1804.4,292.1,1803.3,293.5,1803.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M295.3,1834.9
				c1.5-0.1,1.8-1.2,2.6-2.2c1.3-1.6,3-2.9,4.6-4.1c1.4-1.1,2.8-0.6,2.5,1.3c-0.4,2.8,2.3,5.7-0.4,8.5c-0.6,0.6,0.9,0.8,0.9,1.4
				c0.1,1.3,1.2,2.5,0.4,3.9c-0.5,0.1-0.9,0.1-1.4,0.2c-2-1.4-4.2-2.8-6.1-4.4c-1.3-1.1-3.3-1.8-3.4-4
				C295.2,1835.3,295.3,1835.1,295.3,1834.9z M298.5,1835c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2
				C298.4,1835.2,298.5,1835.1,298.5,1835z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M450.6,1736.8
				c0-0.8,0-1.5,0.1-2.3c0.1-1.8-1-4.1,0.7-5.2c2.1-1.4,4.7-1.4,7.3-1.3c0.7,0,1.3,0.4,1.5,1.1c0.8,3.6,2.2,7.1,1.5,11
				c-1.5-1.5-3.4-2.1-5.5-2.3C454.2,1737.5,452.4,1737.1,450.6,1736.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M979.8,2005.5
				c-4.5,1.6-8.1,4.7-12.1,7.2c-0.5,0.3-1.1,0.6-1.7,0.8c-0.4-1.3-0.8-2.7-1.2-4c-0.2-0.9-0.1-1.6,0.8-2.2c4.7-2.7,9.4-5.5,14.1-8.1
				c0.5-0.3,1.3-0.2,1.9-0.2c0.2,2,0.4,4-0.8,5.8C980.2,2004.7,979.8,2004.9,979.8,2005.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M213,2011.7
				c0.7-0.1,1-0.6,1.3-1.3c0.3-0.8,1-1.6,1.9-1.7c2.6-0.3,4.6,1.6,6.7,2.7c1.7,0.9,1.1,2.9,0.9,4.4c-0.1,1.5-1,1.5-2.1,0.8
				c-1.3-0.8-2.7-0.7-4.1-0.7c-4.5,0.2-5.3,1.4-3.1,5.1c-1.9,1.5-3.3,3.5-6.2,3.8c-2,0.2-2.6-0.3-2.6-2c0.8-1.3,2.2-1.6,3.4-2
				c1.9-0.5,3.1-1.4,2.2-3.6c-0.3-0.9-0.5-1.7,0.5-2.3C213.4,2014.4,213,2013,213,2011.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M967.7,1757.3
				c3.4,0.5,5-3.8,8.6-3c11.3,2.6,22.9,0.3,34.3,1.4c0.8,0.1,1.8,0.7,2.2-0.6c0.2-0.7,0.8-1,1.2-0.6c0.5,0.5,0.8,1.3,0.8,1.9
				c0.1,1-0.8,0.8-1.4,0.8c-3.6-0.1-7.2-0.6-10.8-0.5c-10.4,0.3-20.7,0.8-31.1,1.3C970.2,1758.1,968.9,1758.2,967.7,1757.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M968.6,1781
				c3.2-1.2,6.4-2.3,9.6-3.5c2.7-1,6.2-0.2,10.2-0.2c-3.4,0.9-6.3-0.3-9,1.7c11.9-0.1,23.5-1.2,34.9,1.2
				C999.1,1779,983.9,1781.6,968.6,1781z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M190.9,1844
				c4.3-2.4,8.5-4.7,12.8-7.1c1-0.6,2.2-1.6,3.3-0.8c0.9,0.7,0.3,2,0.2,3.1c-0.2,2.1-0.4,4.3-0.7,6.4c-4.8,0.6-9.5-0.2-14.3,0.1
				C190.8,1845.9,190.9,1844.8,190.9,1844z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M374.3,1920.4
				c-0.5-2.5,0.3-3.4,3.1-3.4c6.5,0,13,0.4,19.5-0.3c4.3-0.5,4.4-0.1,3.6,4.2c-6.3-0.2-12.6-0.4-19-0.5c-0.5-0.3-0.9-0.3-1.4,0
				C378.2,1920.4,376.2,1920.4,374.3,1920.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M969.3,1833.4
				c-0.2,0-0.5-0.1-0.7-0.2c0.1-0.7,0.3-1.1,1.2-1.3c8.3-1.2,16.5-2,24.9-1.5c2.7,0.1,5.4-0.2,8.1-0.3c5-0.3,10,0.4,15,0.7
				c0,0.2,0,0.4,0.2,0.6c-0.8,1.2-2,0.6-3,0.6C999.7,1831.4,984.5,1833,969.3,1833.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M156.1,1989.2
				c-14.9,0.3-29.8,0.7-44.8,1c-0.1-1.3-0.3-2.3,1.8-2.1c5.1,0.6,10.2,0.3,15.3-0.5c1.9-0.3,4.1,0.3,6.1,0.3c2.2,0,2.4-0.8,2.3-2.5
				c-0.1-1.3-0.1-2.6,0-3.9c0-1,0.7-1.7,1.7-1.7c1.2,0,1.7,0.8,1.8,1.9c0.1,1.3-0.2,2.4-1,3.3c-1.7,2-0.7,2.6,1.4,2.6
				c4.2,0,8.3-0.1,12.5-0.1C154.3,1987.6,155.9,1987.2,156.1,1989.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M404.5,1990.4
				c-1.7,2.1-2.7,4.4-2.7,7.1c0,1.3-0.3,2.6-0.7,3.8c-0.4,1.1-1.2,1.8-2.2,1.3c-1.4-0.6,0.3-1.1,0.3-1.7c-0.1-2.6,0-5.6,1.6-7.3
				c3.1-3.3,1.1-7.1,2-10.5c0.3-1.3,0-3,0.2-4.6c0.4-2.9,2.1-4,4.6-5.5C408.2,1979.2,406.2,1984.8,404.5,1990.4L404.5,1990.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M297.4,1694.7
				c-0.1-1.5-0.3-3-0.4-4.5c-0.1-1.4,0.4-2.5,2.1-2.5c0.1-0.1,0.1-0.2,0.2-0.3c0.4,0,0.7-0.2,0.7-0.7l0,0c0.6,0.2,1.2,0.1,1.3-0.6
				l0,0c0.4,0,0.8-0.1,0.6-0.6l0,0c0.6,0.2,1.2,0.2,1.3-0.6l0,0c0.7,0.3,1.1,0.1,1.3-0.6l0,0c0.4,0,0.7-0.1,0.6-0.6l0,0
				c2.3-0.3,3.4,0.3,3.3,3.1c-0.2,5.1,0,5.1-4.9,6.7C301.6,1693.9,299.9,1696.1,297.4,1694.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M299.5,1788.3
				c0,0.5,0,0.9,0,1.4c-1-0.3-1.7,0.3-2.3,1c-0.5,0.1-1.1,0.1-1.1,0.9c0,0.2,0.2,0.4,0.3,0.4c0.6,0,1-0.4,1.2-0.9
				c0.6-0.4,1.2-0.8,1.9-1.2c3.3,1.3,1.8,4.4,2,6.5c0.2,2.1-0.4,4.4,0.3,7.2c-3.5-1.6-5.4-4-7.6-5.9c-0.3-1.3-1.8-2.7,0.6-3.8
				c1-0.5,0.9-1.4-0.5-1.6c-1.6-0.2-2.3-1.1-2.2-2.6c0.1-2,1.8-1.8,2.9-1.9C296.5,1787.6,298.2,1786.7,299.5,1788.3z M298.6,1797.4
				c0.1,0.2,0,0.6,0.4,0.3C298.9,1797.5,298.8,1797.4,298.6,1797.4c-0.1-0.6-0.4-1-1.1-1c-0.1,0-0.2,0.2-0.2,0.3
				C297.5,1797.4,298,1797.5,298.6,1797.4z M295.8,1795.1l-0.2-0.1l0,0.2L295.8,1795.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1017.3,1824.5
				c0.1,0.3,0.2,0.6,0.3,1c-0.9,1-2,0.6-3.1,0.5c-12.3-0.3-24.5,0.4-36.7,1.2c-2.5,0.2-5,0.4-7.7-0.7c1.8-0.8,3.9-0.6,4.8-2.6
				c0.1-0.2,0.7-0.3,0.9-0.1c3.7,3.2,7.9,0.8,11.7,0.7c8.8-0.1,17.7-0.7,26.5,0.2C1015.1,1824.8,1016.2,1824.6,1017.3,1824.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1015.3,1738.1
				c0.2,0.5,0.5,1.1,0.7,1.6c-2.6,1-5.1-0.3-7.7-0.3c-12.2-0.3-24.4,0.3-36.6,1c-1.7,0.1-3.4,0.2-5.5-0.4c2.3-1.5,4.9-1.3,7-1.4
				c12.5-0.8,25-1.4,37.6,0C1012.3,1738.8,1013.9,1738.7,1015.3,1738.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M877.1,1708.3
				c0.1,3-0.9,6.3,1.9,8.8c0.1,0.1,0.2,0.4,0.1,0.6c-1.4,4-2.2,9-1,12.5c0.7,2-0.4,3.5-0.2,5.3c0.2,1.8,0.3,3.4,1.6,4.8
				c-1.6,1.9-1.7,3.9-0.7,6c-0.3,1.4-0.9,2.8,0.7,4c1.3,1-0.4,2-0.6,3c-0.1,0.5-0.4,1.1-1.1,0.8c-1-0.5-0.7-1.5-0.7-2.3
				c-0.4-9.7-0.6-19.3-1.3-29c-0.3-4.6-0.9-9.2-0.4-13.9C875.9,1708.8,876.5,1708.6,877.1,1708.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1017.6,1744.4
				c3.2,3,6.3,5.9,9.5,8.9c0.5,0.5,1.4,0.9,1.1,1.8c-0.3,0.9-1.2,1.1-2.1,1c-2.7-0.5-5.4,0-8-1c-0.2-3.1-3.3-2.3-4.8-3.5
				c-0.3-2,2.8-1.3,2.5-3.2c0.7-0.2,1.8-0.5,0.6-1.1c-1.1-0.5-2.2-1-3.3-1.6c0.1-0.4,0.2-0.8,0.3-1.3
				C1014.7,1744.4,1016.2,1743.4,1017.6,1744.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M978.4,1890.3
				c-5.9,0.1-11.7,0.2-17.6,0.3c-2.7-4.1,1.2-3.9,3.2-4.9c1.5-0.2,3.1-0.3,4.6-0.5c1.1,0.6,2.3,1,3.3-0.2c2.3,0.1,4.7-2.1,6.8,0.6
				C978.6,1887.2,978.5,1888.7,978.4,1890.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M714.6,1729.4
				c-0.9,5.9-1.2,12-3.5,17.6c-0.8,1.9-0.6,4-1.7,5.8c-0.9-0.7-1.9-1.4-2.6,0.2c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3-1-0.7-2.1-0.2-3.1
				c3.1-6,4.7-12.7,7.6-18.9C713.4,1730.5,713.7,1729.7,714.6,1729.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1015.8,1748.3
				c0.3,1.9-2.8,1.3-2.5,3.2c-12.4-0.6-24.7-0.5-37.1,0.4c-3.2,0.2-6.5,0.7-10.3-0.2c2.4-1.5,4.8-1.2,6.8-1.4
				c12.4-0.9,24.9-1.5,37.3-0.3C1012.3,1750.3,1013.5,1747.9,1015.8,1748.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M312.9,1911.9
				c2.2,0,1.9,1.5,2,2.9c0.4,8-0.7,15.8-2.2,23.6c-0.6,3.2-0.8,6.6-3.2,9.7c-0.8-4.5,1-8.5,1.3-12.6c0.3-3.9,1.8-7.6,1-11.6
				c0.2-1,0.5-2,0.7-3c0.9-0.6,0.8-1.4,0.3-2.2C312.7,1916.5,312.6,1914.2,312.9,1911.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M527.4,1679.9
				c-3.5,0.1-7,0.3-10.6,0.4c-0.7-0.4-1.3-0.4-1.9,0.1c-5.1,0.5-10.1,1-15.2,1.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4
				c10.9-1.4,21.6-3.7,32.5-4.8c2.2-0.2,3.7,1.8,5.9,1.4c0.4-0.1,0.7,0.5,0.6,0.9c-0.2,0.6-0.8,0.7-1.3,0.7
				C533.9,1679.5,530.6,1679.1,527.4,1679.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M898.9,1721.4
				c2.1-3.1,3.8-6,1.2-9.9c-0.5-0.7,0.9-3.6,0.9-5.7c0.3,0,0.7,0,1,0c0.3,7.2,0.7,14.3,1,21.5c0.1,1-0.7,2.6,1.5,2.4
				c0.2,0.1,0.5,0.2,0.7,0.3c-1.6,0.9-2,2.2-1.9,4.1c0.4,5.6,0.6,11.3,0.8,16.9c0,0.7,0.4,1.6-0.6,2c-0.4,0.2-0.8,0.1-1.2-0.3
				c-0.5-0.7,0-1.3,0-1.9c0.1-3.4,1.5-7.1-1.4-10.2c-0.7-0.7,0.3-1.3,0.5-2c0.7-2,1.8-4-0.4-5.8c-0.1-0.1-2.1-2.3,0.6-2.9
				c1.1-0.2,0.4-1.3,0.4-2c-0.1-1.3-0.6-2.7-0.4-3.9C902.2,1721.4,900.8,1721.2,898.9,1721.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M299.1,1687.7
				c-2.4-0.6-3.7-2.9-2.1-4.6c3.2-3.4,5.6-7.5,9.5-10.2c2.3,1.5,0.6,4.1,1.5,5.9c1.7,3.1-1.9,3.1-2.8,4.7l0,0
				c-0.4,0-0.7,0.1-0.6,0.6l0,0c-0.7-0.3-1.1-0.1-1.3,0.6l0,0c-0.6-0.1-1.1,0-1.3,0.6l0,0c-0.4,0-0.7,0.1-0.6,0.6l0,0
				c-0.7-0.3-1.1,0-1.3,0.6l0,0c-0.4,0-0.7,0.2-0.7,0.7C299.2,1687.5,299.2,1687.6,299.1,1687.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M161.2,1855
				c0,0.6,0,1.3,0,1.9c0.2,2.4-0.3,3.9-3.2,2.7c-1.6-0.7-4.1,0.9-5-1.1c-0.7-1.5,0-3.6-0.3-5.4c-0.6-3.2,0.8-3.7,3.6-3.6
				C161.3,1849.8,161.3,1849.7,161.2,1855z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.9,1774.7
				c1.6-0.3,3.2-0.6,4.8-0.8c0.9-0.1,2-0.4,2.7,0.6c0.7,1.1-0.1,1.8-0.6,2.6c-2.2,3.4-5.1,6.4-6.3,10.5c-1.6,0.8-3.1,0.5-3.8-1.1
				c-1.4-3.4,2.4-2.6,3.6-3.9c-0.5-1.2-1.4-0.8-2.1-0.9c-1.6-0.2-1.6-1.5-2-2.6c-0.5-1.5,0.6-1.9,1.6-2.3
				C383.8,1776.3,385.1,1776.3,384.9,1774.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M754.9,1789
				c14-2.8,28.1-1.6,42.2-1C783,1788.2,768.9,1789.2,754.9,1789z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1013.3,1744.3
				c-0.1,0.4-0.2,0.8-0.3,1.3c-15.1-0.9-30.3,0.5-45.4,0.2C982.8,1743.6,998,1743.2,1013.3,1744.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M312.5,1921
				c-0.2,1-0.5,2-0.7,3c-1.2-1.6-0.3-3.6-1-5.2c-1.2-0.1-0.9,0.9-1.4,1.3c-2.2,1.9-3.8,1.6-4-1.3c-0.2-2.3-0.7-4-3.3-4.4
				c-0.8-0.2-1.5-0.7-1.3-1.6c0.2-1.1,1.2-1.3,2-1.3c2.5,0.1,5,0.4,7.5,0.5c0.9,0,1.7-0.1,2.6-0.1c-0.4,2.3-0.3,4.6-0.1,6.9
				C311.7,1919.4,311.4,1920.1,312.5,1921z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M450.6,1736.8
				c1.8,0.3,3.6,0.8,5.5,0.9c2.1,0.2,3.9,0.8,5.5,2.3c-0.5,2.2,2.1,3.8,1.4,6.1c-3.9-1.5-7.9-2.5-12.1-2.1
				C450.3,1741.6,449.9,1739.2,450.6,1736.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M406.8,1760.7
				c0.6,5,0.5,5.1-4.4,4.7c-4.7-0.4-9.5-0.7-14.2-1c-0.2-0.7-0.4-1.4-0.6-2.1c1.3-2.1,4.1-1.4,5.8-2.9c2.5,0.2,4.9,0.5,7.4,0.7
				c0,0.4-0.2,0.9-0.1,1.3c0.4,0.9-1.9,1.9,0,2.6c1.3,0.5,1.8-0.8,2.3-1.8c0.3-0.6,0.5-1.4,0.8-2.1
				C404.8,1759.8,405.7,1760.4,406.8,1760.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1014.5,1774.5
				c0,0.2,0,0.4,0,0.6c-12.7-1.6-25.5-0.3-38.2,0c-1.8,0-2.7,1.2-3.8,2.2c-0.6,0.5-1.5,1.2-2,0.7c-0.7-0.7-1.2-1.7-1.5-2.7
				c-0.3-1,0.9-1.1,1.5-1.4c0.5-0.3,2.4,0.3,0.6-1.3c-0.2-0.1,1.5-0.9,1.9-0.1c0.6,1.4,1.8,1.2,2.7,1.1c11.5-1.5,23-0.4,34.5-0.5
				C1011.9,1773.2,1013.4,1773.2,1014.5,1774.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M252.7,1627.2
				c-2.3-0.4-4.8,0.6-7-0.6c0-0.9,0-1.8,1-2.2c1-0.5,1.2-0.9,0-1.5c-0.7-0.3-0.8-0.9-0.6-1.6c0.6-2.2,1.2-4.2-2.6-3.2
				c-1.8,0.4-2.1-2.3-2-3.1c0.3-1.4,0.4-3.6,2.8-3.7c1.2-0.1,2.4-0.1,3.6-0.1c2.1,0,2.9,0.8,1.8,2.8c-1.4,2.5-1,4.7,0.4,7.1
				C251.3,1622.9,252.8,1624.8,252.7,1627.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1015.8,1785.7
				c0.5,0.5,1.5,0.2,1.7,1.1c-12.3-1.2-24.6-1-36.9,0.4c-3.4,0.4-6.8,0.1-10.3-0.3c2.6-2,5.7-1.9,8.6-2c9.4-0.3,18.8-0.2,28.2-0.2
				C1009.9,1784.8,1012.9,1784.9,1015.8,1785.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.1,1766.9
				c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.1-0.2-0.2c0-0.6,0-1.2,0-1.8c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1-0.1-0.2-0.2-0.3
				c-0.2-1.3,0.6-2.6,0-3.8c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.2c0.1-1.8,0.2-3.6,0.3-5.4c1.9,0,3.8,0,5.7,0
				c0.2,1.5-0.3,3-0.5,4.4c-0.6,4.2-2.5,8.2-2.1,12.6c-1.1,1.4-2.2,2.1-3.5,0.2c0.4-0.4,0.7-0.8,0-1.2l0,0.1
				C269.1,1768.7,269.1,1767.8,269.1,1766.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M306.4,1771.3
				c-2.8-2.5,1.3-4.2,0.7-6c-0.8-2.2-1.8-3.8,0.7-5.4c0.4-0.2,0.8-0.4,0.7-0.9c-0.1-0.6-0.6-0.8-0.9-0.6c-5.8,3.1-3.2-1.8-3.6-3.7
				c0.9,0,1.8-0.1,2.6,0.1c3.5,0.5,4.6,1.7,4.5,5.2c0,1.3-0.4,2.7,0,3.9c1.1,3-1.2,4.9-2.1,7.3
				C308.7,1772.3,307.3,1770.6,306.4,1771.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M305.8,1670.3
				c-2.3,2.7-4.6,5.5-6.9,8.2c-0.6,0.7-1.1,1.4-2.2,1.2c-1.1-0.3-1-1.3-1.1-2.1c-0.4-3-0.7-6-1.1-9c3.5-1.3,6.9,0.3,10.4,0.3
				C305.7,1668.7,305.8,1669.6,305.8,1670.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M241,1920.9
				c-0.2-1.5-1.8-1.9-1.8-3.2c4,0,7.9-1.5,11.9-0.8c0.7,0.1,1.8-0.7,2.1,0.6c0.2,1,0.4,2.2-0.8,2.9c-1,0.6-2.1,1.5-3.2,1.6
				c-4,0.1-7.9,1.3-12,0.9c-0.9-0.1-2.3,0.1-2-1.4c0.2-1.2,1.2-0.8,2.1-0.6C238.5,1921.2,239.7,1921.2,241,1920.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1017.2,1819.6
				c0.1,0.2,0.2,0.5,0.3,0.7c-15.5-0.5-31,0.9-46.6,0.9c2.2-1.5,5.3-0.6,6.9-3.1c1.7,2.6,4.1,0.9,6.1,0.9c10.4,0.1,20.8,0.1,31.1,0
				C1015.9,1819,1016.6,1819.1,1017.2,1819.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M394,1928.3
				c0.8,2.3-0.2,3.2-2.5,3.3c-5.8,0.2-11.5,0.6-17.3,0.9c-1-3.8-0.8-4,3-4.3c4.2-0.4,8.4-0.6,12.6-0.6
				C391.3,1927.6,392.7,1927.7,394,1928.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.9,1807.8
				c0,0.4,0.1,0.9,0.1,1.3c-13.8-1.7-27.5,0.1-41.2,0.9c-2.5,0.1-5,0.8-7.9-0.9c5.8-0.5,11.1-1.3,16.4-1.6c7.5-0.5,15-0.4,22.4-0.5
				c3,0,6.1,1,9.1-0.8C1016.8,1805.7,1016.3,1807.4,1016.9,1807.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.6,1812.7
				c-0.2,0.9,0,1.6,0.6,2.3c-7.3-1.3-14.6-0.7-21.9-0.4c-8.6,0.4-17.3,0.9-26.5,1.4c1.9-2.4,4.1-1.9,5.9-2
				c13.1-1.2,26.3-1.4,39.4-0.8C1015.1,1813.2,1015.8,1813,1016.6,1812.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M556.9,1291.3
				c0.6-1.7,1-3.4,2.6-4.5c1.8,7.7,5.4,15.1,4.6,23.4c0,0.4,0,0.9-0.8,1.1c-1.9-2.7-1.8-6.1-3.1-9
				C558.7,1298.8,559.2,1294.6,556.9,1291.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M959.3,1734.3
				c-1.7,0-3.3,0.5-4.8,1.4c-1.9,0-2.1-1.6-2.2-2.7c-0.3-2.8-0.4-5.6-0.5-8.5c0-0.9,0.5-1.9,1.6-1.5c1.8,0.6,4.5-0.1,5,2.7
				c0.2,1.5,0.3,3.2-0.2,4.5C957.6,1732.1,958.5,1733.1,959.3,1734.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M407.8,1841.9
				c-1.1-7.2,5.8-8.3,9.2-12c0.4,4.3-0.8,8.4-1.4,12.6c-0.1,0.9-1.2,0.8-1.9,0.6c-0.9-0.3-1.6-1.2-2.7-1.1c-0.4-0.1-0.8-0.1-1.2-0.2
				l0.1,0c-0.1-0.5-0.5-0.7-0.9-0.7c-0.1,0-0.2,0.5-0.3,0.7C408.3,1841.9,408,1841.9,407.8,1841.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.4,1833.4
				c0,0.6,0.1,1.3,0.1,1.9c-9.4,0.5-18.8,0.9-28.2,1.4c-0.8,0-1.7,0.1-1.9-0.9c-0.2-0.9,0.8-1.4,1.5-1.5c1.5-0.2,3-0.3,4.6-0.3
				C526.5,1833.8,534.5,1833.6,542.4,1833.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.4,1802.1
				c0.1,0.4,0.2,0.8,0.2,1.2c-15.2-0.7-30.4,0.6-45.7,0.8C986,1800.7,1001.2,1802.2,1016.4,1802.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M974.6,1961.5
				c0.6,0.3,1.2,0.5,1.8,0.8c0.6,1.1,1.9,2,2.8,1.7c1.3-0.5,0.5-2.1,0.6-3.2c0.2-2.3-0.7-4.8,0.6-7c0.3,0.6,0.8,1.1,0.8,1.7
				c0,7,0,13.9,0,20.9c0,0.8-0.2,1.5-0.9,2c-0.1-2.2-0.2-4.5-0.4-6.7c0.9-3.6-0.9-5.8-3.9-7.3c-3.1-1.5-5.5-3.9-8.6-5.3
				c-0.9-0.4-2.5-1.1-1.9-2.6c0.5-1.2,1.6-2,3.2-1.7c-0.1,1-0.7,2.2,0.5,2.9C971,1958.9,972.4,1960.8,974.6,1961.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1012.7,1762.7
				c-5.8,0-11.7,0.1-17.5,0c-7.2-0.1-14.3,1.2-21.4,1.7c-1.5,0.1-3.1,0-4.6,0.1c-0.7,0-1.4-0.3-1.4-1c-0.1-1,0.9-0.6,1.4-0.8
				c4.6-1.4,9.3-0.9,14-1.1c9.4-0.3,18.8-0.8,28.1,0.4c0.5,0.1,1,0.2,1.5,0.3C1012.7,1762.4,1012.7,1762.5,1012.7,1762.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M121.7,1748.3
				c3,2,5.9,4.1,8.9,6c1.5,1,1.7,1.8,0.5,3.3c-1.2,1.5-1.9,1.4-3.3,0.4c-4.8-3.6-9.7-7-14.5-10.4
				C116.1,1746.9,118.9,1747.6,121.7,1748.3z M129.1,1756.1c0.1,0.1,0.2,0.1,0.2,0.2C129.2,1756.2,129.1,1756.2,129.1,1756.1
				c-1.7-2.6-4.3-3.6-7-4.6C123.9,1753.7,125.8,1755.9,129.1,1756.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M293.4,1777.7
				c0.4-0.6,0.8-1.2,1.3-1.8c0.9-1.2,2.3-1.1,3.6-1c1.4,0.2,1.4,1.5,1.5,2.5c0.2,2.4,0.4,4.7,0.6,7.1c-0.9,0.1-1.7,0.2-2.6,0.4
				c-2,0.4-5.2,1.6-5.7-0.1C291.2,1782.9,291.5,1779.9,293.4,1777.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M508,1816.9
				c2.1-2.1,4.1-2.3,6-2.4c8.3-0.3,16.5-0.5,24.8-0.6c0.9,0,2.3-0.5,2.3,0.9c0,1.1-1.4,0.7-2.2,0.7c-9.3,0.5-18.6,0.9-27.9,1.4
				C510.3,1816.9,509.6,1816.9,508,1816.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M150.7,1723.8
				c2.4,0.1,4.9,0.1,7.2-0.7c0.7,0.2,1.4,0.4,2.2,0.6c-0.2,1.6-2,1.1-2.8,2.3c2.3,1.6,4.6,0.6,6.6-0.1c4.4-1.5,5-1.2,4.9,3.8
				c-0.7-0.8-0.3-2.4-1.7-2.5c-1.2-0.1-1.3,1.3-2,1.8c-0.6,0.5-1.4,3.7-2.7,0.4c-0.4-1-1.4-1.2-2.5-1.3c-2.5-0.2-5-0.5-7.4-1
				C150.8,1726.8,149.8,1725.7,150.7,1723.8z M154.6,1725.7c-0.5-0.3-0.7-0.5-1-0.5c-0.4,0-1.3-0.2-1.1,0.5
				C152.7,1726.3,153.5,1726.2,154.6,1725.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M320.3,1680.4
				c0-2.5,0-5,0-7.5c0-1.1,0.1-2,1.5-2c1.2,0,1.7,0.5,2,1.7c1,4.5,0.7,9.1,1.3,13.6c0.3,2.2-0.7,3.2-2.9,3.3
				c-2.8,0.1-1.7-1.9-1.8-3.2C320.2,1684.3,320.3,1682.3,320.3,1680.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M121.1,2028.6
				c-3.2-3-6.5-5.9-9.7-8.9c0.2-1-0.7-2.3,0.6-2.9c1.1-0.5,2.1,0.4,2.9,1.1c1.9,1.5,3.8,3.1,5.7,4.7c0.6,0.5,1.2,1.2,2,1
				c2.8-0.6,3.4,1,3.1,3.1c-0.3,2.3-1.9,2.7-3.9,1.9C121.5,2028.5,121.3,2028.6,121.1,2028.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M649.7,1888.9
				c-1.1,3.6-2.3,7.2-4.2,10.5c-0.5,0.9-1.4,0.3-1.7-0.5c-1.5-3-3.4-5.8-3.9-9.2C643.1,1889.3,646.4,1888.6,649.7,1888.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M460.9,1979
				c-4.5-0.9-9.1-0.2-13.6-0.2c-1.5,0-3.3,0.8-3.6-2c-0.2-2.8,1.2-3.4,3.4-3.5c1.3-0.1,2.6-0.2,3.9-0.4c1.2,2-2,0.8-1.6,2.3
				c2.9,0,6,0,9,0C462.2,1975.3,462.5,1975.8,460.9,1979z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M125.4,1850
				c3.1,3.2,6.1,6.4,9.3,9.8c-3.2,0.8-5.6,0.7-7.9-2c-2.5-3.1-5.6-5.8-8.4-8.7C121.1,1846.9,123.2,1848.7,125.4,1850z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M541.7,1821
				c-0.6,1.2-1.7,1-2.6,1.1c-9,0.5-17.9,1-26.9,1.4c-0.9,0-2.5,0.8-2.6-0.9c-0.1-1.1,1.5-1,2.4-1.1c9.3-0.8,18.7-1.1,28.1-1.1
				C540.6,1820.4,541.2,1820.2,541.7,1821z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M603.1,1652.4
				c9.6,1,19.2,2,28.7,3.1c-0.2,1.8-1.5,1.1-2.5,1.1c-7.2,0.3-14.3-1.4-21.5-1.8c-3.3-0.2-6.5-0.6-9.8-0.9c-1-0.1-1.8-0.2-1.7-1.4
				c0.3-1.7,1.3-0.6,1.9-0.4C599.9,1652.8,601.5,1652.7,603.1,1652.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M282.2,1847.1
				c-0.2-2.5,2-3.3,3.5-4.3c2.2-1.6,3.3-3.3,3.2-6.2c-0.2-2.8-0.5-5.7,0.1-8.5c1.4-0.2,1.9,0.5,1.9,1.9c-0.1,5.1-0.1,10.3-0.1,15.4
				c0,2.8-1.8,3-3.9,2.9h0C285.8,1846.2,283.8,1847.4,282.2,1847.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M390,1714.8
				c6.6,4.2,13.3,8.5,19.9,12.7c2.4,1.5,4.7,3,7.1,4.5c0.2,0.5,0.3,1,0.5,1.6c-8.6-5.4-17.3-10.8-25.9-16.2
				c-0.7-0.5-1.6-1.3-2.2-1.2c-3,0.6-3.4-1.4-4-3.4c1.2-0.3,1.8,0.8,2.8,0.8C388.4,1714.4,389.1,1714.8,390,1714.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M843.2,1834.3
				c0.7,2.6,0.5,5.3,0.5,7.9c0,1.8-0.3,3.6,0.4,5.4c0.5,1.3,0.2,2.4-1.5,2.4c-1.5-0.4-2.1-1.5-2.8-2.8c-0.7-1.3-0.5-2.9-1.7-4.1
				c-1-1-2.1-2.4-1.3-3.7c0.9-1.7,2.7-0.4,4.4-0.5C839.5,1836.3,840.2,1834.7,843.2,1834.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1012.7,1768
				c-10,1.5-41.8,1.7-43.6,0.2C983.8,1766.7,998.3,1766.7,1012.7,1768z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M292.3,1757.6
				c6.6,5.7,9.1,9.5,2.6,15.8c-0.3-0.4-0.6-0.8-0.9-1.2c-0.6-0.7-1.7-1.1-1.1-2.3c0.6-1.2,1.6-0.7,2.5-0.8c0.1,0,0.2,0.1,0.3,0.1
				c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.3,0-0.7-0.2-0.9c-0.8-0.9-2.9,0.5-3.1-1.5C291.8,1763.7,290.4,1760.6,292.3,1757.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M326.9,1862.8
				c-1.2-0.3-2.4-0.6-3.6-1c0-2.3,0.2-4.6,0.1-6.9c-0.1-2,0.1-3.2,2.7-3.1c2.3,0,3.2,0.8,2.9,3c-0.4,2.8,0.6,5.6,0,8.4
				c-0.4-0.1-0.7-0.2-1.1-0.3C327.5,1862.9,327.2,1862.9,326.9,1862.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.7,1840.5
				c-0.1,0.4-0.1,0.9-0.2,1.3c-9,0.4-18.1,0.9-27.1,1.3c-0.6,0-1.4,0-1.6-0.8c-0.2-1.1,0.8-1.1,1.5-1.2c5.6-1.1,11.3-0.6,16.9-0.7
				C535.8,1840.5,539.3,1839.5,542.7,1840.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M143.4,2014.7
				c2.9-0.1,5.8-0.3,8.7-0.4c3.1-0.1,2.5,1.9,2.2,3.7c-1.3,1.4-3.3,2-4.2,4c-0.4,0.9-1.8,0.8-2.6,0.1c-1.5-1.3,1.2-1.4,0.8-2.5
				c-1-0.4-1.7,0.1-2,1c-0.4,1.6-1.4,1.5-2.5,1.2c-1.3-0.4-1.1-1.5-0.8-2.4C143.7,2017.9,143.5,2016.3,143.4,2014.7z M145.8,2017.1
				c0.4-0.1,0.8-0.2,0.6-0.6c-0.1-0.2-0.5-0.4-0.7-0.4c-0.2,0-0.4,0.4-0.5,0.6C145.4,2016.9,145.6,2017,145.8,2017.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M979.8,1945
				c-2.9,0-4.7,2.2-6.9,3.6c-2.1,1.3-4.2,2.5-6.4,3.6c-1.3,0.7-2,0.5-2.1-1.1c0.8-0.2,1.5-0.6,1.1-1.5c-0.4-0.9-1.2-0.5-1.9-0.1
				c-0.4-1-1.7-1.8-0.7-3.1c0.9-1.3,2.1-0.7,3.2-0.6c0.7,0.1,1.5-0.1,2.3-0.1c3,0.7,5.7,0.4,8-2c1-1.1,2.5-2.2,4.4-1.6
				c-0.1,0.9-0.1,1.8-0.2,2.6C980.2,1944.5,979.9,1944.6,979.8,1945z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M200.2,2025
				c-2.8-1.2-5.3,0.3-8,0.6c0.5-1.7-2.6-3.9,0.8-5.2c0.5-0.2,0.7-1.2,1.5-0.9c0.9,0.4,0.9,1.1,0.4,1.9c-0.4,0.5-0.8,1.2,0,1.6
				c0.6,0.3,1.5,0.3,1.7-0.5c0.7-3.5,2.1-0.4,3.6,0c-1-1.9,0.6-4.5-2.8-4.5c-0.4,0-0.4-0.5-0.1-0.6c1.6-0.6,0.1-2.5,1.6-3.4
				c0.4,2,3.1,2.5,2.4,5c-0.3,1,0.3,2.1,1.4,2.7c1.3,0.7,2.2-0.1,2.8-0.9c1.4-1.6,2.5-1.2,3.7,0.2c-1.3,0.4-2.6,0.7-3.4,2
				C203.7,2023.1,201.2,2022,200.2,2025z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M161.1,1935.4
				c0.5,2-0.8,3.7-0.5,5.7c0.2,1.9-1.1,1.4-2.2,0.8c-3.7-2-7.3-4.1-11-6.1c0-0.4,0.1-0.9,0.1-1.3c3.7,0.1,7.4,0.1,11.1,0.2
				C159.2,1935.8,160.2,1935.4,161.1,1935.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M332.6,1779.7
				c2-1.7,4.2-1.4,6-1.8c2.1-0.5,2.9-1.3,2.8-3.5c-0.1-4.9,0-9.8,0-14.7c0-0.7-0.4-1.8,0.7-1.9c1.2-0.1,1.2,1.1,1.2,1.9
				c0.2,5,0.5,10,0.3,14.9c0,1.3,1.8,2.6,0.5,3.5c-1.6,1.1-3.7,1.5-5.6,1.5C336.7,1779.6,334.9,1780.7,332.6,1779.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M911.1,1696.8
				c4,0.2,7.8,0.4,12.3,0.6c-4.1,4.1-0.6,9.8-4.5,13.8c-0.1-2-0.5-3.9-0.4-5.5c0.4-4.9-2-6.8-6.4-7.4
				C911.5,1698.2,911,1697.9,911.1,1696.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M287.3,1791.6
				c0.8,0.9,1.1,2.1,1.4,3.3c0,1.1,0.1,2.2,0.1,3.3c-0.6-0.2-1.7,0.9-1.8-0.4c-0.3-2.1-2.2-2.8-3.5-4.1c-1.4-1.3-2.5-1.1-3.8,0.4
				c-3,3.6-5.8,7.5-9.3,10.7c0.2-1.2,0.4-2.4,0.6-3.6c4-2.6,6.4-6.5,8.8-10.4C282.3,1790.7,284.9,1790.6,287.3,1791.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M392.1,1857.6
				c-1.2-0.2-2.4,0.5-3-0.5c-0.7-1.2,0.6-2,1.1-2.8c0.7-1.1,2-2,3.5-2c2.5,0,5,0.1,7.5,0.1c1.3,0,2,0.7,2,2c0,1.2-0.4,1.7-1.7,1.9
				C398.3,1856.7,395.1,1857.2,392.1,1857.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M773.2,2009
				c0.6,1.4,1.2,2.5,1.5,3.6c0.4,1.7,0.7,3.2,2.9,3.2c0.7,0,1.2,0.6,0.9,1.4c-0.3,0.8-1,0.8-1.6,0.4c-3-1.9-2.9-0.3-2.7,2.1
				c0.3,3.5,0.3,6.9,0.4,10.4c0,1.2-0.5,2.2-2,2.1c-1.5-0.1-1.5-1.3-1.2-2.2c0.9-3.9,0.1-7.9,1-11.8
				C773.1,2015.2,771,2012.2,773.2,2009z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M281.6,1715.3
				c5.4,4.1,5.4,4.1,5.1,8.9c-2.3-1.5-3.6-1.1-3.7,1.7c0,0.7-0.3,1.3-1.2,1.2c-0.8-0.1-0.8-0.7-0.6-1.2c2.4-5-1.1-7-4.4-9.3
				c-0.4-0.3-0.8-0.8-1.1-1.1C277.6,1714.8,279.5,1714.4,281.6,1715.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M279.8,1790.8
				c-2.4,3.9-4.8,7.8-8.8,10.4c-1.5-2-1.7-9.1,0-9.5C273.8,1790.9,276.7,1789.8,279.8,1790.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964.7,1793.2
				c0.4,0,0.9,0,1.3,0c-0.4,1.7-2.4,1.2-3.3,2.3c1.9,0.9,2.2,4,0.9,5.4c-1,1.1-2.4,0.9-3.4,1.6c0,0-0.2,0.1-0.2,0.1
				c-1.4-0.3-3.7,1-4.1-0.6c-0.5-1.6,1.4-2.6,3.2-2.9c0.5-0.1,0.8-0.3,0.8-0.8c0-0.5-0.4-0.7-0.8-0.8c-1.2-0.5-3.5,1.2-3.4-1.5
				c0-1.5,4.5-3.4,6.2-2.9C962.8,1793.4,963.7,1793.6,964.7,1793.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M504.4,1603.7
				c3.3,1.5,6.8,1.4,10.2,1.2c2.7-0.1,4.8,0.8,6.9,2.2c-0.6,1.9-2.2,1.8-3.7,1.7c-1.2,0-2.3,0.1-3.5,0.1l-0.1-0.1
				c-0.1-1.1-0.9-1.2-1.7-0.9c-2.7,1-5.2-0.2-7.8-0.5C501.7,1607,503.4,1605.1,504.4,1603.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M525.9,1826.9
				c4.7,0,9.3,0,14,0c0.7,0,1.8-0.2,1.8,0.9c0,1.1-1.1,0.9-1.8,0.9c-9-0.4-17.8,1.7-26.7,1.4c-0.8,0-2,0.3-2-1c0-1.2,1.2-1.1,2-1.1
				C517.5,1828.2,521.6,1826.9,525.9,1826.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M488.8,1933.5
				c0-1.1,0-2.2-0.2-3.2c-0.6-3.3,1.1-3.8,3.8-3.5c2.2,0.2,3.5,0.8,2.8,3.4c-0.2,0.8,0,1.7,0.1,2.5c-0.7,0.8-0.8,1.8-0.8,2.7
				c0,0.9,0.1,1.8-1.2,1.9c-1.1,0.1-2.1-0.1-2.6-1.4C490.4,1935,490.6,1933.5,488.8,1933.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.8,1853.6
				c0,0.4,0,0.9-0.1,1.3c-4.3,1.4-8.9,0.1-13.3,1.2c-3.3,0.8-6.7,0.7-10.1,0.7c-1,0-2.2,0.1-2.3-1.3c-0.1-1.5,1.2-1.2,2.2-1.2
				C527.1,1854.1,534.9,1853.3,542.8,1853.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M967.9,1686.8
				c7.4-1.8,14.9-2.4,22.6-1.6c2.4,0.2,4.8,0.2,7.2,0.2c1.6,0.1,3.3,0,4.5,1.3c-3.3,0-6.6,0-9.9-0.1c-8.1-0.3-16.2,0.5-24.3,1.4l0,0
				C967.9,1687.6,967.9,1687.2,967.9,1686.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M293.4,1830.1
				c2.4-2.6,4.7-5.2,7.1-7.8c0.9-0.9,1.8-3.4,3.3-1.8c1.2,1.3,1.4,3.7-0.5,5.3c-2.7,2.3-5.5,4.5-8.2,6.8
				C293.5,1832.6,293.3,1831.4,293.4,1830.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M474.3,1908.2
				c7.4-1.4,14.9-1.3,22.3-2.2c0.7,0.3,1.6,0.3,1.7,1.2c0.2,1.7-1.8,1-2.4,2c-6.8,0-13.6-0.1-20.4-0.1
				C475.4,1908.4,474.7,1908.5,474.3,1908.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M475.7,1688.7
				c-1.2-2.7-0.3-4.1,2.7-4.3c2.1-0.2,4.2-1.2,6.4-1.1c0.9,0.2,1.9,0.3,3,0.5c-0.4,1.7-1.6,2.2-2.9,2.7c-2.9,1-4.7,2.7-4.1,6.1l0,0
				c-0.5-0.3-1.5-0.7-1.5-1C479.3,1689,477.4,1689,475.7,1688.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M542.9,1859.5
				c0,0.7-0.1,1.3-0.1,2c-2.8,1.1-5.8,0.6-8.7,0.7c-4.5,0.2-8.9,0.4-13.4,0.5c-0.9,0-2.3,0.4-2.4-1c-0.1-1.4,1.3-1.5,2.4-1.7
				c1.1-0.1,2.2-0.1,3.3-0.1C530.3,1859.9,536.6,1859.7,542.9,1859.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M869.2,1711
				c-1,1.5-0.8,3.2-0.6,4.7c1.5,10.3,0.9,20.8,1.8,31.2c-1.9-0.8-1.7-2.7-1.7-4.1c-0.1-9.9-0.9-19.7-1.5-29.6
				C867.2,1711.9,867,1710.3,869.2,1711z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M512.3,1976.5
				c-4.3,1-8.6,0.9-12.9,0.9c-2.7,0-3.3-1.4-1.9-3.9c4,0.4,8,0.7,12,0.3C511.7,1973.5,512.4,1974.6,512.3,1976.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M540.6,1447.8
				c-0.1,0.8,0.2,1.6-0.2,2.5c-0.7,1.7-0.9,3.6-3.5,3.5c-2.1-0.1-3.5-1.2-4.1-3c-0.6-1.8-0.3-3.7,1.6-4.9c2-1.2,3.7-0.4,5.2,1.1
				C540,1447.4,540.3,1447.6,540.6,1447.8L540.6,1447.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M274.4,1771.6
				c3.8-0.1,7.6-1.1,11.3-1.7c0.8-0.1,2.4-0.8,1.9,0.9c-0.4,1.1,1.3,3.6-1.8,3.5c-3.8-0.2-7.6-0.3-11.5-0.4
				c-0.6-0.4-1.5-0.4-1.5-1.4C272.9,1771.6,273.9,1771.9,274.4,1771.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M567.4,1282.8
				c0.9-1.4,1.7-2.9,2.6-4.3c3.5,0.9,6.2,3.2,8.9,5.5c0.2,0.7,0.4,1.4,0.6,2.2c0.1,0.3,0.1,0.6,0.2,0.9c0,0.5,0.1,1,0.1,1.4
				c-0.3,0-0.6,0.1-0.9,0.1c-2.7-3.9-6.5-5.5-11.2-5.1C567.5,1283.2,567.4,1283,567.4,1282.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M569.3,1474.7
				c-1.8,0-4.5-2.8-4.1-4.7c0.4-1.7,0.7-3.9,3.2-3.8c2,0,5.1,2.6,4.8,4.2C572.8,1472.5,572.3,1474.8,569.3,1474.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M381.5,1920.4
				c6.3,0.2,12.6,0.4,19,0.5c0.2,1.6-0.6,2.2-2.1,2c-7.6-0.9-15.3-0.4-22.9-0.6c-1.6,0-1.9-0.6-1.3-2c2,0,3.9,0,5.9,0
				C380.6,1921,381.1,1921,381.5,1920.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M197.1,1909.4
				c1.2-0.2,2.3-0.6,3.5-0.6c1.8,0,3.6,0.4,2.3,3c-0.3,0.6-0.6,1.3,0,1.8c0.7,0.5,1.2-0.1,1.6-0.6c1-1,2.3-1.3,3.5-0.6
				c0.6,0.3,0.2,0.9,0,1.4c-0.6,1.4-1.5,2-3,1.9c-1.2-0.1-2.4-0.2-3.6-0.1c-2.1,0.2-3.2-0.4-2.8-2.6
				C199,1911.4,198.2,1910.6,197.1,1909.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M533.4,1657.3
				c-7.1-0.4-13.8,2.7-20.9,2.3c0.2-1.4,1.4-1.5,2.4-1.8c5.5-1.4,11.1-2.5,16.8-3C534.1,1654.5,533.9,1655.7,533.4,1657.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M654.4,1870.1
				c1.4-5.1-0.1-10.6-3.5-11.8c3.2-0.5,4.7-1.9,3.1-4.9l0.2,0.1l0.1-0.3c0.5,0.1,0.9,0.2,1.4,0.3l0.1,0.2l0.2-0.1
				c1.1,5.6,0,11.3,0.4,16.9c-0.3,1.4,0.6,3.2-1.4,3.9c-0.2-0.9-0.4-1.8-0.6-2.7C654.4,1871.3,654.4,1870.7,654.4,1870.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M450.1,1940
				c-2,1-1.1,2.8-1,4.2c0.1,1,0.9,2,0,3c-3.9-6.9,1.8-11.6,3.8-17.2c0.4-1.2,2.3-1.8,3.5-2.6c0.4-0.3,1-0.3,1.1,0.2
				c0.1,0.6,0.1,1.5-0.3,1.8c-1.4,1.3-2.5,2.9-3.6,4.5c-0.8,0.5-0.7,1.4-0.9,2.1c-0.9,0-1.4,0.4-1.6,1.3
				C450.2,1938,450.4,1939.1,450.1,1940z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M670.8,1913.2
				c7.6,0,15.2,0.2,22.8,0.1c2.2,0,2.3,1,2.1,2.6c-7.3-0.9-14.7-0.4-22.1-0.4C671.9,1915.5,670.4,1915.5,670.8,1913.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M383.4,1813.6
				c-0.2,0-0.4,0-0.6,0c-1.7-0.3-1,2.4-2,1.9c-1.9-0.9-1.5,0.9-2.2,1.3c-1.6,1.1-3.7,0.8-5.6,0.7c0-0.7,0-1.5-0.1-2.2
				c-0.3-2.9,1.1-3.9,4-3.6c2,0.2,4,0,6,0c0.2,0.3,0.4,0.6,0.6,0.8C383.4,1812.9,383.4,1813.2,383.4,1813.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M470.8,1897.9
				c-1.1,0.1-2.1,0.2-3.2,0.3c-1.3-1.8-0.4-4.4,2.2-6.3c1.5,0.1,3.1,0.1,4.5,0.5c1.4,0.4,3.4,0.2,3.8,2c0.4,1.7-1.8,1.6-2.3,2.8
				C474.1,1897,472.2,1896,470.8,1897.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M257.6,1832.8
				c-1.3,0-2.7,0.5-3.5-1c-0.6-1.2,0-2.2,0.9-2.8c1.3-0.8,2.7-1.4,4.3-0.5c0.6,0.4,1.3,2.1,2.1,0.3c0.5-1.1,0-2.1-1.2-2.6
				c-0.8-0.3-1.8-0.2-2.2-1.1c-0.3-0.6-0.1-1.3,0.4-1.8c0.6-0.5,1.1-0.3,1.8,0c2.7,1,4.1,2.9,3.7,5.7c-0.4,2.8-2.6,3.8-5.3,3.9
				C258.3,1832.9,257.9,1832.8,257.6,1832.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M592.9,1353.9
				c-9.6,1-18.9-0.5-28.3,2.9C573.6,1350.2,583.1,1353.1,592.9,1353.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M684.6,1885.6
				c2.7-0.7,5.3-0.4,8-0.1c3.3,0.4,2.5,2.6,2.2,4.2c-0.4,2.1-1.8-0.3-2.7,0.3c-0.3-1.8-1.5-2.3-3-2.2c-1.4,0-2.8,0.3-4.2,0.3
				c-1.3,0-3.1,0.4-3.1-1.4C681.7,1884.9,683.6,1886,684.6,1885.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M270.3,1784.7
				c0.4-2.1-1.2-5.4,0.4-6c2.2-0.8,3.7,2.3,5.5,3.8c1.6,1.3,3.5,2.5,4.8,4.7c-1.8,0.2-3.2,0.4-4.7,0.6
				C274.4,1786.4,273.6,1783.1,270.3,1784.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M606.4,1878.3
				c0.8-0.6-1.4-1.9,0-2.2c1.6-0.4,1.7,1.3,2.2,2.3c1,2,2.1,4,2.9,6.1c0.4,1,1.6,2.6-0.7,3c-1.8,0.3-4,0.8-4.3-1.9
				C606.2,1883.2,606.4,1880.8,606.4,1878.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M292.1,1740.3
				c4.5,1.7,4.7,5.6,4.5,9.4c-0.2,3-2.7,1.7-4.5,1.4C291.4,1747.5,290.9,1743.9,292.1,1740.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M978.5,1925.4
				c-2.5-1.6-2.1-3.8-2.6-5.8c-1,4.7,0.3,9.7-0.8,14.3c-0.8,0.1-1.1-0.2-1.1-0.7c-0.3-4.4-0.6-8.9-0.9-13.3c-0.2-3,1.9-2.6,3.7-2.8
				c2.4-0.2,1.9,1.5,1.9,2.9C978.5,1921.8,978.5,1923.6,978.5,1925.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M260.9,1612.2
				c-0.7,0.3-1.8,0.2-2.8-0.2c-1.9-0.8-1.7-2.5-0.9-3.8c1.1-1.8,7.9-0.7,8.8,1.2c0.5,1.2,0.2,2.2-1.2,2.4
				C263.7,1612,262.5,1612,260.9,1612.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M590.1,1469.2
				c-0.4,1.7-1.1,3.5-3.6,3.5c-2.3,0-4-1.9-3.8-4.3c0.2-1.9,1.2-3.3,3.2-3.6C587.7,1464.6,590.1,1467,590.1,1469.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M541.7,1848.2
				c-2.9,0.2-5.9,0.5-8.8,0.7c-5.1,0.3-10.2,0.6-15.4,0.9c-0.7,0-1.7,0.1-1.8-0.9c-0.1-1.1,0.9-1.1,1.6-1.1
				c7.8-0.2,15.7-0.5,23.5-0.7c0.3,0,0.6,0.2,0.8,0.4C541.7,1847.7,541.7,1847.9,541.7,1848.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1011.8,1921.5
				c-2.5,1.9-5.4,0.8-8.1,1.4c-1.9-0.2-3.8,0.5-6-0.8c4-1.9,7.7-3,11.8-3.2C1011.2,1918.8,1011.5,1920.2,1011.8,1921.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M342.7,1752.9
				c-0.8,0.3-1.6,0.2-2.2-0.5c1.2-5.3,0.2-10.5,0-15.7c-0.1-1.7-1.1-4.7,0.8-4.8c2.5-0.1,1.2,2.9,1.3,4.5
				C342.7,1741.8,342.7,1747.4,342.7,1752.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M406,1849.7
				c0-1.1,0-2.1-0.1-3.2c-0.2-2,0.6-2.8,2.6-2.6l0-0.1c0.2,0.7,0.7,0.8,1.4,0.7l0,0c0.9,1.6,2.5,1.8,4,2.2c1.5,2.4,0.7,3.6-2,3.6
				C409.8,1850.2,407.8,1850.7,406,1849.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M570.2,1288.4
				c4.3,1,8,2.9,10.5,6.6c0.5,0.7,1,1.7,0.4,2.4c-0.7,0.8-1.9,0.2-2.3-0.3c-2.4-2.8-6.9-3.2-8.5-7
				C570.2,1289.6,570.2,1289,570.2,1288.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M574.9,1266.3
				c-1.4-1.2-2.7-2.1-4.7-2.5c-2.5-0.5-2.7-3.7-4.6-5.1c0.2-0.4-1.2-1.2,0.2-1.3c3.1-0.2,8.9,3.7,9.8,6.7
				C575.8,1264.8,576.6,1266,574.9,1266.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.1,1726.7
				c0,1.7-2.5,2.5-1.8,4.7c0.2,0.5-2.2,0.6-3.4,0.8c-0.9-1.7-2.3-2.1-4.1-1.7c-0.9,0.2-1.8,0.3-2.3-0.7c-0.3-0.7-0.4-1.6,0.2-2.1
				c0.8-0.7,1.6,0.2,2.3,0.2C378.1,1727.8,381.1,1727.5,384.1,1726.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M393.3,1759.4
				c-1.6,1.6-4.4,0.8-5.8,2.9c-4.4,0.2-8.4,2.7-12.8,3.1c-0.8,0.1-1.5,0.3-2.2-0.6c2.2-1.5,4.7-1.6,7-1.8c4-0.3,6.7-3.5,10.6-3.9
				C391.3,1759,392.3,1758.6,393.3,1759.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M397,1849.7
				c0.6-3.5,4-4.8,6.1-7.1c0.6-0.7,1.7-0.2,1.5,1.1c-0.3,2.2,0.2,4.5-0.7,6.7C401.6,1850.1,399.3,1849.9,397,1849.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M577.4,1485.4
				c-1.7-0.6-2.6,0.1-2.2,1.9c0.4,1.9-0.8,2.2-2.1,2.6c-0.5,0.2-1.1,0.4-1.4-0.2c-0.2-0.3-0.2-0.9,0-1.2c2.1-3.4,2.6-7.1,2.3-11.1
				c-0.1-0.9-0.5-2.4,0.7-2.4c1.4-0.1,0.8,1.5,0.9,2.3c0.1,1.3,0.3,2.6,0.3,3.9c0.1,1.4,0.4,2.3,2,2.4c0.8,0,1.7,0.2,1.5,1.3
				C579.2,1485.9,578.3,1485.5,577.4,1485.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M935.4,1674.5
				c-0.7,0.8-1.7,0.7-2.5,0.5c-2.8-0.7-5.6-0.7-8.4-0.1c-1,0.2-2.5,0.6-2.8-0.8c-0.2-1.1,1.3-1.2,2.2-1.6
				C928.1,1670.9,932.2,1670,935.4,1674.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M128.4,2016.8
				c0.4,0,0.9-0.1,1.3-0.1c0.6,1.6,0.6,2.7-1.7,2.6c-1.2,0-2.3,0.3-3.5,0.6c-3.5,0.8-5.7-0.6-6.5-4.3c2.6,0.7,5.6-1.3,8.1,0.8
				c-0.3,0.7-0.1,1.2,0.7,1.4C127.5,2018,128.2,2017.8,128.4,2016.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M475,1886
				c0.9,0.3,1.9,0.4,2.8,0.8c1.5,0.7,0.8,2.1,0.8,3.2c0,1.3-1.1,1.1-1.9,1.1c-2.4,0.1-4.8-0.4-7.2,0.2
				C469.3,1887.4,471.5,1886,475,1886z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M417.8,1729.9
				c-0.1-4.2-1.2-8.2-2.5-12.2c-0.5-1.4-0.1-2.7-0.3-4.1c0.5-0.1,0.9-0.2,1.4-0.3c1.3,6,2.6,12,3.9,18
				C418.2,1733,418.7,1730.2,417.8,1729.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M158.6,1934.8
				c-3.7-0.1-7.4-0.1-11.1-0.2c-3.5-0.7-7.1-1.1-10.6,0c-0.6,0-1.2,0-1.9,0c2.1-2,4.9-1,7.3-1.3c4.9-0.8,9.9-0.8,14.8-1.1
				c0.2,0.9,0.3,1.8,1.4,2.1C158.5,1934.4,158.5,1934.6,158.6,1934.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M536.2,1932.4
				c-0.4,3.6,1.8,6.7,1.9,10.1c0,1.1,0.7,2.9-0.7,3.1c-1.6,0.3-1.4-1.6-1.6-2.7c-0.9-4.4-2-8.7-1.7-13.2c0.1-0.8,0-1.7,1-1.8
				c1.1-0.2,1.1,0.8,1.1,1.6C536.2,1930.4,536.2,1931.4,536.2,1932.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271.1,1819.9
				c1.2,0.2,2.2,0.9,3.1,1.7c3.1,2.4,6,5.1,10.5,5c-2.1,2.9-2,2.8-4.6,1.1c-3.2-2.1-5.8-5.2-9.8-6.2
				C270.4,1820.9,270.4,1820.2,271.1,1819.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M292,1725.9
				c1.3-0.5,1.7-1.8,2.3-2.9c0.6,0.3,1.3,0.6,1.4-0.4c0.1-0.7-0.6-0.7-1.2-0.5c0.1-0.4,0.2-0.8,0.3-1.3c0.7-0.1,1.4-0.2,2-0.3
				c0.8,1.8,2.6,3.7,2.2,5.4c-0.5,1.9-3.6,1.7-4.9,3.4C291.3,1729.6,292.4,1727.3,292,1725.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M272.5,1770.5
				c-0.4-4.4,1.6-8.4,2.1-12.6c0.2-1.5,0.7-2.9,0.5-4.4c0.7,0,1.4,0,2.2,0c-1.2,5.6-2.3,11.2-3.5,16.7
				C273.4,1770.3,273,1770.4,272.5,1770.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.9,1908.3
				c0.8,0.1,1.6,0.2,2.5,0.3c0.2,2.4,1.4,4.3,2.6,6.3c-0.4,1.1-1.1,1.8-2.2,2.3c-0.3,0-0.6,0-1,0c-0.2,0-0.5,0-0.7,0
				C960.1,1914.4,960.2,1911.4,960.9,1908.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M422.6,1953.1
				c-1.9,3-2.6,6.7-5,9.5c-0.9-0.5,0.4-3.8-2.5-2c-0.5,0.3-1-0.4-1.2-0.9c-0.2-0.5-0.1-1.2,0.4-1.4
				C417.6,1957.3,419.1,1953.6,422.6,1953.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M302,1714.7
				c-4.1,1.1-1.9-2.4-2.8-3.7c0.7-1.3,5.3-3.2,6.4-2.6c1.5,0.7,0.5,1.5,0,2.3C304.5,1712.2,302.6,1712.9,302,1714.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M959.9,1786.7
				c0.4,0.1,1.3,0.3,2.1,0.6c0.9,0.3,2.6-0.1,2.4,0.9c-0.3,1.2-1,3-2.9,3.1c-0.9,0.1-1.8,0.3-2.6,0.1c-1.3-0.3-3.1,1.8-3.8-0.7
				C954.8,1789.9,958.4,1786.7,959.9,1786.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964.2,1765
				c-1.8,2.7-4.7,3.3-7.6,3.6c-0.9,0.1-2.2,0.4-2.4-1c-0.2-1.2,0.4-1.8,1.7-2.3C960.4,1763.7,960.4,1763.6,964.2,1765z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M649.7,1888.9
				c-3.3-0.3-6.6,0.3-9.8,0.8c-0.5-0.9-0.9-1.9-1.4-2.8c4.1-1.2,8.4-0.3,12.5-1.1C650.9,1887,650.4,1888,649.7,1888.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M543.9,1928
				c0.1,6.3,0.3,12.7,0.4,19c-1.7,0-3.6,0.7-4.7-1.6c2.6-0.5,3.4-2,2.3-4.4c-0.3-0.7,0.1-1.2,0.2-1.9c0.4-3,1.6-5.9,0.7-9
				C542.4,1929.1,542.6,1928.2,543.9,1928z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M607.5,1923.8
				c-0.4-0.4-0.6-0.7,0.2-0.9c3,0.6,6-0.6,9-0.5c3.4,0.1,6.7,0.1,10,1C620.4,1924.5,613.9,1924.6,607.5,1923.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M694.5,1711.8
				c1.3-0.3,2.6-0.5,3.9-0.8c2.9-0.1,5.6-0.6,8.4-1.3c0.5,0.4,1.3,0.7,1.4,1.2c0.2,0.7-0.6,1.3-1.2,1.3c-4.3-0.1-8.3,2.1-12.6,1.2
				C694.5,1712.8,694.5,1712.3,694.5,1711.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M961.9,1785
				c-1.7-0.2-3.2,0.6-4.7,1c-0.9,0.2-1.8,0.5-2.3-0.5c-0.5-0.9,0.2-1.4,0.8-2.2c2.5-3.2,5.4-3.1,8.6-1.2c0,1.4-2.3,1.1-2.2,2.6
				L961.9,1785z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M582.5,1475.8
				c1,0,2.1,0.1,2.8,1.1c0.3,0.4,0.7,0.9,0.3,1.5c-0.4,0.6-0.9,0.4-1.4,0c-0.6-0.5-1.2-1.7-2.1-0.5c-1,1.4-0.8,3.1-0.2,4.7
				c0.3,0.8,0.9,1.6,2,1.1c0.8-0.4,1.8-1,2,0.5c0.1,1.1-0.9,1.7-2,1.7c-1.6,0.1-3.2-0.4-3.7-2c-0.7-2.2-0.6-4.5-0.1-6.7
				C580.4,1475.9,581.3,1475.6,582.5,1475.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M226.7,1663.2
				c0.1,1.9,0.2,3.8,0.3,5.7c-0.2,0.1-0.5,0.3-0.7,0.4c-1.9-3.2-5.6-3.7-7.9-6.1C221.2,1662.3,223.9,1663.8,226.7,1663.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M270.3,1784.7
				c3.3-1.6,4.1,1.7,6,3c-1.1,2-3.2,1.4-4.9,1.8c-1.7,0.3-3.4,1.2-5.4,0.6C269.1,1789.5,270,1787.4,270.3,1784.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M963.7,1771
				c-0.8,0.7-0.9,2.8-1.8,2.6c-2.3-0.6-4.2,1-6.3,0.9c-0.7-0.1-1.3-0.5-1.2-1.3c0.2-0.9,0.3-2.4,1.2-2.4
				C958.2,1770.7,960.8,1768.5,963.7,1771z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M899.5,2022.2
				c-3.9-1.2-7.8-1.5-11.7-0.1c-1.2-0.8-0.7-1.8-0.3-2.9c1.1-0.4,2.2-0.8,3.4-0.6c1.6,1.1,3.3,1.1,5,0.6
				C899,2018.1,899.8,2019.5,899.5,2022.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M570.8,1273.1
				c-1.1-1.3-1.5-3.3-0.6-4.1c1.3-1.2,2.5,0.9,3.9,1.5c0.7,1.3,1.7,2.2,2.9,3c0,0.1,0.1,0.3,0.2,0.4c-0.2,0.8,0.7,1.9-0.3,2.4
				c-0.9,0.4-1.6-0.5-2.2-1C573.5,1274.2,572.5,1273,570.8,1273.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M970.6,1867.4
				c-1.2-0.2-2.5-0.5-3.7-0.7c3-2.8,6.9-2,10.4-2.5c1-0.2,1.3,0.7,1.3,1.5c0,1.2-0.8,1.8-1.9,1.8
				C974.7,1867.5,972.6,1867.5,970.6,1867.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M980.2,1885.6
				c0.2-3.9-0.2-7.8-0.8-11.7c0.4-0.1,0.3-1.2,1.1-0.5c1.5,1.3,1.9,4.5,1.7,4.8c-1.7,2.5,1.1,4.9-0.2,7.2c-0.3,0.6,1,2.2-0.2,2.5
				C980.3,1888.3,980.9,1886.4,980.2,1885.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M359.2,1617.4
				c-0.8,2.5-3.2,3.1-5.2,4.2c-3.1-1.2-1.8-3.8-1.8-5.9c0-0.6,0.7-0.8,1.3-0.8c0.7,0,1.5,0.1,1.5,0.9
				C355,1619.9,357.5,1617.5,359.2,1617.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M374.8,1566.4
				c1.7-0.8,3.3-1,4.3,1c0.5,1,1.1,0.4,1.6,0.2c0.9-0.3,2-1.1,2.4,0.3c0.2,0.9-0.6,1.8-1.5,2.3c-0.9,0.5-2.6,0.7-2.6-0.1
				c0.2-3.5-1.5-1.1-2.5-1c-0.5,0.1-1-0.2-1.5-0.3C374.4,1568.1,374.2,1567.2,374.8,1566.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M439.4,1823.5
				c-0.9-3-1.5-6.1-2.6-9.1c-0.4-1.2,0.2-2.7,0.5-4.9c1,2.3,1.6,3.9,2.3,5.5C438.8,1817.8,440.2,1820.7,439.4,1823.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964,1776.6
				c-0.8,3.4-4.3,2.1-6.4,3.5c-0.8,0.5-2.2,0.8-2.8-0.6c-0.5-1.3,0.5-2.5,1.4-2.6C958.8,1776.6,961.2,1774,964,1776.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M310,1851.7
				c0.1,2.6,0.1,5.2,0.2,7.7c0,0.7,0,1.3-0.8,1.5c-0.7,0.2-1.2-0.3-1.2-0.8c0-3.2-1.5-6.3-0.2-9.5
				C308.6,1851,309.3,1851.3,310,1851.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M337.5,1772
				c0-1.4,0-2.3,0-3.3c-0.2-6.2-0.8-6.7-6.9-5.2c-0.4,0.1-0.8,0.1-1.5,0.2c1.3-1.3,7.5-3.1,8.7-2.6c0.7,0.3,0.8,0.9,0.9,1.6
				C338.9,1764.5,338.4,1769.2,337.5,1772z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M475.5,1909.1
				c6.8,0,13.6,0.1,20.4,0.1c0.1,0.8,1.1,0.5,1.5,1.2c-1.8,1.4-3.7,0.1-5.5,0.1c-4.9,0-9.9,0.6-14.9-0.4
				C476.4,1909.9,475.8,1909.8,475.5,1909.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M416.9,1964.2
				c-0.1,1.1-0.3,2.1-1.2,2.8c-1.4-0.5-3.4,0.6-4.1-1.1c-0.7-1.6-1.1-4,0.3-5.1c1.3-1.1,1.4,1.7,2.6,2.1
				C415.4,1963.1,415.7,1964.4,416.9,1964.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M540,1596.3
				c0.6-0.1,1.3-0.3,1.9-0.4c1.9,0.8,2.3,1.8,0.3,2.9c-1.7,1-3.6,1.4-5.5,1.9c-0.9,0.2-1.1-0.4-1.3-1.1c0.5-2,2.6-2.3,4-3.3
				C539.5,1596.4,539.7,1596.3,540,1596.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M293,1711.6
				c0.2,2.6,0.1,5.3,0.7,7.9c0.6,2.3-0.3,3.1-2.2,3.6c-0.3-3.1-0.8-6.1-0.7-9.2C290.7,1712.2,291.3,1711.4,293,1711.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M214.9,1931
				c0.3,1.6,0.6,3.1,1,4.7c0.2,1,0.2,1.7-1,1.9c-1,0.2-1.8,0.3-2.2-1.1c-0.6-1.9-0.9-3.7-0.2-5.6
				C213.2,1931.4,214.1,1931.3,214.9,1931z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M233.9,1747.7
				c6.9,0.4,13.9,0.8,21.8,1.3c-8,1.9-14.9,0.5-21.8-0.4C233.9,1748.3,233.9,1748,233.9,1747.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M366,1909.7
				c-5,0.5-10.1,1.1-15.1,1.6c4.7-3.4,9.9-2.6,15-2.5C366,1909.1,366,1909.4,366,1909.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M699.1,1736.6
				c-0.7-1.5,0.7-3.6-1.5-4c-1.4-0.3-0.9,1.4-1.8,2.2c-0.8-1.5-1.2-3.2,0.6-3.8c1.6-0.5,4.2-1.4,5.1,0.2
				C702.5,1733.1,701.1,1735.1,699.1,1736.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M438.1,1679.9
				c2.7,0,5.2,0,7.6,0c0.9,0,0.9,0.7,0.9,1.4c0,1.4-0.6,2.6-1.1,4c-1.5-1.2-0.5-3.8-3-4C441,1681.2,439.2,1682.2,438.1,1679.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M497.8,1968.3
				c3.7-0.4,7.5-0.8,11.2-1.2c0.1,1.5-1,2-2.2,2.4c-2.7,1-5.5-0.4-8.2,0.3C498,1970.1,498,1969,497.8,1968.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M755,1957.8
				c3.9-0.3,7.9-0.6,11.8-0.9c0,1.1-0.5,1.8-1.7,2c-2.7,0.5-5.3,0.8-8.1,0.7C755.7,1959.5,754.8,1959.3,755,1957.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M248.8,1739
				c0.2-3,0.6-5.9,0-8.8c-0.1-0.5,0.2-1.1,0.8-1.3c0.7-0.4,1.7,0.1,1.6,0.6c-0.4,2,3.6,3.1,1.8,3.9c-2.3,1.1-2,2.5-2.3,4.1
				C250.5,1738.1,250.2,1738.8,248.8,1739z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M761.7,1867.5
				c-3.5,1.6-7.1,2-10.8,1.3c0-0.5-0.1-1.2,0.6-1.3c3.2-0.4,6.4-0.7,9.6-0.9C761.2,1866.5,761.5,1867.1,761.7,1867.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M528.8,1910.9
				c4.3,0.6,8.7,0.3,13,0.9c2,0.3,2.1,0.9,1.5,2.4c-4.1-0.9-8.2-1.8-12.4-1.6C529.5,1912.7,528.7,1912.3,528.8,1910.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M332,1629.3
				c0.2,0.9,0.4,1.8,0.6,2.8c-3.2-2.9-6.2-5.8-8.4-9.5c1.6-1.5,1.9,0.3,2.6,1.1C328.4,1625.7,329.6,1628,332,1629.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M230.3,1933.8
				c0.1-1.8-0.4-3.8,2.3-3.9c1.4,0,1.6,0.9,1.5,2c-0.2,2-1,4.1-3.1,4.4C229.7,1936.4,230.5,1934.5,230.3,1933.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M359,1769.9
				c-0.6-3.1-1.2-6.2-1.7-9.4c-0.2-1.3,0.7-1.7,1.8-1.7c1.3,0,1.5,0.8,1.1,1.7C358.9,1763.6,359.9,1766.8,359,1769.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M509.6,1656.3
				c-0.6-0.2-1.8-0.1-0.9-1.3c0.6-0.7,1.3-1.3,1.7-2.3c2,1.1,4,0.9,6.1,0.3c1.6-0.5,3.2-0.4,4.8,0.7
				C517.5,1655.4,513.2,1654.1,509.6,1656.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M963.4,1960.8
				c0.8,2,3.5,2,4.2,4.1c-1.8-0.8-2.1,1.1-3,1.8c-1,0.8-1.9,1-1.9-0.6C962.8,1964.3,962.1,1962.4,963.4,1960.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M751.6,1862.8
				c0.4,0.9-0.2,1.4-0.6,2c-0.9,0-1.8,0-1.9,1.2c-0.1,1.7-1.7,1.9-2.6,1.7c-1.3-0.3-0.7-1.8-0.8-2.8c-0.2-1.2,0.2-2.2,1.7-2.1
				C748.8,1862.7,750.2,1862.7,751.6,1862.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M483.4,2011.3
				c-0.6-2.4-4-3.6-2.9-7c1,1.2,2.1,2.7,2.8-0.5C485,2006.6,484.3,2008.9,483.4,2011.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M342.1,1690.9
				c0.3-1.1-0.6-2.9,1.7-2.8c1.7,0,2.2,1,2.3,2.5c0,1.8-0.8,3.1-2.6,3.1C341.7,1693.6,342.2,1691.9,342.1,1690.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M405.1,1858.9
				c-0.4,2.9-0.7,5.8-1.1,8.7c-0.1,0.7,0.1,1.8-0.9,1.7c-1.2-0.1-0.9-1.3-0.8-2.1c0.1-2.8,0.4-5.5,0.6-8.3
				C403.6,1858.9,404.4,1858.9,405.1,1858.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M263.2,1789.7
				c-1.7,0.1-3.5,0.2-5.2,0.3c0.8-2.2,3.7-2.8,4.1-5.5c0.1-0.5,1.2-0.5,1.8-0.2c0.3,0.2,0.7,0.9,0.6,1
				C263.3,1786.6,264,1788.4,263.2,1789.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M295.1,1835.5
				c0.1,2.2,2.1,2.9,3.4,4c1.9,1.6,4,2.9,6.1,4.4c0,0.6,0.2,1.3-0.7,1.4c-3.3-2.2-6.1-5.1-9.7-6.8
				C293.8,1837.2,293.2,1835.9,295.1,1835.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M232.2,1726.5
				c-0.8,1-1.7,2-2.5,3c-0.4-0.3-0.9-0.6-1.3-0.9c-0.1-1.1-0.2-2.1-0.3-3.2c0.6-2,2.2-2.4,3.8-1.9
				C233.7,1724.1,232.4,1725.5,232.2,1726.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M680.6,1928.7
				c-3.1,0.8-6.1-0.9-9.2-0.5c-0.7,0.1-0.6-0.6-0.5-1.2C674.4,1926.4,678,1924.9,680.6,1928.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M306.7,1783
				c-0.1-1.8-0.2-3.6-0.5-5.3c-0.2-1.4,0.3-1.7,1.5-1.5c2.3,0.6,0.8,3,2,4.2c0.1,0.4,0.2,0.8,0.3,1.2
				C309.7,1783.7,307.9,1782.7,306.7,1783z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M452.8,1936
				c0.3-0.7,0.1-1.6,0.9-2.1c4,1.9,3.4,5.4,3,9.3C454.9,1940.8,455.5,1937.4,452.8,1936z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M138.7,2021
				c-1.1-0.3-3.2,0.9-3-1.1c0.3-2.6,3.1-1.4,4.8-2c0.8-0.3,2.1,0.1,1.7,1.5C141.7,2021.2,140.5,2021.7,138.7,2021z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M691.7,1925.8
				c-0.4,0-0.7,0-1.1,0c-0.5-0.3-1-0.3-1.5,0.1c-0.6-0.1-1.2-0.3-1.8-0.4c0.3-2.8,2.2-2.3,4-2c0.9,0.2,1.7,0.7,2.5,1.1
				c0.7,0.3,0.9,0.7,0.3,1.4C693.3,1925.7,692.5,1925.2,691.7,1925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M364.1,1614.3
				c-1,2-2.3,3.7-5,3.2c0.5-1.5-0.8-3.6,1.7-4.4C362.3,1612.6,363.4,1612.9,364.1,1614.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M409.2,1852.9
				c0.6,0,0.9,0,1.2,0c0.9,0.1,2.3-0.4,2.5,0.8c0.2,1.5-1.4,1.3-2.3,1.6c-0.3,0.1-0.7,0.1-1,0.1c-1.3-0.2-3.5,1.1-3.7-1.2
				C405.8,1851.8,408.3,1853.5,409.2,1852.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M417.7,1372.6
				c-3.5-1.3-1.4-4.7-2.6-6.9c-0.4-0.7,0-1.8,0.1-2.9c1.4,2.3,1.4,5,3.5,6.9C419.8,1370.6,416.6,1371.1,417.7,1372.6L417.7,1372.6z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M478.4,2012.6
				c-2.7-2.6-1.6-5.3-1.6-7.8c0-0.4,0.1-0.9,0.6-1c0.7-0.1,1,0.5,1,1C478.4,2007.2,478.4,2009.6,478.4,2012.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271.9,1667.2
				c-0.3,0.7-0.6,1.4-0.9,2.1c-0.4,0.7-0.9,1.3-1.8,1.2c-1-0.1-1.4-0.9-1.3-1.6c0.3-1.6-1.3-1.8-1.8-2.7
				C267.9,1667,270.4,1664.3,271.9,1667.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M191.4,1882.4
				c0.8-0.7,1.3-1.8,2.7-1.6c0.7,2.5,2.8,1.6,4.5,1.8l0,0c-0.3,0.5,0,1.6-0.8,1.6C195.6,1884.1,193.1,1884.7,191.4,1882.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M378.2,1714.8
				c0,1.2-0.1,2.4,0.2,3.5c0.6,2.9-0.5,4.2-3.4,3.7C375.8,1719.4,375.7,1716.5,378.2,1714.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M379,1421.8
				c-1.1,0.1-1.2,2.5-2.5,1.5c-0.6-0.5-3-1.9-0.1-3.2c1-0.4,1.8-1.5,2.7-2.3C381.7,1419.1,378.8,1420.5,379,1421.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M375.6,1599.8
				c4-2.3,6.1-2.2,7.8,0.4c-2.6-0.2-5,0.1-7.1,1.6C375.8,1601.2,375.6,1600.5,375.6,1599.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M568.2,1483.2
				c-2,0-3.3-1.4-4-2.9c-0.3-0.7,0.8-2,2.1-2.5c1.4-0.5,2.4-0.5,1.7,1.3C567.4,1480.6,568.1,1481.8,568.2,1483.2L568.2,1483.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M345,1860.7
				c-1.1-2.7-1.1-5.1-1.1-7.4c0-0.7,0-1.4,0.9-1.4c0.8-0.1,1.1,0.6,1.1,1.3C345.9,1855.5,346.6,1858,345,1860.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M310,1781.5
				c-0.1-0.4-0.2-0.8-0.3-1.2c2.7-1.3,5.8,0.2,8.6-1c0.4-0.2,1,0.2,0.9,0.7c-0.1,0.3-0.5,0.7-0.9,0.7
				C315.5,1781,312.8,1781.2,310,1781.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M399.2,1931.9
				c-4,0.6-3.2-1.9-2.7-4.2C400.3,1927.4,400.5,1927.7,399.2,1931.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M591.9,1881.2
				c1.2,0,2.4,0,4.5,0c-2.3,1.3-3.7,2.4-3.1,4.4c-0.6,0.9-1.2,2-1.6,0C591.3,1884.2,591.3,1882.7,591.9,1881.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M212.6,1800.6
				c-0.1,2.2-0.3,4.3-0.5,6.5c-0.1,0.6,0,1.5-1,1.3c-0.8-0.1-0.8-0.9-0.8-1.6c0.2-2.1,0.4-4.1,0.5-6.2
				C211.5,1800.6,212,1800.6,212.6,1800.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M963.7,1917.1
				c1-0.5,1.8-1.2,2.2-2.3c0.5,0.4,1,0.8,1.6,1.1c1.5,0.7,2.6,1.3,0.5,2.7c-0.8,0.5-1,1.7-1.8,3.2
				C966,1919.5,965.2,1918.1,963.7,1917.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M249.5,1711
				c0.2,0.8-0.1,1.7-0.9,1.6c-1.9-0.1-2-2.1-2.6-3.3c-0.4-0.9,0.6-0.9,1.2-1C249.3,1708,249.2,1709.7,249.5,1711z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M649.9,2009.3
				c0.1-0.8,0.1-1.7,1.1-1.7c0.9,0,1.4,0.8,1.4,1.5c0.1,1.5-0.2,3,0.4,4.5c0.2,0.4,0,0.9-0.6,0.9c-0.5,0-0.8-0.2-1.1-0.6
				C650.2,2012.5,650.7,2010.8,649.9,2009.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M391.3,1321.5
				c-1.8-0.2-3.6-0.3-5.4-0.5c-0.6-0.1-1.1-0.2-1-0.9c0.1-0.6,0.5-1.1,1.1-1c1.3,0.2,2.6-0.1,3.8-0.3c0.9-0.2,2.1-0.3,2.6,0.7
				C392.9,1320.3,391.3,1320.6,391.3,1321.5L391.3,1321.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M158.6,1934.2
				c-1.1-0.3-1.2-1.2-1.4-2.1c0.3-1.7,1.5-2,2.9-1.9c1.1,0.1,0.9,1,1,1.7C161.3,1934,159.9,1934,158.6,1934.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M580.9,1393.7
				c-1.1-0.8-3.2-0.4-3.5-2.2c-0.2-1.2,1-1.9,2.2-1.8c1.6,0.1,2.6,1,2.7,2.7C582.3,1393.2,582,1393.8,580.9,1393.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M414.4,1943.9
				c0.9,0.4,2.2-0.5,3,0.7c-0.3,0.7-0.2,1.4-0.1,2.2c0.2,1.8-1.5,1.4-2.4,1.7c-1.2,0.3-1-0.9-1.1-1.7
				C413.5,1945.6,414,1944.8,414.4,1943.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M445.4,1952.5
				c-0.2-0.8-0.3-1.7-0.5-2.5c0.4-0.8,0.4-1.7,0.1-2.5c-0.5-1.1-0.1-2,1.1-2.1c1.6-0.1,0.9,1.3,1.1,2.1c0.2,1-1.1,1.9,0,2.9
				c0.1,0.9,0.2,1.7-0.4,2.5C446.3,1952.8,445.9,1952.6,445.4,1952.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M243.4,1982.3
				c0.5-1.2-0.7-3.3,1-3.3c1.6,0,0.7,2.1,0.9,3.2c0.2,1.2,0.5,3.3-1,3.2C242.5,1985.3,244,1983.3,243.4,1982.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M415,1968.7
				c-0.9,2-1.8,4-2.7,6C410.8,1970.4,411.3,1969.1,415,1968.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M518.6,1865.7
				c2.5-1.1,5.9-1.6,9.3-1.4C525.4,1866.2,523,1866.6,518.6,1865.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M424,2000.9
				c-0.1,0.3-0.2,0.6-0.4,0.9c-0.9-0.8-3.2,0.5-3.1-1.3c0.1-1.5-0.2-3.8,2.4-4.3c0.7,1,1,2.2,0.9,3.4c-0.4,0.1-0.8,0.2-0.7,0.7
				C423.2,2000.8,423.6,2000.9,424,2000.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M602,1392.5
				c-2-1.6-3.6-3.4-0.2-5.3C602.9,1388.9,602.1,1390.7,602,1392.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M165.2,1933.5
				c-1,0.3-1.8,1.5-2.9,0.7c-0.6-0.4-0.3-1.2,0-1.8c1.1-1.7,2.7-1.9,4.5-1.4C166.9,1932.5,165.3,1932.5,165.2,1933.5L165.2,1933.5z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M626.7,1369.9
				c0,1.5,0.4,3.4-2,3.1c-1.2-0.1-2.6-1.3-2.4-2.6c0.2-2,1.7-0.2,2.5-0.5C625.4,1369.8,626.1,1370,626.7,1369.9L626.7,1369.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M223.2,1940.6
				c0-3.2,0-6.4-0.1-9.7c2.5,2,0.6,4.8,1.2,7.1C224.5,1939,224.8,1940.3,223.2,1940.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M467.6,2002.8
				c-0.7,1.1-1.9,1.8-3.2,1.7c-1.3-0.1-0.9-1.5-0.8-2.4c0.2-1.6,1.5-1.3,2.5-1.2C467.1,2001.1,467.6,2001.7,467.6,2002.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M471.3,1730.9
				c-0.4-2.2-0.5-4.1-3.2-3.7c1.4-1.2,3-1.8,4.1-0.9C473.5,1727.4,472.8,1729,471.3,1730.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M609.4,1401.2
				c-0.1,0.9-0.3,1.9-1.2,1.6c-0.8-0.2-1.9-0.7-1.6-2c0.1-0.7-0.2-1.8,0.9-1.7C608.8,1399.1,609.2,1400.2,609.4,1401.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M450.1,1940
				c0.3-0.9,0.1-2,1-2.7c1.8,1.5,3.3,3.2,3,5.8C453.1,1941.6,451.7,1940.6,450.1,1940z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.2,1925.8
				c0.6,2.3-0.9,3.5-2.5,5c-1-1.6-0.6-2.8,0.3-3.9C903.8,1926.5,904.5,1926.2,905.2,1925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M781.6,2009.2
				c1.8-0.5,3.7-1,4.9,0.9c0.4,0.5,0.4,1.4-0.3,1.7C783.7,2012.8,783.1,2010.1,781.6,2009.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M232.2,1823
				c-1.5-1.4-1.1-3-0.5-4.7c0.7,0.3,2.3-0.3,2,1.3C233.5,1820.8,234.7,1822.8,232.2,1823z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M403.6,1760.1
				c-0.2,0.7-0.4,1.4-0.8,2.1c-0.5,0.9-1,2.2-2.3,1.8c-1.8-0.7,0.5-1.6,0-2.6c-0.2-0.3,0-0.8,0.1-1.3
				C401.7,1760.1,402.7,1760.1,403.6,1760.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M957.1,1826.6
				c1.4,0.2,2.8,0.3,4.2,0.5c-1.5,1.2-2.8,2.1-4.5,2C957.2,1828.4,957.2,1827.5,957.1,1826.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M249.6,1752.5
				c1.1-0.1,1.8,0.8,2.7,0.3c0.9-0.4,1.9-0.6,2,0.6c0.1,0.9-0.8,1.4-1.8,1.5C250.8,1755,249.6,1754.7,249.6,1752.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M514.3,1608.9
				c-1.7,4.1-4.1,0.2-6.2,0.7C510,1608.2,512.2,1609.4,514.3,1608.9L514.3,1608.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809,1820.3
				c-2.1-1.8-0.4-2.6,1-3.5c1,0.7,2.4,1.4,1.8,2.9C811.4,1820.9,809.9,1819.9,809,1820.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M457.1,1920.1
				c1,0.5,2.4,0,3,1.5c-0.3,1.4-1,2.6-2.6,2C455.1,1922.7,457.1,1921.3,457.1,1920.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M539.6,1961.5
				c0.3,0,0.7-0.1,1,0c1.3,0.6,3.8,0.1,3.8,1.6c0,1.6-2.5,0.8-3.8,1.4C539.7,1963.8,540.1,1962.5,539.6,1961.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M694.9,1450.1
				c1.3,0.6,3,0.8,3.2,2.6c0.1,0.6-0.8,1-1.5,0.9C694.3,1453.3,695.3,1451.4,694.9,1450.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M842.3,1851
				c2.8,0.6,1.9,2.8,2.1,4.6C841.7,1855,841.4,1853.3,842.3,1851z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M374.8,1809.9
				c-0.8,0-1.6-0.2-1.6-1.2c0.1-1,0.8-1.5,1.8-1.5c0.7,0,1.7-0.2,1.5,1C376.5,1809.3,375.8,1809.7,374.8,1809.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M448.6,1666.1
				c1.3,0.9,1.3,2,0.7,3.3c-0.2,0-0.4-0.1-0.6-0.1c-1-0.4-2.6,0-2.5-1.8C446.2,1665.8,447.8,1666.7,448.6,1666.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M533.8,1480.5
				c-0.1,0.8-0.6,1.3-1.3,1.2c-1-0.2-1.8-0.9-1.6-2c0.1-0.8,0.7-1.4,1.6-1.2C533.6,1478.8,533.5,1479.8,533.8,1480.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M533.5,1467.3
				c0,0.9-0.4,1.4-1.3,1.4c-0.9,0.1-1.4-0.4-1.4-1.3c0-0.9,0.3-1.8,1.4-1.7C533,1465.8,533.4,1466.5,533.5,1467.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.9,1855
				c0.8,0.1,1.2,0.5,1,1.3c-0.2,1.1-1,1.3-2,1.2c-0.6-0.1-1.1-0.3-0.9-1C383.3,1855.6,383.9,1854.9,384.9,1855z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.4,1753.5
				c-0.1,1.8-0.2,3.6-0.3,5.4c-2.4-1.5-1.3-3.7-1-5.7C268.1,1752.8,269,1753.1,269.4,1753.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.1,1853.1
				c2.2-1,3.4-1.3,4.5-0.3c0.5,0.4,0.4,1.5-0.2,1.7C386.9,1855.1,386.1,1853.7,384.1,1853.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M434.2,1374.1
				c-0.4,1.1-1.4,1.2-2.4,1.1c-0.9-0.1-1.4-0.5-1.2-1.5c0.2-1,1-1.4,1.9-1.3C433.5,1372.5,434.1,1373.1,434.2,1374.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.8,1836.7
				c0.4,0,0.9,0,1.3,0.1c0.7,0.1,1.7-0.2,1.8,0.8c0,0.8-0.8,0.8-1.5,1c-0.4,0.1-0.7,0.5-1,0.7
				C384.1,1838.7,384.5,1837.7,384.8,1836.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M816.1,2001.6
				c-1.6-0.7-2.6-1.1-2.2-2.5c0.2-0.5,1.3-1.2,1.3-1.1C816.3,1998.9,815.2,2000.1,816.1,2001.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M233,1995.1
				c-0.1-1.4-1.1-2.8-0.1-4.6C233.7,1992.3,233.4,1993.7,233,1995.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M177.2,1882.6
				c1.1,0.2,2.5-1.7,3.4,0.7C179.3,1883.7,178.1,1884.3,177.2,1882.6l-0.1-0.1L177.2,1882.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M158.6,1985.2
				c1.7,2.4,1.4,3.3-1.4,4C157.1,1987.6,157.5,1986.3,158.6,1985.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M976.5,1772.1
				c2,0.1,4-1.2,6.1-0.4C980.6,1771.7,978.7,1773,976.5,1772.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271.9,1928.6
				c0.5,0,0.8,0.2,0.8,0.7c0,0.4,0.1,1-0.4,1c-0.3,0-0.7-0.4-0.9-0.7C271.3,1929.2,271.4,1928.8,271.9,1928.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.6,1924.7
				c1.4,0,2.8,0,3.2,0c-0.4,0-1.8,0.2-3.1,0.3C208.6,1924.9,208.6,1924.8,208.6,1924.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M413.7,1846.7
				c-1.5-0.4-3.2-0.6-4-2.2C411.5,1844.5,412.9,1845,413.7,1846.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M570.2,1531.6
				c-0.8-0.5-1.5-1-1.4-2.1C570.2,1529.7,569.8,1530.9,570.2,1531.6L570.2,1531.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.1,1759.7
				c0.6,1.3-0.2,2.6,0,3.8C267.4,1762.2,267.9,1761,269.1,1759.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M473.9,1909.6
				c-0.9,1-1.7,1-2.4,0.3C472.1,1909.1,472.9,1909.4,473.9,1909.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M385.4,1839.8
				c1,2.5-0.5,2.5-2.2,2.1C383.1,1840.4,383.8,1839.7,385.4,1839.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M290.6,1897.3
				c-0.3,0-0.7,0-0.6-0.4c0.1-0.4,0.5-0.7,0.7-0.9c0,0,0.4,0.2,0.4,0.3C291.3,1896.8,291.1,1897.1,290.6,1897.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M602.9,1395.2
				c-0.1-0.2-0.3-0.5-0.3-0.7c-0.1-0.3,0.2-0.7,0.5-0.6c0.2,0.1,0.4,0.4,0.4,0.7C603.6,1394.9,603.4,1395.2,602.9,1395.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M543.2,1914.8
				c0.1,1.4,0.2,2.9,0.3,4.3C541.7,1917.8,542.3,1916.3,543.2,1914.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M157.6,1925.1
				c0.5,0.1,0.8,0.3,0.6,0.7c-0.1,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.7-0.2-0.5-0.6C157.1,1925.3,157.5,1925.2,157.6,1925.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M738.3,1977.6
				c-1-1.6-1-2.5-0.1-3.5C739.5,1975.1,738.7,1976.1,738.3,1977.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M519.6,1612.2
				c-0.3,0.7-0.8,0.8-1.4,0.6c-0.2-0.1-0.4-0.6-0.4-0.7c0.3-0.3,0.6-0.6,1-0.7C519.3,1611.4,519.6,1611.8,519.6,1612.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M150.7,1979.2
				c-0.1,0.3-0.3,0.6-0.7,0.4c-0.4-0.2-0.7-0.6-0.5-1.1c0.1-0.2,0.5-0.5,0.7-0.4C150.7,1978.3,150.8,1978.7,150.7,1979.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M793.5,1872.3
				c0.2,0.1,0.4,0.1,0.5,0.2c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3-0.1-0.5-0.2C793.4,1872.6,793.4,1872.4,793.5,1872.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.7,1920.3
				c0,0.5,0,0.9,0,1.4c-0.6,0-1.2-0.2-1-0.9C694.7,1920.7,695.3,1920.5,695.7,1920.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M347.1,1489.9
				c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.5-0.3-0.7c0.2,0,0.4-0.2,0.5-0.1C347,1489.5,347,1489.7,347.1,1489.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M920.3,2006.2
				c0.3,0.3,0.6,0.5,0.6,0.7c0.1,0.7-0.5,0.5-0.8,0.5c-0.3,0-0.7-0.2-0.5-0.6C919.7,2006.6,920,2006.4,920.3,2006.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M568.2,1483.2
				c0.5,0.1,1,0.2,1.5,0.2c-0.1,0.1-0.1,0.2-0.2,0.3C568.9,1484,568.5,1483.7,568.2,1483.2L568.2,1483.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.1,1766.9
				c0,0.9,0,1.8,0,2.7C268.2,1768.7,268.1,1767.8,269.1,1766.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M409.8,1844.5
				c-0.6,0.1-1.1,0-1.4-0.7C409.1,1843.6,409.6,1843.6,409.8,1844.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M409.7,1841.8
				c0.4,0.1,0.8,0.1,1.2,0.2C410.5,1841.9,410.1,1841.9,409.7,1841.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M408.5,1841.8
				c0.1-0.2,0.2-0.7,0.3-0.7c0.4,0,0.8,0.2,0.9,0.7C409.4,1841.9,408.9,1841.9,408.5,1841.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.1,1713.6
				c-0.3-0.1-0.6-0.2-0.8-0.3c0.1-0.1,0.2-0.2,0.2-0.2C383.7,1713.2,383.9,1713.4,384.1,1713.6L384.1,1713.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M749.6,1818.7
				c-0.1-0.3-0.2-0.7-0.3-1C750,1817.8,750.4,1818,749.6,1818.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.1,1764.4
				c0,0.6,0,1.2,0,1.8C268.3,1765.6,268.3,1765,269.1,1764.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M768.2,1804.1
				c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.3-0.1-0.3-0.2,0-0.3C768.1,1804.1,768.2,1804.1,768.2,1804.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M586.3,1652.2
				c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0.1-0.3,0.2-0.3,0.3-0.1C586.4,1651.9,586.4,1652.1,586.3,1652.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M962,1917.1
				c0.2,0,0.5,0,0.7,0c-0.1,0.1-0.2,0.3-0.2,0.4C962.3,1917.4,962.2,1917.3,962,1917.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M568.9,1289.4
				c-0.1,0-0.2-0.1-0.3-0.1C568.7,1289.4,568.8,1289.4,568.9,1289.4L568.9,1289.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M580.7,1496.3
				c0-0.1,0.1-0.2,0.1-0.2C580.8,1496.1,580.7,1496.2,580.7,1496.3L580.7,1496.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M579.4,1655.9
				c-0.1,0.1-0.2,0.2-0.2,0.3c0-0.1,0-0.1,0-0.2C579.2,1656,579.3,1656,579.4,1655.9L579.4,1655.9z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				699.4,1738 699.5,1738.2 699.3,1738.2 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M893.4,2008.4
				c0.3,0.3,0.5,0.7,0,0.9c-0.1,0.1-0.5-0.3-0.7-0.4C893,2008.8,893.2,2008.6,893.4,2008.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M731.1,1509
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2C731,1508.8,731.1,1509,731.1,1509z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M269.1,1769.5
				c0.7,0.4,0.4,0.8,0,1.2C269.1,1770.3,269.1,1769.9,269.1,1769.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M962.1,1784.8
				c0.1,0.1,0.2,0.3,0.3,0.4c-0.1-0.1-0.3-0.2-0.4-0.2C961.9,1785,962.1,1784.8,962.1,1784.8z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				417.6,1372.6 417.8,1372.7 417.7,1372.6 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M344,1919.1
				c0.1-0.1,0.1-0.2,0.2-0.3C344.1,1918.9,344,1919,344,1919.1L344,1919.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M751.9,1858.2
				c0.3-0.3,0.7-0.4,1,0C752.6,1858.9,752.3,1858.2,751.9,1858.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M713.8,1084.7
				c-0.5-2.1-0.8-4.1,2.1-4.8c1.1-0.2,3.1-1.2,2.9-1.5c-1.8-1.5,0.7-4.4-1.8-5.2c-2.1-0.7-1.9-2.5-1.9-3.9c0.1-1.4,1.6-2,3.1-2.4
				c1-0.2,2.6-0.2,2.4-2c-0.2-0.5-0.4-1-0.6-1.5c0.1-0.1,0.1-0.2,0.1-0.4c0.8-0.8,1.2-1.5-0.2-2.1c-0.6-0.2-1.2-0.5-1.8-0.7
				c-0.6-1-1.4-1.9-1.3-3.1c0.5-1,1.1-2,2.2-2.2c3.6-0.6,3.7-3.3,2.9-5.7c-0.7-2.4-3.2-3.2-5.8-3.4c0.9-2.9,3.5-3.4,5.8-4.4
				c2.4-1,2.8-4.1,0.7-6.1c-2.2-1.3-3.4-3-1.8-5.6c1.7,0,2.9-1.4,4.5-1.8c1-0.2,0.6-4.6-0.4-5.6c-0.6-0.6-1.4-1-2.1-1.5
				c0-1.4,0-2.7,0-4.1c1-0.4,2-0.8,3-1.2c2-0.8,2.5-4.6,0.9-6.1c-0.6-0.6-1.3-1.1-2-1.7c-0.7-1.6,0.4-2.9,0.6-4.3
				c0.4-0.2,0.8-0.4,1.3-0.6c0.9-0.1,1.9,0.1,2.5-0.9c0-2.5,0.1-4.9-2.3-6.9c-0.9-0.8-0.6-3-0.9-4.6c2.1-0.5,5,0.1,5.5-2
				c0.5-2.2,0.2-5-2.8-6.2c-1.5-3.1,1.5-4.4,2.7-6.3c2.4-0.2,0.1-2.8,1.7-3.4c1.3,0,2.4,0.3,3,1.6c0.4,1,1,1,1.8,0.8
				c1-0.3,0.6-1.3,0.7-1.9c0.4-1.5-0.7-0.8-1.3-1c-1.2-0.3-2.4-0.7-3.7-1.1c-1.7,0.3-2.4-1.3-3.4-2.1c-0.6-0.5-0.7-1.3,0.1-1.6
				c2.1-0.7,1.9-3.9,4.6-4c0.7,0,0.7-0.9,0.5-1.6c-0.6-1.6,1-1.3,1.7-1.8c1.1,0.4,2.3,0.8,3.5,1.3c0.1-3.3,0.1-3.3-3.2-2.5
				c-0.1-1.6-1.1-2.2-2.4-1.7c-1.2,0.5-1.8,0.2-2.4-0.8c-0.7-1.2,0-1.7,0.8-2.6c2-2.2,3.7-4.7,5.5-7.2c0.4-0.6,0.3-1.3-0.2-1.8
				c-0.5-0.6-1.3-1-2-0.4c-1.6,1.2-1.8-0.4-2.4-1.1c-0.8-1-0.3-1.3,0.6-2.2c1.4-1.3,1.9-3.5,4.1-4c2-0.9,4.7-0.2,6.1-2.5
				c1.8,0.3,2.2-0.8,1.9-2.1c-0.3-1.5-1.2-0.6-2-0.2c-1.4-0.2-2.9,0.4-4.4-0.2c-0.5-1-1.5-0.8-2.3-0.6c-1.5,0.4-2-0.5-2.5-1.5
				c-0.6-1.2-0.2-1.7,1-2.1c0.7-0.2,0.7-1,0.9-1.6c0.7-1.9,2-2.8,4.2-2.9c1.9,0,4.4,0.8,5.2-2c0.7-2.7,0.6-5.4-2.6-6.8
				c-0.4-0.2-0.7-0.5-1.1-0.7c0.6-1.6,0.8-3-0.3-4.6c-1.3-1.9-0.6-3.6,1.6-4.1c3.3-0.7,6.6-1.4,10.2-1.1c3.1,0.3,6.6,0.3,9.4-1.8
				c1-0.8,1.7-1,2.8-0.3c1,0.6,2.3,1.1,3.4,1c6.1-0.5,12.1,1.1,18.2,1.2c3.8,0,5.1,1.9,6.7,4.6c1.3,2.3,0,4.9,1.3,7.1
				c-1.5,1.5-3.1,2.9-5.3,3.4c-3.3,0.7-4.2,3.3-4.7,6.2c-0.3,1.5,0.5,2,1.7,2.7c1.2,0.7,3.3,0.2,3.5,2.4c-0.8,1-1.6,0.1-2.3,0
				c-0.9-0.2-1.8-0.6-2.1,0.7c-0.2,1,0.3,1.7,1.3,1.9c0.7,0.2,1.5,0.4,2.2,0.5c0.9,0.2,2.2,1.1,2.7,0.2c0.9-1.5-1.1-1.4-1.7-2.1
				c-0.3-0.4-0.3-0.7,0.1-1.1c2.5-0.1,3.6,1.5,4.2,3.4c0.6,2-1,3.1-2.5,4c-3,1.7-3.3,4.4-0.6,6.5c0.4,0.3,0.9,0.6,0.7,1.3
				c-1.8-0.2-3.1-1.6-4.7-2.1c-0.9-0.3-2.2-1.2-2.8,0.3c-0.5,1.4,0.6,2.2,1.9,2.5c1.4,0.4,2.8,0.6,4.1,1c2.1,0.7,1.4-0.8,1.5-1.8
				c2.2,0.9,1.3,2.4,0.5,3.7c-0.4,0.7-1.3,1.1-2,1.6c-2.9,2.2-3,4.3-0.2,6.6c0.4,0.3,0.7,0.8,1,1.2c-2.1,0.2-3.3-1.5-5-2.1
				c-1.1-0.4-2.3-0.8-2.9,0.5c-0.7,1.5,0.6,1.8,1.7,2.2c1.4,0.4,2.8,0.6,4.1,1.2c1.9,0.8,1.9-0.5,2.1-1.7c1.9,2,1.4,3.5-0.9,4.6
				c-1.5,0.7-2.3,1.7-2.8,3.3c-0.5,1.7-1.1,3,1.3,3.7c0.7,0.2,1.9,1,0.7,2.1c-1.5-1.5-3.3-2.5-5.4-2.7c-0.8-0.1-1.6,0-1.9,0.9
				c-0.3,0.9,0.3,1.5,1,1.8c1.7,0.6,3.5,1.2,5.3,1.6c1.6,0.3,1.7-0.5,0.9-1.6c1.7,0.3,2.3,1.2,1.5,2.6c-0.8,1.4-2.2,2-3.6,2.8
				c-1.6,0.8-2.3-0.9-3.5-1.2c-0.8-0.2-1.7-1-2.3,0.2c-0.5,1,0.4,1.6,1.2,2c1.2,0.6,3,0.4,3,2.4c-0.4,1.9,3.3,1.8,2.1,3.3
				c-1.3,1.7-2.5-1.1-3.9-1.3c-1.1-0.2-2.6-0.7-2.9,0.3c-0.3,1.1,0.9,2.2,2.2,2.1c1.7-0.2,3.4,1.7,5.2,0.1c0.4-0.4,1.1-0.1,1.3,0.5
				c0.5,1.2-0.1,2.1-1,2.8c-1.2,0.9-2.7,1.3-3.3,3.2c-0.6,2-1,3.1,1.2,4.1c0.5,0.2,1.5,0.7,0.8,1.7c-2-0.5-3.5-2.2-5.6-2.4
				c-0.7,0-1.3-0.1-1.7,0.6c-0.3,0.6-0.3,1.5,0.4,1.7c1.9,0.7,3.6,1.8,5.7,1.9c1.3,0,1.7-0.7,1.2-1.9c2.5,1.4,1.6,2.8,0,4.2
				c-0.9,0.8-2.3,0.8-2.8,2.1c-0.8,2.2-1.6,4,1.5,5.6c2.7,1.4,2.2,4.1-0.4,5.7c-3.7,2.3-3.9,4.8-0.8,7.4c2.5,2.1,2.3,3.6-0.8,5.8
				c-1.8,0.4-3.5,0.9-5.2-0.3c-0.5-0.4-1.6-0.5-1.8,0.6c-0.2,0.9,0.3,1.6,1.1,1.6c2.6,0,2.4,2.2,3,3.7c-0.3,0.3-0.6,0.6-1,0.9
				c-0.3,0-0.6-0.1-0.9-0.1c-0.9-0.2-2.2-0.6-2.5,0.6c-0.3,1.2,1,1.3,1.9,1.6c1.3,0.4,2.6,0.9,4,1.3c1.6,0.8,2.6,1.6,0.2,2.7
				c-1.1,0.5-2.2,1.4-3.5,0.8c-0.4-0.2-0.7-0.5-1.1-0.6c-0.7-0.2-1.6-0.9-2,0c-0.5,0.9,0.1,1.7,1.1,2c0.5,0.2,1.1,0.2,1.6,0.4
				c2.1,0.3-0.3,2.9,1.6,3.3c0.4,0.2,0.7,0.4,1.1,0.6c0.1,0.9-0.3,1.2-1.2,0.9c-0.9-0.3-1.9-0.6-2.8-0.7c-0.9-0.1-1.9-0.1-2,1.1
				c-0.1,0.9,0.6,1.3,1.4,1.5c1.5,0.4,3,1,4.6,1.3c1.9,0.4,1.7,1.3,0.9,2.5c-1.8,0.8-3.5,2-5.5,0.2c-0.4-0.4-1.6-0.7-1.9,0.3
				c-0.3,0.9,0.4,1.5,1.2,1.6c3.2,0.6,3.2,4.1,5.2,5.8c-1.9,0.3-3.3-1.4-5.2-1.4c-0.9,0-2.1,0.1-1.5,1.3c1,1.8,3.2,1.5,4.9,2
				c1.8,0.5,1.4-1,1.8-1.8c1.9,1.2,2.2,3.4,0.6,4.5c-0.8,0.5-1.7,0.9-2.6,1.3c-1.5,0.8-2.4-0.7-3.6-0.9c-0.7-0.1-1.6-0.6-2,0.2
				c-0.5,1,0.2,1.8,1.1,1.9c2,0.3,2.7,1.3,2.6,3.2c0,0.2,0.8,0.5,1.3,0.8c0.3,0.2,0.7,0.4,1,0.5c-1.3,2-2.5-0.1-3.8-0.1
				c-0.9,0-2.1-0.7-2.5,0.7c-0.3,1.2,0.7,1.7,1.7,2c1.3,0.4,2.7,0.9,4,1.2c1.5,0.4,1.7,1.1,0.8,2.3l0,0c-1.6,0.1-3,1.7-4.8,0.4
				c-0.7-0.5-2-1.1-2.4,0.2c-0.4,1.2,0.6,1.9,1.7,2.3c0.9,0.3,2.4,0.2,1.9,1.9c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.5-0.4,0.7-0.9,0.7
				c-1,0.1-2.8-0.9-2.9,0.9c-0.2,1.8,1.8,1.5,2.9,1.9c1.8,0.6,3.9,0.3,5.1,2.2c-0.5,0.3-1,0.7-1.5,1c-1.9,0.4-3.8,1.4-5.8,0.1
				c-0.4-0.2-1.1-0.1-1.4,0.4c-0.3,0.6,0,1.3,0.5,1.4c2.4,0.4,3.3,1.8,3.5,4.1c0,0.2,0.7,0.3,1.1,0.5c0.6,0.4,1.7,0.6,1.3,1.5
				c-0.3,0.9-1.1,0.1-1.7-0.1c-1.1-0.3-2.3-0.8-3.4-1.1c-0.6-0.2-1.4-0.4-1.7,0.5c-0.3,0.9,0.3,1.5,1.1,1.7c1.8,0.6,3.5,1,5.3,1.5
				c1.5,0.4,1.5,1.2,0.3,1.9c-1.7,0.9-3.4,2.7-5.6,0.7c-0.5-0.4-1.5-0.9-1.9,0.3c-0.3,0.7,0.3,1.3,0.9,1.6c1.3,0.7,4,0.2,2.5,3.2
				c-0.2,0.5,1.3,0.9,2.1,1.3c-1.1,1.1-2.2,0.3-3.3,0c-1.1-0.2-2.3-0.7-2.6,0.8c-0.3,1.3,0.8,1.8,1.9,2.1c1.9,0.4,3.8,0.7,5.4,1.7
				c-0.3,1.5-1.8,0.9-2.2,1.9l-0.1,0.1c-1.5,1.2-2.6-0.3-3.9-0.5c-0.6-0.1-1.4-0.3-1.7,0.4c-0.4,0.8-0.1,1.9,0.6,1.9
				c2.3,0.2,2.5,2.1,3.3,3.5c0.5,0.4,1.3,0.6,1,1.4c-0.2,0.6-0.9,0.5-1.4,0.3c-0.5-0.2-1-0.4-1.5-0.5c-0.8-0.2-1.9-0.7-2.2,0.4
				c-0.3,1.1,0.6,1.7,1.7,2c2,0.5,4.3,0.6,5.8,2.5c-1.2,0.7-2.9,0-3.3,1.8c-1.2,0.5-2-0.6-3-0.7c-0.8-0.1-1.8-0.6-2.1,0.6
				c-0.2,0.9,0.4,1.6,1.3,1.9c1.2,0.4,2.4,0.9,3.6,1.3c-1.5,2.1,0.7,2.8,1.4,4.4c-1.5-0.5-2.9-1-4.4-1.5c-0.7-0.2-1.6-0.6-2,0.3
				c-0.4,1,0.3,1.6,1.2,1.9c2,0.6,4.1,1.2,6.4,1.9c-0.9,1.4-2.7,0.9-3.3,2.1c-1,1-2,0.3-2.9-0.1c-0.9-0.3-2-1-2.5,0.4
				c-0.3,1.1,0.5,1.7,1.6,2c1.1,0.3,1.9,0.5,1.6,2.1c-0.3,1.4-0.6,2.2-2.2,1.9c-0.9-0.2-1.5,0.2-1.4,1.2c0,0.9,0.4,1.2,1.3,1.4
				c2.1,0.5,4.4,0.8,6.2,2.3c-2.1,0.6-3.8,2.9-6.4,1.4c-0.5-0.3-1.4-0.3-1.5,0.6c-0.1,0.7,0.4,1.2,1,1.4c0.8,0.2,1.7,0.4,2.6,0.6
				c-0.2,1.2-1.7,2.4-0.1,3.5c-0.2,0.3-0.4,0.5-0.7,0.8c-0.5-0.1-1.1-0.3-1.6-0.3c-0.6-0.1-1.4-0.4-1.7,0.5
				c-0.3,0.9,0.3,1.4,1.1,1.7c1.3,0.4,2.5,0.7,3.8,1.2c0.7,0.3,2,0.2,2,1.3c0,0.9-1.1,0.9-1.7,1.3c-1.2,0.9-2.2,1.5-3.7,0.6
				c-0.6-0.4-1.8-0.8-2.2,0.4c-0.2,0.9,0.7,1.2,1.4,1.5c0.7,0.2,1.4,0.4,2.6,0.8c-2.5,1.5-1.4,2.6,0.1,3.6c-1.1,1.2-2,0.2-2.9,0.1
				c-0.7,0-1.4-0.1-1.6,0.7c-0.1,0.7,0.4,1.2,1.1,1.4c2.1,0.6,4.2,1.1,6.6,2.5c-2.6,0.7-4.1,3.4-6.8,1.5c-0.5-0.3-1.3-0.3-1.5,0.6
				c-0.1,0.6,0.1,1.1,0.7,1.3c0.9,0.4,1.8,0.6,3,1c-4.5,3.2,1.3,2.4,1.3,4.2c-1.1,0.8-2.1-0.2-3.1-0.4c-0.8-0.1-1.9-0.8-2.3,0.4
				c-0.4,1.3,0.7,1.6,1.7,1.9c1.7,0.5,3.5,1,5.4,2.3c-2.3,0.6-3.7,3-6.2,1.5c-0.6-0.3-1.4-0.4-1.6,0.4c-0.3,0.9,0.4,1.3,1.1,1.7
				c0.7,0.4,2.7,0.3,1.9,1.1c-1,1,0.8,3.9-2.3,3.2c-0.6-0.1-1.1,0.2-1.1,0.9c0,0.9,0.4,1.4,1.3,1.6c1.8,0.5,3.5,1.1,6.1,1.9
				c-2.7,0.8-4,3-6.5,1.8c-0.8-0.4-1.9,0.7-1.2,1.1c2.4,1.3,0.9,5.4,4.4,5.6c-0.6,1.5-1.4,0.5-2,0.5c-1,0-2.4-1.2-2.8,0.4
				c-0.3,1.5,1.5,1.2,2.4,1.7c1.2,0.6,2.8,0.4,4.2,2c-2.6-0.1-3.5,2.7-6,1.9c-0.8-0.2-1.6,0.3-1,1.3c1,1.6,0.1,4.2,2.7,4.8
				c-0.5,0.9-1.1,0.6-1.6,0.5c-0.8-0.1-1.7-0.3-1.8,0.8c-0.1,0.8,0.6,1,1.3,1.2c0.3,0.1,0.7,0.1,0.9,0.3c1,0.6,3.1,0.5,2.8,1.6
				c-0.4,1.2-2.2,2.1-3.8,2.2c-0.8,0.1-2.5,0-2,0.5c1.2,1.4-1.1,3.5,1.2,4.3c1.5,0.5,3,1.2,4.4,1.8c0,0.2,0,0.5,0,0.7
				c-1.3,0-2.5,0.3-3.8-0.5c-0.6-0.4-1.9-0.7-2.2,0.4c-0.3,1,0.8,1.4,1.6,1.6c1.1,0.4,2.6,0.3,3.2,1.7c-0.3,0.2-0.4,0.4-0.5,0.5
				c-0.3,0.1-0.8,0-0.9,0.2c-0.9,1.5-3.6,0.2-4,3c-0.5,3.5,1.8,3.5,3.9,4.1c0.6,0.4,1.2,0.9,1.8,1.3c-1.2,1.2-2.3,0.5-3.5-0.1
				c-0.8-0.4-1.7-0.8-2.5-0.2c-0.9,0.7-0.4,1.9-0.1,2.7c0.9,2.9,0.6,3.5-2.6,4c0.4,0.3,0.8,0.6,1.2,0.8c-0.8,3.2,2,3,3.7,3.7
				c0.8,0.8,1.8,1.4,2,2.7c-1-0.2-2.2-0.3-3.1-0.8c-0.8-0.4-1.8-1-2.3-0.3c-0.5,0.8-0.5,2,0.4,2.9c1.6,1.5,1.3,2.5-0.9,3
				c-0.7,0.2-1.3,1-2,1.5c1.5,0.5,0.1,3.4,2.7,3.1c0.4-0.1,0.8,0.2,1.2,0.3c1,0.8,2.1,1.5,2.5,2.9c-1-0.3-2.1-0.5-3.1-0.8
				c-1.2-1.1-2.4-0.2-3.6,0.1c0.3,0.8,0.3,1.9,0.9,2.4c1.7,1.6,0.4,2.7-0.3,4.1c-1,1.8-1.2,3.5,1.6,4.1c1.5,0.3,2.6,1.5,3.9,2.4
				c-1.2,0.6-2.3,0.1-3.5-0.3c-2-0.7-2.2,0.5-2.1,2c0.2,2.4,2,0.8,2.9,1.2c-1.9,0.2-1.8,1.9-2.3,3.1c-0.5,0-0.8,0.1-0.7,0.7
				c-0.6,0.1-1.1,0.3-1.7,0.4c-0.2-0.1-0.6-0.4-0.7-0.3c-0.5,0.3-0.2,0.6,0.1,0.9c0.6,2.5,2.9,2.7,4.7,3.7c1.8,1,3.2,2.1,1.2,4.2
				c-0.7,0.2-1.5,0.3-2.2,0.5c-2.1,0-1.3,1.3-1.1,2.3c-12.7-2.3-25.5-4.3-37.6-9.2c-0.2-0.4-0.5-0.5-0.9-0.3l-0.1,0
				c-0.1-0.3-0.2-0.5-0.4-0.1c-1.3-0.4-2.6-0.8-3.9-1.1c-0.3-0.1-0.5-0.2-0.7-0.2c-0.6-0.1-1.3-0.3-1.9-0.4
				c-5.5-1.9-11.2-3.3-17.1-3.9c-4.3-0.4-8.9-1-11-5.8l0.2-0.1c0,0,0.2,0.1,0.2,0.1l0.1-0.1c2.1-0.1,4-0.1,2.8-3.3
				c-0.6-1.5-0.3-3.5,2.6-3.1c1.2,0.2,2.6-0.2,3.8-0.6c1.2-0.4,1.6-1.5,1.5-2.6c-0.1-1.2-1-1.2-2-1c-0.5,0.1-1.2,0.2-1.2-0.5
				c0-0.5,0.3-1,0.7-1.4c0.4-0.3,1-0.4,1.5-0.7c1.3-0.8,1.7-2.1,1.4-3.3c-0.3-1.3-1.7-1.1-2.7-1c-1.2,0.1-2.4,0.2-4,0.3
				c1.7-1.8,3.7-1.7,5.5-2.2c2,0.4,2.1-1.1,2.6-2.4l0.1,0c0.6-0.7,1.9,0.3,2.4-1c-0.9-0.8-2.2,0.3-3-0.7l0-0.1
				c-0.8-1.1-0.2-1.7,0.5-2.6c1.4-2,1.2-3.1-0.4-4c-1.1,0-2.1,0.1-3.2,0.1c-0.4-0.4-2,0.4-1.1-1.2c0.3-0.1,0.7-0.1,1-0.2
				c1,0.2,1.9-0.2,2.7-0.6c2.4,0.2,2.3-2.1,2.7-3.2c0.5-1.7-1.2-1.6-2.4-1.5c-0.2,0-0.9,0.1-0.7-0.3c1.3-2.5-1.7-1.9-2.3-3.3
				c1.4-0.8,2.9-0.4,4.3-0.4c0.8,0,1.9,0.6,2-0.7c0.1-1.4-1.1-1.5-2.1-1.4c-1.7,0.1-3.4,0.3-5.7,0.5c1.5-1.7,3.2-1.7,4.7-2.1
				c0.9-0.2,2.2,0.1,2.6-0.9c0.5-1.2-1.5-1-1.7-2.2c0.8,0.1,1.4,0.3,2,0.4c0.6,0.1,1.1-0.3,1.2-0.9c0.1-0.6-0.3-1.3-0.8-1.2
				c-3,0.6-3.2-3-6.2-3.3c2.4-1.2,4.2-1,5.9-0.8c0.7,0.1,1.5,0.7,1.8-0.5c0.2-1.2-0.7-1.4-1.6-1.5c-1.9-0.2-3.9-0.2-6.2,0.8
				c0.8-2,2.2-2.2,3.3-2.3c3.3-0.2,4.2-1.2,1.8-3.9c0.8,0.3,1.6,0.5,2.4,0.9c0.7,0.3,1.3,0.3,1.5-0.5c0.2-0.7-0.1-1.4-0.8-1.5
				c-1.2-0.3-2.7-0.9-3.1,1.2c-0.2-1.8-0.2-3.5-2.5-4.5c-1.7-0.8-1.1-3.1,0.9-3.8c1.3-0.5,2.7-0.7,4.1-1.1c1.5,0.2,0.7-1.9,2.4-2.1
				c2-0.3,2-1.8,0.1-2.7c-1.3-0.6-2-1.3-0.6-2.2c1.2-0.9,3.1-1.3,2.3-3.2c-0.8-1.6-2.6-0.4-3.9-0.6c-0.5-0.1-1.2,0.1-1.6-0.1
				c-0.9-0.4,0-0.8,0.2-1.2l0,0c0.4-0.1,0.9-0.2,1.3-0.2c0.5-0.2,1-0.3,1.5-0.5c-1.5-1.9-0.9-4.5-2.4-6.4c-2.5-3.2-4.4-1.8-6.8,0.4
				c-9.9,9.3-19.8,18.7-30,27.6c-5.4,4.8-10.9,9.6-16.3,14.4c-19.4,17.4-40.2,33.1-60.6,49.3c-1,0.8-1.8,2-3.9,1.6
				c2.2-1.8,4.1-3.4,6-5c14.5-11.6,29.5-22.8,43.5-35c21-18.2,41.6-37,61.6-56.3c2-1.9,3.9-3.8,5.8-5.6c0.9,0,2.4-0.7,1.7,1.1
				c-0.7,1.9,0.4,1.8,1.6,1.6c1.8-0.4,3-1.9,4.6-2.8c0.9-0.5,0.4-2.6-1.2-2.5c-2.7,0.2-2.8-1.6-3.4-3.3c1.4-0.5,2.7-0.8,4-0.3
				c0.7,0.3,1.5,0.9,1.9-0.2c0.2-0.6,0.3-1.3,0.1-1.9c-0.3-0.9-1.2-0.8-1.9-0.7c-1.7,0.2-3.4,0.7-5.5-0.2c1-0.5,1.6-0.7,2.2-1
				c1.1-0.3,3.2,0,1-2c-0.4-0.3-0.8-1.1-0.6-1.4c1.1-2.4-0.4-3.4-2.2-4.4c-1.4-0.8-0.6-2.2-0.6-3.3c-0.1-1.2,1.1-1.1,1.9-1.2
				c1-0.2,1.9-0.2,2.9-0.4c0.5-0.1,1.4-0.6,1.3-0.7c-2.4-1.5,0.5-5.2-2.7-6.3c-0.7-0.8-1.3-1.7-0.1-2.6c1.9,1.7,4.1,0.2,6.1,0.5
				c0.7,0.1,1.5,0.7,1.7-0.6c0.1-0.9-0.5-1.4-1.4-1.4c-1.9-0.2-3.9-0.2-5.7,0.4c-0.5-0.1-1.7,0.1-0.6-0.9c0.7-0.6,1.7-0.8,2.5-1.1
				c0.4,0,0.8-0.1,1.2-0.1c0.7,0,1.3-0.2,1.1-1c-0.1-0.6-0.5-1.1-1.3-0.9c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.4,0-0.9,0-1.3l0,0
				c1.1-1.2,1-2.1-0.7-2.6c-0.9-0.7-2-1.2-2.1-2.5c2.6,0.1,5.2,0.1,7.7,0.4c1,0.1,1.7,0.3,1.8-0.9c0.1-1-0.5-1.6-1.5-1.6
				c-2.1,0-4.1,0-6.2,0.3c-1.8,0.2-0.1-0.8-0.3-1.2c1.1-0.2,2.2-0.3,3.2-0.6c0.4-0.1,0.6-0.5,1-0.7c-2-1.5,0.4-4.5-2-5.9
				c-1.2-0.7-2.3-1.3-1.9-3c0.4-1.5,1.5-2.1,2.9-2.7c0.9-0.4,3.1-0.6,2.7-1c-1.8-1.7,0.9-4.7-2-5.9c-1.3-0.5-2-1.7-1.8-3.2
				c0.2-1.4,1.4-1.7,2.4-2.4c1.1-0.7,4.5,0.4,2.6-3.1c-0.7-1.3,0.6-3.4-1.8-4.3c-1.3-0.5-1.6-1.6-1.3-3c0.3-1.5,1.1-2.2,2.6-2.5
				c1-0.2,3-0.7,2.8-1.1c-1.2-1.8,0.6-4.1-1.3-5.6c-3.3-2.7-3.1-4.3,0.7-6.6c1.1,0.4,2.7,0.6,2.8-0.6c0.2-4.5,2.7-2.1,4.6-1.8
				c0.3-1.4-0.4-2-1.6-2.7C718.3,1086,715.7,1086.4,713.8,1084.7z M727.1,1056.9c0-1.1-0.4-1.7-1.5-1.7c-1.7-0.1-3.5-0.1-5.2,0.4
				c-0.7,0.2-1.7,0.4-1.6,1.3c0.1,1.1,1.2,0.7,1.8,0.7c1.8,0.1,3.5-1,5.3,0.6C726.8,1058.9,726.9,1057.6,727.1,1056.9z
				 M719.1,1083.2c0.1,0,1.3-0.5,2.3,0.2c0.8,0.5,1.5,1.1,1.9-0.3c0.3-1.1,0-1.9-1.3-2.2c-2.1-0.5-4.1-0.2-6.1,0.6
				c-0.5,0.2-1,0.6-0.8,1.2c0.1,0.5,0.6,0.6,1,0.6C716.8,1083.2,717.5,1083.2,719.1,1083.2z M725.6,1042c-1.2,0.3-2.5,0.5-3.9,0.9
				c-0.6,0.2-1.3,0.5-1,1.4c0.2,0.7,0.8,0.6,1.3,0.6c1.9,0,3.8-1,5.7,0.4c0.4,0.3,0.8,0,1-0.4c0.3-0.6,0.2-1.3-0.2-1.8
				C727.9,1042.2,726.8,1042.2,725.6,1042z M782.2,1022.2c-1.4-0.2-2.3-0.4-2.6,0.6c-0.3,1,0.3,1.7,1.2,2c1.6,0.5,3.2,0.8,4.7,1.2
				c0.6,0.2,1.3,0.6,1.6-0.3c0.3-0.7-0.2-1.3-0.8-1.6C784.9,1023.2,783.3,1022.3,782.2,1022.2z M782.4,1009.2
				c-0.8,0.1-1.9,0-2.1,0.9c-0.1,0.4,0.8,1.3,1.4,1.5c1.3,0.6,2.7,1,4.1,1.3c0.7,0.2,1.7,0.4,2-0.4c0.5-1.1-0.6-1.4-1.3-1.8
				C785.3,1010,783.9,1009.4,782.4,1009.2z M748,926.3c0-0.8-0.5-1.5-1.1-1.3c-2.3,0.7-4.8,0.1-7,1.2c-0.4,0.2-0.9,0.5-0.6,0.9
				c0.1,0.3,0.6,0.6,1,0.6c1.9,0.1,3.9-0.2,5.8,0.4C747.2,928.4,747.7,927.3,748,926.3z M729.5,979.9c2.7,0,4.7,0,6.6,0
				c0.7,0,1.6,1.9,2.1-0.2c0.3-1.5-0.6-2.5-2-2.2C734.2,977.9,731.8,977.6,729.5,979.9z M734.5,953.3c3.2,0.8,5.8-0.1,8.2,1
				c0.6,0.3,1.2-0.3,1.5-0.9c0.3-0.6,0.3-1.3-0.1-1.8c-0.5-0.6-1.3-0.6-1.7-0.1c-0.7,0.9-1.3,0.2-2,0.1
				C738.5,951.3,736.7,951.6,734.5,953.3z M742.8,938.4c-0.8,0-1.5-0.1-2.1,0c-1.2,0.2-3.4,0.2-3.3,1.4c0.1,1.7,2.1,0.5,3.2,0.8
				c0.5,0.1,1.1,0.1,1.6,0c1.3-0.1,3.5,1.7,3.8-0.7C746.4,937.2,743.5,939.6,742.8,938.4z M708.6,1121.2c3,0.9,5.5-0.4,7.9,0.4
				c0.6,0.2,0.7-0.4,0.7-0.8c0-0.6-0.3-1.2-0.9-1.2C713.8,1119.4,711.1,1118.6,708.6,1121.2z M729.6,992.2c1.8,1.1,3.3,0.4,4.7,0.7
				c0.6,0.1,1.4,2.1,1.9,0c0.3-1.3-0.2-2.2-1.8-2.4C732.6,990.2,731.2,991,729.6,992.2z M731.3,1003.5c-0.3,0-0.7,0-1.1,0.2
				c-1.3,0.4-3.6,0.3-3.4,1.7c0.2,1.7,2.3,0.5,3.6,0.6c0.9,0,1.8,0,2.5,0.3c1.7,0.6,1.3-0.7,1.2-1.4
				C733.7,1003.3,732.5,1003.5,731.3,1003.5z M722.3,1031.4c2.7,0,4.8,0,6.9,0c0.7,0,0.9-0.4,1-1c0-0.6-0.4-0.7-0.8-0.8
				C727,1029.4,724.8,1029.3,722.3,1031.4z M732.7,966.4c2.5,0.5,4.4-0.1,6,0.7c0.8,0.4,0.8-0.4,1-0.8c0.4-0.9-0.2-1.2-0.8-1.4
				C736.9,964.3,735,964.9,732.7,966.4z M711.2,1134.1c0.8,0,1.3,0.1,1.8,0c0.7-0.2,2.2,1.3,2.2-0.7c0-1.5-1.3-1.4-2.4-1.3
				c-0.4,0-0.9,0.1-1.3,0.1c-1.5,0.3-4.1-0.6-4,1.3C707.6,1135.2,710.1,1133.6,711.2,1134.1z M717.2,1095.9c0.6-1.1,2.8,1.1,2.8-1.1
				c0-1.2-1.7-1.4-2.9-1.2c-1.3,0.2-3.4-0.1-3.4,1.4C713.7,1096.6,715.8,1095.6,717.2,1095.9z M716.6,1069.8
				c2.6,0.7,4.6,0.1,6.6,0.1c0.6,0,1-0.4,1-1c-0.1-0.6-0.5-1.1-1.1-1C721,1068.2,718.8,1067.9,716.6,1069.8z M781.2,1015.3
				c-0.8,0-1.4,0.3-1.2,1.1c0.4,1.7,1.9,1.5,3.1,1.8c0.6,0.1,1.6-0.2,1.2-1C783.7,1016,782.4,1015.6,781.2,1015.3z M700.5,1172.2
				c3.1,0,5.5,0,7.8,0c0.6,0,1-0.3,1-1c0-0.5-0.3-0.9-0.7-0.9C706.2,1170.5,703.4,1169.5,700.5,1172.2z M724.7,1018.2
				c2.7,0.7,4.8,0.4,6.9,0.2c0.4,0,0.5-0.8,0.2-1.1c-1.8-1.3-3.7-0.6-5.6,0C725.9,1017.3,725.7,1017.6,724.7,1018.2z M698,1223.2
				c1.2,0,1.7,0,2.3,0c0.8,0,1.8,0.8,2-0.6c0.2-1.4-0.9-1.5-1.9-1.4c-0.5,0-1.1,0.1-1.6,0.1c-1.4,0.1-3.7-0.2-3.6,1.2
				C695.2,1224.1,697.4,1223.1,698,1223.2z M710.8,1108c2.8,1.1,5,0.1,7.1,0.4c0.7,0.1,1.1-0.3,1-0.9c0-0.3-0.5-0.8-0.8-0.9
				C715.7,1106.2,713.4,1106.2,710.8,1108z M785.6,963.1c-0.9,0.1-1.3,0.7-1.1,1.4c0.4,1.4,1.6,1.7,2.8,1.8c0.6,0,1.3-0.2,1.1-0.9
				C788,964,786.7,963.5,785.6,963.1z M789.4,939.9c0.7,0.1,1.3,0,1.2-0.7c-0.2-1.5-1.7-1.7-2.8-2.2c-0.6-0.3-1.1,0.1-1.1,0.7
				C786.8,939.4,788.2,939.5,789.4,939.9z M783.5,989.5c-0.8-0.1-1.3,0.2-1.2,0.8c0.2,1.5,1.6,1.6,2.7,1.8c0.6,0.2,1.1-0.2,0.9-0.9
				C785.5,990,784.4,989.7,783.5,989.5z M788.5,953.2c0.4-0.1,1.2,0,1.1-0.7c-0.2-1.8-1.9-1.8-3.2-2.2c-0.7-0.3-1,0.2-0.9,0.8
				C785.7,952.7,787.3,952.7,788.5,953.2z M731.2,1024.4c0.1-1-0.4-1.4-1.3-1.4c-0.7,0.1-1.8,0-1.7,1c0.1,1.3,1.5,0.8,2.3,1.2
				C731.4,1025.5,730.9,1024.5,731.2,1024.4z M722.3,1074.2c-0.8,0.1-1.8,0.1-1.8,1c0,1.5,1.7,1,2.5,1.5c0.8,0.5,1.1-0.3,1.1-1
				C724,1074.6,723.3,1074.2,722.3,1074.2z M703.5,1203.8c0.9,0.2,1.7,0.4,1.8-0.7c0-1.1-0.9-1.4-1.8-1.3c-1,0-2.6-0.1-2.6,1.2
				C701,1204.2,702.7,1203.4,703.5,1203.8z M702.1,1196.9c-1.3-0.3-2.4-1.6-3.9-0.4c-0.2,0.1-0.3,0.7-0.2,0.8c1.2,1,2.6,0.7,3.9,0.2
				C702,1197.5,702,1197.3,702.1,1196.9z M785.2,1004.5c-1-1.4-2.1-2-3.5-2c-0.4,0-0.6,0.7-0.4,1
				C782.2,1004.7,783.4,1005.1,785.2,1004.5z M706.7,1177.8c0.5-0.1,1.1-0.2,0.9-0.9c-0.2-0.6-0.8-0.7-1.3-0.6c-0.5,0-1.1,0.3-1,0.9
				C705.5,1177.8,706.1,1177.8,706.7,1177.8z M722,1062.7c1.3,0.5,2.3,0.6,3.3-0.1c0.1-0.1,0.1-0.7-0.1-0.8
				C724,1061.2,723.2,1061.7,722,1062.7z M705.4,1196.5c-0.1-0.6-0.6-0.7-1.2-0.7c-0.5,0-0.7,0.4-0.8,0.8c-0.1,0.7,0.4,0.9,1,0.9
				C705.1,1197.6,705.4,1197.2,705.4,1196.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M945.9,1616.3
				c-1.3-1,0.1-2.8-1.5-4.4c1.1,5-0.4,9.6,1.1,13.9c1.4,3.8-2.3,2.7-3.5,3.7c-1.2,0.9-1.5-1.4-1.7-2.3c-0.5-3.5,0.1-7.2-2.6-10.1
				c-0.1-0.1-0.1-0.4-0.1-0.6c1.5-4.1-0.4-7.8-0.9-11.7c-0.7-5.5-0.5-11.1-0.7-16.6c-0.3-7.1-0.8-14.3-1.1-21.4
				c-0.3-6.5-0.6-13-0.7-19.5c-0.1-4.6-1.2-5.8-5.7-6.6c-7.7-1.5-15.6-1.8-23.3-3.1c-8-1.3-16.2-1.9-24-3.8
				c-6.9-1.7-13.9-2.5-20.8-3.4c-9.8-1.2-19.7-2.8-29.7-3.1c-1.1,0-2.2,0-3.2-0.3c-0.9-0.2-2.1-0.9-2.5,0.2c-0.4,0.8,0.3,2,1.1,2.5
				c1.3,0.9,2,1.6,2.2,3.5c0.3,1.9,2.1,4.3,4.5,5.1c3.1,1,5,2.9,5.5,6c0.3,1.9,1.1,3.5,2.4,4.9c0.7,0.7,1.6,1.5,2.3,0.1
				c0.5-0.9,0.9-1.3,1.7-0.4c0.7,0.8,0.5,1.7-0.2,2.3c-1.2,1.1-3.2,1.4-3.4,3.6c-0.1,0.6-0.9,0.4-1.3-0.1c-4.4-4.8-10.1-4.7-16-4
				c-0.3,0-0.6-0.1-1-0.2c0.1-1.7,1.4-2.6,2.5-3.7c1.2-1.3,1.1-2.2-0.4-3.2c-0.8-0.5-1.6-1.1-2.4-1.8c-1.8-1.7-2.9-1.3-4.6,0.5
				c-6.2,6.5-13.9,10.9-22.6,13.3c-0.3,0.1-0.9,0.1-0.9,0.1c-0.8-4.8-4.2-1.8-6.1-1.7c-11.9,0.6-23.9,1.6-35.8,2.4
				c-10,0.7-20,1.2-30,2.1c-13.8,1.2-27.6,2.3-41.4,3.1c-10.7,0.7-21.2,2.3-32,2.3c-1.6,0-3.9,0.9-4.6-1.2c-0.6-2-0.2-3.6,2.1-5.4
				c3.3-2.7,6.4-5.3,10.7-6.4c1.6-0.4,2.9,0.1,4.4,0c6-0.4,11.9-1.1,18-1.2c7.5,0,15.1,0,22.5-0.9c13.5-1.7,27.2-1.8,40.7-3.6
				c3.2-0.4,6.5,0,9.6-1.6c-1.3-1.4-0.6-2.7,0.9-3.9c2.3-2,8-3.3,10.7-1c-2.6,2.6-6.6,2.4-9.6,4.9c3.6,1.3,6.8-0.2,10.1-0.5
				c6-0.4,11.7-2.4,17.8-2.1c1.8,0.1,3.2-0.9,4.1-2.6c1.7-3.1,3.2-6.3,7.2-7.5c1.3-0.4,2.8-1.6,1.9-3.8c-0.7-1.8,1.1-3.2,1.7-4.8
				c1.3-3.6,0.4-5.7-3.3-6.2c-3.1-0.4-5.8-2.1-8.8-2.9c-3.7-1-5.2-10.2-2.5-13.2c1.4-1.5,1.1-2.9,0.5-4.5c-0.6-1.5-1.5-2.7-3.4-2.4
				c-0.8,0.1-2,0.4-2.1-0.9c-0.1-1,0.8-1.5,1.7-1.6c1.2,0,1.9-0.9,2.8-1.5c1.5-1,2.8-2.5,1.7-4c-1.2-1.8-2.6-4.4-5.3-3.6
				c-1.7,0.5-4.5,1.1-3.9,4.3c0.2,0.8,0,1.7,0,2.6c-0.1,1.5-1.3,2.1-2.4,2.3c-1.4,0.2-1.2-1.1-1.4-2.1c-0.5-3.4-3.2-5.6-5.8-5.7
				c-4.4-0.2-6.4-3.2-9.2-5.4c-0.1-0.1-0.1-0.5-0.2-0.6c-3.2-2.4-6.4-4.8-7.1-9.1c0-0.2-0.5-0.5-0.8-0.5c-4.4-0.5-4.5-4.1-5.3-7.1
				c-1-3.8-3.4-7.2-3.4-11.4c0-1.8,0-3.3,2.3-3.3c2.1,0,1.9-1.1,1.4-2.6c-1.1-3.5,0.8-6.3,4.7-6.9c-2-2.8-0.8-4.8,1.5-6.6
				c0.7-0.6,1-0.9,1.8-0.3c1.4,0.9,2.8,0.2,3.9-0.5c2.8-2,5.2-2,7.6,0.5c0.8,0.7,1.5,2.2,2.5,1c0.9-1.1,2-2.7,1.1-4.4
				c-0.6-1.3-1.5-2.4-2.3-3.6c-0.7-0.9-1.3-1.8-0.4-2.9c1.1-1.3,2.1-0.5,3.1,0.3c0.5,0.4,1.1,0.6,1.9,1.1c-0.1-1.5-0.3-2.7-0.3-3.9
				c0-2.8-1.7-4.4-4.2-4.3c-1.8,0-2.3-0.6-2.7-2.1c-0.2-0.7-0.4-1.7-0.9-2c-3.8-2.6-1-4.3,0.9-5.9c4.9-3.9,10.5-4.7,16-2
				c3.5,1.7,6.7,1.5,10.2,1.3c2.7-0.2,5.3-1.4,8.1-1.3c1.3,0.1,1.6-0.8,1.4-2c-0.2-1.5-0.2-3-0.3-4.5c0.4,0.2,1.1,0.3,1.1,0.5
				c0,2.9,1.6,3,3.8,2.2c0.7-0.3,1.5,0.1,2.1,0.6c4.4,3.8,9.2,7.3,13.3,11.5c2.3,2.4,3.5,5.9,4.8,9.1c2.1,5,3.5,5.6,8.6,3.3
				c1.1-0.5,2.2-1,3.3-1.4c3.9-1.4,5.4-0.7,6.7,3.1c0.8,2.2,1.9,4.1,3.4,5.9c0.9,1.1,1.6,2.7,0.8,3.9c-0.8,1.3-2.2,0-3.3-0.3
				c-5.6-1.6-11.3-3-17.1-3.8c-6.7-0.9-12.8,0.6-18.1,4.9c-0.8,0.7-2.2,1.5-1.6,2.5c0.6,1.1,1.9,0,2.9-0.1c0.6-0.1,1.2-0.3,2.3-0.7
				c-0.7,2.1-2,3.6-2.1,5.6c-0.1,3,0.8,4.8,3.8,5.5c1,0.3,2.5,0.2,3,1.6c-2.5,0.5-4.7-0.8-7-1.2c-1.3-0.2-2,0-1.5,1.5
				c0.2,0.7,1.3,1.6-0.1,2.1c-0.9,0.3-2.4,0.1-2.7-0.7c-1-2.2-2.5-1.5-4-1.1c-2.2,0.5-3.9-0.8-3-2.6c1.2-2.3,0.5-4.3,0-6.6
				c-0.3-1.9-0.5-4.1,2.4-4.4c0.8-0.1,1.2-0.6,1.3-1.2c0.2-0.9-0.3-1.5-1-1.9c-0.8-0.6-1.5-0.4-1.9,0.5c-0.6,1.4-1.5,0.7-2.4,0.4
				c-0.8-0.3-1.6-1.6-2.6-0.6c-0.9,0.9-1,2.1-0.5,3.3c0.7,1.8,1.6,3.7,1.8,5.6c1.1,8,1.6,15.9-0.8,23.8c-0.6,2-1.2,4.1,1.7,4.5
				c1.6,0.2,1.9,0.3,2,2.1c0,1.8,0.4,3.6,0.5,5.5c0.1,1,0.1,2.5,0.8,2.7c3.1,0.6,4.9,4.2,8,3.7c3.5-0.5,3.6,2.6,6.3,3.7
				c-1.2,0.2-1.5,0.3-1.8,0.2c-1.3-0.4-3.2-1.2-3.6,0.5c-0.3,1.7,0.1,4,2.9,4.2c1.3,0.1,2,0.6,2.9,1.8c1.2,1.7,4.4,0.8,6.1,2.6
				c0,0,0.4-0.2,0.5-0.3c3.1-2.2,3.2-2.5,0.4-5.2c-0.7-0.7-1.7-1.1-2.3-1.7c-1.1-1-2.6-2.2-0.7-4.9c0,3.3,2.1,3.8,3.6,3.6
				c3.5-0.6,6,3.1,9.6,1.7c0.3-0.1,1,0.3,1.1,0.6c0.5,1.9,1.8,1.6,3.2,1.3c0.8-0.1,1.5,0.4,1.8,1.2c0.2,0.6,0,1.3-0.6,1.3
				c-3.5,0.2-2.2,1.2-1,3.1c2.2,3.5,2.6,3.7,6.9,1.9c1.6-0.7,4.3-0.4,4.8,1.1c0.7,1.9-2.1,0.6-2.8,1.5c-0.1,0.2-0.2,0.3-0.4,0.6
				c0.5,0.3,0.9,0.7,1.4,0.8c2.5,0.7,5,1.3,4.3,4.8c-0.1,0.5,0.5,1.3,1,0.9c3.9-2.6,4.3,1.1,5.8,3c0.8,1,2,1.8,3.4,1.5
				c1.2-0.3,1.6,0.3,1.9,1.2c0.4,1.3-0.7,1.1-1.4,1.2c-0.5,0.1-1.2,0-1.2,0.8c0,0.6,0.5,0.9,1,1.2c1,0.6,2,0.8,3-0.1
				c0.6-0.6,1.2-1.6,2.2-0.6c1.1,1.1,1.5,0.4,2.3-0.4c1.1-1.2,2.4-2.1,3.9-3.5c-1-0.3-1.4-0.5-1.8-0.6c-1.2-0.3-2.7-0.8-1.9-2.3
				c0.6-1.2,2.1-1.4,3.4-0.3c1.3,1.2,4,1.2,3.6,4c-0.1,0.5,0.2,0.9,0.7,0.8c3.6-0.5,0.9,2.9,2.4,3.9c0.3,0.2-0.8,1.3-1.2,0.7
				c-1-1.7-3.5-1.2-4.1-3.4c-2.1,4.7-1.8,6.4,1.5,6.5c2.7,0.1,4.5,0.5,5.8,3c-0.5-2.3,0.8-4.3,0.1-6.5c-0.3-1,0.8-1.8,2-2.1
				c1.2-0.3,1.4,0.1,1.8,1.1c0.8,1.7,4.9,2.2,6.8,1.3c2.2-1.1,2.3-2.6,0.7-4c-1.7-1.5-0.4-2.7,0.2-3.6c0.8-1.2,2.6-1.1,3.5-0.3
				c3.2,2.9,6.2,3.3,9.4,0c0.3-0.3,0.8-0.4,1.2,0c1.8,1.7,2.1,0.3,2.5-1.1c0.4-1.7,0.9-2.8,3-1.4c1.4,1,3.4,0.8,4.5-0.6
				c1.3-1.7,2.6-1.1,3.5-0.1c2.1,2.5,6.1,4.4,2.6,8.9c-1.4,1.8,0.3,3.7,2.2,4.7c1.7,0.8,3,0.5,4.2-1.1c0.6-0.9,1.3-1.7,1.7-2.7
				c0.3-0.8,1.7-1.1,1.6-0.5c-0.6,3,3.8,3.2,3.2,6.5c-0.4,2.2,2.3,3.2,4,4.2c1.1,0.6,2.2,0.1,2.9-0.9c1.1-1.6,1.9-3.1,0.4-5
				c-1-1.3-0.4-1.9,1.2-1.9c1.3,0,2.7,0,3.9-0.4c1.1-0.4-0.2-0.6-0.3-1c0.3-0.2,0.6-0.4,0.9-0.6c3.7-0.4,6.7-2.2,9.3-4.9
				c1.2-1.3,2.2-3.1,4.4-2.9c-0.3,0.8-0.8,1.7-0.8,2.5c0.1,2.1,0.8,4,3.3,4.1c2.1,0.1,3.2-1.4,3.6-3.1c0.5-2.6-2.9-4.4-6.1-3.4
				c-0.2-2.3,2.2-3.3,2.6-5.5c0.7-3.7,2.1-4.5,5.7-3.6c2,0.5,4.2,0.4,4.7-1.6c0.5-1.8-0.4-3.9-2.7-4.6c-1.7-0.5-2.7-1.7-2.3-3.4
				c0.3-1.6,0-3.2,0-4.8c-0.1-3.9,0.4-4.2,4.1-3.2c0.5,0.1,1-0.2,1.6-0.3c0.3,0.8,0.6,1.6,0.9,2.5c-1,0.5-0.6,1-0.1,1.5
				c-0.5,4.9,0,9.8,0.2,14.6c0.5,13.8,1.2,27.6,1.8,41.4c0.1,1.6,0.6,3.8-1.4,4.3c-4.8,1.1-9.6,3.2-14.7,2.4
				c-4.6-0.7-9.3-1.4-13.9-2.2c-1.9-0.3-2.3,0.5-2.2,2.1c0.1,2.8,0.2,5.7,0.2,8.5c0,2,0.6,3,2.8,3c9.4,0.2,18.7,1.4,28.1,0.5
				c1.6-0.1,2.8,0.5,4.1,1.3c-4.2,0.2-8.4,0.6-12.5,0.4c-5-0.3-10-1-15-1c-2.2,0-4.8-0.7-6.4,0.3c-2.2,1.4-0.3,4.2-0.4,6.3
				c-0.1,3.2-0.8,6.6,1.1,9.6c-0.6,2.4-0.9,4.7,0.7,6.9c-2,2.3-1.6,8.1,0.8,10.6c0,0.4,0,0.8-0.1,1.2c-0.4,1.1-2.4,2.2,0,3.2
				c-0.1,0.2-0.3,0.4-0.4,0.6c-1.3,1.1-0.9,2.5-1,3.9C945.4,1608.9,946.8,1612.6,945.9,1616.3z M780.6,1458.6
				c0.1-5.3-4.3-8.9-9.5-8.3c-2.1,0.3-4.3,2.4-6.6,0.4c-0.3-0.3-0.8,0.2-0.8,0.7c-0.2,5.4,1,10.4,4.4,14.7c1.3,1.7,3,2.9,5.6,2.4
				C777.9,1467.9,780.7,1464.1,780.6,1458.6z M942.3,1526.7c-1.1,2-0.5,3.6-0.7,5.2c-0.7,5.4,2.7,5.8,6.3,4.3
				c1.7-0.7,2.4-0.1,3.4,0.7c0.7-1.3,0.6-2.5,0.2-3.4c-0.8-1.9-0.4-5.3-2.2-5.3c-2-0.1-1.6,3.2-2.3,5c-0.4,1-0.4,1.9-1.8,1.7
				c-1.3-0.1-1.4-1-1.8-1.9C942.8,1531.1,943.7,1529,942.3,1526.7z M787.5,1475.6c-1.1,0-1.9,0.5-2,2.3c0,2.4,1.3,3,3.3,3
				c1.9,0.1,2.5-1.2,2.5-2.7C791.4,1475.9,789.6,1475.8,787.5,1475.6z M789.4,1426.6c-0.1-1.2-0.6-2.6-2.4-2.9
				c-1.7-0.2-2.4,1-2.5,2.3c-0.1,1.5,0.4,2.8,2.3,2.9C788.2,1429,789.3,1428.7,789.4,1426.6z M788.2,1416.2c0.6,1.8,0.3,3.3,0.3,4.7
				c0,0.5,0.4,0.8,0.8,0.8c0.2,0,0.6-0.4,0.6-0.6C789.4,1419.7,790.7,1417.6,788.2,1416.2z M780.1,1418.2c0,2.4,0.5,3.6,1.5,3.5
				c1.2-0.1,1.8-0.9,1.5-2c-0.3-1.3-0.5-3.1-2.1-3.4C779.9,1416.1,780.1,1417.4,780.1,1418.2z M939.5,1579.2
				c-0.7,0.1-1.4,0.4-1.2,1.5c0.3,1.3,1.1,2.1,2.4,2.1c0.9,0,1.3-0.6,1.2-1.5C941.9,1580,941.2,1579.2,939.5,1579.2z M775.8,1423.6
				c-0.4,0.1-1.3,0.4-1.1,1.1c0.2,1.3,1.4,1.5,2.5,1.6c0.6,0.1,1.1-0.4,0.9-1C777.9,1424.2,777.1,1423.7,775.8,1423.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M920.1,1518.4
				c0.1,0.3,1.4,0.6,0.3,1c-1.2,0.4-2.6,0.4-3.9,0.4c-1.6,0-2.2,0.6-1.2,1.9c1.5,1.9,0.6,3.5-0.4,5c-0.7,1-1.8,1.5-2.9,0.9
				c-1.8-1-4.5-2-4-4.2c0.6-3.3-3.7-3.5-3.2-6.5c0.1-0.6-1.3-0.3-1.6,0.5c-0.4,1-1,1.8-1.7,2.7c-1.2,1.7-2.6,2-4.2,1.1
				c-1.9-1-3.6-2.9-2.2-4.7c3.4-4.5-0.5-6.4-2.6-8.9c-0.9-1.1-2.2-1.6-3.5,0.1c-1.1,1.4-3,1.6-4.5,0.6c-2.1-1.5-2.6-0.3-3,1.4
				c-0.4,1.4-0.7,2.8-2.5,1.1c-0.3-0.3-0.9-0.3-1.2,0c-3.1,3.3-6.2,2.9-9.4,0c-1-0.8-2.7-1-3.5,0.3c-0.6,0.9-1.9,2.1-0.2,3.6
				c1.6,1.4,1.5,2.9-0.7,4c-1.9,0.9-6,0.4-6.8-1.3c-0.5-1-0.7-1.4-1.8-1.1c-1.2,0.3-2.4,1.1-2,2.1c0.7,2.2-0.6,4.2-0.1,6.5
				c-1.3-2.5-3.2-2.9-5.8-3c-3.3-0.1-3.6-1.9-1.5-6.5c0.6,2.2,3,1.7,4.1,3.4c0.4,0.6,1.5-0.5,1.2-0.7c-1.5-0.9,1.2-4.4-2.4-3.9
				c-0.5,0.1-0.8-0.4-0.7-0.8c0.4-2.8-2.2-2.8-3.6-4c-1.3-1.2-2.8-0.9-3.4,0.3c-0.7,1.4,0.8,1.9,1.9,2.3c0.4,0.1,0.8,0.2,1.8,0.6
				c-1.5,1.4-2.8,2.3-3.9,3.5c-0.7,0.8-1.2,1.6-2.3,0.4c-0.9-1-1.6,0-2.2,0.6c-1,1-2,0.7-3,0.1c-0.5-0.3-1-0.6-1-1.2
				c0-0.7,0.7-0.7,1.2-0.8c0.7-0.1,1.8,0.1,1.4-1.2c-0.2-0.9-0.7-1.5-1.9-1.2c-1.4,0.3-2.6-0.5-3.4-1.5c-1.5-1.9-1.9-5.6-5.8-3
				c-0.5,0.3-1.1-0.4-1-0.9c0.7-3.5-1.8-4.1-4.3-4.8c-0.5-0.1-0.9-0.5-1.4-0.8c0.2-0.3,0.3-0.5,0.4-0.6c0.8-1,3.5,0.3,2.8-1.5
				c-0.6-1.5-3.2-1.8-4.8-1.1c-4.3,1.8-4.7,1.5-6.9-1.9c-1.2-1.9-2.5-2.9,1-3.1c0.6,0,0.8-0.8,0.6-1.3c-0.3-0.8-0.9-1.3-1.8-1.2
				c-1.4,0.2-2.7,0.6-3.2-1.3c-0.1-0.3-0.8-0.7-1.1-0.6c-3.5,1.4-6.1-2.3-9.6-1.7c-1.4,0.2-3.6-0.3-3.6-3.6c-2,2.7-0.4,3.9,0.7,4.9
				c0.7,0.6,1.6,1.1,2.3,1.7c2.8,2.7,2.8,3-0.4,5.2c-0.2,0.1-0.5,0.3-0.5,0.3c-1.7-1.9-4.8-1-6.1-2.6c-0.9-1.2-1.6-1.8-2.9-1.8
				c-2.8-0.2-3.2-2.5-2.9-4.2c0.4-1.7,2.3-0.9,3.6-0.5c0.3,0.1,0.6-0.1,1.8-0.2c-2.7-1.1-2.8-4.2-6.3-3.7c-3,0.5-4.9-3.2-8-3.7
				c-0.7-0.1-0.7-1.7-0.8-2.7c-0.1-1.8-0.5-3.7-0.5-5.5c0-1.8-0.4-1.9-2-2.1c-2.9-0.4-2.3-2.5-1.7-4.5c2.4-7.9,1.9-15.8,0.8-23.8
				c-0.3-1.9-1.2-3.7-1.8-5.6c-0.4-1.2-0.4-2.4,0.5-3.3c1-1,1.7,0.3,2.6,0.6c0.9,0.3,1.8,1,2.4-0.4c0.4-0.9,1.1-1,1.9-0.5
				c0.7,0.5,1.1,1.1,1,1.9c-0.1,0.7-0.5,1.2-1.3,1.2c-2.9,0.3-2.7,2.5-2.4,4.4c0.4,2.2,1.1,4.2,0,6.6c-0.9,1.8,0.8,3.1,3,2.6
				c1.6-0.4,3-1.1,4,1.1c0.4,0.8,1.8,1,2.7,0.7c1.5-0.5,0.3-1.4,0.1-2.1c-0.4-1.5,0.3-1.7,1.5-1.5c2.3,0.4,4.5,1.7,7,1.2
				c9.2,1.4,18.4,2.5,27.6,4.2c3.7,0.7,7.2,2.6,11.1,2.8c12.2,0.4,24.1,3.6,36.2,4.9c2.4,1.3,3.1,1,3.3-1.2c0.9-0.4,1.4-1.1,2-1.9
				c2.7,0.6,4.3,1.8,2.7,4.8c-0.3,0.6-0.2,1.7,0.3,1.7c2.3-0.1,2.5,3.4,5.1,2.7c0.9-0.2,0.9,0.9,0.9,1.7c0,3.7,2.7,2.5,4.4,2
				c1.7-0.4,4.9-0.3,2.9-3.7c-0.5-0.9-0.2-1.4,0.3-2c2.2-2.3,1.6-4.2-0.4-6.4c-1.4-1.6-2-4.2-0.4-5.7c2.2-2,1.8-3.9,1.3-6.2
				c-0.6-2.6,2-6.1,4.4-5.6c2.5,0.5,2.2-0.2,1.7-2.1c-0.5-2.1,1.7-3,2.9-3.2c1.8-0.2,1.1,1.8,1,2.7c-0.4,3.1,1.3,5.4,2.4,8
				c0.7,1.7,1.2,3.4,3.5,3.3c2.2-0.1,1.8-2.1,2.4-3.4c0.2-0.4,0.2-0.8,0.3-1.3c0.1-0.6,0.4-1.1,1-1c0.6,0.1,0.9,0.7,0.9,1.2
				c0,1.9,1.3,3.2,2.3,4.6c1.7,2.4,3.7,4.6,5.2,7.1c1,1.6,1.1,3.9,0.1,5.3c-0.7,0.9-3.2,0.5-4.1-0.6c-3.5-4.5-8.2-1.8-11-0.5
				c-3.7,1.7-9,2.7-10.1,8.3c-0.3,1.5-0.3,3.9-2.8,4.4c0.4,3.4,0.9,6.9,3.3,9.6c1,1.1,1.7,2,1.4,3.6c-0.6,3.5,0.7,6.2,4.1,7.7
				c1.1,0.5,1.6,1.3,1.9,2.4c0.7,2.2,2.7,3,4.4,4.1c0.5,0.3,1.7-0.6,1.7,0.1c-0.1,3,2.2,3.8,4.1,5.1c3.9,2.8,8.6,4,12.9,3.1
				c7.8-1.8,12.8-7.2,15.7-14.8c1.8-4.9,2.2-9.8,2.2-14.9c0-0.8-0.2-2.3,0.8-2.1c2.1,0.4,3.6-1.2,5.5-1.3c4.2,1.3,2.5,5,3.1,7.8
				c-0.5,0.1-1.1,0.4-1.6,0.3c-3.8-1-4.2-0.7-4.1,3.2c0,1.6,0.3,3.2,0,4.8c-0.3,1.7,0.6,2.9,2.3,3.4c2.3,0.7,3.2,2.7,2.7,4.6
				c-0.6,2-2.7,2.1-4.7,1.6c-3.6-0.9-5-0.1-5.7,3.6c-0.4,2.1-2.8,3.1-2.6,5.5l0-0.1c-2.2-0.2-3.2,1.6-4.4,2.9
				c-2.5,2.7-5.5,4.5-9.3,4.9c-0.9-1.2-4.8-1.7-5.5-0.7c-0.7,0.9-0.2,1.4,0.8,1.4C917.6,1518.6,918.8,1518.5,920.1,1518.4z
				 M774.3,1466.4c0.1,0.7,0.2,1.9,1.1,2.1c2.1,0.4,4.2,0.9,6.2,1.6c0.5,0.2,1.6-0.2,1.8-0.6c1.1-3.1,2.7-1.8,5-1.3
				c2.3,0.6,2.4,2.1,3.2,3.5c0.5,0.9,1.3,1.1,2.2,0.6c0.5-0.3,1.2-0.8,0.6-1.1c-3.3-1.6-3.1-5.6-5.5-7.7c-0.6-0.5-1-1.4-1.9-0.8
				c-3,2-2.7-0.2-2.7-2c0-1.5-0.3-3,0.4-4.5c0.3-0.6,0.4-1.4-0.1-1.8c-0.8-0.8-1.3,0.3-2,0.5c-1.9,0.8-3.7,2.2-5.9,1.4
				c-2.5-0.9-2.4,0.7-2.3,2.1C774.6,1461,774.9,1463.6,774.3,1466.4z M822.3,1501c-0.6,0.1-0.7,0.5-0.6,0.9c0.1,0.3,0.5,0.7,0.8,0.8
				c0.4,0.1,0.9-0.2,0.8-0.7C823.3,1501.5,822.9,1501.1,822.3,1501z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M994.2,1188.8
				c1.9,0.5,3.3,1.7,4.7,2.9c3.5,3.1,6.1,7,10.1,9.7c-5.5-1.8-10.7-4.2-16.3-5.6c-3-0.7-6-1.4-9.1-1.4c-0.7,0-1.7,0-1.8,1
				c0,1.1,1,1,1.7,1c5,0.3,8.5,3,11.5,6.6c1.9,2.3,4.9,3.2,6.8,5.5c0.9,1.1,2.1,1.9,2.7,3.5c-1.9-0.8-3.8-1.5-5.7-2.4
				c-5-2.3-10.3-3.7-15.7-4.8c-1.2-0.3-2.6-0.7-3.7,0.5c-0.3,0.4-0.3,1,0.1,1.5c0.9,1,2,1.1,3.3,1c2.9-0.4,4.5,2.2,6.2,3.6
				c3.7,3,6.8,6.9,10.2,10.4c-6.2-2.9-12.8-5.3-19.6-6.7c-0.2,0-0.4-0.1-0.6-0.1c-1.5-0.3-3.5-0.9-3.8,0.9c-0.2,1.5,1.5,2.4,3.4,1.9
				c0.3-0.1,1,0.1,1.2,0.4c2.7,4.4,8.2,5.3,11.1,9.5c1.7,2.5,5.3,2.9,7.7,5.9c-5-2.1-9.3-3.9-13.7-5.7c-5.1-2-10.5-3.1-15.9-3.6
				c-0.6-0.1-1.4-0.3-1.6,0.6c-0.1,0.6,0.5,0.9,1,1.1c0.5,0.2,1,0.5,1.5,0.6c5.5,0.4,8.9,3.9,12.3,7.8c2.7,3,5.9,5.6,9.4,7.9
				c-10.4-3.9-20.6-8.2-32.2-7.4c4.5,3,10.3,2.8,14.7,6.8c4,3.6,8.8,6.3,12.9,10.7c-2.4-0.1-3.8-1.3-5.4-1.9c-8-3-15.9-6.2-24.8-5.6
				c-1.3,0.1-2.6-0.5-3.9-0.6c-0.7-0.1-1.7-0.1-1.7,1c0,1,0.8,1.2,1.6,1.2c1.6,0,3.2-0.1,4.8,0.5c6.9,2.6,12.8,6.7,18.2,11.6
				c1,0.9,2.2,1.5,3.7,2.6c-3.1,0.3-5.3-1.2-7.5-2c-5.4-2.1-10.8-4-16.8-3.6c-1.4,0.1-2.9-0.8-4.5-0.7c-0.5,0-1.2,0-1.3,0.7
				c-0.2,0.9,0.5,1,1.2,1.2c6.3,1.9,12.3,4.3,17.1,9.2c2.2,2.2,5,3.9,7.3,6.3c-3.4-1.3-6.9-2.6-10.3-3.7c-5.2-1.7-10.3-3.7-16-2.5
				c-0.8,0.2-2,0-2,1.2c-0.1,1,1,0.9,1.7,1.1c9.1,2.8,15.9,9.1,23.2,15c-1.6-0.6-3.2-1.3-4.8-1.9c-1.6-0.6-3.3-1.1-4.8-1.8
				c-5.8-2.5-11.8-3.9-18.1-3.7c-1,0-2.1-0.3-2,1.3c0.1,1.2,0.4,2,2,1.9c6.6-0.4,11.4,2.9,15.7,7.4c2.2,2.3,4.6,4.5,7.3,6.4
				c-2.6,0.1-4.6-1.4-6.9-2.2c-6.5-2.2-12.8-5.1-20-4.2c-0.9,0.1-1.9-0.4-2.9-0.5c-0.9-0.1-2.1-0.8-2.5,0.6
				c-0.3,1.1,0.9,1.6,1.6,2.3c0.3,0.2,0.9,0.4,1.2,0.3c5.3-1.9,8.5,1.5,12,4.5c3.4,2.9,6.7,5.9,10.8,7.9c0.8,0.4,1.9,0.7,2,2.2
				c-10.7-4.2-21.1-9-33.7-6.7c4.9,3.3,11.1,1.8,15.2,5.9c3.7,3.7,8.2,6.7,13,10.5c-5.4-1.3-9.5-4-14.1-4.9
				c-3.9-0.8-7.7-2.6-11.9-1.6c-0.8,0.2-2.5-0.4-2.2,1c0.3,1.1,1.7,2.8,3.2,2.2c3.9-1.6,5.9,1.4,8,3.3c1.9,1.8,3.7,3.7,6,4.8
				c3,1.5,5.4,3.6,7.5,6l0,0c-5.4-1.9-10.7-4.3-16.4-5.7c-1.6-0.4-3.1-1.2-4.8-0.8c-0.8,0.1-2-0.1-2.1,1.1c-0.1,1,0.5,2,1.5,2.3
				c6.7,1.9,9.9,7.7,14.8,12.4c-2.1,0.2-3.6-0.6-4.7-1.3c-4.4-2.6-9.3-3.6-14.2-4.7c-0.9-0.2-1.8-0.5-2.3,0.5
				c-0.6,1.1,0.2,1.9,1,2.5c1.2,0.9,2.5,1.7,3.7,2.6c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0-0.4,0.1-0.6,0.1c0.2,0.1,0.5,0.2,0.7,0.3
				l0.2,0.2c1,2.8,4.3,2.5,6.2,4.1c1.2,1.1,3,1.4,5,2.3c-3.9,1.7-6.9-0.3-9.6-1.4c-3.1-1.2-6.2-1.6-9.2-1.8
				c-4.6-0.2-8.8-1.4-13.2-2.4c-2-0.5-4.2-1.2-6.3-0.2c-1,0.5-1.9,0.2-2.8-0.1c-3.4-1.2-6.6-2.7-10.1-3.5c-1.6-0.4-1.5-2.2-1.5-3.7
				c0.1-1.4,1.3-1.2,2.2-1.3c5.1-0.7,10.2,0.2,15.3,0.6c3.9,0.3,6.9-1.5,8.1-5.1c1.2-3.6,3-7.1,5.1-10c1.3-1.8,2.4-4.3,4.8-5.3
				c0.7-0.3,1.3-1.2,1.1-1.4c-2-3.2,1.3-5.6,1.3-8.4c0-0.3,0.2-0.8,0.3-0.8c5.3,0.9,3.1-3.7,4.2-5.8c0.2-0.4,0.2-0.8,0.3-1.3
				c-1.2-0.6-2.6,2.2-3.4-0.3c-0.6-1.8,0.9-2.4,1.8-3.4c1.9-2.1,3.8-4.2,6.4-5.8c2.6-1.5,0.7-3.8-0.4-5.6c2.7-1.6,5.3-3.1,8.1-4.8
				c-1.3-0.8-2.2,0.2-3.2-0.2c-0.7-0.3-1-0.8-1.2-1.4c-0.3-0.8,0.3-0.9,0.9-0.8c0.4-2.7,2.6-3.9,4.4-5.4c0.1-0.1,0.1-0.6,0-0.9
				c-1.4-3.5,1.1-5.7,2.8-8.2c1.5-2.3,3.9-4.6,2.5-7.8c-0.9-1.9,1.6-2.9,1-4.5c-0.9-2.5,1.1-2.6,2.5-3.2c0.3-0.1,0.8-0.4,0.8-0.5
				c-0.6-3.9,5-5,4.1-9.1c-0.2-0.9,0.4-1.9,1.4-1.9c2.4,0,2.7-1.3,2.4-3.2c-0.3-2.4,1.3-4.1,2.7-5.3c2.2-1.8,2.1-2.7-0.9-3.5
				c3.5-2.2,3.7-6,5.9-8.6c0.9-1.1-1.6-3.6,0.2-5.5c0.3-0.3,0.2-1.4,0.7-1.3c3.4,0.4,3.2-1.8,3.3-4.1c0-0.8,0.1-2.7,2-2.5
				c0.2,0,0.6-0.6,0.7-1c0.7-2.7,1.7-5.1,5.2-5.2c0.6,0,0.9-1.1,0.4-1.6c-1.6-1.5-1.6-4.7-4.9-4.8c2.5-0.1,4.4-1.7,6.7-2
				c2.2-0.3,3.2-1.7,4-3.5c0.7-1.6-0.9-2.3-1.7-3.2c-1.7-2-1.3-5.8,0.9-7.3c1-0.6,1.7-1.5,2-2.5c1.2-3.2,3.3-5.7,5.2-8.4
				c2.2-3.2,5.5-4.1,8.1-6.1c1.5-1.1,1.1-2.7,0.3-3.8c-2.9-3.9-1.7-7.7-0.4-11.6c1.4-4.2,5-6.4,8-9.1c0.9-0.9,1.2-1.5,1-2.7
				c-0.3-2.1,0.3-3.8,1.6-5.9c1.3-2.3,4.9-3.4,4.4-6.9c-0.2-1.2,1.9-1.3,2.5-2.3c0.8-1.3,1.3-3.1,0.6-4.3c-1.3-2-0.3-2.1,1.3-2.3
				c0.9-0.1,1.7-0.8,0.8-1.4c-2.7-2,0.2-3.9,0.7-4.1c2.4-0.9,2.3-3.5,4-4.8c0.7-0.5,0.7-1.4-0.1-1.9c-2.4-1.7-1.2-3.7,0.5-4.3
				c3.7-1.5,3.7-4.8,3.7-7.5c0-2.4,0.4-3,2.4-3.2c2.1-0.2,2.7-0.9,0.7-2.4c-0.5-0.3-0.8-0.8-0.3-1.4c2-2.2,2.2-4.8,2.3-7.6
				c0-1.6,1.4-1.3,2-0.5c0.7,1,1.4,2.1,2.5,1.1c1-1,0.6-2.9-0.3-3.7c-2.2-1.8-0.4-2.8,0.5-3.8c1.1-1.2,4.7-7.4,4.6-9
				c0-0.7,0-1.2,0.5-1.7c4.1-4.5,4.5-10.3,5.8-15.8c0.5-2,1.2-3.4,3.3-3.8c0.6,3-0.6,5.8-1.5,8.6c-0.3,1-0.7,1.7,0.5,2.4
				c8.8,4.8,14.8,12.7,21.8,19.5c0.2,0.2,0.3,0.6,0.7,1.6c-5-2.7-9.6-4.9-14.6-6c-2.2-0.5-4.5-0.9-6.8-1.3c-0.8-0.1-1.9-0.7-2.3,0.4
				c-0.4,0.9,0.6,1.6,1.2,2.1c3.2,2.4,6.5,4.7,9.7,7.2c1.9,1.5,3.2,3.4,4.9,5.2c-6.2-2.3-12.4-4.4-19.9-5
				c4.8,5.9,10.5,9.7,15.8,14.6c-1.8,0.8-2.8,0.1-4-0.4c-5.6-2.6-11.5-4-17.7-4.3c-1,0-2.6-0.5-2.9,0.8c-0.4,1.7,1.5,1,2.2,1.6
				c6.8,4.6,14.3,8.1,20,14.3c-4.2-1.3-8.1-3.5-12.3-4.6c-3.9-1-7.9-1.6-11.9-2.1c-1-0.1-2.2-0.3-2.3,1c-0.2,1.6,1.3,1.1,2.1,1.2
				c1,0.1,2-0.1,2.8,0.6c5.8,4.9,12.7,8.6,18.4,15c-8.3-4.5-16.4-7.2-25.5-7.9c5.9,6.9,14.3,10.2,19.9,16.9
				c-8.4-3.8-16.9-6.9-26.4-6.4c3.7,2.6,6.9,5.5,11.2,7.3c3.8,1.7,6.8,5.3,9.9,8.5c-3.2-1-6.1-2.3-9.2-3.4
				c-4.1-1.4-8.2-2.1-12.4-2.9c-0.9-0.2-1.9-0.2-2.4,0.6c-0.5,1,0.7,1.4,1.2,2c0.5,0.6,1.2,1.3,1.8,1.3c3.7,0,5.8,2.4,8.2,4.7
				c1.9,1.9,4,3.7,6.4,5c1.4,0.7,2.7,1.8,4,4c-6.4-3.1-12.2-5.6-18.4-6.9c-1.8-0.4-3.7-0.7-5.5-0.9c-0.8-0.1-1.5,0.2-1.6,1.2
				c-0.1,0.8,0.5,1.2,1.2,1.4c4.6,1.3,7.7,4.8,11.2,7.7c1.9,1.6,3.5,3.4,5.9,4.4c1.1,0.5,2,1.5,2.4,2.8c-17.7-7.2-26.9-9.3-32.8-6.7
				c5.9,1.4,11.9,1.1,16,5c3.9,3.7,8.3,6.8,11.8,11.1c-5.6-2.3-11.1-4.5-16.9-6c-4-1.1-8-0.3-11.9-1.6c-0.7-0.2-1.7-0.1-1.8,0.8
				c-0.1,1,0.9,1.5,1.7,1.6c1.3,0.1,2.7-0.5,3.9-0.1c2.3,0.8,4.7,1.4,6.6,3.4c3.4,3.7,7.5,6.8,11.7,9.6c1.2,0.8,2.2,1.7,3.1,2.8
				c-6.5-2.7-13-5.5-20-6.9c-0.6-0.1-1.3-0.3-1.9-0.5c-0.6-0.1-1.4-0.2-1.7,0.5c-0.4,0.8,0.2,1.2,0.8,1.6c5.2,3,8.8,8.1,13.9,11.3
				c1.4,0.9,2.8,2,4,4.2c-9.7-4.7-19.1-7.8-29.3-7.5c7.7,1.8,13.4,6.5,18.6,12.1c1.7,1.9,4,3.3,5.9,5.1c-5.2-2.3-10.5-4.5-16.1-5.9
				c-2.1-0.5-4.3-1-6.4-1.4c-0.6-0.1-1.4-0.2-1.7,0.5c-0.4,0.7,0.3,1.2,0.8,1.5c5.4,3.3,10.2,7.3,14.6,11.9c0.8,0.9,1.9,1.7,3.3,2
				c1.1,0.3,2.3,0.6,2.5,2.5c-7.2-3.4-14.3-6.7-22.1-8.1c-0.9-0.1-1.7-0.3-2.6-0.5c-0.5-0.1-1.2,0-1.4,0.6c-0.3,0.9,0.5,0.9,1.1,1.2
				c5.8,3.2,10.7,7.3,15.2,12.1c0.4,0.5,1.1,1.1,0.9,2c-0.9,0.2-1.7,0.2-2.5-0.3c-5.2-3.1-11-4.2-16.8-5.4c-0.8-0.2-2.6-1.3-2.4,1.2
				c-1,0.2-2.1-1-3,0.2c-0.4,0.5-0.4,1-0.1,1.5C987.2,1189.2,992.5,1190,994.2,1188.8z M1031.4,1080.8l-0.1,0.2l0.2,0L1031.4,1080.8
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M745.3,1312
				c0.6-1.4,0.5-3.4,2.7-3.6c1.7-0.5,3.8-0.6,3.1-3.4c-1.4-1-2.7-2.4-4.5-2.8c-3.3-0.6-3-2.7-1.8-4.8c0.9-1.6,2.4-2.9,0.4-4.8
				c-0.7-0.6-0.7-1.9-1.1-2.8c1.4-0.4,2.8-1.4,4.3-0.1c-0.7,0.3-2.3-0.1-1.8,1.2c0.7,1.7,2.8,1.4,4.3,1.8c1.7,0.5,0.9-1.2,1.2-2
				c-0.5-1.6-1.8-2.4-3-3.3c1-1.8-1.2-5.1,0.6-5.5c3.9-0.8,2.7-4,4-5.9c-0.2-1.5-1.4-2.2-2.3-3.1c0.2-0.3,0.6-0.7,0.5-1
				c-1.1-3.9-1.1-7.2,3.4-9c0.9-0.4,1.2-1.8,0.5-3c-0.7-0.5-1.4-1-2.1-1.5c3.4-3.8,2.5-8.5,3.1-13c0-0.3,0-0.6,0-0.9
				c2.1-4.7,1.2-10,3.1-14.9c1-2.6,2-6.6,1.3-9.7c-0.8-3.6-0.4-6.4,2.4-8.3c2.8-1.9,1.9-5.2,3.6-7.4c0.6-0.7-0.1-1.9-0.8-2.4
				c-3.8-3.1-1.3-5.8,0.7-8.1c1.2-1.5,2.6-2.3,0.8-4.3c-0.8-0.9-0.1-2.4,0.5-3.6c1.1-2.1,3.4-4.1,1.2-6.8c-0.3-0.4,0-1,0.5-1.2
				c2.9-1.5,2.8-3.8,2.4-6.8c-0.4-3.4,2.5-5.1,5.7-5.9c-2.5-2.2-3.9-4-3.2-7.8c0.7-3.9,1.3-8,1.6-12.1c0.1-1.9,0.1-3.9,1.9-5.6
				c3-2.9,1-6.8,1.5-10.2c0.4-2.7,0.6-5.2,2.9-7.1c1.5-1.3,1-3.6,1.5-5.4c0.3-1.3-2.1-1.2-3.4-0.5c-2.2,1.2-4.6,2.1-6.1,4.3
				c-0.5,0.7-1.2,1.6-2.2,1.1c-0.8-0.4-0.5-1.3-0.2-2.1c0.6-2.1-1.4-4.5,0.8-6.5c3.2-3,6.2-5.9,10.7-7c14.3-3.6,27.2-0.5,38.8,8.3
				c6.1,4.6,8,14.1,5.7,21.1c-1.7,5-4.7,9.6-5.2,15c-1.2-3.6-3-6.9-6.4-9.5c-0.9,5.3,4.1,8.8,3.4,13.9c-2.2-1.7-3-4.5-5.7-5.7
				c0.2,5.6,3.7,9.8,5.5,15.4c-3-1.4-3.5-4.1-5.4-5.3c-1.9-1.2-3.1-3.5-5.4-3.9c0.9,8.2,9,12.3,10.3,20.1c-2-2.6-3.8-5.3-6.4-7.1
				c-2.4-1.7-4.3-4.8-8-4.2c-1.4,0.1-2.6,0.2-3.5-1.5c-0.9-1.7-2.5-0.1-3.7,0.2c-1.3,0.3-1.1,1.4-0.6,2.5c0.4,0.9,1.4,1.8,0.5,2.8
				c-0.9,1.1-1.8-0.1-2.7-0.4c-2.4-0.6-4.8,0-5.6,2.5c-0.8,2.2,0.2,4.1,2.9,4.8c2,0.5,3.9,1.4,5.8,2.1c1.1,0.4,1.6,1.2,1.6,2.4
				c-0.1,1.3-1.3,0.8-1.9,1.1c-2,1.2-4.3,0.8-7.2,1.9c4.1,1.2,5.7,5,9.5,5.3c0.7,0.1,1.1,0.7,1,1.4c-0.3,1.2-1.1,0.7-1.8,0.5
				c-0.7-0.3-1.4-0.5-2.2-0.8c-1.3-0.4-2.6-0.7-3.5,0.5c-1,1.3-1.5,2.9-0.3,4.2c1,1.1,1.1,2.2,0.9,3.5c-1.3,0-2.4,0.2-2,1.9
				c-1.3,0.4-2,0.9-1.4,2.5c1.1,2.9,0.4,4.7-3.1,4.8c-1.5,0.1-2.6,1.7-1.4,2.7c3,2.4,4.5,6.9,9.5,6.6c1.6-0.1,2.5,1.1,2.3,2.8
				c-2.8,0.9-5.6,2.4-8-1.1c-0.7-1-2.4-0.5-3.4,0.6c-0.6,0.6-1.1,1.3-0.2,1.9c1.7,1.1,2.4,3.1,4.3,4.3c2.3,1.4,5.3,2.1,6.8,4.7
				c-0.6,0.7-1.8-0.2-2.5,1.3c8.2,4.9,13.3,12.3,16.5,21.2c-8.2-9.1-8.5-9.2-12.6-9.4c6.1,5.2,9.3,11.5,11.3,18.5
				c-3.5-3.9-6.8-8-11.8-10.4c-0.6,2.4,1.5,3.1,2,4.6c-1.3-0.2-2.8,0.8-3.8-0.8c-0.4-0.7-1.1-0.6-1.5,0.1c-0.3,0.5-0.9,1.5-0.4,1.7
				c1.4,0.6,2.8,1,4.3,1.2c1.7,0.2,1.2-1.2,1.3-2.1c4.3,3.9,6.5,9,8,14.4c-4.3-4.8-8.8-9.3-15.6-11.9c3.6,6.7,11.8,9,12.3,16.9
				c-0.4,0.4-0.8,0.7-1.2,1.1c-3.4-4.2-8-6.7-12.8-8.7c-0.9-0.4-2.1-0.9-2.8,0.2c-0.6,1.1,0.5,1.9,1.2,2.5c1.8,1.5,3.7,2.6,6.1,3
				c2.3,0.4,4.7,1.3,5.8,3.4c1.9,3.7,4.2,7.4,4.6,11.7c-0.2,0.2-0.3,0.3-0.5,0.5c-4.7-5.4-9.8-10.1-17.5-11.4
				c10.2,4.7,14.5,13.7,18.2,23.2c-4.4-5-8.6-10-15.1-12.2c4.9,5.8,10.7,11.1,12.2,19.1c-3.4-2.8-6.2-6.3-10.3-8.1
				c-0.9-0.4-1.9-2-2.9-0.6c-0.7,1,0.5,2.1,1.5,2.8c0.4,0.3,0.9,0.5,1.3,0.7c6.4,3.5,8.8,9.3,10.2,16c-1.5,0.1-1.6-0.9-2.2-1.5
				c-2.3-2.9-4.9-5.5-8.2-7.4c-0.6-0.4-1.4-1-2-0.1c-0.6,0.8,0,1.2,0.7,1.8c6.4,5,9.3,12.2,12.2,20.2c-8.2-10.1-13.7-14.4-19.3-15.4
				c7.4,6.6,15.4,12.7,17.8,23.6c-17.1-14.3-18.5-15.2-23.4-15.3c0.3,2.9,3.9,2.2,4.9,4.2c-2.9,0.9-1,2.4-0.2,3.3
				c1,1.4,2.3,2.8,1.3,4.4c-1.1,1.7-3.1,1-4.6,0.6c-3.7-0.9-7.4-1.9-11.1-2.6c-3.8-0.7-8.2-1.4-10.8-3c-4.3-2.6-9.6,0.3-12.9-3.7
				c-0.3-1.2-1.2-2.8,1.3-2.7c0.4,1.7,1.8,1.7,3,1.6c5.1-0.1,10.2-0.2,15.3-0.3c1.1,0,2.8,0.2,2.7-1.3c-0.1-1.6-1.8-0.8-2.9-0.9
				c-5.2-0.7-10.4,1.5-15.6,0.3c2.4-2.4,0.7-3.7-1.4-4.9c-2-1.2-4.8-1.4-5.5-4.3c0.2-0.2,0.4-0.4,0.6-0.6c0.7-0.2,1.3-0.3,2-0.5
				C744.7,1312.5,745,1312.2,745.3,1312z M790.7,1263c1.9,0.4,2.8,2.1,4.3,1.5c0.9-0.3,0.1-1.5-0.4-2.2
				C793.3,1261,792.2,1261.3,790.7,1263z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M728.8,1386
				c-4,0.3-7.8-0.5-11.7-0.9c-8.4-0.9-16.9-1-25.1-2.3c-8.1-1.3-16.2-1.6-24.3-2.8c-4.4-0.6-8.9-0.2-13.4-0.3
				c-1.2,0-1.1,1.1-1.7,1.6c-0.9,0.9-2.5,0.3-3-0.3c-2-2.2-3.9-2.1-6.3-0.8c-0.8,0.4-1.9,0-2.9,0c3.3-1.6,3.6-5,4.3-8
				c0.4-1.7,1-3.1,2.6-3.8c1.5-0.7,1.9,1,2.8,1.6c1.7,1.1,3.5,3.8,5.6,0c1.4-2.5,4.1-0.5,6-0.2c3.6,0.7,6.9,3.9,11,1.2
				c0.5-0.3,1.1,0.1,1.3,0.6c0.7,1.2,2,1.6,3.1,1.6c3.4,0,6.5,1.8,10.1,1.1c3.3-0.6,6.7,1.2,10.3,1.3c7.3,0.2,14.4,1.6,21.7,2.1
				c6.8,0.5,13.8,1.4,20.7,2.5c6,1,12.1,1.4,18,2.8c1.5,0.4,2.4-0.5,2.8-2.3c-1.9,0.1-3.6,0.6-5.2-0.3c-0.5-0.3-1.1-1.1-0.9-1.1
				c2.5-0.2,2.7-3.5,5.1-3.5c0.8,0,1.4-0.1,1.6-0.8c1.2-3.8,2.9-7.5,1.9-11.6c-0.2-0.8-0.5-1.5,0-2.2c1.9-2.7,1.5-5.8,1.6-8.7
				c0.1-5.5,0.4-11.1,1.1-16.6c0.2-1.9-0.4-3.2-2.8-3.9c-6.6-1.8-13.3-3-20-4c-3.9-0.6-8-0.3-12-0.4c-0.6,0-1.5,0-1.3,0.9
				c0.3,1.4-0.1,2.2-1.5,2.3c-1.6,0.1-2.6-0.9-3.4-2.2c-0.4-0.6-0.2-1.7-1.2-1.7c-1.1,0-2.2-1.7-2.7,1.5c-0.9,5.7-0.1,11.6-2.3,17
				c-0.5,1.2-0.4,2.3-0.5,3.5c-0.1,0.9-0.5,1.6-1.5,1.1c-2.7-1.4-2.5,0.6-1.9,1.9c1.7,3.5,2.2,7.2,2.2,11.1c0,1,1,2.1,1.7,3.1
				c0.4,0.6,1.1,1.3,0.5,2c-0.6,0.8-1.4,0-2.1-0.1c-6-0.9-11.6-3.8-17.8-4.2c-7.1-0.4-13.5-4.1-20.7-3.8c-2.4,0.1-3.8-1.4-3.9-4.5
				c-0.2-5.3,1.5-10.1,2.9-15.1c1.4-4.9,2.3-9.9,2.3-15c0-1.6-1.2-2.7-3.1-2.8c-1.7-0.1-3.3-0.1-3.7,2.1c-1.4,7.5-3.3,14.8-4.2,22.3
				c-0.2,2-1.1,4-0.7,6.1c0.1,0.8,0,1.7,0.7,2.5c0.6,0.6,1.7,1.2,1.1,2.1c-0.8,1.1-2,0.2-3-0.2c-5-2.1-9.1-5.6-13.9-7.9
				c-0.6-0.3-1.3-0.8-1-1.5c0.5-1,1.3-0.4,1.9-0.1c1.2,0.6,2.4,0.9,3.8,0.8c0.8-0.1,1.5-0.1,1.8-1.1c2.1-6.9,2.7-14,3.3-21
				c0.3-3-1.4-5.6-4.2-7.5c2.5-1.1,5.1-0.7,7.3-0.7c5-0.1,9.1-3.3,14-3.3c4,0,8.1,0.6,12,0.7c3.7,0.1,7.2,1,10.9,1.1
				c3.3,0,6.7,0.6,10,0.6c1.7,0,2.3,1.6,4.2,1.6c7.9,0.2,15.8,1.1,23.7,1.7c0.8,0.1,1.4,0.6,2,1.1c0.5,0.4,1.1,1.2,1.5,1.1
				c6.4-1.4,11.5,3.2,17.3,4c5.8,0.7,11.2,2.4,16.8,3.7c2,0.5,3.1,1.7,3.9,3.1c1.6,2.7,3.7,3.5,6.6,2.3c1.6-0.6,3.2-0.7,2.7,1.5
				c-1.8,8-1.9,16.1-2.2,24.3c-0.1,4,0,8.1-0.2,12.1c-0.1,1.9-0.2,3.8,2.4,4.2c2.3,0.3,4.2-0.5,5.1-2.8c1.5,0.1,2.5,1.5,4.1,1.2
				c0.8,1,1.6,2.1,2.5,3.4c-2.5,0.2-4.7,0.6-6.2,3.1c-0.9,1.5-3.6,1-5.6,1c-3.7,0-7.3,0-11.1,0c-0.9,2.5,1.2,2.7,3.1,3.2
				c4.7,1.2,9.4,2.4,13,6c-3-0.4-3.2,1.8-3.3,3.8c0,1,0.2,1.8-1,1.9c-0.8,0.1-1.6,0.1-2-0.7c-0.6-1.1,0.9-1.3,1-1.9
				c-6.2-1.3-12.4-2.8-18.6-3.9c-5.5-1-11-1.8-16.5-2.5c-1.7-0.2-3.4-0.5-5.1-1c0-0.5,0.1-0.9,0.1-1.4c3.9,0.7,7.7,0.7,12.1,0.7
				c-1.4-2.1-3.3-1.5-4.7-1.7c-3.4-0.6-6.9-0.7-10.3-1.1c-1.3-0.1-1.4-1.7-2.8-1.8c-3-0.2-6-1-9-1.2c-2.6-0.1-5.2-1.4-7.8-0.5
				c0.3,1.3,1.8,0.4,2,1.7C729.6,1384.4,726.6,1383.5,728.8,1386z M773.5,1385.7c1.8-0.4,3.3-0.7,4.8-1.1c-0.1-0.3-0.1-0.6-0.2-0.9
				C776.6,1384.2,774.7,1382.5,773.5,1385.7z M797.3,1377.3l-0.1,0.2l0.2,0L797.3,1377.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M970,1475.3
				c-1.9,0-3.3,1.6-5.5,1.3c-1-0.2-0.8,1.3-0.8,2.1c-0.1,5.1-0.4,10-2.2,14.9c-2.9,7.7-7.9,13.1-15.7,14.8c-4.3,1-9-0.3-12.9-3.1
				c-1.9-1.3-4.2-2.2-4.1-5.1c0-0.7-1.2,0.2-1.7-0.1c-1.7-1.1-3.7-1.9-4.4-4.1c-0.3-1.1-0.8-2-1.9-2.4c-3.4-1.5-4.7-4.2-4.1-7.7
				c0.3-1.7-0.4-2.5-1.4-3.6c-2.4-2.7-2.9-6.1-3.3-9.6c2.5-0.5,2.5-2.9,2.8-4.4c1-5.7,6.4-6.6,10.1-8.3c2.8-1.3,7.5-4,11,0.5
				c0.8,1.1,3.4,1.5,4.1,0.6c1-1.4,1-3.7-0.1-5.3c-1.6-2.5-3.5-4.7-5.2-7.1c-1-1.4-2.3-2.7-2.3-4.6c0-0.6-0.3-1.2-0.9-1.2
				c-0.6-0.1-0.9,0.5-1,1c-0.1,0.4-0.1,0.9-0.3,1.3c-0.6,1.3-0.2,3.3-2.4,3.4c-2.3,0.1-2.8-1.6-3.5-3.3c-1-2.6-2.8-4.9-2.4-8
				c0.1-0.9,0.7-3-1-2.7c-1.2,0.2-3.5,1.1-2.9,3.2c0.5,1.9,0.8,2.5-1.7,2.1c-2.4-0.5-4.9,3-4.4,5.6c0.5,2.3,0.8,4.2-1.3,6.2
				c-1.6,1.5-1,4.1,0.4,5.7c2,2.2,2.6,4.1,0.4,6.4c-0.6,0.6-0.9,1.1-0.3,2c2,3.4-1.2,3.3-2.9,3.7c-1.8,0.4-4.4,1.7-4.4-2
				c0-0.8,0-1.9-0.9-1.7c-2.6,0.6-2.8-2.8-5.1-2.7c-0.6,0-0.7-1-0.3-1.7c1.5-3-0.1-4.2-2.7-4.8c0-1.6-0.4-3.3,0.3-4.9
				c1.3-1.2,2.5-2.5,4.3-3c1.3,4.9,4.6,8,8.7,10.8c1.2-1.5,0.4-2.8-0.1-4.2c-0.7-2.2-2-4.5-1.3-6.9c0.9-3.2,1-4.8-0.4-7.6
				c-0.1,0.4-0.2,0.7-0.2,1c-0.1,5.3-0.9,6.2-5.8,6.8c-0.3,0-0.6,0.2-0.9,0.3c-0.2-6.1,3.6-10.3,6.9-15.2c0.7,1.9,0.5,3.6,1.9,5.4
				c-0.2-2.3,0.5-3.9,1.3-5.5c0.2-0.5,0.7-0.9,0.3-1.5c-0.5-0.6-1.2-0.3-1.8-0.2c-1.6,0.2-2.6,1.1-3.5,2.2c-1.4,1.8-3.1,3.4-4.5,5.1
				c-1.7,2.1-4.7,3.8-3.4,7.5c0.2,0.6-0.4,1.7-1.5,1.9c-1.4-2.4-2.9-4.8-4.3-7.2c2.2-0.1,5,0.1,5.6-2.4c0.7-3.1,2.5-5.5,3.5-8.4
				c2.4-7.3,6.1-9.3,13.8-8c2.3,0.4,4.5,1,6.7,1.6c3.9,1.1,7.6,0.8,11.2-0.9c3.1-1.4,6.1-2.4,9.5-1.8c0.9,0.2,1.8,0.1,2.1-1
				c6.4,2.6,11.8,6.5,16.6,11.4c0.3,1.4,0.5,2.7,1.9,3.5c4.9,2.8,8.1,7.5,11.4,11.8c0.9,1.2,1.9,2.5,0.6,4.4c-1,1.4-0.4,2.4,1.5,3
				c2,0.6,1,2.3,0.6,3.5c-0.3,0.8-0.8,2.3-1.9,1.4c-1.3-1.2-2.3-0.6-3.6-0.4c-5.7,1.1-5.7,1-8.7-3.9c-0.2-0.3-0.2-0.6-0.3-0.9
				c-0.1-0.4,1.4,0.3,0.5-0.6c-0.7-0.7-1.5-1.3-1.4-2.4c0.3-5.4-1.1-10.4-3.4-15.3c-2.3-5-5-9.4-10.7-11.4c1.5,1.6,0.7,3,0,4.4
				c-0.8,1.6-1.8,2.9-2.9,4.3c-0.8,1.1-1.3,2.5-3.2,1.1c-1-0.8-1.8,0.2-2,1.3c-0.6,2.7,1.4,3.2,3.2,3.5c2.7,0.5,6.2,0.1,4.1,4.7
				c-0.1,0.2,0,0.4,0.1,0.6c2.2,3.2,5,5.8,8.2,7.9c1.6,0.2,1.7,1.3,0.9,2.2c-0.8,0.9-2.2,1.4-2.9-0.2c-0.9-2-2.5-1.5-3.8-1.2
				c-1.4,0.3,0.1,1.4-0.2,2.1c0.1,1.7,2.7,2.1,2.3,4.3c-0.1,0.6,0.9,0.7,1.5,0.1c0.7-0.8,0.8-1.8,0.9-2.7c2.6,0.7,5.1,2.6,8.1,1.3
				c0.5-0.2,1.3,0.6,1,0.7c-1.8,1-1.1,4.4-4.4,3.9c1.3,0.7,1.3,3.3,2.6,2.6c2.3-1.1,3.5,0.7,5.3,1
				C965.6,1470,967.9,1472.5,970,1475.3z M940.9,1445.5c-0.8-1.1-0.5-3.5-3-3.3c-0.9,0.1-2.4,0.1-2.2,1.4c0.3,2.1,1.9,3,3.9,3.4
				C940.8,1447.2,941,1446.6,940.9,1445.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M942.7,1420.2
				c-0.3,1.1-1.2,1.1-2.1,1c-3.4-0.6-6.4,0.4-9.5,1.8c-3.6,1.6-7.4,2-11.2,0.9c-2.2-0.6-4.4-1.2-6.7-1.6c-7.7-1.3-11.4,0.7-13.8,8
				c-1,2.9-2.8,5.3-3.5,8.4c-0.5,2.5-3.4,2.3-5.6,2.4c-7.9-3.8-16.7-3.9-25.2-5.5c-2.8-0.5-4.5-2.5-6.7-3.7
				c-0.9-0.5-1.7-1.8-2.9-0.8c-1.2,1,0.2,1.6,0.7,2.4c0.6,1-0.2,1.4-1.1,1.4c-2.1,0-4.1-0.4-6.1-1.2c-1.5-0.6-0.9-1.9-0.7-2.8
				c1.2-3.9,0.1-6.6-3.3-9.2c-5-3.8-9.1-8.5-12.3-13.9c-1-1.7-1.8-3.5-2.2-5.4c-0.7-4.3-0.1-5.5,3.9-7c2.5-0.9,4.8-2,6.7-3.8
				c1.9-1.8,4.7-1.5,7-2.5c6.4-0.2,12.5,0.6,18.6,2.7c1.6,0.6,4.3-0.2,4.4-2.5c0.1-1.9,0.8-1.1,1.7-1c4.1,0.3,8,2.2,12.1,2.1
				c0.5,0.2,1,0.4,1.5,0.6c0.2,1.1,1.9,0.5,1.9,2c-1,0-1.9,0-2.8,0c-0.6,0-1.5-0.3-1.7,0.6c-0.2,1,0.7,1.1,1.4,1.4
				c8.8,3.3,17.6,6.8,26.4,10c3.6,1.3,6.8,3.7,10.8,4.2C929.4,1412.8,936,1416.5,942.7,1420.2z M859.1,1418.8
				c-0.9,0.1-1,0.9-1.1,1.7c-0.7,2.9,5.5,9.8,8.6,9.4c1.2-0.1,1.9-1,2.6-1.9c0.6-0.8-0.1-1.2-0.7-1.5c-0.9-0.4-1.4-2-3-0.6
				c-1.4,1.3-2,0.3-1.9-1.3C863.6,1423,860.4,1418.9,859.1,1418.8z M853.1,1406.3c1.6-3.1,2.5-5.8,2.2-8.9c-0.1-1.1-0.7-1.8-1.6-1.6
				c-1.2,0.2-1.1,1.3-0.5,2c0.9,1.3,0.3,2.5,0.1,3.7C853,1403,852.1,1404.3,853.1,1406.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M922.7,1409.1
				c-4-0.5-7.2-2.9-10.8-4.2c-8.8-3.2-17.6-6.7-26.4-10c-0.7-0.3-1.6-0.4-1.4-1.4c0.2-0.8,1-0.5,1.7-0.6c0.9,0,1.9,0,2.8,0
				c0-1.5-1.7-1-1.9-2c3.5-0.7,6.6,0.9,9.6,2.2c3.4,1.5,7,1.9,10.4,3.4c5.5,2.5,11.1,4.9,16.6,7.3c6,2.6,11.6,5.9,17.3,9
				c6.3,3.5,11.9,8,17.8,12.1c0.3,0.2,0.7,0.4,1,0.5c0.3-3.9,1.8-7.4,3.2-10.8c1.6-3.7,3.3-7.2,4-11.1c0.5-2.6,2-4.9,2.8-7.5
				c0.3-0.9,2.2-4-1.7-2.3c-0.2,0.1-0.7,0.1-0.8-0.1c-0.4-0.5-0.3-1,0.1-1.5c1-1.2,2.2-2.1,3.4-3c2.7-1.9,2.6-4.2,0.5-6.8
				c-4.4-5.6-10.8-8.2-16.6-11.7c-2-1.1-3.9-2.3-6-3.3c-4.1-2-7-0.4-8.2,4c-1,3.6-2.3,7.2-3.7,10.6c-1,2.4-2.6,3.7-5.3,1.5
				c-1.8-1.4-4-2.4-4.2-5.1c0-0.5-0.6-1.3-1.1-1.5c-2.5-1-4.5-2.3-4.6-5.4c0-0.5-0.6-0.9-1.2-0.9c-3.5,0-6.6-2.1-10.1-2
				c-1.9,0.1-2.8-1.5-4.3-2.1c-3.7-1.7-7.5-2.1-11.5-1.9c-1.2,0.1-3.1,0.2-3.4-0.3c-2.2-3.9-6.5-3.5-9.8-4.8
				c-2.7-1.1-5.7-1.4-8.4-2.7c-1-0.5-2.4-0.4-1.3-2.1c0.4-0.5,0.5-1-0.1-1.4c-3-2-1.5-4,0.1-5.9c0.5-0.6,0.9-1.3,1-2
				c1.1-4.8,5.1-5.7,9.1-6.6c0.6-0.1,1,0.2,1.5,0.5c3.7,2.4,8.1,2.8,12.3,4.1c1.7,0.5,3.7-0.3,5.5-0.4c1.7-0.1,2,0.5,2.8,1.3
				c3.8,3.5,8.9,4.6,13.5,6.3c9.9,3.7,19.9,7.1,29.3,11.9c12,6.2,23.6,13.1,32.9,23.2c4.2,4.5,6.7,9.7,8.2,15.4
				c0.8,2.9-0.1,5.8-1.1,8.6c-3.7,9.9-7.4,19.7-11.1,29.6c-0.8,2.1-0.7,3.8,0.5,5.8c1.1,1.8,3,3.7,1.7,6.3c-1-0.1-1.1-0.9-1.5-1.5
				c-2.3-3.8-4.7-7.4-8-10.4c-2.6-2.5-5.2-4.9-7.9-7.4c0.5-0.4,1.6,0.2,1.6-0.7c0-1.1-0.7-2.4-1.7-2.6c-4-0.6-5.9-4.2-8.6-6.3
				c-6.8-5.3-14.6-8.9-21.9-13.3c-1.8-1.1-3.8-0.9-5.6-1.5C922.4,1407.3,922.9,1408.5,922.7,1409.1z M906.3,1352.6
				c0.4-3-2.5-3.2-3.9-4.5c-0.4-0.4-1.3-0.7-1.8-0.2c-0.7,0.7,0,1.3,0.5,1.9c0.9,1.2,1.7,2.5,2.7,3.7c0.4,0.6,0.7,1.5,1.7,1.2
				C906.6,1354.5,906.1,1353.4,906.3,1352.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M800.6,1376.8
				c-1.6,0.3-2.7-1.1-4.1-1.2c-0.3-0.1-0.5-0.3-0.8-0.4c-0.9-1.9-1.4-4.1-3.4-5.3c-0.3-0.6-0.5-1.2-0.8-1.8c-0.1-1.7,0.2-2.8,2-1.1
				c1.2,1.1,2,2.7,4.3,3.8c-1.8-4.1-2.7-7.8-5.9-10.2c-0.4-1.2-0.3-2.3-0.2-3.5c2.3-0.6,2.9,1.3,3.9,2.6c0.8,1,1.4,2.2,3.4,2.6
				c-1.9-3.9-3.4-7.5-6.4-10.2c-1.5-1.3-1.5-2.8-0.5-4.4c3.8,1.3,5.7,4.8,8.9,8.2c-1.5-5.9-4.3-9.9-7.9-13.6
				c-1.2-0.6-0.6-1.7-0.8-2.5c-0.4-1.9,0.6-1.8,1.7-1.1c1.4,1,2.7,2.2,4,3.3c0,1.2,0.9,1.7,2,2.5c-0.1-0.8,0.6-1.5-0.5-2
				c-0.1-2.8-1.2-5-3.5-6.6c-1.6-1.1-2.9-2.5-1.2-4.6c3.6-0.2,4.4,3.6,7.1,5.1c-0.2-4-1.9-6.9-5.1-8.9c-0.6-1.5-1.9-2.7-2-4.4
				c0-0.6-0.3-1.4,0.3-1.8c0.7-0.4,1.2,0.2,1.7,0.6c1.4,1.3,2.8,2.6,4.5,3.7c-1.9-4.3-4.2-8.4-8.2-11.1c-1.3-0.9-2.4-1.7-3.4-2.8
				c-0.9-1.8-3.9-1.1-4.2-3.6c4.1,0.1,5.3,0.8,19.9,13c-2-9.3-8.9-14.5-15.1-20.1c4.8,0.8,9.5,4.5,16.5,13.1
				c-2.5-6.9-4.9-13-10.4-17.2c-0.6-0.5-1.1-0.8-0.6-1.5c0.5-0.7,1.2-0.2,1.7,0.1c2.8,1.6,5.1,3.8,7,6.3c0.5,0.6,0.6,1.4,1.9,1.3
				c-1.2-5.7-3.3-10.7-8.7-13.7c-0.4-0.2-0.8-0.4-1.1-0.6c-0.8-0.6-1.8-1.5-1.2-2.4c0.8-1.2,1.6,0.2,2.4,0.5
				c3.5,1.6,5.9,4.5,8.8,6.9c-1.2-6.9-6.2-11.3-10.4-16.3c5.5,1.9,9.2,6.2,12.9,10.4c-3.2-8.1-6.8-15.8-15.5-19.8
				c6.5,1.1,10.9,5.2,14.9,9.7c0.4,0.5,0.4,1.4,1.5,1.2c-0.4-0.6-0.8-1.1-1.1-1.6c-0.4-3.7-2.3-6.8-4-10c-0.9-1.8-2.9-2.6-4.9-2.9
				c-2-0.3-3.7-1.3-5.2-2.6c-0.7-0.5-1.6-1.2-1.1-2.2c0.5-1,1.6-0.5,2.4-0.2c4.1,1.7,8,3.8,10.9,7.4c1.2,1.6,2.4,3.2,3.6,4.8
				c0-2.4-0.9-4.2-2.5-5.7c-0.5-6.7-7.5-8.7-10.5-14.4c5.8,2.2,9.6,6,13.3,10.1c-1.3-4.7-3.1-9-6.8-12.3l0,0
				c-0.4-1.3-2.2-1.9-1.7-3.9c4.3,2,7.1,5.5,10.1,8.9c-1.7-6-4.4-11.4-9.6-15.8c3.5,0.1,3.7,0.3,10.8,8c-2.7-7.5-7-13.9-14-18
				c0.6-1.3,1.6-0.6,2.1-1.1c4.4,2.1,8.2,4.9,11.3,8.7c-0.3-2.1-0.7-4-2-5.7c-2.4-3.3-5.4-6.1-7.1-9.8c0.6-0.1,1.1-0.2,1.7-0.2
				c3.1,2.5,6.4,4.8,8.8,8.7c0.4-3.1-0.7-5-2.1-6.7c-2.9-3.5-5.8-6.9-8.8-10.4c0.2-0.2,0.4-0.4,0.6-0.6c4,1.7,7.3,4.4,10.1,7.8
				c0.1-3.6-2.9-5.3-4.2-8c-0.8-1.7-2.3-2.8-4.5-2.5c-1.9,0.3-3.4-0.7-4.4-2.2c-1.1-1.7-2.7-3-4.1-4.6c-0.3-1.5,0.6-1.6,1.7-1.7
				c1.5,0.9,2.3,2.9,4.2,3c3.1,0.1,5.2,2,7.5,3.5c2.3,1.5,3.7,3.9,5.7,5.8c-0.5-2-0.3-4-1.7-5.8c-2.1-2.6-3.8-5.4-7.2-6.6
				c-1.4-0.5-2.7-2.3-1.8-3.7c0.8-1.1,2.6-0.1,3.7,0.7c3.1,2,5.6,4.7,7.9,7.6c-0.8-6.1-5.9-10.1-8.4-16c4.1,1.3,5.6,4.5,8.7,7
				c-1.9-6.1-5.5-10.4-8.1-15.3c4,1.8,6.8,4.7,9.4,8.3c0.4-2.9-1.2-4.7-2.2-6.7c-2-4.1-6.1-6.4-8.6-10c3.2-0.5,4.7,2.1,6.8,3.6
				c2.2,1.6,3.7,3.9,5.4,6.1c-1.1-6.6-8-10.2-8.8-17.1c2,0.3,3,2.3,4.6,3.3c1.7,1,2,3.3,4.6,4.5c-1.5-4.7-4.5-8.3-4.6-13.1
				c2.3,1.1,3,3.4,4.9,4.9c0.6-4.4-3.7-7.3-2.9-11.9c2.9,2.3,4.4,5,5.5,8.1c1.1,4.9-0.7,9.7-0.8,14.5c0,5-1.3,9.8-1.8,14.8
				c-0.3,3.5-0.4,7-1,10.6c-0.7,3.8-1,7.8-1.4,11.8c-0.4,4.2-0.8,8.3-1.2,12.5c-0.4,4-1,7.9-1.1,11.9c-0.1,3.9-0.7,7.7-1.1,11.6
				c-0.4,4.4-0.9,8.8-1.3,13.2c-0.4,3.7-0.8,7.4-1.2,11.1c-0.5,4.6-0.6,9.3-1.4,13.8c-0.8,4.4-0.3,8.8-1.4,13.1
				c-0.8,3.3-0.8,6.7-1,10c-0.4,6.9-1.3,13.8-2,20.6c-0.4,4.2-0.9,8.6-1.7,12.8c-0.7,3.8-1.2,7.7-1.6,11.5
				c-0.5,4.6-0.8,9.2-1.9,13.7C801.1,1371,801.5,1374,800.6,1376.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M311,1321.3
				c3.8-3.7,8.7-6,12.9-9.3c3.7-3,6-6.8,7.6-11.1c0.7-1.9-0.1-2.8-2.1-2.2c-3,0.9-6,1.9-9.1,2.8c-0.2-1.2,0.7-1.9,1.2-2.2
				c4.2-2,6.9-5.8,10.7-8.4c0.4-0.3,0.9-0.7,1.3-1c1.3-1,1-2.4,0.8-3.6c-0.3-1.3-1.8-0.9-2.8-0.8c-5.5,0.9-10.8,2.6-16.1,4.4
				c-1,0.3-1.9,1-3.9,0.6c2.8-1.7,5.1-3.2,7.4-4.6c0.5,1.7,1.6,1,2.8,0.9c4.1-0.3,5.6-5,10.3-5.1c-2.3-1.5-4-2.7-5.7-0.5
				c-0.2,0.3-0.8,0.3-1.1-0.2c0.9-1.8,3.3-2.2,3.6-4.7c0.1-1.1,4.2,0.7,4.9,2.7c0.2,0.6-1.9,1.8,0.7,1.7c3.3,0,6.5,0.8,9.9,0.6l0,0
				c1.4,1.7,1.1,5.8,5.3,3.9c2.1-0.5,3,0.3,2.9,2.5c-0.1,1.7-0.7,3.8,1.5,5.1c0.1-0.7,0.2-1.2,0.3-1.7c0.8,1,0.4,2.1,0.4,3.1
				c0.1,1.9,1.1,1.5,2.2,0.8c0.4,0.3-0.1,1.3,0.9,1.1c0.3,1,0.8,1.9,2.1,1.3c2-1,4.3-0.6,6.3-1.2c1.5-0.4,4,0.7,4.5-2
				c0.9,0.1,1.7,0.1,2.6,0.2c-1.6,2.2-3.5,3.3-6.4,3.5c-2.5,0.2-5,1.5-7.4,2c-10.8,2.4-19.8,9.2-30.3,12.2c-0.7,0.2-1,0.6-1.4,1.2
				c-0.8,1.1-1.8,2-3.2,0.8c-0.8-0.7-1.8-0.2-1.6,0.5c0.9,2.6-0.3,2.1-1.9,1.6c-1.8-0.5-3,0.8-3.9,1.8
				C315.2,1320.3,313.2,1320.9,311,1321.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M373,1294.7
				c-0.9-0.1-1.7-0.1-2.6-0.2c-1.8-2.8-4.6-4.6-6.6-7.1c-1.5-1.8-2.4-1.3-3.1,0.8c-0.9,2.8-2.1,5.5-3.2,8.2c-1,0.2-0.6-0.8-0.9-1.1
				c0.8-2,1.4-4.2,2.5-6c2.3-3.6,1.6-6.2-1.9-8.4c-0.1-0.6-1.5-0.4-1-1.2c0.4-0.8,1-1.7,2.2-1.3c-0.2,2,1.2,3.3,2.7,3.5
				c1.8,0.3,1.3-1.9,2-2.9c0.9-1.4,0.9-3.3,2.2-4.6c0.5,0.7,0.5,1.3,0.1,1.8c-4.2,6.2-1.5,10.7,3.7,14.5c0.2,0.1,0.3,0.3,0.4,0.5
				c0.4,0.5,0.8,1.2,1.5,0.9c0.9-0.4,0.5-1.3,0.4-2c-0.9-4.3-2-8.5-2.6-12.8c-0.4-3.3-1.7-4.6-5-3.7c0-0.5,0-0.9,0-1.4
				c1-0.5,1.9-1.2,2.4-2.1c0.3-0.5,0.7-1.2-0.3-1.5c-2.1-0.7-3.4-2.3-5-3.6c-1.6-1.2-2.4-0.9-3.1,0.9c-1,2.3-2.1,4.5-3.1,6.8
				c-2.7-0.6-3.9-2.2-3.5-5.1c0.2-1.4,0.7-3.2-0.6-4.5c-0.4-0.1-0.8-0.1-1.2-0.2c0-0.4,0-0.8,0-1.1c0.5-0.4,1-0.7,1.5-1.1
				c3.6-0.7,0.7,2.4,1.8,3.1c0.2,1.7,0.3,3.4,0.5,5.1c0.1,0.5-0.1,1.2,0.6,1.3c1,0.2,0.9-0.7,1.2-1.2c0.9-1.5,0.9-3.5,2.2-4.9
				c1.1-1.2,0.7-2.3-0.7-3.1c-0.9-0.6-1.7-1.2-2.6-1.8c0.7-1.4,2.2-1.9,3.4-2.7c-0.6,1.1-1.1,2.2,0.3,3.1c0.6,0.4,1.5,0.8,2-0.1
				c0.5-1.1,1.9-2.3,1.2-3.5c-0.7-1.2-1.9,0.1-2.9,0.4c-0.2,0.1-0.4,0.1-0.6,0.2c0.6-0.5,1.6-1.1,0.9-2c-0.5-0.7-1.5-0.3-2.1,0.1
				c-1.9,1.4-3.6,2.9-5.4,4.3c-0.9-0.1-1.3-0.7-1.3-1.5c0.1-1.5-0.7-2.3-2.1-2.2c-1.9,0-1.6,1.8-1.3,2.7c0.6,2,0,3.4-1,5
				c-1.2,0.1-2.5,0-3,1.4c-0.4,0-0.7,0-1.1,0c0-0.6-0.3-1-0.8-1.3c-1.3-1.4-2-4.3-5-2.1c1.3-1.5,1.4-3.6,3-4.8
				c0,0.7,0.1,1.4,0.5,1.9c0.2,1.5,1.5,2.3,2.3,3.3c0.4,0.5,0.5,1.3,1.4,1.1c0.7-0.2,1.3-0.7,1.4-1.6c0.1-0.7,0-1.5,0.1-2.2
				c0-0.5,0.1-1,0.1-1.5c0.2-0.9,0-1.7-0.6-2.5c-0.6-2,2.4-4.7-1.2-6c-2.6-0.7-4.5,1.3-6.7,2c-1.1,0.3-0.7,1.4,0.6,1.5
				c0.1,0.3,0.2,0.6,0.3,1c-4.6-0.9-0.1,2.7-1.8,3.3c-2.7-1.4-3-5-6.3-7.1c3-0.1,4.8-0.8,6.2-2.7c2.3-3.1,2.3-3.9-1.1-6
				c-1.8-1.1-4-1.8-5.7-3.1c-0.8-0.7-3.5-0.7-2.2-3c1.1-2,2.7-1.6,4.2-0.6c1.4,1,2.9,1.3,4.6,1.1c1.4,0.6,2.9,1.2,4.6,2
				c-1.4,0.6-1.3,1.9-2,2.6c-0.7,0.6-0.9,1.3-0.1,1.9c0.7,0.5,1.5,0.3,2-0.4c0.5-0.8,1-1.7,1.4-2.5c0.5-1.1,1.1-1.2,1.9-0.3
				c-1.9,1.1-1.2,2.3-0.2,3.5c1.8,3.2,4.8,4.2,8.2,4.3c6.2,0.4,11.7,3.8,18,3.3c0.3,0.8-0.3,1-0.7,1.3c-1.8,1.4-2.4,2.6-1.9,5.4
				c2.4,12.1,5.3,24.1,7.5,36.2C373,1293.2,373,1294,373,1294.7z M348.6,1251.4c2-0.1,2.3-1.3,1.7-2.8c-0.4-1.1-1.6-1.6-2.4-1.1
				c-1.5,1-0.4,2.2,0.4,3.2C348.4,1250.9,348.5,1251.2,348.6,1251.4c-0.1,0.2-0.3,0.4-0.4,0.4c-0.7-0.1-1.6-1.2-2.1,0.2
				c-0.3,0.9-0.1,2,0.9,2.6c0.7,0.4,1.6,0.6,2.2,0.1c1-0.9-0.1-1.5-0.5-2.2C348.4,1252.1,348.1,1251.8,348.6,1251.4z M366.3,1265.2
				c-0.6-2.2-1.2-4.6-1.7-7c-0.3-1,0.5-2.8-1.2-2.9c-1.5-0.1-1.2,1.8-1.7,2.7c-0.4,0.8-0.4,1.8-0.9,2.4c-1,1.2-0.5,1.9,0.4,2.7
				c1.1,1,2.2,2,3.3,3.1c0.4,0.4,0.8,0.8,1.4,0.6C366.5,1266.5,366.3,1265.9,366.3,1265.2z M362.1,1250.9c-0.1-0.8-0.9-0.9-1.4-1
				c-1.2-0.3-2.5-0.6-3.8-0.8c-1.1-0.2-2.5-0.6-3,1c-0.4,1.2-1.8,2.4-1,3.6c1,1.4,2.3,0,3.5-0.2c1.5-0.3,2.9-0.8,4.4-1.2
				C361.4,1252,362,1251.7,362.1,1250.9z M341.5,1246.7c-0.2-1.4-1-2.1-2-2.3c-1.1-0.2-2.5-1-3.2,0.9c-0.4,1.1-1.9,1.9-1.4,3.3
				c0.2,0.6,1.4,0.4,2.1,0.1C338.6,1248.1,340.1,1247.4,341.5,1246.7z M361.2,1253.4c0-0.5-0.2-0.8-0.7-0.8
				c-0.9,0.1-1.6,0.6-1.7,1.5c-0.1,0.6,0.4,0.6,0.8,0.6C360.4,1254.7,360.9,1254.1,361.2,1253.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960,1432.1
				c2.6,2.5,5.2,4.9,7.9,7.4c3.3,3,5.7,6.7,8,10.4c0.4,0.6,0.5,1.5,1.5,1.5c0.6,9.2-1.9,12.1-12.7,15c-1.7-0.3-3-2.1-5.3-1
				c-1.3,0.6-1.2-1.9-2.6-2.6c3.3,0.6,2.7-2.9,4.4-3.9c0.3-0.2-0.5-1-1-0.7c-3,1.3-5.4-0.7-8.1-1.3c-1.5-0.6-3.1-1.1-4.6-1.7
				c0.3-0.7-1.3-1.8,0.2-2.1c1.3-0.3,2.9-0.8,3.8,1.2c0.7,1.6,2,1.1,2.9,0.2c0.9-0.9,0.8-2-0.9-2.2c-0.2-1.9,0.6-2.8,2.5-2.6
				c0,0.7,0.3,1,1,1.1c0.5,2,2.1,2.8,3.9,3.4c0.1,0.3,0.1,0.6,0.3,0.9c3,4.9,3,4.9,8.7,3.9c1.3-0.2,2.2-0.8,3.6,0.4
				c1.1,0.9,1.6-0.6,1.9-1.4c0.4-1.2,1.4-2.9-0.6-3.5c-1.9-0.6-2.5-1.6-1.5-3c1.3-1.8,0.3-3.2-0.6-4.4c-3.3-4.4-6.5-9-11.4-11.8
				c-1.4-0.8-1.6-2.2-1.9-3.5C959.5,1431.8,959.8,1432,960,1432.1z M972.2,1460.8l0.1-0.2l-0.2,0L972.2,1460.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M885.1,1390.3
				c-4.2,0.1-8-1.7-12.1-2.1c-0.9-0.1-1.5-0.9-1.7,1c-0.2,2.3-2.9,3.1-4.4,2.5c-6-2.2-12.2-2.9-18.6-2.7c0.4-3.8,2.1-5.3,5.4-4.4
				c2.4,0.6,4.8,0.6,7.1,0.2c2.9-0.5,5.5,0.2,8.3,0.9c4.6,1.1,9.3,2.2,13.9,3.2C884,1389.1,885.1,1388.8,885.1,1390.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M894.6,1448.2
				c1.1-0.2,1.7-1.4,1.5-1.9c-1.3-3.7,1.7-5.3,3.4-7.5c1.4-1.7,3.1-3.3,4.5-5.1c0.9-1.1,1.9-2.1,3.5-2.2c0.6-0.1,1.3-0.4,1.8,0.2
				c0.4,0.5-0.1,1-0.3,1.5c-0.8,1.6-1.5,3.2-1.3,5.5c-1.5-1.8-1.2-3.5-1.9-5.4c-3.4,4.9-7.1,9.1-6.9,15.2l0-0.1
				c-1.8,0.5-3.1,1.7-4.3,3C894.6,1450.4,894.6,1449.3,894.6,1448.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M341.3,1267.8
				c0.3-0.8,0.5-1.6,0.8-2.4c1.4-0.9,2.9-0.8,4.4-0.8c-0.4,0.6-0.2,1.3,0,1.9l-0.1,0.2l0.2,0.2c-2.1,0.4-1.2,1.5-0.6,2.5
				c0.2,0.4,1.2,1,0.2,1.4c-1.9,0.7-0.6,2.2-1,3.3c-0.8,1.4,0.1,3.2-1.1,4.5c-2.1-2.4-1.7-5.2-0.9-7.9
				C343.6,1269.2,343.8,1267.6,341.3,1267.8L341.3,1267.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M312.1,1256.7
				c-1.3-4.5-1.3-4.5-6.4-3c-2.8,0.9-5.6,1.8-8.9,2.9c1.1-2.1,2.5-2.9,3.9-3.7c0.7,2.1,2.1,0.8,3.2,0.5c2.7-0.7,5-2.6,8-2
				c1.4,0.3,4.8-0.3,1.7,2.9C312.9,1254.8,312.8,1256,312.1,1256.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M317.1,1276
				c-3.4,2-7.3,2.3-11.2,4.4c3.1-4.1,7.4-4.8,11.3-6.3C317.2,1274.7,317.2,1275.3,317.1,1276z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M311.8,1264.3
				c-3.7,2.1-7.8,3-11.8,4.3c1.5-2,3.5-3.1,5.6-4.1c1,0.3,1.9,0.7,2.9-0.2C309.4,1263.6,310.7,1263.5,311.8,1264.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M342.1,1248.8
				c3.6,1.4,0.6,4.1,1.2,6c-2.9-0.7-4,1-4.6,3.4c-0.5-0.6-0.6-1.2-0.5-1.9c1-1.9,0.2-2.8-1.8-3c-0.1-0.3-0.2-0.6-0.3-1
				C339.4,1253.6,341.1,1251.8,342.1,1248.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M324.8,1264.5
				c0.3-3-1.7-2.4-3.1-1.6c-2.9,1.7-5.8,1.5-8.8,1.1c0.5-0.8,1.3-0.7,2-0.7c2.5,0.2,4.9-0.1,7.1-1.6c1.4-0.9,2.9-0.6,3.8,0.5
				C326.7,1263.2,326.8,1264.5,324.8,1264.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M338.1,1277.1
				c-0.5-1.7-2.2-2-3.4-2.8c0.4-1.6,1.5-2,3-1.5c1.2,0.4,2.5,1,2.7,2.1C340.5,1275.9,339.5,1276.9,338.1,1277.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M373.1,1248.7
				c0.2-0.1,0.4-0.1,0.5,0.1c-1,2.6-0.8,6.3-5.6,5.5C368.7,1251.5,372.3,1251.3,373.1,1248.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M344.1,1278.7
				c1.2-1.3,0.3-3.1,1.1-4.5c0.1,0.3,0.1,0.6,0.2,1c0.2,1.4-0.7,3.2,0.3,4.1c1.3,1.1,2.3-1.2,3.7-1.3c0.5,0,0.8-0.1,1.2,0.3
				c-2,1.5-3.7,3.6-6.6,3.2l0,0C344,1280.7,344.1,1279.7,344.1,1278.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M346.5,1264.6
				c-1.5-0.1-3-0.1-4.4,0.8c-0.1-0.3-0.1-0.6-0.2-0.9c0.5-1.5,1.8-1.3,3-1.4c1.8,0.9,2.8-1,4.4-1.1c0,0.4,0,0.8,0,1.1
				C348.6,1264.1,347.7,1264.6,346.5,1264.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M439.3,1255.5
				c-0.9,1.1-0.4,3.6-3.3,2.8c-1.2-0.3-0.4,1.5-0.5,2.3c-1.7-1.3-1.3-2.5,0.3-3.6C437,1256.4,437.9,1255.4,439.3,1255.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M331.6,1274.3
				c-2.7-1.5-5.4,1-8.1,0c2-1.1,4.2-0.9,6.4-1.3C330.7,1272.8,331.5,1273.2,331.6,1274.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M300.7,1252.8
				c0.7-1.6,1.9-2.7,3.4-3.5C303.7,1251.2,302.6,1252.4,300.7,1252.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M892.3,1458.3
				c-0.2,2.2-0.9,2.5-3.3,1.2C889.9,1458.4,890.9,1457.9,892.3,1458.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M307.2,1324.1
				c0.7-1.3,1.8-2.2,3.3-2.5C310.1,1323.3,308.7,1323.8,307.2,1324.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M322.3,1274.7
				c-1.3,0.8-2.6,1.3-4.1,1C319.4,1274.7,320.8,1274.3,322.3,1274.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M322,1270
				c-0.1-0.8,0.4-1.1,1.1-1.2C323.1,1269.5,322.7,1269.9,322,1270z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M341.3,1267.8
				c0.4,0.7,0,1.2-0.6,1.4c-0.1,0-0.3-0.2-0.4-0.3C340.6,1268.5,341,1268.2,341.3,1267.8C341.3,1267.8,341.3,1267.8,341.3,1267.8z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M441.3,1254.7
				c-0.4,0.9-1.2,0.8-2,0.7C439.8,1254.5,440.6,1254.7,441.3,1254.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M340.1,1263.2
				c0.6,0.2,0.8,0.7,0.8,1.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1-0.1-0.2-0.2-0.3-0.3C340,1264.1,340.1,1263.6,340.1,1263.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M441.4,1252.7
				c0.9,0.4,0.8,1,0.3,1.6C441.6,1253.8,441.5,1253.3,441.4,1252.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M332.4,1274.8
				c-0.5,0.3-0.7,0.1-0.8-0.4C331.9,1274.5,332.1,1274.6,332.4,1274.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M306.5,1263.8
				c0.1-0.4,0.3-0.6,0.7-0.6C307.1,1263.7,306.9,1263.9,306.5,1263.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M340.3,1264.8
				c-0.2,0.2-0.4,0.5-0.7,0.1c0,0,0.3-0.3,0.4-0.4C340.1,1264.6,340.2,1264.7,340.3,1264.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M930,1333.1
				c0.2,0.3,0.3,0.6,0.5,0.9c0,0-0.3,0.1-0.3,0.1s-0.3,0-0.3,0c0,0-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.4
				C929.7,1333.4,929.8,1333.3,930,1333.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M159.3,1251.6
				c-2.1,2.3-4.8,3.7-7.7,4.7c-0.1-0.6-0.1-1.1-0.2-1.7c2.7-0.9,4.4-2.3,4.7-5.6c1-11.8,2-23.6,4-35.3c0.1-0.7,0.1-1.5,0.8-2
				c1.3,1.2-1.3,3.4,1.3,4.2c-1.1,10.5-2.1,21.1-3.2,31.6C158.8,1248.9,158.5,1250.3,159.3,1251.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M975.2,2026.8
				c0-0.7,0-1.5,0-2.2c4.7,0.3,4.7,0.3,4.5-5.7c0.5-0.1,1-0.2,1.5-0.3c1.5,3.6,0.6,7.4,0.8,11.1c0.1,3.8-1,7.7-0.3,11.3
				c1,5.6,0,11.2,0.2,16.7c0,0.7-0.2,1.5-0.4,2.2c-0.6-0.1-1.2-0.2-1.8-0.3c-0.1-4,0.6-8-2.4-11.7c-1.5-1.8-0.9-5.2-0.4-7.8
				C977.6,2035.4,977,2031.1,975.2,2026.8z M979.3,2048.3c0-1.3,0-2.6,0-3.8C978.4,2045.8,978.1,2047,979.3,2048.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M979.8,1945
				c0.1-0.4,0.4-0.5,0.8-0.4c1.9,3.1,0,6.1-0.3,9.1c-1.3,2.2-0.4,4.7-0.6,7c-0.1,1.1,0.7,2.7-0.6,3.2c-0.9,0.3-2.2-0.6-2.8-1.7
				c-0.2-4.8-0.6-9.7,2.4-13.9C979.5,1947.3,979.5,1946.2,979.8,1945z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M981.1,2018.6
				c-0.5,0.1-1,0.2-1.5,0.3c-2.3,0-4.7,0-7,0c0-0.5-0.1-1-0.1-1.5c1.2-0.8,2.5-1.6,3.7-2.5c1.5,0.6,2-0.2,2.2-1.5
				c0.2-0.1,0.4-0.2,0.6-0.2c0.6,0.1,1.2,0.2,1.8,0.3C981.3,2015.1,981.2,2016.9,981.1,2018.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M980.8,2013.4
				c-0.6-0.1-1.2-0.2-1.8-0.3c1.4-2.4,0.1-5.1,0.8-7.6c0.1-0.6,0.4-0.8,1-0.8C981.5,2007.6,981.7,2010.5,980.8,2013.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M230.3,1280.9
				c-3.2,0.5-3.9,3.1-5,5.5c-2.5,1.3-3.5,4.3-5.9,5.7c-2.3,0.7-2,2.7-1.8,4.3c0.4,4.2,0.6,8.4,0.7,12.7c0,5.2,0.1,10.4,0.5,15.7
				c0.1,1.7,1.1,4.7-2.6,4.9c-0.6,0-0.5,1.1-0.1,1.7c1.8,3.3,0.1,6.5-0.3,9.7c-0.2,2.2-1.5,3.8-4.4,3.2c1,1.5,2.2,2.5,2.4,3.9
				c-0.1,0.6-0.6,0.7-1.1,0.7c-6.2-6.8-12.5-13.7-18.7-20.5c-0.4-0.5-0.9-1-1.3-1.5c-0.4-0.6-0.9-1.4,0-2c0.7-0.4,1.3,0.1,1.6,0.7
				c3.3,4.9,8,8.6,11.5,13.4c1.2,1.7,2.6,3.2,4.3,4.4c1.4-2.2,1-4.6,0.8-6.7c-1-9.9,0.1-20-1.2-29.9c-0.2-1.6,0.6-2.6,1.6-3.7
				c1.8-1.8,3.4-3.8,5.1-5.7c-1-1.1-1.7-0.3-2.4-0.3c-1.7,0-1.9-0.7-1-2c1-1.4,2.1-2.7,3.3-4.3c-3.3-0.2-4.7,1.2-5.1,4
				c-0.1,0.6,0,1.5-0.8,1.5c-1,0-0.7-0.9-0.7-1.5c0-4.7,0.1-9.3,0.2-14c0-0.7-0.2-1.6,0.8-1.9c1.1-0.3,1.7,0.4,1.9,1.3
				c0.5,2.2,2.7,3.7,2.5,6.3c1.8-0.3,2.5-2,3.9-2.5c2.7,0,3.2-1.8,3.3-4c1.5-1.6,3.1-3.2,4.6-4.7c0.3,0,0.6,0.1,0.9,0.1
				c0.2,0.2,0.5,0.6,0.7,0.6c1.4-0.4,2.9,0.3,2.9,1.4C231.4,1278.8,230.2,1279.5,230.3,1280.9z M216.2,1325.1
				c-0.6,1.1-0.7,2,0.6,2.7C217.4,1326.7,217.4,1325.8,216.2,1325.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M157.3,1298
				c0.1-0.6,0-1.4,0.4-1.9c3.4-4.2,5.4-9,6.8-14.2c0-0.1,0-0.3,0.1-0.3c4.2-3.5,3.3-9.4,6.4-13.5c1.1-1.5,0.9-3.5,1.5-5.3
				c0.3-0.9,0.4-1.8,1.6-1.6c1.4,0.2,1.3,1.4,0.9,2.3c-0.8,2.2-1.6,4.3-2.5,6.4c-5.1,11.6-10.2,23.3-15.3,34.9c-2.2,5-4.4,10-6.6,15
				c-0.9,2.1-1.5,4.6-3.8,5.8c-0.4,0.2-1.1,1-1,1.2c1,3-1.3,3.6-3.4,4.6c0.4-2.8,2.3-4.8,3.2-7.2c0.5-1.3,1.4-2.6-0.6-3.4
				c-0.2-0.6-0.4-1.1-0.5-1.7c2.2,0.4,2.7-0.7,2.3-2.6c0.1-0.4,0.4-0.6,0.8-0.5c2.6-1.2,2.9-3.8,3.9-6c0.2-0.5,0-1.4,0.2-1.6
				C156.3,1306.5,154,1300.7,157.3,1298c0.1,0,0.2,0,0.2,0.1C157.4,1298,157.3,1298,157.3,1298z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M284.7,1338.4
				c0.3,0,0.5,0.1,0.8,0.1c-0.1,2.2-2,3.1-3.4,2.6c-3.3-1.1-5.8,0.9-8.4,1.9c-3,1.1-5.8,1.3-8.5,0.5c-6.3-1.7-12.7-2.4-19.1-3.5
				l-0.1-0.1c3.2-3.3,7.6-2.8,11.6-3c8.2-0.4,16.3,1.1,24.3,2.3C283,1339.3,283.8,1338.7,284.7,1338.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M345.9,1636.9
				c0.7-3.3,3-5.5,5.5-7.3c4.6-3.4,9.5-6.6,14.2-9.9c3.9-2.7,3.9-2.8,8.7-0.5c-3.8,2.8-7.5,5.3-11.1,8.1c-4.8,3.8-10,6.6-15.2,9.7
				C347.2,1637.3,346.5,1637.3,345.9,1636.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M213.8,1348.3
				c-0.2-1.5-1.3-2.4-2.4-3.9c2.9,0.6,4.1-1,4.4-3.2c0.3-3.2,2.1-6.5,0.3-9.7c-0.3-0.6-0.4-1.7,0.1-1.7c3.6-0.3,2.7-3.2,2.6-4.9
				c-0.4-5.2-0.4-10.4-0.5-15.7c0-4.2-0.3-8.5-0.7-12.7c-0.1-1.6-0.5-3.6,1.8-4.3c-0.8,7.3,0.2,14.5,0.2,21.8
				c0,9.2,0.5,18.4,0.7,27.6C220.5,1346.4,219.2,1347.7,213.8,1348.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M165.3,1367.4
				c-5.1-6.8-8.6-14.6-13.1-21.8c-1.5-2.4-2.8-4.9-4.1-7.5c-0.9-1.8-1.8-3.8,0.8-5.2c0.8,4.1,4.1,6.7,6,10.2c3.8,7.1,8,14,12.3,20.8
				C168.8,1366.7,167.9,1367.3,165.3,1367.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M360.1,1638.7
				C360.1,1638.7,360.1,1638.7,360.1,1638.7c0.5,2.1,2.4,2.1,3.6,1.5c1.8-1,2.6-3,2-5.2c1.2-2.6,3.3-3.9,6.1-4.1
				c-0.7,2.7-2.4,5.1-3.6,7.6c-0.9,1.7-0.7,2.9,1.6,3.2c4.1,0.6,8.1,1.4,12.2,1.9c3.7,0.4,7.3,1.7,11,1.3c2-0.2,4.5-0.6,3.7-3.8
				c1.2-0.4,0.9-1.3,0.7-2.1c0.9,0.3,1.7,0.5,2.6,0c0.2,0.6,0.5,1.3,0.7,1.9c-2.2,1.2-3.2,2.7-1,4.8c-7.2,1.1-14.1,0-21-1.8
				c-2.6-0.7-5.4-0.3-8.1-0.9c-2.8-0.7-5.4-2.3-8.6-1.7C360.8,1641.4,359.2,1640.6,360.1,1638.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M141.5,1377.7
				c1-11,2.2-22.1,4.1-33.3c1.7,0.9,1.7,2.4,2.6,3.3c-1.2,3.9-1.2,7.9-1.8,11.9c-0.7,4.8-1.1,9.7-1.5,14.6
				C144.6,1376.2,143.8,1377.6,141.5,1377.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M391.1,1616.8
				c0-2.3,2-3.2,3.5-4.2c2.2-1.6,4.5-3,6.8-4.4c3.1-1.9,6.7-1.3,10-2.3c0.6-0.2,0.6,0.8,0.8,1.2c-4.6,3-9.8,4.6-14.6,7.2
				c-1.1,0.6-2.5,0.9-2.9,2.4c-0.7,0-1.3,0.1-2,0.1C392.1,1616.8,391.6,1616.8,391.1,1616.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M275.4,1628.8
				c-0.3-3.8-1.9-7.8,1.8-10.9c0.1-0.1,0.4-0.1,0.4-0.1c2.1,3.8,5.4,7.1,5.4,11.9C280.5,1629.5,277.9,1629.7,275.4,1628.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M402.4,1647
				c4.7,0.7,9.4,1.2,14.1,2.1c6.9,1.4,13.9,1.1,20.8,1.6c2.7,0.2,4.8,1,7,3.9c-8.4-4.7-17-1.9-25-3.8c-5.6-1.3-11.4-1.8-17.1-3.1
				C402.3,1647.5,402.3,1647.2,402.4,1647z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M392.7,1616.8
				c0.7,0,1.3-0.1,2-0.1c1.7,7.4,3.5,14.9,5.2,22.3c-0.9,0.5-1.7,0.3-2.6,0l0,0.1C397.1,1631.3,394.8,1624,392.7,1616.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M442,1616.6
				c2.3-2.5,4.6-5,6.9-7.6c0.5-0.6,1.9-0.8,1.4-1.9c-0.4-1-1.5-1.2-2.5-1.4c-1-0.2-2.2,0.2-3.2-0.5c1.3-1.2,1.3-1.2,4.6-0.4
				c1.6,0.4,3,1.5,4.9,0.8C450.8,1610.2,446.8,1613.8,442,1616.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M422.3,1603.2
				c1.1-1.1,2.6-1,3.9-1.3c1.9,1.6,4.4,1.6,6.3,1.3c3.8-0.5,6.9,1.8,10.5,1.7C436.2,1604.4,429.2,1604.9,422.3,1603.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M445.4,1602.1
				c2.6-1.7,6.5-1.3,8.5,0.8C450.9,1603.7,448.2,1602.5,445.4,1602.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M458.2,1603.3
				c2.7-1.8,5.9-1.6,7.3,0.4C463,1604.1,460.5,1604.8,458.2,1603.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M493.9,1606.9
				c-3.7,0.3-7.2-0.8-10.9-0.7C486.7,1605.9,490.5,1604.3,493.9,1606.9C493.9,1606.9,493.9,1606.9,493.9,1606.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M399.6,1645.6
				c-2.2-2.1-1.2-3.6,1-4.8c0.2,1.2,0.4,2.5,0.6,3.7C401.3,1645.9,400.6,1646,399.6,1645.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M315.8,1633.2
				c0-0.2,0-0.5,0-0.7c1-0.3,2.1-0.6,3.1-0.9c0.1,0.1,0.2,0.3,0.4,0.4c-0.1,0.8-0.2,1.6-0.2,2.4l0,0
				C317.9,1634.1,316.7,1634.1,315.8,1633.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M352.4,1637.9
				c0.8-0.2,1.6-0.5,2.2,0.3C353.7,1639.3,353,1639.2,352.4,1637.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M322.8,1628.9
				c0.3-0.6,0.8-1,1.5-1l0,0.1c0.1,0.9-0.5,1.2-1.2,1.4C323,1629.1,322.9,1629,322.8,1628.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M321.9,1630.1
				c-0.3,0.7-0.8,1-1.5,1.1c-0.1-0.1-0.2-0.2-0.2-0.3c0.2-0.7,0.7-1,1.4-1.2C321.7,1629.8,321.8,1630,321.9,1630.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M343.4,1637.4
				c0.1-0.3,0.2-0.6,0.3-0.7c0.4-0.2,0.8,0,0.9,0.5C344.4,1638,344,1638,343.4,1637.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M319,1634.4
				c0.3,0.1,0.6,0.2,0.9,0.2C319.4,1635.2,319.3,1634.7,319,1634.4L319,1634.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M320.4,1631.2
				c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1-0.1-0.2-0.3-0.4-0.4c0.4-0.2,0.9-0.5,1.3-0.7C320.3,1631,320.3,1631.1,320.4,1631.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M321.6,1629.7
				c0.4-0.3,0.8-0.5,1.2-0.8c0.1,0.1,0.2,0.3,0.3,0.4c-0.4,0.3-0.8,0.5-1.2,0.8C321.8,1630,321.7,1629.8,321.6,1629.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M324.3,1627.8
				c0.2-0.2,0.4-0.3,0.7-0.5c0.1,0.1,0.1,0.2,0.2,0.4C324.9,1627.8,324.6,1627.8,324.3,1627.8
				C324.3,1627.9,324.3,1627.8,324.3,1627.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M246.1,1339.9
				c-0.1,0.1-0.2,0.2-0.3,0.3C245.8,1340,245.9,1339.9,246.1,1339.9L246.1,1339.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.9,1297.6
				c-2.3,4.2-5.6,7.5-9,10.8c-3,2.9-5.5,6.2-7.9,9.5c-0.1,0.1-0.1,0.2-0.2,0.2c-1.2,0.7-1.7,3.1-3.5,2.2c-2-1-1.8-3.2-1.3-5
				c0.6-2.2,1.7-4.2,2.7-6.3c0.1-0.2,0.3-0.5,0.4-0.5c4.6,0.3,2.7-3.2,2.8-5.1c1-23.3,1.9-46.6,1.9-69.9c0-1.1,0.2-1.9,1.1-2.5
				c2.9-1.9,5.8-3.9,8.7-5.8c1.7-1.1,2.5-0.7,2.6,1.5c0.5,23.2,1,46.4,1.5,69.6C208.8,1296.8,208.9,1297.2,208.9,1297.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M228,1275.7
				c-0.3,0-0.6-0.1-0.9-0.1c-1.3-1.8-2.7-3.7-4-5.5c-0.3-0.4-0.5-1.2-0.9-1.3c-4.8-1.7-2.3-4.8-1.5-7.5c2.5-8.4,5.2-16.8,7.8-25.2
				c1-3.3,0.7-2.7,3.6-1.1c3.4,1.8,4,2.8,1,5.6c-1,1,0.5,2.5,1.8,3.3c0.2,0.4,0.5,0.5,0.9,0.4c0.3,0,0.6,0,0.9,0
				c0.7,0.1,1.3,0.2,2,0.2c1.9,2.4,3,4.3,0.3,7.4c-1.5,1.7,1,3.2,3.1,3.6c3.3,1-0.1,2.4,0.2,3.5C237.5,1264.4,232.7,1270,228,1275.7
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M230.3,1280.9
				c-0.2-1.3,1-2.1,1.3-3.2c2.7-3.2,5.4-6.5,8.1-9.7c1.3-1.2,2.6-2.5,4-3.7c1,1.3,2.2,2.4,3.7,3.1c-0.2,1.6-0.3,3.2,0.3,4.7
				c0.2,0.3,0.4,0.6,0.6,0.9c0.7-0.5,0.4-1,0-1.5c0.1-2.1,1.8-1.4,2.9-1.7c0.3,1,0.6,2-0.3,2.7c-2.9,2.4-2.5,5.6-2.2,8.7
				c1,9.6,2.1,19.2,3.1,28.9c0.1,0.6,0.3,1.4-0.6,1.6c-0.9,0.1-1.3-0.6-1.7-1.3c-4.2-6.5-8.3-13-12.5-19.5
				C234.8,1287.5,232.6,1284.2,230.3,1280.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M156.6,1269.7
				c2.2-0.1,2.4,1.8,3.3,3.1c0.7,1.1,1.4,2.2,2.2,3.3c2.4-2.1,2-4.2,0.3-6.4c-1.4-1.9-2.6-4-3.9-6c-0.6-0.9-1.4-1.7-0.8-3
				c2-1,3.1-2.9,4.3-4.7c3.9-4.9,8.4-9.2,14.2-12.1c0.7-0.3,1.4-0.9,2.1-0.3c0.8,0.7,0.3,1.6,0,2.4c-2.5,5.7-5,11.3-7.5,17
				c-3.1,7.1-6.2,14.2-9.4,21.3c-3.1,7.1-6.3,14.3-9.5,21.4c-0.4,0.8-0.7,1.8-1.9,1.3c-0.9-0.4-0.6-1.3-0.5-2c0.2-1.1,0.6-2.1,1-3.1
				c1.6-1.8,2.9-3.7,3.2-6.2C154.6,1287.1,155.6,1278.4,156.6,1269.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M182.4,1275.3
				c0,6.9,0,13.7,0,20.6c0,0.8,0.6,2-0.8,2.1c-0.9,0.1-1.4-0.7-1.9-1.5c-3-4.8-6.1-9.6-9.3-14.3c-0.8-1.2-1-2.5-0.4-3.5
				c4.5-7.9,7.4-16.6,11.3-24.7c0.1-0.3,0.2-0.6,0.4-0.9c0.3-0.7,0.6-1.5,1.6-1.3c1,0.3,0.8,1.2,0.7,1.9c0,7.2,0,14.4,0,21.6
				C183.4,1275.3,182.9,1275.3,182.4,1275.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M204.7,1309.2
				c1.4-0.5,1.9-3.1,3.6-2.3c1.8,0.8,0.7,2.9,0.8,4.5c0.2,6.8,0.4,13.7,0.5,20.5c0,1.3,0.1,2.6-0.1,3.9c-0.1,0.9,0.3,2.2-1,2.4
				c-1.2,0.2-1.4-1.1-2.1-1.8c-3.7-4-6.4-8.7-9.4-13.3c-0.7-1.1-1.6-2.1-0.9-3.6c2.7-2.2,4.8-4.8,6.7-7.7c0-0.3,0-0.5,0-0.8l0,0.1
				C204,1311.1,204.2,1310,204.7,1309.2L204.7,1309.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M156.6,1269.7
				c-1,8.7-2,17.4-3,26.1c-0.3,2.5-1.5,4.4-3.2,6.2c-0.7-4.6,0.7-9,1.1-13.5c0.6-6.2,1.6-12.4,2.4-18.6c0.2-1.5,0.6-2.9,2.3-4.1
				C156.4,1267.3,156.5,1268.5,156.6,1269.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M202.8,1311.9
				c-1.8,2.9-4,5.5-6.7,7.7c-1.4-0.6-2.4-1.9-4.1-1.7c2.4-3.3,5-6.6,7.9-9.5c0.4,1.5,1.1,0.9,1.8,0.3c1.2-1.1,2.3-2.1,3.7-3.5
				c0.4,1.7-1.6,2.6-0.8,4l0-0.1c-1.5-0.2-2.2,0.4-2,2l0-0.1c-0.2,0.1-0.3,0.2-0.5,0.2C202.4,1311.5,202.6,1311.7,202.8,1311.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M325,1281.3
				c0.3,0.5,0.9,0.5,1.1,0.2c1.8-2.3,3.4-1.1,5.7,0.5c-4.7,0-6.2,4.7-10.3,5.1c-1.2,0.1-2.3,0.7-2.8-0.9l0.1-0.1
				c1.8,0.4,2.5-0.7,2.9-2.1C322.9,1283,323.4,1281.5,325,1281.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M247.7,1272.1
				c-0.7-1.5-0.5-3.1-0.3-4.7c0.2-0.4,0.6-0.8,0.6-1.1c0-2.8,0-2.8,2.9-2.1c0.4,0.1,0.8,0.1,1.3,0.1c-0.3,1.4-2.9,1.7-1.7,3.4
				c0.9,1.3,1.9-0.5,2.9-0.4l0,0c-0.4,1.1-0.8,2.2-2,2.6c-1.1,0.3-2.8-0.3-2.9,1.7C248.1,1271.7,247.9,1271.9,247.7,1272.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M161.9,1256.1
				c-1.2,1.8-2.2,3.7-4.3,4.7c-1.7-3.6-0.4-5.9,4.2-7.3l0,0C161.9,1254.3,161.9,1255.2,161.9,1256.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M255.3,1280.9
				c0.3-1.3-1.1-1.4-1.5-2.3c-0.3-0.8-0.8-1.6-0.2-2.4c0.3-0.4,0.7-0.9,1.3-0.6c0.8,0.4,0.6,1.3,0.7,2c0.1,0.6-0.1,1.3,0.5,1.8
				c0.9,0.1,0.8-1,1.4-1.2c1-0.4,2.1-2.3,2.9-0.9c1.1,1.6-1.3,0.9-1.9,1.7c-0.5,0.7-1.1,1.3-1.7,2
				C256.4,1280.9,255.8,1280.9,255.3,1280.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M242.2,1258.8
				c-0.3-1.2,3.1-2.5-0.2-3.5c0.4-0.7,0.3-2,1.5-1.8c2.2,0.3,0.5,2.5,1.7,3.6c1.4-0.9,2.2-3.1,5.1-2.6c-1.8,1.4-3.5,2.4-4.3,4.2
				c-1.1,0-2.3,0-3.4,0.1l-0.2,0.2L242.2,1258.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M147.5,1316.2
				c-0.4-0.1-0.7,0-0.8,0.5c-1.3-0.4-1.3-1.5-1-2.4c0.6-1.7,1.5-3.3,2.2-4.9c0.4-0.8,1.1-1.2,1.8-0.9c1,0.3,0.6,1.2,0.3,1.9
				C149.1,1312.3,148.3,1314.2,147.5,1316.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M222.4,1280.3
				c-0.1,2.2-0.6,4-3.3,4c0-1.4-0.1-2.8-0.1-4.2c0-0.8-0.6-1.9,0.5-2.3c1.1-0.4,1.4,0.7,1.8,1.4
				C221.6,1279.6,222,1279.9,222.4,1280.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M255.3,1280.9
				c0.6,0,1.1,0,1.7,0c0.1,1.5,0.1,1.5-3.1,6.2C254,1284.6,252.9,1282.4,255.3,1280.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M242.6,1258.7
				c1.1,0,2.3,0,3.4-0.1c1.3,2.7-2,3.4-2.4,5.3c-0.3-0.8-0.7-1.5-1-2.3C242.6,1260.7,242.6,1259.7,242.6,1258.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M242.6,1261.7
				c0.3,0.8,0.7,1.5,1,2.3c0,0,0.1,0.2,0.1,0.2c-1.3,1.2-2.6,2.5-4,3.7C238.6,1264.9,240.7,1263.4,242.6,1261.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M321.8,1283.8
				c-0.4,1.4-1.2,2.5-2.9,2.1C319.6,1284.9,320.6,1284.2,321.8,1283.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M207.1,1304.1
				c0.2,0.1,0.4,0.2,0.6,0.2c-0.1,0.2-0.1,0.5-0.3,0.5c-0.1,0.1-0.4-0.1-0.5-0.2C206.9,1304.5,207,1304.3,207.1,1304.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M210.6,1298.9
				c0.1,0.1,0.2,0.2,0.4,0.4c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1-0.2-0.2-0.4-0.2-0.5C210.2,1299.1,210.5,1299,210.6,1298.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M253.3,1267.2
				c0.2-0.4,0.4-0.7,0.6-1.1c0.1,0.1,0.3,0.2,0.4,0.3C253.9,1266.7,253.6,1267,253.3,1267.2L253.3,1267.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.9,1297.6
				c0-0.4-0.1-0.8-0.1-1.2C209.5,1296.7,209.5,1297.1,208.9,1297.6z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				161.9,1253.5 162,1253.4 161.9,1253.5 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M983.9,1639.5
				c-0.2-1.4,0.3-2.4,1.2-3.5c6.9-7.7,14.2-15.1,20.6-23.2c1.5-1.9,2.8-1.8,4.5,0c9.3,10.5,18.8,20.9,28.2,31.4
				c1.3,1.5,2.5,3.1,4.9,2c0.6-0.3,1.7-0.4,2,0.5c0.3,0.9-0.6,1.3-1.2,1.8c-6.8,5.1-13.6,10.1-20.4,15.2c-1.5,0.2-3,0.2-4.4-0.8
				c-10.5-7.6-21.9-13.7-32.1-21.8C986.3,1640.6,985.4,1639.5,983.9,1639.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1034.5,1442.8
				c1.2-1.1,2.1-2,3.1-2.9c0.6-0.5,1.4-0.7,2,0.1c0.5,0.7,0.6,1.5-0.1,2.1c-5.4,4.5-8.8,10.7-13,16.2c-6.6,8.6-13,17.5-19.5,26.2
				c-0.8,1.2-1.7,1.8-3.2,1.7c-3.7-0.2-7.4-0.2-11.2-0.2c-1.8,0-2.4-0.6-2.5-2.5c-0.2-5.9,1-11.6,1.2-17.4c0-0.6,0-1.4,0.7-1.6
				c1.1-0.3,1.5,0.6,2,1.3c3.2,4.7,6.4,9.3,9.7,13.9c0.5,0.7,0.8,2.6,2.1,1.8c1.5-1,0-2.1-0.6-3c-3.5-5-7-10-10.7-14.9
				c-2.7-3.7-2.7-4,0.1-7.5c7.5-9.1,15-18.1,22.3-27.3c1.8-2.2,2.9-2.4,4.8-0.2c3.3,3.8,6.8,7.4,10.3,10.9
				C1033.3,1440.5,1033.9,1441.7,1034.5,1442.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1040.5,1525.1
				c-6.3,5.8-11.8,10.7-17.3,15.8c-1.6,1.5-2.8,1.5-4.5,0.1c-8.9-7.3-17.9-14.6-26.9-21.9c-2.3-1.9-2.6-3.5-0.7-6
				c5.6-7.2,11-14.6,16.4-22c2.1-2.9,2.6-2.8,4.6,0c7.1,10,14.2,20,21.2,30.1C1034.9,1523.5,1036.3,1525.8,1040.5,1525.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M985.1,1569.5
				c0.5-10.1,1-20.3,1.5-30.4c0.3-5,0.6-10,0.9-15c0-0.7-0.3-1.6,0.5-2.1c0.9-0.5,1.7,0.2,2.3,0.7c8.6,7,17.2,14.1,25.8,21.1
				c1.9,1.5,1.1,2.7-0.2,4c-7.1,6.7-14.2,13.5-21.3,20.2c-2.1,2-4.2,4.1-6.4,6.1c-0.6,0.6-1.4,1.3-2.3,0.9c-0.8-0.4-0.9-1.3-0.8-2.3
				c0.2-1.1,0.2-2.2,0.2-3.2C985.3,1569.5,985.2,1569.5,985.1,1569.5z M995.7,1563.3c-2.7-0.4-5.4-0.5-8.1,0
				C990.3,1563.9,993,1563.8,995.7,1563.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M997.7,1396
				c-0.6-1-0.1-1.8,0.4-2.7c4.2-7.5,8.4-14.9,12.6-22.4c0.4-0.7,0.6-1.5,1.6-1.4c1,0,1,1,1.4,1.6c5.1,9.6,10.1,19.3,15.2,28.9
				c1.1,2,1.4,4.7,4.8,4.8c-2.8,4.8-6.6,8.3-9.9,12.2c-1.6,2.2-2.9,1.8-4.6-0.2c-4.1-4.7-8.3-9.4-12.9-13.6
				C1003.4,1400.7,1001.9,1396.8,997.7,1396z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1029.1,1326.3
				c0.2,1.2-0.8,2.7-1.7,4.2c-5,8.9-10.1,17.9-15.1,26.8c-0.4,0.7-0.5,1.8-1.7,1.8c-1.1,0-1.3-1.1-1.6-1.8c-2.7-5-5.3-10-8-15
				c-0.9-1.6-1-2.9,0-4.5c5.6-8.4,11.2-16.9,16.7-25.4c0.9-1.4,1.8-1.9,3-0.4c2.6,3.4,5.1,6.8,7.7,10.2
				C1029.1,1323.2,1028.9,1324.5,1029.1,1326.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M993.6,1436.2
				c0.7-10.7,1.3-21.3,2-32c0-0.6-0.1-1.4,0.6-1.8c0.9-0.4,1.4,0.4,1.9,0.9c6.1,6.4,12.1,12.9,18.2,19.3c1.4,1.5,0.7,2.4-0.2,3.6
				c-4.8,6.3-10.4,12-14.8,18.7c-1.5,2.3-3.3,4.5-5,6.7c-0.7,0.8-1.5,1.9-2.7,1.4c-1.2-0.5-1-1.7-0.9-2.8
				C993,1445.5,993.3,1440.8,993.6,1436.2C993.6,1436.2,993.6,1436.2,993.6,1436.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M969.3,1833.4
				c15.2-0.4,30.5-2,45.7-1.4c1,0,2.2,0.6,3-0.6c0.3,0.1,0.6,0.2,0.9,0.4c1.2,1.1,4,1,3.8,2.4c-0.2,2.1-3,0.7-4.4,1.8
				c-1,0.1-2,0.5-2.9,0.4c-13.9-1.1-27.7-0.5-41.5,0.8c-0.9,0.1-2.5-0.4-2.4,0.9c0,1.3,1.6,1.1,2.6,1.1c0.3,0,0.7,0,1-0.1
				c14.5-1,29-1.6,43.6-1.1c2-0.5,3.1,1,4.7,1.9c-1.5,1.5-3.4,1.2-4.8,2c-15,0-30-0.6-45,1.2c-1,0.1-2.8-0.5-2.7,0.9
				c0,1.7,1.8,1,2.9,1c4,0.1,8-0.2,12-0.6c11.1-1,22.2-1,33.4-0.6c1.4,0,2.7,0,4.1,0c0.8,0.4,1.8,0.5,1.9,1.6
				c0.1,1.2-1.1,1.3-1.8,1.6c-2,0.9-3.9,1.1-6.2,1c-7.3-0.4-14.7-0.7-22.1-0.5c-2.6,0.1-5.4-0.8-7.9-0.6c-5,0.3-10.1-0.3-15.1,0.6
				c-2.1,0.4-2,1.9-2,3.5c-1.5,0.4-3.4,0-3.7-1.1c-0.5-2.1,1.5-3.2,3.5-3.9c-1.3-1.4-4.1-0.5-4-2.3c0.1-1.6,1.4-3.5,3.9-3.2
				c-0.3-0.5-0.3-0.7-0.5-0.8c-1.1-0.9-3.4,0.1-3.6-2c-0.2-2,1.8-2.4,3.2-3.1C969,1834.3,969.1,1833.8,969.3,1833.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1030.9,1368.5
				c0.4,8.8,0.9,17.6,1.3,26.5c0,0.9,0.4,2.3-0.7,2.6c-1.1,0.3-1.6-1-2-1.9c-5.1-9.5-10.2-19.1-15.3-28.6c-0.6-1.1-0.3-2,0.3-3.1
				c4.3-7.7,8.6-15.4,12.9-23.1c0.4-0.6,0.6-1.6,1.6-1.2c0.9,0.3,0.6,1.3,0.6,2C1030.1,1350.6,1030.5,1359.5,1030.9,1368.5
				C1030.9,1368.5,1030.9,1368.5,1030.9,1368.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1041.3,1618
				c1.2,7.9,1.5,15.8,1.3,23.7c0,0.6,0.5,1.7-0.6,1.9c-1,0.3-1.2-0.7-1.7-1.2c-9.2-10.2-18.4-20.4-27.5-30.7c-0.9-1-3.4-1.4-2.5-3.3
				c0.9-1.9,2.5-3.6,5-3.7c0.2,0.3,0.3,0.7,0.5,0.8C1024,1610.2,1032.3,1614.8,1041.3,1618z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.2,1603.6
				c1.9-4.5,5.3-8,8.9-11c1.7-1.4,1.9-4.6,5-4.3c3.2-0.4,6.4-0.9,9.6-1.3c1.1,1.9,0.8,3-1.6,3.1c-2.6,0.1-5.3-0.1-7.3,1.5
				c1.7,0.8,3.8,0,5.8-0.3c2-0.3,3.8,0.1,3.9,2.3c0.3,5.9,1.1,11.8,0.5,17.8c-0.9,0.4-0.9,0.9-0.1,1.5c0.3,1,0.5,2.1,0.2,3.2
				c-2.9-0.5-5.4-1.9-7.9-3.2C1027.6,1609.8,1021.9,1606.7,1016.2,1603.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1036.8,1503.1
				c0.2,4.7,0.5,9.4,0.7,14c0,1,0.1,2.5-0.8,3c-1.3,0.6-1.7-1-2.4-1.7c-8-8.6-13.1-19.3-20.4-28.4c-0.5-0.7-1.3-1.4-0.9-2.3
				c0.5-1,1.5-0.5,2.3-0.5c5.9-0.2,11.8-0.3,17.7-0.7c2.7-0.2,3.4,0.7,3.3,3.3c-0.2,4.5-0.1,8.9-0.1,13.4
				C1036.5,1503.1,1036.7,1503.1,1036.8,1503.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1035.6,1471
				c0.2,3.9,0.3,7.8,0.6,11.7c0.1,1.9-0.5,2.5-2.4,2.5c-6.3,0.1-12.7,0.4-19,0.6c-0.7,0-1.6,0.4-2-0.5c-0.4-0.8,0.2-1.4,0.6-2
				c6.3-8.4,12.6-16.9,18.9-25.4c0.5-0.7,1-1.6,2-1.3c0.9,0.3,0.7,1.2,0.7,2c0,4.1,0,8.2,0,12.4
				C1035.2,1471,1035.4,1471,1035.6,1471z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1001.2,1314.6
				c0-5.8,1-13.3,2.1-20.8c0.1-0.9-0.1-2.2,1.2-2.4c1-0.1,1.3,1,1.8,1.6c3.2,4.2,6.3,8.4,9.5,12.5c1.1,1.4,1.5,2.4,0.4,4
				c-4.6,6.9-9.1,13.8-13.7,20.8c-0.4,0.6-0.8,1.6-1.6,1.4c-1.2-0.3-0.6-1.5-0.6-2.2C1000.5,1325.2,1000.8,1320.8,1001.2,1314.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1014.2,1265.8
				c0.9,1.2,1.3,1.6,1.5,2c0.4,1.2,1.2,1.7,2.2,1.2c1.1-0.6,0.3-1.5,0-2.2c-0.5-1-0.9-2-1.5-2.9c-1.2-1.8-1.5-3.4,0.5-5.1
				c1.6-1.4,1.5-3.6,1.1-5.5c-0.3-1.5-0.3-2.3,1.1-3.2c3.3-2.1,3.8-4.1,2.5-7.8c-0.3-0.9-0.5-1.8,0.2-2.3c0.7-0.5,1.8-0.7,2.7-0.6
				c1.2,0.1,0.8,1.3,0.9,2.1c0.3,7.5,0.7,15,1,22.5c0.1,2,0.2,3.4,2.8,3.3c1.1-0.1,2.2,0.6,2.4,1.8c0.2,1,0.2,2.2-1.4,1.5
				c-2.2-0.9-4.1,0.1-6,0.7c-3.9,1.2-7.7,2.5-11.5,3.7c-1.1,0.4-2.2,0.6-2.6-0.9c-0.3-1.1-1.2-2.4,0.4-3.1
				C1012.3,1270.1,1013.4,1268.5,1014.2,1265.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1025.8,1665.8
				c5-4.1,10-8.3,15.1-12.4c1.1-0.9,2-1.1,2.2,0.9c0.7,8.8,1.1,17.7,1,26.6c-0.8,0-1.5,0-2-0.7c-2.9-4.7-8.1-6.6-12.1-9.9
				C1028.5,1668.9,1025.4,1668.9,1025.8,1665.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M945.9,1616.3
				c0.9-3.7-0.5-7.5-0.2-11.2c0.1-1.4-0.2-2.8,1-3.9c6.6,0.6,12.8-1.4,19-2.8c0.6-0.1,1.2-0.1,1.8-0.2c0.9,0.8,1.8,0.1,2.8,0.2
				c1.5,0.1,1.5,0.9,1,2c-1.5,0.7-3.2,1.1-3.4,3.2l0,0c-0.7-0.3-1.1-0.1-1.3,0.6l0,0c-1.2,0-2.1,0.5-1.9,1.9l0,0
				c-0.6-0.1-0.8,0-0.6,0.6l0,0c-0.6-0.2-0.9,0-0.6,0.6l0,0c-1.3-0.5-1.6,0.3-1.9,1.3l0,0c-1-0.1-1.5,0.2-1.3,1.3l0,0
				c-0.7-0.3-1.1,0-1.3,0.6l0,0c-1.2,0.1-2.1,0.5-1.9,1.9l0,0c-0.6-0.2-0.9-0.1-0.6,0.6l0,0c-0.8-0.5-1.1,0-1.3,0.6l0,0
				c-0.5-0.1-0.7,0.1-0.6,0.6l0,0c-0.5-0.1-0.7,0.1-0.6,0.6l0,0c-0.5-0.1-0.7,0.1-0.6,0.6l0,0c-0.5-0.1-0.8,0.1-0.6,0.6l0,0
				c-0.5-0.1-0.8,0.1-0.6,0.6l0,0c-1.5-0.2-2.6,0.1-2.6,1.9l0,0c-0.6-0.3-1.2,0-1.6,0.5c-0.4-0.1-0.8-0.2-1.2-0.4
				c-0.1-0.3-0.1-0.7-0.2-1C945.9,1617.7,945.9,1617,945.9,1616.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1007.6,1854.5
				c-4.2,0.4-8.9,2.5-12.5,0.7c-5.8-2.9-11.7-1.5-17.4-1.6c-2.5-0.1-6.2,0.3-8.6,2.5c-1,0.2-2.2,0.5-2.5-0.7
				c-0.3-1.2,0.5-2.3,1.5-2.9c0.8-0.5,1.9-0.5,2.4-1.5c17.7-1,35.4-2.1,53.2-1.4c0.4,2-0.4,2.6-2.5,2.7
				C1016.7,1852.6,1011.9,1852.1,1007.6,1854.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1000.2,1347
				c0.8,0.2,1,0.8,1.3,1.4c2.2,4.1,4.3,8.3,6.6,12.4c0.9,1.5,0.7,2.8-0.2,4.3c-2.9,5.1-5.7,10.3-8.6,15.4c-0.4,0.6-0.6,1.7-1.5,1.5
				c-0.9-0.2-0.6-1.2-0.6-1.9c0.7-10.3,1.3-20.7,2-31C999.4,1348.3,999,1347.3,1000.2,1347z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M947.1,1596.2
				c-2.4-2.6-2.8-8.3-0.8-10.6c2.3,0.2,4.8-2.4,6.9,0.4c0.1,0.1,0.8,0.1,0.9-0.1c2.3-3.4,6.9-2.1,9.6-4.6c3.2-2.9,6.2,0.1,9.2,0.4
				c1.2,0.1,0.6,1.8-0.8,2.5c-4.2,2-8.2,4.2-12.4,6.2C955.6,1592.4,951.3,1594.3,947.1,1596.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1025.8,1665.8
				c-0.4,3.1,2.7,3.1,4.1,4.3c4,3.4,9.2,5.3,12.1,9.9c0.5,0.8,1.2,0.8,2,0.7c0.9,2,2.7,0.6,4.1,1.1c0.8,0.3,1.8,0.4,2.2,1.2
				c0.3,0.7-0.3,1.3-1,1.5c-0.8,0.2-1.4,1-2.6,0.2c-14.5-10.1-29.2-20-43.8-30.1c-6-4.1-12-8.3-17.9-12.4c-0.9-0.7-2.3-1.2-1.1-2.8
				c1.5,0,2.4,1,3.4,1.8c10.2,8,21.6,14.2,32.1,21.8c1.3,1,2.8,1,4.4,0.8C1023.7,1665.3,1024.7,1665.6,1025.8,1665.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1014.7,1561.6
				c-2.2,1-4,1.9-5.5,4.3c-2.4,3.7-7.5,2-11,4c-0.6-1-0.2-1.4,0.2-1.7c6.8-6.6,13.5-13.3,20.3-19.9c1.8-1.8,3,0.2,4.3,1
				c1.3,0.8,0.7,1.6-0.1,2.6C1020.1,1555.3,1016.8,1558,1014.7,1561.6L1014.7,1561.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1014.6,1561.7
				c1.1,0.1,1.9-0.3,2.4-1.3c1.7-3.1,4.7-5.1,6.6-8c0.9-1.3,2.1-0.8,3.1,0.1c3.3,2.8,6.6,5.6,9.8,8.4c0.5,0.4,1.1,0.8,0.8,1.6
				c-0.3,0.7-0.9,0.4-1.4,0.5c-6.6,1.5-13.3,0.2-19.9,1C1013.9,1564.2,1014.4,1562.8,1014.6,1561.7
				C1014.7,1561.6,1014.6,1561.7,1014.6,1561.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M997.7,1396
				c4.2,0.8,5.7,4.7,8.4,7.2c4.6,4.2,8.8,8.9,12.9,13.6c1.7,2,3,2.4,4.6,0.2c-0.1,1-1.3,1.9-0.5,2.9c0.7,0.9,1.3-0.2,1.9-0.3
				c-0.9,2-0.3,3.3,1.4,4.7c1.8,1.5,3.2,3.5,4.8,5.3c0.4,0.5,0.7,1.3,1.5,0.9c0.6-0.3,0.5-1.1,0.5-1.8c1,2.5,2.6,4.6,4.2,6.6
				c0.7,0.9,1.5,2.1,0.6,2.9c-1.1,1.1-1.9-0.3-2.6-1c-12-12.7-24-25.5-35.9-38.2C998.7,1398.2,997.9,1397.2,997.7,1396z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M995.6,1580.2
				c0.2,0,0.6,0.1,0.6,0c3.3-6.5,10-3.8,15.1-5.5c1.2-0.4,1.4,1,0.7,2.1c-1.6,2.5-3,5-4.7,7.5c-1.1,1.6-4.1,1.6-5.9,0.2
				c-1.2-0.9-1.9-2.4-3.4-3c0-0.4-0.2-0.6-0.6-0.6c-0.1,0-0.2,0.2-0.2,0.2c0.2,0.2,0.4,0.4,0.6,0.6c0.4,0.5,0.8,1,1.1,1.5
				c0.7,1,2.6,1.8,1.7,3.1c-0.8,1.2-2.5,0.4-3.8-0.1c-0.8-0.9-1.1-2.4-2.8-2.1l0,0c0-1.1,0-2.2,0-3.2l0,0
				C994.8,1580.9,995.3,1580.6,995.6,1580.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.6,1589.9
				c-0.5,1.5-1.8,1.2-3,1.4c-3.5,0.7-6.9,1.4-11.3,2.3c3.6,1.3,6.3-1,9,0.6c-1.5,0.9-3.1,1.5-5,1.2c-0.3,2,1.6,2.5,2.3,4.1
				c-1.9,0.5-3.1,0-4.3-1.3c-1.2-1.3-2.5-2.6-4.1-0.5c-3.3-3.2-6.7-6.4-9.3-10.3c0.9-0.1,1.8-0.1,2.7-0.2c0.7,2.6,2.1,4.1,5.1,3.6
				c3.8-0.7,7.7-1.4,11.5-2.1C1011.9,1590.9,1014.5,1589,1016.6,1589.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M947.1,1600.6
				c-2.4-1-0.4-2.1,0-3.2c3.5,0.1,6.3-2,9.3-3.2c4.1-1.7,8-3.8,12-5.5c1.1-0.5,2-0.8,2.2-2.1c0.2-1.5,1.1-1.8,2.3-1.3
				c1.4,0.6,1,1.7,0.7,2.7c-1.1,3.2-3.8,4.4-6.6,5.7c-0.8,0.4-2,0.3-2.5,1.3c-1.8-0.1-2.9,0.8-3.7,2.3c-2.4-0.4-3.1,2.6-5.6,2.5
				C952.5,1599.7,949.8,1600.3,947.1,1600.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1033.2,1428.7
				c0.1,0.7,0.1,1.5-0.5,1.8c-0.8,0.4-1.1-0.4-1.5-0.9c-1.6-1.8-3-3.8-4.8-5.3c-1.7-1.4-2.2-2.8-1.4-4.7c1.7-2.1,3.4-4.2,5.2-6.3
				c0.5-0.6,0.9-1.7,2-1.4c1.2,0.4,0.8,1.6,0.8,2.5C1033.1,1419.2,1033.1,1423.9,1033.2,1428.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1028.5,1546.6
				c-0.4,0.1-0.8,0.3-1.2,0.4c-0.5-0.1-1-0.2-1.5-0.3c-2.6-1.5-2.7-2.8-0.3-4.8c3.2-2.6,6-5.7,9-8.4c0.9-0.8,1.9-3,3.3-1.3
				c1,1.2,1,3.3-0.8,4.6c-0.3,0.2-0.6,0.6-0.9,0.9c-2.2,1.2-3.7,3.3-5.4,5.1c-0.8,0.9-2.5,1.6-1.7,3.3l-0.1,0.1
				c-0.2,0-0.3-0.1-0.5-0.1C1028.5,1546.2,1028.5,1546.4,1028.5,1546.6L1028.5,1546.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1020.6,1302.4
				c-0.9,0-1.3-0.5-1.7-1c-2.1-2.7-4.1-5.3-6.2-8c-0.3-0.4-0.8-0.8-0.5-1.4c0.3-0.6,0.9-0.4,1.4-0.3c3.3,0.8,6.5,1.6,9.8,2.4
				c1.3,0.3,2.1,1.1,1.3,2.3c-1.1,1.9-2.4,3.6-3.6,5.4C1021.1,1302.2,1020.7,1302.3,1020.6,1302.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.2,1603.6
				c5.7,3.1,11.4,6.2,17.1,9.2c2.5,1.3,5,2.7,7.9,3.2c0,0.7,0,1.3,0,2c-9-3.2-17.3-7.8-25.5-12.5c-0.2-0.1-0.3-0.5-0.5-0.8
				C1015.3,1604.1,1015.5,1603.7,1016.2,1603.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1028.1,1312.5
				c-2.9-1.6-4-4.5-5.8-6.6c-0.8-0.9,1.2-3.6,2.9-4.3c1.8-0.7,1.3,1.3,1.6,2.3C1027.5,1306.5,1029.1,1309.1,1028.1,1312.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M993.6,1595.7
				c-3.1,0.5-6.1,1.3-9.2,1.3c0.1-0.2,0.2-0.4,0.4-0.5c1.3-1.1-2.4-3.1,0.4-3.6c2.4-0.5,5.4-1.8,7.3,1.5
				C992.6,1594.8,993.5,1595,993.6,1595.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1013.5,1285.4
				c-0.6-0.1-1.4,0-1.5-0.9c-0.1-0.8,0.4-1.2,1-1.4c4.4-1.5,8.8-3,13.2-4.5c0.7-0.2,1.7-0.8,1.9,0.2c0.2,0.8-0.4,2.4-0.9,2.6
				C1022.5,1282.3,1017.9,1283.5,1013.5,1285.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.7,1597.3
				c0.8-1.5,1.9-2.4,3.7-2.3c-0.4,0.8-0.9,1.6,0.7,1.3c2.7-0.5,5.5-1,8.2-1.6c1-0.2,2-0.7,3.3-0.2c-1.2,2.1-2.7,3.5-5.3,3.4
				c-1.3-0.1-2.6,0.2-3.9,0.2c-0.6,0.1-1.2,0.1-1.8,0.2C963.9,1598.6,962,1599.6,960.7,1597.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1019.2,1285.4
				c2.2-0.7,4.5-1.5,6.7-2.1c0.8-0.2,2.1-0.8,2.3,0.7c0.2,1.4-0.9,1.8-2.1,1.8c-2.3,0-4.5,0-6.8,0
				C1019.2,1285.7,1019.2,1285.5,1019.2,1285.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1010.7,1567.1
				c2-3.4,2-3.4,10.1-2.3C1017.8,1566.3,1014.5,1566.5,1010.7,1567.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M993.5,1587.2
				c-0.9,0.1-1.8,0.1-2.7,0.2c-0.9-0.7-1.6-1.4-0.8-2.6c0.7-0.3,1.3-0.6,1.5-1.4l0,0c0.9,0.2,1.7,0.4,2.6,0.6l0,0
				c0.2,0.9,0.5,1.8,0.7,2.7C994.5,1586.9,994,1587.1,993.5,1587.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M976.9,1583.3
				c-1,0.2-1.8,0.1-1.8-1.1c0-2.1,1.8-1.7,3-2c0.9-0.3,1.8,0.1,1.8,1.2C979.8,1583.4,978.2,1583.3,976.9,1583.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M991.7,1580.8
				c-0.1-0.9-1.5-1.9,0-2.8c0.9-0.5,1.9-0.7,2.7,0c1,0.8,0.4,1.5-0.2,2.2c-0.1,0-0.2,0.1-0.2,0.1c0.1,0.1,0.2,0.3,0.2,0.4l0,0
				C993.3,1580.4,992.5,1580.5,991.7,1580.8L991.7,1580.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M989.6,1584
				c-0.9,0.2-1.8,0.5-2.1-0.7c-0.3-1.2,0.6-1.9,1.4-2.2c0.8-0.4,1.9-0.2,2.8-0.3l-0.1-0.1c-0.3,0.9-0.7,1.7,0,2.6l0,0
				C990.6,1582.6,990.1,1583.3,989.6,1584z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.6,1589.9
				c-2.1-0.9-4.7,1.1-6.5-1.1c2.1-0.4,4.2-1.1,6.5-0.8C1016.6,1588.6,1016.6,1589.3,1016.6,1589.9L1016.6,1589.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M974.5,1586.4
				c0.2-1,0.9-1.4,1.7-1c0.8,0.4,2.1,0.3,2.2,1.5c0,1.1-1,1.3-1.9,1.1C975.5,1587.8,974.6,1587.5,974.5,1586.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M995,1586.7
				c-0.2-0.9-0.5-1.8-0.7-2.7c1.7-0.3,2,1.2,2.8,2.1C996.3,1586.3,995.7,1586.5,995,1586.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M967.9,1603.6
				c0.2-2.1,1.9-2.5,3.4-3.2l0.1,0.2l-0.2,0.2C970.6,1602.3,969.6,1603.4,967.9,1603.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1016.6,1589.9
				c0-0.6,0-1.3,0-1.9c0.4-0.1,0.8-0.1,1.2-0.2C1017.8,1588.7,1017.7,1589.6,1016.6,1589.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M989.6,1584
				c0.5-0.7,1-1.4,1.9-0.7c-0.2,0.8-0.8,1.2-1.5,1.4C989.9,1584.5,989.8,1584.3,989.6,1584z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M980.8,1567
				c-0.6-0.9-0.4-1.8,0.1-2.7C981.2,1565.2,981.1,1566.1,980.8,1567z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M994.2,1580.7
				c-0.1-0.1-0.2-0.3-0.2-0.4c0.1,0,0.2-0.1,0.2-0.1c0.5,0,0.9,0,1.4,0C995.3,1580.6,994.8,1580.9,994.2,1580.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1025.8,1546.7
				c0.5,0.1,1,0.2,1.5,0.3C1026.7,1547.4,1026.1,1547.6,1025.8,1546.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M980.9,1562.5
				c-0.3-0.3-0.1-0.6,0.2-0.8C981,1561.9,980.9,1562.2,980.9,1562.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M683.4,1289.8
				c2.1,4.8,6.8,5.4,11,5.8c5.9,0.5,11.5,1.9,17.1,3.9c-2.6,0.8,0.5,1.4,0,2.2c-0.8,0.6-1.9,0.5-2.6,0.2c-5.5-2.3-11.6,0-17.1-2.5
				c-1-0.4-2.4,0.1-2.9-1.5c-0.2-0.7-1.2-0.7-1.7-0.3c-0.8,0.6-0.1,1.1,0.4,1.5c1.3,1.1,1.4,2.7,0.4,3.7c-0.9,0.9-2.1-0.3-3.1-0.5
				c-2.3-0.5-4.4-2-6.8-1.8c-1.4,0.1-2.8,0-3.2-1.5c-0.4-1.7,1-2,2.4-2.4c1.6-0.5,3.2-0.5,4.9-0.5c1.3,0,2.6,0.1,3.9-1.7
				c-4.5-0.9-8.6,1-12.9,0.2C677,1293.8,679.6,1290.5,683.4,1289.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M618.2,1341.8
				c-1.6,0.1-3-0.6-4.4-1.2c-1.4-0.6-2.5-1-3.9,0.1c-1,0.8-2.5,0.7-3.6-0.3c-2-1.8-4.4-2.3-7-1.9c-4.1,0.7-7.7-1.1-11.4-2.1
				c-1.8-0.5-2.9-6.7-1.5-7.8c0.6-0.4,1.2-0.3,1.3,0.3c2,6.2,7.8,4.6,12.1,5.8c5.4,1.4,11,2.5,16,4.9
				C617,1340.1,618,1340.5,618.2,1341.8L618.2,1341.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M570.8,1273.1
				c1.7-0.1,2.8,1.1,3.9,2.1c0.7,0.6,1.3,1.5,2.2,1c1-0.5,0.1-1.6,0.3-2.4c1.2,3.3,2.9,6.5,1.6,10.1c-2.7-2.3-5.3-4.6-8.9-5.5
				C570.5,1276.7,570,1274.8,570.8,1273.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M793.3,1342.2
				c3.6,3.6,6.4,7.7,7.9,13.6c-3.2-3.4-5.1-6.8-8.9-8.2C792.6,1345.8,793,1344,793.3,1342.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M691.4,1258.2
				c-0.9,1.6,0.7,0.8,1.1,1.2c-0.3,0.7-2.1,0.2-1.5,1.6c0.6,1.5,1.3,0.1,2,0c0.7-0.2,1.5,0.2,2.2,0.1c1.8-0.1,0.7-1.1,0.5-1.8
				c1.6,0.9,1.8,1.9,0.4,4c-0.6,0.9-1.3,1.6-0.5,2.6c-0.3,0-0.7,0-1-0.1c-1.5-0.1,0.7,2.4-1.1,1.7c-1.3-0.5-1.2-1.9-0.9-3.1
				c0.5-1.7-1.2-1.5-1.9-2.1c-0.9-0.7-1.6-1.5-1.4-2.7C689.5,1258.5,690.2,1258,691.4,1258.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M792,1360.5
				c3.2,2.4,4.1,6.1,5.9,10.2c-2.4-1.1-3.1-2.7-4.3-3.8c-1.9-1.7-2.1-0.6-2,1.1C790.2,1365.5,792.4,1363.1,792,1360.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M797,1327.4
				c3.2,2,4.9,5,5.1,8.9c-2.7-1.5-3.5-5.3-7.1-5.1C795.2,1329.8,798,1329.6,797,1327.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M792.8,1352
				c3,2.7,4.5,6.2,6.4,10.2c-2-0.3-2.6-1.6-3.4-2.6c-1-1.2-1.6-3.2-3.9-2.6C792.2,1355.3,792.5,1353.7,792.8,1352z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M792.4,1369.8
				c2,1.2,2.4,3.4,3.4,5.3c-1.4-0.5-3.4,1.8-4.2-0.5C791,1373.2,790.4,1371.1,792.4,1369.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.7,1266.1
				c0.8,1,2.1-0.1,3,0.7c-0.4,1.2-1.8,0.3-2.4,1C696.1,1267.2,695.9,1266.6,695.7,1266.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M696.2,1267.8
				c-0.4,1.3-0.6,2.8-2.6,2.4C695.1,1270,695.1,1268.3,696.2,1267.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M578.8,1288.6
				c0.3,0,0.6-0.1,0.9-0.1c0.1,0.9,1,1.6,0.4,2.6C579,1290.6,578.7,1289.7,578.8,1288.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M799.7,1342.5
				c1.1,0.4,0.4,1.1,0.5,2c-1-0.8-2-1.3-2-2.5C798.7,1342.1,799.2,1342.3,799.7,1342.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M618.2,1341.8
				c0.8,0.1,1.5,0.3,1.7,1.2c0.1,0.4-0.2,0.6-0.6,0.5C618.4,1343.3,618.1,1342.7,618.2,1341.8L618.2,1341.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.1,1257.4
				c-0.9,0.4-1.7,0.8-2.7,0.6C693.2,1257.3,694.1,1257.2,695.1,1257.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M579.6,1287.1
				c-0.1-0.3-0.1-0.6-0.2-0.9C579.7,1286.4,579.9,1286.7,579.6,1287.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M683.9,1289.6
				l-0.1,0.1c0,0-0.2-0.1-0.2-0.1c0-0.1,0-0.3,0-0.4C683.7,1289.3,683.8,1289.5,683.9,1289.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M713.5,1299.9
				c0.2,0.1,0.5,0.2,0.7,0.2C713.8,1300.6,713.6,1300.4,713.5,1299.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M718.2,1301.3
				c0.2-0.4,0.3-0.2,0.4,0.1l-0.2,0.1L718.2,1301.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M718.7,1301.4
				c0.4-0.1,0.7,0,0.9,0.3C719.2,1301.9,718.9,1301.8,718.7,1301.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M662,1601.1
				c-0.6-0.2-1.2-0.5-1.9-0.7c-0.8-3.1-3.2-4.7-6-4.3c-2.7,0.3-4,2.8-4.5,5.5c-4.6,1.4-9,0-13.5-0.6c-1.7-0.2-3.4-0.6-5.2-0.6
				c-1.8,0-4,0.7-4.6-2.2c-0.2-1-1.8-0.4-2.7-0.7c-0.9-0.2-1.8-0.3-2-1.3c-0.3-1.2,0.7-1.4,1.5-1.7c6.6-2.7,13.1-5.4,19.7-8
				c1.1-0.4,2.3-0.3,3.5-0.5c1,0,2,0,2.9,0.1c10.7,1.2,21.4,2.4,32.1,3.7c4.1,0.5,8.3,0.6,12.3,1.6c1.4,2.9,0.1,5.9,0.5,8.9
				c0.4,3,0.9,3.5,3.5,1.9c1.7-1,3.3-2,5.2-2.5c0.6,0.3,0.7,0.8,0.7,1.4c-0.7,0.7-1.4,1.4-2.1,2c-0.6,0-1.1,0.2-1.2,0.9
				c-0.5,0.1-1,0.2-1.5,0.3c-2.5,0.1-4.9,0.1-7.4,0.2c-0.5,0-1.5,0.1-1.5,0.1c0.1,2.8-2,1.5-3.1,1.5c-1.8,0-3.7-0.2-5.5-0.6
				c-1.7-0.4-2.4,0.4-2.9,2c0-1.9-1.4-2.5-2.4-3.4c0.8,0,1.5-0.1,2.3-0.1c2.7,1,5.4,0.1,8.1,0c-2.7,0-5.2-1.4-8-0.9
				c-1.4-0.1-2.8,0-4.2-0.2c-3-0.4-6-0.6-8.8,0.7C664.9,1602,663.9,1601,662,1601.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M747.6,1570.8
				c5.9-1.4,11.9-1.2,17.8-1.7c2.3-0.2,1.6,1.8,1.2,3.1c-1.7,5.4-4.6,10.3-7.5,15.2c-0.9,1.6-2.2,3-3,4.7c-1.6,3.5-4.3,3.5-7.4,2.9
				c-3.7-0.7-7.4-1.7-11.2-2.3c-2.2-0.3-3.1-1.4-2.9-3.4c0.1-2.1-0.6-4.3,0.4-6.4c0.3-0.3,0.5-0.5,0.8-0.8c0.1-0.2,0.3-0.3,0.5-0.4
				C740.9,1578.7,744.5,1575,747.6,1570.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M862.7,1630.2
				c-0.4,1.1-2.6,0.6-2.4,2.3c0.2,1.7,2.1,0.8,2.8,1.8c0.1,1.7-2.6,0.8-2.4,2.5c0.2,1.8,2.1,1.2,3,2c-0.4,1.6-3,0.7-2.9,2.6
				c0.1,1.8,2.1,1.1,2.9,2.2c-0.3,1.6-3,0.5-2.9,2.5c0.2,1.7,2.3,1,3,2.1c-0.6,1.3-2.9,0.5-2.9,2.1c0.1,1.6,2.1,1.3,2.9,2.3
				c-0.3,1.4-2.7,0.6-2.6,2.4c0.1,1.6,1.9,1.3,2.5,2.3c-0.6,1.1-2.8,0.6-2.5,2.5c0.3,1.6,2,1.1,3,2c-2.1,1.7-4.6,1.7-7,1.4
				c-2.6-0.4-5-1.6-7.7-1.3c-0.8,0.1-1.2-0.3-1-1.2c0.4-1.7-0.3-2.8-2.1-2.9c0.4-1.4,2.6-1.1,2.3-3c-0.3-1.5-1.5-1.2-2.4-1.6
				c0.5-1.2,2.8-1,2.2-2.9c-0.4-1.3-1.3-1.7-2.5-1.6l0,0c1-1.3,4.2-2.2,0.7-4.2c-0.8-0.4-0.1-0.8,0.2-1.2c1.6-2.3,1.6-2.4-1.3-3.7
				c0.4-1.2,2.6-0.8,2.3-2.7c-0.3-1.6-1.9-1-2.7-1.8c0.7-1.2,2.8-0.7,2.6-2.6c-0.1-1.7-1.6-1.5-2.9-1.9c1.1-1.2,2.9-1.8,2.5-3.8
				c3.6,1.6,7.6,0.9,11.4,1.9C860.4,1629.3,861.7,1629.3,862.7,1630.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M627.4,1543.5
				c-0.3,0.1-0.6,0.2-0.9,0.4c-1.2-0.7-1.7-0.3-1.7,1c-0.4,0.2-0.8,0.4-1.2,0.6c-1.9-1.3-2.7-0.1-3.2,1.5c-0.1,0.1-0.3,0.2-0.4,0.2
				c-1.1-0.5-1.7-0.3-1.6,1c-4.1,1.3-7.3,4.7-11.9,4.6c-1.2-2.1-3.5-2.7-5.3-3.9c-2.4-1.6-2.8-0.4-2.5,1.8c-1.3-0.2-2.6-0.5-3.9-0.7
				c-2.5-1.6-3.3-3.4-0.7-5.7c0.1-0.1-0.1-0.6-0.1-0.9c2.2-1.6,4.9-1.8,7.5-2.1c9.4-1,18.1-4.7,27.2-7c8.6-2.2,17.3-4.2,26-6.4
				c1.4,0.8,2.9-0.8,4.3,0.3c-4,2.7-8.5,4.4-12.7,6.5c-3.7,1.9-7.4,3.5-11.2,5.2c-2,0-4.6-1-4.5,2.6c-0.4,0.1-0.9,0.2-1.3,0.3
				C628,1541.8,627.4,1542.2,627.4,1543.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M909.4,1608.2
				c0.5,0.2,1,0.3,1.5,0.5c0.2,0.3,0.4,0.5,0.5,0.8c0.2,0.4,0.5,1.1,0.7,1.1c5-0.8,6.9,4.1,10.6,5.7c0.5,0.2,1,1,1,1.6
				c-0.1,3.8,0.9,7.7-0.5,11.6c-0.4,1.3-0.8,1.5-1.9,1.3c-6.9-1.4-13.9-2.8-20.8-4.1c-1.3-0.3-1.6-1.1-1.8-2.3
				c-0.4-4.1,0.8-8.2-1-12.2c-0.4-0.9,0.5-1.4,1.2-1.6c1.2-0.3,2.3-1.2,3.7-1C905,1609.5,907.3,1609.1,909.4,1608.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M896.4,1576.3
				c4.8-2.9,9.7-5.8,14.5-8.6c2.7-1.6,5.4-3.2,8.1-4.8c1.7-1,2.6-0.7,2.7,1.5c0.1,5.2,0.2,10.4,0.6,15.6c0.3,4-2.6,3.4-4.9,3.9
				c-0.3-0.4-0.6-0.3-0.8,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.5-0.2-1.1-0.6-1.5-0.5c-6.8,2.3-11.5-2.1-16.6-5.1
				C896.4,1577.9,896.1,1577.3,896.4,1576.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M697.7,1630.3
				c-5.5,0.9-10.6,2.9-16.3,3.2c-5.4,0.3-10.3-1.7-13.3-5.2c-2.5-3-6-3.4-8.5-5.8c-0.8-0.7-2.3-1-1.9-2.3c0.4-1.2,1.7-0.5,2.6-0.4
				c2.3,0.2,4.6,0.5,6.8,0.8c1.6,0.2,3.2,0.1,2.8,2.5c-0.1,0.5-0.1,0.9,0.5,0.9c0.4,0,0.9-0.2,1.1-0.5c0.4-0.6,0.7-2,0.9-1.9
				c4,0.7,7.3-3.3,11.3-1.6c0.3,0.1,0.7,0.5,0.9,0.8c0.1,0.1-0.3,0.7-0.4,0.7c-3.2-0.1-4,3.5-6.8,4.3c-1.1,0.3-3.8-0.6-3.3,2.7
				c3.1-2.3,6.6-3.2,9.8-5.3c2.2-1.5,4.6-2.3,6.3-4.7c0.5-0.7,2.4-1,3.7-0.3c0.8,0.4,1.1,1,0.7,1.9c-0.5,1.1,0.2,3.1-2.5,2.2
				c1.4,1.1,2.5,1.7,2.5,3.5c0,1.6,0.8,3.2,3.1,2.6C697.7,1629.2,697.7,1629.8,697.7,1630.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M731.7,1420
				c-0.2-2.2-0.4-3.7-0.5-5.1c-0.4,0-0.7,0-0.7,0.1c-0.1,4.8-3.1,8.9-2.8,13.8c0.1,1.8,0.6,2.9,2.7,2.8c2.2-0.2,3.3,1.4,4.4,2.8
				c0.7,0.9,0.9,2,0.2,3.1c-0.6,1.1-1.8,0.5-2.7,0.6c-1.3,0.1-1.8-0.8-1.8-1.8c-0.1-2.3-1-2.2-2.5-1c-0.3,0.2-0.9,0.1-1.2,0.4
				c-1.5,1.1-3.4,2-4.6,0.5c-1-1.2-2.9-3-1.2-5.1c0.2-0.2,0.3-0.8,0.2-0.9c-3.7-3.1-0.4-6.1,0-9.2c0.2-1.8,1.2-3.3,0.8-5.3
				c-0.4-2,1.3-3.7,3.5-4.1c5.6-1,11.1-2.2,16.5-3.9c0.6-0.2,1.3-0.1,1.9,0.2c1,0.4,1.3,1.4,1.7,2.3c0.2,0.5,0,0.9-0.6,0.9
				c-1,0.1-1.7-0.4-2.2-1.1c-1.3-1.8-1.8-1.2-3.3,0.2C736.2,1412.7,735.2,1416.8,731.7,1420z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M676,1604
				c1,0.9,2.4,1.4,2.4,3.4c0.5-1.6,1.3-2.4,2.9-2c1.8,0.4,3.6,0.6,5.5,0.6c1.1,0,3.2,1.3,3.1-1.5c0,0,1-0.1,1.5-0.1
				c2.5-0.1,4.9-0.1,7.4-0.2c0,1.3,0.3,2.6-1.6,3.1c-0.9,0.2-1.6,1.1-1.4,2c0.2,1.1,1.3,1.3,2.3,1.4c2.7,0.2,5.4,0.5,8.1,0.8
				c1,0.1,1.9,0.4,2.8,0.7c0.7,0.2,1.8,0.2,1.4,1.3c-0.3,0.9-1.4,0.8-2,0.5c-2.2-0.9-4.7,0.8-6.9-1.4c-0.6-0.6-2.6,1-4.2,0.8
				c-12.9-1.8-26-1.8-39-3.7c-3-0.4-6-0.3-10.5-0.6c5.2-1.4,3.5-3.2,1.8-5.5c6.8,1.6,13.5,0.7,20.2,1.4c-0.9,1.5-0.2,2.2,1.2,2.4
				c1.4,0.2,3.4,0.9,4.2-0.7c0.7-1.6-1.4-1.3-2.2-1.9C673.8,1603.3,674.8,1603.3,676,1604z M686.2,1608.8c0.9-0.2,2.4,0.5,2.5-0.7
				c0.1-1.3-1.4-0.9-2.3-1.1c-1.1-0.2-2.2-0.2-3.2-0.5c-0.9-0.2-2-0.3-2.2,0.7c-0.3,1.3,1,1,1.7,1.1
				C683.9,1608.5,685.1,1608.6,686.2,1608.8z M694.2,1608.7c-0.3-1-1.1-1.2-2.1-1.1c-0.6,0.1-1.1,0.4-0.9,1.1c0.3,1,1.2,1.3,2.1,1.2
				C693.9,1609.8,694.3,1609.4,694.2,1608.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M896.4,1576.3
				c-0.3,0.9,0,1.6,0.9,2.1c5.1,3.1,9.8,7.4,16.6,5.1c0.4-0.1,1,0.3,1.5,0.5c0,1.9,2.2,1.4,2.9,2.7c0.4,0.7,1.5,1.1,1.1,2.2
				c-0.4,1.1-1.5,0.9-2.3,0.8c-2.7-0.3-5.4-1.4-8.1,0.6c-1,0.8-2.7-0.7-3.8-1.5c-8-6-15.9-11.9-23.8-18c-8-6.2-16.1-12.4-24.4-18.2
				c-0.9-0.6-1.9-1-2.2-2.2c0.9-1,1.7-0.5,2.4-0.1c5.9,3.6,12.6,5.7,18.1,10c0.3,0.2,0.8,0.2,1.3,0.4c-0.6,1.5,0.3,2.2,1.3,2.9
				c2.1,1.5,4.2,3.1,6.3,4.7c1.2,0.9,2.2,2.2,3.9,1.9C890.9,1572.2,893.7,1574.2,896.4,1576.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M770.6,1614.6
				c1.2,2.2-0.3,2.4-1.9,2.3c-2.2-0.1-4.4-0.9-6.7-0.3c-1.2,0.4-3-0.8-3.5,1.6c-0.2,0.9-2,0.3-3,0.4c0.4-1.9-1.5-2.2-2.3-3.1
				c-0.6-0.6-2,0.1-2.2-1c-0.7-3.4-3.2-2.4-5.4-2.4c0.4,1.1,2,0.1,2.3,1.4c-1.7,0.7-3.5,0.2-5.2,0.4c-1.7-2.6,1.7-2.5,2.4-3.9
				c2-4.1,5.6-4.9,10.3-3.5c6.5,1.9,13.2,2.9,19.8,4.3c1.1,0.2,2.4,0.2,3.4,1.5c-0.9,0.7-1.8,1.5-2.8,2c-1.3,0.6-1.6,0-2.4-1.2
				c-0.7-1.1-2.4-1.1-3.5-0.6c-3.3,1.3-6.7,0.7-10.1,1.2C763.5,1614.8,767,1615.3,770.6,1614.6L770.6,1614.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M914.1,1543.8
				c-0.4,1.1-1.2,0.9-2,0.9c-12.7,1.1-25.5,2.1-38.2,3.2c-0.8,0.1-1.5,0.1-2.3,0.1c-0.4,0-0.9-0.1-1-0.6c-0.1-0.5,0.1-0.9,0.5-1
				c4.5-1.3,7.3-5.8,12.1-6.8c3.2-0.6,6.1,0.2,9,0.7c6.4,0.9,12.8,1.8,19.3,2.6C912.5,1542.9,913.5,1542.9,914.1,1543.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M846.3,1567.6
				c-0.9-1.3-0.3-2.9-0.7-4.3c1.3,0,2.6,0.2,4-0.7c-1.3-2-3.4-3-5.1-4.3c-1.7-1.3-1.3-2.6-0.8-4c0.7-1.9,1.5,0.1,2.2,0.1
				c1.9,0.1,1.7-0.9,1.2-2.1c-0.2-0.5-0.3-1.1,0.3-1.5c0.5-0.3,1,0,1.4,0.4c1.2,1.3,3.1,2.4,1.5,5.3c5.7-4,7,3.1,11,3.3
				c-1.9,3.5-2.8,7.8-8.1,7.7c1,1.9,3.7,3.6,2,5c-1.4,1.1-5,1.4-6.3,0.3c-2.6-2.1,1.8-3.2,1.8-5.6
				C849.2,1568.2,847.7,1568.4,846.3,1567.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M710.4,1412.7
				c-3.4-2.7-7.2-4.6-11.3-5.8c-0.6-0.2-1.2-0.4-1.4-1.1c0.7-2.8,2.8-4.4,5.4-4.4c6.7-0.1,13.3,0.5,19.8,2.2c1,0.2,2.5,0.5,2.5,1.7
				c0,1.3-1.6,0.8-2.4,1.2c-3.3,1.9-7.3,2.1-10.7,4C711.2,1411.1,710.3,1411.4,710.4,1412.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M725.7,1609.5
				c-1-9.4-1-9.4,8.3-7.3c3.5,0.8,7.1,1,10.3,2.7c0.8,0.4,1,0.2,0.6,1.3c-1.1,2.8-4.1,3.4-5.9,5.4c-0.2,0.3-1.1,0.3-1.1,0.4
				c-0.3,3.3-2.7,2.1-4.5,2c-1.2-0.1-2.4-0.3-3.6-0.4C727.1,1613.7,725.2,1612.8,725.7,1609.5z M736.2,1611.2c-1-0.5-1.8-0.5-2.9,0
				C734.4,1611.6,735.2,1611.6,736.2,1611.2z M727,1610.7c1.3,0,1.9,0.3,2.4-0.2C729,1610,728.4,1610.1,727,1610.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M888.2,1570.1
				c-1.8,0.3-2.8-0.9-3.9-1.9c-2.1-1.6-4.1-3.2-6.3-4.7c-1-0.7-2-1.5-1.3-2.9c8.3-0.8,16.6-1.3,24.9-0.7c0,0.4,0.1,0.5,0.5,0.5
				c0.2,1.6-1.3,1.8-2.2,2.4C895.9,1565.3,892.1,1567.7,888.2,1570.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M658.7,1528.1
				c-1.4-1.1-2.9,0.5-4.3-0.3c-0.9-0.2,1.7-1.7-0.3-1.1c-6.5,1.8-13,1.2-19.5,0.3c-2.3-0.3-4.7-1-6.1-3.3c-0.4-0.7-0.9-1.3-0.3-2.1
				c0.4-0.6,1.2-0.7,1.8-0.5c8.2,2.9,16.8,1.6,25.3,2.1c3.3,0.2,6.5-0.7,10,0.3C661.9,1523.6,662.7,1528.9,658.7,1528.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M611.5,1590.4
				c-1.8-3.1-5.2-4.2-7.9-6.2c-0.8-0.6-2.3-0.9-1.9-2.3c0.4-1.4,1.8-0.9,2.8-0.9c2.6,0,5.2,0.2,7.8,0.3c4,1.5,8.3,1.1,12.3,1.9
				c1,0.2,2,0.1,2.2,1.4c-4.9,2.8-9.8,5.5-15.2,7.1C611.5,1591.3,611.5,1590.9,611.5,1590.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M693.7,1591.2
				c-4-1-8.2-1.1-12.3-1.6c-10.7-1.3-21.4-2.5-32.1-3.7c-1-0.1-2-0.1-2.9-0.1c2.5-1.7,5.1-2.4,8.2-2c12.3,1.5,24.5,2.9,36.8,4.2
				C693.7,1588.3,693.6,1589.7,693.7,1591.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M896.1,1636.5
				c0.9-0.1,1.6,0.3,1.9,1c0.4,1.1,0.4,2.3-0.8,2.8c-1.3,0.6-1.6,1.4-0.3,2c1.5,0.7,0.8,1.6,0.9,2.7c0.3,3.3-1.8,3.4-3.9,3.6
				c-2.4,0.2-4.7,0.8-7,1.6c-0.6,0.2-1.4,0.6-1.8-0.1c-0.5-0.9,0.3-1.5,1-1.7c2.6-0.5,2.4-2.3,2.3-4.2
				C888.2,1637.7,889.5,1636.3,896.1,1636.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M734.7,1530.1
				c1.3,1.4,2.2,1.7,4,0.5c1.9-1.3,4.5-2.4,6.2,0.8c0.7,1.3,1.6,2.6,2.5,3.8c0.5,0.8,0.7,2.1,0,2.3c-2.1,0.6-3.6,1.2-2.6,3.9
				c0.1,0.4-0.9,0.7-1.3,0.2c-2.2-2.1-2.6-0.5-2.9,1.8C737.9,1539.2,735.8,1535,734.7,1530.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M864.4,1598.9
				c-1.4,0.1-2.3,0.1-2.6-0.8c-0.3-1,0.4-1.4,1.3-1.9c5.6-3.2,11.2-6.4,16.7-9.7c1.2-0.7,4.8,0.7,4.9,2c0.1,0.9-0.6,1-1.2,1.1
				c-1.3,0.3-2.6,0.6-3.9,0.8c-2.2,0.2-3.2,1.1-2.6,3.4c0.3,0.9,0.2,1.7-1,2C872.1,1596.9,867.9,1598,864.4,1598.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M768.6,1506.1
				c-1.6-2.9-0.9-5.1-0.7-7.2c0.2-1.9-1.9-3.9-0.5-5.2c1.2-1,3.6-0.3,5.3-0.9c2.2-0.7,2.9-0.2,2.7,2c-0.1,1.3-0.2,2.6,0.5,3.8
				c0.2,0.4,0.6,1.2,0,1.4c-3.4,1.3-1.6,4-1.8,6.1c-0.1,0.8,0.3,1.8-1.1,1.9c-1.1,0.1-1.9-0.2-2-1.6c-0.1-1.6-0.2-3.2-0.4-4.9
				c-0.1-0.6-0.2-1.4-1.1-1.4c-0.9,0-0.9,0.8-0.9,1.5C768.5,1502.9,768.6,1504.1,768.6,1506.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M589,1558
				c2.9,0.2,5-2.5,8-2.3c1,0.1,2.5-1,2.9,0.3c0.5,1.3-1.2,1.6-2.1,2c-5.8,2.8-11.5,5.6-17.4,8.3c-1,0.5-2.4,1.8-3.1,0.3
				c-0.7-1.3-0.7-3.1,0.7-4.5C581.1,1559.1,585,1558.3,589,1558z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M751,1387.7
				c0,0.5-0.1,0.9-0.1,1.4c-1.5-0.1-2.9-0.2-4.4-0.3c-0.1-1-0.5-1.4-1.4-0.5c-0.4-0.1-0.8-0.3-1.2-0.4c-0.6-1.1-1.3-1.2-2.1-0.3
				c-1.3-0.1-2.5-0.3-3.8-0.4c-0.4-0.6-0.9-0.9-1.5-0.2c-1.5-0.2-2.9-0.3-4.4-0.5c-0.4-0.7-0.9-1.1-1.5-0.2
				c-0.6-0.1-1.2-0.2-1.8-0.3c-2.2-2.5,0.8-1.5,1.5-2.1c-0.2-1.2-1.7-0.4-2-1.7c2.7-0.9,5.2,0.3,7.8,0.5c3,0.2,6,0.9,9,1.2
				c1.4,0.1,1.6,1.7,2.8,1.8C748.6,1386.9,750.1,1386.9,751,1387.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M858.1,1586.6
				c1.5-1.9,3.3-3.6,2.5-6.3c-0.1-0.4,0.4-0.6,0.8-0.8c2.8-1.7,3-3,0.6-5.3c-0.7-0.7-1.8-1.4-1-2.4c0.7-0.9,1.6,0.1,2.3,0.4
				c3,1.4,5.3,3.8,8,5.7c0.8,0.6,1.2,1.6,0.2,2.1c-4.2,2.1-7.6,5.4-12.1,6.9c-0.4,0.1-0.8-0.1-1.3-0.1L858.1,1586.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M626.7,1584.6
				c-0.2-1.3-1.2-1.2-2.2-1.4c-4.1-0.9-8.4-0.4-12.3-1.9c5-1.7,10-1.1,15.1-0.7c1.9,0.2,3.3,0.4,5.2-0.7c2.6-1.5,5.8-1.1,8.9-0.8
				C636.4,1580.9,631.8,1583.4,626.7,1584.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M581.6,1532.1
				c4.6-0.1,7.5,2.9,11,4.5c0.9,0.4,1,1.7,0.2,2.3c-2.2,1.6-5,2.3-7.3,1.2c-2.4-1.2-6-1.4-5.9-5.8
				C579.7,1532.1,580.4,1532,581.6,1532.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M679.3,1585.1
				c-3.4-0.4-6.8-0.9-10.1-1.3c-1.8-0.2-2.7-1.3-2.9-3c-0.2-1.6,0.4-2.5,2-3.2c4.3-1.9,4.3-2,7,2
				C676.5,1581.4,678.8,1582.5,679.3,1585.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M859.2,1613.8
				c3-2,6.6-1.9,9.9-2.5c1.3-0.2,2.1,0.3,2.1,1.7c0,8.2,1.6,7.6-6.4,5.8c-0.8-0.2-1.5-0.3-2-0.9c0.1-1.3-0.3-2.4-1.9-2.5
				C859.9,1615.4,859.5,1614.7,859.2,1613.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M875.9,1642.3
				c0.6-1.7-1.2-2-1.8-3c-0.3-0.4-1-0.6-0.8-1.3c0.3-0.6,0.9-0.9,1.6-0.9c1.8,0.1,3.7,0.3,5.5,0.4c0.7,0.1,1.4,0.1,2.2,0.2
				c0.3,0.5,1,1.1,0.9,1.6c-0.2,2.5,0,5,0.3,7.5c0.2,1.8-0.9,1.6-2.1,1.2l0.1,0.1c-1-0.8-0.5-3.2-2.7-2.7l0.1,0.1
				c0.4-0.8-0.3-1-0.7-1.4l0.1,0.1c-0.2-0.2-0.5-0.5-0.7-0.7l0.1,0.1C877.5,1642.7,877.1,1641.7,875.9,1642.3L875.9,1642.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M858.1,1586.6
				l0.1,0.2c-1.1,1.4-2.6,2.3-4.2,2.9c-3.1,1.1-5.6,3.2-7.3,6c-0.9,1.4-2.1,0.7-3.1,1.2c-0.1-1.2,0.2-2.3,1.1-3.2
				c1-1,1.9-2.2-0.3-3.2c-1.3-0.6-0.4-1.4,0.3-1.8c1.1-0.6,2.5-1,2.3-2.7c2.5,1.5,4.8,3.4,8,1.3
				C855.8,1586.7,857.1,1586.8,858.1,1586.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M735.8,1580.6
				c-1-0.9-2.1-7-1.4-8.3c0.9-1.6,2.7-1.1,4.1-1.3c2.2-0.2,4.3-0.1,6.5-0.1C742.9,1575,739,1577.4,735.8,1580.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M742.8,1614.1
				c1.7-0.3,3.4,0.3,5.2-0.4c-0.3-1.4-1.9-0.3-2.3-1.4c2.2,0,4.7-1,5.4,2.4c0.2,1.1,1.6,0.4,2.2,1c0.9,0.9,2.7,1.2,2.3,3.1
				c-1.1,0.8-2.3,0.6-3.4,0.1c-0.1-0.8-0.1-1.6-1.2-1.2c-1.1,0.4-2.2,0.8-3.4,0.3c-1.4-0.5-2.8-0.6-4.2-0.1c0.4,1.1,1.2,0.5,1.7,0.8
				c0,0.5,0,1-0.1,1.5c-1,0.4-1.9,0.1-2.5-0.7c0.6-2.9-2.6-1.3-3.3-2.7C739.7,1615,741.5,1614.9,742.8,1614.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M675.9,1424.8
				c0.3-0.9,0.7-1.7,1.8-1.5c7.5,1.2,15.3,0.9,22.7,3.2c1.1,0.3,2.8,0.4,2.6,2.3c-5.6,0.2-10.9-1.5-16.3-2.1
				C683.1,1426.3,679.5,1425.5,675.9,1424.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M724.7,1642.3
				c-0.1,2.9,0.1,5.9-3.3,7.1c-0.4,0.2-0.8,0.8-1,1.2c-0.2,0.7,1,2.3-0.8,2c-1.4-0.2-1.7-1.8-1.8-3.1c0-0.5,0.4-1,0.7-1.4
				c1.2-1.9,1.3-3.6-0.6-5.2c-1.5-1.3-0.7-2.4,0.8-2.8c1.6-0.4,3.4-0.6,5-0.8C725.6,1639.1,724.1,1641.4,724.7,1642.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M646.4,1419.8
				c4.1-2.7,8.5-0.3,12.7-0.4c1.3,0,2.8,0.4,4.2,0.4c3.8-0.1,6.5,1.7,8.7,4.5c-8.1-1.3-16.1-2.5-24.2-3.8
				C647.4,1420.4,646.9,1420.1,646.4,1419.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.3,1640.4
				c0,0.8,0.1,1.5,0,2.3c-0.2,1.2,1.2,3.3-1.1,3.3c-1.9,0-3.9-1.2-4.3-3.3c-0.4-2.1-0.3-4.3-0.2-6.5c0.2-2.4,2.3-1.9,3.8-2.1
				c1.8-0.2,1.8,1.1,1.8,2.4C695.3,1637.8,695.3,1639.1,695.3,1640.4C695.3,1640.4,695.3,1640.4,695.3,1640.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M656.4,1397.4
				c-3.4-0.4-6.8-0.9-10.4-0.1c-2.6,0.6-4.9,0-5.1-3.6c-0.1-1.7-1-3.3-1.5-4.9c-0.2-0.5-0.2-1.2,0.4-1.4c0.7-0.3,1.1,0.3,1.1,0.8
				c0.1,1.9,1.5,3.3,1.6,5.2c0.1,2.6,2.3,3.6,4.2,2.5c3.2-1.7,6.8-2.3,10-4C656,1393.8,655.4,1395.6,656.4,1397.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M649.7,1601.6
				c0.5-2.7,1.8-5.2,4.5-5.5c2.8-0.3,5.2,1.3,6,4.3c-1.1,0-2.1,0-3.2,0c0.1,0.9,1.4,1.1,1.1,1.9c-0.5,1-1.6,0.6-2.3,0.5
				C653.7,1602.5,651.7,1602,649.7,1601.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.5,1658.6
				c0-0.4,0-0.8,0-1.3c1.8,0,2.8-1.5,4.3-2.1c1,1.1,2.1,1,3.1,0c0.4-0.4,0.5-1.2,0.1-1.5c-1.1-0.9-2.2-0.7-3.2,0.3
				c-1-0.4-2.1-0.3-3-1.9c4.8,0.2,9.2-0.5,13.6-0.8c0.5,0,1.2,0.1,1.1,0.8c0,0.3-0.5,0.7-0.9,0.8c-1.3,0.4-2.7,0.8-4.1,1
				c-1.9,0.3-2.9,1.3-2.8,3.2c-1.2-1.3-2.5,0.1-3.8,0C887.5,1657.7,886.2,1658.7,884.5,1658.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M664.5,1393.4
				c3.7,0.4,6.1,2.1,10.3,7.4c-3.8-0.2-7.2-1.1-10.5-1.9C664.8,1397,664.9,1395.2,664.5,1393.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M652,1625.5
				c7.4,3.4,13.9,8.1,19.9,13.6C664.7,1635.4,658.2,1630.6,652,1625.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M970.6,1635.7
				c-8.5-1.4-17.1-0.7-25.6-2.5c-1.6-0.3-3.6,1.9-4.8-0.7c1.9-1.5,4.2-0.7,6.2-1.1c1.2,1.6,2.9,1.7,4.7,1.7c5.7,0.2,11.3,0.4,17,1.3
				C969.1,1634.5,970,1634.7,970.6,1635.7L970.6,1635.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M893.8,1620.1
				c0.4-1.2-0.5-2.3,0-3.5c0.5-1,0.4-2.6,2.2-2.2c1.5,0.3,2.3,2,2,2.8c-0.8,2.1-0.3,4-0.3,6c0,0.7,0.2,1.7-0.8,1.9
				c-1,0.2-1.1-0.7-1.4-1.4C894.8,1622.5,895.2,1620.8,893.8,1620.1L893.8,1620.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M893.7,1620
				c-1.1,0.3-0.2,1-0.3,1.5c-0.1,1,1.9,2.7-0.7,3c-1.7,0.2-3.4-0.5-3.5-2.6c-0.1-1.5,0.4-3-1.1-4.1c-0.4-0.3-0.1-0.8,0.2-1.1
				c0.3-0.4,0.6-0.5,1.1-0.1C890.9,1617.8,892.3,1618.9,893.7,1620C893.8,1620.1,893.7,1620,893.7,1620z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M724.1,1617.1
				c-3.6-0.3-6.7-2.5-10.3-2.6c-0.2,0-0.6-0.4-0.6-0.6c-0.1-0.5,0.3-0.8,0.8-0.8c0.9-0.1,1.7-0.1,2.6-0.2c1.1-0.1,2.6,0.5,2.1-1.7
				c-0.1-0.3,1.1-0.4,1.5,0.3c0.3,0.9-0.1,2.2,1.5,2.4C723.4,1614.1,723.9,1615.6,724.1,1617.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M634.4,1607.6
				c-5.1,0.1-10-1.2-15.1-1.8c-0.5-0.1-0.8-0.3-0.8-0.8c0-0.6,0.6-0.7,0.9-0.6c5,1.4,10.2,0.6,15.2,1.5c0.6,0.1,1.4,0.3,1.3,1.1
				C635.9,1608,635,1607.6,634.4,1607.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M755.6,1401.4
				c-3.1,0.7-6.2,1.4-9.3,2c-1.6,0.3-2-0.8-2.2-2.1c-0.3-1.8,0.9-1.4,1.8-1.3c3.2,0.2,6.4,0.5,9.7,0.8
				C755.6,1401,755.6,1401.2,755.6,1401.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M724.1,1617.1
				c-0.2-1.6-0.6-3-2.5-3.2c-1.7-0.2-1.2-1.5-1.5-2.4c1-0.2,2-0.4,3-0.5c1.6,1.3,2.4,3.7,5.1,3.5c0.9,0,0.8,1.3,1.1,2.1
				C727.6,1617.4,725.8,1617.2,724.1,1617.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M930.8,1607
				c-4.1-1.5-7.5-4.2-10.2-7.5c-1-1.2,0.4-2,1.2-2.5c0.9-0.5,1.4,0.1,1.3,1.1c-0.2,1.3,0.9,1.5,1.6,2.2
				C927,1602.3,929.7,1603.9,930.8,1607z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M876.6,1620
				c0.7-0.9,1.1-2.3,2.5-1.1c0.7,0.6,1.1,1.6,0.5,2.3c-0.8,1-1.9,0.6-2.4-0.4c-0.7-1.3-1.2-0.1-1.8-0.1c-1.3,0.2-2.6,0-2.5-1.7
				c0.1-1.2-1.2-3.1,0.9-3.6c2-0.4,1.5,1.6,2,2.7C876.1,1618.7,876.3,1619.2,876.6,1620z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M909.4,1608.2
				c-2.1,0.9-4.4,1.3-6.7,1.2c-0.5-0.7-2-0.3-1.9-1.5c0.1-1.1,1.3-0.9,2-1.2c1.7-0.9,3.4-1.2,5.3-0.7
				C909.4,1606.4,909.6,1607.1,909.4,1608.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M735.8,1580.6
				c3.1-3.2,7.1-5.6,9.2-9.7c0.9,0.3,1.7,0.2,2.6-0.1c-3.2,4.2-6.7,7.9-11.2,10.7C736.2,1581.2,736,1580.9,735.8,1580.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M697.7,1630.3
				c0-0.6,0-1.1,0-1.7c0.1-0.8,0.2-1.7,1.1-1.7c3.3-0.1,6.5,0,9.8,0c0,0.4,0,0.7,0,1.1C705.1,1628.8,701.4,1629.6,697.7,1630.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M859.2,1613.8
				c0.2,0.9,0.7,1.6,1.7,1.6c1.6,0.1,1.9,1.1,1.9,2.5c-3.4-0.8-6.9-1.5-10.3-2.3c0-0.3,0-0.5,0-0.8
				C854.7,1614.5,857,1614.2,859.2,1613.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M778.1,1511.4
				c-2.4,0.7-4.9,0.4-7.3,0.1c-2.7-0.3-3.5-1.6-2.4-4C770.2,1512.2,774.9,1509.9,778.1,1511.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M745,1620.1
				c0-0.5,0-1,0.1-1.5c0.7-0.6,1.6-0.7,2.5-0.7c1.2,0.5,2.3,0.1,3.4-0.3c1.1-0.4,1.2,0.4,1.2,1.2c-0.4,0.2-0.9,0.2-1.2,0.5
				C749.2,1621.5,747.2,1621.5,745,1620.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M593.8,1543.3
				c0,0.3,0.2,0.8,0.1,0.9c-2.6,2.3-1.8,4.1,0.7,5.7c-2.1,0.6-4.4,0.6-5.4-1.4C588.2,1546.7,590.6,1544.2,593.8,1543.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M739.1,1616.7
				c0.7,1.4,3.9-0.3,3.3,2.7c-1.6,0-3.2,0.2-4.8,0.1c-0.8,0-2.2,0.5-2.3-1c-0.1-1.4,1.2-1.6,2.3-1.8
				C738.1,1616.6,738.6,1616.7,739.1,1616.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M729.3,1616.6
				c-0.3-0.8-0.2-2.1-1.1-2.1c-2.7,0.1-3.5-2.2-5.1-3.5c0.9-0.5,1.8-1,2.6-1.5c-0.5,3.3,1.4,4.2,4.2,4c-0.1,2.2,2.1,0.9,2.9,2.2
				C731.6,1616,730.5,1616.3,729.3,1616.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M598.5,1550.5
				c-0.3-2.2,0-3.4,2.5-1.8c1.8,1.2,4.1,1.8,5.3,3.9C603.3,1553.3,601.3,1550.6,598.5,1550.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M673.3,1604.8
				c0.8,0.6,2.9,0.3,2.2,1.9c-0.7,1.6-2.7,0.9-4.2,0.7c-1.4-0.2-2.1-0.9-1.2-2.4C671.1,1604.5,672.2,1604.8,673.3,1604.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M611.5,1590.4
				c0,0.4,0.1,0.9,0.1,1.3c-2.2,1.3-4.5,1.8-7.1,1.5C606.9,1592.5,608.7,1590.5,611.5,1590.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M879.2,1634.8
				c-0.8,0.2-1.7,0.3-2.5,0.6c-0.9,0.3-1.7,0.5-2.5,0c-0.6-0.4,0.1-0.9,0.3-1.4c0.4-1.1,0.9-2,2.3-1.6c0.8,0.2,1.7,0.4,2.5,0.7
				C879.6,1633.6,879.5,1634.2,879.2,1634.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M731.2,1621.8
				c1.3,0.4,3.6-0.4,3.6,1.2c0,1.6-2.3,0.5-3.6,1.1c-0.9,0.5-2.2-0.3-2.2-1.3C728.9,1621.2,730.4,1622,731.2,1621.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M882.3,1620.8
				c-1.6-1.3-2.4-2.6-2.1-4.3c0.1-0.5,1-0.6,1.4-0.3C883.2,1617.4,882.7,1618.9,882.3,1620.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M879.2,1634.8
				c0.4-0.6,0.5-1.1,0.1-1.7c1.7,1.2,4,1.9,3.4,4.8c-0.7-0.1-1.4-0.1-2.2-0.2C879.6,1636.9,880.2,1635.5,879.2,1634.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M646.3,1603.7
				c-2.6,0.8-5.1,0.5-7.5-0.8C641.4,1602.1,643.8,1603,646.3,1603.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M930.8,1647.8
				c-1.7,0.3-3.4,0.6-5.2,0.8c-0.4,0.1-0.9-0.2-0.9-0.7c0-0.5,0.3-0.8,0.8-0.8C927.2,1647,929,1646.5,930.8,1647.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M662,1601.1
				c1.8-0.1,2.8,0.9,3.5,2.5c-1.2,0-2.4,0-3.5,0c-0.8,0-1.6-0.2-1.7-1.1C660.1,1601.1,661.3,1601.3,662,1601.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M703.7,1600.9
				c0-0.6-0.2-1.1-0.7-1.4c0.6-1.1,1.2-2.3,1.8-3.4c0.3,0.1,0.6,0.2,0.9,0.3C705.8,1598.1,705.2,1599.6,703.7,1600.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M888.9,1654.1
				c1-1,2.1-1.1,3.2-0.3c0.4,0.3,0.3,1-0.1,1.5c-1,1-2.1,1.1-3.1,0C888.8,1654.9,888.8,1654.5,888.9,1654.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M678.5,1603.1
				c2.7-0.6,5.3,0.9,8,0.9c-2.7,0.1-5.4,1-8.1,0C678.4,1603.6,678.4,1603.4,678.5,1603.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M723.4,1580.5
				c0-0.9,0.4-1.5,1.1-1.5c1.1-0.1,1.5,0.6,1.6,1.6c0,0.7-0.1,1.4-1,1.4C724,1582,723.7,1581.2,723.4,1580.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M887.2,1638
				c-0.3-2.1-0.1-3.5,2.5-2.4C888.9,1636.4,888.1,1637.2,887.2,1638z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M902,1560.4
				c-0.3,0-0.5-0.1-0.5-0.5c0.3-1.7,1.5-1.7,2.7-1.5c0.2,0,0.5,0.5,0.5,0.7C904.4,1560.7,902.9,1559.8,902,1560.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M630.4,1542.4
				c-0.1-3.5,2.5-2.6,4.5-2.6C633.7,1541.2,632,1541.8,630.4,1542.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M628,1417.1
				c1.4-0.9,2.9-1.4,4.2,0.2C630.7,1417.7,629.3,1418.2,628,1417.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M778.3,1615.4
				c-0.5-0.1-1.5,0.1-1.4-0.8c0.2-1.2,1.4-1.1,2.3-1.3c0.5-0.1,1.4-0.2,1.1,0.9C780.1,1615.3,779.3,1615.5,778.3,1615.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M620.3,1546.9
				c0.6-1.6,1.4-2.7,3.2-1.5C622.6,1546.4,621.7,1547.1,620.3,1546.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M634.2,1602.8
				c-0.1,0.7-0.7,0.9-1.4,0.9c-0.7,0-1.3-0.3-1.4-1c-0.1-0.8,0.6-1.1,1.3-1C633.4,1601.8,634.1,1601.9,634.2,1602.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M675.1,1640.7
				c-1.2,0.5-1.8,0-2.2-0.6c-0.1-0.1,0.1-0.7,0.3-0.8C674.1,1639.2,674.3,1640.1,675.1,1640.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M678.7,1643.1
				c0.6,0.1,1,0.4,1.1,0.9c0,0.2-0.3,0.6-0.5,0.6c-0.6,0-0.9-0.4-1.1-0.9C678,1643.2,678.3,1643.1,678.7,1643.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M623.7,1416.3
				c1-0.6,1.9-0.8,2.4,0.6C625.2,1417.2,624.4,1417.2,623.7,1416.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M676.6,1642.8
				c-0.6-0.1-1-0.2-1-0.7c0-0.2,0.4-0.5,0.6-0.5c0.4,0,0.9,0.1,0.9,0.7C677.1,1642.5,676.8,1642.7,676.6,1642.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M741.8,1387.5
				c0.9-0.9,1.6-0.7,2.1,0.3C743.1,1388,742.4,1388.1,741.8,1387.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M624.8,1544.8
				c-0.1-1.3,0.5-1.7,1.7-1C626.2,1544.7,625.6,1544.9,624.8,1544.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M627.4,1543.5
				c0.1-1.3,0.6-1.7,1.7-0.8C628.8,1543.5,628.2,1543.7,627.4,1543.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M618.2,1548.1
				c0-1.3,0.6-1.5,1.6-1C619.6,1547.9,619.1,1548.3,618.2,1548.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M745.1,1388.3
				c0.9-0.9,1.2-0.5,1.4,0.5C745.9,1388.8,745.3,1389.1,745.1,1388.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M736.5,1386.9
				c0.6-0.8,1.1-0.5,1.5,0.2C737.5,1387.4,737,1387.3,736.5,1386.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M730.6,1386.2
				c0.6-0.9,1.1-0.4,1.5,0.2C731.5,1386.8,731,1386.7,730.6,1386.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M700.4,1603.9
				c0.1-0.7,0.6-0.9,1.2-0.9C701.6,1603.8,701.5,1604.5,700.4,1603.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M615.1,1448.7
				c0,0.1,0,0.2,0,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.3-0.1-0.3-0.3,0-0.4C614.9,1448.6,615,1448.7,615.1,1448.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M736,1581.9
				c-0.3,0.3-0.5,0.5-0.8,0.8C735.2,1582.1,735.5,1582,736,1581.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M947.3,1619.8
				c0.4-0.5,0.9-0.7,1.6-0.5c0,0.6-0.4,0.8-1,0.7C947.7,1620,947.5,1619.9,947.3,1619.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M770.7,1614.7
				c0.1-0.5,0.3-1,1-0.8c0.1,0,0.3,0.5,0.2,0.6C771.5,1615,771.1,1615,770.7,1614.7C770.6,1614.6,770.7,1614.7,770.7,1614.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M916.6,1583.9
				c0.2-0.4,0.5-0.5,0.8-0.1C917.1,1583.8,916.8,1583.8,916.6,1583.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M572.8,1536.9
				c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1,0,0.2,0C573,1536.7,572.9,1536.7,572.8,1536.9L572.8,1536.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M911.5,1609.4
				c-0.2-0.3-0.4-0.5-0.5-0.8C911.4,1608.7,911.6,1609,911.5,1609.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M946,1618.4
				c0.1,0.3,0.1,0.7,0.2,1C945.4,1619.2,945.5,1618.9,946,1618.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M499.3,1557.9
				c0.8,1.3,0,2-0.8,2.9c-3.9,4.4-8.7,7.2-14.2,9.1c-1.4,0.5-2.8,1.1-4.3,1.6c-3,1-5.9,1.9-9.2,1c-4.1-1.1-8.3-1.7-12.5-2.5
				c-0.6-0.1-1.4-0.2-1.5-1c-0.1-0.7,0.5-0.9,1-1.2c5.3-3.8,10.4-7.9,15.9-11.4c5.9-3.7,12.5-3.3,19-2.1c1.7,0.3,3.5,0,5.1,1.1
				C498.9,1556.1,500.2,1556.3,499.3,1557.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M396.6,1641.1
				c0.8,3.2-1.6,3.6-3.7,3.8c-3.7,0.4-7.3-0.9-11-1.3c-4.1-0.5-8.1-1.3-12.2-1.9c-2.2-0.3-2.4-1.5-1.6-3.2c1.3-2.5,2.9-4.8,3.6-7.6
				c0.4-0.7,0.7-1.3,1.1-2c2.7-4,4.9-4.3,8.5-1.1C386.5,1632.2,391.5,1636.6,396.6,1641.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M392.7,1616.8
				c2.2,7.3,4.5,14.5,4.6,22.2c-3.2-0.8-4.6-3.7-7-5.6c-2.8-2.1-5.3-4.5-7.8-6.9c-2.3-2.2-2.2-3.6,0.5-5.3c2.6-1.6,5.4-2.9,8-4.3
				C391.6,1616.8,392.1,1616.8,392.7,1616.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M385.4,1523
				c1.8-3.9,4.7-4.8,7.6-5.5c9.8-2.4,19.6-4.8,29.4-7c5-1.1,10.1-1.9,15.2-2.8c0.2,1.6-0.8,1.5-1.5,1.7c-4.4,1.4-8.7,3.3-13.5,3
				c-1.2-0.1-2.6,0.5-3.8,1C408.4,1518,397.2,1519.9,385.4,1523z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M467,1428.4
				c0.2,7.2,1.2,6.6-6.5,5.3c-3.8-0.6-5.4-1.5-5.4-5.6c0-8.3-0.3-8.2,7.9-7.9c2.9,0.1,4,1.3,3.9,3.9
				C466.9,1425.5,467,1426.9,467,1428.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M523.2,1530.1
				c-0.8,1.1-2.3,1.6-2.8,2.9c-0.3,0.8-1.3,1.8-0.1,2.6c1,0.8,2.3,0.7,2.9-0.3c2.1-3.4,3.9-1.8,5.5,0.3c1.5,2,0.6,5.8-1.8,6.8
				c-1,0.5-1.1,1.1-1.4,1.9c-1.2,3-3.9,3.8-6.7,4.2c-1.5,0.2-2.6-1-2.5-2.4c0.3-4-0.5-8.2,1.6-12.1
				C519.1,1531.9,520.4,1530.1,523.2,1530.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M425.1,1550.8
				c4.5,0.3,9-0.3,13.4-0.7c1.8-0.2,2.1,0.1,1.8,1.8c-1.1,5.5-2,6.1-7.8,5.3c-4.4-0.6-8.9-1.3-13.3-1.7c-1.8-0.2-2.5-0.8-2.6-2.6
				c-0.1-1.9,1.2-2.1,2.6-2.1C421.2,1550.8,423.2,1550.8,425.1,1550.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M455.7,1448
				c-0.6-8.9,0.4-6.4,7.3-5.8c1.5,0.1,4.2-0.6,4.7,1.9c0.5,2.9,0.7,6-0.8,8.6c-0.9,1.6-3.3,1.9-5.3,0.8c-1-0.6-1.9-1.2-3-0.4
				c-2.6,1.9-2.8,0.2-2.9-1.8C455.6,1450.1,455.7,1449,455.7,1448z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M385.1,1551.7
				c4-2,8.1-3.2,12.4-4.1c0.7-0.1,1.5-0.9,2,0.1c0.7,1.4,0.3,3,0.2,4.5c-0.1,0.9-1,0.9-1.8,0.8c-1-0.1-2-0.3-2.9-0.2
				c-0.9,0.1-2.3-0.5-2.4,0.9c-0.1,1.3,1.2,1.3,2.2,1.4c3.9,0.5,7.8,0.9,11.7,1.3c1.3,0.1,2.6,0.3,3.9,0.5c0.9,0.1,1.9,0.4,1.7,1.5
				c-0.2,1.3-1.3,0.9-2.1,0.7c-5.4-1.3-10.8-2.4-16.3-3.1c-0.6-0.1-1.2-0.1-1.1-0.8c0.5-3.4-1.9-3.1-4-2.9
				C387.3,1552.5,386.3,1551.7,385.1,1551.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M425.4,1561.7
				c-2-1.5-4.5,0.2-6.6-0.9c-0.9-0.5-2.4,0-2.2-1.7c0.2-2,1.8-1.2,2.8-1.1c5.2,0.6,10.5,1.3,15.9,2c-0.3,1.4-1.1,2.1-2.3,2.5
				C430.6,1561.1,427.9,1562.1,425.4,1561.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M447.3,1489.9
				c0,2.3-6,6.8-7.8,6.1c-2-0.8-1.6-2.5-0.7-3.8c1.3-1.7,2.9-3.2,4.4-4.7c0.5-0.5,1.4-0.7,1.8-0.2
				C445.6,1488.3,447,1488.8,447.3,1489.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M406.8,1573.6
				c-3.6-0.2-7.1-0.3-10.7-0.5c-1.1,0-2.1-0.5-2.2-1.5c-0.1-1.4,1.3-1.3,2.2-1.3C399.9,1570.4,403.5,1571.3,406.8,1573.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M365.7,1635
				c0.6,2.2-0.2,4.2-2,5.2c-1.2,0.6-3.1,0.6-3.6-1.4C361.6,1637,362.8,1634.6,365.7,1635z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M401.6,1575.6
				c3-1.6,6.1,0.6,9.1-0.4c0.5-0.2,1.1,0.3,1.1,1c0,0.8-0.7,1.2-1.3,1.1C407.5,1576.7,404.4,1577,401.6,1575.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M425.4,1561.7
				c2.5,0.4,5.2-0.7,7.6,0.8c-0.1,1.5-1,2.2-2.4,1.8C428.7,1563.9,426.2,1564.4,425.4,1561.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M382.5,1572.2
				c1.5,0,2.9,0,4.3,0c1,0,1.2,0.6,1.2,1.5c0,1.1-0.8,1.3-1.6,1C385.1,1574.2,383.1,1574.5,382.5,1572.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M539.6,1586.4
				c0,0.8-0.1,1.5-1.1,1.5c-1.2-0.1-1.2-1-1.3-1.8c0-0.7,0.1-1.3,1-1.3C539.4,1584.7,539.6,1585.5,539.6,1586.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M387,1570.2
				c-1,0-2,0-2.1-1.2c-0.1-0.9,0.6-1.3,1.5-1.3c1.1,0,2.1,0.2,2.2,1.4C388.7,1570.1,387.7,1570.1,387,1570.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M520,1561.8
				c1,0.5,1.8,1.1,1.6,2.4c-0.1,0.8-0.7,1-1.4,0.8c-1.1-0.3-1.6-1-1.5-2.1C518.7,1562.2,519,1561.6,520,1561.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M420.9,1573.5
				c-0.7,0-1.3-0.2-1.2-1.1c0.1-0.9,0.7-1.3,1.5-1.4c0.8-0.1,1.2,0.4,1.2,1.2C422.4,1573.2,421.7,1573.5,420.9,1573.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M376.9,1616
				c0,1.3-0.6,2-1.9,1.9c-0.2,0-0.5-0.5-0.4-0.6C375,1616.4,376,1616.1,376.9,1616L376.9,1616z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M379.3,1573.2
				c-1.3,0.2-2.2,0.6-2.9-0.5C377.4,1571.7,378.2,1572.4,379.3,1573.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M376.9,1616.1
				c0-0.4,0-0.9,0.5-1c0.4-0.1,0.6,0.2,0.5,0.6C377.8,1616.1,377.3,1616.1,376.9,1616.1L376.9,1616.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M440.3,1508.1
				c-0.2-0.2-0.4-0.3-0.6-0.5c0.2-0.1,0.4-0.3,0.5-0.3c0.2,0.1,0.4,0.3,0.7,0.4C440.7,1507.8,440.5,1507.9,440.3,1508.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M540,1596.3
				c-0.2,0-0.4,0.1-0.7,0.1C539.5,1596.2,539.7,1595.9,540,1596.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M766.2,1914.3
				c-0.6,0.5-1.2,0.9-2.2,1.7c6.2,1.5,12.1,1.1,17.9,1.6c7.8,0.6,15.5-0.5,23.3,0.7c5.1,0.8,10.4,0.2,15.6,0.2c0.9,0,1.8-0.1,2.6,0
				c2.1,0.2,4.3,0.5,4.3,3.4c-0.1,2.5-2.3,3.7-5.1,3.5c-6.8-0.6-13.6-0.8-20.5-0.8c-6.7,0-13.5-0.3-20.3-0.4c-5.4,0-10.7,0.1-16.1,0
				c-1.4,0-2.2,0.5-2.9,1.5c-0.3,0.4-0.9,0.7-1.4,0.3c-0.3-0.2-0.5-1-0.4-1.1c2-2.3,0.3-5,0.7-7.4c0.5-2.7,2.2-4.2,4.6-3.6
				C766.4,1914.1,766.4,1914.2,766.2,1914.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M766.2,1914.3
				c0.2-0.1,0.3-0.2,0.1-0.4c7.3-1.3,14.5-2.6,21.8-3.8c7.2-1.1,14.5-2.4,21.8-2.3c1.8,0,3.1,0.3,4.7,1.6c2.7,2.2,6.1,3.4,9.8,2.8
				c2.2-0.4,3,0.4,3,2.6c0,2.1-1,2.5-2.9,2.4c-10.5-0.5-21.1-1.1-31.7-1.2C783.9,1915.8,775,1915.4,766.2,1914.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M848.6,1994.4
				c-0.1,0.7,0,1.6-0.8,2c-0.6,0.3-1.1,0.2-1.4-0.3c-1.3-2.2-3.1-1.1-4.6-0.6c-3.9,1.2-7.8,1.8-11.8,1.8c-1.8,0-3.5,0-4.2-2.2
				c-0.5-1.6-1.9-0.6-2.8-0.8c-0.8-0.2-1.9,0.3-2.1-0.9c-0.2-1.2,0.9-1.6,1.9-1.9c1.8-0.6,3.5,0.1,5.1,0.8c1.5,0.7,2.7,0.7,4.1-0.4
				c2.9-2.1,6.3-2.8,9.9-1.6c1.1,0.4,2.4,0.2,3.5,0.3C847.7,1990.8,848.7,1991.9,848.6,1994.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M823.3,1965.4
				c-0.1-1.4,2.8-2,0-3.5c-0.6-0.3,0.1-2.8,0-4.3c-0.1-1.1-0.5-2.1-1.7-0.7c-0.2,0.2-0.6,0.6-0.6,0.6c-3.7-3.3-2.4,1.1-3.3,2.1
				c-0.7,0.7-0.4,2.7-2.2,2.1c-2.5-0.8-3.4-2.7-2.9-5.3c0.3-1.6,1-2.5,2.6-1.9c3.4,1.2,6.3-1,9.3-1.6c2.5-0.5,3.5,0,4.2,2.4
				c0.7,2.3,0.8,4.6,0.4,6.8C828.5,1964.9,825,1963.8,823.3,1965.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M803.4,1967
				c-2.4,0-4.7-0.1-7.1-0.1c0.7-2.2,1.5-3.4,4.1-1.9c1.7,1,3.2,0.2,2.6-2c-0.7-2.5-0.3-4.9-0.5-7.4c0-0.9-0.9-2.2,1-2
				c1.2,0.1,2.4,0.6,2.3,2.2c-0.2,2.6-0.7,5.3-0.6,7.9C805.4,1965.5,802.6,1965.1,803.4,1967z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M783.4,1959.9
				c-0.1,0.9-0.2,1.7-1.2,1.7c-1,0-1.5-0.6-1.8-1.6c-0.6-2.2-2.9-3.3-5.2-2.5c-0.8,0.3-1.7,0.2-2.5,0.4c-0.6,0.2-1,0.5-1.3-0.2
				c-0.3-0.5-0.7-1.2,0-1.7c0.6-0.4,0.8-0.9,0.9-1.6c0.1-1,0.1-2.7,1.3-2.4c1.3,0.3,0.3,1.6-0.2,2.4c-0.6,1.1,0.2,1.3,1,1.3
				c1.9,0.2,3.8,0.6,5.8,0.7C782.5,1956.6,782.8,1958.4,783.4,1959.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M770.1,1970.8
				c0.5-0.2,1-0.5,1.5-0.7c0.1,1.8,1.6,1.7,2.8,2.1c0.6,0.2,1.4,0.1,1.8,0.5c0.4,0.3,0.2,1.4-0.2,1.4c-2.6,0,0.2,5-3.7,3.7
				c-1.5-0.5-2-1.2-1.8-2.8C770.7,1973.6,770.2,1972.2,770.1,1970.8z M774.1,1973.4c-0.3-0.2-0.5-0.5-0.7-0.5
				c-0.2,0-0.6,0.2-0.6,0.4c-0.1,0.5,0.2,0.8,0.7,0.7C773.6,1973.9,773.8,1973.6,774.1,1973.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M818,1908.1
				c2.5,0,4.9,0,7.4,0c0.8,0,1.7,0.2,1.6,1.2c0,0.9-1,0.7-1.6,0.7C822.9,1910.1,820.2,1910.4,818,1908.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M810.7,1881.3
				c3-1.3,5.9-1.6,8.8,0.2C816.6,1881.8,813.6,1882.3,810.7,1881.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M782.5,1974.7
				c0.5-0.6,0.9-1.2,1.8-1.3c0.7,0,1.3,0.2,1.3,1.1c0,0.5-0.1,1.2-0.4,1.5c-0.7,0.7-1.1,0.1-1.4-0.6
				C783.5,1974.8,783.1,1974.6,782.5,1974.7C782.5,1974.7,782.5,1974.7,782.5,1974.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M804.8,1881.2
				c1.6-1.4,3.1-1.2,4.7,0C808,1882.1,806.4,1882,804.8,1881.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M834.5,1898.1
				c1.4,0.2,3-0.6,4,0.9C837,1899.5,835.5,1900.2,834.5,1898.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M757.6,1911.8
				c-2.3-0.3-1-2-1.3-3.1C758.5,1908.9,757.9,1910.4,757.6,1911.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M780.5,1881.7
				c0.4-1.5,1.1-1.7,2-0.4C782,1882.1,781.2,1881.9,780.5,1881.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M784.5,1881.4
				c0.6-1.3,1.3-1.3,2-0.1C785.9,1882,785.2,1882,784.5,1881.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M788.4,1881.4
				c0.6-1.3,1.3-1.3,2-0.1C789.8,1882.1,789.1,1882,788.4,1881.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M834.5,1881.9
				c0.7-1,1.3-0.9,2,0C835.8,1882.7,835.1,1882.6,834.5,1881.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M832.4,1897.9
				c0.1-0.7,0.5-1.1,1.2-1.1c0.7,0,1.1,0.5,1,1.2C833.8,1898,833.1,1898,832.4,1897.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M793,1881.3
				c0.5-1.1,0.9-1.1,1.4,0C793.9,1882,793.5,1882,793,1881.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M802.2,1881.2
				c0.5-1,0.9-0.9,1.4,0C803.1,1881.9,802.7,1881.9,802.2,1881.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M813.3,1897.4
				c0.2,0.1,0.3,0.3,0.2,0.5c-0.4,0.2-1,0.2-1.1-0.1C812.3,1897.2,812.9,1897.2,813.3,1897.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M757,1913.8
				c-0.1-0.4,0.1-0.6,0.5-0.4C757.6,1913.8,757.4,1913.9,757,1913.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M813.3,1895.4
				c0,0.3,0,0.7-0.1,1C812.7,1896,812.7,1895.7,813.3,1895.4z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				766.3,1992 766.5,1992 766.5,1992.1 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M803.8,1756.2
				c-2.4,1.6-9.2,2-10.3,1.1c-0.9-0.7-0.2-1.4-0.1-2.1c1-6.4,2.8-12.8,2.8-19.4c0-2.1-0.1-4.1-0.2-6.2c0-0.8-0.2-1.6-1.2-1.6
				c-0.9,0-1.5,0.5-1.7,1.3c-0.3,1.3-0.7,2.6-0.7,3.8c0,7-1.1,13.8-2.8,20.6c-0.9,3.7-2.3,4.3-5.6,1.9c4,1.2,4.1-1.2,4.7-3.4
				c1.7-6.7,2.2-13.5,2.9-20.4c0.1-1.4,0.4-2.8,0.8-4.1c0.5-1.5,1.8-1.7,3.2-1.6c1.5,0.1,1.6,1.2,1.6,2.4c0.2,4.1,0.5,8.3-0.1,12.4
				c-0.4,2.5-1.9,5.7,3,5.4c-1,2.1-3.5-0.2-4,1.8c-0.7,2.8-2,5.4-1.6,8.7c2.8-5.7,7.9-9.2,12.3-13.3c0.7,0.3-0.6,2.3,0.9,1.9
				c1.2-0.3,2.9-1.2,3-2.7c0.1-1.1-0.5-2.6-1.7-3.2c-0.9-0.5-0.9,0.9-1.4,1.5c-1.3-1.7-0.4-3.3-0.4-4.8c0-2-0.1-3.9-0.9-5.8
				c-0.5-1.2-1.4-1.6-2.6-1.5c-1.4,0.2-1.8,1.2-1.7,2.4c0,1.9,0,3.8-0.7,5.7c-0.6-2.4-1.1-4.8-0.4-7.2c0.4-1.2,0.6-2.6,2.3-2.8
				c1.6-0.2,2.8,0.5,3.6,1.8c0.6,1,1.2,2,1.8,3c1-0.3,0.9-1.2,1.3-1.8c1.6-3,4.1-3,6-0.3c2.9,4.1,4.2,8.1,0.4,12.3
				c-0.7,0.8-0.1,1.6,0.3,2.4c1.8,3.1,1.8,6.2-0.3,9.2c-0.4,0.6-0.8,1.3-1.1,2c-1.7,3.9-3.8,4.9-7.3,3.7c-2.3-0.8-3.8-4.3-3.2-7.2
				c0.4-1.5,2.1-3.7,0.9-4.4c-1.8-1-2.8,1.7-4.3,2.7c-0.2,0.1-0.4,0.2-0.5,0.4c-1.4,1.2-3.4,2.7-2.8,4.4
				C798.7,1757.2,801.2,1756.4,803.8,1756.2z M816.5,1749.6c-0.3-2-0.3-4.3-2.4-5.1c-2.2-0.9-4,0.6-5.4,2.3c-1.9,2.3-2.6,5-2.7,7.9
				c-0.1,1.6,0.4,2.8,2,3.3c1.6,0.5,3.3,1.1,4.6-0.3C814.9,1755.3,816.2,1752.6,816.5,1749.6z M809.7,1737.1
				c0.1,1.3,0.1,2.6,0.9,3.8c1.2,1.8,3,2.3,4.4,1.1c1.6-1.4,2.9-3.7,2.4-5.7c-0.6-2.3-0.8-5.3-3.5-6.7c-1.3-0.6-2.2-0.3-2.6,0.9
				C810.5,1732.7,809.5,1734.8,809.7,1737.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M728.6,1750.5
				c-4.2,3.2-9.6,3.7-12.3,1.4c-1.3-1.1-1.4-2.5-0.3-4.9c0.5,2.5,0.7,4.6,3.8,5.1c5.6,0.9,6.2,0.6,8.4-6.2c1.5-4.6,0.9-5.7-3.7-6.2
				c-0.7-0.1-1.5,0-2.8,0c1-1.7,1.9-1.5,3.1-1c4,1.6,7-0.5,7.1-4.7c0.2-4,0.7-8,0.2-12c-0.2-1.9,0.7-2.4,2.6-2.1
				c6.5,0.9,7.3,1.8,6.8,8.6c-0.2,2.3,0.6,4.5,1,6.6c1,6.3-0.9,12.6-3,18.6c-0.4,1-1.6,1-2.6,0.9c-1.2-0.1-2.4-0.3-3.6-0.2
				C729.1,1754.7,729.1,1754.7,728.6,1750.5z M735.9,1753.3c2,0.3,2.9-0.5,3.5-2.5c1.4-4.3,1.4-8.8,2.2-13.2
				c0.3-1.4-0.3-2.8-1.8-2.4c-2.4,0.6-5.1-2.1-7.2,0.7c-0.2,0.2-0.1,0.6-0.1,1c0.1,4.2-1.1,8.2-2,12.2c-0.5,2.3,0,3.6,2.5,3.8
				C733.9,1753,734.9,1753.1,735.9,1753.3z M739.9,1727.5C740,1727.5,740,1727.5,739.9,1727.5c0.1-0.7,0.1-1.3,0.1-2
				c-0.1-3.4-1.1-4.4-4.6-4.3c-3.3,0.1-1.9,2.6-2.1,4.1c-0.2,1.6,0,3.3-0.2,4.9c-0.3,2.7,1.3,3.2,3.6,3.3c2.6,0.1,3.3-1.2,3.2-3.4
				C739.9,1729.2,739.9,1728.4,739.9,1727.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M755.7,1690.6
				c-0.4-3.2-2.1-6.9,2.1-8.8c2.5-1.1,4.7,0.8,6.6,5.1c1.6-1.2,0.9-4.2,4.1-4.2c3.1,0,4.5,1.3,5.3,3.7c1.5,4.7,1.4,9.6,1.5,14.5
				c0.1,2.6-1.8,4.3-3.7,5.9c-1,0.9-3.4,1.9-2.5,2.8c1.3,1.3,3.4,2.9,5.8,1.7c0.4-0.2,0.7-0.6,1.2-0.2c0,1.2-1.1,2-1.9,1.8
				c-2.5-0.9-5.5,0.1-7.7-1.9c-0.2-0.2-0.7-0.5-0.8-0.4c-2.9,2.8-6.1-0.6-9.1,0.5c-1.9,0.7-2-1-1.4-2.2c1.1-2.2,2.7-4,5.2-4.6
				c2.6-0.6,3.8-2.2,3.8-4.9c0-4.3,0-8.7-1.1-12.9c-0.6-2.2-1.7-3.7-4.4-3.4c-2.7,0.3-2.9,2.3-3.1,4.3
				C755.6,1688.5,755.7,1689.6,755.7,1690.6z M774.3,1698c-0.2-2.7-0.5-5.6-0.7-8.5c-0.2-1.5-0.6-3-1.7-4.2c-1-1.1-2.2-1.4-3.6-1.2
				c-1.4,0.3-1.9,1.5-1.9,2.7c0.2,3.6-1.9,6.9-1.2,10.4c0.9,4.6-0.8,7.2-4.9,8.9c-1.2,0.5-3.1,1.2-2.9,2.7c0.2,1.3,2.2,0.9,3.4,1.4
				c3,1.2,5.8,1.6,7.4-2.3c0.3-0.8,1.3-1.7,2.1-2C774.1,1704.6,774.3,1701.5,774.3,1698z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M685.5,1800
				c-0.3,0-0.9-0.1-0.9-0.1c0.1-3.1-0.7-6.2-0.5-9.2c0.5-5.7,0.1-11.4,0.2-17c0-2.5-0.8-5.2,0.5-7.6c0.6-0.5,2,0.3,1.9-0.5
				c-0.6-4.1-0.7-4.1,3-4.1c2.5,2.9,0.1,6.1,0.4,9.2c0.5,4.9,0.3,10,0.4,15c0,0.7,0.3,1.2,0.8,1.7
				C689.6,1791.5,688.3,1796.1,685.5,1800z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M772.3,1668.8
				c4.4-0.9,8.6,0.6,12.8,0.9c6.5,0.5,12.9,1.7,19.5,1.7c4.3,0,5.7,1.2,5.7,4.5c-8.4-1.8-17.1-1.7-25.6-3.2
				c-5.9-1-11.9-1.6-17.9-2.3c-1.4-0.2-2.4-0.4-2-2.1c0.7-0.3,1.4-0.3,2,0.1C768.5,1670.4,770.4,1669.9,772.3,1668.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M783.3,1735
				c0.2,6.2-1.2,12.3-1.9,18.4c-0.4,3.4-3.7,4.9-6.9,3.7c-1.8-0.7-1.7-2.1-1.5-3.5c0.7-6.3,2-12.4,2-18.8c0-2.2,0.1-4.3,0.2-6.5
				c0.1-2,1-3.1,3.1-2.8c1.9,0.3,4.6-1.2,4.7,2.6C783.1,1730.5,783.2,1732.7,783.3,1735z M782.1,1735.3c0-1.6,0-3.6,0-5.6
				c0.1-2-0.5-3.4-2.9-3.4c-2.4,0-2.8,1.5-3,3.4c-0.2,3,0.3,6.1-0.2,9.1c-0.7,4.5-1.1,9.1-1.6,13.6c-0.2,2,0.3,3.4,2.7,3.6
				c2.2,0.2,2.8-0.8,3.2-2.7C781.3,1747.5,782.3,1741.6,782.1,1735.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M749.1,1755
				c-1.8-2.2-0.3-3.9,0.9-5.5c2.3-3.1,5.8-4.9,8.5-7.6c1.2-1.3,1.9-2.5,1.9-4.3c-0.1-3.4,1-6.8-0.2-10.3c-0.6-1.8-1-3.8-3.4-3.6
				c-2.3,0.2-3.5,1.8-3.5,4c0,1.9,0,3.8-0.9,5.5c-1.2-3.2-0.5-6.3,0.7-9.3c0.6-1.6,2.2-2,3.8-2.1c1.7-0.1,3.1,0.6,3.7,2.1
				c2.2,5.8,1.9,11.8,0.3,17.6c-0.7,2.7-4.2,3.7-6.2,5.7c-1.8,1.8-4.8,2.7-4.6,6.1C749.6,1753.7,748.8,1754,749.1,1755z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M790.4,1713.6
				c0.8-1.3,1.2-2.1,1.8-2.7c1.4-1.5,0.9-2.3-0.7-3.2c-2.4-1.4-3.7-8.8-2-10.9c1.1-1.3,2.5-1.5,3.8-0.9c1.4,0.6,3.2,0.9,3.2,3.1
				c0.1,2,0.5,4.1,0.7,6.1c0.2,1.2,0.4,2.4,1.9,2.8c1.6,0.4,2.1-0.7,2.8-2c0.1,1.4,0.2,2.6,0.2,3.7c-0.3,0-0.5,0.1-0.6,0.1
				c-1.7,0.1-3.3-2-4.8-0.9c-1.4,1,0.7,3-0.1,4.5c-1.8-0.2,0-3.6-2.6-2.7C792.9,1711.2,792.7,1713.1,790.4,1713.6z M794.9,1706
				c-0.2-2.1-0.3-4.3-0.6-6.4c-0.1-1.2-0.8-2-2.1-2.1c-1.2-0.1-2,0.4-2.1,1.5c-0.2,2.5,0,5,1.2,7.2c0.5,1,1.7,1.2,2.9,1.2
				C795,1707.3,795.2,1706.7,794.9,1706z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M824.7,1728.6
				c2.6,8.1,0.3,15.8-1.9,23.5c-0.9,3.2-2.3,6.2-3.5,9.4c-1-2.3-0.2-4.4,0.7-6.5c2.6-5.7,2.4-12.1,4.1-18c0.2-0.7,0.8-4.9-2.4-1.4
				c-0.2,0.2-0.6,0.3-0.9,0.2c-0.4-0.2-0.6-0.7-0.3-1.1C821.8,1732.5,822.1,1729.8,824.7,1728.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M778.7,1706.8
				c-1-0.3-0.8-1.1-0.8-1.6c0.2-5.7-0.7-11.3-1-16.9c-0.1-2-0.3-4.1,2.5-4.5c2.9-0.4,4.3,1.3,4.7,3.9c1,6.6,2.1,13.3,1.2,20.1
				c-0.6-5.1-1.1-10.3-1.7-15.4c-0.2-1.6-0.5-3.2-0.7-4.8c-0.3-2-1.6-2.4-3.3-2.2c-1.8,0.2-2,1.7-1.7,3c1.2,5.3,0,10.9,1.2,16.2
				C779.2,1705.2,779.4,1706.1,778.7,1706.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M739.6,1702.4
				c1.1,0.1,1.6,0.1,2,0.2c5.9,1,5.9,1,5.2-5.1c-0.6-5.3-1.2-10.7-1.8-16.4c1.7,0.9,1.6,2.2,1.7,3.2c0.6,6.6,1.2,13.2,1.7,19.8
				c0.1,1,0,2-0.2,2.9c-0.2,1.8-0.7,3.5-3.1,3.2c-2.5-0.3-5.3,0.1-5.7-3.6c1.6,1.5,3.3,2.4,5.5,2.5c1.1,0,2.3,0.2,2.4-1.4
				c0.1-1.4,0.2-2.9-1.9-3.2C743.7,1704.2,741.7,1704.5,739.6,1702.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M769.8,1744.1
				c3.2-5.6,2.9-11.2,1.8-16.9c-0.2-1.3-1.1-2-2.6-1.9c-1.3,0.1-2.6,0.1-3.3,1.6c-0.6,1.5-1,3.1-0.9,4.8c0.1,1,0.5,2.1-0.2,3.2
				c-1.7-2.6-0.4-9.3,1.9-10.8c2.5-1.7,5.9-0.3,6.5,2.9c0.9,4.9,0.5,9.8-0.4,14.6C772.3,1742.9,771.3,1743.6,769.8,1744.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M729.9,1736.1
				c0-2.8,0.1-5.7,0-8.5c-0.2-6.7-2.8-8.4-8.9-5.7c-0.3-1.4,0.5-2,1.6-2.4c3.3-1.2,6.9,0.7,7.9,4
				C731.8,1727.8,730.8,1731.9,729.9,1736.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M763,1749.6
				c0.7,1.8-2,2.8-0.8,3.7c2,1.5,4.3,2.9,7.2,1.9c-1.5,1.9-3.4,1.6-5.1,0.6c-1.2-0.7-2-1.7-3.2-0.2c-0.4,0.5-1.2,0.6-1.8,0.2
				c-0.8-0.6-0.5-1.5,0-2C760.7,1752.5,761,1750.4,763,1749.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M719.1,1687.2
				c1.2,6.5,1.2,13.1,1.9,19.7C718.8,1700.4,719.8,1693.7,719.1,1687.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M818.8,1765.9
				c7.2-0.6,14.3-2.1,21.5-3.3c0,0.2,0.1,0.5,0.1,0.7c-7.1,1.9-14.3,3.5-21.7,3.2C818.6,1766.3,818.7,1766.1,818.8,1765.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M749.1,1755
				c-0.3-1,0.5-1.3,1.1-1.7c2.6-1.1,4.2,1,6.2,2C754,1756.6,751.6,1753.6,749.1,1755z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M737.1,1702
				c-2.2,1.4-4.7-0.7-6.1,1.8c-0.1,0.3-0.4-0.5-0.5-0.8c-0.6-1.2-0.3-2.8-1.4-3.9c-0.4-0.4-0.4-1,0.3-1.2c0.6-0.2,1.6-0.2,1.6,0.5
				C730.8,1702.6,733.9,1701.9,737.1,1702z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M725.9,1679.2
				c-2.6,1-5-0.8-8,0.1C720.1,1677.1,723.6,1677.3,725.9,1679.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M805.8,1691.6
				c0.4-4.7,1.8-5.5,5.6-3.1c-1.4,0.1-2.5,0.1-3.6,0.4C806.6,1689.1,806.7,1690.6,805.8,1691.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M746.6,1739.7
				c0.7,0.1,1.8,0,1.6,1.1c-0.1,1-1.2,0.7-1.9,0.6c-0.7-0.1-1.8,0-1.8-0.9C744.6,1739.2,745.9,1739.9,746.6,1739.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M694.5,1711.8
				c0,0.5,0,1,0,1.5c-0.7,0.4-1.4,0.4-2.1,0.1c0-0.6,0-1.1,0-1.7C693.1,1711.4,693.8,1711.4,694.5,1711.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M706.8,1753
				c0.8-1.6,1.7-0.9,2.6-0.2C708.7,1754.5,707.7,1753.7,706.8,1753z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M785.8,1745.1
				c-0.1,0-0.2,0-0.3,0c-0.3,0-0.4-0.1-0.1-0.2c0.1,0,0.2,0,0.3,0C785.7,1744.9,785.7,1745,785.8,1745.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M707.6,1759.4
				c0.1-0.1,0.1-0.3,0.2-0.4C708.1,1759.3,707.9,1759.4,707.6,1759.4L707.6,1759.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M639.4,1865.6
				c-0.3-1.4-0.7-2.8-0.7-4.2c-0.2-3.5-1.9-4.9-5.3-4.6c-0.5,0-1-0.4-2.3-0.9c2.5-0.1,4-1.2,5.7-0.4c1.1,0.4,1.7-0.1,1.7-1.2
				c0-1-0.3-2.5-1.3-2.2c-4,0.9-7.4-1.6-11.4-1.7c1.8-1.3,3.3-1.3,5-0.4c1.6,0.9,3.5,0.3,5.2,0.6c1.3,0.2,1.9-0.6,1.6-1.8
				c-0.2-1.1,0.9-2.6-1.3-3.1c-2.9-0.6-5.8-1.3-8.7-2c-3.4-0.8-5.8-2.4-7.7-5.3c1.6-0.4,3.1-0.1,3.6,1.1c1,2.7,3.1,2.9,5.4,3.3
				c1.4,0.2,2.7,0.7,4.1,1.2c2.4,0.8,3.8-0.4,4.5-2.4c0.8-2.4-1.4-2.2-2.7-2.7c-1.8-0.7-3.6-1.3-5.2-2.5c1.8,0.5,3.7,0.9,5.5,1.5
				c1.5,0.5,1.9,0,2.2-1.4c0.6-3.4-0.2-5.2-4.1-7.8c3.1,0.1,3.8-1.4,3.6-3.8c-0.2-2.2,0-4.4-0.1-6.6c0-1-0.1-1.9,0.9-2.8
				c0.5,1.5-0.2,4.3,2.8,2.5c0.5-0.3,1,0,1.2,0.6c0.2,0.5,0.5,1.4,0.7,1.4c3.6-0.2,2.5,2.7,3.2,4.5c-1.5,1.2-2.8-2.1-4-0.1
				c-1.6,2.6-2.8,5.4-2.4,8.4c0.4,2.9-0.3,5.7,0.2,8.6c0.3,1.6,0.7,2,2.5,2.2c-4.5,2.1-1.2,5.4-1.6,8.1c-0.2,1.7,2.1,1.3,4,1.7
				c-1.6,0.2-2.6,0.1-3.4,0.5c-1.3,0.5-0.8,1.7-0.7,2.6c0.1,1,0.5,1.9,1.9,1.8c1-0.1,1.9-0.6,2.9-0.1c0.8,0.4,2.5,0,2.3,1.2
				c-0.2,1.7-1.7,0.7-2.6,0.6c-2.6-0.4-4.4,0.3-4,3.4c0.1,0.7,0,1.5-0.1,2.3C640.1,1865.5,639.8,1865.5,639.4,1865.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M654,1853.5
				c-1.7-2.1-2.8-3.9,1.1-4.7c0.6-0.1,0.6-0.9,0.5-1.4c-0.1-0.9-0.7-0.6-1.2-0.3c-0.7,0.4-1.1,0-1.2-0.6c-0.2-0.9,0.3-1.2,1.1-1
				c1.1,0.3,1.3-0.5,1.4-1.3c0.2-1.2-0.3-1.9-1.6-1.9c-0.8-2.3-0.8-5-2.5-7.2c-1,1.5-0.7,2.9,0,3.9c1.4,1.9,1.4,3.7,0.9,5.8
				c-2.1,0.5-4.6-1.9-6.5,0.8c-0.2,0.2-1.1-0.3-0.7-0.6c1.7-1.8-2.9-1.5-1-3.1c4.4-3.7,6-8.3,4.3-14.2c1.8,0.7,3.1,1.7,4.6,2.1
				c2.2,0.6,3.6,2.1,3.2,4.8c-0.5,3,0.7,5.9-0.1,9c-0.4,1.7,0.2,4,3.2,3.1c1-0.3,2.2,0,3.3-0.1c0.7,0,1.8,0.3,1.8-0.8
				c0-1-1.3-0.3-1.8-1.2c1.6-0.9,3.3-0.5,3.8,0.9c0.5,1.4-1.2,2.5-2.5,2.9c-1.2,0.4-2.6,0.2-3.9,0.2c-2.9,0-5.2,2-5.7,4.9
				c0,0-0.1,0.3-0.1,0.3L654,1853.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M679.2,1756.1
				c-1.8,0.5-1.7,1.9-1.5,3.3c0.2,1.2,0.4,2.2-1.4,2c-2.8-0.5-5.5-2.6-8.3,0c-0.2,0-0.4,0-0.7,0.1c-3.9,1-6.6,0.7-6.2-4.5
				c0.1-1.7-1.4-2.3-3-2.2c-1.1,0.1-2.2,0.1-3.3,0.1c-0.9,0-1.9-0.1-3-0.8c2.8-1.3,5.5-3.2,8.4-3.7c2.8-0.5,5.4-1.3,8-2.3
				c0.8-0.3,1.8-1,2.2,0.1c0.3,1-0.7,1.4-1.6,1.7c-1.5,0.4-3,0.7-4.4,1.2c-1.4,0.4-0.6,1,0,1.6c0.1,1.4,0.1,2.9-2.3,2.4
				c3,1.9,5.6,2,8.4-0.1c-1.3-1-3.1-0.5-3.4-2.2c2.2-0.4,4,0.9,6.1,1.1c1.7,0.1,3.3,0.3,5-0.3C678.7,1754.3,679.6,1755,679.2,1756.1
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M645.9,1754.1
				c-1.9,0.4-3.5-0.6-5.2-1.3c-3.2-1.2-6.6-0.5-10-0.6c-3.1-0.2-3.4-2-3.8-4c-0.5-2.6,2-1.9,3.2-2.6c4.3,3,8.7,6.1,14.4,5.9
				c0.5,0.3,0.9,0.5,1.4,0.8C645.8,1752.9,645.8,1753.5,645.9,1754.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M601.8,1783.6
				c-1.2-5.8-2.5-11.5-4.5-17.1c2.3,0,4.6,0.1,6.9,0.1c1.3,0,2.1,0.5,2.1,1.9c0,1.6-1,0.9-1.8,0.8c-0.4,0-1.1,0.3-1.1,0.4
				c0.6,2.2-2.2,4.2-0.4,6.4c0.9,1.1-1.6,1-1,2.7C602.4,1780,603,1782,601.8,1783.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M595.5,1761.5
				c-0.3-0.9-0.6-1.8-0.9-2.8c3.5-1.3,6.1,1.2,9.2,1.8c7.9,1.5,15.9,1.9,24,0.4c0.7-0.1,1.5,0,2.3,0.1c-5.8,1.4-11.7,2.2-17.6,1.6
				C606.7,1761.9,601,1763.4,595.5,1761.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M644.4,1751.4
				c-5.7,0.2-10-2.9-14.4-5.9C635.4,1743,643.5,1746.3,644.4,1751.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M679.2,1756.1
				c0.4-1.1-0.5-1.8-0.9-2.6c0.6-0.8,1.5-0.4,2.3-0.5c1,1.4,2.6,0.8,3.9,1c1.4,0.2,1.8,1.2,2.1,2.2c0.3,0.9,0.3,1.8-1.1,1.7
				c-0.6,0-0.8,0.3-0.7,0.8c0.1,0.5,0.5,0.5,1,0.7c2.4,0.7,3.9-0.6,5.2-2.4c0,1.6,0.3,3.2-1.2,4.4c-3.8-0.1-3.7-0.1-3,4.1
				c0.1,0.9-1.3,0-1.9,0.5c0-0.3,0-0.5,0-0.8c-0.1-0.6-0.2-1.2-0.2-1.7C684.8,1759.3,682.4,1756,679.2,1756.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M690.9,1756.9
				c-1.2,1.8-2.7,3.1-5.2,2.4c-0.4-0.1-0.9-0.2-1-0.7c-0.1-0.5,0.1-0.8,0.7-0.8c1.4,0.1,1.4-0.8,1.1-1.7c-0.3-1-0.7-2-2.1-2.2
				c-1.3-0.2-2.9,0.4-3.9-1c0.5-0.3,1-0.6,1.6-0.9c0.8,0.1,1.6,0.1,2.4,0.2c1.9,0.4,3.9,2.5,5.7-0.2c0.4,0.1,0.7,0.1,1.1,0.2
				C692.5,1753.9,691.7,1755.4,690.9,1756.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M639.4,1865.6
				c0.3,0,0.7-0.1,1-0.1c0.4,1.6-0.3,3.5,1.2,4.8c0.2,0.4,0.4,0.8,0.6,1.1c-1.4,2.4-0.4,5.1-0.6,7.6c-0.1,1.2,0,2.4,0,3.5
				c0,0.7,0,1.5-0.9,1.3c-0.9-0.1-0.6-0.9-0.6-1.6c0.1-2.1,0.1-4.3-0.7-6.4c0-0.5,0-1,0-1.5C639.5,1871.5,639.5,1868.5,639.4,1865.6
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M667.2,1752.7
				c0.3,1.7,2.1,1.2,3.4,2.2c-2.8,2.2-5.4,2.1-8.4,0.1c2.4,0.5,2.4-1,2.3-2.4C665.3,1752.6,666.3,1752.6,667.2,1752.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M642.2,1871.4
				c-0.2-0.4-0.4-0.8-0.6-1.1c4.2-0.8,8.5-0.5,12.7-0.1c0,0.6,0,1.1,0,1.7c-2.7-0.4-5.3-1.1-8,0.3
				C645.1,1872.9,643.8,1871,642.2,1871.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M652.6,1844.8
				c0.5-2,0.5-3.9-0.9-5.8c-0.7-1-1-2.4,0-3.9c1.7,2.2,1.7,4.9,2.5,7.2C653.4,1844.8,653.4,1844.8,652.6,1844.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M684.5,1752.2
				c-0.8-0.1-1.6-0.1-2.4-0.2c-1-2.8,0.3-4.7,2.4-6.3l0-0.1c0.2,0.7,0.4,1.4,0.6,2.2c0,0.6,0.1,1.1,0.1,1.7
				C684.9,1750.4,684.7,1751.3,684.5,1752.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M684.5,1745.7
				c0.8-0.8,2.2-2.4,2.2-2.2c0.4,2.6,5.3,0.2,4.5,3.9c-1.1,0-2.3-0.1-3.4-0.1C687.9,1744.4,685.6,1746.2,684.5,1745.7L684.5,1745.7z
				"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M647.4,1880.5
				c-0.4-2.1-2.7-1.6-3.8-2.7c-0.4-0.4-0.4-0.8,0-1.1c1.1-0.9,2.3-0.6,3.3,0.3c0.9,0.8,1.9,1.6,1.4,3
				C648.3,1880.1,647.9,1880.2,647.4,1880.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M668,1761.3
				c2.8-2.5,5.5-0.5,8.3,0C673.5,1762.3,670.8,1762.3,668,1761.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M687.8,1747.3
				c1.1,0,2.3,0.1,3.4,0.1c0,1.6,0.1,3.2,0.1,4.8c-0.4-0.1-0.7-0.1-1.1-0.2c0-1.8-1.2-2.4-2.7-2.8c-0.1-0.5-0.2-1-0.2-1.5
				C687.5,1747.6,687.6,1747.5,687.8,1747.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M645.9,1754.1
				c0-0.6,0-1.2-0.1-1.9c1.8-0.2,3.6-0.9,6.1-0.3C649.7,1753.2,647.9,1753.9,645.9,1754.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M637.5,1861.7
				c-2.8,1.5-4.8,1.5-7.4,0C632.7,1861.7,634.7,1861.7,637.5,1861.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M647.3,1866.5
				c2.1-0.4,3.4-1.2,4.6,0.3c0.1,0.2,0.1,0.8,0,0.8C650.3,1868.3,649.1,1867.6,647.3,1866.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M639.5,1874.5
				c0,0.5,0,1,0,1.5c-1.5,0-2.9,0-4.7,0C636.4,1874.3,638.1,1875.1,639.5,1874.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M685.2,1740.4
				c-0.3,1.6-1.6,1.2-2.7,1.3l0,0C683.3,1740.9,684.2,1740.5,685.2,1740.4L685.2,1740.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M684.5,1763.3
				c0.1,0.6,0.2,1.2,0.2,1.7c-0.7-0.1-1.3-0.4-1.2-1.1C683.6,1763.5,684.1,1763.3,684.5,1763.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M656,1853.7
				l-0.2,0.1l-0.1-0.2c-0.1-0.5,0.1-0.8,0.6-0.9c0.1,0,0.4,0.3,0.4,0.4C656.4,1853.3,656.2,1853.5,656,1853.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M641.9,1840.8
				c0,0.1,0,0.2,0,0.2c-0.2,0-0.4,0.1-0.6,0c-0.3-0.1-0.3-0.3,0-0.3C641.5,1840.6,641.7,1840.7,641.9,1840.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M687.2,1739.1
				c0.2-0.6,0.8-0.6,1.3-0.7l0,0C688.2,1738.9,687.7,1739.1,687.2,1739.1L687.2,1739.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M687.2,1739.1
				c-0.3,0.6-0.8,0.7-1.3,0.7l0,0C686.2,1739.2,686.7,1739.1,687.2,1739.1L687.2,1739.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M688.5,1738.5
				c0-0.1,0.1-0.2,0.1-0.3C688.6,1738.3,688.5,1738.3,688.5,1738.5L688.5,1738.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M685.2,1740.4
				c0.2-0.2,0.5-0.4,0.7-0.6l0,0C685.9,1740.2,685.7,1740.4,685.2,1740.4L685.2,1740.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M682.6,1741.7
				c-0.1,0.1-0.2,0.2-0.3,0.4c0-0.1,0-0.2,0-0.2C682.4,1741.8,682.5,1741.8,682.6,1741.7L682.6,1741.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M452,2047.6
				c-1.6,2.8-3.8,4.6-7.3,3.1c-0.6-1.9-1.1-3.8,2.1-3.4c1.8,0.2,1.8-1.3,2.2-2.3c1.1-2.2-0.9-7.4-3.1-8.3c-0.6-0.2-0.8-0.7-0.8-1.2
				c0-3.8,0-7.7,0.1-11.5c0-1.4,0.4-3,2.2-1c0.5,0.6,1.1,1.2,1.9,0.5c0.7-0.6,0.7-1.5,0.3-2.4c-0.3-0.7-0.5-1.6-1.6-1.1
				c-0.7,0.3-1.4,1.2-1.4-0.3c-0.1-1.2-0.6-3.1,0.5-3.6c1.9-0.8,4.1-2.3,6.3-0.6c1.6,1.2,2.6,0.8,3.1-1.2c0.6,0.9,1.3,1.6,0.9,2.3
				c-0.9,1.5-1.4,2.1,1,3c1.7,0.7,0.6,3.5-0.2,5c-1,1.6-1.9,2.9-0.7,4.8c0.4,0.6,0,1.7-0.2,2.5c-0.4,2.1-3.1,3.7-1.3,6.3
				c-2.8,1.2-1,3.4-1.3,5.1c-0.2,1.4-0.3,2.8-2.5,2.7C451,2046,451.4,2046.9,452,2047.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M508.1,2014.3
				c-0.8,1.4-1.3,2.4-2.1,3.9c-2.4-3.8-2-7.3-1.1-10.9c0.3-1,0.7-2,0.8-3.1c0.1-1.9,1.7-3.8-1.4-5.4c-1.7-0.9-0.4-4.1,0.7-6
				c0.6-1.1,2-1.9,0.4-3.2c-0.2-0.2-0.1-0.9,0.1-1.2c0.8-1.2,3.1-2.5,0.5-3.7c-1-0.5-1.6-2.7-3.8-1.4c-1.1,0.6-1.3-1.4-1.7-2.5
				c3.2,0.1,3.2,0.1,6.1-1.4c0.6,0.5,0.3,0.9,0,1.3c-0.4,0.5-0.9,1.1-0.1,1.6c0.5,0.3,1-0.2,1.4-0.5c1.2-0.7,2-3.2,3.6-1.5
				c1.4,1.4,0.3,3.1-0.9,4.5c-1,1.2-3,3.1,0.7,3.7c0.8,0.1,1.1,1,1,1.8c-0.7,9.2,0.1,18.5-0.1,27.7c0,2.5-2.2,2.9-3.4,4.2
				c-0.9,0.9-1.7-0.7-1.4-1.9C507.8,2018.6,508.9,2016.8,508.1,2014.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M514.2,1991.7
				c1.3,1.3,1.3,2.4,1.3,3.4c0.9,11.2,1.7,22.5,2.6,33.7c0,0.6,0.1,1.3,0.4,1.9c0.4,0.8,1.6,1.5-0.1,2.2c-1.2,0.4-2.4,0.7-3.1-0.8
				c-0.7-1.6-1.7-3.2-1.2-5c1.2-4.4,0-8.8,0.3-13.3c0.4-6.6-0.2-13.2-0.4-19.8C514.1,1993.4,514.2,1992.8,514.2,1991.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M489.5,1972.5
				c0.9,0.2,2,0.3,2.8,0.7c1.3,0.6,1.2,1.9,1.2,3.1c-0.1,1.4-1.2,1.3-2.2,1.3c-1.4,0-2.8,0.2-4.2,0.1c-1,0-2.5,1-3-0.7
				c-0.4-1.3,0.1-2.5,1.4-3.2c0.5-0.3,0.8-0.7,1.3-1C487.7,1972.7,488.6,1972.6,489.5,1972.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M489.5,1972.5
				c-0.9,0.1-1.8,0.1-2.7,0.2c-0.6-0.8-2.6-0.3-2-1.9c0.5-1.4,2.2-1,3.4-1.2c1.5-0.3,1.1,1.1,1.2,2
				C489.4,1971.9,489.4,1972.2,489.5,1972.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M501.5,1987.9
				c-0.9-1.3-0.5-2.2,0.2-3C502.5,1986.1,502.5,1986.1,501.5,1987.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M477.5,1949.9
				c-0.1-0.7-0.1-1.3,0.7-1.6c0.1,0,0.3,0.1,0.3,0.2C478.6,1949.2,478.1,1949.6,477.5,1949.9L477.5,1949.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M445.4,1952.5
				c0.5,0.1,0.9,0.3,1.4,0.4c0,0.6-0.3,0.9-0.9,0.7C445.3,1953.5,445.2,1953,445.4,1952.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M424,2000.9
				c-0.4,0-0.8,0-0.9-0.6c-0.1-0.5,0.3-0.6,0.7-0.7C424.5,2000,424.7,2000.4,424,2000.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M499.7,1980.4
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2C499.5,1980.2,499.6,1980.3,499.7,1980.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M476.8,1950.5
				c0-0.4,0.2-0.7,0.7-0.7l0,0C477.2,1950.1,477,1950.3,476.8,1950.5L476.8,1950.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.5,1658.6
				c1.7,0.1,3-0.9,4.6-1.3c0,2.1-0.5,4.1,0.8,6.2c0.5,0.8-0.6,3-1.1,4.4c-0.3,0.9-2,0.2-3-0.1c-1.7-0.4-1-2.1-1.2-3.3
				c-0.4-2.2-0.7-4.4-3.8-3.9l0.1,0C882,1660,883.3,1659.3,884.5,1658.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M632,1639.9
				c-9.2-1-18.5-1.1-27.7-1.9C613.7,1636.7,622.9,1638,632,1639.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M633.9,1640.5
				c3.9-0.5,7,0.4,10.1,0.8c2,0.2,3.8,0.7,5.7-0.1c0.5-0.2,1.5-0.5,1.7,0.3c0.2,0.9-0.6,1.4-1.4,1.4c-2.3,0-4.6,0.2-6.8-0.3
				C640.3,1641.8,637.5,1641.3,633.9,1640.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M772.3,1668.8
				c-1.9,1.1-3.8,1.6-5.5-0.4C768.7,1668.6,770.5,1668.7,772.3,1668.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M879.1,1645.5
				c2.3-0.5,1.8,1.8,2.7,2.7C879.9,1648.3,879.6,1646.8,879.1,1645.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M878.2,1665.3
				c0.9-0.6,1.5,0,2,0.6c0.1,0.1-0.2,0.6-0.3,0.7C878.9,1666.7,878.9,1665.7,878.2,1665.3L878.2,1665.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M897.5,1680.2
				c-0.6,0.4-1.3,0.5-1.9-0.1C896.3,1678.7,896.9,1678.7,897.5,1680.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M875.8,1642.2
				c1.3-0.5,1.6,0.5,2.1,1.3C876.7,1643.9,876.7,1642.4,875.8,1642.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M880.7,1660.7
				c-0.3,0.1-0.7,0.1-1,0.2C880,1660.8,880.4,1660.7,880.7,1660.7C880.8,1660.7,880.7,1660.7,880.7,1660.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M878.3,1665.3
				c-0.1-0.4-0.2-0.7-0.3-1.1c0.2,0.1,0.4,0.1,0.5,0.2C878.7,1664.7,878.8,1665.1,878.3,1665.3
				C878.2,1665.3,878.3,1665.3,878.3,1665.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M878.5,1644.1
				c0.4,0.4,1.1,0.7,0.7,1.4C878.7,1645.2,878.1,1644.9,878.5,1644.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M877.8,1643.5
				c0.2,0.2,0.5,0.5,0.7,0.7C877.9,1644.4,877.7,1644.1,877.8,1643.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M652.3,1640
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2C652.2,1639.8,652.2,1639.9,652.3,1640z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M846.3,1648.8
				c0,0.1-0.1,0.2-0.1,0.3C846.2,1649,846.3,1648.9,846.3,1648.8L846.3,1648.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M870.6,1663.8
				l0,0.1C870.6,1663.9,870.5,1663.9,870.6,1663.8C870.5,1663.9,870.6,1663.8,870.6,1663.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M209.2,2021
				c-1.2-1.4-2.3-1.8-3.7-0.2c-0.7,0.8-1.5,1.6-2.8,0.9c-1.1-0.6-1.7-1.7-1.4-2.7c0.7-2.5-2-3-2.4-5c-1.5,0.9,0,2.8-1.6,3.4
				c-0.3,0.1-0.3,0.6,0.1,0.6c3.3,0,1.8,2.6,2.8,4.5c-1.6-0.5-2.9-3.6-3.6,0c-0.1,0.7-1.1,0.7-1.7,0.5c-0.9-0.4-0.4-1.1,0-1.6
				c0.6-0.8,0.5-1.5-0.4-1.9c-0.8-0.3-1,0.7-1.5,0.9c-3.4,1.3-0.3,3.5-0.8,5.2c-1.3,0.3-2.2,1.8-3.8,1.4c0.5-4.3-1.5-5.1-5.2-3.7
				c-0.9,0.4-2.1,0-3.2,0c-0.8,0-1.6,0-1.5-1.1c0.1-0.9,0.7-1.4,1.6-1.1c2.5,0.6,4.7-1.2,7.2-0.7c-0.4-1.7-2.4-0.6-2.4-2.1
				c1.7-0.8,3.8-0.6,5.3-2c0.2-0.3,0.5-0.6,0.7-0.9l0,0c1.3-0.6,1.4-2.8,3.6-2.2c0.8,0.2,0.9-1,0.8-1.8c-0.1-1.4,1-1.5,1.9-1.6
				c1.1-0.1,1.6,0.4,1.4,1.5c-0.1,0.4-0.1,1.1,0.5,0.8c2.1-1.3,5.2,1.2,6.6-2.5c1.1,2.6-0.3,4.8,0.3,6.9c0.1,0.5-0.7,1.2,0,1.7
				c0.4,0.3,0.8,0.1,1.2-0.3c0.7-0.6,0.4-1.2,0.1-2c-1-2.7,1-5.5,3.9-5.5c1,0,1.7,0.3,1.9,1.4c0,1.3,0.5,2.6-1.1,3.5
				c-1,0.5-0.9,1.4-0.5,2.3C212.2,2019.6,211.1,2020.6,209.2,2021z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M188.1,1982.2
				c-1.9,0.1-3.7-0.5-5.1-1.6c-2.3-1.9-4.3-1.9-6.5,0c-2.8-0.4-4.5,2.1-7.5,3.1c1.5-3.2,5.9-4.2,4.8-8.1c1.2-1.7,3-2.5,5-2.9
				c1.2,1.4,2.8,1.4,3.9,0.3c0.8-0.8-0.5-1.4-1.1-1.9c0-1.3,0.8-1.3,1.7-1.1c-0.1,0.8,0.3,1.5,1,1.3c2.4-0.5,4.7,0.7,7,0
				c2.1,1.1,3.7,1.3,3.6-1.8c0.6,0.4,1.2,0.9,1.9,1.3c-0.1,1.8,2.2,3,1.3,5.1c-2.5-0.2-4.9-0.3-4.7,3.4c0.1,1.1-1.2,1.5-2.2,1.4
				C189.8,1980.7,188.8,1981,188.1,1982.2z M191,1972.8c-2.4-0.2-3.8,0.9-5.2,2.2c-0.4,0.4-0.9,0.8-0.6,1.4c0.3,0.6,0.9,0.9,1.6,1
				c1.9,0.3,3.2-1,4.7-1.8c0.9-0.5,2.6-0.1,2.2-1.7C193.4,1972.3,191.8,1972.9,191,1972.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M171.4,2010
				c0.2-1-0.1-2.1,1.2-2.5c5.2-1.2,9.5-4.4,14.2-6.6c2.1-1,4.1-2.1,6.1-3.1c1.1-0.5,2.5-1,3.1,0.4c0.6,1.4-1,1.4-1.8,1.8
				c-6.8,3.7-13.6,7.4-20.4,11.2C172.2,2012.1,171.2,2012.3,171.4,2010z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M172.4,1963.6
				c0.8,3.1,3.4,2.1,5.3,2c1.1,0,2.1-0.9,3.7-0.2c-2.6,1-4.8,2.2-7.4,2.6c0.8,2.4,3.8,0.9,5,3.4c-4.1-0.5-7.9-0.9-11.6-1.3
				c-0.1-0.6-0.2-1.3-0.2-1.9C167.3,1963.4,167.8,1963.1,172.4,1963.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M190.2,2016.2
				c-1.5,1.4-3.6,1.2-5.3,2c-2.8,0.8-2.6-1.5-2.7-3c-0.1-1.9,1.6-1.4,2.5-1.3c1.6,0.1,3.1-1.4,4.7-0.1c0,0.6,0.2,0.9,0.8,1.1
				C190.2,2015.4,190.2,2015.8,190.2,2016.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M136.9,1934.6
				c3.5-1.1,7.1-0.7,10.6,0c0,0.4-0.1,0.9-0.1,1.3c-2.6,0.5-5.3-0.3-7.8,0.8C138.5,1936.3,137.6,1935.5,136.9,1934.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M134.3,2013.9
				c-1.1-2.8-1.1-2.8,0-7.2C137.2,2009,137.3,2009.2,134.3,2013.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M137.6,1995.7
				c-0.9-4-0.9-4.1,4-3C139.9,1993.4,139.8,1995.9,137.6,1995.7L137.6,1995.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M177.5,2018.5
				c-1.7,0.4-3.3,0.3-4.3,1.6c-0.4,0.5-0.9,0.6-1.4,0.2c-0.4-0.4-0.7-0.9-0.4-1.4c0.9-1.3,2.6-1.3,3.9-2.1
				C176.2,2016.2,176.9,2017.3,177.5,2018.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M179.6,2014.6
				c1.4,0.2,1,1.7,1.3,2.5c0.3,0.8-0.3,1.7-1.4,1.7c-1.3,0-1.5-1-1.3-2.1C178.2,2015.8,177.9,2014.7,179.6,2014.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M174.4,1987.5
				c0.9,0.5,2.8-0.6,2.7,1.1c0,1.2-1.8,0.6-2.8,0.7c-0.8,0-2,0.1-2-1C172.4,1987,173.5,1987.4,174.4,1987.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M167,1971.3
				c0.5,2.3-1.5,4.2-1.1,6.5C164,1975.3,165.7,1973.4,167,1971.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M208.7,2007.4
				c0,1.4-0.7,1.5-1.5,1.6c-1.4,0.1-1.6-0.8-1.6-1.8c0-0.8,0.1-1.7,1.1-1.6C208,2005.6,208.5,2006.6,208.7,2007.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M212.8,2008.3
				c-0.3,1-0.9,1.5-1.9,1.4c-0.9-0.1-1.3-0.7-1.1-1.6c0.2-0.9,0.6-1.7,1.7-1.4C212.2,2006.9,212.8,2007.3,212.8,2008.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M142.9,1996
				c-0.5,0.7-0.5,2-1.6,1.4c-0.3-0.2-0.8-0.8-0.7-1C141.1,1995.2,142.1,1996.3,142.9,1996z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M137.5,1995.6
				c0.2,1.2,0,2.2-1.5,2.1c-0.4,0-1-0.1-0.9-0.7C135.4,1995.6,136.5,1995.7,137.5,1995.6L137.5,1995.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M128.4,2016.8
				c-0.2,0.9-0.9,1.1-1.7,1c-0.8-0.2-1-0.7-0.7-1.4C126.9,2016.1,127.7,2016.3,128.4,2016.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M190.2,2015
				c-0.5-0.2-0.8-0.6-0.8-1.1c1.8-0.8,1.5,0.4,1.4,1.4l0,0C190.6,2015.2,190.4,2015.1,190.2,2015z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M178.4,1982.6
				c0.7-0.3,1.2-0.1,1.4,0.6C179.1,1983.7,178.5,1983.6,178.4,1982.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133.5,1997.2
				c0.2,0.1,0.3,0.1,0.5,0.2c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0-0.3-0.1-0.5-0.2C133.3,1997.6,133.4,1997.4,133.5,1997.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271,1857.2
				c-0.1,0.7-0.5,1.6-0.2,2.2c1.6,3.1-0.2,3.5-2.7,3.5c-2.2,0-4.3,0.5-6.3,1.6c-0.6,0.4-1.4,0.6-2.2,0.6c-7.6,0.1-15.1,0.2-22.7,0.4
				c-1.7,0-3.3-0.1-4.5-1.4c-0.1-2.1-0.2-4.1-0.3-6.2c-0.2-2.7,0.5-5.4-0.6-8c4-0.4,8-0.6,12-0.3c0.7,0.1,1.3,0.1,2,0.1
				c4.7-0.5,4.6-0.5,4.3,4.4c-0.2,3.4,0,6.8,0,10.3c1.9-0.3,1.5-1.3,1.5-1.9c0-3.3,0-6.7,0-10c0-1.2-0.1-2.3,1.6-2.2
				c6.1,0.3,12.2-0.7,18.2,0.6c0.2,1.6,0.5,3.3,0.7,4.9l0-0.1C270.7,1855.8,271.1,1856.6,271,1857.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M227.6,1862.8
				c-6.7,0.3-6.7,0.3-6.7-6.2c0-2-0.1-3.9-0.1-5.9c0-1.2,0.7-2.2,1.8-1.6c1.9,1.1,4,0.5,5.9,0.8c0,1.8,0.1,3.7-0.1,5.5
				C228.3,1858,229.3,1860.6,227.6,1862.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M227.6,1862.8
				c1.7-2.2,0.7-4.8,0.9-7.3c0.1-1.8,0-3.7,0.1-5.5c1,0,2-0.1,3-0.1c1.1,2.6,0.4,5.3,0.6,8c0.2,2.1,0.2,4.1,0.3,6.2
				C230.8,1864,229.2,1863.4,227.6,1862.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M207.3,1854.7
				c-0.1,1.1-0.3,3-0.5,5c-0.1,0.7-0.2,1.3-1.1,1.2c-0.7-0.1-0.7-0.7-0.7-1.3c0.1-2.3,0.2-4.5,0.2-6.8c0-1.2,0.3-2.8,1.4-2.8
				c1.7,0,0.4,1.7,0.7,2.6C207.4,1852.9,207.3,1853.4,207.3,1854.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271.7,1855.7
				c-0.2-1.6-0.5-3.3-0.7-4.9c1.7,0,3.3,0,5.6,0C274.7,1852.8,274,1854.9,271.7,1855.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M198.6,1882.5
				c-1.7-0.2-3.8,0.7-4.5-1.8C196.7,1879,197.8,1879.4,198.6,1882.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M282.2,1847.1
				c1.6,0.3,3.5-0.9,4.6,1.3c-2,0.3-4,1-5.9-0.5C281,1847.1,281.8,1847.3,282.2,1847.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M289.6,1854.3
				c-1.8,1.7-3.3,2.4-5.2,2.4C285.5,1855.1,287.2,1854.7,289.6,1854.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M138.2,1857.6
				c1.6,0.3,2.2,1.5,2.9,2.7C139.1,1860.6,138.4,1859.3,138.2,1857.6L138.2,1857.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M138.3,1857.5
				c-1.3,0-2.1-0.8-2.6-2.3C137.3,1855.4,137.9,1856.3,138.3,1857.5C138.2,1857.6,138.3,1857.5,138.3,1857.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M130.3,1850.4
				c1,0.1,1.6,0.6,1.9,1.8C130.8,1852.1,130.4,1851.4,130.3,1850.4L130.3,1850.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M276.3,1847.7
				c-0.1-0.6,0.2-1.1,0.8-1.1c0.7-0.1,1.2,0.2,1.1,1.1C277.6,1848.1,277,1848.1,276.3,1847.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133.6,1853.6
				c0.5,0.1,1,0.2,1,0.9c0,0.1-0.2,0.2-0.2,0.2C133.7,1854.7,133.6,1854.2,133.6,1853.6L133.6,1853.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M276.3,1861.1
				c0,0-0.4,0.1-0.7,0.3c0-0.2-0.1-0.4-0.1-0.5c0.3-0.2,0.7-0.4,1.1-0.5C276.8,1860.3,276.8,1860.6,276.3,1861.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M271,1857.2
				c0-0.6-0.3-1.4,0.7-1.6C272.3,1856.5,271.3,1856.7,271,1857.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M280.8,1858.6
				c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.2-0.1,0.2-0.1C280.7,1858.4,280.7,1858.5,280.8,1858.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M281.9,1858.1
				c-0.1-0.1-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.2C282,1858,282,1858,281.9,1858.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M283.4,1857.3
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.2C283.3,1857.1,283.4,1857.2,283.4,1857.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133.6,1853.6
				c-0.1-0.1-0.2-0.2-0.4-0.3c0.1,0,0.2,0,0.2,0C133.6,1853.5,133.6,1853.6,133.6,1853.6L133.6,1853.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M278.1,1859.9
				c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.2c0.1,0,0.1-0.1,0.2-0.1C278,1859.7,278.1,1859.8,278.1,1859.9z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				279.2,1859.3 279.1,1859.1 279.4,1859.2 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M536.5,1364.9
				c2.4-1.6,5.3-1.8,7.8-3c1.3-0.6,2.7-0.8,3.2-2.4c0.2-0.6,0.6-1.3,1.4-1.2c1,0.2,1.5,1.1,1.4,1.9c-0.2,2.9,1.1,5.5,1.5,8.3
				C545.7,1371.6,541.2,1367.7,536.5,1364.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M529.9,1366.3
				c0.2-0.9,0.3-1.7,0.5-2.7c1.2,0.6,0.8,1.5,1,2.2C531,1366.4,530.5,1366.6,529.9,1366.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M527.3,1367
				c0.3-0.7,0.7-1.3,1.5-0.5C528.4,1367,527.9,1367.2,527.3,1367z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M530.1,1427.5
				C530.1,1427.5,530.1,1427.5,530.1,1427.5C530.1,1427.5,530.1,1427.5,530.1,1427.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M136.9,1677.6
				c0.9,0,1.2,0.5,1.3,1.3l0,0C137.3,1678.9,136.9,1678.4,136.9,1677.6L136.9,1677.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M118.9,1657.4
				c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2-0.2-0.3-0.5-0.5-0.7c0.2-0.1,0.4-0.2,0.5-0.2C118.7,1657,118.8,1657.2,118.9,1657.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M143.5,1684.8
				c0.3,0.3,0.6,0.6,0.8,0.8c-0.1,0.1-0.3,0.1-0.4,0.2C143.8,1685.5,143.7,1685.1,143.5,1684.8L143.5,1684.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M140.2,1680.9
				c0.2,0.2,0.3,0.4,0.5,0.6c-0.1,0.1-0.2,0.1-0.4,0.2C140.3,1681.4,140.3,1681.1,140.2,1680.9L140.2,1680.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M119.4,1658.9
				c0.1-0.1,0.2-0.3,0.2-0.2c0.2,0.1,0.3,0.2,0.5,0.3c-0.1,0.1-0.2,0.2-0.2,0.2C119.8,1659.1,119.6,1659,119.4,1658.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133.6,1673.7
				c0.2,0.2,0.4,0.5,0.5,0.7C133.3,1674.6,133.8,1673.9,133.6,1673.7L133.6,1673.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M130.4,1669.7
				c-0.4,0-0.6-0.2-0.7-0.7l0,0C129.9,1669.3,130.1,1669.5,130.4,1669.7L130.4,1669.7z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				132.6,1671.9 132.7,1672.1 132.5,1672.1 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M133.7,1673.6
				c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0.1C133.6,1673.5,133.6,1673.6,133.7,1673.6L133.7,1673.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M136.3,1676.9
				c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0.1C136.2,1676.8,136.2,1676.8,136.3,1676.9L136.3,1676.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M136.9,1677.6
				c-0.2-0.2-0.4-0.4-0.7-0.7l0,0C136.5,1677.2,136.7,1677.4,136.9,1677.6L136.9,1677.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M138.2,1678.9
				c0.2,0.2,0.4,0.4,0.7,0.7l0,0C138.7,1679.3,138.5,1679.1,138.2,1678.9L138.2,1678.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M138.9,1679.5
				c0.2,0.2,0.4,0.4,0.7,0.7l0,0C139.3,1680,139.1,1679.7,138.9,1679.5L138.9,1679.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M139.5,1680.2
				c0.2,0.2,0.4,0.4,0.7,0.7l0,0C140,1680.6,139.8,1680.4,139.5,1680.2L139.5,1680.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M142.8,1684.1
				c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0.1C142.8,1684,142.8,1684,142.8,1684.1L142.8,1684.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M142.8,1684.1
				c0.2,0.2,0.4,0.4,0.7,0.7l0,0C143.3,1684.5,143.1,1684.3,142.8,1684.1L142.8,1684.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M130.3,1669.7
				c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1,0,0.2C130.5,1669.9,130.4,1669.8,130.3,1669.7L130.3,1669.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M167.2,1719.4
				c-0.1,0-0.2,0-0.3-0.1c0,0,0-0.1-0.1-0.2C167,1719.3,167.1,1719.4,167.2,1719.4L167.2,1719.4z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				121.6,1660.9 121.4,1661 121.4,1660.8 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M125.8,1665.1
				c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.1,0,0.2,0C125.7,1665,125.7,1665.1,125.8,1665.1L125.8,1665.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M126.4,1665.8
				c-0.2-0.2-0.4-0.4-0.7-0.7l0,0C125.9,1665.4,126.2,1665.6,126.4,1665.8L126.4,1665.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M126.4,1665.8
				c0.2,0.2,0.4,0.4,0.7,0.7l0,0C126.9,1666.2,126.6,1666,126.4,1665.8L126.4,1665.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M127.7,1667.1
				c-0.2-0.2-0.4-0.4-0.7-0.7l0,0C127.3,1666.7,127.5,1666.9,127.7,1667.1L127.7,1667.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M127.7,1667.1
				c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1,0,0.2C127.9,1667.3,127.8,1667.2,127.7,1667.1L127.7,1667.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M129.7,1669
				c-0.1-0.1-0.3-0.2-0.4-0.2c0.1,0,0.2,0,0.2,0C129.6,1668.9,129.6,1669,129.7,1669L129.7,1669z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M914.9,1872.4
				c0.1,5.5,0.3,10.9,1.2,16.3c0.2,1-0.1,1.1-0.8,1.2c-0.9,0.1-2-0.1-2-1.2c0.2-3.3-1.7-6.5,0-10.1c1.1-2.3,0.3-5.6-0.6-8.4
				c-0.2-0.8-1-1.6-0.3-2.4c0.3-0.3,1-0.4,1.5-0.3c1.1,0.4,0.7,1.4,0.8,2.2C914.8,1870.6,914.8,1871.5,914.9,1872.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M906.1,1872
				c-0.1,0.5-0.2,0.9-0.3,1.4c-1.2,0.2-1.9-0.6-2.3-1.4c-1.4-3-2.6-1.5-3.8,0.1c-0.6-0.7-0.7-2-2.2-1.5l0,0c0.4-0.7,0.7-1.4,1.1-2
				c1.5,0.2,2.4-0.4,3.1-1.6c1.2-1.9,2.1-4,3.8-5.5c0.2,0.5,0.4,1,0.6,1.5C903.9,1865.9,902.4,1868.9,906.1,1872z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M878.8,1746.4
				c-1-2.2-0.9-4.2,0.7-6c0.9,0.8,2,1.5,1.6,2.9c0.1,0.7,0.2,1.3,0.2,2C880.5,1745.7,879.7,1746,878.8,1746.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.5,1889.6
				c0-0.3-0.1-0.6-0.1-1c0-1.7-0.1-4.2,1.8-4.2c1.6,0,0.4,2.5,0.7,3.9c0.1,0.4,0.1,0.9,0.1,1.3
				C907.2,1889.6,906.4,1889.6,905.5,1889.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M894.3,1728.7
				c0.5-3.1,2.3-5.4,4.6-7.3C898.4,1724.5,897.1,1727,894.3,1728.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.2,1748.5
				c-2.1,0-2.1-1.3-1.7-2.8c0.3-1.1,1.2-1,2-1.1c0,1.1-0.3,2.2,0.4,3.3C884.7,1748,884.4,1748.3,884.2,1748.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.5,1744.5
				c-0.8,0.1-1.8,0-2,1.1c-0.4-0.1-0.7-0.2-1.1-0.4c-0.1-0.7-0.2-1.3-0.2-2c1.8,0.9,2.9-1,4.4-1.2c0.1,0.1,0.1,0.2,0.2,0.3
				C885.3,1743.1,884.9,1743.8,884.5,1744.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M893.9,1750.3
				c-0.3-0.7-0.5-1.3-0.8-2c0.7-0.3,1.4-0.6,2.2-1c0.2,0.2,0.4,0.4,0.6,0.6C895.4,1748.9,894.8,1749.7,893.9,1750.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M895.3,1747.3
				c0.4-1,1.1-1.7,2-2.4c0.8,1.9,0.2,2.7-1.5,3C895.7,1747.7,895.5,1747.5,895.3,1747.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M887.1,1750.3
				c-0.5,0.6-0.3,1.2-0.2,1.9c-1-0.4-1.4-1.3-1.5-2.3c0.2-0.2,0.4-0.4,0.6-0.5C886.4,1749.7,886.7,1750,887.1,1750.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M885.4,1749.9
				c-0.7-0.2-1.1-0.7-1.3-1.4c0.2-0.2,0.5-0.5,0.7-0.7c0.4,0.5,0.8,1,1.1,1.5C885.8,1749.5,885.6,1749.7,885.4,1749.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.3,1730.1
				c-0.2-0.1-0.5-0.2-0.7-0.3c0.6-0.5,1.3-0.9,2.2-0.4C906.6,1730.2,905.9,1730.1,905.3,1730.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M909.7,1772.2
				c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0,0.2,0,0.3,0C909.6,1772,909.7,1772.1,909.7,1772.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M907.5,1728.6
				c-0.3-0.7,0.1-0.7,0.6-0.6C908.4,1728.7,908,1728.7,907.5,1728.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M906.8,1729.2
				c-0.3-0.7,0-0.8,0.6-0.6C907.6,1729.2,907.3,1729.3,906.8,1729.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M881.6,1749.1
				c0,0.1,0,0.2,0,0.2c0,0.1-0.2,0.1-0.2,0.1c-0.3-0.1-0.3-0.3,0-0.4C881.4,1749,881.5,1749.1,881.6,1749.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M873.8,1749.2
				c0,0-0.1,0.1-0.1,0.2c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.2C873.7,1749,873.7,1749.1,873.8,1749.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M201.2,1411.3
				c1.4-1.3,3-2.2,5.6-2.3C205,1411,203.2,1411.4,201.2,1411.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M434.3,1872.1
				c1.2-1.7,3.1-1.9,4.5-3c0.9-0.8,1.4,0.1,1.5,0.9c0.2,1,0.2,2.1,0.2,3.6C439.1,1869.1,436.3,1873.6,434.3,1872.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M433.3,1857.6
				c2.6-0.4,4.3,1,6.1,2.1c0.4,0.3,0.8,0.9,0.5,1.4c-0.3,0.5-0.9,0.4-1.4,0C436.8,1860,435.2,1858.9,433.3,1857.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M434.7,1863
				c1.2-1,2.1-0.7,3.4-0.4C436.9,1863.6,436,1863.5,434.7,1863z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M303.9,1845.2
				c0.9-0.1,0.7-0.8,0.7-1.4c0.5-0.1,0.9-0.1,1.4-0.2c0.4,1.3,0.5,2.4-1.4,2.1C304.2,1845.8,304,1845.6,303.9,1845.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M326.9,1862.8
				c0.3,0.1,0.6,0.1,0.9,0.2C327.4,1863.2,327.2,1863.1,326.9,1862.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M634.1,1391.8
				c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2,0-0.3-0.1c0-0.1,0.1-0.2,0.1-0.2C633.9,1391.7,634,1391.8,634.1,1391.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M616.2,1968.3
				c-1.9,5.6-3.6,11.3-6.7,16.3c-2.1-2.1-1-4.8-1-7.1c-0.2-12.6-0.1-25.1,0.4-37.7c2.5,8.5,1.4,17.2,2.4,25.9c1.4-0.2,0.9-1.5,1-2.3
				c0.2-1.3,1-1.9,2.3-1.7c1.3,0.2,1.7,1,1.7,2.3C616.3,1965.4,614.9,1966.8,616.2,1968.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M614.8,1934.7
				c-0.9-0.4-2.9,0.9-3-1.1c-0.1-1.3,1.6-2,2.7-1.7c1.4,0.4,4-0.7,4.1,1.4C618.7,1935.3,616.2,1934.4,614.8,1934.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M606.9,1900
				c0.9-1.8,1.8-1.7,2.8-0.1C608.8,1900,607.8,1900,606.9,1900z"/>
			<path clip-path="url(#SVGID_2_)" fill="#333333" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M590,1905.4
				c0.8,0,1.7-0.1,2.5-0.1c0.3,0.7,0.1,1.2-0.7,1.3C590.9,1906.8,590.4,1906.2,590,1905.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M948.9,1619.3
				c0-1.8,1.1-2.2,2.6-1.9C950.9,1618.4,950.2,1619.3,948.9,1619.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M956.8,1612.8
				c-0.2-1.5,0.8-1.8,1.9-1.9C958.9,1612.3,958,1612.7,956.8,1612.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964.7,1606.2
				c-0.1-1.4,0.7-1.9,1.9-1.9C966.8,1605.7,965.9,1606.1,964.7,1606.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.1,1610.1
				c-0.2-1.1,0.3-1.4,1.3-1.3C961.6,1610,961,1610.2,960.1,1610.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M961.4,1608.8
				c0.3-1,0.7-1.8,1.9-1.3C963,1608.5,962.5,1609.2,961.4,1608.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M970.5,1635.7
				c0.3,0.1,0.6,0.2,0.9,0.2C970.9,1636.5,970.8,1635.9,970.5,1635.7L970.5,1635.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M958.7,1610.8
				c0.2-0.7,0.6-1,1.3-0.6C959.9,1611,959.5,1611.2,958.7,1610.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M966.6,1604.3
				c0.2-0.7,0.6-1,1.3-0.6C967.8,1604.4,967.4,1604.7,966.6,1604.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M954.8,1614.1
				c0.2-0.7,0.5-1.1,1.3-0.6C955.9,1614.1,955.6,1614.6,954.8,1614.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M963.3,1607.5
				c-0.2-0.7,0.1-0.8,0.6-0.6C964,1607.4,963.8,1607.5,963.3,1607.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M951.5,1617.4
				c-0.1-0.6,0.1-0.7,0.6-0.6C952.2,1617.2,951.9,1617.3,951.5,1617.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M964,1606.9
				c-0.2-0.6,0.1-0.8,0.6-0.6C964.7,1606.7,964.5,1606.9,964,1606.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M952.2,1616.7
				c-0.1-0.6,0.1-0.7,0.6-0.6C952.9,1616.6,952.6,1616.7,952.2,1616.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M952.8,1616
				c-0.1-0.5,0.1-0.7,0.6-0.6C953.6,1615.9,953.3,1616.1,952.8,1616z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M953.5,1615.4
				c-0.1-0.5,0.1-0.7,0.6-0.6C954.3,1615.3,954,1615.5,953.5,1615.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M954.1,1614.7
				c-0.1-0.5,0.1-0.7,0.6-0.6C955,1614.8,954.7,1614.9,954.1,1614.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M956.1,1613.4
				c-0.3-0.7,0-0.8,0.6-0.6C956.8,1613.3,956.6,1613.4,956.1,1613.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M512.7,1749.4
				c-1.2-8.9-0.8-18-1.1-27c-0.1-2.1,0.3-3.4,2.9-2.5c1.3,0.4,2.8,0.3,4.2,0.3c2.8,0,3.9,0.9,3.2,4c-1.1,4.6-3.4,8.6-5.2,12.9
				C515,1741.1,512.8,1744.9,512.7,1749.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M517.4,1764.4
				c-1.8,3.9-1.9,8.6-5,12c-0.6,0.6-1,2.1-2.2,1.3c-0.8-0.5-0.2-1.6,0.1-2.4c0.9-2.3,1.7-4.7,2.9-6.9c0.8-1.4,1-2.8,1.5-4.2
				c0.3-0.8,0.9-1.5,2-1.3C517.5,1763.1,517.4,1763.7,517.4,1764.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M895.4,1790.1
				c-1.1-0.3-1.2-1.3-1.1-2.1c0.9-4.4-0.5-8.7-0.6-13.1c0-1,0-1.9,0.7-2.8c2.3-3.2,5.1-6,6.9-9.6c0.3-0.6,0.7-1.2,1.5-1
				c0.8,0.2,0.8,1.1,0.7,1.6c-0.5,2.3,1.5,4.9-1.1,6.9c-0.3,0.3,0,0.7,0.4,1c2.2,1.9,1.1,4.5,1,6.8c-0.1,1.4-0.2,2.3,0.3,3.9
				c0.9,3.1,1.1,6.6,0.4,9.9c-0.1,0.4-0.9,0.7-0.5,1.3c2.7,3.9,1,6.9-1.8,9.7c-0.4,0.4-1.3,1-0.5,1.8l0.1-0.1
				c-2.3-0.4-2.2,1.6-3,2.8c-0.6,0.9-1,2.5-2.5,2.1c-1.4-0.3-0.9-1.8-1-2.8c-0.2-3.5,0.3-7-0.6-10.4c-0.5-2,0.1-3.8,1.4-5.4
				c1.2-1.7,4.7-1.9,3.7-5.2c0.3-0.4,0.6-0.7,0.8-1.1c0.2-0.2,0.4-0.5,0.6-0.7c0.5-0.5,1.3-1.4,0.7-1.7c-1-0.5-0.8,0.8-1.1,1.3
				c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.4-0.5,0.9-0.7,1.3C898,1786.9,896.7,1788.5,895.4,1790.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M901.5,1804.6
				c1.4-0.2,1.5-2.9,3.2-2.1c1.5,0.7,0.8,2.6,0.7,3.9c-0.2,1.5,1.7,3.8-1.3,4.6c-0.4,0.1,0.1,0.7,0.3,1c2.1,2.6,1.9,5.3,0.3,8.1
				c-0.4,0.6-1.2,1.6,0.5,1.7c1.1,0.1,1,0.9,1,1.7c0,2.3,0.1,4.6,0.1,6.8c0,0.9,0.4,2.1-1.1,2.3c-0.8,0.1-0.9,0.4-0.4,1
				c3.3,3.9,1.6,7.2-1.3,10.2c-1.4,1.4-2.8,2.8-3.6,4.7c-0.3,0.7-0.7,1.8-1.8,1.5c-1-0.3-0.8-1.3-0.7-2c0.3-2-1.5-4.2,0.5-6.2
				c0.1-0.1-0.2-0.8-0.3-1.1c-1.6-3.7-1.8-7.3,1.4-11.1c-2.1,0.3-3.6-0.7-2.7-2.2c2-3.2-0.3-6-0.7-8.8c-0.5-4.1,0.8-7.3,3.5-9.9
				C900.4,1807.5,900.9,1806,901.5,1804.6L901.5,1804.6z M903.2,1823c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.1,0.2
				c0.1-0.1,0.2-0.1,0.2-0.2C903.3,1823.1,903.2,1823,903.2,1823z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M886.3,1767.2
				c3.3,2.3,3.2,5.9,2.7,9.1c-0.7,4.2,0.5,8.1,0.8,12.1c0.2,3-0.9,2.7-2.8,1.6l0.1,0.1c-1.1-1.6-0.3-4.5-3.4-4.7l0.1,0.1
				c0.3-1.3-0.8-1.9-1.3-2.7l0.1,0.1c0-2.1-3.1-2.4-2.8-4.7c0.6-5-0.8-9.8-1.2-14.8c-0.2-2.3,0.9-2.7,2.7-2.7l-0.1-0.1
				C882.1,1763.5,883.9,1765.7,886.3,1767.2L886.3,1767.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M883.1,1801.9
				c0.7,2.8,2.3,5,4.8,6.5c1.5,0.9,2.9,2.2,2.8,3.7c-0.3,3.5,2,7.3-0.8,10.6c-0.5,0.6,0,1,0.4,1.6c1.5,2.1,0.3,4.7,1,7
				c0.2,0.6-1,0.6-1.6,0.2c-1.4-3.4-3.2-6.5-6.2-8.7c-2.1-2.7-2.4-5.5-2.1-9c0.2-3-0.4-6.3-0.8-9.5
				C880.3,1802,881,1801.3,883.1,1801.9L883.1,1801.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M885.8,1863.5
				c-2.1-2.9-3.5-5.6-2.4-9.8c0.5-1.9-0.3-5.4-1.3-8c-0.8-2.2-0.1-3.1,2.3-2.5l0-0.1c0,0.5,0.2,0.8,0.7,0.7l-0.1-0.1
				c0,0.5,0.1,0.8,0.7,0.7l-0.1,0c1.3,2,2.1,4.2,4.5,5.5c1.6,0.9,2.5,2.5,2.2,4.4c-0.5,2.9,1.7,6-1.1,8.7c-0.4,0.4,2.1,2.2,1.5,4.1
				c-0.3,1,0.8,2.7-0.8,3.1c-1.3,0.3-2-1.4-2.2-2.4C889.4,1865.2,886.8,1865,885.8,1863.5C885.7,1863.4,885.8,1863.5,885.8,1863.5z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M887,1790.1
				c3.2,3.7,4.3,7.8,2.7,12.5c-0.5,1.3,1.2,1.7,0.9,2.9c-0.2,0.8,0.3,1.9-0.5,2.4c-1,0.5-1.5-0.5-2.1-1.1c-1.5-1.8-2.9-3.7-5-4.9
				l0.1,0.1c-2.6-2.7-3.1-5.5-2.3-9.3c0.5-2.1-0.9-4.9-1-7.5c-0.1-2.3,0.5-3,2.7-2.2l-0.1-0.1c-0.3,1.3,0.3,2.1,1.3,2.7l-0.1-0.1
				C884.1,1787.6,885.2,1789.2,887,1790.1L887,1790.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.6,1861.4
				c-1.7,1.6-2.6,3.6-3.8,5.5c-0.8,1.3-1.7,1.9-3.1,1.6c0-0.1-0.1-0.2-0.1-0.3c1.9-3.3-0.3-6.4-0.9-9.4c-0.8-4.7,1.5-7.5,4.3-10.4
				c1.1-1.2,1.8-2.7,2.6-4.1c0.4-0.6,0.9-0.9,1.6-0.7c0.7,0.2,0.7,1,0.5,1.4c-1,2,2.2,5-1.5,6.3c0,0,0.1,0.6,0.3,0.9
				C908.4,1855.3,906.4,1858.4,905.6,1861.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.4,1843.2
				c-0.1-2.2-3.1-3.1-2.4-5.5c1.2-4.2-0.4-8.2-0.4-12.4c0-1-0.5-2.5,1.5-1.8c0.7,2.3,4.2,4.1,1.1,7c-0.1,0.2-0.1,0.5-0.2,0.7
				c0.2-0.1,0.4-0.2,0.7-0.2c2.7-2.6,3.3,1,4.9,1.7c1.9,1.8,2.4,3.9,2.2,6.5c-0.1,2.3-1.3,4.6,0.3,6.9c0.4,0.5,0.6,2.1-0.5,2.6
				c-1,0.5-1.8-0.5-2.3-1.3c-1-1.2-1.6-2.9-3.6-3l0.1,0c0-0.3,0-0.5,0-0.8c-0.3,0-0.5,0-0.8,0l0.1,0.1
				C885.6,1842.9,885.2,1842.8,884.4,1843.2L884.4,1843.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M893.3,1762.7
				c0.3-1.1,1.5-2.6,0.2-4.3c-0.1-0.1,0.3-0.7,0.5-0.7c2.7-0.3,5.4-0.7,8.1-0.6c1.4,0,1.2,1.3,0.4,2.2c-2.4,2.7-4.7,5.6-6.4,8.8
				c-0.3,0.6-0.9,1.3-1.8,1c-0.8-0.3-0.6-1-0.7-1.6C893.4,1766,893.4,1764.6,893.3,1762.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M881.2,1760.7
				c-0.3-0.9-1.7-1.5-1.1-2.5c0.6-1.2,1.9-0.5,3-0.4c0.9,0,1.7,0.3,2.6,0.2c2.7-0.3,3.4,1.1,2.6,3.3c-0.4,1.1-0.1,1.6,0.3,2.5
				c1,2.4,0.2,3.5-2.2,3.5l0.1,0.1C885.9,1764.1,883.2,1762.6,881.2,1760.7L881.2,1760.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M903.4,1879.2
				c1.1,0.4,3.2-0.9,3.2,1.6c-0.1,2.1-1.7,4.4-3.3,4.3c-1.4-0.1-3.4-2.6-3.6-4.2C899.4,1878.2,902,1879.7,903.4,1879.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M906.1,1872
				c-3.7-3-2.2-6.1,0-9.1c0.7,0.8,1.5,6.9,1.1,8.7C907.2,1872.3,906.7,1872.4,906.1,1872z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M886,1749.3
				c-0.4-0.5-0.8-1-1.1-1.5c-0.7-1-0.4-2.2-0.4-3.3c0.4-0.7,0.9-1.4,1.3-2.1c0.4,0,1-0.2,1.3-0.1c1,0.6,1,6.9,0.1,8
				C886.7,1750,886.4,1749.7,886,1749.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M889.6,1832.7
				c-1.6-0.7-2.2-4.3-4.9-1.7c-0.1-0.2-0.3-0.3-0.4-0.5c3.2-2.9-0.4-4.7-1.1-7c0.1-0.3,0.3-0.5,0.4-0.8c3,2.2,4.8,5.3,6.2,8.7
				C889.6,1831.9,889.6,1832.3,889.6,1832.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M899.6,1785.6
				c1.1,3.3-2.4,3.5-3.7,5.2c-0.2-0.3-0.4-0.5-0.6-0.8c1.3-1.6,2.6-3.2,3.9-4.7C899.4,1785.5,899.5,1785.5,899.6,1785.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M892.3,1709.2
				c0.2-0.2,0.4-0.4,0.6-0.6c0.1,0,0.4,0.2,0.4,0.3c0,0.5-0.3,0.7-0.8,0.8l-0.3-0.1L892.3,1709.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M897.6,1870.6
				c-0.1,0.1-0.1,0.2-0.2,0.4c0.1,0,0.1,0,0.2-0.1C897.6,1870.8,897.6,1870.7,897.6,1870.6C897.6,1870.6,897.6,1870.6,897.6,1870.6z
				"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M419.7,1916.6
				c-0.2-2.4,0.7-3.2,3.3-3.4c4.4-0.3,8.7,0.5,13,0.4c1.2,0,3.1-0.7,3.6,0.5c0.7,1.5-0.8,2.7-1.9,3.7c-3.6,0.8-7.1-1.1-10.6-0.2
				C424.5,1918.4,422.1,1918,419.7,1916.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M419.7,1916.6
				c2.3,1.4,4.8,1.8,7.5,1.1c3.5-0.9,7.1,0.9,10.6,0.2c0.2,1.8,0,3.3-2.3,3.3c-4.7-0.1-9.3-0.1-14-0.3c-1,0-2.5,0.2-2.9-0.9
				C418.1,1918.8,418.4,1917.4,419.7,1916.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M435.2,1924.3
				c-2.2,2.7-5.5,3.8-8.5,3.1c-3.1-0.7-6.2-0.5-9.2-1.3c-0.4-2,0.2-3.1,2.4-3.1c4.8,0.1,9.6,0,14.3,0.1
				C435,1923.1,436.6,1922.8,435.2,1924.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M420.2,1931.6
				c0,2.3-1.1,2.9-3.1,2.6c-1-0.2-1.8-0.9-1.4-1.8C416.8,1930.2,418.6,1931.9,420.2,1931.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M146.1,1974.7
				c-1-0.2-2.1-0.3-3-1.5C145.3,1971.8,145.9,1972.7,146.1,1974.7L146.1,1974.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M117.9,1837.9
				c-0.8,0-1.5-0.2-1.8-1.1c-0.1-0.4,0.1-0.7,0.5-0.6C117.5,1836.4,117.8,1837.1,117.9,1837.9L117.9,1837.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M117.8,1837.9
				c0.8,0,1.3,0.4,1.3,1.3l0,0C118.3,1839.2,117.8,1838.8,117.8,1837.9L117.8,1837.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M122.9,1842.6
				c-0.2,0-0.4,0.2-0.5,0.1c-0.1-0.1-0.2-0.3-0.3-0.5c0.2,0,0.4-0.2,0.5-0.1C122.8,1842.1,122.8,1842.4,122.9,1842.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M119.2,1839.2
				c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.2,0-0.2,0C119.2,1839.5,119.2,1839.3,119.2,1839.2L119.2,1839.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M968,1688
				c8.1-0.8,16.1-1.6,24.3-1.4c3.3,0.1,6.6,0.1,9.9,0.1c0.2,0.1,0.5,0.3,0.4,0.4c-1.7,5.6,2.5,7,6.3,8.7c0.6,0.3,1.2,0.6,1.2,1.3
				c-3.1,1.4-5,4.7-8.5,5.2c-1.6,0.3-3.8,0-4,1.9c-0.3,2.2,2.6,0.9,3.4,2.1c-1.6,1.5-3.3,1.8-5.3,0.5c-1.8-1.1-3.8-0.6-5.7-0.2
				c-2.6,0.5-5.2,0.2-7.8,0.3c-0.6,0-1.6,0.1-1.3-0.9c1-2.8-1-2.1-2.4-2.2c-3-0.2-5.5,1.4-7.9,2.7c-2.8,1.4-3.4,0.7-3.1-2
				c0-0.4,0-0.9-0.1-1.3c1.5-1.6,1.6-3.7,1.3-5.6c-0.2-1.5-2-0.5-3-0.8c0-1-0.1-2-0.1-3c2.9-1.4,3.2-2.2,1.6-3.9
				C967.4,1689.3,967.7,1688.6,968,1688z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1000.9,1706.3
				c-0.9-1.2-3.7,0.1-3.4-2.1c0.3-1.9,2.4-1.6,4-1.9c3.5-0.6,5.5-3.8,8.5-5.2c2,0.4,4,0.1,5.9-0.1c0,0.3,0,0.6,0,0.8
				c-2.7,0.7-5.7,0.8-7.9,2.3c-1.7,1.1-3.8,2.6-4.6,4.9c0.2,1.2,1,1.1,1.9,0.8l-0.1,0c-0.6,0.5-1.6,0-2.1,0.8
				C1002.4,1706.5,1001.7,1706.4,1000.9,1706.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1015.9,1697.8
				c0-0.3,0-0.6,0-0.8c6.5-1.9,13.1-3.8,19.6-5.6c-0.1,1.2-1.1,1.2-1.9,1.5C1028,1695,1022.1,1696.9,1015.9,1697.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M996.8,1678.9
				c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0,0.2C997.1,1679.1,997,1679,996.8,1678.9L996.8,1678.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M420.9,1733.9
				c0.4,1.5,0.7,2.9,1.1,4.3c0.2,0.8,0.4,1.6-0.5,2c-1.2,0.5-1-0.7-1.2-1.3c-0.5-1.5-1.2-1.5-2.3-0.5c-1.5,1.5-2.2,3.4-3.3,5.1
				c-0.8,1.1-1.5,2.2-3.3,2.5c0.6-1,1.1-1.7,1.5-2.5c0.4-0.8,1.7-1.6,0.6-2.5c-0.9-0.6-1.8,0.2-2.6,0.9c-2.5,2.1-5.1,4.2-7.6,6.2
				c-0.9,0.7-1.7,1.8-3.2,1.3c5-5,11.4-8.1,16.2-13.2c0.7-0.8,2.7,0.2,2.5-1.8C419.5,1734.3,420.2,1734.1,420.9,1733.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M427.5,1749.5
				c-0.8,0.2-2-0.4-2,1c0,1,0.9,1,1.7,1.1c0.6,0.1,1.8-0.4,1.4,1c-0.2,0.6-0.5,1.5-1.3,1.2c-4.4-1.3-8.5,2.1-12.9,1
				c-1-0.2-2.1,0.2-3-0.5c0.1-0.3,0.1-0.5,0.2-0.5c4.4-0.6,7.9-2.9,11.6-5C424.3,1748.2,426.7,1746.1,427.5,1749.5L427.5,1749.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M411.2,1748.9
				c-0.7,1.1-1.5,2.1-2.1,3.2c-0.3,0.5-0.6,1-1.2,0.9c-1.3-0.4,0.3-2.1-1.1-2.5c-0.1,0-0.1,0-0.2,0.1c1.2-0.5,2.4-1,3.6-1.4
				C410.5,1748.9,410.8,1749,411.2,1748.9L411.2,1748.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M379.6,1754
				c-1.6-0.6-3.1-1.3-4.7-1.9c0-0.4,0-0.8,0-1.2l0,0c3.1-0.1,6.2,0.4,9.2,1.3l0,0C382.6,1752.8,380.1,1750.7,379.6,1754z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M422.8,1742.2
				c-0.1,1.5,1.8,3.3,0.1,4.2c-1.7,0.9-1.3-1.5-1.9-2.3C419.9,1742.6,422.1,1742.9,422.8,1742.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M420.9,1733.9
				c-0.7,0.2-1.3,0.4-2,0.6c-0.5-0.3-0.9-0.6-1.4-0.9c-0.2-0.5-0.3-1-0.5-1.6c0.3-0.7,0.5-1.5,0.8-2.2c0.9,0.4,0.4,3.1,2.5,1.4
				C420.9,1732,421.9,1732.7,420.9,1733.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384.1,1752.2
				c2.2,0,4.3,0,6.5,0C388.4,1752.9,386.2,1753.3,384.1,1752.2L384.1,1752.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M374.9,1750.9
				c-0.1,0-0.2-0.1-0.3-0.1C374.7,1750.8,374.8,1750.8,374.9,1750.9L374.9,1750.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M427.5,1749.6
				c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0.1,0,0.2C427.7,1749.8,427.6,1749.7,427.5,1749.6L427.5,1749.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M411.1,1748.9
				l0-0.1C411.1,1748.8,411.2,1748.9,411.1,1748.9C411.2,1748.9,411.1,1748.9,411.1,1748.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M905.9,1970.2
				c6.5,0,8.4,2,6.6,8.3c-0.7,2.6-3,4.8-4.7,7.1c-0.5,0.7-1.1,0-1.6-0.5c-2.3-2.5-3.5-11.1-2-14.4
				C904.9,1970.6,905.4,1970.4,905.9,1970.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M904.6,1965.1
				c-0.8-1.2-0.6-2.5-0.3-3.8c0.1-0.5,0.6-1.2,0.5-1.5c-2-4.3,0.2-7.7,2.4-11.1c0.3-0.5,0.6-1.1,1.1-1c0.8,0.1,1,0.8,0.9,1.5
				c-0.7,4.8,0.7,9.7-0.8,14.4C907.2,1964.1,906.2,1965.4,904.6,1965.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M888.8,1942
				c3.1,0.1,5.8-1.2,5,3c-0.3,1.8,2.2,3,2.7,5.8C893,1948.3,891.7,1945.1,888.8,1942z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M904.6,1965.1
				c1.6,0.3,2.6-1,3.9-1.5c0.3,2.6-1.3,4.5-2.5,6.6c-0.5,0.2-1.1,0.4-1.6,0.6C904.1,1968.9,905.1,1967,904.6,1965.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M903.4,1943.5
				c-1.1-1.2-0.6-2.4,0.7-2.7c1.6-0.4,3.5-0.9,4.8,0.3c0.8,0.8-0.9,1.1-1.3,1.7C906,1941.5,904.7,1942.7,903.4,1943.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M903.4,1943.5
				c1.3-0.8,2.6-2,4.3-0.6c-0.2,1.4-0.7,3-2.2,3.1C904.4,1946,903.5,1944.8,903.4,1943.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M171.9,1798.1
				c-0.2-1.5,0-2.9,0.6-4.3c1.1-2.5,2.6-2.9,4.6-0.9C175.8,1795.1,175.5,1798.2,171.9,1798.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M401.3,1838.7
				c-1.5,0.4-2.5-1.5-4.1-0.8l0.1,0c-0.2-1.8-2-1.8-3.1-2.4c-3.2-1.8-3.3-1.7-0.6-3.8c0.2-0.2,0.5-0.4,0.8-0.5
				c1.7,2.4,4.5,3.2,6.9,4.7C402.6,1836.8,403.6,1837.7,401.3,1838.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M394.3,1831.2
				c-0.3,0.2-0.5,0.3-0.8,0.5c-2.6,2.1-2.6,2,0.6,3.8c1.1,0.6,2.9,0.6,3.1,2.4c-1.9-0.9-3.8-1.8-5.7-2.7c-0.9-0.5-1.8-1.3-2.9-0.5
				c-0.3,0.2-0.5,0.4-0.8,0.6c-0.4-0.6-2.2-1.1-0.8-1.7c2.2-1,3.8-2.8,6-3.6C394,1829.8,394.6,1830.1,394.3,1831.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M405.7,1823.5
				c0,0.5,0,0.9,0,1.4c-3.1,1.5-6.1,3-9.1,4.6c-0.2-0.3-0.3-0.6-0.5-1C399,1826.5,402.1,1824.6,405.7,1823.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M387.5,1827.5
				c-1.2-1.2-1.8-2.1-0.9-3.3c0.3-0.4,0.9-0.6,1.1-0.3C388.5,1825,388.2,1826.1,387.5,1827.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M383.4,1813.6
				c0-0.4,0-0.7,0-1.1c0.6,0,1.2,0.3,1.1,0.9C384.4,1813.8,383.8,1813.7,383.4,1813.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M387.9,1835.3
				c0.3-0.2,0.5-0.4,0.8-0.6c-0.1,0.4,0,0.9-0.3,1.1C388.1,1836,387.9,1835.7,387.9,1835.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M841.9,1787.1
				l0,0.2c-8.4,1-16.7-0.9-25.1-1.2C825.1,1786.4,833.5,1786.8,841.9,1787.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M331.3,1919.3
				c-1,0.6-1.9,0.7-3,0C329.4,1918.2,330.3,1918.1,331.3,1919.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M210,1667.1
				c1,0.7,2.1,1.4,3.1,2.1c-10,7-20.4,13.4-30.8,19.8c-0.3,0.2-0.8,0.2-1.2,0.3c0.1-1.8,0.1-3.5,0.2-5.3c1.1,0,1.6-0.8,2.4-1.3
				C192.2,1677.1,200.5,1671.1,210,1667.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M179.3,1716.8
				c-0.3-0.7-0.3-1.3,0.1-1.9C179.8,1715.5,179.8,1716.2,179.3,1716.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M181.6,1971.1
				c0.6,0.5,1.9,1.1,1.1,1.9c-1.1,1.1-2.7,1.1-3.9-0.3C179.5,1971.7,180.5,1971.3,181.6,1971.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M810.2,1856.2
				c-1.8-1-3.7-1.3-5.7-1.3c-11.5-0.3-22.9,0.7-34.4,0.8c-5.7,0.1-11.4,0.4-17.1,1.3c-1.1-1.4-2.7-0.2-4.1-0.9
				c0.2-0.4,0.5-0.8,0.7-1.3c2-3.9,2-3.9-2.2-4.8c-0.1-0.7,0.5-0.5,0.8-0.8c1.5-1.2,3.5-2.7,3.1-4.5c-0.4-1.7-2.8-0.1-4.1-1.1
				c2.3-0.5,3.8-2.4,3.6-4.6c-0.2-2.2-3-0.1-4.2-1.7c2.1-0.8,4.1-2.3,4-4.5c-0.1-2.3-2.9-0.5-4.3-1.8c1.5-1.1,3-2.2,4.4-3.3
				c15.8-0.6,31.6-2,47.5-1.6c3.6,0.1,7.2,0.1,10.8,0.2c0.2,0,0.5-0.1,0.6,0c1.3,0.6,3.4,0.9,3.4,2.5c0,1.3-2.2,0.6-3.2,1.5
				c-0.3,0.3-0.8,0.4-1.2,0.5c-4.5-0.3-8.9-0.4-13.4-0.7c-11.2-0.7-22.5,1.9-33.7-0.5c-0.4-0.1-0.9,0-1.2,0.3
				c-1.6,1.2-3.6,1.5-5.5,1.7c-1,0.1-1.9,0.5-2.3,1.8c5.4,0.3,10.7-0.1,16-0.3c13.5-0.5,27-1.8,40.5-0.3c0.6,0.1,1.3,0.1,1.9,0.3
				c0.9,0.3,1.9,0.9,1.9,2c0,1.1-1.1,1.1-2,1.1c-1,0-1.6,0.6-1.9,1.4c-1.8-0.7-3.6-0.9-5.4-0.9c-4.9,0-9.8-0.1-14.7-0.3
				c-8.2-0.3-16.4,1.3-24.5-0.8c-4.2-1.1-7.5,1.3-10.9,3.5c2.5,1.2,5.1,1,7.7,0.8c11.6-0.8,23.1-1.4,34.7-1.3
				c4.6,0.1,9.1,0.1,13.7,0.1c0.7,0.2,1.5,0.3,2.1,0.7c0.9,0.4,2.1,0.9,2.1,1.9c-0.1,1.5-1.6,0.6-2.3,0.9c-0.8,0.3-1.5,0.8-2.2,1.3
				c-18.1-1.3-36.1-0.9-54.3,1.2c1.2,1.7,2.6,1.5,3.9,1.5c7.5-0.3,15.1-0.8,22.6-1c9.4-0.3,18.8-0.8,28.2,0.3c0.3,0,0.6-0.2,0.9-0.1
				c1.4,0.4,3.6,0.6,3.4,2.2c-0.1,2-2.3,1.1-3.6,1.2c-1.1-0.9-0.7,0.3-0.9,0.7l0.1,0c-18.4-0.6-36.7-0.3-54.9,1.8
				c2.6,1.7,5.3,2.4,8.2,1.9c5-0.8,10.2-1.1,15.2-1.4c10.6-0.6,21.3-0.3,31.9-0.3c0.7-0.5,1.3-0.1,1.8,0.2c1.1,0.7,3.4,0.7,3.3,2.2
				c-0.1,1.4-2.1,1.4-3.4,1.5C810.7,1855,810.4,1855.6,810.2,1856.2z M752.9,1837.3c-0.2-0.2-0.4-0.5-0.4-0.5
				c-0.2,0.1-0.4,0.3-0.6,0.5c0.1,0.1,0.3,0.4,0.3,0.4C752.4,1837.6,752.6,1837.5,752.9,1837.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M747.2,1818.7
				c-0.5,0.2-1.2,0.2-1.2-0.5c0-0.7,0.6-0.7,1-0.2C747,1818.3,747.1,1818.5,747.2,1818.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M747.2,1818.7
				c-0.1-0.2-0.1-0.4-0.2-0.6C747.1,1818.3,748.1,1818.3,747.2,1818.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M694.8,1895.3
				c-0.2-1-1.3-2-0.5-3.9C695.3,1892.9,694.8,1894.1,694.8,1895.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M691.7,1968.1
				c0.2-0.6,0.5-0.8,1-0.2C692.4,1968,692,1968,691.7,1968.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M143.5,1778.3
				c-0.8,0.8-1.6,0.8-2.9,0.2C141.8,1778.5,142.6,1778.4,143.5,1778.3L143.5,1778.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M147.5,1777.1
				c0.3-0.5,0.7-0.7,1.3-0.5c0.1,0,0.1,0.2,0.2,0.2C148.4,1776.9,147.9,1777,147.5,1777.1L147.5,1777.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M143.5,1778.4
				c0.4-0.6,0.9-0.8,1.6-0.2C144.5,1778.3,144,1778.3,143.5,1778.4L143.5,1778.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M147.4,1777
				c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1-0.1-0.2-0.2-0.2-0.3C146.9,1777.2,147.2,1777.1,147.4,1777L147.4,1777z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M816.7,1878.1
				c-0.1,0.1-0.3,0.7-0.5,0.7c-0.7,0.1-0.8-0.5-0.7-1.1c0-0.4,0.1-0.9,0.7-0.8C816.6,1876.9,816.7,1877.4,816.7,1878.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M484.5,1924.3
				c-2.8,0.2-5.7,0.2-8.5,0.5c-0.9,0.1-1.5,0.1-1.8-0.7c-0.5-1.2,0.4-1.5,1.3-1.8c1.7-0.5,3.2-0.9,5.1,0.3c2.3,1.4,5.2,0.5,7.9-0.2
				c0.9-0.2,1.9-0.6,2.8-0.6c1.1,0,2.5,0.5,2.5,1.5c0,1.4-1.5,1.2-2.5,1.2c-2.3,0-4.6,0-6.9,0
				C484.5,1924.4,484.5,1924.3,484.5,1924.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M470.8,1897.9
				c1.4-1.9,3.3-0.9,5-0.7C474.4,1899,472.6,1898.2,470.8,1897.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M223.7,1961
				c0.7,4.8-1.3,9.5-0.1,14.3c0.7,2.9-0.6,3.5-3.3,2.4c-6.7-2.8-12.2-7.4-18.7-10.7c-1.5-0.7-1.3-1.6-0.1-2.4c6.3-4,12.5-8,18.8-12
				c1.9-1.2,3.3-0.2,3.3,2.1C223.8,1956.9,223.7,1959,223.7,1961z M219.9,1959.3c-1.6,3.2-0.7,6.5-0.7,9.8
				C221.6,1966.1,220.4,1962.7,219.9,1959.3z M217.1,1962.8c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8,1.3-1.5,2.6-2.3,3.8
				c-0.4,0.7-0.2,1.3,0.3,1.8c0.5,0.5,1.1,0.7,1.8,0.6c0.8-0.2,0.8-0.9,0.8-1.6C217.1,1965.7,217.1,1964.2,217.1,1962.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M228.4,1954.8
				c1.1-2.1-0.3-4.3,1.2-6.4c1.3,1.1,1.2,2.4,1.3,3.6c0.3,7.5,0.5,15,0.7,22.5c0,0.6,0.3,1.6-0.1,1.9c-1.1,0.9-0.7,3.5-2.3,3.2
				c-2-0.4-4.1-1.6-4.1-4.3c-0.1-7.5-0.1-15-0.1-22.5c0-1.2,0.3-2.4,1.7-2.3c1.2,0,0.9,1.2,1,2.1
				C227.7,1953.2,227.5,1954.1,228.4,1954.8z M228.2,1957c-0.4,1.6-1,3.2,0.4,5.4C228.8,1960.1,229.1,1958.5,228.2,1957z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M222,1950.3
				c2-2.7,4.8-4.2,7.5-6c0.5-0.3,1.1-0.5,1.4,0.2c0.3,0.7-0.1,1.3-0.6,1.7C227.7,1948,225.1,1949.4,222,1950.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M198,1975.9
				c0.9-2-1.4-3.3-1.3-5.1c2.8,0.3,4.9,1.2,5,4.5c-1.2,0.3-2.4,0.5-3.6,0.8L198,1975.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M198.1,1976.1
				c1.2-0.3,2.4-0.5,3.6-0.8c0.2,1.2,1.1,2.9-0.6,3.2C200.2,1978.6,197.9,1978.4,198.1,1976.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M194.8,1969.6
				c0.1,3.1-1.5,2.9-3.6,1.8C191.9,1969.8,193.1,1969.2,194.8,1969.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M216.6,1987.8
				c0.1-1.3,0.4-2.1,1.9-1C218,1987.4,217.5,1988,216.6,1987.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M224.9,1983.6
				c-0.2-0.6-0.3-1.2-0.5-1.7c0.7,0.1,1.9-0.4,2.1,0.7C226.6,1983.4,225.6,1983.6,224.9,1983.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M221.8,1985.2
				c0.2-1.1,0.7-1.4,1.6-0.7C223.1,1985.2,222.5,1985.4,221.8,1985.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M222.6,1980.8
				c-0.6,0.4-1.1,0.4-1.4-0.4C221.8,1980.1,222.3,1980.3,222.6,1980.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M965.6,1696.7
				c1.1,0.3,2.8-0.7,3,0.8c0.3,1.9,0.1,4-1.3,5.6C964.4,1701.7,965.9,1699,965.6,1696.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M967,1689.9
				c1.6,1.7,1.3,2.5-1.6,3.9C964.9,1692,963.4,1689.9,967,1689.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1003.2,1706.6
				c0.5-0.8,1.5-0.3,2.1-0.8c1.1-0.3,1.8,0.3,2.7,1.2C1006.1,1708.2,1004.7,1707.3,1003.2,1706.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1005.3,1705.8
				c-0.9,0.3-1.7,0.3-1.9-0.8C1004.2,1705.1,1004.9,1705.1,1005.3,1705.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1019.1,1843.8
				c-11.1-0.4-22.3-0.4-33.4,0.6c-4,0.3-8,0.6-12,0.6c-1.1,0-2.9,0.7-2.9-1c0-1.4,1.7-0.8,2.7-0.9c15-1.9,30-1.2,45-1.2
				C1018.7,1842.5,1018.9,1843.2,1019.1,1843.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1018.7,1837.9
				c-14.5-0.5-29.1,0.1-43.6,1.1c-0.3,0-0.7,0.1-1,0.1c-1.1,0-2.6,0.2-2.6-1.1c0-1.3,1.6-0.8,2.4-0.9c13.8-1.3,27.6-1.9,41.5-0.8
				c1,0.1,1.9-0.2,2.9-0.4C1018.5,1836.6,1018.6,1837.3,1018.7,1837.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M296.9,1720.6
				c-0.7,0.1-1.4,0.2-2,0.3c-0.1-0.8,0.4-1.2,1.1-1.3C296.6,1719.5,296.9,1719.9,296.9,1720.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M294.6,1722.1
				c0.5-0.1,1.2-0.1,1.2,0.5c-0.1,0.9-0.8,0.7-1.4,0.4C294.1,1722.7,294.2,1722.4,294.6,1722.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M254.6,1683.3
				c0.5,1.1,2.6,1.7,1.4,3.4c-0.7,1.1-2.6,0.6-2.2-0.3C254.2,1685.4,254.6,1684.5,254.6,1683.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M198,1835.1
				c0.5-1.2,1.5-1.6,2.8-1.6C200.3,1834.9,199.4,1835.3,198,1835.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M303.2,1625.9
				c-2.1-0.7-2.9-2.6-3.4-4.3c-0.3-1-1.9-3,0.1-3.6c1.4-0.4,3.4,0.4,4.1,2.3c0.7,1.8,1.2,3.7,1.8,5.5
				C305,1625.8,304.1,1625.8,303.2,1625.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M303.2,1625.9
				c0.9,0,1.8,0,2.7,0c1.6,1,2.3,2.4,1.6,4.1c-0.3,0.8-1.3,0.6-2.1,0.3C303.2,1629.6,302.9,1627.9,303.2,1625.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M384,1941
				c-0.3,0.8-1,0.7-1.6,0.6c-0.5,0-1.1-0.2-1.1-0.8c0-0.9,0.7-1,1.3-0.9C383.3,1940.1,383.9,1940.3,384,1941z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M283.4,1800.5
				c0.8-0.9,1.5-1.8,3.1-1.8c-0.4,1.5-1.4,2-2.7,2.2C283.7,1800.7,283.5,1800.6,283.4,1800.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M277.7,1805.3
				c-0.5,0.7-1.1,1.4-2.3,1.1c0.3-1,1.2-1.1,1.9-1.3C277.5,1805.1,277.6,1805.2,277.7,1805.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M283.8,1800.9
				c-0.4,0.9-1.1,1.2-2.1,1.2l-0.2-0.2c0.4-0.8,1.1-1.2,1.9-1.4C283.5,1800.6,283.7,1800.7,283.8,1800.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M277.3,1805
				c0.3-0.7,0.7-1.1,1.4-1.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.3,0.7-0.8,1-1.4,1.2C277.6,1805.2,277.5,1805.1,277.3,1805z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M281,1802.7
				c-0.3,0.5-0.8,0.7-1.3,0.7l-0.1-0.1c0.3-0.5,0.7-0.7,1.3-0.7C280.8,1802.5,281,1802.7,281,1802.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M279.7,1803.3
				c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1-0.1-0.2-0.2-0.3-0.3c0.3-0.2,0.5-0.4,0.8-0.5L279.7,1803.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M281.7,1802.1
				c-0.2,0.2-0.5,0.4-0.7,0.6c0,0-0.2-0.2-0.2-0.2c0.2-0.2,0.5-0.4,0.7-0.6L281.7,1802.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M180.5,1680.2
				c0.1,0.1,0.2,0.3,0.2,0.4c0,0-0.1,0.1-0.2,0.1C180.3,1680.6,180.3,1680.4,180.5,1680.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M691.7,1925.8
				c0.8-0.6,1.6-0.1,2.4,0.2c1.3,1,0.8,2.1-0.1,3c-0.9,0.9-1.2,2-0.9,3.2l0,0c-1.8-0.3-1.2-1.7-1.3-2.8
				C691.8,1928.2,691.7,1927,691.7,1925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M689,1925.8
				c0.5-0.4,1-0.4,1.5-0.1c-0.2,1.1,1.5,3-0.1,3.3C688.7,1929.3,689.6,1926.9,689,1925.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M693.1,1932.2
				c0.3,0.2,0.6,0.5,0.9,0.7c-0.1,0.1-0.3,0.2-0.4,0.3C693.5,1932.8,693.3,1932.5,693.1,1932.2L693.1,1932.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M283.2,1750.9
				c-1-0.1-1.7-0.2-2.5-0.2c-1.9,0-3-0.7-2.1-2.8c0.7-1.6-0.5-4.3,1.6-4.8c2.3-0.6,2.7,2.4,4.1,3.6c0.2,0.2,0.4,0.5,0.6,0.8
				c0.8,0.9,1.8,2,1.1,3.2C285.4,1751.7,284,1750.5,283.2,1750.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M273.6,1750.3
				c-0.8-0.2-2.7,0.6-2.4-1c0.3-1.9,2.4-2.6,4.2-3c0.6-0.1,1.7-0.2,1.3,1.1C276.2,1748.9,276.4,1751.1,273.6,1750.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M600.9,1381.3
				c-1.9-0.5-2.6-1.9-3.3-4c2.6,0.2,3,2,3.6,3.6C601.1,1381,601,1381.1,600.9,1381.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M1019.3,1785.8
				c0,0.1,0,0.1,0.1,0.2c-0.1,0-0.2-0.1-0.3-0.1C1019.1,1785.8,1019.2,1785.8,1019.3,1785.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M692.4,1693.8
				c1.3-0.2,2.1,0.3,3.1,0.7C694.4,1694.7,693.5,1695,692.4,1693.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M297.2,1790.6
				c0.6-0.7,1.2-1.3,2.3-1c0,0,0,0.2,0,0.2c-0.6,0.4-1.2,0.8-1.9,1.2C297.5,1790.9,297.3,1790.8,297.2,1790.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M652,1978.5
				c1.2,2.1,2.3,4.3,3,6.6c-0.8,0.1-1.6,0.3-1.2,1.4c0.2,0.7,0.4,1.5,0.7,2.2c0.4,1.1,0.9,2.4,0,3.4c-0.6,0.7-1.6,0.1-2.1-0.4
				c-0.9-0.8-2.5-1-2.3-3C650.5,1985.3,651,1981.9,652,1978.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M218.4,1825.5
				c2.2-0.7,4.3-0.2,6.3,0.8c2.4,1.2,3.6,3,2.2,5.7c-3.4-1.8-7.6-2.2-9.8-5.9C217.6,1825.9,218,1825.7,218.4,1825.5L218.4,1825.5z"
				/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M218.5,1825.5
				c-0.4,0.2-0.9,0.4-1.3,0.7C217.4,1825.4,217.6,1824.9,218.5,1825.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M410.4,1771
				c1.5-0.3,2.1,0.6,1.7,2.7c-0.4,2.3-0.6,4.8-1,7.1c-0.4,1.8-1.2,2.5-2.8,0.8c-2.1-2.2-4.4-4.2-6.6-6.3c-0.7-0.6-1.4-1.2-0.9-2.2
				c0.3-0.8,1.1-1,1.9-1.1C404.9,1771.6,407.3,1771.3,410.4,1771z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M396.6,1775.1
				c2.3-0.6,3.6,0.9,5,2.3c1.6,1.6,3.1,3.2,4.4,5.2C402.7,1780.4,398.9,1778.7,396.6,1775.1L396.6,1775.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M413.6,1786.1
				c2.6,1.5,5,3.1,5.6,6.4c-2.4-0.2-3.9-2.1-5.8-3.3C411.9,1788.2,413.3,1787.1,413.6,1786.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M407.5,1783.7
				c1.6-0.3,2.1,0.6,2.7,1.7C408.5,1785.7,408,1784.9,407.5,1783.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M396.6,1775.1
				c-0.8,0.1-1.5,0.1-1.8-0.8c-0.1-0.4,0.2-0.6,0.5-0.5C396.2,1773.8,396.7,1774.2,396.6,1775.1L396.6,1775.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M209.4,1989.2
				c-2.2,1.6-3.9,2.6-5.8,0.1C205.5,1988.8,207.1,1988.7,209.4,1989.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M226.4,1794.7
				c-1.7,0.7-3.4,1.1-5.3,0.7c-0.2-3.1-0.1-6.3-0.7-9.4c-0.6-3.6,1.3-5.4,3.9-7c1.4-0.9,2.1-0.8,2.1,1.2
				C226.3,1785,226.4,1789.8,226.4,1794.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M502.1,1944.2
				c-0.7,0.8-1.3,0.5-2,0.1C500.9,1943.6,501.5,1943.8,502.1,1944.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.8,1838.5
				c-4.6,0-9.1,0-13.7-0.1c-11.6-0.1-23.1,0.5-34.7,1.3c-2.6,0.2-5.2,0.4-7.7-0.8c3.3-2.1,6.7-4.6,10.9-3.5
				c8.1,2.1,16.4,0.5,24.5,0.8c4.9,0.2,9.8,0.2,14.7,0.3c1.9,0,3.7,0.2,5.4,0.9C809.4,1837.8,809.6,1838.2,809.8,1838.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.4,1832.7
				c-13.5-1.5-27-0.2-40.5,0.3c-5.3,0.2-10.6,0.6-16,0.3c0.4-1.3,1.2-1.7,2.3-1.8c2-0.2,3.9-0.5,5.5-1.7c0.3-0.2,0.9-0.4,1.2-0.3
				c11.2,2.4,22.4-0.2,33.7,0.5c4.4,0.3,8.9,0.4,13.4,0.7C809,1831.3,809.2,1832,809.4,1832.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.9,1851
				c-10.6,0-21.3-0.3-31.9,0.3c-5,0.3-10.2,0.6-15.2,1.4c-2.9,0.5-5.6-0.1-8.2-1.9c18.2-2.1,36.6-2.4,54.9-1.8
				C809.6,1849.7,809.7,1850.4,809.9,1851z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.7,1845.1
				c-9.4-1.1-18.8-0.6-28.2-0.3c-7.5,0.3-15.1,0.7-22.6,1c-1.2,0-2.7,0.2-3.9-1.5c18.3-2.1,36.3-2.5,54.3-1.2
				C809.5,1843.9,809.6,1844.5,809.7,1845.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.5,1849.1
				c0.2-0.4-0.1-1.5,0.9-0.7C810.1,1848.7,809.8,1848.9,809.5,1849.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M130.4,1560.7
				c0,0.3,0,0.7,0,1c-0.1,1.2,0,2.8-1.4,2.9c-1.3,0.1-2.4-1.5-2.5-2.6c-0.1-2.1-1.3-3.6-2.2-5.3c-0.8-1.6-1.4-3.6,0.5-4.6
				c1.9-0.9,3.3,0.4,4.1,2.2C129.9,1556.4,130.4,1558.5,130.4,1560.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M880.8,1719.2
				c0.8,0.6,1.6,1.1,1.6,2.2l0.1-0.1c-1.4,0.1-1.7-0.9-2-1.9C880.6,1719.4,880.7,1719.3,880.8,1719.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M880.5,1719.5
				c-0.2-0.2-0.5-0.5-0.5-0.5c0.4-0.5,0.6,0,0.8,0.2C880.7,1719.3,880.6,1719.4,880.5,1719.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M974.6,1961.5
				c-2.2-0.7-3.6-2.5-5.5-3.7c-1.2-0.7-0.5-1.9-0.5-2.9c0.8-1.8,2.8-1.6,4.2-2.5c0.8-0.5,1.1,0.5,1.3,1.3
				C974.6,1956.3,974.6,1958.9,974.6,1961.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M963.7,1949.5
				c0.7-0.3,1.5-0.7,1.9,0.1c0.4,0.8-0.3,1.3-1.1,1.5C963.7,1950.8,963.6,1950.2,963.7,1949.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M228.4,1728.6
				c0.4,0.3,0.9,0.6,1.3,0.9c-0.2,2.3-2,3.6-3.4,5.3C226.4,1732.5,227.2,1730.5,228.4,1728.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M971.9,1885.1
				c-1,1.2-2.2,0.7-3.3,0.2C969.6,1884,970.7,1884.1,971.9,1885.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M515,1680.4
				c0.6-0.5,1.2-0.5,1.9-0.1C516.3,1680.8,515.7,1680.9,515,1680.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M804.2,1817
				c0.4-0.1,0.8-0.2,1,0.3c0,0.1-0.1,0.4-0.3,0.4C804.5,1817.7,804.3,1817.5,804.2,1817L804.2,1817z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M978.4,2013.4
				c-0.2,1.3-0.7,2.1-2.2,1.5C976.5,2013.8,977.2,2013.1,978.4,2013.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M972.5,2017.4
				c0,0.5,0.1,1,0.1,1.5c-0.6,0.3-1.4,0.3-1.5-0.5C971,2017.4,971.6,2017.1,972.5,2017.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M898,1709.9
				c-0.7-0.2-1.1-0.7-1.3-1.4c0.2-0.1,0.3-0.3,0.4-0.4c0.6,0.3,1,0.8,1.2,1.4C898.3,1709.6,898.1,1709.7,898,1709.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M896.5,1707.5
				c0.2,0.2,0.5,0.4,0.7,0.6c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2-0.2-0.4-0.5-0.6-0.7C896.3,1707.7,896.4,1707.6,896.5,1707.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M898.4,1709.5
				c0.1,0.2,0.4,0.4,0.3,0.5c-0.3,0.5-0.5,0.1-0.7-0.1C898.1,1709.7,898.3,1709.6,898.4,1709.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M282.6,1896.8
				c-0.8,0.2-1.5,0-1.7-0.9c-0.2-1-0.3-2,0.5-2.7c0.6-0.6,1.2-0.1,1.5,0.6c0.6,1.3,0.9,0.2,1.2-0.2c0.6-0.7,1.2-0.9,1.9-0.2
				c0.5,0.6,1.1,1.2,1.1,1.9c0.1,0.9-0.9,1-1.6,1.2C284.5,1896.5,283.6,1896.6,282.6,1896.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M368.8,1702.3
				c0.1-1.3,0.1-3,0.3-4.6c0.1-0.8,0.5-1.4,1.5-1.4c0.9,0,1.3,0.6,1.3,1.5c-0.1,2.1,1.2,4.1,0,6.3c-0.5,0.9-0.5,1.9-1.8,1.7
				c-1.2-0.1-1.2-1-1.2-1.9C368.8,1703.4,368.8,1703,368.8,1702.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M298.5,1835
				c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2C298.4,1834.8,298.5,1834.9,298.5,1835z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M381.5,1920.4
				c-0.5,0.6-0.9,0.6-1.4,0C380.6,1920.1,381.1,1920.1,381.5,1920.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M303.3,1684.8
				c0.2-0.8,0.6-0.9,1.3-0.6C304.4,1684.9,303.9,1685.1,303.3,1684.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M301.9,1685.4
				c0.2-0.6,0.7-0.8,1.3-0.6C303.1,1685.6,302.6,1685.6,301.9,1685.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M300,1686.7
				c0.2-0.6,0.6-0.9,1.3-0.6C301.1,1686.9,300.6,1686.9,300,1686.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M304.6,1684.1
				c-0.1-0.5,0.2-0.6,0.6-0.6C305.3,1684,305,1684.1,304.6,1684.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M301.3,1686.1
				c-0.1-0.5,0.2-0.6,0.6-0.6C302.1,1686,301.7,1686.1,301.3,1686.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M299.3,1687.4
				c0-0.5,0.2-0.6,0.7-0.7C300,1687.3,299.7,1687.4,299.3,1687.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M297.6,1791.1
				c-0.3,0.5-0.6,0.9-1.2,0.9c-0.1,0-0.3-0.3-0.3-0.4c0-0.8,0.6-0.7,1.1-0.9C297.3,1790.8,297.5,1790.9,297.6,1791.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M298.6,1797.4
				c-0.6,0.1-1.1,0-1.2-0.7c0-0.1,0.1-0.3,0.2-0.3C298.2,1796.3,298.5,1796.8,298.6,1797.4L298.6,1797.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M298.7,1797.3
				c0.1,0.1,0.3,0.2,0.4,0.3C298.7,1798,298.7,1797.6,298.7,1797.3C298.6,1797.4,298.7,1797.3,298.7,1797.3z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				295.8,1795.1 295.6,1795.1 295.7,1795 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M312.5,1921
				c-1.1-0.9-0.8-1.6,0.3-2.2C313.3,1919.6,313.4,1920.4,312.5,1921z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M129.1,1756.1
				c-3.2-0.1-5.1-2.4-7-4.6C124.7,1752.5,127.4,1753.5,129.1,1756.1L129.1,1756.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M129,1756.1
				c0.1,0.1,0.2,0.1,0.3,0.2C129.2,1756.2,129.1,1756.2,129,1756.1L129,1756.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M154.6,1725.7
				c-1.1,0.4-1.9,0.6-2.1,0c-0.2-0.7,0.7-0.5,1.1-0.5C153.9,1725.2,154.1,1725.5,154.6,1725.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M295.3,1769.2
				c0.1,0,0.2,0.1,0.3,0.1C295.5,1769.3,295.5,1769.2,295.3,1769.2L295.3,1769.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M145.8,2017.1
				c-0.2-0.1-0.4-0.2-0.6-0.4c0.2-0.2,0.3-0.5,0.5-0.6c0.2,0,0.6,0.2,0.7,0.4C146.6,2016.9,146.2,2017,145.8,2017.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M767.4,1241.7
				c-0.5,3.8,0.3,7.8-2.6,11.1c-2.1-0.6-4.4-0.6-3.9-4.1c0.4-2.8,3.1-1.4,4-3c0.1-0.2,0.6-0.1,0.9-0.2c0.2-0.1,0.3-0.3,0.5-0.5
				c-0.6-1.4-2-1.3-3.2-1.7c-0.9-0.3-1.9-0.6-1.6-1.6c0.3-1.2,1.5-0.8,2.2-0.4C764.9,1242,766.1,1241.6,767.4,1241.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M766.6,1254
				c0.6,1,0.3,2.2-0.5,2.5c-3.8,1.6-3.8,4.4-2.9,7.7c0.1,0.2-0.3,0.6-0.4,0.8c-1.7-0.8-4.5-0.5-3.7-3.7c0.1-1-0.3-1.2-1.2-0.9
				c3.2-0.5,3.5-1.1,2.6-4c-0.2-0.8-0.7-2,0.1-2.7c0.7-0.6,1.7-0.2,2.5,0.2C764.3,1254.5,765.4,1255.2,766.6,1254z M762.4,1256.1
				c-0.9-0.4-1.3-0.3-1.1,0.8c0.2,1.7,1.4,1.1,2.4,1.1c0.2,0,0.6-0.4,0.6-0.6C764.1,1256.2,763.2,1256.3,762.4,1256.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M764.8,1267.8
				c-1.1,1.7-0.1,4.4-3.4,5.1c-1.6,0.3,0.3,3.1-0.5,4.7c-0.4-0.1-0.9-0.4-1.2-0.3c-2.5,0.4-1.2-2.6-2.7-3.1c0.7-0.5,1.2-1.3,2-1.5
				c2.2-0.5,2.5-1.5,0.9-3c-0.9-0.9-0.9-2.1-0.4-2.9c0.5-0.7,1.5-0.2,2.3,0.3C762.6,1267.5,763.7,1267.5,764.8,1267.8z
				 M759.9,1274.8l-0.2-0.1l0,0.2L759.9,1274.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M760.5,1308
				c4.5,1,8.9-0.9,13.3-0.3c0.9,0.1,2.4-0.5,2.4,0.8c0.1,1.3-1.4,1.1-2.3,1.1c-4.4,0.1-8.7,0.2-13.1,0.3c-1.1,0-2.3,0-2.6-1.4
				C759,1308.4,759.8,1308.2,760.5,1308z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M762.6,1292.5
				c0.6,2.4-1.2,2.5-2.7,2.9c-0.9-0.4-2.7,1.2-2.9-1.2c-0.1-1.5,0.2-2.7,2.1-2C760.3,1292.6,761.4,1293.1,762.6,1292.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M763.4,1280.3
				c-0.2,0.7,0.4,2.1-1,1.7c-1.3-0.3-3.1-0.1-3.6-1.5c-0.4-1,1-0.7,1.6-1C761.3,1279.8,762.3,1280.1,763.4,1280.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M695.7,1259.3
				c0.2,0.7,1.3,1.7-0.5,1.8c-0.7,0.1-1.5-0.3-2.2-0.1c-0.7,0.2-1.4,1.5-2,0c-0.6-1.4,1.2-0.9,1.5-1.6
				C693.6,1259.4,694.7,1259.4,695.7,1259.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M757.7,1298.5
				c-0.2,0.2-0.5,0.4-0.7,0.7C756.9,1298.7,757.2,1298.5,757.7,1298.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M754.7,1300.2
				c-0.3-0.3-0.6-0.6-0.1-0.9c0.1-0.1,0.4,0.2,0.7,0.3C755,1299.8,754.8,1300,754.7,1300.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M780.6,1458.6
				c0.1,5.5-2.8,9.2-6.8,9.9c-2.7,0.5-4.3-0.7-5.6-2.4c-3.4-4.3-4.6-9.3-4.4-14.7c0-0.5,0.5-1,0.8-0.7c2.3,2.1,4.5-0.1,6.6-0.4
				C776.3,1449.7,780.7,1453.4,780.6,1458.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M942.3,1526.7
				c1.4,2.3,0.6,4.4,1.2,6.2c0.3,1,0.5,1.8,1.8,1.9c1.4,0.1,1.4-0.8,1.8-1.7c0.7-1.8,0.3-5.1,2.3-5c1.8,0.1,1.4,3.5,2.2,5.3
				c0.4,0.9,0.5,2.1-0.2,3.4c-1-0.8-1.7-1.4-3.4-0.7c-3.5,1.5-6.9,1.1-6.3-4.3C941.7,1530.3,941.1,1528.7,942.3,1526.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M959.4,1510.1
				c3.2-1,6.6,0.8,6.1,3.4c-0.3,1.7-1.5,3.3-3.6,3.1c-2.5-0.2-3.2-2.1-3.3-4.1C958.6,1511.7,959.1,1510.8,959.4,1510.1L959.4,1510.1
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960.7,1597.3
				c1.3,2.2,3.3,1.2,5,1.1c-6.3,1.4-12.5,3.4-19,2.8c0.1-0.2,0.3-0.4,0.4-0.6c2.7-0.3,5.4-0.9,8-0.8
				C957.5,1599.9,958.3,1597,960.7,1597.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M787.5,1475.6
				c2.2,0.1,3.9,0.3,3.9,2.6c0,1.5-0.6,2.8-2.5,2.7c-2-0.1-3.4-0.7-3.3-3C785.5,1476.2,786.4,1475.6,787.5,1475.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M789.4,1426.6
				c-0.1,2.2-1.2,2.5-2.7,2.4c-1.8-0.2-2.4-1.5-2.3-2.9c0.1-1.3,0.8-2.6,2.5-2.3C788.8,1424,789.4,1425.3,789.4,1426.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M788.2,1416.2
				c2.5,1.4,1.2,3.5,1.7,5c0,0.1-0.4,0.5-0.6,0.6c-0.5,0-0.8-0.3-0.8-0.8C788.5,1419.5,788.8,1418,788.2,1416.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M780.1,1418.2
				c0-0.8-0.3-2.1,0.9-1.9c1.6,0.3,1.8,2.1,2.1,3.4c0.3,1.1-0.3,2-1.5,2C780.6,1421.8,780.1,1420.7,780.1,1418.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M944.8,1518.4
				c-1.3,0.1-2.6,0.2-3.9,0.2c-1,0-1.5-0.5-0.8-1.4c0.7-1,4.6-0.5,5.5,0.7C945.4,1518,945.1,1518.2,944.8,1518.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M939.5,1579.2
				c1.8,0,2.4,0.8,2.5,2.2c0,0.9-0.3,1.5-1.2,1.5c-1.3-0.1-2.2-0.9-2.4-2.1C938,1579.6,938.8,1579.3,939.5,1579.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M775.8,1423.6
				c1.4,0,2.1,0.5,2.3,1.6c0.1,0.6-0.3,1.1-0.9,1c-1.1-0.1-2.3-0.3-2.5-1.6C774.5,1424,775.3,1423.8,775.8,1423.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M799,1466.4
				c0.6-2.8,0.3-5.4,0.1-8c-0.1-1.4-0.2-3,2.3-2.1c2.1,0.7,4-0.7,5.9-1.4c0.6-0.3,1.2-1.3,2-0.5c0.5,0.5,0.4,1.3,0.1,1.8
				c-0.7,1.5-0.4,3-0.4,4.5c0,1.8-0.3,4,2.7,2c0.8-0.6,1.3,0.3,1.9,0.8c2.4,2.2,2.2,6.1,5.5,7.7c0.6,0.3-0.1,0.9-0.6,1.1
				c-0.9,0.6-1.7,0.4-2.2-0.6c-0.8-1.4-0.9-2.9-3.2-3.5c-2.3-0.5-3.9-1.8-5,1.3c-0.1,0.4-1.3,0.8-1.8,0.6c-2-0.7-4.1-1.3-6.2-1.6
				C799.1,1468.3,799.1,1467.1,799,1466.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M847,1501
				c0.6,0.1,1,0.4,1.1,1c0.1,0.5-0.4,0.8-0.8,0.7c-0.3-0.1-0.7-0.5-0.8-0.8C846.2,1501.6,846.4,1501.1,847,1501z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M929.6,1333.5
				c0,0.1,0.1,0.2,0.1,0.4c-0.2-0.1-0.5-0.2-0.7-0.3C929.2,1333.6,929.4,1333.6,929.6,1333.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M796.3,1256.9
				c1.3-1.5,2.2-1.7,3.3-0.6c0.5,0.6,1.1,1.6,0.4,1.9C798.8,1258.6,797.9,1257.2,796.3,1256.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M751,1387.7
				c-0.9-0.9-2.4-0.8-3-2.1c3.5,0.3,6.9,0.4,10.3,1.1c1.4,0.3,3.3-0.3,4.7,1.7C758.7,1388.4,754.8,1388.4,751,1387.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M773.5,1385.7
				c1.2-3.2,3.1-1.5,4.6-2c0.1,0.3,0.1,0.6,0.2,0.9C776.8,1385,775.3,1385.3,773.5,1385.7z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				797.3,1377.3 797.4,1377.5 797.2,1377.5 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M956,1449.7
				c-1.9-0.2-2.7,0.7-2.5,2.6c-3.3-2.1-6.1-4.6-8.2-7.9c-0.1-0.2-0.2-0.5-0.1-0.6c2.1-4.6-1.4-4.2-4.1-4.7c-1.8-0.3-3.8-0.8-3.2-3.5
				c0.2-1.1,1.1-2.1,2-1.3c1.9,1.5,2.4,0.1,3.2-1.1c1-1.4,2.1-2.7,2.9-4.3c0.7-1.5,1.5-2.8,0-4.4c5.7,2,8.4,6.4,10.7,11.4
				c2.2,4.9,3.7,9.9,3.4,15.3c-0.1,1.1,0.7,1.7,1.4,2.4c0.9,0.9-0.6,0.2-0.5,0.6c-1.8-0.6-3.4-1.4-3.9-3.4c0.4-0.4,0.5-0.9,0.1-1.4
				C956.8,1449,956.3,1449.2,956,1449.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M960,1432.1
				c-0.2-0.2-0.5-0.3-0.7-0.5c-4.8-4.9-10.2-8.8-16.6-11.4c-6.7-3.7-13.3-7.4-20-11.1c0.1-0.6-0.3-1.8,1.1-1.4
				c1.9,0.5,3.8,0.4,5.6,1.5c7.3,4.4,15.1,8,21.9,13.3c2.7,2.1,4.6,5.7,8.6,6.3c1,0.2,1.7,1.5,1.7,2.6
				C961.6,1432.3,960.5,1431.8,960,1432.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M898.8,1448.5
				c0.3-0.1,0.6-0.2,0.9-0.3c4.9-0.6,5.6-1.4,5.8-6.8c0-0.3,0.1-0.6,0.2-1c1.4,2.8,1.3,4.4,0.4,7.6c-0.7,2.4,0.6,4.7,1.3,6.9
				c0.5,1.4,1.2,2.8,0.1,4.2C903.5,1456.5,900.2,1453.4,898.8,1448.5C898.9,1448.5,898.8,1448.5,898.8,1448.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M940.9,1445.5
				c0,1.2-0.1,1.8-1.3,1.6c-2-0.4-3.6-1.3-3.9-3.4c-0.2-1.3,1.3-1.4,2.2-1.4C940.4,1442,940.1,1444.4,940.9,1445.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M947.6,1455.3
				c1.5,0.6,3.1,1.1,4.6,1.7c-0.1,1-0.2,2-0.9,2.7c-0.5,0.6-1.6,0.5-1.5-0.1C950.3,1457.4,947.7,1457,947.6,1455.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M859.1,1418.8
				c1.3,0.1,4.5,4.2,4.4,5.7c-0.1,1.6,0.4,2.6,1.9,1.3c1.6-1.4,2.1,0.2,3,0.6c0.6,0.3,1.3,0.7,0.7,1.5c-0.7,0.9-1.4,1.8-2.6,1.9
				c-3,0.3-9.3-6.6-8.6-9.4C858.1,1419.8,858.1,1418.9,859.1,1418.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M853.1,1406.3
				c-1-2.1-0.1-3.4,0.1-4.7c0.2-1.3,0.8-2.4-0.1-3.7c-0.5-0.7-0.7-1.8,0.5-2c0.9-0.2,1.5,0.5,1.6,1.6
				C855.6,1400.5,854.7,1403.2,853.1,1406.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M906.3,1352.6
				c-0.2,0.7,0.4,1.8-0.9,2.1c-1,0.2-1.3-0.7-1.7-1.2c-0.9-1.2-1.7-2.5-2.7-3.7c-0.5-0.6-1.2-1.2-0.5-1.9c0.5-0.5,1.4-0.2,1.8,0.2
				C903.8,1349.5,906.7,1349.7,906.3,1352.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M808.2,1266.2
				c1.6,1.5,2.5,3.4,2.5,5.7c-1.2-1.6-2.4-3.2-3.6-4.8C807.5,1266.8,807.8,1266.5,808.2,1266.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809,1277.5
				c0.4,0.5,0.7,1,1.1,1.6c-1.2,0.2-1.2-0.7-1.5-1.2C808.7,1277.7,808.9,1277.6,809,1277.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M357.5,1296.4
				c1.1-2.7,2.3-5.4,3.2-8.2c0.7-2.1,1.5-2.6,3.1-0.8c2.1,2.5,4.9,4.3,6.6,7.1c-0.5,2.7-3,1.5-4.5,2c-2,0.6-4.3,0.1-6.3,1.2
				C358.3,1298.3,357.8,1297.4,357.5,1296.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M357.2,1280.9
				c3.5,2.2,4.1,4.8,1.9,8.4c-1.2,1.8-1.7,4-2.5,6c-1.1,0.6-2.1,1-2.2-0.8c-0.1-1.1,0.3-2.2-0.4-3.1c0-3-0.7-6,0-9
				C355,1281.7,355.9,1280.8,357.2,1280.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M344,1281.6
				c2.9,0.3,4.6-1.7,6.6-3.2l0,0c1.6,0.1,0.8,1.5,1.2,2.2c0,0.8,0.1,1.6,0.1,2.4c-0.9,0.8-1.8,1.7-2.6,2.5
				C345.1,1287.4,345.4,1283.3,344,1281.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M349.3,1285.4
				c0.9-0.8,1.8-1.7,2.6-2.5c0.7-0.2,1.2-1.1,2.1-0.7c-0.7,3-0.1,6,0,9c-0.1,0.5-0.2,1.1-0.3,1.7c-2.2-1.3-1.6-3.4-1.5-5.1
				C352.2,1285.7,351.4,1285,349.3,1285.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M363.9,1273.8
				c3.3-0.9,4.6,0.4,5,3.7c0.6,4.3,1.7,8.5,2.6,12.8c0.1,0.7,0.5,1.6-0.4,2c-0.7,0.3-1.1-0.4-1.5-0.9c-0.1-0.2-0.3-0.4-0.4-0.5
				c-5.2-3.8-7.9-8.3-3.7-14.5c0.4-0.6,0.4-1.2-0.1-1.8c-1.3,1.3-1.3,3.2-2.2,4.6c-0.6,1-0.2,3.2-2,2.9c-1.5-0.3-2.9-1.5-2.7-3.5
				c0.4-0.4,1.1-0.6,0.7-1.4l0.1-0.1c1.2,0.3,1.7,0,1.3-1.3l0,0C362.3,1276.4,362.9,1274.8,363.9,1273.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M354.7,1272.9
				c1.1-2.3,2.2-4.5,3.1-6.8c0.7-1.8,1.6-2.1,3.1-0.9c1.6,1.3,2.9,3,5,3.6c1,0.3,0.6,1,0.3,1.5c-0.6,0.9-1.4,1.6-2.4,2.1
				c-1.5,0.7-2.7,1.6-3.3,3.2l0,0c-0.8,0-1.4,0.3-1.3,1.3l-0.1,0.1c-1.1,0-2.1,0-3.2-0.1c-0.5-0.6-0.9-1.2-1.4-1.9
				C354.6,1274.4,354.7,1273.6,354.7,1272.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M366.3,1265.2
				c0,0.7,0.2,1.3-0.5,1.6c-0.6,0.2-1-0.2-1.4-0.6c-1.1-1-2.2-2-3.3-3.1c-0.9-0.8-1.4-1.6-0.4-2.7c0.5-0.6,0.5-1.6,0.9-2.4
				c0.5-1,0.2-2.8,1.7-2.7c1.7,0.1,0.9,1.8,1.2,2.9C365.2,1260.6,365.8,1263,366.3,1265.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M353.8,1259.4
				c0.9,0.6,1.7,1.2,2.6,1.8c1.4,0.8,1.7,1.9,0.7,3.1c-1.3,1.4-1.3,3.3-2.2,4.9c-0.3,0.5-0.2,1.5-1.2,1.2c-0.7-0.2-0.5-0.8-0.6-1.3
				c-0.2-1.7-0.3-3.4-0.5-5.1C353.8,1262.7,352.3,1260.6,353.8,1259.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M338.6,1258.2
				c0.6-2.4,1.8-4.1,4.6-3.4c0.6,0.7,0.8,1.5,0.6,2.5c-0.5,0.5-0.6,0.9-0.1,1.5c0,0.7,0,1.5-0.1,2.2c-0.1,0.8-0.7,1.4-1.4,1.6
				c-0.8,0.3-1-0.6-1.4-1.1C340.1,1260.5,338.8,1259.7,338.6,1258.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M362.1,1250.9
				c-0.1,0.8-0.7,1.1-1.3,1.3c-1.4,0.4-2.9,0.9-4.4,1.2c-1.2,0.2-2.5,1.6-3.5,0.2c-0.8-1.2,0.6-2.4,1-3.6c0.5-1.6,1.9-1.1,3-1
				c1.3,0.2,2.6,0.4,3.8,0.8C361.2,1250,362,1250.1,362.1,1250.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M349.3,1261.9
				c-1.5,0.1-2.6,2-4.4,1.1c0.9-1.5,1.6-3,1-5c-0.3-0.9-0.6-2.6,1.3-2.7c1.3,0,2.2,0.7,2.1,2.2c-0.1,0.9,0.4,1.4,1.3,1.5
				c0.1,0.6,0.1,1.1,0.2,1.7C350.3,1261.2,349.8,1261.6,349.3,1261.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M355.9,1277
				c1.1,0,2.1,0,3.2,0.1c0.4,0.8-0.4,1-0.7,1.4c-1.2-0.4-1.8,0.5-2.2,1.3c-0.4,0.8,0.9,0.5,1,1.2c-1.3-0.1-2.2,0.8-3.2,1.4
				c-0.9-0.5-1.4,0.4-2.1,0.7c0-0.8-0.1-1.6-0.1-2.4C353,1279.2,354,1277.6,355.9,1277z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M350.8,1260.9
				c-0.1-0.6-0.1-1.1-0.2-1.7c1.8-1.4,3.6-2.9,5.4-4.3c0.6-0.4,1.6-0.7,2.1-0.1c0.7,0.9-0.2,1.5-0.9,2l0.1-0.1
				c-1.2,0.8-2.7,1.3-3.4,2.7c-1.5,1.2,0,3.3-1.2,4.6C351.5,1263.2,354.3,1260.2,350.8,1260.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M346.5,1264.6
				c1.2-0.1,2.1-0.6,2.8-1.5c0.4,0.1,0.8,0.1,1.2,0.2c0,1.3-0.1,2.6,0.1,3.9c0.2,1.7-0.7,2-2,2l0,0c0.2-1.1-0.3-1.4-1.3-1.3l0,0
				c0.3-0.7-0.1-1.1-0.7-1.3C346.3,1265.9,346.2,1265.3,346.5,1264.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M348.6,1269.2
				c1.3,0,2.3-0.4,2-2c-0.2-1.3-0.1-2.6-0.1-3.9c1.4,1.3,0.8,3.1,0.6,4.5c-0.3,2.9,0.8,4.5,3.5,5.1c-0.1,0.7-0.2,1.5-0.3,2.2
				c-1.3-0.6-1.7-3-3.8-1.9C350.8,1271.4,348.7,1270.8,348.6,1269.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M357.2,1256.8
				c0.2-0.1,0.4-0.1,0.6-0.2c1-0.3,2.2-1.6,2.9-0.4c0.7,1.2-0.6,2.4-1.2,3.5c-0.4,0.9-1.4,0.5-2,0.1
				C356.2,1259,356.6,1257.8,357.2,1256.8L357.2,1256.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M348.6,1251.4
				c-0.5,0.4-0.2,0.7,0,1c0.4,0.7,1.5,1.3,0.5,2.2c-0.6,0.5-1.5,0.3-2.2-0.1c-1-0.6-1.2-1.6-0.9-2.6c0.5-1.4,1.4-0.3,2.1-0.2
				C348.3,1251.8,348.5,1251.6,348.6,1251.4C348.6,1251.5,348.6,1251.4,348.6,1251.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M360.5,1275.7
				c0.6-1.6,1.9-2.5,3.3-3.2c0,0.5,0,0.9,0,1.4C362.9,1274.8,362.3,1276.4,360.5,1275.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M361.2,1253.4
				c-0.3,0.7-0.8,1.2-1.6,1.3c-0.4,0-0.9,0-0.8-0.6c0.1-0.9,0.8-1.4,1.7-1.5C361,1252.6,361.2,1253,361.2,1253.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M343.8,1258.8
				c-0.5-0.5-0.4-1,0.1-1.5C343.8,1257.8,343.8,1258.3,343.8,1258.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M956,1449.7
				c0.3-0.6,0.7-0.7,1.2-0.3c0.4,0.4,0.2,0.9-0.1,1.4C956.4,1450.8,956,1450.4,956,1449.7z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				972.2,1460.8 972.1,1460.5 972.4,1460.6 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M348.6,1269.2
				c0.1,1.6,2.2,2.3,2,4.1c-0.1,0.7-2.4,0.6-0.8,1.9c1.1,1,0,2.3,0.7,3.2l0,0c-0.3-0.4-0.7-0.3-1.2-0.3c-1.4,0.1-2.4,2.4-3.7,1.3
				c-1-0.9-0.1-2.7-0.3-4.1c0-0.3-0.1-0.6-0.2-1c0.4-1.1-1-2.5,1-3.3c1-0.4,0-1-0.2-1.4c-0.6-1.1-1.4-2.1,0.6-2.5
				c0,0.5,0.3,0.7,0.7,0.8l0,0C347.5,1268.5,347.8,1269.1,348.6,1269.2L348.6,1269.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M347.3,1267.9
				c-0.5-0.1-0.7-0.3-0.7-0.8l-0.2-0.2l0.1-0.2C347.2,1266.8,347.6,1267.2,347.3,1267.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M242.6,1261.7
				c-1.9,1.6-4,3.2-2.9,6.2c-2.7,3.2-5.4,6.5-8.1,9.7c0-1.1-1.6-1.8-2.9-1.4c-0.1,0-0.4-0.4-0.7-0.6c4.7-5.6,9.5-11.2,14.2-16.9
				l0.2,0.1l0.2-0.2C242.6,1259.7,242.6,1260.7,242.6,1261.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M216.2,1325.1
				c1.2,0.7,1.2,1.6,0.6,2.7C215.5,1327.1,215.6,1326.2,216.2,1325.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M157.3,1298
				c0.1,0,0.2,0.1,0.2,0.1C157.4,1298,157.4,1298,157.3,1298L157.3,1298z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M248.3,1271.5
				c0.4,0.5,0.6,1,0,1.5c-0.2-0.3-0.4-0.6-0.6-0.9C247.9,1271.9,248.1,1271.7,248.3,1271.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M202.7,1311.1
				c-0.2-1.6,0.5-2.2,2-2C204.2,1310,204,1311.1,202.7,1311.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M202.8,1311.9
				c-0.2-0.2-0.3-0.4-0.5-0.6c0.2-0.1,0.3-0.2,0.5-0.2C202.7,1311.3,202.7,1311.6,202.8,1311.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M995.7,1563.3
				c-2.7,0.4-5.4,0.6-8.1,0C990.3,1562.8,993,1562.9,995.7,1563.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M991.6,1583.3
				c-0.7-0.9-0.3-1.7,0-2.6c0.9-0.2,1.7-0.3,2.6,0c0,1.1,0,2.2,0,3.2C993.3,1583.7,992.5,1583.5,991.6,1583.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M998,1581.6
				c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0.1-0.2,0.2-0.2c0.4,0,0.6,0.2,0.6,0.6L998,1581.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M686.2,1608.8
				c-1.1-0.2-2.3-0.3-3.5-0.5c-0.7-0.1-2.1,0.2-1.7-1.1c0.2-0.9,1.3-0.9,2.2-0.7c1,0.2,2.1,0.3,3.2,0.5c0.9,0.2,2.4-0.2,2.3,1.1
				C688.6,1609.3,687.2,1608.6,686.2,1608.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M694.2,1608.7
				c0.1,0.7-0.3,1.1-0.9,1.2c-0.9,0.1-1.8-0.2-2.1-1.2c-0.2-0.7,0.3-1,0.9-1.1C693.1,1607.5,693.9,1607.7,694.2,1608.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M736.2,1611.2
				c-1,0.4-1.8,0.4-2.9,0C734.4,1610.7,735.2,1610.7,736.2,1611.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M727,1610.7
				c1.4-0.7,1.9-0.8,2.4-0.2C728.9,1611,728.3,1610.7,727,1610.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M747.5,1617.9
				c-0.9,0.1-1.8,0.1-2.5,0.7c-0.5-0.3-1.3,0.2-1.7-0.8C744.7,1617.3,746.1,1617.4,747.5,1617.9z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M774.1,1973.4
				c-0.3,0.3-0.5,0.5-0.7,0.6c-0.5,0.1-0.8-0.2-0.7-0.7c0-0.2,0.4-0.4,0.6-0.4C773.6,1972.8,773.8,1973.1,774.1,1973.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M816.5,1749.6
				c-0.3,3.1-1.7,5.8-3.8,8c-1.4,1.4-3,0.9-4.6,0.3c-1.6-0.5-2.1-1.8-2-3.3c0.2-2.9,0.9-5.6,2.7-7.9c1.4-1.7,3.2-3.1,5.4-2.3
				C816.2,1745.2,816.2,1747.5,816.5,1749.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M809.7,1737.1
				c-0.2-2.3,0.8-4.4,1.5-6.5c0.4-1.2,1.4-1.6,2.6-0.9c2.8,1.4,2.9,4.3,3.5,6.7c0.5,2-0.8,4.3-2.4,5.7c-1.4,1.2-3.2,0.7-4.4-1.1
				C809.8,1739.7,809.8,1738.4,809.7,1737.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M735.9,1753.3
				c-1-0.1-1.9-0.3-2.9-0.4c-2.5-0.2-3-1.5-2.5-3.8c0.8-4,2.1-8,2-12.2c0-0.3-0.1-0.7,0.1-1c2.1-2.9,4.8-0.2,7.2-0.7
				c1.4-0.3,2,1.1,1.8,2.4c-0.8,4.4-0.8,8.9-2.2,13.2C738.8,1752.8,737.9,1753.6,735.9,1753.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M739.9,1727.5
				c0,0.9,0,1.7,0,2.6c0.1,2.2-0.6,3.5-3.2,3.4c-2.3-0.1-3.8-0.6-3.6-3.3c0.2-1.6-0.1-3.3,0.2-4.9c0.2-1.5-1.2-4,2.1-4.1
				c3.4-0.1,4.5,0.9,4.6,4.3C740,1726.2,740,1726.9,739.9,1727.5C740,1727.5,740,1727.5,739.9,1727.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M774.3,1698
				c0,3.5-0.2,6.6-4,8c-0.8,0.3-1.8,1.1-2.1,2c-1.6,3.9-4.3,3.5-7.4,2.3c-1.2-0.5-3.2-0.1-3.4-1.4c-0.2-1.5,1.7-2.2,2.9-2.7
				c4.1-1.7,5.9-4.3,4.9-8.9c-0.7-3.5,1.4-6.8,1.2-10.4c-0.1-1.2,0.4-2.4,1.9-2.7c1.4-0.3,2.6,0.1,3.6,1.2c1.1,1.2,1.5,2.6,1.7,4.2
				C773.9,1692.4,774.1,1695.3,774.3,1698z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M782.1,1735.3
				c0.2,6.3-0.8,12.2-1.9,18.1c-0.4,1.9-1,3-3.2,2.7c-2.4-0.3-2.9-1.7-2.7-3.6c0.5-4.5,1-9.1,1.6-13.6c0.5-3.1-0.1-6.1,0.2-9.1
				c0.1-1.9,0.6-3.4,3-3.4c2.4,0,3,1.3,2.9,3.4C782,1731.7,782.1,1733.6,782.1,1735.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M794.9,1706
				c0.3,0.7,0,1.3-0.8,1.4c-1.1,0-2.3-0.2-2.9-1.2c-1.2-2.2-1.4-4.8-1.2-7.2c0.1-1.1,1-1.6,2.1-1.5c1.3,0.1,2,0.9,2.1,2.1
				C794.6,1701.7,794.8,1703.8,794.9,1706z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M687.5,1749.2
				c1.5,0.4,2.7,1,2.7,2.8c-1.8,2.7-3.8,0.5-5.7,0.2c0.2-0.9,0.4-1.8,0.6-2.7C686,1749.7,686.8,1749.7,687.5,1749.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M687.8,1747.3
				c-0.1,0.1-0.3,0.3-0.5,0.4c-0.7,0-1.5,0.1-2.2,0.1c-0.2-0.7-0.4-1.4-0.6-2.2C685.6,1746.2,687.9,1744.4,687.8,1747.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M685.1,1747.8
				c0.7,0,1.5-0.1,2.2-0.1c0.1,0.5,0.2,1,0.2,1.5c-0.7,0.5-1.5,0.5-2.4,0.3C685.1,1748.9,685.1,1748.4,685.1,1747.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M190.2,2015
				c0.2,0.1,0.5,0.2,0.7,0.3c-0.2,0.3-0.5,0.6-0.7,0.9C190.2,2015.8,190.2,2015.4,190.2,2015z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M191,1972.8
				c0.8,0.2,2.4-0.4,2.7,1.1c0.3,1.5-1.4,1.2-2.2,1.7c-1.5,0.8-2.8,2.1-4.7,1.8c-0.7-0.1-1.3-0.4-1.6-1c-0.3-0.6,0.2-1,0.6-1.4
				C187.2,1973.7,188.6,1972.5,191,1972.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M900.6,1783.4
				c0.3-0.5,0.2-1.9,1.1-1.3c0.6,0.4-0.2,1.2-0.7,1.7C900.9,1783.6,900.8,1783.5,900.6,1783.4z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M900.5,1784.5
				c-0.3,0.4-0.6,0.7-0.8,1.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.2-0.4,0.5-0.9,0.7-1.3C900.2,1784.2,900.3,1784.4,900.5,1784.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M900,1784.1
				c0.2-0.2,0.4-0.5,0.6-0.7c0.1,0.1,0.3,0.3,0.4,0.4c-0.2,0.2-0.4,0.5-0.6,0.7C900.3,1784.4,900.2,1784.2,900,1784.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M903.2,1823
				c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.1-0.2,0.2c0-0.1-0.1-0.2-0.1-0.2C903,1823.1,903.1,1823,903.2,1823z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M881.1,1760.6
				c2.1,2,4.8,3.4,5.3,6.6C883.9,1765.7,882.1,1763.5,881.1,1760.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M887.1,1790.2
				c-1.9-1-3-2.6-3.4-4.7C886.8,1785.7,886,1788.6,887.1,1790.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M883.8,1785.6
				c-1-0.6-1.7-1.4-1.3-2.7C883,1783.8,884.1,1784.4,883.8,1785.6z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M885.1,1843.8
				c0.3,0,0.5,0,0.8,0c0,0.3,0,0.5,0,0.8C885.2,1844.6,885,1844.3,885.1,1843.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.4,1843.1
				c0.8-0.3,1.2-0.2,0.7,0.7C884.6,1843.9,884.4,1843.6,884.4,1843.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M884.7,1831
				c-0.2,0.1-0.4,0.2-0.7,0.2c0.1-0.2,0.1-0.5,0.2-0.7C884.4,1830.7,884.5,1830.8,884.7,1831z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M752.9,1837.3
				c-0.3,0.1-0.5,0.3-0.7,0.4c-0.1,0-0.2-0.2-0.3-0.4c0.2-0.2,0.4-0.3,0.6-0.5C752.5,1836.8,752.7,1837.1,752.9,1837.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M219.9,1959.3
				c0.6,3.3,1.8,6.7-0.7,9.8C219.1,1965.9,218.3,1962.5,219.9,1959.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M217.1,1962.8
				c0,1.5,0,3,0,4.5c0,0.7-0.1,1.4-0.8,1.6c-0.7,0.2-1.3-0.1-1.8-0.6c-0.5-0.5-0.7-1.1-0.3-1.8c0.7-1.3,1.5-2.6,2.3-3.8
				C216.6,1962.7,216.8,1962.7,217.1,1962.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M228.2,1957
				c0.9,1.5,0.5,3.1,0.4,5.4C227.3,1960.2,227.8,1958.6,228.2,1957z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M762.4,1256.1
				c0.8,0.2,1.7,0.2,1.8,1.3c0,0.2-0.4,0.6-0.6,0.6c-0.9,0-2.2,0.6-2.4-1.1C761.7,1256.6,762,1256.3,762.4,1256.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M757.9,1260.5
				c0.9-0.4,1.3-0.1,1.2,0.9C758.7,1261.1,758.3,1260.8,757.9,1260.5z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M761.3,1256.8
				c-0.2-1.1,0.2-1.2,1.1-0.8C762,1256.3,761.7,1256.6,761.3,1256.8z"/>
			<polygon clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" points="
				759.9,1274.8 759.7,1274.8 759.7,1274.7 			"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M350.6,1278.3
				c-0.7-1,0.4-2.3-0.7-3.2c-1.6-1.3,0.7-1.2,0.8-1.9c2.2-1.1,2.5,1.3,3.8,1.9c0.5,0.6,0.9,1.2,1.4,1.9c-1.9,0.6-2.9,2.2-4.1,3.6
				C351.4,1279.8,352.1,1278.5,350.6,1278.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M359.2,1277
				c-0.1-1,0.4-1.2,1.3-1.3C360.8,1277,360.3,1277.3,359.2,1277z"/>
			<path clip-path="url(#SVGID_2_)" fill="#666666" stroke="#B3B3B3" stroke-width="0.25" stroke-miterlimit="10" d="M348.6,1269.2
				c-0.8-0.1-1.1-0.6-1.3-1.3C348.3,1267.7,348.8,1268.1,348.6,1269.2z"/>
			<g clip-path="url(#SVGID_2_)">
				<path fill="none" d="M1453.5,2289.2c-2.5,5.2-10.8,6-14.3,1.5c3.5,0.2,3,7-0.6,6.6"/>
				<path fill="#616552" d="M1420.1,1701.1c-0.5-18.6-1.9-37.2-1.2-55.7c1.7-47-4.6-93.7-5.5-140.6c-0.3-17.1,5.4-24,22.9-24.1
					c32.3-0.2,64.5-5.4,97-0.9c8.7,1.2,18-3.6,27.1-4.4c45-3.8,90-7.5,135.1-10.6c74-5.1,148-10.1,222.1-14.3
					c18.4-1,37-3.6,55.6-0.2c-0.7,6.8-1.5,13.5-2.2,20.3c-2,50.9,1.9,102-4.2,152.8c-0.6,5.2,2,13.5-6.8,13.2
					c-7.4-0.3-11.4-7.3-9.9-13.9c2.8-11.7-2.6-19.2-10.1-26.4c-0.2-2.3-0.4-4.5-0.6-6.8c3.4-17.7,8.5-35.6-3.3-52.4
					c0.1-4.5,0.2-9.1,0.3-13.6c13.3-14.1,5.1-30.2,4.1-45.5c-0.9-13.3-9.6-17.2-20.7-11.8c-7.4,3.7-14.8,4.7-21.7,2.7
					c-16.6-4.8-32.4-5.9-48,3c-2.6,1.5-6.8,0.5-10.2,0.4c-11-0.3-22-1-33-1c-4.4,0-10.8,0-10,6.4c0.6,5.4,6.8,3.7,10.2,3.3
					c35.7-4.8,71.9-0.9,107.5-4.6c12.3-1.3,14.2,3.1,14.3,12.7c-0.3,23-0.7,46-1,69c-5.5,2.4-5.7,4.8-0.1,7.4
					c0.1,4.6,0.3,9.1,0.4,13.7c-9.4-1.7-9.4,10.8-17.1,11.8c-4.4-3.6,1.8-14.1-7.9-12.8c-6.9,1-7.6,8.5-7.7,13.9
					c-0.2,10.9,1.5,21.8,1.4,32.7c-0.2,23.6,0.3,24.4,25.4,27c4.3,7.5,3,15.8,2.9,23.8c-0.2,20.1-0.6,40.2-1,60.4
					c-5.6,2.2-6,4.7-0.3,7.4c2.6,15.3-1.2,30.2-2.1,45.4c-8.1-3.7-18.1-1.4-26.8-7.8c3.5-3.1,6.1-5.4,8.7-7.6
					c3.9-3.4,9.8-7.2,4.6-12.5c-4.3-4.4-8.6,1.3-11.6,4.2c-7.9,7.7-11.2,17.3-9.9,28.5c0.8,6.3,6.3,15.5-4.1,17.3
					c-9.7,1.7-4.6-9.9-8.6-14.1c-0.6-0.6-1.9-0.6-4-1.1c-1.7,17.7-17.3,18.9-29.3,22.1c-8.2,2.2-10,5.1-10.7,12.3
					c-1,10.4-3.1,18.2-16.7,21.7c-15.8,4-28.2,15.3-25,35.3c0.4,2.6-1.3,5.5-2.3,8.2c-14.4,40.9-20.6,83.7-28.8,126.1
					c-2.7,14-0.1,29.8-14.7,41.1c-5.2-11.8-1.2-21.5,0.5-31.6c5.7-34.2,11.4-68.5,4.4-103.2c-1-5-0.8-10.8-7.2-12.7
					c-16.2-4.8-11.5-9.7-0.3-15.4c7.7-3.9,15.8-28.7,10.1-31.6c-9.9-5-8.8-13.3-11.4-21.3c-2.3-7.1-6-18.3-18-9.6
					c-4.1,3-7.8,9.3-13,4.3c-4.9-4.8-3.5-11.9-0.9-18c1.8-4.2,9.4-8.7,6.9-11.1c-11.8-11-3.5-25.9-9-37.7c0.1-2.2,0.3-4.5,0.4-6.7
					c7.2-1.9,12.1-5.6,7.1-13.4c-4.4-6.9-3.5-14.2-3.8-21.6c2.6-0.8,5.3-1.8,5.5-5c0.1-1.9-1.8-3.6-3-2.3c-1.6,1.8-1.9,4.7-2.7,7.2
					c-5,0.6-9.6,2.5-15,1.5c-19.3-3.6-19.4-3.3-16.6,16.2c0.3,2.2,0.2,4.5,0.3,6.8c-2.3,0.1-4.7,0.1-7,0.2
					c-6.3-16.3-3.4-33.3-2.3-49.9c4.2-63.8,4.1-127.6-1.1-191.3c-0.4-4.9-1.1-9.3-7-10.5c-0.2-0.5-0.3-1.1-0.6-1.5
					c-0.3-0.4-1.1-1-1.1-1c-6.8,5.9-7.3,11.7,0.7,17c-0.1,80.1-0.1,160.2-0.2,240.2c-6.3-0.9-13-5.7-16.5,5.5
					c-3.9,12.6-10.3,12.7-15.6,0c-3.2-7.7-10-6.2-14.6-3.3c-6.1,3.7-2.2,9.3,0.7,13.6c4.5,6.8,12.6,4.9,19,7.1
					c15,5.2,17.4,13.5,7.4,26.4c-7.6-14.5-21.5-16.5-33.4-4.5c-3.2,3.2-4.9,8.6-10.8,6c-5.3-2.3-4.6-7.7-4.4-12.2
					c0.2-4.6,1.1-9.2,2.3-13.6c1.1-3.7,4.9-7.2,1.7-11.1c-1.1-1.4-4.5-2.2-6.1-1.5c-5.4,2.1-4.9,7.3-5,11.9
					c-0.3,11,0.6,22.2-0.8,33.1c-1.2,9.3,1.3,13.1,10.6,12.9c3.4-0.1,7.7-0.1,8.8,4c1.6,5.6-4.1,6-7.1,8.3c-7,5.4-14.9,11.2-12.9,21
					c2.2,11.1,12.9,12.8,22,13.7c6.9,0.7,11.7,1.6,10.2,9.7c-1.3,6.9-4.9,12.1-12.9,11c-11.2-1.5-21-7.5-33.1-7.7
					c-6.4-0.1-15.3-9.1-9.1-20.1c2.7-4.8,4.9-10.2-0.4-14.1c-7.4-5.4-5-10.8-3.4-18.2c1.9-8.9-2.6-23-11.9-19.7
					c-15.6,5.5-25.1,0.8-35.9-8c-4.3-3.5-11.3-7.1-15.6-1.1c-5.4,7.7,2.5,9.3,7.7,11.7c5.6,2.5,7.4,7.2,6.4,12.9
					c-1.2,6.9-7.1,7.1-12.2,7.3c-10.1,0.4-17.4-4.6-23.3-12.6c-3.2-4.4-5.6-12.6-12.6-9.5c-14.1,6.2-13.6-3.5-15.6-12.1
					c-2.6-10.9-1-22-2-33c-0.9-9,2.9-18.9-5-30.1c-2.7,14.3,6,26.3-2.9,40c-4.1-14-1.1-25.6-2.7-36.6
					C1427,1731.8,1430.7,1715,1420.1,1701.1z M1778.4,1494.1c4.1-13.7-3.2-20.6-12.4-21.8c-23.5-3.1-48.1-4.3-70.5,2.7
					c-21.6,6.8-43.7,5.7-65.3,9.7c-26.3,4.9-54-0.5-81.3,2.4c-11.1,1.2-23.1,5.6-33.3,2.9c-19.2-5.1-33.6,7.6-51.3,6.9
					c1.4,5.1,3.8,5.4,6,5.3c21.6-0.8,41.6,8.6,65.3-1.4c22.4-9.4,50.2-5.7,75.8-5.7c16.5,0,34,2.7,49.3-3.6
					c10.7-4.4,20.7-6.6,31.8-6.6c13.3-0.1,26.7-1.3,39.9-1.4C1747.9,1483.4,1766.6,1472.8,1778.4,1494.1z M1775,1735.2
					c-6.9,17.2-11.7,4.6-17.4-0.5c-3-2.6-6-6.1-10.5-3.3c-2.4,1.5-2.1,4.7-0.6,6.2c10.1,10.2,4.6,28.3,19.3,36.4
					c6.9,3.9,1.1,6.9-2.7,9.8c-17.1,12.6-16.4,24.2,1.5,32.4c5.3,2.5,8.9,13.8,16.1,5c4.3-5.3,7.6-14.1,6.7-20.6
					C1784.2,1779.2,1785.6,1756.9,1775,1735.2z M1432.1,1612.4c1.7-0.1,3.3-0.3,5-0.4c-3.9-20.2,3.8-41.2-5-59.1
					C1432.1,1571.5,1432.1,1591.9,1432.1,1612.4z M1806.1,1840.7c4.2-5.3,16.5,0,16.3-11.2c-0.1-5.9-6.2-5.7-10.8-5.9
					c-7.6-0.3-12.2,2.8-11.7,11.1C1800,1838.2,1800.4,1841.9,1806.1,1840.7z M1718.8,1695.6c-0.6-4.4-1.2-10.6-6.1-8.5
					c-6.6,2.8-5.1,11.8-8.3,17.6c-2.1,3.8,2.7,8.9,6.5,7.8C1719,1710.1,1716,1701,1718.8,1695.6z M1432.8,1668.7
					c5.3-9,11.3-13.3,19.7-15.4c-8.8,1.2-14.5-3.4-19.6-9.4C1428,1651.8,1429.1,1658.3,1432.8,1668.7z M1872,1549.6
					c14.4,0,24.7,0,35.1,0C1896.3,1543.2,1885.9,1544.1,1872,1549.6z M1431.1,1531.5c1.4-7.6,3.9-15.3-2.4-24.3
					C1425.3,1517.4,1425,1524.8,1431.1,1531.5z M1917.3,1498.2c-0.5,0-1,0-1.5,0c0,13.1,0,26.2,0,39.3c0.5,0,1,0,1.5,0
					C1917.3,1524.4,1917.3,1511.3,1917.3,1498.2z"/>
				<path fill="#3D4037" d="M1436.2,2102.6c-5.3-5.9-3.6-13.1-3.7-19.9c-1-55.4-1.9-110.9-2.8-166.3c1.2-18.7,12-36,9.7-55.7
					c-1.3,33.4,13.2,65.5,4.8,99.2c-1.2,4.8-1.5,11.4,0.8,15.3c6.6,11.4,5.1,23.5,5.7,35.6c0.2,4.7-2.1,12,5.6,12.5
					c7.1,0.4,16.3-2.7,16.2-10.1c-0.2-12.7,9.6-24.2,3.3-37.4c-2-4.2-1-10.3,3.7-12.4c10.5-4.6,15.4-14.9,22.8-22.3
					c6.2,4,1.7,12.3,7.8,15.3c5.9-5.1,3-14.2,10.6-18.2c2.7,4.4,4.7,9.2,8,12.8c17.6,19.6,35.3,38.4,22.1,68.8
					c-3.4,7.9,5.5,19.7,14.2,17.1c10.8-3.2,20.2-2.1,30.4-1c4.2,0.5,8.2-0.9,10.2-5.2c2-4.3,0.6-9-3.1-10.7
					c-15.8-7.4-6.5-14.5-0.3-21.8c4.1-4.9,8.2-9.7,12.4-14.6c19.2-1,11-11.6,7.2-20.7c3.5-2.6,8.7-4.6,10.6-0.7
					c5.2,10.3,8.8,8.6,14.3,0.4c1.5-2.2,6.2-2.2,9.4-3.3c1,3.8-1,8.4,3.8,13.2c0-39.2-0.1-76.6,0.1-114c0-6.6-1.9-14.3,6.7-17.8
					c0.1,30.6,0.2,61.2,0.3,91.8c0.1,22.9,8.6,32.1,31.4,28.2c19-3.3,21.8,4.9,20.7,19.9c-1,0-2,0-2.9-0.1c-0.4,0.3-1,0.6-1,0.9
					c0,0.8,0.4,1.6,0.6,2.4c1.4,1.2,2.8,2.5,4.2,3.7c2.6,1.1,5.5,1.9,7.9,3.5c5.6,3.5,15.3,5.5,12.2,14.2c-2.5,7-9.8,7.8-17.7,5.4
					c-7.9-2.4-15.3,1.9-18.9,9.1c-3.4,6.7-0.9,13,4.8,18.7c8.6,8.7,13.6,18.3-1,26.6c-3.1,1.8-3.5,4.4-2,7.4
					c10.8,21.2,14.6,43.6,11.5,67.2c-0.7,5.7,3.4,8.2,8.3,9.4c6.8,1.6,8.6-3,8.1-8c-1.2-11.6,10-18.1,11.2-28.7
					c0.4-3.2,1.7-7.4,6.4-6.2c3.4,0.9,3.1,4.5,3.3,7.3c0.7,11.5,1.5,23.1,1.9,34.7c0.1,3.7,0.3,7.4,4.5,8.2c4.1,0.8,6.7-2.4,7.1-6
					c1.1-8.6,7.9-12.3,13.6-17.1c8.6-7.2,11.9-2.8,14.4,5.5c7.7,25.3,18.7,32.1,44.4,27.5c5.9-1.1,9.8,1.1,12.6,6.7
					c2.2,4.5,1.1,10.5,8.2,15c1-13.6,8.4-10.1,16-6.9c5.5,2.3,14,4.2,16.6,0.4c7.6-11.3,32.2-11.5,22.9-33.9
					c-0.3-0.6,1.1-1.9,1.7-2.9c6-3.1,8.1-8.7,6.8-14.6c-5.2-25.5,6.4-50.8,0.7-76.3c11.4-0.5,16.7,5.5,17.1,16.4
					c0.4,11.2,4.9,18.5,17.3,18.5c-0.1,4.8-0.1,9.7-0.2,14.5c-5.8,0.8-16.8-3.1-16.4,3.4c0.7,12.1-6,29.1,13.4,34.6
					c-1.6,34,6.1,69-11,104.5c-8-20.5-23.2-34.1-24.2-55.1c-6.8,10.6-1.7,26.7-20.4,29.8c10.6,0,13.8,5,13.7,13.5
					c-0.2,19.7,0,39.5-0.2,59.2c0,3.8,0.7,9.9-5.4,8.4c-18-4.3-7.6,7.2-6.9,12.4c0.9,6.2,2.3,17.7-3,15.6
					c-8.5-3.3-21.6,5.8-25.6-7.4c-2.5-8.4-1.9-18.7-13.6-19c-8.5-0.2-19.7-0.2-25,4.7c-9.8,9.1-17.7,7.1-28.3,3.6
					c-7.3-2.4-19.1-2.4-21.4,5.2c-2.1,6.8,1.6,18.7,7,23.4c6.6,5.7,15.2,13.6,25.2,12.1c18-2.7,21.5-0.7,23.7,18.6
					c-14.3,1.6-27.1-6.1-41.1-6.6c-7.8-16.9-24.8-21.4-39.5-28.6c-7-3.4-13.4-6.3-15-14.9c-2.5-12.8-9.8-15.9-21-8.8
					c-12.5,7.9-22.7,4.9-31.3-6.2c-4.3-5.6-8.8-13.2-10.6,1.3c-0.4,3.2-2.8,3.8-5.1,2.3c-22.2-14-9.8,5.2-10.5,11.2
					c-0.4,3.4,1.3,6.9,1.1,10.3c-0.4,5.6-5.1,5.6-9.2,5.4c-4.4-0.1-8.1-1.4-7.8-7c0.8-18.1,1.5-36.2,2.3-54.3
					c0.2-4.8,0.8-9.5,6.7-10.7c5.5-1.2,8.5,1.7,11.1,6.2c1.7,2.9,4.4,5.3,6.7,7.9c10.1,11,18.7,9.6,27.4-4.5
					c12.1-2.1,22.6-1.6,27,13.1c2.6,8.5,6.2,3.7,11.1,0.5c12.9-8.4,23.3-22.4,42.1-19.1c6.1,1.1,13.4-7.1,8.3-10.4
					c-8-5.2-21.5-9.2-29.2-5.8c-9.6,4.3-12.5,4.4-13.6-5.2c-1-8.9-4.8-7.4-11.7-6.3c-19.8,3.1-20.4,24.6-34,33.3
					c-7.5-19.5-43.9-14.5-38.2-45.1c-16.9-9.3-8.9-25.5-10.8-39c-0.8-5.5,0.6-13.5-8.2-11.9c-7.3,1.3-4.7,7.9-3.7,12.9
					c1.4,7.1,2.4,14.5-5.8,18c-20.1,8.7-38.5,20.1-54.9,34.2c-18.6,15.9-20.9,15.8-29.4-7.6c-2.7-7.5-3.8-16-16.1-6.3
					c-12.8,10.1-26.3,1.2-32.8-13.9c-5.6-13.1-4-24.4-0.5-36.7c3-10.5,0.7-20.3-8.7-26.1c-21.7-13.6-18.6-42.4-36.5-58
					c-0.6-0.5-0.7-2.5-0.2-3.3c8.3-15.3-2.6-26.2-8.4-38.4c-1.8-3.7-2.4-8.4-8.2-6.1c-4.6,1.8-3.5,5.5-4,9.8
					C1437,2071.5,1441.4,2087.4,1436.2,2102.6z M1708.5,1997.7c-3.8,0.7-8.2-1.4-11.9,2.2c4.9,2.5,8.7,1,12.3-1.8
					c2-0.7,4.4-0.9,4.4-3.8c0-0.4-0.7-1-1.1-1C1709.3,1993.3,1709.1,1995.7,1708.5,1997.7z M1677.1,1993.4
					c-2.6,11.2-16.9,13-16.9,25.3c-0.1,36-0.1,72.1-0.1,108.1c0,7.2,4.1,10,10.8,11.1c7,1.1,13.8,3.3,19.3-3.3
					c4.6-5.7,3.5-11.5,1.5-18c-2.6-8.9-14.6-4.2-16.9-13.1c-2.5-9.8,9.6-5.8,12.1-15c5.8-21.5,2.7-42.5,4.5-63.6
					C1692.4,2012.2,1688.1,2002.4,1677.1,1993.4z M1750.2,2221.9c0-4.2-0.2-8.3-6.1-7.8c-5.5,0.4-10.3,2-10.3,8.8
					c0,5.6,3.2,8.4,8.6,8.2C1748.1,2230.9,1749.9,2227,1750.2,2221.9z M1690.3,1988.1c3.1-6.6,14.3-3.8,16.3-12.1
					c0.9-3.4-3.7-5.6-7.5-4.3c-6.5,2.2-13.7,4-14.1,12.8C1684.9,1987,1686.6,1988.6,1690.3,1988.1z"/>
				<path fill="#4F5346" d="M1655.9,1959.3c-3.2,1-7.9,1.1-9.4,3.3c-5.5,8.1-9.1,9.9-14.3-0.4c-1.9-3.9-7.2-1.9-10.6,0.7
					c-5.6,0.4-12.1-0.8-15.4,5.2c-4.5,8,7.2,5.3,7.5,10.4c0.1,1.7,0.5,3.4,0.7,5.1c-4.1,4.9-8.3,9.7-12.4,14.6
					c-6.1,7.3-15.5,14.5,0.3,21.8c3.7,1.7,5.1,6.4,3.1,10.7c-2,4.2-6,5.6-10.2,5.2c-10.2-1.1-19.6-2.3-30.4,1
					c-8.7,2.6-17.6-9.2-14.2-17.1c13.3-30.4-4.5-49.1-22.1-68.8c-3.3-3.7-5.3-8.4-8-12.8c-7.6,4-4.7,13.2-10.6,18.2
					c-6.1-3-1.6-11.2-7.8-15.3c-7.5,7.4-12.4,17.7-22.8,22.3c-4.7,2.1-5.7,8.2-3.7,12.4c6.3,13.2-3.5,24.7-3.3,37.4
					c0.1,7.5-9,10.6-16.2,10.1c-7.7-0.5-5.4-7.7-5.6-12.5c-0.6-12.1,0.9-24.2-5.7-35.6c-2.3-4-2-10.5-0.8-15.3
					c8.4-33.6-6.1-65.8-4.8-99.2c2.3,19.8-8.5,37-9.7,55.7c0.3-71.9-13.9-143.2-9.6-215.3c10.6,13.9,6.8,30.7,9.2,46.2
					c1.7,11.1-1.4,22.7,2.7,36.6c8.9-13.7,0.2-25.7,2.9-40c7.9,11.3,4.1,21.1,5,30.1c1,10.9-0.6,22,2,33c2.1,8.5,1.5,18.3,15.6,12.1
					c7-3.1,9.4,5.2,12.6,9.5c5.9,8,13.2,13,23.3,12.6c5.1-0.2,11-0.3,12.2-7.3c1-5.7-0.7-10.4-6.4-12.9c-5.2-2.3-13-4-7.7-11.7
					c4.2-6,11.2-2.4,15.6,1.1c10.8,8.8,20.4,13.5,35.9,8c9.3-3.3,13.8,10.7,11.9,19.7c-1.6,7.4-4,12.7,3.4,18.2
					c5.3,3.9,3.1,9.3,0.4,14.1c-6.1,11,2.7,20,9.1,20.1c12.1,0.2,21.9,6.2,33.1,7.7c8.1,1.1,11.7-4.1,12.9-11
					c1.5-8.1-3.3-9-10.2-9.7c-9.1-0.9-19.7-2.6-22-13.7c-2-9.8,5.9-15.6,12.9-21c3-2.3,8.6-2.7,7.1-8.3c-1.1-4.1-5.4-4-8.8-4
					c-9.3,0.3-11.8-3.6-10.6-12.9c1.4-10.9,0.5-22,0.8-33.1c0.1-4.6-0.4-9.8,5-11.9c1.7-0.6,5,0.2,6.1,1.5c3.2,4-0.6,7.4-1.7,11.1
					c-1.3,4.4-2.1,9-2.3,13.6c-0.2,4.5-0.9,9.9,4.4,12.2c5.9,2.6,7.6-2.8,10.8-6c12-12,25.8-10,33.4,4.5c10-12.9,7.6-21.3-7.4-26.4
					c-6.4-2.2-14.5-0.3-19-7.1c-2.9-4.3-6.8-9.9-0.7-13.6c4.6-2.8,11.4-4.3,14.6,3.3c5.3,12.7,11.7,12.5,15.6,0
					c3.5-11.2,10.1-6.4,16.5-5.5c3.2,15.7-2.1,30.9-2,46.5C1656.8,1857.7,1656.2,1908.5,1655.9,1959.3z"/>
				<path fill="#252C24" d="M1841,2370.6c-2.1-19.3-5.7-21.3-23.7-18.6c-10,1.5-18.6-6.4-25.2-12.1c-5.4-4.7-9.1-16.6-7-23.4
					c2.3-7.6,14.2-7.6,21.4-5.2c10.6,3.4,18.5,5.5,28.3-3.6c5.3-4.9,16.5-4.9,25-4.7c11.7,0.2,11.1,10.6,13.6,19
					c4,13.1,17,4.1,25.6,7.4c5.4,2.1,3.9-9.4,3-15.6c-0.7-5.1-11.1-16.7,6.9-12.4c6.1,1.5,5.4-4.7,5.4-8.4c0.2-19.7,0-39.5,0.2-59.2
					c0.1-8.5-3.2-13.5-13.7-13.5c18.7-3.1,13.6-19.2,20.4-29.8c0.9,21,16.2,34.6,24.2,55.1c17.2-35.6,9.4-70.6,11-104.5
					c1-12.7,2-25.3,3.1-38c0.1-4.8,0.1-9.7,0.2-14.5c0.5-9.6-0.6-19.4,3.1-28.7c15.9-0.3,28.1-5.3,30.3-23.4c0.2-1.7,1-3.3,1.7-4.9
					c1.6-3.8,3.3-7.9,8.3-7.4c5.4,0.6,5.4,5.3,5.6,9.3c0.3,6.3,0.1,12.7,0.5,19c0.4,6.5,3.4,11.6,10.8,10.5
					c14.2-2.1,13.6,7.3,12.8,16.2c-2.1,23.2,3.6,45.6,6.4,68.2c-4.5,41.4-0.4,83-4.6,124.5c-2.2,21.3-18.7,32.3-29,46.9
					c-13.9,19.6-29.3,38.6-43.8,57.9c-7,9.4-12.9,12.5-25.7,10.2C1904.4,2381.3,1872.4,2377.9,1841,2370.6z"/>
				<path fill="#252C24" d="M1705.2,2273.6c-8.7,14.1-17.3,15.6-27.4,4.5c-2.3-2.5-5-4.9-6.7-7.9c-2.6-4.5-5.7-7.3-11.1-6.2
					c-5.9,1.3-6.5,5.9-6.7,10.7c-0.8,18.1-1.5,36.2-2.3,54.3c-0.2,5.6,3.4,6.9,7.8,7c4.1,0.1,8.7,0.1,9.2-5.4
					c0.3-3.4-1.4-7-1.1-10.3c0.6-6-11.8-25.2,10.5-11.2c2.3,1.5,4.7,0.9,5.1-2.3c1.8-14.5,6.3-6.9,10.6-1.3
					c8.6,11.1,18.8,14,31.3,6.2c11.2-7,18.5-3.9,21,8.8c1.7,8.6,8,11.5,15,14.9c14.7,7.2,31.7,11.7,39.5,28.6
					c-45-3.7-90-7.3-135-11.3c-6.9-0.6-11.9-3.6-17.8-9.8c-11.7-12.3-31-10-47.1-13c-48.7-9.2-97.7-17.2-146.6-25.6
					c-8.8-1.5-13.4-4.9-14.5-15.3c-4.3-37.7-0.4-75.4-2.7-113.1c-1.5-24.3-0.1-48.8,0-73.2c5.2-15.3,0.8-31.1,2.5-46.7
					c0.5-4.2-0.6-7.9,4-9.8c5.8-2.3,6.4,2.3,8.2,6.1c5.9,12.2,16.8,23.1,8.4,38.4c-0.5,0.9-0.4,2.8,0.2,3.3
					c18,15.7,14.9,44.5,36.5,58c9.4,5.9,11.7,15.6,8.7,26.1c-3.5,12.3-5.1,23.6,0.5,36.7c6.5,15.1,20,24,32.8,13.9
					c12.3-9.7,13.4-1.2,16.1,6.3c8.5,23.4,10.8,23.4,29.4,7.6c16.4-14,34.9-25.5,54.9-34.2c8.1-3.5,7.2-10.9,5.8-18
					c-1-5-3.6-11.7,3.7-12.9c8.9-1.5,7.4,6.5,8.2,11.9c1.9,13.4-6.1,29.7,10.8,39C1661.4,2259.2,1697.7,2254.2,1705.2,2273.6
					L1705.2,2273.6z M1453.6,2179.2c1.6,6.1-2.8,16,7.9,16c8.1,0,6.2-8.2,6.2-13.6c0-6.6,1.8-16.1-7.4-16.6
					C1450.1,2164.4,1455.8,2174.4,1453.6,2179.2z"/>
				<path fill="#3D4037" d="M2039.2,2147.4c-2.8-22.7-8.4-45.1-6.4-68.2c0.8-8.9,1.4-18.3-12.8-16.2c-7.4,1.1-10.3-4-10.8-10.5
					c-0.4-6.3-0.2-12.7-0.5-19c-0.2-4-0.2-8.7-5.6-9.3c-5-0.5-6.7,3.6-8.3,7.4c-0.7,1.6-1.5,3.2-1.7,4.9
					c-2.2,18-14.4,23.1-30.3,23.4c-2.4-8.4-2.6-17.1-3.1-25.8c-1.8-28,3.5-55.6,3.6-83.5c0.1-63.4,0.2-126.8,0.3-190.2
					c0-4.6-0.2-9.4,0.8-13.9c1.1-5,5.6-7.7,8.1-2c4.7,10.8,12.2,5.9,19.1,5c3-0.4,5.3-2.4,5.6-5.7c0.7-9.5-10.7-5.1-13.1-11.2
					c2.1-5.6,6.4-6.3,11.2-5c13.4,3.6,12.6,17.1,18.6,27.3c3.8-17.7,12.1-32.4,22.1-46.4c4-5.7-2.3-6.1-5.3-8.2
					c-8.6-5.9-11.2-15.7-5.5-22.8c17.2-21.5,19.3-46.9,22-72.3c3.2-29.8-6-60.3,5.5-89.6c1.5-3.7-0.1-7.5-3.9-8.5
					c-9.6-2.6-14.9-11.4-21.1-17.2c-8.2-7.6-11.5-9.5-19.6-1.4c-4.4,4.4-13.4,7.2-14.4-2.6c-1.6-15.9-12.6-14-22.7-15.2
					c0.7-6.8,1.5-13.5,2.2-20.3c30,11.1,58.5,24.6,82.6,46.3c4.2,3.8,3,8.5,2.9,13c-1.4,62,1.5,124.5-5.5,185.9
					c-6.4,55.5-3.6,110.6-6.2,165.9C2042.6,1956.6,2040.4,2052,2039.2,2147.4z"/>
				<path fill="#4F5346" d="M1917.8,2144.5c-0.6,1-2,2.3-1.7,2.9c9.3,22.5-15.4,22.6-22.9,33.9c-2.6,3.8-11.1,1.9-16.6-0.4
					c-7.6-3.2-15-6.7-16,6.9c-7.1-4.5-6-10.5-8.2-15c-2.7-5.6-6.7-7.7-12.6-6.7c-25.7,4.6-36.8-2.2-44.4-27.5
					c-2.5-8.3-5.9-12.7-14.4-5.5c-5.7,4.8-12.5,8.5-13.6,17.1c-0.5,3.6-3,6.8-7.1,6c-4.2-0.8-4.3-4.5-4.5-8.2
					c-0.4-11.6-1.2-23.1-1.9-34.7c-0.2-2.8,0.2-6.4-3.3-7.3c-4.8-1.2-6.1,2.9-6.4,6.2c-1.2,10.6-12.4,17.1-11.2,28.7
					c0.5,5-1.3,9.5-8.1,8c-4.9-1.1-9.1-3.7-8.3-9.4c3.1-23.6-0.7-46-11.5-67.2c-1.5-3-1.1-5.6,2-7.4c14.6-8.3,9.6-17.9,1-26.6
					c-5.7-5.7-8.2-12-4.8-18.7c3.6-7.1,11-11.4,18.9-9.1c8,2.4,15.3,1.5,17.7-5.4c3.1-8.8-6.6-10.7-12.2-14.2
					c-2.4-1.5-5.3-2.3-7.9-3.5c-0.3-2.3-0.6-4.7-0.9-7c1.1-15-1.7-23.2-20.7-19.9c-22.8,3.9-31.3-5.3-31.4-28.2
					c-0.1-30.6-0.2-61.2-0.3-91.8c0.3-23,0.6-46.1,0.9-69.1c9.7,1.4,3.8,10.6,7.6,15.1c3.4,4-4.7,13.5,4.5,13.6
					c5.9,0.1,8.9-8,10.9-13.9c1.2-3.7,0.8-7.9,1.1-11.8c1,0,2,0.1,3,0.1c3.5-0.1,7-0.1,10.6-0.2c5.5,11.8-2.8,26.7,9,37.7
					c2.5,2.3-5.1,6.9-6.9,11.1c-2.6,6.1-4,13.3,0.9,18c5.2,5,8.9-1.3,13-4.3c12-8.7,15.7,2.4,18,9.6c2.6,8,1.5,16.3,11.4,21.3
					c5.7,2.9-2.4,27.7-10.1,31.6c-11.2,5.7-15.9,10.6,0.3,15.4c6.4,1.9,6.2,7.7,7.2,12.7c7,34.8,1.3,69.1-4.4,103.2
					c-1.7,10.1-5.6,19.8-0.5,31.6c14.6-11.2,12-27,14.7-41.1c8.2-42.3,14.4-85.2,28.8-126.1c1-2.7,2.7-5.7,2.3-8.2
					c-3.2-20,9.1-31.3,25-35.3c13.7-3.5,15.7-11.3,16.7-21.7c0.7-7.1,2.5-10.1,10.7-12.3c12.1-3.2,27.7-4.4,29.3-22.1
					c2.1,0.5,3.4,0.5,4,1.1c3.9,4.2-1.2,15.8,8.6,14.1c10.4-1.8,4.9-11,4.1-17.3c-1.3-11.2,1.9-20.8,9.9-28.5
					c3-2.9,7.3-8.6,11.6-4.2c5.1,5.3-0.7,9.1-4.6,12.5c-2.6,2.2-5.1,4.5-8.7,7.6c8.7,6.4,18.7,4.1,26.8,7.8
					c-0.5,74.2-1,148.4-1.5,222.6c-5.2,2.7-5.1,5.3,0,7.9c0.2,7,0.4,14.1,0.6,21.1c0.1,0.7,0.1,1.4,0.2,2.1
					c-3.8,5.7-3.2,12.2-3.2,18.5C1918.1,2088.9,1917.9,2116.7,1917.8,2144.5z"/>
				<path fill="#4F5346" d="M1971,1470.5c10.1,1.2,21.1-0.7,22.7,15.2c1,9.8,10,7,14.4,2.6c8.1-8.1,11.4-6.2,19.6,1.4
					c6.2,5.8,11.5,14.6,21.1,17.2c3.8,1,5.3,4.8,3.9,8.5c-11.5,29.3-2.4,59.8-5.5,89.6c-2.7,25.4-4.8,50.8-22,72.3
					c-5.7,7.1-3.1,16.9,5.5,22.8c3.1,2.1,9.3,2.6,5.3,8.2c-10,14-18.3,28.7-22.1,46.4c-6-10.2-5.2-23.6-18.6-27.3
					c-4.9-1.3-9.1-0.6-11.2,5c2.5,6.1,13.9,1.7,13.1,11.2c-0.3,3.3-2.6,5.4-5.6,5.7c-6.9,0.8-14.4,5.8-19.1-5c-2.5-5.7-7-3-8.1,2
					c-1,4.5-0.7,9.2-0.8,13.9c-0.1,63.4-0.2,126.8-0.3,190.2c-0.1,27.9-5.4,55.5-3.6,83.5c0.5,8.7,0.7,17.4,3.1,25.8
					c-3.7,9.3-2.6,19-3.1,28.6c-12.3,0-16.8-7.3-17.3-18.5c-0.4-10.9-5.7-16.9-17.1-16.4c0-1.1-0.1-2.2-0.1-3.3
					c3.1-3.4,3.2-6.8,0.3-10.4c0.1-6.8,0.3-13.7,0.4-20.5c13-3.1,16.5-12.7,16.3-24.4c-0.2-11-3.2-20.4-16.4-22.1
					c0.9-46.3,1.8-92.6,2.7-138.9c6.1-2.2,5.6-7.6,7-12.6c4.4-16.1,0.2-32.1-0.4-48.1c-0.9-25.3,4.7-50.6-0.3-75.9
					c1.8-7.4-7.1-14.4-4.5-19.4c13.7-26.3,5.9-54.6,9.5-81.8c7.5,7.2,12.8,14.7,10.1,26.4c-1.6,6.6,2.5,13.6,9.9,13.9
					c8.8,0.3,6.2-8,6.8-13.2C1972.9,1572.6,1969,1521.5,1971,1470.5z M2031.7,1508.6c-0.2-3.2-1.7-4.9-4.2-4.4c-1.2,0.2-2.9,2-3,3.2
					c-0.3,2.5,1.5,4.2,4,3.7C2029.8,1510.9,2030.9,1509.3,2031.7,1508.6z"/>
				<path fill="#252C24" d="M1928.7,1834c-0.9,46.3-1.8,92.6-2.8,138.9c0,15.5,0,31,0,46.5c-0.1,6.8-0.3,13.7-0.4,20.5
					c-1.5,0.2-2.9,0.4-4.4,0.5c-0.2-7-0.4-14.1-0.6-21.1c0-2.6,0-5.3,0-7.9c0.5-74.2,1-148.4,1.5-222.6c0.9-15.1,4.7-30.1,2.1-45.4
					c0.1-2.5,0.2-5,0.3-7.4c0.3-20.1,0.8-40.2,1-60.4c0.1-8,1.3-16.3-2.9-23.8c6.3-23.9,4.2-48.5,5.8-72.8
					c-0.1-4.6-0.3-9.1-0.4-13.7c0-2.5,0.1-4.9,0.1-7.4c0.3-23,0.7-46,1-69c12.6,9.3,3.3,23.4,7.4,34.5c-0.1,4.5-0.2,9.1-0.3,13.6
					c1.1,17.5-4.6,35.4,3.3,52.4c0.2,2.3,0.4,4.5,0.6,6.8c-3.6,27.1,4.2,55.4-9.5,81.8c-2.6,5,6.3,12,4.5,19.4
					c-8.6,3.7-6.5,11.5-6.6,18.1C1928.5,1755.1,1928.6,1794.6,1928.7,1834z"/>
				<path fill="#252C24" d="M1667.4,1771.5c-0.3,23-0.6,46.1-0.9,69.1c-8.6,3.5-6.6,11.2-6.7,17.8c-0.1,37.4-0.1,74.8-0.1,114
					c-4.9-4.7-2.8-9.4-3.8-13.2c0.3-50.8,0.9-101.6,0.6-152.4c-0.1-15.6,5.2-30.9,2-46.5c0.1-80.1,0.1-160.2,0.2-240.2
					c0.4-4.8,0.7-9.7,1.1-14.5c5.9,1.1,6.6,5.6,7,10.5c5.1,63.8,5.2,127.5,1.1,191.3c-1.1,16.5-3.9,33.5,2.3,49.9
					C1669.3,1762,1668.3,1766.8,1667.4,1771.5z"/>
				<path fill="#4F5346" d="M1778.4,1494.1c-11.8-21.3-30.5-10.7-45.9-10.6c-13.1,0.1-26.6,1.3-39.9,1.4
					c-11.1,0.1-21.2,2.3-31.8,6.6c-15.3,6.3-32.8,3.6-49.3,3.6c-25.6,0-53.4-3.7-75.8,5.7c-23.7,10-43.8,0.6-65.3,1.4
					c-2.2,0.1-4.7-0.2-6-5.3c17.6,0.7,32.1-12,51.3-6.9c10.2,2.7,22.3-1.7,33.3-2.9c27.3-2.9,55,2.5,81.3-2.4
					c21.6-4,43.7-2.9,65.3-9.7c22.3-7,46.9-5.9,70.5-2.7C1775.2,1473.5,1782.5,1480.4,1778.4,1494.1z"/>
				<path fill="#4F5346" d="M1775,1735.2c10.6,21.7,9.1,44,12.2,65.4c0.9,6.5-2.3,15.3-6.7,20.6c-7.2,8.8-10.7-2.5-16.1-5
					c-17.9-8.3-18.6-19.8-1.5-32.4c3.9-2.8,9.7-5.9,2.7-9.8c-14.7-8.2-9.2-26.3-19.3-36.4c-1.4-1.5-1.8-4.7,0.6-6.2
					c4.5-2.8,7.5,0.7,10.5,3.3C1763.4,1739.8,1768.2,1752.5,1775,1735.2z"/>
				<path fill="#4F5346" d="M1928.3,1579.1c-1.6,24.3,0.4,48.9-5.8,72.8c-25.2-2.6-25.6-3.4-25.4-27c0.1-10.9-1.6-21.8-1.4-32.7
					c0.1-5.4,0.8-13,7.7-13.9c9.7-1.3,3.4,9.2,7.9,12.8C1918.9,1589.9,1918.9,1577.4,1928.3,1579.1z"/>
				<path fill="#4F5346" d="M1936.4,1523.5c-4.1-11.1,5.2-25.2-7.4-34.5c0-9.5-2-13.9-14.3-12.7c-35.7,3.7-71.8-0.2-107.5,4.6
					c-3.5,0.5-9.6,2.1-10.2-3.3c-0.8-6.4,5.6-6.5,10-6.4c11,0,22,0.7,33,1c3.4,0.1,7.6,1.1,10.2-0.4c15.7-8.9,31.4-7.7,48-3
					c6.9,2,14.3,1,21.7-2.7c11-5.4,19.8-1.5,20.7,11.8C1941.5,1493.3,1949.7,1509.5,1936.4,1523.5z"/>
				<path fill="#4F5346" d="M1708.8,1732.8c0.3,7.3-0.7,14.7,3.8,21.6c5,7.7,0,11.4-7.1,13.4c-1.2-1.2-2.4-2.3-3.5-3.5
					c-0.4-8.2,6.5-21.2-11.7-17.8c-2.9,6.1,9.8,11.7,0.5,17.9c-5.9,0.3-9.7-3.4-13.4-7.3c-0.1-2.3,0-4.6-0.3-6.8
					c-2.7-19.5-2.7-19.8,16.6-16.2c5.4,1,10-0.9,15-1.5L1708.8,1732.8z"/>
				<path fill="#3D4037" d="M1677.3,1757.1c3.7,3.9,7.5,7.6,13.4,7.3c0.2,3.3,0.5,6.7,0.7,10c-0.3,4,0.1,8.2-1.1,11.8
					c-2,5.8-5,14-10.9,13.9c-9.2-0.1-1.1-9.6-4.5-13.6c-3.8-4.5,2.1-13.7-7.6-15.1c1-4.7,1.9-9.5,2.9-14.3
					C1672.6,1757.2,1674.9,1757.2,1677.3,1757.1z"/>
				<path fill="#4F5346" d="M1432.1,1612.4c0-20.4,0-40.9,0-59.5c8.7,17.9,1.1,38.9,5,59.1
					C1435.4,1612.1,1433.7,1612.2,1432.1,1612.4z"/>
				<path fill="#4F5346" d="M1806.1,1840.7c-5.7,1.1-6-2.5-6.2-6.1c-0.5-8.2,4.1-11.4,11.7-11.1c4.6,0.2,10.7,0,10.8,5.9
					C1822.5,1840.8,1810.3,1835.5,1806.1,1840.7z"/>
				<path fill="#4F5346" d="M1939.4,1589.5c-8-17-2.2-34.9-3.3-52.4C1948,1553.9,1942.8,1571.8,1939.4,1589.5z"/>
				<path fill="#4F5346" d="M1718.8,1695.6c-2.8,5.4,0.3,14.5-8,16.9c-3.8,1.1-8.5-4-6.5-7.8c3.2-5.9,1.7-14.8,8.3-17.6
					C1717.5,1685,1718.1,1691.2,1718.8,1695.6z"/>
				<path fill="#4F5346" d="M1432.8,1668.7c-3.7-10.4-4.8-16.8,0-24.7c5.2,6,10.9,10.6,19.6,9.4
					C1444.1,1655.4,1438.1,1659.6,1432.8,1668.7z"/>
				<path fill="#4F5346" d="M1872,1549.6c13.9-5.4,24.3-6.3,35.1,0C1896.7,1549.6,1886.4,1549.6,1872,1549.6z"/>
				<path fill="#4F5346" d="M1431.1,1531.5c-6.1-6.6-5.8-14-2.4-24.3C1435,1516.1,1432.5,1523.8,1431.1,1531.5z"/>
				<path fill="#4F5346" d="M1917.3,1498.2c0,13.1,0,26.2,0,39.3c-0.5,0-1,0-1.5,0c0-13.1,0-26.2,0-39.3
					C1916.3,1498.2,1916.8,1498.2,1917.3,1498.2z"/>
				<path fill="#4F5346" d="M1659.8,1505.6c-0.4,4.8-0.7,9.7-1.1,14.5c-8-5.4-7.5-11.1-0.7-17c0.1-0.1,0.9,0.6,1.1,1
					C1659.5,1504.5,1659.6,1505.1,1659.8,1505.6z"/>
				<path fill="#4F5346" d="M1708.6,1732.6c0.8-2.4,1.1-5.4,2.7-7.2c1.2-1.4,3.1,0.4,3,2.3c-0.2,3.3-2.9,4.2-5.5,5
					C1708.8,1732.8,1708.6,1732.6,1708.6,1732.6z"/>
				<path fill="#3D4037" d="M1701.9,1764.3c1.2,1.2,2.4,2.3,3.5,3.5c-0.1,2.2-0.3,4.5-0.4,6.7c-3.5,0.1-7,0.1-10.6,0.2
					C1696.9,1771.1,1699.4,1767.7,1701.9,1764.3z"/>
				<path fill="#4F5346" d="M1928,1558c0,2.5,0,4.9-0.1,7.4C1922.3,1562.9,1922.4,1560.4,1928,1558z"/>
				<path fill="#4F5346" d="M1924.5,1736c-0.1,2.5-0.2,5-0.3,7.4C1918.4,1740.7,1918.9,1738.2,1924.5,1736z"/>
				<path fill="#4F5346" d="M1677.1,1993.4c11.1,9,15.4,18.9,14.3,31.4c-1.8,21.1,1.3,42.1-4.5,63.6c-2.5,9.2-14.6,5.2-12.1,15
					c2.3,8.9,14.3,4.2,16.9,13.1c1.9,6.6,3.1,12.4-1.5,18c-5.5,6.7-12.3,4.5-19.3,3.3c-6.7-1.1-10.8-3.9-10.8-11.1
					c0-36-0.1-72.1,0.1-108.1C1660.2,2006.3,1674.5,2004.5,1677.1,1993.4z"/>
				<path fill="#252C24" d="M1705.3,2273.7c13.5-8.7,14.2-30.2,34-33.3c6.8-1.1,10.7-2.6,11.7,6.3c1.1,9.6,4,9.5,13.6,5.2
					c7.7-3.4,21.2,0.6,29.2,5.8c5.1,3.3-2.2,11.4-8.3,10.4c-18.8-3.3-29.2,10.7-42.1,19.1c-4.9,3.2-8.5,8-11.1-0.5
					C1727.8,2271.9,1717.3,2271.4,1705.3,2273.7L1705.3,2273.7z"/>
				<path fill="#252C24" d="M1925.3,2050.3c0,1.1,0,2.2,0.1,3.3c5.7,25.5-5.9,50.8-0.7,76.3c1.2,5.9-0.8,11.5-6.8,14.6
					c0.1-27.8,0.3-55.6,0.4-83.4c0-6.3-0.5-12.8,3.2-18.5C1922.7,2045.2,1924,2047.8,1925.3,2050.3z"/>
				<path fill="#4F5346" d="M1959.5,2102.9c-1,12.7-2,25.3-3.1,38c-19.3-5.5-12.6-22.5-13.4-34.6
					C1942.7,2099.9,1953.6,2103.7,1959.5,2102.9z"/>
				<path fill="#616552" d="M1614.5,1983.5c-0.2-1.7-0.6-3.4-0.7-5.1c-0.3-5.1-12-2.5-7.5-10.4c3.3-5.9,9.8-4.8,15.4-5.2
					C1625.5,1971.8,1633.8,1982.4,1614.5,1983.5z"/>
				<path fill="#252C24" d="M1750.2,2221.9c-0.3,5.1-2.1,9-7.9,9.2c-5.4,0.1-8.6-2.6-8.6-8.2c0-6.8,4.8-8.4,10.3-8.8
					C1750,2213.6,1750.2,2217.7,1750.2,2221.9z"/>
				<path fill="#616552" d="M1690.3,1988.1c-3.7,0.5-5.4-1.1-5.3-3.6c0.4-8.8,7.6-10.6,14.1-12.8c3.8-1.3,8.4,0.9,7.5,4.3
					C1704.6,1984.3,1693.4,1981.5,1690.3,1988.1z"/>
				<path fill="#252C24" d="M1708.9,1998.1c-3.6,2.8-7.4,4.4-12.3,1.8c3.7-3.6,8-1.5,11.9-2.2L1708.9,1998.1z"/>
				<path fill="#252C24" d="M1708.5,1997.7c0.6-2,0.8-4.4,3.7-4.5c0.4,0,1.1,0.7,1.1,1c0,2.9-2.4,3.1-4.4,3.8
					C1708.9,1998.1,1708.5,1997.7,1708.5,1997.7z"/>
				<path fill="#252C24" d="M1718.9,1980.5c0.3,2.3,0.6,4.7,0.9,7c-1.4-1.2-2.8-2.5-4.2-3.7c0.1-1.1,0.3-2.2,0.4-3.3
					C1717,1980.5,1717.9,1980.5,1718.9,1980.5z"/>
				<path fill="#616552" d="M1716,1980.4c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2-0.8-0.5-1.6-0.6-2.4C1715,1981.1,1715.6,1980.7,1716,1980.4
					z"/>
				<path fill="#3D4037" d="M1453.6,2179.2c2.2-4.9-3.5-14.8,6.6-14.2c9.2,0.6,7.4,10,7.4,16.6c0,5.4,1.9,13.6-6.2,13.6
					C1450.8,2195.2,1455.3,2185.4,1453.6,2179.2z"/>
				<path fill="#616552" d="M1701.9,1764.3c-2.5,3.4-4.9,6.9-7.4,10.3c-1-0.1-2-0.1-3-0.1c-0.2-3.4-0.5-6.7-0.7-10.1
					c9.3-6.2-3.4-11.8-0.5-17.9C1708.3,1743,1701.5,1756,1701.9,1764.3z"/>
				<path fill="#3D4037" d="M1925.3,2050.3c-1.3-2.6-2.5-5.1-3.8-7.7c-0.1-0.7-0.2-1.4-0.2-2.2c1.4-0.2,2.9-0.4,4.4-0.5
					C1928.5,2043.5,1928.4,2046.9,1925.3,2050.3z"/>
				<path fill="#3D4037" d="M1920.5,2011.4c0,2.6,0,5.3,0,7.9C1915.4,2016.7,1915.4,2014.1,1920.5,2011.4z"/>
				<path fill="#3D4037" d="M1928.7,1834c-0.1-39.5-0.3-78.9-0.2-118.4c0-6.6-2-14.4,6.6-18.1c5,25.3-0.7,50.6,0.3,75.9
					c0.6,16,4.7,31.9,0.4,48.1C1934.4,1826.5,1934.8,1831.8,1928.7,1834z"/>
				<path fill="#3D4037" d="M1926,2019.4c0-15.5,0-31,0-46.5c13.1,1.7,16.2,11,16.4,22.1C1942.5,2006.7,1939,2016.3,1926,2019.4z"/>
				<path fill="#3D4037" d="M2031.7,1508.6c-0.9,0.7-1.9,2.3-3.2,2.6c-2.5,0.4-4.3-1.2-4-3.7c0.2-1.2,1.8-3,3-3.2
					C2030,1503.7,2031.5,1505.3,2031.7,1508.6z"/>
			</g>
			<path clip-path="url(#SVGID_2_)" fill="#396B50" d="M-59.5,2320.2c39.9-54,57.5-124,47.8-190.4c6.8,56.3,4.4,113.7-7.1,169.2
				c21.6-22.2,37.3-50.1,45-80.1c-0.1,19.3-0.1,38.6-0.2,57.9c-6.1,4.9-4.9-12.1,2.7-9.9c7.6,2.2,10.4,11.5,16.9,16
				s17.8-6.4,10.3-8.8c-7.9,5.8,2.3,19.6,11.9,17.4c9.6-2.2,14.9-12.2,18.6-21.3c30.5-73.9,39.9-156.4,26.9-235.3
				c11,88.7,8.7,178.9-6.9,266.9c28.8-26,40.2-65.7,61.5-98.1c21.3-32.4,64.3-58.9,97.6-39c-53.4,13.4-91.4,71.3-82.6,125.6
				c27.6-38.3,84.4-52.1,126.5-30.7c2.7-10.1,6.1-21.2,15.2-26.3c9.1-5.1,24.1,2.7,20.8,12.6c28.9-53,47.6-111.5,55-171.4
				c6.5,63.5,0.1,128.3-18.7,189.3c20.1-21.9,40.2-43.7,60.4-65.6c-1.8,16.1,0.7,32.7,7.1,47.6c94.1-23.8,180.8-75.8,246.1-147.7
				c-29.9,38.8-60.9,80.2-66.6,128.8c27.5-4,55-8,82.5-12c119.8-17.3,173-41.2,181.9-25.6c9,15.9-34.2,59.8-39,56.5
				c-4.4-3,18.5-47.6,98.3-177c-2.6,38.5-5.1,77-7.7,115.5c7.5-10.1,15-20.1,22.5-30.2c27.9,7.5,57.7,8.1,85.9,2
				c-7.5,20.9-10,43.5-7.3,65.5c25.6-24.3,70.3-24.4,95.9-0.1c11.7,11.1,20.9,27,36.7,30c13.9,2.6,27.1-5.9,40.8-9.2
				c13.7-3.3,33,3.8,31.2,17.8c28-24.7,49.2-57.1,60.6-92.7c1.5,24.4,2.9,48.8,4.4,73.1c10.2-11.4,20.5-22.8,30.7-34.2
				c19.1,19.2,58.6,5.5,61.6-21.4c5.5,23.2,11,46.4,16.5,69.5c33.3-39.8,46.1-96,33.2-146.4c15.3,58.5,30.5,117,45.8,175.5
				c2.5,9.4,5.5,19.7,13.6,25c7.4,4.9,17.1,4.2,25.9,3.4c31.9-3,63.8-6.1,95.7-9.1c-1.7-31.2-3.3-62.5-5-93.7
				c13.1,22.1,22.5,46.5,27.5,71.7c16.4-19.8,32.8-39.6,49.2-59.4c11.8,27.3,17.9,57.1,17.7,86.8c57.2-39.6,101.8-97.1,126-162.4
				c11.2,34,22.4,68.1,33.6,102.1c4.1,12.3,8.8,25.6,19.6,32.8c15.2,10.1,35.3,4.2,53.5,4c18.2-0.2,40.8,13.9,34.4,31
				c14.9-5.2,31.5-5.1,46.3,0.2c35.8-76.7,61.9-157.9,77.5-241.1c-2.4,65.7-4.8,131.3-7.3,197c41.7-92.5,107.7-174,189.5-234
				c-32.6,29.1-65.8,59-86.6,97.3c-20.8,38.4-27.1,87.6-4.8,125.1c25.3-28.3,50.7-56.6,76-84.9c-0.9,19.7,3.1,39.7,11.5,57.6
				c19.6-7.7,39.1-15.5,58.7-23.2c20.7,15.2,53.4,10.1,68.6-10.6c7.5,11,15,22.1,22.5,33.1c21.1-13.9,35.8-37.1,39.5-62.2
				c-6,19.1-12,38.2-18,57.4c9.9-60.2,42.1-116.4,88.8-155.6c-13.8,56.8-27.6,113.6-41.4,170.4c28.5-24,56.9-48.1,85.4-72.1
				c-4.4,33.3,24.6,63.8,57.2,72.1c32.5,8.3,66.9-0.3,98.7-11c9.4-3.1,19.5-6.5,28.9-3.4c14.8,4.8,20.3,22.4,23.7,37.5
				c35.1,155.7,70.4,315.9,46.3,473.7c-3.2,20.9-8.4,43.4-24.8,56.9c-13.5,11.2-32,13.8-49.4,16c-295.4,37.3-590.9,74.6-886.3,111.9
				c-230.2,29.1-460.9,58.2-692.8,61.3c-280.3,3.8-559.7-30.4-838-64.5c-118.8-14.6-238-29.2-354.3-57.3
				c-30.1-7.3-61.2-16.1-83.9-37.1c-25.4-23.5-36.4-58.5-43.6-92.3c-35.2-166.1-0.7-345.9,93.6-487.2"/>

			{/* Ноутбук!!! */}
			<path clip-path="url(#SVGID_2_)" 
				style={{transition: '2s'}}			
				fill="#333333" 
				stroke="#999999" stroke-width="4" stroke-miterlimit="10" 
				d={noteBookXY+" l-390.6-151.3c-6.2-2.4-9.2-9.4-6.9-15.5l106.5-275c2.4-6.2,9.4-9.2,15.5-6.9l390.6,151.3c6.2,2.4,9.2,9.4,6.9,15.5l-106.5,275C777.9,2694.1,770.9,2697.2,764.7,2694.8z"}/>
			<path clip-path="url(#SVGID_2_)" fill="#006837" d="M-49.8,2584.5c15-64.9,20.3-132.1,15.6-198.6
				c55.7,56.2,98.6,125.1,124.3,199.9c20.8-35.5,35-74.9,41.6-115.5c4.4,18.2,8.7,36.4,13.1,54.6c11.5-19.8,19.7-41.4,24.3-63.8
				c10.9,13.4,21.9,26.7,32.8,40.1c24.7-66.9,41.6-136.6,50.1-207.4c28,75.6,46.8,154.7,55.8,234.8c17.1-20.6,34.2-41.2,51.2-61.8
				c3.2,14.1,6.3,28.1,9.5,42.2c11.8-7.1,29.3-1.7,34.9,10.9c5.3-9.2,11.3-19,21.3-22.6c10-3.5,23.8,4.2,21.6,14.5
				c68-93.6,154.6-173.6,253.2-234.1c-35.4,60.3-44.9,135.4-25.5,202.6c18.9-31.1,37.9-62.1,56.8-93.2c22.7,13.9,57.4-4.7,58.4-31.3
				c13,7.8,26,15.6,39,23.3c29.3-83.7,35.8-175.1,18.7-262.1c45.3,84.3,79.4,174.6,101.2,267.8c30-96.4,86.4-184.4,161.5-251.9
				c-36.9,92.2-52.5,192.9-45.3,292c4.8-14.7,9.5-29.4,14.3-44.2c3.8,13.1,7.6,26.2,11.4,39.4c23.3-23.7,68-20,87,7.2
				c17.7-28.8,29.5-61.2,34.6-94.7c3.5,33.4,7,66.8,10.5,100.3c26.8-84.2,32.3-175,16-261.8c22.8,91.2,28.7,186.6,17.4,279.9
				c15.4-31.1,30.7-62.2,46.1-93.3c-4.2,17.7-1,38.8,13.3,50c14.4,11.2,40.4,5.4,43.9-12.5c8.9,15,17.8,29.9,26.6,44.9
				c26.5-32.4,89.7-15.5,96.6,25.8c21.7-12.3,43.4-24.5,65.1-36.8c41.4-62.7,66.8-135.8,73.2-210.7c-5.4,78.9-5.8,158.2-1.1,237.2
				c10.9-18.5,21.9-37.1,32.8-55.6c0,13.5,0,27,0,40.5c29.9-40.7,65.3-77.5,104.8-108.9c-21.7,43.7-37.9,90.2-48.1,137.9
				c69.1-69.6,115.4-161.6,130.1-258.6c7.1,107.7,22,214.8,44.5,320.3c33.1-26.1,59.7-60.6,76.5-99.3c-3.7,19.6-3.1,39.9,1.5,59.2
				c14.4-18.5,28.8-37.1,43.2-55.6c3.9,13.4,7.8,26.9,11.7,40.3c33.7-61.8,63.6-125.6,89.5-191.1c-9.7,55.3-15.6,111.3-17.6,167.4
				c65.7-86.4,113.7-186.2,140-291.5c7.8,109.3,15.7,218.7,23.5,328c17.7-22.5,35.5-45.1,53.2-67.6c0.8,14.9,5.2,29.5,12.6,42.4
				c21.5-10.2,40.2-26.3,53.4-46.2c1.7,11.3,3.4,22.6,5,33.9c11.8-5.3,23.5-10.7,35.3-16c-16.2,23.3,27.2,46.3,52.4,33.1
				c25.2-13.1,43.1-40.9,71.2-45.2c-5.5,18.3,28.1,13.4,40.4-1.2c43.9-52.2,87.9-104.4,131.8-156.6c-39,58.6-63.7,126.7-71.4,196.6
				c16.8-23.2,34.4-46,52.7-68.1c6.2-7.6,14.2-15.8,24-14.7c9.7,1.1,13.5,18.5,3.8,19.6c33.9-54.3,61.7-112.5,82.7-173
				c-6.2,95.3-2.4,191.3,11.4,285.9c26.8-47,58.4-91.3,94.3-131.8c-17.7,21.5-26,50.5-22.4,78.1c9.3-9.6,18.6-19.2,28-28.8
				c23.1,97.3,25.9,199.4,8.2,297.8c30,42.9,59.8,97.4,40.3,128.8c-25.7,41.3-122.4,14.1-192,12c-203.5-6.3-321.5,53.7-476,96
				c-391.3,107.2-1010.9,100.1-1268-24c-33.3-16.1-140.7-72.8-256-40c-54.3,15.4-86,43.8-108,60c-214.2,157.7-693,33.5-724-120
				c-10.6-52.4,28-122.8-11.3-158c-22-19.7-53.3-15-57.9-32.8c-7.4-28.5,25.8-48.9,51.5-63.2c32.3-18.1,61.6-65.3,90.5-128.9"/>
			{/* Ноутбук!!! */ }
			{this.props.isCommutatorActivated&&
					
			< path clip-path="url(#SVGID_2_)"
				style={{ transition: '2s' }}
				fill="#333333"
				stroke="#999999" stroke-width="4" stroke-miterlimit="10"
				d={noteBookXY + " l-390.6-151.3c-6.2-2.4-9.2-9.4-6.9-15.5l106.5-275c2.4-6.2,9.4-9.2,15.5-6.9l390.6,151.3c6.2,2.4,9.2,9.4,6.9,15.5l-106.5,275C777.9,2694.1,770.9,2697.2,764.7,2694.8z"}/>
			
			}
			
			<path clip-path="url(#SVGID_2_)" fill="#095A34" d="M529.2,2711.2c28.2-61.3,56.4-122.7,84.6-184c3.2,34.3,6.4,68.5,9.6,102.8
				c36.1-38.1,67.6-80.7,93.5-126.4c-1.6,27.5,1.8,55.2,10.1,81.5c21.3-15.3,41.3-32.5,59.6-51.2c5,16.2,14.8,30.8,27.7,41.8
				c17.6-10.8,35.3-21.6,52.9-32.4c5.6,8.6,14,15.5,23.5,19.3c28.6-41.4,42.1-93,37.3-143.1c20.9,70,24.8,145,11.4,216.8
				c39.6-46.3,100.8-73.4,161.7-71.5c-43.2,27-79,65.8-102.5,111c33.5-10.8,65.1-27.8,92.4-50c3.9,12.4,7.9,24.9,11.8,37.3
				c12.5-9.2,25.1-18.5,37.6-27.7c3.3,9.1,6.6,18.3,9.9,27.4c21.9-13.4,42.1-29.6,59.9-48c2.6,11,5.3,22,7.9,33
				c15.1-8.1,28.7-18.9,40.1-31.6c1.9,9.1,3.9,18.1,5.8,27.2c37.6-16,69.7-44.5,90.1-79.9c-20,70-27.6,143.6-22.2,216.2
				c64-37.2,118.3-91,156.1-154.6c-9.8,23.5-14.8,49-14.8,74.5c15.2-10.9,30.5-21.8,45.7-32.7c-1.9,14.4,20.6,16.5,33.8,10.8
				c30.4-13.1,57.2-34.8,76.4-61.8c1.6,44.8,14.5,89.2,37.4,127.8c13.2-6.9,25.2-16.1,35.5-27c4.5,14.3,11.5,27.8,20.4,39.9
				c66.4-41.2,137.4-75,211.3-100.6c-23.4,24.6-39.3,56.2-45.2,89.6c23.5-6.5,46.9-13,70.4-19.4c-1.8,7.7-3.6,15.4-5.4,23.1
				c15.6-5.4,31.2-10.7,46.7-16.1c-10.4,20.7,9.9,49.2,32.9,46.1c7.6-1,15.9-4.6,22.4-0.6c4.5,2.7,6.4,8.2,7.9,13.2
				c4.7,15.7,8.3,31.7,11,47.9c0.8,5.1,1.4,10.9-2,14.8c-2.8,3.2-7.3,4-11.5,4.6c-130.5,19.6-261.5,39.3-393.5,39.1
				c-99-0.1-197.8-11.4-296.8-11.5c-140.7-0.1-283.8,22-420.8-10.4c-56.7-13.4-111.4-36.1-169.3-42.6c-72.9-8.2-146.6,9.7-219.9,5.6
				c-26.9-1.5-54.5-6.3-77.1-20.8c-34.7-22.2-52.6-64.2-55.8-105.2c-3.2-41,6.2-81.9,15.6-121.9c25.3,35.8,50.5,71.7,75.8,107.5
				c15.2-17.3,27.2-37.5,35.3-59.1"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M938,2626c6,0.9,11.1,6,12,12"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M1102.4,2617.8c-1.3-2.7-2.7-5.3-4-8C1099.7,2611.1,1101.1,2616.5,1102.4,2617.8
				z"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M2602.2,1857.6c5.3,2.3,12.1,0.9,16.1-3.3"/>
			<path clip-path="url(#SVGID_2_)" fill="none" d="M2546.4,1786.6c2.1-1.3,2,4.2,0,2.9"/>
			{/* лого IBM */}
			
				<g 
				opacity={(this.props.selectedDresses.hat && this.props.selectedDresses.shoes && this.props.selectedDresses.pants)?"0.46":"0"} 
				style={{
					transition: "2s",
					}}
				clip-path="url(#SVGID_2_)">
				<path fill="#1CB419" d="M499.6,2304.3c-2.4,0.8-4.8,1.5-7.2,2.3c-0.2,0.1-0.2,0.1-0.3-0.2c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1,0c4.7-1.5,9.3-3,14-4.4c0.2-0.1,0.3,0,0.4,0.1c0.2,0.2,0.4,0.5,0.6,0.8
					c0.1,0.2,0.1,0.2-0.1,0.3c-2.1,0.7-4.3,1.3-6.4,2C500.2,2304.2,499.9,2304.3,499.6,2304.3z"/>
				<path fill="#0C01FD" d="M523.7,2300.9c-0.1,0.3-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0.1
					c-2.4,0.8-4.8,1.5-7.3,2.3c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.3-0.4-0.4c0,0.2,0.1,0.4,0.2,0.6c0,0.1,0,0.1-0.1,0.2
					c-1.4,0.4-2.8,0.9-4.1,1.3c-0.1,0-0.1,0-0.2-0.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.2,0-0.2,0.1-0.3c1.5-0.5,3.1-1,4.6-1.5
					c1.1-0.4,2.2-0.7,3.4-1.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.4,0.4c0,0,0.1,0,0.1,0c0.1,0,0-0.1,0-0.1c0-0.2,0-0.4,0.1-0.6
					c0-0.1,0.1-0.2,0.2-0.2c2.2-0.7,4.4-1.4,6.6-2.1c0.5-0.1,0.9-0.3,1.4-0.4c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.5,0.2,0.8
					c0,0.1,0,0.2-0.1,0.2c-1.4,0.4-2.8,0.9-4.3,1.4c-0.1,0-0.2,0-0.2-0.1C523.8,2301.2,523.7,2301.1,523.7,2300.9z"/>
				<path fill="#1CB719" d="M495.5,2317c0.1,0,0.1-0.1,0.2-0.1c4.8-1.5,9.6-3.1,14.4-4.6c0.2-0.1,0.2-0.1,0.3,0.2
					c0,0.4,0,0.7-0.1,1.1c-0.1,0-0.2,0.1-0.3,0.1c-4.6,1.5-9.2,2.9-13.7,4.4c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.2,0-0.3-0.1
					C495.7,2317.7,495.6,2317.4,495.5,2317z"/>
				<path fill="#1CB419" d="M509.8,2315.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.6,0.7-1.4,1.1-2.2,1.4c-3.4,1.1-6.7,2.1-10.1,3.2
					c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3-0.1,0.5-0.2c4.2-1.3,8.4-2.7,12.6-4
					C509.6,2315.2,509.7,2315.2,509.8,2315.2z"/>
				<path fill="#1CB719" d="M501.7,2299.7c1.1-0.3,2.2-0.2,3.2,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.2,0.1c-4.4,1.4-8.8,2.8-13.1,4.2
					c-0.1,0-0.1,0-0.2,0c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.3,0.2-0.4c2.7-0.9,5.5-1.7,8.2-2.6
					C500.3,2300.1,501,2299.9,501.7,2299.7z"/>
				<path fill="#F90115" d="M485.2,2306.2c-1.7,0.5-3.4,1.1-5.1,1.6c-0.2,0.1-0.2,0-0.3-0.2c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3-0.1,0.4-0.1c3.3-1,6.6-2.1,9.8-3.1c0.2-0.1,0.2-0.1,0.3,0.2c0.1,0.3,0.2,0.6,0.3,0.9
					c0,0.1,0,0.2-0.1,0.2C488.7,2305.1,487,2305.7,485.2,2306.2z"/>
				<path fill="#F90115" d="M489.3,2319c1.7-0.5,3.4-1.1,5.1-1.6c0.2-0.1,0.2,0,0.3,0.1c0.1,0.3,0.2,0.6,0.3,0.9
					c0.1,0.2,0,0.2-0.2,0.3c-0.1,0-0.3,0.1-0.4,0.1c-3.3,1-6.5,2.1-9.8,3.1c-0.3,0.1-0.3,0.1-0.3-0.2c-0.1-0.3-0.2-0.6-0.3-0.8
					c-0.1-0.1,0-0.2,0.1-0.2C485.9,2320.1,487.6,2319.5,489.3,2319z"/>
				<path fill="#F90115" d="M485.6,2307.4c1.7-0.5,3.4-1.1,5.1-1.6c0.2-0.1,0.2,0,0.3,0.1c0.1,0.3,0.2,0.6,0.3,0.9
					c0.1,0.2,0,0.2-0.1,0.3c-1.2,0.4-2.5,0.8-3.7,1.2c-2.2,0.7-4.4,1.4-6.6,2.1c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c-0.1-0.2,0-0.2,0.1-0.3C482.2,2308.5,483.9,2307.9,485.6,2307.4z"/>
				<path fill="#F90115" d="M490.5,2322.7c-1.7,0.5-3.4,1.1-5.1,1.6c-0.2,0.1-0.2,0.1-0.3-0.2c-0.1-0.3-0.2-0.5-0.3-0.8
					c-0.1-0.2,0-0.2,0.2-0.3c1.1-0.3,2.1-0.7,3.2-1c2.4-0.7,4.7-1.5,7.1-2.3c0.2-0.1,0.2,0,0.3,0.2c0.1,0.3,0.2,0.6,0.3,0.8
					c0.1,0.2,0,0.2-0.1,0.2c-1.1,0.3-2.1,0.7-3.2,1C491.8,2322.3,491.1,2322.5,490.5,2322.7z"/>
				<path fill="#1CB419" d="M502.8,2310.9c-1.9,0.6-3.8,1.2-5.8,1.8c-0.2,0.1-0.2,0.1-0.3-0.2c-0.1-0.2-0.1-0.5-0.2-0.7
					c-0.1-0.2,0-0.2,0.2-0.3c2.5-0.8,5-1.6,7.4-2.4c1.1-0.3,2.1-0.7,3.2-1c0.1,0,0.2,0,0.3,0c0.4,0.2,0.7,0.4,1,0.6
					c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.2,0.1C506.6,2309.7,504.7,2310.3,502.8,2310.9z"/>
				<path fill="#1CB719" d="M501.7,2307.5c1.9-0.6,3.8-1.2,5.7-1.8c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.1,0.1c-0.1,0.5-0.3,0.9-0.6,1.3
					c-0.1,0.1-0.1,0.1-0.2,0.1c-2.9,0.9-5.8,1.8-8.7,2.8c-0.6,0.2-1.2,0.4-1.9,0.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.3-0.2-0.6-0.3-0.8
					c0-0.1,0-0.2,0.1-0.2c0.2-0.1,0.4-0.1,0.6-0.2C498.2,2308.6,499.9,2308,501.7,2307.5z"/>
				<path fill="#0C01F8" d="M511.8,2300.5c-1.5,0.5-2.9,0.9-4.4,1.4c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c2.7-0.9,5.5-1.7,8.2-2.6c0.1,0,0.1,0,0.2,0c0.3,0.3,0.6,0.6,0.9,0.9c0.1,0.1,0.1,0.1,0,0.1
					c0,0-0.1,0-0.1,0C514.7,2299.6,513.3,2300,511.8,2300.5z"/>
				<path fill="#0C01FD" d="M524.9,2296.4c-1.5,0.5-2.9,0.9-4.4,1.4c-0.2,0.1-0.2,0.1-0.2-0.2c0-0.3,0.1-0.7,0.1-1
					c0-0.1,0.1-0.2,0.2-0.3c0.5-0.1,0.9-0.3,1.3-0.4c2.3-0.7,4.6-1.4,6.8-2.2c0.2-0.1,0.3,0,0.3,0.2c0.1,0.3,0.2,0.6,0.3,0.8
					c0,0.1,0,0.2-0.1,0.2c-1.1,0.3-2.2,0.7-3.2,1C525.6,2296.1,525.3,2296.2,524.9,2296.4z"/>
				<path fill="#0C01FD" d="M520.6,2295c0-0.4,0.1-0.9,0.1-1.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.3-0.1
					c2.3-0.7,4.5-1.4,6.8-2.2c0.2-0.1,0.2,0,0.3,0.2c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0-0.1,0
					c-2.5,0.8-5,1.6-7.6,2.4C520.8,2295,520.7,2295,520.6,2295z"/>
				<path fill="#0C01FD" d="M514.4,2296.9c-0.1,0.1-0.1,0.1-0.2,0.1c-2.5,0.8-5,1.6-7.6,2.4c-0.2,0.1-0.2,0-0.3-0.1
					c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.1,0-0.2,0.1-0.2c1-0.3,2-0.6,2.9-0.9c1.3-0.4,2.7-0.9,4-1.3c0.2-0.1,0.3,0,0.4,0.1
					C513.9,2296.4,514.2,2296.7,514.4,2296.9z"/>
				<path fill="#0C01F8" d="M529.6,2307.6c-1.1,0.4-2.2,0.7-3.3,1.1c-0.1,0-0.2,0-0.2-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c2.2-0.7,4.4-1.4,6.6-2.1c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2-0.1,0.2
					C531.8,2306.9,530.7,2307.3,529.6,2307.6z"/>
				<path fill="#0C01F8" d="M514.4,2312.4c-1.1,0.3-2.1,0.7-3.2,1c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.1,0.1-0.2c2.2-0.7,4.4-1.4,6.6-2.1c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0,0.2-0.1,0.3
					C516.5,2311.8,515.4,2312.1,514.4,2312.4z"/>
				<path fill="#0C01F8" d="M515.1,2314.9c-1.1,0.3-2.1,0.7-3.2,1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c2.2-0.7,4.3-1.4,6.5-2.1c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0,0.2-0.1,0.3
					C517.3,2314.2,516.2,2314.6,515.1,2314.9z"/>
				<path fill="#0C01FD" d="M530.4,2310c-1.1,0.3-2.2,0.7-3.3,1c-0.2,0.1-0.2,0-0.3-0.2c-0.1-0.3-0.2-0.6-0.3-0.9
					c0-0.1,0-0.2,0.1-0.2c2.2-0.7,4.5-1.4,6.7-2.1c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2-0.1,0.2
					C532.6,2309.3,531.5,2309.7,530.4,2310z"/>
				<path fill="#0C01F8" d="M523.8,2299.1c-1.2,0.4-2.5,0.8-3.7,1.2c-0.2,0.1-0.2,0.1-0.2-0.1c0-0.3,0.1-0.6,0.1-0.9
					c0-0.1,0.1-0.2,0.2-0.2c2.3-0.7,4.6-1.5,6.9-2.2c0.2-0.1,0.2,0,0.3,0.1c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0,0.2-0.1,0.3
					C526.3,2298.3,525.1,2298.7,523.8,2299.1z"/>
				<path fill="#0C01FD" d="M514.3,2302.2c-1.2,0.4-2.5,0.8-3.7,1.2c-0.2,0-0.2,0-0.2-0.1c-0.1-0.3-0.2-0.5-0.2-0.8
					c0-0.1,0-0.2,0.1-0.2c0.3-0.1,0.5-0.2,0.8-0.3c2-0.6,4.1-1.3,6.1-1.9c0.2-0.1,0.3,0,0.4,0.1c0.2,0.2,0.4,0.4,0.6,0.6
					c0.1,0.1,0.1,0.2,0,0.2C516.8,2301.4,515.5,2301.8,514.3,2302.2z"/>
				<path fill="#0C01FD" d="M520,2303.9c1.1-0.3,2.2-0.7,3.3-1c0.1,0,0.2-0.1,0.2,0.1c0,0.3,0,0.6-0.1,0.9c0,0.1,0,0.2-0.2,0.2
					c-1.9,0.6-3.8,1.2-5.7,1.8c-0.2,0.1-0.3-0.1-0.4-0.2c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.1-0.1-0.2,0.1-0.2
					C517.8,2304.6,518.9,2304.2,520,2303.9z"/>
				<path fill="#F90115" d="M488.9,2317.8c-0.8,0.2-1.5,0.5-2.3,0.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.7-0.3-1
					c0-0.1,0-0.2,0.1-0.2c1.5-0.5,3-1,4.5-1.4c0.1,0,0.2,0,0.2,0.1c0.1,0.4,0.2,0.7,0.3,1.1c0,0.1,0,0.2-0.1,0.2
					C490.4,2317.3,489.6,2317.6,488.9,2317.8z"/>
				<path fill="#0C01F8" d="M527.2,2304.1c0.7-0.2,1.4-0.4,2.1-0.7c0.2-0.1,0.2,0,0.3,0.1c0.1,0.3,0.2,0.7,0.3,1
					c0.1,0.2,0,0.2-0.1,0.3c-1.4,0.4-2.8,0.9-4.2,1.3c-0.1,0-0.2,0-0.2-0.1c-0.1-0.4-0.2-0.7-0.3-1.1c0-0.1,0-0.2,0.1-0.2
					C525.8,2304.6,526.5,2304.3,527.2,2304.1z"/>
				<path fill="#0C01FD" d="M520.8,2306.1c0.8-0.2,1.6-0.5,2.3-0.7c0.2-0.1,0.2-0.1,0.2,0.1c0,0.4-0.1,0.8-0.1,1.2
					c0,0.1-0.1,0.2-0.2,0.2c-1.2,0.4-2.4,0.8-3.6,1.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.3-0.5-0.6-0.8-0.9c-0.1-0.1,0-0.2,0.1-0.2
					C519.2,2306.7,520,2306.4,520.8,2306.1z"/>
				<path fill="#0C01FD" d="M514.8,2309.6c-0.7,0.2-1.3,0.4-2,0.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.4-0.2-0.7-0.3-1.1
					c0-0.1,0-0.2,0.1-0.2c1.4-0.4,2.7-0.9,4.1-1.3c0.1,0,0.2,0,0.2,0.1c0.1,0.4,0.2,0.7,0.3,1.1c0,0.1,0,0.2-0.1,0.2
					C516.1,2309.1,515.5,2309.4,514.8,2309.6z"/>
				<path fill="#1CB719" d="M505.1,2303.9c0.7-0.2,1.5-0.5,2.2-0.7c0.3-0.1,0.3-0.1,0.3,0.2c0.1,0.2,0.1,0.5,0.1,0.7
					c0,0.1,0,0.2-0.2,0.2c-1.5,0.5-3,1-4.5,1.4c-0.1,0-0.2,0-0.2-0.1c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.2,0.1-0.2
					C503.5,2304.4,504.3,2304.1,505.1,2303.9z"/>
				<path fill="#1CB419" d="M507.6,2311.9c-0.7,0.2-1.5,0.5-2.2,0.7c-0.3,0.1-0.3,0.1-0.4-0.2c-0.1-0.2-0.1-0.5-0.2-0.7
					c0-0.1,0-0.2,0.1-0.2c1.5-0.5,3-1,4.6-1.4c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.1,0,0.2-0.1,0.2
					C509.1,2311.4,508.4,2311.6,507.6,2311.9z"/>
				<path fill="#0C01F8" d="M526.8,2303c-0.7,0.2-1.4,0.4-2,0.7c-0.2,0.1-0.3,0-0.3-0.2c-0.1-0.3-0.1-0.5-0.2-0.8
					c-0.1-0.2,0-0.2,0.1-0.3c1.3-0.4,2.7-0.8,4-1.3c0.5-0.1,0.4-0.1,0.5,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0,0.2-0.1,0.3
					C528.2,2302.6,527.5,2302.8,526.8,2303C526.8,2303,526.8,2303,526.8,2303z"/>
				<path fill="#F90115" d="M488.1,2315.3c-0.7,0.2-1.5,0.5-2.2,0.7c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.5-0.2-0.8
					c0-0.1,0-0.2,0.1-0.2c1.5-0.5,3-1,4.6-1.5c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2-0.1,0.2
					C489.6,2314.8,488.8,2315,488.1,2315.3z"/>
				<path fill="#F90115" d="M486.7,2310.9c-0.7,0.2-1.5,0.5-2.2,0.7c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.2-0.1-0.4-0.2-0.7
					c-0.1-0.2,0-0.2,0.1-0.3c0.8-0.3,1.6-0.5,2.5-0.8c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.5,0.2,0.8
					c0,0.1,0,0.2-0.1,0.2C488.2,2310.4,487.5,2310.7,486.7,2310.9z"/>
				<path fill="#F90115" d="M487.4,2313.1c-0.8,0.2-1.5,0.5-2.3,0.7c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2-0.1-0.5-0.2-0.7
					c0-0.1,0-0.2,0.1-0.2c1.5-0.5,3-1,4.5-1.4c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.2,0.5,0.3,0.8c0,0.1,0,0.2-0.1,0.2
					C488.9,2312.7,488.2,2312.9,487.4,2313.1z"/>
				<path fill="#0C01FD" d="M514,2307.1c-0.7,0.2-1.3,0.4-2,0.6c-0.1,0-0.2,0-0.2-0.1c-0.1-0.3-0.2-0.6-0.3-0.8c0-0.1,0-0.2,0.1-0.2
					c1.4-0.4,2.7-0.9,4.1-1.3c0.1,0,0.1,0,0.2,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.2-0.1,0.2
					C515.3,2306.6,514.7,2306.8,514,2307.1z"/>
				<path fill="#1CB719" d="M495,2307.1c0.7-0.2,1.5-0.5,2.2-0.7c0.6-0.2,1.2-0.4,1.8-0.5c0.1,0.4,0.2,0.8,0.4,1.1
					c-0.3,0.1-0.5,0.2-0.8,0.3c-1,0.3-2.1,0.7-3.1,1C495.3,2307.8,495.1,2307.5,495,2307.1z"/>
				<path fill="#1CB719" d="M497.2,2313.9c0.8-0.3,1.6-0.5,2.4-0.8c0.5-0.2,1-0.3,1.5-0.5c0.1,0.3,0.2,0.6,0.3,0.9
					c0,0.1,0,0.2-0.1,0.2c-1.2,0.4-2.4,0.8-3.6,1.1c-0.1,0-0.2,0-0.2-0.1C497.4,2314.5,497.3,2314.2,497.2,2313.9z"/>
				<path fill="#0C01F8" d="M521.6,2308.7c0.4-0.1,0.9-0.3,1.3-0.4c0.1,0,0.2-0.1,0.2,0.1c0,0.4,0,0.7-0.1,1.1c0,0.1,0,0.2-0.1,0.2
					c-0.6,0.2-1.1,0.4-1.7,0.5c-0.1,0-0.2,0-0.2-0.1c-0.2-0.3-0.5-0.6-0.7-0.9c-0.1-0.1-0.1-0.1,0.1-0.2
					C520.7,2309,521.2,2308.9,521.6,2308.7z"/>
				<path fill="#0C01F8" d="M522.8,2312.4c-0.3-0.3-0.5-0.6-0.8-0.9c-0.1-0.1-0.1-0.2,0.1-0.2c0.2-0.1,0.5-0.2,0.7-0.2
					c0.1,0,0.1,0,0.1,0.1c0,0.4,0,0.8-0.1,1.2C522.8,2312.3,522.8,2312.4,522.8,2312.4z"/>
				<path fill="#1CB419" d="M495.5,2317c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.2,0.3,0.1c0.1,0,0.1,0,0.2-0.1c4.6-1.5,9.2-2.9,13.7-4.4
					c0.1,0,0.2-0.1,0.3-0.1c0,0.1-0.1,0.1-0.2,0.1c-4.7,1.5-9.4,3-14.1,4.5c-0.2,0.1-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.6-0.3-0.9
					C495.5,2317.2,495.4,2317.1,495.5,2317z"/>
				<path fill="#1CB419" d="M501.7,2299.7c-0.7,0.2-1.4,0.5-2.1,0.7c-2.7,0.9-5.5,1.7-8.2,2.6c-0.3,0.1-0.3,0.1-0.2,0.4
					c0.1,0.3,0.2,0.6,0.3,0.9c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.3-0.2-0.6-0.3-1c0-0.1,0-0.2,0.1-0.2c0.1,0,0.2-0.1,0.4-0.1
					c3.2-1,6.4-2,9.7-3.1C501.3,2299.7,501.5,2299.7,501.7,2299.7z"/>
				<path fill="#1CB419" d="M497.2,2313.9c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0.1,0.2,0.2,0.1c1.2-0.4,2.4-0.8,3.6-1.1
					c0.1,0,0.2-0.1,0.1-0.2c-0.1-0.3-0.2-0.6-0.3-0.9c0.1,0,0.1,0.1,0.1,0.1c0.1,0.3,0.2,0.6,0.3,0.8c0,0.1,0,0.2-0.1,0.2
					c-0.4,0.1-0.9,0.3-1.3,0.4c-0.8,0.3-1.6,0.5-2.4,0.8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.3-0.2-0.5-0.3-0.8
					C497.2,2314,497.1,2314,497.2,2313.9z"/>
				<path fill="#1CB419" d="M495,2307.1c0.1,0.4,0.2,0.8,0.4,1.1c0,0,0,0,0,0C495.2,2307.8,495.1,2307.5,495,2307.1
					C495,2307.1,495,2307.1,495,2307.1z"/>
				<path fill="#1CB419" d="M499.3,2307c-0.1-0.4-0.2-0.8-0.4-1.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0.3,0.2,0.6,0.3,0.9
					C499.3,2306.9,499.4,2306.9,499.3,2307z"/>
			</g>
			
			<g opacity="0.38" clip-path="url(#SVGID_2_)">
				<path fill="#010100" d="M210.7,1587.4c-10.8,0.5-21.6,0.9-32.5,1.4c-0.5,0-1-0.1-1.5-0.3c-1.5-0.7-2.1-2.5-1.5-4.5
					c0.4-1.4,0.9-2.8,1.4-4.1c1.3-3.5,2.5-7,3.8-10.5c1.5-4.3,3.1-8.6,4.6-12.9c1.9-5.3,3.8-10.6,5.7-15.9c1.4-4,2.9-8.1,4.3-12.1
					c1.4-4,2.8-8,4.3-12c1.6-4.3,3.1-8.7,4.7-13c0.2-0.6,0.4-1.2,0.6-1.8c0.9-2.5,2.8-2.9,4.2-0.6c0.6,1,1.1,2.2,1.6,3.3
					c1.6,3.6,3.3,7.1,4.9,10.7c1.7,3.7,3.4,7.4,5.1,11.1c2.3,5,4.7,10.1,7,15.1c1.9,4.2,3.8,8.3,5.7,12.5c2.1,4.5,4.2,9,6.2,13.5
					c2,4.4,4.1,8.8,6.1,13.3c0.9,1.9,0.8,3.4-0.1,4.5c-0.6,0.7-1.3,0.9-2,0.9c-9.9,0.4-19.8,0.8-29.6,1.3
					C212.7,1587.3,211.7,1587.3,210.7,1587.4z M242.5,1581.4c-11.7-25.2-23.4-50.3-35.2-75.5c-9.6,26.1-19.2,52.2-28.8,78.3
					C199.9,1583.2,221.2,1582.3,242.5,1581.4z"/>
				<path fill="#FDC905" d="M242.5,1581.4c-21.4,0.9-42.6,1.8-64,2.7c9.6-26.1,19.2-52.1,28.8-78.3
					C219.1,1531.1,230.8,1556.2,242.5,1581.4z M213.5,1567.3c-0.6-0.1-1.1-0.2-1.6-0.3c-1-0.2-1-0.2-0.7-1.6
					c1.3-8.1,2.5-16.1,3.8-24.2c0-0.1,0.1-0.3-0.1-0.5c-3.8,3.3-7.6,6.6-11.5,10c0,0-0.1-0.1-0.1-0.1c2.6-9.9,5.3-19.8,7.9-29.9
					c-2,0.1-3.9,0.2-5.8,0.2c-0.3,0-0.3,0.2-0.4,0.5c-0.2,1.1-0.3,2.2-0.5,3.3c-1.7,10.6-3.4,21.1-5,31.7c-0.1,0.8-0.3,1.6-0.3,2.6
					c3-2.3,6-4.6,9-6.9c0.1,4.6,0.1,9.1,0.2,13.6c0,0.4-0.1,0.6-0.4,0.5c-0.6-0.1-1.1-0.2-1.7-0.4c-0.4-0.1-0.4,0.1-0.3,0.6
					c0.8,3.3,1.5,6.6,2.2,9.9c0.2,0.8,0.4,1.5,0.6,2.4C210.3,1574.9,211.9,1571.1,213.5,1567.3z"/>
				<path fill="#010100" d="M213.5,1567.3c-1.6,3.8-3.2,7.5-4.8,11.4c-0.2-0.9-0.4-1.6-0.6-2.4c-0.7-3.3-1.5-6.6-2.2-9.9
					c-0.1-0.5-0.1-0.7,0.3-0.6c0.6,0.2,1.1,0.2,1.7,0.4c0.3,0.1,0.4-0.1,0.4-0.5c-0.1-4.5-0.1-8.9-0.2-13.6c-3,2.3-6,4.6-9,6.9
					c0-1,0.2-1.8,0.3-2.6c1.7-10.6,3.4-21.1,5-31.7c0.2-1.1,0.4-2.2,0.5-3.3c0-0.3,0.1-0.5,0.4-0.5c1.9-0.1,3.8-0.2,5.8-0.2
					c-2.7,10-5.3,19.9-7.9,29.9c0,0,0.1,0.1,0.1,0.1c3.8-3.3,7.7-6.7,11.5-10c0.1,0.2,0.1,0.4,0.1,0.5c-1.3,8.1-2.5,16.1-3.8,24.2
					c-0.2,1.4-0.2,1.4,0.7,1.6C212.4,1567.1,212.9,1567.2,213.5,1567.3z"/>
			</g>
			<path clip-path="url(#SVGID_2_)" fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1820.1,2642.1
				c-0.1,14.6-4.2,28.5-10.1,42c-1.7-2.1-3.3-4.1-5-6.1c-4,4.1,0.1,6.8,2.6,9.7c-17.1,8.4-20.3,0.8-17.9-11.9
				c3.3-17.4,7.4-34.8,11.1-52.1c4.4,0.9,8.9,1.9,13.3,2.8c0,0,0,0,0,0c-0.3,1.3-0.6,2.6-0.8,3.8c0.6-0.9,1.1-1.8,1.7-2.7
				c5,1,6.9,3.7,5.7,7.9c-0.8,0.6-2.4,1.2-2.4,1.7C1818.6,2638.9,1819.5,2640.5,1820.1,2642.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1807.6,2687.8
				c-2.5-3-6.6-5.6-2.6-9.7c1.7,2,3.3,4.1,5,6.1c0,0-0.1,2.4-0.1,2.4s-2.8,0.5-2.8,0.5C1807.1,2687,1807.6,2687.8,1807.6,2687.8z"/>
			<path clip-path="url(#SVGID_2_)" fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1807.8,2686.7
				c0,3.1,0.1,6.3,0.1,9.4c-3.2-3.3-6.4-6.6-9.5-10C1801.5,2686.3,1804.7,2686.5,1807.8,2686.7z"/>
			<path clip-path="url(#SVGID_2_)" fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1793.3,775.3
				c-2.5-3.2-5-6.5-7.5-9.7c3.7,2.2,7.5,4.4,11.1,6.6C1795.3,773,1794.1,774,1793.3,775.3z"/>
			<path clip-path="url(#SVGID_2_)" fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1822.1,2612
				c2.3,2.1,4.7,4.1,7,6.2c0,0-0.2,0.1-0.2,0.1c-2.4-2-4.8-4.1-7.1-6.1L1822.1,2612z"/>
			<path clip-path="url(#SVGID_2_)" fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1821.8,2612.2
				c-0.9-0.5-1.9-1-2.8-1.6c1,0.4,2.1,0.9,3.2,1.3C1822.1,2612,1821.8,2612.2,1821.8,2612.2z"/>
			<path clip-path="url(#SVGID_2_)" fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1820.1,2642.1
				c-0.7-1.6-1.5-3.3-1.8-5c-0.1-0.5,1.5-1.1,2.4-1.7C1820.5,2637.7,1820.3,2639.9,1820.1,2642.1z"/>
			<path clip-path="url(#SVGID_2_)" fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1815,2627.6
				c-0.6,0.9-1.1,1.8-1.7,2.7c0.3-1.3,0.5-2.6,0.8-3.8C1814.1,2626.5,1815,2627.6,1815,2627.6z"/>
			<g clip-path="url(#SVGID_2_)">
				<path fill="#FBFDFD" d="M1974.1,1208.6c-0.1-0.2-0.2-0.5-0.3-0.4c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.3-0.1,0.7,0.3,0.6
					C1973.5,1209,1973.8,1208.7,1974.1,1208.6z"/>
				<path fill="#273738" d="M2039.1,1166.5c1.3-7.1,5.3-12.8,9.9-17.8c2.7-2.9,5.9-5.5,8.2-8.9c2.1-0.3,4.3-0.9,5.7,1.4
					c0.4,0.8,0.9,1.6,1.3,2.4c0.5,1.1,1,2.1,1.4,3.2c1.4,3.8,4.3,6.9,4.7,11c-4.1,5.2-9.3,8.9-15.7,10.8c-1.6,0.5-2.7,1.5-3.8,2.5
					c-7,5.8-16,9-21.4,16.8c-26,18.7-48.3,41.9-73.8,61.2c-0.8,0.6-1.4,1.7-2.7,1.4c0-1.2,0-2.4,0-3.6c11.5-8.7,22.3-18.2,33.3-27.6
					c11.9-10.1,24.2-20,34.7-31.6c4.5-4.9,9.7-9.5,13.1-15.5C2035.3,1170.2,2036.9,1168,2039.1,1166.5c0.7,2.2-0.7,3.5-2.1,5
					c-0.9,0.9-1.2,2.4-0.3,3.3c1.4,1.4,3.4,1.5,5.4,1.2c1-0.2,1.4-0.9,1.2-1.9C2042.7,1171.1,2042.3,1168,2039.1,1166.5z"/>
				<path fill="#98B8C3" d="M1995.5,997.5c0.4-0.4,0.8-0.7,1.2-1.1c1.6,4.5,5.6,3.1,8.7,3.7c3.9,0.2,7.4,2.6,11.4,2.5
					c-0.9,0.9-2.4,1.1-2.8,2.7c-0.8,3.6-2.8,2.4-5.4,1.8c-10.1-2.2-19.3-7.5-29.7-8.5c-4.4-0.4-8.5-2.7-12.7-4.2
					c-4.4-1.6-9.3-1.9-13.4-4.5c0-1,0-2,0-3c1.5-0.1,3.2-0.2,4.1,0.9c1.8,2.2,4.3,2.4,6.6,3.2c9.9,3.3,20.1,5,30.1,8
					C1995.5,999.6,1996.1,999.3,1995.5,997.5z"/>
				<path fill="#FBFDFD" d="M1952.9,1002.1c5.2,6.5,9.5,13.5,13.2,21c-4.6-0.1-9-0.9-13.2-2.9
					C1952.9,1014.2,1952.9,1008.1,1952.9,1002.1z"/>
				<path fill="#98B8C3" d="M1952.9,1165.1c7.3,2.9,13.1,7.8,18.1,13.8c3.3,3.9,6.2,8.1,6.9,13.5c-5.2-11.7-13.9-20-25-25.8
					C1952.9,1166.1,1952.9,1165.6,1952.9,1165.1z"/>
				<path fill="#98B8C3" d="M1952.9,1020.2c4.2,2,8.6,2.8,13.2,2.9c0.6,0.7,1.3,1.6,0.7,2.6c-0.7,1-1.7,0.6-2.7,0.3
					c-3.7-0.9-7.4-1.9-11.2-2.9C1952.9,1022.1,1952.9,1021.2,1952.9,1020.2z"/>
				<path fill="#98B8C3" d="M1952.9,1214.4c6.3-1.1,12.7-2,18.6-4.6c-5.5,4.4-12,5.3-18.6,6.1
					C1952.9,1215.4,1952.9,1214.9,1952.9,1214.4z"/>
				<path fill="#98B8C3" d="M1970.3,1032.1c0.2-1.4,0.9-1.4,2-1.1c7.5,2.1,15.1,3.6,22.4,6c5,1.6,10.1,3,15.1,4.5
					c0.7,0.2,1.3,0.6,1.7,1.2c-0.3,0-0.7,0.1-1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.2-0.2,0.5-0.4,0.7-0.6c4.2,0.4,7.8,2.9,12,3.6
					c1.7,0.3-0.5,1.2,0.2,1.6c-9.1-1.8-17.7-5.1-26.7-7.5c-7.5-2-15-4.3-22.5-6.5C1973.1,1033.4,1971.5,1033.3,1970.3,1032.1z"/>
				<path fill="#98B8C3" d="M2070.3,1157.9c-0.4-4.2-3.3-7.3-4.7-11c-0.4-1.1-1-2.2-1.4-3.2c3.3,1.3,4.8,4.6,7.7,6.4
					c4.2,2.6,8.5,4.8,13.4,5.9c1.4,0.3,2.8,0.9,4.1,1.4c0.5,0.2,1.1,0.6,0.9,1.3c-0.2,0.7-1.1,0.8-1.5,0.6c-3.1-1.7-6.7,1.6-9.7-1.2
					c-0.3-0.3-1.5-0.1-2.1,0.2C2074.7,1159.8,2072.5,1159.1,2070.3,1157.9z"/>
				<path fill="#98B8C3" d="M2062.9,1141.3c-1.4-2.3-3.6-1.7-5.7-1.4c1.6-1.7,3.9-1.1,5.7-1
					C2065.1,1139,2064.1,1140.4,2062.9,1141.3z"/>
				<path fill="#98B8C3" d="M2059.5,1241.1c0.2-0.1,0.5-0.1,0.7-0.2c1-0.6-0.2-3.1,1.8-2.6c1.8,0.5,1,2.3,0.9,3.7
					C2061.7,1241.8,2060.4,1242.2,2059.5,1241.1z"/>
				<path fill="#98B8C3" d="M2078.5,1279.8c0.3-1-0.7-3,1.3-2.7c1.9,0.3,1.3,1.9,0.7,3.1C2079.8,1280.2,2079.2,1280,2078.5,1279.8z"
					/>
				<path fill="#98B8C3" d="M2066.7,1254.2c0.2-1-0.8-2.9,1-2.8c2.1,0.2,1,2.2,1.1,3.5C2068.1,1254.9,2067.3,1255,2066.7,1254.2z"/>
				<path fill="#98B8C3" d="M2086.3,1172.2c-1.6,1.5-3.3,1.9-5.4,1.1C2082.6,1172.1,2084.2,1170.9,2086.3,1172.2z"/>
				<path fill="#98B8C3" d="M2087.8,1205.1c0.2-1.1,1-1.5,1.9-1.6C2090,1205,2089,1205.2,2087.8,1205.1z"/>
				<path fill="#98B8C3" d="M1974.1,1208.6c-0.3,0.2-0.6,0.4-0.9,0.5c-0.4,0-0.6-0.3-0.3-0.6c0.2-0.2,0.6-0.2,0.9-0.3
					C1973.8,1208.1,1973.9,1208.4,1974.1,1208.6z"/>
				<path fill="#FBFDFD" d="M2031.6,1042.6c-2.3,1.3-4.2-0.4-6.1-1c-7-1.9-13.7-4.9-20.9-6.3c-1.8-0.4-3.7-1.4-5.3,0.6
					c-1.2,0.3-2.3-0.4-3.4-0.7c0.2-0.5,0.4-1.1,0.7-1.6c7.5-1.8,15.2-1.4,22.8-2.3c1.8-0.2,3.6-0.4,5,1.2c1.2,2.9,3.1,5.3,5.3,7.5
					C2030.3,1040.8,2030.9,1041.7,2031.6,1042.6z"/>
				<path fill="#98B8C3" d="M2043.4,1011.5c4.4,0.2,8.4,2,12.5,3.6c-0.9,0.3-1.9-0.5-2.9,0.3c0.8,2.8,5.5,2,5.5,5.7
					c-3.8-1.3-6.7-4.1-10.6-4.7c-0.3,0-0.6-0.7-0.8-0.7C2041.7,1018,2043.7,1013.8,2043.4,1011.5z"/>
				<path fill="#98B8C3" d="M2020.3,1024.4c-1.3,1.7-2.1,0.3-2.7-0.5c-2.4-3.1-5-6-6.7-9.5c2.7-0.2,4.8,1.1,6.5,3l0-0.1
					C2017.3,1020.1,2021.1,1021.3,2020.3,1024.4z"/>
				<path fill="#98B8C3" d="M2029.6,1039.9c-2.2-2.2-4.1-4.6-5.3-7.5c2.6-0.6,5.6,2.6,5.5,5.7
					C2029.9,1038.7,2029.7,1039.3,2029.6,1039.9z"/>
				<path fill="#98B8C3" d="M2089,1024.6c1.3-0.1,2.6-0.3,3.2,1.2C2090.8,1026.2,2089.9,1025.5,2089,1024.6z"/>
				<path fill="#98B8C3" d="M1996.5,1033.5c-0.2,0.5-0.4,1.1-0.7,1.6c-0.9,0.1-1.9-0.1-1.7-1.1
					C1994.5,1032.9,1995.6,1033.1,1996.5,1033.5z"/>
				<path fill="#273738" d="M2020.3,1024.4c0.8-3.1-3.1-4.3-2.9-7.1c2.1,2.2,4.6,4.1,5.1,7.4c-0.5-0.1-1-0.1-1.4-0.2
					C2020.8,1024.4,2020.6,1024.4,2020.3,1024.4z"/>
				<path fill="#98B8C3" d="M2021.1,1024.5c0.5,0.1,1,0.1,1.4,0.2C2021.9,1025.5,2021.5,1025.2,2021.1,1024.5z"/>
				<path fill="#98B8C3" d="M2039.1,1166.5c3.2,1.5,3.6,4.6,4.2,7.5c0.2,1-0.2,1.7-1.2,1.9c-1.9,0.4-4,0.3-5.4-1.2
					c-0.9-0.9-0.6-2.3,0.3-3.3C2038.4,1170.1,2039.8,1168.8,2039.1,1166.5L2039.1,1166.5z"/>
				<path fill="#273738" d="M2060.9,1182.5c-0.9,2-2.6,2-5,2C2057.6,1182.8,2059.2,1182.5,2060.9,1182.5L2060.9,1182.5z"/>
				<path fill="#273738" d="M2011.6,1042.7c-0.2,0.2-0.5,0.4-0.7,0.6c-0.1-0.1-0.2-0.3-0.3-0.4
					C2011,1042.8,2011.3,1042.8,2011.6,1042.7L2011.6,1042.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1919.1,1192.7c0,12.9-1.8,26.2,0.7,38.5
					c1.8,8.9-4.8,9.4-6.4,14.3c-4.1-3.9-9.9-6.1-7.7-14.7c3.7-14.5-7.2-30.7,4.2-44.5C1914.7,1185.6,1913.7,1194.7,1919.1,1192.7z"
					/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1928,1168.8c-0.1,5.5-0.1,11.1-0.2,16.6
					c-2.2,0.1-4.4,0.2-6.6,0.3c-4.1-3.9-7.6-9.1-14.1-7.1c-4.8-0.7-10,1.6-17.7-4.6C1903.6,1168.2,1915.9,1169.3,1928,1168.8z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1907.1,1178.6c6.5-1.9,10,3.3,14.1,7.1
					c0,2.4,0,4.8-0.1,7.2l-1.1,0.6c0,0-0.9-0.8-0.9-0.8c-5.4,2-4.4-7.1-9.1-6.4C1909,1183.8,1908.1,1181.2,1907.1,1178.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1921.2,1193c0-2.4,0-4.8,0.1-7.2
					c2.2-0.1,4.4-0.2,6.6-0.3c0,0,0.3,0.4,0.3,0.4c1.2,2,2.5,3.9,3.7,5.9C1928.9,1198.3,1924.8,1193.8,1921.2,1193z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2350.2,73.4c5.3,5.3,4.7,11.4,2.1,16.9
					c-8,16.7-7.5,16.2,4.6,31.9c4.8,6.3,5.7,14.8,7.8,22.4c6.9,24.9,13.6,49.8,20.3,74.7c-2.6,5.3-5.2,10.5-7.8,15.8
					c-4.2,0.6-12.6-16-12.5,1.9c0,9.4,9.3,17.7,5.8,27.7c-2.4,0.8-4.7,1.5-7.1,2.3c-18.4,1.6-15.4,14.2-15.2,23.9
					c0.2,18.1,8.2,35.1,14.9,52.1c21.2,1,18.8,25.2,38,28.2c-7.8,5.2-14.5-0.3-21.6-2c-7.5-2.4-10.7,3.8-16.1,5.6
					c-31.6,29.8-18.7,60.4,0.3,91c1.9,1.6,3.8,3.2,5.7,4.8c0,10,0,19.9,0,29.9c-1.9-0.1-3.8-0.1-5.6-0.1
					c-27.5,25.7-13.6,55.1-8.3,83.8c-9.4-0.1-18.7-0.3-28.1-0.4c-2-0.1-4.1-0.2-6.1-0.3c-3.9-4.7-7.4-11.6-15.6-9
					c-7.8,2.4-1.8,8-1.2,12.4c0.9,6.8,0.7,13.6,0.9,20.4c-18.8,6.8-30.2-4.3-42.4-13.3c19.9-16.5,2.7-34.5,1.6-51.8
					c30-10.7,5.3-33.9,14.4-49.3c3.6-1.5,7.3-3,10.7-4.8c0.3-0.2-1.1-4.2-2.2-4.4c-7.9-1.7-12,2.8-15.7,7.5
					c-12.7,2.9-16.6,22.7-35.9,13c4.1-10.3,16.7-12.8,24.9-19.5c16.5-13.4,22.7-26.6-0.1-40.4c10.1-6.6,26,12.8,33.5-5
					c17.9-12.1,32.7-25.6,27.6-47.6c-0.8-12.3,24.7-12.9,17.8-25.4c-5.7-10.3-21.3-2.7-32.1-5.3c0-3.9,0-7.8,0.1-11.7
					c34.7-24.7,34.7-24.7,16.7-42.4c-16.2,28.3-16.3,28.3-53,33.3c-2.3,0.3-4.3,1.4-6.5,2.1c-2.4-3.7-4.9-7.5-7.3-11.2
					c50.2-1.1,24.6-28.6,22.1-48c28.1-15.2,12.2-35,6.5-53.8c1.1-4.5,10.2-10.7,6.9-10.8c-23.1-0.7-46.1-19.8-69.4-2.3
					c-4.5,4.8-9,9.7-13.5,14.5c-1.6,6.7,0.5,14.3-7,19.4c-0.5-3.7-1-7.4-1.5-11c-1.7-2.9,3.8-12.4-7.8-6c-2.3-0.2-4.7-0.6-7-1.1
					c-0.5-12.5-9.1-17.4-22.7-17.5c-8.5-0.1-16.5-0.9-18.4-9.9c13.5-2.8,35.7,9.9,39.7-7c6.6-27.7,5.9-56.8,8.2-85.2
					c-3.7-0.8-7.4-1.6-11.1-2.3c-8.8,34.2-48.8,53.8-51.1,91c2.2,8.2,4.4,16.5,6.6,24.7c-0.8,0.3-1.8,0.5-2.5,0.9
					c-0.4,0.3-0.3,1.1-0.5,1.7c1-0.8,2.1-1.7,3.1-2.5c15.2,10.3-1.4,20-0.2,30c-9.1,2.8-14.9,11.6-26.3,10.1
					c7.4-14.8,3.7-29.7,3.8-47.8c-59.9,28.1-101.3,71.5-160.7,92.2c-0.2-3.1-0.5-6.3-0.7-9.4c52.6-26.5,94.7-65.4,148.6-90.5
					c21.9-10.1,17.4-31.1,20.6-46.3c16.8-79.7,39.3-158.4,50.4-239.2c4-28.6,11.9-57.4,27.7-83.5c9.9,4.2,8,12.1,8.7,19.1
					c-16,43.8-34.7,87.4-9.8,139.7c15.8-36.7,6.9-71.7,18.1-103.6c4.4,6.4,8.8,12.8,13.2,19.2c-3.7,14.6-5.1,31.5,13.6,36.4
					c5.5,1.4,23.7-17.7,22.1-33.8c-0.3-2.9-1.2-5.8-1.8-8.7c4.9-6,9.7-12,14.6-17.9c3.4,37,6.7,73.9,10.1,110.9
					c-2.2,6-3.4,12.5-11.2,15.5c-3.6-8.3-5.1-18.1-11.4-24.6c-6.1-6.3-12.6-18.2-23.6-15.3c-11.5,3.1-23.5,11.2-23,23.7
					c1.5,45.1-20.3,90.1,2.9,135.2c-7,0.2-15.7-3.6-16.3,7.7c-1,18.4-3.5,36.7-5.1,52.6c9,7.7,11.9-9.5,20.8-0.4
					c18.5,13,36.5,4.8,59.6,0c-16.4-7.8-12.4-31.7-38.8-23.1c0.1-6.3,0.3-12.5,0.4-18.8c32-7.5,47.3-24.9,41.3-53.5
					c-5-23.8-5.3-47.7-5.2-71.6c7.8,0.3,16.3-1,21,6.7c-8.2,23.6,1.5,42.4,22.3,60.3c4.6-9.6,16.6-13.2,14-23.7
					C2341.9,73.3,2346,73.3,2350.2,73.4z M2342.6,343.7c-1.1,0.9-2.2,1.8-3.3,2.7c1.2-0.8,2.4-1.7,3.6-2.5c1-1,2-1.9,3-2.9
					C2344.8,341.9,2343.7,342.8,2342.6,343.7z M2292.6,547.4c16.6,8.9,22.5,4.3,29-23c14-5.9,26-12.8,20.8-28.9
					c16.9-22.2-11.8-26.2-21.9-37.4c-3.9-4.3-13.5-9.9-17.2-4.2c-7.5,11.6-5.7,24.1,6.7,34.6c2.9,2.5,3.3,7.2,4.9,10.9
					c-5.5,0.7-14.3-0.1-16,2.5c-3.4,5.5,3.5,8.3,9.1,9.1c8.5,1.2,12.1,5,12,12c-21.7-2.2-30.8,6-27.6,24.2c-2.9,2.1-9.1,3.3-2.8,7.9
					C2290.7,552.6,2291.7,550,2292.6,547.4z M2317,108.7c-22.5,34.6-10.5,63.8-6,90.6c2.3,13.7,31.3,12.3,46.8,10.4
					c13.1-1.6,1.9-14.1,0.4-22C2353.2,160.5,2323.4,143.6,2317,108.7z M2339.1,218.4c-9.4,2.2-22.3,2-22.5,14
					c-0.2,8.5,5.1,15.9,17.3,14.9c13.7-1.1,14.1-11.2,17.2-19.6C2354.9,217.1,2346.1,219,2339.1,218.4z M2308.5,295.2
					c-1.9-2.6-3.5-4.9-5.1-7.1c-1,1.7-2.8,3.4-2.7,5.1c0.2,2.8,1.7,5.5,2.6,8.2C2304.9,299.6,2306.5,297.6,2308.5,295.2z"/>
				<path fill="#606060" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2358.6,1003.6c49.6,29.6,99.3,59,148.7,88.8
					c10.8,6.5,23.1,5.4,34.2,4.7c21.6-1.5,39.4,6,53.6,17.4c10.8,8.6,21.1,10.9,34.3,11.2c7,0.2,19.1-4.6,19.3,6.6
					c0.2,9.1-10.4,8.6-18.8,8.7c-14.1,0.1-13.3,3.8-12.1,16.2c3,30.1,5.6,61.7-14.2,92.4c-28.8-32.8-19.5-63.4-4.3-92.5
					c9.8-18.7-6.5-13.7-15.7-16.2c6.1-14.4-4.5-23-16.9-33.1c-4.8,12.1-21.3,17.6-18.2,32.4c-5-0.1-10-0.1-14.9-0.2
					c0.5-9.5-7.8-17.4-10.4-27.5c-1.9,11.1-3.9,22.2-5.8,33.4l0.7-0.5c-6.3-0.6-12.5-1.2-18.8-1.8c-0.6-19.6-6-35.2-35.5-30.7
					c-7.1-1.5-14.2-2.9-21.3-4.4c0-3.6,0.1-7.1,0.1-10.7c2.9-3.1,20.3-0.3,9.3-7.5c-29.3-19.1-55.8-42.9-95.3-53.8
					c-0.1,17.1-0.1,31.9-0.1,46.7c-1.8-0.1-3.6-0.1-5.4-0.1c-0.2-12.6-0.3-25.1-0.8-37.7c-0.3-7,3.4-17-12.7-13.7
					c-5,4-9.1,8.3-8,14.7c-17.8-1-37-3.8-29.1,21.2c1.3,4.1,0.4,15.9-8.3,9.9c-18-12.4-10.8,2.4-11.1,6.4c-0.5,5.8,5,8.7,11.5,9.2
					c10.1,0.8,15-3.8,16.3-11.9c1.4-8.8,8.7-12.4,17.6-10c16.4,4.3,0,10.1,0.8,14.6c1.6,9.6,10.3,0.1,15.3,2.5
					c0.4,12.1,0.9,24.2,1.3,36.3c-4.7,0.1-9.3,0.2-14,0.4c-7-10.2-14.7-1.9-22.1-1.1c-20.8-28.8-26.4-29.5-63.2-7
					c-2.9,2.4-5.9,4.8-8.8,7.2c-9.7-1.5-19.6-4.2-27.6,3.2c-8.8,0.3-17.6,0.6-26.4,0.8c0.1-22.3,0.3-44.5,0.4-66.8
					c1.8,0,3.7,0.1,5.5,0.2c0.1,7-0.5,14,0.5,21c1.1,7.3-5.6,17.1,5.4,21.5c10.4,4.2,19.7-1,29.1-5.7c15.8-7.9-4.2-15.4,0.4-22.8
					c-3.1-2-6.1-4.1-9.2-6.1l-0.6,0.3c7.2-7.8-9.6-18.2,3.2-25.5c16.4,6.1,27.4,21.3,48.9,22.7c-1.9-18,9.8-37.5-6.9-49.3
					c-11-7.7-28.3,2.7-41.5,8.9c5.2-20.1-15.1-22.7-28.6-26.6c-34.7-10-21-18.7-1.4-28.6c19.2,13.7,34.2,6.3,38.4-9.8
					c5.6-21.4-22-19.7-35.1-31.5c-1.4,9.9-2.3,16.7-3.2,23.4c-20.1-4.5-38.4-10-29.1-33.1c8.1-20.2,22-30.9,48.6-22
					c6.8,0.3,7.8,4.8,11.1,8.8c11.8,14,22.4,31.8,49.1,23.7c4.4,0.1,8.9,0.2,13.3,0.3c7-2.1,11.9-5.9,14.6-11.8
					c23.8,2.4,7.7,21.8,18,30.3c11.7-8.1,18.8-17.7,18.3-30.7c1.5-1.3,3.1-2.5,4.6-3.8c2.4-0.4,4.9-0.7,7.3-1.1
					c-7.6,27.6-3.4,55.1,2.8,82.6c-6.8,2.4-17.2-1.1-22,14.6C2336,997.7,2350.5,1038.7,2358.6,1003.6z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1880.4,2252.6
					c5.8-59.5,32.6-114.9,43.3-173.5c6.1,1.3,12.3,2.7,18.4,4c-15.4,21.5,0.3,49.3-20.4,69.6c13.1,5.4,24.3,6.9,36,4.7
					c6.5,10.1,13.6,2.8,20.5,0.9c19.7-2.2,41.5-0.8,58.4-7.7c13.4-5.5,45.2-11.3,20.9-36.4c4.9-1.5,12.4-1.9,14.1-4.8
					c2.6-4.6-3.2-8.1-8.4-10.1c0-6.9,0.4-13.8,0.1-20.7c-1.8-37.6-23.4-71.9-27.9-109.1c0.5-9.4,2.3-19-7-26.7l-2.2-0.6
					c11.8-0.1,22.6,3.2,32.9,7.7c-3.8,4.6-12.1,7-9.7,14.4c9.2,28.9,18.6,57.8,28,86.7c8.7-40.2,22.5-78.3,13.7-118
					c5.7-0.2,11.3-0.3,17-0.5c-0.6,14.9-3.1,29.9-1.3,44.7c2.5,21.2-16.7,41.8-0.5,63.1c-0.3,11.8-0.5,23.7-0.8,35.5
					c-10.5-5.9,1.5-19.1-11.8-25.3c-2.4,19.5-4.7,37.9-8,65.1c11.5-14.3,17.1-21.2,22.6-28c14.4-4.2,20.6-22.6,41-16.5
					c-6,15.6-21.3,24.8-34.2,35.3c-11.4,9.2-18.8,17.5-4.9,29.5c-5.6,2-11.1,3.9-16.7,5.9c-0.9-0.7-1.7-1.5-2.7-1.9
					c-0.9-0.3-2.4-0.4-3,0c-6.6,4.8-8.7,11.9-8.2,18.6c2.9,41.2-12.3,81.9-4.8,124.3c10.1-7.3,17.5-12.8,24.8-18.2
					c18.7-9.9,38.6-18.5,55.5-30.2c12.3-8.5,35.4-9.1,34.1-29.3c3.1-0.5,6.2-1,9.3-1.5c2.4,6.8,4.9,13.7,7.3,20.5
					c-24.9,13.9-49.9,27.6-74.5,41.8c-5,2.9-14.5,4-11.7,12c2.7,8,10.8,5.7,17.4,5.3c18.4-1.3,36.8-3.2,55.3-4.1
					c8-0.4,20.5-4.1,16.6,10.3c-32.6,2.7-65.1,6.9-97.8,7.7c-21.6,0.5-29.2,7.7-27.1,24.9c-3.5-4.5-7.1-8.9-13.9-17.6
					c-5.2,48.8-10,93.3-14.7,137.8c-10.3-5.8-1.3-18.7-12.7-24.1c3.4-29.4,6.5-58.8,10.2-88.1c4.6-36.4-10.1-56.4-54.2-72.6
					c-5.3-3-10.6-6.1-15.9-9.1c-16.8-8.1-15.5,21.5-33.4,11.1c28.1-31.7,28.3-35.4-32.2-66.7c-12.2,18.6-5.9,41.1-16.2,60.1
					c-4.5-0.1-9.1-0.2-13.6-0.2C1884.7,2252.6,1882.6,2252.6,1880.4,2252.6z M1971.8,2182.2c0.9,2.6,1.9,5.2,2.8,7.8
					c0.9-0.5,1.7-1,2.6-1.5c-1.7-2.2-3.4-4.3-5.1-6.5c-3.6-4.9-6.4-10.8-15-9.6c-1.2,0.2-3.2,3.1-2.8,4.1
					C1957.6,2183.8,1965.6,2180.9,1971.8,2182.2z M2027.3,2230.3c-6.4-21.1,22.6-23.4,29.4-39.4c-8.1-1.1-15.4-3.8-21.3-2.5
					c-22.3,5.1-34.5,20.3-38.1,37.8c-3.5,17.2,17.6,3.3,23.9,10.2c-0.4,19.9,18.5,24,34.8,32.5c4.1-20.6,10.9-39,5.2-57.8
					C2042,2209.7,2048.3,2235.9,2027.3,2230.3z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2030.5,1581.8
					c10.9-45.2,20.2-90.7,33.1-135.4c26.8-93.1,35.6-187.7,31.5-283.6c0.5-1.8,0.7-3.7,0.6-5.6c12.2-2.4,19.2,0.7,22.5,12
					c3.6,12.7,9.8,24.8,15.3,38.2c6.4-5.9,6-15.1,17-14.5c0,0,2.3,1.8,2.3,1.8c-5.1,29.1-14.4,58.1-0.4,87.5
					c-1.3,12.1,8.4,21,13.5,31.3c-15.6,8.6,0.9,26.7-13.9,35.6c-2.6-1.3-5.2-2.8-7.9-3.9c-0.2-0.1-1.3,1.3-2,2
					c2.9,1,5.8,2.1,8.7,3.1c-1.3,8.5-2.7,16.9-4.3,27.4c7.3-3.8,13-6.8,18.7-9.8c2.3,9.4-5,23.2,15.7,24.2
					c4.8,12.4-1.5,24.1-2.5,36.1c-9.8,1.2-25.5-0.6-22.5,11.7c2.8,11.5,17.5,4.5,27.1,5c9.2-16.8,22.4-28.7,46.4-27.7
					c-13.6,15.4-29.4,28.8-48.9,38.8c-7.3,3.8-13.5,9.4-11.3,16.8c2.2,7.3,10.4,6.4,17.4,4.7c0,1.7,0,3.4-0.1,5.1
					c-0.1,10.2-0.2,20.4-0.2,30.6c-18.8-11.7-28-3.2-34.5,11.2c-9.7-4.5-6.5-19.9-22.4-19.2c-0.1-4.3-0.1-8.5-0.2-12.8
					c4.5-7.2,9.1-14.4,7.4-23.2c17.6-16.2,3-32.5-1.9-48.4c-1.8-5.8-1.1-16.7-10.6-16.9c-11.7-0.1-22,7.1-28.7,15
					c-8.5,9.9,6.2,12.9,10.8,18.9c-6.8,2.4-22.1-1.6-12.7,12.6c4.9,16.8,0.6,36.5,21.9,48.3c0,12.4-9.7,13.1-20.7,12.2
					c-3.6-9.4-7.1-18.8-11.3-29.7c-10.3,15.5-8.6,31.4-11.5,46.3c-3.6,3.3-7.1,6.7-10.7,10c-4.4,4.5-3.1,9.3-0.7,14.1
					c-12.6,8.2-8,20.1-8.3,30.9C2044.9,1582.3,2037.7,1582.1,2030.5,1581.8z M2135.3,1285.7c-0.5-1.7-1-3.3-1.4-4.9
					c-1.8,0.8-4.2,1.2-5.1,2.4c-0.7,0.9,0.7,2.8,1.1,4.3C2131.7,1286.9,2133.5,1286.3,2135.3,1285.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2206.2,898.4c-26.6-8.8-40.4,1.8-48.6,22
					c-9.3,23.1,9,28.6,29.1,33.1c0,5.9-0.1,11.9-0.1,17.8c-19.6,9.9-33.3,18.6,1.4,28.6c13.5,3.9,33.8,6.5,28.6,26.6l0.1-0.1
					c-8.5,1.8-20.9-5.6-25.6,6.7c7.4,6.7,16.1,4,23.8,5.2c0.7,1.9,1.1,3.9,1.2,5.9c-12.8,7.2,4,17.6-3.2,25.5
					c-9.6-0.2-14-12.8-25.6-8.1c-1.8-0.1-3.7-0.2-5.5-0.2c-0.3-7,1.8-14.8-1.3-20.8c-5.2-10-6.5-1.4-8.2,2.3
					c-20.5,1.9-17.8-20.3-38.7-24.9c-22.5-5-3.6,26.1-21.9,12.3c1,9.2,2.8,27.2,7.4,25.1c24.4-11.6,34.4,14.8,53.6,12.5
					c0.1,3.8,0.2,7.6,0.3,11.4c-23,4.6-20,20.5-20.4,34.9c-3.3,6.2,8,13.4-1.2,19.2c-10.2-0.7-30-14.1-20.6,11.1
					c-11.8-0.2-23.7-0.4-35.5-0.5c-2.2-20.3-23.1-13.2-35.8-18.1c-13.9-0.4-27.8-0.8-41.7-1.2c7.1-3.5,14.1-7,21.2-10.5
					c59.8-8.1,59.8-8.1,52.4-38.9c-26.3,2.2-39.1,21.8-59.1,32.5c-3.6-2.7-7.2-5.4-10.7-8.1c2.1-13.5,16.8-14.7,27.3-19.9
					c51.3-25.2,52.4-27.3,43-80.1c-11.2,5.5-22.1,10.7-32.9,16c-2.1-1.8-4.3-3.7-6.4-5.5c50.4-21.1,55.8-58.9,50.8-101.2
					c-1.6-13.6,0.8-27.6,1.4-41.4c6.7,0.1,13.4,0.3,20.1,0.4c9.3,4.4,18.3,9.2,27.9,12.9c4.3,1.7,9.1,9.7,13.8,2.5
					c2.1-3.2-1.8-9.2-3-13.9c9.2-0.3,19.9,10,27.5-2.3c10.7-0.2,11.8,4.4,7.2,10.9C2191.5,887.7,2199.8,892.7,2206.2,898.4z
					 M2149.5,999.5c-3.4-5.2-9.2-4.4-14.6-4.2c-5.3,0.2-9,3.1-6.1,7c1.8,2.4,7.7,3.5,11.8,3.7
					C2145.1,1006.2,2149.6,1004.7,2149.5,999.5z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2536.8,2428.4
					c23.4,17.3,29.5,36.7,11.6,59.1c-3.6,0.1-7.2,0.1-10.9,0.2c-2.6-0.1-5.2-0.1-7.7-0.2c5.9-17.5-0.7-29.9-19.4-39.9
					c-10.4-5.6-19.7-13.3-28.8-21.3c-27.7-24.5-66.3-38.8-88.8-68.5c-4.1-5.4-12-6.2-19.1-5c-5.6,0.9-7.4,6.1-4.3,8.9
					c13,11.3,0.2,27.7,12.2,38.9c-12.7,5-28.7,2-39.4,11.6c-0.4-6.1-0.8-12.2-1.2-18.3c23.5-8.6,8.1-23.6,7.8-35.9
					c-2.9,3.8-5.9,7.7-8.8,11.5c-2.1-3.6-6.8-8.8-9.7-4.6c-20.7,28.8-53.3,48.2-77.8,74.1c-40.3,42.4-79.8,86-131.6,119.3
					c-20.3,0.4-11,15.7-16.2,23.8c-4.8,3.9-9.6,7.8-14.4,11.7c-4.4,0.1-8.8,0.1-13.2,0.2c-15.9-14-26-29.5-19.5-49.5
					c1.7,0.2,3.4,0.4,5.1,0.5c1.1,0.4,2.2,0.9,3.3,1.3c-1-0.5-2-1.1-3-1.6c0.2-6.1-1.6-12.7,4.7-17.5c41.2,26.4,41.9,27,77.8-7.1
					c34.5-32.7,66.9-67,101-100c24.2-23.5,49.5-46.1,74.4-69.2c15.9-14.8,20.9-30.1,2.2-46.7c3.2-2.2,6.3-4.4,9.5-6.6
					c6.9,2,13.7,3.9,20.6,5.9c0.8,8.2-1.1,17.7,13.7,18.6c47.5,28.3,79.1,72.4,131.5,95.9c3,1.3,6.8,3.6,7.6,6
					C2512.8,2445,2525.1,2434.3,2536.8,2428.4z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2104.2,801.6c-1.2-18.2,3-40.1-33.9-24.1
					c-9.5,4.1-24.9-1.3-37.5-2.3c-6.8-27.9-19.2-56.7,15.5-78c18.8,5.1,32.3,26.8,56.8,13.8c0.8-0.5,2.5-1.2,2.4-1.4
					c-0.6-1.4-1.5-2.7-2.4-4c0-4.2-0.1-8.4-0.1-12.5c16.3-7.8-5.8-32.2,20.3-35.5c18.1-2.3,36.4-3.8,54.7-5.7
					c4.1,32.7,3.5,65.3-0.3,97.8c-20.8,8.1-25.6,20.2-12.4,31c6,8.7,14.9,21.1-0.2,24.6c-14.6,3.4-29.3-5.1-31.7-23.5
					c-1.5,13.8-7.4,22.1-23.8,19.7C2109,801.5,2106.6,801.5,2104.2,801.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1722.5,2735.4c4.6,0.1,9.5-0.7,13.8,0.3
					c32.2,7,46.7-2.6,46-31.4c-0.1-5.5-5.6-23.1,16.1-18.1c3.2,3.4,6.4,6.7,9.5,10c-0.3,6.9-1.6,13.9-0.6,20.7
					c1.5,9.5-20.5,21.6,4.2,28.4c20,5.6,40.5,12.2,52.8-10.8c4.1-7.6,9.3-15.9,20-19.2c2.7,2.3,5.5,4.7,8.2,7
					c-7.1,11.7-14.3,23.4-21.4,35.1c-10.8,6.3-13.7,14.4-6.9,24.5c-10.1,13.6-20,27.3-43.3,16.4c2.7-11.7,21.2-25.9-6.3-33.2
					c-26.2-7-27.3,11.4-30.5,24.9c-7.5,31.5,19.6,9.8,30,13.9c-19.2,22.4,11.4,21.5,20.4,30.7c-0.2,8.7-11.4,10.7-14.7,17.6
					c-7.6,4.3-12.9,9.8-12.7,18.2c-12.5-0.3-16.1,12-27.3,13.7c-1.9,0.3-3.9,0.5-5.8,0.8c1.7,0.9,3.4,1.8,5.1,2.6
					c0.5,1.9,1,3.8,1.7,5.7c-1,1.9-1.9,3.8-2.9,5.7c-19,7.3-28.1,30.8-55.8,23.8c0-4.2-0.1-8.4-0.1-12.6c10.9,2.1,12.6-4.6,14.5-11
					c11.2-14.2,11-38.4-0.5-48.5c-0.2-2.2-0.4-4.5-0.5-6.7c8.7-17.8-20.5-20.4-20.1-34.9c6.6-0.8,17.4,0.1,19.1-2.8
					c11.4-19.9,8.9-41,1.4-61.8c-0.5-1.4-8.7-0.9-13.4-1.2C1722.5,2740.4,1722.5,2737.9,1722.5,2735.4z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2537.4,2487.7c3.7-0.1,7.4-0.1,10.9-0.2
					c27.7,8.8,19,31.3,23.3,47.6c9.2,34.3,13.9,69.4,20.5,104.1c-7.1,3.9-14.3,7.8-21.4,11.7c-17.2-38.3-15.8-79.7-29.7-122.2
					c-12.5,31.8-21.7,61.4-36,89c-8.8,16.9-6.3,27.6,10.3,37.8c-6.2,2.4-7.3,7.1-7.4,12.2c-9.9,2.3-4.5,7.1-2.5,11.5
					c-14.3,5.9-28.4,12-43,17.5c-22.5,8.4,2.2,11.9,2.8,17.8c-5.4,7.4-8.3,15.3-6.9,24.2c-9.3-0.1-18.7-0.3-28-0.4
					c10-23.2,3.5-37.5-28.8-37.9c-18.3-7.6-18.6-21.1-13.1-34c3.8-8.9,8.4-22,26.4-12.9c-1.1,10.7-38.4,24.7,4.3,31.4
					c11,1.7,28.7,13.3,31.4-4.4c2.7-18.1,31.4-36.8,0.9-55.9c7.6-11.2,30.4,4.3,34.5-14.2c3.4-15.2-12.1-19.4-20.3-27.9
					c18-12.6,46.4-19.5,35.7-47.9c-1.3-4.6-2.5-9.2-3.8-13.9l0.5-0.9c9.1,2.6,17,10.2,31.1,4.6c-7.3-5.7-13.4-10.5-19.6-15.3
					l2.4-0.6c11.4,1.6,10.7-4.2,9-10.6l-0.3,0.1c8.2,3.9,16.5,7.8,28.1,13.2C2544.1,2501.4,2540.8,2494.5,2537.4,2487.7z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2048.3,697.2c-34.6,21.3-22.3,50.1-15.5,78
					c-4.5-0.3-9-0.6-13.5-1c-0.3-11.2-5.3-20.3-20.1-17c-27.1,6.1-22.8-8.8-22.4-21.9c4.4-0.1,8.9-0.2,13.3-0.2
					c1.2,8.7,7.8,11,16.7,9.9c10.7-1.3,11.1-7.6,9.4-14.8c1.5-10,4.5-22.4-15.3-13.4c-10.5,4.8-23.9,6-30.8,15.8
					c-23.4,22-62,23.1-86.1,44.1c-16.1,4.6-32.7,1.7-49.1,1.7c10.3-5.8,19.7-14,31.1-16.8c12.6-3.1,25.9-2.6,26-17.8
					c8.5-8.4,20.9-7.4,32.1-9.4c16.6-2.9,27.5-9.9,26.3-25.9c3,2.1,6,4.2,8.9,6.3c19.2,2.7,31.8-7.3,44.9-16.3
					c0.6-3.1,1.2-6.2,1.7-9.4c33.9,10.2,44.9-22,71.3-26.8c25.4-4.7,34.3-19,22.5-40.9c-29.4,10.8-57.9,21.4-86.5,31.9
					c-0.2-2.9-0.4-5.8-0.5-8.8c26.4-13.4,52.2-27.8,79.5-39.7c14.5-6.3,11.3-15.7,11.4-25.1c0.4-38.9,0.1-77.8,0.8-116.6
					c0.2-8.1-5.6-18.4,7.3-23.8c7.2,17.7,19.5-6.4,27.8,2.9c0.4,5.9,0.8,11.8,1.2,17.7c-12.9,8.1-20.9,16.8-4.6,28.7
					c-20.9,0.3-15.8,13.9-17,24.3c-12.1,22.2-14.1,46.6,0.4,66.8c8.7,12,11.8,15.2-0.9,24.9c-15.4,11.9,0.2,24,7.2,34
					c7.9,11.3,15.1,1.9,21.1-3.7c22.4-2.9,23.6-14.6,16.7-29.8c5-0.4,10-0.8,15.1-1.1c-1.3,7-2.1,13.7,10.4,12.6
					c10.8,12.3-9.4,18.1-7.6,28.5c-0.5,2.2-1,4.5-1.5,6.7c-18.2,1.9-36.5,3.4-54.7,5.7c-26.1,3.3-4,27.7-20.3,35.5
					c-1.1-8-2.3-15.9-3.4-23.9C2087.6,686.9,2062.3,684.7,2048.3,697.2z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2369.5,1438.7c1.8,9.7,3.6,19.4,5.4,29.1
					c-29.8,12.9-25.1,37.8-27.9,60.1c-1.6,1.4-3.2,2.7-4.9,4.1c-28.1,1.8-18.7,25.4-29.5,37.2c-13.4-0.4-8.8,6.2-6.2,12
					c-4.2,7.8-0.8,9.2,7.5,6.4l-0.4-0.8c-4.6,18.5,12.4,31.9,16.6,48.4c-3.1,0.1-6.2,0.2-9.2,0.3c-19.9,6.1-1.3,20.8-8.4,29.7
					c-23.8-11.4-11.8-30.3-12.5-45.5c-1-19.5-7.7-27.8-30.8-22.7c13.3-12.8,7.8-23.7-5.5-33.8c-1-4.4-1-9.7-8.1-10.4
					c-7.4-0.8-8.5,3.7-8.5,8.6c-12.4-3.5-8.2-10.1-4.7-16.6c4.9-4.1,9.8-8.1,14.7-12.2c4.8-2,9.5-4,14.3-5.9l-2.3,1.8l1.6,2.3
					c1.5,2.7,3.8,5.4,4.2,8.2c1.2,7.7-7.5,21.5,8.8,20.3c16.2-1.2,9-15,6.6-24.2c-0.7-2.8-3.3-5.3-5-7.9c-0.2-2.7-0.4-5.4-0.7-8.1
					c1-5.4,2.1-10.8,3.1-16.2c7.9-0.7,18.3,0.8,17.8-9.4c-0.5-9.8-12.4-10.8-20-14.9c-0.3-9.9-1.7-19.9-0.8-29.7
					c1.8-19.4-10.8-22.3-29-20.1c1.8-7.5,13-12.7,7.1-22.1c14.2-9.2,35.4-15.2,22.4-36.7c7.1-8-18.5-38.4,22.1-23.6
					c4,21.8,20.6,2.5,30,7.1c-3.1,9.9-6.3,19.8-9.4,29.8c-2.2,1.9-4.3,3.8-6.5,5.7c-41.9,6.6-12.8,23.3-6.4,36.8
					c-10.8-0.7-23.2-2.6-15,12.6c4.9,9,9.8,18.1,16.3,26.3c4,5.1,2.1,17,13.5,13.9c7.8-2.1,15.1-9.3,19.1-15.9
					c4.9-8.1-6.4-10.9-11.5-15.8c-7.5-7.2-27.1-5.3-22.7-21.4c17.3-11.5,17.2,8.6,26.8,11
					C2350.6,1437.8,2359.5,1445.4,2369.5,1438.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2439,1824.6c3.5,19.9,7,39.9,10.5,59.8
					c-19.4-5.7-26.4,4.3-31.1,17.3c-18.7,16.2-23.5,33.1-6.9,54.1c11.7,14.8,19.8,31.6,28.5,47.9c4.6,8.7,9.2-1.2,13.4,1.2l0,0.3
					c-8.4,20.9,27,38.5,7.8,60.2c-1.1-3.4-1.9-7.1-7.7-6.5l0.3,0.2c-18.1-42.3-41.1-82.7-70.2-120.6c-11.2-12.3-11.2-12.3-36,10.4
					c-6.9-0.1-13.8-0.3-20.6-0.4c0.3-5.1,0.7-10.3,1-15.4c45-17.7,45.9-19.5,27-56.2c0.7-0.6,2-1.3,1.9-1.6
					c-0.4-1.3-1.4-2.5-2.1-3.7c-9.8-13.6-11.4-30.7-25.8-42.2c-5.2-2.5-10.5-5-15.7-7.4c-0.2-7.9-0.3-15.8-0.5-23.7
					c5.1-6.4,15.6-9.6,15-19.3c29.6,18.4,59.3,36.8,89.7,55.7c4.1-17.9-15-29.4-16.2-45.2c17.3-3,22.8,5.6,24.1,18.1
					C2426.3,1815.2,2425.8,1823.8,2439,1824.6z M2330.9,1800c14.6,31.3,33.2,53.6,39.3,80c1.1,4.9,5.3,15,11.4,12.5
					c13.4-5.4,25-14.1,36.6-22.1c5.9-4.1,1.7-8.3-2.2-12.6C2395,1834.8,2369.7,1816.4,2330.9,1800z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1800.8,2623.8
					c12.9-62.4,26-124.9,38.8-187.3c4.4-21.4,8.2-42.8,12.2-64.3c7.3,5.1,5.5,12.3,6,18.9c0.6,9.7,6.4,8.2,13.2,4.8
					c5.1,17.4-23,35.6,2.9,52.6c-2.9,1.6-5.9,3.2-8.8,4.8c-10.4,40.8-20.3,81.8-31.8,122.3c-2,6.9,2.3,8.7,5.4,12
					c7.8,8.4,15.9,9.6,29.3,7.1c22-4.1,17.1-17.6,19.4-29.2c4.6,4.3,9.1,8.5,13.7,12.8c3.2,12-11.6,28.2,12,35.5
					c3,0.4,5.9,0.7,8.9,1.1c-0.4,1.5-1.2,2.8-2.4,4c-4.3,2.3-8.7,4.7-13.1,7c-3.8,0-7.7,0.1-11.5,0.1
					c-12.5-20.3-32.7-14.9-52.1-12.2c-4.6,1.5-9.1,3-13.7,4.5c-2.3-2.1-4.7-4.1-7-6.2c-1.1-0.4-2.1-0.9-3.2-1.3
					c0.9,0.5,1.9,1,2.8,1.6c2.4,2,4.8,4.1,7.1,6.1c-0.3,3-0.6,6.1-1,9.1c-4.6-0.3-9.2-0.6-13.9-0.9
					C1809.7,2625.6,1805.2,2624.7,1800.8,2623.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2370.2,625.3
					c60.3,22.1,119.4,46.4,176.1,74.7c-7,2.3-10.2,6.9-10.5,13.1c-29.8,21.3-28.7,35.6,3.9,47.2c-53.9-0.3-56.6-2.8-39.7-35.7
					c5.8-7,12.3-14.5,4.9-22.9c-10.2-11.7-22.2,6.5-33.3-1c9.4-15-6.4-16.7-16.1-20c-13.7-4.7-10.1,5.2-12,11.3
					c-6.3,20.4,16.3,7.6,21.8,14.2c8.6,10-13.4,13.1-6.6,22.7c-0.3,4-0.6,7.9-0.8,11.9c-18.8,0.4-37.6,0.8-56.4,1.2
					c0-3.8-0.1-7.6-0.1-11.4c49.3-4.8,22.4-35,29.4-59.9c-25,11.2-44.2,19.8-63.3,28.3c-2.6-0.3-5.3-0.7-7.9-1
					c0.1-3.9,0.3-7.7,0.4-11.6c2.4,0,4.7-0.1,7-0.5c15.5-6.7,32.9-10.7,43.8-23.5c6.7-17.3-9.7-9.4-17.1-11.4
					c-9.2-2.3-18.4-4.5-27.6-6.8c-2.4-3.6-4.8-7.3-7.2-10.9C2362.6,630.7,2366.4,628,2370.2,625.3z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2108,1932.5c-5.7,0.2-11.3,0.3-17,0.5
					c-10.6,5.6-21.3,11.3-31.9,16.9c-10.4-4.6-21.1-7.8-32.9-7.7c0.4-5.2,3.7-8,9.8-8.4c11.1-0.2,64.4-43.3,64.1-52.3
					c-0.1-2.8-1.2-6.7-3.4-8.1c-27.3-17.1-53.6-36-88.5-43.2c-2,29.8,23.7,53.7,14.8,81.7c-11.9,4.8-7.8-3.9-9.3-7.9
					c1.2-6,1.1-11.7-5.6-15.8c-6.8-8.8-8.7-18.6-7.8-28.8c-3.1-11.7-9.1-20.8-25.6-20.1c5-23.9,9.9-47.8,14.9-71.6
					c8.6-0.7,17.3-1.4,25.9-2.2c6.4-4.2,9.7-13.9,21.5-8.6c0.3,4.6,0.5,9.1,0.8,13.7c-4.9,3.9-9.7,7.9-14.7,11.7
					c-27.3,20.5-26.8,25.9,6,41.6c17.2,8.2,35.2,15.4,52.6,23.4c8.3,3.8,12.5,2.9,12.9-5.5c8.6,3.7,10.8-12.4,20.8-4.5
					c2,11.8-8.5,24.9,5.8,35.4c0,6.1,0,12.3,0,18.4c-21.8,10.3-15,25.1-7.6,39.9C2111.8,1931.4,2109.9,1931.9,2108,1932.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2535.8,713.2c0.3-6.3,3.5-10.8,10.5-13.1
					c20,8.3,36.1,23.2,59.5,25.5c15.8,12.7,37.4,9,55.5,15.2l1,0.4c0.2,7.6,0.3,15.2,0.5,22.8c-40.5,7.6-20.4,37.8-25.6,57.2
					c-2.6,9.9-5.2,15.7-13.9,20.7c-0.9-7.5-16.8-5.5-10.4-21.7c12.7-32.3-3.3-49.9-34.7-53c2.2-4.3,4.2-10.9,11.6-6
					c18.4,12.2,31.9,0,47.5-5.7c-36.2,0.7-69.7-17.8-104.9-8.1C2523,733.3,2558,726.8,2535.8,713.2z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2342.5,914.1c-2.4,0.4-4.9,0.7-7.3,1.1
					c-2.2,0.7-3.7,2-4.6,3.8c0.5,13-6.6,22.6-18.3,30.7c-10.3-8.5,5.7-27.9-18-30.3c-1.7-10.8-2.5-21.5,7.6-30
					c15,16.2,23.6,1.3,30.7-5.6c11.7-11.3-7.9-9.9-11.2-15.5c0.2-3.9-0.4-6.7-5.7-3.2c-5.2-3.1-10.4-6.2-15.5-9.3
					c0.4-6.7,0.7-13.5,1.1-20.2c26-6.4,26.5-35.7,52.5-42.8c0.8-0.2,7.5-16-9.2-16.7c-0.1-2-0.2-3.9-0.2-5.9c0-2.1,0-4.2,0.1-6.3
					l0.1,0c7.3,0.3,14.6,0.5,21.9,0.8c0,2,0,4,0,5.9c-15.8,75.4-4,152.2-9.6,228.1c-2.5-0.5-5.1-0.9-7.6-1.4
					c0.1-33.3,0.2-66.6,0.3-100c-1.4-0.1-2.7-0.2-4.1-0.2C2344.4,902.9,2343.5,908.5,2342.5,914.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2397,382.4c-19.2-3-16.8-27.1-38-28.1
					c0.1-25.3,0.2-50.7,0.3-76c2.4-0.8,4.7-1.5,7.1-2.3c0,0,1.5,2.3,1.5,2.3c0,12,1.7,24.2-0.3,35.8c-3.8,22.5,5.7,30.9,32.5,28.1
					c24.3-2.6,48.8-4.4,73.5-0.8c5,14.5,16,18,31.7,13.1c6.9-2.2,15.4-7.6,17.2,4.7c2.3,5.7,4.7,11.5,7,17.2
					C2485.4,378.4,2441.2,380.4,2397,382.4z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2662.9,763.9c-0.2-7.6-0.3-15.2-0.5-22.8
					c17.2,5.8,34.8,2.3,52.3,2.9c10,0.3,23.2-2.3,24.9,10.2c1.6,11.8-10.1,10.2-19.5,10.8c-9.6,0.6-14.8,4.1-9.6,13.8
					c12.1,22.5,0.5,46,4.8,69c2,10.8-14.2,13.1-14.6,22.8c0,0.2,0.5,0.5,0.7,0.7c-5.6-7.6-15.4-15.1-16.1-22.9
					c-1.7-19.7-3-40.4,2.4-59.2C2693.8,767.4,2685.1,762.5,2662.9,763.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2489,2089.9c0.5,3.5,0.9,6.9,1.4,10.4
					c-0.9,0.8-1.7,1.7-2.6,2.5c-2.1,1.4-4.2,2.9-6.3,4.3c-3.1,10.8,5.2,24-8.9,32.4c-7.3,10.7-15.1,21.2-21.8,32.2
					c-7.1,11.8-23.5,22.9-4.9,37.7c-0.3,8.6-1.1,17.4,13.6,17.9c2.6,2.1,5.2,4.2,7.7,6.3l0.2-0.1c3.1,17.4-2.2,29.2-26.3,28.1
					c1.8-30.3-23.4-12.8-37.1-15.7c-0.5-8.3-5.3-13.2-15.3-14c0.3-1.8,0.5-3.7,0.5-5.5c14.9-11.5,38.6-20.7,14.3-42.3
					c0.2-3.8,0.3-7.6,0.5-11.5c4.5,0,9.1-0.1,13.6-0.1l0.1,0.1c21.8-1.2,35.2-27,28.3-53.9c4.9-10.9,25-13.7,21.1-29.2
					C2474.4,2089.7,2481.7,2089.8,2489,2089.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2304.3-117.8c4.9-3.3,9.7-6.5,14.6-9.8
					c13.8,71.6,3.4,143.4,8.7,215.1c-4.1-0.6-8.2-1.2-12.3-1.7c-8.5-14.3-7.7-33.4-29.7-40.6c-3.4-8.2-12-7.9-19.7-9.2
					c0.2-4,0.3-8,0.5-11.9c8.3-2,10.5-8.3,13.7-13.9c19.6,11.3,5.8-17.7,20.6-12.1c5,15.6-16.4,35.1,15.7,49.5
					c-13.3-17.1,13.9-37.4-15.8-49.4C2311.9-40.1,2311.6-78.8,2304.3-117.8z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1954.1,1123.5c-0.1-2.9-0.3-5.8-0.4-8.7
					c23.8-1.4,48.2-1.1,67.8-15.2c3.6,2.7,7.2,5.4,10.7,8.1c2.2,2.1,4.5,4.3,6.7,6.4c-7.1,3.5-14.1,7-21.2,10.5
					c-3.6,4.5-14.3,1.4-15,9.9c12.7,2.6,25.2,5.3,37.7,7.5c15.8,2.8,22.3-2.7,19-16.2c12.7,5,33.6-2.1,35.8,18.1
					c0.2,4.5,0.3,9,0.5,13.4c0.1,1.9-0.1,3.7-0.6,5.6c-42.7-5.3-84.6,7.8-127.3,1.7c-2.2-9.8-4.4-19.5-6.6-29.3l-0.3,0.3
					c6.8-0.3,17.1-1.6,15.4-7.1c-2.7-8.9-14.5-3.6-22.3-5.2L1954.1,1123.5z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2441.2,2261.6c24,1,29.3-10.7,26.3-28.1
					c20.2,2,29.9,7.7,13.7,24.4c-2.2,2.3,0,7.6,0.1,11.5c-5.1,2.7-11,6.5-7.2,11.6c17.6,23.2,13.2,52.1,30.4,75.9
					c8.4,2,5.6-20.5,20.3-7.5c8,21.8,15.3,43.7,12.5,66.7c-14.8,4.6-20.4-2.9-26.2-13c-14.5-25.2-27.2-50.6-33.1-78.5
					c-10.8-50.9-30.3-59.1-89.9-40.6c-9.2-2.4-14.3-6.8-10.5-15.4c9.6,1.4,17.5-2.3,25.4-5.9
					C2415.7,2262.3,2428.5,2262,2441.2,2261.6z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1984.7,347.6c38.8-26.1,78-51.7,122.6-70.7
					c11.4,1.5,17.2-7.3,26.3-10.1c0,0,0,0,0,0c5.3,18.2,1,38.9,19.8,53.8c-6.6,0.4-4,16-20.9,4c-16.2-11.5-8.7-22.3-13.7-32.8
					c-1.8-3.7-4.6-0.8-5.6,2c-0.3,0.9-0.7,1.9-1,2.8c-38.4,3.9-61,29.5-89.7,46.6c-11.5,27-9.5,28.8,33,30.3
					c-0.3,1.6-0.4,3.3-0.4,4.9c-25.9,2.5-51.8,1.6-77.7-1C1971.8,365.9,1976.9,356.5,1984.7,347.6z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2253.1,342.3c2.4,3.7,4.9,7.5,7.3,11.2
					c-4.1,19.2-22.6,8.7-34.6,11.7c-9.8-0.4-20.1-1.7-22.3,9.9c-0.6,4.2-1.2,8.5-1.8,12.7c-3.6,2-7.1,4-10.7,6c-3-5-6-5.1-9-0.1
					c-10.9,11-36.6-13.9-41.6,11.6c-9.8,1.4-11.9-8.9-19-11.3c-5.2,14.2,6.4,29.6-9,40.6c-0.4-15.7-0.8-31.3-1.2-47
					c0.4-4.3,0.7-8.6,1.1-13c10.2,2,20.6,6.1,30.5,5.5c10.7-0.6,24.7,14.1,30.3,0.7c4.5-10.6-10.4-18.1-22.7-21.2
					c-5.4-1.4-8.4-1.9-5.8-9.3c23.5,6.9,22.7-21.4,43.5-21.5c3.1,8.1-3.6,17.4,4.5,24.5c0.5,0.4,8.1-5,12.4-7.7
					c4.4-2.1,8.8-4.1,13.1-6.2C2227.4,367.7,2242.1,333,2253.1,342.3z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2442.6,1097.8c0,3.6-0.1,7.1-0.1,10.7
					c-12.7,3.1-27.6,0.5-44.2,13.4c30.4-5,48,3.7,58.1,22.9c-23.3,1.9-46.6,3.9-69.8,5.8c-33.6-7.7-31.5-29-27.7-50.9
					C2386.6,1098.2,2414.4,1094.2,2442.6,1097.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1871,2395.8c-6.8,3.4-12.6,4.9-13.2-4.8
					c-0.4-6.6,1.4-13.7-6-18.9c9.5-39.9,19-79.7,28.6-119.6c2.2,0,4.3,0,6.5,0c0.9,6.9-1.1,14.8,9,18.7c-2.7,13.2-5.3,26.3-8,39.3
					c13.6,4.4,15.8-7.2,23.7-10.1c3.9,0.1,7.8,0.3,11.7,0.4c1.9,0.2,3.8,0.4,5.7,0.6c-0.1,3.9-0.2,7.7-0.2,11.6
					c-13.7,10.3-27.4,20.6-41.1,31c-1.3-8.3-2.6-16.6-4.4-28.1c-14.5,14-15.2,22.5,4.7,27.9c-4.2,13.5-15.6,27.1,2.5,40
					C1885.2,2389.1,1852.8,2363.1,1871,2395.8z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2356.4,1173c-0.1,16.2-0.2,32.4-0.2,48.6
					c-9.7,27.7-10,55.5-0.3,83.6c-0.2,4.3-0.5,8.5-0.7,12.8c-9-0.6-11.9-5.7-12.8-12.3c0.1-20,0.1-39.9,0.2-59.9
					c-4.1,16.4-22.1,28.2-16.2,47.8c3.1,10.3-10.3,18.6-25.9,16.8c-0.3-2.1-0.7-4.2-1-6.3c-0.9-6.9-16-7.8-10.4-17.4
					c15.9-10.9,10.4-31.2,25.1-42.6c3,2.9,6,5.8,8.9,8.7c0.9-1.3,1.9-2.5,2.8-3.8c-4-1.6-7.9-3.2-11.9-4.7
					c-0.9-13.2,18.6-12.6,21.6-23.2c2-6.1,12-12,0-18.3c2.7-11.9,5.4-23.7,8.1-35.6C2348,1169.2,2352.2,1171.1,2356.4,1173z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2418.4,1901.7c4.7-13,11.7-23,31.1-17.3
					c9.7,50.1,26.3,99.4,27,150.5c-19.6-3.4,2.3-29.5-23-29.8c0,0,0-0.3,0-0.3c-0.4-30.1-24.5-57.3-17.3-88.8
					C2436.9,1912.7,2432.4,1899.2,2418.4,1901.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2347,1527.9c2.8-22.3-1.9-47.3,27.9-60.1
					c9.7,48.6,19.4,97.2,29.1,145.7c-5.6,5.7-11.1,11.8-20.8,4.5c10.1-13.4,12.5-27,0.8-41.1c-2.4-12.1,5.3-25.4-6.6-36.4
					c-10.9,0.5-20.5-9.3-32-3.6l1.4-1.3C2346.8,1533,2346.9,1530.4,2347,1527.9z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1883.9,776.7c24.2-21,62.7-22,86.1-44.1
					c2.3,0.9,4.5,1.8,6.8,2.8c-0.4,13-4.7,27.9,22.4,21.9c14.8-3.3,19.8,5.8,20.1,17C1974.2,775.1,1929,775.9,1883.9,776.7z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2561.2,388.6c6.4,26.3,15.1,52.8-9.3,76.4
					c-29.5-27.2-4.6-59.6-17.1-87.6C2547.6,374,2559.7,372,2561.2,388.6z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2327.8,1778.9c0.6,9.6-9.9,12.9-15,19.3
					c-7.9,1.8-14.7,8.7-24,3.1c11-11.4,11.8-22.3-3.1-32.2c17.5-1,33.9-3.7,41.2-19.8c14.2-15,16.6-34.8,27.5-51.1
					c7.5-7.3,19.5-12.5,15.8-25c9.6-22.9,27.7-4.5,41.8-5.5c-0.3,1.9-0.5,3.8-0.8,5.7c-6-0.1-11.2,0.8-13.1,6.5
					c-22.8,0.1-19.1,19.3-28.5,29c-5.5,2.3-7.2,6.4-7.1,11.4C2357.8,1742.8,2328.1,1754.4,2327.8,1778.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1974.8,1839.2c16.5-0.7,22.5,8.4,25.6,20.1
					c-13.5-1.6-9.9,7.2-11.4,13.4c-7.6,31-7.3,63.5-23.2,92.8c-4.8-4.1-9.6-8.2-14.4-12.4
					C1956.4,1914.7,1963.4,1876.6,1974.8,1839.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2439,1824.6c-13.3-0.8-12.8-9.4-13.6-17.2
					c-1.3-12.5-6.8-21.1-24.1-18.1c0,0,2.6-1.2,2.6-1.2c-1.5-12.2,32.9-22,2.6-36.2c0.5-22.5-13.7-44.2-7.2-67
					c6.2,2.3,12.4,4.5,18.7,6.8C2430.9,1735.4,2439.6,1779.5,2439,1824.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1813.8,765.9c-5.8,2-11.6,4-17.4,5.9
					c0,0,0.4,0.4,0.4,0.3c-3.7-2.3-7.4-4.5-11.1-6.6l-0.7-0.6c0.3-8.9,0.5-17.8,0.8-26.7c44.8-10.2,86.3-25.6,122.2-50.9
					c6.8-4.8,16.2-6.9,24.5-10.3c6.7,2.3,8.9,6.8,8.7,12.5c-32.2,15.5-64.4,31-96.6,46.5C1821.7,736.5,1814.1,748.5,1813.8,765.9z"
					/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2373.1,246.3c2.6-5.3,5.2-10.5,7.8-15.8
					c47.5,31.7,95,63.4,142.5,95.2c-1.8,7.3-8.7,7.8-15.6,8.2c-6.4-9-13.6-16.5-27.8-12.1l0.2,0.1c4.5-7.8-2.1-6.6-7.3-6.4l0.3,0.3
					c-2.1-10.5-11.4-12.2-21.5-12.8l0.4,0.4c-1-15.8-11.7-20.7-28.6-18.7l0.2,0.1c0.1-13.2-8.1-16-21.4-12.6l0.2,0.1
					c4.6-7.9-1.9-6.7-7.3-6.5l0.4,0.3c1.2-9.2-3.5-13.5-14.5-12.7l0.4,0.4c1.4-4.6-2.4-5.2-6.2-5.7L2373.1,246.3z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2472.6,2139.5c14.2-8.4,5.9-21.6,8.9-32.3
					c2.5-0.1,4.9-0.3,7.4-0.4c0.4,4.4,0.7,8.9,1.1,13.3c-1.1,7.4-2.3,14.8,8.9,18c11.2,37.9,21.2,76,16.5,115.3
					c-27.3-4.2-2.9-29.6-21.7-37.3C2486.6,2190.6,2479.6,2165,2472.6,2139.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1951.4,1953.1c4.8,4.1,9.6,8.2,14.4,12.4
					c0,3.9,0,7.8-0.1,11.7c-8.5,14.7,4.7,12.1,13.9,12.5c2.7,1.3,5.4,2.7,8.1,4c-11.4,9-39.1,7.4-27.6,31.2
					c-24.9,7.1-1.6,17-1.4,25.5c-0.4,6.7-3.9,13.9,5.3,18.8c-2.3,2-4.6,4-6.8,6c-3.4-3.8-7-7.6-13.2-4.6c-6.6,3.2-3.3,7.6-0.9,11.7
					l-1.1,0.8c-6.1-1.3-12.3-2.7-18.4-4C1932.9,2037.1,1942.1,1995.1,1951.4,1953.1z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2015.6,1765.4c-8.6,0.7-17.3,1.4-25.9,2.2
					c16.8-38.6,8.3-81.6,25.7-120.1c6,2.4,11.9,4.7,17.9,7.1C2030.4,1691.9,2014.6,1727.6,2015.6,1765.4z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2518,1145.4c0,0-0.7,0.5-0.7,0.5
					c5.4-2,10.8-3.9,16.2-5.9c5,0.1,10,0.1,14.9,0.2l0.1,0.6c-2.8,3.6-0.5,5.3,3.4,6.3c-0.2,14.9-2.5,30.1,0.1,44.7
					c2.1,11.9-7.4,19-13.9,19.5c-7.2,0.5-16.1-5.9-17.1-17.6C2519.8,1177.7,2527.4,1161.2,2518,1145.4z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2369.5,1438.7c-10.1,6.7-18.9-0.9-28.3-2.2
					c1.4-11.6,2.5-23.1-6-33.6c-2.3-6.6,1-14.7-7.5-19.6c3.1-9.9,6.3-19.8,9.4-29.8c6-2.5,6.7-15.3,18.4-6.6
					C2360.2,1377.5,2364.8,1408.1,2369.5,1438.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2465.3,767.8c-38.4-2.3-0.5-18.8-7.6-27
					c0.3-4,0.6-7.9,0.8-11.9c13.8-1.4,27.6-2.8,41.4-4.3c-17,32.9-14.2,35.4,39.7,35.7c3,0.5,6,0.9,9.1,1.4
					C2522.7,778.2,2493,764.5,2465.3,767.8z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2548.7,761.7c-3-0.5-6-0.9-9.1-1.4
					c-32.5-11.5-33.7-25.9-3.9-47.2c22.3,13.6-12.8,20.2-3.3,34.1c35.2-9.6,68.7,8.9,104.9,8.1c-15.6,5.7-29.1,18-47.5,5.7
					c-7.4-4.9-9.4,1.7-11.6,6C2567.2,771,2558.1,765.5,2548.7,761.7z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1813.8,765.9c0.2-17.5,7.9-29.4,30.8-29.9
					c14.1,10.8,31.9,3,47.2,7.8c-0.1,15.2-13.4,14.6-26,17.8c-11.4,2.8-20.8,11-31.1,16.8c-4.7-0.2-9.4-0.3-14.1-0.5
					C1814.7,775.4,1813.9,770.8,1813.8,765.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2370.2,625.3c-3.8,2.7-7.5,5.4-11.3,8.1
					c-5.3-0.2-10-0.5-15.6-2.1c-18.2-5.3-13.7,7.3-9.8,13.5c4.6,7.4,12,0.2,18.2-0.8c-1.1,7.9-2.2,15.7-3.3,23.6l-0.5-0.2
					c-5.9-0.5-9.8,1.7-11.7,6.4c-9.2,3.1-3.3,20-19.9,15.9c-1.7-0.4-3.9-6.1-2.9-7c22.5-21.6,1.3-42.8-2-64c1.8-1.7,3.6-3.4,5.5-5.1
					c2.5-1.6,4.9-3.1,7.4-4.7c9.3,1.7,16.4,13.5,27.9,4.9c2.3,0.7,4.6,1.3,6.9,2c2.3,1.5,4.5,2.9,6.8,4.4
					C2367.3,621.9,2368.7,623.6,2370.2,625.3z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1904.7,1170.9c-4.4-12.1-8.8-24.3-13.2-36.4
					c20.8-3.7,39-17.4,62.6-11c0,0-0.1-0.2-0.1-0.2c2.3,4.1,4.6,8.2,6.9,12.2c0,0,0.3-0.3,0.3-0.3c-20.4,2.9-41.5,5.2-28.2,30.7
					C1923.5,1167.6,1914.1,1169.2,1904.7,1170.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1941.2,689.6c0.1-5.7-2-10.2-8.7-12.5
					c25.7-12.4,50.4-26.7,80.1-32.3c0.2,2.9,0.4,5.8,0.5,8.8c-8.7,8.9-30.7,6.3-28.9,24.2c-37.3-2-17.7,24.3-25,37.2
					c-3-2.1-6-4.2-8.9-6.3C1948.1,702,1954.4,692.6,1941.2,689.6z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2411.8,1667.5c-14.1,1-32.2-17.4-41.8,5.5
					c-0.3-5-1.3-9.8-7.1-12.6c11.8-12.3,32.8-23.7,5.3-40.3c5.1-0.7,10.1-1.5,15.1-2.2c9.7,7.3,15.2,1.3,20.8-4.5
					C2404.8,1631.6,2417,1648.8,2411.8,1667.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2614.3,2778.1c-10.4-5-5.6-13.9-7.7-21
					c-3-10.1-7.3-12.2-13.9-2.1c-2.5-2.3-5.1-4.6-7.6-6.9c-5.4-23.6-28.1-8.4-41.6-14c6.5-21.3,28.8-13.1,40.2-9.9
					c20.1,5.6,15.2-5,17-12.6c1.8-0.1,3.5-0.1,5.3,0C2606.5,2734,2623.9,2755.1,2614.3,2778.1z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2355.5,1346.9c-11.7-8.7-12.4,4.1-18.4,6.6
					c-9.3-4.6-25.9,14.6-29.9-7.1c20.4-8.1,13-33.8,35.2-40.7c0.8,6.7,3.8,11.7,12.8,12.3
					C2355.2,1327.6,2355.3,1337.3,2355.5,1346.9z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2530.4,2331.2c-9.2-2.7-36,10-17-16.8
					c9.1-12.8-15.1-27.3-11.7-43.4c7.6,1.7,12-3.4,17.6-5.8C2541.6,2285.3,2525.1,2309.4,2530.4,2331.2z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2453.4,2005.1c25.3,0.3,3.4,26.4,23,29.8
					c0,0-1,0-1,0c-1.2,5,0,9.4,5.2,12.7c9.1,11.1,10.2,23.4,8.2,36.2c-11.8-2.3-23.6-4.5-35.4-6.8c-0.2-3.7-0.3-7.4-0.5-11
					c2.8-0.2,5.6-0.5,8.3-0.7C2480.4,2043.6,2445.1,2026,2453.4,2005.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1942.9,1077.5c31-20,61.9-40.1,92.9-60.1
					c1.9,2.5,3.7,5,5.6,7.4c-23.2,28.2-57.3,43.8-92.9,57.7C1946.7,1080.8,1944.8,1079.1,1942.9,1077.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2519.4,2265.2c-5.6,2.4-10,7.5-17.6,5.8
					c-6.8-0.5-13.6-1.1-20.5-1.6c-0.2-3.9-2.3-9.2-0.1-11.5c16.2-16.7,6.5-22.5-13.7-24.4c0,0-0.2,0.1-0.2,0.1
					c-2-5.3-4.1-10.7-6.1-16c9.3-1,13.8,6.1,20.7,9c17.1,7.4,12.3-3.4,11.8-10.5c18.9,7.7-5.5,33,21.7,37.3
					C2515.2,2257.7,2526,2259.5,2519.4,2265.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2507.8,334c6.9-0.5,13.8-0.9,15.6-8.2
					c6.7,3.4,13.4,6.8,20.2,10.2c0,3.6,0.1,7.3,0.1,10.9c-11-0.7-13.2,9.3-21.1,12.3c-1.9-12.3-10.3-6.9-17.2-4.7
					c-15.8,4.9-26.7,1.4-31.7-13.1c1.4-2.3,2.7-4.7,4.1-7c3.8-0.5,7.6-1,11.5-1.5C2495.4,333.2,2501.6,333.6,2507.8,334z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2030.5,1581.8c7.2,0.2,14.4,0.5,21.6,0.7
					l0.6,0.5c-0.4,5.9-0.9,11.8-1.3,17.7c-0.3,1.6-0.4,3.2-0.1,4.8c-10.1,2.3-18.7,5-4.1,13.3c0.9,11,11.9,22.5-1.7,32.7
					c-6.4-9-5.8-26.9-27.8-16.2C2022,1617.5,2026.2,1599.7,2030.5,1581.8z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2522.6,359.2c7.9-3,10.1-13,21.1-12.3
					c3.6,12.5,20.7,10.5,27.9,19.1c0,3.5,0.1,6.9,0.1,10.4c-2.2,4.9-1.6,11.6-10.6,12.2c-1.5-16.6-13.5-14.6-26.4-11.2l-2.8,0.5
					l-2.4-1.4C2527.2,370.7,2524.9,364.9,2522.6,359.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1942.7,376.3c1.9-11.7-2.5-21.4-14-28.6
					c18.7-0.1,37.4-0.1,56-0.2c-7.8,8.9-13,18.3-7.2,29.8C1965.9,377.1,1954.3,376.7,1942.7,376.3z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2358.7,1099.7c-3.8,21.9-5.8,43.2,27.7,50.9
					c-1.1,3.4-2.8,6-7.8,4.5c-11.7-13.6-28.7-2.8-42.4-7.2c-5.2-7.1-8.8-14.4-6.6-22.9c4.7-0.1,9.3-0.2,14-0.4
					c13.6-12.2,2.8-28,7.3-41.5c1.8,0,3.6,0,5.4,0.1C2357.1,1088.7,2357.9,1094.2,2358.7,1099.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2112.3,434.7c15.4-11,3.8-26.4,9-40.6
					c7.2,2.4,9.2,12.7,19,11.3c0.1,2.1,0.2,4.2,0.3,6.3c-0.5,7-2.2,14.3,6.6,19.1c-2.6,3.9-5.3,7.7-7.9,11.6
					c-8.3-9.3-20.6,14.8-27.8-2.9C2110.7,437.7,2111,436.1,2112.3,434.7z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2606,2711.6c-1.8-0.1-3.5-0.1-5.3,0
					c-16-11.2,4.5-35-21.2-42.9c-5.4-5.1-10-10.5-8.7-17.8c7.1-3.9,14.3-7.8,21.4-11.7C2596.8,2663.4,2601.4,2687.5,2606,2711.6z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2571.6,366c-7.2-8.6-24.4-6.6-27.9-19.1
					c0-3.6-0.1-7.3-0.1-10.9c14,10.7,42.5-7.7,49,18.2C2580.2,351.3,2568.9,349.8,2571.6,366z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2124.8,868c-6.7-0.1-13.4-0.3-20.1-0.4
					c0-8,0-15.9,0-23.9c10.1,0.1,19.7-0.2,20.1-11.8c2.2-2.2,4.3-4.4,6.5-6.6c6.4,5.8,25.2-0.3,20.9,15.8
					C2130.8,841,2128.6,855.1,2124.8,868z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1932.9,1165.9
					c-13.3-25.5,7.9-27.8,28.2-30.7c2.2,9.8,4.4,19.5,6.6,29.3C1956.2,1165,1944.6,1165.4,1932.9,1165.9z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2470.7,1144.3c-1.4-10.6,8.4-22.9-7-31.5
					c29.5-4.5,34.9,11.1,35.5,30.7C2489.7,1143.8,2480.2,1144,2470.7,1144.3z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2548.5,1140.2
					c-3.2-14.7,13.3-20.3,18.2-32.4c12.4,10,23,18.7,16.9,33.1c-9.4,0-18.8,0-28.3,0c-2.2-1.2-4.4-1.2-6.6-0.1L2548.5,1140.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2571.6,366c-2.7-16.2,8.6-14.7,21-11.8
					c7.8,1,17.2-1.5,21.4,7.4c0.3,4.1,0.6,8.2,0.9,12.2c-14.4,0.9-28.8,1.7-43.2,2.6C2571.7,372.9,2571.7,369.4,2571.6,366z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2661.3,740.7c-18.1-6.2-39.8-2.4-55.5-15.2
					c13.9-1.3,27.8-2.6,41.8-3.9C2668.1,719.9,2663.5,730.9,2661.3,740.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2365.8,620.2c-2.3-1.5-4.5-2.9-6.8-4.4
					c0.2-34.7,0.4-69.4,0.6-104.1c1.9,0,3.8,0,5.6,0.1C2365.4,547.9,2365.6,584.1,2365.8,620.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1950.5,321.4c-9.4,10.8-19,21.3-36.6,22.8
					c-8.3-27,21.4-23.5,35.9-32.2C1950.1,315.1,1950.3,318.2,1950.5,321.4z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2112.3,374.7c-0.4,4.3-0.7,8.6-1.1,13
					c-18.4-4.2-39.2,2.9-55.9-9.2c0-1.7,0.1-3.3,0.4-4.9c0,0-0.1,0.1-0.1,0.1C2074.4,374,2093.3,374.3,2112.3,374.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2355.9,1305.2c-9.7-28-9.5-55.9,0.3-83.6
					C2356,1249.5,2355.9,1277.4,2355.9,1305.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2365.2,481.9c-1.9-1.6-3.8-3.2-5.7-4.9
					c0.3-24.4,0.6-48.8,0.8-73.2c2-0.1,4.1-0.2,6.1-0.2C2368.4,429.8,2369.6,455.9,2365.2,481.9z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2336.3,1147.9c13.7,4.4,30.6-6.4,42.4,7.2
					c-24.9-9.7-20.9,6.5-22.3,17.9c-4.2-1.9-8.4-3.9-12.6-5.8c-2.6-2.1-5.2-4.3-7.8-6.4C2336.1,1156.5,2336.2,1152.2,2336.3,1147.9z
					"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2349.1,997.5c2.5,0.5,5.1,0.9,7.6,1.4
					c1.3,1.4,1.9,2.9,1.9,4.7c-8.1,35.1-22.6-5.9-35.3,7.7c4.8-15.7,15.2-12.2,22-14.6C2346.5,997.3,2347.8,997.5,2349.1,997.5z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2017.7,1635.4c22-10.7,21.4,7.1,27.8,16.2
					c0,0-1.1,0-1.1,0c-3.7,1-7.5,2.1-11.2,3.1c-6-2.4-11.9-4.7-17.9-7.1C2016.1,1643.4,2016.9,1639.4,2017.7,1635.4z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2124.7,831.8c-0.3,11.6-10,12-20.1,11.8
					c-0.2-14-0.3-28.1-0.5-42.1c2.4,0,4.8-0.1,7.2-0.1c0.8,10.3-5.2,21.6,6.3,30.5C2120,831.9,2122.4,831.9,2124.7,831.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2453.4,2077c11.8,2.3,23.6,4.5,35.4,6.8
					c0.1,2,0.1,4,0.2,6.1c-7.3-0.1-14.6-0.2-21.9-0.3c-4.5-2.4-9-4.8-13.6-7.1C2453.5,2080.6,2453.4,2078.8,2453.4,2077z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2366.5,403.6c-2,0-4.1,0.1-6.1,0.2
					c-0.4-5.9-0.7-11.9-1.1-17.8c5.4-1.8,8.6-8.1,16.1-5.6C2361.9,385.1,2372.1,396.7,2366.5,403.6z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2418,1691.7c-6.2-2.3-12.4-4.5-18.7-6.8
					c-1.4-1.6-1.9-3.3-1.5-5.2c1.9-5.7,7.1-6.7,13.1-6.5C2413.3,1679.3,2422.8,1683.6,2418,1691.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2614.9,373.8c-0.3-4.1-0.6-8.2-0.9-12.2
					c4.9,0,9.9,0,14.8,0c-1,5.8-3.6,10.4-10.8,12.2l-1.5,0.9L2614.9,373.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2035.8,1017.4c4.3-4.4,9.7-7.1,16.7-7.2
					c2.1,1.8,4.3,3.7,6.4,5.5c-5.8,3-11.7,6.1-17.5,9.1C2039.6,1022.3,2037.7,1019.8,2035.8,1017.4z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2490,2120.1c-0.4-4.4-0.7-8.9-1.1-13.3
					c-0.3-1.3-0.7-2.6-1.1-3.9c0.8-0.9,1.7-1.7,2.6-2.5c7.3,5.7,4.8,12.9,4.6,19.8C2493.4,2120.1,2491.7,2120.1,2490,2120.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2490,2120.1c1.7,0,3.4,0,5.1,0
					c1.3,6,2.5,12,3.8,18C2487.6,2134.9,2488.9,2127.5,2490,2120.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1932,1085.9c3.7-2.8,7.3-5.6,11-8.4
					c1.9,1.7,3.7,3.3,5.6,5c-3.9,2.7-7.9,5.4-11.8,8.2C1935.1,1089,1933.5,1087.5,1932,1085.9z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2480.6,2047.6c-5.2-3.3-6.3-7.7-5.2-12.7
					C2485.9,2036.7,2480.5,2042.9,2480.6,2047.6z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2548.7,1140.8c2.2-1.1,4.4-1.1,6.6,0.1
					c-1.1,2.1-2.2,4.2-3.2,6.2C2548.1,1146.1,2545.8,1144.4,2548.7,1140.8z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2311.3,618.6c3.3,21.3,24.5,42.4,2,64
					c-1,1,1.2,6.6,2.9,7c16.6,4.1,10.7-12.7,19.9-15.9c5.9,0.4,9.5-2.1,11.7-6.4c0,0,0.5,0.2,0.5,0.2c1.9,24.7,3.8,49.4,5.8,76.2
					c8.3-16,2.6-31.3,5.3-45.7c2.6,0.3,5.3,0.7,7.9,1c-11.5,28.1,9.9,30.9,33.9,31.6c0,3.8,0.1,7.6,0.1,11.4
					c0.5,8.7-6.6,10.1-14.4,10.9c-30.5-0.6-61-1.2-98-1.9c21.5,12.1,41.7-0.5,55.5,13c0,0-0.1,0-0.1,0c-5,2-4.9,4.1-0.1,6.3
					c0.1,2,0.2,3.9,0.2,5.8c-3.6,5.1-9.1,12.7-15.3,8.8c-17.2-10.7-37-5.5-54.9-10.2c-19.3-13.6-35.7-8.4-50.7,4.9
					c-9.5-0.1-13.5,4.5-14,12c-8.8-4-14.7-9.5-13.4-18.7c8.9,0.1,13.3-3.7,13.5-11.2c11.8-2.1,23.7-4,33.8-11.3
					c-17.5-15.9-37.5,5.5-53.1-7c-0.6-10.8-0.3-21.7-1.9-32.4c-3.3-22,8.9-44.8-7-66c-1.8-10.4,18.4-16.2,7.6-28.5
					c-4.6-34.3,10-69.2-7.1-103.1c8.4-11.8-0.1-23-2.4-34.4c13.8-4.2,9.4-14.5,9.8-22.8c0.9-20.8,1.1-41.7,1.6-62.5
					c3.6-2,7.1-4,10.7-6c10.6,5.7,22,8.1,34.3,7.4c1.1,4.1,2.2,8.1,3.3,12.2c-6.6,7.8-20.5-7.4-25.9,6.8
					c9.2,6.2,24.5,11.1,15.5,25.6c-14.7,3.4-15.8-16.5-32.2-13.5c0,9.1,0.7,18.1-0.2,26.9c-2.3,23.1,11.3,11.7,21.6,7.8
					c3.4,2.3,6.7,4.7,10.1,7c-6.1,4.3-12.5,8.4-18.1,13.1c-21.4,18-17.3,27.4,15.3,35.6c3.1,1.8,6.3,3.6,9.4,5.4
					c3.2,13.6,19.6,19.4,25.6,31.4c1.1,17.3,18.3,35.3-1.6,51.8c12.2,9,23.6,20.1,42.4,13.3C2304.5,618.5,2307.9,618.6,2311.3,618.6
					z M2285.2,689.5c2.8,1.1,5.6,2.2,8.4,3.2c0.9-0.7,1.8-1.3,2.7-2c-3.8-0.4-7.5-0.8-11.3-1.1c-1.1-2.9-2.3-5.8-3.4-8.7
					c-1.2,0.3-3.1,0.5-3.5,1C2275.4,685.2,2277.8,687.7,2285.2,689.5z M2274.2,688.7c-4.7-21.8,9.6-39.8-10.1-51.4
					c-7.9-4.7-23.7-1.6-26.9,11.2C2232,669.3,2251.8,675,2274.2,688.7z M2194.1,678.4c5.2,22-8.5,42.2,9.2,50
					c13.4,5.9,21-15.6,36.4-22.9C2225.3,696.9,2213.3,689.8,2194.1,678.4z M2200.5,542.9c-2.2,17.2-11.3,35.3,0.4,49.4
					c5.6,6.8,20.6-3.5,25.3-9.9C2242.6,559.2,2194.2,563.6,2200.5,542.9z M2316.2,742.9c-11.6-5.5-9.5-26.8-33.6-19
					c-9.9,3.2-15.7,9.8-12.9,13.4C2281,751.6,2299.5,741.2,2316.2,742.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2253.1,342.3c-11-9.2-25.6,25.4-35-2.7
					c25.9-19.5,23.5-27.2-12.6-40.3c-12.8-6.4-6.8-16.3-7.2-25c9.4-0.5,13.7-5.2,14.5-12.8c1.9-3.3,3.7-6.7,5.6-10
					c7.8,25,35.5,30.9,56.8,43C2277.7,313.7,2303.3,341.2,2253.1,342.3z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2236,395.2c-12.3,0.7-23.7-1.7-34.3-7.4
					c0.6-4.2,1.2-8.5,1.8-12.7c10.6,1.7,24.1,8.1,22.3-9.9c12-3,30.5,7.4,34.6-11.7c2.2-0.7,4.3-1.8,6.5-2.1c36.7-5,36.7-5,53-33.3
					c17.9,17.6,17.9,17.6-16.7,42.4c-10.4,0.2-21.6-3.6-32.8,3.2c10,11.1,22.1,7.6,32.7,8.5c10.8,2.6,26.4-5,32.1,5.3
					c6.9,12.4-18.6,13-17.8,25.3c-11.2-0.2-22.3-0.4-33.5-0.7C2275.8,377.8,2260.1,375.5,2236,395.2z M2309.7,384.7
					c-3.9-2.1-6.6-4.7-8.5-4.4c-2.7,0.5-4.7,3.2-7,5c2.1,1.4,4.4,4.1,6.4,4C2303.2,389.2,2305.6,386.9,2309.7,384.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2489.1,332.9c-3.8,0.5-7.6,1-11.5,1.5
					c-20.5-2.4-35.8-14-51-23.8c-18.8-12-33-29.5-58.7-32.3c0,0-1.5-2.3-1.5-2.3c3.5-10-5.8-18.2-5.8-27.7c-0.1-18,8.3-1.4,12.5-1.9
					c0,0,2.2,1.9,2.2,1.9c0.4,3.2-1.2,8,6.2,5.7l-0.4-0.4c-3.5,11.1,1.3,15.3,14.5,12.7l-0.4-0.3c-4.1,7.5,2.3,6.4,7.3,6.5
					c0,0-0.2-0.1-0.2-0.1c3.2,9.1,10.1,13.5,21.4,12.6c0,0-0.2-0.1-0.2-0.1c8.8,7.1,8.3,24.5,28.6,18.7l-0.4-0.4
					c0.1,12.9,8.9,15.2,21.5,12.8l-0.3-0.3c-4,7.5,2.3,6.4,7.3,6.4c0,0-0.2-0.1-0.2-0.1C2483,325.5,2486.1,329.2,2489.1,332.9z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2127.1,212c4.8,1.2,9.5,2.4,14.3,3.6
					c1.9,9.1,9.9,9.8,18.4,9.9c13.6,0.1,22.2,5.1,22.7,17.6c-29.7,1.6-13.6,27.2-26.6,37.5c-8.8-3-10.1-14.7-22.2-13.7l0,0
					c-1.2-10,15.4-19.7,0.2-30l-0.2-0.1C2131.5,228.5,2129.3,220.3,2127.1,212z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2283.9,402.2c11.2,0.2,22.3,0.4,33.5,0.7
					c5.1,22.1-9.7,35.5-27.6,47.6c-6.3-7.4-31.6-0.3-21.6-20.3C2279.9,423.6,2281.7,412.8,2283.9,402.2z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2268.2,430.2c-10.1,20,15.2,12.9,21.6,20.3
					c-7.4,17.8-23.3-1.6-33.5,5c-6.1-1.4-15.4,11.3-18.5-3.2c4-3.5,8.1-6.9-2.2-7.5c0,0,1.2,1,1.2,1c0.5-4.4-1.4-6.8-7.1-5.8
					c0,0-0.9-0.2-0.9-0.2c9-14.4-6.3-19.4-15.5-25.6c5.4-14.2,19.3,1,25.9-6.8C2250.2,413.8,2266.2,415.7,2268.2,430.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2359.3,386c0.4,5.9,0.7,11.9,1.1,17.8
					c-0.3,24.4-0.6,48.8-0.8,73.2C2340.5,446.4,2327.7,415.9,2359.3,386z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2198.3,274.2c0.4,8.7-5.6,18.7,7.2,25
					c-0.2,15.5-0.3,31-0.5,46.5c-4.3,2.7-11.9,8.1-12.4,7.7c-8.1-7.1-1.4-16.4-4.5-24.5c3.5-18.6,3.4-37.2,0.1-55.8
					c0.5-8.1,1-16.3,1.5-24.4c2.6,0.5,5.2,1.1,7.7,1.6c0.5,3.7,1,7.4,1.5,11.1C2198.6,265.6,2198.5,269.9,2198.3,274.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2359.6,511.6c-0.2,34.7-0.4,69.4-0.6,104.1
					c-2.3-0.7-4.6-1.3-6.9-2c-0.2-6.1-0.5-12.2-0.7-18.3C2345.9,566.8,2332.1,537.4,2359.6,511.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2281.7,240.6c-24.3,8.4-42.3-6.2-62.4-13.2
					c23.2-17.4,46.3,1.6,69.4,2.3C2291.9,229.8,2282.8,236.1,2281.7,240.6z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2334.9,229.6c7,0.6,15.8-1.2,12,9.3
					c-3.1,8.4-3.5,18.5-17.2,19.6c-12.2,1-17.5-6.4-17.3-14.9C2312.6,231.6,2325.5,231.8,2334.9,229.6z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2359.3,278.2c-0.1,25.3-0.2,50.7-0.3,76
					c-6.8-17-14.7-34-14.9-52.1C2344,292.5,2340.9,279.8,2359.3,278.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2189.6,248.6c-0.5,8.1-1,16.3-1.5,24.4
					c-12.8-2.8-22.3-2.3-18.6,12.5c-5.3-0.1-10.5-0.3-13.6-4.9c13-10.3-3.1-35.9,26.6-37.5c2.3,0.5,4.6,0.8,7,1.1
					C2189.6,245.7,2189.6,247.2,2189.6,248.6z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2310.8,510.6c11.9,10.9,18.5-2.1,27.5-3.9
					c5.2,16.1-6.8,23-20.9,28.9c0,0-1.5-1.3-1.5-1.3c0.1-6.9-3.6-10.8-12-12c-5.6-0.8-12.6-3.7-9.1-9.1
					C2296.5,510.6,2305.3,511.4,2310.8,510.6z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2288.4,558.5c-3.1-18.2,5.9-26.5,27.6-24.2
					c0,0,1.5,1.3,1.5,1.3c-6.5,27.3-12.4,31.9-29,23L2288.4,558.5z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2351.3,595.4c0.2,6.1,0.5,12.2,0.7,18.3
					c-11.6,8.6-18.6-3.2-27.9-4.9c-0.3-4.6-0.6-9.3-0.9-13.9C2332.6,595.1,2341.9,595.3,2351.3,595.4z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2311.3,618.6c-3.4,0-6.7-0.1-10.1-0.1
					c-0.3-6.8,0-13.7-0.9-20.4c-0.6-4.4-6.5-10,1.2-12.4c8.3-2.6,11.7,4.3,15.6,9c-0.1,6.3-0.2,12.6-0.3,18.8
					C2314.9,615.2,2313.1,616.9,2311.3,618.6z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2303.1,372.2c-10.6-0.9-22.7,2.6-32.7-8.5
					c11.2-6.8,22.4-3,32.8-3.2C2303.1,364.4,2303.1,368.3,2303.1,372.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2198.3,274.2c0.2-4.3,0.3-8.6,0.5-12.9
					c7.5-5,5.4-12.7,7-19.4c2,1.3,4,2.7,6,4c0.3,5.1,0.7,10.3,1,15.4C2212,269,2207.8,273.7,2198.3,274.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2304.3,306.5c-2,2.3-3.6,4.3-5.3,6.3
					c-0.9-2.7-2.4-5.5-2.6-8.2c-0.1-1.7,1.7-3.4,2.7-5.1C2300.8,301.6,2302.4,303.8,2304.3,306.5z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2316.7,613.5c0.1-6.3,0.2-12.5,0.3-18.8
					c2,0.1,4.1,0.2,6.1,0.3c0.3,4.6,0.6,9.2,0.9,13.9C2321.7,610.4,2319.2,612,2316.7,613.5z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2189.6,248.6c0-1.5,0-3,0-4.4
					c11.5-6.4,6.1,3.2,7.8,6C2194.7,249.7,2192.2,249.2,2189.6,248.6z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2288.5,558.6c-1,2.6-1.9,5.2-2.9,7.8
					c-6.4-4.6-0.1-5.8,2.8-7.9L2288.5,558.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2338.7,355.1c-1.2,0.8-2.4,1.7-3.6,2.5
					c1.1-0.9,2.2-1.8,3.3-2.7L2338.7,355.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2338.5,354.9c1.1-0.9,2.1-1.8,3.2-2.7
					c-1,1-2,1.9-3,2.9C2338.7,355.1,2338.5,354.9,2338.5,354.9z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2133.9,236.9c-1,0.8-2.1,1.7-3.1,2.5
					c0.1-0.6,0-1.4,0.5-1.7c0.6-0.5,1.6-0.6,2.5-0.9C2133.7,236.7,2133.9,236.9,2133.9,236.9z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2336.3,1147.9c-0.1,4.3-0.2,8.6-0.2,12.9
					c-39.8-6.2-17.5,24.5-28.6,35.4c-7,6.1-14,12.1-20.9,18.2c-8.3-1.5-14.9,1.4-21.2,5.2c-0.2-21.3-0.5-42.6-0.7-63.9
					c2.8-5.7-4.9-17.8,11.5-15.2c17.6,2.8,17.9-6.4,17.6-16.7l0.1-0.2c4.5,0.1,9.1,0.2,13.6,0.2c7.4-0.8,15.2-9.1,22.1,1.1
					C2327.4,1133.5,2331.1,1140.8,2336.3,1147.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2151.3,1133.3c9.2-5.8-2.1-13,1.2-19.2
					c7.2,6.4,14.4,12.7,21.6,19.1c2.4-1.7,4.8-3.4,7.2-5.1c8.8-0.3,17.6-0.6,26.4-0.8c1.1,1,2.2,2.1,3.4,3.1
					c-0.7,5.3-1.4,10.7-2.1,16l-0.2,0.3c-15.8-1.4-20.6,9.8-28.3,17.7c-6.2,1-6.1,3.3-1.5,6.4c-0.1,1.3,0.2,2.4,1,3.5
					c-10.5,3.6-7.2,12.2-9.4,18.9c-1.5,0-3,0.2-4.5,0.4c-4.5,0.4-9,0.9-13.5,1.3c0,0-2.3-1.8-2.3-1.8c0.7-3.9,1.3-7.8,2-11.7
					C2158.8,1165.3,2176.4,1149.3,2151.3,1133.3z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2342.3,1088.4c-5-2.4-13.8,7.1-15.3-2.5
					c-0.7-4.6,15.6-10.3-0.8-14.6c-8.9-2.3-16.2,1.2-17.6,10c-1.3,8.1-6.2,12.7-16.3,11.9c-6.5-0.5-12-3.4-11.5-9.2
					c0.3-4-6.9-18.8,11.1-6.4c8.7,6,9.6-5.8,8.3-9.9c-7.8-25,11.3-22.2,29.1-21.2c0,0-0.1-0.1-0.1-0.1c-0.6,3.5-2.9,9.3-1.6,10
					c5.3,2.6,7.8-1.1,9.3-5C2349.7,1062.7,2341.9,1075.9,2342.3,1088.4z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2349.1,997.5c-1.3,0-2.6-0.2-3.8-0.8
					c-6.2-27.5-10.4-55-2.8-82.6c0.9-5.6,1.9-11.2,2.8-16.8c1.4,0.1,2.7,0.2,4.1,0.2C2349.4,930.9,2349.3,964.2,2349.1,997.5z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2342.3,1088.4c-0.4-12.5,7.4-25.6-5.3-37
					c0.8-0.9,1.2-1.9,1.2-3.1c-0.3-5.5-0.5-11-0.8-16.5c16.1-3.3,12.4,6.7,12.7,13.7c0.5,12.6,0.6,25.1,0.8,37.7
					c-4.6,13.5,6.3,29.3-7.3,41.5C2343.1,1112.5,2342.7,1100.4,2342.3,1088.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2279.8,931.3c-0.5-20.2-0.9-40.4-1.4-60.6
					c18-2.9,19.8,8.8,23.6,18.8c-10.2,8.5-9.3,19.1-7.6,30C2291.6,925.3,2286.7,929.2,2279.8,931.3z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2307.5,1123.9c-4.5-0.1-9.1-0.2-13.6-0.2
					c-15-11.2-33.2-3.7-49.6-6.8C2281.1,1094.4,2286.7,1095.1,2307.5,1123.9z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2181.4,1128.1c-2.4,1.7-4.8,3.4-7.2,5.1
					c-0.4-18-0.9-36-1.3-54c-0.1-3.8-0.2-7.6-0.3-11.4c-0.1-8.3-0.2-16.6-0.3-25c1.7-3.7,3-12.3,8.2-2.3c3.1,6,1,13.8,1.3,20.8
					C2181.7,1083.6,2181.5,1105.8,2181.4,1128.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2244.3,1116.9c16.4,3.1,34.6-4.4,49.6,6.8
					c0,0-0.1,0.2-0.1,0.2c-21.2-3.2-40,2.3-57.9,10.7c-0.1-3.5-0.3-7-0.4-10.5C2238.4,1121.7,2241.3,1119.3,2244.3,1116.9z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2533.6,1140c-5.4,2-10.8,3.9-16.2,5.9
					c1.9-11.1,3.9-22.2,5.8-33.4C2525.8,1122.6,2534.1,1130.5,2533.6,1140z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2235.4,1124.1c0.1,3.5,0.3,7,0.4,10.5
					c-4.1-0.2-8.2-0.5-12.2-0.7c-2.8-4.8-7.2-5.1-12.4-3.6c-1.2-1-2.3-2-3.4-3.1C2215.8,1119.8,2225.7,1122.5,2235.4,1124.1z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2337.4,1031.7c0.3,5.5,0.5,11,0.7,16.5
					c-3-0.6-5.9-1.2-8.9-1.8c0,0,0.1,0.1,0.1,0.1C2328.3,1040,2332.4,1035.7,2337.4,1031.7z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2330.6,919.1c0.9-1.8,2.4-3.1,4.6-3.8
					C2333.7,916.5,2332.2,917.8,2330.6,919.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2222.8,1075.5c-3.1-2-6.1-4.1-9.2-6.1
					C2216.6,1071.4,2219.7,1073.5,2222.8,1075.5z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2225.2,2334.2c13.9-12.1,16.6-31,39.7-41.4
					c-21.7-8.8-39.7-0.6-56.7-3.4c-8.5-9.6-0.2-14.3,8-12.1c19.7,5.3,32-5.6,53.2-9.8c-24.4-16.7-44.1-30.2-63.7-43.6
					c-2.4-6.8-4.9-13.7-7.3-20.5c0-3.1,0.1-6.2,0.1-9.4c7.2,0,14.3,0,21.5,0.1c7.6,8.6,18.4,3.9,27.7,5.4
					c26.5,2.6,49.7,20.6,78.3,13.6c6.2-0.2,11.9-1.3,15.1-6.5c4.9,0.1,9.7,0.2,14.6,0.4c13.7-4.7,9.9-22.1,25.4-25.5
					c2.9,2.9,5.8,5.9,8.8,8.8c-2.8,5.3-4,12.3-8.8,15.5c-21.8,14.2-6.6,17.2,8.3,20.7c0,1.9-0.2,3.7-0.5,5.5
					c-0.3,2.6-0.6,5.2-0.9,7.9c-10.2-0.9-20.4-1.8-30.6-2.6c-28.7,12.3-31.5,23.7-10.6,42.3c0.1,1.9,0.3,3.8,0.6,5.7
					c-9.4,0.2-14.9,3.8-14.9,12.2c-3.2,2.2-6.3,4.4-9.5,6.6c-8.6-4.8-17.2-9.7-25.8-14.5c-0.1-7.8-0.1-15.5-0.2-23.3
					c-0.9-16.3,21.5-19.4,25.9-33.2c-26.5-8.2-53.3-8.9-82.7-10.7c8.3,16.9,26.3,20.8,35.8,31.8c4.6,12.1-11,29.4,13.6,36.4
					c-24.1,17.7-36.4,44.4-62.9,60.3C2226.2,2345.2,2225.7,2339.7,2225.2,2334.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2071.2,2477.7c1.9,0.4,3.8,0.6,5.7,0.4
					c0.1,4.2,0.1,8.5,0.2,12.7c-12.1,10.2-6,24.2-9.5,36.2c-6.3,4.9-4.5,11.4-4.7,17.5l-0.3,0.3c-1.7-0.1-3.4-0.3-5.1-0.5
					c-13-9.6-16,2.7-22.5,7.1c-4.8-4.4-9.6-8.8-14.4-13.2c3.8-14.5-7-23.8-17.9-32.1c-17.3-13.1-35.9-24.9-49.7-41.1
					c5.5,0,11,0.1,16.5,0.1c20.5,12.7,41,25.5,66.2,41.2c-2.3-12.6-3.4-19.3-4.9-26.1c-15.3-69-30.8-138.1-46.2-207.1
					c5-0.1,10-0.2,15-0.3c8.5,32.2,16.7,64.3,25.6,96.4c4.6,16.5,1.9,34.8,16.9,48.9c11.4,5.4,2.4,18.3,12.7,24.1
					c4.5,17.2-2.7,33.8-4.3,54.1C2059.5,2488.2,2065.3,2483,2071.2,2477.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2109.9,2135.7c8.2,2,16.3,5.7,24.6,0.9
					c-2.1,11.1-5.2,22.6,14.4,24.8c12.4,15.4,34.4,20.2,49.5,32.6c0,3.1-0.1,6.3-0.1,9.4c-3.1,0.5-6.2,1-9.3,1.5
					c-12.9-0.5-25.9-0.7-38.8-1.7c-54.5-4.4-54.5-4.5-59.4,40.9c-10.2-9.3-4.2-20.5-5.7-30.9c-3.4-24.5,15.4-46.8,8.1-71.7
					C2098.7,2139.6,2104.3,2137.7,2109.9,2135.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2057.5,2114.2c-28.4-17.7-46.4-45.7-80-58.5
					c-6.2-2.4-11.1-7-18.7-5.2c-0.2-8.5-23.5-18.3,1.4-25.5c34.3,24.7,68.7,49.5,103,74.2c5.2,2.1,11,5.5,8.4,10.1
					C2069.9,2112.3,2062.4,2112.7,2057.5,2114.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2090.8,2244.1c2.1,7-4.9,16.6,8.6,20.2
					c-7.4,5.4-14.8,10.8-24.8,18.2c-7.5-42.3,7.6-83.1,4.8-124.3c-0.5-6.6,1.6-13.7,8.2-18.6c0.6-0.4,2.1-0.3,3,0
					c1,0.4,1.8,1.3,2.7,1.9c7.3,24.9-11.5,47.2-8.1,71.7C2086.6,2223.6,2080.6,2234.8,2090.8,2244.1z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2120.2,1998.2c2.4,15.8,1.9,30.9-14.1,42
					c-16.1-21.3,3.1-41.9,0.5-63.1c-1.7-14.7,0.7-29.8,1.3-44.7c1.9-0.5,3.9-1,5.8-1.5C2118.6,1953.3,2106.4,1976.4,2120.2,1998.2z"
					/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1979.7,1977.8
					c16.3-11.7,32.7-23.4,48.9-35.1c9.2,7.7,7.4,17.3,6.9,26.7c-26.5-6.5-29,20.1-47.7,24.4c-2.7-1.3-5.4-2.7-8.1-4
					C1979.6,1985.8,1979.6,1981.8,1979.7,1977.8z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2105.3,2075.7c0.9,3.9,1.8,7.8,2.8,11.8
					c-5.5,6.8-11,13.7-22.6,28c3.3-27.1,5.6-45.6,8-65.1C2106.8,2056.6,2094.7,2069.8,2105.3,2075.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1900.5,2252.8c-0.4,6.4,2.1,13.3-4.6,18.5
					c-10.1-3.9-8.1-11.8-9-18.7C1891.4,2252.7,1896,2252.7,1900.5,2252.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1971.8,2182.2c-6.1-1.3-14.2,1.6-17.5-5.7
					c-0.4-1,1.5-3.9,2.8-4.1c8.6-1.2,11.4,4.7,15,9.6L1971.8,2182.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1957.7,2157.4c0.1-2,0.4-3.9,1.1-5.8
					c5,0,10,0,15-0.1c4.1,1,5.4,3.3,4.3,6.7C1971.3,2160.2,1964.2,2167.5,1957.7,2157.4z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1972.1,2182c1.7,2.2,3.4,4.3,5.1,6.5
					c-0.9,0.5-1.7,1-2.6,1.5c-0.9-2.6-1.9-5.2-2.8-7.8C1971.8,2182.2,1972.1,2182,1972.1,2182z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2180.8,1392c-20.6-1-13.4-14.8-15.7-24.2
					c3.4-6.7-2.4-22.2,6.9-19.7c27.9,7.6,11.3-10.3,15.1-16c0.4-10.6,0.8-21.2,1.2-31.9c19.3-12.9,47.7-21.4,33.4-50.7
					c4.4-0.2,8.7-0.3,13.1-0.5c2.9,3.5,5.8,7.2,9,0.3l0.1-0.1c21.1,6.5,35.4,15.7,21.1,36.5c-4.6,0.3-9.2,0.6-13.9,0.9
					c-49.2,15.5-53.1,21.8-35.5,56.8c-8.6,1.8-8.3,6.5-5.3,12.2c-3.8,3.1-7.6,6.2-11.4,9.3C2175.3,1365.2,2187.4,1383.3,2180.8,1392
					z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2188.3,1300.2c-0.4,10.6-0.8,21.2-1.2,31.9
					c-15.2,0.6-22.7-5.3-21.2-18.5c-5-10.3-14.8-19.1-13.5-31.3c6.9-9.7,11.2-18.6,3.3-30.8c-10.7-16.6,3.6-17.3,17.8-16.4
					c-0.1,24.6-0.2,49.2-0.2,73.8c6.6-25.1,6.8-50.3,6.7-75.4c0-11.1-0.8-22.3,14.6-27.1l-0.2,0.1c-0.1,4-0.1,8-0.2,12
					c0,1.7,0.1,3.5,0.4,5.2C2178,1248.1,2190.4,1274.7,2188.3,1300.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2151.7,1524.3c-0.9,1.9-1,3.8-0.2,5.7
					c-14.4,11.9-4.4,28.1-9.3,41.7c-3.5,0.6-7.1,1.2-10.6,1.8c-0.8-11.8-0.4-23.4,1.4-35.3c2.3-15.2-8.5-4.6-14.1-3.7
					c-7.1-18.1-21.6-3.7-32.3-5.9c2.7-5.9,5.4-11.8,8.1-17.8c11,0.8,20.7,0.2,20.7-12.2c0.8-3.3,2.6-5.5,7.2-4.6
					c0.7,4.4,0.9,8.9,6.8,11.1C2145.2,1504.4,2142,1519.8,2151.7,1524.3z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2152.5,1181.3c-0.7,3.9-1.3,7.8-2,11.7
					c-11-0.7-10.6,8.6-17,14.5c-5.5-13.3-11.7-25.5-15.3-38.2c-3.3-11.4-10.3-14.5-22.5-12c-0.2-4.5-0.3-8.9-0.5-13.4
					c11.8,0.2,23.7,0.4,35.5,0.5c2.9,7.3,20.9,9.6,10.7,20.6c-2.7,2.9-11.6,2-6.8,9C2138.5,1179.5,2145.6,1180.5,2152.5,1181.3z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2173.5,1235.2c-14.2-0.9-28.5-0.2-17.8,16.4
					c7.9,12.2,3.6,21.1-3.3,30.8c-14-29.5-4.7-58.5,0.4-87.5c4.5-0.4,9-0.9,13.5-1.3c2.1,12.5-7.2,26.5,7.3,37.5
					C2173.7,1232.4,2173.7,1233.8,2173.5,1235.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2165.8,1313.6c-1.4,13.2,6,19.1,21.2,18.5
					c-3.8,5.8,12.8,23.7-15.1,16c-9.3-2.6-3.6,13-6.9,19.7c-5.7,3-11.4,6-18.7,9.8c1.6-10.5,3-18.9,4.3-27.4l1.2-1.1
					C2166.7,1340.3,2150.2,1322.2,2165.8,1313.6z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2213.4,1527.4c-10.8-2.3-20.7-5.9-27.2-14.3
					c0.1-10.2,0.2-20.4,0.2-30.6c2.5,0.1,5.1,0.3,7.6,0.4c0.7,19.6,24.1,23.4,35,36C2227,1525.8,2214.3,1518.8,2213.4,1527.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2052.1,1582.5c0.3-10.8-4.3-22.7,8.3-30.9
					l3-0.3c8.6,0.7,17.2,1.4,25.8,2.2c-11.5,10.4-18.8,24.6-36.5,29.6L2052.1,1582.5z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2178.3,1428.1c1.5,5.5,3.1,11.1,4.6,16.6
					c-9.6-0.5-24.3,6.5-27.1-5C2152.8,1427.5,2168.5,1429.3,2178.3,1428.1z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2135.3,1285.7c-1.8,0.6-3.6,1.2-5.4,1.9
					c-0.4-1.5-1.8-3.5-1.1-4.3c0.9-1.2,3.4-1.6,5.1-2.4C2134.3,1282.4,2134.8,1284,2135.3,1285.7z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2122.5,1494c2.2-0.6,4.4-1.2,6.6-1.7
					c0.1,4.3,0.1,8.5,0.2,12.8C2123.4,1503,2123.2,1498.4,2122.5,1494z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2150.8,1350.3c-2.9-1-5.8-2.1-8.7-3.1
					c0.7-0.7,1.8-2.1,2-2c2.7,1.2,5.3,2.6,7.9,3.9C2151.9,1349.2,2150.8,1350.3,2150.8,1350.3z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2194.1,1482.9c-2.6-0.1-5.1-0.2-7.6-0.4
					c0-1.7,0-3.4,0.1-5.1c2.4-0.2,4.9-0.4,7.3-0.7c0.7,0.7,2,1.4,1.9,2C2195.5,1480.1,2194.7,1481.5,2194.1,1482.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2117.7,832c-11.5-8.9-5.5-20.2-6.3-30.5
					c16.5,2.4,22.3-5.9,23.8-19.7c2.4,18.5,17.2,26.9,31.7,23.5c15.1-3.5,6.2-16,0.2-24.7c5-2.4,10.1-4.8,15.1-7.2
					c3.6,31.4-10,63.5,7.5,94.3c0,0,1.2-0.4,1.2-0.4c-7.6,12.3-18.3,2-27.5,2.3c-7.1-8.6-11.6-18-11.4-28.6
					c4.3-16.1-14.5-10-20.9-15.8C2119.3,816.9,2119,825.2,2117.7,832z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2172.3,1042.8c0.1,8.3,0.2,16.6,0.3,25
					c-19.2,2.3-29.2-24.2-53.6-12.5c-4.6,2.2-6.4-15.9-7.4-25.1c18.3,13.8-0.6-17.2,21.9-12.3
					C2154.5,1022.5,2151.8,1044.8,2172.3,1042.8z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2152.5,1181.3c-6.9-0.8-13.9-1.8-17.8-7.4
					c-4.8-6.9,4.1-6,6.8-9c10.2-11-7.8-13.3-10.7-20.6c-9.4-25.2,10.4-11.8,20.6-11.1C2176.4,1149.3,2158.8,1165.3,2152.5,1181.3z"
					/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2172.9,1079.2c0.4,18,0.8,36,1.3,54
					c-7.2-6.4-14.4-12.7-21.6-19.1C2152.9,1099.7,2149.9,1083.8,2172.9,1079.2z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2149.5,999.5c0.1,5.1-4.4,6.6-8.8,6.5
					c-4.1-0.2-10-1.3-11.8-3.7c-3-4,0.7-6.8,6.1-7C2140.4,995.1,2146.1,994.3,2149.5,999.5z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2216.7,1026.5c-0.6,3.9-1.2,7.9-1.8,11.8
					c-7.7-1.2-16.4,1.5-23.8-5.2C2195.8,1020.9,2208.2,1028.3,2216.7,1026.5z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2579.5,2668.8c25.8,7.9,5.2,31.7,21.2,42.9
					c-1.8,7.7,3,18.2-17,12.6c-11.4-3.2-33.7-11.4-40.2,9.9c-8.3,22-23.6,8.5-37.1,4.8c0.7-25.8-20.2-25.1-41-24.5
					c-0.5-5.9-25.3-9.4-2.8-17.8c14.5-5.4,28.7-11.6,43-17.5c6.2,20.2,23.6,25.7,45.4,25.3c10.5,3.5,21.4,11.9,32,3.1
					c4.6-3.9,2.8-11.3-4.9-15.1C2582.2,2684.8,2568.2,2676,2579.5,2668.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2120.8,2558.1c19.7,15.7,38.8,4.4,59.8,3.2
					c41.6-2.4,81.8-11.9,123.5-12.4c13.9-0.2,27.7-3.2,41.6-4.9c28,1.5,18.9-14.9,18.3-27.7c-0.5-11-14.2-25.2,8.5-31.4
					c4.6,27.6-0.1,56.3,16.4,82.4c-0.3,2.7-0.6,5.4-0.9,8c-2.3-1-4.5-1-6.7,0.1c-14.3-17.4-31.1-20.4-50.8-7.2
					c-67.7,5.7-136.4,5.7-202.9,19.5c-9.1,2.5-20.1,10.3-23-5.9C2109.8,2573.9,2100.5,2558.6,2120.8,2558.1z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2381.5,2400.5c16.2,8.6,17.5,22.9,20.5,36.7
					c-6.3,1.9-12.5,3.8-18.8,5.6c-2.2-4.9-3-15-10.8-10.8c-10.9,5.9-6.7,16.5-0.5,25.2c-1.9,1.2-3.9,2.4-5.8,3.6
					c-8.4-6.8-17.2-0.8-25.7-0.8c-3.1-16.1-1.8-32.1,1.8-47.9C2352.8,2402.5,2368.9,2405.5,2381.5,2400.5z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2062.7,2595.5c-2.2,9.7-12,9.7-17.4,15.3
					c-2.6-0.2-5.1-0.5-7.7-0.7c-0.1-6.6,3.2-14-2.9-19.3c-0.3-12.6,9.8-25.4,0.9-37.8c0.3-1.1,0.6-2.2,0.9-3.3
					c6-3.8,10.4-15.6,19.1-4.9C2047,2563.9,2052.4,2580.2,2062.7,2595.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2347.3,2285.2c-0.3-1.9-0.5-3.8-0.6-5.7
					c0,0-0.2,0.1-0.2,0.1c4.7,0.2,9.5,0.4,14.2,0.6c1.5,2.1,3.1,4.3,4.6,6.4c1.5-5.2,3-10.4,4.5-15.6c12.2-0.3,19.1,3.5,15.7,15
					c-7.9,11.3-18.8,21.4-18.7,35.8c-14.8-0.9-12.9-10.4-13.7-18.6C2353.6,2296.7,2354.3,2290.2,2347.3,2285.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2340.8,2378.1c-0.2-6.1-0.4-12.2-0.7-18.3
					c3-2.8,5.9-5.6,8.9-8.4C2349.1,2360.6,2364.4,2372.1,2340.8,2378.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2520.8,2497.9c2.9-3.5,5.9-6.9,8.8-10.4
					c2.6,0.1,5.2,0.1,7.7,0.2c3.4,6.9,6.7,13.7,11.5,23.5C2537.3,2505.7,2529,2501.8,2520.8,2497.9z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2347.3,2285.2c7,4.9,6.3,11.5,5.7,18.1
					c-6.9-2-13.7-3.9-20.6-5.9C2332.4,2289,2337.9,2285.4,2347.3,2285.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2062.9,2544.5c1,0.5,2,1.1,3,1.6
					c-1.1-0.4-2.2-0.9-3.3-1.3C2062.6,2544.8,2062.9,2544.5,2062.9,2544.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2182.7,774.6c-4.9,2.3-9.8,4.6-14.7,7
					c-12.9-10.4-8.1-22.2,12.2-30c5.9,1.1,8.5-2,10.5-5.9c15.3,12.1,34.7-8.6,51.9,6.8c-9.8,7-21.4,8.9-32.9,10.9
					c-9.1-0.5-13.7,3-13.2,10.8c-1.2,8.8,4.5,14.1,13.1,18c0,5.8,0,11.6,0.1,17.4c-4.8-0.2-9.5-0.4-14.3-0.6
					c-1.7-0.3-3.5-0.5-5.2-0.5C2186.2,797.4,2196.3,784.4,2182.7,774.6z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2190.4,743.6c-2.1,4-4.7,7.2-10.8,6.1
					c3.8-32.5,4.4-65.1,0.3-97.8c0.5-2.2,1-4.5,1.5-6.7c16,21.1,3.8,44,7,66C2190.1,721.9,2189.9,732.8,2190.4,743.6z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2105,711c-0.9-1.8-0.9-3.6,0-5.4
					c0.8,1.3,1.8,2.6,2.4,4C2107.5,709.8,2105.9,710.5,2105,711z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2034.6,2554.8c13.5,11.3,3.8,25.2,6.8,37.6
					c-19.3,45.4-11.6,94.2-21.1,140.9c-3.1,15.4-16.2,32.3,6.4,45.7c-0.4,6.2-0.9,12.4-1.3,18.6c-1.9-0.3-3.9-0.6-5.8-0.9
					c-0.2-9-4.6-15.7-15.1-18.7c-10.7-6.7,0.3-12.8,0.3-19.2c1.8-29,3.6-58,5.5-89.8c-25,14.9-35.9,36.8-53.4,53.8
					c-12.3,2-25.1-14.4-37.1-0.1c-5.4-0.1-10.9-0.2-16.3-0.3c-3.7-0.1-7.4-0.2-11-0.2c-2.7-2.3-5.5-4.7-8.2-7
					c5.3-7.8,10.5-15.7,15.8-23.5c6.2,0.1,11.9-0.6,14.7-6.1c3.1,0.4,6.3,0.8,9.4,1.2c6.9,8,7.1,32.8,26.3,13.8
					c14.9-14.8,30.2-29.6,46-43.6c20.2-17.8,11.9-25.4-11.5-30.5c7-12.1,34.3,4,34.6-17.6c-0.6-19.6-15.9-8.9-25.8-9.4
					c-1.1-1.9-2.1-3.8-3.2-5.8l0.2-1.3C1999.1,2574.7,2023.7,2570.5,2034.6,2554.8z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2388,2575.5c0.3-2.7,0.6-5.4,0.9-8
					c21,2.6,28.5,27,52.8,24.1c5.8,10.4-1.2,18.4-6.4,26.7c-4.4,4.8-0.2,5.7,4.2,6.5c-8.2,9.6-16.4,19.2-24.6,28.8
					c-17.9-9.1-22.6,4-26.4,12.9c-5.5,13-5.2,26.4,13.1,34c-9.7,16.1-19.1,32.1,7.3,44.5c0.1,1.3,0.1,2.5,0,3.8
					c-6.2,9.8-20.9,26.6-26.2,15.8c-7.6-15.6-10.9-7.1-18-4.4c-1.9,1.8-3.8,3.7-5.6,5.5c-17-2.1-25,5-26.1,18.8
					c-7.9,1.8-18-1.9-23.5,6.1c-9,8.7-18,17.5-26.9,26.2c-1.7-8.2-10.7-8.3-17.1-11.2c5.6-9.4,11.2-18.8,16.9-28.2
					c2.1-2,4.2-4.1,6.3-6.1c8.4-6.5,13.7-14.4,14.2-24.3c19.1-17,41.1-32.3,45.6-57.7c8.4-0.8,11-6.1,13-12
					c15.5-3.3,12.8-14.4,13-23.7c0.4-20.5,0.1-41,0-61.5c19.8,13.8,6.7,34.9,15.8,51.5c39.5-34.8,39.5-34.8-0.5-65.3
					C2388.8,2577.5,2388.5,2576.4,2388,2575.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2515.4,2655.6c17.9,16,30.7,38.3,62.6,36.9
					c7.7,3.8,9.5,11.3,4.9,15.1c-10.5,8.8-21.5,0.4-32-3.1c-9-16.8-31.4-22.2-43-36.8C2508.2,2662.6,2509.2,2657.9,2515.4,2655.6z"
					/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2451.5,2624.5c-2.4-0.3-4.8-0.5-7.2-0.8
					c-3-1.8-6.1-3.6-9.1-5.5c5.2-8.3,12.2-16.3,6.4-26.7c0,0,1.5,1.8,1.5,1.8c7.4-3.7,14.8-7.5,22.2-11.2l0.3,0.3
					c8.2,8.5,23.7,12.7,20.3,27.9C2481.9,2628.8,2459.1,2613.3,2451.5,2624.5z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2466,2564.5c1.6-5.3,2.4-11.3,11.1-11.8
					c10.1-0.8,11.6-6,8.8-13.2c0,0-0.4,0.4-0.4,0.4c5.3-1.8,10.6-3.6,15.9-5.4c10.7,28.4-17.6,35.3-35.7,47.9c0,0-0.4-0.3-0.3-0.3
					c0.2-3.9,0.4-7.8,0.6-11.7C2465.9,2568.5,2465.9,2566.5,2466,2564.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2498.2,2519.8c3.9-3.6,7.7-7.2,11.6-10.7
					c6.1,4.8,12.3,9.6,19.6,15.3C2515.1,2529.9,2507.3,2522.4,2498.2,2519.8z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2501.4,2534.6c-5.3,1.8-10.6,3.6-15.9,5.4
					c-3.8-10.1,6.9-13.4,12.2-19.2C2498.9,2525.3,2500.2,2529.9,2501.4,2534.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2512.1,2508.4c3-3.5,6-7.1,9-10.6
					C2522.8,2504.3,2523.5,2510.1,2512.1,2508.4z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2146.8,635c-6,5.6-13.2,15.1-21.1,3.7
					c-7-10-22.6-22.1-7.2-34c12.6-9.7,9.5-12.9,0.9-24.9c-14.5-20.2-12.6-44.6-0.4-66.8c0,0-0.3-0.1-0.3-0.1
					c3.4,2.1,6.8,4.2,10.2,6.3c6.2,2.2,12.3,4.4,18.5,6.7c-8,12.3-41,19.1-9.8,41c9.2-13.2,22-24.3,23.4-40.7c0,0,0.5-0.4,0.5-0.4
					c6.4-0.1,12.8-0.1,19.1-0.2c2.1,26.2,5.1,52.5-2.1,78.4c-5,0.4-10,0.8-15,1.1C2149.9,611.7,2149.9,624,2146.8,635z M2137,614.2
					c-2-0.7-4.3-2.4-5.9-2c-1.4,0.4-1.9,3-2.8,4.6c2,0.7,4.3,2.4,5.8,2C2135.6,618.4,2136.1,615.8,2137,614.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2140.7,411.6c-0.1-2.1-0.2-4.2-0.3-6.3
					c5-25.6,30.6-0.6,41.6-11.6c-0.5,24.4-1,48.8-1.4,73.2c-6.9-0.3-13.9-0.5-20.8-0.8c-5.5-4.2-10.2-10.2-19.1-6.1
					c-0.4-5.9-0.8-11.8-1.2-17.7c2.6-3.9,5.3-7.7,7.9-11.6c-2.2-13.7,15.1-10.3,23.5-18.5C2159,412,2149.8,411.8,2140.7,411.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2136,488.8c13.5,2.4,14.2,16.3,25.7,20.6
					c-0.1,5.5-0.1,11-0.2,16.5c0,0-0.5,0.4-0.5,0.4c-4.5-0.1-9.1-0.2-13.6-0.3c-6.2-2.2-12.3-4.4-18.5-6.7
					c-3.4-2.1-6.8-4.2-10.2-6.3c0,0,0.3,0.1,0.3,0.1C2120.2,502.7,2115.1,489,2136,488.8z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2178.6,604.1c7.2-25.9,4.2-52.2,2.1-78.4
					c0.4-4,0.8-8.1,1.3-12.1c17.1,33.9,2.5,68.8,7.1,103.1C2176.6,617.8,2177.3,611.1,2178.6,604.1z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2146.8,635c3.1-11,3.1-23.3,16.8-29.8
					C2170.5,620.4,2169.3,632.1,2146.8,635z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1990.2,733.6c8.7-1.8,17.4-3.6,26.1-5.4
					c1.7,7.9,1.3,14.8-9.4,16.2C1997.9,745.6,1991.4,743.1,1990.2,733.6z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2362.8,1660.5c5.8,2.8,6.8,7.6,7.1,12.6
					c3.7,12.5-8.2,17.7-15.8,25c-36.3,11.5-40.3,19-27.5,51.1c-7.3,16.1-23.7,18.7-41.2,19.8c-11.3-6.3-19.1-7-31.6,1.8
					c-12.6,8.9-17.5,28.3-40.5,25.4c-2.3-24.2,31.3-26.2,37.4-44c-30.1-22.2-58.5-45.8-93.8-61.6c0.2-9.4,0.3-18.7,0.5-28.1
					c12.6,2.2,18.8-7.5,28.6-10.6c2.9,1.9,5.7,3.8,8.6,5.7c-18.5,11.4-19.3,21.4,2.2,32.5c19.9,10.3,38.2,22.9,57.3,34.5
					c7.4,4.5,9,19.6,22.6,12.1c9.2-5.1,13.8-15,13.2-25.5c-0.5-7.9-1.2-15.7-1.9-23.6c17.4,17.8,17.4,17.8,25.7,5.6
					c18.9-0.4,30.8-5.4,22-24.3c-5.2-11.1-10-22.4-15-33.5c3.1-0.1,6.2-0.2,9.2-0.3c10.6,7.3,12.8,18.5,19.4,27.6
					C2350.3,1663.9,2358.2,1661.3,2362.8,1660.5z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2342.4,1305.7
					c-22.2,6.9-14.9,32.6-35.2,40.7c-40.6-14.8-14.9,15.6-22.1,23.6c-5.8,0.1-11.5,0.2-17.3,0.2c-4.9-9.8,1.4-22.1-10-30.5
					c18.9-4.9,30.7-17.2,42.7-29.3c15.6,1.8,29-6.5,25.9-16.8c-5.9-19.6,12.1-31.4,16.2-47.8
					C2342.5,1265.7,2342.4,1285.7,2342.4,1305.7z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2267.8,1370.2c5.8-0.1,11.5-0.2,17.3-0.2
					c13,21.5-8.2,27.5-22.4,36.7c-13.6-1.6-19.8,12.9-33.7,10.8c21.6-30.2-12.3-38.2-30.1-52.6c3.8-3.1,7.6-6.2,11.4-9.3
					c13.8,7.1,26.8,14.3,38.4,24.6C2262.7,1392.5,2267,1381.4,2267.8,1370.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2313.8,1693.2c-8.3,12.2-8.3,12.2-25.7-5.6
					c-10.2-27.3,7.3-58.3-17.5-83.7c-0.2-2.1-0.3-4.2-0.5-6.3c0,0-1.1-0.6-1.1-0.6c23.2-5.1,29.9,3.2,30.8,22.7
					c0.7,15.1-11.3,34.1,12.5,45.5C2312.8,1674.5,2313.3,1683.9,2313.8,1693.2z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2271.2,1526.6c-1.3-28.5,2.8-57.6-13.7-84.6
					c-0.3-4.3-0.5-8.6-0.8-12.9c0,0-1.1-0.4-1.1-0.4c18.1-2.2,30.7,0.7,29,20.1c-0.9,9.8,0.4,19.8,0.8,29.7
					c0.7,8.1,1.5,16.2,2.2,24.3c-1,5.4-2.1,10.8-3.1,16.2c-3.1,2.4-6.2,4.8-9.3,7.2c-1.6,1.5-3.1,3-4.7,4.5l-1.6-2.3L2271.2,1526.6z
					"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2334.4,1567.3c18.2,12.7,30.1,33,53.3,39.9
					c0,0-0.3-0.5-0.3-0.5c-0.5,4.4-1,8.9-1.5,13.3c-1.3,2.8-2.7,5.7-4,8.5c-24.1-5.7-26.3-34.4-49.1-41.6c0,0,0.3,0.9,0.3,0.9
					c-2.2-2.6-4.4-5.2-6.6-7.8C2324.7,1573.4,2321.1,1565.7,2334.4,1567.3z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2270.4,1530.7c1.6-1.5,3.1-3,4.7-4.5
					c3.3,0.3,6.6,0.6,9.9,0.8c1.7,2.6,4.2,5.1,5,7.9c2.4,9.2,9.6,23-6.6,24.2c-16.2,1.2-7.6-12.6-8.8-20.3
					C2274.2,1536.1,2271.9,1533.5,2270.4,1530.7z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2287.5,1502.8c-0.7-8.1-1.5-16.2-2.2-24.3
					c7.7,4.1,19.5,5.1,20,14.9C2305.8,1503.7,2295.3,1502.2,2287.5,1502.8z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2237.1,1523.5c7.3,1.9,15.3,2.6,19.8,9.1
					c-4.9,4.1-9.8,8.1-14.7,12.2c-4.4-2.7-8.8-5.3-13.2-8C2231.7,1532.3,2234.4,1527.9,2237.1,1523.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2335.2,1402.9c-6.2-3.6-13.9-6-14-13.9
					c2.2-1.9,4.3-3.8,6.5-5.7C2336.2,1388.2,2333,1396.3,2335.2,1402.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2263.5,1563.2c-4.6-0.4-9.1-0.7-13.7-1.1
					c0,0-1.8,0-1.8,0s-1.4-1-1.3-0.9c0.2-4.8,1.3-9.2,8.7-8.5C2262.5,1553.5,2262.6,1558.8,2263.5,1563.2z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2346.7,1535.5c-1.5-1.2-3-2.4-4.6-3.6
					c1.6-1.4,3.3-2.7,4.9-4.1C2346.9,1530.4,2346.8,1533,2346.7,1535.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2285,1527.1c-3.3-0.3-6.6-0.6-9.9-0.8
					c3.1-2.4,6.2-4.8,9.3-7.2C2284.6,1521.7,2284.8,1524.4,2285,1527.1z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2296.7,2007.4c-3.3-19.7-15-19.8-29.8-9
					c-24.4,17.7-47.3,36.9-72.6,53.6c-13.1,8.6-24.4,22.5-45.2,19.1c22.4-26.1,51.4-46.7,80.8-66.6c37.5-25.5,38-25-4-47.5
					c-4-2.1-8.4-3.6-12.6-5.3c0.6-6.3-1.1-13.4,7.2-17.2c19.8-8.2,28.5,8.4,41.4,14.7c33.8,16.4,40.2,13.9,42.1-18.8
					c10.6,3.4,7.4,19.6,23.1,18.3c6.9,0.1,13.8,0.3,20.6,0.4c0.4,8.5,0.8,17,1.2,25.5l0.5-0.4c-14.2-0.4-35-8.5-40.3,4.5
					c-5.2,12.8,11.9,21.5,28.3,24c9.5,7.2,20.5,13.3,30.5,19.3c26.2,15.7,50.5,36.7,85.9,37.3c0,0-0.3-0.2-0.3-0.2
					c-3.9,2.1-4.1,4.5-0.6,7.2c0.2,3.7,0.3,7.4,0.5,11c0,1.8,0.1,3.6,0.1,5.4c-28.5-17.4-59.4-31.3-91-44.1
					c-2.2-1.1-4.5-2.3-6.7-3.4c0.3,2.4,0.6,4.7,0.8,7.1c18.1,36.2,36.2,72.4,54.3,108.5c20-5.3,22.9-22.2,35.1-31.8
					c7,27-6.5,52.7-28.3,53.9c0.5-0.8,0.9-1.7,1.4-2.5c-0.5,0.8-1,1.6-1.5,2.4c-4.5,0-9.1,0.1-13.6,0.1c-2-2.1-3.9-4.1-5.9-6.2
					c-11.9-36.5-27-72-51.1-104.4c-5.5-15.6-12-11.3-13.3,0.4c-1.7,15.8,0.4,31.9,0.8,47.9c-1.3,8.1,8.3,22.4-14.3,18.5
					c1.3-32.9-16.5-65.7-1.9-98.9c3.1-7-6.9-11.6-13.3-15.7C2302.3,2012,2299.5,2009.7,2296.7,2007.4z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2325.5,1894.4c2.7,12.8,5.6,25.6,2.5,38.6
					c-0.3,5.1-0.7,10.3-1,15.4c-15.8,1.3-12.5-14.8-23.1-18.2c4-36.2-3.4-72.2-3.7-108.4c4.4,0,8.7,0,13.1,0
					c5.2,2.5,10.5,5,15.7,7.4c-0.9,7.6-1.8,15.3-2.6,22.9C2304.6,1865.5,2304.6,1879.7,2325.5,1894.4z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2383.6,1938.4
					c-14.9,9.6-17.7,27.8-34.7,35.9c-0.4-8.5-0.8-17-1.2-25.5C2372.4,1926.1,2372.4,1926.1,2383.6,1938.4z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2313.2,1821.8c-4.4,0-8.7,0-13.1,0
					c-2.4-4.5-0.6-11.1-8.9-12.7c-0.2-2.3-0.5-4.6-0.7-6.9l-1.9-0.9c9.4,5.5,16.2-1.3,24-3.1
					C2312.9,1806,2313.1,1813.9,2313.2,1821.8z"/>
				<path fill="#F2F2F2" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2355,1876.8c0.2-1.8,0.2-3.6-0.2-5.3
					c0.8,1.2,1.7,2.4,2.1,3.7C2357,1875.6,2355.7,1876.3,2355,1876.8z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1969.5,2465c-5.5,0-11-0.1-16.5-0.1
					c-17.7-21.2-40.1-38.1-68-57.4c9.2,59.7,17.7,114.7,26.2,169.6c-3.4,0.4-6.8,0.7-10.3,1.1c-4.6-4.3-9.1-8.5-13.7-12.8
					c-3.4-25.7-7.1-51.4-10.2-77.2c-1.5-12.4,2.9-25.9-12.1-35.1c2.9-1.6,5.9-3.2,8.8-4.8c-25.9-17,2.2-35.1-2.9-52.6
					c-18.2-32.8,14.2-6.7,19.6-12c26.2,23.1,52.5,46.2,78.2,69.7C1971.2,2455.6,1969.4,2461.1,1969.5,2465z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1897.7,2687.9
					c-29.2-15.1-48.5-38.6-69.7-60.4c0.3-3,0.6-6.1,1-9.1c0,0,0.2-0.1,0.2-0.1c4.6-1.5,9.1-3,13.7-4.5c21.8,9.3,31.3,29,49,41.7
					C1904,2665.1,1899.6,2676.6,1897.7,2687.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1891.8,2655.4c1-9.8,2-19.6,3.1-29.5
					c3.8,0,7.7-0.1,11.5-0.1c3,15.9-5.1,32.9,8.2,47.7c0,4,0.1,8.1,0.1,12.1c-2.8,5.5-8.5,6.2-14.7,6.1c-1.7-0.9-2.4-2.2-2.3-3.9
					C1899.6,2676.6,1904,2665.1,1891.8,2655.4z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1901,2578.2c3.4-0.4,6.8-0.7,10.3-1.1
					c1.3,4,2.5,7.9,3.8,11.9c-4.9,4.5-14.6,11.3,2.5,10.2c10.6-0.7,17.6-2.6,17.2,9.3c-0.8,1.8-0.9,3.6-0.2,5.4
					c-0.1,1.9,0.2,3.8,1.1,5.7c-4.7,0.3-9.4,0.6-14.1,1l-2.1-1.7c1.2-1.2,2-2.5,2.4-4c-2-6.9-5.1-5.9-8.9-1.1
					C1889.4,2606.4,1904.2,2590.2,1901,2578.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1914.6,2673.5c-13.2-14.8-5.2-31.8-8.2-47.7
					c4.4-2.3,8.7-4.7,13.1-7c0,0,2.1,1.7,2.1,1.7c-0.1,13.5-0.2,26.9-0.2,40.4C1920.9,2665.8,1920.2,2670.7,1914.6,2673.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1913,2613.7c3.7-4.8,6.9-5.7,8.9,1.1
					C1918.9,2614.5,1915.9,2614.1,1913,2613.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2366,644.3c0.3,13.9,0.6,27.8,0.8,41.7
					c-2.3,0.4-4.6,0.6-7,0.5c-6.1-13.8,7.4-29.9-8.2-42.5c-6.2,1-13.6,8.2-18.2,0.8c-3.9-6.3-8.4-18.9,9.8-13.5
					c5.6,1.7,10.3,2,15.6,2.1C2361.2,637,2363.6,640.6,2366,644.3z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2351.7,643.9c15.6,12.6,2.1,28.8,8.2,42.5
					c-0.1,3.9-0.3,7.7-0.4,11.6c-2.7,14.4,3,29.7-5.3,45.7c-2-26.8-3.9-51.5-5.8-76.2C2349.5,659.6,2350.6,651.8,2351.7,643.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2410.6,662.4c-9.2,0-17.2-1.3-17.1-11.4
					C2401,653,2417.3,645.1,2410.6,662.4z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2133,1830.8c-20.4-23.5-40.9-46.9-62.9-72.2
					c-8.1,9.3-14.8,16.8-21.4,24.4c-2.3-4.2-4.6-8.3-6.9-12.5c3.2-29.2-4.9-56.1-24.6-80.6c-1.4-5.4,1.1-9.2,6.6-11.7
					c13.3,7.8,11.2,27.4,31.6,29.7c7.7,6.2,5,23.9,25,16.6c1.7,8.2-7.9,15.5,0.6,24.7c17.2,18.7,32.7,38.4,49.2,58.1
					c9.7-12.6-19.5-26.2,3.4-36.5c2.2,17.1,19.8,30.6,16.3,49C2137.2,1815.7,2142.5,1831.5,2133,1830.8z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2220.4,1934.3c-8.3,3.9-6.6,10.9-7.2,17.2
					c-22.9-15-52.6-21.1-71.5-41.5c-4.8-5.1-13.2-2.6-20-1c-0.1-6-0.2-12-0.3-18.1c0-6.1,0-12.3,0-18.4c5.2,0.3,10.4,0.6,15.6,0.9
					c-0.6,1.5-1,3.1-1.2,4.7c-1.7,19.8,8.8,21.7,26.4,13.4C2186.6,1900.8,2205.4,1915.6,2220.4,1934.3z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2121.4,1891c0.1,6,0.2,12,0.3,18.1
					c5.3,29.9,3.1,59.5-1.5,89.1c-13.8-21.8-1.6-45-6.4-67.2C2106.4,1916.1,2099.5,1901.4,2121.4,1891z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2137,1873.5c-5.2-0.3-10.4-0.6-15.6-0.9
					c-14.3-10.5-3.8-23.6-5.8-35.4c11.6-15.6,1.3-33.8,6.9-50.2c0,0,1.4-1.4,1.4-1.4l2.2,0.1c8.5,18.2-4.5,38.4,8.4,56.2
					c0.5,1.9,1,3.9,1.5,5.8c0.1,8.5,0.2,17,0.4,25.6L2137,1873.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2013.8,1903.9c-6.2-4.3-5.7-10.1-5.6-15.8
					C2014.8,1892.2,2015,1897.9,2013.8,1903.9z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2301.3,835.7c-13.1-4.9-15.2-14.1-13.8-24.7
					c5.2,3.2,12.1,9.9,15.1,8.8c10.1-3.5,4.7-13.1,6.5-20.1c0.9-0.9,1.9-1.8,2.8-2.7c-1.1,0.8-2.2,1.5-3.3,2.3
					c-5.6,5.7-22.2-2.4-21,11.9c-5-0.2-10.1-0.4-15.1-0.6c0.6-11.9,1.2-23.8,1.8-35.8c17.9,4.7,37.7-0.5,54.9,10.2
					c6.2,3.9,11.7-3.7,15.3-8.8c16.8,0.6,10,16.4,9.2,16.7C2327.9,799.9,2327.3,829.2,2301.3,835.7z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2344.5,764c-13.8-13.5-34-0.9-55.5-13
					c37,0.7,67.5,1.3,98,1.9c-3.3,8.3-10.4,12.1-20.6,11.9C2359.1,764.6,2351.8,764.3,2344.5,764z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2344.3,770.4c-4.8-2.2-4.9-4.4,0.1-6.3
					C2344.4,766.1,2344.3,768.3,2344.3,770.4z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2321.5,868.3c-2.6-0.1-4.5-1.2-5.7-3.2
					C2321.1,861.6,2321.7,864.4,2321.5,868.3z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2403.6,2184.2c-4.6,2-9.2,4.1-13.8,6.1
					c-2.9-2.9-5.8-5.9-8.8-8.8c3.5-4.8,6.9-9.6,10.4-14.4c2.2-0.2,4.5-0.4,6.7-0.6c2,2.1,3.9,4.1,5.9,6.2
					C2403.9,2176.5,2403.8,2180.3,2403.6,2184.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2445.9,2209.4c8.6-2,13.2,1.1,14.4,8.5
					c-0.3,3.2-0.5,6.3-0.8,9.5C2444.8,2226.8,2445.6,2218,2445.9,2209.4z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2459.5,2227.3c0.3-3.1,0.5-6.3,0.8-9.5
					c0,0,0.8-0.2,0.8-0.2c2.1,5.3,4.1,10.6,6.1,16C2464.7,2231.5,2462.1,2229.4,2459.5,2227.3z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2487.9,2102.8c0.4,1.3,0.7,2.6,1.1,3.9
					c-2.5,0.1-4.9,0.3-7.4,0.4C2483.7,2105.7,2485.8,2104.2,2487.9,2102.8z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1960.8,1135.5c-2.3-4.1-4.6-8.2-6.9-12.2
					c7.7,1.6,19.5-3.7,22.3,5.2C1977.9,1133.9,1967.6,1135.2,1960.8,1135.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2111.2,343.6c0.4-15.7,0.7-31.3,1.1-47
					c0.3-0.9,0.7-1.9,1-2.8c0.9-2.7,3.8-5.7,5.6-2c5,10.4-2.5,21.3,13.7,32.8c16.9,12,14.3-3.6,20.9-3.9c2.8,0.3,5.6,0.6,8.3,0.8
					c-8.8,22.5-40.1,23.3-54.9,40.5c-2.8,3.3-13.3-0.2-15.7-7.4C2102.3,356.6,2111,355.6,2111.2,343.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2161.9,321.5c-2.8-0.3-5.6-0.6-8.3-0.8
					c-18.9-14.8-14.5-35.5-19.9-53.8c12.1-1,13.5,10.8,22.2,13.7c3.1,4.7,8.3,4.9,13.6,5c2.9,1.3,12.5,3.2,6,5.1
					C2154.9,296.9,2161.3,309.6,2161.9,321.5z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2022.6,343.2c4.2,0.8,8.4,1.6,12.6,2.4
					c-6.8,16.3,22.4,14.1,20.4,28c0,0,0.1-0.1,0.1-0.1C2013.1,372,2011.1,370.2,2022.6,343.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2055.5,373.6c2-13.9-27.2-11.6-20.4-28
					c18.5-0.1,36.9-0.2,55.4-0.2c0.2,3.1,0.4,6.1,0.7,9.2c2.5,7.2,12.9,10.7,15.7,7.4c14.9-17.2,46.2-17.9,54.9-40.5
					c-0.7-11.8-7.1-24.6,13.5-30.8c6.6-2-3-3.8-6-5.1c-3.7-14.8,5.8-15.3,18.6-12.5c3.4,18.7,3.4,37.3-0.1,55.8
					c-20.7,0.1-20,28.4-43.5,21.5c-2.6,7.5,0.4,8,5.8,9.3c12.3,3.1,27.2,10.6,22.7,21.2c-5.6,13.4-19.6-1.3-30.3-0.7
					c-9.9,0.6-20.3-3.5-30.5-5.5C2093.3,374.3,2074.4,374,2055.5,373.6z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2180.5,466.9c0.5-24.4,1-48.8,1.4-73.2
					c3-5,6-4.9,9,0.1c-0.5,20.8-0.7,41.7-1.6,62.5c-0.4,8.3,4,18.6-9.8,22.8C2179.9,475.1,2180.2,471,2180.5,466.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2225.8,365.2c1.9,18-11.6,11.7-22.3,9.9
					C2205.7,363.5,2216,364.8,2225.8,365.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1888.1,2343.8c-20-5.4-19.2-13.9-4.7-27.9
					c1.8,11.5,3.1,19.8,4.4,28.1C1887.7,2344,1888.1,2343.8,1888.1,2343.8z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1943.3,2301.8c-4.8,3.8-9.7,7.5-14.5,11.3
					c0.1-3.9,0.2-7.7,0.2-11.6l0.4,0.1C1934.1,2301.7,1938.7,2301.7,1943.3,2301.8z"/>
				<path fill="#C0C1C2" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1929.1,2301.5c-1.9-0.1-3.8-0.3-5.7-0.6
					c-0.6-2-1.3-4-1.9-6l-0.1,0.2c4.7,0.8,8-0.6,9.9-4.2c-0.6,3.6-1.2,7.1-1.8,10.7L1929.1,2301.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1921.5,2294.9c0.6,2,1.3,4,1.9,6
					c-3.9-0.1-7.8-0.3-11.7-0.4C1913.7,2297.1,1914.9,2292.6,1921.5,2294.9z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2265,1285.8c14.3-20.8,0-30-21.1-36.5
					c1.9-8.9,15-12.6,14.3-22.7c1.7-1,3.6-1.4,5.7-1.3c14.2,4.5,28.5,5.6,42.8,1.2c5.9-1.5,8.6-3.4,0.6-6.1l0.2,0.2
					c-1.8-5.1-4-6.1-6.6-0.5c-5.8-0.1-11.2-0.8-14.4-5.6c7-6.1,14-12.1,20.9-18.2c6.9,2.2,13.8,4.3,20.7,6.5
					c-3.4,7.8-10.6,16.6,7.4,18.4c-3,10.6-22.5,10-21.6,23.2l0.1-0.2c-14.7,11.4-9.2,31.7-25.2,42.6
					C2281,1286.4,2273,1286.1,2265,1285.8z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2328.2,1202.7c-6.9-2.2-13.8-4.3-20.7-6.5
					c11-10.9-11.3-41.5,28.6-35.4c2.6,2.1,5.2,4.3,7.8,6.4c-2.7,11.9-5.4,23.7-8.1,35.6C2333.2,1202.8,2330.7,1202.7,2328.2,1202.7z
					"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2265,1285.8c8,0.3,16,0.6,24,0.9
					c-5.5,9.6,9.6,10.5,10.4,17.4c-4.8,0.2-9.6,0.4-14.4,0.6c-11.9-1.6-24.9,3-35.6-5.4c0.6-4.2,1.1-8.5,1.7-12.7
					C2255.8,1286.3,2260.4,1286.1,2265,1285.8z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2285,1304.7c4.8-0.2,9.6-0.4,14.4-0.6
					c0.3,2.1,0.7,4.2,1,6.3c-12,12.1-23.8,24.4-42.7,29.3c-1-2.3-2.1-4.6-3.1-6.9C2266.2,1324.5,2268.2,1308.8,2285,1304.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2328.2,1202.7c2.5,0,5,0.1,7.5,0.1
					c11.9,6.3,1.9,12.2,0,18.3C2317.6,1219.3,2324.8,1210.4,2328.2,1202.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2314.1,1244.3c4,1.6,7.9,3.2,11.9,4.7
					c-0.9,1.3-1.9,2.5-2.8,3.8c-3-2.9-6-5.8-8.9-8.7C2314.2,1244.2,2314.1,1244.3,2314.1,1244.3z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2367.4,1620c1-2.6,1.9-5.3,2.9-7.9
					c8.4-1.2,6.5-7.1,7.3-11.8c12.8-5.6,2.2-16.1,6.4-23.5l0,0c11.8,14.1,9.3,27.7-0.8,41.1c-5,0.7-10,1.4-15.1,2.2
					C2368,1620.1,2367.4,1620,2367.4,1620z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2374.9,1557.4c0.8-5.6,1.6-11.2,2.4-16.9
					c11.9,11,4.2,24.3,6.6,36.4c0,0,0,0,0,0c-6.5-2.2-5.8-7.5-7.5-11.9C2376,1562.4,2375.5,1559.9,2374.9,1557.4z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2369.4,1708.7c9.5,16.6,16.6,34.6,29.2,49.2
					c8.8,10.2,4.5-4.3,7.9-6c30.3,14.2-4.1,23.9-2.6,36.2c-13.9-22.7-27.7-45.3-41.6-68C2362.2,1715.1,2363.9,1711.1,2369.4,1708.7z
					"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1979.7,1977.8c0,4,0,7.9-0.1,11.9
					c-9.3-0.5-22.5,2.2-13.9-12.5C1970.3,1977.4,1975,1977.6,1979.7,1977.8z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1957.2,2075.3c-1.8,6.5-9,5.2-14,7
					c-2.4-4.1-5.7-8.5,0.9-11.7C1950.2,2067.7,1953.8,2071.5,1957.2,2075.3z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2347.8,667.3c-2.2,4.3-5.8,6.9-11.7,6.4
					C2338,669.1,2341.9,666.9,2347.8,667.3z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2058.8,1617.3c-3.9,0.5-7.7,1.1-11.6,1.6
					c-14.6-8.2-6.1-10.9,4.1-13.3c2.9,3.8,6.2,4.9,10.1,1.2c2.5,0,4.2,1,5,3C2063.9,1612.3,2061.3,1614.8,2058.8,1617.3z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2061.4,1606.8c-4,3.7-7.2,2.6-10.1-1.2
					c-0.3-1.6-0.3-3.2,0.1-4.8C2054.7,1602.8,2058.1,1604.8,2061.4,1606.8z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2140.7,411.6c9.1,0.2,18.3,0.4,30.1,0.6
					c-8.4,8.3-25.6,4.8-23.5,18.5C2138.5,425.9,2140.2,418.6,2140.7,411.6z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2117.7,832c1.4-6.8,1.7-15,13.6-6.8
					c-2.2,2.2-4.4,4.4-6.5,6.6C2122.4,831.9,2120,831.9,2117.7,832z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2180.5,466.9c-0.3,4.1-0.6,8.2-1,12.2
					c2.3,11.4,10.9,22.6,2.4,34.4c-0.4,4-0.8,8.1-1.3,12.1c-6.4,0.1-12.8,0.1-19.2,0.2c0.1-5.5,0.1-11,0.2-16.5
					c19.7-14.7-0.7-28.8-2-43.1C2166.6,466.4,2173.6,466.7,2180.5,466.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2316.2,742.9c-16.7-1.7-35.1,8.7-46.4-5.6
					c-2.8-3.6,3-10.2,12.9-13.4C2306.6,716.1,2304.6,737.4,2316.2,742.9z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2196.3,773.1c-0.5-8.1,4.2-11.7,13.5-11.2
					C2209.6,769.3,2205.2,773.2,2196.3,773.1z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2295.1,702.9c-5.8-2.7-7.7-6.8-5.6-12
					c0.3-0.7,1.8-1.1,2.7-1.6c0.9,4.6,1.8,9.2,2.7,13.7C2294.9,703.1,2295.1,702.9,2295.1,702.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2294.9,703.1c3,0.5,6,1.1,9,1.6
					c-0.7,1.1-1.4,2.1-2.2,3.2c-2.2-1.7-4.4-3.3-6.6-5C2295.1,702.9,2294.9,703.1,2294.9,703.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2232.1,515.1c1.2,1.4,2.7,2.6,3.6,4.1
					c0.4,0.6-0.6,1.8-1,2.8c-3.1-1.8-6.3-3.6-9.4-5.4C2227.6,516.1,2229.8,515.6,2232.1,515.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2233.9,462.5c-1.9,1.8-3.9,3.6-5.8,5.4
					c-3.4-2.3-6.7-4.7-10.1-7c0.7-1.6,2.1-2.5,4.2-2.5C2226.1,459.9,2230,461.2,2233.9,462.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2233.6,160.6c-5.6,0.3-8.9-1.2-7.5-6.6
					C2235,151,2237.5,153.2,2233.6,160.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2226.5,154.3c-6.3,1-8.8-1.2-7.5-6.5
					C2227.5,145,2230.4,146.9,2226.5,154.3z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2240.7,166.7c-6.2,0.8-8.2-1.8-7.6-6.6
					C2242,157,2244.5,159.2,2240.7,166.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2222.2,458.5c2.9-2.8,6.2-5.8,10.7-3.1
					c1.5,0.9,0.7,4.7,1,7.1C2230,461.2,2226.1,459.9,2222.2,458.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2235.6,444.7c10.3,0.6,6.2,4.1,2.2,7.5
					C2237.1,449.8,2236.4,447.3,2235.6,444.7z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2229.8,439.9c5.7-1,7.5,1.3,7.1,5.8
					C2234.5,443.8,2232.1,441.9,2229.8,439.9z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2212.8,261.4c-0.3-5.1-0.7-10.3-1-15.4
					c2.2,1.8,4.4,3.6,6.6,5.4C2216.5,254.7,2214.7,258,2212.8,261.4z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2309.7,384.7c-4.1,2.2-6.6,4.5-9.1,4.6
					c-2.1,0.1-4.3-2.6-6.4-4c2.3-1.8,4.4-4.4,7-5C2303.1,379.9,2305.8,382.5,2309.7,384.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2233.3,190.5c3.2,10.8-3.5,15.8-14.6,18.1
					C2215.5,197.9,2216.3,189.3,2233.3,190.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2240.4,184.5c1.9,5.9-1.9,6.9-7.6,6.3
					C2228.2,182.6,2232.2,181.8,2240.4,184.5z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2264.6,1155.8c-4.6,0.9-9.2,1.8-13.8,2.7
					c-2.3-1.9-4.7-3.8-7-5.6c-4.8-7-9.4-5.5-13.7,0.1l0.1,0.1c-7.6-1-16.2,0.8-21.3-6.4c0,0,0.2-0.3,0.2-0.3
					c9.7-0.1,13.9-4.7,14.5-12.5c4.1,0.2,8.2,0.5,12.2,0.7c18-8.4,36.7-14,57.9-10.7c0.3,10.3,0,19.5-17.6,16.7
					C2259.7,1138,2267.4,1150.1,2264.6,1155.8z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2182.3,773.4c14,10.1,3.6,23.6,7.5,35.1
					c-0.3,19.4-0.6,38.9-1,58.3c0,0,0.9,0.9,0.9,0.9C2172.3,836.9,2185.8,804.8,2182.3,773.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2294.9,853.8c-11.7-2.5-1.7,25.6-21.9,11
					c-2-4.6-4-9.1-6-13.7C2278.8,829.2,2286.2,847.4,2294.9,853.8z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2188.8,866.9c0.3-19.4,0.6-38.9,1-58.3
					c1.8,0.1,3.6,0.3,5.4,0.6c3.4,18-3.8,36.5,4.3,54.3C2197.5,867.9,2194,869,2188.8,866.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2273.1,864.6c1.5,2,3,4,4.5,5.9
					c-4.6-0.1-9.2-0.1-13.8-0.2C2266.9,868.5,2270,866.5,2273.1,864.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2208.9,1146.6c5.1,7.2,13.7,5.4,21.3,6.4
					c2.9,12.9-38,17.5-8.5,35.5c9,14.9,20.3,28.2,36.5,38.1c0.7,10.1-12.4,13.8-14.3,22.7c0,0-0.1,0.1-0.1,0.1c-3-0.1-6-0.2-9-0.3
					c-4.4,0.2-8.7,0.3-13.1,0.5c0,0,0.3,0.1,0.3,0.1c-2.2-2.3-4.4-4.6-6.6-7c-9.1-4.6-16.1-11-21-19c-0.3-1.7-0.4-3.5-0.4-5.2
					c5.2-0.9,12.3-0.7,10.8-7.2c-0.5-2-6.9-3.2-10.6-4.7c0,0,0.2-0.1,0.2-0.1c4.1-13.5-4.7-23-14.4-32.2c-0.8-1.1-1.1-2.3-1-3.5
					c0.5-2.1,1-4.3,1.5-6.4C2188.3,1156.4,2193.1,1145.2,2208.9,1146.6z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2180.1,1174.2c9.7,9.2,18.4,18.7,14.4,32.2
					c-15.3,4.8-14.6,16-14.6,27.1c0.1,25.2-0.1,50.4-6.7,75.4c0.1-24.6,0.2-49.2,0.2-73.8c0.2-1.4,0.2-2.8,0-4.2
					c-0.9-12.6-1.9-25.2-2.8-37.9C2172.8,1186.4,2169.6,1177.8,2180.1,1174.2z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2170.7,1193.1c0.9,12.6,1.9,25.2,2.8,37.9
					c-14.5-11-5.2-24.9-7.3-37.4C2167.7,1193.3,2169.2,1193.1,2170.7,1193.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2223.6,1133.9c-0.6,7.7-4.8,12.4-14.5,12.5
					c0.7-5.4,1.4-10.7,2.1-16C2216.4,1128.8,2220.8,1129.1,2223.6,1133.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2180.6,1164.3c-0.5,2.1-1,4.3-1.5,6.4
					C2174.6,1167.6,2174.5,1165.3,2180.6,1164.3z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2329.3,1046.4c2.9,0.6,5.9,1.2,8.9,1.8
					c0.1,1.2-0.3,2.2-1.2,3.1c-1.5,3.9-4,7.7-9.3,5C2326.4,1055.7,2328.7,1049.8,2329.3,1046.4z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2229.2,2353
					c-50.8,46-100.2,93.1-155.4,135.5c0.1-4.2,0.2-8.5,0.3-12.7c40.9-54.9,99.9-95.2,154.3-139.3
					C2228.7,2342,2229,2347.5,2229.2,2353z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2320.3,2128.9c22.5,4,13-10.3,14.3-18.5
					c3.8,0.2,7.5,0.4,11.3,0.6c-5.5,21.9,17.8,35.7,22.5,54.7c-7.4-1.4-19.9-14.9-18.6,4.7c0.8,12.2,3.9,24.4,5.9,36.6
					c-4.9-0.1-9.7-0.2-14.6-0.4c-2.5-7.7,3.5-16.9-6.3-23.2c-6.7-16.7-15.4-24.4-35.9-11c-15.8,10.3-37.3,13.8-51.3,27.1
					c-9.3-1.5-20.1,3.2-27.7-5.4c24.2-14.9,54.4-21.6,78.1-39.9c-12.2-5-26.8,1.4-35.8-8.6
					C2274.1,2122.2,2304.4,2142.7,2320.3,2128.9z M2335.9,2160.6l-1.3,2.1l2.2,0.2L2335.9,2160.6z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2276,2254c9.1,1.3,7.2,16.7,21,12.1
					c0.1,7.8,0.1,15.5,0.2,23.3c-2.5,0.3-5,0.6-7.6,0.9C2264.9,2283.4,2280.6,2266.1,2276,2254z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2243.3,2145.1c0.7,9.5-6.4,10.7-15.4,11.2
					c-26.4,1.5-52.7,3.4-79,5.2c-19.6-2.2-16.5-13.8-14.4-24.8c2.5-1.5,4.9-3,7.4-4.5c-0.2,1.4-0.3,2.8-0.4,4.3
					c4.4,10.5,10,8.4,16.1,1.4c1.4,0,2.8-0.1,4.3-0.1c8.6,10.4,19.5,5.7,30.3,4.1C2209.3,2139.3,2226.9,2133.9,2243.3,2145.1z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2161.7,2137.7c-1.4,0-2.8,0.1-4.3,0.1
					c-2.9-2.2-5.7-4.4-8.6-6.6c-0.9-0.8-1.7-1.6-2.4-2.5c28.7-19,44.7-49.7,80.1-62.5c1.8,1.6,3.7,3.2,5.5,4.8
					C2212.2,2096,2176.9,2109.2,2161.7,2137.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2226.4,2066.2
					c11.5-12.4,25.9-21.8,41.3-30.3c2.3,2.1,4.7,4.1,7,6.2c-12.6,11.4-26.3,21.6-42.8,28.9
					C2230.1,2069.4,2228.2,2067.8,2226.4,2066.2z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2296.7,2007.4c2.8,2.3,5.6,4.6,8.3,6.9
					c-12.9,7.1-14.2,23.3-30.4,27.8c-2.3-2.1-4.7-4.1-7-6.2C2275.8,2025.2,2281.5,2012.8,2296.7,2007.4z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2141.4,2136.4c0.1-1.4,0.2-2.9,0.4-4.3
					c0.7-2,2.2-3.1,4.6-3.3c0.8,0.8,1.6,1.7,2.5,2.5C2146.4,2133,2143.9,2134.7,2141.4,2136.4z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1931.3,2290.9c-1.9,3.7-5.2,5.1-9.9,4.2
					c2.3-7.5-3-19.5,14.5-18.1c1.6,5.8,2.2,11.4-5.5,14.5C1930.4,2291.6,1931.3,2290.9,1931.3,2290.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1949.9,2259.5c6.4,8,1.9,11-7.3,12
					C1936.5,2263.6,1942.7,2261.3,1949.9,2259.5z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1942.9,2271.2c1.1,5-1.4,7-7.3,6.1
					C1933.1,2271,1934.6,2268.2,1942.9,2271.2z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2022.7,1912.3c19.4,0.3,8.2,15,14.3,21.6
					C2017.5,1935.4,2014.7,1931.1,2022.7,1912.3z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2020.9,2236.9c0.3-2.4,0.6-4.9,1-7.3
					c2,0.4,4.1,0.9,6.1,1.3C2030.6,2237.3,2026.4,2237.7,2020.9,2236.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1931.3,2290.9c0,0-0.8,0.7-0.9,0.7
					c6.9-0.5,13.8-1,20.7-1.5c4.8,7.6-2.9,9-7.8,11.7c-4.6-0.1-9.2-0.1-13.8-0.2C1930.1,2298.1,1930.7,2294.5,1931.3,2290.9z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2250.2,1562c-31.6,28.8-62.3,58.3-99.2,82.4
					c-2.4-1.7-4.8-3.5-7.3-5.2c0.8-12.1,21.7-12,20.8-25.2c22.7-17.7,45.3-35.4,68.1-53C2238.3,1556.6,2244.9,1550,2250.2,1562z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2142.2,1571.7c4.9-13.6-5.1-29.8,9.3-41.7
					c19.7,2.1,11.4,15,11.2,23c-0.5,16.6-3.7,33-5.8,49.5c-4.2-0.1-8.3-0.2-12.5-0.3C2143.6,1592,2142.9,1581.9,2142.2,1571.7z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2144.4,1602.2c4.2,0.1,8.3,0.2,12.5,0.3
					c2,4.1-0.6,10.3,7.6,11.5c0.9,13.1-20,13.1-20.8,25.2c-13.2-8.4-5.8-20.3-8-30.6c0.5-2.1,1-4.2,1.6-6.4
					C2139.6,1602.2,2142,1602.2,2144.4,1602.2z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2237.1,1523.5c-2.7,4.4-5.3,8.8-8,13.3
					c-5.2-3.1-10.4-6.3-15.6-9.4c0.8-8.6,13.6-1.6,15.7-8.5C2231.8,1520.4,2234.4,1521.9,2237.1,1523.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2253.3,1332.7c-8.7,12.8-24,9.7-37.7,10.7
					c-17.6-34.9-13.7-41.2,35.5-56.8c-0.6,4.2-1.1,8.5-1.7,12.7C2218.3,1312.5,2246.6,1321.9,2253.3,1332.7z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2234.8,1249c3,0.1,6,0.2,9,0.3
					C2240.6,1256.2,2237.7,1252.5,2234.8,1249z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2194.3,1206.5c3.7,1.6,10.2,2.7,10.6,4.7
					c1.5,6.6-5.6,6.4-10.8,7.2C2194.2,1214.5,2194.2,1210.5,2194.3,1206.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2236.4,1447.5
					c-6.9,17.6-20.9,27.7-42.9,29.6C2201.8,1460.9,2217.4,1452.4,2236.4,1447.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2256.7,1429.1c0.3,4.3,0.5,8.6,0.8,12.9
					c-6.9,2.6-10.7,12.9-21.4,5.8C2241.2,1440.2,2239.8,1427.3,2256.7,1429.1z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2144.4,1602.2c-2.4,0-4.8,0.1-7.2,0.1
					c-7.6-0.2-15.3-0.4-22.9-0.7c-15.1-13.6-1.3-37.7-25.1-48.1c-8.6-0.7-17.2-1.4-25.8-2.2c1.6-3.1,3.2-6.2,4.8-9.3
					c3.4-3.1,6.8-6.1,10.1-9.2c2.8-1.4,5.5-2.8,8.3-4.2c10.7,2.2,25.2-12.2,32.3,5.9c0,0-0.7,0.1-0.7,0.1c-1.6-1-3.1-2-4.7-3
					c0.9,1.3,1.9,2.7,2.8,4c-6.5,18,4.8,33.2,12.1,49.1c1.1-3.7,2.1-7.5,3.2-11.2c3.5-0.6,7.1-1.2,10.6-1.8
					C2142.9,1581.9,2143.6,1592,2144.4,1602.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2372.5,2485c-22.7,6.2-9.1,20.3-8.5,31.4
					c0.6,12.8,9.7,29.1-18.3,27.7c5-22.4-7.6-44.1-4.7-66.4c11.8-2,17.2-10.6,25.1-16.8c1.9-1.2,3.9-2.4,5.8-3.6
					C2372.1,2466.5,2372.3,2475.8,2372.5,2485z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2366.1,2460.8c-7.9,6.1-13.3,14.8-25.1,16.8
					c-0.2-5.9-0.4-11.8-0.6-17.6C2348.9,2460,2357.6,2454.1,2366.1,2460.8z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2466,2564.5c-0.1,2-0.1,4,0,6
					c-7.5,2-15,3.9-22.6,5.9c-20.1-44.5-40.1-89.1-60.2-133.6c6.3-1.9,12.5-3.8,18.8-5.6c16.8,38.6,33.8,77.2,50.1,116
					C2454.8,2559.5,2458.2,2563.5,2466,2564.5z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2485.9,2539.5c2.8,7.2,1.3,12.4-8.8,13.2
					C2475.1,2545.9,2473.6,2539.3,2485.9,2539.5z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2197.4,2646.5c35.1-1,47.9-30.4,74.8-42.3
					c21.1-9.4,39-23.7,58.3-35.9c19.6-13.2,36.5-10.2,50.8,7.2c2.1,6.9-3.1,11.4-6.9,16.3c0,20.5,0.3,41,0,61.5
					c-0.2,9.4,2.5,20.4-13,23.7c0,0-3.2-1.2-3.2-1.2c-2.5-30-5-60-8-95.5c-39.1,25.8-72.4,47.8-105.7,69.7
					C2228.1,2656.3,2211.6,2665.4,2197.4,2646.5z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2158.9,2613.1c11.5,1.9,16.2,8.6,17.2,17.8
					c-3.9,0.5-10.1-0.9-10,3.9c0.1,2.7,5.1,5.4,7.9,8.1c18.5,17.2,27.1,42.8,58.1,49.4c3.5,2.8,7,5.5,10.5,8.3
					c-1.4,8.3-2.8,16.6-4.2,25c-8-5.8-17.6-10.7-23.8-17.7c-16.5-18.4-30.2-15.5-50.8-3.3c-35.7,21.1-68.6,44.8-101.5,68.6
					c-9.8-0.2-25,7.2-21.5-11.4c24.9-26.1,60.3-41,91-60.9c16.1-10.4,35.8-19.1,36.9-40c-3.6-16.4-15.5-26.2-33.9-30.7
					c-0.7-1.8-1.2-3.5-1.7-5.3c1.2-11,11.2-13.1,21.3-15.2c0,0,0.1,0.4,0,0.5c-0.8,1.2-1.6,2.4-2.3,3.7
					C2154.3,2613.5,2156.6,2613.3,2158.9,2613.1z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2049.2,2611.3c3.2-0.1,6.4-0.1,9.6-0.2
					c-4.4,8.9,2.9,10.8,10.5,12.6c2.3,7.6,4.7,15.2,7,22.8c-17.1-1.5-28.6-2.5-30,19.4c-1.1,17.1-2.4,35.2-6,52.5
					C2025,2681.3,2037.7,2646.3,2049.2,2611.3z"/>
				<path fill="#7A7B7D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2049.2,2611.3c-11.5,35-24.2,70-8.8,107.1
					c0,6.2-0.1,12.3-0.1,18.5c-19.6,2.9-12.3,14.6-10.6,24.5c-0.9,5.9-1.9,11.8-2.8,17.7c-22.6-13.5-9.6-30.3-6.4-45.7
					c9.5-46.7,1.7-95.5,21.1-140.9C2050.2,2597,2047.7,2604.7,2049.2,2611.3z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2360.5,2274.6c2.9-1.4,5.7-2.8,8.6-4.2
					c0,0,0.6,0.6,0.6,0.5c-1.5,5.2-3,10.4-4.5,15.6c-1.5-2.1-3.1-4.3-4.6-6.4C2360.7,2278.3,2360.6,2276.5,2360.5,2274.6z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2360.5,2274.6c0.1,1.9,0.2,3.7,0.1,5.6
					c-4.7-0.2-9.5-0.4-14.2-0.6C2349.5,2274.8,2354.8,2274.2,2360.5,2274.6z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1984.9,2626.6c-14-0.6-17.5,7.5-23.1,16.7
					c-9.6,16-20.9,31.7-37.8,43.5c-3.1-0.4-6.3-0.8-9.4-1.2c0-4-0.1-8.1-0.1-12.1c5.6-2.9,6.3-7.7,6.7-12.6
					c14.9-10.6,28.2-21.8,20.8-40.8c1.1-1.8,1.3-3.6,0.6-5.6c2.6-1.2,5.1-2.5,7.7-3.7c15.5-2.2,23.7-16.4,40.1-17
					c1.1,1.9,2.1,3.8,3.2,5.8c0.2,20.5,16.9,7,25.8,9.4C2019.3,2630.6,1992,2614.5,1984.9,2626.6z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2242.6,2700.5c29.5,6.5,47.8,23.6,60.2,46.3
					c-0.5,9.9-5.8,17.8-14.2,24.3c1.4-18.3-16.1-29.5-24.6-44c-5-8.5-13.5-2.4-19,3c-2.2-1.5-4.4-3.1-6.6-4.6
					C2239.8,2717.1,2241.2,2708.8,2242.6,2700.5z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2348.4,2689.1c0,0,0.8-0.1,0.8-0.1
					c-16.5-11.7-35.8-5.9-54-7.1c0,0,0.2,0.5,0.2,0.5c0.1-4.3,0.2-8.6,0.4-12.8c2-1.7,4-3.4,6-5c21.3-6,36.8,10.8,56.4,11.4
					c0,0,3.2,1.2,3.2,1.2C2359.3,2682.9,2356.8,2688.3,2348.4,2689.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2435.3,2618.3c3,1.8,6.1,3.6,9.1,5.5
					l-2.2,1.3l-2.7-0.3C2435.1,2623.9,2430.9,2623.1,2435.3,2618.3z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2443.2,2593.4c0.1-5.7,0.2-11.3,0.3-17
					c7.5-2,15-3.9,22.6-5.9c-0.2,3.9-0.4,7.8-0.6,11.7C2458,2585.9,2450.6,2589.6,2443.2,2593.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2147.4,526c4.5,0.1,9.1,0.2,13.6,0.3
					c-1.4,16.4-14.2,27.5-23.4,40.7C2106.4,545.1,2139.4,538.3,2147.4,526z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2137,614.2c-0.9,1.6-1.4,4.2-2.8,4.6
					c-1.4,0.4-3.8-1.2-5.8-2c0.9-1.6,1.4-4.3,2.8-4.6C2132.7,611.8,2135,613.4,2137,614.2z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2118.7,513c3.4,2.1,6.8,4.2,10.2,6.3
					C2125.5,517.2,2122.1,515.1,2118.7,513z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2157.1,1690.6c-4.1,3.9-8.2,7.7-12.3,11.6
					c-8-16.5-11.4-2-16.5,1.5c-3-0.1-5.9-0.1-8.9-0.2c4.4-14.6,1.2-28.5-6.4-41.9c0.9-2,1.7-3.9,2.6-5.9c5.3-2.8,6.8-7,6.5-12.1
					c-1.8-9.9-3.6-19.8-5.3-29.7c6.3-1.7,12.6-3.5,18.9-5.2c2.3,10.3-5.2,22.2,8,30.6c2.4,1.8,4.9,3.5,7.3,5.2
					c-2,7.1,2.9,12.4,6.6,18.1C2157.4,1671.8,2157.2,1681.2,2157.1,1690.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2193.3,1651.2c1,1.3,2.2,2.6,2.8,4
					c0.2,0.6-0.8,1.6-1.3,2.4c-2.9-1.9-5.7-3.8-8.6-5.7C2188.5,1651.6,2190.9,1651.4,2193.3,1651.2z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2221.6,1633.3c-3.3,13.2-12.2,20-28.8,18
					C2197,1639.2,2208.1,1634.9,2221.6,1633.3z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2270.6,1603.9c-2.3,0.3-4.5,0.5-6.8,0.8
					c-5-7-1.4-8.3,6.3-7.1C2270.3,1599.7,2270.4,1601.8,2270.6,1603.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2376.5,1565c1.7,4.3,1,9.7,7.5,11.9
					c-4.2,7.4,6.4,17.9-6.4,23.5c-2.5-0.1-4.9-0.2-7.4-0.2c0,0,0.4,0.5,0.4,0.5c4.3-13.1-8.9-22-11.9-33.3c1.4-1,2.9-1.9,4.3-2.9
					C2367.4,1564.5,2372,1564.7,2376.5,1565z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2349.3,1973.9c-6.2,8.8-17.2,16-12,28.4
					c-16.4-2.5-33.5-11.2-28.3-24C2314.3,1965.5,2335.1,1973.5,2349.3,1973.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2347,2062.2c-19,15.6,2.4,32.7-1.2,48.9
					c-3.8-0.2-7.5-0.4-11.3-0.6c-0.4-16-2.5-32.1-0.8-47.9C2335,2050.9,2341.5,2046.6,2347,2062.2z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2417.7,2172.6c0.5-0.8,1-1.6,1.5-2.4
					c-0.5,0.8-0.9,1.7-1.4,2.5L2417.7,2172.6z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2325.5,1894.4
					c-20.9-14.8-20.9-28.9,0.8-42.3C2326,1866.2,2325.8,1880.3,2325.5,1894.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2291.3,1809.1c-4.7,1.2-9.2,7.9-14.3,0.3
					c1.8-6.1,5.2-10,13.6-7.1C2290.8,1804.5,2291.1,1806.8,2291.3,1809.1z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1934.7,2608.5c5.2,0.8,10.4,1.5,15.6,2.3
					c-2.6,1.2-5.1,2.5-7.7,3.7c-2.7-0.2-5.4-0.4-8.1-0.6C1933.8,2612.1,1933.9,2610.3,1934.7,2608.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M1934.5,2613.9c2.7,0.2,5.4,0.4,8.1,0.6
					c0.7,1.9,0.5,3.8-0.6,5.6l-3.2,0.1l-3.2-0.6C1934.7,2617.8,1934.4,2615.9,1934.5,2613.9z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2464.6,706.5c-4.4-8,2.3-6.6,7.5-6.4
					C2476.5,708.1,2469.9,706.7,2464.6,706.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2044,1650.9c17.6-4.6,12.2,10.2,18.1,15.5
					c-6.3,0.5-10.3,3.1-11.4,8.5C2044.1,1667.7,2043.9,1659.4,2044,1650.9z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2277.6,1815.2
					c-38.5,25.4-77,50.9-115.5,76.3c-17.6,8.3-28.1,6.4-26.4-13.4c22.2,9,34.9-6.9,46-15.9c26.6-21.6,58.7-35.5,88.8-52.2
					C2272.9,1811.8,2275.2,1813.5,2277.6,1815.2z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2135.9,1847.8c18.3-12.8,36.4-25.7,55-38.2
					c4.4-3,9.2-10,16.2-1.9c-23.6,21.9-47.2,43.8-70.8,65.6C2136.1,1864.8,2136,1856.3,2135.9,1847.8z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2126,1785.7c3.8-11.2-17-16.1-10.5-28
					c8.6,0.1,17.2,0.3,25.8,0.4c-2.3,27.9-4.6,55.8-6.9,83.8C2121.5,1824.1,2134.5,1803.9,2126,1785.7z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2287.7,811.1c-1.3-14.3,15.4-6.2,21-11.9
					l0.5,0.4c-1.8,7,3.6,16.6-6.5,20.1c-3,1.1-10-5.7-15.1-8.8C2287.5,811,2287.7,811.1,2287.7,811.1z"/>
				<path fill="#414144" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2308.7,799.2c1.1-0.8,2.2-1.5,3.3-2.3
					c-0.9,0.9-1.9,1.8-2.8,2.7C2309.2,799.7,2308.7,799.2,2308.7,799.2z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2091.2,354.4c-0.2-3.1-0.4-6.1-0.6-9.2
					c6.9-0.5,13.8-1.1,20.7-1.6C2110.9,355.7,2102.2,356.6,2091.2,354.4z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2306.8,1226.5c0.2-2,0.4-4.1,0.6-6.1
					C2315.4,1223.1,2312.7,1224.9,2306.8,1226.5z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2307.5,1220.5c-2.2-0.1-4.4-0.2-6.6-0.5
					C2303.6,1214.4,2305.7,1215.4,2307.5,1220.5z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2253.3,1332.7c-6.7-10.8-35-20.1-3.8-33.4
					c10.6,8.4,23.7,3.8,35.5,5.4c-16.9,4.1-18.9,19.8-30.4,28.1C2254.7,1332.8,2253.3,1332.7,2253.3,1332.7z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2376.5,1565c-4.5-0.2-9.1-0.4-13.6-0.6
					c4-2.3,8-4.6,12.1-7C2375.5,1559.9,2376,1562.4,2376.5,1565z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2206.5,1806.9c-7.5-7.5-1.2-9.6,7.6-10.8
					C2213.6,1800.5,2215.1,1805.6,2206.5,1806.9z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2277.4,1815.3c-2.2-1.8-4.4-3.7-6.7-5.5
					c2.4-0.3,4.8-0.5,7.2-0.8c0.5,1.4,1.3,2.9,1.5,4.3C2279.5,1814,2278.1,1814.7,2277.4,1815.3z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2122.1,1643.5c0.3,5-1.2,9.3-6.5,12.1
					c-8.6-9.1-28.9-8.3-28.6-24.8C2101.8,1628.6,2109,1642,2122.1,1643.5z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2058.8,1617.3c2.5-2.5,5-4.9,7.6-7.4
					c9.3,5.3,24.8,6.3,20.8,21.1C2068.8,1640,2067.1,1623.7,2058.8,1617.3z"/>
				<path fill="#38393D" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2135.6,1608.6c-6.3,1.7-12.6,3.5-18.9,5.2
					c-0.8-4.1-1.7-8.1-2.5-12.2c7.6,0.2,15.3,0.4,22.9,0.7C2136.7,1604.4,2136.1,1606.5,2135.6,1608.6z"/>
				<path fill="#262629" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2221.8,1188.4
					c-29.6-17.9,11.3-22.6,8.5-35.5c0,0-0.1-0.1-0.1-0.1c4.6,0,9.2-0.1,13.7-0.1c2.3,1.9,4.7,3.8,7,5.6
					C2241.1,1168.4,2231.4,1178.4,2221.8,1188.4z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2243.8,1152.8c-4.6,0-9.2,0.1-13.7,0.1
					C2234.4,1147.2,2239,1145.8,2243.8,1152.8z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2222,1249.6c-2.2-2.3-4.4-4.6-6.6-7
					C2217.6,1245,2219.8,1247.3,2222,1249.6z"/>
				<polygon fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" points="2335.9,2160.6 2336.7,2162.9 
					2334.5,2162.7 				"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2141.4,2136.4c2.5-1.7,5-3.4,7.5-5.1
					c2.9,2.2,5.7,4.4,8.6,6.6C2151.4,2144.9,2145.8,2146.9,2141.4,2136.4z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2118.2,1534.6l-1.8,1
					c-0.9-1.3-1.9-2.7-2.8-4C2115.1,1532.6,2116.6,1533.6,2118.2,1534.6z"/>
				<path fill="#66676A" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2245.6,2668.5c-28.3-2.2-5.8,17.2-13.5,23.7
					c-31-6.5-39.6-32.1-58.1-49.4c5.6,0.1,11.3,0.1,16.9,0.2c2.2,1.2,4.3,2.3,6.5,3.5c14.2,18.8,30.7,9.8,47,3.6
					c16.7,12.1,41.1,0.7,57.3,14.3c-2,1.7-4,3.4-6,5C2279,2669.2,2262.3,2668.9,2245.6,2668.5z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2190.9,2643.1c-5.6-0.1-11.3-0.1-16.9-0.2
					c-2.8-2.7-7.9-5.4-7.9-8.1c-0.1-4.8,6.1-3.4,10-3.9C2181.1,2635,2186,2639,2190.9,2643.1z"/>
				<path fill="#515156" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2158.9,2613.1c-2.3,0.2-4.6,0.3-7,0.5
					c0.7-1.2,1.5-2.4,2.3-3.7C2156,2610.9,2157.5,2612,2158.9,2613.1z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2245.6,2668.5c16.7,0.3,33.4,0.7,50.1,1
					c-0.1,4.3-0.2,8.6-0.4,12.8C2275.6,2686.5,2259.6,2680.2,2245.6,2668.5z"/>
				<path fill="#17181B" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2295.2,2681.9c18.2,1.2,37.5-4.6,54,7.1
					C2330.2,2692.5,2312.1,2691.1,2295.2,2681.9z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2141.5,1758.1c-8.6-0.1-17.2-0.2-25.8-0.3
					c-7-11.4,14.8-31.2-15.1-36c-0.1-10.8,12.7-12,18.6-18.3c3,0.1,5.9,0.1,8.9,0.2c3.1,5.2,8.4,6.8,14.7,6.7
					C2146.3,1726.3,2144,1742.2,2141.5,1758.1z"/>
				<path fill="#48494C" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2143,1710.3c-6.4,0.1-11.6-1.6-14.7-6.7
					c5.1-3.4,8.5-18,16.5-1.5C2144.2,1704.9,2143.6,1707.6,2143,1710.3z"/>
				<path fill="#2F2F33" stroke="#999999" stroke-width="2" stroke-miterlimit="10" d="M2093.6,1727.5c-3.9-7.5,1.6-7,7.3-6.1
					C2102.9,1727.3,2099,1728,2093.6,1727.5z"/>
			</g>
		</g>
		<g>
			<g>
				<g>
				{this.props.kz&&
					<g>
						<g>
							<path fill="#C7A331" d="M1802.7,1354.6c0.3-0.4,0.7-0.7,0.9-1c0.2-0.3,0.4-0.6,0.5-0.8c0.2-0.4,0.3-0.6,0.4-0.8
								c0-0.2-0.2-0.1,0-0.4c-0.5,0.7-0.9,1.3-1.2,1.8c-0.4,0.4-0.6,0.8-0.8,1c-0.4,0.5-0.4,0.7-0.4,0.7
								C1802.1,1355.3,1802.8,1354.6,1802.7,1354.6z"/>
							<path fill="#C7A331" d="M1746.5,1323.5l0.6,1.3c-0.7-1.8-1.2-3.7-1.8-5.5c-0.6-1.8-1.1-3.7-1.6-5.6
								C1744.5,1317,1745.5,1320.2,1746.5,1323.5z"/>
							<path fill="#C7A331" d="M1740.5,1249.9c-0.4,3.5-0.6,10.3-0.6,17.8c0,7.5,0.4,15.8,0.7,22.4l0,2.2c0.3,2,0.7,3.9,1.1,5.9
								c-0.9-7.9-1.5-17.2-1.6-25.8C1740.1,1263.6,1740.3,1255.6,1740.5,1249.9z"/>
							<path fill="#C7A331" d="M1742.4,1209.9c-0.1,1.2-0.2-0.9-0.3-1.2c0-0.2,0.2,1.5,0.3,10.5c0-0.9,0-1.2,0.1-1.7
								C1742.6,1216,1742.5,1213.7,1742.4,1209.9z"/>
							<path fill="#C7A331" d="M1742.6,1217.6C1742.7,1225.5,1742.8,1212.4,1742.6,1217.6L1742.6,1217.6z"/>
							<path fill="#C7A331" d="M1739.1,1181l1.1,5.2l0.9,5.2c-0.1-1.1-0.4-4-0.7-6.2c-0.4-2.2-0.6-3.6-0.2-1.9
								c-0.3-1.3-0.5-2.5-0.7-3.4c-0.2-0.9-0.4-1.7-0.6-2.3c-0.3-1.2-0.5-1.7-0.5-1.7C1738.2,1175.9,1738.6,1177.9,1739.1,1181z"/>
							<path fill="#C7A331" d="M1736.2,1171.4c0.5,1.7,1.1,3.5,1.5,5.3c-0.1-0.5-0.2-1.1-0.4-1.9
								C1737.1,1173.9,1736.7,1172.8,1736.2,1171.4z"/>
							<path fill="#C7A331" d="M1740.1,1188c-0.3-1.9-0.7-3.8-1.1-5.7c-0.3-1.9-0.9-3.8-1.3-5.6c0.1,0.8,0.1,1.2,0.3,1.9
								c0.1,0.7,0.4,1.6,0.9,3.3c-0.2-0.4-0.1-0.1,0.1,0.9C1739.2,1183.8,1739.7,1185.5,1740.1,1188z"/>
							<path fill="#C7A331" d="M1740.8,1194.5c0.3,2.9,0.4,6.3,0.5,7.6c0,1.3-0.1,0.6-0.6-4.6c0.2,2.5,0.4,4.6,0.5,6.4
								c0.1,1.8,0.2,3.2,0.3,4.3c0.2,2.2,0.2,3.1,0.3,2.7c0.1-0.4,0.1-2-0.1-4.8c0-1.4-0.1-3.1-0.3-5c-0.1-1-0.2-2-0.2-3.1
								C1741.1,1196.9,1741,1195.7,1740.8,1194.5z"/>
							<path fill="#C7A331" d="M1739.9,1246l-0.4,7.8l0.1,6.7C1739.5,1257.2,1740.1,1245.6,1739.9,1246z"/>
							<path fill="#C7A331" d="M1702.4,1127.7c-0.4,0.1,0.7,0.2,1.9,0.5c0.6,0.1,1.3,0.3,1.8,0.5c0.5,0.2,1,0.4,1.2,0.5
								c2.8,0.9,5.4,2.3,7.6,4c-0.8-0.7-1.9-1.5-3.1-2.2c-1.2-0.7-2.6-1.4-3.9-1.9C1705.1,1128,1702.7,1127.8,1702.4,1127.7z"/>
							<path fill="#C7A331" d="M1707.2,1129.1c-2.1-0.6-4.4-0.9-6.6-0.7c-1.1,0-2.2,0.3-3.3,0.5l-1.6,0.5c-0.5,0.2-1,0.4-1.5,0.6
								c2.7-1.2,5.1-1.5,6.9-1.5c0.5,0,0.9,0,1.3,0c0.4,0,0.8,0,1.1,0.1c0.7,0.1,1.3,0.2,1.7,0.2
								C1707.2,1129.2,1707.5,1129.4,1707.2,1129.1z"/>
							<path fill="#C7A331" d="M1735,1169.8c2,6,3.5,12.2,4.6,18.5c-1.3-7.5-3.1-14.9-5.8-22
								C1734.2,1167.4,1734.6,1168.6,1735,1169.8z"/>
							<path fill="#C7A331" d="M1673.1,1161c0.8-1.9,1.6-3.9,2.4-5.9c-1.4,3.5-2.8,7-4.5,10.2
								C1671.7,1163.9,1672.4,1162.4,1673.1,1161z"/>
							<path fill="#C7A331" d="M1654.7,1177.1c2-0.4,0.6-0.3-0.7-0.3c-0.7-0.1-1.3-0.1-1.5-0.2c-0.2-0.1,0,0,1-0.1
								c-3.1,0.1-6.2-0.9-9-2.1c-2.8-1.2-5.4-2.8-7.9-4.4c2.6,1.7,5.2,3.4,8.2,4.8C1647.7,1176.3,1651,1177.3,1654.7,1177.1z"/>
							<path fill="#C7A331" d="M1628.5,1164.2c2.5,2,5.1,3.9,8,5.8C1633.8,1168.2,1631.2,1166.2,1628.5,1164.2z"/>
							<path fill="#C7A331" d="M1680.9,1145.9c-0.5,0.8-1.5,2.3-4.1,8.1l2.2-4C1679.5,1148.8,1680.2,1147.3,1680.9,1145.9z"/>
							<path fill="#C7A331" d="M1679,1149.9l-0.1,0.1c-2,4.5-3.3,7.5-4,8.8C1675.5,1157.8,1676.7,1155.1,1679,1149.9z"/>
							<path fill="#C7A331" d="M1681.4,1144.8c-0.2,0.4-0.3,0.7-0.5,1C1681.1,1145.5,1681.2,1145.3,1681.4,1144.8z"/>
							<path fill="#C7A331" d="M1722,1142.2c-1.1-1.4-2.5-3.1-4-4.6c-1.5-1.5-3.1-2.9-4.2-3.7c-1.2-0.8-1.8-1.1-1.4-0.8
								c0.4,0.4,1.8,1.4,4.2,3.9c1.8,1.7,1.4,1.1,1.4,0.9c0-0.1,0.2,0,0.8,0.5C1719.4,1139.1,1720.4,1140.1,1722,1142.2z"/>
							<path fill="#C7A331" d="M1684.6,1140c1.1-1.8,2.4-3.5,3.9-5.1c1.5-1.6,3.3-3,5.3-4c-0.1,0-0.8,0.3-1,0.3
								c-0.1,0-0.1,0,0.2-0.2c0.2-0.2,0.7-0.5,1.6-0.9c-2.3,1-4.3,2.6-5.9,4.3C1687.1,1136.1,1685.8,1138,1684.6,1140z"/>
							<path fill="#C7A331" d="M1681.8,1145.5c0.8-1.8,1.8-3.7,2.8-5.5c-0.8,1.3-1.6,2.7-2.3,4.1L1681.8,1145.5z"/>
							<path fill="#C7A331" d="M1644.8,1175.8c-4.5-1.8-9.2-5-14.2-9c2.7,2.2,5.4,4.3,8.2,6.1c2.8,1.9,5.8,3.4,8.8,4.3
								c0.4,0.1,1.3,0.2,1.3,0.1c0,0-0.3-0.1-0.9-0.4C1647.4,1176.8,1646.4,1176.5,1644.8,1175.8z"/>
							<path fill="#C7A331" d="M1666,1172.6c-0.2,0.2-0.5,0.5-0.8,0.7C1665.7,1172.9,1666.1,1172.6,1666,1172.6z"/>
							<path fill="#C7A331" d="M1572.1,1144.6c0.4-0.1,0.7-0.2,1-0.3c0.1-0.1,0.1-0.1,0.2-0.2L1572.1,1144.6z"/>
							<path fill="#C7A331" d="M1648.7,1177.6c2.3,0.6,4.2,0.7,5.8,0.6c0.8-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.3,1.9-0.6
								c-1.6,0.5-3.4,0.9-5.2,0.9c-1.8,0-3.8-0.2-5.7-0.9C1647.3,1177.2,1647.2,1177.2,1648.7,1177.6z"/>
							<path fill="#C7A331" d="M1614.4,1153.1c2.2,1.7,4.6,3.8,7.1,5.9C1619.5,1157.1,1615.3,1153.6,1614.4,1153.1z"/>
							<path fill="#C7A331" d="M1663.9,1174.2c0.2-0.1,0.3-0.3,0.5-0.4C1663.2,1174.6,1663.4,1174.5,1663.9,1174.2z"/>
							<path fill="#C7A331" d="M1663.9,1174.2c-0.5,0.4-0.9,0.7-1.4,1c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.5-1.3,0.7
								c-0.4,0.2-0.9,0.4-1.3,0.6c0.7-0.3,1.3-0.4,1.9-0.8c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.7,2.1-1.4,2.9-2.2
								C1664.8,1173.7,1664.3,1174,1663.9,1174.2z"/>
							<path fill="#C7A331" d="M1556.7,1157.1c0.2-0.4,2.9-3,7.1-5.8c2.1-1.4,4.6-2.8,7.4-3.9c2.7-1.2,5.7-2,8.7-2.4
								c2.9-0.6,4.6-0.7,5.4-0.9c0.8-0.2,0.6-0.4-0.3-0.6c-0.4-0.1-1.1-0.2-1.8-0.2c-0.8-0.1-1.7,0-2.7,0c-2.1,0.1-4.6,0.5-7.2,1.2
								c-0.1,0.1-0.2,0.3-1.7,0.9c-1.4,0.7-4.4,1.9-9.5,5.4c0.9-0.6,2.9-1.7,5.2-2.9c2.3-1.2,5.1-2.3,7.6-2.9
								c1.2-0.4,2.3-0.6,3.2-0.8c0.9-0.2,1.6-0.2,2-0.3c0.8,0,0.3,0.1-2.3,0.7c-3.9,1-6.6,2.2-8.5,3.1c-1.9,0.9-3.1,1.6-4.1,2.1
								c-1,0.5-1.8,0.9-2.7,1.5c-0.9,0.5-2,1.2-3.7,2.3c2.9-1.8-1.3,1.3-5.3,5.1c-4,3.8-7.8,8.2-6.4,7.2c3.2-3.4,6.5-6.6,10.1-9.6
								C1557.4,1156.1,1553.7,1159.5,1556.7,1157.1z"/>
							<path fill="#C7A331" d="M1597.1,1144l2.5,0.9l2.4,1c-2.7-1.3-5.1-2.2-7.8-3c-2.7-0.8-5.8-1.4-9.8-1.4c-4.5,0.3-7,1.2-8.6,1.6
								c-1.5,0.5-2.1,0.8-2.3,1c4.1-1.4,8.4-2.2,12.7-2.1c4.4,0,8.7,1,12.7,2.5L1597.1,1144z"/>
							<path fill="#C7A331" d="M1620.7,1158.7c-1.6-1.1-4.8-3.4-7-4.9c-2.2-1.4-3.2-1.9-0.4,0.4c5.8,4.4,7.8,6.1,9.3,7.1
								c1.5,1.1,2.4,1.7,5.8,4.4c-18.1-14.9,5.3,4.1-7.5-6.9c3.6,2.9,6.8,5.5,9.8,7.9c-3.2-2.6-6.3-5.3-9.2-7.8
								c0.2,0.1,0.3,0.2,0.4,0.4l0.1,0.1l0,0c0.7,0.7,1,0.9,0.1,0.2c-0.1,0-0.3-0.2-0.4-0.3
								C1621.5,1159.2,1621.1,1159,1620.7,1158.7c-5.3-4.4-0.8-0.8,0.9,0.6c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0-0.1-0.1
								c-6.1-4.9-12.4-9.9-19.9-13.4C1606.6,1148.2,1612.1,1151.7,1620.7,1158.7z"/>
							<path fill="#C7A331" d="M1705.4,1131.2c-0.7-0.2-0.7-0.2-0.7-0.3c-0.8-0.1-1.3-0.2-1.3-0.1c0,0,0.2,0,0.5,0.1
								C1704.2,1131,1704.7,1131,1705.4,1131.2z"/>
							<path fill="#C7A331" d="M1695.7,1131.6c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.2,0.9-0.4,1.6-0.5c0.3-0.1,0.7-0.1,1.1-0.2
								c0.4-0.1,0.8-0.1,1.3-0.1c0.2,0,0.5,0,0.8,0c0.3,0,0.6,0,0.8,0c0.6,0,1.2,0.1,2,0.2c4.8,1.3-0.1,0.1,0.2,0.4
								c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,1.1,0.3,1.6,0.5c0.5,0.2,1,0.4,1.5,0.6c0.4,0.2,0.8,0.4,1.1,0.5c0.6,0.3,0.7,0.3,0.2-0.1
								c-0.6-0.3-1.8-1.2-4.4-2c-0.6-0.1-1.1-0.3-1.7-0.3c-2.4-0.4-5.1-0.4-7.6,0.3c-2.5,0.7-4.7,2-6.3,3.4
								c-1.6,1.4-2.8,2.7-3.5,3.5c-0.7,0.9-1,1.2-0.8,0.9c1-1.1,1.9-2.1,3.3-3.2C1690.8,1134.1,1692.7,1132.6,1695.7,1131.6z"/>
							<path fill="#C7A331" d="M1664.8,1175.2c-2.4,1.9-5.3,3.2-8.3,3.8c-3,0.6-6.1,0.3-9.1-0.6c3,1,6.2,1.5,9.4,1
								c-0.2,0-0.3-0.1-0.5-0.1c0.7-0.1,1.2-0.3,1.8-0.4c0.6-0.1,1-0.3,1.5-0.5c0.4-0.2,0.9-0.3,1.2-0.5c0.4-0.2,0.7-0.3,1-0.5
								c1.2-0.6,1.8-1,2.2-1.3C1664.6,1175.6,1664.3,1175.7,1664.8,1175.2z"/>
							<path fill="#C7A331" d="M1569.9,1145.3l1.2-0.8l1.2-0.7c-3.7,1.6-7.1,3.6-10.3,5.9
								C1564.5,1148.1,1567.1,1146.6,1569.9,1145.3z"/>
							<path fill="#C7A331" d="M1548.6,1160.5l0.4-0.9c-1.6,1.6-3.2,3.3-4.7,5C1545.7,1163.1,1547.1,1161.8,1548.6,1160.5z"/>
							<path fill="#C7A331" d="M1104,930.9c-1.8,0.7-3.6,1.4-5.4,2c1.3-0.5,2.4-0.8,3.3-1.2C1102.7,931.4,1103.4,931.1,1104,930.9z"
								/>
							<path fill="#C7A331" d="M1141.8,912.9l0.8-0.7C1141,913.3,1140.4,913.8,1141.8,912.9z"/>
							<path fill="#C7A331" d="M1287.8,1031c-0.2-8.4-0.2-17.7-0.6-16.7l-0.3,2.6C1287.4,1022.7,1287.6,1027.2,1287.8,1031z"/>
							<path fill="#C7A331" d="M1240.1,667.4c-0.8,0.1-1.6,0.2-2.4,0.4c-1.9,0.7-3.8,1.3-5.3,1.9c0.6-0.2,1.2-0.4,1.8-0.6
								c0.6-0.2,1.3-0.4,1.9-0.6c0.7-0.2,1.3-0.4,2-0.6C1238.7,667.7,1239.4,667.6,1240.1,667.4z"/>
							<path fill="#C7A331" d="M1245.8,664.8c3.8-0.3,7.6-0.2,11.4,0.4C1254.7,664.7,1251,664.4,1245.8,664.8z"/>
							<path fill="#C7A331" d="M895.1,880.8l-2.2-2.7l-2.1-2.7c1.5,2.2,2.5,3.6,3.1,4.5c0.6,0.9,0.9,1.2,1,1.3
								C895.3,881.4,894.8,880.6,895.1,880.8z"/>
							<path fill="#C7A331" d="M1385.1,1289.7c-4.1,5.7-8.1,9.6-11.7,12.7c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.7-0.7,1.1-1
								c0.7-0.7,1.4-1.3,2.1-1.9c1.3-1.3,2.5-2.5,3.5-3.7c1-1.2,1.8-2.2,2.5-3C1384.4,1291,1384.9,1290.2,1385.1,1289.7z"/>
							<path fill="#C7A331" d="M1117.3,920.3c-3.1,1.5-4.4,2.3-4.3,2.3C1116.2,921.1,1118,920.1,1117.3,920.3z"/>
							<path fill="#C7A331" d="M1356.1,1311.6c1.2-0.3,2.3-0.7,3.5-1.1c4.7-1.9,9.2-4.3,13.9-8.1c-2.4,1.9-5.1,3.6-8.1,5.2
								C1362.4,1309.2,1359.3,1310.5,1356.1,1311.6z"/>
							<path fill="#C7A331" d="M422.6,688.2c-0.6,0.1-1.3,0.2-1.8,0.3C420.7,688.6,421.5,688.4,422.6,688.2z"/>
							<path fill="#C7A331" d="M294.9,546.2c0.3,2.3,0.7,6.2,1.1,9.8C295.8,554.2,295.5,551.2,294.9,546.2z"/>
							<path fill="#C7A331" d="M1470.2,1207.4C1474.5,1209.7,1470.9,1207.7,1470.2,1207.4L1470.2,1207.4z"/>
							<path fill="#C7A331" d="M1493.8,1210.9c-1.1,0.4-1.9,0.7-2,0.8C1492.2,1211.5,1492.9,1211.2,1493.8,1210.9z"/>
							<path fill="#C7A331" d="M1308.9,1300.3c-0.1-0.1-0.2-0.2-0.3-0.4C1309.5,1301.2,1309.4,1301,1308.9,1300.3z"/>
							<path fill="#C7A331" d="M1476.9,1210.3c-1.8-0.5-3.8-1.3-5.8-2.3c0.2,0.1,0.5,0.3,0.8,0.5c0.3,0.2,0.7,0.3,1.2,0.6
								c0.5,0.2,1,0.4,1.6,0.7c0.3,0.1,0.6,0.2,1,0.4C1476.1,1210.1,1476.5,1210.2,1476.9,1210.3z"/>
							<path fill="#C7A331" d="M1476,1209.9c1.6,0.5,2.9,0.7,4.2,0.9c0.3,0,0.3,0-0.3-0.1c-0.3,0-0.7-0.1-1.4-0.2
								C1477.9,1210.4,1477.1,1210.1,1476,1209.9z"/>
							<path fill="#C7A331" d="M1464.5,1203.3c2.2,1.9,4.4,3.5,6.6,4.7c-0.8-0.5-1.2-0.7-0.9-0.6
								C1468.9,1206.7,1467.2,1205.6,1464.5,1203.3z"/>
							<path fill="#C7A331" d="M637.4,972.9c-0.3,0.2-0.7,0.4-1,0.6C632,976.6,634,975.3,637.4,972.9z"/>
							<path fill="#C7A331" d="M1286.8,896.6c0.1,0.2,0.2,0.3,0.3,0.5c-0.1-0.2-0.3-0.5-0.4-0.7L1286.8,896.6z"/>
							<path fill="#C7A331" d="M1276.4,880.6c5.1,6.3,7.5,10.4,10.3,15.8c-0.3-1-0.6-2.1-1-3.1l-1.1-3c-1.3-1.7-2.7-3.3-4.1-4.9
								C1279.2,883.8,1277.8,882.2,1276.4,880.6z"/>
							<path fill="#C7A331" d="M1005,942.4c2.3,0.4,4.5,0.6,6.8,0.9c2.3,0.3,4.6,0.5,6.8,0.7c-8.3-0.1-15.5-1.1-18.9-1.5
								c-1.7-0.2-2.5-0.3-2.1-0.2c0.4,0.2,2.1,0.5,5.3,1.1c1-0.4,14.4,1.3,27.8,1.1c13.4,0.1,26.7-1.7,27.5-2.6c3-0.3,6-0.7,9-1.1
								c-2.6,0.4-3,0.6-5.5,1c4.4-0.5,7.4-1,9.7-1.6c2.4-0.5,4.1-1,6-1.6c1.9-0.6,3.9-1.2,6.8-2.1c1.5-0.4,3.1-0.9,5.1-1.5
								c2-0.6,4.3-1.3,7-2.1c1.9-0.6,3.6-1.1,5.1-1.6c1.5-0.5,2.7-0.9,3.5-1.1c1.6-0.5,1.7-0.4-0.8,0.6l4.1-1.6
								c1.4-0.6,2.7-1.2,4-1.7c2.7-1.1,5.3-2.5,8-3.7c4.6-2.1,3.6-1.4-1,0.7c4-1.9,8.1-3.7,12-5.9l5.9-3.2c1-0.5,2-1,2.9-1.6
								l2.9-1.7l-0.2,0.1c1.6-1.1,4.2-2.8,6.8-4.5c1.3-0.8,2.6-1.8,3.8-2.7c1.2-0.9,2.3-1.6,3.1-2.3c5.7-3.6-2.7,2-1.2,1.2l6.1-4.3
								c2-1.4,3.9-3,5.9-4.5c5-3.3-4.5,4.4-13.1,9.9c6.1-3.3,8.7-5,12.6-8.2c2-1.6,4.4-3.4,7.8-6.1c1.7-1.4,3.6-2.9,5.8-4.7
								c1.1-0.9,2.3-1.8,3.6-2.8c1.3-1,2.6-2.2,4-3.4c-3.4,3.2-7.1,6.1-10.6,9.1c3.7-2.6,7.8-5.5,12.2-9.4c1.1-1,2.3-1.9,3.5-3
								c1.2-1,2.5-2.1,3.7-3.2c2.5-2.3,5.2-4.8,8.1-7.4c-0.7,0.6-2.6,2.2-4.8,4.1c-2.2,1.9-4.7,4.1-6.9,5.8
								c-4.3,3.4-6.7,5.1-0.8-0.4c0.9-0.7,1.8-1.5,2.8-2.4c1-0.9,2-1.9,3.2-3c1.1-1.1,2.3-2.3,3.7-3.6c1.3-1.3,2.7-2.7,4.2-4.3
								c0.2,0.1,1.8-1.3,4.2-3.8c1.2-1.2,2.7-2.7,4.2-4.3c1.5-1.7,3.2-3.6,5-5.5l0,1c1.9-2.1,3.3-3.7,4.3-5c1-1.3,1.7-2.2,2.3-3
								c1.2-1.6,2.1-2.9,4.1-5.5c2.1-2.4,3.7-4.4,5.1-5.9c1.4-1.6,2.4-2.8,3.3-3.8c1.8-1.9,2.9-2.9,3.9-3.9
								c12.5-16.2-9.2,10.7,5.4-8.6c1.3-1.7,2.5-3.3,3.7-4.9c1.2-1.6,2.3-3.2,3.4-4.7c2.3-3.1,4.4-6.1,6.5-9.3
								c2.1-3.2,4.3-6.4,6.6-10c2.3-3.6,4.7-7.4,7.3-11.8c6.3-10.1-0.7,2,1.6-1.6c1.7-3.1,3.3-6.3,4.8-9.5c1.2-2.3,1-1.5,0.8-1.1
								c2-4,1.9-4.3,2.1-5.1c0.2-0.8,0.8-2.1,3.4-8.6c0.4-1.9,3.4-8.4,5.8-17.1c1.2-4.3,2.1-9.2,2.5-14.1c0.4-4.9,0.1-9.9-0.9-14.2
								c-0.6-4.3,0.8,2,0.8,0.7c-1.1-6.1-2.8-10.2-4.4-13.3c-0.4-0.8-0.8-1.5-1.1-2.1c-0.4-0.7-0.7-1.3-1.1-1.8
								c-0.8-1.1-1.4-2.2-2.1-3.1c-1.4-1.9-2.9-3.6-5-5.5c-0.5-0.5-1.1-1-1.7-1.5c-0.6-0.5-1.3-1-2.1-1.6c-0.7-0.6-1.6-1.1-2.5-1.7
								c-0.5-0.3-0.9-0.6-1.4-0.9c-0.5-0.3-1.1-0.5-1.6-0.8c-2.4-1.5-5.1-2.5-7.7-3.5c-2.7-0.8-5.5-1.5-8.3-1.9
								c1.6,0.2,2.6,0.5,3.3,0.7c0.7,0.2,1,0.3,1,0.4c0,0.1-1.2,0-3.3-0.2c-4.1-0.4-11.5-0.3-18.1,1.4c4.3-0.6,7.9-0.7,10.4-0.5
								c-3.6,0.1-7.1,0.6-10.6,1.3c-3.5,0.7-6.9,1.8-10.2,3.1c-1.2,0.4-1.4,0.4-0.8,0c0.6-0.3,1.8-1,3.5-1.6
								c-1.4,0.5-2.7,1.1-3.7,1.5c-0.9,0.3-1.6,0.6-2.2,0.8c-0.6,0.2-1,0.4-1.3,0.5c-0.6,0.2-0.7,0.1-0.4-0.1c0.3-0.2,1-0.6,1.9-1.1
								c0.9-0.5,2.1-1,3.4-1.6c1.3-0.4,2.6-0.7,3.8-1.1c1.3-0.3,2.6-0.5,3.8-0.8c1.3-0.4,2.6-0.7,3.7-1.1c1.2-0.3,2.3-0.5,3.1-0.8
								c1.8-0.4,2.8-0.6,2.2-0.8c-1.4,0.2-4.2,0.5-7.2,1.2c1.4-0.4,2.3-0.6,1.6-0.6c-0.4,0-1.1,0.2-2.2,0.5
								c-0.5,0.1-1.2,0.3-1.9,0.5c-0.7,0.2-1.5,0.5-2.3,0.7c-0.8,0.1-1.7,0.2-2.5,0.4c-0.8,0.2-1.7,0.3-2.5,0.5l-1,0.7
								c-9.1,3.3-17.5,8.5-24.6,15.2c-3.6,3.8-6.6,7.9-9,12.5c-2.5,4.5-4.2,9.5-5.5,14.9c-0.7,2.7-1.1,5.5-1.5,8.5
								c-0.3,3-0.6,6.1-0.6,9.3c-0.1,1.6,0,3.3,0.1,5c0.1,1.7,0.2,3.5,0.4,5.3c0.3,3.6,0.9,7.3,1.8,11.3c-0.5-1.9-0.8-3-1-3.5
								c-0.2-0.5-0.3-0.5-0.4-0.2c-0.1,0.5-0.1,2-0.5,1.9c-0.5-4.4-1.4-10.7-1.4-18c0-3.7,0.2-7.6,0.8-11.6c0.6-4,1.6-8.2,3-12.2
								c-0.7,2.1-1.2,3.6-1.5,4.6c-0.3,1-0.5,1.5-0.6,1.6c-0.2,0.3,0-0.7,0.2-1.9c-2.5,9.9-2.9,20.2-1.6,30.2
								c-0.3-0.3-0.2,1.3,0.1,4c0.3,2.7,1,6.4,1.8,10.2c-0.2-3,1.1,2.5,3.7,9.9c1.3,3.7,3,7.9,4.8,11.7c1.8,3.9,3.7,7.5,5.3,10.1
								c0.1,0.4-0.4,0-1.7-2.1c-0.3-0.5-0.7-1.2-1.1-2c-0.4-0.8-0.9-1.7-1.4-2.7c-1.1-2.1-2.3-4.8-3.7-8.3
								c2.8,7.8,5.6,12.4,8.5,16.8c2.9,4.4,5.9,8.7,10.3,15.4c-2.1-2.9-3.2-4.6-4.1-5.7c1.2,1.7,2.9,4.1,4.7,6.5
								c1.8,2.4,3.8,5,5.6,7.2c3.5,4.4,6,7.4,4.3,5.7l-2.3-2.7c7.6,9.3,20.1,22.6,30.7,34.1c5.3,5.8,10,11.1,13.6,15.2
								c1.8,2.1,3.3,3.8,4.4,5.1c1.1,1.3,1.9,2.1,2.3,2.4c0.1,0.2-2.7-1.8-3.8-2.8c9.5,10.4,18.5,21.6,25.1,34.4
								c-1.1-1.6-0.6,1.1-0.9,1.9c-0.1,0.4-0.5,0.4-1.3-0.9c-0.4-0.6-0.9-1.6-1.6-2.9c-0.8-1.3-1.6-3-2.8-5.1c0.7,1.2,1.3,2.6,2,4
								c0.7,1.4,1.4,3.1,2.2,4.9c1.5,3.6,3.2,7.9,4.6,13.6c-0.2-0.9-0.4-2-0.5-3c-0.1-1-0.3-1.9-0.4-2.5c-0.1-1.3,0.1-1.5,0.9,1
								c0.8,3.5,1.2,5.9,1.4,7.8c0.1,0.9,0.3,1.7,0.3,2.5c0.1,0.7,0.1,1.4,0.2,2.2c0.1,0.7,0.1,1.5,0.2,2.3c0,0.9,0,1.8,0,2.9
								c0,0.6,0,1.2,0,1.9c0,0.7-0.1,1.4-0.1,2.2c-0.1,0.8-0.1,1.6-0.2,2.6c-0.1,0.9-0.3,1.9-0.4,3c0.4-2.8,0.7-2.6,0.8-0.6
								c0,0.5,0,1.1,0,1.8c0,0.7-0.1,1.5-0.2,2.4c-0.1,0.9-0.2,1.9-0.4,2.9c-0.2,1-0.5,2.1-0.8,3.2c0.2-1.4-1.3,0.4-0.6-2.8
								c-1.2,7.3-4.2,14.3-6.8,21.5c2.1-5.3-0.5-1.4-2.8,3.4c0.3-0.5-0.1,0.3-0.7,2.1c-0.6,1.8-1.6,4.4-2.3,7.5
								c-0.7,3-1.2,6.4-1.5,9.3c-0.3,3-0.3,5.6-0.3,7.1c0.1-3.3,0.4-6.6,0.8-9.9c-0.8,6.6-0.6,11.5-0.3,15.5c0.3,4,0.7,7.2,1.1,10.4
								c0.8,6.3,1.7,12.6,2.5,25.4c-0.4-3.1-1.9-7.6-1.2,5.5c1,5.9,4.5,26.2,6.5,48.8l0,4.4l1.5,17.3c-1.6-10.8-5.4-41-7.2-57.5
								c0.4,9.7-0.2,2.7,0.8,17.2c0,5.1-1-7.8-1.3-12.1l2,33.7c0.6-1.5,2.4,25.7,2.9,24.4l-3.1-36.5c0.6-1.5,3.3,21,4.8,44.9
								c0.1,6.1-1.5-4.5-1.7-3.9c0.4,3.1,1.9,22.6,1.9,24.8l-1-10.1c0.6,13.3,2.3,21.6,2.7,37.6c0.3,5.5,0.2,16.5,0.7,17.4
								c0.2-9,2.4-10.9,3.4-5c0.1,1.9,1.2,29,1.2,35.3c0,9.1-2.3-13.5-2.3-5.8c0.2,0.3,0.8,5.3,1,7.4c0.2,5.8-0.7-2.4,0.2,10.2
								c0.8,2.1,1.6,25.6,1,5.9c0.5,6.1,0.7,9.4,0.9,12.9c0.2,3.5,0.3,7.3,0.9,14.6c0-2.3,0.6,0.9,1.6,6.2c0.5,2.6,1.3,5.8,2.3,8.9
								c1,3.2,2.3,6.3,3.7,9c-0.2-0.3-0.1,0.2,0.2,0.9c0.3,0.7,0.7,1.7,1.1,2.2c3.4,5.4,7.8,9.1,12.3,11.6c2.3,1.3,4.6,2.2,6.9,3
								c2.3,0.7,4.7,1.2,7.1,1.6c4.7,0.6,9.5,0.6,14.4-0.2c5-0.7,10.1-2.3,15.4-4.8c1-0.4,4.1-1.9,6.3-3.3c2.2-1.3,3.5-2.3,1.7-0.7
								c0.7-0.5,1.3-1.1,2-1.7c0.7-0.6,1.4-1.2,2.1-2c0.7-0.8,1.6-1.6,2.6-2.6c1-1,2-2.2,3.2-3.6c2.4-2.6,2.9-3.2,2.5-2.6
								c-0.4,0.6-1.7,2.3-3.2,4c-0.8,0.8-1.5,1.8-2.2,2.5c-0.7,0.7-1.3,1.3-1.7,1.8c-0.8,0.8-0.6,0.8,1.5-1c0.1-0.2,1-1.2,2.3-2.7
								c0.6-0.8,1.4-1.6,2.2-2.7c0.8-1,1.6-2.2,2.5-3.4c3.5-4.9,6.8-11.2,8.7-16c-1,1.3-3.4,6.8-7.1,12.6c-1.9,2.9-4,5.9-6.3,8.5
								c-0.6,0.7-1.1,1.3-1.7,1.9c-0.6,0.6-1.2,1.2-1.7,1.7c-0.6,0.6-1.1,1.1-1.6,1.6c-0.5,0.5-1.1,1-1.6,1.4
								c-0.1-0.1,0.1-0.3,0.4-0.8c0.4-0.4,0.9-1,1.6-1.8c0.7-0.8,1.5-1.7,2.5-2.9c0.9-1.2,2-2.5,3.1-4c2.3-3,4.7-6.8,7.1-11.3
								c2.4-4.5,4.6-9.8,6.5-15.5c0.8-2.3,1.3-4.2,1.8-5.6c0.4-1.5,0.8-2.6,1-3.5c0.5-1.7,0.7-2.2,0.8-2.2c0.2-0.1-0.2,1.8,0.2,0.2
								c2.3-8.8,3.3-15.3,4-20.4c0.6-5.1,1.3-8.8,2-12.3c0.7-3.5,1.5-6.8,3-11c0.8-2.1,1.7-4.4,3-7c1.3-2.6,3-5.5,5.4-8.7
								c2-2.4,3.3-3.4,3.9-3.8c0.6-0.4,0.5-0.1,0.2,0.3c-0.6,0.9-1.9,2.8-0.9,1.9c-2.7,3-4.5,6-6.1,8.8c-1.5,2.8-2.8,5.5-3.8,8
								c-2.1,5.1-3.5,9.7-4.7,15c1-4.3,2.3-8.6,3.9-12.8c-0.8,2.4-1.9,6.2-2.8,10.1c-0.9,3.9-1.7,8-2.2,10.8
								c0.5-2.5,1.4-7.6,3.2-14.1c0.9-3.2,2.1-6.8,3.6-10.5c1.5-3.7,3.5-7.6,6-11.2c2.5-3.6,5.7-7.1,9.8-9.2c2-1.1,4.2-1.8,6.5-2.1
								c2.3-0.3,4.5-0.1,6.7,0.4c2.2,0.5,4.2,1.4,6.1,2.5c1.9,1.1,3.6,2.4,5.1,3.7c3.1,2.7,5.8,5.5,8.4,8c1.9,1.7,4.7,3.8,6.7,4.9
								c1,0.6,1.8,0.9,2.1,1c0.3,0.1,0.1-0.1-0.8-0.6c-2.6-1.5-4.9-3.3-7.2-5.3c-1.4-1.5-1.4-1.5-0.6-0.9c0.8,0.6,2.3,1.9,3.9,3
								c0.7,0.6,1.5,1.1,2.2,1.5c0.6,0.4,1.2,0.7,1.4,0.8c0.3,0.1,0.3,0.1,0-0.1c-0.3-0.2-1-0.7-2.1-1.5c4.8,3.3,10.9,5.4,17.6,5.1
								c6.7-0.3,13.8-2.8,21-6.9c1-0.4,2-0.7,3-1.1c-0.2,0-2.5,1.5-5.4,3c-2.9,1.5-6.4,3-8.6,3.7c1.6-0.6,3.9-1.4,6.5-2.6
								c2.6-1.2,5.4-2.7,8.2-4.5c5.5-3.5,10.5-7.9,13-10.9c-2.9,2.6-6,5.1-9.1,7.4c4.6-3.7,9.4-8.5,13.9-13.8
								c2.3-2.7,4.4-5.5,6.8-8.4c2.3-2.9,4.7-5.8,7.3-8.6c-2.5,2.4-3.1,3.1-3.9,4.1c-0.9,1-2.1,2.4-5.7,6.4c1.6-1.7,0.9-0.6-0.9,1.7
								c-1.8,2.3-4.8,5.7-7.8,8.8c-3.1,3.1-6.2,5.9-7.9,7.3c-0.8,0.7-1.3,1.1-1.1,1.1c0.1,0,0.9-0.5,2.3-1.6
								c-4.4,3.1-8.2,5.7-12.5,8.1c-2.2,1.2-4.5,2.3-7.1,3.2c-2.6,0.9-5.5,1.7-8.8,1.9c0.2,0.1,1.4,0,1.1,0.1
								c-0.2,0.1-0.7,0.2-1.9,0.3c-1.2,0.1-3.1,0.3-6-0.1c-0.5,0-1.7-0.1-2.5-0.2c-0.3-0.1-0.5-0.1-0.7-0.2c0.2,0.1,0.4,0.1,0.6,0.2
								c-0.5-0.1-0.5-0.1,0.2,0.1c2.7,0.7,4.9,0.7,5.8,0.9c-1.4,0.1-3.7,0.1-6.3-0.4c-2.6-0.5-5.5-1.6-8.2-3.2
								c-2.7-1.6-5.2-3.7-7.3-5.6c-2.1-1.9-4-3.7-5.3-4.8c-2.8-2.6-2.2-2.1-1.2-1.3c1,0.8,2.2,1.8,0.8,0.3c-2.6-2.2-5.4-4.4-8.7-5.9
								c-0.1-0.1-0.5-0.4-1-0.7c-0.5-0.3-1.3-0.6-2.1-1c-1.7-0.6-3.7-1.2-5.1-1.4c-1.4-0.3-2.3-0.3-1.5-0.4c0.8,0.1,3.2-0.2,7.9,1.5
								c-2.8-1.1-5.7-1.7-8.7-1.7c-3,0-6,0.6-8.8,1.9c-2.4,1-4.9,2.6-7,4.6c-2.1,2-4,4.2-5.6,6.6c-3.2,4.7-5.3,9.7-6.7,13.6
								c-1.4,3.6-2.7,8.3-3.3,11.3c-0.6,3-0.8,4.2-0.3,0.9c-0.5,2.1-0.9,4.1-1.3,6.2c-0.4,2.1-0.7,4.2-1,6.2
								c-0.6,4.1-1.3,8.1-2.1,12.1l-0.2,0.2c-2.6,11.8-6.1,23.8-12.5,34.9c-6.3,10.9-15.7,21.1-28.6,25.3c-1.8,0.7-3.7,1.4-5.7,1.9
								c-2,0.5-4.1,1-6.5,1.3c-4.6,0.6-10.1,0.7-16.4-0.9c-0.8-0.4-1.6-0.7-2.3-1.1c-2.4-0.7-5.2-1.9-8-3.7
								c-2.7-1.8-5.3-4.1-7.3-6.7c-4.1-5.2-5.8-11.2-6.6-13.2c-0.5-2.7-1.3-7-1.8-12.5c-0.5-5.5-0.9-12.1-1.4-19
								c-1-13.7-2.1-28.3-2.6-37.7l-0.6-0.7c-0.8-12.8-1.8-25.1-3-41l-0.4,1c-1.3-13.7-2-35.3-3.5-56.9c0.3,1.5,0.6,7,0.7,10.9
								c-0.7-26-4.4-54.6-5.2-78.1c-0.6-5.8-0.8-10.1-0.9-14.2c0,0.5,0.1,1,0.1,1.5c0-0.6-0.1-1.5-0.2-2.4c-0.1-2.6-0.2-5.3-0.3-8.1
								c0.1,2.5,0.1,4.8,0.2,6.9c-0.3-4.5-0.8-11.4-1.4-18.1l0.3-2.9c0-0.5-0.1-1-0.1-1.6c-0.2-6.6-0.6-5.3-0.7-1.7
								c-0.3-3.4-0.5-6.4-0.7-8.8c0.3-2.7,0.2-7,0.5-11.5c0.3-4.5,1.3-9.2,2.7-12.4c0.2-1.1,1-3.6,2.2-6.7c1.2-3.2,2.9-7,4.5-10.9
								c1.6-3.9,3-7.9,3.6-11.3c0.2-0.8,0.4-1.6,0.5-2.3c0.1-0.7,0.2-1.4,0.3-2c0.1-1.2,0.2-2,0.2-2.5c0.2-0.2,0.6-2.2-0.1,1.4
								c1.5-7.5,1.6-15.3,0.3-22l0-3.3c-1.4-5.4-3.3-10.7-5.6-15.7c-1.8-4.7-3.3-7.9-3.6-8.9c-0.4-1,0.5,0.2,2.9,4.6
								c-1.4-2.7-2.6-5-3.7-6.9c-0.6-1-1.1-1.9-1.6-2.7c-0.5-0.8-1-1.6-1.4-2.3c-0.9-1.4-1.6-2.6-2.3-3.6c-0.7-1.1-1.3-2-2-2.9
								c-2.6-3.6-4.8-6.9-11.4-14.7l0.9,0.5c-4.5-5.7-12.5-14.2-15.2-17.6c-2.5-3.2-7.2-8.5-13.2-15.1c-6-6.6-13.3-14.6-20.7-23.4
								c-7.4-8.8-14.4-19-19.9-29.6c-5.5-10.6-9.4-21.7-11.4-31.7c0.3,4-0.8-0.7-1.4-6.6c-0.3-3-0.5-6.2-0.6-8.8
								c-0.1-1.3-0.1-2.5-0.1-3.3c0-0.9-0.1-1.5-0.2-1.7c0.3-2.4,0.9-8,0.7-1.4c0.1-5.2,0.8-9.6,1.7-13.6c0.9-4,2-7.5,3.4-10.7
								c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.4-0.8,0.5-1.1c0.4-0.7,0.7-1.5,1.1-2.2c0.8-1.4,1.6-2.9,2.5-4.2c1.7-2.8,3.7-5.4,6-8.1
								c0.9-0.7,0.2,0.1-0.5,1c-0.7,0.9-1.4,1.8-0.6,1.1c0.5-0.7,1.6-1.8,2.7-3.1c1.2-1.2,2.5-2.4,3.3-3c1.7-1.2-2.2,2-4.3,4.5
								c3.6-3.7,6.7-6.3,9.3-8.2c1.3-0.9,2.4-1.7,3.3-2.4c1-0.6,1.8-1.2,2.5-1.7c2.8-1.8,3.5-2.6,4-3.3c5-2.1,8.9-3.1,12.4-4
								c3.6-0.8,6.8-1.5,10.7-1.9c0.9,0,2,0.1,2.9,0.1c0.9,0.1,1.6,0.2,1.8,0.3c0.4,0.2-1.4-0.1-7.9,0.5c1.8-0.2,5.6-0.5,10.1,0
								c4.5,0.4,9.8,1.7,14.4,4c4.7,2.2,8.5,5.4,11.1,8c2.5,2.7,4,4.7,4.2,4.8c0.3,0.5,0.8,1.3,1.3,2.2c0.5,1,1.2,2.1,1.7,3.2
								c1.1,2.3,2,4.6,2.4,5.5c1.3,3.9,1.8,7.1,2.1,9.8c0.2,1.3,0.2,2.6,0.3,3.8c0,1.2,0,2.3,0,3.4c-0.1,2.2-0.2,4.5-0.5,7.2
								c-0.3,2.7-0.8,5.8-1.8,9.8c0.4-1.5,0.7-3,1.1-4.4c-0.2,3.3-1,6.6-2.3,10.7c-1.3,4.1-3.2,8.9-5.9,15l0.1-0.4
								c-3.3,6.9-5.6,11-7.2,13.7c-1.6,2.7-2.4,4.2-3.2,5.6c-0.8,1.4-1.6,2.8-3.3,5.4c-1.7,2.6-4.3,6.4-8.7,12.6
								c0.4-0.4,0.9-0.7,1.3-1.1c-0.2,0.7-1.3,2.5-2.9,4.9c-1.7,2.4-3.9,5.6-6.5,9c-1.3,1.7-2.7,3.5-4.1,5.3
								c-1.4,1.8-2.8,3.8-4.3,5.6c-1.5,1.9-3,3.8-4.4,5.6c-1.4,1.9-2.8,3.7-4.2,5.4l2.1-2.4l2-2.4c-0.7,1-1.5,2.1-2.4,3.2
								c-0.9,1.1-1.9,2.2-2.9,3.4c-2.1,2.4-4.3,4.9-6.6,7.3c-4.7,4.8-9.4,9.7-12.7,13.1c-1.5,1.5,2.1-2.5,4-4.8
								c-4.3,4.6-6.7,7-7.8,8.4c-1.2,1.3-1.2,1.6-1.2,1.7c0.1,0.1,0.3,0-0.2,0.9c-0.6,0.8-2,2.4-5.3,5.8c-1.3,1.1-0.7,0.2-0.9,0.1
								c-0.1-0.1-0.3,0.1-1.2,0.8c-0.8,0.7-2.1,2.1-4.4,4.2c-1.2,1-3.4,3.2-6.2,6c-1.4,1.4-3,2.9-4.8,4.6c-1.8,1.6-3.6,3.4-5.6,5.1
								c-1,0.9-2,1.8-3,2.6c-1,0.9-2.1,1.7-3.1,2.6c-2.1,1.7-4.2,3.5-6.3,5.2c-2.2,1.6-4.3,3.2-6.3,4.6c-2,1.5-3.9,2.9-5.8,4
								c0.8-0.5,1.7-0.9,2.3-1.3c0.6-0.4,1.1-0.7,1.3-0.7c0.3-0.1-0.5,0.8-4.1,3.3c-1.4,0.9-2.9,1.9-4.5,3c-0.8,0.5-1.6,1.1-2.5,1.6
								c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.3-0.9,0.5-1.4,0.8c-1.8,1-3.7,2.1-5.6,3.2c-1,0.5-1.9,1.1-2.9,1.7c-1,0.6-1.9,1.1-2.9,1.6
								c-4,2-7.8,4.1-11.4,5.9c-3.7,1.7-7,3.4-9.6,4.8c-1.6,0.7-2.2,0.9-2.3,0.8c-4.6,2.1-12.3,5.2-19.8,7.6
								c-7.4,2.5-14.5,4.2-17.4,5.1c-1.2,0.1-3.1,0.3-5.4,0.7c-2.3,0.4-5.2,0.8-8.4,1.3c-6.3,0.9-13.9,1.9-21.2,2.3
								c-7.3,0.4-14.4,0.5-19.6,0.2c-5.2-0.3-8.5-0.7-8.4-1.1c-10.4-0.9-20.8-2.7-30.9-5.3l0.8,0.1c-9.7-2.6-16-4.9-20.9-6.7
								c-2.4-0.9-4.4-1.8-6.3-2.4c-1.8-0.7-3.4-1.5-5.1-2.1c-2.1-1.1-2.3-1.3-2-1.2c0.3,0.1,1.2,0.5,1.4,0.5
								c-1.6-0.8-3.1-1.7-4.6-2.5c-0.7-0.4-1.4-0.8-2.1-1.2c-0.7-0.4-1.3-0.8-1.9-1.2c-1.2-0.8-2.4-1.5-3.5-2.2
								c-1.1-0.7-2.1-1.4-3.1-2.1c-1-0.7-1.9-1.3-2.8-1.9c-0.9-0.6-1.7-1.3-2.5-1.9c-0.8-0.6-1.6-1.2-2.4-1.7
								c-0.8-0.6-1.5-1.2-2.2-1.8c-3-2.3-5.7-4.6-8.8-7.4c-1.5-1.4-3.2-2.9-5-4.7c-1.8-1.8-3.7-3.7-5.7-6c-0.4-0.7-0.8-1.5-1.3-2.3
								c-2.2-2.5-8.8-10.9-15-22.7c-3.2-5.9-6.1-12.6-8.7-19.6c-2.7-7-5.4-14.4-8.9-20.9c0.2,0.2,0.5,0.7,1.6,2.8
								c-1.8-3.7-4-7.6-6.7-11.3c-2.7-3.7-5.9-7.2-9.8-10c-1.9-1.5-3.9-2.6-6-3.8c-2.1-1.1-4.3-2-6.5-2.7c-4.4-1.5-9-2.3-13.4-2.6
								c-8.8-0.5-16.9,0.9-23.3,2.9c-6.4,2-11,4.5-13.5,6.4c-3.2,1.8-6.3,3.8-9.2,6c-2.9,2.3-5.6,4.7-8.2,7.2c1.3-1.2,2.4-2.2,3.4-3
								c1-0.8,1.9-1.5,2.6-2.1c1.4-1.2,2.4-1.7,2.8-2c0.9-0.6,0.2,0.1-1,1.1c-2.3,2.1-6.1,5.6-1.6,2c-1,0.9-2.2,1.8-3.3,2.8
								c-1.1,1-2.3,2-3.4,3c-1.1,1-2.1,2-3.1,2.9c-1,0.9-1.8,1.8-2.5,2.5c-1.4,1.4-2.3,2.3-2.3,2.2c0-0.1,0.9-1.2,3.2-3.7
								c0.5-0.5,1-1,1.5-1.5c1.4-2.1-4.9,4-10.6,11.3c-5.8,7.2-11.1,15.4-11.2,14.9c-7.7,11.4-14.6,23.1-22.5,34.1l-1.4,2.1l-1.5,2
								l-3,4c-2,2.7-4.1,5.3-6.2,7.9c-2.1,2.6-4.2,5.2-6.4,7.7l-3.3,3.8l-1.6,1.9l-1.7,1.8c-17.8,19.6-37.6,36.8-58.6,51.4
								c1.8-0.9,3.5-1.9,5.2-2.8l-3.6,2.6l-3.7,2.5l-3.7,2.5l-3.8,2.4c-4,2.4-5.6,3,5.1-4c-1.4,0.9-3.3,2-5.2,3.3
								c-1.9,1.3-4,2.6-5.9,3.9c-3.8,2.5-6.9,4.5-7.1,4.6c-2.7,1.4-4.7,2.3-6,2.8c-1.3,0.5-2,0.8-2.2,0.8c-0.4,0.1,1.2-0.7,3.2-1.7
								c4-2,9.7-5.5,6.4-3.7c-3,1.7-6,3.2-9.1,4.6l2.1-0.8c-3.7,1.8-5.7,2.8-8.6,3.8c-1.5,0.5-3.1,1-5.4,1.5c-2.2,0.5-5,0.9-8.8,0.9
								c-2.3-0.1-3.8-0.2-4.7-0.4c-0.9-0.2-1.3-0.3-1.6-0.3c-0.5-0.2-0.2-0.2-2-0.6c0,0-0.3-0.1-1.1-0.3c-0.8-0.2-2.2-0.5-4.3-1.2
								c-6.8-2.4-12.9-6.5-18.2-11.4c1,1.1,2.1,2.1,3.1,3.1c1.1,1,2.2,2,3.3,2.8c-2.1-1.3-4-2.9-5.7-4.3c-0.8-0.8-1.6-1.5-2.3-2.1
								c-0.7-0.7-1.3-1.3-1.7-1.7c-0.9-1-1.4-1.5-1.3-1.3c0.1,0.2,0.9,1.2,2.6,3.2c-2.5-2.8-7.2-8.1-11.7-15.5
								c-2.2-3.7-4.4-7.9-6.4-12.3c-2-4.5-3.7-9.2-5.1-14c-2.7-9.6-4.2-19.5-5.2-27.4c-0.2-2-0.5-3.8-0.6-5.6
								c-0.1-1.7-0.3-3.3-0.4-4.7c-0.1-1.4-0.2-2.6-0.3-3.6c0-1-0.1-1.8-0.1-2.4c-1.2-13.9-1.2-27.7-1.2-44.9
								c-0.1,4.3-0.7,6.8-0.7-8c0.3,1.3,0.5,0.3,0.6-2.1c0.1-2.5,0.1-6.4,0.1-10.9c-0.1-9-0.7-20.1-0.9-26.1
								c0.1,2.7-0.1,1.4-0.5-1.8c-0.1-0.8-0.2-1.7-0.3-2.7c-0.1-1-0.3-2.1-0.5-3.2c-0.2-1.1-0.3-2.3-0.5-3.5
								c-0.1-0.6-0.2-1.2-0.3-1.8l-0.3-1.8c-0.4-2.4-0.9-4.8-1.4-6.9c-0.5-2-0.9-3.8-1.3-5c-0.7-2.3-0.9-2.5-0.1,1.7
								c-1.5-6-1.5-6.2-1.3-6.1c0.2,0.1,0.5,0.6-0.7-4c-2.4-7-5.3-14.3-8.9-21.6c-1.8-3.6-3.8-7.3-6-10.8c-1.1-1.8-2.3-3.6-3.4-5.3
								c-0.6-0.9-1.2-1.7-1.9-2.6c-0.6-0.9-1.3-1.7-1.9-2.6c-5.2-6.9-11.4-13.4-18.6-18.9c-3.6-2.8-7.5-5.3-11.6-7.4
								c-2-1.1-4.2-2-6.3-2.9l-3.3-1.2c-1.1-0.4-2.2-0.7-3.3-1c-0.6,0,5.3,2-2.4,0.1c-6.2-1.9-12.8-2.9-19.4-3
								c-0.9,0-1.7,0.1-2.4,0.1c-0.7,0.1-1.3,0.2-1.9,0.3c-1.2,0.2-2.2,0.4-3.4,0.6c-1.2,0.3-2.6,0.5-4.5,0.8c-1,0.1-2,0.4-3.3,0.6
								c-1.3,0.2-2.7,0.4-4.3,0.7c0.9-0.1,2.1-0.3,2.4-0.3c0.3,0-0.2,0.2-2.5,0.7c-8.8,1.9-17.4,4.4-26,6.2
								c-8.7,1.9-17.5,3-26.3,2.1l-3.2-0.5l-1.6-0.3l-1.6-0.4c-1.1-0.3-2.1-0.5-3.2-0.9c-1-0.4-2.1-0.6-3.1-1.1
								c-4.1-1.6-8-3.7-11.6-6.2c-7.1-5.2-12.9-12.2-17.5-19.8c-4.6-7.7-8.1-16.1-10.8-24.7c-2.8-8.6-4.7-17.5-6.3-26.4
								c-0.8-4.5-1.4-8.9-2-13.4c-0.6-4.5-1-9-1.4-13.5c-0.9-9-1.7-18.1-2.5-27c0.7,9.8,0,3.3-0.8-4.1c0.4,4.8-0.4-0.1-0.6,0.9
								l-2.6-30.1c-1.3-5.4-3.6-32.2-4.6-35.3l1.2,9.5c-2.1-12.6,0.3,17.9-0.9,16.1c2.4,14.1,2.8,37.5,6.6,60.6
								c0.4,5.2-0.8-4.3-0.7-1.6c0.5,2.9,1,6.2,1.5,10.3c0.5,4.2,1,9.2,1.8,15.9l-0.3-1.5c1.2,9.3,2.6,17.2,4.2,24.5
								c1.6,7.3,3.4,13.9,5.7,20.6c1.2,3.3,2.4,6.7,3.9,10c1.5,3.4,3.1,6.8,5.2,10.4c2,3.5,4.4,7.2,7.3,10.8
								c2.9,3.6,6.4,7.2,10.8,10.5c-0.7-0.8-1.5-1.6-2.2-2.4c1.8,1.5,3.2,2.6,4.5,3.5c0.6,0.4,1.2,0.9,1.7,1.2
								c0.5,0.3,1.1,0.6,1.5,0.9c0.5,0.3,0.9,0.6,1.3,0.8c0.4,0.2,0.8,0.5,1.3,0.7c0.8,0.4,1.7,0.9,2.7,1.4c5.1,1.6-1.1-0.5-6.8-4
								c-5.6-3.6-10.3-8.4-6.5-5.4c2.1,2.2,6.5,5.8,12.7,8.7c0.8,0.4,1.6,0.7,2.4,1c0.8,0.4,1.7,0.7,2.5,1c0.9,0.3,1.8,0.6,2.7,0.9
								c0.9,0.2,1.8,0.5,2.8,0.7c0.9,0.3,1.9,0.4,2.9,0.6c1,0.2,1.9,0.3,2.9,0.5c1,0.1,2,0.2,3,0.2c1,0.1,2,0.2,3,0.2
								c7.9,0.1,15.6-1.2,21.6-2.4c6.1-1.2,10.7-2.4,12.7-2.6c13.4-3.5-4.1,0.5,9.3-2.9c1.2-0.2,2.4-0.4,3.6-0.5
								c1.1-0.4,2.2-0.8,3.3-1.1c4.4-0.9,7.7-1.3,10-1.4c2.3-0.1,3.5-0.1,3.6,0.1c-1.1,0.1-2.6,0.3-4.1,0.4
								c-1.5,0.3-3.1,0.5-4.2,0.7c1.4-0.2,3-0.4,4.7-0.6c0.8-0.1,1.7-0.1,2.6-0.2c0.9-0.1,1.8-0.2,2.7-0.2c0.9,0,1.8-0.1,2.7-0.1
								c0.5,0,0.9,0,1.4,0l1.4,0c0.9,0,1.8,0.1,2.7,0.1c0.9,0.1,1.8,0.2,2.7,0.3c1.8,0.1,3.4,0.5,5,0.7c1.6,0.3,3,0.7,4.3,0.9
								c2.6,0.7,4.5,1.3,5.4,1.7c-4-1.5-8.1-2.7-12.4-3.3c9,1.2,18.2,4.7,26.6,10.1c8.4,5.3,15.9,12.5,22.2,20.4
								c6.4,8,11.6,16.8,15.9,25.7c4.2,9,7.4,18.1,9.7,26.8c-0.1-0.9-0.3-1.8-0.5-2.7c1.9,6.6,2.7,11.4,3.3,15.2
								c0.5,3.8,0.9,6.6,1.2,9.6c0.1,1.5,0.2,3,0.4,4.6c0.1,0.8,0.1,1.7,0.2,2.6c0.1,0.9,0.1,1.9,0.2,2.9c0.1,2.1,0.3,4.5,0.4,7.3
								c0.1,2.8,0.3,6.1,0.4,9.9l-0.1-1.1c0.4,6.1,0.6,14.3,0.6,22.6c-0.3-7.7-0.5,14.5-0.8,4.4c0.2,15.1,0.4,18.9,0.6,21.3
								c0.2,2.5,0.3,3.6,1.1,13.6l-0.1,4.6c0.2,1.7,0.3,3,0.5,4.3c0.2,1.2,0.3,2.4,0.5,3.9c0.2,1.4,0.3,3.2,0.6,5.5
								c0.3,2.3,0.6,5.2,1.2,9c-1-6.2-0.8-3.8-0.7-1.5c0.1,1.1,0.2,2.2,0.1,2.1c-0.1-0.1-0.4-1.3-0.9-4.8c0.1,1,0.2,2,0.3,3
								c0.1,1,0.2,1.9,0.4,2.9c0.3,1.9,0.5,3.7,0.8,5.5c0.6,3.5,1.3,6.8,1.9,9.8c1.4,6.1,3.2,11.4,5.1,15.9
								c3.8,9.2,7.7,15.8,11.5,22.3c2.4,2.9,2.6,2.9,1.6,1.4c-1-1.5-3.1-4.6-5.1-8c-4-6.8-7.3-14.8-5.6-11c-1.2-3.3,0.1-0.5,2.5,4.1
								c1.2,2.3,2.8,5,4.4,7.7c1.6,2.7,3.4,5.2,4.9,7.2c1.6,2,3,4,4.6,5.9c1.6,2,3.4,4,5.7,6.2c2.3,2.2,5.2,4.7,9.1,7.1
								c2,1.2,4.2,2.4,6.8,3.4c2.6,1,5.5,2,8.8,2.6c-2.6-0.5-2.8-0.5-5.4-1.2c4.8,1.6,9.8,2.2,15,2c5.2-0.2,10.6-1.3,16.4-3.2
								c3.1-1.4,6.7-3,9.6-4.5c2.9-1.5,5.3-2.9,5.9-3.7c-6.8,4-14.1,7.6-22,9.6c6.7-1.7,12.7-4.7,19.3-8.5c3.3-1.9,6.7-4.1,10.5-6.6
								c1.9-1.2,3.9-2.5,6-3.9c2.1-1.4,4.3-2.8,6.6-4.4c-1.3,1.1-6.6,5.1-10.4,7.6c1.4-0.8,2.7-1.7,4.1-2.5c1.3-0.9,2.6-1.8,3.9-2.7
								c2.6-1.8,5.1-3.7,7.9-5.6c-0.3,0.4-1.9,1.7-4.3,3.5c-1.2,0.9-2.7,1.9-4.2,3c-1.6,1.1-3.3,2.3-5.2,3.5
								c-1.8,1.2-3.8,2.4-5.7,3.7c-2,1.2-4,2.5-6,3.6c-4,2.4-7.9,4.4-11.2,6.1c1.7-0.6,4-1.7,6.5-3.1c2.5-1.3,5-2.9,7.2-4.2
								c4.3-2.7,7-4.5,4.2-2.2c8.2-6.4,21.4-14,34.5-25.8l-0.6,1.1c1.1-0.9,2.6-2.3,4.5-3.9c1.8-1.6,3.8-3.4,5.7-5.1
								c-2.1,2.2-4.4,4.4-6.6,6.5c10.5-9.6,20.8-18,30-28.1c-2.5,2.6-4.4,4.6-5.9,6.1c-1.5,1.5-2.6,2.6-3.4,3.3
								c-1.6,1.5-1.8,1.6-1.7,1.4c0.2-0.5,1.8-2.4-2.6,1.7c12.4-12.5,15.7-16.3,19.8-20.1c1.9-2,4.1-4.1,7.2-7.6
								c3.1-3.5,7.3-8.5,13.1-16.5c-0.8,1.1-1.9,2.6-3.1,4.2c-1.2,1.6-2.6,3.2-3.6,4.5c-2.1,2.5-3.2,3.7-0.7,0.3
								c4.8-5.8,10.7-13.8,13.5-17.5c14.4-21.8-2.6,3.1,3.3-6.3c5.4-7.1,6.7-10.9,12.7-20.5l0.4,0.2c4.1-6.1,6.4-9.5,7.9-11.6
								c1.4-2.1,1.8-2.9,2.2-3.6c0.4-0.7,0.7-1.3,1.9-3.1c1.3-1.7,3.4-4.6,7.9-9.5c-1.2,1.4-2.3,3-3.4,4.4c-1.1,1.5-2.2,3-3.2,4.5
								c1.4-1.8,2.8-3.6,4.3-5.4l2.2-2.6l2.3-2.5l-1.5,2.4c4.8-5.6,8.4-9,12.1-12c3.7-3.1,7.6-5.8,14-8.9c-1.6,0.6-1.3,0.2,0.9-1.1
								c2.2-1.2,6.3-3.2,12.5-4.9c2.1-0.5,2.9-0.5,3.2-0.5c0.2,0-0.1,0.1-0.1,0.2c0,0,0.3,0,1.6-0.3c1.4-0.2,3.9-0.7,8.4-1
								c-2,0.2-4.1,0.7-6.7,1.1c-2.5,0.5-5.5,1.1-9.4,2c-0.4,0.2-1.3,0.6-2.2,1c-0.9,0.4-1.8,0.8-2.2,1c4.4-1.5,10-3.1,15.4-3.8
								c5.4-0.8,10.5-0.7,13.7-0.4c-2.4-0.3-5.4-0.3-6.9-0.4c8.6-0.5,17.3,1.1,24.9,5.2c1.7,1.1,1.8,1.3,1.2,1
								c-0.3-0.1-0.8-0.4-1.4-0.7c-0.6-0.3-1.3-0.6-2-1c2.3,1.2,4.2,2.3,6.2,3.7c2,1.5,4.1,3.3,6.6,6.2c-0.8-0.6-1.2-0.8-2.3-1.7
								c-0.3-0.2-0.6-0.5-1-0.9c-0.4-0.3-0.9-0.7-1.5-1.2c-1.1-0.9-2.7-2.1-4.9-3.5c6.8,4.1,12,10.6,15.8,17.9
								c0.1,0.4,0.6,1.3,1,2.4c0.5,1.1,1,2.5,1.5,3.7c0.9,2.4,1.6,4.3,0.9,2.8c1.3,3.3,2.7,7,4.2,10.9c1.6,3.9,3.4,8.2,5.5,12.5
								c4.2,8.5,9.4,17.1,15,25c0.7,0.8,1.9,2.3,4.5,5.5c5.6,7,11.5,13.3,18.2,19.1c6.8,5.8,14.4,11.4,23.9,16.3
								c1.7,1.1,3.4,2.2,5.2,3.2l5.3,3c7.7,3.3,4,1.4,1.6,0.1c-1.2-0.6-2-1.2-1-0.8c0.5,0.2,1.5,0.5,3.2,1.2c0.8,0.3,1.8,0.8,3,1.2
								c1.2,0.5,2.6,1,4.2,1.6c9.2,3.3,11,3.8,11.2,4.1c0.2,0.3-1.2,0.1,2.1,1.2c9.5,2.5,26.3,5.3,44.3,6.7c-3,0-5.6-0.3-8.2-0.5
								C1010.3,943,1007.7,942.8,1005,942.4z M1191.9,875.9c-2.9,2.7-8.6,8.2-11.1,9.7C1184,882.4,1186.7,880.2,1191.9,875.9z
								 M1212.5,678.6c2.3-1.9,6.2-4.4,10.9-6.5C1220,673.8,1216.3,675.9,1212.5,678.6z M1417.9,1197.3c-0.1,0.1-0.2,0.2-0.3,0.4
								c-1.5,1.8-2.3,3.1-2.8,3.8c-0.5,0.7-0.6,1-0.5,0.8C1414.6,1201.9,1415.9,1199.8,1417.9,1197.3z M1434.6,1187.4
								c1-0.2,1.5-0.2,1.6-0.2c0.1,0-0.1-0.1-0.6-0.1c-0.4,0.1-0.9,0.1-1.6,0.2c-0.7,0.1-1.6,0.3-2.5,0.5c-1.9,0.5-4.2,1.5-6.3,2.9
								c0.3-0.2,0.7-0.4,1.2-0.6c0.5-0.2,1.1-0.5,1.8-0.9C1429.6,1188.6,1431.6,1187.8,1434.6,1187.4z M583.7,985.3
								c1.1,0.4,2.2,0.6,3.3,0.8c-0.9-0.2-1.7-0.3-2.4-0.4c-0.7-0.2-1.2-0.3-1.6-0.4c-0.8-0.2-1.2-0.3-1.3-0.4
								C581.6,984.9,582.6,985.1,583.7,985.3z"/>
							<path fill="#C7A331" d="M1245,665c1.1,0,1.5,0,1.7,0.1C1248.3,665,1248.4,664.9,1245,665z"/>
							<path fill="#C7A331" d="M895.9,881.8l-0.4-0.5c-0.2-0.3-0.4-0.4-0.4-0.5L895.9,881.8z"/>
							<path fill="#C7A331" d="M1296.4,1229.2c-0.2-3.7-0.2-5.8-0.2-7.2C1296.1,1221.7,1296.1,1223.4,1296.4,1229.2z"/>
							<path fill="#C7A331" d="M1517.1,1194.4c-2.8,2.6-5.7,5-8.8,7.1c4.4-3.2,5.9-4.4,8.1-6.2
								C1516.2,1195.4,1514.5,1196.7,1517.1,1194.4z"/>
							<path fill="#C7A331" d="M1599.5,1147.4c1.6,0.6,3.3,1.5,4.8,2.3l-0.3-0.4c-3.2-1.8-6.1-2.8-7.3-3.2
								C1595.4,1145.8,1596,1145.9,1599.5,1147.4z"/>
							<path fill="#C7A331" d="M1525.8,1188c0.5-0.2,1.9-1.6,3.8-3.9c1.9-2.2,4.2-5.3,6.9-8.5c-0.3,0.4-0.6,0.7-0.9,1.1
								c0.6-1.1-0.5,0-2.4,2.3C1531.2,1181.5,1528.7,1184.8,1525.8,1188z"/>
							<path fill="#C7A331" d="M1537.7,1174.2c1.6-1.9,3.3-3.9,5-5.7C1543.2,1167.6,1540.5,1170.7,1537.7,1174.2z"/>
							<path fill="#C7A331" d="M1456.9,1196.7c2.8,2.8,2.1,1.3,3.6,2.9c0.9,0.6,0.1-0.1-1.3-1.4c-1.4-1.3-3.5-3.1-5.3-4.5
								c-1.8-1.4-3.4-2.3-3.2-2.1C1450.9,1191.8,1452.9,1193,1456.9,1196.7z"/>
							<path fill="#C7A331" d="M1531.1,1184l-2.3,1.9c-0.9,1.2-1.8,2.5-2.8,3.7C1527.8,1187.7,1529.5,1185.9,1531.1,1184z"/>
							<path fill="#C7A331" d="M1476.9,1212.6c2.1,0.5,4.2,0.8,6.4,0.8c2.2,0,4.3-0.2,6.4-0.6c-0.2,0,1.4-0.4,3.3-1
								c-3.8,1.2-8,1.5-11.1,1.4c-1.6-0.1-2.9-0.3-3.7-0.4C1477.3,1212.6,1476.8,1212.5,1476.9,1212.6z"/>
							<path fill="#C7A331" d="M1493,1211.8c4-1.1,7.6-2.9,9.6-4c-0.5,0.3-1.1,0.6-1.9,1c-0.7,0.4-1.6,0.7-2.4,1.1
								C1496.5,1210.6,1494.6,1211.3,1493,1211.8z"/>
							<path fill="#C7A331" d="M1518.9,1196.9c-1,0.8-2,1.6-3,2.4c-2.4,1.9-4.8,3.8-7.4,5.5
								C1512.1,1202.5,1515.6,1199.8,1518.9,1196.9z"/>
							<path fill="#C7A331" d="M1398,1267.6c-1.5,4.5-3.2,8.9-5.3,13.2c-0.2,0.7,0.1,0.2,0.7-1.1c0.3-0.7,0.7-1.5,1.2-2.5
								c0.4-1,0.9-2.1,1.4-3.4c0.6-1.6,1.3-3.7,1.7-5.1C1398.3,1267.3,1398.4,1266.5,1398,1267.6z"/>
							<path fill="#C7A331" d="M1408.7,1225.9c-1,3.7-1.1,3.9-1.7,6l0.5,0.5C1407.9,1230.3,1408.3,1228.1,1408.7,1225.9z"/>
							<path fill="#C7A331" d="M1399.9,1266.6c0,1.5-7,19.7-0.4,2.7C1399.9,1267.8,1403.9,1254,1399.9,1266.6z"/>
							<polygon fill="#C7A331" points="1296.7,1207.1 1296.9,1210.7 1297.9,1210.1 							"/>
							<path fill="#C7A331" d="M1281.7,1018.4c0.9,16.9,0.9,4,1.6,19.2c0.5,3.9-0.6-12.5-0.1-8.4
								C1282.3,1019.1,1282.1,1013.7,1281.7,1018.4z"/>
							<path fill="#C7A331" d="M1283.2,1029.2c0.2,1.6,0.3,3.3,0.5,5.2C1283.5,1031.5,1283.3,1030.1,1283.2,1029.2z"/>
							<polygon fill="#C7A331" points="291.1,506.6 290.1,495.7 290.5,502.8 							"/>
							<path fill="#C7A331" d="M288,485.9l0.4-2.6l-1.3-6.4C287.4,479.3,288,484.1,288,485.9z"/>
							<path fill="#C7A331" d="M1214.5,855.6c0.2-0.2,0.3-0.5,0.5-0.7c-1.3,1.1-3.1,2.9-6.7,6.6L1214.5,855.6z"/>
							<polygon fill="#C7A331" points="1091,935.8 1093.5,935.1 1088.7,935.9 							"/>
							<path fill="#C7A331" d="M967.3,934.9c1.2,0.4,2.5,0.8,3.8,1.3c1.3,0.4,2.7,0.8,4,1.2c1.4,0.4,2.7,0.8,4,1.1
								c1.3,0.3,2.6,0.7,3.9,1C972.1,936.8,966.2,933.7,967.3,934.9z"/>
						</g>
					</g>
				}
				</g>
			</g>
		</g>
	</g>
</switch>
</svg>
</div>)
	}
}
